import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FiCheck } from 'react-icons/fi';
import { FormFieldTooltipIndexProps, ISelectOption } from '../../../types';
import { cn } from '../../../utils/classUtils';
import FormErrorMessage from './ZenFormErrorMessage';

interface ZenControlledImageRadioInputOption extends ISelectOption {
  img: React.ReactElement;
}

interface ZenControlledImageRadioInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  options: Array<ZenControlledImageRadioInputOption>;
  disabled?: boolean;
}

const ZenControlledImageRadioInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  options,
  disabled = false,
  tooltipIndex,
  shouldUnregister = true,
  ...rest
}: ZenControlledImageRadioInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <div className='w-full space-y-1'>
          <div
            className='flex flex-row flex-wrap justify-between'
            data-tooltip-index={tooltipIndex}
          >
            {options.map(({ value: optionValue, label, img }) => {
              const checked = value === optionValue;

              return (
                <label
                  key={optionValue}
                  className={cn(
                    'border rounded-xl px-3 md:px-4 pt-8 pb-4 md:pt-12 md:pb-3 h-40 w-40 md:h-52 md:w-52 flex flex-col items-center relative',
                    { 'border-primary-blue': checked },
                  )}
                >
                  {img}
                  <p className='font-zen-body mt-4 text-xs md:text-sm text-center text-zen-dark-9'>
                    {label}
                  </p>
                  <input
                    type='radio'
                    className='hidden'
                    value={optionValue}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={checked}
                    disabled={disabled}
                  />
                  <div
                    className={cn(
                      'absolute w-7 h-7 border right-3 top-3 rounded-full flex items-center justify-center',
                      checked
                        ? 'border-primary-blue bg-primary-blue'
                        : 'border-gray-300',
                    )}
                  >
                    {checked && <FiCheck className='text-white text-lg' />}
                  </div>
                </label>
              );
            })}
          </div>
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenControlledImageRadioInput;
