import { useCallback, useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  FeeSplitFeeTypeEnum,
  FlexTeamConfigDtoCountryEnum,
  FlexTeamDto,
  UpdateFlexTeamLeader,
  UpdateFlexTeammate,
  UpdateFlexTeamMemberTypeEnum,
} from '../../../../openapi/yenta';
import ZenSidebarModal from '../../ZenSidebarModal';
import ZenTabs from '../../ZenTabs';
import {
  LeaderDto,
  TeammateDto,
} from '../../../../routes/ZenTeamsProDetailsRoute';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import { FlexTeamRoles, ISelectOption } from '../../../../types';
import {
  getFlexTeamDetailOverview,
  updateFlexTeamMember,
} from '../../../../slices/TeamSlice';
import {
  calculateDefaultFeeSplits,
  calculateDefaultLeaderDetailsForTeammate,
  combineFeeSplits,
  getSplitPercentage,
  getTeamCapDetails,
  setDefaultFeeSplits,
} from '../../../../utils/ProTeams/ProTeamsHelper';
import { getParticipantName } from '../../../../utils/ParticipantHelper';
import ZenTeamMemberLeaderFeeSplitsTab from './ZenTeamMemberLeaderFeeSplitsTab';
import ZenTeamMemberAgentFeesTab from './ZenTeamMemberAgentFeesTab';
import ZenTeamMemberTeamFeesTab from './ZenTeamMemberTeamFeesTab';
import ZenDeleteProTeamMemberModal from './ZenDeleteProTeamMemberModal';
import ZenAddProAdminTeamMemberForm from './ZenProAdminTeamMemberForm';

export interface TeamMemberDto extends TeammateDto, LeaderDto {}
interface ZenEditProTeamMemberProps {
  teammate: TeamMemberDto;
  leaders: LeaderDto[];
  team: FlexTeamDto;
  onClose(): void;
  refetch(): void;
  readOnly?: boolean;
  removeMember: boolean;
  isViewing?: boolean;
}

export interface ProTeamMember {
  name: string;
  agentId: ISelectOption;
  teamLeader: ISelectOption;
  realSplit: string;
  leaderSplit: string;
  beopFee: string;
  realFee: string;
  transactionFee: string;
  roles: ISelectOption;
  leaderFeeDetails: any;
  capAmount: string;
  teamPreCapFee: string;
  teamPostCapFee: string;
  individualTransactionFee: string;
  individualBEOPFee: string;
  individualRealFee: string;
  individualRealSplit: string;
  realCap: string;
  adminSplit?: boolean;
  adminRealSplit?: string;
  adminBEOPFee?: string;
  adminRealFee?: string;
  adminTransactionFee?: string;
  override?: boolean;
}
const ZenEditProTeamMember: React.FC<ZenEditProTeamMemberProps> = ({
  teammate,
  leaders,
  team,
  onClose,
  refetch,
  readOnly = true,
  removeMember,
  isViewing,
}) => {
  const originalLeaderDetails = leaders.map((leader) =>
    calculateDefaultLeaderDetailsForTeammate(leader, teammate),
  );
  const originalTeamCapDetails = getTeamCapDetails(team, teammate);

  const [selected, setSelected] = useState<string>();
  const [memberToDelete, setMemberToDelete] = useState<TeamMemberDto>();
  const isTeammateRole = [FlexTeamRoles.Teammate, FlexTeamRoles.Admin].includes(
    teammate.roles[0] as FlexTeamRoles,
  );

  const isEmptyFeeAndLeaderSplits =
    isEmpty(
      (teammate?.teammateDetails?.feeSplits || []).filter(
        (splits) => !isEmpty(splits?.feeSplits),
      ),
    ) && isEmpty(teammate?.teammateDetails?.leaderSplits);

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = useForm<ProTeamMember>({
    defaultValues: {
      roles: {
        label: capitalizeEnum(teammate?.roles[0]!),
        value: teammate?.roles[0]!,
      },
      adminSplit: teammate.teammateDetails?.paysLeaderSplit,
      override: isTeammateRole
        ? !isEmptyFeeAndLeaderSplits
          ? true
          : false
        : true,
      teamLeader:
        teammate?.roles[0] === FlexTeamRoles.Leader
          ? {
              label: getParticipantName(teammate?.agent),
              value: teammate?.leaderDetails?.id!,
            }
          : undefined,
      capAmount: originalTeamCapDetails?.capAmount?.toString(),
      teamPostCapFee: originalTeamCapDetails?.postCapFee?.toString(),
      teamPreCapFee: originalTeamCapDetails?.preCapFee?.toString(),
      realCap: isTeammateRole
        ? teammate?.teammateDetails?.realCap?.toString()
        : teammate?.leaderDetails?.realCap?.toString(),
      leaderFeeDetails: isTeammateRole
        ? originalLeaderDetails
        : [
            {
              teamLeader: {
                value: teammate?.leaderDetails?.id!,
                label: getParticipantName(teammate?.agent),
              },
              leaderSplit: teammate?.leaderDetails?.leaderSplit!,
              realSplit: getSplitPercentage(
                FeeSplitFeeTypeEnum.RealSplit,
                teammate?.leaderDetails?.feeSplits,
              ),
              beopFee: getSplitPercentage(
                FeeSplitFeeTypeEnum.BeopFee,
                teammate?.leaderDetails?.feeSplits,
              ),
              realFee: getSplitPercentage(
                FeeSplitFeeTypeEnum.BrokerageFee,
                teammate?.leaderDetails?.feeSplits,
              ),
              transactionFee: getSplitPercentage(
                FeeSplitFeeTypeEnum.TransactionFee,
                teammate?.leaderDetails?.feeSplits,
              ),
            },
          ],
    },
  });

  const [leaderFeeDetails, adminSplit] = watch([
    'leaderFeeDetails',
    'adminSplit',
  ]);
  const override = watch('override', undefined);
  useEffect(() => {
    if (override) {
      setValue('override', override);
    }
  }, [override, setValue]);
  useEffect(() => {
    if (!adminSplit && teammate?.roles[0] !== FlexTeamRoles.Leader) {
      setValue('override', false);
    }
  }, [adminSplit, setValue, teammate?.roles]);

  const showTeamMateTabs =
    teammate?.roles[0] === FlexTeamRoles.Teammate ||
    (teammate?.roles[0] === FlexTeamRoles.Admin && adminSplit);

  useEffect(() => {
    if (showTeamMateTabs) {
      calculateDefaultFeeSplits(setValue, leaders);
    }
  }, [leaders, setValue, showTeamMateTabs]);

  const { fields } = useFieldArray<ProTeamMember>({
    name: 'leaderFeeDetails',
    control,
  });

  const agentFeeValues = useCallback(() => {
    if (leaderFeeDetails) {
      let feeTypeTotals = {
        [FeeSplitFeeTypeEnum.RealSplit]: 0,
        [FeeSplitFeeTypeEnum.BeopFee]: 0,
        [FeeSplitFeeTypeEnum.BrokerageFee]: 0,
        [FeeSplitFeeTypeEnum.TransactionFee]: 0,
      };
      leaderFeeDetails.forEach((leaderFeeDetail: any) => {
        const { realSplit, beopFee, realFee, transactionFee } = leaderFeeDetail;
        feeTypeTotals[FeeSplitFeeTypeEnum.RealSplit] += parseFloat(
          realSplit || 0,
        );
        feeTypeTotals[FeeSplitFeeTypeEnum.BeopFee] += parseFloat(beopFee || 0);
        feeTypeTotals[FeeSplitFeeTypeEnum.BrokerageFee] += parseFloat(
          realFee || 0,
        );
        feeTypeTotals[FeeSplitFeeTypeEnum.TransactionFee] += parseFloat(
          transactionFee || 0,
        );

        setDefaultFeeSplits(setValue, feeTypeTotals);
      });
    }
  }, [leaderFeeDetails, setValue]);

  useEffect(() => {
    if (selected === 'Agent Fees') {
      agentFeeValues();
    }
  }, [agentFeeValues, selected]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onSubmit = async (values: ProTeamMember) => {
    setIsSubmitting(true);
    const overridden = values.override;

    if (teammate.roles[0] === FlexTeamRoles.Leader) {
      const feeSplits = combineFeeSplits(values.leaderFeeDetails[0]);

      const req: UpdateFlexTeamLeader = {
        leaderSplit: parseFloat(values.leaderFeeDetails[0].leaderSplit),
        type: UpdateFlexTeamMemberTypeEnum.Leader,
        feeSplits: feeSplits,
      };
      await dispatch(
        updateFlexTeamMember(team?.id!, teammate.leaderDetails?.id!, req),
      );
      dispatch(getFlexTeamDetailOverview(team?.id!));
      onClose();
    } else {
      const memberFeeSplits = [];
      const leaderSplits = [];

      for (let i = 0; i < values.leaderFeeDetails.length; i++) {
        const feeSplits = overridden
          ? combineFeeSplits(values.leaderFeeDetails[i])
          : [];

        memberFeeSplits.push({
          leaderId: values.leaderFeeDetails[i].teamLeader.value,
          feeSplits: feeSplits,
        });
        if (overridden) {
          leaderSplits.push({
            leaderId: values.leaderFeeDetails[i].teamLeader.value,
            leaderSplit: parseFloat(values.leaderFeeDetails[i].leaderSplit),
          });
        }
      }

      let teamCap: any = {};

      values.capAmount &&
        values.capAmount !== team?.teamCap?.capAmount?.toString() &&
        Object.assign(teamCap, { capAmount: parseFloat(values.capAmount) });
      values.teamPreCapFee &&
        values.teamPreCapFee !== team?.teamCap?.preCapFee?.toString() &&
        Object.assign(teamCap, { preCapFee: parseFloat(values.teamPreCapFee) });
      values.teamPostCapFee &&
        values.teamPostCapFee !== team?.teamCap?.postCapFee?.toString() &&
        Object.assign(teamCap, {
          postCapFee: parseFloat(values.teamPostCapFee),
        });

      let req: UpdateFlexTeammate = {};
      if (values.roles.value === FlexTeamRoles.Admin && !values.adminSplit) {
        req = {
          type: UpdateFlexTeamMemberTypeEnum.Teammate,
          // this is needed to reset fee splits and leader splits for a admin who does not pay leader split
          //@ts-ignore
          feeSplits: null,
          //@ts-ignore
          leaderSplits: null,
          paysLeaderSplit:
            values.roles.value === FlexTeamRoles.Admin
              ? values.adminSplit
              : true,
        };
      } else {
        req = {
          type: UpdateFlexTeamMemberTypeEnum.Teammate,
          feeSplits: memberFeeSplits,
          leaderSplits: leaderSplits,
          ...(!isEmpty(teamCap)
            ? { teamCap: teamCap }
            : {
                teamCap: null,
              }),
          realCap: parseFloat(values.realCap),
          paysLeaderSplit: true,
        };
      }
      await dispatch(
        updateFlexTeamMember(team?.id!, teammate.teammateDetails?.id!, req),
      );
      dispatch(getFlexTeamDetailOverview(team?.id!));
      onClose();
    }
    setIsSubmitting(false);
  };
  const resetToDefault = useCallback(
    (index: number) => {
      const leaderId = leaderFeeDetails[index]?.teamLeader?.value;
      const leader = leaders.find((val) => val.leaderDetails?.id === leaderId);
      setValue(
        `leaderFeeDetails.${index}.leaderSplit`,
        leader?.leaderDetails?.leaderSplit?.toString() ?? '',
      );
      setValue(
        `leaderFeeDetails.${index}.realSplit`,
        getSplitPercentage(
          FeeSplitFeeTypeEnum.RealSplit,
          leader?.leaderDetails?.feeSplits,
        ),
      );
      setValue(
        `leaderFeeDetails.${index}.beopFee`,
        getSplitPercentage(
          FeeSplitFeeTypeEnum.BeopFee,
          leader?.leaderDetails?.feeSplits,
        ),
      );
      setValue(
        `leaderFeeDetails.${index}.realFee`,
        getSplitPercentage(
          FeeSplitFeeTypeEnum.BrokerageFee,
          leader?.leaderDetails?.feeSplits,
        ),
      );
      setValue(
        `leaderFeeDetails.${index}.transactionFee`,
        getSplitPercentage(
          FeeSplitFeeTypeEnum.TransactionFee,
          leader?.leaderDetails?.feeSplits,
        ),
      );
    },
    [leaderFeeDetails, setValue, leaders],
  );

  const resetAllToDefault = useCallback(() => {
    leaderFeeDetails.forEach((_: any, index: number) => {
      resetToDefault(index);
    });
  }, [leaderFeeDetails, resetToDefault]);

  const resetToOriginal = useCallback(() => {
    leaderFeeDetails.forEach((_: any, index: number) => {
      const originalLeaderDetail = originalLeaderDetails?.[index];
      setValue(
        `leaderFeeDetails.${index}.leaderSplit`,
        originalLeaderDetail?.leaderSplit,
      );
      setValue(
        `leaderFeeDetails.${index}.realSplit`,
        originalLeaderDetail?.realSplit,
      );
      setValue(
        `leaderFeeDetails.${index}.beopFee`,
        originalLeaderDetail?.beopFee,
      );
      setValue(
        `leaderFeeDetails.${index}.realFee`,
        originalLeaderDetail?.realFee,
      );
      setValue(
        `leaderFeeDetails.${index}.transactionFee`,
        originalLeaderDetail?.transactionFee,
      );
    });
  }, [leaderFeeDetails, originalLeaderDetails, setValue]);

  useEffect(() => {
    if (!!override) {
      resetToOriginal();
      return;
    }

    resetAllToDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [override, resetAllToDefault]);

  const country = team.config?.country;
  return (
    <ZenSidebarModal
      isOpen={!!teammate}
      onClose={onClose}
      title={`${isViewing ? 'View' : 'Edit'} ${capitalizeEnum(
        teammate?.roles[0] ?? '',
      )} (${getParticipantName(teammate?.agent)})`}
    >
      {showTeamMateTabs && (
        <ZenTabs
          equalWidths
          tabs={[
            {
              name: 'Leader Fees',
              TabComponent: (
                <ZenTeamMemberLeaderFeeSplitsTab
                  setMemberToDelete={setMemberToDelete}
                  leaders={leaders}
                  teammate={teammate}
                  onClose={onClose}
                  isEdit
                  roles={{
                    value: FlexTeamRoles.Teammate,
                    label: FlexTeamRoles.Teammate,
                  }}
                  setValue={setValue}
                  readonly={readOnly}
                  isViewing={isViewing}
                  override={override}
                  control={control}
                  fields={fields}
                  resetToDefault={resetToDefault}
                  buttonText='Next'
                  trigger={trigger}
                  onSubmit={() => setSelected('Agent Fees')}
                  leaderFeeDetails={leaderFeeDetails}
                  adminSplit={
                    teammate?.roles[0] === FlexTeamRoles.Admin && adminSplit
                  }
                  removeMember={removeMember}
                  country={country as FlexTeamConfigDtoCountryEnum}
                />
              ),
            },
            {
              name: 'Agent Fees',
              TabComponent: (
                <ZenTeamMemberAgentFeesTab
                  onClose={onClose}
                  control={control}
                  team={team}
                  buttonText='Next'
                  trigger={trigger}
                  setValue={setValue}
                  onSubmit={() => setSelected('Team Fees')}
                  readOnly={readOnly}
                />
              ),
            },
            {
              name: 'Team Fees',
              TabComponent: (
                <ZenTeamMemberTeamFeesTab
                  control={control}
                  onClose={onClose}
                  setValue={setValue}
                  isSubmitting={isSubmitting}
                  buttonText='Update'
                  team={team}
                  onSubmit={handleSubmit(onSubmit)}
                  readOnly={readOnly}
                />
              ),
            },
          ]}
          selected={selected}
          onChange={setSelected}
          size='sm'
        />
      )}
      {teammate?.roles[0] === FlexTeamRoles.Leader && (
        <ZenTeamMemberLeaderFeeSplitsTab
          leaders={leaders}
          onClose={onClose}
          isEdit
          setMemberToDelete={setMemberToDelete}
          teammate={teammate}
          roles={{
            value: FlexTeamRoles.Leader,
            label: FlexTeamRoles.Leader,
          }}
          setValue={setValue}
          control={control}
          fields={fields}
          resetToDefault={resetToDefault}
          isSubmitting={isSubmitting}
          buttonText='Update'
          override={override}
          trigger={trigger}
          onSubmit={handleSubmit(onSubmit)}
          leaderFeeDetails={leaderFeeDetails}
          readonly={readOnly}
          removeMember={removeMember}
          country={country as FlexTeamConfigDtoCountryEnum}
        />
      )}
      {teammate?.roles[0] === FlexTeamRoles.Admin && !adminSplit && (
        <ZenAddProAdminTeamMemberForm
          isEdit
          setMemberToDelete={setMemberToDelete}
          teammate={teammate}
          buttonText='Update'
          control={control}
          isSubmitting={isSubmitting}
          onClose={onClose}
          onSubmit={handleSubmit(onSubmit)}
          watch={watch}
          readOnly={readOnly}
          removeMember={removeMember}
          country={country as FlexTeamConfigDtoCountryEnum}
        />
      )}
      {memberToDelete && (
        <ZenDeleteProTeamMemberModal
          teamId={team?.id!}
          memberToDelete={memberToDelete}
          onClose={() => onClose()}
          refetch={refetch}
          setMemberToDelete={() => setMemberToDelete(undefined)}
        />
      )}
    </ZenSidebarModal>
  );
};

export default ZenEditProTeamMember;
