import React from 'react';
import { cn } from '../../../utils/classUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';

export interface GeminiTabProps {
  title: string;
  count?: string;
  isActive?: boolean;
  isPill?: boolean;
}

const GeminiTab: React.FC<GeminiTabProps> = ({
  title,
  count,
  isActive = false,
  isPill = false,
}) => {
  const activeClass = isActive
    ? isPill
      ? 'bg-avatar-blue-400 text-white'
      : 'font-medium text-primary-dark border-b-rezen-blue-600'
    : isPill
    ? 'bg-grey-200 text-grey-600'
    : 'text-gray-500 font-normal';

  const commonClass = isPill
    ? 'flex px-5 py-2.5 rounded-full items-center justify-center text-center'
    : 'h-11 min-w-[118px] -mb-0.5 flex items-center justify-center text-center z-10 border-b-2 border-transparent';

  return (
    <div className={cn(commonClass, activeClass)}>
      <span className='font-zen-body-2 text-sm shrink-0'>
        {capitalizeEnum(title)}
        {!!count && ` (${count})`}
      </span>
    </div>
  );
};

export default GeminiTab;
