import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { last } from 'lodash';
import { I_DONT_KNOW_YET } from '../../../../constants/TransactionConstants';
import { useUpdateAgentIdsForTransactionBuilder } from '../../../../hooks/useUpdateAgentIdsForTransactionBuilder';
import {
  AgentParticipantInfo,
  AgentParticipantInfoRoleEnum,
  AgentsInfoRepresentationTypeEnum,
  CommissionSplit,
  ExternalParticipantInfoRoleEnum,
  FeaturesResponseEligibleEnum,
  MoneyValue,
  MoneyValueCurrencyEnum,
  PercentageValue,
  TransactionResponseTransactionTypeEnum,
} from '../../../../openapi/arrakis';
import {
  AddressRequestCountryEnum,
  AgentResponseAccountCountryEnum,
} from '../../../../openapi/yenta';
import { FeatureFlagTypeEnum, RootState, YesNoType } from '../../../../types';
import { getFullName, isCanadianUser } from '../../../../utils/AgentHelper';
import {
  displayAmount,
  displayFormattedAmountWithCurrency,
} from '../../../../utils/CurrencyUtils';
import { getParticipantName } from '../../../../utils/ParticipantHelper';
import {
  capitalizeEnum,
  formatAddress,
  formatPhoneNumber,
} from '../../../../utils/StringUtils';
import {
  getCommissionPayerDisplayName,
  getTransactionCountry,
  getTransactionPriceLabel,
  isIDontKnowChecked,
  isSaleTransaction,
  participantRoleDisplayName,
} from '../../../../utils/TransactionHelper';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenAgentWebsiteConfirmationModal from '../../Modal/ZenAgentWebsiteConfirmationModal';
import ZenConfirmationModal from '../../Modal/ZenConfirmationModal';
import ZenButton from '../../ZenButton';
import { isCommercialAgent } from '../../../../utils/FormUtils';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { showTxBuilderDoubleEnderCommissionPayerStep } from '../../../../utils/DoubleEnderUtils';
import { getTransactionRealTitleName } from '../../../../utils/RealTitleUtils';
import { createTransactionCommissionPayerStepHeader } from '../../../../utils/TransactionUtils';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
} from './ZenCreateTransactionSteps';
import ZenDisplayReviewCard, {
  TransactionReviewItems,
} from './ZenDisplayReviewCard';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';

const ZenTransactionStepReview: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({
  navigateTo,
  form: {
    watch,
    formState: { isSubmitting },
  },
  onSubmit,
}) => {
  const [confirm, setConfirm] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);
  const history = useHistory();
  const {
    userIds: { agentById },
    auth: { userDetail },
    transactionBuilder: {
      transactionBuilder,
      commissionPayerRoles,
      builderFeatures,
    },
  } = useSelector((state: RootState) => state);
  const isRealTitleFeatureAvailable = !!builderFeatures?.eligible?.includes(
    FeaturesResponseEligibleEnum.Title,
  );
  const teamLeaders = transactionBuilder?.agentsInfo?.coAgents?.filter(
    (p) => p.role === AgentParticipantInfoRoleEnum.TeamLeader,
  );

  const coAgents:
    | AgentParticipantInfo[]
    | undefined = transactionBuilder?.agentsInfo?.coAgents?.filter(
    (p) => p.role !== AgentParticipantInfoRoleEnum.TeamLeader,
  );

  const isDoubleEnderEnabled = useFeatureFlag(FeatureFlagTypeEnum.DOUBLE_ENDER);

  useUpdateAgentIdsForTransactionBuilder(transactionBuilder!);

  const isFMLSFeeApplicable = !!builderFeatures?.eligible?.includes(
    FeaturesResponseEligibleEnum.Fmls,
  );

  const isCanadaTransaction =
    getTransactionCountry(watch('address'), watch('location')) ===
    AddressRequestCountryEnum.Canada;

  const getPercentageOrAmount = (item: CommissionSplit) => {
    let moneyValue: MoneyValue | undefined = undefined;
    let percentageValue: PercentageValue | undefined = undefined;

    if (item.commission?.percentEnabled) {
      percentageValue = {
        value: +item.commission?.commissionPercent?.toFixed(2)!,
        string: `${item?.commission?.commissionPercent?.toFixed(2)!}%`,
      };
    } else if (item?.commission?.commissionAmount?.amount) {
      moneyValue = {
        amount: item?.commission?.commissionAmount?.amount,
        currency: (item.commission?.commissionAmount
          ?.currency as unknown) as MoneyValueCurrencyEnum,
      };
    }
    const participantPaymentValue = {
      money: moneyValue,
      percentage: percentageValue,
      participantId: item.participantId,
    };

    return `${participantPaymentValue.percentage?.string ?? ''} ${
      participantPaymentValue.money?.amount
        ? `$${participantPaymentValue.money?.amount}`
        : ''
    }`;
  };

  const isMLSFeeApplied = (): boolean => {
    return !!transactionBuilder?.additionalFeesInfo?.additionalFeesParticipantInfos?.find(
      (fee) => fee.description === 'FMLS Closing Fee',
    );
  };
  const getAllCommissionParticipants = () => {
    let data: TransactionReviewItems[] = [];

    transactionBuilder?.allCommissionRecipient?.forEach(
      (participant, index) => {
        const commissionSplitInfo = transactionBuilder?.commissionSplitsInfo?.find(
          (p) => p.participantId === participant?.id,
        );
        data.push({
          label: `Participant ${index + 1}:`,
          value: `${getParticipantName(
            //@ts-ignore
            participant?.agentId
              ? //@ts-ignore
                agentById[participant?.agentId!]
              : participant,
          )} ◆
        ${participantRoleDisplayName(participant.role!)}`,
        });
        data.push({
          label: 'Percentage/Amount',
          value: commissionSplitInfo
            ? getPercentageOrAmount(commissionSplitInfo!)
            : 'N/A',
        });
      },
    );
    return data;
  };

  const getAllAdditionalParticipants = () => {
    let data: TransactionReviewItems[] = [];

    transactionBuilder?.additionalFeesInfo?.additionalFeesParticipantInfos?.forEach(
      (participant) => {
        const agent = transactionBuilder?.allParticipants?.find(
          (p) => p.id === participant.participantId,
        );

        const agentName = participant
          ? // @ts-ignore
            !!agent?.agentId
            ? // @ts-ignore
              getParticipantName(agentById[agent?.agentId])
            : getParticipantName(agent)
          : 'N/A';
        data.push({
          label: 'Participant:',
          value: agentName,
        });
        data.push({
          label: 'Amount/Fee type',
          value: `${displayAmount(participant.amount, {
            hideCurrency: true,
          })}/${capitalizeEnum(participant.feeType!)}`,
        });
      },
    );
    return data;
  };

  const isSaleTypeAndCanadianTransaction =
    isSaleTransaction(transactionBuilder?.dealType!) &&
    userDetail?.accountCountry === AgentResponseAccountCountryEnum.Canada;

  const otherBrokerageInfo = transactionBuilder?.otherParticipants?.find(
    (p) => p.role === ExternalParticipantInfoRoleEnum.OtherAgent,
  );

  const sellerLawyerInfo = transactionBuilder?.otherParticipants?.find(
    (p) => p.role === ExternalParticipantInfoRoleEnum.SellersLawyer,
  );

  const buyerLawyerInfo = transactionBuilder?.otherParticipants?.find(
    (p) => p.role === ExternalParticipantInfoRoleEnum.BuyersLawyer,
  );

  const commissionPayerInfo = transactionBuilder?.commissionPayerInfo
    ?.participantId
    ? transactionBuilder?.allParticipants?.find(
        (p) => p.id === transactionBuilder.commissionPayerInfo?.participantId,
      )
    : transactionBuilder?.commissionPayerInfo;

  const role = getCommissionPayerDisplayName(
    commissionPayerRoles!,
    commissionPayerInfo,
  );

  const oneLineAddress = `${formatAddress(
    transactionBuilder?.address?.street!,
    transactionBuilder?.address?.street2,
    transactionBuilder?.address?.city!,
    capitalizeEnum(transactionBuilder?.address?.state! || ''),
    transactionBuilder?.address?.zip!,
  )} ${capitalizeEnum(userDetail?.accountCountry!)}`;

  const isIDontKnowYetOption = isIDontKnowChecked(commissionPayerInfo);

  const application = last(userDetail?.applications);
  const isOneRealTitleEnabled = !!transactionBuilder?.titleInfo?.useRealTitle;

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-3xl mx-auto flex-grow'>
        <h4 className='text-xl font-zen-title font-medium text-zen-dark-9 mb-4'>
          Review transaction information
        </h4>
        {/* ADDRESS_STEP */}
        <ZenDisplayReviewCard
          counter={<span className='print-review-counter' />}
          items={[
            { label: 'Address:', value: oneLineAddress },
            {
              label: 'Year Built:',
              value: transactionBuilder?.yearBuilt,
              display: !isCanadianUser(userDetail?.accountCountry!),
            },
            { label: 'MLS Number:', value: transactionBuilder?.mlsNumber },
            {
              label: 'Escrow Number:',
              value: transactionBuilder?.escrowNumber || 'N/A',
              display: !isCanadianUser(userDetail?.accountCountry!),
            },
            { label: 'Unit No:', value: transactionBuilder?.address?.unit },
          ]}
          onEdit={() => navigateTo(CreateTransactionStepName.ADDRESS_STEP)}
        />
        {/* TYPE_PRICE_STEP */}
        <ZenDisplayReviewCard
          counter={<span className='print-review-counter' />}
          items={[
            {
              label: 'Deal Type:',
              value: capitalizeEnum(transactionBuilder?.dealType!),
            },
            {
              label: getTransactionPriceLabel(transactionBuilder?.dealType!),
              value: displayAmount(
                transactionBuilder?.salePrice! as MoneyValue,
                {
                  hideCurrency: true,
                },
              ),
            },
            {
              label: 'Listing Commission Percentage:',
              value: `${
                transactionBuilder?.listingCommission?.commissionPercent || 0
              }%`,
              display: transactionBuilder?.listingCommission?.percentEnabled,
            },
            {
              label: `Listing Gross Commission (${transactionBuilder?.salePrice?.currency}):`,
              value: displayAmount(
                {
                  amount: +(
                    (transactionBuilder?.listingCommission?.commissionPercent! *
                      transactionBuilder?.salePrice?.amount!) /
                    100
                  ).toFixed(2),
                  currency: transactionBuilder?.salePrice
                    ?.currency! as MoneyValueCurrencyEnum,
                },
                { hideCurrency: true },
              ),
              display: transactionBuilder?.listingCommission?.percentEnabled,
            },
            {
              label: `Listing Gross Commission (${transactionBuilder?.listingCommission?.commissionAmount?.currency}):`,
              value: displayAmount(
                transactionBuilder?.listingCommission
                  ?.commissionAmount as MoneyValue,
                { hideCurrency: true },
              ),
              display: !transactionBuilder?.listingCommission?.percentEnabled,
            },
            {
              label: 'Sales Commission Percentage:',
              value: `${transactionBuilder?.saleCommission?.commissionPercent} %`,
              display: transactionBuilder?.saleCommission?.percentEnabled,
            },
            {
              label: `Sales Gross Commission (${transactionBuilder?.salePrice
                ?.currency!}):`,
              value: displayAmount(
                {
                  amount: +(
                    (transactionBuilder?.saleCommission?.commissionPercent! *
                      transactionBuilder?.salePrice?.amount!) /
                    100
                  ).toFixed(2),
                  currency: transactionBuilder?.salePrice
                    ?.currency! as MoneyValueCurrencyEnum,
                },
                { hideCurrency: true },
              ),
              display: transactionBuilder?.saleCommission?.percentEnabled,
            },
            {
              label: `Sales Gross Commission (${transactionBuilder
                ?.saleCommission?.commissionAmount?.currency!}):`,
              value: displayAmount(
                transactionBuilder?.saleCommission
                  ?.commissionAmount as MoneyValue,
                { hideCurrency: true },
              ),
              display: !transactionBuilder?.saleCommission?.percentEnabled,
            },
            {
              label: 'Acceptance Date:',
              value: transactionBuilder?.acceptanceDate,
            },
            {
              label: 'Closing Date (estimated):',
              value: transactionBuilder?.estimatedClosingDate,
            },
          ]}
          onEdit={() => navigateTo(CreateTransactionStepName.TYPE_PRICE_STEP)}
        />
        {isCanadaTransaction && (
          <ZenDisplayReviewCard
            counter={<span className='print-review-counter' />}
            items={[
              {
                label: 'Financing Conditions:',
                value: transactionBuilder?.financingConditionsExpirationDate,
              },
              {
                label: 'property Inspection:',
                value: transactionBuilder?.propertyInspectionExpirationDate,
              },
              {
                label: 'Sale of Buyers Property:',
                value: transactionBuilder?.saleOfBuyersPropertyExpirationDate,
              },
              {
                label: 'Condo Documents:',
                value: transactionBuilder?.condoDocumentsExpirationDate,
              },
              {
                label: 'Other Conditions:',
                value: transactionBuilder?.otherConditionsExpirationDate,
              },
            ]}
            onEdit={() => navigateTo(CreateTransactionStepName.FIRM_DATE_STEP)}
          />
        )}
        {/* FMLS_FEE */}
        {isFMLSFeeApplicable && (
          <ZenDisplayReviewCard
            counter={<span className='print-review-counter' />}
            items={[
              {
                label: 'Is this property listed in FMLS?',
                display: isFMLSFeeApplicable,
                value: isMLSFeeApplied()
                  ? capitalizeEnum(YesNoType.YES)
                  : capitalizeEnum(YesNoType.NO),
              },
            ]}
            onEdit={() => navigateTo(CreateTransactionStepName.FMLS_FEE)}
          />
        )}
        {/* ZERO_COMMISSION_DEAL_STEP */}
        {!!transactionBuilder?.zeroCommissionDeal && (
          <ZenDisplayReviewCard
            counter={<span className='print-review-counter' />}
            items={[
              {
                label: 'Is this a zero commission deal?',
                value: capitalizeEnum(YesNoType.YES),
              },
            ]}
            onEdit={() =>
              navigateTo(CreateTransactionStepName.ZERO_COMMISSION_DEAL_STEP)
            }
          />
        )}
        {/* BUYER_AND_SELLER_STEP */}
        <ZenDisplayReviewCard
          counter={<span className='print-review-counter' />}
          items={[
            ...(transactionBuilder?.sellers || [])?.map((seller) => {
              return {
                label: isSaleTransaction(
                  (transactionBuilder?.dealType! as unknown) as TransactionResponseTransactionTypeEnum,
                )
                  ? 'Seller:'
                  : 'Landlord:',
                value: seller.companyName
                  ? seller.companyName
                  : `${seller.firstName} ${seller.lastName}`,
              };
            }),
            ...(transactionBuilder?.buyers || [])?.map((buyer) => {
              return {
                label: isSaleTransaction(
                  (transactionBuilder?.dealType! as unknown) as TransactionResponseTransactionTypeEnum,
                )
                  ? 'Buyer:'
                  : 'Tenant:',
                value: buyer.companyName
                  ? buyer.companyName
                  : `${buyer.firstName} ${buyer.lastName}`,
              };
            }),
          ]}
          onEdit={() =>
            navigateTo(CreateTransactionStepName.BUYER_AND_SELLER_STEP)
          }
        />
        {/* OWNER_STEP  */}
        <ZenDisplayReviewCard
          counter={<span className='print-review-counter' />}
          items={[
            {
              label: 'Agent (transaction owner)',
              value: `${
                agentById[
                  transactionBuilder?.agentsInfo?.ownerAgent[0]?.agentId!
                ]?.firstName
              } ${
                agentById[
                  transactionBuilder?.agentsInfo?.ownerAgent[0]?.agentId!
                ]?.lastName
              }`,
            },
            {
              label: 'Representation Type:',
              value: capitalizeEnum(
                transactionBuilder?.agentsInfo?.representationType!,
              ),
            },
            ...(teamLeaders || []).map((agent) => {
              return {
                label: 'Team Leader:',
                value: `${agentById[agent?.agentId!]?.firstName} ${
                  agentById[agent?.agentId!]?.lastName
                }`,
              };
            }),
            ...(coAgents || []).map((agent) => {
              return {
                label: 'Co-agent:',
                value: `${agentById[agent?.agentId!]?.firstName} ${
                  agentById[agent?.agentId!]?.lastName
                }`,
              };
            }),
          ]}
          onEdit={() => navigateTo(CreateTransactionStepName.OWNER_STEP)}
        />
        {/* OTHER_BROKERAGE_STEP  */}
        {transactionBuilder?.agentsInfo?.representationType! !==
          AgentsInfoRepresentationTypeEnum.Dual && (
          <div>
            {isDoubleEnderEnabled &&
            transactionBuilder?.doubleEnderInfo?.doubleEnderAgentId ? (
              <ZenDisplayReviewCard
                counter={<span className='print-review-counter' />}
                onEdit={() =>
                  navigateTo(CreateTransactionStepName.OTHER_BROKERAGE_STEP)
                }
                items={[
                  {
                    label: 'Real Agent representing the other side',
                    value: getFullName(
                      agentById[
                        transactionBuilder.doubleEnderInfo?.doubleEnderAgentId
                      ],
                    ),
                  },
                ]}
              />
            ) : (
              <ZenDisplayReviewCard
                counter={<span className='print-review-counter' />}
                items={[
                  {
                    label: 'Brokerage Name:',
                    value: otherBrokerageInfo?.companyName || 'N/A',
                  },
                  {
                    label: 'Brokerage Address:',
                    value: otherBrokerageInfo?.address || 'N/A',
                  },
                  {
                    label: 'First Name:',
                    value: otherBrokerageInfo?.firstName || 'N/A',
                  },
                  {
                    label: 'Last Name:',
                    value: otherBrokerageInfo?.lastName || 'N/A',
                  },
                  {
                    label: 'Email Address:',
                    value: otherBrokerageInfo?.email || 'N/A',
                  },
                  {
                    label: 'Phone Number:',
                    value:
                      formatPhoneNumber(otherBrokerageInfo?.phoneNumber) ||
                      'N/A',
                  },
                ]}
                onEdit={() =>
                  navigateTo(CreateTransactionStepName.OTHER_BROKERAGE_STEP)
                }
              />
            )}
          </div>
        )}
        {/* REFERRAL_STEP  */}
        <ZenDisplayReviewCard
          counter={<span className='print-review-counter' />}
          items={[
            {
              label: 'Do you have to pay a referral fee for this transaction?',
              value: !!transactionBuilder?.referralInfo
                ?.allReferralParticipantInfo?.length
                ? capitalizeEnum(YesNoType.YES)
                : capitalizeEnum(YesNoType.NO),
            },
            { label: '', value: '' },
            ...(
              transactionBuilder?.referralInfo?.allReferralParticipantInfo || []
            ).map((participant, index) => {
              let participantName: string;

              // @ts-ignore
              if (participant.agentId) {
                // @ts-ignore
                participantName = agentById[participant.agentId]
                  ? // @ts-ignore
                    getParticipantName(agentById[participant.agentId])
                  : 'N/A';
              } else {
                participantName = getParticipantName(participant);
              }
              return {
                label: `Participant ${index + 1}:`,
                value: participantName,
              };
            }),
          ]}
          onEdit={() => navigateTo(CreateTransactionStepName.REFERRAL_STEP)}
        />
        {/* COMMISSION_STEP  */}
        <ZenDisplayReviewCard
          counter={<span className='print-review-counter' />}
          items={[
            {
              label: 'Commission Split',
              value: `${transactionBuilder?.allCommissionRecipient?.length} participants`,
            },
            {
              label: 'Total',
              value: displayFormattedAmountWithCurrency(
                transactionBuilder?.grossCommission
                  ?.commissionAmount as MoneyValue,
                false,
              ),
            },
            ...getAllCommissionParticipants(),
          ]}
          onEdit={() => navigateTo(CreateTransactionStepName.COMMISSION_STEP)}
        />
        {/* PERSONAL_DEAL_STEP */}
        {!isCommercialAgent(application, userDetail) && (
          <ZenDisplayReviewCard
            counter={<span className='print-review-counter' />}
            items={[
              {
                label: `Is this a personal deal for ${getFullName(
                  agentById[
                    transactionBuilder?.agentsInfo?.ownerAgent[0]?.agentId!
                  ],
                )}?`,
                value: transactionBuilder?.personalDeal
                  ? capitalizeEnum(YesNoType.YES)
                  : capitalizeEnum(YesNoType.NO),
              },
            ]}
            onEdit={() =>
              navigateTo(CreateTransactionStepName.PERSONAL_DEAL_STEP)
            }
          />
        )}
        {/* ADDITIONAL_FEE_STEP  */}
        <ZenDisplayReviewCard
          counter={<span className='print-review-counter' />}
          items={[
            {
              label: 'Are there any additional fees or rebates?',
              value: !!transactionBuilder?.additionalFeesInfo
                ?.additionalFeesParticipantInfos?.length
                ? capitalizeEnum(YesNoType.YES)
                : capitalizeEnum(YesNoType.NO),
            },
            { label: '', value: '' },
            ...getAllAdditionalParticipants(),
          ]}
          onEdit={() =>
            navigateTo(CreateTransactionStepName.ADDITIONAL_FEE_STEP)
          }
        />
        {/* SELLER_LAWYER */}
        {isSaleTypeAndCanadianTransaction && (
          <ZenDisplayReviewCard
            counter={<span className='print-review-counter' />}
            items={[
              {
                label: 'Who is the seller’s lawyer? (Optional)',
                value: '',
              },
              {
                label: '',
                value: '',
              },
              { label: 'First Name', value: sellerLawyerInfo?.firstName },
              { label: 'Last Name', value: sellerLawyerInfo?.lastName },
              { label: 'Email', value: sellerLawyerInfo?.email },
              { label: 'Phone Number', value: sellerLawyerInfo?.phoneNumber },
              { label: 'Company Name', value: sellerLawyerInfo?.companyName },
              { label: 'Address', value: sellerLawyerInfo?.address },
            ]}
            onEdit={() => navigateTo(CreateTransactionStepName.SELLER_LAWYER)}
          />
        )}
        {/* BUYER_LAWYER */}
        {isSaleTypeAndCanadianTransaction && (
          <ZenDisplayReviewCard
            counter={<span className='print-review-counter' />}
            items={[
              {
                label: 'Who is the buyer’s lawyer? (Optional)',
                value: '',
              },
              {
                label: '',
                value: '',
              },
              { label: 'First Name', value: buyerLawyerInfo?.firstName },
              { label: 'Last Name', value: buyerLawyerInfo?.lastName },
              { label: 'Email', value: buyerLawyerInfo?.email },
              { label: 'Phone Number', value: buyerLawyerInfo?.phoneNumber },
              { label: 'Company Name', value: buyerLawyerInfo?.companyName },
              { label: 'Address', value: buyerLawyerInfo?.address },
            ]}
            onEdit={() => navigateTo(CreateTransactionStepName.BUYER_LAWYER)}
          />
        )}
        {/* REAL_TITLE_STEP */}
        {isRealTitleFeatureAvailable && (
          <ZenDisplayReviewCard
            counter={<span className='print-review-counter' />}
            items={[
              {
                label: `Are you using ${getTransactionRealTitleName(
                  transactionBuilder?.address?.state,
                )} for this transaction?`,
                value: isOneRealTitleEnabled
                  ? capitalizeEnum(YesNoType.YES)
                  : capitalizeEnum(YesNoType.NO),
              },
            ]}
            onEdit={() =>
              navigateTo(CreateTransactionStepName.USE_REAL_TITLE_STEP)
            }
          />
        )}
        {/* COMMISSION_PAYER_STEP */}
        {showTxBuilderDoubleEnderCommissionPayerStep({
          isDoubleEnderEnabled,
          userDetail,
          formValues: watch(),
        }) && (
          <ZenDisplayReviewCard
            counter={<span className='print-review-counter' />}
            items={[
              {
                label: createTransactionCommissionPayerStepHeader(
                  isCanadianUser(userDetail?.accountCountry!),
                ),
                value: '',
              },
              {
                label: '',
                value: '',
              },
              {
                label: isOneRealTitleEnabled ? 'Title Escrow' : 'Role:',
                value: role,
                display: isIDontKnowYetOption,
              },
              {
                label: 'Other Details:',
                value: capitalizeEnum(I_DONT_KNOW_YET),
                display: isIDontKnowYetOption,
              },
              {
                label: isOneRealTitleEnabled ? 'Title Escrow' : 'Role:',
                value: role,
                display: !isIDontKnowYetOption,
              },
              {
                label: isOneRealTitleEnabled
                  ? `${getTransactionRealTitleName(
                      transactionBuilder?.address?.state,
                    )}:`
                  : 'Company Name:',
                // @ts-ignore
                value: commissionPayerInfo?.companyName || 'N/A',
                display: !isIDontKnowYetOption,
              },

              {
                label: 'First Name:',
                // @ts-ignore
                value: commissionPayerInfo?.firstName || 'N/A',
                display: !isIDontKnowYetOption,
              },

              {
                label: 'Last Name:',
                // @ts-ignore
                value: commissionPayerInfo?.lastName || 'N/A',
                display: !isIDontKnowYetOption,
              },

              {
                label: 'Email Address:',
                // @ts-ignore
                value: commissionPayerInfo?.email || 'N/A',
                display: !isIDontKnowYetOption,
              },
              {
                label: 'Phone Number',
                // @ts-ignore
                value: formatPhoneNumber(commissionPayerInfo?.phoneNumber),
                display: !isIDontKnowYetOption,
              },
            ]}
            onEdit={() =>
              navigateTo(CreateTransactionStepName.COMMISSION_PAYER_STEP)
            }
          />
        )}
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-3xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Cancel'
              onClick={() => setCancel(true)}
            />
            <ZenButton
              isFullWidth
              label='Create Transaction'
              onClick={() => setConfirm(true)}
            />
          </div>
        </div>
      </div>
      <ZenConfirmationModal
        isOpen={confirm}
        onClose={() => setConfirm(false)}
        confirmButtonText='Yes'
        isSubmitting={isSubmitting}
        isDisabled={isSubmitting}
        onConfirm={async () => {
          await onSubmit();
          setConfirm(false);
        }}
        hideIcon
        size='large'
        variant='primary'
        title='Create transaction?'
        subtitle='You’ll be able to edit any information once this transaction is created.'
      />
      <ZenAgentWebsiteConfirmationModal
        variant='cancel'
        size='large'
        title='Delete transaction?'
        subtitle='The information won’t be saved and you’ll go back to the the transaction list'
        isOpen={cancel}
        onClose={() => setCancel(false)}
      >
        <div className='flex flex-row justify-between space-x-5 mt-10'>
          <ZenButton
            isFullWidth
            variant='secondary-light-outline'
            label='No'
            onClick={() => setCancel(false)}
          />
          <ZenButton
            isFullWidth
            label='Delete'
            variant='danger'
            type='submit'
            onClick={() => history.push('/transactions')}
          />
        </div>
      </ZenAgentWebsiteConfirmationModal>
    </div>
  );
};

export default withCreateTransactionProgress(ZenTransactionStepReview);
