import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { RevShareControllerApi } from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenControlledTextAreaInput from '../Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../ZenSidebarModalForm';

interface ZenReprocessRevshareFormSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  transactionId: string;
  cancelReason: string;
}

const ZenReprocessRevshareFormSidebarModal: React.FC<ZenReprocessRevshareFormSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (values: FormData) => {
    try {
      await new RevShareControllerApi(
        getArrakisConfiguration(),
      ).reCalculateAndProcessRevshareForTransaction({
        transactionId: values.transactionId,
        cancelReason: values.cancelReason,
      });
      onClose();
      dispatch(showSuccessToast('Reprocessed revshare successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error reprocessing revshares', e, {
        data: {
          transactionId: values.transactionId,
          cancelReason: values.cancelReason,
        },
      });
      dispatch(
        showErrorToast(
          'We had a problem reprocessing revshare for the transaction.',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <ZenSidebarModalForm
      title='Reprocess Revshare'
      isOpen={isOpen}
      onClose={onClose}
      actionTitle='Reprocess'
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <div className='mt-5'>
        <ZenControlledTextInput<FormData, 'transactionId'>
          label='Transaction ID'
          name='transactionId'
          control={control}
          rules={{ required: 'Please provide a transaction ID' }}
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledTextAreaInput<FormData, 'cancelReason'>
          control={control}
          label='Cancel Reason'
          name='cancelReason'
          placeholder='A short reason to cancel'
          rules={{ required: 'Please provide a cancel reason' }}
          rows={2}
          isRequired
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenReprocessRevshareFormSidebarModal;
