/* tslint:disable */
/* eslint-disable */
/**
 * Avalon API
 * Avalon Main Application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcceptInviteRequest
 */
export interface AcceptInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof AcceptInviteRequest
     */
    'paymentMethod': string;
}
/**
 * 
 * @export
 * @interface AddressResponse
 */
export interface AddressResponse {
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'country'?: AddressResponseCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'streetAddress1'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'stateOrProvince'?: AddressResponseStateOrProvinceEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'zipOrPostalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'oneLine'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AddressResponseCountryEnum {
    UnitedStates = 'UNITED_STATES',
    Canada = 'CANADA'
}
/**
    * @export
    * @enum {string}
    */
export enum AddressResponseStateOrProvinceEnum {
    Alabama = 'ALABAMA',
    Alaska = 'ALASKA',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    California = 'CALIFORNIA',
    Colorado = 'COLORADO',
    Connecticut = 'CONNECTICUT',
    Delaware = 'DELAWARE',
    DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
    Florida = 'FLORIDA',
    Georgia = 'GEORGIA',
    Hawaii = 'HAWAII',
    Idaho = 'IDAHO',
    Illinois = 'ILLINOIS',
    Indiana = 'INDIANA',
    Iowa = 'IOWA',
    Kansas = 'KANSAS',
    Kentucky = 'KENTUCKY',
    Louisiana = 'LOUISIANA',
    Maine = 'MAINE',
    Maryland = 'MARYLAND',
    Massachusetts = 'MASSACHUSETTS',
    Michigan = 'MICHIGAN',
    Minnesota = 'MINNESOTA',
    Mississippi = 'MISSISSIPPI',
    Missouri = 'MISSOURI',
    Montana = 'MONTANA',
    Nebraska = 'NEBRASKA',
    Nevada = 'NEVADA',
    NewHampshire = 'NEW_HAMPSHIRE',
    NewJersey = 'NEW_JERSEY',
    NewMexico = 'NEW_MEXICO',
    NewYork = 'NEW_YORK',
    NorthCarolina = 'NORTH_CAROLINA',
    NorthDakota = 'NORTH_DAKOTA',
    Ohio = 'OHIO',
    Oklahoma = 'OKLAHOMA',
    Oregon = 'OREGON',
    Pennsylvania = 'PENNSYLVANIA',
    RhodeIsland = 'RHODE_ISLAND',
    SouthCarolina = 'SOUTH_CAROLINA',
    SouthDakota = 'SOUTH_DAKOTA',
    Tennessee = 'TENNESSEE',
    Texas = 'TEXAS',
    Utah = 'UTAH',
    Vermont = 'VERMONT',
    Virginia = 'VIRGINIA',
    Washington = 'WASHINGTON',
    WestVirginia = 'WEST_VIRGINIA',
    Wisconsin = 'WISCONSIN',
    Wyoming = 'WYOMING',
    PuertoRico = 'PUERTO_RICO',
    Alberta = 'ALBERTA',
    BritishColumbia = 'BRITISH_COLUMBIA',
    Manitoba = 'MANITOBA',
    NewBrunswick = 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia = 'NOVA_SCOTIA',
    Ontario = 'ONTARIO',
    PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
    Quebec = 'QUEBEC',
    Saskatchewan = 'SASKATCHEWAN'
}

/**
 * 
 * @export
 * @interface AdministrativeAreaResponse
 */
export interface AdministrativeAreaResponse {
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdministrativeAreaResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdministrativeAreaResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaResponse
     */
    'country'?: AdministrativeAreaResponseCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaResponse
     */
    'stateOrProvince'?: AdministrativeAreaResponseStateOrProvinceEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AdministrativeAreaResponse
     */
    'signupFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AdministrativeAreaResponse
     */
    'licenseTransferFee'?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof AdministrativeAreaResponse
     */
    'agentCanTransferLicense'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum AdministrativeAreaResponseCountryEnum {
    UnitedStates = 'UNITED_STATES',
    Canada = 'CANADA'
}
/**
    * @export
    * @enum {string}
    */
export enum AdministrativeAreaResponseStateOrProvinceEnum {
    Alabama = 'ALABAMA',
    Alaska = 'ALASKA',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    California = 'CALIFORNIA',
    Colorado = 'COLORADO',
    Connecticut = 'CONNECTICUT',
    Delaware = 'DELAWARE',
    DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
    Florida = 'FLORIDA',
    Georgia = 'GEORGIA',
    Hawaii = 'HAWAII',
    Idaho = 'IDAHO',
    Illinois = 'ILLINOIS',
    Indiana = 'INDIANA',
    Iowa = 'IOWA',
    Kansas = 'KANSAS',
    Kentucky = 'KENTUCKY',
    Louisiana = 'LOUISIANA',
    Maine = 'MAINE',
    Maryland = 'MARYLAND',
    Massachusetts = 'MASSACHUSETTS',
    Michigan = 'MICHIGAN',
    Minnesota = 'MINNESOTA',
    Mississippi = 'MISSISSIPPI',
    Missouri = 'MISSOURI',
    Montana = 'MONTANA',
    Nebraska = 'NEBRASKA',
    Nevada = 'NEVADA',
    NewHampshire = 'NEW_HAMPSHIRE',
    NewJersey = 'NEW_JERSEY',
    NewMexico = 'NEW_MEXICO',
    NewYork = 'NEW_YORK',
    NorthCarolina = 'NORTH_CAROLINA',
    NorthDakota = 'NORTH_DAKOTA',
    Ohio = 'OHIO',
    Oklahoma = 'OKLAHOMA',
    Oregon = 'OREGON',
    Pennsylvania = 'PENNSYLVANIA',
    RhodeIsland = 'RHODE_ISLAND',
    SouthCarolina = 'SOUTH_CAROLINA',
    SouthDakota = 'SOUTH_DAKOTA',
    Tennessee = 'TENNESSEE',
    Texas = 'TEXAS',
    Utah = 'UTAH',
    Vermont = 'VERMONT',
    Virginia = 'VIRGINIA',
    Washington = 'WASHINGTON',
    WestVirginia = 'WEST_VIRGINIA',
    Wisconsin = 'WISCONSIN',
    Wyoming = 'WYOMING',
    PuertoRico = 'PUERTO_RICO',
    Alberta = 'ALBERTA',
    BritishColumbia = 'BRITISH_COLUMBIA',
    Manitoba = 'MANITOBA',
    NewBrunswick = 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia = 'NOVA_SCOTIA',
    Ontario = 'ONTARIO',
    PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
    Quebec = 'QUEBEC',
    Saskatchewan = 'SASKATCHEWAN'
}

/**
 * 
 * @export
 * @interface AgentInvitesResponse
 */
export interface AgentInvitesResponse {
    /**
     * 
     * @type {Array<GroupedInviteResponse>}
     * @memberof AgentInvitesResponse
     */
    'invites'?: Array<GroupedInviteResponse>;
    /**
     * 
     * @type {Array<GroupedMembershipResponse>}
     * @memberof AgentInvitesResponse
     */
    'memberships'?: Array<GroupedMembershipResponse>;
}
/**
 * 
 * @export
 * @interface BankAccountResponse
 */
export interface BankAccountResponse {
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof BankAccountResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof BankAccountResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'bankName'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof BankAccountResponse
     */
    'bankAddress'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'bankRoutingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'wireRoutingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'accountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'accountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'ftniSettlementId'?: string;
}
/**
 * 
 * @export
 * @interface CapLevelCriterionResponse
 */
export interface CapLevelCriterionResponse {
    /**
     * 
     * @type {string}
     * @memberof CapLevelCriterionResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CapLevelCriterionResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof CapLevelCriterionResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CapLevelCriterionResponse
     */
    'capLevel'?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof CapLevelCriterionResponse
     */
    'capped'?: boolean;
}
/**
 * 
 * @export
 * @interface CapLevelsCriteriaResponse
 */
export interface CapLevelsCriteriaResponse {
    /**
     * 
     * @type {string}
     * @memberof CapLevelsCriteriaResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CapLevelsCriteriaResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof CapLevelsCriteriaResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CapLevelsCriteriaResponse
     */
    'type'?: CapLevelsCriteriaResponseTypeEnum;
    /**
     * 
     * @type {Array<CapLevelCriterionResponse>}
     * @memberof CapLevelsCriteriaResponse
     */
    'capLevelsCriteria'?: Array<CapLevelCriterionResponse>;
}

/**
    * @export
    * @enum {string}
    */
export enum CapLevelsCriteriaResponseTypeEnum {
    Tenure = 'TENURE',
    Transactions = 'TRANSACTIONS',
    CapLevels = 'CAP_LEVELS',
    EliteStatus = 'ELITE_STATUS'
}

/**
 * 
 * @export
 * @interface CapLevelsCriteriaResponseAllOf
 */
export interface CapLevelsCriteriaResponseAllOf {
    /**
     * 
     * @type {Array<CapLevelCriterionResponse>}
     * @memberof CapLevelsCriteriaResponseAllOf
     */
    'capLevelsCriteria'?: Array<CapLevelCriterionResponse>;
}
/**
 * 
 * @export
 * @interface CompanyResponse
 */
export interface CompanyResponse {
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'ein'?: string;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof CompanyResponse
     */
    'administrativeArea'?: AdministrativeAreaResponse;
    /**
     * 
     * @type {AddressResponse}
     * @memberof CompanyResponse
     */
    'address'?: AddressResponse;
}
/**
 * 
 * @export
 * @interface CreateCapLevelCriterionRequest
 */
export interface CreateCapLevelCriterionRequest {
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCapLevelCriterionRequest
     */
    'capLevel': MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCapLevelCriterionRequest
     */
    'capped': boolean;
}
/**
 * 
 * @export
 * @interface CreateCapLevelsCriteriaRequest
 */
export interface CreateCapLevelsCriteriaRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCapLevelsCriteriaRequest
     */
    'type': CreateCapLevelsCriteriaRequestTypeEnum;
    /**
     * 
     * @type {Array<CreateCapLevelCriterionRequest>}
     * @memberof CreateCapLevelsCriteriaRequest
     */
    'capLevelsCriteria': Array<CreateCapLevelCriterionRequest>;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateCapLevelsCriteriaRequestTypeEnum {
    Tenure = 'TENURE',
    Transactions = 'TRANSACTIONS',
    CapLevels = 'CAP_LEVELS',
    EliteStatus = 'ELITE_STATUS'
}

/**
 * 
 * @export
 * @interface CreateCapLevelsCriteriaRequestAllOf
 */
export interface CreateCapLevelsCriteriaRequestAllOf {
    /**
     * 
     * @type {Array<CreateCapLevelCriterionRequest>}
     * @memberof CreateCapLevelsCriteriaRequestAllOf
     */
    'capLevelsCriteria'?: Array<CreateCapLevelCriterionRequest>;
}
/**
 * 
 * @export
 * @interface CreateEligibilityCriterionRequest
 */
export interface CreateEligibilityCriterionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateEligibilityCriterionRequest
     */
    'type': CreateEligibilityCriterionRequestTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateEligibilityCriterionRequestTypeEnum {
    Tenure = 'TENURE',
    Transactions = 'TRANSACTIONS',
    CapLevels = 'CAP_LEVELS',
    EliteStatus = 'ELITE_STATUS'
}

/**
 * 
 * @export
 * @interface CreateEliteStatusCriterionRequest
 */
export interface CreateEliteStatusCriterionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateEliteStatusCriterionRequest
     */
    'type': CreateEliteStatusCriterionRequestTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEliteStatusCriterionRequest
     */
    'achieved': boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateEliteStatusCriterionRequestTypeEnum {
    Tenure = 'TENURE',
    Transactions = 'TRANSACTIONS',
    CapLevels = 'CAP_LEVELS',
    EliteStatus = 'ELITE_STATUS'
}

/**
 * 
 * @export
 * @interface CreateEliteStatusCriterionRequestAllOf
 */
export interface CreateEliteStatusCriterionRequestAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEliteStatusCriterionRequestAllOf
     */
    'achieved'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateInviteRequest
 */
export interface CreateInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateInviteRequest
     */
    'agentId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInviteRequest
     */
    'tierId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateInviteRequest
     */
    'interests': number;
}
/**
 * 
 * @export
 * @interface CreateJointVentureFormResponse
 */
export interface CreateJointVentureFormResponse {
    /**
     * 
     * @type {Array<CompanyResponse>}
     * @memberof CreateJointVentureFormResponse
     */
    'companies'?: Array<CompanyResponse>;
    /**
     * 
     * @type {Array<BankAccountResponse>}
     * @memberof CreateJointVentureFormResponse
     */
    'bankAccounts'?: Array<BankAccountResponse>;
}
/**
 * 
 * @export
 * @interface CreateJointVentureRequest
 */
export interface CreateJointVentureRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateJointVentureRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateJointVentureRequest
     */
    'realEquity': number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateJointVentureRequest
     */
    'issuePrice': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CreateJointVentureRequest
     */
    'shares': number;
    /**
     * 
     * @type {string}
     * @memberof CreateJointVentureRequest
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateJointVentureRequest
     */
    'bankAccountId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateJointVentureRequest
     */
    'states': Array<CreateJointVentureRequestStatesEnum>;
    /**
     * 
     * @type {Array<CreateTierRequest>}
     * @memberof CreateJointVentureRequest
     */
    'tiers': Array<CreateTierRequest>;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateJointVentureRequestStatesEnum {
    Alabama = 'ALABAMA',
    Alaska = 'ALASKA',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    California = 'CALIFORNIA',
    Colorado = 'COLORADO',
    Connecticut = 'CONNECTICUT',
    Delaware = 'DELAWARE',
    DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
    Florida = 'FLORIDA',
    Georgia = 'GEORGIA',
    Hawaii = 'HAWAII',
    Idaho = 'IDAHO',
    Illinois = 'ILLINOIS',
    Indiana = 'INDIANA',
    Iowa = 'IOWA',
    Kansas = 'KANSAS',
    Kentucky = 'KENTUCKY',
    Louisiana = 'LOUISIANA',
    Maine = 'MAINE',
    Maryland = 'MARYLAND',
    Massachusetts = 'MASSACHUSETTS',
    Michigan = 'MICHIGAN',
    Minnesota = 'MINNESOTA',
    Mississippi = 'MISSISSIPPI',
    Missouri = 'MISSOURI',
    Montana = 'MONTANA',
    Nebraska = 'NEBRASKA',
    Nevada = 'NEVADA',
    NewHampshire = 'NEW_HAMPSHIRE',
    NewJersey = 'NEW_JERSEY',
    NewMexico = 'NEW_MEXICO',
    NewYork = 'NEW_YORK',
    NorthCarolina = 'NORTH_CAROLINA',
    NorthDakota = 'NORTH_DAKOTA',
    Ohio = 'OHIO',
    Oklahoma = 'OKLAHOMA',
    Oregon = 'OREGON',
    Pennsylvania = 'PENNSYLVANIA',
    RhodeIsland = 'RHODE_ISLAND',
    SouthCarolina = 'SOUTH_CAROLINA',
    SouthDakota = 'SOUTH_DAKOTA',
    Tennessee = 'TENNESSEE',
    Texas = 'TEXAS',
    Utah = 'UTAH',
    Vermont = 'VERMONT',
    Virginia = 'VIRGINIA',
    Washington = 'WASHINGTON',
    WestVirginia = 'WEST_VIRGINIA',
    Wisconsin = 'WISCONSIN',
    Wyoming = 'WYOMING',
    PuertoRico = 'PUERTO_RICO',
    Alberta = 'ALBERTA',
    BritishColumbia = 'BRITISH_COLUMBIA',
    Manitoba = 'MANITOBA',
    NewBrunswick = 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia = 'NOVA_SCOTIA',
    Ontario = 'ONTARIO',
    PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
    Quebec = 'QUEBEC',
    Saskatchewan = 'SASKATCHEWAN'
}

/**
 * 
 * @export
 * @interface CreateTenureCriterionRequest
 */
export interface CreateTenureCriterionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTenureCriterionRequest
     */
    'type': CreateTenureCriterionRequestTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateTenureCriterionRequest
     */
    'minimumMonths': number;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateTenureCriterionRequestTypeEnum {
    Tenure = 'TENURE',
    Transactions = 'TRANSACTIONS',
    CapLevels = 'CAP_LEVELS',
    EliteStatus = 'ELITE_STATUS'
}

/**
 * 
 * @export
 * @interface CreateTenureCriterionRequestAllOf
 */
export interface CreateTenureCriterionRequestAllOf {
    /**
     * 
     * @type {number}
     * @memberof CreateTenureCriterionRequestAllOf
     */
    'minimumMonths'?: number;
}
/**
 * 
 * @export
 * @interface CreateTierRequest
 */
export interface CreateTierRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTierRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTierRequest
     */
    'poolShare': number;
    /**
     * 
     * @type {number}
     * @memberof CreateTierRequest
     */
    'buyInShares': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTierRequest
     */
    'autoInvite': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateTierRequest
     */
    'colorCode': string;
    /**
     * 
     * @type {Array<CreateTierRequestEligibilityCriteriaInner>}
     * @memberof CreateTierRequest
     */
    'eligibilityCriteria'?: Array<CreateTierRequestEligibilityCriteriaInner>;
}
/**
 * @type CreateTierRequestEligibilityCriteriaInner
 * @export
 */
export type CreateTierRequestEligibilityCriteriaInner = CreateCapLevelsCriteriaRequest | CreateEliteStatusCriterionRequest | CreateTenureCriterionRequest | CreateTransactionsCriterionRequest;

/**
 * 
 * @export
 * @interface CreateTransactionsCriterionRequest
 */
export interface CreateTransactionsCriterionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionsCriterionRequest
     */
    'type': CreateTransactionsCriterionRequestTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionsCriterionRequest
     */
    'minimumCount': number;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateTransactionsCriterionRequestTypeEnum {
    Tenure = 'TENURE',
    Transactions = 'TRANSACTIONS',
    CapLevels = 'CAP_LEVELS',
    EliteStatus = 'ELITE_STATUS'
}

/**
 * 
 * @export
 * @interface CreateTransactionsCriterionRequestAllOf
 */
export interface CreateTransactionsCriterionRequestAllOf {
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionsCriterionRequestAllOf
     */
    'minimumCount'?: number;
}
/**
 * 
 * @export
 * @interface EligibilityCriterionResponse
 */
export interface EligibilityCriterionResponse {
    /**
     * 
     * @type {string}
     * @memberof EligibilityCriterionResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EligibilityCriterionResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof EligibilityCriterionResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof EligibilityCriterionResponse
     */
    'type'?: EligibilityCriterionResponseTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EligibilityCriterionResponseTypeEnum {
    Tenure = 'TENURE',
    Transactions = 'TRANSACTIONS',
    CapLevels = 'CAP_LEVELS',
    EliteStatus = 'ELITE_STATUS'
}

/**
 * 
 * @export
 * @interface EliteStatusCriterionResponse
 */
export interface EliteStatusCriterionResponse {
    /**
     * 
     * @type {string}
     * @memberof EliteStatusCriterionResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EliteStatusCriterionResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof EliteStatusCriterionResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof EliteStatusCriterionResponse
     */
    'type'?: EliteStatusCriterionResponseTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EliteStatusCriterionResponse
     */
    'achieved'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum EliteStatusCriterionResponseTypeEnum {
    Tenure = 'TENURE',
    Transactions = 'TRANSACTIONS',
    CapLevels = 'CAP_LEVELS',
    EliteStatus = 'ELITE_STATUS'
}

/**
 * 
 * @export
 * @interface GroupedInviteResponse
 */
export interface GroupedInviteResponse {
    /**
     * 
     * @type {string}
     * @memberof GroupedInviteResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupedInviteResponse
     */
    'status'?: GroupedInviteResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof GroupedInviteResponse
     */
    'interests'?: number;
    /**
     * 
     * @type {TierResponse}
     * @memberof GroupedInviteResponse
     */
    'tier'?: TierResponse;
    /**
     * 
     * @type {JointVentureResponse}
     * @memberof GroupedInviteResponse
     */
    'jointVenture'?: JointVentureResponse;
}

/**
    * @export
    * @enum {string}
    */
export enum GroupedInviteResponseStatusEnum {
    Created = 'CREATED',
    Viewed = 'VIEWED',
    Declined = 'DECLINED',
    Accepted = 'ACCEPTED',
    Withdrawn = 'WITHDRAWN'
}

/**
 * 
 * @export
 * @interface GroupedMembershipResponse
 */
export interface GroupedMembershipResponse {
    /**
     * 
     * @type {GroupedInviteResponse}
     * @memberof GroupedMembershipResponse
     */
    'invite'?: GroupedInviteResponse;
    /**
     * 
     * @type {string}
     * @memberof GroupedMembershipResponse
     */
    'status'?: GroupedMembershipResponseStatusEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof GroupedMembershipResponse
     */
    'paymentAmount'?: MoneyValue;
}

/**
    * @export
    * @enum {string}
    */
export enum GroupedMembershipResponseStatusEnum {
    PendingPayment = 'PENDING_PAYMENT',
    PaymentFailed = 'PAYMENT_FAILED',
    PendingSignature = 'PENDING_SIGNATURE',
    Active = 'ACTIVE'
}

/**
 * 
 * @export
 * @interface InviteResponse
 */
export interface InviteResponse {
    /**
     * 
     * @type {string}
     * @memberof InviteResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof InviteResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof InviteResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof InviteResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteResponse
     */
    'type'?: InviteResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InviteResponse
     */
    'status'?: InviteResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof InviteResponse
     */
    'lastImpressionAt'?: number;
    /**
     * 
     * @type {TierResponse}
     * @memberof InviteResponse
     */
    'tier'?: TierResponse;
    /**
     * 
     * @type {JointVentureResponse}
     * @memberof InviteResponse
     */
    'jointVenture'?: JointVentureResponse;
    /**
     * 
     * @type {MembershipResponse}
     * @memberof InviteResponse
     */
    'membership'?: MembershipResponse;
    /**
     * 
     * @type {number}
     * @memberof InviteResponse
     */
    'interests'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum InviteResponseTypeEnum {
    Automated = 'AUTOMATED',
    Manual = 'MANUAL'
}
/**
    * @export
    * @enum {string}
    */
export enum InviteResponseStatusEnum {
    Created = 'CREATED',
    Viewed = 'VIEWED',
    Declined = 'DECLINED',
    Accepted = 'ACCEPTED',
    Withdrawn = 'WITHDRAWN'
}

/**
 * 
 * @export
 * @interface JointVentureResponse
 */
export interface JointVentureResponse {
    /**
     * 
     * @type {string}
     * @memberof JointVentureResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof JointVentureResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof JointVentureResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof JointVentureResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof JointVentureResponse
     */
    'realEquity'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof JointVentureResponse
     */
    'issuePrice'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof JointVentureResponse
     */
    'shares'?: number;
    /**
     * 
     * @type {string}
     * @memberof JointVentureResponse
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof JointVentureResponse
     */
    'bankAccountId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JointVentureResponse
     */
    'states'?: Array<JointVentureResponseStatesEnum>;
    /**
     * 
     * @type {Array<TierResponse>}
     * @memberof JointVentureResponse
     */
    'tiers'?: Array<TierResponse>;
    /**
     * 
     * @type {OccupancyResponse}
     * @memberof JointVentureResponse
     */
    'occupancy'?: OccupancyResponse;
}

/**
    * @export
    * @enum {string}
    */
export enum JointVentureResponseStatesEnum {
    Alabama = 'ALABAMA',
    Alaska = 'ALASKA',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    California = 'CALIFORNIA',
    Colorado = 'COLORADO',
    Connecticut = 'CONNECTICUT',
    Delaware = 'DELAWARE',
    DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
    Florida = 'FLORIDA',
    Georgia = 'GEORGIA',
    Hawaii = 'HAWAII',
    Idaho = 'IDAHO',
    Illinois = 'ILLINOIS',
    Indiana = 'INDIANA',
    Iowa = 'IOWA',
    Kansas = 'KANSAS',
    Kentucky = 'KENTUCKY',
    Louisiana = 'LOUISIANA',
    Maine = 'MAINE',
    Maryland = 'MARYLAND',
    Massachusetts = 'MASSACHUSETTS',
    Michigan = 'MICHIGAN',
    Minnesota = 'MINNESOTA',
    Mississippi = 'MISSISSIPPI',
    Missouri = 'MISSOURI',
    Montana = 'MONTANA',
    Nebraska = 'NEBRASKA',
    Nevada = 'NEVADA',
    NewHampshire = 'NEW_HAMPSHIRE',
    NewJersey = 'NEW_JERSEY',
    NewMexico = 'NEW_MEXICO',
    NewYork = 'NEW_YORK',
    NorthCarolina = 'NORTH_CAROLINA',
    NorthDakota = 'NORTH_DAKOTA',
    Ohio = 'OHIO',
    Oklahoma = 'OKLAHOMA',
    Oregon = 'OREGON',
    Pennsylvania = 'PENNSYLVANIA',
    RhodeIsland = 'RHODE_ISLAND',
    SouthCarolina = 'SOUTH_CAROLINA',
    SouthDakota = 'SOUTH_DAKOTA',
    Tennessee = 'TENNESSEE',
    Texas = 'TEXAS',
    Utah = 'UTAH',
    Vermont = 'VERMONT',
    Virginia = 'VIRGINIA',
    Washington = 'WASHINGTON',
    WestVirginia = 'WEST_VIRGINIA',
    Wisconsin = 'WISCONSIN',
    Wyoming = 'WYOMING',
    PuertoRico = 'PUERTO_RICO',
    Alberta = 'ALBERTA',
    BritishColumbia = 'BRITISH_COLUMBIA',
    Manitoba = 'MANITOBA',
    NewBrunswick = 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia = 'NOVA_SCOTIA',
    Ontario = 'ONTARIO',
    PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
    Quebec = 'QUEBEC',
    Saskatchewan = 'SASKATCHEWAN'
}

/**
 * 
 * @export
 * @interface MembershipResponse
 */
export interface MembershipResponse {
    /**
     * 
     * @type {string}
     * @memberof MembershipResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MembershipResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof MembershipResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {InviteResponse}
     * @memberof MembershipResponse
     */
    'invite'?: InviteResponse;
    /**
     * 
     * @type {string}
     * @memberof MembershipResponse
     */
    'stripeCustomerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipResponse
     */
    'stripeChargeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipResponse
     */
    'status'?: MembershipResponseStatusEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof MembershipResponse
     */
    'paymentAmount'?: MoneyValue;
}

/**
    * @export
    * @enum {string}
    */
export enum MembershipResponseStatusEnum {
    PendingPayment = 'PENDING_PAYMENT',
    PaymentFailed = 'PAYMENT_FAILED',
    PendingSignature = 'PENDING_SIGNATURE',
    Active = 'ACTIVE'
}

/**
 * 
 * @export
 * @interface MoneyValue
 */
export interface MoneyValue {
    /**
     * 
     * @type {number}
     * @memberof MoneyValue
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MoneyValue
     */
    'currency'?: MoneyValueCurrencyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MoneyValueCurrencyEnum {
    Usd = 'USD',
    Cad = 'CAD'
}

/**
 * 
 * @export
 * @interface OccupancyResponse
 */
export interface OccupancyResponse {
    /**
     * 
     * @type {number}
     * @memberof OccupancyResponse
     */
    'capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof OccupancyResponse
     */
    'occupied'?: number;
    /**
     * 
     * @type {number}
     * @memberof OccupancyResponse
     */
    'utilization'?: number;
}
/**
 * 
 * @export
 * @interface SendInviteFormResponse
 */
export interface SendInviteFormResponse {
    /**
     * 
     * @type {Array<JointVentureResponse>}
     * @memberof SendInviteFormResponse
     */
    'jointVentures'?: Array<JointVentureResponse>;
}
/**
 * 
 * @export
 * @interface TenureCriterionResponse
 */
export interface TenureCriterionResponse {
    /**
     * 
     * @type {string}
     * @memberof TenureCriterionResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TenureCriterionResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenureCriterionResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenureCriterionResponse
     */
    'type'?: TenureCriterionResponseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TenureCriterionResponse
     */
    'minimumMonths'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TenureCriterionResponseTypeEnum {
    Tenure = 'TENURE',
    Transactions = 'TRANSACTIONS',
    CapLevels = 'CAP_LEVELS',
    EliteStatus = 'ELITE_STATUS'
}

/**
 * 
 * @export
 * @interface TierResponse
 */
export interface TierResponse {
    /**
     * 
     * @type {string}
     * @memberof TierResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TierResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof TierResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof TierResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof TierResponse
     */
    'poolShare'?: number;
    /**
     * 
     * @type {number}
     * @memberof TierResponse
     */
    'buyInShares'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TierResponse
     */
    'autoInvite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TierResponse
     */
    'colorCode'?: string;
    /**
     * 
     * @type {Array<TierResponseEligibilityCriteriaInner>}
     * @memberof TierResponse
     */
    'eligibilityCriteria'?: Array<TierResponseEligibilityCriteriaInner>;
    /**
     * 
     * @type {OccupancyResponse}
     * @memberof TierResponse
     */
    'occupancy'?: OccupancyResponse;
}
/**
 * @type TierResponseEligibilityCriteriaInner
 * @export
 */
export type TierResponseEligibilityCriteriaInner = CapLevelsCriteriaResponse | EliteStatusCriterionResponse | TenureCriterionResponse | TransactionsCriterionResponse;

/**
 * 
 * @export
 * @interface TitleServiceAvailability
 */
export interface TitleServiceAvailability {
    /**
     * 
     * @type {string}
     * @memberof TitleServiceAvailability
     */
    'stateOrProvince': TitleServiceAvailabilityStateOrProvinceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TitleServiceAvailability
     */
    'available'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum TitleServiceAvailabilityStateOrProvinceEnum {
    Alabama = 'ALABAMA',
    Alaska = 'ALASKA',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    California = 'CALIFORNIA',
    Colorado = 'COLORADO',
    Connecticut = 'CONNECTICUT',
    Delaware = 'DELAWARE',
    DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
    Florida = 'FLORIDA',
    Georgia = 'GEORGIA',
    Hawaii = 'HAWAII',
    Idaho = 'IDAHO',
    Illinois = 'ILLINOIS',
    Indiana = 'INDIANA',
    Iowa = 'IOWA',
    Kansas = 'KANSAS',
    Kentucky = 'KENTUCKY',
    Louisiana = 'LOUISIANA',
    Maine = 'MAINE',
    Maryland = 'MARYLAND',
    Massachusetts = 'MASSACHUSETTS',
    Michigan = 'MICHIGAN',
    Minnesota = 'MINNESOTA',
    Mississippi = 'MISSISSIPPI',
    Missouri = 'MISSOURI',
    Montana = 'MONTANA',
    Nebraska = 'NEBRASKA',
    Nevada = 'NEVADA',
    NewHampshire = 'NEW_HAMPSHIRE',
    NewJersey = 'NEW_JERSEY',
    NewMexico = 'NEW_MEXICO',
    NewYork = 'NEW_YORK',
    NorthCarolina = 'NORTH_CAROLINA',
    NorthDakota = 'NORTH_DAKOTA',
    Ohio = 'OHIO',
    Oklahoma = 'OKLAHOMA',
    Oregon = 'OREGON',
    Pennsylvania = 'PENNSYLVANIA',
    RhodeIsland = 'RHODE_ISLAND',
    SouthCarolina = 'SOUTH_CAROLINA',
    SouthDakota = 'SOUTH_DAKOTA',
    Tennessee = 'TENNESSEE',
    Texas = 'TEXAS',
    Utah = 'UTAH',
    Vermont = 'VERMONT',
    Virginia = 'VIRGINIA',
    Washington = 'WASHINGTON',
    WestVirginia = 'WEST_VIRGINIA',
    Wisconsin = 'WISCONSIN',
    Wyoming = 'WYOMING',
    PuertoRico = 'PUERTO_RICO',
    Alberta = 'ALBERTA',
    BritishColumbia = 'BRITISH_COLUMBIA',
    Manitoba = 'MANITOBA',
    NewBrunswick = 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia = 'NOVA_SCOTIA',
    Ontario = 'ONTARIO',
    PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
    Quebec = 'QUEBEC',
    Saskatchewan = 'SASKATCHEWAN'
}

/**
 * 
 * @export
 * @interface TitleServiceAvailabilityResponse
 */
export interface TitleServiceAvailabilityResponse {
    /**
     * 
     * @type {Array<TitleServiceAvailability>}
     * @memberof TitleServiceAvailabilityResponse
     */
    'serviceAvailabilities'?: Array<TitleServiceAvailability>;
}
/**
 * 
 * @export
 * @interface TransactionsCriterionResponse
 */
export interface TransactionsCriterionResponse {
    /**
     * 
     * @type {string}
     * @memberof TransactionsCriterionResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionsCriterionResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionsCriterionResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionsCriterionResponse
     */
    'type'?: TransactionsCriterionResponseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TransactionsCriterionResponse
     */
    'minimumCount'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TransactionsCriterionResponseTypeEnum {
    Tenure = 'TENURE',
    Transactions = 'TRANSACTIONS',
    CapLevels = 'CAP_LEVELS',
    EliteStatus = 'ELITE_STATUS'
}

/**
 * 
 * @export
 * @interface TransactionsCriterionResponseAllOf
 */
export interface TransactionsCriterionResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof TransactionsCriterionResponseAllOf
     */
    'minimumCount'?: number;
}

/**
 * InviteControllerApi - axios parameter creator
 * @export
 */
export const InviteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accept an invite
         * @param {string} inviteId 
         * @param {AcceptInviteRequest} acceptInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvite: async (inviteId: string, acceptInviteRequest: AcceptInviteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('acceptInvite', 'inviteId', inviteId)
            // verify required parameter 'acceptInviteRequest' is not null or undefined
            assertParamExists('acceptInvite', 'acceptInviteRequest', acceptInviteRequest)
            const localVarPath = `/api/v1/invites/{inviteId}/accept`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptInviteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Decline an invite
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineInvite: async (inviteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('declineInvite', 'inviteId', inviteId)
            const localVarPath = `/api/v1/invites/{inviteId}/decline`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all aggregated invites and memberships for an agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedAgentInvites: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getAggregatedAgentInvites', 'agentId', agentId)
            const localVarPath = `/api/v1/invites/agent/{agentId}/aggregated`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all aggregated invites and memberships for the logged in agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedInvites: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/invites/agent/aggregated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all invites and memberships for an agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAgentInvites: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getAllAgentInvites', 'agentId', agentId)
            const localVarPath = `/api/v1/invites/agent/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all invites and memberships for the logged in agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInvites: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/invites/agent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get reference data for send invite form
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInviteForm: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getInviteForm', 'agentId', agentId)
            const localVarPath = `/api/v1/invites/agent/{agentId}/form`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get next invite for agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextInviteForAgent: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/invites/agent/next`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an invite
         * @param {CreateInviteRequest} createInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvite: async (createInviteRequest: CreateInviteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInviteRequest' is not null or undefined
            assertParamExists('sendInvite', 'createInviteRequest', createInviteRequest)
            const localVarPath = `/api/v1/invites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInviteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Withdraw an invite
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawInvite: async (inviteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('withdrawInvite', 'inviteId', inviteId)
            const localVarPath = `/api/v1/invites/{inviteId}/withdraw`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InviteControllerApi - functional programming interface
 * @export
 */
export const InviteControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InviteControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Accept an invite
         * @param {string} inviteId 
         * @param {AcceptInviteRequest} acceptInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptInvite(inviteId: string, acceptInviteRequest: AcceptInviteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembershipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptInvite(inviteId, acceptInviteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Decline an invite
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declineInvite(inviteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.declineInvite(inviteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all aggregated invites and memberships for an agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAggregatedAgentInvites(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentInvitesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAggregatedAgentInvites(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all aggregated invites and memberships for the logged in agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAggregatedInvites(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentInvitesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAggregatedInvites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all invites and memberships for an agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAgentInvites(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InviteResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAgentInvites(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all invites and memberships for the logged in agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllInvites(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InviteResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllInvites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get reference data for send invite form
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInviteForm(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendInviteFormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInviteForm(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get next invite for agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextInviteForAgent(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNextInviteForAgent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send an invite
         * @param {CreateInviteRequest} createInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInvite(createInviteRequest: CreateInviteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInvite(createInviteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Withdraw an invite
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async withdrawInvite(inviteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.withdrawInvite(inviteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InviteControllerApi - factory interface
 * @export
 */
export const InviteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InviteControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Accept an invite
         * @param {string} inviteId 
         * @param {AcceptInviteRequest} acceptInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvite(inviteId: string, acceptInviteRequest: AcceptInviteRequest, options?: any): AxiosPromise<MembershipResponse> {
            return localVarFp.acceptInvite(inviteId, acceptInviteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Decline an invite
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineInvite(inviteId: string, options?: any): AxiosPromise<InviteResponse> {
            return localVarFp.declineInvite(inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all aggregated invites and memberships for an agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedAgentInvites(agentId: string, options?: any): AxiosPromise<AgentInvitesResponse> {
            return localVarFp.getAggregatedAgentInvites(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all aggregated invites and memberships for the logged in agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedInvites(options?: any): AxiosPromise<AgentInvitesResponse> {
            return localVarFp.getAggregatedInvites(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all invites and memberships for an agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAgentInvites(agentId: string, options?: any): AxiosPromise<Array<InviteResponse>> {
            return localVarFp.getAllAgentInvites(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all invites and memberships for the logged in agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInvites(options?: any): AxiosPromise<Array<InviteResponse>> {
            return localVarFp.getAllInvites(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get reference data for send invite form
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInviteForm(agentId: string, options?: any): AxiosPromise<SendInviteFormResponse> {
            return localVarFp.getInviteForm(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get next invite for agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextInviteForAgent(options?: any): AxiosPromise<InviteResponse> {
            return localVarFp.getNextInviteForAgent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an invite
         * @param {CreateInviteRequest} createInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvite(createInviteRequest: CreateInviteRequest, options?: any): AxiosPromise<InviteResponse> {
            return localVarFp.sendInvite(createInviteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Withdraw an invite
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawInvite(inviteId: string, options?: any): AxiosPromise<InviteResponse> {
            return localVarFp.withdrawInvite(inviteId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InviteControllerApi - object-oriented interface
 * @export
 * @class InviteControllerApi
 * @extends {BaseAPI}
 */
export class InviteControllerApi extends BaseAPI {
    /**
     * 
     * @summary Accept an invite
     * @param {string} inviteId 
     * @param {AcceptInviteRequest} acceptInviteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteControllerApi
     */
    public acceptInvite(inviteId: string, acceptInviteRequest: AcceptInviteRequest, options?: AxiosRequestConfig) {
        return InviteControllerApiFp(this.configuration).acceptInvite(inviteId, acceptInviteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Decline an invite
     * @param {string} inviteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteControllerApi
     */
    public declineInvite(inviteId: string, options?: AxiosRequestConfig) {
        return InviteControllerApiFp(this.configuration).declineInvite(inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all aggregated invites and memberships for an agent
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteControllerApi
     */
    public getAggregatedAgentInvites(agentId: string, options?: AxiosRequestConfig) {
        return InviteControllerApiFp(this.configuration).getAggregatedAgentInvites(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all aggregated invites and memberships for the logged in agent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteControllerApi
     */
    public getAggregatedInvites(options?: AxiosRequestConfig) {
        return InviteControllerApiFp(this.configuration).getAggregatedInvites(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all invites and memberships for an agent
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteControllerApi
     */
    public getAllAgentInvites(agentId: string, options?: AxiosRequestConfig) {
        return InviteControllerApiFp(this.configuration).getAllAgentInvites(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all invites and memberships for the logged in agent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteControllerApi
     */
    public getAllInvites(options?: AxiosRequestConfig) {
        return InviteControllerApiFp(this.configuration).getAllInvites(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get reference data for send invite form
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteControllerApi
     */
    public getInviteForm(agentId: string, options?: AxiosRequestConfig) {
        return InviteControllerApiFp(this.configuration).getInviteForm(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get next invite for agent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteControllerApi
     */
    public getNextInviteForAgent(options?: AxiosRequestConfig) {
        return InviteControllerApiFp(this.configuration).getNextInviteForAgent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an invite
     * @param {CreateInviteRequest} createInviteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteControllerApi
     */
    public sendInvite(createInviteRequest: CreateInviteRequest, options?: AxiosRequestConfig) {
        return InviteControllerApiFp(this.configuration).sendInvite(createInviteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Withdraw an invite
     * @param {string} inviteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteControllerApi
     */
    public withdrawInvite(inviteId: string, options?: AxiosRequestConfig) {
        return InviteControllerApiFp(this.configuration).withdrawInvite(inviteId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JointVentureControllerApi - axios parameter creator
 * @export
 */
export const JointVentureControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new Joint Venture
         * @param {CreateJointVentureRequest} createJointVentureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJointVenture: async (createJointVentureRequest: CreateJointVentureRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createJointVentureRequest' is not null or undefined
            assertParamExists('createJointVenture', 'createJointVentureRequest', createJointVentureRequest)
            const localVarPath = `/api/v1/joint-ventures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createJointVentureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get reference data for create Joint Venture form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJointVentureForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/joint-ventures/form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Joint Ventures
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJointVentures: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/joint-ventures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JointVentureControllerApi - functional programming interface
 * @export
 */
export const JointVentureControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JointVentureControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new Joint Venture
         * @param {CreateJointVentureRequest} createJointVentureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createJointVenture(createJointVentureRequest: CreateJointVentureRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JointVentureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createJointVenture(createJointVentureRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get reference data for create Joint Venture form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createJointVentureForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateJointVentureFormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createJointVentureForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all Joint Ventures
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJointVentures(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JointVentureResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJointVentures(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JointVentureControllerApi - factory interface
 * @export
 */
export const JointVentureControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JointVentureControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new Joint Venture
         * @param {CreateJointVentureRequest} createJointVentureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJointVenture(createJointVentureRequest: CreateJointVentureRequest, options?: any): AxiosPromise<JointVentureResponse> {
            return localVarFp.createJointVenture(createJointVentureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get reference data for create Joint Venture form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJointVentureForm(options?: any): AxiosPromise<CreateJointVentureFormResponse> {
            return localVarFp.createJointVentureForm(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Joint Ventures
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJointVentures(options?: any): AxiosPromise<Array<JointVentureResponse>> {
            return localVarFp.getJointVentures(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JointVentureControllerApi - object-oriented interface
 * @export
 * @class JointVentureControllerApi
 * @extends {BaseAPI}
 */
export class JointVentureControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create a new Joint Venture
     * @param {CreateJointVentureRequest} createJointVentureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JointVentureControllerApi
     */
    public createJointVenture(createJointVentureRequest: CreateJointVentureRequest, options?: AxiosRequestConfig) {
        return JointVentureControllerApiFp(this.configuration).createJointVenture(createJointVentureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get reference data for create Joint Venture form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JointVentureControllerApi
     */
    public createJointVentureForm(options?: AxiosRequestConfig) {
        return JointVentureControllerApiFp(this.configuration).createJointVentureForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all Joint Ventures
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JointVentureControllerApi
     */
    public getJointVentures(options?: AxiosRequestConfig) {
        return JointVentureControllerApiFp(this.configuration).getJointVentures(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MembershipControllerApi - axios parameter creator
 * @export
 */
export const MembershipControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sign membership document
         * @param {string} membershipId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markDocumentsAsSigned: async (membershipId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'membershipId' is not null or undefined
            assertParamExists('markDocumentsAsSigned', 'membershipId', membershipId)
            const localVarPath = `/api/v1/memberships/{membershipId}/mark-signed`
                .replace(`{${"membershipId"}}`, encodeURIComponent(String(membershipId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MembershipControllerApi - functional programming interface
 * @export
 */
export const MembershipControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MembershipControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Sign membership document
         * @param {string} membershipId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markDocumentsAsSigned(membershipId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembershipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markDocumentsAsSigned(membershipId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MembershipControllerApi - factory interface
 * @export
 */
export const MembershipControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MembershipControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Sign membership document
         * @param {string} membershipId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markDocumentsAsSigned(membershipId: string, options?: any): AxiosPromise<MembershipResponse> {
            return localVarFp.markDocumentsAsSigned(membershipId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MembershipControllerApi - object-oriented interface
 * @export
 * @class MembershipControllerApi
 * @extends {BaseAPI}
 */
export class MembershipControllerApi extends BaseAPI {
    /**
     * 
     * @summary Sign membership document
     * @param {string} membershipId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembershipControllerApi
     */
    public markDocumentsAsSigned(membershipId: string, options?: AxiosRequestConfig) {
        return MembershipControllerApiFp(this.configuration).markDocumentsAsSigned(membershipId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TitleServiceControllerApi - axios parameter creator
 * @export
 */
export const TitleServiceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTitleServiceAvailabilities: async (state: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('getTitleServiceAvailabilities', 'state', state)
            const localVarPath = `/api/v1/titles/availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state) {
                localVarQueryParameter['state'] = Array.from(state);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TitleServiceControllerApi - functional programming interface
 * @export
 */
export const TitleServiceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TitleServiceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTitleServiceAvailabilities(state: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TitleServiceAvailabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTitleServiceAvailabilities(state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TitleServiceControllerApi - factory interface
 * @export
 */
export const TitleServiceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TitleServiceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTitleServiceAvailabilities(state: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: any): AxiosPromise<TitleServiceAvailabilityResponse> {
            return localVarFp.getTitleServiceAvailabilities(state, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TitleServiceControllerApi - object-oriented interface
 * @export
 * @class TitleServiceControllerApi
 * @extends {BaseAPI}
 */
export class TitleServiceControllerApi extends BaseAPI {
    /**
     * 
     * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} state 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TitleServiceControllerApi
     */
    public getTitleServiceAvailabilities(state: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: AxiosRequestConfig) {
        return TitleServiceControllerApiFp(this.configuration).getTitleServiceAvailabilities(state, options).then((request) => request(this.axios, this.basePath));
    }
}


