import {
  AgentResponseAgentStatusEnum,
  TeamInvitationSimplifiedDtoInvitationStatusEnum,
} from '../../../openapi/yenta';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumMultiSelectColumnGenerator from './EnumMultiSelectColumnGenerator';

export interface TeamInvitedMemberStatusColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const TeamInvitedMemberStatusColumnFilter = <D extends object>(
  props: TeamInvitedMemberStatusColumnFilterProps<D>,
) => {
  const TeamAgentResponseStatusEnum = {
    ...AgentResponseAgentStatusEnum,
    ...TeamInvitationSimplifiedDtoInvitationStatusEnum,
  };

  return EnumMultiSelectColumnGenerator<D>(TeamAgentResponseStatusEnum)(props);
};

export default TeamInvitedMemberStatusColumnFilter;
