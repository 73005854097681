interface TransactionCommissionSplitRowProps {
  title: string;
  amount: string;
}

const TransactionCommissionSplitRow: React.FC<TransactionCommissionSplitRowProps> = ({
  title,
  amount,
}) => {
  return (
    <div className='flex flex-row items-center justify-between flex-shrink'>
      <p className='text-base font-primary-thin'>{title}</p>
      <p className='text-base font-primary-medium'>{amount}</p>
    </div>
  );
};

export default TransactionCommissionSplitRow;
