import React from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { CalendarCreateRequest } from '../../../openapi/yenta';
import { createCalendar } from '../../../slices/CalendarSlice';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSimpleModal from '../../Zen/Modal/ZenSimpleModal';
import ZenButton from '../../Zen/ZenButton';

interface ZenCreateCalendarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  calendarName: string;
  calendarUrl: string;
}

const ZenCreateCalendarModal: React.FC<ZenCreateCalendarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    const calendarCreateRequest: Required<CalendarCreateRequest> = {
      calendarName: data.calendarName,
      calendarUrl: data.calendarUrl,
    };
    await dispatch(createCalendar(calendarCreateRequest));
    onClose();
  };

  return (
    <ZenSimpleModal
      isOpen={isOpen}
      onClose={onClose}
      title='Create Calendar'
      size='large'
    >
      <div className='px-12 py-8'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <ZenControlledTextInput<FormData, 'calendarName'>
              name='calendarName'
              control={control}
              label='Calendar Name'
              isRequired
              rules={{ required: 'Calendar name is required' }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'calendarUrl'>
              name='calendarUrl'
              control={control}
              label='Calendar Url'
              isRequired
              rules={{ required: 'Calendar url is required' }}
            />
          </div>
          <div className='mt-5'>
            <ZenButton
              label='Create'
              type='submit'
              variant='primary'
              isSubmitting={isSubmitting}
              isDisabled={isSubmitting}
              isFullWidth
            />
          </div>
        </form>
      </div>
    </ZenSimpleModal>
  );
};

export default ZenCreateCalendarModal;
