import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';
import ZenMarkPermanentlyProducingFormSidebarModal from '../forms/ZenMarkPermanentlyProducingFormSidebarModal';

interface ZenMarkPermanentlyProducingProps {}

const ZenMarkPermanentlyProducing: React.FC<ZenMarkPermanentlyProducingProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <ZenResourceLinkCard
        title='Mark Permanently Producing'
        onClick={() => setIsOpen(true)}
        icon={regular('circle-check')}
        iconSize='2x'
      />

      {isOpen && (
        <ZenMarkPermanentlyProducingFormSidebarModal
          isOpen
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default ZenMarkPermanentlyProducing;
