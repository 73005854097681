import { useDispatch, useSelector } from 'react-redux';
import { updateNeedsWillBeneficiaryOnboarding } from '../../../../slices/AuthSlice';
import { AnalyticsEventEnum, RootState } from '../../../../types';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenButton from '../../ZenButton';
import AnalyticsService from '../../../../services/AnalyticsService';
import BeneficiaryIntro from './BeneficiaryIntro';
import {
  CreateBeneficiaryV2FormData,
  CreateBeneficiaryV2StepName,
} from './CreateBeneficiaryScreenV2';

const CreateBeneficiaryIntroStepV2: StepByStepComponent<
  CreateBeneficiaryV2FormData,
  CreateBeneficiaryV2StepName
> = ({ form: { setValue }, onNext }) => {
  const dispatch = useDispatch();
  const {
    auth: { userDetail: agent },
  } = useSelector((state: RootState) => state);

  const markWillableAsShown = () => {
    setValue('maybeLater', true);
    dispatch(updateNeedsWillBeneficiaryOnboarding(agent?.id!, false));
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.WILLABLE_REVSHARE_INTRO_POPUP_CLOSE,
    );
    onNext();
  };

  return (
    <div className='w-full relative shadow-zen-web bg-white rounded-xl'>
      <BeneficiaryIntro />
      <div className='absolute rounded-b-xl bg-white bottom-0 right-0 left-0 z-50 md:px-12 px-5'>
        <div className='justify-center flex md:flex-row flex-col-reverse items-center md:py-8 py-4'>
          <div className='md:w-1/2 w-full md:mr-2 md:mt-0 mt-3'>
            <ZenButton
              label='Maybe Later'
              onClick={markWillableAsShown}
              type='button'
              variant='primary-outline'
              isFullWidth
            />
          </div>
          <div className='md:w-1/2 w-full md:ml-2'>
            <ZenButton
              label='Sign Up'
              type='button'
              onClick={() => {
                AnalyticsService.instance().logEvent(
                  AnalyticsEventEnum.WILLABLE_REVSHARE_SIGNUP_BUTTON_CLICK,
                );
                onNext();
              }}
              variant='primary'
              isFullWidth
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBeneficiaryIntroStepV2;
