import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faLinkSimple } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import qs from 'qs';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Column } from 'react-table';
import AnalyticsEventOnLoad from '../components/Analytics/AnalyticsEventOnLoad';
import InviteBuyerSidebarModalForm from '../components/Mortgage/InviteBuyerSidebarModalForm';
import MortgageStatus from '../components/Mortgage/MortgageStatus';
import AddressStateEnumSelectColumnFilter from '../components/table/Filters/AddressStateEnumSelectColumnFilter';
import MortgageStatusSelectColumnFilter from '../components/table/Filters/MortgageStatusSelectColumnFilter';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenPhoneNumberCell from '../components/Zen/Table/Cell/ZenPhoneNumberCell';
import ZenButton from '../components/Zen/ZenButton';
import ZenLink from '../components/Zen/ZenLink';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenRoute from '../components/Zen/ZenRoute';
import { useRefresh } from '../hooks/useRefresh';
import { LeadLoanDto } from '../openapi/atlantis';
import { AnalyticsEventEnum, RootState } from '../types';
import { getFullName } from '../utils/AgentHelper';
import { isSmScreen } from '../utils/BrowserUtils';
import { fetchMortgageLeadAndLoans } from '../utils/MortgageUtils';
import { capitalizeEnum } from '../utils/StringUtils';

export const columns: Array<Column<LeadLoanDto>> = [
  {
    Header: 'Client',
    accessor: 'clientName',
    Cell: ({ value }) => (
      <p className='font-normal whitespace-pre'>{value || 'N/A'}</p>
    ),
    Filter: TextColumnFilter,
  },
  {
    Header: 'Phone',
    accessor: 'phoneNumber',
    Cell: ({ value }) => (
      <div className='font-normal'>
        <ZenPhoneNumberCell phoneNumber={value?.value} />
      </div>
    ),
    disableSortBy: true,
    disableFilters: true,
  },

  {
    Header: 'Email',
    accessor: 'email',
    Cell: ({ value }) => <p className='font-normal'>{value?.value || 'N/A'}</p>,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Location',
    accessor: 'location',
    Cell: ({ value }) => (
      <p className='font-normal'>{value ? capitalizeEnum(value) : 'N/A'}</p>
    ),
    Filter: AddressStateEnumSelectColumnFilter,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (value ? <MortgageStatus status={value} /> : 'N/A'),
    Filter: MortgageStatusSelectColumnFilter,
  },
  {
    Header: 'Loan Processor',
    accessor: 'loanProcessor',
    Cell: ({ value }) => {
      return (
        <p className='font-normal whitespace-pre'>
          {getFullName(value) || 'N/A'}
        </p>
      );
    },
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Real Originate Mortgage Advisor',
    accessor: 'realOriginateLoanOfficer',
    Cell: ({ value }) => {
      return (
        <p className='font-normal whitespace-pre'>
          {getFullName(value) || 'N/A'}
        </p>
      );
    },
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
];

const AdminMortgagesRoute: React.FC = () => {
  const location = useLocation();
  const { key, refresh } = useRefresh();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {
    auth: { userDetail, isMortgageAdmin, isAdmin, isROMA },
  } = useSelector((state: RootState) => state);

  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );

  const modifiedColumns: Array<Column<LeadLoanDto>> = useMemo(() => {
    let alteredColumns: Array<Column<LeadLoanDto>> = [...columns];

    alteredColumns.splice(0, 0, {
      Header: 'Loan#',
      accessor: 'loanNumber',
      Cell: ({ value, row: { original } }) => {
        const {
          loanOfficer,
          loanProcessor,
          realOriginateLoanOfficer,
        } = original;

        const authorizedPerson = [
          loanOfficer,
          loanProcessor,
          realOriginateLoanOfficer,
        ].find((person) => person?.userId === userDetail?.id);

        if (isEmpty(value) || (isROMA && !authorizedPerson)) {
          return <p className='font-normal ml-4'>--</p>;
        }

        return (
          <Link
            to={`/mortgages/${original.id}`}
            className='font-normal cursor-pointer text-primary-blue'
          >
            {value}
          </Link>
        );
      },
      Filter: TextColumnFilter,
      disableSortBy: true,
    });

    alteredColumns.splice(5, 0, {
      Header: 'Agent',
      accessor: 'agentName',
      Cell: ({ value, row: { original } }) => {
        const { realOriginateLoanOfficer, agentId } = original;

        const isLoanROMA = agentId === realOriginateLoanOfficer?.userId;

        return (
          <div>
            <p className='font-normal whitespace-pre'>{value || 'N/A'}</p>
            {isLoanROMA && (
              <p className='font-normal whitespace-pre text-sm text-grey-400'>
                <FontAwesomeIcon
                  icon={faLinkSimple}
                  fontSize={14}
                  className='mr-1'
                />
                Real Originate
              </p>
            )}
          </div>
        );
      },
      disableSortBy: true,
      disableFilters: true,
    });

    if (isAdmin || isMortgageAdmin || isROMA) {
      alteredColumns.splice(1, 0, {
        Header: 'Loan Officer',
        accessor: 'loanOfficer',
        Cell: ({ value }) => {
          return (
            <p className='font-normal whitespace-pre'>
              {getFullName(value) || 'N/A'}
            </p>
          );
        },
        Filter: TextColumnFilter,
        disableSortBy: true,
      });
    }

    return alteredColumns;
  }, [userDetail?.id, isAdmin, isMortgageAdmin, isROMA]);

  return (
    <ZenRoute title='Loans'>
      <ZenPageLayoutWithSearch
        path={[
          { title: 'Home', url: '/' },
          { title: 'Mortgage', url: '/mortgages' },
        ]}
        search={search}
        onSearchChange={setSearch}
      >
        <div className='px-4 py-0 mt-5'>
          <ZenResourceIndexContainer<LeadLoanDto>
            header='Loans'
            resourceName='Loan'
            key={key}
            search={search}
            columns={modifiedColumns}
            showSearch={isSmScreen()}
            fetchData={async (req, cancelToken) =>
              await fetchMortgageLeadAndLoans(req, { cancelToken })
            }
            RightComponent={
              <div className='flex flex-row items-center space-x-2.5 md:space-x-3'>
                <ZenLink
                  label='Explore One Real Mortgage'
                  onClick={() => window.open('/onereal-mortgage', '_blank')}
                  variant='primary-with-no-hover-effect'
                />
                <ZenButton
                  label='Invite Buyer'
                  LeftIconComponent={<FontAwesomeIcon icon={solid('plus')} />}
                  onClick={() => setIsOpen(true)}
                />
              </div>
            }
            hiddenColumns={['loanProcessor', 'realOriginateLoanOfficer']}
            stickyHeader
          />
        </div>
        {isOpen && (
          <InviteBuyerSidebarModalForm
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            refresh={refresh}
          />
        )}
        <AnalyticsEventOnLoad
          eventName={AnalyticsEventEnum.MC_DASHBOARD_VIEWED}
        />
      </ZenPageLayoutWithSearch>
    </ZenRoute>
  );
};

export default AdminMortgagesRoute;
