import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import {
  AddressResponse,
  AdministrativeAreaRequest,
  DirectoryControllerApi,
  DirectoryEntryResponseTypeEnum,
  DirectoryPersonCreateRequest,
  DirectoryPersonLinkRequest,
  DirectoryPersonResponse,
  DirectoryPersonResponseCurrentUserPermissionEnum,
  DirectoryPersonResponseRoleEnum,
  DirectoryPersonResponseStatusEnum,
  DirectoryPersonUpdateRequest,
  DirectoryVendorCreateRequest,
  DirectoryVendorPartialResponse,
  DirectoryVendorResponseCurrentUserPermissionEnum,
  DirectoryVendorResponseRoleEnum,
  DirectoryVendorResponseStatusEnum,
  DirectoryVendorUpdateRequest,
  NationalBusinessIdentification,
  PagedDirectoryEntryResponse,
  PermissionDto,
} from '../../openapi/yenta';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { queryKeys } from '../base/queryKeys';
import { QueryOptions, useSimpleQuery } from '../base/useSimpleQuery';
import { sortAndGroupContacts } from '../../components/Directory/utils';
import { useBaseMutation } from '../base/useBaseMutation';
import { useGoogleMapsAPILoader } from '../../hooks/useGoogleMapsAPILoader';
import { useGeocodeAddress } from '../../hooks/useGoogleGeocodeAddress';
import { AddressComponentType } from '../../types';
import { showSuccessToast } from '../../slices/ToastNotificationSlice';

export const SUCCESS_CREATE_PRIMARY_MESSAGE = 'Contact added successfully'; // no period
export const SUCCESS_CREATE_SECONDARY_MESSAGE =
  'The new contact has been added to your Directory.';
export const ERROR_CREATE_MESSAGE = 'Contact was not added';

export const SUCCESS_UPDATE_MESSAGE = 'Contact updated successfully';
export const ERROR_UPDATE_MESSAGE = 'Contact was not updated';

export const ERROR_DIRECTORY_FETCH_MESSAGE =
  'Error retrieving Directory contacts';

export const SUCCESS_VERIFY_MESSAGE = 'Contact verified successfully';
export const ERROR_VERIFY_MESSAGE = 'Error verifying contact';

export const ERROR_UPDATE_ADMIN_AREAS_MESSAGE =
  'Error updating administrative areas';

export const SUCCESS_ACTIVATE_MESSAGE = 'Contact activated successfully';
export const SUCCESS_ARCHIVE_MESSAGE = 'Contact archived successfully';
export const ERROR_ARCHIVE_MESSAGE = 'Error archiving contact';

export const ERROR_FETCH_W9 = 'Error in retrieving W9';

export interface DirectoryCommonEntityResponse {
  id?: string;
  createdBy?: string;
  createdAt?: number;
  updatedAt?: number;
  emailAddress?: string;
  secondaryEmailAddress?: string; // Business/Vendor only
  phoneNumber?: string;
  address?: AddressResponse;
  status?: string; // Assuming the status enums are compatible, otherwise use a union of status enum types
  agentPermissionSet?: Array<PermissionDto>;
  teamPermissionSet?: Array<PermissionDto>;
  currentUserPermission?:
    | DirectoryVendorResponseCurrentUserPermissionEnum
    | DirectoryPersonResponseCurrentUserPermissionEnum;

  // Properties unique to DirectoryPersonResponse
  firstName?: string;
  lastName?: string;
  linkedVendor?: DirectoryVendorPartialResponse;

  // Properties unique to DirectoryVendorResponse
  name?: string;
  nationalBusinessIdentifications?: Array<NationalBusinessIdentification>;
  hasW9?: boolean;
  administrativeAreaIds?: Array<string>;
  linkedPersons?: Array<DirectoryPersonResponse>;

  // Union type for role as it differs between Person and Vendor
  role?: DirectoryPersonResponseRoleEnum | DirectoryVendorResponseRoleEnum;

  // from use of `useGeocodeAddress`, used in listing/transaction flow
  placeId?: string;
  addressComponents?: AddressComponentType[];
  vendorId?: string;

  // custom injecting w9file to form
  linkedName?: string;
  linkedId?: string;
  w9form?: File[];
  type?: string;
}

interface UseDirectoryProps {
  /**
   * Same `options` as you would pass to `useQuery` in react-query, but with some extra options.
   * See `QueryOptions` type for details.
   */
  options?: QueryOptions<PagedDirectoryEntryResponse>;
  /**
   * All the arguments the API function is expecting.
   */
  fnArgs: Parameters<DirectoryControllerApi['getAllEntries']>;
}
/**
 *
 * A reusable helper hook to return data for the /directory page.
 * Useful for searching and filtering, most likely the go-to query function.
 * 
 * Usage:
 * 
 * ```typescript
    const queryParams = useQueryParams<{ [key: string]: string | string[] }>();
    const page = parseInt((queryParams?.page as string) ?? 0);
    const size = parseInt((queryParams?.size as string) ?? 25);

    const { isLoading, data } = useDirectory({ fnArgs: [page, size] });
    if (isLoading) {
      // do something
    }
    if (data) {
      // render UI with data
    }
 * ```
 *
 */
export const useDirectory = ({ options, fnArgs }: UseDirectoryProps) => {
  const fetchDirectory = async () => {
    const { data } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).getAllEntries(...fnArgs);
    return data;
  };
  const [pageIndex, pageSize, ...others] = fnArgs;
  const queryResult = useSimpleQuery<PagedDirectoryEntryResponse>({
    queryKey: queryKeys.directory.list({ pageIndex, pageSize, params: others })
      .queryKey,
    queryFn: () => fetchDirectory(),
    options: {
      toastErrorMessage: ERROR_DIRECTORY_FETCH_MESSAGE,
      logErrorMessage: ERROR_DIRECTORY_FETCH_MESSAGE,
      ...options,
    },
  });

  const data = useMemo(() => {
    const contacts = queryResult.data?.directoryEntryResponseList;
    const groupedData = contacts ? sortAndGroupContacts(contacts) : {};
    return queryResult.data !== undefined
      ? {
          ...queryResult.data,
          directoryEntryResponseList: groupedData,
        }
      : undefined;
  }, [queryResult.data]);

  return {
    data: data,
    isLoading: queryResult?.isLoading,
    isError: queryResult?.isError,
    error: queryResult?.error,
  };
};

export const useDirectoryAddress = () => {
  const isApiLoaded = useGoogleMapsAPILoader();
  const addressResult = useGeocodeAddress(isApiLoaded);

  return addressResult;
};

export const useCreateVendor = () => {
  const createVendor = async (personData: DirectoryVendorCreateRequest) => {
    const { data } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).createVendor(personData);
    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.directory._def,
    mutationFn: createVendor,
  });
};

export const useCreatePerson = () => {
  const createPerson = async (personData: DirectoryPersonCreateRequest) => {
    const { data } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).createPerson(personData);
    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.directory._def,
    mutationFn: createPerson,
  });
};

export const useUpdateVendor = (id: string) => {
  const queryClient = useQueryClient();
  const updateVendor = async ({
    id,
    vendorRequest,
  }: {
    id: string;
    vendorRequest: DirectoryVendorUpdateRequest;
  }) => {
    const { data } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).updateVendor(id, vendorRequest);
    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.directory.detail(
      DirectoryEntryResponseTypeEnum.Vendor,
      id,
    ).queryKey,
    mutationFn: updateVendor,
    successMessage: SUCCESS_UPDATE_MESSAGE,
    queryDataTransform: (variables, prevData) => {
      return {
        ...prevData,
        ...variables,
      };
    },
    onSettled: () => {
      // Normally useBaseMutation handles invalidations by default,
      // but we need to invalidate the directory list which is has a different query key
      queryClient.invalidateQueries(queryKeys.directory._def);
    },
  });
};

export const useUpdatePerson = (id: string) => {
  const queryClient = useQueryClient();
  const updatePerson = async ({
    id,
    personRequest,
  }: {
    id: string;
    personRequest: DirectoryPersonUpdateRequest;
  }) => {
    const { data } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).updatePerson(id, personRequest);
    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.directory.detail('PERSON', id).queryKey,
    mutationFn: updatePerson,
    successMessage: SUCCESS_UPDATE_MESSAGE,
    queryDataTransform: (variables, prevData) => {
      return {
        ...prevData,
        ...variables,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.directory._def);
    },
  });
};

export const useLinkPersontoVendor = (id: string) => {
  const queryClient = useQueryClient();
  const updateLinkedPerson = async ({
    id,
    personRequest,
  }: {
    id: string;
    personRequest: DirectoryPersonLinkRequest;
  }) => {
    const { data } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).linkPerson(id, personRequest);
    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.directory.detail('PERSON', id).queryKey,
    mutationFn: updateLinkedPerson,
    successMessage: SUCCESS_UPDATE_MESSAGE,
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.directory._def);
    },
  });
};

export const useDirectoryEntity = (
  id: string,
  type: DirectoryEntryResponseTypeEnum,
) => {
  const directoryApi = new DirectoryControllerApi(getYentaConfiguration());
  const getDirectoryEntity = async (id: string) => {
    if (type.toUpperCase() === DirectoryEntryResponseTypeEnum.Person) {
      const { data } = await directoryApi.getPerson(id);
      return data;
    } else {
      const { data } = await directoryApi.getVendorById(id);
      return data;
    }
  };

  const queryResult = useSimpleQuery<DirectoryCommonEntityResponse>({
    queryKey: queryKeys.directory.detail(type, id).queryKey,
    queryFn: () => getDirectoryEntity(id),
    options: {
      toastErrorMessage: ERROR_DIRECTORY_FETCH_MESSAGE,
      logErrorMessage: ERROR_DIRECTORY_FETCH_MESSAGE,
    },
  });

  return {
    data: queryResult.data,
    isFetching: queryResult.isLoading,
    isLoading: queryResult.isLoading,
  };
};

export const useUpdateVendorAdminAreas = () => {
  const updateVendorAdminAreas = async ({
    id,
    administrativeAreaRequest,
  }: {
    id: string;
    administrativeAreaRequest: Array<AdministrativeAreaRequest>;
  }) => {
    const { data: administrativeArea } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).updateVendorAdminAreas(id, administrativeAreaRequest);
    return administrativeArea;
  };

  return useBaseMutation({
    queryKey: queryKeys.directory._def,
    mutationFn: updateVendorAdminAreas,
    errorMessage: ERROR_UPDATE_ADMIN_AREAS_MESSAGE,
  });
};

export const useVerifyVendor = (id: string) => {
  const queryClient = useQueryClient();
  const verifyVendor = async (id: string) => {
    const { data } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).verifyVendor(id);
    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.directory.detail(
      DirectoryEntryResponseTypeEnum.Vendor,
      id,
    ).queryKey,
    mutationFn: verifyVendor,
    successMessage: SUCCESS_VERIFY_MESSAGE,
    errorMessage: ERROR_VERIFY_MESSAGE,
    queryDataTransform: (_variables, prevData) => {
      return {
        ...prevData,
        status: DirectoryVendorResponseStatusEnum.Verified,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.directory._def);
    },
  });
};

export const useArchiveVendor = (id: string) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const archiveVendor = async ({
    id,
    archive,
  }: {
    id: string;
    archive?: boolean;
  }) => {
    const { data: vendorArchive } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).archiveVendor(id, archive);
    return vendorArchive;
  };

  return useBaseMutation({
    queryKey: queryKeys.directory.detail(
      DirectoryEntryResponseTypeEnum.Vendor,
      id,
    ).queryKey,
    mutationFn: archiveVendor,
    onSuccess: ({ status }) => {
      if (status === DirectoryVendorResponseStatusEnum.Unverified) {
        dispatch(showSuccessToast(SUCCESS_ACTIVATE_MESSAGE));
      }
      if (status === DirectoryVendorResponseStatusEnum.Archived) {
        dispatch(showSuccessToast(SUCCESS_ARCHIVE_MESSAGE));
      }
    },
    queryDataTransform: (variables, prevData) => {
      if (variables.archive === false) {
        return {
          ...prevData,
          status: DirectoryVendorResponseStatusEnum.Unverified,
        };
      }

      if (variables.archive === true) {
        return {
          ...prevData,
          status: DirectoryVendorResponseStatusEnum.Archived,
        };
      }

      return prevData;
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.directory._def);
    },
  });
};

export const useArchivePerson = (id: string) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const archivePerson = async ({
    id,
    archive,
  }: {
    id: string;
    archive?: boolean;
  }) => {
    const { data: personArchive } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).archivePerson(id, archive);
    return personArchive;
  };

  return useBaseMutation({
    queryKey: queryKeys.directory.detail(
      DirectoryEntryResponseTypeEnum.Person,
      id,
    ).queryKey,
    mutationFn: archivePerson,
    onSuccess: ({ status }) => {
      if (status === DirectoryPersonResponseStatusEnum.Active) {
        dispatch(showSuccessToast(SUCCESS_ACTIVATE_MESSAGE));
      }
      if (status === DirectoryPersonResponseStatusEnum.Archived) {
        dispatch(showSuccessToast(SUCCESS_ARCHIVE_MESSAGE));
      }
    },
    queryDataTransform: (variables, prevData) => {
      if (variables.archive === false) {
        return {
          ...prevData,
          status: DirectoryPersonResponseStatusEnum.Active,
        };
      }

      if (variables.archive === true) {
        return {
          ...prevData,
          status: DirectoryPersonResponseStatusEnum.Archived,
        };
      }

      return prevData;
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.directory._def);
    },
  });
};

export const useFileUpdate = (id: string) => {
  const queryClient = useQueryClient();
  const updateVendorW9 = async ({
    vendorId,
    w9,
  }: {
    vendorId?: string;
    w9: File;
  }) => {
    const { data } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).updateVendorW9(vendorId || id, w9);
    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.directory.w9.queryKey,
    mutationFn: updateVendorW9,
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.directory.detail._def);
      queryClient.invalidateQueries(queryKeys.directory.w9.queryKey);
    },
  });
};

export const useDirectoryVendorW9Url = (id: string, hasW9?: boolean) => {
  const fetchW9 = async (id: string) => {
    const { data } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).getVendorW9Path(id);
    return data;
  };
  const enabled = !!id && hasW9;
  const queryResult = useSimpleQuery({
    queryKey: queryKeys.directory.w9.queryKey,
    queryFn: () => fetchW9(id),
    options: {
      toastErrorMessage: ERROR_FETCH_W9,
      logErrorMessage: ERROR_FETCH_W9,
      enabled: enabled ?? false,
    },
  });

  return {
    data: hasW9 ? (queryResult.data as string) : '',
  };
};

export const useFetchW9OnDemand = () => {
  const fetchW9 = async (id: string) => {
    const { data } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).getVendorW9Path(id);
    return data;
  };
  return useBaseMutation({
    queryKey: queryKeys.directory.w9.queryKey,
    mutationFn: ({ id }) => fetchW9(id),
  });
};
