import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useHistory } from 'react-router-dom';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface ZenBankAccountsProps {}

const ZenBankAccounts: React.FC<ZenBankAccountsProps> = () => {
  const history = useHistory();

  return (
    <div>
      <ZenResourceLinkCard
        title='Bank Accounts'
        onClick={() => history.push('/bank-accounts')}
        icon={light('building-columns')}
      />
    </div>
  );
};

export default ZenBankAccounts;
