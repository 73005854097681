import { faFilePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';
import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form-v7';
import {
  ParticipantResponse,
  ParticipantResponseParticipantRoleEnum,
} from '../../openapi/arrakis';
import { MoneyValueCurrencyEnum } from '../../openapi/yenta';
import { cn } from '../../utils/classUtils';
import { displayAmount, parseMoney } from '../../utils/CurrencyUtils';
import { getParticipantName } from '../../utils/ParticipantHelper';
import { participantRoleDisplayName } from '../../utils/TransactionHelper';
import { MONEY_VALIDATIONS, PERCENT_VALIDATION } from '../../utils/Validations';
import Avatar from '../Avatar';
import ControlledCurrencyInputV7 from '../ControlledCurrencyInputV7';
import ControlledPercentageInputV7 from '../ControlledPercentageInputV7';
import ControlledTextInputV7 from '../ControlledTextInputV7';
import ControlledToggleInputV7 from '../ControlledToggleInputV7';
import Crown from '../Icons/Crown';

interface CommissionSplitContentProps {
  fields: Record<'id', string>[];
  participants: ParticipantResponse[];
  commissionSplitErrors: string[];
  currency: MoneyValueCurrencyEnum;
  watchParticipants: any;
  totalAmount: number;
  totalPercentage: number;
  control: Control<any, object>;
  errors: DeepMap<any, FieldError>;
  totalCommission: number;
  toggleSwitches(index: number): void;
  defaultPercent?: string;
  defaultAmount?: string;
  isRequired?: boolean;
}

const CommissionSplitContent: React.FC<CommissionSplitContentProps> = ({
  fields,
  participants,
  commissionSplitErrors,
  currency,
  watchParticipants,
  totalAmount,
  totalPercentage,
  control,
  errors,
  totalCommission,
  toggleSwitches,
  defaultPercent = '0',
  defaultAmount = '0',
  isRequired = false,
}) => {
  const isConsiderDollar =
    watchParticipants.every((p: any) => p.isDollar) ||
    totalAmount === totalCommission;

  const checkAmountError = (index: number) =>
    get(errors, `commissionParticipant[${index}].money.amount`);

  const checkPercentageError = (index: number) =>
    get(errors, `commissionParticipant[${index}].percent.value`);

  return (
    <div>
      <div className='py-2'>
        <div className='flex flex-row justify-between items-center px-2 md:px-4'>
          <p className='text-lg font-primary-regular'>
            Commission Splits{' '}
            {isRequired && <span className='text-error'>*</span>}
          </p>
        </div>
        <div className='divide-y space-y-2'>
          <ul>
            {fields.map((item: any, index: number) => {
              const participant = participants?.find((p) => p.id === item.id)!;

              return (
                <section
                  className='flex flex-row justify-between py-1 px-2 md:px-4'
                  key={item.id}
                >
                  <div className='flex flex-row space-x-1'>
                    <div className='flex flex-row space-x-2 items-center'>
                      <Avatar
                        name={getParticipantName(participant) || 'N/A'}
                        imageUrl={item.avatar!}
                        size='xs'
                      />
                      <div className='flex md:flex-row flex-col'>
                        <div className='flex flex-col'>
                          <ControlledTextInputV7
                            type='hidden'
                            control={control}
                            shouldUnregister={false}
                            name={`commissionParticipant[${index}].id`}
                            defaultValue={item.id}
                            noBorder
                          />
                          <p className='md:text-base text-sm'>
                            {getParticipantName(participant)}
                          </p>
                          <p className='text-xs'>
                            {participant?.participantRole
                              ? participantRoleDisplayName(
                                  participant?.participantRole!,
                                )
                              : null}
                          </p>
                        </div>
                        <div className='flex flex-row space-x-1 ml-1'>
                          {participant?.participantRole! ===
                            ParticipantResponseParticipantRoleEnum.TeamLeader && (
                            <Crown length={22} />
                          )}
                          {participant?.commissionDocumentPayer && (
                            <FontAwesomeIcon
                              icon={faFilePlus}
                              className='text-gray-500 text-lg p-1'
                              data-testid='documentIcon'
                            />
                          )}
                          {/* {item.isPayer && (
                                    <AttachMoney
                                      data-testid='moneyIcon'
                                      fontSize='small'
                                      className='text-green-600'
                                    />
                                  )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-row items-center space-x-3'>
                    <div onClick={() => toggleSwitches(index)}>
                      <ControlledToggleInputV7
                        label=''
                        name={`commissionParticipant[${index}].isDollar`}
                        control={control}
                        shouldUnregister={false}
                        defaultValue={
                          watchParticipants[index]?.isDollar || false
                        }
                        rightIcon='$'
                        leftIcon='%'
                      />
                    </div>
                    <div
                      className={cn(
                        'mt-1 rounded shadow-sm',
                        watchParticipants[index]?.isDollar ? 'hidden' : 'flex',
                      )}
                    >
                      <span
                        className={cn(
                          'text-sm inline-flex items-center px-1.5 md:px-2 rounded-l border border-r-0 bg-gray-200 text-gray-500 sm:text-sm',
                          checkPercentageError(index)
                            ? 'border-red-500'
                            : 'border-gray-300',
                        )}
                      >
                        %
                      </span>
                      <span
                        className={cn(
                          'w-20 rounded-none rounded-r sm:text-sm border border-l-0',
                          checkPercentageError(index)
                            ? 'border-red-500'
                            : 'border-gray-300',
                        )}
                      >
                        <ControlledPercentageInputV7
                          control={control}
                          name={`commissionParticipant[${index}].percent.value`}
                          shouldUnregister={false}
                          defaultValue={item.percent?.value || defaultPercent}
                          rules={{
                            validate: (val: string) =>
                              !watchParticipants[index].isDollar &&
                              (typeof val === 'undefined' || val === '')
                                ? 'Required'
                                : undefined,
                            ...PERCENT_VALIDATION,
                          }}
                          noBorder
                          placeholder='Percentage'
                          hideErrorMessage
                        />
                        <ControlledTextInputV7
                          control={control}
                          type='hidden'
                          name={`commissionParticipant[${index}].percent.string`}
                          shouldUnregister={false}
                          noBorder
                          defaultValue={item.percent?.string || '0%'}
                        />
                      </span>
                    </div>
                    <div
                      className={cn(
                        'mt-1 rounded shadow-sm',
                        watchParticipants[index]?.isDollar ? 'flex' : 'hidden',
                      )}
                    >
                      <span
                        className={cn(
                          'text-sm inline-flex items-center px-1.5 md:px-2 rounded-l border border-r-0 bg-gray-200 text-gray-500 sm:text-sm',
                          checkAmountError(index)
                            ? 'border-red-500'
                            : 'border-gray-300',
                        )}
                      >
                        $
                      </span>
                      <span
                        className={cn(
                          'w-20 rounded-none rounded-r text-sm border border-l-0',
                          checkAmountError(index)
                            ? 'border-red-500'
                            : 'border-gray-300',
                        )}
                      >
                        <ControlledCurrencyInputV7
                          control={control}
                          name={`commissionParticipant[${index}].money.amount`}
                          shouldUnregister={false}
                          noBorder
                          defaultValue={
                            +item?.money?.amount
                              ? parseMoney(+item.money.amount)
                              : defaultAmount
                          }
                          rules={{
                            validate: (val: string) =>
                              watchParticipants[index].isDollar &&
                              (typeof val === 'undefined' || val === '')
                                ? 'Required'
                                : undefined,
                            ...MONEY_VALIDATIONS,
                          }}
                          placeholder='Amount'
                          hideErrorMessage
                        />
                        <ControlledTextInputV7
                          control={control}
                          type='hidden'
                          name={`commissionParticipant[${index}].money.currency`}
                          shouldUnregister={false}
                          noBorder
                          defaultValue={item.money?.currency || currency}
                        />
                      </span>
                    </div>
                  </div>
                </section>
              );
            })}
          </ul>
        </div>
      </div>
      <div className='flex flex-col space-y-2 py-2 px-2 md:px-4'>
        <div className='flex flex-row justify-between items-start'>
          <p className='text-lg font-primary-regular text-left'>Total</p>
          <div className='flex justify-end'>
            <p
              className={cn(
                !!commissionSplitErrors.length
                  ? 'text-red-600 border border-red-300 rounded p-1'
                  : 'text-black p-1 border-0',
              )}
            >
              {isConsiderDollar
                ? displayAmount({
                    amount: totalAmount,
                    currency: currency,
                  })
                : `${totalPercentage}%`}
            </p>
          </div>
        </div>
        {!!commissionSplitErrors.length && (
          <div className='flex flex-col items-end'>
            {commissionSplitErrors.map((errorText) => (
              <p
                key={errorText}
                className='text-red-600 border border-red-300 rounded p-1 flex text-sm md:text-base mt-2'
              >
                {errorText}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CommissionSplitContent;
