import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { CreateWebhookRequestEventNamesEnum } from '../../openapi/plutus';
import { enumToCamelCase } from '../../utils/StringUtils';
import { WebhookEvent, WebhookEventGroup } from '../../utils/WebhookUtils';
import { cn } from '../../utils/classUtils';

export interface WebhookAccordionGroupProps {
  eventsList: WebhookEventGroup[];
  search: string;
  handleToggleEvent(
    eventEnum: CreateWebhookRequestEventNamesEnum,
    removeFlag?: boolean,
  ): void;
  handleToggleGroup(index: number, toggle: boolean): void;
}

const WebhookAccordionGroup: React.FC<WebhookAccordionGroupProps> = ({
  eventsList = [],
  search,
  handleToggleEvent,
  handleToggleGroup,
}) => {
  const [isOpen, setActivePanel] = useState<{ [key: string]: boolean }>({});
  const [filteredEventsList, setFilteredEventsList] = useState<
    WebhookEventGroup[]
  >();

  const handleAccordionClick = (key: string) => {
    setActivePanel((prevState) => ({
      ...prevState,
      [key]: !Boolean(prevState[key]),
    }));
  };

  useEffect(() => {
    if (search) {
      const filteredList = eventsList
        .map((group) => ({
          ...group,
          events: group.events.filter((event: WebhookEvent) =>
            event.label.toLowerCase().includes(search.toLowerCase()),
          ),
        }))
        .filter((group) => group.events.length > 0);
      setFilteredEventsList(filteredList);
    } else {
      setFilteredEventsList(eventsList);
    }
  }, [eventsList, search]);

  return (
    <div>
      <div className='border border-zen-dark-5 rounded-md m-3 mb-24'>
        {filteredEventsList?.map((group, index) => {
          const isLastItem = index === filteredEventsList?.length - 1;
          const groupTitle = group?.title?.replace(/_./g, ' ');
          const selectedEvents = (group?.events || []).filter(
            (event: WebhookEvent) => event?.isChecked === true,
          );

          return (
            <div key={group?.title} className={cn({ 'border-b': !isLastItem })}>
              <div
                className='flex justify-between p-3 cursor-pointer items-center'
                onClick={() => handleAccordionClick(group?.title)}
              >
                <div className='text-zen-gray-5 font-bold text-xs uppercase font-zen-body'>
                  {`${groupTitle} (${group?.events?.length})`}
                </div>

                <div className='flex gap-1 items-center'>
                  <p className='bg-grey-100 p-1 rounded text-xs text-zen-dark-12 font-normal font-zen-body'>
                    {`${selectedEvents?.length} Selected`}
                  </p>
                  <FontAwesomeIcon
                    icon={isOpen[group?.title] ? faChevronUp : faChevronDown}
                  />
                </div>
              </div>

              {isOpen[group?.title] && (
                <div>
                  {group?.events?.length > 0 ? (
                    <div>
                      {!search && (
                        <div className='flex items-center p-3 gap-1'>
                          <input
                            id='default-checkbox'
                            type='checkbox'
                            data-testid={`${group?.title}-checkbox`}
                            checked={group.isGroupSelected}
                            onClick={(e) =>
                              handleToggleGroup(index, e.currentTarget.checked)
                            }
                            value=''
                            className='w-4 h-4 text-blue-600 rounded-sm border-gray-300 focus:ring-0 focus:outline-none cursor-pointer'
                          />

                          <label className='ml-2 font-zen-body font-normal text-dark'>
                            {`Select all ${enumToCamelCase(group?.title)}`}
                          </label>
                        </div>
                      )}

                      {group?.events?.map((event: WebhookEvent) => (
                        <div
                          className='flex items-start p-3 gap-1'
                          key={event?.value}
                        >
                          <input
                            id='default-checkbox'
                            type='checkbox'
                            data-testid={`${event?.label}-checkbox`}
                            checked={event?.isChecked}
                            onClick={() => handleToggleEvent(event?.value)}
                            value=''
                            className='w-4 h-4 text-blue-600 rounded-sm border-gray-300 focus:ring-0 focus:outline-none cursor-pointer'
                          />
                          <label className='ml-2  -mt-1 font-normal text-dark flex flex-col gap-1 font-zen-body'>
                            {event?.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className='flex justify-center items-center mb-6 p-2 text-base text-zen-dark-5 font-zen-body'>
                      No Events
                    </p>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WebhookAccordionGroup;
