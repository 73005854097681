import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsRotate,
  faCalendar,
  faChevronDown,
  faFileLines,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { DateTime } from 'luxon';
import qs from 'qs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { FieldError, useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AdminAgentSelector from '../components/AgentReports/AdminAgentSelector';
import AvatarLabelComponent from '../components/AgentReports/AvatarLabelComponent';
import BrokerAgentSelector from '../components/AgentReports/BrokerAgentSelector';
import TeamAdminAgentSelector from '../components/AgentReports/TeamAdminAgentSelector';
import PageLayout from '../components/PageLayout';
import ResourceContainer from '../components/ResourceContainer';
import ZenControlledDateRangeInput from '../components/Zen/Input/ZenControlledDateRangeInput';
import ZenControlledSelectInput from '../components/Zen/Input/ZenControlledSelectInput';
import ZenButton from '../components/Zen/ZenButton';
import ZenRoute from '../components/Zen/ZenRoute';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { ReportControllerApi } from '../openapi/arrakis';
import { AgentControllerApi } from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { fetchAgentsInfo } from '../slices/UserIdsSlice';
import {
  FeatureFlagTypeEnum,
  ISelectOption,
  ReportType,
  RootState,
} from '../types';
import { administrativeAreaList, getFullName } from '../utils/AgentHelper';
import {
  getArrakisConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { parseCsv } from '../utils/reportsUtils';

import { getTeamsWithAdminOrLeaderRole } from '../utils/TeamHelper';

interface ReportsRouteProps {}

export interface ReportFormData {
  reportType: ISelectOption;
  reportPeriod: ISelectOption;
  dateFrom: string;
  dateTo: string;
  agent: ISelectOption | undefined;
}

const ReportsRoute: React.FC<ReportsRouteProps> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    auth: { userDetail, isAdmin, isBroker },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);
  const adminTeams = getTeamsWithAdminOrLeaderRole(userDetail!) || [];
  const isTeamAdmin = adminTeams.length > 0;
  const { agentId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as { agentId: string };

  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [reportData, setReportData] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<ReportFormData>();
  const [reportPeriod, dateFrom, dateTo, reportType, agent] =
    watch(['reportPeriod', 'dateFrom', 'dateTo', 'reportType', 'agent']) ||
    undefined;

  const areasList = administrativeAreaList(userDetail!);

  const isRevenueShareReportFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.REVENUE_SHARE_REPORT,
  );

  const availableReportOptions: ISelectOption[] = [
    {
      value: ReportType.CLOSED,
      label: 'Closed Transaction Breakdown',
    },
    {
      value: ReportType.PENDING,
      label: 'Pending Transaction Breakdown',
    },
    {
      value: ReportType.TERMINATED,
      label: 'Terminated Transactions Breakdown',
    },
  ];

  if (isRevenueShareReportFeatureFlagEnabled) {
    availableReportOptions.push({
      value: ReportType.REVENUE,
      label: 'Revenue Share Report',
    });
  }

  useEffect(() => {
    if (reportPeriod?.value === 'DATE') {
      if (dateFrom && dateTo) {
        const formattedDateLabel = `${DateTime.fromISO(dateFrom).toLocaleString(
          DateTime.DATE_FULL,
        )} to ${DateTime.fromISO(dateTo).toLocaleString(DateTime.DATE_FULL)}`;

        setValue('reportPeriod.label', formattedDateLabel);
      } else if (dateFrom) {
        setValue(
          'reportPeriod.label',
          `${DateTime.fromISO(dateFrom).toLocaleString(
            DateTime.DATE_FULL,
          )} to Date To`,
        );
      } else if (dateTo) {
        setValue(
          'reportPeriod.label',
          `Date From to ${DateTime.fromISO(dateTo).toLocaleString(
            DateTime.DATE_FULL,
          )}`,
        );
      } else {
        setValue('reportPeriod.label', 'Date From to Date To');
      }
    } else if (reportPeriod) {
      setValue('reportPeriod.label', `Last ${reportPeriod.value} months`);
    }
  }, [dateFrom, dateTo, reportPeriod, setValue, watch]);

  const setDefaultAgent = useCallback(
    async (agentYentaId: string) => {
      setIsLoading(true);
      try {
        const { data: agentData } = await new AgentControllerApi(
          getYentaConfiguration(),
        ).getAgentById(agentYentaId!);
        if (agentData) {
          setValue('agent', {
            //@ts-ignore
            label: (
              <AvatarLabelComponent
                avatar={agentData?.avatar!}
                firstName={agentData?.firstName!}
                lastName={agentData?.lastName!}
                emailAddress={agentData?.emailAddress!}
                administrativeAreas={administrativeAreaList(agentData!)}
              />
            ),
            value: agentData?.id!,
          });
        }
      } catch (e) {
        ErrorService.notify('Error fetching real agent', e);
        dispatch(
          showErrorToast(
            'We had a problem fetching the real agent',
            'Please try again in a few moments.',
          ),
        );
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, setValue],
  );

  useEffect(() => {
    if (
      (!isTeamAdmin && !isAdmin && !isBroker) ||
      (agentId && userDetail?.id === agentId)
    ) {
      setValue('agent', {
        //@ts-ignore
        label: (
          <AvatarLabelComponent
            avatar={userDetail?.avatar!}
            firstName={userDetail?.firstName!}
            lastName={userDetail?.lastName!}
            emailAddress={userDetail?.emailAddress!}
            administrativeAreas={areasList}
          />
        ),
        value: userDetail?.id!,
      });
    } else if (agentId && userDetail?.id !== agentId) {
      setDefaultAgent(agentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAdmin,
    isBroker,
    isTeamAdmin,
    setValue,
    userDetail,
    setDefaultAgent,
    agentId,
  ]);

  const colourStyles = {
    //@ts-ignore
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? null : '',
        color: '',
        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected ? null : null,
        },
      };
    },
  };

  const onSubmit = async (values: ReportFormData) => {
    try {
      setReportData('');
      let fromDate: string = '0',
        toDate: string = '0';
      if (values.reportPeriod.value !== 'DATE') {
        fromDate = DateTime.local()
          .minus({ months: +values.reportPeriod.value })
          .toISODate();

        toDate = DateTime.local().toISODate();
      } else {
        if (values.dateFrom && values.dateTo) {
          fromDate = values.dateFrom;
          toDate = values.dateTo;
        } else {
          return setError('reportPeriod', {
            message: 'Please provide the date range',
          });
        }
      }
      dispatch(fetchAgentsInfo([values.agent!.value]));
      if (values.reportType.value === ReportType.CLOSED) {
        const { data } = await new ReportControllerApi(
          getArrakisConfiguration(),
        ).getAgentClosedTransactionReport(
          values.agent!.value,
          fromDate,
          toDate,
        );

        setReportData(data);
      }
      if (values.reportType.value === ReportType.PENDING) {
        const { data } = await new ReportControllerApi(
          getArrakisConfiguration(),
        ).getAgentOpenTransactionReport(values.agent!.value, fromDate, toDate);

        setReportData(data);
      }

      if (values.reportType.value === ReportType.TERMINATED) {
        const { data } = await new ReportControllerApi(
          getArrakisConfiguration(),
        ).getAgentTerminatedTransactionReport(
          values.agent!.value,
          fromDate,
          toDate,
        );

        setReportData(data);
      }

      if (values.reportType.value === ReportType.REVENUE) {
        const { data } = await new ReportControllerApi(
          getArrakisConfiguration(),
        ).getAgentRevenueSharePaymentsReport(
          values.agent!.value,
          fromDate,
          toDate,
        );

        setReportData(data);
      }
      csvLinkRef?.current?.link.click();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to generate report', e, {
        report: { values },
      });
      dispatch(
        showErrorToast(
          'We had a problem generating the report',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  const hasAgentFieldErrorMessage = !!(errors.agent as FieldError)?.message;

  let filename;
  if (agent?.value && agentById[agent.value]) {
    filename = `${getFullName(agentById[agent.value])}-${
      reportType?.value
    }-transaction-reports.csv`;
  } else {
    filename = 'report.csv';
  }

  return (
    <ZenRoute title='My Reports'>
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'My Reports', url: '/reports' },
        ]}
      >
        <ResourceContainer
          loading={isLoading}
          isEmpty={false}
          resourceName='report'
        >
          <div className='p-4 space-y-4'>
            <div className='py-6 text-2xl'>Reports</div>
            <div className='w-full border rounded-xl border-gray-300 p-5 md:p-10 mt-4 grid grid-cols-1 md:grid-cols-2 gap-8 gap-y-9'>
              <div>
                <ZenControlledSelectInput<ReportFormData, 'reportType'>
                  control={control}
                  rules={{
                    required: 'Select a report',
                  }}
                  options={availableReportOptions}
                  name='reportType'
                  label='Select Report'
                  placeholder='Select'
                  startAdornment={
                    <FontAwesomeIcon
                      icon={faFileLines}
                      className='ml-3 text-blue-500 mr-2.5'
                      size='lg'
                    />
                  }
                />
              </div>
              <div>
                <ZenControlledSelectInput<ReportFormData, 'reportPeriod'>
                  control={control}
                  closeMenuOnSelect={false}
                  styles={colourStyles}
                  customClassName='no-overflow-datepicker no-padding'
                  options={[
                    {
                      value: '3',
                      label: (
                        <div className='hover:bg-primary-blue hover:bg-opacity-20 p-2 px-4'>
                          Last 3 months
                        </div>
                      ),
                    },
                    {
                      value: '6',
                      label: (
                        <div className='hover:bg-primary-blue hover:bg-opacity-20 py-2 px-4'>
                          Last 6 months
                        </div>
                      ),
                    },
                    {
                      value: '12',
                      label: (
                        <div className='hover:bg-primary-blue hover:bg-opacity-20 p-2 px-4'>
                          Last 12 months
                        </div>
                      ),
                    },
                    {
                      value: 'DATE',
                      label: (
                        <div>
                          <ZenControlledDateRangeInput control={control} />
                        </div>
                      ),
                    },
                  ]}
                  name='reportPeriod'
                  label='Select Period'
                  placeholder='Select'
                  rules={{
                    required: 'Select a time period for the report',
                  }}
                  startAdornment={
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className='text-blue-500 ml-3 mr-1'
                      size='lg'
                    />
                  }
                />
              </div>
              {!agentId && isAdmin && <AdminAgentSelector control={control} />}

              {!agentId && isBroker && (
                <div>
                  <BrokerAgentSelector control={control} />
                </div>
              )}

              {!agentId && isTeamAdmin && !isAdmin && (
                <div>
                  <TeamAdminAgentSelector control={control} />
                </div>
              )}

              {(agentId || (!isTeamAdmin && !isAdmin && !isBroker)) && (
                <div>
                  <ZenControlledSelectInput<ReportFormData, 'agent'>
                    control={control}
                    rules={{
                      required:
                        'Select an agent for whom you want to generate a report',
                    }}
                    readOnly
                    options={[
                      {
                        label: (
                          <AvatarLabelComponent
                            avatar={userDetail?.avatar!}
                            firstName={userDetail?.firstName!}
                            lastName={userDetail?.lastName!}
                            emailAddress={userDetail?.emailAddress!}
                            administrativeAreas={areasList}
                          />
                        ),
                        value: userDetail?.id!,
                      },
                    ]}
                    name='agent'
                    label='Select Agent'
                    placeholder=''
                    startAdornment={
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className='text-blue-500 ml-4'
                      />
                    }
                    endAdornment={
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className='text-gray-500 mx-3'
                      />
                    }
                  />
                </div>
              )}
              <div
                className={classNames(
                  'flex',
                  hasAgentFieldErrorMessage ? 'items-center mt-1' : 'items-end',
                )}
              >
                <ZenButton
                  LeftIconComponent={<FontAwesomeIcon icon={faArrowsRotate} />}
                  label='Generate & Download CSV File'
                  variant='primary'
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                  isDisabled={isSubmitting}
                  isFullWidth
                />
              </div>
            </div>

            <CSVLink
              ref={csvLinkRef}
              data={parseCsv(reportData)}
              filename={filename}
              target='_blank'
              className='hidden'
            />
          </div>
        </ResourceContainer>
      </PageLayout>
    </ZenRoute>
  );
};

export default ReportsRoute;
