import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AuthControllerApi,
  IdentitySummaryResponse,
  JwtAuthenticationResponse,
  KeyMakerControllerApi,
  UserControllerApi as KeymakerUserControllerApi,
  LoginRequest,
  MfaControllerApi,
  UserPrincipal,
} from '../openapi/keymaker';
import {
  AgentControllerApi,
  AgentResponse,
  AgentResponseAgentStatusEnum,
  AgentResponseTypeEnum,
  ApplicationResponse,
  CountryControllerApi,
  GetCountriesResponse,
  PayeeDetailsResponse,
  UserControllerApi,
  UserResponse,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import MenuService from '../services/MenuService';
import SessionStorageService from '../services/SessionStorageService';
import { AppDispatch, AppThunk, AsyncResponse, AuthState } from '../types';
import {
  deleteAuthCookie,
  hasAdminRole,
  hasAnnouncerRole,
  hasLeoExpertRole,
  hasLoanOfficerRole,
  hasLoanProcessorRole,
  hasMortgageAdminRole,
  hasMortgagePermission,
  hasMortgageUserRole,
  hasRealTitleOfficerRole,
  hasROMARole,
  hasSuperAdminRole,
  setAuthCookie,
  setUserInLogServices,
} from '../utils/AuthUtils';
import Logger from '../utils/Logger';
import {
  getKeymakerConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { resetApp } from './actions/authActions';
import { showApiErrorModal } from './ErrorSlice';
import { fetchExperiments } from './ExperimentSlice';
import { showErrorToast } from './ToastNotificationSlice';
import { performAsyncRequest } from './utils/SliceUtil';

export const initialState: AuthState = {
  loadingUserDetail: false,
  userDetail: null,
  isSuperAdmin: false,
  isAdmin: false,
  isBroker: false,
  isAnnouncer: false,
  isLoanOfficer: false,
  isMortgageAdmin: false,
  isLoanProcessor: false,
  isROMA: false,
  isMortgageUserRole: false,
  hasMortgagePermission: false,
  isRealTitleOfficer: false,
  isLeoExpert: false,
  tipaltiStatus: null,
  tipaltiLoading: false,
  keymakerCurrentUser: null,
  identityByKeymakerIdResponse: { loading: false, name: 'Identity', data: {} },
  availableCountries: { countries: [] },
  currentUserJwtResponse: null,
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addIdentity(
      state,
      action: PayloadAction<AsyncResponse<IdentitySummaryResponse>>,
    ) {
      state.identityByKeymakerIdResponse.loading = action.payload.loading;
      state.identityByKeymakerIdResponse.data![
        action.payload.data?.id!
      ] = action.payload.data!;
      state.identityByKeymakerIdResponse.name = action.payload.name;
      state.identityByKeymakerIdResponse.error = action.payload.error;
    },
    changeLoading(state, action: PayloadAction<boolean>) {
      state.loadingUserDetail = action.payload;
    },
    saveUserDetail(state, action: PayloadAction<AgentResponse>) {
      state.userDetail = action.payload;
    },
    saveApplication(state, action: PayloadAction<ApplicationResponse>) {
      let applicationIndex = state.userDetail?.applications?.findIndex(
        (a) => a.id === action.payload.id,
      );
      if (applicationIndex !== -1) {
        state.userDetail!.applications![applicationIndex!] = action.payload;
      } else {
        state.userDetail!.applications = [action.payload];
      }
    },
    setIsSuperAdmin(state, action: PayloadAction<boolean>) {
      state.isSuperAdmin = action.payload;
    },
    setIsRealTitleOfficer(state, action: PayloadAction<boolean>) {
      state.isRealTitleOfficer = action.payload;
    },
    setIsAdmin(state, action: PayloadAction<boolean>) {
      state.isAdmin = action.payload;
    },
    setIsBroker(state, action: PayloadAction<boolean>) {
      state.isBroker = action.payload;
    },
    setIsAnnouncer(state, action: PayloadAction<boolean>) {
      state.isAnnouncer = action.payload;
    },
    setIsLoanOfficer(state, action: PayloadAction<boolean>) {
      state.isLoanOfficer = action.payload;
    },
    setIsMortgageAdmin(state, action: PayloadAction<boolean>) {
      state.isMortgageAdmin = action.payload;
    },
    setIsLoanProcessor(state, action: PayloadAction<boolean>) {
      state.isLoanProcessor = action.payload;
    },
    setIsROMA(state, action: PayloadAction<boolean>) {
      state.isROMA = action.payload;
    },
    setIsMortgageUserRole(state, action: PayloadAction<boolean>) {
      state.isMortgageUserRole = action.payload;
    },
    setHasMortgagePermission(state, action: PayloadAction<boolean>) {
      state.hasMortgagePermission = action.payload;
    },
    setIsLeoExpert(state, action: PayloadAction<boolean>) {
      state.isLeoExpert = action.payload;
    },
    saveTipaltiStatus(state, action: PayloadAction<PayeeDetailsResponse>) {
      state.tipaltiStatus = action.payload;
    },
    setTipaltiLoading(state, action: PayloadAction<boolean>) {
      state.tipaltiLoading = action.payload;
    },
    setKeymakerCurrentUser(
      state,
      action: PayloadAction<IdentitySummaryResponse>,
    ) {
      state.keymakerCurrentUser = action.payload;
    },
    saveAvailableCountries(state, action: PayloadAction<GetCountriesResponse>) {
      state.availableCountries = action.payload;
    },
    saveCurrentUserJwtResponse(
      state,
      action: PayloadAction<JwtAuthenticationResponse>,
    ) {
      state.currentUserJwtResponse = action.payload;
    },
  },
});

export const {
  changeLoading,
  saveUserDetail,
  saveApplication,
  setIsSuperAdmin,
  setIsRealTitleOfficer,
  setIsAdmin,
  setIsBroker,
  setIsAnnouncer,
  setIsLoanOfficer,
  setIsMortgageAdmin,
  setIsLoanProcessor,
  setIsROMA,
  setIsMortgageUserRole,
  setHasMortgagePermission,
  setIsLeoExpert,
  setTipaltiLoading,
  saveTipaltiStatus,
  setKeymakerCurrentUser,
  addIdentity,
  saveAvailableCountries,
  saveCurrentUserJwtResponse,
} = AuthSlice.actions;

export const loginUserOrThrow = (
  req: LoginRequest,
): AppThunk<Promise<JwtAuthenticationResponse | null>> => async (dispatch) => {
  const api = new AuthControllerApi(getKeymakerConfiguration());

  try {
    const { data } = await api.authenticateUser(req);

    dispatch(saveCurrentUserJwtResponse(data));
    setAuthCookie(data.accessToken!);

    return data;
  } catch (e) {
    Logger.error('Unable to login user. Throwing error.');

    throw e;
  }
};

export const fetchGenericUserInfo = (): AppThunk<
  Promise<UserResponse | undefined>
> => async (dispatch) => {
  try {
    const { data } = await new UserControllerApi(
      getYentaConfiguration(),
    ).getCurrentGenericUser();

    dispatch(saveUserDetail((data as unknown) as AgentResponse));
    return data;
  } catch (e) {
    ErrorService.notify('Unable to fetch generic user info', e);
    return undefined;
  }
};

export const fetchAuthUserDetail = (
  loading = true,
): AppThunk<Promise<AgentResponse | undefined>> => async (dispatch) => {
  if (loading) {
    dispatch(changeLoading(true));
  }

  try {
    const keymakerCurrentUser = await dispatch(fetchKeymakerCurrentUser(true));

    if (!keymakerCurrentUser) {
      // Do nothing, if the API fails it redirects to /login
      // and if API works, everything will be normal
      return;
    }

    if (hasMortgageUserRole(keymakerCurrentUser?.activeRoles || [])) {
      const res = await dispatch(fetchGenericUserInfo());
      if (res) {
        await dispatch(fetchExperiments(res?.id!));
        setUserInLogServices(
          ({
            ...res,
            accountCountry: res?.agentAccountCountry || '',
          } as unknown) as AgentResponse,
          keymakerCurrentUser,
        );
      }
      return;
    }

    const { data } = await new UserControllerApi(
      getYentaConfiguration(),
    ).getCurrentUser();

    if (data.agentStatus === AgentResponseAgentStatusEnum.Rejected) {
      await dispatch(handleLogout());
      dispatch(
        showErrorToast(
          'Your account is in a rejected status. Please contact support for more information.',
        ),
      );
      return;
    }

    await dispatch(fetchExperiments(data?.id!));
    dispatch(saveUserDetail(data));

    if (data.type === AgentResponseTypeEnum.Broker) {
      dispatch(setIsBroker(true));
    }
    setUserInLogServices(data, keymakerCurrentUser);
    return data;
  } catch (e) {
    deleteAuthCookie();
    return undefined;
  } finally {
    if (loading) {
      dispatch(changeLoading(false));
    }
  }
};

export const checkTipaltiStatus = (): AppThunk => async (dispatch) => {
  dispatch(setTipaltiLoading(true));
  try {
    const { data } = await new AgentControllerApi(
      getYentaConfiguration(),
    ).getTipaltiSignUpUrl();
    dispatch(saveTipaltiStatus(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors('Unable to fetch Tipalti status', e);
    dispatch(
      showErrorToast(
        'We had a problem fetching Tipalti status',
        'Please try again in a few moments.',
      ),
    );
  } finally {
    dispatch(setTipaltiLoading(false));
  }
};

export const fetchKeymakerCurrentUser = (
  showToastMessage: boolean = false,
): AppThunk<Promise<IdentitySummaryResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new KeyMakerControllerApi(
      getKeymakerConfiguration(),
    ).getCurrentUser();

    const isSuperAdmin = hasSuperAdminRole(data.activeRoles || []);
    dispatch(setIsSuperAdmin(isSuperAdmin));

    dispatch(setKeymakerCurrentUser(data));
    if (hasAdminRole(data?.activeRoles || [])) {
      dispatch(setIsAdmin(true));
    }

    const isRealTitleOfficer = hasRealTitleOfficerRole(data.activeRoles || []);
    dispatch(setIsRealTitleOfficer(isRealTitleOfficer));

    if (hasAnnouncerRole(data?.activeRoles || [])) {
      dispatch(setIsAnnouncer(true));
    }
    if (hasLoanOfficerRole(data?.activeRoles || [])) {
      dispatch(setIsLoanOfficer(true));
    }
    if (hasMortgageAdminRole(data?.activeRoles || [])) {
      dispatch(setIsMortgageAdmin(true));
    }
    if (hasLoanProcessorRole(data?.activeRoles || [])) {
      dispatch(setIsLoanProcessor(true));
    }
    if (hasROMARole(data?.activeRoles || [])) {
      dispatch(setIsROMA(true));
    }
    if (hasMortgageUserRole(data?.activeRoles || [])) {
      dispatch(setIsMortgageUserRole(true));
    }
    if (hasMortgagePermission(data?.activeRoles || [])) {
      dispatch(setHasMortgagePermission(true));
    }
    if (hasLeoExpertRole(data?.activeRoles || [])) {
      dispatch(setIsLeoExpert(true));
    }

    return data!;
  } catch (e) {
    if (e.response?.status === 403) {
      deleteAuthCookie();
      dispatch(resetApp());
      MenuService.clear();
      SessionStorageService.clear();
      dispatch(showErrorToast('Your session has expired. Please log back in.'));
    } else if (showToastMessage) {
      dispatch(
        showErrorToast(
          'Oops! Something went wrong. Please refresh the page and try again.',
        ),
      );
    }
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to fetch keymaker current user',
      e,
    );

    return undefined;
  }
};

export const verifyJWT = (
  jwtToken: string,
): AppThunk<Promise<UserPrincipal | null>> => async () => {
  try {
    const { data } = await new AuthControllerApi(
      getKeymakerConfiguration(),
    ).verifyToken(jwtToken);
    return data;
  } catch (e) {
    ErrorService.notify('Unable to verify jwt token', e);
    return null;
  }
};

export const mfaSignIn = (code: number): AppThunk => async (dispatch) => {
  try {
    const { data } = await new MfaControllerApi(
      getKeymakerConfiguration(),
    ).signInWithMfa({
      code: code,
    });
    setAuthCookie(data.accessToken!);
    await dispatch(fetchAuthUserDetail());
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to signin with two factor authentication', e, {
      data: {
        code: code,
      },
    });
    dispatch(
      showErrorToast(
        'We had a problem signing in with two factor authentication',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const fetchIdentityByKeymakerId = (
  keyMakerId: string,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new KeymakerUserControllerApi(getKeymakerConfiguration()).getIdentity(
      keyMakerId,
    );

  await performAsyncRequest(
    dispatch as AppDispatch,
    'Identity',
    addIdentity,
    fetch,
    {
      errorMetadata: { agent: { keyMakerId } },
      skipAuthDatadog: true,
    },
  );
};

export const getAvailableCountries = (
  agentId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await new CountryControllerApi(
      await getYentaConfiguration(),
    ).getCountries();
    dispatch(saveAvailableCountries(data));
  } catch (e) {
    ErrorService.notify('Unable to fetch available countries', e, {
      agent: { id: agentId },
    });
  }
};

export const handleLogout = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new AuthControllerApi(getKeymakerConfiguration()).signOut();
    deleteAuthCookie();
    // Change route to /login to clear our redirectTo params on logout
    window.history.replaceState(undefined, '', '/login');
    dispatch(resetApp());
    MenuService.clear();
    SessionStorageService.clear();
  } catch (e) {
    ErrorService.notify('Unable to logout', e);
    dispatch(
      showErrorToast(
        'We had a problem signing out',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const updateNeedsWillBeneficiaryOnboarding = (
  agentId: string,
  needsWillBeneficiaryOnboarding: boolean,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new AgentControllerApi(
      getYentaConfiguration(),
    ).updateNeedsWillBeneficiaryOnboarding(agentId, {
      needsWillBeneficiaryOnboarding,
    });
    dispatch(saveUserDetail(data));
  } catch (e) {
    ErrorService.notify('Unable to update will beneficiary onboarding', e, {
      agent: { id: agentId },
    });
  }
};

export const fetchIdentity = (
  keyMakerId: string,
): AppThunk<Promise<IdentitySummaryResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const identityResponse = await new KeymakerUserControllerApi(
      getKeymakerConfiguration(),
    ).getIdentity(keyMakerId);
    return identityResponse?.data;
  } catch (e) {
    ErrorService.notify('Unable to fetch user identity details', e, {
      keyMakerId: { keyMakerId },
    });
    dispatch(
      showErrorToast(
        'We had a problem fetching user identity details.',
        'Please try again in a few moments.',
      ),
    );

    return undefined;
  }
};

export default AuthSlice.reducer;
