import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsService from '../../../../services/AnalyticsService';
import {
  getRevenueShareIncome,
  updateWealthGoals,
} from '../../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum, RootState } from '../../../../types';
import { formatMoneyValue } from '../../../../utils/CurrencyUtils';
import { safeTypeConToNum } from '../../../../utils/WealthPlanUtils';
import { useDebounce } from '../../utils';
import RevenueShareIncomeFormInputs from '../../V2/Forms/RevenueShareIncomeFormInputs';
import IncomeProjectionChart from '../../V2/IncomeProjectionChart';
import { GoalsFormInput } from '../../V2/SetGoalsSideBarV2';
import WealthPlanInfoCard from '../../V2/WealthPlanInfoCard';
import { WealthManagementCard } from '../../WealthManagementCard';
import { RevenueShareIncomeTierProgress } from '../RevenueShareIncomeTierProgress';
import WealthPlanSidebarContainer from './WealthPlanSidebarContainer';

const RevenueShareIncomeSidebarContentV2 = () => {
  const dispatch = useDispatch();
  const {
    auth: { userDetail },
    wealthManagement: {
      revenueShareIncomeResponse: {
        loading: isRevenueShareIncomeLoading,
        data: revenueShareIncomeResponse,
      },
      wealthGoalsResponse: { data: wealthGoalsResponse },
    },
  } = useSelector((state: RootState) => state);

  const formControls = useForm<GoalsFormInput>({
    defaultValues: {
      agentReferredPerYear:
        wealthGoalsResponse?.goalDetails.agentReferredPerYear ?? 0,
      agentsAgentReferredPerYear:
        wealthGoalsResponse?.goalDetails.agentsAgentReferredPerYear ?? 0,
    },
  });
  const { watch, handleSubmit } = formControls;
  const formValues = watch([
    'agentReferredPerYear',
    'agentsAgentReferredPerYear',
  ]);

  const goalDetails = wealthGoalsResponse?.goalDetails;
  const { currentPaidEarnings, projectedPaidEarnings, agentsInMyNetwork } =
    revenueShareIncomeResponse || {};

  const isLoading = isRevenueShareIncomeLoading && !revenueShareIncomeResponse;

  useEffect(() => {
    dispatch(getRevenueShareIncome());
  }, [dispatch, wealthGoalsResponse]);

  useDebounce(
    handleSubmit((formData: GoalsFormInput) => {
      const agentReferredPerYear = safeTypeConToNum(
        formData.agentReferredPerYear,
      );
      const agentsAgentReferredPerYear = safeTypeConToNum(
        formData.agentsAgentReferredPerYear,
      );

      if (
        agentReferredPerYear !== goalDetails?.agentReferredPerYear ||
        agentsAgentReferredPerYear !== goalDetails?.agentsAgentReferredPerYear
      ) {
        dispatch(
          updateWealthGoals({
            agentReferredPerYear,
            agentsAgentReferredPerYear,
          }),
        );
      }
    }),
    {
      dependencies: [formValues],
      delay: 500,
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string) => {
      AnalyticsService.instance().logEvent(event);
    }, 500),
    [],
  );

  return (
    <WealthPlanSidebarContainer
      type='REVENUE_SHARE_INCOME'
      loading={isLoading}
      isEmpty={!revenueShareIncomeResponse}
      resourceName='Revenue Share Income'
      showStockForecastInput={false}
    >
      <WealthPlanInfoCard
        label='5 Year Projected Paid Earnings'
        value={formatMoneyValue(projectedPaidEarnings)}
        labelTooltip='Paid Earnings-to-Date, in addition to the 5 year projection of revenue share income, calculated based on the new producing agents you will personally attract and the agents they will attract annually.'
        tooltipAnalyticsEvent={
          AnalyticsEventEnum.WEALTH_PLAN_REV_SHARE_5_YEAR_PROJ_TOOLTIP
        }
        valueSizeVariant='text-3xl'
      />
      <div className='flex flex-row justify-between space-x-3'>
        <WealthPlanInfoCard
          label='Paid Earnings-to-Date'
          value={formatMoneyValue(currentPaidEarnings)}
        />
        <WealthPlanInfoCard
          label='Agents in My Network'
          value={(agentsInMyNetwork || 0).toString()}
        />
      </div>
      <WealthManagementCard>
        <RevenueShareIncomeTierProgress
          numberOfFrontLineAgentsUnlockingTier={
            revenueShareIncomeResponse?.tier1Agents ?? 0
          }
          unlockValue={revenueShareIncomeResponse?.currentTier ?? 1}
          agentsRequiredToUnlockNextTier={
            revenueShareIncomeResponse?.neededAgentsForNextTier ?? 5
          }
          unlockedTier={revenueShareIncomeResponse?.currentTier ?? 1}
        />
        <div
          onClick={() => {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.WEALTH_PLAN_REV_SHARE_VIEW_DETAILS,
            );
            window.open(`/people/${userDetail?.id}/revshare/insights`);
          }}
          className='text-primary-blue font-bold cursor-pointer items-center flex flex-col mt-3'
        >
          View Details →
        </div>
      </WealthManagementCard>
      <IncomeProjectionChart
        earningType='REVENUE_SHARE_INCOME'
        title={{
          text: '5 Year Projected Income',
        }}
      />
      <FormProvider {...formControls}>
        <RevenueShareIncomeFormInputs
          logAnalyticsEvent={logAnalyticsEvent}
          showOnlyAttractionInputs
          noContainer
        />
      </FormProvider>
    </WealthPlanSidebarContainer>
  );
};

export default RevenueShareIncomeSidebarContentV2;
