import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps } from '../types';
import { cn } from '../utils/classUtils';
import FormErrorMessage from './FormErrorMessage';

export interface ControlledPercentageInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  subLabel?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  isRequired?: boolean;
}

const ControlledPercentageInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  tooltipIndex,
  shouldUnregister = true,
  startAdornment,
  endAdornment,
  isRequired = false,
  ...rest
}: ControlledPercentageInputV7Props<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error },
      }) => (
        <div className='w-full space-y-1'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              <span>{label}</span>
              {!!subLabel && (
                <span className='font-primary-regular text-sm text-gray-400 ml-1'>
                  {subLabel}
                </span>
              )}
              {isRequired && <span className='text-error'>*</span>}
            </label>
          )}
          <div
            className={cn(
              'flex items-stretch border rounded overflow-hidden',
              {
                'ring-1 ring-blue-600': focus && !noBorder,
              },
              error && hideErrorMessage
                ? 'border-zen-danger'
                : noBorder
                ? 'border-none'
                : undefined,
            )}
          >
            {startAdornment && <div>{startAdornment}</div>}
            <CurrencyInput
              id={name}
              value={value}
              onFocus={() => setFocus(true)}
              onBlur={() => {
                setFocus(false);
                onBlur();
              }}
              onValueChange={onChange}
              name={name}
              readOnly={readOnly}
              placeholder={placeholder}
              className={cn(
                'appearance-none p-1.5 w-full border-none focus:outline-none focus:ring-0',
                {
                  'bg-gray-50': readOnly,
                },
              )}
              data-tooltip-index={tooltipIndex}
              allowDecimals
              disableGroupSeparators
            />
            {endAdornment && <div>{endAdornment}</div>}
          </div>
          {error && !hideErrorMessage && (
            <FormErrorMessage message={error.message} />
          )}
        </div>
      )}
    />
  );
};

export default ControlledPercentageInputV7;
