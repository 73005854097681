// User idle time in milli-seconds.
export const USER_IDLE_TIMEOUT = 1000 * 60 * 30; // 30 mins.

export const getAge = (dob: string): number => {
  const today = new Date();
  const birthDate = new Date(dob);
  const yearDiff = today.getFullYear() - birthDate.getFullYear();
  const isBeforeBirthday =
    today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() &&
      today.getDate() < birthDate.getDate());

  return isBeforeBirthday ? yearDiff - 1 : yearDiff;
};
