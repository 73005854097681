import { Link } from 'react-router-dom';
import {
  AgentResponse,
  TeamMemberResponseRolesEnum,
  UserPreviewResponse,
} from '../../../../openapi/yenta';
import { ReactComponent as AdminIcon } from '../../../../assets/icons/admin.svg';
import { ReactComponent as CrownIcon } from '../../../../assets/icons/zen/zen-teamscrownfilled.svg';
import { getFullName } from '../../../../utils/AgentHelper';
import ZenAvatar from '../../ZenAvatar';
import { FlexTeamRoles } from '../../../../types';

export interface ZenTeamAgentNameCellProps {
  agent: UserPreviewResponse | AgentResponse;
  role: TeamMemberResponseRolesEnum | FlexTeamRoles;
  showLeaderIcon?: boolean;
  excludeName?: boolean;
}

const ZenTeamAgentNameCell: React.FC<ZenTeamAgentNameCellProps> = ({
  agent,
  role,
  showLeaderIcon = true,
  excludeName = false,
}) => {
  return (
    <div className='flex items-center'>
      <span className='mr-2'>
        <Link to={`/people/${agent?.id}/profile`}>
          <div className='font-zen-body text-sm items-center w-max gap-2 bg-zen-light-blue px-2 py-1.5 rounded-full inline-flex'>
            <ZenAvatar
              name={getFullName(agent!)!}
              size='xs'
              imageUrl={agent?.avatar!}
            />
            {!excludeName && getFullName(agent!)}
          </div>
        </Link>
      </span>
      {!!showLeaderIcon && role === TeamMemberResponseRolesEnum.Leader && (
        <CrownIcon data-testid='crown-icon' className='text-zen-warning-dark' />
      )}
      {role === TeamMemberResponseRolesEnum.Admin && (
        <AdminIcon data-testid='admin-icon' />
      )}
    </div>
  );
};

export default ZenTeamAgentNameCell;
