import { faFilm } from '@fortawesome/pro-regular-svg-icons';
import { faClapperboardPlay, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LegacyRef, useRef, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { MAX_AUDIO_VIDEO_SIZE_200MB } from '../../../constants/AnnouncementConstants';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  bytesToSize,
  isAudioFile,
  isDocSizeInvalid,
  isVideoFile,
} from '../../../utils/FileUtils';
import DefaultLoader from '../../DefaultLoader';
import ZenButton from '../ZenButton';
import ZenCheckBox from '../ZenCheckbox';
import { RootState } from '../../../types';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import { MediaInfoTypeEnum } from '../../../openapi/mercury';
import { CreateAnnouncementFormData } from './CreateAnnouncementSidebarModel';

interface UploadAudioVideoProps {
  setValue: UseFormSetValue<CreateAnnouncementFormData>;
  audio?: string | File;
  video?: string | File;
}

const UploadAudioVideo: React.FC<UploadAudioVideoProps> = ({
  audio,
  video,
  setValue,
}) => {
  const dispatch = useDispatch();
  const { announcementDefinitionMediaInfo } = useSelector(
    (state: RootState) => state.announcement,
  );
  const fileInputRef = useRef<HTMLDivElement>();
  const [willHaveFileUpload, setWillHaveFileUpload] = useState<boolean>(
    !!audio || !!video,
  );

  const audioOrVideoFile = announcementDefinitionMediaInfo?.data?.mediaInfos?.find(
    (mediaInfo) =>
      mediaInfo?.type === MediaInfoTypeEnum.Audio ||
      mediaInfo?.type === MediaInfoTypeEnum.Video,
  );

  const newAudioVideoFileAdded =
    (!!audio && typeof audio === 'object') ||
    (!!video && typeof video === 'object');

  const alreadyAudioVideoFileExists =
    (!!audio && typeof audio === 'string') ||
    (!!video && typeof video === 'string');

  const isAudioOrVideoPreviewVisible =
    newAudioVideoFileAdded || alreadyAudioVideoFileExists;

  const newAudioVideoFileName = newAudioVideoFileAdded
    ? ((audio || video) as File)?.name!
    : audioOrVideoFile?.fileName!;

  const newAudioVideoFileSize = newAudioVideoFileAdded
    ? ((audio || video) as File)?.size
    : audioOrVideoFile?.contentLength;

  const handleFileValidationErrorToasts = (
    isSizeInvalid: boolean,
    isNotAudioOrVideo: boolean,
  ) => {
    if (isSizeInvalid) {
      dispatch(
        showErrorToast(
          `File size exceeds maximum limit of ${bytesToSize(
            MAX_AUDIO_VIDEO_SIZE_200MB,
          )}`,
        ),
      );
    } else if (isNotAudioOrVideo) {
      dispatch(
        showErrorToast(`File type not supported. Upload .mp3/.mp4 file only`),
      );
    }
  };

  const saveFileToForm = (file: File) => {
    const isSizeInvalid = isDocSizeInvalid([file], MAX_AUDIO_VIDEO_SIZE_200MB);
    const isAudio = isAudioFile(file.name);
    const isVideo = isVideoFile(file.name);
    const isNotAudioOrVideo = !isAudio && !isVideo;
    if (isSizeInvalid || isNotAudioOrVideo) {
      handleFileValidationErrorToasts(isSizeInvalid, isNotAudioOrVideo);
      deleteFile();
    } else if (isAudio) {
      setValue('audio', file);
    } else if (isVideo) {
      setValue('video', file);
    }
  };

  const deleteFile = () => {
    setValue('audio', undefined);
    setValue('video', undefined);
  };

  const toggleFileUpload = () => {
    setWillHaveFileUpload(!willHaveFileUpload);
    deleteFile();
  };

  return (
    <div>
      <div
        className='mb-3 flex flex-row items-center cursor-pointer'
        onClick={toggleFileUpload}
      >
        <ZenCheckBox
          value={willHaveFileUpload}
          onChange={toggleFileUpload}
          variant='square'
        />
        <div className='pl-2 flex-grow'>
          <p className='font-zen-body font-semibold text-zen-black'>
            Upload Audio or Video
          </p>
        </div>
      </div>
      {willHaveFileUpload && (
        <div className='pt-2'>
          {isAudioOrVideoPreviewVisible ? (
            <AuthorizationContainer
              asyncResponse={announcementDefinitionMediaInfo}
            >
              <div className='w-1/2 bg-grey-100 py-3 pl-3.5 pr-4.5 flex flex-row items-center rounded-lg'>
                <div className='w-8 h-8 bg-white border border-zen-dark-5 py-4 px-1 mr-3 rounded-md flex flex-row items-center justify-center'>
                  <FontAwesomeIcon
                    icon={faFilm}
                    className='text-zen-dark-5 text-lg'
                  />
                </div>
                <div className='flex-grow px-2 w-5/6'>
                  <p className='text-dark font-zen-body font-semibold text-base truncate'>
                    {newAudioVideoFileName}
                  </p>
                  <p className='text-dark font-zen-body text-sm'>
                    {bytesToSize(newAudioVideoFileSize || 0, 2)}
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faX}
                  className='text-dark text-sm cursor-pointer'
                  onClick={deleteFile}
                  aria-label='delete-audio-video-file'
                />
              </div>
            </AuthorizationContainer>
          ) : (
            <div className='w-1/3'>
              {announcementDefinitionMediaInfo?.loading ? (
                <DefaultLoader />
              ) : (
                <ZenButton
                  label='Upload'
                  variant='primary'
                  LeftIconComponent={
                    <FontAwesomeIcon
                      icon={faClapperboardPlay}
                      className='text-base text-white mr-0.5'
                    />
                  }
                  onClick={() => fileInputRef.current?.click()}
                />
              )}
            </div>
          )}
          <input
            id='announcement-file-upload'
            data-testid='announcement-file-upload'
            className='hidden w-full h-full'
            type='file'
            accept='audio/mp3,video/mp4'
            ref={fileInputRef as LegacyRef<HTMLInputElement>}
            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
              await saveFileToForm(Array.from(e.target.files!)[0] as File);
              e.target.value = '';
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UploadAudioVideo;
