import ZenOutgoingPaymentsComponent from '../components/outgoingPayment/ZenOutgoingPaymentsComponent';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';

const ZenOutgoingPaymentsIndexRoute: React.FC = () => {
  return (
    <ZenRoute title='Outgoing Payments'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Outgoing Payments', url: '/outgoing-payments' },
        ]}
      >
        <ZenOutgoingPaymentsComponent />
      </ZenPageLayout>
    </ZenRoute>
  );
};

export default ZenOutgoingPaymentsIndexRoute;
