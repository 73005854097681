import React, { useEffect, useRef, useState } from 'react';
import {
  LINE_GRAPH_PATH_DATA_ONE,
  LINE_GRAPH_PATH_DATA_TWO,
  LINE_GRAPH_PATH_DATA_THREE,
} from '../../../constants/WealthPlanConstants';
import { EnumMap } from '../../../types';
import { EarningType, PrimaryColor } from '../WealthManagementTypes';

export interface EarningOverviewCurveTooltipInfo {
  label: string;
  value: string;
}

interface EarningOverviewCurveGraphProps {
  earningType: EarningType;
  completionPercentage: number;
  tooltipInfo: EarningOverviewCurveTooltipInfo;
  customLineGraphAttributes?: LineGraphAttributes;
}

interface LineGraphAttributes {
  pathData: string;
  height: number;
  width: number;
  strokeColor: string;
}

const EarningOverviewCurveGraph: React.FC<EarningOverviewCurveGraphProps> = ({
  earningType,
  completionPercentage,
  tooltipInfo,
  customLineGraphAttributes,
}) => {
  const pathRef = useRef<SVGPathElement | null>(null);
  const [dotPosition, setDotPosition] = useState({ x: 0, y: 0 });

  const lineGraphAttributes: EnumMap<
    EarningType,
    LineGraphAttributes | undefined
  > = {
    COMMISSION_INCOME: {
      pathData: LINE_GRAPH_PATH_DATA_ONE,
      height: 67.5,
      width: 311.5,
      strokeColor: PrimaryColor['COMMISSION_INCOME'],
    },
    PRE_CAP_SPP: undefined,
    STOCK_AWARD_FOR_CAPPING: {
      pathData: LINE_GRAPH_PATH_DATA_TWO,
      height: 100.5,
      width: 325,
      strokeColor: PrimaryColor['STOCK_AWARD_FOR_CAPPING'],
    },
    POST_CAP_SPP: undefined,
    ELITE_PRODUCTION_AWARD: {
      pathData: LINE_GRAPH_PATH_DATA_TWO,
      height: 100.5,
      width: 325,
      strokeColor: PrimaryColor['ELITE_PRODUCTION_AWARD'],
    },
    ELITE_CULTURAL_AWARD: {
      pathData: LINE_GRAPH_PATH_DATA_TWO,
      height: 100.5,
      width: 325,
      strokeColor: PrimaryColor['ELITE_CULTURAL_AWARD'],
    },
    REVENUE_SHARE_INCOME: {
      pathData: LINE_GRAPH_PATH_DATA_THREE,
      height: 88.5,
      width: 318,
      strokeColor: PrimaryColor['REVENUE_SHARE_INCOME'],
    },
    STOCK_AWARD_FOR_ATTRACTING: {
      pathData: LINE_GRAPH_PATH_DATA_THREE,
      height: 88.5,
      width: 318,
      strokeColor: PrimaryColor['STOCK_AWARD_FOR_ATTRACTING'],
    },
  };

  if (customLineGraphAttributes) {
    lineGraphAttributes[earningType] = customLineGraphAttributes;
  }

  useEffect(() => {
    if (pathRef.current) {
      if (!pathRef.current['getTotalLength']) return;
      const pathLength = pathRef.current.getTotalLength();
      const { x, y } = pathRef.current.getPointAtLength(
        pathLength * (completionPercentage / 100),
      );
      setDotPosition({ x, y });
    }
  }, [completionPercentage]);

  const renderLineGraph = () => {
    const { pathData, strokeColor } = lineGraphAttributes[earningType] || {};

    return (
      <path
        ref={pathRef}
        d={pathData}
        stroke={strokeColor}
        strokeWidth={1.5}
        strokeLinecap='round'
        fill='transparent'
      />
    );
  };

  const renderDot = () => {
    const { strokeColor } = lineGraphAttributes[earningType] || {};

    return (
      <circle
        id='dot'
        cx={dotPosition.x}
        cy={dotPosition.y}
        r={4}
        fill={strokeColor}
        stroke='white'
        strokeWidth={2}
      />
    );
  };

  const getTooltipPosition = (): React.CSSProperties => {
    const graphWidth = lineGraphAttributes[earningType]?.width!;
    if (graphWidth - dotPosition.x > 100) {
      return { left: dotPosition.x + 6 };
    }
    return {
      right: lineGraphAttributes[earningType]?.width! - dotPosition.x + 6,
    };
  };

  return (
    <div className='relative'>
      <div
        className='min-w-max flex items-center px-1.5 py-0.5 text-xs font-normal bg-white rounded-[4px] border border-grey-100'
        style={{
          position: 'absolute',
          ...getTooltipPosition(),
          top: dotPosition.y - 10,
          zIndex: 10,
        }}
      >
        <span className='text-zen-dark-12 shrink-0'>{tooltipInfo.label}</span>
        <span className='text-black ml-1'> {tooltipInfo.value}</span>
      </div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={lineGraphAttributes[earningType]?.width}
        height={lineGraphAttributes[earningType]?.height}
        viewBox={`0 0 ${lineGraphAttributes[earningType]?.width} ${lineGraphAttributes[earningType]?.height}`}
        fill='none'
      >
        {renderLineGraph()}
        {renderDot()}
      </svg>
    </div>
  );
};

export default EarningOverviewCurveGraph;
