import React from 'react';
import { cn } from '../../../utils/classUtils';
import { FeedbackOption } from './ControlledSentimentFeedbackInput';

interface CustomFeedbackGroupRadioButtonProps {
  items: FeedbackOption[];
  onChange(value: string): void;
  value: string;
  disabled?: boolean;
}

const CustomFeedbackGroupRadioButton: React.FC<CustomFeedbackGroupRadioButtonProps> = ({
  items,
  value,
  onChange,
  disabled,
}) => {
  return (
    <div
      className='flex flex-row items-center md:space-x-3 space-x-1'
      key={items.map((i) => i.value).join()}
    >
      {items.map((item) => {
        const isActive = item.value === value;
        return (
          <button
            className={cn(
              'flex-grow flex-shrink items-center text-center md:w-10 md:h-28 h-24 w-1/5 border outline-none focus:outline-none rounded-md',
              isActive
                ? 'bg-[#F5F5F5] border-2 border-[#000000] bg-opacity-20'
                : 'bg-white border-gray-300 bg-opacity-95',
            )}
            type='button'
            name={item.label}
            key={item.label}
            onClick={() => onChange(item.value)}
            disabled={disabled}
          >
            <div className='flex flex-col justify-center items-center'>
              <div>{item.icon}</div>
              {isActive && (
                <span className='font-primary-medium flex-wrap text-xs mt-2 text-gray-700 font-semibold'>
                  {item.label}
                </span>
              )}
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default CustomFeedbackGroupRadioButton;
