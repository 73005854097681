import React, { useState } from 'react';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenSuperAdminComponent from '../components/Zen/SuperAdmin/ZenSuperAdminComponent';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenPasswordGuard from './ZenPasswordGuard';

export const SUPER_ADMIN_PASSWORD = 'donttellanyone';

interface ZenSuperAdminIndexRouteProps {}

const ZenSuperAdminIndexRoute: React.FC<ZenSuperAdminIndexRouteProps> = () => {
  const [authenticated, setAuthenticated] = useState(false);

  return (
    <ZenRoute title='Super Admin'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Super Admin', url: '/superadmin' },
        ]}
      >
        {!authenticated ? (
          <ZenPasswordGuard
            password={SUPER_ADMIN_PASSWORD}
            setAuthenticated={setAuthenticated}
          />
        ) : (
          <ZenSuperAdminComponent />
        )}
      </ZenPageLayout>
    </ZenRoute>
  );
};

export default ZenSuperAdminIndexRoute;
