import { useParams } from 'react-router-dom';
import ZenRoute from '../components/Zen/ZenRoute';
import CDAForm from './CDAForm';

type Match = {
  id: string;
  participantId?: string;
};

const TransactionTradeRecordSheetPdfRoute: React.FC = () => {
  const { id, participantId } = useParams() as Match;

  return (
    <ZenRoute title='Transaction Trade Record Sheet'>
      <CDAForm commissionDocumentId={id} participantId={participantId} isPdf />
    </ZenRoute>
  );
};

export default TransactionTradeRecordSheetPdfRoute;
