import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ItemResponse } from '../../../../openapi/sherlock';
import {
  CommentControllerApi,
  PostCommentBodyReaderRoleEnum,
  QueryCommentsRs,
  RezenObjectTypeEnum,
  RichText,
} from '../../../../openapi/yada';
import {
  fetchChecklistItemCommentsCount,
  saveLatestComment,
} from '../../../../slices/CheckListSlice';
import { RootState } from '../../../../types';
import { getYadaConfiguration } from '../../../../utils/OpenapiConfigurationUtils';
import { MentionSource } from '../../../transactions/Comments/ZenCommentSection';
import { addAutoTagMentionsInComments } from '../../../../utils/CommentUtils';
import ZenCommentsCell from './ZenCommentsCell';
import ZenLastCommentCell from './ZenLastCommentCell';

export const LOAD_MORE_COMMENT_COUNT = 10;

interface ZenChecklistItemCommentsCellProps {
  itemResponse: ItemResponse;
  containerType: RezenObjectTypeEnum;
  containerId: string;
  getMentions?(searchTerm?: string): MentionSource[];
  callerGroupId?: string;
}

const ZenChecklistItemCommentsCell: React.FC<ZenChecklistItemCommentsCellProps> = ({
  itemResponse,
  containerType,
  containerId,
  getMentions,
  callerGroupId,
}) => {
  const dispatch = useDispatch();
  const {
    checklist: { latestCommentsByChecklistItemId },
    userIds: { agentByKeymakerId },
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
    },
  } = useSelector((state: RootState) => state);
  const [isCommenting, setIsCommenting] = useState<boolean>(false);
  const isAutoTagAdminInCommentEnabled =
    containerType === RezenObjectTypeEnum.Transaction;

  const fetchComments = async (
    pageStart: string | undefined,
  ): Promise<QueryCommentsRs> => {
    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).getCommentsByReference(
      RezenObjectTypeEnum.ChecklistItem,
      itemResponse?.id!,
      LOAD_MORE_COMMENT_COUNT,
      pageStart,
      'USER',
    );

    return data;
  };

  const onSubmit = async (
    richContent: RichText,
    readerRole: PostCommentBodyReaderRoleEnum,
  ) => {
    const updatedRichContent = addAutoTagMentionsInComments(
      isAutoTagAdminInCommentEnabled,
      transactionDetail?.otherParticipants || [],
      richContent,
    );

    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).postComment(
      containerType,
      containerId,
      'USER',
      {
        richContent: updatedRichContent,
        readerRole: readerRole,
      },
      RezenObjectTypeEnum.ChecklistItem,
      itemResponse?.id!,
      itemResponse.name,
      callerGroupId,
    );

    dispatch(fetchChecklistItemCommentsCount(['CI#' + itemResponse.id!]));

    dispatch(
      saveLatestComment({ comment: data, checklistItemId: itemResponse.id! }),
    );

    return data;
  };

  if (isCommenting) {
    return (
      <ZenCommentsCell
        fetchComments={fetchComments}
        getMentions={getMentions}
        onSubmit={onSubmit}
        onClose={() => setIsCommenting(false)}
      />
    );
  }

  return (
    <ZenLastCommentCell
      comment={latestCommentsByChecklistItemId.data![itemResponse.id!]}
      agentByKeymakerId={agentByKeymakerId}
      onClick={() => setIsCommenting(true)}
    />
  );
};

export default ZenChecklistItemCommentsCell;
