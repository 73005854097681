import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import React, { useState } from 'react';
import ZenResurrectAgentSidebarModal from '../../../forms/ZenResurrectAgentSidebarModal';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface ZenResurrectAgentProps {}

const ZenResurrectAgent: React.FC<ZenResurrectAgentProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <ZenResourceLinkCard
        title='Resurrect an Agent'
        onClick={() => setIsOpen(true)}
        icon={solid('arrow-alt-up')}
        iconSize='2x'
      />
      <ZenResurrectAgentSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenResurrectAgent;
