import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { DateTime } from 'luxon';
import React, { useEffect, useRef } from 'react';
import { FiMail } from 'react-icons/fi';
import { CalendarEventResponse } from '../../../openapi/yenta';
import {
  handleAddToCalendarModalOpen,
  isYesOrMaybeRsvpStatus,
} from '../../../utils/EventHelper';
import ZenIconText from '../ZenIconText';

export interface ZenAddToCalendarButtonProps {
  event: CalendarEventResponse;
}

const ZenAddToCalendarButton: React.FC<ZenAddToCalendarButtonProps> = ({
  event,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.addEventListener('click', (e) => {
      handleAddToCalendarModalOpen(e, event);
    });

    return () => {
      ref.current?.removeEventListener('click', (e) => {
        handleAddToCalendarModalOpen(e, event);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div aria-label='add-to-calendar' ref={ref}>
      {isYesOrMaybeRsvpStatus(event.rsvpStatus) ? (
        <AddToCalendarButton
          name={event.title}
          startDate={DateTime.fromMillis(event.startAt!).toFormat('yyyy-MM-dd')}
          startTime={DateTime.fromMillis(event.startAt!).toLocaleString(
            DateTime.TIME_24_SIMPLE,
          )}
          endDate={DateTime.fromMillis(event.endAt!).toFormat('yyyy-MM-dd')}
          endTime={DateTime.fromMillis(event.endAt!).toLocaleString(
            DateTime.TIME_24_SIMPLE,
          )}
          location={event.location || ''}
          options={[
            'Apple',
            'Google',
            'Microsoft365',
            'MicrosoftTeams',
            'Outlook.com',
            'Yahoo',
            'iCal',
          ]}
          size='4'
          trigger='hover'
          description={event.description}
          iCalFileName={event.title}
          listStyle='modal'
          timeZone='currentBrowser'
          styleLight='--btn-background: #3B82F6; --font: DIN Next W01 Medium;  --btn-text: #fff; --btn-background-hover: #3B82F6; --btn-text-hover: #fff; --btn-shadow: none; --btn-shadow-hover: none; --btn-shadow-active: none; --overlay-cursor: default;'
          hideCheckmark
        />
      ) : (
        <ZenIconText
          icon={<FiMail size={18} />}
          variant='small'
          text='RSVP to Add to Calendar'
          containerClassName='text-zen-dark-12'
          textClassName='text-zen-dark-12'
        />
      )}
    </div>
  );
};

export default ZenAddToCalendarButton;
