import { useEffect, useState } from 'react';
import { useGoogleMapsAPILoader } from './useGoogleMapsAPILoader';

const useGooglePlaceId = (placeId: string | undefined) => {
  const isLoaded = useGoogleMapsAPILoader();
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState<google.maps.GeocoderResult | undefined>();

  const getGooglePlaceFromPlaceId = async (placeId: string) => {
    setLoading(true);
    const geoCoder = new google.maps.Geocoder();
    const geoCode = await geoCoder.geocode({
      placeId,
    });

    if (geoCode) {
      setPlace(geoCode.results[0]);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (placeId && isLoaded) {
      getGooglePlaceFromPlaceId(placeId);
    } else if (!placeId) {
      setPlace(undefined);
    }
  }, [placeId, isLoaded]);

  return { place, loading };
};

export default useGooglePlaceId;
