import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

interface ZenCheckReviewProps {
  label: string;
  url: string;
}

const ZenCheckReview: React.FC<ZenCheckReviewProps> = ({ label, url }) => {
  return (
    <div>
      <label>{label}</label>
      <div className='w-full md:w-80 h-36 border border-dashed border-gray-400 my-4 relative rounded'>
        <img
          src={url}
          alt='review-check'
          className='w-4/5 h-28 my-4 mx-auto object-contain'
        />
        <FontAwesomeIcon
          icon={faCheckCircle}
          size='xl'
          className='absolute right-3 bottom-2 text-green-600'
        />
      </div>
    </div>
  );
};

export default ZenCheckReview;
