import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLogout } from '../../../hooks/useLogout';
import { MfaControllerApi } from '../../../openapi/keymaker';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { ErrorCode } from '../../../types';
import { getKeymakerConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { PHONE_NUMBER_VALIDATIONS } from '../../../utils/Validations';
import GeminiFeatureFlagButton from '../../GeminiFeatureFlagButton';
import ZenControlledPhoneNumberInput from '../../Zen/Input/ZenControlledPhoneNumberInput';
import ZenSidebarModalForm from '../../Zen/ZenSidebarModalForm';

interface FormData {
  phoneNumber: string;
}

interface ZenAgentTextMessageAuthenticationMethodSideBarModalFormProps {
  isOpen: boolean;
  onClose(): void;
  setPhoneNumber(value: string): void;
}

const ZenAgentTextMessageAuthenticationMethodSideBarModalForm: React.FC<ZenAgentTextMessageAuthenticationMethodSideBarModalFormProps> = ({
  isOpen,
  onClose,
  setPhoneNumber,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const logout = useLogout();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (values: FormData) => {
    try {
      await new MfaControllerApi(getKeymakerConfiguration()).sendMfaSms(
        values.phoneNumber,
      );
      setPhoneNumber(values.phoneNumber);
      onClose();
      dispatch(
        showSuccessToast(
          'Successfully sent authentication code to phone number.',
        ),
      );
    } catch (e) {
      const errorCode = ErrorService.getErrorCode(e);
      if (
        errorCode === ErrorCode.UNAUTHORIZED ||
        errorCode === ErrorCode.FORBIDDEN
      ) {
        await logout();
        dispatch(
          showErrorToast('Your session has expired. Please log back in.'),
        );
        history.push('/login');
        return;
      }
      dispatch(showApiErrorModal(e));
      ErrorService.notify(
        'Unable to send authentication code to phone number',
        e,
        {
          data: { phoneNumber: values.phoneNumber },
        },
      );
      dispatch(
        showErrorToast(
          'We had a problem sending authentication code to phone number',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <ZenSidebarModalForm
      isOpen={isOpen}
      onClose={onClose}
      isSubmitting={isSubmitting}
      title='Two-Factor Authentication: Text Messages (SMS)'
      subtitle='Receive verification codes via text messages'
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Send Confirmation Code'
      cancelTitle='Back'
      hideFooterActions
    >
      <div>
        <p className='font-zen-body font-normal text-zen-dark-9'>
          Enter your mobile phone number.
        </p>
        <p className='font-zen-body font-normal text-zen-dark-9 text-sm mt-2'>
          We’ll send you a text message with a confirmation code to this number
        </p>
        <div className='mt-5'>
          <ZenControlledPhoneNumberInput<FormData, 'phoneNumber'>
            control={control}
            name='phoneNumber'
            placeholder='+1 (702) 123-4567'
            rules={{
              required: 'Please enter your phone number',
              ...PHONE_NUMBER_VALIDATIONS,
            }}
          />
        </div>
      </div>
      <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2 w-full'>
        <GeminiFeatureFlagButton
          onClick={onClose}
          label='Back'
          zenVariant='primary-outline'
          geminiVariant='primary-outline'
          isFullWidth
        />
        <GeminiFeatureFlagButton
          isSubmitting={isSubmitting}
          type='submit'
          label='Send Confirmation Code'
          isFullWidth
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenAgentTextMessageAuthenticationMethodSideBarModalForm;
