import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Control, FieldErrors } from 'react-hook-form-v7';
import { get } from 'lodash';
import {
  LeaderSplitDto,
  ParticipantResponse,
  ParticipantResponseParticipantRoleEnum,
} from '../../../openapi/arrakis';
import { parseMoney } from '../../../utils/CurrencyUtils';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import { participantRoleDisplayName } from '../../../utils/TransactionHelper';
import {
  MONEY_VALIDATIONS,
  PERCENT_VALIDATION,
} from '../../../utils/Validations';
import ZenControlledCurrencyInput from '../Input/ZenControlledCurrencyInput';
import ZenControlledPercentageInput from '../Input/ZenControlledPercentageInput';
import ZenControlledToggleInput from '../Input/ZenControlledToggleInput';
import ZenAvatar from '../ZenAvatar';
import ZenFormErrorMessage from '../Input/ZenFormErrorMessage';
import { cn } from '../../../utils/classUtils';
import { EditTransactionFormData } from './ZenEditTransactionParticipantFormSidebarModal';

interface ZenEditProLeaderSplitFormProps {
  control: Control<EditTransactionFormData, object>;
  fields: Record<'id', string>[];
  participants?: ParticipantResponse[];
  update: (index: number, value: Partial<unknown>) => void;
  proLeaders: LeaderSplitDto[];
  errors: FieldErrors<EditTransactionFormData>;
  readOnly?: boolean;
}

const ZenEditProLeaderSplitForm: React.FC<ZenEditProLeaderSplitFormProps> = ({
  control,
  fields,
  participants,
  update,
  proLeaders,
  errors,
  readOnly = false,
}) => {
  const toggleSwitches = (index: number, item: any) => {
    const updatedItem = {
      ...item,
      commission: {
        ...item.commission,
        percentEnabled: !item?.commission?.percentEnabled,
      },
    };
    update(index, updatedItem);
  };

  const checkPercentageError = (index: number) => {
    const leaders = proLeaders.filter(
      (leader, leaderIndex) =>
        leader.commission.percentEnabled && leaderIndex !== index,
    );
    const totalPercentage = leaders.reduce(
      (total, leader) =>
        total + Number(leader.commission?.commissionPercent || 0),
      0,
    );
    return 100 - totalPercentage;
  };

  return (
    <div className='divide-y space-y-2'>
      <ul>
        {fields.map((item: any, index: number) => {
          const participant = participants?.find(
            (p: any) => p.id === item.teamLeaderParticipantId,
          )!;

          const error = get(
            errors,
            `proLeaders.${index}.commission.commissionPercent`,
          );
          return (
            <section
              className='flex flex-row justify-between py-1 px-2 md:px-4'
              key={participant?.id}
            >
              <div className='flex flex-row space-x-1'>
                <div className='flex flex-row space-x-4 items-center'>
                  <ZenAvatar
                    name={getParticipantName(participant) || 'N/A'}
                    imageUrl={item.avatar!}
                    size='sm'
                  />
                  <div className='flex md:flex-row flex-col'>
                    <div className='flex flex-col'>
                      <div className='flex items-start space-x-2.5'>
                        <p className='font-zen-body font-normal md:text-base text-sm text-zen-dark-9'>
                          {getParticipantName(participant)}
                        </p>
                        {participant?.participantRole! ===
                          ParticipantResponseParticipantRoleEnum.TeamLeader && (
                          <FontAwesomeIcon
                            icon={solid('crown')}
                            size='lg'
                            className='text-zen-warning-dark'
                          />
                        )}
                      </div>
                      <p className='font-zen-body font-medium md:text-sm text-xs text-zen-dark-6'>
                        {participant?.participantRole
                          ? participantRoleDisplayName(
                              participant?.participantRole!,
                            )
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-row items-center space-x-3'>
                <div
                  onClick={() => (!readOnly ? toggleSwitches(index, item) : {})}
                >
                  <ZenControlledToggleInput
                    label=''
                    name={`proLeaders.${index}.commission.percentEnabled`}
                    control={control}
                    shouldUnregister={false}
                    defaultValue={item.commission.percentEnabled}
                    rightIcon='%'
                    leftIcon='$'
                    readOnly={readOnly}
                  />
                </div>
                <div>
                  <div
                    className={cn(
                      'my-1 rounded-lg shadow-sm',
                      proLeaders[index]?.commission.percentEnabled
                        ? 'flex'
                        : 'hidden',
                    )}
                  >
                    <span
                      className='text-sm inline-flex items-center px-1.5 md:px-2 rounded-l-lg border border-r-0  text-primary-blue sm:text-sm'
                      //   checkPercentageError(index)
                      //     ? 'border-zen-danger'
                      //     : 'border-gray-300',
                    >
                      %
                    </span>
                    <span
                      className='w-20 rounded-none rounded-r-lg sm:text-sm border border-l-0'
                      //   checkPercentageError(index)
                      //     ? 'border-zen-danger'
                      //     : 'border-gray-300',
                    >
                      <ZenControlledPercentageInput
                        control={control}
                        name={`proLeaders.${index}.commission.commissionPercent`}
                        shouldUnregister={false}
                        defaultValue={item.commission.commissionPercent}
                        rules={{
                          required: proLeaders[index]?.commission.percentEnabled
                            ? 'Required'
                            : undefined,
                          pattern: proLeaders[index]?.commission.percentEnabled
                            ? PERCENT_VALIDATION?.pattern!
                            : undefined,
                          max: proLeaders[index]?.commission.percentEnabled
                            ? {
                                value: checkPercentageError(index),

                                message: 'Total > 100%',
                              }
                            : undefined,
                        }}
                        noBorder
                        readOnly={readOnly}
                        placeholder='Percentage'
                        hideErrorMessage
                      />
                    </span>
                  </div>
                  <div
                    className={cn(
                      'my-1 rounded shadow-sm',
                      proLeaders[index]?.commission.percentEnabled
                        ? 'hidden'
                        : 'flex',
                    )}
                  >
                    <span
                      className='text-sm inline-flex items-center px-1.5 md:px-2.5 rounded-l-lg border border-r-0 text-primary-blue sm:text-sm'
                      //   checkAmountError(index)
                      //     ? 'border-zen-danger'
                      //     : 'border-gray-300',
                    >
                      $
                    </span>
                    <span
                      className='w-20 rounded-none rounded-r-lg text-sm border border-l-0'
                      //   checkAmountError(index)
                      //     ? 'border-zen-danger'
                      //     : 'border-gray-300',
                    >
                      <ZenControlledCurrencyInput
                        control={control}
                        name={`proLeaders.${index}.commission.commissionAmount.amount`}
                        shouldUnregister={false}
                        noBorder
                        defaultValue={
                          item?.commission?.commissionAmount &&
                          parseMoney(
                            +item?.commission?.commissionAmount?.amount || 0,
                          )
                        }
                        readOnly={readOnly}
                        rules={{
                          required: proLeaders[index]?.commission.percentEnabled
                            ? undefined
                            : 'Required',
                          pattern: proLeaders[index]?.commission.percentEnabled
                            ? undefined
                            : MONEY_VALIDATIONS?.pattern!,
                        }}
                        placeholder='Amount'
                      />
                    </span>
                  </div>
                  {error && <ZenFormErrorMessage message={error.message} />}
                </div>
              </div>
            </section>
          );
        })}
      </ul>
    </div>
  );
};

export default ZenEditProLeaderSplitForm;
