import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  UserAvailabilityResponse,
  UserControllerApi,
} from '../../../../openapi/yenta';
import { queryKeys } from '../../../../query/base/queryKeys';
import { useBaseMutation } from '../../../../query/base/useBaseMutation';
import { saveLoggedInAgentDetails } from '../../../../slices/AgentSlice';
import { AppDispatch, RootState } from '../../../../types';
import { getYentaConfiguration } from '../../../../utils/OpenapiConfigurationUtils';
import { formatDateRange } from '../../../../utils/RoarUtils';
import ZenSimpleModal from '../../../Zen/Modal/ZenSimpleModal';
import ZenButton from '../../../Zen/ZenButton';

type RemoveOutOfOfficeProps = {
  agentId: string;
  onClose(): void;
};

const RemoveOutOfOffice: React.FC<RemoveOutOfOfficeProps> = ({
  agentId,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const agentDetail = useSelector(
    (state: RootState) => state.agentDetail.detailResponse.data,
  );
  const userAvailability = (agentDetail?.availability ||
    {}) as UserAvailabilityResponse;
  const outOfOffice = userAvailability.outOfOffice?.[0]!;

  const { mutate, isLoading } = useBaseMutation({
    queryKey: queryKeys.roar.officeHours(agentId).queryKey,
    mutationFn: async () => {
      const { data } = await new UserControllerApi(
        getYentaConfiguration(),
      ).patchUser(agentId, {
        userAvailability: {
          ...userAvailability,
          outOfOffice: [],
        },
      });
      dispatch(
        saveLoggedInAgentDetails({
          ...agentDetail,
          availability: data.availability,
        }),
      );
      onClose();
    },
    successMessage: 'Settings Saved',
    errorMessage: 'Failed to save settings',
  });

  return (
    <ZenSimpleModal
      title='Delete Time Off?'
      isOpen
      onClose={onClose}
      size='large'
    >
      <div className='font-inter flex flex-col items-center justify-center text-center mt-5'>
        <p>This will remove the following scheduled time off:</p>
        <p className='font-semibold'>{formatDateRange(outOfOffice)}</p>
      </div>
      <div className='mt-6 flex justify-center space-x-3 pb-5'>
        <ZenButton
          label='Cancel'
          variant='dark-outline'
          className='!px-10'
          onClick={onClose}
        />
        <ZenButton
          label='Confirm'
          variant='danger'
          className='!px-10'
          onClick={mutate}
          isDisabled={isLoading}
        />
      </div>
    </ZenSimpleModal>
  );
};

export default RemoveOutOfOffice;
