import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { EnumMap } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { ZenButtonType } from '../../Zen/ZenButton';
import GeminiButton, { GeminiButtonVariant } from '../GeminiButton';
import { ZenSimpleModalSizeVariantType } from '../GeminiSimpleModal';

export interface GeminiConfirmationModalProps {
  variant?: GeminiButtonVariant;
  cancelButtonVariant?: GeminiButtonVariant;
  title?: string;
  isOpen: boolean;
  subtitle?: string | React.ReactElement;
  hideIcon?: boolean;
  size?: ZenSimpleModalSizeVariantType;
  onClose?(info: { isX: boolean }): void;
  onConfirm?(): void;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  cancelButtonText?: string;
  cancelButtonLeftIcon?: IconProp;
  confirmButtonText?: string;
  confirmButtonLeftIcon?: IconProp;
  hideFooterButtons?: boolean;
  hideCancelButton?: boolean;
  confirmButtonType?: ZenButtonType;
  containerClassNames?: string;
  titleClassName?: string;
  onIconClose?(): void;
}

const GeminiConfirmationModal: React.FC<GeminiConfirmationModalProps> = ({
  variant = 'primary',
  cancelButtonVariant = 'dark-outline',
  title,
  isOpen,
  subtitle,
  onClose,
  hideIcon = false,
  size = 'default',
  onConfirm,
  isSubmitting,
  isDisabled,
  cancelButtonText = 'Cancel',
  cancelButtonLeftIcon,
  confirmButtonText = 'Confirm',
  confirmButtonLeftIcon,
  hideFooterButtons = false,
  hideCancelButton = false,
  confirmButtonType = 'button',
  containerClassNames,
  titleClassName,
  onIconClose,
  children,
}) => {
  const sizeVariant: EnumMap<ZenSimpleModalSizeVariantType, string> = {
    default: 'md:w-1/2 lg:w-1/4',
    large: 'md:w-3/5 lg:w-1/3',
    small: 'md:w-2/5 lg:w-1/5',
    extraLarge: 'md:w-4/5 lg:w-6/12',
    fixed: 'w-[420]px',
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-primary-dark bg-opacity-40'
        role='button'
        onClick={() => onClose?.({ isX: true })}
      />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
        <div
          className={cn(
            'w-full bg-white rounded-xl z-10 shadow-zen-web',
            containerClassNames || sizeVariant[size],
          )}
          aria-label='confirmation-modal'
        >
          <div
            className={cn(
              'flex flex-row items-center bg-white px-3 py-1 rounded-t-xl border-[#CCCCCC]',
              { 'border-b': title },
            )}
          >
            {!hideIcon && <div className='w-9 mx-2' />}

            <div className='flex flex-grow flex-shrink flex-col items-center justify-center gap-y-2 my-3'>
              {title && (
                <p
                  className={cn(
                    'text-lg font-medium text-center font-zen-body-2 text-primary-dark',
                    titleClassName,
                  )}
                >
                  {title}
                </p>
              )}
            </div>

            {!hideIcon && (
              <div className='w-6 h-6 flex items-center justify-center bg-zen-dark-10 rounded-full mx-2 my-3'>
                <FontAwesomeIcon
                  icon={faXmark}
                  className='text-primary-light cursor-pointer'
                  onClick={() =>
                    onIconClose ? onIconClose() : onClose?.({ isX: true })
                  }
                />
              </div>
            )}
          </div>
          <div className='w-full'>
            {subtitle && (
              <p className='text-base text-center font-zen-body-2 text-primary-dark p-6'>
                {subtitle}
              </p>
            )}
            {children}
            {!hideFooterButtons && (
              <div className='flex flex-row my-3 space-x-3 px-6 py-4 justify-center'>
                {!hideCancelButton && (
                  <GeminiButton
                    variant={cancelButtonVariant}
                    label={cancelButtonText}
                    onClick={() => onClose?.({ isX: false })}
                    leftIcon={cancelButtonLeftIcon}
                  />
                )}
                <GeminiButton
                  label={confirmButtonText}
                  variant={variant}
                  onClick={onConfirm}
                  isDisabled={isDisabled}
                  isSubmitting={isSubmitting}
                  leftIcon={confirmButtonLeftIcon}
                  type={confirmButtonType}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeminiConfirmationModal;
