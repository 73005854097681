import { Controller, UseControllerOptions } from 'react-hook-form-v6';
import { get } from 'lodash';
import { FieldValues } from 'react-hook-form-v6/dist/types';
import { FieldErrors } from 'react-hook-form-v6/dist/types/errors';
import { cn } from '../utils/classUtils';
import { FormFieldTooltipIndexProps, ISelectOption } from '../types';
import FormErrorMessage from './FormErrorMessage';

interface ControlledRadioInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends UseControllerOptions<TFieldValues>,
    FormFieldTooltipIndexProps {
  errors: FieldErrors<TFieldValues>;
  label?: string;
  options: Array<ISelectOption>;
  inline?: boolean;
  disabled?: boolean;
}

const ControlledRadioInput: React.FC<ControlledRadioInputProps> = ({
  errors,
  control,
  name,
  label,
  defaultValue,
  rules,
  options,
  inline = false,
  disabled = false,
  tooltipIndex,
}) => {
  const error = get(errors, name);

  return (
    <div className='space-y-1 w-full'>
      {label && <label htmlFor={name}>{label}</label>}
      <Controller
        id={name}
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        render={({ name, value, onChange, onBlur }) => (
          <div
            className={cn(inline ? 'flex space-x-3' : 'space-y-1')}
            data-tooltip-index={tooltipIndex}
          >
            {options.map(({ value: optionValue, label }) => (
              <label
                key={optionValue}
                className='flex items-center justify-start space-x-2'
              >
                <input
                  type='radio'
                  className='rounded-full border-none ring-1 ring-gray-200 focus:outline-none focus:ring-0 h-4 w-4 text-primary'
                  value={optionValue}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  checked={value === optionValue}
                  disabled={disabled}
                />
                <p>{label}</p>
              </label>
            ))}
          </div>
        )}
      />
      {error && <FormErrorMessage message={error.message} />}
    </div>
  );
};

export default ControlledRadioInput;
