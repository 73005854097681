import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import RenderRoleBasedComponent, {
  RouteComponentUserRoleMappingConfig,
} from '../containers/RenderRoleBasedComponent';
import { AgentResponseAgentStatusEnum } from '../openapi/yenta';
import { RootState } from '../types';
import Logger from '../utils/Logger';

export interface RouteByRoleProps extends Omit<RouteProps, 'render'> {
  config?: RouteComponentUserRoleMappingConfig;
}

const RouteByRole: React.FC<RouteByRoleProps> = ({
  component: Component,
  config,
  ...rest
}) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userDetail) {
          Logger.debug('Not logged in. Redirecting to /');
          return <Redirect to='/' />;
        }

        if (userDetail.agentStatus === AgentResponseAgentStatusEnum.Candidate) {
          Logger.debug(
            'RouteByRoleRoute: user is candidate, going to application form',
          );
          return <Redirect to='/onboarding/application-form' />;
        }

        return !!config ? (
          <RenderRoleBasedComponent config={config} {...props} />
        ) : Component ? (
          <Component {...props} />
        ) : null;
      }}
    />
  );
};

export default RouteByRole;
