import {
  GoalDetails,
  MoneyValueCurrencyEnum,
  ProspectiveAgentInfoCountryEnum,
} from '../../../../openapi/plutus';
import {
  getAverageFixedCommissionEndOfDeal,
  getTeamDefaultValues,
  getTeamInfo,
} from '../../../../utils/WealthPlanUtils';
import { SendWealthPlanStepByStepFormData } from '../../SendWealthPlan/V2/SendWealthPlanStepByStepFormV2';
import { GoalsFormInput } from './EditReferralGoalsSidebarV2';

const PROJECTION_YEARS = 5;

interface AgentName {
  firstname: string;
  lastname: string;
}

export const wealthPlanReferralGoalsToQueryParams = (
  goals: GoalDetails,
  accountCountry: ProspectiveAgentInfoCountryEnum,
  referringAgentName: AgentName,
  realAgentName: AgentName,
) => {
  const queryGoals = {
    ...goals,
    earningsGoalValue: 0,
    realStockFuturePrice: goals.realStockFuturePrice?.amount ?? 0,
    volumePerYear: goals.volumePerYear?.amount ?? 0,
    accountCountry,
    referringAgentFirstName: referringAgentName.firstname,
    referringAgentLastName: referringAgentName.lastname,
    realAgentFirstName: realAgentName.firstname,
    realAgentLastName: realAgentName.lastname,
    averageHomeSalePrice: goals.averageHomeSalePrice?.amount ?? 0,
    teamAverageSalesPrice: goals.teamAverageSalesPrice?.amount ?? 0,
    teamAverageFixedCommission: goals.teamAverageFixedCommission?.amount ?? 0,
    averageFixedCommission: goals.averageFixedCommission?.amount ?? 0,
  };
  return Object.keys(queryGoals)
    .map((key) => `${key}=${queryGoals[key as keyof GoalDetails]}`)
    .join('&');
};
export const queryParamsToWealthPlanReferralGoals = (
  params: URLSearchParams,
): GoalDetails | undefined => {
  const useAverageFixedCommission =
    params.get('useAverageFixedCommission') === 'true';
  const teamUseAverageFixedCommission =
    params.get('teamUseAverageFixedCommission') === 'true';
  const accountCountry = params.get('accountCountry');

  if (!accountCountry) {
    return undefined;
  }

  const currency =
    params.get('accountCountry') === ProspectiveAgentInfoCountryEnum.Canada
      ? MoneyValueCurrencyEnum.Cad
      : MoneyValueCurrencyEnum.Usd;

  return {
    agentReferredPerYear: Number(params.get('agentReferredPerYear')),
    agentsAgentReferredPerYear: Number(
      params.get('agentsAgentReferredPerYear'),
    ),
    additionalRealShares: Number(params.get('additionalRealShares')),
    averageFixedCommission: useAverageFixedCommission
      ? {
          amount: Number(params.get('averageFixedCommission')),
          currency,
        }
      : undefined,
    averageHomeSalePrice: {
      amount: Number(params.get('averageHomeSalePrice')),
      currency,
    },
    averageTransactionCommissionPercentage: !useAverageFixedCommission
      ? Number(params.get('averageTransactionCommissionPercentage'))
      : undefined,
    earningsGoalValue: {
      amount: 0,
      currency,
    },
    eliteAgentCulturalAwardParticipation:
      params.get('eliteAgentCulturalAwardParticipation') === 'true',
    eliteAgentProductionAwardParticipation:
      params.get('eliteAgentProductionAwardParticipation') === 'true',
    planId: '',
    planStartDate: new Date().toISOString(),
    projectionYears: PROJECTION_YEARS,
    realStockFuturePrice: {
      amount: Number(params.get('realStockFuturePrice')),
      currency: MoneyValueCurrencyEnum.Usd,
    },
    realTitleJVParticipation: false,
    realTitleJVShares: 0,
    revShareParticipation: params.get('revShareParticipation') === 'true',
    seenEliteCulturalPage: false,
    seenEliteProductionPage: false,
    sppParticipation: params.get('sppParticipation') === 'true',
    teamAverageFixedCommission: teamUseAverageFixedCommission
      ? {
          amount: Number(params.get('teamAverageFixedCommission')),
          currency,
        }
      : undefined,
    teamAverageSalesPrice: {
      amount: Number(params.get('teamAverageSalesPrice')),
      currency,
    },
    teamAverageTransactionCommissionPercentage: !teamUseAverageFixedCommission
      ? Number(params.get('teamAverageTransactionCommissionPercentage'))
      : undefined,
    teamLeaderCommissionPercentagePerTransaction: Number(
      params.get('teamLeaderCommissionPercentagePerTransaction'),
    ),
    teamLead: params.get('teamLead') === 'true',
    teamNumberCount: Number(params.get('teamNumberCount')),
    teamTransactionsPerMember: Number(params.get('teamTransactionsPerMember')),
    teamUseAverageFixedCommission,
    transactionsPerYear: Number(params.get('transactionsPerYear')),
    useAverageFixedCommission,
    volumePerYear: {
      amount: Number(params.get('volumePerYear')),
      currency,
    },
  };
};

export const wealthGoalsInputToGoalDetails = (
  formData: GoalsFormInput,
  currency: MoneyValueCurrencyEnum,
): GoalDetails => ({
  ...getAverageFixedCommissionEndOfDeal(formData, currency),
  ...getTeamInfo(formData, currency),
  additionalRealShares: formData.additionalRealShares,
  agentReferredPerYear: formData.agentReferredPerYear,
  agentsAgentReferredPerYear: formData.agentsAgentReferredPerYear,
  averageHomeSalePrice: {
    amount: formData.averageHomeSalePrice,
    currency,
  },
  earningsGoalValue: {
    amount: formData.earningsGoalValue,
    currency: currency,
  },
  eliteAgentCulturalAwardParticipation:
    formData.eliteAgentCulturalAwardParticipation,
  eliteAgentProductionAwardParticipation:
    formData.eliteAgentProductionAwardParticipation,
  planId: '',
  planStartDate: new Date().toISOString(),
  projectionYears: PROJECTION_YEARS,
  realStockFuturePrice: {
    amount: formData.realStockFuturePrice,
    currency: MoneyValueCurrencyEnum.Usd,
  },
  realTitleJVParticipation: false,
  realTitleJVShares: 0,
  revShareParticipation:
    formData.agentReferredPerYear > 0 ||
    formData.agentsAgentReferredPerYear > 0,
  seenEliteCulturalPage: false,
  seenEliteProductionPage: false,
  sppParticipation: formData.sppParticipation,
  transactionsPerYear: formData.transactionsPerYear,
  volumePerYear: {
    amount: formData.volumePerYear,
    currency,
  },
});

export const formDataToGoalDetails = (
  data: SendWealthPlanStepByStepFormData,
  forPreview: boolean = false,
): GoalDetails => {
  const isUsAgent =
    data?.country.value === ProspectiveAgentInfoCountryEnum.UnitedStates;
  const currency = isUsAgent
    ? MoneyValueCurrencyEnum.Usd
    : MoneyValueCurrencyEnum.Cad;

  return {
    ...getAverageFixedCommissionEndOfDeal(data, currency),
    ...getTeamInfo(data, currency, forPreview),
    additionalRealShares: 0,
    agentReferredPerYear: data?.agentReferredPerYear,
    agentsAgentReferredPerYear: data?.agentsAgentReferredPerYear,
    averageHomeSalePrice: {
      amount: data.averageHomeSalePrice,
      currency,
    },
    earningsGoalValue: {
      amount: data?.earningsGoalValue,
      currency,
    },
    eliteAgentCulturalAwardParticipation: true,
    eliteAgentProductionAwardParticipation: true,
    planId: '',
    planStartDate: new Date().toISOString(),
    projectionYears: 5,
    realStockFuturePrice: {
      amount: data?.realStockFuturePrice,
      currency: MoneyValueCurrencyEnum.Usd,
    },
    realTitleJVParticipation: false,
    realTitleJVShares: 0,
    revShareParticipation:
      data?.agentReferredPerYear > 0 || data?.agentsAgentReferredPerYear > 0,
    seenEliteCulturalPage: false,
    seenEliteProductionPage: false,
    sppParticipation: data?.sppParticipation,
    transactionsPerYear: data?.transactionsPerYear,
    volumePerYear: {
      amount: data?.volumePerYear,
      currency,
    },
  };
};

export const getDefaultFormData = (
  defaultGoals: GoalDetails | undefined,
): SendWealthPlanStepByStepFormData => ({
  additionalRealShares: defaultGoals?.additionalRealShares || 10,
  agentReferredPerYear: defaultGoals?.agentReferredPerYear || 4,
  agentsAgentReferredPerYear: defaultGoals?.agentsAgentReferredPerYear || 2,
  averageFixedCommission: defaultGoals?.averageFixedCommission?.amount || 0,
  averageHomeSalePrice: defaultGoals?.averageHomeSalePrice?.amount || 300000,
  averageTransactionCommissionPercentage:
    defaultGoals?.averageTransactionCommissionPercentage ?? 0.1,
  brokerageName: '',
  country: {
    label: 'USA',
    value: ProspectiveAgentInfoCountryEnum.UnitedStates,
  },
  email: '',
  emailMessage: '',
  emailSubject: '',
  earningsGoalValue: 0,
  eliteAgentCulturalAwardParticipation:
    defaultGoals?.eliteAgentProductionAwardParticipation ?? false,
  eliteAgentProductionAwardParticipation:
    defaultGoals?.eliteAgentProductionAwardParticipation ?? false,
  firstName: '',
  lastName: '',
  ownsAdditionalRealStockShares: !!defaultGoals?.additionalRealShares,
  phoneNumber: '',
  realStockFuturePrice: defaultGoals?.realStockFuturePrice.amount || 10,
  revShareParticipation: defaultGoals?.revShareParticipation ?? false,
  volumePerYear: defaultGoals?.volumePerYear?.amount ?? 1000000,
  sppParticipation: defaultGoals?.sppParticipation ?? true,
  transactionsPerYear: defaultGoals?.transactionsPerYear || 12,
  useAverageFixedCommission: defaultGoals?.useAverageFixedCommission || false,
  ...getTeamDefaultValues(defaultGoals),
});
