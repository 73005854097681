import { faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircleSplitChart from '../CircleSplitChart';
import {
  CommissionSplitParticipantValue,
  PaymentParticipantValue,
} from '../../openapi/arrakis';
import { displayAmount } from '../../utils/CurrencyUtils';
import ResourceContainer from '../ResourceContainer';
import { Mapping } from '../../types';
import { getParticipantName } from '../../utils/ParticipantHelper';

interface TransactionCommissionSplitReviewProps {
  commissionSplit: CommissionSplitParticipantValue[];
  paymentParticipants: PaymentParticipantValue[];
}

const colors: string[] = [
  '#05C3F9',
  '#007DBB',
  '#003C77',
  '#00284e',
  '#00162d',
];
const TransactionCommissionSplitReview: React.FC<TransactionCommissionSplitReviewProps> = ({
  commissionSplit,
  paymentParticipants,
}) => {
  const participantIdToName: Mapping<{ name: string }> = {};

  paymentParticipants.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.yentaId!]: { name: getParticipantName(item) },
      }),
    participantIdToName,
  );

  const circleSplitData = commissionSplit.map((item, index) => {
    return {
      name: participantIdToName[item.yentaId!]?.name,
      value: item.percentage?.value,
      fill: colors[index % colors.length],
    };
  });
  return (
    <ResourceContainer
      loading={false}
      isEmpty={!commissionSplit.length}
      resourceName='Commission Split'
      emptyIcon={<FontAwesomeIcon icon={faDollarSign} size='xl' />}
    >
      <div className='grid grid-flow-row lg:grid-flow-col items-center'>
        <div>
          <CircleSplitChart
            data={circleSplitData}
            width={200}
            xKey='name'
            yKey='value'
          />
        </div>
        <div className='px-3'>
          {commissionSplit.map((item, index) => {
            return (
              <div
                key={item.yentaId}
                className='flex flex-row justify-between py-3'
              >
                <div className='flex flex-row space-x-2'>
                  <div
                    className='w-1.5 rounded max-h-10'
                    style={{
                      backgroundColor: colors[index % colors.length],
                    }}
                  />
                  <div>
                    <p className='font-primary-medium text-gray-800'>
                      {participantIdToName[item.yentaId!]?.name}
                    </p>
                    <p className='font-primary-light'>
                      {item.percentage?.string} split
                    </p>
                  </div>
                </div>
                <div>
                  <p className='font-primary-regular'>
                    {displayAmount(item.amount)}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </ResourceContainer>
  );
};

export default TransactionCommissionSplitReview;
