import { VictoryPie } from 'victory';
import type { AnimatePropTypeInterface, NumberOrCallback } from 'victory-core';

interface Data {
  fill?: string;
}

export interface CircleSplitChartProps<T> {
  data: T[];
  xKey: keyof T;
  yKey: keyof T;
  width: number;
  innerRadius?: number;
  padAngle?: NumberOrCallback;
  animate?: boolean | AnimatePropTypeInterface;
}

const CircleSplitChart = <T extends Data>({
  data,
  width,
  xKey,
  yKey,
  innerRadius = 70,
  padAngle = 0,
  animate,
}: CircleSplitChartProps<T>): React.ReactElement => (
  <VictoryPie
    width={width}
    height={width * 0.9}
    innerRadius={innerRadius}
    labels={() => null}
    style={{
      data: {
        fill: ({ datum }) => datum.fill,
      },
    }}
    animate={animate}
    padding={40}
    padAngle={padAngle}
    data={data}
    x={String(xKey)}
    y={String(yKey)}
  />
);

export default CircleSplitChart;
