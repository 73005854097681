import { useState } from 'react';
import { faG } from '@fortawesome/pro-regular-svg-icons';
import ZenEnableOrDisableGooglessoFormSidebarModal from '../../../forms/ZenEnableOrDisableGooglessoFormSideBarModal';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface ZenEnableForceGoogleSSOProps {}

const ZenEnableForceGoogleSSO: React.FC<ZenEnableForceGoogleSSOProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div>
      <ZenResourceLinkCard
        title='Enable/Disable Force Google SSO'
        onClick={() => setIsOpen(true)}
        icon={faG}
        iconSize='2x'
      />
      <ZenEnableOrDisableGooglessoFormSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenEnableForceGoogleSSO;
