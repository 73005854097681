import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import GeminiCreateBoardForm from '../components/board/Gemini/GeminiCreateBoardForm';
import ZenCreateBoardForm from '../components/board/Zen/ZenCreateBoardForm';
import ZenBoardResourceTable from '../components/Zen/board/ZenBoardResourceTable';
import ZenButton from '../components/Zen/ZenButton';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenRoute from '../components/Zen/ZenRoute';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { FeatureFlagTypeEnum } from '../types';

const ZenBoardSearchRoute: React.FC = () => {
  const location = useLocation();
  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );

  const [openCreateBoardForm, setOpenCreateBoardForm] = useState<boolean>(
    false,
  );

  const isBoardAndMlsImprovementsFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.BOARD_AND_MLS_IMPROVEMENTS,
  );

  const CreateBoardSidebarModal = isBoardAndMlsImprovementsFlagEnabled
    ? GeminiCreateBoardForm
    : ZenCreateBoardForm;

  return (
    <ZenRoute title='Boards'>
      <ZenPageLayoutWithSearch
        path={[
          { title: 'Home', url: '/' },
          { title: 'Board', url: '/boards' },
        ]}
        search={search}
        onSearchChange={setSearch}
      >
        <ZenBoardResourceTable
          search={search}
          RightComponent={
            <ZenButton
              LeftIconComponent={
                <FontAwesomeIcon icon={faPlus} className='mx-1' />
              }
              label='Create a Board'
              variant='primary'
              onClick={() => {
                setOpenCreateBoardForm(true);
              }}
            />
          }
        />
        {openCreateBoardForm && (
          <CreateBoardSidebarModal
            isOpen
            onClose={() => setOpenCreateBoardForm(false)}
          />
        )}
      </ZenPageLayoutWithSearch>
    </ZenRoute>
  );
};

export default ZenBoardSearchRoute;
