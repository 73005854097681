import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IdentitySummaryResponseMfaTypeEnum } from '../../openapi/keymaker';
import { AgentResponse } from '../../openapi/yenta';
import SessionStorageService from '../../services/SessionStorageService';
import { fetchIdentityByKeymakerId } from '../../slices/AuthSlice';
import {
  FeatureFlagTypeEnum,
  RootState,
  SessionStorageKeyEnum,
  YesNoType,
} from '../../types';
import { createLoginAsToken } from '../../utils/AgentHelper';
import { getAuthCookie, setAuthCookie } from '../../utils/AuthUtils';
import FeatureFlagEnabledOnly from '../FeatureFlagEnabledOnly';
import ZenButton from '../Zen/ZenButton';
import AdminDelete2FAModal from './security/AdminDelete2FAModal';

interface AgentDetailActionBarProps {
  agentInfo: AgentResponse;
  handleClearCache: () => void;
}

const ZenAgentDetailActionBar: React.FC<AgentDetailActionBarProps> = ({
  agentInfo,
  handleClearCache,
}) => {
  const dispatch = useDispatch();
  const {
    identityByKeymakerIdResponse: { data },
  } = useSelector((state: RootState) => state.auth);
  const agentKeyMaker = data && data[agentInfo.keyMakerId!];

  const [clearingCache, setClearingCache] = useState<boolean>(false);
  const [
    isRemovingTwoFactorAuthentication,
    setIsRemovingTwoFactorAuthentication,
  ] = useState<boolean>(false);
  const handleLoginAsAgent = (): void => {
    SessionStorageService.setItem(
      SessionStorageKeyEnum.STOP_PAGE_REDIRECTS,
      YesNoType.YES,
    );
    const token = getAuthCookie();
    const newToken = createLoginAsToken(token!, agentInfo.keyMakerId!);
    setAuthCookie(newToken);
    window.location.reload();
  };

  const doClearCache = async () => {
    setClearingCache(true);
    await handleClearCache();
    setClearingCache(false);
  };

  useEffect(() => {
    dispatch(fetchIdentityByKeymakerId(agentInfo.keyMakerId!));
  }, [agentInfo.keyMakerId, dispatch]);

  return (
    <div className='md:ml-auto md:flex md:items-center md:space-x-2 md:space-y-0 space-y-2'>
      {(agentKeyMaker?.mfaType ===
        IdentitySummaryResponseMfaTypeEnum.Authenticator ||
        agentKeyMaker?.mfaType === IdentitySummaryResponseMfaTypeEnum.Sms) && (
        <ZenButton
          label='Remove 2FA'
          onClick={() => setIsRemovingTwoFactorAuthentication(true)}
          variant='secondary-gray-outline'
        />
      )}
      <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.LOGIN_AS_AGENT}>
        <ZenButton
          variant='secondary-gray-outline'
          label='Login As...'
          onClick={handleLoginAsAgent}
        />
      </FeatureFlagEnabledOnly>
      <ZenButton
        label='Clear Cache'
        isSubmitting={clearingCache}
        onClick={doClearCache}
        variant='secondary-gray-outline'
      />
      <AdminDelete2FAModal
        isOpen={isRemovingTwoFactorAuthentication}
        onClose={() => {
          dispatch(fetchIdentityByKeymakerId(agentInfo.keyMakerId!));
          setIsRemovingTwoFactorAuthentication(false);
        }}
        keymakerId={agentInfo.keyMakerId!}
      />
    </div>
  );
};

export default ZenAgentDetailActionBar;
