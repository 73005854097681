import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../openapi/arrakis';
import { setActiveTransition } from '../../slices/QuickActionSlice';
import { KBarActionEnum, RootState } from '../../types';
import {
  getActionableTransitions,
  getTerminatedTransition,
  isTransactionReadOnly,
} from '../../utils/TransactionHelper';
import BrokerOnly from '../auth/BrokerOnly';
import ZenMarkListingAsInContractButtonContainer from '../listings/ZenMarkListingAsInContractButtonContainer';
import TerminateTransactionConfirmationModal from '../Zen/Modal/TerminateTransactionConfirmationModal';
import ZenTransactionQuickActionSidebarModal from '../Zen/Transaction/ZenTransactionQuickActionSidebarModal';
import ZenButton from '../Zen/ZenButton';
import { useNavigateIfNotCurrentRoute } from '../../hooks/useNavigateIfNotCurrentRoute';

interface ZenListingDetailSecondaryHeaderProps {
  transaction: TransactionResponse;
}

const ZenListingDetailSecondaryHeader: React.FC<ZenListingDetailSecondaryHeaderProps> = ({
  transaction,
}) => {
  const [
    isQuickActionSidebarOpen,
    setIsQuickActionSidebarOpen,
  ] = useState<boolean>(false);
  const [
    showTerminationTransactionModal,
    setShowTerminationTransactionModal,
  ] = useState(false);
  const {
    auth: { isAdmin, userDetail, isBroker },
    kBar: { sidebarToOpen },
  } = useSelector((state: RootState) => state);
  const navigateIfNotCurrentRoute = useNavigateIfNotCurrentRoute();
  const dispatch = useDispatch();
  const transitionInfo = getActionableTransitions(transaction, isAdmin);

  const isEditListingDisabled =
    transaction.lifecycleState?.state ===
    TransactionLifecycleStateValueStateEnum.ListingClosed;

  const terminateTransition = getTerminatedTransition(transaction);

  const terminationRequestedTransition = transitionInfo.secondaryTransitions.find(
    (item) =>
      item.state ===
      TransactionLifecycleStateValueStateEnum.TerminationRequested,
  );

  const preActions = () => {
    navigateIfNotCurrentRoute(`/listings/${transaction.id}/detail`);
  };

  useEffect(() => {
    if (sidebarToOpen === KBarActionEnum.EDIT_LISTING) {
      setIsQuickActionSidebarOpen(true);
    }
  }, [sidebarToOpen]);

  return (
    <>
      <div className='p-4 flex flex-wrap gap-4 justify-between'>
        <div
          className='flex items-center justify-between space-x-4'
          id='transaction-edit'
        >
          {(!isTransactionReadOnly(transaction, userDetail!) || isAdmin) &&
            !isBroker && (
              <ZenButton
                label='Edit Listing'
                LeftIconComponent={
                  <FontAwesomeIcon icon={faPen} className='mr-1' />
                }
                variant='secondary-gray-outline'
                onClick={() => setIsQuickActionSidebarOpen(true)}
                isDisabled={isEditListingDisabled}
              />
            )}

          <ZenMarkListingAsInContractButtonContainer listing={transaction} />
        </div>
        <div className='md:flex flex-row items-center space-y-4 md:space-y-0 md:space-x-4'>
          {terminateTransition && (
            <BrokerOnly>
              <ZenButton
                label='Terminate'
                LeftIconComponent={
                  <FontAwesomeIcon
                    title='delete'
                    icon={faTrash}
                    className='text-white mr-1'
                    size='sm'
                  />
                }
                onClick={() => {
                  preActions();
                  dispatch(setActiveTransition(terminateTransition));
                }}
                variant='danger'
              />
            </BrokerOnly>
          )}

          {terminationRequestedTransition && (
            <BrokerOnly>
              <ZenButton
                label='Request Termination'
                LeftIconComponent={
                  <FontAwesomeIcon
                    title='delete'
                    icon={faTrash}
                    className='text-white mr-1'
                    size='sm'
                  />
                }
                onClick={() => {
                  setShowTerminationTransactionModal(true);
                }}
                variant='danger'
              />
            </BrokerOnly>
          )}
        </div>
      </div>
      <ZenTransactionQuickActionSidebarModal
        isOpen={isQuickActionSidebarOpen}
        onClose={() => setIsQuickActionSidebarOpen(false)}
        transaction={transaction!}
        secondaryTransitions={transitionInfo.secondaryTransitions}
        setShowTerminationTransactionModal={setShowTerminationTransactionModal}
      />
      <TerminateTransactionConfirmationModal
        transaction={transaction!}
        onClose={() => setIsQuickActionSidebarOpen(false)}
        secondaryTransitions={transitionInfo.secondaryTransitions}
        setShowTerminationTransactionModal={setShowTerminationTransactionModal}
        showTerminationTransactionModal={showTerminationTransactionModal}
      />
    </>
  );
};

export default ZenListingDetailSecondaryHeader;
