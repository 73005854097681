import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { AnnouncementDefRequestDriverEnum } from '../../../openapi/mercury';
import { RootState } from '../../../types';
import ZenControlledDatePickerInput from '../Input/ZenControlledDatePickerInput';
import { CreateAnnouncementFormData } from './CreateAnnouncementSidebarModel';

interface TimeOrEventAnnouncementTypeProps {
  control: Control<CreateAnnouncementFormData, object>;
  driver: AnnouncementDefRequestDriverEnum;
  startDate?: string;
  setValue: UseFormSetValue<CreateAnnouncementFormData>;
}
const TimeOrEventAnnouncementType: React.FC<TimeOrEventAnnouncementTypeProps> = ({
  control,
  driver,
  startDate,
  setValue,
}) => {
  const { announcementDefinitionDetail } = useSelector(
    (state: RootState) => state.announcement,
  );

  const deliverAfterDate = !!announcementDefinitionDetail?.deliverAfter
    ? DateTime.fromMillis(announcementDefinitionDetail?.deliverAfter).toISO()
    : undefined;

  useEffect(() => {
    if (!isEqual(startDate, deliverAfterDate)) {
      setValue('expiresAt', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, startDate]);

  if (!driver) {
    return null;
  }

  return (
    <div>
      <div className='flex flex-row items-center'>
        <div>
          <p className='font-zen-body font-medium text-base leading-6 text-dark'>
            2. Select Announcement Period
          </p>
        </div>
      </div>
      {driver === AnnouncementDefRequestDriverEnum.Time && (
        <div className='mt-8'>
          <div>
            <div className='flex flex-row items-center'>
              <p className='w-full font-zen-body font-medium text-base leading-6 text-dark'>
                Select{' '}
                <span className='font-semibold underline'>Date & Time</span> to
                send this announcement
              </p>
            </div>
            <div className='flex flex-row items-center mt-4'>
              <FontAwesomeIcon
                icon={faArrowRightLong}
                className='text-zen-dark-8 text-base mr-4.5'
              />
              <div>
                <ZenControlledDatePickerInput<
                  CreateAnnouncementFormData,
                  'deliverAfter'
                >
                  control={control}
                  name='deliverAfter'
                  placeholder='MM/DD/YYYY'
                  shouldUnregister={false}
                  datePickerConfig={{
                    showTimeSelect: true,
                    timeIntervals: 15,
                    dateFormat: 'MM/dd/yyyy h:mm aa',
                  }}
                  icon={
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className='text-primary-blue text-lg mt-1 ml-2'
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className='mt-5'>
            <div className='flex flex-row items-center'>
              <p className='w-full font-zen-body font-medium text-base leading-6 text-dark'>
                Select{' '}
                <span className='font-semibold underline'>Expiration Date</span>{' '}
                of this announcement
              </p>
            </div>
            <div className='flex flex-row items-center mt-4'>
              <FontAwesomeIcon
                icon={faArrowRightLong}
                className='text-zen-dark-8 text-base mr-4.5'
              />
              <div>
                <ZenControlledDatePickerInput<
                  CreateAnnouncementFormData,
                  'expiresAt'
                >
                  control={control}
                  name='expiresAt'
                  placeholder='MM/DD/YYYY'
                  shouldUnregister={false}
                  datePickerConfig={{
                    showTimeSelect: true,
                    timeIntervals: 15,
                    dateFormat: 'MM/dd/yyyy h:mm aa',
                    minDate: startDate
                      ? DateTime.fromISO(startDate).plus({ days: 1 }).toJSDate()
                      : undefined,
                  }}
                  icon={
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className='text-primary-blue text-lg mt-1 ml-2'
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeOrEventAnnouncementType;
