import {
  faBallotCheck,
  faPlus,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { ActionBarButtonsProps } from '../components/TableRowActionBar';
import ZenRouterTabs from '../components/Zen/Tab/ZenRouterTabs';
import ZenButton from '../components/Zen/ZenButton';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import LQMActionCell from '../components/leo/LQMActionCell';
import LQMAssignedQueueTab from '../components/leo/LQMAssignedQueueTab';
import AssigneeCell from '../components/leo/LQMAssigneeCell';
import LQMBulkAssignment from '../components/leo/LQMBulkAssignment';
import LQMBulkDeleteModal from '../components/leo/LQMBulkDeleteModal';
import LQMCompletedQueueTab from '../components/leo/LQMCompletedQueueTab';
import LQMCreatedByCell from '../components/leo/LQMCreatedByCell';
import LQMGeoIconCell from '../components/leo/LQMGeoIconCell';
import LQMSidebarModalForm from '../components/leo/LQMSidebarModalForm';
import LQMWorkQueueTab from '../components/leo/LQMWorkQueueTab';
import useQueryParamFiltering from '../components/leo/hooks/useQueryParamFiltering';
import useAgentsInfo from '../hooks/useAgentsInfo';
import { useRefresh } from '../hooks/useRefresh';
import {
  LeoQuestionManagerApi,
  PaginatedPairResponse,
  PairBase,
} from '../openapi/leo';
import {
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
} from '../openapi/yenta';
import { fetchLeoQuestionAssignees } from '../slices/LeoSlice';
import { ISelectOption, RootState } from '../types';
import { getLeoConfiguration } from '../utils/OpenapiConfigurationUtils';

interface LeoQuestionManagerRouteProps {}

export interface LeoTableHeaderFormData {
  bulkAssigneeId: ISelectOption;
  assignedQueueAssigneeId: ISelectOption;
  completedQueueAssigneeId: ISelectOption;
  assigneeId: ISelectOption;
  selectedPairIds: string[];
  workQueueCustomStartDate: string | undefined;
  workQueueCustomEndDate: string | undefined;
  assignedCustomStartDate: string | undefined;
  assignedCustomEndDate: string | undefined;
  completedCustomStartDate: string | undefined;
  completedCustomEndDate: string | undefined;
  workQueueSearch: string;
  assignedSearch: string;
  completedSearch: string;
  workQueueCountryFilter: string;
  workQueueStatesFilter: string[];
  assignedQueueCountryFilter: string;
  assignedQueueStatesFilter: string[];
  completedQueueCountryFilter: string;
  completedQueueStatesFilter: string[];
  loading: boolean;
}

export interface fetchQueueParams {
  isCompleted: boolean;
  isWorkQueue: boolean;
  searchString?: string;
  pageNumber: number;
  pageSize: number;
  startDate: string | undefined;
  endDate: string | undefined;
  assigneeId: string | undefined;
  country?: string;
  states?: string[];
}

export enum TABS_ENUM {
  WORK_QUEUE = 'WORK_QUEUE',
  ASSIGNED_QUEUE = 'ASSIGNED_QUEUE',
  COMPLETED_QUEUE = 'COMPLETED_QUEUE',
}

const LeoQuestionManagerRoute: React.FC<LeoQuestionManagerRouteProps> = () => {
  const {
    auth: { userDetail },
    leo: {
      leoQuestionManager: { assignedQueue, completedQueue, workQueue },
      assigneeUserIds,
    },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const { key, refresh } = useRefresh();
  const formControls = useForm<LeoTableHeaderFormData>({
    defaultValues: {
      assignedQueueAssigneeId: {
        value: userDetail?.id!,
        label: 'Me',
      },
      completedQueueAssigneeId: {
        value: userDetail?.id!,
        label: 'Me',
      },
      workQueueSearch: '',
      assignedSearch: '',
      completedSearch: '',
      loading: true,
    },
    reValidateMode: 'onChange',
  });
  const { control, watch, setValue, handleSubmit } = formControls;
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState<boolean>(
    false,
  );
  const [createdByIds, setCreatedByIds] = useState<string[]>([]);

  const [
    loading,
    assignedQueueAssigneeId,
    completedQueueAssigneeId,
    workQueueSearch,
    assignedSearch,
    completedSearch,
    workQueueCountryFilter,
    workQueueStatesFilter,
    assignedQueueCountryFilter,
    assignedQueueStatesFilter,
    completedQueueCountryFilter,
    completedQueueStatesFilter,
  ] = watch([
    'loading',
    'assignedQueueAssigneeId',
    'completedQueueAssigneeId',
    'workQueueSearch',
    'assignedSearch',
    'completedSearch',
    'workQueueCountryFilter',
    'workQueueStatesFilter',
    'assignedQueueCountryFilter',
    'assignedQueueStatesFilter',
    'completedQueueCountryFilter',
    'completedQueueStatesFilter',
  ]);

  const columns = useMemo<Column<PairBase>[]>(() => {
    return [
      {
        Header: 'Created Date',
        accessor: 'created_at',
        Cell: ({ value }: any) => {
          const date = value
            ? DateTime.fromISO(value)?.toFormat('LL/dd/yy')
            : 'N/A';

          return (
            <div
              className={classNames(
                'font-zen-body font-normal text-sm',
                value ? 'text-zen-dark-9' : 'text-zen-dark-5',
              )}
            >
              {date}
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Created By',
        accessor: 'user_id',
        Cell: ({ value }) => <LQMCreatedByCell userId={value} />,
        disableSortBy: true,
      },
      {
        Header: 'Assigned To',
        accessor: 'assignee_id',
        Cell: ({ row: { original } }) => <AssigneeCell original={original} />,
        disableSortBy: true,
      },
      {
        Header: 'Question',
        accessor: 'question',
        Cell: ({ value }: any) => <p>{value}</p>,
        disableSortBy: true,
      },
      {
        Header: 'Geo Scope',
        id: 'country',
        accessor: 'country',
        Cell: ({ row: { original } }) => (
          <LQMGeoIconCell
            country={original.country as AdministrativeAreaRequestCountryEnum}
            states={
              original.state_province as AdministrativeAreaRequestStateOrProvinceEnum[]
            }
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Actions',
        id: 'pair_id',
        accessor: 'pair_id',
        Cell: ({ row: { original, isSelected } }) => (
          <div
            className={classNames({
              'pointer-events-none opacity-30 cursor-not-allowed': isSelected,
            })}
          >
            <LQMActionCell questionDetails={original} refresh={refresh} />
          </div>
        ),
        disableSortBy: true,
      },
    ];
  }, [refresh]);

  const fetchQueue = useCallback(
    async (req: fetchQueueParams): Promise<PaginatedPairResponse> => {
      const { data } = await new LeoQuestionManagerApi(
        getLeoConfiguration(),
      ).getQueueLqmQapairQueueGet(
        req.isCompleted,
        req.isWorkQueue,
        req.pageNumber,
        req.pageSize,
        req.startDate,
        req.endDate,
        req.assigneeId,
      );

      const userIds = data.data.map((pair: PairBase) => pair.user_id);
      setCreatedByIds(userIds);

      return data;
    },
    [],
  );

  const searchQueue = useCallback(
    async (req: fetchQueueParams): Promise<PaginatedPairResponse> => {
      const { data } = await new LeoQuestionManagerApi(
        getLeoConfiguration(),
      ).searchLqmQapairSearchGet(
        req.isCompleted,
        req.isWorkQueue,
        req.searchString!,
        req.pageNumber,
        req.pageSize,
        req.assigneeId,
        req.startDate,
        req.endDate,
        req.country,
        req.states,
      );

      const userIds = data.data.map((pair: PairBase) => pair.user_id);
      setCreatedByIds(userIds);

      return data;
    },
    [],
  );

  const actionBarButtons: ActionBarButtonsProps<PairBase>[] = useMemo(() => {
    return [
      {
        icon: (
          <FontAwesomeIcon
            icon={faTrashCan}
            className='text-zen-danger'
            aria-label='delete-selected-questions'
          />
        ),
        hoverText: 'Delete Selected Questions',
        onClick: async (questions: PairBase[]) => {
          setValue(
            'selectedPairIds',
            questions.map((pair) => pair.pair_id! as string),
          );
          setShowBulkDeleteModal(true);
        },
      },
      {
        icon: <LQMBulkAssignment refresh={refresh} />,
        hoverText: 'Assign Selected Questions',
        onClick: (questions: PairBase[]) => {
          setValue(
            'selectedPairIds',
            questions.map((pair) => pair.pair_id! as string),
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Tabs = useMemo(() => {
    return (
      <ZenRouterTabs
        tabs={[
          {
            label: `Work Queue`,
            leftLabelIcon: (
              <FontAwesomeIcon
                icon={faBallotCheck}
                className='text-zen-dark-13 mr-2'
                aria-label='view'
              />
            ),
            path: '/leo-question-manager',
            exact: true,
            TabComponent: () => (
              <LQMWorkQueueTab
                key={key}
                control={control}
                columns={columns.filter(
                  (column) => column.accessor !== 'assignee_id',
                )}
                actionBarButtons={actionBarButtons}
                fetchData={fetchQueue}
                searchQueue={searchQueue}
              />
            ),
          },
          {
            label: `Assigned`,
            path: '/leo-question-manager/assigned',
            exact: true,
            TabComponent: () => (
              <LQMAssignedQueueTab
                key={key}
                control={control}
                columns={columns}
                actionBarButtons={actionBarButtons}
                fetchData={fetchQueue}
                assigneeId={assignedQueueAssigneeId?.value}
                searchQueue={searchQueue}
              />
            ),
          },
          {
            label: `Completed`,
            path: '/leo-question-manager/completed',
            exact: true,
            TabComponent: () => (
              <LQMCompletedQueueTab
                key={key}
                control={control}
                columns={columns}
                actionBarButtons={actionBarButtons}
                fetchData={fetchQueue}
                assigneeId={completedQueueAssigneeId?.value}
                searchQueue={searchQueue}
              />
            ),
          },
        ]}
      />
    );
  }, [
    key,
    control,
    columns,
    actionBarButtons,
    fetchQueue,
    searchQueue,
    assignedQueueAssigneeId?.value,
    completedQueueAssigneeId?.value,
  ]);

  useEffect(() => {
    dispatch(fetchLeoQuestionAssignees());
  }, [dispatch]);

  useAgentsInfo([...assigneeUserIds, userDetail?.id!, ...createdByIds]);

  useQueryParamFiltering({
    assignedQueueAssigneeId,
    completedQueueAssigneeId,
    workQueueDateFilterType: workQueue.dateFilterType,
    workQueueDateRange: workQueue.dateRange,
    assignedDateFilterType: assignedQueue.dateFilterType,
    assignedDateRange: assignedQueue.dateRange,
    completedDateFilterType: completedQueue.dateFilterType,
    completedDateRange: completedQueue.dateRange,
    workQueueSearch,
    assignedSearch,
    completedSearch,
    setValue,
    workQueueCountryFilter,
    workQueueStatesFilter,
    assignedQueueCountryFilter,
    assignedQueueStatesFilter,
    completedQueueCountryFilter,
    completedQueueStatesFilter,
  });

  return (
    <ZenRoute title='Leo Question Manager'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Leo Question Manager', url: '/leo-question-manager' },
        ]}
      >
        {!loading && (
          <FormProvider {...formControls}>
            <div className='px-4'>
              <div className='flex items-center justify-between px-2 md:px-4 py-4 space-y-2 md:space-y-0'>
                <div className='text-xl font-zen-body font-semibold'>
                  Leo Question Manager
                </div>
                <div className='flex items-center justify-between md:space-x-4'>
                  <ZenButton
                    variant='primary'
                    label='New Draft'
                    LeftIconComponent={
                      <FontAwesomeIcon icon={faPlus} className='text-sm' />
                    }
                    onClick={() => setIsCreateModalOpen(true)}
                  />
                </div>
              </div>
              {Tabs}
            </div>
          </FormProvider>
        )}
      </ZenPageLayout>
      {isCreateModalOpen && (
        <LQMSidebarModalForm
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          refresh={refresh}
        />
      )}
      {showBulkDeleteModal && (
        <LQMBulkDeleteModal
          isOpen={showBulkDeleteModal}
          onClose={() => setShowBulkDeleteModal(false)}
          onSubmit={handleSubmit}
          refresh={refresh}
        />
      )}
    </ZenRoute>
  );
};

export default LeoQuestionManagerRoute;
