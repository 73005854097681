import React from 'react';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MapImg from '../../assets/img/map.png';
import IconCircle from '../IconCircle';

interface TransactionAddressBlockProps {
  addressOneLine: string;
}

const TransactionAddressBlock: React.FC<TransactionAddressBlockProps> = ({
  addressOneLine,
}) => {
  return (
    <div
      className='relative bg-cover bg-center rounded overflow-hidden'
      style={{ backgroundImage: `url("${MapImg}")` }}
    >
      <div className='bg-black bg-opacity-60 flex items-center p-5'>
        <IconCircle
          icon={<FontAwesomeIcon icon={faLocationDot} className='text-dark' />}
          variant='default'
          size='xs'
        />
        <p className='text-white text-lg ml-2'>{addressOneLine}</p>
      </div>
    </div>
  );
};

export default TransactionAddressBlock;
