import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ReactComponent as RealTitleIcon } from '../../../assets/icons/real-title-icon.svg';
import SwitchToGeminiToggle from '../../Gemini/SwitchToGeminiToggle';
import ZenSideBarItem from '../../ZenSideBarItem';

interface ZenRealTitleOfficerMenuProps {
  isCollapsed: boolean;
}

const ZenRealTitleOfficerMenu: React.FC<ZenRealTitleOfficerMenuProps> = ({
  isCollapsed,
}) => {
  return (
    <div>
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={regular('clipboard-list')} />}
        label='My Transactions'
        linkTo='/transactions'
        collapsed={isCollapsed}
      />
      <ZenSideBarItem
        icon={<RealTitleIcon />}
        label='Real Title'
        linkTo='/real-title'
        collapsed={isCollapsed}
      />
      <SwitchToGeminiToggle collapsed={isCollapsed} />
    </div>
  );
};

export default ZenRealTitleOfficerMenu;
