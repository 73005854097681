import { SvgIcon } from '@material-ui/core';
import { ReactComponent as triggerIcon } from '../../../assets/icons/zen/autoToggle/trigger.svg';

const EmptyTriggerPlaceholder: React.FC = () => {
  return (
    <div className='py-10 flex flex-col items-center border border-zen-dark-4 rounded-xl'>
      <SvgIcon
        component={triggerIcon}
        viewBox='0 0 18 15'
        className='text-6xl'
        filter='invert(150%)'
      />
      <p className='mt-2 font-zen-body text-base text-zen-dark-6'>
        No triggers created yet
      </p>
    </div>
  );
};

export default EmptyTriggerPlaceholder;
