interface AnnouncementTitleCellProps {
  title: string;
  // mandatoryType: string;
}
const AnnouncementTitleCell: React.FC<AnnouncementTitleCellProps> = ({
  title,
}) => {
  // const getIcon = () => {
  //   switch (mandatoryType) {
  //     case 'video':
  //       return regular('film');
  //     case 'audio':
  //       return regular('microphone');
  //     case 'link':
  //       return regular('link');
  //     default:
  //       return regular('text-size');
  //   }
  // };

  return (
    <div className='flex flex-row items-start'>
      {/* <div className='mt-1 mr-3 h-[30px] min-w-[30px] flex items-center justify-center border border-zen-dark-5 rounded-[5px] text-zen-dark-6'>
        <FontAwesomeIcon icon={getIcon()} className='text-base' />
      </div> */}
      <p className='font-zen-body font-normal text-sm text-zen-dark-9'>
        {title}
      </p>
    </div>
  );
};

export default AnnouncementTitleCell;
