import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  faCheckCircle,
  faCircleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AgentOnboardingLayout from '../AgentOnboardingLayout';
import Button from '../Button';
import { AppDispatch, RootState } from '../../types';
import { checkTipaltiStatus } from '../../slices/AuthSlice';
import Alert from '../Alert';
import {
  getTipaltiErrorMessage,
  isRegisteredWithTipalti,
} from '../../utils/TipaltiHelper';
import ZenRoute from '../Zen/ZenRoute';

interface TipaltiRegisterProps {}

const TipaltiRegister: React.FC<TipaltiRegisterProps> = () => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const { tipaltiStatus, tipaltiLoading } = useSelector(
    (state: RootState) => state.auth,
  );

  const [showRegistrationError, setShowRegistrationError] = useState<boolean>(
    false,
  );

  const fetchTipaltiStatus = useCallback(async () => {
    setShowRegistrationError(false);
    await dispatch(checkTipaltiStatus());
    setShowRegistrationError(true);
  }, [dispatch]);

  if (tipaltiStatus && isRegisteredWithTipalti(tipaltiStatus)) {
    history.push('/');
  }

  const errorMessage = tipaltiStatus
    ? getTipaltiErrorMessage(tipaltiStatus)
    : '';

  return (
    <ZenRoute title='Tipalti Register'>
      <AgentOnboardingLayout title='Getting Paid' hideClose>
        <div className='w-full h-full flex items-center justify-center'>
          <div className='max-w-lg'>
            <p className='text-2xl font-primary-medium my-2 text-trueGray-700'>
              How You Get Paid
            </p>
            {showRegistrationError && (
              <div className='my-2'>
                <Alert
                  text={errorMessage || ''}
                  variant='error'
                  icon={
                    <FontAwesomeIcon icon={faCircleExclamation} size='lg' />
                  }
                />
              </div>
            )}
            <p>
              We want to pay you as fast as possible, so make sure you&apos;re
              registered with Tipalti, our secure payment processing system.
            </p>
            <div className='mt-2'>
              <p className='mb-2'>
                Once setup you will get automatic payments for your:
              </p>
              <div className='flex space-x-2 mb-1'>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size='lg'
                  className='text-green-500'
                />
                <p>Commission Payments</p>
              </div>
              <div className='flex space-x-2 mb-1'>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size='lg'
                  className='text-green-500'
                />
                <p>Revenue Share</p>
              </div>
              <div className='flex space-x-2'>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size='lg'
                  className='text-green-500'
                />
                <p>Referral Payments</p>
              </div>
            </div>
            <div className='max-w-lg flex flex-col items-end mt-2'>
              <a
                href={tipaltiStatus?.signUpLink}
                target='_blank'
                rel='noopener noreferrer'
                className='w-full mt-2'
              >
                <Button
                  fullWidth
                  label='Register to Get Paid'
                  className='!py-2'
                />
              </a>

              <Button
                label="I've already registered"
                fullWidth
                className='!mt-2 !py-2'
                onClick={fetchTipaltiStatus}
                type='outline'
                isSubmitting={tipaltiLoading}
              />
            </div>
          </div>
        </div>
      </AgentOnboardingLayout>
    </ZenRoute>
  );
};

export default TipaltiRegister;
