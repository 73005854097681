import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { useParams } from 'react-router-dom';
import ZenEditAboutContentSidebarModalForm from '../components/Agent/website/zen/sidebars/ZenEditAboutContentSidebarModalForm';
import ZenEditAgentWebsitePropertyFilterForm from '../components/Agent/website/zen/sidebars/ZenEditAgentWebsitePropertyFilterForm';
import ZenEditContactInfoSidebarModalForm from '../components/Agent/website/zen/sidebars/ZenEditContactInfoSidebarModalForm';
import ZenEditFeaturedContentSidebarModalForm from '../components/Agent/website/zen/sidebars/ZenEditFeaturedContentSidebarModalForm';
import ZenEditMainContentSidebarModalForm from '../components/Agent/website/zen/sidebars/ZenEditMainContentSidebarModalForm';
import ZenEditTestimonialSidebarModalForm from '../components/Agent/website/zen/sidebars/ZenEditTestimonialSidebarModalForm';
import ZenEditThemeSidebarModalForm from '../components/Agent/website/zen/sidebars/ZenEditThemeSidebarModalForm';
import ZenAgentWebsiteAboutContent from '../components/Agent/website/zen/ZenAgentWebsiteAboutContent';
import ZenAgentWebsiteContactInfo from '../components/Agent/website/zen/ZenAgentWebsiteContactInfo';
import ZenAgentWebsiteMainContent from '../components/Agent/website/zen/ZenAgentWebsiteMainContent';
import ZenAgentWebsiteThemeContent from '../components/Agent/website/zen/ZenAgentWebsiteThemeContent';
import ZenFeaturedContent from '../components/Agent/website/zen/ZenFeaturedContent';
import ZenListingInfo from '../components/Agent/website/zen/ZenListingInfo';
import ZenTestimonialInfo from '../components/Agent/website/zen/ZenTestimonialInfo';
import DefaultLoader from '../components/DefaultLoader';
import ZenConfirmationModal from '../components/Zen/Modal/ZenConfirmationModal';
import ZenAction from '../components/Zen/ZenAction';
import ZenButton from '../components/Zen/ZenButton';
import ZenCard from '../components/Zen/ZenCard';
import ZenRoute from '../components/Zen/ZenRoute';
import {
  CreateOrUpdateAgentWebsiteRequest,
  CreateOrUpdateAgentWebsiteRequestCompetenciesEnum,
} from '../openapi/yenta';
import AmplitudeService, { AmplitudeEvent } from '../services/AmplitudeService';
import {
  fetchAgentWebsiteDetails,
  updateAgentWebsiteDetails,
} from '../slices/AgentSlice';
import { RootState } from '../types';
import {
  FACEBOOK_URL,
  formUrlFromPath,
  INSTAGRAM_URL,
  LINKEDIN_URL,
} from '../utils/AgentWebsiteUtils';

interface Match {
  id: string;
}

export interface AgentWebsiteFormData
  extends CreateOrUpdateAgentWebsiteRequest {}

const ZenAgentDetailWebsiteRoute: React.FC = () => {
  const dispatch = useDispatch();
  const {
    agentDetail: { agentWebsite },
  } = useSelector((state: RootState) => state);
  const { id } = useParams() as Match;
  const [isThemeEditOpen, setIsThemeEditOpen] = useState<boolean>(false);
  const [isContactInfoEditOpen, setIsContactInfoEditOpen] = useState<boolean>(
    false,
  );
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [isAboutContentEditOpen, setIsAboutContentEditOpen] = useState<boolean>(
    false,
  );
  const [isMainContentEditOpen, setIsMainContentEditOpen] = useState<boolean>(
    false,
  );
  const [
    isFeaturedContentEditOpen,
    setIsFeaturedContentEditOpen,
  ] = useState<boolean>(false);
  const [
    isTestimonialContentEditOpen,
    setIsTestimonialContentEditOpen,
  ] = useState<boolean>(false);
  const [
    isPropertyFilterEditOpen,
    setIsPropertyFilterEditOpen,
  ] = useState<boolean>(false);
  const [updateAgentWebsite, setUpdateAgentWebsite] = useState<boolean>(false);
  const [
    resetAgentWebsiteDetails,
    setResetAgentWebsiteDetails,
  ] = useState<boolean>(false);

  const defaultValues = {
    clubhouseURL: agentWebsite?.agentResponse?.clubhouseURL,
    facebookURL: formUrlFromPath(
      FACEBOOK_URL,
      agentWebsite?.agentResponse?.facebookURL,
    ),
    instagramURL: formUrlFromPath(
      INSTAGRAM_URL,
      agentWebsite?.agentResponse?.instagramURL,
    ),
    linkedInURL: formUrlFromPath(
      LINKEDIN_URL,
      agentWebsite?.agentResponse?.linkedInURL,
    ),
    twitterURL: agentWebsite?.agentResponse?.twitterURL,
    yelpURL: agentWebsite?.agentResponse?.yelpURL,
    youtubeURL: agentWebsite?.agentResponse?.youtubeURL,
    zillowURL: agentWebsite?.agentResponse?.zillowURL,
    googleBusinessProfileURL:
      agentWebsite?.agentResponse?.googleBusinessProfileURL,
    serviceAreaZipcodes: agentWebsite?.agentResponse?.serviceAreaZipcodes,
    slug: agentWebsite?.agentResponse?.slug,
    competencies: ((agentWebsite?.agentResponse?.competencies ||
      []) as unknown) as CreateOrUpdateAgentWebsiteRequestCompetenciesEnum[],
    websiteTitle: agentWebsite?.websiteTitle,
    themeColor: agentWebsite?.themeColor,
    testimonials: agentWebsite?.testimonials,
    showReferralLink: agentWebsite?.showReferralLink,
    photoSet: agentWebsite?.photoSet,
    propertyFilter: agentWebsite?.propertyFilter,
    bottomTitle: agentWebsite?.bottomTitle,
    bottomSubtitle: agentWebsite?.bottomSubtitle,
    mainTitle: agentWebsite?.mainTitle,
    mainSubtitle: agentWebsite?.mainSubtitle,
    feature1Content: agentWebsite?.feature1Content,
    feature1Title: agentWebsite?.feature1Title,
    feature2Content: agentWebsite?.feature2Content,
    feature2Title: agentWebsite?.feature2Title,
    feature3Content: agentWebsite?.feature3Content,
    feature3Title: agentWebsite?.feature3Title,
  } as AgentWebsiteFormData;

  useEffect(() => {
    dispatch(fetchAgentWebsiteDetails(id));
  }, [id, dispatch]);

  const handleUpdateAgentWebsite = async () => {
    setIsPublishing(true);
    await dispatch(updateAgentWebsiteDetails(defaultValues, agentWebsite?.id!));
    setUpdateAgentWebsite(false);
    setIsPublishing(false);

    AmplitudeService.logEvent(AmplitudeEvent.AGENT_WEBSITE_UPDATE);
  };

  if (!agentWebsite) {
    return <DefaultLoader />;
  }

  return (
    <ZenRoute title='Agent Website'>
      <div className='w-full'>
        <div className='flex flex-col md:flex-row justify-between items-start md:items-center px-4 py-6 space-y-4 md:space-x-10 space-x-0 md:space-y-0'>
          <div className='flex flex-col items-start justify-center flex-shrink-0'>
            <h2 className='font-zen-title text-xl md:text-3xl text-zen-dark-9'>
              Agent Real Website (Update)
            </h2>
            <p className='font-zen-body text-lg md:text-xl text-zen-dark-9'>
              Make changes to any of the sections, save it and come back to
              Publish everything at once.
            </p>
          </div>
          <div className='flex flex-row items-center justify-end space-x-4 flex-grow'>
            <ZenButton
              label='Cancel'
              variant='secondary-outline'
              onClick={() => setResetAgentWebsiteDetails(true)}
              isFullWidth
            />
            <ZenButton
              label='Publish'
              type='submit'
              onClick={() => setUpdateAgentWebsite(true)}
              isFullWidth
            />
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-3 grid-rows-1 gap-4 p-4'>
          <div className='col-span-2'>
            <ZenCard
              title='About Content'
              RightComponent={
                <ZenAction
                  text='Edit'
                  onClick={() => setIsAboutContentEditOpen(true)}
                  icon={
                    <FontAwesomeIcon
                      className='text-primary-blue'
                      icon={faPen}
                      size='sm'
                    />
                  }
                />
              }
              testId='about-content'
              resize
            >
              <ZenAgentWebsiteAboutContent agentWebsite={agentWebsite} />
            </ZenCard>
            <div className='mt-5'>
              <ZenCard
                title='Main Content'
                RightComponent={
                  <ZenAction
                    text='Edit'
                    onClick={() => setIsMainContentEditOpen(true)}
                    icon={
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={faPen}
                        size='sm'
                      />
                    }
                  />
                }
                resize
                testId='main-content'
              >
                <ZenAgentWebsiteMainContent agentWebsite={agentWebsite} />
              </ZenCard>
            </div>
            <div className='my-5'>
              <ZenCard
                title='Featured Content'
                RightComponent={
                  <ZenAction
                    text='Edit'
                    onClick={() => setIsFeaturedContentEditOpen(true)}
                    icon={
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={faPen}
                        size='sm'
                      />
                    }
                  />
                }
                testId='featured-content'
              >
                <ZenFeaturedContent agentWebsite={agentWebsite} />
              </ZenCard>
            </div>
            <div className='my-5'>
              <ZenCard
                title='Testimonials'
                RightComponent={
                  <ZenAction
                    text='Edit'
                    onClick={() => setIsTestimonialContentEditOpen(true)}
                    icon={
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={faPen}
                        size='sm'
                      />
                    }
                  />
                }
                testId='testimonials'
              >
                <ZenTestimonialInfo agentWebsite={agentWebsite} />
              </ZenCard>
            </div>
          </div>
          <div className='col-span-1'>
            <ZenCard
              title='Theme Image & Color'
              RightComponent={
                <ZenAction
                  text='Edit'
                  onClick={() => setIsThemeEditOpen(true)}
                  icon={
                    <FontAwesomeIcon
                      className='text-primary-blue'
                      icon={faPen}
                      size='sm'
                    />
                  }
                />
              }
              testId='image-and-color'
              resize
            >
              <ZenAgentWebsiteThemeContent agentWebsite={agentWebsite} />
            </ZenCard>
            <div className='mt-5'>
              <ZenCard
                title='Contact Info'
                RightComponent={
                  <ZenAction
                    text='Edit'
                    onClick={() => setIsContactInfoEditOpen(true)}
                    icon={
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={faPen}
                        size='sm'
                      />
                    }
                  />
                }
                testId='contact-info'
              >
                <ZenAgentWebsiteContactInfo agentWebsite={agentWebsite} />
              </ZenCard>
            </div>
            <div className='mt-5'>
              <ZenCard
                title='Listings (search criteria)'
                RightComponent={
                  <ZenAction
                    text='Edit'
                    onClick={() => setIsPropertyFilterEditOpen(true)}
                    icon={
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={faPen}
                        size='sm'
                      />
                    }
                  />
                }
                testId='listings'
              >
                <ZenListingInfo agentWebsite={agentWebsite} />
              </ZenCard>
            </div>
          </div>
        </div>
        {isAboutContentEditOpen && (
          <ZenEditAboutContentSidebarModalForm
            isOpen
            onClose={() => setIsAboutContentEditOpen(false)}
            agentWebsite={agentWebsite}
          />
        )}
        {isMainContentEditOpen && (
          <ZenEditMainContentSidebarModalForm
            isOpen
            onClose={() => setIsMainContentEditOpen(false)}
            agentWebsite={agentWebsite}
          />
        )}
        {isFeaturedContentEditOpen && (
          <ZenEditFeaturedContentSidebarModalForm
            isOpen
            onClose={() => setIsFeaturedContentEditOpen(false)}
            agentWebsite={agentWebsite}
          />
        )}
        {isTestimonialContentEditOpen && (
          <ZenEditTestimonialSidebarModalForm
            isOpen
            onClose={() => setIsTestimonialContentEditOpen(false)}
            agentWebsite={agentWebsite}
          />
        )}
        {isThemeEditOpen && (
          <ZenEditThemeSidebarModalForm
            isOpen
            onClose={() => setIsThemeEditOpen(false)}
            agentWebsite={agentWebsite}
          />
        )}
        {isContactInfoEditOpen && (
          <ZenEditContactInfoSidebarModalForm
            isOpen
            onClose={() => setIsContactInfoEditOpen(false)}
            agentWebsite={agentWebsite}
          />
        )}
        {isPropertyFilterEditOpen && (
          <ZenEditAgentWebsitePropertyFilterForm
            isOpen
            onClose={() => setIsPropertyFilterEditOpen(false)}
            agentWebsite={agentWebsite}
          />
        )}
        <ZenConfirmationModal
          variant='primary'
          title='Publish Agent Real Website?'
          subtitle='Agent website will be live in a few minutes.'
          isOpen={updateAgentWebsite}
          onClose={() => setUpdateAgentWebsite(false)}
          isSubmitting={isPublishing}
          isDisabled={isPublishing}
          confirmButtonText='Publish'
          onConfirm={handleUpdateAgentWebsite}
        />
        <ZenConfirmationModal
          variant='danger'
          title='Cancel and discard all changes?'
          subtitle='You made some edits. Are you sure you want to discard all these changes?'
          isOpen={resetAgentWebsiteDetails}
          onClose={() => setResetAgentWebsiteDetails(false)}
          cancelButtonText='No'
          confirmButtonText='Yes'
          confirmButtonType='submit'
          onConfirm={() => {
            dispatch(fetchAgentWebsiteDetails(id));
            setResetAgentWebsiteDetails(false);
          }}
        />
      </div>
    </ZenRoute>
  );
};

export default ZenAgentDetailWebsiteRoute;
