import { Link } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form-v7';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { InvoiceResponse } from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenSidebarModal from '../ZenSidebarModal';
import ZenControlledTextInput from '../../../components/Zen/Input/ZenControlledTextInput';

interface ZenInvoiceFormSidebarModalProps {
  isOpen: boolean;
  onClose(reload?: boolean): void;
  invoice: InvoiceResponse;
}

const ZenInvoiceFormSidebarModal: React.FC<ZenInvoiceFormSidebarModalProps> = ({
  isOpen,
  onClose,
  invoice,
}) => {
  const { control } = useForm();

  return (
    <ZenSidebarModal title='Invoice Details' isOpen={isOpen} onClose={onClose}>
      <form className='flex flex-col justify-between min-h-full'>
        <div className='p-4'>
          <ZenControlledTextInput
            control={control}
            label='Status'
            name='status'
            defaultValue={invoice?.status && capitalizeEnum(invoice?.status)}
            readOnly
          />
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Transaction Code'
              name='transactionId'
              defaultValue={invoice?.transactionCode}
              readOnly
            />
          </div>
          {invoice?.transactionCode && (
            <Link to={`/transactions/code/${invoice?.transactionCode}`}>
              <span className='text-primary-blue font-zen-body flex items-center mt-1'>
                View Transaction <FiExternalLink className='ml-2' />
              </span>
            </Link>
          )}
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Invoice Number'
              name='invoiceNumber'
              defaultValue={invoice?.invoiceNumber}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Amount'
              name='invoicedAmount'
              defaultValue={displayAmount(invoice?.invoicedAmount)}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='First Name'
              name='firstName'
              defaultValue={invoice?.firstName}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Last Name'
              name='lastName'
              defaultValue={invoice?.lastName}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Company'
              name='company'
              defaultValue={invoice?.company}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Paid At'
              name='paidAt'
              defaultValue={
                invoice?.paidAt &&
                DateTime.fromMillis(invoice?.paidAt).toFormat('LL/dd/yy')
              }
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Payment System'
              name='paymentSystem'
              defaultValue={
                invoice?.paymentSystem && capitalizeEnum(invoice?.paymentSystem)
              }
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Payment System ID'
              name='paymentSystemId'
              defaultValue={invoice?.paymentSystemId}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Error'
              name='error'
              defaultValue={invoice?.error}
              readOnly
            />
          </div>
          <div className='mt-5 pb-20'>
            <ZenControlledTextInput
              control={control}
              label='Payer Note'
              name='payerNote'
              defaultValue={invoice?.payerNote}
              readOnly
            />
          </div>
        </div>
      </form>
    </ZenSidebarModal>
  );
};

export default ZenInvoiceFormSidebarModal;
