import React from 'react';
import Thumbnail from '../../../../assets/img/wealthManagement/RealTechnologyThumbnail.svg';
import { REAL_TECHNOLOGY_VIDEO_URL } from '../../EarningLandingPages/LandingPageVideoConstants';

export interface WealthPlanReferralVideoCardProps {}

const WealthPlanReferralVideoCard: React.FC<WealthPlanReferralVideoCardProps> = () => {
  return (
    <div className='font-zen-body-2 rounded-xl bg-seaglass pt-4 shadow-zen-card'>
      <div className='w-full flex items-center justify-center lg:h-[240px] h-[160px] px-4 mb-4'>
        <video
          src={REAL_TECHNOLOGY_VIDEO_URL}
          poster={Thumbnail}
          controls
          className='w-full h-full bg-black rounded-md'
        />
      </div>
      <div className='bg-regent-100 px-4 py-6 rounded-b-xl'>
        <p className='text-base text-primary-navy font-medium'>
          Sharpen Your Competitive Edge with Real&apos;s Technology
        </p>
        <p className='text-sm font-normal text-grey-600 mt-2'>
          We built our proprietary, industry-leading, AI-powered technology
          specifically for you, now and in the future.
        </p>
      </div>
    </div>
  );
};

export default WealthPlanReferralVideoCard;
