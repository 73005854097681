import { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { MoneyValue, MoneyValueCurrencyEnum } from '../../../openapi/yenta';
import { FormFieldTooltipIndexProps } from '../../../types';
import { getSelectOptionsForEnums } from '../../../utils/TableUtils';
import { ControlledTextInputModeProps } from '../../ControlledTextInput';
import ZenFormErrorMessage from '../../Zen/Input/ZenFormErrorMessage';
import { cn } from '../../../utils/classUtils';

type IMoneyValue = Record<string, MoneyValue>;

interface ZenControlledFormattedMoneyInputProps<
  TFieldValues extends FieldValues | IMoneyValue = FieldValues | IMoneyValue,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  inputMode?: ControlledTextInputModeProps;
  label?: string;
  subLabel?: string;
  readOnly?: boolean;
  currencyReadOnly?: boolean;
  noBorder?: boolean;
  hideErrorMessage?: boolean;
  placeholder?: string;
  isRequired?: boolean;
}

const ZenControlledFormattedMoneyInput = <
  TFieldValues extends FieldValues | IMoneyValue = FieldValues | IMoneyValue,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  readOnly,
  currencyReadOnly,
  placeholder,
  noBorder,
  hideErrorMessage,
  shouldUnregister = true,
  inputMode = 'text',
  isRequired = false,
  ...rest
}: ZenControlledFormattedMoneyInputProps<TFieldValues, TName>) => {
  const options = getSelectOptionsForEnums(MoneyValueCurrencyEnum);
  const [focus, setFocus] = useState<boolean>(false);
  const isCurrencyReadOnly = readOnly || currencyReadOnly;

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error, invalid },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label className='inline-block' htmlFor={`${name}.amount`}>
              <span
                className={cn(
                  'font-zen-body font-semibold',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                )}
              >
                {label}
              </span>
              {!!subLabel && (
                <span className='font-zen-body text-sm text-zen-dark-12 ml-1'>
                  {subLabel}
                </span>
              )}
              {isRequired && <span className='text-zen-danger'>*</span>}
            </label>
          )}
          <div
            className={cn(
              'flex items-stretch border rounded-lg overflow-hidden',
              value && 'text-zen-dark-9',
              invalid && '!border-zen-danger',
              focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
              {
                'border-none': noBorder,
              },
            )}
          >
            <CurrencyInput
              id={`${name}.amount`}
              name={`${name}.amount`}
              value={value?.amount}
              onValueChange={(v) => {
                onChange({
                  currency: value.currency,
                  amount: v,
                });
              }}
              inputMode={inputMode}
              onFocus={() => setFocus(true)}
              onBlur={() => {
                setFocus(false);
                onBlur();
              }}
              readOnly={readOnly}
              placeholder={placeholder}
              className={cn(
                'appearance-none p-2 border-0 bg-white focus:outline-none focus:ring-0 flex-grow w-full font-zen-body font-normal',
                {
                  'bg-gray-50': readOnly,
                },
              )}
              allowDecimals
            />
            <div>
              <select
                id={`${name}.currency`}
                name={`${name}.currency`}
                value={value?.currency}
                onChange={(e) => {
                  onChange({ ...(value || {}), currency: e.target.value });
                }}
                className={cn(
                  'appearance-none p-2 bg-white focus:outline-none border-0 focus:ring-0 w-18 font-zen-body font-normal',
                  {
                    'bg-gray-50': isCurrencyReadOnly,
                  },
                )}
                disabled={isCurrencyReadOnly}
                data-testid='currency-select'
              >
                {options.map((currency) => (
                  <option key={currency.value} value={currency.value}>
                    {currency.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {!!error && !hideErrorMessage && (
            <ZenFormErrorMessage message={error.message} />
          )}
        </div>
      )}
    />
  );
};

export default ZenControlledFormattedMoneyInput;
