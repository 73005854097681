import RealLogoImg from '../assets/img/RealLogoBlack.png';

export interface SlimDocumentMastHeadProps {
  companyName: string;
  companyAddress: string;
}

const SlimDocumentMastHead: React.FC<SlimDocumentMastHeadProps> = ({
  companyName,
  companyAddress,
}) => {
  return (
    <div className='flex flex-col px-4 pb-3 pt-8 print:pt-4'>
      <div className='flex items-start'>
        <img src={RealLogoImg} className='h-7 print:h-5' alt='Real Logo' />
      </div>
      <p className='text-xl print:text-lg font-primary-medium text-left mt-3'>
        {companyName}
      </p>
      <p className='font-primary-medium text-left print:text-xs'>
        {companyAddress}
      </p>
    </div>
  );
};

export default SlimDocumentMastHead;
