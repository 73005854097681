import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faTableColumns } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGemini } from '../../hooks/useGemini';
import { RootState } from '../../types';
import { cn } from '../../utils/classUtils';
import ResourceContainer from '../ResourceContainer';
import ZenAction from '../Zen/ZenAction';
import ZenCard from '../Zen/ZenCard';
import OneRealImpactSidebarModal from './OneRealImpactSidebarModal';

interface OneRealImpactCardProps {}

const OneRealImpactCard: React.FC<OneRealImpactCardProps> = () => {
  const isGeminiEnabled = useGemini();
  const {
    detailResponse: { data: agentResponse },
  } = useSelector((state: RootState) => state.agentDetail);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const oneRealImpactFund = agentResponse?.oneRealImpactFundConfig;

  const { amount, percent, percentEnabled } = oneRealImpactFund || {};

  return (
    <>
      <ZenCard
        title='One Real Impact'
        RightComponent={
          <ZenAction
            icon={
              <FontAwesomeIcon
                icon={faPlus}
                className={cn(
                  isGeminiEnabled
                    ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                    : 'text-primary-blue',
                )}
              />
            }
            text='Donate'
            onClick={() => setOpenModal(true)}
          />
        }
      >
        <ResourceContainer
          isEmpty={!oneRealImpactFund}
          loading={false}
          resourceName='One Real Impact'
          emptyMessage='You have not donated to One Real Impact'
          emptyIcon={
            <FontAwesomeIcon
              icon={faTableColumns}
              className='text-zen-dark-7 text-[32px] font-[900]'
            />
          }
        >
          <div className='flex flex-row items-center justify-between w-full px-4 py-2 text-zen-dark-9 border-b border-zen-light-gray-2'>
            <p className='font-zen-body text-base font-semibold'>
              Donation per Transaction
            </p>
            <p className='font-zen-title text-xl font-medium'>
              {percentEnabled ? `${percent}%` : `$${amount}`}
            </p>
          </div>
        </ResourceContainer>
      </ZenCard>
      {openModal && (
        <OneRealImpactSidebarModal
          agentId={agentResponse?.id!}
          oneRealImpactFund={oneRealImpactFund}
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default OneRealImpactCard;
