import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PersonalDealInfoRequest } from '../../../../openapi/arrakis';
import { savePersonalDealInfos } from '../../../../slices/TransactionBuilderSlice';
import { AppDispatch, RootState, YesNoType } from '../../../../types';
import { displayAmount } from '../../../../utils/CurrencyUtils';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledRadioInput from '../../Input/ZenControlledRadioInput';
import ZenButton from '../../ZenButton';
import { getFullName } from '../../../../utils/AgentHelper';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
  Match,
} from './ZenCreateTransactionSteps';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';

const ZenTransactionPersonalDealStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({ form: { control, watch, trigger }, onPrevious, onNext }) => {
  const dispatch: AppDispatch = useDispatch();
  const { transactionBuilderId } = useParams<Match>();
  const {
    agentDetail: { agentLiteById },
    transactionBuilder: { transactionBuilder },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const transactionOwnerId =
    transactionBuilder?.agentsInfo?.ownerAgent[0].agentId;
  const personalDealInfo = agentLiteById[transactionOwnerId!];
  const personalDealRemaining =
    personalDealInfo?.totalNumberOfPersonalDealsLeft || 0;
  const isPersonalDealLimitExceed = personalDealRemaining <= 0;

  const [personalDeal, representedByRealEstateAgent] = watch([
    'personalDeal',
    'representedByRealEstateAgent',
  ]);

  const handlePersonalDealStep = async () => {
    const isValid = await trigger();
    if (isValid) {
      const personalDealInfoRequest: PersonalDealInfoRequest = {
        personalDeal: personalDeal === YesNoType.YES,
        representedByAgent: representedByRealEstateAgent === YesNoType.YES,
      };
      setLoading(true);
      const res = await dispatch(
        savePersonalDealInfos(transactionBuilderId!, personalDealInfoRequest),
      );
      setLoading(false);
      if (res) {
        onNext();
      }
    }
  };

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div>
          <p className='text-xl font-zen-title font-medium text-zen-dark-9 mb-4'>
            Is this a personal deal for{' '}
            {getFullName(
              agentById[
                transactionBuilder?.agentsInfo?.ownerAgent[0]?.agentId!
              ],
            )}
            ?
          </p>
          <p className='text-base font-zen-body font-normal text-zen-dark-6 mb-4'>
            {`Owner agent, spouse or company, must be on the contract or deed to be
          considered personal deal. There’s a ${displayAmount(
            personalDealInfo?.commissionPlan!.personalDealFee,
          )} fee for personal
          deals.`}
          </p>
          <p className='text-base font-zen-body font-normal text-zen-dark-9 mb-4'>
            {`You have ${personalDealRemaining} personal deals left this anniversary year.`}
          </p>
          <ZenControlledRadioInput<CreateTransactionFormState, 'personalDeal'>
            name='personalDeal'
            control={control}
            inlineOptions
            defaultValue={
              isPersonalDealLimitExceed ? YesNoType.NO : YesNoType.YES
            }
            shouldUnregister={false}
            disabled={isPersonalDealLimitExceed}
            options={Object.values(YesNoType).map((value) => {
              return { label: capitalizeEnum(value), value };
            })}
          />
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              isDisabled={loading}
              label='Next'
              onClick={handlePersonalDealStep}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withCreateTransactionProgress(ZenTransactionPersonalDealStep);
