import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ParticipantValue,
  PaymentParticipantValue,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { removeTransactionParticipant } from '../../../slices/TransactionSlice';
import { AppDispatch, RootState } from '../../../types';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import { isTransactionReadOnly } from '../../../utils/TransactionHelper';
import ZenEditTransactionParticipantFormSidebarModal from '../forms/ZenEditTransactionParticipantFormSidebarModal';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import ZenListingAllParticipantsTable from './ZenListingAllParticipantsTable';

interface ZenListingAllParticipantsProps {
  transaction: TransactionResponse;
}

const ZenListingAllParticipants: React.FC<ZenListingAllParticipantsProps> = ({
  transaction,
}) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const dispatch: AppDispatch = useDispatch();
  const [editingParticipant, setEditingParticipant] = useState<
    ParticipantValue | PaymentParticipantValue | null
  >(null);
  const [deletingParticipant, setDeletingParticipant] = useState<
    ParticipantValue | PaymentParticipantValue | null
  >(null);
  const [isDeleting, setDeleting] = useState<boolean>(false);

  const deleteParticipant = async () => {
    setDeleting(true);
    const success = await dispatch(
      removeTransactionParticipant(transaction.id!, deletingParticipant?.id!),
    );
    if (success) {
      setDeletingParticipant(null);
    }
    setDeleting(false);
  };

  return (
    <div className='col-span-12 p-4'>
      <ZenListingAllParticipantsTable
        transaction={transaction!}
        onEdit={(participant) => setEditingParticipant(participant)}
        onDelete={(participant) => setDeletingParticipant(participant)}
        isReadonly={isTransactionReadOnly(transaction, userDetail!)}
      />

      {!!editingParticipant && (
        <ZenEditTransactionParticipantFormSidebarModal
          transaction={transaction}
          participant={editingParticipant}
          isOpen={!!editingParticipant}
          onClose={() => setEditingParticipant(null)}
          readOnly={isTransactionReadOnly(transaction, userDetail!)}
        />
      )}

      <ZenConfirmationModal
        isOpen={!!deletingParticipant}
        onClose={() => setDeletingParticipant(null)}
        variant='danger'
        isSubmitting={isDeleting}
        isDisabled={isDeleting}
        onConfirm={deleteParticipant}
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        title={`Remove ${getParticipantName(deletingParticipant!)}?`}
        subtitle='You can always add them back in the future.'
        hideIcon
      />
    </div>
  );
};

export default ZenListingAllParticipants;
