import {
  faArrowUpRight,
  faCirclePlay,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import PhoneImage from '../../../assets/img/zen/real-mortgage/V2/phone-1.png';
import { VIDEO_URL } from '../../../constants/RealMortgageConstants';
import ZenButton from '../../Zen/ZenButton';
import IFrameVideoplayer from '../../IFrameVideoplayer';

interface OneRealMortgageLandingSectionProps {
  onGetClientPreApproved?: () => void;
}

const OneRealMortgageLandingSection: React.FC<OneRealMortgageLandingSectionProps> = ({
  onGetClientPreApproved,
}) => {
  const [play, setPlay] = useState<boolean>(false);

  return (
    <>
      <IFrameVideoplayer play={play} setPlay={setPlay} videoUrl={VIDEO_URL} />
      <div className='flex flex-col-reverse sm:flex-row gap-10 bg-primary-navy'>
        <div className='w-full sm:w-1/2 sm:max-w-[600px] pr-6 sm:pt-12'>
          <div className='relative'>
            <div className='border-[3px] border-l-0 border-zen-danger w-full max-w-[540px] h-[320px] md:h-[380px] relative top-[3px]' />
            <div className='absolute sm:-left-8 lg:-left-11 top-12 z-20 w-full max-w-[540px] flex justify-center sm:justify-end'>
              <div className='relative'>
                <img
                  src={PhoneImage}
                  alt='phone'
                  className='w-[271px] lg:w-[340px] mx-auto'
                />
                <div className='absolute bottom-6 w-full flex flex-col items-center'>
                  <p className='font-semibold mb-3'>ONEREALMORTGAGE.COM</p>
                  <button
                    className='flex items-center gap-2 bg-gray-900 w-fit py-2 px-6 rounded-md cursor-pointer'
                    onClick={() => setPlay(true)}
                  >
                    <p className='text-white'>See Video</p>
                    <FontAwesomeIcon
                      icon={faCirclePlay}
                      className='text-white'
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className='border-r-[3px] border-r-zen-danger border-t-[3px] border-t-primary-navy w-1/2 max-w-[540px] h-36 relative z-10' />
          </div>
        </div>
        <div className='w-full sm:w-1/2 sm:pr-12 flex flex-col justify-center gap-5 p-5 pb-0 sm:p-0'>
          <h1 className='text-white text-[40px] leading-[48px] font-poppins font-semibold'>
            <span className='text-aqua'>Your trusted</span>{' '}
            <span>partner for all your client&apos;s</span>{' '}
            <span className='text-aqua'>mortgage</span>{' '}
            <span>lending needs.</span>
          </h1>
          <p className='text-white font-light'>
            Our cutting-edge technology makes it easy for your clients to get
            pre-approved for a mortgage. Our innovative programs and agent
            revenue opportunities support your business and help you win more
            deals.
          </p>
          <div className='mt-3'>
            <ZenButton
              label='Get Your Client Pre-Approved'
              RightIconComponent={<FontAwesomeIcon icon={faArrowUpRight} />}
              onClick={onGetClientPreApproved}
              buttonProps={{
                style: {
                  display: 'flex',
                  gap: 6,
                  fontFamily: 'Inter',
                  lineHeight: '20px',
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OneRealMortgageLandingSection;
