import { ISelectOption } from '../../types';

export const getRealCapOptionsForTeam = (capsAllowed?: number[]) => {
  const realCapOptions = [
    {
      value: '4000',
      label: '4K',
      disabled: !capsAllowed?.includes(4000),
    },
    {
      value: '6000',
      label: '6K',
      disabled: !capsAllowed?.includes(6000),
    },
    {
      value: '12000',
      label: '12K',
      disabled: !capsAllowed?.includes(12000),
    },
  ];

  return realCapOptions;
};

export const getDefaultTeamCapForMemberInvite = (options: ISelectOption[]) => {
  const firstAvailableCap = options?.find((option) => !option?.disabled);

  return firstAvailableCap?.value || '';
};
