import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { last } from 'lodash';
import ApplicationStepCompleteMessage from '../../ApplicationStepCompleteMessage';
import ApplicationLayout from '../../ApplicationLayout';
import ApplicationVerticalStepProgress from '../ApplicationVerticalStepProgress';
import { RootState } from '../../../types';
import { getSupportPhoneNumberForCountry } from '../../../constants/SupportConstants';
import { ApplicationResponseStatusEnum } from '../../../openapi/yenta';

const ApplicationSubmittedSuccess: React.FC = () => {
  const history = useHistory();
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const supportPhoneNumber = getSupportPhoneNumberForCountry(
    userDetail?.accountCountry,
  );

  const isApplicationApproved =
    last(userDetail?.applications || [])?.status ===
    ApplicationResponseStatusEnum.Approved;

  useEffect(() => {
    if (isApplicationApproved) {
      history.push('/');
    }
  }, [isApplicationApproved, history]);

  return (
    <ApplicationLayout>
      <div className='w-full mx-auto px-4 my-10 lg:max-w-6xl gap-10 grid grid-cols-1 lg:grid-cols-5'>
        <div className='lg:col-span-3'>
          <ApplicationStepCompleteMessage
            icon={<FontAwesomeIcon icon={faCheck} size='2xl' />}
            header='We have received your request. We’re confirming that your license and board sponsorship transfer has been completed, and we will activate your account shortly.'
            subtitle={`For any assistance, please reach out to our customer support team or call us at ${supportPhoneNumber}.`}
            onNextStepClicked={() => history.push('/')}
          />
        </div>
        <div className='lg:col-span-2'>
          <ApplicationVerticalStepProgress />
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default ApplicationSubmittedSuccess;
