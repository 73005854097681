import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CreateTriggerDefRequestActionNameEnum } from '../../../openapi/sherlock';
import { RootState } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenControlledSelectInput from '../Input/ZenControlledSelectInput';
import {
  CreateEditTriggerFormData,
  CreateEditTriggerStepName,
} from './CreateEditTriggerModal';
import CreateEditTriggerModalFooter from './CreateEditTriggerModalFooter';
import CreateTriggerModalHeader from './CreateEditTriggerModalHeader';

const SubscriptionActionStep: StepByStepComponent<
  CreateEditTriggerFormData,
  CreateEditTriggerStepName
> = ({ form: { control, trigger }, onPrevious, onSubmit, progress }) => {
  const [loading, setLoading] = useState(false);
  const { showEditTriggerModal, selectedTrigger } = useSelector(
    (state: RootState) => state.checkListDefinition,
  );

  let updateTriggerButton = showEditTriggerModal
    ? 'Update Trigger'
    : 'Create Trigger';

  const handleSubmit = async () => {
    const isValid = await trigger();
    if (isValid) {
      setLoading(true);
      await onSubmit();
      setLoading(false);
    }
  };

  let actionDefaultValue = showEditTriggerModal
    ? {
        label: capitalizeEnum(selectedTrigger.actionName!),
        value: selectedTrigger.actionName!,
      }
    : undefined;

  return (
    <div className='max-w-[600px]'>
      <CreateTriggerModalHeader
        currentStep={progress?.currentIndex}
        isEditMode={showEditTriggerModal}
      />

      <div className='px-6 py-6 bg-primary-light'>
        <div className='flex items-start'>
          <div className='mt-[2px] mr-3 w-6 h-6 shrink-0 flex justify-center items-center rounded-full bg-primary-blue overflow-hidden'>
            <span className='text-base text-primary-light'>2</span>
          </div>

          <div className='text-sm font-zen-body font-normal text-primary-dark'>
            Select an action. The set of Subscription-Conditions will result on
            the selected action.
          </div>
        </div>

        <div className='mt-7 mb-4 p-5 flex flex-col items-center justify-center text-zen-body border rounded-lg border-green-600'>
          <div className='text-green-600 font-semibold'>Action</div>

          <div className='w-full mt-5'>
            <ZenControlledSelectInput<
              CreateEditTriggerFormData,
              'subscriptionAction'
            >
              name='subscriptionAction'
              placeholder='Select an action'
              defaultValue={actionDefaultValue!}
              control={control}
              options={values(CreateTriggerDefRequestActionNameEnum).map(
                (action) => ({
                  label: capitalizeEnum(action),
                  value: action,
                }),
              )}
              rules={{ required: 'Action is required' }}
              isRequired
              shouldUnregister={false}
            />
          </div>
        </div>
      </div>

      <CreateEditTriggerModalFooter
        previousLabelText='Go Back'
        nextLabelText={updateTriggerButton}
        previousButtonLeftIcon={
          <FontAwesomeIcon
            icon={faArrowLeft}
            size='lg'
            className='mr-0.5 pt-0.5'
          />
        }
        onPrevious={onPrevious}
        onNext={handleSubmit}
        isSubmitting={loading}
      />
    </div>
  );
};

export default SubscriptionActionStep;
