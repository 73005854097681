import React from 'react';
import { ParticipantResponse } from '../../../openapi/arrakis';
import ZenAvatar from '../../Zen/ZenAvatar';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { AgentInfo } from '../../../openapi/yenta';
import { getParticipantName } from '../../../utils/ParticipantHelper';

interface ParticipantLabelComponentProps {
  participant: ParticipantResponse | AgentInfo;
  showParticipantRole?: boolean;
}

const ParticipantLabelComponent: React.FC<ParticipantLabelComponentProps> = ({
  participant,
  showParticipantRole = true,
}) => {
  const participantName = getParticipantName(participant);
  const participantRole =
    'participantRole' in participant
      ? participant?.participantRole!
      : undefined;

  return (
    <div className='flex flex-row items-center space-x-2'>
      <ZenAvatar
        name={participantName || ''}
        size='sm'
        variant='circle'
        imageUrl={'avatar' in participant ? participant?.avatar : undefined}
      />
      <div className='flex flex-col space-y-0.5'>
        <p className='font-zen-body font-normal text-sm text-zen-dark-9'>
          {participantName || 'N/A'}
        </p>
        {showParticipantRole && (
          <p className='font-zen-body font-normal text-[10px] text-zen-dark-7'>
            {!!participantRole ? capitalizeEnum(participantRole) : 'N/A'}
          </p>
        )}
      </div>
    </div>
  );
};

export default ParticipantLabelComponent;
