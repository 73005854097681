import React from 'react';
import CirclePlexusGif from '../../../../assets/img/roar/circle-plexus.gif';
import EllipseSvg from '../../../../assets/img/roar/ellipse.svg';

const TranscribedByLeoPill: React.FC = () => {
  return (
    <div className='text-rezen-blue-600 rounded-3xl transcribed-by-leo'>
      <div className='flex items-center space-x-1 px-2 py-1'>
        <p className='font-inter text-sm'>Transcribed by Leo</p>
        <div className='relative'>
          <img src={EllipseSvg} alt='ellipse' className='w-5 h-5' />
          <div className='w-3 h-3 absolute mt-px top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 '>
            <img
              src={CirclePlexusGif}
              alt='circle-plexus'
              className='w-full h-auto'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TranscribedByLeoPill;
