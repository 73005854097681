import { values } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  CreateJointVentureRequestStatesEnum,
  JointVentureControllerApi,
} from '../../openapi/avalon';
import { ISelectOption } from '../../types';
import { getAvalonConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import ZenControlledMultiSelectInput from '../Zen/Input/ZenControlledMultiSelectInput';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import CircleCheckStepProgress from './CircleCheckStepProgress';
import {
  CreateJointVentureFormState,
  CreateJointVentureStepName,
} from './CreateJointVentureSteps';
import TitleLayout from './TitleLayout';

const GeneralInfoStep: StepByStepComponent<
  CreateJointVentureFormState,
  CreateJointVentureStepName
> = ({ form: { control }, progress: { currentIndex, lastIndex }, onNext }) => {
  const [companyOptions, setCompanyOptions] = useState<ISelectOption[]>([]);
  const [bankAccountOptions, setBankAccountOptions] = useState<ISelectOption[]>(
    [],
  );

  const getCompaniesAndBankAccounts = useCallback(async () => {
    const { data } = await new JointVentureControllerApi(
      await getAvalonConfiguration(),
    ).createJointVentureForm();
    if (data) {
      setCompanyOptions(
        data.companies!.map(({ id, name }) => ({
          label: name!,
          value: id!,
        })),
      );
      setBankAccountOptions(
        data.bankAccounts!.map(({ id, bankName }) => ({
          label: bankName!,
          value: id!,
        })),
      );
    }
  }, []);

  useEffect(() => {
    getCompaniesAndBankAccounts();
  }, [getCompaniesAndBankAccounts]);
  return (
    <TitleLayout
      onNext={onNext}
      header={
        <CircleCheckStepProgress
          stepNames={Object.values(CreateJointVentureStepName)}
          totalStepsCount={lastIndex + 1}
          inProgressIndex={currentIndex}
        />
      }
    >
      <div className='w-full max-w-2xl mx-auto flex-grow p-5'>
        <div className='text-dark pt-5'>
          <p className='text-xl font-primary-medium text-dark text-center'>
            General Joint Venture Info
          </p>
          <div className='pt-8'>
            <ZenControlledTextInput<CreateJointVentureFormState, 'name'>
              name='name'
              control={control}
              placeholder='Eg: Florida JV'
              label='Joint Venture Name'
              isRequired
              shouldUnregister={false}
              rules={{
                required: 'Please provide the name of the Joint Venture',
              }}
            />
          </div>
          <div className='pt-8'>
            <ZenControlledSelectInput<CreateJointVentureFormState, 'companyId'>
              options={companyOptions}
              label='LLC Company'
              name='companyId'
              placeholder='Select LLC Company'
              control={control}
              isRequired
              shouldUnregister={false}
              rules={{
                required: 'Please select the LLC Company',
              }}
            />
          </div>
          <div className='pt-8'>
            <ZenControlledSelectInput<
              CreateJointVentureFormState,
              'bankAccountId'
            >
              name='bankAccountId'
              label='Bank Account'
              placeholder='Select Bank Account'
              control={control}
              options={bankAccountOptions}
              isRequired
              shouldUnregister={false}
              rules={{
                required: 'Please select the Bank Account',
              }}
            />
          </div>
          <div className='pt-8'>
            <div>
              <ZenControlledMultiSelectInput<
                CreateJointVentureFormState,
                'states'
              >
                name='states'
                label='Participating States'
                placeholder='Select Participating States'
                control={control}
                options={values(CreateJointVentureRequestStatesEnum).map(
                  (el) => ({
                    label: capitalizeEnum(el),
                    value: el,
                  }),
                )}
                shouldUnregister={false}
                isRequired
                rules={{
                  required: 'Please select the Participating States',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </TitleLayout>
  );
};

export default GeneralInfoStep;
