import React from 'react';
import classNames from 'classnames';
import { isSmScreen } from '../../../utils/BrowserUtils';
import Hover from '../../Hover';
import ZenIconCircle, {
  ZenIconCircleSize,
  ZenIconCircleVariant,
} from '../ZenIconCircle';

export interface ZenIconCircleWithTooltipCellProps {
  tooltipText?: string;
  onClick: () => void;
  icon?: React.ReactElement;
  hoverComponent?: React.ReactElement;
  noWrap?: boolean;
  iconVariant?: ZenIconCircleVariant;
  iconSize?: ZenIconCircleSize;
  count?: number;
  disabled?: boolean;
  wrapText?: boolean;
}

const ZenIconCircleWithTooltipCell: React.FC<ZenIconCircleWithTooltipCellProps> = ({
  tooltipText,
  onClick,
  icon,
  iconVariant = 'gray',
  iconSize = 'xs',
  noWrap = false,
  hoverComponent,
  count,
  disabled = false,
  wrapText = true,
}) => {
  return (
    <div className='relative md:space-x-3 flex md:flex-row flex-col'>
      <Hover
        hoverComponent={
          hoverComponent ? (
            hoverComponent
          ) : (
            <div
              className={classNames('text-center text-zen-dark-12 px-4', {
                'whitespace-nowrap': !wrapText,
              })}
            >
              {tooltipText}
            </div>
          )
        }
        config={{
          trigger: 'hover',
          placement: 'top',
          visible: !isSmScreen() ? undefined : false,
        }}
      >
        <button
          type='button'
          data-testid='edit/delete'
          onClick={onClick}
          disabled={disabled}
        >
          {noWrap ? (
            icon
          ) : (
            <ZenIconCircle icon={icon} variant={iconVariant} size={iconSize} />
          )}
        </button>
      </Hover>
      {!!count && (
        <div className='absolute -top-2 -right-1 h-5 w-5 flex items-center justify-center rounded text-xs font-normal bg-zen-danger text-white text-center'>
          {count}
        </div>
      )}
    </div>
  );
};

export default ZenIconCircleWithTooltipCell;
