import { faHouse } from '@fortawesome/pro-light-svg-icons';
import {
  faCheckDouble,
  faCloudArrowDown,
  faCopy,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import config from '../../../../../config';
import { getAuthCookie } from '../../../../../utils/AuthUtils';
import { numberToMoney } from '../../../../../utils/CurrencyUtils';
import {
  copyImageToClipboard,
  getFileBlob,
} from '../../../../../utils/FileUtils';
import {
  getDownloadableImageUrl,
  handleLeoListingPosterDownload,
  ListingPosterItem,
} from '../../../../../utils/LeoUtils';
import ZenButton from '../../../ZenButton';

type LeoSocialShareablesInfoSectionProps = {
  shareablesInfo: ListingPosterItem;
};

const LeoSocialShareablesInfoSection: React.FC<LeoSocialShareablesInfoSectionProps> = ({
  shareablesInfo: { posterLink, listingDetails },
}) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isCopyingImage, setIsCopyingImage] = useState<boolean>(false);
  const [isImageCopied, setIsImageCopied] = useState<boolean>(false);

  const handleDownload = async (posterLink: string) => {
    setIsDownloading(true);
    await handleLeoListingPosterDownload(posterLink);
    setIsDownloading(false);
  };

  const copyImage = async () => {
    setIsCopyingImage(true);
    // this is slow because it downloads the image from server, not a cdn
    const blob = await getFileBlob(getDownloadableImageUrl(posterLink), {
      headers: new Headers({
        Authorization: `Bearer ${getAuthCookie()}`,
      }),
    });
    const blobUrl = URL.createObjectURL(blob!);
    await copyImageToClipboard(blobUrl);
    setIsCopyingImage(false);
    setIsImageCopied(true);
    setTimeout(() => setIsImageCopied(false), 2000);
  };

  return (
    <div className='flex flex-col p-6 relative bg-white h-full w-full overflow-y-scroll font-inter'>
      <div className='pb-6 w-full'>
        <div className='flex-col justify-center pb-6'>
          <span className=' font-normal text-sm text-grey-600 pb-2'>
            {listingDetails.code}
          </span>
          <p className='font-semibold text-xl text-primary-dark pb-2'>
            {listingDetails.address?.one_line}
          </p>
          <span className='font-normal text-lg text-primary-dark'>
            {numberToMoney(listingDetails.price?.amount!)}{' '}
            {listingDetails.price?.currency}
          </span>
        </div>
        <div className='w-full flex flex-col lg:flex-row flex-wrap mt-2 justify-between'>
          <div className='lg:w-1/2 lg:pr-1 pb-2 md:mb-0 w-full'>
            <ZenButton
              label='Download'
              LeftIconComponent={
                !isDownloading ? (
                  <FontAwesomeIcon
                    icon={faCloudArrowDown}
                    className='text-white mr-1'
                  />
                ) : undefined
              }
              onClick={() => handleDownload(posterLink)}
              isSubmitting={isDownloading}
              isDisabled={isDownloading}
              textSize='md'
              className='bg-rezen-blue-600 outline-none border-none text-white font-inter font-normal h-full py-2.5'
              isFullWidth
            />
          </div>
          <div className='lg:w-1/2 lg:pl-1 w-full'>
            <ZenButton
              variant='secondary-light-outline'
              label={isCopyingImage ? 'Copying image' : 'Copy image'}
              LeftIconComponent={
                !isCopyingImage ? (
                  <FontAwesomeIcon
                    icon={isImageCopied ? faCheckDouble : faCopy}
                    className='text-primary-dark mr-1'
                  />
                ) : undefined
              }
              onClick={copyImage}
              className='bg-regent-300 font-inter'
              isFullWidth
              isDisabled={isCopyingImage}
              isSubmitting={isCopyingImage}
            />
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-4 pt-6'>
        <div className='flex flex-row justify-between'>
          <span className='font-medium font-inter text-grey-600 text-base'>
            Listing info
          </span>

          <div className='flex items-center text-rezen-blue-600'>
            <FontAwesomeIcon
              icon={faHouse}
              className='text-rezen-blue-600 mr-2 text-sm md:text-base mb-px'
            />
            <a
              href={`${config.reactAppHostUrl}/listings/${listingDetails.id}`}
              className='font-medium text-sm md:text-base'
              target='_blank'
              rel='noreferrer'
            >
              Open Listing
            </a>
          </div>
        </div>
        <div className='grid grid-cols-[auto_1fr] gap-5 font-normal text-sm '>
          <div className='text-primary-dark '>Listing added </div>
          <div className='text-grey-600 '>
            {listingDetails.listing_date || 'N/A'}
          </div>

          <div className='text-primary-dark '>Property Status</div>
          <div className='text-grey-600 '>
            {listingDetails.lifecycle_state.state === 'LISTING_ACTIVE'
              ? 'Active'
              : 'Inactive'}
          </div>

          <div className='text-primary-dark '>Owner</div>
          <div className='text-grey-600 '>
            {listingDetails.transaction_owner?.name}
          </div>

          <div className='text-primary-dark '>Built</div>
          <div className='text-grey-600 '>{listingDetails.year_built}</div>
        </div>
      </div>
    </div>
  );
};
export default LeoSocialShareablesInfoSection;
