import React from 'react';
import styled from 'styled-components';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ABOUT_1099_FORM_LINK } from '../../constants/TEN99FormConstants';
import { TaxDocumentResponse } from '../../openapi/arrakis';
import { numberToMoney } from '../../utils/CurrencyUtils';
import { capitalizeEnum, formatTaxDocAddress } from '../../utils/StringUtils';
import Ten99FormInputField from '../Ten99Form/Ten99FormInputField';
import TEN99FormStepNumberInputField from '../Ten99Form/TEN99FormStepNumberInputField';
import { isTaxDocumentAmended } from '../../utils/TaxDocumentHelper';

const Print = styled.div`
  width: 1024px;
  margin: 0 auto;

  @media print {
    @page {
      size: A4;
      margin: 20pt 0 35pt;
    }
  }
`;
interface TEN99NECFormProps {
  taxDocument: TaxDocumentResponse;
}

const TEN99NECForm: React.FC<TEN99NECFormProps> = ({ taxDocument }) => {
  const compensationsAmt =
    (taxDocument.netCommissionPaid?.amount! || 0) +
    (taxDocument.equityContributed?.amount! || 0) +
    (taxDocument.revsharePaid?.amount! || 0) +
    (taxDocument.releasedEquityMatch?.amount! || 0) +
    (taxDocument.releasedAwardsEquity?.amount! || 0);

  const streetAddressArr = [taxDocument.mailingAddress?.street];
  if (taxDocument.mailingAddress?.street2) {
    streetAddressArr.push(taxDocument.mailingAddress.street2);
  }
  const streetAddress = streetAddressArr.join(', ');

  const address = [];

  if (taxDocument.mailingAddress?.city) {
    address.push(taxDocument.mailingAddress?.city);
  }

  if (taxDocument.mailingAddress?.state) {
    address.push(capitalizeEnum(taxDocument.mailingAddress?.state));
  }

  if (taxDocument.mailingAddress?.zip) {
    address.push(taxDocument.mailingAddress?.zip);
  }

  return (
    <Print>
      <div className='w-full h-auto scrollbar overflow-auto text-sm print:text-xs'>
        <div className='w-full print:mx-0 mx-auto print:py-0 py-12'>
          <div className='flex flex-col justify-center p-8'>
            <div className='flex flex-row justify-center items-center space-x-2 mb-1'>
              <div className='flex flex-row items-center justify-center text-center w-6 h-6 border border-gray-400'>
                {isTaxDocumentAmended(taxDocument) && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className='mt-2 text-lg'
                    aria-label='check-icon'
                  />
                )}
              </div>
              <div>
                <p>CORRECTED (if checked)</p>
              </div>
            </div>
            <div className='grid grid-rows-1 grid-cols-12'>
              <div className='col-span-5 border-2 border-gray-800'>
                <div>
                  <Ten99FormInputField
                    label="PAYER'S name, street address, city or town, state or
               province, country, ZIP or foreign postal code, and telephone no."
                    size='2xl'
                    value={`${
                      taxDocument.realBusinessName || ''
                    }\n${formatTaxDocAddress(
                      taxDocument.realBusinessAddress!,
                    )}`}
                  />
                </div>

                <div className='flex flex-row items-center border-t-2 border-gray-800'>
                  <div className='flex-grow border-r-2 border-gray-800'>
                    <Ten99FormInputField
                      label="PAYER'S TIN"
                      size='xs'
                      value={taxDocument.realTaxId}
                    />
                  </div>
                  <div className='flex-grow'>
                    <Ten99FormInputField
                      label="RECIPIENT'S TIN"
                      size='xs'
                      value={
                        taxDocument.businessTaxId || taxDocument.personalTaxId
                      }
                    />
                  </div>
                </div>
                <div className='border-t-2 border-b-2 border-gray-800'>
                  <Ten99FormInputField
                    label="RECIPIENT'S name"
                    size='lg'
                    value={taxDocument.name}
                  />
                  <Ten99FormInputField
                    label='Street address (including apt. no.)'
                    size='xs'
                    value={streetAddress}
                  />
                  <Ten99FormInputField
                    label='City or town, state or province, country, and ZIP or foreign
              postal code'
                    size='xs'
                    value={address.join(', ')}
                  />
                </div>
                <div>
                  <Ten99FormInputField
                    label='Account number (see instructions)'
                    size='xs'
                  />
                </div>
              </div>
              <div className='col-span-7'>
                <div className='flex print:flex flex-row'>
                  <div className='flex w-full flex-row print:flex flex-grow print:flex-grow border-t-2 border-r-2 border-gray-800'>
                    <div className='flex flex-col items-center w-32 h-32 bg-gray-500' />
                    <div className='flex flex-grow flex-col'>
                      <div className='px-3'>
                        <span>OMB No. 1545-0116</span>
                        <div className='flex flex-row justify-between items-center'>
                          <span>Form</span>
                          <h3 className='font-primary-bold text-xl print:text-sm text-dark'>
                            1099 NEC
                          </h3>
                        </div>
                        <span>(Rev. January 2022)</span>
                      </div>
                      <hr className='h-0.5 bg-gray-800' />
                      <span className='px-3 print:px-1'>For calendar year</span>
                      <div className='flex justify-center items-center'>
                        <p>
                          20
                          <span className='font-primary-medium text-sm text-dark underline'>
                            {(taxDocument?.year || 0) % 1000}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col justify-center items-center p-8 print:py-8 px-3'>
                    <p className='font-primary-bold text-xl print:text-lg text-dark'>
                      Nonemployee Compensation
                    </p>
                  </div>
                </div>
                <div className='flex flex-row border-t-2 border-b-2 border-gray-800'>
                  <div className='w-8/12'>
                    <div className='border-b-2 border-gray-800'>
                      <TEN99FormStepNumberInputField
                        label='Nonemployee compensation'
                        step={1}
                        value={numberToMoney(compensationsAmt, {
                          includeCurrencySymbol: false,
                        })}
                      />
                    </div>
                    <div className='border-b-2 border-gray-800'>
                      <TEN99FormStepNumberInputField
                        label='Payer made direct sales totaling $5,000 or more of
                      consumer products to recipient for resale'
                        step={2}
                        isCheck
                      />
                    </div>
                    <div className='border-b-2 border-gray-800'>
                      <div className='relative h-10 bg-gray-500'>
                        <p className='absolute top-0 left-0 px-2 bg-white'>3</p>
                      </div>
                    </div>
                    <div>
                      <TEN99FormStepNumberInputField
                        label='Federal income tax withheld'
                        step={4}
                        value='0'
                      />
                    </div>
                  </div>
                  <div className='w-4/12 flex flex-col items-end border-l-2 border-gray-800'>
                    <p className='font-primary-bold text-xl print:text-lg text-dark'>
                      Copy B
                    </p>
                    <p className='font-primary-bold text-base text-dark'>
                      For Recipient
                    </p>
                    <p className='font-primary-regular text-right text-xs text-dark px-0.5'>
                      formation and is being furnished to the IRS. If you are
                      required to file a return, a negligence penalty or other
                      sanction may be imposed on you if this income is taxable
                      and the IRS determines that it has not been reported.
                    </p>
                  </div>
                </div>
                <div className='flex flex-row border-r-2 border-b-2 border-gray-800'>
                  <div className='flex-auto border-r-2 border-gray-800'>
                    <TEN99FormStepNumberInputField
                      label='State tax withheld'
                      step={5}
                      value={['0', '0']}
                    />
                  </div>
                  <div className='flex-auto border-r-2 border-gray-800'>
                    <TEN99FormStepNumberInputField
                      label="State/Payer's state no."
                      step={6}
                      value={['', '']}
                      isCurrencyInput={false}
                    />
                  </div>
                  <div className='flex-auto'>
                    <TEN99FormStepNumberInputField
                      label=' State income'
                      step={7}
                      value={['', '']}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full'>
              <div className='flex flex-row items-center justify-between'>
                <div className='flex flex-row items-center flex-nowrap'>
                  <p className='font-primary-regular text-xs text-dark'>Form</p>
                  <p className='font-primary-bold text-xl print:text-lg text-dark whitespace-nowrap'>
                    1099-NEC{' '}
                    <span className='font-primary-regular text-xs text-dark'>
                      (Rev. 1-2022)
                    </span>
                  </p>
                </div>
                <p className='font-primary-regular text-xs print:text-3xs text-dark whitespace-nowrap'>
                  (keep for your records)
                </p>
                <a
                  href={ABOUT_1099_FORM_LINK}
                  rel='noreferrer'
                  target='_blank'
                  className='font-primary-regular text-xs print:text-3xs text-dark whitespace-nowrap'
                >
                  www.irs.gov/forms-pubs/about-form-1099-nec
                </a>
                <p className='font-primary-regular text-xs print:text-3xs text-dark whitespace-nowrap'>
                  Department of the Treasury - Internal Revenue Service
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Print>
  );
};

export default TEN99NECForm;
