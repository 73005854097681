import { DateTime } from 'luxon';
import { ReactDatePickerProps } from 'react-datepicker';
import { GooglePlaceLocationType } from '../../components/ControlledGoogleAutocompleteSearchInputV7';
import { FieldObject } from '../../openapi/atlantis';
import { getAddressFromAddressComponent } from '../TransactionHelper';
import { Validation } from './MortgageApplicationFormUtils';

export const getInputValidations = (field: FieldObject): Validation => {
  let validations: Validation = {};

  if (field.required) {
    validations.required = 'Required';
  }

  for (let validation of field.validations || []) {
    if (validation.type === 'regex') {
      validations.pattern = {
        //@ts-ignore
        value: new RegExp(validation.value),
        //@ts-ignore
        message: validation.message,
      };
    }
  }

  return validations;
};

export const getDatePickerConfig = (
  field: FieldObject,
): Omit<
  ReactDatePickerProps,
  'name' | 'onChange' | 'selected' | 'onBlur' | 'readOnly'
> => {
  let config: Omit<
    ReactDatePickerProps,
    'name' | 'onChange' | 'selected' | 'onBlur' | 'readOnly'
  > = {};

  field.validations?.forEach((validation: any) => {
    const date = DateTime.fromISO(validation.effectiveValue);

    if (date.isValid) {
      if (validation.type === 'minDate') {
        config.minDate = date.toJSDate();
      }

      if (validation.type === 'maxDate') {
        config.maxDate = date.toJSDate();
      }
    }
  });

  return config;
};

export const getGoogleAutoCompleteInputValidations = (
  field: FieldObject,
): Validation => {
  return {
    validate: (value: GooglePlaceLocationType | undefined) => {
      if (field.required && !value?.formatted_address) {
        return 'Required';
      }

      const address = getAddressFromAddressComponent(
        value?.address_components!,
      );

      //@ts-ignore
      const validateFieldsFromConfig = field.validations
        .filter((v: any) => address.hasOwnProperty(v.type))
        .map((d: any) => !!address[d.type as keyof typeof address])
        .includes(false);

      const mandatoryAddressFields =
        !address.city || !address.zipOrPostalCode || !address.streetAddress1;

      const isNotValidAddress =
        mandatoryAddressFields && validateFieldsFromConfig && !!value?.place_id;

      if (isNotValidAddress) {
        return 'Please enter a valid address';
      }

      return undefined;
    },
  };
};
