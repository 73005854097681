import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { UserPreviewResponse } from '../../../openapi/yenta';
import {
  getTeamDetailOverview,
  updateTeamMember,
} from '../../../slices/TeamSlice';
import { isSmScreen } from '../../../utils/BrowserUtils';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';

interface ZenDeleteTeamMemberModalProps {
  teamId: string;
  memberToDelete: UserPreviewResponse | undefined | null;
  onClose(): void;
  setMemberToDelete(): void;
}

interface DeleteTeamFormData {
  confirm: string;
}

const ZenDeleteTeamMemberModal: React.FC<ZenDeleteTeamMemberModalProps> = ({
  teamId,
  memberToDelete,
  onClose,
  setMemberToDelete,
}) => {
  const { control, watch } = useForm<DeleteTeamFormData>();

  const CONFIRMATION_STRING_REQUIRED = 'CONFIRM';
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const watchConfirm = watch('confirm');
  const isDisabled = watchConfirm?.trim() !== CONFIRMATION_STRING_REQUIRED;

  return (
    <ZenConfirmationModal
      isOpen={!!memberToDelete}
      variant='danger'
      title={`Are you sure you want to remove ${memberToDelete?.fullName!}?`}
      subtitle={
        <div className='flex-col'>
          <div className='text-zen-dark-9 font-zen-body'>
            You can always add them back.
          </div>
          <div className='mt-2 flex justify-center'>
            <div className='w-1/2'>
              <ZenControlledTextInput
                control={control}
                name='confirm'
                type='text'
                placeholder={`Please type ${CONFIRMATION_STRING_REQUIRED}`}
              />
            </div>
          </div>
        </div>
      }
      onClose={setMemberToDelete}
      onConfirm={async () => {
        setLoading(true);
        await dispatch(
          updateTeamMember(teamId, {
            createRequests: [],
            updateRequests: [],
            userIdsToDelete: [memberToDelete?.id!],
          }),
        );
        setLoading(false);
        setMemberToDelete();
        dispatch(getTeamDetailOverview(teamId!));
        onClose();
      }}
      isSubmitting={loading}
      isDisabled={isDisabled}
      confirmButtonText='Remove Member'
      size='large'
      hideIcon={isSmScreen()}
      confirmButtonLeftIcon={<FontAwesomeIcon icon={faTrashCan} />}
    />
  );
};

export default ZenDeleteTeamMemberModal;
