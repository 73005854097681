import { DateTime } from 'luxon';
import React from 'react';
import Card from '../../Card';

interface CheckDepositHeaderProps {
  transactionAddress?: string | undefined;
  closingDate?: string;
}

const CheckDepositHeader: React.FC<CheckDepositHeaderProps> = ({
  transactionAddress,
  closingDate,
}) => {
  return (
    <Card>
      <div className='p-4'>
        <h6 className='font-primary-regular text-base text-dark'>
          Transaction
        </h6>
        <p className='py-2 text-xl font-primary-regular font-medium text-dark'>
          {transactionAddress}
        </p>
        {closingDate && (
          <p className='font-primary-regular text-base text-dark pt-2'>
            Certified closing date:
            <span className='font-primary-medium text-base text-dark ml-1'>
              {DateTime.fromISO(closingDate).toFormat('LL/dd/y')}
            </span>
          </p>
        )}
      </div>
    </Card>
  );
};

export default CheckDepositHeader;
