import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import Select, { ControlProps, components } from 'react-select';
import { ISelectOption } from '../types';
import FormErrorMessage from './FormErrorMessage';

interface ControlledMultiSelectInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  placeholder?: string;
  options: Array<ISelectOption>;
  placeholderLabel?: string;
}

const Control = ({ children, ...props }: ControlProps<ISelectOption, true>) => {
  return (
    <components.Control {...props}>
      {!!props.selectProps.placeholderLabel && (
        <span className='px-2'>{props.selectProps.placeholderLabel}</span>
      )}
      {children}
    </components.Control>
  );
};

const ControlledMultiSelectInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  placeholder = 'Select',
  options,
  shouldUnregister = false,
  placeholderLabel,
  ...rest
}: ControlledMultiSelectInputV7Props<TFieldValues, TName>) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <div className='space-y-1 w-full'>
          {label && <label htmlFor={name}>{label}</label>}
          <Select<ISelectOption, true>
            options={options}
            placeholder={placeholder}
            name={name}
            components={{ Control }}
            isMulti
            value={value}
            closeMenuOnSelect={false}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            className='react-select-container'
            classNamePrefix='react-select'
            inputId={name}
            placeholderLabel={placeholderLabel}
          />
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledMultiSelectInputV7;
