import { groupBy, map } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import { displayDate, isToday } from '../../../../utils/DateUtils';
import { scrollToBottom } from '../../../../utils/DOMUtils';
import DefaultLoader from '../../../DefaultLoader';
import LeoChatBubble from './LeoChatBubble';
import { SENDER_RECEIVER_ENUM } from './LeoChatIndex';
import UserChatBubble from './UserChatBubble';

interface LeoChatProps {
  isShowingHistory: boolean;
}

const LeoChat: React.FC<LeoChatProps> = ({ isShowingHistory }) => {
  const { currentChat, isChatLoading, msgContext } = useSelector(
    (state: RootState) => state.leo,
  );
  const currentMessage = currentChat[currentChat.length - 1]
    ? currentChat[currentChat.length - 1].message
    : '';

  useEffect(() => {
    if (!isChatLoading) {
      scrollToBottom(document?.getElementById('leo-chat-box')!);
    }
  }, [currentMessage, msgContext, isChatLoading]);

  const chatGroupedByDate = groupBy(currentChat, (el) =>
    displayDate(el.created_at!!),
  );

  const getGroupedByDateHeader = (createdAt: string) => {
    const dateString = displayDate(createdAt);
    if (isToday(createdAt)) {
      return `Today ${dateString}`;
    }
    return dateString;
  };

  return (
    <div
      className='h-[50px] flex-grow px-8 sm:pl-12 py-3 overflow-y-auto scrollbar-hide'
      id='leo-chat-box'
    >
      {isChatLoading ? (
        <DefaultLoader />
      ) : (
        map(chatGroupedByDate, (chatByDate) => (
          <div key={chatByDate[0].created_at!}>
            <div className='line-with-words-center mb-3 after:border-b before:border-b after:border-b-zen-dark-6 before:border-b-zen-dark-6'>
              <p className='bg-[#05060b] text-zen-dark-6 text-xs px-1'>
                {getGroupedByDateHeader(chatByDate[0].created_at!)}
              </p>
            </div>
            {(chatByDate || []).map((msgRes, ind) =>
              msgRes.sender === SENDER_RECEIVER_ENUM.SYSTEM ? (
                <LeoChatBubble
                  {...msgRes}
                  isShowingHistory={isShowingHistory}
                  isDisclaimer={ind === 0}
                />
              ) : (
                <UserChatBubble
                  text={msgRes.message!}
                  sentTime={msgRes.created_at!}
                />
              ),
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default LeoChat;
