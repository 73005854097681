import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../types';
import { fetchAgentDetail } from '../slices/AgentSlice';
import ResourceContainer from '../components/ResourceContainer';
import DetailPageLoader from '../components/DetailPageLoader';
import ZenBoardCard from '../components/Agent/V2/ZenBoardCard';
import ZenMlsCard from '../components/Agent/V2/ZenMlsCard';
import ZenBasicInformationCard from '../components/Agent/V2/ZenBasicInformationCard';
import ZenPublicProfileDetailHeader from '../components/Zen/PublicProfile/ZenPublicProfileDetailHeader';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenCard from '../components/Zen/ZenCard';
import ZenTeamAgentLicenses from '../components/Zen/Teams/ZenTeamAgentLicense';

type Match = {
  id: string;
};

const ZenPublicProfileDetailRoute: React.FC = () => {
  const { id: agentId } = useParams() as Match;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const {
    auth: { userDetail },
    agentDetail: { detailResponse },
  } = useSelector((state: RootState) => state);

  const fetchData = useCallback(async () => {
    setLoading(true);
    return Promise.all([dispatch(fetchAgentDetail(agentId))]);
  }, [agentId, dispatch]);

  useEffect(() => {
    fetchData().finally(() => {
      setLoading(false);
    });
  }, [fetchData]);

  const path = [
    { title: 'Home', url: '/' },
    {
      title: 'Profile',
      url: `/people/${userDetail?.id}`,
    },
  ];

  if (detailResponse.data) {
    path.push({
      title: detailResponse.data.fullName!,
      url: `/people/${detailResponse.data.id}`,
    });
  }

  return (
    <ResourceContainer
      loading={loading}
      LoaderComponent={<DetailPageLoader />}
      errorCode={detailResponse.error}
      isEmpty={!detailResponse.data}
      resourceName='agent'
    >
      <div className='relative'>
        <ZenPageLayout path={path} stickyHeader>
          <ZenPublicProfileDetailHeader agentInfo={detailResponse.data!} />
          <div className='flex md:flex-row flex-col'>
            <div className='relative md:ml-[380px] gap-x-4 px-4 md:px-8 py-6 w-full'>
              <div className='col-span-3 grid grid-cols-1 gap-y-4.5'>
                <ZenBasicInformationCard
                  agent={detailResponse.data!}
                  readonly
                />
                <ZenCard title='Licenses'>
                  <ZenTeamAgentLicenses
                    licenses={detailResponse.data?.licenses!}
                  />
                </ZenCard>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-7 gap-y-4.5'>
                  <ZenBoardCard agentResponse={detailResponse} />
                  <ZenMlsCard agentResponse={detailResponse} />
                </div>
              </div>
            </div>
          </div>
        </ZenPageLayout>
      </div>
    </ResourceContainer>
  );
};

export default ZenPublicProfileDetailRoute;
