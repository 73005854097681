import pluralize from 'pluralize';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMessageLines,
  faPaperclip,
} from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MessageDto } from '../../../openapi/yada';
import { RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { getRelativeTimeShort } from '../../../utils/DateUtils';
import ZenCheckbox from '../../Zen/ZenCheckbox';

interface BrokerQueueTransactionProps {
  message: MessageDto;
  onClick(): void;
  selected: boolean;
  onChange(): void;
}

const BrokerQueueTransaction: React.FC<BrokerQueueTransactionProps> = ({
  message,
  onClick,
  selected,
  onChange,
}) => {
  const location = useLocation();
  const { markingReadUnreadLoading } = useSelector(
    (state: RootState) => state.brokerQueue,
  );
  const paths = [
    `/transactions/${message.target?.id}`,
    `/listings/${message.target?.id}`,
    `/borrower/${message.target?.id}`,
  ];
  const isActive = paths.some((path) => location.pathname.includes(path));
  const isUnread = !message.read;

  return (
    <div
      className={cn('flex', {
        'bg-green-100': !isActive && isUnread,
      })}
    >
      <div className='w-2 shrink-0 flex'>
        {!isActive && isUnread && (
          <div className='flex items-center h-full'>
            <div className='w-2 h-2 rounded-full bg-green-400 ml-1' />
          </div>
        )}
        {isActive && <div className='w-1.5 h-full bg-primary-blue' />}
      </div>
      <div
        className={cn('flex-grow p-3 border-b border-grey-200', {
          'bg-rezen-light-blue-100': isActive,
        })}
      >
        <div className='flex sm:space-x-2'>
          <div
            className='space-y-1 cursor-pointer flex flex-col flex-grow'
            onClick={onClick}
          >
            <div className='flex items-center'>
              <div className='mr-1.5 mb-1' onClick={(e) => e.stopPropagation()}>
                <ZenCheckbox
                  variant='square'
                  ringVariant='none'
                  borderVariant='dark'
                  value={selected}
                  onChange={onChange}
                  disabled={markingReadUnreadLoading}
                />
              </div>
              <div className='flex-grow grid grid-cols-1'>
                <span className='font-primary-medium text-lg'>
                  <span className='col-span-1 block truncate'>
                    {message.target?.metadata?.name}
                  </span>
                </span>
              </div>
            </div>
            <div className='flex flex-col gap-1'>
              {!!message.unreadComments && (
                <div className='flex items-center gap-1'>
                  <FontAwesomeIcon
                    icon={faMessageLines}
                    size='sm'
                    className='text-grey-600'
                  />
                  <p className='bg-grey-300 rounded-full w-5 h-5 flex items-center justify-center p-1 font-primary-regular text-xs'>
                    {message.unreadComments}
                  </p>
                  <span className='text-sm text-primary-dark font-primary-light'>
                    {pluralize('Comment', message.unreadComments)}
                  </span>
                </div>
              )}
              {!!message.unreadFileUploads && (
                <div className='flex items-center gap-1.5'>
                  <FontAwesomeIcon
                    icon={faPaperclip}
                    size='sm'
                    className='text-grey-600'
                  />
                  <p className='bg-grey-300 rounded-full w-5 h-5 flex items-center justify-center p-1 font-primary-regular text-xs'>
                    {message.unreadFileUploads}
                  </p>
                  <span className='text-sm text-primary-dark font-primary-light'>
                    {pluralize('Upload', message.unreadFileUploads)}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='space-y-1 flex items-end'>
            <p className='whitespace-nowrap font-primary-regular text-xs text-grey-500 text-center'>
              {getRelativeTimeShort(message.createdAt!)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrokerQueueTransaction;
