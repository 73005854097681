import React, { useState } from 'react';
import { UnpackNestedValue } from 'react-hook-form-v7';
import { TableInstance } from 'react-table';
import { createPortal } from 'react-dom';
import { ZenRowsSelectionAction } from '../../../types';
import ZenFilterOption from '../../table/Options/ZenFilterOption';
import ZenFilterConfirmationModal from '../../table/ZenFilterConfirmationModal';
import ZenButton from '../ZenButton';

interface TableOptionsProps<D extends object> {
  tableInstance: TableInstance<D>;
  selectionOptions?: ZenRowsSelectionAction<D>[];
  onSelectionOptionClicked?(
    index: number,
    formData?: UnpackNestedValue<D>,
  ): void;
  hideFilters?: boolean;
  selectionPortal?: React.RefObject<any>;
}

const ZenTableOptions = <D extends object>({
  selectionOptions = [],
  selectionPortal,
  onSelectionOptionClicked,
  tableInstance,
  hideFilters = false,
}: TableOptionsProps<D>) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [selectionIndex, setSelectionIndex] = useState<number | null>(null);

  const handleOptionClick = async (
    index: number,
    formData?: UnpackNestedValue<D>,
  ) => {
    setSubmitting(true);
    await onSelectionOptionClicked!(index, formData);
    setSubmitting(false);
    setSelectionIndex(null);
  };

  const renderSelectionOptions = () => {
    return selectionOptions.map(
      ({ label, variant = 'primary', confirm, disabled }, index) => (
        <div key={label}>
          <ZenButton
            variant={variant}
            label={label}
            isDisabled={disabled}
            onClick={() => {
              if (confirm) {
                setSelectionIndex(index);
              } else {
                handleOptionClick(index);
              }
            }}
          />
        </div>
      ),
    );
  };

  return (
    <div className='flex flex-row flex-nowrap space-x-3 justify-end'>
      {!!selectionPortal?.current
        ? createPortal(renderSelectionOptions(), selectionPortal.current)
        : renderSelectionOptions()}
      {!hideFilters && <ZenFilterOption<D> tableInstance={tableInstance} />}
      {selectionIndex !== null && !!selectionOptions[selectionIndex] && (
        <div>
          <ZenFilterConfirmationModal<D>
            selection={selectionOptions[selectionIndex]}
            isSubmitting={isSubmitting}
            onClick={(formData?: UnpackNestedValue<D>) =>
              handleOptionClick(selectionIndex, formData)
            }
            onClose={() => setSelectionIndex(null)}
          />
        </div>
      )}
    </div>
  );
};

export default ZenTableOptions;
