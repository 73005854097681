import React from 'react';
import { EnumMap } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';

export type TransactionOrListingPillVariantType =
  | 'ACTIVE'
  | 'CLOSED'
  | 'TERMINATED';

interface Props {
  label: string;
  variant: TransactionOrListingPillVariantType;
}

const TransactionOrListingOverviewDisplayPill: React.FC<Props> = ({
  label,
  variant,
}) => {
  const pillVariantTypeToClassNameMap: EnumMap<
    TransactionOrListingPillVariantType,
    string
  > = {
    ACTIVE: 'bg-midnight-green-300 text-midnight-green-900',
    CLOSED: 'bg-rezen-light-blue-100 text-regent-900',
    TERMINATED: 'bg-red-100 text-red-600',
  };

  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center rounded-lg p-4 border border-regent-600 font-zen-body-2 text-sm leading-4 h-max flex-1',
        pillVariantTypeToClassNameMap[variant],
      )}
    >
      <span className='font-bold'>{label}</span>
      <span className='font-medium'>{capitalizeEnum(variant)}</span>
    </div>
  );
};

export default TransactionOrListingOverviewDisplayPill;
