import qs from 'qs';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

interface useBrokerQueueResponse {
  isBrokerQueueActive: boolean;
}

const useBrokerQueue = (): useBrokerQueueResponse => {
  const location = useLocation();
  const { brokerQueue } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [isBrokerQueueActive] = useState(brokerQueue === 'true');

  return { isBrokerQueueActive };
};

export default useBrokerQueue;
