import {
  DepositCheckFormData,
  DepositCheckStepName,
} from '../../../routes/ZenTransactionDepositCheckRoute';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenProgressBar from '../../ZenProgressBar';

const withZenExternalCheckDepositProgress = (
  Component: StepByStepComponent<DepositCheckFormData, DepositCheckStepName>,
): StepByStepComponent<DepositCheckFormData, DepositCheckStepName> => (
  props,
) => {
  const {
    progress: { currentIndex, lastIndex },
    form: { watch },
  } = props;
  const isExternalCheckDeposit = watch('isExternalCheckDeposit');

  return (
    <>
      {isExternalCheckDeposit && (
        <div className='absolute top-0 left-0 w-full'>
          {currentIndex > 0 ? (
            <ZenProgressBar completed={currentIndex + 1} total={lastIndex} />
          ) : (
            <div className='flex relative w-full bg-green-600 h-10 justify-center items-center'>
              <p className='text-base text-white text-center'>
                Deposit a check online in 3 easy steps!
              </p>
            </div>
          )}
        </div>
      )}
      <Component {...props} />
    </>
  );
};

export default withZenExternalCheckDepositProgress;
