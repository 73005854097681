import { faAddressBook, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  DirectoryEntryResponseTypeEnum,
  DirectoryVendorResponseRoleEnum,
} from '../../../../openapi/yenta';
import { DirectoryCommonEntityResponse } from '../../../../query/directory/useDirectory';
import { CreateTransactionParticipantType } from '../../../../types';
import { EMAIL_VALIDATIONS } from '../../../../utils/Validations';
import ZenRoleDirectoryForm from '../../../Directory/ZenRoleDirectoryForm';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenContactListSidebar from '../../../Directory/ZenContactListSidebar';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenButton from '../../ZenButton';
import ZenStickyBottomPagination from '../../ZenStickyBottomPagination';
import { FieldArrayPrefix } from '../CreateTransaction/ZenTransactionBuyerAndSellerStep';
import withZenCreateReferralTransactionProgress from './withZenCreateReferralTransactionProgress';
import {
  ReferralTransactionFormState,
  ReferralTransactionStepName,
} from './ZenReferralTransactionSteps';

const ZenClientInformationStep: StepByStepComponent<
  ReferralTransactionFormState,
  ReferralTransactionStepName
> = ({ form: { control, setValue, getValues }, onPrevious, onNext }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [roleSibebarOpen, setRoleSidebarOpen] = useState<boolean>(false);
  const [currentFormContact, setCurrentFormContact] = useState<
    DirectoryCommonEntityResponse | undefined
  >();
  const handleSidebarToggle = () => () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleSave = (
    _fieldArrayPrefix?: FieldArrayPrefix,
    contact?: DirectoryCommonEntityResponse,
  ) => {
    setValue('clientFirstName', contact?.firstName ?? '');
    setValue('clientLastName', contact?.lastName ?? '');
    setValue('clientEmailAddress', contact?.emailAddress ?? '');
  };
  const handleRoleSidebarToggle = () => async () => {
    const values = getValues();
    setCurrentFormContact({
      emailAddress: values?.clientEmailAddress,
      firstName: values?.clientFirstName,
      lastName: values?.clientLastName,
      role: DirectoryVendorResponseRoleEnum.Client,
    });
    setRoleSidebarOpen((prevState) => !prevState);
  };
  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div>
          <div className='flex flex-row items-start justify-between'>
            <p className='text-xl font-zen-body font-semibold text-zen-dark-9 mb-4'>
              Client Information
            </p>
            <div className='-mt-1'>
              <ZenButton
                label='Directory'
                variant='primary-link'
                LeftIconComponent={<FontAwesomeIcon icon={faAddressBook} />}
                onClick={handleSidebarToggle()}
                zeroPadding
                fontstyle
              />
            </div>
          </div>
          <div className='space-y-4'>
            <div>
              <ZenControlledTextInput<
                ReferralTransactionFormState,
                'clientFirstName'
              >
                control={control}
                label='First Name'
                name='clientFirstName'
                shouldUnregister={false}
                placeholder='E.g. Andrew'
                rules={{
                  required: 'Required',
                }}
                isRequired
              />
            </div>
            <div>
              <ZenControlledTextInput<
                ReferralTransactionFormState,
                'clientLastName'
              >
                control={control}
                label='Last Name'
                name='clientLastName'
                shouldUnregister={false}
                placeholder='E.g. Garfield.'
                rules={{
                  required: 'Required',
                }}
                isRequired
              />
            </div>
            <div>
              <ZenControlledTextInput<
                ReferralTransactionFormState,
                'clientEmailAddress'
              >
                control={control}
                label='Email Address'
                name='clientEmailAddress'
                shouldUnregister={false}
                rules={{ ...EMAIL_VALIDATIONS }}
                placeholder='E.g. garfield@example.com'
              />
            </div>
            <div className='flex items-end justify-end mt-3 -mr-4'>
              <ZenButton
                label='Add to My Directory'
                variant='primary-link'
                LeftIconComponent={
                  <FontAwesomeIcon
                    icon={faPlus}
                    className='text-primary-blue cursor-pointer'
                  />
                }
                onClick={handleRoleSidebarToggle()}
                fontstyle
              />
            </div>
          </div>
        </div>
      </div>
      {isSidebarOpen && (
        <ZenContactListSidebar
          participantType={CreateTransactionParticipantType.PERSON}
          isOpen={isSidebarOpen}
          onClose={handleSidebarToggle()}
          onSave={handleSave}
          currentFieldArrayPrefixNotRequired
        />
      )}
      {roleSibebarOpen && (
        <ZenRoleDirectoryForm
          isOpen={roleSibebarOpen}
          existingVendorOrPerson={currentFormContact}
          mode='populate'
          existingType={DirectoryEntryResponseTypeEnum.Person}
          onClose={handleRoleSidebarToggle()}
        />
      )}
      <ZenStickyBottomPagination
        maxWidth='2xl'
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </div>
  );
};

export default withZenCreateReferralTransactionProgress(
  ZenClientInformationStep,
);
