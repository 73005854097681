import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DefaultLoader from '../components/DefaultLoader';
import useQueryParams from '../hooks/useQueryParams';

interface Query {
  formUrl?: string;
  formApproved?: string;
}

interface WalletApplicationRouteProps {}

const WalletApplicationRoute: React.FC<WalletApplicationRouteProps> = () => {
  const history = useHistory();
  const { formUrl, formApproved } = useQueryParams<Query>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { redirectTo } = event.data;

      if (event.origin === process.env.REACT_APP_HOST_URL && redirectTo) {
        history.push(redirectTo);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [history]);

  useEffect(() => {
    if (formApproved === 'true') {
      window.parent.postMessage(
        { redirectTo: '/wallet' },
        process.env.REACT_APP_HOST_URL || '*',
      );
    }
  }, [formApproved]);

  return (
    <div className='w-screen h-screen'>
      {isLoading && <DefaultLoader />}
      <iframe
        src={formUrl}
        title='wallet-application-form'
        width='100%'
        height='100%'
        onLoad={() => setIsLoading(false)}
        style={{ display: isLoading ? 'none' : 'block' }}
      />
    </div>
  );
};

export default WalletApplicationRoute;
