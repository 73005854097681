import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface SidebarModalProps {
  title: string;
  subtitle?: string;
  isOpen: boolean;
  onClose(): void;
}

const SidebarModal: React.FC<SidebarModalProps> = ({
  title,
  subtitle,
  isOpen,
  onClose,
  children,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full text-gray-600 h-full-window'
      title='sidebar'
    >
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 hidden bg-black lg:block bg-opacity-40'
        role='button'
        title='close'
        onClick={onClose}
      />
      <div className='relative flex flex-col w-full h-full ml-auto bg-white lg:w-1/3 animate-slide-in-up lg:animate-slide-in-right'>
        <div className='flex items-start justify-between p-4 bg-gray-100'>
          <div>
            <h2 className='text-lg font-primary-medium text-gray-700'>
              {title}
            </h2>
            {subtitle && <p className='text-xs text-gray-600'>{subtitle}</p>}
          </div>
          <div>
            <FontAwesomeIcon
              icon={faXmark}
              className='text-gray-500 cursor-pointer'
              size='lg'
              onClick={onClose}
            />
          </div>
        </div>
        <div className='h-full scrollbar overflow-y-auto' id='modal-scroll'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SidebarModal;
