import { CheckCircleOutline } from '@material-ui/icons';
import Pill, { PillVariantType } from '../../Pill';
import { EnumMap } from '../../../types';
import { InstantPaymentResponseStatusEnum } from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';

export interface InstantPaymentStatusCellProps {
  status: InstantPaymentResponseStatusEnum;
}

export const instantPaymentStatusToVariant: EnumMap<
  InstantPaymentResponseStatusEnum,
  PillVariantType
> = {
  [InstantPaymentResponseStatusEnum.Repaid]: 'success',
  [InstantPaymentResponseStatusEnum.PartiallyRepaid]: 'success',
  [InstantPaymentResponseStatusEnum.InProgress]: 'warning',
  [InstantPaymentResponseStatusEnum.Disbursed]: 'danger',
};

export const instantPaymentStatusToIcon: EnumMap<
  InstantPaymentResponseStatusEnum,
  React.ReactElement
> = {
  [InstantPaymentResponseStatusEnum.Repaid]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [InstantPaymentResponseStatusEnum.PartiallyRepaid]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [InstantPaymentResponseStatusEnum.InProgress]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [InstantPaymentResponseStatusEnum.Disbursed]: (
    <CheckCircleOutline fontSize='small' />
  ),
};

const InstantPaymentStatusCell: React.FC<InstantPaymentStatusCellProps> = ({
  status,
}) => {
  const variant: PillVariantType = instantPaymentStatusToVariant[status];
  const icon: React.ReactElement = instantPaymentStatusToIcon[status];

  return (
    <Pill text={capitalizeEnum(status || '')} variant={variant!} icon={icon!} />
  );
};

export default InstantPaymentStatusCell;
