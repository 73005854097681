import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { WealthPlanEventEnum } from '../components/WeathManagement/WealthManagementTypes';
import { CalendarControllerApi, CalendarEventResponse } from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { setWealthPlanEvents } from '../slices/WealthManagementSlice';
import { EventTabEnum } from '../utils/EventHelper';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';

const SALES_ACCELERATOR_URL =
  'https://www.real.academy/course/sales-accelerator';
const ATTRACTION_ACCELERATOR_URL =
  'https://www.real.academy/course/attraction-accelerator';

const useWealthPlanEvents = (agentId?: string) => {
  const dispatch = useDispatch();

  const fetchApi = useCallback(
    async (
      tab: EventTabEnum,
      agentId: string,
    ): Promise<CalendarEventResponse[]> => {
      try {
        const { data } = await new CalendarControllerApi(
          getYentaConfiguration(),
        ).getEventsForAgent(tab, agentId);
        return data;
      } catch (e) {
        ErrorService.notifyIgnoreAuthErrors('Unable to fetch events', e, {
          event: { tab, agentId },
        });
        return [];
      }
    },
    [],
  );

  const filterEventsByLocation = (
    events: CalendarEventResponse[],
    locationUrl: string,
  ) => events.filter((event) => [locationUrl].includes(event.location || ''));

  useEffect(() => {
    const fetchAndSetEvents = async (
      eventType: WealthPlanEventEnum,
    ): Promise<void | null> => {
      const locationUrl =
        eventType === WealthPlanEventEnum.ATTRACTION_ACCELERATOR
          ? ATTRACTION_ACCELERATOR_URL
          : SALES_ACCELERATOR_URL;

      if (!agentId) {
        return null;
      }

      const upcomingEvents = await fetchApi(EventTabEnum.UPCOMING, agentId);
      const filterUpcomingEvents = filterEventsByLocation(
        upcomingEvents,
        locationUrl,
      );

      if (filterUpcomingEvents.length > 0) {
        dispatch(
          setWealthPlanEvents({ eventType, data: filterUpcomingEvents }),
        );
      } else {
        const values = await Promise.all([
          fetchApi(EventTabEnum.YES, agentId),
          fetchApi(EventTabEnum.NO, agentId),
          fetchApi(EventTabEnum.MAYBE, agentId),
        ]);

        const filteredEvents = values.flatMap((event) =>
          filterEventsByLocation(event, locationUrl),
        );
        if (filteredEvents.length > 0) {
          dispatch(setWealthPlanEvents({ eventType, data: filteredEvents }));
        } else {
          const pastEvents = await fetchApi(EventTabEnum.PAST, agentId);
          const pastEvent = pastEvents.find((event) =>
            [locationUrl].includes(event.location || ''),
          );
          if (!!pastEvent) {
            dispatch(setWealthPlanEvents({ eventType, data: [pastEvent] }));
          }
        }
      }
    };

    [
      WealthPlanEventEnum.SALES_ACCELERATOR,
      WealthPlanEventEnum.ATTRACTION_ACCELERATOR,
    ].forEach((eventType) => {
      fetchAndSetEvents(eventType);
    });
  }, [agentId, dispatch, fetchApi]);
};

export default useWealthPlanEvents;
