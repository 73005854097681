import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IdentityRoleResponse } from '../../openapi/keymaker';
import { grantRole } from '../../slices/RoleSlice';
import { RootState } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import ZenGrantingRoleToggleInput from './SuperAdminGrantingRoleToggleInput';

interface Props {
  onClose(): void;
  keyMakerId: string;
  agentActiveGrantorRoles: IdentityRoleResponse[];
  setRevokeRole(role: string): void;
}

const SuperAdminGrantRolesSideBarModal: React.FC<Props> = ({
  onClose,
  keyMakerId,
  agentActiveGrantorRoles,
  setRevokeRole,
}) => {
  const dispatch = useDispatch();
  const [confirmRoleLoading, setConfirmRoleLoading] = useState<boolean>(false);
  const [grantingRole, setGrantingRole] = useState<string>();
  const {
    roles: { grantorRolesResponse },
    agentDetail: {
      detailResponse: { data: detail },
    },
  } = useSelector((state: RootState) => state);

  return (
    <ZenSidebarModal onClose={onClose} title='Assign a Grantor Role' isOpen>
      {!!grantorRolesResponse && (
        <div className='px-5 pb-10'>
          <ZenGrantingRoleToggleInput
            agentActiveRoles={agentActiveGrantorRoles}
            grantingRoles={grantorRolesResponse.data || []}
            onToggle={(toggled, value) => {
              if (toggled) {
                setGrantingRole(value);
              } else {
                setRevokeRole(value);
              }
            }}
          />
        </div>
      )}
      {!!grantingRole && (
        <ZenConfirmationModal
          isOpen
          variant='warning'
          title={`Grant ${capitalizeEnum(grantingRole)}`}
          subtitle={`You are about to grant ${capitalizeEnum(
            grantingRole,
          )} role to ${detail?.fullName}.`}
          onClose={() => setGrantingRole(undefined)}
          confirmButtonText='Grant'
          cancelButtonText='Cancel'
          onConfirm={async () => {
            setConfirmRoleLoading(true);
            await dispatch(grantRole(keyMakerId, grantingRole));
            setConfirmRoleLoading(false);
            setGrantingRole(undefined);
          }}
          isSubmitting={confirmRoleLoading}
        />
      )}
    </ZenSidebarModal>
  );
};

export default SuperAdminGrantRolesSideBarModal;
