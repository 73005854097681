import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import ZenButton from '../../ZenButton';

export interface TransactionReviewItems {
  label: string;
  value?: any;
  display?: boolean;
}

interface ZenDisplayReviewCardProps {
  counter: React.ReactElement;
  items?: TransactionReviewItems[];
  onEdit(): void;
}

const ZenDisplayReviewCard: React.FC<ZenDisplayReviewCardProps> = ({
  counter,
  items,
  onEdit,
}) => {
  return (
    <div className='w-full flex flex-row items-center py-2.5 border-b border-zen-dark-4'>
      <div className='mr-6'>
        <div className='w-7 h-7 rounded-full bg-grey-200 text-zen-dark-9 font-zen-body font-semibold flex justify-center items-center'>
          {counter}
        </div>
      </div>
      <div className='grid grid-cols-2 flex-grow'>
        {items?.map((item, index) => {
          const { display = true, label, value } = item;
          if (display) {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`${value}-${index}`}
                className='font-zen-body text-sm font-semibold leading-5 p-1'
              >
                <p className='text-zen-dark-6'>{label}</p>
                <p className='text-zen-dark-9 break-words'>{value ?? 'N/A'}</p>
              </div>
            );
          }
          return null;
        })}
      </div>
      <div className='flex-grow flex justify-end items-center'>
        <ZenButton
          label='Edit'
          variant='primary-link'
          LeftIconComponent={
            <FontAwesomeIcon
              icon={faPencil}
              className='text-primary-blue mr-1'
            />
          }
          onClick={onEdit}
        />
      </div>
    </div>
  );
};

export default ZenDisplayReviewCard;
