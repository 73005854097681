import { values } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NationalIdentificationValueTypeEnum } from '../../openapi/arrakis';
import {
  AddressCountryEnum,
  AddressRequestCountryEnum,
  AddressStateOrProvinceEnum,
  AdministrativeAreaRequestCountryEnum,
  CreateTaxFormTypeEnum,
  CreateTaxFormW9,
  CreateTaxFormW9TaxClassificationEnum,
  UpdateTaxFormTypeEnum,
  UpdateTaxFormW9,
  UpdateTaxFormW9TaxClassificationEnum,
} from '../../openapi/yenta';
import {
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps,
} from '../../routes/MSDynamicsOnboardingRoute';
import {
  createTaxForm,
  updateTaxFormInfo,
} from '../../slices/TaxInformationSlice';
import {
  AgentAddressTypeEnum,
  AppDispatch,
  RootState,
  YesNoType,
} from '../../types';
import {
  getIdValidations,
  getMaskTypeForIdentificationType,
} from '../../utils/AgentHelper';
import { capitalizeEnum } from '../../utils/StringUtils';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import ZenControlledCheckboxInput from '../Zen/Input/ZenControlledCheckboxInput';
import ZenControlledDatePickerInput from '../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledRadioInput from '../Zen/Input/ZenControlledRadioInput';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledSignatureInput from '../Zen/Input/ZenControlledSignatureInput';
import ZenControlledStateOrProvince from '../Zen/Input/ZenControlledStateOrProvince';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenButton from '../Zen/ZenButton';
import { getPostalCodeValidation } from '../../utils/Validations';
import ZenControlledMaskTextInput from '../Zen/Input/ZenControlledMaskTextInput';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import WithMsDynamicsProgress from './WithMsDynamicOnboardingProgress';

const MsDynamicOnboardingTaxFormW9: StepByStepComponent<
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps
> = ({ form: { control, watch, trigger, setValue }, onPrevious, onNext }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [usTaxIdType, taxFormID] = watch(['w9.usTaxIdType', 'w9.id']);
  const [loading, setLoading] = useState(false);

  const { userDetail } = useSelector((state: RootState) => state.auth);

  const handleNext = async () => {
    const isValid = await trigger();
    if (!isValid) {
      dispatch(showErrorToast('There are fields which need to be filled out'));
      return;
    }
    setLoading(true);
    const formValues = watch().w9;
    let isSuccess: boolean;
    if (formValues.id) {
      const req: UpdateTaxFormW9 = {
        type: UpdateTaxFormTypeEnum.W9,
        name: formValues.taxPayer,
        nationalIds: [
          { type: formValues.usTaxIdType, id: formValues.usTaxIdNumber },
        ],
        electronicDelivery: !!formValues.electronicDelivery?.[0],
        disregardedEntityName: formValues.disregardedEntityName,
        residenceAddress: {
          type: AgentAddressTypeEnum.HOME,
          city: formValues.city,
          country: AddressCountryEnum.UnitedStates,
          streetAddress1: formValues.addressLine,
          stateOrProvince: (formValues.state as unknown) as AddressStateOrProvinceEnum,
          zipOrPostalCode: formValues.zipCode,
        },
        taxClassification: formValues.taxClassification
          .value as UpdateTaxFormW9TaxClassificationEnum,
      };
      isSuccess = await dispatch(
        updateTaxFormInfo(userDetail?.id!, formValues.id, req),
      );
    } else {
      const req: CreateTaxFormW9 = {
        type: CreateTaxFormTypeEnum.W9,
        name: formValues.taxPayer,
        nationalIds: [
          { type: formValues.usTaxIdType, id: formValues.usTaxIdNumber },
        ],
        electronicDelivery: !!formValues.electronicDelivery?.[0],
        disregardedEntityName: formValues.disregardedEntityName,
        residenceAddress: {
          type: AgentAddressTypeEnum.HOME,
          city: formValues.city,
          country: AddressCountryEnum.UnitedStates,
          streetAddress1: formValues.addressLine,
          stateOrProvince: (formValues.state as unknown) as AddressStateOrProvinceEnum,
          zipOrPostalCode: formValues.zipCode,
        },
        taxClassification: formValues.taxClassification
          .value as CreateTaxFormW9TaxClassificationEnum,
        signedAt: DateTime.fromISO(formValues.signDate).toMillis(),
        typedSignature: formValues.signature,
      };
      isSuccess = await dispatch(createTaxForm(userDetail?.id!, req));
    }
    if (isSuccess) {
      onNext();
    }
    setLoading(false);
  };

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow text-zen-dark-9 space-y-14'>
        <div className='space-y-7'>
          <h1 className='text-xl font-zen-title'>Substitute Form W-9</h1>
          <div className='space-y-8'>
            <div className='space-y-1'>
              <ZenControlledTextInput<
                MSDynamicOnboardingFormData,
                'w9.taxPayer'
              >
                control={control}
                label='Taxpayer or business name'
                placeholder='Taxpayer or business name'
                name='w9.taxPayer'
                rules={{ required: 'Name is required' }}
                shouldUnregister={false}
                isRequired
              />
              <p className='font-zen-body text-sm text-zen-dark-6'>
                Enter full name exactly as shown on your income tax return
              </p>
            </div>
            <div className='space-y-1'>
              <ZenControlledTextInput<
                MSDynamicOnboardingFormData,
                'w9.disregardedEntityName'
              >
                control={control}
                label='Disregarded entity name'
                placeholder='Disregarded entity name'
                name='w9.disregardedEntityName'
                shouldUnregister={false}
              />
              <p className='font-zen-body text-sm text-zen-dark-6'>
                If different from full name
              </p>
            </div>
            <div className='flex items-center space-x-5'>
              <div className='flex-1'>
                <ZenControlledMaskTextInput<
                  MSDynamicOnboardingFormData,
                  'w9.usTaxIdNumber'
                >
                  control={control}
                  label='US tax ID number'
                  placeholder='US tax ID number'
                  name='w9.usTaxIdNumber'
                  rules={{
                    required: 'Please enter tax ID number',
                    ...getIdValidations(usTaxIdType),
                  }}
                  maskType={getMaskTypeForIdentificationType(usTaxIdType)}
                  shouldUnregister={false}
                  isRequired
                  setValue={setValue}
                />
                <p className='font-zen-body text-sm text-zen-dark-6'>
                  Number and type must correspond with name provided
                </p>
              </div>
              <div className=''>
                <ZenControlledRadioInput<
                  MSDynamicOnboardingFormData,
                  'w9.usTaxIdType'
                >
                  name='w9.usTaxIdType'
                  control={control}
                  shouldUnregister={false}
                  options={[
                    {
                      label: 'SSN',
                      value: NationalIdentificationValueTypeEnum.Ssn,
                    },
                    {
                      label: 'EIN',
                      value: NationalIdentificationValueTypeEnum.Ein,
                    },
                  ]}
                  rules={{ required: 'Required' }}
                  isRequired
                />
              </div>
            </div>
            <div className=''>
              <ZenControlledSelectInput<
                MSDynamicOnboardingFormData,
                'w9.taxClassification'
              >
                control={control}
                label='Tax Classification'
                name='w9.taxClassification'
                shouldUnregister={false}
                options={values(CreateTaxFormW9TaxClassificationEnum).map(
                  (item) => ({
                    label: capitalizeEnum(item),
                    value: item,
                  }),
                )}
                rules={{ required: 'Please select a tax classification' }}
                isRequired
              />
            </div>
            <div className='space-y-1'>
              <ZenControlledTextInput<
                MSDynamicOnboardingFormData,
                'w9.addressLine'
              >
                control={control}
                label='Address'
                placeholder='Address'
                name='w9.addressLine'
                rules={{ required: 'Please enter an address' }}
                shouldUnregister={false}
                isRequired
              />
              <p className='font-zen-body text-sm text-zen-dark-6'>
                Include apartment or suite no. if applicable
              </p>
            </div>
            <div className='flex flex-col md:flex-row md:items-center space-y-8 md:space-y-0 md:space-x-2.5'>
              <div className='flex-1'>
                <ZenControlledTextInput<MSDynamicOnboardingFormData, 'w9.city'>
                  label='City'
                  placeholder='City'
                  control={control}
                  name='w9.city'
                  shouldUnregister={false}
                  rules={{ required: 'Please enter a city' }}
                  isRequired
                />
              </div>
              <div className='flex-1'>
                <ZenControlledStateOrProvince<
                  MSDynamicOnboardingFormData,
                  'w9.state'
                >
                  control={control}
                  name='w9.state'
                  setValue={setValue}
                  selectedCountry={
                    AdministrativeAreaRequestCountryEnum.UnitedStates
                  }
                  rules={{ required: 'Please select a state.' }}
                  isRequired
                  shouldUnregister={false}
                />
              </div>
              <div className='flex-1'>
                <ZenControlledTextInput<
                  MSDynamicOnboardingFormData,
                  'w9.zipCode'
                >
                  control={control}
                  label='Zipcode'
                  placeholder='Zipcode'
                  name='w9.zipCode'
                  rules={{
                    required: 'Please enter a zip code',
                    ...getPostalCodeValidation(
                      AddressRequestCountryEnum.UnitedStates,
                    ),
                  }}
                  shouldUnregister={false}
                  isRequired
                  type='number'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='font-zen-body text-zen-dark-9 space-y-5'>
          <div className='font-bold'>
            Under penalties of perjury, I certify that:
          </div>
          <ol className='pl-10 list-decimal leading-snug space-y-3'>
            <li className=''>
              The number shown on this form is my correct taxpayer
              identification number (or I am waiting for a number to be issued
              to me); and
            </li>
            <li className=''>
              I am not subject to backup withholding because: (a) I am exempt
              from backup withholding, or (b) I have not been notified by the
              Internal Revenue Service (IRS) that I am subject to backup
              withholding as a result of a failure to report all interest or
              dividends, or (c) the IRS has notified me that I am no longer
              subject to backup withholding; and
            </li>
            <li className=''>I am a US citizen or other US person; and</li>
            <li className=''>
              The FATCA code(s) entered on this form (if any) indicating that I
              am exempt from FATCA reporting is correct.
            </li>
          </ol>
          <div className='leading-snug'>
            The Internal Revenue Service does not require your consent to any
            provision of this document other than the certifications required to
            avoid backup withholding.
          </div>
        </div>
        <div className='space-y-12'>
          <div className='space-y-7'>
            <div className=''>
              <div className='space-y-1'>
                <ZenControlledSignatureInput<
                  MSDynamicOnboardingFormData,
                  'w9.signature'
                >
                  control={control}
                  label='Your Signature'
                  placeholder='Type Full Name'
                  name='w9.signature'
                  rules={{ required: 'Please enter a signature' }}
                  shouldUnregister={false}
                  isRequired
                  readOnly={!!taxFormID}
                />
                <p className='font-zen-body text-sm text-zen-dark-6'>
                  Typing your name acts as your signature and certifies that you
                  have capacity to sign for the person/entity identified on this
                  form
                </p>
              </div>
            </div>
            <div className=''>
              <div className='space-y-1'>
                <ZenControlledDatePickerInput<
                  MSDynamicOnboardingFormData,
                  'w9.signDate'
                >
                  control={control}
                  name='w9.signDate'
                  label='Sign Date'
                  placeholder='mm/dd/yyyy'
                  isRequired
                  shouldUnregister={false}
                  rules={{ required: 'Please pick a sign date' }}
                  readOnly={!!taxFormID}
                />
                <p className='font-zen-body text-sm text-zen-dark-6'>
                  Use mm-dd-yyyy format, e.g., 06-30-2024
                </p>
              </div>
            </div>
          </div>
          <div className='space-y-2.5'>
            <div className='font-zen-body text-zen-dark-9 font-medium'>
              Electronic delivery
            </div>
            <div className='space-y-3'>
              <ZenControlledCheckboxInput<
                MSDynamicOnboardingFormData,
                'w9.electronicDelivery'
              >
                control={control}
                name='w9.electronicDelivery'
                shouldUnregister={false}
                options={[
                  {
                    label: 'Issue me electronic 1099 forms only',
                    value: YesNoType.YES,
                  },
                ]}
                reverse
              />
              <p className='leading-snug font-zen-body text-sm text-zen-dark-6'>
                Uncheck to receive a mailed copy in addition to an electronic
                copy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-sm'>
          <div className='flex flex-row items-center py-8 shadow-top-sm justify-center space-x-5'>
            <div className='w-28'>
              <ZenButton
                isFullWidth
                variant='primary-outline'
                type='button'
                label='Previous'
                onClick={onPrevious}
              />
            </div>
            <div className='w-56'>
              <ZenButton
                isFullWidth
                type='submit'
                variant='primary'
                label='Next'
                isSubmitting={loading}
                isDisabled={loading}
                onClick={handleNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithMsDynamicsProgress(MsDynamicOnboardingTaxFormW9);
