import SlimDocumentMastHead from './SlimDocumentMastHead';
import SlimDocumentTitle from './SlimDocumentTitle';

interface SlimHeaderProps {
  title: string;
  companyName: string;
  companyAddress: string;
  revision: number;
  createdAt: number;
  isPdf?: boolean;
}

const SlimDocumentHeader: React.FC<SlimHeaderProps> = ({
  title,
  companyName,
  companyAddress,
  createdAt,
  revision,
  isPdf = false,
}) => {
  return (
    <div className='grid grid-row grid-cols-2 border-b'>
      <SlimDocumentMastHead
        companyName={companyName}
        companyAddress={companyAddress}
      />
      <SlimDocumentTitle
        title={title}
        revision={revision}
        createdAt={createdAt}
        isPdf={isPdf}
      />
    </div>
  );
};

export default SlimDocumentHeader;
