import { useForm, useWatch } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import ResourceContainer from '../components/ResourceContainer';
import ZenControlledAsyncSelectInput from '../components/Zen/Input/ZenControlledAsyncSelectInput';
import ZenSidebarModalForm from '../components/Zen/ZenSidebarModalForm';
import { UserResponseAgentStatusEnum } from '../openapi/yenta';
import {
  useGoogleSSOByKeymakerId,
  useToggleGoogleSSO,
} from '../query/login/useGoogleSSO';
import ErrorService from '../services/ErrorService';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { AsyncSelectOption, ISelectOption } from '../types';
import { searchForAgents } from '../utils/TableUtils';

interface ZenEnableOrDisableGooglessoFormSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  agentId: ISelectOption;
}

const ZenEnableOrDisableGooglessoFormSidebarModal: React.FC<ZenEnableOrDisableGooglessoFormSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<FormData>({ mode: 'onChange' });
  const agent = useWatch({ control, name: 'agentId' });
  const agentId = agent?.value;

  const { data, isFetching, refetch } = useGoogleSSOByKeymakerId({
    fnArgs: [agentId],
  });

  const { mutate: toggleGoogleSSO, isLoading } = useToggleGoogleSSO(agentId);

  const isForceGoogleSSO = data?.forceGoogleSso;

  const handleToggleGoogleSSO = async () => {
    toggleGoogleSSO(
      {
        keymakerId: agentId,
        enable: !!isForceGoogleSSO,
      },
      {
        onSuccess() {
          refetch();
        },
      },
    );
  };

  return (
    <ZenSidebarModalForm
      title='Enable/Disable Force Google SSO'
      isOpen={isOpen}
      onClose={onClose}
      actionTitle={isForceGoogleSSO ? 'Disable' : 'Enable'}
      onSubmit={handleSubmit(handleToggleGoogleSSO)}
      isSubmitting={isSubmitting || isLoading}
      isDisabled={
        !agentId || isSubmitting || isFetching || isLoading || !isValid
      }
    >
      <div>
        <div className='mb-5'>
          <ZenControlledAsyncSelectInput<FormData, 'agentId'>
            control={control}
            name='agentId'
            placeholder='Search Agent'
            label='Search Agent'
            fetchData={async (search, page) => {
              try {
                const { data } = await searchForAgents({
                  page,
                  search,
                  filterBy: {
                    agentStatus: [UserResponseAgentStatusEnum.Active],
                  },
                });

                const options: AsyncSelectOption[] = data.map((resp) => ({
                  value: `${resp.keyMakerId}`,
                  label: `${resp.firstName} ${resp.lastName} - ${resp.emailAddress}`,
                }));

                return options;
              } catch (e) {
                ErrorService.notify(
                  'Unable to search request registered agents in add team member form',
                  e,
                  {
                    search: {
                      term: search,
                      page,
                    },
                  },
                );
                dispatch(
                  showErrorToast(
                    'An unexpected error occurred.',
                    'We were unable to search for an registered agent. Please try again in a few moments or contact support.',
                  ),
                );
              }

              return [];
            }}
            rules={{
              required: 'Please select agent',
            }}
          />
          <ResourceContainer
            isEmpty={false}
            loading={isFetching}
            resourceName='Google SSO Status'
          >
            {!!agentId && !isFetching && (
              <p className='font-primary-regular text-sm text-zen-dark-9 my-2'>
                {`Google SSO is ${
                  isForceGoogleSSO ? 'enabled' : 'disabled'
                } for this account`}
              </p>
            )}
          </ResourceContainer>
        </div>
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenEnableOrDisableGooglessoFormSidebarModal;
