import {
  ConveyanceResponse,
  ConveyanceResponseStateEnum,
} from '../openapi/arrakis';

const ADDITIONAL_NOTE_PLACEHOLDER = '%%ADDITIONAL_NOTE%%';

export const getConveyanceDocumentHtml = (
  conveyanceDocument: ConveyanceResponse | undefined,
): string => {
  const html: string = conveyanceDocument?.content || '';
  const additionalNote =
    conveyanceDocument?.state === ConveyanceResponseStateEnum.Draft
      ? 'YOUR ADDITIONAL NOTE WILL BE INSERTED HERE'
      : conveyanceDocument?.additionalNote || '';

  return html.replace(ADDITIONAL_NOTE_PLACEHOLDER, additionalNote);
};
