export const MAX_FILE_SIZE = 10485760;
export const MAX_FILE_SIZE_30MB = 31457280; // 30MB
export const MAX_DOC_SIZE = 31457280; // 30MB
export const MAX_DOC_SIZE_100MB = 104857600; //100MB
export const ALLOWED_FILE_TYPES = ['.xlsx', '.xls', '.doc', '.docx', '.pdf'];
export const ALLOWED_USER_AVATAR_FILE_TYPES = [
  'png',
  'jpg',
  'jpeg',
  'gif',
  'bmp',
];
export const CHECKLIST_ALLOWED_FILE_TYPES = [
  '.xlsx',
  '.xls',
  '.doc',
  '.docx',
  '.pdf',
  '.png',
  '.jpeg',
  '.jpg',
  '.heic',
];
export const REFUNDS_ALLOWED_FILE_TYPES = [
  '.xlsx',
  '.xls',
  '.doc',
  '.docx',
  '.pdf',
  '.png',
  '.jpeg',
  '.jpg',
];
export const IMAGE_RESIZING_ATTRIBUTES = {
  width: 1000,
  height: 1000,
  format: 'png',
  quality: 100,
  outputType: 'file',
};
