import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { YesNoType } from '../../types';
import { cn } from '../../utils/classUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import GeminiPill from './GeminiPill';

type PillVariant =
  | 'primary'
  | 'danger'
  | 'primary-no-background'
  | 'danger-no-background';

interface GeminiYesOrNoPillProps {
  value: YesNoType;
  noBackground?: boolean;
  className?: string;
}

const variantMap: Record<PillVariant, string> = {
  primary: 'bg-midnight-green-300 text-midnight-green-900',
  danger: 'bg-red-100 text-red-600',
  'primary-no-background': 'text-green-400',
  'danger-no-background': 'text-red-500',
};

const GeminiYesOrNoPill: React.FC<GeminiYesOrNoPillProps> = ({
  value,
  noBackground = false,
  className,
}) => {
  const isYesType = value === YesNoType.YES;

  const variant: PillVariant = isYesType
    ? noBackground
      ? 'primary-no-background'
      : 'primary'
    : noBackground
    ? 'danger-no-background'
    : 'danger';

  return (
    <GeminiPill
      label={capitalizeEnum(value)}
      icon={isYesType ? faCheck : faXmark}
      containerClassNames={cn(variantMap[variant], className)}
    />
  );
};

export default GeminiYesOrNoPill;
