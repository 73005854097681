import React from 'react';
import { useSelector } from 'react-redux';
import ZenCommentSection from '../components/transactions/Comments/ZenCommentSection';
import ZenRoute from '../components/Zen/ZenRoute';
import { CommentOnSubmitParams } from '../components/ZenCommentWidget/ZenCommentWidget';
import { SSE_URL } from '../constants/CommonConstants';
import useTransactionMentions from '../hooks/useTransactionMentions';
import {
  CommentControllerApi,
  CommentDto,
  PostCommentBodyReaderRoleEnum,
  QueryCommentsRs,
  RezenObjectTypeEnum,
  UpdateCommentBodyReaderRoleEnum,
} from '../openapi/yada';
import { RootState } from '../types';
import { getYadaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { getFileNameFromUrl } from '../utils/StringUtils';
import { getPageTitleForTransaction } from '../utils/TransactionUtils';
import { addAutoTagMentionsInComments } from '../utils/CommentUtils';

const LOAD_MORE_COMMENT_COUNT = 10;

interface TransactionCommentRouteProps {}

const TransactionCommentRoute: React.FC<TransactionCommentRouteProps> = () => {
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
    },
  } = useSelector((state: RootState) => state);
  const { getMentions, callerGroupId } = useTransactionMentions();

  const onDeleteComment = async (comment: CommentDto) => {
    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).deleteComment(
      comment?.container?.type!,
      comment?.container?.id!,
      comment?.id! as string,
    );

    return data;
  };

  const fetchComments = async (
    pageStart: string | undefined,
  ): Promise<QueryCommentsRs> => {
    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).getCommentsByContainer(
      RezenObjectTypeEnum.Transaction,
      transactionDetail?.id!,
      LOAD_MORE_COMMENT_COUNT,
      pageStart,
    );

    return data;
  };

  const handleSubmit = async (values: CommentOnSubmitParams) => {
    if (values.mediaType) {
      const _file = await fetch(values.filePath!);
      const fileBlob = await _file.blob();
      const fileName = getFileNameFromUrl(values.filePath!);
      const file = new File([fileBlob], fileName! + '.m4a', {
        type: values.fileType,
      });

      await new CommentControllerApi(getYadaConfiguration()).uploadMedia(
        RezenObjectTypeEnum.Transaction, // containerType
        transactionDetail?.id!, // containerId
        'USER', // authorType
        values.mediaType, // mediaType
        // @ts-expect-error
        JSON.stringify(values.comment.blocks!), // richBlocks
        file, // file
        undefined, // refersToType
        undefined, // refersToId
        undefined, // refersToName
        callerGroupId, // callerGroupId
        undefined, // triggerActivity
        undefined, // readerRole
        undefined, // fileDescription
        undefined, // axios config
      );

      return;
    }

    const updatedRichContent = addAutoTagMentionsInComments(
      true,
      transactionDetail?.otherParticipants || [],
      values?.comment,
    );

    await new CommentControllerApi(getYadaConfiguration()).postComment(
      RezenObjectTypeEnum.Transaction,
      transactionDetail?.id!,
      'USER',
      {
        richContent: updatedRichContent,
        readerRole: values.role as PostCommentBodyReaderRoleEnum,
      },
      undefined,
      undefined,
      undefined,
      callerGroupId,
    );
  };

  const onEditComment = async (
    values: CommentOnSubmitParams,
    commentId: string,
  ): Promise<CommentDto> => {
    const updatedRichContent = addAutoTagMentionsInComments(
      true,
      transactionDetail?.otherParticipants || [],
      values?.comment,
    );

    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).updateComment(
      RezenObjectTypeEnum.Transaction,
      transactionDetail?.id!,
      commentId,
      {
        richContent: updatedRichContent,
        readerRole: values.role as UpdateCommentBodyReaderRoleEnum,
      },
    );

    return data;
  };

  return (
    <ZenRoute
      title={`${getPageTitleForTransaction(transactionDetail)} Comments - ${
        transactionDetail?.address?.oneLine
      }`}
    >
      <div className='w-full mx-auto px-4 md:pt-6 relative'>
        <ZenCommentSection
          fetchComments={fetchComments}
          onDelete={onDeleteComment}
          onEdit={onEditComment}
          onSubmit={handleSubmit}
          getMentions={getMentions}
          htmlElementScrollableParent={document?.getElementById('main-page')!}
          containerType={RezenObjectTypeEnum.Transaction}
          containerId={transactionDetail?.id!}
          sseURL={`${SSE_URL}?containerType=${RezenObjectTypeEnum.Transaction}&containerId=${transactionDetail?.id}`}
        />
      </div>
    </ZenRoute>
  );
};

export default TransactionCommentRoute;
