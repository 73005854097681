import React, { useEffect, useMemo } from 'react';
import { Remarkable } from 'remarkable';
import { linkify } from 'remarkable/linkify';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';

interface MarkdownProps {
  content: string;
}

var md = new Remarkable().use(linkify);

md.set({
  html: true,
  breaks: true,
  typographer: true,
  linkTarget: '_blank',
});

const Markdown: React.FC<MarkdownProps> = ({ content }) => {
  const parsedMarkdown = useMemo(() => md.render(content), [content]);

  useEffect(() => {
    const links = document.querySelectorAll('#leo-markdown a');
    links.forEach((link) => {
      link.addEventListener('click', () => {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.LEO_RESPONSE_LINK_CLICK,
          {
            link: link.getAttribute('href'),
          },
        );
      });
    });
  }, [content]);

  return (
    <div
      id='leo-markdown'
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: parsedMarkdown }}
    />
  );
};

export default Markdown;
