import {
  Controller,
  FieldValues,
  FieldPath,
  UseControllerProps,
} from 'react-hook-form-v7';
import { cn } from '../utils/classUtils';
import { FormFieldTooltipIndexProps, ISelectOption } from '../types';
import FormErrorMessage from './FormErrorMessage';

interface ControlledSelectV7InputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  placeholder?: string;
  options: Array<ISelectOption>;
  readOnly?: boolean;
  isRequired?: boolean;
}

const ControlledSelectInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  placeholder,
  options,
  readOnly = false,
  shouldUnregister = true,
  isRequired = false,
  ...rest
}: ControlledSelectV7InputProps<TFieldValues, TName>) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              {label}
              {isRequired && <span className='text-error'>*</span>}
            </label>
          )}
          <select
            id={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            placeholder={placeholder}
            disabled={readOnly}
            className={cn(
              'appearance-none p-1.5 border-gray-200 rounded bg-white focus:outline-none focus:ring-0 w-full',
              {
                'bg-gray-50 pointer-events-none': readOnly,
              },
            )}
          >
            {options.map(({ value, label, disabled }) => (
              <option key={value} value={value} disabled={disabled}>
                {label}
              </option>
            ))}
          </select>
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledSelectInputV7;
