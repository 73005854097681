import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import ZenDepositCard from '../components/finance/ZenDepositCard';
import ResourceContainer from '../components/ResourceContainer';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenConfirmationModal from '../components/Zen/Modal/ZenConfirmationModal';
import ZenButton from '../components/Zen/ZenButton';
import ZenSidebarModal from '../components/Zen/ZenSidebarModal';
import {
  EscrowDepositResponse,
  EscrowResponse,
  EscrowResponseEscrowTypeEnum,
  TransactionControllerApi,
  TransactionResponse,
} from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { showErrorToastForErrorCode } from '../slices/ToastNotificationSlice';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';

interface ZenDeleteEscrowDepositOrInstallmentSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  transactionId: string;
}

export enum EscrowTypeEnum {
  Deposit = 'DEPOSIT',
  Installment = 'INSTALLMENT',
}

const ZenDeleteEscrowDepositOrInstallmentSidebarModal: React.FC<ZenDeleteEscrowDepositOrInstallmentSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [deleteDepositModal, setDeleteDepositModal] = useState<boolean>(false);
  const [isDeletingDeposit, setIsDeletingDeposit] = useState<boolean>(false);
  const [escrowType, setEscrowType] = useState<EscrowTypeEnum>(
    EscrowTypeEnum.Deposit,
  );
  const [
    depositInformation,
    setDepositInformation,
  ] = useState<EscrowResponse>();
  const [
    depositInstallmentInformation,
    setDepositInstallmentInformation,
  ] = useState<EscrowDepositResponse>();
  const [transaction, setTransaction] = useState<
    TransactionResponse | undefined
  >(undefined);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const trustDeposits = transaction?.escrows?.filter(
    (escrow) => escrow.escrowType === EscrowResponseEscrowTypeEnum.TrustDeposit,
  );
  const commissionDeposits = transaction?.escrows?.filter(
    (escrow) => escrow.escrowType === EscrowResponseEscrowTypeEnum.Commission,
  );

  const fetchTransaction = async (formData: FormData) => {
    try {
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).getTransaction(formData.transactionId);

      setTransaction(data);
    } catch (e) {
      ErrorService.notify('Unable to fetch the transaction', e, {
        agent: { transactionId: formData.transactionId },
      });
    }
  };

  const handleDeleteDeposit = async (escrowId: string) => {
    setIsDeletingDeposit(true);

    try {
      if (escrowType === EscrowTypeEnum.Deposit) {
        await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).deleteEscrow(escrowId);
      } else {
        await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).deleteEscrowDeposit(escrowId);
      }

      setDeleteDepositModal(false);
      fetchTransaction({ transactionId: getValues('transactionId') });
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to delete the escrow deposit', e, {
        escrow: { id: escrowId },
      });
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to delete the escrow deposit.',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setIsDeletingDeposit(false);
    }
  };

  return (
    <ZenSidebarModal
      title='Delete Escrow Deposit / Installment'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form className='flex flex-col justify-between min-h-full'>
        <div className='p-4'>
          <div className='flex flex-row items-end space-x-4'>
            <ZenControlledTextInput<FormData, 'transactionId'>
              label='Transaction Id'
              name='transactionId'
              control={control}
              placeholder='Enter transaction id.'
              rules={{ required: 'Transaction id is required' }}
              endAdornment={
                <ZenButton
                  LeftIconComponent={
                    <FontAwesomeIcon
                      icon={faSearch}
                      title='search'
                      className='ml-0.5 mr-1.5'
                    />
                  }
                  onClick={handleSubmit(fetchTransaction)}
                  variant='primary'
                  label='Search'
                />
              }
              isRequired
            />
          </div>
          <p className='font-zen-body font-medium text-lg text-black pt-5 pb-3'>
            Trust Deposits
          </p>
          <ResourceContainer
            loading={isSubmitting}
            resourceName='Trust Deposit'
            isEmpty={!trustDeposits?.length}
          >
            {trustDeposits?.map((depositPayment) => {
              return (
                <div className='mb-5' key={depositPayment.id}>
                  <ZenDepositCard
                    depositPayment={depositPayment}
                    onDelete={() => {
                      setDepositInformation(depositPayment);
                      setDeleteDepositModal(true);
                      setEscrowType(EscrowTypeEnum.Deposit);
                    }}
                    deleteInstallment={setDepositInstallmentInformation}
                    setEscrowType={setEscrowType}
                    setDeleteDepositModal={setDeleteDepositModal}
                  />
                </div>
              );
            })}
          </ResourceContainer>
          <p className='font-zen-body font-medium text-lg text-black pt-5 pb-3'>
            Commission Deposits
          </p>
          <ResourceContainer
            loading={isSubmitting}
            resourceName='Commission Deposit'
            isEmpty={!commissionDeposits?.length}
          >
            {commissionDeposits?.map((depositPayment) => {
              return (
                <div className='mb-5' key={depositPayment.id}>
                  <ZenDepositCard
                    depositPayment={depositPayment}
                    onDelete={() => {
                      setDepositInformation(depositPayment);
                      setDeleteDepositModal(true);
                      setEscrowType(EscrowTypeEnum.Deposit);
                    }}
                    deleteInstallment={setDepositInstallmentInformation}
                    setEscrowType={setEscrowType}
                    setDeleteDepositModal={setDeleteDepositModal}
                  />
                </div>
              );
            })}
          </ResourceContainer>
        </div>
        {deleteDepositModal && (
          <ZenConfirmationModal
            isOpen={isOpen}
            variant='danger'
            title='Confirm Delete'
            subtitle='Are you sure you would like to delete the deposit?'
            confirmButtonText='Delete'
            onClose={() => {
              setDeleteDepositModal(false);
              setDepositInformation(undefined);
            }}
            onConfirm={() =>
              handleDeleteDeposit(
                escrowType === EscrowTypeEnum.Deposit
                  ? depositInformation?.id!
                  : depositInstallmentInformation?.id!,
              )
            }
            isSubmitting={isDeletingDeposit}
            isDisabled={isDeletingDeposit}
          />
        )}
      </form>
    </ZenSidebarModal>
  );
};

export default ZenDeleteEscrowDepositOrInstallmentSidebarModal;
