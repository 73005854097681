import { FieldPath, FieldValues } from 'react-hook-form-v7';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
import ZenControlledSelectInput, {
  ZenControlledSelectInputProps,
} from './ZenControlledSelectInput';

interface ZenControlledTimezoneSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<ZenControlledSelectInputProps<TFieldValues, TName>, 'options'> {}

const ZenControlledTimezoneSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: ZenControlledTimezoneSelectProps<TFieldValues, TName>,
) => {
  const { options } = useTimezoneSelect({
    labelStyle: 'original',
    timezones: allTimezones,
  });

  return <ZenControlledSelectInput {...props} options={options} />;
};

export default ZenControlledTimezoneSelect;
