import { getFormattedDateISOString } from '../../../utils/DateUtils';

export interface DateCellProps {
  date: string | undefined;
}

const DateCell: React.FC<DateCellProps> = ({ date }) => {
  return <span>{date ? getFormattedDateISOString(date) : 'N/A'}</span>;
};

export default DateCell;
