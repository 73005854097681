import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { VictoryPie } from 'victory';
import { WEALTH_PLAN_PIE_CHART_RADIUS } from '../../../../constants/WealthPlanConstants';
import useEarningsChartData, {
  EarningsChartData,
} from '../../../../hooks/useEarningsChartData';
import { RootState } from '../../../../types';
import { displayFormattedFixedAmountWithCurrency } from '../../../../utils/CurrencyUtils';
import { useScreenSize } from '../../utils';
import { EarningIcon } from '../../WealthManagementTypes';
import SliceHoverElement, {
  SliceHoverPosition,
  SliceProps,
} from './SliceHoverElement';

const LabelComponent = (props: any) => {
  const screenSize = useScreenSize();
  const isSmallScreen = screenSize.screen === 'xs' || screenSize.screen === 's';
  const { x, y, datum } = props as {
    x: number;
    y: number;
    datum: EarningsChartData;
  };

  return (
    <g
      transform={`translate(${x - (isSmallScreen ? 10 : 160)}, ${y - 10})`}
      className='pointer-events-none'
    >
      <FontAwesomeIcon
        icon={datum.locked ? faLock : EarningIcon[datum.type!]}
        size='xs'
        viewBox='0 0 12000 12000'
        color='white'
      />
    </g>
  );
};

const TotalEarningsPieChart: React.FC = () => {
  const ref = useRef<any>(null);
  const screenSize = useScreenSize();
  const isSmallScreen = screenSize.screen === 'xs' || screenSize.screen === 's';

  const {
    earningsOverviewResponse: { data: overviewResponse },
  } = useSelector((state: RootState) => state.wealthManagement);

  const { projectedEarningsValue } = overviewResponse || {};

  const projectedEarnings = displayFormattedFixedAmountWithCurrency(
    projectedEarningsValue,
    false,
    0,
  );

  const [earningsData, setEarningsData] = useState<EarningsChartData[]>(
    useEarningsChartData(),
  );
  const [sliceProps, setSliceProps] = useState<SliceProps | null>(null);
  const [chartDimensions, setChartDimensions] = useState({
    height: 1,
    width: 1,
  });
  const [sliceMouseEventActive, setSliceMouseEventActive] = useState(false);
  const [
    sliceHoverElementMouseEventActive,
    setSliceHoverElementMouseEventActive,
  ] = useState(false);

  const calculatePoint = (startAngle: number, endAngle: number) => {
    const { height, width } = ref.current.getBoundingClientRect();
    const midAngle = (startAngle + endAngle) / 2;
    const radius = height / 2;
    const radians = ((midAngle - 90) * Math.PI) / 180;

    let position: SliceHoverPosition = 'bottom-left';
    const isTopRight = midAngle <= 90;
    const isBottomRight = midAngle > 90 && midAngle <= 180;
    const isBottomLeft = midAngle > 180 && midAngle <= 270;
    const isTopLeft = midAngle > 270 && midAngle <= 360;

    const midX = Math.round(radius * Math.cos(radians));
    const midY = Math.round(radius * Math.sin(radians));

    let x = midX + width / 2;
    let y = midY + height / 2;

    const adjustPosition = (offsetX: number, offsetY: number) => ({
      x: x - offsetX,
      y: y - offsetY,
      position,
    });

    if (isBottomRight) {
      if (isSmallScreen) {
        position = 'top-left';
        return midAngle < 135
          ? adjustPosition(250, 170)
          : adjustPosition(200, 210);
      }
      position = 'bottom-right';
      return midAngle < 135 ? adjustPosition(45, 25) : adjustPosition(25, 50);
    }
    if (isTopRight) {
      if (isSmallScreen) {
        position = 'bottom-left';
        return midAngle < 45
          ? adjustPosition(220, -20)
          : adjustPosition(260, -20);
      }
      position = 'top-right';
      return midAngle < 45 ? adjustPosition(30, 90) : adjustPosition(45, 100);
    }
    if (isBottomLeft) {
      if (isSmallScreen) {
        position = 'top-right';
        return midAngle < 225
          ? adjustPosition(-20, 200)
          : adjustPosition(-50, 170);
      }
      position = 'bottom-left';
      return midAngle < 225 ? adjustPosition(220, 60) : adjustPosition(200, 20);
    }
    if (isTopLeft) {
      if (isSmallScreen) {
        position = 'bottom-right';
        return midAngle < 315
          ? adjustPosition(-40, -20)
          : adjustPosition(10, -60);
      }
      position = 'top-left';
      return midAngle < 315
        ? adjustPosition(200, 110)
        : adjustPosition(220, 90);
    }

    return { x, y, position };
  };

  useEffect(() => {
    const resizeHandler = () => {
      if (!ref.current) return;
      const { height, width } = ref.current.getBoundingClientRect();
      setChartDimensions({ height, width });
    };
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [ref]);

  return (
    <div ref={ref} className='relative'>
      <div
        className='absolute z-10 top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] items-center'
        style={{
          width: chartDimensions.height / 2 - 30,
        }}
      >
        <p
          className='text-gray-400 text-sm text-center line-clamp-1'
          title='5 year projection'
        >
          5 year projection
        </p>
        <p
          title={projectedEarnings}
          className='w-full text-2xl text-center break-words line-clamp-1'
        >
          {projectedEarnings}
        </p>
      </div>
      <VictoryPie
        data={earningsData}
        width={isSmallScreen ? 400 : 700}
        innerRadius={isSmallScreen ? 100 : 90}
        radius={({ datum }) => datum.radius || WEALTH_PLAN_PIE_CHART_RADIUS}
        padAngle={({ datum }) => datum.padding || 0.2}
        labelRadius={140}
        labelComponent={<LabelComponent />}
        animate={{ duration: 300 }}
        style={{
          data: {
            fill: ({ datum }) => (datum.locked ? '#C8C8C8' : datum.fill),
            pointerEvents: 'auto',
          },
        }}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onMouseOver: () => {
                return [
                  {
                    target: 'data',
                    mutation: ({ datum, data }) => {
                      setSliceMouseEventActive(true);
                      const info = calculatePoint(
                        datum.startAngle,
                        datum.endAngle,
                      );
                      setSliceProps({ ...info, type: datum.type });

                      const index = data.findIndex(
                        (d: any) => d.fill === datum.fill,
                      );

                      setEarningsData((prev) => {
                        const newData = [
                          ...prev.map((d) => ({
                            ...d,
                            padding: 0,
                            radius: WEALTH_PLAN_PIE_CHART_RADIUS,
                          })),
                        ];
                        newData[index] = {
                          ...newData[index],
                          padding: 1.5,
                          radius: WEALTH_PLAN_PIE_CHART_RADIUS + 10,
                        };
                        return newData;
                      });
                    },
                  },
                ];
              },
              onMouseLeave: () => {
                return [
                  {
                    target: 'data',
                    mutation: () => {
                      setSliceMouseEventActive(false);
                      if (sliceHoverElementMouseEventActive) return;
                      setSliceProps(null);
                      setEarningsData((prev) => {
                        const newData = prev.map((d) => ({
                          ...d,
                          padding: 0,
                          radius: WEALTH_PLAN_PIE_CHART_RADIUS,
                        }));
                        return newData;
                      });
                    },
                  },
                ];
              },
            },
          },
        ]}
      />
      {sliceProps && (
        <SliceHoverElement
          earningsData={earningsData}
          sliceMouseEventActive={sliceMouseEventActive}
          setSliceHoverElementMouseEventActive={
            setSliceHoverElementMouseEventActive
          }
          setEarningsData={setEarningsData}
          setSliceProps={setSliceProps}
          {...sliceProps}
        />
      )}
    </div>
  );
};

export default TotalEarningsPieChart;
