import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface InvalidTokenTransactionProps {}

const InvalidTokenTransaction: React.FC<InvalidTokenTransactionProps> = () => {
  return (
    <div className='h-80 flex items-center justify-center mx-auto w-full max-w-xl'>
      <div className='w-full h-full p-5 flex flex-col items-center justify-center'>
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          className='text-error text-5xl'
          size='lg'
        />
        <p className='text-lg mt-3 text-center'>
          This transaction has been closed/terminated, please contact support if
          you think there is a mistake
        </p>
      </div>
    </div>
  );
};

export default InvalidTokenTransaction;
