import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faInfoCircle,
  faTriangleExclamation,
  faTrash as faTrashSolid,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowUpToLine,
  faCheck,
  faTrash,
  faXmarkCircle,
} from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { EnumMap } from '../../../types';
import ZenButton, { ZenButtonVariant } from '../ZenButton';

export type ZenModalVariantType =
  | 'info'
  | 'warning'
  | 'success'
  | 'error'
  | 'danger'
  | 'upload'
  | 'cancel'
  | 'close';

export type ZenModalSizeVariantType = 'default' | 'small' | 'medium' | 'large';

interface ZenNonOutsideClickConfirmationModalProps {
  variant: ZenModalVariantType;
  title?: string;
  isOpen: boolean;
  subtitle?: string;
  hideIcon?: boolean;
  size?: ZenModalSizeVariantType;
  onClose(): void;
  customIcon?: React.ReactElement;
  onConfirm?(): void;
  isSubmitting: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  hideBottomButtons?: boolean;
  hideCancelButton?: boolean;
}

const ZenNonOutsideClickConfirmationModal: React.FC<ZenNonOutsideClickConfirmationModalProps> = ({
  variant,
  title,
  isOpen,
  subtitle,
  onClose,
  hideIcon = false,
  size = 'default',
  onConfirm,
  isSubmitting,
  customIcon,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  hideBottomButtons = false,
  hideCancelButton = false,
  children,
}) => {
  const classMapStyle: EnumMap<ZenModalVariantType, string> = {
    error: 'bg-zen-danger text-zen-danger',
    info: 'bg-primary-blue text-primary-blue',
    success: 'bg-green-600 text-green-600',
    warning: 'bg-primary-blue text-primary-blue',
    danger: 'bg-zen-danger text-zen-danger',
    upload: 'bg-primary-blue text-primary-blue',
    cancel: 'bg-zen-danger text-zen-danger',
    close: 'bg-zen-danger text-zen-danger',
  };

  const IconComponent: EnumMap<ZenModalVariantType, React.ReactElement> = {
    error: <FontAwesomeIcon icon={faExclamationCircle} />,
    info: <FontAwesomeIcon icon={faInfoCircle} />,
    success: <FontAwesomeIcon icon={faCheck} />,
    warning: <FontAwesomeIcon icon={faTriangleExclamation} />,
    danger: <FontAwesomeIcon icon={faTrashSolid} />,
    upload: <FontAwesomeIcon icon={faArrowUpToLine} />,
    cancel: <FontAwesomeIcon icon={faTrash} />,
    close: <FontAwesomeIcon icon={faXmarkCircle} />,
  };

  const sizeVariant: EnumMap<ZenModalSizeVariantType, string> = {
    default: 'md:w-1/2 lg:w-1/4',
    large: 'md:w-3/5 lg:w-1/3',
    small: 'md:w-2/5 lg:w-1/5',
    medium: 'md:w-5/12 lg:w-4/12',
  };

  const modalVariantToButtonVariantMap: EnumMap<
    ZenModalVariantType,
    ZenButtonVariant
  > = {
    info: 'primary',
    success: 'success',
    warning: 'primary',
    upload: 'primary',
    cancel: 'danger',
    close: 'danger',
    danger: 'danger',
    error: 'danger',
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40' />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
        <div
          className={classNames(
            'w-full bg-white rounded-xl shadow-zen-web p-4 z-10',
            sizeVariant[size],
          )}
        >
          <div
            className={classNames('flex items-start', {
              'justify-center': hideIcon,
            })}
          >
            {customIcon ? (
              <div className='mr-3'>
                <div
                  className={classNames(
                    'flex justify-center items-center w-10 h-10 p-1 bg-opacity-20 rounded-full',
                    classMapStyle[variant],
                  )}
                >
                  {customIcon}
                </div>
              </div>
            ) : (
              !hideIcon && (
                <div className='mr-3'>
                  <div
                    className={classNames(
                      'flex justify-center items-center w-10 h-10 bg-opacity-20 rounded-full p-1',
                      classMapStyle[variant],
                    )}
                  >
                    {IconComponent[variant]}
                  </div>
                </div>
              )
            )}
            <div className='w-full py-2'>
              {title && (
                <p className='font-zen-body font-bold text-zen-dark-9 text-base'>
                  {title}
                </p>
              )}
              {subtitle && (
                <p className='text-base text-gray-500 font-primary-regular py-2'>
                  {subtitle}
                </p>
              )}
              {children}
              {!hideBottomButtons && (
                <div className='flex flex-row mt-3 space-x-3'>
                  {!hideCancelButton && (
                    <ZenButton
                      isFullWidth
                      label={cancelButtonText}
                      variant='secondary-gray-outline'
                      onClick={onClose}
                    />
                  )}
                  <ZenButton
                    isFullWidth
                    label={confirmButtonText}
                    variant={modalVariantToButtonVariantMap[variant]}
                    onClick={onConfirm}
                    isDisabled={isSubmitting}
                    isSubmitting={isSubmitting}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenNonOutsideClickConfirmationModal;
