import ZenAdd from '../../Zen/ZenAdd';
import Key from '../../../assets/img/Key.png';

interface AgentGenerateKeyBoxProps {
  currentTab?: string;
  onClick?(): void;
}

const AgentGenerateKeyBox: React.FC<AgentGenerateKeyBoxProps> = ({
  currentTab = 'Active',
  onClick,
}) => {
  return (
    <div className='grid justify-center'>
      <div>
        <img src={Key} alt='key' className='block mx-auto w-6/12' />
      </div>
      <div className='pt-6 font-zen-body mx-auto'>
        {currentTab === 'Revoked' || currentTab === 'Expired' ? (
          <span className='text-sm text-zen-dark-7'>
            No {currentTab === 'Revoked' ? 'revoked' : 'expired'} API keys yet.
          </span>
        ) : (
          <span className='text-sm text-zen-dark-7'>
            You don’t have any API keys.
          </span>
        )}
      </div>
      <div className='pt-1 pb-4 flex flex-col items-center mx-auto'>
        {currentTab !== 'Revoked' && currentTab !== 'Expired' && (
          <ZenAdd onClick={onClick} text='Generate Key' />
        )}
      </div>
    </div>
  );
};
export default AgentGenerateKeyBox;
