import React from 'react';
import { EnumMap } from '../../types';
import { cn } from '../../utils/classUtils';

export type ZenAlertVariantType =
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | 'default';

interface ZenAlertProps {
  text: string;
  variant: ZenAlertVariantType;
  icon?: JSX.Element;
  className?: string;
}

const ZenAlert: React.FC<ZenAlertProps> = ({
  text,
  variant,
  className,
  icon,
}) => {
  const alertVariantToClassNameMap: EnumMap<ZenAlertVariantType, string> = {
    success: 'bg-green-600 bg-opacity-10 text-success',
    info: 'bg-primary-blue bg-opacity-10 text-primary-blue',
    warning: 'bg-zen-warning bg-opacity-10 text-zen-warning',
    error: 'bg-error bg-opacity-10 text-error',
    default: 'bg-gray-500',
  };

  return (
    <div
      className={cn(
        'text-white rounded-[10px] px-6 py-2',
        alertVariantToClassNameMap[variant],
        className,
      )}
    >
      <div className='flex items-center'>
        {icon}
        <span className='font-primary-regular ml-3 pt-0.5 break-words'>
          {text}
        </span>
      </div>
    </div>
  );
};

export default ZenAlert;
