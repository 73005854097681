import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EscrowResponse,
  TransactionControllerApi,
  TransactionResponse,
} from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { showErrorToastForErrorCode } from '../../../slices/ToastNotificationSlice';
import { fetchTransactionDetails } from '../../../slices/TransactionSlice';
import { AppDispatch, RootState } from '../../../types';
import { isBroker } from '../../../utils/AuthUtils';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import {
  isTransactionOrFinanceAdmin,
  isTransactionReadOnly,
} from '../../../utils/TransactionHelper';
import ResourceContainer from '../../ResourceContainer';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../../Zen/ZenButton';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import ZenCommissionDepositInformationForm from '../CommissionDeposits/ZenCommissionDepositInformationForm';
import ZenManageCommissionDepositCard from './ZenManageCommissionDepositCard';

interface ManageCommissionDepositsProps {
  isOpen: boolean;
  onClose(): void;
  depositPayments: EscrowResponse[];
  transaction: TransactionResponse;
}

const ZenManageCommissionDeposits: React.FC<ManageCommissionDepositsProps> = ({
  depositPayments,
  isOpen,
  onClose,
  transaction,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    auth: { userDetail, keymakerCurrentUser },
  } = useSelector((state: RootState) => state);
  const [
    depositInformation,
    setDepositInformation,
  ] = useState<EscrowResponse>();
  const [isOpenDepositModal, setIsOpenDepositModal] = useState<boolean>(false);
  const [deleteDepositModal, setDeleteDepositModal] = useState<boolean>(false);
  const [isDeletingDeposit, setIsDeletingDeposit] = useState<boolean>(false);

  const allowAddDeposit =
    !isTransactionReadOnly(transaction, userDetail!) ||
    isBroker(userDetail) ||
    (isTransactionOrFinanceAdmin(keymakerCurrentUser?.activeRoles!) &&
      transaction.terminated);

  const handleDeleteDeposit = async (escrowId: string) => {
    setIsDeletingDeposit(true);
    try {
      await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).deleteEscrow(escrowId);
      dispatch(fetchTransactionDetails(transaction.id!));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to delete the escrow deposit', e, {
        escrow: { id: escrowId },
      });
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to delete the escrow deposit.',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setIsDeletingDeposit(false);
    }
  };

  return (
    <ZenSidebarModal
      title='Manage Commission Deposits'
      subtitle={transaction?.address?.oneLine}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='p-4'>
        {allowAddDeposit && (
          <div className='border-b border-gray-200 pb-4'>
            <ZenButton
              type='button'
              onClick={() => {
                setDepositInformation(undefined);
                setIsOpenDepositModal(true);
              }}
              label='Add Commission Deposit'
              LeftIconComponent={
                <FontAwesomeIcon
                  icon={faPlus}
                  size='sm'
                  className='mx-1 mt-0.5'
                />
              }
              variant='primary-outline'
            />
          </div>
        )}
        <p className='text-zen-dark-9 text-lg font-zen-body font-semibold py-5'>
          Commission Deposit
        </p>
        <ResourceContainer
          loading={false}
          resourceName='Commission Deposit'
          isEmpty={!depositPayments?.length}
        >
          {depositPayments?.map((depositPayment) => {
            return (
              <div className='mb-5' key={depositPayment.id}>
                <ZenManageCommissionDepositCard
                  depositPayment={depositPayment}
                  onEdit={() => {
                    setDepositInformation(depositPayment);
                    setIsOpenDepositModal(true);
                  }}
                  onDelete={() => {
                    setDepositInformation(depositPayment);
                    setDeleteDepositModal(true);
                  }}
                  transaction={transaction}
                  userDetails={userDetail!}
                />
              </div>
            );
          })}
        </ResourceContainer>
        {isOpenDepositModal && (
          <ZenCommissionDepositInformationForm
            depositInformation={depositInformation!}
            onClose={() => {
              setIsOpenDepositModal(false);
              onClose();
            }}
            transaction={transaction}
          />
        )}
        {deleteDepositModal && (
          <ZenConfirmationModal
            isOpen={isOpen}
            onClose={() => {
              setDeleteDepositModal(false);
              setDepositInformation(undefined);
            }}
            variant='danger'
            title='Confirm Delete'
            subtitle='Are you sure you would like to delete the deposit?'
            onConfirm={() => handleDeleteDeposit(depositInformation?.id!)}
            cancelButtonText='Cancel'
            confirmButtonText='Delete'
            isDisabled={isDeletingDeposit}
            isSubmitting={isDeletingDeposit}
          />
        )}
      </div>
    </ZenSidebarModal>
  );
};

export default ZenManageCommissionDeposits;
