import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { TransactionResponse } from '../../../openapi/arrakis';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import { capitalizeEnum, formatPhoneNumber } from '../../../utils/StringUtils';
import ZenIconText from '../../Zen/ZenIconText';
import { getSortedContacts } from '../../../utils/TransactionHelper';
import Hover from '../../Hover';
import ZenCopyToClipboard from '../../Zen/ZenCopyToClipBoard';

interface ContactsProps {
  transaction: TransactionResponse;
}

const Contacts: React.FC<ContactsProps> = ({ transaction }) => {
  return (
    <div className='p-2'>
      <div className='flex flex-row items-center'>
        <p className='text-lg pr-2'>Contacts</p>
        <div className='border-[0.5px] border-primary-dark w-full' />
      </div>
      <div className='px-2'>
        {!!transaction?.participants?.length &&
          getSortedContacts(transaction?.participants)?.map(
            (sortedParticipants, index) => {
              return (
                <div
                  key={`${sortedParticipants?.length + index}`}
                  className='w-full'
                >
                  {sortedParticipants?.map((participant) => (
                    <div
                      key={participant?.id}
                      className='flex flex-row items-center justify-start w-full py-1'
                    >
                      <ZenIconText
                        icon={
                          <FontAwesomeIcon
                            className='bg-grey-200 p-2 rounded-full'
                            icon={faUser}
                            size='sm'
                          />
                        }
                        text={
                          <>
                            <span className='font-primary-medium text-sm'>
                              {capitalizeEnum(
                                participant?.participantRole || '',
                              )}
                              :
                            </span>{' '}
                            <span className='font-primary-regular text-sm'>
                              {getParticipantName(participant)}
                            </span>
                          </>
                        }
                      />
                      {participant.emailAddress && (
                        <Hover
                          hoverComponent={
                            <div className='flex items-center text-gray-500'>
                              {participant.emailAddress}
                              <ZenCopyToClipboard
                                label=''
                                value={participant.emailAddress!}
                                variant='primary'
                              />
                            </div>
                          }
                          config={{ trigger: 'hover', placement: 'top' }}
                        >
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className='cursor-pointer text-primary-blue ml-2'
                            aria-label='participant-email'
                          />
                        </Hover>
                      )}
                      {participant.phoneNumber && (
                        <Hover
                          hoverComponent={
                            <div className='flex items-center  text-gray-500'>
                              {formatPhoneNumber(participant.phoneNumber)}
                              <ZenCopyToClipboard
                                label=''
                                value={
                                  formatPhoneNumber(participant.phoneNumber) ||
                                  ''
                                }
                                variant='primary'
                              />
                            </div>
                          }
                          config={{ trigger: 'hover', placement: 'top' }}
                        >
                          <FontAwesomeIcon
                            icon={faPhone}
                            className='cursor-pointer text-primary-blue ml-2'
                            aria-label='participant-phone'
                          />
                        </Hover>
                      )}
                    </div>
                  ))}
                </div>
              );
            },
          )}
      </div>
    </div>
  );
};

export default Contacts;
