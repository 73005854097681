import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form-v7';
import { sum } from 'lodash';
import {
  TransactionSetupFormProps,
  TransactionSetupStepName,
} from '../../../containers/transaction/setup/TransactionSetupContainer';
import { AppDispatch, RootState } from '../../../types';
import { displayAmount, parseMoney } from '../../../utils/CurrencyUtils';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import { calculateCommissionSplitErrors } from '../../../utils/CommissionHelper';
import {
  FeaturesResponseEligibleEnum,
  MoneyValue,
  ParticipantPaymentValue,
  PercentageValue,
} from '../../../openapi/arrakis';
import { isStringEmpty } from '../../../utils/StringUtils';
import Logger from '../../../utils/Logger';
import { updateCommissionPayouts } from '../../../slices/TransactionSlice';
import CommissionSplitContent from '../CommissionSplitContent';
import Button from '../../Button';
import AddParticipantsForm from '../AddParticipantsForm';
import { toggleAddCommissionParticipant } from '../../../slices/QuickActionSlice';
import IconButton from '../../IconButton';

const TransactionSetupCommissionSplitsStep: StepByStepComponent<
  TransactionSetupFormProps,
  TransactionSetupStepName
> = ({
  onPrevious,
  onNext,
  form: {
    control,
    watch,
    setValue,
    formState: { errors, isValid },
  },
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
      features,
    },
    quickAction: { addCommissionParticipantSidebar },
  } = useSelector((state: RootState) => state);
  const totalCommission = transactionDetail?.grossCommission?.amount!;

  const isTransactionEligibleForRealTitle = !!features?.eligible?.find(
    (feature) => feature === FeaturesResponseEligibleEnum.Title,
  );

  const { fields } = useFieldArray({
    control,
    name: 'commissionParticipant',
  });

  const watchParticipants = watch('commissionParticipant', []);
  const totalPercentage = sum(
    watchParticipants
      .filter((p: any) => !p.isDollar)
      .map((item: any) => +item.percent?.value || 0) || 0,
  );
  const totalAmount = parseMoney(
    sum(
      watchParticipants
        .filter((p) => p.isDollar)
        .map((item) => +item.money?.amount! || 0),
    ),
  );

  const isPercentageTypeSplitAvailable = !!watchParticipants.find(
    (p) => !p.isDollar,
  );

  const commissionSplitErrors = calculateCommissionSplitErrors(
    {
      amount: totalCommission,
      currency: transactionDetail?.grossCommission?.currency,
    },
    totalPercentage,
    totalAmount,
    errors,
    !!transactionDetail?.zeroCommissionDeal,
    isPercentageTypeSplitAvailable,
  );

  useEffect(() => {
    setValue(
      'commissionParticipant',
      transactionDetail?.possiblePayableParticipants?.map((participant) => {
        return {
          percent: {
            value: participant.payment?.percent
              ? parseFloat((participant.payment?.percent! * 100).toFixed(2))
              : 0,
            string: `${((participant.payment?.percent || 0)! * 100).toFixed(
              2,
            )}%`,
          },
          money: {
            amount: parseMoney(participant.payment?.amount?.amount! || 0),
            currency: participant.payment?.amount?.currency,
          },

          isDollar: !!participant.payment?.amount,
          id: participant.id!,
        };
      }) || [],
    );
  }, [setValue, transactionDetail?.possiblePayableParticipants]);

  const onSubmit = async () => {
    setIsLoading(true);
    let finalData: Array<ParticipantPaymentValue | null> = watchParticipants?.map(
      (item: any) => {
        let moneyValue: MoneyValue | undefined = undefined;
        let percentageValue: PercentageValue | undefined = undefined;

        if (!item.isDollar && !isStringEmpty(item.percent.value)) {
          percentageValue = {
            value: item.percent.value!,
            string: `${item.percent.value}%`,
          };
        } else if (!isStringEmpty(item.money.amount)) {
          moneyValue = {
            amount: item.money.amount!,
            currency: item.money.currency,
          };
        } else {
          Logger.debug(
            'Both percentage and amount are null. Skipping over.',
            item,
          );
          return null;
        }

        const participantPaymentValue: ParticipantPaymentValue = {
          paymentValue: {
            money: moneyValue,
            percentage: percentageValue,
          },
          participantId: item.id,
        };

        return participantPaymentValue;
      },
    );

    finalData = finalData.filter((d) => d !== null);

    await dispatch(
      updateCommissionPayouts(transactionDetail?.id!, {
        payments: finalData as Array<ParticipantPaymentValue>,
      }),
    );

    setIsLoading(false);
    onNext();
  };

  const toggleSwitches = (index: number) => {
    const newParticipants = [...watchParticipants];
    newParticipants[index] = {
      ...newParticipants[index],
      isDollar: watchParticipants[index].isDollar,
      id: watchParticipants[index].id,
    };

    setValue('commissionParticipant', newParticipants, {
      shouldValidate: true,
    });
    setValue(
      `commissionParticipant.${index}.percent`,
      {
        value: newParticipants[index]?.percent?.value || 0,
        string: newParticipants[index]?.percent?.string || '0%',
      },
      {
        shouldValidate: true,
      },
    );
    setValue(
      `commissionParticipant.${index}.money`,
      {
        amount: parseMoney(+newParticipants[index]?.money?.amount!) || 0,
        currency: transactionDetail?.price?.currency,
      },
      {
        shouldValidate: true,
      },
    );
  };

  return (
    <div className='flex-grow flex justify-center mt-10 w-full max-w-md'>
      <div className='w-full max-w-md mb-20'>
        <div className='w-full p-3 bg-gray-100'>
          <p className='font-primary-medium text-lg'>
            Total Commission:{' '}
            {displayAmount(transactionDetail?.grossCommission)}
          </p>
          <p className='text-sm text-gray-600'>
            Property: {transactionDetail?.address?.oneLine}
          </p>
        </div>
        <div className='space-y-2 divide-y mt-3'>
          <div className='flex flex-row justify-between items-center px-2 md:px-4'>
            <p className='text-lg font-primary-regular'>
              Participants ({watchParticipants.length})
            </p>
            <IconButton
              label='Add Participant'
              variant='outline'
              onClick={() =>
                dispatch(toggleAddCommissionParticipant({ isOpen: true }))
              }
            />
          </div>
          <CommissionSplitContent
            totalAmount={totalAmount}
            totalPercentage={totalPercentage}
            watchParticipants={watchParticipants}
            commissionSplitErrors={commissionSplitErrors}
            control={control}
            currency={transactionDetail?.price?.currency!}
            errors={errors}
            fields={fields}
            toggleSwitches={toggleSwitches}
            participants={transactionDetail?.possiblePayableParticipants || []}
            totalCommission={totalCommission}
          />
        </div>
        <AddParticipantsForm
          isOpen={!!addCommissionParticipantSidebar?.isOpen}
          onClose={() =>
            dispatch(toggleAddCommissionParticipant({ isOpen: false }))
          }
          transaction={transactionDetail!}
          agentType={addCommissionParticipantSidebar?.agentType}
          role={addCommissionParticipantSidebar?.role}
        />
      </div>
      <div className='absolute bottom-0 w-full z-0 bg-white'>
        <div className='w-full mx-auto max-w-6xl'>
          <div className='w-full flex items-center justify-between py-6 md:py-8 shadow-top-sm'>
            <Button
              fullWidth
              size='lg'
              type='outline'
              className='w-1/3 justify-center text-opacity-50'
              label='Previous'
              onClick={onPrevious}
            />
            <Button
              fullWidth
              className='w-1/3 justify-center bg-primary md:col-span-1 offset'
              size='lg'
              label={isTransactionEligibleForRealTitle ? 'Next' : 'Review'}
              disabled={!isValid || !!commissionSplitErrors.length}
              isSubmitting={isLoading}
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionSetupCommissionSplitsStep;
