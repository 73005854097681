import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CheckDepositUploadResponse } from '../../../openapi/arrakis';
import {
  resetCounter,
  uploadCheckDeposits,
  uploadEscrowCheckDeposits,
  uploadExternalCheckDeposit,
} from '../../../slices/CheckDepositsSlice';
import { AppDispatch, RootState } from '../../../types';
import {
  transactionCheckDepositPayAtClosingSteps,
  updateCheckDepositsRequestCounter,
} from '../../../utils/CheckDepositsHelper';
import ZenApplicationStepProgressHeader from '../../onboarding/ZenApplicationStepProgressHeader';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';

import {
  DepositCheckFormData,
  DepositCheckStepName,
  Match,
} from '../../../routes/ZenTransactionDepositCheckRoute';
import ZenCheckDepositBasicInfoStep from './ZenCheckDepositBasicInfoStep';
import ZenCheckDepositHeader from './ZenCheckDepositHeader';
import withZenExternalCheckDepositProgress from './WithZenExternalCheckDepositProgress';

export const TransactionAddress =
  '39899 Balentine Dr, Ste 200 Newark, CA 94560';

const ZenTransactionCheckDepositBasicInfo: StepByStepComponent<
  DepositCheckFormData,
  DepositCheckStepName
> = ({ form, onNext, onPrevious }) => {
  const { watch, trigger, setValue } = form;
  const { id } = useParams<Match>();
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
      tokenTransactionDetail,
    },
    checkDeposits: { requestCounter },
  } = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();
  const [
    isExternalCheckDeposit,
    depositorName,
    depositorEmail,
    depositorPhoneNumber,
    depositAmount,
    frontCheckImage,
    backCheckImage,
    closingDate,
    escrowId,
    showClosing,
    isExternalCheckDepositUpload,
  ] = watch([
    'isExternalCheckDeposit',
    'depositorName',
    'depositorEmail',
    'depositorPhoneNumber',
    'depositAmount',
    'frontCheckImage',
    'backCheckImage',
    'closingDate',
    'escrowId',
    'showClosing',
    'isExternalCheckDeposit',
  ]);

  const handleExternalCheckDepositUpload = async (): Promise<boolean> => {
    const isValid = await trigger();
    if (isValid) {
      let data: CheckDepositUploadResponse | undefined;

      const counter = updateCheckDepositsRequestCounter(requestCounter, id);

      data = await dispatch(
        uploadExternalCheckDeposit(
          id,
          parseFloat(depositAmount!),
          frontCheckImage!,
          backCheckImage!,
          depositorName!,
          depositorEmail!,
          depositorPhoneNumber!,
          counter,
        ),
      );

      if (data) {
        setValue('amount', data.submittedAmount?.toString());
        setValue('scannedAmount', data.scannedAmount?.toString());
        onNext();
        return false;
      }

      return true;
    }

    return false;
  };

  const handleUploadCheckDeposit = async (): Promise<boolean> => {
    const isValid = await trigger();
    if (isValid) {
      const counter = updateCheckDepositsRequestCounter(requestCounter, id);

      let data: CheckDepositUploadResponse | undefined;

      if (!escrowId) {
        data = await dispatch(
          uploadCheckDeposits(
            id,
            parseFloat(depositAmount!),
            frontCheckImage!,
            backCheckImage!,
            counter,
          ),
        );
      } else {
        data = await dispatch(
          uploadEscrowCheckDeposits(
            escrowId,
            parseFloat(depositAmount!),
            frontCheckImage!,
            backCheckImage!,
            counter,
          ),
        );
      }

      if (data) {
        setValue('amount', data.submittedAmount?.toString());
        setValue('scannedAmount', data.scannedAmount?.toString());
        onNext();
        return false;
      }

      return true;
    }

    return false;
  };

  useEffect(() => {
    dispatch(resetCounter(id));
  }, [dispatch, id]);

  return (
    <div className='w-full h-full max-w-3xl py-4 mx-auto bg-white'>
      <div className='mb-8'>
        {!isExternalCheckDepositUpload && showClosing && (
          <div className='flex justify-center mb-3'>
            <ZenApplicationStepProgressHeader
              steps={transactionCheckDepositPayAtClosingSteps(
                !watch('closeAt'),
              )}
              inProgressIndex={1}
            />
          </div>
        )}
      </div>

      <ZenCheckDepositHeader
        transactionAddress={
          isExternalCheckDeposit
            ? tokenTransactionDetail?.propertyAddress
            : transactionDetail?.address?.oneLine
        }
        closingDate={closingDate}
      />

      <ZenCheckDepositBasicInfoStep
        form={form}
        onPrevious={onPrevious}
        onSubmit={
          isExternalCheckDeposit
            ? handleExternalCheckDepositUpload
            : handleUploadCheckDeposit
        }
      />
    </div>
  );
};

export default withZenExternalCheckDepositProgress(
  ZenTransactionCheckDepositBasicInfo,
);
