import classNames from 'classnames';
import { EnumMap, ZenEditTransactionButtonType } from '../../../types';

export type IconVariantType = 'primary' | 'danger' | 'disabled';

export interface ZenEditTransactionButtonProps {
  type?: ZenEditTransactionButtonType;
  leftIcon: React.ReactElement;
  label: string;
  onClick?(): void;
  iconVariant?: IconVariantType;
  disabled?: boolean;
}

const ZenEditTransactionButton: React.FC<ZenEditTransactionButtonProps> = ({
  leftIcon,
  onClick,
  label,
  disabled = false,
  type = 'primary_outline',
  iconVariant = 'primary',
}) => {
  const TransactionButtonTypeToClassNameMap: EnumMap<
    ZenEditTransactionButtonType,
    string
  > = {
    primary_outline:
      'bg-white border border-zen-dark-4 rounded-lg hover:border-zen-dark-9',
    disabled_outline:
      'bg-gray-200 bg-opacity-70 border border-zen-dark-4 rounded-lg',
    danger_no_outline:
      'bg-white hover:bg-zen-danger hover:bg-opacity-10 w-fit text-zen-danger',
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        'w-full mb-3 flex flex-row items-center rounded focus:outline-none group text-zen-dark-9 p-4',
        TransactionButtonTypeToClassNameMap[type],
        { 'cursor-not-allowed': iconVariant === 'disabled' || disabled },
      )}
    >
      <div className='flex flex-row items-center space-x-2'>
        <span className='w-[34px] h-[34px] flex justify-center items-center'>
          {leftIcon}
        </span>
        <span className='font-zen-body font-normal text-sm text-left'>
          {label}
        </span>
      </div>
    </button>
  );
};

export default ZenEditTransactionButton;
