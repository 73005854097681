import { faLongArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBy, map } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import { displayDate } from '../../../../utils/DateUtils';
import ChatHistoryByDayCard from './ChatHistoryByDayCard';

interface LeoChatHistoryProps {
  toggleHistory(param: boolean): void;
}

export interface SessionHistory {
  id: string;
  agent_id: string;
  title?: string;
  system_message: string;
  start_time: string;
  end_time?: string;
}

const LeoChatHistory: React.FC<LeoChatHistoryProps> = ({ toggleHistory }) => {
  const { chatHistory } = useSelector((state: RootState) => state.leo);

  const chatHistoryLength = chatHistory?.length;
  const sortedChatHistory = [...(chatHistory || [])]
    .sort((a, b) => {
      const aTime = DateTime.fromISO(a.start_time).toMillis();
      const bTime = DateTime.fromISO(b.start_time).toMillis();
      if (aTime < bTime) {
        return 1;
      } else if (aTime > bTime) {
        return -1;
      }
      return 0;
    })
    .map((el, ind) => ({
      ...el,
      title: el.title ?? `Session ${chatHistoryLength! - ind}`,
    }));
  const historyGroupedByDay = groupBy(sortedChatHistory, (el) =>
    displayDate(el.start_time),
  );

  return (
    <div className='flex-grow flex flex-col ml-3 mb-3'>
      <div className='flex flex-row items-center'>
        <button
          className='appearance-none h-5 mr-3 block sm:hidden'
          onClick={() => toggleHistory(false)}
        >
          <FontAwesomeIcon
            icon={faLongArrowLeft}
            className='text-lg text-white'
          />
        </button>
        <div>
          <p className='font-poppins font-regular text-lg text-white'>
            Message History
          </p>
        </div>
      </div>
      {!!chatHistoryLength ? (
        <div className='overflow-y-scroll flex-grow h-[200px] scrollbar-hide'>
          {map(historyGroupedByDay, (history) => (
            <ChatHistoryByDayCard
              key={history.map((h) => h.id).join(',')}
              history={history}
            />
          ))}
        </div>
      ) : (
        <div className='mt-5'>
          <p className='font-zen-body text-base text-white'>
            You currently have no chat history. Ask a question, and find our
            conversation history here.
          </p>
        </div>
      )}
    </div>
  );
};

export default LeoChatHistory;
