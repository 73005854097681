import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { BankActivityDto } from '../../../openapi/arrakis';
import ZenTransactionMatchCard from './ZenTransactionMatchCard';

export interface ZenControlledTransactionMatchCheckboxInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  options: Array<BankActivityDto>;
  handleCheckBoxChange: (activity: BankActivityDto) => void;
  selectedActivity: BankActivityDto;
}

const ZenControlledTransactionMatchCheckboxInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  options,
  handleCheckBoxChange,
  selectedActivity,
  ...rest
}: ZenControlledTransactionMatchCheckboxInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      {...rest}
      render={({ field: { name, onBlur, ref } }) => (
        <div className='space-y-4'>
          {options.map((option) => (
            <ZenTransactionMatchCard
              key={option.id}
              name={name}
              value={selectedActivity}
              option={option}
              onChange={handleCheckBoxChange}
              onBlur={onBlur}
              ref={ref}
            />
          ))}
        </div>
      )}
    />
  );
};

export default ZenControlledTransactionMatchCheckboxInput;
