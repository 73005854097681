import { filter, keys, map } from 'lodash';
import { useForm, SubmitHandler } from 'react-hook-form-v7';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/pro-solid-svg-icons';
import ZenButton from '../../components/Zen/ZenButton';
import AdminFeatureFlagService from '../../services/AdminFeatureFlagService';
import { FeatureFlagTypeEnum, YesNoType } from '../../types';
import { FEATURE_FLAG_CONFIG } from '../../utils/FeatureFlagUtils';
import ZenControlledSelectInput from '../../components/Zen/Input/ZenControlledSelectInput';

interface FlagsIndexProps {}

type FEATURE_FLAG_KEYS = keyof typeof FEATURE_FLAG_CONFIG;

type FormValues = Record<
  FEATURE_FLAG_KEYS,
  {
    label: string;
    value: YesNoType;
  }
>;

const isFeatureFlagValid = (key: string): boolean =>
  !key.startsWith('EXAMPLE_') && key !== FeatureFlagTypeEnum.LOGIN_AS_AGENT;

const ENABLED_OPTION = { label: 'Enabled', value: YesNoType.YES } as const;
const DISABLED_OPTION = { label: 'Disabled', value: YesNoType.NO } as const;

const OPTIONS = [ENABLED_OPTION, DISABLED_OPTION];

export const validFeatureFlags: FeatureFlagTypeEnum[] = keys(
  FEATURE_FLAG_CONFIG,
).filter(isFeatureFlagValid) as FeatureFlagTypeEnum[];

const getDefaultFormValues = (): FormValues =>
  validFeatureFlags.reduce((acc, flag) => {
    const value = AdminFeatureFlagService.isAvailable(flag)
      ? YesNoType.YES
      : YesNoType.NO;
    const label = value === YesNoType.YES ? 'Enabled' : 'Disabled';
    return {
      ...acc,
      [flag]: { label, value },
    };
  }, {}) as FormValues;

const FlagsIndex: React.FC<FlagsIndexProps> = () => {
  const { handleSubmit, control, reset, watch } = useForm<FormValues>({
    defaultValues: getDefaultFormValues(),
  });
  const watchedFlags = watch();

  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    const flagsToEnable = filter(
      keys(values),
      (flag: FEATURE_FLAG_KEYS) => values[flag].value === YesNoType.YES,
    ) as FeatureFlagTypeEnum[];

    AdminFeatureFlagService.setAllFeatureFlags(flagsToEnable);
  };

  useEffect(() => {
    handleSubmit(onSubmit)();
  }, [handleSubmit, watchedFlags]);

  const enableAllFlags = () => {
    AdminFeatureFlagService.setAllFeatureFlags(validFeatureFlags);
    const resetValues = validFeatureFlags.reduce((acc, flag) => {
      return {
        ...acc,
        [flag]: ENABLED_OPTION,
      };
    }, {});
    reset(resetValues);
  };

  const disableAllFlags = () => {
    AdminFeatureFlagService.setAllFeatureFlags([]);
    const resetValues = validFeatureFlags.reduce((acc, flag) => {
      return {
        ...acc,
        [flag]: DISABLED_OPTION,
      };
    }, {});
    reset(resetValues);
  };

  return (
    <div className='max-w-xl mx-auto'>
      <h1 className='font-primary-medium mt-10 text-xl mb-1 flex'>
        <FontAwesomeIcon icon={faWandMagicSparkles} className='mr-2' />
        Feature Flags
      </h1>
      <div className='rounded border border-coolGray-200 shadow'>
        <div className='flex flex-row items-center justify-end p-3 pb-0 space-x-3'>
          <ZenButton label='Enable All' onClick={enableAllFlags} />
          <ZenButton label='Disable All' onClick={disableAllFlags} />
        </div>
        <div>
          {map(validFeatureFlags, (flag: FeatureFlagTypeEnum) => (
            <div
              key={flag}
              className='flex justify-between align-middle border-b border-coolGray-200 p-3'
            >
              <div>
                <p className='text-base mb-1'>
                  {FEATURE_FLAG_CONFIG[flag].name}
                </p>
                <p className='text-xs text-gray-500'>
                  {FEATURE_FLAG_CONFIG[flag].subtitle}
                </p>
              </div>
              <div className='w-36' data-testid={flag}>
                <ZenControlledSelectInput
                  control={control}
                  options={OPTIONS}
                  name={flag}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FlagsIndex;
