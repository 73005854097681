import {
  PinInput as MPinInput,
  PinInputProps as MPinInputProps,
} from '@mantine/core';
import { cn } from '../../utils/classUtils';
import { combineDefaultClassnamesWithCustomClassnames } from './utils';

type PinInputProps = MPinInputProps;

export const PinInput: React.FC<PinInputProps> = (props) => {
  return (
    <MPinInput
      unstyled
      autoFocus
      type='number'
      {...props}
      classNames={combineDefaultClassnamesWithCustomClassnames(
        {
          root: 'flex gap-2 items-center justify-center',
          input: cn(
            'w-10 h-10 rounded-md border border-grey-300 text-center ring-1 ring-primary-light font-inter',
            'placeholder:text-[15px] placeholder:font-inter placeholder:font-light placeholder:text-grey-500',
            'focus:ring-1 focus:ring-primary-blue focus:outline-none',
            props.error && 'border-red-600',
          ),
        },
        {
          input: cn(props.error && 'border-red-600'),
        },
        {
          input: cn(props.disabled && 'bg-gray-100'),
        },
        props.classNames,
      )}
    />
  );
};
