import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import {
  AppDispatch,
  AppThunk,
  AsyncResponse,
  ErrorCode,
  IPaginateRes,
  RevenueState,
} from '../types';
import ErrorService from '../services/ErrorService';
import {
  RevshareByTierResponse,
  RevShareContributionResponse,
  RevShareControllerApi,
  RevShareHistoryResponse,
  RevSharePaymentPreviewResponse,
  RevSharePaymentResponse,
  RevSharePaymentsResponse,
} from '../openapi/arrakis';
import {
  getArrakisConfiguration,
  getInsigniaConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import {
  AgentBadgeResponse,
  AgentBadgesResponse,
  BadgeApi,
  BadgeResponse,
  BadgeResponseNameEnum,
  BadgesResponse,
} from '../openapi/insignia';
import {
  AgentControllerApi,
  NetworkSizeByTierResponse,
} from '../openapi/yenta';
import { showApiErrorModal } from './ErrorSlice';
import { showErrorToast } from './ToastNotificationSlice';
import { performAsyncRequest } from './utils/SliceUtil';

export const initialState: RevenueState = {
  pendingPaymentsOverview: null,
  pendingPaymentsLoading: true,
  pendingPaymentsError: null,
  previousPayments: null,
  previousPaymentsLoading: true,
  previousPaymentsError: null,
  contributors: null,
  contributorsError: null,
  contributorsLoading: false,
  networkSizeByTierResponse: { loading: true, name: 'network size by tier' },
  revShareByTierResponse: { loading: true, name: 'revenue share by tier' },
  revshareHistoryResponse: { loading: true, name: 'revenue share history' },
  agentBadgesResponse: { loading: true, name: 'agent badges' },
  generalBadgesResponse: { loading: true, name: 'badges' },
  agentBadgeByName: {},
  generalBadgeByName: {},
};

const RevenueSlice = createSlice({
  name: 'revenue',
  initialState,
  reducers: {
    savePendingPaymentsOverview(
      state,
      action: PayloadAction<RevSharePaymentPreviewResponse>,
    ) {
      state.pendingPaymentsOverview = action.payload;
      state.pendingPaymentsError = null;
    },
    setPendingPaymentsLoading(state, action: PayloadAction<boolean>) {
      state.pendingPaymentsLoading = action.payload;
    },
    errorFetchingPendingPayments(state, action: PayloadAction<ErrorCode>) {
      state.pendingPaymentsError = action.payload;
    },
    savePreviousPayments(
      state,
      action: PayloadAction<RevSharePaymentsResponse>,
    ) {
      state.previousPayments = action.payload;
      state.previousPaymentsError = null;
    },
    setPreviousPaymentsLoading(state, action: PayloadAction<boolean>) {
      state.previousPaymentsLoading = action.payload;
    },
    errorFetchingPreviousPayments(state, action: PayloadAction<ErrorCode>) {
      state.previousPaymentsError = action.payload;
    },
    saveContributors(state, action: PayloadAction<RevSharePaymentResponse>) {
      state.contributors = action.payload;
      state.contributorsError = null;
    },
    setContributorsLoading(state, action: PayloadAction<boolean>) {
      state.contributorsLoading = action.payload;
    },
    errorFetchingContributors(state, action: PayloadAction<ErrorCode>) {
      state.contributorsError = action.payload;
    },
    saveAgentBadges(
      state,
      action: PayloadAction<AsyncResponse<AgentBadgesResponse>>,
    ) {
      state.agentBadgesResponse = action.payload;
      state.agentBadgeByName = (action.payload.data?.badges || []).reduce(
        (acc, curr) => {
          const name = curr.badge?.name;
          if (name) {
            acc[name] = curr;
          }
          return acc;
        },
        {} as Record<BadgeResponseNameEnum, AgentBadgeResponse>,
      );
    },
    saveGeneralBadges(
      state,
      action: PayloadAction<AsyncResponse<BadgesResponse>>,
    ) {
      state.generalBadgesResponse = action.payload;
      state.generalBadgeByName = (action.payload.data?.badges || []).reduce(
        (acc, curr) => {
          const name = curr.name;
          if (name) {
            acc[name] = curr;
          }
          return acc;
        },
        {} as Record<BadgeResponseNameEnum, BadgeResponse>,
      );
    },
    saveNetworkSizeByTier(
      state,
      action: PayloadAction<AsyncResponse<NetworkSizeByTierResponse>>,
    ) {
      state.networkSizeByTierResponse = action.payload;
    },
    saveRevShareByTier(
      state,
      action: PayloadAction<AsyncResponse<RevshareByTierResponse>>,
    ) {
      state.revShareByTierResponse = action.payload;
    },
    saveRevShareHistory(
      state,
      action: PayloadAction<AsyncResponse<RevShareHistoryResponse>>,
    ) {
      state.revshareHistoryResponse = action.payload;
    },
  },
});

export const {
  savePreviousPayments,
  setPreviousPaymentsLoading,
  errorFetchingPreviousPayments,
  errorFetchingContributors,
  saveContributors,
  setContributorsLoading,
  errorFetchingPendingPayments,
  setPendingPaymentsLoading,
  savePendingPaymentsOverview,
  saveNetworkSizeByTier,
  saveRevShareByTier,
  saveRevShareHistory,
  saveAgentBadges,
  saveGeneralBadges,
} = RevenueSlice.actions;

export const getPendingRevenueSharePaymentsOverview = (
  yentaId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(setPendingPaymentsLoading(true));
  try {
    const { data } = await new RevShareControllerApi(
      await getArrakisConfiguration(),
    ).getPendingPaymentPreviewForAgent(yentaId);

    dispatch(savePendingPaymentsOverview(data));
  } catch (e) {
    dispatch(errorFetchingContributors(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had trouble fetching your pending overview for revenue payment information. Please try again in a few moments.',
      ),
    );
    ErrorService.notify(
      'unable to fetch pending overview revenue payment information',
      e,
      {
        agent: { agentYentaId: yentaId },
      },
    );
  } finally {
    dispatch(setPendingPaymentsLoading(false));
  }
};

export const getPreviousRevenueSharePayments = (
  yentaId: string,
  pageNumber: number,
  pageSize: number,
  showLoading: boolean = true,
): AppThunk<Promise<RevSharePaymentsResponse | undefined>> => async (
  dispatch,
) => {
  if (showLoading) {
    dispatch(setPreviousPaymentsLoading(true));
  }

  try {
    const { data } = await new RevShareControllerApi(
      await getArrakisConfiguration(),
    ).getRevSharePaymentsForAgent(yentaId, pageNumber, pageSize);

    dispatch(savePreviousPayments(data));

    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(errorFetchingPreviousPayments(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had trouble fetching your revenue payment information. Please try again in a few moments.',
      ),
    );
    ErrorService.notify(
      'unable to fetch previous revenue payment information',
      e,
      {
        agent: { agentYentaId: yentaId },
      },
    );
  } finally {
    dispatch(setPreviousPaymentsLoading(false));
  }

  return undefined;
};

export const getOutgoingRevenueSharePayments = (
  yentaId: string,
  outgoingPaymentId: string,
  pageNumber: number,
  pageSize: number,
  showLoading: boolean = true,
): AppThunk<Promise<RevSharePaymentResponse | undefined>> => async (
  dispatch,
) => {
  if (showLoading) {
    dispatch(setContributorsLoading(true));
  }

  try {
    const { data } = await new RevShareControllerApi(
      await getArrakisConfiguration(),
    ).getRevSharePaymentsForAgent1(
      yentaId,
      outgoingPaymentId,
      pageNumber,
      pageSize,
    );
    dispatch(saveContributors(data));

    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(errorFetchingContributors(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had trouble fetching your revenue payment information. Please try again in a few moments.',
      ),
    );
    ErrorService.notify('unable to fetch revenue payment contributors', e, {
      agent: { agentYentaId: yentaId },
    });
  } finally {
    dispatch(setContributorsLoading(false));
  }

  return undefined;
};

export const exportPendingRevenueSharePayments = (
  yentaId: string,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new RevShareControllerApi(
      await getArrakisConfiguration(),
    ).getPendingPaymentExportForAgent(yentaId);
    return data;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We are unable to export the file. Please try again in a few moments.',
      ),
    );
    ErrorService.notify(
      'unable to fetch csv for pending revenue share payments',
      e,
      {
        agent: { agentYentaId: yentaId },
      },
    );
  }
  return undefined;
};

export const exportAllRevenueSharePaymentsOfAgent = (
  yentaId: string,
  outgoingPaymentId: string,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new RevShareControllerApi(
      await getArrakisConfiguration(),
    ).getRevSharePaymentExportForAgent(yentaId, outgoingPaymentId);
    return data;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We are unable to export the file. Please try again in a few moments.',
      ),
    );
    ErrorService.notify(
      'unable to fetch csv for contributions for revenue share payment',
      e,
      {
        agent: { agentYentaId: yentaId },
      },
    );
  }
  return undefined;
};

export const fetchPendingRevenueSharePayments = (
  yentaId: string,
  pageNumber: number,
  pageSize: number,
  showLoading: boolean = false,
): AppThunk<Promise<RevSharePaymentResponse | undefined>> => async (
  dispatch,
) => {
  if (showLoading) {
    dispatch(setContributorsLoading(true));
  }

  try {
    const { data } = await new RevShareControllerApi(
      await getArrakisConfiguration(),
    ).getPendingPaymentForAgent(yentaId, pageNumber, pageSize);
    dispatch(saveContributors(data));

    return data;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We were unable to fetch information on your pending revenue share payments',
      ),
    );
    ErrorService.notify(
      'Unable to fetch information on pending revshare payments',
      e,
      {
        agent: { id: yentaId },
      },
    );
  } finally {
    dispatch(setContributorsLoading(false));
  }

  return undefined;
};

export const fetchRevenueSharePaymentsForTable = (
  yentaId: string,
  pageNumber: number,
  pageSize: number,
  pendingPaymentsOverview: RevSharePaymentPreviewResponse | null,
  showLoading: boolean = true,
): AppThunk<Promise<IPaginateRes<RevSharePaymentPreviewResponse>>> => async (
  dispatch,
) => {
  const paymentResponse = await dispatch(
    getPreviousRevenueSharePayments(yentaId, pageNumber, pageSize, showLoading),
  );

  let data;
  if (pendingPaymentsOverview) {
    data = [pendingPaymentsOverview, ...(paymentResponse?.payments || [])];
  } else {
    data = paymentResponse?.payments || [];
  }

  const tableData: IPaginateRes<RevSharePaymentPreviewResponse> = {
    data,
    total: (paymentResponse?.totalCount ?? 0) + 1,
  };

  return tableData;
};

export const fetchContributionsForOutgoingPaymentForTable = (
  yentaId: string,
  outgoingPaymentId: string,
  pageNumber: number,
  pageSize: number,
  showLoading: boolean = true,
): AppThunk<Promise<IPaginateRes<RevSharePaymentResponse>>> => async (
  dispatch,
) => {
  const paymentResponse = await dispatch(
    getOutgoingRevenueSharePayments(
      yentaId,
      outgoingPaymentId,
      pageNumber,
      pageSize,
      showLoading,
    ),
  );

  const tableData: IPaginateRes<RevSharePaymentResponse> = {
    data: paymentResponse?.contributions || [],
    total: (paymentResponse?.totalCount ?? 0) + 1,
  };

  return tableData;
};

export const fetchPendingContributionsForTable = (
  yentaId: string,
  pageNumber: number,
  pageSize: number,
  showLoading: boolean = true,
): AppThunk<Promise<IPaginateRes<RevShareContributionResponse>>> => async (
  dispatch,
) => {
  const paymentResponse = await dispatch(
    fetchPendingRevenueSharePayments(
      yentaId,
      pageNumber,
      pageSize,
      showLoading,
    ),
  );

  const tableData: IPaginateRes<RevShareContributionResponse> = {
    data: paymentResponse?.contributions || [],
    total: paymentResponse?.totalCount ?? 0,
  };

  return tableData;
};

export const fetchRevShareInsightsBadges = (
  agentId: string,
): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const fetchAgentBadges = () =>
    new BadgeApi(getInsigniaConfiguration()).getAgentBadges1(agentId);
  const fetchGeneralBadges = () =>
    new BadgeApi(getInsigniaConfiguration()).getAgentBadges();
  const requests = [
    performAsyncRequest(
      dispatch as AppDispatch,
      'agent badge',
      saveAgentBadges,
      fetchAgentBadges,
    ),
  ];
  if (isEmpty(getState().revenue.generalBadgesResponse.data)) {
    requests.push(
      performAsyncRequest(
        dispatch as AppDispatch,
        'badge',
        saveGeneralBadges,
        fetchGeneralBadges,
      ),
    );
  }
  await Promise.all(requests);
};

export const fetchNetworkSizeByTier = (
  agentId: string,
  asOfDate: string,
): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const { networkSizeByTierResponse } = getState().revenue;
  const fetchNetworkSize = async () =>
    new AgentControllerApi(getYentaConfiguration()).getNetworkSizeByTier(
      agentId,
      asOfDate,
    );
  await performAsyncRequest(
    dispatch as AppDispatch,
    'network size by tier',
    saveNetworkSizeByTier,
    fetchNetworkSize,
    { changeLoading: !networkSizeByTierResponse.data },
  );
};

export const fetchRevShareByTier = (
  agentId: string,
  startDate?: string,
  endDate?: string,
): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const { revShareByTierResponse } = getState().revenue;
  const fetchRevShare = async () =>
    new RevShareControllerApi(getArrakisConfiguration()).getRevshareByTier(
      agentId,
      startDate,
      endDate,
    );
  await performAsyncRequest(
    dispatch as AppDispatch,
    'revenue share by tier',
    saveRevShareByTier,
    fetchRevShare,
    { changeLoading: !revShareByTierResponse.data },
  );
};

export const fetchRevShareHistory = (
  agentId: string,
  startDate: string,
  endDate: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  const fetchHistory = () =>
    new RevShareControllerApi(getArrakisConfiguration()).getRevshareHistory(
      agentId,
      startDate,
      endDate,
    );
  await performAsyncRequest(
    dispatch as AppDispatch,
    'revenue share history',
    saveRevShareHistory,
    fetchHistory,
  );
};

export default RevenueSlice.reducer;
