import { faUserShield } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGemini } from '../../../hooks/useGemini';
import { IdentitySummaryResponse } from '../../../openapi/keymaker';
import { fetchIdentityByKeymakerId } from '../../../slices/AuthSlice';
import { getGrantorRoles } from '../../../slices/RoleSlice';
import { RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { getOnlyGrantorRoles } from '../../../utils/SuperAdminUtils';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import ResourceContainer from '../../ResourceContainer';
import ZenAction from '../../Zen/ZenAction';
import ZenCardWithItems from '../../Zen/ZenCardWithItems';
import ZenAgentManageRolesSideBarModal from './ZenAgentManageRolesSidebarModal';

interface ZenAgentRolesProps {}

const ZenAgentRoles: React.FC<ZenAgentRolesProps> = () => {
  const dispatch = useDispatch();
  const isGeminiEnabled = useGemini();

  const {
    auth: { identityByKeymakerIdResponse, keymakerCurrentUser },
    roles: { grantorRolesResponse },
    agentDetail: {
      detailResponse: { data: agentDetailResponse },
    },
  } = useSelector((state: RootState) => state);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const agentLoginDetails:
    | IdentitySummaryResponse
    | undefined = identityByKeymakerIdResponse.data![
    agentDetailResponse?.keyMakerId!
  ];

  const currentUserGrantorRoles = getOnlyGrantorRoles(
    keymakerCurrentUser?.activeRoles || [],
    grantorRolesResponse.data || [],
  );

  const isGrantor = !!currentUserGrantorRoles?.length;

  useEffect(() => {
    if (!grantorRolesResponse.data?.length) {
      dispatch(getGrantorRoles());
    }
  }, [dispatch, grantorRolesResponse.data?.length]);

  useEffect(() => {
    if (!agentLoginDetails) {
      dispatch(fetchIdentityByKeymakerId(agentDetailResponse?.keyMakerId!));
    }
  }, [agentLoginDetails, agentDetailResponse?.keyMakerId, dispatch]);

  return (
    <AuthorizationContainer asyncResponse={identityByKeymakerIdResponse}>
      <ResourceContainer
        loading={!agentLoginDetails && identityByKeymakerIdResponse.loading}
        isEmpty={!agentLoginDetails}
        resourceName='Roles'
      >
        <ZenCardWithItems
          title='My Roles'
          RightComponent={
            isGrantor ? (
              <ZenAction
                text='Manage Roles'
                icon={
                  <FontAwesomeIcon
                    icon={faUserShield}
                    className={cn(
                      isGeminiEnabled
                        ? 'font-light text-[13px] leading-[23px] text-rezen-blue-600'
                        : 'text-sm text-primary-blue',
                    )}
                  />
                }
                onClick={() => setIsOpenModal(true)}
              />
            ) : (
              <div />
            )
          }
          items={
            agentLoginDetails?.activeRoles
              ? agentLoginDetails?.activeRoles?.map((roleData) => {
                  return {
                    name: capitalizeEnum(roleData.role!),
                    value: DateTime.fromMillis(roleData.grantedAt!).toFormat(
                      'LL/dd/yyyy',
                    ),
                  };
                })
              : []
          }
        />
        {isOpenModal && (
          <ZenAgentManageRolesSideBarModal
            onClose={() => setIsOpenModal(false)}
            agentId={agentLoginDetails?.id!}
          />
        )}
      </ResourceContainer>
    </AuthorizationContainer>
  );
};

export default ZenAgentRoles;
