import { cn } from '../../../utils/classUtils';

export interface GeminiJourneyProgressCellProps {
  progress: number;
}

const GeminiJourneyProgressCell: React.FC<GeminiJourneyProgressCellProps> = ({
  progress,
}) => {
  return (
    <div className='flex lg:flex-row flex-wrap flex-col lg:items-center items-start'>
      <div className='text-regent-600 font-zen-body-2 text-xs font-semibold uppercase shrink-0 lg:mr-1.5 max-w-full flex-1 truncate'>
        Road to success
      </div>
      <div className='flex items-center flex-shrink-0'>
        <div className='h-2.5 w-44 mr-2 flex items-center rounded-sm bg-regent-300 overflow-hidden'>
          <div
            className={cn(
              'h-full transition-all ease-in-out duration-300',
              progress === 100 ? 'bg-green-600' : 'bg-avatar-blue-600',
            )}
            style={{
              width: `${progress}%`,
            }}
          />
        </div>
        <div className='text-primary-dark font-zen-body-2 text-sm font-normal'>
          {progress}%
        </div>
      </div>
    </div>
  );
};

export default GeminiJourneyProgressCell;
