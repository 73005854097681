interface IConfig {
  debug: boolean;
  reactAppHostUrl: string;
  assetsBaseUrl: string;
  gaTrackingId: string;
  stripe: {
    publishableKey: string;
  };
  datadog: {
    applicationId: string;
    releaseStage: string;
    clientToken: string;
    deployVersion: string;
    sessionSampleRate: number;
    sessionReplaySampleRate: number;
  };
  yentaImageBaseUrl: string;
  mercuryAssetsBaseUrl: string;
  apiBasePath: {
    yenta: string;
    arrakis: string;
    keymaker: string;
    hermes: string;
    wanderer: string;
    dali: string;
    sherlock: string;
    yada: string;
    memento: string;
    bff: string;
    dropbox: string;
    avalon: string;
    mercury: string;
    leo: string;
    atlantis: string;
    signature: string;
    plutus: string;
    insignia: string;
    wallet: string;
    sirius: string;
  };
  amplitude: {
    apiKey: string;
  };
  deployVersion: string;
  gMapsAPIKey: string;
  googleAnalyticsMeasurementId: string;
  joinRealEmailDomain: string;
  daliAuthToken: string;
  giphyApiKey: string;
  calendarImageUrl: string;
  launchNotes: {
    projectId: string;
    projectToken: string;
  };
  realSignatureBaseUrl: string;
  ci: boolean;
  googleSSOClientId: string;
}

const config: IConfig = {
  debug: process.env.REACT_APP_DEBUG! === 'true',
  reactAppHostUrl: process.env.REACT_APP_HOST_URL!,
  assetsBaseUrl: process.env.REACT_APP_ASSETS_BASE_URL!,
  gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID!,
  stripe: {
    publishableKey: process.env.REACT_APP_STRIPE_KEY!,
  },
  datadog: {
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID!,
    releaseStage: process.env.REACT_APP_DATADOG_RELEASE_STAGE!,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN!,
    deployVersion: process.env.REACT_APP_DEPLOY_VERSION!,
    sessionSampleRate: parseInt(
      process.env.REACT_APP_DATADOG_SESSION_SAMPLE_RATE!,
      10,
    ),
    sessionReplaySampleRate: parseInt(
      process.env.REACT_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE!,
      10,
    ),
  },
  yentaImageBaseUrl: process.env.REACT_APP_YENTA_IMAGE_BASE_URL!,
  mercuryAssetsBaseUrl: process.env.REACT_APP_MERCURY_ASSETS_BASE_URL!,
  apiBasePath: {
    yenta: process.env.REACT_APP_YENTA_API_BASE!,
    arrakis: process.env.REACT_APP_ARRAKIS_API_BASE!,
    keymaker: process.env.REACT_APP_KEYMAKER_API_BASE!,
    hermes: process.env.REACT_APP_HERMES_API_BASE!,
    wanderer: process.env.REACT_APP_WANDERER_API_BASE!,
    dali: process.env.REACT_APP_DALI_API_BASE!,
    sherlock: process.env.REACT_APP_SHERLOCK_API_BASE!,
    yada: process.env.REACT_APP_YADA_API_BASE!,
    memento: process.env.REACT_APP_MEMENTO_API_BASE!,
    bff: process.env.REACT_APP_BFF_API_BASE!,
    dropbox: process.env.REACT_APP_DROPBOX_API_BASE!,
    avalon: process.env.REACT_APP_AVALON_API_BASE!,
    mercury: process.env.REACT_APP_MERCURY_API_BASE!,
    leo: process.env.REACT_APP_LEO_API_BASE!,
    atlantis: process.env.REACT_APP_ATLANTIS_API_BASE!,
    signature: process.env.REACT_APP_SIGNATURE_API_BASE!,
    plutus: process.env.REACT_APP_PLUTUS_API_BASE!,
    insignia: process.env.REACT_APP_INSIGNIA_API_BASE!,
    wallet: process.env.REACT_APP_WALLET_API_BASE!,
    sirius: process.env.REACT_APP_SIRIUS_API_BASE!,
  },
  amplitude: {
    apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY!,
  },
  deployVersion: process.env.REACT_APP_DEPLOY_VERSION!,
  gMapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  googleAnalyticsMeasurementId: process.env
    .REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID!,
  joinRealEmailDomain: process.env.REACT_APP_JOIN_REAL_EMAIL_DOMAIN!,
  daliAuthToken: process.env.REACT_APP_DALI_AUTH_TOKEN!,
  giphyApiKey: process.env.REACT_APP_GIPHY_API_KEY!,
  calendarImageUrl: process.env.REACT_APP_CALENDAR_EVENT_IMAGE_BASE_URL!,
  launchNotes: {
    projectId: process.env.REACT_APP_LAUNCHNOTES_PROJECT_ID!,
    projectToken: process.env.REACT_APP_LAUNCHNOTES_PROJECT_TOKEN!,
  },
  realSignatureBaseUrl: process.env.REACT_APP_SIGNATURE_BASE_PATH!,
  ci: process.env.REACT_APP_CI! === 'true',
  googleSSOClientId: process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID!,
};

export default config;
