import { ApiKeyResponse } from '../../../openapi/keymaker';
import AgentClientApiKeyTabCards from './AgentClientApiKeyTabCards';
import AgentGenerateKeyBox from './AgentGenerateKeyBox';

interface AgentClientApiKeyTabsProps {
  currentTab: string;
  handleOpenGenerateApiKeyModal(): void;
  handleOpenRevokeApiModal(apiKey: ApiKeyResponse): void;
  tabData: ApiKeyResponse[];
}

const AgentClientApiKeyTabs: React.FC<AgentClientApiKeyTabsProps> = ({
  currentTab,
  handleOpenGenerateApiKeyModal,
  handleOpenRevokeApiModal,
  tabData,
}) => {
  return (
    <div className='py-8'>
      {tabData.length > 0 &&
        tabData.map((apiKey) => (
          <div key={apiKey?.value} className='pb-6'>
            <AgentClientApiKeyTabCards
              currentTab={currentTab}
              cardData={apiKey}
              handleOpenRevokeApiModal={handleOpenRevokeApiModal}
            />
          </div>
        ))}
      {tabData.length === 0 && (
        <div className='pt-8 pb-2 px-4 border rounded-xl'>
          <AgentGenerateKeyBox
            currentTab={currentTab}
            onClick={handleOpenGenerateApiKeyModal}
          />
        </div>
      )}
    </div>
  );
};

export default AgentClientApiKeyTabs;
