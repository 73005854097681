import { useParams } from 'react-router-dom';
import FormBasedOnType from '../components/FormBasedOnType';
import ZenRoute from '../components/Zen/ZenRoute';
import RenderApplicationFormForPDF from '../containers/RenderApplicationFormForPDF';
import { getFormType } from '../utils/FormUtils';

type Match = {
  id: string;
};

const ICASignedPdfRoute: React.FC = () => {
  const { id: applicationId } = useParams() as Match;

  return (
    <ZenRoute title='ICA Signed PDF'>
      <RenderApplicationFormForPDF applicationId={applicationId}>
        {(application) => (
          <FormBasedOnType
            formType={getFormType(application?.country!)}
            onSubmit={() => {}}
            application={application!}
          />
        )}
      </RenderApplicationFormForPDF>
    </ZenRoute>
  );
};

export default ICASignedPdfRoute;
