import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgentResponse } from '../../openapi/yenta';
import { updateSingleCheckPreference } from '../../slices/AgentSlice';
import { AppDispatch, RootState } from '../../types';
import { cn } from '../../utils/classUtils';
import { getSupportEmail } from '../../utils/SupportUtils';
import AdminOnly from '../auth/AdminOnly';
import AgentOnly from '../auth/AgentOnly';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../Zen/ZenButton';
import OptInSingleCheck from './OptInSingleCheck';
import OptOutSingleCheck from './OptOutSingleCheck';

interface WarningMessageProps {
  agentDetails?: AgentResponse;
  variant?: 'light';
}

const WarningMessage: React.FC<WarningMessageProps> = ({
  agentDetails,
  variant,
}) => {
  return (
    <div
      className={cn(
        'bg-zen-warning-1 flex items-center mt-4 rounded-md w-fit',
        variant === 'light' ? 'px-6 py-4' : 'py-3 px-4',
      )}
    >
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        fontSize={18}
        className='mr-4'
      />
      <p
        className={cn(
          'font-inter text-base text-primary-dark',
          variant === 'light'
            ? 'text-grey-600 font-light'
            : 'text-primary-dark',
        )}
        data-testid='opt-warning-message'
      >
        If you decide to proceed, you will only be able to opt-out by contacting{' '}
        <a
          href={`mailto:${getSupportEmail(agentDetails?.accountCountry)}`}
          className={cn(
            'text-primary-blue underline underline-offset-4',
            variant === 'light' ? 'font-normal' : 'font-medium',
          )}
        >
          {getSupportEmail(agentDetails?.accountCountry)}
        </a>
        {variant === 'light' && '.'}
      </p>
    </div>
  );
};

interface OptSingleCheckProps {}

const OptSingleCheck: React.FC<OptSingleCheckProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    auth: { isAdmin },
    agentDetail: {
      detailResponse: { data: agentDetails },
    },
  } = useSelector((state: RootState) => state);

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const isPrefersSingleCheck = agentDetails?.prefersSingleCheck;

  const handleOnConfirm = async () => {
    setLoading(true);
    const response = await dispatch(
      updateSingleCheckPreference(agentDetails?.id!, {
        prefersSingleCheck: !isPrefersSingleCheck,
      }),
    );
    if (response) {
      setIsOpen(false);
    }
    setLoading(false);
  };

  const offices = useMemo(
    () =>
      agentDetails?.offices
        ?.slice()
        .sort((a, b) => a.name?.localeCompare(b.name!)!),
    [agentDetails?.offices],
  );

  return (
    <div className='my-4 mx-4 md:mx-5'>
      <div className='flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center'>
        <p
          className={classNames(
            'font-inter font-light text-base text-primary-dark',
            { 'md:w-9/12': isAdmin || !isPrefersSingleCheck },
          )}
        >
          {!isPrefersSingleCheck ? (
            'Opt-in to be paid electronically via direct deposit for all commissions (all your commissions will be sent to Real and Real will pay you via direct deposit using the information you provided below).'
          ) : (
            <span data-testid='opt-out-text'>
              You have opted in to receive commissions via direct deposit. To
              opt-out, please contact{' '}
              <a
                href={`mailto:${getSupportEmail(agentDetails?.accountCountry)}`}
                className='text-primary-blue underline underline-offset-4'
              >
                {getSupportEmail(agentDetails?.accountCountry)}
              </a>
              .
            </span>
          )}
        </p>

        {!isPrefersSingleCheck && (
          <div className='w-full md:w-auto ml-auto'>
            <ZenButton
              label='Opt-in to Single Check'
              textSize='md'
              variant='primary-outline'
              className='!font-inter !text-base !font-medium'
              isFullWidth
              onClick={() => setIsOpen(true)}
            />
          </div>
        )}

        <AdminOnly>
          {isPrefersSingleCheck && (
            <div className='w-full md:w-auto ml-auto'>
              <ZenButton
                label='Opt-out of Single Check'
                textSize='md'
                variant='primary-outline'
                className='!font-inter !text-base !font-medium'
                isFullWidth
                onClick={() => setIsOpen(true)}
              />
            </div>
          )}
        </AdminOnly>
      </div>

      {!isPrefersSingleCheck && (
        <AgentOnly>
          <WarningMessage agentDetails={agentDetails} />
        </AgentOnly>
      )}

      {isOpen && (
        <ZenConfirmationModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title={`Please confirm Single Check payment opt-${
            !isPrefersSingleCheck ? 'in' : 'out'
          }?`}
          hideFooterButtons
          containerClassNames='w-full max-w-[600px]'
          titleClassName='font-inter text-base font-bold'
        >
          <div className='m-4 md:mx-5 mb-8 max-h-[30rem] overflow-auto'>
            <p className='md:px-4 text-zen-dark-13 font-inter font-normal mb-4'>
              {!isPrefersSingleCheck
                ? 'By opting-in, you will be paid via single-check for all new transactions and transactions where the Commission Document has not yet been generated for the following office(s):'
                : 'The agent will be opted-out of Single Check payments for all new transactions and transactions where the Commission Document has not yet been generated for the following office(s):'}
            </p>
            {offices?.map((office) => (
              <div
                key={office.id}
                className='border-b px-4 py-3 flex items-center justify-between'
              >
                <p className='text-sm font-zen-body text-zen-dark-7 font-normal'>
                  {office.name}
                </p>
                <div className='flex'>
                  {!isPrefersSingleCheck ? (
                    <OptInSingleCheck office={office} />
                  ) : (
                    <OptOutSingleCheck office={office} />
                  )}
                </div>
              </div>
            ))}

            <AgentOnly>
              <div className='pt-2.5'>
                <WarningMessage agentDetails={agentDetails} variant='light' />
              </div>
            </AgentOnly>
          </div>

          <div className='px-4 flex flex-col md:flex-row my-3 md:space-x-4 space-y-3 md:space-y-0 md:justify-center'>
            <div className='md:w-36'>
              <ZenButton
                isFullWidth
                variant='secondary-outline'
                label='Cancel'
                onClick={() => setIsOpen(false)}
                className='!font-inter !text-base !font-medium !text-zen-dark-13'
              />
            </div>
            <div className='md:w-36'>
              <ZenButton
                isFullWidth
                label='Confirm'
                variant='primary'
                onClick={handleOnConfirm}
                type='button'
                isDisabled={loading}
                isSubmitting={loading}
                className='!font-inter !text-base !font-medium'
              />
            </div>
          </div>
        </ZenConfirmationModal>
      )}
    </div>
  );
};

export default OptSingleCheck;
