import { values } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import Button from '../components/Button';
import ConfirmationModal from '../components/ConfirmationModal';
import ZenSidebarModalActionFooter from '../components/SidebarModal/ZenSideBarModalActionFooter';
import ZenControlledDatePickerInput from '../components/Zen/Input/ZenControlledDatePickerInput';
import ZenControlledMoneyInput from '../components/Zen/Input/ZenControlledMoneyInput';
import ZenControlledSelectInput from '../components/Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenSidebarModal from '../components/Zen/ZenSidebarModal';
import {
  AgentCreditControllerApi,
  CreditResponseTypeEnum,
} from '../openapi/arrakis';
import {
  AgentFlexTeamMembershipResponseTeamTypeEnum,
  AgentResponse,
  MoneyValue,
  MoneyValueCurrencyEnum,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { setTaxAlertData } from '../slices/TaxSlice';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { ISelectOption } from '../types';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../utils/StringUtils';
import { MONEY_VALUE_ALLOW_NEGATIVE_VALIDATIONS } from '../utils/Validations';
import { isTeammate } from '../utils/ProTeams/ProTeamsHelper';
import ZenAlert from '../components/Zen/ZenAlert';

interface ZenAddAgentCreditFormSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  agent: AgentResponse;
  showOnlyTaxTypes?: boolean;
  refreshCredits?(): void;
}

interface FormValues {
  type: ISelectOption;
  title: string;
  number: number;
  amount: MoneyValue;
  issuedOn: string;
  issuerNote: string;
  team: ISelectOption;
}

const ZenAddAgentCreditFormSidebarModal: React.FC<ZenAddAgentCreditFormSidebarModalProps> = ({
  isOpen,
  onClose,
  agent,
  showOnlyTaxTypes,
  refreshCredits,
}) => {
  const dispatch = useDispatch();

  const typeOptions = !showOnlyTaxTypes
    ? values(CreditResponseTypeEnum).map((type) => ({
        value: type,
        label: capitalizeEnum(type),
      }))
    : [
        {
          value: CreditResponseTypeEnum.TaxDocumentCommission,
          label: capitalizeEnum(CreditResponseTypeEnum.TaxDocumentCommission),
        },
        {
          value: CreditResponseTypeEnum.TaxDocumentRevshare,
          label: capitalizeEnum(CreditResponseTypeEnum.TaxDocumentRevshare),
        },
      ];

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    shouldUnregister: true,
    defaultValues: {
      amount: {
        currency: (agent.defaultCurrency as unknown) as MoneyValueCurrencyEnum,
      },
    },
  });

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const onSubmit = async (data: FormValues) => {
    const finalData = {
      title: data.title,
      issuerNote: data.issuerNote,
      issuedOn: data.issuedOn,
      amount: data.amount,
      number: data.number,
      teamId: data.team?.value,
    };

    try {
      switch (data.type?.value) {
        case CreditResponseTypeEnum.BrokerageFee:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueBrokerageFeeCredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.Cap:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueCapCredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.CumulativeGci:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueCumulativeGCICredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.NumberOfExecutedTransactions:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueNumberOfExecutedTransactionsCredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.NumberOfPersonalTransactions:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueNumberOfPersonalTransactionsCredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.NumberOfExecutedEliteTransactions:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueNumberOfExecutedEliteTransactionsCredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.PostCapFee:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issuePostCapFeeCredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.PostCapEquityPurchase:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issuePostCapEquityPurchaseCredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.PreCapEquityPurchase:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issuePreCapEquityPurchaseCredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.Revshare:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueRevShareCredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.NetCommission:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueNetCommissionCredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.TaxDocumentRevshare:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueTaxDocumentRevShareCredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.TaxDocumentCommission:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueTaxDocumentCommissionCredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.BeopFee:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueBeopFeeCredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.PersonalDealFee:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issuePersonalDealFeeCredit(agent.id!, finalData);
          break;
        case CreditResponseTypeEnum.TeamCap:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueTeamCapCredit(agent.id!, finalData);
          break;
        default:
          setIsErrorModalOpen(true);
          throw new Error('error');
      }
      onClose();
      if (refreshCredits) {
        refreshCredits();
      }
      dispatch(showSuccessToast('Credit added successfully.'));
      dispatch(
        setTaxAlertData({
          description: `A ${capitalizeEnum(
            data.type.label,
          )} credit has been added. 
        See Credits table for more details.`,
          taxFormState: 'SUCCESS',
          title: 'AGENT CREDIT ADDED',
        }),
      );
    } catch (e) {
      dispatch(showApiErrorModal(e));
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to create the agent credit.',
          ErrorService.getErrorCode(e),
        ),
      );
      ErrorService.notify('Error adding agent credit', e, {
        addAgentCredit: {
          agentYentaId: agent.id!,
          req: data,
        },
      });
    }
  };

  const partOfProTeam = agent.flexTeamMemberships?.some(
    (team) => team.teamType === AgentFlexTeamMembershipResponseTeamTypeEnum.Pro,
  )!;
  const flexTeamOptions = (agent.flexTeamMemberships || [])
    .filter((team) => isTeammate(agent.id!, team))
    .filter(
      (team) =>
        team.teamType === AgentFlexTeamMembershipResponseTeamTypeEnum.Pro,
    )
    .map((team) => ({
      value: team.teamId ?? '',
      label: team.teamName ?? '',
    }));

  const type = watch('type');

  const isShowNumberInput = (type: string) => {
    return [
      CreditResponseTypeEnum.NumberOfPersonalTransactions,
      CreditResponseTypeEnum.NumberOfExecutedTransactions,
      CreditResponseTypeEnum.NumberOfExecutedEliteTransactions,
    ].includes(type as any);
  };

  return (
    <ZenSidebarModal
      title='Add an Agent Credit'
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='add-agent-credit-form'
      >
        <div className='p-4'>
          {type?.value === CreditResponseTypeEnum.TeamCap &&
            partOfProTeam &&
            (flexTeamOptions.length === 0 ? (
              <div className='mb-4'>
                <ZenAlert
                  variant='warning'
                  text='This agent is an owner of a Pro Team. You can only add team cap to teammates'
                />
              </div>
            ) : (
              !partOfProTeam && (
                <div className='mb-4'>
                  <ZenAlert
                    variant='warning'
                    text='This agent does not belong to any Pro Teams.'
                  />
                </div>
              )
            ))}
          <div>
            <ZenControlledSelectInput<FormValues, 'type'>
              name='type'
              control={control}
              label='Type'
              placeholder='Type'
              options={typeOptions}
              rules={{
                required: 'Type is Required',
              }}
              isRequired
            />
          </div>
          {type?.value === CreditResponseTypeEnum.TeamCap && (
            <div className='mt-5'>
              <ZenControlledSelectInput<FormValues, 'team'>
                name='team'
                control={control}
                label='Team'
                placeholder='Team'
                options={flexTeamOptions}
                rules={{
                  required: 'Team is Required',
                }}
                isRequired
              />
            </div>
          )}
          <div className='mt-5'>
            <ZenControlledTextInput<FormValues, 'title'>
              control={control}
              label='Issuer Title'
              name='title'
              rules={{
                required: 'Issuer title is required',
              }}
              isRequired
            />
          </div>
          {isShowNumberInput(type?.value) ? (
            <div className='mt-5'>
              <ZenControlledTextInput<FormValues, 'number'>
                control={control}
                label='Number'
                name='number'
                rules={{
                  required: 'number is required',
                }}
                isRequired
              />
            </div>
          ) : (
            <div className='mt-5'>
              <ZenControlledMoneyInput<FormValues, 'amount'>
                label='Credit Amount'
                control={control}
                name='amount'
                rules={MONEY_VALUE_ALLOW_NEGATIVE_VALIDATIONS}
                isRequired
              />
            </div>
          )}
          <div className='mt-5'>
            <ZenControlledDatePickerInput<FormValues, 'issuedOn'>
              name='issuedOn'
              control={control}
              label='Issued On'
              rules={{
                required: 'Issued on date is Required',
              }}
              isRequired
            />
          </div>
          <div className='pb-20 mt-5'>
            <ZenControlledTextInput<FormValues, 'issuerNote'>
              control={control}
              label='Issuer Note'
              name='issuerNote'
              rules={{
                required: 'Issuer note is required',
              }}
              isRequired
            />
          </div>
        </div>
        <ZenSidebarModalActionFooter
          onClose={onClose}
          isSubmitting={isSubmitting}
          submitButtonDisabled={isSubmitting}
          submitButtonText='Add'
        />
      </form>
      <ConfirmationModal
        variant='error'
        title='Unable to add an Agent Credit'
        subtitle={`${capitalizeEnum(
          type?.value || 'This Credit Type',
        )} is not yet supported, please try again later.`}
        isOpen={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
      >
        <div className='mt-4 space-x-4 d-flex flex-nowrap'>
          <Button
            label='Close'
            type='secondary'
            onClick={() => setIsErrorModalOpen(false)}
          />
        </div>
      </ConfirmationModal>
    </ZenSidebarModal>
  );
};

export default ZenAddAgentCreditFormSidebarModal;
