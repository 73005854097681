import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncDebounce } from 'react-table';
import {
  DEFAULT_PAGE_NUM,
  DEFAULT_PAGE_SIZE,
} from '../../../constants/ListPaginationConstants';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  AppDispatch,
  ErrorCode,
  IPaginateReq,
  IPaginateRes,
} from '../../../types';
import ZenListPaginate from '../ZenListPaginate';

interface ZenListPaginateContainerProps<D> {
  fetchData(req: IPaginateReq<D>): Promise<IPaginateRes<D>>;
  children(data: Array<D>): React.ReactNode;
  searchPlaceholder?: string;
  resourceName: string;
  initialLoading?: boolean;
}

const ZenListPaginateContainer = <D extends object>({
  fetchData,
  resourceName,
  searchPlaceholder,
  initialLoading = false,
  children,
}: ZenListPaginateContainerProps<D>) => {
  const dispatch = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState<null | ErrorCode>(null);
  const [search, setSearch] = useState<string | undefined>();
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(DEFAULT_PAGE_NUM);
  const [listItemsPaginateRes, setListItemsPaginateRes] = useState<
    IPaginateRes<D>
  >({ data: [], total: 0 });

  const debounceApiCall = useAsyncDebounce(async () => {
    setLoading(true);

    try {
      const res = await fetchData({ page, pageSize, search });

      setListItemsPaginateRes(res);
      setIsError(null);
    } catch (e) {
      ErrorService.notify('Unable to fetch data', e);
      setIsError(ErrorService.getErrorCode(e));

      dispatch(showApiErrorModal(e));
    } finally {
      setLoading(false);
    }
  }, 400);

  useEffect(() => {
    if (!initialLoading) {
      debounceApiCall();
    }
  }, [debounceApiCall, page, pageSize, search, initialLoading]);

  return (
    <ZenListPaginate
      listItems={listItemsPaginateRes.data}
      search={search}
      itemsToShow={pageSize}
      page={page}
      totalItems={listItemsPaginateRes.total}
      loading={loading}
      errorCode={isError}
      onSearch={setSearch}
      goToPage={setPage}
      updateTotalItemsToShow={setPageSize}
      searchPlaceholder={searchPlaceholder}
      resourceName={resourceName}
    >
      {children}
    </ZenListPaginate>
  );
};

export default ZenListPaginateContainer;
