import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import BackgroundCoverSvg from '../../../assets/icons/wealth-plan/bg-cover.svg';
import VideoIconSvg from '../../../assets/icons/wealth-plan/video-icon.svg';
import AnalyticsService from '../../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../../types';
import { REAL_ACADEMY_URL } from '../WealthManagementUtils';

export interface GotoRealAcademyCardProps {}

const GotoRealAcademyCard: React.FC<GotoRealAcademyCardProps> = () => {
  return (
    <div className='font-zen-body-2 rounded-xl bg-primary-navy shadow-zen-card'>
      <div
        className='flex items-center justify-center h-36'
        style={{
          backgroundImage: `url(${BackgroundCoverSvg})`,
          backgroundSize: '122px 87px',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <img src={VideoIconSvg} alt='video-icon' height='76px' width='74px' />
      </div>
      <div className='bg-white p-6 rounded-b-xl'>
        <p className='text-base text-black font-medium'>Real Academy</p>
        <p className='text-sm text-zen-gray-6 font-zen-body mt-2'>
          Collaborate. Learn. Thrive. We offer 200+ courses catering to every
          stage of your career.
        </p>
        <div
          onClick={() => {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.WEALTH_PLAN_OVERVIEW_SCREEN_GO_TO_REAL_ACADEMY,
            );
            window.open(REAL_ACADEMY_URL, '_blank');
          }}
          className='text-base font-medium text-primary-blue mt-4 cursor-pointer'
        >
          Go to Real Academy
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            className='text-sm font-normal ml-1.5'
          />
        </div>
      </div>
    </div>
  );
};

export default GotoRealAcademyCard;
