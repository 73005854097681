import Avatar from '../../Avatar';

interface ProfileImageCellProps {
  imageUrl?: string;
  name: string;
}

const ProfileImageCell: React.FC<ProfileImageCellProps> = ({
  imageUrl,
  name,
}) => {
  return (
    <div className='h-8 w-8'>
      <Avatar name={name} size='xs' imageUrl={imageUrl} />
    </div>
  );
};

export default ProfileImageCell;
