import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as EventEmptyIcon } from '../assets/img/eventEmptyState.svg';
import AuthorizationContainer from '../components/auth/AuthorizationContainer';
import DefaultLoader from '../components/DefaultLoader';
import PageLayout from '../components/PageLayout';
import ResourceContainer from '../components/ResourceContainer';
import ZenEditEventDetailsSidebarModal from '../components/Zen/Event/ZenEditEventDetailsSidebarModal';
import ZenEventDetailsSidebarModal from '../components/Zen/Event/ZenEventDetailsSidebarModal';
import ZenEvents from '../components/Zen/Event/ZenEvents';
import ZenEventsFilter from '../components/Zen/Event/ZenEventsFilter';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenSearchBar from '../components/Zen/ZenSearchBar';
import { CalendarEventResponse } from '../openapi/yenta';
import {
  fetchAllEvents,
  fetchCalendars,
  fetchEventDetail,
  saveEventDetail,
} from '../slices/CalendarSlice';
import { AppDispatch, RootState } from '../types';
import { isSmScreen } from '../utils/BrowserUtils';

type Match = {
  eventId: string;
};

const EventsRoute: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const location = useLocation();
  const { eventId } = useParams() as Match;

  const calendarIds = useMemo(() => {
    const { calendarIds } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as { calendarIds: string[] };

    return calendarIds;
  }, [location.search]);

  const {
    calendar: { calendars, eventDetail, allEventsResponse },
  } = useSelector((state: RootState) => state);
  const { loading, error, data: events } = allEventsResponse;
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEditEvent, setIsEditEvent] = useState<CalendarEventResponse>();

  const filterEvents = (events || []).filter((event) =>
    event.title?.toLowerCase().includes(search.toLowerCase()),
  );

  const handleFetchEventDetail = useCallback(async () => {
    if (eventId) {
      await dispatch(fetchEventDetail(eventId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchCalendars = useCallback(async () => {
    setIsLoading(true);
    await dispatch(fetchCalendars());
    await handleFetchEventDetail();
    setIsLoading(false);
  }, [dispatch, handleFetchEventDetail]);

  useEffect(() => {
    handleFetchCalendars();
  }, [handleFetchCalendars]);

  useEffect(() => {
    dispatch(fetchAllEvents(calendarIds));
  }, [calendarIds, dispatch]);

  if (isLoading) {
    return <DefaultLoader />;
  }

  return (
    <ZenRoute title='All Events'>
      <AuthorizationContainer asyncResponse={allEventsResponse}>
        <PageLayout
          path={[
            { title: 'Home', url: '/' },
            { title: 'All Events', url: '/all-events' },
          ]}
        >
          <div className='md:flex items-center justify-between md:p-8 p-4'>
            <div className='text-2xl font-zen-body text-zen-dark font-semibold'>
              All Events
            </div>
            <div className='flex flex-row items-center space-x-4 md:mt-0 mt-4 md:w-2/5'>
              <div className='md:w-3/4 flex flex-row justify-end'>
                <ZenSearchBar
                  value={search}
                  onChange={setSearch}
                  placeholder='Search by keywords or ID'
                  showTransition={!isSmScreen()}
                />
              </div>
              <div className='md:w-1/4 self-end md:self-start'>
                <ZenEventsFilter
                  calendars={calendars}
                  onSubmit={({ calendarIds }) =>
                    history.replace(
                      `/my-events?${qs.stringify({
                        calendarIds,
                      })}`,
                    )
                  }
                  calendarIds={calendarIds}
                />
              </div>
            </div>
          </div>
          <ResourceContainer
            loading={loading}
            isEmpty={!filterEvents.length}
            errorCode={error}
            LoaderComponent={
              <div className='flex items-center justify-center h-screen'>
                <DefaultLoader />
              </div>
            }
            resourceName='event'
            EmptyComponent={
              <div className='flex flex-col items-center justify-center py-10'>
                <EventEmptyIcon aria-label='empty-events' />
                <p className='font-zen-body font-semibold text-2xl text-zen-dark-7 text-center py-4'>
                  You have no events booked.
                </p>
                <p className='font-zen-body font-normal text-base text-zen-dark-7 text-center max-w-3xl'>
                  You have not RSVP&apos;d to any events just yet... Don&apos;t
                  miss out on amazing events with exclusive intel made for REAL
                  agents & brokers. Knowledge is power!
                </p>
              </div>
            }
          >
            <ZenEvents
              events={filterEvents}
              onOpen={(event) => dispatch(saveEventDetail(event))}
              onEdit={setIsEditEvent}
            />
          </ResourceContainer>
          {eventDetail && (
            <ZenEventDetailsSidebarModal
              isOpen={!!eventDetail}
              onClose={() => {
                dispatch(saveEventDetail(undefined));
                history.replace(
                  `/my-events?${qs.stringify({
                    calendarIds,
                  })}`,
                );
              }}
              event={eventDetail}
            />
          )}
          {isEditEvent && (
            <ZenEditEventDetailsSidebarModal
              isOpen={!!isEditEvent}
              onClose={() => setIsEditEvent(undefined)}
              event={isEditEvent}
            />
          )}
        </PageLayout>
      </AuthorizationContainer>
    </ZenRoute>
  );
};

export default EventsRoute;
