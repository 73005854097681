import { isNil } from 'lodash';
import { AgentStateOneOf, DisplayCondition } from '../../../openapi/mercury';
import {
  getFormRecipientStates,
  OTHER_ANNOUNCEMENT_RECIPIENT_CONDITION,
} from '../../../utils/AnnouncementUtils';
import AnnouncementRecipientAgentType from './AnnouncementRecipientAgentType';
import AnnouncementRecipientStatesStack from './AnnouncementRecipientStatesStack';

interface AnnouncementRecipientCellProps {
  recipient: DisplayCondition;
}

const AnnouncementRecipientCell: React.FC<AnnouncementRecipientCellProps> = ({
  recipient,
}) => {
  const agentTypeCondition = recipient?.conditions?.filter(
    (el) =>
      el?.$type !== OTHER_ANNOUNCEMENT_RECIPIENT_CONDITION.AgentStateOneOf,
  );
  const stateCondition = recipient?.conditions?.find(
    (el) =>
      el?.$type === OTHER_ANNOUNCEMENT_RECIPIENT_CONDITION.AgentStateOneOf,
  );

  // get states/countries from statesOrProvinces list
  const statesOrProvincesList = !isNil(stateCondition)
    ? getFormRecipientStates(
        (stateCondition as AgentStateOneOf)?.stateOrProvinces,
      )?.sops
    : undefined;

  return (
    <div className='flex flex-col'>
      {(agentTypeCondition || [])?.map((condition) => (
        <AnnouncementRecipientAgentType
          key={condition?.$type}
          agentTypeCondition={condition}
        />
      ))}
      <AnnouncementRecipientStatesStack
        statesOrProvincesList={statesOrProvincesList}
      />
    </div>
  );
};

export default AnnouncementRecipientCell;
