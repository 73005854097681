import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/pro-solid-svg-icons';
import { faCheck, faCopy } from '@fortawesome/pro-regular-svg-icons';
import {
  CheckDepositDtoTypeEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenButton from '../../Zen/ZenButton';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';

interface FormData {
  externalDepositLink: string;
}

interface ExternalDepositLinkSidebarProps {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
  depositType: CheckDepositDtoTypeEnum;
  externalLink?: string;
}

const ZenExternalDepositLinkSidebar: React.FC<ExternalDepositLinkSidebarProps> = ({
  isOpen,
  onClose,
  transaction,
  depositType,
  externalLink,
}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { control, getValues, setValue } = useForm<FormData>({
    defaultValues: {
      externalDepositLink: externalLink ?? 'Loading...',
    },
  });

  const copyToClipboard = async (value: string) => {
    await navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 800);
  };

  useEffect(() => {
    setValue('externalDepositLink', externalLink ?? 'Loading...');
  }, [externalLink, setValue]);

  return (
    <ZenSidebarModal
      title={`External ${capitalizeEnum(depositType)} Deposit Link`}
      subtitle={transaction?.address?.oneLine}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='p-4'>
        <div className='border-b border-zen-dark-4 pb-10'>
          <p className='font-zen-body text-zen-dark-9 text-base'>
            If the external agent or law firm has a check that needs to be
            deposited to Real&apos;s {capitalizeEnum(depositType)} account, they
            can use the link below to direct deposit into Real&apos;s{' '}
            {capitalizeEnum(depositType)} account without logging into the Real
            App.
          </p>
          <p className='font-zen-body text-zen-dark-9 text-base mt-5'>
            Note: Please ask the depositor to keep the check until the deposit
            is confirmed.
          </p>
          <div className='grid grid-cols-8 items-end gap-2 mt-5'>
            <div className='col-span-5'>
              <ZenControlledTextInput<FormData, 'externalDepositLink'>
                name='externalDepositLink'
                label='External deposit link'
                shouldUnregister={false}
                control={control}
                readOnly
              />
            </div>
            <div className='col-span-3'>
              <ZenButton
                label={isCopied ? 'Link copied' : 'Copy link'}
                variant='primary'
                LeftIconComponent={
                  <FontAwesomeIcon
                    icon={isCopied ? faCheck : faCopy}
                    size='sm'
                  />
                }
                onClick={() =>
                  copyToClipboard(getValues('externalDepositLink'))
                }
                isFullWidth
              />
            </div>
          </div>
        </div>
        <div className='mt-10'>
          <p className='flex items-center space-x-2 text-zen-dark-9'>
            <FontAwesomeIcon icon={faBook} className='mb-1' />
            <span className='font-medium font-zen-title text-lg'>
              Instructions for the depositor
            </span>
          </p>
          <ol
            className='font-zen-body text-zen-dark-9 text-base mt-3 list-decimal pl-5'
            type='1'
          >
            <li>Paste the link into the browser&apos;s address bar.</li>
            <li>Enter contact/depositor&apos;s information.</li>
            <li>Follow the check deposit instructions.</li>
          </ol>
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default ZenExternalDepositLinkSidebar;
