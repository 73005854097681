import { useMemo } from 'react';
import { Control, useFormContext } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import {
  LeoTableHeaderFormData,
  TABS_ENUM,
} from '../../routes/LeoQuestionManagerRoute';
import { RootState } from '../../types';
import LQMDateFilter, {
  DateFilterEnum,
  DateRange,
} from '../Zen/Calendars/LQMDateFilter';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenSearchBar from '../Zen/ZenSearchBar';
import LQMGeoLocationFilter from './LQMGeoLocationFilter';

interface LQMTableHeaderProps {
  tabName: TABS_ENUM;
  dateFilterLabel: DateFilterEnum;
  setDateFilterLabel(dateFilterType: DateFilterEnum): void;
  setDateFilter(range: DateRange): void;
  control: Control<LeoTableHeaderFormData, object>;
}

const LQMTableHeader: React.FC<LQMTableHeaderProps> = ({
  tabName,
  dateFilterLabel,
  setDateFilterLabel,
  setDateFilter,
  control,
}) => {
  const {
    auth: { userDetail },
    userIds: { agentById },
    leo: { assigneeUserIds },
  } = useSelector((state: RootState) => state);

  const { watch, setValue } = useFormContext<LeoTableHeaderFormData>();
  const [workQueueSearch, assignedSearch, completedSearch] = watch([
    'workQueueSearch',
    'assignedSearch',
    'completedSearch',
  ]);

  const assigneeOptions = [
    {
      value: userDetail?.id!,
      label: 'Me',
    },
    {
      value: 'all',
      label: 'All',
    },
    ...assigneeUserIds
      .filter((userId) => userId !== userDetail?.id)
      .map((assigneeId: string) => {
        return {
          value: assigneeId,
          label:
            assigneeId === userDetail?.id
              ? 'Me'
              : `${agentById[assigneeId]?.firstName!} ${agentById[assigneeId]
                  ?.lastName!}`,
        };
      }),
  ];

  const searchValue = useMemo(() => {
    if (tabName === TABS_ENUM.WORK_QUEUE) {
      return workQueueSearch;
    } else if (tabName === TABS_ENUM.ASSIGNED_QUEUE) {
      return assignedSearch;
    } else if (tabName === TABS_ENUM.COMPLETED_QUEUE) {
      return completedSearch;
    } else {
      return '';
    }
  }, [assignedSearch, completedSearch, tabName, workQueueSearch]);

  const handleSearchChange = (value: string) => {
    if (tabName === TABS_ENUM.WORK_QUEUE) {
      setValue('workQueueSearch', value);
    } else if (tabName === TABS_ENUM.ASSIGNED_QUEUE) {
      setValue('assignedSearch', value);
    } else if (tabName === TABS_ENUM.COMPLETED_QUEUE) {
      setValue('completedSearch', value);
    }
  };

  return (
    <div className='flex flex-grow flex-col md:flex-row md:items-center pt-5 justify-between w-full space-y-3 md:space-y-0'>
      {tabName === TABS_ENUM.WORK_QUEUE ? (
        <p className='ml-2 text-lg leading-5 font-extrabold'>All Questions</p>
      ) : (
        <div className='w-full md:w-80'>
          {tabName === TABS_ENUM.ASSIGNED_QUEUE && (
            <ZenControlledSelectInput<
              LeoTableHeaderFormData,
              `assignedQueueAssigneeId`
            >
              control={control}
              name='assignedQueueAssigneeId'
              placeholder='Select User'
              options={assigneeOptions}
              shouldUnregister={false}
            />
          )}
          {tabName === TABS_ENUM.COMPLETED_QUEUE && (
            <ZenControlledSelectInput<
              LeoTableHeaderFormData,
              `completedQueueAssigneeId`
            >
              control={control}
              name='completedQueueAssigneeId'
              placeholder='Select User'
              options={assigneeOptions}
              shouldUnregister={false}
            />
          )}
        </div>
      )}
      <div className='flex flex-grow flex-col md:flex-row md:items-center justify-end space-y-3 md:space-y-0'>
        <div className='md:w-56 md:mr-4'>
          <ZenSearchBar value={searchValue} onChange={handleSearchChange} />
        </div>
        <LQMDateFilter
          dateFilterLabel={dateFilterLabel}
          setDateFilterLabel={setDateFilterLabel}
          setDateFilter={setDateFilter}
          control={control}
          tabName={tabName}
        />
        <LQMGeoLocationFilter tabName={tabName} />
      </div>
    </div>
  );
};

export default LQMTableHeader;
