import React from 'react';
import ZenPopover from '../../../ZenPopover';

interface MaximumTeammatePopoverProps {}

const MaximumTeammatePopover: React.FC<MaximumTeammatePopoverProps> = () => {
  return (
    <ZenPopover
      title='Maximum Teammates'
      description={
        <div className='text-zen-dark-9 text-base font-light'>
          <p>
            Some types of teams limit the maximum number of team members. For
            instance, if the Real Administrator sets this value to 2, then Rezen
            will not allow this team to have more than 2 members.
          </p>
        </div>
      }
    />
  );
};

export default MaximumTeammatePopover;
