import { last } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../types';
import ApplicationStepCompleteMessage from '../ApplicationStepCompleteMessage';
import ApplicationLayout from '../ApplicationLayout';
import ApplicationVerticalStepProgress from './ApplicationVerticalStepProgress';

const ApplicationICASigningSuccess: React.FC = () => {
  const history = useHistory();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const feesWaived = last(userDetail?.applications!)!.feesWaivedAt;

  return (
    <ApplicationLayout>
      <div className='w-full mx-auto px-4 my-10 lg:max-w-6xl gap-10 grid grid-cols-1 lg:grid-cols-5'>
        <div className='lg:col-span-3'>
          <ApplicationStepCompleteMessage
            icon={<FontAwesomeIcon icon={faCheck} size='2xl' />}
            header='Thank you for signing the independent contractor agreement'
            subtitle={
              !feesWaived
                ? 'Up next, we will ask you to pay the application fee.'
                : 'Your application fees have been waived. Please continue to transfer your license.'
            }
            nextStepName={
              !feesWaived ? 'Pay Application Fee' : 'Transfer License'
            }
            onNextStepClicked={() => {
              const path = !feesWaived
                ? '/onboarding/application-fee'
                : '/onboarding/application-transfer-license';
              history.push(path);
            }}
          />
        </div>
        <div className='lg:col-span-2'>
          <ApplicationVerticalStepProgress />
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default ApplicationICASigningSuccess;
