import { AttachedFeeResponse, MoneyValue } from '../openapi/arrakis';
import { getCdaAdditionalFeeTypeColValue } from '../utils/CdaUtils';
import { displayAmount } from '../utils/CurrencyUtils';
import { cn } from '../utils/classUtils';

interface RebatesSectionProps {
  additionalFees?: AttachedFeeResponse[];
}

const RebatesSection: React.FC<RebatesSectionProps> = ({ additionalFees }) => {
  if (!additionalFees?.length) {
    return null;
  }

  return (
    <div className='my-4'>
      <p className='text-zen-dark-9 text-lg print:text-md font-primary-medium print:whitespace-nowrap whitespace-nowrap mb-1'>
        Additional Fees & Rebates{' '}
        <span className='text-base font-primary-regular text-zen-dark-7'>
          (Details of additional fees & rebates included in disbursement(s)
          listed above)
        </span>
      </p>
      <table className='w-full table-auto border border-gray-200 rounded-md'>
        <tbody>
          {additionalFees?.map((fee, index) => {
            const isLastRow = index === additionalFees.length - 1;
            return (
              <tr
                key={fee.feeType! + fee.participantName!}
                className={cn(!isLastRow && 'border-b border-zen-dark-4')}
              >
                <td className='relative px-5 py-3 font-zen-body font-medium text-sm text-zen-dark-9'>
                  {fee.participantName}
                </td>
                <td className='relative px-5 py-3 font-zen-body font-medium text-sm text-zen-dark-9'>
                  {getCdaAdditionalFeeTypeColValue(fee)}
                </td>
                <td className='relative px-5 py-3 font-zen-body font-normal text-sm text-zen-dark-7'>
                  {fee.description}
                </td>
                <td className='relative px-5 py-3 font-zen-body font-normal text-sm text-zen-dark-9'>
                  {displayAmount(fee.amount as MoneyValue)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RebatesSection;
