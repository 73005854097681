import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import useZenPagination, { DOTS } from '../../../../../hooks/useZenPagination';
import { cn } from '../../../../../utils/classUtils';

type ShareablePaginationProps = {
  totalCount: number;
  pageSize: number;
  lastPage: number;
  goToPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
};

const ShareablePagination: React.FC<ShareablePaginationProps> = ({
  totalCount,
  pageSize,
  lastPage,
  goToPage,
  currentPage,
}) => {
  const onPrevious = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    goToPage(currentPage - 1);
  };
  const onNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    goToPage(currentPage + 1);
  };

  const paginationRange = useZenPagination({
    currentPage,
    totalCount,
    pageSize,
  });

  return (
    <div className='flex flex-row justify-between items-center mt-5 px-4'>
      <p className='text-white font-zen-body-medium text-sm'>
        {`${pageSize} of ${totalCount}`}
      </p>
      <div className='flex space-x-1 items-center'>
        <button
          className='bg-[#2E2F37] disabled:cursor-not-allowed flex items-center justify-center rounded-full w-7 h-7'
          disabled={currentPage === 0}
          onClick={(e) => onPrevious(e)}
          aria-label='go-previous'
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={cn(currentPage === 0 ? 'text-black' : 'text-white')}
          />
        </button>
        <div className='flex items-center font-zen-body text-base text-white space-x-1'>
          {paginationRange.map((pageNumber) => {
            if (pageNumber === DOTS) {
              return <div>...</div>;
            }

            return (
              <button
                key={pageNumber}
                className={classNames('px-2 py-0.5 rounded', {
                  'bg-[#2E2F37]': +pageNumber - 1 === currentPage,
                })}
                onClick={(e) => {
                  e.preventDefault();
                  goToPage(+pageNumber - 1);
                }}
              >
                {pageNumber}
              </button>
            );
          })}
        </div>
        <button
          className='bg-[#2E2F37] disabled:cursor-not-allowed flex items-center justify-center rounded-full w-7 h-7'
          disabled={currentPage === lastPage - 1}
          onClick={(e) => {
            e.preventDefault();
            onNext(e);
          }}
          aria-label='go-next'
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            className={cn(
              currentPage === lastPage - 1 ? 'text-black' : 'text-white',
            )}
          />
        </button>
      </div>
    </div>
  );
};

export default ShareablePagination;
