import React from 'react';
import RealLogoImg from '../assets/img/new-rezen-black-logo.svg';
import ZenSimpleModal from './Zen/Modal/ZenSimpleModal';

interface CookiesDisabledModalProps {}

const CookiesDisabledModal: React.FC<CookiesDisabledModalProps> = () => {
  return (
    <ZenSimpleModal
      title='Enable Cookies for a Better Experience'
      isOpen
      onClose={() => {}}
      hideIcon
    >
      <div className='m-6 flex flex-col'>
        <div className='flex flex-row justify-center pb-4 px-4'>
          <img src={RealLogoImg} className='h-7' alt='Real Logo' />
        </div>
        <p className='font-zen-body text-base text-zen-dark-9'>
          To fully enjoy our website&apos;s features and personalized
          experience, please enable cookies in your browser settings. By doing
          so, you&apos;ll have access to a seamless browsing experience. Thank
          you!
        </p>
      </div>
    </ZenSimpleModal>
  );
};

export default CookiesDisabledModal;
