import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ItemResponse } from '../../openapi/sherlock';
import { markChecklistItemAsCompleted } from '../../slices/CheckListSlice';
import { RootState } from '../../types';
import { getFullName } from '../../utils/AgentHelper';
import { capitalizeEnum } from '../../utils/StringUtils';
import { journeyStatusToDisplayName } from '../../utils/TransactionHelper';
import ExcludeAgent from '../auth/ExcludeAgent';
import ToggleRow from '../ToggleRow';
import { cn } from '../../utils/classUtils';
import JourneyStatusIcon from './JourneyStatusIcon';

export interface JourneyStepTypeProps extends ItemResponse {
  icon: React.ReactElement | null;
}
interface JourneyStepProps {
  step: ItemResponse;
  isLastStep: boolean;
}

const JourneyStep: React.FC<JourneyStepProps> = ({ step, isLastStep }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    userIds: { agentById },
  } = useSelector((state: RootState) => state);
  const fullName = getFullName(agentById[step.assignee!]);
  const markedFullName = getFullName(agentById[step?.markedCompletedBy!]);
  const isStepCompleted = step.complete && !!step.completedAt;

  const updateJourneyProgress = async () => {
    setLoading(true);
    await dispatch(markChecklistItemAsCompleted(step.id!, !step.complete));
    setLoading(false);
  };

  return (
    <li
      key={step.name}
      className={cn('relative flex items-center', {
        'pb-10': !isLastStep,
      })}
    >
      <div className='pr-2'>
        <div className='w-28 text-right'>
          {isStepCompleted && (
            <div className='flex flex-col'>
              <span className='text-xs  text-gray-500'>
                {DateTime.fromMillis(step.completedAt!).toFormat('M/d/y')}
              </span>
              <span className='text-xs text-gray-500'>
                {DateTime.fromMillis(step.completedAt!).toFormat(
                  'hh:mm a ZZZZ',
                )}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className='flex-grow'>
        {!isLastStep && (
          <div className='ml-10 absolute top-4 left-24 w-0.5 h-full bg-gray-300' />
        )}
        <div className='relative flex items-center'>
          <JourneyStatusIcon status={step.status} />
          <div className='ml-10 min-w-0 flex flex-col'>
            <span className='text-base font-primary-medium text-dark whitespace-pre-wrap'>
              {step.name}
            </span>
            {!!fullName && (
              <div className='text-xs text-gray-500 -mt-1'>
                {capitalizeEnum(step.status)} by {fullName}
              </div>
            )}
            <ExcludeAgent>
              {!!markedFullName && (
                <div className='text-xs text-gray-500'>
                  {journeyStatusToDisplayName(step.status)} by {markedFullName}
                </div>
              )}
            </ExcludeAgent>
          </div>
        </div>
      </div>
      <ExcludeAgent>
        <div className='justify-end'>
          <div className='w-48'>
            <ToggleRow
              loading={loading}
              value={step.complete!}
              onChange={updateJourneyProgress}
              title={step.complete ? 'Complete' : 'Incomplete'}
            />
          </div>
        </div>
      </ExcludeAgent>
    </li>
  );
};

export default JourneyStep;
