import { MlsResponseStatusEnum } from '../../../openapi/yenta';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumMultiSelectColumnGenerator from './EnumMultiSelectColumnGenerator';

const MlsResponseStatusEnumSelectColumnFilter = <D extends object>(
  props: SelectColumnFilterProps<D>,
) => {
  return EnumMultiSelectColumnGenerator<D>(MlsResponseStatusEnum)(props);
};

export default MlsResponseStatusEnumSelectColumnFilter;
