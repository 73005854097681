import { faAddressBook, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { faArrowUpArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { ADDRESS_PLACEHOLDER } from '../../../../../constants/PlaceholderConstants';
import { fetchPdfAndConvertToFile } from '../../../../../hooks/useFetchPdfAndSetForm';
import { ParticipantResponseParticipantRoleEnum } from '../../../../../openapi/arrakis';
import {
  DirectoryEntryResponseTypeEnum,
  DirectoryVendorResponseRoleEnum,
  NationalBusinessIdentificationTypeEnum,
  NationalIdentificationValueTypeEnum,
} from '../../../../../openapi/yenta';
import {
  DirectoryCommonEntityResponse,
  useDirectoryAddress,
  useFetchW9OnDemand,
} from '../../../../../query/directory/useDirectory';
import AnalyticsService from '../../../../../services/AnalyticsService';
import { saveReferralInfos } from '../../../../../slices/TransactionBuilderSlice';
import {
  AnalyticsEventEnum,
  AppDispatch,
  ParticipantType,
  RootState,
} from '../../../../../types';
import { getIdValidations } from '../../../../../utils/AgentHelper';
import { getValidParticipantRoles } from '../../../../../utils/TransactionHelper';
import {
  EMAIL_VALIDATIONS,
  FILE_VALIDATIONS,
  GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../../../utils/Validations';
import { companyVerify } from '../../../../Directory/DirectoryUtils';
import { ExternalAgentConfirmationModal } from '../../../../Directory/ExternalAgentConfirmationModal';
import ZenRoleDirectoryForm from '../../../../Directory/ZenRoleDirectoryForm';
import IconButton from '../../../../IconButton';
import ZenContactListSidebar from '../../../../Directory/ZenContactListSidebar';
import ZenControlledEmailInput from '../../../Input/ZenControlledEmailInput';
import ZenControlledFileUploadInput from '../../../Input/ZenControlledFileUploadInput';
import ZenControlledGoogleAutocompleteSearchInput from '../../../Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledHTMLSelectInput from '../../../Input/ZenControlledHTMLSelectInput';
import ZenControlledPhoneNumberInput from '../../../Input/ZenControlledPhoneNumberInput';
import ZenControlledSecretTextInput from '../../../Input/ZenControlledSecretTextInput';
import ZenControlledTextInput from '../../../Input/ZenControlledTextInput';
import ZenButton from '../../../ZenButton';
import ZenSidebarModal from '../../../ZenSidebarModal';
import ZenExternalParticipantWarning from '../../ZenExternalParticipantWarning';
import { CreateTransactionFormState } from '../ZenCreateTransactionSteps';
import { FieldArrayPrefix } from '../ZenTransactionBuyerAndSellerStep';
import { extractNationalBusinessIds } from '../../../../Directory/utils';

interface ZenAddExternalParticipantProps {
  isOpen: boolean;
  onClose(): void;
  control: Control<CreateTransactionFormState, object>;
  watch: UseFormWatch<CreateTransactionFormState>;
  trigger: UseFormTrigger<CreateTransactionFormState>;
  setValue: UseFormSetValue<CreateTransactionFormState>;
  getValues: UseFormGetValues<CreateTransactionFormState>;
}

const ZenAddExternalParticipant: React.FC<ZenAddExternalParticipantProps> = ({
  control,
  watch,
  isOpen,
  onClose,
  trigger,
  setValue,
  getValues,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    transactionBuilder: { transactionBuilder },
  } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const [isManual, setIsManual] = useState<boolean>(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [roleSibebarOpen, setRoleSidebarOpen] = useState<boolean>(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [currentFormContact, setCurrentFormContact] = useState<
    DirectoryCommonEntityResponse | undefined
  >();
  const [pendingSaveData, setPendingSaveData] = useState<
    DirectoryCommonEntityResponse | undefined
  >();

  const { getGeocodeAddress } = useDirectoryAddress();
  const { mutate: fetchW9 } = useFetchW9OnDemand();

  const [receivesInvoice, companyName, firstName, lastName] = watch([
    'externalParticipant.receivesInvoice',
    'externalParticipant.paidViaBusinessEntity.name',
    'externalParticipant.firstName',
    'externalParticipant.lastName',
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string, eventData?: Record<string, unknown>) => {
      AnalyticsService.instance().logEvent(event, eventData);
    }, 500),
    [],
  );

  const onExternalParticipantSave = async () => {
    const isValid = await trigger();
    if (isValid) {
      const externalParticipant = watch('externalParticipant');

      setLoading(true);
      await dispatch(
        saveReferralInfos(
          transactionBuilder?.id!,
          externalParticipant?.participantRole,
          externalParticipant?.firstName,
          externalParticipant?.lastName,
          externalParticipant?.paidViaBusinessEntity?.name,
          externalParticipant?.email,
          externalParticipant?.phoneNumber,
          isManual
            ? externalParticipant?.address
            : externalParticipant?.autoAddress?.formatted_address,
          externalParticipant?.agentId,
          externalParticipant?.file?.[0]!,
          false,
          'EXTERNAL_ENTITY',
          externalParticipant?.ein,
        ),
      );

      logAnalyticsEvent(
        AnalyticsEventEnum.NEW_TRANSACTION_REFERRAL_FEE_EXTERNAL_PARTICIPANT_SAVE,
      );

      setLoading(false);
      onClose();
    }
  };

  const handleSidebarToggle = (_index?: number) => () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const importFromDirectory = async (
    contact: DirectoryCommonEntityResponse,
  ) => {
    // Only vendor can have status 'VERIFIED', so we can assume
    // that if the status is 'VERIFIED' then it is a vendor
    const vendorId =
      contact.type === DirectoryEntryResponseTypeEnum.Person
        ? contact.linkedId
        : contact.id;
    const vendorName =
      contact.type === DirectoryEntryResponseTypeEnum.Person
        ? contact.linkedName
        : contact.name;

    const { nationalId } = extractNationalBusinessIds(
      contact?.nationalBusinessIdentifications,
    );

    if (vendorId && contact.hasW9) {
      await fetchW9(
        { id: vendorId },
        {
          onSuccess: (signedUrl) => {
            if (signedUrl) {
              fetchPdfAndConvertToFile(signedUrl).then((file) => {
                if (file) {
                  setValue('externalParticipant.file', [file]);
                }
              });
            }
          },
        },
      );
    }
    setValue('externalParticipant.paidViaBusinessEntity.name', vendorName);
    setValue('externalParticipant.firstName', contact?.firstName);
    setValue('externalParticipant.lastName', contact?.lastName);
    setValue('externalParticipant.email', contact?.emailAddress);
    setValue('externalParticipant.phoneNumber', contact?.phoneNumber);
    setValue('externalParticipant.address', contact?.address?.oneLine);
    setValue('externalParticipant.ein', nationalId);
  };

  const handleSave = async (
    _fieldArrayPrefix?: FieldArrayPrefix,
    contact?: DirectoryCommonEntityResponse,
  ) => {
    setPendingSaveData(contact);
    if (
      contact?.role === DirectoryVendorResponseRoleEnum.OtherAgent &&
      contact?.nationalBusinessIdentifications &&
      contact?.nationalBusinessIdentifications?.length > 0
    ) {
      setPendingSaveData(contact);
      setConfirmationModalOpen(true);
      return;
    }

    if (contact) {
      setPendingSaveData(contact);
      await importFromDirectory(contact);
    }
  };

  const completeSaveOperation = async () => {
    if (pendingSaveData) {
      await importFromDirectory(pendingSaveData);
    }
    setPendingSaveData(undefined);
    setConfirmationModalOpen(false);
  };

  const handleRoleSidebarToggle = () => async () => {
    const values = getValues(`externalParticipant`);
    const geoAddress =
      values?.autoAddress ?? (await getGeocodeAddress(values?.address!));
    setCurrentFormContact({
      ...values,
      emailAddress: values?.email,
      address: {
        oneLine: geoAddress?.formatted_address ?? undefined,
      },
      addressComponents: geoAddress?.address_components ?? [],
      placeId: geoAddress?.place_id,
      name: values?.paidViaBusinessEntity?.name!,
      role: DirectoryVendorResponseRoleEnum.OtherAgent,
      firstName: values?.firstName,
      lastName: values?.lastName,
      nationalBusinessIdentifications: [
        {
          nationalId: values?.ein!,
          type: NationalBusinessIdentificationTypeEnum.Ein,
        },
      ],
      w9form: values?.file,
    });
    setRoleSidebarOpen((prevState) => !prevState);
  };

  return (
    <ZenSidebarModal
      title='Add Participant'
      isOpen={isOpen}
      onClose={() => {
        onClose();
        logAnalyticsEvent(
          AnalyticsEventEnum.NEW_TRANSACTION_REFERRAL_FEE_EXTERNAL_PARTICIPANT_CLICK_X,
        );
      }}
    >
      <div className='px-4 pt-4 pb-24'>
        <div>
          <ZenExternalParticipantWarning />
        </div>

        <div className='flex justify-end whitespace-nowrap mt-2'>
          <ZenButton
            label='Directory'
            variant='primary-link'
            LeftIconComponent={<FontAwesomeIcon icon={faAddressBook} />}
            onClick={handleSidebarToggle()}
            zeroPadding
            fontstyle
          />
        </div>
        <div className='mt-5'>
          <ZenControlledTextInput<
            CreateTransactionFormState,
            'externalParticipant.paidViaBusinessEntity.name'
          >
            control={control}
            label='Business name'
            subLabel='(I.e. Brokerage or company name)'
            name='externalParticipant.paidViaBusinessEntity.name'
            placeholder='E.g. Dream Home LLC.'
            rules={{
              validate: (realCompanyName) => {
                if (!realCompanyName && !firstName && !lastName) {
                  return 'You must provide a first and last name OR company name';
                }

                return true;
              },
            }}
            isRequired={!companyName && !firstName && !lastName}
          />
        </div>
        <div className='mt-5'>
          <ZenControlledHTMLSelectInput<
            CreateTransactionFormState,
            'externalParticipant.participantRole'
          >
            name='externalParticipant.participantRole'
            control={control}
            label='Role'
            placeholder='Role'
            readOnly
            rules={{ required: 'Please select a role' }}
            options={[
              {
                label: `Select Role`,
                value: '',
                disabled: true,
              },
              ...getValidParticipantRoles(
                transactionBuilder?.dealType!,
                undefined,
                ParticipantType.EXTERNAL_ENTITY,
              ),
            ]}
            defaultValue={ParticipantResponseParticipantRoleEnum.ReferringAgent}
            isRequired
          />
        </div>
        <div className='flex flex-row mt-5 space-x-2'>
          <ZenControlledTextInput<
            CreateTransactionFormState,
            'externalParticipant.firstName'
          >
            control={control}
            label='First name'
            name='externalParticipant.firstName'
            placeholder='E.g. John'
            rules={{
              minLength: {
                value: 2,
                message: 'Must be at least 2 characters',
              },
              validate: (realFirstName) => {
                if (!realFirstName && !lastName && !companyName) {
                  return 'You must provide a first and last name OR company name';
                }

                if (!companyName && !realFirstName) {
                  return 'Please enter your first name';
                }

                return true;
              },
            }}
            isRequired={
              (!firstName && !lastName && !companyName) ||
              (!companyName && !firstName)
            }
          />
          <ZenControlledTextInput<
            CreateTransactionFormState,
            'externalParticipant.lastName'
          >
            control={control}
            label='Last name'
            name='externalParticipant.lastName'
            placeholder='E.g. Dow'
            rules={{
              minLength: {
                value: 2,
                message: 'Must be at least 2 characters',
              },
              validate: (realLastName) => {
                if (!realLastName && !firstName && !companyName) {
                  return 'You must provide a first and last name OR company name';
                }

                if (!companyName && !realLastName) {
                  return 'Please enter your last name';
                }

                return true;
              },
            }}
            isRequired={
              (!firstName && !lastName && !companyName) ||
              (!companyName && !lastName)
            }
          />
        </div>

        <div className='mt-5'>
          <ZenControlledSecretTextInput<
            CreateTransactionFormState,
            'externalParticipant.ein'
          >
            control={control}
            name='externalParticipant.ein'
            label='Employer Identification Number (EIN)'
            placeholder='E.g. 12-1234567'
            rules={{
              ...getIdValidations(NationalIdentificationValueTypeEnum.Ein),
            }}
          />
        </div>

        <div className='mt-5'>
          <ZenControlledEmailInput<
            CreateTransactionFormState,
            'externalParticipant.email'
          >
            control={control}
            label='Email'
            subLabel='(Required if receiving electronic documents)'
            name='externalParticipant.email'
            placeholder='E.g. John@example.com'
            rules={{
              required: {
                value: !!receivesInvoice,
                message: 'Email is required if receiving electronic documents',
              },
              ...EMAIL_VALIDATIONS,
            }}
            isRequired={!!receivesInvoice}
          />
        </div>
        <div className='mt-5'>
          <ZenControlledPhoneNumberInput<
            CreateTransactionFormState,
            'externalParticipant.phoneNumber'
          >
            control={control}
            label='Phone number'
            name='externalParticipant.phoneNumber'
            placeholder='E.g. +1 (702) 123-4567'
            rules={{
              ...PHONE_NUMBER_VALIDATIONS,
            }}
          />
        </div>
        <div className='relative mt-8'>
          {isManual ? (
            <ZenControlledTextInput<
              CreateTransactionFormState,
              'externalParticipant.address'
            >
              control={control}
              label='Address'
              subLabel='(Must match address on W9 Form, if uploaded)'
              name='externalParticipant.address'
              placeholder={ADDRESS_PLACEHOLDER}
            />
          ) : (
            <ZenControlledGoogleAutocompleteSearchInput<
              CreateTransactionFormState,
              'externalParticipant.autoAddress'
            >
              control={control}
              shouldUnregister={false}
              name='externalParticipant.autoAddress'
              label='Search Address'
              subLabel='(Source by Google)'
              placeholder={ADDRESS_PLACEHOLDER}
              rules={{
                ...GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
              }}
            />
          )}
          <div className='absolute -top-3 right-0'>
            <IconButton
              label={isManual ? 'Search Address' : 'Enter address manually'}
              variant='none'
              buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
              leftIcon={
                <FontAwesomeIcon icon={faArrowUpArrowDown} className='p-1' />
              }
              onClick={() => {
                setIsManual(!isManual);
              }}
            />
          </div>
        </div>
        <div className='mt-5'>
          <ZenControlledFileUploadInput<
            CreateTransactionFormState,
            'externalParticipant.file'
          >
            name='externalParticipant.file'
            control={control}
            label='Upload W9 Form'
            subLabel='(Optional for external referral)'
            placeholder='E.g. W9.pdf'
            accept='.pdf'
            rules={{
              ...FILE_VALIDATIONS,
            }}
          />
        </div>
        <div className='flex items-end justify-end mt-3 -mr-4'>
          <ZenButton
            label='Add to My Directory'
            variant='primary-link'
            LeftIconComponent={
              <FontAwesomeIcon
                icon={faPlus}
                className='text-primary-blue cursor-pointer'
              />
            }
            onClick={handleRoleSidebarToggle()}
            fontstyle
          />
        </div>
      </div>
      <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2 w-full'>
        <ZenButton
          type='button'
          variant='secondary-outline'
          onClick={() => {
            onClose();
            logAnalyticsEvent(
              AnalyticsEventEnum.NEW_TRANSACTION_REFERRAL_FEE_EXTERNAL_PARTICIPANT_CANCEL,
            );
          }}
          label='Cancel'
          isFullWidth
        />
        <ZenButton
          isDisabled={loading}
          isSubmitting={loading}
          label='Save'
          onClick={onExternalParticipantSave}
          isFullWidth
        />
      </div>
      {isSidebarOpen && (
        <ZenContactListSidebar
          currentFieldArrayPrefixNotRequired
          isExternalAgent
          isOpen={isSidebarOpen}
          onClose={handleSidebarToggle()}
          onSave={handleSave}
        />
      )}
      {roleSibebarOpen && (
        <ZenRoleDirectoryForm
          isOpen={roleSibebarOpen}
          existingVendorOrPerson={currentFormContact}
          mode='populate'
          onClose={handleRoleSidebarToggle()}
          isExternal
        />
      )}
      {pendingSaveData && isConfirmationModalOpen && (
        <ExternalAgentConfirmationModal
          isConfirmationModalOpen={isConfirmationModalOpen}
          setConfirmationModalOpen={setConfirmationModalOpen}
          companyVerify={companyVerify}
          completeSaveOperation={completeSaveOperation}
          pendingSaveData={pendingSaveData}
        />
      )}
    </ZenSidebarModal>
  );
};

export default ZenAddExternalParticipant;
