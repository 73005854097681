import { OutgoingPaymentResponsePaymentSystemEnum } from '../../../openapi/arrakis';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumMultiSelectColumnGenerator from './EnumMultiSelectColumnGenerator';

export interface OutgoingPaymentPaymentSystemSelectColumnFilterProps<
  D extends object
> extends SelectColumnFilterProps<D> {}

const OutgoingPaymentPaymentSystemSelectColumnFilter = <D extends object>(
  props: OutgoingPaymentPaymentSystemSelectColumnFilterProps<D>,
) => {
  return EnumMultiSelectColumnGenerator<D>(
    OutgoingPaymentResponsePaymentSystemEnum,
  )(props);
};

export default OutgoingPaymentPaymentSystemSelectColumnFilter;
