import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import ZenRouterTabsContainer from './ZenRouterTabsContainer';

export interface ZenTab {
  label: string;
  leftLabelIcon?: React.ReactElement;
  path: string;
  exact?: boolean;
  badgeComponent?: React.ReactElement;
  TabComponent?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export interface ZenTabsProps {
  tabs: ZenTab[];
  stickyTabs?: boolean;
  customTop?: string;
  leftLabelIcon?: React.ReactElement;
}

const ZenRouterTabs: React.FC<ZenTabsProps> = ({
  tabs,
  stickyTabs = false,
  customTop = 'top-0',
}) => {
  return (
    <div className='relative'>
      <ZenRouterTabsContainer
        tabs={tabs}
        stickyTabs={stickyTabs}
        customTop={customTop}
        leftLabelIcon={tabs[0].leftLabelIcon}
      />
      <div className='px-4'>
        <Switch>
          {tabs.map(({ label, TabComponent, path, exact = true }) => (
            <Route
              key={`route-${label}`}
              path={path}
              exact={exact}
              component={TabComponent}
            />
          ))}
        </Switch>
      </div>
    </div>
  );
};

export default ZenRouterTabs;
