import { useState } from 'react';
import ZenEngineeringComponent from '../components/Zen/Engineering/ZenEngineeringComponent';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenPasswordGuard from './ZenPasswordGuard';

export const ENGINEERING_PASSWORD = 'abracadabra';

interface ZenEngineeringIndexRouteProps {}

const ZenEngineeringIndexRoute: React.FC<ZenEngineeringIndexRouteProps> = () => {
  const [authenticated, setAuthenticated] = useState(false);

  return (
    <ZenRoute title='Engineering'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Engineering', url: '/engineering' },
        ]}
      >
        {!authenticated ? (
          <ZenPasswordGuard
            password={ENGINEERING_PASSWORD}
            setAuthenticated={setAuthenticated}
          />
        ) : (
          <ZenEngineeringComponent />
        )}
      </ZenPageLayout>
    </ZenRoute>
  );
};

export default ZenEngineeringIndexRoute;
