import { useEffect } from 'react';
import AmplitudeService, {
  AmplitudeEvent,
} from '../../services/AmplitudeService';

interface AmplitudeEventOnLoadProps {
  eventName: AmplitudeEvent;
  eventData?: Record<string, unknown>;
}

const AmplitudeEventOnLoad: React.FC<AmplitudeEventOnLoadProps> = ({
  eventName,
  eventData = {},
}) => {
  useEffect(() => {
    AmplitudeService.logEvent(eventName, eventData);
    // eslint-disable-next-line
  }, []);

  return null;
};

export default AmplitudeEventOnLoad;
