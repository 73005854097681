import { useEffect, useState } from 'react';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { MdLaunch } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { BiCaretDown, BiCaretUp } from 'react-icons/bi';
import ProcessingIcon from '../../../assets/img/processing.png';
import { ReactComponent as ZenEye } from '../../../assets/img/zen-eye.svg';
import {
  ConveyanceLetterRevisionResponse,
  ConveyanceLetterRevisionResponseStatusEnum,
} from '../../../openapi/arrakis';
import {
  fetchConveyanceDocuments,
  generateConveyanceDocument,
} from '../../../slices/ConveyanceDocumentSlice';
import { RootState, AnalyticsEventEnum } from '../../../types';
import AnalyticsService from '../../../services/AnalyticsService';
import { capitalizeEnum } from '../../../utils/StringUtils';
import AdminOnly from '../../auth/AdminOnly';
import EmptyConveyance from '../../ConveyanceDocuments/EmptyConveyanceTable';
import ResourceContainer from '../../ResourceContainer';
import MilliDateCell from '../../table/Cells/MilliDateCell';
import ZenResourceIndexContainer from '../Containers/ZenResourceIndexContainer';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import ZenButton from '../ZenButton';
import ZenConveyanceState from './ZenConveyanceState';
import ZenDocumentSetTable from './ZenDocumentSetTable';

interface ZenConveyanceResourceTableProps {
  transactionId: string;
}

export const columns: Array<Column<ConveyanceLetterRevisionResponse>> = [
  {
    Header: 'Document Set',
    accessor: 'name',
    Cell: ({ row, value }) => (
      <button
        className='py-2 px-4.5 w-fit flex items-center space-x-2 bg-regent-100 border border-zen-dark-4 rounded-md cursor-pointer'
        onClick={() => row.toggleRowExpanded()}
      >
        <p className='font-zen-body text-sm font-normal text-zen-dark-9'>
          {capitalizeEnum(value!)}
        </p>
        {row.isExpanded ? (
          <BiCaretUp
            className='text-primary-blue text-sm'
            data-testid='caretUp'
          />
        ) : (
          <BiCaretDown
            className='text-primary-blue text-sm'
            data-testid='caretDown'
          />
        )}
      </button>
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <div className='mt-2.5'>
        <ZenConveyanceState state={value} />
      </div>
    ),
    cardColSize: 4,
  },
  {
    Header: 'Processed Date',
    accessor: 'processedAt',
    Cell: ({ value }) => (
      <div className='font-zen-body font-normal text-sm text-zen-dark-9 mt-2.5'>
        <MilliDateCell date={value} />
      </div>
    ),
    cardColSize: 4,
  },
];

const ZenConveyanceResourceTable: React.FC<ZenConveyanceResourceTableProps> = ({
  transactionId,
}) => {
  const [regenerateModal, setRegenerateModal] = useState<boolean>(false);
  const [regenerating, setRegenerating] = useState(false);
  const {
    auth: { isAdmin },
    conveyanceDocument: { conveyances, conveyancesLoading },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const history = useHistory();

  const allConveyanceDocsAvailable = isAdmin
    ? conveyances
    : conveyances.filter(
        (c) => c.status === ConveyanceLetterRevisionResponseStatusEnum.Valid,
      );

  useEffect(() => {
    dispatch(fetchConveyanceDocuments(transactionId));
  }, [dispatch, transactionId]);

  const handleGenerate = async (loading: boolean) => {
    await dispatch(generateConveyanceDocument(transactionId, loading));
  };

  const columnsWithAction: Array<Column<ConveyanceLetterRevisionResponse>> = [
    ...columns,
    {
      Header: 'Actions',
      accessor: 'revision',
      id: 'action',
      Cell: ({ row }) => {
        if (
          [
            ConveyanceLetterRevisionResponseStatusEnum.ReadyForProcessing,
            ConveyanceLetterRevisionResponseStatusEnum.Valid,
            ConveyanceLetterRevisionResponseStatusEnum.Invalid,
          ].includes(row.original.status!)
        ) {
          return (
            <ZenButton
              variant='primary-outline'
              label='View PDF Set'
              LeftIconComponent={<ZenEye />}
              onClick={() =>
                history.push(
                  `/transactions/${transactionId}/conveyance-documents/${row.original.revision}/preview`,
                )
              }
            />
          );
        }

        if (
          [ConveyanceLetterRevisionResponseStatusEnum.Draft].includes(
            row.original.status!,
          ) &&
          isAdmin
        ) {
          return (
            <ZenButton
              type='button'
              label='Add Notes & Finalize'
              variant='primary-outline'
              LeftIconComponent={<MdLaunch className='text-lg mb-[2px] mr-1' />}
              onClick={() =>
                history.push(
                  `/transactions/${transactionId}/conveyance-documents/${row.original.revision}/preview`,
                )
              }
            />
          );
        }

        return null;
      },
      disableSortBy: true,
    },
  ];

  return (
    <div>
      <ResourceContainer
        loading={conveyancesLoading}
        isEmpty={false}
        resourceName='conveyance documents'
      >
        <div className='p-4'>
          <div className='flex flex-col sm:flex-row sm:items-center justify-between mb-5 font-zen-body'>
            {/* Back button & Title */}
            <div className='flex items-center mb-2 sm:mb-0'>
              <ZenButton
                type='button'
                label='Back'
                variant='secondary-gray-outline'
                LeftIconComponent={
                  <HiOutlineArrowNarrowLeft
                    fontSize={20}
                    className='mb-[2px]'
                  />
                }
                onClick={() =>
                  history.push(`/transactions/${transactionId!}/detail`)
                }
              />

              <div className='font-zen-title ml-5 text-xl font-medium text-zen-dark-9'>
                Conveyance Documents
              </div>
            </div>

            {/* Generate & Regenerate Buttons */}
            <AdminOnly>
              {!allConveyanceDocsAvailable?.length ? (
                <ZenButton
                  type='button'
                  label='Generate Document Set'
                  variant='primary'
                  LeftIconComponent={
                    <img
                      src={ProcessingIcon}
                      alt='processing'
                      className='mr-1'
                    />
                  }
                  onClick={() => handleGenerate(true)}
                />
              ) : (
                <ZenButton
                  type='button'
                  label='Regenerate Document Set'
                  variant='danger-outline'
                  onClick={() => setRegenerateModal(true)}
                />
              )}
            </AdminOnly>
          </div>

          <ZenResourceIndexContainer<ConveyanceLetterRevisionResponse>
            resourceName='Conveyance Documents'
            columns={columnsWithAction}
            data={allConveyanceDocsAvailable || []}
            hidePagination
            hideFilters
            cellVerticalAlignment='align-top'
            renderToggleRowComponent={(row) => (
              <ZenDocumentSetTable
                isDownloadable={
                  row.original.status ===
                  ConveyanceLetterRevisionResponseStatusEnum.Valid
                }
                conveyanceId={row.original.revision!}
                processedDate={row.original.processedAt}
                conveyanceDocuments={row.original.conveyanceDocuments!}
              />
            )}
            customEmptyComponent={
              <EmptyConveyance>
                <div className='font-zen-body font-medium text-base text-regent-200'>
                  No conveyance documents generated yet
                </div>
                <AdminOnly>
                  <div className='flex space-x-1 font-zen-body text-base text-zen-dark-7 mt-2'>
                    <p>Click</p>
                    <button
                      className='text-primary-blue'
                      onClick={() => handleGenerate(true)}
                    >
                      Generate Document Set
                    </button>
                    <p>to start</p>
                  </div>
                </AdminOnly>
              </EmptyConveyance>
            }
          />
        </div>
      </ResourceContainer>
      <ZenConfirmationModal
        isOpen={regenerateModal}
        variant='danger'
        title='Regenerate document set?'
        subtitle='This action will invalidate previously finalized documents sets and generate new set'
        onClose={() => setRegenerateModal(false)}
        onConfirm={async () => {
          setRegenerating(true);
          await handleGenerate(false);
          setRegenerating(false);
          setRegenerateModal(false);
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.CONVEYANCE_DOCUMENT_INVALIDATED,
          );
        }}
        hideIcon
        isSubmitting={regenerating}
      />
    </div>
  );
};

export default ZenConveyanceResourceTable;
