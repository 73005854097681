import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { JobExecutionResponseJobExecutionStatusEnum } from '../../../openapi/yenta';
import { getLatestCronJobs } from '../../../slices/CronJobs';
import { AppDispatch, PartialEnumMap, RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import AuthorizedOnly from '../../auth/AuthorizedOnly';

export interface ZenCronJobsStatusIconProps {
  isCollapsed?: boolean;
}

const ZenCronJobsStatusIcon: React.FC<ZenCronJobsStatusIconProps> = ({
  isCollapsed = false,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const fetchLatestCronJobs = useCallback(async () => {
    await dispatch(getLatestCronJobs());
  }, [dispatch]);

  useEffect(() => {
    fetchLatestCronJobs();
  }, [fetchLatestCronJobs]);

  const {
    cronJobs: { cronJobsResponse },
  } = useSelector((state: RootState) => state);

  const cronJobs = cronJobsResponse.data?.jobExecutionResponses;

  const jobsExecutionStatus: JobExecutionResponseJobExecutionStatusEnum =
    cronJobs?.findIndex(
      (job) =>
        job.jobExecutionStatus ===
        JobExecutionResponseJobExecutionStatusEnum.Fail,
    ) !== -1
      ? JobExecutionResponseJobExecutionStatusEnum.Fail
      : JobExecutionResponseJobExecutionStatusEnum.Success;

  const statusToIconMap: PartialEnumMap<
    JobExecutionResponseJobExecutionStatusEnum,
    React.ReactElement
  > = {
    FAIL: (
      <FontAwesomeIcon
        icon={faCircleXmark}
        className={cn(
          'text-red-600 bg-black',
          isCollapsed ? 'absolute text-xs -right-1.5 top-0.5' : 'text-sm',
        )}
        aria-label='cron-job-fail'
      />
    ),
    SUCCESS: (
      <FontAwesomeIcon
        icon={faCircleCheck}
        className={cn(
          'text-green-600 bg-black',
          isCollapsed ? 'absolute text-xs -right-1.5 top-0.5' : 'text-sm',
        )}
        aria-label='cron-job-success'
      />
    ),
  };

  if (isCollapsed) {
    return (
      <div className='relative'>
        <FontAwesomeIcon icon={faCalendar} />
        <AuthorizedOnly asyncResponse={cronJobsResponse}>
          {statusToIconMap[jobsExecutionStatus]}
        </AuthorizedOnly>
      </div>
    );
  }

  return (
    <AuthorizedOnly asyncResponse={cronJobsResponse}>
      {statusToIconMap[jobsExecutionStatus]}
    </AuthorizedOnly>
  );
};

export default ZenCronJobsStatusIcon;
