import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import useAgentsInfo from '../../../hooks/useAgentsInfo';
import { RootState } from '../../../types';
import Avatar from '../../Avatar';

interface RecipientTableProps {
  ids: string[];
  removeUser(id: string): void;
}

const RecipientTable: React.FC<RecipientTableProps> = ({ ids, removeUser }) => {
  const { agentById } = useSelector((state: RootState) => state.userIds);

  useAgentsInfo(ids);

  if (!ids?.length) {
    return null;
  }

  return (
    <div className='pt-8'>
      <p className='mb-1'>Share With</p>
      {ids?.map((id) => {
        const agent = agentById[id];

        if (!agent) {
          return (
            <div className='font-zen-body text-base text-zen-dark-9 py-3'>
              Loading...
            </div>
          );
        }

        return (
          <div
            key={id}
            className='pt-3.5 pb-4 flex flex-row items-center border-b'
          >
            <Avatar
              name={`${agent?.firstName} ${agent?.lastName}`}
              size='xs'
              imageUrl={agent?.avatar}
            />
            <div className='flex flex-row flex-grow ml-4'>
              <p className='font-zen-body text-zen-dark leading-6 text-base mr-1.5'>
                {`${agent?.firstName} ${agent?.lastName}`}
              </p>
              <p className='font-zen-body text-zen-dark-6 leading-6 text-base'>
                {agent?.emailAddress}
              </p>
            </div>
            <FontAwesomeIcon
              icon={faTrashCan}
              className='w-4 h-4 text-zen-danger cursor-pointer'
              onClick={() => removeUser(id)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RecipientTable;
