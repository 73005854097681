import { faCassetteTape, faVoicemail } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  VoiceCallBlock,
  VoiceCallBlockRecordingTypeEnum,
} from '../../../../openapi/yada';
import { isCallCancelled } from '../../../../utils/RoarUtils';

type VoiceCallStatusPillProps = {
  recordingBlock: VoiceCallBlock;
};

const VoiceCallStatusPill: React.FC<VoiceCallStatusPillProps> = ({
  recordingBlock,
}) => {
  const recordingType = recordingBlock.recordingType;
  const callCancelled = isCallCancelled(recordingBlock);

  function getLabel() {
    if (recordingType === VoiceCallBlockRecordingTypeEnum.Voicemail) {
      return 'VOICEMAIL';
    }
    return callCancelled ? 'CANCELLED CALL' : 'PHONE CALL';
  }

  return (
    <div className='flex items-center space-x-1.5 bg-regent-200 text-grey-500 rounded-md px-2 py-1.5'>
      <FontAwesomeIcon
        icon={
          recordingType === VoiceCallBlockRecordingTypeEnum.Voicemail
            ? faVoicemail
            : faCassetteTape
        }
        fontSize={14}
      />
      <span className='text-sm'>{getLabel()}</span>
    </div>
  );
};

export default VoiceCallStatusPill;
