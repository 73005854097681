import React from 'react';
import { Address, MoneyValue } from '../../openapi/arrakis/api';
import { displayAmount } from '../../utils/CurrencyUtils';
import T4AField from './T4AField';
import T4AFieldWithBoxCase from './T4AFieldWithBoxCase';

interface T4AFormFooterProps {
  revsharePaid: MoneyValue;
  name: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  mailingAddress: Address | undefined;
  isBusinessEntity: boolean;
}

const T4AFormFooter: React.FC<T4AFormFooterProps> = ({
  revsharePaid,
  name,
  firstName,
  lastName,
  mailingAddress,
  isBusinessEntity,
}) => {
  return (
    <div className='relative border-b-2 border-black'>
      <div className='flex items-end justify-between'>
        <div className='flex-1 border-2 border-black px-2 h-80 bg-white'>
          <p className='mt-1 mb-2 font-primary-medium'>
            Recipient&apos;s name and address – Nom et adresse du bénéficiaire
          </p>
          <div className='grid grid-cols-6 text-xs'>
            <div className='flex flex-col justify-end col-span-3'>
              <p>Last name (print) - Nom de famille (en lettres moulées)</p>
              <div className='border-2 border-black h-10 px-2 pt-1 mt-3'>
                {isBusinessEntity ? name : lastName}
              </div>
            </div>
            <div className='flex flex-col justify-end col-span-2'>
              <p>First name - Prénom</p>
              <div className='border-t-2 border-b-2 border-black h-10 px-2 py-1 mt-3'>
                {!isBusinessEntity && firstName}
              </div>
            </div>
            <div className='flex flex-col justify-end col-span-1'>
              <p>Initials – Initiales</p>
              <div className='border-2 border-l-0 border-black h-10 mt-3' />
            </div>
          </div>
          <div className='mt-6 h-40'>{mailingAddress?.oneLine}</div>
        </div>
        <div className='flex-1'>
          <div className='flex items-end space-x-2 pl-1'>
            <div className='max-w-md flex-1'>
              <T4AField
                labelNumber='024'
                label={
                  <div>
                    <p className='text-center'>Annuities</p>
                    <p className='text-center'>Rentes</p>
                  </div>
                }
              />
            </div>
            <div className='max-w-md flex-1'>
              <T4AField
                labelNumber='048'
                label={
                  <div>
                    <p className='text-center'>Fees for services</p>
                    <p className='text-center'>Honoraires ou autres sommes</p>
                    <p className='text-center'>pour services rendus</p>
                  </div>
                }
              />
            </div>
          </div>
          <div className='flex-1 border-t-2 border-r-2 border-black mt-2 px-2'>
            <p className='pt-2 text-center leading-5'>
              Other information (see page 2)
            </p>
            <p className='text-center leading-5'>
              Autres renseignements (voir à la page 2)
            </p>
            <div className='flex items-end space-x-3'>
              <div className='max-w-md flex-1'>
                <T4AFieldWithBoxCase
                  label={<p className='text-center'>Amount – Montant</p>}
                />
              </div>
              <div className='max-w-md flex-1'>
                <T4AFieldWithBoxCase
                  label={<p className='text-center'>Amount – Montant</p>}
                />
              </div>
            </div>
            <div className='flex items-end space-x-3 mt-1.5'>
              <div className='max-w-md flex-1'>
                <T4AFieldWithBoxCase
                  label={<p className='text-center'>Amount – Montant</p>}
                />
              </div>
              <div className='max-w-md flex-1'>
                <T4AFieldWithBoxCase
                  label={<p className='text-center'>Amount – Montant</p>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='pt-0.5 pb-1.5 pr-2 pl-5 flex items-center space-x-2 border-r-2 border-l-2 border-black'>
        <div className='space-y-2 flex-1'>
          <div className='flex items-end space-x-3'>
            <div className='max-w-md flex-1'>
              <T4AFieldWithBoxCase
                label={<p className='text-center'>Amount – Montant</p>}
                value='028'
                primaryText={displayAmount(revsharePaid)}
              />
            </div>
            <div className='max-w-md flex-1'>
              <T4AFieldWithBoxCase
                label={<p className='text-center'>Amount – Montant</p>}
              />
            </div>
          </div>
          <div className='flex items-end space-x-3'>
            <div className='max-w-md flex-1'>
              <T4AFieldWithBoxCase
                label={<p className='text-center'>Amount – Montant</p>}
              />
            </div>
            <div className='max-w-md flex-1'>
              <T4AFieldWithBoxCase
                label={<p className='text-center'>Amount – Montant</p>}
              />
            </div>
          </div>
        </div>
        <div className='flex-1 pl-4'>
          <div className='space-y-2 my-1'>
            <div className='flex items-end space-x-3'>
              <div className='max-w-md flex-1'>
                <T4AFieldWithBoxCase
                  label={<p className='text-center'>Amount – Montant</p>}
                />
              </div>
              <div className='max-w-md flex-1'>
                <T4AFieldWithBoxCase
                  label={<p className='text-center'>Amount – Montant</p>}
                />
              </div>
            </div>
            <div className='flex items-end space-x-3'>
              <div className='max-w-md flex-1'>
                <T4AFieldWithBoxCase
                  label={<p className='text-center'>Amount – Montant</p>}
                />
              </div>
              <div className='max-w-md flex-1'>
                <T4AFieldWithBoxCase
                  label={<p className='text-center'>Amount – Montant</p>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default T4AFormFooter;
