import React from 'react';
import { ZenLinkSizeVariant, ZenLinkVariant } from '../../types';
import { cn } from '../../utils/classUtils';

export interface ZenLinkProps {
  label: string;
  variant?: ZenLinkVariant;
  size?: ZenLinkSizeVariant;
  onClick?: () => void;
  leftIconComponent?: React.ReactElement;
  rightIconComponent?: React.ReactElement;
}

const ZenLink: React.FC<ZenLinkProps> = ({
  label,
  variant = 'primary',
  onClick,
  leftIconComponent,
  rightIconComponent,
  size = 'medium',
}) => {
  const variantToButtonStyleMap: { [type in ZenLinkVariant]: string } = {
    primary: 'text-primary-blue hover:border-primary-blue',
    secondary: 'text-zen-dark-9 hover:border-zen-dark-9',
    danger: 'text-zen-danger',
    'primary-with-no-hover-effect': 'text-primary-blue',
  };

  const variantToSizeStyleMap: { [type in ZenLinkSizeVariant]: string } = {
    small: 'text-xs md:text-sm',
    medium: 'text-sm md:text-base',
    large: 'text-base md:text-lg',
  };

  return (
    <button
      type='button'
      className={cn(
        'flex flex-row justify-center items-center border-b border-transparent font-zen-body font-semibold space-x-1',
        variantToButtonStyleMap[variant],
        variantToSizeStyleMap[size],
      )}
      onClick={onClick}
    >
      {leftIconComponent && <span>{leftIconComponent}</span>}
      <span>{label}</span>
      {rightIconComponent && <span>{rightIconComponent}</span>}
    </button>
  );
};

export default ZenLink;
