import { faPause, faPlay, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { formatTime } from '../../utils/DateUtils';

type RecordMemoPreviewProps = {
  path: string;
  duration: number;
  onClose(): void;
};

const RecordMemoPreview: React.FC<RecordMemoPreviewProps> = ({
  path,
  duration,
  onClose,
}) => {
  const [waveForm, setWaveForm] = useState<WaveSurfer>();
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const _waveForm = WaveSurfer.create({
      barWidth: 2,
      barRadius: 2,
      barGap: 3,
      cursorWidth: 1,
      container: '#record-player',
      backend: 'WebAudio',
      fillParent: true,
      progressColor: '#4967FD',
      waveColor: '#615B56',
      cursorColor: 'transparent',
      hideScrollbar: true,
      height: 20,
      barMinHeight: 2,
    });

    setWaveForm(_waveForm);
    _waveForm.load(path);
    _waveForm.on('finish', () => {
      _waveForm.pause();
      _waveForm.seekTo(0);
      setIsPlaying(false);
    });

    return () => {
      _waveForm?.destroy();
    };
  }, [path]);

  const handlePlay = () => {
    if (isPlaying) {
      waveForm?.pause();
      setIsPlaying(false);
    } else {
      waveForm?.play();
      setIsPlaying(true);
    }
  };

  const handleCancel = () => {
    waveForm?.destroy();
    URL.revokeObjectURL(path);
    onClose();
  };

  return (
    <div className='h-10 flex items-center space-x-3'>
      <button
        className='bg-regent-300 p-2 rounded-full w-8 h-8 flex items-center justify-center'
        onClick={handleCancel}
      >
        <FontAwesomeIcon
          icon={faXmark}
          className='text-primary-dark'
          fontSize={14}
        />
      </button>
      <div className='flex-1 flex items-center space-x-3 bg-grey-100 py-2 px-3 rounded-full'>
        <button
          onClick={handlePlay}
          className='bg-regent-300 rounded-full p-1 w-8 h-8'
          type='button'
        >
          <FontAwesomeIcon
            icon={isPlaying ? faPause : faPlay}
            fontSize={14}
            className='text-primary-dark'
          />
        </button>
        <div id='record-player' className='flex-1 mt-1' />
        <p className='ml-2 text-sm font-inter text-grey-600'>
          {formatTime(duration)}
        </p>
      </div>
    </div>
  );
};

export default RecordMemoPreview;
