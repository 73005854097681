import { BankActivityDto } from '../../../openapi/arrakis';
import { displayAmount } from '../../../utils/CurrencyUtils';
import ZenBank from '../../../assets/img/zen/zen-bank.png';
import ZenPiggyBank from '../../../assets/img/zen/zen-piggy-bank.png';

interface ZenReconcileTransactionSidebarModalHeaderProps {
  selectedTransaction: null | BankActivityDto;
}

const ZenReconcileTransactionSidebarModalHeader: React.FC<ZenReconcileTransactionSidebarModalHeaderProps> = ({
  selectedTransaction,
}) => {
  return (
    <div className='grid grid-cols-6 border-b border-zen-dark-4 p-5 gap-5'>
      <div className='col-span-4'>
        <div className='flex items-start space-x-5'>
          <img src={ZenBank} alt='bank-icon' className='w-5 h-5 mt-1' />
          <div className='space-y-1'>
            <p className='font-zen-body text-zen-dark text-base font-semibold'>
              {selectedTransaction?.bank} (Ref:{' '}
              {selectedTransaction?.attributes?.bankRef || 'N/A'})
            </p>
            <p className='font-zen-body text-zen-dark-9 text-sm font-normal'>
              {selectedTransaction?.description}
            </p>
            <p className='font-zen-body text-zen-dark-9 text-sm font-normal'>
              BO Customer:{' '}
              {selectedTransaction?.attributes?.boCustomer || 'N/A'}
            </p>
            <p className='font-zen-body text-zen-dark-7 text-sm font-normal'>
              <span>
                Code: {selectedTransaction?.attributes?.code! || 'N/A'}
              </span>
              <span className='mx-1'>|</span>
              <span>Date: {selectedTransaction?.date || 'N/A'}</span>
            </p>
          </div>
        </div>
      </div>
      <div className='col-span-2'>
        <div className='flex items-center space-x-5'>
          <img src={ZenPiggyBank} alt='bank-icon' className='w-5 h-5 ' />
          <p className='font-zen-body text-zen-dark text-base font-semibold'>
            {displayAmount(selectedTransaction?.amount)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ZenReconcileTransactionSidebarModalHeader;
