import { faFlag, faPaperclip } from '@fortawesome/pro-light-svg-icons';
import {
  faChevronDown,
  faCircleXmark,
  faFlag as faFlagSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ZenPill from '../../Zen/ZenPill';

interface TemplateItemSidebarHeaderProps {
  onClose(): void;
  isRequired: boolean;
  checkListName: string;
}

const TemplateItemSidebarHeader: React.FC<TemplateItemSidebarHeaderProps> = ({
  isRequired,
  onClose,
  checkListName,
}) => {
  return (
    <div className='flex flex-row items-center justify-between px-4 py-2 bg-white border-b border-zen-dark-4'>
      <ZenPill
        title={checkListName}
        variant='primary'
        rightIcon={
          <FontAwesomeIcon icon={faChevronDown} className='mt-2' size='sm' />
        }
      />
      <div className='flex flex-row items-center gap-x-4'>
        <FontAwesomeIcon size='xl' className='mr-2' icon={faPaperclip} />
        {isRequired ? (
          <FontAwesomeIcon
            size='xl'
            className='text-error mr-2'
            icon={faFlagSolid}
          />
        ) : (
          <FontAwesomeIcon size='xl' className='mr-2' icon={faFlag} />
        )}
        <div className='mx-2 my-3'>
          <FontAwesomeIcon
            icon={faCircleXmark}
            className='text-3xl text-zen-dark-5 cursor-pointer'
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default TemplateItemSidebarHeader;
