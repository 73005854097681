import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faEnvelope, faTag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as CalenderCheckIcon } from '../../../../assets/img/zen/transactionHeader/calender-check.svg';
import { ReactComponent as CalenderNoteIcon } from '../../../../assets/img/zen/transactionHeader/calender-note.svg';
import { ReactComponent as CalenderHistoryIcon } from '../../../../assets/img/zen/transactionHeader/calender.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/img/zen/transactionHeader/copy.svg';
import { ReactComponent as DollarIcon } from '../../../../assets/img/zen/transactionHeader/dollar.svg';
import { ReactComponent as OfficeIcon } from '../../../../assets/img/zen/transactionHeader/office.svg';
import { ReactComponent as ProcessedIcon } from '../../../../assets/img/zen/transactionHeader/processed.svg';
import { ReactComponent as UserIcon } from '../../../../assets/img/zen/transactionHeader/user.svg';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import {
  ParticipantValueRoleEnum,
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
  TransactionResponseTransactionTypeEnum,
} from '../../../../openapi/arrakis';
import { fetchDropbox } from '../../../../slices/DropboxSlice';
import { setActiveTransition } from '../../../../slices/QuickActionSlice';
import { getProcessTransaction } from '../../../../slices/TransactionSlice';
import { FeatureFlagTypeEnum, RootState } from '../../../../types';
import { displayFormattedAmountWithCurrency } from '../../../../utils/CurrencyUtils';
import { getParticipantName } from '../../../../utils/ParticipantHelper';
import {
  dateInPast,
  getAllTransactionParticipantsByRole,
  getTransactionActualClosingDate,
  getTransactionTransitionToReadableName,
  isCanadaTransaction,
  isTransactionInClosedState,
} from '../../../../utils/TransactionHelper';
import {
  getTransactionVariant,
  isPersonalDealTransaction,
} from '../../../../utils/TransactionUtils';
import CopyToClipboardHover from '../../../CopyToClipboardHover';
import Hover from '../../../Hover';
import ExcludeRealTitleOfficer from '../../../auth/ExcludeRealTitleOfficer';
import ZenSimpleConfirmationModal from '../../Modal/ZenSimpleConfirmationModal';
import ZenEditDealInformationForm from '../ZenEditDealInformationForm';
import ZenMarkAsCompliantStatus from './ZenMarkAsCompliantStatus';
import ZenTransactionCheckType from './ZenTransactionCheckType';
import ZenTransactionDealTypeLabel from './ZenTransactionDealTypeLabel';
import ZenTransactionHeaderRowCell from './ZenTransactionHeaderRowCell';
import ZenTransactionHeaderStatus from './ZenTransactionHeaderStatus';
import ZenTransactionPersonalDealLabel from './ZenTransactionPersonalDealLabel';
import ZenTransactionRepresenteeType from './ZenTransactionRepresenteeType';
import ZenTransitionConfirmationModal from './ZenTransitionConfirmationModal';

interface ZenTransactionHeaderProps {
  transaction: TransactionResponse;
}

const ZenTransactionHeader: React.FC<ZenTransactionHeaderProps> = ({
  transaction,
}) => {
  const dispatch = useDispatch();
  const {
    quickAction: { activeTransition },
    dropbox: { dropboxById },
    team: { teamDetailOverview },
  } = useSelector((state: RootState) => state);
  const [
    retryTransition,
    setRetryTransition,
  ] = useState<TransactionLifecycleStateValueStateEnum>();
  const [openDealInfoSidebar, setOpenDealInfoSidebar] = useState(false);

  const allBuyers = getAllTransactionParticipantsByRole(
    transaction,
    ParticipantValueRoleEnum.Buyer,
  );
  const allTenants = getAllTransactionParticipantsByRole(
    transaction,
    ParticipantValueRoleEnum.Tenant,
  );

  const internalReferral = useFeatureFlag(
    FeatureFlagTypeEnum.INTERNAL_REFERRAL,
  );

  const isTransactionLeaseOrSale =
    transaction?.transactionType ===
      TransactionResponseTransactionTypeEnum.Lease ||
    transaction?.transactionType ===
      TransactionResponseTransactionTypeEnum.Sale;

  const allSellers = getAllTransactionParticipantsByRole(
    transaction,
    ParticipantValueRoleEnum.Seller,
  );
  const allLandlords = getAllTransactionParticipantsByRole(
    transaction,
    ParticipantValueRoleEnum.Landlord,
  );

  const allSellersAndLandlordsNames = [
    ...allSellers,
    ...allLandlords,
  ].map((participant) => getParticipantName(participant));

  const allBuyersAndTenantsNames = [
    ...allBuyers,
    ...allTenants,
  ].map((participant) => getParticipantName(participant));

  const dropboxId = transaction?.dropboxId!;
  const dropbox = dropboxById[dropboxId];

  useEffect(() => {
    if (!!dropboxId && dropbox?.id !== dropboxId) {
      dispatch(fetchDropbox(dropboxId));
    }
  }, [dispatch, dropbox?.id, dropboxId]);

  const closingDate = DateTime.fromISO(transaction.skySlopeEscrowClosingDate!, {
    zone: 'utc',
  }).toISODate();
  const today = DateTime.local({ zone: 'utc' }).toISODate();

  return (
    <header className='px-4 md:px-8 py-6 flex flex-col md:flex-row items-start justify-between md:gap-x-4 bg-zen-dark-13 text-white mx-4 my-1 rounded-[15px] scrollbar overflow-x-auto overflow-auto space-y-4 md:space-y-0'>
      <div className='flex flex-col md:w-2/3'>
        <div className='text-2xl font-primary-medium'>
          <p className='font-zen-body font-bold text-[22px] leading-7 text-white'>
            {transaction.address?.oneLine}
          </p>
          <div className='flex flex-col md:flex-row items-start md:items-center space-x-0 md:space-x-2 space-y-2 md:space-y-0 my-3'>
            <div className='flex flex-row items-center space-x-1'>
              <FontAwesomeIcon icon={faTag} size='sm' />
              <p className='font-zen-body font-normal text-xl text-white min-w-max'>
                {displayFormattedAmountWithCurrency(transaction.price)}
              </p>
            </div>
            <ZenTransactionDealTypeLabel
              dealType={transaction?.transactionType!}
            />
            {internalReferral ? (
              isTransactionLeaseOrSale && (
                <ZenTransactionRepresenteeType transaction={transaction} />
              )
            ) : (
              <ZenTransactionRepresenteeType transaction={transaction} />
            )}
            {isPersonalDealTransaction(transaction) && (
              <ZenTransactionPersonalDealLabel />
            )}
            <div className='flex flex-row items-center bg-zen-gray-6 rounded-[7px] px-2'>
              <p className='font-zen-body font-bold text-sm text-center text-white min-w-max'>{`Code: ${transaction?.code!}`}</p>
              <div className='font-zen-body font-normal text-sm text-white'>
                <CopyToClipboardHover
                  value={transaction?.code!}
                  copyToltipLabel='Copy Code'
                  copyIcon={
                    <CopyIcon width={24} height={24} className='text-white' />
                  }
                />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 md:gap-x-8 gap-y-4 md:gap-y-0 mt-4 md:mt-8'>
            <div className='flex flex-col gap-y-4'>
              <ZenTransactionHeaderRowCell
                icon={
                  <OfficeIcon width={24} height={24} className='text-white' />
                }
                content={
                  <div className='flex flex-row items-center space-x-2'>
                    <Link to={`/offices/${transaction.office?.id}`}>
                      <p className='flex flex-grow font-zen-body font-semibold text-sm text-white'>
                        {transaction.office?.name}
                      </p>
                    </Link>
                    <ZenTransactionCheckType transaction={transaction} />
                  </div>
                }
              />
              {transaction.listingTransaction?.price && (
                <ZenTransactionHeaderRowCell
                  icon={
                    <DollarIcon width={24} height={24} className='text-white' />
                  }
                  content={
                    <div className='flex flex-row items-center space-x-2'>
                      <p className='font-zen-body font-semibold text-sm text-white'>
                        List Price:
                      </p>
                      <p className='font-zen-body font-normal text-sm text-white'>
                        {displayFormattedAmountWithCurrency(
                          transaction.listingTransaction?.price,
                        )}
                      </p>
                    </div>
                  }
                />
              )}

              <ZenTransactionHeaderRowCell
                icon={
                  <DollarIcon width={24} height={24} className='text-white' />
                }
                content={
                  <div className='flex flex-row items-center space-x-2'>
                    <p className='font-zen-body font-semibold text-sm text-white'>
                      Commission:
                    </p>
                    <p className='font-zen-body font-normal text-sm text-white space-x-0.5'>
                      <span>{transaction.grossCommissionPercentage}</span>
                      <span>|</span>
                      <span>
                        {displayFormattedAmountWithCurrency(
                          transaction.grossCommission,
                        )}
                      </span>
                    </p>
                  </div>
                }
              />
              <ExcludeRealTitleOfficer>
                <ZenTransactionHeaderRowCell
                  icon={
                    <UserIcon width={24} height={24} className='text-white' />
                  }
                  content={
                    <div className='flex flex-row items-center space-x-2'>
                      <p className='font-zen-body font-semibold text-sm text-white'>
                        Buyer/Tenant:
                      </p>
                      <p
                        data-testId='buyer-tenant-value'
                        className='font-zen-body font-normal text-sm text-white space-x-0.5'
                      >
                        {allBuyersAndTenantsNames?.length > 0
                          ? allBuyersAndTenantsNames.join(', ')
                          : 'N/A'}
                      </p>
                    </div>
                  }
                />
                <ZenTransactionHeaderRowCell
                  icon={
                    <UserIcon width={24} height={24} className='text-white' />
                  }
                  content={
                    <div className='flex flex-row items-center space-x-2'>
                      <p className='font-zen-body font-semibold text-sm text-white'>
                        Seller/Landlord:
                      </p>
                      <p className='font-zen-body font-normal text-sm text-white space-x-0.5'>
                        {allSellersAndLandlordsNames?.length > 0
                          ? allSellersAndLandlordsNames.join(', ')
                          : 'N/A'}
                      </p>
                    </div>
                  }
                />
              </ExcludeRealTitleOfficer>
              <ZenTransactionHeaderRowCell
                icon={
                  <UserIcon width={24} height={24} className='text-white' />
                }
                content={
                  <div className='flex flex-row items-center space-x-2'>
                    <p className='font-zen-body font-semibold text-sm text-white'>
                      Transaction Owner:
                    </p>
                    <p className='font-zen-body font-normal text-sm text-white space-x-0.5'>
                      {transaction?.transactionOwner?.name || 'N/A'}
                    </p>
                  </div>
                }
              />
              {!!teamDetailOverview && (
                <ZenTransactionHeaderRowCell
                  icon={
                    <UserIcon width={24} height={24} className='text-white' />
                  }
                  content={
                    <div className='flex flex-row items-center space-x-2'>
                      <p className='font-zen-body font-semibold text-sm text-white'>
                        Team
                      </p>
                      <Link to={`/teams/${teamDetailOverview.id}`}>
                        <p className='font-zen-body font-normal text-sm text-white space-x-0.5'>
                          {teamDetailOverview?.name || 'N/A'}
                        </p>
                      </Link>
                    </div>
                  }
                />
              )}
            </div>
            <div className='flex flex-col gap-y-4'>
              {!isCanadaTransaction(transaction!) && (
                <ZenTransactionHeaderRowCell
                  icon={
                    <CalenderNoteIcon
                      width={24}
                      height={24}
                      className='text-white'
                    />
                  }
                  content={
                    <div className='flex flex-row items-center space-x-2'>
                      <p className='font-zen-body font-semibold text-sm text-white'>
                        Year Built:
                      </p>
                      <p className='font-zen-body font-normal text-sm text-white'>
                        {transaction.yearBuilt ? transaction.yearBuilt : 'N/A'}
                      </p>
                    </div>
                  }
                />
              )}

              <ZenTransactionHeaderRowCell
                icon={
                  <CalenderCheckIcon
                    width={24}
                    height={24}
                    className='text-white'
                  />
                }
                content={
                  <div className='flex flex-row items-center space-x-2'>
                    <Hover
                      hoverComponent={
                        <div className='text-zen-dark-12 font-medium text-sm font-zen-body px-2'>
                          Contract Acceptance Date is the date the purchase
                          contract was executed by both parties.
                        </div>
                      }
                      config={{ trigger: 'hover', placement: 'top' }}
                    >
                      <p
                        aria-label='AcceptanceDate'
                        className='font-zen-body font-semibold text-sm text-white cursor-pointer'
                      >
                        Acceptance Date:
                      </p>
                    </Hover>
                    <p
                      data-testId='acceptance-date-value'
                      className='font-zen-body font-normal text-sm text-white space-x-0.5'
                    >
                      {transaction.contractAcceptanceDate
                        ? DateTime.fromISO(
                            transaction.contractAcceptanceDate,
                          ).toFormat('MM/dd/yyyy')
                        : 'N/A'}
                    </p>
                  </div>
                }
              />

              <ZenTransactionHeaderRowCell
                icon={
                  <CalenderHistoryIcon
                    width={24}
                    height={24}
                    className='text-white'
                  />
                }
                content={
                  <div className='flex flex-row items-center space-x-2'>
                    <Hover
                      hoverComponent={
                        <div className='text-zen-dark-12 font-medium text-sm font-zen-body px-2'>
                          Estimated Closing Date is the date that the property
                          is expected to close and fully transfer to the other
                          party. This date can be adjusted, please remember to
                          upload the appropriate amendments and/or addenda to
                          the checklist if this date changes.
                        </div>
                      }
                      config={{ trigger: 'hover', placement: 'top' }}
                    >
                      <p
                        aria-label='estimatedClosingDate'
                        className='font-zen-body font-semibold text-sm text-white cursor-pointer'
                      >
                        Estimated Closing Date:
                      </p>
                    </Hover>
                    <p className='font-zen-body font-normal text-sm text-white'>
                      {transaction.skySlopeEscrowClosingDate
                        ? DateTime.fromISO(
                            transaction.skySlopeEscrowClosingDate,
                          ).toFormat('MM/dd/yyyy')
                        : 'N/A'}
                    </p>

                    {transaction.skySlopeEscrowClosingDate &&
                      !isTransactionInClosedState(transaction) &&
                      dateInPast(closingDate, today) && (
                        <Hover
                          hoverComponent={
                            <div className='text-zen-dark-12 font-medium text-base font-zen-body px-6 '>
                              Expired
                            </div>
                          }
                          config={{ trigger: 'hover', placement: 'top' }}
                        >
                          <FontAwesomeIcon
                            icon={regular('circle-exclamation')}
                            color='#FF728C'
                            size='xs'
                            className='pt-2 cursor-pointer'
                            data-testid='expired'
                            onClick={() => setOpenDealInfoSidebar(true)}
                          />
                        </Hover>
                      )}
                  </div>
                }
              />
              {isCanadaTransaction(transaction!) && (
                <ZenTransactionHeaderRowCell
                  icon={
                    <CalenderNoteIcon
                      width={24}
                      height={24}
                      className='text-white'
                    />
                  }
                  content={
                    <div className='flex flex-row items-center space-x-2'>
                      <Hover
                        hoverComponent={
                          <div className='text-zen-dark-12 font-medium text-sm font-zen-body px-2'>
                            Firm Date is the date the transaction has become
                            firm and all the conditions have been removed. If
                            the contract is conditional, the Firm Date is the
                            latest conditions date on the contract; if the
                            contract is not conditional, the Firm Date is the
                            date of contract acceptance.
                          </div>
                        }
                        config={{ trigger: 'hover', placement: 'top' }}
                      >
                        <p
                          aria-label='firmDate'
                          className='font-zen-body font-semibold text-sm text-white cursor-pointer'
                        >
                          Firm Date:
                        </p>
                      </Hover>
                      <p className='font-zen-body font-normal text-sm text-white'>
                        {transaction.firmDate
                          ? DateTime.fromISO(transaction.firmDate).toFormat(
                              'MM/dd/yyyy',
                            )
                          : 'N/A'}
                      </p>
                    </div>
                  }
                />
              )}
              <ZenTransactionHeaderRowCell
                icon={
                  <CalenderCheckIcon
                    width={24}
                    height={24}
                    className='text-white'
                  />
                }
                content={
                  <div className='flex flex-row items-center space-x-2'>
                    <Hover
                      hoverComponent={
                        <div className='text-zen-dark-12 font-medium text-sm font-zen-body px-2'>
                          Actual Closing Date is the closing date that is
                          verified by the Real Transactions Team and is entered
                          when the Transactions Team Approves the Transaction
                          for processing in reZEN.
                        </div>
                      }
                      config={{ trigger: 'hover', placement: 'top' }}
                    >
                      <p
                        aria-label='ActualClosingDate'
                        className='font-zen-body font-semibold text-sm text-white cursor-pointer'
                      >
                        Actual Closing Date:
                      </p>
                    </Hover>
                    <p className='font-zen-body font-normal text-sm text-white space-x-0.5'>
                      {getTransactionActualClosingDate(transaction)}
                    </p>
                  </div>
                }
              />
              <ZenTransactionHeaderRowCell
                icon={
                  <ProcessedIcon
                    width={24}
                    height={24}
                    className='text-white'
                  />
                }
                content={
                  <div className='flex flex-row items-center space-x-2'>
                    <Hover
                      hoverComponent={
                        <div className='text-zen-dark-12 font-medium text-sm font-zen-body px-2'>
                          Transaction Process date is the date when the
                          Transaction was processed for closing in reZEN, and if
                          applicable, payments were scheduled.
                        </div>
                      }
                      config={{ trigger: 'hover', placement: 'top' }}
                    >
                      <p
                        aria-label='TransactionProcessed'
                        className='font-zen-body font-semibold text-sm text-white cursor-pointer'
                      >
                        Transaction Processed:
                      </p>
                    </Hover>
                    <p className='font-zen-body font-normal text-sm text-white space-x-0.5'>
                      {transaction.rezenClosedAt
                        ? DateTime.fromMillis(
                            transaction.rezenClosedAt,
                          ).toFormat('MM/dd/yyyy')
                        : 'no date yet'}
                    </p>
                  </div>
                }
              />
              {!!dropbox?.emailAddress && (
                <ZenTransactionHeaderRowCell
                  icon={
                    <div className='w-6 h-6 flex items-center justify-center'>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className='text-white'
                        fontSize={14}
                      />
                    </div>
                  }
                  content={
                    <div className='flex flex-row items-center flex-wrap'>
                      <p className='font-zen-body font-semibold text-sm text-white whitespace-nowrap mr-2'>
                        Transaction Email:
                      </p>
                      <div className='flex-grow flex items-center justify-start'>
                        <a
                          href={`mailto:${dropbox.emailAddress}`}
                          className='font-zen-body font-normal text-sm text-white space-x-0.5 whitespace-nowrap'
                        >
                          {dropbox.emailAddress}
                        </a>
                        <div className='font-zen-body font-normal text-sm text-white'>
                          <CopyToClipboardHover
                            value={dropbox.emailAddress}
                            copyToltipLabel='Copy Transaction Email'
                            copyIcon={
                              <CopyIcon
                                width={24}
                                height={24}
                                className='text-white'
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='md:w-1/3'>
        <div className='mb-4'>
          <ZenMarkAsCompliantStatus transaction={transaction} />
        </div>
        <ZenTransactionHeaderStatus
          setRetryTransition={setRetryTransition}
          transaction={transaction}
        />
      </div>
      <ZenSimpleConfirmationModal
        isOpen={!!retryTransition}
        variant='info'
        title={`Retry ${getTransactionTransitionToReadableName(
          transaction,
          retryTransition!,
        )}?`}
        subtitle={`Are you sure you'd like to reprocess this transaction?`}
        onClose={() => {
          setRetryTransition(undefined);
        }}
        onConfirm={async () => {
          await dispatch(getProcessTransaction(transaction.id!));
          setRetryTransition(undefined);
        }}
        confirmButtonText='Reprocess'
        isSubmitting={false}
      />
      <ZenTransitionConfirmationModal
        isOpen={!!activeTransition}
        onClose={() => dispatch(setActiveTransition(undefined))}
        transaction={transaction}
        activeTransition={activeTransition}
        variant={getTransactionVariant(activeTransition!)}
      />
      {openDealInfoSidebar && (
        <ZenEditDealInformationForm
          isOpen
          onClose={() => setOpenDealInfoSidebar(false)}
          transaction={transaction}
        />
      )}
    </header>
  );
};

export default ZenTransactionHeader;
