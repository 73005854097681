import { isEmpty } from 'lodash';
import React from 'react';
import {
  AgentDebtResponse,
  AgentDebtResponseStatusEnum,
} from '../../openapi/arrakis';
import { displayAmount } from '../../utils/CurrencyUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import DebtPendingBalance from './DebtPendingBalance';
import DebtStatus from './DebtStatus';

interface DebtsCardRowCellProps {
  debt: AgentDebtResponse;
}

const DebtsCardRowCell: React.FC<DebtsCardRowCellProps> = ({ debt }) => {
  const { debtType, status, amount } = debt || {};
  const isVoidDebtType = status === AgentDebtResponseStatusEnum.Cancelled;

  return (
    <div className='flex flex-row items-start justify-between px-4 py-3'>
      <div className='flex flex-col space-y-2.5'>
        <span className='font-zen-body font-normal text-base leading-5 text-zen-dark-7'>
          {capitalizeEnum(debtType!)}
        </span>
        {!isEmpty(debt?.pendingAmount) && !isVoidDebtType && (
          <DebtPendingBalance pendingAmount={debt?.pendingAmount!} />
        )}
        {!!status && !isVoidDebtType && <DebtStatus status={status} />}
      </div>
      {!isEmpty(amount) && !isVoidDebtType && (
        <p className='font-zen-body font-semibold leading-4 text-zen-dark-8 text-sm text-right'>
          {displayAmount(amount, { hideCurrency: true })}
        </p>
      )}
      {!!status && isVoidDebtType && <DebtStatus status={status} />}
    </div>
  );
};

export default DebtsCardRowCell;
