import React from 'react';
// @ts-ignore
import { Snake } from 'react-snake-lib';

export interface SnakeGameProps {}

const SnakeGame: React.FC<SnakeGameProps> = () => {
  const [score, setScore] = React.useState<number>(0);

  return (
    <div className='break-room-img w-full h-full flex flex-col items-center justify-center'>
      <div className='flex flex-col justify-center'>
        <div className='flex flex-row justify-between items-center px-2 mb-3'>
          <p className='text-base text-left flex-grow font-semibold font-zen-body text-white'>
            Snake Game
          </p>
          <p className='font-semibold font-zen-body text-white'>
            Score: {score}
          </p>
        </div>
        <Snake
          onScoreChange={(score: number) => setScore(score)}
          onGameOver={() => {}}
          onGameStart={() => {}}
          width={
            window.innerWidth > 600
              ? '500px'
              : window.innerWidth > 500
              ? '400px'
              : '300px'
          }
          height={
            window.innerWidth > 600
              ? '500px'
              : window.innerWidth > 500
              ? '400px'
              : '300px'
          }
          bgColor='silver'
          innerBorderColor='#b1b0b0'
          snakeSpeed={90}
          borderColor='black'
          snakeColor='#3e3e3e'
          snakeHeadColor='#1a1a1a'
          appleColor='tomato'
          borderRadius={5}
          snakeHeadRadius={1}
          borderWidth={0}
          shakeBoard
          boxShadow='rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
          size={16}
          startGameText='Start Game'
          startButtonStyle={{
            color: 'white',
            padding: '6px 20px',
            backgroundColor: '#1a1a1a',
            borderRadius: '10px',
            fontSize: '17px',
            fontWeight: '600',
            cursor: 'pointer',
          }}
          startButtonHoverStyle={{
            backgroundColor: '#4f4d4d',
          }}
          noWall={false}
        />
      </div>
    </div>
  );
};

export default SnakeGame;
