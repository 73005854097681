import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import ZenUserPill from '../ZenUserPill';
import {
  TransactionControllerApi,
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
  TransactionResponseCountryEnum,
} from '../../../openapi/arrakis';
import { CardItem, RootState } from '../../../types';
import ZenCardWithItems from '../ZenCardWithItems';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenTransactionStatus from './ZenTransactionStatus';

interface ZenInternalReferralTransactionProps {
  transaction: TransactionResponse;
}

const ZenInternalReferralTransaction: React.FC<ZenInternalReferralTransactionProps> = ({
  transaction,
}) => {
  const {
    userIds: { agentById },
  } = useSelector((state: RootState) => state);
  const transactionId =
    transaction?.originatingTransactionId ||
    transaction?.referringTransactionId;

  const [
    originatingTransaction,
    setOriginatingTransaction,
  ] = useState<TransactionResponse>();

  useEffect(() => {
    if (!!transactionId) {
      const getData = async () => {
        const { data } = await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).getTransaction(transactionId!);
        setOriginatingTransaction(data);
      };
      getData();
    }
  }, [transactionId]);

  const items: CardItem[] = [
    {
      name: transaction?.originatingTransactionId
        ? 'Originating Agent'
        : 'Referral Agent',
      value: originatingTransaction?.transactionOwner?.name ? (
        <Link to={`/people/${originatingTransaction?.transactionOwner?.id}`}>
          <div className='mr-[-12px]'>
            <ZenUserPill
              name={originatingTransaction.transactionOwner?.name}
              prefixText={
                !transaction?.originatingTransactionId
                  ? 'Real Broker'
                  : undefined
              }
              backgroundVariant='background'
              imageUrl={
                agentById[originatingTransaction.transactionOwner?.id!]
                  ?.avatar || ''
              }
            />
          </div>
        </Link>
      ) : (
        'N/A'
      ),
    },
    {
      name: transaction?.originatingTransactionId
        ? 'Originating Transaction'
        : 'Referral Transaction',
      value: originatingTransaction?.code ? (
        <div className='mr-[-12px]'>
          <div className='inline-flex items-center space-x-2 border border-zen-dark-4 rounded-lg px-2 py-1'>
            <Link to={`/transactions/${originatingTransaction.id}`}>
              <div className='text-sm whitespace-nowrap mr-1.5'>
                {originatingTransaction?.code}
              </div>
            </Link>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </div>
        </div>
      ) : (
        'N/A'
      ),
    },
    {
      name: transaction?.originatingTransactionId
        ? 'Originating Transaction Status'
        : 'Referral Transaction Status',
      value: (
        <div className='mr-[-12px]'>
          <ZenTransactionStatus
            status={
              originatingTransaction?.lifecycleState?.state ||
              (('' as unknown) as TransactionLifecycleStateValueStateEnum)
            }
            country={
              (originatingTransaction?.country! as unknown) as TransactionResponseCountryEnum
            }
          />
        </div>
      ),
    },
  ];

  if (!transactionId) {
    return null;
  }

  return (
    <div className='h-fit'>
      <ZenCardWithItems
        title={
          transaction?.originatingTransactionId
            ? 'Originating Transaction'
            : 'Referral Transaction'
        }
        items={items}
        variant='regular'
      />
    </div>
  );
};

export default ZenInternalReferralTransaction;
