import {
  faArrowLeftLong,
  faPaperPlane,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { AnnouncementDefRequestStatusEnum } from '../../../openapi/mercury';
import { RootState } from '../../../types';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenControlledToggleInput from '../Input/ZenControlledToggleInput';
import ZenButton from '../ZenButton';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION } from '../../../utils/AnnouncementUtils';
import AnnouncementPagination from './AnnouncementPagination';
import {
  CreateAnnouncementFormData,
  CreateAnnouncementStepName,
} from './CreateAnnouncementSidebarModel';
import TimeOrEventAnnouncementType from './TimeOrEventAnnouncementType';
import WhoIsThisAnnouncementFor from './WhoIsThisAnnouncementFor';

const AnnouncementRulesStep: StepByStepComponent<
  CreateAnnouncementFormData,
  CreateAnnouncementStepName
> = ({
  form: {
    control,
    watch,
    setValue,
    setError,
    trigger,
    formState: { isSubmitting },
  },
  onSubmit,
  onPrevious,
}) => {
  const formValues = watch();
  const { announcementDefinitionDetail } = useSelector(
    (state: RootState) => state.announcement,
  );

  const setUsers = (users: string[]) => {
    setValue('userId', { label: '', value: '' });
    setValue('allUserIds', users);
  };
  const setTeams = (teams: string[]) => {
    setValue('teamId', { label: '', value: '' });
    setValue('allTeamIds', teams);
  };

  useDidUpdateEffect(() => {
    setValue('stateOrProvinces', []);
  }, [formValues.locationType]);

  useDidUpdateEffect(() => {
    if (formValues.stateOrProvinces?.length === 0) {
      setValue('announcementFor', { label: '', value: '' as any });
    }
  }, [formValues.stateOrProvinces]);

  const handleNext = () => {
    if (
      formValues.announcementFor?.value ===
        ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentIdOneOf &&
      !formValues.allUserIds?.length
    ) {
      setError('userId', { message: 'Please select users' });
      return;
    }
    setValue('status', AnnouncementDefRequestStatusEnum.Active);
    onSubmit();
  };

  return (
    <div className='w-full h-full flex flex-col min-h-full scrollbar overflow-y-auto px-4 pt-3'>
      <div className='pb-28 pr-3'>
        <div>
          <ZenButton
            label='Back'
            variant='secondary-gray-outline'
            LeftIconComponent={
              <FontAwesomeIcon
                icon={faArrowLeftLong}
                className='text-base text-dark mr-1'
              />
            }
            onClick={onPrevious}
          />
        </div>
        <div className='pt-9'>
          <p className='text-xl leading-6 font-zen-body font-bold'>
            Announcement Rules
          </p>
        </div>
        <div className='w-full flex flex-row items-center pb-7 pt-6 mb-1 border-b border-b-zen-dark-4'>
          <div className='w-full flex flex-row items-start justify-between'>
            <div className='flex-grow flex flex-row items-start'>
              <p className='flex-grow font-zen-body font-medium text-base leading-6 text-dark'>
                1. Is there a mandatory action required?
              </p>
            </div>
            <div>
              <ZenControlledToggleInput<
                CreateAnnouncementFormData,
                'mandatoryActionRequired'
              >
                control={control}
                name='mandatoryActionRequired'
              />
            </div>
          </div>
        </div>
        <div className='w-full pb-7 pt-10 border-b border-b-zen-dark-4'>
          <TimeOrEventAnnouncementType
            control={control}
            driver={formValues.driver}
            startDate={formValues.deliverAfter}
            setValue={setValue}
          />
        </div>
        <div className='w-full mt-7'>
          <WhoIsThisAnnouncementFor
            control={control}
            recipientType={formValues.announcementFor?.value}
            userId={formValues.userId}
            teamId={formValues.teamId}
            allUserIds={formValues.allUserIds}
            allTeamIds={formValues.allTeamIds}
            selectedStatesOptions={formValues.stateOrProvinces}
            setUsers={setUsers}
            setTeams={setTeams}
            joinedStartDate={formValues.announcementForJoinedBetween.startDate}
            selectSpecificRecipients={formValues.selectSpecificRecipients}
            locationType={formValues.locationType}
            setValue={setValue}
          />
        </div>
      </div>
      <AnnouncementPagination
        submitButtonText={announcementDefinitionDetail ? 'Update' : 'Publish'}
        LeftIconComponent={
          <FontAwesomeIcon
            icon={faPaperPlane}
            className='text-white text-sm mr-0.5'
          />
        }
        onNext={handleNext}
        isSubmitting={isSubmitting}
        trigger={trigger}
        formValues={formValues}
      />
    </div>
  );
};

export default AnnouncementRulesStep;
