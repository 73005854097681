import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { faDollar } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import Hover from '../components/Hover';
import { StepByStepComponent } from '../components/StepByStep/StepByStepContainer';
import HasTransactionClosedModalHeader from '../components/transactions/HasTransactionClosedModalHeader';
import ZenControlledCurrencyInput from '../components/Zen/Input/ZenControlledCurrencyInput';
import ZenControlledDatePickerInput from '../components/Zen/Input/ZenControlledDatePickerInput';
import ZenControlledRadioInput from '../components/Zen/Input/ZenControlledRadioInput';
import ZenControlledTextAreaInput from '../components/Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenButton from '../components/Zen/ZenButton';
import ZenLink from '../components/Zen/ZenLink';
import { toggleClosedModal } from '../slices/TransactionSlice';
import {
  SentDepositToRealTypeEnum,
  TransactionClosedFormData,
  TransactionClosedStepName,
} from './HasTransactionClosedModal';

const MoneySentToRealYesStep: StepByStepComponent<
  TransactionClosedFormData,
  TransactionClosedStepName
> = ({
  form: {
    control,
    watch,
    formState: { isSubmitting },
  },
  onPrevious,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const depositType = watch('depositType');
  const isNotSure = depositType === SentDepositToRealTypeEnum.NotSure;

  return (
    <div className='w-full rounded-lg z-10 md:w-full max-w-[745px] shadow-sm shadow-gray-300 bg-white relative'>
      <HasTransactionClosedModalHeader
        title='👍🏼 Thanks! Just a few more questions!'
        onClose={() => dispatch(toggleClosedModal(false))}
      />
      <div className='w-full px-6 md:px-10 lg:px-[126px] py-6'>
        <div className='w-full flex flex-col items-center justify-center'>
          <div className='w-full m-4'>
            <ZenControlledCurrencyInput<
              TransactionClosedFormData,
              'depositAmount.amount'
            >
              name='depositAmount.amount'
              label='How much was sent to Real?'
              control={control}
              placeholder='0.00'
              startAdornment={
                <div className='h-full flex flex-row items-center justify-center ml-3'>
                  <FontAwesomeIcon
                    icon={faDollar}
                    className='text-primary-blue mx-1'
                  />
                </div>
              }
              shouldUnregister={false}
              isRequired
              rules={{ required: 'Required' }}
            />
          </div>
          <div className='w-full'>
            <ZenControlledDatePickerInput<
              TransactionClosedFormData,
              'depositedAt'
            >
              shouldUnregister={false}
              control={control}
              name='depositedAt'
              label='When was it sent?'
              placeholder='MM/DD/YYYY'
              icon={
                <FontAwesomeIcon
                  icon={faCalendar}
                  className='text-primary-blue ml-0.5'
                  size='lg'
                />
              }
              isRequired
              rules={{ required: 'Required' }}
            />
          </div>

          <div className='w-full m-4'>
            <ZenControlledRadioInput<TransactionClosedFormData, 'depositType'>
              control={control}
              label='How was the deposit sent to Real?'
              name='depositType'
              options={[
                {
                  label: 'Check Deposit',
                  value: SentDepositToRealTypeEnum.Check,
                },
                {
                  label: 'Wire Transfer',
                  value: SentDepositToRealTypeEnum.Wire,
                },
                {
                  label: "I'm not sure",
                  value: SentDepositToRealTypeEnum.NotSure,
                },
              ]}
              shouldUnregister={false}
              inlineOptions
              isRequired
              rules={{ required: 'Required' }}
            />
          </div>
          <div className='w-full mt-4'>
            <div className='mb-1'>
              <label
                className='font-zen-body font-semibold flex flex-row'
                htmlFor='depositConfirmationNumber'
              >
                <span className='mb-1'>Do you have a confirmation number?</span>
                <Hover
                  hoverComponent={
                    <div>
                      <p className='font-primary-regular text-sm text-dark px-2'>
                        Add check deposit or wire confirmation number.
                      </p>
                    </div>
                  }
                  config={{ trigger: 'hover', placement: 'top' }}
                >
                  <span className='mt-1 ml-2 w-3 h-3 rounded-full flex flex-row items-center justify-center font-semibold text-[10px] bg-primary-blue text-white'>
                    ?
                  </span>
                </Hover>
              </label>
            </div>
            <ZenControlledTextInput<
              TransactionClosedFormData,
              'depositConfirmationNumber'
            >
              name='depositConfirmationNumber'
              shouldUnregister={false}
              control={control}
              placeholder='Enter confirmation details'
            />
          </div>
          {isNotSure && (
            <div className='w-full m-4'>
              <label
                className='text-sm font-semibold mb-2'
                htmlFor='missingMoneyNote'
              />

              <ZenControlledTextAreaInput<
                TransactionClosedFormData,
                'missingMoneyNote'
              >
                name='missingMoneyNote'
                label='How will the money be sent to Real?'
                placeholder='Add Description'
                control={control}
                rows={4}
                shouldUnregister={false}
                className='border px-3 py-2 text-sm text-gray-800 rounded-2xl'
                noResize
                isRequired
                rules={{ required: 'Required' }}
              />
            </div>
          )}
          <div className='w-full mt-32 text-sm flex row items-center space-x-6'>
            <div className='whitespace-nowrap'>
              <ZenLink label='Go Back' size='small' onClick={onPrevious} />
            </div>
            <ZenButton
              label='Complete'
              variant='primary'
              onClick={onSubmit}
              isFullWidth
              isSubmitting={isSubmitting}
              isDisabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoneySentToRealYesStep;
