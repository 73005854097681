import WillableBg from '../../../../assets/img/willable_bg.png';

interface BeneficiaryIntroProps {}

const BeneficiaryIntro: React.FC<BeneficiaryIntroProps> = () => {
  return (
    <div className='w-full mb-14' aria-label='beneficiary-intro'>
      <div className='relative w-full rounded-t-xl h-[300px]'>
        <img
          src={WillableBg}
          className='w-full rounded-t-xl h-full'
          alt='willable revshare'
        />
        <div className='absolute h-[300px] top-0 flex flex-row items-center justify-center'>
          <p className='md:text-[64px] text-5xl text-white font-bold font-primary md:px-12 px-5'>
            It&apos;s never too early to plan ahead!
          </p>
        </div>
      </div>
      <div className='w-full p-6 pb-16 md:p-12 md:h-[300px] h-[250px] scrollbar overflow-y-scroll md:overflow-y-hidden'>
        <p className='w-full font-zen-body text-base text-dark leading-6'>
          <span>For </span>
          <span className='font-bold'>every full year of tenure</span>{' '}
          <span>
            with the brokerage,{' '}
            <span className='font-bold'>20% of your revenue share</span> will be
            paid to your designated beneficiary.
          </span>
        </p>
        <p className='w-full font-zen-body text-base text-dark leading-6 mt-3 mb-4'>
          This means that you can start building a better future for your family
          now, while also benefiting from ongoing payments based on your
          contributions to the brokerage.
        </p>
      </div>
    </div>
  );
};

export default BeneficiaryIntro;
