import {
  faArrowUpRightFromSquare,
  faLocationDot,
} from '@fortawesome/pro-regular-svg-icons';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReferralTransitionStatus from './ReferralTransitionStatus';

interface ReferralAgentProfileProps {
  isShort?: boolean;
}

const ReferralAgentProfile: React.FC<ReferralAgentProfileProps> = ({
  isShort = false,
}) => {
  return (
    <div className='flex-col justify-start items-start gap-6 flex w-full'>
      <div className='h-16 justify-between items-center flex w-full'>
        <div className='justify-start items-center gap-2 flex'>
          <img
            className='w-16 h-16 rounded-[32px]'
            src='https://via.placeholder.com/64x64'
            alt='avatar'
          />
          <div className='flex-col justify-start items-start inline-flex gap-1'>
            <div className='self-stretch justify-start items-center gap-1 inline-flex'>
              <div className='text-primary-dark text-lg font-semibold font-inter leading-normal'>
                Taylor Swift
              </div>
              <FontAwesomeIcon
                icon={faBadgeCheck}
                className='text-rezen-blue-600 text-sm font-black '
              />
            </div>
            <div className='self-stretch'>
              <FontAwesomeIcon
                icon={faLocationDot}
                className='text-grey-6 text-sm font-normal pr-1'
              />
              <span className='text-gray-600 text-sm font-normal font-inter leading-tight'>
                San Diego, United States
              </span>
            </div>
          </div>
        </div>
        {!isShort && (
          <div className='justify-end items-center gap-2 flex'>
            <ReferralTransitionStatus referralDetails={{}} />
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              className='text-rezen-blue-600 text-sm'
            />
          </div>
        )}
      </div>
      {!isShort && (
        <div className='flex-col justify-start items-start gap-4 flex'>
          <div className='self-stretch text-primary-dark text-base font-light font-inter leading-snug'>
            I&apos;m baby skateboard tofu pop-up meggings locavore typewriter,
            flexitarian cliche master cleanse kitsch church-key. Master cleanse
            migas paleo, chartreuse hexagon DSA raw denim hot chicken umami
            vexillologist stumptown echo park vibecession.
          </div>
        </div>
      )}
    </div>
  );
};

export default ReferralAgentProfile;
