import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import {
  MoneyValueCurrencyEnum,
  TaxDocumentLineItemResponse,
  TaxDocumentLineItemResponseSourceEnum,
  TaxDocumentLineItemResponseTypeEnum,
} from '../../openapi/arrakis';
import { getYearlyTaxDocumentDetails } from '../../slices/TaxSlice';
import { RootState } from '../../types';
import { displayAmount, zeroMoneyValue } from '../../utils/CurrencyUtils';
import EmptyTray from '../../assets/img/EmptyTray.png';
import AuthorizationContainer from '../auth/AuthorizationContainer';
import ZenRoute from '../Zen/ZenRoute';
import ZenTabs from '../Zen/ZenTabs';
import ZenFixedDataTable from '../Zen/ZenFixedDataTable';
import ZenResourceContainer from '../Zen/ZenResourceContainer';
import ZenMilliDateCell from '../Zen/Table/Cell/ZenMilliDateCell';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenDateCell from '../Zen/Table/Cell/ZenDateCell';

export const equityPaymentsColumns: Array<
  Column<TaxDocumentLineItemResponse>
> = [
  {
    Header: 'Transaction',
    accessor: 'transactionAddress',
    Cell: ({ value, row: { original } }) => (
      <Link
        to={`/transactions/${original.transactionId}`}
        className='font-zen-body font-normal'
      >
        <p>{value?.oneLine}</p>
      </Link>
    ),
    cardColSize: 7,
  },
  {
    Header: 'Closed Date',
    accessor: 'transactionRezenClosedAt',
    Cell: ({ value }) => (
      <div className='font-zen-body font-normal'>
        <ZenMilliDateCell date={value} />
      </div>
    ),
    disableFilters: true,
  },
];

export const equityPaymentCreditColumns: Array<
  Column<TaxDocumentLineItemResponse>
> = [
  {
    Header: 'Credit Type',
    accessor: 'agentCreditType',
    Cell: ({ value }) => (
      <p className='font-zen-body font-normal'>
        {value ? capitalizeEnum(value) : 'N/A'}
      </p>
    ),
    cardColSize: 7,
  },
  {
    Header: 'Issuer Title',
    accessor: 'agentCreditTitle',
    Cell: ({ value }) => <p className='font-zen-body font-normal'>{value}</p>,
    disableFilters: true,
  },
  {
    Header: 'Issuer On',
    accessor: 'agentCreditIssuedOn',
    Cell: ({ value }) => (
      <div className='font-zen-body font-normal'>
        <ZenDateCell date={value} />
      </div>
    ),
    disableFilters: true,
  },
];

type Match = {
  id: string;
  year: string;
};

const ZenTaxEquity: React.FC = () => {
  const {
    taxDoc: { taxDocumentsDetailResponse },
    agentDetail: {
      detailResponse: { data: detail },
    },
  } = useSelector((state: RootState) => state);
  const { id, year } = useParams() as Match;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getYearlyTaxDocumentDetails(id, +year));
  }, [dispatch, id, year]);

  const dynamicEquityPaymentsColumns: Array<
    Column<TaxDocumentLineItemResponse>
  > = [
    ...equityPaymentsColumns,
    {
      Header: `Contribution Amount (${detail?.defaultCurrency})`,
      accessor: 'amount',
      id: 'contribution',
      Cell: ({ value }) => (
        <p className='font-zen-body font-normal'>
          {displayAmount(value, { hideCurrency: true })}
        </p>
      ),
      disableFilters: true,
    },
  ];

  const dynamicEquityPaymentsCreditColumns: Array<
    Column<TaxDocumentLineItemResponse>
  > = [
    ...equityPaymentCreditColumns,
    {
      Header: `Amount (${detail?.defaultCurrency})`,
      accessor: 'amount',
      Cell: ({ value }) => (
        <p className='font-zen-body font-normal'>
          {displayAmount(value, { hideCurrency: true })}
        </p>
      ),
      disableFilters: true,
    },
  ];
  const equityPayments = taxDocumentsDetailResponse.data?.lineItems!.filter(
    (item) =>
      item.type === TaxDocumentLineItemResponseTypeEnum.EquityContribution &&
      item.source !== TaxDocumentLineItemResponseSourceEnum.Credit,
  );
  const equityPaymentsCredits = taxDocumentsDetailResponse.data?.lineItems!.filter(
    (item) =>
      item.type === TaxDocumentLineItemResponseTypeEnum.EquityContribution &&
      item.source === TaxDocumentLineItemResponseSourceEnum.Credit,
  );
  const creditAmount =
    equityPaymentsCredits
      ?.map((item) => item.amount!.amount!)
      .reduce((prev, curr) => prev + curr, 0) || 0;

  const [activeTab, setActiveTab] = useState('Equity');

  return (
    <ZenRoute title='Equity'>
      <div className='p-4'>
        <div className='space-y-4'>
          <p className='font-zen-body font-medium text-xl text-zen-dark-9'>
            {year} Equity & Credits:{' '}
            {displayAmount(
              taxDocumentsDetailResponse.data?.equityContributed ||
                zeroMoneyValue(detail?.defaultCurrency!),
            )}
          </p>

          <AuthorizationContainer asyncResponse={taxDocumentsDetailResponse}>
            <ZenResourceContainer
              loading={taxDocumentsDetailResponse.loading}
              isEmpty={false}
              resourceName='equity payment'
            >
              <ZenTabs
                tabs={[
                  {
                    name: 'Equity',
                    subtitle: `${displayAmount({
                      amount:
                        (taxDocumentsDetailResponse.data?.equityContributed
                          ?.amount || 0) - creditAmount,
                      currency:
                        taxDocumentsDetailResponse.data?.equityContributed
                          ?.currency ||
                        ((detail?.defaultCurrency! as unknown) as MoneyValueCurrencyEnum),
                    })}`,
                    TabComponent: (
                      <ZenRoute title='Equity'>
                        <div className='pt-4 space-y-2'>
                          <p>Equity Payments ({equityPayments?.length || 0})</p>
                          <ZenFixedDataTable<TaxDocumentLineItemResponse>
                            columns={dynamicEquityPaymentsColumns}
                            data={equityPayments || []}
                            resourceName='Equity Payment'
                            hidePagination
                            hideFilters
                            emptyIconComponent={
                              <img
                                src={EmptyTray}
                                alt='EmptyTrayImage'
                                className='h-20'
                              />
                            }
                          />
                        </div>
                      </ZenRoute>
                    ),
                  },
                  {
                    name: 'Credits',
                    subtitle: `${displayAmount({
                      amount: creditAmount,
                      currency: (detail?.defaultCurrency! as unknown) as MoneyValueCurrencyEnum,
                    })}`,
                    TabComponent: (
                      <ZenRoute title='Credits'>
                        <div className='pt-4 space-y-2'>
                          <p>Credits ({equityPaymentsCredits?.length || 0})</p>
                          <ZenFixedDataTable<TaxDocumentLineItemResponse>
                            columns={dynamicEquityPaymentsCreditColumns}
                            data={equityPaymentsCredits || []}
                            resourceName='Equity Payment Credit'
                            hidePagination
                            hideFilters
                            emptyIconComponent={
                              <img
                                src={EmptyTray}
                                alt='EmptyTrayImage'
                                className='h-20'
                              />
                            }
                          />
                        </div>
                      </ZenRoute>
                    ),
                  },
                ]}
                selected={activeTab}
                onChange={setActiveTab}
                size='sm'
              />
            </ZenResourceContainer>
          </AuthorizationContainer>
        </div>
      </div>
    </ZenRoute>
  );
};

export default ZenTaxEquity;
