import ZenProfileImageCell from '../../Zen/Table/Cell/ZenProfileImageCell';

interface ZenContributorCellProps {
  imageUrl: string;
  name: string;
  tier: number;
}

const ZenContributorCell: React.FC<ZenContributorCellProps> = ({
  imageUrl,
  name,
  tier,
}) => {
  return (
    <div className='flex flex-row items-center cursor-pointer'>
      <ZenProfileImageCell name={name} imageUrl={imageUrl} />
      <div className='flex flex-row items-center space-x-1 px-3 font-zen-body'>
        <p>{name}</p>
        <p className='text-gray-500'> • Tier {tier}</p>
      </div>
    </div>
  );
};

export default ZenContributorCell;
