import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../../config';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import CircleCheckStepProgress from '../CircleCheckStepProgress';
import SignUpLayout from '../SignupLayout';
import {
  JointVentureSignUpFormState,
  JointVentureSignUpStepName,
} from './JointVentureSignUpSteps';
import SignUpPaymentComponent from './SignUpPayment';

const MakeThePaymentStep: StepByStepComponent<
  JointVentureSignUpFormState,
  JointVentureSignUpStepName
> = ({
  form: { control, watch, trigger },
  progress: { currentIndex, lastIndex },
  onNext,
}) => {
  const stripePromise = loadStripe(config.stripe.publishableKey);
  const [inviteId] = watch(['inviteId']);
  return (
    <SignUpLayout
      header={
        <CircleCheckStepProgress
          stepNames={Object.values(JointVentureSignUpStepName)}
          totalStepsCount={lastIndex + 1}
          inProgressIndex={currentIndex}
        />
      }
      hideNavigation
    >
      <div className='w-full flex flex-col flex-grow relative h-full'>
        <div className='w-full flex-grow h-full'>
          <div className='text-dark h-full'>
            <Elements stripe={stripePromise}>
              <SignUpPaymentComponent
                trigger={trigger}
                inviteId={inviteId}
                control={control}
                onNext={onNext}
              />
            </Elements>
          </div>
        </div>
      </div>
    </SignUpLayout>
  );
};

export default MakeThePaymentStep;
