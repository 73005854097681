export const WhyRealTitle = [
  {
    title: 'Profit Sharing',
    content:
      'Every client that you refer to Real Title helps to grow the Joint Venture, as well as your share of the profits.',
  },
  {
    title: 'Price Match Guarantee',
    content:
      'Real Title promises the best service and technology for the lowest cost. If you find a better price, Real Title will match it - guaranteed!',
  },
  {
    title: 'Transparency & Technology',
    content:
      'View your Real Title transaction tasks, documents, and next steps in reZEN, so you know at a glance where each closing stands.',
  },
  {
    title: 'Exceptional Service',
    content:
      'Because we are part of the Real ecosystem, we are focused on delivering exceptional service.',
  },
];

export const RealTitleFeatures = [
  'Progress Tracker',
  'Documents',
  'Agent Information',
  'Important Dates',
  'Wire Instructions',
];

export const RealTitleCornerStones = [
  'Share in the profits of one of our fastest growing businesses.',
  'Make a modest, one-time purchase of shares in the JV.',
  'Enjoy periodic distribution payments directly to your account.',
];

export const JV_BOOK_MEETING_LINK = 'https://calendly.com/taylor-conley-2';
