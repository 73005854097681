import { cn } from '../utils/classUtils';
import Avatar from './Avatar';

export type AvatarPillVariantType =
  | 'default'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger';

export interface AvatarPillProps {
  text: string;
  imageUrl: string;
  variant?: AvatarPillVariantType;
}

const AvatarPill: React.FC<AvatarPillProps> = ({
  text,
  imageUrl,
  variant = 'default',
}) => {
  const avatarPillVariantTypeToClassNameMap: {
    [type in AvatarPillVariantType]: string;
  } = {
    default: 'bg-gray-200 text-dark',
    primary: 'bg-primary bg-opacity-20 text-primary',
    success: 'bg-success bg-opacity-20 text-success',
    warning: 'bg-warning bg-opacity-20 text-warning',
    danger: 'bg-error bg-opacity-20 text-error',
  };

  return (
    <div
      className={cn(
        'inline-block py-1 px-3 rounded-full',
        avatarPillVariantTypeToClassNameMap[variant],
      )}
    >
      <div className='flex flex-row content-center space-x-1 space-y-1 items-center'>
        <Avatar name={text} size='xxs' imageUrl={imageUrl} />
        <p className='font-primary-medium text-sm'>{text}</p>
      </div>
    </div>
  );
};

export default AvatarPill;
