import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import JointVentureCard from '../components/JointVenture/JointVentureCard';
import ResourceContainer from '../components/ResourceContainer';
import ZenButton from '../components/Zen/ZenButton';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import { fetchJointVentures } from '../slices/JointVentureSlice';
import { RootState } from '../types';

const RealTitleIndexRoute: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    auth: { userDetail },
    jointVenture: {
      jointVenturesResponse: { loading, data },
    },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(fetchJointVentures());
  }, [dispatch, userDetail?.id]);

  return (
    <ZenRoute title='Joint Ventures'>
      <ZenPageLayout
        path={[
          { title: 'Title Portal', url: '/' },
          { title: 'Joint Ventures', url: '/real-title/joint-ventures' },
        ]}
      >
        <div className='flex flex-row items-center justify-between md:p-10 p-5'>
          <p className='font-zen-body font-semibold text-xl leading-7 text-black'>
            Joint Ventures
          </p>
          <div className='flex flex-row items-center space-x-2'>
            <ZenButton
              label='Create JV'
              LeftIconComponent={
                <FontAwesomeIcon icon={faPlus} className='mx-1' />
              }
              onClick={() => history.push('/real-title/create-jv')}
            />
          </div>
        </div>
        <ResourceContainer
          isEmpty={!data?.length}
          loading={loading}
          resourceName='invitation form detail'
        >
          <div className='grid md:grid-cols-2 grid-cols-1 md:p-10 p-5 gap-3'>
            {data?.map((detail) => (
              <JointVentureCard key={detail.id} jointVentureDetails={detail} />
            ))}
          </div>
        </ResourceContainer>
      </ZenPageLayout>
    </ZenRoute>
  );
};

export default RealTitleIndexRoute;
