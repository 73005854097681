import React from 'react';
import { useFormContext } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { setWealthKnowledgeLandingPage } from '../../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum } from '../../../../types';
import ZenControlledSquareToggleInput from '../../../Zen/Input/ZenControlledSquareToggleInput';
import { Earning } from '../../WealthManagementTypes';
import { GoalsFormInput } from '../SetGoalsSideBarV2';
import WealthManagementCardV2 from '../WealthManagementCardV2';

interface StockPurchasePlanSppFormInputsProps {
  logAnalyticsEvent: (event: string) => void;
}

const StockPurchasePlanSppFormInputs: React.FC<StockPurchasePlanSppFormInputsProps> = ({
  logAnalyticsEvent,
}) => {
  const dispatch = useDispatch();
  const { control } = useFormContext<GoalsFormInput>();

  return (
    <WealthManagementCardV2
      title='Stock Purchase Plan (SPP)'
      onClickText='Learn More'
      onClick={() => {
        logAnalyticsEvent(
          AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_LEARN_MORE_SPP,
        );
        dispatch(setWealthKnowledgeLandingPage(Earning.PRE_CAP_SPP));
      }}
    >
      <ZenControlledSquareToggleInput<GoalsFormInput, 'sppParticipation'>
        control={control}
        name='sppParticipation'
        label='Will you participate in the Stock Purchase Plan (SPP)?'
        onChangeSpy={(value) => {
          logAnalyticsEvent(
            value
              ? AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_SPP_TO_YES
              : AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_SPP_TO_NO,
          );
        }}
      />
    </WealthManagementCardV2>
  );
};

export default StockPurchasePlanSppFormInputs;
