import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';

interface ExcludeAdminProps {}

const ExcludeAdmin: React.FC<ExcludeAdminProps> = ({ children }) => {
  const { isAdmin, isSuperAdmin } = useSelector(
    (state: RootState) => state.auth,
  );

  if (isAdmin || isSuperAdmin) {
    return null;
  }

  // This will be released in a new version of eslint. Disabling for now.
  // https://github.com/yannickcr/eslint-plugin-react/commit/9f1d618499b23e184a628365cb5e0ea48292cd6f
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Fragment>{children}</Fragment>;
};

export default ExcludeAdmin;
