import { HiOutlineArrowLongDown } from 'react-icons/hi2';
import { TriggerDefinitionDto } from '../../../openapi/sherlock';
import { capitalizeEnum } from '../../../utils/StringUtils';
import TriggerSubscriptionConditions from './TriggerSubscriptionConditions';

interface TriggerSubscriptionProps {
  trigger: TriggerDefinitionDto;
}

const TriggerSubscription: React.FC<TriggerSubscriptionProps> = ({
  trigger,
}) => {
  return (
    <div className='w-full flex flex-col justify-center items-center'>
      {/* Purple Box */}
      <div className='w-full px-6 py-5 flex flex-col justify-center items-center border-2 rounded-lg border-zen-purple'>
        <div className='font-zen-body font-bold text-base uppercase mb-4 text-zen-purple'>
          Subscription-Condition Pairs
        </div>

        {/* Blue Component */}
        <TriggerSubscriptionConditions trigger={trigger} />
      </div>

      {/* Arrow */}
      <HiOutlineArrowLongDown
        fontSize={50}
        className='font-light text-zen-purple-dark -mt-1 -mb-1'
      />

      {/* Green Box */}
      <div className='w-full px-6 py-5 flex flex-col justify-center items-center border-2 rounded-lg border-green-600 font-zen-body'>
        <div className='mb-3 text-base font-bold text-green-600'>ACTION</div>

        <div className='text-sm font-semibold text-zen-dark-9'>
          {capitalizeEnum(trigger?.actionName!) || 'NA'}
        </div>
      </div>
    </div>
  );
};

export default TriggerSubscription;
