import React from 'react';
import ZenPopover from '../../../ZenPopover';

interface AvailableRealCapLevelPopoverProps {}

const AvailableRealCapLevelPopover: React.FC<AvailableRealCapLevelPopoverProps> = () => {
  return (
    <ZenPopover
      title='Available Real Cap Levels'
      description={
        <div className='text-zen-dark-9 text-base font-inter font-light'>
          <p>
            The Real Administrator can decide which cap levels will be available
            for the members of this team. The full set of cap level options come
            from the associated commission plan. However, the Real Administrator
            may choose to make fewer options available for team configuration
            than those contained in the plan.
          </p>
        </div>
      }
    />
  );
};

export default AvailableRealCapLevelPopover;
