import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  IdentitySummaryResponse,
  IdentitySummaryResponseMfaTypeEnum,
  MfaControllerApi,
} from '../../../openapi/keymaker';
import ErrorService from '../../../services/ErrorService';
import { fetchKeymakerCurrentUser } from '../../../slices/AuthSlice';
import { showSuccessToast } from '../../../slices/ToastNotificationSlice';
import { getApiErrorMessage } from '../../../utils/ErrorUtils';
import { getKeymakerConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSimpleConfirmationModal from '../../Zen/Modal/ZenSimpleConfirmationModal';

interface FormData {
  code: number;
}

interface AgentDeleteTwoFactorAuthenticationModalProps {
  isOpen: boolean;
  onClose(): void;
  keymakerCurrentUser: IdentitySummaryResponse;
}

const AgentDeleteTwoFactorAuthenticationModal: React.FC<AgentDeleteTwoFactorAuthenticationModalProps> = ({
  isOpen,
  onClose,
  keymakerCurrentUser,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<FormData>();
  const isSmsTypeMFA =
    keymakerCurrentUser?.mfaType === IdentitySummaryResponseMfaTypeEnum.Sms;

  const sendMfaSms = useCallback(async () => {
    try {
      await new MfaControllerApi(getKeymakerConfiguration()).sendMfaSms(
        keymakerCurrentUser.phoneNumber,
      );
    } catch (e) {
      ErrorService.notify(
        'Unable to send authentication code to phone number during delete authentication',
        e,
        {
          data: { phoneNumber: keymakerCurrentUser.phoneNumber },
        },
      );
    }
  }, [keymakerCurrentUser.phoneNumber]);

  useEffect(() => {
    if (isSmsTypeMFA) {
      sendMfaSms();
    }
  }, [isSmsTypeMFA, sendMfaSms]);

  const onSubmit = async (values: FormData) => {
    try {
      await new MfaControllerApi(getKeymakerConfiguration()).deleteMfa({
        code: values.code,
      });
      await dispatch(fetchKeymakerCurrentUser());
      onClose();
      dispatch(
        showSuccessToast('Successfully deleted two factor authentication.'),
      );
    } catch (e) {
      setError('code', {
        message: getApiErrorMessage(e),
      });
      ErrorService.notifyIgnoreAuthErrors(
        'Unable to delete two factor authentication',
        e,
        {
          data: {
            code: values.code,
          },
        },
      );
    }
  };

  return (
    <ZenSimpleConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      variant='danger'
      title='Delete Two-Factor Authentication'
      size='large'
      onConfirm={handleSubmit(onSubmit)}
      confirmButtonText='Delete'
      cancelButtonText='Cancel'
      isSubmitting={isSubmitting}
    >
      <p className='font-primary-regular text-gray-500 my-3'>
        By removing this protection, your account will be less secure. If you
        are sure, enter the code from your mobile device or authenticator app.
      </p>
      <ZenControlledTextInput<FormData, 'code'>
        control={control}
        name='code'
        type='number'
        placeholder='123456'
        rules={{
          required: 'Please enter authentication code',
          minLength: {
            value: 6,
            message: 'Must have 6 digit number',
          },
          maxLength: {
            value: 6,
            message: 'Must have 6 digit number',
          },
        }}
      />
    </ZenSimpleConfirmationModal>
  );
};

export default AgentDeleteTwoFactorAuthenticationModal;
