import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToSquare } from '@fortawesome/pro-regular-svg-icons';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { DateTime } from 'luxon';
import IconButton from '../../components/IconButton';
import RealHeader from '../../components/RealHeader';
import SignatureWidget from '../../components/SignatureWidget/SignatureWidget';
import ZenRoute from '../../components/Zen/ZenRoute';
import {
  AgreementResponseAgreementTypeEnum,
  UserAgreementControllerApi,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { fetchAuthUserDetail } from '../../slices/AuthSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import { RootState, SignatureWidgetModeEnum } from '../../types';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import {
  saveSignAgentAgreement,
  fetchAgreementsByUserAndAgreementTypeAndVersionDesc,
  saveAgentNonSignedAgreementsResponse,
} from '../../slices/AgentSlice';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import { useLogout } from '../../hooks/useLogout';

const RezenICASigningV2Route: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const logout = useLogout();
  const {
    auth: { userDetail },
    agentDetail: { agentNonSignedAgreementsResponse, agentAgreementsResponse },
  } = useSelector((state: RootState) => state);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isAlreadySigned = !agentNonSignedAgreementsResponse?.signeeToken;
  const token = !isAlreadySigned
    ? agentNonSignedAgreementsResponse?.signeeToken
    : agentAgreementsResponse?.[0]?.signeeToken;
  const agreementId = !isAlreadySigned
    ? agentNonSignedAgreementsResponse?.agreementId
    : agentAgreementsResponse?.[0]?.agreementId;
  const requiredAt = !isAlreadySigned
    ? agentNonSignedAgreementsResponse?.requiredAt
    : agentAgreementsResponse?.[0]?.requiredAt;

  const handleSignICA = useCallback(async () => {
    setIsLoading(true);
    try {
      await new UserAgreementControllerApi(
        getYentaConfiguration(),
      ).signAgreement({
        userId: userDetail?.id!,
        agreementId: agreementId!,
      });
      await dispatch(
        fetchAgreementsByUserAndAgreementTypeAndVersionDesc(
          userDetail?.id!,
          AgreementResponseAgreementTypeEnum.Ica,
          true,
        ),
      );
      dispatch(saveSignAgentAgreement(false));
      dispatch(saveAgentNonSignedAgreementsResponse(undefined));
      dispatch(fetchAuthUserDetail(false));
      dispatch(showSuccessToast('You have successfully signed the agreement.'));
      history.push('/welcome-to-rezen/mobile-app');
    } catch (e) {
      dispatch(showApiErrorModal(e));
      dispatch(
        showErrorToast('Unable to sign the agreement. Please try again later.'),
      );
      ErrorService.notify('Error signing the agreement', e, {
        user: { id: userDetail?.id! },
      });
    } finally {
      setIsLoading(false);
    }
  }, [agreementId, dispatch, history, userDetail?.id]);

  const onDocumentSigned = useCallback(async () => {
    handleSignICA();
  }, [handleSignICA]);

  const onDocumentAlreadySigned = useCallback(() => {
    handleSignICA();
  }, [handleSignICA]);

  return (
    <ZenRoute title='Sign ICA'>
      <div className='relative h-full-window scrollbar overflow-y-auto w-full flex flex-col'>
        <RealHeader />
        <div className='text-center my-8 mx-2 lg:mx-0 font-zen-body text-zen-dark-9'>
          <p className='text-[22px] leading-[22px] font-medium mb-4'>
            INDEPENDENT CONTRACTOR AGREEMENT
          </p>
          <p className='text-base'>
            We kindly ask you to review and put your digital signature on the
            updated version.
          </p>
          <p className='text-base'>
            Starting{' '}
            {DateTime.fromMillis(requiredAt || 0).toFormat('LLL dd yyyy')},
            signing the updated ICA is mandatory to continue accessing your
            reZEN account.
          </p>
        </div>
        <div className='bg-gray-100 w-full h-[calc(100vh-210px)] justify-center relative'>
          <SignatureWidget
            token={token!}
            mode={SignatureWidgetModeEnum.SIGN}
            onDocumentSigned={onDocumentSigned}
            onDocumentAlreadySigned={onDocumentAlreadySigned}
            showDownload
            showWebHelperButtons={isMobile && !isAlreadySigned}
            isLoading={isLoading}
            browserMode={isAlreadySigned}
          />
        </div>
        {!isLoading && (
          <div
            className={classNames(
              isMobile
                ? 'flex justify-start items-center p-4'
                : 'absolute bottom-0 left-9',
            )}
          >
            <div className={classNames(isMobile ? 'my-0' : 'my-5')}>
              <IconButton
                label='Logout'
                variant='outline'
                leftIcon={
                  <FontAwesomeIcon
                    icon={faArrowDownToSquare}
                    className='-rotate-90 mx-0.5'
                  />
                }
                buttonType='button'
                onClick={async () => await logout()}
              />
            </div>
          </div>
        )}
      </div>
    </ZenRoute>
  );
};

export default RezenICASigningV2Route;
