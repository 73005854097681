import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import {
  faCircleCheck,
  faCircleInfo,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InvoiceResponseStatusEnum } from '../../../../openapi/arrakis';
import { EnumMap } from '../../../../types';
import { safeEnumMapGet } from '../../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import ZenStatusPill, { PillVariantType } from '../../ZenStatusPill';

interface ZenInvoicesStatusCellProps {
  type: InvoiceResponseStatusEnum;
}

const typeToVariant: EnumMap<InvoiceResponseStatusEnum, PillVariantType> = {
  [InvoiceResponseStatusEnum.Cancel]: 'danger',
  [InvoiceResponseStatusEnum.CancelConfirmed]: 'danger',
  [InvoiceResponseStatusEnum.InvoiceCreated]: 'primary',
  [InvoiceResponseStatusEnum.NetsuiteError]: 'danger',
  [InvoiceResponseStatusEnum.Open]: 'warning',
  [InvoiceResponseStatusEnum.Paid]: 'success',
  [InvoiceResponseStatusEnum.TransactionMissing]: 'danger',
  [InvoiceResponseStatusEnum.PaidConfirmed]: 'success',
  [InvoiceResponseStatusEnum.GeneralError]: 'danger',
  [InvoiceResponseStatusEnum.MicrosoftDynamicsError]: 'danger',
};

const getIconForStatus = (status: InvoiceResponseStatusEnum): IconProp => {
  switch (status) {
    case InvoiceResponseStatusEnum.Cancel:
    case InvoiceResponseStatusEnum.CancelConfirmed:
    case InvoiceResponseStatusEnum.NetsuiteError:
    case InvoiceResponseStatusEnum.TransactionMissing:
    case InvoiceResponseStatusEnum.GeneralError:
    case InvoiceResponseStatusEnum.MicrosoftDynamicsError:
      return faXmark;
    case InvoiceResponseStatusEnum.InvoiceCreated:
      return faCircleInfo;
    case InvoiceResponseStatusEnum.Open:
      return faTriangleExclamation;
    case InvoiceResponseStatusEnum.Paid:
    case InvoiceResponseStatusEnum.PaidConfirmed:
      return faCircleCheck;
    default:
      return faCircleInfo;
  }
};

const ZenInvoicesStatusCell: React.FC<ZenInvoicesStatusCellProps> = ({
  type,
}) => {
  const variant: PillVariantType = safeEnumMapGet(
    typeToVariant,
    type,
    'primary',
  );

  if (!type) return null;

  return (
    <ZenStatusPill
      text={capitalizeEnum(type)}
      variant={variant}
      icon={<FontAwesomeIcon icon={getIconForStatus(type)} />}
    />
  );
};

export default ZenInvoicesStatusCell;
