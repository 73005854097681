import React from 'react';
import { Link } from 'react-router-dom';
import TransactionOrListingOverviewDisplayPill, {
  TransactionOrListingPillVariantType,
} from './TransactionOrListingOverviewDisplayPill';

export interface TransactionOrListingOverviewInfo {
  title: TransactionOrListingPillVariantType;
  value: number;
}

interface Props {
  title: string;
  items: TransactionOrListingOverviewInfo[];
  navigateTo?: string;
}

const TransactionOrListingOverviewDisplayCard: React.FC<Props> = ({
  title,
  items,
  navigateTo,
}) => {
  const getPath = (variant: TransactionOrListingPillVariantType) => {
    switch (variant) {
      case 'ACTIVE':
        return `${navigateTo}`;
      case 'CLOSED':
        return `${navigateTo}/closed`;
      case 'TERMINATED':
        return `${navigateTo}/terminated`;
      default:
        return navigateTo;
    }
  };
  return (
    <div className='w-full'>
      <div className='flex flex-row items-center font-zen-body-2 text-sm mb-1'>
        <span className='font-semibold leading-[18px] text-white'>{title}</span>
        <Link to={navigateTo}>
          <span className='font-medium left-4 text-aqua ml-1.5 cursor-pointer leading-4'>
            See all
          </span>
        </Link>
      </div>
      <div className='flex flex-row items-center space-x-3'>
        {items.map((item) => {
          return (
            <div key={item.title} className='flex-1'>
              <Link to={getPath(item.title)} className='block h-full w-full'>
                <TransactionOrListingOverviewDisplayPill
                  label={item.value.toString()}
                  variant={item.title}
                />
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TransactionOrListingOverviewDisplayCard;
