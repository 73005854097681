import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from '@fortawesome/pro-light-svg-icons';
import { faContactBook } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface GeminiAgentContactInfoDetailHeaderProps {
  phoneNumber?: string;
  emailAddress?: string;
  addresses?: string;
}

const DisplayRow: React.FC<{ icon: IconProp; label?: string }> = ({
  icon,
  label,
}) => (
  <div className='flex gap-2 items-center'>
    <div className='h-6 w-6 flex items-center justify-center shrink-0'>
      <FontAwesomeIcon icon={icon} className='text-white font-light text-sm' />
    </div>
    <span>{label ?? 'N/A'}</span>
  </div>
);

const GeminiAgentContactInfoDetailHeader: React.FC<GeminiAgentContactInfoDetailHeaderProps> = ({
  phoneNumber,
  emailAddress,
  addresses,
}) => {
  return (
    <div className='relative p-4 rounded-lg bg-regent-900 font-zen-body-2 text-sm font-light leading-4 text-white space-y-2.5'>
      <div className='text-zen-dark-12 absolute right-4 top-4'>
        <FontAwesomeIcon
          icon={faContactBook}
          className='font-black align-middle leading-[50px] text-[34px]'
        />
      </div>
      <DisplayRow icon={faPhone} label={phoneNumber} />
      <DisplayRow icon={faEnvelope} label={emailAddress} />
      <DisplayRow icon={faLocationDot} label={addresses} />
    </div>
  );
};
export default GeminiAgentContactInfoDetailHeader;
