import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { EnumMap } from '../../../types';
import ZenButton, { ZenButtonVariant } from '../../Zen/ZenButton';
import { cn } from '../../../utils/classUtils';

export type GeminiModalVariantType =
  | 'info'
  | 'warning'
  | 'success'
  | 'error'
  | 'danger'
  | 'upload'
  | 'cancel'
  | 'close';

export type GeminiModalSizeVariantType =
  | 'default'
  | 'small'
  | 'medium'
  | 'large'
  | 'fixed';

interface GeminiSimpleConfirmationModalProps {
  variant: GeminiModalVariantType;
  title?: string;
  isOpen: boolean;
  subtitle?: string;
  hideIcon?: boolean;
  size?: GeminiModalSizeVariantType;
  onClose(): void;
  onConfirm?(): void;
  isSubmitting: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  hideBottomButtons?: boolean;
  hideCancelButton?: boolean;
}

const GeminiSimpleConfirmationModal: React.FC<GeminiSimpleConfirmationModalProps> = ({
  variant,
  title,
  isOpen,
  subtitle,
  onClose,
  size = 'default',
  onConfirm,
  isSubmitting,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  hideBottomButtons = false,
  hideCancelButton = false,
  children,
}) => {
  const sizeVariant: EnumMap<GeminiModalSizeVariantType, string> = {
    default: 'md:w-1/2 lg:w-1/4',
    large: 'md:w-3/5 lg:w-1/3',
    small: 'md:w-2/5 lg:w-1/5',
    medium: 'md:w-5/12 lg:w-4/12',
    fixed: 'md:w-3/5 lg:w-[536px]',
  };

  const modalVariantToButtonVariantMap: EnumMap<
    GeminiModalVariantType,
    ZenButtonVariant
  > = {
    info: 'primary',
    success: 'success',
    warning: 'primary',
    upload: 'primary',
    cancel: 'danger',
    close: 'danger',
    danger: 'danger',
    error: 'danger',
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window !ml-0'
      aria-label='simple-confirmation-modal'
    >
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
        <div
          className={cn(
            'w-full bg-white rounded-xl shadow-zen-web p-4 z-10',
            sizeVariant[size],
          )}
        >
          <div className='flex items-start'>
            <div className='w-full'>
              {title && (
                <div className='flex justify-between items-center border-b border-gray-200 2xl:ml-10'>
                  <p className='font-inter text-center font-medium text-primary-dark text-lg px-4 py-2 pl-14'>
                    {title}
                  </p>
                  <div className=' bg-regent-400 rounded-full p-1 px-2 flex justify-center items-center cursor-pointer mb-1'>
                    <FontAwesomeIcon
                      icon={faXmark}
                      className='text-primary-light h-4.5 w-2.5'
                      title='Close'
                      onClick={onClose}
                    />
                  </div>
                </div>
              )}
              {subtitle && (
                <p className='text-base text-gray-500 font-zen-body-2 p-6'>
                  {subtitle}
                </p>
              )}
              {children}
              {!hideBottomButtons && (
                <div className='flex justify-center w-full'>
                  <div className='flex mt-4 space-x-3 w-4/5'>
                    {!hideCancelButton && (
                      <ZenButton
                        isFullWidth
                        label={cancelButtonText}
                        variant='dark-outline'
                        onClick={onClose}
                      />
                    )}
                    <ZenButton
                      isFullWidth
                      label={confirmButtonText}
                      variant={modalVariantToButtonVariantMap[variant]}
                      onClick={onConfirm}
                      isDisabled={isSubmitting}
                      isSubmitting={isSubmitting}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeminiSimpleConfirmationModal;
