import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import ZenAgentAttractionAssets from '../assets/img/zen/marketing/zen-agent-attraction-assets.jpg';
import ZenAgentAttractionWebsite from '../assets/img/zen/marketing/zen-agent-attraction-website.jpg';
import ZenEarningCalculator from '../assets/img/zen/marketing/zen-earning-calculator.jpg';
import ZenBusinessCards from '../assets/img/zen/marketing/zen-business-cards.jpg';
import ZenLogosAndBranding from '../assets/img/zen/marketing/zen-logos-and-branding.jpg';
import ZenNineWaysToEarnIncomeAtReal from '../assets/img/zen/marketing/zen-nine-ways-to-earn-income-at-real.jpg';
import ZenRealDesignCenter from '../assets/img/zen/marketing/zen-real-design-center.jpg';
import ZenRealEstateSignage from '../assets/img/zen/marketing/zen-real-estate-signage.jpg';
import ZenRealSwagShop from '../assets/img/zen/marketing/zen-real-swag-shop.jpg';
import ZenSocialMediaShareables from '../assets/img/zen/marketing/zen-social-media-shareables.jpg';
import { ZenMarketingCenterCardItem } from '../components/Zen/Marketing/ZenMarketingCenterCard';
import { SocialMediaLink } from '../components/Zen/SocialMediaLinks';
import {
  AGENT_ATTRACTION_ASSETS,
  AGENT_ATTRACTION_WEBSITE,
  EARNING_CALCULATOR,
  BUSINESS_CARDS,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  LOGOS_AND_BRANDING,
  NINE_WAYS_TO_EARN_INCOME_AT_REAL,
  REAL_DESIGN_CENTER,
  REAL_ESTATE_SIGNAGE,
  REAL_SWAG_SHOP,
  SOCIAL_MEDIA_SHAREABLES,
  TIK_TOK_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from '../constants/MarketingCenterLinksConstants';

export const getZenMarketingCenterItems = (
  jwt: string,
): ZenMarketingCenterCardItem[] => {
  return [
    {
      title: '9 Ways to Earn Income at Real',
      subtitle:
        'This informational deck walks you through all the paths to earning income with Real from keeping more of your commission to sponsoring new agents.',
      link: NINE_WAYS_TO_EARN_INCOME_AT_REAL,
      image: ZenNineWaysToEarnIncomeAtReal,
    },
    {
      title: 'Logos & Branding',
      subtitle:
        'Access key marketing resources to get you up and running, including fonts, logos, color codes, brand guidelines, and more.',
      link: LOGOS_AND_BRANDING,
      image: ZenLogosAndBranding,
    },
    {
      title: 'Agent Attraction Website',
      subtitle:
        'This prospective agent deck is the perfect way to educate interested agents on Real’s strengths, financial model, and culture.',
      link: AGENT_ATTRACTION_WEBSITE,
      image: ZenAgentAttractionWebsite,
    },
    {
      title: 'Earnings Calculator',
      subtitle:
        'Understanding your potential earnings at Real. See how much you could potentially make if you join today!',
      link: EARNING_CALCULATOR,
      image: ZenEarningCalculator,
    },
    {
      title: 'Business Cards',
      subtitle:
        'Order Real-branded business cards based on pre-approved templates.',
      link: BUSINESS_CARDS,
      image: ZenBusinessCards,
    },
    {
      title: 'Real Estate Signage',
      subtitle:
        'Order Real-branded signage for your listings, and don’t forget to have your state broker approve the design to ensure compliance before ordering!',
      link: REAL_ESTATE_SIGNAGE,
      image: ZenRealEstateSignage,
    },
    {
      title: 'Social Media Shareables',
      subtitle:
        'These Real-branded creative assets are the perfect size for sharing on your social media channels.',
      link: SOCIAL_MEDIA_SHAREABLES,
      image: ZenSocialMediaShareables,
    },
    {
      title: 'Agent Attraction Assets',
      subtitle:
        'Want to tell Real’s story to prospective agents? These assets will help you market the fastest-growing publicly traded real estate brokerage!',
      link: AGENT_ATTRACTION_ASSETS,
      image: ZenAgentAttractionAssets,
    },
    {
      title: 'Real Design Center',
      subtitle:
        'This platform enables you to design, customize, and download production assets with MLS integration.',
      link: `${REAL_DESIGN_CENTER}?jwt=${jwt}`,
      image: ZenRealDesignCenter,
    },
    {
      title: 'Real Swag Shop',
      subtitle:
        'New Look. New Swag. Shop the Real swag shop and purchase T-Shirts, Outerwear, Drinkware & More.',
      link: REAL_SWAG_SHOP,
      image: ZenRealSwagShop,
    },
  ];
};

export const getSocialMediaLinks = (): SocialMediaLink[] => {
  return [
    { icon: brands('facebook'), url: FACEBOOK_URL },
    { icon: brands('twitter'), url: TWITTER_URL },
    { icon: brands('instagram'), url: INSTAGRAM_URL },
    { icon: brands('linkedin'), url: LINKEDIN_URL },
    { icon: brands('youtube'), url: YOUTUBE_URL },
    { icon: brands('tiktok'), url: TIK_TOK_URL },
  ];
};
