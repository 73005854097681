import { ISelectOption } from '../types';
import Logger from '../utils/Logger';
import CheckBox from './CheckBox';

interface ControlledCheckboxGroupItemProps {
  value: string[];
  items: ISelectOption[];
  onToggle(newValue: string[]): void;
}

const ControlledCheckboxGroupItem = ({
  items,
  onToggle,
  value = [],
}: ControlledCheckboxGroupItemProps) => {
  const handleToggle = (checked: boolean, valueToUpdate: string) => {
    let newValue: string[];

    if (checked) {
      newValue = [...value, valueToUpdate];
    } else {
      newValue = value.filter((v) => v !== valueToUpdate);
    }

    Logger.debug('[ControlledCheckboxGroupItem] updating value to:', newValue);
    onToggle(newValue);
  };

  return (
    <div>
      {items.map((item) => {
        const checked = !!value?.find((val: string) => val === item.value);

        return (
          <div
            key={item.value}
            className='flex-row items-center my-2'
            onClick={() => handleToggle(!checked, item.value)}
          >
            <CheckBox
              value={checked}
              onChange={() => handleToggle(!checked, item.value)}
            />
            <span className='font-primary-regular flex-wrap text-gray-800 text-base ml-2'>
              {item.label}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default ControlledCheckboxGroupItem;
