import { AnnouncementDefLiteResponseStatusEnum } from '../../../openapi/mercury';
import { EnumMap } from '../../../types';
import { announcementStatusToDisplayName } from '../../../utils/AnnouncementUtils';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import ZenStatusPill, { PillVariantType } from '../ZenStatusPill';

interface AnnouncementStatusPillProps {
  status: AnnouncementDefLiteResponseStatusEnum;
}

const AnnouncementStatusPill: React.FC<AnnouncementStatusPillProps> = ({
  status,
}) => {
  const statusToVariantMap: EnumMap<
    AnnouncementDefLiteResponseStatusEnum,
    PillVariantType
  > = {
    DRAFT: 'primary',
    ACTIVE: 'success',
    SUSPENDED: 'danger',
    ARCHIVED: 'default',
  };

  const variant = safeEnumMapGet(statusToVariantMap, status, 'primary');

  return (
    <ZenStatusPill
      text={announcementStatusToDisplayName(status)}
      variant={variant}
    />
  );
};

export default AnnouncementStatusPill;
