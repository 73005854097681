import { last } from 'lodash';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import DefaultLoader from '../components/DefaultLoader';
import useQueryParams from '../hooks/useQueryParams';
import {
  AgentResponseAccountCountryEnum,
  AgentResponseAgentStatusEnum,
  TeamInvitationDto,
} from '../openapi/yenta';
import {
  useInvitation,
  useRedeemInvitation,
} from '../query/team/useInvitation';
import { showSuccessToast } from '../slices/ToastNotificationSlice';
import { RootState } from '../types';
import { isApplicationCreated, isInvitationExpired } from '../utils/TeamHelper';
import InvitationExpired from './InvitationExpired';

export enum InvitationEnum {
  EMAIL = 'email',
  LINK = 'link',
}

interface Query {
  firstName: string;
  lastName: string;
  email: string;
  team: string;
  country: AgentResponseAccountCountryEnum;
}

interface JoinByInvitationRouteProps {}

interface JoinByInvitationRouteParams {
  id: string;
  type: InvitationEnum;
}

const JoinByInvitationRoute: React.FC<JoinByInvitationRouteProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, type } = useParams<JoinByInvitationRouteParams>();
  const params = useQueryParams<Query>();
  const { userDetail, isAdmin, isBroker } = useSelector(
    (state: RootState) => state.auth,
  );

  const { data: invitation } = useInvitation({
    fnArgs: [id, type],
  });

  const {
    mutate: redeemInvitation,
    isError: isRedeemError,
  } = useRedeemInvitation(id, type);

  const isExpired = isInvitationExpired(invitation);
  const isRedeemed = isApplicationCreated(invitation);

  const isError = (isExpired && !isRedeemed) || isRedeemError;

  const applicationId = useMemo(() => {
    const application = last(userDetail?.applications);
    return application?.id;
  }, [userDetail?.applications]);

  const handleRedirectAndRedeem = useCallback(() => {
    const isInvitationPresentAndNotExpired = invitation && !isExpired;
    const isInvitationPresentAndApplicationCreated = invitation && isRedeemed;

    if (isInvitationPresentAndApplicationCreated) {
      history.push(
        `/login?${qs.stringify({
          email: (invitation as TeamInvitationDto)?.emailAddress,
        })}`,
      );
    } else if (isInvitationPresentAndNotExpired) {
      const isActiveOrCandidate = [
        AgentResponseAgentStatusEnum.Active,
        AgentResponseAgentStatusEnum.Candidate,
      ].includes(userDetail?.agentStatus!);
      const isAgent = !isAdmin && !isBroker;

      const canRedeemInvitation = isAgent && isActiveOrCandidate;
      // if user is active, then redeem the invitation
      if (canRedeemInvitation) {
        redeemInvitation(
          {
            applicationId: applicationId!,
            invitation,
          },
          {
            onSuccess: () => {
              const isCandidate =
                userDetail?.agentStatus ===
                AgentResponseAgentStatusEnum.Candidate;

              if (!isCandidate) {
                dispatch(showSuccessToast('Invitation redeemed successfully!'));
              }

              window.location.href = '/';
            },
          },
        );
      } else {
        // Redirect to register if not loggedin
        history.push(
          `/register?${qs.stringify({
            invitationType: type,
            invitationId: id,
            ...params,
          })}`,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applicationId,
    history,
    id,
    invitation,
    isAdmin,
    isBroker,
    isExpired,
    redeemInvitation,
    type,
    userDetail?.agentStatus,
  ]);

  useEffect(() => {
    handleRedirectAndRedeem();
  }, [handleRedirectAndRedeem]);

  if (isError) {
    return <InvitationExpired />;
  }

  return <DefaultLoader />;
};

export default JoinByInvitationRoute;
