import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useGemini } from '../../hooks/useGemini';
import { cn } from '../../utils/classUtils';

interface ZenViewProps {
  text?: string;
  onClick?(): void;
}

const ZenView: React.FC<ZenViewProps> = ({ text = 'View', onClick }) => {
  const isGeminiEnabled = useGemini();

  return (
    <button
      onClick={onClick}
      className={cn(
        'flex flex-row items-center justify-center space-x-1',
        isGeminiEnabled
          ? 'font-inter font-medium text-base leading-5 text-rezen-blue-600'
          : 'font-zen-body font-semibold text-sm text-primary-blue',
      )}
    >
      <FontAwesomeIcon
        icon={faEye}
        className='font-light text-[13px] leading-[23px]'
      />
      <span>{text}</span>
    </button>
  );
};

export default ZenView;
