import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { setWealthKnowledgeLandingPage } from '../../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum } from '../../../../types';
import { displayFormattedFixedAmountWithCurrency } from '../../../../utils/CurrencyUtils';
import {
  MONEY_VALIDATIONS,
  PERCENT_VALIDATION,
} from '../../../../utils/Validations';
import { getTeamCount } from '../../../../utils/WealthPlanUtils';
import ZenControlledNumberInput from '../../../Zen/Input/ZenControlledNumberInput';
import ZenControlledPercentageInput from '../../../Zen/Input/ZenControlledPercentageInput';
import ZenControlledSquareToggleInput from '../../../Zen/Input/ZenControlledSquareToggleInput';
import ZenControlledToggleInput from '../../../Zen/Input/ZenControlledToggleInput';
import ZenControlledInputSlider from '../../../Zen/ZenControlledInputSlider';
import { Earning } from '../../WealthManagementTypes';
import { WealthPlanTooltip } from '../../WealthManagementUtils';
import { GoalsFormInput } from '../SetGoalsSideBarV2';
import WealthManagementCardV2 from '../WealthManagementCardV2';

const ContainerWrapper: React.FC<{
  noContainer: boolean;
  logAnalyticsEvent: (event: string) => void;
}> = ({ noContainer, logAnalyticsEvent, children }) => {
  const dispatch = useDispatch();

  if (noContainer) {
    return <>{children}</>;
  }

  return (
    <WealthManagementCardV2
      title='Commission Income'
      onClickText='Learn More'
      onClick={() => {
        logAnalyticsEvent(
          AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_LEARN_MORE_COMMISSION,
        );
        dispatch(setWealthKnowledgeLandingPage(Earning.COMMISSION_INCOME));
      }}
      className='md:!p-4 !p-3'
    >
      {children}
    </WealthManagementCardV2>
  );
};

interface CommissionIncomeFormInputsProps {
  logAnalyticsEvent: (event: string) => void;
  noContainer?: boolean;
}

const CommissionIncomeFormInputs: React.FC<CommissionIncomeFormInputsProps> = ({
  logAnalyticsEvent,
  noContainer = false,
}) => {
  const {
    control,
    watch,
    setValue,
    clearErrors,
  } = useFormContext<GoalsFormInput>();

  const [
    averageHomeSalePrice,
    transactionsPerYear,
    useAverageFixedCommission,
    teamLead,
    teamUseAverageFixedCommission,
    teamNumberCount,
  ] = watch([
    'averageHomeSalePrice',
    'transactionsPerYear',
    'useAverageFixedCommission',
    'teamLead',
    'teamUseAverageFixedCommission',
    'teamNumberCount',
  ]);

  const volumePerYear = useMemo(() => {
    const value = averageHomeSalePrice * transactionsPerYear;
    return displayFormattedFixedAmountWithCurrency(
      {
        amount: value,
        currency: 'USD',
      },
      false,
      0,
    );
  }, [averageHomeSalePrice, transactionsPerYear]);

  return (
    <ContainerWrapper
      noContainer={noContainer}
      logAnalyticsEvent={logAnalyticsEvent}
    >
      <WealthManagementCardV2
        title={
          <div className='text-xl font-primary-light flex items-center w-full font-normal space-x-1'>
            <h2 className='text-zen-dark-9'>
              <span className='font-inter text-lg leading-6 text-zen-dark-9'>
                Individual
              </span>
            </h2>
          </div>
        }
        className='md:!p-4 !p-3 mb-4'
      >
        <ZenControlledInputSlider<GoalsFormInput, 'averageHomeSalePrice'>
          control={control}
          name='averageHomeSalePrice'
          label='What is your average home sales price?'
          startAdornment={<div className='h-full text-primary-blue'>$</div>}
          min={10000}
          max={20000000}
          step={1000}
          placeholder='Enter an amount'
          rules={{
            required: 'Please set an average home sales price.',
            min: {
              value: 10000,
              message: 'Must be greater than or equal to $10000',
            },
            max: {
              value: 20000000,
              message: 'Must be less than or equal to $20,000,000',
            },
          }}
          onChangeSpy={() =>
            logAnalyticsEvent(
              AnalyticsEventEnum.WEALTH_PLAN_COMMISSION_INCOME_AVERAGE_HOME_SALES_PRICE,
            )
          }
        />
        <div className='border-b border-gray-200 my-4' />
        <ZenControlledInputSlider<GoalsFormInput, 'transactionsPerYear'>
          control={control}
          min={1}
          max={200}
          step={1}
          name='transactionsPerYear'
          label='How many transactions per year?'
          rules={{
            required: 'Please enter a number',
            min: {
              value: 1,
              message: 'Must be greater than or equal to 1',
            },
            max: {
              value: 200,
              message: 'Must be less than or equal to 200',
            },
          }}
          onChangeSpy={(_, source) => {
            if (source === 'input') {
              logAnalyticsEvent(
                AnalyticsEventEnum.WEALTH_PLAN_COMMISSION_INCOME_TRANSACTION_INPUT,
              );
            } else {
              logAnalyticsEvent(
                AnalyticsEventEnum.WEALTH_PLAN_COMMISSION_INCOME_TRANSACTION_SLIDER,
              );
            }
          }}
        />
        <div className='border-b border-gray-200 my-4' />
        <div className='flex items-center justify-between'>
          <span
            className={classNames(
              'text-base font-zen-body font-semibold text-zen-dark-9',
            )}
          >
            Volume per year
          </span>
          <div className='text-base leading-5 font-semibold flex items-stretch overflow-hidden text-primary-dark '>
            {volumePerYear}
          </div>
        </div>
        <div className='border-b border-gray-200 my-4' />
        <div className='flex md:flex-row flex-col items-start justify-between space-x-2 mb-6'>
          <span className='font-zen-body text-base font-semibold text-zen-dark-9'>
            What is the average commission charged on one end of a deal?
          </span>
          <div className='flex justify-end items-start'>
            <div className='flex-shrink-0 mt-1'>
              <ZenControlledToggleInput<
                GoalsFormInput,
                'useAverageFixedCommission'
              >
                name='useAverageFixedCommission'
                control={control}
                shouldUnregister={false}
                rightIcon='$'
                leftIcon='%'
                onChangeSpy={(value) => {
                  if (value) {
                    logAnalyticsEvent(
                      AnalyticsEventEnum.WEALTH_PLAN_COMMISSION_INCOME_USE_AVERAGE_FIXED_COMMISSION_TOGGLED_ON,
                    );
                  } else {
                    logAnalyticsEvent(
                      AnalyticsEventEnum.WEALTH_PLAN_COMMISSION_INCOME_USE_AVERAGE_FIXED_COMMISSION_TOGGLED_OFF,
                    );
                  }
                }}
              />
            </div>
            <div className='flex-grow md:max-w-[220px]'>
              {useAverageFixedCommission ? (
                <ZenControlledInputSlider<
                  GoalsFormInput,
                  'averageFixedCommission'
                >
                  control={control}
                  name='averageFixedCommission'
                  startAdornment={
                    <div className='h-full text-primary-blue'>$</div>
                  }
                  placeholder='Amount'
                  shouldUnregister={false}
                  rules={{
                    required: 'Please enter an amount',
                    ...MONEY_VALIDATIONS,
                    validate: (value) => {
                      if (!!value) {
                        if (value > 100000) {
                          return 'Amount must be less than or equal to 100,000';
                        }
                      }
                      return;
                    },
                  }}
                  showSlider={false}
                />
              ) : (
                <ZenControlledPercentageInput<
                  GoalsFormInput,
                  'averageTransactionCommissionPercentage'
                >
                  control={control}
                  name='averageTransactionCommissionPercentage'
                  placeholder='Percentage'
                  shouldUnregister={false}
                  startAdornment={
                    <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
                      %
                    </div>
                  }
                  rules={{
                    required: 'Please enter a percentage',
                    ...PERCENT_VALIDATION,
                    validate: (value) => {
                      if (!!value) {
                        if (value < 0.1) {
                          return 'Percentage must be greater than or equal to 0.1';
                        } else if (value > 10) {
                          return 'Percentage must be less than or equal to 10';
                        }
                      }
                      return;
                    },
                  }}
                  displayVariant='inline'
                />
              )}
            </div>
          </div>
        </div>
      </WealthManagementCardV2>
      <WealthManagementCardV2
        title={
          <div className='text-xl font-primary-light flex items-center w-full font-normal space-x-1'>
            <h2 className='text-zen-dark-9'>
              <span className='font-inter text-lg leading-6 text-zen-dark-9'>
                Team Leaders
              </span>
              <span className='ml-1 font-zen-body text-zen-dark-12 italic'>
                (optional)
              </span>
            </h2>
            <WealthPlanTooltip
              title='If you plan to be a team leader, fill out these inputs to project your earnings as a team leader in 5 years.'
              arrow
            >
              <div className='h-6 w-6 flex items-center justify-center'>
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className='text-base text-zen-dark-9'
                />
              </div>
            </WealthPlanTooltip>
          </div>
        }
        className='md:!p-4 !p-3'
      >
        <div>
          <ZenControlledSquareToggleInput<GoalsFormInput, 'teamLead'>
            label='Will you be a team leader?'
            name='teamLead'
            control={control}
            shouldUnregister={false}
            onChangeSpy={(value) => {
              if (value) {
                setValue('teamTransactionsPerMember', 6);
                setValue('teamAverageSalesPrice', 450000);
                setValue('teamLeaderCommissionPercentagePerTransaction', 20);
                setValue('teamUseAverageFixedCommission', false);
                setValue('teamAverageFixedCommission', 0);
                setValue('teamAverageTransactionCommissionPercentage', 2.5);
                logAnalyticsEvent(
                  AnalyticsEventEnum.WEALTH_PLAN_COMMISSION_INCOME_TEAM_LEAD_TOGGLED_ON,
                );
              } else {
                clearErrors();
                setValue('teamNumberCount', getTeamCount(teamNumberCount));
                setValue('teamTransactionsPerMember', 1);
                setValue('teamAverageSalesPrice', 10000);
                setValue('teamLeaderCommissionPercentagePerTransaction', 1);
                setValue('teamUseAverageFixedCommission', false);
                setValue('teamAverageFixedCommission', 0);
                setValue('teamAverageTransactionCommissionPercentage', 0.1);
                logAnalyticsEvent(
                  AnalyticsEventEnum.WEALTH_PLAN_COMMISSION_INCOME_TEAM_LEAD_TOGGLED_OFF,
                );
              }
            }}
          />
          <div className={classNames('w-full', teamLead ? 'block ' : 'hidden')}>
            <div className='border-b border-gray-200 my-6' />
            <ZenControlledNumberInput<GoalsFormInput, 'teamNumberCount'>
              control={control}
              name='teamNumberCount'
              label='How many team members will you have?'
              placeholder='Enter an number'
              allowDecimals={false}
              rules={{
                required: 'Please set a value',
                min: {
                  value: 1,
                  message: 'Must be greater than or equal to 1',
                },
                max: {
                  value: 1000,
                  message: 'Must be less than or equal to 1000',
                },
              }}
              shouldUnregister={false}
              startAdornment={
                <div className='h-full pl-2.5 pr-1 flex items-center justify-center'>
                  <FontAwesomeIcon
                    icon={faUsers}
                    className='text-sm text-primary-blue'
                  />
                </div>
              }
              displayVariant='inline'
            />
            <div className='border-b border-gray-200 my-6' />
            <ZenControlledNumberInput<
              GoalsFormInput,
              'teamTransactionsPerMember'
            >
              control={control}
              name='teamTransactionsPerMember'
              label='How many transactions will each team member close per year?'
              placeholder='Enter an number'
              shouldUnregister={false}
              rules={{
                required: 'Please set a value',
                min: {
                  value: 1,
                  message: 'Must be greater than or equal to 1',
                },
                max: {
                  value: 500,
                  message: 'Must be less than or equal to 500',
                },
              }}
              allowDecimals={false}
              displayVariant='inline'
            />
            <div className='border-b border-gray-200 my-6' />
            <ZenControlledInputSlider<GoalsFormInput, 'teamAverageSalesPrice'>
              control={control}
              name='teamAverageSalesPrice'
              label='What is the average home sales price?'
              placeholder='Enter an amount'
              shouldUnregister={false}
              rules={{
                required: 'Please set a value',
                min: {
                  value: 10000,
                  message: 'Must be greater than or equal to $10,000',
                },
                max: {
                  value: 20000000,
                  message: 'Must be less than or equal to $20,000,000',
                },
              }}
              showSlider={false}
              startAdornment={<div className='h-full text-primary-blue'>$</div>}
            />
            <div className='border-b border-gray-200 my-6' />
            <div className='flex md:flex-row flex-col items-start justify-between space-x-2'>
              <span className='font-zen-body font-semibold text-zen-dark-9'>
                What is the average commission charged on one end of a deal?
              </span>
              <div className='flex justify-end items-start'>
                <div className='flex-shrink-0 mt-1'>
                  <ZenControlledToggleInput<
                    GoalsFormInput,
                    'teamUseAverageFixedCommission'
                  >
                    name='teamUseAverageFixedCommission'
                    control={control}
                    shouldUnregister={false}
                    rightIcon='$'
                    leftIcon='%'
                    onChangeSpy={(value) => {
                      if (value) {
                        logAnalyticsEvent(
                          AnalyticsEventEnum.WEALTH_PLAN_COMMISSION_INCOME_TEAM_USE_AVERAGE_FIXED_COMMISSION_TOGGLED_ON,
                        );
                      } else {
                        logAnalyticsEvent(
                          AnalyticsEventEnum.WEALTH_PLAN_COMMISSION_INCOME_TEAM_USE_AVERAGE_FIXED_COMMISSION_TOGGLED_OFF,
                        );
                      }
                    }}
                  />
                </div>
                <div className='flex-grow md:max-w-[220px]'>
                  {teamUseAverageFixedCommission ? (
                    <ZenControlledInputSlider<
                      GoalsFormInput,
                      'teamAverageFixedCommission'
                    >
                      control={control}
                      name='teamAverageFixedCommission'
                      startAdornment={
                        <div className='h-full text-primary-blue'>$</div>
                      }
                      placeholder='Amount'
                      shouldUnregister={false}
                      rules={{
                        required: 'Please enter an amount',
                        ...MONEY_VALIDATIONS,
                        validate: (value) => {
                          if (!!value) {
                            if (value > 100000) {
                              return 'Amount must be less than or equal to 100,000';
                            }
                          }
                          return;
                        },
                      }}
                      showSlider={false}
                    />
                  ) : (
                    <ZenControlledPercentageInput<
                      GoalsFormInput,
                      'teamAverageTransactionCommissionPercentage'
                    >
                      control={control}
                      name='teamAverageTransactionCommissionPercentage'
                      placeholder='Percentage'
                      shouldUnregister={false}
                      startAdornment={
                        <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
                          %
                        </div>
                      }
                      rules={{
                        required: 'Please enter a percentage',
                        ...PERCENT_VALIDATION,
                        validate: (value) => {
                          if (!!value) {
                            if (value < 0.1) {
                              return 'Percentage must be greater than or equal to 0.1';
                            } else if (value > 10) {
                              return 'Percentage must be less than or equal to 10';
                            }
                          }
                          return;
                        },
                      }}
                      displayVariant='inline'
                    />
                  )}
                </div>
              </div>
            </div>
            <div className='border-b border-gray-200 my-6' />
            <ZenControlledPercentageInput<
              GoalsFormInput,
              'teamLeaderCommissionPercentagePerTransaction'
            >
              control={control}
              label='What percentage is yours?'
              startAdornment={
                <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
                  %
                </div>
              }
              name='teamLeaderCommissionPercentagePerTransaction'
              shouldUnregister={false}
              rules={{
                required: 'Percentage is required',
                min: {
                  value: 1,
                  message: 'Must be greater than or equal to 1',
                },
                max: {
                  value: 100,
                  message: 'Must be less than or equal to 10',
                },
                ...PERCENT_VALIDATION,
              }}
              placeholder='Percentage'
              displayVariant='inline'
            />
          </div>
        </div>
      </WealthManagementCardV2>
    </ContainerWrapper>
  );
};

export default CommissionIncomeFormInputs;
