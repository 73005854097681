import React from 'react';
import ZenPopover from '../../../ZenPopover';

interface MinimumTeammatesPopoverProps {}

const MinimumTeammatesPopover: React.FC<MinimumTeammatesPopoverProps> = () => {
  return (
    <ZenPopover
      title='Minimum Teammates'
      description={
        <div className='text-zen-dark-9 text-base font-light'>
          <p>
            Some types of teams require a minimum number of team members. For
            instance, if the Real Administrator sets this value to 10, then
            Rezen will not allow this team to have fewer than 10 members.
          </p>
        </div>
      }
    />
  );
};

export default MinimumTeammatesPopover;
