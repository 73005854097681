import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { onBoardingStepType } from '../../types';
import ZenButton from '../Zen/ZenButton';
import { cn } from '../../utils/classUtils';

export interface VerticalStepProgressProps {
  steps: onBoardingStepType[];
  inProgressIndex: number;
}

const ZenApplicationStepProgress: React.FC<VerticalStepProgressProps> = ({
  steps,
  inProgressIndex,
}) => (
  <ol>
    {steps.map((step, index) => {
      const isLastStep = index === steps.length - 1;
      const isStepCompleted = inProgressIndex > index;

      return (
        <li
          key={step.name}
          className={cn('relative flex items-center', {
            'pb-10': !isLastStep,
          })}
        >
          {!isLastStep && (
            <div className='ml-5 absolute w-2 h-full pb-2 bg-grey-200 top-11' />
          )}
          <div className='grid md:grid-flow-col grid-flow-row grid-cols-1'>
            <div className='relative flex items-start group'>
              <FontAwesomeIcon
                icon={isStepCompleted ? faCircleCheck : faCircleExclamation}
                className={cn(
                  'w-12 h-12 bg-primary-light',
                  isStepCompleted ? 'text-green-400' : 'text-grey-300',
                )}
              />

              <span className='ml-4 min-w-0 flex flex-col my-auto'>
                <span
                  className={cn(
                    'text-base font-zen-body font-semibold  whitespace-pre-wrap',
                    isStepCompleted ? 'text-primary-dark' : 'text-grey-600',
                  )}
                >
                  {step.name}
                </span>
                {step.subtitle && (
                  <span className='text-sm text-grey-600'>{step.subtitle}</span>
                )}
              </span>
            </div>
            {step.action && (
              <span className='md:pt-0 pt-1 ml-10'>
                <ZenButton
                  label={step.action!.buttonText}
                  variant='secondary-gray-outline'
                  onClick={step.action!.onClick}
                />
              </span>
            )}
          </div>
        </li>
      );
    })}
  </ol>
);

export default ZenApplicationStepProgress;
