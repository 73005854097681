import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faUser } from '@fortawesome/pro-solid-svg-icons';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { useEffect } from 'react';
import {
  getOfficialPlan,
  setWealthKnowledgeLandingPage,
} from '../../../slices/WealthManagementSlice';
import ZenButton from '../../Zen/ZenButton';
import { Earning } from '../WealthManagementTypes';
import AttractingAgentsCard from '../../../assets/img/wealthManagement/attracting-agent-card.svg';
import AnalyticsService from '../../../services/AnalyticsService';
import { AnalyticsEventEnum, RootState } from '../../../types';
import BringEmOn from '../../../assets/img/wealthManagement/agent-bring-em-on.svg';
import { ZenPrimaryGradientText } from './ZenPrimaryGradientText';
import { LearnMoreCarousel } from './LearnMoreCarousel';
import AgreementDetails from './AgreementDetails';
import ZenLandingPageIntro from './ZenLandingPageIntro';

const HowItWorks = () => {
  return (
    <div className='pb-10 pt-32 mt-5 relative bg-white'>
      <div className='flex flex-col justify-center items-center mt-10'>
        <div className='text-[52px] font-bold text-black w-2/3 text-center mb-5 font-zen-primary-regular'>
          How <ZenPrimaryGradientText>it Works</ZenPrimaryGradientText>
        </div>
        <div className='w-4/5 text-center font-zen-body-2 text-2xl'>
          Real grants you a stock award when an agent you&apos;ve brought in
          completes their first qualified transaction.
        </div>
        <div className='w-4/5 text-center font-zen-body-2 text-2xl'>
          A qualified first transaction has a minimum GCI of $2,000.
        </div>
      </div>
      <div className='w-full h-full bg-white flex items-center justify-center'>
        <img
          src={AttractingAgentsCard}
          alt='how capping works'
          className='w-full h-full'
        />
      </div>
    </div>
  );
};

const AttractingAwardBreakdown = () => {
  const dispatch = useDispatch();
  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );
  useEffect(() => {
    if (officialPlanAndAwardData === undefined) {
      dispatch(getOfficialPlan());
    }
  }, [dispatch, officialPlanAndAwardData]);

  const individual = officialPlanAndAwardData?.awardsConfig?.capLevels?.find(
    (c) => c.awardType === 'ATTRACTING' && c.capLevel === 12000,
  );
  const teamMember = officialPlanAndAwardData?.awardsConfig?.capLevels?.find(
    (c) => c.awardType === 'ATTRACTING' && c.capLevel === 6000,
  );
  const platinum = officialPlanAndAwardData?.awardsConfig?.capLevels?.find(
    (c) => c.awardType === 'ATTRACTING' && c.capLevel === 4000,
  );

  return (
    <div>
      <div className='flex flex-col justify-center items-center bg-black py-10'>
        <div className='text-[52px] font-bold text-white w-2/3 text-center mb-5 font-zen-primary-regular'>
          Your <ZenPrimaryGradientText>Attracting Award</ZenPrimaryGradientText>{' '}
          Breakdown
        </div>
        <div className='text-white w-2/5 text-center mb-5 font-zen-body-2 font-light text-xl'>
          Your attraction stock award varies based on your cap level.
        </div>
        <div className='bg-gray-900 rounded-xl flex flex-row gap-2 w-3/5 py-4 px-4 justify-between mb-3'>
          <div className='flex gap-3 ml-2'>
            <FontAwesomeIcon
              icon={faUser}
              size='xl'
              className='text-primary-blue'
            />
            <div>
              <div className='text-white font-zen-body-2'>INDIVIDUAL AGENT</div>
              <div className='text-white font-zen-body-2'>& TEAM LEAD</div>
            </div>
          </div>
          <div>
            <div className='text-blue-200 text-xl font-zen-body-2'>
              {individual?.awardAmount} shares
            </div>
            <div className='text-gray-400 text-xl font-zen-body-2'>
              ${individual?.capLevel! / 1000}K CAP
            </div>
          </div>
        </div>

        <div className='bg-gray-900 rounded-xl flex flex-row gap-2 w-3/5 py-3 px-4 justify-between mb-3'>
          <div className='flex gap-3 ml-2'>
            <FontAwesomeIcon
              icon={faUsers}
              size='xl'
              className='text-zen-purple-1'
            />
            <div>
              <div className='text-white font-zen-body-2'>STANDARD TEAM</div>
              <div className='text-white font-zen-body-2'>MEMBER</div>
            </div>
          </div>
          <div>
            <div className='text-blue-200 text-xl font-zen-body-2'>
              {teamMember?.awardAmount} shares
            </div>
            <div className='text-gray-400 text-xl font-zen-body-2'>
              ${teamMember?.capLevel! / 1000}K CAP
            </div>
          </div>
        </div>

        <div className='bg-gray-900 rounded-xl flex flex-row gap-2 w-3/5 py-3 px-4 justify-between mb-5'>
          <div className='flex gap-3 ml-2'>
            <FontAwesomeIcon
              icon={faStar}
              size='xl'
              className='text-amber-500'
            />
            <div>
              <div className='text-white font-zen-body-2'>PLATINUM TEAM</div>
              <div className='text-white font-zen-body-2'>MEMBER</div>
            </div>
          </div>
          <div>
            <div className='text-blue-200 text-xl font-zen-body-2'>
              {platinum?.awardAmount} shares
            </div>
            <div className='text-gray-400 text-xl font-zen-body-2'>
              ${platinum?.capLevel! / 1000}K CAP
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WhatsMyCommissionCap = () => {
  const dispatch = useDispatch();

  const handleViewCommissionIncome = () => {
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.WEALTH_PLAN_LP_STOCK_AWARD_ATTRACTING_CLICK_ON_VIEW_COMMISSION_INCOME,
    );
    dispatch(setWealthKnowledgeLandingPage(Earning.COMMISSION_INCOME));
  };

  return (
    <div className='py-20'>
      <div className='flex flex-col justify-center items-center'>
        <div className='text-[52px] font-bold text-black w-1/2 text-center mb-5 font-zen-primary-regular'>
          What&apos;s My
          <div>
            <ZenPrimaryGradientText>Commission Cap ?</ZenPrimaryGradientText>
          </div>
        </div>

        <div className='text-xl text-center mb-5 w-3/5'>
          Agents achieve a &apos;capped&apos; status when they reach their
          earnings limit, with varying cap levels applicable to three distinct
          agent types at Real.
        </div>
        <div className='w-1/4'>
          <ZenButton
            label='See Commission Income →'
            onClick={handleViewCommissionIncome}
            isFullWidth
          />
        </div>
      </div>
    </div>
  );
};

export const ZenStockAwardForAttractingKnowledgePage = () => {
  return (
    <>
      <ZenLandingPageIntro
        earningType={Earning.STOCK_AWARD_FOR_ATTRACTING}
        sloganImage={BringEmOn}
        subtitle='Let Real reward you for attracting other agents'
        showAddToMyPlan
        buttonProps={{
          earningType: Earning.STOCK_AWARD_FOR_ATTRACTING,
        }}
        headerBackgroundProps={{ bgVariant: 'blue' }}
      />
      <HowItWorks />
      <AttractingAwardBreakdown />
      <WhatsMyCommissionCap />
      <AgreementDetails />
      <LearnMoreCarousel />
    </>
  );
};
