import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import SelfOrAdminOnly from '../../auth/SelfOrAdminOnly';
import {
  AgentControllerApi,
  AgentLicenseImagesResponse,
  LicenseResponse,
  TeamResponseStatusEnum,
} from '../../../openapi/yenta';
import { RootState } from '../../../types';
import IconButton from '../../IconButton';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenTeamStatusCell from '../../table/Cells/ZenTeamStatusCell';
import ErrorService from '../../../services/ErrorService';
import ZenEditAgentLicense from './ZenEditAgentLicense';

export interface AgentLicenseImageWithId {
  id: string;
  data: AgentLicenseImagesResponse;
}

interface ZenAgentLicenseDetailProps {
  license: LicenseResponse;
}

const ZenAgentLicenseDetail: React.FC<ZenAgentLicenseDetailProps> = ({
  license,
}) => {
  const agentDetailResponse = useSelector(
    (state: RootState) => state.agentDetail?.detailResponse?.data,
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isAdmin } = useSelector((state: RootState) => state.auth);
  const [agentLicensesImage, setAgentLicenseImages] = useState<
    AgentLicenseImageWithId[]
  >([]);

  const fetchAgentLicenseImages = useCallback(async () => {
    if (license?.id) {
      try {
        const { data } = await new AgentControllerApi(
          await getYentaConfiguration(),
        ).getAgentLicenseImages(license?.id);
        setAgentLicenseImages([{ id: license?.id, data }]);
      } catch (e) {
        ErrorService.notifyIgnoreHandled('Unable to fetch license images', e, {
          license: { id: license?.id },
        });
      }
    }
  }, [license.id]);

  useEffect(() => {
    fetchAgentLicenseImages();
  }, [fetchAgentLicenseImages]);

  return (
    <div className='p-2 px-3'>
      <div className='flex flex-row items-center justify-between'>
        <div className='flex flex-row items-center space-x-4'>
          <div className='flex flex-row items-center space-x-2'>
            <p className='font-zen-body text-base text-zen-dark-7'>Lic. No.</p>
            <p className='font-zen-body font-semibold text-sm text-zen-dark-9'>
              {license.number}
            </p>
          </div>
          <ZenTeamStatusCell
            status={
              license.active
                ? TeamResponseStatusEnum.Active
                : TeamResponseStatusEnum.Inactive
            }
          />
        </div>
        <SelfOrAdminOnly agentId={agentDetailResponse?.id}>
          <IconButton
            buttonStyle='bg-grey-200 rounded-full h-9'
            variant='none'
            leftIcon={
              <FontAwesomeIcon
                icon={isAdmin ? faPen : faEye}
                aria-label={isAdmin ? 'edit-licenses' : 'view-license'}
                size={isAdmin ? 'sm' : 'lg'}
                className='w-5'
              />
            }
            onClick={() => setIsOpen(true)}
          />
        </SelfOrAdminOnly>
      </div>
      <div className='flex md:flex-row flex-col md:items-center md:space-x-4'>
        <div className='flex flex-row items-center space-x-2'>
          <p className='font-zen-body text-base text-zen-dark-7'>Expires:</p>
          <p className='font-zen-body font-semibold text-sm text-zen-dark-9'>
            {DateTime.fromISO(license.expirationDate!).toFormat('LL/dd/yyyy')}
          </p>
        </div>
        <div className='flex flex-row items-center space-x-2'>
          <p className='font-zen-body text-base text-zen-dark-7'>Complaints:</p>
          <p className='font-zen-body font-semibold text-sm text-zen-dark-9'>
            {license.knownComplaints ? 'Yes' : 'No'}
          </p>
        </div>
      </div>
      {isOpen && (
        <ZenEditAgentLicense
          license={license}
          agent={agentDetailResponse!}
          licenseImages={agentLicensesImage}
          readOnly={!isAdmin}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default ZenAgentLicenseDetail;
