import { useDispatch } from 'react-redux';
import ErrorService from '../../../services/ErrorService';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { AsyncSelectOption } from '../../../types';
import { officeTableFetchData } from '../../../utils/TableUtils';
import AsyncMultiSelectColumnFilter, {
  AsyncMultiSelectColumnFilterProps,
} from './Base/AsyncMultiSelectColumnFilter';

export interface OfficeAsyncMultiSelectColumnFilterProps<D extends object>
  extends AsyncMultiSelectColumnFilterProps<D> {}

const OfficeAsyncMultiSelectColumnFilter = <D extends object>(
  props: OfficeAsyncMultiSelectColumnFilterProps<D>,
) => {
  const dispatch = useDispatch();

  return (
    <AsyncMultiSelectColumnFilter
      {...props}
      fetchData={async (search, page = 0) => {
        try {
          const { data } = await officeTableFetchData({
            page,
            pageSize: 20,
            search,
          });

          const options: AsyncSelectOption[] = data.map((resp) => ({
            value: `${resp.id}`,
            label: `${resp.name}`,
          }));

          return options;
        } catch (e) {
          ErrorService.notify(
            'Unable to search request offices in add office to user form',
            e,
            {
              search: {
                term: search,
                page,
              },
            },
          );
          dispatch(
            showErrorToast(
              'An unexpected error occurred.',
              'We were unable to search for an office. Please try again in a few moments or contact support.',
            ),
          );
        }
        return [];
      }}
    />
  );
};

export default OfficeAsyncMultiSelectColumnFilter;
