import { faAdd, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { every } from 'lodash';
import { useState } from 'react';
import { useFieldArray } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  AgentResponseAccountCountryEnum,
  CreatePaymentMethod,
  CreatePaymentMethodPurposeEnum,
  CreatePaymentMethodTypeEnum,
  UpdatePaymentMethod,
  UpdatePaymentMethodPurposeEnum,
  UpdatePaymentMethodTypeEnum,
} from '../../openapi/yenta';
import {
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps,
} from '../../routes/MSDynamicsOnboardingRoute';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import {
  archivePaymentMethod,
  createPaymentMethod,
  updatePaymentMethod,
} from '../../slices/PaymentMethodSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import { AppDispatch, RootState } from '../../types';
import {
  getBankInfoInvalidLengthErrMessage,
  getBankPurposeLabel,
} from '../../utils/MSDynamicHelper';
import {
  CA_TRANSIT_NUMBER_VALIDATIONS,
  CA_INSTITUTION_NUMBER_VALIDATIONS,
  US_ROUTING_NUMBER_VALIDATIONS,
} from '../../utils/Validations';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import ZenControlledRadioInput from '../Zen/Input/ZenControlledRadioInput';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenFormErrorMessage from '../Zen/Input/ZenFormErrorMessage';
import ZenButton from '../Zen/ZenButton';
import { cn } from '../../utils/classUtils';
import withMSDynamicsProgress from './withMSDynamicsProgress';

const MSDynamicsBankInformation: StepByStepComponent<
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps
> = ({ form: { control, watch, trigger }, onPrevious, onNext }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(false);
  const [invalidLengthErr, setInvalidLengthErr] = useState('');
  const [isDeletingAccountById, setIsDeletingAccountById] = useState<
    Record<string, boolean>
  >({});

  const {
    fields,
    append,
    remove,
    update,
  } = useFieldArray<MSDynamicOnboardingFormData>({
    control,
    name: 'bankInfo',
  });

  const { userDetail } = useSelector((state: RootState) => state.auth);

  const isCanada =
    userDetail?.accountCountry === AgentResponseAccountCountryEnum.Canada;

  const handleNext = async () => {
    try {
      const isValid = await trigger();
      if (!isValid) {
        dispatch(
          showErrorToast('There are fields which need to be filled out'),
        );
        return;
      }
      setLoading(true);
      const formValues = watch();
      const errMsg = getBankInfoInvalidLengthErrMessage(formValues.bankInfo);
      if (!!errMsg?.length) {
        setInvalidLengthErr(errMsg);
        return;
      } else {
        setInvalidLengthErr('');
      }
      const response: boolean[] = [];
      const createPaymentReq: CreatePaymentMethod[] = [];
      const createPaymentReqFieldIdx: number[] = [];

      for (let i = 0; i < formValues.bankInfo.length; i++) {
        const bank = formValues.bankInfo[i];

        if (bank.bankId) {
          update(i, { ...bank, bankId: bank.bankId });
          const req: UpdatePaymentMethod = {
            bankName: bank.bankName,
            accountNumber: bank.accountNumber,
            nickname: bank.nickname,
            purpose: bank.purpose?.value as UpdatePaymentMethodPurposeEnum,
            type: bank.type as UpdatePaymentMethodTypeEnum,
            // @ts-expect-error
            usRoutingNumber: isCanada ? undefined : bank.usRoutingNumber,
            canadaRoutingNumber: isCanada
              ? {
                  branchNumber: bank.canadaRoutingNumber?.branchNumber!,
                  institutionNumber: bank.canadaRoutingNumber
                    ?.institutionNumber!,
                }
              : undefined,
          };
          response.push(
            await dispatch(
              updatePaymentMethod(userDetail?.id!, bank.bankId!, req, {
                silent: true,
              }),
            ),
          );
        } else {
          const req: CreatePaymentMethod = {
            bankName: bank.bankName,
            accountNumber: bank.accountNumber,
            nickname: bank.nickname,
            purpose: bank.purpose?.value as CreatePaymentMethodPurposeEnum,
            type: bank.type as CreatePaymentMethodTypeEnum,
            // @ts-expect-error
            usRoutingNumber: isCanada ? undefined : bank.usRoutingNumber,
            canadaRoutingNumber: isCanada
              ? {
                  branchNumber: bank.canadaRoutingNumber?.branchNumber!,
                  institutionNumber: bank.canadaRoutingNumber
                    ?.institutionNumber!,
                }
              : undefined,
          };
          createPaymentReq.push(req);
          createPaymentReqFieldIdx.push(i);
        }
      }

      if (createPaymentReq.length) {
        const createPaymentRes = await dispatch(
          createPaymentMethod(
            userDetail?.id!,
            {
              paymentMethods: createPaymentReq,
            },
            { silent: true },
          ),
        );
        createPaymentRes?.forEach((bankId, index) => {
          const fieldIdx = createPaymentReqFieldIdx[index];
          const bank = formValues.bankInfo[fieldIdx];
          response.push(!!bankId);
          if (!!bankId) {
            update(fieldIdx, { ...bank, bankId });
          }
        });
      }

      const isSuccess = every(response, Boolean);

      if (isSuccess) {
        dispatch(showSuccessToast('Payment method updated successfully.'));
        onNext();
      }
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notifyIgnoreAuthErrors(
        'Unable to create payment method (ms-dynamics)',
        e,
        {
          user: { id: userDetail?.id },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async (index: number, bankId?: string) => {
    if (bankId) {
      setIsDeletingAccountById((map) => ({ ...map, [bankId]: true }));
      await dispatch(archivePaymentMethod(userDetail?.id!, bankId!));
      setIsDeletingAccountById((map) => ({ ...map, [bankId]: false }));
    }
    remove(index);
  };

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div>
          <p className='text-xl font-primary-medium mb-4'>
            Ready to get paid? Add your bank account information.
          </p>
          {fields.map((bank, index) => {
            const [usRoutingNumber, accountNumber, purpose, bankInfo] = watch([
              `bankInfo.${index}.usRoutingNumber`,
              `bankInfo.${index}.accountNumber`,
              `bankInfo.${index}.purpose`,
              `bankInfo`,
            ]);
            const isPurposeRevShareOrTransaction =
              purpose?.value === CreatePaymentMethodPurposeEnum.Revshare ||
              purpose?.value === CreatePaymentMethodPurposeEnum.Transaction;
            const showAddBtn =
              bankInfo.length < 2 &&
              index === 0 &&
              isPurposeRevShareOrTransaction;
            const showDeleteBtn = index !== 0;
            const anotherPaymentMethodPurpose = bankInfo
              ?.filter((_, i) => i !== index)
              .map((p) => p.purpose?.value);

            return (
              <div className='space-y-4 mb-5' key={bank.id}>
                <div className='pt-2 flex justify-between'>
                  <div>
                    <ZenControlledRadioInput<
                      MSDynamicOnboardingFormData,
                      `bankInfo.${number}.type`
                    >
                      name={`bankInfo.${index}.type`}
                      control={control}
                      label='Is this a checking or savings account?'
                      inlineOptions
                      shouldUnregister={false}
                      options={[
                        {
                          label: 'Checking',
                          value: CreatePaymentMethodTypeEnum.Checking,
                        },
                        {
                          label: 'Savings',
                          value: CreatePaymentMethodTypeEnum.Savings,
                        },
                      ]}
                      rules={{
                        required: 'Please select account type',
                      }}
                      isRequired
                    />
                  </div>
                  {showDeleteBtn && (
                    <button
                      className={cn('mt-2', {
                        'opacity-50': isDeletingAccountById[bank.bankId!],
                      })}
                      disabled={isDeletingAccountById[bank.bankId!]}
                      onClick={() => handleDeleteAccount(index, bank.bankId)}
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className='text-zen-danger'
                      />
                    </button>
                  )}
                </div>
                <div className='pt-2'>
                  <ZenControlledTextInput<
                    MSDynamicOnboardingFormData,
                    `bankInfo.${number}.bankName`
                  >
                    control={control}
                    label='Bank Name'
                    name={`bankInfo.${index}.bankName`}
                    placeholder='Bank Name'
                    shouldUnregister={false}
                    rules={{ required: 'Bank name is required' }}
                    isRequired
                  />
                  <p className='font-zen-body text-base font-normal text-zen-dark-6'>
                    Enter a bank name holding this account.
                  </p>
                </div>
                <div className='pt-2'>
                  <ZenControlledTextInput<
                    MSDynamicOnboardingFormData,
                    `bankInfo.${number}.nickname`
                  >
                    control={control}
                    label='Account Nickname'
                    name={`bankInfo.${index}.nickname`}
                    placeholder='Account Nickname'
                    shouldUnregister={false}
                    rules={{ required: 'Nickname is required' }}
                    isRequired
                  />
                  <p className='font-zen-body text-base font-normal text-zen-dark-6'>
                    Enter a nickname you’d like to use for this account
                  </p>
                </div>

                {isCanada ? (
                  <>
                    <div>
                      <ZenControlledTextInput<
                        MSDynamicOnboardingFormData,
                        `bankInfo.${number}.canadaRoutingNumber.institutionNumber`
                      >
                        control={control}
                        label='Institution number'
                        name={`bankInfo.${index}.canadaRoutingNumber.institutionNumber`}
                        placeholder='Institution number'
                        shouldUnregister={false}
                        rules={{
                          required: 'Institution number is required',
                          ...CA_INSTITUTION_NUMBER_VALIDATIONS,
                        }}
                        isRequired
                      />
                    </div>
                    <div>
                      <ZenControlledTextInput<
                        MSDynamicOnboardingFormData,
                        `bankInfo.${number}.canadaRoutingNumber.branchNumber`
                      >
                        control={control}
                        label='Transit number'
                        name={`bankInfo.${index}.canadaRoutingNumber.branchNumber`}
                        placeholder='Transit number'
                        shouldUnregister={false}
                        rules={{
                          required: 'Transit number is required',
                          ...CA_TRANSIT_NUMBER_VALIDATIONS,
                        }}
                        isRequired
                      />
                    </div>
                  </>
                ) : (
                  <div>
                    <ZenControlledTextInput<
                      MSDynamicOnboardingFormData,
                      `bankInfo.${number}.usRoutingNumber`
                    >
                      control={control}
                      label='Routing number'
                      name={`bankInfo.${index}.usRoutingNumber`}
                      placeholder='Routing number'
                      shouldUnregister={false}
                      rules={{
                        required: 'Routing number is required',
                        ...US_ROUTING_NUMBER_VALIDATIONS,
                      }}
                      isRequired
                    />
                    <div className='mt-3'>
                      <ZenControlledTextInput<
                        MSDynamicOnboardingFormData,
                        `bankInfo.${number}.usConfirmRoutingNumber`
                      >
                        control={control}
                        name={`bankInfo.${index}.usConfirmRoutingNumber`}
                        placeholder='Confirm Routing number'
                        shouldUnregister={false}
                        disableCopyPaste
                        rules={{
                          required: 'Confirm Routing number is required',
                          ...US_ROUTING_NUMBER_VALIDATIONS,
                          validate: (value) => {
                            if (value !== usRoutingNumber) {
                              return "Routing number doesn't match";
                            }
                            return;
                          },
                        }}
                        isRequired
                      />
                    </div>
                    <p className='font-zen-body text-base font-normal text-zen-dark-6'>
                      Enter the routing number. It is located on the bottom left
                      corner of a check or within account details from the bank.
                    </p>
                  </div>
                )}

                <ZenControlledTextInput<
                  MSDynamicOnboardingFormData,
                  `bankInfo.${number}.accountNumber`
                >
                  control={control}
                  label='Account number'
                  name={`bankInfo.${index}.accountNumber`}
                  placeholder='Account number'
                  shouldUnregister={false}
                  rules={{ required: 'Account number is required' }}
                  isRequired
                />

                <ZenControlledTextInput<
                  MSDynamicOnboardingFormData,
                  `bankInfo.${number}.confirmAccountNumber`
                >
                  control={control}
                  name={`bankInfo.${index}.confirmAccountNumber`}
                  placeholder='Confirm Account number'
                  shouldUnregister={false}
                  disableCopyPaste
                  rules={{
                    required: 'Confirm Account number is required',
                    validate: (value) => {
                      if (value !== accountNumber) {
                        return "Account Number doesn't match";
                      }
                      return undefined;
                    },
                  }}
                  isRequired
                />

                <div className='w-full max-w-md'>
                  <ZenControlledSelectInput<
                    MSDynamicOnboardingFormData,
                    `bankInfo.${number}.purpose`
                  >
                    control={control}
                    label='Purpose?'
                    name={`bankInfo.${index}.purpose`}
                    placeholder='Select one'
                    rules={{
                      required: 'Purpose is required',
                      validate: (val) => {
                        if (
                          fields.length > 1 &&
                          val?.value === CreatePaymentMethodPurposeEnum.Any
                        ) {
                          return 'All Payments is not allowed with multiple bank accounts';
                        }
                        if (
                          val &&
                          anotherPaymentMethodPurpose?.includes(val.value)
                        ) {
                          return "Can't have two bank accounts with same purpose";
                        }
                        return undefined;
                      },
                    }}
                    shouldUnregister={false}
                    options={[
                      {
                        label: getBankPurposeLabel(
                          CreatePaymentMethodPurposeEnum.Any,
                        ),
                        value: CreatePaymentMethodPurposeEnum.Any,
                      },
                      {
                        label: getBankPurposeLabel(
                          CreatePaymentMethodPurposeEnum.Transaction,
                        ),
                        value: CreatePaymentMethodPurposeEnum.Transaction,
                      },
                      {
                        label: getBankPurposeLabel(
                          CreatePaymentMethodPurposeEnum.Revshare,
                        ),
                        value: CreatePaymentMethodPurposeEnum.Revshare,
                      },
                    ]}
                    isRequired
                  />
                </div>
                {showAddBtn && (
                  <button
                    className='flex items-center space-x-2'
                    onClick={() =>
                      append({
                        bankName: '',
                        bankId: '',
                        accountNumber: '',
                        nickname: '',
                        usRoutingNumber: isCanada ? undefined : '',
                        canadaRoutingNumber: isCanada
                          ? { branchNumber: '', institutionNumber: '' }
                          : undefined,
                        confirmAccountNumber: '',
                        usConfirmRoutingNumber: '',
                        purpose: undefined,
                        type: undefined,
                      })
                    }
                  >
                    <FontAwesomeIcon
                      icon={faAdd}
                      className='text-primary-blue text-base'
                    />
                    <p className='text-primary-blue text-sm font-zen-body font-semibold'>
                      ADD ANOTHER BANK
                    </p>
                  </button>
                )}
              </div>
            );
          })}
          {!!invalidLengthErr?.length && (
            <div className='py-4 flex justify-center'>
              <ZenFormErrorMessage message={invalidLengthErr} />
            </div>
          )}
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-sm'>
          <div className='flex flex-row items-center py-8 shadow-top-sm justify-center space-x-5'>
            <div className='w-28'>
              <ZenButton
                isFullWidth
                variant='primary-outline'
                type='button'
                label='Previous'
                onClick={onPrevious}
              />
            </div>
            <div className='w-56'>
              <ZenButton
                isFullWidth
                isSubmitting={loading}
                type='submit'
                variant='primary'
                isDisabled={loading}
                label='Next'
                onClick={handleNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withMSDynamicsProgress(MSDynamicsBankInformation);
