import React from 'react';
import classNames from 'classnames';
import Cancel from '@material-ui/icons/Cancel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import GeminiButton from '../../components/Gemini/GeminiButton';

interface ReferralCreatedConfirmationModalProps {
  isOpen: boolean;
  onClose(): void;
}

const ReferralCreatedConfirmationModal: React.FC<ReferralCreatedConfirmationModalProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'
      title='zen-modal'
    >
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
        <div
          className={classNames(
            'w-full max-h-[85%] pb-10 bg-white rounded-xl z-10 shadow-[rgba(0,0,0,0.25)_15px_15px_4px_0px] scrollbar',
            'md:w-1/2 lg:w-1/3',
            'overflow-y-auto',
          )}
          aria-label='simple-modal'
        >
          <div className='m-4 flex justify-end'>
            <Cancel
              className='text-zen-dark-5 cursor-pointer text-2xl'
              onClick={onClose}
            />
          </div>
          <div className='flex flex-row items-center rounded-t-xl'>
            <div className='flex flex-grow flex-shrink flex-col items-center justify-center my-3 space-y-3'>
              <div>
                <FontAwesomeIcon
                  icon={light('party-horn')}
                  className='text-rezen-blue-600 text-4xl rounded-full bg-gray-100 p-5'
                />
              </div>
              <p className='font-inter font-normal text-primary-dark text-lg text-center'>
                Wohoo! Referral created
              </p>
              <p className='text-sm text-center text-primary-dark font-inter font-light'>
                You have successfully created and published the referral
              </p>
            </div>
          </div>
          <div className='px-16 flex-1 flex-col justify-center items-center'>
            <div>
              <GeminiButton
                variant='primary-outline'
                label='View Referral'
                isFullWidth
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralCreatedConfirmationModal;
