import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import ChargeCard from '../../assets/img/wallet/charge-card.svg';
import DebitCard from '../../assets/img/wallet/debit-card.svg';
import {
  useFetchEligibleOfferings,
  useWallet,
} from '../../query/wallet/useWallet';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';
import ZenButton from '../Zen/ZenButton';
import ZenResourceContainer from '../Zen/ZenResourceContainer';
import WalletAgreementModal from './Agreement/WalletAgreementModal';
import WalletRedirectionModal from './WalletRedirectionModal';

interface WalletOfferingsProps {}

const WalletOfferings: React.FC<WalletOfferingsProps> = () => {
  const { data, isLoading, errorCode } = useFetchEligibleOfferings();
  const { data: wallet } = useWallet();

  const [offerId, setOfferId] = useState<string>();
  const [formUrl, setFormUrl] = useState<string>();

  const isApplicationPending = !isEmpty(wallet?.pendingBankingApplications);

  if (data && isEmpty(data?.eligibleDebitOfferings)) {
    return <p className='m-4 font-inter'>No Offers</p>;
  }

  return (
    <div>
      <ZenResourceContainer
        loading={isLoading}
        isEmpty={isEmpty(data)}
        resourceName='offering'
        errorCode={errorCode}
      >
        <div className='flex flex-col items-center p-10 xl:pt-20'>
          <div>
            <p className='mb-4 font-inter font-light text-base	text-primary-dark'>
              Select your debit or charge card.
            </p>

            <div className='flex flex-col xl:flex-row justify-center gap-6'>
              {/* We want to show pending application if user has already submitted Real's application */}
              {isApplicationPending &&
                wallet?.pendingBankingApplications?.map((application) => {
                  if (!application?.formUrl) {
                    return <div />;
                  }

                  return (
                    <div
                      key={application?.id}
                      className='flex justify-between items-center p-3 w-fit border rounded-xl border-grey-300'
                    >
                      <div className='ml-3'>
                        <p className='font-inter font-medium text-lg text-primary-dark'>
                          Sign up for the Debit Card
                        </p>

                        <p className='sm:w-72 mr-6 mt-2 font-inter font-light text-sm text-primary-dark'>
                          Automatic cashback offers. No monthly fee. No credit
                          check
                        </p>

                        <ZenButton
                          label='Sign up'
                          className='w-24 h-10 mt-4 px-4 border-2 rounded-lg bg-white text-rezen-blue-600 font-inter font-medium text-base'
                          onClick={() => setFormUrl(application?.formUrl)}
                        />
                      </div>

                      <img src={DebitCard} alt='debit-card' />
                    </div>
                  );
                })}

              {/* Debit Card */}
              {!isApplicationPending &&
                data?.eligibleDebitOfferings?.map(({ debitOffering }) => {
                  const offerId = debitOffering?.id;
                  return (
                    <div
                      key={offerId}
                      className='flex justify-between items-center p-3 w-fit border rounded-xl border-grey-300'
                    >
                      <div className='ml-3'>
                        <p className='font-inter font-medium text-lg text-primary-dark'>
                          Sign up for the Debit Card
                        </p>

                        <p className='sm:w-72 mr-6 mt-2 font-inter font-light text-sm text-primary-dark'>
                          Automatic cashback offers. No monthly fee. No credit
                          check
                        </p>

                        <ZenButton
                          label='Sign up'
                          className='w-24 h-10 mt-4 px-4 border-2 rounded-lg bg-white text-rezen-blue-600 font-inter font-medium text-base'
                          onClick={() => {
                            setOfferId(offerId);
                            AnalyticsService.instance().logEvent(
                              AnalyticsEventEnum.WALLET_SIGN_UP_FOR_DEBIT_CLICKED,
                            );
                          }}
                        />
                      </div>

                      <img src={DebitCard} alt='debit-card' />
                    </div>
                  );
                })}

              {/* Charge Card */}
              {!isApplicationPending &&
                data?.eligibleCreditOfferings?.map(({ creditOffering }) => {
                  const offerId = creditOffering?.id;
                  return (
                    <img
                      key={offerId}
                      src={ChargeCard}
                      alt='charge-card'
                      className='w-fit'
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </ZenResourceContainer>

      {offerId && (
        <WalletAgreementModal
          offerId={offerId}
          onClose={() => setOfferId(undefined)}
        />
      )}

      {formUrl && <WalletRedirectionModal formUrl={formUrl} />}
    </div>
  );
};

export default WalletOfferings;
