import React, { useCallback, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import {
  IFrameEvent,
  IFrameEventEnum,
  OnDocumentAlreadySignedEventData,
  OnDocumentSignedEventData,
  SignatureWidgetModeEnum,
} from '../../types';
import ErrorService from '../../services/ErrorService';
import DefaultLoader from '../DefaultLoader';
import { getWidgetUrl } from '../../utils/SignatureWidgetUtils';
import Logger from '../../utils/Logger';

interface SignatureWidgetProps {
  token: string;
  mode: SignatureWidgetModeEnum;
  onDocumentSigned?: (data: OnDocumentSignedEventData) => void;
  onDocumentAlreadySigned?: (data: OnDocumentAlreadySignedEventData) => void;
  isLoading: boolean;
  showDownload?: boolean;
  showMobileTooltip?: boolean;
  showWebHelperButtons?: boolean;
  browserMode?: boolean;
}

const SignatureWidget: React.FC<SignatureWidgetProps> = ({
  token,
  mode,
  onDocumentSigned,
  onDocumentAlreadySigned,
  isLoading,
  showDownload,
  showMobileTooltip,
  showWebHelperButtons,
  browserMode,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const onIframeEvent = useCallback(
    (e: IFrameEvent) => {
      if (e.type === IFrameEventEnum.DOCUMENT_SIGNED) {
        onDocumentSigned?.(e?.data);
      } else if (e.type === IFrameEventEnum.DOCUMENT_ALREADY_SIGNED) {
        onDocumentAlreadySigned?.(e?.data);
      } else if (e.type === IFrameEventEnum.OPEN_DOCUMENT_IN_BROWSER) {
        const browserUrl = getWidgetUrl(mode, token, {
          showDownload: true,
          showMobileTooltip: isMobile ? true : false,
          showWebHelperButtons: false,
          browserMode: true,
        });
        window.open(browserUrl, '_blank');
      } else {
        const error = new Error(
          'Unrecognized event type in signature widget iframe: ' + e.type,
        );
        ErrorService.notify(error.message, error);
      }
    },
    [onDocumentSigned, onDocumentAlreadySigned, mode, token],
  );

  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      const iframeData = e?.data;
      if (iframeRef?.current && typeof iframeData === 'string') {
        try {
          const iframeEvent: IFrameEvent = JSON.parse(iframeData);
          if (
            iframeEvent.type === IFrameEventEnum.DOCUMENT_SIGNED ||
            iframeEvent.type === IFrameEventEnum.DOCUMENT_ALREADY_SIGNED ||
            iframeEvent.type === IFrameEventEnum.OPEN_DOCUMENT_IN_BROWSER
          ) {
            onIframeEvent(iframeEvent);
          }
        } catch {
          Logger.debug('Could not parse iframe event data', iframeData);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [onIframeEvent]);

  if (isLoading || !token) {
    return <DefaultLoader />;
  }
  const url = getWidgetUrl(mode, token, {
    showDownload,
    showMobileTooltip,
    showWebHelperButtons,
    browserMode,
  });

  return (
    <iframe
      ref={iframeRef}
      title='signature-widget'
      id='signature-widget'
      src={url}
      className='w-full h-full'
    />
  );
};

export default SignatureWidget;
