import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../types';
import ZenAgentDetailRoute from './AgentDetailV2/ZenAgentDetailRoute';
import SuperAdminAgentDetailRoute from './SuperAdminAgentDetailRoute';
import ZenPublicProfileDetailRoute from './ZenPublicProfileDetailRoute';

type Match = {
  id: string;
};

const DecideProfileDetailRoute: React.FC = () => {
  const { id: agentId } = useParams() as Match;
  const { userDetail, isSuperAdmin, isAdmin, isBroker } = useSelector(
    (state: RootState) => state.auth,
  );
  const currentUserProfile = userDetail?.id === agentId;

  if (isSuperAdmin && !currentUserProfile) {
    return <SuperAdminAgentDetailRoute />;
  }

  if (!isAdmin && !isBroker && !currentUserProfile) {
    return <ZenPublicProfileDetailRoute />;
  }

  return <ZenAgentDetailRoute />;
};

export default DecideProfileDetailRoute;
