import { useState } from 'react';
import Logger from '../utils/Logger';
import ErrorService from '../services/ErrorService';
import { delay } from '../utils/TableUtils';

const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async (text?: string | null) => {
    if (!text) {
      setCopied(false);
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);

      // wait for 3 seconds and then reset the copied state
      await delay(3000);
      setCopied(false);
    } catch (err) {
      Logger.error('Failed to copy:', err);
      ErrorService.notify('Failed to copy to clipboard', err);
    }
  };

  return [copied, copyToClipboard] as const;
};

export default useCopyToClipboard;
