import { sum } from 'lodash';
import {
  PaymentParticipantValue,
  TransactionResponse,
} from '../openapi/arrakis';
import { displayAmount } from '../utils/CurrencyUtils';
import CollapsibleTableRow from './CollapsibleTableRow';
import ParticipantCard from './ParticipantCard';
import SignedNumber from './SignedNumber';
import LedgerItemRow from './LedgerItemRow';
import { PaidAtLabel } from './PaidAtLabel';

export interface PaymentParticipantCardProps {
  transaction?: TransactionResponse;
  participant: PaymentParticipantValue;
  isAdmin: boolean;
  onEdit(): void;
  onDelete(): void;
  isReadonly?: boolean;
  transactionOwner: boolean;
  isSingleCheck: boolean;
  defaultCollapsed?: boolean;
  hideView?: boolean;
}

const PaymentParticipantCard: React.FC<PaymentParticipantCardProps> = ({
  transaction,
  participant,
  onEdit,
  onDelete,
  isReadonly,
  transactionOwner,
  isAdmin,
  defaultCollapsed = true,
  hideView,
  // isSingleCheck,
}) => {
  return (
    <div className='w-full'>
      <ParticipantCard
        transaction={transaction}
        participant={participant}
        onDelete={onDelete}
        onEdit={onEdit}
        isReadonly={isReadonly}
        transactionOwner={transactionOwner}
        isAdmin={isAdmin}
        hideView={hideView}
      >
        <table className='w-full divide-y-2 border border-gray-200 border-collapse'>
          <CollapsibleTableRow
            name='Commission Split'
            ValueComponent={
              participant.paid ? (
                <PaidAtLabel paidAt={participant.paidAt!} />
              ) : (
                <p>{participant.payoutSetting?.percentage?.string}</p>
              )
            }
          />
          <CollapsibleTableRow
            name='Total'
            ValueComponent={<p>{displayAmount(participant.commissionTotal)}</p>}
          />

          <CollapsibleTableRow
            name='Real'
            ValueComponent={
              <SignedNumber
                value={{
                  amount: sum(
                    participant?.realItems?.map((item) => item.amount?.amount),
                  ),
                  currency: participant.commissionTotal?.currency!,
                }}
              />
            }
            defaultCollapsed={defaultCollapsed}
          >
            {participant?.realItems?.length !== 0 &&
              participant?.realItems?.map((item) => (
                <LedgerItemRow key={item.id} item={item} />
              ))}
          </CollapsibleTableRow>

          <CollapsibleTableRow
            name='Fees And Rebates'
            ValueComponent={
              <SignedNumber
                value={{
                  amount: sum(
                    participant.feeAndRebateItems?.map(
                      (item) => item.amount?.amount,
                    ),
                  ),
                  currency: participant.commissionTotal?.currency!,
                }}
              />
            }
            defaultCollapsed={defaultCollapsed}
          >
            {participant?.feeAndRebateItems?.length !== 0 &&
              participant?.feeAndRebateItems?.map((item) => (
                <LedgerItemRow key={item.id} item={item} />
              ))}
          </CollapsibleTableRow>

          <CollapsibleTableRow
            name='Net Payout'
            ValueComponent={<SignedNumber value={participant.netPayout!} />}
            // RightComponent={
            //   isSingleCheck ? (
            //     <CollapsibleTableRowRightComponent
            //       text='SINGLE CHECK'
            //       icon={<InfoIcon style={{ fontSize: 14 }} />}
            //       toolTipText='Will be paid by Real'
            //     />
            //   ) : (
            //     <CollapsibleTableRowRightComponent
            //       text='SPLIT CHECK'
            //       icon={<InfoIcon style={{ fontSize: 14 }} />}
            //       toolTipText='Will be paid in split check'
            //     />
            //   )
            // }
          />
        </table>
      </ParticipantCard>
    </div>
  );
};

export default PaymentParticipantCard;
