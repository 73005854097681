import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  FlexTeamControllerApi,
  FlexTeamDto,
  TeamResponseStatusEnum,
  TeamResponseTypeEnum,
} from '../../../../openapi/yenta';
import {
  LeaderDto,
  TeammateDto,
} from '../../../../routes/ZenTeamsProDetailsRoute';
import ErrorService from '../../../../services/ErrorService';
import { showApiErrorModal } from '../../../../slices/ErrorSlice';
import { getFlexTeamDetailOverview } from '../../../../slices/TeamSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../slices/ToastNotificationSlice';
import { AppDispatch, ISelectOption } from '../../../../types';
import { getYentaConfiguration } from '../../../../utils/OpenapiConfigurationUtils';
import { teamSearchRequest } from '../../../../utils/TableUtils';
import ZenControlledAsyncSelectInput from '../../Input/ZenControlledAsyncSelectInput';
import ZenControlledSelectInput from '../../Input/ZenControlledSelectInput';
import ZenSidebarModalActionFooterV2 from '../../Modal/ZenSidebarModalActionFooterV2';
import ZenSidebarModal from '../../ZenSidebarModal';

interface ZenTransferProTeamAgentProps {
  member: TeammateDto | LeaderDto;
  flexTeam: FlexTeamDto;
  onClose(): void;
}

interface FormData {
  agentId: ISelectOption;
  fromTeamId: ISelectOption;
  toTeamIdAndType: ISelectOption;
}

const ZenTransferProTeamAgent: React.FC<ZenTransferProTeamAgentProps> = ({
  member,
  flexTeam,
  onClose,
}) => {
  const flexTeamId = flexTeam?.id!;
  const fullName = `${member?.agent.firstName} ${member?.agent.lastName}`;

  const dispatch = useDispatch<AppDispatch>();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      agentId: { label: fullName, value: member.agent.id },
      fromTeamId: { label: flexTeam?.name!, value: flexTeamId },
    },
  });

  const onSubmit = async (values: FormData) => {
    const request = {
      memberId: member.memberId!,
      fromTeam: flexTeamId,
      toTeam: values.toTeamIdAndType.value,
    };

    try {
      await new FlexTeamControllerApi(
        getYentaConfiguration(),
      ).transferFlexTeamMember(request);
      dispatch(
        showSuccessToast('Agent successfully transferred to another team.'),
      );
      dispatch(getFlexTeamDetailOverview(flexTeamId));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error transferring pro team agent', e, {
        request,
      });
      dispatch(
        showErrorToast(
          'We had a problem transferring pro team agent.',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      onClose();
    }
  };

  const fetchProTeamsList = async (
    search: string,
    page: number | undefined = 0,
  ) => {
    const requestQuery = {
      page,
      pageSize: 10,
      search,
      filter: {
        type: { values: [TeamResponseTypeEnum.Pro] },
        status: { value: TeamResponseStatusEnum.Active },
      },
    };

    let options: ISelectOption[] = [];

    try {
      const { data } = await teamSearchRequest(requestQuery);

      options = (data || [])
        .filter(({ id }) => id !== flexTeamId)
        .map((team) => ({
          label: team.name!,
          value: team.id!,
        }));
    } catch (e) {
      ErrorService.notify('Unable to fetch Teams at this moment.', e, {
        search: { term: search, page },
      });
    }

    return options;
  };

  return (
    <ZenSidebarModal
      isOpen
      onClose={onClose}
      title={`Transfer Agent: ${fullName}`}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='transfer-team-agent-form'
      >
        <div className='p-5 pb-24'>
          <div className='mt-3'>
            <ZenControlledSelectInput<FormData, 'agentId'>
              control={control}
              options={[]}
              label='Agent Name'
              name='agentId'
              readOnly
              isRequired
            />
          </div>
          <div className='mt-3'>
            <ZenControlledSelectInput<FormData, 'fromTeamId'>
              control={control}
              options={[]}
              label='From Team Name'
              name='fromTeamId'
              readOnly
              isRequired
            />
          </div>
          <div className='mt-3'>
            <ZenControlledAsyncSelectInput<FormData, 'toTeamIdAndType'>
              name='toTeamIdAndType'
              control={control}
              label='To Team Name'
              placeholder='Select Team Name'
              fetchData={fetchProTeamsList}
              rules={{
                required: 'Please select a team',
              }}
              isRequired
            />
          </div>
        </div>
        <ZenSidebarModalActionFooterV2
          onClose={onClose}
          isSubmitting={isSubmitting}
          submitButtonText='Save'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenTransferProTeamAgent;
