import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCircleExclamation,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { taxStatusToVariant } from '../../utils/AgentHelper';
import { EnumMap } from '../../types';
import { setTaxAlertData } from '../../slices/TaxSlice';
import { cn } from '../../utils/classUtils';

export interface TaxAlert {
  taxFormState: string;
  title: string;
  description: string;
}

const TaxFormAlert: React.FC<TaxAlert> = ({
  taxFormState,
  description,
  title,
}) => {
  const [dismiss, setDismiss] = useState(false);
  const taxStatusToIcon: EnumMap<string, React.ReactElement> = {
    SUCCESS: (
      <FontAwesomeIcon icon={faCheckCircle} className='text-success text-lg' />
    ),
    WARNING: (
      <FontAwesomeIcon
        icon={faCircleExclamation}
        className='text-warning text-lg'
      />
    ),
    DANGER: <FontAwesomeIcon icon={faXmark} className='text-lg' />,
  };

  const pillVariantTypeToClassNameMap: {
    [type in string]: string;
  } = {
    success:
      'bg-success bg-opacity-20 text-green-700 border-green-500 border rounded-sm',
    warning:
      'bg-warning bg-opacity-20 text-amber-700 border-amber-500 border rounded-sm',
    danger: 'bg-error bg-opacity-20 text-error',
  };
  const dispatch = useDispatch();
  return (
    <div
      id='transaction-status'
      className={cn('bg-white rounded', dismiss ? 'hidden' : 'block')}
    >
      <div
        className={cn(
          pillVariantTypeToClassNameMap[taxStatusToVariant[taxFormState]],
          'p-2',
        )}
      >
        <div className='flex flex-row space-x-2 justify-between'>
          <div className='flex flex-row space-x-2 items-center'>
            {taxStatusToIcon[taxFormState]}
            <p className='font-primary-medium'>{title || ''}</p>
          </div>
          <FontAwesomeIcon
            icon={faXmark}
            className='text-lg'
            onClick={() => {
              setDismiss(true);
              dispatch(setTaxAlertData(null));
            }}
          />
        </div>
        <p className='pl-7 font-primary-light text-base'>{description}</p>
      </div>
    </div>
  );
};

export default TaxFormAlert;
