import React from 'react';
import { IdentityRoleResponse, RoleResponse } from '../../openapi/keymaker';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenToggleRow from '../Zen/Input/ZenToggleRow';

interface ZenGrantingRoleToggleInputProps {
  grantingRoles: RoleResponse[];
  agentActiveRoles: IdentityRoleResponse[];
  onToggle(toggled: boolean, value: string): void;
}

const ZenGrantingRoleToggleInput: React.FC<ZenGrantingRoleToggleInputProps> = ({
  agentActiveRoles,
  grantingRoles,
  onToggle,
}) => {
  return (
    <div>
      {grantingRoles?.map((role) => {
        const checked = agentActiveRoles.some(
          (val: IdentityRoleResponse) => val.role === role.name,
        );
        return (
          <div
            className='flex flex-row items-start justify-between pt-8 pb-6 border-b border-zen-dark-4'
            key={role?.name}
          >
            <div>
              <label
                htmlFor={role?.name}
                className='text-lg font-zen-title font-normal leading-6 text-zen-dark mb-1'
              >
                {capitalizeEnum(role.name!)}
              </label>
              <p className='text-sm font-zen-body font-normal leading-6 text-zen-dark-8'>
                {role.description}
              </p>
            </div>
            <ZenToggleRow
              value={checked}
              onChange={() => onToggle(!checked, role.name!)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ZenGrantingRoleToggleInput;
