import { flatten } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import AuthorizationContainer from '../components/auth/AuthorizationContainer';
import ResourceContainer from '../components/ResourceContainer';
import ZenFixedDataTable from '../components/Zen/ZenFixedDataTable';
import ZenRoute from '../components/Zen/ZenRoute';
import { EquityContributionValue } from '../openapi/arrakis';
import { fetchEquity } from '../slices/AgentSlice';
import { RootState } from '../types';
import { displayAmount } from '../utils/CurrencyUtils';

type Match = {
  id: string;
};

export const columns: Array<Column<EquityContributionValue>> = [
  {
    Header: 'Address',
    accessor: 'address',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => value?.oneLine,
    cardColSize: 7,
  },
  {
    Header: 'Closing Date',
    accessor: 'closingDateActual',
    Cell: ({ value }) => DateTime.fromISO(value!).toFormat('M/d/y'),
    disableSortBy: true,
  },
  {
    Header: 'Contribution Amount',
    accessor: 'contributionAmount',
    Cell: ({ value }) => displayAmount(value),
    disableSortBy: true,
  },
  {
    Header: 'Refund Amount',
    accessor: 'refundAmount',
    Cell: ({ value }) => displayAmount(value),
    disableSortBy: true,
  },
  {
    Header: 'Match Amount',
    accessor: 'matchAmount',
    Cell: ({ value }) => displayAmount(value),
    disableSortBy: true,
  },
  {
    Header: 'Transaction Id',
    accessor: 'transactionId',
    Cell: ({ value }) => <Link to={`/transactions/${value!}`}>{value}</Link>,
    disableSortBy: true,
  },
];

const ZenAgentDetailEquityRoute: React.FC = () => {
  const { id: agentId } = useParams() as Match;
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    await dispatch(fetchEquity(agentId));
  }, [agentId, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    agentDetail: { equityResponse },
  } = useSelector((state: RootState) => state);
  const allContributions: EquityContributionValue[] =
    flatten(
      equityResponse.data?.monthlyAmounts?.map(
        (monthlyAmount) => monthlyAmount.contributions || [],
      ),
    ) || [];

  return (
    <ZenRoute title='Equity'>
      <div className='p-4'>
        <AuthorizationContainer asyncResponse={equityResponse}>
          <ResourceContainer
            resourceName='Equity'
            loading={equityResponse.loading}
            isEmpty={false}
          >
            <ZenFixedDataTable<EquityContributionValue>
              header='Equity'
              columns={columns}
              resourceName='Equity'
              data={allContributions}
              hidePagination
              hideFilters
            />
          </ResourceContainer>
        </AuthorizationContainer>
      </div>
    </ZenRoute>
  );
};
export default ZenAgentDetailEquityRoute;
