import { cn } from '../../utils/classUtils';
import ZenAvatar from './ZenAvatar';

export type ZenAvatarPillVariantType =
  | 'default'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger';

export interface ZenAvatarPillProps {
  text: string;
  imageUrl: string;
  variant?: ZenAvatarPillVariantType;
}

const ZenAvatarPill: React.FC<ZenAvatarPillProps> = ({
  text,
  imageUrl,
  variant = 'default',
}) => {
  const avatarPillVariantTypeToClassNameMap: {
    [type in ZenAvatarPillVariantType]: string;
  } = {
    default: 'bg-gray-200 text-zen-dark',
    primary: 'bg-primary-blue bg-opacity-20 text-primary-blue',
    success: 'bg-green-600 bg-opacity-20 text-green-600',
    warning: 'bg-zen-warning bg-opacity-20 text-zen-warning',
    danger: 'bg-zen-danger bg-opacity-20 text-zen-danger',
  };

  return (
    <div
      className={cn(
        'inline-block py-1 px-3 rounded-full',
        avatarPillVariantTypeToClassNameMap[variant],
      )}
    >
      <div className='flex flex-row content-center space-x-1 items-center'>
        <ZenAvatar name={text} size='xs' imageUrl={imageUrl} />
        <p className='font-zen-body font-semibold text-sm text-zen-dark-9'>
          {text}
        </p>
      </div>
    </div>
  );
};

export default ZenAvatarPill;
