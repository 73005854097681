import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import RealLogoImg from '../../../assets/img/real_logo_blue.png';
import {
  EscrowDepositResponse,
  TransactionControllerApi,
} from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { fetchTransactionDetails } from '../../../slices/TransactionSlice';
import { fetchUserByIds } from '../../../slices/UserIdsSlice';
import { RootState } from '../../../types';
import { displayFormattedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { isCanadaTransaction } from '../../../utils/TransactionHelper';
import DefaultLoader from '../../DefaultLoader';
import DivContainer from '../../DivContainer';
import CACommissionDepositReceiptFooterNote from './Receipt/CACommissionDepositReceiptFooterNote';
import USCommissionDepositReceiptFooterNote from './Receipt/USCommissionDepositReceiptFooterNote';

interface CommissionDepositReceiptProps {
  transactionId: string;
  escrowDepositId: string;
  isPdf: boolean;
}

const CommissionDepositReceipt: React.FC<CommissionDepositReceiptProps> = ({
  escrowDepositId,
  transactionId,
  isPdf,
}) => {
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(true);
  const [escrowDeposit, setEscrowDeposit] = useState<EscrowDepositResponse>();
  const {
    userIds: { usersById },
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
    },
  } = useSelector((state: RootState) => state);

  const Print = styled.div`
    width: 1024px;
    margin: 0 auto;

    @media print {
      @page {
        size: A4;
        margin: 20pt 0 35pt;
      }
    }
  `;

  const Container = isPdf ? Print : DivContainer;

  const fetchDeposit = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).getEscrowDeposit('escrowId', escrowDepositId);
      await dispatch(fetchUserByIds([data.confirmedBy!]));
      await dispatch(fetchTransactionDetails(transactionId));
      setEscrowDeposit(data);
    } catch (e) {
      dispatch(
        showErrorToast(
          'We had trouble fetch escrow deposit receipt. Please try again in a few moments.',
        ),
      );
      ErrorService.notify('Unable to fetch escrow deposit', e, {
        deposit: { id: escrowDepositId },
      });
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, escrowDepositId, transactionId]);

  useEffect(() => {
    fetchDeposit();
  }, [fetchDeposit]);

  if (loading) {
    return <DefaultLoader />;
  }
  return (
    <Container>
      <div className='flex flex-col justify-between w-full print:text-xs p-20 print:p-20'>
        <div className='flex w-full flex-row justify-between items-start mb-10'>
          <div>
            <img src={RealLogoImg} className='w-auto h-12' alt='Real Logo' />
          </div>
          <div className='flex flex-col items-end font-primary-regular text-base text-black'>
            <p className='max-w-xs text-right'>
              {transactionDetail?.office?.address}
            </p>
            <p className='font-primary-bold'>
              {transactionDetail?.office?.emailAddress}
            </p>
            {transactionDetail?.office?.taxes?.map((tax) => (
              <p key={tax.taxId}>
                {tax.type} # ${tax.taxId}
              </p>
            ))}
          </div>
        </div>
        <div className='flex justify-center items-center font-primary-bold py-20 text-lg text-black'>
          COMMISSION DEPOSIT RECEIPT
        </div>
        <div className='flex my-5 text-lg'>
          <div className='grid grid-cols-2 gap-x-1 gap-y-2 text-black'>
            <div className='font-primary-bold mb-5'>DATE RECEIVED:</div>
            <div>{escrowDeposit?.dateReceived}</div>
            <div className='font-primary-bold'>RECEIVED FROM:</div>
            <div>{escrowDeposit?.depositorName}</div>
            <div className='font-primary-bold'>COMMISSION DEPOSIT ON:</div>
            <div>{transactionDetail?.address?.oneLine}</div>
          </div>
        </div>
        <div className='flex my-5 text-lg'>
          <div className='grid grid-cols-2 gap-x-10 gap-y-2 text-black'>
            <div className='font-primary-bold'>DEPOSIT AMOUNT RECEIVED:</div>
            <div>
              {displayFormattedAmountWithCurrency(escrowDeposit?.amount)}
            </div>
            <div className='font-primary-bold'>PAYMENT TYPE:</div>
            <div>{escrowDeposit?.depositMethod}</div>
            <div className='font-primary-bold'>DEPOSIT RECEIVED IN: </div>
            <div>
              {isCanadaTransaction(transactionDetail) ? 'CDN funds' : 'USD'}
            </div>
          </div>
        </div>
        <div className='flex my-6 text-lg'>
          <div className='grid grid-cols-2 gap-2 text-black'>
            <div className='font-primary-bold'>DEPOSIT CONFIRMED BY:</div>
            <div>{`${usersById[escrowDeposit?.confirmedBy!]?.firstName} ${
              usersById[escrowDeposit?.confirmedBy!]?.lastName
            }`}</div>
          </div>
        </div>

        {isCanadaTransaction(transactionDetail) ? (
          <CACommissionDepositReceiptFooterNote />
        ) : (
          <USCommissionDepositReceiptFooterNote />
        )}
      </div>
    </Container>
  );
};

export default CommissionDepositReceipt;
