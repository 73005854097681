import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RealTitleCornerStones } from '../../constants/JointVentureConstants';
import { capitalizeEnum } from '../../utils/StringUtils';
import TierMembershipCard, { ShadeVariant } from './TierMembershipCard';

interface LearnMoreSidebarProps {
  tierName?: string;
  jointVentureName?: string;
}

const LearnMoreSidebar: React.FC<LearnMoreSidebarProps> = ({
  tierName,
  jointVentureName,
}) => {
  const tierType = tierName ? (tierName.toLowerCase() as ShadeVariant) : 'gold';
  return (
    <div className='bg-zen-dark-13 flex flex-col flex-grow p-12'>
      <div className='py-8 mb-0.5 w-full px-12'>
        <p className='text-white text-[32px] text-center'>
          Participate in the Growth of Real Title!
        </p>
      </div>
      <div className='h-56 mx-auto w-full'>
        <TierMembershipCard
          tierType={tierType}
          label={`${capitalizeEnum(tierType)} Membership`}
          subLabel={jointVentureName}
          wrap
        />
      </div>
      <div className='w-full mt-4.5'>
        {RealTitleCornerStones.map((item) => (
          <div key={item} className='mt-4 flex items-start'>
            <FontAwesomeIcon
              icon={faCheck}
              size='1x'
              className='text-green-600 mt-0.5'
            />
            <p className='text-white text-lg leading-6 ml-3.5'>{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LearnMoreSidebar;
