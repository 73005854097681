import React from 'react';
import ConfirmationModal, { ConfirmationModalProps } from './ConfirmationModal';
import Button from './Button';

interface SimpleConfirmationModalProps extends ConfirmationModalProps {
  onConfirm(): void;
  isSubmitting: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

const SimpleConfirmationModal: React.FC<SimpleConfirmationModalProps> = ({
  variant,
  title,
  isOpen,
  subtitle,
  onClose,
  hideIcon = false,
  size = 'default',
  onConfirm,
  isSubmitting,
  customIcon,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
}) => {
  return (
    <ConfirmationModal
      variant={variant}
      title={title}
      isOpen={isOpen}
      subtitle={subtitle}
      onClose={onClose}
      hideIcon={hideIcon}
      size={size}
      customIcon={customIcon}
    >
      <div className='flex flex-row mt-3 space-x-3'>
        <Button
          fullWidth
          label={cancelButtonText}
          type='secondary'
          onClick={onClose}
        />
        <Button
          fullWidth
          label={confirmButtonText}
          type='primary'
          onClick={onConfirm}
          isSubmitting={isSubmitting}
        />
      </div>
    </ConfirmationModal>
  );
};

export default SimpleConfirmationModal;
