import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CellProps, Filters } from 'react-table';
import GeminiResourceTableWithNestedRouterTabs, {
  CustomColumn,
} from '../components/Gemini/Containers/GeminiResourceTableWithNestedRouterTabs';
import GeminiTransactionTypeCell from '../components/Gemini/Table/Cell/GeminiTransactionTypeCell';
import GeminiTransactionStatus from '../components/Gemini/Transaction/GeminiTransactionStatus';
import { nonAgentTransactionOrListingListColumns } from '../components/Gemini/Transaction/TransactionOrListingListUtils';
import { TabsConfig } from '../components/NestedTabsWithRouter';
import TransactionStatusSelectColumnFilter from '../components/table/Filters/TransactionStatusSelectColumnFilter';
import TransactionTypeSelectColumnFilter from '../components/table/Filters/TransactionTypeSelectColumnFilter';
import TransactionsOrListingsRouteHeader from '../components/transactions/TransactionsOrListingsRouteHeader';
import { TableQueryParamState } from '../components/Zen/Containers/ZenResourceIndexContainer';
import useQueryParams from '../hooks/useQueryParams';
import { TransactionResponse } from '../openapi/arrakis';
import { useFetchChecklistProgressByIds } from '../query/transaction/useChecklist';
import {
  useFetchNonAgentTransactions,
  useFetchNonAgentTransactionsCount,
} from '../query/transaction/useTransaction';
import { useFetchAgentsInfoByIds } from '../query/transaction/useUser';
import { saveTransactionPaginatedResultsByIdWithFilters } from '../slices/TransactionSlice';
import { TransactionsMainTabType, TransactionsSubTabType } from '../types';
import { capitalizeEnum } from '../utils/StringUtils';
import {
  convertQueryFilters,
  getNonAgentTransactionsAPIParams,
  getPaginatedResults,
  getTransactionStatusByLifecycleGroupEnum,
  transactionLifecycleGroupEnumMap,
  transactionTypesByPathnameEnumMap,
} from '../utils/TransactionUtils';

interface GeminiAdminOrBrokerTransactionOrListingTableProps {
  type: TransactionsMainTabType;
  lifecycle: TransactionsSubTabType;
  isListing?: boolean;
  officeIds?: string[];
}

const GeminiAdminOrBrokerTransactionOrListingTable: React.FC<GeminiAdminOrBrokerTransactionOrListingTableProps> = ({
  isListing = false,
  lifecycle,
  type,
  officeIds = [],
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams<TableQueryParamState<TransactionResponse>>();

  const {
    data: count,
    isLoading: isLoadingCount,
  } = useFetchNonAgentTransactionsCount({
    fnArgs: getNonAgentTransactionsAPIParams(
      query,
      type,
      lifecycle,
      officeIds,
      isListing,
    ),
    activeTab: lifecycle,
    options: { enabled: !!type && !!lifecycle },
  });

  const tabsConfig = useMemo<TabsConfig<TransactionResponse>>(() => {
    const baseTabs = [
      { key: 'all', title: 'All' },
      { key: 'sale', title: 'Sale' },
      { key: 'lease', title: 'Lease' },
      { key: 'referrals', title: 'Referrals' },
    ].filter((tab) => !isListing || tab.key !== 'referrals');

    return {
      MAIN_TABS: { queryKey: 'type', tabs: baseTabs },
      SUB_TABS: {
        queryKey: 'lifecycle',
        tabs: [
          { key: 'active', title: `Active (${count?.OPEN ?? 0})` },
          { key: 'closed', title: `Closed (${count?.CLOSED ?? 0})` },
          {
            key: 'terminated',
            title: `Terminated (${count?.TERMINATED ?? 0})`,
          },
        ],
      },
    };
  }, [count?.CLOSED, count?.OPEN, count?.TERMINATED, isListing]);

  const { data, isLoading, error } = useFetchNonAgentTransactions({
    fnArgs: getNonAgentTransactionsAPIParams(
      query,
      type,
      lifecycle,
      officeIds,
      isListing,
    ),
    options: { enabled: !!type && !!lifecycle },
  });

  /**
   * Navigate to transaction detail page
   *
   * @param path - Path to navigate
   * We are saving the results for showing pagination in detail page.
   */
  const handleNavigate = useCallback(
    (path: string) => {
      history.push(path);
      if (!isListing) {
        const queryLifecycleStateFilter = query?.filter?.find(
          (f) => f?.id === 'lifecycleState',
        );
        const transactionTypeFilter: Filters<TransactionResponse> = [
          {
            id: 'transactionType',
            value: {
              values: transactionTypesByPathnameEnumMap[type],
              column: 'transactionType',
              operator: 'IN',
            },
          },
        ];

        dispatch(
          saveTransactionPaginatedResultsByIdWithFilters({
            hasNext: data?.hasNext,
            totalCount: data?.totalCount,
            results: getPaginatedResults(
              data?.pageNumber ?? 0,
              data?.pageSize ?? 20,
              data?.results || [],
            ),
            filters: convertQueryFilters({
              ...query,
              filter: [
                ...(query?.filter || []),
                ...transactionTypeFilter,
                ...(queryLifecycleStateFilter
                  ? []
                  : [
                      {
                        id: 'lifecycleState',
                        value: {
                          values: getTransactionStatusByLifecycleGroupEnum(
                            transactionLifecycleGroupEnumMap[lifecycle]!,
                          ),
                          column: 'lifecycleState',
                          operator: 'IN',
                        },
                      },
                    ]),
              ],
            }),
            officeIds,
          }),
        );
      }
    },
    [data, dispatch, history, isListing, lifecycle, officeIds, query, type],
  );

  /**
   * Memoized columns for table modification based on transaction type and lifecycle
   *
   * - we show transactionType sortBy option only if main tab type is all
   * - we show lifecycleState sortBy option only if sub tab type is active
   * - we show status filter option only if sub tab type is active
   */
  const modifyColumns = useMemo(() => {
    const isLifecycleActiveOrClosed =
      lifecycle === 'active' || lifecycle === 'closed';

    const columns: CustomColumn<TransactionResponse>[] = [
      ...nonAgentTransactionOrListingListColumns(isListing, handleNavigate),
    ];

    columns.splice(1, 0, {
      Header: 'Type',
      accessor: 'transactionType',
      Cell: ({ value }) => (
        <GeminiTransactionTypeCell dealType={value!} noBackground />
      ),
      position: 'top-left',
      Filter: TransactionTypeSelectColumnFilter,
      disableFilters: true,
      disableSortBy: type !== 'all',
    });

    columns.splice(4, 0, {
      Header: 'Status',
      id: 'lifecycleState',
      accessor: ({ lifecycleState }) => lifecycleState?.state,
      Cell: ({
        value,
        row: { original },
      }: React.PropsWithChildren<CellProps<TransactionResponse>>) => (
        <GeminiTransactionStatus status={value} country={original.country!} />
      ),
      position: 'top-left',
      Filter: isLifecycleActiveOrClosed
        ? (props) => (
            <TransactionStatusSelectColumnFilter
              {...props}
              options={getTransactionStatusByLifecycleGroupEnum(
                lifecycle === 'active' ? 'OPEN' : 'CLOSED',
              ).map((v) => ({ label: capitalizeEnum(v), value: v }))}
            />
          )
        : undefined,
      disableFilters: !isLifecycleActiveOrClosed,
      disableSortBy: !isLifecycleActiveOrClosed,
    });

    return columns;
  }, [handleNavigate, isListing, lifecycle, type]);

  const checklistIds = useMemo(
    () =>
      (data?.results || []).flatMap(
        ({ checklistId, journeyId }) =>
          [checklistId, journeyId].filter(Boolean) as string[],
      ),
    [data?.results],
  );

  /**
   * Memoized all agent ids from api response.
   *
   * @returns {string[]} - Array of agent yentaUserIds without duplicates
   */
  const allAgentIds = useMemo(() => {
    const agentIds = new Set(
      (data?.results || []).flatMap((t) =>
        (t?.participants || []).map((p) => p?.yentaUserId!).filter(Boolean),
      ),
    );
    return Array.from(agentIds);
  }, [data?.results]);

  /**
   * Fetching checklist progress for all checklist ids
   *
   * @returns - Array of checklist progress objects and saving in the store via saveChecklistProgressById
   */
  useFetchChecklistProgressByIds(checklistIds, !!checklistIds.length);

  /**
   * Fetching agent info for all agent ids
   *
   * @returns - Array of agent info objects and saving in the store via saveAgentById
   */
  useFetchAgentsInfoByIds(allAgentIds);

  return (
    <GeminiResourceTableWithNestedRouterTabs<TransactionResponse>
      routerTabs={{
        basePath: isListing ? '/listings' : '/transactions',
        tabsConfig,
        clearQueryParamsOnTabChange: true,
      }}
      columns={modifyColumns}
      header={isListing ? 'Listings' : 'Transactions'}
      RightComponent={
        <TransactionsOrListingsRouteHeader isListing={isListing} />
      }
      data={data?.results || []}
      initialSort={
        isListing
          ? { listingExpirationDate: 'asc' }
          : { skySlopeEscrowClosingDate: 'asc' }
      }
      search={query?.search}
      isLoading={isLoading || isLoadingCount}
      resourceName={isListing ? 'listing' : 'transaction'}
      total={data?.totalCount || 0}
      pageSize={20}
      resourceContainerDisplayVariant='CARDS'
      error={error}
      isDisplayVariantCardExpandable={!isListing}
      showExpandAllButton={!isListing}
      showTotalCountInPagination
    />
  );
};

export default GeminiAdminOrBrokerTransactionOrListingTable;
