import { cn } from '../../utils/classUtils';

export type Direction = 'lr' | 'rl';

interface ZenOutlineActionButtonProps {
  text?: string;
  onClick(): void;
  icon?: React.ReactElement;
  a11yLabel?: string;
  direction?: Direction;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const ZenOutlineActionButton: React.FC<ZenOutlineActionButtonProps> = ({
  text,
  onClick,
  icon,
  a11yLabel,
  direction = 'lr',
  type,
}) => {
  return (
    <button
      type={type}
      title={a11yLabel}
      onClick={onClick}
      className={cn(
        'flex rounded items-center focus:outline-none ml-2 font-zen-body font-semibold',
        direction === 'lr' ? 'flex-row' : 'flex-row-reverse',
      )}
    >
      {!!icon && (
        <span
          className={cn('text-primary-blue', direction === 'lr' ? '' : 'pl-1')}
        >
          {icon}
        </span>
      )}
      {!!text && <span className='text-primary-blue text-sm'>{text}</span>}
    </button>
  );
};

export default ZenOutlineActionButton;
