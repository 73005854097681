import React, { useRef } from 'react';
import { cn } from '../utils/classUtils';

interface IFrameVideoplayerProps {
  play: boolean;
  setPlay: (play: boolean) => void;
  videoUrl: string;
}

const IFrameVideoplayer: React.FC<IFrameVideoplayerProps> = ({
  play,
  setPlay,
  videoUrl,
}) => {
  const ref = useRef<HTMLIFrameElement>(null);

  const getVideoUrl = () => {
    const isMp4 = videoUrl.endsWith('.mp4');
    if (isMp4) {
      return play ? videoUrl : '';
    }
    return videoUrl;
  };

  return (
    <div
      className={cn(
        'inset-0 bg-black bg-opacity-75 z-50 transition',
        play ? 'fixed' : 'hidden',
      )}
      onClick={() => {
        setPlay(false);
        if (ref.current) {
          // stop video from playing in background
          ref.current.src = '';
          ref.current.src = videoUrl;
        }
      }}
      data-testid='video-iframe-container'
    >
      <div className='w-full h-full flex items-center justify-center'>
        <iframe
          ref={ref}
          title='OneReal Mortgage'
          src={getVideoUrl()}
          allow='autoplay'
          className='w-5/6 h-5/6 rounded'
          data-testid='video-iframe'
        />
      </div>
    </div>
  );
};

export default IFrameVideoplayer;
