import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import ZenSidebarModalActionFooter from '../components/SidebarModal/ZenSideBarModalActionFooter';
import ZenControlledDatePickerInput from '../components/Zen/Input/ZenControlledDatePickerInput';
import ZenControlledFormattedMoneyInput from '../components/Zen/Input/ZenControlledFormattedMoneyInput';
import ZenSidebarModal from '../components/Zen/ZenSidebarModal';
import {
  MoneyValue,
  MoneyValueCurrencyEnum,
  ShareworksControllerApi,
  TransactionResponseCountryEnum,
} from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { AppDispatch, EnumMap } from '../types';
import { defaultMoneyValue } from '../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { MONEY_AMOUNT_REGEX } from '../utils/StringUtils';

interface FormData {
  unitedStatesStrikePrice: MoneyValue;
  canadaStrikePrice: MoneyValue;
  endDate: string;
}

interface ZenProcessShareworksSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

const ZenProcessShareworksSidebarModal: React.FC<ZenProcessShareworksSidebarModalProps> = ({
  onClose,
  isOpen,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const defaultValues = useMemo(
    () => ({
      unitedStatesStrikePrice: defaultMoneyValue(MoneyValueCurrencyEnum.Usd),
      canadaStrikePrice: defaultMoneyValue(MoneyValueCurrencyEnum.Cad),
    }),
    [],
  );

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [isOpen, reset, defaultValues]);

  const onSubmit = async (formValues: FormData) => {
    const strikePriceMap: EnumMap<
      TransactionResponseCountryEnum,
      MoneyValue
    > = {
      CANADA: formValues.canadaStrikePrice,
      UNITED_STATES: formValues.unitedStatesStrikePrice,
    };

    try {
      await new ShareworksControllerApi(
        getArrakisConfiguration(),
      ).createShareWorkCsvFilesForUpload({
        endDate: formValues.endDate,
        strikePrices: strikePriceMap,
      });
      dispatch(showSuccessToast('Successfully processed Shareworks.'));
      onClose();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to process shareworks', e);
      dispatch(
        showErrorToastForErrorCode(
          'We encountered an error while trying to process Shareworks. Please try again in a few moments.',
          ErrorService.getErrorCode(e),
        ),
      );
    }
  };

  return (
    <ZenSidebarModal
      title='Process Shareworks'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4'>
          <div>
            <ZenControlledFormattedMoneyInput<
              FormData,
              'unitedStatesStrikePrice'
            >
              control={control}
              name='unitedStatesStrikePrice'
              label='Strike Price (United States)'
              rules={{
                validate: (v: MoneyValue) => {
                  if (!v?.amount) {
                    return 'Required';
                  }

                  if (
                    !!v?.amount &&
                    !MONEY_AMOUNT_REGEX.test(v?.amount?.toString())
                  ) {
                    return 'Please enter valid price';
                  }

                  return undefined;
                },
              }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledFormattedMoneyInput<FormData, 'canadaStrikePrice'>
              control={control}
              name='canadaStrikePrice'
              label='Strike Price (Canada)'
              rules={{
                validate: (v: MoneyValue) => {
                  if (!v?.amount) {
                    return 'Required';
                  }

                  if (
                    !!v?.amount &&
                    !MONEY_AMOUNT_REGEX.test(v?.amount?.toString())
                  ) {
                    return 'Please enter valid price';
                  }

                  return undefined;
                },
              }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledDatePickerInput
              control={control}
              name='endDate'
              label='End Date'
              rules={{ required: 'Required' }}
              isRequired
            />
          </div>
        </div>

        <ZenSidebarModalActionFooter
          onClose={onClose}
          isSubmitting={isSubmitting}
          submitButtonDisabled={isSubmitting}
          submitButtonText='Process'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenProcessShareworksSidebarModal;
