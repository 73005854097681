import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { ReactComponent as CalendarDateImg } from '../assets/icons/calendar-date.svg';
import { ReactComponent as DealTerminatedImg } from '../assets/icons/deal-terminated.svg';
import { StepByStepComponent } from '../components/StepByStep/StepByStepContainer';
import HasTransactionClosedModalHeader from '../components/transactions/HasTransactionClosedModalHeader';
import ZenControlledDatePickerInput from '../components/Zen/Input/ZenControlledDatePickerInput';
import ZenControlledImageRadioInput from '../components/Zen/Input/ZenControlledImageRadioInput';
import ZenButton from '../components/Zen/ZenButton';
import ZenLink from '../components/Zen/ZenLink';
import { toggleClosedModal } from '../slices/TransactionSlice';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import {
  TransactionClosedFormData,
  TransactionClosedStepName,
  TransactionNotClosedOptionsEnum,
} from './HasTransactionClosedModal';

const HasTransactionClosedNoStep: StepByStepComponent<
  TransactionClosedFormData,
  TransactionClosedStepName
> = ({
  form: {
    control,
    watch,
    formState: { isSubmitting },
  },
  onSubmit,
  navigateTo,
}) => {
  const dispatch = useDispatch();
  const [transactionNotClosedReason, reason, estimatedClosingDate] = watch([
    'transactionNotClosedReason',
    'reason',
    'estimatedClosingDate',
  ]);
  const isNewClosingDate =
    transactionNotClosedReason ===
    TransactionNotClosedOptionsEnum.NEW_CLOSING_DATE;
  const isTransactionTerminated =
    transactionNotClosedReason ===
    TransactionNotClosedOptionsEnum.REQUEST_TERMINATION;

  return (
    <div className='w-full rounded-lg z-10 md:w-full max-w-[745px] shadow-sm shadow-gray-300 bg-white relative'>
      <HasTransactionClosedModalHeader
        title='Transaction Update'
        onClose={() => dispatch(toggleClosedModal(false))}
      />
      <div className='w-full px-6 md:px-10 lg:px-[157px] py-6 font-zen-body text-black'>
        <div className='w-full flex flex-col items-center justify-center'>
          <div className='w-full'>
            <p className='text-center text-xl zen-font-body font-semibold mt-3'>
              What is the current status of this transaction?
            </p>
            <div className='w-full mt-6 flex flex-row justify-center items-center'>
              <ZenControlledImageRadioInput<
                TransactionClosedFormData,
                'transactionNotClosedReason'
              >
                control={control}
                name='transactionNotClosedReason'
                rules={{ required: 'Required' }}
                options={[
                  {
                    img: <CalendarDateImg />,
                    label: 'Deal has new closing date',
                    value: TransactionNotClosedOptionsEnum.NEW_CLOSING_DATE,
                  },
                  {
                    img: <DealTerminatedImg />,
                    label: 'Deal fell through; request termination',
                    value: TransactionNotClosedOptionsEnum.REQUEST_TERMINATION,
                  },
                ]}
              />
            </div>
            {isNewClosingDate && (
              <div className='mt-6 mb-3'>
                <ZenControlledDatePickerInput<
                  TransactionClosedFormData,
                  'estimatedClosingDate'
                >
                  control={control}
                  label='When will this transaction close?'
                  name='estimatedClosingDate'
                  icon={
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className='text-primary-blue ml-0.5'
                      size='lg'
                    />
                  }
                  placeholder='MM/DD/YYYY'
                  isRequired
                  rules={{ required: 'Required' }}
                />
              </div>
            )}
            {isTransactionTerminated && (
              <div className='mt-6 mb-3'>
                <ZenControlledTextInput<TransactionClosedFormData, 'reason'>
                  name='reason'
                  placeholder='Enter reason'
                  control={control}
                  label='Reason'
                  isRequired
                  rules={{
                    required: 'Required',
                  }}
                />
              </div>
            )}
            <div
              className={classNames(
                'w-full my-8 text-sm flex row items-center justify-center space-x-6',
              )}
            >
              <div className='whitespace-nowrap'>
                <ZenLink
                  label='Go Back'
                  size='small'
                  onClick={() =>
                    navigateTo(TransactionClosedStepName.HAS_TRANSACTION_CLOSED)
                  }
                />
              </div>
              <ZenButton
                label={
                  isTransactionTerminated ? 'Request Termination' : 'Complete'
                }
                variant={isTransactionTerminated ? 'danger' : 'primary'}
                onClick={onSubmit}
                isFullWidth
                isDisabled={
                  isSubmitting ||
                  !transactionNotClosedReason ||
                  (isTransactionTerminated && !reason) ||
                  (isNewClosingDate && !estimatedClosingDate)
                }
                isSubmitting={isSubmitting}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HasTransactionClosedNoStep;
