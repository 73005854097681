import { faChartPie } from '@fortawesome/pro-light-svg-icons';
import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons';
import { faLock, faSackDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import { getLockedEarnings } from '../../../utils/WealthPlanUtils';
import ResourceContainer from '../../ResourceContainer';
import TotalEarningsPieChart from './EarningPieChart/TotalEarningsPieChart';

interface GoalOverviewCardProps {
  isReferral?: boolean;
}

export const GoalOverviewCard: React.FC<GoalOverviewCardProps> = ({
  isReferral = false,
}) => {
  const {
    earningsOverviewResponse: {
      loading: earningsOverviewLoading,
      data: earningsOverviewData,
    },
    wealthGoalsResponse: { data: wealthGoalsResponse },
  } = useSelector((state: RootState) => ({
    ...state.wealthManagement,
  }));

  const projectedEarnings =
    earningsOverviewData?.projectedEarningsValue.amount ?? 0;
  const goal =
    earningsOverviewData?.goalValue.amount! > 0
      ? earningsOverviewData?.goalValue.amount!
      : 1;

  const { totalLockedEarnings } = getLockedEarnings(
    wealthGoalsResponse?.goalDetails,
  );

  return (
    <div className='h-full w-full bg-white rounded-xl shadow-zen-card'>
      <ResourceContainer
        loading={earningsOverviewLoading}
        isEmpty={false}
        resourceName='Goal Overview Card'
      >
        <div className='flex items-center p-4'>
          <FontAwesomeIcon
            icon={faChartPie}
            className='text-zen-dark-13 text-[20px] font-[300] mr-3'
          />
          <p className='text-zen-dark-13 text-lg font-semibold'>
            Earnings Graph
          </p>
        </div>
        <div className='mt-2'>
          <TotalEarningsPieChart />
        </div>
        {!isReferral && (
          <div className='flex items-center justify-between gap-2 max-w-lg m-auto md:px-8 px-4 py-10'>
            <div className='flex flex-col items-center justify-between'>
              <FontAwesomeIcon
                icon={faBullseyeArrow}
                className='mb-2'
                size='lg'
                color='#61BFED'
              />
              <p className='font-bold text-lg'>
                {parseFloat(((projectedEarnings / goal) * 100).toFixed(2))}%
              </p>
              <p className='text-gray-600 text-sm'>Goal Complete</p>
            </div>
            <div className='w-[1px] h-12 bg-slate-300' />
            <div className='flex flex-col items-center justify-between'>
              <FontAwesomeIcon
                icon={faLock}
                className='mb-2'
                size='lg'
                color='#61BFED'
              />
              <p className='font-bold text-lg'>{totalLockedEarnings}</p>
              <p className='text-gray-600 text-sm'>Missed Opportunities</p>
            </div>
            <div className='w-[1px] h-12 bg-slate-300' />
            <div className='flex flex-col items-center justify-between'>
              <FontAwesomeIcon
                icon={faSackDollar}
                className='mb-2'
                size='lg'
                color='#61BFED'
              />
              <p className='font-bold text-lg'>{8 - totalLockedEarnings}</p>
              <p className='text-gray-600 text-sm'>Ways Earned</p>
            </div>
          </div>
        )}
      </ResourceContainer>
    </div>
  );
};
