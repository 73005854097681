import { EarningTitle, EarningType } from '../WealthManagementTypes';

interface LandingPageHeaderProps {
  type: EarningType;
}

const LandingPageHeader = ({ type }: LandingPageHeaderProps) => {
  return (
    <div className='inline-flex h-9 justify-center items-center gap-2.5 shrink-0 border shadow-[3px_6px_0px_0px_#000] px-2.5 py-0 rounded-lg border-solid border-black bg-white'>
      <div className='text-neutral-800 text-xl md:text-2xl font-bold font-zen-body leading-[120%]'>
        {EarningTitle[type]}
      </div>
    </div>
  );
};

export default LandingPageHeader;
