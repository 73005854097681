import { FilterProps } from 'react-table';
import { keys } from 'lodash';
import { DateTime } from 'luxon';
import {
  DateFilterType,
  DateFilterTypeLabel,
  FilterColumnsToProcess,
  SearchParamOperatorEnum,
} from '../../../types';
import { PROCESS_FILTER_COLUMN } from '../../../utils/TableUtils';
import { NUMBER_REGEX } from '../../../utils/StringUtils';
import ControlledSelectInputV7 from '../../ControlledSelectInputV7';
import ControlledTextInputV7 from '../../ControlledTextInputV7';
import ControlledDatePickerInputV7 from '../../ControlledDatePickerInputV7';

const dateOptions = keys(DateFilterType) as DateFilterType[];

export interface DatePickerColumnFilterProps<D extends object>
  extends FilterProps<D> {}

const DateColumnFilter = <D extends object>({
  column,
  watch,
  control,
}: DatePickerColumnFilterProps<D>): React.ReactElement => {
  const prefixFieldName = `${PROCESS_FILTER_COLUMN}.${FilterColumnsToProcess.DATE}.${column.id}`;

  const getType = () => {
    if (!!column.filterValue) {
      if (column.filterValue.length === 1) {
        const op = column.filterValue[0].operator;
        switch (op) {
          case SearchParamOperatorEnum.Eq:
            return DateFilterType.equals;
          case SearchParamOperatorEnum.Gt:
            return DateFilterType.isAfter;
          case SearchParamOperatorEnum.Gte:
            return DateFilterType.isAfterOrOn;
          case SearchParamOperatorEnum.Lt:
            return DateFilterType.isBefore;
          case SearchParamOperatorEnum.Lte:
            return DateFilterType.isBeforeOrOn;
        }
      } else if (column.filterValue.length === 2) {
        return DateFilterType.between;
      }
    }
    return DateFilterType.inTheLast;
  };

  const selectedDateType =
    (watch(`${prefixFieldName}.type`) as DateFilterType) ?? getType();
  const startDate = watch(`${prefixFieldName}.start_date`);
  const endDate = watch(`${prefixFieldName}.end_date`);

  return (
    <div className='space-y-1'>
      <ControlledSelectInputV7
        control={control}
        options={dateOptions.map((dateOption) => ({
          label: DateFilterTypeLabel[dateOption],
          value: dateOption,
        }))}
        shouldUnregister={false}
        name={`${prefixFieldName}.type`}
        rules={{
          required: 'Required',
        }}
        defaultValue={getType()}
      />
      {selectedDateType === DateFilterType.inTheLast && (
        <div className='grid grid-cols-2 gap-1'>
          <ControlledTextInputV7
            control={control}
            type='number'
            name={`${prefixFieldName}.duration`}
            shouldUnregister={false}
            rules={{
              required: 'Required',
              pattern: {
                value: NUMBER_REGEX,
                message: 'Please input valid number',
              },
            }}
            defaultValue=''
          />
          <ControlledSelectInputV7
            control={control}
            shouldUnregister={false}
            options={[
              { label: 'Day', value: 'day' },
              { label: 'Month', value: 'month' },
            ]}
            name={`${prefixFieldName}.duration_type`}
            rules={{
              required: 'Required',
            }}
            defaultValue='day'
          />
        </div>
      )}
      {selectedDateType !== DateFilterType.inTheLast && (
        <div className='flex justify-between space-x-1'>
          <ControlledDatePickerInputV7
            control={control}
            shouldUnregister={false}
            name={`${prefixFieldName}.start_date`}
            rules={{
              required: 'Required',
            }}
            defaultValue={
              column.filterValue?.length > 0
                ? DateTime.fromMillis(
                    parseInt(column.filterValue[0].value, 10),
                  ).toISODate()
                : ''
            }
            datePickerConfig={{
              selectsStart: selectedDateType === DateFilterType.between,
              startDate: startDate
                ? DateTime.fromISO(startDate).toJSDate()
                : undefined,
              maxDate: endDate
                ? DateTime.fromISO(endDate).toJSDate()
                : undefined,
            }}
          />
          {selectedDateType === DateFilterType.between && (
            <ControlledDatePickerInputV7
              control={control}
              shouldUnregister={false}
              name={`${prefixFieldName}.end_date`}
              rules={{
                required: 'Required',
              }}
              defaultValue={
                column.filterValue?.length > 1
                  ? DateTime.fromMillis(
                      parseInt(column.filterValue[1].value, 10),
                    ).toISODate()
                  : ''
              }
              datePickerConfig={{
                selectsEnd: true,
                endDate: endDate
                  ? DateTime.fromISO(endDate).toJSDate()
                  : undefined,
                minDate: startDate
                  ? DateTime.fromISO(startDate).toJSDate()
                  : undefined,
                popperModifiers: {},
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DateColumnFilter;
