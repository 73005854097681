import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AgentControllerApi } from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { RootState } from '../../types';
import { cn } from '../../utils/classUtils';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ZenExternalLinkSideBarItem from '../ZenExternalLinkSideBarItem';

interface TipaltiSidebarMenuItemProps {
  isCollapsed: boolean;
}

const TipaltiSidebarMenuItem: React.FC<TipaltiSidebarMenuItemProps> = ({
  isCollapsed,
}) => {
  const [link, setLink] = useState<string>('#');

  const { userDetail } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const fetchTipaltiUrl = async () => {
      const api = new AgentControllerApi(getYentaConfiguration());
      try {
        const { data } = await api.getTipaltiSignUpUrl();

        setLink(data.signUpLink!);
      } catch (e) {
        ErrorService.notifyIgnoreAuthErrors(
          'Unable to fetch tipalti sign up URL',
          e,
        );
      }
    };

    fetchTipaltiUrl();
  }, []);

  if (!userDetail?.tipaltiId) {
    return null;
  }

  return (
    <ZenExternalLinkSideBarItem
      icon={
        <FontAwesomeIcon
          icon={faArrowUpRightFromSquare}
          className={cn(isCollapsed ? 'text-lg' : 'text-sm')}
        />
      }
      label='Tipalti Account'
      linkTo={link}
      collapsed={isCollapsed}
    />
  );
};

export default TipaltiSidebarMenuItem;
