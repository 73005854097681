import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import {
  DebtRepaymentItemResponse,
  DebtRepaymentItemResponsePaymentSourceTypeEnum,
} from '../../openapi/arrakis';
import { RootState } from '../../types';
import { getRepaymentItemURL } from '../../utils/AgentHelper';
import { numberToMoney } from '../../utils/CurrencyUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import ResourceContainer from '../ResourceContainer';
import ZenFixedDataTable from '../Zen/Table/ZenFixedDataTable';
import ZenButton from '../Zen/ZenButton';
import ZenSidebarModal from '../Zen/ZenSidebarModal';

interface DebtRepaymentItemsProps {
  isOpen: boolean;
  onClose(): void;
  repaymentItems?: Array<DebtRepaymentItemResponse>;
  agentId: string;
}

const DebtRepaymentItems: React.FC<DebtRepaymentItemsProps> = ({
  isOpen,
  onClose,
  repaymentItems,
  agentId,
}) => {
  const {
    agentDetail: {
      publicTransactionResponseById,
      publicTransactionResponseByIdLoading,
      publicTransactionResponseByIdErrorCode,
    },
  } = useSelector((state: RootState) => state);

  const columns: Array<Column<DebtRepaymentItemResponse>> = [
    {
      Header: 'Address',
      accessor: 'paymentSourceId',
      Cell: ({ value, row: { original } }) => (
        <p className='font-zen-body font-normal text-sm text-zen-dark-9'>
          {value &&
          original?.paymentSourceType ===
            DebtRepaymentItemResponsePaymentSourceTypeEnum.Commission
            ? publicTransactionResponseById[value]?.addressOneLine
            : capitalizeEnum(original?.paymentSourceType!)}
        </p>
      ),
      disableFilters: true,
    },
    {
      Header: 'Repayment Amount',
      accessor: 'repaymentAmount',
      Cell: ({ value }) => (
        <p className='font-zen-body font-normal text-sm text-zen-dark-9'>
          {value ? numberToMoney(value) : 'N/A'}
        </p>
      ),
      disableFilters: true,
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ row: { original } }) => (
        <a
          href={getRepaymentItemURL(original, agentId)}
          target='_blank'
          rel='noreferrer'
        >
          <FontAwesomeIcon
            icon={regular('arrow-up-right-from-square')}
            className='text-primary-blue'
            aria-label='transaction-link'
            fontSize={16}
          />
        </a>
      ),
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  return (
    <ZenSidebarModal title='Repayment Items' isOpen={isOpen} onClose={onClose}>
      <div className='p-4'>
        <ResourceContainer
          loading={publicTransactionResponseByIdLoading}
          resourceName='repayment item'
          errorCode={publicTransactionResponseByIdErrorCode}
          isEmpty={false}
        >
          <ZenFixedDataTable
            columns={columns}
            resourceName='repayment item'
            data={repaymentItems || []}
            hidePageSize
            hideFilters
            hidePagination
          />
        </ResourceContainer>
      </div>
      <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 left-0 right-0 px-3 absolute py-2'>
        <div className='w-2/6 ml-auto flex flex-row justify-start items-center'>
          <ZenButton
            type='button'
            onClick={onClose}
            label='Cancel'
            variant='primary-outline'
            isFullWidth
          />
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default DebtRepaymentItems;
