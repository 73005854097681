import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import ZenSideBarItem from '../../ZenSideBarItem';

interface ZenSuperAdminMenuProps {
  isCollapsed: boolean;
}

const ZenSuperAdminMenu: React.FC<ZenSuperAdminMenuProps> = ({
  isCollapsed,
}) => {
  return (
    <div>
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={faUser} className='text-lg' />}
        label='People'
        linkTo='/people'
        collapsed={isCollapsed}
      />
    </div>
  );
};

export default ZenSuperAdminMenu;
