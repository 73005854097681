import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import { getFullName } from '../../../utils/AgentHelper';
import Avatar from '../../Avatar';
import { cn } from '../../../utils/classUtils';

interface ZenMentionAgentProps {
  id: string;
  side: 'left' | 'right';
}

const ZenMentionAgent: React.FC<ZenMentionAgentProps> = ({ id, side }) => {
  const {
    userIds: { agentById },
    transaction: { commentsMentionUsers },
  } = useSelector((state: RootState) => state);
  const isGroup = (commentsMentionUsers?.mentionableGroups || [])?.find(
    (group) => group?.groupId === id,
  );
  const agent = agentById[id];
  const fullName = !!isGroup
    ? isGroup?.groupName
    : commentsMentionUsers?.mentionableUsers?.find(
        (user) => user?.yentaId === id,
      )?.displayName || getFullName(agent);
  const isSelf = side === 'right';

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href={!isGroup ? `/people/${agent?.id!}` : undefined}
      target='_blank'
      rel='noreferrer'
      className='inline-block'
    >
      <div
        className={cn(
          isSelf ? 'bg-zen-primary-7' : 'bg-zen-gray-2',
          'flex flex-row items-center p-1 rounded-full space-x-1',
        )}
      >
        {fullName && (
          <Avatar name={fullName!} imageUrl={agent?.avatar} size='xxs' />
        )}
        <p
          className={cn(
            isSelf ? 'text-white' : 'text-zen-dark',
            'font-zen-body-regular text-sm',
          )}
        >
          {fullName}
        </p>
      </div>
    </a>
  );
};

export default ZenMentionAgent;
