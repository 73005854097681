import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import DefaultLoader from '../../../components/DefaultLoader';
import {
  AgentControllerApi as ArrakisAgentControllerApi,
  AgentResponse as ArrakisAgentResponse,
} from '../../../openapi/arrakis';
import { AgentControllerApi, AgentResponse } from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { togglePermanentlyProducing } from '../../../slices/AgentSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  getArrakisConfiguration,
  getYentaConfiguration,
} from '../../../utils/OpenapiConfigurationUtils';
import ZenAvatarPill from '../ZenAvatarPill';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenSimpleConfirmationModal from '../Modal/ZenSimpleConfirmationModal';
import ZenButton from '../ZenButton';
import ZenSidebarModalForm from '../ZenSidebarModalForm';

interface ZenMarkPermanentlyProducingFormSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  agentId: string;
}

const ZenMarkPermanentlyProducingFormSidebarModal: React.FC<ZenMarkPermanentlyProducingFormSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [agentDetail, setAgentDetail] = useState<AgentResponse>();
  const [
    agentPerformance,
    setAgentPerformance,
  ] = useState<ArrakisAgentResponse>();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<FormData>();

  const agentId = watch('agentId');

  const onSubmit = async () => {
    setIsSaveModalOpen(true);
  };

  const fetchAgentInfo = async (id: string) => {
    setLoading(true);
    try {
      const [{ data }, { data: performance }] = await Promise.all([
        new AgentControllerApi(await getYentaConfiguration()).getAgentById(id),
        new ArrakisAgentControllerApi(
          await getArrakisConfiguration(),
        ).getAgentByYentaId(id),
      ]);
      setAgentDetail(data);
      setAgentPerformance(performance);
    } catch (e) {
      ErrorService.notify('Unable to fetch agent detail', e, { agent: { id } });
      dispatch(
        showErrorToast(
          'We had a problem fetching agent details.',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceAgentInfo = useCallback(debounce(fetchAgentInfo, 500), []);

  useEffect(() => {
    if (agentId) {
      debounceAgentInfo(agentId);
    } else {
      setAgentDetail(undefined);
      setAgentPerformance(undefined);
    }
  }, [agentId, debounceAgentInfo]);

  const handlePermanentlyProducing = async () => {
    setSubmitting(true);
    await dispatch(
      togglePermanentlyProducing(agentId, {
        permanentlyProducing: !agentPerformance?.permanentlyProducing,
      }),
    );
    setSubmitting(false);
    setIsCancelModalOpen(false);
    setIsSaveModalOpen(false);
    onClose();
  };

  return (
    <React.Fragment>
      <ZenSidebarModalForm
        title='Mark Permanently Producing'
        isOpen={isOpen}
        onClose={onClose}
        actionTitle='Save'
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isSubmitting}
        isDisabled={isSubmitting || !!agentPerformance?.permanentlyProducing}
      >
        <div>
          <div className='mb-2'>
            <ZenControlledTextInput<FormData, 'agentId'>
              label='Agent ID'
              name='agentId'
              control={control}
              placeholder='12345678-1111-2222-3333-12345678'
              rules={{ required: 'Agent ID is required' }}
              isRequired
            />
          </div>
          {loading ? (
            <DefaultLoader />
          ) : agentDetail && agentPerformance?.permanentlyProducing ? (
            <div className='flex flex-row items-center space-x-4'>
              <ZenAvatarPill
                text={agentDetail?.fullName!}
                imageUrl={agentDetail?.avatar!}
              />

              <ZenButton
                label='Remove'
                variant='danger'
                LeftIconComponent={
                  <FontAwesomeIcon
                    icon={faTrash}
                    className='cursor-pointer'
                    title='remove'
                  />
                }
                onClick={() => setIsCancelModalOpen(true)}
              />
            </div>
          ) : (
            agentDetail &&
            !agentPerformance?.permanentlyProducing && (
              <p className='font-primary-regular'>
                {agentDetail?.fullName} is not permanently producing.
              </p>
            )
          )}
        </div>
      </ZenSidebarModalForm>

      {isCancelModalOpen && (
        <ZenSimpleConfirmationModal
          title='This agent will no longer be permanently producing?'
          isOpen
          onClose={() => setIsCancelModalOpen(false)}
          variant='cancel'
          isSubmitting={submitting}
          onConfirm={handlePermanentlyProducing}
        />
      )}

      {isSaveModalOpen && (
        <ZenSimpleConfirmationModal
          title='Do you really want to mark this agent permanently producing'
          isOpen
          onClose={() => setIsSaveModalOpen(false)}
          variant='info'
          isSubmitting={submitting}
          onConfirm={handlePermanentlyProducing}
        />
      )}
    </React.Fragment>
  );
};

export default ZenMarkPermanentlyProducingFormSidebarModal;
