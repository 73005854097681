import { faPowerOff } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  AgentResponseAccountCountryEnum,
  TeamControllerApi,
  TeamMemberResponseRolesEnum,
  TeamResponse,
  TeamResponseCommissionDocumentApproverEnum,
  TeamResponseStatusEnum,
  UpdateTeamRequestCommissionDocumentApproverEnum,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  markTeamAsInactive,
  saveTeamDetailOverview,
  updateTeam,
} from '../../../slices/TeamSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { ISelectOption } from '../../../types';
import { isSmScreen } from '../../../utils/BrowserUtils';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { isTeamTypeEligibleForInstantPayments } from '../../../utils/TeamHelper';
import { getInstantCommissionName } from '../../../utils/TransactionHelper';
import IconButton from '../../IconButton';
import ZenControlledSelectInput from '../Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../Input/ZenControlledToggleInput';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import ZenSidebarModalActionFooterV2 from '../Modal/ZenSidebarModalActionFooterV2';
import ZenSidebarModal from '../ZenSidebarModal';

interface ZenEditTeamFormProps {
  isOpen: boolean;
  onClose(): void;
  team: TeamResponse;
  country: AgentResponseAccountCountryEnum;
}

interface FormData {
  name: string;
  eligibleInstantPayments: boolean;
  paidAtClose: boolean;
  commissionDocumentApprover?: ISelectOption;
}

const ZenEditTeamForm: React.FC<ZenEditTeamFormProps> = ({
  isOpen,
  onClose,
  team,
  country,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: team.name,
      commissionDocumentApprover: {
        label: capitalizeEnum(team.commissionDocumentApprover!),
        value: team.commissionDocumentApprover!,
      },
      eligibleInstantPayments: team.instantPaymentEligible,
      paidAtClose: team.paidAtClosing,
    },
  });
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [inActivating, setInActivating] = useState(false);

  const hasMembers = team?.agents?.filter((agent) =>
    agent.roles?.includes(TeamMemberResponseRolesEnum.Member),
  )?.length;

  const dispatch = useDispatch();

  const onSubmit = async (formData: FormData) => {
    if (
      formData.name !== team.name ||
      formData.commissionDocumentApprover?.value !==
        team.commissionDocumentApprover
    ) {
      await dispatch(
        updateTeam(team.id!, {
          name: formData.name,
          commissionDocumentApprover: formData.commissionDocumentApprover
            ?.value as UpdateTeamRequestCommissionDocumentApproverEnum,
        }),
      );
    }

    onClose();

    if (formData.paidAtClose !== team.paidAtClosing) {
      try {
        const { data } = await new TeamControllerApi(
          getYentaConfiguration(),
        ).updateTeamPaidAtClosing(team.id!, {
          paidAtClosing: formData.paidAtClose,
        });
        dispatch(saveTeamDetailOverview(data));
      } catch (e) {
        await dispatch(showApiErrorModal(e));
        await dispatch(
          showErrorToast(
            `Unable to update ${getInstantCommissionName(country)}`,
          ),
        );
        ErrorService.notify(
          `Unable to update ${getInstantCommissionName(country)}`,
          e,
          {
            team: { id: team.id },
          },
        );
      }
    }
  };

  return (
    <ZenSidebarModal title='Edit Team' isOpen={isOpen} onClose={onClose}>
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='edit-team-form'
      >
        <div className='p-5'>
          <div className='mt-3'>
            <ZenControlledTextInput<FormData, 'name'>
              control={control}
              name='name'
              placeholder='Team Name'
              label='Name'
              isRequired
              rules={{
                required: 'Please enter a team name',
              }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, 'commissionDocumentApprover'>
              name='commissionDocumentApprover'
              placeholder='Label'
              control={control}
              options={[
                ...values(TeamResponseCommissionDocumentApproverEnum).map(
                  (state) => ({
                    value: state,
                    label: capitalizeEnum(state),
                  }),
                ),
              ]}
              label='Commission Document Approver'
            />
          </div>
          <div className='mt-8'>
            <div className='border border-zen-dark-4 rounded-lg px-2'>
              {isTeamTypeEligibleForInstantPayments(team.type!) && (
                <div className='p-3 border-b border-zen-dark-4'>
                  <ZenControlledToggleInput<FormData, 'eligibleInstantPayments'>
                    name='eligibleInstantPayments'
                    control={control}
                    label='Eligible For Instant Payments?'
                    disabled
                  />
                </div>
              )}
              <div className='p-3'>
                <ZenControlledToggleInput<FormData, 'paidAtClose'>
                  name='paidAtClose'
                  control={control}
                  label={`Eligible for ${getInstantCommissionName(country)}?`}
                />
              </div>
            </div>
          </div>
          <div className='mt-8'>
            {!hasMembers && team?.status === TeamResponseStatusEnum.Active && (
              <IconButton
                variant='none'
                buttonType='button'
                label='Mark as Inactive'
                onClick={() => setConfirmationModal(true)}
                leftIcon={
                  <FontAwesomeIcon
                    icon={faPowerOff}
                    className='text-zen-danger'
                  />
                }
                buttonStyle='hover:bg-zen-danger hover:bg-opacity-10 hover:rounded-md'
                textStyle='text-zen-danger'
              />
            )}
          </div>
        </div>
        <ZenSidebarModalActionFooterV2
          onClose={onClose}
          isSubmitting={isSubmitting}
          submitButtonText='Update'
        />
      </form>
      <ZenConfirmationModal
        title='Are you sure you want to mark as inactive?'
        subtitle={
          <span className='text-zen-dark-9 font-zen-body'>
            Once a team is inactive, you cannot reactivate it.
          </span>
        }
        variant='danger'
        isOpen={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        onConfirm={async () => {
          setInActivating(true);
          await dispatch(markTeamAsInactive(team?.id!));
          setInActivating(false);
          setConfirmationModal(false);
          onClose();
        }}
        confirmButtonText='Mark as Inactive'
        confirmButtonLeftIcon={<FontAwesomeIcon icon={faPowerOff} />}
        isSubmitting={inActivating}
        size='large'
        hideIcon={isSmScreen()}
      />
    </ZenSidebarModal>
  );
};

export default ZenEditTeamForm;
