/* tslint:disable */
/* eslint-disable */
/**
 * Plutus API
 * Plutus Main Application
 *
 * The version of the OpenAPI document: 4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AppConfig
 */
export interface AppConfig {
    /**
     * 
     * @type {string}
     * @memberof AppConfig
     */
    'hostUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppConfig
     */
    'keymakerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppConfig
     */
    'arrakisUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppConfig
     */
    'atlantisUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppConfig
     */
    'yentaUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppConfig
     */
    'hermesUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppConfig
     */
    'yadaUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'realTitlePayoutPerShare'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'realTitlePaymentsPerYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'realTitleYearsOfPayments'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'avgIndustryCommissionPct'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultAverageTransactionsPerYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultAverageVolumePerYear'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AppConfig
     */
    'defaultParticipateInSPP'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppConfig
     */
    'defaultParticipateInRevShare'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultAttractHowManyAgents'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultNetworkAttractsHowManyAgents'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AppConfig
     */
    'defaultParticipateInRealTitleJointVenture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppConfig
     */
    'defaultHasExternalRealShares'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultExternalRealShareCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultFutureRealStockPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultTeamNumberCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultTeamTransactionsPerMember'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultTeamAverageSalesPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultTeamLeaderCommissionPercentagePerTransaction'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultTeamAverageFixedCommission'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AppConfig
     */
    'defaultTeamLead'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppConfig
     */
    'defaultUseAverageFixedCommission'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppConfig
     */
    'defaultUseTeamAverageFixedCommission'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultAverageFixedCommission'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultTeamAverageTransactionCommissionPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultAverageHomeSalesPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'internalUsersCacheExpirationHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'loanAuthUsersCacheHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'borrowerAuthUsersCacheHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'leadsAuthUsersCacheHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'txAuthUsersCacheHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'commentsAuthUsersCacheHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'eventNameCacheHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'schemaMigrationBatchSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'wealthReferralMonthlyLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultWealthProjectionYears'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'defaultWealthAgentsToFirstTier'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppConfig
     */
    'joinRealDomain'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppConfig
     */
    'yentaImagesDomain'?: string;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'emailSenderPoolSize'?: number;
    /**
     * 
     * @type {AppConfigEmailSenderAwaitTerminationPeriod}
     * @memberof AppConfig
     */
    'emailSenderAwaitTerminationPeriod'?: AppConfigEmailSenderAwaitTerminationPeriod;
    /**
     * 
     * @type {string}
     * @memberof AppConfig
     */
    'boltDomain'?: string;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'maxStockPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppConfig
     */
    'noReplyEmailAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'averageTransactionCommissionPercentageMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'averageTransactionCommissionPercentageMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'averageFixedCommissionMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'averageFixedCommissionMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'teamNumberCountMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'teamNumberCountMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'teamTransactionsPerMemberMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'teamTransactionsPerMemberMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'teamAverageSalesPriceMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'teamAverageSalesPriceMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'teamLeaderCommissionPercentagePerTransactionMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'teamLeaderCommissionPercentagePerTransactionMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'teamAverageTransactionCommissionPercentageMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'teamAverageTransactionCommissionPercentageMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'teamAverageFixedCommissionMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfig
     */
    'teamAverageFixedCommissionMax'?: number;
}
/**
 * 
 * @export
 * @interface AppConfigEmailSenderAwaitTerminationPeriod
 */
export interface AppConfigEmailSenderAwaitTerminationPeriod {
    /**
     * 
     * @type {number}
     * @memberof AppConfigEmailSenderAwaitTerminationPeriod
     */
    'seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfigEmailSenderAwaitTerminationPeriod
     */
    'nano'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AppConfigEmailSenderAwaitTerminationPeriod
     */
    'negative'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppConfigEmailSenderAwaitTerminationPeriod
     */
    'zero'?: boolean;
    /**
     * 
     * @type {Array<AppConfigEmailSenderAwaitTerminationPeriodUnitsInner>}
     * @memberof AppConfigEmailSenderAwaitTerminationPeriod
     */
    'units'?: Array<AppConfigEmailSenderAwaitTerminationPeriodUnitsInner>;
}
/**
 * 
 * @export
 * @interface AppConfigEmailSenderAwaitTerminationPeriodUnitsInner
 */
export interface AppConfigEmailSenderAwaitTerminationPeriodUnitsInner {
    /**
     * 
     * @type {boolean}
     * @memberof AppConfigEmailSenderAwaitTerminationPeriodUnitsInner
     */
    'dateBased'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppConfigEmailSenderAwaitTerminationPeriodUnitsInner
     */
    'timeBased'?: boolean;
    /**
     * 
     * @type {AppConfigEmailSenderAwaitTerminationPeriodUnitsInnerDuration}
     * @memberof AppConfigEmailSenderAwaitTerminationPeriodUnitsInner
     */
    'duration'?: AppConfigEmailSenderAwaitTerminationPeriodUnitsInnerDuration;
    /**
     * 
     * @type {boolean}
     * @memberof AppConfigEmailSenderAwaitTerminationPeriodUnitsInner
     */
    'durationEstimated'?: boolean;
}
/**
 * 
 * @export
 * @interface AppConfigEmailSenderAwaitTerminationPeriodUnitsInnerDuration
 */
export interface AppConfigEmailSenderAwaitTerminationPeriodUnitsInnerDuration {
    /**
     * 
     * @type {number}
     * @memberof AppConfigEmailSenderAwaitTerminationPeriodUnitsInnerDuration
     */
    'seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppConfigEmailSenderAwaitTerminationPeriodUnitsInnerDuration
     */
    'nano'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AppConfigEmailSenderAwaitTerminationPeriodUnitsInnerDuration
     */
    'negative'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppConfigEmailSenderAwaitTerminationPeriodUnitsInnerDuration
     */
    'zero'?: boolean;
}
/**
 * 
 * @export
 * @interface AttractingStockAwardsEarningsResponse
 */
export interface AttractingStockAwardsEarningsResponse {
    /**
     * 
     * @type {Array<CommissionGraphDataPoint>}
     * @memberof AttractingStockAwardsEarningsResponse
     */
    'fiveYearGraphData'?: Array<CommissionGraphDataPoint>;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AttractingStockAwardsEarningsResponse
     */
    'projectedPortfolioValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof AttractingStockAwardsEarningsResponse
     */
    'projectedPortfolioShares'?: number;
    /**
     * 
     * @type {number}
     * @memberof AttractingStockAwardsEarningsResponse
     */
    'projectedPortfolioAgents'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AttractingStockAwardsEarningsResponse
     */
    'currentPortfolioValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof AttractingStockAwardsEarningsResponse
     */
    'currentPortfolioShares'?: number;
    /**
     * 
     * @type {number}
     * @memberof AttractingStockAwardsEarningsResponse
     */
    'currentPortfolioAgents'?: number;
    /**
     * 
     * @type {number}
     * @memberof AttractingStockAwardsEarningsResponse
     */
    'agentsReferredPerYear'?: number;
}
/**
 * 
 * @export
 * @interface BaseMessage
 */
export interface BaseMessage {
    /**
     * 
     * @type {string}
     * @memberof BaseMessage
     */
    'eventId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseMessage
     */
    'createdAt'?: number;
}
/**
 * 
 * @export
 * @interface CappingAwardEarningsResponse
 */
export interface CappingAwardEarningsResponse {
    /**
     * 
     * @type {Array<CommissionGraphDataPoint>}
     * @memberof CappingAwardEarningsResponse
     */
    'fiveYearGraphData'?: Array<CommissionGraphDataPoint>;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CappingAwardEarningsResponse
     */
    'projectedCappingAwardValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CappingAwardEarningsResponse
     */
    'projectedCappingAwardShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CappingAwardEarningsResponse
     */
    'currentCappingAwardValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CappingAwardEarningsResponse
     */
    'currentCappingAwardShares'?: number;
}
/**
 * 
 * @export
 * @interface CommissionEarningsResponse
 */
export interface CommissionEarningsResponse {
    /**
     * 
     * @type {string}
     * @memberof CommissionEarningsResponse
     */
    'cycleStartDate': string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionEarningsResponse
     */
    'preCapCommissionEarningsValue'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CommissionEarningsResponse
     */
    'cappingDate'?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionEarningsResponse
     */
    'projectedPostCapValue'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionEarningsResponse
     */
    'currentCycleComissionIncome'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionEarningsResponse
     */
    'oneYearProjectedIncomeValue'?: MoneyValue;
    /**
     * 
     * @type {Array<CommissionGraphDataPoint>}
     * @memberof CommissionEarningsResponse
     */
    'fiveYearGraphData'?: Array<CommissionGraphDataPoint>;
    /**
     * 
     * @type {Array<CommissionGraphDataPoint>}
     * @memberof CommissionEarningsResponse
     */
    'oneYearGraphData'?: Array<CommissionGraphDataPoint>;
    /**
     * 
     * @type {Array<CommissionGraphDataPoint>}
     * @memberof CommissionEarningsResponse
     */
    'teamCommissionFiveYearGraphData'?: Array<CommissionGraphDataPoint>;
    /**
     * 
     * @type {Array<CommissionGraphDataPoint>}
     * @memberof CommissionEarningsResponse
     */
    'individualCommissionFiveYearGraphData'?: Array<CommissionGraphDataPoint>;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionEarningsResponse
     */
    'projectedNetCommissionIncome': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionEarningsResponse
     */
    'currentGCI': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionEarningsResponse
     */
    'currentNetCommissionIncome': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CommissionEarningsResponse
     */
    'projectionYears'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommissionEarningsResponse
     */
    'projectedTransactionsPerYear'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionEarningsResponse
     */
    'projectedVolumePerYear': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionEarningsResponse
     */
    'projectedIndividualNetCommissionIncome': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionEarningsResponse
     */
    'projectedTeamNetCommissionIncome'?: MoneyValue;
}
/**
 * 
 * @export
 * @interface CommissionGraphDataPoint
 */
export interface CommissionGraphDataPoint {
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionGraphDataPoint
     */
    'value'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CommissionGraphDataPoint
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface CreateWebhookRequest
 */
export interface CreateWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateWebhookRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWebhookRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWebhookRequest
     */
    'url': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateWebhookRequest
     */
    'eventNames': Array<CreateWebhookRequestEventNamesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateWebhookRequestEventNamesEnum {
    AgentAnniversaryUpdated = 'AGENT_ANNIVERSARY_UPDATED',
    AgentNationalIdentificationUpdated = 'AGENT_NATIONAL_IDENTIFICATION_UPDATED',
    AgentJoinedTeam = 'AGENT_JOINED_TEAM',
    AgentLeftTeam = 'AGENT_LEFT_TEAM',
    AgentSwitchedTeams = 'AGENT_SWITCHED_TEAMS',
    AgentTerminated = 'AGENT_TERMINATED',
    AgentPaymentDetailsInvalidUpdated = 'AGENT_PAYMENT_DETAILS_INVALID_UPDATED',
    AgentSingleCheckPreferenceUpdated = 'AGENT_SINGLE_CHECK_PREFERENCE_UPDATED',
    AgentActivated = 'AGENT_ACTIVATED',
    AgentEmailUpdated = 'AGENT_EMAIL_UPDATED',
    AgentReachedElite = 'AGENT_REACHED_ELITE',
    AgentCapped = 'AGENT_CAPPED',
    AgentApplicationStatusUpdated = 'AGENT_APPLICATION_STATUS_UPDATED',
    TransactionCreated = 'TRANSACTION_CREATED',
    CommissionValidated = 'COMMISSION_VALIDATED',
    TransactionApprovedClosing = 'TRANSACTION_APPROVED_CLOSING',
    TransactionPaid = 'TRANSACTION_PAID',
    TransactionSettled = 'TRANSACTION_SETTLED',
    TransactionClosed = 'TRANSACTION_CLOSED',
    TransactionPaymentAccepted = 'TRANSACTION_PAYMENT_ACCEPTED',
    TransactionPaymentScheduled = 'TRANSACTION_PAYMENT_SCHEDULED',
    DepositReceived = 'DEPOSIT_RECEIVED',
    TransactionApprovedForCdGeneration = 'TRANSACTION_APPROVED_FOR_CD_GENERATION',
    CommissionDocumentApproved = 'COMMISSION_DOCUMENT_APPROVED',
    CommissionPayerAssigned = 'COMMISSION_PAYER_ASSIGNED',
    CommissionDocumentGenerated = 'COMMISSION_DOCUMENT_GENERATED',
    CommissionDocumentSent = 'COMMISSION_DOCUMENT_SENT',
    CommissionDocumentUpdated = 'COMMISSION_DOCUMENT_UPDATED',
    ConveyanceValidatedEvent = 'CONVEYANCE_VALIDATED_EVENT',
    ConveyanceInvalidatedEvent = 'CONVEYANCE_INVALIDATED_EVENT',
    DepositConfirmed = 'DEPOSIT_CONFIRMED',
    TrustDepositSubmitted = 'TRUST_DEPOSIT_SUBMITTED',
    TrustDepositSubmittedInFull = 'TRUST_DEPOSIT_SUBMITTED_IN_FULL',
    TrustDepositConfirmedInFull = 'TRUST_DEPOSIT_CONFIRMED_IN_FULL',
    TrustDepositInstallmentSubmitted = 'TRUST_DEPOSIT_INSTALLMENT_SUBMITTED',
    TrustDepositInstallmentConfirmed = 'TRUST_DEPOSIT_INSTALLMENT_CONFIRMED',
    CommissionDepositSubmitted = 'COMMISSION_DEPOSIT_SUBMITTED',
    CommissionDepositSubmittedInFull = 'COMMISSION_DEPOSIT_SUBMITTED_IN_FULL',
    CommissionDepositConfirmedInFull = 'COMMISSION_DEPOSIT_CONFIRMED_IN_FULL',
    CommissionDepositInstallmentSubmitted = 'COMMISSION_DEPOSIT_INSTALLMENT_SUBMITTED',
    CommissionDepositInstallmentConfirmed = 'COMMISSION_DEPOSIT_INSTALLMENT_CONFIRMED',
    ExcessFundsReleased = 'EXCESS_FUNDS_RELEASED',
    FundsReleaseReverted = 'FUNDS_RELEASE_REVERTED',
    TransactionTerminationRequested = 'TRANSACTION_TERMINATION_REQUESTED',
    TransactionTerminated = 'TRANSACTION_TERMINATED',
    TransactionParticipantAdded = 'TRANSACTION_PARTICIPANT_ADDED',
    TransactionParticipantUpdated = 'TRANSACTION_PARTICIPANT_UPDATED',
    TransactionParticipantRemoved = 'TRANSACTION_PARTICIPANT_REMOVED',
    TransactionFeeAdded = 'TRANSACTION_FEE_ADDED',
    TransactionFeeRemoved = 'TRANSACTION_FEE_REMOVED',
    TransactionAddressUpdated = 'TRANSACTION_ADDRESS_UPDATED',
    TransactionYearBuiltUpdated = 'TRANSACTION_YEAR_BUILT_UPDATED',
    TransactionMlsNumberUpdated = 'TRANSACTION_MLS_NUMBER_UPDATED',
    TransactionEscrowNumberUpdated = 'TRANSACTION_ESCROW_NUMBER_UPDATED',
    TransactionSalePriceUpdated = 'TRANSACTION_SALE_PRICE_UPDATED',
    TransactionListingCommissionUpdated = 'TRANSACTION_LISTING_COMMISSION_UPDATED',
    TransactionSaleCommissionUpdated = 'TRANSACTION_SALE_COMMISSION_UPDATED',
    TransactionContractAcceptanceDateUpdated = 'TRANSACTION_CONTRACT_ACCEPTANCE_DATE_UPDATED',
    TransactionActualClosingDateUpdated = 'TRANSACTION_ACTUAL_CLOSING_DATE_UPDATED',
    TransactionEstimatedClosingDateUpdated = 'TRANSACTION_ESTIMATED_CLOSING_DATE_UPDATED',
    TransactionFirmDateUpdated = 'TRANSACTION_FIRM_DATE_UPDATED',
    TransactionMarkedAsCompliant = 'TRANSACTION_MARKED_AS_COMPLIANT',
    TransactionMarkedAsNonCompliant = 'TRANSACTION_MARKED_AS_NON_COMPLIANT',
    DoubleEnderTransactionLinked = 'DOUBLE_ENDER_TRANSACTION_LINKED',
    DoubleEnderTransactionUnlinked = 'DOUBLE_ENDER_TRANSACTION_UNLINKED',
    TransactionInternalReferralUnlinked = 'TRANSACTION_INTERNAL_REFERRAL_UNLINKED',
    TransactionInternalReferralLinked = 'TRANSACTION_INTERNAL_REFERRAL_LINKED',
    TxReminderWaitForApproveCdCalculation = 'TX_REMINDER_WAIT_FOR_APPROVE_CD_CALCULATION',
    TxReminderWaitForSendCd = 'TX_REMINDER_WAIT_FOR_SEND_CD',
    TxReminderListingIsAboutToExpire = 'TX_REMINDER_LISTING_IS_ABOUT_TO_EXPIRE',
    TxReminderListingHasExpired = 'TX_REMINDER_LISTING_HAS_EXPIRED',
    TxReminderIsAboutToClose = 'TX_REMINDER_IS_ABOUT_TO_CLOSE',
    TxReminderHasExpired = 'TX_REMINDER_HAS_EXPIRED',
    TransactionTaxExemptUpdated = 'TRANSACTION_TAX_EXEMPT_UPDATED',
    TransactionTaxPaidFromCommissionUpdated = 'TRANSACTION_TAX_PAID_FROM_COMMISSION_UPDATED',
    TransactionTaxRatesUpdated = 'TRANSACTION_TAX_RATES_UPDATED',
    TransactionTaxAmountUpdated = 'TRANSACTION_TAX_AMOUNT_UPDATED',
    ListingCreated = 'LISTING_CREATED',
    ChecklistCreatedEvent = 'CHECKLIST_CREATED_EVENT',
    ChecklistItemCreated = 'CHECKLIST_ITEM_CREATED',
    ChecklistItemNotStarted = 'CHECKLIST_ITEM_NOT_STARTED',
    ChecklistItemPending = 'CHECKLIST_ITEM_PENDING',
    ChecklistItemWaitingForReview = 'CHECKLIST_ITEM_WAITING_FOR_REVIEW',
    ChecklistItemInReview = 'CHECKLIST_ITEM_IN_REVIEW',
    ChecklistItemRevisionRequested = 'CHECKLIST_ITEM_REVISION_REQUESTED',
    ChecklistItemAccepted = 'CHECKLIST_ITEM_ACCEPTED',
    ChecklistItemDeleted = 'CHECKLIST_ITEM_DELETED',
    ChecklistItemStatusUpdated = 'CHECKLIST_ITEM_STATUS_UPDATED',
    ChecklistItemNameUpdated = 'CHECKLIST_ITEM_NAME_UPDATED',
    ChecklistItemRequiredStatusChanged = 'CHECKLIST_ITEM_REQUIRED_STATUS_CHANGED',
    ChecklistItemDescriptionUpdated = 'CHECKLIST_ITEM_DESCRIPTION_UPDATED',
    ChecklistItemAssigneeUpdated = 'CHECKLIST_ITEM_ASSIGNEE_UPDATED',
    ChecklistItemDueDateUpdated = 'CHECKLIST_ITEM_DUE_DATE_UPDATED',
    ChecklistItemUrgentStatusUpdated = 'CHECKLIST_ITEM_URGENT_STATUS_UPDATED',
    ChecklistItemRequiredStatusUpdated = 'CHECKLIST_ITEM_REQUIRED_STATUS_UPDATED',
    ChecklistItemLabelAddedEvent = 'CHECKLIST_ITEM_LABEL_ADDED_EVENT',
    ChecklistItemLabelRemovedEvent = 'CHECKLIST_ITEM_LABEL_REMOVED_EVENT',
    ChecklistItemFileUploaded = 'CHECKLIST_ITEM_FILE_UPLOADED',
    ChecklistItemFileVersionUploaded = 'CHECKLIST_ITEM_FILE_VERSION_UPLOADED',
    ChecklistItemFileDeleted = 'CHECKLIST_ITEM_FILE_DELETED',
    ChecklistItemFileReferenceAttached = 'CHECKLIST_ITEM_FILE_REFERENCE_ATTACHED',
    ChecklistItemFileReferenceRemoved = 'CHECKLIST_ITEM_FILE_REFERENCE_REMOVED',
    ChecklistItemAssigned = 'CHECKLIST_ITEM_ASSIGNED',
    ChecklistItemUnassigned = 'CHECKLIST_ITEM_UNASSIGNED',
    JourneyItemCompleted = 'JOURNEY_ITEM_COMPLETED',
    JourneyItemIncomplete = 'JOURNEY_ITEM_INCOMPLETE',
    EmailReceivedEvent = 'EMAIL_RECEIVED_EVENT',
    OfficePaymentSystemUpdated = 'OFFICE_PAYMENT_SYSTEM_UPDATED',
    OfficePaymentBatchUpdated = 'OFFICE_PAYMENT_BATCH_UPDATED',
    OfficeRealWithholdsTaxUpdated = 'OFFICE_REAL_WITHHOLDS_TAX_UPDATED',
    OfficeTaxesUpdated = 'OFFICE_TAXES_UPDATED',
    OfficeTaxPaidFromCommissionUpdated = 'OFFICE_TAX_PAID_FROM_COMMISSION_UPDATED',
    MortgageLeadCreated = 'MORTGAGE_LEAD_CREATED',
    MortgageBorrowerInvited = 'MORTGAGE_BORROWER_INVITED',
    MortgageCoBorrowerAdded = 'MORTGAGE_CO_BORROWER_ADDED',
    MortgageCoBorrowerInvited = 'MORTGAGE_CO_BORROWER_INVITED',
    MortgageApplicationStarted = 'MORTGAGE_APPLICATION_STARTED',
    MortgageApplicationSubmitted = 'MORTGAGE_APPLICATION_SUBMITTED',
    MortgageLoanPreApproved = 'MORTGAGE_LOAN_PRE_APPROVED',
    DropboxCreatedEvent = 'DROPBOX_CREATED_EVENT',
    DropboxFileCreatedEvent = 'DROPBOX_FILE_CREATED_EVENT',
    DropboxFileVersionAddedEvent = 'DROPBOX_FILE_VERSION_ADDED_EVENT',
    DropboxFileDeletedEvent = 'DROPBOX_FILE_DELETED_EVENT',
    DropboxFileMovedToTrashEvent = 'DROPBOX_FILE_MOVED_TO_TRASH_EVENT',
    DropboxFileRestoredFromTrashEvent = 'DROPBOX_FILE_RESTORED_FROM_TRASH_EVENT',
    DropboxFileNameUpdatedEvent = 'DROPBOX_FILE_NAME_UPDATED_EVENT',
    CommentCreated = 'COMMENT_CREATED',
    CommentEdited = 'COMMENT_EDITED',
    CommentMediaUploaded = 'COMMENT_MEDIA_UPLOADED',
    CommentAudioTranscribed = 'COMMENT_AUDIO_TRANSCRIBED',
    WebhookEndpointTestEvent = 'WEBHOOK_ENDPOINT_TEST_EVENT',
    FundReleaseRequestSubmitted = 'FUND_RELEASE_REQUEST_SUBMITTED',
    FundReleaseRequestUpdated = 'FUND_RELEASE_REQUEST_UPDATED',
    FundReleaseRequestApprovedByFinance = 'FUND_RELEASE_REQUEST_APPROVED_BY_FINANCE',
    FundReleaseRequestApprovedByBroker = 'FUND_RELEASE_REQUEST_APPROVED_BY_BROKER',
    FundReleaseRequestCancelled = 'FUND_RELEASE_REQUEST_CANCELLED',
    FundReleaseRequestRestored = 'FUND_RELEASE_REQUEST_RESTORED',
    FundReleaseRequestReleased = 'FUND_RELEASE_REQUEST_RELEASED',
    FundReleaseRequestUndoApproval = 'FUND_RELEASE_REQUEST_UNDO_APPROVAL',
    FundReleaseRequestReverted = 'FUND_RELEASE_REQUEST_REVERTED',
    FundReleaseRequestAttachmentUploaded = 'FUND_RELEASE_REQUEST_ATTACHMENT_UPLOADED',
    FundReleaseRequestAttachmentReplaced = 'FUND_RELEASE_REQUEST_ATTACHMENT_REPLACED',
    FundReleaseRequestAttachmentDeleted = 'FUND_RELEASE_REQUEST_ATTACHMENT_DELETED',
    OutgoingPaymentSentToPaymentSystem = 'OUTGOING_PAYMENT_SENT_TO_PAYMENT_SYSTEM',
    TeamMembershipUpdated = 'TEAM_MEMBERSHIP_UPDATED',
    TeamLeaderAdded = 'TEAM_LEADER_ADDED',
    TeamLeaderRemoved = 'TEAM_LEADER_REMOVED',
    WalletCreated = 'WALLET_CREATED',
    WalletBankingPartnerApplicationCreated = 'WALLET_BANKING_PARTNER_APPLICATION_CREATED',
    WalletBankingPartnerApplicationSubmitted = 'WALLET_BANKING_PARTNER_APPLICATION_SUBMITTED',
    WalletBankingPartnerApplicationPendingDocuments = 'WALLET_BANKING_PARTNER_APPLICATION_PENDING_DOCUMENTS',
    WalletBankingPartnerApplicationPendingReview = 'WALLET_BANKING_PARTNER_APPLICATION_PENDING_REVIEW',
    WalletBankingPartnerApplicationApproved = 'WALLET_BANKING_PARTNER_APPLICATION_APPROVED',
    WalletBankingPartnerApplicationDenied = 'WALLET_BANKING_PARTNER_APPLICATION_DENIED',
    WalletBankingPartnerApplicationCanceled = 'WALLET_BANKING_PARTNER_APPLICATION_CANCELED',
    WalletBankingPartnershipCreated = 'WALLET_BANKING_PARTNERSHIP_CREATED',
    WalletAccountCreated = 'WALLET_ACCOUNT_CREATED',
    WalletAccountApplicationSubmitted = 'WALLET_ACCOUNT_APPLICATION_SUBMITTED',
    WalletAccountApplicationApproved = 'WALLET_ACCOUNT_APPLICATION_APPROVED',
    WalletAccountApplicationMarkedForReview = 'WALLET_ACCOUNT_APPLICATION_MARKED_FOR_REVIEW',
    WalletAccountApplicationDenied = 'WALLET_ACCOUNT_APPLICATION_DENIED',
    WalletAccountApplicationCanceled = 'WALLET_ACCOUNT_APPLICATION_CANCELED',
    WalletBankCardCreated = 'WALLET_BANK_CARD_CREATED',
    WalletBankCardActivated = 'WALLET_BANK_CARD_ACTIVATED',
    WalletBankCardFrozen = 'WALLET_BANK_CARD_FROZEN',
    WalletBankCardUnfrozen = 'WALLET_BANK_CARD_UNFROZEN',
    WalletBankCardExpired = 'WALLET_BANK_CARD_EXPIRED',
    WalletBankCardLost = 'WALLET_BANK_CARD_LOST',
    WalletBankCardStolen = 'WALLET_BANK_CARD_STOLEN',
    WalletBankCardSuspectedOfFraud = 'WALLET_BANK_CARD_SUSPECTED_OF_FRAUD',
    WalletBankCardClearedOfFraudSuspicion = 'WALLET_BANK_CARD_CLEARED_OF_FRAUD_SUSPICION',
    WalletBankCardClosed = 'WALLET_BANK_CARD_CLOSED',
    WalletBankCardReissued = 'WALLET_BANK_CARD_REISSUED',
    WalletBankCardPinSet = 'WALLET_BANK_CARD_PIN_SET',
    WalletChargeAuthorizationRequested = 'WALLET_CHARGE_AUTHORIZATION_REQUESTED',
    WalletChargeAuthorizationUserApprovalRequested = 'WALLET_CHARGE_AUTHORIZATION_USER_APPROVAL_REQUESTED',
    WalletChargeAuthorizationRequestApprovedByUser = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_APPROVED_BY_USER',
    WalletChargeAuthorizationRequestDeclinedByUser = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_DECLINED_BY_USER',
    WalletChargeAuthorizationRequestApprovedByBankingPartner = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_APPROVED_BY_BANKING_PARTNER',
    WalletChargeAuthorizationRequestDeclinedByBankingPartner = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_DECLINED_BY_BANKING_PARTNER',
    WalletChargeAuthorizationRequestApproved = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_APPROVED',
    WalletChargeAuthorizationRequestDeclined = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_DECLINED',
    WalletBankCardAutoApprovalLimitUpdated = 'WALLET_BANK_CARD_AUTO_APPROVAL_LIMIT_UPDATED',
    WalletBankCardAutoApprovalDailyLimitUpdated = 'WALLET_BANK_CARD_AUTO_APPROVAL_DAILY_LIMIT_UPDATED',
    VoiceCallStarted = 'VOICE_CALL_STARTED',
    VoiceRecordingComplete = 'VOICE_RECORDING_COMPLETE',
    VoiceRecordingTranscribed = 'VOICE_RECORDING_TRANSCRIBED'
}

/**
 * 
 * @export
 * @interface CurrentWealthResponse
 */
export interface CurrentWealthResponse {
    /**
     * 
     * @type {number}
     * @memberof CurrentWealthResponse
     */
    'projectionYears'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CurrentWealthResponse
     */
    'wealthValue': MoneyValue;
}
/**
 * 
 * @export
 * @interface EarningsDetails
 */
export interface EarningsDetails {
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'netCommission': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'individualCommission': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'teamCommission': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'grossCommission': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'preCapShareValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EarningsDetails
     */
    'preCapSppShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'preCapSPPContributionValue': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'preCapMatchingShareValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EarningsDetails
     */
    'preCapSppMatchingShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'combinedPreCapShareValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EarningsDetails
     */
    'combinedPreCapShareCount'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'postCapShareValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EarningsDetails
     */
    'postCapSppShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'postCapSPPContributionValue': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'postCapMatchingShareValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EarningsDetails
     */
    'postCapSppMatchingShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'combinedPostCapShareValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EarningsDetails
     */
    'combinedPostCapShareCount'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'cappingAwardValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EarningsDetails
     */
    'cappingAwardShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'eliteAgentProductionAwardValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EarningsDetails
     */
    'eliteAgentProductionAwardShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'culturalAwardValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EarningsDetails
     */
    'eliteAgentCulturalAwardShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'revShareEarnings': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'attractingAwardValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EarningsDetails
     */
    'referredStockAwardShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'realTitleJointVentureValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EarningsDetails
     */
    'realTitleJointVentureShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'externalSharesValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EarningsDetails
     */
    'externalShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsDetails
     */
    'zeroMoney'?: MoneyValue;
}
/**
 * 
 * @export
 * @interface EarningsOverviewResponse
 */
export interface EarningsOverviewResponse {
    /**
     * 
     * @type {EarningsDetails}
     * @memberof EarningsOverviewResponse
     */
    'oneYearProjections': EarningsDetails;
    /**
     * 
     * @type {EarningsDetails}
     * @memberof EarningsOverviewResponse
     */
    'terminalYearProjections': EarningsDetails;
    /**
     * 
     * @type {EarningsDetails}
     * @memberof EarningsOverviewResponse
     */
    'earningsToDate': EarningsDetails;
    /**
     * 
     * @type {EarningsDetails}
     * @memberof EarningsOverviewResponse
     */
    'earningsThisCapCycle': EarningsDetails;
    /**
     * 
     * @type {EarningsDetails}
     * @memberof EarningsOverviewResponse
     */
    'remainderOfCycleProjections': EarningsDetails;
    /**
     * 
     * @type {EarningsDetails}
     * @memberof EarningsOverviewResponse
     */
    'netEarningsPlusProjections': EarningsDetails;
    /**
     * 
     * @type {number}
     * @memberof EarningsOverviewResponse
     */
    'projectionYears'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsOverviewResponse
     */
    'goalValue': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsOverviewResponse
     */
    'projectedEarningsValue': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EarningsOverviewResponse
     */
    'currentWealthValue': MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof EarningsOverviewResponse
     */
    'eliteAgentProductionAwardParticipation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EarningsOverviewResponse
     */
    'eliteAgentCulturalAwardParticipation'?: boolean;
}
/**
 * 
 * @export
 * @interface EliteAgentCulturalEarningsResponse
 */
export interface EliteAgentCulturalEarningsResponse {
    /**
     * 
     * @type {Array<CommissionGraphDataPoint>}
     * @memberof EliteAgentCulturalEarningsResponse
     */
    'fiveYearGraphData'?: Array<CommissionGraphDataPoint>;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteAgentCulturalEarningsResponse
     */
    'projectedEliteAgentCulturalAwardValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EliteAgentCulturalEarningsResponse
     */
    'projectedEliteAgentCulturalAwardShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteAgentCulturalEarningsResponse
     */
    'currentEliteAgentCulturalAwardValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EliteAgentCulturalEarningsResponse
     */
    'currentEliteAgentCulturalAwardShares'?: number;
    /**
     * 
     * @type {number}
     * @memberof EliteAgentCulturalEarningsResponse
     */
    'currentCycleShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteAgentCulturalEarningsResponse
     */
    'currentCycleValue': MoneyValue;
}
/**
 * 
 * @export
 * @interface EliteAgentProductionEarningsResponse
 */
export interface EliteAgentProductionEarningsResponse {
    /**
     * 
     * @type {Array<CommissionGraphDataPoint>}
     * @memberof EliteAgentProductionEarningsResponse
     */
    'fiveYearGraphData': Array<CommissionGraphDataPoint>;
    /**
     * 
     * @type {number}
     * @memberof EliteAgentProductionEarningsResponse
     */
    'currentEliteAgentProductionAward'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteAgentProductionEarningsResponse
     */
    'projectedEliteAgentProductionAwardValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EliteAgentProductionEarningsResponse
     */
    'projectedEliteAgentProductionAwardShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteAgentProductionEarningsResponse
     */
    'currentYearEliteAgentProductionAwardValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EliteAgentProductionEarningsResponse
     */
    'currentYearEliteAgentProductionAwardShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteAgentProductionEarningsResponse
     */
    'currentEliteAgentProductionAwardValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EliteAgentProductionEarningsResponse
     */
    'currentEliteAgentProductionAwardShares'?: number;
}
/**
 * 
 * @export
 * @interface FailedMessage
 */
export interface FailedMessage {
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'clientType': FailedMessageClientTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'status': FailedMessageStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'reprocessingAttempts'?: number;
    /**
     * 
     * @type {KafkaMessageLocation}
     * @memberof FailedMessage
     */
    'kafkaMessageLocation': KafkaMessageLocation;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'key'?: string;
    /**
     * 
     * @type {WrapperBaseMessage}
     * @memberof FailedMessage
     */
    'payload'?: WrapperBaseMessage;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'unprocessed'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FailedMessage
     */
    'processed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'shortenedId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageClientTypeEnum {
    Producer = 'PRODUCER',
    Consumer = 'CONSUMER'
}
/**
    * @export
    * @enum {string}
    */
export enum FailedMessageStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FailedMessageRetryRequest
 */
export interface FailedMessageRetryRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageRetryRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface FailedMessageUpdateStatusRequest
 */
export interface FailedMessageUpdateStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'status'?: FailedMessageUpdateStatusRequestStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageUpdateStatusRequestStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FailedMessagesCriteriaRequest
 */
export interface FailedMessagesCriteriaRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtTo'?: string;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseFailedMessage
 */
export interface GenericSearchResponseFailedMessage {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseFailedMessage
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortDirection'?: GenericSearchResponseFailedMessageSortDirectionEnum;
    /**
     * 
     * @type {Array<FailedMessage>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'results'?: Array<FailedMessage>;
}

/**
    * @export
    * @enum {string}
    */
export enum GenericSearchResponseFailedMessageSortDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GetCommsPreferencesResponse
 */
export interface GetCommsPreferencesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetCommsPreferencesResponse
     */
    'enableEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetCommsPreferencesResponse
     */
    'enableNotifications'?: boolean;
}
/**
 * 
 * @export
 * @interface GoalDetails
 */
export interface GoalDetails {
    /**
     * 
     * @type {string}
     * @memberof GoalDetails
     */
    'planId': string;
    /**
     * 
     * @type {number}
     * @memberof GoalDetails
     */
    'projectionYears'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof GoalDetails
     */
    'earningsGoalValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof GoalDetails
     */
    'transactionsPerYear'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof GoalDetails
     */
    'averageHomeSalePrice'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof GoalDetails
     */
    'volumePerYear'?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof GoalDetails
     */
    'sppParticipation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GoalDetails
     */
    'revShareParticipation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GoalDetails
     */
    'agentReferredPerYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof GoalDetails
     */
    'agentsAgentReferredPerYear'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GoalDetails
     */
    'realTitleJVParticipation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GoalDetails
     */
    'realTitleJVShares'?: number;
    /**
     * 
     * @type {number}
     * @memberof GoalDetails
     */
    'additionalRealShares'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GoalDetails
     */
    'eliteAgentProductionAwardParticipation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GoalDetails
     */
    'eliteAgentCulturalAwardParticipation'?: boolean;
    /**
     * 
     * @type {MoneyValue}
     * @memberof GoalDetails
     */
    'realStockFuturePrice': MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof GoalDetails
     */
    'planStartDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof GoalDetails
     */
    'seenEliteProductionPage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GoalDetails
     */
    'seenEliteCulturalPage'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GoalDetails
     */
    'averageTransactionCommissionPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof GoalDetails
     */
    'teamNumberCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GoalDetails
     */
    'teamTransactionsPerMember'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof GoalDetails
     */
    'teamAverageSalesPrice'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof GoalDetails
     */
    'teamLeaderCommissionPercentagePerTransaction'?: number;
    /**
     * 
     * @type {number}
     * @memberof GoalDetails
     */
    'teamAverageTransactionCommissionPercentage'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GoalDetails
     */
    'teamUseAverageFixedCommission'?: boolean;
    /**
     * 
     * @type {MoneyValue}
     * @memberof GoalDetails
     */
    'teamAverageFixedCommission'?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof GoalDetails
     */
    'teamLead'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GoalDetails
     */
    'useAverageFixedCommission'?: boolean;
    /**
     * 
     * @type {MoneyValue}
     * @memberof GoalDetails
     */
    'averageFixedCommission'?: MoneyValue;
    /**
     * 
     * @type {AppConfig}
     * @memberof GoalDetails
     */
    'nullOptionalValuesToDefaultValues'?: AppConfig;
}
/**
 * 
 * @export
 * @interface KafkaMessageLocation
 */
export interface KafkaMessageLocation {
    /**
     * 
     * @type {string}
     * @memberof KafkaMessageLocation
     */
    'topic': string;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'offset'?: number;
}
/**
 * 
 * @export
 * @interface MoneyValue
 */
export interface MoneyValue {
    /**
     * 
     * @type {number}
     * @memberof MoneyValue
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MoneyValue
     */
    'currency'?: MoneyValueCurrencyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MoneyValueCurrencyEnum {
    Usd = 'USD',
    Cad = 'CAD'
}

/**
 * 
 * @export
 * @interface PostCapSppEarningsResponse
 */
export interface PostCapSppEarningsResponse {
    /**
     * 
     * @type {MoneyValue}
     * @memberof PostCapSppEarningsResponse
     */
    'projectedPostCapSppValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof PostCapSppEarningsResponse
     */
    'projectedPostCapSppShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof PostCapSppEarningsResponse
     */
    'currentPostCapSppValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof PostCapSppEarningsResponse
     */
    'currentPostCapSppShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof PostCapSppEarningsResponse
     */
    'matchingGrantValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof PostCapSppEarningsResponse
     */
    'matchingGrantShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof PostCapSppEarningsResponse
     */
    'projectedPurchasePostCapSppValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof PostCapSppEarningsResponse
     */
    'projectedPurchasePostCapSppShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof PostCapSppEarningsResponse
     */
    'projectedMatchingPostCapSppValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof PostCapSppEarningsResponse
     */
    'projectedMatchingPostCapSppShares'?: number;
}
/**
 * 
 * @export
 * @interface PreCapSPPEarningsResponse
 */
export interface PreCapSPPEarningsResponse {
    /**
     * 
     * @type {number}
     * @memberof PreCapSPPEarningsResponse
     */
    'projectedPreCapSppShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof PreCapSPPEarningsResponse
     */
    'projectedPreCapSppValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof PreCapSPPEarningsResponse
     */
    'currentPreCapSpp'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof PreCapSPPEarningsResponse
     */
    'matchingGrantsValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof PreCapSPPEarningsResponse
     */
    'matchingGrantsShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof PreCapSPPEarningsResponse
     */
    'projectedVolumePerYear': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof PreCapSPPEarningsResponse
     */
    'projectedPurchasePreCapSppValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof PreCapSPPEarningsResponse
     */
    'projectedPurchasePreCapSppShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof PreCapSPPEarningsResponse
     */
    'projectedMatchingPreCapSppValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof PreCapSPPEarningsResponse
     */
    'projectedMatchingPreCapSppShares'?: number;
}
/**
 * 
 * @export
 * @interface ProspectiveAgentInfo
 */
export interface ProspectiveAgentInfo {
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentInfo
     */
    'referralId': string;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentInfo
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentInfo
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentInfo
     */
    'brokerageName': string;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentInfo
     */
    'country': ProspectiveAgentInfoCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentInfo
     */
    'referringAgentFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentInfo
     */
    'referringAgentLastName': string;
}

/**
    * @export
    * @enum {string}
    */
export enum ProspectiveAgentInfoCountryEnum {
    UnitedStates = 'UNITED_STATES',
    Canada = 'CANADA'
}

/**
 * 
 * @export
 * @interface ProspectiveAgentWealthPlanDto
 */
export interface ProspectiveAgentWealthPlanDto {
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'referralId': string;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'referringAgentId': string;
    /**
     * 
     * @type {WealthPlanDetailDto}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'wealthPlanDetailEntity': WealthPlanDetailDto;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'brokerageName': string;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'emailSubject': string;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'emailMessage': string;
    /**
     * 
     * @type {number}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'openEmailAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'wealthPlanLoadedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'mostRecentOpenDateAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'emailSentTimeAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'lastUpdatedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'emailOpenedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'planOpenedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'planEditedCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProspectiveAgentWealthPlanDto
     */
    'firstAndLastName'?: string;
}
/**
 * 
 * @export
 * @interface ProspectiveReferralRateLimitInfo
 */
export interface ProspectiveReferralRateLimitInfo {
    /**
     * 
     * @type {number}
     * @memberof ProspectiveReferralRateLimitInfo
     */
    'referralsPerMonthLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProspectiveReferralRateLimitInfo
     */
    'numberOfReferralsLeftThisMonth'?: number;
}
/**
 * 
 * @export
 * @interface RealTitleJointVentureEarningsResponse
 */
export interface RealTitleJointVentureEarningsResponse {
    /**
     * 
     * @type {MoneyValue}
     * @memberof RealTitleJointVentureEarningsResponse
     */
    'projectedValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof RealTitleJointVentureEarningsResponse
     */
    'projectedShares'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RealTitleJointVentureEarningsResponse
     */
    'currentRealTitleJVValue': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof RealTitleJointVentureEarningsResponse
     */
    'payoutPerShare'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RealTitleJointVentureEarningsResponse
     */
    'totalAnnualPayout': MoneyValue;
}
/**
 * 
 * @export
 * @interface ReferralsListResponse
 */
export interface ReferralsListResponse {
    /**
     * 
     * @type {number}
     * @memberof ReferralsListResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralsListResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {Array<ProspectiveAgentWealthPlanDto>}
     * @memberof ReferralsListResponse
     */
    'pageOfRecords': Array<ProspectiveAgentWealthPlanDto>;
}
/**
 * 
 * @export
 * @interface RevenueShareEarningsResponse
 */
export interface RevenueShareEarningsResponse {
    /**
     * 
     * @type {Array<CommissionGraphDataPoint>}
     * @memberof RevenueShareEarningsResponse
     */
    'fiveYearGraphData'?: Array<CommissionGraphDataPoint>;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevenueShareEarningsResponse
     */
    'projectedPaidEarnings': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevenueShareEarningsResponse
     */
    'currentPaidEarnings': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareEarningsResponse
     */
    'agentsInMyNetwork'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareEarningsResponse
     */
    'currentTier'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareEarningsResponse
     */
    'neededAgentsForNextTier'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareEarningsResponse
     */
    'tier1Agents'?: number;
}
/**
 * 
 * @export
 * @interface SetCommsPreferencesRequest
 */
export interface SetCommsPreferencesRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SetCommsPreferencesRequest
     */
    'enableEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SetCommsPreferencesRequest
     */
    'enableNotifications'?: boolean;
}
/**
 * 
 * @export
 * @interface StockPriceDetails
 */
export interface StockPriceDetails {
    /**
     * 
     * @type {MoneyValue}
     * @memberof StockPriceDetails
     */
    'realStockPrice': MoneyValue;
}
/**
 * 
 * @export
 * @interface SubmitProspectiveAgentReferralRequest
 */
export interface SubmitProspectiveAgentReferralRequest {
    /**
     * 
     * @type {GoalDetails}
     * @memberof SubmitProspectiveAgentReferralRequest
     */
    'goalDetails': GoalDetails;
    /**
     * 
     * @type {string}
     * @memberof SubmitProspectiveAgentReferralRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitProspectiveAgentReferralRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitProspectiveAgentReferralRequest
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitProspectiveAgentReferralRequest
     */
    'brokerageName': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitProspectiveAgentReferralRequest
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitProspectiveAgentReferralRequest
     */
    'emailSubject': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitProspectiveAgentReferralRequest
     */
    'emailMessage': string;
}
/**
 * 
 * @export
 * @interface SubscriptionDto
 */
export interface SubscriptionDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'eventName'?: SubscriptionDtoEventNameEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SubscriptionDtoEventNameEnum {
    AgentAnniversaryUpdated = 'AGENT_ANNIVERSARY_UPDATED',
    AgentNationalIdentificationUpdated = 'AGENT_NATIONAL_IDENTIFICATION_UPDATED',
    AgentJoinedTeam = 'AGENT_JOINED_TEAM',
    AgentLeftTeam = 'AGENT_LEFT_TEAM',
    AgentSwitchedTeams = 'AGENT_SWITCHED_TEAMS',
    AgentTerminated = 'AGENT_TERMINATED',
    AgentPaymentDetailsInvalidUpdated = 'AGENT_PAYMENT_DETAILS_INVALID_UPDATED',
    AgentSingleCheckPreferenceUpdated = 'AGENT_SINGLE_CHECK_PREFERENCE_UPDATED',
    AgentActivated = 'AGENT_ACTIVATED',
    AgentEmailUpdated = 'AGENT_EMAIL_UPDATED',
    AgentReachedElite = 'AGENT_REACHED_ELITE',
    AgentCapped = 'AGENT_CAPPED',
    AgentApplicationStatusUpdated = 'AGENT_APPLICATION_STATUS_UPDATED',
    TransactionCreated = 'TRANSACTION_CREATED',
    CommissionValidated = 'COMMISSION_VALIDATED',
    TransactionApprovedClosing = 'TRANSACTION_APPROVED_CLOSING',
    TransactionPaid = 'TRANSACTION_PAID',
    TransactionSettled = 'TRANSACTION_SETTLED',
    TransactionClosed = 'TRANSACTION_CLOSED',
    TransactionPaymentAccepted = 'TRANSACTION_PAYMENT_ACCEPTED',
    TransactionPaymentScheduled = 'TRANSACTION_PAYMENT_SCHEDULED',
    DepositReceived = 'DEPOSIT_RECEIVED',
    TransactionApprovedForCdGeneration = 'TRANSACTION_APPROVED_FOR_CD_GENERATION',
    CommissionDocumentApproved = 'COMMISSION_DOCUMENT_APPROVED',
    CommissionPayerAssigned = 'COMMISSION_PAYER_ASSIGNED',
    CommissionDocumentGenerated = 'COMMISSION_DOCUMENT_GENERATED',
    CommissionDocumentSent = 'COMMISSION_DOCUMENT_SENT',
    CommissionDocumentUpdated = 'COMMISSION_DOCUMENT_UPDATED',
    ConveyanceValidatedEvent = 'CONVEYANCE_VALIDATED_EVENT',
    ConveyanceInvalidatedEvent = 'CONVEYANCE_INVALIDATED_EVENT',
    DepositConfirmed = 'DEPOSIT_CONFIRMED',
    TrustDepositSubmitted = 'TRUST_DEPOSIT_SUBMITTED',
    TrustDepositSubmittedInFull = 'TRUST_DEPOSIT_SUBMITTED_IN_FULL',
    TrustDepositConfirmedInFull = 'TRUST_DEPOSIT_CONFIRMED_IN_FULL',
    TrustDepositInstallmentSubmitted = 'TRUST_DEPOSIT_INSTALLMENT_SUBMITTED',
    TrustDepositInstallmentConfirmed = 'TRUST_DEPOSIT_INSTALLMENT_CONFIRMED',
    CommissionDepositSubmitted = 'COMMISSION_DEPOSIT_SUBMITTED',
    CommissionDepositSubmittedInFull = 'COMMISSION_DEPOSIT_SUBMITTED_IN_FULL',
    CommissionDepositConfirmedInFull = 'COMMISSION_DEPOSIT_CONFIRMED_IN_FULL',
    CommissionDepositInstallmentSubmitted = 'COMMISSION_DEPOSIT_INSTALLMENT_SUBMITTED',
    CommissionDepositInstallmentConfirmed = 'COMMISSION_DEPOSIT_INSTALLMENT_CONFIRMED',
    ExcessFundsReleased = 'EXCESS_FUNDS_RELEASED',
    FundsReleaseReverted = 'FUNDS_RELEASE_REVERTED',
    TransactionTerminationRequested = 'TRANSACTION_TERMINATION_REQUESTED',
    TransactionTerminated = 'TRANSACTION_TERMINATED',
    TransactionParticipantAdded = 'TRANSACTION_PARTICIPANT_ADDED',
    TransactionParticipantUpdated = 'TRANSACTION_PARTICIPANT_UPDATED',
    TransactionParticipantRemoved = 'TRANSACTION_PARTICIPANT_REMOVED',
    TransactionFeeAdded = 'TRANSACTION_FEE_ADDED',
    TransactionFeeRemoved = 'TRANSACTION_FEE_REMOVED',
    TransactionAddressUpdated = 'TRANSACTION_ADDRESS_UPDATED',
    TransactionYearBuiltUpdated = 'TRANSACTION_YEAR_BUILT_UPDATED',
    TransactionMlsNumberUpdated = 'TRANSACTION_MLS_NUMBER_UPDATED',
    TransactionEscrowNumberUpdated = 'TRANSACTION_ESCROW_NUMBER_UPDATED',
    TransactionSalePriceUpdated = 'TRANSACTION_SALE_PRICE_UPDATED',
    TransactionListingCommissionUpdated = 'TRANSACTION_LISTING_COMMISSION_UPDATED',
    TransactionSaleCommissionUpdated = 'TRANSACTION_SALE_COMMISSION_UPDATED',
    TransactionContractAcceptanceDateUpdated = 'TRANSACTION_CONTRACT_ACCEPTANCE_DATE_UPDATED',
    TransactionActualClosingDateUpdated = 'TRANSACTION_ACTUAL_CLOSING_DATE_UPDATED',
    TransactionEstimatedClosingDateUpdated = 'TRANSACTION_ESTIMATED_CLOSING_DATE_UPDATED',
    TransactionFirmDateUpdated = 'TRANSACTION_FIRM_DATE_UPDATED',
    TransactionMarkedAsCompliant = 'TRANSACTION_MARKED_AS_COMPLIANT',
    TransactionMarkedAsNonCompliant = 'TRANSACTION_MARKED_AS_NON_COMPLIANT',
    DoubleEnderTransactionLinked = 'DOUBLE_ENDER_TRANSACTION_LINKED',
    DoubleEnderTransactionUnlinked = 'DOUBLE_ENDER_TRANSACTION_UNLINKED',
    TransactionInternalReferralUnlinked = 'TRANSACTION_INTERNAL_REFERRAL_UNLINKED',
    TransactionInternalReferralLinked = 'TRANSACTION_INTERNAL_REFERRAL_LINKED',
    TxReminderWaitForApproveCdCalculation = 'TX_REMINDER_WAIT_FOR_APPROVE_CD_CALCULATION',
    TxReminderWaitForSendCd = 'TX_REMINDER_WAIT_FOR_SEND_CD',
    TxReminderListingIsAboutToExpire = 'TX_REMINDER_LISTING_IS_ABOUT_TO_EXPIRE',
    TxReminderListingHasExpired = 'TX_REMINDER_LISTING_HAS_EXPIRED',
    TxReminderIsAboutToClose = 'TX_REMINDER_IS_ABOUT_TO_CLOSE',
    TxReminderHasExpired = 'TX_REMINDER_HAS_EXPIRED',
    TransactionTaxExemptUpdated = 'TRANSACTION_TAX_EXEMPT_UPDATED',
    TransactionTaxPaidFromCommissionUpdated = 'TRANSACTION_TAX_PAID_FROM_COMMISSION_UPDATED',
    TransactionTaxRatesUpdated = 'TRANSACTION_TAX_RATES_UPDATED',
    TransactionTaxAmountUpdated = 'TRANSACTION_TAX_AMOUNT_UPDATED',
    ListingCreated = 'LISTING_CREATED',
    ChecklistCreatedEvent = 'CHECKLIST_CREATED_EVENT',
    ChecklistItemCreated = 'CHECKLIST_ITEM_CREATED',
    ChecklistItemNotStarted = 'CHECKLIST_ITEM_NOT_STARTED',
    ChecklistItemPending = 'CHECKLIST_ITEM_PENDING',
    ChecklistItemWaitingForReview = 'CHECKLIST_ITEM_WAITING_FOR_REVIEW',
    ChecklistItemInReview = 'CHECKLIST_ITEM_IN_REVIEW',
    ChecklistItemRevisionRequested = 'CHECKLIST_ITEM_REVISION_REQUESTED',
    ChecklistItemAccepted = 'CHECKLIST_ITEM_ACCEPTED',
    ChecklistItemDeleted = 'CHECKLIST_ITEM_DELETED',
    ChecklistItemStatusUpdated = 'CHECKLIST_ITEM_STATUS_UPDATED',
    ChecklistItemNameUpdated = 'CHECKLIST_ITEM_NAME_UPDATED',
    ChecklistItemRequiredStatusChanged = 'CHECKLIST_ITEM_REQUIRED_STATUS_CHANGED',
    ChecklistItemDescriptionUpdated = 'CHECKLIST_ITEM_DESCRIPTION_UPDATED',
    ChecklistItemAssigneeUpdated = 'CHECKLIST_ITEM_ASSIGNEE_UPDATED',
    ChecklistItemDueDateUpdated = 'CHECKLIST_ITEM_DUE_DATE_UPDATED',
    ChecklistItemUrgentStatusUpdated = 'CHECKLIST_ITEM_URGENT_STATUS_UPDATED',
    ChecklistItemRequiredStatusUpdated = 'CHECKLIST_ITEM_REQUIRED_STATUS_UPDATED',
    ChecklistItemLabelAddedEvent = 'CHECKLIST_ITEM_LABEL_ADDED_EVENT',
    ChecklistItemLabelRemovedEvent = 'CHECKLIST_ITEM_LABEL_REMOVED_EVENT',
    ChecklistItemFileUploaded = 'CHECKLIST_ITEM_FILE_UPLOADED',
    ChecklistItemFileVersionUploaded = 'CHECKLIST_ITEM_FILE_VERSION_UPLOADED',
    ChecklistItemFileDeleted = 'CHECKLIST_ITEM_FILE_DELETED',
    ChecklistItemFileReferenceAttached = 'CHECKLIST_ITEM_FILE_REFERENCE_ATTACHED',
    ChecklistItemFileReferenceRemoved = 'CHECKLIST_ITEM_FILE_REFERENCE_REMOVED',
    ChecklistItemAssigned = 'CHECKLIST_ITEM_ASSIGNED',
    ChecklistItemUnassigned = 'CHECKLIST_ITEM_UNASSIGNED',
    JourneyItemCompleted = 'JOURNEY_ITEM_COMPLETED',
    JourneyItemIncomplete = 'JOURNEY_ITEM_INCOMPLETE',
    EmailReceivedEvent = 'EMAIL_RECEIVED_EVENT',
    OfficePaymentSystemUpdated = 'OFFICE_PAYMENT_SYSTEM_UPDATED',
    OfficePaymentBatchUpdated = 'OFFICE_PAYMENT_BATCH_UPDATED',
    OfficeRealWithholdsTaxUpdated = 'OFFICE_REAL_WITHHOLDS_TAX_UPDATED',
    OfficeTaxesUpdated = 'OFFICE_TAXES_UPDATED',
    OfficeTaxPaidFromCommissionUpdated = 'OFFICE_TAX_PAID_FROM_COMMISSION_UPDATED',
    MortgageLeadCreated = 'MORTGAGE_LEAD_CREATED',
    MortgageBorrowerInvited = 'MORTGAGE_BORROWER_INVITED',
    MortgageCoBorrowerAdded = 'MORTGAGE_CO_BORROWER_ADDED',
    MortgageCoBorrowerInvited = 'MORTGAGE_CO_BORROWER_INVITED',
    MortgageApplicationStarted = 'MORTGAGE_APPLICATION_STARTED',
    MortgageApplicationSubmitted = 'MORTGAGE_APPLICATION_SUBMITTED',
    MortgageLoanPreApproved = 'MORTGAGE_LOAN_PRE_APPROVED',
    DropboxCreatedEvent = 'DROPBOX_CREATED_EVENT',
    DropboxFileCreatedEvent = 'DROPBOX_FILE_CREATED_EVENT',
    DropboxFileVersionAddedEvent = 'DROPBOX_FILE_VERSION_ADDED_EVENT',
    DropboxFileDeletedEvent = 'DROPBOX_FILE_DELETED_EVENT',
    DropboxFileMovedToTrashEvent = 'DROPBOX_FILE_MOVED_TO_TRASH_EVENT',
    DropboxFileRestoredFromTrashEvent = 'DROPBOX_FILE_RESTORED_FROM_TRASH_EVENT',
    DropboxFileNameUpdatedEvent = 'DROPBOX_FILE_NAME_UPDATED_EVENT',
    CommentCreated = 'COMMENT_CREATED',
    CommentEdited = 'COMMENT_EDITED',
    CommentMediaUploaded = 'COMMENT_MEDIA_UPLOADED',
    CommentAudioTranscribed = 'COMMENT_AUDIO_TRANSCRIBED',
    WebhookEndpointTestEvent = 'WEBHOOK_ENDPOINT_TEST_EVENT',
    FundReleaseRequestSubmitted = 'FUND_RELEASE_REQUEST_SUBMITTED',
    FundReleaseRequestUpdated = 'FUND_RELEASE_REQUEST_UPDATED',
    FundReleaseRequestApprovedByFinance = 'FUND_RELEASE_REQUEST_APPROVED_BY_FINANCE',
    FundReleaseRequestApprovedByBroker = 'FUND_RELEASE_REQUEST_APPROVED_BY_BROKER',
    FundReleaseRequestCancelled = 'FUND_RELEASE_REQUEST_CANCELLED',
    FundReleaseRequestRestored = 'FUND_RELEASE_REQUEST_RESTORED',
    FundReleaseRequestReleased = 'FUND_RELEASE_REQUEST_RELEASED',
    FundReleaseRequestUndoApproval = 'FUND_RELEASE_REQUEST_UNDO_APPROVAL',
    FundReleaseRequestReverted = 'FUND_RELEASE_REQUEST_REVERTED',
    FundReleaseRequestAttachmentUploaded = 'FUND_RELEASE_REQUEST_ATTACHMENT_UPLOADED',
    FundReleaseRequestAttachmentReplaced = 'FUND_RELEASE_REQUEST_ATTACHMENT_REPLACED',
    FundReleaseRequestAttachmentDeleted = 'FUND_RELEASE_REQUEST_ATTACHMENT_DELETED',
    OutgoingPaymentSentToPaymentSystem = 'OUTGOING_PAYMENT_SENT_TO_PAYMENT_SYSTEM',
    TeamMembershipUpdated = 'TEAM_MEMBERSHIP_UPDATED',
    TeamLeaderAdded = 'TEAM_LEADER_ADDED',
    TeamLeaderRemoved = 'TEAM_LEADER_REMOVED',
    WalletCreated = 'WALLET_CREATED',
    WalletBankingPartnerApplicationCreated = 'WALLET_BANKING_PARTNER_APPLICATION_CREATED',
    WalletBankingPartnerApplicationSubmitted = 'WALLET_BANKING_PARTNER_APPLICATION_SUBMITTED',
    WalletBankingPartnerApplicationPendingDocuments = 'WALLET_BANKING_PARTNER_APPLICATION_PENDING_DOCUMENTS',
    WalletBankingPartnerApplicationPendingReview = 'WALLET_BANKING_PARTNER_APPLICATION_PENDING_REVIEW',
    WalletBankingPartnerApplicationApproved = 'WALLET_BANKING_PARTNER_APPLICATION_APPROVED',
    WalletBankingPartnerApplicationDenied = 'WALLET_BANKING_PARTNER_APPLICATION_DENIED',
    WalletBankingPartnerApplicationCanceled = 'WALLET_BANKING_PARTNER_APPLICATION_CANCELED',
    WalletBankingPartnershipCreated = 'WALLET_BANKING_PARTNERSHIP_CREATED',
    WalletAccountCreated = 'WALLET_ACCOUNT_CREATED',
    WalletAccountApplicationSubmitted = 'WALLET_ACCOUNT_APPLICATION_SUBMITTED',
    WalletAccountApplicationApproved = 'WALLET_ACCOUNT_APPLICATION_APPROVED',
    WalletAccountApplicationMarkedForReview = 'WALLET_ACCOUNT_APPLICATION_MARKED_FOR_REVIEW',
    WalletAccountApplicationDenied = 'WALLET_ACCOUNT_APPLICATION_DENIED',
    WalletAccountApplicationCanceled = 'WALLET_ACCOUNT_APPLICATION_CANCELED',
    WalletBankCardCreated = 'WALLET_BANK_CARD_CREATED',
    WalletBankCardActivated = 'WALLET_BANK_CARD_ACTIVATED',
    WalletBankCardFrozen = 'WALLET_BANK_CARD_FROZEN',
    WalletBankCardUnfrozen = 'WALLET_BANK_CARD_UNFROZEN',
    WalletBankCardExpired = 'WALLET_BANK_CARD_EXPIRED',
    WalletBankCardLost = 'WALLET_BANK_CARD_LOST',
    WalletBankCardStolen = 'WALLET_BANK_CARD_STOLEN',
    WalletBankCardSuspectedOfFraud = 'WALLET_BANK_CARD_SUSPECTED_OF_FRAUD',
    WalletBankCardClearedOfFraudSuspicion = 'WALLET_BANK_CARD_CLEARED_OF_FRAUD_SUSPICION',
    WalletBankCardClosed = 'WALLET_BANK_CARD_CLOSED',
    WalletBankCardReissued = 'WALLET_BANK_CARD_REISSUED',
    WalletBankCardPinSet = 'WALLET_BANK_CARD_PIN_SET',
    WalletChargeAuthorizationRequested = 'WALLET_CHARGE_AUTHORIZATION_REQUESTED',
    WalletChargeAuthorizationUserApprovalRequested = 'WALLET_CHARGE_AUTHORIZATION_USER_APPROVAL_REQUESTED',
    WalletChargeAuthorizationRequestApprovedByUser = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_APPROVED_BY_USER',
    WalletChargeAuthorizationRequestDeclinedByUser = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_DECLINED_BY_USER',
    WalletChargeAuthorizationRequestApprovedByBankingPartner = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_APPROVED_BY_BANKING_PARTNER',
    WalletChargeAuthorizationRequestDeclinedByBankingPartner = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_DECLINED_BY_BANKING_PARTNER',
    WalletChargeAuthorizationRequestApproved = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_APPROVED',
    WalletChargeAuthorizationRequestDeclined = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_DECLINED',
    WalletBankCardAutoApprovalLimitUpdated = 'WALLET_BANK_CARD_AUTO_APPROVAL_LIMIT_UPDATED',
    WalletBankCardAutoApprovalDailyLimitUpdated = 'WALLET_BANK_CARD_AUTO_APPROVAL_DAILY_LIMIT_UPDATED',
    VoiceCallStarted = 'VOICE_CALL_STARTED',
    VoiceRecordingComplete = 'VOICE_RECORDING_COMPLETE',
    VoiceRecordingTranscribed = 'VOICE_RECORDING_TRANSCRIBED'
}

/**
 * 
 * @export
 * @interface UpdateFailedMessageStatusBatch
 */
export interface UpdateFailedMessageStatusBatch {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'status': UpdateFailedMessageStatusBatchStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateFailedMessageStatusBatchStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface UpdateWebhookRequest
 */
export interface UpdateWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateWebhookRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWebhookRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWebhookRequest
     */
    'url': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateWebhookRequest
     */
    'eventNames': Array<UpdateWebhookRequestEventNamesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateWebhookRequestEventNamesEnum {
    AgentAnniversaryUpdated = 'AGENT_ANNIVERSARY_UPDATED',
    AgentNationalIdentificationUpdated = 'AGENT_NATIONAL_IDENTIFICATION_UPDATED',
    AgentJoinedTeam = 'AGENT_JOINED_TEAM',
    AgentLeftTeam = 'AGENT_LEFT_TEAM',
    AgentSwitchedTeams = 'AGENT_SWITCHED_TEAMS',
    AgentTerminated = 'AGENT_TERMINATED',
    AgentPaymentDetailsInvalidUpdated = 'AGENT_PAYMENT_DETAILS_INVALID_UPDATED',
    AgentSingleCheckPreferenceUpdated = 'AGENT_SINGLE_CHECK_PREFERENCE_UPDATED',
    AgentActivated = 'AGENT_ACTIVATED',
    AgentEmailUpdated = 'AGENT_EMAIL_UPDATED',
    AgentReachedElite = 'AGENT_REACHED_ELITE',
    AgentCapped = 'AGENT_CAPPED',
    AgentApplicationStatusUpdated = 'AGENT_APPLICATION_STATUS_UPDATED',
    TransactionCreated = 'TRANSACTION_CREATED',
    CommissionValidated = 'COMMISSION_VALIDATED',
    TransactionApprovedClosing = 'TRANSACTION_APPROVED_CLOSING',
    TransactionPaid = 'TRANSACTION_PAID',
    TransactionSettled = 'TRANSACTION_SETTLED',
    TransactionClosed = 'TRANSACTION_CLOSED',
    TransactionPaymentAccepted = 'TRANSACTION_PAYMENT_ACCEPTED',
    TransactionPaymentScheduled = 'TRANSACTION_PAYMENT_SCHEDULED',
    DepositReceived = 'DEPOSIT_RECEIVED',
    TransactionApprovedForCdGeneration = 'TRANSACTION_APPROVED_FOR_CD_GENERATION',
    CommissionDocumentApproved = 'COMMISSION_DOCUMENT_APPROVED',
    CommissionPayerAssigned = 'COMMISSION_PAYER_ASSIGNED',
    CommissionDocumentGenerated = 'COMMISSION_DOCUMENT_GENERATED',
    CommissionDocumentSent = 'COMMISSION_DOCUMENT_SENT',
    CommissionDocumentUpdated = 'COMMISSION_DOCUMENT_UPDATED',
    ConveyanceValidatedEvent = 'CONVEYANCE_VALIDATED_EVENT',
    ConveyanceInvalidatedEvent = 'CONVEYANCE_INVALIDATED_EVENT',
    DepositConfirmed = 'DEPOSIT_CONFIRMED',
    TrustDepositSubmitted = 'TRUST_DEPOSIT_SUBMITTED',
    TrustDepositSubmittedInFull = 'TRUST_DEPOSIT_SUBMITTED_IN_FULL',
    TrustDepositConfirmedInFull = 'TRUST_DEPOSIT_CONFIRMED_IN_FULL',
    TrustDepositInstallmentSubmitted = 'TRUST_DEPOSIT_INSTALLMENT_SUBMITTED',
    TrustDepositInstallmentConfirmed = 'TRUST_DEPOSIT_INSTALLMENT_CONFIRMED',
    CommissionDepositSubmitted = 'COMMISSION_DEPOSIT_SUBMITTED',
    CommissionDepositSubmittedInFull = 'COMMISSION_DEPOSIT_SUBMITTED_IN_FULL',
    CommissionDepositConfirmedInFull = 'COMMISSION_DEPOSIT_CONFIRMED_IN_FULL',
    CommissionDepositInstallmentSubmitted = 'COMMISSION_DEPOSIT_INSTALLMENT_SUBMITTED',
    CommissionDepositInstallmentConfirmed = 'COMMISSION_DEPOSIT_INSTALLMENT_CONFIRMED',
    ExcessFundsReleased = 'EXCESS_FUNDS_RELEASED',
    FundsReleaseReverted = 'FUNDS_RELEASE_REVERTED',
    TransactionTerminationRequested = 'TRANSACTION_TERMINATION_REQUESTED',
    TransactionTerminated = 'TRANSACTION_TERMINATED',
    TransactionParticipantAdded = 'TRANSACTION_PARTICIPANT_ADDED',
    TransactionParticipantUpdated = 'TRANSACTION_PARTICIPANT_UPDATED',
    TransactionParticipantRemoved = 'TRANSACTION_PARTICIPANT_REMOVED',
    TransactionFeeAdded = 'TRANSACTION_FEE_ADDED',
    TransactionFeeRemoved = 'TRANSACTION_FEE_REMOVED',
    TransactionAddressUpdated = 'TRANSACTION_ADDRESS_UPDATED',
    TransactionYearBuiltUpdated = 'TRANSACTION_YEAR_BUILT_UPDATED',
    TransactionMlsNumberUpdated = 'TRANSACTION_MLS_NUMBER_UPDATED',
    TransactionEscrowNumberUpdated = 'TRANSACTION_ESCROW_NUMBER_UPDATED',
    TransactionSalePriceUpdated = 'TRANSACTION_SALE_PRICE_UPDATED',
    TransactionListingCommissionUpdated = 'TRANSACTION_LISTING_COMMISSION_UPDATED',
    TransactionSaleCommissionUpdated = 'TRANSACTION_SALE_COMMISSION_UPDATED',
    TransactionContractAcceptanceDateUpdated = 'TRANSACTION_CONTRACT_ACCEPTANCE_DATE_UPDATED',
    TransactionActualClosingDateUpdated = 'TRANSACTION_ACTUAL_CLOSING_DATE_UPDATED',
    TransactionEstimatedClosingDateUpdated = 'TRANSACTION_ESTIMATED_CLOSING_DATE_UPDATED',
    TransactionFirmDateUpdated = 'TRANSACTION_FIRM_DATE_UPDATED',
    TransactionMarkedAsCompliant = 'TRANSACTION_MARKED_AS_COMPLIANT',
    TransactionMarkedAsNonCompliant = 'TRANSACTION_MARKED_AS_NON_COMPLIANT',
    DoubleEnderTransactionLinked = 'DOUBLE_ENDER_TRANSACTION_LINKED',
    DoubleEnderTransactionUnlinked = 'DOUBLE_ENDER_TRANSACTION_UNLINKED',
    TransactionInternalReferralUnlinked = 'TRANSACTION_INTERNAL_REFERRAL_UNLINKED',
    TransactionInternalReferralLinked = 'TRANSACTION_INTERNAL_REFERRAL_LINKED',
    TxReminderWaitForApproveCdCalculation = 'TX_REMINDER_WAIT_FOR_APPROVE_CD_CALCULATION',
    TxReminderWaitForSendCd = 'TX_REMINDER_WAIT_FOR_SEND_CD',
    TxReminderListingIsAboutToExpire = 'TX_REMINDER_LISTING_IS_ABOUT_TO_EXPIRE',
    TxReminderListingHasExpired = 'TX_REMINDER_LISTING_HAS_EXPIRED',
    TxReminderIsAboutToClose = 'TX_REMINDER_IS_ABOUT_TO_CLOSE',
    TxReminderHasExpired = 'TX_REMINDER_HAS_EXPIRED',
    TransactionTaxExemptUpdated = 'TRANSACTION_TAX_EXEMPT_UPDATED',
    TransactionTaxPaidFromCommissionUpdated = 'TRANSACTION_TAX_PAID_FROM_COMMISSION_UPDATED',
    TransactionTaxRatesUpdated = 'TRANSACTION_TAX_RATES_UPDATED',
    TransactionTaxAmountUpdated = 'TRANSACTION_TAX_AMOUNT_UPDATED',
    ListingCreated = 'LISTING_CREATED',
    ChecklistCreatedEvent = 'CHECKLIST_CREATED_EVENT',
    ChecklistItemCreated = 'CHECKLIST_ITEM_CREATED',
    ChecklistItemNotStarted = 'CHECKLIST_ITEM_NOT_STARTED',
    ChecklistItemPending = 'CHECKLIST_ITEM_PENDING',
    ChecklistItemWaitingForReview = 'CHECKLIST_ITEM_WAITING_FOR_REVIEW',
    ChecklistItemInReview = 'CHECKLIST_ITEM_IN_REVIEW',
    ChecklistItemRevisionRequested = 'CHECKLIST_ITEM_REVISION_REQUESTED',
    ChecklistItemAccepted = 'CHECKLIST_ITEM_ACCEPTED',
    ChecklistItemDeleted = 'CHECKLIST_ITEM_DELETED',
    ChecklistItemStatusUpdated = 'CHECKLIST_ITEM_STATUS_UPDATED',
    ChecklistItemNameUpdated = 'CHECKLIST_ITEM_NAME_UPDATED',
    ChecklistItemRequiredStatusChanged = 'CHECKLIST_ITEM_REQUIRED_STATUS_CHANGED',
    ChecklistItemDescriptionUpdated = 'CHECKLIST_ITEM_DESCRIPTION_UPDATED',
    ChecklistItemAssigneeUpdated = 'CHECKLIST_ITEM_ASSIGNEE_UPDATED',
    ChecklistItemDueDateUpdated = 'CHECKLIST_ITEM_DUE_DATE_UPDATED',
    ChecklistItemUrgentStatusUpdated = 'CHECKLIST_ITEM_URGENT_STATUS_UPDATED',
    ChecklistItemRequiredStatusUpdated = 'CHECKLIST_ITEM_REQUIRED_STATUS_UPDATED',
    ChecklistItemLabelAddedEvent = 'CHECKLIST_ITEM_LABEL_ADDED_EVENT',
    ChecklistItemLabelRemovedEvent = 'CHECKLIST_ITEM_LABEL_REMOVED_EVENT',
    ChecklistItemFileUploaded = 'CHECKLIST_ITEM_FILE_UPLOADED',
    ChecklistItemFileVersionUploaded = 'CHECKLIST_ITEM_FILE_VERSION_UPLOADED',
    ChecklistItemFileDeleted = 'CHECKLIST_ITEM_FILE_DELETED',
    ChecklistItemFileReferenceAttached = 'CHECKLIST_ITEM_FILE_REFERENCE_ATTACHED',
    ChecklistItemFileReferenceRemoved = 'CHECKLIST_ITEM_FILE_REFERENCE_REMOVED',
    ChecklistItemAssigned = 'CHECKLIST_ITEM_ASSIGNED',
    ChecklistItemUnassigned = 'CHECKLIST_ITEM_UNASSIGNED',
    JourneyItemCompleted = 'JOURNEY_ITEM_COMPLETED',
    JourneyItemIncomplete = 'JOURNEY_ITEM_INCOMPLETE',
    EmailReceivedEvent = 'EMAIL_RECEIVED_EVENT',
    OfficePaymentSystemUpdated = 'OFFICE_PAYMENT_SYSTEM_UPDATED',
    OfficePaymentBatchUpdated = 'OFFICE_PAYMENT_BATCH_UPDATED',
    OfficeRealWithholdsTaxUpdated = 'OFFICE_REAL_WITHHOLDS_TAX_UPDATED',
    OfficeTaxesUpdated = 'OFFICE_TAXES_UPDATED',
    OfficeTaxPaidFromCommissionUpdated = 'OFFICE_TAX_PAID_FROM_COMMISSION_UPDATED',
    MortgageLeadCreated = 'MORTGAGE_LEAD_CREATED',
    MortgageBorrowerInvited = 'MORTGAGE_BORROWER_INVITED',
    MortgageCoBorrowerAdded = 'MORTGAGE_CO_BORROWER_ADDED',
    MortgageCoBorrowerInvited = 'MORTGAGE_CO_BORROWER_INVITED',
    MortgageApplicationStarted = 'MORTGAGE_APPLICATION_STARTED',
    MortgageApplicationSubmitted = 'MORTGAGE_APPLICATION_SUBMITTED',
    MortgageLoanPreApproved = 'MORTGAGE_LOAN_PRE_APPROVED',
    DropboxCreatedEvent = 'DROPBOX_CREATED_EVENT',
    DropboxFileCreatedEvent = 'DROPBOX_FILE_CREATED_EVENT',
    DropboxFileVersionAddedEvent = 'DROPBOX_FILE_VERSION_ADDED_EVENT',
    DropboxFileDeletedEvent = 'DROPBOX_FILE_DELETED_EVENT',
    DropboxFileMovedToTrashEvent = 'DROPBOX_FILE_MOVED_TO_TRASH_EVENT',
    DropboxFileRestoredFromTrashEvent = 'DROPBOX_FILE_RESTORED_FROM_TRASH_EVENT',
    DropboxFileNameUpdatedEvent = 'DROPBOX_FILE_NAME_UPDATED_EVENT',
    CommentCreated = 'COMMENT_CREATED',
    CommentEdited = 'COMMENT_EDITED',
    CommentMediaUploaded = 'COMMENT_MEDIA_UPLOADED',
    CommentAudioTranscribed = 'COMMENT_AUDIO_TRANSCRIBED',
    WebhookEndpointTestEvent = 'WEBHOOK_ENDPOINT_TEST_EVENT',
    FundReleaseRequestSubmitted = 'FUND_RELEASE_REQUEST_SUBMITTED',
    FundReleaseRequestUpdated = 'FUND_RELEASE_REQUEST_UPDATED',
    FundReleaseRequestApprovedByFinance = 'FUND_RELEASE_REQUEST_APPROVED_BY_FINANCE',
    FundReleaseRequestApprovedByBroker = 'FUND_RELEASE_REQUEST_APPROVED_BY_BROKER',
    FundReleaseRequestCancelled = 'FUND_RELEASE_REQUEST_CANCELLED',
    FundReleaseRequestRestored = 'FUND_RELEASE_REQUEST_RESTORED',
    FundReleaseRequestReleased = 'FUND_RELEASE_REQUEST_RELEASED',
    FundReleaseRequestUndoApproval = 'FUND_RELEASE_REQUEST_UNDO_APPROVAL',
    FundReleaseRequestReverted = 'FUND_RELEASE_REQUEST_REVERTED',
    FundReleaseRequestAttachmentUploaded = 'FUND_RELEASE_REQUEST_ATTACHMENT_UPLOADED',
    FundReleaseRequestAttachmentReplaced = 'FUND_RELEASE_REQUEST_ATTACHMENT_REPLACED',
    FundReleaseRequestAttachmentDeleted = 'FUND_RELEASE_REQUEST_ATTACHMENT_DELETED',
    OutgoingPaymentSentToPaymentSystem = 'OUTGOING_PAYMENT_SENT_TO_PAYMENT_SYSTEM',
    TeamMembershipUpdated = 'TEAM_MEMBERSHIP_UPDATED',
    TeamLeaderAdded = 'TEAM_LEADER_ADDED',
    TeamLeaderRemoved = 'TEAM_LEADER_REMOVED',
    WalletCreated = 'WALLET_CREATED',
    WalletBankingPartnerApplicationCreated = 'WALLET_BANKING_PARTNER_APPLICATION_CREATED',
    WalletBankingPartnerApplicationSubmitted = 'WALLET_BANKING_PARTNER_APPLICATION_SUBMITTED',
    WalletBankingPartnerApplicationPendingDocuments = 'WALLET_BANKING_PARTNER_APPLICATION_PENDING_DOCUMENTS',
    WalletBankingPartnerApplicationPendingReview = 'WALLET_BANKING_PARTNER_APPLICATION_PENDING_REVIEW',
    WalletBankingPartnerApplicationApproved = 'WALLET_BANKING_PARTNER_APPLICATION_APPROVED',
    WalletBankingPartnerApplicationDenied = 'WALLET_BANKING_PARTNER_APPLICATION_DENIED',
    WalletBankingPartnerApplicationCanceled = 'WALLET_BANKING_PARTNER_APPLICATION_CANCELED',
    WalletBankingPartnershipCreated = 'WALLET_BANKING_PARTNERSHIP_CREATED',
    WalletAccountCreated = 'WALLET_ACCOUNT_CREATED',
    WalletAccountApplicationSubmitted = 'WALLET_ACCOUNT_APPLICATION_SUBMITTED',
    WalletAccountApplicationApproved = 'WALLET_ACCOUNT_APPLICATION_APPROVED',
    WalletAccountApplicationMarkedForReview = 'WALLET_ACCOUNT_APPLICATION_MARKED_FOR_REVIEW',
    WalletAccountApplicationDenied = 'WALLET_ACCOUNT_APPLICATION_DENIED',
    WalletAccountApplicationCanceled = 'WALLET_ACCOUNT_APPLICATION_CANCELED',
    WalletBankCardCreated = 'WALLET_BANK_CARD_CREATED',
    WalletBankCardActivated = 'WALLET_BANK_CARD_ACTIVATED',
    WalletBankCardFrozen = 'WALLET_BANK_CARD_FROZEN',
    WalletBankCardUnfrozen = 'WALLET_BANK_CARD_UNFROZEN',
    WalletBankCardExpired = 'WALLET_BANK_CARD_EXPIRED',
    WalletBankCardLost = 'WALLET_BANK_CARD_LOST',
    WalletBankCardStolen = 'WALLET_BANK_CARD_STOLEN',
    WalletBankCardSuspectedOfFraud = 'WALLET_BANK_CARD_SUSPECTED_OF_FRAUD',
    WalletBankCardClearedOfFraudSuspicion = 'WALLET_BANK_CARD_CLEARED_OF_FRAUD_SUSPICION',
    WalletBankCardClosed = 'WALLET_BANK_CARD_CLOSED',
    WalletBankCardReissued = 'WALLET_BANK_CARD_REISSUED',
    WalletBankCardPinSet = 'WALLET_BANK_CARD_PIN_SET',
    WalletChargeAuthorizationRequested = 'WALLET_CHARGE_AUTHORIZATION_REQUESTED',
    WalletChargeAuthorizationUserApprovalRequested = 'WALLET_CHARGE_AUTHORIZATION_USER_APPROVAL_REQUESTED',
    WalletChargeAuthorizationRequestApprovedByUser = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_APPROVED_BY_USER',
    WalletChargeAuthorizationRequestDeclinedByUser = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_DECLINED_BY_USER',
    WalletChargeAuthorizationRequestApprovedByBankingPartner = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_APPROVED_BY_BANKING_PARTNER',
    WalletChargeAuthorizationRequestDeclinedByBankingPartner = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_DECLINED_BY_BANKING_PARTNER',
    WalletChargeAuthorizationRequestApproved = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_APPROVED',
    WalletChargeAuthorizationRequestDeclined = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_DECLINED',
    WalletBankCardAutoApprovalLimitUpdated = 'WALLET_BANK_CARD_AUTO_APPROVAL_LIMIT_UPDATED',
    WalletBankCardAutoApprovalDailyLimitUpdated = 'WALLET_BANK_CARD_AUTO_APPROVAL_DAILY_LIMIT_UPDATED',
    VoiceCallStarted = 'VOICE_CALL_STARTED',
    VoiceRecordingComplete = 'VOICE_RECORDING_COMPLETE',
    VoiceRecordingTranscribed = 'VOICE_RECORDING_TRANSCRIBED'
}

/**
 * 
 * @export
 * @interface UpdateWebhookStatus
 */
export interface UpdateWebhookStatus {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWebhookStatus
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface WealthGoalsResponse
 */
export interface WealthGoalsResponse {
    /**
     * 
     * @type {GoalDetails}
     * @memberof WealthGoalsResponse
     */
    'goalDetails': GoalDetails;
    /**
     * 
     * @type {MoneyValue}
     * @memberof WealthGoalsResponse
     */
    'currentWealthValue': MoneyValue;
    /**
     * 
     * @type {StockPriceDetails}
     * @memberof WealthGoalsResponse
     */
    'stockPriceDetails': StockPriceDetails;
}
/**
 * 
 * @export
 * @interface WealthPlanDetailDto
 */
export interface WealthPlanDetailDto {
    /**
     * 
     * @type {boolean}
     * @memberof WealthPlanDetailDto
     */
    'seenEliteProductionPage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WealthPlanDetailDto
     */
    'seenEliteCulturalPage'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WealthPlanDetailDto
     */
    'agentCurrency': WealthPlanDetailDtoAgentCurrencyEnum;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'wealthGoal': number;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'averageTransactionsPerYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'transactionVolumePerYear': number;
    /**
     * 
     * @type {boolean}
     * @memberof WealthPlanDetailDto
     */
    'sppParticipation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WealthPlanDetailDto
     */
    'revShareParticipation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'agentReferredPerYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'agentsAgentReferredPerYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof WealthPlanDetailDto
     */
    'planStartDate': string;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'projectionYears'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WealthPlanDetailDto
     */
    'realTitleJVParticipation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'realTitleJVShares'?: number;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'additionalRealShares'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WealthPlanDetailDto
     */
    'eliteAgentProductionAwardParticipation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WealthPlanDetailDto
     */
    'eliteAgentCulturalAwardParticipation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'realStockFuturePrice': number;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'averageTransactionCommissionPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'teamNumberCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'teamTransactionsPerMember'?: number;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'teamAverageSalesPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'teamLeaderCommissionPercentagePerTransaction'?: number;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'teamAverageTransactionCommissionPercentage'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WealthPlanDetailDto
     */
    'teamUseAverageFixedCommission'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'teamAverageFixedCommission'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WealthPlanDetailDto
     */
    'useAverageFixedCommission'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WealthPlanDetailDto
     */
    'averageFixedCommission'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WealthPlanDetailDto
     */
    'teamLead'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum WealthPlanDetailDtoAgentCurrencyEnum {
    Usd = 'USD',
    Cad = 'CAD'
}

/**
 * 
 * @export
 * @interface WebhookCreatedResponse
 */
export interface WebhookCreatedResponse {
    /**
     * 
     * @type {string}
     * @memberof WebhookCreatedResponse
     */
    'webhookId'?: string;
}
/**
 * 
 * @export
 * @interface WebhookDto
 */
export interface WebhookDto {
    /**
     * 
     * @type {string}
     * @memberof WebhookDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookDto
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookDto
     */
    'ownerId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookDto
     */
    'active'?: boolean;
    /**
     * 
     * @type {Array<SubscriptionDto>}
     * @memberof WebhookDto
     */
    'subscriptions'?: Array<SubscriptionDto>;
    /**
     * 
     * @type {number}
     * @memberof WebhookDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof WebhookDto
     */
    'updatedAt'?: number;
}
/**
 * 
 * @export
 * @interface WebhookEventsDto
 */
export interface WebhookEventsDto {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof WebhookEventsDto
     */
    'events': { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface WrapperBaseMessage
 */
export interface WrapperBaseMessage {
    /**
     * 
     * @type {BaseMessage}
     * @memberof WrapperBaseMessage
     */
    'message'?: BaseMessage;
    /**
     * 
     * @type {string}
     * @memberof WrapperBaseMessage
     */
    '_class'?: string;
}

/**
 * AgentWealthManagementApi - axios parameter creator
 * @export
 */
export const AgentWealthManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary This returns the agent\'s communications preferences
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentCommunicationsPreferences: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getAgentCommunicationsPreferences', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/settings/comms`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent\'s attracting stock award earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttractingStockAwardsEarnings: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getAttractingStockAwardsEarnings', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/earnings/attracting-stock-award`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent capping award earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCappingAwardEarnings: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getCappingAwardEarnings', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/earnings/capping-award`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent commission income
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommissionIncome: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getCommissionIncome', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/earnings/commission-income`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the total net wealth earned by an agent to-date
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentWealth: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getCurrentWealth', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/wealth/current`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the agent\'s earnings overview
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarningsOverview: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getEarningsOverview', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/earnings/overview`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent elite agent cultural award earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEliteAgentCulturalAwardsEarnings: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getEliteAgentCulturalAwardsEarnings', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/earnings/elite-agent-cultural-award`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent elite agent production award earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEliteAgentProductionAwardEarnings: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getEliteAgentProductionAwardEarnings', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/earnings/elite-agent-production-award`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a preview of of a prospective agent\'s wealth plan
         * @param {GoalDetails} goalDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanPreview: async (goalDetails: GoalDetails, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'goalDetails' is not null or undefined
            assertParamExists('getPlanPreview', 'goalDetails', goalDetails)
            const localVarPath = `/api/v1/wealth-management-referrals/plan/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(goalDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent post-cap-spp earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostCapSPPEarnings: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getPostCapSPPEarnings', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/earnings/post-cap-spp`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent pre-cap spp earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreCapSPPIncome: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getPreCapSPPIncome', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/earnings/pre-cap-spp`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get rate limit info
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRateLimitInfo: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getRateLimitInfo', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/referral/rate-limits`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the agent\'s real title joint venture earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealTitleJointVentureEarnings: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getRealTitleJointVentureEarnings', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/earnings/real-title-joint-venture`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a prospective agent\'s real title joint venture income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealTitleJointVentureEarnings1: async (referralId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('getRealTitleJointVentureEarnings1', 'referralId', referralId)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/earnings/real-title-joint-venture`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a prospective agent\'s attracting stock award income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralAttractingStockAwardsEarnings: async (referralId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('getReferralAttractingStockAwardsEarnings', 'referralId', referralId)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/earnings/attracting-stock-award`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a prospective agent\'s capping award income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralCappingAwardEarnings: async (referralId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('getReferralCappingAwardEarnings', 'referralId', referralId)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/earnings/capping-award`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a prospective agent\'s commission income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralCommissionIncome: async (referralId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('getReferralCommissionIncome', 'referralId', referralId)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/earnings/commission-income`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the default wealth goals for a prospective agent
         * @param {'USD' | 'CAD'} referralAgentCurrency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralDefaultWealthGoals: async (referralAgentCurrency: 'USD' | 'CAD', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralAgentCurrency' is not null or undefined
            assertParamExists('getReferralDefaultWealthGoals', 'referralAgentCurrency', referralAgentCurrency)
            const localVarPath = `/api/v1/wealth-management-referrals/currency/{referralAgentCurrency}/wealth/goals/defaults`
                .replace(`{${"referralAgentCurrency"}}`, encodeURIComponent(String(referralAgentCurrency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a prospective agent\'s earnings overview
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralEarningsOverview: async (referralId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('getReferralEarningsOverview', 'referralId', referralId)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/earnings/overview`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a prospective agent\'s elite agent cultural award income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralEliteAgentCulturalAwardsEarnings: async (referralId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('getReferralEliteAgentCulturalAwardsEarnings', 'referralId', referralId)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/earnings/elite-agent-cultural-award`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a prospective agent\'s elite agent production award income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralEliteAgentProductionAwardEarnings: async (referralId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('getReferralEliteAgentProductionAwardEarnings', 'referralId', referralId)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/earnings/elite-agent-production-award`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get information about a referral
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralInfo: async (referralId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('getReferralInfo', 'referralId', referralId)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/info`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a prospective agent\'s post-cap-spp income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralPostCapSPPEarnings: async (referralId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('getReferralPostCapSPPEarnings', 'referralId', referralId)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/earnings/post-cap-spp`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a prospective agent\'s pre-cap spp income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralPreCapSPPIncome: async (referralId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('getReferralPreCapSPPIncome', 'referralId', referralId)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/earnings/pre-cap-spp`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a prospective agent\'s revenue share income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralRevenueShareEarnings: async (referralId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('getReferralRevenueShareEarnings', 'referralId', referralId)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/earnings/revenue-share-income`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a prospective agent\'s wealth goals
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralWealthGoals: async (referralId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('getReferralWealthGoals', 'referralId', referralId)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/wealth/goals`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent revenue share earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevenueShareEarnings: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getRevenueShareEarnings', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/earnings/revenue-share-income`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the stock price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockPrice: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/wealth-management/stock-price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the default plan attributes for an agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWealthGoalDefaults: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getWealthGoalDefaults', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/wealth/goals/defaults`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent\'s wealth goals
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWealthGoals: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getWealthGoals', 'agentId', agentId)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/wealth/goals`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a list of submitted referrals
         * @param {string} agentId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {'FIRST_NAME' | 'LAST_NAME' | 'PHONE_NUMBER' | 'BROKERAGE_NAME' | 'EMAIL_ADDRESS' | 'OPEN_EMAIL_AT' | 'WEALTH_PLAN_LOADED_AT' | 'MOST_RECENT_OPEN_DATE_AT' | 'EMAIL_SENT_TIME_AT'} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReferrals: async (agentId: string, pageNumber: number, pageSize: number, sortBy?: 'FIRST_NAME' | 'LAST_NAME' | 'PHONE_NUMBER' | 'BROKERAGE_NAME' | 'EMAIL_ADDRESS' | 'OPEN_EMAIL_AT' | 'WEALTH_PLAN_LOADED_AT' | 'MOST_RECENT_OPEN_DATE_AT' | 'EMAIL_SENT_TIME_AT', sortDirection?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('listReferrals', 'agentId', agentId)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('listReferrals', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listReferrals', 'pageSize', pageSize)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/referrals/list`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This marks the prospective agent\'s referral as opened.
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralOpened: async (referralId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('referralOpened', 'referralId', referralId)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/opened`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This marks the prospective agent\'s referral as opened.
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralViewed: async (referralId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('referralViewed', 'referralId', referralId)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/viewed`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary save a prospective agent\'s wealth goals
         * @param {string} referralId 
         * @param {GoalDetails} goalDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReferralWealthGoals: async (referralId: string, goalDetails: GoalDetails, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('saveReferralWealthGoals', 'referralId', referralId)
            // verify required parameter 'goalDetails' is not null or undefined
            assertParamExists('saveReferralWealthGoals', 'goalDetails', goalDetails)
            const localVarPath = `/api/v1/wealth-management-referrals/{referralId}/wealth/goals`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(goalDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the agent goal
         * @param {string} agentId 
         * @param {GoalDetails} goalDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWealthGoals: async (agentId: string, goalDetails: GoalDetails, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('setWealthGoals', 'agentId', agentId)
            // verify required parameter 'goalDetails' is not null or undefined
            assertParamExists('setWealthGoals', 'goalDetails', goalDetails)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/wealth/goals`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(goalDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary submit a referral for a prospective agent
         * @param {string} agentId 
         * @param {SubmitProspectiveAgentReferralRequest} submitProspectiveAgentReferralRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAgentReferral: async (agentId: string, submitProspectiveAgentReferralRequest: SubmitProspectiveAgentReferralRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('submitAgentReferral', 'agentId', agentId)
            // verify required parameter 'submitProspectiveAgentReferralRequest' is not null or undefined
            assertParamExists('submitAgentReferral', 'submitProspectiveAgentReferralRequest', submitProspectiveAgentReferralRequest)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/submit/referral`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitProspectiveAgentReferralRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This returns the agent\'s communications preferences
         * @param {string} agentId 
         * @param {SetCommsPreferencesRequest} setCommsPreferencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentCommunicationsPreferences: async (agentId: string, setCommsPreferencesRequest: SetCommsPreferencesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updateAgentCommunicationsPreferences', 'agentId', agentId)
            // verify required parameter 'setCommsPreferencesRequest' is not null or undefined
            assertParamExists('updateAgentCommunicationsPreferences', 'setCommsPreferencesRequest', setCommsPreferencesRequest)
            const localVarPath = `/api/v1/wealth-management/agent/{agentId}/settings/comms`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setCommsPreferencesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentWealthManagementApi - functional programming interface
 * @export
 */
export const AgentWealthManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentWealthManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary This returns the agent\'s communications preferences
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentCommunicationsPreferences(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommsPreferencesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentCommunicationsPreferences(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent\'s attracting stock award earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttractingStockAwardsEarnings(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttractingStockAwardsEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttractingStockAwardsEarnings(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent capping award earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCappingAwardEarnings(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CappingAwardEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCappingAwardEarnings(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent commission income
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommissionIncome(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommissionIncome(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the total net wealth earned by an agent to-date
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentWealth(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentWealthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentWealth(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the agent\'s earnings overview
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEarningsOverview(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EarningsOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEarningsOverview(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent elite agent cultural award earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEliteAgentCulturalAwardsEarnings(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EliteAgentCulturalEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEliteAgentCulturalAwardsEarnings(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent elite agent production award earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEliteAgentProductionAwardEarnings(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EliteAgentProductionEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEliteAgentProductionAwardEarnings(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a preview of of a prospective agent\'s wealth plan
         * @param {GoalDetails} goalDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlanPreview(goalDetails: GoalDetails, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EarningsOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlanPreview(goalDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent post-cap-spp earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostCapSPPEarnings(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostCapSppEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostCapSPPEarnings(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent pre-cap spp earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreCapSPPIncome(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreCapSPPEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreCapSPPIncome(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get rate limit info
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRateLimitInfo(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectiveReferralRateLimitInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRateLimitInfo(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the agent\'s real title joint venture earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRealTitleJointVentureEarnings(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealTitleJointVentureEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRealTitleJointVentureEarnings(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a prospective agent\'s real title joint venture income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRealTitleJointVentureEarnings1(referralId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealTitleJointVentureEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRealTitleJointVentureEarnings1(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a prospective agent\'s attracting stock award income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralAttractingStockAwardsEarnings(referralId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttractingStockAwardsEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralAttractingStockAwardsEarnings(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a prospective agent\'s capping award income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralCappingAwardEarnings(referralId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CappingAwardEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralCappingAwardEarnings(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a prospective agent\'s commission income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralCommissionIncome(referralId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralCommissionIncome(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the default wealth goals for a prospective agent
         * @param {'USD' | 'CAD'} referralAgentCurrency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralDefaultWealthGoals(referralAgentCurrency: 'USD' | 'CAD', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WealthGoalsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralDefaultWealthGoals(referralAgentCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a prospective agent\'s earnings overview
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralEarningsOverview(referralId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EarningsOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralEarningsOverview(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a prospective agent\'s elite agent cultural award income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralEliteAgentCulturalAwardsEarnings(referralId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EliteAgentCulturalEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralEliteAgentCulturalAwardsEarnings(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a prospective agent\'s elite agent production award income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralEliteAgentProductionAwardEarnings(referralId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EliteAgentProductionEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralEliteAgentProductionAwardEarnings(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get information about a referral
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralInfo(referralId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectiveAgentInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralInfo(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a prospective agent\'s post-cap-spp income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralPostCapSPPEarnings(referralId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostCapSppEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralPostCapSPPEarnings(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a prospective agent\'s pre-cap spp income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralPreCapSPPIncome(referralId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreCapSPPEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralPreCapSPPIncome(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a prospective agent\'s revenue share income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralRevenueShareEarnings(referralId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RevenueShareEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralRevenueShareEarnings(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a prospective agent\'s wealth goals
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralWealthGoals(referralId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WealthGoalsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralWealthGoals(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent revenue share earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevenueShareEarnings(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RevenueShareEarningsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevenueShareEarnings(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the stock price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStockPrice(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStockPrice(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the default plan attributes for an agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWealthGoalDefaults(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WealthGoalsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWealthGoalDefaults(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent\'s wealth goals
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWealthGoals(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WealthGoalsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWealthGoals(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a list of submitted referrals
         * @param {string} agentId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {'FIRST_NAME' | 'LAST_NAME' | 'PHONE_NUMBER' | 'BROKERAGE_NAME' | 'EMAIL_ADDRESS' | 'OPEN_EMAIL_AT' | 'WEALTH_PLAN_LOADED_AT' | 'MOST_RECENT_OPEN_DATE_AT' | 'EMAIL_SENT_TIME_AT'} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReferrals(agentId: string, pageNumber: number, pageSize: number, sortBy?: 'FIRST_NAME' | 'LAST_NAME' | 'PHONE_NUMBER' | 'BROKERAGE_NAME' | 'EMAIL_ADDRESS' | 'OPEN_EMAIL_AT' | 'WEALTH_PLAN_LOADED_AT' | 'MOST_RECENT_OPEN_DATE_AT' | 'EMAIL_SENT_TIME_AT', sortDirection?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReferrals(agentId, pageNumber, pageSize, sortBy, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This marks the prospective agent\'s referral as opened.
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async referralOpened(referralId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.referralOpened(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This marks the prospective agent\'s referral as opened.
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async referralViewed(referralId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.referralViewed(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary save a prospective agent\'s wealth goals
         * @param {string} referralId 
         * @param {GoalDetails} goalDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveReferralWealthGoals(referralId: string, goalDetails: GoalDetails, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoalDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveReferralWealthGoals(referralId, goalDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set the agent goal
         * @param {string} agentId 
         * @param {GoalDetails} goalDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setWealthGoals(agentId: string, goalDetails: GoalDetails, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoalDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setWealthGoals(agentId, goalDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary submit a referral for a prospective agent
         * @param {string} agentId 
         * @param {SubmitProspectiveAgentReferralRequest} submitProspectiveAgentReferralRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitAgentReferral(agentId: string, submitProspectiveAgentReferralRequest: SubmitProspectiveAgentReferralRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitAgentReferral(agentId, submitProspectiveAgentReferralRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This returns the agent\'s communications preferences
         * @param {string} agentId 
         * @param {SetCommsPreferencesRequest} setCommsPreferencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentCommunicationsPreferences(agentId: string, setCommsPreferencesRequest: SetCommsPreferencesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentCommunicationsPreferences(agentId, setCommsPreferencesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentWealthManagementApi - factory interface
 * @export
 */
export const AgentWealthManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentWealthManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary This returns the agent\'s communications preferences
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentCommunicationsPreferences(agentId: string, options?: any): AxiosPromise<GetCommsPreferencesResponse> {
            return localVarFp.getAgentCommunicationsPreferences(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent\'s attracting stock award earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttractingStockAwardsEarnings(agentId: string, options?: any): AxiosPromise<AttractingStockAwardsEarningsResponse> {
            return localVarFp.getAttractingStockAwardsEarnings(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent capping award earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCappingAwardEarnings(agentId: string, options?: any): AxiosPromise<CappingAwardEarningsResponse> {
            return localVarFp.getCappingAwardEarnings(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent commission income
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommissionIncome(agentId: string, options?: any): AxiosPromise<CommissionEarningsResponse> {
            return localVarFp.getCommissionIncome(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the total net wealth earned by an agent to-date
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentWealth(agentId: string, options?: any): AxiosPromise<CurrentWealthResponse> {
            return localVarFp.getCurrentWealth(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the agent\'s earnings overview
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarningsOverview(agentId: string, options?: any): AxiosPromise<EarningsOverviewResponse> {
            return localVarFp.getEarningsOverview(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent elite agent cultural award earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEliteAgentCulturalAwardsEarnings(agentId: string, options?: any): AxiosPromise<EliteAgentCulturalEarningsResponse> {
            return localVarFp.getEliteAgentCulturalAwardsEarnings(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent elite agent production award earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEliteAgentProductionAwardEarnings(agentId: string, options?: any): AxiosPromise<EliteAgentProductionEarningsResponse> {
            return localVarFp.getEliteAgentProductionAwardEarnings(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a preview of of a prospective agent\'s wealth plan
         * @param {GoalDetails} goalDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanPreview(goalDetails: GoalDetails, options?: any): AxiosPromise<EarningsOverviewResponse> {
            return localVarFp.getPlanPreview(goalDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent post-cap-spp earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostCapSPPEarnings(agentId: string, options?: any): AxiosPromise<PostCapSppEarningsResponse> {
            return localVarFp.getPostCapSPPEarnings(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent pre-cap spp earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreCapSPPIncome(agentId: string, options?: any): AxiosPromise<PreCapSPPEarningsResponse> {
            return localVarFp.getPreCapSPPIncome(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get rate limit info
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRateLimitInfo(agentId: string, options?: any): AxiosPromise<ProspectiveReferralRateLimitInfo> {
            return localVarFp.getRateLimitInfo(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the agent\'s real title joint venture earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealTitleJointVentureEarnings(agentId: string, options?: any): AxiosPromise<RealTitleJointVentureEarningsResponse> {
            return localVarFp.getRealTitleJointVentureEarnings(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a prospective agent\'s real title joint venture income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealTitleJointVentureEarnings1(referralId: string, options?: any): AxiosPromise<RealTitleJointVentureEarningsResponse> {
            return localVarFp.getRealTitleJointVentureEarnings1(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a prospective agent\'s attracting stock award income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralAttractingStockAwardsEarnings(referralId: string, options?: any): AxiosPromise<AttractingStockAwardsEarningsResponse> {
            return localVarFp.getReferralAttractingStockAwardsEarnings(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a prospective agent\'s capping award income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralCappingAwardEarnings(referralId: string, options?: any): AxiosPromise<CappingAwardEarningsResponse> {
            return localVarFp.getReferralCappingAwardEarnings(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a prospective agent\'s commission income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralCommissionIncome(referralId: string, options?: any): AxiosPromise<CommissionEarningsResponse> {
            return localVarFp.getReferralCommissionIncome(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the default wealth goals for a prospective agent
         * @param {'USD' | 'CAD'} referralAgentCurrency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralDefaultWealthGoals(referralAgentCurrency: 'USD' | 'CAD', options?: any): AxiosPromise<WealthGoalsResponse> {
            return localVarFp.getReferralDefaultWealthGoals(referralAgentCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a prospective agent\'s earnings overview
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralEarningsOverview(referralId: string, options?: any): AxiosPromise<EarningsOverviewResponse> {
            return localVarFp.getReferralEarningsOverview(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a prospective agent\'s elite agent cultural award income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralEliteAgentCulturalAwardsEarnings(referralId: string, options?: any): AxiosPromise<EliteAgentCulturalEarningsResponse> {
            return localVarFp.getReferralEliteAgentCulturalAwardsEarnings(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a prospective agent\'s elite agent production award income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralEliteAgentProductionAwardEarnings(referralId: string, options?: any): AxiosPromise<EliteAgentProductionEarningsResponse> {
            return localVarFp.getReferralEliteAgentProductionAwardEarnings(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get information about a referral
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralInfo(referralId: string, options?: any): AxiosPromise<ProspectiveAgentInfo> {
            return localVarFp.getReferralInfo(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a prospective agent\'s post-cap-spp income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralPostCapSPPEarnings(referralId: string, options?: any): AxiosPromise<PostCapSppEarningsResponse> {
            return localVarFp.getReferralPostCapSPPEarnings(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a prospective agent\'s pre-cap spp income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralPreCapSPPIncome(referralId: string, options?: any): AxiosPromise<PreCapSPPEarningsResponse> {
            return localVarFp.getReferralPreCapSPPIncome(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a prospective agent\'s revenue share income
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralRevenueShareEarnings(referralId: string, options?: any): AxiosPromise<RevenueShareEarningsResponse> {
            return localVarFp.getReferralRevenueShareEarnings(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a prospective agent\'s wealth goals
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralWealthGoals(referralId: string, options?: any): AxiosPromise<WealthGoalsResponse> {
            return localVarFp.getReferralWealthGoals(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent revenue share earnings
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevenueShareEarnings(agentId: string, options?: any): AxiosPromise<RevenueShareEarningsResponse> {
            return localVarFp.getRevenueShareEarnings(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the stock price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockPrice(options?: any): AxiosPromise<string> {
            return localVarFp.getStockPrice(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the default plan attributes for an agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWealthGoalDefaults(agentId: string, options?: any): AxiosPromise<WealthGoalsResponse> {
            return localVarFp.getWealthGoalDefaults(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent\'s wealth goals
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWealthGoals(agentId: string, options?: any): AxiosPromise<WealthGoalsResponse> {
            return localVarFp.getWealthGoals(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a list of submitted referrals
         * @param {string} agentId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {'FIRST_NAME' | 'LAST_NAME' | 'PHONE_NUMBER' | 'BROKERAGE_NAME' | 'EMAIL_ADDRESS' | 'OPEN_EMAIL_AT' | 'WEALTH_PLAN_LOADED_AT' | 'MOST_RECENT_OPEN_DATE_AT' | 'EMAIL_SENT_TIME_AT'} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReferrals(agentId: string, pageNumber: number, pageSize: number, sortBy?: 'FIRST_NAME' | 'LAST_NAME' | 'PHONE_NUMBER' | 'BROKERAGE_NAME' | 'EMAIL_ADDRESS' | 'OPEN_EMAIL_AT' | 'WEALTH_PLAN_LOADED_AT' | 'MOST_RECENT_OPEN_DATE_AT' | 'EMAIL_SENT_TIME_AT', sortDirection?: 'ASC' | 'DESC', options?: any): AxiosPromise<ReferralsListResponse> {
            return localVarFp.listReferrals(agentId, pageNumber, pageSize, sortBy, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This marks the prospective agent\'s referral as opened.
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralOpened(referralId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.referralOpened(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This marks the prospective agent\'s referral as opened.
         * @param {string} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralViewed(referralId: string, options?: any): AxiosPromise<void> {
            return localVarFp.referralViewed(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary save a prospective agent\'s wealth goals
         * @param {string} referralId 
         * @param {GoalDetails} goalDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReferralWealthGoals(referralId: string, goalDetails: GoalDetails, options?: any): AxiosPromise<GoalDetails> {
            return localVarFp.saveReferralWealthGoals(referralId, goalDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the agent goal
         * @param {string} agentId 
         * @param {GoalDetails} goalDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWealthGoals(agentId: string, goalDetails: GoalDetails, options?: any): AxiosPromise<GoalDetails> {
            return localVarFp.setWealthGoals(agentId, goalDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary submit a referral for a prospective agent
         * @param {string} agentId 
         * @param {SubmitProspectiveAgentReferralRequest} submitProspectiveAgentReferralRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAgentReferral(agentId: string, submitProspectiveAgentReferralRequest: SubmitProspectiveAgentReferralRequest, options?: any): AxiosPromise<void> {
            return localVarFp.submitAgentReferral(agentId, submitProspectiveAgentReferralRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This returns the agent\'s communications preferences
         * @param {string} agentId 
         * @param {SetCommsPreferencesRequest} setCommsPreferencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentCommunicationsPreferences(agentId: string, setCommsPreferencesRequest: SetCommsPreferencesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAgentCommunicationsPreferences(agentId, setCommsPreferencesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentWealthManagementApi - object-oriented interface
 * @export
 * @class AgentWealthManagementApi
 * @extends {BaseAPI}
 */
export class AgentWealthManagementApi extends BaseAPI {
    /**
     * 
     * @summary This returns the agent\'s communications preferences
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getAgentCommunicationsPreferences(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getAgentCommunicationsPreferences(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent\'s attracting stock award earnings
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getAttractingStockAwardsEarnings(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getAttractingStockAwardsEarnings(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent capping award earnings
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getCappingAwardEarnings(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getCappingAwardEarnings(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent commission income
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getCommissionIncome(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getCommissionIncome(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the total net wealth earned by an agent to-date
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getCurrentWealth(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getCurrentWealth(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the agent\'s earnings overview
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getEarningsOverview(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getEarningsOverview(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent elite agent cultural award earnings
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getEliteAgentCulturalAwardsEarnings(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getEliteAgentCulturalAwardsEarnings(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent elite agent production award earnings
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getEliteAgentProductionAwardEarnings(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getEliteAgentProductionAwardEarnings(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a preview of of a prospective agent\'s wealth plan
     * @param {GoalDetails} goalDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getPlanPreview(goalDetails: GoalDetails, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getPlanPreview(goalDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent post-cap-spp earnings
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getPostCapSPPEarnings(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getPostCapSPPEarnings(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent pre-cap spp earnings
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getPreCapSPPIncome(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getPreCapSPPIncome(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get rate limit info
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getRateLimitInfo(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getRateLimitInfo(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the agent\'s real title joint venture earnings
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getRealTitleJointVentureEarnings(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getRealTitleJointVentureEarnings(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a prospective agent\'s real title joint venture income
     * @param {string} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getRealTitleJointVentureEarnings1(referralId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getRealTitleJointVentureEarnings1(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a prospective agent\'s attracting stock award income
     * @param {string} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getReferralAttractingStockAwardsEarnings(referralId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getReferralAttractingStockAwardsEarnings(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a prospective agent\'s capping award income
     * @param {string} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getReferralCappingAwardEarnings(referralId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getReferralCappingAwardEarnings(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a prospective agent\'s commission income
     * @param {string} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getReferralCommissionIncome(referralId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getReferralCommissionIncome(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the default wealth goals for a prospective agent
     * @param {'USD' | 'CAD'} referralAgentCurrency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getReferralDefaultWealthGoals(referralAgentCurrency: 'USD' | 'CAD', options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getReferralDefaultWealthGoals(referralAgentCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a prospective agent\'s earnings overview
     * @param {string} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getReferralEarningsOverview(referralId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getReferralEarningsOverview(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a prospective agent\'s elite agent cultural award income
     * @param {string} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getReferralEliteAgentCulturalAwardsEarnings(referralId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getReferralEliteAgentCulturalAwardsEarnings(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a prospective agent\'s elite agent production award income
     * @param {string} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getReferralEliteAgentProductionAwardEarnings(referralId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getReferralEliteAgentProductionAwardEarnings(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get information about a referral
     * @param {string} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getReferralInfo(referralId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getReferralInfo(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a prospective agent\'s post-cap-spp income
     * @param {string} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getReferralPostCapSPPEarnings(referralId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getReferralPostCapSPPEarnings(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a prospective agent\'s pre-cap spp income
     * @param {string} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getReferralPreCapSPPIncome(referralId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getReferralPreCapSPPIncome(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a prospective agent\'s revenue share income
     * @param {string} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getReferralRevenueShareEarnings(referralId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getReferralRevenueShareEarnings(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a prospective agent\'s wealth goals
     * @param {string} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getReferralWealthGoals(referralId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getReferralWealthGoals(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent revenue share earnings
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getRevenueShareEarnings(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getRevenueShareEarnings(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the stock price
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getStockPrice(options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getStockPrice(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the default plan attributes for an agent
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getWealthGoalDefaults(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getWealthGoalDefaults(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent\'s wealth goals
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public getWealthGoals(agentId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).getWealthGoals(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a list of submitted referrals
     * @param {string} agentId 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {'FIRST_NAME' | 'LAST_NAME' | 'PHONE_NUMBER' | 'BROKERAGE_NAME' | 'EMAIL_ADDRESS' | 'OPEN_EMAIL_AT' | 'WEALTH_PLAN_LOADED_AT' | 'MOST_RECENT_OPEN_DATE_AT' | 'EMAIL_SENT_TIME_AT'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public listReferrals(agentId: string, pageNumber: number, pageSize: number, sortBy?: 'FIRST_NAME' | 'LAST_NAME' | 'PHONE_NUMBER' | 'BROKERAGE_NAME' | 'EMAIL_ADDRESS' | 'OPEN_EMAIL_AT' | 'WEALTH_PLAN_LOADED_AT' | 'MOST_RECENT_OPEN_DATE_AT' | 'EMAIL_SENT_TIME_AT', sortDirection?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).listReferrals(agentId, pageNumber, pageSize, sortBy, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This marks the prospective agent\'s referral as opened.
     * @param {string} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public referralOpened(referralId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).referralOpened(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This marks the prospective agent\'s referral as opened.
     * @param {string} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public referralViewed(referralId: string, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).referralViewed(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary save a prospective agent\'s wealth goals
     * @param {string} referralId 
     * @param {GoalDetails} goalDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public saveReferralWealthGoals(referralId: string, goalDetails: GoalDetails, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).saveReferralWealthGoals(referralId, goalDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the agent goal
     * @param {string} agentId 
     * @param {GoalDetails} goalDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public setWealthGoals(agentId: string, goalDetails: GoalDetails, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).setWealthGoals(agentId, goalDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary submit a referral for a prospective agent
     * @param {string} agentId 
     * @param {SubmitProspectiveAgentReferralRequest} submitProspectiveAgentReferralRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public submitAgentReferral(agentId: string, submitProspectiveAgentReferralRequest: SubmitProspectiveAgentReferralRequest, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).submitAgentReferral(agentId, submitProspectiveAgentReferralRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This returns the agent\'s communications preferences
     * @param {string} agentId 
     * @param {SetCommsPreferencesRequest} setCommsPreferencesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWealthManagementApi
     */
    public updateAgentCommunicationsPreferences(agentId: string, setCommsPreferencesRequest: SetCommsPreferencesRequest, options?: AxiosRequestConfig) {
        return AgentWealthManagementApiFp(this.configuration).updateAgentCommunicationsPreferences(agentId, setCommsPreferencesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KafkaRetryProducerControllerApi - axios parameter creator
 * @export
 */
export const KafkaRetryProducerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus: async (updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFailedMessageStatusBatch' is not null or undefined
            assertParamExists('batchUpdateFailedMessageStatus', 'updateFailedMessageStatusBatch', updateFailedMessageStatusBatch)
            const localVarPath = `/api/v1/kafka-retry/producer/status-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFailedMessageStatusBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/producer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = createdAtFrom;
            }

            if (createdAtTo !== undefined) {
                localVarQueryParameter['createdAtTo'] = createdAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage: async (failedMessageRetryRequest: FailedMessageRetryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageRetryRequest' is not null or undefined
            assertParamExists('retryFailedMessage', 'failedMessageRetryRequest', failedMessageRetryRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageRetryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages: async (failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessagesCriteriaRequest' is not null or undefined
            assertParamExists('retryFailedMessages', 'failedMessagesCriteriaRequest', failedMessagesCriteriaRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessagesCriteriaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus: async (failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageUpdateStatusRequest' is not null or undefined
            assertParamExists('updateFailedMessageStatus', 'failedMessageUpdateStatusRequest', failedMessageUpdateStatusRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageUpdateStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - functional programming interface
 * @export
 */
export const KafkaRetryProducerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KafkaRetryProducerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessage(failedMessageRetryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessages(failedMessagesCriteriaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - factory interface
 * @export
 */
export const KafkaRetryProducerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KafkaRetryProducerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: any): AxiosPromise<number> {
            return localVarFp.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: any): AxiosPromise<GenericSearchResponseFailedMessage> {
            return localVarFp.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: any): AxiosPromise<number> {
            return localVarFp.retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: any): AxiosPromise<FailedMessage> {
            return localVarFp.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KafkaRetryProducerControllerApi - object-oriented interface
 * @export
 * @class KafkaRetryProducerControllerApi
 * @extends {BaseAPI}
 */
export class KafkaRetryProducerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Update provided failed messages status
     * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of failed messages
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
     * @param {number} [createdAtFrom] 
     * @param {number} [createdAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry one message by id
     * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry messages by criteria
     * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update failed event status
     * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlayDebugControllerApi - axios parameter creator
 * @export
 */
export const PlayDebugControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete an agent\'s plan
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWealthGoal: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('deleteWealthGoal', 'agentId', agentId)
            const localVarPath = `/api/v1/play-debug/agent/{agentId}/wealth/goals`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlayDebugControllerApi - functional programming interface
 * @export
 */
export const PlayDebugControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlayDebugControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete an agent\'s plan
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWealthGoal(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWealthGoal(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlayDebugControllerApi - factory interface
 * @export
 */
export const PlayDebugControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlayDebugControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary delete an agent\'s plan
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWealthGoal(agentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWealthGoal(agentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlayDebugControllerApi - object-oriented interface
 * @export
 * @class PlayDebugControllerApi
 * @extends {BaseAPI}
 */
export class PlayDebugControllerApi extends BaseAPI {
    /**
     * 
     * @summary delete an agent\'s plan
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayDebugControllerApi
     */
    public deleteWealthGoal(agentId: string, options?: AxiosRequestConfig) {
        return PlayDebugControllerApiFp(this.configuration).deleteWealthGoal(agentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a webhook
         * @param {CreateWebhookRequest} createWebhookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebhook: async (createWebhookRequest: CreateWebhookRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWebhookRequest' is not null or undefined
            assertParamExists('createWebhook', 'createWebhookRequest', createWebhookRequest)
            const localVarPath = `/api/v1/webhooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWebhookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the specified webhook
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebhook: async (webhookId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('deleteWebhook', 'webhookId', webhookId)
            const localVarPath = `/api/v1/webhooks/{webhookId}`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get webhook by id
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhook: async (webhookId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('getWebhook', 'webhookId', webhookId)
            const localVarPath = `/api/v1/webhooks/{webhookId}`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get webhooks for specific user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhookByUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getWebhookByUserId', 'userId', userId)
            const localVarPath = `/api/v1/webhooks/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of events that can be subscribed to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhookEvents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/webhooks/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get webhook providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhookProviders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/webhooks/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace the specified webhook
         * @param {string} webhookId 
         * @param {UpdateWebhookRequest} updateWebhookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebhook: async (webhookId: string, updateWebhookRequest: UpdateWebhookRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('updateWebhook', 'webhookId', webhookId)
            // verify required parameter 'updateWebhookRequest' is not null or undefined
            assertParamExists('updateWebhook', 'updateWebhookRequest', updateWebhookRequest)
            const localVarPath = `/api/v1/webhooks/{webhookId}`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWebhookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the status of the specified webhook
         * @param {string} webhookId 
         * @param {UpdateWebhookStatus} updateWebhookStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebhook1: async (webhookId: string, updateWebhookStatus: UpdateWebhookStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('updateWebhook1', 'webhookId', webhookId)
            // verify required parameter 'updateWebhookStatus' is not null or undefined
            assertParamExists('updateWebhook1', 'updateWebhookStatus', updateWebhookStatus)
            const localVarPath = `/api/v1/webhooks/{webhookId}/status`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWebhookStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a webhook
         * @param {CreateWebhookRequest} createWebhookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWebhook(createWebhookRequest: CreateWebhookRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookCreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWebhook(createWebhookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the specified webhook
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWebhook(webhookId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWebhook(webhookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get webhook by id
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebhook(webhookId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebhook(webhookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get webhooks for specific user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebhookByUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WebhookDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebhookByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of events that can be subscribed to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebhookEvents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookEventsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebhookEvents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get webhook providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebhookProviders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebhookProviders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Replace the specified webhook
         * @param {string} webhookId 
         * @param {UpdateWebhookRequest} updateWebhookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWebhook(webhookId: string, updateWebhookRequest: UpdateWebhookRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWebhook(webhookId, updateWebhookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the status of the specified webhook
         * @param {string} webhookId 
         * @param {UpdateWebhookStatus} updateWebhookStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWebhook1(webhookId: string, updateWebhookStatus: UpdateWebhookStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWebhook1(webhookId, updateWebhookStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a webhook
         * @param {CreateWebhookRequest} createWebhookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebhook(createWebhookRequest: CreateWebhookRequest, options?: any): AxiosPromise<WebhookCreatedResponse> {
            return localVarFp.createWebhook(createWebhookRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the specified webhook
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebhook(webhookId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWebhook(webhookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get webhook by id
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhook(webhookId: string, options?: any): AxiosPromise<WebhookDto> {
            return localVarFp.getWebhook(webhookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get webhooks for specific user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhookByUserId(userId: string, options?: any): AxiosPromise<Array<WebhookDto>> {
            return localVarFp.getWebhookByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of events that can be subscribed to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhookEvents(options?: any): AxiosPromise<WebhookEventsDto> {
            return localVarFp.getWebhookEvents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get webhook providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhookProviders(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getWebhookProviders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace the specified webhook
         * @param {string} webhookId 
         * @param {UpdateWebhookRequest} updateWebhookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebhook(webhookId: string, updateWebhookRequest: UpdateWebhookRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateWebhook(webhookId, updateWebhookRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the status of the specified webhook
         * @param {string} webhookId 
         * @param {UpdateWebhookStatus} updateWebhookStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebhook1(webhookId: string, updateWebhookStatus: UpdateWebhookStatus, options?: any): AxiosPromise<void> {
            return localVarFp.updateWebhook1(webhookId, updateWebhookStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * 
     * @summary Create a webhook
     * @param {CreateWebhookRequest} createWebhookRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public createWebhook(createWebhookRequest: CreateWebhookRequest, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).createWebhook(createWebhookRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the specified webhook
     * @param {string} webhookId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public deleteWebhook(webhookId: string, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).deleteWebhook(webhookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get webhook by id
     * @param {string} webhookId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public getWebhook(webhookId: string, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).getWebhook(webhookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get webhooks for specific user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public getWebhookByUserId(userId: string, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).getWebhookByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of events that can be subscribed to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public getWebhookEvents(options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).getWebhookEvents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get webhook providers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public getWebhookProviders(options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).getWebhookProviders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace the specified webhook
     * @param {string} webhookId 
     * @param {UpdateWebhookRequest} updateWebhookRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public updateWebhook(webhookId: string, updateWebhookRequest: UpdateWebhookRequest, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).updateWebhook(webhookId, updateWebhookRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the status of the specified webhook
     * @param {string} webhookId 
     * @param {UpdateWebhookStatus} updateWebhookStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public updateWebhook1(webhookId: string, updateWebhookStatus: UpdateWebhookStatus, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).updateWebhook1(webhookId, updateWebhookStatus, options).then((request) => request(this.axios, this.basePath));
    }
}


