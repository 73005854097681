import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

interface ReferralAccordionProps {
  name: string;
  content: React.ReactNode;
}

const ReferralAccordion: React.FC<ReferralAccordionProps> = ({
  name,
  content,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className='w-full py-4 bg-white rounded-lg border border-regent-300 flex-col justify-start items-start gap-2 flex cursor-pointer'>
      <div
        className='self-stretch px-4 justify-between items-center inline-flex'
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className='text-center text-primary-dark text-base font-medium font-inter leading-snug'>
          {name}
        </div>
        <div className='w-5 h-5 justify-center items-center flex'>
          <FontAwesomeIcon
            icon={isOpen ? faChevronUp : faChevronDown}
            className='text-primary-dark w-5 h-5'
          />
        </div>
      </div>
      {isOpen && <div className='w-full px-4 py-2'>{content}</div>}
    </div>
  );
};

export default ReferralAccordion;
