import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import {
  Earning,
  EarningIcon,
  EarningNumber,
  EarningTitle,
  EarningType,
  LearnMoreBackground,
  PrimaryColor,
  TertiaryColor,
} from '../WealthManagementTypes';
import { WealthCircleIcon } from '../WealthCircleIcon';
import { setWealthKnowledgeLandingPage } from '../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum } from '../../../types';
import AnalyticsService from '../../../services/AnalyticsService';

const EarningTypeToEventMap: Record<EarningType, AnalyticsEventEnum> = {
  [Earning.COMMISSION_INCOME]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_COMMISSION_INCOME,
  [Earning.PRE_CAP_SPP]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_PRE_CAP,
  [Earning.POST_CAP_SPP]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_POST_CAP,
  [Earning.ELITE_PRODUCTION_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_ELITE_AGENT_PRODUCTION_AWARD,
  [Earning.ELITE_CULTURAL_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_ELITE_AGENT_CULTURAL_AWARD,
  [Earning.STOCK_AWARD_FOR_CAPPING]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_CAPPING_AWARD,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_STOCK_AWARD_FOR_ATTRACTING,
  [Earning.REVENUE_SHARE_INCOME]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_REVENUE_SHARE_INCOME,
};

interface LearnMoreTileProps {
  type: EarningType;
}

export const LearnMoreTile: React.FC<LearnMoreTileProps> = ({ type }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    AnalyticsService.instance().logEvent(EarningTypeToEventMap[type]);
    dispatch(setWealthKnowledgeLandingPage(type));
  };

  return (
    <div
      className='relative h-[330px] w-[296px] rounded-xl m-5 mr-0 cursor-pointer'
      onClick={handleClick}
    >
      <div
        className='rounded-xl border-b-[330px]
  border-l-[296px] border-l-transparent'
        style={{
          background: LearnMoreBackground[type],
          borderBottomColor: TertiaryColor[type],
        }}
      />
      <div className='absolute bottom-8 left-7'>
        <WealthCircleIcon
          icon={EarningIcon[type]}
          iconColor={PrimaryColor[type]}
          iconBackgroundColor={LearnMoreBackground[type]}
          size='4lg'
        />
      </div>
      <div className='absolute top-4 left-4 bg-white border rounded-full h-10 w-10'>
        <div className='text-zen-dark-7 text-0.5xl font-zen-body flex items-center justify-center pt-1'>
          {EarningNumber[type]}
        </div>
      </div>
      <div className='absolute left-5 top-16 w-4/5 text-[1.75rem] text-gray-800 font-inter font-normal'>
        {EarningTitle[type]}
      </div>
      <FontAwesomeIcon
        icon={faChevronRight}
        size='2x'
        className='absolute right-8 bottom-7'
      />
    </div>
  );
};
