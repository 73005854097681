import classNames from 'classnames';
import React from 'react';
import { ReactComponent as CompanyIcon } from '../../../../assets/img/zen/transactionHeader/company.svg';
import { ReactComponent as HomeIcon } from '../../../../assets/img/zen/transactionHeader/home.svg';
import { ReactComponent as ShakeHandsIcon } from '../../../../assets/img/zen/transactionHeader/shakehands.svg';
import {
  TransactionBuilderResponseDealTypeEnum,
  TransactionLiteResponseTransactionTypeEnum,
  TransactionResponseTransactionTypeEnum,
  PendingReviewAndMarkCompliantTransactionTypeEnum,
  ListingToApproveTransactionTypeEnum,
  ChecklistFollowupTransactionTypeEnum,
  TransactionToApproveForCDATransactionTypeEnum,
} from '../../../../openapi/arrakis';
import { PartialEnumMap } from '../../../../types';
import { safePartialEnumMapGet } from '../../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../../utils/StringUtils';

export type DealTypeEnum =
  | TransactionResponseTransactionTypeEnum
  | TransactionLiteResponseTransactionTypeEnum
  | TransactionBuilderResponseDealTypeEnum
  | PendingReviewAndMarkCompliantTransactionTypeEnum
  | ListingToApproveTransactionTypeEnum
  | ChecklistFollowupTransactionTypeEnum
  | TransactionToApproveForCDATransactionTypeEnum;

interface GeminiTransactionTypeCellProps {
  dealType: DealTypeEnum;
  noBackground?: boolean;
}

const GeminiTransactionTypeCell: React.FC<GeminiTransactionTypeCellProps> = ({
  dealType,
  noBackground = false,
}) => {
  const dealTypeToStyleMap: PartialEnumMap<DealTypeEnum, string> = {
    SALE: 'bg-rezen-light-blue-100 text-primary-navy w-18',
    LEASE: 'bg-midnight-green-300 text-midnight-green-900 w-20',
    REFERRAL: 'bg-purple-300 text-purple-900 w-30',
    INTERNAL_REFERRAL: 'bg-sangria-300 text-sangria-900 w-40',
  };

  const style = safePartialEnumMapGet(
    dealTypeToStyleMap,
    dealType,
    'bg-rezen-light-blue-100 text-primary-navy w-18',
  );

  const dealTypeToIconMap: PartialEnumMap<DealTypeEnum, React.ReactElement> = {
    SALE: <HomeIcon width={24} height={24} className='text-primary-navy' />,
    LEASE: (
      <CompanyIcon width={24} height={24} className='text-midnight-green-900' />
    ),
    REFERRAL: (
      <ShakeHandsIcon width={24} height={24} className='text-purple-900' />
    ),
    INTERNAL_REFERRAL: (
      <ShakeHandsIcon width={24} height={24} className='text-sangria-900' />
    ),
  };

  const Icons = safePartialEnumMapGet(
    dealTypeToIconMap,
    dealType,
    <HomeIcon width={24} height={24} className='text-primary-navy' />,
  );

  return (
    <div
      className={classNames('rounded-full', style, {
        '!bg-transparent': noBackground,
      })}
    >
      <div className='flex flex-wrap flex-row justify-center items-center p-1'>
        <span className='flex-shrink-0'>{Icons}</span>
        <p className='font-zen-body font-medium text-sm max-w-full flex-1 truncate'>
          {capitalizeEnum(dealType === 'REFERRAL' ? 'EXT._REFERRAL' : dealType)}
        </p>
      </div>
    </div>
  );
};

export default GeminiTransactionTypeCell;
