import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../types';
import AmplitudeService, {
  AmplitudeEvent,
} from '../../../services/AmplitudeService';
import { fetchTransactionDetails } from '../../../slices/TransactionSlice';
import { getCommissionDocumentName } from '../../../utils/TransactionHelper';
import ZenRoute from '../../Zen/ZenRoute';
import ZenResourceContainer from '../../Zen/ZenResourceContainer';
import ZenAgentOnboardingLayout from '../../ZenAgentOnboardingLayout';
import ZenApproveCommissionDocumentIntro from './ZenApproveCommissionDocumentIntro';
import ZenApproveCommissionDocument from './ZenApproveCommissionDocument';
import ZenViewCommissionDocument from './ZenViewCommissionDocument';

type Match = {
  id: string;
};

const ZenTransactionCommissionDocumentReview: React.FC = () => {
  const history = useHistory();
  const { id: transactionId } = useParams() as Match;
  const dispatch = useDispatch<AppDispatch>();
  const {
    transactionDetailResponse: { data: transactionDetail, loading },
  } = useSelector((state: RootState) => state.transaction);

  useEffect(() => {
    if (!transactionDetail) {
      dispatch(fetchTransactionDetails(transactionId));
    }
  }, [dispatch, transactionDetail, transactionId]);

  const [index, setIndex] = useState<number>(0);

  const renderContent = () => {
    switch (index) {
      case 0:
        return (
          <ZenApproveCommissionDocumentIntro
            transaction={transactionDetail!}
            onNext={() => setIndex(index + 1)}
            goBack={() => {
              AmplitudeService.logEvent(
                AmplitudeEvent.TX_ONBOARDING_CDA_APPROVAL_CANCEL,
                { currentStep: index },
              );

              history.replace(`/transactions/${transactionDetail?.id}/detail`);
            }}
          />
        );
      case 1:
        return (
          <ZenViewCommissionDocument
            transaction={transactionDetail!}
            onClick={() => setIndex(index + 1)}
          />
        );
      case 2:
        return (
          <ZenApproveCommissionDocument
            transaction={transactionDetail!}
            goBack={() => {
              AmplitudeService.logEvent(
                AmplitudeEvent.TX_ONBOARDING_CDA_APPROVAL_CANCEL,
                { currentStep: index },
              );

              history.replace(`/transactions/${transactionDetail?.id}/detail`);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ZenRoute title='Commission Document Review'>
      <ZenResourceContainer
        loading={loading}
        isEmpty={!transactionDetail}
        resourceName='transaction'
      >
        <ZenAgentOnboardingLayout
          title={`Approve ${getCommissionDocumentName(
            transactionDetail?.country!,
          )}`}
          onClose={() => history.push('/transactions')}
          hideLogout
        >
          <div className='w-full h-full flex justify-center items-center'>
            {renderContent()}
          </div>
        </ZenAgentOnboardingLayout>
      </ZenResourceContainer>
    </ZenRoute>
  );
};

export default ZenTransactionCommissionDocumentReview;
