import React from 'react';
import ZenPopover from '../../../ZenPopover';

interface LeaderSplitPopoverProps {}

const LeaderSplitPopover: React.FC<LeaderSplitPopoverProps> = () => {
  return (
    <div className='font-inter text-sm mt-7 text-zen-dark-9'>
      Leader’s Split
      <ZenPopover
        title='Leader’s Split'
        description={
          <div className='text-zen-dark-9 text-base font-inter font-light'>
            <p>
              The Leader&apos;s Split is the percentage amount that is
              automatically deducted from the Teammate&apos;s gross amount and
              credited to the Team Leader. When there are multiple Team Leaders,
              each can have their own split.
            </p>
          </div>
        }
      />
    </div>
  );
};

export default LeaderSplitPopover;
