import { useParams } from 'react-router-dom';
import ZenRoute from '../components/Zen/ZenRoute';
import CDAForm from './CDAForm';

type Match = {
  id: string;
};

const CDAFormRoute: React.FC = () => {
  const { id } = useParams() as Match;

  return (
    <ZenRoute title='CDA Form'>
      <CDAForm commissionDocumentId={id} isPdf={false} />
    </ZenRoute>
  );
};

export default CDAFormRoute;
