import { useParams } from 'react-router-dom';
import ZenRoute from '../components/Zen/ZenRoute';
import CDAForm from './CDAForm';

type Match = {
  id: string;
  participantId?: string;
};

const TransactionTradeRecordSheetRoute: React.FC = () => {
  const { id, participantId } = useParams() as Match;

  return (
    <ZenRoute title='Transaction Trade Record Sheet'>
      <CDAForm
        commissionDocumentId={id}
        participantId={participantId}
        isPdf={false}
      />
    </ZenRoute>
  );
};

export default TransactionTradeRecordSheetRoute;
