import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import ZenControlledAsyncSelectInput from '../components/Zen/Input/ZenControlledAsyncSelectInput';
import ZenSidebarModalForm from '../components/Zen/ZenSidebarModalForm';
import { AgentControllerApi } from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { fetchAgentDetail } from '../slices/AgentSlice';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { AppDispatch, AsyncSelectOption, ISelectOption } from '../types';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { officeTableFetchData } from '../utils/TableUtils';

interface FormData {
  officeId: ISelectOption;
}

interface ZenAddOfficeToUserSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  agentId: string;
}

const ZenAddOfficeToUserSidebarModal: React.FC<ZenAddOfficeToUserSidebarModalProps> = ({
  isOpen,
  onClose,
  agentId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (formValues: FormData) => {
    try {
      await new AgentControllerApi(getYentaConfiguration()).addOffice(
        agentId,
        formValues.officeId?.value,
      );
      onClose();
      dispatch(fetchAgentDetail(agentId));
      dispatch(showSuccessToast('Added office successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to add the office', e, {
        agent: { id: agentId },
        office: { id: formValues.officeId.value },
      });
      dispatch(
        showErrorToast(
          'We had a problem adding the office',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <ZenSidebarModalForm
      title='Add Office to Agent'
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Add'
      isSubmitting={isSubmitting}
    >
      <div>
        <ZenControlledAsyncSelectInput
          control={control}
          name='officeId'
          label='Office To Add'
          placeholder='Select an office...'
          fetchData={async (search, page = 0) => {
            try {
              const { data } = await officeTableFetchData({
                page,
                pageSize: 20,
                search,
              });
              const activeOffices = data.filter((office) => office.active);
              const options: AsyncSelectOption[] = activeOffices.map(
                (resp) => ({
                  value: `${resp.id}`,
                  label: `${resp.name}`,
                }),
              );

              return options;
            } catch (e) {
              ErrorService.notify(
                'Unable to search request offices in add office to user form',
                e,
                {
                  search: {
                    term: search,
                    page,
                  },
                },
              );
              dispatch(
                showErrorToast(
                  'An unexpected error occurred.',
                  'We were unable to search for an office. Please try again in a few moments or contact support.',
                ),
              );
            }
            return [];
          }}
          rules={{
            required: 'Please select the office',
          }}
          isRequired
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenAddOfficeToUserSidebarModal;
