import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faRotate } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEvent } from 'react';
import { isSmScreen } from '../../utils/BrowserUtils';
import { cn } from '../../utils/classUtils';

export type GeminiButtonVariant =
  | 'primary'
  | 'primary-outline'
  | 'primary-link'
  | 'secondary'
  | 'secondary-outline'
  | 'secondary-link'
  | 'dark'
  | 'dark-outline'
  | 'dark-link'
  | 'success'
  | 'success-outline'
  | 'success-link'
  | 'danger'
  | 'danger-outline'
  | 'danger-link';

export type ButtonDisabledVariant =
  | 'disabled'
  | 'disabled-outline'
  | 'disabled-link';

export type GeminiButtonSize = 'md' | 'sm';

export interface GeminiButtonProps {
  label?: string;
  type?: 'button' | 'submit' | 'reset';
  leftIcon?: IconProp;
  rightIcon?: IconProp;
  variant?: GeminiButtonVariant;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  onClick?:
    | ((e: MouseEvent<HTMLElement>) => void)
    | ((e: MouseEvent<HTMLButtonElement>) => void)
    | (() => void);
  isFullWidth?: boolean;
  size?: GeminiButtonSize;
}

const GeminiButton: React.FC<GeminiButtonProps> = ({
  label,
  type = 'button',
  leftIcon,
  rightIcon,
  onClick,
  variant = 'primary',
  isSubmitting = false,
  isDisabled = false,
  isFullWidth = false,
  size,
}) => {
  const variantClassNamesMap: Record<GeminiButtonVariant, string> = {
    primary: 'bg-rezen-blue-600 text-white',
    'primary-outline':
      'ring-2 ring-rezen-blue-600 ring-inset text-rezen-blue-600',
    'primary-link': 'text-rezen-blue-600',
    secondary: 'bg-grey-300 text-primary-dark',
    'secondary-outline': 'ring-2 ring-grey-300 ring-inset text-primary-dark',
    'secondary-link': 'text-grey-300',
    dark: 'bg-primary-dark text-white',
    'dark-outline': 'ring-2 ring-primary-dark ring-inset text-primary-dark',
    'dark-link': 'text-primary-dark',
    success: 'bg-green-600 text-white',
    'success-outline': 'ring-2 ring-green-600 ring-inset text-green-600',
    'success-link': 'text-green-600',
    danger: 'bg-red-600 text-white',
    'danger-outline': 'ring-2 ring-red-600 ring-inset text-red-600',
    'danger-link': 'text-red-600',
  };

  const disabledVariantClassNamesMap: Record<ButtonDisabledVariant, string> = {
    disabled: 'bg-regent-400 text-white',
    'disabled-outline': 'ring-2 border-regent-400 text-regent-400',
    'disabled-link': 'text-regent-400',
  };

  const btnSize: GeminiButtonSize = size ?? (isSmScreen() ? 'sm' : 'md');
  const sizeClassNamesMap: Record<GeminiButtonSize, string> = {
    md: 'text-base py-2.5 px-4',
    sm: 'text-sm py-2 px-4',
  };

  const getDisabledVariant = (variant: GeminiButtonVariant) => {
    switch (variant) {
      case 'primary':
      case 'dark':
      case 'success':
      case 'danger':
        return 'disabled';
      case 'primary-outline':
      case 'dark-outline':
      case 'success-outline':
      case 'danger-outline':
        return 'disabled-outline';
      case 'primary-link':
      case 'dark-link':
      case 'success-link':
      case 'danger-link':
        return 'disabled-link';
      default:
        return 'disabled';
    }
  };

  return (
    <button
      type={type}
      disabled={isDisabled}
      onClick={onClick}
      className={cn(
        'flex items-center justify-center rounded-md font-inter leading-5 font-medium space-x-1.5',
        sizeClassNamesMap[btnSize],
        isDisabled
          ? disabledVariantClassNamesMap[getDisabledVariant(variant)]
          : variantClassNamesMap[variant],
        isDisabled && 'cursor-not-allowed',
        isFullWidth && 'w-full',
      )}
    >
      {isSubmitting && (
        <FontAwesomeIcon icon={faRotate} className='animate-spin' />
      )}
      {!!leftIcon && (
        <div className='h-full'>
          <FontAwesomeIcon icon={leftIcon} />
        </div>
      )}
      {!!label && <span>{label}</span>}
      {rightIcon && (
        <div className='h-full'>
          <FontAwesomeIcon icon={rightIcon} />
        </div>
      )}
    </button>
  );
};

export default GeminiButton;
