import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row } from 'react-table';
import {
  confirmDepositPayment,
  getDepositInstallmentReceipt,
} from '../../../slices/TransactionSlice';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import { getCheckDepositsDetails } from '../../../slices/CheckDepositsSlice';
import { AppDispatch } from '../../../types';
import {
  EscrowDepositResponse,
  EscrowResponse,
  TransactionResponse,
} from '../../../openapi/arrakis';
import ZenDepositDetailInstallmentTable from '../TrustDeposits/ZenDepositDetailInstallmentTable';
import ZenCommissionDepositAddForm from './ZenCommissionDepositAddForm';

interface ZenCommissionDepositInstallmentDetailsTableProps {
  row: Row<EscrowResponse>;
  transaction: TransactionResponse;
}

const ZenCommissionDepositInstallmentDetailsTable: React.FC<ZenCommissionDepositInstallmentDetailsTableProps> = ({
  row,
  transaction,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [
    isDepositConfirm,
    setIsDepositConfirm,
  ] = useState<EscrowDepositResponse>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isOpenDepositModal, SetIsOpenDepositModal] = useState<boolean>(false);
  const [deposit, setDeposit] = useState<EscrowDepositResponse>();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await dispatch(
      confirmDepositPayment(row.original.id!, isDepositConfirm?.id!),
    );
    setIsSubmitting(false);
    setIsDepositConfirm(undefined);
  };

  const handleViewCheckDeposit = async (checkDepositId: string) => {
    await dispatch(getCheckDepositsDetails(transaction.id!, checkDepositId));
  };

  const handleViewProofOfDeposit = async (depositId: string) => {
    const receiptURL = await dispatch(
      getDepositInstallmentReceipt(
        transaction?.id!,
        row.original?.id!,
        depositId,
      ),
    );
    window.open(receiptURL, '_blank');
  };

  return (
    <div className='mx-auto my-5 w-full max-w-4xl scrollbar overflow-x-auto flex justify-center'>
      <ZenDepositDetailInstallmentTable
        deposit={row.original}
        transaction={transaction}
        onViewCheckDeposit={handleViewCheckDeposit}
        onViewProofOfDeposit={handleViewProofOfDeposit}
        onConfirm={(p) => setIsDepositConfirm(p)}
        onEdit={(p) => {
          setDeposit(p);
          SetIsOpenDepositModal(true);
        }}
      />
      {isOpenDepositModal && (
        <ZenCommissionDepositAddForm
          onClose={() => SetIsOpenDepositModal(false)}
          commissionDepositDetails={deposit}
          transaction={transaction}
          escrowId={row.original.id!}
        />
      )}
      <ZenConfirmationModal
        isOpen={!!isDepositConfirm}
        onClose={() => setIsDepositConfirm(undefined)}
        onConfirm={handleSubmit}
        isSubmitting={isSubmitting}
        title='Confirm installment has been received?'
        subtitle='This will confirm that the installment has been received. Confirm installment?'
        variant='primary'
      />
    </div>
  );
};

export default ZenCommissionDepositInstallmentDetailsTable;
