import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenButton from '../../ZenButton';
import {
  CreateBeneficiaryV2FormData,
  CreateBeneficiaryV2StepName,
} from './CreateBeneficiaryScreenV2';
import Message from './Message';
import { UpdateBeneficiaryV2StepName } from './UpdateBeneficiaryScreenV2';

const CreateBeneficiarySuccessStepV2: StepByStepComponent<
  CreateBeneficiaryV2FormData,
  CreateBeneficiaryV2StepName | UpdateBeneficiaryV2StepName
> = ({ onNext }) => {
  return (
    <div className='w-full relative shadow-zen-web bg-white rounded-xl'>
      <Message
        title='You’ve  successfully designated a beneficiary!'
        subtitleComponent={
          <div className='mx-7'>
            <p className='w-full px-8 font-zen-body text-base text-dark text-center leading-6'>
              <span>
                You can find and change the beneficiary in your Profile under
                the Revenue Share section.
              </span>
            </p>
          </div>
        }
      />
      <div className='absolute rounded-b-xl bg-white bottom-0 right-0 left-0 z-50 px-14'>
        <div className='justify-center flex flex-row items-center py-8'>
          <div className='w-1/2'>
            <ZenButton
              label='Okay'
              type='button'
              onClick={onNext}
              variant='primary-outline'
              isFullWidth
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBeneficiarySuccessStepV2;
