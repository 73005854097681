import { last } from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsService from '../../../../services/AnalyticsService';
import {
  getPostCapSpp,
  updateWealthGoals,
} from '../../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum, RootState } from '../../../../types';
import ZenControlledSquareToggleInput from '../../../Zen/Input/ZenControlledSquareToggleInput';
import { GoalsFormInput } from '../../V2/SetGoalsSideBarV2';
import { Earning } from '../../WealthManagementTypes';

const SppParticipationToggleV2: React.FC = () => {
  const {
    wealthGoalsResponse: { data: goals },
    selectedEarningsOverviewType,
  } = useSelector((state: RootState) => state.wealthManagement);
  const sppParticipation = goals?.goalDetails.sppParticipation;
  const selectedType = last(selectedEarningsOverviewType);

  const dispatch = useDispatch();

  const { control, watch } = useForm<GoalsFormInput>({
    defaultValues: {
      sppParticipation: true,
    },
  });

  const watchSppParticipation = watch('sppParticipation');

  useEffect(() => {
    if (selectedType === Earning.PRE_CAP_SPP) {
      AnalyticsService.instance().logEvent(
        watchSppParticipation
          ? AnalyticsEventEnum.WEALTH_PLAN_PRE_CAP_SPP_TO_YES
          : AnalyticsEventEnum.WEALTH_PLAN_PRE_CAP_SPP_TO_NO,
      );
    } else if (selectedType === Earning.POST_CAP_SPP) {
      AnalyticsService.instance().logEvent(
        watchSppParticipation
          ? AnalyticsEventEnum.WEALTH_PLAN_POST_CAP_SPP_TO_YES
          : AnalyticsEventEnum.WEALTH_PLAN_POST_CAP_SPP_TO_NO,
      );
    }

    if (sppParticipation !== watchSppParticipation) {
      dispatch(
        updateWealthGoals({
          sppParticipation: watchSppParticipation,
        }),
      );
    }
  }, [dispatch, selectedType, sppParticipation, watchSppParticipation]);

  useEffect(() => {
    if (selectedType === Earning.POST_CAP_SPP) {
      dispatch(getPostCapSpp());
    } else if (selectedType === Earning.PRE_CAP_SPP) {
      dispatch(getPostCapSpp());
    }
  }, [dispatch, selectedType]);

  return (
    <ZenControlledSquareToggleInput<GoalsFormInput, 'sppParticipation'>
      control={control}
      name='sppParticipation'
      label='Will you participate in the Stock Purchase Plan (SPP)?'
    />
  );
};

export default SppParticipationToggleV2;
