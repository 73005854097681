import { useEffect, useState } from 'react';
import {
  Control,
  DeepPartial,
  FieldValues,
  FormState,
  Path,
  SubmitHandler,
  UnpackNestedValue,
  useForm,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form-v7';
import {
  Mode,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
} from 'react-hook-form-v7/dist/types/form';
import { cn } from '../../utils/classUtils';
import Logger from '../../utils/Logger';
import StepByStepContainerPagination from './StepByStepContainerPagination';

export interface StepByStepComponentProps<
  FormData extends FieldValues,
  NameEnum
> {
  onNext(): void;
  onPrevious(): void;
  form: {
    control: Control<FormData, object>;
    watch: UseFormWatch<FormData>;
    formState: FormState<FormData>;
    trigger: UseFormTrigger<FormData>;
    getValues: UseFormGetValues<FormData>;
    setValue: UseFormSetValue<FormData>;
    setError: UseFormSetError<FormData>;
    clearErrors: UseFormClearErrors<FormData>;
  };
  onSubmit(): void;
  navigateTo(stepName: NameEnum): void;
  progress: { currentIndex: number; lastIndex: number };
  onClose?(): void;
}

export type StepByStepComponent<
  FormData extends FieldValues,
  NameEnum
> = React.FC<StepByStepComponentProps<FormData, NameEnum>>;

export interface StepConfig<FormData extends FieldValues, NameEnum> {
  name: NameEnum;
  Component: StepByStepComponent<FormData, NameEnum>;
  hidePagination?: boolean;
  enableSkip?: {
    keysToResetOnSkip: Path<FormData>[];
  };
  showStepIf?(values: UnpackNestedValue<FormData>): boolean;
}

interface StepByStepContainerProps<FormData extends FieldValues, NameEnum> {
  steps: StepConfig<FormData, NameEnum>[];
  onSubmit: SubmitHandler<FormData>;
  defaultValues: UnpackNestedValue<DeepPartial<FormData>>;
  mode?: Mode;
  reValidateMode?: Exclude<Mode, 'onTouched' | 'all'>;
  pageStyle?: string;
  onChangeStep?(step: StepConfig<FormData, NameEnum>): void;
}

const StepByStepContainer = <FormData extends Record<string, any>, NameEnum>({
  steps,
  onSubmit,
  defaultValues,
  mode,
  reValidateMode,
  onChangeStep,
  pageStyle,
}: StepByStepContainerProps<FormData, NameEnum>) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [visitedIndexes, setVisitedIndexes] = useState<any>({});
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    trigger,
    watch,
    clearErrors,
    formState,
  } = useForm<FormData>({
    defaultValues,
    mode,
    reValidateMode,
  });

  const filteredSteps: StepConfig<FormData, NameEnum>[] = steps.filter((s) =>
    s.showStepIf ? s.showStepIf(watch()) : true,
  );

  const didVisitIndex = (i: number): boolean => !!visitedIndexes[i];

  useEffect(() => {
    if (didVisitIndex(currentIndex)) {
      // This fixes an issue where the validations wouldn't properly
      // trigger if you went back and forth to a previous screen
      trigger();
    } else {
      setVisitedIndexes({ ...visitedIndexes, [currentIndex]: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  const currentStep: StepConfig<FormData, NameEnum> =
    filteredSteps[currentIndex];

  useEffect(() => {
    onChangeStep?.(currentStep);
  }, [currentStep, onChangeStep]);

  const isLastStep: boolean = currentIndex === filteredSteps.length - 1;

  const onPrevious = () => setCurrentIndex(currentIndex - 1);

  const onNext = () => setCurrentIndex(currentIndex + 1);

  const onSkip = () => {
    // If you skip, you want to reset certain keys to undefined.
    (currentStep.enableSkip?.keysToResetOnSkip || []).forEach((key) =>
      // @ts-ignore
      setValue(key, undefined),
    );

    setCurrentIndex(currentIndex + 1);
  };

  const navigateTo = (stepName: NameEnum): void => {
    const stepIndex = filteredSteps.findIndex((s) => s.name === stepName);

    if (stepIndex === -1) {
      Logger.warn('Could not navigate to step=', stepName);
    } else {
      setCurrentIndex(stepIndex);
    }
  };

  const handleNext = handleSubmit(isLastStep ? onSubmit : onNext);

  return (
    <div
      className={cn(
        pageStyle ??
          'w-full h-full px-4 pt-5  flex flex-col justify-center items-center relative',
      )}
    >
      <currentStep.Component
        onNext={handleNext}
        onPrevious={onPrevious}
        form={{
          control,
          watch,
          formState,
          trigger,
          getValues,
          setValue,
          setError,
          clearErrors,
        }}
        onSubmit={handleSubmit(onSubmit)}
        navigateTo={navigateTo}
        progress={{ currentIndex, lastIndex: filteredSteps.length - 1 }}
      />

      {!currentStep.hidePagination && (
        <div className='sticky max-w-6xl w-full bottom-0 z-0 bg-white flex flex-col justify-center items-center'>
          <div className='max-w-6xl w-full'>
            <StepByStepContainerPagination
              previousText={currentIndex !== 0 ? 'Previous' : ''}
              onPrevious={onPrevious}
              nextText={
                currentIndex === filteredSteps.length - 1 ? 'Submit' : 'Next'
              }
              isNextLoading={formState.isSubmitting}
              onNext={handleNext}
              onSkip={handleSubmit(onSkip)}
              showSkip={!!currentStep.enableSkip}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StepByStepContainer;
