import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ZenSideBarItem from '../ZenSideBarItem';

interface UserRealAcademySidebarMenuItemProps {
  isCollapsed: boolean;
}

const UserRealAcademySidebarMenuItem: React.FC<UserRealAcademySidebarMenuItemProps> = ({
  isCollapsed,
}) => {
  return (
    <ZenSideBarItem
      icon={<FontAwesomeIcon icon={regular('graduation-cap')} />}
      label='Real Academy'
      target='_blank'
      linkTo='/learnworlds/sso'
      collapsed={isCollapsed}
    />
  );
};

export default UserRealAcademySidebarMenuItem;
