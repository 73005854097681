import { useCallback, useMemo } from 'react';

export const DOTS = '...';

interface usePaginationProps {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  siblingCount?: number;
  beforeOrAfterSiblingCount?: number;
}

const useZenPagination = ({
  totalCount,
  pageSize,
  currentPage,
  siblingCount = 1,
  beforeOrAfterSiblingCount = 1,
}: usePaginationProps): (string | number)[] => {
  const range = useCallback((start, end) => {
    let length = end - start + 1;
    return new Array(length).fill(null).map((_, index) => start + index);
  }, []);

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    const startIndex = 1;
    const lastIndex = totalPageCount;

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount,
    );

    // We do not want to show dots if there is only one position left at start/end
    const showLeftDots = leftSiblingIndex > beforeOrAfterSiblingCount;
    const showRightDots =
      rightSiblingIndex < totalPageCount - beforeOrAfterSiblingCount;

    if (!showLeftDots && showRightDots) {
      let leftItemCount = beforeOrAfterSiblingCount + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (showLeftDots && !showRightDots) {
      let rightItemCount = beforeOrAfterSiblingCount + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount,
      );
      return [startIndex, DOTS, ...rightRange];
    }

    if (showLeftDots && showRightDots) {
      let middleRange = range(leftSiblingIndex + 1, rightSiblingIndex + 1);
      return [startIndex, DOTS, ...middleRange, DOTS, lastIndex];
    }

    return range(startIndex, lastIndex);
  }, [
    totalCount,
    pageSize,
    siblingCount,
    currentPage,
    beforeOrAfterSiblingCount,
    range,
  ]);

  return paginationRange;
};

export default useZenPagination;
