import { values } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TitleInfo } from '../../../../openapi/arrakis';
import { addRealTitle } from '../../../../slices/TransactionBuilderSlice';
import { AppDispatch, RootState, YesNoType } from '../../../../types';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledRadioInput from '../../Input/ZenControlledRadioInput';
import ZenConfirmationModal from '../../Modal/ZenConfirmationModal';
import ZenButton from '../../ZenButton';
import { getTransactionRealTitleName } from '../../../../utils/RealTitleUtils';
import ZenRealTitleInformation from './SidebarForms/ZenRealTitleInformation';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
  Match,
} from './ZenCreateTransactionSteps';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';

const ZenTransactionUseRealTitleStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({ form: { control, watch, trigger }, onPrevious, onNext }) => {
  const dispatch: AppDispatch = useDispatch();
  const { transactionBuilderId } = useParams<Match>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openSidebarModal, setOpenSidebarModal] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(
    false,
  );

  const {
    transactionBuilder: { transactionBuilder },
  } = useSelector((state: RootState) => state);

  const hasRealTitle = watch('hasRealTitle');

  const handleAddRealTitle = async () => {
    const isValid = await trigger();
    if (isValid) {
      const addRealTitleRequest: TitleInfo = {
        useRealTitle: hasRealTitle === YesNoType.YES,
      };
      setLoading(true);
      const res = await dispatch(
        addRealTitle(transactionBuilderId!, addRealTitleRequest),
      );
      setLoading(false);
      if (res) {
        onNext();
      }
    }
  };

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <p className='text-xl font-zen-title font-normal text-zen-dark-9 mb-4'>
          Are you using&nbsp;
          <button
            onClick={() => setOpenSidebarModal(true)}
            className='underline text-primary-blue cursor-pointer'
          >
            {getTransactionRealTitleName(transactionBuilder?.address?.state)}
          </button>
          &nbsp;for this transaction?
        </p>
        <ZenControlledRadioInput<CreateTransactionFormState, 'hasRealTitle'>
          name='hasRealTitle'
          control={control}
          inlineOptions
          shouldUnregister={false}
          options={values(YesNoType).map((value) => ({
            label: capitalizeEnum(value),
            value,
          }))}
          rules={{ required: 'Please select an option' }}
        />

        {openSidebarModal && (
          <ZenRealTitleInformation
            isOpen={openSidebarModal}
            onClose={() => setOpenSidebarModal(false)}
          />
        )}
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              isDisabled={loading}
              label='Next'
              onClick={() => {
                if (hasRealTitle === YesNoType.YES) {
                  setOpenConfirmationModal(true);
                } else {
                  handleAddRealTitle();
                }
              }}
            />
          </div>
        </div>
      </div>
      <ZenConfirmationModal
        isOpen={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        confirmButtonText='Yes'
        isSubmitting={loading}
        isDisabled={loading}
        onConfirm={async () => {
          await handleAddRealTitle();
          setOpenConfirmationModal(false);
        }}
        hideIcon
        size='large'
        title='Action requires confirmation'
        subtitle={`By continuing you are declaring that ${getTransactionRealTitleName(
          transactionBuilder?.address?.state,
        )} is the closing agent on this transaction.`}
      />
    </div>
  );
};

export default withCreateTransactionProgress(ZenTransactionUseRealTitleStep);
