import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FileResponse } from '../../../../openapi/dropbox';
import { getExtensionFromFileName } from '../../../../utils/FileUtils';
import { EnumMap } from '../../../../types';

type FileTypeVariant = 'pdf' | 'image' | 'txt' | 'other';

export const fileTypeToIconMap: EnumMap<FileTypeVariant, React.ReactElement> = {
  pdf: (
    <FontAwesomeIcon
      icon={solid('file-pdf')}
      className='text-zen-dark-5 text-2xl'
    />
  ),
  image: (
    <FontAwesomeIcon
      icon={solid('file-image')}
      className='text-zen-dark-5 text-2xl'
    />
  ),
  txt: (
    <FontAwesomeIcon
      icon={solid('envelope')}
      className='text-zen-dark-5 text-2xl'
    />
  ),
  other: (
    <FontAwesomeIcon
      icon={solid('file')}
      className='text-zen-dark-5 text-2xl'
    />
  ),
};

interface TemplateItemNameCellProps {
  file: FileResponse;
  handleOpenFile(): Promise<void>;
  hideIcon?: boolean;
}

const ZenFileCabinetFileNameCell: React.FC<TemplateItemNameCellProps> = ({
  file,
  handleOpenFile,
  hideIcon = false,
}) => {
  return (
    <div className='flex flex-row items-start font-zen-body'>
      {!hideIcon && (
        <div className='mr-3 h-9 w-9 min-w-[36px] flex items-center justify-center rounded-[5px] text-zen-dark-5'>
          {fileTypeToIconMap[getExtensionFromFileName(file.path!)]}
        </div>
      )}
      <div>
        <div className='flex flex-row items-center mt-2'>
          <span className='text-sm font-normal text-zen-dark-9'>
            {file.filename}{' '}
            <button
              onClick={(e) => {
                e.preventDefault();
                handleOpenFile();
              }}
              className='ml-1'
            >
              <FontAwesomeIcon
                icon={regular('arrow-up-right-from-square')}
                className='text-xs text-primary-blue cursor-pointer -mb-px'
              />
            </button>
          </span>
        </div>
        {/* DESCRIPTION | TODO: UNCOMMENT & IMPLEMENT AFTER VERIFICATION */}
        {/* <div className='text-xs font-semibold text-zen-dark-6'>
          Hello this is a description that goes here.
        </div> */}
      </div>
    </div>
  );
};

export default ZenFileCabinetFileNameCell;
