import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { cn } from '../../utils/classUtils';

export interface ZenSidebarModalProps {
  title?: string;
  subtitle?: string;
  isOpen: boolean;
  customHeader?: React.ReactElement;
  onClose(): void;
  size?: 'md' | 'lg';
  displayleft?: boolean;
  closeicon?: boolean;
}

const SizeMap = {
  md: 'lg:w-[650px]',
  lg: 'lg:w-[1091px]',
};

const ZenSidebarModal: React.FC<ZenSidebarModalProps> = ({
  title,
  subtitle,
  isOpen,
  customHeader,
  onClose,
  children,
  size = 'md',
  displayleft = false,
  closeicon = false,
}) => {
  if (!isOpen) {
    return null;
  }

  const disleft = displayleft ? 'mr-auto' : 'ml-auto';
  const slideleft = displayleft
    ? 'lg:animate-slide-in-left'
    : 'lg:animate-slide-in-right';
  const buttonclose = closeicon ? 'py-0' : 'py-4';

  return (
    <div
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full text-gray-600 h-full-window'
      title='sidebar'
    >
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 hidden bg-black lg:block bg-opacity-40'
        role='button'
        onClick={onClose}
        title='close'
      />
      <div
        className={cn(
          `relative bg-white flex flex-col w-full h-full ${disleft} animate-slide-in-up ${slideleft}`,
          SizeMap[size],
        )}
      >
        {!!customHeader ? (
          customHeader
        ) : (
          <div
            className={`flex items-center justify-between px-5 ${buttonclose} bg-zen-light-blue`}
          >
            <div className='space-y-1.5'>
              {title && (
                <h2 className='text-md font-zen-body font-bold text-zen-dark-9 tracking-wide'>
                  {title}
                </h2>
              )}
              {subtitle && (
                <p className='text-[15px] text-gray-600'>{subtitle}</p>
              )}
            </div>
            {!closeicon && (
              <button
                onClick={onClose}
                title='Close'
                className='h-6 w-6 flex items-center justify-center bg-zen-dark-10 rounded-full p-1'
                data-testid='close-modal'
              >
                <FontAwesomeIcon icon={faXmark} className='text-white' />
              </button>
            )}
          </div>
        )}
        <div className='h-full scrollbar overflow-y-auto' id='modal-scroll'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ZenSidebarModal;
