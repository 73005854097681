import { EnumMap } from '../../../../types';
import { parseInitials } from '../../../../utils/StringUtils';
import { cn } from '../../../../utils/classUtils';

export type AgentProfileImageSizeType = 'small' | 'medium' | 'large';

interface AgentProfileImagePreviewProps {
  imageUrl?: string;
  name: string;
  size?: AgentProfileImageSizeType;
}

const AgentProfileImagePreview: React.FC<AgentProfileImagePreviewProps> = ({
  imageUrl,
  name,
  size = 'large',
}) => {
  const sizeToClassNameMap: EnumMap<AgentProfileImageSizeType, string> = {
    small: 'w-6 h-6',
    medium: 'w-14 h-14',
    large: 'w-32 h-32',
  };

  const sizeToTextStyleClassNameMap: EnumMap<
    AgentProfileImageSizeType,
    string
  > = {
    small: 'text-sm',
    medium: 'text-2xl',
    large: 'text-[64px] leading-[64px]',
  };

  if (imageUrl) {
    return (
      <img
        className={cn('rounded-full object-cover', sizeToClassNameMap[size])}
        src={imageUrl}
        alt='avatar'
      />
    );
  }

  return (
    <span
      className={cn(
        'inline-flex items-center justify-center rounded-full bg-white',
        sizeToClassNameMap[size],
      )}
    >
      <span
        className={cn(
          'font-primary-bold text-zen-dark-7',
          sizeToTextStyleClassNameMap[size],
        )}
      >
        {parseInitials(name)}
      </span>
    </span>
  );
};

export default AgentProfileImagePreview;
