import { faCheck, faXmark } from '@fortawesome/pro-light-svg-icons';
import { faCircleXmark, faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import { GoalDetails } from '../../openapi/plutus';
import AnalyticsService from '../../services/AnalyticsService';
import {
  setWealthKnowledgeLandingPage,
  updateWealthGoals,
} from '../../slices/WealthManagementSlice';
import { AnalyticsEventEnum } from '../../types';
import ZenButton from '../Zen/ZenButton';
import { Earning, EarningType } from './WealthManagementTypes';

export type WealthManagementParticipationType = keyof Pick<
  GoalDetails,
  | 'sppParticipation'
  | 'eliteAgentProductionAwardParticipation'
  | 'eliteAgentCulturalAwardParticipation'
  | 'revShareParticipation'
>;

const Title: Record<WealthManagementParticipationType, string> = {
  sppParticipation: 'Will you join the Stock Purchase Plan?',
  eliteAgentProductionAwardParticipation:
    'Would you like to participate in the Elite Agent Production Award?',
  eliteAgentCulturalAwardParticipation:
    'Would you like to participate in the Elite Cultural Production Award?',
  revShareParticipation:
    'Would you like to participate in Revenue Share Income?',
};

const LandingPage: Record<WealthManagementParticipationType, EarningType> = {
  sppParticipation: Earning.PRE_CAP_SPP,
  eliteAgentCulturalAwardParticipation: Earning.ELITE_CULTURAL_AWARD,
  eliteAgentProductionAwardParticipation: Earning.ELITE_PRODUCTION_AWARD,
  revShareParticipation: Earning.REVENUE_SHARE_INCOME,
};

const AnalyticEventYes: Partial<Record<EarningType, AnalyticsEventEnum>> = {
  [Earning.PRE_CAP_SPP]: AnalyticsEventEnum.WEALTH_PLAN_JOIN_SPP_PRE_YES,
  [Earning.POST_CAP_SPP]: AnalyticsEventEnum.WEALTH_PLAN_JOIN_SPP_POST_YES,
  [Earning.ELITE_PRODUCTION_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_ELITE_PRODUCTION_YES,
  [Earning.ELITE_CULTURAL_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_ELITE_CULTURAL_YES,
  [Earning.REVENUE_SHARE_INCOME]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_REV_SHARE_YES,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_ATTRACTING_YES,
};

const AnalyticEventNo: Partial<Record<EarningType, AnalyticsEventEnum>> = {
  [Earning.PRE_CAP_SPP]: AnalyticsEventEnum.WEALTH_PLAN_JOIN_SPP_PRE_NO,
  [Earning.POST_CAP_SPP]: AnalyticsEventEnum.WEALTH_PLAN_JOIN_SPP_POST_NO,
  [Earning.ELITE_PRODUCTION_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_ELITE_PRODUCTION_NO,
  [Earning.ELITE_CULTURAL_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_ELITE_CULTURAL_NO,
  [Earning.REVENUE_SHARE_INCOME]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_REV_SHARE_NO,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_ATTRACTING_NO,
};

const AnalyticEventLearnMore: Partial<
  Record<EarningType, AnalyticsEventEnum>
> = {
  [Earning.PRE_CAP_SPP]: AnalyticsEventEnum.WEALTH_PLAN_JOIN_SPP_PRE_LEARN_MORE,
  [Earning.POST_CAP_SPP]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_SPP_POST_LEARN_MORE,
  [Earning.ELITE_PRODUCTION_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_ELITE_PRODUCTION_LEARN_MORE,
  [Earning.ELITE_CULTURAL_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_ELITE_CULTURAL_LEARN_MORE,
  [Earning.REVENUE_SHARE_INCOME]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_REV_SHARE_LEARN_MORE,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_ATTRACTING_LEARN_MORE,
};

const AnalyticEventClose: Partial<Record<EarningType, AnalyticsEventEnum>> = {
  [Earning.PRE_CAP_SPP]: AnalyticsEventEnum.WEALTH_PLAN_JOIN_SPP_PRE_CLOSE,
  [Earning.POST_CAP_SPP]: AnalyticsEventEnum.WEALTH_PLAN_JOIN_SPP_POST_CLOSE,
  [Earning.ELITE_PRODUCTION_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_ELITE_PRODUCTION_CLOSE,
  [Earning.ELITE_CULTURAL_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_ELITE_CULTURAL_CLOSE,
  [Earning.REVENUE_SHARE_INCOME]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_REV_SHARE_CLOSE,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]:
    AnalyticsEventEnum.WEALTH_PLAN_JOIN_ATTRACTING_CLOSE,
};

export interface UnlockEarningModalProps {
  isOpen: boolean;
  subtitle?: string | React.ReactElement;

  onClose(openSidebar?: boolean): void;

  isSubmitting?: boolean;
  isDisabled?: boolean;
  confirmButtonLeftIcon?: React.ReactElement;
  landingPageUrl?: string;
  wealthGoalProperty: WealthManagementParticipationType;
  earningType: EarningType | null;
}

export const UnlockEarningModal: React.FC<UnlockEarningModalProps> = ({
  isOpen,
  subtitle,
  onClose,
  isSubmitting,
  isDisabled,
  confirmButtonLeftIcon,
  wealthGoalProperty,
  earningType,
}) => {
  const dispatch = useDispatch();
  if (!isOpen) {
    return null;
  }

  const logEvent = (event: string | undefined) => {
    if (event) {
      AnalyticsService.instance().logEvent(event);
    }
  };

  const handleYesClick = () => {
    if (earningType) {
      logEvent(AnalyticEventYes[earningType]);
    }
    dispatch(
      updateWealthGoals({
        [wealthGoalProperty]: true,
        ...(wealthGoalProperty === 'eliteAgentProductionAwardParticipation' && {
          seenEliteProductionPage: true,
        }),
        ...(wealthGoalProperty === 'eliteAgentCulturalAwardParticipation' && {
          seenEliteCulturalPage: true,
        }),
      }),
    );
    onClose(true);
  };

  const handleLearnMore = () => {
    if (earningType) {
      logEvent(AnalyticEventLearnMore[earningType]);
    }
    onClose();
    dispatch(setWealthKnowledgeLandingPage(LandingPage[wealthGoalProperty]));
  };

  const handleNo = () => {
    if (earningType) {
      logEvent(AnalyticEventNo[earningType]);
    }
    onClose();
  };

  const handleClose = () => {
    if (earningType) {
      logEvent(AnalyticEventClose[earningType]);
    }
    onClose();
  };

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'
        role='button'
        onClick={handleClose}
      />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
        <div
          className='w-full bg-white rounded-xl z-10 shadow-zen-web md:w-1/2 lg:w-1/4'
          aria-label='confirmation-modal'
        >
          <div className='flex flex-row items-center bg-white rounded-t-xl border-gray-200 relative'>
            <div className='flex flex-grow flex-shrink flex-col items-center justify-center gap-y-2 my-3'>
              <span className='h-16 w-16 rounded-full flex flex-row items-center justify-center bg-primary bg-gradient-to-r from-blue-500 to-bg-primary border-4 border-slate-200 mb-3'>
                <FontAwesomeIcon
                  icon={faLock}
                  size='lg'
                  className='text-white'
                />
              </span>
              <p className='text-base text-center font-primary-medium text-zen-dark-9'>
                {Title[wealthGoalProperty]}
              </p>
            </div>

            <FontAwesomeIcon
              onClick={handleClose}
              icon={faCircleXmark}
              className='text-gray-400 absolute top-2 right-2 cursor-pointer'
              size='lg'
            />
          </div>
          <div className='w-full'>
            {subtitle && (
              <p className='text-base text-center text-gray-500 font-primary-regular p-4'>
                {subtitle}
              </p>
            )}
            <div className='flex flex-row my-3 space-x-3 px-4 pb-1'>
              <ZenButton
                isFullWidth
                label={
                  (
                    <div className='flex justify-center items-center gap-2'>
                      <FontAwesomeIcon icon={faXmark} size='sm' />
                      No
                    </div>
                  ) as any
                }
                onClick={handleNo}
                variant='secondary-outline'
              />
              <ZenButton
                isFullWidth
                label={
                  (
                    <div className='flex justify-center items-center gap-2'>
                      <FontAwesomeIcon icon={faCheck} size='sm' />
                      Yes
                    </div>
                  ) as any
                }
                onClick={handleYesClick}
                isDisabled={isDisabled}
                isSubmitting={isSubmitting}
                LeftIconComponent={confirmButtonLeftIcon}
              />
            </div>
          </div>
          <div
            onClick={handleLearnMore}
            className='flex flex-row mb-3 space-x-3 px-4 pb-4 justify-center text-primary-blue cursor-pointer'
          >
            Learn More
          </div>
        </div>
      </div>
    </div>
  );
};
