import {
  Controller,
  FieldValues,
  UseControllerProps,
  FieldPath,
} from 'react-hook-form-v7';
import IncrementalSlider from './IncrementalSlider';

interface ControlledIncrementalSliderV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  minValue: number;
  maxValue: number;
  step: number;
  minValueLabel?: string;
  maxValueLabel?: string;
  disabled?: boolean;
}

const ControlledIncrementalSliderV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  minValue,
  maxValue,
  step,
  minValueLabel,
  maxValueLabel,
  disabled,
  ...rest
}: ControlledIncrementalSliderV7Props<TFieldValues, TName>) => {
  return (
    <div className='space-y-1 px-5 w-full'>
      <Controller
        {...rest}
        render={({ field: { onChange, value } }) => (
          <IncrementalSlider
            value={value}
            minValue={minValue}
            maxValue={maxValue}
            step={step}
            onChange={onChange}
            minValueLabel={minValueLabel}
            maxValueLabel={maxValueLabel}
            disabled={disabled}
          />
        )}
      />
    </div>
  );
};
export default ControlledIncrementalSliderV7;
