import { DateTime } from 'luxon';
import React, { FormEvent } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { TaxDocumentResponse } from '../../openapi/arrakis';
import { setTaxAlertData } from '../../slices/TaxSlice';
import { EmailSupportType, RootState } from '../../types';
import { getTaxType } from '../../utils/AgentHelper';
import { displayAmount } from '../../utils/CurrencyUtils';
import { openEmailClientForSupportEmail } from '../../utils/SupportUtils';
import ZenSidebarModalActionFooter from '../SidebarModal/ZenSideBarModalActionFooter';
import ZenControlledTextAreaInput from '../Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenCardWithItems from '../Zen/ZenCardWithItems';
import ZenOutlineActionButton from '../Zen/ZenOutlineButton';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import { capitalizeEnum } from '../../utils/StringUtils';

interface ZenTaxReportIssueProps {
  isOpen: boolean;
  onClose(): void;
  taxDocumentDetails: TaxDocumentResponse;
}

interface FormData {
  taxReportYear: string;
  taxReportDescription: string;
}

const ZenTaxReportIssue: React.FC<ZenTaxReportIssueProps> = ({
  isOpen,
  onClose,
  taxDocumentDetails,
}) => {
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  const { control, getValues } = useForm<FormData>();
  const taxType = getTaxType(taxDocumentDetails?.taxDocumentType!);
  const dispatch = useDispatch();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    openEmailClientForSupportEmail(
      EmailSupportType.TAX_SUPPORT,
      userDetail!,
      `Issue with my ${taxType} form for tax year ${taxDocumentDetails.year}
     
      Description

      ${getValues().taxReportDescription}
      `,
    );
    dispatch(
      setTaxAlertData({
        description: `Your request email has been sent to Support. We’ll look into this and follow up via email as soon as possible.`,
        taxFormState: 'SUCCESS',
        title: 'SUPPORT EMAIL SENT',
      }),
    );
  };

  let taxReportYearValue = `Issue with my ${taxType} form for tax year ${taxDocumentDetails.year}`;
  if (taxDocumentDetails.stateOrProvince) {
    taxReportYearValue += ` - ${capitalizeEnum(
      taxDocumentDetails.stateOrProvince,
    )}`;
  }

  return (
    <ZenSidebarModal
      title={`Report a ${taxType} issue`}
      isOpen={isOpen}
      onClose={onClose}
      subtitle='Send an email to support'
    >
      <form
        className='p-4 flex flex-col min-h-full space-y-4 justify-between'
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className='space-y-4'>
          <div className='flex flex-row  space-x-2 items-center'>
            <p className='text-xl font-primary-medium'>
              {taxType} Form - Tax Year {taxDocumentDetails?.year}{' '}
              {taxDocumentDetails.stateOrProvince &&
                `- ${capitalizeEnum(taxDocumentDetails.stateOrProvince)}`}
            </p>
            <p className='font-primary-light'>
              {' '}
              Generated on{' '}
              {DateTime.fromMillis(
                taxDocumentDetails?.createdAt! || 0,
              ).toFormat('LL/dd/yy')}
            </p>
          </div>
          <ZenCardWithItems
            items={[
              {
                name: 'Total Net Commission',
                value: displayAmount(taxDocumentDetails?.netCommissionPaid),
                rightActionComponent: (
                  <ZenOutlineActionButton
                    direction='rl'
                    text='View'
                    icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                    onClick={() =>
                      window.open(
                        `/people/${
                          userDetail?.id
                        }/tax-documents/${taxDocumentDetails?.year!}/net-commissions`,
                      )
                    }
                    type='button'
                  />
                ),
              },
              {
                name: 'Total Rev. Share Paid',
                value: displayAmount(taxDocumentDetails?.revsharePaid),
                rightActionComponent: (
                  <ZenOutlineActionButton
                    direction='rl'
                    text='View'
                    icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                    onClick={() =>
                      window.open(
                        `/people/${
                          userDetail?.id
                        }/tax-documents/${taxDocumentDetails?.year!}/rev-share`,
                      )
                    }
                    type='button'
                  />
                ),
              },
              {
                name: `Total Equity Contribution (${userDetail?.defaultCurrency})`,
                value: displayAmount(taxDocumentDetails?.equityContributed),
                rightActionComponent: (
                  <ZenOutlineActionButton
                    direction='rl'
                    text='View'
                    icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                    onClick={() =>
                      window.open(
                        `/people/${
                          userDetail?.id
                        }/tax-documents/${taxDocumentDetails?.year!}/equity`,
                      )
                    }
                    type='button'
                  />
                ),
              },
              {
                name: `Total Equity Match (${userDetail?.defaultCurrency})`,
                value: displayAmount(taxDocumentDetails?.releasedEquityMatch),
                rightActionComponent: (
                  <ZenOutlineActionButton
                    direction='rl'
                    text='View'
                    icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                    onClick={() =>
                      window.open(
                        `/people/${
                          userDetail?.id
                        }/tax-documents/${taxDocumentDetails?.year!}/equity-match`,
                      )
                    }
                    type='button'
                  />
                ),
              },
              {
                name: `Total Equity Earned by Awards (${userDetail?.defaultCurrency})`,
                value: displayAmount(taxDocumentDetails?.releasedAwardsEquity),
                rightActionComponent: (
                  <ZenOutlineActionButton
                    direction='rl'
                    text='View'
                    icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                    onClick={() =>
                      window.open(
                        `/people/${
                          userDetail?.id
                        }/tax-documents/${taxDocumentDetails?.year!}/equity-awards`,
                      )
                    }
                    type='button'
                  />
                ),
              },
            ]}
          />
          <div className='space-y-2'>
            <p className='font-primary-medium'>Send an email to Support</p>
            <p className='font-primary-light'>
              Briefly explain the issue. Include the disputed amount, expected
              amount, and any additional information.
            </p>
            <div className='space-y-2'>
              <ZenControlledTextInput<FormData, 'taxReportYear'>
                name='taxReportYear'
                control={control}
                defaultValue={taxReportYearValue}
                readOnly
              />
              <ZenControlledTextAreaInput<FormData, 'taxReportDescription'>
                name='taxReportDescription'
                control={control}
                placeholder='Description'
                rows={4}
              />
            </div>
          </div>
        </div>
        <ZenSidebarModalActionFooter
          onClose={onClose}
          submitButtonText='Send Email'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenTaxReportIssue;
