import { faTag } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface TemplateItemLabelProps {
  label: string;
}

const TemplateItemLabel: React.FC<TemplateItemLabelProps> = ({ label }) => {
  return (
    <div className='flex flex-row items-center gap-x-1'>
      <FontAwesomeIcon icon={faTag} className='text-zen-dark-6' />
      <span className='font-zen-body font-semibold text-sm text-zen-dark-6'>
        {label}
      </span>
    </div>
  );
};

export default TemplateItemLabel;
