import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { cn } from '../../../utils/classUtils';
import { UpdateAnnouncementRequestActionEnum } from '../../../openapi/mercury';
import {
  dismissAnnouncement,
  savePreviewAnnouncementId,
} from '../../../slices/AnnouncementSlice';
import { RootState } from '../../../types';

export interface DismissTooltipProps {
  preview: boolean;
}

const DismissTooltip: React.FC<DismissTooltipProps> = ({ preview }) => {
  const { topAnnouncement } = useSelector(
    (state: RootState) => state.announcement,
  );
  const dispatch = useDispatch();
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const closeIconStyle = {
    '--fa-primary-color': '#ffffff',
    '--fa-secondary-color': '#B2BDC6',
    '--fa-secondary-opacity': 1,
  };

  const isDismissible = !(
    topAnnouncement?.dismissCount === topAnnouncement?.maxDismissalCount
  );

  const dismissModal = async () => {
    setIsLoading(true);
    await dispatch(
      dismissAnnouncement(
        topAnnouncement?.definitionId!,
        UpdateAnnouncementRequestActionEnum.Dismiss,
      ),
    );
    setIsLoading(false);
  };

  const handleClose = () => {
    if (preview) {
      dispatch(savePreviewAnnouncementId(undefined));
      return;
    }
    if (isDismissible) {
      if (!isLoading) {
        dismissModal();
      }
      return;
    }
    setIsTooltipOpen(true);
  };

  return (
    <Popover
      isOpen={isTooltipOpen}
      positions={['top', 'bottom']}
      padding={5}
      align='center'
      onClickOutside={() => setIsTooltipOpen(false)}
      content={({ position, childRect, popoverRect }) => {
        return (
          <ArrowContainer
            position={position}
            popoverRect={popoverRect}
            childRect={childRect}
            arrowColor='white'
            arrowSize={10}
            className='popover-arrow-container drop-shadow-lg'
          >
            <div
              data-testid='announcemen-popup-tooltip'
              className='w-60 p-4 rounded-lg bg-white drop-shadow-lg flex flex-row'
            >
              <FontAwesomeIcon
                icon={solid('circle-exclamation')}
                className='text-zen-danger mt-0.5 mr-1.5'
              />
              <p className='font-zen-body font-semibold text-zen-dark-9 text-sm'>
                Please acknowledge this announcement by clicking the “I
                Acknowledge” button before moving forward
              </p>
            </div>
          </ArrowContainer>
        );
      }}
    >
      <FontAwesomeIcon
        icon={duotone('circle-xmark')}
        style={closeIconStyle as CSSProperties}
        className={cn('z-20 absolute right-4 top-4 text-xl cursor-pointer', {
          'opacity-50': isLoading,
        })}
        onClick={handleClose}
        aria-label='announcement-close'
      />
    </Popover>
  );
};

export default DismissTooltip;
