import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../../routes/AgentWebsiteOnboardingRoute';
import { PropertyFilterCategoryTypeEnum } from '../../../openapi/yenta';
import {
  getDisplayBaths,
  getDisplayBeds,
} from '../../../utils/AgentWebsiteUtils';
import {
  capitalizeEnum,
  displayStringOrNA,
  displayStringOrNotSelected,
} from '../../../utils/StringUtils';
import { numberToMoney } from '../../../utils/CurrencyUtils';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ReviewRow from '../../ReviewRow';
import EditOutlineActionButton from '../EditOutlineActionButton';

const AgentWebsiteOnboardingListingsReview: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ form: { watch }, navigateTo }) => {
  const [propertyFilter, location, priceRange] = watch([
    'propertyFilter',
    'location',
    'priceRange',
  ]);

  return (
    <div className='p-4'>
      <div className='grid lg:grid-cols-2 grid-cols-1 gap-5'>
        <ReviewRow
          label='Category:'
          value={
            propertyFilter?.categoryType
              ? capitalizeEnum(propertyFilter?.categoryType!)
              : 'N/A'
          }
        />
        {propertyFilter?.categoryType! !==
        PropertyFilterCategoryTypeEnum.Mls ? (
          <ReviewRow
            label='Location:'
            value={displayStringOrNA(location?.formatted_address!)}
          />
        ) : (
          <ReviewRow
            label='MLS Number/Name:'
            value={displayStringOrNA(propertyFilter?.mlsNumber)}
          />
        )}
        <ReviewRow
          label='Property Type:'
          value={displayStringOrNotSelected(
            propertyFilter?.homeJunctionPropertyTypes
              ?.map((propertyType) => capitalizeEnum(propertyType))
              .join(', '),
          )}
        />
        <ReviewRow
          label='Price Range (USD):'
          value={`${numberToMoney(priceRange?.min!)} to ${numberToMoney(
            priceRange?.max!,
          )}`}
        />
        <ReviewRow
          label='Beds and Baths:'
          value={`${getDisplayBeds(
            propertyFilter?.bedrooms!,
          )} Bedrooms, ${getDisplayBaths(
            propertyFilter?.bathrooms!,
          )} Bathrooms`}
        />
      </div>
      <div className='mt-5 mb-2'>
        <EditOutlineActionButton
          text='Edit listings'
          icon={<FontAwesomeIcon icon={faPen} className='mt-1 mr-1' />}
          onClick={() => navigateTo(AgentWebsiteOnboardingStepName.LISTINGS)}
        />
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingListingsReview;
