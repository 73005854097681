import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AutorenewRounded from '@material-ui/icons/AutorenewRounded';
import { GroupMemberResponse } from '../../openapi/yenta';
import ZenAvatar from '../Zen/ZenAvatar';
import { getFullName } from '../../utils/AgentHelper';
import { capitalizeEnum, formatPhoneNumber } from '../../utils/StringUtils';
import { removeOfficeGroupMember } from '../../slices/OfficeSlice';
import GeminiCopyToClipboard from '../Gemini/GeminiCopyToClipborad';
import GeminiSimpleConfirmationModal from '../Gemini/Modal/GeminiSimpleConfirmationModal';
import { cn } from '../../utils/classUtils';
import AdminOnly from '../auth/AdminOnly';
import GeminiHover from '../Gemini/GeminiHover';

interface GeminiOfficeGroupCardRowProps {
  member: GroupMemberResponse;
  officeId: string;
  groupId: string;
  isVisible?: boolean;
  length?: number;
}

export const truncateEmail = (email: string): string => {
  if (window.innerWidth <= 900) {
    const atIndex = email.indexOf('@');
    const prefix = email.substring(0, atIndex);
    const truncatedPrefix = prefix.substring(0, 6) + '...';
    let domain = email.substring(atIndex + 1);
    if (domain.length > 15) {
      domain = domain.substring(0, 10) + '...';
    }
    return truncatedPrefix + '@' + domain;
  } else {
    if (email?.length > 25 && window.innerWidth < 1400) {
      return email.substring(0, 20) + '...';
    }
    return email;
  }
};

const GeminiOfficeGroupCardRow: React.FC<GeminiOfficeGroupCardRowProps> = ({
  member,
  officeId,
  groupId,
  isVisible,
  length,
}) => {
  const dispatch = useDispatch();
  const [removeMember, setRemoveMember] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const nameRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (nameRef.current) {
      const isTruncated =
        nameRef.current.scrollWidth > nameRef.current.clientWidth;
      setIsTruncated(isTruncated);
    }
  }, [member]);

  const handleRemoveMember = async () => {
    setIsDeleting(true);
    await dispatch(
      removeOfficeGroupMember(officeId, groupId, { userId: member?.user?.id! }),
    );
    setIsDeleting(false);
    setRemoveMember(false);
  };
  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex flex-row justify-between space-x-2 px-4 py-6 w-full',
        length === 1 ? 'border-none' : 'border-b border-gray-200',
      )}
      aria-label='office-group-card-row'
    >
      <div className='flex gap-2'>
        <div className='flex flex-row items-start'>
          <Link to={`/people/${member?.user?.id}/profile`}>
            <div className='hidden md:block'>
              <ZenAvatar
                name={getFullName(member?.user) || 'Agent Name'}
                imageUrl={member?.user?.avatar}
                size='xxl'
              />
            </div>
            <div className='block md:hidden'>
              <ZenAvatar
                name={getFullName(member?.user) || 'Agent Name'}
                imageUrl={member?.user?.avatar}
              />
            </div>
          </Link>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col md:flex-row md:items-center gap-1 md:gap-4'>
            <Link to={`/people/${member?.user?.id}/profile`}>
              {isTruncated ? (
                <GeminiHover
                  hoverComponent={
                    <p className='font-inter text-primary-dark text-sm md:text-base font-medium break-all'>
                      {getFullName(member?.user) || 'N/A'}
                    </p>
                  }
                  config={{ trigger: 'hover', placement: 'top' }}
                >
                  <p
                    className='font-inter text-primary-dark text-sm md:text-base font-medium break-all max-w-[200px] truncate'
                    ref={nameRef}
                  >
                    {getFullName(member?.user) || 'N/A'}
                  </p>
                </GeminiHover>
              ) : (
                <p
                  className='font-inter text-primary-dark text-sm md:text-base font-medium break-all max-w-[200px] truncate'
                  ref={nameRef}
                >
                  {getFullName(member?.user) || 'N/A'}
                </p>
              )}
            </Link>
            <hr className='h-4 w-0.5 bg-gray-200 hidden md:block' />
            <p className='font-inter font-light text-xs text-grey-500'>
              {member?.role ? capitalizeEnum(member?.role) : 'N/A'}
            </p>
          </div>
          <div className='flex flex-col gap-2'>
            <GeminiCopyToClipboard
              value={formatPhoneNumber(member?.user?.phoneNumber) || 'N/A'}
              leftIconElement={
                <FontAwesomeIcon
                  icon={light('phone')}
                  className='text-primary-dark h-4 w-4'
                />
              }
              variant='secondary'
              label={formatPhoneNumber(member?.user?.phoneNumber) || 'N/A'}
            />
            <GeminiCopyToClipboard
              value={member?.user?.emailAddress || 'N/A'}
              leftIconElement={
                <FontAwesomeIcon
                  icon={light('envelope')}
                  className='text-primary-dark h-4 w-4'
                />
              }
              variant='secondary'
              label={truncateEmail(member?.user?.emailAddress ?? '') || 'N/A'}
            />
          </div>
        </div>
      </div>
      <AdminOnly>
        <div className='flex flex-row items-start md:items-center flex-shrink-0 space-x-2'>
          <button
            className='flex justify-center items-center bg-zen-danger-light rounded-full w-6 h-6 p-[17.5px]'
            onClick={() => {
              setRemoveMember(true);
            }}
            disabled={removeMember}
            aria-label='remove-group-member'
          >
            {isDeleting ? (
              <AutorenewRounded className='animate-spin' fontSize='small' />
            ) : (
              <div>
                <div className='hidden md:flex items-center justify-center'>
                  <FontAwesomeIcon
                    icon={light('trash-can')}
                    className='text-zen-danger h-5 w-5'
                  />
                </div>
                <div className='flex items-center justify-center md:hidden'>
                  <FontAwesomeIcon
                    icon={light('trash-can')}
                    className='text-zen-danger'
                    size='sm'
                  />
                </div>
              </div>
            )}
          </button>
        </div>
      </AdminOnly>
      <GeminiSimpleConfirmationModal
        isOpen={removeMember}
        onClose={() => {
          setRemoveMember(false);
        }}
        onConfirm={handleRemoveMember}
        title='Are you sure you want to delete this member?'
        isSubmitting={isDeleting}
        variant='danger'
        confirmButtonText='Delete'
        size='fixed'
      >
        <div className='text-center px-6 py-4 text-primary-dark font-normal text-base font-inter'>
          Once deleted, member will be removed from the team and needs to be
          added again
        </div>
      </GeminiSimpleConfirmationModal>
    </div>
  );
};

export default GeminiOfficeGroupCardRow;
