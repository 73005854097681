import React, { useCallback, useEffect, useState } from 'react';
import useAgentsInfo from '../../../hooks/useAgentsInfo';
import { ChecklistResponse, ItemResponse } from '../../../openapi/sherlock';
import { RezenObjectTypeEnum } from '../../../openapi/yada';
import ResourceContainer from '../../ResourceContainer';
import ZenRoadToSuccessJourneyStep from './ZenRoadToSuccessJourneyStep';
import { ChecklistType } from './ZenVerticalJourneyStepProgress';

interface ZenRoadToSuccessSectionProps {
  containerType: RezenObjectTypeEnum;
  checklistType?: ChecklistType;
  fetchJourney(): Promise<ChecklistResponse>;
  onFetchJourneyError: (e: any) => void;
  toggleJourneyItem(
    stepId: string,
    toggleState: boolean,
  ): Promise<ItemResponse>;
  onToggleJourneyItemError: (
    e: any,
    stepId: string,
    toggleState: boolean,
  ) => void;
  getPublicUserInfo?: boolean;
}

const ZenRoadToSuccessSection: React.FC<ZenRoadToSuccessSectionProps> = ({
  containerType,
  checklistType = ChecklistType.ROAD_TO_SUCCESS,
  fetchJourney,
  onFetchJourneyError,
  toggleJourneyItem,
  onToggleJourneyItemError,
  getPublicUserInfo = false,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [journeyItems, setJourneyItems] = useState<ItemResponse[]>([]);

  const handleFetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchJourney();
      setJourneyItems(response.items || []);
    } catch (e) {
      onFetchJourneyError(e);
    } finally {
      setIsLoading(false);
    }
  }, [fetchJourney, onFetchJourneyError]);

  const handleToggleJourneyItem = useCallback(
    async (stepId: string, toggleState: boolean) => {
      try {
        const response = await toggleJourneyItem(stepId, toggleState);

        let updatedItems = journeyItems;

        const itemIndex = updatedItems.findIndex(
          (item) => item.id === response.id!,
        );

        if (itemIndex !== -1) {
          updatedItems[itemIndex!] = response;
        }

        setJourneyItems([...updatedItems]);
      } catch (e) {
        onToggleJourneyItemError(e, stepId, toggleState);
      }
    },
    [journeyItems, onToggleJourneyItemError, toggleJourneyItem],
  );

  const agentIds = journeyItems
    .map((item) => item.assignee!)
    .filter((assignee) => !!assignee);

  const markedAgentIds = journeyItems
    .map((item) => item?.markedCompletedBy!)
    .filter((markedAgent) => !!markedAgent);

  useAgentsInfo([...agentIds, ...markedAgentIds], getPublicUserInfo);

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 my-7 mx-0 md:mx-4 gap-4 scrollbar overflow-x-auto'>
      <ResourceContainer
        loading={isLoading}
        isEmpty={!journeyItems}
        resourceName='Journey'
      >
        <div>
          <div className='py-4 my-2.5'>
            <ol>
              {journeyItems.map((step, index) => {
                return (
                  <ZenRoadToSuccessJourneyStep
                    key={step.id}
                    isLastStep={index === journeyItems.length - 1}
                    step={step}
                    handleToggleJourneyItem={handleToggleJourneyItem}
                    checklistType={checklistType}
                    containerType={containerType}
                  />
                );
              })}
            </ol>
          </div>
        </div>
      </ResourceContainer>
    </div>
  );
};

export default ZenRoadToSuccessSection;
