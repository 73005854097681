import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ChecklistResponse } from '../../../../openapi/sherlock';
import {
  AnalyticsEventEnum,
  ImageContent,
  ISelectOption,
} from '../../../../types';
import ZenConfirmationModal from '../../Modal/ZenConfirmationModal';
import { showApiErrorModal } from '../../../../slices/ErrorSlice';
import ErrorService from '../../../../services/ErrorService';
import { showErrorToastForErrorCode } from '../../../../slices/ToastNotificationSlice';
import config from '../../../../config';
import { forceReporting } from '../../../../utils/DatadogHelper';
import AnalyticsService from '../../../../services/AnalyticsService';
import { RezenObjectTypeEnum } from '../../../../openapi/yada';
import ZenPreviewPDFPages from './ZenPreviewPDFPages';
import ZenSplitPdfPreviewAndAssign from './ZenSplitPdfPreviewAndAssign';
import ZenUploadPDF from './ZenUploadPDF';
import ZenPDFLoading from './ZenPDFLoading';

export interface SplitPDFFormData {
  pdfFile: File[];
  assignTo: ISelectOption | undefined;
  selectStartPageNumber?: string;
  selectEndPageNumber?: string;
}

interface ZenSplitAndAttachPDFProps {
  checklist: ChecklistResponse;
  pdfLink?: string;
  containerType: RezenObjectTypeEnum;
  containerId: string;
  dropboxId: string;
  backUrl: string;
}

const ZenSplitAndAttachPDF: React.FC<ZenSplitAndAttachPDFProps> = ({
  checklist,
  pdfLink,
  containerType,
  containerId,
  dropboxId,
  backUrl,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const form = useForm<SplitPDFFormData>({
    mode: 'onChange',
    defaultValues: {
      pdfFile: [],
      selectEndPageNumber: '',
      selectStartPageNumber: '',
    },
  });

  const {
    watch,
    control,
    trigger,
    formState: { errors },
  } = form;

  const pdfFile = watch('pdfFile');
  const [selectedPages, setSelectedPages] = useState<ImageContent[]>([]);
  const [pdfImages, setPDFImages] = useState<any | undefined>();
  const [pdfUrl, setPDFUrl] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isInvalidPDF, setIsInvalidPDF] = useState<boolean>(false);

  useEffect(() => {
    const setUploadPDFs = async () => {
      const isValid = await trigger();
      if (isValid) {
        try {
          setLoading(true);
          // temporary code. move to slice when swagger docs are ready.
          const url = `${config.apiBasePath.dali}/api/convert-pdf-to-images`;
          const formData = new FormData();
          formData.append('pdf', pdfFile[0]);
          const { data } = await axios.post(url, formData, {
            headers: { 'content-type': 'multipart/form-data' },
          });
          if (data) {
            setPDFImages(data.images);
            setPDFUrl(data.pdfUrl);
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.SPLIT_PDF_CONVERTED_PDF_TO_IMAGES,
            );
          } else {
            setIsInvalidPDF(true);
          }
        } catch (e) {
          dispatch(showApiErrorModal(e));
          ErrorService.notify(
            'Unable to process the pdf to images.',
            forceReporting(e),
          );
          dispatch(
            showErrorToastForErrorCode(
              'We were unable to process the pdf. Please try again in a few moments.',
              ErrorService.getErrorCode(e),
            ),
          );
        } finally {
          setLoading(false);
        }
      }
    };

    const setUploadPDFsViaUrl = async () => {
      const isValid = await trigger();
      if (isValid) {
        try {
          setLoading(true);
          // temporary code. move to slice when swagger docs are ready.
          const url = `${config.apiBasePath.dali}/api/convert-pdf-url-to-images-and-upload-to-s3`;

          const { data } = await axios.post(
            url,
            { url: pdfLink },
            {
              headers: { 'content-type': 'application/json' },
            },
          );
          if (data) {
            setPDFImages(data.images);
            setPDFUrl(data.pdfUrl);
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.SPLIT_PDF_CONVERTED_PDF_TO_IMAGES_FROM_URL,
            );
          } else {
            setIsInvalidPDF(true);
          }
        } catch (e) {
          dispatch(showApiErrorModal(e));
          ErrorService.notify(
            'Unable to process the pdf to images from file cabinet url.',
            forceReporting(e),
          );
          dispatch(
            showErrorToastForErrorCode(
              'We were unable to process the pdf. Please try again in a few moments.',
              ErrorService.getErrorCode(e),
            ),
          );
        } finally {
          setLoading(false);
        }
      }
    };
    if (!!pdfFile.length && !pdfLink) {
      setUploadPDFs();
    } else if (!!pdfLink) {
      setUploadPDFsViaUrl();
    }
  }, [pdfLink, pdfFile, pdfFile.length, trigger, dispatch]);

  const onBack = () => {
    if (history.location.key) {
      history.go(-1);
    } else if (!!backUrl) {
      history.push(backUrl);
    }
  };

  return (
    <>
      {loading && (
        <ZenPDFLoading onBack={onBack} pdf={pdfFile[0] || undefined} />
      )}

      {!pdfLink && (!pdfImages || !!errors.pdfFile) && !loading && (
        <ZenUploadPDF control={control} onBack={onBack} />
      )}

      {pdfImages && !errors.pdfFile && !loading && (
        <div className='h-screen w-screen flex relative'>
          <FontAwesomeIcon
            icon={regular('close')}
            className='absolute right-3 top-3 cursor-pointer text-zen-dark-9 z-10'
            onClick={() => {
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.SPLIT_PDF_CLOSED,
              );
              onBack();
            }}
            data-testid='close-split-pdf'
          />
          <div className='w-36 flex-col justify-center h-screen border-r'>
            <ZenPreviewPDFPages
              form={form}
              pdfImages={pdfImages}
              selectedPages={selectedPages}
              setSelectedPages={setSelectedPages}
            />
          </div>
          <div className='flex-grow h-screen'>
            <ZenSplitPdfPreviewAndAssign
              pdfUrl={pdfUrl}
              selectedPages={selectedPages!}
              setSelectedPages={setSelectedPages}
              checklist={checklist}
              containerId={containerId}
              containerType={containerType}
              form={form}
              backUrl={backUrl}
              dropboxId={dropboxId}
            />
          </div>
        </div>
      )}

      <ZenConfirmationModal
        isOpen={isInvalidPDF}
        variant='danger'
        title='The uploaded PDF is invalid'
        confirmButtonText='Okay'
        subtitle='The PDF you uploaded is not a valid PDF/A format. Please convert it and reupload.'
        onConfirm={() => window.location.reload()}
        hideCancelButton
        hideIcon
      />
    </>
  );
};

export default ZenSplitAndAttachPDF;
