import { SubmitHandler, useForm } from 'react-hook-form-v7';
import Button from '../Button';
import ConfirmationModal from '../ConfirmationModal';
import { RowsSelectionAction } from '../../types';

interface FilterConfirmationModalProps<D extends object> {
  selection: RowsSelectionAction<D>;
  isSubmitting: boolean;
  onClick: SubmitHandler<D>;
  onClose(): void;
}

const FilterConfirmationModal = <D extends object>({
  selection,
  isSubmitting,
  onClick,
  onClose,
}: FilterConfirmationModalProps<D>) => {
  const { control, handleSubmit } = useForm<D>({
    reValidateMode: 'onChange',
  });

  return (
    <ConfirmationModal
      variant={selection.confirm!.modalType || 'info'}
      title={selection.confirm!.title}
      subtitle={selection.confirm!.description}
      isOpen
      onClose={onClose}
    >
      <div className='flex flex-col'>
        {selection?.confirm?.childJSX && selection?.confirm?.childJSX(control)}
        <div className='flex items-center mt-3 space-x-3'>
          <Button
            label={selection.confirm!.primaryActionTitle || 'Confirm'}
            type={selection.confirm!.primaryActionType || 'primary'}
            isSubmitting={isSubmitting}
            onClick={handleSubmit(onClick)}
          />
          <Button
            label={selection.confirm!.secondaryActionTitle || 'Cancel'}
            type={selection.confirm!.secondaryActionType || 'secondary'}
            onClick={onClose}
          />
        </div>
      </div>
    </ConfirmationModal>
  );
};

export default FilterConfirmationModal;
