import { Redirect, RedirectProps } from 'react-router-dom';
import useQueryParams from '../../hooks/useQueryParams';
import Logger from '../../utils/Logger';

export interface ZenRedirectProps extends RedirectProps {}

interface Query {
  redirectTo?: string;
}

const ZenRedirect: React.FC<ZenRedirectProps> = ({ to, ...rest }) => {
  const { redirectTo } = useQueryParams<Query>();

  let finalTo = to;

  if (redirectTo?.startsWith('/')) {
    finalTo = redirectTo;
  } else if (redirectTo) {
    finalTo = `/${redirectTo}`;
  }

  Logger.debug('ZenRedirect: redirecting to', decodeURIComponent(finalTo));

  return <Redirect to={decodeURIComponent(finalTo)} {...rest} />;
};

export default ZenRedirect;
