import { chunk, flatten } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  AgentControllerApi,
  AgentPublicInfosResponse,
  UserControllerApi,
  UserPublicInfo,
  UserPublicInfosResponse,
} from '../../openapi/yenta';
import { saveAgentById } from '../../slices/UserIdsSlice';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { queryKeys } from '../base/queryKeys';
import { useSimpleQuery } from '../base/useSimpleQuery';

export const useFetchAgentsInfoByIds = (
  agentIds: string[],
  getPublicUserInfo: boolean = false,
) => {
  const dispatch = useDispatch();
  const fetchAgentInfo = async (
    agentIds: string[],
    getPublicUserInfo: boolean,
  ) => {
    const fetchApi = async (ids: string[]) =>
      getPublicUserInfo
        ? await new UserControllerApi(
            getYentaConfiguration(),
          ).getPublicUserInfos({ ids })
        : await new AgentControllerApi(
            getYentaConfiguration(),
          ).getPublicAgentInfos({ agentIds: ids });

    const allAgentPublicInfosRes = chunk(agentIds, 20).map(async (ids) => {
      const { data } = await fetchApi(ids);
      const response = getPublicUserInfo
        ? (data as UserPublicInfosResponse)?.userInfos
        : (data as AgentPublicInfosResponse)?.agentInfos;

      return response || [];
    });

    const allIds = await Promise.all(allAgentPublicInfosRes);
    return flatten(allIds);
  };

  const queryResult = useSimpleQuery<UserPublicInfo[]>({
    queryKey: queryKeys.user.agentInfoByIds(agentIds).queryKey,
    queryFn: () => fetchAgentInfo(agentIds, getPublicUserInfo),
    options: {
      toastErrorMessage: 'Error retrieving agent info',
      logErrorMessage: 'Error retrieving agent info',
      enabled: !!agentIds.length,
    },
  });

  dispatch(saveAgentById(queryResult?.data || []));
};
