import React from 'react';
import { InviteResponse, InviteResponseStatusEnum } from '../../openapi/avalon';
import InvitationCard from './InvitationCard';

interface AgentJointVentureCardProps {
  invitedJV: InviteResponse[];
}

const AgentJointVentureCard: React.FC<AgentJointVentureCardProps> = ({
  invitedJV,
}) => {
  const filteredInvites = invitedJV?.filter(
    (item) =>
      item.status === InviteResponseStatusEnum.Created ||
      item.status === InviteResponseStatusEnum.Viewed,
  );
  return (
    <div className='col-span-1 md:col-span-2 lg:col-span-3'>
      {filteredInvites.map((jointVenture) => {
        return (
          <div key={jointVenture.id}>
            <InvitationCard jointVentureDetails={jointVenture} />
          </div>
        );
      })}
    </div>
  );
};

export default AgentJointVentureCard;
