import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form-v7'; // Updated import
import { useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';
import { debounce } from 'lodash';
import { OptionTypeBase, OptionsType } from 'react-select';
import { ReactComponent as FilterIcon } from '../../assets/icons/zen/zen-filter.svg';
import { AnalyticsEventEnum, RootState } from '../../types';
import ZenControlledMultiSelectInput from '../Zen/Input/ZenControlledMultiSelectInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenButton from '../Zen/ZenButton';
import { FilterValuesFormData } from '../../routes/ZenDirectoryRoute';
import AnalyticsService from '../../services/AnalyticsService';
import { getDirectoryVendorRolesByUserCountry } from './DirectoryUtils';
import { countAppliedFilters, prepareDataForApi } from './filterUtils';

const DEFAULT_EMPTY_FILTERS: FilterValuesFormData = {
  participantRole: [],
  name: '',
  email: '',
  phoneNumber: '',
};

/**
 * By default, Popover is injected into document.body, this would cause scrolling
 * within the Popover to stop working.
 *
 * Pass in a parentRef from parent component fixes the scrolling issue.
 */
const ZenDirectoryContactListFilter = ({
  parentRef,
  onFilterApplied,
  defaultFilters,
}: {
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
  onFilterApplied: (data: FilterValuesFormData) => void;
  defaultFilters: FilterValuesFormData;
}) => {
  const {
    handleSubmit,
    control,
    reset,
    getValues,
  } = useForm<FilterValuesFormData>({
    defaultValues: defaultFilters,
  });

  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [rolesLength, setRolesLength] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState<FilterValuesFormData[]>([
    defaultFilters,
  ]);
  const { userDetail } = useSelector((state: RootState) => state.auth);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string, eventData?: Record<string, unknown>) => {
      AnalyticsService.instance().logEvent(event, eventData);
    }, 500),
    [],
  );

  const handleRoleChange = (roles: OptionsType<OptionTypeBase>) => {
    logAnalyticsEvent(
      roles?.length > rolesLength
        ? AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_FILTERS_ADD_ROLE
        : AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_FILTERS_REMOVE_ROLE,
    );
    setRolesLength(roles.length);
  };

  const handleApplyFilters = (data: FilterValuesFormData) => {
    const prepData = prepareDataForApi(data);
    setAppliedFilters([prepData]);
    onFilterApplied(prepData);
    setPopoverOpen(false);
  };

  const handleClickOutside = (e: MouseEvent) => {
    e.stopPropagation();
    const data = getValues();
    handleApplyFilters(data);
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_FILTERS_CLOSED,
    );
  };

  const onSubmit = (data: FilterValuesFormData) => {
    handleApplyFilters(data);
  };

  const handleClear = () => {
    // resets the form values
    reset(DEFAULT_EMPTY_FILTERS);
    // resets the filter button styling
    setAppliedFilters([]);
    // resets the filter state to trigger the parent component to fetch data
    onFilterApplied(DEFAULT_EMPTY_FILTERS);
    setPopoverOpen(false);
    setRolesLength(0);
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_FILTERS_CLEARED,
    );
  };
  const roleOptionsByCountry = getDirectoryVendorRolesByUserCountry(
    userDetail?.accountCountry!,
  );

  const appliedFiltersLength = countAppliedFilters(appliedFilters);

  const height = parentRef.current?.getBoundingClientRect().height ?? 48;
  const offset = 4;
  return (
    <div>
      <Popover
        isOpen={isPopoverOpen}
        containerClassName='z-10 w-80 !transform-none'
        containerStyle={{
          position: 'absolute',
          left: 'unset',
          right: '0',
          top: `${height + offset}px`,
        }}
        parentElement={parentRef.current ?? undefined}
        boundaryElement={parentRef.current ?? undefined}
        clickOutsideCapture
        onClickOutside={handleClickOutside}
        content={
          <div data-testid='directory-filter-popover'>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='w-full bg-white border border-zen-dark-5 divide-y divide-[#ECECEC] rounded-lg shadow-side-popup'
            >
              <div className='flex flex-row justify-between space-x-4 items-center p-4'>
                <ZenButton
                  label='Clear'
                  variant='secondary-outline'
                  onClick={handleClear}
                />
                <p className='text-base font-semibold'>Filters</p>
                <ZenButton
                  type='submit'
                  label='Apply'
                  variant='primary'
                  onClick={() =>
                    logAnalyticsEvent(
                      AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_FILTERS_APPLIED,
                    )
                  }
                />
              </div>
              <div>
                <div className='p-4'>
                  <ZenControlledMultiSelectInput<
                    FilterValuesFormData,
                    'participantRole'
                  >
                    name='participantRole'
                    control={control}
                    label='Role'
                    labelClassName='font-inter font-normal text-base'
                    placeholder='Search'
                    options={roleOptionsByCountry}
                    endAdornment={
                      <FontAwesomeIcon
                        icon={regular('angle-down')}
                        className='text-zen-dark-9 px-3'
                      />
                    }
                    onChangeSpy={handleRoleChange}
                    isSearchable
                  />
                </div>
                <hr className='border-t border-zen-dark-5 mt-1' />
                <div className='p-4'>
                  <div>
                    <ZenControlledTextInput<FilterValuesFormData, 'name'>
                      control={control}
                      label='Name'
                      labelClassName='font-inter font-normal text-base'
                      name='name'
                      placeholder='Search'
                      shouldUnregister={false}
                      onChangeSpy={() =>
                        logAnalyticsEvent(
                          AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_FILTERS_NAME_ENTERED,
                        )
                      }
                    />
                  </div>
                  <div className='mt-4'>
                    <ZenControlledTextInput<FilterValuesFormData, 'email'>
                      control={control}
                      name='email'
                      label='Email'
                      labelClassName='font-inter font-normal text-base'
                      placeholder='Search'
                      shouldUnregister={false}
                      onChangeSpy={() =>
                        logAnalyticsEvent(
                          AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_FILTERS_EMAIL_ENTERED,
                        )
                      }
                    />
                  </div>
                  <div className='mt-4'>
                    <ZenControlledTextInput<FilterValuesFormData, 'phoneNumber'>
                      control={control}
                      label='Phone'
                      labelClassName='font-inter font-normal text-base'
                      name='phoneNumber'
                      placeholder='Search'
                      shouldUnregister={false}
                      onChangeSpy={() =>
                        logAnalyticsEvent(
                          AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_FILTERS_PHONE_ENTERED,
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        }
      >
        <ZenButton
          LeftIconComponent={
            <FilterIcon
              className={classNames(
                'w-4 h-6',
                appliedFiltersLength ? 'text-primary-blue' : 'text-zen-dark-7',
              )}
              role='img'
              aria-label='filter'
            />
          }
          label=''
          variant={`${
            appliedFiltersLength
              ? 'primary-outline-selected'
              : 'secondary-outline-selected'
          }`}
          onClick={() => {
            setPopoverOpen(!isPopoverOpen);
            if (!isPopoverOpen) {
              logAnalyticsEvent(
                AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_FILTERS_OPENED,
              );
            }
          }}
        />
      </Popover>
    </div>
  );
};

export default ZenDirectoryContactListFilter;
