import { LegacyRef, useRef } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { bytesToSize, isDocSizeInvalid, validDocs } from '../utils/FileUtils';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { MAX_DOC_SIZE } from '../constants/FilesConstants';
import { cn } from '../utils/classUtils';
import FormErrorMessage from './FormErrorMessage';
import DefaultLoader from './DefaultLoader';

export type fileInputSize = 'medium' | 'large' | 'small';

interface ControlledDraggableDocumentUploadInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  placeholder?: string;
  accept?: string;
  multiple?: boolean;
  fileInputSize?: fileInputSize;
  downloadUrl?: string;
  borderPrimary?: boolean;
  isUploading?: boolean;
  maxUploadSize?: number;
}

const ControlledDraggableDocumentUploadInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  placeholder,
  accept,
  multiple = false,
  fileInputSize = 'large',
  rules,
  downloadUrl,
  borderPrimary = false,
  isUploading = false,
  maxUploadSize = MAX_DOC_SIZE,
  ...rest
}: ControlledDraggableDocumentUploadInputProps<TFieldValues, TName>) => {
  const fileInputRef = useRef<HTMLDivElement>();
  const fileUploadInputContainerSizeToClassNameMap: {
    [type in fileInputSize]: string;
  } = {
    medium: 'w-full h-48',
    large: 'w-full h-60',
    small: 'w-full h-24',
  };

  const dispatch = useDispatch();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      {...rest}
      render={({ field: { name, onChange }, fieldState: { error } }) => {
        return (
          <div className='w-full space-y-1'>
            {label && (
              <label className='inline-block' htmlFor={name}>
                {label}
              </label>
            )}
            <div>
              <div
                className={cn(
                  'text-primary border border-dashed rounded bg-white hover:bg-primary hover:bg-opacity-10 hover:border-primary cursor-default mb-1',
                  fileUploadInputContainerSizeToClassNameMap[fileInputSize],
                  {
                    'border-red-500': !!error,
                    'border-gray-400': !error && !borderPrimary,
                    'border-primary': !error && borderPrimary,
                  },
                )}
                onClick={() => fileInputRef.current?.click()}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => e.preventDefault()}
                onDragLeave={(e) => e.preventDefault()}
                onDrop={async (e) => {
                  e.preventDefault();
                  const files = Array.from(e.dataTransfer.files);

                  if (validDocs(files, maxUploadSize)?.length) {
                    if (multiple) {
                      onChange(validDocs(files, maxUploadSize));
                    } else {
                      onChange(validDocs(files?.slice(0, 1), maxUploadSize));
                    }
                  }

                  if (isDocSizeInvalid(files, maxUploadSize)) {
                    dispatch(
                      showErrorToast(
                        `File size exceeds maximum limit of ${bytesToSize(
                          maxUploadSize,
                        )}.`,
                      ),
                    );
                  }
                }}
              >
                <div className='flex items-center justify-center h-full'>
                  <input
                    id={name}
                    ref={
                      (fileInputRef as unknown) as LegacyRef<HTMLInputElement>
                    }
                    onChange={(e) => {
                      const files = Array.from(e.target.files!);
                      if (validDocs(files, maxUploadSize)?.length) {
                        onChange(validDocs(files, maxUploadSize));
                      }

                      if (isDocSizeInvalid(files, maxUploadSize)) {
                        dispatch(
                          showErrorToast(
                            `File size exceeds maximum limit of ${bytesToSize(
                              maxUploadSize,
                            )}.`,
                          ),
                        );
                      }
                    }}
                    placeholder={placeholder}
                    multiple={multiple}
                    accept={accept}
                    type='file'
                    name={name}
                    className='hidden w-full h-full'
                  />
                  {isUploading ? (
                    <DefaultLoader />
                  ) : (
                    <p className='text-sm text-center font-primary-regular whitespace-pre-wrap'>
                      {placeholder}
                    </p>
                  )}
                </div>
              </div>
              {error && <FormErrorMessage message={error.message} />}
            </div>
          </div>
        );
      }}
    />
  );
};

export default ControlledDraggableDocumentUploadInput;
