import { useRef, useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAutorenew } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  isDraggableImageMimeTypeValid,
  isDraggableImageValid,
  isImageMimeTypeValid,
  isImageValid,
} from '../../../utils/AgentHelper';
import ZenImageUploadEmptyState from '../../../assets/img/zen/zen-image-upload-empty-state.png';
import { cn } from '../../../utils/classUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';

export type fileInputSize = 'medium' | 'large' | 'small' | 'extraLarge';

interface ZenControlledDraggableImageUploadInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  placeholder?: string;
  fileInputSize?: fileInputSize;
  downloadUrl?: string;
  borderPrimary?: boolean;
  customPlaceholder?: React.ReactElement;
  onDelete(): void;
}

const ZenControlledDraggableImageUploadInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  placeholder,
  fileInputSize = 'large',
  rules,
  downloadUrl,
  borderPrimary = false,
  customPlaceholder,
  onDelete,
  ...rest
}: ZenControlledDraggableImageUploadInputProps<TFieldValues, TName>) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const fileUploadInputContainerSizeToClassNameMap: {
    [type in fileInputSize]: string;
  } = {
    medium: 'w-full h-48',
    large: 'w-full h-60',
    small: 'w-full h-24',
    extraLarge: 'w-full h-80',
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      {...rest}
      render={({ field: { name, onChange, value }, fieldState: { error } }) => {
        return (
          <div className='w-full space-y-2'>
            {label && (
              <label
                className='font-zen-body text-base font-semibold text-zen-dark-9 inline-block'
                htmlFor={name}
              >
                {label}
              </label>
            )}
            <div>
              {!!value &&
              (typeof value === 'object' || typeof value === 'string') ? (
                <div className='relative'>
                  <img
                    src={
                      typeof value === 'object'
                        ? URL.createObjectURL(value)
                        : value
                    }
                    alt='check-img'
                    className={cn(
                      fileUploadInputContainerSizeToClassNameMap[fileInputSize],
                      'bg-center bg-cover bg-no-repeat border border-dashed border-zen-dark-5 rounded-lg',
                    )}
                  />
                  <button
                    type='button'
                    onClick={async () => {
                      setIsDeleting(true);
                      await onDelete();
                      setIsDeleting(false);
                    }}
                    disabled={isDeleting}
                    className='absolute top-5 right-5 bg-white p-2 rounded-lg'
                  >
                    {isDeleting ? (
                      <MdAutorenew
                        className='text-primary-blue animate-spin mr-1'
                        fontSize={22}
                      />
                    ) : (
                      <FaTrashAlt
                        className='text-primary-blue'
                        fontSize={22}
                        aria-label='delete-file'
                      />
                    )}
                  </button>
                </div>
              ) : (
                <div
                  className={cn(
                    'text-primary-blue border border-dashed rounded-lg bg-white hover:bg-primary-blue hover:bg-opacity-10 hover:border-primary-blue cursor-pointer mb-1',
                    fileUploadInputContainerSizeToClassNameMap[fileInputSize],
                    {
                      'border-red-500': !!error,
                      'border-zen-dark-5': !error && !borderPrimary,
                      'border-primary-blue': !error && borderPrimary,
                    },
                  )}
                  onClick={() => fileInputRef.current?.click()}
                  onDragOver={(e) => e.preventDefault()}
                  onDragEnter={(e) => e.preventDefault()}
                  onDragLeave={(e) => e.preventDefault()}
                  onDrop={async (e) => {
                    if (
                      isDraggableImageValid(e) &&
                      isDraggableImageMimeTypeValid(e)
                    ) {
                      e.preventDefault();
                      onChange(e.dataTransfer.files[0]);
                    } else if (
                      isDraggableImageValid(e) &&
                      !isDraggableImageMimeTypeValid(e)
                    ) {
                      e.preventDefault();
                      await dispatch(
                        showErrorToast(
                          'File type is incorrect. Only .jpeg, .png files are accepted',
                        ),
                      );
                    } else {
                      e.preventDefault();
                      await dispatch(
                        showErrorToast(
                          'File size exceeds maximum limit of 30 MB.',
                        ),
                      );
                    }
                  }}
                >
                  <div className='flex flex-col items-center justify-center h-full'>
                    <input
                      id={name}
                      ref={fileInputRef}
                      onChange={async (e) => {
                        if (isImageValid(e) && isImageMimeTypeValid(e)) {
                          onChange(e.target.files![0]);
                        } else if (
                          isImageValid(e) &&
                          !isImageMimeTypeValid(e)
                        ) {
                          await dispatch(
                            showErrorToast(
                              'File type is incorrect. Only .jpeg, .png files are accepted',
                            ),
                          );
                        } else {
                          await dispatch(
                            showErrorToast(
                              'File size exceeds maximum limit of 10 MB.',
                            ),
                          );
                        }
                      }}
                      placeholder={placeholder}
                      accept='image/*'
                      type='file'
                      name={name}
                      className='hidden w-full h-full'
                    />
                    {customPlaceholder ?? (
                      <img
                        src={ZenImageUploadEmptyState}
                        alt='empty-events'
                        className='w-1/3'
                      />
                    )}
                    <p className='text-base text-center font-zen-body font-semibold whitespace-pre-wrap'>
                      {placeholder}
                    </p>
                  </div>
                </div>
              )}
              {error && <ZenFormErrorMessage message={error.message} />}
            </div>
          </div>
        );
      }}
    />
  );
};

export default ZenControlledDraggableImageUploadInput;
