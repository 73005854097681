export interface FeedbackModalProps {
  title: string;
  isOpen: boolean;
  onClose?(): void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-60'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0 '>
        <div className='flex flex-col justify-center items-center w-full bg-white rounded-lg z-10 md:w-full max-w-3xl shadow-sm shadow-gray-300 px-4 md:px-10 lg:px-[90px] py-10'>
          <p className='text-base px-3 py-1 font-primary-medium tracking-wide bg-primary rounded-md bg-opacity-10 text-dark text-center'>
            {title}
          </p>
          {children}
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
