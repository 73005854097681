import { MAX_FILE_SIZE } from '../constants/FilesConstants';
import {
  CalendarEventResponse,
  CalendarEventResponseRsvpStatusEnum,
} from '../openapi/yenta';
import AmplitudeService, { AmplitudeEvent } from '../services/AmplitudeService';
import { EnumMap } from '../types';
import ZenEventCard1 from '../assets/img/zen/zen-event-card-1.png';
import ZenEventCard2 from '../assets/img/zen/zen-event-card-2.png';
import ZenEventCard3 from '../assets/img/zen/zen-event-card-3.png';
import ZenEventCard4 from '../assets/img/zen/zen-event-card-4.png';
import {
  getNumberBasedOnString,
  GOOGLE_MEET_REGEX,
  WEBSITE_REGEX,
  ZOOM_MEET_REGEX,
} from './StringUtils';
import { safeEnumMapGet } from './EnumHelper';

export const DEFAULT_INVITEES_PAGE_NUMBER = 0;

export const DEFAULT_INVITEES_PAGE_SIZE = 10;

const eventTemplateImages = [
  ZenEventCard1,
  ZenEventCard2,
  ZenEventCard3,
  ZenEventCard4,
];

export const getEventTemplateBasedOnTitle = (str: string): string => {
  const index = getNumberBasedOnString(str, eventTemplateImages.length);

  return eventTemplateImages[index];
};

export enum EventTabEnum {
  YES = 'YES',
  NO = 'NO',
  MAYBE = 'MAYBE',
  UPCOMING = 'UPCOMING',
  PAST = 'PAST',
}

export enum EventRsvpStatusEnum {
  YES = 'YES',
  NO = 'NO',
  MAYBE = 'MAYBE',
}

export enum EventLocationTypeEnum {
  MEET_LINK = 'MEET_LINK',
  ZOOM_LINK = 'ZOOM_LINK',
  NORMAL_LINK = 'NORMAL_LINK',
  NON_LINK = 'NON_LINK',
}

export const getEventLocationType = (
  location: string,
): EventLocationTypeEnum => {
  if (location.match(GOOGLE_MEET_REGEX)) {
    return EventLocationTypeEnum.MEET_LINK;
  } else if (location.match(ZOOM_MEET_REGEX)) {
    return EventLocationTypeEnum.ZOOM_LINK;
  } else if (location.match(WEBSITE_REGEX)) {
    return EventLocationTypeEnum.NORMAL_LINK;
  } else {
    return EventLocationTypeEnum.NON_LINK;
  }
};

export const isImage = (file: File) => {
  return (
    (file?.type === 'image/png' || file?.type === 'image/jpeg') &&
    file?.size < MAX_FILE_SIZE
  );
};

export const tabToEmptyStateMap: EnumMap<
  EventTabEnum,
  { title: string; subtitle: string }
> = {
  YES: {
    title: 'You have no events booked.',
    subtitle:
      'You have not RSVP’d to any events just yet...  Don’t miss out on amazing events with exclusive intel made for REAL agents & brokers. Knowledge is power!',
  },
  NO: {
    title: 'You have not declined any upcoming events.',
    subtitle: '',
  },
  MAYBE: {
    title: "You have not RSVP'ed to any events as 'Maybe'",
    subtitle:
      'You have not RSVP’d to any events just yet...  Don’t miss out on amazing events with exclusive intel made for REAL agents & brokers. Knowledge is power!',
  },
  UPCOMING: {
    title: 'No upcoming events just yet.',
    subtitle: 'But, stay tuned! Events are coming your way in no time.',
  },
  PAST: {
    title: 'There are no past events to display.',
    subtitle: '',
  },
};

export const isYesOrMaybeRsvpStatus = (
  rsvpStatus: CalendarEventResponseRsvpStatusEnum | undefined,
): boolean => {
  if (!rsvpStatus) {
    return false;
  }

  const RSVP_STATUS_ENUM_MAP: EnumMap<
    CalendarEventResponseRsvpStatusEnum,
    boolean
  > = {
    [CalendarEventResponseRsvpStatusEnum.Yes]: true,
    [CalendarEventResponseRsvpStatusEnum.Maybe]: true,
    [CalendarEventResponseRsvpStatusEnum.No]: false,
  };

  return safeEnumMapGet(RSVP_STATUS_ENUM_MAP, rsvpStatus, false);
};

export const handleAddToCalendarModalOpen = (
  e: Event,
  event: CalendarEventResponse,
) => {
  const target = e.target as HTMLElement;

  const modalId = target.getAttribute('atcb-button-id');

  if (!!modalId) {
    const modalShadowRootEl = document.getElementById(`${modalId}-modal-host`)
      ?.shadowRoot;

    modalShadowRootEl?.addEventListener('click', (e) => {
      const calendarButtonLinkTarget = (e.target as HTMLElement).closest(
        '.atcb-list-item',
      ) as HTMLElement;

      if (calendarButtonLinkTarget.classList.value === 'atcb-list-item') {
        AmplitudeService.logEvent(AmplitudeEvent.ADD_EVENT_TO_CALENDAR, {
          event,
        });
      }
    });
  }
};
