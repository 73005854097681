export const BROKER_BAY_LINK_URL = 'https://auth.brokerbay.com/';

export const CANADIAN_PRESENTATION_DECK_LINK_URL =
  'https://docs.google.com/presentation/d/e/2PACX-1vSdc2Mnga-91Q8Mdi3iPmSvWGZC7iVJZ0d7P099m96ZRGL3LNxUKuckACgx36l2E3V0hXZQZvqvONNF/pub?start=false&loop=false&delayms=3000&slide=id.gd1d19f22b9_3_380';

export const CHIME_LINK_URL = 'https://member.chime.com/users/sign_in';

export const JOIN_REAL_LINK_URL = 'https://joinreal.com/';

export const REAL_AGENT_BENEFITS_USA_URL = 'https://www.realagentbenefits.com/';

export const REAL_AGENT_BENEFITS_CA_URL = 'https://www.realagentbenefits.ca/';

export const MARKETING_CENTER_LINK_URL = 'https://marketing.joinreal.com/';

export const RECO_LOGIN_LINK_URL = 'https://myweb.reco.on.ca/members';

export const ONTARIO_DEPOSIT_FORM_LINK_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSdHXKpD97rT3bn5UxH6d02e8OwhoGG_1WojbjxQLx-5tF1S6A/viewform';

export const WORK_PLACE_LINK_URL =
  'https://realbroker.workplace.com/work/landing/input/';

export const ARRAKIS_SWAGGER_LIVE_URL =
  'https://arrakis.therealbrokerage.com/swagger-ui/index.html';

export const YENTA_SWAGGER_LIVE_URL =
  'https://yenta.therealbrokerage.com/swagger-ui/index.html';

export const SHERLOCK_SWAGGER_LIVE_URL =
  'https://sherlock.therealbrokerage.com/swagger-ui/index.html';
