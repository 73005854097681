import React from 'react';

interface Column {
  label: string;
  value: string;
}

interface CheckInfoDataColumnProps {
  data: Column[];
  showDivider?: boolean;
}

const ZenCheckInfoDataColumn: React.FC<CheckInfoDataColumnProps> = ({
  data,
  showDivider = false,
}) => {
  return (
    <div className='flex flex-row justify-center items-center'>
      {data.map((d, index) => (
        <div
          key={d.label}
          className='flex flex-row justify-between items-center mx-2'
        >
          <div>
            <h6 className='font-zen-body text-base text-zen-dark-9'>
              {d.label}
            </h6>
            <p className='font-primary-medium text-base text-center text-zen-dark-9 py-2'>
              {d.value}
            </p>
          </div>
          {showDivider && index < data.length - 1 && (
            <div className='h-10 w-px mx-6 bg-gray-300' />
          )}
        </div>
      ))}
    </div>
  );
};

export default ZenCheckInfoDataColumn;
