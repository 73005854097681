import qs, { IParseOptions } from 'qs';

export const parseQueryString = <T extends any>(locationSearch: string): T => {
  return qs.parse(locationSearch, {
    ignoreQueryPrefix: true,
    decoder: booleanDecoder,
  }) as T;
};

// https://github.com/ljharb/qs/issues/91#issuecomment-348481496
const booleanDecoder: IParseOptions['decoder'] = (
  value: string,
  defaultDecoder,
): any => {
  let keywords = {
    true: true,
    false: false,
    null: null,
    undefined: undefined,
  };

  if (value in keywords) {
    // @ts-ignore
    return keywords[value];
  }

  return defaultDecoder(value);
};
