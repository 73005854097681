import {
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import ErrorService from '../../services/ErrorService';
import { isAPICommonsError } from '../../utils/TypeUtils';
import { ApiError } from '../../types';

interface UseBaseMutationOptions<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
> extends UseMutationOptions<TData, TError, TVariables, TContext> {
  errorMessage?: string;
  method?: string;
  queryDataTransform?: MutateTransformFunction<TVariables, TData>;
  queryKey: QueryKey;
  successMessage?: string;
}

export type MutateTransformFunction<
  TPayload = unknown,
  TQueryData = unknown
> = (payload: TPayload, existingQueryData: TQueryData) => TQueryData;

const NoOpMutateTransform: MutateTransformFunction<any, any> = (_, data) =>
  data;

type MutationContext<TData = unknown> = {
  prevData: [QueryKey, TData | undefined][];
};

type MutationHandler<TVariables = any, TData = unknown> = (
  variables: TVariables,
) => Promise<MutationContext<TData>>;

/**
 * Helper handler factory for mutation onMutate that handles optimistic updates.
 * Note: react-query v5 makes this obsolete though.
 *
 * @param queryKey message to display
 * @param transform the transform function that will handle the optimistic update
 * @returns handler to plug into useMutation()'s onMutate
 */
export function useMutationHandler<TVariables = any, TResultData = unknown>(
  queryKey: QueryKey,
  transform: MutateTransformFunction<
    TVariables,
    TResultData
  > = NoOpMutateTransform,
): MutationHandler<TVariables, TResultData> {
  const queryClient = useQueryClient();
  return useCallback(
    async (variables: TVariables) => {
      await queryClient.cancelQueries(queryKey);
      const prevData: TResultData = queryClient.getQueryData(queryKey)!;

      if (prevData) {
        const mutatedData: TResultData = transform(variables, prevData);
        queryClient.setQueryData(queryKey, mutatedData);
      }

      // return previous data for revert if there was an error
      return { prevData: [[queryKey, prevData]] };
    },
    [queryClient, queryKey, transform],
  );
}
/**
 * Helper handler factory for mutation error, that simply displays error
 * toast if message is set. Otherwise, show default error message.
 * Also calls `ErrorService` to log the error.
 *
 * @param message message to display
 * @returns handler to plug into useMutation()'s onError
 */
export const useMutationErrorHandler = (message?: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useCallback(
    // Quirk- setting a type to prevData will cause errors in `base.ts`
    (err, variables, context) => {
      const errorDetails: ApiError = isAPICommonsError(err.response.data)
        ? err.response.data['com.real.commons.apierror.ApiError']
        : err.response.data[Object.keys(err.response.data)[0]];
      // rollback the changes in case of an error
      if (context.prevData?.length) {
        context.prevData.forEach(
          ([queryKey, prevData]: [QueryKey, unknown | undefined]) => {
            queryClient.setQueryData(queryKey, prevData);
          },
        );
      }
      dispatch(
        showErrorToast(
          message ??
            errorDetails?.subErrors?.[0]?.message ??
            'Unable to complete request. Please try again later.',
        ),
      );
      ErrorService.notify(
        message ??
          errorDetails?.subErrors?.[0]?.message ??
          'Unable to complete request. Please try again later.',
        err,
        { payload: variables },
      );
    },
    [dispatch, message, queryClient],
  );
};

/**
 * Helper handler factory for mutation success, that simply displays success
 * toast if message is set. Doesn't show anything if message is falsy.
 *
 * @param message message to display
 * @returns handler to plug into useMutation()'s onSuccess
 */
export const useMutationSuccessHandler = (message?: string) => {
  const dispatch = useDispatch();
  return useCallback(() => {
    if (!message) {
      return;
    }
    dispatch(showSuccessToast(message));
    return undefined;
  }, [message, dispatch]);
};

/**
 * Helper handler factory for mutation settled, that invalidates the provided
 * queryKey's cache.
 *
 * @param queryKey QueryKey as string or string factory that accepts mutation params
 * @returns handler to plug into useMutation()'s onSettled
 */
export const useMutationSettledHandler = (queryKey: QueryKey) => {
  const queryClient = useQueryClient();
  return useCallback(() => queryClient.invalidateQueries(queryKey), [
    queryKey,
    queryClient,
  ]);
};

/**
 * `useBaseMutation` is a custom hook that simplifies the usage of the `useMutation` hook from React Query,
 * incorporating additional functionalities for optimistic updates, error handling, success notifications,
 * and cache invalidation. It's designed to work within applications that follow specific patterns of state
 * management, caching, and user feedback.
 *
 * By providing a unified interface for performing mutations, this hook enhances developer experience
 * and code maintainability. It abstracts common pre and post-mutation behaviors like displaying toast
 * notifications for success and error, rollback mechanisms for optimistic updates, and automatic cache
 * invalidation upon mutation settlement.
 *
 * @template TData The type of data expected from the mutation function.
 * @template TVariables The type of variables the mutation function accepts.
 * @param queryKey queryKey for mutation lifecycle (optimistic updates, reverts, invalidation, etc.)
 *
 * @param successMessage Successful toast message to show when mutation
 * succeeds, no toast will be shown if not set.
 * Has no effect if onSuccess override is used.
 *
 * @param errorMessage Error message to show and log when mutation fails.
 * Defaults to fallback error message if not provided.
 *
 * @param queryDataTransform Optimistic Update Factory for cached data, defaults
 * to noop (keeping same data for the queryKey after mutation).
 * Does NOT use API response, only previous data (if any). So unless you set
 * query data for the same queryKey explicitly, this function will be the only
 * source of cached data for this mutation.
 * Has no effect if useMutate override is used.
 *
 * @returns UseMutationResults
 */
export const useBaseMutation = <TData = any, TVariables = any>({
  queryKey,
  successMessage,
  errorMessage,
  queryDataTransform,
  // react-query Options Overrides
  mutationFn,
  onMutate,
  onError,
  onSuccess,
  onSettled,
}: UseBaseMutationOptions<TData, any, TVariables, any>): UseMutationResult<
  TData,
  any,
  TVariables,
  any
> => {
  const mutationHandler = useMutationHandler(queryKey, queryDataTransform);
  const errorHandler = useMutationErrorHandler(errorMessage);
  const successHandler = useMutationSuccessHandler(successMessage);
  const settledHandler = useMutationSettledHandler(queryKey);

  return useMutation({
    mutationFn,
    onMutate: onMutate ?? mutationHandler,
    onError: onError ?? errorHandler,
    onSuccess: onSuccess ?? successHandler,
    onSettled: onSettled ?? settledHandler,
  });
};
