import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE } from '../constants/TableConstants';
import {
  ActivityControllerApi,
  PagedActivityResponse,
} from '../openapi/memento';
import ErrorService from '../services/ErrorService';
import { ActivityState, AppThunk, ErrorCode } from '../types';
import { getMementoConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showErrorToast } from './ToastNotificationSlice';

export const initialState: ActivityState = {
  loadingActivities: false,
  activities: [],
  activitiesErrorCode: null,
};

const ActivitySlice = createSlice({
  name: 'activityList',
  initialState,
  reducers: {
    changeLoadingActivities(state, action: PayloadAction<boolean>) {
      state.loadingActivities = action.payload;
    },
    saveActivity(state, action: PayloadAction<PagedActivityResponse>) {
      if (action.payload.pageNumber === 0) {
        state.activities = action.payload.activities || [];
      } else {
        state.activities.push(...(action.payload.activities || []));
      }
      state.activitiesErrorCode = null;
    },
    errorFetchingActivities(state, action: PayloadAction<ErrorCode>) {
      state.activitiesErrorCode = action.payload;
    },
  },
});

export const {
  changeLoadingActivities,
  saveActivity,
  errorFetchingActivities,
} = ActivitySlice.actions;

export const fetchActivity = (
  transactionId: string,
  page: number,
  pageSize: number = DEFAULT_PAGE_SIZE,
  loading = true,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  if (loading) {
    dispatch(changeLoadingActivities(true));
  }
  try {
    const { data } = await new ActivityControllerApi(
      getMementoConfiguration(),
    ).getActivities(transactionId, page, pageSize);
    dispatch(saveActivity(data));
    return !!data.hasNext;
  } catch (e) {
    dispatch(errorFetchingActivities(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem fetching activity check lists.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to fetch activity check lists', e, {
      transaction: { transactionId },
    });
    return false;
  } finally {
    if (loading) {
      dispatch(changeLoadingActivities(false));
    }
  }
};

export default ActivitySlice.reducer;
