import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import RealTitleFeesInfo from '../../assets/img/zen/real-title/real-title-fees-info.png';
import {
  REAL_TITLE_LEARN_MORE_VIDEO_LINK,
  REAL_TITLE_QUOTE,
} from '../../constants/RealTitleConstants';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';
import ZenIFrameContainer from '../Zen/ZenIFrameContainer';

interface RealTitleLearnMoreSectionProps {}

const RealTitleLearnMoreSection: React.FC<RealTitleLearnMoreSectionProps> = () => {
  return (
    <div className='grid lg:grid-cols-3 grid-cols-1 lg:space-x-8 lg:space-y-0 space-y-8 mt-8'>
      <div className='col-span-2 bg-zen-dark-13 rounded-2xl lg:px-8 px-4 lg:py-10 py-4'>
        <div className='grid lg:grid-cols-3 grid-cols-1 items-center lg:space-x-8 space-y-reverse lg:space-y-0 space-y-8'>
          <div>
            <p className='font-zen-body font-medium text-lg text-white'>
              Learn more about Real Title directly from our CEO Sean Daly from
              this Title 101 video.
            </p>
            <p className='font-zen-body font-normal text-sm text-zen-dark-4 mt-3'>
              Many title businesses have been operating the same way for decades
              - some of them are still recording documents by delivering paper
              deeds to the county courthouse. Where others see dysfunction, we
              see this as an incredible opportunity.
            </p>
          </div>
          <div className='col-span-2 lg:order-none order-first'>
            <div className='relative w-full ' style={{ paddingTop: '56.25%' }}>
              <ZenIFrameContainer
                onClick={() =>
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.ONE_APP_TITLE_VIEWED_JV_LEARN_MORE_VIDEO,
                  )
                }
                id='real-title-learn-more-video'
                title='Learn More about Real Title'
                src={REAL_TITLE_LEARN_MORE_VIDEO_LINK}
                className='absolute top-0 w-full h-full border border-zen-dark-4 rounded-2xl'
                frameBorder='0'
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
      <div className='border border-zen-dark-4 rounded-2xl lg:p-8 p-4'>
        <img
          src={RealTitleFeesInfo}
          className='w-full'
          alt='real-title-fees-info'
        />
        <p className='font-zen-body text-sm text-zen-dark-9 mt-4'>
          Want to know how Real Title fees compare to other title companies? Get
          a quote via link below.
        </p>
        <button
          onClick={() => {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.ONE_APP_TITLE_CLICKED_GET_QUOTE,
            );
            window.open(REAL_TITLE_QUOTE, '_blank');
          }}
          className='flex flex-row items-center mt-8 space-x-2 text-sm text-zen-dark-9 font-zen-body font-semibold'
        >
          <span>Get Quote</span>
          <FontAwesomeIcon icon={faExternalLink} />
        </button>
      </div>
    </div>
  );
};

export default RealTitleLearnMoreSection;
