import React from 'react';
import { useHistory } from 'react-router-dom';
import { RezenObject } from '../../../openapi/yada';
import { capitalizeEnum } from '../../../utils/StringUtils';
import CommentRowCell from './CommentRowCell';

interface ChecklistReferenceProps {
  refersTo: RezenObject;
}

const ChecklistReference: React.FC<ChecklistReferenceProps> = ({
  refersTo,
}) => {
  const history = useHistory();

  return (
    <div className='bg-opacity-40 px-4 py-2 border border-gray-200 rounded-tl-2xl'>
      <CommentRowCell
        title={`${capitalizeEnum(refersTo.type)}: ${refersTo.metadata?.name}`}
        onClick={() => history.push(`checklist?checklistItemId=${refersTo.id}`)}
      />
    </div>
  );
};

export default ChecklistReference;
