import { useEffect, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { ReactComponent as Copy } from '../assets/img/copy.svg';

interface CopyToClipboardProps {
  label?: string;
  value: string;
  copyToltipLabel?: string;
  copyIcon?: React.ReactElement;
}

const CopyToClipboardHover: React.FC<CopyToClipboardProps> = ({
  value,
  label,
  copyToltipLabel,
  copyIcon,
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();
  const [text] = useState(value);
  const [titleText, setTitleText] = useState(copyToltipLabel);
  const onCopyText = () => {
    if (navigator.clipboard && window.isSecureContext)
      navigator.clipboard.writeText(text);
    else {
      // text area method
      let textArea = document.createElement('textarea');
      textArea.value = text;
      // make the textarea out of viewport
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      new Promise<void>((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }

    setTitleText('Copied!');
  };

  useEffect(() => {
    setTimeout(() => setTitleText(copyToltipLabel), 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleText]);

  return (
    <div className='flex flex-row items-center my-1 space-x-2'>
      <button
        className='flex items-center text-gray-500 space-x-1 p-0.5 focus:outline-none'
        onClick={() => {
          onCopyText();
        }}
        type='button'
      >
        <div>
          <div ref={setTriggerRef}>{copyIcon || <Copy />}</div>
          {visible && titleText && (
            <div
              ref={setTooltipRef}
              {...getTooltipProps({ className: 'tooltip' })}
            >
              <div {...getArrowProps({ className: 't-arrow' })} />
              {titleText}
            </div>
          )}
        </div>
        {!!label && <p>{label}</p>}
      </button>
    </div>
  );
};

export default CopyToClipboardHover;
