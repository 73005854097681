import { LegacyRef, useRef } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { ReactComponent as NewFile } from '../../../assets/img/file-new.svg';
import { MAX_DOC_SIZE } from '../../../constants/FilesConstants';
import { DocumentDefinitionDto } from '../../../openapi/sherlock';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { EnumMap } from '../../../types';
import { cn } from '../../../utils/classUtils';
import {
  bytesToSize,
  isDocSizeInvalid,
  validDocs,
} from '../../../utils/FileUtils';
import DefaultLoader from '../../DefaultLoader';
import ZenFormErrorMessage from './ZenFormErrorMessage';

type DocumentUploadInputVariant = 'default' | 'primary';

type DocumentUploadInputHeightVariant = 'small' | 'medium' | 'large';

export interface DocumentCell {
  name: string;
  version?: string;
  uploaded_at?: string | number;
  document?: DocumentDefinitionDto;
  checklistId?: string;
  checklistItemId?: string;
}

interface ZenControlledDraggableDocumentUploadInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  customPlaceHolder?: React.ReactElement;
  accept?: string;
  isUploading?: boolean;
  variant?: DocumentUploadInputVariant;
  isRequired?: boolean;
  readOnly?: boolean;
  height?: DocumentUploadInputHeightVariant;
  maxUploadSize?: number;
  multiple?: boolean;
  onChangeSpy?: (value: DocumentCell[]) => void;
}

const ZenControlledDraggableDocumentUploadInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  customPlaceHolder,
  accept,
  rules,
  isUploading = false,
  variant = 'default',
  isRequired,
  readOnly = false,
  height = 'medium',
  multiple = true,
  maxUploadSize = MAX_DOC_SIZE,
  onChangeSpy,
  ...rest
}: ZenControlledDraggableDocumentUploadInputProps<TFieldValues, TName>) => {
  const fileInputRef = useRef<HTMLDivElement>();
  const dispatch = useDispatch();

  const variantToClassNameMap: EnumMap<DocumentUploadInputVariant, string> = {
    default: 'border-zen-dark-5 bg-white',
    primary: 'border-primary-blue bg-white text-primary-blue',
  };

  const variantToClassNameHeightMap: EnumMap<
    DocumentUploadInputHeightVariant,
    string
  > = {
    small: 'min-h-[105px]',
    medium: 'min-h-[210px]',
    large: 'min-h-[420px]',
  };

  const variantToClassNameMapHover: EnumMap<
    DocumentUploadInputVariant,
    string
  > = {
    default: 'hover:bg-grey-100 text-zen-dark-7',
    primary: 'hover:bg-opacity-20 hover:bg-primary-blue',
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      {...rest}
      render={({
        field: { name, onChange },
        fieldState: { error, invalid },
      }) => {
        return (
          <div className='w-full space-y-1 h-full'>
            {label && (
              <label
                className={cn(
                  'inline-block font-zen-body font-semibold',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                )}
                htmlFor={name}
              >
                {label}
                {isRequired && <span className='text-zen-danger'>*</span>}
              </label>
            )}
            <div
              className={cn(
                'border border-dashed rounded cursor-default mb-1 w-full h-auto',
                variantToClassNameMap[variant],
                variantToClassNameHeightMap[height],
                invalid && '!border-zen-danger',
              )}
            >
              {!readOnly && (
                <div
                  className={cn(
                    variantToClassNameMapHover[variant],
                    'group min-h-[inherit]',
                  )}
                  onClick={() => {
                    if (!isUploading) {
                      fileInputRef.current?.click();
                    }
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onDragEnter={(e) => e.preventDefault()}
                  onDragLeave={(e) => e.preventDefault()}
                  onDrop={async (e) => {
                    if (!isUploading) {
                      e.preventDefault();
                      const files = Array.from(e.dataTransfer.files);

                      if (validDocs(files, maxUploadSize)?.length) {
                        if (multiple) {
                          onChange(validDocs(files, maxUploadSize));
                        } else {
                          onChange(
                            validDocs(files?.slice(0, 1), maxUploadSize),
                          );
                        }
                      }

                      if (isDocSizeInvalid(files, maxUploadSize)) {
                        dispatch(
                          showErrorToast(
                            `File size exceeds maximum limit of ${bytesToSize(
                              maxUploadSize,
                            )}.`,
                          ),
                        );
                      }
                    }
                  }}
                >
                  <div className='flex flex-col h-full min-h-[inherit] justify-center'>
                    <input
                      id={name}
                      data-testid='pdfFile'
                      ref={
                        (fileInputRef as unknown) as LegacyRef<HTMLInputElement>
                      }
                      onChange={async (e) => {
                        const files = Array.from(e.target.files!);
                        const validFiles = validDocs(files, maxUploadSize);

                        if (validFiles?.length) {
                          onChange(validFiles);

                          if (onChangeSpy) {
                            onChangeSpy(validFiles);
                          }
                        }

                        if (isDocSizeInvalid(files, maxUploadSize)) {
                          dispatch(
                            showErrorToast(
                              `File size exceeds maximum limit of ${bytesToSize(
                                maxUploadSize,
                              )}.`,
                            ),
                          );
                        }
                      }}
                      onClick={(e) => {
                        e.currentTarget.value = '';
                      }}
                      accept={accept}
                      type='file'
                      name={name}
                      className='hidden w-full h-full'
                      multiple={multiple}
                      readOnly={isUploading}
                    />
                    {isUploading ? (
                      <div className='flex flex-col justify-center items-center gap-y-2 min-h-[inherit]'>
                        <DefaultLoader />
                      </div>
                    ) : (
                      <>
                        {customPlaceHolder ? (
                          customPlaceHolder
                        ) : (
                          <div className='flex flex-col justify-center items-center gap-y-2'>
                            <NewFile fontSize={20} />
                            <p className='font-zen-body text-sm font-semibold'>
                              Drag your documents here
                            </p>
                            <div className='w-32 h-px flex justify-center items-center bg-zen-dark-5 my-3'>
                              <span className='font-zen-body text-sm font-semibold px-2 py-0.5 bg-white group-hover:bg-grey-100'>
                                OR
                              </span>
                            </div>
                            <span className='font-zen-body font-semibold text-base text-primary-blue'>
                              Browse Files
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            {!!error && <ZenFormErrorMessage message={error.message} />}
          </div>
        );
      }}
    />
  );
};

export default ZenControlledDraggableDocumentUploadInput;
