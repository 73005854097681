import {
  AuthControllerApi,
  GoogleSsoInfoResponse,
  GoogleSsoRequest,
  IdentitySummaryResponse,
  UserControllerApi,
} from '../../openapi/keymaker';
import { getKeymakerConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { queryKeys } from '../base/queryKeys';
import { useBaseMutation } from '../base/useBaseMutation';
import { QueryOptions, useSimpleQuery } from '../base/useSimpleQuery';

interface UseFetchKeymakerDetailByYentaId {
  options?: Partial<QueryOptions<GoogleSsoInfoResponse>>;
  fnArgs: [string];
}

export const useGoogleSSOByKeymakerId = ({
  fnArgs,
  options,
}: UseFetchKeymakerDetailByYentaId) => {
  const [keymakerId] = fnArgs;

  const identitySummaryQueryResult = useSimpleQuery<IdentitySummaryResponse>({
    queryKey: queryKeys.agent.keymaker(keymakerId).queryKey,
    queryFn: async () => {
      const { data } = await new UserControllerApi(
        getKeymakerConfiguration(),
      ).getIdentity(keymakerId);

      return data;
    },
    options: {
      toastErrorMessage: 'Failed to fetch keymaker detail',
      logErrorMessage: 'Failed to fetch keymaker detail',
      enabled: !!keymakerId,
    },
  });

  const { data: identitySummaryResponse } = identitySummaryQueryResult;

  const queryResult = useGoogleSSO({
    fnArgs: [identitySummaryResponse?.username || ''],
    options: {
      enabled: !!identitySummaryResponse?.username,
      ...options,
    },
  });

  return {
    data: queryResult.data,
    isFetching: queryResult.isFetching || identitySummaryQueryResult.isFetching,
    refetch: queryResult.refetch,
  };
};

interface UseFetchKeymakerDetailByYentaId {
  options?: Partial<QueryOptions<GoogleSsoInfoResponse>>;
  fnArgs: [string];
}

export const useGoogleSSO = ({
  fnArgs,
  options,
}: UseFetchKeymakerDetailByYentaId) => {
  const [usernameOrEmail] = fnArgs;

  const queryResult = useSimpleQuery<GoogleSsoInfoResponse>({
    queryKey: queryKeys.login.googleSSO(usernameOrEmail).queryKey,
    queryFn: async () => {
      const { data } = await new AuthControllerApi(
        getKeymakerConfiguration(),
      ).getUserInfoByUsernameOrEmail({ usernameOrEmail });

      return data;
    },
    options: {
      toastErrorMessage: 'Failed to fetch Google SSO detail',
      logErrorMessage: 'Failed to fetch Google SSO detail',
      enabled: !!usernameOrEmail,
      ...options,
    },
  });

  return queryResult;
};

export const useLoginWithGoogle = () => {
  const loginWithGoogle = async (req: GoogleSsoRequest) => {
    const { data } = await new AuthControllerApi(
      getKeymakerConfiguration(),
    ).authenticateUser1(req);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.login._def,
    mutationFn: loginWithGoogle,
  });
};

export const useToggleGoogleSSO = (usernameOrEmail: string) => {
  const toggleGoogleSSO = async ({
    keymakerId,
    enable,
  }: {
    keymakerId: string;
    enable: boolean;
  }) => {
    if (enable) {
      const { data } = await new UserControllerApi(
        getKeymakerConfiguration(),
      ).removeForceGooglesSso(keymakerId);

      return data;
    } else {
      const { data } = await new UserControllerApi(
        getKeymakerConfiguration(),
      ).forceGooglesSso(keymakerId);

      return data;
    }
  };

  return useBaseMutation({
    queryKey: queryKeys.login.googleSSO(usernameOrEmail).queryKey,
    mutationFn: toggleGoogleSSO,
  });
};
