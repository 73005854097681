import { FilterProps } from 'react-table';
import ControlledTextInputV7 from '../../ControlledTextInputV7';

export interface TextColumnFilterProps<D extends object>
  extends FilterProps<D> {}

const TextColumnFilter = <D extends object>({
  column,
  control,
}: TextColumnFilterProps<D>): React.ReactElement => {
  return (
    <ControlledTextInputV7
      control={control}
      name={column.id}
      placeholder={`Enter ${column.render('Header')}...`}
      shouldUnregister={false}
      rules={{
        required: `${column.render('Header')} is required`,
      }}
      defaultValue={column.filterValue?.value}
    />
  );
};

export default TextColumnFilter;
