import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  AgentWebsiteResponse,
  AgentWebsiteResponsePhotoSetEnum,
  AgentWebsiteResponseThemeColorEnum,
} from '../../../../../openapi/yenta';
import { saveAgentWebsiteDetails } from '../../../../../slices/AgentSlice';
import {
  agentCoverSetMap,
  agentWebsiteThemeColors,
} from '../../../../../testUtils/AgentWebsiteOnboardingUtils';
import { ISelectOption } from '../../../../../types';
import { capitalizeEnum } from '../../../../../utils/StringUtils';
import ZenControlledColorSelectorInput from '../../../../Zen/ZenControlledColorSelectorInput';
import ZenControlledImageSelectorInput from '../../../../Zen/ZenControlledImageSelectorInput';
import { ZenCustomColorPickerInputSelectOption } from '../../../../Zen/ZenCustomColorPickerInput';
import ZenSidebarModalForm from '../../../../Zen/ZenSidebarModalForm';

interface ZenEditThemeSidebarModalFormProps {
  isOpen: boolean;
  onClose(): void;
  agentWebsite: AgentWebsiteResponse;
}
interface FormData {
  themeColor: string;
  themeImage: string;
}

const ZenEditThemeSidebarModalForm: React.FC<ZenEditThemeSidebarModalFormProps> = ({
  isOpen,
  onClose,
  agentWebsite,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const colorOptions: ZenCustomColorPickerInputSelectOption[] = Object.keys(
    agentWebsiteThemeColors,
  ).map((key) => ({
    value: key,
    label: capitalizeEnum(key),
    color: agentWebsiteThemeColors[key as AgentWebsiteResponseThemeColorEnum],
  }));

  const themeOptions: ISelectOption[] = Object.keys(agentCoverSetMap).map(
    (key) => ({
      value: key,
      label: capitalizeEnum(key),
    }),
  );

  const onSubmit = (formData: FormData) => {
    const updatedData: AgentWebsiteResponse = {
      ...agentWebsite,
      themeColor: formData.themeColor as AgentWebsiteResponseThemeColorEnum,
      photoSet: formData.themeImage as AgentWebsiteResponsePhotoSetEnum,
    };
    dispatch(saveAgentWebsiteDetails(updatedData));
    onClose();
  };

  return (
    <ZenSidebarModalForm
      title='Theme'
      subtitle='Homepage main image and accent color'
      isOpen={isOpen}
      onClose={onClose}
      actionTitle='Save'
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <ZenControlledColorSelectorInput<FormData, 'themeColor'>
          control={control}
          label='Theme Color'
          name='themeColor'
          options={colorOptions}
          defaultValue={agentWebsite.themeColor}
          rules={{ required: 'Please provide theme color' }}
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledImageSelectorInput<FormData, 'themeImage'>
          control={control}
          label='Theme Image'
          name='themeImage'
          options={themeOptions}
          defaultValue={agentWebsite.photoSet}
          rules={{ required: 'Please provide theme image' }}
          isRequired
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenEditThemeSidebarModalForm;
