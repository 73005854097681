import ProgressBar from '../../ProgressBar';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import {
  Address,
  MoneyValue,
  PercentageValue,
  TransactionResponseTransactionTypeEnum,
} from '../../../openapi/arrakis';
import {
  CreateTransactionParticipantType,
  ISelectOption,
  YesNoType,
} from '../../../types';
import { GooglePlaceLocationType } from '../../ControlledGoogleAutocompleteSearchInputV7';
import {
  ZenCreateListingFormState,
  ZenCreateListingStepName,
} from '../ZenCreateListing/ZenCreateListingSteps';

export interface CreateListingFormState {
  //STEP ONE
  location?: GooglePlaceLocationType;
  unitNo: string;
  yearBuilt: number;
  mlsNumber: string;
  address: Address;
  isManualAddress: boolean;
  manualAddressUnitNo: string;
  manualAddressYearBuilt: number;
  isFMLSProperty?: YesNoType;
  // STEP TWO
  listingDate: Date;
  listingExpirationDate: Date;
  firmDate: Date;
  transactionType: TransactionResponseTransactionTypeEnum;
  price: MoneyValue;
  commission: Commission;
  listingCommission: Commission;
  //STEP THREE
  sellerOrLandlord: Participant[];
  buyerOrTenant: Participant[];
  //STEP FOUR
  transactionOwnerName: ISelectOption;
  realCoAgent: RealCoAgents;
  realCoAgents: RealCoAgents[];
  officeId: string;
  teamId: ISelectOption;
}

export interface Participant {
  participantType: CreateTransactionParticipantType;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  address: string;
}

interface Commission {
  percent?: PercentageValue;
  money?: MoneyValue;
  isDollar?: boolean;
}

interface RealCoAgents {
  id?: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  agentId?: string;
}
export enum CreateListingStepName {
  ADDRESS_STEP = 'ADDRESS_STEP',
  TYPE_PRICE_STEP = 'TYPE_PRICE_STEP',
  BUYER_AND_SELLER_STEP = 'BUYER_AND_SELLER_STEP',
  OWNER_STEP = 'OWNER_STEP',
  STEP_REVIEW = 'STEP_REVIEW',
}

const withCreateListingProgress = (
  Component: StepByStepComponent<
    CreateListingFormState | ZenCreateListingFormState,
    CreateListingStepName | ZenCreateListingStepName
  >,
): StepByStepComponent<
  CreateListingFormState | ZenCreateListingFormState,
  CreateListingStepName | ZenCreateListingStepName
> => (props) => {
  return (
    <>
      <div className='absolute top-0 left-0 w-full'>
        {props.progress.currentIndex > 0 ? (
          <ProgressBar
            completed={props.progress.currentIndex + 1}
            total={props.progress.lastIndex + 1}
          />
        ) : (
          <div className='flex relative w-full bg-success h-8 justify-center items-center'>
            <p className='text-base text-white text-center'>
              Your new listing is just a few minutes away!
            </p>
          </div>
        )}
      </div>
      <Component {...props} />
    </>
  );
};

export default withCreateListingProgress;
