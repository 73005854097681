import React from 'react';
import classNames from 'classnames';
import { BreadCrumbsPath } from '../../types';
import GeminiBreadCrumbsWithUrls from './GeminiBreadCrumbsWithUrls';

export interface GeminiPageLayoutProps {
  headerRef?: React.RefObject<HTMLDivElement>;
  path: BreadCrumbsPath[];
  RightComponent?: React.FC | React.ReactElement;
  hideLayoutPaths?: boolean;
  stickyHeader?: boolean;
}

const GeminiPageLayout: React.FC<GeminiPageLayoutProps> = ({
  headerRef,
  path,
  RightComponent,
  hideLayoutPaths = false,
  stickyHeader = false,
  children,
}) => {
  return (
    <div>
      {!hideLayoutPaths && (
        <div
          ref={headerRef}
          className={classNames(
            'px-4 py-2 lg:border-b border-trueGray-200 flex flex-row flex-nowrap items-center bg-white',
            { 'sticky top-0 z-40': stickyHeader },
          )}
        >
          <div className='py-3 font-zen-title font-medium text-base text-zen-dark-9'>
            <GeminiBreadCrumbsWithUrls path={path} />
          </div>
          {RightComponent}
        </div>
      )}
      {children}
    </div>
  );
};

export default GeminiPageLayout;
