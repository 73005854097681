import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AgentControllerApi as ArrakisAgentControllerApi,
  FrontLineAgentsResponse,
} from '../openapi/arrakis';
import {
  AgentControllerApi,
  DownLineAgentsResponse,
  FrontLineAgentInfoResponse,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import {
  AppDispatch,
  AppThunk,
  AsyncResponse,
  ErrorCode,
  NetworkState,
} from '../types';
import {
  getArrakisConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from './ErrorSlice';
import { performAsyncRequest } from './utils/SliceUtil';

export const initialState: NetworkState = {
  networksByIdResponse: { loading: false, name: 'networksById' },
  loading: true,
  zenLoading: true,
  zenNetworksById: {},
  fetchErrorCode: null,
  zenFetchErrorCode: null,
  downLineAgentsById: {},
};

const NetworkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    errorFetching(state, action: PayloadAction<ErrorCode>) {
      state.networksByIdResponse.error = action.payload;
    },
    saveNetworksById(
      state,
      action: PayloadAction<
        AsyncResponse<FrontLineAgentInfoResponse, { id: string }>
      >,
    ) {
      state.networksByIdResponse = {
        ...state.networksByIdResponse,
        data: {
          ...state.networksByIdResponse.data,
          [action.payload.additionalProps!.id!]: action.payload.data
            ?.frontLineAgentInfos,
        },
      };
    },
    changeZenLoading(state, action: PayloadAction<boolean>) {
      state.zenLoading = action.payload;
    },
    errorZenFetching(state, action: PayloadAction<ErrorCode>) {
      state.zenFetchErrorCode = action.payload;
    },
    saveZenNetworksById(
      state,
      action: PayloadAction<{
        finalData: FrontLineAgentsResponse;
        id: string;
      }>,
    ) {
      state.zenNetworksById[action.payload.id] = action.payload.finalData;
      state.zenFetchErrorCode = null;
    },
    saveDownLineAgentsById(
      state,
      action: PayloadAction<
        AsyncResponse<
          DownLineAgentsResponse,
          { id: string; pageNumber: number }
        >
      >,
    ) {
      const agentId = action.payload.additionalProps?.id!;
      const pageNumber = action.payload.additionalProps?.pageNumber!;
      if (pageNumber === 0) {
        state.downLineAgentsById[agentId] = action.payload;
      } else {
        const prev = { ...(state.downLineAgentsById[agentId] || {}) };
        state.downLineAgentsById[agentId] = {
          ...action.payload,
          data: {
            ...(action.payload.data || {}),
            downLineAgents: [
              ...(prev.data?.downLineAgents || []),
              ...(action.payload.data?.downLineAgents || []),
            ],
          },
        };
      }
    },
  },
});

export const {
  errorFetching,
  saveNetworksById,
  changeZenLoading,
  errorZenFetching,
  saveZenNetworksById,
  saveDownLineAgentsById,
} = NetworkSlice.actions;

export const fetchZenFrontLineForAgent = (
  id: string,
): AppThunk<Promise<FrontLineAgentsResponse | void>> => async (dispatch) => {
  dispatch(changeZenLoading(true));
  try {
    const { data } = await new ArrakisAgentControllerApi(
      getArrakisConfiguration(),
    ).getAgentFrontLine(id);
    dispatch(saveZenNetworksById({ finalData: data, id }));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreStatusCodes(
      [401, 403, 404],
      'Unable to fetch agent sponsor detail',
      e,
      {
        agent: { id },
      },
    );
    dispatch(errorZenFetching(ErrorService.getErrorCode(e)));
    return undefined;
  } finally {
    dispatch(changeZenLoading(false));
  }
};

export const fetchDownLineAgents = (
  id: string,
  tier: number,
  pageNumber: number,
  pageSize: number,
): AppThunk<Promise<DownLineAgentsResponse | undefined>> => async (
  dispatch,
) => {
  const fetchData = () =>
    new AgentControllerApi(getYentaConfiguration()).getDownLineAgents(
      id,
      tier,
      pageNumber,
      pageSize,
    );
  const res = await performAsyncRequest(
    dispatch as AppDispatch,
    'downLineAgentsById',
    saveDownLineAgentsById,
    fetchData,
    {
      skipAuthDatadog: true,
      additionalProps: { id, pageNumber },
    },
  );
  return res?.data;
};

export default NetworkSlice.reducer;
