import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import { ContextualPrompt } from '../../../../utils/LeoUtils';
import LeoChatInput from './LeoChatInput';
import LeoChatPrompts from './LeoChatPrompts';

interface LeoChatFooterProps {
  isIntro?: boolean;
}

const LeoChatFooter: React.FC<LeoChatFooterProps> = ({ isIntro = false }) => {
  const { msgContext, selectedItems } = useSelector(
    (state: RootState) => state.leo,
  );
  const [showPrompts, setShowPrompts] = useState<boolean>(false);
  const [promptSuggestions, setPromptSuggestions] = useState<
    ContextualPrompt[]
  >();

  useEffect(() => {
    const prompts = msgContext?.details[0]?.prompts;
    const singleSelectionPrompts =
      !!prompts && !!prompts?.single_selection
        ? prompts?.single_selection?.filter((el) => !!el)
        : [];
    const multiSelectionPrompts =
      !!prompts && !!prompts?.multi_selection
        ? prompts?.multi_selection?.filter((el) => !!el)!
        : [];
    const responseContext = msgContext?.details[0]?.type || '';
    if (selectedItems.length > 1 && multiSelectionPrompts.length) {
      setPromptSuggestions(
        multiSelectionPrompts.map((p) => ({
          prompt: p!,
          handler_type: responseContext,
          title: p!,
        })),
      );
      return;
    }
    if (selectedItems.length === 1 && singleSelectionPrompts.length) {
      setPromptSuggestions(
        singleSelectionPrompts.map((p) => ({
          prompt: p!,
          handler_type: responseContext,
          title: p!,
        })),
      );
      return;
    }
    setPromptSuggestions(undefined);
  }, [msgContext, selectedItems.length]);

  return (
    <motion.div
      className='max-h-[245px] bg-transparent'
      transition={{ duration: 0.1 }}
      layout
    >
      {!isIntro && (
        <button
          type='button'
          className='bg-[#282932] appearance-none w-[130px] py-1.5 px-4 ml-4 rounded-t-xl -mb-1'
          onClick={() => setShowPrompts(!showPrompts)}
          disabled={!!promptSuggestions?.length}
        >
          {showPrompts || !!promptSuggestions?.length ? (
            <div className='flex flex-row items-center'>
              <p className='flex-grow text-zen-dark-6 text-[12px] font-zen-body mr-0.5'>
                {showPrompts ? 'Hide Prompts' : 'Prompts'}
              </p>
              {showPrompts && (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className='text-xs text-white mt-px ml-0.5'
                />
              )}
            </div>
          ) : (
            <div className='flex flex-row items-center'>
              <p className='flex-grow text-zen-dark-6 text-[12px] font-zen-body mr-0.5'>
                Show Prompts
              </p>
              <FontAwesomeIcon
                icon={faChevronUp}
                className='text-xs text-white mt-0.5 ml-0.5'
              />
            </div>
          )}
        </button>
      )}
      <div
        className={classNames(
          'bg-[#282932] p-3 pb-6 sm:pb-3 sm:rounded-b-2xl',
          !showPrompts && 'pt-1',
        )}
      >
        {!isIntro && (showPrompts || !!promptSuggestions?.length) && (
          <LeoChatPrompts
            onSelect={() => setShowPrompts(false)}
            promptSuggestions={promptSuggestions}
            contextType={msgContext?.details[0]?.type}
          />
        )}
        <LeoChatInput closePrompts={() => setShowPrompts(false)} />
      </div>
    </motion.div>
  );
};

export default LeoChatFooter;
