import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { faBlanket, faFileCircleInfo } from '@fortawesome/pro-thin-svg-icons';
import millify from 'millify';
import AnalyticsService from '../../../services/AnalyticsService';
import { AnalyticsEventEnum, RootState } from '../../../types';
import { Earning, EarningType } from '../WealthManagementTypes';
import { PrimaryGradientText } from './PrimaryGradientText';
import { ZenTermsAndConditionsAccordion } from './ZenTermsAndConditionsAccordion';

type AgreementDetailsProps = {
  expand: number;
  scrollToExample: number;
  type: EarningType;
};

export const SppAgreementDetails = ({
  expand = 0,
  scrollToExample = 0,
  type,
}: AgreementDetailsProps) => {
  const exampleAccordionRef = useRef<any>();

  useEffect(() => {
    if (scrollToExample === 0) {
      return;
    }

    exampleAccordionRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [scrollToExample]);

  const isPreCap = type === Earning.PRE_CAP_SPP;
  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expand > 0) {
      setExpanded(true);
    }
  }, [expand]);

  const homePrice = 400000;
  const gci = 10000;
  const commissionSplit = isPreCap
    ? officialPlanAndAwardData?.commissionPlan?.preCapRealCommissionSplit ?? 15
    : 0;
  const capNetCommissionPercent = isPreCap
    ? officialPlanAndAwardData?.equityPurchasePlan
        ?.preCapNetCommissionPercent ?? 5
    : officialPlanAndAwardData?.equityPurchasePlan
        ?.postCapNetCommissionPercent ?? 10;
  const realSplit = gci * (commissionSplit / 100);
  const agentSplit = gci - realSplit;
  const capContribution = agentSplit * (capNetCommissionPercent / 100);
  const sharePrice = 1;
  const shares = capContribution / sharePrice;
  const matchingGrantPercent = isPreCap
    ? officialPlanAndAwardData?.equityPurchasePlan?.preCapBonusSharePercent ??
      10
    : officialPlanAndAwardData?.equityPurchasePlan?.postCapBonusSharePercent ??
      20;
  const matchingGrant = Math.floor(shares * (matchingGrantPercent / 100));
  const totalShares = shares + matchingGrant;

  return (
    <div className='lg:px-40'>
      <div className='flex flex-col justify-center items-center'>
        <div className='text-6xl font-bold text-black w-2/3 text-center mb-5'>
          Agreement <PrimaryGradientText>Details</PrimaryGradientText>
        </div>
        <div className='w-3/4 text-center mb-7 text-2xl font-normal font-zen-body'>
          Explore how and when shares become yours!
        </div>
        <Accordion
          className='w-4/5 mb-8 border border-zen-dark-13'
          style={{
            borderRadius: '10px',
          }}
          expanded={expanded}
          onChange={() => {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.WEALTH_PLAN_LP_POST_CAP_SPP_EXPAND_EXAMPLE,
            );
            setExpanded(!expanded);
          }}
          ref={exampleAccordionRef}
        >
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <div className='flex gap-5 items-center justify-start text-zen-dark-13 font-semibold font-zen-body text-xl'>
              <FontAwesomeIcon icon={faBlanket} className='text-primary-blue' />
              Example
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div className='text-lg mb-2'>
                Agent Sally sold a ${millify(homePrice)} home and received $
                {millify(gci)} in GCI.
              </div>
              <div className='flex gap-2'>
                <div className='mt-1.5'>
                  <div className='w-1.5 h-1.5 rounded-full bg-black mr-1' />
                </div>
                ${agentSplit.toLocaleString()} ({100 - commissionSplit}% split)
                to Sally
              </div>
              {realSplit > 0 && (
                <div className='flex gap-2'>
                  <div className='mt-1.5'>
                    <div className='w-1.5 h-1.5 rounded-full bg-black mr-1' />
                  </div>
                  ${realSplit.toLocaleString()} ({commissionSplit}% split) to
                  Real
                </div>
              )}
              <div className='flex gap-2'>
                <div className='mt-1.5'>
                  <div className='w-1.5 h-1.5 rounded-full bg-black mr-1' />
                </div>
                {isPreCap ? 'Pre-cap' : 'Post-cap'}, she contributes{' '}
                {capNetCommissionPercent}% of her ${agentSplit.toLocaleString()}{' '}
                commission ($
                {capContribution.toLocaleString()})
              </div>
              <div className='flex gap-2'>
                <div className='mt-1.5'>
                  <div className='w-1.5 h-1.5 rounded-full bg-black mr-1' />
                </div>
                At ${sharePrice} stock price that equals{' '}
                {shares.toLocaleString()} shares she buys today
              </div>
              <div className='flex gap-2'>
                <div className='mt-1.5'>
                  <div className='w-1.5 h-1.5 rounded-full bg-black mr-1' />
                </div>
                In 12 months, Real gives Sally a matching grant bonus of $
                {matchingGrant} shares (${matchingGrantPercent}% x $
                {shares.toLocaleString()} shares, rounded down)
              </div>
              <div className='flex gap-2'>
                <div className='mt-1.5'>
                  <div className='w-1.5 h-1.5 rounded-full bg-black mr-1' />
                </div>
                Sally owns ${totalShares.toLocaleString()} total shares.
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <ZenTermsAndConditionsAccordion earningType={type} />
        <Accordion
          className='w-4/5 mb-8 border border-zen-dark-13'
          style={{
            borderRadius: '10px',
          }}
          onChange={() => {
            const event = isPreCap
              ? AnalyticsEventEnum.WEALTH_PLAN_LP_PRE_CAP_SPP_EXPAND_IMPORTANT_DETAILS
              : AnalyticsEventEnum.WEALTH_PLAN_LP_POST_CAP_SPP_EXPAND_IMPORTANT_DETAILS;
            AnalyticsService.instance().logEvent(event);
          }}
        >
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <div className='flex gap-4 items-center justify-start text-zen-dark-13 font-semibold font-zen-body text-xl'>
              <FontAwesomeIcon
                icon={faFileCircleInfo}
                className='text-primary-blue'
              />
              Important Details
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div className='flex gap-2 items-start justify-start text-zen-dark-13 font-zen-body'>
                <div>
                  <FontAwesomeIcon
                    icon={faCheck}
                    size='sm'
                    className='text-green-600'
                  />
                </div>
                SPP contributions must hit an initial $250 minimum before
                account funds will be used to purchase stock.
              </div>
              <div className='flex gap-2 items-start justify-start text-zen-dark-13 font-zen-body'>
                <div>
                  <FontAwesomeIcon
                    icon={faCheck}
                    size='sm'
                    className='text-green-600'
                  />
                </div>
                For example, if 5% of an agent’s commission contributions only
                total $205, they will have to wait until $45 more in commission
                contributions are earned before that money is used to purchase
                Real stock shares.
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
