import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { MediaAudioBlock, VoiceCallBlock } from '../../../../openapi/yada';
import {
  isRecordingUnavailable,
  isTranscriptAvailable,
  isTranscriptUnavailable,
  isVoiceCall,
  isVoiceMail,
} from '../../../../utils/RoarUtils';
import { isMediaAudioBlock } from '../../../../utils/TypeUtils';
import {
  VoiceCallTranscriptionInProgress,
  VoiceCallTranscriptionSummary,
} from './VoiceCallTranscriptionStatus';
import VoiceRecordingTranscript from './VoiceRecordingTranscript';

type VoiceCallTranscriptionProps = {
  recordingBlock: VoiceCallBlock | MediaAudioBlock;
  showTranscriptionDetails(): void;
};

const VoiceCallTranscription: React.FC<VoiceCallTranscriptionProps> = ({
  recordingBlock,
  showTranscriptionDetails,
}) => {
  const hasTranscript = isTranscriptAvailable(recordingBlock);

  const [showTranscriptionSummary, setShowTranscriptionSummary] = useState(
    hasTranscript,
  );
  const [animateInProgress, setAnimateInProgress] = useState(false);

  useEffect(() => {
    // update with SSE
    if (hasTranscript) {
      setAnimateInProgress(true);
    }
  }, [hasTranscript]);

  if (
    isTranscriptUnavailable(recordingBlock) ||
    isRecordingUnavailable(recordingBlock)
  ) {
    const type = isMediaAudioBlock(recordingBlock)
      ? 'voice memo'
      : isVoiceMail(recordingBlock)
      ? 'voicemail'
      : 'call';

    return (
      <div className='flex items-center space-x-2'>
        <FontAwesomeIcon
          icon={faCircleInfo}
          fontSize={14}
          className='text-primary-blue'
        />
        <p className='text-grey-500 font-light text-sm'>
          No summary or transcript available for this {type}.
        </p>
      </div>
    );
  }

  const recordingUrl =
    (recordingBlock as MediaAudioBlock).url ||
    (recordingBlock as VoiceCallBlock).recordingUrl;

  return (
    <div className='relative'>
      <AnimatePresence>
        {!showTranscriptionSummary && (
          <VoiceCallTranscriptionInProgress
            animate={animateInProgress}
            recordingInProgress={!recordingUrl}
            onAnimationComplete={() => {
              setShowTranscriptionSummary(true);
            }}
          />
        )}
      </AnimatePresence>
      {showTranscriptionSummary && (
        <>
          {isVoiceCall(recordingBlock) && showTranscriptionSummary && (
            <VoiceCallTranscriptionSummary
              recordingBlock={recordingBlock}
              showTranscriptionDetails={showTranscriptionDetails}
            />
          )}
          {isVoiceMail(recordingBlock) && showTranscriptionSummary && (
            <VoiceRecordingTranscript
              transcript={recordingBlock.recordingTranscription?.transcript}
            />
          )}
          {isMediaAudioBlock(recordingBlock) && (
            <VoiceRecordingTranscript
              transcript={recordingBlock?.transcription?.transcript}
            />
          )}
        </>
      )}
    </div>
  );
};

export default VoiceCallTranscription;
