import { DateTime } from 'luxon';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form-v6';
import { useSelector } from 'react-redux';
import { RootState, YesNoType } from '../../types';
import { FormComponentProps, ICAForm } from '../../utils/FormUtils';
import ControlledTextInput from '../ControlledTextInput';
import { capitalizeEnum } from '../../utils/StringUtils';
import HighlightedDiv from '../HighlightedDiv';
import ControlledDatePickerInput from '../ControlledDatePickerInput';
import TamirsSignatureImg from '../../assets/img/tamirs-signature.png';
import ControlledSignatureInput from '../ControlledSignatureInput';
import SubmitButton from '../SubmitButton';
import {
  EMAIL_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../utils/Validations';
import ControlledPhoneNumberInput from '../ControlledPhoneNumberInput';
import { isEmptyObject } from '../../utils/ObjectUtils';
import ControlledRadioInput from '../ControlledRadioInput';
import { getSupportEmail } from '../../utils/SupportUtils';
import ControlledEmailInput from '../ControlledEmailInput';
import CAPoliciesAndProcedureForm from './CAPoliciesAndProcedureForm';

export interface CARealBrokerageICAFormProps
  extends FormComponentProps<ICAForm> {}

const CARealBrokerageICAForm: React.FC<CARealBrokerageICAFormProps> = ({
  onSubmit,
  application,
}) => {
  const location = useLocation();
  const { sponsorName } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const fullName = !!application
    ? `${application?.firstName} ${application?.lastName}`
    : userDetail?.fullName;
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
    watch,
  } = useForm<ICAForm>({ mode: 'onChange' });

  const emailAddress = !!application
    ? application.emailAddress
    : userDetail?.emailAddress;

  const ackSignature = watch('ackSignature');

  return (
    <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
      <div className='bg-white lg:p-24 p-5 text-justify font-arial text-center'>
        <p className='font-primary-medium text-center text-2xl'>
          THE REAL BROKERAGE, INC
        </p>
        <p className='font-primary-medium mb-10 text-center text-2xl'>
          CANADIAN INDEPENDENT CONTRACTOR AGREEMENT
        </p>
        <div className='mt-5'>
          <ControlledTextInput
            label='Agent’s Name on License'
            control={control}
            name='name'
            errors={errors}
            defaultValue={fullName}
            readOnly
          />
        </div>
        {application?.doesBusinessInExtended?.map((business, index) => (
          <div
            className='grid lg:grid-cols-2 print:grid-cols-2 grid-col-1 gap-x-16 my-10'
            key={business.licenseResponse?.number}
          >
            <div>
              <ControlledTextInput
                label={
                  index === 0
                    ? 'Primary Province Licensed In'
                    : 'Additional Province'
                }
                control={control}
                name={`licensedState-${index}`}
                errors={errors}
                defaultValue={capitalizeEnum(
                  business.licenseResponse?.administrativeArea
                    ?.stateOrProvince!,
                )}
                readOnly
              />
            </div>
            <div className='lg:mt-0 print:mt-0 mt-5'>
              <ControlledTextInput
                label={`${capitalizeEnum(
                  business?.licenseResponse?.administrativeArea
                    ?.stateOrProvince!,
                )} License #/ID`}
                control={control}
                name={`licenseNumber-${index}`}
                errors={errors}
                defaultValue={business?.licenseResponse?.number}
                readOnly
              />
            </div>
          </div>
        ))}
        <div className='mt-5'>
          <ControlledDatePickerInput
            label='Date of Agreement'
            name='agreementDate'
            control={control}
            errors={errors}
            defaultValue={
              application?.icaSignedAt
                ? DateTime.fromMillis(application?.icaSignedAt).toISODate()
                : DateTime.local().toISODate()
            }
            readOnly
          />
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>PARTIES</p>
          <p className='mt-2'>
            This Agreement is entered into by and between the Agent and The Real
            Brokerage Inc., or any of its entities; including but not limited to
            Real Broker AB LTD, Real Broker BC LTD. Agent acknowledges he or she
            is licensed and duly qualified to solicit real estate as a service
            to the general public in the province(s) listed above and wishes to
            affiliate and place his or her license with the Company. Company
            desires to allow the Agent to affiliate with it according to the
            terms and conditions stated herein.
          </p>
          <p className='mt-2'>
            In this agreement licensee is referred to as “Agent” and The Real
            Brokerage, Inc. entity or entities identified as “Company”. Company
            and Agent are referred to individually as a “Party” and collectively
            as the “Parties”.
          </p>
          <p className='mt-2'>Now, therefore, the Parties agree as follows:</p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>TERM</p>
          <p className='mt-2'>
            This Agreement shall commence upon mutual execution of this
            Agreement and continue until either party elects to terminate this
            Agreement.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>TERMINATION</p>
          <p className='mt-2'>
            This Agreement may be terminated by either party, at any time, with
            or without cause upon written notice given to the other party. Even
            after termination, this Agreement shall govern all disputes and
            claims between Company and Agent connected with their relationship
            under this Agreement, including obligations and liabilities arising
            from existing and completed listings, transactions, and services.
          </p>
          <p className='mt-2'>
            Upon termination of this Agreement, Agent is required to provide
            Company all information, files and documents relating to closed,
            pending or current contracts.
          </p>
          <p className='mt-2'>
            Upon termination of this Agreement Agent agrees to immediately cease
            using any and all sales, marketing or other materials bearing the
            logo or name of any entities of the Company.
          </p>
          <p className='mt-2'>
            Upon termination of this Agreement Agent authorizes the Company to
            deduct any outstanding amounts due and owed by Agent to Broker.
          </p>
          <p className='mt-2'>
            Agent acknowledges that termination from the Company could result in
            a significant financial loss, which could include loss of stock
            awards and revenue share.
          </p>
          <p className='mt-2'>
            This agreement shall be automatically terminated in the event an
            Agent&apos;s real estate license is expired, revoked, canceled, or
            becomes inactive for any reason, or transferred to a different
            brokerage not affiliated with the Company&apos;s license.
          </p>
          <p className='mt-2'>
            Should the Agent wish to voluntarily terminate this agreement,
            written notice of termination must be provided to the Company and
            emailed to
            <a
              href={`mailto:${getSupportEmail(userDetail?.accountCountry)}`}
              className='ml-1 text-blue-500 underline'
            >
              {getSupportEmail(userDetail?.accountCountry)}
            </a>
            .
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>POLICIES AND PROCEDURES</p>
          <p className='mt-2'>
            Agent acknowledges he or she has read, fully understands, and will
            comply with the Canadian National Policies and Procedures Manual,
            which is incorporated as part of this Agreement.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>CONDUCT OF AGENT</p>
          <p className='mt-2'>
            Agent agrees to conduct his or her business dealings in a
            professional manner and in compliance with the policies set out by
            the Company in the Canadian National Policies and Procedures.
            Violations of conduct with these rules may result in immediate
            termination of sponsorship without notice.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>INDEPENDENT CONTRACTOR</p>
          <p className='mt-2'>
            Company and Agent are independent contracting parties and this
            agreement does not constitute an employment agreement by either
            party and shall not be construed as a partnership and Company shall
            not be liable for any obligation, injury, disability or liability
            incurred by Agent. It is expressly understood by Company and Agent
            that no employment relationship exists between Company and Agent.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            WORKERS COMPENSATION INSURANCE
          </p>
          <p className='mt-2'>
            Company will comply with any Provincial requirement which requires
            Company to provide workers compensation insurance for its affiliated
            agents in that Province. However, such compliance shall not affect
            Agent’s status as an independent contractor, nor shall Company’s
            compliance be construed as an indication that Agent is an employee
            of Company for any purpose whatsoever.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>TAXATION</p>
          <p className='mt-2'>
            Agent is considered to be an Independent Contractor for tax purposes
            and will be responsible for all tax issues at the end of each
            calendar year. Agent acknowledges and understands that the Company
            will not withhold any Federal, Provincial, or local taxes on any
            payments made by the Company to the agent. Agent shall be solely
            responsible for the payment of all taxes affiliated with any
            payments received from the Company.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>EXPENSES</p>
          <p className='mt-2'>
            Agent acknowledges that payment of any expenses incurred by the
            agent, including but not limited to, professional licenses and dues,
            travel expenses, office space, place of business, communication
            charges, supplies, advertisements or marketing materials, are his or
            her sole responsibility. Company shall not be liable to reimburse
            Agent for any expenses.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            COSTS ATTRIBUTABLE TO CLIENTS
          </p>
          <p className='mt-2'>
            Agent acknowledges that any expenses involved in a real estate
            transaction, including but not limited to property inspections,
            surveys, well inspections, septic inspections, property reports, are
            costs which shall be ordered in the name of, billed to, and paid by
            the Seller or Buyer. Company is not liable for costs which should be
            attributable to clients.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>PLACE OF WORK</p>
          <p className='mt-2'>
            Company is a virtual brokerage and does not provide an office space
            or other form of working space for the agent, except where required
            by law. An Agent may work from home, personal office or other
            place(s) of agent’s choice in accordance with applicable laws. Agent
            is not authorized to enter into a contract for a leased working
            space using the name of the Company or representing that Company is
            a party to the contract.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>LICENSED ACTIVITIES</p>
          <p className='mt-2'>
            Agent shall keep his or her real estate license(s) current and
            active during the term of this Agreement, including satisfying all
            applicable continuing education and provisional license requirements
            of the Province in which the Agent is licensed. Agent agrees to
            provide the Company true and accurate license information in
            accordance with the terms and conditions of this agreement.
          </p>
          <p className='mt-2'>
            Agent shall be familiar and comply with all applicable Federal,
            Provincial and local laws, policies, and procedures having to do
            with purchasing and sales of real estate in his or her Province,
            including but not limited to anti-discrimination laws,
            anti-competition laws, advertising laws, FINTRAC record keeping,
            privacy laws, and fair housing.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>BROKERAGE EXCLUSIVITY</p>
          <p className='mt-2'>
            Agent agrees not to enter into another agreement to provide real
            estate services with another individual or entity while sponsored by
            the Company in provinces the Company is currently licensed to do
            business in. Agent agrees to conduct any and all real estate
            transaction(s) in the name of the Company, including but not limited
            to listings, sales, leases or rentals. This paragraph excludes
            property management. Violation of this requirement shall be grounds
            for immediate separation.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>BOARD OF REALTORS®</p>
          <p className='mt-2'>
            Agent is required to apply and become a paying dues member to a
            local Association of Realtors board affiliated with the Canadian
            Real Estate Association within thirty (30) days of the effective
            date of this agreement. All related costs shall be paid by the
            Agent. Agent agrees to reimburse Company for any cost incurred to
            Company by any Board of REALTORS® or MLS® for Agent’s actions or
            lack of actions, or failure to join such Board of REALTORS® or MLS®.
            Agent agrees to comply with all MLS® and board rules and regulations
            and remain in good standing at all times.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>PROPERTY MANAGEMENT</p>
          <p className='mt-2'>
            Property management, as described in this Agreement, is the
            professional management of another person’s real property. Property
            management, as described, is strictly prohibited. Salespersons who
            practice property management are subject to immediate separation.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>AUTHORITY</p>
          <p className='mt-2'>
            Agent will have no authority to bind, obligate or commit Company by
            any promise or representation unless specifically authorized by the
            Company. Agent will have no authority to enter into any co-brokerage
            agreement or division of commission agreement without the prior
            written consent of the broker.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>OWNERSHIP OF AGREEMENTS</p>
          <p className='mt-2'>
            Agent acknowledges all agreements for brokerage services in which
            the Company is named as a party, including but not limited to
            listings, buyer representation agreements and commission agreements
            are owned exclusively by the Company.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>AGENT ACTIVITIES</p>
          <p className='mt-2'>
            Agent agrees to perform no other activities in association with the
            Company, except to solicit and obtain listings, sales, leases or
            rentals of property for the parties mutual benefit.
          </p>
          <p className='mt-2'>
            Company shall not limit Agent’s activities to geographical areas,
            manner in which services are to be performed with regard to hours,
            schedule, or similar activities, except to the extent required by
            applicable laws, policies, and procedures.
          </p>
          <p className='mt-2'>
            Agent agrees to be competent in all geographical areas in which they
            serve as required by Provincial licensing laws.
          </p>
          <p className='mt-2'>
            Agent agrees that all real estate service contracts shall be taken
            in the name of the Company. Agent acknowledges and agrees that all
            compensation in connection with any listing, sale, lease or rental
            is to be made payable to the Company.
          </p>
          <p className='mt-2'>
            Agent agrees to provide Company copies of all documents used in the
            closing of a transaction and all related documentation, including,
            but not limited to listing and buyer representation agreements,
            purchase and sale agreements, addendums, disclosures, closing
            statements and client information data within two business days of
            the execution of the document.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>ANNIVERSARY DATE</p>
          <p className='mt-2'>
            Agent’s “join date” shall be the date on which the contractor
            completes the onboarding process and transfers his or her license to
            the Company. The “anniversary date” for Agent will be the first day
            of the calendar month following Agent’s join date with the Company
            unless specified otherwise here: Join Date is hereby set to
            <span className='inline-block mr-2 mt-2'>
              <HighlightedDiv>
                <ControlledDatePickerInput
                  name='customAnniversaryDate'
                  control={control}
                  errors={errors}
                  label='Join Date'
                  defaultValue={
                    application?.icaAnniversaryDateRequested
                      ? DateTime.fromISO(
                          application?.icaAnniversaryDateRequested,
                        ).toISODate()
                      : DateTime.local().toISODate()
                  }
                  rules={{
                    required: 'Anniversary date is required',
                  }}
                  readOnly={!!application?.icaSignedAt}
                  tooltipIndex={0}
                />
              </HighlightedDiv>
            </span>
            .
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>GOOD STANDING</p>
          <p className='mt-2'>
            To be considered in “Good Standing” an Agent must be current on all
            financial obligations required to conduct real estate business,
            including all fees, dues or amounts owed to Company, Province
            licensing authorities and local, Provincial or national REALTOR®
            associations and multi-listing services.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>COMMISSION</p>
          <div className='mt-5'>
            <p className='font-bold'>COMMISSION SPLIT:</p>
            <p className='mt-2'>
              Unless otherwise agreed in a separate written agreement between
              Company and Agent, when Agent performs any services under this
              Agreement for which a commission or any payment governed by
              applicable real estate license law is earned and collected by
              Company (collectively, “Commission”), Company shall pay Agent as
              follows:
            </p>
            <p className='mt-2'>
              Agent will be at a commission split of 85/15, with 85% of all
              residential commissions going to the Agent and 15% of said
              commissions being paid to Company until the commissions paid to
              Company total $12,000, which is defined as the{' '}
              <span className='font-bold'>Agent Cap Amount</span>. Referral
              agreements will be paid from the gross amount unless stated
              otherwise in the agreement.
            </p>
            <p className='mt-2'>
              Once Company has retained the $12,000 Agent Cap Amount, from
              Commissions generated by Agent, Company will pay Agent one hundred
              percent (100%) of any additional Commissions generated in that
              same anniversary year, less the fees detailed in the Fees and
              Payment Schedule.
            </p>
            <p className='mt-2'>
              In the event Agents affiliated with the Company represent both the
              purchaser and seller of the same property address, each “side”
              will represent a separate transaction and will be subject to
              company splits and transaction fees per side.
            </p>
            <p className='mt-2'>
              The Company will withhold commissions from an agent to comply with
              a garnishment order presented by a court of appropriate
              jurisdiction and authority.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>RATE OF COMMISSION CHARGED TO CLIENTS</p>
            <p className='mt-2'>
              Agent may use his/her discretion regarding the commission fees
              charged to clients for real estate brokerage services provided.
              Agent will obtain written permission from the Provincial Broker
              for any contemplated commission to client which is decidedly lower
              than the regular and customary commission charged by a full
              service broker in the geographic area in which the Agent does
              business. Agent understands they need to charge a commission high
              enough to avoid being in a negative commission situation.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>COMPANY CHARGES ON LEASES</p>
            <p className='mt-2'>
              Company shall be entitled to the greater of $125 or 15% of the
              lease commission amount on lease transactions, until Agent reaches
              their Cap.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>FLAT FEE OR DISCOUNT BROKERAGE</p>
            <p className='mt-2'>
              Agents are free to negotiate their commissions, however there
              shall be no advertising by an Agent appearing to offer a
              consistent low flat fee or offering consistent discounts on their
              commissions.
            </p>
          </div>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>FEES AND PAYMENT SCHEDULE</p>
          <div className='mt-5'>
            <p className='font-bold'>Sign-up fee</p>
            <p className='mt-2'>
              $249 will be charged to the Agent at the time of signing this
              document.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>Brokerage fee</p>
            <p className='mt-2'>
              Company will charge Agent a $1,200 brokerage fee each anniversary
              year, payable to Company in three equal installments of $400 out
              of the Agent’s first three transactions following the last
              anniversary date.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>Payment/funding source</p>
            <p className='mt-2'>
              Agent must keep on file with Company an electronic funds transfer
              funding source for payment of any and all fees and amounts due
              from Agent under this Agreement. Unused portions of any fees
              previously paid will not be credited/prorated. By signing this
              document Agent is giving Company permission to directly deduct the
              funding source on file for unpaid fees, charges, repayments,
              billbacks and any other amounts Agent owes to Company and will be
              deducted directly from any and all of Agent’s pending and future
              earnings that would otherwise be payable to Agent by Company.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>Payment of Commissions</p>
            <p className='mt-2'>
              All Commissions collected by Company and due to Agent, shall be
              paid to Agent after deduction of expenses (if any, including wire
              transfer fees), unless otherwise expressed in this Agreement.
              Company may withhold payment to Agent until such time the
              transaction and its corresponding file is closed and complete
              which determination shall be in Company’s absolute discretion. In
              case of a known or pending claim against Company or Agent on
              transactions for which Agent has yet to be paid, Company may
              withhold such payment of amounts on which Agent could be
              responsible for under the terms of this agreement, until such
              claim is resolved. Agent is not entitled to any advance payment by
              the Company on behalf of future compensation. Company may withhold
              a portion of Agent’s commission (or a commission in full) to
              offset sums owed to Company by Agent. At no time is Agent
              authorized to request, receive, ask or authorize any closing
              company to release a commission check directly to Agent unless
              otherwise agreed in writing by the Company. Unauthorized receipt
              of a commission check to the Agent is grounds for immediate
              separation.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>Personal Transactions</p>
            <p className='mt-2'>
              A “personal transaction” is the sale of any residential real
              property for which an Agent, the Agent’s spouse, a business entity
              controlled by the Agent or his spouse, or a revocable trust
              controlled by the Agent or his spouse, has an ownership interest
              in. An agent may take three (3) personal transactions per
              anniversary year and will carry a $275 transaction fee. To be
              eligible to transact a personal transaction, an Agent must:
            </p>
            <ul className='list-disc lg:ml-10 ml-5 mt-2'>
              <li>Be in good standing with Company.</li>
              <li>
                Personal transaction commissions are not included in Revenue
                Share Plan calculations.
              </li>
              <li>
                The Agent’s name, or his/her spouse’s name, or business entity
                controlled by the Agent or Agent’s spouse, must be on the title
                to be eligible as a personal transaction.
              </li>
              <li>
                Amounts due and owing Company for the annual fee will still be
                charged on personal transactions up to an amount of $400 per
                transaction.
              </li>
            </ul>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>Transaction Fee After Cap</p>
            <p className='mt-2'>
              When the Agent reaches the Agent Cap Amount, the Company will not
              retain 15% of the commission from Agent’s Transactions. Instead,
              the company will assess a transaction fee of $275 per Transaction
              with a $9,000 cap. The Transaction Fee is “per Transaction” and
              not “per Agent”, and is split between Agents on a Transaction
              equal to the percentage of Commission each Agent earns.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>Lease Fee After Cap</p>
            <p className='mt-2'>
              After Agent reaches the Agent Cap Amount leases will be charged at
              a fee of $275 per transaction after cap or 15% of the gross
              commission, whichever is lower.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>COMMISSIONS ON LEASES:</p>
            <p className='mt-2'>
              Agent may list a rental property on the MLS®, market it, and show
              it to potential tenants. Agent may not accept a rental/lease
              payment or deposit on behalf of the landlord. Agent shall direct
              any such payments directly to the owner, property management or
              closing company. Agent shall not accept any type of direct
              compensation regarding a rental or lease transaction. All
              compensation for an Agent must be paid through the Company unless
              otherwise agreed in writing by Company.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>COMPENSATION UPON TERMINATION:</p>
            <p className='mt-2'>
              Upon termination of this agreement, so long as Agent is not in
              default of any provision of this Agreement, Agent may be entitled
              to payment for transactions that occurred prior to the date of
              termination for which Agent has not yet been paid. At the
              termination of this Agreement, Agent authorizes the Company to
              deduct from any commissions due all financial obligations owed to
              the Company that are imposed by terms of this Agreement.
              Furthermore, in the event Agent leaves and has transactions
              pending that require further work that would normally be rendered
              by the Agent, Company shall make arrangements for Broker or
              another Agent in the company to perform the required work. The
              broker or Agent assigned shall be compensated for completing the
              details of the pending transactions and such compensation shall be
              deducted from the terminating Agent’s share of the commission.
              Agent cap ceases to exist once this agreement is terminated.
              Company shall retain a minimum of 15% of commission checks for
              transactions that close after the termination of this agreement.
            </p>
            <p className='mt-2'>
              In the event of a termination of this Agreement, all pending
              listings and pending sale agreements shall remain with the Company
              unless Company sends a written document stating otherwise to the
              Agent. Company may, at their discretion, release any active
              listings to another broker upon a written request from the
              acquiring broker.
            </p>
          </div>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>DOCUMENTS AND FILES</p>
          <p className='mt-2'>
            All files and documents pertaining to listings, leads and
            transactions produced, received or retained by Agent are the
            property of Company and shall be delivered to Company by Agent
            according to the manner and term indicated in Company’s Policy and
            Procedure Manual. Failure to comply with this requirement may result
            in delay of commission payments. An Agent obtaining the signature of
            a party to a listing or other agreement involved in a real estate
            transaction shall furnish a copy of the listing or other agreement
            to the party immediately after obtaining the party’s signature.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>AUTOMOBILE INSURANCE</p>
          <p className='mt-2'>
            Agent shall, at all times, maintain automobile insurance coverage
            for liability and property damage with minimum coverage amounts of
            $100,000 / $300,000. Company shall be indemnified and held harmless
            against any claims or demands resulting from any automobile accident
            of Agent or as a result of Agent’s default of this paragraph.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            INDEMNIFICATION AND HOLD HARMLESS
          </p>
          <p className='mt-2'>
            In addition to all other legal or equitable remedies of Company,
            Agent shall indemnify and hold harmless the Company, its owners,
            managers, affiliates, directors, officers, agents, employees and
            representatives from any actions arising out of the discharge of
            Agent’s duties and shall reimburse the same with respect to any
            losses, damages, demands, claims, costs, and expenses, including
            reasonable attorney fees (collective “Losses”), incurred by or in
            connection with any fraud or misrepresentation, or failure to
            conduct normal due diligence of Agent, including, but not limited
            to, Agent’s misrepresentation of its relationship with Company to
            any third party or any action by Agent taken or omitted pursuant to
            this Agreement. Any such claims or costs payable pursuant to this
            Agreement are due to be paid in full by Agent, who hereby agrees to
            indemnify and hold harmless Company or manager for all such sums.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>INJURIES TO AGENT</p>
          <p className='mt-2'>
            Agent, an independent contractor, acknowledges and agrees that the
            Company does not provide workers compensation. It is the
            Agent&apos;s obligation to obtain appropriate insurance coverage for
            any injuries. Agent and its employees, if any, waive any rights to
            recovery from the Company for any injuries that Agent and/or its
            employees may sustain while performing services under this
            Agreement.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>AGENT’S EMPLOYEES </p>
          <p className='mt-2'>
            Agent’s employees, if any, who perform services for the Company
            under this Agreement shall also be bound by the provision of this
            Agreement. Agents with employees or unlicensed assistants must have
            a written service agreement in place that details the duties and
            responsibilities and be approved by the managing broker. Agent’s
            responsibilities include advising its employees of the relationship
            between Agent and Company and the terms of this Agreement. Agent is
            responsible for supervising his or her employee’s activities to
            ensure their compliance with all terms of this Agreement. At request
            of the Company, Agent shall provide evidence that such persons are
            Agent’s employees and are bound by the provisions of this Agreement.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>ACTIVITY REPORTING</p>
          <p className='mt-2'>
            Agent is required to report all real estate related activities to
            the Company within two (2) business days of their occurrence. Real
            estate activities include listing agreements, accepted purchase
            agreements, earnest money deposits, closings, cancelled and expired
            agreements, referral fee agreements and/or any other business
            contract or arrangement involving an Agent and his or her client.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>E & O INSURANCE</p>
          <p className='mt-2'>
            E&O insurance coverage is provided to the Agent by either a
            mandatory provincial policy, a policy purchased by the Company, or
            both. Agent is responsible for the deductible on any transaction
            brokered through the Company that results in a lawsuit or claim.
            Such deductible may be as high as $10,000 CAD. Agent hereby
            authorizes Company to collect any unpaid portion of such deductible
            from Agent’s commissions. Agent is subject to the first $5,000 CAD,
            unless the Company determines the agent was grossly negligent /
            fraudulent in their actions. If the agent has been shown to be
            negligent or fraudulent in their actions he or she will be
            responsible for the entire deductible. Agent agrees to pay the
            determined deductible amount from any insurance policy regardless of
            his or her sponsorship status by the Company.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            USE OF A DBA, ALIAS, AND FICTITIOUS NAME
          </p>
          <p className='mt-2'>
            Agents may only trade in the name in which they are registered with
            their Provincial Regulator. Although agents and associates may use a
            DBA, Alias, AKA Name or Fictitious Name for their business to
            promote name awareness and/or identify team affiliation, approval
            for such name must be approved by the Provincial broker or
            compliance officer of the Company. Additionally, after Compliance
            approves the name, the agent or associate must receive approval from
            their Province licensing authority, if applicable. The agent or
            associate is responsible for all paperwork, forms, and cost (if any)
            for registering such name with the Province licensing authority.
            Please note that use of nicknames also falls under this rule.
            Without specific Province licensing authority approval, the name on
            your Province license is the name you must use in your real estate
            business.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            REWARD PROGRAMS DISCLOSURE
          </p>
          <p className='mt-2'>
            From time to time, Company will implement reward programs, such as
            production awards, and every Agent in good standing will be eligible
            to participate under the terms specified for each program.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>EMERGENCY CONTACT:</p>
          <p className='mt-2'>
            Agent authorizes the Company to contact the person below on Agent’s
            behalf. Agent agrees to update this contact within two (2) business
            days of any change.
          </p>
          <div className='mt-5'>
            <HighlightedDiv>
              <ControlledTextInput
                label='Emergency Contact Name'
                name='emergencyContactName'
                control={control}
                errors={errors}
                rules={{
                  required: 'Emergency contact name is required',
                }}
                defaultValue={application?.icaEmergencyContactName}
                readOnly={!!application?.icaSignedAt}
                tooltipIndex={1}
              />
            </HighlightedDiv>
          </div>
          <div className='mt-5'>
            <HighlightedDiv>
              <ControlledPhoneNumberInput
                label='Emergency Contact Phone Number'
                name='emergencyContactPhoneNumber'
                placeholder='+1 (702) 123-4567'
                control={control}
                errors={errors}
                rules={{
                  required: 'Please enter your emergency phone number',
                  ...PHONE_NUMBER_VALIDATIONS,
                }}
                defaultValue={application?.icaEmergencyContactPhoneNumber}
                readOnly={!!application?.icaSignedAt}
                tooltipIndex={2}
              />
            </HighlightedDiv>
          </div>
          <div className='mt-5'>
            <HighlightedDiv>
              <ControlledTextInput
                label='Emergency Contact Email'
                name='emergencyContactEmailAddress'
                control={control}
                errors={errors}
                rules={{
                  required: 'Please enter your emergency contact email',
                  ...EMAIL_VALIDATIONS,
                }}
                defaultValue={application?.icaEmergencyContactEmailAddress}
                readOnly={!!application?.icaSignedAt}
                tooltipIndex={3}
              />
            </HighlightedDiv>
          </div>
          <div className='mt-5'>
            <HighlightedDiv>
              <ControlledTextInput
                label='Emergency Contact Relationship'
                control={control}
                name='emergencyContactRelationship'
                errors={errors}
                rules={{
                  required: 'Emergency contact relationship is required',
                }}
                defaultValue={application?.icaEmergencyContactRelationship}
                readOnly={!!application?.icaSignedAt}
                tooltipIndex={4}
              />
            </HighlightedDiv>
          </div>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>CONFIDENTIALITY:</p>
          <p className='mt-2'>
            Company may provide Agent innovative proprietary tools and
            information that are only available to Company’s Agents. Agent
            agrees to keep all information concerning the Company&apos;s tools,
            online system, forms, website, marketing strategies, programs, legal
            information, brochures, clients, training materials and plans
            confidential at all times.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>SPONSORING AGENT</p>
          <p className='mt-2'>
            Agent may have been introduced to the Company by a &quot;Sponsoring
            Agent&quot; who through an introduction and explanation of the
            Company’s business model and potential benefits, influenced Agent’s
            decision to join the Company as an agent. It is the sole
            determination of the Agent as to who he/she names as the Sponsoring
            Agent. Agent is not required to name a sponsor, but may not name
            more than two sponsors.{' '}
            <span className='font-bold'>
              Agent acknowledges and confirms that the Sponsoring Agent(s), has
              not offered, enticed or promised anything of value, including but
              not limited to lead generation platforms, CRM, coaching, trips,
              rebates or currency in return for being named Sponsoring agent(s).
            </span>{' '}
            Agent names{' '}
            <span className='inline-block mx-2 mt-2'>
              <HighlightedDiv>
                <ControlledTextInput
                  name='referringAgentName'
                  label='Referring Agent Name'
                  control={control}
                  errors={errors}
                  rules={{
                    required:
                      control.getValues('referringAgentEmail') &&
                      'Please enter your referring agent name',
                  }}
                  defaultValue={
                    application?.icaSponsoringAgents?.[0]?.name || sponsorName
                  }
                  readOnly={!!application?.icaSignedAt}
                  tooltipIndex={5}
                />
              </HighlightedDiv>
            </span>{' '}
            <span className='inline-block mx-2 mt-2'>
              {' '}
              <HighlightedDiv>
                <ControlledEmailInput
                  name='referringAgentEmail'
                  label='Referring Agent Email'
                  control={control}
                  errors={errors}
                  rules={EMAIL_VALIDATIONS}
                  defaultValue={application?.icaSponsoringAgents?.[0]?.email}
                  readOnly={!!application?.icaSignedAt}
                  tooltipIndex={6}
                />
              </HighlightedDiv>
            </span>{' '}
            as a sponsoring agent(s).
          </p>
        </div>
        <div>
          <div className='mt-5'>
            <p className='font-bold text-violet-700'>CO-SPONSORING AGENT</p>
            <p className='mt-2'>
              <span className='font-bold'>
                Agent is not required to name two Sponsoring Agents.
              </span>{' '}
              Should Agent determine that an additional agent added significant
              influence to the introduction and explanation of the Company’s
              business model and potential benefits, he/she may name a
              co-sponsoring agent below.
              <p>
                Agent names{' '}
                <span className='inline-block mx-2 mt-2'>
                  <HighlightedDiv>
                    <ControlledTextInput
                      name='referringCoAgentName'
                      label='Referring Co-Agent Name'
                      control={control}
                      errors={errors}
                      rules={{
                        required:
                          control.getValues('referringCoAgentEmail') &&
                          'Please enter your co-agent name',
                      }}
                      defaultValue={application?.icaSponsoringAgents?.[1]?.name}
                      readOnly={!!application?.icaSignedAt}
                      tooltipIndex={7}
                    />
                  </HighlightedDiv>
                </span>{' '}
                <span className='inline-block mx-2 mt-2'>
                  <HighlightedDiv>
                    <ControlledEmailInput
                      name='referringCoAgentEmail'
                      label='Referring Co-Agent Email'
                      control={control}
                      errors={errors}
                      rules={EMAIL_VALIDATIONS}
                      defaultValue={
                        application?.icaSponsoringAgents?.[1]?.email
                      }
                      readOnly={!!application?.icaSignedAt}
                      tooltipIndex={8}
                    />
                  </HighlightedDiv>
                </span>{' '}
                as a co-sponsoring agent.
              </p>
            </p>
            <p className='mt-2'>
              <span className='font-bold'>PLEASE NOTE:</span> Agent acknowledges
              that when this Independent Contractor Agreement is signed the
              name(s) of the Sponsoring Agent(s) listed above will not be
              changed for any reason, which includes, but is not limited to
              divorces or an agent leaving a team.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold text-violet-700'>
              DEFINITION OF CO-SPONSOR
            </p>
            <p className='mt-2'>
              Should an Agent name two (2) sponsoring agents, each sponsoring
              agent shall be defined as a &quot;co-sponsor&quot;.
            </p>
          </div>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            PROHIBITION AGAINST “SPONSORSHIP OFFERINGS”
          </p>
          <p className='mt-2'>
            Any offering, enticement or promise of anything of value made by an
            existing agent of the Company to a potential licensee with an
            intention of having the licensee name the Agent as the “referral
            agent” or sponsor, is strictly prohibited. Any agent who is shown
            through his or her actions to have acted in this manner is subject
            to separation from the Company and forfeits all future revenue
            share. Agent hereby agrees that they will indemnify and hold
            harmless Company for any such separation.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            PROHIBITION AGAINST INSIDER TRADING
          </p>
          <p className='mt-2'>
            The Agent hereby acknowledges and is aware that United States
            securities laws and Canada securities laws prohibit any person who
            has material, non-public information about a company from purchasing
            or selling securities of such a company or from communicating such
            information to any other person under circumstances in which it is
            reasonably foreseeable that such person is likely to purchase or
            sell such securities. Agent further acknowledges that information is
            material if it would reasonably be expected to result in a
            significant change in the market price or value of any of the
            Company’s securities.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>CHANGE OF BROKERAGES</p>
          <p className='mt-2'>
            An agent who separates from the Company for any reason and wishes to
            again affiliate with the Company within a period of 365 days will
            retain the same sponsor as when the agent separated from Company.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            REVISIONS/MODIFICATIONS TO THIS AGREEMENT
          </p>
          <p className='mt-2'>
            The Broker may, in its sole discretion, periodically change portions
            of this Agreement. For any material change of this agreement Broker
            will provide Agent a review period of 10 days. At the end of the
            review period if Agent has not notified Broker of any objections to
            the changes in writing, Agent is deemed to have accepted all
            revisions. The notice of any material changes will be sent to the
            email Company has on file for Agent.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>ENTIRE AGREEMENT</p>
          <p className='mt-2'>
            This Agreement constitutes the entire agreement between the Parties
            and fully supersedes any and all prior understandings,
            representations, warranties or agreements pertaining to the subject
            matter of this Agreement. No oral agreements, understandings or
            representations shall change, modify or amend any part of this
            Agreement. Any changes, modifications, or amendments of this
            Agreement will be shared publicly and become effective ten (10) days
            after posting.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>GOVERNING LAW</p>
          <p className='mt-2'>
            This agreement and the rights of the parties hereunder shall be
            governed by and construed in accordance with the laws of the
            Province where the Real Agent is domiciled on the effective date of
            this Agreement, exclusive of conflict or choice of law rules.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>DISPUTE RESOLUTION</p>
          <div className='mt-5'>
            <p className='font-bold'>
              Mandatory Mediation in Advance of Arbitration
            </p>
            <ol>
              <li className='mt-2'>
                1. The parties agree that any and all disputes, claims or
                controversies arising out of or relating to this Agreement shall
                be submitted to JAMS, or its successor, for mediation, and if
                the matter is not resolved through mediation, then it shall be
                submitted to JAMS, or its successor, for final and binding
                arbitration pursuant to the clause set forth in Paragraph 5
                below.
              </li>
              <li className='mt-2'>
                2. Either party may commence mediation by providing to JAMS and
                the other party a written request for mediation, setting forth
                the subject of the dispute and the relief requested.
              </li>
              <li className='mt-2'>
                3. The parties will cooperate with JAMS and with one another in
                selecting a mediator from the JAMS panel of neutrals and in
                scheduling the mediation proceedings. The parties agree that
                they will participate in the mediation in good faith and that
                they will share equally in its costs.
              </li>
              <li className='mt-2'>
                4. All offers, promises, conduct and statements, whether oral or
                written, made in the course of the mediation by any of the
                parties, their agents, employees, experts and attorneys, and by
                the mediator or any JAMS employees, are confidential, privileged
                and inadmissible for any purpose, including impeachment, in any
                arbitration or other proceeding involving the parties, provided
                that evidence that is otherwise admissible or discoverable shall
                not be rendered inadmissible or non-discoverable as a result of
                its use in the mediation.
              </li>
              <li className='mt-2'>
                5. Either party may initiate arbitration with respect to the
                matters submitted to mediation by filing a written demand for
                arbitration at any time following the initial mediation session
                or at any time following 45 days from the date of filing the
                written request for mediation, whichever occurs first (“Earliest
                Initiation Date”). The mediation may continue after the
                commencement of arbitration if the parties so desire.
              </li>
              <li className='mt-2'>
                6. At no time prior to the Earliest Initiation Date shall either
                side initiate an arbitration or litigation related to this
                Agreement except to pursue a provisional remedy that is
                authorized by law or by JAMS Rules or by agreement of the
                parties. However, this limitation is inapplicable to a party if
                the other party refuses to comply with the requirements of
                Paragraph 3 above.
              </li>
              <li className='mt-2'>
                7. All applicable statutes of limitation and defenses based upon
                the passage of time shall be tolled until 15 days after the
                Earliest Initiation Date. The parties will take such action, if
                any, required to effectuate such tolling.
              </li>
            </ol>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>Binding Arbitration</p>
            <p className='mt-2'>
              Any dispute, claim or controversy arising out of or relating to
              this Agreement or the breach, termination, enforcement,
              interpretation or validity thereof, including the determination of
              the scope or applicability of this agreement to arbitrate, not
              resolved by paragraphs 1 through 7 above, shall be determined by
              arbitration in the Province where the transaction of real property
              made the basis of the dispute closed, or in the case there is no
              transaction, the Province in which the Real Agent was domiciled at
              the time of the dispute, before three arbitrators. The arbitration
              shall be administered by JAMS pursuant to its Comprehensive
              Arbitration Rules and Procedures [and in accordance with the
              Expedited Procedures in those Rules] [or pursuant to JAMS’
              Streamlined Arbitration Rules and Procedures]. Judgment on the
              Award may be entered in any court having jurisdiction. This clause
              shall not preclude parties from seeking provisional remedies in
              aid of arbitration from a court of appropriate jurisdiction. The
              Chair of the three arbitrators must have previously served as
              Chair in at least 10 arbitrations resulting in an award where an
              award was rendered following a hearing on the merits and one of
              the wing arbitrators must be an expert in the area of residential
              real estate.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>
              Confidentiality of Arbitration Proceeding
            </p>
            <p className='mt-2'>
              The parties shall maintain the confidential nature of the
              arbitration proceeding and the Award, including the Hearing,
              except as may be necessary to prepare for or conduct the
              arbitration hearing on the merits, or except as may be necessary
              in connection with a court application for a preliminary remedy, a
              judicial challenge to an Award or its enforcement, or unless
              otherwise required by law or judicial decision.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>FEES AND COSTS TO PREVAILING PARTY</p>
            <p className='mt-2'>
              In any arbitration arising out of or related to this Agreement,
              the arbitrators shall award to the prevailing party, if any, the
              costs and attorneys&apos; fees reasonably incurred by the
              prevailing party in connection with the arbitration.
            </p>
            <p className='mt-2'>
              If the arbitrators determine a party to be the prevailing party
              under circumstances where the prevailing party won on some but not
              all of the claims and counterclaims, the arbitrators may award the
              prevailing party an appropriate percentage of the costs and
              attorneys&apos; fees reasonably incurred by the prevailing party
              in connection with the arbitration.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>PUNITIVE DAMAGES</p>
            <p className='mt-2'>
              In any arbitration arising out of or related to this Agreement,
              the arbitrators are not empowered to award punitive or exemplary
              damages, except where permitted by statute, and the parties waive
              any right to recover any such damages
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>LIMITATION OF LIABILITY</p>
            <p className='mt-2'>
              In any arbitration arising out of or related to this Agreement,
              the arbitrator(s) may not award any incidental, indirect or
              consequential damages, including damages for lost profits.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>APPEAL</p>
            <p className='mt-2'>
              The Parties adopt and agree to implement the JAMS Optional
              Arbitration Appeal Procedure (as it exists on the effective date
              of this Agreement) with respect to any final award in an
              arbitration arising out of or related to this Agreement.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>CLASS ACTION WAIVER:</p>
            <p className='mt-2'>
              Company and Agent agree that any and all claims pursued against
              each other will be on an individual basis, and not on behalf of or
              as a part of any purported class, collective, representative, or
              consolidated action. Both Company and Agent hereby waive their
              right to commence, become a party to or remain a participant in
              any group, representative, class collective or hybrid
              class/collective or group action in any court, arbitration
              proceeding, or any other forum, against the other. The parties
              agree that any claim by or against Company or Agent shall be heard
              in arbitration without joinder of parties or consolidation of such
              claim with any other person or entity&apos;s claim, except as
              otherwise agreed to in writing by Company and Agent. This Class
              Action waiver shall supersede any contrary agreements, statements
              or rules in the JAMS Rules.
            </p>
          </div>
        </div>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>ACKNOWLEDGEMENT:</p>
          <p className='mt-2'>
            Agent hereby acknowledges reading and understanding this Agreement
            and Company’s Policies and Procedures manual in its entirety and
            agrees to abide, comply and respect the provisions set forth by this
            Agreement.
          </p>
        </div>
        <div className='flex flex-row space-x-20'>
          <div className='mt-10'>
            <img
              src={TamirsSignatureImg}
              alt="Tamir's Signature"
              className='h-10 mb-5'
            />
            <span>Tamir Poleg (CEO), Real</span>
          </div>
        </div>
        <div className='mt-20 grid lg:grid-cols-2 print:grid-cols-2 grid-cols-1 gap-10 bi-avoid'>
          <ControlledTextInput
            label='Name'
            control={control}
            name='name'
            errors={errors}
            defaultValue={fullName}
            readOnly
          />
          <HighlightedDiv>
            <ControlledSignatureInput
              label='Signature'
              control={control}
              name='ackSignature'
              errors={errors}
              rules={{
                required: 'Signature is required',
              }}
              defaultValue={application?.icaSignature}
              readOnly={!!application?.icaSignedAt}
              tooltipIndex={9}
            />
          </HighlightedDiv>
          <ControlledTextInput
            label='Email'
            control={control}
            name='email'
            defaultValue={emailAddress}
            errors={errors}
            readOnly
          />
          <ControlledDatePickerInput
            name='date'
            control={control}
            errors={errors}
            label='Date'
            defaultValue={
              application?.icaSignedAt
                ? DateTime.fromMillis(application?.icaSignedAt).toISODate()
                : DateTime.local().toISODate()
            }
            readOnly
          />
        </div>
        <div className='mt-20'>
          <div>
            <p className='font-primary-medium text-lg text-center'>
              ADDENDUM A - 2020 Agent Stock Purchase Plan Opt-In Form
            </p>
            <div className='mt-5'>
              <p>
                Real Brokerage Inc (“REAX”, TSXV: REAX.V, NASDAQ: REAX) adopted
                the Agent Stock Initiatives (“the Initiatives”) on [date].
                Pursuant to the Initiatives, REAX created the Agent Stock
                Purchase Plan (“the Plan”) to be administered by the board’s
                discretion, may issue shares of Real’s restricted stock to the
                Company’s agents who elect to participate (“Participants”).
              </p>
              <p className='mt-2'>
                <span className='font-bold'>Eligibility:</span> All agents in
                good standing with the Company are eligible to participate in
                the Plan. “Participant” is defined in this addendum as the Agent
                in their individual capacity only. Shares will not be issued to
                the Agent&apos;s corporate entity.
              </p>
              <p className='mt-2'>
                <span className='font-bold'>
                  Issuance of Shares as Payment of Commission:
                </span>{' '}
                By submitting this Opt-in Form, Participant authorizes the
                Company to set aside five percent (5%) of Participant’s Net
                Commission (after splits, fees and any other withholdings) on
                Transactions which close in Participant’s name, commencing with
                Transactions on or after Participant’s Join Date, and before
                Participant has paid the full annual cap.
              </p>
              <p className='mt-2'>
                Participant authorizes the Company to set aside ten percent
                (10%) of Participant’s Net Commission (after splits, fees and
                any other withholdings), up to a total cumulative amount of
                $15,000, on Transactions which close in Participant’s name,
                commencing with Transactions after Participant’s capping
                Transaction.
              </p>
              <p className='mt-2'>
                <span className='font-bold'>Price of Issued Shares:</span> The
                price for shares issued under the Program shall be the fair
                market value of REAX’s restricted stock, as determined by the
                closing market price of REAX’s common stock on the last trading
                day of the month.
              </p>
              <p className='mt-2'>
                <span className='font-bold'>Issue Date:</span> Shares under the
                Program shall be issued on the last trading day of the month
                during which the closing on the sales of any properties from
                which a Shares for Payment has been authorized results in an
                accumulated Shares for Payment of not less than $300 CAD (each a
                “Issue Date”).
              </p>
              <p className='mt-2'>
                <span className='font-bold'>Custody of Shares:</span> All shares
                issued under the Program shall initially be placed and held in
                an account created in Participant’s name with Shareworks.
                Accounts will be created within Shareworks upon creation of the
                first grant. Upon termination, accounts will remain active up to
                90days after the last vesting occurs.
              </p>
              <p className='mt-2'>
                <span className='font-bold'>Associated Costs:</span> Ownership
                of shares issued under the Program may come with associated
                costs imposed by third parties, including but not limited to,
                fees that may be imposed by a stockbroker, financial services
                broker of Participant’s choosing, or others.
              </p>
              <p className='mt-2'>
                <span className='font-bold'>
                  Cancellation of Participation:
                </span>{' '}
                Any Participant may cancel his or her participation in the
                Program by providing email notification of cancellation
                (“Cancellation Notice”) not less than thirty (30) calendar days
                prior to the next scheduled Issue Date. In order to be
                effective, signed cancellation Notices must be sent to
                <a
                  href={`mailto:${getSupportEmail(userDetail?.accountCountry)}`}
                  className='ml-1 text-blue-500 underline'
                >
                  {getSupportEmail(userDetail?.accountCountry)}
                </a>
                . Once participation is cancelled by Participant he/she may not
                elect to opt-in to the Agent Stock Purchase Plan until the
                following anniversary year.
              </p>
              <p className='mt-2'>
                <span className='font-bold'>Modification or Termination:</span>{' '}
                The Program is subject to modification or termination at the
                discretion of the Company’s Board of Directors.
              </p>
              <p className='mt-2'>
                <span className='font-bold'>Acknowledgments:</span> Participant
                understands that participation in the Plan is subject to the
                terms and conditions contained in the Independent Contractor
                Agreement, in this Agent Stock Purchase Plan Opt-in Form, in the
                Plan itself, and in the Initiatives. Participant has read and
                fully understands both the Plan and the Initiatives. By
                participating in the Plan, the Participant agrees to be bound by
                the terms and conditions of the ICA, the Plan and the
                Initiatives. By acceptance of this opportunity to receive
                shares, Participant consents to the electronic delivery of all
                related documents, including the Plan, the Initiatives, any
                account statements and Plan prospectuses, as applicable, and all
                other documents that REAX is required to deliver to its security
                holders (including, without limitation, annual reports and proxy
                statements) or other communications or information related to an
                investment in REAX’s stock.
              </p>
              <p className='mt-2'>
                By signing this opt-in form, the Participant certifies that he
                or she is of legal age in the Province or country of his or her
                residence.
              </p>
              <p className='mt-2'>
                Participant, by signing this opt-in form, certifies that:
                Participant is not subject to backup withholding because (a)
                Participant is exempt from backup withholding, or (b)
                Participant has been notified by the Canada Revenue Agency (CRA)
                that Participant is not subject to backup withholding, or (c)
                the Canada Revenue Agency (CRA) has notified Participant that
                Participant is no longer subject to backup withholding.
              </p>
              <p className='mt-2'>
                Participant, by signing this opt-in form, certifies that:
                Participant is receiving the shares solely for Participant’s own
                account, and not for the benefit of any other person.
                Participant is being issued the shares solely for investment
                purposes and not with a view to distribution or resale, nor with
                the intention of selling, transferring or otherwise disposing of
                all or any part thereof for any particular price, or at any
                particular time, or upon the happening of any particular event
                or circumstance, except selling, transferring, or disposing of
                the shares, in full compliance with all applicable provisions of
                the Securities Act, the rules and regulations promulgated by the
                Securities and Exchange Commission thereunder, and applicable
                Province securities laws.
              </p>
              <p className='mt-2'>
                Participant confirms that she or he has had the opportunity to
                ask questions of, and receive answers from, REAX or any
                authorized person acting on its behalf concerning REAX and its
                business, and to obtain any additional information, to the
                extent possessed by REAX (or to the extent it could have been
                acquired by REAX without unreasonable effort or expense)
                necessary to verify the accuracy of the information received by
                Participant.
              </p>
              <p className='mt-2'>
                Participant has carefully considered and has discussed (or
                accepts the responsibility to discuss) with its own legal, tax,
                accounting and financial advisors, to the extent the Participant
                has deemed necessary, the suitability of this investment and the
                transactions contemplated by this Agreement for the
                Participant’s particular federal, Province, provincial, local
                and foreign tax and financial situation and has independently
                determined that this investment and the transactions
                contemplated by this Agreement are a suitable investment for the
                Participant. Participant understands that it (and not REAX )
                shall be responsible for Participant’s own tax liability that
                may arise as a result of the receipt of the shares or the
                transactions contemplated by this Agreement.
              </p>
              <p className='mt-2'>
                Participant understands that participation in this Program does
                not change the at will nature of Participant’s independent
                contractor consulting relationship with the Company.
              </p>
              <p className='mt-5 font-bold'>
                NO AGENT, BROKER OR ELIGIBLE INDIVIDUAL SHALL BE DEEMED A
                PARTICIPANT UNLESS AND UNTIL SUBMITTING THIS COMPLETED OPT-IN
                FORM.
              </p>
            </div>
            <div className='mt-5'>
              <HighlightedDiv>
                <ControlledRadioInput
                  control={control}
                  errors={errors}
                  name='optInStockPlan'
                  label='Please check the appropriate choice below and sign:'
                  options={[
                    {
                      label:
                        'YES, I would like to participate in the Stock Purchase Plan.',
                      value: YesNoType.YES,
                    },
                    {
                      label:
                        'NO, I do not wish to participate in the Stock Purchase Plan at this time.',
                      value: YesNoType.NO,
                    },
                  ]}
                  rules={{
                    required: 'Required',
                  }}
                  defaultValue={
                    application?.optInToStockPlan !== null
                      ? application?.optInToStockPlan
                        ? YesNoType.YES
                        : YesNoType.NO
                      : undefined
                  }
                  disabled={!!application?.icaSignedAt}
                  tooltipIndex={10}
                />
              </HighlightedDiv>
            </div>
          </div>
        </div>
        <CAPoliciesAndProcedureForm />
        <div className='mt-20 grid lg:grid-cols-2 print:grid-cols-2 grid-cols-1 gap-10 bi-avoid'>
          <ControlledTextInput
            label='Name'
            control={control}
            name='name'
            errors={errors}
            defaultValue={fullName}
            readOnly
          />
          <HighlightedDiv>
            <ControlledSignatureInput
              label='Signature'
              control={control}
              name='signature'
              errors={errors}
              rules={{
                required: 'Signature is required',
                validate: (value) =>
                  ackSignature !== value
                    ? 'Signatures do not match'
                    : undefined,
              }}
              defaultValue={application?.icaSignature}
              readOnly={!!application?.icaSignedAt}
              tooltipIndex={11}
            />
          </HighlightedDiv>
          <ControlledTextInput
            label='Email'
            control={control}
            name='email'
            defaultValue={emailAddress}
            errors={errors}
            readOnly
          />
          <ControlledDatePickerInput
            name='date'
            control={control}
            errors={errors}
            label='Date'
            defaultValue={
              application?.icaSignedAt
                ? DateTime.fromMillis(application?.icaSignedAt).toISODate()
                : DateTime.local().toISODate()
            }
            readOnly
          />
        </div>
        <div className='flex justify-end mt-10'>
          {!application?.icaSignedAt && (
            <SubmitButton
              label='Sign and Submit'
              isSubmitting={isSubmitting || !isEmptyObject(errors)}
              onClick={handleSubmit(onSubmit)}
              inline
            />
          )}
          {!isEmptyObject(errors) && (
            <p className='text-red-600 font-bold'>
              Please make sure you have filled out all of the fields correctly.
            </p>
          )}
        </div>
      </div>
    </form>
  );
};

export default CARealBrokerageICAForm;
