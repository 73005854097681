import React from 'react';
import EmptyBrokerQueue from '../../../assets/img/EmptyBrokerQueue.png';

const BrokerQueueMenuEmpty: React.FC = () => {
  return (
    <div className='flex flex-col justify-center px-3'>
      <img
        src={EmptyBrokerQueue}
        className='h-75 pt-14 pb-12'
        alt='Empty broker queue'
      />
      <div className='text-lg text-black text-center font-primary-light'>
        Your Inbox is clear today!
      </div>
      <div className='text-md font-weight-200 text-center mt-4 px-3 font-primary-light'>
        Transactions with updates, comments and other activity will land on your
        Inbox, so keep an eye on it 😉
      </div>
    </div>
  );
};

export default BrokerQueueMenuEmpty;
