import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { useEventListener, useIntersectionObserver } from 'usehooks-ts';
import { cn } from '../../../utils/classUtils';
import { useDetermineTabOrder } from '../../../hooks/useDetermineTabOrder';
import ZenRouterTabItem from './ZenRouterTabItem';
import { ZenMoreTab } from './ZenMoreTab';
import { ZenTab } from './ZenRouterTabs';
import { ZenMoreDropdown } from './ZenMoreDropdown';

export interface ZenTabsProps {
  tabs: ZenTab[];
  stickyTabs?: boolean;
  customTop?: string;
  leftLabelIcon?: React.ReactElement;
}

const ZenRouterTabsContainer: React.FC<ZenTabsProps> = ({
  tabs,
  stickyTabs = false,
  customTop = 'top-0',
}) => {
  const history = useHistory();
  const location = useLocation();

  const containerRef = useRef<HTMLDivElement>(null);
  const moreRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef(tabs.map(() => React.createRef<HTMLDivElement>()));
  const [showDropdown, setShowDropdown] = useState(false);

  const { ref, isIntersecting } = useIntersectionObserver({
    threshold: 1,
  });

  const isTabActive = useCallback(
    (tab: ZenTab) => tab.path === location.pathname,
    [location.pathname],
  );
  const activeTabIndex = tabs.findIndex(isTabActive);

  const { showMoreTab, tabsWithVisibility } = useDetermineTabOrder(
    tabs,
    containerRef,
    moreRef,
    tabRefs.current,
    activeTabIndex,
  );

  useEventListener('click', (e) => {
    if (
      showDropdown &&
      moreRef.current &&
      !moreRef.current.contains(e.target as Node)
    ) {
      setShowDropdown(false);
    }
  });

  const position = stickyTabs && !isIntersecting ? 'fixed' : 'absolute';

  const dropdownLabels = tabsWithVisibility
    .filter(({ isVisible }) => !isVisible)
    .map(({ label }) => label);

  return (
    <>
      <div
        className={classNames(
          // Reason for top-[-1px] padding-top-[1px]
          // https://stackoverflow.com/questions/16302483/event-to-detect-when-positionsticky-is-triggered
          'bg-white top-[-1px] padding-top-[1px]',
          { 'sticky z-30': stickyTabs },
          stickyTabs && customTop,
        )}
        ref={ref}
      >
        <div
          ref={containerRef}
          className='mx-4 flex flex-row items-center flex-nowrap space-x-1 border-b-2 border-b-regent-300'
        >
          {tabsWithVisibility.map(
            (
              {
                label,
                isVisible,
                badgeComponent,
                path: tabPath,
                exact,
                leftLabelIcon,
              },
              index,
            ) => {
              const isActive = location.pathname === tabPath;

              return (
                <div
                  className={cn('px-2', {
                    'invisible absolute': !isVisible,
                  })}
                  aria-label={label}
                  key={label}
                  ref={tabRefs.current[index]}
                >
                  <ZenRouterTabItem
                    label={label}
                    badgeComponent={badgeComponent}
                    path={tabPath}
                    exact={exact}
                    isActive={isActive}
                    leftLabelIcon={leftLabelIcon}
                  />
                </div>
              );
            },
          )}
          <ZenMoreTab
            show={showMoreTab}
            ref={moreRef}
            onClick={() => setShowDropdown(!showDropdown)}
          />
        </div>
      </div>
      <ZenMoreDropdown
        labels={dropdownLabels}
        size='lg'
        show={showDropdown}
        position={position}
        containerRef={containerRef}
        moreRef={moreRef}
        onClick={(label) => {
          const tab = tabs.find(({ label: tabLabel }) => tabLabel === label);
          if (tab) {
            setShowDropdown(false);
            history.push(tab.path);
          }
        }}
      />
    </>
  );
};

export default ZenRouterTabsContainer;
