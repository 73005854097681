import { isEqual, values } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  AttachedFeeRequest,
  AttachedFeeRequestFeeTypeEnum,
  AttachedFeeRequestRecipientTypeEnum,
  AttachedFeeValue,
  AttachedFeeValueFeeTypeEnum,
  FeaturesResponseEligibleEnum,
  MoneyValue,
  MoneyValueCurrencyEnum,
  TransactionResponse,
} from '../../openapi/arrakis';
import AnalyticsService from '../../services/AnalyticsService';
import { toggleAdditionalFee } from '../../slices/QuickActionSlice';
import {
  addAttachedFee,
  updateAttachedFee,
} from '../../slices/TransactionSlice';
import {
  AnalyticsEventEnum,
  ISelectOption,
  RootState,
  YesNoType,
} from '../../types';
import { capitalizeEnum, MONEY_AMOUNT_REGEX } from '../../utils/StringUtils';
import {
  getAttachFeeParticipantOption,
  getParticipantsBasedOnFeeType,
} from '../../utils/TransactionHelper';
import { getAttachedFeeTypes } from '../../utils/TransactionUtils';
import ZenControlledMoneyInput from '../Zen/Input/ZenControlledMoneyInput';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextAreaInput from '../Zen/Input/ZenControlledTextAreaInput';
import ZenSidebarModalForm from '../Zen/ZenSidebarModalForm';

interface ZenAddAttachedFeeProps {
  isOpen: boolean;
  onClose(): void;
  attachedFee?: AttachedFeeValue;
  transaction: TransactionResponse;
  preselectedFeeType?: AttachedFeeValueFeeTypeEnum;
}

interface FormData {
  amount: MoneyValue;
  description: string;
  feeType?: ISelectOption;
  participantId?: ISelectOption;
  passthrough: YesNoType;
  transactionCoordinator: string;
  recipientType?: ISelectOption;
  addedBySystem?: boolean;
}

const ZenAddAttachedFee: React.FC<ZenAddAttachedFeeProps> = ({
  isOpen,
  onClose,
  attachedFee,
  transaction,
  preselectedFeeType,
}) => {
  const dispatch = useDispatch();
  const {
    transactionDetailResponse: { data: transactionDetail },
    features,
  } = useSelector((state: RootState) => state.transaction);
  const {
    office: { officeDetailById },
  } = useSelector((state: RootState) => state);
  const feesType = preselectedFeeType
    ? preselectedFeeType
    : attachedFee?.feeType;
  const defaultParticipantId = getAttachFeeParticipantOption(
    transactionDetail!,
    attachedFee?.counterpartyId!,
  );

  const feeTypeRef = useRef<AttachedFeeValueFeeTypeEnum | undefined>(feesType);

  const officeDetails = officeDetailById[transaction.office?.id!];
  const isFMLSFeeApplicable = !!features?.eligible?.includes(
    FeaturesResponseEligibleEnum.Fmls,
  );
  const feeTypesAvailableInOffice = getAttachedFeeTypes(officeDetails);
  const isFeeTypeVisible = (feeTypesAvailableInOffice || [])?.includes(
    (feesType as unknown) as AttachedFeeRequestFeeTypeEnum,
  );
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      amount: {
        currency:
          attachedFee?.amount?.currency ||
          ((transaction.currency as unknown) as MoneyValueCurrencyEnum),
        amount: attachedFee?.amount?.amount,
      },
      description: attachedFee?.description?.replace(
        'Transaction Coordinator: ',
        '',
      ),
      participantId: defaultParticipantId,
      feeType:
        !!feesType && isFeeTypeVisible
          ? {
              label: capitalizeEnum(feesType),
              value: feesType,
            }
          : undefined,
      recipientType: attachedFee?.recipientType
        ? {
            label: capitalizeEnum(attachedFee?.recipientType),
            value: attachedFee?.recipientType,
          }
        : undefined,
      addedBySystem: attachedFee?.addedBySystem ?? false,
    },
  });

  const feeType = watch('feeType');
  const participantId = watch('participantId', defaultParticipantId);
  const [participantLabel, setParticipantLabel] = useState('Participant');
  const [descriptionLabel, setDescriptionLabel] = useState('Description');

  useEffect(() => {
    setDescriptionLabel('Description');
    if (feeType?.value === AttachedFeeValueFeeTypeEnum.AdditionalCommission) {
      setParticipantLabel('Who is receiving this amount?');
    }
    if (feeType?.value === AttachedFeeValueFeeTypeEnum.Mls) {
      setParticipantLabel('Who is paying this fee?');
    }
    if (feeType?.value === AttachedFeeValueFeeTypeEnum.Reimbursement) {
      setParticipantLabel('Who is getting reimbursed?');
    }
    if (feeType?.value === AttachedFeeValueFeeTypeEnum.TransactionCoordinator) {
      setParticipantLabel('Who is paying this fee?');
      setDescriptionLabel('Transaction Coordinator Name');
    }
    if (feeType?.value === AttachedFeeValueFeeTypeEnum.Rebate) {
      setParticipantLabel('Who is offering the rebate?');
    }

    if (feeType?.value === AttachedFeeValueFeeTypeEnum.OneRealImpactFund) {
      setParticipantLabel('Agent’s fund towards One Real Impact');
    }
  }, [attachedFee, feeType, participantId, setValue, transactionDetail]);

  const onSubmit = async (data: FormData) => {
    if (attachedFee) {
      const finalData: AttachedFeeRequest = {
        amount: {
          currency: attachedFee?.amount?.currency,
          amount: +data.amount.amount!,
        },
        description:
          data.feeType?.value ===
          AttachedFeeRequestFeeTypeEnum.TransactionCoordinator
            ? `Transaction Coordinator: ${data.description}`
            : data.description,
        feeType: data.feeType?.value as AttachedFeeRequestFeeTypeEnum,
        participantId: data.participantId?.value || '',
        recipientType:
          (data.recipientType?.value as AttachedFeeRequestRecipientTypeEnum) ||
          undefined,
        addedBySystem: false,
      };
      await dispatch(
        updateAttachedFee(attachedFee?.id!, transaction?.id!, finalData),
      );
      if (isFMLSFeeApplicable && data.addedBySystem) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.FMLS_AMOUNT_EDITED_FOR_PMT_PARTICIPANT,
        );
      }
    } else {
      const finalData: AttachedFeeRequest = {
        amount: {
          amount: +data.amount.amount!,
          currency: data.amount.currency,
        },
        description:
          data.feeType?.value ===
          AttachedFeeRequestFeeTypeEnum.TransactionCoordinator
            ? `Transaction Coordinator: ${data.description}`
            : data.description,
        feeType: data.feeType?.value as AttachedFeeRequestFeeTypeEnum,
        participantId: data.participantId?.value || '',
        recipientType:
          (data.recipientType?.value as AttachedFeeRequestRecipientTypeEnum) ||
          undefined,
        addedBySystem: false,
      };
      await dispatch(addAttachedFee(transaction?.id!, finalData));
      if (
        isFMLSFeeApplicable &&
        data.feeType?.value === AttachedFeeRequestFeeTypeEnum.Mls
      ) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.FMLS_ADDED_MLS_FEE_ON_TRANSACTION,
        );
      }
    }

    dispatch(toggleAdditionalFee({ isOpen: false }));
    onClose();
  };

  useEffect(() => {
    if (!isEqual(feeTypeRef.current, feeType?.value)) {
      setValue('description', '');
      feeTypeRef.current = feeType?.value as AttachedFeeValueFeeTypeEnum;
    }
  }, [feeType?.value, setValue]);

  return (
    <ZenSidebarModalForm
      title={
        attachedFee?.id
          ? 'Edit Additional Fee / Rebate'
          : 'Add Additional Fee / Rebate'
      }
      subtitle={transactionDetail?.address?.oneLine}
      isOpen={isOpen}
      onClose={onClose}
      actionTitle='Save'
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <div>
          <ZenControlledSelectInput<FormData, 'feeType'>
            control={control}
            label='Fee Type'
            name='feeType'
            placeholder='Fee Type'
            rules={{ required: 'Fee Type is required' }}
            options={values(feeTypesAvailableInOffice).map((c) => ({
              value: c,
              label: capitalizeEnum(c),
            }))}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledSelectInput<FormData, 'participantId'>
            control={control}
            name='participantId'
            placeholder='Search Member'
            label={participantLabel}
            options={getParticipantsBasedOnFeeType(transactionDetail!)}
            rules={{
              required: 'Required',
            }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledMoneyInput<FormData, 'amount'>
            control={control}
            label='Enter Amount'
            name='amount'
            placeholder='2000'
            rules={{
              validate: (v: MoneyValue) => {
                if (!!v && !v?.amount) {
                  return 'Please enter a amount';
                } else {
                  return !MONEY_AMOUNT_REGEX?.test(String(v?.amount))
                    ? 'Please enter a valid amount'
                    : undefined;
                }
              },
            }}
            currencyReadOnly
            isRequired
          />
        </div>
        {feeType?.value === AttachedFeeRequestFeeTypeEnum.Rebate && (
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, 'recipientType'>
              control={control}
              label='Recipient Type'
              name='recipientType'
              placeholder='Select recipient type'
              rules={{
                required: 'Please select recipient type',
              }}
              options={values(AttachedFeeRequestRecipientTypeEnum).map(
                (type) => ({
                  label: capitalizeEnum(type),
                  value: type,
                }),
              )}
              onChangeSpy={(value) => {
                if (value?.label) {
                  setValue('description', `Recipient : ${value?.label}`);
                } else {
                  setValue('description', '');
                }
              }}
              isRequired
            />
          </div>
        )}
        <div className='mt-5'>
          <ZenControlledTextAreaInput<FormData, 'description'>
            name='description'
            control={control}
            label={descriptionLabel}
            placeholder={descriptionLabel}
            rules={{
              required: 'Required',
            }}
            rows={3}
            isRequired
          />
        </div>
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenAddAttachedFee;
