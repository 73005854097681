import { formatPhoneNumber } from '../../../../utils/StringUtils';

interface ZenPhoneNumberCellProps {
  phoneNumber: string | undefined;
}

const ZenPhoneNumberCell: React.FC<ZenPhoneNumberCellProps> = ({
  phoneNumber,
}) => {
  return (
    <p className='whitespace-pre'>{formatPhoneNumber(phoneNumber) || 'N/A'}</p>
  );
};

export default ZenPhoneNumberCell;
