import { values } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { DateTime } from 'luxon';
import {
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps,
} from '../../routes/MSDynamicsOnboardingRoute';
import {
  AddressCountryEnum,
  AddressRequestCountryEnum,
  AddressStateOrProvinceEnum,
  AdministrativeAreaResponseCountryEnum,
  CreateTaxFormTypeEnum,
  CreateTaxFormW8ECI,
  CreateTaxFormW8ECICountryOfIncorporationEnum,
  CreateTaxFormW8ECITaxEntityTypeEnum,
  NationalIdentificationValueTypeEnum,
  UpdateTaxFormTypeEnum,
  UpdateTaxFormW8ECI,
  UpdateTaxFormW8ECICountryOfIncorporationEnum,
  UpdateTaxFormW8ECITaxEntityTypeEnum,
} from '../../openapi/yenta';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import ZenControlledHTMLSelectInput from '../Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledRadioInput from '../Zen/Input/ZenControlledRadioInput';
import ZenControlledCheckboxInput from '../Zen/Input/ZenControlledCheckboxInput';
import {
  AgentAddressTypeEnum,
  AppDispatch,
  RootState,
  YesNoType,
} from '../../types';
import ZenControlledDatePickerInput from '../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledTextAreaInput from '../Zen/Input/ZenControlledTextAreaInput';
import {
  getIdValidations,
  getMaskTypeForIdentificationType,
} from '../../utils/AgentHelper';
import {
  createTaxForm,
  updateTaxFormInfo,
} from '../../slices/TaxInformationSlice';
import ZenButton from '../Zen/ZenButton';
import ZenControlledStateOrProvince from '../Zen/Input/ZenControlledStateOrProvince';
import ZenControlledSignatureInput from '../Zen/Input/ZenControlledSignatureInput';
import ZenControlledMaskTextInput from '../Zen/Input/ZenControlledMaskTextInput';
import { getPostalCodeValidation } from '../../utils/Validations';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import withMSDynamicsProgress from './withMSDynamicsProgress';

const MSDynamicsFormW8eci: StepByStepComponent<
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps
> = ({ form: { control, watch, trigger, setValue }, onNext, onPrevious }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [
    businessAddressSameAsPermanent,
    taxIdType,
    country,
    businessCountry,
    taxFormID,
  ] = watch([
    'w8ECI.businessAddressSameAsPermanent',
    'w8ECI.usTaxIdType',
    'w8ECI.country',
    'w8ECI.businessCountry',
    'w8ECI.id',
  ]);
  const isBusinessAddressDifferent = !businessAddressSameAsPermanent?.[0];
  const [loading, setLoading] = useState(false);

  const { userDetail, availableCountries } = useSelector(
    (state: RootState) => state.auth,
  );

  const countries = availableCountries.countries || [];

  const handleNext = async () => {
    const isValid = await trigger();
    if (!isValid) {
      dispatch(showErrorToast('There are fields which need to be filled out'));
      return;
    }
    setLoading(true);
    const formValues = watch().w8ECI;
    const isBusinessAddressSameAsPermanent =
      formValues.businessAddressSameAsPermanent?.[0];
    let isSuccess: boolean;
    if (formValues.id) {
      const req: UpdateTaxFormW8ECI = {
        type: UpdateTaxFormTypeEnum.W8Eci,
        businessAddress: {
          type: AgentAddressTypeEnum.OFFICE,
          zipOrPostalCode: isBusinessAddressSameAsPermanent
            ? formValues.zipCode
            : formValues.businessZipCode,
          streetAddress1: isBusinessAddressSameAsPermanent
            ? formValues.addressLine
            : formValues.businessAddressLine,
          stateOrProvince: ((isBusinessAddressSameAsPermanent
            ? formValues.state
            : formValues.businessState) as unknown) as AddressStateOrProvinceEnum,
          country: isBusinessAddressSameAsPermanent
            ? formValues.country
            : formValues.businessCountry,
          city: isBusinessAddressSameAsPermanent
            ? formValues.city
            : formValues.businessCity,
        },
        countryOfIncorporation: formValues.countryOfIncorporation
          .value as UpdateTaxFormW8ECICountryOfIncorporationEnum,
        foreignTaxId: formValues.foreignTaxId,
        name: formValues.taxPayer,
        nationalIds: [
          { type: formValues.usTaxIdType, id: formValues.usTaxIdNumber },
        ],
        disregardedEntityName: formValues.disregardedEntityName,
        residenceAddress: {
          type: AgentAddressTypeEnum.HOME,
          city: formValues.city,
          country: AddressCountryEnum.UnitedStates,
          streetAddress1: formValues.addressLine,
          stateOrProvince: (formValues.state as unknown) as AddressStateOrProvinceEnum,
          zipOrPostalCode: formValues.zipCode,
        },
        taxEntityType: formValues.typeOfEntity
          .value as UpdateTaxFormW8ECITaxEntityTypeEnum,
        specifyIncome: formValues.specifyIncome,
      };
      isSuccess = await dispatch(
        updateTaxFormInfo(userDetail?.id!, formValues.id, req),
      );
    } else {
      const req: CreateTaxFormW8ECI = {
        type: CreateTaxFormTypeEnum.W8Eci,
        businessAddress: {
          type: AgentAddressTypeEnum.OFFICE,
          zipOrPostalCode: isBusinessAddressSameAsPermanent
            ? formValues.zipCode
            : formValues.businessZipCode,
          streetAddress1: isBusinessAddressSameAsPermanent
            ? formValues.addressLine
            : formValues.businessAddressLine,
          stateOrProvince: ((isBusinessAddressSameAsPermanent
            ? formValues.state
            : formValues.businessState) as unknown) as AddressStateOrProvinceEnum,
          country: isBusinessAddressSameAsPermanent
            ? formValues.country
            : formValues.businessCountry,
          city: isBusinessAddressSameAsPermanent
            ? formValues.city
            : formValues.businessCity,
        },
        countryOfIncorporation: formValues.countryOfIncorporation
          .value as CreateTaxFormW8ECICountryOfIncorporationEnum,
        foreignTaxId: formValues.foreignTaxId,
        name: formValues.taxPayer,
        nationalIds: [
          { type: formValues.usTaxIdType, id: formValues.usTaxIdNumber },
        ],
        disregardedEntityName: formValues.disregardedEntityName,
        residenceAddress: {
          type: AgentAddressTypeEnum.HOME,
          city: formValues.city,
          country: AddressCountryEnum.UnitedStates,
          streetAddress1: formValues.addressLine,
          stateOrProvince: (formValues.state as unknown) as AddressStateOrProvinceEnum,
          zipOrPostalCode: formValues.zipCode,
        },
        taxEntityType: formValues.typeOfEntity
          .value as CreateTaxFormW8ECITaxEntityTypeEnum,
        specifyIncome: formValues.specifyIncome,
        signedAt: DateTime.fromISO(formValues.signDate).toMillis(),
        typedSignature: formValues.signature,
      };
      isSuccess = await dispatch(createTaxForm(userDetail?.id!, req));
    }
    if (isSuccess) {
      onNext();
    }
    setLoading(false);
  };

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div>
          <p className='text-xl font-primary-medium mb-2'>
            Substitute Form W-8ECI
          </p>
          <p className='font-zen-body text-base font-bold text-zen-dark-9 mb-4 py-3.5'>
            Identification of beneficial owner
          </p>

          <div className='space-y-4 mb-5'>
            <div>
              <ZenControlledTextInput<
                MSDynamicOnboardingFormData,
                'w8ECI.taxPayer'
              >
                control={control}
                label='Taxpayer or business name'
                name='w8ECI.taxPayer'
                placeholder='Taxpayer or business name'
                shouldUnregister={false}
                rules={{ required: 'Please enter taxpayer or business name' }}
                isRequired
              />
              <p className='font-zen-body text-base font-normal text-zen-dark-6'>
                As shown on your income tax return
              </p>
            </div>

            <div>
              <ZenControlledSelectInput<
                MSDynamicOnboardingFormData,
                'w8ECI.countryOfIncorporation'
              >
                name='w8ECI.countryOfIncorporation'
                control={control}
                label='Country'
                shouldUnregister={false}
                placeholder='Country'
                options={countries.map((val) => ({ label: val, value: val }))}
                rules={{ required: 'Please select a country' }}
                isRequired
              />
              <p className='font-zen-body text-base font-normal text-zen-dark-6'>
                If you are a corporation, enter the country of incorporation. If
                you are filing for another type of entity, enter the country
                under whose laws the entity is organized. If you are an
                individual, provide your country of residence for tax purposes.
              </p>
            </div>

            <div>
              <ZenControlledSelectInput<
                MSDynamicOnboardingFormData,
                'w8ECI.typeOfEntity'
              >
                control={control}
                label='Type of Entity'
                name='w8ECI.typeOfEntity'
                placeholder='Type of Entity'
                rules={{ required: 'Entity type is required' }}
                shouldUnregister={false}
                options={values(CreateTaxFormW8ECITaxEntityTypeEnum).map(
                  (c) => ({
                    value: c,
                    label: capitalizeEnum(c),
                  }),
                )}
                isRequired
              />
            </div>

            <div>
              <ZenControlledTextInput<
                MSDynamicOnboardingFormData,
                'w8ECI.disregardedEntityName'
              >
                control={control}
                label='Disregarded entity name'
                name='w8ECI.disregardedEntityName'
                placeholder='Taxpayer or business name'
                shouldUnregister={false}
              />
              <p className='font-zen-body text-base font-normal text-zen-dark-6'>
                Entity receiving the payments
              </p>
            </div>

            <div>
              <div className='flex flex-row items-center'>
                <div className='w-10/12'>
                  <ZenControlledMaskTextInput<
                    MSDynamicOnboardingFormData,
                    'w8ECI.usTaxIdNumber'
                  >
                    control={control}
                    label='US tax ID number'
                    name='w8ECI.usTaxIdNumber'
                    placeholder='US tax ID number'
                    shouldUnregister={false}
                    rules={{
                      required: 'Please enter tax id number',
                      ...getIdValidations(taxIdType),
                    }}
                    maskType={getMaskTypeForIdentificationType(taxIdType)}
                    setValue={setValue}
                    isRequired
                  />
                </div>
                <div className='w-2/12 pt-5 ml-2'>
                  <ZenControlledRadioInput<
                    MSDynamicOnboardingFormData,
                    'w8ECI.usTaxIdType'
                  >
                    name='w8ECI.usTaxIdType'
                    control={control}
                    shouldUnregister={false}
                    options={[
                      {
                        label: 'SSN/ITIN',
                        value: NationalIdentificationValueTypeEnum.Ssn,
                      },
                      {
                        label: 'EIN',
                        value: NationalIdentificationValueTypeEnum.Ein,
                      },
                    ]}
                    rules={{
                      required: 'Required',
                    }}
                    isRequired
                  />
                </div>
              </div>
              <p className='font-zen-body text-base font-normal text-zen-dark-6 mt-0'>
                Number and type must correspond with name provided
              </p>
            </div>

            <div>
              <ZenControlledTextInput<
                MSDynamicOnboardingFormData,
                'w8ECI.foreignTaxId'
              >
                control={control}
                label='Foreign tax ID number'
                name='w8ECI.foreignTaxId'
                placeholder='Foreign tax ID number'
                shouldUnregister={false}
                rules={{ required: 'Foreign tax ID number required' }}
                isRequired
              />
            </div>

            <div>
              <ZenControlledTextInput<
                MSDynamicOnboardingFormData,
                'w8ECI.addressLine'
              >
                control={control}
                label='Permanent residence address'
                name='w8ECI.addressLine'
                placeholder='Permanent residence address'
                shouldUnregister={false}
                rules={{ required: 'Permanent residence address is required' }}
                isRequired
              />
              <p className='font-zen-body text-base font-normal text-zen-dark-6'>
                Include apartment or suite no. if applicable
              </p>
            </div>

            <div className='flex flex-col md:flex-row md:items-center space-y-8 md:space-y-0 md:space-x-2.5'>
              <div className='flex-1'>
                <ZenControlledTextInput<
                  MSDynamicOnboardingFormData,
                  'w8ECI.city'
                >
                  label='City'
                  placeholder='City'
                  control={control}
                  name='w8ECI.city'
                  shouldUnregister={false}
                  rules={{ required: 'Please enter a city' }}
                  isRequired
                />
              </div>
              <div className='flex-1'>
                <ZenControlledStateOrProvince<
                  MSDynamicOnboardingFormData,
                  'w8ECI.state'
                >
                  control={control}
                  name='w8ECI.state'
                  setValue={setValue}
                  selectedCountry={country}
                  rules={{ required: 'Please select a state.' }}
                  isRequired
                  shouldUnregister={false}
                />
              </div>
              <div className='flex-1'>
                <ZenControlledTextInput<
                  MSDynamicOnboardingFormData,
                  'w8ECI.zipCode'
                >
                  control={control}
                  label='Zipcode'
                  placeholder='Zipcode'
                  name='w8ECI.zipCode'
                  rules={{
                    required: 'Please enter a zip code',
                    ...getPostalCodeValidation(country),
                  }}
                  shouldUnregister={false}
                  isRequired
                  type='number'
                />
              </div>
            </div>

            <div>
              <ZenControlledHTMLSelectInput<
                MSDynamicOnboardingFormData,
                'w8ECI.country'
              >
                name='w8ECI.country'
                control={control}
                label='Country'
                shouldUnregister={false}
                options={[
                  {
                    label: 'Select Country',
                    value: '',
                    disabled: true,
                  },
                  ...values(AdministrativeAreaResponseCountryEnum).map(
                    (country) => ({
                      value: country,
                      label: capitalizeEnum(country),
                    }),
                  ),
                ]}
                rules={{ required: 'Please select a country' }}
                isRequired
              />
              <p className='font-zen-body text-base font-normal text-zen-dark-6'>
                The country where you claim to be a resident for income tax tax
                purposes.
              </p>
            </div>

            <div>
              <ZenControlledCheckboxInput<
                MSDynamicOnboardingFormData,
                'w8ECI.businessAddressSameAsPermanent'
              >
                shouldUnregister={false}
                control={control}
                name='w8ECI.businessAddressSameAsPermanent'
                options={[
                  {
                    label:
                      'My business address is the same as my permanent residence',
                    value: YesNoType.YES,
                  },
                ]}
                reverse
              />
            </div>

            {isBusinessAddressDifferent && (
              <>
                <div>
                  <ZenControlledTextInput<
                    MSDynamicOnboardingFormData,
                    'w8ECI.businessAddressLine'
                  >
                    control={control}
                    label='Business address'
                    name='w8ECI.businessAddressLine'
                    placeholder='Business address'
                    shouldUnregister={false}
                    rules={{ required: 'Please enter a business address' }}
                    isRequired
                  />
                  <p className='font-zen-body text-base font-normal text-zen-dark-6'>
                    If different than permanent address
                  </p>
                </div>

                <div className='flex flex-col md:flex-row md:items-center space-y-8 md:space-y-0 md:space-x-2.5'>
                  <div className='flex-1'>
                    <ZenControlledTextInput<
                      MSDynamicOnboardingFormData,
                      'w8ECI.businessCity'
                    >
                      label='City'
                      placeholder='City'
                      control={control}
                      name='w8ECI.businessCity'
                      shouldUnregister={false}
                      rules={{ required: 'Please enter a city' }}
                      isRequired
                    />
                  </div>
                  <div className='flex-1'>
                    <ZenControlledStateOrProvince<
                      MSDynamicOnboardingFormData,
                      'w8ECI.businessState'
                    >
                      control={control}
                      name='w8ECI.businessState'
                      setValue={setValue}
                      selectedCountry={
                        businessCountry ??
                        AddressRequestCountryEnum.UnitedStates
                      }
                      rules={{ required: 'Please select a state.' }}
                      isRequired
                      shouldUnregister={false}
                    />
                  </div>
                  <div className='flex-1'>
                    <ZenControlledTextInput<
                      MSDynamicOnboardingFormData,
                      'w8ECI.businessZipCode'
                    >
                      control={control}
                      label='Zipcode'
                      placeholder='Zipcode'
                      name='w8ECI.businessZipCode'
                      rules={{
                        required: 'Please enter a zip code',
                        ...getPostalCodeValidation(businessCountry),
                      }}
                      shouldUnregister={false}
                      isRequired
                      type='number'
                    />
                  </div>
                </div>

                <div>
                  <ZenControlledHTMLSelectInput<
                    MSDynamicOnboardingFormData,
                    'w8ECI.businessCountry'
                  >
                    name='w8ECI.businessCountry'
                    control={control}
                    label='Country'
                    shouldUnregister={false}
                    options={[
                      {
                        label: 'Select Country',
                        value: '',
                        disabled: true,
                      },
                      ...values(AdministrativeAreaResponseCountryEnum).map(
                        (country) => ({
                          value: country,
                          label: capitalizeEnum(country),
                        }),
                      ),
                    ]}
                    rules={{ required: 'Please select a country' }}
                    isRequired
                  />
                </div>
              </>
            )}

            <div>
              <ZenControlledTextAreaInput<
                MSDynamicOnboardingFormData,
                'w8ECI.specifyIncome'
              >
                control={control}
                label='Specify income'
                name='w8ECI.specifyIncome'
                placeholder='Specify income'
                shouldUnregister={false}
                rows={3}
              />
              <p className='font-zen-body text-base font-normal text-zen-dark-6'>
                Specify each item of income that is, or is expected to be,
                received from the payer that effectively connected with the
                conduct of a trade or business in the United States.
              </p>
            </div>

            <div className='font-zen-body text-zen-dark-9 space-y-5'>
              <div className='space-y-2.5'>
                <div className='font-bold'>Certification</div>

                <div className='leading-snug'>
                  Under penalties of perjury, I declare that I have examined the
                  information on this form and to the best of my knowledge and
                  believe it is true, correct, and complete. I further certify
                  under penalties of perjury that:
                </div>
              </div>

              <ol className='pl-10 list-disc leading-snug space-y-3'>
                <li>
                  I am the beneficial owner (or I am authorized to sign for the
                  beneficial owner) of all the payments to which this form
                  relates,
                </li>
                <li>
                  The amounts for which this certification is provided are
                  effectively connected with the conduct of a trade or business
                  in the United States,
                </li>
                <li>
                  The income for which this form was provided is includible in
                  my gross income (or the beneficial owner&apos;s gross income)
                  for the taxable year, and
                </li>
                <li className='space-y-3'>
                  The beneficial owner is not a US person.
                </li>
              </ol>

              <div className='leading-snug'>
                Furthermore, I authorize this form to be provided to any
                withholding agent that has control, receipt, or custody of the
                payments of which I am the beneficial owner or any withholding
                agent that can disburse or make payments of the amounts of which
                I am the beneficial owner.
              </div>

              <div className='leading-snug'>
                I agree that I will submit a new form within 30 days if any
                certification made on this form becomes incorrect.
              </div>

              <div className='leading-snug'>
                The Internal Revenue Service does not require your consent to
                any provisions of this document other than the certifications
                required to establish your status as a non-US person and that
                the income for which this form is provided is effectively
                connected with the conduct of a trade or business within the
                United States.
              </div>
            </div>

            <div>
              <ZenControlledSignatureInput<
                MSDynamicOnboardingFormData,
                'w8ECI.signature'
              >
                control={control}
                label='Your Signature'
                name='w8ECI.signature'
                placeholder='Type Full name'
                shouldUnregister={false}
                rules={{ required: 'Please enter a signature' }}
                isRequired
                readOnly={!!taxFormID}
              />
              <p className='font-zen-body text-base font-normal text-zen-dark-6'>
                Typing your name acts as your signature and certifies that you
                have capacity to sign for the person/entity identified on this
                form
              </p>
            </div>

            <div>
              <ZenControlledDatePickerInput<
                MSDynamicOnboardingFormData,
                'w8ECI.signDate'
              >
                control={control}
                shouldUnregister={false}
                name='w8ECI.signDate'
                placeholder='mm/dd/yyyy'
                label='Sign Date'
                rules={{ required: 'Please pick a sign date' }}
                isRequired
                readOnly={!!taxFormID}
              />
              <p className='font-zen-body text-base font-normal text-zen-dark-6'>
                Use mm-dd-yyyy format, e.g., 06-30-2024
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-sm'>
          <div className='flex flex-row items-center py-8 shadow-top-sm justify-center space-x-5'>
            <div className='w-28'>
              <ZenButton
                isFullWidth
                variant='primary-outline'
                type='button'
                label='Previous'
                onClick={onPrevious}
              />
            </div>
            <div className='w-56'>
              <ZenButton
                isFullWidth
                type='submit'
                variant='primary'
                label='Next'
                isSubmitting={loading}
                isDisabled={loading}
                onClick={handleNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withMSDynamicsProgress(MSDynamicsFormW8eci);
