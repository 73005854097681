import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';
import { useCallback, useMemo, useState } from 'react';

const BASE_URL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/esm';

const useFFmpeg = (): {
  load: () => Promise<void>;
  transcode: (
    filePath: string,
    inputPath: string,
    outputPath: string,
  ) => Promise<string>;
} => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const ffmpeg = useMemo(() => new FFmpeg(), []);

  const load = useCallback(async () => {
    if (isLoaded || isLoading) {
      return;
    }

    // toBlobURL is used to bypass CORS issue, urls with the same
    // domain can be used directly.
    const [coreURL, wasmURL] = await Promise.all([
      toBlobURL(`${BASE_URL}/ffmpeg-core.js`, 'text/javascript'),
      toBlobURL(`${BASE_URL}/ffmpeg-core.wasm`, 'application/wasm'),
    ]);

    await ffmpeg.load({ coreURL, wasmURL });

    setIsLoading(false);
    setIsLoaded(true);
  }, [ffmpeg, isLoaded, isLoading]);

  const transcode = useCallback(
    async (filePath: string, inputPath: string, outputPath: string) => {
      const blob = await fetchFile(filePath);
      await ffmpeg.writeFile(inputPath, blob);
      await ffmpeg.exec(['-i', inputPath, outputPath]);
      const data = await ffmpeg.readFile(outputPath);
      const path = URL.createObjectURL(
        // @ts-expect-error
        new Blob([data.buffer], { type: 'audio/m4a' }),
      );
      return path;
    },
    [ffmpeg],
  );

  return { load, transcode };
};

export default useFFmpeg;
