import {
  faCircleQuestion,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../../../openapi/arrakis';
import { setActiveTransition } from '../../../../slices/QuickActionSlice';
import { EnumMap, RootState } from '../../../../types';
import { cn } from '../../../../utils/classUtils';
import { safeEnumMapGet } from '../../../../utils/EnumHelper';
import {
  getActionableTransitions,
  renderTransactionStatusByCountry,
} from '../../../../utils/TransactionHelper';
import ExcludeBroker from '../../../auth/ExcludeBroker';
import ExcludeRealTitleOfficer from '../../../auth/ExcludeRealTitleOfficer';
import { transactionStatusToIcon } from '../../../table/Cells/TransactionStateCell';
import GeminiTransactionAction from './GeminiTransactionAction';

export type GeminiTransactionHeaderVariantType =
  | 'default'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'alert';

export const getTransactionStatusVariant = (
  status: TransactionLifecycleStateValueStateEnum,
): GeminiTransactionHeaderVariantType => {
  switch (status) {
    case TransactionLifecycleStateValueStateEnum.ApprovedForClosing:
    case TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved:
    case TransactionLifecycleStateValueStateEnum.CommissionDocumentGenerated:
    case TransactionLifecycleStateValueStateEnum.CommissionDocumentSent:
    case TransactionLifecycleStateValueStateEnum.Settled:
    case TransactionLifecycleStateValueStateEnum.Closed:
    case TransactionLifecycleStateValueStateEnum.CommissionValidated:
    case TransactionLifecycleStateValueStateEnum.PaymentAccepted:
    case TransactionLifecycleStateValueStateEnum.ListingActive:
      return 'success';
    case TransactionLifecycleStateValueStateEnum.ReadyForCommissionDocumentGeneration:
    case TransactionLifecycleStateValueStateEnum.CalculateLedger:
    case TransactionLifecycleStateValueStateEnum.WaitingOnPayment:
    case TransactionLifecycleStateValueStateEnum.NeedsCommissionValidation:
    case TransactionLifecycleStateValueStateEnum.New:
    case TransactionLifecycleStateValueStateEnum.ListingInContract:
      return 'warning';
    case TransactionLifecycleStateValueStateEnum.PaymentScheduled:
      return 'primary';
    case TransactionLifecycleStateValueStateEnum.Terminated:
      return 'danger';
    case TransactionLifecycleStateValueStateEnum.TerminationRequested:
      return 'alert';
    case TransactionLifecycleStateValueStateEnum.ListingClosed:
      return 'default';
    default:
      return 'warning';
  }
};

interface GeminiTransactionStatusInfoProps {
  transaction: TransactionResponse;
  setIsHelpSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const GeminiTransactionStatusInfo: React.FC<GeminiTransactionStatusInfoProps> = ({
  transaction,
  setIsHelpSidebarOpen,
}) => {
  const dispatch = useDispatch();
  const {
    auth: { isAdmin, isBroker },
  } = useSelector((state: RootState) => state);
  const { lifecycleState } = transaction;
  const transitionInfo = getActionableTransitions(transaction, isAdmin);
  const isCommissionValidated =
    lifecycleState?.state ===
    TransactionLifecycleStateValueStateEnum.CommissionValidated;
  const statusVariant = getTransactionStatusVariant(lifecycleState?.state!);

  const transactionStatusVariantTypeToClassNameMap: EnumMap<
    GeminiTransactionHeaderVariantType,
    string
  > = {
    default: 'bg-grey-100 text-grey-600',
    primary: 'bg-rezen-light-blue-100 text-primary-navy',
    success: 'bg-green-100 text-midnight-green-900',
    warning: 'bg-yellow-100 text-yellow-900',
    danger: 'bg-red-100 text-red-600',
    alert: 'bg-purple-300 text-purple-600',
  };

  return (
    <div
      id='transaction-status'
      data-testid='transaction-status'
      className={cn(
        'rounded-[10px] overflow-hidden px-6 py-4',
        transactionStatusVariantTypeToClassNameMap[statusVariant],
      )}
    >
      <div className='flex flex-row items-start justify-between font-zen-body-2'>
        {!!lifecycleState?.state && (
          <div className='flex flex-row items-center'>
            <div className='h-6 w-6 flex items-center justify-center'>
              {safeEnumMapGet(
                transactionStatusToIcon,
                lifecycleState?.state,
                <FontAwesomeIcon icon={faTriangleExclamation} />,
              )}
            </div>
            <span className='ml-1.5 text-base leading-5 font-semibold'>
              {renderTransactionStatusByCountry(
                lifecycleState.state,
                transaction.country!,
              )}
            </span>
          </div>
        )}
        <div
          className='h-6 w-6 flex flex-row items-center justify-center shrink-0 cursor-pointer'
          aria-label='help-modal'
          onClick={() => setIsHelpSidebarOpen(true)}
        >
          <FontAwesomeIcon
            icon={faCircleQuestion}
            className='text-xl text-primary-dark'
          />
        </div>
      </div>
      {!!lifecycleState?.description && (
        <p className='font-zen-body-2 text-sm font-normal text-primary-dark mt-1'>
          {lifecycleState.description}
        </p>
      )}
      <div className='space-y-4 flex flex-col mt-2'>
        <ExcludeBroker>
          <ExcludeRealTitleOfficer>
            {transitionInfo.primaryTransitions.map((transition) => (
              <GeminiTransactionAction
                key={transition.state!}
                action={transition.state!}
                onClick={() => dispatch(setActiveTransition(transition))}
                transaction={transaction}
              />
            ))}
          </ExcludeRealTitleOfficer>
        </ExcludeBroker>
        {isBroker && isCommissionValidated && (
          <GeminiTransactionAction
            action={transaction.nextPrimaryAdminTransition?.state!}
            onClick={() =>
              dispatch(
                setActiveTransition(transaction.nextPrimaryAdminTransition),
              )
            }
            transaction={transaction}
          />
        )}
      </div>
    </div>
  );
};

export default GeminiTransactionStatusInfo;
