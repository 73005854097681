import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { ADDRESS_PLACEHOLDER } from '../../constants/PlaceholderConstants';
import {
  CreateParticipantRequest,
  CreateParticipantRequestParticipantRoleEnum,
  NationalIdentificationValueTypeEnum,
  ParticipantValueRoleEnum,
  PaymentParticipantValueTypeEnum,
  TransactionResponse,
  TransactionResponseCountryEnum,
} from '../../openapi/arrakis';
import ErrorService from '../../services/ErrorService';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import {
  addCommissionParticipant,
  addOPCityCommissionParticipant,
  fetchTransactionDetails,
  uploadW9Form,
} from '../../slices/TransactionSlice';
import { fetchUserByIds } from '../../slices/UserIdsSlice';
import {
  AppDispatch,
  AsyncSelectOption,
  ISelectOption,
  ParticipantType,
} from '../../types';
import { getIdValidations } from '../../utils/AgentHelper';
import { searchForAgents } from '../../utils/TableUtils';
import {
  RequiredField,
  getValidParticipantRoles,
  isAddParticipantLabelRequired,
  isCanadaTransaction,
  isNotOPCityParticipantType,
  validateFields,
} from '../../utils/TransactionHelper';
import {
  EMAIL_VALIDATIONS,
  FILE_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../utils/Validations';
import ControlledAsyncSelectInputV7 from '../ControlledAsyncSelectInputV7';
import ControlledEmailInputV7 from '../ControlledEmailInputV7';
import ControlledFileUploadInputV7 from '../ControlledFileUploadInputV7';
import ControlledPhoneNumberInputV7 from '../ControlledPhoneNumberInputV7';
import ControlledRadioInputV7 from '../ControlledRadioInputV7';
import ControlledSelectInputV7 from '../ControlledSelectInputV7';
import ControlledTextAreaInputV7 from '../ControlledTextAreaInputV7';
import ControlledTextInputV7 from '../ControlledTextInputV7';
import ControlledToggleInputV7 from '../ControlledToggleInputV7';
import IconButton from '../IconButton';
import SidebarModal from '../SidebarModal';
import SidebarModalActionFooter from '../SidebarModal/SideBarModalActionFooter';
import AdminOnly from '../auth/AdminOnly';

interface AddParticipantsFormProps {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
  agentType?: ParticipantType;
  role?: ParticipantValueRoleEnum;
}

interface FormData {
  type: ParticipantType;
  yentaId: ISelectOption;
  firstName: string;
  lastName: string;
  company: string;
  emailAddress: string;
  phoneNumber: string;
  commissionDocumentRecipient: boolean;
  passThrough: boolean;
  personalDeal: boolean;
  address: string;
  participantRole: ParticipantValueRoleEnum;
  w9form: string;
  Bn: string;
  gstId: string;
  pstId: string;
  hstId: string;
  qstId: string;
  ein: string;
  sin: string;
  notes: string;
}

const OPCITY_KEY = 'opCity';

const AddParticipantsForm: React.FC<AddParticipantsFormProps> = ({
  isOpen,
  onClose,
  transaction,
  agentType,
  role,
}) => {
  const {
    control,
    watch,
    trigger,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const dispatch: AppDispatch = useDispatch();
  const type = watch(
    'type',
    agentType! || PaymentParticipantValueTypeEnum.ExternalEntity,
  );

  const yentaId = watch('yentaId', { label: '', value: '' });
  const isCanada =
    transaction.country === TransactionResponseCountryEnum.Canada;

  const [
    participantRole,
    firstName,
    lastName,
    companyName,
    commissionDocumentRecipient,
    ein,
  ] = watch([
    'participantRole',
    'firstName',
    'lastName',
    'company',
    'commissionDocumentRecipient',
    'ein',
  ]);

  useEffect(() => {
    if (
      isOpen &&
      type ===
        ((PaymentParticipantValueTypeEnum.ExternalEntity as unknown) as ParticipantType)
    ) {
      trigger(['firstName', 'lastName', 'company', 'address', 'emailAddress']);
    }
  }, [trigger, firstName, lastName, companyName, isOpen, type]);

  useEffect(() => {
    if (yentaId?.value) {
      dispatch(fetchUserByIds([yentaId.value]));
    }
  }, [dispatch, yentaId?.value]);

  const watchFields = {
    firstName,
    lastName,
    companyName,
    participantRole,
    ein,
  };

  const participantTypeOptions = [
    {
      label: 'Real Agent',
      value: PaymentParticipantValueTypeEnum.Agent,
    },
    {
      label: 'External Participant',
      value: PaymentParticipantValueTypeEnum.ExternalEntity,
    },
  ];

  if (!transaction.listing && !isCanadaTransaction(transaction)) {
    participantTypeOptions.push({
      label: 'Op City',
      value: OPCITY_KEY as PaymentParticipantValueTypeEnum,
    });
  }

  const onSubmit = async (data: FormData) => {
    if (data.type === OPCITY_KEY) {
      await dispatch(addOPCityCommissionParticipant(transaction.id!));
    } else {
      const nationalIds = [];

      if (isCanada) {
        if (data.Bn) {
          nationalIds?.push({
            id: data.Bn,
            type: NationalIdentificationValueTypeEnum.Bn,
          });
        }
        if (data.gstId) {
          nationalIds?.push({
            id: data.gstId,
            type: NationalIdentificationValueTypeEnum.GstId,
          });
        }
        if (data.pstId) {
          nationalIds?.push({
            id: data.pstId,
            type: NationalIdentificationValueTypeEnum.PstId,
          });
        }
        if (data.hstId) {
          nationalIds?.push({
            id: data.hstId,
            type: NationalIdentificationValueTypeEnum.HstId,
          });
        }
        if (data.qstId) {
          nationalIds?.push({
            id: data.qstId,
            type: NationalIdentificationValueTypeEnum.QstId,
          });
        }
        if (data.sin) {
          nationalIds?.push({
            id: data.sin,
            type: NationalIdentificationValueTypeEnum.Sin,
          });
        }
      } else {
        if (data.ein) {
          nationalIds?.push({
            id: data.ein,
            type: NationalIdentificationValueTypeEnum.Ein,
          });
        }
      }

      const finalData: CreateParticipantRequest = {
        emailAddress: data.emailAddress,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        yentaId: data.yentaId?.value,
        commissionDocumentRecipient: !!data.commissionDocumentRecipient,
        payer: false,
        passThrough: !!data.passThrough,
        paidByReal: false,
        personalDeal: !!data.personalDeal,
        address: data.address,
        paymentInstructions: '',
        participantRole: (data.participantRole as unknown) as CreateParticipantRequestParticipantRoleEnum,
        paidViaBusinessEntity: data.company
          ? {
              name: data.company,
              nationalIds,
            }
          : undefined,
        notes: data.notes,
      };
      const participant = await dispatch(
        addCommissionParticipant(transaction.id!, finalData),
      );

      if (participant) {
        if (data.w9form) {
          await dispatch(uploadW9Form(participant.id!, data.w9form[0]));
        }
      }
    }

    await dispatch(fetchTransactionDetails(transaction.id!, false));
    onClose();
  };

  return (
    <SidebarModal
      title='Add Participant'
      subtitle={transaction.address?.oneLine}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-2 md:p-4'>
          <IconButton
            leftIcon={<FontAwesomeIcon icon={faChevronLeft} size='sm' />}
            label='Back'
            variant='outline'
            onClick={onClose}
          />
          <div className='mt-5'>
            <ControlledRadioInputV7
              label='Participant type'
              options={participantTypeOptions}
              name='type'
              defaultValue={
                agentType! || PaymentParticipantValueTypeEnum.ExternalEntity
              }
              isRequired
              control={control}
              rules={{
                required: 'Required',
              }}
              inlineOptions
            />
          </div>
          {isNotOPCityParticipantType(type, agentType) ? (
            <div>
              <div className='mt-5'>
                <ControlledSelectInputV7
                  name='participantRole'
                  control={control}
                  label='Role'
                  placeholder='Role'
                  rules={{ required: 'Please select a role' }}
                  options={[
                    {
                      label: `Select Role`,
                      value: '',
                    },
                    ...getValidParticipantRoles(
                      transaction.transactionType!,
                      transaction?.transactionOwner?.represents,
                    ),
                  ]}
                  isRequired
                  defaultValue={role}
                />
              </div>
              {((PaymentParticipantValueTypeEnum.Agent as unknown) as ParticipantType) ===
              type ? (
                <div className='mt-5'>
                  <ControlledAsyncSelectInputV7
                    control={control}
                    name='yentaId'
                    placeholder='Search'
                    label='Name of Participant'
                    fetchData={async (search, page) => {
                      try {
                        const { data } = await searchForAgents({
                          page,
                          search,
                          filterBy: {
                            country: [transaction?.country!],
                          },
                        });

                        const options: AsyncSelectOption[] = data.map(
                          (resp) => ({
                            value: `${resp.id}`,
                            label: `${resp.firstName} ${resp.lastName}`,
                          }),
                        );

                        return options;
                      } catch (e) {
                        ErrorService.notify(
                          'Unable to search for registered agents in add participants',
                          e,
                          {
                            search: {
                              term: search,
                              page,
                              country: transaction?.country,
                            },
                          },
                        );
                        dispatch(
                          showErrorToast(
                            'An unexpected error occurred.',
                            'We were unable to search for an agent. Please try again in a few moments or contact support.',
                          ),
                        );
                      }

                      return [];
                    }}
                    rules={{
                      required: 'Required',
                    }}
                    isRequired
                  />
                </div>
              ) : (
                <div>
                  <div className='flex flex-row mt-5 space-x-2'>
                    <ControlledTextInputV7
                      control={control}
                      label='First name'
                      name='firstName'
                      placeholder='E.g. John'
                      rules={{
                        minLength: {
                          value: 2,
                          message: 'Must be at least 2 characters',
                        },
                        ...validateFields(
                          RequiredField.FIRST_NAME,
                          transaction,
                          watchFields,
                        ),
                      }}
                      isRequired
                    />
                    <ControlledTextInputV7
                      control={control}
                      label='Last name'
                      name='lastName'
                      placeholder='E.g. Dow'
                      rules={{
                        minLength: {
                          value: 2,
                          message: 'Must be at least 2 characters',
                        },
                        ...validateFields(
                          RequiredField.LAST_NAME,
                          transaction,
                          watchFields,
                        ),
                      }}
                      isRequired
                    />
                  </div>
                  <div className='mt-5'>
                    <ControlledTextInputV7
                      control={control}
                      label='Business name'
                      subLabel='(I.e. Brokerage or company name)'
                      name='company'
                      placeholder='E.g. Dream Home LLC.'
                      rules={{
                        ...validateFields(
                          RequiredField.COMPANY_NAME,
                          transaction,
                          watchFields,
                        ),
                      }}
                      isRequired
                    />
                  </div>
                  {!isCanada ? (
                    <div className='mt-5'>
                      <ControlledTextInputV7
                        control={control}
                        label='EIN'
                        name='ein'
                        placeholder='E.g. 12-1234567'
                        rules={{
                          ...getIdValidations(
                            NationalIdentificationValueTypeEnum.Ein,
                          ),
                        }}
                      />
                    </div>
                  ) : (
                    <div className='flex flex-row mt-5 space-x-2'>
                      <ControlledTextInputV7
                        control={control}
                        label='GST'
                        name='gstId'
                        placeholder='E.g. 123456789 RT 0001'
                        rules={{
                          ...getIdValidations(
                            NationalIdentificationValueTypeEnum.GstId,
                          ),
                        }}
                      />
                      <ControlledTextInputV7
                        control={control}
                        label='Business number'
                        name='Bn'
                        placeholder='E.g. 123456789'
                        rules={{
                          ...getIdValidations(
                            NationalIdentificationValueTypeEnum.Bn,
                          ),
                        }}
                      />
                    </div>
                  )}

                  <div className='mt-5'>
                    <ControlledEmailInputV7
                      control={control}
                      label='Email'
                      subLabel='(Required if receiving electronic documents)'
                      name='emailAddress'
                      placeholder='E.g. John@example.com'
                      rules={{
                        required: {
                          value: !!commissionDocumentRecipient,
                          message:
                            'Email is required if receiving electronic documents',
                        },
                        ...EMAIL_VALIDATIONS,
                        ...validateFields(
                          RequiredField.EMAIL,
                          transaction,
                          watchFields,
                        ),
                      }}
                      isRequired={
                        isAddParticipantLabelRequired(
                          transaction,
                          participantRole,
                        ) || !!commissionDocumentRecipient
                      }
                    />
                  </div>
                  <div className='mt-5'>
                    <ControlledPhoneNumberInputV7
                      control={control}
                      label='Phone number'
                      name='phoneNumber'
                      placeholder='E.g. +1 (702) 123-4567'
                      rules={{
                        ...PHONE_NUMBER_VALIDATIONS,
                      }}
                    />
                  </div>
                  <div className='mt-5'>
                    <ControlledTextInputV7
                      control={control}
                      label='Address'
                      subLabel='(Must match address on W9 Form, if uploaded)'
                      name='address'
                      placeholder={ADDRESS_PLACEHOLDER}
                      rules={{
                        ...validateFields(
                          RequiredField.ADDRESS,
                          transaction,
                          watchFields,
                        ),
                      }}
                      isRequired={isAddParticipantLabelRequired(
                        transaction,
                        participantRole,
                      )}
                    />
                  </div>
                  <div className='mt-5'>
                    {!isCanadaTransaction(transaction) && (
                      <ControlledFileUploadInputV7
                        name='w9form'
                        control={control}
                        label='Upload W9 Form'
                        placeholder='E.g. W9.pdf'
                        accept='.pdf'
                        rules={{
                          ...FILE_VALIDATIONS,
                        }}
                      />
                    )}
                  </div>
                  {isCanada && (
                    <div className='mt-5'>
                      <label className='text-base font-primary-medium text-dark'>
                        Other Tax Id. Numbers
                      </label>
                      <div className='mt-2'>
                        <ControlledTextInputV7
                          control={control}
                          name='sin'
                          label='Social insurance number (SIN)'
                          placeholder='E.g. 123-123-1234'
                          rules={{
                            ...getIdValidations(
                              NationalIdentificationValueTypeEnum.Sin,
                            ),
                          }}
                        />
                      </div>
                      <div className='mt-5'>
                        <ControlledTextInputV7
                          control={control}
                          name='hstId'
                          label='Harmonized sales tax (HST)'
                          placeholder='E.g. 123456789 RT 00002'
                          rules={{
                            ...getIdValidations(
                              NationalIdentificationValueTypeEnum.HstId,
                            ),
                          }}
                        />
                      </div>
                      <div className='mt-5'>
                        <ControlledTextInputV7
                          control={control}
                          name='pstId'
                          label='Provincial sales tax (PST)'
                          placeholder='E.g. PST-1234-1234'
                          rules={{
                            ...getIdValidations(
                              NationalIdentificationValueTypeEnum.PstId,
                            ),
                          }}
                        />
                      </div>
                      <div className='mt-5'>
                        <ControlledTextInputV7
                          control={control}
                          name='qstId'
                          label='Quebec sales tax (QST)'
                          placeholder='E.g. 123456789 TQ 1234'
                          rules={{
                            ...getIdValidations(
                              NationalIdentificationValueTypeEnum.QstId,
                            ),
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className='mt-5'>
                <ControlledTextAreaInputV7
                  control={control}
                  label='Notes'
                  name='notes'
                  placeholder={`E.g. Assistant's name and number, etc.`}
                  rows={2}
                />
              </div>

              <div className='pb-20 mt-5'>
                {!transaction.listing && (
                  <div className='border divide-y rounded'>
                    <AdminOnly>
                      <ControlledToggleInputV7
                        name='personalDeal'
                        control={control}
                        defaultValue={false}
                        label='Personal Deal?'
                      />
                    </AdminOnly>
                    <ControlledToggleInputV7
                      name='commissionDocumentRecipient'
                      control={control}
                      defaultValue={false}
                      label='Receives Invoices/Comm. Documents?'
                    />
                    <AdminOnly>
                      <ControlledToggleInputV7
                        name='passThrough'
                        control={control}
                        defaultValue={false}
                        label='Pass Through Deal?'
                      />
                    </AdminOnly>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
        <SidebarModalActionFooter
          isSubmitting={isSubmitting}
          onClose={onClose}
        />
      </form>
    </SidebarModal>
  );
};

export default AddParticipantsForm;
