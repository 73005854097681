import {
  Control,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { useHistory, useLocation } from 'react-router-dom';
import ZenSearchBar from '../../ZenSearchBar';
import ZenControlledDraggableHorizontalInput from '../../Input/ZenControlledDraggableHorizontalInput';
import { MAX_DOC_SIZE_100MB } from '../../../../constants/FilesConstants';
import FileCabinetAction from './FileCabinetActionTabs';

interface ZenArchiveFileHeaderProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  isTrashFolder: boolean;
  search: string;
  setSearch: (value: string) => void;
  showActionButton: boolean;
  isUploading: boolean;
  control: Control<TFieldValues>;
}

export const ZenArchiveFileHeader = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  isTrashFolder,
  search,
  control,
  name,
  setSearch,
  showActionButton,
  isUploading,
}: ZenArchiveFileHeaderProps<TFieldValues, TName>) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div>
      <div className='flex flex-col items-center justify-between md:flex-row pb-4 gap-3'>
        {showActionButton && (
          <div className='flex flex-col md:flex-row items-center justify-between space-y-2'>
            <FileCabinetAction
              onArchive={() => {
                history.push(`${location.pathname}?isArchive=true`);
              }}
              onViewAll={() => {
                history.push(`${location.pathname}`);
              }}
            />
          </div>
        )}
        <div className='flex flex-col items-center justify-between md:flex-row gap-2'>
          <div className={`${isTrashFolder ? 'md:pr-1' : ''}`}>
            <ZenSearchBar value={search} onChange={setSearch} />
          </div>
          {!isTrashFolder && showActionButton && (
            <div className='w-72 md:w-80 overflow-hidden'>
              <ZenControlledDraggableHorizontalInput
                name={name}
                iconHeight={19}
                iconWidth={19}
                control={control}
                inputHeight='small'
                accept='/'
                isUploading={isUploading}
                maxUploadSize={MAX_DOC_SIZE_100MB}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ZenArchiveFileHeader;
