import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';
import ZenEmailUnbanFormSidebarModal from '../forms/ZenEmailUnbanFormSidebarModal';

interface ZenEmailStatusCheckProps {}

const ZenUnbanEmail: React.FC<ZenEmailStatusCheckProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <ZenResourceLinkCard
        title='Unban Email'
        onClick={() => setIsOpen(true)}
        icon={solid('envelope-circle-check')}
        iconSize='2x'
      />
      {isOpen && (
        <ZenEmailUnbanFormSidebarModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default ZenUnbanEmail;
