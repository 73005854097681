import React, { useState } from 'react';
import {
  InviteResponse,
  InviteResponseStatusEnum,
  MembershipResponseStatusEnum,
} from '../../openapi/avalon';
import ResourceContainer from '../ResourceContainer';
import ZenCard from '../Zen/ZenCard';

import ZenSidebarModal from '../Zen/ZenSidebarModal';
import ZenTabs from '../Zen/ZenTabs';
import ZenAgentJVCard from './ZenAgentJVCard';

interface ZenRealJointVentureSideBarModelProps {
  isOpen: boolean;
  onClose(): void;
  jointVentures: InviteResponse[] | undefined;
}

const ZenRealJointVentureSideBarModel: React.FC<ZenRealJointVentureSideBarModelProps> = ({
  isOpen,
  onClose,
  jointVentures,
}) => {
  const [currentTab, setCurrentTab] = useState<string>('New Released');

  const newReleased =
    jointVentures?.filter(
      (jointVenture) =>
        jointVenture.status === InviteResponseStatusEnum.Created ||
        jointVenture.status === InviteResponseStatusEnum.Viewed,
    ) || [];

  const ownedJV =
    jointVentures?.filter(
      (jointVenture) =>
        jointVenture.membership?.status === MembershipResponseStatusEnum.Active,
    ) || [];

  const newReleasedJVCards = () => {
    return (
      <ResourceContainer
        isEmpty={!newReleased.length}
        loading={false}
        resourceName='Released Tier'
      >
        {newReleased?.map((jointVenture) => {
          return (
            <ZenAgentJVCard
              key={jointVenture.id}
              colorCode={jointVenture.tier?.colorCode!}
              interests={jointVenture.interests!}
              jointVentureName={jointVenture.jointVenture?.name!}
              status={jointVenture.status!}
              tierName={jointVenture.tier?.name!}
              inviteId={jointVenture.id!}
              inSidebar
              isRounded
            />
          );
        })}
      </ResourceContainer>
    );
  };
  const ownedJVCards = () => {
    return (
      <ResourceContainer
        isEmpty={!ownedJV.length}
        loading={false}
        resourceName='Owned Tier'
      >
        {ownedJV?.map((jointVenture) => {
          return (
            <ZenAgentJVCard
              key={jointVenture.id}
              colorCode={jointVenture.tier?.colorCode!}
              interests={jointVenture.interests!}
              jointVentureName={jointVenture.jointVenture?.name!}
              status={jointVenture.status!}
              tierName={jointVenture.tier?.name!}
              inviteId={jointVenture.id!}
              inSidebar
              isRounded
            />
          );
        })}
      </ResourceContainer>
    );
  };
  return (
    <ZenSidebarModal title='Real JV Tiers' isOpen={isOpen} onClose={onClose}>
      <ZenCard>
        <div className='px-0 md:px-4 lg:pt-5'>
          <ZenTabs
            tabs={[
              {
                name: 'New Released',
                TabComponent: (
                  <div className='space-y-4 py-4'>{newReleasedJVCards()}</div>
                ),
              },
              {
                name: 'Owned',
                TabComponent: (
                  <div className='space-y-4 py-4'>{ownedJVCards()}</div>
                ),
              },
            ]}
            selected={currentTab}
            onChange={setCurrentTab}
            size='lg'
          />
        </div>
      </ZenCard>
    </ZenSidebarModal>
  );
};

export default ZenRealJointVentureSideBarModel;
