import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  TransactionSetupFormProps,
  TransactionSetupStepName,
} from '../../../containers/transaction/setup/TransactionSetupContainer';
import {
  ParticipantValueRoleEnum,
  PaymentParticipantValueRoleEnum,
} from '../../../openapi/arrakis';
import { RootState, YesNoType } from '../../../types';
import { getAllTransactionParticipantsByRole } from '../../../utils/TransactionHelper';
import ControlledRadioInputV7 from '../../ControlledRadioInputV7';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import TransactionParticipantSelectionRow from './TransactionParticipantSelectionRow';

const TransactionSetupReferrerStep: StepByStepComponent<
  TransactionSetupFormProps,
  TransactionSetupStepName
> = ({ form: { control, watch, trigger } }) => {
  const {
    transactionDetailResponse: { data: transactionDetail },
  } = useSelector((state: RootState) => state.transaction);

  const allReferralParticipants = useMemo(
    () =>
      getAllTransactionParticipantsByRole(
        transactionDetail!,
        PaymentParticipantValueRoleEnum.ReferringAgent,
      ),
    [transactionDetail],
  );

  const needsReferral: boolean = watch('referral') === YesNoType.YES;

  useEffect(() => {
    trigger('referral');
  }, [allReferralParticipants, trigger]);

  return (
    <div className='flex-grow flex flex-col mt-10 w-full max-w-xl'>
      <p className='text-xl font-primary-medium text-dark mb-4'>
        Do you have a referral or an OpCity referral?
      </p>
      <ControlledRadioInputV7<TransactionSetupFormProps, 'referral'>
        shouldUnregister={false}
        control={control}
        name='referral'
        rules={{
          required: 'Required',
          validate: (value: YesNoType) => {
            return value === YesNoType.YES && !allReferralParticipants?.length
              ? 'Please add referrer to proceed.'
              : undefined;
          },
        }}
        options={[
          { label: 'Yes', value: YesNoType.YES },
          { label: 'No', value: YesNoType.NO },
        ]}
        inlineOptions
      />
      {needsReferral && (
        <TransactionParticipantSelectionRow
          transaction={transactionDetail!}
          defaultRole={ParticipantValueRoleEnum.ReferringAgent}
        />
      )}
    </div>
  );
};

export default TransactionSetupReferrerStep;
