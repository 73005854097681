import React from 'react';
import { useSelector } from 'react-redux';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenActivityTable from '../components/transactions/Activity/ZenActivityTable';
import { DEFAULT_PAGE_SIZE } from '../constants/TableConstants';
import {
  ActivityControllerApi,
  PagedActivityResponse,
} from '../openapi/memento';
import ErrorService from '../services/ErrorService';
import { RootState } from '../types';
import { getMementoConfiguration } from '../utils/OpenapiConfigurationUtils';
import { getPageTitleForTransaction } from '../utils/TransactionUtils';

interface ZenTransactionActivityProps {}

const ZenTransactionActivity: React.FC<ZenTransactionActivityProps> = () => {
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
    },
  } = useSelector((state: RootState) => state);

  const fetchActivities = async (
    pageNumber: number,
  ): Promise<PagedActivityResponse> => {
    const { data } = await new ActivityControllerApi(
      getMementoConfiguration(),
    ).getActivities(transactionDetail?.id!, pageNumber, DEFAULT_PAGE_SIZE);

    return data;
  };

  return (
    <ZenRoute
      title={`${getPageTitleForTransaction(transactionDetail)} Activity - ${
        transactionDetail?.address?.oneLine
      }`}
    >
      <ZenActivityTable
        fetchActivities={fetchActivities}
        onError={(e) => {
          ErrorService.notify('Unable to fetch activity check lists', e, {
            transaction: {
              transactionId: transactionDetail?.id,
            },
          });
        }}
      />
    </ZenRoute>
  );
};

export default ZenTransactionActivity;
