import React from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import config from '../../../config';
import { AgentResponse } from '../../../openapi/yenta';
import { parseUrl } from '../../../utils/UrlUtils';
import { Divider } from './GeminiIdentityInfoHeaderDetails';

interface GeminiAgentWebsiteHeaderProps {
  detail: AgentResponse | undefined;
}

const GeminiAgentWebsiteHeader: React.FC<GeminiAgentWebsiteHeaderProps> = ({
  detail,
}) => {
  const history = useHistory();

  if (detail?.hasAgentWebsite) {
    return (
      <a
        href={`${parseUrl(config.joinRealEmailDomain)}/${detail?.slug}`}
        target='_blank'
        rel='noopener noreferrer'
        className='flex items-center font-zen-body-2 text-sm leading-4 font-medium text-avatar-blue-200 space-x-1'
      >
        <Divider />
        <FiExternalLink className='text-sm ml-1.5' />
        <span>Agent Website</span>
      </a>
    );
  }

  return (
    <button
      onClick={() =>
        history.push(`/people/${detail?.id}/agent-website-onboarding`)
      }
      className='flex items-center font-zen-body-2 text-sm leading-4 font-medium text-avatar-blue-200 space-x-1'
    >
      <Divider />
      <FiExternalLink className='text-sm ml-1.5' />
      <span>Create Website</span>
    </button>
  );
};

export default GeminiAgentWebsiteHeader;
