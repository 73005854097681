import { cn } from '../utils/classUtils';

export interface IconTextProps {
  icon: React.ReactElement;
  text: React.ReactElement | string;
  lineClamp?: number;
  textStyles?: string;
}

const IconText: React.FC<IconTextProps> = ({
  icon,
  text,
  lineClamp,
  textStyles,
}) => {
  return (
    <div className='flex flex-row items-start flex-nowrap'>
      {icon}
      <p
        className={cn(
          'pl-1 font-primary-light',
          lineClamp ? `line-clamp-${lineClamp}` : 'line-clamp-none',
          textStyles,
        )}
      >
        {text}
      </p>
    </div>
  );
};

export default IconText;
