import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { cn } from '../../utils/classUtils';

type ZenColorType = 'primary' | 'danger' | 'warning' | 'success' | 'default';

export interface ZenDropdownItem {
  icon?: React.ReactElement;
  text: string;
  colorType?: ZenColorType;
  onClick(): void;
  divider?: boolean;
  disabled?: boolean;
}

interface ZenDropdownProps {
  title?: string;
  icon?: React.ReactElement;
  label?: string;
  items: ZenDropdownItem[];
  noBorder?: boolean;
  openIcon?: React.ReactElement;
  outlined?: boolean;
  containerClassName?: string;
  containerStyle?: Partial<CSSStyleDeclaration>;
}

const ZenDropdown: React.FC<ZenDropdownProps> = ({
  title,
  label,
  icon,
  items,
  noBorder,
  openIcon,
  outlined = false,
  containerClassName,
  containerStyle,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const colorTypeClassNameMap: { [type in ZenColorType]: string } = {
    primary: 'text-primary-blue hover:bg-primary-blue hover:bg-opacity-10',
    danger: 'text-zen-danger hover:bg-zen-danger hover:bg-opacity-10',
    warning: 'text-zen-warning hover:bg-zen-warning hover:bg-opacity-10',
    success: 'text-green-600 hover:bg-green-600 hover:bg-opacity-10',
    default: 'text-dark hover:bg-trueGray-200',
  };

  return (
    <Popover
      isOpen={isOpen}
      positions={['bottom']}
      align='end'
      containerClassName={containerClassName}
      containerStyle={containerStyle}
      onClickOutside={() => setIsOpen(false)}
      content={
        <div
          className={cn(
            'border shadow-pop-over bg-white rounded-md max-h-60 overflow-y-auto',
            {
              'mt-1': outlined,
            },
          )}
        >
          {title && (
            <p className='text-zen-dark-6 text-md pt-3 pl-4'>{title}</p>
          )}
          {items.map((item) => (
            <div
              key={item.text}
              className={cn(
                'flex flex-row items-center py-2 px-4 font-zen-body text-base font-normal',
                colorTypeClassNameMap[item.colorType || 'default'],
                { 'border-t': item.divider },
                item.disabled
                  ? 'text-gray-400 cursor-default'
                  : 'cursor-pointer',
              )}
              onClick={() => {
                if (!item.disabled) {
                  item.onClick();
                  setIsOpen(false);
                }
              }}
            >
              <span className='w-8 h-auto pr-2'>{item.icon}</span>
              <span className='text-sm max-w-[180px] overflow-hidden whitespace-nowrap text-ellipsis'>
                {item.text}
              </span>
            </div>
          ))}
        </div>
      }
    >
      <div
        className={cn(
          'flex items-center cursor-pointer p-0.5',
          { 'border border-gray-200 rounded': !noBorder && !icon },
          {
            'rounded-full bg-gray-500 bg-opacity-20':
              !noBorder && icon && isOpen,
          },
          {
            'flex-row-reverse bg-white border border-gray-200 ring-gray-200 ring-1 border-1 py-1 px-2': outlined,
          },
        )}
        onClick={() => setIsOpen(true)}
      >
        {openIcon
          ? !isOpen
            ? icon && <span>{icon}</span>
            : openIcon && <span>{openIcon}</span>
          : icon && <span>{icon}</span>}
        {label && <span className='whitespace-nowrap'>{label}</span>}
      </div>
    </Popover>
  );
};

export default ZenDropdown;
