import { ISelectOption } from '../../types';
import { cn } from '../../utils/classUtils';

export interface ZenCustomColorPickerInputSelectOption extends ISelectOption {
  color: string;
}

interface ZenCustomColorPickerInputProps {
  item: ZenCustomColorPickerInputSelectOption;
  isActive: boolean;
  onChange(value: string): void;
  disabled?: boolean;
}

const ZenCustomColorPickerInput: React.FC<ZenCustomColorPickerInputProps> = ({
  isActive,
  item,
  onChange,
  disabled,
}) => {
  return (
    <div className='flex flex-col items-center space-y-3'>
      <button
        type='button'
        onClick={() => onChange(item.value)}
        className={cn(
          'w-16 h-16 p-0.5 mx-1.5 rounded-full outline-none focus:outline-none',
          isActive ? 'border-primary-blue' : 'border-white',
          isActive ? 'border-4' : 'border-0',
        )}
        aria-label={item.label}
        disabled={disabled}
      >
        <div
          className='w-full h-full rounded-full'
          style={{ backgroundColor: item.color }}
        />
      </button>
      <h6
        className={cn(
          'font-zen-title text-sm',
          isActive ? 'text-zen-dark-9' : 'text-zen-dark-6',
          isActive ? 'font-semibold' : 'font-normal',
        )}
      >
        {item.label}
      </h6>
    </div>
  );
};

export default ZenCustomColorPickerInput;
