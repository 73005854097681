import {
  IconDefinition,
  faHouse,
  faHouseBuilding,
  faHandshake,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  TransactionBuilderResponseDealTypeEnum,
  TransactionLiteResponseTransactionTypeEnum,
} from '../../openapi/arrakis';
import { EnumMap } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';

export interface TransactionTypeProps {
  type: string;
}

const TransactionTypeLabel: React.FC<TransactionTypeProps> = ({ type }) => {
  const transactionTypeToIcon: EnumMap<
    | TransactionLiteResponseTransactionTypeEnum
    | TransactionBuilderResponseDealTypeEnum,
    IconDefinition
  > = {
    SALE: faHouse,
    LEASE: faHouseBuilding,
    OTHER: faHouse,
    COMMERCIAL_LEASE: faHouse,
    COMPENSATING: faHouse,
    PLOT: faHouse,
    REFERRAL: faHandshake,
    INTERNAL_REFERRAL: faHandshake,
  };

  return (
    <div className='flex flex-row items-start'>
      <FontAwesomeIcon
        icon={
          transactionTypeToIcon[
            (type.toUpperCase() as any) as TransactionLiteResponseTransactionTypeEnum
          ]
        }
        className='text-white mt-px'
        size='sm'
      />
      <span className='px-2 font-primary-regular text-sm text-white rounded-sm whitespace-pre'>
        {capitalizeEnum(type)}
      </span>
    </div>
  );
};

export default TransactionTypeLabel;
