import React from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { AuthControllerApi, LoginRequest } from '../../openapi/keymaker';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import { showSuccessToast } from '../../slices/ToastNotificationSlice';
import { getAuthCookie } from '../../utils/AuthUtils';
import { getKeymakerConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { PASSWORD_VALIDATIONS } from '../../utils/Validations';
import ZenSidebarModalActionFooter from '../SidebarModal/ZenSideBarModalActionFooter';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import { RootState } from '../../types';
import { loginUserOrThrow } from '../../slices/AuthSlice';

interface ZenChangePasswordFormProps {
  isOpen: boolean;
  onClose(): void;
}

interface EditPasswordFormData {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

const ZenChangePasswordForm: React.FC<ZenChangePasswordFormProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useForm<EditPasswordFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    agentDetail: {
      detailResponse: { data: detail },
    },
    auth: { identityByKeymakerIdResponse },
  } = useSelector((state: RootState) => state);

  const agentLoginDetails = identityByKeymakerIdResponse.data![
    detail?.keyMakerId!
  ];
  const handlePasswordChange = async (values: EditPasswordFormData) => {
    try {
      const api = new AuthControllerApi(getKeymakerConfiguration());

      await api.updatePassword({
        currentPassword: values.currentPassword,
        jwtToken: getAuthCookie()!,
        newPassword: values.password,
      });

      const loginRequest: LoginRequest = {
        password: values.password,
        usernameOrEmail: agentLoginDetails?.email!,
      };
      await dispatch(loginUserOrThrow(loginRequest));

      dispatch(showSuccessToast('Password updated successfully.'));

      onClose();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to change password', e, {
        values: { ...values },
      });
    }
  };

  return (
    <ZenSidebarModal title='Change Password' isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(handlePasswordChange)}>
        <div className='p-4'>
          <ZenControlledTextInput<EditPasswordFormData, 'currentPassword'>
            control={control}
            label='Current Password'
            type='password'
            name='currentPassword'
            placeholder='Your existing password'
            rules={{
              required: 'Password is required',
            }}
            isRequired
          />
          <div className='mt-4'>
            <ZenControlledTextInput<EditPasswordFormData, 'password'>
              control={control}
              label='New Password'
              type='password'
              name='password'
              placeholder='New Password'
              rules={{
                ...PASSWORD_VALIDATIONS,
              }}
              isRequired
            />
          </div>
          <div className='mt-4'>
            <ZenControlledTextInput<EditPasswordFormData, 'confirmPassword'>
              control={control}
              label='Confirm New Password'
              type='password'
              name='confirmPassword'
              placeholder='Confirm New Password'
              rules={{
                required: 'Please re-enter your password',
                validate: (value) =>
                  getValues().password !== value
                    ? 'Passwords do not match'
                    : undefined,
              }}
              isRequired
            />
          </div>
        </div>
        <ZenSidebarModalActionFooter
          onClose={onClose}
          isSubmitting={isSubmitting}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenChangePasswordForm;
