import { GetCommsPreferencesResponse } from '../openapi/plutus';
import { AgentInfo, AgentPublicInfo } from '../openapi/yenta';
import { compactNumber } from './MathUtils';

export const getAgentName = (
  agent: AgentInfo | AgentPublicInfo,
  truncateLength: number = 15,
): string => {
  if (!agent?.firstName && !agent?.lastName) {
    return 'N/A';
  } else {
    let agentName = `${agent.firstName} ${agent.lastName}`.trim();
    if (agentName.length > truncateLength) {
      agentName = agentName.substring(0, truncateLength) + '...';
    }
    return agentName;
  }
};

export const compareWealthPlanCommsSettings = (
  commsSettings: GetCommsPreferencesResponse,
  wealthPlanNotifications: GetCommsPreferencesResponse,
) => {
  if (commsSettings) {
    if (
      commsSettings.enableEmails !== wealthPlanNotifications.enableEmails ||
      commsSettings.enableNotifications !==
        wealthPlanNotifications.enableNotifications
    ) {
      return true;
    }
  }
  return false;
};

export const getCompactCappedAmount = (amount: number) => {
  const cappedAmount = amount || 0;
  const precision = cappedAmount % 100 === 0 ? 1 : 2;
  const finalPrecision = cappedAmount % 1000 === 0 ? 0 : precision;
  const compactAmount = compactNumber(
    cappedAmount,
    finalPrecision,
  )?.toUpperCase();

  return `$${compactAmount}`;
};
