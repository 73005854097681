import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  EscrowRequest,
  EscrowRequestEscrowTypeEnum,
  EscrowResponse,
  EscrowResponseEscrowTypeEnum,
  MoneyValue,
  MoneyValueCurrencyEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import {
  createEscrow,
  updateEscrowDetails,
} from '../../../slices/TransactionSlice';
import { DepositHeldEnum, ISelectOption } from '../../../types';
import { getISelectOptionDefaultValue } from '../../../utils/FormUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { MONEY_NON_ZERO_VALUE_VALIDATIONS } from '../../../utils/Validations';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledFormattedMoneyInput from '../../Zen/Input/ZenControlledFormattedMoneyInput';
import ZenControlledSelectInput from '../../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextAreaInput from '../../Zen/Input/ZenControlledTextAreaInput';
import ZenSidebarModalActionFooter from '../../Zen/Modal/ZenSidebarModalActionFooter';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';

interface FormData {
  amount: MoneyValue;
  description: string;
  heldByReal?: ISelectOption<DepositHeldEnum>;
  requiredByDate: string;
  info: string;
  note?: string;
  escrowType: EscrowResponseEscrowTypeEnum | EscrowRequestEscrowTypeEnum;
}

interface DepositInformationFormProps {
  onClose(): void;
  depositInformation?: EscrowResponse;
  transaction: TransactionResponse;
}

const ZenDepositInformationForm: React.FC<DepositInformationFormProps> = ({
  transaction,
  depositInformation,
  onClose,
}) => {
  const dispatch = useDispatch();

  const isDualRepresentationTransaction =
    transaction.transactionOwner?.represents === 'Dual Representation';

  const heldByRealDefaultValue = isDualRepresentationTransaction
    ? getISelectOptionDefaultValue<DepositHeldEnum>(DepositHeldEnum.REAL)
    : undefined;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      amount: depositInformation?.amount ?? {
        currency: (transaction.currency as unknown) as MoneyValueCurrencyEnum,
      },
      description: depositInformation?.description,
      note: depositInformation?.note,
      info: depositInformation?.info,
      requiredByDate: depositInformation?.requiredByDate,
      escrowType: EscrowResponseEscrowTypeEnum.TrustDeposit,
      heldByReal: depositInformation
        ? getISelectOptionDefaultValue<DepositHeldEnum>(
            depositInformation?.heldByReal
              ? DepositHeldEnum.REAL
              : DepositHeldEnum.OTHER_BROKERAGE,
          )
        : heldByRealDefaultValue,
    },
  });

  const onSubmit = async (data: FormData) => {
    const final: EscrowRequest = {
      amount: data.amount || depositInformation?.amount,
      note: data.note || depositInformation?.note,
      requiredByDate: data.requiredByDate || depositInformation?.requiredByDate,
      description: data.description || depositInformation?.description,
      info: data.info || depositInformation?.info,
      escrowType: EscrowRequestEscrowTypeEnum.TrustDeposit,
      heldByReal: data.heldByReal?.value === DepositHeldEnum.REAL,
    };

    if (depositInformation) {
      await dispatch(updateEscrowDetails(final, depositInformation.id!));
    } else {
      await dispatch(createEscrow(transaction.id!, final));
    }
    onClose();
  };

  return (
    <ZenSidebarModal
      title={depositInformation ? 'Edit Trust Deposit' : 'Add a Trust Deposit'}
      subtitle={transaction.address?.oneLine}
      isOpen
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='zen-deposit-form'
      >
        <div className='p-4 pb-20'>
          <div className='w-1/2'>
            <ZenControlledFormattedMoneyInput<FormData, 'amount'>
              control={control}
              label='Amount'
              shouldUnregister={!depositInformation}
              name='amount'
              placeholder='E.g. 120,000.00'
              rules={MONEY_NON_ZERO_VALUE_VALIDATIONS}
              currencyReadOnly
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextAreaInput<FormData, 'description'>
              name='description'
              shouldUnregister={!depositInformation}
              control={control}
              label='Description'
              placeholder='E.g. Initial Payment'
              rules={{
                required: 'Please provide some description about the payment',
              }}
              rows={2}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, 'heldByReal'>
              name='heldByReal'
              control={control}
              shouldUnregister={!depositInformation}
              label='Deposit Held By'
              placeholder='Deposit Held By'
              disabled={isDualRepresentationTransaction}
              options={[
                {
                  label: 'Select Deposit Held By',
                  value: '',
                  disabled: true,
                },
                ...values(DepositHeldEnum).map((held) => ({
                  value: held,
                  label: capitalizeEnum(held),
                })),
              ]}
              rules={{
                required: 'Please select deposit held by',
              }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledDatePickerInput<FormData, 'requiredByDate'>
              name='requiredByDate'
              shouldUnregister={!depositInformation}
              control={control}
              label='Required By'
              placeholder='MM/DD/YYYY'
              icon={
                <FontAwesomeIcon
                  icon={faCalendar}
                  className='text-gray-600'
                  size='xl'
                />
              }
              rules={{
                required: 'Please select a date',
              }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextAreaInput<FormData, 'note'>
              name='note'
              shouldUnregister={!depositInformation}
              control={control}
              label='Note (optional)'
              placeholder='Note'
              rows={2}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextAreaInput<FormData, 'info'>
              name='info'
              shouldUnregister={!depositInformation}
              control={control}
              label='Additional Info (optional)'
              placeholder='Additional info'
              rows={2}
            />
          </div>
        </div>

        <ZenSidebarModalActionFooter
          submitButtonText={depositInformation ? 'Save' : 'Add'}
          cancelButtonText='Cancel'
          isSubmitting={isSubmitting}
          onClose={onClose}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenDepositInformationForm;
