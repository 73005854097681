import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AnalyticsService from '../../../services/AnalyticsService';
import { AnalyticsEventEnum, RootState } from '../../../types';
import ResourceContainer from '../../ResourceContainer';
import ZenRoute from '../ZenRoute';
import ZenAgentJourneyStep from './ZenAgentJourneyStep';

interface ZenAgentJourneyProps {}

const ZenAgentJourney: React.FC<ZenAgentJourneyProps> = () => {
  const {
    agentDetail: { detailResponse },
    checklist: { journey, checklistLoading, checklistErrorCode },
  } = useSelector((state: RootState) => state);

  const steps = journey?.items || [];

  useEffect(() => {
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.ONBOARDING_CHECKLIST_TAB_OPENED,
    );
  }, []);

  return (
    <ZenRoute title={`Onboarding Checklist - ${detailResponse.data?.fullName}`}>
      <div className='grid grid-cols-1 md:grid-cols-2 my-7 mx-0 md:mx-4 gap-4 scrollbar overflow-x-auto'>
        <ResourceContainer
          loading={checklistLoading}
          isEmpty={isEmpty(journey?.items)}
          resourceName='Onboarding Checklist'
          errorCode={checklistErrorCode}
        >
          <div>
            <div className='py-4 my-2.5'>
              {steps?.map((step, index) => (
                <ZenAgentJourneyStep
                  key={step?.name}
                  isLastStep={index === steps?.length - 1}
                  step={step}
                />
              ))}
            </div>
          </div>
        </ResourceContainer>
      </div>
    </ZenRoute>
  );
};

export default ZenAgentJourney;
