import { useCallback, useMemo } from 'react';
import { HiOutlineCloudArrowDown } from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { ResourceTableVariant } from '../../../containers/ResourceTable';
import {
  ConveyanceControllerApi,
  ConveyanceResponse,
} from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { RootState } from '../../../types';
import { isSmScreen } from '../../../utils/BrowserUtils';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import AdminOnly from '../../auth/AdminOnly';
import EmptyConveyance from '../../ConveyanceDocuments/EmptyConveyanceTable';
import ZenIconCircleWithTooltipCell from '../Transaction/ZenIconCircleWithTooltipCell';
import ZenResourceTable from '../Table/ZenResourceTable';

interface ZenDocumentSetTableProps {
  processedDate: number | undefined;
  conveyanceDocuments: Array<ConveyanceResponse>;
  isDownloadable: boolean;
  conveyanceId: number;
}

export const columns: Array<Column<ConveyanceResponse>> = [
  {
    Header: 'Documents',
    accessor: 'name',
    Cell: ({ value }) => (
      <div className='font-zen-body text-sm font-normal text-zen-dark-9'>
        {capitalizeEnum(value!)}
      </div>
    ),
    disableFilters: true,
  },
];

const ZenDocumentSetTable: React.FC<ZenDocumentSetTableProps> = ({
  conveyanceDocuments,
  isDownloadable,
  conveyanceId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
    },
  } = useSelector((state: RootState) => state);

  const handleDownload = useCallback(
    async (docId: string | string[]) => {
      try {
        const { data } = await new ConveyanceControllerApi(
          getArrakisConfiguration(),
        ).getPreSignedUrl(transactionDetail?.id!);

        const foundDocumentDownloadLinkMap = data.filter((link) =>
          typeof docId === 'string'
            ? link.id === docId
            : docId.includes(link.id!),
        );

        if (!foundDocumentDownloadLinkMap?.length) {
          dispatch(
            showErrorToast('Cannot find conveyance document link to download'),
          );
        } else {
          foundDocumentDownloadLinkMap.forEach((link) => {
            window.open(link.url, '_blank');
          });
        }
      } catch (e) {
        ErrorService.notify('Unable to download conveyance document', e, {
          data: { docId },
        });
      }
    },
    [dispatch, transactionDetail?.id],
  );

  const columnsWithAction: Array<Column<ConveyanceResponse>> = useMemo(() => {
    let updatedColumns = [...columns];

    if (isDownloadable) {
      updatedColumns.push({
        Header: 'Actions',
        accessor: 'id',
        id: 'action',
        Cell: ({ value }) => {
          return (
            <ZenIconCircleWithTooltipCell
              tooltipText='Download'
              icon={
                <HiOutlineCloudArrowDown
                  data-testid='download'
                  className='text-zen-dark-8 text-lg'
                />
              }
              onClick={async () => await handleDownload(value!)}
            />
          );
        },
        disableSortBy: true,
        disableFilters: true,
      });
    }

    return updatedColumns;
  }, [handleDownload, isDownloadable]);

  const tableInstance = useTable(
    {
      columns: columnsWithAction,
      data: conveyanceDocuments,
      initialState: {
        pageSize: 1000,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  return (
    <div className='px-2 pt-5 min-w-fit scrollbar overflow-visible bg-regent-100 border-b'>
      <div className='px-2 w-full max-w-xl'>
        <div className='ml-1 md:ml-5 mb-3 md:-mb-1 flex items-center justify-between'>
          <p className='font-zen-title font-medium text-base text-zen-dark-9'>
            Final Documents
          </p>

          {isDownloadable && (
            <button
              className='flex flex-row items-center space-x-1 font-zen-body font-semibold text-sm text-primary-blue'
              onClick={async () =>
                await handleDownload(conveyanceDocuments.map((doc) => doc.id!))
              }
            >
              <HiOutlineCloudArrowDown className='text-lg' />
              <span>Download All</span>
            </button>
          )}
        </div>

        <ZenResourceTable<ConveyanceResponse>
          {...tableInstance}
          resourceName='final documents'
          fetchData={() => ({
            data: conveyanceDocuments,
            total: conveyanceDocuments.length,
          })}
          totalCount={conveyanceDocuments.length}
          variant={
            isSmScreen() ? ResourceTableVariant.CARD : ResourceTableVariant.ROW
          }
          hideRowBorder
          headerVariant='light2'
          customEmptyComponent={
            <EmptyConveyance>
              <div className='font-zen-body text-sm text-zen-dark-9'>
                No final documents yet.
              </div>
              <AdminOnly>
                <div className='flex space-x-1 mt-2 font-zen-body text-sm'>
                  <button
                    className='text-primary-blue'
                    onClick={() =>
                      history.push(
                        `/transactions/${transactionDetail?.id}/conveyance-documents/${conveyanceId}/preview`,
                      )
                    }
                  >
                    Add Notes & Finalize
                  </button>
                  <p>to generate final PDFs</p>
                </div>
              </AdminOnly>
            </EmptyConveyance>
          }
          hidePageSize
          hidePagination
        />
      </div>
    </div>
  );
};

export default ZenDocumentSetTable;
