import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce, isArray, isEmpty, values } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { OptionTypeBase, ValueType } from 'react-select';
import { Popover } from 'react-tiny-popover';
import { ReactComponent as FilterIcon } from '../../../assets/icons/zen/zen-filter.svg';
import {
  ItemResponseRequiredForEnum,
  ItemResponseStatusEnum,
} from '../../../openapi/sherlock';
import AnalyticsService from '../../../services/AnalyticsService';
import {
  AnalyticsEventEnum,
  ISelectOption,
  RootState,
  YesNoType,
} from '../../../types';
import {
  checkListStatusToDisplayName,
  getChecklistRequiredForDisplayName,
  getChecklistStatusExtraEnum,
  getOriginalChecklistStatusEnum,
  getValidChecklistStatusesForRole,
} from '../../../utils/ChecklistUtils';
import DefaultEmpty from '../../DefaultEmpty';
import IconButton from '../../IconButton';
import ZenControlledCheckboxInput from '../Input/ZenControlledCheckboxInput';
import ZenControlledMultiSelectCustomInput from '../Input/ZenControlledMultiSelectCustomInput';
import ZenControlledMultiSelectInput from '../Input/ZenControlledMultiSelectInput';
import ZenChecklistLabel from '../Transaction/ZenChecklistLabel';

interface ZenChecklistSearchFilterInputProps {
  onSearch?(search: string): void;
  onFilter?(filter: FormData): void;
  searchTitles?: string[];
}

export enum TypeEnum {
  Required = 'Required',
  Optional = 'Optional',
}

export interface FormData {
  requiredFor: ISelectOption[];
  status: ISelectOption[];
  urgent: YesNoType[];
  required: YesNoType[];
}

const ZenChecklistSearchFilterInput: React.FC<ZenChecklistSearchFilterInputProps> = ({
  onFilter,
  onSearch,
  searchTitles,
}) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const { control, handleSubmit, reset } = useForm<FormData>();
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const filteredSearchTitles = searchTitles?.filter((title) =>
    title?.toLowerCase().includes(search.toLowerCase()),
  );

  const onFilterSubmit = (formData: FormData) => {
    onFilter!(formData);
    setShowFilter(false);
  };

  const handleSearch = (value: string) => {
    setSearch(value);

    if (value && !isAutoCompleteOpen) {
      setIsAutoCompleteOpen(true);
    } else if (!value) {
      onSearch!(value);
      setIsAutoCompleteOpen(false);
    }
  };

  const closeFilterModal = () => {
    for (const property in control._formValues) {
      if (
        (!isArray(control._formValues[property]) &&
          control._formValues[property] !== undefined) ||
        (isArray(control._formValues[property]) &&
          !isEmpty(control._formValues[property]))
      ) {
        return;
      }
    }
    setShowFilter(false);
  };

  const customMultiValue = (props: any) => {
    return (
      <div className='p-1'>
        <ZenChecklistLabel
          status={getOriginalChecklistStatusEnum(props.data.value)}
          isRequired={props.data.required}
          showCloseIcon
          onClick={() => props.removeProps.onClick(props.value)}
        />
      </div>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeRequired = useCallback(
    debounce((values: string[]) => {
      if (values.length) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.CHECKLIST_CHECKED_REQUIRED,
        );
      }
    }, 400),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeUrgent = useCallback(
    debounce((values: string[]) => {
      if (values.length) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.CHECKLIST_CHECKED_URGENT,
        );
      }
    }, 400),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeStatus = useCallback(
    debounce((values: ValueType<OptionTypeBase, true>) => {
      if (values.length) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.CHECKLIST_SELECTED_STATUS,
        );
      }
    }, 400),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeRequiredFor = useCallback(
    debounce((values: ValueType<OptionTypeBase, true>) => {
      if (values.length) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.CHECKLIST_SELECTED_REQUIRED_FOR,
        );
      }
    }, 400),
    [],
  );

  return (
    <Popover
      isOpen={showFilter}
      positions={['top', 'right', 'left']}
      align='start'
      padding={5}
      onClickOutside={closeFilterModal}
      content={
        <div className='w-[350px] z-10' aria-label='filter-popper'>
          <form
            onSubmit={handleSubmit(onFilterSubmit)}
            className='w-full bg-white border border-zen-dark-5 divide-y divide-[#ECECEC] rounded-lg shadow-side-popup'
          >
            <div className='flex items-center justify-between w-full p-4'>
              <IconButton
                label='Clear'
                variant='outline'
                buttonStyle='!bg-white border border-mineshaft px-4 py-3 rounded-lg'
                textStyle='text-sm text-mineshaft'
                onClick={() => {
                  let resetInputs: FormData = {
                    requiredFor: [],
                    status: [],
                    urgent: [],
                    required: [],
                  };

                  reset(resetInputs);
                  onFilterSubmit(resetInputs);
                }}
              />

              <p className='text-sm text-mineshaft font-semibold font-zen-body'>
                Filters
              </p>

              <IconButton
                label='Apply'
                buttonType='submit'
                buttonStyle='bg-primary-blue rounded-lg px-4 py-3 ring-0 border-0'
                textStyle='text-sm text-white'
                onClick={() => {
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.CHECKLIST_APPLIED_FILTER,
                  );
                }}
              />
            </div>
            <div className='p-4'>
              <ZenControlledCheckboxInput<FormData, 'required'>
                shouldUnregister={false}
                control={control}
                name='required'
                options={[
                  {
                    label: 'Required',
                    value: YesNoType.YES,
                  },
                ]}
                reverse
                defaultValue={[]}
                size='sm'
                optionsTextStyle='text-zen-dark-25'
                onChangeSpy={handleChangeRequired}
              />
            </div>
            <div className='flex p-4'>
              <ZenControlledCheckboxInput<FormData, 'urgent'>
                shouldUnregister={false}
                control={control}
                name='urgent'
                options={[
                  {
                    label: 'Urgent',
                    value: YesNoType.YES,
                  },
                ]}
                reverse
                defaultValue={[]}
                size='sm'
                optionsTextStyle='text-zen-dark-25'
                onChangeSpy={handleChangeUrgent}
              />
            </div>

            <div className='p-4 space-y-1.5'>
              <ZenControlledMultiSelectCustomInput<FormData, 'status'>
                control={control}
                name='status'
                label='Status'
                placeholder='Select'
                options={getValidChecklistStatusesForRole(false)
                  .map((status) => ({
                    label: checkListStatusToDisplayName(status, true),
                    value: getChecklistStatusExtraEnum(status, true),
                    required: true,
                  }))
                  .concat({
                    label: checkListStatusToDisplayName(
                      ItemResponseStatusEnum.NotStarted,
                      false,
                    ),
                    value: getChecklistStatusExtraEnum(
                      ItemResponseStatusEnum.NotStarted,
                      false,
                    ),
                    required: false,
                  })}
                endAdornment={
                  <FontAwesomeIcon
                    icon={regular('angle-down')}
                    className='text-zen-dark-9 px-6'
                  />
                }
                customMultiValue={customMultiValue}
                onChangeSpy={handleChangeStatus}
              />
            </div>

            <div className='p-4 space-y-1.5'>
              <ZenControlledMultiSelectInput<FormData, 'requiredFor'>
                control={control}
                name='requiredFor'
                label='Required for'
                placeholder='Select'
                options={values(ItemResponseRequiredForEnum).map((status) => ({
                  label: getChecklistRequiredForDisplayName(
                    status,
                    true,
                    userDetail?.accountCountry!,
                  )!,
                  value: status,
                }))}
                endAdornment={
                  <FontAwesomeIcon
                    icon={regular('angle-down')}
                    className='text-zen-dark-9 px-6'
                  />
                }
                shouldUnregister={false}
                onChangeSpy={handleChangeRequiredFor}
              />
            </div>
          </form>
        </div>
      }
    >
      <div className='flex flex-row relative items-center border border-zen-dark-5 md:max-w-[22rem] w-[350px] rounded-md'>
        <div className='flex flex-row items-center flex-grow px-1.5 py-2 border-r border-zen-dark-5 w-full'>
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            size='sm'
            className='text-primary-blue'
          />
          <input
            type='text'
            value={search}
            onChange={(event) => {
              const { value } = event.target;
              handleSearch(value);
            }}
            placeholder='Search'
            className='font-zen-body appearance-none p-1.5 capitalize w-full h-6 border-none focus:outline-none placeholder-zen-dark-8 focus:ring-0'
            onFocus={() => {
              if (search) {
                setIsAutoCompleteOpen(true);
              }
            }}
            data-testId='checklist-search-filter-input'
          />

          {!!search && (
            <FontAwesomeIcon
              icon={faXmark}
              className='text-zen-danger cursor-pointer'
              size='sm'
              onClick={() => {
                handleSearch('');
              }}
              data-testid='clear-search-icon'
            />
          )}
        </div>

        <div
          className='flex flex-row items-center space-x-1 px-2 py-2 cursor-pointer'
          onClick={() => setShowFilter(!showFilter)}
        >
          <div className='px-0.5 bg-white' aria-label='filter-icon'>
            <FilterIcon className='fill-zen-dark-7' />
          </div>
        </div>

        {isAutoCompleteOpen && (
          <div className='absolute divide-y left-0 z-10 w-full bg-white border border-zen-dark-5 rounded-md top-11'>
            {!!filteredSearchTitles?.length ? (
              filteredSearchTitles?.map((title) => (
                <button
                  onClick={() => {
                    onSearch!(title);
                    setIsAutoCompleteOpen(false);
                    setSearch(title);
                    AnalyticsService.instance().logEvent(
                      AnalyticsEventEnum.CHECKLIST_SEARCHED_ITEMS,
                    );
                  }}
                  className='font-zen-body hover:bg-primary-blue hover:bg-opacity-10 w-full py-1 pl-8 pr-3 text-left capitalize truncate appearance-none focus:outline-none focus:border-0'
                  key={title}
                  type='button'
                >
                  {title}
                </button>
              ))
            ) : (
              <DefaultEmpty
                message={`Sorry we couldn't find any matches for ${search}`}
              />
            )}
          </div>
        )}
      </div>
    </Popover>
  );
};

export default ZenChecklistSearchFilterInput;
