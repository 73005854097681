import { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps } from '../../../types';
import { cn } from '../../../utils/classUtils';
import FormErrorMessage from './ZenFormErrorMessage';

type DisplayVariant = 'block' | 'inline';

interface ZenControlledCurrencyInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  subLabel?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  isRequired?: boolean;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  displayVariant?: DisplayVariant;
  onChangeSpy?: (value: string | number | undefined) => void;
  onBlurSpy?: () => void;
}

const ZenControlledCurrencyInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  tooltipIndex,
  shouldUnregister = true,
  startAdornment,
  endAdornment,
  isRequired = false,
  displayVariant = 'block',
  onKeyPress,
  onChangeSpy,
  onBlurSpy,
  ...rest
}: ZenControlledCurrencyInputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);

  const DisplayVariantEnumMap: Record<DisplayVariant, string> = {
    block: 'space-y-1',
    inline: 'flex items-start justify-between',
  };

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error, invalid },
      }) => (
        <div className={cn('w-full', DisplayVariantEnumMap[displayVariant])}>
          {label && (
            <label className='inline-block' htmlFor={name}>
              <span
                className={cn(
                  'text-base font-zen-body font-semibold',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                )}
              >
                {label}
              </span>
              {!!subLabel && (
                <span className='font-zen-body text-sm text-zen-dark-12 ml-1'>
                  {subLabel}
                </span>
              )}
              {isRequired && <span className='text-zen-danger'>*</span>}
            </label>
          )}
          <div>
            <div
              className={cn(
                'flex items-stretch border rounded-lg overflow-hidden',
                value && 'text-zen-dark-9',
                invalid && '!border-zen-danger',
                focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
                {
                  'border-none': noBorder,
                },
              )}
            >
              {startAdornment && <div>{startAdornment}</div>}
              <CurrencyInput
                id={name}
                value={value}
                onFocus={() => setFocus(true)}
                onBlur={() => {
                  setFocus(false);
                  onBlur();
                  if (onBlurSpy) {
                    onBlurSpy();
                  }
                }}
                onKeyPress={onKeyPress}
                onValueChange={(value) => {
                  onChange(value ?? null);
                  onChangeSpy?.(value);
                }}
                name={name}
                readOnly={readOnly}
                placeholder={placeholder}
                className={cn(
                  'appearance-none p-2 w-full border-none focus:outline-none focus:ring-0 font-zen-body font-normal',
                  {
                    'bg-gray-50': readOnly,
                  },
                )}
                data-tooltip-index={tooltipIndex}
                allowDecimals
              />
              {endAdornment && <div>{endAdornment}</div>}
            </div>
            {error && !hideErrorMessage && (
              <FormErrorMessage message={error.message} />
            )}
          </div>
        </div>
      )}
    />
  );
};

export default ZenControlledCurrencyInput;
