import { useForm } from 'react-hook-form-v7';
import {
  PatchTeamInvitationRequestStatusEnum,
  TeamInvitationSimplifiedDto,
} from '../../../openapi/yenta';
import { useRevokeInvitation } from '../../../query/team/useInvitation';
import { isSmScreen } from '../../../utils/BrowserUtils';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import { InvitationEnum } from '../../../routes/JoinByInvitationRoute';
import AnalyticsService from '../../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../../types';

interface ZenRevokeInvitationProps {
  memberToRevoke: TeamInvitationSimplifiedDto | null;
  onClose(): void;
  onConfirm(): void;
}

interface DeleteTeamFormData {
  confirm: string;
}

const ZenRevokeInvitation: React.FC<ZenRevokeInvitationProps> = ({
  memberToRevoke,
  onClose,
  onConfirm,
}) => {
  const {
    mutate: revokeInvitation,
    isLoading: isRevokingInvitation,
  } = useRevokeInvitation(memberToRevoke?.invitationId!, InvitationEnum.EMAIL);
  const { control, watch } = useForm<DeleteTeamFormData>();
  const CONFIRMATION_STRING_REQUIRED = 'CONFIRM';
  const watchConfirm = watch('confirm');
  const isDisabled = watchConfirm?.trim() !== CONFIRMATION_STRING_REQUIRED;

  return (
    <ZenConfirmationModal
      isOpen={!!memberToRevoke}
      variant='danger'
      title={`Are you sure you want to revoke invitation for ${memberToRevoke?.firstName!} ${memberToRevoke?.lastName!}?`}
      subtitle={
        <div className='flex-col'>
          <div className='text-zen-dark-9 font-zen-body'>
            You can always invite them back
          </div>
          <div className='mt-2 flex justify-center'>
            <div className='w-1/2'>
              <ZenControlledTextInput
                control={control}
                name='confirm'
                type='text'
                placeholder={`Please type ${CONFIRMATION_STRING_REQUIRED}`}
              />
            </div>
          </div>
        </div>
      }
      onClose={({ isX }) => {
        if (isX) {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.SELF_SERVICE_TEAM_REVOKE_POPUP_CLICK_X,
          );
        } else {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.SELF_SERVICE_TEAM_REVOKE_POPUP_CLICK_CANCEL,
          );
        }

        onClose();
      }}
      onConfirm={() =>
        revokeInvitation(
          {
            invitationId: memberToRevoke?.invitationId!,
            req: {
              invitationId: memberToRevoke?.invitationId!,
              status: PatchTeamInvitationRequestStatusEnum.Cancelled,
            },
          },
          {
            onSuccess: () => {
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.SELF_SERVICE_TEAM_REVOKE_POPUP_CLICK_REVOKE_INVITATION,
              );
              onConfirm();
              onClose();
            },
          },
        )
      }
      isSubmitting={isRevokingInvitation}
      isDisabled={isDisabled}
      confirmButtonText='Revoke Invitation'
      size='large'
      hideIcon={isSmScreen()}
    />
  );
};

export default ZenRevokeInvitation;
