import { DateTime } from 'luxon';

export const hasPastAgreementRequiredDate = (requiredAt: number) => {
  if (requiredAt) {
    return DateTime.now().toISODate() >=
      DateTime.fromMillis(requiredAt).toISODate()
      ? true
      : false;
  }
  return false;
};
