import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

interface SystemTimeProps {}

const SystemTime: React.FC<SystemTimeProps> = () => {
  const [currentTime, setCurrentTime] = useState(DateTime.utc());

  useEffect(() => {
    const tick = setInterval(() => {
      setCurrentTime(DateTime.utc());
    }, 1000);

    return () => clearTimeout(tick);
  }, []);

  return (
    <p className='text-white mb-3'>
      {`System Time: ${currentTime.toISODate()} ${currentTime.toFormat('t')}`}
    </p>
  );
};

export default SystemTime;
