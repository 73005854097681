export interface ItemInfoProps {
  title: string;
  value: string | number;
}

const ItemInfo: React.FC<ItemInfoProps> = ({ title, value }) => {
  return (
    <div>
      <p className='uppercase font-primary-medium tracking-wider text-gray-700'>
        {title}
      </p>
      <p className='uppercase font-primary-light'>{value}</p>
    </div>
  );
};

export default ItemInfo;
