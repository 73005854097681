import {
  faArrowUpRightFromSquare,
  faPaperPlane,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import { PHONE_NUMBER_VALIDATIONS } from '../../../../utils/Validations';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledPhoneNumberInput from '../../../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledTextAreaInput from '../../../Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../../../Zen/Input/ZenControlledTextInput';
import ZenButton from '../../../Zen/ZenButton';
import {
  formDataToGoalDetails,
  wealthPlanReferralGoalsToQueryParams,
} from '../../WealthPlanReferral/V2/wealthPlanReferralUtilsV2';
import { WealthPlanRateBanner } from '../WealthPlanRate';
import {
  SendWealthPlanStepByStepFormData,
  WealthPlanFormStepName,
} from './SendWealthPlanStepByStepFormV2';

const AgentInfoPlanV2: StepByStepComponent<
  SendWealthPlanStepByStepFormData,
  WealthPlanFormStepName
> = ({ form: { control, watch }, onSubmit, onPrevious }) => {
  const data: SendWealthPlanStepByStepFormData = watch();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [isSubmitting, setIsSubmitting] = useState<boolean>();

  const handlePreviewWealthPlan = () => {
    const goals = formDataToGoalDetails(data, true);
    const country = data?.country.value;
    const queryParams = wealthPlanReferralGoalsToQueryParams(
      goals,
      country,
      {
        firstname: data.firstName,
        lastname: data.lastName,
      },
      {
        firstname: userDetail?.firstName ?? '',
        lastname: userDetail?.lastName ?? '',
      },
    );
    window.open(`/wealthplan/referral?${queryParams}`, '_blank');
  };

  return (
    <>
      <div className='w-full min-h-full h-full scrollbar overflow-y-scroll'>
        <div className='w-full flex flex-col justify-start relative items-start gap-1 pt-6 pb-32'>
          <p className='font-medium text-xl font-zen-body text-dark'>
            Agent&apos;s Info
          </p>
          <p className='text-zen-dark-8 font-zen-body'>
            Please provide some information so we can create a plan for{' '}
            {data.firstName} {data.lastName}
          </p>
          <WealthPlanRateBanner />
          <div className='flex flex-row gap-2 w-full mt-2'>
            <div className='w-1/2'>
              <ZenControlledTextInput<
                SendWealthPlanStepByStepFormData,
                'firstName'
              >
                control={control}
                name='firstName'
                label='First Name'
                variant='light'
                rules={{ required: 'Please enter the firstname' }}
                isRequired
                shouldUnregister={false}
              />
            </div>
            <div className='w-1/2'>
              <ZenControlledTextInput<
                SendWealthPlanStepByStepFormData,
                'lastName'
              >
                control={control}
                variant='light'
                name='lastName'
                label='Last Name'
                isRequired
                rules={{ required: 'Please enter the lastname' }}
                shouldUnregister={false}
              />
            </div>
          </div>
          <div className='mt-2 w-full'>
            <ZenControlledPhoneNumberInput<
              SendWealthPlanStepByStepFormData,
              'phoneNumber'
            >
              control={control}
              label='Phone Number'
              name='phoneNumber'
              placeholder='+1 (702) 123-4567'
              rules={{
                required: 'Please enter your phone number',
                ...PHONE_NUMBER_VALIDATIONS,
              }}
              shouldUnregister={false}
            />
          </div>
          <div className='mt-2 w-full'>
            <ZenControlledTextInput<
              SendWealthPlanStepByStepFormData,
              'brokerageName'
            >
              control={control}
              variant='light'
              name='brokerageName'
              placeholder="Agent's current brokerage"
              rules={{ required: 'Please enter the Current Brokerage Name' }}
              label='Brokerage Name'
              shouldUnregister={false}
            />
          </div>
          <div className='mt-2 w-full'>
            <ZenControlledTextInput<SendWealthPlanStepByStepFormData, 'email'>
              control={control}
              variant='light'
              name='email'
              label='Email Address'
              rules={{
                required: 'Please enter your email',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Please enter a valid email',
                },
              }}
              shouldUnregister={false}
            />
          </div>
          <div className='mt-2 w-full'>
            <ZenControlledTextInput<
              SendWealthPlanStepByStepFormData,
              'emailSubject'
            >
              control={control}
              variant='light'
              name='emailSubject'
              rules={{ required: 'Please enter Email Subject' }}
              label='Email Subject line'
              shouldUnregister={false}
            />
          </div>
          <div className='mt-2 w-full'>
            <ZenControlledTextAreaInput<
              SendWealthPlanStepByStepFormData,
              'emailMessage'
            >
              control={control}
              rows={3}
              name='emailMessage'
              label='Email message'
              rules={{ required: 'Please enter Email Message' }}
              shouldUnregister={false}
            />
          </div>
        </div>
      </div>
      <div className='sticky p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-2 flex flex-row justify-between items-center left-0 right-0  w-full'>
        <div
          className='flex gap-1 items-center cursor-pointer'
          onClick={handlePreviewWealthPlan}
        >
          <p className='text-primary-blue text-medium font-zen-body'>
            Preview WealthPlan
          </p>
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            className='text-primary-blue'
          />
        </div>
        <div className='flex gap-2'>
          <ZenButton
            variant='primary-outline'
            label='Back'
            onClick={() => onPrevious()}
          />
          <ZenButton
            label='Send WealthPlan'
            onClick={async () => {
              setIsSubmitting(true);
              await onSubmit();
              setIsSubmitting(false);
            }}
            RightIconComponent={<FontAwesomeIcon icon={faPaperPlane} />}
            isSubmitting={isSubmitting}
            isDisabled={isSubmitting}
            type='submit'
          />
        </div>
      </div>
    </>
  );
};

export default AgentInfoPlanV2;
