import React from 'react';
import { FieldObject } from '../../../openapi/atlantis';
import useValidateVisibleCondition from '../hooks/useValidateVisibleCondition';
import MortgageApplicationFormInput from './MortgageApplicationFormInput';

interface MortgageApplicationConditionalFormInputProps {
  name: string;
  field: FieldObject;
  onSubmit: () => void;
  isReadOnly: boolean;
}

const MortgageApplicationConditionalFormInput: React.FC<MortgageApplicationConditionalFormInputProps> = ({
  name,
  field,
  onSubmit,
  isReadOnly,
}) => {
  const { validateFieldVisibleCondition } = useValidateVisibleCondition();
  const isVisible = validateFieldVisibleCondition(field);

  if (!isVisible) {
    return null;
  }

  return (
    <MortgageApplicationFormInput
      name={name}
      field={field}
      onSubmit={onSubmit}
      isReadOnly={isReadOnly}
    />
  );
};

export default MortgageApplicationConditionalFormInput;
