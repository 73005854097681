import { faCircleInfo, faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { Control, UseFormTrigger } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { InviteControllerApi } from '../../../openapi/avalon';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { fetchJVInvite } from '../../../slices/JointVentureSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { getErrorMessage } from '../../../utils/ErrorUtils';
import { getAvalonConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import Alert from '../../Alert';
import DefaultLoader from '../../DefaultLoader';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenFormErrorMessage from '../../Zen/Input/ZenFormErrorMessage';
import ZenIconCircleWithTooltipCell from '../../Zen/Transaction/ZenIconCircleWithTooltipCell';
import ZenButton from '../../Zen/ZenButton';
import { JointVentureSignUpFormState } from './JointVentureSignUpSteps';
import JVSignUpBanner from './JVSignUpBanner';

interface SignUpPaymentComponentProps {
  inviteId: string;
  control: Control<JointVentureSignUpFormState, object>;
  trigger: UseFormTrigger<JointVentureSignUpFormState>;
  onNext(): void;
}

interface FieldErrorMap {
  cardNumber?: string;
  cardExpiry?: string;
  cardCvc?: string;
}

interface FieldFocusMap {
  cardNumber?: boolean;
  cardExpiry?: boolean;
  cardCvc?: boolean;
}

interface FieldEmptyMap {
  cardNumber?: boolean;
  cardExpiry?: boolean;
  cardCvc?: boolean;
}

const SignUpPaymentComponent: React.FC<SignUpPaymentComponentProps> = ({
  inviteId,
  trigger,
  onNext,
  control,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [stripeError, setStripeError] = useState<StripeError>();
  const [processing, setProcessing] = useState(false);
  const [isError, setIsError] = useState('');
  const [fieldError, setFieldError] = useState<FieldErrorMap>({});
  const [fieldFocus, setFieldFocus] = useState<FieldFocusMap>({});
  const [fieldEmpty, setFieldEmpty] = useState<FieldEmptyMap>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    jointVenture: {
      showInviteResponse: { loading, data },
    },
  } = useSelector((state: RootState) => state);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (!stripe || !elements) {
      return;
    }

    const { paymentMethod, error } = await stripe!.createPaymentMethod({
      type: 'card',
      card: elements.getElement!(CardNumberElement)!,
    });

    const isValid = await trigger();
    // don't merge the two ifs
    if (!isValid) {
      return;
    }
    if (error) {
      setStripeError(error);
      setProcessing(false);
      return;
    }

    const acceptReq = {
      paymentMethod: paymentMethod?.id!,
    };
    try {
      await new InviteControllerApi(getAvalonConfiguration()).acceptInvite(
        inviteId,
        acceptReq,
      );
      onNext();
    } catch (e) {
      setProcessing(false);
      setIsError(getErrorMessage(ErrorService.getErrorCode(e)));
      dispatch(showApiErrorModal(e));
      dispatch(
        showErrorToast(
          'We had a problem updating your joint venture invitation',
          'Please try again in a few moments.',
        ),
      );
      ErrorService.notify('Unable to accept joint venture invitation', e, {
        req: { inviteId, acceptReq },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    dispatch(fetchJVInvite());
  }, [dispatch]);

  if (loading) {
    return <DefaultLoader />;
  }

  return (
    <div className='h-full flex flex-col'>
      <JVSignUpBanner inviteResponse={data} />
      <form onSubmit={handleSubmit} className='flex-grow flex flex-col'>
        <div className='flex-grow w-1/4 mx-auto pt-8'>
          <div className='w-full'>
            <div>
              <p className='text-xl text-dark font-zen-body font-medium'>
                Enter Payment Details
              </p>
            </div>
            {(isError || stripeError) && (
              <div className='flex flex-row items-center justify-center my-2'>
                <Alert
                  text={isError || stripeError?.message!}
                  variant='error'
                />
              </div>
            )}
            <div className='pt-7 mt-0.5'>
              <label className='inline-block' htmlFor='name'>
                <span
                  className={cn(
                    'font-zen-body font-semibold text-base',
                    fieldError.cardNumber
                      ? 'text-zen-danger'
                      : 'text-zen-dark-9',
                  )}
                >
                  Card Number
                </span>
                <span className='text-zen-danger'>*</span>
              </label>
              <div
                className={cn(
                  'flex items-center border rounded-lg overflow-hidden',
                  fieldFocus.cardNumber
                    ? 'border-zen-dark-9'
                    : 'border-zen-dark-5',
                  !fieldEmpty.cardNumber && 'text-zen-dark-9',
                  fieldError.cardNumber && '!border-zen-danger',
                )}
              >
                <div className='h-full flex items-center justify-center ml-3 mr-2'>
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    size='1x'
                    className='text-primary-blue'
                  />
                </div>
                <CardNumberElement
                  onChange={({ error, empty }) => {
                    if (error) {
                      setFieldError({
                        ...fieldError,
                        cardNumber: error.message,
                      });
                    } else if (fieldError.cardNumber) {
                      setFieldError({
                        ...fieldError,
                        cardNumber: undefined,
                      });
                    }
                    if (empty && !fieldEmpty.cardNumber) {
                      setFieldEmpty({ ...fieldEmpty, cardNumber: true });
                    } else if (!empty && fieldEmpty.cardNumber) {
                      setFieldEmpty({ ...fieldEmpty, cardNumber: false });
                    }
                  }}
                  onFocus={() =>
                    setFieldFocus({ ...fieldFocus, cardNumber: true })
                  }
                  onBlur={() =>
                    setFieldFocus({ ...fieldFocus, cardNumber: false })
                  }
                  className='flex-grow appearance-none p-2 py-3 w-full border-none focus:outline-none focus:ring-0 font-zen-body font-normal text-base'
                />
              </div>
              <div className='flex'>
                {!!fieldError.cardNumber && (
                  <ZenFormErrorMessage message={fieldError.cardNumber} />
                )}
              </div>
            </div>
            <div className='pt-7 mt-0.5 grid grid-cols-2 gap-x-7'>
              <div className='w-full space-y-1'>
                <label className='inline-block' htmlFor='name'>
                  <span
                    className={cn(
                      'font-zen-body font-semibold text-base',
                      fieldError.cardExpiry
                        ? 'text-zen-danger'
                        : 'text-zen-dark-9',
                    )}
                  >
                    Card Expiry
                  </span>
                  <span className='text-zen-danger'>*</span>
                </label>
                <div
                  className={cn(
                    'flex items-center border rounded-lg',
                    fieldFocus.cardExpiry
                      ? 'border-zen-dark-9'
                      : 'border-zen-dark-5',
                    !fieldEmpty.cardExpiry && 'text-zen-dark-9',
                    fieldError.cardExpiry && '!border-zen-danger',
                  )}
                >
                  <CardExpiryElement
                    onChange={({ error, empty }) => {
                      if (error) {
                        setFieldError({
                          ...fieldError,
                          cardExpiry: error.message,
                        });
                      } else if (fieldError.cardExpiry) {
                        setFieldError({
                          ...fieldError,
                          cardExpiry: undefined,
                        });
                      }
                      if (empty && !fieldEmpty.cardExpiry) {
                        setFieldEmpty({ ...fieldEmpty, cardExpiry: true });
                      } else if (!empty && fieldEmpty.cardExpiry) {
                        setFieldEmpty({ ...fieldEmpty, cardExpiry: false });
                      }
                    }}
                    onFocus={() =>
                      setFieldFocus({ ...fieldFocus, cardExpiry: true })
                    }
                    onBlur={() =>
                      setFieldFocus({ ...fieldFocus, cardExpiry: false })
                    }
                    className='flex-grow appearance-none p-2 py-3 w-full border-none focus:outline-none focus:ring-0 font-zen-body font-normal text-base'
                  />
                </div>
                <div className='flex'>
                  {!!fieldError.cardExpiry && (
                    <ZenFormErrorMessage message={fieldError.cardExpiry} />
                  )}
                </div>
              </div>
              <div className='w-full space-y-1'>
                <label className='inline-block' htmlFor='name'>
                  <span
                    className={cn(
                      'font-zen-body font-semibold text-base',
                      fieldError.cardCvc
                        ? 'text-zen-danger'
                        : 'text-zen-dark-9',
                    )}
                  >
                    Card CVC
                  </span>
                  <span className='text-zen-danger'>*</span>
                </label>
                <div
                  className={cn(
                    'flex items-center border rounded-lg',
                    fieldFocus.cardCvc
                      ? 'border-zen-dark-9'
                      : 'border-zen-dark-5',
                    !fieldEmpty.cardCvc && 'text-zen-dark-9',
                    fieldError.cardCvc && '!border-zen-danger',
                  )}
                >
                  <CardCvcElement
                    onChange={({ error, empty }) => {
                      if (error) {
                        setFieldError({
                          ...fieldError,
                          cardCvc: error.message,
                        });
                      } else if (fieldError.cardCvc) {
                        setFieldError({
                          ...fieldError,
                          cardCvc: undefined,
                        });
                      }
                      if (empty && !fieldEmpty.cardCvc) {
                        setFieldEmpty({ ...fieldEmpty, cardCvc: true });
                      } else if (!empty && fieldEmpty.cardCvc) {
                        setFieldEmpty({ ...fieldEmpty, cardCvc: false });
                      }
                    }}
                    onFocus={() =>
                      setFieldFocus({ ...fieldFocus, cardCvc: true })
                    }
                    onBlur={() =>
                      setFieldFocus({ ...fieldFocus, cardCvc: false })
                    }
                    className='flex-grow appearance-none p-2 py-3 w-full border-none focus:outline-none focus:ring-0 font-zen-body font-normal text-base'
                  />
                  <div className='h-full flex items-center justify-center ml-1 mr-3 z-50'>
                    <ZenIconCircleWithTooltipCell
                      hoverComponent={
                        <div className='w-64 text-dark font-zen-body p-1 bg-white z-40'>
                          CVC is the last three digits on the back of your card
                        </div>
                      }
                      onClick={() => {}}
                      icon={
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          size='sm'
                          className='text-zen-dark-7'
                        />
                      }
                      noWrap
                    />
                  </div>
                </div>
                <div className='flex'>
                  {!!fieldError.cardCvc && (
                    <ZenFormErrorMessage message={fieldError.cardCvc} />
                  )}
                </div>
              </div>
            </div>
            <div className='pt-7'>
              <ZenControlledTextInput<JointVentureSignUpFormState, 'nameOnCard'>
                name='nameOnCard'
                control={control}
                label='Name on Card'
                placeholder='Gavin Wood'
                isRequired
                shouldUnregister={false}
                rules={{
                  required: 'Please provide the name on your card.',
                }}
              />
            </div>
            {processing && (
              <div className='pt-3'>
                <DefaultLoader />
              </div>
            )}
          </div>
        </div>
        <div className='w-full bg-white items-end justify-end flex flex-col flex-grow'>
          <div className='w-full p-4 border-t border-coolGray-300 flex justify-end'>
            <div className='w-44'>
              <ZenButton
                variant='primary'
                label='Sign Up'
                type='submit'
                isFullWidth
                isSubmitting={isSubmitting}
                isDisabled={isSubmitting}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignUpPaymentComponent;
