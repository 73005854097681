import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { CreateWebhookRequestEventNamesEnum } from '../../openapi/plutus';
import { WebhookEvent, WebhookEventGroup } from '../../utils/WebhookUtils';
import CustomChip from '../CustomChip';
import WebhookSidebarModal from './WebhookSidebarModal';

interface WebhookEventSelectInputProps {
  name: string;
  placeholder: string;
  eventsList: WebhookEventGroup[];
  eventsToDisplay: WebhookEvent[];
  isAllSelected?: boolean;
  handleToggleEvent(
    eventEnum: CreateWebhookRequestEventNamesEnum,
    removeFlag?: boolean,
  ): void;
  handleToggleGroup(index: number, toggle: boolean): void;
  handleToggleAll(toggle: boolean): void;
  handleEventSubmit(): void;
}

const EmptyPlaceholder = (handleSidebarOpen?: () => void) => {
  return (
    <div className='h-40 w-full flex items-center justify-center flex-col gap-2'>
      <div
        className='text-primary-blue flex items-center gap-2 cursor-pointer'
        onClick={handleSidebarOpen}
      >
        <FontAwesomeIcon icon={faPlus} color='text-primary-blue' />
        <p className='mt-1'>Add Events</p>
      </div>
      <p className='font-normal text-zen-dark-5'>
        Please select the events to add in your endpoint
      </p>
    </div>
  );
};

const WebhookEventSelectInput: React.FC<WebhookEventSelectInputProps> = ({
  name,
  placeholder,
  eventsList = [],
  eventsToDisplay = [],
  isAllSelected,
  handleToggleEvent,
  handleToggleGroup,
  handleToggleAll,
  handleEventSubmit,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  return (
    <div className='space-y-1 w-full'>
      <label className='inline-block' htmlFor={name}>
        <span className='font-zen-body font-semibold text-zen-dark-9'>
          Select Events to Add
        </span>

        <span className='text-zen-danger'>*</span>
      </label>

      <div className='flex rounded-lg bg-white border overflow-hidden border-zen-dark-9'>
        <div
          id={name}
          placeholder={placeholder}
          className='appearance-none p-2 focus:outline-none focus:ring-0 w-full border-none flex items-center justify-center'
        >
          {isEmpty(eventsToDisplay) ? (
            EmptyPlaceholder(() => setIsSidebarOpen(true))
          ) : (
            <div className='max-h-80 w-full flex flex-wrap scrollbar overflow-y-auto'>
              {eventsToDisplay?.map((event: WebhookEvent) => (
                <CustomChip
                  key={event.value}
                  label={event.label}
                  handleClick={() => handleToggleEvent(event.value, true)}
                />
              ))}
            </div>
          )}

          <WebhookSidebarModal
            isOpen={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
            eventsList={eventsList}
            isAllSelected={isAllSelected}
            handleToggleEvent={handleToggleEvent}
            handleToggleGroup={handleToggleGroup}
            handleToggleAll={handleToggleAll}
            handleEventSubmit={() => {
              handleEventSubmit();
              setIsSidebarOpen(false);
            }}
          />
        </div>
      </div>

      {!isEmpty(eventsToDisplay) && (
        <div
          className='text-primary-blue flex items-center gap-2 cursor-pointer pt-2'
          onClick={() => setIsSidebarOpen(true)}
        >
          <FontAwesomeIcon icon={faPlus} color='text-primary-blue' />
          <p className='mt-1'>Add Events</p>
        </div>
      )}
    </div>
  );
};

export default WebhookEventSelectInput;
