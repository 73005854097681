import ZenEmailReset from '../../Engineering/ZenEmailReset';
import ZenCancelCapDeferment from './ZenCancelCapDeferment';
import ZenChangeAnniversaryDate from './ZenChangeAnniversaryDate';
import ZenChangeSponsor from './ZenChangeSponsor';
import ZenCompaniesLink from './ZenCompaniesLink';
import ZenMarkAsCapDeferred from './ZenMarkAsCapDeffered';

interface ZenOnboardingComponentProps {}

const ZenOnboardingComponent: React.FC<ZenOnboardingComponentProps> = () => (
  <div className='grid grid-cols-1 grid-flow-row md:grid-cols-6 gap-4 p-4'>
    <ZenEmailReset />
    <ZenChangeSponsor />
    <ZenChangeAnniversaryDate />
    <ZenCompaniesLink />
    <ZenMarkAsCapDeferred />
    <ZenCancelCapDeferment />
  </div>
);

export default ZenOnboardingComponent;
