import config from '../config';
import { EnumMap } from '../types';

export const States: EnumMap<string, string> = {
  ALASKA: 'Alaska',
  ARIZONA: 'Arizona',
  CALIFORNIA: 'California',
  COLORADO: 'Colorado',
  CONNECTICUT: 'Connecticut',
  FLORIDA: 'Florida',
  GEORGIA: 'Georgia',
  HAWAII: 'Hawaii',
  ILLINOIS: 'Illinois',
  KANSAS: 'Kansas',
  LOUISIANA: 'Louisiana',
  MARYLAND: 'Maryland',
  MASSACHUSETTS: 'Massachusetts',
  MINNESOTA: 'Minnesota',
  MISSOURI: 'Missouri',
  NEVADA: 'Nevada',
  NEW_HAMPSHIRE: 'New Hampshire',
  NEW_JERSEY: 'New Jersey',
  NORTH_CAROLINA: 'North Carolina',
  NEW_YORK: 'New York',
  OHIO: 'Ohio',
  OKLAHOMA: 'Oklahoma',
  OREGON: 'Oregon',
  PENNSYLVANIA: 'Pennsylvania',
  RHODE_ISLAND: 'Rhode Island',
  SOUTH_CAROLINA: 'South Carolina',
  TENNESSEE: 'Tennessee',
  TEXAS: 'Texas',
  UTAH: 'Utah',
  VIRGINIA: 'Virginia',
  WASHINGTON_STATE: 'Washington State',
  WASHINGTON_DC: 'Washington D.C.',
  WISCONSIN: 'Wisconsin',
};

export const StatesAbbreviation: EnumMap<string, string> = {
  ALASKA: 'AK',
  ARIZONA: 'AZ',
  CALIFORNIA: 'CA',
  COLORADO: 'CO',
  CONNECTICUT: 'CT',
  FLORIDA: 'FL',
  GEORGIA: 'GA',
  HAWAII: 'HI',
  ILLINOIS: 'IL',
  KANSAS: 'KS',
  LOUISIANA: 'LA',
  MARYLAND: 'MD',
  MASSACHUSETTS: 'MA',
  MISSOURI: 'MO',
  MINNESOTA: 'MN',
  NEVADA: 'NV',
  NEW_HAMPSHIRE: 'NH',
  NEW_JERSEY: 'NJ',
  NEW_YORK: 'NY',
  NORTH_CAROLINA: 'NC',
  OHIO: 'OH',
  OKLAHOMA: 'OK',
  OREGON: 'OR',
  PENNSYLVANIA: 'PA',
  RHODE_ISLAND: 'RI',
  SOUTH_CAROLINA: 'SC',
  TENNESSEE: 'TN',
  TEXAS: 'TX',
  UTAH: 'UT',
  VIRGINIA: 'VA',
  WASHINGTON_STATE: 'WA',
  WASHINGTON_DC: 'DC',
  WISCONSIN: 'WI',
};

export const stateToCustomPolicyFileName: EnumMap<string, string> = {
  // By default, the policy file name is the abbreviation of the state: NY.pdf.
  // However, if you have a new version that needs to be added (but we still need to host the old one
  // for existing ICAs, you can add a separate config here.
  LOUISIANA: 'LA-v2',
  ARIZONA: 'AZ-v2',
};

export const StateToDisplayName = (state: string) => {
  return States[state];
};

export const getPolicyUrl = (state: string): string => {
  let fileName = StatesAbbreviation[state];
  if (stateToCustomPolicyFileName[state]) {
    fileName = stateToCustomPolicyFileName[state];
  }
  return `${config.assetsBaseUrl}/policies-and-procedures/${fileName}.pdf`;
};
