import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCInfo } from '../components/TransactionCoordinator/TCInfoSection';
import ErrorService from '../services/ErrorService';
import {
  AppThunk,
  TransactionCoordinatorAccessEnum,
  TransactionCoordinatorState,
} from '../types';
import { TCAccessStatusEnum } from '../utils/TransactionCoordinatorUtils';
import { showErrorToast } from './ToastNotificationSlice';

export const initialState: TransactionCoordinatorState = {
  isLoading: false,
  tcDataById: {},
};

const TransactionCoordinatorSlice = createSlice({
  name: 'transactionCoordinator',
  initialState,
  reducers: {
    setTCInfo: (state, action: PayloadAction<TCInfo>) => {
      state.tcDataById[action.payload.id] = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setTCInfo, setIsLoading } = TransactionCoordinatorSlice.actions;
export default TransactionCoordinatorSlice.reducer;

export const fetchTCInfo = (tcId: string): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const data: TCInfo = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          id: '1',
          name: 'John Doe',
          teamName: 'Team 1',
          phone: '123456789',
          email: 'john@email.com',
          startedOn: '2022-01-01',
          accessType:
            TransactionCoordinatorAccessEnum.ALL_OPEN_LISTINGS_AND_TRANSACTIONS,
          status: TCAccessStatusEnum.ACTIVE,
        });
      }, 1000);
    });
    dispatch(setTCInfo(data));
  } catch (error) {
    dispatch(
      showErrorToast(
        'We had a problem in fetching the transaction coordinator information.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify(
      'Error in fetching the transaction coordinator information.',
      error,
      {
        tcInfo: { tcId },
      },
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};
