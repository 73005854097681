import { useSelector } from 'react-redux';
import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../routes/AgentWebsiteOnboardingRoute';
import { AGENT_ONBOARDING_STEPS } from '../../testUtils/AgentWebsiteOnboardingUtils';
import {
  AgentWebsiteOnboardingSiteInfoAccordionName,
  AgentWebsiteOnboardingStepEnum,
  RootState,
} from '../../types';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import Accordion from '../Accordion';
import ApplicationStepProgressHeader from '../onboarding/ApplicationStepProgressHeader';
import AgentWebsiteOnboardingContactInfo from './site-config/AgentWebsiteOnboardingContactInfo';
import AgentWebsiteOnboardingAboutContent from './site-config/AgentWebsiteOnboardingAboutContent';
import AgentWebsiteOnboardingMainContent from './site-config/AgentWebsiteOnboardingMainContent';
import AgentWebsiteOnboardingFeaturedContent from './site-config/AgentWebsiteOnboardingFeaturedContent';

const AgentWebsiteOnboardingSiteInfo: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = (props) => {
  const { defaultAccordionOpenType } = useSelector(
    (state: RootState) => state.agentWebsiteOnboarding,
  );
  return (
    <div className='h-full w-full flex flex-col items-center max-w-6xl mx-auto'>
      <ApplicationStepProgressHeader
        steps={AGENT_ONBOARDING_STEPS}
        inProgressIndex={AgentWebsiteOnboardingStepEnum.SITE_INFO}
      />
      <div className='w-full'>
        <div className='mt-5 font-primary-regular text-base text-dark leading-loose'>
          <p>
            Contact info and About sections are shown on your Home page, Contact
            form, and About page.
          </p>
          <p>
            Main content refers to the top area — the first thing your clients
            see when visiting your site.
          </p>
          <p>
            Featured content refers to your 3 highlighted competencies or
            services you offer.
          </p>
        </div>
        <div className='my-5'>
          <Accordion
            title={AgentWebsiteOnboardingSiteInfoAccordionName.CONTACT_INFO}
            isOpenDefault={
              defaultAccordionOpenType ===
              AgentWebsiteOnboardingSiteInfoAccordionName.CONTACT_INFO
            }
          >
            <AgentWebsiteOnboardingContactInfo {...props} />
          </Accordion>
          <Accordion
            title={AgentWebsiteOnboardingSiteInfoAccordionName.ABOUT_CONTENT}
            isOpenDefault={
              defaultAccordionOpenType ===
              AgentWebsiteOnboardingSiteInfoAccordionName.ABOUT_CONTENT
            }
          >
            <AgentWebsiteOnboardingAboutContent {...props} />
          </Accordion>
          <Accordion
            title={AgentWebsiteOnboardingSiteInfoAccordionName.MAIN_CONTENT}
            isOpenDefault={
              defaultAccordionOpenType ===
              AgentWebsiteOnboardingSiteInfoAccordionName.MAIN_CONTENT
            }
          >
            <AgentWebsiteOnboardingMainContent {...props} />
          </Accordion>
          <Accordion
            title={AgentWebsiteOnboardingSiteInfoAccordionName.FEATURED_CONTENT}
            isOpenDefault={
              defaultAccordionOpenType ===
              AgentWebsiteOnboardingSiteInfoAccordionName.FEATURED_CONTENT
            }
          >
            <AgentWebsiteOnboardingFeaturedContent {...props} />
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingSiteInfo;
