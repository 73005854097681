interface ApiGenerationTermsOfServicesProps {}

const ApiGenerationTermsOfServices: React.FC<ApiGenerationTermsOfServicesProps> = () => {
  return (
    <div style={{ margin: '5rem' }}>
      <h2
        dir='ltr'
        style={{ lineHeight: '1.38', marginTop: '18pt', marginBottom: '4pt' }}
      >
        <span
          style={{
            fontSize: '17pt',
            fontFamily: 'Arial',
            color: '#03363d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Application Developer and API Agreement
        </span>
      </h2>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          As of Dec 1, 2022 (the, “Effective Date”), Real Broker, LLC., and its
          subsidiaries (collectively, “RealBroker”) have updated this
          Application Developer and API License Agreement (the, “Agreement”). If
          You are a new Licensee, then this Agreement will be effective as of
          Nov 1, 2022. If You are an existing Licensee, We are providing You
          with prior notice of these changes which will be effective as of Nov
          1, 2022.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          By accepting this Agreement, either by accessing or using the API, or
          authorizing or permitting any individual to access or use the API, You
          agree to be bound by this Agreement.
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;If You are entering into this Agreement on behalf of a company,
          organization or another legal entity (an “Entity”), You are agreeing
          to this Agreement for that Entity and representing to RealBroker that
          You have the authority to bind such Entity and its Affiliates to this
          Agreement, in which case the terms “Licensee”, “You”, “Your” or a
          related capitalized term herein shall refer to such Entity and its
          Affiliates. If You do not have such authority, or if You do not agree
          with this Agreement, You must not accept this Agreement and may not
          access nor use the API.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          1. DEFINITIONS
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          For purposes of this Agreement, capitalized terms shall have the
          meanings set forth below. Capitalized terms utilized in this Agreement
          and not defined have the meaning set forth in the Master Subscription
          Agreement, available&nbsp;
        </span>
        <a
          href='https://www.zendesk.com/company/customers-partners/master-subscription-agreement/'
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#17494d',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            here
          </span>
        </a>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          , or such other applicable agreement between Licensee and RealBroker
          relating to its access to and use of the Services (“Service
          Agreement”).
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          API
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          : means an API and any accompanying or related documentation, source
          code, SDKs, executable applications and other materials made available
          by RealBroker, including, without limitation, through its&nbsp;
        </span>
        <a
          href='https://bolt.therealbrokerage.com/'
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Developer Website
          </span>
        </a>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          .
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Applications
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          : mean paid or unpaid web or other software services, applications, or
          Themes developed by Licensee that utilize or interact with the API or
          otherwise interact with the Services and are authorized to be
          Published pursuant to this Agreement.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          App Market
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          : means any marketplace or other aggregator or public repository of
          code or applications.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Intellectual Property Rights
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          : means patents, inventions, copyrights, trademarks, domain names,
          trade secrets, know-how and any other intellectual property and/or
          proprietary rights.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Internal Use
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          : means the use of the API in connection with Licensee’s subscription
          to a Service for Licensee’s internal business purposes in accordance
          with the Service Agreement.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Marketplace
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          : means the marketplace or other aggregator or public repository of
          code or applications provided and operated by RealBroker.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Paid Applications
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          : mean any Application published by Licensee in which a Subscriber
          pays Licensee and/or RealBroker for a license to use, access and/or
          deploy such Application.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Payment Processor
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          : means the third party payment processor that processes fees related
          to a Paid Application.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Privacy Policy
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          : means the Privacy Policy found&nbsp;
        </span>
        <a
          href='https://joinreal.com/page/legal'
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            here
          </span>
        </a>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          .
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Publish/Published/Publishing
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          : means the making of any Application available to any Subscriber
          other than Licensee or for any purpose other than for use by Licensee
          as a Subscriber for Internal Use.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Subscriber
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          : means and refers to an individual or an Entity that has agreed to a
          Service Agreement for use of our Services.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Themes
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          : means Applications that allow Subscribers to present customized or
          non-default appearances and functionality within RealBroker Guide or
          help center functionality.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          2. PURPOSE AND LICENSE
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          2.1
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;This Agreement and any and all restrictions and policies
          implemented by RealBroker from time to time with respect to the API as
          set forth in the Documentation (“General API Policies”) govern
          Licensee’s rights to use and access the API for the purpose of
          developing, implementing and Publishing Applications. Licensee’s
          access to and use of the API for Internal Use is governed by the
          Service Agreement and the General API Policies. This Agreement also
          governs Licensee’s Publishing of Applications that do not utilize or
          interact with the API, but otherwise interact with the Services.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          2.2
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Subject to this Agreement, including the restrictions set forth
          in Section 3, RealBroker grants to Licensee a non-exclusive,
          non-transferable, non-sublicensable, worldwide, revocable right and
          license during the Term (as defined in Section 13) to: (a) use and
          make calls to the API to develop, implement and distribute
          Applications solely for use by Subscribers in connection with the
          Services; (b) use and display the RealBroker Marks only to identify
          that the Service Data originates from the Services; and (c) market and
          sell Applications through the Marketplace in accordance with this
          Agreement.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          2.3
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Licensee grants to RealBroker a non-exclusive, worldwide, fully
          paid-up, royalty-free license, for as long as its Applications are
          Published to a Marketplace to: (a) market, sell, distribute, use,
          perform, and display such Applications; and (b) permit others to
          access, install, purchase and (in the case of downloadable software
          applications) download such Applications through the Marketplace.
          Licensee further grants to RealBroker a non-exclusive, worldwide,
          fully paid-up, royalty-free license, during the Term, to use
          Licensee’s name, Application name(s) and associated logos
          (collectively, “Licensee Marks”) solely to enable RealBroker to
          exercise its rights and perform its obligations under this Agreement.
          Any use of Licensee’s Marks shall be in accordance with Licensee’s
          reasonable trademark usage policies if such policies are communicated
          to RealBroker.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          2.4
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;RealBroker shall have, and Licensee hereby grants to RealBroker,
          a royalty-free, fully paid-up, worldwide, transferable,
          sub-licensable, irrevocable and perpetual license to implement, use,
          modify, commercially exploit, incorporate into any of RealBroker’s
          products or services (including the Services and/or the API) and/or
          otherwise use in any manner any suggestions, enhancement requests,
          recommendations or other feedback RealBroker receives from Licensee.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3. RESTRICTIONS AND RESPONSIBILITIES
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          The licenses granted in Section 2 of this Agreement are explicitly
          conditioned on Licensee’s adherence to the following restrictions and
          compliance with its responsibilities as set forth herein.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.1
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Licensee must comply with all restrictions set forth in this
          Agreement, the&nbsp;
        </span>
        <a
          href='https://joinreal.com/page/legal'
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Privacy Policy
          </span>
        </a>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          , and the General API Policies in all uses of the API and Service
          Data, and in Publishing Applications. Licensee must also comply with
          all restrictions set forth in this Agreement and the&nbsp;
        </span>
        <a
          href='https://joinreal.com/page/legal'
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Trademark Usage Guidelines
          </span>
        </a>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;in all uses of RealBroker Marks. If RealBroker believes, in its
          sole discretion, that Licensee has violated or attempted to violate
          any term, condition or the spirit of this Agreement, the license
          afforded Licensee pursuant to this Agreement may be temporarily or
          permanently revoked, with or without notice to Licensee.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.2
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;In order to use and access the API, Licensee must obtain API
          credentials (a “Token”) by becoming a Subscriber. Licensee may not
          share its Token with any third party, shall keep such Token and all
          Login information secure, and shall use the Token as Licensee’s sole
          means of accessing the API.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.3
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Licensee’s Applications shall not substantially replicate
          products or services offered by RealBroker, including, without
          limitation, functions or clients on platforms (such as iOS or Android)
          where RealBroker offers its own client or function. Subject to the
          preceding sentence and the parties’ other rights and obligations under
          this Agreement, each party agrees that the other party may develop and
          publish applications that are similar to or otherwise compete with
          such party’s applications. Applications may not use or access the API
          or a Service in order to monitor the availability, performance, or
          functionality of any of the API or a Service or for any similar
          benchmarking purposes.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.4
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Applications shall not, in any manner, display any form of
          advertising within or connected to any Service Data received by any
          Subscriber, Agent or End-User.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.5
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Licensee is not permitted to Publish any Applications on any App
          Market that is not Published in identical form on the Marketplace.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.6
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Licensee shall not, under any circumstances, through
          Applications or otherwise, repackage or resell the Services, or any
          part thereof, API or Service Data. Licensee shall not use or assist a
          third party in using the API or any Software in such a way to
          circumvent the requirement for an individual Agent Login for each
          individual who (a) leverages the Services to interact with End-Users;
          (b) Processes data related to interactions with End-Users; or (c)
          absent a license from Us otherwise, Processes data related to
          interactions originating from a Non-RealBroker Service that provides
          functionality similar to functionality provided by the Services and
          which would, pursuant to this Agreement, require an individual Agent
          Login if utilizing the Services for such interaction. Further,
          Licensee shall not use or assist a third party in using the API or any
          Software in such a way to circumvent applicable Service Plan
          restrictions or Agent licensing restrictions that are enforced in the
          Service user interface. Licensee is not permitted to use the API or
          any Service Data in any manner that does or could potentially
          undermine the security of the Services, the API, Service Data or any
          other data or information stored or transmitted using the Services. In
          addition, Licensee shall not, and shall not attempt to: (a) interfere
          with, modify or disable any features, functionality or security
          controls of the Services or the API, (b) defeat, avoid, bypass,
          remove, deactivate or otherwise circumvent any protection mechanisms
          for the Service or the API, or (c) reverse engineer, decompile,
          disassemble or derive source code, underlying ideas, algorithms,
          structure or organizational form from the Services or the API.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.7
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Licensee acknowledges that Licensee is solely responsible, and
          that RealBroker has no responsibility or liability of any kind, for
          the content, development, operation, support or maintenance of
          Applications. Without limiting the foregoing, Licensee will be solely
          responsible for (a) the technical installation and operation of its
          Applications; (b) creating and displaying information and content on,
          through or within its Applications; (c) ensuring that its Applications
          do not violate or infringe the Intellectual Property Rights of any
          third party; (d) ensuring that Applications are not offensive,
          profane, obscene, libelous or otherwise illegal; (e) ensuring that its
          Applications do not contain or introduce Malicious Software into a
          Service, an API, any Service Data or other data stored or transmitted
          using the Service; (f) ensuring that its Applications are not designed
          to or utilized for the purpose of spamming any RealBroker subscribers,
          Agents or End-Users; and (g) ensuring that its Applications do not
          violate any applicable law or third party right.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.8
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Licensee will respect and comply with the technical and
          policy-implemented limitations of the API and the restrictions of this
          Agreement in designing and implementing Applications. Without limiting
          the foregoing, Licensee shall not violate any explicit rate
          limitations on calling or otherwise utilizing an API.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.9
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Licensee shall not make any modifications to any Service Data,
          other than as reasonably necessary to modify the formatting of such
          Service Data in order to display it in a manner appropriate for the
          pertinent Applications.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.10
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;In order to Publish and maintain a Paid Application on the
          Marketplace, Licensee must (a) submit such Application to RealBroker
          for approval and listing as a Paid Application; (b) sign up and
          register for an account with the Payment Processor under the terms
          communicated to Licensee by the Payment Processor; (c) maintain a
          relevant terms of service and privacy policy to govern Subscriber’s
          use of the Paid Application; and (d) provide ongoing commercially
          reasonable support to users, purchasers and/or Subscribers of the Paid
          Application.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.11
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Licensee acknowledges and agrees that RealBroker may, at its
          sole discretion, initiate a refund related to any Purchase Fee
          (defined below) paid to Licensee by Subscriber in connection with a
          Subscriber’s purchase of a Paid Application in the event that
          RealBroker determines such a refund is appropriate. In addition,
          Licensee acknowledges and agrees that any such refund shall be the
          exclusive financial responsibility of Licensee. Without limiting any
          other rights afforded RealBroker with respect to such refund, the
          parties acknowledge and agree that any such refund may be processed
          through Payment Processor and taken out of Licensee’s Payment
          Processor account and returned to Subscriber. Licensee agrees that
          RealBroker shall not be responsible for and will not issue any refunds
          out of its funds related to Licensee’s Applications for any reason,
          including Licensee’s failure to support the Application.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.12
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Licensee agrees that RealBroker may perform periodic
          vulnerability assessments, penetration testing and scanning
          (collectively, “Security Assessment”) of any Application developed by
          Licensee, in accordance with the following principles:
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          (i) Security Assessments will be carried out in such a manner as to
          reasonably avoid disruption in the use of the Application by
          Subscribers. Despite the foregoing, Security Assessments may in rare
          cases cause downtime or other adverse effects on the Application or
          Licensee’s systems;
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          (ii) Where possible and practicable, RealBroker shall provide notice
          to Licensee no less than seven (7) days prior to the Security
          Assessment.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Licensee agrees to cooperate reasonably with such Security
          Assessments. Licensee agrees that RealBroker, its employees, and
          contractors shall not be liable to Licensee, Subscribers or any third
          party for damages or losses arising in connection with Security
          Assessments performed in accordance with this Section 3.12. RealBroker
          conducts such Security Assessments for its own benefit and Licensee
          may not rely on, publicly disclose or promote the successful
          Application passage of such evaluation. If the Application, in whole
          or in part, runs outside of RealBroker’s systems, the Security
          Assessment may include remote application-level security testing of
          the Application, and network-level security testing including a
          vulnerability threat assessment. RealBroker may conduct such testing
          itself or through a third party.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          4. REPRESENTATIONS, WARRANTIES AND COVENANTS
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          4.1
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;To the extent Licensee’s Applications transmit Service Data
          outside a Service, Licensee represents and warrants that Licensee has
          notified all users of such Applications that their Service Data will
          be transmitted outside the Service and that RealBroker is not
          responsible for the privacy, security or integrity of such Service
          Data. Licensee further represents and warrants that to the extent
          Licensee’s Applications store, process or transmit Service Data,
          neither Licensee nor Licensee’s Application will, without appropriate
          prior user consent or except to the extent required by applicable law
          (a) modify the content of Service Data in a manner that adversely
          affects the integrity of Service Data; (b) disclose Service Data to
          any third party; or (c) use Service Data for any purpose other than
          providing the Application functionality to users of such Application.
          Licensee shall maintain and handle all Service Data in accordance with
          privacy and security measures reasonably adequate to preserve the
          confidentiality and security of all Service Data and all applicable
          privacy laws and regulations, and in no event less protective than the
          measures and policies set forth in the&nbsp;
        </span>
        <a
          href='https://www.zendesk.com/company/customers-partners/privacy-policy/'
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#17494d',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Privacy Policy
          </span>
        </a>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          .
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          4.2
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Licensee represents, warrants and covenants that (a) its
          Applications and Licensee Marks, the use of such Applications by its
          users, and the activities with respect to such Applications and
          Licensee Marks undertaken by RealBroker in accordance with the terms
          of this Agreement, do not and will not violate, misappropriate or
          infringe upon the Intellectual Property Rights of any third party; (b)
          Licensee will comply with all applicable local, state, national and
          international laws and regulations, including, without limitation, all
          applicable export control laws, and maintain all licenses, permits and
          other permissions necessary to develop, implement and Publish its
          Applications; (c) its Applications do not and will not contain or
          introduce any Malicious Software into the Service, the API, any
          Service Data, or other data stored or transmitted using the Service;
          (d) its Applications are not designed to or utilized for the purpose
          of spamming any Subscribers, Agents or End-Users; (e) it has all
          right, power and authority to grant the licenses granted to RealBroker
          herein; and (f) it acknowledges RealBroker’s right to charge
          transaction and/or listing fees as provided in Section 8 herein.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          4.3
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Licensee represents, warrants and covenants that it will include
          the mandatory terms of service provisions listed in Section 4.4 below
          (“Mandatory Service Terms”) in the terms of service (“App Terms of
          Service”) that govern use of its Applications by Subscribers. Such
          Mandatory Service Terms are a minimum set of provisions and Licensee
          may have more exhaustive App Terms of Service. Licensee may change the
          term references to match the terms used in its App Terms of Service.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          4.4
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Mandatory Service Terms:
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          (i) The Licensee is the licensor of the Application and RealBroker is
          not a party to the App Terms of Service.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          (ii) Except as otherwise limited by any App Terms of Service imposed
          or required by the Licensee, Licensee grants Subscriber a perpetual,
          worldwide, non-exclusive, non-transferable and non-sublicensable
          license to access, deploy, use and integrate the Application in
          connection with Subscriber’s active Account for a Service.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          (iii) Any information that Licensee collects, stores and processes
          from Subscriber or the systems Subscriber uses to access or deploy the
          Application, including Service Data, will be subject to the App Terms
          of Service, privacy notice, or similar terms that the Licensor
          provides to Subscriber, and will not be subject to the Privacy Policy.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          (iv) Subscriber may not modify, reverse engineer, decompile or
          disassemble the Application in whole or in part, or create any
          derivative works from or sublicense any rights in the Application,
          unless otherwise expressly authorized in writing by Licensor.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          (v) Each of Subscriber and the Licensor shall maintain all rights,
          title and interest in and to all its respective patents, inventions,
          copyrights, trademarks, domain names, trade secrets, know-how and any
          other intellectual property and/or proprietary rights (collectively,
          “IP Rights”). The rights granted to Subscriber to use the Application
          under these App Terms of Service do not convey any additional rights
          in the Application or Licensor Service, or in any IP Rights associated
          therewith. Subject only to limited rights to access and use the
          Application as expressly stated herein, all rights, title and interest
          in and to the Application and all hardware, software and other
          components of or used to provide the Application, including all
          related IP Rights, will remain with and belong exclusively to the
          Licensor. Licensor shall have a royalty-free, worldwide, transferable,
          sub-licensable, irrevocable and perpetual license to incorporate into
          the Application or otherwise use any suggestions, enhancement
          requests, recommendations or other feedback it receives from
          Subscriber.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          -End of Mandatory Service Terms-
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          5. MODIFICATIONS
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Licensee acknowledges and agrees that RealBroker may modify this
          Agreement, the API, and the General API Policies, from time to time (a
          “Modification”). Licensee will be notified of a Modification by one of
          the following methods: (a) through notifications or posts on the
          RealBroker
        </span>
        <a
          href='https://bolt.therealbrokerage.com/'
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            &nbsp;Developer Website
          </span>
        </a>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          ; (b) through notices sent through the Services, RealBroker’s Sites,
          or any other website owned and operated by RealBroker; or (c) through
          a form of direct communication from RealBroker to Licensee. Licensee
          further acknowledges and agrees that such Modifications may be
          implemented at any time and without any notice to Licensee. Licensee
          shall, within thirty (30) days from the date of first notice of any
          Modification(s) (or such shorter period of time specified in the
          notice of the Modification(s)) (the “Conformance Period”) comply with
          such Modification(s) by implementing and using the most current
          version of the API and making any changes to Applications that may be
          required as a result of such Modification(s). Licensee acknowledges
          that a Modification may have an adverse effect on Applications,
          including but not limited to changing the manner in which Applications
          communicate with the API and display or transmit Service Data.
          RealBroker shall have no liability of any kind to Licensee or any user
          of Licensee’s Applications with respect to such Modifications or any
          adverse effects resulting from such Modifications. Licensee’s
          continued access to or use of the Services or API following the
          Conformance Period shall constitute binding acceptance of the
          Modification(s) at issue.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          6. OWNERSHIP
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Subject to the limited licenses expressly provided in this Agreement,
          nothing in this Agreement transfers or assigns to RealBroker any of
          Licensee’s Intellectual Property Rights in its Applications or
          Licensee’s Marks or other technology, and nothing in this Agreement
          transfers or assigns to Licensee any of RealBroker’s Intellectual
          Property Rights in the Services, the API, the RealBroker Marks, or
          RealBroker’s other technology or the respective Intellectual Property
          Rights in any Service Data of RealBroker or its Subscribers, Agents or
          End-Users.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          7. SUPPORT
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          This Agreement does not entitle Licensee to any support for the
          Services or the API, unless Licensee makes separate arrangements with
          RealBroker for such support. Licensee is solely responsible for
          providing all support and technical assistance to End-Users of its
          Applications and Subscribers who access, deploy and/or purchase its
          Applications. Licensee acknowledges and agrees that RealBroker has no
          obligation to provide support or technical assistance to the users of
          Applications and Licensee shall not represent to any such users that
          RealBroker is available to provide such support. Licensee agrees to
          use commercially reasonable efforts to provide reasonable support to
          users of its Applications.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          8. FEES
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If Licensee submits a Paid Application, such application is approved
          by RealBroker, and Licensee follows the required registration and
          listing steps contained herein and as otherwise communicated to
          Licensee, then Licensee may list the Paid Application on the
          Marketplace and charge Subscribers to purchase the Paid Application.
          Fees collected from the sale of Licensee’s Paid Application (“Purchase
          Fees”) must be processed through the Payment Processor account
          Licensee registered for in connection with listing the Paid
          Application. Purchase Fees shall be processed from the Subscriber to
          Licensee through the Payment Processor. Purchase Fees shall be
          transferred to Licensee’s Payment Processor account pursuant to the
          terms of the agreement entered into between Licensee and Payment
          Processor. RealBroker reserves the right to charge fees to Licensee
          related to any aspect of the Marketplace at its sole discretion either
          as indicated to Licensee at time of listing of the Paid Application or
          upon ten (10) days’ notice to Licensee. Continued listing of the Paid
          Application on the Marketplace after notice of RealBroker’s collection
          of such fees, shall be deemed consent to the imposition and collection
          of such charges.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          9. CONFIDENTIALITY
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Licensee may from time to time, gain access to Confidential
          Information. Licensee may use Confidential Information only to the
          extent necessary to exercise its rights under this Agreement. Subject
          to the express permissions set forth herein, Licensee may not disclose
          Confidential Information to a third party without the prior express
          consent of RealBroker, provided in writing or by email. Without
          limiting any other obligation of Licensee under this Agreement,
          Licensee agrees that it will protect Confidential Information from
          unauthorized use, access, or disclosure in the same manner that
          Licensee would use to protect its own confidential and proprietary
          information of a similar nature and in any event with no less than a
          reasonable degree of care.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          10. DISCLAIMER OF WARRANTIES
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          ALL ASPECTS OF THE SERVICES AND THE API, INCLUDING ALL SERVER AND
          NETWORK COMPONENTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
          BASIS, WITHOUT ANY WARRANTIES OF ANY KIND TO THE FULLEST EXTENT
          PERMITTED BY LAW, AND REALBROKER EXPRESSLY DISCLAIMS ANY AND ALL
          WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
          ANY IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
          PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT
          REALBROKER DOES NOT WARRANT THAT THE SERVICE OR API WILL BE
          UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR FREE FROM VIRUSES OR
          OTHER MALICIOUS SOFTWARE, AND NO INFORMATION OR ADVICE OBTAINED BY YOU
          FROM REALBROKER OR THROUGH THE SERVICE SHALL CREATE ANY WARRANTY NOT
          EXPRESSLY STATED IN THIS AGREEMENT.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          11. LIMITATION OF LIABILITY
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          11.1
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;EXCEPT FOR LICENSEE’S INDEMNIFICATION OBLIGATIONS, UNDER NO
          CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT, TORT,
          NEGLIGENCE OR OTHERWISE) WILL EITHER PARTY TO THIS AGREEMENT, OR THEIR
          AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS OR
          LICENSORS BE LIABLE TO THE OTHER PARTY OR ANY THIRD PARTY FOR ANY LOST
          PROFITS, LOST SALES OR BUSINESS, LOST DATA, BUSINESS INTERRUPTION,
          LOSS OF GOODWILL, OR FOR ANY TYPE OF INDIRECT, INCIDENTAL, SPECIAL,
          EXEMPLARY, CONSEQUENTIAL OR PUNITIVE LOSS OR DAMAGES, OR ANY OTHER
          LOSS OR DAMAGES INCURRED BY SUCH PARTY OR THIRD PARTY IN CONNECTION
          WITH THIS AGREEMENT, THE API OR THE SERVICES, REGARDLESS OF WHETHER
          SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE
          FORESEEN SUCH DAMAGES.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          11.2
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT,
          REALBROKER’S AGGREGATE LIABILITY TO LICENSEE OR ANY THIRD PARTY
          ARISING OUT THIS AGREEMENT, SHALL IN NO EVENT EXCEED ONE HUNDRED U.S.
          DOLLARS ($100.00). ANY CLAIM ARISING OUT OF OR RELATING TO THIS
          AGREEMENT MUST BE BROUGHT WITHIN ONE (1) YEAR OF THE FIRST EVENT OR
          OCCURRENCE GIVING RISE TO THE CLAIM.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          11.3
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Some jurisdictions do not allow the exclusion of implied
          warranties or limitation of liability for incidental or consequential
          damages, which means that some of the above limitations may not apply
          to Licensee. IN THESE JURISDICTIONS, REALBROKER’S LIABILITY WILL BE
          LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW. The limitations set
          forth in this Section 11 will survive and apply even if any limited
          remedy specified in this Agreement is found to have failed of its
          essential purpose.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          12. INDEMNIFICATION
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Licensee will indemnify and hold RealBroker harmless against any claim
          brought by a third party against RealBroker arising from or related to
          any breach of an obligation, representation, warranty, covenant or
          other provision of this Agreement by Licensee or any matter which
          Licensee has expressly agreed to be responsible pursuant to this
          Agreement.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          13. TERM AND TERMINATION
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          This Agreement shall commence on the Effective Date and will remain in
          effect until terminated pursuant to this Section 13 (the, “Term”).
          Either party may terminate this Agreement at any time, for any reason,
          or for no reason including, but not limited to, if Licensee violates
          any provision of this Agreement. Any termination of this Agreement
          shall also terminate the licenses granted to Licensee hereunder. Upon
          termination of this Agreement for any reason, Licensee shall cease
          using, and either return to RealBroker, or destroy and remove from all
          computers, hard drives, networks, and other storage media, all copies
          of any materials licensed pursuant to this Agreement and any
          Confidential Information in Licensee’s possession, and shall certify
          to RealBroker that such actions have occurred. Sections 3, 4, 6, 8-16
          and 18 shall survive termination of this Agreement.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          14. ASSIGNMENT; ENTIRE AGREEMENT; REVISIONS
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          14.1
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Licensee may not, directly or indirectly, by operation of law or
          otherwise, assign all or any part of this Agreement or Licensee’s
          rights under this Agreement or delegate performance of Licensee’s
          duties under this Agreement without RealBroker’s prior consent, which
          consent will not be unreasonably withheld. RealBroker may, without
          Licensee’s consent, assign this Agreement to any Affiliate or in
          connection with any merger or change of control of RealBroker or the
          sale of all or substantially all of its assets provided that any such
          successor agrees to fulfill its obligations pursuant to this
          Agreement. Subject to the foregoing restrictions, this Agreement will
          be fully binding upon, inure to the benefit of and be enforceable by
          the parties and their respective successors and assigns.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          14.2
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;This Agreement, together with any other incorporated agreements
          or policies, constitutes the entire agreement among the parties with
          respect to the subject matter of this Agreement. Either party’s
          failure to enforce at any time any provision of this Agreement does
          not constitute a waiver of that provision or of any other provision of
          this Agreement.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          15. SEVERABILITY
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If any provision in this Agreement is held by a court of competent
          jurisdiction to be unenforceable, such provision shall be modified by
          the court and interpreted so as to best accomplish the original
          provision to the fullest extent permitted by law, and the remaining
          provisions of this Agreement shall remain in effect.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          16. RELATIONSHIP OF THE PARTIES
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          The parties are independent contractors. This Agreement does not
          create a partnership, franchise, joint venture, agency, fiduciary or
          employment relationship among the parties.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          17. NOTICE
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          All notices to be provided by RealBroker to Licensee under this
          Agreement may be delivered as described in Section 5 above. Licensee
          must give notice to RealBroker in writing by Courier or U.S. Mail to
          the following address: RealBroker, Inc., Attn: Legal Department, 39899
          Balentine Dr, Ste 200 Newark, CA 94560. All notices shall be deemed to
          have been given immediately upon delivery by electronic mail, or if
          otherwise delivered upon receipt or, if earlier, two (2) business days
          after being deposited in the mail or with a Courier as permitted
          above.
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '18pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          18. GOVERNING LAW
        </span>
      </p>
      <p
        dir='ltr'
        style={{
          lineHeight: '1.7999999999999998',
          marginTop: '0pt',
          marginBottom: '0pt',
        }}
      >
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#17494d',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          This Agreement shall be governed by the laws of the State of New York,
          without reference to conflict of laws principles. Any disputes under
          this Agreement shall be resolved in a court of general jurisdiction in
          New York, NY. You hereby expressly agree to submit to the exclusive
          personal jurisdiction of this jurisdiction for the purpose of
          resolving any dispute relating to this Agreement or access to or use
          of the Services by You, Agents or End Users.
        </span>
      </p>
      <p>
        <br />
      </p>
    </div>
  );
};

export default ApiGenerationTermsOfServices;
