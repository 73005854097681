import { CreateBeneficiaryV2FormData } from '../components/Zen/RevenueShare/V2/CreateBeneficiaryScreenV2';
import { WillBeneficiaryAndTypeResponseTypeEnum } from '../openapi/yenta';

export const isBeneficiaryFormValid = (
  formData: CreateBeneficiaryV2FormData,
) => {
  if (
    formData.beneficiaryType ===
    WillBeneficiaryAndTypeResponseTypeEnum.Individual
  ) {
    return (
      !!formData.firstName &&
      !!formData.lastName &&
      !!formData.address.city &&
      !!formData.address.stateOrProvince &&
      !!formData.address.streetAddress1 &&
      !!formData.address.zipOrPostalCode &&
      !!formData.birthDate &&
      !!formData.phoneNumber
    );
  } else if (
    formData.beneficiaryType === WillBeneficiaryAndTypeResponseTypeEnum.Trust
  ) {
    return !!formData.trustInformation;
  }

  return false;
};
