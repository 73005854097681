import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ZenResourceContainer from '../../../Zen/ZenResourceContainer';
import DetailPageLoader from '../../../DetailPageLoader';
import ZenUplineRow from '../../../upline/ZenUplineRow';
import { RootState } from '../../../../types';

interface RevShareInsightsNetworkUplineAgentsProps {
  agentId: string;
}

const RevShareInsightsNetworkUplineAgents: React.FC<RevShareInsightsNetworkUplineAgentsProps> = ({
  agentId,
}) => {
  const {
    auth: { isAdmin },
    agentDetail: {
      agentUplineInfoById,
      detailResponse: { data },
    },
  } = useSelector((state: RootState) => state);

  const agentUplineResponse = agentUplineInfoById[agentId];
  const actualUplineSponsors = (
    agentUplineResponse?.data?.flattenedSponsors || []
  )
    ?.filter((agent) => !agent?.real)
    ?.sort((a, b) => a?.level! - b?.level!);

  return (
    <div>
      <p className='py-2 px-4 bg-grey-100 text-zen-dark-12 text-xs font-zen-body font-medium'>
        UPLINE AGENTS ({actualUplineSponsors.length || 0})
      </p>
      <ZenResourceContainer
        loading={!!agentUplineResponse?.loading}
        isEmpty={!actualUplineSponsors?.length}
        errorCode={agentUplineResponse?.error}
        LoaderComponent={<DetailPageLoader />}
        resourceName='sponsor agent'
        emptyMessage={`${data?.firstName} ${data?.lastName} has not sponsored any other agents.`}
      >
        <div className='divide-y divide-zen-light-gray-2'>
          {actualUplineSponsors?.map((agent) => (
            <div key={agent.id} className='px-4'>
              {isAdmin ? (
                <Link to={`/people/${agent?.id}`} className='cursor-pointer'>
                  <ZenUplineRow
                    name={`${agent?.firstName} ${agent?.lastName}`}
                    imageUrl={agent?.avatar!}
                    level={agent?.level}
                    email={agent?.emailAddress!}
                    phone={agent?.phoneNumber!}
                    sponsorSplit={agent?.revShareSplit}
                    status={agent?.status!}
                  />
                </Link>
              ) : (
                <ZenUplineRow
                  name={`${agent?.firstName} ${agent?.lastName}`}
                  imageUrl={agent?.avatar!}
                  level={agent?.level}
                  email={agent?.emailAddress!}
                  phone={agent?.phoneNumber!}
                  sponsorSplit={agent?.revShareSplit}
                  status={agent?.status!}
                />
              )}
            </div>
          ))}
        </div>
      </ZenResourceContainer>
    </div>
  );
};

export default RevShareInsightsNetworkUplineAgents;
