import ProgressBar from '../../../ProgressBar';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
} from './ZenCreateTransactionSteps';

const withCreateTransactionProgress = (
  Component: StepByStepComponent<
    CreateTransactionFormState,
    CreateTransactionStepName
  >,
): StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> => (props) => {
  return (
    <>
      <div className='absolute top-0 left-0 w-full'>
        {props.progress.currentIndex > 0 ? (
          <ProgressBar
            completed={props.progress.currentIndex + 1}
            total={props.progress.lastIndex + 1}
          />
        ) : (
          <div className='flex relative w-full bg-green-600 h-8 justify-center items-center'>
            <p className='text-base text-white text-center'>
              Your new transaction is just a few minutes away!
            </p>
          </div>
        )}
      </div>
      <Component {...props} />
    </>
  );
};

export default withCreateTransactionProgress;
