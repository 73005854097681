import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import {
  AppThunk,
  BoardState,
  ErrorCode,
  IPaginateReq,
  SearchParam,
} from '../types';
import ErrorService from '../services/ErrorService';
import {
  BoardControllerApi,
  BoardResponse,
  BoardRequest,
  GenericSearchResponseBoardResponse,
} from '../openapi/yenta';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import {
  BoardsSortByTypeEnum,
  BoardsSortDirectionTypeEnum,
} from '../utils/BoardUtils';
import { showApiErrorModal } from './ErrorSlice';
import {
  showErrorToast,
  showErrorToastForErrorCode,
  showSuccessToast,
} from './ToastNotificationSlice';

export const initialState: BoardState = {
  loadingDetail: false,
  detail: undefined,
  fetchDetailErrorCode: null,
};

const BoardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    changeLoadingDetail(state, action: PayloadAction<boolean>) {
      state.loadingDetail = action.payload;
    },
    saveDetail(state, action: PayloadAction<BoardResponse>) {
      state.detail = action.payload;
      state.fetchDetailErrorCode = null;
    },
    errorFetchingDetail(state, action: PayloadAction<ErrorCode>) {
      state.fetchDetailErrorCode = action.payload;
    },
  },
});

export const {
  changeLoadingDetail,
  saveDetail,
  errorFetchingDetail,
} = BoardSlice.actions;

export const boardDetail = (id: string): AppThunk => async (dispatch) => {
  dispatch(changeLoadingDetail(true));
  try {
    const { data } = await new BoardControllerApi(
      getYentaConfiguration(),
    ).getBoardById(id);
    dispatch(saveDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch board detail', e, {
      board: { id },
    });
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export const createBoard = (
  boardData: BoardRequest,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new BoardControllerApi(
      getYentaConfiguration(),
    ).createBoard(boardData);
    dispatch(saveDetail(data));
    dispatch(showSuccessToast('Board successfully created'));
    return data.id;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create board', e, {
      boardData,
    });
    dispatch(
      showErrorToastForErrorCode(
        "We couldn't create the board",
        ErrorService.getErrorCode(e),
      ),
    );
    return undefined;
  }
};

export const updateBoard = (
  id: string,
  boardData: BoardRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new BoardControllerApi(
      getYentaConfiguration(),
    ).updateBoardById(id, boardData);
    dispatch(saveDetail(data));
    dispatch(showSuccessToast('Board successfully updated'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update board', e, {
      board: { id },
      boardData,
    });
    dispatch(
      showErrorToastForErrorCode(
        "We couldn't update the board",
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const fetchBoards = (
  req: IPaginateReq<BoardResponse>,
  axiosOptions?: AxiosRequestConfig,
): AppThunk<Promise<GenericSearchResponseBoardResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const sortKey = Object.keys(req.sortBy || {})[0];
    const sortType = Object.values(req.sortBy || {})[0];
    const { data } = await new BoardControllerApi(
      getYentaConfiguration(),
    ).searchBoards(
      BoardsSortByTypeEnum[sortKey],
      BoardsSortDirectionTypeEnum[sortType as string],
      req.page,
      req.pageSize,
      req.search,
      (req.filter?.id as SearchParam)?.value,
      req?.filter?.name as string,
      req?.filter?.code as string,
      // @ts-ignore
      (req.filter?.[`administrativeArea:id`] as SearchParam)?.values,
      (req.filter?.status as SearchParam)?.values as any[],
      axiosOptions,
    );
    return data;
  } catch (e) {
    dispatch(
      showErrorToast(
        'An unexpected error occurred.',
        'We were unable to search for an board. Please try again in a few moments or contact support.',
      ),
    );
    ErrorService.notify('Unable to search for boards', e, {
      filter: {
        search: req.search,
        pagination: { page: req.page, pageSize: req.pageSize },
      },
    });
    return undefined;
  }
};

export default BoardSlice.reducer;
