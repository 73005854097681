import React from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ChecklistDefinitionDto } from '../../../openapi/sherlock';
import {
  saveTemplate,
  updateTemplate,
} from '../../../slices/ChecklistDefinitionSlice';
import { AppDispatch } from '../../../types';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSimpleModal from '../../Zen/Modal/ZenSimpleModal';
import ZenButton from '../../Zen/ZenButton';

interface AddTemplateProps {
  checklistDef?: ChecklistDefinitionDto;
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  title: string;
}

const AddTemplate: React.FC<AddTemplateProps> = ({
  checklistDef,
  isOpen,
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: { title: checklistDef ? checklistDef.name : undefined },
  });

  const onSubmit = async (formData: FormData) => {
    if (checklistDef) {
      await dispatch(
        updateTemplate(checklistDef.id!, { name: formData?.title }),
      );
    } else {
      const res = await dispatch(saveTemplate({ name: formData?.title }));
      if (res) {
        history.push(`/resources/templates/${res?.id}`);
      }
    }
    onClose();
  };

  return (
    <ZenSimpleModal
      isOpen={isOpen}
      onClose={onClose}
      title={checklistDef ? 'Update template' : 'Add New Template'}
      size='large'
    >
      <div className='px-12 py-8'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <ZenControlledTextInput<FormData, 'title'>
              name='title'
              control={control}
              label='Template Title'
              placeholder='Type Title'
              isRequired
              rules={{ required: 'Title is required' }}
            />
          </div>
          <div className='my-4'>
            <ZenButton
              label={checklistDef ? 'Update Template' : 'Create Template'}
              type='submit'
              variant='primary'
              isFullWidth
              isSubmitting={isSubmitting}
              isDisabled={isSubmitting}
            />
          </div>
        </form>
      </div>
    </ZenSimpleModal>
  );
};

export default AddTemplate;
