interface LabelValueDisplayProps {
  label: string;
  value: string;
}

const LabelValueDisplay: React.FC<LabelValueDisplayProps> = ({
  label,
  value,
}) => {
  return (
    <div className='flex-row'>
      <p className='text-base text-gray-500 font-primary-regular'>{label}:</p>
      <p className='font-primary-regular text-base text-dark capitalize'>
        {value}
      </p>
    </div>
  );
};

export default LabelValueDisplay;
