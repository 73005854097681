import DocumentMastHead from './DocumentMastHead';
import DocumentTitle from './DocumentTitle';

interface DocumentHeaderProps {
  title: string;
  companyName: string;
  companyAddress: string;
  revision?: number;
  createdAt?: number;
  isPdf?: boolean;
}

const DocumentHeader: React.FC<DocumentHeaderProps> = ({
  companyAddress,
  companyName,
  title,
  createdAt,
  revision,
  isPdf = false,
}) => {
  return (
    <div>
      <DocumentMastHead
        companyName={companyName}
        companyAddress={companyAddress}
        createdAt={createdAt}
        revision={revision}
        isPdf={isPdf}
      />
      <DocumentTitle title={title} />
    </div>
  );
};

export default DocumentHeader;
