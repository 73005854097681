import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faTrashCan,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import {
  AttachedFeeValue,
  AttachedFeeValueFeeTypeEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { toggleAdditionalFee } from '../../../slices/QuickActionSlice';
import { deleteAttachedFee } from '../../../slices/TransactionSlice';
import { RootState } from '../../../types';
import {
  getAttachedFeeRowSelectionLabel,
  getTransactionAttachedFeeByType,
} from '../../../utils/TransactionHelper';
import Avatar from '../../Avatar';
import Button from '../../Button';
import ConfirmationModal from '../../ConfirmationModal';
import AddAttachedFee from '../AddAttachedFee';

interface TransactionAttachedFeeSelectionRowProps {
  transaction: TransactionResponse;
  attachedFeeType: AttachedFeeValueFeeTypeEnum;
}

const TransactionAttachedFeeSelectionRow: React.FC<TransactionAttachedFeeSelectionRowProps> = ({
  transaction,
  attachedFeeType,
}) => {
  const dispatch = useDispatch();
  const {
    quickAction: { showAdditionalFeeCreateModal },
  } = useSelector((state: RootState) => state);
  const [
    deletingAttachedFee,
    setDeletingAttachedFee,
  ] = useState<AttachedFeeValue>();
  const [
    editingAttachedFee,
    setEditingAttachedFee,
  ] = useState<AttachedFeeValue>();

  const attachedFees = getTransactionAttachedFeeByType(
    transaction,
    attachedFeeType,
  );

  return (
    <>
      <p className='text-base font-primary-medium mt-4 mb-2'>
        Additional Fees / Rebates
      </p>
      <div className='flex flex-col space-y-1'>
        <button
          onClick={() => dispatch(toggleAdditionalFee({ isOpen: true }))}
          className='focus:outline-none appearance-none w-full flex border rounded border-gray-300 p-2.5 items-center text-dark space-x-4'
        >
          <FontAwesomeIcon icon={faUser} />
          <span className='flex-grow text-left'>
            Add an additional fee / rebate...
          </span>
          <FontAwesomeIcon icon={faChevronRight} fontSize={14} />
        </button>
        {showAdditionalFeeCreateModal?.isOpen && (
          <AddAttachedFee
            isOpen
            onClose={() => {
              setEditingAttachedFee(undefined);
              dispatch(toggleAdditionalFee({ isOpen: false }));
            }}
            transaction={transaction}
            preselectedFeeType={
              !editingAttachedFee ? attachedFeeType : undefined
            }
            attachedFee={editingAttachedFee}
          />
        )}
      </div>
      <div className='space-y-3 mt-3'>
        {attachedFees?.map((fee) => {
          const name = getAttachedFeeRowSelectionLabel(transaction, fee);

          return (
            <div
              key={fee.id}
              className='w-full flex border rounded border-gray-300 p-2.5 items-center text-dark space-x-4'
            >
              <button
                onClick={() => {
                  setEditingAttachedFee(fee);
                  dispatch(toggleAdditionalFee({ isOpen: true }));
                }}
                className='appearance-none focus:outline-none flex-grow text-left flex items-center'
              >
                <Avatar name={name} size='xxs' />
                <span className='mx-4 flex-grow'>{name}</span>
                <FontAwesomeIcon icon={faPen} />
              </button>
              <FontAwesomeIcon
                icon={faTrashCan}
                className='cursor-pointer'
                onClick={() => setDeletingAttachedFee(fee)}
              />
            </div>
          );
        })}
      </div>
      {deletingAttachedFee && (
        <ConfirmationModal
          isOpen
          onClose={() => setDeletingAttachedFee(undefined)}
          title='Delete'
          variant='error'
        >
          <p className='text-base'>
            Are you sure you want to delete this additional fee / rebate?
          </p>
          <div className='mt-3 space-x-3'>
            <Button
              label='Delete'
              type='primary'
              onClick={() => {
                dispatch(
                  deleteAttachedFee(deletingAttachedFee.id!, transaction.id!),
                );
                setDeletingAttachedFee(undefined);
              }}
            />
            <Button
              label='Cancel'
              type='secondary'
              onClick={() => setDeletingAttachedFee(undefined)}
            />
          </div>
        </ConfirmationModal>
      )}
    </>
  );
};

export default TransactionAttachedFeeSelectionRow;
