import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
  AdministrativeAreaResponseCountryEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
  ApplicationResponse,
  ApplicationResponseTeamRoleEnum,
  LicenseBoardMLSRequest,
  LicenseBoardMLSResponse,
  UpdateApplicationRequest,
  UpdateApplicationRequestTeamRoleEnum,
} from '../../openapi/yenta';
import {
  editApplicationForm,
  getApplicationDriverLicenseImage,
  updateApplicationDriverLicenseImage,
} from '../../slices/ApplicationSlice';
import { countryMapState } from '../../testUtils/OnboardingUtils';
import {
  AppDispatch,
  AsyncSelectOption,
  ISelectOption,
  YesNoType,
} from '../../types';
import { getISelectOptionDefaultValue } from '../../utils/FormUtils';
import { capitalizeEnum, getFileNameFromUrl } from '../../utils/StringUtils';
import {
  FILE_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../utils/Validations';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledDatePickerInput from '../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledFileUploadInput from '../Zen/Input/ZenControlledFileUploadInput';
import ZenControlledMultiSelectInput from '../Zen/Input/ZenControlledMultiSelectInput';
import ZenControlledPhoneNumberInput from '../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledRadioInput from '../Zen/Input/ZenControlledRadioInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenButton from '../Zen/ZenButton';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import { fetchBoards } from '../../slices/BoardSlice';
import { fetchLiteMLSDetails } from '../../slices/MLSSlice';
import { DEFAULT_PAGE_SIZE } from '../../constants/TableConstants';

interface ApplicationEditFormProps {
  isOpen: boolean;
  onClose(): void;
  application: ApplicationResponse;
}

interface ModifiedDoesBusinessInExtended
  extends Omit<LicenseBoardMLSResponse, 'boards' | 'mlses'> {
  boards?: ISelectOption;
  mlses?: ISelectOption;
}
interface FormData extends Omit<ApplicationResponse, 'doesBusinessInExtended'> {
  doesBusinessInExtended: Array<ModifiedDoesBusinessInExtended>;
  addresses?: ISelectOption<AdministrativeAreaResponseStateOrProvinceEnum>[];
  individualAgent?: YesNoType;
  isPreferredTitleVendor?: YesNoType;
  driverLicense?: File[];
  teamLeader?: YesNoType;
}

const ApplicationEditForm: React.FC<ApplicationEditFormProps> = ({
  application,
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [driverLicenseUrl, setDriverLicenseUrl] = useState<
    string | undefined
  >();

  const defaultValues: FormData = useMemo(
    () => ({
      ...application,
      doesBusinessInExtended: application.doesBusinessInExtended?.map(
        (item) => ({
          boards: getISelectOptionDefaultValue(
            item.boards?.[0],
            item.boards?.[0],
          ),
          mlses: getISelectOptionDefaultValue(item.mlses?.[0], item.mlses?.[0]),
          licenseResponse: {
            ...item.licenseResponse,
            active: item.licenseResponse?.active ? YesNoType.YES : YesNoType.NO,
            knownComplaints: item.licenseResponse?.knownComplaints
              ? YesNoType.YES
              : YesNoType.NO,
          } as any,
        }),
      )!,
      individualAgent: application.teamName ? YesNoType.NO : YesNoType.YES,
      teamName: application.teamName,
      teamLeader:
        application.teamRole === ApplicationResponseTeamRoleEnum.Leader
          ? YesNoType.YES
          : YesNoType.NO,
      isPreferredTitleVendor: application.preferredTitleVendor
        ? YesNoType.YES
        : YesNoType.NO,
      preferredTitleVendor: application.preferredTitleVendor,
      firstName: application.firstName,
      lastName: application.lastName,
      addresses: application.doesBusinessInExtended?.map(
        (item) =>
          getISelectOptionDefaultValue(
            item.licenseResponse?.administrativeArea?.stateOrProvince,
          )!,
      ),
    }),

    [application],
  );

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues,
    shouldUnregister: true,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'doesBusinessInExtended',
  });

  const addresses = watch('addresses');

  const onSubmit = async (data: FormData) => {
    const defaultCountry =
      defaultValues.doesBusinessInExtended?.[0]?.licenseResponse
        ?.administrativeArea?.country;
    const doesBusinessInExtended: LicenseBoardMLSRequest[] = (
      data.doesBusinessInExtended || []
    ).map((item, index) => {
      const license = item.licenseResponse || {};
      const board = data.doesBusinessInExtended?.[index]?.boards;
      const mls = data.doesBusinessInExtended?.[index]?.mlses;

      return {
        licenseRequest: {
          number: license.number!,
          expirationDate: license.expirationDate!,
          administrativeAreaRequest: {
            country: (defaultCountry as unknown) as AdministrativeAreaRequestCountryEnum,
            stateOrProvince: (data.addresses?.[index]
              ?.value as unknown) as AdministrativeAreaRequestStateOrProvinceEnum,
          },
          knownComplaints:
            ((license.knownComplaints as unknown) as string) === YesNoType.YES,
          active: ((license.active as unknown) as string) === YesNoType.YES,
        },
        boards: [board?.label!],
        mlses: [mls?.label!],
      };
    });
    const finalData: UpdateApplicationRequest = {
      firstName: data.firstName!,
      lastName: data.lastName!,
      emailAddress: application.emailAddress!,
      teamName:
        data.individualAgent === YesNoType.NO ? data.teamName! : undefined,
      teamLeaderName:
        data.individualAgent === YesNoType.NO ? data.teamLeaderName : undefined,
      teamRole:
        data.individualAgent === YesNoType.NO
          ? data.teamLeader === YesNoType.YES
            ? UpdateApplicationRequestTeamRoleEnum.Leader
            : UpdateApplicationRequestTeamRoleEnum.Member
          : undefined,
      preferredTitleVendor:
        data.isPreferredTitleVendor === YesNoType.YES
          ? data.preferredTitleVendor!
          : undefined,
      birthDate: data.birthDate!,
      currentBrokerage: data.currentBrokerage!,
      estimatedSales: data.estimatedSales!,
      phoneNumber: data.phoneNumber!,
      doesBusinessInExtended: doesBusinessInExtended,
      commercialAgent: !!application.commercialAgent,
    };

    if (data?.driverLicense) {
      await dispatch(
        updateApplicationDriverLicenseImage(
          application?.id!,
          data.driverLicense[0],
        ),
      );
    }

    await dispatch(editApplicationForm(application.id!, finalData));
    onClose();
  };

  const [addressLength, setAddressLength] = useState(addresses?.length || 0);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    const appendBusiness = (
      state: AdministrativeAreaResponseStateOrProvinceEnum,
    ) => {
      append({
        licenseResponse: {
          active: (null as unknown) as undefined,
          administrativeArea: {
            agentCanTransferLicense: (null as unknown) as undefined,
            country:
              defaultValues.doesBusinessInExtended[0]?.licenseResponse
                ?.administrativeArea?.country ||
              AdministrativeAreaResponseCountryEnum.UnitedStates,
            stateOrProvince: state,
          },
          expirationDate: (null as unknown) as undefined,
          knownComplaints: (null as unknown) as undefined,
          number: '',
        },
        boards: (null as unknown) as undefined,
        mlses: (null as unknown) as undefined,
      });
    };

    addresses?.forEach((state) => {
      const stateExists: boolean = fields.some(
        (item) =>
          item.licenseResponse?.administrativeArea?.stateOrProvince ===
          state?.value,
      );
      if (!stateExists) {
        if (addressLength < addresses.length) {
          appendBusiness(addresses[addresses.length - 1].value);
          setAddressLength(addresses.length);
        } else if (addressLength > fields.length) {
          setValue(
            'addresses',
            addresses.filter((ele) => {
              return ele.value !== state.value;
            }),
          );
          setAddressLength(addresses.length - 1);
        }
      }
    });

    fields.forEach((state, index) => {
      const stateExists = addresses?.some(
        (item) =>
          item.value ===
          state.licenseResponse?.administrativeArea?.stateOrProvince,
      );
      if (addressLength < fields.length && !stateExists) {
        remove(index);
        setAddressLength(addresses?.length ? addresses.length - 1 : 0);
      }
    });
    setAddressLength(addresses?.length || 0);
  }, [
    append,
    application.doesBusinessInExtended,
    addresses,
    fields,
    defaultValues,
    setValue,
    addressLength,
    remove,
  ]);

  const defaultCountry: AdministrativeAreaResponseCountryEnum =
    application.doesBusinessInExtended && application.doesBusinessInExtended[0]
      ? application?.doesBusinessInExtended?.[0]?.licenseResponse
          ?.administrativeArea?.country!
      : AdministrativeAreaResponseCountryEnum.UnitedStates;

  const getDriverLicense = useCallback(async () => {
    const licenseImage = await dispatch(
      getApplicationDriverLicenseImage(application?.id!),
    );
    if (licenseImage) {
      setDriverLicenseUrl(licenseImage);
    }
  }, [application?.id, dispatch]);

  useEffect(() => {
    if (application?.driverLicenseImagePath) {
      getDriverLicense();
    }
  }, [application?.driverLicenseImagePath, getDriverLicense]);

  const driverLicenseName =
    driverLicenseUrl && getFileNameFromUrl(driverLicenseUrl) !== 'null'
      ? getFileNameFromUrl(driverLicenseUrl)
      : '';

  const teamLeader = watch('teamLeader');

  return (
    <ZenSidebarModal
      title='Editing: Application Form'
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='edit-application-form'
      >
        <div className='p-4 pb-20'>
          <div>
            <ZenControlledTextInput
              control={control}
              label='First Name'
              name='firstName'
              placeholder='E.g. John'
              rules={{ required: 'First Name is required' }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Last Name'
              name='lastName'
              placeholder='E.g. Doe'
              rules={{ required: 'Last Name is required' }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledPhoneNumberInput
              control={control}
              label='Phone Number'
              name='phoneNumber'
              placeholder='+1 (702) 123-4567'
              rules={{
                required: 'Please enter your phone number',
                ...PHONE_NUMBER_VALIDATIONS,
              }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledDatePickerInput
              name='birthDate'
              control={control}
              label='Date of Birth'
              placeholder='Date of Birth'
              rules={{
                required: 'Please enter your date of birth',
              }}
            />
          </div>

          <div className='mt-5'>
            <ZenControlledFileUploadInput
              name='driverLicense'
              control={control}
              label="Driver's License Image"
              placeholder={driverLicenseName || 'E.g. Driver License.pdf'}
              accept='application/pdf, image/*'
              rightAction={
                driverLicenseName
                  ? {
                      text: 'View License Image',
                      onClick() {
                        window.open(driverLicenseUrl, '_blank');
                      },
                    }
                  : undefined
              }
              rules={{
                ...FILE_VALIDATIONS,
              }}
            />
          </div>

          <div className='mt-5'>
            <ZenControlledMultiSelectInput
              name='addresses'
              label='In which state / province do you operate?'
              rules={{
                required: 'Please select a state / province',
                validate: (value) =>
                  !value?.length
                    ? 'Please select a state / province'
                    : undefined,
              }}
              placeholder='Choose state / province'
              control={control}
              options={countryMapState[defaultCountry]}
            />
          </div>
          {fields?.map((field, index) => {
            const mls = watch(`doesBusinessInExtended.${index}.mlses`);
            const board = watch(`doesBusinessInExtended.${index}.boards`);

            return (
              <div key={field?.id} className='border rounded mt-5 p-2'>
                <div className='flex flex-row justify-between'>
                  <p>
                    {field?.licenseResponse?.administrativeArea
                      ?.stateOrProvince! &&
                      capitalizeEnum(
                        field?.licenseResponse?.administrativeArea
                          ?.stateOrProvince!,
                      )}
                  </p>
                  <FontAwesomeIcon
                    icon={faXmark}
                    className='text-grey-500 cursor-pointer text-lg p-1 text-center'
                    onClick={() => remove(index)}
                  />
                </div>

                <div className='mt-5'>
                  <ZenControlledAsyncSelectInput
                    control={control}
                    label='What is the name of the MLS you are a member of or will be joining?'
                    name={`doesBusinessInExtended.${index}.mlses`}
                    fetchData={async (search, page) => {
                      const res = await dispatch(
                        fetchLiteMLSDetails(
                          page,
                          DEFAULT_PAGE_SIZE,
                          'ASC',
                          search,
                        ),
                      );
                      const options: AsyncSelectOption[] = (
                        res?.results || []
                      )?.map((resp) => ({
                        value: `${resp?.id}`,
                        label: `${resp?.name}`,
                      }));
                      return options;
                    }}
                    rules={{
                      required: 'Please select the name of the MLS',
                    }}
                  />
                  <label className='flex items-center justify-start space-x-2 mt-3'>
                    <input
                      type='checkbox'
                      className='rounded-full border-none ring-1 ring-gray-200 focus:outline-none focus:ring-0 h-4 w-4 text-primary'
                      value='I do not belong to a MLS.'
                      onChange={({ target: { checked, value } }) => {
                        setValue(
                          `doesBusinessInExtended.${index}.mlses`,
                          checked ? { label: value, value } : undefined,
                        );
                      }}
                      checked={mls?.label === 'I do not belong to a MLS.'}
                    />
                    <p>I do not belong to a MLS.</p>
                  </label>
                </div>
                <div className='mt-5'>
                  <ZenControlledAsyncSelectInput
                    control={control}
                    label='What is the name of the local Board of Realtors (Associations of Realtors) you are a member of or will be joining?'
                    name={`doesBusinessInExtended.${index}.boards`}
                    fetchData={async (search, page) => {
                      const res = await dispatch(
                        fetchBoards({
                          search,
                          page: page ?? 0,
                          pageSize: 20,
                          sortBy: { name: 'asc' },
                        }),
                      );

                      const options: AsyncSelectOption[] = (
                        res?.results || []
                      ).map((resp) => ({
                        value: `${resp?.id}`,
                        label: `${resp?.name}`,
                      }));
                      return options;
                    }}
                    rules={{
                      required: 'Please select the name of the Board',
                    }}
                  />
                  <label className='flex items-center justify-start space-x-2 mt-3'>
                    <input
                      type='checkbox'
                      className='rounded-full border-none ring-1 ring-gray-200 focus:outline-none focus:ring-0 h-4 w-4 text-primary'
                      value='I do not belong to a Board.'
                      onChange={({ target: { checked, value } }) => {
                        setValue(
                          `doesBusinessInExtended.${index}.boards`,
                          checked ? { label: value, value } : undefined,
                        );
                      }}
                      checked={board?.label === 'I do not belong to a Board.'}
                    />
                    <p>I do not belong to a Board.</p>
                  </label>
                </div>

                <div className='mt-2'>
                  <ZenControlledTextInput
                    control={control}
                    label='Please enter the License number below'
                    name={`doesBusinessInExtended.${index}.licenseResponse.number`}
                    placeholder='123456789'
                    rules={{
                      required: 'Please enter your license number',
                    }}
                  />
                </div>
                <div className='mt-5'>
                  <ZenControlledRadioInput
                    name={`doesBusinessInExtended.${index}.licenseResponse.active`}
                    label='Is your license active?'
                    rules={{
                      required: 'Please choose one',
                    }}
                    options={[
                      {
                        label: 'Yes',
                        value: YesNoType.YES,
                      },
                      {
                        label: 'No',
                        value: YesNoType.NO,
                      },
                    ]}
                    control={control}
                  />
                </div>
                <div className='mt-5'>
                  <ZenControlledDatePickerInput
                    name={`doesBusinessInExtended.${index}.licenseResponse.expirationDate`}
                    control={control}
                    label='License Expiration date'
                    placeholder='2022'
                    rules={{
                      required: 'Please choose a date.',
                    }}
                    defaultValue={field?.licenseResponse?.expirationDate}
                  />
                </div>
                <div className='mt-5'>
                  <ZenControlledRadioInput
                    name={`doesBusinessInExtended.${index}.licenseResponse.knownComplaints`}
                    label='Registered Complaint Filed'
                    rules={{
                      required: 'Please choose one',
                    }}
                    options={[
                      {
                        label: 'Yes',
                        value: YesNoType.YES,
                      },
                      {
                        label: 'No',
                        value: YesNoType.NO,
                      },
                    ]}
                    control={control}
                  />
                </div>
                <div className='hidden'>
                  <ZenControlledTextInput
                    name={`doesBusinessInExtended.${index}.licenseResponse.administrativeArea.stateOrProvince`}
                    control={control}
                    label='State / province'
                    placeholder='State / province'
                    defaultValue={
                      field?.licenseResponse?.administrativeArea
                        ?.stateOrProvince
                    }
                  />
                </div>
              </div>
            );
          })}

          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Current Brokerage Name'
              name='currentBrokerage'
              rules={{
                required: "Please enter your current brokerage's name",
              }}
            />
            <label className='flex items-center justify-start space-x-2 mt-3'>
              <input
                type='checkbox'
                className='rounded-full border-none ring-1 ring-gray-200 focus:outline-none focus:ring-0 h-4 w-4 text-primary'
                value='I do not currently belong to a brokerage.'
                onChange={({ target: { checked, value } }) => {
                  setValue('currentBrokerage', checked ? value : '');
                }}
                checked={
                  watch('currentBrokerage') ===
                  'I do not currently belong to a brokerage.'
                }
              />
              <p>I do not currently belong to a brokerage.</p>
            </label>
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Estimated Sales Volume in last 12 months'
              name='estimatedSales'
              rules={{
                required: 'Please enter the Estimated Sales Amount.',
              }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledRadioInput
              name='individualAgent'
              label='Individual agent that will be on our $12,000 cap plan'
              rules={{
                required: 'Please choose one',
              }}
              options={[
                {
                  label: 'Yes',
                  value: YesNoType.YES,
                },
                {
                  label: 'No',
                  value: YesNoType.NO,
                },
              ]}
              control={control}
            />
          </div>
          {watch('individualAgent') === YesNoType.NO && (
            <>
              <div className='mt-5'>
                <ZenControlledRadioInput
                  name='teamLeader'
                  label='Is a team leader or team member?'
                  rules={{
                    required: 'Please choose one',
                  }}
                  isRequired
                  options={[
                    {
                      label: 'Team Leader',
                      value: YesNoType.YES,
                    },
                    {
                      label: 'Team Member',
                      value: YesNoType.NO,
                    },
                  ]}
                  control={control}
                />
              </div>
              {teamLeader === YesNoType.NO && (
                <div className='mt-5'>
                  <ZenControlledTextInput
                    control={control}
                    label='Team Leader Name'
                    name='teamLeaderName'
                    rules={{
                      required: 'Please enter the team leader name',
                    }}
                    isRequired
                  />
                </div>
              )}
              <div className={teamLeader ? 'mt-5' : 'mt-3'}>
                <ZenControlledTextInput
                  control={control}
                  label='Team Name'
                  name='teamName'
                  rules={{
                    required: 'Please enter your team name',
                  }}
                  isRequired
                />
              </div>
            </>
          )}
          <div className='mt-5'>
            <ZenControlledRadioInput
              name='isPreferredTitleVendor'
              label='Do you have a preferred title vendor that you currently work with?'
              rules={{
                required: 'Please choose one',
              }}
              options={[
                {
                  label: 'Yes',
                  value: YesNoType.YES,
                },
                {
                  label: 'No',
                  value: YesNoType.NO,
                },
              ]}
              control={control}
            />
          </div>
          {watch('isPreferredTitleVendor') === YesNoType.YES && (
            <div className='mt-5'>
              <ZenControlledTextInput
                control={control}
                label='Preferred Title Vendor'
                name='preferredTitleVendor'
                rules={{
                  required: 'Please enter your preferred title vendor',
                }}
              />
            </div>
          )}
        </div>
        <div className='p-4 bg-white border-t border-gray-200 sticky bottom-0 space-x-5'>
          <div className='flex flex-row space-x-3'>
            <ZenButton
              type='submit'
              label='Save'
              variant='primary'
              isDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              isFullWidth
            />
            <ZenButton
              type='button'
              onClick={() => {
                reset();
                onClose();
              }}
              label='Cancel'
              variant='secondary'
              isFullWidth
            />
          </div>
        </div>
      </form>
    </ZenSidebarModal>
  );
};

export default ApplicationEditForm;
