import { useState } from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';
import ZenInvoiceCancelFormSidebarModal from '../forms/ZenInvoiceCancelFormSidebarModal';

interface ZenInvoiceCancelProps {}

const ZenInvoiceCancel: React.FC<ZenInvoiceCancelProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <ZenResourceLinkCard
        title='Cancel Invoice'
        onClick={() => setIsOpen(true)}
        icon={regular('file-lines')}
        iconSize='2x'
      />

      <ZenInvoiceCancelFormSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenInvoiceCancel;
