import { AgentWebsiteResponse } from '../../../../openapi/yenta';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import ZenContentRow from './ZenContentRow';

interface ZenAgentWebsiteAboutContentProps {
  agentWebsite: AgentWebsiteResponse | undefined;
}

const ZenAgentWebsiteAboutContent: React.FC<ZenAgentWebsiteAboutContentProps> = ({
  agentWebsite,
}) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-8 p-4'>
      <ZenContentRow
        title='I Work With:'
        content={agentWebsite?.agentResponse?.competencies
          ?.map((c) => capitalizeEnum(c))
          .join(', ')}
        defaultContent='None Selected'
      />
      <ZenContentRow
        title='Service Area ZIP Codes'
        content={agentWebsite?.agentResponse?.serviceAreaZipcodes}
        defaultContent='Service AreaZIP Codes'
      />
    </div>
  );
};

export default ZenAgentWebsiteAboutContent;
