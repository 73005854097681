import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { keys, values } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import ZenSidebarModalActionFooter from '../components/SidebarModal/ZenSideBarModalActionFooter';
import ZenControlledDatePickerInput from '../components/Zen/Input/ZenControlledDatePickerInput';
import ZenControlledMultiSelectInput from '../components/Zen/Input/ZenControlledMultiSelectInput';
import ZenControlledPhoneNumberInput from '../components/Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledSelectInput from '../components/Zen/Input/ZenControlledSelectInput';
import ZenControlledTextAreaInput from '../components/Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenSidebarModal from '../components/Zen/ZenSidebarModal';
import { ADDRESS_PLACEHOLDER } from '../constants/PlaceholderConstants';
import {
  UpdateNotificationPreferencesDtoAddChannelsEnum,
  UpdateNotificationPreferencesDtoRemoveChannelsEnum,
  UserControllerApi,
} from '../openapi/hermes';
import {
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaResponseCountryEnum,
  AgentResponse,
  AgentUpdateRequestCompetenciesEnum,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import {
  fetchDriverLicense,
  getGlobalNotificationStatus,
  updateAgentBusinessEmail,
  updateAgentDetail,
  updateGlobalNotificationSetting,
  uploadDriverLicense,
} from '../slices/AgentSlice';
import {
  AgentAddressTypeEnum,
  AppDispatch,
  ISelectOption,
  LanguageType,
  RootState,
  YesNoType,
} from '../types';
import { isCanadianUser, isSlugAvailable } from '../utils/AgentHelper';
import { getUpdateAgentDetailObject } from '../utils/ApiObjectUtils';
import { parseRealUsername } from '../utils/AuthUtils';
import { getHermesConfiguration } from '../utils/OpenapiConfigurationUtils';
import { capitalizeEnum, getFileNameFromUrl } from '../utils/StringUtils';
import {
  EMAIL_VALIDATIONS,
  FILE_VALIDATIONS,
  getPostalCodeValidation,
  PHONE_NUMBER_VALIDATIONS,
  URL_VALIDATIONS,
} from '../utils/Validations';
import { parseUrl } from '../utils/UrlUtils';
import ZenControlledFileUploadInput from '../components/Zen/Input/ZenControlledFileUploadInput';
import { getISelectOptionDefaultValue } from '../utils/FormUtils';
import ZenControlledStateOrProvince from '../components/Zen/Input/ZenControlledStateOrProvince';
import { cn } from '../utils/classUtils';

interface ZenBasicInformationFormProps {
  isOpen: boolean;
  onClose(): void;
  agent: AgentResponse;
}

interface Address {
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  type: ISelectOption;
  country: ISelectOption;
  stateOrProvince: AddressRequestStateOrProvinceEnum;
  zipOrPostalCode: string;
}

interface BasicInfoData {
  firstName: string;
  middleName: string;
  lastName: string;
  title: string;
  addresses: Address[];
  driverLicense: string;
  slug: string;
  facebookURL: string;
  clubhouseURL: string;
  twitterURL: string;
  instagramURL: string;
  youtubeURL: string;
  personalWebsiteURL: string;
  linkedInURL: string;
  yelpURL: string;
  zillowURL: string;
  birthDate: string;
  phoneNumber: string;
  emailAddress: string;
  personalPhoneNumber: string;
  competencies: ISelectOption[];
  serviceAreaZipcodes: string;
  about: string;
  languages: ISelectOption[];
  googleBusinessProfileURL: string;
  receivesTransactionEmailNotifications: ISelectOption;
  receivesTransactionPushNotifications: ISelectOption;
}

const ZenBasicInformationForm: React.FC<ZenBasicInformationFormProps> = ({
  isOpen,
  onClose,
  agent,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const { userDetail, isAdmin } = useSelector((state: RootState) => state.auth);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<BasicInfoData>({
    shouldUnregister: true,
    defaultValues: {
      addresses: agent.addresses?.map((address) => ({
        type: getISelectOptionDefaultValue(address?.type),
        city: address?.city || '',
        zipOrPostalCode: address?.zipOrPostalCode || '',
        streetAddress1: address?.streetAddress1 || '',
        streetAddress2: address?.streetAddress2 || '',
        stateOrProvince: (address?.stateOrProvince as unknown) as AddressRequestStateOrProvinceEnum,
        country: getISelectOptionDefaultValue(address?.country),
      })),
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addresses',
  });

  const getChannelType = () => {
    const emailNotifications = (watch(
      'receivesTransactionEmailNotifications',
    ) as unknown) as string;
    const pushNotifications = (watch(
      'receivesTransactionPushNotifications',
    ) as unknown) as string;

    let addChannels = [];
    let removeChannels = [];
    if (emailNotifications) {
      addChannels.push(UpdateNotificationPreferencesDtoAddChannelsEnum.Email);
    } else {
      removeChannels.push(
        UpdateNotificationPreferencesDtoRemoveChannelsEnum.Email,
      );
    }

    if (pushNotifications) {
      addChannels.push(UpdateNotificationPreferencesDtoAddChannelsEnum.Push);
    } else {
      removeChannels.push(
        UpdateNotificationPreferencesDtoRemoveChannelsEnum.Push,
      );
    }

    return { addChannels, removeChannels };
  };

  const fetchNotificationsStatus = useCallback(async () => {
    const realUsername = parseRealUsername(agent?.joinRealEmail);
    if (!!realUsername) {
      await dispatch(getGlobalNotificationStatus(realUsername));
    }
  }, [dispatch, agent]);

  useEffect(() => {
    fetchNotificationsStatus();
  }, [fetchNotificationsStatus]);

  const onSubmit = async (data: BasicInfoData) => {
    const didEmailChange = agent.emailAddress !== data.emailAddress;

    if (data.driverLicense) {
      await dispatch(uploadDriverLicense(agent?.id!, data.driverLicense[0]));
    }

    if (didEmailChange) {
      await dispatch(
        updateAgentBusinessEmail(agent.id!, {
          newBusinessEmailRequest: data.emailAddress,
        }),
      );
    }
    const { addChannels, removeChannels } = getChannelType();
    await Promise.all([
      dispatch(
        updateAgentDetail(
          agent.id!,
          getUpdateAgentDetailObject(
            agent,
            {
              ...data,
              firstName: data.firstName,
              middleName: data.middleName,
              lastName: data.lastName,
              phoneNumber: data.phoneNumber || null,
              clubhouseURL: parseUrl(data.clubhouseURL) || null,
              facebookURL: parseUrl(data.facebookURL) || null,
              googleBusinessProfileURL:
                parseUrl(data.googleBusinessProfileURL) || null,
              instagramURL: parseUrl(data.instagramURL) || null,
              linkedInURL: parseUrl(data.linkedInURL) || null,
              personalWebsiteURL: parseUrl(data.personalWebsiteURL) || null,
              twitterURL: parseUrl(data.twitterURL) || null,
              yelpURL: parseUrl(data.yelpURL) || null,
              youtubeURL: parseUrl(data.youtubeURL) || null,
              zillowURL: parseUrl(data.zillowURL) || null,
              serviceAreaZipcodes: data.serviceAreaZipcodes || null,
              languages: values(data.languages).map(
                (language) => language.value,
              ),
              personalPhoneNumber: data.personalPhoneNumber || null,
              competencies: values(data.competencies || []).map(
                (competencies) => competencies.value,
              ),
              slug: data.slug || null,
              addressRequests: data.addresses?.map((addr) => ({
                ...addr,
                type: addr.type?.value,
                country: addr.country?.value,
              })),
              receivesTransactionEmailNotifications:
                data.receivesTransactionEmailNotifications.value ===
                YesNoType.YES,

              receivesTransactionPushNotifications:
                data.receivesTransactionPushNotifications.value ===
                YesNoType.YES,
            },
            { omitAddressRequest: true, includeAddressRequests: true },
          ),
        ),
      ),
      dispatch(
        updateGlobalNotificationSetting(
          parseRealUsername(agent?.joinRealEmail!)!,
          {
            addChannels,
            removeChannels,
          },
        ),
      ),
    ]);
    onClose();
  };

  const [driverLicenseUrl, setDriverLicenseUrl] = useState<
    string | undefined
  >();

  const getDriverLicense = useCallback(async () => {
    const data = await dispatch(fetchDriverLicense(agent?.id!));
    setDriverLicenseUrl(data);
  }, [agent?.id, dispatch]);

  useEffect(() => {
    getDriverLicense();
  }, [getDriverLicense]);

  const driverLicenseName =
    driverLicenseUrl && getFileNameFromUrl(driverLicenseUrl) !== 'null'
      ? getFileNameFromUrl(driverLicenseUrl)
      : '';

  const isAddAnotherAddressDisabled = fields.length >= 3;

  return (
    <ZenSidebarModal
      title='Edit Basic Information'
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='edit-basic-information-form'
      >
        <div className='p-4'>
          <div>
            <ZenControlledTextInput<BasicInfoData, 'firstName'>
              control={control}
              label='First Name'
              name='firstName'
              placeholder='First Name'
              defaultValue={agent.firstName}
              rules={{ required: 'First Name is required' }}
              isRequired
              readOnly={!isAdmin}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'middleName'>
              control={control}
              label='Middle Name'
              name='middleName'
              placeholder='Middle Name'
              defaultValue={agent.middleName}
              readOnly={!isAdmin}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'lastName'>
              control={control}
              label='Last Name'
              name='lastName'
              placeholder='Last Name'
              defaultValue={agent.lastName}
              rules={{ required: 'Last Name is required' }}
              isRequired
              readOnly={!isAdmin}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledDatePickerInput
              control={control}
              label='Birthday Date'
              name='birthDate'
              icon={
                <FontAwesomeIcon
                  icon={faCalendar}
                  className='text-primary-blue ml-[1px] pr-0.5'
                  size='lg'
                />
              }
              defaultValue={agent.birthDate}
              placeholder='Birthday Date'
              rules={{ required: 'Required' }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledFileUploadInput<BasicInfoData, 'driverLicense'>
              name='driverLicense'
              control={control}
              label="Driver's License Image"
              placeholder={driverLicenseName || 'E.g. Driver License.pdf'}
              accept='application/pdf, image/*'
              rightAction={
                driverLicenseName
                  ? {
                      text: 'View License Image',
                      onClick() {
                        window.open(driverLicenseUrl, '_blank');
                      },
                    }
                  : undefined
              }
              rules={{
                ...FILE_VALIDATIONS,
              }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'title'>
              control={control}
              label='Title'
              name='title'
              placeholder='Your Title'
              defaultValue={agent.title}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledPhoneNumberInput<BasicInfoData, 'phoneNumber'>
              control={control}
              label='Phone Number'
              name='phoneNumber'
              placeholder='+1 (702) 123-4567'
              defaultValue={agent.phoneNumber}
              rules={{
                required: 'Please enter the phone number',
                ...PHONE_NUMBER_VALIDATIONS,
              }}
              shouldUnregister={false}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'emailAddress'>
              control={control}
              label='Email Address'
              name='emailAddress'
              placeholder='Email Address'
              defaultValue={agent.emailAddress}
              subLabel={`This email will appear on all ${
                isCanadianUser(agent.accountCountry!)
                  ? 'Trade Record Sheets'
                  : 'Commission Documents and Invoices'
              } . To change the email you use to login, please go to the Security section in your profile page.`}
              rules={{
                ...EMAIL_VALIDATIONS,
                required: 'Required',
                validate: async (value) => {
                  if (agent.emailAddress !== value) {
                    try {
                      const {
                        data: emailAddress,
                      } = await new UserControllerApi(
                        getHermesConfiguration(),
                      ).validateEmail(value);
                      return !emailAddress.available
                        ? emailAddress?.reason
                        : undefined;
                    } catch (e) {
                      ErrorService.notify(
                        'Unable to verify if the business email is valid or not',
                        e,
                        {
                          agent: { id: agent.id },
                        },
                      );
                      return "Couldn't verify the email. Please try again later.";
                    }
                  }
                  return;
                },
              }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledPhoneNumberInput<BasicInfoData, 'personalPhoneNumber'>
              control={control}
              label='Personal Phone Number'
              name='personalPhoneNumber'
              placeholder='+1 (702) 123-4567'
              defaultValue={agent.personalPhoneNumber}
              rules={{
                required: 'Please enter the phone number',
                ...PHONE_NUMBER_VALIDATIONS,
              }}
              shouldUnregister={false}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledMultiSelectInput<BasicInfoData, 'competencies'>
              name='competencies'
              control={control}
              label='Works with'
              //@ts-ignore
              defaultValue={agent.competencies?.map((c) => ({
                value: c,
                label: capitalizeEnum(c),
              }))}
              placeholder='Works with'
              options={[
                ...values(AgentUpdateRequestCompetenciesEnum).map(
                  (competencies) => ({
                    value: competencies,
                    label: capitalizeEnum(competencies),
                  }),
                ),
              ]}
              closeMenuOnSelect={false}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextAreaInput<BasicInfoData, 'serviceAreaZipcodes'>
              control={control}
              label='Service area postal codes'
              name='serviceAreaZipcodes'
              rows={3}
              defaultValue={agent.serviceAreaZipcodes}
            />
          </div>
          <div>
            {fields.map((field, index) => {
              const [countrySelected, addresses] = watch([
                `addresses.${index}.country`,
                'addresses',
              ]);
              const showDeleteBtn = index !== 0;
              const otherAddressTypes = addresses
                ?.filter((_, i) => i !== index)
                .map((f) => f.type?.value);

              return (
                <div key={field.id} className='relative'>
                  {showDeleteBtn && (
                    <button
                      className='mt-1 absolute top-0 right-3'
                      onClick={() => remove(index)}
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className='text-zen-danger'
                      />
                    </button>
                  )}
                  <div className='mt-5'>
                    <ZenControlledSelectInput<
                      BasicInfoData,
                      `addresses.${number}.type`
                    >
                      control={control}
                      label='Address Type'
                      name={`addresses.${index}.type`}
                      options={values(AgentAddressTypeEnum).map((type) => ({
                        value: type,
                        label: capitalizeEnum(type),
                      }))}
                      rules={{
                        required: 'Required',
                        validate: (val) => {
                          if (
                            val?.value &&
                            otherAddressTypes?.includes(val.value)
                          ) {
                            return "Can't have multiple addresses of the same type";
                          }
                          return undefined;
                        },
                      }}
                      isRequired
                    />
                  </div>
                  <div className='mt-5'>
                    <ZenControlledSelectInput<
                      BasicInfoData,
                      `addresses.${number}.country`
                    >
                      name={`addresses.${index}.country`}
                      control={control}
                      label='Country'
                      placeholder='Country'
                      options={values(
                        AdministrativeAreaResponseCountryEnum,
                      ).map((state) => ({
                        value: state,
                        label: capitalizeEnum(state),
                      }))}
                      rules={{
                        required: 'Please select country',
                      }}
                      isRequired
                    />
                  </div>
                  <div className='mt-5'>
                    <ZenControlledTextInput<
                      BasicInfoData,
                      `addresses.${number}.streetAddress1`
                    >
                      control={control}
                      label='Street Address Line 1'
                      name={`addresses.${index}.streetAddress1`}
                      placeholder={ADDRESS_PLACEHOLDER}
                      rules={{
                        required: 'Required',
                      }}
                      isRequired
                    />
                  </div>
                  <div className='mt-5'>
                    <ZenControlledTextInput<
                      BasicInfoData,
                      `addresses.${number}.streetAddress2`
                    >
                      control={control}
                      label='Street Address Line 2'
                      name={`addresses.${index}.streetAddress2`}
                      placeholder={ADDRESS_PLACEHOLDER}
                    />
                  </div>
                  <div className='mt-5'>
                    <ZenControlledTextInput<
                      BasicInfoData,
                      `addresses.${number}.city`
                    >
                      control={control}
                      label='City'
                      name={`addresses.${index}.city`}
                      placeholder='City'
                      rules={{
                        required: 'Required',
                      }}
                      isRequired
                    />
                  </div>

                  <div className='mt-5'>
                    <ZenControlledStateOrProvince<
                      BasicInfoData,
                      `addresses.${number}.stateOrProvince`
                    >
                      name={`addresses.${index}.stateOrProvince`}
                      control={control}
                      selectedCountry={
                        countrySelected?.value as AdministrativeAreaRequestCountryEnum
                      }
                      setValue={setValue}
                      rules={{
                        required: 'Please select state or province',
                      }}
                      isRequired
                    />
                  </div>
                  <div className='mt-5'>
                    <ZenControlledTextInput<
                      BasicInfoData,
                      `addresses.${number}.zipOrPostalCode`
                    >
                      control={control}
                      label='Postal Code'
                      name={`addresses.${index}.zipOrPostalCode`}
                      placeholder='Postal Code'
                      rules={{
                        required: 'Required',
                        ...getPostalCodeValidation(
                          countrySelected?.value as AddressRequestCountryEnum,
                        ),
                      }}
                      isRequired
                    />
                  </div>
                </div>
              );
            })}
            <button
              className='mt-2 flex items-center space-x-2'
              type='button'
              disabled={isAddAnotherAddressDisabled}
              onClick={() =>
                append({
                  country: getISelectOptionDefaultValue(
                    (userDetail?.accountCountry as unknown) as AddressRequestCountryEnum,
                  ),
                  type: undefined,
                  city: undefined,
                  stateOrProvince: undefined,
                  streetAddress1: undefined,
                  streetAddress2: undefined,
                  zipOrPostalCode: undefined,
                })
              }
            >
              <FontAwesomeIcon
                icon={faAdd}
                className={cn(
                  'text-base',
                  isAddAnotherAddressDisabled
                    ? 'text-grey-300 cursor-not-allowed'
                    : 'text-primary-blue',
                )}
              />
              <p
                className={cn(
                  'text-sm font-zen-body font-semibold',
                  isAddAnotherAddressDisabled
                    ? 'text-grey-300 cursor-not-allowed'
                    : 'text-primary-blue',
                )}
              >
                Add another address
              </p>
            </button>
          </div>
          <div className='mt-5'>
            <ZenControlledMultiSelectInput<BasicInfoData, 'languages'>
              control={control}
              name='languages'
              label='Languages'
              placeholder='Select Languages'
              options={keys(LanguageType).map((language) => ({
                label: capitalizeEnum(language),
                value: language,
              }))}
              //@ts-ignore
              defaultValue={agent.languages?.map((language) => ({
                label: capitalizeEnum(language),
                value: language,
              }))}
              closeMenuOnSelect={false}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextAreaInput<BasicInfoData, 'about'>
              control={control}
              label='Bio'
              name='about'
              placeholder='A short bio of the agent'
              defaultValue={agent.about}
              rows={2}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'slug'>
              control={control}
              label='Slug'
              name='slug'
              placeholder='Slug'
              defaultValue={agent.slug}
              rules={{
                validate: (slug: string) => {
                  if (slug && !slug?.trim()) {
                    return 'Please enter a valid slug';
                  }
                  if (!!slug?.trim() && agent?.slug !== slug) {
                    return isSlugAvailable(slug);
                  }
                  return undefined;
                },
              }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'facebookURL'>
              control={control}
              label='Facebook Link'
              name='facebookURL'
              placeholder='Facebook Link'
              defaultValue={agent.facebookURL}
              rules={{ ...URL_VALIDATIONS }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'clubhouseURL'>
              control={control}
              label='Clubhouse Link'
              name='clubhouseURL'
              placeholder='Clubhouse Link'
              defaultValue={agent.clubhouseURL}
              rules={{ ...URL_VALIDATIONS }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'twitterURL'>
              control={control}
              label='Twitter Link'
              name='twitterURL'
              placeholder='Twitter Link'
              defaultValue={agent.twitterURL}
              rules={{ ...URL_VALIDATIONS }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'instagramURL'>
              control={control}
              label='Instagram Link'
              name='instagramURL'
              placeholder='Instagram Link'
              defaultValue={agent.instagramURL}
              rules={{ ...URL_VALIDATIONS }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'youtubeURL'>
              control={control}
              label='Youtube Link'
              name='youtubeURL'
              placeholder='Youtube Link'
              defaultValue={agent.youtubeURL}
              rules={{ ...URL_VALIDATIONS }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'personalWebsiteURL'>
              control={control}
              label='Website Link'
              name='personalWebsiteURL'
              placeholder='Website Link'
              defaultValue={agent.personalWebsiteURL}
              rules={{ ...URL_VALIDATIONS }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'googleBusinessProfileURL'>
              control={control}
              label='Google Business Profile Link'
              name='googleBusinessProfileURL'
              placeholder='Google Business Profile Link'
              defaultValue={agent.googleBusinessProfileURL}
              rules={{ ...URL_VALIDATIONS }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'linkedInURL'>
              control={control}
              label='LinkedIn Link'
              name='linkedInURL'
              placeholder='LinkedIn Link'
              defaultValue={agent.linkedInURL}
              rules={{ ...URL_VALIDATIONS }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'yelpURL'>
              control={control}
              label='Yelp Link'
              name='yelpURL'
              placeholder='Yelp Link'
              defaultValue={agent.yelpURL}
              rules={{ ...URL_VALIDATIONS }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<BasicInfoData, 'zillowURL'>
              control={control}
              label='Zillow Link'
              name='zillowURL'
              placeholder='Zillow Link'
              defaultValue={agent.zillowURL}
              rules={{ ...URL_VALIDATIONS }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledSelectInput<
              BasicInfoData,
              'receivesTransactionEmailNotifications'
            >
              name='receivesTransactionEmailNotifications'
              control={control}
              label='Receives Transaction Email Notifications'
              placeholder='Receives Transaction Email Notifications'
              options={[
                { label: 'Yes', value: 'YES' },
                { label: 'No', value: 'NO' },
              ]}
              defaultValue={{
                label: agent.receivesTransactionEmailNotifications
                  ? capitalizeEnum(YesNoType.YES)
                  : capitalizeEnum(YesNoType.NO),
                value: agent.receivesTransactionEmailNotifications
                  ? YesNoType.YES
                  : YesNoType.NO,
              }}
            />
          </div>
          <div className='mt-5 pb-20'>
            <ZenControlledSelectInput<
              BasicInfoData,
              'receivesTransactionPushNotifications'
            >
              name='receivesTransactionPushNotifications'
              control={control}
              label='Receives Transaction Push Notifications'
              placeholder='Receives Transaction Push Notifications'
              options={[
                { label: 'Yes', value: 'YES' },
                { label: 'No', value: 'NO' },
              ]}
              defaultValue={{
                label: agent.receivesTransactionPushNotifications
                  ? capitalizeEnum(YesNoType.YES)
                  : capitalizeEnum(YesNoType.NO),
                value: agent.receivesTransactionPushNotifications
                  ? YesNoType.YES
                  : YesNoType.NO,
              }}
            />
          </div>
        </div>

        <ZenSidebarModalActionFooter
          isSubmitting={isSubmitting}
          submitButtonDisabled={isSubmitting}
          onClose={onClose}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenBasicInformationForm;
