import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form-v7';
import { FaSlidersH } from 'react-icons/fa';
import { Popover } from 'react-tiny-popover';
import { CalendarResponse } from '../../../openapi/yenta';
import ZenControlledCheckboxInput from '../Input/ZenControlledCheckboxInput';

interface FormData {
  calendarIds: string[];
}

export interface ZenEventsFilterProps {
  calendars: Pick<CalendarResponse, 'id' | 'name'>[];
  onSubmit: SubmitHandler<FormData>;
  calendarIds?: string[];
}

const ZenEventsFilter: React.FC<ZenEventsFilterProps> = ({
  calendars,
  onSubmit,
  calendarIds = [],
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { control, handleSubmit, watch, reset } = useForm<FormData>({
    defaultValues: { calendarIds },
  });
  const watchCalendarIds = watch('calendarIds');
  const handleOnSubmit = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <Popover
      isOpen={isOpen}
      positions={['bottom']}
      align='end'
      onClickOutside={() => setIsOpen(false)}
      containerClassName='z-10'
      content={
        <form
          onChange={handleOnSubmit}
          className='z-10 mt-1 bg-white ring-1 ring-zen-dark-4 w-72 rounded-lg'
          title='event-filter-modal'
        >
          <div>
            <div className='flex flex-row items-center justify-between p-4'>
              <p className='font-zen-body font-semibold text-sm text-zen-dark-5'>
                Filter By
              </p>
              <button
                type='reset'
                onClick={async () => {
                  await reset({ calendarIds: [] });
                  await handleOnSubmit();
                }}
                className='font-zen-body font-semibold text-sm text-primary-blue'
              >
                Reset
              </button>
            </div>
            <div className='px-4 pb-4 h-full max-h-96 overflow-auto'>
              <ZenControlledCheckboxInput<FormData, 'calendarIds'>
                shouldUnregister={false}
                control={control}
                name='calendarIds'
                options={calendars.map((calendar) => ({
                  label: calendar.name!,
                  value: calendar.id!,
                }))}
              />
            </div>
          </div>
        </form>
      }
    >
      <button
        className='flex justify-center items-center space-x-2 border rounded-lg py-2 px-4.5'
        onClick={() => setIsOpen(!isOpen)}
      >
        <FaSlidersH className='text-primary-blue' />
        <span className='font-zen-body font-normal text-zen-dark-9 min-w-max'>
          Filter {!!watchCalendarIds?.length && `(${watchCalendarIds?.length})`}
        </span>
      </button>
    </Popover>
  );
};

export default ZenEventsFilter;
