import classNames from 'classnames';
import React from 'react';
import { AiFillExclamationCircle } from 'react-icons/ai';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { ItemResponseStatusEnum } from '../../../openapi/sherlock';
import { PartialEnumMap } from '../../../types';
import { safePartialEnumMapGet } from '../../../utils/EnumHelper';
import Hover from '../../Hover';

interface ZenJourneyStatusIconProps {
  status: ItemResponseStatusEnum;
}

const ZenJourneyStatusIcon: React.FC<ZenJourneyStatusIconProps> = ({
  status,
}) => {
  const isJourneyCompleted =
    status === ItemResponseStatusEnum.Accepted ||
    status === ItemResponseStatusEnum.Done;

  const statusToIconMap: PartialEnumMap<
    ItemResponseStatusEnum,
    React.ReactElement
  > = {
    ACCEPTED: (
      <RiCheckboxCircleFill
        fontSize='small'
        className='w-12 h-12 text-green-600'
      />
    ),
    DONE: (
      <RiCheckboxCircleFill
        fontSize='small'
        className='w-12 h-12 text-green-600'
      />
    ),
    NOT_STARTED: (
      <AiFillExclamationCircle
        fontSize='small'
        className='w-12 h-12 text-zen-dark-4'
      />
    ),
  };

  const getJourneyIcon = safePartialEnumMapGet(
    statusToIconMap,
    status,
    <AiFillExclamationCircle
      fontSize='small'
      className='w-12 h-12 text-zen-dark-4'
    />,
  );

  return (
    <div
      className={classNames(
        'absolute z-10 flex items-center justify-center rounded-full bg-white mr-2 w-4 h-8',
      )}
    >
      <Hover
        hoverComponent={
          <div className='text-zen-dark-12 text-base font-primary-medium px-3'>
            {isJourneyCompleted ? 'Complete' : 'Incomplete'}
          </div>
        }
        config={{ trigger: 'hover', placement: 'top' }}
      >
        {getJourneyIcon}
      </Hover>
    </div>
  );
};

export default ZenJourneyStatusIcon;
