import React from 'react';
import { ReactComponent as IncomeScholarOn } from '../assets/icons/revshare-insights/on/income-scholar.svg';
import { ReactComponent as RisingStarOn } from '../assets/icons/revshare-insights/on/rising-star.svg';
import { ReactComponent as NetworkBuilderOn } from '../assets/icons/revshare-insights/on/network-builder.svg';
import { ReactComponent as PeakConquerorOn } from '../assets/icons/revshare-insights/on/peak-conqueror.svg';
import { ReactComponent as AdmiralAchieverOn } from '../assets/icons/revshare-insights/on/admiral-achiver.svg';
import { ReactComponent as LocksmithOn } from '../assets/icons/revshare-insights/on/locksmith.svg';
import { ReactComponent as MasterRecruiterOn } from '../assets/icons/revshare-insights/on/master-recruiter.svg';
import { ReactComponent as CollaborationChampionOn } from '../assets/icons/revshare-insights/on/collaboration-champion.svg';
import { ReactComponent as MilestoneMakerOn } from '../assets/icons/revshare-insights/on/milestone-maker.svg';
import { ReactComponent as QuarterQuasarOn } from '../assets/icons/revshare-insights/on/quater-quasar.svg';
import { ReactComponent as MidpointMaestroOn } from '../assets/icons/revshare-insights/on/midpoint-maestro.svg';
import { ReactComponent as MilestoneMonarchOn } from '../assets/icons/revshare-insights/on/milestone-monarch.svg';
import { ReactComponent as SixFigureSovereignOn } from '../assets/icons/revshare-insights/on/six-figure-sovereign.svg';
import { ReactComponent as IncomeScholarOff } from '../assets/icons/revshare-insights/off/income-scholar.svg';
import { ReactComponent as RisingStarOff } from '../assets/icons/revshare-insights/off/rising-star.svg';
import { ReactComponent as NetworkBuilderOff } from '../assets/icons/revshare-insights/off/network-builder.svg';
import { ReactComponent as PeakConquerorOff } from '../assets/icons/revshare-insights/off/peak-conqueror.svg';
import { ReactComponent as AdmiralAchieverOff } from '../assets/icons/revshare-insights/off/admiral-achiver.svg';
import { ReactComponent as LocksmithOff } from '../assets/icons/revshare-insights/off/locksmith.svg';
import { ReactComponent as MasterRecruiterOff } from '../assets/icons/revshare-insights/off/master-recruiter-on.svg';
import { ReactComponent as CollaborationChampionOff } from '../assets/icons/revshare-insights/off/collabortaion-champion.svg';
import { ReactComponent as MilestoneMakerOff } from '../assets/icons/revshare-insights/off/milestone-maker.svg';
import { ReactComponent as QuarterQuasarOff } from '../assets/icons/revshare-insights/off/quarter-quasar.svg';
import { ReactComponent as MidpointMaestroOff } from '../assets/icons/revshare-insights/off/midpoint-maestro.svg';
import { ReactComponent as MilestoneMonarchOff } from '../assets/icons/revshare-insights/off/milestone-monarch.svg';
import { ReactComponent as SixFigureSovereignOff } from '../assets/icons/revshare-insights/off/six-figure-sovereign.svg';
import { ReactComponent as IncomeScholarDetailOn } from '../assets/icons/revshare-insights/details/on/income-scholar-detail.svg';
import { ReactComponent as RisingStarDetailOn } from '../assets/icons/revshare-insights/details/on/rising-start-detail.svg';
import { ReactComponent as NetworkBuilderDetailOn } from '../assets/icons/revshare-insights/details/on/network-builder-detail.svg';
import { ReactComponent as PeakConquerorDetailOn } from '../assets/icons/revshare-insights/details/on/peak-conqueror-detail.svg';
import { ReactComponent as AdmiralAchieverDetailOn } from '../assets/icons/revshare-insights/details/on/admiral-achiever-detail.svg';
import { ReactComponent as LocksmithDetailOn } from '../assets/icons/revshare-insights/details/on/locksmith-detail.svg';
import { ReactComponent as MasterRecruiterDetailOn } from '../assets/icons/revshare-insights/details/on/master-recruiter-detail.svg';
import { ReactComponent as CollaborationChampionDetailOn } from '../assets/icons/revshare-insights/details/on/collaboration-champion-detail.svg';
import { ReactComponent as MilestoneMakerDetailOn } from '../assets/icons/revshare-insights/details/on/milestone-maker-detail.svg';
import { ReactComponent as QuarterQuasarDetailOn } from '../assets/icons/revshare-insights/details/on/quarter-quasar-detail.svg';
import { ReactComponent as MidpointMaestroDetailOn } from '../assets/icons/revshare-insights/details/on/midpoint-maestro-detail.svg';
import { ReactComponent as MilestoneMonarchDetailOn } from '../assets/icons/revshare-insights/details/on/milestone-monarch-detail.svg';
import { ReactComponent as SixFigureSovereignDetailOn } from '../assets/icons/revshare-insights/details/on/six-figure-sovereign-detail.svg';
import { ReactComponent as IncomeScholarDetailOff } from '../assets/icons/revshare-insights/details/off/income-scholar-detail.svg';
import { ReactComponent as RisingStarDetailOff } from '../assets/icons/revshare-insights/details/off/rising-star-detail.svg';
import { ReactComponent as NetworkBuilderDetailOff } from '../assets/icons/revshare-insights/details/off/network-builder-detail.svg';
import { ReactComponent as PeakConquerorDetailOff } from '../assets/icons/revshare-insights/details/off/peak-conqueror-detail.svg';
import { ReactComponent as AdmiralAchieverDetailOff } from '../assets/icons/revshare-insights/details/off/admiral-achiever-detail.svg';
import { ReactComponent as LocksmithDetailOff } from '../assets/icons/revshare-insights/details/off/locksmith-detail.svg';
import { ReactComponent as MasterRecruiterDetailOff } from '../assets/icons/revshare-insights/details/off/master-recruiter-detail.svg';
import { ReactComponent as CollaborationChampionDetailOff } from '../assets/icons/revshare-insights/details/off/collaboration-champion-detail.svg';
import { ReactComponent as MilestoneMakerDetailOff } from '../assets/icons/revshare-insights/details/off/milestone-maker-detail.svg';
import { ReactComponent as QuarterQuasarDetailOff } from '../assets/icons/revshare-insights/details/off/quarter-quasar-detail.svg';
import { ReactComponent as MidpointMaestroDetailOff } from '../assets/icons/revshare-insights/details/off/midpoint-maestro-detail.svg';
import { ReactComponent as MilestoneMonarchDetailOff } from '../assets/icons/revshare-insights/details/off/milestone-monarch-detail.svg';
import { ReactComponent as SixFigureSovereignDetailOff } from '../assets/icons/revshare-insights/details/off/six-figure-sovereign-detail.svg';
import { ReactComponent as Tier1Unlocked } from '../assets/icons/revshare-insights/tiers/on/tier1.svg';
import { ReactComponent as Tier2Unlocked } from '../assets/icons/revshare-insights/tiers/on/tier2.svg';
import { ReactComponent as Tier3Unlocked } from '../assets/icons/revshare-insights/tiers/on/tier3.svg';
import { ReactComponent as Tier4Unlocked } from '../assets/icons/revshare-insights/tiers/on/tier4.svg';
import { ReactComponent as Tier5Unlocked } from '../assets/icons/revshare-insights/tiers/on/tier5.svg';
import { ReactComponent as Tier1Locked } from '../assets/icons/revshare-insights/tiers/off/tier1.svg';
import { ReactComponent as Tier2Locked } from '../assets/icons/revshare-insights/tiers/off/tier2.svg';
import { ReactComponent as Tier3Locked } from '../assets/icons/revshare-insights/tiers/off/tier3.svg';
import { ReactComponent as Tier4Locked } from '../assets/icons/revshare-insights/tiers/off/tier4.svg';
import { ReactComponent as Tier5Locked } from '../assets/icons/revshare-insights/tiers/off/tier5.svg';
import { MoneyValue } from '../openapi/bff';
import { TierRevshareResponse } from '../openapi/arrakis';
import { BadgeResponseNameEnum } from '../openapi/insignia';
import { AnalyticsEventEnum } from '../types';
import { RevenueShareTierResponse } from '../openapi/yenta';

export enum RevShareInsightsFilterEnum {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}

export type FilterDates = {
  startDate?: string;
  endDate?: string;
};

type SvgComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & { title?: string }
>;

export const revShareInsightsTierColorMap: Record<string, string> = {
  1: '#05C3F9',
  2: '#439775',
  3: '#EA8039',
  4: '#F84C6C',
  5: '#5F2EEA',
};

export const revShareInsightsBadgeUnlockedIconMap: Record<
  BadgeResponseNameEnum,
  SvgComponent
> = {
  [BadgeResponseNameEnum.IncomeScholar]: IncomeScholarOn,
  [BadgeResponseNameEnum.RisingStar]: RisingStarOn,
  [BadgeResponseNameEnum.NetworkBuilder]: NetworkBuilderOn,
  [BadgeResponseNameEnum.PeakConqueror]: PeakConquerorOn,
  [BadgeResponseNameEnum.AdmiralAchiever]: AdmiralAchieverOn,
  [BadgeResponseNameEnum.Locksmith]: LocksmithOn,
  [BadgeResponseNameEnum.MasterRecruiter]: MasterRecruiterOn,
  [BadgeResponseNameEnum.CollaborationChampion]: CollaborationChampionOn,
  [BadgeResponseNameEnum.MidpointMaestro]: MidpointMaestroOn,
  [BadgeResponseNameEnum.QuarterQuasar]: QuarterQuasarOn,
  [BadgeResponseNameEnum.MilestoneMonarch]: MilestoneMonarchOn,
  [BadgeResponseNameEnum.MilestoneMaker]: MilestoneMakerOn,
  [BadgeResponseNameEnum.SixFigureSovereign]: SixFigureSovereignOn,
};

export const revShareInsightsBadgeLockedIconMap: Record<
  BadgeResponseNameEnum,
  SvgComponent
> = {
  [BadgeResponseNameEnum.IncomeScholar]: IncomeScholarOff,
  [BadgeResponseNameEnum.RisingStar]: RisingStarOff,
  [BadgeResponseNameEnum.NetworkBuilder]: NetworkBuilderOff,
  [BadgeResponseNameEnum.PeakConqueror]: PeakConquerorOff,
  [BadgeResponseNameEnum.AdmiralAchiever]: AdmiralAchieverOff,
  [BadgeResponseNameEnum.Locksmith]: LocksmithOff,
  [BadgeResponseNameEnum.MasterRecruiter]: MasterRecruiterOff,
  [BadgeResponseNameEnum.CollaborationChampion]: CollaborationChampionOff,
  [BadgeResponseNameEnum.MidpointMaestro]: MidpointMaestroOff,
  [BadgeResponseNameEnum.QuarterQuasar]: QuarterQuasarOff,
  [BadgeResponseNameEnum.MilestoneMonarch]: MilestoneMonarchOff,
  [BadgeResponseNameEnum.MilestoneMaker]: MilestoneMakerOff,
  [BadgeResponseNameEnum.SixFigureSovereign]: SixFigureSovereignOff,
};

export const revShareInsightsBadgeDetailUnlockedMap: Record<
  BadgeResponseNameEnum,
  SvgComponent
> = {
  [BadgeResponseNameEnum.IncomeScholar]: IncomeScholarDetailOn,
  [BadgeResponseNameEnum.RisingStar]: RisingStarDetailOn,
  [BadgeResponseNameEnum.NetworkBuilder]: NetworkBuilderDetailOn,
  [BadgeResponseNameEnum.PeakConqueror]: PeakConquerorDetailOn,
  [BadgeResponseNameEnum.AdmiralAchiever]: AdmiralAchieverDetailOn,
  [BadgeResponseNameEnum.Locksmith]: LocksmithDetailOn,
  [BadgeResponseNameEnum.MasterRecruiter]: MasterRecruiterDetailOn,
  [BadgeResponseNameEnum.CollaborationChampion]: CollaborationChampionDetailOn,
  [BadgeResponseNameEnum.MilestoneMaker]: MilestoneMakerDetailOn,
  [BadgeResponseNameEnum.QuarterQuasar]: QuarterQuasarDetailOn,
  [BadgeResponseNameEnum.MidpointMaestro]: MidpointMaestroDetailOn,
  [BadgeResponseNameEnum.MilestoneMonarch]: MilestoneMonarchDetailOn,
  [BadgeResponseNameEnum.SixFigureSovereign]: SixFigureSovereignDetailOn,
};

export const revShareInsightsBadgeDetailLockedMap: Record<
  BadgeResponseNameEnum,
  SvgComponent
> = {
  [BadgeResponseNameEnum.IncomeScholar]: IncomeScholarDetailOff,
  [BadgeResponseNameEnum.RisingStar]: RisingStarDetailOff,
  [BadgeResponseNameEnum.NetworkBuilder]: NetworkBuilderDetailOff,
  [BadgeResponseNameEnum.PeakConqueror]: PeakConquerorDetailOff,
  [BadgeResponseNameEnum.AdmiralAchiever]: AdmiralAchieverDetailOff,
  [BadgeResponseNameEnum.Locksmith]: LocksmithDetailOff,
  [BadgeResponseNameEnum.MasterRecruiter]: MasterRecruiterDetailOff,
  [BadgeResponseNameEnum.CollaborationChampion]: CollaborationChampionDetailOff,
  [BadgeResponseNameEnum.MilestoneMaker]: MilestoneMakerDetailOff,
  [BadgeResponseNameEnum.QuarterQuasar]: QuarterQuasarDetailOff,
  [BadgeResponseNameEnum.MidpointMaestro]: MidpointMaestroDetailOff,
  [BadgeResponseNameEnum.MilestoneMonarch]: MilestoneMonarchDetailOff,
  [BadgeResponseNameEnum.SixFigureSovereign]: SixFigureSovereignDetailOff,
};

export const revShareInsightsBadgeDetailTitleMap: Record<
  BadgeResponseNameEnum,
  string
> = {
  [BadgeResponseNameEnum.IncomeScholar]: 'Complete',
  [BadgeResponseNameEnum.RisingStar]: 'First Agent joined',
  [BadgeResponseNameEnum.NetworkBuilder]: 'Tier 2 unlocked',
  [BadgeResponseNameEnum.PeakConqueror]: 'Tier 3 unlocked',
  [BadgeResponseNameEnum.AdmiralAchiever]: 'Tier 4 unlocked',
  [BadgeResponseNameEnum.Locksmith]: 'Tier 5 unlocked',
  [BadgeResponseNameEnum.MasterRecruiter]: 'With 100 agents in your network',
  [BadgeResponseNameEnum.CollaborationChampion]:
    'With 250 agents in your network',
  [BadgeResponseNameEnum.MilestoneMaker]: 'Your total earnings exceeds 10K',
  [BadgeResponseNameEnum.QuarterQuasar]: 'Your total earnings exceeds 25K',
  [BadgeResponseNameEnum.MidpointMaestro]: 'Your total earnings exceeds 50K',
  [BadgeResponseNameEnum.MilestoneMonarch]: 'Your total earnings exceeds 75K',
  [BadgeResponseNameEnum.SixFigureSovereign]: 'Your total earnings hit 100K',
};

export const revShareInsightsBadgeDetailDescriptionMap: Record<
  BadgeResponseNameEnum,
  string
> = {
  [BadgeResponseNameEnum.IncomeScholar]:
    'Understanding the revenue share program makes you an Income Intellectual. Your readiness to learn is your key to unlocking potential earnings!',
  [BadgeResponseNameEnum.RisingStar]:
    "You're a Rising Star, paving the way to greatness!",
  [BadgeResponseNameEnum.NetworkBuilder]: 'Your network is growing strong!',
  [BadgeResponseNameEnum.PeakConqueror]:
    "You've conquered new peaks by reaching Tier 3. Your dedication and hard work are commendable!",
  [BadgeResponseNameEnum.AdmiralAchiever]:
    'Moving up to Tier 4 makes you an admiral in this sea of opportunities. Your achievements are truly noteworthy, Admiral!',
  [BadgeResponseNameEnum.Locksmith]: "You've unlocked the full potential!",
  [BadgeResponseNameEnum.MasterRecruiter]: "You're a true recruitment master!",
  [BadgeResponseNameEnum.CollaborationChampion]:
    "With 250 agents in your network, you're a true Collaboration Champion, fostering growth and shared success!",
  [BadgeResponseNameEnum.MilestoneMaker]:
    "You're making major moves in earnings!",
  [BadgeResponseNameEnum.QuarterQuasar]:
    "Shining bright like a Quasar, you've hit the $25K mark! Your journey is illuminating the path to success!",
  [BadgeResponseNameEnum.MidpointMaestro]:
    "Conducting your journey like a maestro, you've hit the $50K midpoint! Your symphony of success is resonating!",
  [BadgeResponseNameEnum.MilestoneMonarch]:
    "You've become a Milestone Monarch, reigning over the $75K mark. Your kingdom of success is expanding!",
  [BadgeResponseNameEnum.SixFigureSovereign]:
    "You've ascended the throne as a Six-Figure Sovereign by reaching the $100K milestone! Long live the reign of your success!",
};

export const revShareInsightsTierIconUnlockedMap: Record<
  number,
  SvgComponent
> = {
  1: Tier1Unlocked,
  2: Tier2Unlocked,
  3: Tier3Unlocked,
  4: Tier4Unlocked,
  5: Tier5Unlocked,
};

export const revShareInsightsTierIconLockedMap: Record<string, SvgComponent> = {
  1: Tier1Locked,
  2: Tier2Locked,
  3: Tier3Locked,
  4: Tier4Locked,
  5: Tier5Locked,
};

export const getRevShareTierTotalActiveContribution = (
  revShareByTier: Array<TierRevshareResponse | undefined>,
): MoneyValue | undefined => {
  const currency = revShareByTier[0]?.earnedRevshareAmount?.currency;
  return revShareByTier?.reduce(
    (acc, curr) => {
      acc.amount! += curr?.earnedRevshareAmount?.amount || 0;
      return acc;
    },
    { amount: 0, currency } as MoneyValue,
  );
};

export const getRevShareInsightsEarnedPercentage = (
  amount?: MoneyValue,
  total?: MoneyValue,
): number => {
  if (!total?.amount) {
    return 0;
  }
  return Math.round(((amount?.amount || 0) / total.amount) * 100);
};

export const revShareInsightsNetworksAnalyticsMap: Record<
  number,
  AnalyticsEventEnum
> = {
  1: AnalyticsEventEnum.RSI_OPEN_TIER_1_NETWORK,
  2: AnalyticsEventEnum.RSI_OPEN_TIER_2_NETWORK,
  3: AnalyticsEventEnum.RSI_OPEN_TIER_3_NETWORK,
  4: AnalyticsEventEnum.RSI_OPEN_TIER_4_NETWORK,
  5: AnalyticsEventEnum.RSI_OPEN_TIER_5_NETWORK,
};

export const revShareInsightsFilterAnalyticsMap: Record<
  RevShareInsightsFilterEnum,
  AnalyticsEventEnum
> = {
  day: AnalyticsEventEnum.RSI_FILTER_CONTRIBUTION_BY_DAY,
  week: AnalyticsEventEnum.RSI_FILTER_CONTRIBUTION_BY_WEEK,
  month: AnalyticsEventEnum.RSI_FILTER_CONTRIBUTION_BY_MONTH,
  year: AnalyticsEventEnum.RSI_FILTER_CONTRIBUTION_BY_YEAR,
};

export const revShareInsightsContributorsAnalyticsMap: Record<
  number,
  AnalyticsEventEnum
> = {
  1: AnalyticsEventEnum.RSI_OPEN_TIER_1_CONTRIBUTORS,
  2: AnalyticsEventEnum.RSI_OPEN_TIER_2_CONTRIBUTORS,
  3: AnalyticsEventEnum.RSI_OPEN_TIER_3_CONTRIBUTORS,
  4: AnalyticsEventEnum.RSI_OPEN_TIER_4_CONTRIBUTORS,
  5: AnalyticsEventEnum.RSI_OPEN_TIER_5_CONTRIBUTORS,
};

export const revShareInsightsContributionsAnalyticsMap: Record<
  number,
  AnalyticsEventEnum
> = {
  1: AnalyticsEventEnum.RSI_OPEN_TIER_1_CONTRIBUTIONS,
  2: AnalyticsEventEnum.RSI_OPEN_TIER_2_CONTRIBUTIONS,
  3: AnalyticsEventEnum.RSI_OPEN_TIER_3_CONTRIBUTIONS,
  4: AnalyticsEventEnum.RSI_OPEN_TIER_4_CONTRIBUTIONS,
  5: AnalyticsEventEnum.RSI_OPEN_TIER_5_CONTRIBUTIONS,
};

export const showZeroReferredAgentMsg = (
  badgeName: BadgeResponseNameEnum,
  unlocked: boolean,
): boolean => {
  if (unlocked) {
    return false;
  }
  return badgeName === BadgeResponseNameEnum.RisingStar;
};

export const showCurrentNetwork = (
  badgeName: BadgeResponseNameEnum,
  unlocked: boolean,
): boolean => {
  if (unlocked) {
    return false;
  }
  switch (badgeName) {
    case BadgeResponseNameEnum.MasterRecruiter:
    case BadgeResponseNameEnum.CollaborationChampion:
      return true;
    default:
      return false;
  }
};

export const showCurrentEarnings = (
  badgeName: BadgeResponseNameEnum,
  unlocked: boolean,
): boolean => {
  if (unlocked) {
    return false;
  }
  switch (badgeName) {
    case BadgeResponseNameEnum.MilestoneMaker:
    case BadgeResponseNameEnum.QuarterQuasar:
    case BadgeResponseNameEnum.MidpointMaestro:
    case BadgeResponseNameEnum.MilestoneMonarch:
    case BadgeResponseNameEnum.SixFigureSovereign:
      return true;
    default:
      return false;
  }
};

export const getDefaultNetworkSizeRequired = (
  badgeName: BadgeResponseNameEnum,
): number => {
  switch (badgeName) {
    case BadgeResponseNameEnum.CollaborationChampion:
      return 250;
    case BadgeResponseNameEnum.MasterRecruiter:
      return 100;
    default:
      return 0;
  }
};

export const getDefaultEarningsRequired = (
  badgeName: BadgeResponseNameEnum,
): number => {
  switch (badgeName) {
    case BadgeResponseNameEnum.MilestoneMaker:
      return 10000;
    case BadgeResponseNameEnum.QuarterQuasar:
      return 25000;
    case BadgeResponseNameEnum.MidpointMaestro:
      return 50000;
    case BadgeResponseNameEnum.MilestoneMonarch:
      return 75000;
    case BadgeResponseNameEnum.SixFigureSovereign:
      return 100000;
    default:
      return 0;
  }
};

export const getTierProgressPercentage = (
  tier: number,
  unlockedTier: number,
  agentsRequiredToUnlockNextTier: number,
  sortedTiers: RevenueShareTierResponse[],
): number => {
  const currentTier = sortedTiers[tier - 1];
  const nextTier = sortedTiers[tier];

  if (tier < unlockedTier || !nextTier) {
    return 100;
  }

  if (tier === unlockedTier) {
    const totalRequired =
      nextTier.numberOfTierOneAgentsToUnlockThisTier! -
      currentTier?.numberOfTierOneAgentsToUnlockThisTier!;

    return Math.max(
      0,
      ((totalRequired - agentsRequiredToUnlockNextTier) / totalRequired) * 100,
    );
  }

  return 0;
};
