import { useState } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { BulkUpdateRequest, LeoQuestionManagerApi } from '../../openapi/leo';
import { LeoTableHeaderFormData } from '../../routes/LeoQuestionManagerRoute';
import ErrorService from '../../services/ErrorService';
import { showErrorToastForErrorCode } from '../../slices/ToastNotificationSlice';
import { RootState } from '../../types';
import { ActionType } from '../../utils/LeoUtils';
import { getLeoConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';

interface LQMBulkDeleteModalProps {
  isOpen: boolean;
  onClose(): void;
  onSubmit: UseFormHandleSubmit<LeoTableHeaderFormData>;
  refresh(): void;
}

const LQMBulkDeleteModal: React.FC<LQMBulkDeleteModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  refresh,
}) => {
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleBulkDelete = async ({
    selectedPairIds,
  }: LeoTableHeaderFormData) => {
    setIsDeleting(true);
    try {
      const bulkDeleteRequest: BulkUpdateRequest = {
        pair_ids: selectedPairIds,
        action: ActionType.Delete,
        updated_by: userDetail?.id!,
      };
      await new LeoQuestionManagerApi(
        getLeoConfiguration(),
      ).bulkUpdateLqmQapairBulkPatch(bulkDeleteRequest);
      refresh();
      onClose();
    } catch (e) {
      ErrorService.notify('Unable to delete the questions', e, {
        selectedPairIds,
      });
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to delete the questions. Please try again in some time.',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <ZenConfirmationModal
      isOpen={isOpen}
      variant='danger'
      title='Are you sure want to delete selected questions?'
      confirmButtonText='Delete'
      hideIcon
      subtitle='Once deleted, questions will be removed from Leo question manager and cannot be retrieved.'
      onClose={onClose}
      onConfirm={onSubmit(handleBulkDelete)}
      isSubmitting={isDeleting}
      isDisabled={isDeleting}
    />
  );
};

export default LQMBulkDeleteModal;
