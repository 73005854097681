import { values } from 'lodash';
import { AgentResponseCompetenciesEnum } from '../../../openapi/yenta';
import { LanguageType } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { PHONE_NUMBER_VALIDATIONS } from '../../../utils/Validations';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenControlledCheckboxGroup from '../../Zen/Input/ZenControlledGroupCheckboxInput';
import ZenControlledMultiSelectInput from '../../Zen/Input/ZenControlledMultiSelectInput';
import ZenControlledPhoneNumberInput from '../../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenStickyBottomPagination from '../../Zen/ZenStickyBottomPagination';
import withApplicactionFormProgress from './withWelcomeOnboardingFormProgress';
import {
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName,
} from './ZenWelcomeOnboardingForm';

const ProfessionalInfo: StepByStepComponent<
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName
> = ({ form: { control }, onNext, onPrevious }) => {
  return (
    <div className='flex flex-col justify-between w-full h-full mt-8 max-w-md'>
      <div className='text-zen-dark-9 space-y-6 mb-10'>
        <div className='text-0.5xl font-medium font-zen-title text-zen-dark-9'>
          Professional/Business Information
        </div>
        <div>
          <ZenControlledTextInput<
            WelcomeOnboardingFormState,
            'agentUpdateRequest.title'
          >
            label='Title'
            placeholder='E.g. Realtor'
            name='agentUpdateRequest.title'
            control={control}
            shouldUnregister={false}
          />
        </div>
        <div className='flex items-center space-x-5'>
          <div className='flex-1'>
            <ZenControlledPhoneNumberInput<
              WelcomeOnboardingFormState,
              'agentUpdateRequest.phoneNumber'
            >
              name='agentUpdateRequest.phoneNumber'
              control={control}
              label='Business Phone No.'
              placeholder='+1 (702) 123-4567'
              shouldUnregister={false}
              rules={{
                required: 'Business Phone No. is required',
                ...PHONE_NUMBER_VALIDATIONS,
              }}
              isRequired
            />
          </div>
          <div className='flex-1'>
            <div>
              <ZenControlledPhoneNumberInput<
                WelcomeOnboardingFormState,
                'agentUpdateRequest.personalPhoneNumber'
              >
                name='agentUpdateRequest.personalPhoneNumber'
                control={control}
                label='Personal Phone No.'
                placeholder='+1 (702) 123-4567'
                rules={PHONE_NUMBER_VALIDATIONS}
                shouldUnregister={false}
              />
            </div>
          </div>
        </div>
        <div>
          <ZenControlledCheckboxGroup<
            WelcomeOnboardingFormState,
            'agentUpdateRequest.competencies'
          >
            label='I work with'
            name='agentUpdateRequest.competencies'
            options={values(AgentResponseCompetenciesEnum).map((service) => ({
              value: service,
              label: capitalizeEnum(service),
            }))}
            control={control}
            shouldUnregister={false}
          />
        </div>
        <div>
          <ZenControlledMultiSelectInput<
            WelcomeOnboardingFormState,
            'agentUpdateRequest.languages'
          >
            name='agentUpdateRequest.languages'
            control={control}
            options={values(LanguageType).map((language) => ({
              value: language,
              label: capitalizeEnum(language),
            }))}
            label='Languages Spoken'
            placeholder='Select'
            shouldUnregister={false}
            isSearchable
          />
        </div>
      </div>
      <ZenStickyBottomPagination
        onPrevious={onPrevious}
        onNext={onNext}
        maxWidth='xl'
      />
    </div>
  );
};

export default withApplicactionFormProgress(ProfessionalInfo);
