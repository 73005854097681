import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AgentOnboardingLayout from '../components/AgentOnboardingLayout';
import ResourceContainer from '../components/ResourceContainer';
import StepByStepContainer, {
  StepConfig,
} from '../components/StepByStep/StepByStepContainer';
import ZenRoute from '../components/Zen/ZenRoute';
import InvalidTokenTransaction from '../components/transactions/CheckDeposit/InvalidTokenTransaction';
import ZenExternalDepositorDetailsStep from '../components/transactions/CheckDeposit/ZenExternalDepositorDetailsStep';
import ZenTransactionCertifyClosing from '../components/transactions/CheckDeposit/ZenTransactionCertifyClosing';
import ZenTransactionCheckDepositBasicInfo from '../components/transactions/CheckDeposit/ZenTransactionCheckDepositBasicInfo';
import ZenTransactionCheckDepositConfirmation from '../components/transactions/CheckDeposit/ZenTransactionCheckDepositConfirmation';
import ZenTransactionCheckDepositReview from '../components/transactions/CheckDeposit/ZenTransactionCheckDepositReview';
import config from '../config';
import { CheckDepositTokenResponseCheckDepositTypeEnum } from '../openapi/arrakis';
import { saveUploadedCheckDeposits } from '../slices/CheckDepositsSlice';
import { getTransactionByToken } from '../slices/TransactionSlice';
import { RootState } from '../types';
import { getTransactionClosingDate } from '../utils/CheckDepositsHelper';
import { parseUrl } from '../utils/UrlUtils';
import { DepositCheckFormData } from './ZenTransactionDepositCheckRoute';

export type Match = {
  id: string;
};

export enum DepositCheckStepName {
  EXTERNAL_DEPOSITOR_DETAILS = 'EXTERNAL_DEPOSITOR_DETAILS',
  CERTIFY_CLOSING = 'CERTIFY_CLOSING',
  CHECK_INFO = 'CHECK_INFO',
  REVIEW = 'REVIEW',
  CONFIRM = 'CONFIRM',
}

export const ZenExternalCheckDepositSteps: StepConfig<
  DepositCheckFormData,
  DepositCheckStepName
>[] = [
  {
    name: DepositCheckStepName.EXTERNAL_DEPOSITOR_DETAILS,
    Component: ZenExternalDepositorDetailsStep,
    hidePagination: true,
  },
  {
    name: DepositCheckStepName.CERTIFY_CLOSING,
    Component: ZenTransactionCertifyClosing,
    showStepIf: ({ showClosing, closeAt }) => !closeAt && showClosing,
    hidePagination: true,
  },
  {
    name: DepositCheckStepName.CHECK_INFO,
    Component: ZenTransactionCheckDepositBasicInfo,
    hidePagination: true,
  },
  {
    name: DepositCheckStepName.REVIEW,
    Component: ZenTransactionCheckDepositReview,
    hidePagination: true,
  },
  {
    name: DepositCheckStepName.CONFIRM,
    Component: ZenTransactionCheckDepositConfirmation,
    hidePagination: true,
  },
];

const ZenExternalTrustDepositCheckUploadRoute: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams() as Match;
  const {
    transaction: {
      loadingTransactionDetail,
      fetchTransactionDetailErrorCode,
      tokenTransactionDetail,
    },
  } = useSelector((state: RootState) => state);

  const isExternalCommission =
    tokenTransactionDetail?.checkDepositType ===
    CheckDepositTokenResponseCheckDepositTypeEnum.Commission;

  const defaultValues: DepositCheckFormData = {
    isExternalCheckDeposit: true,
    depositAmount: undefined,
    backCheckImage: undefined,
    checkNo: undefined,
    frontCheckImage: undefined,
    amount: undefined,
    scannedAmount: undefined,
    accountNo: undefined,
    routingNo: undefined,
    acceptTermsAndConditions: undefined,
    escrowId: undefined,
    showClosing: isExternalCommission,
    closeAt: isExternalCommission
      ? getTransactionClosingDate(tokenTransactionDetail.rezenClosedAt, [])
      : undefined,
    closingDate: isExternalCommission
      ? getTransactionClosingDate(tokenTransactionDetail.rezenClosedAt, [])
      : undefined,
  };

  const onSubmit = () => {
    window.open(parseUrl(config?.joinRealEmailDomain!)!, '_blank');
  };

  useEffect(() => {
    return () => {
      dispatch(saveUploadedCheckDeposits(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTransactionByToken(id));
  }, [dispatch, id]);

  return (
    <ZenRoute title='Deposit Check'>
      <AgentOnboardingLayout title='Deposit Check' hideLogout hideClose>
        <ResourceContainer
          resourceName='Transaction'
          loading={loadingTransactionDetail}
          isEmpty={!tokenTransactionDetail}
          errorCode={fetchTransactionDetailErrorCode}
        >
          {tokenTransactionDetail?.valid ? (
            <StepByStepContainer<DepositCheckFormData, DepositCheckStepName>
              steps={ZenExternalCheckDepositSteps}
              onSubmit={onSubmit}
              defaultValues={defaultValues}
              mode='onChange'
              reValidateMode='onChange'
            />
          ) : (
            <InvalidTokenTransaction />
          )}
        </ResourceContainer>
      </AgentOnboardingLayout>
    </ZenRoute>
  );
};

export default ZenExternalTrustDepositCheckUploadRoute;
