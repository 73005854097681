import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  EnableMfaRequestMfaTypeEnum,
  MfaControllerApi,
} from '../../../openapi/keymaker';
import ErrorService from '../../../services/ErrorService';
import { fetchKeymakerCurrentUser, mfaSignIn } from '../../../slices/AuthSlice';
import { showSuccessToast } from '../../../slices/ToastNotificationSlice';
import { getApiErrorMessage } from '../../../utils/ErrorUtils';
import { getKeymakerConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { formatPhoneNumber } from '../../../utils/StringUtils';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../../Zen/ZenSidebarModalForm';

interface FormData {
  code: number;
}

interface ZenAgentTextMessageAuthenticationCodeSideBarModalFormProps {
  isOpen: boolean;
  onClose(): void;
  phoneNumber: string;
  setEnableTwoFactorAuthentication(enable: boolean): void;
  isForceMfa?: boolean;
}

const ZenAgentTextMessageAuthenticationCodeSideBarModalForm: React.FC<ZenAgentTextMessageAuthenticationCodeSideBarModalFormProps> = ({
  isOpen,
  onClose,
  phoneNumber,
  setEnableTwoFactorAuthentication,
  isForceMfa,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (values: FormData) => {
    try {
      await new MfaControllerApi(getKeymakerConfiguration()).enableMfa({
        code: values.code,
        mfaType: EnableMfaRequestMfaTypeEnum.Sms,
        phoneNumber,
      });
      if (isForceMfa) {
        await dispatch(mfaSignIn(values.code));
      }
      await dispatch(fetchKeymakerCurrentUser());
      setEnableTwoFactorAuthentication(false);
      onClose();
      dispatch(
        showSuccessToast('Successfully enabled two factor authentication.'),
      );
    } catch (e) {
      setError('code', {
        message: getApiErrorMessage(e),
      });
      ErrorService.notify('Unable to enable two factor authentication', e, {
        data: {
          code: values.code,
          mfaType: EnableMfaRequestMfaTypeEnum.Sms,
          phoneNumber,
        },
      });
    }
  };

  return (
    <ZenSidebarModalForm
      isOpen={isOpen}
      onClose={onClose}
      isSubmitting={isSubmitting}
      title='Two-Factor Authentication: Text Messages (SMS)'
      subtitle='Receive verification codes via text messages'
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Verify Code'
      cancelTitle='Back'
    >
      <div>
        <p className='font-primary-regular'>Enter authentication code</p>
        <p className='font-primary-regular text-sm mt-2'>
          {`We just sent you a 6-digit code to ${formatPhoneNumber(
            phoneNumber,
          )}`}
        </p>
        <div className='mt-5'>
          <ZenControlledTextInput<FormData, 'code'>
            control={control}
            name='code'
            type='number'
            placeholder='123456'
            rules={{
              required: 'Please enter authentication code',
              minLength: {
                value: 6,
                message: 'Must have 6 digit number',
              },
              maxLength: {
                value: 6,
                message: 'Must have 6 digit number',
              },
            }}
          />
        </div>
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenAgentTextMessageAuthenticationCodeSideBarModalForm;
