import {
  faBuildings,
  faCalendar,
  faCircleDollar,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OfficeResponseTransactionTypeEnum } from '../../openapi/yenta';
import { RootState } from '../../types';
import { capitalizeEnum, formatPhoneNumber } from '../../utils/StringUtils';
import { cn } from '../../utils/classUtils';
import GeminiDefaultEmpty from '../GeminiDefaultEmpty';
import AdminOnly from '../auth/AdminOnly';
import GeminiOfficeGroupCard from '../office/GeminiOfficeGroupCard';
import GeminiBankTrustInfo from './GeminiBankTrustInfo';
import GeminiIconText from './GeminiIconText';
import GeminiInformationCard from './GeminiInformationCard';
import GeminiOfficeBankingInfo from './GeminiOfficeBankingInfo';
import GeminiOfficeCompanyDetails from './GeminiOfficeCompanyDetails';
import GeminiOfficeTemplates from './GeminiOfficeTemplates';
import GeminiAgentListContainer from './Office/GeminiAgentListContainer';
import GeminiLabelValueDisplayCard from './Office/GeminiLabelValueDisplayCard';
import GeminiOfficeTransactionTypeCell from './Office/GeminiOfficeTransactionTypeCell';
import { default as GeminiProfileDisplayCard } from './Office/GeminiProfileDisplayCard';

interface GeminiOfficeDetailTabProps {
  isSticky: boolean;
  officeId: string;
}

const GeminiOfficeDetailTab: React.FC<GeminiOfficeDetailTabProps> = ({
  isSticky,
  officeId,
}: GeminiOfficeDetailTabProps) => {
  const history = useHistory();
  const {
    office: { officeDetail, officeGroupsById },
  } = useSelector((state: RootState) => state);

  return (
    <div className='flex md:flex-row flex-col'>
      <div
        className={cn(
          'w-full space-y-6',
          !isSticky ? 'md:px-8 px-3 py-4' : 'p-8 pt-28',
        )}
      >
        <div
          className={cn('grid md:grid-cols-11 grid-cols-1 md:gap-x-8 gap-y-4')}
        >
          <div className='bg-white border-grey-200 border rounded-[10px] col-span-3 p-[26px] space-y-6'>
            <GeminiLabelValueDisplayCard
              label='Transaction Type'
              value={
                <GeminiOfficeTransactionTypeCell
                  transactionType={
                    officeDetail?.transactionType as OfficeResponseTransactionTypeEnum
                  }
                />
              }
              leftIcon={
                <FontAwesomeIcon
                  className='text-zen-dark-8'
                  size='sm'
                  icon={faCircleDollar}
                />
              }
              noPadding
              noSpace
            />
            <div className=''>
              <GeminiLabelValueDisplayCard
                label='Brokerage License ID'
                value={
                  <div className='flex flex-col space-y-1.5'>
                    <p className='font-inter font-light text-sm text-zen-dark-9'>
                      {officeDetail?.brokerageLicenseNumber || 'N/A'}
                    </p>
                    {!!officeDetail?.licenseExpirationDate && (
                      <GeminiIconText
                        text={`Expires: ${
                          !!officeDetail?.licenseExpirationDate
                            ? DateTime.fromISO(
                                officeDetail?.licenseExpirationDate,
                              ).toFormat('LL/dd/yyyy')
                            : 'N/A'
                        }`}
                        icon={
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className='text-zen-dark-7'
                          />
                        }
                        variant='small'
                        textClassName='font-light text-xs text-zen-dark-9'
                      />
                    )}
                  </div>
                }
                leftIcon={
                  <FontAwesomeIcon
                    icon={faBuildings}
                    size='sm'
                    className='text-zen-dark-8'
                  />
                }
                noPadding
              />
            </div>
            <div className=''>
              <GeminiLabelValueDisplayCard
                label='Payment System'
                value={
                  officeDetail?.paymentSystem
                    ? capitalizeEnum(officeDetail?.paymentSystem)
                    : 'N/A'
                }
                leftIcon={
                  <FontAwesomeIcon
                    className='text-zen-dark-8'
                    size='sm'
                    icon={faCircleDollar}
                  />
                }
                noPadding
              />
            </div>
          </div>
          <div className='col-span-4'>
            <GeminiProfileDisplayCard
              title='Designated Broker'
              name={officeDetail?.designatedBroker?.fullName}
              imageUrl={officeDetail?.designatedBroker?.avatar}
              emailAddress={officeDetail?.designatedBroker?.emailAddress}
              phoneNumber={
                formatPhoneNumber(officeDetail?.designatedBroker?.phoneNumber)!
              }
              profileLink={() =>
                history.push(`/people/${officeDetail?.designatedBroker?.id}`)
              }
            />
          </div>
          <div className='col-span-4'>
            {officeDetail?.managingBroker ? (
              <GeminiProfileDisplayCard
                title='Manager'
                name={officeDetail?.managingBroker?.fullName}
                imageUrl={officeDetail?.managingBroker?.avatar}
                emailAddress={officeDetail?.managingBroker?.emailAddress}
                phoneNumber={
                  formatPhoneNumber(officeDetail?.managingBroker?.phoneNumber)!
                }
                profileLink={() =>
                  history.push(`/people/${officeDetail?.managingBroker?.id}`)
                }
              />
            ) : (
              <GeminiInformationCard title='Managing Broker'>
                <div className='h-56'>
                  <GeminiDefaultEmpty message='There is no managing broker to display' />
                </div>
              </GeminiInformationCard>
            )}
          </div>
        </div>

        <div className='grid lg:grid-cols-2 grid-cols-1 gap-6'>
          {!!officeGroupsById[officeId]?.groups?.length &&
            officeGroupsById[officeId]?.groups?.map((group) => {
              return (
                <GeminiOfficeGroupCard
                  key={group?.groupName}
                  group={group}
                  officeId={officeId}
                />
              );
            })}
        </div>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
          <GeminiBankTrustInfo officeDetail={officeDetail} />
          <GeminiOfficeBankingInfo officeDetail={officeDetail} />
          <GeminiOfficeCompanyDetails officeDetail={officeDetail} />
        </div>
        <AdminOnly>
          <GeminiAgentListContainer officeId={[officeId]} />
        </AdminOnly>
        <AdminOnly>
          <GeminiOfficeTemplates officeId={officeId} />
        </AdminOnly>
      </div>
    </div>
  );
};

export default GeminiOfficeDetailTab;
