import React, { createContext, useMemo, useState } from 'react';
import EditSpeakersModal from './Roar/EditSpeakersModal';
import VoiceCallDisclaimerModal from './Roar/VoiceCallDisclaimerModal';
import VoiceCallTranscriptionDetailSidebar from './Roar/VoiceCallTranscriptionDetailSidebar';

type RecordingType = { recordingId: string } | undefined;

type ContextType = {
  setVoiceCallTranscriptionData: (value: RecordingType) => void;
  setEditVoiceCallSpeakers: (value: RecordingType) => void;
  showVoiceCallDisclaimer: () => void;
};

// @ts-expect-error
export const ZenCommentSectionContext = createContext<ContextType>(null);

const ZenCommentSectionLayout: React.FC = ({ children }) => {
  const [
    voiceCallTranscriptionData,
    setVoiceCallTranscriptionData,
  ] = useState<RecordingType>();
  const [
    editVoiceCallSpeakers,
    setEditVoiceCallSpeakers,
  ] = useState<RecordingType>();
  const [voiceCallDisclaimer, setVoiceCallDisclaimer] = useState(false);

  const value = useMemo(() => {
    return {
      setVoiceCallTranscriptionData,
      setEditVoiceCallSpeakers,
      showVoiceCallDisclaimer: () => setVoiceCallDisclaimer(true),
    };
  }, []);

  return (
    <ZenCommentSectionContext.Provider value={value}>
      {children}
      {!!voiceCallTranscriptionData?.recordingId && (
        <VoiceCallTranscriptionDetailSidebar
          recordingId={voiceCallTranscriptionData.recordingId}
          onClose={() => setVoiceCallTranscriptionData(undefined)}
          onClickEditSpeakers={setEditVoiceCallSpeakers}
        />
      )}
      {!!editVoiceCallSpeakers?.recordingId && (
        <EditSpeakersModal
          isOpen
          onClose={() => setEditVoiceCallSpeakers(undefined)}
          recordingId={editVoiceCallSpeakers?.recordingId}
        />
      )}
      {voiceCallDisclaimer && (
        <VoiceCallDisclaimerModal
          onClose={() => setVoiceCallDisclaimer(false)}
        />
      )}
    </ZenCommentSectionContext.Provider>
  );
};

export default ZenCommentSectionLayout;
