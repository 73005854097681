import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faRotate } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useHideBrokerNumberFromAgent from '../../hooks/useHideBrokerNumbersFromAgent';
import { GroupMemberResponse } from '../../openapi/yenta';
import { removeOfficeGroupMember } from '../../slices/OfficeSlice';
import { capitalizeEnum, formatPhoneNumber } from '../../utils/StringUtils';
import Hover from '../Hover';
import ZenAvatar from '../Zen/ZenAvatar';

interface OfficeGroupCardRowProps {
  member: GroupMemberResponse;
  isHeaderVisible?: boolean;
  officeId: string;
  groupId: string;
}

const OfficeGroupCardRow: React.FC<OfficeGroupCardRowProps> = ({
  member,
  isHeaderVisible,
  officeId,
  groupId,
}) => {
  const dispatch = useDispatch();
  const [removeMember, setRemoveMember] = useState(false);
  const handleRemoveMember = async () => {
    setRemoveMember(true);
    await dispatch(
      removeOfficeGroupMember(officeId, groupId, {
        userId: member?.userInfo?.agentId!,
      }),
    );
    setRemoveMember(false);
  };

  const hideBrokeNumber = useHideBrokerNumberFromAgent(member.role!);

  return (
    <div
      className='flex flex-row justify-between items-center space-x-2 p-3'
      aria-label='office-group-card-row'
    >
      <div className='flex flex-row items-center'>
        <Link to={`/people/${member?.userInfo?.agentId}/profile`}>
          <ZenAvatar
            name={member?.userInfo?.displayName || 'Agent Name'}
            imageUrl={member?.userInfo?.avatar}
            size='md'
          />
        </Link>
        <div className='flex flex-row items-center space-x-2 pl-2'>
          <Link to={`/people/${member?.userInfo?.agentId}/profile`}>
            <p className='font-zen-body text-zen-dark-9 text-base font-medium break-all'>
              {member?.userInfo?.displayName || 'N/A'}
            </p>
          </Link>
          <Hover
            hoverComponent={
              <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                {member?.userInfo?.emailAddress || 'N/A'}
              </div>
            }
            config={{ trigger: 'hover', placement: 'top' }}
          >
            <FontAwesomeIcon
              icon={regular('envelope')}
              title='email'
              className='mt-1 cursor-pointer text-primary-blue'
            />
          </Hover>
          {!hideBrokeNumber && (
            <Hover
              hoverComponent={
                <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                  {formatPhoneNumber(member?.userInfo?.phoneNumber) || 'N/A'}
                </div>
              }
              config={{ trigger: 'hover', placement: 'top' }}
            >
              <FontAwesomeIcon
                icon={regular('phone')}
                title='phone'
                className='mt-1 cursor-pointer text-base text-primary-blue'
              />
            </Hover>
          )}
        </div>
      </div>
      <div className='flex flex-row items-center flex-shrink-0 space-x-2'>
        <p className='font-zen-body font-medium text-xs text-zen-dark-7'>
          {member?.role ? capitalizeEnum(member?.role) : 'N/A'}
        </p>
        {isHeaderVisible && (
          <Hover
            config={{ trigger: 'hover', placement: 'top' }}
            hoverComponent={
              <p className='font-zen-body text-sm text-zen-dark-9 px-2'>
                Remove
              </p>
            }
          >
            <button
              className='flex justify-center items-center bg-zen-danger-light rounded-full w-8 h-8'
              onClick={handleRemoveMember}
              disabled={removeMember}
              aria-label='remove-group-member'
            >
              {removeMember ? (
                <FontAwesomeIcon icon={faRotate} className='animate-spin' />
              ) : (
                <FontAwesomeIcon
                  icon={regular('xmark')}
                  className='text-zen-danger'
                  size='lg'
                />
              )}
            </button>
          </Hover>
        )}
      </div>
    </div>
  );
};

export default OfficeGroupCardRow;
