import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import {
  IdentityRoleResponse,
  IdentitySummaryResponse,
} from '../../../openapi/keymaker';
import { getAllRoles, grantRole, revokeRole } from '../../../slices/RoleSlice';
import { RootState } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ResourceContainer from '../../ResourceContainer';
import DateColumnFilter from '../../table/Filters/DateColumnFilter';
import TextColumnFilter from '../../table/Filters/TextColumnFilter';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import ZenTabs from '../../Zen/ZenTabs';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import ZenFixedResourceIndexContainer from '../../Zen/Containers/ZenFixedResourceIndexContainer';
import ZenGrantingRoleToggleInput from './ZenGrantingRoleToggleInput';

interface ZenAgentManageRolesSideBarModalProps {
  onClose(): void;
  agentId: string;
}

const columns: Array<Column<IdentityRoleResponse>> = [
  {
    Header: 'role',
    accessor: 'role',
    Cell: ({ row: { original: roleData } }) => (
      <p>{capitalizeEnum(roleData.role!)}</p>
    ),
    Filter: TextColumnFilter,
  },
  {
    Header: 'action',
    accessor: 'grantedAt',
    Cell: ({ row: { original: roleData } }) => (
      <p className='font-bold'>
        {roleData.revokedAt ? (
          <span className='text-red-600'>Revoked</span>
        ) : (
          <span className='text-green-600'>Granted</span>
        )}
      </p>
    ),
  },
  {
    Header: 'by',
    accessor: 'revokedBy',
    Cell: ({ row: { original: roleData } }) => (
      <p>{roleData.revokedBy ? roleData.revokedBy : roleData.grantedBy}</p>
    ),
    Filter: TextColumnFilter,
  },
  {
    Header: 'date',
    accessor: 'revokedAt',
    Cell: ({ row: { original: roleData } }) => (
      <p>
        {DateTime.fromMillis(
          roleData.revokedAt ? roleData.revokedAt : roleData.grantedAt!,
        ).toFormat('LL/dd/yyyy')}
      </p>
    ),
    Filter: DateColumnFilter,
  },
];

interface ToggleState {
  toggled: boolean;
  value: string;
}

const ZenAgentManageRolesSideBarModal: React.FC<ZenAgentManageRolesSideBarModalProps> = ({
  onClose,
  agentId,
}) => {
  const dispatch = useDispatch();
  const {
    auth: { identityByKeymakerIdResponse },
    agentDetail: {
      detailResponse: { data: agentDetailResponse },
    },
    roles: { rolesResponse },
  } = useSelector((state: RootState) => state);
  const [selectedTab, setSelectedTab] = useState<string>();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [toggleState, setToggleState] = useState<ToggleState>();
  const agentLoginDetails:
    | IdentitySummaryResponse
    | undefined = identityByKeymakerIdResponse.data![
    agentDetailResponse?.keyMakerId!
  ];

  const handleToggle = async (toggled: boolean, value: string) => {
    setConfirmLoading(true);
    if (toggled) {
      await dispatch(grantRole(agentId, value));
    } else {
      await dispatch(revokeRole(agentId, value));
    }
    setToggleState(undefined);
    setConfirmLoading(false);
  };

  useEffect(() => {
    if (!rolesResponse.data?.length) {
      dispatch(getAllRoles());
    }
  }, [dispatch, rolesResponse.data?.length]);

  return (
    <ZenSidebarModal title='Grant / Revoke Roles' onClose={onClose} isOpen>
      <div className='py-5'>
        <ZenTabs
          selected={selectedTab}
          onChange={setSelectedTab}
          size='sm'
          tabs={[
            {
              name: 'Roles',
              TabComponent: (
                <div className='px-5'>
                  <AuthorizationContainer asyncResponse={rolesResponse}>
                    <ResourceContainer
                      loading={rolesResponse.loading}
                      resourceName='granting role'
                      isEmpty={!rolesResponse.data?.length}
                    >
                      <ZenGrantingRoleToggleInput
                        agentActiveRoles={agentLoginDetails?.activeRoles!}
                        grantingRoles={rolesResponse.data || []}
                        onToggle={(toggled, value) =>
                          setToggleState({ toggled, value })
                        }
                      />
                    </ResourceContainer>
                  </AuthorizationContainer>
                </div>
              ),
            },
            {
              name: 'History',
              TabComponent: (
                <div className='px-5'>
                  <AuthorizationContainer
                    asyncResponse={identityByKeymakerIdResponse}
                  >
                    <ZenFixedResourceIndexContainer<IdentityRoleResponse>
                      key={agentLoginDetails?.revokedRoles?.length}
                      columns={columns}
                      data={agentLoginDetails?.revokedRoles || []}
                      resourceName='role history'
                      initialSort={{ revokedAt: 'desc' }}
                      hideFilters
                    />
                  </AuthorizationContainer>
                </div>
              ),
            },
          ]}
        />
      </div>
      {!!toggleState && (
        <ZenConfirmationModal
          variant={toggleState.toggled ? 'warning' : 'danger'}
          subtitle={
            toggleState.toggled
              ? `You are about to grant ${capitalizeEnum(
                  toggleState.value,
                )} Role to ${agentLoginDetails?.username}.`
              : `You are about to revoke ${capitalizeEnum(
                  toggleState.value,
                )} Role from ${agentLoginDetails?.username}.`
          }
          title={
            toggleState.toggled
              ? `Grant ${capitalizeEnum(toggleState.value)} Role?`
              : `Revoke ${capitalizeEnum(toggleState.value)} Role?`
          }
          onClose={() => setToggleState(undefined)}
          onConfirm={() => handleToggle(toggleState.toggled, toggleState.value)}
          isSubmitting={confirmLoading}
          isOpen
        />
      )}
    </ZenSidebarModal>
  );
};

export default ZenAgentManageRolesSideBarModal;
