import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import ZenControlledDatePickerInput from '../components/Zen/Input/ZenControlledDatePickerInput';
import ZenSidebarModalActionFooter from '../components/SidebarModal/ZenSideBarModalActionFooter';
import ZenSidebarModal from '../components/Zen/ZenSidebarModal';
import { RevShareControllerApi } from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { AppDispatch } from '../types';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';

interface FormData {
  endDate: string;
}

interface ZenProcessRevshareSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

const ZenProcessRevshareSidebarModal: React.FC<ZenProcessRevshareSidebarModalProps> = ({
  onClose,
  isOpen,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (formValues: FormData) => {
    try {
      await new RevShareControllerApi(
        getArrakisConfiguration(),
      ).processRevshare({ endDate: formValues.endDate });
      dispatch(showSuccessToast('Successfully processed Revshares.'));
      onClose();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to process revshares', e, {
        values: formValues,
      });
      dispatch(
        showErrorToastForErrorCode(
          'We encountered an error while trying to process revshares. Please try again in a few moments.',
          ErrorService.getErrorCode(e),
        ),
      );
    }
  };

  return (
    <ZenSidebarModal title='Process Revshare' isOpen={isOpen} onClose={onClose}>
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4'>
          <div>
            <ZenControlledDatePickerInput
              control={control}
              name='endDate'
              label='End Date'
              rules={{ required: 'Required' }}
              isRequired
            />
          </div>
        </div>

        <ZenSidebarModalActionFooter
          onClose={onClose}
          isSubmitting={isSubmitting}
          submitButtonDisabled={isSubmitting}
          submitButtonText='Process'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenProcessRevshareSidebarModal;
