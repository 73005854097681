import React from 'react';
import ZenButton from '../ZenButton';

interface ZenSidebarModalActionFooterProps {
  submitButtonText?: string;
  cancelButtonText?: string;
  onClose(): void;
  isSubmitting: boolean;
  isDisabled?: boolean;
}

const ZenSidebarModalActionFooter: React.FC<ZenSidebarModalActionFooterProps> = ({
  submitButtonText = 'Save',
  cancelButtonText = 'Cancel',
  onClose,
  isSubmitting,
  isDisabled,
}) => {
  return (
    <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2 w-full'>
      <ZenButton
        type='button'
        onClick={onClose}
        label={cancelButtonText}
        variant='secondary-outline'
        isFullWidth
      />
      <ZenButton
        isDisabled={isSubmitting || isDisabled}
        isSubmitting={isSubmitting}
        type='submit'
        label={submitButtonText}
        isFullWidth
      />
    </div>
  );
};

export default ZenSidebarModalActionFooter;
