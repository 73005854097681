import { cn } from '../utils/classUtils';
import { parseInitials, stringToHexCode } from '../utils/StringUtils';
import { AvatarSizeType, EnumMap } from '../types';
import { getYentaImageUrl } from '../utils/ImgUtils';

export interface AvatarProps {
  imageUrl?: string;
  name: string;
  size: AvatarSizeType;
  isRing?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({
  imageUrl,
  name,
  size,
  isRing = false,
}) => {
  const avatarSizeToClassNameMap: EnumMap<AvatarSizeType, string> = {
    xxs: 'h-6 w-6',
    xs: 'h-8 w-8',
    sm: 'h-10 w-10',
    lg: 'h-20 w-20',
    xl: 'h-28 w-28',
  };

  const textSizeToClassNameMap: EnumMap<AvatarSizeType, string> = {
    xxs: 'text-xs',
    xs: 'text-sm',
    sm: 'text-lg',
    lg: 'text-3xl',
    xl: 'text-4xl',
  };

  if (imageUrl) {
    return (
      <img
        className={cn('rounded-full', avatarSizeToClassNameMap[size], {
          'ring-2 ring-white': isRing,
        })}
        src={getYentaImageUrl(imageUrl)}
        alt='avatar'
      />
    );
  }

  return (
    <span
      className={cn(
        'inline-flex items-center justify-center rounded-full ',
        avatarSizeToClassNameMap[size],
        {
          'ring-2 ring-white': isRing,
        },
      )}
      style={{ backgroundColor: name ? stringToHexCode(name) : '' }}
    >
      <span
        className={cn(
          'font-primary-medium leading-1 text-white',
          textSizeToClassNameMap[size],
        )}
      >
        {name && parseInitials(name)}
      </span>
    </span>
  );
};

export default Avatar;
