import React from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps, ISelectOption } from '../types';
import { cn } from '../utils/classUtils';
import FormErrorMessage from './FormErrorMessage';

interface ControlledCheckboxInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  options: Array<ISelectOption<string, React.ReactNode>>;
  inlineOptions?: boolean;
  inlineLabel?: boolean;
  disabled?: boolean;
}

const ControlledCheckboxInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  options,
  inlineOptions = false,
  inlineLabel = false,
  disabled = false,
  tooltipIndex,
  shouldUnregister = true,
  ...rest
}: ControlledCheckboxInputV7Props<TFieldValues, TName>) => {
  return (
    <Controller
      {...rest}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <div className='space-y-1'>
          {label && <label htmlFor={name}>{label}</label>}
          <div className={cn(inlineOptions ? 'flex space-x-3' : 'space-y-1')}>
            {options.map(({ value: optionValue, label }) => (
              <label
                key={optionValue}
                className='flex items-start justify-start space-x-2'
              >
                <input
                  type='checkbox'
                  className='rounded-full border-none ring-1 ring-gray-200 focus:outline-none focus:ring-0 h-4 w-4 text-primary mt-1'
                  value={optionValue}
                  name={name}
                  onChange={({ target: { checked, value: checkboxValue } }) => {
                    const updatedValues = checked
                      ? [...(value || []), checkboxValue]
                      : value?.filter((v: string) => v !== checkboxValue);
                    onChange(updatedValues.length ? updatedValues : '');
                  }}
                  onBlur={onBlur}
                  checked={value?.includes(optionValue)}
                  ref={ref}
                />
                <p>{label}</p>
              </label>
            ))}
          </div>
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledCheckboxInputV7;
