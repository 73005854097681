import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface HasTransactionClosedModalHeaderProps {
  title: string;
  onClose(): void;
}

const HasTransactionClosedModalHeader: React.FC<HasTransactionClosedModalHeaderProps> = ({
  title,
  onClose,
}) => {
  return (
    <div className='w-full flex-col items-center bg-primary bg-opacity-10 text-dark text-base px-4 py-6 md:px-12 lg:px-[95px]'>
      <div className='flex flex-row'>
        <div className='font-bold text-lg text-center w-full'>
          <p>{title}</p>
        </div>
        <button className='absolute right-5' onClick={onClose}>
          <FontAwesomeIcon
            icon={faCircleXmark}
            className='text-2xl text-zen-dark-5'
          />
        </button>
      </div>
    </div>
  );
};

export default HasTransactionClosedModalHeader;
