import { LeadLoanDtoStatusEnum } from '../../../openapi/atlantis';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumMultiSelectColumnGenerator from './EnumMultiSelectColumnGenerator';

export interface MortgageStatusSelectColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const MortgageStatusSelectColumnFilter = <D extends object>(
  props: MortgageStatusSelectColumnFilterProps<D>,
) => {
  return EnumMultiSelectColumnGenerator<D>(LeadLoanDtoStatusEnum)(props);
};

export default MortgageStatusSelectColumnFilter;
