import { useMemo } from 'react';
import classNames from 'classnames';
import { faLongArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponseTransactionTypeEnum,
  TransactionResponseCountryEnum,
} from '../../../../openapi/arrakis';
import { getTransactionPriceLabel } from '../../../../utils/TransactionHelper';
import ZenTransactionStatus from '../../Transaction/ZenTransactionStatus';
import ZenCheckBox from '../../ZenCheckbox';
import {
  AbbreviatedMonthMap,
  CourseItem,
  TransactionItem,
} from '../../../../utils/LeoUtils';
import TransactionTypeIconLabel from '../../../transactions/TransactionTypeIconLabel';
import AnalyticsService from '../../../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../../../types';

interface LeoResponseItemCardProps extends TransactionItem, CourseItem {
  msgContextType?: string | null;
  onSelect(id?: string, isCurrentlySelected?: boolean): void;
}

const LeoResponseItemCard: React.FC<LeoResponseItemCardProps> = ({
  id,
  address,
  title,
  content,
  commission,
  lifecycleState,
  escrow_date,
  listingExpirationDate,
  price,
  type,
  isNew,
  isSelected,
  msgContextType,
  country,
  onSelect,
}) => {
  const isLifecycleStateValid =
    !!lifecycleState?.state &&
    Object.values(TransactionLifecycleStateValueStateEnum).includes(
      (lifecycleState?.state as any) as TransactionLifecycleStateValueStateEnum,
    );

  const getEllipsedText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };

  const cardTitle = useMemo(() => {
    switch (msgContextType) {
      case 'transaction_wmm':
      case 'transaction_story':
        return address
          ? getEllipsedText(address, 50)
          : 'Transaction has no address';
      case 'listings':
        return address
          ? getEllipsedText(address, 50)
          : 'Listing has no address';
      case 'courses':
        return title ? getEllipsedText(title, 50) : 'Course has no title';
      default:
        return '';
    }
  }, [address, title, msgContextType]);

  const secondRow = useMemo(() => {
    switch (msgContextType) {
      case 'transaction_wmm':
      case 'transaction_story':
        return [
          {
            header: 'Escrow Date',
            value: escrow_date
              ? `${
                  AbbreviatedMonthMap[escrow_date.slice(0, -9)]
                } ${escrow_date.slice(-9)}`
              : '',
          },
          {
            header: getTransactionPriceLabel(
              (type as any) as TransactionResponseTransactionTypeEnum,
            ),
            value: price ? price.slice(0, -7) : '',
          },
          {
            header: 'Commission',
            value: commission ? commission.slice(0, -7) : '',
          },
        ].map(({ header, value }) => (
          <div key={value} className='flex flex-col flex-grow flex-shrink'>
            <p className='font-zen-body-regular font-semibold text-xs uppercase text-zen-dark-10'>
              {header}
            </p>
            <p className='font-zen-body-regular text-sm text-white'>{value}</p>
          </div>
        ));
      case 'listings':
        return [
          {
            header: 'Closing Date',
            value: listingExpirationDate
              ? `${
                  AbbreviatedMonthMap[listingExpirationDate.slice(0, -9)]
                } ${listingExpirationDate.slice(-9)}`
              : '',
          },
          {
            header: !!type
              ? getTransactionPriceLabel(
                  (type!.toUpperCase() as any) as TransactionResponseTransactionTypeEnum,
                )
              : 'Sale Price',
            value: price ? price.slice(0, -7) : '',
          },
          {
            header: 'Commission',
            value: commission ? commission.slice(0, -7) : '',
          },
        ].map(({ header, value }) => (
          <div key={value} className='flex flex-col flex-grow flex-shrink'>
            <p className='font-zen-body-regular font-semibold text-xs uppercase text-zen-dark-10'>
              {header}
            </p>
            <p className='font-zen-body-regular text-sm text-white'>{value}</p>
          </div>
        ));
      case 'courses':
        const ellipsiedContent = content
          ? getEllipsedText(content, 230)
          : content;
        return (
          <p
            className={classNames(
              'flex-grow',
              'flex-shrink',
              'text-sm',
              'text-white',
              'flex-wrap',
              'font-zen-body',
            )}
          >
            {ellipsiedContent}
          </p>
        );
      default:
        return [];
    }
  }, [
    msgContextType,
    escrow_date,
    type,
    price,
    commission,
    listingExpirationDate,
    content,
  ]);

  const statusPill = useMemo(() => {
    switch (msgContextType) {
      case 'transaction_wmm':
      case 'transaction_story':
      case 'listings':
        if (isLifecycleStateValid) {
          return (
            <ZenTransactionStatus
              country={
                country ? country : TransactionResponseCountryEnum.UnitedStates
              }
              status={
                (lifecycleState.state as any) as TransactionLifecycleStateValueStateEnum
              }
            />
          );
        }
        return;
      case 'courses':
      default:
        return (
          <div className='w-max flex items-center space-x-1.5 flex-grow flex-shrink-0 py-1.5 px-2 rounded-full md:whitespace-nowrap bg-regent-200 text-primary-blue text-sm'>
            <span>Real Academy</span>
          </div>
        );
    }
  }, [country, isLifecycleStateValid, lifecycleState, msgContextType]);

  const showSelectionCheckbox = useMemo(() => {
    if (
      ['transaction_story', 'transaction_wmm', 'listings'].includes(
        msgContextType!,
      )
    ) {
      return true;
    }
    return false;
  }, [msgContextType]);

  return (
    <div
      className={classNames(
        'bg-[#171821]',
        'flex',
        'flex-col',
        'border',
        'border-zen-dark-4',
        'rounded-lg',
        'rounded-cardBorder',
        'overflow-hidden',
        // 'mt-4',
        'p-3',
      )}
    >
      {!!isNew && (
        <div
          className={classNames(
            'absolute',
            'top-0',
            'left-0',
            'flex-row',
            'justify-center',
            'items-center',
            'mt-3',
          )}
        >
          <p
            className={classNames(
              'font-primary-medium',
              'text-sm',
              'text-white',
              'text-center',
              'py-0.5',
              'ml-1',
            )}
          >
            New
          </p>
        </div>
      )}
      <div className='h-full flex flex-col justify-between'>
        <div className={classNames('flex flex-row items-start w-full')}>
          <p
            className={classNames(
              'flex-grow',
              'flex-shrink',
              'font-primary-regular',
              'text-base',
              'text-white',
              'flex-wrap',
              !!isNew ? 'ml-12' : 'ml-0',
            )}
          >
            {cardTitle}
          </p>
          {!!type && (
            <div className={classNames('ml-6', 'mt-0.5')}>
              <TransactionTypeIconLabel type={type!} />
            </div>
          )}
          {msgContextType === 'courses' && (
            <button
              type='button'
              className='ml-6 appearance-none cursor-pointer'
              onClick={() => onSelect()}
            >
              <FontAwesomeIcon
                icon={faLongArrowRight}
                className='text-white'
                size='lg'
              />
            </button>
          )}
        </div>
        <div className={classNames('flex flex-row flex-grow my-4')}>
          {secondRow}
        </div>
        <div
          className={classNames(
            'w-full',
            'flex',
            'flex-row',
            'justify-between',
            'items-center',
          )}
        >
          <div className='flex-shrink overflow-x-auto'>{statusPill}</div>
          <div className='flex-grow flex flex-row justify-end cursor-pointer ml-2'>
            {showSelectionCheckbox && (
              <ZenCheckBox
                value={!!isSelected}
                onChange={() => {
                  onSelect(id!, !!isSelected);
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.LEO_TRANSACTION_CHECKBOX_CLICK,
                    {
                      transactionId: id,
                    },
                  );
                }}
                variant='square'
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeoResponseItemCard;
