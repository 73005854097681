import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';
import ZenClearTransactionErrorsFormSidebarModal from './ZenClearTransactionErrorsFormSidebarModal';

interface ZenClearTransactionErrorsProps {}

const ZenClearTransactionErrors: React.FC<ZenClearTransactionErrorsProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <ZenResourceLinkCard
        title='Clear Transaction Errors'
        onClick={() => setIsOpen(true)}
        icon={solid('wallet')}
        iconSize='2x'
      />

      <ZenClearTransactionErrorsFormSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenClearTransactionErrors;
