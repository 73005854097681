import { useGemini } from '../../hooks/useGemini';
import { cn } from '../../utils/classUtils';

export interface DataRowProps {
  name: string;
  value?: any;
  customNameStyle?: string;
  customValueStyle?: string;
}

const ZenDataRow: React.FC<DataRowProps> = ({
  name,
  value,
  children,
  customNameStyle,
  customValueStyle,
}) => {
  const isGeminiEnabled = useGemini();

  return (
    <tr className='font-zen-body text-sm'>
      <td
        className={cn(
          'border py-2 px-3 font-normal',
          isGeminiEnabled
            ? 'font-zen-body-2 text-grey-600 font-medium'
            : 'text-zen-dark-7',
          customNameStyle,
        )}
      >
        {name}
      </td>
      <td
        className={cn(
          'border py-2 px-3 word-break',
          isGeminiEnabled
            ? 'font-zen-body-2 font-light text-primary-dark'
            : 'font-semibold text-zen-dark-9',
          customValueStyle,
        )}
      >
        {value} {children}
      </td>
    </tr>
  );
};

export default ZenDataRow;
