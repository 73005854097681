// Where to Find Things in Marketing Center

export const MARKETING_CENTER_REDIRECT_URL = 'https://marketing.onereal.com';

export const ONE_REAL_IMPACT_URL = 'https://onereal.com/pages/one-real-impact';

export const REAL_DESIGN_CENTER = 'https://www.realdesigncenter.com/sso';

export const REAL_SWAG_SHOP = 'https://onerealstore.com/';

export const AGENT_ATTRACTION_WEBSITE = 'https://go.joinreal.com/';

export const EARNING_CALCULATOR =
  ' https://joinreal.com/joinreal-earnings-calculator';

export const NINE_WAYS_TO_EARN_INCOME_AT_REAL =
  'https://realbroker.workplace.com/work/knowledge/904234556922434';

export const REAL_BRAND_GUIDELINES =
  'https://docs.google.com/presentation/d/e/2PACX-1vSHwZ_AkNMsCEfCRG8NScR4ZHn5yGjGetg8FbJT0nCq_kxEq28WAWLfBwGj7sTm5CZDNJCQjkZyJyM0/pub?start=false&loop=false&delayms=3000';

export const AGENT_EVENT_CALENDER =
  'https://calendar.google.com/calendar/u/0/embed?src=agentevents%40therealbrokerage.com&ctz=America%2FNew_York&utm_source=google&utm_campaign=AgentEventCal';

export const AGENT_DESIGN_REQUEST_FORM =
  'https://form.asana.com/?k=Ax5HKeWUFHA_3Du8IUSx4g&d=1200832257068990';

export const LOGOS_AND_BRANDING =
  'https://realbroker.workplace.com/work/knowledge/777404046272153';

export const BUSINESS_CARDS =
  'https://realbroker.workplace.com/work/knowledge/777403829605508';

export const REAL_ESTATE_SIGNAGE =
  'https://realbroker.workplace.com/work/knowledge/802017683810789';

export const SOCIAL_MEDIA_SHAREABLES =
  'https://realbroker.workplace.com/work/knowledge/792652051414019';

export const AGENT_ATTRACTION_ASSETS =
  'https://realbroker.workplace.com/work/knowledge/779091202770104';

// LET’S WORK TOGETHER!**

export const CLICK_HERE_AND_SHARE_DETAILS =
  'https://docs.google.com/forms/d/e/1FAIpQLSdb_F2oMjI6wX64n1FA42FMpRnyKw0O8s_pd8l5d1hyycHo0w/viewform';

export const TELL_US_HERE = 'https://forms.gle/MtoXwAKQs19yXfen7';

export const BOOK_AN_OFFICE_HOURS_SESSION =
  'https://calendly.com/real-elisabeth-warrick/agent-feedback';

export const PARTNER_REDIRECT_URL =
  'https://onereal.widencollective.com/portals/taqiemso/Partners';

// SOCIAL CHANNELS**

export const FACEBOOK_URL = 'https://www.facebook.com/realbrokerage';
export const TWITTER_URL = 'https://www.twitter.com/joinreal';
export const INSTAGRAM_URL = 'https://www.instagram.com/realbrokerage/';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/joinreal';
export const YOUTUBE_URL = 'https://www.youtube.com/c/RealBrokerage';
export const TIK_TOK_URL = 'https://www.tiktok.com/@therealbrokerage';
export const REAL_TITLE_LINKEDIN_URL =
  'https://www.linkedin.com/company/therealtitle/';
export const REAL_TITLE_INSTAGRAM_URL =
  'https://instagram.com/onerealtitle?igshid=MzRlODBiNWFlZA==';

export const ORM_LINKEDIN_URL =
  'https://www.linkedin.com/company/onerealmortgage/';
export const ORM_INSTAGRAM_URL = 'https://www.instagram.com/onerealmortgage/';
