import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  faArrowLeft,
  faArrowRight,
  faPen,
  faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISelectOption } from '../../types';
import { StepByStepComponent } from '../../components/StepByStep/StepByStepContainer';
import GeminiButton from '../../components/Gemini/GeminiButton';
import { cn } from '../../utils/classUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import ReferralReviewRow from './components/ReferralReviewRow';
import { CreateReferralStepName, RefAddress } from './CreateReferral';
import { SendReferralStepName } from './SendReferral';
import { ApplyToReferralStepName } from './ApplyToReferralSteps';
import { ReferralStepsFormState } from './ReferralUtils';

const ReferralReviewStep: StepByStepComponent<
  ReferralStepsFormState,
  CreateReferralStepName | ApplyToReferralStepName | SendReferralStepName
> = ({
  form: { watch },
  onSubmit,
  onPrevious,
  onNext,
  onClose,
  navigateTo,
}) => {
  const clientInformation = watch('clientInformation');

  const {
    phoneNumber = '',
    firstName = '',
    lastName = '',
    email = '',
    address,
    expirationDate,
  } = clientInformation || {};

  return (
    <>
      <div className='flex flex-col justify-between'>
        <div className='font-inter border border-gray-200 px-3 mb-8 py-2 rounded-lg m-4'>
          <div className='flex flex-row items-center justify-between border-grey-200 pt-4 pl-4'>
            <p className='font-inter text-lg text-primary-dark'>
              Review Referral Details
            </p>
            <FontAwesomeIcon
              icon={faPen}
              onClick={() => {
                navigateTo(
                  !clientInformation
                    ? CreateReferralStepName.REFERRAL_DETAILS
                    : SendReferralStepName.REFERRAL_DETAILS,
                );
              }}
              className='rounded-full bg-grey-200 p-2 cursor-pointer'
            />
          </div>
          <div className='space-y-4 p-4'>
            <ReferralReviewRow
              label='Client Role'
              value={capitalizeEnum(watch('clientRole') ?? '') ?? 'NA'}
              icon={light('circle-user')}
            />
            <ReferralReviewRow
              label='Referral Location'
              value={watch('referralAddress').map(
                (address: RefAddress) => address.address.formatted_address,
              )}
              addressPill
              icon={light('map-marker-alt')}
            />
            <ReferralReviewRow
              label='Referral Fee'
              value={`${watch('referralFee')}%`}
              icon={light('money-check-dollar-pen')}
            />
            <ReferralReviewRow
              label='Timeline'
              value={watch('timeline')}
              icon={light('calendar')}
            />
            <ReferralReviewRow
              label='Price Range'
              icon={light('circle-dollar')}
              value={`$${watch('minimum')} - $${watch('maximum')}`}
            />
            <ReferralReviewRow
              label='Language Preference'
              icon={light('language')}
              value={
                watch('language')
                  ?.map((lang: ISelectOption) => lang.label)
                  .join(', ') ?? 'NA'
              }
            />
            <ReferralReviewRow
              label='Notes'
              icon={light('notes')}
              value={watch('notes') ?? 'NA'}
            />
            <ReferralReviewRow
              label='Personal Nickname'
              icon={light('tag')}
              isLast
              value={watch('personalNickname') ?? 'NA'}
            />
          </div>
        </div>
      </div>

      {!!clientInformation && (
        <div className='flex flex-col justify-between'>
          <div className='font-inter border border-gray-200 px-3 mb-8 py-2 rounded-lg m-4'>
            <div className='flex flex-row items-center justify-between border-grey-200 pt-4 pl-4'>
              <p className='font-inter text-lg text-primary-dark'>
                Client Details
              </p>
              <FontAwesomeIcon
                icon={faPen}
                onClick={onPrevious}
                className='rounded-full bg-grey-200 p-2 cursor-pointer'
              />
            </div>
            <div className='space-y-4 p-4'>
              <ReferralReviewRow
                label='Name'
                value={`${firstName} ${lastName}`}
                icon={light('address-card')}
              />
              <ReferralReviewRow
                label='Address'
                value={address?.formatted_address || ''}
                addressPill
                icon={light('map-marker-alt')}
              />
              <ReferralReviewRow
                label='Email'
                value={email}
                icon={light('envelope')}
              />
              <ReferralReviewRow
                label='Phone Number'
                value={phoneNumber}
                icon={light('phone')}
              />
              <ReferralReviewRow
                label='Expiration Date'
                icon={light('calendar')}
                value={expirationDate?.toString() || ''}
              />
            </div>
          </div>
        </div>
      )}

      <div className='sticky w-full bottom-0 right-0 left-0 bg-primary-light border-t'>
        <div className='flex flex-row items-center justify-between'>
          <GeminiButton
            type='button'
            label='Cancel'
            variant='primary-link'
            onClick={onClose}
          />
          <div
            className={cn(
              'flex flex-row items-center p-4 space-x-2',
              !!clientInformation ? 'w-1/2' : 'w-2/3',
            )}
          >
            <GeminiButton
              type='button'
              label='Back'
              variant='primary-outline'
              onClick={onPrevious}
              leftIcon={faArrowLeft}
              isFullWidth={!!clientInformation}
            />

            <GeminiButton
              type='button'
              label={!clientInformation ? 'Create & Publish Referral' : 'Next'}
              variant='primary'
              onClick={!clientInformation ? onSubmit : onNext}
              leftIcon={!clientInformation ? faPlus : undefined}
              rightIcon={!!clientInformation ? faArrowRight : undefined}
              isFullWidth
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralReviewStep;
