import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGemini } from '../../hooks/useGemini';
import {
  AgentResponse,
  EquityOverviewResponse,
  IncomeOverviewResponse,
} from '../../openapi/arrakis';
import { AsyncResponse, CardItem } from '../../types';
import { cn } from '../../utils/classUtils';
import AuthorizationContainer from '../auth/AuthorizationContainer';
import AuthorizedOnly from '../auth/AuthorizedOnly';
import Popper from '../Popper';
import ZenCard from './ZenCard';

export type CardValueVariant = 'bold' | 'regular';
export interface ZenCardWithItemsProps {
  items: CardItem[];
  title?: string;
  bottomAction?: string;
  onBottomActionClick?(): void;
  variant?: CardValueVariant;
  RightComponent?: React.ReactElement;
  BottomComponent?: React.ReactElement;
  cardVariant?: 'border' | 'no-border';
  authResponse?: AsyncResponse<
    IncomeOverviewResponse | EquityOverviewResponse | AgentResponse
  >;
  focusId?: string;
}

const ZenCardWithItems: React.FC<ZenCardWithItemsProps> = ({
  items,
  bottomAction,
  onBottomActionClick,
  title,
  variant = 'bold',
  RightComponent,
  BottomComponent,
  focusId,
  cardVariant = 'border',
  children,
  authResponse = { loading: false, name: 'name' },
}) => {
  const isGeminiEnabled = useGemini();

  return (
    <ZenCard
      bottomAction={
        <AuthorizedOnly asyncResponse={authResponse}>
          {bottomAction}
        </AuthorizedOnly>
      }
      title={title}
      onBottomActionClick={onBottomActionClick}
      BottomComponent={
        <AuthorizedOnly asyncResponse={authResponse}>
          {BottomComponent}
        </AuthorizedOnly>
      }
      RightComponent={
        <AuthorizedOnly asyncResponse={authResponse}>
          {RightComponent}
        </AuthorizedOnly>
      }
      variant={cardVariant}
      focusId={focusId}
    >
      <AuthorizationContainer asyncResponse={authResponse}>
        {items.map((item, index) => {
          const { ValueWrapper } = item;
          const value = (
            <span
              className={cn(
                'leading-4 text-right text-sm',
                variant === 'bold' ? 'font-bold' : 'font-semibold',
                isGeminiEnabled
                  ? 'font-zen-body-2 font-light text-primary-dark'
                  : '',
              )}
            >
              {item.value}
            </span>
          );
          return (
            <div key={item.name} aria-label={item.name}>
              {items.length + 1 !== index && index > 0 && (
                <div className='border-b' />
              )}

              <div className='px-4 py-2 justify-between flex-row flex items-center'>
                <div className='flex flex-col'>
                  <div className='flex flex-row items-center space-x-1'>
                    <div>
                      {item.url ? (
                        <Link to={item.url}>
                          <span className='text-primary-blue font-zen-body text-base font-semibold hover:underline'>
                            {item.name}
                          </span>
                        </Link>
                      ) : (
                        <span
                          className={cn(
                            'leading-5',
                            isGeminiEnabled
                              ? 'font-zen-body-2 text-sm font-medium text-grey-600'
                              : 'font-zen-body font-normal text-base text-zen-dark-7',
                          )}
                        >
                          {item.name}
                        </span>
                      )}
                    </div>
                    {item?.tooltipInfo && (
                      <Popper
                        popperComponent={
                          <div className='font-primary-regular text-sm text-white px-2 max-w-[260px]'>
                            {item?.tooltipInfo}
                          </div>
                        }
                        config={{ placement: 'top' }}
                      >
                        <FontAwesomeIcon
                          icon={item?.tooltipIcon ?? faCircleExclamation}
                          size='lg'
                          className='text-zen-dark-9'
                        />
                      </Popper>
                    )}
                  </div>
                  <span className='text-sm font-zen-body text-zen-dark-5'>
                    {item.subtitle}
                  </span>
                </div>
                <div className='flex flex-row items-center space-x-2 justify-between'>
                  {ValueWrapper ? <ValueWrapper>{value}</ValueWrapper> : value}
                  {!!item?.rightActionComponent && (
                    <div>{item?.rightActionComponent}</div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {children && <div>{children}</div>}
      </AuthorizationContainer>
    </ZenCard>
  );
};

export default ZenCardWithItems;
