import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import ZenButton from '../ZenButton';
import { USER_IDLE_TIMEOUT } from '../../../utils/commonUtils';
import ZenSimpleModal from './ZenSimpleModal';

interface ZenIdlePopupModalProps {}

const ZenIdlePopupModal: React.FC<ZenIdlePopupModalProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useIdleTimer({
    timeout: USER_IDLE_TIMEOUT,
    onIdle: () => setIsOpen(true),
    crossTab: true,
    syncTimers: 1000,
  });

  return (
    <ZenSimpleModal
      title='Are you still there?'
      isOpen={isOpen}
      onClose={() => {}}
      hideIcon
    >
      <div className='flex flex-row mt-8 mb-4 space-x-3 px-10'>
        <ZenButton
          isFullWidth
          label='Yes'
          variant='primary'
          onClick={() => window.location.reload()}
        />
      </div>
    </ZenSimpleModal>
  );
};

export default ZenIdlePopupModal;
