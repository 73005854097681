import React, { useEffect, useState } from 'react';
import { Control, UseFormSetValue, UseFormTrigger } from 'react-hook-form-v7';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import { FlexTeamDto } from '../../../../openapi/yenta';
import ZenPopover from '../../ZenPopover';
import ZenControlledGroupRadioButtonInputV2 from '../../ZenControlledGroupRadioButtonInputV2';
import ZenSidebarModalActionFooterV2 from '../../Modal/ZenSidebarModalActionFooterV2';
import { ISelectOption } from '../../../../types';
import { ProTeamMember } from './ZenEditProTeamMember';
import RealBEOPFeePopover from './ProTeamPopover/RealBEOPFeePopover';
import RealSplitFeePopover from './ProTeamPopover/RealSplitFeePopover';
import RealBrokerageFeePopover from './ProTeamPopover/RealBrokerageFeePopover';
import RealPostCapTransactionFeePopover from './ProTeamPopover/RealPostCapTransactionFeePopover';

interface ZenTeamMemberAgentFeesTabProps {
  onClose(): void;
  control: Control<ProTeamMember, object>;
  team: FlexTeamDto;
  onSubmit: () => any;
  trigger: UseFormTrigger<ProTeamMember>;
  buttonText: string;
  setValue: UseFormSetValue<ProTeamMember>;
  readOnly?: boolean;
}
const ZenTeamMemberAgentFeesTab: React.FC<ZenTeamMemberAgentFeesTabProps> = ({
  onClose,
  control,
  team,
  buttonText,
  onSubmit,
  trigger,
  setValue,
  readOnly,
}) => {
  const [realCapOptions, setRealCapOptions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    const options = [
      {
        value: '4000',
        label: '4K',
        disabled: readOnly || !team?.config?.allowedMemberCaps?.includes(4000),
      },
      {
        value: '6000',
        label: '6K',
        disabled: readOnly || !team?.config?.allowedMemberCaps?.includes(6000),
      },
      {
        value: '12000',
        label: '12K',
        disabled: readOnly || !team?.config?.allowedMemberCaps?.includes(12000),
      },
    ];
    setRealCapOptions(options);
  }, [readOnly, team?.config?.allowedMemberCaps]);

  useEffect(() => {
    const availableOptions = realCapOptions.filter(
      (option) => !option.disabled,
    );
    if (availableOptions.length === 1) {
      setValue('realCap', availableOptions[0].value);
    }
  }, [realCapOptions, setValue]);

  const onNext = async () => {
    const isValid = await trigger();
    if (isValid) {
      onSubmit();
    }
  };

  return (
    <div className='flex flex-col justify-between max-h-[95%] pb-14'>
      <div className='p-5 max-h-full overflow-y-auto'>
        <div className='p-5'>
          <div className='font-normal font-primary-light text-sm text-zen-dark-7'>
            Choose default splits for leaders and teammates in each category.
            These can be adjusted individually for each team member when editing
            their personal settings.
          </div>
          <div className='mt-5'>
            <div className='font-inter text-sm text-zen-dark-9 mb-5'>
              Teammate’s Real Cap
              <ZenPopover
                title='Teammate’s Real Cap'
                description={
                  <div className='text-zen-dark-9 text-base font-inter font-light'>
                    <p>
                      Real fees are charges that are collected only when you
                      close transactions. Real does not charge any monthly fees.
                    </p>
                    <p className='mt-4'>
                      Select the default percentage split for Real Fee between
                      Team Leader and Teammates (or members within your team).
                    </p>
                  </div>
                }
              />
            </div>
            <ZenControlledGroupRadioButtonInputV2<ProTeamMember, 'realCap'>
              control={control}
              options={realCapOptions}
              minWidth='w-60'
              name='realCap'
              shouldUnregister={false}
              isRequired
              rules={{
                required: 'Please select a Real Cap',
              }}
            />
          </div>
          <div className='mt-5'>
            <div className='font-normal font-primary-medium text-lg text-zen-dark-9'>
              Individual Fee
            </div>
            <div className='h-px bg-zen-dark-5 mt-2' />
            <div className='mt-5 font-inter text-sm text-zen-dark-7'>
              This is the percentage of each fee a teammate pays to Real.
            </div>
            <div className='space-y-2'>
              <div className='flex flex-row justify-between items-center mt-5'>
                <RealSplitFeePopover />
                <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                  <ZenControlledTextInput<ProTeamMember, 'individualRealSplit'>
                    label='Teammate Pays to Real'
                    placeholder='Real Split'
                    control={control}
                    type='number'
                    name='individualRealSplit'
                    startAdornment={
                      <div className='flex w-full h-full items-center justify-center pl-2  bg-gray-50'>
                        <FontAwesomeIcon
                          className='text-primary-blue'
                          icon={solid('percentage')}
                        />
                      </div>
                    }
                    shouldUnregister={false}
                    readOnly
                  />
                </div>
              </div>
              <div className='flex flex-row justify-between items-center'>
                <RealBEOPFeePopover />
                <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                  <ZenControlledTextInput<ProTeamMember, 'individualBEOPFee'>
                    control={control}
                    type='number'
                    name='individualBEOPFee'
                    placeholder='Real BEOP Fee'
                    startAdornment={
                      <div className='flex w-full h-full items-center justify-center pl-2  bg-gray-50'>
                        <FontAwesomeIcon
                          className='text-primary-blue'
                          icon={solid('percentage')}
                        />
                      </div>
                    }
                    shouldUnregister={false}
                    readOnly
                  />
                </div>
              </div>
              <div className='flex flex-row justify-between items-center'>
                <RealBrokerageFeePopover />
                <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                  <ZenControlledTextInput<ProTeamMember, 'individualRealFee'>
                    control={control}
                    type='number'
                    name='individualRealFee'
                    placeholder='Real Brokerage Fee'
                    startAdornment={
                      <div className='flex w-full h-full items-center justify-center pl-2  bg-gray-50'>
                        <FontAwesomeIcon
                          className='text-primary-blue'
                          icon={solid('percentage')}
                        />
                      </div>
                    }
                    shouldUnregister={false}
                    readOnly
                  />
                </div>
              </div>
              <div className='flex flex-row justify-between items-center'>
                <RealPostCapTransactionFeePopover />
                <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                  <ZenControlledTextInput<
                    ProTeamMember,
                    'individualTransactionFee'
                  >
                    control={control}
                    type='number'
                    startAdornment={
                      <div className='flex w-full h-full items-center justify-center pl-2 bg-gray-50'>
                        <FontAwesomeIcon
                          className='text-primary-blue'
                          icon={solid('percentage')}
                        />
                      </div>
                    }
                    name='individualTransactionFee'
                    placeholder='Transaction Fee'
                    shouldUnregister={false}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ZenSidebarModalActionFooterV2
        onClose={onClose}
        buttonType='submit'
        isDisabled={readOnly}
        onClick={onNext}
        isSubmitting={false}
        submitButtonText={buttonText}
      />
    </div>
  );
};

export default ZenTeamMemberAgentFeesTab;
