import SwitchNullableColumnFilter, {
  SwitchNullableColumnFilterProps,
} from './Base/SwitchNullableColumnFilter';

export interface TransactionCompliantToggleColumnFilterProps<D extends object>
  extends SwitchNullableColumnFilterProps<D> {}

const TransactionCompliantToggleColumnFilter = <D extends object>(
  props: TransactionCompliantToggleColumnFilterProps<D>,
) => {
  return <SwitchNullableColumnFilter {...props} label='Marked Compliant' />;
};

export default TransactionCompliantToggleColumnFilter;
