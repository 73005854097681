import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import MenuService from '../services/MenuService';
import { MainMenuState } from '../types';

export const initialState: MainMenuState = {
  isCollapsed: false,
  openItems: [],
};

const MainMenuSlice = createSlice({
  name: 'mainMenuSlice',
  initialState,
  reducers: {
    setIsCollapsed(state, action: PayloadAction<boolean>) {
      state.isCollapsed = action.payload;
      MenuService.setOpen(!action.payload);
    },
    toggleItem(state, action: PayloadAction<string>) {
      const item = state.openItems.find(
        (openItem) => openItem === action.payload,
      );

      if (item) {
        state.openItems = state.openItems.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.openItems.push(action.payload);
      }
    },
    resetItems(state) {
      state.openItems = [];
    },
  },
});

export const { setIsCollapsed, toggleItem, resetItems } = MainMenuSlice.actions;

export default MainMenuSlice.reducer;
