import { getFormattedDateTimeWithTimezone } from '../../../../utils/DateUtils';

export interface ZenMilliDateTimeCellProps {
  date: number | undefined;
}

const ZenMilliDateTimeCell: React.FC<ZenMilliDateTimeCellProps> = ({
  date,
}) => {
  return <span>{date ? getFormattedDateTimeWithTimezone(date) : 'N/A'}</span>;
};

export default ZenMilliDateTimeCell;
