import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';

interface AnalyticsPageViewEventProps {}

const AnalyticsPageViewEvent: React.FC<AnalyticsPageViewEventProps> = () => {
  const location = useLocation();

  useEffect(() => {
    // Log page view event on location change
    AnalyticsService.instance().logEvent(AnalyticsEventEnum.PAGE_VIEW, {
      page_location: window.location.href,
      page_path: location.pathname,
    });
  }, [location.pathname]);

  return null;
};

export default AnalyticsPageViewEvent;
