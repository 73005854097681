import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { RefundAttachmentResponse } from '../../../openapi/arrakis';
import { replaceRefundAttachment } from '../../../slices/ReleaseFundsSlice';
import { AppDispatch } from '../../../types';
import { FILE_VALIDATIONS } from '../../../utils/Validations';
import ZenControlledFileUploadInput from '../../Zen/Input/ZenControlledFileUploadInput';
import ZenButton from '../../Zen/ZenButton';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import { REFUNDS_ALLOWED_FILE_TYPES } from '../../../constants/FilesConstants';

interface ReplaceAttachmentProps {
  attachment: RefundAttachmentResponse;
  onClose(): void;
}
interface FormData {
  file: FileList;
}

const ReplaceAttachment: React.FC<ReplaceAttachmentProps> = ({
  attachment,
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {},
  });

  const onSubmit = async (formData: FormData) => {
    await dispatch(
      replaceRefundAttachment(
        attachment?.id!,
        formData.file[0],
        attachment?.refundId!,
      ),
    );
    onClose();
  };

  return (
    <ZenSidebarModal title='Replace Attachment' isOpen onClose={onClose}>
      <div className='flex flex-col justify-between min-h-full pb-20'>
        <div className='p-4'>
          <ZenControlledFileUploadInput<FormData, 'file'>
            name='file'
            control={control}
            label='Replace with a new attachment'
            placeholder='pdf, docx, jpeg, png etc.'
            accept={REFUNDS_ALLOWED_FILE_TYPES.join(',')}
            rules={{
              required: 'Please select a file',
              ...FILE_VALIDATIONS,
            }}
            isRequired
          />
        </div>
        <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 left-0 right-0 px-3 absolute py-2'>
          <div className='w-4/6 ml-auto flex flex-row justify-start items-center space-x-5'>
            <ZenButton
              type='button'
              onClick={onClose}
              label='Cancel'
              variant='primary-outline'
              isFullWidth
            />
            <ZenButton
              isSubmitting={isSubmitting}
              type='button'
              label='Replace'
              isFullWidth
              onClick={handleSubmit(onSubmit)}
              isDisabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default ReplaceAttachment;
