import { DateTime } from 'luxon';
import {
  CommissionDocumentResponse,
  MoneyValue,
  ParticipantResponseParticipantRoleEnum,
  TransactionResponse,
} from '../../openapi/arrakis';
import { getDocumentIdentifier } from '../../utils/CommissionDocumentHelper';
import { displayAmount } from '../../utils/CurrencyUtils';
import { getParticipantName } from '../../utils/ParticipantHelper';
import DataRowDetails from '../DataRowDetails';
import FormLayout from '../FormLayout';
import FormPaymentParticipantsSection from '../FormPaymentParticipantsSection';
import SlimDataRow from '../SlimDataRow';

interface CanadaCDAProps {
  cdaFormDetail: CommissionDocumentResponse;
  transaction: TransactionResponse;
  participantId?: string;
  isPdf: boolean;
}

const CanadaCDA: React.FC<CanadaCDAProps> = ({
  cdaFormDetail,
  participantId,
  transaction,
  isPdf,
}) => {
  const participants = participantId
    ? cdaFormDetail.paymentParticipants?.filter(
        (participant) =>
          participant.id ===
          cdaFormDetail.payeesPaidByReal?.find((p) => p.id === participantId)
            ?.transactionParticipantId,
      )
    : cdaFormDetail.paymentParticipants;

  return (
    <FormLayout
      title='Trade Record Sheet'
      companyName={cdaFormDetail.companyName!}
      companyAddress={cdaFormDetail.officeAddress!}
      identifier={getDocumentIdentifier(cdaFormDetail)}
      revision={cdaFormDetail.revision}
      createdAt={cdaFormDetail.createdAt}
      header='canada'
      isPdf={isPdf}
    >
      <div className='grid grid-cols-2 lg:grid-cols-3 print:grid-cols-3 print:text-xs pb-2 gap-4'>
        <div>
          <div>
            <p className='uppercase font-primary-bold text-gray-700 text-xs tracking-wider'>
              Property
            </p>
            <div className='space-y-1'>
              <SlimDataRow
                name='Reference:'
                value={cdaFormDetail.transactionCode}
              />
              <SlimDataRow
                name='Address:'
                value={cdaFormDetail.propertyAddress}
              />
              <SlimDataRow
                name='Representation:'
                value={cdaFormDetail.representing}
              />

              <div className='grid grid-cols-2 print:grid-cols-2 print:text-xs pb-2 gap-y-4'>
                <SlimDataRow
                  name='Offer date:'
                  value={
                    transaction?.contractAcceptanceDate
                      ? DateTime.fromISO(
                          transaction?.contractAcceptanceDate,
                        ).toFormat('LL/dd/yyyy')
                      : 'N/A'
                  }
                />
                <SlimDataRow
                  name='Entry date:'
                  value={
                    transaction?.createdAt
                      ? DateTime.fromMillis(transaction?.createdAt).toFormat(
                          'LL/dd/yyyy',
                        )
                      : 'N/A'
                  }
                />
                <SlimDataRow
                  name='Firm date:'
                  value={
                    transaction?.firmDate
                      ? DateTime.fromISO(transaction?.firmDate).toFormat(
                          'LL/dd/yyyy',
                        )
                      : 'N/A'
                  }
                />
                <SlimDataRow
                  name='Close date:'
                  value={
                    transaction?.skySlopeEscrowClosingDate
                      ? DateTime.fromISO(
                          transaction?.skySlopeEscrowClosingDate,
                        ).toFormat('LL/dd/yyyy')
                      : 'N/A'
                  }
                />
                <SlimDataRow
                  name='Status:'
                  value={cdaFormDetail.tradeRecordSheetStatus}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-2'>
          <div className='grid grid-cols-1 lg:grid-cols-2 print:grid-cols-2 gap-4 print:text-xs print:gap-4'>
            {cdaFormDetail.details?.map((item) => {
              return (
                <DataRowDetails
                  key={item.id}
                  title={item.label!}
                  address={item.address!}
                  emailAddress={item.emailAddress!}
                  name={item.fullName!}
                  phoneNumber={item.phoneNumber!}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className='border-t border-dashed pb-4 -mx-5' />

      <p className='uppercase font-primary-bold text-gray-700 text-xs tracking-wider'>
        Referring Brokerage(s) Contact info (if applicable):
      </p>
      <div className='grid grid-cols-2 lg:grid-cols-3 print:grid-cols-2 print:text-xs my-3 gap-4'>
        {transaction?.participants
          ?.filter(
            (referral) =>
              referral.participantRole ===
              ParticipantResponseParticipantRoleEnum.ReferringAgent,
          )
          .map((agent) => (
            <DataRowDetails
              key={agent.id}
              address={agent.address!}
              emailAddress={agent.emailAddress!}
              name={getParticipantName(agent)}
              phoneNumber={agent.phoneNumber!}
            />
          ))}
      </div>
      <div className='border-t border-dashed pb-4 -mx-5' />
      <div className='flex flex-col pb-2'>
        <div>
          <p className='uppercase font-primary-bold text-gray-700 text-xs tracking-wider'>
            Trust Deposit Details:
          </p>
        </div>
        {cdaFormDetail.trustDeposits?.map((deposit, index) => (
          <table
            key={[deposit.amount, index].join(',')}
            className='table-fixed max-w-md text-xs font-thin text-gray-600 tracking-wider print:tracking-normal mt-2'
          >
            <tr>
              <td>Deposit</td>
              <td>{displayAmount(deposit.amount)}</td>
              <td>
                <SlimDataRow
                  name='Required By:'
                  value={DateTime.fromISO(deposit.requiredByDate!).toFormat(
                    'LL/dd/yyyy',
                  )}
                />
              </td>
            </tr>
            {deposit.payments?.map((installment, i) => (
              <tr key={[installment.receivedOnDate, i].join(',')}>
                <td>Installment</td>
                <td>{displayAmount(installment.amount)}</td>
                <td>
                  <SlimDataRow
                    name='Deposited On:'
                    value={DateTime.fromISO(
                      installment.receivedOnDate!,
                    ).toFormat('LL/dd/yyyy')}
                  />
                </td>
              </tr>
            ))}
          </table>
        ))}
      </div>
      <div className='border-t border-dashed pb-4 -mx-5' />

      <div className='flex flex-col pb-2'>
        <div>
          <p className='uppercase font-primary-bold text-gray-700 text-xs tracking-wider mb-2'>
            Financial:
          </p>
        </div>
        <div className='flex flex-row flex-wrap space-x-4 print:space-x-4'>
          <SlimDataRow
            name='Sales Price:'
            value={displayAmount(cdaFormDetail.price)}
          />
          <SlimDataRow
            name='Listing Comm Rate:'
            value={`${
              !!cdaFormDetail.listingCommissionPercentage
                ? (cdaFormDetail.listingCommissionPercentage * 100).toFixed(2)
                : 0
            }%`}
          />
          <SlimDataRow
            name='Selling Comm. Rate:'
            value={`${
              !!cdaFormDetail.saleCommissionPercentage
                ? (cdaFormDetail.saleCommissionPercentage * 100).toFixed(2)
                : 0
            }%`}
          />
          <SlimDataRow
            name='Expected Amount to Real:'
            value={displayAmount(cdaFormDetail.expectedDepositToReal)}
          />
        </div>
        <div className='flex flex-row flex-wrap space-x-4 print:space-x-4'>
          <SlimDataRow
            name='Vendor Refund:'
            value={displayAmount(cdaFormDetail.vendorRefund)}
          />
        </div>
      </div>
      <div className='my-2 -mx-4 -mb-16 print:mb-0 break-inside-avoid'>
        <FormPaymentParticipantsSection
          paymentParticipants={participants || []}
          doubleEnderCommissionInfo={cdaFormDetail.doubleEnderCommissionInfo}
          transaction={transaction}
          defaultCollapsed={false}
        />
      </div>
      <table className='w-full max-w-xl mb-20 print:mb-0 mt-5 print:mt-0 break-inside-avoid text-xs font-thin text-gray-600 tracking-wider'>
        <tr className='border-b border-black font-primary-medium'>
          <td className='text-left'>Transaction Ledger Activity</td>
          <td className='text-left'>Date</td>
          <td className='text-left'>Amount</td>
        </tr>

        {cdaFormDetail.transactionLedgerActivity?.map((ledger) => (
          <tr key={ledger.name}>
            <td>{ledger.name}</td>
            <td>{ledger.date}</td>
            <td>{displayAmount(ledger.amount as MoneyValue)}</td>
          </tr>
        ))}
        <tr className='border-t border-dark font-primary-medium'>
          <td />
          <td>Balance</td>
          <td>{displayAmount(cdaFormDetail.balance)}</td>
        </tr>
      </table>
    </FormLayout>
  );
};

export default CanadaCDA;
