export interface ZenColoredProgressBarProps {
  progressPercentage: number;
  hidePercentage?: boolean;
  height?: number;
}

const ZenColoredProgressBar: React.FC<ZenColoredProgressBarProps> = ({
  progressPercentage,
  hidePercentage = false,
  height = 16,
}) => {
  return (
    <div
      className='overflow-hidden flex rounded-full bg-zen-dark-4 relative'
      style={{ height }}
    >
      <div
        key={progressPercentage}
        data-testid='colored-progress-bar'
        style={{
          width: `${progressPercentage}%`,
        }}
        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-blue rounded-full'
      >
        {!hidePercentage && (
          <div className='flex justify-center w-full absolute'>
            <div className='font-zen-body my-1'>{progressPercentage}%</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ZenColoredProgressBar;
