import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import ZenAgentsAvatarList from './ZenAgentsAvatarList';

interface ZenAgentsInfoPopperProps {
  participants: string[];
  represents?: string;
}

const ZenAgentsInfoPopper: React.FC<ZenAgentsInfoPopperProps> = ({
  participants,
  represents,
}) => {
  const { agentById } = useSelector((state: RootState) => state.userIds);
  const avatarList = participants.map((participant) =>
    agentById[participant] ? getParticipantName(agentById[participant]) : 'N/A',
  );

  return (
    <div>
      {avatarList.length > 0 ? (
        <div className='cursor-pointer'>
          <ZenAgentsAvatarList
            avatarList={avatarList}
            represents={represents}
          />
        </div>
      ) : (
        <p className='text-gray-500'>--</p>
      )}
    </div>
  );
};

export default ZenAgentsInfoPopper;
