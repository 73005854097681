import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';

interface ZenPopoverProps {
  icon?: string;
  title: string;
  description?: React.ReactNode;
}

const ZenPopover: React.FC<ZenPopoverProps> = ({ title, description }) => {
  const [isNetworkTooltipOpen, setIsNetworkTooltipOpen] = React.useState(false);
  return (
    <Popover
      isOpen={isNetworkTooltipOpen}
      positions={['top', 'bottom']}
      padding={5}
      align='center'
      onClickOutside={() => setIsNetworkTooltipOpen(false)}
      content={({ position, childRect, popoverRect }) => {
        return (
          <ArrowContainer
            position={position}
            popoverRect={popoverRect}
            childRect={childRect}
            arrowColor='white'
            arrowSize={10}
            className='popover-arrow-container drop-shadow-lg'
          >
            <div
              data-testid='my-network-tooltip'
              className='w-[365px] rounded-xl bg-white drop-shadow-lg font-zen-body font-normal text-zen-dark-9 text-base'
            >
              <div className='flex justify-between px-4 pt-4'>
                <p className='font-zen-title text-lg font-medium'>{title}</p>
                <FontAwesomeIcon
                  icon={solid('circle-xmark')}
                  className='text-sm text-zen-dark-10 cursor-pointer'
                  onClick={() => setIsNetworkTooltipOpen(false)}
                />
              </div>
              <div className='p-4'>{description}</div>
            </div>
          </ArrowContainer>
        );
      }}
    >
      <FontAwesomeIcon
        icon={solid('circle-info')}
        className='ml-2 text-xs text-zen-dark-12 cursor-pointer'
        onClick={() => setIsNetworkTooltipOpen(true)}
        title='network-tooltip-icon'
      />
    </Popover>
  );
};

export default ZenPopover;
