import React from 'react';
import { useSelector } from 'react-redux';
import ZenCommentSection from '../components/transactions/Comments/ZenCommentSection';
import ZenRoute from '../components/Zen/ZenRoute';
import { CommentOnSubmitParams } from '../components/ZenCommentWidget/ZenCommentWidget';
import { SSE_URL } from '../constants/CommonConstants';
import {
  CommentControllerApi,
  CommentDto,
  PostCommentBodyReaderRoleEnum,
  PostCommentParamsAuthorTypeEnum,
  QueryCommentsRs,
  RezenObjectTypeEnum,
  UpdateCommentBodyReaderRoleEnum,
} from '../openapi/yada';
import AnalyticsService from '../services/AnalyticsService';
import { AnalyticsEventEnum, RootState } from '../types';
import { getFullName } from '../utils/AgentHelper';
import { getBorrower } from '../utils/MortgageUtils';
import { getYadaConfiguration } from '../utils/OpenapiConfigurationUtils';

const LOAD_MORE_COMMENT_COUNT = 10;

interface MortgageCommentRouteProps {
  borrowerId: string;
}

const MortgageCommentRoute: React.FC<MortgageCommentRouteProps> = ({
  borrowerId,
}) => {
  const {
    loanResponse: { data: loan },
  } = useSelector((state: RootState) => state.mortgage);
  const borrower = getBorrower(borrowerId, loan?.borrowers || []);

  const onDeleteComment = async (comment: CommentDto) => {
    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).deleteComment(
      comment?.container?.type!,
      comment?.container?.id!,
      comment?.id! as string,
    );

    return data;
  };

  const fetchComments = async (
    pageStart: string | undefined,
  ): Promise<QueryCommentsRs> => {
    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).getCommentsByContainer(
      RezenObjectTypeEnum.Borrower,
      borrowerId,
      LOAD_MORE_COMMENT_COUNT,
      pageStart,
    );

    return data;
  };

  const handleSubmit = async (values: CommentOnSubmitParams) => {
    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).postComment(
      RezenObjectTypeEnum.Borrower,
      borrowerId,
      PostCommentParamsAuthorTypeEnum.User,
      {
        richContent: values.comment,
        readerRole: values.role as PostCommentBodyReaderRoleEnum,
      },
    );

    if (data) {
      AnalyticsService.instance().logEvent(AnalyticsEventEnum.MC_COMMENT_ADDED);
    }

    return data;
  };

  const onEditComment = async (
    values: CommentOnSubmitParams,
    commentId: string,
  ): Promise<CommentDto> => {
    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).updateComment(RezenObjectTypeEnum.Borrower, borrowerId, commentId, {
      richContent: values.comment,
      readerRole: values.role as UpdateCommentBodyReaderRoleEnum,
    });

    return data;
  };

  return (
    <ZenRoute title={`Mortgage Comments - ${getFullName(borrower)}`}>
      <div className='w-full mx-auto px-4 md:pt-6 relative'>
        <ZenCommentSection
          fetchComments={fetchComments}
          onDelete={onDeleteComment}
          onEdit={onEditComment}
          onSubmit={handleSubmit}
          htmlElementScrollableParent={document?.getElementById('main-page')!}
          getPublicUserInfo
          containerType={RezenObjectTypeEnum.Borrower}
          containerId={borrowerId}
          sseURL={`${SSE_URL}?containerType=${RezenObjectTypeEnum.Borrower}&containerId=${borrowerId}`}
          hideVoiceMemo
        />
      </div>
    </ZenRoute>
  );
};

export default MortgageCommentRoute;
