import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import {
  faBuildings,
  faCircleInfo,
  faCircleXmark,
  faLockOpen,
  faMoneyCheckDollar,
  faUserXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { last } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AiFillExclamationCircle } from 'react-icons/ai';
import { FaAddressCard, FaCheckCircle, FaCrown } from 'react-icons/fa';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import CapStatusTracker from '../../components/Agent/CapStatusTracker';
import EliteStatusTracker from '../../components/Agent/EliteStatusTracker';
import ZenAgentCommissionAdvances from '../../components/Agent/V2/ZenAgentCommissionAdvances';
import ZenAgentContractCardV2 from '../../components/Agent/V2/ZenAgentContractCardV2';
import ZenAgentCredits from '../../components/Agent/V2/ZenAgentCredits';
import ZenAgentLicenses from '../../components/Agent/V2/ZenAgentLicenses';
import ZenBoardCard from '../../components/Agent/V2/ZenBoardCard';
import ZenEditAgentLicense from '../../components/Agent/V2/ZenEditAgentLicense';
import ZenHalfPieProgress from '../../components/Agent/V2/ZenHalfPieProgress';
import ZenMlsCard from '../../components/Agent/V2/ZenMlsCard';
import ZenNotesContainer from '../../components/Agent/V2/ZenNotesContainer';
import ZenOfficeTabs from '../../components/Agent/V2/ZenOfficeTabs';
import ZenSecurityProfileCard from '../../components/Agent/V2/ZenSecurityProfileCard';
import ZenTaxInformation from '../../components/Agent/V2/ZenTaxInformation';
import ZenTeams from '../../components/Agent/V2/ZenTeams';
import DebtsCardRowCell from '../../components/AgentOwesReal/DebtsCardRowCell';
import AdminOnly from '../../components/auth/AdminOnly';
import AdminOrBrokerOnly from '../../components/auth/AdminOrBrokerOnly';
import AuthorizationContainer from '../../components/auth/AuthorizationContainer';
import AuthorizedOnly from '../../components/auth/AuthorizedOnly';
import SelfOnly from '../../components/auth/SelfOnly';
import SelfOrAdminOnly from '../../components/auth/SelfOrAdminOnly';
import DefaultEmpty from '../../components/DefaultEmpty';
import AgentJointVentureCard from '../../components/JointVenture/AgentJointVentureCard';
import ZenRealJointVentureTiers from '../../components/JointVenture/ZenRealJointVentureTiers';
import ZenNetwork from '../../components/network/ZenNetwork';
import ZenNetworkRow from '../../components/network/ZenNetworkRow';
import OneRealImpactCard from '../../components/RealMortgage/OneRealImpactCard';
import ResourceContainer from '../../components/ResourceContainer';
import RevShareInsightsOverviewCard from '../../components/RevShareInsights/RevShareInsightsOverviewCard';
import ZenUpline from '../../components/upline/ZenUpline';
import ZenUplineRow from '../../components/upline/ZenUplineRow';
import DesignateBeneficiaryModal from '../../components/Zen/Modal/DesignateBeneficiary';
import UpdateBeneficiaryModal from '../../components/Zen/Modal/UpdateBeneficiaryModal';
import ZenAction from '../../components/Zen/ZenAction';
import ZenAdd from '../../components/Zen/ZenAdd';
import ZenBarChart from '../../components/Zen/ZenBarChart';
import ZenCard from '../../components/Zen/ZenCard';
import ZenCardWithItems from '../../components/Zen/ZenCardWithItems';
import ZenRoute from '../../components/Zen/ZenRoute';
import ZenView from '../../components/Zen/ZenView';
import { START_TIER } from '../../constants/NetworkConstants';
import { DemoModeContainer } from '../../containers/DemoModeContainer';
import ZenAddOfficeToUserSidebarModal from '../../forms/ZenAddOfficeToUserSidebarModal';
import { useDemoMode } from '../../hooks/useDemoMode';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useGemini } from '../../hooks/useGemini';
import {
  AgentDebtResponseStatusEnum,
  FrontLineAgentResponse,
  FrontLineAgentResponseStatusEnum,
} from '../../openapi/arrakis';
import {
  AgreementResponseAgreementTypeEnum,
  CreateNoteRequestEntityTypeEnum,
  WillBeneficiaryAndTypeResponseTypeEnum,
} from '../../openapi/yenta';
import AnalyticsService from '../../services/AnalyticsService';
import {
  fetchAgentWillBeneficiary,
  fetchAgreementsByUserAndAgreementTypeAndVersionDesc,
  getPaginatedAgentDebts,
  resetPaginatedAgentDebtResponse,
} from '../../slices/AgentSlice';
import {
  AnalyticsEventEnum,
  FeatureFlagTypeEnum,
  KBarActionEnum,
  RootState,
} from '../../types';
import {
  getAgentCapReachedDate,
  getCapCycleRemainingBalance,
  getCommissionAmount,
  getInformationAsOfDate,
  getSortedNetworks,
  isAgentRejectedOrCandidate,
} from '../../utils/AgentHelper';
import { calculateCapPercentage, canBeElite } from '../../utils/CapHelper';
import { cn } from '../../utils/classUtils';
import { displayAmount, DisplayAmountOptions } from '../../utils/CurrencyUtils';
import { getLabelAlternateNumber } from '../../utils/MathUtils';
import { getCompactCappedAmount } from '../../utils/AgentUtils';

export interface ZenAgentOverviewRouteProps {
  agentId: string;
}

const ZenAgentOverviewRoute: React.FC<ZenAgentOverviewRouteProps> = ({
  agentId,
}) => {
  const dispatch = useDispatch();
  const {
    agentDetail,
    auth: { isAdmin, isBroker },
    network: { networksByIdResponse },
    network: { zenNetworksById },
    jointVenture: {
      invitedJointVentures: { data: invitedJV },
      modalToggle,
    },
  } = useSelector((state: RootState) => state);
  const demoModeEnabled = useDemoMode();
  const agentNotRejectedOrCandidate = useMemo(
    () =>
      !isAgentRejectedOrCandidate(
        agentDetail?.detailResponse?.data?.agentStatus!,
      ),
    [agentDetail?.detailResponse?.data?.agentStatus],
  );

  const minPurchaseAmount = useMemo(
    () => agentDetail?.performanceResponse?.data?.minPurchaseAmount,
    [agentDetail?.performanceResponse?.data?.minPurchaseAmount],
  );

  const showBeneficiaryButton = !isAdmin && !isBroker;

  const [selectAgents, setSelectAgents] = useState<
    FrontLineAgentResponse[] | null
  >(null);

  const isRealTitleFlagEnabled = useFeatureFlag(FeatureFlagTypeEnum.REAL_TITLE);
  const isGeminiEnabled = useGemini();
  const agentDetailResponse = agentDetail?.detailResponse?.data;

  const history = useHistory();
  const [isAddOfficeOpen, setIsAddOfficeOpen] = useState<boolean>(false);
  const [isNetworkTooltipOpen, setIsNetworkTooltipOpen] = useState<boolean>(
    false,
  );
  const [editingLicenses, setEditingLicenses] = useState<boolean>(false);
  const [showUplineSidebar, setShowUplineSidebar] = useState<boolean>(false);
  const [
    showDesignateBeneficiaryModal,
    setShowDesignateBeneficiaryModal,
  ] = useState<boolean>(false);
  const [
    showUpdateBeneficiaryModal,
    setShowUpdateBeneficiaryModal,
  ] = useState<boolean>(false);
  const [agentDebtLoading, setAgentDebtLoading] = useState(false);

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ placement: 'top' });

  useEffect(() => {
    if (
      agentNotRejectedOrCandidate &&
      showBeneficiaryButton &&
      agentDetailResponse?.id
    ) {
      dispatch(fetchAgentWillBeneficiary(agentDetailResponse?.id));
    }
  }, [
    agentNotRejectedOrCandidate,
    agentDetailResponse?.id,
    dispatch,
    showBeneficiaryButton,
  ]);

  const fetchAgentDebt = useCallback(async () => {
    if (agentDetailResponse?.id) {
      setAgentDebtLoading(true);
      await dispatch(
        getPaginatedAgentDebts(
          agentDetailResponse?.id,
          undefined,
          undefined,
          0,
          1000,
        ),
      );
      setAgentDebtLoading(false);
    }
  }, [agentDetailResponse?.id, dispatch]);

  useEffect(() => {
    if (agentNotRejectedOrCandidate) {
      fetchAgentDebt();
    }

    return () => {
      dispatch(resetPaginatedAgentDebtResponse());
    };
  }, [dispatch, fetchAgentDebt, agentNotRejectedOrCandidate]);

  const fetchAgentAgreementsByType = useCallback(
    async (type: AgreementResponseAgreementTypeEnum) => {
      await dispatch(
        fetchAgreementsByUserAndAgreementTypeAndVersionDesc(
          agentDetailResponse?.id!,
          type,
          true,
        ),
      );
    },
    [agentDetailResponse?.id, dispatch],
  );

  useEffect(() => {
    if (agentNotRejectedOrCandidate) {
      fetchAgentAgreementsByType(AgreementResponseAgreementTypeEnum.Ica);
    }
  }, [agentNotRejectedOrCandidate, fetchAgentAgreementsByType]);

  // const transactionsGraphDataItems = getTransactionGraphDataItems(
  //   transactionsCountByLifecycleGroup,
  // );

  //  const listingsGraphDataItems = getTransactionGraphDataItems(
  //   listingCountByLifecycleGroup,
  //  );

  const barChartData = agentDetail.incomeChart?.monthlyTotals?.map((item) => {
    return {
      month: `${item.month}/${item.year!.toString().slice(-2)}`,
      total: item.total?.amount!,
    };
  });
  const highlightedMonth = last(barChartData);

  const displayAmountOptions: DisplayAmountOptions = {
    hideZeroCents: true,
    hideCurrency: true,
  };

  const networks = zenNetworksById[agentId]?.frontLineAgents;

  const agentUplineResponse = agentDetail.agentUplineInfoById[agentId];
  const actualUplineSponsors = (
    agentUplineResponse?.data?.sponsors || []
  )?.filter((agent) => !agent?.real);

  const willBeneficiary = agentDetail.willBeneficiaryAndTypeResponse.data;
  const isWillBeneficiaryTypeNone =
    !willBeneficiary ||
    willBeneficiary?.type === WillBeneficiaryAndTypeResponseTypeEnum.None;

  const activeDebts = (
    agentDetail?.paginatedAgentDebtsResponse?.data?.agentDebts || []
  )?.filter((debt) => debt?.status === AgentDebtResponseStatusEnum.Active);

  const incomeOverviewResponse = agentDetail?.incomeOverviewResponse?.data;

  const cappedAt = getAgentCapReachedDate(incomeOverviewResponse);

  const showTeamCapCycleCard =
    !!incomeOverviewResponse?.teamCapStatus &&
    //As per PRD team cap amount will be -1 if it is unlimited
    incomeOverviewResponse?.teamCapStatus?.capAmount?.amount !== -1;

  return (
    <ResourceContainer
      loading={agentDetail.detailResponse.loading}
      errorCode={agentDetail.detailResponse.error}
      isEmpty={!agentDetail.detailResponse?.data}
      resourceName='agent overview'
    >
      <ZenRoute
        title={`Profile Overview - ${agentDetail.detailResponse.data?.fullName}`}
      >
        <div className='grid grid-flow-row grid-cols-1 gap-6 px-0 md:px-4 py-8 md:grid-cols-2 lg:grid-cols-3'>
          {/* BarChart  */}
          {agentNotRejectedOrCandidate && (
            <div className='col-span-1 md:col-span-2 lg:col-span-3 flex justify-end'>
              <p className='font-primary-regular text-base text-zen-dark-8'>
                Info as of{' '}
                {getInformationAsOfDate(
                  incomeOverviewResponse,
                  agentDetail.incomeChart,
                  agentDetail.revShareOverview,
                )}
              </p>
            </div>
          )}
          <SelfOnly agentId={agentId}>
            {!modalToggle && !!invitedJV && (
              <AgentJointVentureCard invitedJV={invitedJV} />
            )}
          </SelfOnly>
          {agentNotRejectedOrCandidate && (
            <ZenCard
              title='Income Current Cap Cycle'
              focusId={KBarActionEnum.INCOME}
            >
              <AuthorizationContainer
                asyncResponse={agentDetail?.incomeOverviewResponse}
              >
                <div className='flex flex-row justify-between pt-3'>
                  <span
                    className={cn(
                      'px-4',
                      isGeminiEnabled
                        ? 'font-zen-body-2 font-medium text-sm text-grey-600'
                        : 'font-zen-body font-normal text-base text-zen-dark-7 ',
                    )}
                  >
                    Total ({agentDetailResponse?.defaultCurrency})
                  </span>
                  <DemoModeContainer>
                    <span
                      className={cn(
                        'px-4',
                        isGeminiEnabled
                          ? 'font-zen-body-2 font-light text-sm text-primary-dark'
                          : 'text-lg text-zen-dark-9 font-zen-title font-medium',
                      )}
                    >
                      {displayAmount(
                        incomeOverviewResponse?.grossCommission,
                        displayAmountOptions,
                      )}
                    </span>
                  </DemoModeContainer>
                </div>
                <div className='px-3 pb-2'>
                  <ZenBarChart
                    blurYAxis={demoModeEnabled}
                    data={barChartData! || []}
                    highlightedData={[highlightedMonth!]}
                    xKey='month'
                    yKey='total'
                    xShowAlternate={getLabelAlternateNumber(
                      (barChartData || []).length,
                    )}
                    activeTintColor='#3B82F6'
                    inactiveTintColor='#8ABCF9'
                  />
                </div>
              </AuthorizationContainer>
            </ZenCard>
          )}
          {agentNotRejectedOrCandidate && (
            <ZenCard
              title='Current Cap Cycle'
              focusId={KBarActionEnum.CURRENT_CAP_CYCLE}
              RightComponent={
                <ZenView
                  text='Generate Report'
                  onClick={() =>
                    history.push(
                      `/reports?agentId=${agentDetail.detailResponse.data?.id}`,
                    )
                  }
                />
              }
            >
              <AuthorizationContainer
                asyncResponse={agentDetail?.incomeOverviewResponse}
              >
                <div className='pt-4'>
                  <div className='flex flex-row justify-between px-4 pb-1'>
                    <p
                      className={cn(
                        'mr-1',
                        isGeminiEnabled
                          ? 'font-zen-body-2 font-medium text-sm text-grey-600'
                          : 'font-zen-body font-normal text-base text-zen-dark-7 ',
                      )}
                    >
                      Cap Reset:
                    </p>
                    <p
                      className={cn(
                        isGeminiEnabled
                          ? 'font-zen-body-2 font-light text-sm text-primary-dark'
                          : 'text-lg text-zen-dark-9 font-zen-title font-semibold',
                      )}
                    >
                      {DateTime.fromISO(
                        incomeOverviewResponse?.anniversaryDate!,
                      ).toFormat('MM/dd/yyyy')}
                    </p>
                  </div>
                  {!!cappedAt && (
                    <div className='flex flex-row justify-between px-4 pb-1'>
                      <p
                        className={cn(
                          'mr-1',
                          isGeminiEnabled
                            ? 'font-zen-body-2 font-medium text-sm text-grey-600'
                            : 'font-zen-body font-normal text-base text-zen-dark-7',
                        )}
                      >
                        Cap Reached:
                      </p>
                      <p
                        className={cn(
                          isGeminiEnabled
                            ? 'font-zen-body-2 font-light text-sm text-primary-dark'
                            : 'text-lg text-zen-dark-9 font-zen-title font-semibold',
                        )}
                      >
                        {!!cappedAt &&
                          DateTime.fromISO(cappedAt).toFormat('MM/dd/yyyy')}
                      </p>
                    </div>
                  )}
                  <div className='flex justify-between px-4'>
                    <div className='flex space-x-2 items-start'>
                      {incomeOverviewResponse?.capped && (
                        <div className='flex flex-col items-center font-zen-body'>
                          <div className='flex flex-col text-zen-dark-8 items-center'>
                            <FaCheckCircle />
                            <p className='text-sm '>Capped</p>
                          </div>
                          <p className='text-sm text-zen-dark-8'>
                            {getCompactCappedAmount(
                              incomeOverviewResponse.capStatus?.capAmount
                                ?.amount!,
                            )}
                          </p>
                        </div>
                      )}
                      {incomeOverviewResponse?.elite && (
                        <div className='flex flex-col items-center text-yellow-600'>
                          <FaCrown />
                          <p className='text-sm font-zen-body'>Elite</p>
                        </div>
                      )}
                      {agentDetailResponse?.capDeferred && (
                        <AdminOnly>
                          <div ref={setTriggerRef} className='text-zen-dark-9'>
                            <div className='flex items-center flex-row space-x-1 font-zen-body font-normal text-sm text-zen-dark-8'>
                              <AiFillExclamationCircle size={20} />
                              <p className='pt-1'>Cap Deferred</p>
                            </div>
                          </div>
                          {visible && (
                            <div
                              ref={setTooltipRef}
                              {...getTooltipProps({ className: 'tooltip' })}
                            >
                              <div
                                {...getArrowProps({ className: 't-arrow' })}
                              />
                              Cap deferred until{' '}
                              {DateTime.fromISO(
                                agentDetailResponse?.deferredCapEndDate!,
                              ).toFormat('MM/dd/yyyy')}
                            </div>
                          )}
                        </AdminOnly>
                      )}
                    </div>
                  </div>
                </div>
                <div className='px-4'>
                  {incomeOverviewResponse?.capped ? (
                    <div>
                      <div className='mb-5'>
                        <CapStatusTracker
                          capStatus={incomeOverviewResponse?.capStatus!}
                        />
                      </div>
                      {canBeElite(incomeOverviewResponse?.capStatus!) && (
                        <EliteStatusTracker
                          eliteStatus={incomeOverviewResponse?.eliteStatus!}
                        />
                      )}
                    </div>
                  ) : (
                    <div>
                      <ZenHalfPieProgress
                        progress={calculateCapPercentage(
                          incomeOverviewResponse?.capStatus,
                        )}
                        text={`${displayAmount(
                          incomeOverviewResponse?.capStatus?.capAmountPaid,
                          displayAmountOptions,
                        )} / ${displayAmount(
                          incomeOverviewResponse?.capStatus?.capAmount,
                          displayAmountOptions,
                        )}`}
                        blurText={demoModeEnabled}
                        activeTintColor='#4E8EF6'
                      />
                      <p
                        className={cn(
                          'text-center font-bold text-xl text-error',
                          demoModeEnabled && 'blur-md',
                        )}
                      >
                        {getCapCycleRemainingBalance(
                          incomeOverviewResponse?.capStatus,
                          displayAmountOptions,
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </AuthorizationContainer>

              <AdminOnly>
                <div className='flex flex-row justify-between p-4'>
                  <span
                    className={cn(
                      isGeminiEnabled
                        ? 'font-zen-body-2 font-medium text-sm text-grey-600'
                        : 'font-zen-body font-normal text-base text-zen-dark-9',
                    )}
                  >
                    Personal Deals Count
                  </span>
                  <span
                    className={cn(
                      isGeminiEnabled
                        ? 'font-zen-body-2 font-light text-sm text-primary-dark'
                        : 'font-zen-title font-medium text-lg text-zen-dark-9',
                    )}
                  >
                    {agentDetail.performanceResponse.data?.personalDealsCount ??
                      0}
                  </span>
                </div>
              </AdminOnly>
            </ZenCard>
          )}
          {agentNotRejectedOrCandidate && !!showTeamCapCycleCard && (
            <ZenCard
              title='Team Cap Cycle'
              focusId={KBarActionEnum.CURRENT_CAP_CYCLE}
            >
              <AuthorizationContainer
                asyncResponse={agentDetail?.incomeOverviewResponse}
              >
                <div className='pt-4'>
                  <div className='flex flex-row justify-between px-4 pb-1'>
                    <p className='font-zen-body font-normal text-base text-zen-dark-7 mr-1'>
                      Cap Reset:
                    </p>
                    <p className='font-zen-title font-semibold text-lg text-zen-dark-9'>
                      {DateTime.fromISO(
                        incomeOverviewResponse?.anniversaryDate!,
                      ).toFormat('MM/dd/yyyy')}
                    </p>
                  </div>
                  <div className='flex justify-between px-4'>
                    <div className='flex space-x-2 items-start'>
                      {incomeOverviewResponse?.teamCapStatus?.capped && (
                        <div className='flex flex-col items-center font-zen-body'>
                          <div className='flex flex-col text-zen-dark-8 items-center'>
                            <FaCheckCircle />
                            <p className='text-sm '>Capped</p>
                          </div>
                          <p className='text-sm text-zen-dark-8'>
                            {getCompactCappedAmount(
                              incomeOverviewResponse.teamCapStatus?.capAmount
                                ?.amount!,
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='px-4'>
                  {incomeOverviewResponse?.teamCapStatus?.capped ? (
                    <div>
                      <div className='mb-5'>
                        <CapStatusTracker
                          capStatus={incomeOverviewResponse?.teamCapStatus!}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <ZenHalfPieProgress
                        progress={calculateCapPercentage(
                          incomeOverviewResponse?.teamCapStatus,
                        )}
                        text={`${displayAmount(
                          incomeOverviewResponse?.teamCapStatus?.capAmountPaid,
                          displayAmountOptions,
                        )} / ${displayAmount(
                          incomeOverviewResponse?.teamCapStatus?.capAmount,
                          displayAmountOptions,
                        )}`}
                        blurText={demoModeEnabled}
                        activeTintColor='#4E8EF6'
                      />

                      <p
                        className={cn(
                          'text-center font-bold text-xl text-error',
                          demoModeEnabled && 'blur-md',
                        )}
                      >
                        {getCapCycleRemainingBalance(
                          incomeOverviewResponse.teamCapStatus,
                          displayAmountOptions,
                        )}
                      </p>
                    </div>
                  )}
                  <br />
                </div>
              </AuthorizationContainer>
            </ZenCard>
          )}

          {/* <ZenCard
          title='Transactions'
          RightComponent={
            !zeroTransactions ? (
              <ZenView
                onClick={() =>
                  history.push(
                    `/people/${agentDetailResponse?.id!}/transactions`,
                  )
                }
                text='View'
              />
            ) : undefined
          }
        >
          <div className='grid grid-flow-row lg:grid-flow-col items-center'>
            <div>
              <CircleSplitChart
                innerRadius={40}
                padAngle={5}
                data={transactionsGraphDataItems}
                width={300}
                xKey='name'
                yKey='value'
              />
            </div>

            <div className='px-3'>
              <ZenPieChartItem
                color='#304676'
                text={`Active (${openTransactionsCount})`}
              />
              <ZenPieChartItem
                color='#3B82F6'
                text={`Closed (${closedTransactionsCount})`}
              />
              <ZenPieChartItem
                color='#54C5EB'
                text={`Draft (${draftTransactionsCount})`}
              />
            </div>
          </div>
        </ZenCard>

        <ZenCard
          title='Listings'
          RightComponent={
            !zeroListings ? (
              <ZenView
                onClick={() =>
                  history.push(`/people/${agentDetailResponse?.id!}/listings`)
                }
                text='View'
              />
            ) : undefined
          }
        >
          <div className='grid grid-flow-row lg:grid-flow-col items-center'>
            <div>
              <CircleSplitChart
                innerRadius={40}
                padAngle={5}
                data={listingsGraphDataItems}
                width={300}
                xKey='name'
                yKey='value'
              />
            </div>
            <div className='px-3'>
              <ZenPieChartItem
                color='#304676'
                text={`Active (${openListingsCount})`}
              />
              <ZenPieChartItem
                color='#3B82F6'
                text={`Closed (${closedListingsCount})`}
              />
              <ZenPieChartItem
                color='#54C5EB'
                text={`Draft (${draftListingsCount})`}
              />
            </div>
          </div>
        </ZenCard> */}

          <div
            className={classNames(
              agentNotRejectedOrCandidate
                ? 'flex flex-col space-y-4'
                : 'hidden',
            )}
          >
            {/* My Upline */}
            <ZenCard
              resize
              title='My Upline'
              bottomAction={
                !!actualUplineSponsors?.length ? (
                  <div className='flex w-full flex-row items-center space-x-2 justify-center'>
                    <p>View All</p> <HiOutlineArrowNarrowRight />
                  </div>
                ) : undefined
              }
              onBottomActionClick={() => setShowUplineSidebar(true)}
              focusId={KBarActionEnum.UPLINE}
            >
              <AuthorizationContainer asyncResponse={networksByIdResponse}>
                <ResourceContainer
                  loading={agentUplineResponse?.loading!}
                  isEmpty={!actualUplineSponsors?.length}
                  errorCode={agentUplineResponse?.error}
                  resourceName='upline'
                  emptyMessage='You do not have any upline.'
                  emptyIcon={
                    <FontAwesomeIcon
                      icon={faUserXmark}
                      size='xl'
                      className='mb-1.5'
                    />
                  }
                >
                  <div className='border-b border-zen-light-gray-2 divide-y divide-zen-light-gray-2'>
                    {actualUplineSponsors?.slice(0, 5)?.map((agent) => (
                      <div key={agent?.id} className='px-4'>
                        {isAdmin ? (
                          <Link
                            to={`/people/${agent?.id}`}
                            className='cursor-pointer'
                          >
                            <ZenUplineRow
                              key={agent?.id}
                              name={`${agent?.firstName} ${agent?.lastName}`}
                              level={agent?.level}
                              imageUrl={agent?.avatar!}
                              email={agent?.emailAddress!}
                              phone={agent?.phoneNumber!}
                              sponsorSplit={agent?.revShareSplit}
                              status={agent?.status!}
                            />
                          </Link>
                        ) : (
                          <ZenUplineRow
                            key={agent?.id}
                            name={`${agent?.firstName} ${agent?.lastName}`}
                            level={agent?.level}
                            imageUrl={agent?.avatar!}
                            email={agent?.emailAddress!}
                            phone={agent?.phoneNumber!}
                            sponsorSplit={agent?.revShareSplit}
                            status={agent?.status!}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </ResourceContainer>
              </AuthorizationContainer>
            </ZenCard>

            <div className='flex flex-grow'>
              <ZenCard
                resize
                title='My Network'
                focusId={KBarActionEnum.NETWORK}
                bottomAction={
                  <AuthorizedOnly asyncResponse={networksByIdResponse}>
                    {!!networks?.length && (
                      <div className='flex w-full flex-row items-center space-x-2  justify-center'>
                        <p>View All</p> <HiOutlineArrowNarrowRight />
                      </div>
                    )}
                  </AuthorizedOnly>
                }
                onBottomActionClick={() =>
                  setSelectAgents([
                    {
                      id: agentDetailResponse?.id!,
                      firstName: agentDetailResponse?.firstName!,
                      lastName: agentDetailResponse?.lastName!,
                      phoneNumber: agentDetailResponse?.phoneNumber!,
                      emailAddress: agentDetailResponse?.emailAddress!,
                      avatar: agentDetailResponse?.avatar!,
                      status: (agentDetailResponse?.agentStatus! as unknown) as FrontLineAgentResponseStatusEnum,
                      sizeOfNetwork: agentDetailResponse?.sizeOfNetwork!,
                    },
                  ])
                }
                titleAdjacentComponent={
                  <Popover
                    isOpen={isNetworkTooltipOpen}
                    positions={['top', 'bottom']}
                    padding={5}
                    align='center'
                    onClickOutside={() => setIsNetworkTooltipOpen(false)}
                    content={({ position, childRect, popoverRect }) => {
                      return (
                        <ArrowContainer
                          position={position}
                          popoverRect={popoverRect}
                          childRect={childRect}
                          arrowColor='white'
                          arrowSize={10}
                          className='popover-arrow-container drop-shadow-lg'
                        >
                          <div
                            data-testid='my-network-tooltip'
                            className='w-[365px] rounded-xl bg-white drop-shadow-lg font-zen-body font-normal text-zen-dark-9 text-base'
                          >
                            <div className='flex justify-between px-4 pt-4'>
                              <p className='font-zen-title text-lg font-medium'>
                                My Network
                              </p>
                              <FontAwesomeIcon
                                icon={faCircleXmark}
                                className='text-xl text-zen-dark-10 cursor-pointer'
                                onClick={() => setIsNetworkTooltipOpen(false)}
                              />
                            </div>
                            <div className='scrollbar overflow-y-auto max-h-96 px-4 pb-4'>
                              <p className='mt-1.5'>
                                The <b className='font-semibold'>Lock Icon</b> (
                                <FontAwesomeIcon
                                  icon={faLockOpen}
                                  title='unlocking-icon'
                                  className='mx-0.5 text-green-600'
                                />
                                ) next to the agent&apos;s name indicates
                                whether or not the agent is a tier-unlocking
                                agent.
                              </p>
                              <p className='mt-2'>
                                A{' '}
                                <b className='font-semibold'>tier-unlocking</b>{' '}
                                agent is active and has at least{' '}
                                {getCommissionAmount(
                                  agentDetailResponse?.accountCountry!,
                                )}{' '}
                                in closed commission in the last 6 months.
                              </p>

                              <p className='mt-4'>
                                <b className='font-semibold'>Expiration Date</b>{' '}
                                is the date that the agent&apos;s tier-unlocking
                                status expires.
                              </p>

                              <p className='mt-2'>
                                A <b className='font-semibold'>producing</b>{' '}
                                agent is an agent who is active and has at least{' '}
                                {getCommissionAmount(
                                  agentDetailResponse?.accountCountry!,
                                )}{' '}
                                in closed commission in the last 6 months. Real
                                agents are given a 6 month grace period after
                                joining where they are considered producing.
                                Only producing agents may receive revenue share.
                              </p>
                              <p className='mt-2'>
                                <b className='font-semibold'>
                                  {' '}
                                  Joining Momentum
                                </b>{' '}
                                is a Real program for sponsors who are in their
                                6 month grace period. During this time all of
                                their active front line agents will be
                                considered as tier-unlocking.
                              </p>

                              <p className='mt-4 flex flex-wrap'>
                                And the number within the grey circle (
                                <span className='flex items-center justify-center h-5 w-8 mt-[3px] bg-zen-light-gray-2 rounded-full'>
                                  <span className='font-zen-body text-sm  text-zen-dark-9'>
                                    0
                                  </span>
                                </span>
                                ){' '}
                                <span>
                                  stands for how many agents that specific agent
                                  brought into their network.
                                </span>
                              </p>

                              <p className='mt-4'>
                                The percentage next to the agent&apos;s name is
                                the percent of sponsorship. In a co-sponsorship
                                scenario this percentage will be less than 100%.
                              </p>
                            </div>
                          </div>
                        </ArrowContainer>
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className='ml-2 text-base text-zen-dark-9 cursor-pointer text-center flex items-center justify-center'
                      onClick={() => setIsNetworkTooltipOpen(true)}
                      title='network-tooltip-icon'
                    />
                  </Popover>
                }
              >
                <AuthorizationContainer asyncResponse={networksByIdResponse}>
                  <ResourceContainer
                    loading={!networks}
                    isEmpty={networks?.length === 0}
                    resourceName='network'
                    emptyMessage='You have not referred any other agents.'
                    emptyIcon={
                      <FontAwesomeIcon
                        icon={faUserXmark}
                        size='xl'
                        className='mb-1.5'
                      />
                    }
                  >
                    <div className='border-b border-zen-light-gray-2 divide-y divide-zen-light-gray-2'>
                      {getSortedNetworks(networks || [])
                        ?.slice(0, 3)
                        .map((agent) => (
                          <div key={agent.id} className='px-4'>
                            <ZenNetworkRow
                              key={agent.id}
                              id={agent.id!}
                              name={`${agent.firstName} ${agent.lastName}`}
                              tier={START_TIER}
                              imageUrl={agent.avatar!}
                              sizeOfNetwork={agent?.sizeOfNetwork || 0}
                              email={agent?.emailAddress!}
                              phone={agent?.phoneNumber!}
                              status={agent?.status!}
                              sponsorSplit={agent?.sponsorSplit! || 0}
                              unlocking={!!agent?.unlocking!}
                              expiryDate={agent?.unlockingExpiryDate!}
                              openAgentProfile={isAdmin}
                              onClick={() => {
                                setSelectAgents([
                                  {
                                    id: agentDetailResponse?.id!,
                                    firstName: agentDetailResponse?.firstName!,
                                    lastName: agentDetailResponse?.lastName!,
                                    emailAddress: agentDetailResponse?.emailAddress!,
                                    phoneNumber: agentDetailResponse?.phoneNumber!,
                                    avatar: agentDetailResponse?.avatar!,
                                    status: (agentDetailResponse?.agentStatus! as unknown) as FrontLineAgentResponseStatusEnum,
                                    sizeOfNetwork: agentDetailResponse?.sizeOfNetwork!,
                                  },
                                  agent,
                                ]);
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </ResourceContainer>
                </AuthorizationContainer>
              </ZenCard>
            </div>
          </div>

          {/* Commission  */}

          {agentNotRejectedOrCandidate && (
            <ZenCardWithItems
              title='Commission'
              RightComponent={
                <ZenView
                  onClick={() =>
                    history.push(
                      `/people/${agentDetailResponse?.id!}/transactions`,
                    )
                  }
                />
              }
              variant='regular'
              items={[
                {
                  name: 'Total Commission',
                  value: displayAmount(
                    incomeOverviewResponse?.grossCommission,
                    displayAmountOptions,
                  ),
                  ValueWrapper: DemoModeContainer,
                },
                {
                  name: 'Net Commission',
                  value: displayAmount(
                    incomeOverviewResponse?.netCommission,
                    displayAmountOptions,
                  ),
                  ValueWrapper: DemoModeContainer,
                },
                {
                  name: 'Closed Transactions',
                  value: `${incomeOverviewResponse?.numTransactions!}`,
                  ValueWrapper: DemoModeContainer,
                },
                {
                  name: 'Pending Total Commission',
                  value: displayAmount(
                    incomeOverviewResponse?.pendingTotalCommission,
                    displayAmountOptions,
                  ),
                  ValueWrapper: DemoModeContainer,
                },
                {
                  name: 'Pending Net Commission',
                  value: displayAmount(
                    incomeOverviewResponse?.pendingNetCommission,
                    displayAmountOptions,
                  ),
                  ValueWrapper: DemoModeContainer,
                },
              ]}
              authResponse={agentDetail.incomeOverviewResponse}
              focusId={KBarActionEnum.MY_COMMISSION}
            />
          )}

          {agentNotRejectedOrCandidate && (
            <ZenCardWithItems
              title='Equity'
              RightComponent={
                <ZenView
                  onClick={() =>
                    history.push(`/people/${agentDetailResponse?.id!}/equity`)
                  }
                />
              }
              variant='regular'
              items={[
                {
                  name: 'Total Pre Cap Contribution Amount',
                  value: displayAmount(
                    agentDetail.equityResponse.data
                      ?.yearlyPreCapPurchasedAmount,
                    displayAmountOptions,
                  ),
                },
                {
                  name: 'Total Post Cap Contribution Amount',
                  value: displayAmount(
                    agentDetail.equityResponse.data
                      ?.yearlyPostCapPurchasedAmount,
                    displayAmountOptions,
                  ),
                },
                {
                  name: 'Total Contribution Amount',
                  value: displayAmount(
                    agentDetail.equityResponse.data?.yearlyPurchasedAmount,
                    displayAmountOptions,
                  ),
                },
                {
                  name: 'Pending Contribution Amount',
                  value: displayAmount(
                    {
                      amount:
                        agentDetail.equityResponse.data
                          ?.yearlyPreCapPendingAmount?.amount! +
                        agentDetail.equityResponse.data
                          ?.yearlyPostCapPendingAmount?.amount!,
                      currency:
                        agentDetail.equityResponse.data
                          ?.yearlyPreCapPendingAmount?.currency,
                    },
                    displayAmountOptions,
                  ),
                  tooltipInfo: !!minPurchaseAmount
                    ? `Equity contribution will only be purchased when agent has accumulated over ${displayAmount(
                        minPurchaseAmount,
                      )}`
                    : undefined,
                },
              ]}
              focusId={KBarActionEnum.MY_EQUITY}
            />
          )}

          {agentNotRejectedOrCandidate && (
            <RevShareInsightsOverviewCard
              onClickBeneficiary={() => {
                if (!isWillBeneficiaryTypeNone) {
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.WILLABLE_REVSHARE_BENEFICIARY_UPDATE,
                  );
                  setShowUpdateBeneficiaryModal(true);
                } else {
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.WILLABLE_REVSHARE_BENEFICIARY_ADD,
                  );
                  setShowDesignateBeneficiaryModal(true);
                }
              }}
            />
          )}

          {/* Teams */}
          {agentNotRejectedOrCandidate && (
            <ZenTeams asyncResponse={agentDetail?.detailResponse} />
          )}
          {/* OfficeTabs  */}
          <ZenCard
            testId='offices-card'
            title='Offices'
            RightComponent={
              <AuthorizedOnly asyncResponse={agentDetail.detailResponse}>
                <AdminOnly>
                  <ZenAdd onClick={() => setIsAddOfficeOpen(true)} />
                </AdminOnly>
              </AuthorizedOnly>
            }
          >
            <AuthorizationContainer asyncResponse={agentDetail?.detailResponse}>
              <ResourceContainer
                loading={agentDetail.detailResponse.loading}
                isEmpty={agentDetailResponse?.offices?.length === 0}
                resourceName='office'
                emptyIcon={
                  <FontAwesomeIcon
                    icon={faBuildings}
                    size='2x'
                    className='-scale-x-90 mb-0.5'
                  />
                }
              >
                <ZenOfficeTabs
                  offices={agentDetailResponse?.offices!}
                  agentId={agentDetailResponse?.id!}
                />
              </ResourceContainer>
            </AuthorizationContainer>
          </ZenCard>
          <ZenCard
            title='Licenses'
            RightComponent={
              <AuthorizedOnly asyncResponse={agentDetail.detailResponse}>
                <AdminOnly>
                  <ZenAction
                    text='Add'
                    icon={
                      <FontAwesomeIcon
                        className={cn(
                          isGeminiEnabled
                            ? 'font-light text-[13px] leading-[23px] text-rezen-blue-600'
                            : 'text-primary-blue text-sm',
                        )}
                        icon={faPlus}
                      />
                    }
                    onClick={() => setEditingLicenses(true)}
                  />
                </AdminOnly>
              </AuthorizedOnly>
            }
          >
            <AuthorizationContainer asyncResponse={agentDetail?.detailResponse}>
              <ResourceContainer
                loading={agentDetail.detailResponse.loading}
                isEmpty={agentDetailResponse?.licenses?.length === 0}
                resourceName='licenses'
                EmptyComponent={
                  <DefaultEmpty
                    message='There are no licenses to display.'
                    icon={<FaAddressCard size={38} />}
                  />
                }
              >
                <ZenAgentLicenses licenses={agentDetailResponse?.licenses!} />
              </ResourceContainer>
              {editingLicenses && (
                <ZenEditAgentLicense
                  licenseImages={[]}
                  agent={agentDetailResponse!}
                  readOnly={!isAdmin}
                  isOpen={editingLicenses}
                  onClose={() => setEditingLicenses(false)}
                />
              )}
            </AuthorizationContainer>
          </ZenCard>
          <ZenMlsCard agentResponse={agentDetail.detailResponse} />
          <ZenBoardCard agentResponse={agentDetail.detailResponse} />
          <ZenAgentContractCardV2 detailResponse={agentDetail.detailResponse} />
          {agentNotRejectedOrCandidate && (
            <SelfOrAdminOnly agentId={agentDetailResponse?.id}>
              <ZenTaxInformation
                detailResponse={agentDetail?.detailResponse}
                nationalIdentificationsResponse={
                  agentDetail?.nationalIdentificationsResponse
                }
              />
            </SelfOrAdminOnly>
          )}
          <SelfOrAdminOnly agentId={agentDetailResponse?.id!}>
            <ZenSecurityProfileCard />
          </SelfOrAdminOnly>
          {agentNotRejectedOrCandidate && isRealTitleFlagEnabled && (
            <SelfOrAdminOnly agentId={agentDetailResponse?.id!}>
              <ZenRealJointVentureTiers jointVentureInvites={invitedJV} />
            </SelfOrAdminOnly>
          )}
          {agentNotRejectedOrCandidate && (
            <DemoModeContainer hide>
              <ZenCard
                title='Debts'
                RightComponent={
                  <AuthorizedOnly
                    asyncResponse={agentDetail?.paginatedAgentDebtsResponse}
                  >
                    <AdminOnly>
                      <ZenAction
                        text={
                          !!agentDetail?.paginatedAgentDebtsResponse?.data
                            ?.agentDebts?.length
                            ? 'Edit'
                            : 'Add'
                        }
                        onClick={() => {
                          AnalyticsService.instance().logEvent(
                            AnalyticsEventEnum.AGENT_OWES_REAL_CARD_EDIT_BUTTON_CLICK,
                          );
                          history.push(
                            `/people/${agentDetailResponse?.id}/all-debts`,
                          );
                        }}
                        icon={
                          <FontAwesomeIcon
                            icon={faPen}
                            className={cn(
                              isGeminiEnabled
                                ? 'font-light text-[13px] leading-[23px] text-rezen-blue-600'
                                : 'text-primary-blue text-sm',
                            )}
                          />
                        }
                      />
                    </AdminOnly>
                  </AuthorizedOnly>
                }
                bottomAction={
                  !agentDetail?.paginatedAgentDebtsResponse?.loading &&
                  !!agentDetail?.paginatedAgentDebtsResponse?.data?.agentDebts
                    ?.length ? (
                    <AuthorizedOnly
                      asyncResponse={agentDetail?.paginatedAgentDebtsResponse}
                    >
                      <div className='flex flex-row items-center space-x-2 justify-center'>
                        <p>View All</p> <HiOutlineArrowNarrowRight />
                      </div>
                    </AuthorizedOnly>
                  ) : undefined
                }
                onBottomActionClick={() => {
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.AGENT_OWES_REAL_VIEW_ALL_BUTTON_CLICK,
                  );
                  history.push(`/people/${agentDetailResponse?.id}/all-debts`);
                }}
              >
                <ResourceContainer
                  loading={agentDebtLoading}
                  isEmpty={!activeDebts?.length}
                  resourceName='active debt'
                  errorCode={agentDetail?.paginatedAgentDebtsResponse?.error}
                  emptyIcon={
                    <FontAwesomeIcon
                      icon={faMoneyCheckDollar}
                      size='2x'
                      className='text-zen-dark-7'
                    />
                  }
                >
                  {activeDebts?.slice(0, 3)?.map((debt) => {
                    return (
                      <div
                        className='flex flex-col border-b border-zen-light-gray-2'
                        key={debt?.id}
                      >
                        <DebtsCardRowCell debt={debt} />
                      </div>
                    );
                  })}
                </ResourceContainer>
              </ZenCard>
            </DemoModeContainer>
          )}

          <OneRealImpactCard />
        </div>

        {agentNotRejectedOrCandidate && (
          <AdminOnly>
            <div className='col-span-12 px-0 md:px-4 py-4'>
              <ZenAgentCredits agentResponse={agentDetail.detailResponse} />
            </div>
          </AdminOnly>
        )}
        {agentNotRejectedOrCandidate && (
          <div className='col-span-12 px-0 md:px-4 py-4'>
            <ZenAgentCommissionAdvances agentId={agentId} />
          </div>
        )}

        <AdminOrBrokerOnly>
          <div className='col-span-12 px-0 md:px-4 py-4'>
            <ZenCard title='Internal Notes'>
              <AuthorizationContainer
                asyncResponse={agentDetail?.detailResponse}
              >
                <ZenNotesContainer
                  entityId={agentDetailResponse?.id!}
                  entityType={CreateNoteRequestEntityTypeEnum.Agent}
                />
              </AuthorizationContainer>
            </ZenCard>
          </div>
        </AdminOrBrokerOnly>
        {selectAgents && (
          <ZenNetwork
            isOpen={!!selectAgents.length}
            onClose={() => setSelectAgents(null)}
            selectAgents={selectAgents!}
          />
        )}
        {showUplineSidebar && (
          <ZenUpline
            isOpen
            onClose={() => setShowUplineSidebar(false)}
            agentId={agentId}
          />
        )}
        {isAddOfficeOpen && (
          <ZenAddOfficeToUserSidebarModal
            isOpen
            onClose={() => setIsAddOfficeOpen(false)}
            agentId={agentDetailResponse?.id!}
          />
        )}
        <DesignateBeneficiaryModal
          isOpen={showDesignateBeneficiaryModal}
          onClose={() => {
            setShowDesignateBeneficiaryModal(false);
          }}
        />
        <UpdateBeneficiaryModal
          isOpen={showUpdateBeneficiaryModal}
          onClose={() => {
            setShowUpdateBeneficiaryModal(false);
          }}
          beneficiary={willBeneficiary!}
        />
      </ZenRoute>
    </ResourceContainer>
  );
};

export default ZenAgentOverviewRoute;
