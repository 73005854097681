import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../types';
import ZenSimpleModal from '../Zen/Modal/ZenSimpleModal';
import ZenButton from '../Zen/ZenButton';

interface SuccessModalProps {
  onClose(): void;
}
const SuccessModal: React.FC<SuccessModalProps> = ({ onClose }) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  return (
    <ZenSimpleModal size='large' isOpen onClose={onClose} hideHeader>
      <div className='p-4 pt-10 flex flex-col items-center text-center'>
        <div className='text-green-600 border-green-600 border-2 w-16 h-16 rounded-full mb-6 flex items-center justify-center'>
          <FontAwesomeIcon icon={faCheck} size='2xl' />
        </div>
        <div className='text-lg mb-2 font-inter font-medium'>
          Success - you&apos;ve opted in to receive text messages!
        </div>
        <div className='font-thin text-base font-inter'>
          You&apos;ll receive text messages at your business phone <br />{' '}
          number. To update your phone number, <br />
          please visit your{' '}
          <Link to={`/people/${userDetail?.id}`} target='_blank'>
            <span className=' text-rezen-blue-600 cursor-pointer underline'>
              profile.
            </span>
          </Link>
        </div>
        <ZenButton label='Ok' onClick={onClose} className='w-60 mt-8' />
      </div>
    </ZenSimpleModal>
  );
};

export default SuccessModal;
