import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
// import ZenSidebarModalActionFooterV2 from '../../Modal/ZenSidebarModalActionFooterV2';
import { LeaderDto } from '../../../../routes/ZenTeamsProDetailsRoute';
import {
  FeeSplitFeeTypeEnum,
  // FlexTeamDtoCdaApprovalRolesEnum,
} from '../../../../openapi/yenta';
import { getRemainingPercentage } from '../../../../utils/TeamHelper';
import RealBEOPFeePopover from './ProTeamPopover/RealBEOPFeePopover';
import RealSplitFeePopover from './ProTeamPopover/RealSplitFeePopover';
import RealBrokerageFeePopover from './ProTeamPopover/RealBrokerageFeePopover';
import RealPostCapTransactionFeePopover from './ProTeamPopover/RealPostCapTransactionFeePopover';

interface FormData {
  realCap: string;
  totalRealSplit: string;
  totalBEOPFee: string;
  totalBrokerageFee: string;
  totalTransactionFee: string;
}

interface ZenAgentFeesTabProps {
  onClose(): void;
  leaders: LeaderDto[];
}
const ZenAgentFeesTab: React.FC<ZenAgentFeesTabProps> = ({
  // onClose,
  leaders,
}) => {
  const { control, handleSubmit, setValue } = useForm<FormData>();

  useEffect(() => {
    const leaderDetails = leaders.map((leader) => leader.leaderDetails);
    let feeTypeTotals: any = {};
    leaderDetails.forEach((item) => {
      item?.feeSplits?.forEach((feeSplit) => {
        const { feeType, percent } = feeSplit;
        feeTypeTotals[feeType] = (feeTypeTotals[feeType] || 0) + percent;
      });
    });
    setValue(
      'totalRealSplit',
      getRemainingPercentage(feeTypeTotals[FeeSplitFeeTypeEnum.RealSplit]),
    );
    setValue(
      'totalBEOPFee',
      getRemainingPercentage(feeTypeTotals[FeeSplitFeeTypeEnum.BeopFee]),
    );
    setValue(
      'totalBrokerageFee',
      getRemainingPercentage(feeTypeTotals[FeeSplitFeeTypeEnum.BrokerageFee]),
    );
    setValue(
      'totalTransactionFee',
      getRemainingPercentage(feeTypeTotals[FeeSplitFeeTypeEnum.TransactionFee]),
    );
  }, [leaders, setValue]);

  const onSubmit = () => {};

  return (
    <form
      className='flex flex-col justify-between max-h-[calc(100vh-98px)]'
      onSubmit={handleSubmit(onSubmit)}
      title='edit-team-form'
    >
      <div className='p-5 pb-28 h-full overflow-y-auto'>
        <div className='mt-5'>
          <div className='font-normal font-primary-medium text-lg text-zen-dark-9'>
            Individual Fee
          </div>
          <div className='h-px bg-zen-dark-5 mt-2' />
          <div className='mt-5 font-inter text-sm text-zen-dark-7'>
            This is the percentage of each fee a teammate pays to Real.
          </div>
          <div className='space-y-2'>
            <div className='flex flex-row justify-between items-center mt-5'>
              <RealSplitFeePopover />
              <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                <ZenControlledTextInput<FormData, 'totalRealSplit'>
                  label='Teammate Pays to Real'
                  placeholder='Real Split'
                  control={control}
                  type='number'
                  shouldUnregister={false}
                  startAdornment={
                    <div className='flex w-full h-full items-center justify-center pl-2 bg-gray-50'>
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={solid('percentage')}
                      />
                    </div>
                  }
                  name='totalRealSplit'
                  readOnly
                />
              </div>
            </div>
            <div className='flex flex-row justify-between items-center'>
              <RealBEOPFeePopover />
              <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                <ZenControlledTextInput<FormData, 'totalBEOPFee'>
                  control={control}
                  type='number'
                  name='totalBEOPFee'
                  placeholder='Real BEOP Fee'
                  startAdornment={
                    <div className='flex w-full h-full items-center justify-center pl-2 bg-gray-50'>
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={solid('percentage')}
                      />
                    </div>
                  }
                  shouldUnregister={false}
                  readOnly
                />
              </div>
            </div>
            <div className='flex flex-row justify-between items-center'>
              <RealBrokerageFeePopover />
              <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                <ZenControlledTextInput<FormData, 'totalBrokerageFee'>
                  control={control}
                  type='number'
                  placeholder='Real Brokerage Fee'
                  startAdornment={
                    <div className='flex w-full h-full items-center justify-center pl-2 bg-gray-50'>
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={solid('percentage')}
                      />
                    </div>
                  }
                  name='totalBrokerageFee'
                  shouldUnregister={false}
                  readOnly
                />
              </div>
            </div>

            <div className='flex flex-row justify-between items-center'>
              <RealPostCapTransactionFeePopover />
              <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                <ZenControlledTextInput<FormData, 'totalTransactionFee'>
                  control={control}
                  type='number'
                  placeholder='Transaction Fee'
                  startAdornment={
                    <div className='flex w-full h-full items-center justify-center pl-2 bg-gray-50'>
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={solid('percentage')}
                      />
                    </div>
                  }
                  name='totalTransactionFee'
                  shouldUnregister={false}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ZenSidebarModalActionFooterV2
        onClose={onClose}
        isSubmitting={isSubmitting}
        submitButtonText='Update'
      /> */}
    </form>
  );
};

export default ZenAgentFeesTab;
