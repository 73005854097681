import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faStar } from '@fortawesome/pro-regular-svg-icons';
import ADescIcon from '../../assets/img/a-desc.svg';
import MarkIcon from '../../assets/img/mark-desc.svg';
import {
  TitleMilestone,
  TitleMilestoneStatusEnum,
} from '../../openapi/arrakis';
import { EnumMap } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import { cn } from '../../utils/classUtils';

interface TitleProgressMilestonesProps {
  milestones?: TitleMilestone[];
  isCaliforniaTransaction?: boolean;
}

const TitleProgressMilestones: React.FC<TitleProgressMilestonesProps> = ({
  milestones,
  isCaliforniaTransaction,
}) => {
  const backGroundColorMap: EnumMap<TitleMilestoneStatusEnum, string> = {
    [TitleMilestoneStatusEnum.Completed]: 'bg-success',
    [TitleMilestoneStatusEnum.Pending]: 'bg-warning',
  };

  const milestoneIconMap: EnumMap<string, React.ReactElement> = {
    0: <FontAwesomeIcon icon={faStar} className='text-white' />,
    1: <div className='text text-white font-primary-medium'>A</div>,
    2: <img src={ADescIcon} alt='pre-closing' />,
    3: <FontAwesomeIcon icon={faDollarSign} className='text-white' />,
    4: <img src={MarkIcon} alt='post-closing' />,
  };

  return (
    <div className='w-full max-w-md'>
      <p className='font-primary-medium text-xl text-dark mb-3'>
        {isCaliforniaTransaction ? 'Escrow Progress' : 'Title Progress'}
      </p>
      <div className='p-5 border rounded'>
        <div className='relative'>
          <div className='absolute w-0.5 bg-gray-200 h-full left-5 -ml-0.5' />
          <div className='space-y-8'>
            {milestones?.map((milestone, index) => (
              <div key={milestone.name} className='flex items-center space-x-3'>
                <div className='bg-gray-200 p-2 rounded-full z-10 flex items-center justify-center'>
                  <div
                    className={cn(
                      'w-6 h-6 rounded-full p-1 flex items-center justify-center',
                      backGroundColorMap[milestone.status!],
                    )}
                  >
                    {milestoneIconMap[index]}
                  </div>
                </div>
                <p className='font-primary-regular text-base text-dark capitalize'>
                  {capitalizeEnum(milestone.name!)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleProgressMilestones;
