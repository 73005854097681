import { values } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpArrowDown } from '@fortawesome/pro-regular-svg-icons';
import {
  AddressStateEnum,
  LocationInfoRequest,
  LocationInfoRequestStateEnum,
} from '../../../../openapi/arrakis';
import {
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaResponseCountryEnum,
} from '../../../../openapi/yenta';
import {
  getTransactionBuilderFeatures,
  saveLocationInfos,
} from '../../../../slices/TransactionBuilderSlice';
import { AppDispatch, RootState } from '../../../../types';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import { getAddressFromAddressComponent } from '../../../../utils/TransactionHelper';
import {
  GOOGLE_AUTO_COMPLETE_VALIDATIONS,
  YEAR_VALIDATIONS,
} from '../../../../utils/Validations';
import IconButton from '../../../IconButton';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledGoogleAutocompleteSearchInput from '../../Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledHTMLSelectInput from '../../Input/ZenControlledHTMLSelectInput';
import ZenControlledStateOrProvinceInput from '../../Input/ZenControlledStateOrProvince';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenSimpleConfirmationModal from '../../Modal/ZenSimpleConfirmationModal';
import ZenButton from '../../ZenButton';
import { isCanadianUser } from '../../../../utils/AgentHelper';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
  Match,
} from './ZenCreateTransactionSteps';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';

const ZenTransactionAddressStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({ form: { control, setValue, watch, trigger }, onNext }) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [
    isManualAddress,
    address,
    location,
    mlsNumber,
    yearBuilt,
    escrowNumber,
    unitNo,
    manualAddressUnitNo,
  ] = watch([
    'isManualAddress',
    'address',
    'location',
    'mlsNumber',
    'yearBuilt',
    'escrowNumber',
    'unitNo',
    'manualAddressUnitNo',
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { transactionBuilderId } = useParams<Match>();
  const isCanadaUser = isCanadianUser(userDetail?.accountCountry!);

  const handleAddressStep = async () => {
    const isValid = await trigger();
    if (isValid) {
      const locationInfoRequest: LocationInfoRequest = {
        street:
          isManualAddress || !location?.place_id
            ? address?.street!
            : getAddressFromAddressComponent(location?.address_components!)
                ?.streetAddress1,
        street2:
          isManualAddress || !location?.place_id
            ? address?.street2!
            : getAddressFromAddressComponent(location?.address_components!)
                ?.streetAddress2,
        city:
          isManualAddress || !location?.place_id
            ? address?.city!
            : getAddressFromAddressComponent(location?.address_components!)
                ?.city!,
        state:
          isManualAddress || !location?.place_id
            ? ((address?.state! as unknown) as LocationInfoRequestStateEnum)
            : ((getAddressFromAddressComponent(location?.address_components!)
                ?.stateOrProvince! as unknown) as LocationInfoRequestStateEnum),
        zip:
          isManualAddress || !location?.place_id
            ? address?.zip!
            : getAddressFromAddressComponent(location?.address_components!)
                ?.zipOrPostalCode!,
        mlsNumber: mlsNumber!,
        yearBuilt: yearBuilt,
        escrowNumber: escrowNumber,
        unit: isManualAddress ? manualAddressUnitNo : unitNo,
      };

      setLoading(true);
      const res = await dispatch(
        saveLocationInfos(transactionBuilderId!, locationInfoRequest),
      );
      await dispatch(getTransactionBuilderFeatures(transactionBuilderId));
      setLoading(false);
      if (res) {
        onNext();
      }
    }
  };

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <p className='text-xl font-zen-title font-medium py-2 text-zen-dark-9'>
          Hi, {userDetail?.fullName} 👋
        </p>
        <p className='mb-4 font-zen-body font-normal text-zen-dark-7'>
          We’ll guide you through a few steps to create a new transaction.
        </p>
        {isManualAddress && (
          <div className='space-y-4'>
            <div>
              <ZenControlledTextInput<
                CreateTransactionFormState,
                'address.street'
              >
                control={control}
                label='Street'
                name='address.street'
                shouldUnregister={false}
                placeholder='E.g. 270 1st Ave.'
                rules={{
                  required: 'Street is required',
                }}
                isRequired
              />
            </div>
            <div className='flex flex-row space-x-4'>
              <ZenControlledTextInput<
                CreateTransactionFormState,
                'address.street2'
              >
                control={control}
                label='Street Line 2'
                name='address.street2'
                shouldUnregister={false}
                placeholder='E.g. 270 1st Ave.'
              />
              <ZenControlledHTMLSelectInput<
                CreateTransactionFormState,
                'address.country'
              >
                name='address.country'
                control={control}
                label='Country'
                shouldUnregister={false}
                placeholder='Country'
                options={[
                  {
                    label: 'Choose Country',
                    value: '',
                  },
                  ...values(AdministrativeAreaResponseCountryEnum).map(
                    (state) => ({
                      value: state,
                      label: capitalizeEnum(state),
                    }),
                  ),
                ]}
                rules={{
                  required: 'Please select country',
                }}
                isRequired
              />
            </div>
            <div className='flex flex-row space-x-4'>
              <ZenControlledTextInput<
                CreateTransactionFormState,
                'manualAddressUnitNo'
              >
                control={control}
                label='Unit No.'
                shouldUnregister={false}
                name='manualAddressUnitNo'
                placeholder='Eg: 123'
              />
              <ZenControlledTextInput<
                CreateTransactionFormState,
                'address.city'
              >
                control={control}
                label='City'
                name='address.city'
                shouldUnregister={false}
                placeholder='Eg. New York'
                rules={{
                  required: 'City is required',
                }}
                isRequired
              />
            </div>

            <div className='flex flex-row space-x-4'>
              <ZenControlledStateOrProvinceInput<
                CreateTransactionFormState,
                'address.state'
              >
                name='address.state'
                control={control}
                shouldUnregister={false}
                defaultValue={('' as unknown) as AddressStateEnum}
                setValue={setValue}
                selectedCountry={
                  (watch(
                    'address.country',
                  ) as unknown) as AdministrativeAreaRequestCountryEnum
                }
                rules={{
                  required: 'State is required',
                }}
                isRequired
              />

              <ZenControlledTextInput<CreateTransactionFormState, 'address.zip'>
                control={control}
                label='ZIP/Postal Code'
                name='address.zip'
                shouldUnregister={false}
                placeholder='E.g. 10001'
                rules={{
                  required: 'Zip code is required',
                }}
                isRequired
              />
            </div>
            {!isCanadaUser && (
              <div className='grid grid-cols-2 gap-x-4'>
                <ZenControlledTextInput<CreateTransactionFormState, 'yearBuilt'>
                  control={control}
                  shouldUnregister={false}
                  maxLength={4}
                  label='Year Built'
                  name='yearBuilt'
                  placeholder='Eg: 2020'
                  rules={{
                    required: 'Year built is required',
                    ...YEAR_VALIDATIONS,
                  }}
                  isRequired
                />
              </div>
            )}
          </div>
        )}

        {!isManualAddress && (
          <div className='space-y-4'>
            <ZenControlledGoogleAutocompleteSearchInput<
              CreateTransactionFormState,
              'location'
            >
              control={control}
              shouldUnregister={false}
              name='location'
              label='Search Address'
              subLabel='(Source by Google)'
              placeholder='E.g. 1st St. New York, NY 10010'
              rules={{
                ...GOOGLE_AUTO_COMPLETE_VALIDATIONS,
              }}
              isRequired
            />
            <div className='flex flex-row space-x-4'>
              <ZenControlledTextInput<CreateTransactionFormState, 'unitNo'>
                control={control}
                shouldUnregister={false}
                label='Unit No.'
                name='unitNo'
                placeholder='Eg: 123'
              />
              {!isCanadaUser && (
                <ZenControlledTextInput<CreateTransactionFormState, 'yearBuilt'>
                  control={control}
                  label='Year Built'
                  maxLength={4}
                  shouldUnregister={false}
                  name='yearBuilt'
                  placeholder='Eg: 2020'
                  rules={{
                    required: 'Year built is required',
                    ...YEAR_VALIDATIONS,
                  }}
                  isRequired
                />
              )}
            </div>
          </div>
        )}
        <div className='mt-6'>
          <IconButton
            label={
              isManualAddress ? 'Search Address' : 'Enter address manually'
            }
            variant='none'
            buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
            leftIcon={
              <FontAwesomeIcon icon={faArrowUpArrowDown} className='p-1' />
            }
            onClick={() => {
              setValue('isManualAddress', !isManualAddress);
            }}
          />
        </div>
        <div className='flex flex-row items-baseline space-x-4 mb-5 mt-12'>
          <ZenControlledTextInput<CreateTransactionFormState, 'mlsNumber'>
            control={control}
            label='MLS Number'
            subLabel='(enter N/A if this is an exclusive / non-MLS deal)'
            name='mlsNumber'
            shouldUnregister={false}
            placeholder='E.g. 120001234AB'
            rules={{
              required: 'MLS number is required.',
            }}
            isRequired
          />
          {!isCanadaUser && (
            <ZenControlledTextInput<CreateTransactionFormState, 'escrowNumber'>
              control={control}
              shouldUnregister={false}
              label='Escrow #'
              name='escrowNumber'
              placeholder='E.g. 01-12345-ABC'
            />
          )}
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Cancel'
              onClick={() => setCancel(true)}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              isDisabled={loading}
              label='Next'
              onClick={handleAddressStep}
            />
          </div>
        </div>
      </div>
      {cancel && (
        <ZenSimpleConfirmationModal
          isOpen
          title='Transaction Saved!'
          subtitle='This transaction will be saved in your drafts and will be available for future references.'
          variant='info'
          isSubmitting={false}
          confirmButtonText='Ok'
          onConfirm={() => history.push('/transactions/draft')}
          onClose={() => setCancel(false)}
          size='medium'
          hideCancelButton
        />
      )}
    </div>
  );
};

export default withCreateTransactionProgress(ZenTransactionAddressStep);
