import { faEmptySet } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ZenButton from '../../../Zen/ZenButton';

type OutOfOfficeEmptyProps = {
  onClick(): void;
};

const OutOfOfficeEmpty: React.FC<OutOfOfficeEmptyProps> = ({ onClick }) => {
  return (
    <div className='p-4 pb-8 flex flex-col items-center justify-center space-y-4'>
      <FontAwesomeIcon
        icon={faEmptySet}
        className='text-primary-dark'
        fontSize={16}
      />
      <p className='text-center font-inter text-primary-dark leading-6'>
        You have not yet <br />
        scheduled time off
      </p>
      <ZenButton label='Schedule' variant='primary-outline' onClick={onClick} />
    </div>
  );
};

export default OutOfOfficeEmpty;
