import {
  AgentResponse,
  AgentResponseAccountCountryEnum,
} from '../openapi/yenta';
import { EmailSupportType, EnumMap } from '../types';
import { safeEnumMapGet } from './EnumHelper';

export const APPLICATION_SUPPORT_SUBJECT = 'Application Support';
export const TRANSACTION_SUPPORT_SUBJECT = 'Transaction Support';
export const TECHNICAL_SUPPORT_SUBJECT = 'Technical Support';
export const OTHER_SUPPORT_SUBJECT = 'Other Support';
export const TAX_SUPPORT_SUBJECT = 'Tax Support';
export const CHANGE_MY_MLS_MEMBERSHIPS_SUBJECT = 'Change My MLS Memberships';
export const CHANGE_MY_BOARD_MEMBERSHIPS_SUBJECT =
  'Change My Board Memberships';
export const CHANGE_MY_LICENCES_SUBJECT = 'Change My Licenses';
export const VOICE_RECORDING_SUBJECT = 'ROAR: Voice opt-out request';

export const SUPPORT_EMAIL_ADDRESS = 'support@therealbrokerage.com';
export const CANADA_SUPPORT_EMAIL_ADDRESS = 'supportcad@therealbrokerage.com';

export const TRANSFER_LICENSE_KNOWLEDGE_BASE_URL =
  'https://therealbrokerage.zendesk.com/hc/en-us/sections/4408475447063-License-Transfer-Instructions';

interface EmailComposeOptions {
  app?: string | null;
  title?: string;
  message?: string;
  cancelLabel?: string;
  removeText?: boolean;
  to?: string;
  cc?: string;
  bcc?: string;
  subject?: string;
  body?: string;
}

export const openEmailClientForSupportEmail = async (
  type: EmailSupportType,
  user: AgentResponse,
  body: string = '',
) => {
  let composeOptions: EmailComposeOptions = {
    to: getSupportEmail(user.accountCountry),
    body,
  };

  switch (type) {
    case EmailSupportType.MY_APPLICATION:
      composeOptions.subject = APPLICATION_SUPPORT_SUBJECT;
      break;
    case EmailSupportType.MY_TRANSACTION:
      composeOptions.subject = TRANSACTION_SUPPORT_SUBJECT;
      break;
    case EmailSupportType.TECHNICAL_SUPPORT:
      composeOptions.subject = TECHNICAL_SUPPORT_SUBJECT;
      break;
    case EmailSupportType.OTHERS:
      composeOptions.subject = OTHER_SUPPORT_SUBJECT;
      break;
    case EmailSupportType.TAX_SUPPORT:
      composeOptions.subject = TAX_SUPPORT_SUBJECT;
      break;
    case EmailSupportType.MY_MLS:
      composeOptions.subject = CHANGE_MY_MLS_MEMBERSHIPS_SUBJECT;
      composeOptions.to = getSupportEmail(user.accountCountry);
      break;
    case EmailSupportType.MY_BOARDS:
      composeOptions.subject = CHANGE_MY_BOARD_MEMBERSHIPS_SUBJECT;
      composeOptions.to = getSupportEmail(user.accountCountry);
      break;
    case EmailSupportType.MY_LICENSES:
      composeOptions.subject = CHANGE_MY_LICENCES_SUBJECT;
      composeOptions.to = getSupportEmail(user.accountCountry);
      break;
    case EmailSupportType.VOICE_RECORDING:
      composeOptions.subject = VOICE_RECORDING_SUBJECT;
      composeOptions.to = getSupportEmail(user.accountCountry);
      break;
  }
  return window.open(
    `mailto:${composeOptions.to}?subject=${composeOptions.subject}&body=${composeOptions.body}`,
    '_blank',
  );
};

export const getSupportEmail = (
  country: AgentResponseAccountCountryEnum | undefined,
) => {
  const getEmailMapToAgent: EnumMap<AgentResponseAccountCountryEnum, string> = {
    CANADA: CANADA_SUPPORT_EMAIL_ADDRESS,
    UNITED_STATES: SUPPORT_EMAIL_ADDRESS,
  };

  if (!country) {
    return SUPPORT_EMAIL_ADDRESS;
  }

  return safeEnumMapGet(getEmailMapToAgent, country, SUPPORT_EMAIL_ADDRESS);
};
