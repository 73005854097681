import { useHistory, useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export const useNavigateIfNotCurrentRoute = () => {
  const history = useHistory();
  const location = useLocation();

  return useMemo(
    () => (newPath: string) => {
      if (location.pathname !== newPath) {
        history.push(newPath);
      }
    },
    [history, location],
  );
};
