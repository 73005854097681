import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import QuoteIcon from '../../../assets/icons/real-mortgage/quote.svg';
import AgentDawnSemancikImage from '../../../assets/img/zen/real-mortgage/V2/AgentDawnSemancik.jpg';
import AgentLateefahMathewsImage from '../../../assets/img/zen/real-mortgage/V2/AgentLateefahMathews.jpg';
import { cn } from '../../../utils/classUtils';

type Testimonial = {
  name: string;
  message: string;
  image?: string;
};

export const TESTIMONIALS: Testimonial[] = [
  {
    name: 'Dawn Semancik',
    message:
      "Tony and I worked together with a buyer in Cuyahoga Falls and a second buyer in Solon and it couldn't have gone any more smooth! Closed in 2 weeks and between the program that One Real Mortgage offers and the seller closing credit to the buyer, he only had to bring less than $1,000 to closing! The buyer was thrilled and I will absolutely work with Tony in the future! He is very responsive with communication which is a priority for my clients to feel comfortable. It is also great to be competitive with cash buyers and have the ability to close in 2 weeks. Thanks Tony!",
    image: AgentDawnSemancikImage,
  },
  {
    name: 'Lateefah Mathews',
    message:
      'I had the pleasure of working with Tony from One Real Mortgage and I was more than impressed with his exceptional service. We worked together effortlessly, it was a perfect marriage. His proactive communication and dedication to ensuring a stress-free experience for my buyer set him apart from the rest. I highly recommend Tony to anyone seeking a highly dedicated and reliable loan officer. Thank you Tony, for making this process a seamless and enjoyable one!',
    image: AgentLateefahMathewsImage,
  },
  {
    name: 'Dharmendra Patel',
    message:
      'It was much pleasure working with Mr. Dylan Harlacker of One Real Mortgage. They are very honest, very professional. They provided excellent service and entire mortgage process was very smooth. I will definitely recommend for their services.',
  },
  {
    name: 'Tiffany Parker',
    message:
      'Daniel Reece is the most amazing lender to work with while assisting to buy a home. He is always quick to respond and was extremely knowledgeable throughout the entire lending process. #1 Preferred Lender. Thank you Daniel for helping me buy my first home. You are awesome!!',
  },
  {
    name: 'Nathan Olson',
    message:
      'I worked with Matt Scalese and he was great. Super knowledgeable and helpful with every step along the way. Matt is also willing to go above and beyond making sure you understand the whole process and your options. Matt is super committed to getting you what you need by working on the weekend to get you approved.',
  },
  {
    name: 'Gurkamal Singh',
    message:
      "I've worked with Pranav Pandya several times and he is absolutely amazing! Very knowledgable and responsive. His service is the best and he is great at solving any challenges that may come up. I will definitely keep using him and would recommend him to all my friends and family!!",
  },
];

const OneRealMortgageTestimonialsCarousal: React.FC = () => {
  const ref = useRef<Slider>(null);
  const [index, setIndex] = useState(0);
  const currentTestimonial = TESTIMONIALS[index];

  return (
    <div className='flex flex-col sm:flex-row gap-3 sm:gap-6'>
      <div className='w-full sm:w-1/2'>
        <h1 className='font-poppins text-[40px] leading-[48px] font-semibold'>
          <span>What are Clients</span>
          <br />
          <span>and Agents Saying</span>
        </h1>
        <Slider
          ref={ref}
          dots={false}
          infinite
          speed={500}
          autoplay
          autoplaySpeed={10000}
          afterChange={setIndex}
        >
          {TESTIMONIALS.map((testimonial) => (
            <div className='mt-5' key={testimonial.name}>
              <p className='font-light'>{testimonial.message}</p>
            </div>
          ))}
        </Slider>
        <div className='mt-5 flex items-center space-x-2'>
          {TESTIMONIALS.map((el, idx) => (
            <button
              key={el.name}
              onClick={() => ref.current?.slickGoTo(idx)}
              className={cn(
                'w-2 h-2 rounded-full',
                index === idx ? 'bg-primary-blue' : 'bg-grey-300',
              )}
            />
          ))}
        </div>
      </div>
      <div className='mt-5 sm:mt-0 w-full sm:w-1/2'>
        <div className='relative -z-10 border-[3px] border-r-0 border-zen-danger w-[88%] max-w-[535px] h-[256px] sm:h-[352px] ml-auto p-12'>
          <div className='absolute top-10 sm:top-12'>
            <div className='relative bg-seaglass w-[180px] sm:w-[300px] h-[180px] sm:h-[256px] rounded-md flex flex-col gap-2 items-center justify-center mx-auto'>
              <img src={QuoteIcon} alt='quote' className='absolute -top-3' />
              <div className='h-[64px] w-[64px] sm:h-[100px] sm:w-[100px] rounded-full overflow-hidden'>
                {currentTestimonial.image ? (
                  <img
                    className='h-full w-full object-cover'
                    src={currentTestimonial.image}
                    alt='agent'
                    data-testid='agent-image'
                  />
                ) : (
                  <div className='bg-primary-navy w-full h-full flex justify-center items-center'>
                    <p className='text-2xl sm:text-3xl text-white'>
                      {currentTestimonial.name
                        .split(' ')
                        .map((name) => name[0])}
                    </p>
                  </div>
                )}
              </div>
              <p>{currentTestimonial.name}</p>
              <div className='flex'>
                {[1, 2, 3, 4, 5].map((val) => (
                  <FontAwesomeIcon
                    icon={faStar}
                    key={val}
                    className='text-yellow-300'
                    data-testid='star-icon'
                  />
                ))}
              </div>
            </div>
          </div>
          <div className='absolute top-full left-1/2 border-l-[3px] border-b-[3px] border-zen-danger border-t-[3px] border-t-white h-[100px] md:h-[140px] w-full' />
        </div>
      </div>
    </div>
  );
};

export default OneRealMortgageTestimonialsCarousal;
