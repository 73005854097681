import { values } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps,
} from '../../routes/MSDynamicsOnboardingRoute';
import {
  EMAIL_VALIDATIONS,
  getPostalCodeValidation,
  PHONE_NUMBER_VALIDATIONS,
} from '../../utils/Validations';
import {
  AddressCountryEnum,
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaResponseCountryEnum,
} from '../../openapi/yenta';
import ZenControlledEmailInput from '../Zen/Input/ZenControlledEmailInput';
import ZenControlledHTMLSelectInput from '../Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledPhoneNumberInput from '../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenControlledStateOrProvinceInput from '../Zen/Input/ZenControlledStateOrProvince';
import ZenControlledCheckboxInput from '../Zen/Input/ZenControlledCheckboxInput';
import {
  AgentAddressTypeEnum,
  AppDispatch,
  RootState,
  YesNoType,
} from '../../types';
import ZenButton from '../Zen/ZenButton';
import { getUpdateAgentDetailObject } from '../../utils/ApiObjectUtils';
import {
  onboardAgentToPaymentSystem,
  updateAgentDetail,
} from '../../slices/AgentSlice';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import { getAddressBasedOnPriority } from '../../utils/AgentHelper';
import { capitalizeEnum, NAME_REGEX } from '../../utils/StringUtils';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { isMsdxVendorPresent } from '../../utils/MSDynamicHelper';
import withMSDynamicsProgress from './withMSDynamicsProgress';

const MSDynamicsContactInformation: StepByStepComponent<
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps
> = ({ form: { watch, control, setValue, trigger }, onPrevious, onNext }) => {
  const dispatch = useDispatch<AppDispatch>();
  const mailAddressSameAsContact = watch('mailAddressSameAsContact')?.[0];
  const [country, mailCountry] = watch(['country', 'mailCountry']);
  const differentMailAddress = mailAddressSameAsContact !== YesNoType.YES;
  const [loading, setLoading] = useState<boolean>(false);

  const { userDetail } = useSelector((state: RootState) => state.auth);
  const handleNext = async () => {
    const isValid = await trigger();
    if (!isValid) {
      dispatch(showErrorToast('There are fields which need to be filled out'));
      return;
    }
    const formValues = watch();
    setLoading(true);
    const addressType = getAddressBasedOnPriority(userDetail?.addresses || [])
      ?.type;
    const addressRequests = [
      {
        type: addressType ?? AgentAddressTypeEnum.OFFICE,
        streetAddress1: formValues.streetAddress1,
        streetAddress2: formValues.streetAddress2,
        city: formValues.city,
        country: formValues.country,
        stateOrProvince: formValues.stateOrProvince,
        zipOrPostalCode: formValues.zipOrPostalCode,
      },
      {
        type: AgentAddressTypeEnum.MAILING,
        streetAddress1: differentMailAddress
          ? formValues.mailStreetAddress1
          : formValues.streetAddress1,
        streetAddress2: differentMailAddress
          ? formValues.mailStreetAddress2
          : formValues.streetAddress2,
        city: differentMailAddress ? formValues.mailCity : formValues.city,
        country: differentMailAddress
          ? formValues.mailCountry
          : formValues.country,
        stateOrProvince: differentMailAddress
          ? formValues.mailStateOrProvince
          : formValues.stateOrProvince,
        zipOrPostalCode: differentMailAddress
          ? formValues.mailZipOrPostalCode
          : formValues.zipOrPostalCode,
      },
    ];
    const req = getUpdateAgentDetailObject(
      userDetail!,
      {
        firstName: formValues.firstName,
        middleName: formValues.middleName,
        lastName: formValues.lastName,
        personalEmailAddress: formValues.emailAddress,
        phoneNumber: formValues.phoneNumber,
        addressRequests,
      },
      { omitAddressRequest: true, includeAddressRequests: true },
    );
    const needToOnboard = !isMsdxVendorPresent(userDetail?.msdxVendors);
    let failedToOnboard = false;
    const savedUserInfo = await dispatch(
      updateAgentDetail(userDetail?.id!, req),
    );
    if (savedUserInfo && needToOnboard) {
      failedToOnboard = !(await dispatch(
        onboardAgentToPaymentSystem(userDetail?.id!),
      ));
    }
    if (savedUserInfo && !failedToOnboard) {
      onNext();
    }
    setLoading(false);
  };

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div>
          <p className='text-xl font-primary-medium mb-4'>
            Verify contact information associated with this account.
          </p>
          <div className='space-y-4 mb-5'>
            <div className='flex flex-row space-x-4'>
              <ZenControlledTextInput<MSDynamicOnboardingFormData, 'firstName'>
                control={control}
                label='First Name'
                name='firstName'
                placeholder='First Name'
                shouldUnregister={false}
                rules={{
                  required: 'Please enter a first name',
                  pattern: {
                    value: NAME_REGEX,
                    message: 'Please enter a valid first name',
                  },
                }}
                isRequired
              />
              <ZenControlledTextInput<MSDynamicOnboardingFormData, 'middleName'>
                control={control}
                label='Middle Name'
                name='middleName'
                placeholder='Middle Name'
                shouldUnregister={false}
                rules={{
                  pattern: {
                    value: NAME_REGEX,
                    message: 'Please enter a valid middle name',
                  },
                }}
              />
              <ZenControlledTextInput<MSDynamicOnboardingFormData, 'lastName'>
                control={control}
                label='Last Name'
                name='lastName'
                placeholder='Last Name'
                shouldUnregister={false}
                rules={{
                  required: 'Please enter a last name',
                  pattern: {
                    value: NAME_REGEX,
                    message: 'Please enter a valid last name',
                  },
                }}
                isRequired
              />
            </div>
            <ZenControlledEmailInput<
              MSDynamicOnboardingFormData,
              'emailAddress'
            >
              control={control}
              label='Contact Email'
              name='emailAddress'
              placeholder='Contact Email'
              shouldUnregister={false}
              rules={{
                required: 'Please enter a contact email',
                ...EMAIL_VALIDATIONS,
              }}
              isRequired
            />
            <ZenControlledPhoneNumberInput<
              MSDynamicOnboardingFormData,
              'phoneNumber'
            >
              control={control}
              label='Phone number'
              name='phoneNumber'
              placeholder='E.g. +1 (702) 123-4567'
              shouldUnregister={false}
              rules={{
                required: 'Please enter a phone number',
                ...PHONE_NUMBER_VALIDATIONS,
              }}
              isRequired
            />
            <ZenControlledTextInput<
              MSDynamicOnboardingFormData,
              'streetAddress1'
            >
              control={control}
              label='Address Line 1'
              name='streetAddress1'
              placeholder='Address Line 1'
              shouldUnregister={false}
              rules={{ required: 'Please enter an address line' }}
              isRequired
            />
            <ZenControlledTextInput<
              MSDynamicOnboardingFormData,
              'streetAddress2'
            >
              control={control}
              label='Address Line 2'
              name='streetAddress2'
              placeholder='Address Line 2'
              shouldUnregister={false}
            />
            <div className='flex flex-row space-x-4'>
              <ZenControlledTextInput<MSDynamicOnboardingFormData, 'city'>
                control={control}
                label='City'
                name='city'
                placeholder='City'
                shouldUnregister={false}
                rules={{ required: 'Please enter a city' }}
                isRequired
              />
              <ZenControlledStateOrProvinceInput<
                MSDynamicOnboardingFormData,
                'stateOrProvince'
              >
                name='stateOrProvince'
                control={control}
                shouldUnregister={false}
                setValue={setValue}
                disableChooseOption={false}
                selectedCountry={
                  (country as unknown) as AdministrativeAreaRequestCountryEnum
                }
                rules={{ required: 'Please select a state or province' }}
                isRequired
              />
            </div>
            <div className='flex flex-row space-x-4'>
              <ZenControlledTextInput<
                MSDynamicOnboardingFormData,
                'zipOrPostalCode'
              >
                control={control}
                label='ZIP/Postal Code'
                name='zipOrPostalCode'
                shouldUnregister={false}
                placeholder='E.g. 10001'
                rules={{
                  required: 'Please enter a zip or postal code',
                  ...getPostalCodeValidation(country as AddressCountryEnum),
                }}
                isRequired
              />
              <ZenControlledHTMLSelectInput<
                MSDynamicOnboardingFormData,
                'country'
              >
                name='country'
                control={control}
                label='Country'
                shouldUnregister={false}
                defaultValue={userDetail?.accountCountry}
                options={[
                  {
                    label: 'Select Country',
                    value: '',
                  },
                  ...values(AdministrativeAreaResponseCountryEnum).map(
                    (country) => ({
                      value: country,
                      label: capitalizeEnum(country),
                    }),
                  ),
                ]}
                rules={{ required: 'Please select a country' }}
                isRequired
              />
            </div>

            <p className='text-xl font-primary-medium pt-5'>
              In case we need to mail you a check, where should we send it?
            </p>
            <ZenControlledCheckboxInput<
              MSDynamicOnboardingFormData,
              'mailAddressSameAsContact'
            >
              shouldUnregister={false}
              control={control}
              name='mailAddressSameAsContact'
              options={[
                {
                  label: 'My mailing address is the same as my contact address',
                  value: YesNoType.YES,
                },
              ]}
              reverse
            />
            {differentMailAddress && (
              <>
                <ZenControlledTextInput<
                  MSDynamicOnboardingFormData,
                  'mailStreetAddress1'
                >
                  control={control}
                  label='Address Line 1'
                  name='mailStreetAddress1'
                  placeholder='Address Line 1'
                  shouldUnregister={false}
                  rules={{ required: 'Please enter an address line' }}
                  isRequired
                />
                <ZenControlledTextInput<
                  MSDynamicOnboardingFormData,
                  'mailStreetAddress2'
                >
                  control={control}
                  label='Address Line 2'
                  name='mailStreetAddress2'
                  placeholder='Address Line 2'
                  shouldUnregister={false}
                />
                <div className='flex flex-row space-x-4'>
                  <ZenControlledTextInput<
                    MSDynamicOnboardingFormData,
                    'mailCity'
                  >
                    control={control}
                    label='City'
                    name='mailCity'
                    placeholder='City'
                    shouldUnregister={false}
                    rules={{ required: 'Please enter a city' }}
                    isRequired
                  />

                  <ZenControlledStateOrProvinceInput<
                    MSDynamicOnboardingFormData,
                    'mailStateOrProvince'
                  >
                    name='mailStateOrProvince'
                    control={control}
                    shouldUnregister={false}
                    setValue={setValue}
                    selectedCountry={
                      (mailCountry as unknown) as AdministrativeAreaRequestCountryEnum
                    }
                    rules={{ required: 'Please select a state or province' }}
                    isRequired
                  />
                </div>
                <div className='flex flex-row space-x-4'>
                  <ZenControlledTextInput<
                    MSDynamicOnboardingFormData,
                    'mailZipOrPostalCode'
                  >
                    control={control}
                    label='ZIP/Postal Code'
                    name='mailZipOrPostalCode'
                    shouldUnregister={false}
                    placeholder='E.g. 10001'
                    rules={{
                      required: 'Please enter a zip or postal code',
                      ...getPostalCodeValidation(
                        mailCountry as AddressCountryEnum,
                      ),
                    }}
                    isRequired
                  />
                  <ZenControlledHTMLSelectInput<
                    MSDynamicOnboardingFormData,
                    'mailCountry'
                  >
                    name='mailCountry'
                    control={control}
                    label='Country'
                    shouldUnregister={false}
                    placeholder='Country'
                    defaultValue={userDetail?.accountCountry}
                    options={[
                      {
                        label: 'Select Country',
                        value: '',
                      },
                      ...values(AdministrativeAreaResponseCountryEnum).map(
                        (country) => ({
                          value: country,
                          label: capitalizeEnum(country),
                        }),
                      ),
                    ]}
                    rules={{ required: 'Please select a country' }}
                    isRequired
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-sm'>
          <div className='flex flex-row items-center py-8 shadow-top-sm justify-center space-x-5'>
            <div className='w-28'>
              <ZenButton
                isFullWidth
                variant='primary-outline'
                type='button'
                label='Previous'
                onClick={onPrevious}
              />
            </div>
            <div className='w-56'>
              <ZenButton
                isFullWidth
                isSubmitting={loading}
                type='submit'
                variant='primary'
                isDisabled={loading}
                label='Next'
                onClick={handleNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withMSDynamicsProgress(MSDynamicsContactInformation);
