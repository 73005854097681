import { PayeeDetailsResponse } from '../openapi/yenta';

export const isRegisteredWithTipalti = (
  tipaltiStatus: PayeeDetailsResponse,
): boolean => {
  return !!tipaltiStatus.registered && !!tipaltiStatus.paymentMethod;
};

export const getTipaltiErrorMessage = (
  tipaltiStatus: PayeeDetailsResponse,
): string | null => {
  if (isRegisteredWithTipalti(tipaltiStatus)) {
    return null;
  }

  if (!tipaltiStatus.registered) {
    return "We didn't find a Tiptalti account. Please try registering again.";
  }

  return 'It seems that you are registered for Tipalti, but you have not set a preferred payment method. Please set one by clicking below.';
};
