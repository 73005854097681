import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import { useState } from 'react';
import { Column } from 'react-table';
import { useLocation } from 'react-router-dom';
import AnalyticsEventOnLoad from '../components/Analytics/AnalyticsEventOnLoad';
import InviteBuyerSidebarModalForm from '../components/Mortgage/InviteBuyerSidebarModalForm';
import MortgageStatus from '../components/Mortgage/MortgageStatus';
import AddressStateEnumSelectColumnFilter from '../components/table/Filters/AddressStateEnumSelectColumnFilter';
import MortgageStatusSelectColumnFilter from '../components/table/Filters/MortgageStatusSelectColumnFilter';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenPhoneNumberCell from '../components/Zen/Table/Cell/ZenPhoneNumberCell';
import ZenButton from '../components/Zen/ZenButton';
import ZenLink from '../components/Zen/ZenLink';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenRoute from '../components/Zen/ZenRoute';
import { useRefresh } from '../hooks/useRefresh';
import { LeadLoanDto } from '../openapi/atlantis';
import { AnalyticsEventEnum } from '../types';
import { getFullName } from '../utils/AgentHelper';
import { isSmScreen } from '../utils/BrowserUtils';
import { fetchMortgageLeadAndLoans } from '../utils/MortgageUtils';
import { capitalizeEnum } from '../utils/StringUtils';

export const columns: Array<Column<LeadLoanDto>> = [
  {
    Header: 'Client',
    accessor: 'clientName',
    Cell: ({ value }) => (
      <p className='font-normal whitespace-pre'>{value || 'N/A'}</p>
    ),
    Filter: TextColumnFilter,
  },
  {
    Header: 'Phone',
    accessor: 'phoneNumber',
    Cell: ({ value }) => (
      <div className='font-normal'>
        <ZenPhoneNumberCell phoneNumber={value?.value} />
      </div>
    ),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: ({ value }) => <p className='font-normal'>{value?.value || 'N/A'}</p>,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Location',
    accessor: 'location',
    Cell: ({ value }) => (
      <p className='font-normal'>{value ? capitalizeEnum(value) : 'N/A'}</p>
    ),
    Filter: AddressStateEnumSelectColumnFilter,
  },
  {
    Header: 'Loan Officer',
    accessor: 'loanOfficer',
    Cell: ({ value }) => {
      return (
        <p className='font-normal whitespace-pre'>
          {getFullName(value) || 'N/A'}
        </p>
      );
    },
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (value ? <MortgageStatus status={value} /> : 'N/A'),
    Filter: MortgageStatusSelectColumnFilter,
  },
];

const AgentMortgagesRoute: React.FC = () => {
  const location = useLocation();
  const { key, refresh } = useRefresh();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );

  return (
    <ZenRoute title='Loans'>
      <ZenPageLayoutWithSearch
        path={[
          { title: 'Home', url: '/' },
          { title: 'Mortgage', url: '/mortgages' },
        ]}
        search={search}
        onSearchChange={setSearch}
      >
        <div className='px-4 py-0 mt-5'>
          <ZenResourceIndexContainer<LeadLoanDto>
            key={key}
            header='Loans'
            resourceName='Loan'
            columns={columns}
            search={search}
            showSearch={isSmScreen()}
            fetchData={async (req, cancelToken) =>
              await fetchMortgageLeadAndLoans(req, { cancelToken })
            }
            RightComponent={
              <div className='flex flex-row items-center space-x-2.5 md:space-x-3'>
                <ZenLink
                  label='Explore One Real Mortgage'
                  onClick={() => window.open('/onereal-mortgage', '_blank')}
                  variant='primary-with-no-hover-effect'
                />
                <ZenButton
                  label='Invite Buyer'
                  LeftIconComponent={<FontAwesomeIcon icon={solid('plus')} />}
                  onClick={() => setIsOpen(true)}
                />
              </div>
            }
            stickyHeader
          />
          {isOpen && (
            <InviteBuyerSidebarModalForm
              isOpen
              onClose={() => setIsOpen(false)}
              refresh={refresh}
            />
          )}
        </div>
        <AnalyticsEventOnLoad
          eventName={AnalyticsEventEnum.MC_LEADS_LIST_VIEWED}
        />
      </ZenPageLayoutWithSearch>
    </ZenRoute>
  );
};

export default AgentMortgagesRoute;
