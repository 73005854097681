import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnalyticsEventEnum, RootState } from '../../../../types';
import DesignateBeneficiaryModal from '../../Modal/DesignateBeneficiary';
import AnalyticsEventOnLoad from '../../../Analytics/AnalyticsEventOnLoad';
import AnalyticsService from '../../../../services/AnalyticsService';
import { fetchAgentWillBeneficiary } from '../../../../slices/AgentSlice';

interface DesignateBeneficiaryModalIndexProps {}

const DesignateBeneficiaryModalIndex: React.FC<DesignateBeneficiaryModalIndexProps> = () => {
  const dispatch = useDispatch();
  const {
    auth: {
      isSuperAdmin,
      isRealTitleOfficer,
      isAdmin,
      isBroker,
      userDetail: agent,
    },
  } = useSelector((state: RootState) => state);
  const [
    showDesignateBeneficiary,
    setShowDesignateBeneficiary,
  ] = useState<boolean>(!!agent?.needsWillBeneficiaryOnboarding);

  useEffect(() => {
    if (agent?.id) {
      dispatch(fetchAgentWillBeneficiary(agent?.id));
    }
  }, [agent?.id, dispatch]);

  const isAgent = !isSuperAdmin && !isRealTitleOfficer && !isAdmin && !isBroker;
  if (!isAgent || !showDesignateBeneficiary) {
    return null;
  }

  return (
    <>
      <DesignateBeneficiaryModal
        isOpen
        onClose={() => {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.WILLABLE_REVSHARE_INTRO_POPUP_CLOSE,
          );

          setShowDesignateBeneficiary(false);
        }}
      />
      <AnalyticsEventOnLoad
        eventName={AnalyticsEventEnum.WILLABLE_REVSHARE_INTRO_POPUP_OPEN}
      />
    </>
  );
};

export default DesignateBeneficiaryModalIndex;
