import NotFound404Img from '../assets/icons/404.svg';

interface InvitationExpiredProps {}

const InvitationExpired: React.FC<InvitationExpiredProps> = () => {
  return (
    <div className='h-screen flex flex-col justify-center items-center -mt-10'>
      <img src={NotFound404Img} alt='404' />
      <p className='text-center font-zen-body text-zen-dark-7 max-w-lg'>
        The link is invalid. Please contact your team leader/admin to get a new
        one sent to you.
      </p>
    </div>
  );
};

export default InvitationExpired;
