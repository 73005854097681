import { values } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  CalendarEventResponse,
  CalendarEventUpdateRequest,
  CalendarEventUpdateRequestStatusEnum,
} from '../../../openapi/yenta';
import {
  deleteEventImage,
  updateEvent,
  uploadEventImage,
} from '../../../slices/CalendarSlice';
import { showSuccessToast } from '../../../slices/ToastNotificationSlice';
import { ISelectOption } from '../../../types';
import { isImage } from '../../../utils/EventHelper';
import { getCalendarImageUrl } from '../../../utils/ImgUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { FILE_VALIDATIONS } from '../../../utils/Validations';
import ZenControlledDraggableImageUploadInput from '../Input/ZenControlledDraggableImageUploadInput';
import ZenControlledSelectInput from '../Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenButton from '../ZenButton';
import ZenSidebarModal from '../ZenSidebarModal';

interface FormData {
  imageFile?: File;
  host?: string;
  status?: ISelectOption;
  location?: string;
}

interface ZenEditEventDetailsSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  event: CalendarEventResponse;
}

const ZenEditEventDetailsSidebarModal: React.FC<ZenEditEventDetailsSidebarModalProps> = ({
  isOpen,
  onClose,
  event,
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { control, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      imageFile: event.imageUrl
        ? ((getCalendarImageUrl(event.imageUrl) as unknown) as File)
        : undefined,
      host: event.host || undefined,
      status: event.status
        ? {
            label: capitalizeEnum(event.status),
            value: event.status,
          }
        : undefined,
      location: event.location || undefined,
    },
  });

  const handleDeleteEventImage = async () => {
    if (event.imageUrl) {
      await dispatch(deleteEventImage(event.id!));
      setValue('imageFile', undefined);
    } else {
      setValue('imageFile', undefined);
    }
  };

  const onSubmit = async (values: FormData) => {
    setIsSubmitting(true);

    const eventId = event.id!;

    const updateRequest: CalendarEventUpdateRequest = {
      host: values.host,
      location: values.location,
      status: (values.status
        ?.value as unknown) as CalendarEventUpdateRequestStatusEnum,
    };

    await dispatch(updateEvent(eventId, updateRequest));

    if (isImage(values.imageFile!)) {
      await dispatch(uploadEventImage(eventId, values.imageFile!));
    }

    dispatch(showSuccessToast('Event updated successfully.'));
    setIsSubmitting(false);
    onClose();
  };

  return (
    <ZenSidebarModal
      title='Edit Event Details'
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <div>
        <div className='py-4 md:px-8 px-4 pb-32'>
          <p className='font-zen-body text-3xl font-semibold text-zen-dark-9 my-8'>
            {event.title}
          </p>
          <p className='font-zen-body text-base font-semibold text-zen-dark-12 mb-2'>
            Replace photo with your own image or Real will use default template.
          </p>
          <ZenControlledDraggableImageUploadInput<FormData, 'imageFile'>
            name='imageFile'
            control={control}
            placeholder='Browse Files'
            fileInputSize='extraLarge'
            onDelete={handleDeleteEventImage}
            rules={{
              ...FILE_VALIDATIONS,
            }}
          />
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, 'status'>
              name='status'
              control={control}
              label='Status'
              placeholder='Select status'
              options={values(CalendarEventUpdateRequestStatusEnum).map(
                (status) => ({
                  label: capitalizeEnum(status),
                  value: status,
                }),
              )}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'host'>
              name='host'
              control={control}
              label='Host'
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'location'>
              name='location'
              control={control}
              label='Location'
            />
          </div>
        </div>
        <div className='absolute bottom-0 left-0 right-0 bg-white flex flex-row justify-end space-x-4 items-center w-full py-4 md:px-8 px-4 border border-gray-200'>
          <div className='w-40'>
            <ZenButton
              type='button'
              label='Cancel'
              variant='primary-outline'
              onClick={onClose}
              isFullWidth
            />
          </div>
          <div className='w-40'>
            <ZenButton
              type='button'
              label='Save'
              variant='primary'
              isSubmitting={isSubmitting}
              isDisabled={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              isFullWidth
            />
          </div>
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default ZenEditEventDetailsSidebarModal;
