import { motion, Variants } from 'framer-motion';
import { useEffect, useState } from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { AgentBadgeResponse } from '../../openapi/insignia';
import { cn } from '../../utils/classUtils';

interface ProgressProviderProps {
  valueStart: number;
  valueEnd: number;
  children(value: number): React.ReactElement;
}

const ProgressProvider: React.FC<ProgressProviderProps> = ({
  valueStart,
  valueEnd,
  children,
}) => {
  const [value, setValue] = useState(valueStart);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setValue(valueEnd);
    }, 1500);
    return () => clearTimeout(timeout);
  }, [valueEnd]);

  return children(value);
};

const variants: Variants = {
  initial: { translateX: 1000 },
  in: { translateX: 0, transition: { duration: 1.5 } },
};

interface RevShareInsightsBadgeProps {
  icon: React.ReactElement;
  badge?: AgentBadgeResponse;
  onClick?(): void;
}

const RevShareInsightsBadge: React.FC<RevShareInsightsBadgeProps> = ({
  icon,
  onClick,
  badge,
}) => {
  const unlocked = !!badge?.unlocked;
  const progress =
    ((badge?.milestoneCurrentValue || 0) /
      (badge?.badge?.milestoneTargetValue || 0)) *
    100;

  return (
    <motion.div
      variants={variants}
      initial='initial'
      animate='in'
      className={cn(
        'inline-block cursor-pointer',
        unlocked ? 'p-1' : 'p-2.5 w-[88px]',
      )}
      onClick={onClick}
      aria-label={badge?.badge?.name}
    >
      {unlocked ? (
        icon
      ) : (
        <ProgressProvider valueStart={0} valueEnd={progress}>
          {(value) => (
            <CircularProgressbarWithChildren
              value={value}
              styles={buildStyles({
                rotation: 0.5,
                strokeLinecap: 'round',
                pathTransitionDuration: 1,
                pathColor: 'rgba(59, 130, 246, 1)',
                trailColor: '#2828261A',
              })}
            >
              {icon}
            </CircularProgressbarWithChildren>
          )}
        </ProgressProvider>
      )}
    </motion.div>
  );
};

export default RevShareInsightsBadge;
