import { useParams } from 'react-router-dom';
import FormBasedOnType from '../components/FormBasedOnType';
import ZenRoute from '../components/Zen/ZenRoute';
import RenderApplicationFormForPDF from '../containers/RenderApplicationFormForPDF';
import { FormType } from '../utils/FormUtils';

type Match = {
  id: string;
};

const LOISignedPdfRoute: React.FC = () => {
  const { id: applicationId } = useParams() as Match;
  return (
    <ZenRoute title='PDF'>
      <RenderApplicationFormForPDF applicationId={applicationId}>
        {(application) => (
          <FormBasedOnType
            formType={FormType.LETTER_OF_INTENT}
            application={application}
            onSubmit={() => {}}
          />
        )}
      </RenderApplicationFormForPDF>
    </ZenRoute>
  );
};

export default LOISignedPdfRoute;
