import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { OutgoingPaymentsControllerApi } from '../../openapi/arrakis';
import ErrorService from '../../services/ErrorService';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import { fetchTransactionOutgoingPayments } from '../../slices/TransactionSlice';
import { AppDispatch } from '../../types';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ZenSimpleConfirmationModal from '../Zen/Modal/ZenSimpleConfirmationModal';
import ZenCheckBox from '../Zen/ZenCheckbox';

interface ZenOutgoingReleasePaymentEarlyModalProps {
  isOpen: boolean;
  transactionId?: string;
  approvingOutgoingPaymentId: string;
  onClose(): void;
}

const ZenOutgoingReleasePaymentEarlyModal: React.FC<ZenOutgoingReleasePaymentEarlyModalProps> = ({
  isOpen,
  onClose,
  transactionId,
  approvingOutgoingPaymentId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isApprovingLoading, setIsApprovingLoading] = useState<boolean>(false);
  const [
    isCertifyReleasePayment,
    setIsCertifyReleasePayment,
  ] = useState<boolean>(false);
  const [isCheckedOnce, setIsCheckedOnce] = useState<boolean>(false);

  const handleApprovedOutgoingPayment = async () => {
    setIsCheckedOnce(true);
    if (isCertifyReleasePayment) {
      setIsApprovingLoading(true);
      try {
        await new OutgoingPaymentsControllerApi(
          getArrakisConfiguration(),
        ).approve(approvingOutgoingPaymentId);
        dispatch(showSuccessToast(`Successfully approved outgoing payment`));
        onClose();
        if (transactionId) {
          dispatch(fetchTransactionOutgoingPayments(transactionId));
        }
      } catch (e) {
        dispatch(
          showErrorToastForErrorCode(
            'We were unable to approved the outgoing payment',
            ErrorService.getErrorCode(e),
          ),
        );
      } finally {
        setIsApprovingLoading(false);
        setIsCheckedOnce(false);
      }
    }
  };

  return (
    <ZenSimpleConfirmationModal
      isOpen={isOpen}
      title='Certify and Release Payment Early'
      confirmButtonText='Release Payment'
      onConfirm={handleApprovedOutgoingPayment}
      cancelButtonText='Cancel'
      onClose={onClose}
      isSubmitting={isApprovingLoading}
      variant='warning'
      size='large'
    >
      <div className='my-6 space-y-0.5'>
        <div className='flex space-x-3 mb-3'>
          <div className='-mt-0.5'>
            <ZenCheckBox
              value={isCertifyReleasePayment}
              onChange={() =>
                setIsCertifyReleasePayment(!isCertifyReleasePayment)
              }
              isCheckedOnce={isCheckedOnce}
            />
          </div>
          <p
            onClick={() => setIsCertifyReleasePayment(!isCertifyReleasePayment)}
            className='font-zen-body cursor-pointer text-gray-500 pr-5 leading-tight'
          >
            I certify that this is an exception case and one of the payment
            participants need to be paid earlier before we accept payment. I
            have the necessary authorization to do this action.
          </p>
        </div>
        {isCheckedOnce && !isCertifyReleasePayment && (
          <p className='font-zen-body text-sm text-zen-danger'>
            Select the checkbox to certify.
          </p>
        )}
      </div>
    </ZenSimpleConfirmationModal>
  );
};

export default ZenOutgoingReleasePaymentEarlyModal;
