import React from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../components/Zen/ZenSidebarModalForm';
import { AgentControllerApi } from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';

interface ZenResurrectAgentSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  agentId: string;
}

const ZenResurrectAgentSidebarModal: React.FC<ZenResurrectAgentSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    try {
      await new AgentControllerApi(getYentaConfiguration()).unTerminateAgent(
        data.agentId,
      );
      onClose();
      dispatch(showSuccessToast('Agent resurrected successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notifyIgnoreAuthErrors('Error resurrecting agent', e, {
        agent: {
          id: data.agentId,
        },
      });
      dispatch(
        showErrorToast(
          'We had a problem resurrecting an agent',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <ZenSidebarModalForm
      isOpen={isOpen}
      onClose={onClose}
      title='Resurrect Agent'
      subtitle='This will mark a terminated agent as active.'
      actionTitle='Resurrect'
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <ZenControlledTextInput
          control={control}
          label='Agent ID'
          name='agentId'
          placeholder='12345678-1111-2222-3333-12345678'
          rules={{ required: 'Agent ID is required' }}
          isRequired
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenResurrectAgentSidebarModal;
