import { WandererControllerApi } from '../../openapi/wanderer';
import {
  BoardControllerApi,
  BoardRequest,
  BoardResponse,
  BoardSecretsResponse,
  MlsControllerApi,
  MlsGetPropertyTypeResponse,
  MlsPropertyTypeControllerApi,
  MlsRequest,
  MlsResponse,
  MlsSecretsResponse,
} from '../../openapi/yenta';
import {
  getWandererConfiguration,
  getYentaConfiguration,
} from '../../utils/OpenapiConfigurationUtils';
import { queryKeys } from '../base/queryKeys';
import { useBaseMutation } from '../base/useBaseMutation';
import { useSimpleQuery } from '../base/useSimpleQuery';

export const useCreateBoard = () => {
  const createBoard = async ({ boardData }: { boardData: BoardRequest }) => {
    const { data } = await new BoardControllerApi(
      getYentaConfiguration(),
    ).createBoard(boardData);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.boardAndMls._def,
    mutationFn: createBoard,
  });
};

export const useFetchBoardDetails = (id: string) => {
  const getDetails = async (boardId: string): Promise<BoardResponse> => {
    const { data } = await new BoardControllerApi(
      getYentaConfiguration(),
    ).getBoardById(boardId);
    return data;
  };

  const { isLoading, data, error, refetch } = useSimpleQuery<BoardResponse>({
    queryKey: queryKeys.boardAndMls.detail(id).queryKey,
    queryFn: () => getDetails(id),
    options: {
      toastErrorMessage: 'Error retrieving board details',
      logErrorMessage: 'Error retrieving board details',
      enabled: !!id,
    },
  });

  return { isLoading, data, error, refetch };
};

export const useFetchBoardSecretDetails = (id: string, enabled: boolean) => {
  const getDetails = async (boardId: string): Promise<BoardSecretsResponse> => {
    const { data } = await new BoardControllerApi(
      getYentaConfiguration(),
    ).getBoardSecretsById(boardId);
    return data;
  };

  const { isLoading, data, refetch } = useSimpleQuery<BoardSecretsResponse>({
    queryKey: queryKeys.boardAndMls.secrets(id).queryKey,
    queryFn: () => getDetails(id),
    options: {
      toastErrorMessage: 'Error retrieving board secret details',
      logErrorMessage: 'Error retrieving board secret details',
      enabled: !!id && enabled,
    },
  });

  return { isLoading, data, refetch };
};

export const useUpdateBoard = (id: string) => {
  const updateBoard = async ({
    id,
    boardData,
  }: {
    id: string;
    boardData: BoardRequest;
  }) => {
    const { data } = await new BoardControllerApi(
      getYentaConfiguration(),
    ).updateBoardById(id, boardData);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.boardAndMls.updateBoardOrMls(id).queryKey,
    mutationFn: updateBoard,
  });
};

export const useCreateMls = () => {
  const createMls = async ({ mlsData }: { mlsData: MlsRequest }) => {
    const { data } = await new MlsControllerApi(
      getYentaConfiguration(),
    ).createMls(mlsData);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.boardAndMls._def,
    mutationFn: createMls,
  });
};

export const useFetchMlsDetails = (id: string) => {
  const getDetails = async (mlsId: string): Promise<MlsResponse> => {
    const { data } = await new MlsControllerApi(
      getYentaConfiguration(),
    ).getMlsById(mlsId);
    return data;
  };

  const { isLoading, data, refetch, error } = useSimpleQuery<MlsResponse>({
    queryKey: queryKeys.boardAndMls.detail(id).queryKey,
    queryFn: () => getDetails(id),
    options: {
      toastErrorMessage: 'Error retrieving mls details',
      logErrorMessage: 'Error retrieving mls details',
      enabled: !!id,
    },
  });

  return { isLoading, data, error, refetch };
};

export const useFetchMlsSecretDetails = (id: string, enabled: boolean) => {
  const getDetails = async (mlsId: string): Promise<MlsSecretsResponse> => {
    const { data } = await new MlsControllerApi(
      getYentaConfiguration(),
    ).getMlsSecretsById(mlsId);
    return data;
  };

  const { isLoading, data, refetch } = useSimpleQuery<MlsSecretsResponse>({
    queryKey: queryKeys.boardAndMls.secrets(id).queryKey,
    queryFn: () => getDetails(id),
    options: {
      toastErrorMessage: 'Error retrieving mls secret details',
      logErrorMessage: 'Error retrieving mls secret details',
      enabled: !!id && enabled,
    },
  });

  return { isLoading, data, refetch };
};

export const useGetPropertyTypeMapping = (id: string, enabled: boolean) => {
  const getDetails = async (mlsId: string) => {
    const { data } = await new MlsPropertyTypeControllerApi(
      getYentaConfiguration(),
    ).getPropertyTypeMapping(mlsId);
    return data;
  };

  const {
    isLoading,
    data,
    refetch,
  } = useSimpleQuery<MlsGetPropertyTypeResponse>({
    queryKey: queryKeys.boardAndMls.getPropertyTypeMapping(id).queryKey,
    queryFn: () => getDetails(id),
    options: {
      toastErrorMessage: 'Error retrieving property type mapping details',
      logErrorMessage: 'Error retrieving property type mapping details',
      enabled: !!id && enabled,
    },
  });

  return { isLoading, data, refetch };
};

export const useFetchPropertyTypesWanderer = (code: string) => {
  const getDetails = async (mlsCode: string) => {
    const { data } = await new WandererControllerApi(
      getWandererConfiguration(),
    ).getPropertyTypeValuesForMLS(mlsCode);
    return data;
  };

  const { isLoading, data, refetch } = useSimpleQuery<any>({
    queryKey: queryKeys.boardAndMls.getPropertyTypesWanderer(code).queryKey,
    queryFn: () => getDetails(code),
    options: {
      toastErrorMessage: 'Error retrieving property type values for MLS',
      logErrorMessage: 'Error retrieving property type values for MLS',
      enabled: !!code,
    },
  });

  return { isLoading, data, refetch };
};

export const useUpdateMls = (id: string) => {
  const updateMls = async ({
    id,
    mlsData,
  }: {
    id: string;
    mlsData: MlsRequest;
  }) => {
    const { data } = await new MlsControllerApi(
      getYentaConfiguration(),
    ).updateMlsById(id, mlsData);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.boardAndMls.updateBoardOrMls(id).queryKey,
    mutationFn: updateMls,
  });
};
