import { values } from 'lodash';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { CapCardISelection, ISelectOption, YesNoType } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenFormErrorMessage from '../Zen/Input/ZenFormErrorMessage';

interface JointVentureControlledCapInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  options: Array<ISelectOption>;
  disabled?: boolean;
}

const JointVentureControlledCapInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  options,
  shouldUnregister = true,
  ...rest
}: JointVentureControlledCapInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      {...rest}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <div>
          <div className='w-full flex flex-row items-center font-zen-body font-semibold text-sm leading-6 text-zen-dark-6'>
            <p className='w-1/3'>Cap Levels</p>
            <p className='w-2/3'>Cap Met?</p>
          </div>
          {options.map(({ value: optionValue, label }) => {
            const capMetOption = ((value || []) as CapCardISelection[]).find(
              (df) => df.capLevel === optionValue,
            );

            return (
              <div
                key={optionValue}
                className='flex flex-row items-center w-full py-1.5 h-12'
              >
                <label className='flex items-center w-1/3'>
                  <input
                    type='checkbox'
                    className='w-5 h-5 rounded border-2 border-zen-dark-4 text-primary-blue focus:outline-none focus:ring-0'
                    value={optionValue}
                    name={name}
                    onChange={({
                      target: { checked, value: checkboxValue },
                    }) => {
                      const updatedValues = checked
                        ? [
                            ...(value || []),
                            {
                              capLevel: checkboxValue,
                              capped: capMetOption ? capMetOption.capped : '',
                            },
                          ]
                        : value?.filter(
                            (v: CapCardISelection) =>
                              v.capLevel !== checkboxValue,
                          );
                      onChange(updatedValues);
                    }}
                    onBlur={onBlur}
                    checked={(value as CapCardISelection[])?.some(
                      (ch) => ch.capLevel === optionValue,
                    )}
                    ref={ref}
                  />
                  <p className='font-zen-body font-normal text-base leading-6 text-zen-dark-9 ml-3'>
                    {label}
                  </p>
                </label>
                <div>
                  {(((value as CapCardISelection[]) || undefined) ?? []).some(
                    (df) => df.capLevel === optionValue,
                  ) && (
                    <select
                      value={
                        capMetOption && capMetOption.capped === YesNoType.YES
                          ? YesNoType.YES
                          : YesNoType.NO
                      }
                      onChange={({ target: { value: selectedValue } }) => {
                        onChange(
                          (value as CapCardISelection[]).map((dv) => {
                            if (dv.capLevel === optionValue) {
                              return {
                                capLevel: dv.capLevel,
                                capped: selectedValue,
                              };
                            }
                            return dv;
                          }),
                        );
                      }}
                      placeholder='Select'
                      defaultValue=''
                      className='appearance-none px-2.5 py-1.5 border-zen-dark-4 rounded-lg bg-white focus:outline-none focus:ring-0 w-32'
                    >
                      {[
                        { label: 'Select', value: '', disabled: true },
                        ...values(YesNoType).map((el) => ({
                          label: capitalizeEnum(el),
                          value: el,
                          disabled: false,
                        })),
                      ].map((v) => (
                        <option
                          key={v.value}
                          value={v.value}
                          disabled={v.disabled}
                        >
                          {v.label}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
            );
          })}
          <div className='flex'>
            {!!error && <ZenFormErrorMessage message={error.message} />}
          </div>
        </div>
      )}
    />
  );
};

export default JointVentureControlledCapInput;
