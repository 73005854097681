import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { ISelectOption } from '../../types';
import GeminiCheckBox from './GeminiCheckBox';

interface GeminiControlledGroupSelectSquareProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  options: ISelectOption[];
  shouldUnregister?: boolean;
}

const GeminiControlledGroupSelectSquare = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  options,
  shouldUnregister = true,
  ...rest
}: GeminiControlledGroupSelectSquareProps<TFieldValues, TName>) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({ field: { value, onChange } }) => (
        <div>
          <div className='flex-grow'>
            <GeminiCheckBox
              onToggle={onChange}
              options={options}
              value={value}
            />
          </div>
        </div>
      )}
    />
  );
};
export default GeminiControlledGroupSelectSquare;
