import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { components, ControlProps, GroupTypeBase, Styles } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { EnumMap, ISelectOption } from '../../../types';
import ZenFormErrorMessage from './ZenFormErrorMessage';

type creatableVariant = 'default' | 'primary';

interface ZenControlledMultiSelectCreatableInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  placeholder?: string;
  options: Array<ISelectOption>;
  placeholderLabel?: string;
  styles?: Partial<Styles<any, boolean, GroupTypeBase<any>>>;
  customOptionIcon?: React.ReactElement;
  createLabelIcon?: React.ReactElement;
  variant?: creatableVariant;
  isRequired?: boolean;
  readOnly?: boolean;
}

const ZenControlledMultiSelectCreatableInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  placeholder = 'Select',
  options,
  customOptionIcon,
  createLabelIcon,
  variant = 'default',
  styles,
  shouldUnregister = false,
  placeholderLabel,
  isRequired,
  readOnly = false,
  ...rest
}: ZenControlledMultiSelectCreatableInputProps<TFieldValues, TName>) => {
  const variantToBgColorMap: EnumMap<creatableVariant, string> = {
    default: '#E6E6E6',
    primary: '#F3F8FF',
  };
  const variantToTextColorMap: EnumMap<creatableVariant, string> = {
    default: '#333333',
    primary: '#3B82F6',
  };
  const variantToClassNameMap: EnumMap<creatableVariant, string> = {
    default: 'text-zen-dark',
    primary: 'text-primary-blue',
  };

  const customStyle = {
    ...styles,
    multiValueLabel: (base: any) => ({
      ...base,
      backgroundColor: variantToBgColorMap[variant],
      color: variantToTextColorMap[variant],
      opacity: 1,
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      backgroundColor: variantToBgColorMap[variant],
      color: variantToTextColorMap[variant],
      '&:hover': {
        backgroundColor: variantToBgColorMap[variant],
        color: variantToTextColorMap[variant],
      },
    }),
    option: (base: any) => ({
      ...base,
      fontFamily: 'Open Sans',
      fontWeight: '400',
      fontSize: '16px',
      color: '#3E3F3C',
      backgroundColor: '#FFFFFF',
    }),
  };

  const Control = ({
    children,
    ...props
  }: ControlProps<ISelectOption, true>) => {
    return (
      <components.Control {...props}>
        {!!placeholderLabel && (
          <span className='px-2 font-zen-body font-medium'>
            {placeholderLabel}
          </span>
        )}
        {children}
      </components.Control>
    );
  };

  const MultiValueLabel = (props: any) => {
    return (
      <components.MultiValueLabel {...props}>
        <div className='flex flex-row items-center font-zen-body text-sm font-semibold'>
          {customOptionIcon}
          {props?.children}
        </div>
      </components.MultiValueLabel>
    );
  };

  const [focus, setFocus] = useState<boolean>(false);

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { error, invalid },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label
              htmlFor={name}
              className={classNames(
                'font-zen-body text-base font-semibold',
                invalid ? 'text-zen-danger' : 'text-zen-dark-9',
              )}
            >
              {label}
              {isRequired && <span className='text-zen-danger'>*</span>}
            </label>
          )}
          <div
            className={classNames(
              'flex flex-row flex-grow items-start border focus:border-zen-dark-9 rounded-lg',
              invalid && '!border-zen-danger',
              focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
              !isEmpty(value) && 'text-zen-dark-9',
            )}
          >
            <div className='w-full px-2'>
              <CreatableSelect<ISelectOption, true>
                options={options}
                formatCreateLabel={(label: string) => (
                  <div className='-mx-3'>
                    <div
                      className={classNames(
                        'flex flex-row items-center pt-2 text-primary-blue border-t border-zen-dark-5 px-3',
                        variantToClassNameMap[variant],
                      )}
                    >
                      {createLabelIcon}
                      <span className='font-zen-body text-base font-semibold text-primary-blue'>{`Create "${label}"`}</span>
                    </div>
                  </div>
                )}
                placeholder={placeholder}
                name={name}
                components={{
                  Control,
                  MultiValueLabel,
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null,
                  IndicatorsContainer: () => (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className='text-zen-dark mx-3'
                    />
                  ),
                }}
                isMulti
                value={value}
                closeMenuOnSelect={false}
                onChange={onChange}
                onFocus={() => setFocus(true)}
                onBlur={() => {
                  setFocus(false);
                  onBlur();
                }}
                ref={ref}
                className='react-select-container font-zen-body font-normal'
                classNamePrefix='react-select-noborder'
                inputId={name}
                aria-label={label}
                styles={customStyle}
                isDisabled={readOnly}
              />
            </div>
          </div>
          {error && <ZenFormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenControlledMultiSelectCreatableInput;
