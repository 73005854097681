import classNames from 'classnames';
import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { inRange } from 'lodash';
import { FormFieldTooltipIndexProps } from '../../../types';
import { amountWithPrecision } from '../../../utils/CurrencyUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';

type DisplayVariant = 'block' | 'inline';
export interface ZenControlledPercentageInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  subLabel?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  isRequired?: boolean;
  textAlign?: 'text-left' | 'text-right' | 'text-center';
  decimalsLimit?: number;
  displayVariant?: DisplayVariant;
}

const ZenControlledPercentageInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  tooltipIndex,
  shouldUnregister = true,
  startAdornment,
  endAdornment,
  isRequired = false,
  textAlign = 'text-left',
  decimalsLimit,
  displayVariant = 'block',
  ...rest
}: ZenControlledPercentageInputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);

  const DisplayVariantEnumMap: Record<DisplayVariant, string> = {
    block: 'space-y-1',
    inline: 'flex items-start justify-between',
  };

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error, invalid },
      }) => (
        <div
          className={classNames(
            'w-full',
            DisplayVariantEnumMap[displayVariant],
          )}
        >
          {label && (
            <label className='inline-block' htmlFor={name}>
              <span
                className={classNames(
                  'font-zen-body font-semibold',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                )}
              >
                {label}

                {!!subLabel && (
                  <span className='font-zen-body text-sm text-zen-dark-12 ml-1'>
                    {subLabel}
                  </span>
                )}
                {isRequired && <span className='text-zen-danger'>*</span>}
              </span>
            </label>
          )}
          <div>
            <div
              className={classNames(
                'flex items-stretch border rounded-lg overflow-hidden',
                value && 'text-zen-dark-9',
                invalid && '!border-zen-danger',
                focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
                {
                  'border-none': noBorder,
                },
              )}
            >
              {startAdornment && <div>{startAdornment}</div>}
              <CurrencyInput
                id={name}
                value={value}
                onFocus={() => setFocus(true)}
                onBlur={() => {
                  onChange(
                    value
                      ? amountWithPrecision(value, decimalsLimit ?? 2)
                      : value,
                  );
                  setFocus(false);
                  onBlur();
                }}
                onValueChange={(val) => {
                  // as percentage should be between 0 and 100
                  if (!val || inRange(Number(val), 0, 100.01)) {
                    onChange(val);
                  }
                }}
                name={name}
                readOnly={readOnly}
                placeholder={placeholder}
                allowNegativeValue={false}
                className={classNames(
                  'appearance-none p-2 w-full border-none focus:outline-none focus:ring-0 font-zen-body font-normal',
                  textAlign,
                  {
                    'bg-gray-50': readOnly,
                  },
                )}
                data-tooltip-index={tooltipIndex}
                decimalsLimit={decimalsLimit}
                allowDecimals
                disableGroupSeparators
              />
              {endAdornment && <div>{endAdornment}</div>}
            </div>
            {error && !hideErrorMessage && (
              <ZenFormErrorMessage message={error.message} />
            )}
          </div>
        </div>
      )}
    />
  );
};

export default ZenControlledPercentageInput;
