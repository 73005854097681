import React from 'react';
import { cn } from '../../../utils/classUtils';

interface ZenViewCheckInfoColumnProps {
  label: string;
  value?: React.ReactElement | number | string;
  bold?: boolean;
}

const ZenViewCheckInfoColumn: React.FC<ZenViewCheckInfoColumnProps> = ({
  label,
  value,
  bold = false,
}) => {
  return (
    <div className='inline-flex flex-col space-y-2 font-zen-body font-medium text-base text-zen-dark-9'>
      <label className='font-zen-title uppercase'>{label}</label>
      <div
        className={cn('text-zen-dark-7', {
          'font-bold text-zen-dark-9': bold,
        })}
      >
        {value ?? 'N/A'}
      </div>
    </div>
  );
};

export default ZenViewCheckInfoColumn;
