import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import {
  EscrowResponse,
  TransactionResponse,
  AgentResponse,
  EscrowDepositResponse,
} from '../../../openapi/arrakis';
import { displayFormattedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import {
  isTransactionOrFinanceAdmin,
  isTransactionReadOnly,
} from '../../../utils/TransactionHelper';
import { RootState } from '../../../types';
import { getAgentFullName } from '../../../utils/AgentHelper';
import { isExternDeposit } from '../../../utils/DepositsUtil';

interface ManageCommissionDepositCardProps {
  depositPayment: EscrowResponse;
  transaction: TransactionResponse;
  userDetails: AgentResponse;
  onEdit(): void;
  onDelete(): void;
}

const ZenManageCommissionDepositCard: React.FC<ManageCommissionDepositCardProps> = ({
  depositPayment,
  onDelete,
  onEdit,
  transaction,
  userDetails,
}) => {
  const {
    userIds: { agentById },
    auth: { keymakerCurrentUser },
  } = useSelector((state: RootState) => state);

  const isDepositDeleteDisabled = (deposits: EscrowDepositResponse[] = []) => {
    return deposits.some(
      (deposit) => !!deposit?.checkDepositId || !!deposit?.confirmedAt,
    );
  };

  const depositCreatedBy = !!depositPayment?.createdBy
    ? getAgentFullName({
        firstName: agentById[depositPayment?.createdBy]?.firstName,
        lastName: agentById[depositPayment?.createdBy]?.lastName,
      })
    : isExternDeposit(depositPayment?.deposits || [])
    ? depositPayment?.deposits?.[0]?.depositorName
    : undefined;

  const allowEditAndDelete =
    !isTransactionReadOnly(transaction, userDetails) ||
    (isTransactionOrFinanceAdmin(keymakerCurrentUser?.activeRoles!) &&
      transaction.terminated);

  return (
    <div className='border rounded-[10px] border-zen-dark-4 p-6'>
      <div className='flex justify-between items-center border-b border-zen-dark-4 pb-4'>
        <p className='text-zen-dark-9 text-lg font-zen-body font-semibold'>
          {displayFormattedAmountWithCurrency(depositPayment.amount)} deposit
        </p>
        {allowEditAndDelete && (
          <div className='flex space-x-3'>
            <div
              onClick={onEdit}
              data-testid='edit'
              className='bg-zen-light-gray-2 rounded-full p-2 cursor-pointer hover:bg-zen-light-gray-1'
            >
              <FontAwesomeIcon icon={faPen} className='px-1' />
            </div>
            {!isDepositDeleteDisabled(depositPayment.deposits) && (
              <div
                data-testid='delete'
                onClick={onDelete}
                className='bg-zen-light-gray-2 rounded-full text-zen-danger p-2 cursor-pointer hover:bg-zen-light-gray-1'
              >
                <FontAwesomeIcon icon={faTrashCan} className='px-1' />
              </div>
            )}
          </div>
        )}
      </div>
      <div className='grid grid-cols-2 py-4'>
        <div className='space-y-1'>
          <p className='text-[#969799] text-base font-zen-body font-semibold'>
            Required by
          </p>
          <p className='text-zen-dark-9 text-lg font-zen-body'>
            {DateTime.fromISO(depositPayment.requiredByDate!).toFormat(
              'LL/dd/yy',
            )}
          </p>
        </div>
        <div className='space-y-1'>
          <p className='text-[#969799] text-base font-zen-body font-semibold'>{`Installments (${depositPayment.deposits?.length})`}</p>
          {depositPayment.deposits?.map((deposit) => (
            <div className='grid grid-cols-2' key={deposit.id}>
              <p className='text-zen-dark-9 text-lg font-zen-body'>
                {displayFormattedAmountWithCurrency(deposit.amount)}
              </p>
              <p className='text-zen-dark-9 text-lg font-zen-body'>
                - {DateTime.fromISO(deposit.dateReceived!).toFormat('LL/dd/yy')}
              </p>
            </div>
          ))}
        </div>
      </div>
      {depositCreatedBy && (
        <div className='py-2'>
          <div>
            <p className='text-[#969799] text-base font-zen-body font-semibold'>
              Created By
            </p>
            <p className='text-zen-dark-9 text-lg font-zen-body break-words'>
              {depositCreatedBy}
            </p>
          </div>
        </div>
      )}
      <div className='py-2'>
        <div>
          <p className='text-[#969799] text-base font-zen-body font-semibold'>
            Deposit Description
          </p>
          <p className='text-zen-dark-9 text-lg font-zen-body break-words'>
            {depositPayment.description}
          </p>
        </div>
      </div>
      <div className='py-2'>
        <div>
          <p className='text-[#969799] text-base font-zen-body font-semibold'>
            Notes
          </p>
          {depositPayment.note ? (
            <p className='text-zen-dark-9 text-lg font-zen-body break-words'>
              {depositPayment.note}
            </p>
          ) : (
            <p className='text-gray-400'>No notes</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ZenManageCommissionDepositCard;
