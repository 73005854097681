import { useSelector } from 'react-redux';
import { DemoModeContainer } from '../../containers/DemoModeContainer';
import { EliteStatusValue } from '../../openapi/arrakis';
import { RootState } from '../../types';
import { cn } from '../../utils/classUtils';
import {
  displayAmount,
  DisplayAmountOptions,
  displayShortAmount,
} from '../../utils/CurrencyUtils';
import { useGemini } from '../../hooks/useGemini';

interface EliteStatusTrackerProps {
  eliteStatus: EliteStatusValue;
}

const EliteStatusTracker: React.FC<EliteStatusTrackerProps> = ({
  eliteStatus,
}) => {
  const isGeminiEnabled = useGemini();
  const {
    agentDetail: {
      performanceResponse: { data: performance },
    },
  } = useSelector((state: RootState) => state);

  let gciProgressPercentage =
    (eliteStatus.gciEarned?.amount! / eliteStatus.minGCI?.amount!) * 100;

  let postCapFeeProgressPercentage =
    (eliteStatus.postCapFeesPaid?.amount! /
      eliteStatus.postCapTransactionFeesTotal?.amount!) *
    100;

  if (gciProgressPercentage > 100) {
    gciProgressPercentage = 100;
  }

  if (postCapFeeProgressPercentage > 100) {
    postCapFeeProgressPercentage = 100;
  }

  const options: DisplayAmountOptions = { hideZeroCents: true };

  return (
    <div className='font-zen-body text-zen-dark-9'>
      <p>Elite Status:</p>
      <div>
        <DemoModeContainer>
          <p>{`${displayAmount(
            eliteStatus.gciEarned,
            options,
          )} / ${displayAmount(eliteStatus.minGCI, options)} GCI`}</p>
        </DemoModeContainer>
        <div className='my-2 bg-gray-100 rounded'>
          <div
            data-testid='elite-progress-bar'
            className={cn(
              'rounded',
              gciProgressPercentage >= 100
                ? 'bg-primary-blue'
                : 'bg-yellow-400',
            )}
            style={{
              width: `${gciProgressPercentage}%`,
              height: '10px',
            }}
          />
        </div>
        <DemoModeContainer>
          <p className='mt-4'>
            {`${displayAmount(
              eliteStatus.postCapFeesPaid,
              options,
            )} / ${displayAmount(
              eliteStatus.postCapTransactionFeesTotal,
              options,
            )} post-cap fees`}
          </p>
        </DemoModeContainer>
        <div className='my-2 bg-gray-100 rounded'>
          <div
            data-testid='elite-progress-bar'
            className={cn(
              'rounded',
              postCapFeeProgressPercentage >= 100
                ? 'bg-primary-blue'
                : 'bg-yellow-400',
            )}
            style={{
              width: `${postCapFeeProgressPercentage}%`,
              height: '10px',
            }}
          />
        </div>
        {!!performance?.eliteEquityAwardPlan?.minTransactionPrice && (
          <div className='flex flex-row items-center justify-between my-4'>
            <span
              className={cn(
                isGeminiEnabled
                  ? 'font-zen-body-2 font-medium text-sm text-grey-600'
                  : 'font-zen-body font-normal text-base text-zen-dark-9',
              )}
            >
              {`Transactions Over $${displayShortAmount(
                performance?.eliteEquityAwardPlan?.minTransactionPrice,
              )}`}
            </span>
            <span
              className={cn(
                isGeminiEnabled
                  ? 'font-zen-body-2 font-light text-sm text-primary-dark'
                  : 'font-zen-body font-semibold text-base text-zen-dark-9',
              )}
            >
              {performance?.numClosedEliteTransactions ?? 0}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EliteStatusTracker;
