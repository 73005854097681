import React from 'react';
import { cn } from '../../utils/classUtils';

export type ZenIconCircleVariant =
  | 'success'
  | 'primary'
  | 'warning'
  | 'error'
  | 'default'
  | 'gray'
  | 'light-gray'
  | 'purple';

export type ZenIconCircleSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const iconMapBgColor: Record<ZenIconCircleVariant, string> = {
  default: 'bg-white',
  primary: 'bg-primary-blue bg-opacity-10 hover:bg-opacity-30',
  error: 'bg-zen-danger bg-opacity-10 hover:bg-opacity-30',
  warning: 'bg-zen-warning bg-opacity-10 hover:bg-opacity-30',
  success: 'bg-green-600 bg-opacity-10 hover:bg-opacity-30',
  gray: 'bg-zen-dark-8 bg-opacity-10 hover:bg-opacity-30',
  purple: 'bg-zen-purple-dark bg-opacity-10 hover:bg-opacity-30',
  'light-gray': 'bg-zen-light-gray-2 hover:bg-zen-dark-4',
};

const iconMapSize: Record<ZenIconCircleSize, string> = {
  xs: 'h-8 w-8',
  sm: 'h-12 w-12',
  md: 'h-16 w-16',
  lg: 'h-20 w-20',
  xl: 'h-24 w-24',
};

interface ZenIconCircleProps {
  variant: ZenIconCircleVariant;
  size?: ZenIconCircleSize;
  icon: React.ReactNode;
}

const ZenIconCircle: React.FC<ZenIconCircleProps> = ({
  variant,
  icon,
  size = 'md',
}) => {
  return (
    <div
      className={cn(
        `${iconMapSize[size]} rounded-full flex flex-row items-center justify-center`,
        iconMapBgColor[variant],
      )}
    >
      {icon}
    </div>
  );
};

export default ZenIconCircle;
