import { BsExclamationSquareFill } from 'react-icons/bs';

export interface ZenDefaultErrorProps {
  message: string;
}

const ZenDefaultError: React.FC<ZenDefaultErrorProps> = ({ message }) => {
  return (
    <div className='py-4 px-6 grid grid-flow-col items-center bg-zen-danger-light rounded-lg'>
      <BsExclamationSquareFill
        className='text-error col-span-1'
        fontSize={18}
      />
      <p className='font-zen-body font-medium text-error pl-4 col-span-11'>
        {message}
      </p>
    </div>
  );
};

export default ZenDefaultError;
