import { useRef, useState } from 'react';
import AnnouncementPlaceholder from '../../../assets/img/announcement-placeholder.png';
import GradientPlayButton from '../../../assets/img/gradient-primary-play.png';
import DismissTooltip from './DismissToolTip';

export interface AnnouncementHeaderProps {
  bannerURL?: string;
  videoURL?: string;
  preview: boolean;
}

const AnnouncementHeader: React.FC<AnnouncementHeaderProps> = ({
  bannerURL,
  videoURL,
  preview,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayPause = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (isPlaying) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
    setIsPlaying(!isPlaying);
    event?.preventDefault();
  };

  return (
    <div className='relative h-full max-h-[300px] rounded-t-2xl'>
      {videoURL ? (
        <div
          className='relative bg-black h-full max-h-[300px] mx-auto'
          onClick={(event) => handlePlayPause(event)}
        >
          <video
            className='w-full h-full max-h-[300px] overflow-hidden'
            src={videoURL}
            controls
            ref={videoRef}
            aria-label='announcement-video'
          >
            <source src={videoURL} type='video/mp4' />
          </video>
          {!isPlaying && (
            <div className='absolute top-0 left-0 right-0 bottom-0 h-full cursor-pointer'>
              <div className='w-full h-full flex items-center justify-center'>
                {!!bannerURL && (
                  <img
                    src={bannerURL}
                    className='w-full h-full absolute top-0 right-0 left-0 bottom-0 rounded-t-xl overflow-hidden'
                    alt='announcement-banner'
                  />
                )}
                <img
                  src={GradientPlayButton}
                  className='w-10 z-20'
                  alt='play'
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <img
          src={bannerURL || AnnouncementPlaceholder}
          className='w-full rounded-t-xl overflow-hidden'
          style={{ maxWidth: 'inherit', maxHeight: 'inherit' }}
          alt='announcement-placeholder'
        />
      )}
      <DismissTooltip preview={preview} />
    </div>
  );
};

export default AnnouncementHeader;
