import ZenResourceIndexContainer, {
  TableProps,
} from './Containers/ZenResourceIndexContainer';

interface ZenFixedDataTableProps<D extends object>
  extends Omit<TableProps<D>, 'fetchData'> {}

const ZenFixedDataTable = <D extends object>(
  props: ZenFixedDataTableProps<D>,
) => {
  return (
    <ZenResourceIndexContainer<D>
      {...props}
      standalone={props.standalone ?? false}
    />
  );
};

export default ZenFixedDataTable;
