import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import RealTitleLogo from '../../assets/img/RealTitleLogo.svg';
import {
  declineJointVentureInvitation,
  fetchJVInvite,
} from '../../slices/JointVentureSlice';
import { RootState } from '../../types';
import ResourceContainer from '../ResourceContainer';
import ZenSimpleConfirmationModal from '../Zen/Modal/ZenSimpleConfirmationModal';
import ZenRoute from '../Zen/ZenRoute';
import LearnMoreBookMeeting from './LearnMoreBookMeeting';
import LearnMoreFooter from './LearnMoreFooter';
import LearnMoreSidebar from './LearnMoreSidebar';
import LearnMoreWhyAndFeatures from './LearnMoreWhyAndFeatures';

type Match = {
  inviteId: string;
};

const LearnMore: React.FC = () => {
  const history = useHistory();
  const { inviteId } = useParams() as Match;
  const dispatch = useDispatch();
  const {
    auth: { userDetail, isAdmin, isBroker },
    jointVenture: {
      showInviteResponse: { data: inviteDetails, loading },
    },
  } = useSelector((state: RootState) => state);

  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);

  const handleMaybeLater = () => {
    setOpenCancelModal(false);
    history.push('/');
  };

  const handleDecline = () => {
    if (inviteDetails?.id) {
      dispatch(declineJointVentureInvitation(inviteDetails.id));
    }
    handleMaybeLater();
  };

  const onClose = () => setOpenCancelModal(true);

  useEffect(() => {
    if (userDetail?.id && !isAdmin && !isBroker) {
      dispatch(fetchJVInvite());
    }
  }, [dispatch, isAdmin, isBroker, userDetail?.id]);

  if (!loading && (!inviteDetails || inviteDetails.id !== inviteId)) {
    return <Redirect to='/' />;
  }

  return (
    <ZenRoute title='Joint-Venture Information'>
      <div className='fixed top-0 right-0 bottom-0 left-0 w-full h-full scrollbar overflow-auto z-10'>
        <div className='w-full bg-zen-light-blue flex items-center justify-between py-6 px-10'>
          <img
            src={RealTitleLogo}
            className='w-[132px] h-8'
            alt='Real Title Mail'
          />
          <div className='w-6 h-6 flex items-center justify-center bg-zen-dark-10 rounded-full'>
            <FontAwesomeIcon
              icon={faXmark}
              size='1x'
              color='white'
              onClick={onClose}
            />
          </div>
        </div>
        <ResourceContainer
          isEmpty={!inviteDetails}
          loading={loading}
          resourceName='Joint Venture'
        >
          <div className='flex flex-grow'>
            <div className='grid grid-cols-3'>
              <LearnMoreSidebar
                tierName={inviteDetails?.tier?.name}
                jointVentureName={inviteDetails?.jointVenture?.name}
              />

              <div className='col-span-2 flex flex-col flex-grow'>
                <LearnMoreWhyAndFeatures />
                <LearnMoreBookMeeting />
              </div>
            </div>
          </div>
        </ResourceContainer>
        <LearnMoreFooter
          onSignUp={() =>
            history.push(`/real-title/${inviteDetails?.id}/signup`)
          }
          issuePrice={inviteDetails?.jointVenture?.issuePrice?.amount}
        />
        <ZenSimpleConfirmationModal
          variant='danger'
          title={`Are you sure you do not want to join ${inviteDetails?.jointVenture?.name}?`}
          confirmButtonText='Decline Invitation'
          cancelButtonText='Maybe Later'
          onClose={handleMaybeLater}
          onConfirm={handleDecline}
          isOpen={openCancelModal}
          size='large'
          isSubmitting={false}
          hideIcon
        />
      </div>
    </ZenRoute>
  );
};

export default LearnMore;
