import {
  LedgerItemValue,
  LedgerItemValueLedgerItemTypeEnum,
} from '../openapi/arrakis';
import { capitalizeEnum, isStringPresent } from '../utils/StringUtils';
import CollapsibleTableRowItem from './CollapsibleTableRowItem';
import SignedNumber from './SignedNumber';

interface LedgerItemRowProps {
  item: LedgerItemValue;
}

const LedgerItemRow: React.FC<LedgerItemRowProps> = ({ item }) => {
  let name = capitalizeEnum(item.ledgerItemType!);

  if (
    item.ledgerItemType ===
      LedgerItemValueLedgerItemTypeEnum.InstantPaymentRepayment &&
    isStringPresent(item.note)
  ) {
    name = item.note;
  }

  return (
    <CollapsibleTableRowItem
      name={name}
      value={<SignedNumber value={item.amount!} />}
    />
  );
};

export default LedgerItemRow;
