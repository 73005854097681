import React from 'react';
import { cn } from '../../../utils/classUtils';

export interface SidebarTab {
  tabName: string;
  tabContent: React.ReactNode;
}

interface ReferralSidebarTabsProps {
  tabs: SidebarTab[];
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}

const ReferralSidebarTabs: React.FC<ReferralSidebarTabsProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
}) => {
  const handleClick = (tabName: string) => {
    setSelectedTab(tabName);
  };

  return (
    <div className='flex flex-col px-4'>
      <div className='w-full px-2 py-1.5 bg-regent-300 rounded-lg justify-center items-center gap-1 flex'>
        {tabs.map((val) => (
          <div
            key={val.tabName}
            className={cn(
              'px-4 py-1.5  rounded-lg justify-center items-center gap-2.5 flex w-1/2 cursor-pointer',
              selectedTab === val.tabName &&
                'border border-regent-600 bg-white',
            )}
            onClick={() => {
              handleClick(val.tabName);
            }}
          >
            <div
              className={cn(
                'text-center font-inter leading-tight text-base',
                selectedTab === val.tabName
                  ? 'text-primary-dark font-medium'
                  : 'text-[#7b7a7a] font-normal',
              )}
            >
              {val.tabName}
            </div>
          </div>
        ))}
      </div>
      {tabs.find((tab) => tab.tabName === selectedTab)?.tabContent}
    </div>
  );
};

export default ReferralSidebarTabs;
