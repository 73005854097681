import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ApplicationLayout from '../ApplicationLayout';
import IconButton from '../IconButton';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { RootState } from '../../types';
import { saveApplication } from '../../slices/AuthSlice';
import useRedirectAwayOnboardingEffect from '../../hooks/useRedirectAwayOnboardingEffect';
import {
  ApplicationControllerApi,
  ApplicationResponseNextStatusEnum,
} from '../../openapi/yenta';
import SubmitButton from '../SubmitButton';
import ErrorService from '../../services/ErrorService';
import { toggleSupportModal } from '../../slices/SupportSlice';

const ApplicationJoinBoard: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const applicationLastIndex = userDetail!.applications!.length - 1;

  useRedirectAwayOnboardingEffect(ApplicationResponseNextStatusEnum.JoinBoard);

  const agentJoinedBoard = async () => {
    setIsSubmitting(true);

    try {
      const api = new ApplicationControllerApi(getYentaConfiguration());
      const { data } = await api.boardJoined({
        applicationId: userDetail?.applications![applicationLastIndex].id!,
      });
      dispatch(saveApplication(data));
      history.push('/onboarding/application-submitted/success');
    } catch (e) {
      ErrorService.notify('Unable to join board in application', e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ApplicationLayout>
      <div className='flex justify-center items-center mt-20'>
        <div className='container'>
          <div className='text-center px-5'>
            <p className='lg:text-4xl text-3xl font-primary-medium'>
              Transfer Board/MLS Sponsorship
            </p>
            <p className='pt-6 text-gray-500 lg:text-lg lg:px-96'>
              You&apos;re almost there!
            </p>
            <p className='py-3 text-gray-500 lg:text-lg lg:px-96'>
              Please transfer your board/MLS sponsorship. When you’re finished,
              click the button below.
            </p>
          </div>
          <div className='flex flex-col items-center'>
            <div className='mt-5'>
              <SubmitButton
                label='Yes, I’ve transferred the board/MLS sponsorship'
                isSubmitting={isSubmitting}
                onClick={agentJoinedBoard}
              />
            </div>
            <div className='mt-5 mb-2'>
              <p className='text-gray-500'>Have Questions?</p>
            </div>
            <div>
              <IconButton
                label='Contact Support'
                onClick={() => dispatch(toggleSupportModal(true))}
                variant='outline'
              />
            </div>
          </div>
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default ApplicationJoinBoard;
