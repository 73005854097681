import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';
import { Controller, UseControllerOptions } from 'react-hook-form-v6';
import { FieldValues } from 'react-hook-form-v6/dist/types';
import { FieldErrors } from 'react-hook-form-v6/dist/types/errors';
import { FormFieldTooltipIndexProps } from '../types';
import { cn } from '../utils/classUtils';
import { isStringPresent } from '../utils/StringUtils';
import FormErrorMessage from './FormErrorMessage';

export type ControlledEmailInputType =
  | 'text'
  | 'email'
  | 'number'
  | 'hidden'
  | 'date'
  | 'datetime-local'
  | 'image'
  | 'month'
  | 'password'
  | 'search'
  | 'tel'
  | 'time'
  | 'url'
  | 'week';

export interface ControlledEmailInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends UseControllerOptions<TFieldValues>,
    FormFieldTooltipIndexProps {
  errors: FieldErrors<TFieldValues>;
  type?: ControlledEmailInputType;
  label?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  subtitle?: string;
}

const ControlledEmailInput: React.FC<ControlledEmailInputProps> = ({
  errors,
  control,
  name,
  label,
  defaultValue,
  rules,
  type = 'text',
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  tooltipIndex,
  subtitle,
}) => {
  const error = get(errors, name);

  return (
    <div className='space-y-1 w-full'>
      {label && (
        <label className='inline-block' htmlFor={name}>
          {label}
        </label>
      )}
      {isStringPresent(subtitle) && (
        <div className='flex flex-row'>
          <FontAwesomeIcon
            icon={faCircleQuestion}
            className='text-base text-gray-600 mr-2'
          />
          <p className='text-gray-600'>{subtitle}</p>
        </div>
      )}
      <Controller
        id={name}
        control={control}
        name={name}
        defaultValue={defaultValue || ''}
        rules={rules}
        render={({ name, value, onChange, onBlur, ref }) => (
          <input
            id={name}
            ref={ref}
            value={value}
            onChange={(e) => {
              onChange(e.target.value.trim());
            }}
            onBlur={onBlur}
            type={type}
            name={name}
            readOnly={readOnly}
            placeholder={placeholder}
            className={cn(
              'appearance-none p-1.5 border-gray-200 rounded bg-white focus:outline-none focus:ring-0 w-full',
              {
                'bg-gray-50': readOnly,
              },
              {
                'border-none': noBorder,
              },
            )}
            data-tooltip-index={tooltipIndex}
          />
        )}
      />
      {error && !hideErrorMessage && (
        <FormErrorMessage message={error.message} />
      )}
    </div>
  );
};

export default ControlledEmailInput;
