import { groupBy, values } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGavel } from '@fortawesome/pro-solid-svg-icons';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { AgentResponse, BoardPreviewResponse } from '../../../openapi/yenta';
import { useGemini } from '../../../hooks/useGemini';
import { AsyncResponse, EmailSupportType } from '../../../types';
import { getDefaultStateOrProvince } from '../../../utils/BoardUtils';
import { cn } from '../../../utils/classUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { openEmailClientForSupportEmail } from '../../../utils/SupportUtils';
import AdminOnly from '../../auth/AdminOnly';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import AuthorizedOnly from '../../auth/AuthorizedOnly';
import ExcludeAdmin from '../../auth/ExcludeAdmin';
import SelfOnly from '../../auth/SelfOnly';
import DefaultEmpty from '../../DefaultEmpty';
import ZenAction from '../../Zen/ZenAction';
import ZenCard from '../../Zen/ZenCard';
import ZenTabs from '../../Zen/ZenTabs';
import ManageBoardForm from './ManageBoardForm';

interface ZenBoardCardProps {
  agentResponse: AsyncResponse<AgentResponse>;
}

const ZenBoardCard: React.FC<ZenBoardCardProps> = ({ agentResponse }) => {
  const agent = agentResponse.data;
  const stateBoard = groupBy(
    agent?.boards,
    'administrativeArea.stateOrProvince',
  );

  const isGeminiEnabled = useGemini();

  const tabs = values(stateBoard).map((board: BoardPreviewResponse[]) => {
    return {
      name: capitalizeEnum(board[0].administrativeArea!.stateOrProvince!),
      TabComponent: (
        <div className='flex flex-col space-y-2 p-3'>
          {board.map((item) => {
            return (
              <p className='font-zen-body text-base' key={item.code}>
                {item.name}
              </p>
            );
          })}
        </div>
      ),
    };
  });

  const tabName = tabs[0]?.name;
  const [boardTab, setBoardTab] = useState<string>(tabName);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setBoardTab(tabName);
  }, [tabName]);

  return (
    <ZenCard
      title='Board'
      RightComponent={
        <AuthorizedOnly asyncResponse={agentResponse}>
          <AdminOnly>
            <ZenAction
              text='Edit'
              onClick={() => setIsOpen(true)}
              icon={
                <FontAwesomeIcon
                  icon={faPen}
                  className={cn(
                    isGeminiEnabled
                      ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                      : 'text-primary-blue text-sm',
                  )}
                />
              }
            />
          </AdminOnly>
          <ExcludeAdmin>
            <SelfOnly agentId={agent?.id}>
              <ZenAction
                icon={
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={cn(
                      isGeminiEnabled
                        ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                        : 'text-primary-blue text-sm',
                    )}
                  />
                }
                text='Contact Support for Updates'
                onClick={() =>
                  openEmailClientForSupportEmail(
                    EmailSupportType.MY_BOARDS,
                    agent!,
                  )
                }
              />
            </SelfOnly>
          </ExcludeAdmin>
        </AuthorizedOnly>
      }
    >
      <AuthorizationContainer asyncResponse={agentResponse}>
        {!values(stateBoard)?.length ? (
          <DefaultEmpty
            message='There are no Boards to display.'
            icon={
              <FontAwesomeIcon
                icon={faGavel}
                className='text-zen-dark-9'
                size='lg'
              />
            }
          />
        ) : (
          <ZenTabs
            tabs={tabs}
            selected={boardTab}
            onChange={setBoardTab}
            size='sm'
          />
        )}
        {isOpen && (
          <ManageBoardForm
            isOpen
            onClose={() => setIsOpen(false)}
            defaultStateOrProvince={getDefaultStateOrProvince(boardTab)}
          />
        )}
      </AuthorizationContainer>
    </ZenCard>
  );
};

export default ZenBoardCard;
