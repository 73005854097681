import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import Select, {
  FocusEventHandler,
  GroupTypeBase,
  MenuPosition,
  OptionProps,
  Props as SelectProps,
  Styles,
  components,
} from 'react-select';
import { CustomSelectOptions } from './ZenControlledSelectInput';

interface ZenSelectInputProps {
  closeMenuOnSelect?: boolean;
  customClassName?: string;
  disabled?: boolean;
  endAdornment?: React.ReactElement;
  isClearable?: boolean;
  isRequired?: boolean;
  isSearchable?: boolean;
  label?: string;
  menuPosition?: MenuPosition;
  name: string;
  onBlur?: FocusEventHandler;
  onChange?: SelectProps<CustomSelectOptions>['onChange'];
  options: CustomSelectOptions[];
  placeholder?: string;
  readOnly?: boolean;
  startAdornment?: React.ReactElement;
  styles?: Partial<Styles<any, boolean, GroupTypeBase<any>>>;
  subLabel?: string;
  value?: CustomSelectOptions | null | undefined;
}

const Option = (
  props: OptionProps<
    CustomSelectOptions,
    false,
    GroupTypeBase<CustomSelectOptions>
  >,
) => {
  return (
    <components.Option {...props}>
      {props.data.LabelComponent ?? props.data.label}
    </components.Option>
  );
};

/**
 *
 * Spin-off version of `ZenControlledSelectInput`
 * Does not depend on `react-hook-form`
 * Useful for simple things like a dropdown select menu outside a form
 *
 */
const ZenSelectInput = ({
  label,
  subLabel,
  placeholder = 'Select',
  disabled = false,
  readOnly,
  options,
  styles,
  name,
  value,
  onChange,
  onBlur,
  startAdornment,
  endAdornment,
  customClassName = '',
  closeMenuOnSelect = true,
  isSearchable = false,
  isRequired = false,
  isClearable = false,
  menuPosition,
}: ZenSelectInputProps) => {
  const [focus, setFocus] = useState<boolean>(false);

  return (
    <div className='space-y-1 w-full'>
      <div className='space-y-1 w-full'>
        {label && (
          <label className={classNames('inline-block')} htmlFor={name}>
            <span className='font-zen-body font-semibold'>{label}</span>
            {!!subLabel && (
              <span className='font-zen-body text-sm text-zen-dark-6 ml-1'>
                {subLabel}
              </span>
            )}
            {isRequired && <span className='text-zen-danger'>*</span>}
          </label>
        )}
        <div
          className={classNames(
            'flex flex-row flex-grow items-center border rounded-lg font-zen-body font-normal px-2 text-ellipsis whitespace-nowrap',
            value?.value && 'text-zen-dark-9',
            focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
            customClassName,
            readOnly && 'bg-gray-50',
          )}
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
          }}
        >
          <div className='flex flex-row items-center flex-grow'>
            {startAdornment && <div>{startAdornment}</div>}
            <div className='w-full'>
              <Select
                isOptionDisabled={(option) => option.disabled!}
                styles={styles}
                placeholder={placeholder}
                value={value}
                onFocus={() => setFocus(true)}
                onBlur={(e) => {
                  setFocus(false);
                  onBlur?.(e);
                }}
                onChange={onChange}
                isSearchable={isSearchable}
                name={name}
                isDisabled={disabled || readOnly}
                defaultValue={value}
                options={options}
                menuPosition={menuPosition}
                closeMenuOnSelect={closeMenuOnSelect}
                className='react-select-container font-zen-body font-normal'
                classNamePrefix='react-select-noborder'
                inputId={name}
                isClearable={isClearable}
                components={{
                  Option,
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                  IndicatorsContainer: (props) =>
                    endAdornment ? (
                      <div className='flex flex-row items-center h-10'>
                        {isClearable && (
                          //@ts-ignore
                          <components.ClearIndicator {...props} />
                        )}
                        <div
                          className={classNames(
                            'h-10 border-l pt-2',
                            value && 'border-zen-dark-9',
                          )}
                        >
                          {endAdornment}
                        </div>
                      </div>
                    ) : (
                      <div className='flex flex-row items-center h-10'>
                        {isClearable && (
                          //@ts-ignore
                          <components.ClearIndicator {...props} />
                        )}
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className='text-zen-dark mx-3 text-sm'
                        />
                      </div>
                    ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ZenSelectInput;
