export const PRIVACY_POLICY_URL = 'https://onereal.com/pages/legal#privacy';
export const TERMS_OF_USE_URL = 'https://onereal.com/pages/legal#terms-of-use';
export const SEDAR_PLUS_URL = 'https://www.sedarplus.ca';
export const SEC_URL = 'https://www.sec.gov';

export const WealthPlanDisclaimer = () => {
  return (
    <div className='border-t border-zen-dark-4 py-3 px-2'>
      <div className='mt-4 font-bold text-zen-dark-9 text-sm'>Disclaimer:</div>
      <div className='mt-1 text-zen-dark-9 text-justify text-sm'>
        The projections on this website or app are for informational purposes
        only, are not based on any historical results or stock prices of Real or
        any information pertaining to Real that has not been generally
        disclosed. These projections are only intended to provide an example of
        potential compensation for a real estate agent affiliated with Real
        based on the specific assumptions and agent performance as set out on
        this website. Actual results may vary substantially, and Real makes no
        guarantees, representations, or warranties regarding the accuracy,
        completeness, or appropriateness of these projections, or an
        individual’s ability to achieve the projections results, including, for
        certainty, representations or undertakings about the future value or
        stock price of Real and any such representation or undertaking made with
        the intention of effecting a trade in a security of Real is an offence.
        Actual results are based on many factors, including, but not limited to,
        an individual’s ability to achieve the goals outlined in the
        assumptions, general economic conditions, the future stock price of
        Real, among others. Real reserves the right, at its sole discretion, to
        modify all or any part of this website without any liability or
        notification to any person. This website or app should be read in
        conjunction with Real’s public disclosure filings available on its
        issuer profiles at{' '}
        <a
          className='text-primary-blue'
          target='_blank'
          href={SEDAR_PLUS_URL}
          rel='noreferrer'
        >
          www.sedarplus.ca
        </a>{' '}
        and{' '}
        <a
          className='text-primary-blue'
          target='_blank'
          href={SEC_URL}
          rel='noreferrer'
        >
          www.sec.gov
        </a>{' '}
        from time to time, including Real’s most recently filed annual
        information form and the risk factors related to Real contained therein.
        Real has not authorized anyone to provide prospective real estate agents
        with additional or different information. This website or app does not
        constitute, nor should be construed as, investment advice, a
        solicitation to purchase securities or an offering memorandum with
        respect to the sale of securities. Any sale of a security as a result of
        the material contained herein can only be made in accordance with
        applicable securities laws. You acknowledge that any reliance on or use
        by you of the information contained on this website or app shall be at
        your own risk.
      </div>
      <div className='mt-1 text-zen-dark-9 text-justify text-sm'>
        <a
          className='text-primary-blue'
          target='_blank'
          href={PRIVACY_POLICY_URL}
          rel='noreferrer'
        >
          Privacy Policy
        </a>{' '}
        |{' '}
        <a
          className='text-primary-blue'
          target='_blank'
          href={TERMS_OF_USE_URL}
          rel='noreferrer'
        >
          Terms of Use
        </a>{' '}
        © {new Date().getFullYear()} The Real Brokerage. All Rights Reserved.
      </div>
    </div>
  );
};
