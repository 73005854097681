import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faHandshake } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactComponent as RealTitleIcon } from '../../../assets/icons/real-title-icon.svg';
import { PARTNER_REDIRECT_URL } from '../../../constants/MarketingCenterLinksConstants';
import useBrokerRedirect from '../../../hooks/useBrokerRedirect';
import { RootState } from '../../../types';
import { isCanadianUser } from '../../../utils/AgentHelper';
import { getInboxLink } from '../../../utils/UrlUtils';
import SwitchToGeminiToggle from '../../Gemini/SwitchToGeminiToggle';
import ZenSideBarItem from '../../ZenSideBarItem';
import ZenSideBarMenu from '../../ZenSideBarMenu';
import UserOfficesSidebarMenuItem from '../UserOfficesSidebarMenuItem';
import UserRealAcademySidebarMenuItem from '../UserRealAcademySidebarMenuItem';
import OneRealImpactSidebarMenuItem from './OneRealImpactSidebarMenuItem';
import RealMarketingSidebarMenuItem from './RealMarketingSidebarMenuItem';
import RealSignatureSidebarMenuItem from './RealSignatureSidebarMenuItem';
import ZenNewBadge from './ZenNewBadge';
import ZenUnreadCountPill from './ZenUnreadCountPill';

interface ZenBrokerMenuProps {
  isCollapsed: boolean;
}

const ZenBrokerMenu: React.FC<ZenBrokerMenuProps> = ({ isCollapsed }) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const location = useLocation();

  useBrokerRedirect();

  const isCanadian = isCanadianUser(userDetail?.accountCountry!);

  return (
    <div>
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={solid('house')} />}
        label='My Dashboard'
        linkTo='/dashboard'
        collapsed={isCollapsed}
      />
      <ZenSideBarItem
        collapsed={isCollapsed}
        icon={
          isCollapsed ? (
            <ZenUnreadCountPill isCollapsed={isCollapsed} />
          ) : (
            <FontAwesomeIcon icon={regular('inbox')} />
          )
        }
        label='Inbox'
        hideActive
        linkTo={getInboxLink(location.search)}
        target='_blank'
        BadgeComponent={<ZenUnreadCountPill />}
      />
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={regular('user-group')} />}
        label='People'
        linkTo='/people'
        collapsed={isCollapsed}
      />
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={regular('clipboard-list')} />}
        label='Transactions'
        linkTo='/transactions'
        collapsed={isCollapsed}
      />
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={regular('list')} />}
        label='Listings'
        linkTo='/listings'
        collapsed={isCollapsed}
      />
      <UserOfficesSidebarMenuItem
        offices={userDetail?.offices || []}
        isCollapsed={isCollapsed}
      />

      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={faHandshake} />}
        label='Partners'
        linkTo={PARTNER_REDIRECT_URL}
        collapsed={isCollapsed}
        target='_blank'
        BadgeComponent={!isCollapsed ? <ZenNewBadge /> : undefined}
        isExternal
      />

      <RealMarketingSidebarMenuItem isCollapsed={isCollapsed} />

      {!isCanadian && (
        <ZenSideBarItem
          icon={<RealTitleIcon />}
          label='One Real Title'
          linkTo='/real-title'
          collapsed={isCollapsed}
        />
      )}
      <OneRealImpactSidebarMenuItem isCollapsed={isCollapsed} />
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={regular('file-contract')} />}
        label='Reports'
        linkTo='/reports'
        collapsed={isCollapsed}
      />
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={regular('calendar')} />}
        label='Events'
        linkTo='/my-events'
        collapsed={isCollapsed}
      />
      <ZenSideBarMenu
        icon={<FontAwesomeIcon icon={regular('file-certificate')} />}
        collapsed={isCollapsed}
        label='Boards & MLS'
      >
        <ZenSideBarItem label='MLS' linkTo='/mls' isSubTab />
        <ZenSideBarItem label='Boards' linkTo='/boards' isSubTab />
      </ZenSideBarMenu>

      <UserRealAcademySidebarMenuItem isCollapsed={isCollapsed} />

      <RealSignatureSidebarMenuItem isCollapsed={isCollapsed} />
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={regular('calendar')} />}
        label='WealthPlan'
        linkTo='/wealthplan'
        collapsed={isCollapsed}
        target='_blank'
        BadgeComponent={!isCollapsed ? <ZenNewBadge /> : undefined}
      />
      <SwitchToGeminiToggle collapsed={isCollapsed} />
      {/* TODO: Enable once MLSs and Boards are properly wired up to offices. */}
      {/*<ZenSideBarItem*/}
      {/*  icon={<List />}*/}
      {/*  label='MLS'*/}
      {/*  linkTo='/mls'*/}
      {/*  collapsed={isCollapsed}*/}
      {/*/>*/}

      {/*<ZenSideBarItem*/}
      {/*  icon={<Gavel />}*/}
      {/*  label='Boards'*/}
      {/*  linkTo='/boards'*/}
      {/*  collapsed={isCollapsed}*/}
      {/*/>*/}
    </div>
  );
};
export default ZenBrokerMenu;
