import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import {
  toggleCreateTriggerModal,
  toggleEditTriggerModal,
} from '../../../slices/ChecklistDefinitionSlice';
import { cn } from '../../../utils/classUtils';

export interface CreateEditTriggerModalHeaderProps {
  currentStep: number;
  isEditMode?: boolean;
}

const CreateEditTriggerModalHeader: React.FC<CreateEditTriggerModalHeaderProps> = ({
  currentStep,
  isEditMode = false,
}) => {
  const dispatch = useDispatch();
  const isSecondStep = currentStep === 1;

  const handleClose = () => {
    if (isEditMode) {
      dispatch(toggleEditTriggerModal(false));
    } else {
      dispatch(toggleCreateTriggerModal(false));
    }
  };

  return (
    <div>
      <div className='relative px-6 py-5 flex flex-col items-center rounded-t-lg bg-zen-light-blue font-zen-body'>
        <div className='mb-5 text-base text-zen-dark-9 font-bold'>
          {isEditMode ? 'Edit Trigger' : 'Create Trigger'}
        </div>

        <div className='flex items-center'>
          <div
            className={cn(
              'w-8 h-8 shrink-0 flex justify-center items-center rounded-full overflow-hidden',
              isSecondStep ? 'bg-zen-dark-5' : 'bg-primary-blue',
            )}
          >
            <span className='text-base text-white font-bold'>
              {isSecondStep ? <FontAwesomeIcon icon={faCheck} /> : 1}
            </span>
          </div>

          <div className='h-1 w-5 bg-zen-dark-5' />

          <div
            className={cn(
              'w-8 h-8 shrink-0 flex justify-center items-center rounded-full overflow-hidden border-2 border-primary-blue',
              isSecondStep ? 'bg-primary-blue' : 'bg-white',
            )}
          >
            <span
              className={cn(
                'text-base font-bold',
                isSecondStep ? 'text-white' : 'text-primary-blue',
              )}
            >
              2
            </span>
          </div>
        </div>

        <div
          onClick={handleClose}
          className='flex absolute right-4.5 top-4.5 bg-zen-dark-5 cursor-pointer items-center justify-center text-white h-5 w-5 rounded-full'
        >
          <FontAwesomeIcon icon={faXmark} />
        </div>
      </div>
    </div>
  );
};

export default CreateEditTriggerModalHeader;
