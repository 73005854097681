import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock } from '@fortawesome/pro-solid-svg-icons';
import BlockIcon from '../../assets/icons/blocked.png';
import { ItemResponseStatusEnum } from '../../openapi/sherlock';
import { cn } from '../../utils/classUtils';

interface JourneyStatusIconProps {
  status: ItemResponseStatusEnum;
}

const getJourneyIcon = (status: ItemResponseStatusEnum) => {
  if (status === ItemResponseStatusEnum.Accepted) {
    return (
      <FontAwesomeIcon
        icon={faCheckCircle}
        className='w-6 h-6 text text-success'
      />
    );
  }

  if (status === ItemResponseStatusEnum.Pending) {
    return <FontAwesomeIcon icon={faClock} className='w-6 h-6 text-error' />;
  }

  if (
    status === ItemResponseStatusEnum.WaitingForReview ||
    status === ItemResponseStatusEnum.InReview
  ) {
    return <img src={BlockIcon} alt='blocked' className='h-6 w-6' />;
  }

  return null;
};

const JourneyStatusIcon: React.FC<JourneyStatusIconProps> = ({ status }) => {
  return (
    <div
      className={cn(
        'absolute z-10 flex items-center justify-center bg-gray-200 rounded-full',
        status === ItemResponseStatusEnum.NotStarted
          ? 'w-4 h-4 ml-2'
          : 'w-8 h-8',
      )}
    >
      {getJourneyIcon(status)}
    </div>
  );
};

export default JourneyStatusIcon;
