import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faTrashCan,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import EditTransactionParticipantFormSidebarModal from '../../../forms/EditTransactionParticipantFormSidebarModal';
import {
  ParticipantValue,
  ParticipantValueRoleEnum,
  PaymentParticipantValue,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { removeCommissionParticipant } from '../../../slices/TransactionSlice';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import Avatar from '../../Avatar';
import Button from '../../Button';
import ConfirmationModal from '../../ConfirmationModal';
import AddParticipantsForm from '../AddParticipantsForm';
import { getAllParticipants } from '../../../utils/AgentHelper';

interface TransactionParticipantSelectionRowProps {
  transaction: TransactionResponse;
  defaultRole: ParticipantValueRoleEnum;
}

const TransactionParticipantSelectionRow: React.FC<TransactionParticipantSelectionRowProps> = ({
  transaction,
  defaultRole,
}) => {
  const dispatch = useDispatch();
  const allParticipants = getAllParticipants(transaction);
  const allReferrerParticipants = allParticipants.filter(
    (participant) =>
      participant.role === ParticipantValueRoleEnum.ReferringAgent,
  );
  const [isAddingParticipant, setIsAddingParticipant] = useState<boolean>(
    false,
  );
  const [editingParticipant, setEditingParticipant] = useState<
    PaymentParticipantValue | ParticipantValue | undefined
  >(undefined);
  const [deletingParticipant, setDeletingParticipant] = useState<
    PaymentParticipantValue | ParticipantValue | undefined
  >(undefined);

  return (
    <>
      <p className='text-base font-primary-medium mt-4 mb-2'>Referrers</p>
      <div className='flex flex-col'>
        <button
          onClick={() => setIsAddingParticipant(true)}
          className='focus:outline-none appearance-none w-full flex border rounded border-gray-300 p-2.5 items-center text-dark space-x-4'
        >
          <FontAwesomeIcon icon={faUser} />
          <span className='flex-grow text-left'>Add Participant</span>
          <FontAwesomeIcon icon={faChevronRight} fontSize={14} />
        </button>
        {isAddingParticipant && (
          <AddParticipantsForm
            isOpen
            transaction={transaction}
            onClose={() => setIsAddingParticipant(false)}
            role={defaultRole}
          />
        )}
      </div>
      <div>
        <div className='space-y-3 mt-3'>
          {allReferrerParticipants.map((participant) => {
            const participantName = getParticipantName(participant);

            return (
              <div
                key={participant.id}
                className=' w-full flex border rounded border-gray-300 p-2.5 items-center text-dark space-x-4'
              >
                <button
                  onClick={() => setEditingParticipant(participant)}
                  className='appearance-none focus:outline-none flex-grow text-left flex items-center'
                >
                  <Avatar name={participantName} size='xxs' />
                  <span className='mx-4 flex-grow'>{participantName}</span>
                  <FontAwesomeIcon icon={faPen} />
                </button>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className='cursor-pointer'
                  onClick={() => setDeletingParticipant(participant)}
                />
              </div>
            );
          })}
        </div>
        {editingParticipant && (
          <EditTransactionParticipantFormSidebarModal
            isOpen
            onClose={() => setEditingParticipant(undefined)}
            participant={editingParticipant}
            transaction={transaction}
          />
        )}
        {deletingParticipant && (
          <ConfirmationModal
            isOpen
            onClose={() => setDeletingParticipant(undefined)}
            title='Delete'
            variant='error'
          >
            <p className='text-base'>
              Are you sure you want to delete{' '}
              <span className='font-primary-medium'>
                {getParticipantName(deletingParticipant)}
              </span>
              ?
            </p>
            <div className='mt-3 space-x-3'>
              <Button
                label='Delete'
                type='primary'
                onClick={() => {
                  dispatch(
                    removeCommissionParticipant(
                      transaction.id!,
                      deletingParticipant.id!,
                    ),
                  );
                  setDeletingParticipant(undefined);
                }}
              />
              <Button
                label='Cancel'
                type='secondary'
                onClick={() => setDeletingParticipant(undefined)}
              />
            </div>
          </ConfirmationModal>
        )}
      </div>
    </>
  );
};

export default TransactionParticipantSelectionRow;
