import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { chunk, flatten } from 'lodash';
import { AppThunk, ErrorCode, UserIdsState } from '../types';
import {
  AgentCommentDetails,
  AgentControllerApi,
  AgentInfo,
  AgentPublicInfo,
  AgentPublicInfosResponse,
  UserControllerApi,
  UserPublicInfosResponse,
} from '../openapi/yenta';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: UserIdsState = {
  loading: false,
  usersById: {},
  fetchUsersByIdErrorCode: null,
  agentById: {},
  agentByKeymakerId: {},
};

const UserIdsSlice = createSlice({
  name: 'userIds',
  initialState,
  reducers: {
    changeLoadingIds(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    errorFetchingUsersById(state, action: PayloadAction<ErrorCode>) {
      state.fetchUsersByIdErrorCode = action.payload;
    },
    saveUsersById(state, action: PayloadAction<Array<AgentPublicInfo>>) {
      action.payload.forEach((data) => {
        state.usersById[data.id!] = data;
      });
      state.fetchUsersByIdErrorCode = null;
    },
    saveAgentById(state, action: PayloadAction<Array<AgentInfo>>) {
      action.payload.forEach((agentInfo) => {
        state.agentById[agentInfo.id!] = agentInfo;
      });
    },
    saveAgentByKeymakerId(
      state,
      action: PayloadAction<Array<AgentCommentDetails>>,
    ) {
      action.payload.forEach((agentInfo) => {
        state.agentByKeymakerId[agentInfo.keymakerId!] = agentInfo;
      });
    },
  },
});

export const {
  changeLoadingIds,
  errorFetchingUsersById,
  saveUsersById,
  saveAgentById,
  saveAgentByKeymakerId,
} = UserIdsSlice.actions;

export const fetchUserByIds = (ids: string[]): AppThunk => async (dispatch) => {
  dispatch(changeLoadingIds(true));
  try {
    const allAgentPublicInfosRes = chunk(ids, 100).map(async (agentIds) => {
      const { data } = await new AgentControllerApi(
        getYentaConfiguration(),
      ).getPublicAgentInfos({ agentIds });

      return data?.agentInfos || [];
    });

    const allIds = await Promise.all(allAgentPublicInfosRes);

    dispatch(saveUsersById(flatten(allIds)));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch agents by ids', e, {
      users: { ids },
    });
    dispatch(errorFetchingUsersById(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingIds(false));
  }
};

export const fetchAgentsInfo = (
  agentIds: string[],
  getPublicUserInfo?: boolean,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const fetchApi = async (ids: string[]) =>
      getPublicUserInfo
        ? await new UserControllerApi(
            getYentaConfiguration(),
          ).getPublicUserInfos({ ids })
        : await new AgentControllerApi(
            getYentaConfiguration(),
          ).getPublicAgentInfos({ agentIds: ids });

    const allAgentPublicInfosRes = chunk(agentIds, 20).map(async (ids) => {
      const { data } = await fetchApi(ids);
      const response = getPublicUserInfo
        ? (data as UserPublicInfosResponse)?.userInfos
        : (data as AgentPublicInfosResponse)?.agentInfos;

      return response || [];
    });

    const allIds = await Promise.all(allAgentPublicInfosRes);

    dispatch(saveAgentById(flatten(allIds)));
  } catch (e) {
    ErrorService.notifyIgnoreAuthErrors('Unable to fetch agent info', e, {
      agent: { agentIds },
    });
  }
};

export const fetchAgentsInfoKeymakerId = (
  agentIds: string[],
  getPublicUserInfo?: boolean,
): AppThunk => async (dispatch) => {
  try {
    const fetchApi = async (ids: string[]) =>
      getPublicUserInfo
        ? await new UserControllerApi(
            getYentaConfiguration(),
          ).getUserDetailsForComment({ ids })
        : await new AgentControllerApi(
            getYentaConfiguration(),
          ).getAgentCommentDetails(ids);

    const userResponse = chunk(agentIds, 20).map(async (ids) => {
      const { data } = await fetchApi(ids);
      return data || [];
    });

    const res = await Promise.all(userResponse);
    dispatch(saveAgentByKeymakerId(flatten(res) || []));
  } catch (e) {
    ErrorService.notify('Unable to fetch agent keymaker id info', e, {
      agent: { agentIds },
    });
  }
};

export default UserIdsSlice.reducer;
