import { isEmpty } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { BrokerControllerApi, ChecklistFollowup } from '../../openapi/arrakis';
import { AdministrativeAreaResponseStateOrProvinceEnum } from '../../openapi/yenta';
import { convertMillisToHuman } from '../../utils/DateUtils';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum, formatAddress } from '../../utils/StringUtils';
import { IFilter } from '../../utils/TableUtils';
import StateOrProvinceIconCell from '../table/Cells/StateOrProvinceIconCell';
import ZenPowerAuditActionButtonCell from '../table/Cells/ZenPowerAuditActionButtonCell';
import ZenViewActionButtonCell from '../table/Cells/ZenViewActionButtonCell';
import OfficeAsyncMultiSelectColumnFilter from '../table/Filters/OfficeAsyncMultiSelectColumnFilter';
import ZenResourceIndexContainer from '../Zen/Containers/ZenResourceIndexContainer';
import ZenUserPill from '../Zen/ZenUserPill';

interface ChecklistOverTimeResourceTableProps {}

interface ModifiedCheckListOverTimeResponse extends ChecklistFollowup {
  alerts?: Array<string>;
}

export const columns: Array<Column<ModifiedCheckListOverTimeResponse>> = [
  {
    Header: 'Action',
    accessor: 'transactionId',
    Cell: ({ value }) => (
      <Link to={`/transactions/${value}/checklist`}>
        <ZenViewActionButtonCell />
      </Link>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Power Audit Link',
    id: 'power-audit-link',
    Cell: ({
      row: { original },
    }: CellProps<ModifiedCheckListOverTimeResponse>) => (
      <Link to={`/transactions/${original?.transactionId}/power-audit`}>
        <ZenPowerAuditActionButtonCell />
      </Link>
    ),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Alerts',
    accessor: 'alerts',
    Cell: ({ value }) => (
      <p className='md:w-max'>{!!value?.length ? value?.join(', ') : 'N/A'}</p>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'State / Province',
    accessor: 'stateOrProvince',
    Cell: ({ value }) => {
      if (value) {
        return (
          <StateOrProvinceIconCell
            state={
              (value as unknown) as AdministrativeAreaResponseStateOrProvinceEnum
            }
          />
        );
      }
      return 'N/A';
    },
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Office Name',
    accessor: 'officeName',
    Cell: ({ value }) => <p>{value ? value : 'N/A'}</p>,
    Filter: OfficeAsyncMultiSelectColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Address',
    accessor: (d) => d['address']?.street,
    Cell: ({
      row: {
        original: { address },
      },
    }: CellProps<ModifiedCheckListOverTimeResponse>) => (
      <div className='w-56'>
        {!isEmpty(address)
          ? formatAddress(
              address?.street!,
              address?.street2!,
              address?.city!,
              capitalizeEnum(address?.state!),
              address?.zip!,
            )
          : 'N/A'}
      </div>
    ),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Checklist Name',
    accessor: 'checklistName',
    Cell: ({ value }) => <p>{value ? value : 'N/A'}</p>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Pending Times',
    accessor: 'oldestPendingTime',
    Cell: ({ value }) => <p>{value ? convertMillisToHuman(value) : 'N/A'}</p>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Pending Item',
    accessor: 'oldestPendingItem',
    Cell: ({ value }) => <p>{value ? value : 'N/A'}</p>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Pending Label',
    accessor: 'oldestPendingLabel',
    Cell: ({ value }) => <p>{value ? value : 'N/A'}</p>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Pending Number',
    accessor: 'numPending',
    Cell: ({ value }) => <p>{value ? value : 'N/A'}</p>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Urgent Pending Number',
    accessor: 'numPendingUrgent',
    Cell: ({ value }) => <p>{value ? value : 'N/A'}</p>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Agent Name',
    accessor: 'ownerName',
    Cell: ({
      value,
      row: {
        original: { ownerAgentId },
      },
    }) =>
      ownerAgentId ? (
        <Link to={`/people/${ownerAgentId}`}>
          <div className='w-max'>
            <ZenUserPill name={`${value}`} backgroundVariant='background' />
          </div>
        </Link>
      ) : (
        <div className='w-max'>
          <ZenUserPill name={`${value}`} backgroundVariant='background' />
        </div>
      ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Transaction Count',
    accessor: 'transactionCount',
    Cell: ({ value }) => <p>{value ? value : 'N/A'}</p>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Team Name',
    accessor: 'teamName',
    Cell: ({ value }) => <p>{value ? capitalizeEnum(value) : 'N/A'}</p>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Non-Broker Label',
    accessor: 'numNonBrokerLabel',
    Cell: ({ value }) => <p>{value ? value : 'N/A'}</p>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Total Label',
    accessor: 'numTotalLabel',
    Cell: ({ value }) => <p>{value ? value : 'N/A'}</p>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Deposit Label',
    accessor: 'numDepositLabel',
    Cell: ({ value }) => <p>{value ? value : 'N/A'}</p>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Termination Label',
    accessor: 'numTerminationLabel',
    Cell: ({ value }) => <p>{value ? value : 'N/A'}</p>,
    disableFilters: true,
    disableSortBy: true,
  },
];

const ChecklistOverTimeResourceTable: React.FC<ChecklistOverTimeResourceTableProps> = () => {
  return (
    <div>
      <h1 className='font-zen-body text-xl text-zen-dark-9 block lg:hidden mb-4'>
        OverTime Checklists
      </h1>
      <ZenResourceIndexContainer<ModifiedCheckListOverTimeResponse>
        columns={columns}
        header='OverTime Checklists'
        resourceName='OverTime Checklist'
        pageSize={10}
        fetchData={async (req) => {
          const offices = req.filter?.officeName as IFilter;
          const { data } = await new BrokerControllerApi(
            getArrakisConfiguration(),
          ).getChecklistOvertimeAlerts(
            req.page,
            req.pageSize,
            offices?.values || [],
          );

          const finalData = data?.checklistOvertimeAlerts?.map((d) => ({
            ...d?.checklistFollowup,
            alerts: d?.checklistAlerts,
          }));

          return {
            data: finalData || [],
            total: data?.totalCount || 0,
          };
        }}
        standalone={false}
        hideFilters
      />
    </div>
  );
};

export default ChecklistOverTimeResourceTable;
