import React, { useMemo, useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { ItemResponse } from '../../../../openapi/sherlock';
import IconButton from '../../../IconButton';
import { ReactComponent as ZenEye } from '../../../../assets/img/eye.svg';
import { getChecklistsWithFileReferences } from '../../../../utils/ChecklistUtils';

interface AttachedChecklistsProps {
  checklists: ItemResponse[];
  fileId: string;
  isTrashFolder: boolean;
}

const AttachedChecklists: React.FC<AttachedChecklistsProps> = ({
  checklists,
  fileId,
  isTrashFolder,
}) => {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const attachedChecklists = useMemo(() => {
    let attachedChecklistArray: ItemResponse[] = getChecklistsWithFileReferences(
      checklists,
      fileId!,
    );

    return attachedChecklistArray;
  }, [checklists, fileId]);

  if (!attachedChecklists.length) return null;

  return attachedChecklists.length === 1 ? (
    <button
      className={classNames({
        'cursor-not-allowed': isTrashFolder,
      })}
      disabled={isTrashFolder}
      onClick={() => {
        if (!isTrashFolder) {
          history.push(`checklist?checklistItemId=${attachedChecklists[0].id}`);
        }
      }}
    >
      <p className='text-primary-blue max-w-[160px] line-clamp-1 text-left overflow-hidden'>
        {attachedChecklists[0].name}
      </p>
    </button>
  ) : (
    <Popover
      isOpen={isOpen}
      positions={['top']}
      onClickOutside={() => setIsOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor='white'
          arrowSize={10}
          arrowStyle={{
            bottom: 1,
            zIndex: 1,
          }}
          className='popover-arrow-container'
          arrowClassName='popover-arrow'
        >
          <div className='border rounded-[30px] border-zen-dark-5 bg-white text-dark shadow-2xl p-6 divide-y-2'>
            <div className='flex justify-between items-center gap-20'>
              <h3 className='font-bold text-lg'>
                {attachedChecklists.length} Checklist Items
              </h3>
              <FontAwesomeIcon
                role='button'
                aria-label='close'
                data-testid='close-checklist-popover'
                icon={faClose}
                onClick={() => setIsOpen(false)}
              />
            </div>
            <div className='mt-3 pt-3 flex flex-col gap-3'>
              {attachedChecklists?.map((checklist, index) => (
                <div
                  key={checklist.id}
                  className='flex items-center justify-between'
                >
                  <p className='max-w-[180px] line-clamp-1'>
                    {index + 1}. {checklist.name}
                  </p>
                  <IconButton
                    buttonStyle='border border-primary-blue bg-primary-blue rounded-lg px-1'
                    variant='none'
                    leftIcon={
                      <ZenEye
                        className='p-0.5 h-5 w-5 text-white'
                        aria-label='view'
                      />
                    }
                    disabled={isTrashFolder}
                    onClick={() => {
                      if (!isTrashFolder) {
                        history.push(
                          `checklist?checklistItemId=${checklist.id}`,
                        );
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </ArrowContainer>
      )}
    >
      <button onClick={() => setIsOpen(true)} className='text-primary-blue'>
        Multiple Documents
      </button>
    </Popover>
  );
};

export default AttachedChecklists;
