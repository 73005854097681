import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, { MouseEvent } from 'react';
import GeminiButton from '../GeminiButton';

interface GeminiPillTabEmptyStateProps {
  message: string;
  icon?: React.ReactElement;
  buttonText?: string;
  buttonLeftIcon?: IconProp;
  onButtonClick?:
    | ((e: MouseEvent<HTMLElement>) => void)
    | ((e: MouseEvent<HTMLButtonElement>) => void)
    | (() => void);
}

const GeminiPillTabEmptyState: React.FC<GeminiPillTabEmptyStateProps> = ({
  message,
  icon,
  buttonText,
  buttonLeftIcon,
  onButtonClick,
}) => {
  return (
    <div className='w-full h-full p-20 flex flex-col items-center justify-center space-y-2'>
      {icon}
      <p className='font-inter font-light text-primary-dark text-lg'>
        {message}
      </p>
      <GeminiButton
        label={buttonText}
        leftIcon={buttonLeftIcon}
        onClick={onButtonClick}
      />
    </div>
  );
};

export default GeminiPillTabEmptyState;
