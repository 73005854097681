import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateInviteRequest,
  CreateJointVentureRequest,
  InviteControllerApi,
  InviteResponse,
  JointVentureControllerApi,
  JointVentureResponse,
  MembershipControllerApi,
} from '../openapi/avalon';
import ErrorService from '../services/ErrorService';
import {
  AppDispatch,
  AppThunk,
  AsyncResponse,
  JointVentureState,
} from '../types';
import { getAvalonConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from './ErrorSlice';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { performAsyncRequest } from './utils/SliceUtil';

export const initialState: JointVentureState = {
  loading: true,
  jointVenturesResponse: { name: 'jointVentures', loading: false },
  showInviteResponse: { name: 'showInvite', loading: false },
  invitedJointVentures: { name: 'invitedJointVentures', loading: false },
  modalToggle: true,
};

const JointVentureSlice = createSlice({
  name: 'jointVenture',
  initialState,
  reducers: {
    changeLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    saveJointVenturesResponse(
      state,
      action: PayloadAction<AsyncResponse<JointVentureResponse[]>>,
    ) {
      state.jointVenturesResponse = action.payload;
    },
    saveShowInviteResponse(
      state,
      action: PayloadAction<AsyncResponse<InviteResponse>>,
    ) {
      state.showInviteResponse = action.payload;
    },
    saveAllInvitedResponse(
      state,
      action: PayloadAction<AsyncResponse<InviteResponse[]>>,
    ) {
      state.invitedJointVentures = action.payload;
    },
    toggleJVInvitationModal(state, action: PayloadAction<boolean>) {
      state.modalToggle = action.payload;
    },
  },
});

export const {
  changeLoading,
  saveJointVenturesResponse,
  saveShowInviteResponse,
  toggleJVInvitationModal,
  saveAllInvitedResponse,
} = JointVentureSlice.actions;

export const fetchJointVentures = (): AppThunk => async (dispatch) => {
  const fetch = () =>
    new JointVentureControllerApi(getAvalonConfiguration()).getJointVentures();
  performAsyncRequest(
    dispatch as AppDispatch,
    'jointVentures',
    saveJointVenturesResponse,
    fetch,
    {
      skipAuthDatadog: true,
    },
  );
};

export const fetchJVInvite = (): AppThunk => async (dispatch) => {
  const fetch = () =>
    new InviteControllerApi(getAvalonConfiguration()).getNextInviteForAgent();
  performAsyncRequest(
    dispatch as AppDispatch,
    'jointVentures',
    saveShowInviteResponse,
    fetch,
    {
      skipAuthDatadog: true,
    },
  );
};

export const fetchAllJVInvitations = (): AppThunk => async (dispatch) => {
  const fetch = () =>
    new InviteControllerApi(getAvalonConfiguration()).getAllInvites();
  performAsyncRequest(
    dispatch as AppDispatch,
    'invitedJointVentures',
    saveAllInvitedResponse,
    fetch,
    {
      skipAuthDatadog: true,
      ignoreStatusCodesDatadog: [404],
    },
  );
};

export const fetchAllAgentJVInvitations = (agentId: string): AppThunk => async (
  dispatch,
) => {
  const fetch = () =>
    new InviteControllerApi(getAvalonConfiguration()).getAllAgentInvites(
      agentId,
    );
  performAsyncRequest(
    dispatch as AppDispatch,
    'invitedJointVentures',
    saveAllInvitedResponse,
    fetch,
    {
      skipAuthDatadog: true,
      ignoreStatusCodesDatadog: [404],
    },
  );
};

export const createJointVenture = (
  req: CreateJointVentureRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new JointVentureControllerApi(
      getAvalonConfiguration(),
    ).createJointVenture(req);
    if (data) {
      dispatch(showSuccessToast('The Joint Venture was created successfully'));
      return true;
    }
    return false;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreStatusCodes(
      [401, 403, 404],
      'Failed to create joint venture',
      e,
      {
        req,
      },
    );
    dispatch(
      showErrorToast(
        'We had a problem creating the joint venture',
        'Please try again in a few moments.',
      ),
    );
    return false;
  } finally {
    dispatch(changeLoading(false));
  }
};

export const declineJointVentureInvitation = (
  inviteId: string,
): AppThunk => async (dispatch) => {
  try {
    await new InviteControllerApi(getAvalonConfiguration()).declineInvite(
      inviteId,
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to decline joint venture invitation', e, {
      req: { inviteId },
    });
    dispatch(
      showErrorToast(
        'We had a problem updating your joint venture invitation',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const withdrawJointVentureInvitation = (
  inviteId: string,
): AppThunk => async (dispatch) => {
  try {
    await new InviteControllerApi(getAvalonConfiguration()).withdrawInvite(
      inviteId,
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to mark agent document as signed', e, {
      req: { inviteId },
    });
    dispatch(
      showErrorToast(
        'We had a problem withdrawing the joint venture invitation',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const markAgentDocumentsAsSigned = (
  membershipId: string,
): AppThunk => async (dispatch) => {
  try {
    await new MembershipControllerApi(
      getAvalonConfiguration(),
    ).markDocumentsAsSigned(membershipId);
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to mark agent document as signed', e, {
      req: { membershipId },
    });
    dispatch(
      showErrorToast(
        'We had a problem updating the status of the invitation.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const inviteAgentToJoinVenture = (
  req: CreateInviteRequest,
  agentId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new InviteControllerApi(
      getAvalonConfiguration(),
    ).sendInvite(req);

    if (data) {
      dispatch(showSuccessToast(`Invitation to join sent successfully.`));
      await dispatch(fetchAllAgentJVInvitations(agentId));
      return true;
    }
    return false;
  } catch (e) {
    ErrorService.notifyIgnoreStatusCodes(
      [401, 403, 404],
      'Failed to invite agent to joint venture',
      e,
      {
        req,
      },
    );
    dispatch(
      showErrorToast(
        'We had a problem inviting the agent to the joint venture',
        'Please try again in a few moments.',
      ),
    );
    return false;
  }
};

export default JointVentureSlice.reducer;
