import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  PriceAndDateInfoRequest,
  PriceAndDateInfoRequestDealTypeEnum,
} from '../../../../openapi/arrakis';
import { savePriceDateInfos } from '../../../../slices/TransactionBuilderSlice';
import { AppDispatch, YesNoType } from '../../../../types';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledDatePickerInput from '../../Input/ZenControlledDatePickerInput';
import ZenControlledRadioInput from '../../Input/ZenControlledRadioInput';
import ZenButton from '../../ZenButton';
import { getConditionalDateErrors } from '../../../../utils/TransactionUtils';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
  Match,
} from './ZenCreateTransactionSteps';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';

const ZenTransactionFirmDateStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({
  form: { control, watch, trigger, setValue, setError },
  onPrevious,
  onNext,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { transactionBuilderId } = useParams<Match>();
  const [loading, setLoading] = useState<boolean>(false);

  const [
    conditionalDates,
    closingDate,
    acceptanceDate,
    transactionType,
    price,
    commission,
    listingCommission,
    isSalesCommissionDollar,
    isListingCommissionDollar,
    transactionOwnerRepresent,
    financingConditionsExpirationDate,
    propertyInspectionExpirationDate,
    saleOfBuyersPropertyExpirationDate,
    condoDocumentsExpirationDate,
    otherConditionsExpirationDate,
  ] = watch([
    'conditionalDates',
    'closingDate',
    'acceptanceDate',
    'transactionType',
    'price',
    'commission',
    'listingCommission',
    'commission.isDollar',
    'listingCommission.isDollar',
    'transactionOwnerRepresent',
    'financingConditionsExpirationDate',
    'propertyInspectionExpirationDate',
    'saleOfBuyersPropertyExpirationDate',
    'condoDocumentsExpirationDate',
    'otherConditionsExpirationDate',
    'address',
    'location',
  ]);

  const setConditionalDateErrors = (e: any) => {
    const apiError = getConditionalDateErrors(e);
    if (!!apiError?.length) {
      apiError?.forEach((error) => {
        setError(error?.field as keyof CreateTransactionFormState, {
          message: error?.errorMessage,
        });
      });

      return true;
    }

    return false;
  };

  const handleFirmDate = async () => {
    const isValid = await trigger();
    if (isValid) {
      const isConditionalDate = conditionalDates === YesNoType.YES;
      const priceDateRequest: PriceAndDateInfoRequest = {
        dealType: (transactionType as unknown) as PriceAndDateInfoRequestDealTypeEnum,
        closingDate: (closingDate as unknown) as string,
        acceptanceDate: (acceptanceDate as unknown) as string,
        listingCommission: {
          commissionAmount: isListingCommissionDollar
            ? {
                amount: +listingCommission.money?.amount! || 0,
                currency: price.currency,
              }
            : undefined,
          commissionPercent: !isListingCommissionDollar
            ? listingCommission.percent?.value!
            : undefined,
          percentEnabled: !listingCommission.isDollar,
        },
        saleCommission: {
          commissionAmount: isSalesCommissionDollar
            ? {
                amount: +commission.money?.amount! || 0,
                currency: price.currency,
              }
            : undefined,
          commissionPercent: !isSalesCommissionDollar
            ? commission.percent?.value!
            : undefined,
          percentEnabled: !commission.isDollar,
        },
        salePrice: {
          amount: +price?.amount! || 0,
          currency: price?.currency,
        },
        representationType: transactionOwnerRepresent,
        financingConditionsExpirationDate: isConditionalDate
          ? ((financingConditionsExpirationDate as unknown) as string)
          : undefined,
        propertyInspectionExpirationDate: isConditionalDate
          ? ((propertyInspectionExpirationDate as unknown) as string)
          : undefined,
        saleOfBuyersPropertyExpirationDate: isConditionalDate
          ? ((saleOfBuyersPropertyExpirationDate as unknown) as string)
          : undefined,
        condoDocumentsExpirationDate: isConditionalDate
          ? ((condoDocumentsExpirationDate as unknown) as string)
          : undefined,
        otherConditionsExpirationDate: isConditionalDate
          ? ((otherConditionsExpirationDate as unknown) as string)
          : undefined,
      };

      setLoading(true);
      const res = await dispatch(
        savePriceDateInfos(
          transactionBuilderId!,
          priceDateRequest,
          setConditionalDateErrors,
        ),
      );
      setLoading(false);
      if (res) {
        onNext();
      }
    }
  };

  useEffect(() => {
    if (conditionalDates === YesNoType.NO) {
      setValue('firmDate', acceptanceDate);
    }
  }, [acceptanceDate, conditionalDates, setValue]);

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div>
          <ZenControlledRadioInput<
            CreateTransactionFormState,
            'conditionalDates'
          >
            name='conditionalDates'
            control={control}
            label='Is this transaction conditional?'
            shouldUnregister={false}
            options={[
              {
                label: 'Yes',
                value: YesNoType.YES,
              },
              {
                label: 'No',
                value: YesNoType.NO,
              },
            ]}
            rules={{
              required: 'Please choose one',
            }}
            inlineOptions
            isRequired
          />
        </div>

        {conditionalDates === YesNoType.YES && (
          <div className='my-5'>
            <div className='my-3'>
              <p className='font-zen-body font-bold text-xl text-zen-dark-9'>
                Condition Dates{' '}
                <span className='font-zen-body font-normal text-base'>
                  (Must select at least one)
                </span>
              </p>
            </div>
            <div className='flex flex-row space-x-4'>
              <ZenControlledDatePickerInput<
                CreateTransactionFormState,
                'financingConditionsExpirationDate'
              >
                control={control}
                label='Financing Conditions'
                name='financingConditionsExpirationDate'
                shouldUnregister={false}
                placeholder='MM/DD/YYYY'
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    title='calendar'
                    className='text-primary-blue mb-0.5 text-xl'
                  />
                }
                rules={{
                  validate: (value?: Date) => {
                    if (
                      !value &&
                      !propertyInspectionExpirationDate &&
                      !saleOfBuyersPropertyExpirationDate &&
                      !condoDocumentsExpirationDate &&
                      !otherConditionsExpirationDate
                    ) {
                      return 'At least one date is required';
                    }

                    return undefined;
                  },
                }}
              />
              <ZenControlledDatePickerInput<
                CreateTransactionFormState,
                'propertyInspectionExpirationDate'
              >
                control={control}
                label='Property Inspection'
                name='propertyInspectionExpirationDate'
                shouldUnregister={false}
                placeholder='MM/DD/YYYY'
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    title='calendar'
                    className='text-primary-blue mb-0.5 text-xl'
                  />
                }
                rules={{
                  validate: (value?: Date) => {
                    if (
                      !value &&
                      !financingConditionsExpirationDate &&
                      !saleOfBuyersPropertyExpirationDate &&
                      !condoDocumentsExpirationDate &&
                      !otherConditionsExpirationDate
                    ) {
                      return 'At least one date is required';
                    }

                    return undefined;
                  },
                }}
              />
            </div>
            <div className='flex flex-row space-x-4 mt-5'>
              <ZenControlledDatePickerInput<
                CreateTransactionFormState,
                'saleOfBuyersPropertyExpirationDate'
              >
                control={control}
                label="Sale of Buyer's Property"
                name='saleOfBuyersPropertyExpirationDate'
                shouldUnregister={false}
                placeholder='MM/DD/YYYY'
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    title='calendar'
                    className='text-primary-blue mb-0.5 text-xl'
                  />
                }
                rules={{
                  validate: (value?: Date) => {
                    if (
                      !value &&
                      !financingConditionsExpirationDate &&
                      !propertyInspectionExpirationDate &&
                      !condoDocumentsExpirationDate &&
                      !otherConditionsExpirationDate
                    ) {
                      return 'At least one date is required';
                    }

                    return undefined;
                  },
                }}
              />
              <ZenControlledDatePickerInput<
                CreateTransactionFormState,
                'condoDocumentsExpirationDate'
              >
                control={control}
                label='Condo Documents'
                name='condoDocumentsExpirationDate'
                shouldUnregister={false}
                placeholder='MM/DD/YYYY'
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    title='calendar'
                    className='text-primary-blue mb-0.5 text-xl'
                  />
                }
                rules={{
                  validate: (value?: Date) => {
                    if (
                      !value &&
                      !financingConditionsExpirationDate &&
                      !propertyInspectionExpirationDate &&
                      !saleOfBuyersPropertyExpirationDate &&
                      !otherConditionsExpirationDate
                    ) {
                      return 'At least one date is required';
                    }

                    return undefined;
                  },
                }}
              />
            </div>
            <div className='flex flex-row space-x-4 mt-5 w-1/2'>
              <ZenControlledDatePickerInput<
                CreateTransactionFormState,
                'otherConditionsExpirationDate'
              >
                control={control}
                label='Other Conditions'
                name='otherConditionsExpirationDate'
                shouldUnregister={false}
                placeholder='MM/DD/YYYY'
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    title='calendar'
                    className='text-primary-blue mb-0.5 text-xl'
                  />
                }
                rules={{
                  validate: (value?: Date) => {
                    if (
                      !value &&
                      !financingConditionsExpirationDate &&
                      !propertyInspectionExpirationDate &&
                      !saleOfBuyersPropertyExpirationDate &&
                      !condoDocumentsExpirationDate
                    ) {
                      return 'At least one date is required';
                    }

                    return undefined;
                  },
                }}
              />
            </div>
          </div>
        )}

        {conditionalDates === YesNoType.NO && (
          <div className='my-5'>
            <p className='font-zen-body font-semibold text-base text-zen-dark-9'>
              This transaction does not have any conditions and the Firm Date
              will be set as the date of contract acceptance.
            </p>
            <div className='mt-5'>
              <ZenControlledDatePickerInput<
                CreateTransactionFormState,
                'firmDate'
              >
                control={control}
                label='Firm Date'
                name='firmDate'
                shouldUnregister={false}
                placeholder='MM/DD/YYYY'
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    title='calendar'
                    className='text-primary-blue mb-0.5 text-xl'
                  />
                }
                readOnly
              />
            </div>
          </div>
        )}
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              label='Next'
              onClick={handleFirmDate}
              isSubmitting={loading}
              isDisabled={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withCreateTransactionProgress(ZenTransactionFirmDateStep);
