import classNames from 'classnames';
import React from 'react';
import { TeamPreviewResponse, TeamResponse } from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import CopyToClipboardHover from '../../CopyToClipboardHover';
import { ReactComponent as ZenCopyIcon } from '../../../assets/icons/zen/zen-copy.svg';

type cellVariant = 'primary' | 'secondary';

interface ZenTeamsCodeCellProps {
  team: TeamResponse | TeamPreviewResponse;
  variant?: cellVariant;
}

const ZenTeamsCodeCell: React.FC<ZenTeamsCodeCellProps> = ({
  team,
  variant = 'primary',
}) => {
  const variantClassNameMap: EnumMap<cellVariant, string> = {
    primary: 'border border-zen-dark-4 px-2 text-zen-dark-9',
    secondary: 'bg-grey-600 py-1.5 px-4 text-white',
  };

  const variantToCopyIconMap: EnumMap<cellVariant, React.ReactElement> = {
    primary: <ZenCopyIcon className='text-zen-dark-9' />,
    secondary: <ZenCopyIcon className='text-white' />,
  };

  return (
    <div
      className={classNames(
        'flex items-center space-x-2 rounded-lg w-max',
        variantClassNameMap[variant],
      )}
    >
      <span className='font-zen-body font-medium text-sm'>{team?.id}</span>
      <CopyToClipboardHover
        value={team?.id!}
        copyIcon={variantToCopyIconMap[variant]}
        copyToltipLabel='Copy ID'
      />
    </div>
  );
};

export default ZenTeamsCodeCell;
