import React from 'react';
import { useSelector } from 'react-redux';
import FileCabinetTable from '../components/Zen/Transaction/FileCabinet/FileCabinetTable';
import ZenRoute from '../components/Zen/ZenRoute';
import { RezenObjectTypeEnum } from '../openapi/yada';
import { RootState } from '../types';
import { getFullName } from '../utils/AgentHelper';
import { getBorrower } from '../utils/MortgageUtils';

interface MortgageFileCabinetRouteProps {
  borrowerId: string;
}

const MortgageFileCabinetRoute: React.FC<MortgageFileCabinetRouteProps> = ({
  borrowerId,
}) => {
  const {
    loanResponse: { data: loan },
  } = useSelector((state: RootState) => state.mortgage);
  const borrower = getBorrower(borrowerId, loan?.borrowers || []);

  return (
    <ZenRoute title={`Mortgage File Cabinet - ${getFullName(borrower)}`}>
      <FileCabinetTable
        dropboxId={borrower?.dropboxId!}
        getPublicUserInfo
        containerType={RezenObjectTypeEnum.Borrower}
        containerId={borrower?.id!}
        checklistId={borrower?.checklistId!}
        backUrl={window.location.pathname}
      />
    </ZenRoute>
  );
};

export default MortgageFileCabinetRoute;
