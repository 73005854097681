import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faPen } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useGemini } from '../../../hooks/useGemini';
import { IdentitySummaryResponse } from '../../../openapi/keymaker';
import { fetchIdentityByKeymakerId } from '../../../slices/AuthSlice';
import { RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import useSelfOnly from '../../auth/hooks/useSelfOnly';
import ResourceContainer from '../../ResourceContainer';
import ZenAction from '../../Zen/ZenAction';
import ZenCardWithItems from '../../Zen/ZenCardWithItems';
import ZenChangePasswordForm from '../ZenChangePasswordForm';
import ZenUpdateEmailForm from '../ZenUpdateEmailForm';

interface AgentEmailAndPasswordSecuritySettingsProps {}

const AgentEmailAndPasswordSecuritySettings: React.FC<AgentEmailAndPasswordSecuritySettingsProps> = () => {
  const dispatch = useDispatch();
  const {
    agentDetail: {
      detailResponse: { data: detail },
    },
    auth: { identityByKeymakerIdResponse },
  } = useSelector((state: RootState) => state);
  const isSelfOnly = useSelfOnly(detail?.id!);
  const [isChangingEmail, setIsChangingEmail] = useState<boolean>(false);
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);

  const agentLoginDetails:
    | IdentitySummaryResponse
    | undefined = identityByKeymakerIdResponse.data![detail?.keyMakerId!];

  useEffect(() => {
    dispatch(fetchIdentityByKeymakerId(detail?.keyMakerId!));
  }, [dispatch, detail?.keyMakerId]);

  const isGeminiEnabled = useGemini();

  return (
    <AuthorizationContainer asyncResponse={identityByKeymakerIdResponse}>
      <ResourceContainer
        loading={identityByKeymakerIdResponse.loading}
        errorCode={identityByKeymakerIdResponse.error}
        isEmpty={!agentLoginDetails}
        resourceName='Identity'
      >
        <ZenCardWithItems
          title='My Login'
          items={[
            ...[
              {
                name: 'User name',
                value: agentLoginDetails?.username,
              },
              {
                name: 'Email',
                value: agentLoginDetails?.email,
              },
              ...(isSelfOnly
                ? [
                    {
                      name: 'Password',
                      value: '****************',
                    },
                  ]
                : []),
              {
                name: '',
                value: '',
              },
            ],
          ]}
          RightComponent={
            <div className='flex flex-row items-center space-x-4'>
              <ZenAction
                text='Update Email'
                icon={
                  <FontAwesomeIcon
                    icon={faPen}
                    className={cn(
                      isGeminiEnabled
                        ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                        : 'text-primary-blue text-sm',
                    )}
                  />
                }
                onClick={() => setIsChangingEmail(true)}
              />
              <ZenAction
                text='Change Password'
                icon={
                  <FontAwesomeIcon
                    icon={faLock}
                    className={cn(
                      isGeminiEnabled
                        ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                        : 'text-primary-blue text-sm',
                    )}
                  />
                }
                onClick={() => setIsChangingPassword(true)}
              />
            </div>
          }
        />
        {isChangingEmail && (
          <ZenUpdateEmailForm
            isOpen
            onClose={() => setIsChangingEmail(false)}
            identity={agentLoginDetails!}
          />
        )}
        <ZenChangePasswordForm
          isOpen={isChangingPassword}
          onClose={() => setIsChangingPassword(false)}
        />
      </ResourceContainer>
    </AuthorizationContainer>
  );
};
export default AgentEmailAndPasswordSecuritySettings;
