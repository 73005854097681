import {
  faAddressBook,
  faBuildingColumns,
  faCalendar,
  faChartSimpleHorizontal,
  faListUl,
  faUserMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pickBy, values } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AddressResponse,
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
  MlsRequest,
  MlsResponse,
  MlsSecretsResponse,
} from '../../../openapi/yenta';

import {
  useCreateMls,
  useUpdateMls,
} from '../../../query/boardsAndMls/useBoardsAndMls';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { showErrorToastForErrorCode } from '../../../slices/ToastNotificationSlice';
import { AppDispatch, RootState } from '../../../types';
import {
  billingFrequencyOptions,
  billingTypeOptions,
  countryOptions,
  getAddressFromGoogleLocation,
  getDefaultFormValuesForMlsForm,
  idxFeedStatusOptions,
  isCollapsible,
  paymentTypeOptions,
  statusOptions,
  unreportedLicenseRiskOptions,
} from '../../../utils/MLSUtils';
import {
  EMAIL_VALIDATIONS,
  MONEY_VALUE_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../utils/Validations';
import AddressFormComponent from '../../Gemini/AddressFormComponent';
import GeminiAccordion from '../../Gemini/GeminiAccordion';
import GeminiProfileImage from '../../Gemini/GeminiProfileImage';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledEmailInput from '../../Zen/Input/ZenControlledEmailInput';
import { GooglePlaceLocationType } from '../../Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledHTMLSelectInput from '../../Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledMoneyInput from '../../Zen/Input/ZenControlledMoneyInput';
import ZenControlledPhoneNumberInput from '../../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledStateOrProvinceInput from '../../Zen/Input/ZenControlledStateOrProvince';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../../Zen/ZenSidebarModalForm';

interface GeminiCreateMLSFormProps {
  isOpen: boolean;
  mlsDetails?: MlsResponse;
  secrets?: MlsSecretsResponse;
  onClose(): void;
}

export interface MlsFormData extends Omit<MlsRequest, 'administrativeAreas'> {
  location?: GooglePlaceLocationType;
  isManualAddress: boolean;
  administrativeAreas: {
    country: AdministrativeAreaRequestCountryEnum;
    stateOrProvince: AdministrativeAreaRequestStateOrProvinceEnum;
  };
}

const isValidAddress = (address: AddressResponse) => {
  const { oneLine, streetAddress2, type, ...requiredFields } = address || {};

  return values(requiredFields).some((value) => value);
};

const GeminiCreateMLSForm: React.FC<GeminiCreateMLSFormProps> = ({
  isOpen,
  onClose,
  mlsDetails,
  secrets,
}) => {
  const history = useHistory();
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const { mutate: createMls, isLoading: createMlsLoading } = useCreateMls();
  const { mutate: updateMls, isLoading: updateMlsLoading } = useUpdateMls(
    mlsDetails?.id!,
  );

  const dispatch: AppDispatch = useDispatch();

  const methods = useForm<MlsFormData>({
    defaultValues: getDefaultFormValuesForMlsForm(
      mlsDetails,
      secrets,
      userDetail?.defaultCurrency,
    ),
    mode: 'onChange',
  });

  const {
    getValues,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  const formValues = getValues();
  const [selectedCountry, isManualAddress] = watch([
    'administrativeAreas.country',
    'isManualAddress',
  ]);

  const onError = (e: any, data: MlsRequest, type: 'create' | 'update') => {
    dispatch(showApiErrorModal(e));

    ErrorService.notify(`Unable to ${type} board`, e, {
      data,
    });

    dispatch(
      showErrorToastForErrorCode(
        `We couldn't ${type} the board`,
        ErrorService.getErrorCode(e),
      ),
    );
  };

  const onSubmit = async (data: MlsFormData) => {
    const filteredData = pickBy(data, (value) => value);
    filteredData.recurringFee = filteredData.recurringFee?.amount
      ? filteredData.recurringFee
      : undefined;

    if (!isManualAddress && !!data.location?.place_id) {
      filteredData.address = getAddressFromGoogleLocation(data.location);
    } else if (isValidAddress((data.address! as unknown) as AddressResponse)) {
      filteredData.address = { ...data.address!, type: '' };
    } else {
      filteredData.address = undefined;
    }

    delete filteredData.location;

    const mlsData: MlsRequest = {
      ...filteredData,
      administrativeAreas: [
        {
          country: filteredData?.administrativeAreas?.country!,
          stateOrProvince: filteredData?.administrativeAreas?.stateOrProvince!,
        },
      ],
    };

    if (mlsDetails) {
      updateMls(
        { id: mlsDetails.id!, mlsData },
        {
          onSuccess: () => onClose(),
          onError: (e) => onError(e, mlsData, 'update'),
        },
      );
    } else {
      createMls(
        { mlsData },
        {
          onSuccess: (data) => {
            if (data?.id) {
              history.push(`/mls/${data?.id}`);
            }
          },
          onError: (e) => onError(e, mlsData, 'create'),
        },
      );
    }
  };

  return (
    <ZenSidebarModalForm
      title={!!mlsDetails ? 'Edit MLS' : 'Create MLS'}
      isOpen={isOpen}
      onClose={onClose}
      actionTitle={!!mlsDetails ? 'Update' : 'Create'}
      cancelTitle='Cancel'
      isEqualWidth
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting || createMlsLoading || updateMlsLoading}
    >
      <div className='pl-5 pr-6'>
        <FormProvider {...methods}>
          <div className='mt-10 mb-4 flex justify-center items-center'>
            <GeminiProfileImage
              width={134}
              imageUrl={mlsDetails?.logo ?? '/img/default-avatar.svg'}
            />
          </div>
          <GeminiAccordion
            leftIcon={faListUl}
            title='MLS Details'
            isOpenDefault
            canToggle={isCollapsible(formValues, errors, [
              'name',
              'registrationFee.amount',
              'joinDate',
              'status',
              'administrativeAreas.stateOrProvince',
              'administrativeAreas.country',
            ])}
          >
            <div className='flex flex-col gap-6 bg-regent-200 rounded-2xl px-5 py-4'>
              <ZenControlledTextInput<MlsFormData, 'name'>
                control={control}
                label='MLS Name'
                name='name'
                placeholder='Enter MLS Name'
                rules={{ required: 'Please provide a MLS name' }}
                isRequired
                shouldUnregister={false}
              />

              <AddressFormComponent />

              <ZenControlledTextInput<MlsFormData, 'officeCode'>
                control={control}
                label='Office Code'
                name='officeCode'
                placeholder='T2M 3YU7'
                shouldUnregister={false}
              />

              <ZenControlledTextInput<MlsFormData, 'brokerOrMemberId'>
                control={control}
                label='Broker/Member Id'
                name='brokerOrMemberId'
                placeholder='Enter Broker/Member Id'
                shouldUnregister={false}
              />

              <div className='flex gap-3'>
                <ZenControlledStateOrProvinceInput<
                  MlsFormData,
                  'administrativeAreas.stateOrProvince'
                >
                  selectedCountry={
                    (selectedCountry as unknown) as AdministrativeAreaRequestCountryEnum
                  }
                  name='administrativeAreas.stateOrProvince'
                  control={control}
                  rules={{ required: 'Please select state or province.' }}
                  setValue={setValue}
                  isRequired
                  shouldUnregister={false}
                />

                <ZenControlledHTMLSelectInput<
                  MlsFormData,
                  'administrativeAreas.country'
                >
                  name='administrativeAreas.country'
                  control={control}
                  shouldUnregister={!mlsDetails}
                  label='Country'
                  placeholder='Country'
                  options={countryOptions}
                  rules={{
                    required: 'Please select country',
                  }}
                  isRequired
                />
              </div>

              <div className='flex gap-3'>
                <ZenControlledMoneyInput<MlsFormData, 'registrationFee'>
                  control={control}
                  label='Registration Fee'
                  name='registrationFee'
                  placeholder='Enter registration fee'
                  rules={MONEY_VALUE_VALIDATIONS}
                  isRequired
                  shouldUnregister={false}
                />

                <ZenControlledDatePickerInput<MlsFormData, 'joinDate'>
                  name='joinDate'
                  control={control}
                  label='Join Date'
                  placeholder='Join Date'
                  rules={{ required: 'Please provide a join date' }}
                  isRequired
                  shouldUnregister={false}
                  icon={
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className='text-primary-blue'
                    />
                  }
                />
              </div>

              <ZenControlledHTMLSelectInput<MlsFormData, 'status'>
                name='status'
                control={control}
                label='Status'
                placeholder='Status'
                options={statusOptions}
                rules={{ required: 'Please select MLS status' }}
                isRequired
                shouldUnregister={false}
              />
            </div>
          </GeminiAccordion>

          <GeminiAccordion
            leftIcon={faAddressBook}
            title='Contact Details'
            isOpenDefault
            canToggle={isCollapsible(formValues, errors, [
              'phoneNumber',
              'email',
            ])}
          >
            <div className='flex flex-col gap-6 bg-regent-200 rounded-2xl px-5 py-4'>
              <ZenControlledPhoneNumberInput<MlsFormData, 'phoneNumber'>
                control={control}
                label='Phone Number'
                name='phoneNumber'
                placeholder='+1 (702) 123-4567'
                rules={{
                  required: 'Please provide a phone number',
                  ...PHONE_NUMBER_VALIDATIONS,
                }}
                isRequired
                shouldUnregister={false}
              />
              <ZenControlledEmailInput<MlsFormData, 'email'>
                control={control}
                label='Email Address'
                name='email'
                placeholder='example@gmail.com'
                rules={{
                  required: 'Please provide an email address',
                  ...EMAIL_VALIDATIONS,
                }}
                isRequired
                shouldUnregister={false}
              />
            </div>
          </GeminiAccordion>

          <GeminiAccordion
            leftIcon={faUserMagnifyingGlass}
            title='Login Credentials'
            isOpenDefault
          >
            <div className='flex flex-col gap-6 bg-regent-200 rounded-2xl px-5 py-4'>
              <ZenControlledTextInput<MlsFormData, 'username'>
                control={control}
                label='Username/ Email'
                name='username'
                placeholder='Enter Username/Email ID'
                shouldUnregister={false}
              />

              <ZenControlledTextInput<MlsFormData, 'password'>
                control={control}
                type='password'
                label='Password'
                name='password'
                placeholder='Password'
                shouldUnregister={false}
              />
            </div>
          </GeminiAccordion>

          <GeminiAccordion
            leftIcon={faBuildingColumns}
            title='Finance Details'
            isOpenDefault
          >
            <div className='flex flex-col gap-6 bg-regent-200 rounded-2xl px-5 py-4'>
              <div className='flex gap-3'>
                <ZenControlledHTMLSelectInput<MlsFormData, 'billingType'>
                  name='billingType'
                  control={control}
                  label='Billing Type'
                  placeholder='Select Billing Type'
                  options={billingTypeOptions}
                  shouldUnregister={false}
                />

                <ZenControlledHTMLSelectInput<MlsFormData, 'billingFrequency'>
                  name='billingFrequency'
                  control={control}
                  label='Billing Frequency'
                  placeholder='Billing Frequency'
                  options={billingFrequencyOptions}
                  shouldUnregister={false}
                />
              </div>

              <div className='flex gap-3'>
                <ZenControlledMoneyInput<MlsFormData, 'recurringFee'>
                  control={control}
                  label='Fee Amount'
                  name='recurringFee'
                  placeholder='Enter Fee Amount'
                  shouldUnregister={false}
                />

                <ZenControlledHTMLSelectInput<MlsFormData, 'paymentType'>
                  name='paymentType'
                  control={control}
                  label='Payment Type'
                  placeholder='Select Payment Type'
                  options={paymentTypeOptions}
                  shouldUnregister={false}
                />
              </div>

              <ZenControlledDatePickerInput<MlsFormData, 'nextDueDate'>
                name='nextDueDate'
                control={control}
                label='Next Due Date'
                placeholder='Next Due Date'
                shouldUnregister={false}
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className='text-primary-blue'
                  />
                }
              />

              <ZenControlledHTMLSelectInput<
                MlsFormData,
                'unreportedLicenseRisk'
              >
                name='unreportedLicenseRisk'
                control={control}
                label='Unreported License Risk'
                placeholder='Select Unreported License Risk'
                options={unreportedLicenseRiskOptions}
                shouldUnregister={false}
              />
            </div>
          </GeminiAccordion>

          <GeminiAccordion
            leftIcon={faChartSimpleHorizontal}
            title='IDX'
            isOpenDefault
            canToggle={isCollapsible(formValues, errors, ['code'])}
          >
            <div className='flex flex-col gap-6 bg-regent-200 rounded-2xl px-5 py-4'>
              <ZenControlledTextInput<MlsFormData, 'code'>
                control={control}
                label='MLS Code'
                name='code'
                placeholder='US-AKSMLS'
                rules={{ required: 'Please provide a MLS code' }}
                isRequired
                shouldUnregister={false}
              />

              <ZenControlledHTMLSelectInput<MlsFormData, 'idxFeedStatus'>
                name='idxFeedStatus'
                control={control}
                label='IDX Feed Status'
                placeholder='Select IDX Feed Status'
                options={idxFeedStatusOptions}
                shouldUnregister={false}
              />
            </div>
          </GeminiAccordion>
        </FormProvider>
      </div>
    </ZenSidebarModalForm>
  );
};

export default GeminiCreateMLSForm;
