import capitalize from 'lodash/capitalize';
import { AdministrativeAreaStateEnum, EnumMap } from '../../types';
import {
  NationalBusinessIdentification,
  NationalBusinessIdentificationTypeEnum,
} from '../../openapi/yenta';

type Entities = { name?: string };

export const sortAndGroupContacts = <T extends Entities>(
  contacts: T[],
  search: string = '',
): EnumMap<string, T[]> => {
  const filteredContacts = contacts.filter(
    (contact) =>
      // Using optional chaining and a fallback to ensure TypeScript recognizes the condition
      contact.name?.toLowerCase().includes(search.toLowerCase()) ?? false,
  );

  const sortedContacts = filteredContacts.sort((a, b) => {
    const nameA = a.name?.toLowerCase() ?? '';
    const nameB = b.name?.toLowerCase() ?? '';
    return nameA.localeCompare(nameB);
  });

  return sortedContacts.reduce((acc: EnumMap<string, T[]>, contact) => {
    const firstLetter = contact.name?.[0]?.toUpperCase() || '';
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(contact);
    return acc;
  }, {} as EnumMap<string, T[]>);
};

/**
 * Returns a reversed mapping of an object.
 *
 * @param object object to reverse the mapping of
 * @returns reversed mapping of the object
 */
export const getReversedMapping = (object: object) =>
  Object.entries(object).reduce(
    // Reverse the mapping to look up by code
    (acc: Record<string, string>, [key, value]) => {
      acc[value] = key;
      return acc;
    },
    {},
  );

/**
 * Attempts to look up the readable name of a state or province code.
 * If the `name` is not found, but is a key of the `AdministrativeAreaStateEnum` object,
 * it will return the `name` as is.
 *
 * @param name abbreviation of state or province, i.e, 'US-CA'
 * @returns readable name of state or province, i.e, 'CALIFORNIA'
 */
export const lookupStateOrProvinceCode = (name: string) => {
  const stateOrProvinceMap = getReversedMapping(AdministrativeAreaStateEnum);
  return (
    stateOrProvinceMap[name] ||
    (name in AdministrativeAreaStateEnum ? name : null)
  );
};

/**
 * Returns the readable name of a state or province code.
 *
 * @param code abbreviation of state or province, i.e, 'US-CA'
 * @returns readable name of state or province, i.e, 'California'
 */
export const getReadableStateOrProvinceName = (code: string) => {
  const reversedMapping = getReversedMapping(AdministrativeAreaStateEnum);

  const key = reversedMapping[code];

  if (!key) {
    return 'Unknown';
  }

  return key
    .toLowerCase()
    .split('_')
    .map((word) => capitalize(word))
    .join(' ');
};

/**
 * Formats a list of state or province codes into a readable string.
 *
 * @param stateOrProvinces list of state or province codes, i.e, ['US-CA', 'US-NY']
 * @returns formatted string of state or province names, i.e, 'California, New York'
 */
export const formatStateOrProvince = (stateOrProvinces: string[]) => {
  const formattedStateOrProvince = stateOrProvinces
    ?.map((id) => getReadableStateOrProvinceName(id))
    .join(', ');
  return formattedStateOrProvince;
};

/**
 * Returns an array of NationalBusinessIdentification objects based on the form data.
 *
 * @param formData form data containing the national business ids
 * @returns array of NationalBusinessIdentification objects
 */
export const getNationalBusinessIds = (values: {
  gstId?: string;
  hstId?: string;
  qstId?: string;
  ein?: string;
  Bn?: string;
  bn?: string;
}) => {
  const { gstId, hstId, qstId, ein, Bn, bn } = values;

  const idMappings = [
    { id: gstId, type: NationalBusinessIdentificationTypeEnum.GstId },
    { id: hstId, type: NationalBusinessIdentificationTypeEnum.HstId },
    { id: qstId, type: NationalBusinessIdentificationTypeEnum.QstId },
    { id: ein, type: NationalBusinessIdentificationTypeEnum.Ein },
    { id: Bn ?? bn, type: NationalBusinessIdentificationTypeEnum.Bn },
  ];

  return idMappings.reduce((acc, { id, type }) => {
    if (id) {
      acc.push({
        type: type,
        nationalId: id,
      });
    }
    return acc;
  }, [] as NationalBusinessIdentification[]);
};

/**
 * Extracts national business ids from an array of NationalBusinessIdentification objects.
 * Add more id types as needed.
 */
export const extractNationalBusinessIds = (
  identifications: NationalBusinessIdentification[] | undefined,
) => {
  const findId = (
    type: NationalBusinessIdentificationTypeEnum,
  ): string | undefined =>
    identifications?.find((n) => n?.type === type)?.nationalId;

  return {
    nationalId: findId(NationalBusinessIdentificationTypeEnum.Ein) ?? '',
    gstNationalId: findId(NationalBusinessIdentificationTypeEnum.GstId) ?? '',
    hstNationalId: findId(NationalBusinessIdentificationTypeEnum.HstId) ?? '',
    qstNationalId: findId(NationalBusinessIdentificationTypeEnum.QstId) ?? '',
    bnNationalId: findId(NationalBusinessIdentificationTypeEnum.Bn) ?? '',
  };
};

/**
 * determines if a vendor is eligible for verification
 */
export const determineVerificationEligibility = ({
  hasW9,
  isCanadaVendor,
  isUSVendor,
  isVendorExternalAgent,
  isVendorSelected,
  nationalBusinessIdentifications = [],
}: {
  hasW9: boolean;
  isCanadaVendor: boolean;
  isUSVendor: boolean;
  isVendorExternalAgent: boolean;
  isVendorSelected: boolean;
  nationalBusinessIdentifications: NationalBusinessIdentification[];
}) => {
  const hasId = (idType: NationalBusinessIdentificationTypeEnum) =>
    nationalBusinessIdentifications.some((val) => val.type === idType);

  const hasBn = hasId(NationalBusinessIdentificationTypeEnum.Bn);
  const hasGstId = hasId(NationalBusinessIdentificationTypeEnum.GstId);
  const hasHstId = hasId(NationalBusinessIdentificationTypeEnum.HstId);
  const hasQstId = hasId(NationalBusinessIdentificationTypeEnum.QstId);
  const hasEin = hasId(NationalBusinessIdentificationTypeEnum.Ein);

  const canadaTaxFilled = hasBn && (hasGstId || hasHstId || hasQstId);
  const allUSTaxInfoFilled = hasEin && hasW9;

  if (isVendorExternalAgent) {
    if (
      (isCanadaVendor && !canadaTaxFilled) ||
      (isUSVendor && !allUSTaxInfoFilled)
    ) {
      return false;
    }
  }

  return isVendorSelected;
};

/**
 * Checks if a value is part of an Enum's values.
 *
 * @param enumObject enum object
 * @param value value to check
 * @returns true if the value is part of the enum's values, false otherwise
 */
export const isInEnum = <T>(enumObject: T, value: any): value is T[keyof T] => {
  if (typeof enumObject !== 'object' || enumObject === null) {
    return false;
  }

  // Get the values of the enumObject and check if value is included
  const values = Object.values(enumObject);
  return values.includes(value);
};
