/* tslint:disable */
/* eslint-disable */
/**
 * Hermes API
 * Hermes Main Application
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiResponse
 */
export interface ApiResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface BaseMessage
 */
export interface BaseMessage {
    /**
     * 
     * @type {string}
     * @memberof BaseMessage
     */
    'eventId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseMessage
     */
    'createdAt'?: number;
}
/**
 * 
 * @export
 * @interface BlockedSender
 */
export interface BlockedSender {
    /**
     * 
     * @type {string}
     * @memberof BlockedSender
     */
    'senderAddress': string;
    /**
     * 
     * @type {number}
     * @memberof BlockedSender
     */
    'bounces': number;
}
/**
 * 
 * @export
 * @interface BlockedSenderResponse
 */
export interface BlockedSenderResponse {
    /**
     * 
     * @type {Array<BlockedSender>}
     * @memberof BlockedSenderResponse
     */
    'blockedSenders'?: Array<BlockedSender>;
}
/**
 * 
 * @export
 * @interface BooleanResponse
 */
export interface BooleanResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanResponse
     */
    'available'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface DeferredNotificationDto
 */
export interface DeferredNotificationDto {
    /**
     * 
     * @type {string}
     * @memberof DeferredNotificationDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeferredNotificationDto
     */
    'body'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeferredNotificationDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeferredNotificationDto
     */
    'recipientUsername'?: string;
    /**
     * 
     * @type {RezenObject}
     * @memberof DeferredNotificationDto
     */
    'publisher'?: RezenObject;
    /**
     * 
     * @type {string}
     * @memberof DeferredNotificationDto
     */
    'channel'?: DeferredNotificationDtoChannelEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DeferredNotificationDtoChannelEnum {
    Push = 'PUSH',
    Email = 'EMAIL'
}

/**
 * 
 * @export
 * @interface EmailAvailabilityResponse
 */
export interface EmailAvailabilityResponse {
    /**
     * 
     * @type {boolean}
     * @memberof EmailAvailabilityResponse
     */
    'available'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailAvailabilityResponse
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface EmailStatusRequest
 */
export interface EmailStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailStatusRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface EmailStatusResponse
 */
export interface EmailStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof EmailStatusResponse
     */
    'banned'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailStatusResponse
     */
    'status'?: EmailStatusResponseStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EmailStatusResponseStatusEnum {
    Ok = 'OK',
    Bounced = 'BOUNCED',
    ReportedSpam = 'REPORTED_SPAM'
}

/**
 * 
 * @export
 * @interface EmailTemplateResponse
 */
export interface EmailTemplateResponse {
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateResponse
     */
    'emailTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateResponse
     */
    'subjectTemplate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateResponse
     */
    'bodyTemplate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailTemplateResponse
     */
    'templateParameterNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateResponse
     */
    'status'?: EmailTemplateResponseStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EmailTemplateResponse
     */
    'attachmentRequired'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum EmailTemplateResponseStatusEnum {
    Active = 'ACTIVE',
    Archived = 'ARCHIVED'
}

/**
 * 
 * @export
 * @interface EmailTemplateResponses
 */
export interface EmailTemplateResponses {
    /**
     * 
     * @type {Array<EmailTemplateResponse>}
     * @memberof EmailTemplateResponses
     */
    'emailTemplates'?: Array<EmailTemplateResponse>;
}
/**
 * 
 * @export
 * @interface FailedMessage
 */
export interface FailedMessage {
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'clientType': FailedMessageClientTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'status': FailedMessageStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'reprocessingAttempts'?: number;
    /**
     * 
     * @type {KafkaMessageLocation}
     * @memberof FailedMessage
     */
    'kafkaMessageLocation': KafkaMessageLocation;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'key'?: string;
    /**
     * 
     * @type {WrapperBaseMessage}
     * @memberof FailedMessage
     */
    'payload'?: WrapperBaseMessage;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'unprocessed'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FailedMessage
     */
    'processed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'shortenedId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageClientTypeEnum {
    Producer = 'PRODUCER',
    Consumer = 'CONSUMER'
}
/**
    * @export
    * @enum {string}
    */
export enum FailedMessageStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FailedMessageRetryRequest
 */
export interface FailedMessageRetryRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageRetryRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface FailedMessageUpdateStatusRequest
 */
export interface FailedMessageUpdateStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'status'?: FailedMessageUpdateStatusRequestStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageUpdateStatusRequestStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FailedMessagesCriteriaRequest
 */
export interface FailedMessagesCriteriaRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtTo'?: string;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseFailedMessage
 */
export interface GenericSearchResponseFailedMessage {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseFailedMessage
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortDirection'?: GenericSearchResponseFailedMessageSortDirectionEnum;
    /**
     * 
     * @type {Array<FailedMessage>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'results'?: Array<FailedMessage>;
}

/**
    * @export
    * @enum {string}
    */
export enum GenericSearchResponseFailedMessageSortDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GlobalNotificationPreferencesDto
 */
export interface GlobalNotificationPreferencesDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GlobalNotificationPreferencesDto
     */
    'channels'?: Array<GlobalNotificationPreferencesDtoChannelsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum GlobalNotificationPreferencesDtoChannelsEnum {
    Push = 'PUSH',
    Email = 'EMAIL'
}

/**
 * 
 * @export
 * @interface KafkaMessageLocation
 */
export interface KafkaMessageLocation {
    /**
     * 
     * @type {string}
     * @memberof KafkaMessageLocation
     */
    'topic': string;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'offset'?: number;
}
/**
 * 
 * @export
 * @interface PublisherNotificationPreferencesDto
 */
export interface PublisherNotificationPreferencesDto {
    /**
     * 
     * @type {RezenObject}
     * @memberof PublisherNotificationPreferencesDto
     */
    'publisher': RezenObject;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublisherNotificationPreferencesDto
     */
    'mutedChannels'?: Array<PublisherNotificationPreferencesDtoMutedChannelsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum PublisherNotificationPreferencesDtoMutedChannelsEnum {
    Push = 'PUSH',
    Email = 'EMAIL'
}

/**
 * 
 * @export
 * @interface PushNotificationRegistrationResponse
 */
export interface PushNotificationRegistrationResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof PushNotificationRegistrationResponse
     */
    'pushNotificationRegistrationIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ReassignEmailByEmailRequest
 */
export interface ReassignEmailByEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof ReassignEmailByEmailRequest
     */
    'originalEmail': string;
    /**
     * 
     * @type {string}
     * @memberof ReassignEmailByEmailRequest
     */
    'newEmail': string;
}
/**
 * 
 * @export
 * @interface ReassignEmailByRealUserNameRequest
 */
export interface ReassignEmailByRealUserNameRequest {
    /**
     * 
     * @type {string}
     * @memberof ReassignEmailByRealUserNameRequest
     */
    'realUserName': string;
    /**
     * 
     * @type {string}
     * @memberof ReassignEmailByRealUserNameRequest
     */
    'newEmail': string;
}
/**
 * 
 * @export
 * @interface ReassignEmailRequest
 */
export interface ReassignEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof ReassignEmailRequest
     */
    'realUserName': string;
    /**
     * 
     * @type {string}
     * @memberof ReassignEmailRequest
     */
    'oldEmail': string;
    /**
     * 
     * @type {string}
     * @memberof ReassignEmailRequest
     */
    'newEmail': string;
}
/**
 * 
 * @export
 * @interface Recipient
 */
export interface Recipient {
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ResetEmailRequest
 */
export interface ResetEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetEmailRequest
     */
    'oldEmail': string;
    /**
     * 
     * @type {string}
     * @memberof ResetEmailRequest
     */
    'newEmail': string;
}
/**
 * 
 * @export
 * @interface RezenObject
 */
export interface RezenObject {
    /**
     * 
     * @type {string}
     * @memberof RezenObject
     */
    'type': RezenObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RezenObject
     */
    'id': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RezenObject
     */
    'metadata'?: { [key: string]: string; };
}

/**
    * @export
    * @enum {string}
    */
export enum RezenObjectTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE'
}

/**
 * 
 * @export
 * @interface SendEmailUsingTemplateRequest
 */
export interface SendEmailUsingTemplateRequest {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof SendEmailUsingTemplateRequest
     */
    'templateParameters': { [key: string]: any; };
    /**
     * 
     * @type {Array<string>}
     * @memberof SendEmailUsingTemplateRequest
     */
    'fileAttachmentUrls'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SendEmailUsingTemplateRequest
     */
    'templateName': string;
    /**
     * 
     * @type {string}
     * @memberof SendEmailUsingTemplateRequest
     */
    'recipientEmailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof SendEmailUsingTemplateRequest
     */
    'recipientName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendEmailUsingTemplateRequest
     */
    'otherRecipientsEmailAddresses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendEmailUsingTemplateRequest
     */
    'replyToEmailAddresses'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SendEmailUsingTemplateRequest
     */
    'customSenderName'?: string;
}
/**
 * 
 * @export
 * @interface SendEmailUsingTemplateRequestV2
 */
export interface SendEmailUsingTemplateRequestV2 {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof SendEmailUsingTemplateRequestV2
     */
    'templateParameters': { [key: string]: any; };
    /**
     * 
     * @type {Array<string>}
     * @memberof SendEmailUsingTemplateRequestV2
     */
    'fileAttachmentUrls'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SendEmailUsingTemplateRequestV2
     */
    'templateName': SendEmailUsingTemplateRequestV2TemplateNameEnum;
    /**
     * 
     * @type {string}
     * @memberof SendEmailUsingTemplateRequestV2
     */
    'recipientEmailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof SendEmailUsingTemplateRequestV2
     */
    'recipientName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendEmailUsingTemplateRequestV2
     */
    'otherRecipientsEmailAddresses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendEmailUsingTemplateRequestV2
     */
    'replyToEmailAddresses'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SendEmailUsingTemplateRequestV2
     */
    'customSenderName'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SendEmailUsingTemplateRequestV2TemplateNameEnum {
    Test = 'TEST',
    Welcome = 'WELCOME',
    WelcomeCanada = 'WELCOME_CANADA',
    ApplicationApproved = 'APPLICATION_APPROVED',
    SponseeJustJoined = 'SPONSEE_JUST_JOINED',
    PasswordReset = 'PASSWORD_RESET',
    CommissionValidation = 'COMMISSION_VALIDATION',
    CdaInvoice = 'CDA_INVOICE',
    NudgeTransaction = 'NUDGE_TRANSACTION',
    ClosingDocs = 'CLOSING_DOCS',
    PaymentScheduled = 'PAYMENT_SCHEDULED',
    PaymentScheduledV2 = 'PAYMENT_SCHEDULED_V2',
    MigrationWelcome = 'MIGRATION_WELCOME',
    SignedIca = 'SIGNED_ICA',
    Onboarding = 'ONBOARDING',
    AgentLead = 'AGENT_LEAD',
    CalendarEvent = 'CALENDAR_EVENT',
    PaymentDetailsInvalid = 'PAYMENT_DETAILS_INVALID',
    PostClosingRefund = 'POST_CLOSING_REFUND',
    PostClosingDebt = 'POST_CLOSING_DEBT',
    SignatureSignDocument = 'SIGNATURE_SIGN_DOCUMENT',
    SignatureDocumentSentOnBehalf = 'SIGNATURE_DOCUMENT_SENT_ON_BEHALF',
    SignatureSignDocumentCompleted = 'SIGNATURE_SIGN_DOCUMENT_COMPLETED',
    MortgageBorrowerInvitation = 'MORTGAGE_BORROWER_INVITATION',
    MortgageCoBorrowerInvitation = 'MORTGAGE_CO_BORROWER_INVITATION',
    MortgageApplicationStarted = 'MORTGAGE_APPLICATION_STARTED',
    MortgageApplicationSubmitted = 'MORTGAGE_APPLICATION_SUBMITTED',
    MortgageLoanPreApproved = 'MORTGAGE_LOAN_PRE_APPROVED',
    MortgageBorrowerChecklistItemAssigned = 'MORTGAGE_BORROWER_CHECKLIST_ITEM_ASSIGNED',
    MortgageBorrowerApplicationSubmitted = 'MORTGAGE_BORROWER_APPLICATION_SUBMITTED',
    MortgageBorrowerLoanPreApproved = 'MORTGAGE_BORROWER_LOAN_PRE_APPROVED',
    ApprovedForCommissionDocumentGeneration = 'APPROVED_FOR_COMMISSION_DOCUMENT_GENERATION',
    TransactionHasExpired = 'TRANSACTION_HAS_EXPIRED',
    TransactionIsAboutToClose = 'TRANSACTION_IS_ABOUT_TO_CLOSE',
    TransactionListingHasExpired = 'TRANSACTION_LISTING_HAS_EXPIRED',
    TransactionListingIsAboutToExpire = 'TRANSACTION_LISTING_IS_ABOUT_TO_EXPIRE',
    TransactionWaitForApproveCdCalculation = 'TRANSACTION_WAIT_FOR_APPROVE_CD_CALCULATION',
    TransactionWaitForSendCd = 'TRANSACTION_WAIT_FOR_SEND_CD',
    WealthPlanAgentAttractionReferral = 'WEALTH_PLAN_AGENT_ATTRACTION_REFERRAL',
    WealthPlanAgentAttractionReferrer = 'WEALTH_PLAN_AGENT_ATTRACTION_REFERRER',
    WealthPlanNotification = 'WEALTH_PLAN_NOTIFICATION',
    InviteMemberToTeam = 'INVITE_MEMBER_TO_TEAM',
    InviteBuyerToSignBuyerBrokerAgreement = 'INVITE_BUYER_TO_SIGN_BUYER_BROKER_AGREEMENT',
    VoiceCallRecordingComplete = 'VOICE_CALL_RECORDING_COMPLETE',
    VoiceMailRecordingComplete = 'VOICE_MAIL_RECORDING_COMPLETE',
    VoiceCallRecordingTranscribed = 'VOICE_CALL_RECORDING_TRANSCRIBED',
    VoiceMailRecordingTranscribed = 'VOICE_MAIL_RECORDING_TRANSCRIBED'
}

/**
 * 
 * @export
 * @interface SendNotificationRq
 */
export interface SendNotificationRq {
    /**
     * 
     * @type {RezenObject}
     * @memberof SendNotificationRq
     */
    'publisher': RezenObject;
    /**
     * 
     * @type {string}
     * @memberof SendNotificationRq
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SendNotificationRq
     */
    'body': string;
    /**
     * 
     * @type {Array<Recipient>}
     * @memberof SendNotificationRq
     */
    'recipients': Array<Recipient>;
}
/**
 * 
 * @export
 * @interface SendPushNotificationRequest
 */
export interface SendPushNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof SendPushNotificationRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SendPushNotificationRequest
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof SendPushNotificationRequest
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendPushNotificationRequest
     */
    'sound'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendPushNotificationRequest
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendPushNotificationRequest
     */
    'clientApplication'?: SendPushNotificationRequestClientApplicationEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SendPushNotificationRequestClientApplicationEnum {
    Rezen = 'REZEN',
    OneReal = 'ONE_REAL'
}

/**
 * 
 * @export
 * @interface SendPushNotificationTemplateRequest
 */
export interface SendPushNotificationTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof SendPushNotificationTemplateRequest
     */
    'templateName': SendPushNotificationTemplateRequestTemplateNameEnum;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof SendPushNotificationTemplateRequest
     */
    'templateParameters': { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof SendPushNotificationTemplateRequest
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendPushNotificationTemplateRequest
     */
    'sound'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendPushNotificationTemplateRequest
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendPushNotificationTemplateRequest
     */
    'clientApplication': SendPushNotificationTemplateRequestClientApplicationEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SendPushNotificationTemplateRequestTemplateNameEnum {
    Test = 'TEST',
    Welcome = 'WELCOME',
    WelcomeCanada = 'WELCOME_CANADA',
    ApplicationApproved = 'APPLICATION_APPROVED',
    SponseeJustJoined = 'SPONSEE_JUST_JOINED',
    PasswordReset = 'PASSWORD_RESET',
    CommissionValidation = 'COMMISSION_VALIDATION',
    CdaInvoice = 'CDA_INVOICE',
    NudgeTransaction = 'NUDGE_TRANSACTION',
    ClosingDocs = 'CLOSING_DOCS',
    PaymentScheduled = 'PAYMENT_SCHEDULED',
    PaymentScheduledV2 = 'PAYMENT_SCHEDULED_V2',
    MigrationWelcome = 'MIGRATION_WELCOME',
    SignedIca = 'SIGNED_ICA',
    Onboarding = 'ONBOARDING',
    AgentLead = 'AGENT_LEAD',
    CalendarEvent = 'CALENDAR_EVENT',
    PaymentDetailsInvalid = 'PAYMENT_DETAILS_INVALID',
    PostClosingRefund = 'POST_CLOSING_REFUND',
    PostClosingDebt = 'POST_CLOSING_DEBT',
    SignatureSignDocument = 'SIGNATURE_SIGN_DOCUMENT',
    SignatureDocumentSentOnBehalf = 'SIGNATURE_DOCUMENT_SENT_ON_BEHALF',
    SignatureSignDocumentCompleted = 'SIGNATURE_SIGN_DOCUMENT_COMPLETED',
    MortgageBorrowerInvitation = 'MORTGAGE_BORROWER_INVITATION',
    MortgageCoBorrowerInvitation = 'MORTGAGE_CO_BORROWER_INVITATION',
    MortgageApplicationStarted = 'MORTGAGE_APPLICATION_STARTED',
    MortgageApplicationSubmitted = 'MORTGAGE_APPLICATION_SUBMITTED',
    MortgageLoanPreApproved = 'MORTGAGE_LOAN_PRE_APPROVED',
    MortgageBorrowerChecklistItemAssigned = 'MORTGAGE_BORROWER_CHECKLIST_ITEM_ASSIGNED',
    MortgageBorrowerApplicationSubmitted = 'MORTGAGE_BORROWER_APPLICATION_SUBMITTED',
    MortgageBorrowerLoanPreApproved = 'MORTGAGE_BORROWER_LOAN_PRE_APPROVED',
    ApprovedForCommissionDocumentGeneration = 'APPROVED_FOR_COMMISSION_DOCUMENT_GENERATION',
    TransactionHasExpired = 'TRANSACTION_HAS_EXPIRED',
    TransactionIsAboutToClose = 'TRANSACTION_IS_ABOUT_TO_CLOSE',
    TransactionListingHasExpired = 'TRANSACTION_LISTING_HAS_EXPIRED',
    TransactionListingIsAboutToExpire = 'TRANSACTION_LISTING_IS_ABOUT_TO_EXPIRE',
    TransactionWaitForApproveCdCalculation = 'TRANSACTION_WAIT_FOR_APPROVE_CD_CALCULATION',
    TransactionWaitForSendCd = 'TRANSACTION_WAIT_FOR_SEND_CD',
    WealthPlanAgentAttractionReferral = 'WEALTH_PLAN_AGENT_ATTRACTION_REFERRAL',
    WealthPlanAgentAttractionReferrer = 'WEALTH_PLAN_AGENT_ATTRACTION_REFERRER',
    WealthPlanNotification = 'WEALTH_PLAN_NOTIFICATION',
    InviteMemberToTeam = 'INVITE_MEMBER_TO_TEAM',
    InviteBuyerToSignBuyerBrokerAgreement = 'INVITE_BUYER_TO_SIGN_BUYER_BROKER_AGREEMENT',
    VoiceCallRecordingComplete = 'VOICE_CALL_RECORDING_COMPLETE',
    VoiceMailRecordingComplete = 'VOICE_MAIL_RECORDING_COMPLETE',
    VoiceCallRecordingTranscribed = 'VOICE_CALL_RECORDING_TRANSCRIBED',
    VoiceMailRecordingTranscribed = 'VOICE_MAIL_RECORDING_TRANSCRIBED'
}
/**
    * @export
    * @enum {string}
    */
export enum SendPushNotificationTemplateRequestClientApplicationEnum {
    Rezen = 'REZEN',
    OneReal = 'ONE_REAL'
}

/**
 * 
 * @export
 * @interface SendRecipientPushNotificationRequest
 */
export interface SendRecipientPushNotificationRequest {
    /**
     * 
     * @type {Recipient}
     * @memberof SendRecipientPushNotificationRequest
     */
    'recipient': Recipient;
    /**
     * 
     * @type {SendPushNotificationRequest}
     * @memberof SendRecipientPushNotificationRequest
     */
    'sendPushNotificationRequest': SendPushNotificationRequest;
}
/**
 * 
 * @export
 * @interface TestEmailRequest
 */
export interface TestEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof TestEmailRequest
     */
    'senderEmailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof TestEmailRequest
     */
    'senderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestEmailRequest
     */
    'recipientEmailAddress': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TestEmailRequest
     */
    'otherRecipientsEmailAddresses'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TestEmailRequest
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestEmailRequest
     */
    'body'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestEmailRequest
     */
    'mailerPoolName'?: TestEmailRequestMailerPoolNameEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TestEmailRequestMailerPoolNameEnum {
    Primary = 'PRIMARY',
    Secondary = 'SECONDARY',
    Marketing = 'MARKETING'
}

/**
 * 
 * @export
 * @interface UpdateEmailTemplateRequest
 */
export interface UpdateEmailTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailTemplateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailTemplateRequest
     */
    'titleTemplate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailTemplateRequest
     */
    'subjectTemplate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailTemplateRequest
     */
    'bodyTemplate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateEmailTemplateRequest
     */
    'templateParameterNames'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmailTemplateRequest
     */
    'attachmentRequired'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateFailedMessageStatusBatch
 */
export interface UpdateFailedMessageStatusBatch {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'status': UpdateFailedMessageStatusBatchStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateFailedMessageStatusBatchStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface UpdateJoinRealEmailRequest
 */
export interface UpdateJoinRealEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateJoinRealEmailRequest
     */
    'currentJoinRealEmail': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateJoinRealEmailRequest
     */
    'newJoinRealEmail': string;
}
/**
 * 
 * @export
 * @interface UpdateNotificationPreferencesDto
 */
export interface UpdateNotificationPreferencesDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateNotificationPreferencesDto
     */
    'addChannels'?: Array<UpdateNotificationPreferencesDtoAddChannelsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateNotificationPreferencesDto
     */
    'removeChannels'?: Array<UpdateNotificationPreferencesDtoRemoveChannelsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateNotificationPreferencesDtoAddChannelsEnum {
    Push = 'PUSH',
    Email = 'EMAIL'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateNotificationPreferencesDtoRemoveChannelsEnum {
    Push = 'PUSH',
    Email = 'EMAIL'
}

/**
 * 
 * @export
 * @interface UpdateOrCreateByUsernameRequest
 */
export interface UpdateOrCreateByUsernameRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrCreateByUsernameRequest
     */
    'currentJoinRealEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrCreateByUsernameRequest
     */
    'newJoinRealEmail': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrCreateByUsernameRequest
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrCreateByUsernameRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrCreateByUsernameRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrCreateByUsernameRequest
     */
    'userName'?: string;
}
/**
 * 
 * @export
 * @interface UpdatePublisherNotificationPreferencesDto
 */
export interface UpdatePublisherNotificationPreferencesDto {
    /**
     * 
     * @type {RezenObject}
     * @memberof UpdatePublisherNotificationPreferencesDto
     */
    'publisher': RezenObject;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePublisherNotificationPreferencesDto
     */
    'muteChannels'?: Array<UpdatePublisherNotificationPreferencesDtoMuteChannelsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePublisherNotificationPreferencesDto
     */
    'unmuteChannels'?: Array<UpdatePublisherNotificationPreferencesDtoUnmuteChannelsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdatePublisherNotificationPreferencesDtoMuteChannelsEnum {
    Push = 'PUSH',
    Email = 'EMAIL'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdatePublisherNotificationPreferencesDtoUnmuteChannelsEnum {
    Push = 'PUSH',
    Email = 'EMAIL'
}

/**
 * 
 * @export
 * @interface UpdatePushNotificationRegistrationRequest
 */
export interface UpdatePushNotificationRegistrationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePushNotificationRegistrationRequest
     */
    'pushRegistrationId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePushNotificationRegistrationRequest
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePushNotificationRegistrationRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePushNotificationRegistrationRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePushNotificationRegistrationRequest
     */
    'clientApplication': UpdatePushNotificationRegistrationRequestClientApplicationEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdatePushNotificationRegistrationRequestClientApplicationEnum {
    Rezen = 'REZEN',
    OneReal = 'ONE_REAL'
}

/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'emailAddress': string;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'realUsername'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'realEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface WrapperBaseMessage
 */
export interface WrapperBaseMessage {
    /**
     * 
     * @type {BaseMessage}
     * @memberof WrapperBaseMessage
     */
    'message'?: BaseMessage;
    /**
     * 
     * @type {string}
     * @memberof WrapperBaseMessage
     */
    '_class'?: string;
}

/**
 * EmailBlockControllerApi - axios parameter creator
 * @export
 */
export const EmailBlockControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of blocked senders
         * @param {string} recipientEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBlockedSendersByRecipientEmail: async (recipientEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recipientEmail' is not null or undefined
            assertParamExists('getAllBlockedSendersByRecipientEmail', 'recipientEmail', recipientEmail)
            const localVarPath = `/api/v1/emails/blocks/blocked-senders-by-recipient/{recipientEmail}`
                .replace(`{${"recipientEmail"}}`, encodeURIComponent(String(recipientEmail)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unblock blocked email sending by sender and recipient emails
         * @param {string} senderEmail 
         * @param {string} recipientEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unblockBySenderAndRecipient: async (senderEmail: string, recipientEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'senderEmail' is not null or undefined
            assertParamExists('unblockBySenderAndRecipient', 'senderEmail', senderEmail)
            // verify required parameter 'recipientEmail' is not null or undefined
            assertParamExists('unblockBySenderAndRecipient', 'recipientEmail', recipientEmail)
            const localVarPath = `/api/v1/emails/blocks/unblock-by-sender-and-recipient/{senderEmail}/{recipientEmail}`
                .replace(`{${"senderEmail"}}`, encodeURIComponent(String(senderEmail)))
                .replace(`{${"recipientEmail"}}`, encodeURIComponent(String(recipientEmail)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unblock blocked email sending by recipient\'s email
         * @param {string} recipientEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unblockBySenderAndRecipient1: async (recipientEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recipientEmail' is not null or undefined
            assertParamExists('unblockBySenderAndRecipient1', 'recipientEmail', recipientEmail)
            const localVarPath = `/api/v1/emails/blocks/unblock-by-recipient/{recipientEmail}`
                .replace(`{${"recipientEmail"}}`, encodeURIComponent(String(recipientEmail)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailBlockControllerApi - functional programming interface
 * @export
 */
export const EmailBlockControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailBlockControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of blocked senders
         * @param {string} recipientEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBlockedSendersByRecipientEmail(recipientEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockedSenderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBlockedSendersByRecipientEmail(recipientEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unblock blocked email sending by sender and recipient emails
         * @param {string} senderEmail 
         * @param {string} recipientEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unblockBySenderAndRecipient(senderEmail: string, recipientEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unblockBySenderAndRecipient(senderEmail, recipientEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unblock blocked email sending by recipient\'s email
         * @param {string} recipientEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unblockBySenderAndRecipient1(recipientEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unblockBySenderAndRecipient1(recipientEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailBlockControllerApi - factory interface
 * @export
 */
export const EmailBlockControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailBlockControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary List of blocked senders
         * @param {string} recipientEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBlockedSendersByRecipientEmail(recipientEmail: string, options?: any): AxiosPromise<BlockedSenderResponse> {
            return localVarFp.getAllBlockedSendersByRecipientEmail(recipientEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unblock blocked email sending by sender and recipient emails
         * @param {string} senderEmail 
         * @param {string} recipientEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unblockBySenderAndRecipient(senderEmail: string, recipientEmail: string, options?: any): AxiosPromise<void> {
            return localVarFp.unblockBySenderAndRecipient(senderEmail, recipientEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unblock blocked email sending by recipient\'s email
         * @param {string} recipientEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unblockBySenderAndRecipient1(recipientEmail: string, options?: any): AxiosPromise<void> {
            return localVarFp.unblockBySenderAndRecipient1(recipientEmail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailBlockControllerApi - object-oriented interface
 * @export
 * @class EmailBlockControllerApi
 * @extends {BaseAPI}
 */
export class EmailBlockControllerApi extends BaseAPI {
    /**
     * 
     * @summary List of blocked senders
     * @param {string} recipientEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailBlockControllerApi
     */
    public getAllBlockedSendersByRecipientEmail(recipientEmail: string, options?: AxiosRequestConfig) {
        return EmailBlockControllerApiFp(this.configuration).getAllBlockedSendersByRecipientEmail(recipientEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unblock blocked email sending by sender and recipient emails
     * @param {string} senderEmail 
     * @param {string} recipientEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailBlockControllerApi
     */
    public unblockBySenderAndRecipient(senderEmail: string, recipientEmail: string, options?: AxiosRequestConfig) {
        return EmailBlockControllerApiFp(this.configuration).unblockBySenderAndRecipient(senderEmail, recipientEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unblock blocked email sending by recipient\'s email
     * @param {string} recipientEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailBlockControllerApi
     */
    public unblockBySenderAndRecipient1(recipientEmail: string, options?: AxiosRequestConfig) {
        return EmailBlockControllerApiFp(this.configuration).unblockBySenderAndRecipient1(recipientEmail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailCallbacksControllerApi - axios parameter creator
 * @export
 */
export const EmailCallbacksControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Handle a callback from Amazon SES
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAmazonSESCallback: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/emails/callbacks/ses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Handle an email reply callback from SendGrid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmailReplyCallback: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/emails/callbacks/sendgrid/email-reply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Handle a callback from SendGrid
         * @param {string} secretWord 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSendGridCallback: async (secretWord: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'secretWord' is not null or undefined
            assertParamExists('postSendGridCallback', 'secretWord', secretWord)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postSendGridCallback', 'body', body)
            const localVarPath = `/api/v1/emails/callbacks/sendgrid/{secretWord}`
                .replace(`{${"secretWord"}}`, encodeURIComponent(String(secretWord)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailCallbacksControllerApi - functional programming interface
 * @export
 */
export const EmailCallbacksControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailCallbacksControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Handle a callback from Amazon SES
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAmazonSESCallback(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAmazonSESCallback(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Handle an email reply callback from SendGrid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEmailReplyCallback(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEmailReplyCallback(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Handle a callback from SendGrid
         * @param {string} secretWord 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSendGridCallback(secretWord: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSendGridCallback(secretWord, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailCallbacksControllerApi - factory interface
 * @export
 */
export const EmailCallbacksControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailCallbacksControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Handle a callback from Amazon SES
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAmazonSESCallback(options?: any): AxiosPromise<void> {
            return localVarFp.postAmazonSESCallback(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Handle an email reply callback from SendGrid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmailReplyCallback(options?: any): AxiosPromise<void> {
            return localVarFp.postEmailReplyCallback(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Handle a callback from SendGrid
         * @param {string} secretWord 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSendGridCallback(secretWord: string, body: string, options?: any): AxiosPromise<void> {
            return localVarFp.postSendGridCallback(secretWord, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailCallbacksControllerApi - object-oriented interface
 * @export
 * @class EmailCallbacksControllerApi
 * @extends {BaseAPI}
 */
export class EmailCallbacksControllerApi extends BaseAPI {
    /**
     * 
     * @summary Handle a callback from Amazon SES
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailCallbacksControllerApi
     */
    public postAmazonSESCallback(options?: AxiosRequestConfig) {
        return EmailCallbacksControllerApiFp(this.configuration).postAmazonSESCallback(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Handle an email reply callback from SendGrid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailCallbacksControllerApi
     */
    public postEmailReplyCallback(options?: AxiosRequestConfig) {
        return EmailCallbacksControllerApiFp(this.configuration).postEmailReplyCallback(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Handle a callback from SendGrid
     * @param {string} secretWord 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailCallbacksControllerApi
     */
    public postSendGridCallback(secretWord: string, body: string, options?: AxiosRequestConfig) {
        return EmailCallbacksControllerApiFp(this.configuration).postSendGridCallback(secretWord, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailControllerApi - axios parameter creator
 * @export
 */
export const EmailControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check status of logged in user\'s email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMyEmailStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/emails/my/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check email status
         * @param {EmailStatusRequest} emailStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailStatus: async (emailStatusRequest: EmailStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailStatusRequest' is not null or undefined
            assertParamExists('emailStatus', 'emailStatusRequest', emailStatusRequest)
            const localVarPath = `/api/v1/emails/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send test email
         * @param {TestEmailRequest} testEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEmail: async (testEmailRequest: TestEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testEmailRequest' is not null or undefined
            assertParamExists('sendTestEmail', 'testEmailRequest', testEmailRequest)
            const localVarPath = `/api/v1/emails/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailControllerApi - functional programming interface
 * @export
 */
export const EmailControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check status of logged in user\'s email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkMyEmailStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkMyEmailStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check email status
         * @param {EmailStatusRequest} emailStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailStatus(emailStatusRequest: EmailStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailStatus(emailStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send test email
         * @param {TestEmailRequest} testEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendTestEmail(testEmailRequest: TestEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendTestEmail(testEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailControllerApi - factory interface
 * @export
 */
export const EmailControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Check status of logged in user\'s email address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMyEmailStatus(options?: any): AxiosPromise<EmailStatusResponse> {
            return localVarFp.checkMyEmailStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check email status
         * @param {EmailStatusRequest} emailStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailStatus(emailStatusRequest: EmailStatusRequest, options?: any): AxiosPromise<EmailStatusResponse> {
            return localVarFp.emailStatus(emailStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send test email
         * @param {TestEmailRequest} testEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEmail(testEmailRequest: TestEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendTestEmail(testEmailRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailControllerApi - object-oriented interface
 * @export
 * @class EmailControllerApi
 * @extends {BaseAPI}
 */
export class EmailControllerApi extends BaseAPI {
    /**
     * 
     * @summary Check status of logged in user\'s email address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailControllerApi
     */
    public checkMyEmailStatus(options?: AxiosRequestConfig) {
        return EmailControllerApiFp(this.configuration).checkMyEmailStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check email status
     * @param {EmailStatusRequest} emailStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailControllerApi
     */
    public emailStatus(emailStatusRequest: EmailStatusRequest, options?: AxiosRequestConfig) {
        return EmailControllerApiFp(this.configuration).emailStatus(emailStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send test email
     * @param {TestEmailRequest} testEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailControllerApi
     */
    public sendTestEmail(testEmailRequest: TestEmailRequest, options?: AxiosRequestConfig) {
        return EmailControllerApiFp(this.configuration).sendTestEmail(testEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailTemplateControllerApi - axios parameter creator
 * @export
 */
export const EmailTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get All Email Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmailTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/emails/templates/getAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Email Template
         * @param {string} emailTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplate: async (emailTemplateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailTemplateId' is not null or undefined
            assertParamExists('getEmailTemplate', 'emailTemplateId', emailTemplateId)
            const localVarPath = `/api/v1/emails/templates/{emailTemplateId}`
                .replace(`{${"emailTemplateId"}}`, encodeURIComponent(String(emailTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send email using email template
         * @param {SendEmailUsingTemplateRequest} sendEmailUsingTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail: async (sendEmailUsingTemplateRequest: SendEmailUsingTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendEmailUsingTemplateRequest' is not null or undefined
            assertParamExists('sendEmail', 'sendEmailUsingTemplateRequest', sendEmailUsingTemplateRequest)
            const localVarPath = `/api/v1/emails/templates/sendEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendEmailUsingTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send email using email template v2
         * @param {SendEmailUsingTemplateRequestV2} sendEmailUsingTemplateRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailByTemplateEnum: async (sendEmailUsingTemplateRequestV2: SendEmailUsingTemplateRequestV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendEmailUsingTemplateRequestV2' is not null or undefined
            assertParamExists('sendEmailByTemplateEnum', 'sendEmailUsingTemplateRequestV2', sendEmailUsingTemplateRequestV2)
            const localVarPath = `/api/v1/emails/templates/sendEmailByTemplateEnum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendEmailUsingTemplateRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Email Template
         * @param {string} emailTemplateId 
         * @param {UpdateEmailTemplateRequest} updateEmailTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailTemplate: async (emailTemplateId: string, updateEmailTemplateRequest: UpdateEmailTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailTemplateId' is not null or undefined
            assertParamExists('updateEmailTemplate', 'emailTemplateId', emailTemplateId)
            // verify required parameter 'updateEmailTemplateRequest' is not null or undefined
            assertParamExists('updateEmailTemplate', 'updateEmailTemplateRequest', updateEmailTemplateRequest)
            const localVarPath = `/api/v1/emails/templates/{emailTemplateId}`
                .replace(`{${"emailTemplateId"}}`, encodeURIComponent(String(emailTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmailTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailTemplateControllerApi - functional programming interface
 * @export
 */
export const EmailTemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailTemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get All Email Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEmailTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateResponses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEmailTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Email Template
         * @param {string} emailTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailTemplate(emailTemplateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailTemplate(emailTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send email using email template
         * @param {SendEmailUsingTemplateRequest} sendEmailUsingTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmail(sendEmailUsingTemplateRequest: SendEmailUsingTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmail(sendEmailUsingTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send email using email template v2
         * @param {SendEmailUsingTemplateRequestV2} sendEmailUsingTemplateRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailByTemplateEnum(sendEmailUsingTemplateRequestV2: SendEmailUsingTemplateRequestV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailByTemplateEnum(sendEmailUsingTemplateRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Email Template
         * @param {string} emailTemplateId 
         * @param {UpdateEmailTemplateRequest} updateEmailTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailTemplate(emailTemplateId: string, updateEmailTemplateRequest: UpdateEmailTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailTemplate(emailTemplateId, updateEmailTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailTemplateControllerApi - factory interface
 * @export
 */
export const EmailTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailTemplateControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get All Email Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmailTemplates(options?: any): AxiosPromise<EmailTemplateResponses> {
            return localVarFp.getAllEmailTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Email Template
         * @param {string} emailTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplate(emailTemplateId: string, options?: any): AxiosPromise<EmailTemplateResponse> {
            return localVarFp.getEmailTemplate(emailTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send email using email template
         * @param {SendEmailUsingTemplateRequest} sendEmailUsingTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail(sendEmailUsingTemplateRequest: SendEmailUsingTemplateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendEmail(sendEmailUsingTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send email using email template v2
         * @param {SendEmailUsingTemplateRequestV2} sendEmailUsingTemplateRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailByTemplateEnum(sendEmailUsingTemplateRequestV2: SendEmailUsingTemplateRequestV2, options?: any): AxiosPromise<void> {
            return localVarFp.sendEmailByTemplateEnum(sendEmailUsingTemplateRequestV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Email Template
         * @param {string} emailTemplateId 
         * @param {UpdateEmailTemplateRequest} updateEmailTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailTemplate(emailTemplateId: string, updateEmailTemplateRequest: UpdateEmailTemplateRequest, options?: any): AxiosPromise<EmailTemplateResponse> {
            return localVarFp.updateEmailTemplate(emailTemplateId, updateEmailTemplateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailTemplateControllerApi - object-oriented interface
 * @export
 * @class EmailTemplateControllerApi
 * @extends {BaseAPI}
 */
export class EmailTemplateControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get All Email Templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateControllerApi
     */
    public getAllEmailTemplates(options?: AxiosRequestConfig) {
        return EmailTemplateControllerApiFp(this.configuration).getAllEmailTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Email Template
     * @param {string} emailTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateControllerApi
     */
    public getEmailTemplate(emailTemplateId: string, options?: AxiosRequestConfig) {
        return EmailTemplateControllerApiFp(this.configuration).getEmailTemplate(emailTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send email using email template
     * @param {SendEmailUsingTemplateRequest} sendEmailUsingTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateControllerApi
     */
    public sendEmail(sendEmailUsingTemplateRequest: SendEmailUsingTemplateRequest, options?: AxiosRequestConfig) {
        return EmailTemplateControllerApiFp(this.configuration).sendEmail(sendEmailUsingTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send email using email template v2
     * @param {SendEmailUsingTemplateRequestV2} sendEmailUsingTemplateRequestV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateControllerApi
     */
    public sendEmailByTemplateEnum(sendEmailUsingTemplateRequestV2: SendEmailUsingTemplateRequestV2, options?: AxiosRequestConfig) {
        return EmailTemplateControllerApiFp(this.configuration).sendEmailByTemplateEnum(sendEmailUsingTemplateRequestV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Email Template
     * @param {string} emailTemplateId 
     * @param {UpdateEmailTemplateRequest} updateEmailTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateControllerApi
     */
    public updateEmailTemplate(emailTemplateId: string, updateEmailTemplateRequest: UpdateEmailTemplateRequest, options?: AxiosRequestConfig) {
        return EmailTemplateControllerApiFp(this.configuration).updateEmailTemplate(emailTemplateId, updateEmailTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KafkaRetryConsumerControllerApi - axios parameter creator
 * @export
 */
export const KafkaRetryConsumerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus1: async (updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFailedMessageStatusBatch' is not null or undefined
            assertParamExists('batchUpdateFailedMessageStatus1', 'updateFailedMessageStatusBatch', updateFailedMessageStatusBatch)
            const localVarPath = `/api/v1/kafka-retry/consumer/status-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFailedMessageStatusBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages1: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/consumer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = createdAtFrom;
            }

            if (createdAtTo !== undefined) {
                localVarQueryParameter['createdAtTo'] = createdAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage1: async (failedMessageRetryRequest: FailedMessageRetryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageRetryRequest' is not null or undefined
            assertParamExists('retryFailedMessage1', 'failedMessageRetryRequest', failedMessageRetryRequest)
            const localVarPath = `/api/v1/kafka-retry/consumer/retry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageRetryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages1: async (failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessagesCriteriaRequest' is not null or undefined
            assertParamExists('retryFailedMessages1', 'failedMessagesCriteriaRequest', failedMessagesCriteriaRequest)
            const localVarPath = `/api/v1/kafka-retry/consumer/retry-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessagesCriteriaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus1: async (failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageUpdateStatusRequest' is not null or undefined
            assertParamExists('updateFailedMessageStatus1', 'failedMessageUpdateStatusRequest', failedMessageUpdateStatusRequest)
            const localVarPath = `/api/v1/kafka-retry/consumer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageUpdateStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaRetryConsumerControllerApi - functional programming interface
 * @export
 */
export const KafkaRetryConsumerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KafkaRetryConsumerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFailedMessages1(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFailedMessages1(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessage1(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessage1(failedMessageRetryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessages1(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessages1(failedMessagesCriteriaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateFailedMessageStatus1(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFailedMessageStatus1(failedMessageUpdateStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KafkaRetryConsumerControllerApi - factory interface
 * @export
 */
export const KafkaRetryConsumerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KafkaRetryConsumerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: any): AxiosPromise<number> {
            return localVarFp.batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages1(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: any): AxiosPromise<GenericSearchResponseFailedMessage> {
            return localVarFp.getFailedMessages1(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage1(failedMessageRetryRequest: FailedMessageRetryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryFailedMessage1(failedMessageRetryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages1(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: any): AxiosPromise<number> {
            return localVarFp.retryFailedMessages1(failedMessagesCriteriaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus1(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: any): AxiosPromise<FailedMessage> {
            return localVarFp.updateFailedMessageStatus1(failedMessageUpdateStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KafkaRetryConsumerControllerApi - object-oriented interface
 * @export
 * @class KafkaRetryConsumerControllerApi
 * @extends {BaseAPI}
 */
export class KafkaRetryConsumerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Update provided failed messages status
     * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of failed messages
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
     * @param {number} [createdAtFrom] 
     * @param {number} [createdAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public getFailedMessages1(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).getFailedMessages1(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry one message by id
     * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public retryFailedMessage1(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).retryFailedMessage1(failedMessageRetryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry messages by criteria
     * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public retryFailedMessages1(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).retryFailedMessages1(failedMessagesCriteriaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update failed event status
     * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public updateFailedMessageStatus1(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).updateFailedMessageStatus1(failedMessageUpdateStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KafkaRetryProducerControllerApi - axios parameter creator
 * @export
 */
export const KafkaRetryProducerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus: async (updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFailedMessageStatusBatch' is not null or undefined
            assertParamExists('batchUpdateFailedMessageStatus', 'updateFailedMessageStatusBatch', updateFailedMessageStatusBatch)
            const localVarPath = `/api/v1/kafka-retry/producer/status-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFailedMessageStatusBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/producer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = createdAtFrom;
            }

            if (createdAtTo !== undefined) {
                localVarQueryParameter['createdAtTo'] = createdAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage: async (failedMessageRetryRequest: FailedMessageRetryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageRetryRequest' is not null or undefined
            assertParamExists('retryFailedMessage', 'failedMessageRetryRequest', failedMessageRetryRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageRetryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages: async (failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessagesCriteriaRequest' is not null or undefined
            assertParamExists('retryFailedMessages', 'failedMessagesCriteriaRequest', failedMessagesCriteriaRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessagesCriteriaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus: async (failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageUpdateStatusRequest' is not null or undefined
            assertParamExists('updateFailedMessageStatus', 'failedMessageUpdateStatusRequest', failedMessageUpdateStatusRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageUpdateStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - functional programming interface
 * @export
 */
export const KafkaRetryProducerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KafkaRetryProducerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessage(failedMessageRetryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessages(failedMessagesCriteriaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - factory interface
 * @export
 */
export const KafkaRetryProducerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KafkaRetryProducerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: any): AxiosPromise<number> {
            return localVarFp.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: any): AxiosPromise<GenericSearchResponseFailedMessage> {
            return localVarFp.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: any): AxiosPromise<number> {
            return localVarFp.retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: any): AxiosPromise<FailedMessage> {
            return localVarFp.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KafkaRetryProducerControllerApi - object-oriented interface
 * @export
 * @class KafkaRetryProducerControllerApi
 * @extends {BaseAPI}
 */
export class KafkaRetryProducerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Update provided failed messages status
     * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of failed messages
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
     * @param {number} [createdAtFrom] 
     * @param {number} [createdAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry one message by id
     * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry messages by criteria
     * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update failed event status
     * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationControllerApi - axios parameter creator
 * @export
 */
export const NotificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Run email digest job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runDigestJob: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications/digest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a notification to many recipients
         * @param {SendNotificationRq} sendNotificationRq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification: async (sendNotificationRq: SendNotificationRq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendNotificationRq' is not null or undefined
            assertParamExists('sendNotification', 'sendNotificationRq', sendNotificationRq)
            const localVarPath = `/api/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendNotificationRq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationControllerApi - functional programming interface
 * @export
 */
export const NotificationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Run email digest job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runDigestJob(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runDigestJob(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a notification to many recipients
         * @param {SendNotificationRq} sendNotificationRq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotification(sendNotificationRq: SendNotificationRq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotification(sendNotificationRq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationControllerApi - factory interface
 * @export
 */
export const NotificationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Run email digest job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runDigestJob(options?: any): AxiosPromise<void> {
            return localVarFp.runDigestJob(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a notification to many recipients
         * @param {SendNotificationRq} sendNotificationRq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification(sendNotificationRq: SendNotificationRq, options?: any): AxiosPromise<void> {
            return localVarFp.sendNotification(sendNotificationRq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationControllerApi - object-oriented interface
 * @export
 * @class NotificationControllerApi
 * @extends {BaseAPI}
 */
export class NotificationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Run email digest job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public runDigestJob(options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).runDigestJob(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a notification to many recipients
     * @param {SendNotificationRq} sendNotificationRq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public sendNotification(sendNotificationRq: SendNotificationRq, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).sendNotification(sendNotificationRq, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PushNotificationControllerApi - axios parameter creator
 * @export
 */
export const PushNotificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Register a push notification token for a particular user.
         * @param {UpdatePushNotificationRegistrationRequest} updatePushNotificationRegistrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (updatePushNotificationRegistrationRequest: UpdatePushNotificationRegistrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePushNotificationRegistrationRequest' is not null or undefined
            assertParamExists('register', 'updatePushNotificationRegistrationRequest', updatePushNotificationRegistrationRequest)
            const localVarPath = `/api/v1/pushNotifications/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePushNotificationRegistrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a push notification to a recipient
         * @param {SendRecipientPushNotificationRequest} sendRecipientPushNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushNotification: async (sendRecipientPushNotificationRequest: SendRecipientPushNotificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendRecipientPushNotificationRequest' is not null or undefined
            assertParamExists('sendPushNotification', 'sendRecipientPushNotificationRequest', sendRecipientPushNotificationRequest)
            const localVarPath = `/api/v1/pushNotifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendRecipientPushNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a push notification
         * @param {string} realUsername 
         * @param {SendPushNotificationRequest} sendPushNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushNotification1: async (realUsername: string, sendPushNotificationRequest: SendPushNotificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realUsername' is not null or undefined
            assertParamExists('sendPushNotification1', 'realUsername', realUsername)
            // verify required parameter 'sendPushNotificationRequest' is not null or undefined
            assertParamExists('sendPushNotification1', 'sendPushNotificationRequest', sendPushNotificationRequest)
            const localVarPath = `/api/v1/pushNotifications/{realUsername}`
                .replace(`{${"realUsername"}}`, encodeURIComponent(String(realUsername)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendPushNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a push notification using template
         * @param {string} realUsername 
         * @param {SendPushNotificationTemplateRequest} sendPushNotificationTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushNotificationUsingTemplate: async (realUsername: string, sendPushNotificationTemplateRequest: SendPushNotificationTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realUsername' is not null or undefined
            assertParamExists('sendPushNotificationUsingTemplate', 'realUsername', realUsername)
            // verify required parameter 'sendPushNotificationTemplateRequest' is not null or undefined
            assertParamExists('sendPushNotificationUsingTemplate', 'sendPushNotificationTemplateRequest', sendPushNotificationTemplateRequest)
            const localVarPath = `/api/v1/pushNotifications/{realUsername}/with-template`
                .replace(`{${"realUsername"}}`, encodeURIComponent(String(realUsername)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendPushNotificationTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PushNotificationControllerApi - functional programming interface
 * @export
 */
export const PushNotificationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PushNotificationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Register a push notification token for a particular user.
         * @param {UpdatePushNotificationRegistrationRequest} updatePushNotificationRegistrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(updatePushNotificationRegistrationRequest: UpdatePushNotificationRegistrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushNotificationRegistrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(updatePushNotificationRegistrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a push notification to a recipient
         * @param {SendRecipientPushNotificationRequest} sendRecipientPushNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPushNotification(sendRecipientPushNotificationRequest: SendRecipientPushNotificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPushNotification(sendRecipientPushNotificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a push notification
         * @param {string} realUsername 
         * @param {SendPushNotificationRequest} sendPushNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPushNotification1(realUsername: string, sendPushNotificationRequest: SendPushNotificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPushNotification1(realUsername, sendPushNotificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a push notification using template
         * @param {string} realUsername 
         * @param {SendPushNotificationTemplateRequest} sendPushNotificationTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPushNotificationUsingTemplate(realUsername: string, sendPushNotificationTemplateRequest: SendPushNotificationTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPushNotificationUsingTemplate(realUsername, sendPushNotificationTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PushNotificationControllerApi - factory interface
 * @export
 */
export const PushNotificationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PushNotificationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Register a push notification token for a particular user.
         * @param {UpdatePushNotificationRegistrationRequest} updatePushNotificationRegistrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(updatePushNotificationRegistrationRequest: UpdatePushNotificationRegistrationRequest, options?: any): AxiosPromise<PushNotificationRegistrationResponse> {
            return localVarFp.register(updatePushNotificationRegistrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a push notification to a recipient
         * @param {SendRecipientPushNotificationRequest} sendRecipientPushNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushNotification(sendRecipientPushNotificationRequest: SendRecipientPushNotificationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendPushNotification(sendRecipientPushNotificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a push notification
         * @param {string} realUsername 
         * @param {SendPushNotificationRequest} sendPushNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushNotification1(realUsername: string, sendPushNotificationRequest: SendPushNotificationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendPushNotification1(realUsername, sendPushNotificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a push notification using template
         * @param {string} realUsername 
         * @param {SendPushNotificationTemplateRequest} sendPushNotificationTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushNotificationUsingTemplate(realUsername: string, sendPushNotificationTemplateRequest: SendPushNotificationTemplateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendPushNotificationUsingTemplate(realUsername, sendPushNotificationTemplateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PushNotificationControllerApi - object-oriented interface
 * @export
 * @class PushNotificationControllerApi
 * @extends {BaseAPI}
 */
export class PushNotificationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Register a push notification token for a particular user.
     * @param {UpdatePushNotificationRegistrationRequest} updatePushNotificationRegistrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationControllerApi
     */
    public register(updatePushNotificationRegistrationRequest: UpdatePushNotificationRegistrationRequest, options?: AxiosRequestConfig) {
        return PushNotificationControllerApiFp(this.configuration).register(updatePushNotificationRegistrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a push notification to a recipient
     * @param {SendRecipientPushNotificationRequest} sendRecipientPushNotificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationControllerApi
     */
    public sendPushNotification(sendRecipientPushNotificationRequest: SendRecipientPushNotificationRequest, options?: AxiosRequestConfig) {
        return PushNotificationControllerApiFp(this.configuration).sendPushNotification(sendRecipientPushNotificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a push notification
     * @param {string} realUsername 
     * @param {SendPushNotificationRequest} sendPushNotificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationControllerApi
     */
    public sendPushNotification1(realUsername: string, sendPushNotificationRequest: SendPushNotificationRequest, options?: AxiosRequestConfig) {
        return PushNotificationControllerApiFp(this.configuration).sendPushNotification1(realUsername, sendPushNotificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a push notification using template
     * @param {string} realUsername 
     * @param {SendPushNotificationTemplateRequest} sendPushNotificationTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationControllerApi
     */
    public sendPushNotificationUsingTemplate(realUsername: string, sendPushNotificationTemplateRequest: SendPushNotificationTemplateRequest, options?: AxiosRequestConfig) {
        return PushNotificationControllerApiFp(this.configuration).sendPushNotificationUsingTemplate(realUsername, sendPushNotificationTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Register user
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRealUser: async (createUserRequest: CreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('createRealUser', 'createUserRequest', createUserRequest)
            const localVarPath = `/api/v1/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user
         * @param {string} realUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRealUser: async (realUsername: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realUsername' is not null or undefined
            assertParamExists('deleteRealUser', 'realUsername', realUsername)
            const localVarPath = `/api/v1/users/{realUsername}`
                .replace(`{${"realUsername"}}`, encodeURIComponent(String(realUsername)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all deferred notifications for user
         * @param {string} realUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeferredNotificationsByUsername: async (realUsername: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realUsername' is not null or undefined
            assertParamExists('getDeferredNotificationsByUsername', 'realUsername', realUsername)
            const localVarPath = `/api/v1/users/{realUsername}/deferred-notifications`
                .replace(`{${"realUsername"}}`, encodeURIComponent(String(realUsername)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user\'s notification preferences
         * @param {string} realUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationPreferences: async (realUsername: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realUsername' is not null or undefined
            assertParamExists('getNotificationPreferences', 'realUsername', realUsername)
            const localVarPath = `/api/v1/users/{realUsername}/notification-preferences/global`
                .replace(`{${"realUsername"}}`, encodeURIComponent(String(realUsername)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user\'s notification preferences for a given publisher
         * @param {string} realUsername 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} publisherType 
         * @param {string} publisherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherNotificationPreferences: async (realUsername: string, publisherType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', publisherId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realUsername' is not null or undefined
            assertParamExists('getPublisherNotificationPreferences', 'realUsername', realUsername)
            // verify required parameter 'publisherType' is not null or undefined
            assertParamExists('getPublisherNotificationPreferences', 'publisherType', publisherType)
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getPublisherNotificationPreferences', 'publisherId', publisherId)
            const localVarPath = `/api/v1/users/{realUsername}/notification-preferences/publisher`
                .replace(`{${"realUsername"}}`, encodeURIComponent(String(realUsername)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (publisherType !== undefined) {
                localVarQueryParameter['publisherType'] = publisherType;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisherId'] = publisherId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user
         * @param {string} realUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealUser: async (realUsername: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realUsername' is not null or undefined
            assertParamExists('getRealUser', 'realUsername', realUsername)
            const localVarPath = `/api/v1/users/{realUsername}`
                .replace(`{${"realUsername"}}`, encodeURIComponent(String(realUsername)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if email is available
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        isEmailAvailabile: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('isEmailAvailabile', 'email', email)
            const localVarPath = `/api/v1/users/email-available/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if a join real email is available
         * @param {string} joinRealEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isJoinRealEmailAvailable: async (joinRealEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'joinRealEmail' is not null or undefined
            assertParamExists('isJoinRealEmailAvailable', 'joinRealEmail', joinRealEmail)
            const localVarPath = `/api/v1/users/join-real-email-available/{joinRealEmail}`
                .replace(`{${"joinRealEmail"}}`, encodeURIComponent(String(joinRealEmail)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user\'s notification preferences for a given publisher
         * @param {string} realUsername 
         * @param {UpdatePublisherNotificationPreferencesDto} updatePublisherNotificationPreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mutePublisherChannel: async (realUsername: string, updatePublisherNotificationPreferencesDto: UpdatePublisherNotificationPreferencesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realUsername' is not null or undefined
            assertParamExists('mutePublisherChannel', 'realUsername', realUsername)
            // verify required parameter 'updatePublisherNotificationPreferencesDto' is not null or undefined
            assertParamExists('mutePublisherChannel', 'updatePublisherNotificationPreferencesDto', updatePublisherNotificationPreferencesDto)
            const localVarPath = `/api/v1/users/{realUsername}/notification-preferences/publisher`
                .replace(`{${"realUsername"}}`, encodeURIComponent(String(realUsername)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePublisherNotificationPreferencesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user email address
         * @param {ReassignEmailRequest} reassignEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignEmail: async (reassignEmailRequest: ReassignEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reassignEmailRequest' is not null or undefined
            assertParamExists('reassignEmail', 'reassignEmailRequest', reassignEmailRequest)
            const localVarPath = `/api/v1/users/reassign-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reassignEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user email address
         * @param {ReassignEmailByEmailRequest} reassignEmailByEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignEmailByEmail: async (reassignEmailByEmailRequest: ReassignEmailByEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reassignEmailByEmailRequest' is not null or undefined
            assertParamExists('reassignEmailByEmail', 'reassignEmailByEmailRequest', reassignEmailByEmailRequest)
            const localVarPath = `/api/v1/users/reassign-email-by-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reassignEmailByEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user email address
         * @param {ReassignEmailByRealUserNameRequest} reassignEmailByRealUserNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignEmailByRealUserName: async (reassignEmailByRealUserNameRequest: ReassignEmailByRealUserNameRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reassignEmailByRealUserNameRequest' is not null or undefined
            assertParamExists('reassignEmailByRealUserName', 'reassignEmailByRealUserNameRequest', reassignEmailByRealUserNameRequest)
            const localVarPath = `/api/v1/users/reassign-email-by-real-user-name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reassignEmailByRealUserNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resets old email to new one (along with email stats)
         * @param {ResetEmailRequest} resetEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetEmail: async (resetEmailRequest: ResetEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetEmailRequest' is not null or undefined
            assertParamExists('resetEmail', 'resetEmailRequest', resetEmailRequest)
            const localVarPath = `/api/v1/users/reset-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unblock user email by resetting bounce and spam counts
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unblockUser: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('unblockUser', 'email', email)
            const localVarPath = `/api/v1/users/unblock/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user\'s global notification preferences that are applied to all publishers
         * @param {string} realUsername 
         * @param {UpdateNotificationPreferencesDto} updateNotificationPreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGlobalNotificationPreferences: async (realUsername: string, updateNotificationPreferencesDto: UpdateNotificationPreferencesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realUsername' is not null or undefined
            assertParamExists('updateGlobalNotificationPreferences', 'realUsername', realUsername)
            // verify required parameter 'updateNotificationPreferencesDto' is not null or undefined
            assertParamExists('updateGlobalNotificationPreferences', 'updateNotificationPreferencesDto', updateNotificationPreferencesDto)
            const localVarPath = `/api/v1/users/{realUsername}/notification-preferences/global`
                .replace(`{${"realUsername"}}`, encodeURIComponent(String(realUsername)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationPreferencesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update join real email -- DEPRECATED -- use POST /username instead
         * @param {UpdateJoinRealEmailRequest} updateJoinRealEmailRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateJoinRealEmail: async (updateJoinRealEmailRequest: UpdateJoinRealEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateJoinRealEmailRequest' is not null or undefined
            assertParamExists('updateJoinRealEmail', 'updateJoinRealEmailRequest', updateJoinRealEmailRequest)
            const localVarPath = `/api/v1/users/join-real-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateJoinRealEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user\'s realUsername or create a new user if none exists
         * @param {UpdateOrCreateByUsernameRequest} updateOrCreateByUsernameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrCreateByUsername: async (updateOrCreateByUsernameRequest: UpdateOrCreateByUsernameRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOrCreateByUsernameRequest' is not null or undefined
            assertParamExists('updateOrCreateByUsername', 'updateOrCreateByUsernameRequest', updateOrCreateByUsernameRequest)
            const localVarPath = `/api/v1/users/username`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrCreateByUsernameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user
         * @param {string} realUsername 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRealUser: async (realUsername: string, updateUserRequest: UpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realUsername' is not null or undefined
            assertParamExists('updateRealUser', 'realUsername', realUsername)
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('updateRealUser', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/api/v1/users/{realUsername}`
                .replace(`{${"realUsername"}}`, encodeURIComponent(String(realUsername)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if email is available
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('validateEmail', 'email', email)
            const localVarPath = `/api/v1/users/validate-email/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Register user
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRealUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRealUser(createUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user
         * @param {string} realUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRealUser(realUsername: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRealUser(realUsername, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all deferred notifications for user
         * @param {string} realUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeferredNotificationsByUsername(realUsername: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeferredNotificationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeferredNotificationsByUsername(realUsername, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user\'s notification preferences
         * @param {string} realUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationPreferences(realUsername: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalNotificationPreferencesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationPreferences(realUsername, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user\'s notification preferences for a given publisher
         * @param {string} realUsername 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} publisherType 
         * @param {string} publisherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublisherNotificationPreferences(realUsername: string, publisherType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', publisherId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherNotificationPreferencesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublisherNotificationPreferences(realUsername, publisherType, publisherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user
         * @param {string} realUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRealUser(realUsername: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRealUser(realUsername, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if email is available
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async isEmailAvailabile(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isEmailAvailabile(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if a join real email is available
         * @param {string} joinRealEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isJoinRealEmailAvailable(joinRealEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isJoinRealEmailAvailable(joinRealEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user\'s notification preferences for a given publisher
         * @param {string} realUsername 
         * @param {UpdatePublisherNotificationPreferencesDto} updatePublisherNotificationPreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mutePublisherChannel(realUsername: string, updatePublisherNotificationPreferencesDto: UpdatePublisherNotificationPreferencesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherNotificationPreferencesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mutePublisherChannel(realUsername, updatePublisherNotificationPreferencesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user email address
         * @param {ReassignEmailRequest} reassignEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reassignEmail(reassignEmailRequest: ReassignEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reassignEmail(reassignEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user email address
         * @param {ReassignEmailByEmailRequest} reassignEmailByEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reassignEmailByEmail(reassignEmailByEmailRequest: ReassignEmailByEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reassignEmailByEmail(reassignEmailByEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user email address
         * @param {ReassignEmailByRealUserNameRequest} reassignEmailByRealUserNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reassignEmailByRealUserName(reassignEmailByRealUserNameRequest: ReassignEmailByRealUserNameRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reassignEmailByRealUserName(reassignEmailByRealUserNameRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resets old email to new one (along with email stats)
         * @param {ResetEmailRequest} resetEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetEmail(resetEmailRequest: ResetEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetEmail(resetEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unblock user email by resetting bounce and spam counts
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unblockUser(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unblockUser(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user\'s global notification preferences that are applied to all publishers
         * @param {string} realUsername 
         * @param {UpdateNotificationPreferencesDto} updateNotificationPreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGlobalNotificationPreferences(realUsername: string, updateNotificationPreferencesDto: UpdateNotificationPreferencesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalNotificationPreferencesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGlobalNotificationPreferences(realUsername, updateNotificationPreferencesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update join real email -- DEPRECATED -- use POST /username instead
         * @param {UpdateJoinRealEmailRequest} updateJoinRealEmailRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateJoinRealEmail(updateJoinRealEmailRequest: UpdateJoinRealEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJoinRealEmail(updateJoinRealEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a user\'s realUsername or create a new user if none exists
         * @param {UpdateOrCreateByUsernameRequest} updateOrCreateByUsernameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrCreateByUsername(updateOrCreateByUsernameRequest: UpdateOrCreateByUsernameRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrCreateByUsername(updateOrCreateByUsernameRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user
         * @param {string} realUsername 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRealUser(realUsername: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRealUser(realUsername, updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if email is available
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateEmail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailAvailabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Register user
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRealUser(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.createRealUser(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user
         * @param {string} realUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRealUser(realUsername: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRealUser(realUsername, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all deferred notifications for user
         * @param {string} realUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeferredNotificationsByUsername(realUsername: string, options?: any): AxiosPromise<Array<DeferredNotificationDto>> {
            return localVarFp.getDeferredNotificationsByUsername(realUsername, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user\'s notification preferences
         * @param {string} realUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationPreferences(realUsername: string, options?: any): AxiosPromise<GlobalNotificationPreferencesDto> {
            return localVarFp.getNotificationPreferences(realUsername, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user\'s notification preferences for a given publisher
         * @param {string} realUsername 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} publisherType 
         * @param {string} publisherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherNotificationPreferences(realUsername: string, publisherType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', publisherId: string, options?: any): AxiosPromise<PublisherNotificationPreferencesDto> {
            return localVarFp.getPublisherNotificationPreferences(realUsername, publisherType, publisherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user
         * @param {string} realUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealUser(realUsername: string, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getRealUser(realUsername, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if email is available
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        isEmailAvailabile(email: string, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.isEmailAvailabile(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if a join real email is available
         * @param {string} joinRealEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isJoinRealEmailAvailable(joinRealEmail: string, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.isJoinRealEmailAvailable(joinRealEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user\'s notification preferences for a given publisher
         * @param {string} realUsername 
         * @param {UpdatePublisherNotificationPreferencesDto} updatePublisherNotificationPreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mutePublisherChannel(realUsername: string, updatePublisherNotificationPreferencesDto: UpdatePublisherNotificationPreferencesDto, options?: any): AxiosPromise<PublisherNotificationPreferencesDto> {
            return localVarFp.mutePublisherChannel(realUsername, updatePublisherNotificationPreferencesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user email address
         * @param {ReassignEmailRequest} reassignEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignEmail(reassignEmailRequest: ReassignEmailRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.reassignEmail(reassignEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user email address
         * @param {ReassignEmailByEmailRequest} reassignEmailByEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignEmailByEmail(reassignEmailByEmailRequest: ReassignEmailByEmailRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.reassignEmailByEmail(reassignEmailByEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user email address
         * @param {ReassignEmailByRealUserNameRequest} reassignEmailByRealUserNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignEmailByRealUserName(reassignEmailByRealUserNameRequest: ReassignEmailByRealUserNameRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.reassignEmailByRealUserName(reassignEmailByRealUserNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resets old email to new one (along with email stats)
         * @param {ResetEmailRequest} resetEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetEmail(resetEmailRequest: ResetEmailRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.resetEmail(resetEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unblock user email by resetting bounce and spam counts
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unblockUser(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.unblockUser(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user\'s global notification preferences that are applied to all publishers
         * @param {string} realUsername 
         * @param {UpdateNotificationPreferencesDto} updateNotificationPreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGlobalNotificationPreferences(realUsername: string, updateNotificationPreferencesDto: UpdateNotificationPreferencesDto, options?: any): AxiosPromise<GlobalNotificationPreferencesDto> {
            return localVarFp.updateGlobalNotificationPreferences(realUsername, updateNotificationPreferencesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update join real email -- DEPRECATED -- use POST /username instead
         * @param {UpdateJoinRealEmailRequest} updateJoinRealEmailRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateJoinRealEmail(updateJoinRealEmailRequest: UpdateJoinRealEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateJoinRealEmail(updateJoinRealEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user\'s realUsername or create a new user if none exists
         * @param {UpdateOrCreateByUsernameRequest} updateOrCreateByUsernameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrCreateByUsername(updateOrCreateByUsernameRequest: UpdateOrCreateByUsernameRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateOrCreateByUsername(updateOrCreateByUsernameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user
         * @param {string} realUsername 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRealUser(realUsername: string, updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateRealUser(realUsername, updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if email is available
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmail(email: string, options?: any): AxiosPromise<EmailAvailabilityResponse> {
            return localVarFp.validateEmail(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @summary Register user
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public createRealUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).createRealUser(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user
     * @param {string} realUsername 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteRealUser(realUsername: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).deleteRealUser(realUsername, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all deferred notifications for user
     * @param {string} realUsername 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getDeferredNotificationsByUsername(realUsername: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getDeferredNotificationsByUsername(realUsername, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user\'s notification preferences
     * @param {string} realUsername 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getNotificationPreferences(realUsername: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getNotificationPreferences(realUsername, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user\'s notification preferences for a given publisher
     * @param {string} realUsername 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} publisherType 
     * @param {string} publisherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getPublisherNotificationPreferences(realUsername: string, publisherType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', publisherId: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getPublisherNotificationPreferences(realUsername, publisherType, publisherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user
     * @param {string} realUsername 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getRealUser(realUsername: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getRealUser(realUsername, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if email is available
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public isEmailAvailabile(email: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).isEmailAvailabile(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if a join real email is available
     * @param {string} joinRealEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public isJoinRealEmailAvailable(joinRealEmail: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).isJoinRealEmailAvailable(joinRealEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user\'s notification preferences for a given publisher
     * @param {string} realUsername 
     * @param {UpdatePublisherNotificationPreferencesDto} updatePublisherNotificationPreferencesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public mutePublisherChannel(realUsername: string, updatePublisherNotificationPreferencesDto: UpdatePublisherNotificationPreferencesDto, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).mutePublisherChannel(realUsername, updatePublisherNotificationPreferencesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user email address
     * @param {ReassignEmailRequest} reassignEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public reassignEmail(reassignEmailRequest: ReassignEmailRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).reassignEmail(reassignEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user email address
     * @param {ReassignEmailByEmailRequest} reassignEmailByEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public reassignEmailByEmail(reassignEmailByEmailRequest: ReassignEmailByEmailRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).reassignEmailByEmail(reassignEmailByEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user email address
     * @param {ReassignEmailByRealUserNameRequest} reassignEmailByRealUserNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public reassignEmailByRealUserName(reassignEmailByRealUserNameRequest: ReassignEmailByRealUserNameRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).reassignEmailByRealUserName(reassignEmailByRealUserNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resets old email to new one (along with email stats)
     * @param {ResetEmailRequest} resetEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public resetEmail(resetEmailRequest: ResetEmailRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).resetEmail(resetEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unblock user email by resetting bounce and spam counts
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public unblockUser(email: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).unblockUser(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user\'s global notification preferences that are applied to all publishers
     * @param {string} realUsername 
     * @param {UpdateNotificationPreferencesDto} updateNotificationPreferencesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateGlobalNotificationPreferences(realUsername: string, updateNotificationPreferencesDto: UpdateNotificationPreferencesDto, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updateGlobalNotificationPreferences(realUsername, updateNotificationPreferencesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update join real email -- DEPRECATED -- use POST /username instead
     * @param {UpdateJoinRealEmailRequest} updateJoinRealEmailRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateJoinRealEmail(updateJoinRealEmailRequest: UpdateJoinRealEmailRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updateJoinRealEmail(updateJoinRealEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user\'s realUsername or create a new user if none exists
     * @param {UpdateOrCreateByUsernameRequest} updateOrCreateByUsernameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateOrCreateByUsername(updateOrCreateByUsernameRequest: UpdateOrCreateByUsernameRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updateOrCreateByUsername(updateOrCreateByUsernameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user
     * @param {string} realUsername 
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateRealUser(realUsername: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updateRealUser(realUsername, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if email is available
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public validateEmail(email: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).validateEmail(email, options).then((request) => request(this.axios, this.basePath));
    }
}


