import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps } from '../types';
import FormErrorMessage from './FormErrorMessage';
import CustomColorPickerInput, {
  CustomColorPickerInputSelectOption,
} from './CustomColorPickerInput';

export interface ControlledColorSelectorInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  options: CustomColorPickerInputSelectOption[];
  disabled?: boolean;
}

const ControlledColorSelectorInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  options,
  disabled = false,
  tooltipIndex,
  shouldUnregister = true,
  ...rest
}: ControlledColorSelectorInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      {...rest}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <div className='w-full'>
          <div className='flex flex-col space-y-2'>
            {label && <label htmlFor={name}>{label}</label>}
            <div
              className='flex flex-row items-center space-x-1 md:space-x-3 w-full scrollbar overflow-x-auto'
              data-tooltip-index={tooltipIndex}
            >
              {options.map((option) => (
                <CustomColorPickerInput
                  key={option.label}
                  isActive={option.value === value}
                  item={option}
                  onChange={onChange}
                  disabled={disabled}
                />
              ))}
            </div>
          </div>
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledColorSelectorInput;
