import { KBarProvider } from 'kbar';
import { debounce } from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/css/index.css';
import { saveBreakRoomType, saveSidebarToOpen } from '../../slices/KBarSlice';
import { saveIsLeoModalVisible } from '../../slices/LeoSlice';
import { RootState } from '../../types';
import CommandBar from './CommandBar';

export interface KBarProps {}

const KBar: React.FC<KBarProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { isLeoModalVisible } = useSelector((state: RootState) => state.leo);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedResetSidebarToOpen = useCallback(
    debounce(async () => {
      dispatch(saveSidebarToOpen(undefined));
    }, 100),
    [],
  );

  return (
    <KBarProvider
      actions={[]}
      options={{
        callbacks: {
          onSelectAction: (action) => {
            if (action.id !== 'playAGame') {
              dispatch(saveBreakRoomType(undefined));
            }
            if (isLeoModalVisible && action.id !== 'askLeo') {
              dispatch(saveIsLeoModalVisible(false));
            }
            debouncedResetSidebarToOpen();
          },
        },
      }}
    >
      <CommandBar />
      {children}
    </KBarProvider>
  );
};

export default KBar;
