import React from 'react';
import { MoneyValue } from '../../openapi/arrakis/api';
import { displayAmount } from '../../utils/CurrencyUtils';
import { MoneyValueCurrencyEnum } from '../../openapi/yenta';
import T4AField from './T4AField';

interface T4AFormMidProps {
  netCommissionPaid: MoneyValue;
  equityContributionsPaid: MoneyValue;
  einNumber: string | undefined;
  sinNumber: string | undefined;
  businessNumber: string | undefined;
  releasedEquityMatch: MoneyValue;
  releaseAwardsEquity: MoneyValue;
}

const T4AFormMid: React.FC<T4AFormMidProps> = ({
  netCommissionPaid,
  equityContributionsPaid,
  sinNumber,
  businessNumber,
  einNumber,
  releaseAwardsEquity,
  releasedEquityMatch,
}) => {
  const totalCommissionsPaid =
    (netCommissionPaid?.amount! || 0) +
    (equityContributionsPaid?.amount! || 0) +
    (releaseAwardsEquity?.amount! || 0) +
    (releasedEquityMatch?.amount! || 0);

  return (
    <div className='relative flex justify-between items-start space-x-3'>
      <div className='flex-1 space-y-2'>
        <div className='flex'>
          <T4AField
            labelNumber='061'
            primaryText={
              <>
                <p className='text-xs'>
                  Payer&apos;s program account number / Numéro de compte de
                  programme du payeur
                </p>
                <p>{einNumber}</p>
              </>
            }
            primaryFieldColor='grey'
            hideSecondaryField
          />
        </div>
        <div className='flex items-end space-x-3'>
          <div className='max-w-md flex-1'>
            <T4AField
              labelNumber='012'
              label={
                <div>
                  <p className='text-center'>Social insurance number</p>
                  <p className='text-center'>Numéro d&apos;assurance sociale</p>
                </div>
              }
              primaryText={sinNumber}
              hideSecondaryField
            />
          </div>
          <div className='max-w-md flex-1'>
            <T4AField
              labelNumber='013'
              label={
                <div>
                  <p className='text-center'>
                    Recipient&apos;s program account number
                  </p>
                  <p className='text-center'>Numéro de compte de programme</p>
                  <p className='text-center'>du bénéficiaire</p>
                </div>
              }
              primaryText={businessNumber}
              hideSecondaryField
            />
          </div>
        </div>
      </div>
      <div className='flex-1 space-y-2 relative -top-3'>
        <div className='flex items-end space-x-2'>
          <div className='max-w-md flex-1'>
            <T4AField
              labelNumber='016'
              label={
                <div>
                  <p className='text-center'>
                    Pension or superannuation – line 11500
                  </p>
                  <p className='text-center'>
                    Prestations de retraite ou autres
                  </p>
                  <p className='text-center'>pensions – ligne 11500</p>
                </div>
              }
            />
          </div>
          <div className='max-w-md flex-1'>
            <T4AField
              labelNumber='022'
              label={
                <div>
                  <p className='text-center'>
                    Income tax deducted – line 43700
                  </p>
                  <p className='text-center'>
                    Impôt sur le revenu retenu – ligne 43700
                  </p>
                </div>
              }
            />
          </div>
        </div>
        <div className='flex items-end space-x-2'>
          <div className='max-w-md flex-1'>
            <T4AField
              labelNumber='018'
              label={
                <div>
                  <p className='text-center'>Lump-sum payments – line 13000</p>
                  <p className='text-center'>
                    Paiements forfaitaires – ligne 13000
                  </p>
                </div>
              }
            />
          </div>
          <div className='max-w-md flex-1'>
            <T4AField
              labelNumber='020'
              label={
                <div>
                  <p className='text-center'>Self-employed commissions</p>
                  <p className='text-center'>
                    Commissions d&apos;un travail indépendant
                  </p>
                </div>
              }
              primaryText={displayAmount({
                amount: totalCommissionsPaid,
                currency: MoneyValueCurrencyEnum.Cad,
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default T4AFormMid;
