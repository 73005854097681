import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';
import { AgentDebtResponse } from '../../openapi/arrakis';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenAvatar from '../Zen/ZenAvatar';
import useAgentsInfo from '../../hooks/useAgentsInfo';
import { RootState } from '../../types';
import ResourceContainer from '../ResourceContainer';
import { getFullName } from '../../utils/AgentHelper';
import { getFormattedDate } from '../../utils/DateUtils';
import AdminOnly from '../auth/AdminOnly';

const Hover: React.FC<{
  hoverComponent: React.ReactNode;
}> = ({ hoverComponent, children }) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ trigger: 'hover', placement: 'auto' });

  return (
    <div ref={setTriggerRef}>
      <AdminOnly>
        {visible && (
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className: 'tooltip-container !border-zen-dark-5 !rounded-3xl',
            })}
          >
            <div
              {...getArrowProps({
                className: 'tooltip-arrow !border-zen-dark-5',
              })}
            />
            <div className='-m-2 p-1'>{hoverComponent}</div>
          </div>
        )}
      </AdminOnly>
      {children}
    </div>
  );
};

interface DebtTypeColProps {
  agentDebt: AgentDebtResponse;
}

const DebtTypeCol: React.FC<DebtTypeColProps> = ({ agentDebt }) => {
  const { agentById } = useSelector((state: RootState) => state.userIds);

  const agentIds = useMemo(() => {
    const ids = [];
    if (agentDebt.updatedBy) {
      ids.push(agentDebt.updatedBy);
    }
    return ids;
  }, [agentDebt.updatedBy]);

  const { loading } = useAgentsInfo(agentIds);

  if (!agentDebt.debtType) {
    return <p className='text-zen-dark-9 font-body'>N/A</p>;
  }

  const agentInfo = agentById[agentDebt.updatedBy!];
  const agentName = getFullName(agentInfo) || 'N/A';

  return (
    <Hover
      hoverComponent={
        <div className='p-2.5 font-zen-body text-zen-dark-9'>
          <p className='font-zen-title font-normal text-lg'>Debt History</p>
          <p className='mt-1.5 font-semibold text-sm'>Updated By</p>
          <ResourceContainer
            loading={loading}
            isEmpty={false}
            resourceName='agent info'
          >
            <div className='mt-1.5 font-normal flex items-center'>
              <ZenAvatar
                name={agentName}
                imageUrl={agentInfo?.avatar}
                size='xs'
              />
              <p className='ml-1 flex items-center space-x-1 whitespace-nowrap'>
                <span>{agentName}</span>
                <span className='text-primary-blue'>
                  ({agentInfo?.emailAddress})
                </span>
              </p>
              <p className='ml-8 text-zen-dark-7'>
                {getFormattedDate(agentDebt.updatedAt!)}
              </p>
            </div>
          </ResourceContainer>
        </div>
      }
    >
      <p className='max-w-min bg-grey-100 text-zen-dark-9 text-sm font-zen-body font-semibold px-3 py-1.5 rounded-full whitespace-pre uppercase'>
        {capitalizeEnum(agentDebt.debtType)}
      </p>
    </Hover>
  );
};

export default DebtTypeCol;
