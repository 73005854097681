import { useSelector } from 'react-redux';
import { RootState } from '../../types';
import { getSupportEmail } from '../../utils/SupportUtils';
import config from '../../config';

interface CAPoliciesAndProcedureFormProps {}

const CAPoliciesAndProcedureForm: React.FC<CAPoliciesAndProcedureFormProps> = () => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  return (
    <div className='mt-20'>
      <div>
        <p className='font-primary-medium text-2xl text-center'>
          THE REAL BROKERAGE, INC
        </p>
        <p className='font-primary-medium text-lg text-center'>
          CANADIAN NATIONAL POLICIES AND PROCEDURES MANUAL
        </p>
        <div className='mt-5'>
          <p className='font-bold text-violet-700'>INTRODUCTION:</p>
          <p>
            This Policies and Procedure Manual is part of the Independent
            Contractor’s Agreement signed by each licensee or “Agent” with The
            Real Brokerage, Inc or any of its entities, collectively referred
            herein as “Company” or “Real”.
          </p>
          <p className='mt-2'>
            As an innovative real estate brokerage, REAL is dedicated to the
            highest standards of professionalism and service in order to surpass
            customer expectations. Real agents are committed to ensuring that
            the public, customers, clients and fellow real estate agents are
            treated in an honest, fair and professional manner at all times. Our
            policies and procedure manual outlines key issues important for our
            agents’ operations.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>CORE VALUES:</p>
          <div className='lg:ml-10 ml-5'>
            <div className='mt-2'>
              <p className='font-primary-medium'>Work Hard. Be Kind.</p>
              <p className='lg:ml-10 ml-5'>
                Be disciplined in the practice of professional skills and
                knowledge. Kind as you create a reputation in your dealings with
                others.
              </p>
            </div>
            <div className='mt-2'>
              <p className='font-primary-medium'>Simply Great Service.</p>
              <p className='lg:ml-10 ml-5'>
                No excuses. Work to implement repeatable business practices and
                professional interaction that sets up consistent successful
                outcomes.
              </p>
            </div>
            <div className='mt-2'>
              <p className='font-primary-medium'>Embrace. Resolve. Evolve.</p>
              <p className='lg:ml-10 ml-5'>
                Embrace where you are. Resolve to Improve. Evolve into the
                ideal.
              </p>
            </div>
            <div className='mt-2'>
              <p className='font-primary-medium'>Make a Difference.</p>
              <p className='lg:ml-10 ml-5'>
                Make a difference in yourself first. If you must be hard, be
                hard with yourself, then extend understanding, consideration and
                kindness to others. Be mindful of the situations of others and
                extend help where you can.
              </p>
            </div>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>POLICY:</p>
          <p>
            Real strives to improve the professional skills of our employees and
            agents, in order to create a brand associated with excellence and
            dedicated to the highest level of customer service.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>PROCEDURES:</p>
          <p>
            Real agents are expected to be legal, ethical and professional in
            their business practices. To that end, agents will comply with all
            items enumerated in this document. Company reserves the right to
            terminate an agent who is shown to have violated any of the terms of
            this manual.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            PROVINCE SPECIFIC ADDENDUM
          </p>
          <p>
            Real will implement its policies and procedures in a national and
            province specific format. This document addresses those policies and
            procedures which are applicable on a national scale. Subjects
            requiring a province specific approach will be contained within the
            province specific policies and procedures addendum. An agent must be
            familiar with both the national and province specific policies and
            procedures documents. Should there be a conflict between the
            documents, the province specific addendum will supersede the
            national policy and procedures manual.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>DEFINITIONS:</p>
          <p>
            <span className='font-primary-medium'>Agent(s) </span>- A licensed
            real estate professional who signed an Independent Contractor’s
            Agreement with The Real Brokerage, Inc. or one of its entities.
          </p>
          <p className='mt-2'>
            <span className='font-primary-medium'>Company </span>- The Real
            Brokerage, Inc. or one of its entities with which the agent is
            affiliated to conduct real estate activities.
          </p>
          <p className='mt-2'>
            <span className='font-primary-medium'>Client(s) </span>- Person(s),
            to whom an agent owes a fiduciary duty; (i.e. sellers, buyers,
            landlords, tenants, etc.)
          </p>
          <div className='mt-2'>
            <span className='font-primary-medium'>Fiduciary Duty </span>- Duties
            due the client in a real estate transaction, to include:
            <div className='lg:ml-10 ml-5'>
              <p className='mt-2'>
                <span className='font-primary-medium'>Loyalty </span>- Agent
                must act in the best interests of the client to the exclusion of
                all other interests.
              </p>
              <p className='mt-2'>
                <span className='font-primary-medium'>Obedience </span>- Agent
                agrees to promptly obey and execute all the lawful instructions
                from the client.
              </p>
              <p className='mt-2'>
                <span className='font-primary-medium'>Disclosure </span>- The
                fiduciary responsibilities require an agent to disclose any and
                all relevant and material information obtained to the client.
              </p>
              <p className='mt-2'>
                <span className='font-primary-medium'>Confidentiality </span>-
                The Agent must keep in confidence all information provided by
                the client, excepting that which the client gives permission to
                disclose. This duty lives on after the transaction closes or the
                agency relationship is terminated
              </p>
              <p className='mt-2'>
                <span className='font-primary-medium'>
                  Reasonable Care and Diligence{' '}
                </span>
                - Agent must perform all duties with the care and diligence
                which may be reasonably expected of someone undertaking duties
                of a competent real estate professional.
              </p>
              <p className='mt-2'>
                <span className='font-primary-medium'>Full Accounting </span>-
                Agents are obligated to account for all money or property the
                client has entrusted to them.
              </p>
            </div>
            <p className='mt-2'>
              <span className='font-primary-medium'>Provincial Broker </span>- A
              Broker responsible for the supervision and training of agents
              within the scope of the Independent Contractor Agreement and the
              Agent Policy and Procedure Manual.
            </p>
            <p className='mt-2'>
              <span className='font-primary-medium'>Transaction </span>- Listing
              or Buyer representation that results in a closed transaction for a
              property.
            </p>
            <p className='mt-2'>
              <span className='font-primary-medium'>Material Fact </span>-
              Information that might influence a buyer in making a decision
              regarding entering into or remaining in a purchase contract, or
              the price paid or received for property.
            </p>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>CONDUCT OF AGENT</p>
          <p className='font-primary-medium'>Agent Safety</p>
          <div>
            It is critically important that an agent be aware of safety risks
            inherent in the real estate industry. Please review the following
            guidelines you should follow in your day to day activities:
            <ul className='lg:ml-10 ml-5'>
              <li>
                1. If you do not know a customer try to arrange a meeting at a
                public place.
              </li>
              <li>
                2. Never meet a prospect at a vacant house alone. Always take
                another person with you. Do not meet the prospect after dark.
              </li>
              <li>
                3. Always let the office or someone at your home know where you
                will be showing property, especially to prospects you are
                meeting for the first time.
              </li>
              <li>
                4. When showing a property, do not go to dark areas, basements,
                garages, or areas without multiple exits. Allow the prospect to
                view those areas on their own and you stay in an area that
                allows for a quick exit.
              </li>
              <li>
                5. Always drive your own car. Do not let a prospect you do not
                know drive your car. Preferably, drive separate cars to the
                showing.
              </li>
            </ul>
          </div>
        </div>

        <div className='mt-2'>
          <p className='font-primary-medium'>Professionalism</p>
          <p>
            Agents at Real will conduct themselves in a professional and ethical
            manner at all times. This includes agent’s commitment to
            enthusiastically exemplify business expertise, honesty, kindness,
            trustworthiness, helpfulness, and a courteous character.
          </p>
        </div>

        <div className='mt-2'>
          <p className='font-primary-medium'>CREA REALTOR® Code of Ethics</p>
          <p>
            All agents will comply with the CREA REALTOR Code and be in good
            standing.
          </p>
        </div>

        <div className='mt-2'>
          <p className='font-primary-medium'>Core Values</p>
          <p>An agent should be in alignment with the Core Values of Real:</p>
          <ul className='text-sm'>
            <li>Work Hard. Be Kind</li>
            <li>Simply Great Service</li>
            <li>Embrace. Resolve. Evolve</li>
            <li>Making a Difference</li>
          </ul>
        </div>

        <div className='mt-2'>
          <p className='font-primary-medium'>Agent Communications</p>
          <p>
            Agent communications, including emails, texts, phone calls, or
            social media posts and comments shall remain professional and should
            never be disparaging, aggressive, rude, insulting, or inappropriate.
          </p>
        </div>

        <div className='mt-2'>
          <p className='font-primary-medium'>Discrimination</p>
          <p>
            Company does not discriminate, does not allow any associated parties
            to discriminate and will not tolerate discrimination. Company will
            not tolerate discrimination directed towards any individual or
            group, employee, contractor, licensed agent, or consumer. Agents may
            not engage in the unfair or unequal treatment of an individual or
            group based on sex, color, religion, race, origin, disability,
            sexual orientation or familial status. Agents must assist all
            customers and clients in meeting their real estate needs.
          </p>
        </div>

        <div className='mt-2'>
          <p className='font-primary-medium'>Hate Speech</p>
          <p>
            Abusive or threatening speech or writing that expresses prejudice
            against a particular group, especially on the basis of race,
            religion, or sexual orientation, will not be tolerated and will be
            grounds for immediate termination.
          </p>
        </div>

        <div className='mt-2'>
          <p className='font-primary-medium'>Harassment</p>
          <p>
            Company will not tolerate harassment of any kind, whether verbal,
            physical or sexual in nature. All agents must avoid offensive or
            inappropriate behavior. If an agent feels that they have been
            harassed in any way, they shall immediately notify Human Resources
            at{' '}
            <a
              href='mailto:HR@therealbrokerage.com'
              className='text-primary underline'
            >
              HR@therealbrokerage.com
            </a>{' '}
            or the VP of Brokerage Operations at{' '}
            <a
              href='mailto:operations@therealbrokerage.com'
              className='text-primary underline'
            >
              {' '}
              operations@therealbrokerage.com.
            </a>{' '}
            An investigation will be made into the allegations, including
            interviews with all relevant persons. If it is found that an agent
            has violated this policy, it will be grounds for immediate
            separation from the Company.
          </p>
          <div className='mt-2'>
            <div className='lg:ml-10 ml-5'>
              <p className='font-primary-medium'>Sexual Harassment</p>
              <p>
                All Agents must avoid offensive or inappropriate sexual behavior
                and are responsible for ensuring that their workplace is free
                from sexual harassment at all times. Broker prohibits; unwelcome
                sexual advances, requests for sexual acts or favors, with or
                without accompanying promises, threats or reciprocal favors or
                actions, or other verbal or physical conduct of a sexual nature
                which creates a hostile or offensive working environment.
              </p>
            </div>
          </div>
        </div>

        <div className='mt-2'>
          <p className='font-primary-medium'>Conduct on Social Media</p>
          <p>
            Agents are responsible for conducting themselves in a professional
            manner on social media. The following actions are not acceptable on
            social media in the form of posts or comments that reflect:
          </p>
          <ul className='list-disc lg:ml-10 ml-5'>
            <li>Aggressive conversation</li>
            <li>Harassing speech</li>
            <li>
              Epithets or slurs based on race, color, religion, sex, handicap,
              familial status, national origin, sexual orientation, or gender
              identity
            </li>
            <li>
              Disparaging comments, to include but not limited to comments
              directed at individuals, competing brokerages, political entities,
              religious organizations, etc.
            </li>
            <li>Attacks on political stances</li>
            <li>
              Intentional publication of information which is misleading or
              misrepresenting material facts
            </li>
            <li>Hate speech</li>
            <li>No discussions of an anti-competitive nature</li>
          </ul>
        </div>

        <div className='mt-2'>
          <p className='font-primary-medium'>Disciplinary Measures</p>
          <p>
            An agent whose conduct violates any of the paragraphs described
            herein may be subject to immediate separation from the Company.
          </p>
        </div>

        <div className='mt-2'>
          <p className='font-primary-medium'>Agent’s Employees</p>
          <p>
            Agent is obligated to inform Agent’s employees, if any, who perform,
            directly or indirectly services for Company, of their obligation to
            be bound by the provisions of this Policies and Procedures Manual.
            Agent is responsible for notifying Agent’s employees about this
            obligation and supervise their activities to ensure they comply.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            RELATIONSHIP WITH THE PROVINCIAL REGULATOR
          </p>
          <p className='font-primary-medium'>LICENSE</p>
          <p>
            Agents are required to maintain an active real estate license in all
            the provinces in which they conduct real estate activities.
          </p>
          <div className='lg:ml-10 ml-5'>
            <div className='mt-2'>
              <p className='font-primary-medium'>License in Good Standing</p>
              <p>
                It is the agent’s sole responsibility to keep their license in
                good standing with the provincial department of licensing. Any
                license status that reflects a license is not in good standing,
                such as being expired, revoked, or suspended will result in
                termination of sponsorship and you may not engage in any real
                estate activity on behalf of the Company. Company, by law, may
                not pay commission to any person who does not hold an active
                real estate license.
              </p>
            </div>
            <div className='mt-2'>
              <p className='font-primary-medium'>License Costs</p>
              <p>
                Agent is responsible for all continuing education costs,
                licensing fees, renewal fees, and other costs expended to keep a
                license active.
              </p>
            </div>
            <div className='mt-2'>
              <p className='font-primary-medium'>Continuing Education</p>
              <p>
                Agent is responsible for completing all continuing education
                required by provincial rules and law. Company, by law, may not
                pay commission to any agent who does not hold an active license.
              </p>
            </div>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            RELATIONSHIP WITH CREA ASSOCIATIONS/BOARDS OR MLS®
          </p>
          <p className='font-primary-medium'>REALTOR® ASSOCIATION MEMBERSHIP</p>
          <p>
            Agents are required to be members in good standing with a CREA
            affiliated Realtor® Association or Board. Purchase of lockboxes is
            the responsibility of the Agent.
          </p>
          <div className='lg:ml-10 ml-5'>
            <div className='mt-2'>
              <p className='font-primary-medium'>
                Payment of Association Dues and MLS® Fees
              </p>
              <p>
                Agent is responsible for remaining in good standing with their
                Association and MLS® by paying dues and fees. All related costs
                shall be paid by the agent. Agent agrees to reimburse Company
                for any costs incurred to Company by any board, association or
                MLS® for agent’s failure to join such entities. Company retains
                the right to terminate the sponsorship of any agent that has not
                paid their dues or fees.
              </p>
            </div>
          </div>
          <div className='mt-2'>
            <p className='font-primary-medium'>
              COMPLIANCE WITH ASSOCIATION AND MLS® RULES
            </p>
            <p>
              Agents are required to comply with all rules of the
              Association/Board and MLS® in which they are affiliated. Any fines
              resulting from a violation of Association/MLS® rules are the sole
              responsibility of the agent.
            </p>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            DEALING WITH THE PUBLIC AND CUSTOMERS
          </p>
          <p>Agents shall be honest and fair in all their dealings.</p>
          <p className='mt-2'>
            Agents shall offer equal professional services to all persons and
            shall not discriminate for reasons of race, color, religion, sex,
            handicap, familial status, national origin, sexual orientation, or
            gender identity.
          </p>
          <p className='mt-2'>
            Agents shall not be party to any plan or agreement to discriminate
            against a person or persons on the basis of race, color, religion,
            sex, handicap, familial status, national origin, sexual orientation,
            or gender identity.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            REPRESENTING THE SELLER / LANDLORD
          </p>
          <p>
            Upon the signing of an agency agreement, the agent now has a
            fiduciary duty to the client.
          </p>
          <div className='mt-2'>
            <p className='font-primary-medium'>Listing Agreement</p>
            <p>
              All listing agreements shall be made in the name of the Company.
              An agent must complete a listing contract that details in writing
              the agency relationship and responsibilities of the Company and
              seller / landlord, to which all legal owners are required to sign.
              A copy of the signed contract shall be immediately provided to the
              seller.
            </p>
            <div className='lg:ml-10 ml-5'>
              <p className='font-primary-medium mt-2'>
                Time To Submit the Agreement and Other Documents
              </p>
              <p>
                An agent will upload a digital copy of all signed documentation
                within two (2) business days of the effective date of the
                respective document, to include the listing agreement, all
                addenda, disclosures, and other documentation required by the
                provincial broker.
              </p>
            </div>
          </div>
          <div className='mt-2'>
            <p className='font-primary-medium'>Sellers Disclosure</p>
            <p>
              An agent shall encourage the seller to complete a seller’s
              disclosure which describes the condition of the property and all
              known material facts.
            </p>
          </div>
          <div className='mt-2'>
            <p className='font-primary-medium'>Cooperation and Compensation</p>
            <p>
              Agents cannot do their jobs effectively without the cooperation of
              colleagues in other brokerages. All listed properties shall be
              available to all brokers on a cooperative basis, unless
              specifically directed by the owner in writing. This compensation
              shall remain consistent between all cooperating brokerages.
            </p>
            <div className='lg:ml-10 ml-5'>
              <p className='font-primary-medium mt-2'>Sub-Agents</p>
              <p>
                REAL does not offer cooperation or compensation to sub-agents.
              </p>
            </div>
          </div>
          <div className='mt-2'>
            <p className='font-primary-medium'>Submission of Offers</p>
            <p>
              Listing agents shall continue to submit offers and counter-offers
              to the seller / landlord until closing or execution of a lease,
              unless the seller / landlord, in writing, has either waived this
              requirement or has instructed the agent that they may cease this
              activity.
            </p>
          </div>
          <div className='mt-2'>
            <p className='font-primary-medium'>Comparative Market Analysis</p>
            <p>
              Agent should inform sellers as to what similar properties have
              sold for with the same or similar square footage, year built,
              number of stories, etc through the use of a comparative market
              analysis (CMA). This comparative market analysis should be
              submitted to the Company document repository, reZEN.
            </p>
          </div>
          <div className='mt-2'>
            <p className='font-primary-medium'>Listing Cancellations</p>
            <p>
              The Company, through its provincial broker, retains the right to
              decide whether to cancel a listing agreement when requested by a
              client. Cancellations must be made in writing, submitted to the
              provincial broker and if approved, should be uploaded to the
              Company&apos;s document management system, reZEN, within two (2)
              days of cancellation.
            </p>
          </div>
          <div className='mt-2'>
            <p className='font-primary-medium'>Disputes with Seller</p>
            <p>
              An agent shall make the provincial broker aware of any dispute or
              disagreement with the seller of a listed property. The provincial
              broker will have full authority to address the issues with the
              Seller to try to negotiate a solution. Such a solution may
              include, but is not limited to cancellation of the listing, a
              reduction in commission, and/or replacing the agent with a new
              agent for the transaction. Agent is advised that the Company is
              the owner of the listing, not the agent, and as such any solution
              agreed upon by the provincial broker is binding on the agent, even
              if that solution involves the reduction or elimination of
              commissions to be paid to the agent.
            </p>
          </div>
          <div className='mt-2'>
            <p className='font-primary-medium'>Business Brokering</p>
            <p>
              An Agent may assist a seller in the listing of real property.
              However, an Agent may not assist a seller in the sale of a
              business entity, or assets which are not real property without the
              provincial broker’s knowledge and written consent.
            </p>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>REPRESENTING THE BUYER</p>
          <p className='font-primary-medium'>
            Buyer’s Representation Agreement
          </p>
          <p>
            Agent must complete a buyer’s representation agreement approved by
            the provincial licensing authority, board/association, or attorney
            before an offer is written. A copy of the signed buyer’s
            representation agreement shall be immediately provided to the
            buyer(s). It shall be submitted to the Company document repository
            (reZEN) within two (2) days after signature of buyer(s).
          </p>
          <p className='font-primary-medium mt-2'>
            Comparative Market Analysis
          </p>
          <p>
            Agent should inform buyers as to what similar properties have sold
            for with the same or similar square footage, year built, number of
            stories, etc through the use of a comparative market analysis (CMA).
            This comparative market analysis should be submitted to the Company
            document repository, reZEN.
          </p>
          <p className='font-primary-medium mt-2'>Forms</p>
          <p>
            Agents will only use forms approved by the provincial licensing
            authority, board/associations, or those prepared by an attorney.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>DUAL REPRESENTATION</p>
          <div>
            <p className='font-primary-medium mt-2'>
              Representing Both Sides of a Transaction
            </p>
            <p>
              An agent <span className='font-primary-medium'> shall not </span>
              represent both parties of a transaction unless:
            </p>
            <ul className='list-disc lg:ml-10 ml-5'>
              <li>
                It is lawful to do so in the province where the transaction will
                occur.
              </li>
              <li>
                All parties have given written consent to such representation
                prior to the execution of any contract.
              </li>
              <li>
                Agent has received prior written consent from their provincial
                broker.
              </li>
            </ul>
          </div>
          <div className='mt-2'>
            <p className='font-primary-medium'>Agent or Family Member</p>
            <p>
              An agent <span className='font-primary-medium'>may not </span>
              represent both parties in a transaction if the agent, or a family
              member, is a party in the transaction.
            </p>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>EXPERTISE/COMPETENCY</p>
          <p>
            An agent may not represent a client outside their area of expertise.
            An agent must obtain prior written approval from the provincial
            broker to participate in transactions including, but not limited to
            commercial transactions, farm and ranch transactions, or short sale
            transactions. Provincial brokers, at their sole discretion, may
            assign another agent familiar with these operations to assist the
            agent requesting to participate in any of the above activities.
          </p>
          <div className='lg:ml-10 ml-5'>
            <p className='font-primary-medium mt-2'>Geographical Expertise</p>
            <p>
              Agents may not conduct real estate activities outside their
              typical geographical area of transactions without prior written
              approval from the provincial broker.
            </p>
            <p className='font-primary-medium mt-2 '>Advice</p>
            <p>
              An agent must never give advice to a client outside the scope of
              their expertise, including but not limited to tax, mortgage, legal
              or appraisal topics. Agents shall always encourage their clients
              to consult a professional with the requisite expertise.
            </p>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>FIDUCIARY DUTY</p>
          <p>
            Agents must place the interests of the client (Seller or Buyer)
            before all others, except where there may be a conflict with federal
            or provincial law.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>CONFIDENTIALITY</p>
          <p>
            Agent has a duty not to disclose any information obtained during the
            course of the transaction that the client could reasonably expect to
            remain confidential, except that information the client has
            authorized in writing to be disclosed. This duty continues after
            closing and after the agency agreement has terminated.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>TRANSACTIONS</p>
          <p className='font-primary-medium'>
            TRANSACTIONS INVOLVING AGENT OWNED PROPERTIES
          </p>
          <p>When dealing with the sale of agent owned properties:</p>
          <ul className='list-disc lg:ml-10 ml-5'>
            <li>
              Parties shall use standard forms typically in use by the members
              of the real estate association or MLS® the agent is affiliated
              with. Forms outside this definition must be approved by the
              provincial broker.
            </li>
            <li>
              In relation to a transaction where an agent or a family member
              owns the property, the agent may not represent both sides of a
              transaction.
            </li>
            <li>
              Agents shall disclose all known material facts and information
              regarding the property and its condition and provide such to the
              buyer on an approved seller’s disclosure form and acknowledged by
              buyer(s) prior to closing.
            </li>
            <li>
              A professional home inspection must be performed and a copy
              delivered to the buyer. A signed waiver should be signed if the
              buyer waives the inspection.
            </li>
            <li>A home warranty must be provided.</li>
          </ul>
          <p className='font-primary-medium mt-2'>FORMS FOR TRANSACTIONS</p>
          <p>
            An agent must utilize the forms approved by the provincial licensing
            authority, provincial Realtor association, an attorney, or forms
            commonly accepted for use in the local association of affiliation.
            The Company may create specific forms for which signatures of their
            clients are required. Agents are expected to obtain these signatures
            from their clients as required by the Broker. Documents or forms
            outside the definition of this paragraph must be approved by the
            provincial broker.
          </p>
          <p className='font-primary-medium mt-2'>
            ALL TRANSACTIONS THROUGH THE BROKERAGE
          </p>
          <p>
            Any and all real estate transactions, including personal
            transactions, leases, family representation, or transactions where
            no commission is taken, are to be performed and documented through
            the Company. All transactions are to be conducted through the
            Company. Any agent found to be transacting business outside the
            Company may be subject to immediate separation.
          </p>
          <p className='font-primary-medium mt-2'>
            CONTENTS OF A TRANSACTION FILE
          </p>
          <p>
            A transaction file should contain all documents related to the
            transaction, including representation agreements, disclosures,
            comparative market analysis, all offers, purchase and sale
            agreements, emails, texts, settlement documents and such other
            documentation required by the provincial broker.
          </p>
          <p className='font-primary-medium mt-2'>SUBMISSION OF DOCUMENTS</p>
          <p>
            Company will make timely payment of commissions to the agent after
            receipt of all appropriate transaction documentation required by the
            provincial broker. All listing agreements, buyer representation
            agreements, purchase and sale agreements, referrals, disclosures,
            and other documents pertaining to the transaction should be uploaded
            to the company’s document management system (reZEN) within two (2)
            days of obtaining signatures.
          </p>
          <div className='mt-2'>
            <div className='lg:ml-10 ml-5'>
              <p className='font-primary-medium mt-2'>Late Submission</p>
              <p>
                Submission of a document(s) to the Company’s document management
                system (reZEN) outside the parameters described in the above
                paragraph (Submission of Documents) will be considered late.
                Late submissions will not be tolerated and may result in a
                delayed payment of commission. An agent may be assessed a late
                fee of $150 for a file whose documents were submitted more than
                10 days after execution. An agent who repeatedly submits
                documents late may be subject to separation.
              </p>
              <p className='font-primary-medium mt-2'>Incomplete Files</p>
              <p>
                A transaction file which lacks any required documentation at the
                time of closing may result in future commissions being withheld
                until all the required documentation in the incomplete file is
                provided and uploaded.
              </p>
              <p className='font-primary-medium mt-2'>
                Cancelled, Expired or Withdrawn Transactions
              </p>
              <p>
                Open files need to be closed out in reZEN. Agents with
                transaction files terminated due to cancellation, expiration or
                withdrawal are still required to upload relevant required
                documentation as determined by the provincial broker.
              </p>
            </div>
          </div>
          <div className='mt-5'>
            <p className='font-primary-medium mt-2'>MINIMUM TRANSACTION FEE</p>
            <p>
              The minimum brokerage fee an agent may charge a client on a
              transaction is $650. This fee is at the broker’s discretion, to
              waive or increase. Agents are not permitted to charge a $0 fee for
              listings. Agents who consistently waive a reasonable fee for
              listings may be asked to leave the brokerage.
            </p>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>EARNEST MONEY DEPOSITS:</p>
          <p>
            The provincial rules and regulations where the agent conducts
            business shall govern the procedures of handling earnest money. In
            certain provinces, Company will maintain a trust fund account.
            Earnest money deposits shall never be handled or deposited by Agent.
            Agent should notify the closing agent immediately to arrange for any
            earnest money deposits needing to be picked up or delivered and
            submitted on behalf of the client and inform Company of such earnest
            money deposit. Agent shall never accept any funds from clients, nor
            receive any cash payments from clients. All trust funds shall be
            handled in compliance with applicable local, provincial and federal
            laws. Agent shall not accept a postdated check unless approved in
            writing by all parties in writing. Agent shall not provide earnest
            money on behalf of their client.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>COMMISSIONS</p>
          <p>
            All commissions, including those resulting from purchases, sales,
            leases, broker price opinions, or administrative fees, shall be made
            payable in the name of the Company. An agent is prohibited from
            requesting any entity to pay a commission directly to either their
            personal name, team name or corporate entity. Violation of this
            policy will result in termination of the agent’s sponsorship.
          </p>
          <div className='lg:ml-10 ml-5'>
            <p className='font-primary-medium mt-2'>
              Commissions Paid to Agents
            </p>
            <p>
              Payment of commissions will comply with all federal, provincial
              and local rules. Commissions will be made payable to the name
              listed on the Agent’s license on file with the Company. Company
              may pay an agent’s corporate entity if such payment complies with
              the provincial licensing department rules and regulations.
            </p>
            <p className='font-primary-medium mt-2'>Referrals</p>
            <p>
              All referrals will be in writing in the form of a signed referral
              agreement. Referral commissions shall only be paid by the Company
              in accordance with the terms contained in the referral agreement.
              The referral agreement is to be signed by the Agent and Provincial
              Broker and other salesperson/broker and approved by Company prior
              to the closing of any transaction. Referrals will only be paid to
              licensed agents and subject to the rules and regulations of the
              province(s) where the agents do business. Company splits will be
              applied after fees to referring agents have been paid.
            </p>
            <p className='font-primary-medium mt-2'>Bonuses, BPOs and Fees</p>
            <p>
              All bonuses, broker price opinions and fees will be made payable
              to Company and are subject to the Company split. A social
              insurance number of the referring broker or business number is
              required, along with referring broker contact information and
              referral agreement.
            </p>
            <p className='font-primary-medium mt-2'>Commission Advances</p>
            <p>
              All companies conducting the business of commission advances must
              be approved in advance by the V.P. of Brokerage Operations. Each
              commission advance must be approved in advance by the provincial
              broker. An agent may only receive up to 70% of the net commission
              due as part of the advance.
            </p>
            <p className='font-primary-medium mt-2'>
              Collection of Unpaid Commissions
            </p>
            <p>
              Only the Company is authorized to pursue unpaid commissions. An
              agent is not permitted to initiate any type of legal action in an
              attempt to collect unpaid commissions without written approval of
              the Company. The Company has no requirement to pay an agent for
              any commissions unpaid by another brokerage or party. In no event
              shall Company, its directors, or employees, be personally liable
              to Agent for Agent’s share of commissions not collected. Agent
              shall not be entitled to any advance or payment from Company upon
              future commissions for such uncollected commissions.
            </p>
            <p className='font-primary-medium mt-2'>
              Commission Reimbursements
            </p>
            <p>
              Agent shall be responsible for any previously earned sales
              commission reimbursement, rebate, or refund that may be ordered by
              a Court of Law or by a professional Arbitration or Mediation
              Panel, for any reason.
            </p>
            <p className='font-primary-medium mt-2'>Commission Disputes</p>
            <p>
              A dispute of any kind involving compensation from a transaction
              may result in that compensation being held by the Company until
              the dispute is resolved. It is agreed that the Company will incur
              no liability for such withholding.
            </p>
            <p className='font-primary-medium mt-2'>
              Voluntary Change of Brokerage
            </p>
            <p>
              If an agent voluntarily requests to terminate sponsorship with
              Real the following will apply to existing transactions:
            </p>
            <div className='lg:ml-10 ml-5'>
              <p className='font-primary-medium mt-2'>Active Listings</p>
              <p>
                All active listings may be transferred to the new brokerage with
                the provincial broker’s approval.
              </p>
              <p className='font-primary-medium mt-2'>Pending Transactions</p>
              <p>
                Any pending transaction may be transferred to the new brokerage
                pending a 15% referral fee payable to Real.
              </p>
            </div>
            <p className='font-primary-medium mt-2'>Direct Payment To Agent</p>
            <p>
              At no time is an Agent to request, receive, ask or authorize any
              third party, such as an attorney or title company, to release a
              commission check to the Agent unless authorized to do so by the
              Company, in writing. Payment of a commission by the Company
              follows a completed package of the transaction documents approved
              by the provincial broker, and all commissions must be paid through
              the company.
            </p>
            <p className='font-primary-medium mt-2'>Disciplinary Termination</p>
            <p>
              If the agent’s sponsorship with Company is terminated as a result
              of a disciplinary measure all transactions shall remain with the
              Company. Release of such transactions are at the sole discretion
              of the Company and shall only occur with written authorization and
              evaluated on a case by case basis.
            </p>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>ADVERTISING</p>
          <p>
            All advertising must comply with federal, provincial, local rules
            and regulations, to include but not limited to print media, social
            media or digital media. Agent advertising is to adhere to Company’s
            branding guidelines and must be submitted to the marketing
            department and provincial broker for approval prior to use. Agent
            shall use Company’s name on all advertising and marketing, including
            but not limited to Agent’s business cards, signage, stationary and
            websites. Agent agrees that Company retains exclusive rights to the
            “Real” and “Joinreal.com” trademark logo and graphics. Agent agrees
            to discontinue the use of the Real trademark logo and graphics
            immediately upon termination of this Agreement.
          </p>
          <div className='lg:ml-10 ml-5'>
            <p className='font-primary-medium mt-2'>Use of Company Logo</p>
            <p>
              All advertising using the Real logo must comply with the logo
              guideline form.
            </p>
            <a
              href='https://drive.google.com/file/d/1Q0RkOAXO8OlMa7UYYr34ohntN3wqch7O/view?usp=sharing'
              className='text-primary underline'
            >
              Logo Guideline Form
            </a>
            <p className='font-primary-medium mt-2'>Use of Company Name</p>
            <p>
              Use of the Company name “Real” in any advertising, whether in a
              team name, domain name, or in the name of a social media page
              which suggests exclusivity, or a location such as a city,
              province, region or geographic location, such as “YourRealAgent”,
              or “RealAlbertaTeam”, or “Your Real Calgary Agent” is prohibited
              unless approved by the Company in advance. It is suggested that
              any name an Agent wants to use in advertising is approved by the
              Company’s marketing department and the provincial broker.
            </p>
            <p className='font-primary-medium mt-2'>Signage</p>
            <p>
              Agent is responsible for ordering and purchasing signage. Only
              known and reliable vendors should be used. Agent should comply
              with all rules on signage:
            </p>
            <ul className='list-disc lg:ml-10 ml-5 mt-2'>
              <li>
                Be approved by the provincial broker and Company marketing
                department.
              </li>
              <li>
                Signage must comply with all federal, provincial and local
                rules.
              </li>
              <li>
                All signage must identify Real as the broker using the correct
                full-company name for the Agent&apos; province.
              </li>
              <li>
                An agent must have a signed listing agreement with the client in
                place before a sign may be placed on the property.
              </li>
              <li>
                Placement and removal of signage must comply with the rules of
                the Association, Board and/or MLS with which the agent is
                affiliated.
              </li>
            </ul>
            <p className='font-primary-medium mt-2'>Misleading Advertisement</p>
            <p>
              An advertisement which is found to be intentionally misleading
              shall be grounds for immediate separation from the Company.
            </p>
            <div className='lg:ml-5 ml-5'>
              <p className='font-primary-medium mt-2'>Titles</p>
              <p>
                Agents are prohibited from using any title in their advertising
                which would create the impression they are employed with the
                Company. Titles such as CEO, President, Vice-President, Founder,
                Growth Leader, or Manager in connection with Real may not be
                used by an agent without a formal agreement in place allowing
                such usage.
              </p>
              <p className='font-primary-medium mt-2'>Use of Brokerage Name</p>
              <p>
                All advertisements will contain the name of the Company name.
                Advertisements not displaying the name of the Company will be
                considered “misleading”. Use of the D/B/A name “Real” is
                appropriate only in provinces where allowed and the name “Real”
                has been registered with the provincial department of licensing.
              </p>
            </div>
            <p className='font-primary-medium mt-2'>Recruiting Advertising</p>
            <p>
              Any advertisement made by an affiliated agent, whether individual
              or team, intended to recruit new agents to the Company for the
              benefit of revenue share is strictly prohibited. This would
              include, but is not limited to social media posts, emails, or
              texts. Any advertisement, made by an affiliated agent or team,
              whose language or appearance makes it appear to have originated
              from the Company, for whatever purpose, is prohibited and may be
              considered misleading advertising.
            </p>
            <p>
              Advertisements, made by an affiliated agent or team, whose
              language or appearance makes it appear to be an opportunity of
              employment when no position is currently available or being
              entertained is prohibited.
            </p>
            <p className='font-primary-medium mt-2'>
              Unauthorized Advertising Language
            </p>
            <p>
              Agents shall only use verbiage in their advertising to describe
              the property. Language that in any manner is used to describe,
              define or suggest the desired or appropriate buyer or tenant is
              strictly prohibited. Further, language that would intimate or
              suggest the appropriate current residents or future residents, or
              neighbors or neighborhood is also prohibited. Agents should be
              familiar with any limitations or additional protected classes in
              the city they are advertising in. Examples of unauthorized
              language may include, but are not limited to the following:
            </p>
            <div className='lg:ml-10 ml-5'>
              <p className='font-primary-medium mt-2'>
                Race, color or national origin
              </p>
              <p>
                Agent may not use any language which would indicate a preference
                or limitation on account of race, color or national origin, or
                that would describe current or future residents, the neighbors
                or the neighborhood in racial terms. Permissible terms may
                include property descriptive terms such as “owner’s Room”, or
                “desirable neighborhood”.
              </p>
              <p className='font-primary-medium mt-2'>Familial Status</p>
              <p>
                Agents may not use language in an advertisement which would
                indicate a preference or limitation because of the number or
                ages of children, or state a preference for adults, couples or
                singles. Statements such as “No children” or “Adults Only” shall
                not be used in any advertisement. Agents may use descriptions of
                the property (relaxing family room), or services and facilities
                (no bicycles allowed), or neighborhoods (quiet streets).
                Descriptions of communities with codified rules or bylaws
                surrounding age may be used in advertising.
              </p>
              <p className='font-primary-medium mt-2'>Gender</p>
              <p>
                Agents may not use language in an advertisement which indicates
                a preference, limitation or discrimination on the basis of
                gender. Agents may use property descriptions such as “owner’s
                room”, “mother-in-law suite”, or “bachelor apartment”.
              </p>
              <p className='font-primary-medium mt-2'>Handicap</p>
              <p>
                Agent may not use language in an advertisement which states a
                preference, limitation or descrimination on the basis of
                handicap. Descriptions of the property and accessibility
                features, such as “elevators” and “wheelchair ramps” are
                permissible.
              </p>
              <p className='font-primary-medium mt-2'>Religion</p>
              <p>
                Agent may not use language in an advertisement which states a
                preference, limitation or descrimination on the basis of
                religion.
              </p>
            </div>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>OFFICE POLICIES</p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            COMMUNICATION WITH COMPANY
          </p>
          <p>
            Contact with the Company can occur by contacting the provincial
            broker or through the agent support channel.
          </p>
          <div className='lg:ml-10 ml-5'>
            <p className='font-primary-medium mt-2'>Provincial Broker</p>
            <p>
              The provincial broker contact information may be obtained from the
              Real app. The provincial broker will generally be available during
              normal business hours. The process for contacting the provincial
              broker will be addressed in the province-specific addendum.
            </p>
            <p className='font-primary-medium mt-2'>Support</p>
            <p>
              Agent questions may be emailed to{' '}
              <a
                href={`mailto:${getSupportEmail(userDetail?.accountCountry)}`}
                className='text-primary underline'
              >
                {getSupportEmail(userDetail?.accountCountry)}
              </a>
            </p>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>TECHNOLOGY RESOURCES</p>
          <p>
            Technology resources provided by the Company, including but not
            limited to email, Workplace, reZEN and any proprietary technology
            owned by the Company, are business tools provided to Agent by the
            Company. These resources shall be used for Company business purposes
            only. Agent will consider these as Company property and shall not
            share, provide, or convey these resources to any individual or
            entity outside the Company without express written permission.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            CONTACT INFO AND COMMUNICATIONS WITH AGENT
          </p>
          <p>
            Company will primarily communicate with the agents through the use
            of email, texts and Facebook Workplace. It is the sole
            responsibility of the agent to ensure that the Company has their
            current and correct contact information, including phone number,
            email address, and physical address. Agent agrees that the receipt
            of email to the email address on file with the company is sufficient
            for all business purposes, including disciplinary communications.
          </p>
          <div className='lg:ml-10- ml-5'>
            <p className='font-primary-medium mt-2'>Regular Business Mail</p>
            <p>
              Process for delivery of regular business mail will be discussed in
              the province-specific addendum. Agents should make every effort to
              ensure regular mail is delivered to their home address.
            </p>
            <p className='font-primary-medium mt-2'>
              Failure to Respond to Communications
            </p>
            <p>
              It is the responsibility of the agent to respond promptly to any
              communication from the Company or the provincial broker. Agent
              must be accessible by phone and email. Except for extenuating
              circumstances, an Agent shall respond to voicemails and emails
              from the provincial broker or the Company within a maximum time
              frame of twenty-four (24) hours. An agent who fails to respond to
              communications from the provincial broker or Company may be
              subject to separation from the Company.
            </p>
            <p className='font-primary-medium mt-2'>
              Changes in Contact Information
            </p>
            <p>
              Agent will have two (2) business days to notify the Company of any
              changes to their contact information, including name, address,
              telephone number, and email.
            </p>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>COMPANY NOTIFICATION</p>
          <p>
            Agents shall immediately notify the Company through their provincial
            broker regarding any of the following situations:
          </p>
          <ul className='list-disc lg:ml-10 ml-5 mt-2'>
            <li>
              Any demands or claims from a client, whether verbally or in
              writing.
            </li>
            <li>
              Any demands or claims from a person claiming to represent a
              client.
            </li>
            <li>
              Any notification of the commencement of a lawsuit, arbitration or
              mediation process.
            </li>
            <li>
              An act of harassment committed by an agent in a transaction.
            </li>
            <li>
              An act of discrimination committed by an agent in a transaction.
            </li>
            <li>
              An accident resulting in physical injury while conducting real
              estate activities.
            </li>
            <li>Any criminal offense.</li>
            <li>A complaint from parties of a real estate transaction.</li>
            <li>
              Any contact from an employee of a real estate licensing
              department.
            </li>
            <li>
              Any threat of legal action against the agent or Company as a
              result of real estate activities.
            </li>
            <li>Civil judgment, such as insolvency filing or bankruptcy.</li>
            <li>
              Criminal judgment, such as a felony or misdemeanor, to include but
              not limited to impaired driving
            </li>
          </ul>
          <p className='mt-2'>
            Agents are encouraged to report any concerning incidents to their
            broker as soon as possible. Failure to notify the broker and Company
            in a timely manner regarding any of the issues described above may
            result in disciplinary action, including separation from the
            Company.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>AGENT UNAVAILABILITY</p>
          <p>
            An agent who will be unavailable or out of town for a significant
            period should make arrangements with another agent of Real to
            service their clients in their absence.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>DRUGS AND ALCOHOL</p>
          <p>
            Agents are strictly prohibited from the usage of drugs and alcohol
            while conducting work for the Company. Agents found to be in
            violation of this policy may be subject to immediate termination of
            sponsorship.
          </p>
          <div className='lg:ml-10 ml-5'>
            <p className='font-primary-medium mt-2'>AGENT SUBSTANCE ABUSE</p>
            <p>
              Agents who demonstrate a history of abusing drugs or alcohol and
              may pose a danger to themselves or others, or demonstrably affect
              the company’s reputation negatively may be terminated by the
              Company after such determination is made by the V.P. of Brokerage
              Operations.
            </p>
            <p className='font-primary-medium mt-2'>CLIENT SUBSTANCE ABUSE</p>
            <p>
              If it becomes apparent that a client or customer is under the
              influence of drugs or alcohol during a real estate activity, the
              agent should immediately terminate all activities. Agent shall
              notify the provincial broker of such a situation immediately.
            </p>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            LITIGATION AND CLAIMS HANDLING
          </p>
          <p className='mt-2'>
            Agent agrees to cooperate with the Company in the defense of any
            claim. Agent acknowledges and agrees that the Company has the right
            to make all decisions concerning the defense of a claim, including
            choice of counsel. In the event that Agent objects to any decision
            made by the Company, the Agent may obtain their own attorney at
            their own expense; however, Agent shall not be relieved from the
            obligation to pay his or her portion of the cost of the claim. Agent
            shall promptly pay Company any amounts due upon notice to him or her
            from the Company in regards to any claim or lawsuit.
          </p>
          <p className='mt-2'>
            The cost of the defense of a claim, or to defend or protect against
            any potential or possible claim where the Company or Agent are not
            involved as a party, including attorney’s fees, the cost of any
            settlement or judgment (collectively the “costs of defense”), shall
            be allocated between Company and Agent in the same percentages as
            per this Agreement or prospective transaction that led to the claim,
            whether or not the transaction actually closed.
          </p>
          <p className='mt-2'>
            Agent will be solely responsible for ALL costs of a claim if he or
            she fails to follow any law, regulation or company policy as set
            forth in this policy manual and that failure results in a judgment
            or other final adjudication based on that failure.
          </p>
          <p className='mt-2'>
            Agent will be solely responsible, and shall reimburse the Company,
            for all the Company’s costs of defense if a judgment or other final
            adjudication establishes that dishonest, fraudulent, criminal or
            malicious acts, errors or omissions were committed, or results in a
            finding of intentional tort, slander, defamation or any conduct
            which leads to the imposition of punitive, exemplary or multiple
            damages, or fines or penalties, or establishes discrimination on the
            basis of race, creed, relgion, ethnic background, national origin,
            age, sex, handicap, familial status, physical disability, sexual
            preference, or any other unlawful classification.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            CONFIDENTIALITY OF COMPANY COMMUNICATIONS
          </p>
          <p>
            Agents may be provided with communications regarding proprietary
            tools and information that are only available to agents of the
            Company. Agent agrees to keep all such information confidential
            including, but not limited to, Company tools, systems, forms,
            website, marketing strategies, programs, legal information,
            brochures, clients, training materials and other non-public
            information.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>THIRD PARTY VENDORS</p>
          <p>
            Company will not be responsible for any expenses incurred for work
            or services performed by a third party vendor. Expenses for such
            services as inspections, surveys, etc should be billed to and paid
            by either the buyer or seller.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            UNAUTHORIZED REAL ESTATE ACTIVITIES
          </p>
          <p className='font-primary-medium'>WALK THROUGH INSPECTIONS</p>
          <p>
            Walk through inspections, if allowable, are part of the reasonable
            steps that a client should take to determine the condition of a
            property. An agent may not perform a final walk through inspection
            on behalf of their client.
          </p>
          <p className='font-primary-medium mt-2'>
            ACTING UNDER A CLIENT POWER OF ATTORNEY
          </p>
          <p>
            An agent may not act under a power of attorney to perform business
            transactions on behalf of the client. An agent{' '}
            <span className='underline font-primary-medium'>
              may not and shall not
            </span>{' '}
            act in the capacity of an attorney-in-fact on behalf of a client.
          </p>
          <p className='font-primary-medium mt-2'>PROPERTY MANAGEMENT</p>
          <p>
            An agent{' '}
            <span className='underline font-primary-medium'>
              may not and shall not
            </span>{' '}
            perform property management activities on behalf of a client.
            Property management is the management of another person’s real
            property, including duties such as; running background checks,
            selecting tenants, rent collection, or scheduling maintenance and
            repairs. Further, an agent may not be employed with a property
            management company. An agent may, where permissible by provincial
            licensing laws, manage their own personal properties.
          </p>
          <p className='font-primary-medium mt-2'>WHOLESALING</p>
          <p>
            The practice of wholesaling is prohibited. Wholesaling occurs when
            an agent contracts with a property owner to purchase their property,
            then before closing markets the property to interested buyers, then
            assigns the contract for a higher price to the ultimate buyer. This
            is also referred to as being the “middleman” in the transaction.
            While affiliated with the Company agents are not permitted to act in
            the capacity of a middleman.
          </p>
          <div className='lg:ml-10 ml-5'>
            <p className='font-primary-medium mt-2'>Acting as a middleman</p>
            <p>
              Agents must hold title to a property they sell. Agents are not
              permitted to act as a “middleman” in a transaction or to assign a
              contract of property of which they do not hold title.
              Additionally, agents are not permitted to represent parties as a
              middleman agent.
            </p>
          </div>
          <p className='font-primary-medium mt-2'>PURCHASING LOANS</p>
          <p>
            Agents are not permitted to purchase loans and resale loans under
            their real estate license at Real. This activity is strictly
            prohibited.
          </p>
          <p className='font-primary-medium mt-2'>SELLING BUSINESSES</p>
          <p>
            An agent may not represent a client outside the scope of real
            estate, such as in the capacity of a business broker to sell a
            business, without prior written approval of the provincial broker.
          </p>

          <p className='font-primary-medium mt-2'>MORTGAGE ACTIVITIES</p>
          <p>
            A real estate agent who is also licensed as a mortgage originator
            may not act simultaneously in both capacities in the same
            transaction with a client. Additionally, the agent may not act as a
            mortgage originator for any transaction for which they may derive a
            financial benefit through revenue share.
          </p>

          <p className='font-primary-medium mt-2'>REPAIRS OR CONTRACTED WORK</p>
          <p>
            An agent may not personally perform repairs or contract for work on
            a property that is owned by a seller they represent. Further, an
            agent may not personally perform repairs or contract work on a
            property a buyer they represent is purchasing.
          </p>

          <p className='font-primary-medium mt-2'>APPRAISAL ACTIVITIES</p>
          <p>
            An agent who is also licensed as a real estate appraiser may not
            simultaneously perform both duties on properties his clients are
            directly involved with. Additionally, they may not perform appraisal
            activities on properties for which they would receive a financial
            benefit through revenue share.
          </p>

          <p className='font-primary-medium mt-2'>FAMILIAL RECOMMENDATIONS</p>
          <p>
            An agent may not recommend a client to any third party vendor or
            service with which the agent has a familial relationship.
          </p>

          <p className='font-primary-medium mt-2'>
            UNDISCLOSED DUAL REPRESENTATION
          </p>
          <p>
            An agent that represents both the seller and the buyer of real
            property in the same transaction, without written permission from
            both parties agreeing to such representation, is defined as
            Undisclosed Dual Representation, and whether unintentional or
            accidental, is expressly prohibited.
          </p>

          <p className='font-primary-medium mt-2'>AUCTIONS</p>
          <p>
            An agent is prohibited from engaging in auctions related to real
            property without the express written consent of the V.P. of
            Brokerage Operations.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>LEGAL INFORMATION</p>
          <p className='font-primary-medium'>CANADIAN HUMAN RIGHTS ACT</p>
          <p>Discrimination in any form is prohibited.</p>{' '}
          <a
            href='https://laws-lois.justice.gc.ca/eng/acts/h-6/fulltext.html'
            className='underline text-primary mt-2'
          >
            Canadian Human Rights Act (justice.gc.ca)
          </a>
          <p className='font-primary-medium mt-2'>FINTRAC</p>
          <p>
            Canada has a national financial intelligence unit that is tasked
            with the detection, prevention, and deterrance of money laundering
            and financing of terrorism. In order to comply with FINTRAC
            (Financial Analysis and Reports Analysis Centre of Canada), each
            Provincial Broker will have a FINTRAC officer that is responsible
            for record keeping and reporting of suspicious activities. Agents
            agree to follow the guidelines for accurate and complete record
            keeping and will advise the FINTRAC officer of any suspicious
            activity. Failure to send in fully completed FINTRAC documents will
            result in commissions being held back until the required documents
            are uploaded, and in cases where the Agent is repeatedly negligent,
            or willfully inaccurate, the Company may escalate the disciplinary
            actions or terminate the Agent.
          </p>
          <a
            href='https://www.fintrac-canafe.gc.ca/guidance-directives/recordkeeping-document/record/real-eng'
            className='underline text-primary mt-2'
          >
            https://www.fintrac-canafe.gc.ca/guidance-directives/recordkeeping-document/record/real-eng
          </a>
          <p className='font-primary-medium mt-2'>GARNISHMENTS</p>
          <p>
            Garnishment orders from a court of appropriate authority will be
            observed by the Company in the payment of commissions, including but
            not limited to child support, consumer debt and student loans.
          </p>
          <p className='font-primary-medium mt-2'>DISCLOSURES</p>
          <p>
            When required by law, an Agent must comply with the Federal and
            Provincial disclosure laws regarding health & safety hazards,
            property defects, environmental issues, drug house remediation, and
            any other issues that may render the property unfit for the intended
            use, which may materially affect a Buyers decision to purchase
            and/or the price they might be willing to pay. The Provincial Broker
            should immediately be notified in a situation where a Seller Client
            is suggesting, instructing, or intimating that a material fact not
            be disclosed, to determine if the listing should be terminated. If a
            Seller Client refuses to disclose any known defects or hazards then
            the listing must not be taken or immediately terminated.
          </p>
          <p className='font-primary-medium mt-2'>NATIONAL DO NOT CALL LIST</p>
          <p className='mt-2'>
            <span className='underline font-primary-medium'>
              No Telephone Solicitation Is Allowed
            </span>{' '}
            by an agent to individuals who have registered their telephone
            numbers on the National Do Not Call List. Agents are required to be
            compliant with the Unsolicited Telecommunications and National Do
            Not Call List Rules. Company is not liable or responsible for any
            advertising done by Agent on its behalf and Agent agrees to hold
            Company harmless of any costs, damages, legal or otherwise,
            specifically arising as a result of Agent’s failure to comply with
            this requirement.
          </p>
          <a
            href='https://lnnte-dncl.gc.ca/en'
            className='underline text-primary mt-2'
          >
            Canadian National Do Not Call List Link Here
          </a>
          <p className='mt-2'>
            Agents utilizing cold calling lead generation must remain compliant
            with all federal, provincial and local laws. Agents that cold call
            must use a version of the DNC list not more than 31 days old. Agent
            agrees to indemnify and hold the Company harmless of any costs,
            damages, legal or otherwise, specifically arising as a result of the
            agent&apos; failure to comply with this requirement.
          </p>
          <p className='font-primary-medium mt-2'>
            CANADIAN ANTI-SPAM LEGISLATION
          </p>
          <p>
            Agents must be familiar with and comply with the requirements
            contained in the Canadian Anti-Spam Legislation (CASL) of 2014.
          </p>
          <a
            href='https://www.fightspam.gc.ca/eic/site/030.nsf/eng/home'
            className='underline text-primary mt-2'
          >
            Canadian Anti-Spam Legislation
          </a>
          <p className='font-primary-medium mt-2'>PRIVACY</p>
          <p>
            Agents holding personal information of their clients must be
            compliant with all applicable privacy legislation in their
            jurisdiction, including but not limited to{' '}
            <a
              href='https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/'
              className='underline text-primary'
            >
              PIPEDA,
            </a>{' '}
            <a
              href='https://www.alberta.ca/personal-information-protection-act.aspx'
              className='underline text-primary'
            >
              PIPA (Alberta),
            </a>{' '}
            <a
              href='https://www.bclaws.gov.bc.ca/civix/document/id/complete/statreg/00_03063_01'
              className='underline text-primary'
            >
              PIPA (BC)
            </a>{' '}
            <a
              href='http://legisquebec.gouv.qc.ca/en/ShowDoc/cs/P-39.1'
              className='underline text-primary'
            >
              Act Respecting The Protection of Personal Information in the
              Private Sector (Quebec).
            </a>
            <p className='mt-2'>
              <span className='text-red-500'>LINK TO PRIVACY POLICY: </span>
              <a
                href='https://www.joinreal.com/about/legal'
                className='underline text-primary'
              >
                https://www.joinreal.com/about/legal
              </a>
            </p>
          </p>
          <p className='font-primary-medium mt-2'>DRONES</p>
          <p>
            Agents using drones in their real estate activities agree to be
            compliant with all Transport Canada rules regarding drones. Agents
            will be solely responsible for any fines resulting from any
            violations.{' '}
            <a
              href='https://tc.canada.ca/en/aviation/drone-safety/learn-rules-you-fly-your-drone/flying-your-drone-safely-legally'
              className='underline text-primary'
            >
              See Transport Canada rules here
            </a>
          </p>
          <p className='font-primary-medium mt-2'>COMPANY INDEMNIFICATION</p>
          <p>
            Agent will indemnify and hold the Company harmless for any real
            estate sales activities performed including, but not limited to,
            suits, fines, proceedings, damages, claims or attorney fees.
          </p>
          <p className='font-primary-medium mt-2'>OUTSTANDING BALANCES</p>
          <p>
            Agent agrees that any outstanding balances may be deducted from rev
            share payments in the event the balance is not cleared within 30
            days. Company and agent further agree that all outstanding balances
            of the agent shall be paid promptly by an agent whose company
            affiliation is terminated.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>INSIDER TRADING</p>
          <p>
            Securities legislation prohibits “persons in a special relationship
            with the Company”, which may include an agent, from purchasing or
            selling shares of the Company, The Real Brokerage Inc. (TSXV: REAX)
            (OTCQX: REAXF), if they have material and nonpublic information
            about the company. This prohibition is known as Insider Trading.
          </p>
          <p className='mt-2'>
            <span className='font-primary-medium'>
              Persons in a special relationship with the Company
            </span>{' '}
            - Include a person who obtains material non-public information from
            any of the following; an employee of the company, or any person
            engaging in or proposing to engage in business or professional
            activity with the Company.
          </p>
          <p className='mt-2'>
            <span className='font-primary-medium'>Material</span> - Information
            which could reasonably be expected to affect the price of stock or
            would be considered important in the determination of whether to
            purchase or sell the stock, such as the stock becoming listed on a
            national stock exchange, projections of future earnings or losses,
            or a change in senior management.
          </p>
          <p className='mt-2'>
            <span className='font-primary-medium'>Nonpublic</span> - Information
            which is not generally known or available to the public. Generally,
            information remains nonpublic until the close of the second full
            trading day after the company information is released.
          </p>
          <div className='lg:ml-10 ml-5'>
            <p className='font-primary-medium mt-2'>
              Dissemination of Material Nonpublic Information
            </p>
            <p>
              An agent may at no time disseminate material nonpublic information
              to others, which includes family members, anyone who lives in your
              household, other persons or entities. This includes recommending
              that the stock be either purchased or sold.
            </p>
            <div className='lg:ml-10 ml-5'>
              <p className='font-primary-medium mt-2'>Tipping</p>
              <p>
                Persons in a special relationship with the Company are
                prohibited from informing anyone of a material fact or a
                material change before that material information has been
                generally disclosed. This is commonly referred to as “tipping”.
                A “tippee” is a person in a special relationship with the
                Company who learns material information from someone. This is
                not limited to communications obtained from senior management,
                it could also include unauthorized disclosures from Company
                personnel.
              </p>
            </div>
            <p className='font-primary-medium mt-2'>Other Companies’ Stock</p>
            <p>
              The Insider Trading Policy discussed here is not limited to The
              Real Brokerage, Inc. stock, but also applicable to the securities
              of other firms with which the Company may be in negotiations,
              acquiring or investing.
            </p>
            <p className='font-primary-medium mt-2'>
              Prohibition on Speculation
            </p>
            <p>
              Purchases of the Company’s securities are for investment purposes
              only. All trades with the intention of a quick profit are
              prohibited. In addition, speculative trading in securities of
              another public company contemplated in either purchasing or being
              purchased by Company is prohibited.
            </p>
            <p className='font-primary-medium mt-2'>
              Prohibition on Margin Accounts
            </p>
            <p>
              Company personnel and related persons are prohibited from
              operating margin accounts for the purpose of purchasing or holding
              the Company’s securities, except with prior approval of the
              Compliance Committee.
            </p>
            <p className='font-primary-medium mt-2'>
              Use of Discretionary Accounts
            </p>
            <p>
              To comply with Insider trading laws, Company personnel and related
              persons who have a discretionary account with a broker must advise
              them there are to be no purchases or sales of the Company’s
              securities without first discussing it with that person.
            </p>
            <p className='font-primary-medium mt-2'>
              Trades Pursuant to the Company’s Stock Option Plan
            </p>
            <p>
              Elections to participate, changes in participation or instructions
              to sell the Company’s securities under the Company’s Stock Option
              Plan (the “Plan) cannot be made at any time when in possession of
              material non-public information.
            </p>
            <p className='font-primary-medium mt-2'>Compliance</p>
            <p>
              For further information, Agent may refer to “The Real Brokerage
              Inc. Stock Trading Policy.” Agent agrees to the above described
              insider trading policy and understands it is their responsibility
              to comply and be familiar with the policy.
            </p>
          </div>
        </div>

        <div className='mt-5'>
          <p className='mt-2 font-bold text-violet-700'>TEAMS</p>
          <p className='font-primary-medium'>Formation Requirements</p>
          <p>
            Teams may be formed at any time during an agent’s tenure with the
            Company. To qualify for team status there is a collective annual
            production requirement of its members of at least $5 million in
            closed volume.
          </p>
          <p className='font-primary-medium mt-2'>Cap Status</p>
          <p>
            Existing individual agents with a full cap status who wish to join a
            team should note that the reset to the team cap status will take
            place on their next anniversary date. The cap status of new agents
            to the Company who are joining a team will be established during the
            affiliation process.
          </p>
          <p className='font-primary-medium mt-2'>Components of a Team</p>
          <p>
            A team consists of two or more agents who work collectively to
            perform real estate activities for the Company. A team will have one
            team leader and at least one team member.
          </p>
          <p className='font-primary-medium mt-2'>
            Responsibilities of Team Members
          </p>
          <p>
            Team members will be provided various services for which they will
            return an agreed percentage of commissions earned to the team leader
            on each closed transaction. A team member agreement will be signed
            by all team members, which enumerates their commission split and
            responsibilities to the Company and team.
          </p>
          <p className='font-primary-medium mt-2'>
            Responsibilities of Team Leaders
          </p>
          <p>
            Although provincial brokers are ultimately responsible for the real
            estate activities of each agent, the team leader is responsible for
            every activity of the team. The team leader should develop business
            practices within the team that promote and comply with all federal,
            provincial and local laws, together with rules and regulations of
            the provincial licensing authority, affiliated associations/board,
            and multi-listing services. The team leader should provide services
            and leadership which are beneficial to the team member and
            proportionate to the percentage of commissions being charged. A team
            leader agreement will be signed by the team leader which enumerates
            these responsibilities and their commission split.
          </p>

          <div className='mt-5'>
            <p className='font-primary-medium underline'>
              Classifications of Teams
            </p>
            <div>
              <p className='font-primary-medium mt-2'>Standard Team</p>
              <p>
                Team members will return a minimum of 40% of commissions earned
                on each closed transaction, comprising at least 25% to the team
                leader and 15% to the Company. Production requirement for a
                Standard Team is at least $5 Million in Closed Transactions. In
                exchange team members are to be at a $6,000 cap.
              </p>
              <div className='lg:ml-10 ml-5'>
                <p className='font-primary-medium mt-2'>Billing / Accounting</p>
                <p>
                  Each transaction will be in the name of the team member so
                  they will have the ability to participate in the equity plan
                  and the Company will pay revenue share based on their
                  individual production. Company will also have the ability to
                  track their individual production. Commission splits agreed to
                  in the team member agreement will apply. Individual team
                  member production will be accumulated to determine the total
                  production of the team.
                </p>
                <p className='font-primary-medium mt-2'>Elite Status</p>
                <p>
                  Should the team reach the production requirements for elite
                  status, only the team leader will receive the award.
                </p>
              </div>
            </div>
            <div>
              <p className='font-primary-medium mt-2'>Domestic Team</p>
              <p>
                A Domestic Team is comprised of either a domestic couple or
                related family members (maximum of 2 total team members) which
                share a commission cap. There is no production requirement for a
                domestic team.
              </p>
              <div className='lg:ml-10 ml-5'>
                <p className='font-primary-medium mt-2'>Billing / Accounting</p>
                <p>
                  Transactions may be in the name of the agent working the
                  transaction, but all transactions will be applied to one
                  Domestic Team account from which the team may participate in
                  the equity program and from which Revenue share will be paid.
                  If they choose to opt in, the team may opt in to one shared
                  Lofty Account.
                </p>
                <p className='font-primary-medium mt-2'>Elite Status</p>
                <p>
                  Should the team reach the production requirements for elite
                  status both team member and team leader will be recognized
                  collectively and will receive one Elite award.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <p className='mt-2 font-bold text-violet-700'>GROUPS</p>
          <p className='font-primary-medium'>Formation Requirements</p>
          <p>
            A group is a collection of one or more agents who agree to work
            together under one marketing name. A group may be formed at any time
            during the agents’ tenure with the Company and has no production
            requirement.
          </p>
          <div className='lg:ml-10 ml-5'>
            <p className='font-primary-medium mt-2'>Capping</p>
            <p>
              Each member is operating under an individual full cap and will cap
              as an individual agent, not as a member of the group. Each member
              of the group has their individual revenue share organization. Each
              member in a Group is eligible for Elite Status awards.
            </p>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>ELITE AGENT PROGRAM</p>
          <div>
            <p>
              Agents who achieve exceptional sales volume and embody the core
              cultural values of Real are offered the opportunity to earn Elite
              Agent status. ONE of the following production benchmarks need to
              be met:
            </p>
            <div className='lg:ml-10 ml-5'>
              <p>
                Pay the full annual cap AND generate $9,000 in post cap
                transaction fees{' '}
                <span className='underline font-primary-medium'>-or- </span>
                <p>
                  Generate a minimum GCI of $500,000 and 10 completed
                  transactions with all sales prices $1M or above.
                </p>
              </p>
            </div>
          </div>
        </div>

        <div className='mt-5'>
          <em className='font-primary-medium block'>Elite Awards</em>
          <p>
            Upon achievement of the production benchmark within an agent’s
            anniversary year, the Elite Agent will be awarded $16,000 in stock
            which vest over 3 years annually based on RSU award date.
          </p>
          <p className='mt-2'>
            Additional $8k in stock is awarded upon completion of cultural
            contributions back to the entire agent population through Real
            Academy. This award vests over a 3 year annual period based on RSU
            award dates.
          </p>
          <p className='mt-2'>
            The $275 capped transaction fee will be reduced to $100 upon the
            agent achieving the Elite Agent Award Status. All fees will reset
            annually upon the Agent’s anniversary date.
          </p>
          <em className='mt-2 block'>
            Agents who are at a 1⁄2 cap on the team will need to pay an
            additional $6,000 in company fees before their fees drop to $100.
            Team members are not eligible for elite awards unless they are at a
            full cap.
          </em>
          <em className='font-primary-medium mt-2 block'>
            Elite status is earned by individuals or by team leads when they
            achieve the benchmarks described above. The Elite Agent program is
            reviewed annually and is subject to change in the future
          </em>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>REVENUE SHARE</p>
          <p>
            Agents who help to grow the Real Brokerage community by referring
            other agents to the brokerage are paid a referral fee in the form of
            Revenue Share when the incoming agent identifies the agent(s) as
            being a sponsoring agent. The sponsoring agent(s) receives revenue
            share until the producing agent caps each anniversary year. This
            program is subject to change. Agents found to be enticing or
            “gaming” the system in any manner are subject to being removed from
            the program. The chart below provides an example and calculation of
            the Revenue Share payouts.
          </p>
        </div>

        <p className='text-center my-5'>REVENUE SHARE CHART</p>

        <div className='w-full scrollbar overflow-x-auto mt-5 text-sm'>
          <div className='md:p-4'>
            <table className='table-auto mx-auto' cellPadding={15}>
              <thead>
                <tr className='text-center'>
                  <th
                    className='font-primary-medium border border-lightBlue-300'
                    colSpan={4}
                  >
                    Single Sponsor
                  </th>
                </tr>
                <tr>
                  <th className='border border-lightBlue-300'> </th>
                  <th className='border border-lightBlue-300 bg-cyan-50 font-primary-regular'>
                    SINGLE SPONSOR REFERRAL BONUS GCI%
                  </th>
                  <th className='border border-lightBlue-300 bg-cyan-50 font-primary-regular'>
                    NUMBER OF QUALIFIED TIER 1 AGENTS REQUIRED TO UNLOCK TIER
                  </th>
                  <th className='border border-lightBlue-300 bg-cyan-50 font-primary-regular'>
                    MAX/AGENT PER YEAR
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='border border-lightBlue-300'>
                  <td className='border font-bold border-lightBlue-300 text-center '>
                    TIER 1
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    5% up to ANNUAL CAP
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    1+
                  </td>
                  <td>$4,000</td>
                </tr>
                <tr className='border border-lightBlue-300'>
                  <td className='font-bold border  border-lightBlue-300 text-center'>
                    TIER 2
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    4% up to ANNUAL CAP
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    10+
                  </td>
                  <td>$3,200</td>
                </tr>
                <tr className='border border-lightBlue-300'>
                  <td className='font-bold border border-lightBlue-300 text-center'>
                    TIER 3
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    3% up to ANNUAL CAP
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    15+
                  </td>
                  <td>$2,400</td>
                </tr>
                <tr className='border border-lightBlue-300'>
                  <td className='font-bold border border-lightBlue-300 text-center'>
                    TIER 4
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    2% up to ANNUAL CAP
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    20+
                  </td>
                  <td>$1,600</td>
                </tr>
                <tr className='border border-lightBlue-300'>
                  <td className='font-bold border border-lightBlue-300 text-center'>
                    TIER 5
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    1% up to ANNUAL CAP
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    25+
                  </td>
                  <td>$800</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className='w-full scrollbar overflow-x-auto mt-5 text-sm'>
          <div className='md:p-4'>
            <table className='table-auto mx-auto' cellPadding={15}>
              <thead>
                <tr className='text-center'>
                  <th
                    className='font-primary-medium border border-lightBlue-300'
                    colSpan={4}
                  >
                    Co-Sponsor
                  </th>
                </tr>
                <tr>
                  <th className='border border-lightBlue-300'> </th>
                  <th className='border border-lightBlue-300 bg-cyan-50 font-primary-regular'>
                    CO-SPONSOR REFERRAL BONUS GCI%
                  </th>
                  <th className='border border-lightBlue-300 bg-cyan-50 font-primary-regular'>
                    NUMBER OF QUALIFIED TIER 1 AGENTS REQUIRED TO UNLOCK TIER
                  </th>
                  <th className='border border-lightBlue-300 bg-cyan-50 font-primary-regular'>
                    MAX/AGENT PER YEAR
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='border border-lightBlue-300'>
                  <td className='border font-bold border-lightBlue-300 text-center '>
                    TIER 1
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    2.25%
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    1+
                  </td>
                  <td>$1,800</td>
                </tr>
                <tr className='border border-lightBlue-300'>
                  <td className='font-bold border  border-lightBlue-300 text-center'>
                    TIER 2
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    1.80%
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    10+
                  </td>
                  <td>$1,440</td>
                </tr>
                <tr className='border border-lightBlue-300'>
                  <td className='font-bold border border-lightBlue-300 text-center'>
                    TIER 3
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    1.35%
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    15+
                  </td>
                  <td>$1,080</td>
                </tr>
                <tr className='border border-lightBlue-300'>
                  <td className='font-bold border border-lightBlue-300 text-center'>
                    TIER 4
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    0.90%
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    20+
                  </td>
                  <td>$720</td>
                </tr>
                <tr className='border border-lightBlue-300'>
                  <td className='font-bold border border-lightBlue-300 text-center'>
                    TIER 5
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    0.45%
                  </td>
                  <td className='border border-lightBlue-300 text-center'>
                    25+
                  </td>
                  <td>$360</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className='mt-5'>
          <p className='font-primary-medium'>UNLOCKING TIERS</p>
          <p>
            For the purposes of unlocking Revenue Share tiers a producing agent
            with a single sponsor will count as one (1) Qualified Tier 1 Agent
            for the Sponsoring Agent. A producing agent with two sponsors
            (Co-sponsored) will count as one half (.5) of a Qualified Tier 1
            Agent for the Sponsoring Agent.
          </p>
        </div>
        <div className='mt-10'>
          <p className='font-primary-medium'>DISTRIBUTION OF REVENUE SHARE</p>
          <ul className='list-disc lg:ml-10 ml-5'>
            <li>
              <p className='font-bold'>No sponsoring agent named</p>
              <p>
                If no sponsor is indicated in the Sponsoring Agent paragraph no
                agent will participate in revenue sharing for the incoming
                Agent.
              </p>
            </li>
            <li>
              <p className='font-bold'>One sponsoring agent named</p>
              <p>
                If only one agent is named in the Sponsoring Agent paragraph
                he/she shall receive 100% of the eligible amount to be paid in
                revenue share, based on the sponsored Agent’s monthly closed
                transaction(s).
              </p>
            </li>
            <li>
              <p className='font-bold'>Two sponsoring agents named</p>
              <p>
                If two agents are named in the Sponsoring Agent paragraph, each
                Sponsoring Agent shall be considered co-sponsors and shall
                receive forty-five percent (45%) of the eligible amount to be
                paid in revenue share, based on the sponsored Agent’s monthly
                closed transactions. The Company shall receive ten percent (10%)
                of the eligible amount to be paid in revenue share.
              </p>
            </li>
          </ul>
        </div>

        <div className='mt-5'>
          <p className='font-primary-medium'>DEFINITION OF PRODUCING AGENT </p>
          <p>
            A producing agent is defined as an affiliated agent that has
            contributed at least $450 in company dollar to the Company in the
            last six month period. Only an Agent who is qualified as a
            “producing agent” is able to participate in and receive Revenue
            Share. Transactions that qualify toward the $450 company dollar
            requirement shall include listings, sales, or leases for which the
            agent is a named agent on the purchase and sale or lease contract.
            Newly sponsored agents will be granted a grace period of producing
            agent status to receive Revenue Share for the first six months of
            their anniversary year without meeting the requirements of this
            paragraph.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-primary-medium'>QUALIFICATION OF TIER 1 AGENTS</p>
          <p>
            For an agent to qualify as a Tier 1 agent in revenue share
            calculations he or she must be a producing agent, as is described
            above. An agent not meeting this definition will not be considered
            in revenue share calculations and may not be considered in unlocking
            tiers. Notwithstanding the foregoing paragraph, referrals will
            qualify as a transaction which qualifies an Agent to receive Revenue
            Share payments, but shall not qualify as a transaction which unlocks
            Revenue Share tiers.
          </p>
        </div>
        <div className='mt-5'>
          <p className='font-primary-medium'>
            QUALIFICATION TO RECEIVE REVENUE SHARE
          </p>
          <p>
            To receive revenue share an agent must meet the definition of a
            “producing agent” as defined above.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-primary-medium'>
            PARTICIPATION AND PROCESSING FEES
          </p>
          <p>
            An annual $175 participation fee and a 1.2% monthly processing fee
            on Revenue Payments will be assessed for all agents participating in
            the Revenue Share program. The participation fee will be deducted
            from the first Revenue Share payment of the Agent’s anniversary
            year. The processing fee will be deducted from the first and each
            subsequent revenue share payment of the Agent’s anniversary year.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>ATTRACTION / RECRUITING</p>
          <p>
            In any conversation with potential agents regarding affiliation,
            Agent shall not suggest, advise, instruct, or give any type of
            guidance how to nullify, invalidate, or bypass an existing
            contractual agreement the candidate has with his current brokerage,
            including but not limited to an independent contractor agreement,
            corporate franchise agreement, or non-compete agreement. Agent will
            indemnify and hold harmless the Company for any damages incurred as
            a result of actions which would violate this paragraph. An Agent
            whose actions are found to have violated this paragraph is subject
            to immediate separation from the Company.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>AGENT ATTRACTION POLICIES</p>
          <div className='mt-2'>
            <p className='font-primary-medium underline'>
              Attraction Activities Not Allowed:
            </p>
            <ul className='list-disc lg:ml-10 ml-5'>
              <li>Don’t disparage agents from other brokerages.</li>
              <li>
                <p>Don’t disparage other agents affiliated with Real.</p>
                <p className='lg:ml-10 ml-5'>
                  “Be Kind” is one of the core values of Real. Disparaging
                  another agent is not being kind and is completely unnecessary.
                  Consider Apple, Inc. a company that currently commands a large
                  segment of the computer manufacturing industry. In all of
                  Apple’s advertisements, promotions and presentations, Apple
                  refuses to disparage anyone. They focus on their purpose
                  statement, “Everyone Matters so Each One Matters.”
                </p>
              </li>
              <li>
                Advertisements created solely for the purpose of attracting
                agents to the Company for the benefit of revenue share is
                strictly prohibited.
                <ul className='list-disc lg:ml-10 ml-5'>
                  This would include, but is not limited to:
                  <li>
                    Customized sponsored social media ads (Use of company
                    templates are allowed.)
                  </li>
                  <li>Email campaigns or email solicitation</li>
                  <li> Slydial or dropping messages into voicemail boxes</li>
                  <li>Click funnels or similar programs</li>
                  <li>Auto dialers</li>
                  <li>Group texting</li>
                  <li>Print ads focused on attraction</li>
                  <li>Postcard campaigns or mailers focused on attraction</li>
                  <li>
                    Disparaging comparisons of other brokerages to Real in any
                    printed or digital format
                  </li>
                </ul>
              </li>
              <li>
                Meetings organized to explain Real’s model or training events
                primarily created for agent attraction purposes are strictly
                prohibited
              </li>
              <li>
                Enticing or promising agent “value” by selecting you as sponsor
              </li>
              <ul className='list-disc lg:ml-10 ml-5'>
                <li>Lead generation platforms</li>
                <li>Coaching</li>
                <li>Trips</li>
                <li>Leads</li>
                <li>CRM</li>
                <li>Rebates</li>
                <li>Currency in return for naming them as sponsor</li>
                <li>Office space</li>
                <li>
                  Other valuables or consideration to name you as a sponsor vs
                  another Real agent
                </li>
                <li>
                  Bait & Switch - This is defined as enticing/influencing an
                  agent to name you as the sponsor instead of the other Real
                  agent who referred the prospective agent to you for validation
                </li>
                <li>Use of 3rd party recruiting services on your behalf</li>
              </ul>
            </ul>
          </div>
          <div className='mt-2'>
            <p className='font-primary-medium underline'>
              Allowed Attraction Activities
            </p>
            <ul className='list-disc lg:ml-10 ml-5'>
              <li>
                One on one conversations with other agents you have a
                relationship with
              </li>
              <ul className='list-disc lg:ml-10 ml-5'>
                <li>Agent from a prior transaction</li>
                <li>Agent from a current transaction</li>
                <li>
                  Discussions with peers from the real estate industry with whom
                  you have an existing relationship
                </li>
              </ul>
              <li>Inviting outside agents to Company training events</li>
              <li>
                Posting on your social media (your followers) demonstrating
                success, value and wins in real estate
              </li>
              <li>
                Posting on your social media (your followers) Company
                announcements
              </li>
              <li>Be a leader in your community</li>
              <li>Be a resource to agents</li>
              <li>Share your story</li>
              <li>Compliment other brokerage models</li>
              <li>Boast our core values</li>
              <li>Share our purpose statement</li>
            </ul>
          </div>
          <p className='font-primary-medium italic mt-5'>
            An Agent whose actions are found to have violated the provisions of
            these attraction policies may be subject to separation from the
            Company.
          </p>
          <p className='mt-5'>
            <span className='font-primary-medium'>
              PROHIBITION AGAINST “SPONSORSHIP OFFERINGS”
            </span>{' '}
            - (From ICA) Any offering, enticement, promise of anything of value
            made by an existing agent of the Company or violation of any of the
            attraction standards as outlined above, to a potential licensee with
            an intention of having the licensee name the Agent as the “referral
            agent” or sponsor, is strictly prohibited. Any agent who is shown
            through his or her actions to have acted in this manner is subject
            to separation from the Company and forfeits all future revenue
            share. Agent hereby agrees that they will indemnify and hold
            harmless Company for any such separation.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>UNDISCLOSED TOPICS</p>
          <p>
            Decisions regarding how to address topics not discussed in this
            manual will be at sole discretion of the Company.
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>
            REVISIONS/MODIFICATIONS TO THIS AGREEMENT
          </p>
          <p>
            The Company may, in its sole discretion, periodically change
            portions of this manual. Notifications of these changes will be
            communicated through the Real Broker app and/or Workplace. Company
            will provide agents a review period of 10 calendar days to make
            written inquiries of changes for which they need clarification.
            Changes will be deemed accepted at the end of the review period. The
            email agents may use to make inquiries during the review period is{' '}
            <a
              href={`mailto:${getSupportEmail(userDetail?.accountCountry)}`}
              className='text-primary underline'
            >
              {getSupportEmail(userDetail?.accountCountry)}
            </a>
          </p>
        </div>

        <div className='mt-5'>
          <p className='font-bold text-violet-700'>PROVINCIAL ADDENDUM</p>
          <p>
            I acknowledge receipt of the provincial addendum to the Independent
            Contractor’s Agreement pertinent to the province in which I am
            licensed to perform real estate activities. I understand that this
            addendum contains laws and rules specific to my province of license
            and that I am responsible for reading, understanding and complying
            with them.
          </p>
        </div>

        <p className='mt-5'>Please find your provincial addendum below:</p>
        <ul className='list-disc ml-4 mt-2'>
          <li className='text-primary mb-1'>
            <a
              href={`${config.assetsBaseUrl}/policies-and-procedures/canada/AB.pdf`}
              target='_blank'
              rel='noreferrer'
            >
              Alberta
            </a>
          </li>
          <li className='text-primary mb-1'>
            <a
              href={`${config.assetsBaseUrl}/policies-and-procedures/canada/BC.pdf`}
              target='_blank'
              rel='noreferrer'
            >
              British Columbia
            </a>
          </li>
          <li className='text-primary mb-1'>
            <a
              href={`${config.assetsBaseUrl}/policies-and-procedures/canada/MB.pdf`}
              target='_blank'
              rel='noreferrer'
            >
              Manitoba
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CAPoliciesAndProcedureForm;
