import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types';
import { getRateLimit } from '../../../slices/WealthManagementSlice';

export const WealthPlanRateBanner: React.FC = () => {
  const {
    auth: { userDetail },
    wealthManagement: { rateLimitResponse },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetail) {
      dispatch(getRateLimit());
    }
  }, [dispatch, userDetail]);

  const referralsPerMonthLimit =
    rateLimitResponse.data?.referralsPerMonthLimit ?? 0;
  const numberOfReferralsLeftThisMonth =
    rateLimitResponse.data?.numberOfReferralsLeftThisMonth ?? 0;

  return (
    <div className='w-full'>
      <div className='bg-grey-100 p-2.5 w-full flex items-center gap-2 rounded-lg mt-2'>
        <FontAwesomeIcon icon={faInfoCircle} className='text-zen-dark-8 ml-2' />
        <p className=' text-dark-8 font-zen-body'>
          You may send {referralsPerMonthLimit} WealthPlans to agents per month
        </p>
      </div>
      <div className='text-sm font-zen-body mt-1 text-dark'>
        <span className='text-zen-dark-12'>Remaining WealthPlans to send:</span>{' '}
        {numberOfReferralsLeftThisMonth}
      </div>
    </div>
  );
};
