import { capitalize, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  CreateCreditAccountApplication,
  CreateDebitAccountApplication,
} from '../../openapi/wallet';
import {
  useCreditApplication,
  useDebitApplication,
  useWallet,
} from '../../query/wallet/useWallet';
import { fetchNationalIdentifications } from '../../slices/AgentSlice';
import { ISelectOption, RootState, YesNoType } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import { getCreateApplicationPayload } from '../../utils/WalletUtils';
import DefaultLoader from '../DefaultLoader';
import StepByStepContainer, {
  StepConfig,
} from '../StepByStep/StepByStepContainer';
import ZenRoute from '../Zen/ZenRoute';
import ZenAgentOnboardingLayout from '../ZenAgentOnboardingLayout';
import WalletPersonalInformationStep from './WalletPersonalInformationStep';
import WalletRedirectionModal from './WalletRedirectionModal';
import WalletReviewApplicationStep from './WalletReviewApplicationStep';
import WalletSsnStep from './WalletSsnStep';

type Match = {
  type: 'credit' | 'debit';
  offeringId: string;
};

export enum WalletApplicationStepName {
  PERSONAL_INFORMATION_STEP = 'PERSONAL_INFORMATION_STEP',
  SSN_STEP = 'SSN_STEP',
  REVIEW_APPLICATION_STEP = 'REVIEW_APPLICATION_STEP',
}

export interface WalletApplicationFormState {
  // Step 1: Personal Information
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;

  // Billing Address
  street: string;
  apartmentOrUnitOrSuit?: string;
  city: string;
  state: ISelectOption;
  zipCode: string;
  isShippingAddressSameAsBilling: YesNoType[];

  // Shipping Address
  shippingStreet: string;
  shippingApartmentOrUnitOrSuit?: string;
  shippingCity: string;
  shippingState: ISelectOption;
  shippingZipCode: string;

  // Step 2: SSN
  birthDate: string;
  ssn: string;
}

const steps: StepConfig<
  WalletApplicationFormState,
  WalletApplicationStepName
>[] = [
  {
    name: WalletApplicationStepName.PERSONAL_INFORMATION_STEP,
    Component: WalletPersonalInformationStep,
    hidePagination: true,
  },
  {
    name: WalletApplicationStepName.SSN_STEP,
    Component: WalletSsnStep,
    hidePagination: true,
  },
  {
    name: WalletApplicationStepName.REVIEW_APPLICATION_STEP,
    Component: WalletReviewApplicationStep,
    hidePagination: true,
  },
];

const WalletApplicationSteps: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    auth: { userDetail },
    agentDetail: { nationalIdentificationsResponse: identification },
  } = useSelector((state: RootState) => state);

  const { type, offeringId } = useParams<Match>();
  const [formUrl, setFormUrl] = useState<string>();
  const isLoading = identification?.loading || isEmpty(identification.data);

  const { data: wallet } = useWallet();
  const { mutateAsync: createDebitApplication } = useDebitApplication();
  const { mutateAsync: createCreditApplication } = useCreditApplication();

  useEffect(() => {
    if (isEmpty(identification.data)) {
      dispatch(fetchNationalIdentifications(userDetail?.id!));
    }
  }, [dispatch, identification.data, userDetail?.id]);

  // This is executed when application is created followed by fetching the updated wallet
  useEffect(() => {
    wallet?.pendingBankingApplications?.forEach((application) => {
      if (application.formUrl) {
        setFormUrl(application.formUrl);
      }
    });
  }, [wallet]);

  const defaultValues: WalletApplicationFormState = {
    // Personal Info
    firstName: userDetail?.firstName!,
    lastName: userDetail?.lastName!,
    emailAddress: userDetail?.emailAddress!,
    phoneNumber: userDetail?.phoneNumber!,

    // Billing Address
    street: userDetail?.primaryAddress?.streetAddress1!,
    apartmentOrUnitOrSuit: userDetail?.primaryAddress?.streetAddress2,
    city: userDetail?.primaryAddress?.city!,
    state: {
      label: capitalizeEnum(
        userDetail?.primaryAddress?.stateOrProvince ?? 'State',
      ),
      value: userDetail?.primaryAddress?.stateOrProvince ?? '',
    },
    zipCode: userDetail?.primaryAddress?.zipOrPostalCode!,
    isShippingAddressSameAsBilling: [],

    // Shipping Address
    shippingStreet: '',
    shippingApartmentOrUnitOrSuit: '',
    shippingCity: '',
    shippingState: { label: 'State', value: '' },
    shippingZipCode: '',

    // SSN Step
    birthDate: userDetail?.birthDate!,
    ssn:
      identification?.data?.identifications?.find((id) => id.type === 'SSN')
        ?.id ?? '',
  };

  const onSubmit = async (formData: WalletApplicationFormState) => {
    const requestObj = getCreateApplicationPayload(
      type,
      offeringId,
      userDetail?.primaryAddress?.country,
      formData,
    );

    if (type === 'debit') {
      await createDebitApplication(requestObj as CreateDebitAccountApplication);
    }

    if (type === 'credit') {
      await createCreditApplication(
        requestObj as CreateCreditAccountApplication,
      );
    }
  };

  return (
    <ZenRoute title='Wallet Application'>
      <ZenAgentOnboardingLayout
        title={`Sign up for ${capitalize(type)} Card`}
        onClose={() => history.push('/wallet')}
        modalTitle='Do you want to cancel the application?'
        modalSubtitle='The information won’t be saved and you’ll go back to the landing page.'
        modalSubmitText='Cancel'
        textClassNames='font-inter'
        hideLogout
      >
        {isLoading ? (
          <DefaultLoader />
        ) : (
          <StepByStepContainer<
            WalletApplicationFormState,
            WalletApplicationStepName
          >
            steps={steps}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            reValidateMode='onChange'
            mode='onChange'
          />
        )}
      </ZenAgentOnboardingLayout>

      <WalletRedirectionModal formUrl={formUrl} />
    </ZenRoute>
  );
};

export default WalletApplicationSteps;
