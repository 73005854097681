import { Earning } from '../WealthManagementTypes';
import { LearnMoreTile } from './LearnMoreTile';

export const LearnMoreCarousel = () => {
  return (
    <div className='p-3 lg:pl-12'>
      <div className='text-3xl md:text-[42px] leading-[100%] font-semibold font-poppins text-black lg:px-5 mt-5'>
        Learn More
      </div>
      <div className='flex scrollbar overflow-x-scroll gap-1 mt-10'>
        {Object.values(Earning).map((earning) => (
          <LearnMoreTile key={earning} type={earning} />
        ))}
      </div>
    </div>
  );
};
