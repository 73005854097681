import { get, last } from 'lodash';
import qs from 'qs';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useQueryParams from '../../../hooks/useQueryParams';
import {
  ApplicationControllerApi,
  ApplyToRealRequest,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { saveApplication } from '../../../slices/AuthSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { isShowExtraFieldInReviewStep } from '../../../testUtils/OnboardingUtils';
import { AppDispatch, FormFieldConfig, RootState } from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import Button from '../../Button';
import SubmitButton from '../../SubmitButton';
import { FormDataType } from '../ApplicationForm';

export interface ReviewProps {
  title: string;
  questions: FormFieldConfig[];
  setCurrentIndex(currentIndex: number): void;
  formData: FormDataType;
  setIsReviewing(isReviewing: boolean): void;
}

interface Query {
  sponsorCode?: string;
  sponsorName?: string;
  loi?: string;
}

const Review: React.FC<ReviewProps> = ({
  title,
  setCurrentIndex,
  formData,
  questions,
  setIsReviewing,
}) => {
  const history = useHistory();
  const { sponsorCode, sponsorName, loi } = useQueryParams<Query>();

  const dispatch: AppDispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    const applyToRealReq: ApplyToRealRequest = {
      applicationId: last(userDetail?.applications)?.id!,
      sponsoredByCode: sponsorCode as string,
    };

    try {
      const { data } = await new ApplicationControllerApi(
        getYentaConfiguration(),
      ).applyAsRealAgent(applyToRealReq);

      await dispatch(saveApplication(data));

      if (loi === 'true') {
        history.push(
          `/onboarding/application-loi-signing?${qs.stringify({
            sponsorCode,
            sponsorName,
          })}`,
        );
      } else {
        history.push(
          `/onboarding/application-form/success?${qs.stringify({
            sponsorCode,
            sponsorName,
          })}`,
        );
      }
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to save application', e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='w-full px-4 lg:max-w-xl mx-auto my-10'>
      <p className='text-2xl font-primary-medium text-center mb-4'>{title}</p>
      <div className='divide-y'>
        {questions.map((question, index) => {
          const displayValue = get(formData, question.name)
            ? question.resolveFieldDisplayValue
              ? question.resolveFieldDisplayValue(get(formData, question.name))
              : get(formData, question.name)
            : 'N/A';
          return (
            <div key={question.name} className='py-2 flex items-center'>
              <div>
                <div className='w-7 h-7 rounded-full bg-gray-200 flex justify-center items-center'>
                  {index + 1}
                </div>
              </div>
              <div className='flex-grow px-3'>
                <p className='leading-5'>
                  {question.reviewLabel ?? question.label}
                </p>
                {displayValue instanceof File ||
                (typeof displayValue === 'string' &&
                  displayValue.startsWith('http')) ? (
                  <div className='border border-dashed p-2'>
                    <img
                      className='object-cover w-1/2 h-auto mx-auto'
                      src={
                        typeof displayValue === 'object'
                          ? URL.createObjectURL(displayValue)
                          : displayValue
                      }
                      alt='imagetoupload'
                    />
                  </div>
                ) : (
                  <p className='leading-5 text-gray-500'>{displayValue}</p>
                )}
                {question.extraFields &&
                  question.extraFields.map((extraField) => {
                    const extraFieldValue = get(formData, extraField.name)
                      ? extraField.resolveFieldDisplayValue
                        ? extraField.resolveFieldDisplayValue(
                            get(formData, extraField.name),
                          )
                        : get(formData, extraField.name)
                      : 'N/A';
                    return extraFieldValue &&
                      isShowExtraFieldInReviewStep(
                        extraField.name,
                        displayValue,
                        formData,
                      ) ? (
                      <div key={extraField.name}>
                        <p className='leading-5'>
                          {extraField.reviewLabel || extraField.label}
                        </p>
                        <p className='leading-5 text-gray-500'>
                          {extraFieldValue}
                        </p>
                      </div>
                    ) : undefined;
                  })}
              </div>
              <Button
                label='Edit'
                type='secondary'
                onClick={() => {
                  setCurrentIndex(index);
                  setIsReviewing(false);
                }}
              />
            </div>
          );
        })}
      </div>
      <div className='flex mt-4'>
        <SubmitButton
          label='Submit'
          isSubmitting={isSubmitting}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default Review;
