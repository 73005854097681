import { capitalize, values } from 'lodash';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import ZenControlledHTMLSelectInput from '../components/Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledStateOrProvinceInput from '../components/Zen/Input/ZenControlledStateOrProvince';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenButton from '../components/Zen/ZenButton';
import ZenSidebarModal from '../components/Zen/ZenSidebarModal';
import {
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
  BankAccountControllerApi,
  BankAccountResponse,
  CreateBankAccountRequest,
  CreateBankAccountRequestMsdxBatchEnum,
  EditBankAccountRequest,
  EditBankAccountRequestMsdxBatchEnum,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { AgentAddressTypeEnum } from '../types';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import {
  capitalizeEnum,
  getRoutingNumberForCountry,
} from '../utils/StringUtils';

interface FormData {
  accountName?: string;
  accountNumber: string;
  bankName: string;
  city: string;
  country: AddressRequestCountryEnum;
  stateOrProvince: AddressRequestStateOrProvinceEnum;
  streetAddress1: string;
  streetAddress2?: string;
  addressType: string;
  zipOrPostalCode: string;
  bankRoutingNumber: string;
  wireRoutingNumber?: string;
  ftniSettlementId?: string;
  msdxBatch?: string;
  msdxGlNumber: string;
}

interface CreateBankAccountSidebarModalProps {
  isOpen: boolean;
  bankAccountDetails?: BankAccountResponse;
  readOnly?: boolean;
  onClose(): void;
}

const CreateBankAccountSidebarModal: React.FC<CreateBankAccountSidebarModalProps> = ({
  isOpen,
  bankAccountDetails,
  readOnly,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      accountName: bankAccountDetails?.accountName || '',
      accountNumber: bankAccountDetails?.accountNumber || '',
      bankName: bankAccountDetails?.bankName || '',
      city: bankAccountDetails?.bankAddress?.city || '',
      country: ((bankAccountDetails?.bankAddress?.country ||
        '') as unknown) as AddressRequestCountryEnum,
      stateOrProvince: ((bankAccountDetails?.bankAddress?.stateOrProvince! ||
        '') as unknown) as AddressRequestStateOrProvinceEnum,
      streetAddress1: bankAccountDetails?.bankAddress?.streetAddress1 || '',
      streetAddress2: bankAccountDetails?.bankAddress?.streetAddress2 || '',
      addressType: bankAccountDetails?.bankAddress?.type || '',
      zipOrPostalCode: bankAccountDetails?.bankAddress?.zipOrPostalCode || '',
      bankRoutingNumber: bankAccountDetails?.bankRoutingNumber || '',
      wireRoutingNumber: bankAccountDetails?.wireRoutingNumber || '',
      ftniSettlementId: bankAccountDetails?.ftniSettlementId || '',
      msdxGlNumber: bankAccountDetails?.msdxGlNumber || '',
      msdxBatch: bankAccountDetails?.msdxBatch,
    },
  });

  const selectedCountry = watch('country');

  const routingNumberPattern: RegExp = getRoutingNumberForCountry(
    selectedCountry,
  );

  const updateBankAccount = async (data: EditBankAccountRequest) => {
    try {
      await new BankAccountControllerApi(
        getYentaConfiguration(),
      ).editBankAccount(bankAccountDetails!.id!, data);
      dispatch(showSuccessToast('Successfully updated the bank account.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error updating the bank account', e);
      dispatch(
        showErrorToastForErrorCode(
          'We encountered an error while updating the bank account, Please try again in a few moments.',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      onClose();
    }
  };

  const createBankAccount = async (data: CreateBankAccountRequest) => {
    try {
      await new BankAccountControllerApi(
        getYentaConfiguration(),
      ).createBankAccount(data);
      dispatch(showSuccessToast('Successfully created the bank account.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error creating the bank account', e);
      dispatch(
        showErrorToastForErrorCode(
          'We encountered an error while creating the bank account, Please try again in a few moments.',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      onClose();
    }
  };

  const onSubmit = async (formValues: FormData) => {
    const data = {
      accountName: formValues.accountName!,
      accountNumber: formValues.accountNumber,
      bankName: formValues.bankName,
      addressRequest: {
        city: formValues.city,
        country: formValues.country,
        stateOrProvince: formValues.stateOrProvince,
        streetAddress1: formValues.streetAddress1,
        streetAddress2: formValues.streetAddress2,
        zipOrPostalCode: formValues.zipOrPostalCode,
        type: formValues.addressType,
      },
      bankRoutingNumber: formValues.bankRoutingNumber,
      wireRoutingNumber: formValues.wireRoutingNumber
        ? formValues.wireRoutingNumber
        : undefined,
      ftniSettlementId: formValues.ftniSettlementId,
      msdxBatch: formValues.msdxBatch as CreateBankAccountRequestMsdxBatchEnum &
        EditBankAccountRequestMsdxBatchEnum,
      msdxGlNumber: formValues.msdxGlNumber,
    };

    if (bankAccountDetails) {
      await updateBankAccount(data);
    } else {
      await createBankAccount(data as CreateBankAccountRequest);
    }
  };

  return (
    <ZenSidebarModal
      title={
        !!bankAccountDetails
          ? `${readOnly ? 'Viewing' : 'Editing'} ${
              bankAccountDetails?.bankName
            }`
          : 'Create Bank Account'
      }
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4'>
          <div>
            <ZenControlledTextInput<FormData, 'bankName'>
              control={control}
              label='Bank Name'
              name='bankName'
              placeholder='Enter Bank Name'
              rules={{ required: 'Please provide a bank name' }}
              readOnly={readOnly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'accountNumber'>
              control={control}
              label='Account Number'
              name='accountNumber'
              placeholder='Enter Account Number'
              rules={{
                required: 'Please provide an account number',
              }}
              readOnly={readOnly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'accountName'>
              control={control}
              label='Account Name'
              name='accountName'
              placeholder='Enter Account Name'
              readOnly={readOnly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'bankRoutingNumber'>
              control={control}
              label='Bank Routing Number'
              name='bankRoutingNumber'
              placeholder='Enter Bank Routing Number'
              rules={{
                required: 'Please provide the bank routing number',
                pattern: {
                  value: routingNumberPattern,
                  message: 'Please enter a valid bank routing number',
                },
              }}
              readOnly={readOnly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'wireRoutingNumber'>
              control={control}
              label='Wire Routing Number'
              name='wireRoutingNumber'
              placeholder='Enter Wire Routing Number'
              rules={{
                pattern: {
                  value: routingNumberPattern,
                  message: 'Please enter a valid wire routing number',
                },
              }}
              readOnly={readOnly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'ftniSettlementId'>
              control={control}
              label='FTNI Settlement ID'
              name='ftniSettlementId'
              placeholder='Enter FTNI Settlement ID'
              readOnly={readOnly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledHTMLSelectInput<FormData, 'msdxBatch'>
              control={control}
              label='MSDX Batch'
              name='msdxBatch'
              placeholder='MSDX Batch'
              options={[
                {
                  label: 'Choose MSDX Batch type',
                  value: '',
                },
                ...values(CreateBankAccountRequestMsdxBatchEnum).map(
                  (type) => ({
                    value: type,
                    label: capitalizeEnum(type),
                  }),
                ),
              ]}
              isRequired
              rules={{
                required: 'Please select the MSDX Batch type',
              }}
              readOnly={readOnly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'msdxGlNumber'>
              control={control}
              label='MSDX GL Number'
              name='msdxGlNumber'
              placeholder='Enter MSDX GL Number'
              rules={{
                required: 'Please provide the MSDX GL number',
              }}
              isRequired
              readOnly={readOnly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledHTMLSelectInput<FormData, 'addressType'>
              control={control}
              label='Address Type'
              name='addressType'
              placeholder='Address'
              options={[
                {
                  label: 'Choose address type',
                  value: '',
                  disabled: true,
                },
                ...values(AgentAddressTypeEnum).map((type) => ({
                  value: type,
                  label: capitalize(type),
                })),
              ]}
              rules={{
                required: 'Please select address type',
              }}
              readOnly={readOnly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledHTMLSelectInput<FormData, 'country'>
              name='country'
              control={control}
              label='Country'
              placeholder='Country'
              options={[
                {
                  label: 'Select Country',
                  value: '',
                  disabled: true,
                },
                ...values(AddressRequestCountryEnum).map((state) => ({
                  value: state,
                  label: capitalizeEnum(state),
                })),
              ]}
              rules={{
                required: 'Please select a country',
              }}
              readOnly={readOnly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'streetAddress1'>
              control={control}
              name='streetAddress1'
              placeholder='Enter the address'
              rules={{ required: 'Please enter the street address' }}
              label='Street Address Line 1'
              readOnly={readOnly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'streetAddress2'>
              control={control}
              name='streetAddress2'
              placeholder='#100'
              label='Street Address Line 2'
              readOnly={readOnly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'city'>
              control={control}
              label='City'
              name='city'
              placeholder='City'
              rules={{
                required: 'Please enter the city',
              }}
              readOnly={readOnly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledStateOrProvinceInput<FormData, 'stateOrProvince'>
              name='stateOrProvince'
              control={control}
              setValue={setValue}
              selectedCountry={selectedCountry}
              rules={{
                required: 'Please select a state / province.',
              }}
              readOnly={readOnly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'zipOrPostalCode'>
              control={control}
              label='Postal Code'
              name='zipOrPostalCode'
              placeholder='Postal Code'
              rules={{
                required: 'Please enter the postal code',
              }}
              readOnly={readOnly}
            />
          </div>
        </div>
        {!readOnly && (
          <div className='sticky bottom-0 flex flex-row justify-end items-center space-x-10 w-full p-4 bg-white'>
            <ZenButton
              label='Cancel'
              type='button'
              variant='secondary-outline'
              onClick={() => onClose()}
              isFullWidth
            />
            <ZenButton
              label='Save'
              type='submit'
              variant='primary'
              isSubmitting={isSubmitting}
              isDisabled={isSubmitting}
              isFullWidth
            />
          </div>
        )}
      </form>
    </ZenSidebarModal>
  );
};

export default CreateBankAccountSidebarModal;
