import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { cn } from '../../utils/classUtils';

interface GeminiPillProps {
  label: string;
  icon?: IconProp;
  iconStyles?: string;
  containerClassNames?: string;
}

const GeminiPill: React.FC<GeminiPillProps> = ({
  label,
  icon,
  iconStyles,
  containerClassNames,
}) => {
  return (
    <div
      className={cn(
        'flex items-center justify-center px-2 py-1 rounded-full shrink-0 w-max h-max',
        containerClassNames,
      )}
    >
      {!!icon && (
        <div className='h-6 w-6 flex items-center justify-center shrink-0'>
          <FontAwesomeIcon icon={icon} className={cn('text-sm', iconStyles)} />
        </div>
      )}
      <span className='font-zen-body-2 font-medium text-sm'>{label}</span>
    </div>
  );
};

export default GeminiPill;
