import { FeatureFlagTypeEnum } from '../types';
import BreadCrumbsWithUrls, { BreadCrumbsProps } from './BreadCrumbsWithUrls';
import FeatureFlagEnabledOnly from './FeatureFlagEnabledOnly';
import DoNotDisturbBanner from './Roar/OfficeHours/DoNotDisturbBanner';
import OutOfOfficeBanner from './Roar/OfficeHours/OutOfOffice/OutOfOfficeBanner';

interface PageLayoutProps extends BreadCrumbsProps {
  RightComponent?: React.FC | React.ReactElement;
  hideLayoutPaths?: boolean;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  path,
  RightComponent,
  hideLayoutPaths = false,
  children,
}) => {
  return (
    <div>
      {!hideLayoutPaths && (
        <div className='px-4 py-1 h-10 lg:h-14 lg:border-b border-trueGray-200 flex flex-row flex-nowrap items-center'>
          <BreadCrumbsWithUrls path={path} />
          {RightComponent}
        </div>
      )}
      <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.ROAR}>
        <DoNotDisturbBanner />
        <OutOfOfficeBanner />
      </FeatureFlagEnabledOnly>
      {children}
    </div>
  );
};

export default PageLayout;
