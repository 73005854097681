import { isEmpty } from 'lodash';
import {
  RevShareFeeResponseTypeEnum,
  RevSharePaymentResponse,
} from '../openapi/arrakis';

export const getAllRevShareFees = (
  contributors: RevSharePaymentResponse | null,
) => {
  const annualRevShareFee = (contributors?.revShareFees || [])?.find(
    (rev) => rev?.type === RevShareFeeResponseTypeEnum.AnnualFee,
  );

  const processingRevShareFee = (contributors?.revShareFees || [])?.find(
    (rev) => rev?.type === RevShareFeeResponseTypeEnum.ProcessingFee,
  );

  const originalAmount = !isEmpty(annualRevShareFee)
    ? annualRevShareFee?.originalAmount?.amount
    : processingRevShareFee?.originalAmount?.amount;

  const revShareAmount = originalAmount
    ? originalAmount
    : contributors?.amount?.amount || 0;

  const annualFee = annualRevShareFee?.fee?.amount ?? 0;

  const processingFee = (
    (contributors?.revShareFees || [])?.filter(
      (rev) => rev?.type === RevShareFeeResponseTypeEnum.ProcessingFee,
    ) || []
  ).reduce((accu, val) => {
    return accu + val.fee?.amount!;
  }, 0);

  return { annualFee, processingFee, revShareAmount };
};
