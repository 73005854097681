import { faSlidersSimple } from '@fortawesome/pro-light-svg-icons';
import { flatten, keys, map, merge, omit } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { FilterValue, IdType, TableInstance } from 'react-table';
import { ReactComponent as FilterIcon } from '../../../assets/icons/zen/zen-filter.svg';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  FeatureFlagTypeEnum,
  FilterColumnsToProcess,
  YesNoType,
} from '../../../types';
import { cn } from '../../../utils/classUtils';
import { isGeminiUIEnabled } from '../../../utils/switchToGeminiUtils';
import {
  getProcessedFilterData,
  PROCESS_FILTER_COLUMN,
} from '../../../utils/TableUtils';
import ControlledTextInputV7 from '../../ControlledTextInputV7';
import GeminiButton from '../../Gemini/GeminiButton';
import ZenButton from '../../Zen/ZenButton';

interface ZenFilterOptionProps<D extends object> {
  tableInstance: TableInstance<D>;
}

const ZenFilterOption = <D extends object>({
  tableInstance,
}: ZenFilterOptionProps<D>) => {
  const [open, setOpen] = useState(false);
  const {
    columns,
    setAllFilters,
    state: { filters },
  } = tableInstance;
  const getNewFilters = useCallback(
    () => merge({}, ...filters.map((f) => ({ [f.id]: f.value ?? null }))),
    [filters],
  );
  const [openColumns, setOpenColumns] = useState<string[]>([]);
  const useFormInstance = useForm({
    defaultValues: getNewFilters(),
  });
  const { handleSubmit, reset, getValues } = useFormInstance;
  const filterColumnIds = columns.filter((c) => c.canFilter).map((c) => c.id);
  const appliedFiltersLength = filters.filter((key) =>
    filterColumnIds.includes(key.id),
  ).length;

  const openColumn = (id: string) => setOpenColumns([...openColumns, id]);

  const closeColumn = (id: string) =>
    setOpenColumns(openColumns.filter((c) => c !== id));

  useEffect(() => {
    setOpenColumns(filters.map((f) => f.id));
  }, [filters]);

  useEffect(() => {
    reset({
      ...getValues(),
      ...getNewFilters(),
    });
  }, [getValues, getNewFilters, reset]);

  const onSubmit = (values: FilterValue) => {
    const visibleColumnIds = (columns || [])?.map((c) => c?.id);
    const keysToOmit = keys(values).filter(
      (key) => !visibleColumnIds.includes(key),
    );

    const unProcessedData = omit(values, [
      PROCESS_FILTER_COLUMN,
      ...keysToOmit,
    ]);
    const processedData = Object.assign(
      {},
      ...flatten(
        map(
          values[PROCESS_FILTER_COLUMN],
          (values, key: FilterColumnsToProcess) => {
            const valueKeys = Object.keys(values);
            valueKeys.forEach((valueKey) => {
              if (!openColumns.includes(valueKey)) {
                delete values[valueKey];
              }
            });
            return getProcessedFilterData(values, key);
          },
        ),
      ),
    );
    const allFilters = map(
      { ...unProcessedData, ...processedData },
      (value: FilterValue, id: IdType<D>) => ({
        id,
        value,
      }),
    );
    setAllFilters(allFilters);

    tableInstance.gotoPage(0);
    setOpen(false);
  };

  const handleReset = async () => {
    reset(
      Object.assign(
        {},
        getNewFilters(),
        ...filterColumnIds.map((id) => ({ [id]: undefined })),
      ),
    );
    setOpenColumns([]);
  };

  const clearFormFieldValue = (fieldName: string) => {
    reset({ ...getValues(), [fieldName]: undefined });
  };

  const isGeminiFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.GEMINI_REDESIGN,
  );
  const geminiRedesignFlagEnabled =
    isGeminiFeatureFlagEnabled && isGeminiUIEnabled();

  return (
    <section className='relative' title='table-options'>
      {geminiRedesignFlagEnabled ? (
        <GeminiButton
          leftIcon={faSlidersSimple}
          label={`${
            appliedFiltersLength
              ? `Applied (${appliedFiltersLength})`
              : 'Filters'
          }`}
          variant={
            appliedFiltersLength ? 'secondary-outline' : 'secondary-outline'
          }
          onClick={() => setOpen(!open)}
        />
      ) : (
        <ZenButton
          LeftIconComponent={
            <FilterIcon
              className={cn(
                'mr-1 mb-0.5',
                appliedFiltersLength ? 'text-primary-blue' : 'text-zen-dark-7',
              )}
              role='img'
              aria-label='filter'
            />
          }
          label={`${
            appliedFiltersLength
              ? `Applied (${appliedFiltersLength})`
              : 'Filters'
          }`}
          variant={`${
            appliedFiltersLength
              ? 'secondary-light-outline'
              : 'secondary-gray-text-outline'
          }`}
          onClick={() => setOpen(!open)}
        />
      )}
      {open && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          onReset={handleReset}
          className='absolute right-0 z-10 mt-1 bg-white rounded-lg shadow-lg w-72 ring-2 ring-gray-200'
        >
          <div className='flex flex-row items-center justify-between p-3 font-zen-body'>
            <ZenButton variant='secondary-outline' label='Clear' type='reset' />
            <p className='text-gray-600'>Filters</p>
            <ZenButton variant='primary' label='Apply' type='submit' />
          </div>
          {columns.map((column) => {
            const isColumnApplied = openColumns.includes(column.id);
            return (
              <div key={`${column.id}`} className='font-zen-body'>
                {column.canFilter ? (
                  <>
                    <div className='p-3 border-t border-gray-200'>
                      <label className='flex items-center justify-start space-x-2'>
                        <input
                          key={`${column.id}-${
                            isColumnApplied ? YesNoType.YES : YesNoType.NO
                          }`}
                          type='checkbox'
                          className='w-4 h-4 border-none rounded-full ring-1 ring-gray-200 focus:outline-none focus:ring-0 text-primary-blue'
                          onChange={() => {
                            if (isColumnApplied) {
                              closeColumn(column.id);
                              clearFormFieldValue(column.id);
                            } else {
                              openColumn(column.id);
                            }
                          }}
                          checked={isColumnApplied}
                        />
                        <div className='font-zen-body text-base'>
                          {column.render('Header')}
                        </div>
                      </label>
                    </div>
                    {isColumnApplied && (
                      <div className='p-2 bg-gray-100'>
                        {column.render('Filter', useFormInstance)}
                      </div>
                    )}
                  </>
                ) : (
                  <div className='invisible'>
                    <ControlledTextInputV7
                      name={column.id}
                      shouldUnregister={false}
                      {...useFormInstance}
                      type='hidden'
                    />
                  </div>
                )}
              </div>
            );
          })}
        </form>
      )}
    </section>
  );
};

export default ZenFilterOption;
