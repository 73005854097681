export interface SlimDataRowProps {
  name: string;
  value?: any;
}

const SlimDataRowCapitalized: React.FC<SlimDataRowProps> = ({
  name,
  value,
  children,
}) => {
  return (
    <div className='items-center grid grid-cols-2 gap-x-10'>
      <p className='text-xs uppercase text-gray-700 text-left font-normal font-primary-bold tracking-wider pr-2 whitespace-nowrap'>
        {name}
      </p>
      <p className='text-sm'>
        {value} {children}
      </p>
    </div>
  );
};

export default SlimDataRowCapitalized;
