import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from '@fortawesome/pro-regular-svg-icons';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form-v7';
import RealTitleOperatesStates from '../../assets/img/zen/real-title/real-title-operates-states.png';
import {
  CHECK_THE_MAP_LINK,
  REAL_TITLE_CONTRACT_EMAIL,
} from '../../constants/RealTitleConstants';
import {
  getRealTitleContactInfo,
  RealTitleOperationStatesEnum,
} from '../../utils/RealTitleUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenControlledHTMLSelectInput from '../Zen/Input/ZenControlledHTMLSelectInput';
import ZenIconText from '../Zen/ZenIconText';
import ZenIconTextLink from '../Zen/ZenIconTextLink';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';

interface RealTitleOperatesSectionProps {}

interface FormData {
  state: RealTitleOperationStatesEnum;
}

const RealTitleOperatesSection: React.FC<RealTitleOperatesSectionProps> = () => {
  const { control, watch } = useForm<FormData>({
    defaultValues: { state: RealTitleOperationStatesEnum.Arizona },
  });

  const state = watch('state');

  useEffect(() => {
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.ONE_APP_TITLE_SELECTED_LOCATION_IN_CONTACT_US,
    );
  }, [state]);

  const contactInfo = useMemo(() => {
    return getRealTitleContactInfo(state);
  }, [state]);

  return (
    <div className='grid lg:grid-cols-3 grid-cols-1 mt-8 lg:space-x-8 lg:space-y-0 space-y-8'>
      <div className='col-span-2 border border-zen-dark-4 rounded-2xl p-6'>
        <img src={RealTitleOperatesStates} alt='real title operation states' />
        <button
          onClick={() => {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.ONE_APP_TITLE_CLICKED_CHECK_OUR_MAP,
            );
            window.open(CHECK_THE_MAP_LINK, '_blank');
          }}
          className='flex flex-row items-center mt-4 space-x-2 text-sm text-zen-dark-9 font-semibold'
        >
          <span>Check our map</span>
          <FontAwesomeIcon icon={faExternalLink} />
        </button>
      </div>
      <div className='bg-zen-dark-13 rounded-2xl text-white p-6'>
        <h2 className='font-semibold text-lg'>Contact us</h2>
        <div className='mt-5'>
          <p className='font-medium text-xs text-zen-dark-4 mb-1'>
            Search Location
          </p>
          <ZenControlledHTMLSelectInput<FormData, 'state'>
            name='state'
            control={control}
            shouldUnregister={false}
            options={values(RealTitleOperationStatesEnum)
              .sort()
              .map((state) => ({
                value: state,
                label: capitalizeEnum(state),
              }))}
          />
        </div>
        <div className='mt-8'>
          <span className='text-base text-regent-300'>Real Title</span>
          {!!contactInfo && (
            <div>
              <div className='flex flex-col mt-2 space-y-2'>
                <div>
                  <ZenIconText
                    text={contactInfo.oneLineAddress}
                    variant='medium'
                    textClassName='text-white'
                    icon={
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        className='text-white pb-0.5'
                      />
                    }
                  />
                </div>
                <div>
                  <ZenIconTextLink
                    text={contactInfo.phoneNumber}
                    icon={faPhone}
                    onClick={() => {
                      window.open(`tel:${contactInfo.phoneNumber}`, '_blank');
                    }}
                  />
                </div>
                <div>
                  <ZenIconTextLink
                    text={contactInfo.emailAddress}
                    icon={faEnvelope}
                    onClick={() => {
                      window.open(
                        `mailto:${contactInfo.emailAddress}`,
                        '_blank',
                      );
                    }}
                  />
                </div>
              </div>
              <div className='mt-10'>
                <p className='text-base text-regent-300'>
                  Send the contract to:
                </p>
                <ZenIconTextLink
                  text={REAL_TITLE_CONTRACT_EMAIL}
                  icon={faEnvelope}
                  onClick={() => {
                    window.open(
                      `mailto:${REAL_TITLE_CONTRACT_EMAIL}`,
                      '_blank',
                    );
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RealTitleOperatesSection;
