import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flatten, uniq } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CellProps, Column } from 'react-table';
import {
  faCheckCircle,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import {
  CheckDepositDtoTypeEnum,
  EscrowResponse,
  EscrowResponseEscrowTypeEnum,
  MonetaryAmount,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { fetchAgentsInfo } from '../../../slices/UserIdsSlice';
import { AppDispatch } from '../../../types';
import { displayFormattedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import DateCell from '../../table/Cells/DateCell';
import ZenFixedDataResourceIndexContainer from '../../Zen/Containers/ZenFixedDataResourceIndexContainer';
import ZenButton from '../../Zen/ZenButton';
import { OfficeResponse } from '../../../openapi/yenta';
import { isAllDepositsConfirmed } from '../../../utils/DepositsUtil';
import { isFTNISettlementIdAvailableForTrustAccount } from '../../../utils/TransactionHelper';
import ZenManageDeposits from './ZenManageDeposits';
import ZenTrustDepositInstallmentsCell from './ZenTrustDepositInstallmentsCell';
import ZenTrustDepositInstallmentDetailTable from './ZenTrustDepositInstallmentDetailTable';
import { ZenExternalDepositLink } from './ZenExternalDepositLink';

interface TrustDepositsSectionProps {
  transaction: TransactionResponse;
  office: OfficeResponse | undefined;
}

const ZenTrustDepositsSection: React.FC<TrustDepositsSectionProps> = ({
  transaction,
  office,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const deposits = useMemo(
    () =>
      transaction.escrows?.filter(
        (escrow) =>
          escrow.escrowType === EscrowResponseEscrowTypeEnum.TrustDeposit,
      ) || [],
    [transaction.escrows],
  );

  const columns: Array<Column<EscrowResponse>> = useMemo(
    () => [
      {
        Header: 'Amount',
        accessor: (d) => d['amount']?.amount,
        headerContentClassName: 'md:w-56',
        Cell: ({
          row: { original },
        }: CellProps<EscrowResponse, MonetaryAmount>) => {
          return (
            <div className='py-2 flex space-x-3 items-center text-zen-dark-9'>
              <div>
                {isAllDepositsConfirmed(original) || !original.heldByReal ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size='lg'
                    className='text-green-600'
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    size='lg'
                    className='text-red-600'
                  />
                )}
              </div>
              <p className='text-ellipsis overflow-hidden'>
                {displayFormattedAmountWithCurrency(original.amount)}
              </p>
            </div>
          );
        },
      },
      {
        Header: 'Installments',
        accessor: 'deposits',
        headerContentClassName: 'md:w-[500px]',
        Cell: ({ row, value }) => {
          return (
            row.original.heldByReal && (
              <div className='py-2 text-zen-dark-9'>
                <ZenTrustDepositInstallmentsCell
                  deposits={value!}
                  escrow={row.original}
                  transaction={transaction}
                  row={row}
                />
              </div>
            )
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Required By',
        accessor: 'requiredByDate',
        headerContentClassName: 'md:w-28',
        Cell: ({ value }) => (
          <div className='py-2 text-sm text-zen-dark-9'>
            <DateCell date={value} />
          </div>
        ),
      },
      {
        Header: 'Held By',
        accessor: 'heldByReal',
        disableFilters: true,
        headerContentClassName: 'md:w-28',
        Cell: ({ row }) => (
          <p className='md:w-28 md:whitespace-nowrap py-2 text-zen-dark-9'>
            {row.original.heldByReal ? 'Real Brokerage' : 'Other Brokerage'}
          </p>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Notes',
        accessor: 'note',
        disableFilters: true,
        headerContentClassName: 'md:w-60',
        Cell: ({ value }) => (
          <p className='py-2 font-regular font-zen-body text-zen-dark-9'>
            {value || 'N/A'}
          </p>
        ),
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const agentIds = useMemo(() => {
    const confirmedByIds = uniq(
      flatten(
        (deposits || []).map((desposit) =>
          (desposit?.deposits || []).map((d) => d?.confirmedBy!),
        ),
      ).filter((deposit) => !!deposit),
    );

    const trustDepositsCreatedByIds = uniq(
      (deposits || [])
        ?.map((deposit) => deposit?.createdBy!)
        ?.filter((id) => !!id),
    );

    const installmentCreatedByIds = uniq(
      flatten(
        (deposits || []).map((desposit) =>
          (desposit?.deposits || []).map((d) => d?.createdBy!),
        ),
      ).filter((id) => !!id),
    );

    return uniq([
      ...confirmedByIds,
      ...trustDepositsCreatedByIds,
      ...installmentCreatedByIds,
    ]);
  }, [deposits]);

  useEffect(() => {
    if (agentIds && agentIds.length > 0) {
      dispatch(fetchAgentsInfo(agentIds));
    }
  }, [agentIds, dispatch]);

  return (
    <div className='w-full p-4 relative'>
      <div className='flex flex-col md:flex-row md:justify-between md:items-center space-y-3 md:space-y-0 py-3'>
        <p className='font-semibold text-xl text-zen-dark-9'>Trust Deposits</p>
        <div className='flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:items-center'>
          {isFTNISettlementIdAvailableForTrustAccount(office) && (
            <ZenExternalDepositLink
              transaction={transaction}
              depositType={CheckDepositDtoTypeEnum.Trust}
            />
          )}

          <ZenButton
            label='Manage Trust Deposits'
            onClick={() => setIsOpen(true)}
            LeftIconComponent={
              <FontAwesomeIcon
                icon={regular('gear')}
                className='text-white mt-1 mr-1'
              />
            }
          />
        </div>
      </div>
      <div className='table-align-top'>
        <ZenFixedDataResourceIndexContainer<EscrowResponse>
          columns={columns}
          data={deposits}
          resourceName='Trust Deposit'
          hidePagination
          renderToggleRowComponent={(row) => (
            <ZenTrustDepositInstallmentDetailTable
              row={row}
              transaction={transaction}
            />
          )}
          hideFilters
        />
      </div>
      <ZenManageDeposits
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        depositPayments={deposits}
        transaction={transaction}
      />
    </div>
  );
};

export default ZenTrustDepositsSection;
