import {
  BoardResponseStatusEnum,
  MlsResponseStatusEnum,
} from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import GeminiPill from '../GeminiPill';

interface GeminiMlsOrBoardStatusPillProps {
  status?: BoardResponseStatusEnum | MlsResponseStatusEnum;
  className?: string;
}

const statusToVariant: EnumMap<
  BoardResponseStatusEnum | MlsResponseStatusEnum,
  string
> = {
  ACTIVE: 'bg-green-100 text-green-600',
  INACTIVE: 'bg-grey-200 text-grey-600',
  NOT_MEMBERS: 'bg-red-100 text-red-600',
  PENDING: 'bg-yellow-100 text-yellow-900',
  WILLING_TO_JOIN: 'bg-rezen-light-blue-100 text-rezen-blue-600',
};

const GeminiMlsOrBoardStatusPill: React.FC<GeminiMlsOrBoardStatusPillProps> = ({
  status,
  className,
}) => {
  if (!status) return null;

  return (
    <GeminiPill
      label={capitalizeEnum(status)}
      containerClassNames={cn(statusToVariant[status], className)}
    />
  );
};

export default GeminiMlsOrBoardStatusPill;
