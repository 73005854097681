import { useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';
import ZenEmailResetFormSidebarModal from '../forms/ZenEmailResetFormSidebarModal';

interface ZenEmailResetProps {}

const ZenEmailReset: React.FC<ZenEmailResetProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <ZenResourceLinkCard
        title='Fix Email'
        onClick={() => setIsOpen(true)}
        icon={solid('envelope-open')}
        iconSize='2x'
      />
      <ZenEmailResetFormSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenEmailReset;
