import { faFile, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Popover } from 'react-tiny-popover';
import ZenTemplatePopper from './ZenTemplatePopper';

type ZenMoreOptionsProps = {
  handleTemplateSelect: (text: string) => void;
};

const ZenMoreOptions: React.FC<ZenMoreOptionsProps> = ({
  handleTemplateSelect,
}) => {
  const [isTemplatesOpen, setIsTemplatesOpen] = React.useState(false);

  return (
    <div className='flex flex-col gap-1 font-inter pt-1 pb-1.5'>
      <span className='py-1 pl-5 text-sm font-semibold text-grey-400'>
        More Options
      </span>
      <div className='border-b border-regent-300'>
        <Popover
          isOpen={isTemplatesOpen}
          positions={['right', 'left', 'top', 'bottom']}
          content={() => (
            <ZenTemplatePopper handleTemplateSelect={handleTemplateSelect} />
          )}
          onClickOutside={() => setIsTemplatesOpen(false)}
        >
          <div
            className='flex flex-row items-center justify-between px-6 py-3 cursor-pointer'
            onClick={() => setIsTemplatesOpen(!isTemplatesOpen)}
          >
            <div className='flex gap-2 items-center'>
              <span>
                <FontAwesomeIcon icon={faFile} size='sm' />
              </span>
              <span className='text-base font-light text-primary-dark'>
                Add Templates
              </span>
            </div>
            <span>
              <FontAwesomeIcon icon={faChevronRight} size='sm' />
            </span>
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default ZenMoreOptions;
