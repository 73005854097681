import React from 'react';
import { MoneyValue } from '../../openapi/arrakis';
import { displayAmount } from '../../utils/CurrencyUtils';

interface DebtPendingBalanceProps {
  pendingAmount: MoneyValue;
}

const DebtPendingBalance: React.FC<DebtPendingBalanceProps> = ({
  pendingAmount,
}) => {
  return (
    <div className='flex flex-row items-center space-x-1 px-2 py-1 bg-zen-danger-light rounded w-max'>
      <span className='font-zen-body font-normal text-xs text-zen-danger'>
        Balance:
      </span>
      <span className='font-zen-body font-semibold text-xs text-zen-danger'>
        {displayAmount(pendingAmount, {
          hideCurrency: true,
        })}
      </span>
    </div>
  );
};

export default DebtPendingBalance;
