import { useState } from 'react';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenOnboardingComponent from '../components/Zen/Onboarding/admin/ZenOnboardingComponent';
import ZenPasswordGuard from './ZenPasswordGuard';

export const ONBOARDING_PASSWORD = 'simplygreatservice';

interface ZenOnboardingIndexRouteProps {}

const ZenOnboardingIndexRoute: React.FC<ZenOnboardingIndexRouteProps> = () => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  return (
    <ZenRoute title='Onboarding'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Onboarding', url: '/onboarding' },
        ]}
      >
        {!authenticated ? (
          <ZenPasswordGuard
            password={ONBOARDING_PASSWORD}
            setAuthenticated={setAuthenticated}
          />
        ) : (
          <ZenOnboardingComponent />
        )}
      </ZenPageLayout>
    </ZenRoute>
  );
};

export default ZenOnboardingIndexRoute;
