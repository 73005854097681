import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import {
  MediaAudioBlock,
  VoiceCallBlock,
  VoiceCallBlockRecordingFlowStatusEnum,
  VoiceCallBlockRecordingTypeEnum,
} from '../openapi/yada';
import { AgentResponse, DateRange } from '../openapi/yenta';

type RecordingBlock = VoiceCallBlock | MediaAudioBlock;

export const isTranscriptAvailable = (
  recordingBlock: RecordingBlock,
): boolean => {
  return (
    recordingBlock.recordingFlowStatus ===
    VoiceCallBlockRecordingFlowStatusEnum.TranscriptAvailable
  );
};

export const isTranscriptUnavailable = (
  recordingBlock: RecordingBlock,
): boolean => {
  return (
    recordingBlock.recordingFlowStatus ===
    VoiceCallBlockRecordingFlowStatusEnum.TranscriptUnavailable
  );
};

export const isRecordingInProgress = (
  recordingBlock: RecordingBlock,
): boolean => {
  return (
    recordingBlock.recordingFlowStatus ===
    VoiceCallBlockRecordingFlowStatusEnum.RecordingInProgress
  );
};

export const isRecordingUnavailable = (
  recordingBlock: RecordingBlock,
): boolean => {
  return (
    recordingBlock.recordingFlowStatus ===
    VoiceCallBlockRecordingFlowStatusEnum.RecordingUnavailable
  );
};

export const isCallCancelled = (recordingBlock: RecordingBlock): boolean => {
  return (
    recordingBlock.recordingFlowStatus ===
    VoiceCallBlockRecordingFlowStatusEnum.Cancelled
  );
};

export const isVoiceCall = (
  recordingBlock: VoiceCallBlock | MediaAudioBlock,
): recordingBlock is VoiceCallBlock => {
  return (
    'recordingType' in recordingBlock &&
    recordingBlock.recordingType === VoiceCallBlockRecordingTypeEnum.Call
  );
};

export const isVoiceMail = (
  recordingBlock: RecordingBlock,
): recordingBlock is VoiceCallBlock => {
  return (
    'recordingType' in recordingBlock &&
    recordingBlock.recordingType === VoiceCallBlockRecordingTypeEnum.Voicemail
  );
};

export const isOutOfOffice = (agentDetail?: AgentResponse | null): boolean => {
  const availability = agentDetail?.availability;
  const outOfOffice = availability?.outOfOffice?.[0];
  const timezone = agentDetail?.availability?.timeZone;
  if (availability?.available || isNil(outOfOffice)) {
    return false;
  }
  const startDate = DateTime.fromISO(outOfOffice.startDate).setZone(timezone);
  const endDate = DateTime.fromISO(outOfOffice.endDate).setZone(timezone);
  const today = DateTime.fromISO(DateTime.now().toFormat('yyyy-MM-dd')).setZone(
    timezone,
  );
  return startDate <= today && today <= endDate;
};

export const formatDateRange = (dateRange: DateRange): string => {
  const { startDate, endDate } = dateRange || {};

  if (!startDate || !endDate) {
    return 'N/A';
  }

  return (
    DateTime.fromISO(startDate).toFormat('EEE, dd LLL') +
    ' - ' +
    DateTime.fromISO(endDate).toFormat('EEE, dd LLL')
  );
};

export const canDeleteVoiceRecording = (
  recordingBlock: VoiceCallBlock,
  userDetails?: AgentResponse | null,
): boolean => {
  return recordingBlock.callerId === userDetails?.id;
};
