import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import DocumentToolbarImg from '../../assets/img/document-toolbar.png';
import {
  CANADIAN_AGENT_FAQ_AND_SUPPORT_LINK,
  US_AGENT_LICENSE_TRANSFERS_LINK,
} from '../../constants/SupportConstants';
import useRedirectAwayOnboardingEffect from '../../hooks/useRedirectAwayOnboardingEffect';
import {
  ApplicationControllerApi,
  ApplicationResponseNextStatusEnum,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { saveApplication } from '../../slices/AuthSlice';
import { toggleSupportModal } from '../../slices/SupportSlice';
import { RootState } from '../../types';
import { isCanadianUser } from '../../utils/AgentHelper';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ApplicationLayout from '../ApplicationLayout';
import IconButton from '../IconButton';
import SubmitButton from '../SubmitButton';

const ApplicationTransferLicense: React.FC = () => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const applicationLastIndex = userDetail!.applications!.length - 1;

  useRedirectAwayOnboardingEffect(
    ApplicationResponseNextStatusEnum.TransferLicense,
  );

  const agentCanTransferLicense = userDetail?.applications![
    applicationLastIndex
  ]!.doesBusinessInExtended![0].licenseResponse?.administrativeArea!
    .agentCanTransferLicense;

  const agentLicenseTransferred = async () => {
    setIsSubmitting(true);

    try {
      const api = new ApplicationControllerApi(getYentaConfiguration());
      const { data } = await api.licenseTransferred({
        applicationId: userDetail?.applications![applicationLastIndex].id!,
      });
      dispatch(saveApplication(data));
      history.push('/onboarding/application-join-board');
    } catch (e) {
      ErrorService.notify('Unable to transfer license', e);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (agentCanTransferLicense) {
    return (
      <ApplicationLayout>
        <div className='flex justify-center items-center my-20'>
          <div className='container lg:px-32 px-5'>
            <div className='text-center'>
              <p className='lg:text-4xl text-3xl font-primary-medium'>
                Transfer License
              </p>
            </div>
            <div className='grid lg:grid-cols-3 grid-cols-1 border rounded mt-10'>
              <div className='bg-primary-light col-span-2 flex flex-col justify-center lg:p-16 p-5'>
                <p className='text-xl font-primary-medium'>
                  License Transfer Guide
                </p>
                <p className='text-grey-600 lg:text-lg mt-3'>
                  We have prepared a detailed knowledge base for you on how to
                  transfer the license for each state / province.
                </p>
                <p className='text-primary-dark my-3'>
                  Instructions specific to your State / Province can be found in
                  the below link:
                </p>
                <div>
                  <a
                    rel='noopener noreferrer'
                    target='_blank'
                    href={
                      isCanadianUser(userDetail?.accountCountry!)
                        ? CANADIAN_AGENT_FAQ_AND_SUPPORT_LINK
                        : US_AGENT_LICENSE_TRANSFERS_LINK
                    }
                    className='mt-3 bg-legacy-blue text-primary-light p-2 rounded'
                  >
                    Learn More
                  </a>
                </div>
                <p className='mt-3 text-grey-500'>
                  Have you found the instructions you needed in our knowledge
                  base? If not, please Contact Support.
                </p>
              </div>
              <div className='bg-primary-dark flex flex-col items-center justify-center text-primary-light p-5'>
                <FontAwesomeIcon icon={faInfoCircle} size='3x' />
                <p className='text-lg text-center mt-5'>
                  Click the button below when you have completed the transfer of
                  your license
                </p>
                <SubmitButton
                  label='I have transferred my license'
                  isSubmitting={isSubmitting}
                  onClick={agentLicenseTransferred}
                />
                <div className='mt-5'>
                  <IconButton
                    label='Contact Support'
                    onClick={() => dispatch(toggleSupportModal(true))}
                    variant='outline'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ApplicationLayout>
    );
  }

  return (
    <ApplicationLayout>
      <div className='flex justify-center items-center my-20'>
        <div className='container lg:px-32 px-5'>
          <div className='text-center'>
            <p className='lg:text-4xl text-3xl font-primary-medium'>
              Transfer License
            </p>
          </div>
          <div className='grid lg:grid-cols-3 grid-cols-1 border rounded mt-10 bg-primary-dark'>
            <div className='col-span-2 flex flex-col justify-center lg:p-20 p-5'>
              <p className='text-2xl text-primary-light'>
                STOP HERE, Please follow the below link for your state&apos;s /
                province&apos;s license transfer instructions
              </p>
              <p className='text-grey-200 text-justify mt-3'>
                Please follow the instructions in the below link to begin the
                license transfer process and will notify you once it is
                completed. Note that it may take a few days depending on the
                state / province you&apos;re in. You can check the average
                processing time for your state / province in this{' '}
                <a
                  href={
                    isCanadianUser(userDetail?.accountCountry!)
                      ? CANADIAN_AGENT_FAQ_AND_SUPPORT_LINK
                      : US_AGENT_LICENSE_TRANSFERS_LINK
                  }
                  className='text-legacy-blue'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  knowledge base article.
                </a>{' '}
                If you have not found the answer in the knowledge base, please
                feel free to contact our onboarding team by email at
                <a
                  href='mailto:onboarding@therealbrokerage.com'
                  className='text-legacy-blue mx-1 text-sm'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  onboarding@therealbrokerage.com
                </a>
                .
              </p>
              <div className='mt-10'>
                <IconButton
                  label='Visit the help center'
                  buttonType='button'
                  size='lg'
                  onClick={() =>
                    window.open(
                      isCanadianUser(userDetail?.accountCountry!)
                        ? CANADIAN_AGENT_FAQ_AND_SUPPORT_LINK
                        : US_AGENT_LICENSE_TRANSFERS_LINK,
                      '_blank',
                    )
                  }
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      className='mb-0.5'
                    />
                  }
                />
              </div>
            </div>
            <div className='bg-primary-dark flex flex-col items-center justify-center text-primary-blue p-5'>
              <img src={DocumentToolbarImg} alt='document-toolbar' />
            </div>
          </div>
        </div>
      </div>
    </ApplicationLayout>
  );
};
export default ApplicationTransferLicense;
