import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import LockImg from '../../../assets/img/lock.png';
import { IdentitySummaryResponseMfaTypeEnum } from '../../../openapi/keymaker';
import { RootState } from '../../../types';
import { formatPhoneNumber } from '../../../utils/StringUtils';
import GeminiFeatureFlagButton from '../../GeminiFeatureFlagButton';
import ZenCard from '../../Zen/ZenCard';
import ZenCardWithItems from '../../Zen/ZenCardWithItems';
import AgentDeleteTwoFactorAuthenticationModal from './AgentDeleteTwoFactorAuthenticationModal';
import ZenAgentAuthenticatorAppAuthenticationMethodSideBarModalForm from './ZenAgentAuthenticatorAppAuthenticationMethodSideBarModalForm';
import ZenAgentSelectTwoFactorAuthenticationMethodSideBarModal from './ZenAgentSelectTwoFactorAuthenticationMethodSideBarModal';
import ZenAgentTextMessageAuthenticationCodeSideBarModalForm from './ZenAgentTextMessageAuthenticationCodeSideBarModalForm';
import ZenAgentTextMessageAuthenticationMethodSideBarModalForm from './ZenAgentTextMessageAuthenticationMethodSideBarModalForm';

interface AgentTwoFactorAuthenticationProps {
  isForceMfa?: boolean;
}

const AgentTwoFactorAuthentication: React.FC<AgentTwoFactorAuthenticationProps> = ({
  isForceMfa = false,
}) => {
  const { keymakerCurrentUser } = useSelector((state: RootState) => state.auth);
  const [
    enableTwoFactorAuthentication,
    setEnableTwoFactorAuthentication,
  ] = useState<boolean>(false);
  const [
    authenticationType,
    setAuthenticationType,
  ] = useState<IdentitySummaryResponseMfaTypeEnum>();
  const [
    isDeleteTwoFactorAuthentication,
    setIsDeleteTwoFactorAuthentication,
  ] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const isSmsTypeMFA =
    keymakerCurrentUser?.mfaType === IdentitySummaryResponseMfaTypeEnum.Sms;
  const isAuthenticatorTypeMFA =
    authenticationType === IdentitySummaryResponseMfaTypeEnum.Authenticator;

  return (
    <div>
      {!!keymakerCurrentUser?.mfaType ? (
        <ZenCardWithItems
          title='Two-Factor Authentication'
          RightComponent={
            <button
              aria-label='delete-mfa'
              className='hover:bg-zen-danger hover:bg-opacity-10 cursor-pointer flex h-8 w-8 items-center justify-center rounded-full mx-3'
              onClick={() => setIsDeleteTwoFactorAuthentication(true)}
            >
              <FontAwesomeIcon icon={faTrashCan} className='text-zen-danger' />
            </button>
          }
          items={[
            {
              name: 'Status',
              rightActionComponent: (
                <div className='font-primary-medium text-success flex items-center'>
                  <FontAwesomeIcon icon={faShieldCheck} className='mr-1' />
                  <span className='mx-3'>Enabled</span>
                </div>
              ),
            },
            {
              name: 'Method',
              value: isSmsTypeMFA ? 'Text Messages (SMS)' : 'Authenticator App',
            },
            ...(isSmsTypeMFA
              ? [
                  {
                    name: 'Phone',
                    value: formatPhoneNumber(keymakerCurrentUser.phoneNumber),
                  },
                ]
              : []),
          ]}
        />
      ) : (
        <div>
          <ZenCard title='Two-Factor Authentication'>
            <div className='p-4 relative'>
              <p className='sticky font-primary-regular text-dark pt-3 text-lg z-10'>
                Add a second step verification to protect your account
              </p>
              <p className='font-primary-regular text-zen-dark-8 pt-3 text-sm'>
                Two-factor authentication (2FA) provides an additional layer of
                security beyond passwords and is strongly recommended. Once
                enabled, your account is protected by requiring both your
                password and an authentication code from an authenticator app or
                your mobile device in order to log in to Rezen.
              </p>
              <img
                src={LockImg}
                alt='blocked'
                className='absolute h-28 right-0 top-0 z-0'
              />
              <p className='py-4'>
                <GeminiFeatureFlagButton
                  label='Enable Two-Factor Authentication'
                  onClick={() => setEnableTwoFactorAuthentication(true)}
                />
              </p>
            </div>
          </ZenCard>
        </div>
      )}
      <ZenAgentSelectTwoFactorAuthenticationMethodSideBarModal
        isOpen={enableTwoFactorAuthentication}
        onClose={() => setEnableTwoFactorAuthentication(false)}
        setAuthenticationType={setAuthenticationType}
      />
      <ZenAgentTextMessageAuthenticationMethodSideBarModalForm
        isOpen={authenticationType === IdentitySummaryResponseMfaTypeEnum.Sms}
        onClose={() => setAuthenticationType(undefined)}
        setPhoneNumber={setPhoneNumber}
      />
      <ZenAgentTextMessageAuthenticationCodeSideBarModalForm
        isOpen={!!phoneNumber}
        onClose={() => setPhoneNumber(undefined)}
        phoneNumber={phoneNumber!}
        setEnableTwoFactorAuthentication={setEnableTwoFactorAuthentication}
        isForceMfa={isForceMfa}
      />
      {isAuthenticatorTypeMFA && (
        <ZenAgentAuthenticatorAppAuthenticationMethodSideBarModalForm
          isOpen
          onClose={() => setAuthenticationType(undefined)}
          setEnableTwoFactorAuthentication={setEnableTwoFactorAuthentication}
          isForceMfa={isForceMfa}
        />
      )}
      {isDeleteTwoFactorAuthentication && (
        <AgentDeleteTwoFactorAuthenticationModal
          isOpen
          onClose={() => setIsDeleteTwoFactorAuthentication(false)}
          keymakerCurrentUser={keymakerCurrentUser!}
        />
      )}
    </div>
  );
};
export default AgentTwoFactorAuthentication;
