import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { times } from 'lodash';
import React from 'react';
import { capitalizeEnum } from '../../../utils/StringUtils';

interface SimpleStepProgressProps {
  icon: IconProp;
  title: string;
  subtitle: string;
  totalSteps: number;
  currentStep: number;
}

const SimpleStepProgress: React.FC<SimpleStepProgressProps> = ({
  icon,
  currentStep,
  subtitle,
  title,
  totalSteps,
}) => {
  return (
    <div className='m-4'>
      <div className='flex flex-row items-center space-x-2'>
        <FontAwesomeIcon
          icon={icon}
          size='lg'
          className='text-rezen-blue-600 py-2.5 px-2 bg-gray-100 rounded-full'
        />
        <div>
          <p className='text-primary-navy'>{capitalizeEnum(title)}</p>
          <p className='text-gray-400'>{subtitle}</p>
        </div>
      </div>

      <p className='flex justify-end items-end'>
        {currentStep + 1}/{totalSteps} Steps
      </p>
      <div className='flex flex-row justify-center items-center space-x-2'>
        {times(totalSteps, (index) => {
          if (index < currentStep + 1) {
            return (
              <div className='h-2 w-full bg-rezen-blue-600 rounded-full' />
            );
          }
          return <div className='h-2 w-full bg-gray-300 rounded-full' />;
        })}
      </div>
    </div>
  );
};

export default SimpleStepProgress;
