import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import {
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
  AgentControllerApi,
  AgentUpdateRequest,
  UserControllerApi,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { fetchAuthUserDetail } from '../../../slices/AuthSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  AppDispatch,
  ISelectOption,
  RootState,
  YesNoType,
} from '../../../types';
import { getUpdateAgentDetailObject } from '../../../utils/ApiObjectUtils';
import Logger from '../../../utils/Logger';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import StepByStepContainer, {
  StepConfig,
} from '../../StepByStep/StepByStepContainer';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { parseUrl } from '../../../utils/UrlUtils';
import { updateAgentDetail } from '../../../slices/AgentSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import AddressInfo from './AddressInfo';
import InitialStep from './InitialStep';
import ProfessionalInfo from './ProfessionalInfo';
import WelcomeOnboardingProfilePic from './ProfileInfo';
import ReviewStep from './Review';
import SocialMediaLinksInfo from './SocialMedia';
import WelcomeOnboardingLayout from './WelcomeOnboardingLayout';
import LoftySubscription from './LoftySubscription';

interface WelcomeOnboardingFormProps {}

export enum WelcomeOnboardingFormStepName {
  INITIAL_STEP = 'INITIAL_STEP',
  PROFILE_PIC_INFO_STEP = 'PROFILE_PIC_INFO_STEP',
  ADDRESS_INFO_STEP = 'ADDRESS_INFO_STEP',
  PROFESSIONAL_INFO_STEP = 'PROFESSIONAL_INFO_STEP',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  LOFTY_SUBSCRIPTION = 'LOFTY_SUBSCRIPTION',
  REVIEW_INFO_STEP = 'REVIEW_INFO_STEP',
}

interface IAgentUpdateRequest
  extends Omit<AgentUpdateRequest, 'addressRequest' | 'languages'> {
  languages?: ISelectOption[];
  addressRequest: {
    type: string;
    streetAddress1: string;
    streetAddress2?: string;
    city?: string;
    stateOrProvince?: ISelectOption<AddressRequestStateOrProvinceEnum>;
    country: ISelectOption<AddressRequestCountryEnum>;
    zipOrPostalCode: string;
  };
}

export interface WelcomeOnboardingFormState {
  avatar: any;
  agentUpdateRequest: IAgentUpdateRequest;
  loftySubscription: YesNoType | undefined;
}

type StepType = StepConfig<
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName
>;

export const steps: StepType[] = [
  {
    name: WelcomeOnboardingFormStepName.INITIAL_STEP,
    Component: InitialStep,
    hidePagination: true,
  },
  {
    name: WelcomeOnboardingFormStepName.PROFILE_PIC_INFO_STEP,
    Component: WelcomeOnboardingProfilePic,
    hidePagination: true,
  },
  {
    name: WelcomeOnboardingFormStepName.ADDRESS_INFO_STEP,
    Component: AddressInfo,
    hidePagination: true,
  },
  {
    name: WelcomeOnboardingFormStepName.PROFESSIONAL_INFO_STEP,
    Component: ProfessionalInfo,
    hidePagination: true,
  },
  {
    name: WelcomeOnboardingFormStepName.SOCIAL_MEDIA,
    Component: SocialMediaLinksInfo,
    hidePagination: true,
  },
  {
    name: WelcomeOnboardingFormStepName.LOFTY_SUBSCRIPTION,
    Component: LoftySubscription,
    hidePagination: true,
  },
  {
    name: WelcomeOnboardingFormStepName.REVIEW_INFO_STEP,
    Component: ReviewStep,
    hidePagination: true,
  },
];

const ZenWelcomeOnboardingForm: React.FC<WelcomeOnboardingFormProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const [currentStep, setCurrentStep] = useState<StepType>(steps[0]);

  const stepToHeadingMap: Record<WelcomeOnboardingFormStepName, string> = {
    INITIAL_STEP: 'Real Agent Profile',
    PROFILE_PIC_INFO_STEP: 'Real Agent Profile - Picture and Bio',
    ADDRESS_INFO_STEP: 'Real Agent Profile - Address',
    PROFESSIONAL_INFO_STEP:
      'Real Agent Profile - Professional/Bussiness Information',
    SOCIAL_MEDIA: 'Real Agent Profile - Social Media',
    LOFTY_SUBSCRIPTION: 'Real Agent Profile - Lofty subscription',
    REVIEW_INFO_STEP: 'Real Agent Profile - Review & Submit',
  };

  const defaultCountry = (userDetail?.accountCountry as unknown) as AddressRequestCountryEnum;

  const defaultValues: WelcomeOnboardingFormState = {
    avatar: undefined,
    loftySubscription: undefined,
    agentUpdateRequest: {
      addressRequest: {
        country: {
          label: capitalizeEnum(defaultCountry),
          value: defaultCountry,
        },
        stateOrProvince: undefined,
        streetAddress1: '',
        type: '',
        zipOrPostalCode: '',
        city: '',
        streetAddress2: '',
      },
      about: '',
      title: '',
      personalPhoneNumber: '',
      phoneNumber: '',
      languages: [],
      personalWebsiteURL: '',
      facebookURL: '',
      twitterURL: '',
      instagramURL: '',
      youtubeURL: '',
      zillowURL: '',
      competencies: [],
    },
  };

  const onSubmit = async (formData: WelcomeOnboardingFormState) => {
    const { agentUpdateRequest, avatar } = formData;
    const languages = agentUpdateRequest.languages?.map((lang) => lang.value);
    const loftyOptIn = formData.loftySubscription === YesNoType.YES;

    if (typeof avatar === 'object') {
      try {
        await new UserControllerApi(getYentaConfiguration()).uploadAvatar(
          userDetail?.id!,
          avatar,
        );
      } catch (e) {
        dispatch(showApiErrorModal(e));
        dispatch(showErrorToast('Unable to upload profile picture'));
        ErrorService.notify('Unable to upload profile picture', e, {
          userDetail: { id: userDetail?.id },
        });
      }
    }

    if (loftyOptIn) {
      try {
        await new AgentControllerApi(
          getYentaConfiguration(),
        ).requestChimeSubscribe(userDetail?.id!);
      } catch (e) {
        Logger.error('Unable to opt in to lofty', e);
        dispatch(
          showErrorToast('We were unable to update your lofty subscription'),
        );
        ErrorService.notify('unable to request lofty subscription', e, {
          agent: { id: userDetail!.id },
        });
      }
    }

    const updateAuthUserDetailObject = getUpdateAgentDetailObject(userDetail!, {
      streetAddress1: agentUpdateRequest.addressRequest?.streetAddress1!,
      streetAddress2: agentUpdateRequest.addressRequest?.streetAddress2!,
      type: agentUpdateRequest.addressRequest?.type!,
      city: agentUpdateRequest.addressRequest?.city!,
      zipOrPostalCode: agentUpdateRequest.addressRequest?.zipOrPostalCode!,
      country: agentUpdateRequest.addressRequest?.country.value,
      stateOrProvince:
        agentUpdateRequest.addressRequest?.stateOrProvince?.value,
      about: agentUpdateRequest.about,
      phoneNumber: agentUpdateRequest.phoneNumber,
      competencies: agentUpdateRequest.competencies,
      languages: languages,
      personalPhoneNumber: agentUpdateRequest.personalPhoneNumber,
      title: agentUpdateRequest.title,
      personalWebsiteURL: parseUrl(agentUpdateRequest.personalWebsiteURL!),
      facebookURL: parseUrl(agentUpdateRequest.facebookURL!),
      instagramURL: parseUrl(agentUpdateRequest.instagramURL!),
      twitterURL: parseUrl(agentUpdateRequest.twitterURL!),
      youtubeURL: parseUrl(agentUpdateRequest.youtubeURL!),
      zillowURL: parseUrl(agentUpdateRequest.zillowURL!),
    });

    try {
      const success = await dispatch(
        updateAgentDetail(userDetail?.id!, updateAuthUserDetailObject),
      );
      if (success) {
        await new AgentControllerApi(
          getYentaConfiguration(),
        ).updateNeedOnBoarding(userDetail?.id!, {
          needOnBoarding: false,
        });
        await dispatch(fetchAuthUserDetail(false));
        history.push('/');
      }
    } catch (e) {
      Logger.error('Unable to updated user detail', e);
      dispatch(showErrorToast('Unable to updated user detail'));
      ErrorService.notify('unable to update user details on onboarding', e, {
        agent: { id: userDetail!.id },
        updateAuthUserDetailObject,
      });
    }
  };

  return (
    <WelcomeOnboardingLayout
      title={stepToHeadingMap[currentStep.name]}
      variant='cancel'
      onClose={() => history.push('/')}
      showStickyBottomOptions
    >
      <StepByStepContainer<
        WelcomeOnboardingFormState,
        WelcomeOnboardingFormStepName
      >
        steps={steps}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        reValidateMode='onChange'
        mode='onChange'
        onChangeStep={setCurrentStep}
      />
    </WelcomeOnboardingLayout>
  );
};

export default ZenWelcomeOnboardingForm;
