import { faArrowRight, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFieldArray } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { DefinedConditionPairEventTypeEnum } from '../../../openapi/sherlock';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import { RootState } from '../../../types';
import CreateEditSubscriptionConditions from './CreateEditSubscriptionConditions';
import {
  CreateEditTriggerFormData,
  CreateEditTriggerStepName,
} from './CreateEditTriggerModal';
import CreateEditTriggerModalFooter from './CreateEditTriggerModalFooter';
import CreateEditTriggerModalHeader from './CreateEditTriggerModalHeader';

const SubscriptionConditionsStep: StepByStepComponent<
  CreateEditTriggerFormData,
  CreateEditTriggerStepName
> = ({ form: { control, trigger, watch, setValue }, onNext, progress }) => {
  const {
    fields: subscriptionConditions,
    append: appendCondition,
    remove: removeCondition,
    update: updateConditions,
  } = useFieldArray({
    control,
    name: 'subscriptionConditions',
  });

  const { showEditTriggerModal } = useSelector(
    (state: RootState) => state.checkListDefinition,
  );

  const handleSubscriptionConditionStep = async () => {
    const isValid = await trigger();
    if (isValid) onNext();
  };

  return (
    <div className='max-w-[600px] '>
      <CreateEditTriggerModalHeader
        currentStep={progress?.currentIndex}
        isEditMode={showEditTriggerModal}
      />

      <div className='px-3 md:px-6 py-6 max-h-[65vh] scrollbar overflow-y-auto bg-primary-light'>
        <div
          className='flex items-start'
          aria-label='subscription-condition-text'
        >
          <div className='mt-[2px] mr-3 w-6 h-6 shrink-0 flex justify-center items-center rounded-full bg-primary-blue overflow-hidden'>
            <span className='text-base text-primary-light'>1</span>
          </div>

          <div className='text-sm font-zen-body font-normal text-grey-600'>
            Add one or many Subscription-Condition pairs. These pairs evaluate
            as an OR function. Ex. Subscription-Condition 1 <strong>OR</strong>{' '}
            Subscription-Condition
          </div>
        </div>

        {subscriptionConditions.map((field, index) => (
          <CreateEditSubscriptionConditions
            key={field.id}
            control={control}
            watch={watch}
            setValue={setValue}
            field={field}
            index={index}
            removeCondition={removeCondition}
            subscriptionConditions={subscriptionConditions}
            updateConditions={updateConditions}
          />
        ))}
      </div>

      <CreateEditTriggerModalFooter
        previousLabelText='Add Subscription-Condition'
        nextLabelText='Add Action'
        previousButtonLeftIcon={
          <FontAwesomeIcon icon={faPlus} className='mx-1' size='lg' />
        }
        nextButtonRightIcon={
          <FontAwesomeIcon icon={faArrowRight} size='lg' className='ml-0.5' />
        }
        onPrevious={() => {
          appendCondition({
            eventType: DefinedConditionPairEventTypeEnum.System,
            event: undefined,
            checklistDefinition: undefined,
            itemDefinition: undefined,
            condition: undefined,
          });
        }}
        onNext={handleSubscriptionConditionStep}
      />
    </div>
  );
};

export default SubscriptionConditionsStep;
