import React, {
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
  MouseEvent,
} from 'react';
import { useEventListener } from 'usehooks-ts';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ZenMoreTab } from '../../Zen/Tab/ZenMoreTab';
import { ZenMoreDropdown } from '../../Zen/Tab/ZenMoreDropdown';
import { useDetermineTabOrder } from '../../../hooks/useDetermineTabOrder';
import { cn } from '../../../utils/classUtils';
import GeminiPillTabEmptyState from './GeminiPillTabEmptyState';

type Size = 'sm' | 'lg';

export interface GeminiPillTab {
  name: string;
  subtitle?: string | number;
  headerComponent?: React.ReactElement;
  badgeComponent?: React.ReactElement;
  TabComponent: React.FC | React.ReactNode;
}

export interface GeminiPillTabsProps {
  tabs: GeminiPillTab[];
  selected?: string;
  RightComponent?: React.FC | React.ReactNode;
  equalWidths?: boolean;
  onChange(name: string): void;
  sortTabs?: boolean;
  size: Size;
  noBorder?: boolean;
  isTabComponentEmpty?: boolean;
  emptyStateMessage?: string;
  emptyStateIcon?: React.ReactElement;
  emptyStateButtonText?: string;
  emptyStateButtonLeftIcon?: IconProp;
  emptyStateButtonOnClick?:
    | ((e: MouseEvent<HTMLElement>) => void)
    | ((e: MouseEvent<HTMLButtonElement>) => void)
    | (() => void);
}

const GeminiPillTabs: React.FC<GeminiPillTabsProps> = ({
  tabs,
  selected = tabs[0]?.name,
  RightComponent,
  equalWidths = false,
  onChange,
  sortTabs = false,
  size,
  isTabComponentEmpty = false,
  emptyStateMessage,
  emptyStateIcon,
  emptyStateButtonText,
  emptyStateButtonLeftIcon,
  emptyStateButtonOnClick,
}) => {
  const tabSelected = tabs.find((tab) => tab.name === selected);

  const containerRef = useRef<HTMLDivElement>(null);
  const moreRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef(tabs.map(() => React.createRef<HTMLDivElement>()));
  const [showDropdown, setShowDropdown] = useState(false);

  const activeTabIndex = tabs.findIndex((tab) => tab.name === selected);

  const sortedTabs = useMemo(
    () =>
      !sortTabs ? tabs : tabs.sort((a, b) => a.name.localeCompare(b.name)),
    [sortTabs, tabs],
  );

  const { showMoreTab, tabsWithVisibility } = useDetermineTabOrder(
    sortedTabs,
    containerRef,
    moreRef,
    tabRefs.current,
    activeTabIndex,
  );

  useEffect(() => {
    const scrollWidth = containerRef.current?.scrollWidth ?? 0;
    const offsetWidth = containerRef.current?.offsetWidth ?? 0;
    if (scrollWidth > offsetWidth && equalWidths) {
      console.warn(
        'Do not ignore. Tabs overflowing container. Consider setting equalWidths to false.',
      );
    }
  }, [equalWidths, tabsWithVisibility]);

  useEventListener('click', (e) => {
    if (
      showDropdown &&
      moreRef.current &&
      !moreRef.current.contains(e.target as Node)
    ) {
      setShowDropdown(false);
    }
  });

  const dropdownLabels = tabsWithVisibility
    .filter(({ isVisible }) => !isVisible)
    .map(({ name }) => name);

  const changeTab = (name: string) => {
    onChange(name);
    setShowDropdown(false);
  };

  return (
    <div className='flex flex-col w-full h-full'>
      <div className='flex flex-row w-full items-center'>
        <div
          ref={containerRef}
          className='flex flex-row w-full'
          data-testid='gemini-pill-tabs'
        >
          {tabsWithVisibility.map(
            ({ name, subtitle, headerComponent, badgeComponent }, index) => {
              const activeTab = name === selected;
              return (
                <div
                  className={cn('flex flex-row items-center -mr-3', {})}
                  key={name}
                  ref={tabRefs.current[index]}
                >
                  <div className={cn('flex flex-col')}>
                    <button
                      className={cn(
                        'focus:outline-none whitespace-pre',
                        'font-inter',
                        'pt-3 pb-2 ',
                        activeTab ? 'text-primary-light' : 'text-grey-600',
                        size === 'sm' && 'text-sm px-3',
                        size === 'lg' && 'text-base min-w-[118px] px-4',
                      )}
                      onClick={(evt) => {
                        evt.preventDefault();
                        changeTab(name);
                      }}
                    >
                      {headerComponent ?? (
                        <p
                          className={cn(
                            'flex flex-col rounded-full py-2.5 px-6 items-center',
                            activeTab
                              ? 'bg-avatar-blue-400 text-primary-light font-inter font-medium'
                              : 'bg-grey-200 text-grey-600 font-inter font-normal',
                            subtitle
                              ? 'items-start'
                              : 'items-center justify-center min-w-max',
                          )}
                        >
                          <span>{name}</span>
                          {subtitle && (
                            <span className='font-inter text-sm'>
                              {subtitle}
                            </span>
                          )}
                        </p>
                      )}
                    </button>
                  </div>
                  {badgeComponent && badgeComponent}
                </div>
              );
            },
          )}
          <ZenMoreTab
            show={showMoreTab && !equalWidths}
            ref={moreRef}
            onClick={() => setShowDropdown(!showDropdown)}
            smallButton
          />
        </div>
        <div>{RightComponent}</div>
      </div>

      <ZenMoreDropdown
        labels={dropdownLabels}
        show={showDropdown}
        position='absolute'
        size='sm'
        containerRef={containerRef}
        moreRef={moreRef}
        onClick={changeTab}
      />
      {!!tabSelected && (
        <Fragment key={tabSelected.name}>
          <div>
            {isTabComponentEmpty ? (
              <GeminiPillTabEmptyState
                message={emptyStateMessage!}
                buttonLeftIcon={emptyStateButtonLeftIcon}
                buttonText={emptyStateButtonText}
                icon={emptyStateIcon!}
                onButtonClick={emptyStateButtonOnClick}
              />
            ) : (
              tabSelected.TabComponent
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default GeminiPillTabs;
