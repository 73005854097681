import { DateTime } from 'luxon';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { CalendarResponse } from '../../../openapi/yenta';
import {
  fetchCalendars,
  syncAllCalendars,
  syncCalendar,
} from '../../../slices/CalendarSlice';
import { RootState } from '../../../types';
import DefaultLoader from '../../DefaultLoader';
import ZenFixedResourceIndexContainer from '../Containers/ZenFixedResourceIndexContainer';
import ZenButton from '../ZenButton';
import ZenCreateCalendarModal from './ZenCreateCalendarModal';
import ZenEditCalendarSidebarModal from './ZenEditCalendarSidebarModal';

export const columns: Array<Column<CalendarResponse>> = [
  {
    Header: 'Calendar Name',
    accessor: 'name',
    Cell: ({ value, row: { original } }) => (
      <Link
        to={`/my-events?${qs.stringify({
          calendarIds: [original.id],
        })}`}
      >
        {value}
      </Link>
    ),
    disableSortBy: true,
  },
  {
    Header: 'Calendar Link',
    accessor: 'url',
    Cell: ({ value, row: { original } }) => (
      <Link
        to={`/my-events?${qs.stringify({
          calendarIds: [original.id],
        })}`}
      >
        <div className='w-72 break-all'>{value}</div>
      </Link>
    ),
    disableSortBy: true,
  },
  {
    Header: 'Last Sync',
    accessor: 'syncedAt',
    Cell: ({ value, row: { original } }) => (
      <Link
        to={`/my-events?${qs.stringify({
          calendarIds: [original.id],
        })}`}
      >
        {value ? DateTime.fromMillis(value).toRelative() : 'N/A'}
      </Link>
    ),
    disableSortBy: true,
  },
];

interface ZenCalendarsTableProps {}

const ZenCalendarsTable: React.FC<ZenCalendarsTableProps> = () => {
  const dispatch = useDispatch();
  const { calendars } = useSelector((state: RootState) => state.calendar);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isCreateCalendar, setIsCreateCalendar] = useState<boolean>(false);
  const [isEditCalendar, setIsEditCalendar] = useState<CalendarResponse>();
  const [isSyncingCalendarId, setIsSyncingCalendarId] = useState<string>();
  const [isSyncingAllCalendars, setIsSyncingAllCalendars] = useState<boolean>(
    false,
  );

  const fetchData = useCallback(async () => {
    setIsFetching(true);
    await dispatch(fetchCalendars());
    setIsFetching(false);
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSyncCalendar = useCallback(
    async (calendarId: string) => {
      setIsSyncingCalendarId(calendarId);
      await dispatch(syncCalendar(calendarId));
      setIsSyncingCalendarId(undefined);
    },
    [dispatch],
  );

  const handleSyncAllCalendars = async () => {
    setIsSyncingAllCalendars(true);
    await dispatch(syncAllCalendars());
    setIsSyncingAllCalendars(false);
  };

  const columnsWithActions: Array<Column<CalendarResponse>> = useMemo(
    () => [
      ...columns,
      {
        Header: 'Actions',
        accessor: 'calendarId',
        id: 'actions',
        Cell: ({ row: { original } }) => {
          const isSyncing = isSyncingCalendarId === original.id;

          return (
            <div className='flex space-x-3'>
              <ZenButton
                label='Sync'
                variant='primary'
                isSubmitting={isSyncing}
                isDisabled={isSyncing}
                onClick={() => handleSyncCalendar(original.id!)}
                isFullWidth
              />
              <ZenButton
                label='Edit'
                variant='secondary-outline'
                onClick={() => setIsEditCalendar(original)}
                isFullWidth
              />
            </div>
          );
        },
        disableSortBy: true,
      },
    ],
    [handleSyncCalendar, isSyncingCalendarId],
  );

  if (isFetching) {
    return <DefaultLoader />;
  }

  return (
    <div className='my-4 px-4'>
      <ZenFixedResourceIndexContainer<CalendarResponse>
        key={calendars.length}
        header='Calendars'
        columns={columnsWithActions}
        data={calendars}
        RightComponent={
          <div className='flex items-center space-x-2'>
            <ZenButton
              label='Sync All Calendars'
              variant='primary-outline'
              isSubmitting={isSyncingAllCalendars}
              isDisabled={isSyncingAllCalendars}
              onClick={handleSyncAllCalendars}
            />
            <ZenButton
              LeftIconComponent={<FontAwesomeIcon icon={faPlus} size='lg' />}
              label='Create Calendar'
              variant='primary'
              onClick={() => setIsCreateCalendar(true)}
            />
          </div>
        }
        resourceName='Calendar'
        hidePageSize
        hidePagination
        hideFilters
      />
      <ZenCreateCalendarModal
        isOpen={isCreateCalendar}
        onClose={() => setIsCreateCalendar(false)}
      />
      {isEditCalendar && (
        <ZenEditCalendarSidebarModal
          isOpen={!!isEditCalendar}
          onClose={() => setIsEditCalendar(undefined)}
          calendar={isEditCalendar}
        />
      )}
    </div>
  );
};

export default ZenCalendarsTable;
