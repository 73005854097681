import { omit } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AgentOnboardingLayout from '../components/AgentOnboardingLayout';
import AgentWebsiteOnboardingListing from '../components/agentWebsiteOnboarding/AgentWebsiteOnboardingListing';
import AgentWebsiteOnboardingReviewAndPublish from '../components/agentWebsiteOnboarding/AgentWebsiteOnboardingReviewAndPublish';
import AgentWebsiteOnboardingSiteInfo from '../components/agentWebsiteOnboarding/AgentWebsiteOnboardingSiteInfo';
import AgentWebsiteOnboardingTestimonial from '../components/agentWebsiteOnboarding/AgentWebsiteOnboardingTestimonial';
import AgentWebsiteOnboardingTheme from '../components/agentWebsiteOnboarding/AgentWebsiteOnboardingTheme';
import AgentWebsiteOnboardingWelcome from '../components/agentWebsiteOnboarding/AgentWebsiteOnboardingWelcome';
import { GooglePlaceLocationType } from '../components/ControlledGoogleAutocompleteSearchInputV7';
import DefaultLoader from '../components/DefaultLoader';
import StepByStepContainer, {
  StepConfig,
} from '../components/StepByStep/StepByStepContainer';
import useGooglePlaceId from '../hooks/useGooglePlaceId';
import {
  AgentControllerApi,
  AgentWebsiteControllerApi,
  AgentWebsiteResponse,
  CreateOrUpdateAgentWebsiteRequest,
  UserControllerApi,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import {
  fetchAgentDetail,
  fetchAgentWebsiteDetails,
} from '../slices/AgentSlice';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { AppDispatch, RootState } from '../types';
import {
  CLUBHOUSE_URL,
  FACEBOOK_URL,
  formUrlFromPath,
  getAgentWebsiteDefaultFormValues,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  TWITTER_URL,
  YELP_URL,
  YOUTUBE_URL,
  ZILLOW_URL,
} from '../utils/AgentWebsiteUtils';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import AmplitudeService, { AmplitudeEvent } from '../services/AmplitudeService';
import Logger from '../utils/Logger';
import ZenRoute from '../components/Zen/ZenRoute';
import { fetchAuthUserDetail } from '../slices/AuthSlice';
import { fetchJourney } from '../slices/CheckListSlice';
import { ItemResponseStatusEnum } from '../openapi/sherlock';

export enum AgentWebsiteOnboardingStepName {
  WELCOME = 'WELCOME',
  THEME = 'THEME',
  SITE_INFO = 'SITE_INFO',
  TESTIMONIALS = 'TESTIMONIALS',
  LISTINGS = 'LISTINGS',
  REVIEW = 'REVIEW',
}

export interface AgentWebsiteOnboardingFormData
  extends CreateOrUpdateAgentWebsiteRequest {
  photo?: File | string;
  location?: GooglePlaceLocationType | null;
  priceRange?: { min: number; max: number };
  name?: string;
}

type Match = {
  id: string;
};

export const steps: StepConfig<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
>[] = [
  {
    name: AgentWebsiteOnboardingStepName.WELCOME,
    Component: AgentWebsiteOnboardingWelcome,
    hidePagination: true,
  },
  {
    name: AgentWebsiteOnboardingStepName.THEME,
    Component: AgentWebsiteOnboardingTheme,
  },
  {
    name: AgentWebsiteOnboardingStepName.SITE_INFO,
    Component: AgentWebsiteOnboardingSiteInfo,
  },
  {
    name: AgentWebsiteOnboardingStepName.TESTIMONIALS,
    Component: AgentWebsiteOnboardingTestimonial,
  },
  {
    name: AgentWebsiteOnboardingStepName.LISTINGS,
    Component: AgentWebsiteOnboardingListing,
  },
  {
    name: AgentWebsiteOnboardingStepName.REVIEW,
    Component: AgentWebsiteOnboardingReviewAndPublish,
    hidePagination: true,
  },
];

const AgentWebsiteOnboardingRoute: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    agentDetail: {
      detailResponse: { data: detail },
      agentWebsite,
      loadingAgentWebsite,
    },
  } = useSelector((state: RootState) => state);
  const history = useHistory();
  const { place, loading: isLoadingPlace } = useGooglePlaceId(
    agentWebsite?.propertyFilter?.googlePlaceId,
  );
  const hasAgentWebsite = detail?.hasAgentWebsite;
  const { id: agentId } = useParams() as Match;

  const fetchData = useCallback(async () => {
    if (hasAgentWebsite) {
      dispatch(fetchAgentWebsiteDetails(agentId));
    }
  }, [agentId, dispatch, hasAgentWebsite]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const defaultValues: AgentWebsiteOnboardingFormData = getAgentWebsiteDefaultFormValues(
    hasAgentWebsite
      ? agentWebsite
      : ({ agentResponse: detail } as AgentWebsiteResponse),
    hasAgentWebsite ? place : undefined,
  );

  const onSubmit = async (formData: AgentWebsiteOnboardingFormData) => {
    const { photo, ...agentWebsiteFormData } = formData;

    if (typeof photo === 'object') {
      try {
        await new UserControllerApi(getYentaConfiguration()).uploadAvatar(
          agentId,
          photo,
        );
      } catch (e) {
        dispatch(showApiErrorModal(e));
        Logger.error('Unable to save profile image:', e);
        dispatch(showErrorToast('Unable to upload profile picture'));
        ErrorService.notify('unable to upload profile picture', e, {
          agent: { id: agentId },
        });
      }
    }

    try {
      const data: CreateOrUpdateAgentWebsiteRequest = {
        ...omit(agentWebsiteFormData, [
          'name',
          'location',
          'priceRange',
          'photo',
        ]),
        agentIdOrSlug: agentId,
        facebookURL: formUrlFromPath(
          FACEBOOK_URL,
          agentWebsiteFormData.facebookURL,
        ),
        linkedInURL: formUrlFromPath(
          LINKEDIN_URL,
          agentWebsiteFormData.linkedInURL,
        ),
        instagramURL: formUrlFromPath(
          INSTAGRAM_URL,
          agentWebsiteFormData.instagramURL,
        ),
        youtubeURL: formUrlFromPath(
          YOUTUBE_URL,
          agentWebsiteFormData.youtubeURL,
        ),
        twitterURL: formUrlFromPath(
          TWITTER_URL,
          agentWebsiteFormData.twitterURL,
        ),
        zillowURL: formUrlFromPath(ZILLOW_URL, agentWebsiteFormData.zillowURL),
        yelpURL: formUrlFromPath(YELP_URL, agentWebsiteFormData.yelpURL),
        clubhouseURL: formUrlFromPath(
          CLUBHOUSE_URL,
          agentWebsiteFormData.clubhouseURL,
        ),
        propertyFilter: {
          ...(agentWebsiteFormData.propertyFilter || {}),
          maxPrice: agentWebsiteFormData.priceRange?.max,
          minPrice: agentWebsiteFormData.priceRange?.min,
          longitude: agentWebsiteFormData.location?.geometry.location.lng,
          latitude: agentWebsiteFormData.location?.geometry.location.lat,
          googlePlaceId: agentWebsiteFormData.location?.place_id,
          homeJunctionPropertyTypes:
            agentWebsiteFormData.propertyFilter?.homeJunctionPropertyTypes ||
            [],
        },
      };

      if (detail?.hasAgentWebsite) {
        await new AgentWebsiteControllerApi(
          await getYentaConfiguration(),
        ).updateWebsiteById(agentWebsite?.id!, data);

        AmplitudeService.logEvent(AmplitudeEvent.AGENT_WEBSITE_UPDATE);
      } else {
        await new AgentWebsiteControllerApi(
          await getYentaConfiguration(),
        ).createWebsiteForAgent(data);

        AmplitudeService.logEvent(AmplitudeEvent.AGENT_WEBSITE_CREATE);
      }

      // update needs website onboarding to false if it is true.
      if (detail?.needsWebsiteOnboarding) {
        await new AgentControllerApi(
          await getYentaConfiguration(),
        ).updateAgentNeedsWebsiteOnboarding(agentId, {
          needsWebsiteOnboarding: false,
        });
      }

      dispatch(
        showSuccessToast('Your agent website was successfully updated.'),
      );

      const res = await Promise.all([
        dispatch(fetchAgentDetail(agentId)),
        dispatch(fetchAuthUserDetail(false)),
      ]);

      if (!!res?.[0]?.onboardingChecklistId) {
        const data = await dispatch(
          fetchJourney(res?.[0]?.onboardingChecklistId),
        );
        const isTabVisible =
          !!data?.items?.length &&
          data?.items?.some?.(
            (item) => item?.status !== ItemResponseStatusEnum.Accepted,
          );
        if (isTabVisible) {
          history.push(`/people/${agentId}/onboarding-checklist`);
          return;
        }
      }

      history.push(`/people/${agentId}`);
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to update agent website', e, {
        agent: { id: agentId },
      });
    }
  };

  if (loadingAgentWebsite || isLoadingPlace) {
    return <DefaultLoader />;
  }

  return (
    <ZenRoute title='Agent Website Onboarding'>
      <AgentOnboardingLayout
        title='Your Real Website'
        onClose={() => {
          history.push(`/people/${agentId}`);
        }}
        hideLogout
      >
        <StepByStepContainer<
          AgentWebsiteOnboardingFormData,
          AgentWebsiteOnboardingStepName
        >
          steps={steps}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          mode='onChange'
          reValidateMode='onChange'
        />
      </AgentOnboardingLayout>
    </ZenRoute>
  );
};

export default AgentWebsiteOnboardingRoute;
