import React from 'react';
import {
  CalendarEventResponse,
  CalendarEventResponseRsvpStatusEnum,
} from '../../../openapi/yenta';
import ZenSimpleModal from '../Modal/ZenSimpleModal';
import ZenAddToCalendarButton from './ZenAddToCalendarButton';

interface ZenEventAddToCalendarModalProps {
  isOpen: boolean;
  onClose(): void;
  event: CalendarEventResponse;
}

const ZenEventAddToCalendarModal: React.FC<ZenEventAddToCalendarModalProps> = ({
  isOpen,
  onClose,
  event,
}) => {
  const title =
    event.rsvpStatus === CalendarEventResponseRsvpStatusEnum.Yes
      ? 'You’re going!'
      : 'Need a reminder?';

  return (
    <ZenSimpleModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      size='large'
    >
      <div className='w-full px-12 py-5 flex flex-col items-center justify-center'>
        <p className='text-base text-zen-dark font-zen-body text-center'>
          Would you like to add the event “{event.title}” to your calendar?
        </p>
        <div className='mt-3'>
          <ZenAddToCalendarButton event={event} />
        </div>
        <button
          className='text-primary-blue font-zen-body font-semibold text-sm cursor-pointer'
          onClick={onClose}
        >
          No, Thanks
        </button>
      </div>
    </ZenSimpleModal>
  );
};

export default ZenEventAddToCalendarModal;
