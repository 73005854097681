import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  UNVERIFIED_COMPLIANCE_CDA,
  UNVERIFIED_COMPLIANCE_HEADLINE,
} from '../../../constants/TransactionConstants';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import AmplitudeService, {
  AmplitudeEvent,
} from '../../../services/AmplitudeService';
import { transitionTransaction } from '../../../slices/TransactionSlice';
import { AppDispatch, FeatureFlagTypeEnum } from '../../../types';
import { cn } from '../../../utils/classUtils';
import {
  getCDAName,
  getCommissionDocumentName,
} from '../../../utils/TransactionHelper';
import ZenButton from '../../Zen/ZenButton';
import { pillVariantTypeToClassNameMap } from '../../Zen/ZenStatusPill';

interface ZenApproveCommissionDocumentProps {
  transaction: TransactionResponse;
  goBack(): void;
}

const ZenApproveCommissionDocument: React.FC<ZenApproveCommissionDocumentProps> = ({
  transaction,
  goBack,
}) => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const isDoubleEnderEnabled = useFeatureFlag(FeatureFlagTypeEnum.DOUBLE_ENDER);

  const approveCommissionDocument = async () => {
    AmplitudeService.logEvent(
      AmplitudeEvent.TX_ONBOARDING_CDA_APPROVAL_SUCCESS,
    );

    setLoading(true);
    await dispatch(
      transitionTransaction(
        transaction,
        TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved,
        undefined,
        { [FeatureFlagTypeEnum.DOUBLE_ENDER]: isDoubleEnderEnabled },
      ),
    );
    setLoading(false);
    history.replace(`/transactions/${transaction.id}/detail`);
  };

  return (
    <div className='max-w-xl w-full space-y-10 px-4 md:px-0'>
      {!!transaction.office?.splitCheck && !transaction.compliantAt && (
        <div className='bg-white'>
          <div
            className={cn(
              pillVariantTypeToClassNameMap['warning'],
              'p-2',
              'rounded-lg',
            )}
          >
            <div className='flex flex-row space-x-2'>
              <FontAwesomeIcon
                icon={solid('triangle-exclamation')}
                className='mt-1'
              />
              <p className='font-zen-body font-medium'>
                {UNVERIFIED_COMPLIANCE_HEADLINE}
              </p>
            </div>
            <p className='font-zen-body pl-7 text-sm'>
              {UNVERIFIED_COMPLIANCE_CDA}
            </p>
          </div>
        </div>
      )}
      <div>
        <p className='text-xl font-zen-body font-semibold text-zen-dark-9 mb-3'>
          Ready to send?
        </p>
        <p className='text-base font-zen-body font-medium text-zen-dark-9 mb-1.5'>
          What happens next?
        </p>
        <p className='text-base font-zen-body font-thin text-zen-dark-9 mb-5'>
          {` - ${getCommissionDocumentName(
            transaction.country!,
          )} will be sent to all the authorized entities.`}
        </p>
        <div className='flex justify-between items-center space-x-3 md:space-x-0'>
          <ZenButton
            label='No, I need to make changes'
            variant='secondary-gray-outline'
            onClick={goBack}
            isDisabled={loading}
          />
          <ZenButton
            label={`Everything is correct, send the ${getCDAName(
              transaction.country!,
            )}`}
            onClick={approveCommissionDocument}
            isSubmitting={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default ZenApproveCommissionDocument;
