import ProgressBar from '../ProgressBar';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import {
  WalletApplicationFormState,
  WalletApplicationStepName,
} from './WalletApplicationSteps';

const withWalletApplicationProgress = (
  Component: StepByStepComponent<
    WalletApplicationFormState,
    WalletApplicationStepName
  >,
): StepByStepComponent<
  WalletApplicationFormState,
  WalletApplicationStepName
> => (props) => {
  return (
    <>
      <div className='absolute top-0 left-0 w-full'>
        <ProgressBar
          containerClassNames='bg-seaglass h-5'
          completed={props.progress.currentIndex + 1}
          total={props.progress.lastIndex + 1}
          textClassNames='py-0.5 font-inter text-primary-dark font-light font-normal text-sx'
        />
      </div>
      <Component {...props} />
    </>
  );
};

export default withWalletApplicationProgress;
