import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenSidebarModal from '../components/Zen/ZenSidebarModal';
import {
  OutgoingPaymentResponse,
  OutgoingPaymentResponseStatusEnum,
} from '../openapi/arrakis';
import { displayAmount } from '../utils/CurrencyUtils';
import { capitalizeEnum } from '../utils/StringUtils';
import ZenConfirmationModal from '../components/Zen/Modal/ZenConfirmationModal';
import { markOutgoingPaymentInvalid } from '../slices/TransactionSlice';
import { AppDispatch } from '../types';
import AdminOnly from '../components/auth/AdminOnly';

interface ZenOutgoingPaymentSidebarModalProps {
  isOpen: boolean;
  onClose(reload?: boolean): void;
  outgoingPayment: OutgoingPaymentResponse;
}
interface FormData {
  status: string;
  transactionCode: string;
  fullName: string;
  outgoingPaymentdAmount: string;
  paidAt: string;
  paidBy: string;
  confirmationCode: string;
  payerNote: string;
  paymentSystem: string;
  paymentSystemId: string;
}

const ZenOutgoingPaymentSidebarModal: React.FC<ZenOutgoingPaymentSidebarModalProps> = ({
  isOpen,
  onClose,
  outgoingPayment,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { control } = useForm<FormData>();
  const [isMarkAsInvalidOpen, setIsMarkAsInvalidOpen] = useState<boolean>(
    false,
  );
  const [
    isMarkingPaymentAsInvalid,
    setIsMarkingPaymentAsInvalid,
  ] = useState<boolean>(false);

  const handleOutgoingPaymentInvalid = async () => {
    setIsMarkingPaymentAsInvalid(true);
    await dispatch(markOutgoingPaymentInvalid(outgoingPayment?.id!));
    setIsMarkingPaymentAsInvalid(false);
    setIsMarkAsInvalidOpen(false);
  };

  return (
    <ZenSidebarModal title='Outgoing Payment' isOpen={isOpen} onClose={onClose}>
      <form className='flex flex-col justify-between min-h-full'>
        <div className='p-4 pb-20'>
          <ZenControlledTextInput<FormData, 'status'>
            control={control}
            label='Status'
            name='status'
            defaultValue={
              outgoingPayment?.status && capitalizeEnum(outgoingPayment?.status)
            }
            readOnly
          />
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'transactionCode'>
              control={control}
              label='Transaction Code'
              name='transactionCode'
              defaultValue={outgoingPayment?.transactionCode}
              readOnly
            />
          </div>
          {outgoingPayment?.transactionCode && (
            <Link to={`/transactions/code/${outgoingPayment?.transactionCode}`}>
              <span className='text-primary-blue font-zen-body font-medium flex items-center mt-1'>
                View Transaction{' '}
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className='ml-2'
                  fontSize={14}
                />
              </span>
            </Link>
          )}
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'fullName'>
              control={control}
              label='Full Name'
              name='fullName'
              defaultValue={`${outgoingPayment?.firstName} ${outgoingPayment?.lastName}`}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'outgoingPaymentdAmount'>
              control={control}
              label='Amount'
              name='outgoingPaymentdAmount'
              defaultValue={displayAmount(outgoingPayment?.amount)}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'paidAt'>
              control={control}
              label='Paid At'
              name='paidAt'
              defaultValue={
                outgoingPayment?.paidAt
                  ? DateTime.fromMillis(outgoingPayment?.paidAt).toFormat(
                      'LL/dd/yy',
                    )
                  : ''
              }
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'paidBy'>
              control={control}
              label='Paid By'
              name='paidBy'
              defaultValue={outgoingPayment?.paidBy}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'confirmationCode'>
              control={control}
              label='Confirmation Code'
              name='confirmationCode'
              defaultValue={outgoingPayment?.confirmationCode}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'payerNote'>
              control={control}
              label='Payer Note'
              name='payerNote'
              defaultValue={outgoingPayment?.payerNote}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'paymentSystem'>
              control={control}
              label='Payment System'
              name='paymentSystem'
              defaultValue={
                outgoingPayment?.paymentSystem &&
                capitalizeEnum(outgoingPayment?.paymentSystem)
              }
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'paymentSystemId'>
              control={control}
              label='Payment System ID'
              name='paymentSystemId'
              defaultValue={outgoingPayment?.paymentSystemId}
              readOnly
            />
          </div>
          {outgoingPayment?.status !==
            OutgoingPaymentResponseStatusEnum.InvalidPaymentDetails && (
            <AdminOnly>
              <button
                type='button'
                className='mt-5 font-zen-title w-full text-zen-danger text-right'
                onClick={() => setIsMarkAsInvalidOpen(true)}
              >
                Notify Payment Method Failed
              </button>
            </AdminOnly>
          )}
        </div>
        <ZenConfirmationModal
          title='Are you sure you want to notify about the payment method failure?'
          isOpen={isMarkAsInvalidOpen}
          onClose={() => setIsMarkAsInvalidOpen(false)}
          isSubmitting={isMarkingPaymentAsInvalid}
          isDisabled={isMarkingPaymentAsInvalid}
          onConfirm={handleOutgoingPaymentInvalid}
          confirmButtonText='Confirm'
          cancelButtonText='Cancel'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenOutgoingPaymentSidebarModal;
