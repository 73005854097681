import { RumInitConfiguration } from '@datadog/browser-rum';
import config from '../../config';

export const getTracingUrls = (): RumInitConfiguration['allowedTracingUrls'] => {
  // covers all subdomains
  const boltAndSubdomains = new RegExp(
    `${config.reactAppHostUrl.replace('bolt.', '.*\\.')}`,
  );

  return [boltAndSubdomains];
};
