import React from 'react';
import { cn } from '../../utils/classUtils';

interface ISelectOption {
  label: string;
  value: string;
}

type GeminiCheckboxVariantType = 'square' | 'circle';
type GeminiCheckboxRingVariantType = 'none' | 'default';
type GeminiCheckboxBorderVariantType = 'none' | 'dark';
type GeminiCheckboxSizeVariantType = 'small' | 'medium' | 'large';

interface GeminiCheckBoxProps {
  options: ISelectOption[];
  value: string[];
  variant?: GeminiCheckboxVariantType;
  ringVariant?: GeminiCheckboxRingVariantType;
  borderVariant?: GeminiCheckboxBorderVariantType;
  size?: GeminiCheckboxSizeVariantType;
  onToggle(newValue: string[]): void;
  isCheckedOnce?: boolean;
  disabled?: boolean;
}

const GeminiCheckBox: React.FC<GeminiCheckBoxProps> = ({
  options,
  value = [],
  onToggle,
  isCheckedOnce,
  variant = 'square',
  disabled = false,
  size = 'small',
  ringVariant = 'default',
  borderVariant = 'none',
}) => {
  const handleToggle = (checked: boolean, valueToUpdate: string) => {
    let newValue: string[];

    if (checked) {
      newValue = [...value, valueToUpdate];
    } else {
      newValue = value.filter((v) => v !== valueToUpdate);
    }

    onToggle(newValue);
  };

  const variantClassMap: Record<GeminiCheckboxVariantType, string> = {
    square: 'rounded',
    circle: 'rounded-full',
  };

  const ringVariantClassMap: Record<GeminiCheckboxRingVariantType, string> = {
    none: 'ring-0 focus:ring-0',
    default: 'ring-1 ring-grey-200 focus:ring-0',
  };

  const borderVariantClassMap: Record<
    GeminiCheckboxBorderVariantType,
    string
  > = {
    none: 'border-none',
    dark: 'border-2 border-zen-dark-4',
  };

  const sizeVariantClassMap: Record<GeminiCheckboxSizeVariantType, string> = {
    small: 'h-4 w-4',
    medium: 'h-6 w-6',
    large: 'h-8 w-8',
  };

  return (
    <div>
      {options.map((item) => {
        const checked = !!value?.find((val: string) => val === item.value);
        return (
          <div
            key={item.value}
            className='flex-row items-center my-2'
            onClick={() => handleToggle(!checked, item.value)}
          >
            <input
              id={item.value}
              type='checkbox'
              data-testid='gemini-checkbox'
              className={cn(
                'focus:outline-none text-rezen-blue-600 indeterminate:text-dark',
                variantClassMap[variant],
                ringVariantClassMap[ringVariant],
                borderVariantClassMap[borderVariant],
                sizeVariantClassMap[size],
                isCheckedOnce && !value && 'ring-zen-danger',
                !disabled && 'cursor-pointer',
              )}
              checked={checked}
              onChange={() => handleToggle(!checked, item.value)}
            />
            <span className='font-primary-regular flex-wrap text-zen-dark-7 text-base ml-2'>
              {item.label}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default GeminiCheckBox;
