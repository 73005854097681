import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import qs, { IParseOptions, IStringifyOptions } from 'qs';

export interface UsePageQueryParamsProps {
  parseOptions?: IParseOptions;
  stringifyOptions?: IStringifyOptions;
}

export interface ParsedQuery<T = string> {
  [key: string]: T | null | Array<T | null> | unknown;
}

export interface QueryParamsType {
  parsed: ParsedQuery<string>;
  history: ReturnType<typeof useHistory>;
  search: string;
  qs: typeof qs;
  pushNewQuery: (query: Record<string, any>, state?: any) => void;
  putNewQuery: (query: Record<string, any>, state?: any) => void;
}

export const usePageQueryParams = (
  options: UsePageQueryParamsProps = {},
): QueryParamsType => {
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const parsed = useMemo(
    () =>
      qs.parse(search, { ignoreQueryPrefix: true, ...options?.parseOptions }),
    [search, options?.parseOptions],
  );
  // push a new history stack with only changes to a specific query parameter
  const pushNewQuery = useCallback(
    (query, state) => {
      const newQueries = { ...parsed, ...query };

      history.push(
        { search: qs.stringify(newQueries, options?.stringifyOptions) },
        state,
      );
    },
    [parsed, history, options?.stringifyOptions],
  );

  const putNewQuery = useCallback(
    (query, state) => {
      const newQueries = { ...parsed, ...query };

      history.replace(
        { search: qs.stringify(newQueries, options?.stringifyOptions) },
        state,
      );
    },
    [parsed, history, options?.stringifyOptions],
  );

  return { parsed, history, search, qs, pushNewQuery, putNewQuery };
};
