import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import {
  EnumMap,
  FeatureFlagTypeEnum,
  FormFieldTooltipIndexProps,
  ISelectOption,
} from '../../../types';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { cn } from '../../../utils/classUtils';
import FormErrorMessage from './ZenFormErrorMessage';

type ZenRadioInputVariant = 'default' | 'outline';

export interface ZenRadioOptionComponentProps<
  TFieldValues extends FieldValues = FieldValues
> {
  value: string;
  name: string;
  disabled?: boolean;
  checked: boolean;
  label: string;
  subLabel?: string;
  invalid?: boolean;
  onChange(value: string): void;
  control?: Control<TFieldValues, object>;
}

export type ZenRadioOptionComponent<
  TFieldValues extends FieldValues = FieldValues
> = React.FC<ZenRadioOptionComponentProps<TFieldValues>>;

interface ZenControlledRadioInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  subLabel?: string;
  variant?: ZenRadioInputVariant;
  options: Array<ISelectOption>;
  inlineOptions?: boolean;
  inlineLabel?: boolean;
  disabled?: boolean;
  radioButtonStyle?: string;
  isRequired?: boolean;
  containerStyles?: string;
  OptionComponent?: ZenRadioOptionComponent<TFieldValues>;
}

const ZenControlledRadioInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  options,
  variant = 'default',
  inlineOptions = false,
  inlineLabel = false,
  disabled = false,
  tooltipIndex,
  shouldUnregister = true,
  radioButtonStyle,
  isRequired = false,
  containerStyles,
  OptionComponent,
  ...rest
}: ZenControlledRadioInputProps<TFieldValues, TName>) => {
  const variantToClassNameMap: EnumMap<ZenRadioInputVariant, string> = {
    default:
      'border-zen-dark-4 focus:text-primary-blue focus:outline-none focus:ring-0',
    outline:
      'border-zen-dark-4 focus:border-primary-blue focus:outline-none focus:ring-0',
  };

  const isGeminiControlledInputsEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.GEMINI_REDESIGN,
  );

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur },
        fieldState: { error, invalid },
      }) => (
        <div
          className={cn(
            'w-full space-y-1',
            isGeminiControlledInputsEnabled && 'font-inter',
          )}
        >
          <div
            className={cn('flex', {
              'justify-between': inlineLabel,
              'flex-col space-y-1': !inlineLabel,
            })}
          >
            {label && (
              <label htmlFor={name} className='mb-2'>
                <span
                  className={cn(
                    !isGeminiControlledInputsEnabled &&
                      'font-zen-body font-semibold',
                    invalid
                      ? 'text-zen-danger'
                      : isGeminiControlledInputsEnabled
                      ? 'text-primary-dark'
                      : 'text-zen-dark-9',
                  )}
                >
                  {label}
                </span>
                {isRequired && <span className='text-error'>*</span>}
                {!!subLabel && (
                  <span className='ml-1 text-sm text-zen-dark-12 font-zen-body'>
                    {subLabel}
                  </span>
                )}
              </label>
            )}
            <div
              className={cn(
                inlineOptions
                  ? isGeminiControlledInputsEnabled
                    ? 'flex space-x-8'
                    : 'flex space-x-3'
                  : 'space-y-1',
                containerStyles,
              )}
              data-tooltip-index={tooltipIndex}
            >
              {options.map(({ value: optionValue, label, subLabel }) => (
                <label
                  key={optionValue}
                  className={cn(
                    'flex items-center justify-start space-x-2',
                    radioButtonStyle,
                  )}
                >
                  {OptionComponent ? (
                    <OptionComponent
                      name={name}
                      onChange={onChange}
                      label={label}
                      subLabel={subLabel}
                      checked={value === optionValue}
                      disabled={disabled}
                      value={optionValue}
                      invalid={invalid}
                      control={rest.control}
                    />
                  ) : (
                    <>
                      <input
                        type='radio'
                        className={cn(
                          'w-4 h-4 border-2 rounded-full',
                          variantToClassNameMap[variant],
                        )}
                        value={optionValue}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        checked={value === optionValue}
                        disabled={disabled}
                      />
                      <p
                        className={cn(
                          isGeminiControlledInputsEnabled
                            ? 'font-inter text-base text-zen-dark-9'
                            : 'font-zen-body text-sm',
                        )}
                      >
                        {label}
                        <br />
                        {!!subLabel && (
                          <span className='mt-1 font-zen-body text-sm text-zen-dark-12'>
                            {subLabel}
                          </span>
                        )}
                      </p>
                    </>
                  )}
                </label>
              ))}
            </div>
          </div>
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenControlledRadioInput;
