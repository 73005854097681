import React from 'react';
import { FieldObject, FieldObjectTypeEnum } from '../../../openapi/atlantis';
import MortgageApplicationConditionalFormFieldArrayInput from './MortgageApplicationConditionalFormFieldArrayInput';
import MortgageApplicationConditionalFormInput from './MortgageApplicationConditionalFormInput';

interface MortgageApplicationFieldsProps {
  fields?: FieldObject[];
  onSubmit: () => void;
  isReadOnly: boolean;
}

const MortgageApplicationFields: React.FC<MortgageApplicationFieldsProps> = ({
  fields = [],
  onSubmit,
  isReadOnly,
}) => {
  return (
    <div className='grid grid-cols-3 items-end gap-4'>
      {fields.map((field: FieldObject) => {
        const isFieldArray = field.type === FieldObjectTypeEnum.FieldArray;

        if (isFieldArray) {
          return (
            <MortgageApplicationConditionalFormFieldArrayInput
              key={field.id}
              field={field}
              onSubmit={onSubmit}
              isReadOnly={isReadOnly}
            />
          );
        }

        return (
          <MortgageApplicationConditionalFormInput
            key={field.id}
            name={field.id!}
            field={field}
            onSubmit={onSubmit}
            isReadOnly={isReadOnly}
          />
        );
      })}
    </div>
  );
};
export default MortgageApplicationFields;
