import { EventSourcePolyfill } from 'event-source-polyfill';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import config from '../../config';
import { TransactionControllerApi } from '../../openapi/arrakis';
import { queryKeys } from '../../query/base/queryKeys';
import { useSimpleQuery } from '../../query/base/useSimpleQuery';
import { RootState } from '../../types';
import { getAuthCookie } from '../../utils/AuthUtils';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';
import CallConnectedSvg from '../../assets/img/roar/call-connected.svg';

type SSECallEvent = {
  container?: { id?: string; type?: 'TRANSACTION' };
  callee?: {
    name: string;
    userId: string;
    phone: string;
    email: string;
  };
  caller?: {
    name: string;
    userId: string;
    phone: string;
    email: string;
  };
};

const VoiceCallConnectedModal: React.FC = () => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [eventDetails, setEventDetails] = useState<SSECallEvent>();
  const userId = userDetail?.id!;
  const transactionId = eventDetails?.container?.id;

  const { isLoading, data } = useSimpleQuery({
    queryKey: queryKeys.transaction.detail(transactionId!).queryKey,
    queryFn: () =>
      new TransactionControllerApi(getArrakisConfiguration()).getTransaction(
        transactionId!,
      ),
    options: {
      logErrorMessage: 'Failed to fetch transaction for auto open transaction',
      toastErrorMessage: 'Failed to fetch transaction',
      skipShowToastOnError: true,
      enabled:
        eventDetails?.container?.type === 'TRANSACTION' && !!transactionId,
    },
  });

  useEffect(() => {
    let eventSource: EventSourcePolyfill | undefined;

    if (userId) {
      const sseUrl = `${config.apiBasePath.yada}/api/v1/voice/calls/sse-subscribe?calleeUserId=${userId}`;
      eventSource = new EventSourcePolyfill(sseUrl, {
        headers: { Authorization: `Bearer ${getAuthCookie()}` },
        heartbeatTimeout: 300000, // 5 min - keep alive
      });
      eventSource.onmessage = (event) => {
        if (event.data.length) {
          const data = JSON.parse(event.data) as SSECallEvent;
          if (data) {
            setEventDetails(data as SSECallEvent);
          }
        }
      };
    }

    return () => {
      eventSource?.close();
    };
  }, [userId]);

  const txAddr = data?.data?.address?.oneLine;

  if (!eventDetails || !txAddr || isLoading) {
    return null;
  }

  return (
    <ZenConfirmationModal
      isOpen={!!eventDetails?.container?.id}
      title='Would you like to navigate to this transaction?'
      containerClassNames='w-full max-w-lg'
      onConfirm={() => {
        window.open(`/transactions/${transactionId}/detail`, '_blank');
        setEventDetails(undefined);
      }}
      onClose={() => setEventDetails(undefined)}
      confirmButtonText='Yes'
      cancelButtonText='No'
    >
      <div className='p-4 flex flex-col justify-center items-center space-y-4'>
        <img src={CallConnectedSvg} alt='call-icon' />
        <p className='font-zen-body text-zen-dark-9'>
          You are now ROARing with{' '}
          <span className='font-semibold'>{eventDetails?.caller?.name}</span>{' '}
          about:
        </p>
        <p className='font-zen-body font-semibold text-zen-dark-9'>{txAddr}</p>
      </div>
    </ZenConfirmationModal>
  );
};

export default VoiceCallConnectedModal;
