import { useRef, useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MdAutorenew } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/pro-regular-svg-icons';
import { cn } from '../../../utils/classUtils';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  isDraggableImageMimeTypeValid,
  isDraggableImageValid,
  isImageMimeTypeValid,
  isImageValid,
} from '../../../utils/AgentHelper';
import ZenFormErrorMessage from '../../Zen/Input/ZenFormErrorMessage';
import GeminiHover from '../GeminiHover';

export type fileInputSize =
  | 'medium'
  | 'large'
  | 'small'
  | 'extraSmall'
  | 'extraLarge';

interface GeminiControlledDraggableImageUploadInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  placeholder?: string;
  fileInputSize?: fileInputSize;
  downloadUrl?: string;
  borderPrimary?: boolean;
  onDelete(): void;
}

const GeminiControlledDraggableImageUploadInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  placeholder,
  fileInputSize = 'large',
  rules,
  downloadUrl,
  borderPrimary = false,
  onDelete,
  ...rest
}: GeminiControlledDraggableImageUploadInputProps<TFieldValues, TName>) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isHoveringDelete, setIsHoveringDelete] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const fileUploadInputContainerSizeToClassNameMap: {
    [type in fileInputSize]: string;
  } = {
    medium: 'w-48 h-48',
    large: 'w-60 h-60',
    small: 'w-36 h-36',
    extraSmall: 'w-24 h-24',
    extraLarge: 'w-80 h-80',
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      {...rest}
      render={({ field: { name, onChange, value }, fieldState: { error } }) => {
        return (
          <div className='w-full'>
            {label && (
              <label
                className='font-zen-body text-base font-semibold text-zen-dark-9 inline-block'
                htmlFor={name}
              >
                {label}
              </label>
            )}
            <div className='w-full flex items-center justify-center cursor-pointer'>
              {!!value &&
              (typeof value === 'object' || typeof value === 'string') ? (
                <div className='relative'>
                  <img
                    src={
                      typeof value === 'object'
                        ? URL.createObjectURL(value)
                        : value
                    }
                    alt='check-img'
                    className={cn(
                      fileUploadInputContainerSizeToClassNameMap[fileInputSize],
                      'bg-center bg-cover bg-no-repeat border border-zen-dark-5 rounded-full',
                    )}
                  />
                  <div className='absolute -top-1 right-4'>
                    <GeminiHover
                      hoverComponent={
                        <p className='font-zen-body font-normal text-sm text-primary-dark px-1.5 py-0.5'>
                          Delete
                        </p>
                      }
                      config={{ trigger: 'hover', placement: 'top' }}
                    >
                      <button
                        type='button'
                        onClick={async (e) => {
                          e.stopPropagation();
                          setIsDeleting(true);
                          await onDelete();
                          setIsDeleting(false);
                          setIsHoveringDelete(false);
                        }}
                        onMouseOver={() => setIsHoveringDelete(true)}
                        onMouseOut={() => setIsHoveringDelete(false)}
                        disabled={isDeleting}
                        className='bg-red-100 hover:bg-red-600 flex items-center justify-center p-2 rounded-full'
                      >
                        {isDeleting ? (
                          <MdAutorenew
                            className='text-primary-blue animate-spin mr-1'
                            fontSize={14}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className={cn(
                              isHoveringDelete ? 'text-white' : 'text-red-600',
                            )}
                            icon={light('trash-can')}
                            fontSize={16}
                            aria-label='delete-file'
                          />
                        )}
                      </button>
                    </GeminiHover>
                  </div>
                </div>
              ) : (
                <div
                  className={cn(
                    'relative rounded-full cursor-pointer bg-primary-navy',
                    fileUploadInputContainerSizeToClassNameMap[fileInputSize],
                    {
                      'border-red-500': !!error,
                      'border-zen-dark-5': !error && !borderPrimary,
                      'border-zen-primary': !error && borderPrimary,
                    },
                  )}
                  onClick={() =>
                    typeof value !== 'string' && fileInputRef.current?.click()
                  }
                >
                  <div className='bg-primary-navy bg-opacity-30 h-full w-full rounded-full flex flex-col justify-center items-center gap-2'>
                    {(value == null ||
                      (typeof value !== 'string' &&
                        typeof value !== 'object')) && (
                      <>
                        <FontAwesomeIcon
                          icon={faCamera}
                          className='text-white h-8 w-8'
                        />
                        <span className='text-white'>Upload Image</span>
                      </>
                    )}
                  </div>

                  <div className='rounded-full hidden w-full h-full'>
                    <input
                      id={name}
                      ref={fileInputRef}
                      onChange={async (e) => {
                        if (isImageValid(e) && isImageMimeTypeValid(e)) {
                          onChange(e.target.files![0]);
                        } else if (
                          isImageValid(e) &&
                          !isImageMimeTypeValid(e)
                        ) {
                          await dispatch(
                            showErrorToast(
                              'File type is incorrect. Only .jpeg, .png files are accepted',
                            ),
                          );
                        } else {
                          await dispatch(
                            showErrorToast(
                              'File size exceeds maximum limit of 10 MB.',
                            ),
                          );
                        }
                      }}
                      onDrop={async (e) => {
                        if (
                          isDraggableImageValid(e) &&
                          isDraggableImageMimeTypeValid(e)
                        ) {
                          e.preventDefault();
                          onChange(e.dataTransfer.files[0]);
                        } else if (
                          isDraggableImageValid(e) &&
                          !isDraggableImageMimeTypeValid(e)
                        ) {
                          e.preventDefault();
                          await dispatch(
                            showErrorToast(
                              'File type is incorrect. Only .jpeg, .png files are accepted',
                            ),
                          );
                        } else {
                          e.preventDefault();
                          await dispatch(
                            showErrorToast(
                              'File size exceeds maximum limit of 30 MB.',
                            ),
                          );
                        }
                      }}
                      placeholder={placeholder}
                      accept='image/*'
                      type='file'
                      name={name}
                      className=''
                    />
                  </div>
                </div>
              )}
              {error && <ZenFormErrorMessage message={error.message} />}
            </div>
          </div>
        );
      }}
    />
  );
};

export default GeminiControlledDraggableImageUploadInput;
