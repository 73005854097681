import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as HandMoney } from '../../../assets/icons/zen/transaction/hand-give-money.svg';
import useAgentsInfo from '../../../hooks/useAgentsInfo';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useGemini } from '../../../hooks/useGemini';
import {
  ParticipantValue,
  PaymentParticipantValue,
  PaymentParticipantValueRoleEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import {
  toggleAddCommissionParticipant,
  toggleCommissionSplit,
} from '../../../slices/QuickActionSlice';
import { removeTransactionParticipant } from '../../../slices/TransactionSlice';
import { AppDispatch, FeatureFlagTypeEnum, RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { showDoubleEnderParticipantInTx } from '../../../utils/DoubleEnderUtils';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import {
  isParticipantTransactionOwner,
  isTransactionReadOnly,
} from '../../../utils/TransactionHelper';
import EmptyParticipantPlaceholder from '../../EmptyParticipantPlaceholder';
import ZenEditTransactionParticipantFormSidebarModal from '../forms/ZenEditTransactionParticipantFormSidebarModal';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import ZenCard from '../ZenCard';
import ZenAddParticipantsForm from './ZenAddParticipantsForm';
import ZenCommissionSplitForm from './ZenCommissionSplitForm';
import ZenDoubleEnderParticipant from './ZenDoubleEnderParticipant';
import ZenPaymentParticipantCard from './ZenPaymentParticipantCard';

interface PaymentParticipantSectionProps {
  transactionDetail: TransactionResponse;
}

const ZenTransactionParticipantsSection: React.FC<PaymentParticipantSectionProps> = ({
  transactionDetail,
}) => {
  const { isAdmin, userDetail } = useSelector((state: RootState) => state.auth);
  const dispatch: AppDispatch = useDispatch();
  const {
    showCommissionParticipantSidebar,
    addCommissionParticipantSidebar,
  } = useSelector((state: RootState) => state.quickAction);
  const [
    openEditParticipantFormSidebarModal,
    setOpenEditParticipantFormSidebarModal,
  ] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState<boolean>(false);

  const [payoutParticipant, setPayoutParticipant] = useState<
    PaymentParticipantValue | ParticipantValue | null
  >(null);
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);

  const handleEditParticipant = (
    participant: PaymentParticipantValue | ParticipantValue,
  ) => {
    setPayoutParticipant(participant);
    setOpenEditParticipantFormSidebarModal(true);
  };

  const handleDeleteParticipant = (
    participant: PaymentParticipantValue | ParticipantValue,
  ) => {
    setPayoutParticipant(participant);
    setIsConfirmDelete(true);
  };

  const isGeminiEnabled = useGemini();

  const deleteParticipant = async () => {
    setDeleting(true);
    const success = await dispatch(
      removeTransactionParticipant(
        transactionDetail.id!,
        payoutParticipant?.id!,
      ),
    );
    if (success) {
      setIsConfirmDelete(false);
    }
    setDeleting(false);
  };

  const isHeaderVisible = !isTransactionReadOnly(
    transactionDetail,
    userDetail!,
  );

  const agentIds = useMemo(
    () =>
      transactionDetail?.paymentParticipants?.map(
        (participant) => participant.yentaId!,
      ) || [],
    [transactionDetail],
  );

  useAgentsInfo(agentIds);

  const getCardRightComponent = () => {
    if (!isHeaderVisible) return;

    return (
      <div className='flex items-center'>
        {!isTransactionReadOnly(transactionDetail, userDetail!) && (
          <div
            className={cn(
              'flex items-center ml-3 cursor-pointer',
              isGeminiEnabled
                ? 'font-inter font-medium text-base leading-5 text-center text-rezen-blue-600'
                : 'primary-light text-primary-blue',
            )}
            onClick={() => dispatch(toggleCommissionSplit(true))}
          >
            <HandMoney
              fontSize={13}
              className={cn(
                'mr-2 mb-0.5',
                isGeminiEnabled
                  ? 'font-light text-[13px] leading-[23px] text-rezen-blue-600'
                  : ' text-primary-blue font-primary-bold',
              )}
            />
            Adjust Commission (%) / splits
          </div>
        )}
      </div>
    );
  };

  const { officeDetailById } = useSelector((state: RootState) => state.office);

  const office = officeDetailById[transactionDetail.office?.id!];

  const isDoubleEnderEnabled = useFeatureFlag(FeatureFlagTypeEnum.DOUBLE_ENDER);

  const showDoubleEnderAgent =
    isDoubleEnderEnabled &&
    showDoubleEnderParticipantInTx(transactionDetail, office);

  return (
    <div className='py-4'>
      <ZenCard
        title='Payment Participants'
        RightComponent={getCardRightComponent()}
      >
        <div className='col-span-12 p-5'>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'>
            {transactionDetail?.paymentParticipants?.map((participant) => {
              return (
                <div key={participant.id}>
                  <ZenPaymentParticipantCard
                    transaction={transactionDetail}
                    participant={participant}
                    onEdit={() => handleEditParticipant(participant)}
                    onDelete={() => {
                      if (
                        isAdmin ||
                        participant.role !==
                          PaymentParticipantValueRoleEnum.TeamLeader
                      ) {
                        handleDeleteParticipant(participant);
                      }
                    }}
                    isReadonly={isTransactionReadOnly(
                      transactionDetail,
                      userDetail!,
                    )}
                    transactionOwner={isParticipantTransactionOwner(
                      transactionDetail.transactionOwner!,
                      participant,
                    )}
                    isAdmin={isAdmin}
                    isSingleCheck={
                      participant.paidByReal ||
                      !transactionDetail.office?.splitCheck
                    }
                    isPaymentDetailsHidden={participant?.paymentDetailsHidden}
                  />
                </div>
              );
            })}
            {showDoubleEnderAgent && (
              <ZenDoubleEnderParticipant
                doubleEnderInfo={transactionDetail.doubleEnderInfo}
                commissionInfo={
                  transactionDetail.doubleEnderInfo?.otherCommissionInfo!
                }
              />
            )}
            <EmptyParticipantPlaceholder
              participantLength={
                transactionDetail.paymentParticipants?.length! +
                (showDoubleEnderAgent ? 1 : 0)
              }
            />
          </div>
        </div>
      </ZenCard>

      {showCommissionParticipantSidebar && (
        <ZenCommissionSplitForm
          isOpen
          onClose={() => dispatch(toggleCommissionSplit(false))}
          transaction={transactionDetail}
        />
      )}
      {addCommissionParticipantSidebar?.isOpen && (
        <ZenAddParticipantsForm
          isOpen={addCommissionParticipantSidebar?.isOpen || false}
          onClose={() =>
            dispatch(toggleAddCommissionParticipant({ isOpen: false }))
          }
          transaction={transactionDetail}
          agentType={addCommissionParticipantSidebar?.agentType}
          role={addCommissionParticipantSidebar?.role}
        />
      )}

      {payoutParticipant && (
        <>
          {openEditParticipantFormSidebarModal && (
            <ZenEditTransactionParticipantFormSidebarModal
              transaction={transactionDetail}
              participant={payoutParticipant}
              readOnly={isTransactionReadOnly(transactionDetail, userDetail!)}
              isOpen
              onClose={() => setOpenEditParticipantFormSidebarModal(false)}
            />
          )}
          <ZenConfirmationModal
            isOpen={isConfirmDelete}
            onClose={() => setIsConfirmDelete(false)}
            variant='danger'
            isSubmitting={isDeleting}
            isDisabled={isDeleting}
            onConfirm={deleteParticipant}
            cancelButtonText='Cancel'
            confirmButtonText='Confirm'
            title={`Remove ${getParticipantName(payoutParticipant)}?`}
            subtitle='You can always add them back in the future.'
          />
        </>
      )}
    </div>
  );
};

export default ZenTransactionParticipantsSection;
