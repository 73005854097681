import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import {
  AgentDebtResponse,
  AgentDebtResponseStatusEnum,
} from '../../openapi/arrakis';
import { displayAmount } from '../../utils/CurrencyUtils';
import DebtPendingBalance from './DebtPendingBalance';

export interface AllDebtsDeductionCellProps {
  debt?: AgentDebtResponse;
}

const AllDebtsDeductionCell: React.FC<AllDebtsDeductionCellProps> = ({
  debt,
}) => {
  if (!debt?.amount?.amount) {
    return (
      <div className='text-center'>
        <FontAwesomeIcon
          icon={regular('dash')}
          title='dash'
          className='text-zen-dark-9'
        />
      </div>
    );
  }

  return (
    <div className='flex flex-col space-y-1 items-start font-normal'>
      <p className='font-zen-body font-normal text-sm text-zen-dark-9 min-w-max'>
        {displayAmount(debt?.amount, { hideCurrency: true })}{' '}
        {debt?.amount?.currency}
      </p>
      {!isEmpty(debt?.pendingAmount) &&
        debt?.status === AgentDebtResponseStatusEnum.Active && (
          <DebtPendingBalance pendingAmount={debt?.pendingAmount!} />
        )}
    </div>
  );
};

export default AllDebtsDeductionCell;
