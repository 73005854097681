import { faArrowRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Column, IdType } from 'react-table';
import IconButton from '../../components/IconButton';
import NullableTextCell from '../../components/table/Cells/NullableTextCell';
import ZenViewActionButtonCell from '../../components/table/Cells/ZenViewActionButtonCell';
import InvoiceStatusSelectColumnFilter from '../../components/table/Filters/InvoiceStatusSelectColumnFilter';
import NumberColumnFilter from '../../components/table/Filters/NumberColumnFilter';
import TextColumnFilter from '../../components/table/Filters/TextColumnFilter';
import TextStrictCaseColumnFilter from '../../components/table/Filters/TextStrictCaseColumnFilter';
import ZenResourceIndexContainer from '../../components/Zen/Containers/ZenResourceIndexContainer';
import ZenInvoiceFormSidebarModal from '../../components/Zen/Invoices/ZenInvoiceFormSidebarModal';
import ZenRetryInvoiceModal from '../../components/Zen/Invoices/ZenRetryInvoiceModal';
import ZenConfirmationModal from '../../components/Zen/Modal/ZenConfirmationModal';
import ZenInvoicesStatusCell from '../../components/Zen/Table/Cell/ZenInvoicesStatusCell';
import ZenTransactionCodeCell from '../../components/Zen/Transaction/ZenTransactionCodeCell';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import {
  InvoiceControllerApi,
  InvoiceResponse,
  InvoiceResponseStatusEnum,
  TransactionControllerApi,
  TransactionLifecycleStateValueStateEnum,
} from '../../openapi/arrakis';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import { showErrorToastForErrorCode } from '../../slices/ToastNotificationSlice';
import { transitionTransaction } from '../../slices/TransactionSlice';
import {
  AppDispatch,
  EnumMap,
  FeatureFlagTypeEnum,
  SearchParam,
} from '../../types';
import { displayAmount } from '../../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';

interface ZenAgentDetailPaymentProps {
  hiddenColumns?: Array<IdType<InvoiceResponse>>;
  agentId?: string;
}

export const columns: Array<Column<InvoiceResponse>> = [
  {
    Header: 'Name',
    accessor: 'firstName',
    Filter: TextColumnFilter,
    Cell: ({ value, row: { original } }) => (
      <p>{`${value} ${original.lastName}`}</p>
    ),
  },
  {
    Header: 'Transaction Code',
    accessor: 'transactionCode',
    Cell: ({ row: { original } }) => (
      <ZenTransactionCodeCell
        linkTo={`/transactions/code/${original.transactionCode}`}
        transaction={{ ...original, code: original.transactionCode }}
        hideInvoice
      />
    ),
    Filter: TextColumnFilter,
  },
  {
    Header: 'Invoice Number',
    accessor: 'invoiceNumber',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Amount',
    accessor: 'invoicedAmount',
    Cell: ({ value }) => (value ? displayAmount(value) : 'N/A'),
    Filter: NumberColumnFilter,
    disableFilters: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Filter: InvoiceStatusSelectColumnFilter,
    Cell: ({ value }) => <ZenInvoicesStatusCell type={value!} />,
  },
  {
    Header: 'Company',
    accessor: 'company',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Payment System Id',
    accessor: 'paymentSystemId',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => value,
    Filter: TextStrictCaseColumnFilter,
    cardColSize: 6,
  },
];

const ZenAgentDetailPaymentsComponent: React.FC<ZenAgentDetailPaymentProps> = ({
  hiddenColumns,
  agentId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const isDoubleEnderEnabled = useFeatureFlag(FeatureFlagTypeEnum.DOUBLE_ENDER);

  const [invoiceId, setInvoiceId] = useState<string | null>(null);
  const [currentInvoice, setCurrentInvoice] = useState<InvoiceResponse | null>(
    null,
  );

  const [retryingInvoice, setRetryingInvoice] = useState<
    InvoiceResponse | undefined
  >(undefined);
  const [transactionCode, setTransactionCode] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getInvoiceDetailsById = useCallback(
    async (id: string) => {
      try {
        const { data } = await new InvoiceControllerApi(
          getArrakisConfiguration(),
        ).getInvoice(id);

        setCurrentInvoice(data);
      } catch (e) {
        dispatch(
          showErrorToastForErrorCode(
            'We were unable to fetch the invoice details',
            ErrorService.getErrorCode(e),
          ),
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (!!invoiceId) {
      getInvoiceDetailsById(invoiceId);
    }
  }, [invoiceId, getInvoiceDetailsById]);

  const transitionTransactionToPaymentAccepted = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).getTransactionByCode(transactionCode!);

      await dispatch(
        transitionTransaction(
          data,
          TransactionLifecycleStateValueStateEnum.PaymentAccepted,
          undefined,
          { [FeatureFlagTypeEnum.DOUBLE_ENDER]: isDoubleEnderEnabled },
        ),
      );
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to fetch transaction with the code', e, {
        transaction: { code: transactionCode },
      });
      dispatch(
        showErrorToastForErrorCode(
          'Unable to fetch transaction with the code',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setIsSubmitting(false);
      setTransactionCode(null);
    }
  };

  const columnsWithAction: Array<Column<InvoiceResponse>> = [
    {
      Header: 'Action',
      accessor: 'id',
      id: 'action',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row: { original } }) => (
        <div className='flex space-x-2'>
          <ZenViewActionButtonCell onClick={() => setInvoiceId(original.id!)} />
          {original.status === InvoiceResponseStatusEnum.NetsuiteError && (
            <IconButton
              buttonStyle='border border-primary-blue bg-primary-blue rounded-lg'
              variant='none'
              leftIcon={
                <FontAwesomeIcon
                  icon={faArrowRotateRight}
                  title='Retry'
                  className='text-base text-white mx-1'
                />
              }
              onClick={() => setRetryingInvoice(original)}
            />
          )}
        </div>
      ),
    },
    ...columns,
  ];

  const InvoiceSortByMap: EnumMap<
    string,
    | 'FIRST_NAME'
    | 'LAST_NAME'
    | 'TRANSACTION_CODE'
    | 'INVOICE_NUMBER'
    | 'AMOUNT'
    | 'STATUS'
    | 'COMPANY'
    | 'PAYMENT_SYSTEM_ID'
    | 'ID'
  > = {
    firstName: 'FIRST_NAME',
    lastName: 'LAST_NAME',
    transactionCode: 'TRANSACTION_CODE',
    invoiceNumber: 'INVOICE_NUMBER',
    invoicedAmount: 'AMOUNT',
    status: 'STATUS',
    company: 'COMPANY',
    paymentSystemId: 'PAYMENT_SYSTEM_ID',
    id: 'ID',
  };
  const InvoiceSortDirection: EnumMap<string, 'ASC' | 'DESC'> = {
    asc: 'ASC',
    desc: 'DESC',
  };

  return (
    <>
      <div className='px-4 lg:py-5'>
        <ZenResourceIndexContainer<InvoiceResponse>
          header='Payments Invoices'
          columns={columnsWithAction}
          resourceName='Payments Invoice'
          hiddenColumns={hiddenColumns}
          pageSize={10}
          fetchData={async (req) => {
            const sortKey = Object.keys(req.sortBy || {})[0];
            const sortType = Object.values(req.sortBy || {})[0];

            const { data } = await new InvoiceControllerApi(
              getArrakisConfiguration(),
            ).getInvoices(
              req.page,
              req.pageSize,
              InvoiceSortDirection[sortType],
              [InvoiceSortByMap[sortKey!]],
              req.search,
              req.filter?.firstName as string,
              req.filter?.lastName as string,
              req.filter?.transactionCode as string,
              req.filter?.invoiceNumber as string,
              req.filter?.company as string,
              req.filter?.paymentSystemId as string,
              agentId,
              (req.filter?.id as SearchParam)?.value as string,
              req.filter?.invoicedAmount as number,
              undefined,
              undefined,
              (req.filter?.status as SearchParam)
                ?.values as InvoiceResponseStatusEnum[],
            );

            return {
              data: data?.results || [],
              total: data?.totalCount || 0,
            };
          }}
        />
      </div>

      <ZenInvoiceFormSidebarModal
        isOpen={!!invoiceId && !!currentInvoice}
        onClose={() => {
          setInvoiceId(null);
          setCurrentInvoice(null);
        }}
        invoice={currentInvoice!}
      />

      <ZenConfirmationModal
        variant='warning'
        subtitle='Once done, payments will be sent out to all suitable parties. This action cannot be undone.'
        title='Are you sure you want to mark this as received?'
        onClose={() => setTransactionCode(null)}
        onConfirm={transitionTransactionToPaymentAccepted}
        isSubmitting={isSubmitting}
        isOpen={!!transactionCode?.length}
        confirmButtonText='Confirm'
        size='large'
      />

      <ZenRetryInvoiceModal
        isOpen={!!retryingInvoice}
        onClose={() => setRetryingInvoice(undefined)}
        invoice={retryingInvoice}
      />
    </>
  );
};

export default ZenAgentDetailPaymentsComponent;
