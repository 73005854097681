/* tslint:disable */
/* eslint-disable */
/**
 * Mercury API
 * Mercury Main Application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AgentHasCapStatus
 */
export interface AgentHasCapStatus extends DisplayCondition {
    /**
     * 
     * @type {boolean}
     * @memberof AgentHasCapStatus
     */
    'capped'?: boolean;
}
/**
 * 
 * @export
 * @interface AgentHasCapStatusAllOf
 */
export interface AgentHasCapStatusAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof AgentHasCapStatusAllOf
     */
    'capped'?: boolean;
}
/**
 * 
 * @export
 * @interface AgentHasEliteStatus
 */
export interface AgentHasEliteStatus extends DisplayCondition {
    /**
     * 
     * @type {boolean}
     * @memberof AgentHasEliteStatus
     */
    'elite'?: boolean;
}
/**
 * 
 * @export
 * @interface AgentHasEliteStatusAllOf
 */
export interface AgentHasEliteStatusAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof AgentHasEliteStatusAllOf
     */
    'elite'?: boolean;
}
/**
 * 
 * @export
 * @interface AgentIdOneOf
 */
export interface AgentIdOneOf extends DisplayCondition {
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentIdOneOf
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface AgentIdOneOfAllOf
 */
export interface AgentIdOneOfAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentIdOneOfAllOf
     */
    'ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AgentJoinedBetween
 */
export interface AgentJoinedBetween extends DisplayCondition {
    /**
     * 
     * @type {string}
     * @memberof AgentJoinedBetween
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentJoinedBetween
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface AgentJoinedBetweenAllOf
 */
export interface AgentJoinedBetweenAllOf {
    /**
     * 
     * @type {string}
     * @memberof AgentJoinedBetweenAllOf
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentJoinedBetweenAllOf
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface AgentMemberOfAnyTeam
 */
export interface AgentMemberOfAnyTeam extends DisplayCondition {
    /**
     * 
     * @type {boolean}
     * @memberof AgentMemberOfAnyTeam
     */
    'teamMember'?: boolean;
}
/**
 * 
 * @export
 * @interface AgentMemberOfAnyTeamAllOf
 */
export interface AgentMemberOfAnyTeamAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof AgentMemberOfAnyTeamAllOf
     */
    'teamMember'?: boolean;
}
/**
 * 
 * @export
 * @interface AgentMemberOfTeam
 */
export interface AgentMemberOfTeam extends DisplayCondition {
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentMemberOfTeam
     */
    'teamIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AgentMemberOfTeamAllOf
 */
export interface AgentMemberOfTeamAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentMemberOfTeamAllOf
     */
    'teamIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AgentStateOneOf
 */
export interface AgentStateOneOf extends DisplayCondition {
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentStateOneOf
     */
    'stateOrProvinces': Array<AgentStateOneOfStateOrProvincesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum AgentStateOneOfStateOrProvincesEnum {
    Alabama = 'ALABAMA',
    Alaska = 'ALASKA',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    California = 'CALIFORNIA',
    Colorado = 'COLORADO',
    Connecticut = 'CONNECTICUT',
    Delaware = 'DELAWARE',
    DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
    Florida = 'FLORIDA',
    Georgia = 'GEORGIA',
    Hawaii = 'HAWAII',
    Idaho = 'IDAHO',
    Illinois = 'ILLINOIS',
    Indiana = 'INDIANA',
    Iowa = 'IOWA',
    Kansas = 'KANSAS',
    Kentucky = 'KENTUCKY',
    Louisiana = 'LOUISIANA',
    Maine = 'MAINE',
    Maryland = 'MARYLAND',
    Massachusetts = 'MASSACHUSETTS',
    Michigan = 'MICHIGAN',
    Minnesota = 'MINNESOTA',
    Mississippi = 'MISSISSIPPI',
    Missouri = 'MISSOURI',
    Montana = 'MONTANA',
    Nebraska = 'NEBRASKA',
    Nevada = 'NEVADA',
    NewHampshire = 'NEW_HAMPSHIRE',
    NewJersey = 'NEW_JERSEY',
    NewMexico = 'NEW_MEXICO',
    NewYork = 'NEW_YORK',
    NorthCarolina = 'NORTH_CAROLINA',
    NorthDakota = 'NORTH_DAKOTA',
    Ohio = 'OHIO',
    Oklahoma = 'OKLAHOMA',
    Oregon = 'OREGON',
    Pennsylvania = 'PENNSYLVANIA',
    RhodeIsland = 'RHODE_ISLAND',
    SouthCarolina = 'SOUTH_CAROLINA',
    SouthDakota = 'SOUTH_DAKOTA',
    Tennessee = 'TENNESSEE',
    Texas = 'TEXAS',
    Utah = 'UTAH',
    Vermont = 'VERMONT',
    Virginia = 'VIRGINIA',
    Washington = 'WASHINGTON',
    WestVirginia = 'WEST_VIRGINIA',
    Wisconsin = 'WISCONSIN',
    Wyoming = 'WYOMING',
    PuertoRico = 'PUERTO_RICO',
    Alberta = 'ALBERTA',
    BritishColumbia = 'BRITISH_COLUMBIA',
    Manitoba = 'MANITOBA',
    NewBrunswick = 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia = 'NOVA_SCOTIA',
    Ontario = 'ONTARIO',
    PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
    Quebec = 'QUEBEC',
    Saskatchewan = 'SASKATCHEWAN'
}

/**
 * 
 * @export
 * @interface AgentStateOneOfAllOf
 */
export interface AgentStateOneOfAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentStateOneOfAllOf
     */
    'stateOrProvinces'?: Array<AgentStateOneOfAllOfStateOrProvincesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum AgentStateOneOfAllOfStateOrProvincesEnum {
    Alabama = 'ALABAMA',
    Alaska = 'ALASKA',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    California = 'CALIFORNIA',
    Colorado = 'COLORADO',
    Connecticut = 'CONNECTICUT',
    Delaware = 'DELAWARE',
    DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
    Florida = 'FLORIDA',
    Georgia = 'GEORGIA',
    Hawaii = 'HAWAII',
    Idaho = 'IDAHO',
    Illinois = 'ILLINOIS',
    Indiana = 'INDIANA',
    Iowa = 'IOWA',
    Kansas = 'KANSAS',
    Kentucky = 'KENTUCKY',
    Louisiana = 'LOUISIANA',
    Maine = 'MAINE',
    Maryland = 'MARYLAND',
    Massachusetts = 'MASSACHUSETTS',
    Michigan = 'MICHIGAN',
    Minnesota = 'MINNESOTA',
    Mississippi = 'MISSISSIPPI',
    Missouri = 'MISSOURI',
    Montana = 'MONTANA',
    Nebraska = 'NEBRASKA',
    Nevada = 'NEVADA',
    NewHampshire = 'NEW_HAMPSHIRE',
    NewJersey = 'NEW_JERSEY',
    NewMexico = 'NEW_MEXICO',
    NewYork = 'NEW_YORK',
    NorthCarolina = 'NORTH_CAROLINA',
    NorthDakota = 'NORTH_DAKOTA',
    Ohio = 'OHIO',
    Oklahoma = 'OKLAHOMA',
    Oregon = 'OREGON',
    Pennsylvania = 'PENNSYLVANIA',
    RhodeIsland = 'RHODE_ISLAND',
    SouthCarolina = 'SOUTH_CAROLINA',
    SouthDakota = 'SOUTH_DAKOTA',
    Tennessee = 'TENNESSEE',
    Texas = 'TEXAS',
    Utah = 'UTAH',
    Vermont = 'VERMONT',
    Virginia = 'VIRGINIA',
    Washington = 'WASHINGTON',
    WestVirginia = 'WEST_VIRGINIA',
    Wisconsin = 'WISCONSIN',
    Wyoming = 'WYOMING',
    PuertoRico = 'PUERTO_RICO',
    Alberta = 'ALBERTA',
    BritishColumbia = 'BRITISH_COLUMBIA',
    Manitoba = 'MANITOBA',
    NewBrunswick = 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia = 'NOVA_SCOTIA',
    Ontario = 'ONTARIO',
    PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
    Quebec = 'QUEBEC',
    Saskatchewan = 'SASKATCHEWAN'
}

/**
 * 
 * @export
 * @interface AllConditionsMet
 */
export interface AllConditionsMet extends DisplayCondition {
    /**
     * 
     * @type {Array<DisplayCondition>}
     * @memberof AllConditionsMet
     */
    'conditions': Array<DisplayCondition>;
}
/**
 * 
 * @export
 * @interface AllConditionsMetAllOf
 */
export interface AllConditionsMetAllOf {
    /**
     * 
     * @type {Array<DisplayCondition>}
     * @memberof AllConditionsMetAllOf
     */
    'conditions'?: Array<DisplayCondition>;
}
/**
 * 
 * @export
 * @interface AnnouncementDefLiteResponse
 */
export interface AnnouncementDefLiteResponse {
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefLiteResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefLiteResponse
     */
    'driver'?: AnnouncementDefLiteResponseDriverEnum;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefLiteResponse
     */
    'status'?: AnnouncementDefLiteResponseStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementDefLiteResponse
     */
    'dismissible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefLiteResponse
     */
    'maxDismissalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefLiteResponse
     */
    'msgRepeatIntervalInHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefLiteResponse
     */
    'deliverAfter'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefLiteResponse
     */
    'expiresAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefLiteResponse
     */
    'msgTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefLiteResponse
     */
    'msgDetails'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefLiteResponse
     */
    'msgHyperlinkTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefLiteResponse
     */
    'msgHyperlink'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementDefLiteResponse
     */
    'msgHyperlinkEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefLiteResponse
     */
    'publishedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefLiteResponse
     */
    'suspendedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefLiteResponse
     */
    'archivedAt'?: number;
    /**
     * 
     * @type {AnnouncementDefRequestDisplayCondition}
     * @memberof AnnouncementDefLiteResponse
     */
    'displayCondition'?: AnnouncementDefRequestDisplayCondition;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefLiteResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefLiteResponse
     */
    'createdBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefLiteResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefLiteResponse
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefLiteResponse
     */
    'version'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AnnouncementDefLiteResponseDriverEnum {
    Time = 'TIME',
    Event = 'EVENT'
}
/**
    * @export
    * @enum {string}
    */
export enum AnnouncementDefLiteResponseStatusEnum {
    Draft = 'DRAFT',
    Active = 'ACTIVE',
    Suspended = 'SUSPENDED',
    Archived = 'ARCHIVED'
}

/**
 * 
 * @export
 * @interface AnnouncementDefRequest
 */
export interface AnnouncementDefRequest {
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefRequest
     */
    'driver': AnnouncementDefRequestDriverEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementDefRequest
     */
    'dismissible'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefRequest
     */
    'status': AnnouncementDefRequestStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefRequest
     */
    'maxDismissalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefRequest
     */
    'msgRepeatIntervalInHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefRequest
     */
    'deliverAfter'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefRequest
     */
    'expiresAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefRequest
     */
    'msgTitle': string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefRequest
     */
    'msgDetails'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefRequest
     */
    'msgHyperlinkTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefRequest
     */
    'msgHyperlink'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementDefRequest
     */
    'msgHyperlinkEnabled'?: boolean;
    /**
     * 
     * @type {AnnouncementDefRequestDisplayCondition}
     * @memberof AnnouncementDefRequest
     */
    'displayCondition': AnnouncementDefRequestDisplayCondition;
    /**
     * 
     * @type {File}
     * @memberof AnnouncementDefRequest
     */
    'video'?: File;
    /**
     * 
     * @type {File}
     * @memberof AnnouncementDefRequest
     */
    'audio'?: File;
    /**
     * 
     * @type {File}
     * @memberof AnnouncementDefRequest
     */
    'banner'?: File;
}

/**
    * @export
    * @enum {string}
    */
export enum AnnouncementDefRequestDriverEnum {
    Time = 'TIME',
    Event = 'EVENT'
}
/**
    * @export
    * @enum {string}
    */
export enum AnnouncementDefRequestStatusEnum {
    Draft = 'DRAFT',
    Active = 'ACTIVE',
    Suspended = 'SUSPENDED',
    Archived = 'ARCHIVED'
}

/**
 * @type AnnouncementDefRequestDisplayCondition
 * @export
 */
export type AnnouncementDefRequestDisplayCondition = AgentHasCapStatus | AgentHasEliteStatus | AgentIdOneOf | AgentJoinedBetween | AgentMemberOfAnyTeam | AgentMemberOfTeam | AgentStateOneOf | AllConditionsMet | AnyConditionMet | NoDisplayCondition | PlatformOneOf | UserRoleOneOf;

/**
 * 
 * @export
 * @interface AnnouncementDefResponse
 */
export interface AnnouncementDefResponse {
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'driver'?: AnnouncementDefResponseDriverEnum;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'status'?: AnnouncementDefResponseStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementDefResponse
     */
    'dismissible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefResponse
     */
    'maxDismissalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefResponse
     */
    'msgRepeatIntervalInHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefResponse
     */
    'deliverAfter'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefResponse
     */
    'expiresAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'msgTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'msgDetails'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'msgHyperlinkTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'msgHyperlink'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementDefResponse
     */
    'msgHyperlinkEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefResponse
     */
    'publishedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefResponse
     */
    'suspendedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefResponse
     */
    'archivedAt'?: number;
    /**
     * 
     * @type {AnnouncementDefRequestDisplayCondition}
     * @memberof AnnouncementDefResponse
     */
    'displayCondition'?: AnnouncementDefRequestDisplayCondition;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'createdBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDefResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'msgVideoLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'msgAudioLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'msgBannerLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'signedMsgVideoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'signedMsgAudioUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDefResponse
     */
    'signedMsgBannerUrl'?: string;
    /**
     * 
     * @type {{ [key: string]: RecipientDto; }}
     * @memberof AnnouncementDefResponse
     */
    'recipients'?: { [key: string]: RecipientDto; };
}

/**
    * @export
    * @enum {string}
    */
export enum AnnouncementDefResponseDriverEnum {
    Time = 'TIME',
    Event = 'EVENT'
}
/**
    * @export
    * @enum {string}
    */
export enum AnnouncementDefResponseStatusEnum {
    Draft = 'DRAFT',
    Active = 'ACTIVE',
    Suspended = 'SUSPENDED',
    Archived = 'ARCHIVED'
}

/**
 * 
 * @export
 * @interface AnnouncementResponse
 */
export interface AnnouncementResponse {
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResponse
     */
    'definitionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResponse
     */
    'deliveryId'?: string;
    /**
     * 
     * @type {RecipientDto}
     * @memberof AnnouncementResponse
     */
    'recipient'?: RecipientDto;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResponse
     */
    'driver'?: AnnouncementResponseDriverEnum;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResponse
     */
    'status'?: AnnouncementResponseStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementResponse
     */
    'dismissible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementResponse
     */
    'dismissCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementResponse
     */
    'maxDismissalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementResponse
     */
    'msgRepeatIntervalInHours'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResponse
     */
    'msgTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResponse
     */
    'msgDetails'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResponse
     */
    'msgHyperlink'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResponse
     */
    'msgHyperlinkTitle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementResponse
     */
    'msgHyperlinkEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResponse
     */
    'msgVideoLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResponse
     */
    'msgAudioLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResponse
     */
    'msgBannerLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResponse
     */
    'signedMsgVideoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResponse
     */
    'signedMsgAudioUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementResponse
     */
    'signedMsgBannerUrl'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AnnouncementResponseDriverEnum {
    Time = 'TIME',
    Event = 'EVENT'
}
/**
    * @export
    * @enum {string}
    */
export enum AnnouncementResponseStatusEnum {
    Draft = 'DRAFT',
    Active = 'ACTIVE',
    Suspended = 'SUSPENDED',
    Archived = 'ARCHIVED'
}

/**
 * 
 * @export
 * @interface AnyConditionMet
 */
export interface AnyConditionMet extends DisplayCondition {
    /**
     * 
     * @type {Array<DisplayCondition>}
     * @memberof AnyConditionMet
     */
    'conditions': Array<DisplayCondition>;
}
/**
 * 
 * @export
 * @interface DisplayCondition
 */
export interface DisplayCondition {
    /**
     * 
     * @type {Array<AnnouncementDefRequestDisplayCondition>}
     * @memberof DisplayCondition
     */
    'conditions'?: Array<AnnouncementDefRequestDisplayCondition>;
    /**
     * 
     * @type {string}
     * @memberof DisplayCondition
     */
    '$type': string;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseAnnouncementDefLiteResponse
 */
export interface GenericSearchResponseAnnouncementDefLiteResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAnnouncementDefLiteResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAnnouncementDefLiteResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseAnnouncementDefLiteResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAnnouncementDefLiteResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<AnnouncementDefLiteResponse>}
     * @memberof GenericSearchResponseAnnouncementDefLiteResponse
     */
    'list'?: Array<AnnouncementDefLiteResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseAnnouncementResponse
 */
export interface GenericSearchResponseAnnouncementResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAnnouncementResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAnnouncementResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseAnnouncementResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAnnouncementResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<AnnouncementResponse>}
     * @memberof GenericSearchResponseAnnouncementResponse
     */
    'list'?: Array<AnnouncementResponse>;
}
/**
 * 
 * @export
 * @interface MediaInfo
 */
export interface MediaInfo {
    /**
     * 
     * @type {string}
     * @memberof MediaInfo
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaInfo
     */
    'type'?: MediaInfoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MediaInfo
     */
    'location'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaInfo
     */
    'contentLength'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MediaInfoTypeEnum {
    Video = 'VIDEO',
    Audio = 'AUDIO',
    Banner = 'BANNER'
}

/**
 * 
 * @export
 * @interface MediaInfoResponse
 */
export interface MediaInfoResponse {
    /**
     * 
     * @type {Array<MediaInfo>}
     * @memberof MediaInfoResponse
     */
    'mediaInfos'?: Array<MediaInfo>;
}
/**
 * 
 * @export
 * @interface NoDisplayCondition
 */
export interface NoDisplayCondition extends DisplayCondition {
}
/**
 * 
 * @export
 * @interface PlatformOneOf
 */
export interface PlatformOneOf extends DisplayCondition {
    /**
     * 
     * @type {Array<string>}
     * @memberof PlatformOneOf
     */
    'platforms': Array<PlatformOneOfPlatformsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum PlatformOneOfPlatformsEnum {
    Bolt = 'BOLT',
    App = 'APP'
}

/**
 * 
 * @export
 * @interface PlatformOneOfAllOf
 */
export interface PlatformOneOfAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof PlatformOneOfAllOf
     */
    'platforms'?: Array<PlatformOneOfAllOfPlatformsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum PlatformOneOfAllOfPlatformsEnum {
    Bolt = 'BOLT',
    App = 'APP'
}

/**
 * 
 * @export
 * @interface RecipientDto
 */
export interface RecipientDto {
    /**
     * 
     * @type {string}
     * @memberof RecipientDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientDto
     */
    'middleName'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAnnouncementRequest
 */
export interface UpdateAnnouncementRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementRequest
     */
    'definitionId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementRequest
     */
    'action': UpdateAnnouncementRequestActionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateAnnouncementRequestActionEnum {
    Dismiss = 'DISMISS',
    Acknowledge = 'ACKNOWLEDGE'
}

/**
 * 
 * @export
 * @interface UserRoleOneOf
 */
export interface UserRoleOneOf extends DisplayCondition {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRoleOneOf
     */
    'roles': Array<UserRoleOneOfRolesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum UserRoleOneOfRolesEnum {
    Agent = 'AGENT',
    Broker = 'BROKER'
}

/**
 * 
 * @export
 * @interface UserRoleOneOfAllOf
 */
export interface UserRoleOneOfAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRoleOneOfAllOf
     */
    'roles'?: Array<UserRoleOneOfAllOfRolesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum UserRoleOneOfAllOfRolesEnum {
    Agent = 'AGENT',
    Broker = 'BROKER'
}


/**
 * AnnouncementControllerApi - axios parameter creator
 * @export
 */
export const AnnouncementControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get announcement delivery by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDelivery: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDelivery', 'id', id)
            const localVarPath = `/api/v1/announcements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the top announcement for the logged-in user
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopAnnouncementForLoggedInUser: async (xRealAppName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/announcements/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xRealAppName != null) {
                localVarHeaderParameter['X-real-app-name'] = String(xRealAppName);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get announcement deliveries
         * @param {'ACTIVE' | 'ACKNOWLEDGED' | 'DISMISSED' | 'SUSPENDED' | 'ARCHIVED'} [status] 
         * @param {Array<string>} [recipientIds] 
         * @param {Array<'CREATED_AT'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (status?: 'ACTIVE' | 'ACKNOWLEDGED' | 'DISMISSED' | 'SUSPENDED' | 'ARCHIVED', recipientIds?: Array<string>, sortBy?: Array<'CREATED_AT'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (recipientIds) {
                localVarQueryParameter['recipientIds'] = Array.from(recipientIds);
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dismiss or acknowledge the announcement for the logged-in user
         * @param {UpdateAnnouncementRequest} updateAnnouncementRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTopAnnouncementForLoggedInUser: async (updateAnnouncementRequest: UpdateAnnouncementRequest, xRealAppName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAnnouncementRequest' is not null or undefined
            assertParamExists('updateTopAnnouncementForLoggedInUser', 'updateAnnouncementRequest', updateAnnouncementRequest)
            const localVarPath = `/api/v1/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xRealAppName != null) {
                localVarHeaderParameter['X-real-app-name'] = String(xRealAppName);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnnouncementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementControllerApi - functional programming interface
 * @export
 */
export const AnnouncementControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get announcement delivery by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDelivery(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDelivery(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the top announcement for the logged-in user
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopAnnouncementForLoggedInUser(xRealAppName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopAnnouncementForLoggedInUser(xRealAppName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get announcement deliveries
         * @param {'ACTIVE' | 'ACKNOWLEDGED' | 'DISMISSED' | 'SUSPENDED' | 'ARCHIVED'} [status] 
         * @param {Array<string>} [recipientIds] 
         * @param {Array<'CREATED_AT'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(status?: 'ACTIVE' | 'ACKNOWLEDGED' | 'DISMISSED' | 'SUSPENDED' | 'ARCHIVED', recipientIds?: Array<string>, sortBy?: Array<'CREATED_AT'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseAnnouncementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(status, recipientIds, sortBy, sortDirection, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dismiss or acknowledge the announcement for the logged-in user
         * @param {UpdateAnnouncementRequest} updateAnnouncementRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTopAnnouncementForLoggedInUser(updateAnnouncementRequest: UpdateAnnouncementRequest, xRealAppName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTopAnnouncementForLoggedInUser(updateAnnouncementRequest, xRealAppName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnouncementControllerApi - factory interface
 * @export
 */
export const AnnouncementControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get announcement delivery by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDelivery(id: string, options?: any): AxiosPromise<AnnouncementResponse> {
            return localVarFp.getDelivery(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the top announcement for the logged-in user
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopAnnouncementForLoggedInUser(xRealAppName?: string, options?: any): AxiosPromise<AnnouncementResponse> {
            return localVarFp.getTopAnnouncementForLoggedInUser(xRealAppName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get announcement deliveries
         * @param {'ACTIVE' | 'ACKNOWLEDGED' | 'DISMISSED' | 'SUSPENDED' | 'ARCHIVED'} [status] 
         * @param {Array<string>} [recipientIds] 
         * @param {Array<'CREATED_AT'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(status?: 'ACTIVE' | 'ACKNOWLEDGED' | 'DISMISSED' | 'SUSPENDED' | 'ARCHIVED', recipientIds?: Array<string>, sortBy?: Array<'CREATED_AT'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<GenericSearchResponseAnnouncementResponse> {
            return localVarFp.search(status, recipientIds, sortBy, sortDirection, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dismiss or acknowledge the announcement for the logged-in user
         * @param {UpdateAnnouncementRequest} updateAnnouncementRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTopAnnouncementForLoggedInUser(updateAnnouncementRequest: UpdateAnnouncementRequest, xRealAppName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateTopAnnouncementForLoggedInUser(updateAnnouncementRequest, xRealAppName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementControllerApi - object-oriented interface
 * @export
 * @class AnnouncementControllerApi
 * @extends {BaseAPI}
 */
export class AnnouncementControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get announcement delivery by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public getDelivery(id: string, options?: AxiosRequestConfig) {
        return AnnouncementControllerApiFp(this.configuration).getDelivery(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the top announcement for the logged-in user
     * @param {string} [xRealAppName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public getTopAnnouncementForLoggedInUser(xRealAppName?: string, options?: AxiosRequestConfig) {
        return AnnouncementControllerApiFp(this.configuration).getTopAnnouncementForLoggedInUser(xRealAppName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get announcement deliveries
     * @param {'ACTIVE' | 'ACKNOWLEDGED' | 'DISMISSED' | 'SUSPENDED' | 'ARCHIVED'} [status] 
     * @param {Array<string>} [recipientIds] 
     * @param {Array<'CREATED_AT'>} [sortBy] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public search(status?: 'ACTIVE' | 'ACKNOWLEDGED' | 'DISMISSED' | 'SUSPENDED' | 'ARCHIVED', recipientIds?: Array<string>, sortBy?: Array<'CREATED_AT'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return AnnouncementControllerApiFp(this.configuration).search(status, recipientIds, sortBy, sortDirection, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dismiss or acknowledge the announcement for the logged-in user
     * @param {UpdateAnnouncementRequest} updateAnnouncementRequest 
     * @param {string} [xRealAppName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public updateTopAnnouncementForLoggedInUser(updateAnnouncementRequest: UpdateAnnouncementRequest, xRealAppName?: string, options?: AxiosRequestConfig) {
        return AnnouncementControllerApiFp(this.configuration).updateTopAnnouncementForLoggedInUser(updateAnnouncementRequest, xRealAppName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnouncementDefControllerApi - axios parameter creator
 * @export
 */
export const AnnouncementDefControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an announcement definition
         * @param {AnnouncementDefRequest} announcementDefRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDefinition: async (announcementDefRequest: AnnouncementDefRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'announcementDefRequest' is not null or undefined
            assertParamExists('createDefinition', 'announcementDefRequest', announcementDefRequest)
            const localVarPath = `/api/v1/announcement-definitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcementDefRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Announcement Audio
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAudio: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAudio', 'id', id)
            const localVarPath = `/api/v1/announcement-definitions/{id}/audio`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Announcement Banner
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBanner: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBanner', 'id', id)
            const localVarPath = `/api/v1/announcement-definitions/{id}/banner`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Announcement Video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVideo: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteVideo', 'id', id)
            const localVarPath = `/api/v1/announcement-definitions/{id}/video`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get announcement definition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefinition: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDefinition', 'id', id)
            const localVarPath = `/api/v1/announcement-definitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get announcement definition media info
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefinitionMediaInfo: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDefinitionMediaInfo', 'id', id)
            const localVarPath = `/api/v1/announcement-definitions/{id}/media-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get announcement definitions
         * @param {'DRAFT' | 'ACTIVE' | 'SUSPENDED' | 'ARCHIVED'} [status] 
         * @param {string} [msgTitle] 
         * @param {boolean} [dismissible] 
         * @param {Array<'CREATED_AT' | 'MSG_TITLE' | 'STATUS'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefinitions: async (status?: 'DRAFT' | 'ACTIVE' | 'SUSPENDED' | 'ARCHIVED', msgTitle?: string, dismissible?: boolean, sortBy?: Array<'CREATED_AT' | 'MSG_TITLE' | 'STATUS'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/announcement-definitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (msgTitle !== undefined) {
                localVarQueryParameter['msgTitle'] = msgTitle;
            }

            if (dismissible !== undefined) {
                localVarQueryParameter['dismissible'] = dismissible;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an announcement definition
         * @param {string} id 
         * @param {AnnouncementDefRequest} announcementDefRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefinition: async (id: string, announcementDefRequest: AnnouncementDefRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDefinition', 'id', id)
            // verify required parameter 'announcementDefRequest' is not null or undefined
            assertParamExists('updateDefinition', 'announcementDefRequest', announcementDefRequest)
            const localVarPath = `/api/v1/announcement-definitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcementDefRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Announcement Audio
         * @param {string} id 
         * @param {File} audio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAudio: async (id: string, audio: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadAudio', 'id', id)
            // verify required parameter 'audio' is not null or undefined
            assertParamExists('uploadAudio', 'audio', audio)
            const localVarPath = `/api/v1/announcement-definitions/{id}/audio`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (audio !== undefined) { 
                localVarFormParams.append('audio', audio as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Announcement Banner
         * @param {string} id 
         * @param {File} banner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBanner: async (id: string, banner: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadBanner', 'id', id)
            // verify required parameter 'banner' is not null or undefined
            assertParamExists('uploadBanner', 'banner', banner)
            const localVarPath = `/api/v1/announcement-definitions/{id}/banner`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (banner !== undefined) { 
                localVarFormParams.append('banner', banner as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Announcement Video
         * @param {string} id 
         * @param {File} video 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadVideo: async (id: string, video: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadVideo', 'id', id)
            // verify required parameter 'video' is not null or undefined
            assertParamExists('uploadVideo', 'video', video)
            const localVarPath = `/api/v1/announcement-definitions/{id}/video`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (video !== undefined) { 
                localVarFormParams.append('video', video as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementDefControllerApi - functional programming interface
 * @export
 */
export const AnnouncementDefControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementDefControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create an announcement definition
         * @param {AnnouncementDefRequest} announcementDefRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDefinition(announcementDefRequest: AnnouncementDefRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementDefResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDefinition(announcementDefRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Announcement Audio
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAudio(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAudio(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Announcement Banner
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBanner(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBanner(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Announcement Video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVideo(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVideo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get announcement definition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefinition(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementDefResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefinition(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get announcement definition media info
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefinitionMediaInfo(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefinitionMediaInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get announcement definitions
         * @param {'DRAFT' | 'ACTIVE' | 'SUSPENDED' | 'ARCHIVED'} [status] 
         * @param {string} [msgTitle] 
         * @param {boolean} [dismissible] 
         * @param {Array<'CREATED_AT' | 'MSG_TITLE' | 'STATUS'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefinitions(status?: 'DRAFT' | 'ACTIVE' | 'SUSPENDED' | 'ARCHIVED', msgTitle?: string, dismissible?: boolean, sortBy?: Array<'CREATED_AT' | 'MSG_TITLE' | 'STATUS'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseAnnouncementDefLiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefinitions(status, msgTitle, dismissible, sortBy, sortDirection, pageNumber, pageSize, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an announcement definition
         * @param {string} id 
         * @param {AnnouncementDefRequest} announcementDefRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDefinition(id: string, announcementDefRequest: AnnouncementDefRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementDefResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDefinition(id, announcementDefRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Announcement Audio
         * @param {string} id 
         * @param {File} audio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAudio(id: string, audio: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAudio(id, audio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Announcement Banner
         * @param {string} id 
         * @param {File} banner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadBanner(id: string, banner: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadBanner(id, banner, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Announcement Video
         * @param {string} id 
         * @param {File} video 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadVideo(id: string, video: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadVideo(id, video, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnouncementDefControllerApi - factory interface
 * @export
 */
export const AnnouncementDefControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementDefControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create an announcement definition
         * @param {AnnouncementDefRequest} announcementDefRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDefinition(announcementDefRequest: AnnouncementDefRequest, options?: any): AxiosPromise<AnnouncementDefResponse> {
            return localVarFp.createDefinition(announcementDefRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Announcement Audio
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAudio(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAudio(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Announcement Banner
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBanner(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBanner(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Announcement Video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVideo(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteVideo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get announcement definition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefinition(id: string, options?: any): AxiosPromise<AnnouncementDefResponse> {
            return localVarFp.getDefinition(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get announcement definition media info
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefinitionMediaInfo(id: string, options?: any): AxiosPromise<MediaInfoResponse> {
            return localVarFp.getDefinitionMediaInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get announcement definitions
         * @param {'DRAFT' | 'ACTIVE' | 'SUSPENDED' | 'ARCHIVED'} [status] 
         * @param {string} [msgTitle] 
         * @param {boolean} [dismissible] 
         * @param {Array<'CREATED_AT' | 'MSG_TITLE' | 'STATUS'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefinitions(status?: 'DRAFT' | 'ACTIVE' | 'SUSPENDED' | 'ARCHIVED', msgTitle?: string, dismissible?: boolean, sortBy?: Array<'CREATED_AT' | 'MSG_TITLE' | 'STATUS'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, sort?: string, options?: any): AxiosPromise<GenericSearchResponseAnnouncementDefLiteResponse> {
            return localVarFp.getDefinitions(status, msgTitle, dismissible, sortBy, sortDirection, pageNumber, pageSize, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an announcement definition
         * @param {string} id 
         * @param {AnnouncementDefRequest} announcementDefRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefinition(id: string, announcementDefRequest: AnnouncementDefRequest, options?: any): AxiosPromise<AnnouncementDefResponse> {
            return localVarFp.updateDefinition(id, announcementDefRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Announcement Audio
         * @param {string} id 
         * @param {File} audio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAudio(id: string, audio: File, options?: any): AxiosPromise<void> {
            return localVarFp.uploadAudio(id, audio, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Announcement Banner
         * @param {string} id 
         * @param {File} banner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBanner(id: string, banner: File, options?: any): AxiosPromise<void> {
            return localVarFp.uploadBanner(id, banner, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Announcement Video
         * @param {string} id 
         * @param {File} video 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadVideo(id: string, video: File, options?: any): AxiosPromise<void> {
            return localVarFp.uploadVideo(id, video, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementDefControllerApi - object-oriented interface
 * @export
 * @class AnnouncementDefControllerApi
 * @extends {BaseAPI}
 */
export class AnnouncementDefControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create an announcement definition
     * @param {AnnouncementDefRequest} announcementDefRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementDefControllerApi
     */
    public createDefinition(announcementDefRequest: AnnouncementDefRequest, options?: AxiosRequestConfig) {
        return AnnouncementDefControllerApiFp(this.configuration).createDefinition(announcementDefRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Announcement Audio
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementDefControllerApi
     */
    public deleteAudio(id: string, options?: AxiosRequestConfig) {
        return AnnouncementDefControllerApiFp(this.configuration).deleteAudio(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Announcement Banner
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementDefControllerApi
     */
    public deleteBanner(id: string, options?: AxiosRequestConfig) {
        return AnnouncementDefControllerApiFp(this.configuration).deleteBanner(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Announcement Video
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementDefControllerApi
     */
    public deleteVideo(id: string, options?: AxiosRequestConfig) {
        return AnnouncementDefControllerApiFp(this.configuration).deleteVideo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get announcement definition
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementDefControllerApi
     */
    public getDefinition(id: string, options?: AxiosRequestConfig) {
        return AnnouncementDefControllerApiFp(this.configuration).getDefinition(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get announcement definition media info
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementDefControllerApi
     */
    public getDefinitionMediaInfo(id: string, options?: AxiosRequestConfig) {
        return AnnouncementDefControllerApiFp(this.configuration).getDefinitionMediaInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get announcement definitions
     * @param {'DRAFT' | 'ACTIVE' | 'SUSPENDED' | 'ARCHIVED'} [status] 
     * @param {string} [msgTitle] 
     * @param {boolean} [dismissible] 
     * @param {Array<'CREATED_AT' | 'MSG_TITLE' | 'STATUS'>} [sortBy] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementDefControllerApi
     */
    public getDefinitions(status?: 'DRAFT' | 'ACTIVE' | 'SUSPENDED' | 'ARCHIVED', msgTitle?: string, dismissible?: boolean, sortBy?: Array<'CREATED_AT' | 'MSG_TITLE' | 'STATUS'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, sort?: string, options?: AxiosRequestConfig) {
        return AnnouncementDefControllerApiFp(this.configuration).getDefinitions(status, msgTitle, dismissible, sortBy, sortDirection, pageNumber, pageSize, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an announcement definition
     * @param {string} id 
     * @param {AnnouncementDefRequest} announcementDefRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementDefControllerApi
     */
    public updateDefinition(id: string, announcementDefRequest: AnnouncementDefRequest, options?: AxiosRequestConfig) {
        return AnnouncementDefControllerApiFp(this.configuration).updateDefinition(id, announcementDefRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Announcement Audio
     * @param {string} id 
     * @param {File} audio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementDefControllerApi
     */
    public uploadAudio(id: string, audio: File, options?: AxiosRequestConfig) {
        return AnnouncementDefControllerApiFp(this.configuration).uploadAudio(id, audio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Announcement Banner
     * @param {string} id 
     * @param {File} banner 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementDefControllerApi
     */
    public uploadBanner(id: string, banner: File, options?: AxiosRequestConfig) {
        return AnnouncementDefControllerApiFp(this.configuration).uploadBanner(id, banner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Announcement Video
     * @param {string} id 
     * @param {File} video 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementDefControllerApi
     */
    public uploadVideo(id: string, video: File, options?: AxiosRequestConfig) {
        return AnnouncementDefControllerApiFp(this.configuration).uploadVideo(id, video, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CacheControllerApi - axios parameter creator
 * @export
 */
export const CacheControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clear all caches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAllCaches: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/caches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CacheControllerApi - functional programming interface
 * @export
 */
export const CacheControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CacheControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Clear all caches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearAllCaches(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearAllCaches(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CacheControllerApi - factory interface
 * @export
 */
export const CacheControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CacheControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Clear all caches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAllCaches(options?: any): AxiosPromise<void> {
            return localVarFp.clearAllCaches(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CacheControllerApi - object-oriented interface
 * @export
 * @class CacheControllerApi
 * @extends {BaseAPI}
 */
export class CacheControllerApi extends BaseAPI {
    /**
     * 
     * @summary Clear all caches
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CacheControllerApi
     */
    public clearAllCaches(options?: AxiosRequestConfig) {
        return CacheControllerApiFp(this.configuration).clearAllCaches(options).then((request) => request(this.axios, this.basePath));
    }
}


