import {
  faAddressBook,
  faPlus,
  faUserPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isNumber } from 'lodash';
import {
  faArrowUpArrowDown,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import {
  BuyerAndSellerRequest,
  TransactionResponseTransactionTypeEnum,
} from '../../../openapi/arrakis';
import { saveBuyersAndSellersInfos } from '../../../slices/TransactionBuilderSlice';
import {
  AppDispatch,
  CreateTransactionParticipantType,
  RootState,
  YesNoType,
} from '../../../types';
import { capitalizeEnum, formatAddress } from '../../../utils/StringUtils';
import { isSaleTransaction } from '../../../utils/TransactionHelper';
import {
  EMAIL_VALIDATIONS,
  GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../utils/Validations';
import ZenRoleDirectoryForm from '../../Directory/ZenRoleDirectoryForm';
import IconButton from '../../IconButton';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenControlledCheckboxInput from '../../Zen/Input/ZenControlledCheckboxInput';
import ZenControlledEmailInput from '../../Zen/Input/ZenControlledEmailInput';
import ZenControlledGoogleAutocompleteSearchInput from '../../Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledPhoneNumberInput from '../../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledRadioInput from '../../Zen/Input/ZenControlledRadioInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenButton from '../../Zen/ZenButton';
import withCreateListingProgress from '../CreateListing/withCreateListingProgress';
import {
  DirectoryEntryResponseTypeEnum,
  DirectoryPersonResponse,
  DirectoryPersonResponseRoleEnum,
  DirectoryVendorResponse,
} from '../../../openapi/yenta';
import {
  DirectoryCommonEntityResponse,
  useDirectoryAddress,
} from '../../../query/directory/useDirectory';
import {
  FieldArrayPrefix,
  SELLER_FIELD_PREFIX,
} from '../../Zen/Transaction/CreateTransaction/ZenTransactionBuyerAndSellerStep';
import ZenContactListSidebar from '../../Directory/ZenContactListSidebar';
import {
  Match,
  ZenCreateListingFormState,
  ZenCreateListingStepName,
} from './ZenCreateListingSteps';

const ZenListingSellerStep: StepByStepComponent<
  ZenCreateListingFormState,
  ZenCreateListingStepName
> = ({
  form: { control, watch, trigger, setValue, getValues },
  onPrevious,
  onNext,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { transactionBuilderId } = useParams<Match>();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [roleSidebarOpen, setRoleSidebarOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined,
  );
  const [currentParticipantType, setCurrentParticipantType] = useState<
    CreateTransactionParticipantType | undefined
  >(undefined);
  const [currentFormContact, setCurrentFormContact] = useState<
    DirectoryCommonEntityResponse | undefined
  >();

  const { getGeocodeAddress, isLoading } = useDirectoryAddress();

  const {
    fields: sellerOrLandlordFields,
    append: appendSellerOrLandlord,
    remove: removeSellerOrLandlord,
  } = useFieldArray({
    control,
    name: 'sellerOrLandlord',
  });

  const {
    auth: { userDetail },
    transactionBuilder: { transactionBuilder },
  } = useSelector((state: RootState) => state);

  const [transactionType, sellerOrLandlord] = watch([
    'transactionType',
    'sellerOrLandlord',
  ]);
  const isTransactionTypeSale =
    transactionType === TransactionResponseTransactionTypeEnum.Sale;

  const propertyAddress = `${formatAddress(
    transactionBuilder?.address?.street!,
    transactionBuilder?.address?.street2,
    transactionBuilder?.address?.city!,
    capitalizeEnum(transactionBuilder?.address?.state! || ''),
    transactionBuilder?.address?.zip!,
  )} ${capitalizeEnum(userDetail?.accountCountry!)}`;

  const handleBuyerSellerStep = async () => {
    const isValid = await trigger();
    if (isValid) {
      const buyerSellerInfoRequest: BuyerAndSellerRequest = {
        buyers: [],
        sellers: sellerOrLandlord.map((seller) => {
          const isChecked = seller.usePropertyAddress?.[0] === YesNoType.YES;
          return {
            firstName: seller.firstName,
            lastName: seller.lastName,
            email: seller.email,
            companyName: seller.companyName,
            phoneNumber: seller.phoneNumber,
            address:
              !isChecked && seller.isSearchAddress
                ? seller.location?.formatted_address
                : seller.address,
          };
        }),
      };
      setLoading(true);
      const res = await dispatch(
        saveBuyersAndSellersInfos(
          transactionBuilderId!,
          buyerSellerInfoRequest,
        ),
      );
      setLoading(false);
      if (res) {
        onNext();
      }
    }
  };

  useEffect(() => {
    sellerOrLandlordFields.forEach((element, index) => {
      const isCheckboxChecked = element?.usePropertyAddress?.includes(
        YesNoType.YES,
      );

      if (isCheckboxChecked) {
        const isAddressSame = propertyAddress === element.address;

        if (!isAddressSame) {
          setValue(`sellerOrLandlord.${index}.usePropertyAddress`, []);
          setValue(`sellerOrLandlord.${index}.address`, '');
        }
      }
    });
  }, [propertyAddress, sellerOrLandlordFields, setValue]);

  const handleCheckboxClick = (index: number, isChecked: boolean) => {
    setValue(
      `sellerOrLandlord.${index}.address`,
      !isChecked ? propertyAddress : '',
    );
  };

  const handleSidebarToggle = (
    participantType?: CreateTransactionParticipantType,
    index?: number,
  ) => () => {
    setIsSidebarOpen((prevState) => !prevState);
    if (isNumber(index)) {
      setCurrentIndex((currentIndex) =>
        currentIndex === index ? undefined : index,
      );
      setCurrentParticipantType(participantType);
    } else {
      setCurrentIndex(undefined);
      setCurrentParticipantType(undefined);
    }
  };

  const handleSave = (
    sellerFieldArrayPrefix?: FieldArrayPrefix,
    contact?: DirectoryCommonEntityResponse,
  ) => {
    if (
      isNumber(currentIndex) &&
      sellerFieldArrayPrefix === SELLER_FIELD_PREFIX
    ) {
      if (currentParticipantType === CreateTransactionParticipantType.PERSON) {
        setValue(
          `${sellerFieldArrayPrefix}.${currentIndex}.firstName`,
          (contact as DirectoryPersonResponse)?.firstName ?? '',
        );
        setValue(
          `${sellerFieldArrayPrefix}.${currentIndex}.lastName`,
          (contact as DirectoryPersonResponse)?.lastName ?? '',
        );
      } else if (
        currentParticipantType === CreateTransactionParticipantType.COMPANY
      ) {
        setValue(
          `${sellerFieldArrayPrefix}.${currentIndex}.companyName`,
          (contact as DirectoryVendorResponse)?.name ?? '',
        );
      }
      setValue(
        `${sellerFieldArrayPrefix}.${currentIndex}.phoneNumber`,
        contact?.phoneNumber ?? '',
      );
      setValue(
        `${sellerFieldArrayPrefix}.${currentIndex}.email`,
        contact?.emailAddress ?? '',
      );
      setValue(
        `${sellerFieldArrayPrefix}.${currentIndex}.address`,
        contact?.address?.oneLine ?? '',
      );
    }
  };

  const handleRoleSidebarToggle = (
    participantType?: CreateTransactionParticipantType,
    index?: number,
  ) => async () => {
    if (isNumber(index)) {
      const values = getValues(`sellerOrLandlord.${index}`);
      const geoAddress =
        values?.location ?? (await getGeocodeAddress(values?.address));
      setCurrentParticipantType(participantType);
      setCurrentFormContact({
        ...values,
        emailAddress: values?.email,
        address: {
          oneLine: geoAddress?.formatted_address ?? undefined,
        },
        addressComponents: geoAddress?.address_components ?? [],
        placeId: geoAddress?.place_id ?? undefined,
        name: values?.companyName,
        role:
          participantType === CreateTransactionParticipantType.PERSON
            ? DirectoryPersonResponseRoleEnum.Client
            : DirectoryPersonResponseRoleEnum.Other,
      });
    } else {
      setCurrentParticipantType(undefined);
      setCurrentFormContact(undefined);
    }

    setRoleSidebarOpen((prevState) => !prevState);
  };

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <p className='text-xl font-primary-medium mb-2'>
          {isTransactionTypeSale
            ? 'Who is selling the property?'
            : 'Who is leasing the property?'}
        </p>
        <div className='space-y-4'>
          {sellerOrLandlordFields.map((field, index) => {
            const [
              participantType,
              isSearchAddress,
              usePropertyAddress,
            ] = watch([
              `sellerOrLandlord.${index}.participantType`,
              `sellerOrLandlord.${index}.isSearchAddress`,
              `sellerOrLandlord.${index}.usePropertyAddress`,
            ]);
            const isChecked = usePropertyAddress?.[0] === YesNoType.YES;

            return (
              <div key={field.id}>
                <div className='relative space-y-4 mb-8'>
                  <div className='flex justify-between'>
                    <ZenControlledRadioInput<
                      ZenCreateListingFormState,
                      `sellerOrLandlord.${number}.participantType`
                    >
                      name={`sellerOrLandlord.${index}.participantType`}
                      control={control}
                      inlineOptions
                      shouldUnregister={false}
                      options={[
                        {
                          label: 'Person',
                          value: CreateTransactionParticipantType.PERSON,
                        },
                        {
                          label: 'Company',
                          value: CreateTransactionParticipantType.COMPANY,
                        },
                      ]}
                      rules={{
                        required: 'Please select a type',
                      }}
                      isRequired
                      defaultValue={CreateTransactionParticipantType.PERSON}
                    />
                    <div className='flex flex-row items-center gap-x-2 md:whitespace-pre -mt-1.5'>
                      <div className='whitespace-nowrap'>
                        <ZenButton
                          label='Directory'
                          variant='primary-link'
                          LeftIconComponent={
                            <FontAwesomeIcon icon={faAddressBook} />
                          }
                          onClick={handleSidebarToggle(participantType, index)}
                          fontstyle
                          zeroPadding
                        />
                      </div>
                      <ZenButton
                        label={
                          isSaleTransaction(transactionType)
                            ? 'Duplicate Seller'
                            : 'Duplicate Landlord'
                        }
                        LeftIconComponent={
                          <FontAwesomeIcon icon={faUserPlus} size='sm' />
                        }
                        variant='primary-link'
                        onClick={() => {
                          appendSellerOrLandlord(sellerOrLandlord[index]);
                        }}
                        fontstyle
                      />
                      {sellerOrLandlordFields.length > 1 && (
                        <div className='cursor-pointer'>
                          <FontAwesomeIcon
                            icon={faTrash}
                            title='delete'
                            className='text-gray-400 hover:text-gray-900'
                            size='sm'
                            onClick={() => removeSellerOrLandlord(index)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {participantType ===
                  CreateTransactionParticipantType.PERSON ? (
                    <div className='flex flex-row space-x-4 mt-5'>
                      <ZenControlledTextInput<
                        ZenCreateListingFormState,
                        `sellerOrLandlord.${number}.firstName`
                      >
                        control={control}
                        label='First Name'
                        name={`sellerOrLandlord.${index}.firstName`}
                        placeholder='E.g. John'
                        shouldUnregister={false}
                        rules={{ required: 'First Name is required' }}
                        isRequired
                      />

                      <ZenControlledTextInput<
                        ZenCreateListingFormState,
                        `sellerOrLandlord.${number}.lastName`
                      >
                        control={control}
                        label='Last Name'
                        name={`sellerOrLandlord.${index}.lastName`}
                        shouldUnregister={false}
                        placeholder='E.g. Doe'
                        rules={{ required: 'Last Name is required' }}
                        isRequired
                      />
                    </div>
                  ) : (
                    <ZenControlledTextInput<
                      ZenCreateListingFormState,
                      `sellerOrLandlord.${number}.companyName`
                    >
                      control={control}
                      label='Company Name'
                      name={`sellerOrLandlord.${index}.companyName`}
                      placeholder='E.g. Company Name LLC.'
                      shouldUnregister={false}
                      rules={{ required: 'Company Name is required' }}
                      isRequired
                    />
                  )}
                  <div className='flex flex-row space-x-4'>
                    <ZenControlledEmailInput<
                      ZenCreateListingFormState,
                      `sellerOrLandlord.${number}.email`
                    >
                      control={control}
                      label='Email Address'
                      name={`sellerOrLandlord.${index}.email`}
                      shouldUnregister={false}
                      placeholder='Eg. johndoe@business.com'
                      rules={{
                        required: 'Email is required',
                        ...EMAIL_VALIDATIONS,
                      }}
                      isRequired
                    />

                    <ZenControlledPhoneNumberInput<
                      ZenCreateListingFormState,
                      `sellerOrLandlord.${number}.phoneNumber`
                    >
                      control={control}
                      label='Phone Number'
                      name={`sellerOrLandlord.${index}.phoneNumber`}
                      shouldUnregister={false}
                      placeholder='E.g. +1 (123) 456 7890'
                      rules={{
                        required: 'Please provide a phone number',
                        ...PHONE_NUMBER_VALIDATIONS,
                      }}
                      isRequired
                    />
                  </div>
                </div>
                <div className='relative'>
                  {!isChecked && isSearchAddress ? (
                    <div className='flex flex-row mt-5'>
                      <ZenControlledGoogleAutocompleteSearchInput<
                        ZenCreateListingFormState,
                        `sellerOrLandlord.${number}.location`
                      >
                        control={control}
                        shouldUnregister={false}
                        name={`sellerOrLandlord.${index}.location`}
                        label='Search Address'
                        subLabel='(Source by Google)'
                        placeholder='E.g. 1st St. New York, NY 10010'
                        rules={{
                          ...GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
                        }}
                      />
                    </div>
                  ) : (
                    <div className='flex flex-row mt-5'>
                      <ZenControlledTextInput<
                        ZenCreateListingFormState,
                        `sellerOrLandlord.${number}.address`
                      >
                        control={control}
                        label='Address'
                        name={`sellerOrLandlord.${index}.address`}
                        placeholder='11011 Street, Block 11..'
                        shouldUnregister={false}
                        readOnly={isChecked}
                      />
                    </div>
                  )}
                  {!isChecked && (
                    <div className='absolute -top-3 right-0'>
                      <IconButton
                        label={
                          isSearchAddress
                            ? 'Enter address manually'
                            : 'Search Address'
                        }
                        variant='none'
                        buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
                        leftIcon={
                          <FontAwesomeIcon
                            icon={faArrowUpArrowDown}
                            className='text-base'
                          />
                        }
                        onClick={() => {
                          setValue(
                            `sellerOrLandlord.${index}.isSearchAddress`,
                            !isSearchAddress,
                          );
                        }}
                      />
                    </div>
                  )}
                  <div className='mt-1 mb-4 flex flex-row items-center space-x-2'>
                    <div
                      className='mr-1'
                      onClick={() => {
                        handleCheckboxClick(index, isChecked);
                      }}
                      data-testid='use-property-address'
                    >
                      <ZenControlledCheckboxInput<
                        ZenCreateListingFormState,
                        `sellerOrLandlord.${number}.usePropertyAddress`
                      >
                        label=''
                        name={`sellerOrLandlord.${index}.usePropertyAddress`}
                        shouldUnregister={false}
                        options={[
                          {
                            label: 'Same address as property address',
                            value: YesNoType.YES,
                          },
                        ]}
                        control={control}
                        reverse
                      />
                    </div>
                    <div className='flex items-center'>
                      <hr className='h-7 border-l border-solid border-zen-dark-6' />
                      <div className='-ml-2'>
                        <ZenButton
                          label='Add to My Directory'
                          variant='primary-link'
                          isSubmitting={isLoading}
                          isDisabled={isLoading}
                          LeftIconComponent={
                            <FontAwesomeIcon
                              icon={faPlus}
                              className='text-primary-blue cursor-pointer'
                            />
                          }
                          onClick={handleRoleSidebarToggle(
                            participantType,
                            index,
                          )}
                          fontstyle
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className='pb-12'>
          <ZenButton
            label={
              isSaleTransaction(transactionType) ? 'Add Seller' : 'Add Landlord'
            }
            LeftIconComponent={<FontAwesomeIcon icon={faUserPlus} size='sm' />}
            variant='primary-link'
            onClick={() => {
              appendSellerOrLandlord({
                companyName: '',
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
              });
            }}
            zeroPadding
          />
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-gray-outline'
              type='button'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              type='submit'
              variant='primary'
              isDisabled={loading}
              label='Next'
              onClick={handleBuyerSellerStep}
            />
          </div>
        </div>
      </div>
      {isSidebarOpen && (
        <ZenContactListSidebar
          participantType={currentParticipantType}
          currentFieldArrayPrefix={SELLER_FIELD_PREFIX}
          isOpen={isSidebarOpen}
          onClose={handleSidebarToggle()}
          onSave={handleSave}
        />
      )}
      {roleSidebarOpen && (
        <ZenRoleDirectoryForm
          isOpen={roleSidebarOpen}
          existingVendorOrPerson={currentFormContact}
          mode='populate'
          existingType={
            currentParticipantType === CreateTransactionParticipantType.PERSON
              ? DirectoryEntryResponseTypeEnum.Person
              : DirectoryEntryResponseTypeEnum.Vendor
          }
          onClose={handleRoleSidebarToggle()}
        />
      )}
    </div>
  );
};

export default withCreateListingProgress(ZenListingSellerStep);
