import { useHistory, useLocation } from 'react-router-dom';
import {
  Control,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeftLong,
  faBoxArchive,
} from '@fortawesome/pro-regular-svg-icons';
import ZenButton from '../../ZenButton';
import ZenSearchBar from '../../ZenSearchBar';
import ZenControlledDraggableHorizontalInput from '../../Input/ZenControlledDraggableHorizontalInput';
import { MAX_DOC_SIZE_100MB } from '../../../../constants/FilesConstants';

interface ZenFileCabinetHeaderProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  isTrashFolder: boolean;
  search: string;
  setSearch: (value: string) => void;
  showActionButton: boolean;
  isUploading: boolean;
  control: Control<TFieldValues>;
}

const ZenFileCabinetHeader = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  isTrashFolder,
  search,
  setSearch,
  showActionButton,
  isUploading,
  control,
  name,
}: ZenFileCabinetHeaderProps<TFieldValues, TName>) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <div className='mb-5 flex flex-col-reverse md:flex-row justify-between items-center'>
      <div className='w-full md:w-[25%]'>
        <ZenSearchBar value={search} onChange={setSearch} />
      </div>

      {!isTrashFolder && showActionButton && (
        <div className='w-full md:w-3/4 flex flex-col md:flex-row items-center justify-end gap-5 mb-5 md:mb-0'>
          <div className=''>
            <ZenButton
              label='Archived'
              variant='primary-outline'
              LeftIconComponent={<FontAwesomeIcon icon={faBoxArchive} />}
              onClick={() => {
                history.push(`${location.pathname}?isArchive=true`);
              }}
            />
          </div>
          <div className='w-full md:w-2/4'>
            <ZenControlledDraggableHorizontalInput
              name={name}
              control={control}
              accept='*/*'
              isUploading={isUploading}
              maxUploadSize={MAX_DOC_SIZE_100MB}
            />
          </div>
        </div>
      )}
      {isTrashFolder && (
        <div className='w-full flex flex-col md:flex-row items-center justify-end mb-5 md:mb-0'>
          <ZenButton
            label='Go Back'
            variant='dark-outline'
            LeftIconComponent={<FontAwesomeIcon icon={faArrowLeftLong} />}
            onClick={() => {
              history.push(`${location.pathname}`);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ZenFileCabinetHeader;
