import React from 'react';
import CardWithItems from '../CardWithItems';
import { WireInstructions } from '../../openapi/arrakis';

interface TitleProgressWireInstructionsProps {
  wireInstructions?: WireInstructions;
}

const TitleProgressWireInstructions: React.FC<TitleProgressWireInstructionsProps> = ({
  wireInstructions,
}) => {
  return (
    <div className='w-full max-w-lg'>
      <p className='font-primary-medium text-xl text-dark mb-3'>
        Wire Instructions
      </p>
      <CardWithItems
        items={[
          { name: 'Bank Name:', value: wireInstructions?.bankName || 'N/A' },
          {
            name: 'Account Name:',
            value: wireInstructions?.accountName || 'N/A',
          },
          {
            name: 'Bank Address:',
            value: wireInstructions?.bankAddress?.oneLine || 'N/A',
          },
          {
            name: 'Account Information:',
            value: (
              <div>
                <p>
                  Routing Number: {wireInstructions?.routingNumber || 'N/A'}
                </p>
                <p>
                  Account Number: {wireInstructions?.accountNumber || 'N/A'}
                </p>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default TitleProgressWireInstructions;
