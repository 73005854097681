import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';

interface SelfOrAdminProps {
  agentId: string | undefined;
}

const SelfOrAdminOnly: React.FC<SelfOrAdminProps> = ({ agentId, children }) => {
  const { userDetail, isAdmin } = useSelector((state: RootState) => state.auth);

  let isSelf = false;
  if (userDetail && agentId) {
    isSelf = userDetail.id === agentId;
  }

  if (!(isAdmin || isSelf)) {
    return null;
  }

  // This will be released in a new version of eslint. Disabling for now.
  // https://github.com/yannickcr/eslint-plugin-react/commit/9f1d618499b23e184a628365cb5e0ea48292cd6f
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Fragment>{children}</Fragment>;
};

export default SelfOrAdminOnly;
