import { DateTime } from 'luxon';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  UserAvailabilityResponse,
  UserControllerApi,
} from '../../../openapi/yenta';
import { queryKeys } from '../../../query/base/queryKeys';
import { useBaseMutation } from '../../../query/base/useBaseMutation';
import { saveLoggedInAgentDetails } from '../../../slices/AgentSlice';
import { AppDispatch, RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenCustomSwitch from '../../Zen/Input/ZenCustomSwitch';

type DoNotDisturbProps = { agentId: string };

const DoNotDisturb: React.FC<DoNotDisturbProps> = ({ agentId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const agentDetail = useSelector(
    (state: RootState) => state.agentDetail.detailResponse.data,
  );

  const userAvailability = (agentDetail?.availability ||
    {}) as UserAvailabilityResponse;
  const timeZone =
    agentDetail?.availability?.timeZone || DateTime.local().zoneName;

  const toggle = !!userAvailability.doNotDisturb;

  const { mutate, isLoading } = useBaseMutation({
    queryKey: queryKeys.roar.officeHours(agentId).queryKey,
    mutationFn: async (doNotDisturb: boolean) => {
      const { data } = await new UserControllerApi(
        getYentaConfiguration(),
      ).patchUser(agentId, {
        userAvailability: {
          ...userAvailability,
          doNotDisturb,
          timeZone,
        },
      });
      dispatch(
        saveLoggedInAgentDetails({
          ...agentDetail,
          availability: data.availability,
        }),
      );
      return data;
    },
    successMessage: 'Settings Saved',
    errorMessage: 'Failed to save settings',
  });

  return (
    <div
      className={cn(
        'p-4 border border-grey-300 rounded-lg flex items-center justify-between space-x-3 transition duration-300',
        toggle && 'bg-yellow-100 border-yellow-100',
      )}
    >
      <div>
        <p className='font-inter font-medium text-primary-dark'>
          Do Not Disturb
        </p>
        <p className='mt-1 font-inter text-grey-600'>
          You will not receive roars when this is toggled on. You can toggle off
          to resume roaring.
        </p>
      </div>
      <ZenCustomSwitch
        toggle={toggle}
        disabled={isLoading}
        onClick={() => mutate(!toggle)}
        testId='do-not-disturb'
      />
    </div>
  );
};

export default DoNotDisturb;
