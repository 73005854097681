import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import GeminiButton from '../../components/Gemini/GeminiButton';
import GeminiUserPill from '../../components/Gemini/GeminiUserPill';
import { ReferralCentralReferralDto } from '../../openapi/arrakis';
import ReferralAddressTimelineStatus from './components/ReferralAddressTimelineStatus';
import ReferralInfo from './components/ReferralInfo';
import ReferralDetailDiscoverSidebar from './sidebars/ReferralDetailDiscoverSidebar';

interface DiscoverReferralCardProps {
  referralDetails?: ReferralCentralReferralDto;
}

const DiscoverReferralCard: React.FC<DiscoverReferralCardProps> = ({
  referralDetails,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className='border border-grey-300 rounded-xl font-inter h-[16rem]'>
      <div className='flex flex-row items-center justify-between px-3 py-3.5'>
        <p className='text-grey-500 text-sm -mt-px'>
          Created :{' '}
          {DateTime.fromMillis(referralDetails?.createdAt! || 0).toLocaleString(
            DateTime.DATE_MED,
          )}
        </p>
      </div>
      <div className='flex flex-col justify-between border border-grey-300 border-b-0 shadow-top-lg rounded-xl -mx-px -mt-px h-[81.8%]'>
        <div className='pl-3 pr-1 py-2'>
          <div className='flex flex-row items-center justify-between'>
            <ReferralInfo
              clientRole={referralDetails?.clientType!}
              maximumPrice={referralDetails?.priceRange?.maximum?.amount!}
              minimumPrice={referralDetails?.priceRange?.minimum?.amount!}
              referralFee={referralDetails?.referralFeePercentage!}
            />
          </div>
          <ReferralAddressTimelineStatus
            address={referralDetails?.locations!}
            timeline={referralDetails?.timeline!}
          />
        </div>
        <div>
          <div className='flex flex-row items-center space-x-3 pb-3 pt-1 px-3'>
            <GeminiButton label='Apply' isFullWidth variant='primary-outline' />
            <GeminiButton
              leftIcon={faEye}
              variant='primary-outline'
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </div>
          <div>
            <div className='border-t border-dashed mx-3' />
            <div className='flex flex-row items-center justify-between py-3 mx-3'>
              <p className='text-sm text-grey-600'>Referral Agent</p>
              <div>
                <GeminiUserPill
                  name='Taylor Swift'
                  imageUrl=''
                  fontWeight='font-normal'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReferralDetailDiscoverSidebar
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        referralDetails={referralDetails!}
      />
    </div>
  );
};

export default DiscoverReferralCard;
