import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPhoneNumber } from '../../../utils/StringUtils';
import Hover from '../../Hover';
import ZenAvatar from '../../Zen/ZenAvatar';

export interface AddPersonalAccountRowProps {
  name: string;
  imageUrl: string;
  email: string;
  phone: string;
}

const AddPersonalAccountRow: React.FC<AddPersonalAccountRowProps> = ({
  name,
  imageUrl,
  email,
  phone,
}) => {
  return (
    <div className='py-3 flex flex-row justify-between items-start space-x-2'>
      <div className='flex flex-row items-center'>
        <ZenAvatar name={name} imageUrl={imageUrl} size='md' />
        <div className='flex flex-col pl-2'>
          <div className='flex flex-row items-start space-x-2'>
            <p className='font-zen-body text-zen-dark-9 text-base font-medium break-all'>
              {name}
            </p>
            <Hover
              hoverComponent={
                <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                  {email || 'N/A'}
                </div>
              }
              config={{ trigger: 'hover', placement: 'top' }}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                title='email'
                className='mt-1 cursor-pointer text-primary-blue'
              />
            </Hover>
            <Hover
              hoverComponent={
                <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                  {formatPhoneNumber(phone) || 'N/A'}
                </div>
              }
              config={{ trigger: 'hover', placement: 'top' }}
            >
              <FontAwesomeIcon
                icon={faPhone}
                title='phone'
                className='mt-1 cursor-pointer text-base text-primary-blue'
              />
            </Hover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPersonalAccountRow;
