import { CapStatusValue } from '../openapi/arrakis';

export const calculateCapPercentage = (
  capStatus: CapStatusValue | undefined,
): number => {
  if (!capStatus) {
    return 0;
  }

  const capPercentage = Math.floor(
    (capStatus.capAmountPaid!.amount! / capStatus.capAmount!.amount!) * 100,
  );

  if (capPercentage > 100) {
    return 100;
  }

  return capPercentage;
};

export const canBeElite = (cap: CapStatusValue): boolean => {
  return cap.capAmount?.amount === 12000;
};
