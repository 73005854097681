import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useBrokerQueue from '../../hooks/useBrokerQueue';
import { RootState } from '../../types';

const useKbarRedirect = () => {
  const history = useHistory();
  const {
    transaction: {
      transactionDetailResponse: { data },
    },
  } = useSelector((state: RootState) => state);

  const { isBrokerQueueActive } = useBrokerQueue();

  const redirectTo = (path: string) => {
    if (isBrokerQueueActive && !path.startsWith(`/transactions/${data?.id}`)) {
      window.location.href = path;
      return;
    }
    history.push(path);
  };

  return { redirectTo };
};

export default useKbarRedirect;
