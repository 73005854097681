import { last, omit } from 'lodash';
import {
  AddressRequest,
  AgentResponse,
  AgentUpdateRequest,
} from '../openapi/yenta';

type Options = {
  omitAddressRequest?: boolean;
  includeAddressRequests?: boolean;
};

export const getUpdateAgentDetailObject = (
  agent: AgentResponse,
  values: any,
  options: Options = {},
): AgentUpdateRequest => {
  const address = last(agent?.addresses);

  return {
    firstName: updatedValue('firstName', values, agent),
    middleName: updatedValue('middleName', values, agent),
    lastName: updatedValue('lastName', values, agent),
    displayName: updatedValue('displayName', values, agent),
    phoneNumber: updatedValue('phoneNumber', values, agent),
    about: updatedValue('about', values, agent),
    personalEmailAddress: updatedValue('personalEmailAddress', values, agent),
    addressRequest: !options.omitAddressRequest
      ? {
          type: updatedValue('type', values, address),
          streetAddress1: updatedValue('streetAddress1', values, address),
          streetAddress2: updatedValue('streetAddress2', values, address),
          city: updatedValue('city', values, address),
          country: updatedValue('country', values, address),
          stateOrProvince: updatedValue('stateOrProvince', values, address),
          zipOrPostalCode: updatedValue('zipOrPostalCode', values, address),
        }
      : undefined,
    addressRequests: values.addressRequests
      ? values.addressRequests
      : options.includeAddressRequests
      ? agent.addresses?.map(
          (addr) => (omit(addr, ['oneLine']) as unknown) as AddressRequest,
        )
      : undefined,
    birthDate: updatedValue('birthDate', values, agent),
    clubhouseURL: updatedValue('clubhouseURL', values, agent),
    competencies: updatedValue('competencies', values, agent),
    facebookURL: updatedValue('facebookURL', values, agent),
    googleBusinessProfileURL: updatedValue(
      'googleBusinessProfileURL',
      values,
      agent,
    ),
    instagramURL: updatedValue('instagramURL', values, agent),
    languages: updatedValue('languages', values, agent),
    linkedInURL: updatedValue('linkedInURL', values, agent),
    notifyLeadsInSMS: updatedValue('notifyLeadsInSMS', values, agent),
    personalWebsiteURL: updatedValue('personalWebsiteURL', values, agent),
    personalPhoneNumber: updatedValue('personalPhoneNumber', values, agent),
    receivesLeadsOnlyFromZipcodes: updatedValue(
      'receivesLeadsOnlyFromZipcodes',
      values,
      agent,
    ),
    receivesPaymentEmailNotifications: updatedValue(
      'receivesPaymentEmailNotifications',
      values,
      agent,
    ),
    receivesPaymentPushNotifications: updatedValue(
      'receivesPaymentPushNotifications',
      values,
      agent,
    ),
    receivesTransactionEmailNotifications: updatedValue(
      'receivesTransactionEmailNotifications',
      values,
      agent,
    ),
    receivesTransactionPushNotifications: updatedValue(
      'receivesTransactionPushNotifications',
      values,
      agent,
    ),
    serviceAreaZipcodes: updatedValue('serviceAreaZipcodes', values, agent),
    title: updatedValue('title', values, agent),
    twitterURL: updatedValue('twitterURL', values, agent),
    yelpURL: updatedValue('yelpURL', values, agent),
    youtubeURL: updatedValue('youtubeURL', values, agent),
    zillowURL: updatedValue('zillowURL', values, agent),
    slug: updatedValue('slug', values, agent),
  };
};

const updatedValue = (key: string, values: any, original: any) => {
  return values.hasOwnProperty(key) ? values[key] : original && original[key];
};
