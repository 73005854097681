import React from 'react';
import { ReactComponent as CrownIcon } from '../../../assets/icons/zen/zen-teamscrownoutlined.svg';
import { ReactComponent as HomeIcon } from '../../../assets/icons/zen/zen-teamshome.svg';
import { ReactComponent as TeamIcon } from '../../../assets/icons/zen/zen-teamsicon.svg';
import { ReactComponent as SpeakerPhoneIcon } from '../../../assets/icons/zen/zen-teamsspeakerphone.svg';
import { ReactComponent as StarChirtmas } from '../../../assets/icons/zen/zen-star-christmas.svg';
import {
  FlexTeamConfigDtoTeamTypeEnum,
  TeamResponseTypeEnum,
} from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { teamTypeToDisplayName } from '../../../utils/TeamHelper';

export interface GeminiTeamsTypeProps {
  type: TeamResponseTypeEnum | FlexTeamConfigDtoTeamTypeEnum;
}

const GeminiTeamsType: React.FC<GeminiTeamsTypeProps> = ({ type }) => {
  const containerClassNameVariantEnumMap: EnumMap<
    TeamResponseTypeEnum | FlexTeamConfigDtoTeamTypeEnum,
    string
  > = {
    [TeamResponseTypeEnum.Group]: 'bg-purple-300 text-purple-600',
    [TeamResponseTypeEnum.Domestic]:
      'bg-midnight-green-300 text-midnight-green-900',
    [TeamResponseTypeEnum.Normal]: 'bg-rezen-light-blue-100 text-primary-navy',
    [TeamResponseTypeEnum.Platinum]: 'bg-yellow-100 text-yellow-900',
    [TeamResponseTypeEnum.Pro]: 'bg-sangria-300 text-sangria-900',
  };

  const iconVariantMap: EnumMap<
    TeamResponseTypeEnum | FlexTeamConfigDtoTeamTypeEnum,
    React.ReactElement
  > = {
    [TeamResponseTypeEnum.Group]: <SpeakerPhoneIcon />,
    [TeamResponseTypeEnum.Domestic]: <HomeIcon />,
    [TeamResponseTypeEnum.Normal]: <TeamIcon />,
    [TeamResponseTypeEnum.Platinum]: <CrownIcon />,
    [TeamResponseTypeEnum.Pro]: <StarChirtmas />,
  };

  const containerStyles = safeEnumMapGet(
    containerClassNameVariantEnumMap,
    type,
    'bg-zen-primary-light text-zen-primary',
  );

  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center rounded-md p-2 h-20 w-20',
        containerStyles,
      )}
    >
      <div className='h-9 w-9 flex items-center justify-center'>
        {safeEnumMapGet(iconVariantMap, type, <TeamIcon />)}
      </div>
      <span className='font-zen-body-2 text-sm leading-4 font-medium mt-2'>
        {teamTypeToDisplayName(type)}
      </span>
    </div>
  );
};

export default GeminiTeamsType;
