import { debounce } from 'lodash';
import { useRef, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveCardInFocus } from '../../slices/KBarSlice';
import { RootState } from '../../types';

const useKBarCardFocus = (focusId?: string) => {
  const dispatch = useDispatch();
  const { cardInFocus } = useSelector((state: RootState) => state.kBar);
  const cardRef = useRef<HTMLDivElement>(null);
  const isCardInFocus = useMemo(() => !!focusId && cardInFocus === focusId, [
    cardInFocus,
    focusId,
  ]);

  const debouncedResetCardInFocus = debounce(() => {
    dispatch(saveCardInFocus(undefined));
  }, 5000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedResetCardInFocusCallback = useCallback(
    debouncedResetCardInFocus,
    [],
  );

  useEffect(() => {
    // when cardInfocus changes, cancel previous debounce and start a new one
    if (cardInFocus) {
      debouncedResetCardInFocus.cancel();
      debouncedResetCardInFocusCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardInFocus]);

  useEffect(() => {
    if (isCardInFocus) {
      cardRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      cardRef.current?.classList.add('kbar-card-focus');
    } else {
      cardRef.current?.classList.remove('kbar-card-focus');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCardInFocus]);

  return { cardRef, isCardInFocus };
};

export default useKBarCardFocus;
