import {
  faLongArrowLeft,
  faLongArrowRight,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { cn } from '../../../../../utils/classUtils';

export interface ShareablesCarouselProps {
  items: JSX.Element[];
  activeShareableIndex: number;
}

export const ShareablesCarousel: React.FC<ShareablesCarouselProps> = ({
  items,
  activeShareableIndex,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(activeShareableIndex);

  return (
    <div className='px-4 w-full flex items-center justify-between overflow-hidden'>
      <div className='pr-10'>
        <div className='rounded-full flex items-center justify-center overflow-hidden'>
          <button
            className={cn(
              'appearance-none flex items-center justify-center p-3.5',
              activeIndex === 0 ? 'bg-gray-500' : 'bg-white',
              activeIndex > 0 && 'cursor-pointer',
            )}
            onClick={() => setActiveIndex((prev) => prev - 1)}
            type='button'
            disabled={activeIndex === 0}
          >
            <FontAwesomeIcon
              icon={faLongArrowLeft}
              className='text-primary-dark'
            />
          </button>
        </div>
      </div>
      <div className='flex-grow'>{items[activeIndex]}</div>
      <div className='pl-10'>
        <div className='rounded-full flex items-center justify-center overflow-hidden'>
          <button
            className={cn(
              'appearance-none flex items-center justify-center p-3.5',
              activeIndex === items.length - 1 ? 'bg-gray-500' : 'bg-white',
              activeIndex < items.length - 1 && 'cursor-pointer',
            )}
            onClick={() => setActiveIndex((prev) => prev + 1)}
            disabled={activeIndex === items.length - 1}
            type='button'
          >
            <FontAwesomeIcon
              icon={faLongArrowRight}
              className='text-primary-dark'
            />
          </button>
        </div>
      </div>
    </div>
  );
};
