import {
  faEnvelope,
  faGlobe,
  faLocationDot,
  faPhone,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  CONTACT_ADDRESS_1,
  CONTACT_ADDRESS_2,
  CONTACT_EMAIL,
  CONTACT_PHONE_NUMBER,
  ONE_REAL_MORTGAGE_URL,
} from '../../../constants/RealMortgageConstants';

const styles = 'flex items-center gap-3 font-light';

interface OneRealMortgageContactUsProps {
  contactRef: React.RefObject<HTMLDivElement>;
}

const OneRealMortgageContactUs: React.FC<OneRealMortgageContactUsProps> = ({
  contactRef,
}) => {
  return (
    <div
      id='contact-us'
      ref={contactRef}
      className='bg-primary-navy rounded-2xl p-6 md:p-10 md:pr-14 w-full md:w-[435px] text-white'
    >
      <h1 className='text-2xl mb-6 font-semibold font-poppins'>Contact us</h1>
      <div className='flex flex-col gap-5'>
        <div className={styles}>
          <FontAwesomeIcon icon={faPhone} size='lg' />
          <p className='text-sm'>{CONTACT_PHONE_NUMBER}</p>
        </div>
        <div className={styles}>
          <FontAwesomeIcon icon={faEnvelope} size='lg' />
          <p className='text-sm'>{CONTACT_EMAIL}</p>
        </div>
        <div className={styles}>
          <FontAwesomeIcon icon={faLocationDot} size='lg' />
          <p className='text-sm'>{CONTACT_ADDRESS_1}</p>
        </div>
        <div className={styles}>
          <FontAwesomeIcon icon={faLocationDot} size='lg' />
          <p className='text-sm'>{CONTACT_ADDRESS_2}</p>
        </div>
        <div className={`${styles} text-aqua`}>
          <FontAwesomeIcon icon={faGlobe} size='lg' />
          <a
            href={ONE_REAL_MORTGAGE_URL}
            target='_blank'
            rel='noopener noreferrer'
            className='text-sm'
          >
            onerealmortgage.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default OneRealMortgageContactUs;
