import { usePopperTooltip } from 'react-popper-tooltip';

interface IconWithTooltipProps {
  icon: React.ReactElement;
  toolTipText: string;
}

const IconWithTooltip: React.FC<IconWithTooltipProps> = ({
  icon,
  toolTipText,
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  return (
    <div>
      <div ref={setTriggerRef}>{icon}</div>
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip' })}>
          <div {...getArrowProps({ className: 't-arrow' })} />
          <span className='text-xs'>{toolTipText}</span>
        </div>
      )}
    </div>
  );
};

export default IconWithTooltip;
