import { AxiosRequestConfig } from 'axios';
import { values } from 'lodash';
import { IN_PROGRESS_APPLICATION_STATUS } from '../components/table/Filters/ApplicationResponseStatusEnumSelectColumnFilter';
import {
  ApplicationControllerApi,
  ApplicationResponse,
  ApproveApplicationRequestAgentPlansConfigEnum,
} from '../openapi/yenta';
import { EnumMap, IPaginateReq, SearchParam, YesNoType } from '../types';
import { safeEnumMapGet } from './EnumHelper';
import { getYentaConfiguration } from './OpenapiConfigurationUtils';
import { getStartAndEndDate } from './TableUtils';

export const getPlanEnabledStatusMap = () => {
  const map: Record<ApproveApplicationRequestAgentPlansConfigEnum, boolean> = {
    US_RESIDENTIAL_V2: true,
    CA_RESIDENTIAL_SCOTT_BENSON_V1: true,
    CA_RESIDENTIAL_V1: true,
    US_COMMERCIAL_V1: false,
    US_RESIDENTIAL_V1: true,
    US_BROKER_4K: true,
    US_BROKER_6K: true,
    US_BROKER_12K: true,
    CA_BROKER_4K: true,
    CA_BROKER_6K: true,
    CA_BROKER_12K: true,
  };

  return map;
};

export const isPlanEnabled = (
  plan: ApproveApplicationRequestAgentPlansConfigEnum,
) => {
  const planConfig = getPlanEnabledStatusMap();

  return safeEnumMapGet<ApproveApplicationRequestAgentPlansConfigEnum, boolean>(
    planConfig,
    plan,
    true,
  );
};

export const getAllEnabledPlans = () => {
  const enabledPlans = values(
    ApproveApplicationRequestAgentPlansConfigEnum,
  ).filter((plan) => isPlanEnabled(plan));

  return enabledPlans;
};

export const ApplicationSortByTypeEnum: EnumMap<
  string,
  | 'FIRST_NAME'
  | 'LAST_NAME'
  | 'EMAIL'
  | 'STATUS'
  | 'CREATED_AT'
  | 'UPDATED_AT'
  | 'HAS_PENDING_TRANSACTIONS_WITH_CURRENT_BROKERAGE'
> = {
  firstName: 'FIRST_NAME',
  lastName: 'LAST_NAME',
  emailAddress: 'EMAIL',
  status: 'STATUS',
  createdAt: 'CREATED_AT',
  updatedAt: 'UPDATED_AT',
  hasPendingTransactionsWithCurrentBrokerage:
    'HAS_PENDING_TRANSACTIONS_WITH_CURRENT_BROKERAGE',
};

const ApplicationSortDirectionTypeEnum: EnumMap<string, 'ASC' | 'DESC'> = {
  asc: 'ASC',
  desc: 'DESC',
};

export const applicationTableFetchData = async (
  req: IPaginateReq<ApplicationResponse>,
  axiosOptions?: AxiosRequestConfig,
) => {
  const sortKey = Object.keys(req.sortBy || {})[0];
  const sortType = Object.values(req.sortBy || {})[0];
  const [createdAtStart, createdAtEnd] = getStartAndEndDate(
    (req.filter?.createdAt! as unknown) as SearchParam[],
  );
  const [updatedAtStart, updatedAtEnd] = getStartAndEndDate(
    (req.filter?.updatedAt! as unknown) as SearchParam[],
  );

  const hasPendingTransactionsWithCurrentBrokerage = !!req.filter
    ?.hasPendingTransactionsWithCurrentBrokerage
    ? (req.filter?.hasPendingTransactionsWithCurrentBrokerage as SearchParam)
        ?.value === YesNoType.YES
    : undefined;

  const { data } = await new ApplicationControllerApi(
    getYentaConfiguration(),
  ).getApplications(
    req.filter?.firstName as string,
    req.filter?.lastName as string,
    req.filter?.emailAddress as string,
    //@ts-ignore
    req.filter?.status?.values?.length > 0
      ? //@ts-ignore
        req.filter?.status?.values
      : IN_PROGRESS_APPLICATION_STATUS,
    (req.filter?.phoneNumber as unknown) as string,
    //@ts-ignore
    req.filter?.state?.values || [],
    undefined,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    hasPendingTransactionsWithCurrentBrokerage,
    req.page,
    req.pageSize,
    ApplicationSortDirectionTypeEnum[sortType!],
    [ApplicationSortByTypeEnum[sortKey!]],
    req.search,
    axiosOptions,
  );

  return {
    data: data?.applications || [],
    total: data?.totalCount || 0,
  };
};
