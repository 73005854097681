import { faHouse, faHouseBuilding } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ChecklistDefinitionDtoDealTypeEnum } from '../../openapi/yenta';
import { EnumMap } from '../../types';
import { safePartialEnumMapGet } from '../../utils/EnumHelper';
import { capitalizeEnum } from '../../utils/StringUtils';
import { cn } from '../../utils/classUtils';

interface OfficeDealTypeCellProps {
  dealType: ChecklistDefinitionDtoDealTypeEnum;
}

const GeminiOfficeDealTypeCell: React.FC<OfficeDealTypeCellProps> = ({
  dealType,
}) => {
  const dealTypeToStyleMap: EnumMap<
    ChecklistDefinitionDtoDealTypeEnum,
    string
  > = {
    SALE: 'bg-rezen-light-blue-100 text-primary-navy w-[75px]',
    LEASE: 'bg-midnight-green-300 text-midnight-green-900 w-[89px]',
  };

  const style = safePartialEnumMapGet(
    dealTypeToStyleMap,
    dealType,
    'bg-rezen-light-blue-100 text-primary-navy w-18',
  );

  const dealTypeToIconMap: EnumMap<
    ChecklistDefinitionDtoDealTypeEnum,
    React.ReactElement
  > = {
    SALE: <FontAwesomeIcon icon={faHouse} size='sm' />,
    LEASE: <FontAwesomeIcon icon={faHouseBuilding} size='sm' />,
  };

  const Icons = safePartialEnumMapGet(
    dealTypeToIconMap,
    dealType,
    <FontAwesomeIcon icon={faHouse} />,
  );

  return (
    <div className={cn('rounded-full', style)}>
      <div className='flex flex-row justify-center space-x-1.5 items-center px-1 py-[5px]'>
        {Icons}
        <p className='font-zen-body-2 font-medium text-sm min-w-max'>
          {capitalizeEnum(dealType)}
        </p>
      </div>
    </div>
  );
};

export default GeminiOfficeDealTypeCell;
