import React from 'react';
import { OfficeResponse } from '../../openapi/yenta';
import { formatAddressObject } from '../../utils/StringUtils';
import StateOrProvinceIconCell from '../table/Cells/StateOrProvinceIconCell';
import GeminiInformationCard from './GeminiInformationCard';
import GeminiLabelValueDisplayCard from './Office/GeminiLabelValueDisplayCard';

interface GeminiOfficeCompanyDetailsProps {
  officeDetail: OfficeResponse | undefined;
}

const GeminiOfficeCompanyDetails: React.FC<GeminiOfficeCompanyDetailsProps> = ({
  officeDetail,
}) => {
  if (officeDetail?.company === null) return null;
  return (
    <GeminiInformationCard title='Company Details'>
      <div className='hidden md:block space-y-6 py-5'>
        <div className='grid grid-cols-2 divide-x divide-grey-300'>
          <div className='px-7 py-2'>
            <GeminiLabelValueDisplayCard
              label='Name'
              value={officeDetail?.company?.name! ?? 'N/A'}
              noPadding
            />
          </div>
          <div className='px-4 py-2'>
            <GeminiLabelValueDisplayCard
              label='EIN'
              value={officeDetail?.company?.ein ?? 'N/A'}
              noPadding
            />
          </div>
        </div>
        <div className='grid grid-cols-2 divide-x divide-grey-300'>
          <div className='px-7 py-2'>
            <GeminiLabelValueDisplayCard
              label='Address'
              value={
                formatAddressObject(officeDetail?.company?.address!) ?? 'N/A'
              }
              noPadding
            />
          </div>
          <div className='px-4 py-2'>
            <GeminiLabelValueDisplayCard
              label='State/Province'
              value={
                <StateOrProvinceIconCell
                  state={
                    officeDetail?.company?.administrativeArea?.stateOrProvince!
                  }
                />
              }
              noPadding
            />
          </div>
        </div>
      </div>
      <div className='px-4 md:hidden block divide-y divide-grey-300'>
        <div className='py-4'>
          <GeminiLabelValueDisplayCard
            label='Name'
            value={officeDetail?.company?.name! ?? 'N/A'}
            noPadding
          />
        </div>
        <div className='py-4'>
          <GeminiLabelValueDisplayCard
            label='EIN'
            value={officeDetail?.company?.ein ?? 'N/A'}
            noPadding
          />
        </div>
        <div className='py-4'>
          <GeminiLabelValueDisplayCard
            label='Address'
            value={
              formatAddressObject(officeDetail?.company?.address!) ?? 'N/A'
            }
            noPadding
          />
        </div>
        <div className='py-4'>
          <GeminiLabelValueDisplayCard
            label='State/Province'
            value={
              <StateOrProvinceIconCell
                state={
                  officeDetail?.company?.administrativeArea?.stateOrProvince!
                }
              />
            }
            noPadding
          />
        </div>
      </div>
    </GeminiInformationCard>
  );
};

export default GeminiOfficeCompanyDetails;
