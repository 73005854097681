import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { UserPreviewResponse } from '../../../../openapi/yenta';
import { RootState } from '../../../../types';
import { getFullName } from '../../../../utils/AgentHelper';
import ResourceContainer from '../../../ResourceContainer';
import ZenAvatar from '../../ZenAvatar';

export interface ZenMultiMemberNameCellProps {
  users: UserPreviewResponse[];
  numToDisplay?: number;
  displayOverflow?: boolean;
}

const ZenMultiMemberNameCell: React.FC<ZenMultiMemberNameCellProps> = ({
  users,
  numToDisplay = 2,
  displayOverflow = true,
}) => {
  const {
    userIds: { agentByKeymakerId },
  } = useSelector((state: RootState) => state);
  const [isOpen, setIsOpen] = useState<string | undefined>(undefined);
  const [isAllUserHoverOpen, setIsAllUserHoverOpen] = useState<boolean>(false);

  const marginLeft =
    numToDisplay === 1 || users?.length === 1 ? 'ml-0' : '-ml-4';

  return (
    <div className='flex items-center ml-4'>
      <div className='flex'>
        {users?.slice(0, numToDisplay).map((user) => {
          if (!user?.id) {
            return null;
          }

          return (
            <div
              key={user?.id}
              className={classNames(
                'mr-2 rounded-full hover:z-10 mt-0.5',
                marginLeft,
              )}
              onMouseLeave={() => setIsOpen(undefined)}
            >
              <Popover
                isOpen={user.id === isOpen}
                positions={['top', 'bottom']}
                onClickOutside={() => setIsOpen(undefined)}
                parentElement={document.getElementById('main-page')!}
                content={({ position, childRect, popoverRect }) => (
                  <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor='white'
                    arrowSize={10}
                    arrowStyle={{
                      bottom: 1,
                      zIndex: 1,
                    }}
                    className='popover-arrow-container'
                    arrowClassName='popover-arrow'
                  >
                    <div className='md:w-auto w-auto border rounded-[20px] border-gray-300 bg-white divide-y-2 space-y-2 px-4 py-2 text-dark shadow-2xl'>
                      <Link to={`/people/${user?.id}/profile`}>
                        <div
                          key={user?.id}
                          className='flex flex-row items-center space-x-2 p-2 border-t-0'
                        >
                          <ZenAvatar
                            name={getFullName(user)!}
                            imageUrl={agentByKeymakerId[user?.id!]?.avatar}
                            size='xs'
                          />
                          <p className='text-sm font-zen-body font-normal'>
                            {getFullName(user) || 'N/A'}
                          </p>
                        </div>
                      </Link>
                    </div>
                  </ArrowContainer>
                )}
              >
                <div
                  onMouseEnter={() => setIsOpen(user?.id)}
                  className='border-2 border-white rounded-full'
                >
                  <ZenAvatar
                    name={getFullName(user)!}
                    imageUrl={agentByKeymakerId[user?.id!]?.avatar}
                    size='sm'
                  />
                </div>
              </Popover>
            </div>
          );
        })}

        {displayOverflow && users?.length > numToDisplay && (
          <div onMouseLeave={() => setIsAllUserHoverOpen(false)}>
            <Popover
              isOpen={isAllUserHoverOpen}
              positions={['top', 'bottom']}
              onClickOutside={() => setIsAllUserHoverOpen(false)}
              parentElement={document.getElementById('main-page')!}
              content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                  position={position}
                  childRect={childRect}
                  popoverRect={popoverRect}
                  arrowColor='white'
                  arrowSize={10}
                  arrowStyle={{
                    bottom: 1,
                    zIndex: 1,
                  }}
                  className='popover-arrow-container'
                  arrowClassName='popover-arrow'
                >
                  <div className='md:w-auto w-auto border rounded-[20px] border-gray-300 bg-white divide-y-2 space-y-2 px-6 py-2 text-dark shadow-2xl'>
                    <ResourceContainer
                      loading={false}
                      isEmpty={false}
                      resourceName='agents'
                    >
                      <div className='py-2 font-semibold font-zen-body text-base border-b border-zen-dark-4'>
                        Team Members
                      </div>
                      <div className='py-2'>
                        {users?.map((user) => (
                          <div key={user?.id}>
                            <Link to={`/people/${user?.id}/profile`}>
                              <div className='flex flex-row items-center space-x-2 py-2 border-t-0'>
                                <ZenAvatar
                                  name={getFullName(user)!}
                                  imageUrl={
                                    agentByKeymakerId[user?.id!]?.avatar
                                  }
                                  size='xs'
                                />
                                <p className='text-sm font-zen-body font-normal'>
                                  {getFullName(user) || 'N/A'}
                                </p>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </ResourceContainer>
                  </div>
                </ArrowContainer>
              )}
            >
              <div
                className={classNames(
                  'flex items-center justify-center w-9 h-9 font-semibold text-white text-sm font-zen-body rounded-full bg-zen-blue-4 border-2 border-white mt-0.5 -ml-4 cursor-pointer',
                )}
                onMouseEnter={() => setIsAllUserHoverOpen(true)}
              >
                +{users?.length - numToDisplay}
              </div>
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
};

export default ZenMultiMemberNameCell;
