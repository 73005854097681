import { cn } from '../../utils/classUtils';
import { AvatarSizeType, EnumMap } from '../../types';
import Avatar from '../Avatar';

export type ZenUserPillSizeType = 'xs' | 'sm' | 'md';
export type ZenPillBackgroundType = 'background' | 'no-background';

export interface ZenUserPillProps {
  name: string;
  size?: ZenUserPillSizeType;
  imageUrl?: string;
  backgroundVariant?: ZenPillBackgroundType;
  fontWeight?: string;
  noWrap?: boolean;
  prefixText?: string;
}

const ZenUserPill: React.FC<ZenUserPillProps> = ({
  size = 'xs',
  backgroundVariant = 'background',
  imageUrl,
  name,
  fontWeight = 'font-semibold',
  noWrap = false,
  prefixText,
}) => {
  const zenUserPillTextToClassNameMap: EnumMap<ZenUserPillSizeType, string> = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
  };

  const zenUserPillSizeToClassNameMap: EnumMap<
    ZenUserPillSizeType,
    AvatarSizeType
  > = {
    xs: 'xxs',
    sm: 'sm',
    md: 'xxs',
  };

  const zenUserBackgroundToClassNameMap: EnumMap<
    ZenPillBackgroundType,
    string
  > = {
    background: 'rounded-full bg-zen-light-blue hover:bg-opacity-50 px-4 py-2',
    'no-background': 'bg-none',
  };

  return (
    <div
      className={cn(
        zenUserBackgroundToClassNameMap[backgroundVariant],
        fontWeight,
        'font-zen-body inline-block',
      )}
    >
      <div
        className={cn('flex flex-row content-center space-x-2 items-center', {
          'whitespace-nowrap': noWrap,
        })}
      >
        <Avatar
          name={name}
          size={zenUserPillSizeToClassNameMap[size]}
          imageUrl={imageUrl}
        />
        <p
          className={cn(
            zenUserPillTextToClassNameMap[size],
            'text-zen-dark-9 capitalize',
          )}
        >
          {prefixText ? `${prefixText} (${name})` : name}
        </p>
      </div>
    </div>
  );
};

export default ZenUserPill;
