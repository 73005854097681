import EditIcon from '@material-ui/icons/Edit';
import qs from 'qs';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { RiAddFill } from 'react-icons/ri';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import ZenRoute from '../components/Zen/ZenRoute';
import { CompanyControllerApi, CompanyResponse } from '../openapi/yenta';
import { EnumMap, SearchParam } from '../types';
import { isSmScreen } from '../utils/BrowserUtils';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenButton from '../components/Zen/ZenButton';
import ZenViewActionButtonCell from '../components/table/Cells/ZenViewActionButtonCell';
import ZenActionButtonCell from '../components/Zen/Table/Cell/ZenActionButtonCell';
import ZenCompanyFormSidebarModal from '../forms/ZenCompanyFormSidebarModal';
import ZenAddressCell from '../components/Zen/Table/Cell/ZenAddressCell';
import AddressStateEnumSelectColumnFilter from '../components/table/Filters/AddressStateEnumSelectColumnFilter';

export const columns: Array<Column<CompanyResponse>> = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
  },
  {
    Header: 'EIN',
    accessor: 'ein',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Address',
    accessor: 'address',
    id: 'streetAddress',
    Cell: ({ value }) => (
      <div className='w-56'>
        <ZenAddressCell address={value} />
      </div>
    ),
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Administrative Area',
    accessor: 'administrativeArea',
    id: 'states',
    Cell: ({ value }) => value?.id,
    Filter: AddressStateEnumSelectColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
  },
];

export const columnsToFetch = columns.map((col) => col.accessor as string);

const ZenCompaniesIndexRoute: React.FC = () => {
  const location = useLocation();
  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [editCompany, setEditCompany] = useState<CompanyResponse>();

  const columnsWithAction: Array<Column<CompanyResponse>> = [
    {
      Header: 'Actions',
      id: 'actions',
      Cell: ({ row }: { row: Row<CompanyResponse> }) => (
        <div className='flex space-x-2'>
          <ZenViewActionButtonCell
            onClick={() => {
              setEditCompany(row.original);
              setIsReadOnly(true);
            }}
          />
          <ZenActionButtonCell
            leftIcon={
              <EditIcon
                className='text-white'
                titleAccess='Edit'
                data-testid='Edit'
              />
            }
            onClick={() => setEditCompany(row.original)}
          />
        </div>
      ),
    },
    ...columns,
  ];

  const CompaniesSortDirectionTypeEnum: EnumMap<string, 'ASC' | 'DESC'> = {
    asc: 'ASC',
    desc: 'DESC',
  };

  const CompaniesSortByTypeEnum: EnumMap<
    string,
    ['NAME'] | ['ID'] | ['STATE'] | ['EIN']
  > = {
    id: ['ID'],
    name: ['NAME'],
    administrativeArea: ['STATE'],
    ein: ['EIN'],
  };

  return (
    <ZenRoute title='Companies'>
      <ZenPageLayoutWithSearch
        path={[
          { title: 'Home', url: '/' },
          { title: 'Company', url: '/companies' },
        ]}
        search={search}
        onSearchChange={setSearch}
      >
        <div className='px-4 lg:pt-5'>
          <ZenResourceIndexContainer<CompanyResponse>
            header='Company'
            search={search}
            columns={columnsWithAction}
            initialSort={{ name: 'asc' }}
            RightComponent={
              <div className='flex flex-row space-x-2'>
                <ZenButton
                  label='Create Company'
                  variant='primary'
                  LeftIconComponent={<RiAddFill className='text-lg mb-0.5' />}
                  onClick={() => setIsCreating(true)}
                />
              </div>
            }
            fetchData={async (req) => {
              const sortKey = Object.keys(req.sortBy || {})[0];
              const sortType = Object.values(req.sortBy || {})[0];
              console.log('req', req);

              const { data } = await new CompanyControllerApi(
                getYentaConfiguration(),
              ).searchCompanies(
                CompaniesSortByTypeEnum[sortKey],
                CompaniesSortDirectionTypeEnum[sortType],
                req.page,
                req.pageSize,
                req.search,
                req.filter?.id as string,
                req.filter?.name as string,
                req.filter?.ein as string,
                //@ts-ignore
                req.filter?.streetAddress as string,
                //@ts-ignore
                (req.filter?.states as SearchParam)?.values as any[],
              );

              return {
                data: data?.results || [],
                total: data?.totalCount || 0,
              };
            }}
            resourceName='Company'
            showSearch={isSmScreen()}
          />
        </div>
        {(!!editCompany || isCreating) && (
          <ZenCompanyFormSidebarModal
            isOpen
            onClose={() => {
              if (isCreating) {
                setIsCreating(false);
              } else {
                setEditCompany(undefined);
                setIsReadOnly(false);
              }
            }}
            company={editCompany}
            readOnly={isReadOnly}
          />
        )}
      </ZenPageLayoutWithSearch>
    </ZenRoute>
  );
};

export default ZenCompaniesIndexRoute;
