import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import ZenTerminateAgentSidebarModal from '../../../forms/ZenTerminateAgentSidebarModal';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface ZenTerminateAgentProps {}

const ZenTerminateAgent: React.FC<ZenTerminateAgentProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div>
      <ZenResourceLinkCard
        title='Terminate Agent'
        onClick={() => setIsOpen(true)}
        icon={solid('user')}
        iconSize='2x'
      />
      <ZenTerminateAgentSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenTerminateAgent;
