import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { fetchJVInvite } from '../../../slices/JointVentureSlice';
import { RootState } from '../../../types';
import StepByStepContainer, {
  StepConfig,
} from '../../StepByStep/StepByStepContainer';
import MakeThePaymentStep from './MakeThePaymentStep';
import SignAgreementsStep from './SignAgreementsStep';

type Match = {
  inviteId: string;
};

export enum JointVentureSignUpStepName {
  MAKE_THE_PAYMENT = 'MAKE THE PAYMENT',
  SIGN_AGREEMENTS = 'SIGN AGREEMENTS',
}

export interface JointVentureSignUpFormState {
  cardInfo?: string;
  expirationDate?: string;
  cvc?: string;
  nameOnCard?: string;
  inviteId: string;
  jointVentureName: string;
  issuePrice: number;
}

const JointVentureSignUpSteps: React.FC = () => {
  const dispatch = useDispatch();
  const {
    auth: { userDetail, isAdmin, isBroker },
    jointVenture: {
      showInviteResponse: { data: inviteDetails, loading },
    },
  } = useSelector((state: RootState) => state);

  const { inviteId } = useParams() as Match;

  useEffect(() => {
    if (userDetail?.id && !isAdmin && !isBroker) {
      dispatch(fetchJVInvite());
    }
  }, [dispatch, isAdmin, isBroker, userDetail?.id]);

  const steps: StepConfig<
    JointVentureSignUpFormState,
    JointVentureSignUpStepName
  >[] = [
    {
      name: JointVentureSignUpStepName.MAKE_THE_PAYMENT,
      Component: MakeThePaymentStep,
      hidePagination: true,
    },
    {
      name: JointVentureSignUpStepName.SIGN_AGREEMENTS,
      Component: SignAgreementsStep,
      hidePagination: true,
    },
  ];

  const defaultValues: JointVentureSignUpFormState = {
    inviteId: inviteDetails?.id!,
    jointVentureName: inviteDetails?.jointVenture?.name!,
    issuePrice: inviteDetails?.jointVenture?.issuePrice?.amount!,
  };

  const onSubmit = async () => {};

  if (!loading && (!inviteDetails || inviteDetails.id !== inviteId)) {
    return <Redirect to='/' />;
  }

  return (
    <StepByStepContainer<
      JointVentureSignUpFormState,
      JointVentureSignUpStepName
    >
      steps={steps}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      reValidateMode='onChange'
      mode='onChange'
    />
  );
};

export default JointVentureSignUpSteps;
