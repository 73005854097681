import { isEmpty } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import qs from 'qs';
import AuthorizationContainer from '../components/auth/AuthorizationContainer';
import ResourceContainer from '../components/ResourceContainer';
import { useFetchAsyncMappingResponse } from '../hooks/useFetchAsyncMappingResponse';
import { ChecklistResponse } from '../openapi/sherlock';
import { fetchCheckLists } from '../slices/CheckListSlice';
import { AppDispatch, RootState } from '../types';
import { saveSplitPdfUrl } from '../slices/DropboxSlice';
import ZenSplitAndAttachPDF from '../components/Zen/split-pdf/V2/ZenSplitAndAttachPDF';
import { RezenObjectTypeEnum } from '../openapi/yada';
import ErrorService from '../services/ErrorService';
import { FileApi } from '../openapi/dropbox';
import { getDropboxConfiguration } from '../utils/OpenapiConfigurationUtils';

type Match = {
  checklistId: string;
  containerId: string;
  containerType: RezenObjectTypeEnum;
  dropboxId: string;
};

const SplitAndAttachPDFToChecklistRoute: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    containerId,
    checklistId,
    containerType,
    dropboxId,
  } = useParams<Match>();
  const {
    checklist: { checklistsById },
    dropbox: { splitPdfUrl },
  } = useSelector((state: RootState) => state);
  const { fileId, versionId, backUrl } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  }) as { fileId?: string; versionId?: string; backUrl: string };

  const fetchFile = useCallback(
    async (fileId: string, currentVersionId: string) => {
      try {
        const { data: fileUrl } = await new FileApi(
          getDropboxConfiguration(),
        ).getFileVersionUrl(fileId, currentVersionId);

        dispatch(
          saveSplitPdfUrl({
            name: 'splitPdfUrl',
            data: fileUrl,
            loading: false,
          }),
        );
      } catch (e) {
        ErrorService.notify('Unable to open a file in mortgage', e, {
          mortgage: { fileId },
        });

        dispatch(
          saveSplitPdfUrl({
            name: 'splitPdfUrl',
            error: ErrorService.getErrorCode(e),
            loading: false,
          }),
        );
      }
    },
    [dispatch],
  );

  const getCheckList = useCallback((): void => {
    if (checklistId) {
      dispatch(fetchCheckLists(checklistId));
    }
  }, [checklistId, dispatch]);

  const checklistAsyncResponse = useFetchAsyncMappingResponse<ChecklistResponse>(
    {
      name: 'checklist',
      fetch: getCheckList,
      response: checklistsById[checklistId]!,
    },
  );

  const checklistDetails = checklistAsyncResponse?.data;

  useEffect(() => {
    if (fileId && versionId) {
      fetchFile(fileId, versionId);
    }

    return () => {
      dispatch(saveSplitPdfUrl({ loading: false, name: 'splitPdfUrl' }));
    };
  }, [dispatch, fetchFile, fileId, versionId]);

  return (
    <AuthorizationContainer asyncResponse={checklistAsyncResponse}>
      <ResourceContainer
        loading={checklistAsyncResponse.loading || splitPdfUrl?.loading!}
        isEmpty={isEmpty(checklistAsyncResponse.data) && !splitPdfUrl?.data}
        resourceName='Checklist'
        errorCode={checklistAsyncResponse.error || splitPdfUrl?.error}
      >
        <ZenSplitAndAttachPDF
          checklist={checklistDetails!}
          containerId={containerId}
          containerType={containerType}
          pdfLink={splitPdfUrl?.data}
          dropboxId={dropboxId}
          backUrl={backUrl}
        />
      </ResourceContainer>
    </AuthorizationContainer>
  );
};

export default SplitAndAttachPDFToChecklistRoute;
