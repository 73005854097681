import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CreateTriggerDefRequest,
  CreateTriggerDefRequestActionNameEnum,
  DefinedConditionPairConditionNameEnum,
  DefinedConditionPairEventTypeEnum,
  EventSubscriptionEventEnum,
} from '../../../openapi/sherlock';
import {
  saveTrigger,
  editTrigger,
  toggleCreateTriggerModal,
  toggleEditTriggerModal,
} from '../../../slices/ChecklistDefinitionSlice';
import { ISelectOption, RootState } from '../../../types';
import StepByStepContainer, {
  StepConfig,
} from '../../StepByStep/StepByStepContainer';
import { capitalizeEnum } from '../../../utils/StringUtils';
import SubscriptionActionStep from './SubscriptionActionStep';
import SubscriptionConditionsStep from './SubscriptionConditionsStep';

export interface Condition {
  eventType?: DefinedConditionPairEventTypeEnum;
  event?: ISelectOption;
  checklistDefinition?: ISelectOption;
  itemDefinition?: ISelectOption;
  condition?: ISelectOption;
}

export interface CreateEditTriggerFormData {
  subscriptionConditions: Condition[];
  subscriptionAction?: ISelectOption;
  checklistId: string;
}

export enum CreateEditTriggerStepName {
  SUBSCRIPTION_CONDITION = 'SUBSCRIPTION_CONDITION',
  SUBSCRIPTION_ACTION = 'SUBSCRIPTION_ACTION',
}

interface CreateEditTriggerModalProps {
  itemId: string;
  templateId: string;
  triggerDefId?: string;
}

const CreateEditTriggerModal: React.FC<CreateEditTriggerModalProps> = ({
  itemId,
  templateId,
  triggerDefId,
}) => {
  const dispatch = useDispatch();
  const {
    showEditTriggerModal,
    selectedTrigger,
    checklistDefinitionById,
    itemDefinitionById,
  } = useSelector((state: RootState) => state.checkListDefinition);

  const steps: StepConfig<
    CreateEditTriggerFormData,
    CreateEditTriggerStepName
  >[] = useMemo(
    () => [
      {
        name: CreateEditTriggerStepName.SUBSCRIPTION_CONDITION,
        Component: SubscriptionConditionsStep,
        hidePagination: true,
      },
      {
        name: CreateEditTriggerStepName.SUBSCRIPTION_ACTION,
        Component: SubscriptionActionStep,
        hidePagination: true,
      },
    ],
    [],
  );

  const onSubmitTrigger = async (formData: CreateEditTriggerFormData) => {
    const req: CreateTriggerDefRequest = {
      actionName: formData?.subscriptionAction
        ?.value as CreateTriggerDefRequestActionNameEnum,
      conditionSets: {
        definedConditionPairs: (formData.subscriptionConditions || []).map(
          ({
            condition,
            checklistDefinition,
            event,
            itemDefinition,
            eventType,
          }) => ({
            eventType: eventType!,
            conditionName: condition?.value! as DefinedConditionPairConditionNameEnum,
            eventSubscription:
              eventType === DefinedConditionPairEventTypeEnum.System
                ? {
                    checklistDefinitionId: checklistDefinition?.value!,
                    event: event?.value! as EventSubscriptionEventEnum,
                    itemDefinitionId: itemDefinition?.value!,
                  }
                : undefined,
          }),
        ),
        scriptConditionPairs: [],
      },
    };

    if (triggerDefId) {
      await dispatch(editTrigger(itemId, triggerDefId, templateId, req));
      dispatch(toggleEditTriggerModal(false));
    } else {
      await dispatch(saveTrigger(itemId, templateId, req));
      dispatch(toggleCreateTriggerModal(false));
    }
  };

  const defaultValues: CreateEditTriggerFormData =
    showEditTriggerModal &&
    selectedTrigger?.conditionSets?.definedConditionPairs
      ? {
          subscriptionConditions: selectedTrigger.conditionSets.definedConditionPairs?.map(
            (condition) => ({
              eventType: condition.eventType,
              event: condition.eventSubscription?.event
                ? {
                    label: capitalizeEnum(condition.eventSubscription?.event!),
                    value: condition.eventSubscription?.event!,
                  }
                : undefined,
              checklistDefinition: checklistDefinitionById[
                condition.eventSubscription?.checklistDefinitionId!
              ]?.data
                ? {
                    label: checklistDefinitionById[
                      condition.eventSubscription?.checklistDefinitionId!
                    ]?.data?.name!,
                    value: checklistDefinitionById[
                      condition.eventSubscription?.checklistDefinitionId!
                    ]?.data?.id!,
                  }
                : undefined,
              itemDefinition: itemDefinitionById[
                condition.eventSubscription?.itemDefinitionId!
              ]?.data
                ? {
                    label: itemDefinitionById[
                      condition.eventSubscription?.itemDefinitionId!
                    ]?.data?.name!,
                    value: itemDefinitionById[
                      condition.eventSubscription?.itemDefinitionId!
                    ]?.data?.id!,
                  }
                : undefined,
              condition: condition.conditionName
                ? {
                    label: capitalizeEnum(condition.conditionName!),
                    value: condition.conditionName!,
                  }
                : undefined,
            }),
          ),
          subscriptionAction: selectedTrigger.actionName
            ? {
                label: capitalizeEnum(selectedTrigger.actionName!),
                value: selectedTrigger.actionName!,
              }
            : undefined,
          checklistId: templateId,
        }
      : {
          subscriptionConditions: [
            {
              eventType: DefinedConditionPairEventTypeEnum.System,
              event: undefined,
              checklistDefinition: undefined,
              itemDefinition: undefined,
              condition: undefined,
            },
          ],
          subscriptionAction: undefined,
          checklistId: templateId,
        };

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-60'
        role='button'
        onClick={() => {
          if (triggerDefId) {
            dispatch(toggleEditTriggerModal(false));
          } else {
            dispatch(toggleCreateTriggerModal(false));
          }
        }}
      />
      <div className='flex items-center justify-center w-full h-full scrollbar overflow-scroll py-5'>
        <div className='flex flex-col min-h-750 font-zen-body justify-center items-center w-fit z-10'>
          <StepByStepContainer<
            CreateEditTriggerFormData,
            CreateEditTriggerStepName
          >
            steps={steps}
            onSubmit={onSubmitTrigger}
            defaultValues={defaultValues}
            reValidateMode='onChange'
            mode='onChange'
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CreateEditTriggerModal);
