import { displayAmount } from '../../utils/CurrencyUtils';
import { MoneyValue } from '../../openapi/yenta';
import { EnumMap } from '../../types';

export type zenSignedNumberVariant = 'xs' | 'sm' | 'md';

export interface ZenSignedNumberProps {
  value: MoneyValue;
  textSize?: zenSignedNumberVariant;
}

const ZenSignedNumber: React.FC<ZenSignedNumberProps> = ({
  value,
  textSize = 'sm',
}) => {
  const zenVariantToClassNameMap: EnumMap<zenSignedNumberVariant, string> = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
  };

  const textColorFromAmount = (value: MoneyValue) => {
    if (value.amount! > 0) return 'text-green-600';
    if (value.amount! < 0) return 'text-zen-danger';
    return 'text-zen-dark-9';
  };

  return (
    <p
      className={`${textColorFromAmount(value)} ${
        zenVariantToClassNameMap[textSize]
      }`}
    >
      {displayAmount(value)}
    </p>
  );
};

export default ZenSignedNumber;
