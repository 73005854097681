import ZenEmailReset from '../../Zen/Engineering/ZenEmailReset';
import ZenEmailStatusCheck from '../../Zen/Engineering/ZenEmailStatusCheck';
import ZenUnbanEmail from '../../Zen/Engineering/ZenUnbanEmail';
import ZenResurrectAgent from './ZenResurrectAgent';
import ZenTerminateAgent from './ZenTerminateAgent';
import ZenAddForceMFA from './ZenAddForceMFA';
import ZenEnableForceGoogleSSO from './ZenAddForceGoogleSSO';

interface SupportComponentProps {}

const ZenSupportComponent: React.FC<SupportComponentProps> = () => {
  return (
    <div className='grid grid-cols-1 grid-flow-row md:grid-cols-6 gap-4 p-4'>
      <ZenEmailReset />
      <ZenTerminateAgent />
      <ZenResurrectAgent />
      <ZenEmailStatusCheck />
      <ZenUnbanEmail />
      <ZenAddForceMFA />
      <ZenEnableForceGoogleSSO />
      {/*TODO: Comment out for now. Do not want to give support the power to remove as of now. */}
      {/*<ZenRemoveForceMFA />*/}
    </div>
  );
};

export default ZenSupportComponent;
