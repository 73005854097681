import { isArray } from 'lodash';
import {
  DEFAULT_404_MESSAGE,
  DEFAULT_FORBIDDEN_MESSAGE,
  DEFAULT_SERVER_ERROR_MESSAGE,
  DEFAULT_UNAUTHORIZED_MESSAGE,
} from '../constants/ErrorsContants';
import ErrorService from '../services/ErrorService';
import { ApiError, ApiSubError, ErrorCode } from '../types';

export const TEAMMATE_LIMIT_REACHED = 'TEAMMATE_LIMIT_REACHED';

export const getErrorMessage = (
  errorCode: ErrorCode,
  resourceName?: string,
) => {
  if (errorCode === ErrorCode.UNAUTHORIZED) {
    return `${DEFAULT_UNAUTHORIZED_MESSAGE} ${resourceName}.`;
  }

  if (errorCode === ErrorCode.FORBIDDEN) {
    return `${DEFAULT_FORBIDDEN_MESSAGE} ${resourceName}.`;
  }

  if (errorCode === ErrorCode.NOT_FOUND) {
    return DEFAULT_404_MESSAGE;
  }

  return DEFAULT_SERVER_ERROR_MESSAGE;
};

export const getApiErrorMessage = (e: any) =>
  e?.response?.data?.['com.real.commons.apierror.ApiError']?.message ||
  getErrorMessage(ErrorService.getErrorCode(e));

export const getErrorModalTitleForStatus = (
  isAdmin: boolean,
  errorData: ApiError,
) => {
  if (isAdmin) {
    return 'Api Error';
  }

  if (errorData.status === 400) {
    return 'Bad Request';
  }

  if (errorData.status === 401) {
    return 'Unauthorized';
  }

  return 'Hmm, something is not right.';
};

export const getErrorModalMessage = (isAdmin: boolean, errorData: ApiError) => {
  if (isAdmin) {
    return `Error Code: ${errorData.status}`;
  }

  if (errorData.status === 500) {
    return 'An unexpected error occurred, please try again in a few moments';
  }

  return errorData.message;
};

export const showAndReportErrors = (errorResponse: ApiError) => {
  return errorResponse?.status !== 401 && errorResponse?.status !== 403;
};

export const createSubErrorString = (subErrors: ApiSubError[]) => {
  // return No Sub Error if there are no sub errors or is not array
  if (!subErrors?.length || !isArray(subErrors)) return 'No Sub Errors';

  // return all sub errors as a string
  return subErrors
    .map((subError) => `${subError?.field} - ${subError?.message}`)
    .join(', ');
};
