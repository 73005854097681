import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferralState } from '../types';

export const initialState: ReferralState = {
  errorFetchingMyReferrals: null,
  myReferrals: [],
  loadingAllReferrals: false,
};

const ReferralSlice = createSlice({
  name: 'referralCentral',
  initialState,
  reducers: {
    changeLoadingAllReferrals(state, action: PayloadAction<boolean>) {
      state.loadingAllReferrals = action.payload;
    },
  },
});

export const { changeLoadingAllReferrals } = ReferralSlice.actions;

export default ReferralSlice.reducer;
