import React from 'react';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Address,
  TransactionBuilderAddress,
} from '../../../../openapi/arrakis';
import NullableTextCell from '../../../table/Cells/NullableTextCell';
import { capitalizeEnum, formatAddress } from '../../../../utils/StringUtils';
import { AddressResponse } from '../../../../openapi/yenta';

interface GeminiAddressCellProps {
  address?: Address | TransactionBuilderAddress | AddressResponse;
  invoiceNumber?: string;
  placeholder?: React.ReactElement | string;
  showInvoice?: boolean;
}

const GeminiAddressCell: React.FC<GeminiAddressCellProps> = ({
  address,
  invoiceNumber,
  placeholder,
  showInvoice = true,
}) => {
  if (!address && placeholder) {
    return <div>{placeholder}</div>;
  }

  if (!address) {
    return <NullableTextCell text={undefined} />;
  }

  const getAddressPropertyValue = (
    key1: keyof Address | keyof Omit<TransactionBuilderAddress, 'unit'>,
    key2: keyof AddressResponse,
  ): string => {
    if (key1 in (address as Address)) {
      return (address as Address)[key1]! as string;
    }
    if (key2 in (address as AddressResponse)) {
      return (address as AddressResponse)[key2]!;
    }
    return '';
  };

  return (
    <div className='flex'>
      <div className='h-5 w-3 flex items-center justify-center mr-1'>
        <FontAwesomeIcon
          icon={faLocationDot}
          size='sm'
          className='text-primary-dark'
        />
      </div>
      <div className='flex flex-col space-y-1 font-zen-body-2 text-sm'>
        <span className='text-primary-dark font-normal'>
          {formatAddress(
            getAddressPropertyValue('street', 'streetAddress1'),
            getAddressPropertyValue('street2', 'streetAddress2'),
            getAddressPropertyValue('city', 'city'),
            capitalizeEnum(getAddressPropertyValue('state', 'stateOrProvince')),
            getAddressPropertyValue('zip', 'zipOrPostalCode'),
          )}
        </span>
        {showInvoice ? (
          <div className='flex text-gray-500'>
            <span className='uppercase font-semibold'>Invoice:</span>
            <span className='font-medium ml-0.5'>
              {!!invoiceNumber ? `#${invoiceNumber}` : 'N/A'}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default GeminiAddressCell;
