import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../types';
import DetailPageLoader from '../DetailPageLoader';
import ZenResourceContainer from '../Zen/ZenResourceContainer';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import ZenUplineRow from './ZenUplineRow';

interface ZenUplineProps {
  isOpen: boolean;
  onClose(): void;
  agentId: string;
}

const ZenUpline: React.FC<ZenUplineProps> = ({ isOpen, onClose, agentId }) => {
  const {
    auth: { isAdmin },
    agentDetail: {
      agentUplineInfoById,
      detailResponse: { data },
    },
  } = useSelector((state: RootState) => state);

  const agentUplineResponse = agentUplineInfoById[agentId];
  const actualUplineSponsors = (
    agentUplineResponse?.data?.flattenedSponsors || []
  )
    ?.filter((agent) => !agent?.real)
    ?.sort((a, b) => a?.level! - b?.level!);

  return (
    <ZenSidebarModal
      title='My Upline'
      subtitle='List of sponsors in your network upline'
      isOpen={isOpen}
      onClose={onClose}
    >
      <ZenResourceContainer
        loading={!!agentUplineResponse?.loading!}
        isEmpty={!actualUplineSponsors?.length}
        errorCode={agentUplineResponse?.error}
        LoaderComponent={<DetailPageLoader />}
        resourceName='sponsor agent'
        emptyMessage={`${data?.firstName} ${data?.lastName} has not sponsored any other agents.`}
      >
        <div className='divide-y divide-zen-light-gray-2'>
          {actualUplineSponsors?.map((agent) => (
            <div key={agent.id} className='px-4'>
              {isAdmin ? (
                <Link to={`/people/${agent?.id}`} className='cursor-pointer'>
                  <ZenUplineRow
                    name={`${agent?.firstName} ${agent?.lastName}`}
                    imageUrl={agent?.avatar!}
                    level={agent?.level}
                    email={agent?.emailAddress!}
                    phone={agent?.phoneNumber!}
                    sponsorSplit={agent?.revShareSplit}
                    status={agent?.status!}
                  />
                </Link>
              ) : (
                <ZenUplineRow
                  name={`${agent?.firstName} ${agent?.lastName}`}
                  imageUrl={agent?.avatar!}
                  level={agent?.level}
                  email={agent?.emailAddress!}
                  phone={agent?.phoneNumber!}
                  sponsorSplit={agent?.revShareSplit}
                  status={agent?.status!}
                />
              )}
            </div>
          ))}
        </div>
      </ZenResourceContainer>
    </ZenSidebarModal>
  );
};

export default ZenUpline;
