import { FilterProps } from 'react-table';
import { keys } from 'lodash';
import {
  NumberFilterType,
  FilterColumnsToProcess,
  NumberFilterTypeLabel,
  SearchParamOperatorEnum,
} from '../../../types';
import { PROCESS_FILTER_COLUMN } from '../../../utils/TableUtils';
import { MONEY_AMOUNT_REGEX } from '../../../utils/StringUtils';
import ControlledSelectInputV7 from '../../ControlledSelectInputV7';
import ControlledTextInputV7 from '../../ControlledTextInputV7';

const numberFilterOptions = keys(NumberFilterType) as NumberFilterType[];

export interface NumberColumnFilterProps<D extends object>
  extends FilterProps<D> {}

const NumberColumnFilter = <D extends object>({
  column,
  watch,
  control,
}: NumberColumnFilterProps<D>): React.ReactElement => {
  const prefixFieldName = `${PROCESS_FILTER_COLUMN}[${FilterColumnsToProcess.NUMBER}][${column.id}]`;

  const getType = () => {
    if (!!column.filterValue) {
      if (column.filterValue.length === 1) {
        const op = column.filterValue[0].operator;
        switch (op) {
          case SearchParamOperatorEnum.Gt:
            return NumberFilterType.greaterThan;
          case SearchParamOperatorEnum.Gte:
            return NumberFilterType.greaterThanOrEqual;
          case SearchParamOperatorEnum.Lt:
            return NumberFilterType.lessThan;
          case SearchParamOperatorEnum.Lte:
            return NumberFilterType.lessThanOrEqual;
        }
      } else if (column.filterValue.length === 2) {
        return NumberFilterType.between;
      }
    }

    return NumberFilterType.equals;
  };

  const selectedNumberFilter =
    (watch(`${prefixFieldName}.type`) as NumberFilterType) ?? getType();
  const startNumber = watch(
    `${prefixFieldName}.start_number`,
    column.filterValue?.length > 0 ? column.filterValue[0].value : '',
  );
  const endNumber = watch(
    `${prefixFieldName}.end_number`,
    column.filterValue?.length > 1 ? column.filterValue[1].value : '',
  );

  return (
    <div className='space-y-1'>
      <ControlledSelectInputV7
        control={control}
        shouldUnregister={false}
        options={numberFilterOptions.map((numberFilterOption) => ({
          label: NumberFilterTypeLabel[numberFilterOption],
          value: numberFilterOption,
        }))}
        name={`${prefixFieldName}.type`}
        rules={{
          required: 'Required',
        }}
        defaultValue={getType()}
      />
      <div className='flex justify-between space-x-1'>
        <ControlledTextInputV7
          control={control}
          type='number'
          shouldUnregister={false}
          name={`${prefixFieldName}.start_number`}
          rules={{
            required: 'Required',
            pattern: {
              value: MONEY_AMOUNT_REGEX,
              message: 'Please input number',
            },
            ...(endNumber
              ? {
                  max: {
                    value: endNumber,
                    message: `Cannot be greater than ${endNumber}`,
                  },
                }
              : {}),
          }}
          defaultValue={
            column.filterValue?.length > 0 ? column.filterValue[0].value : ''
          }
        />
        {selectedNumberFilter === NumberFilterType.between && (
          <ControlledTextInputV7
            control={control}
            type='number'
            shouldUnregister={false}
            name={`${prefixFieldName}.end_number`}
            rules={{
              required: 'Required',
              pattern: {
                value: MONEY_AMOUNT_REGEX,
                message: 'Please input number',
              },
              ...(startNumber
                ? {
                    min: {
                      value: startNumber,
                      message: `Cannot be less than ${startNumber}`,
                    },
                  }
                : {}),
            }}
            defaultValue={
              column.filterValue?.length > 1 ? column.filterValue[1].value : ''
            }
          />
        )}
      </div>
    </div>
  );
};

export default NumberColumnFilter;
