import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  AgentControllerApi,
  AgentResponse,
  UserResponseAgentStatusEnum,
} from '../../../../../openapi/yenta';
import ErrorService from '../../../../../services/ErrorService';
import { showApiErrorModal } from '../../../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../slices/ToastNotificationSlice';
import { AsyncSelectOption } from '../../../../../types';
import { getYentaConfiguration } from '../../../../../utils/OpenapiConfigurationUtils';
import { searchForAgents } from '../../../../../utils/TableUtils';

import DefaultLoader from '../../../../DefaultLoader';
import DoubleConfirmationModal from '../../../../DoubleConfirmationModal';
import IconButton from '../../../../IconButton';
import ZenControlledAsyncSelectInput from '../../../Input/ZenControlledAsyncSelectInput';
import ZenAvatarPill from '../../../ZenAvatarPill';
import ZenSidebarModalForm from '../../../ZenSidebarModalForm';

interface ZenChangeSponsorSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  sponsorAgent: AsyncSelectOption[];
  realAgent: AsyncSelectOption;
}

const ZenChangeSponsorSidebarModal: React.FC<ZenChangeSponsorSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    watch,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormData>({ mode: 'onChange' });
  const [realAgent, setRealAgent] = useState<AgentResponse>();
  const [loading, setLoading] = useState(false);
  const [sponsorAgentChange, setSponsorAgentChange] = useState(false);
  const [removeSponsor, setRemoveSponsor] = useState(false);
  const [removeSponsorLoading, setRemoveSponsorLoading] = useState(false);
  const [realAgentWatch, sponsorAgentWatch] = watch([
    'realAgent',
    'sponsorAgent',
  ]);

  const actualAgents = (realAgent?.sponsors || []).filter((data) => !data.real);

  const fetchAgent = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const { data } = await new AgentControllerApi(
          getYentaConfiguration(),
        ).getAgentById(id);
        setRealAgent(data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        ErrorService.notify('Error fetching real agent', e);
        dispatch(
          showErrorToast(
            'We had a problem fetching the real agent',
            'Please try again in a few moments.',
          ),
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (realAgentWatch) {
      fetchAgent(realAgentWatch.value);
    } else {
      setRealAgent(undefined);
    }
  }, [fetchAgent, realAgentWatch]);

  useEffect(() => {
    reset({ realAgent: realAgentWatch, sponsorAgent: [] });
  }, [realAgentWatch, reset]);

  const onSubmit = async (values: FormData) => {
    try {
      await new AgentControllerApi(
        getYentaConfiguration(),
      ).updateSponsorsForAgent(values.realAgent.value, {
        sponsorCodes: values.sponsorAgent.map((sponsor) => sponsor.value),
      });
      onClose();
      dispatch(showSuccessToast('Updated Sponsor agent(s) successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error updating sponsor agent(s)', e);
      dispatch(
        showErrorToast(
          'We had a problem updating the sponsor agent(s)',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setSponsorAgentChange(false);
    }
  };

  const removeAgentSponsor = async () => {
    setRemoveSponsorLoading(true);
    try {
      await new AgentControllerApi(
        getYentaConfiguration(),
      ).removeSponsorsForAgent(realAgent?.id!);
      onClose();
      dispatch(showSuccessToast('Sponsor(s) deleted successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error deleting sponsor agent(s)', e);
      dispatch(
        showErrorToast(
          'We had a problem deleting the sponsor(s)',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setRemoveSponsorLoading(false);
      setRemoveSponsor(false);
    }
  };

  return (
    <ZenSidebarModalForm
      actionTitle='Update'
      title='Change Sponsors'
      isOpen={isOpen}
      onClose={onClose}
      isSubmitting={isSubmitting}
      onSubmit={() => {
        setSponsorAgentChange(true);
      }}
    >
      <div>
        <div className='mt-1'>
          <ZenControlledAsyncSelectInput<FormData, 'realAgent'>
            control={control}
            name='realAgent'
            placeholder='Search'
            label='Which agent would you like to change?'
            fetchData={async (search, page) => {
              try {
                const { data } = await searchForAgents({
                  page,
                  search,
                  filterBy: {
                    agentStatus: [
                      UserResponseAgentStatusEnum.Active,
                      UserResponseAgentStatusEnum.Candidate,
                    ],
                  },
                });

                const options: AsyncSelectOption[] = data.map((resp) => ({
                  value: `${resp.id}`,
                  label: `${resp.firstName} ${resp.lastName} (${resp.emailAddress})`,
                }));

                return options;
              } catch (e) {
                ErrorService.notify(
                  'Unable to search for agents in change sponsor form',
                  e,
                  {
                    search: {
                      term: search,
                      page,
                    },
                  },
                );
                dispatch(
                  showErrorToast(
                    'An unexpected error occurred.',
                    'We were unable to search for an agent. Please try again in a few moments or contact support.',
                  ),
                );
              }

              return [];
            }}
            rules={{
              required: 'Agent is required',
            }}
          />
        </div>
        <div className='mt-4 flex items-start'>
          {!loading ? (
            !!actualAgents?.length ? (
              <div className='flex flex-col items-start font-primary-regular space-y-2'>
                <p className='font-primary-medium'>Current Sponsor(s):</p>
                <div className='flex flex-row items-start -ml-2 pb-2'>
                  {actualAgents?.map((sponsor) => (
                    <a
                      href={`/people/${sponsor?.sponsor?.id}`}
                      target='_blank'
                      rel='noreferrer nooopener'
                      className='mx-2'
                      key={sponsor?.sponsor?.id}
                    >
                      <ZenAvatarPill
                        text={sponsor?.sponsor?.fullName!}
                        imageUrl={sponsor?.sponsor?.avatar!}
                      />
                    </a>
                  ))}
                </div>
                <IconButton
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faTrash}
                      className='cursor-pointer'
                      title='remove'
                    />
                  }
                  variant='danger'
                  label='Remove Sponsors'
                  onClick={() => setRemoveSponsor(true)}
                />
              </div>
            ) : (
              !!realAgentWatch?.value && (
                <p className='font-primary-regular'>
                  {realAgent?.firstName} {realAgent?.lastName} does not have a
                  sponsors.
                </p>
              )
            )
          ) : (
            <DefaultLoader />
          )}
        </div>
        <div className='mt-5'>
          <ZenControlledAsyncSelectInput<FormData, 'sponsorAgent'>
            control={control}
            isMulti
            name='sponsorAgent'
            placeholder='Search'
            label='Who would you like to change their sponsors to?'
            fetchData={async (search, page) => {
              try {
                const { data } = await searchForAgents({
                  page,
                  search,
                  filterBy: {
                    agentStatus: [
                      UserResponseAgentStatusEnum.Active,
                      UserResponseAgentStatusEnum.Candidate,
                    ],
                  },
                });

                const options: AsyncSelectOption[] = data.map((resp) => ({
                  value: `${resp.sponsorCode}`,
                  label: `${resp.firstName} ${resp.lastName} (${resp.emailAddress})`,
                }));

                return options;
              } catch (e) {
                ErrorService.notify(
                  'Unable to search for agents in change sponsor form',
                  e,
                  {
                    search: {
                      term: search,
                      page,
                    },
                  },
                );
                dispatch(
                  showErrorToast(
                    'An unexpected error occurred.',
                    'We were unable to search for an agent. Please try again in a few moments or contact support.',
                  ),
                );
              }

              return [];
            }}
            rules={{
              required: 'Please select the sponsoring agents',
              validate: (value) => {
                if (value.some((v) => v.value === realAgent?.sponsorCode)) {
                  return 'You cannot set the sponsor as themselves.';
                }
                if (value.length > 2) {
                  return 'You cannot select more than two sponsors';
                }
                return undefined;
              },
            }}
          />
        </div>
      </div>
      <DoubleConfirmationModal
        onConfirm={handleSubmit(onSubmit)}
        isSubmitting={isSubmitting}
        isOpen={sponsorAgentChange}
        variant='info'
        title='Confirm Sponsor Agent Change'
        onClose={() => setSponsorAgentChange(false)}
        subtitle={`You are about to change ${
          realAgentWatch?.label
        } to have a sponsor of ${sponsorAgentWatch
          ?.map((data) => data.label)
          .join(', ')} is that correct?`}
      />
      <DoubleConfirmationModal
        onConfirm={removeAgentSponsor}
        isSubmitting={removeSponsorLoading}
        isOpen={removeSponsor}
        variant='error'
        title='Remove Sponsor?'
        onClose={() => setRemoveSponsor(false)}
        subtitle={`You are about to remove ${realAgentWatch?.label}'s sponsor.`}
      />
    </ZenSidebarModalForm>
  );
};

export default ZenChangeSponsorSidebarModal;
