import React from 'react';
import ZenAvatar from '../Zen/ZenAvatar';

interface MentionRowProps {
  name: string;
  emailAddress?: string;
  avatar?: string;
}

const MentionRow: React.FC<MentionRowProps> = ({
  name,
  emailAddress,
  avatar,
}) => {
  return (
    <div className='flex flex-row flex-nowrap items-center space-x-1 py-1 rounded hover:bg-primary hover:bg-opacity-10 cursor-pointer focus:bg-primary p-2'>
      <div>
        <ZenAvatar name={name} imageUrl={avatar} size='xs' />
      </div>
      <p className='text-black pl-1 whitespace-nowrap'>{name}</p>
      {!!emailAddress && (
        <React.Fragment>
          <p>&#8226;</p>
          <p className='text-gray-500 whitespace-nowrap truncate'>
            {emailAddress}
          </p>
        </React.Fragment>
      )}
    </div>
  );
};

export default MentionRow;
