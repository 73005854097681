interface StepContentInputProps {
  step: number;
}

const StepContentInput: React.FC<StepContentInputProps> = ({
  step,
  children,
}) => {
  return (
    <div className='flex flex-row items-start space-x-3'>
      <div className='w-6 h-6 bg-gray-300 rounded-full flex justify-center items-center'>
        <p className='p-2'>{step}</p>
      </div>
      {children}
    </div>
  );
};

export default StepContentInput;
