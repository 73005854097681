import React from 'react';
import classNames from 'classnames';
import { ReactComponent as TeamIcon } from '../../../assets/icons/zen/zen-teamsicon.svg';
import { ReactComponent as SpeakerPhoneIcon } from '../../../assets/icons/zen/zen-teamsspeakerphone.svg';
import { ReactComponent as HomeIcon } from '../../../assets/icons/zen/zen-teamshome.svg';
import { ReactComponent as CrownIcon } from '../../../assets/icons/zen/zen-teamscrownoutlined.svg';
import { EnumMap } from '../../../types';
import {
  FlexTeamConfigDtoTeamTypeEnum,
  TeamResponseTypeEnum,
} from '../../../openapi/yenta';
import { teamTypeToDisplayName } from '../../../utils/TeamHelper';
import { safeEnumMapGet } from '../../../utils/EnumHelper';

export interface ZenTeamsTypeProps {
  type: TeamResponseTypeEnum | FlexTeamConfigDtoTeamTypeEnum;
  isPill?: boolean;
}

const ZenTeamsType: React.FC<ZenTeamsTypeProps> = ({ type, isPill = true }) => {
  const iconVariantClassNameMap: EnumMap<TeamResponseTypeEnum, string> = {
    [TeamResponseTypeEnum.Group]: 'bg-zen-purple-light text-zen-purple-dark',
    [TeamResponseTypeEnum.Domestic]: 'bg-green-100 text-green-600',
    [TeamResponseTypeEnum.Normal]: 'bg-regent-200 text-primary-blue',
    [TeamResponseTypeEnum.Platinum]: 'bg-zen-warning-light text-zen-warning',
    [TeamResponseTypeEnum.Pro]: 'bg-regent-200 text-primary-blue',
  };

  const iconVariantMap: EnumMap<TeamResponseTypeEnum, React.ReactElement> = {
    [TeamResponseTypeEnum.Group]: <SpeakerPhoneIcon />,
    [TeamResponseTypeEnum.Domestic]: <HomeIcon />,
    [TeamResponseTypeEnum.Normal]: <TeamIcon />,
    [TeamResponseTypeEnum.Platinum]: <CrownIcon />,
    [TeamResponseTypeEnum.Pro]: <TeamIcon />,
  };

  const style = safeEnumMapGet(
    iconVariantClassNameMap,
    type as TeamResponseTypeEnum,
    'bg-grey-100 text-primary-blue',
  );

  return (
    <div
      className={classNames(
        'font-zen-body text-sm',
        isPill
          ? 'inline-flex items-center rounded-full px-3 py-1.5 font-semibold w-max'
          : 'flex flex-col justify-end rounded-lg p-3 font-medium w-[88px]',
        style,
      )}
    >
      {!isPill && (
        <div className='flex justify-center pb-2'>
          {safeEnumMapGet(
            iconVariantMap,
            type as TeamResponseTypeEnum,
            <TeamIcon />,
          )}
        </div>
      )}
      <div className='flex justify-center'>{teamTypeToDisplayName(type)}</div>
    </div>
  );
};

export default ZenTeamsType;
