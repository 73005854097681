import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  InstantPaymentControllerApi,
  TransactionLinkResponse,
} from '../../openapi/arrakis';
import AmplitudeService, {
  AmplitudeEvent,
} from '../../services/AmplitudeService';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import { fetchInstantPaymentsSummary } from '../../slices/InstantPaymentSlice';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { AppDispatch, RootState } from '../../types';
import { getInstantPaymentAmplitudeEventData } from '../../utils/InstantPaymentHelper';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import Button from '../Button';
import TermsAndCondition from './TermsAndCondition';

interface InstantPaymentsTermsAndConditionsModalProps {
  isOpen: boolean;
  agentId: string;
  transaction: TransactionLinkResponse | undefined;
  onClose(): void;
  onDecline(): void;
}

const InstantPaymentsTermsAndConditionsModal: React.FC<InstantPaymentsTermsAndConditionsModalProps> = ({
  isOpen,
  onClose,
  onDecline,
  agentId,
  transaction,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const [acceptingTOC, setAcceptingTOC] = useState<boolean>(false);

  const acceptInstantPaymentsTermsAndConditions = useCallback(async () => {
    setAcceptingTOC(true);
    try {
      await new InstantPaymentControllerApi(
        getArrakisConfiguration(),
      ).agreeToTermsAndConditions(agentId);

      await dispatch(fetchInstantPaymentsSummary(userDetail?.id!, false));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify(
        'unable to accept the instant payments terms and conditions',
        e,
      );
      dispatch(
        showErrorToast(
          'We had trouble accepting instant payments terms and conditions',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setAcceptingTOC(false);
    }
  }, [agentId, dispatch]);

  if (!isOpen) {
    return null;
  }
  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
        <div className='z-10 m-5 scrollbar overflow-y-auto bg-white rounded-lg md:w-3/5 lg:w-3/6 md:h-4/6 lg:h-5/6'>
          <TermsAndCondition
            userDetail={userDetail!}
            transaction={transaction}
          />

          <div className='sticky bottom-0 flex flex-row justify-end p-4 bg-white border-t border-gray-200'>
            <Button
              label='I Disagree'
              className='mr-5 !px-7'
              type='outline'
              onClick={onDecline}
              disabled={acceptingTOC}
            />
            <Button
              label='Agree and Sign'
              className='!px-7'
              type='primary'
              onClick={async () => {
                AmplitudeService.logEvent(
                  AmplitudeEvent.INSTANT_PAYMENTS_TOC_SIGN,
                  getInstantPaymentAmplitudeEventData(transaction),
                );
                await acceptInstantPaymentsTermsAndConditions();
                onClose();
              }}
              disabled={acceptingTOC}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstantPaymentsTermsAndConditionsModal;
