import amplitude from 'amplitude-js';
import config from '../config';
import { AgentResponse } from '../openapi/yenta';
import Logger from '../utils/Logger';

export enum AmplitudeEvent {
  TX_ONBOARDING_CDA_APPROVAL_ATTEMPT = 'TX_ONBOARDING_CDA_APPROVAL_ATTEMPT',
  TX_ONBOARDING_CDA_APPROVAL_CANCEL = 'TX_ONBOARDING_CDA_APPROVAL_CANCEL',
  TX_ONBOARDING_CDA_APPROVAL_SUCCESS = 'TX_ONBOARDING_CDA_APPROVAL_SUCCESS',
  TX_ONBOARDING_COMMISSION_VALIDATION_CANCEL = 'TX_ONBOARDING_COMMISSION_VALIDATION_CANCEL',
  TX_ONBOARDING_COMMISSION_VALIDATION_SUCCESS = 'TX_ONBOARDING_COMMISSION_VALIDATION_SUCCESS',

  INSTANT_PAYMENTS_TRANSACTION_BANNER_VIEW = 'INSTANT_PAYMENTS_TRANSACTION_BANNER_VIEW',
  INSTANT_PAYMENTS_TRANSACTION_BANNER_CLICK = 'INSTANT_PAYMENTS_TRANSACTION_BANNER_CLICK',
  INSTANT_PAYMENTS_SPLASH_VIEW = 'INSTANT_PAYMENTS_SPLASH_VIEW',
  INSTANT_PAYMENTS_TOC_VIEW = 'INSTANT_PAYMENTS_TOC_VIEW',
  INSTANT_PAYMENTS_TOC_SIGN = 'INSTANT_PAYMENTS_TOC_SIGN',
  INSTANT_PAYMENTS_INDEX_PAGE_VIEW = 'INSTANT_PAYMENTS_INDEX_PAGE_VIEW',
  INSTANT_PAYMENTS_CREATE_PAGE_VIEW = 'INSTANT_PAYMENTS_CREATE_PAGE_VIEW',
  INSTANT_PAYMENTS_CREATE_ATTEMPT = 'INSTANT_PAYMENTS_CREATE_ATTEMPT',
  INSTANT_PAYMENTS_CREATE_CANCEL = 'INSTANT_PAYMENTS_CREATE_CANCEL',
  INSTANT_PAYMENTS_CREATE_SUCCESS = 'INSTANT_PAYMENTS_CREATE_SUCCESS',
  INSTANT_PAYMENTS_DETAIL_PAGE_VIEW = 'INSTANT_PAYMENTS_DETAIL_PAGE_VIEW',

  AGENT_WEBSITE_CREATE = 'AGENT_WEBSITE_CREATE',
  AGENT_WEBSITE_UPDATE = 'AGENT_WEBSITE_UPDATE',

  ADD_EVENT_TO_CALENDAR_MODAL_VIEW = 'ADD_EVENT_TO_CALENDAR_MODAL_VIEW',
  ADD_EVENT_TO_CALENDAR = 'ADD_EVENT_TO_CALENDAR',
}

export default class AmplitudeService {
  static initialize(): void {
    Logger.debug('Amplitude -- initializing.');

    return amplitude.getInstance().init(config.amplitude.apiKey);
  }

  static setUser(userDetail: AgentResponse) {
    Logger.debug('Amplitude -- setting user to', userDetail.emailAddress);

    const identity = new amplitude.Identify();
    identity.set('id', userDetail.id!);
    identity.set('email', userDetail.emailAddress!);
    identity.set('country', userDetail.accountCountry!);
    identity.set('firstName', userDetail.firstName!);
    identity.set('lastName', userDetail.lastName!);

    amplitude.getInstance().setUserId(userDetail.emailAddress!);
    amplitude.getInstance().setUserProperties({
      id: userDetail.id,
      email: userDetail.emailAddress,
      country: userDetail.accountCountry,
      firstName: userDetail.firstName,
      lastName: userDetail.lastName,
    });
    amplitude.getInstance().identify(identity);
  }

  static logEvent(
    eventName: AmplitudeEvent,
    eventData: Record<string, unknown> = {},
  ) {
    Logger.debug(
      'Amplitude -- firing event',
      eventName,
      'with data',
      eventData,
    );

    amplitude.getInstance().logEvent(eventName, eventData);
  }
}
