import React from 'react';
import { Segment } from '../../../openapi/atlantis';
import MortgageApplicationSegment from './MortgageApplicationSegment';

interface MortgageApplicationSegmentsProps {
  segments?: Segment[];
  onSubmit: () => void;
  isReadOnly: boolean;
}

const MortgageApplicationSegments: React.FC<MortgageApplicationSegmentsProps> = ({
  segments = [],
  onSubmit,
  isReadOnly,
}) => {
  return (
    <div>
      {segments.map((segment: Segment) => (
        <MortgageApplicationSegment
          key={segment.name}
          segment={segment}
          onSubmit={onSubmit}
          isReadOnly={isReadOnly}
        />
      ))}
    </div>
  );
};
export default MortgageApplicationSegments;
