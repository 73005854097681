import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  BOOK_A_MEETING,
  REAL_FAST_14_URL,
} from '../../../constants/RealMortgageConstants';

export const OFFERINGS = [
  {
    title: 'Lead Nurture',
    description: 'Let our Advisors save you time and nurture your CRM leads.',
    link: BOOK_A_MEETING,
  },
  {
    title: 'REAL Fast 14',
    description:
      'Clear to Close Guarantee in 14 days, or your clients receive $2,500.',
    link: REAL_FAST_14_URL,
  },
];

const OneRealMortgageProductsAndOfferings: React.FC = () => {
  return (
    <div className='bg-seaglass p-5 sm:p-16'>
      <h1 className='sm:text-center text-[40px] font-semibold font-poppins leading-[48px]'>
        Products and Offerings
      </h1>
      <p className='font-light sm:text-center my-3'>
        One Real Mortgage offers innovative promotions to help you win more
        deals.
      </p>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 w-fit mx-auto mt-5 sm:mt-8'>
        {OFFERINGS.map((offering) => (
          <div
            key={offering.title}
            className='flex gap-3 p-3 py-5 border border-slate-600 rounded-lg cursor-pointer max-w-[414px] transition hover:bg-primary-navy hover:text-white'
            onClick={() => window.open(offering.link, '_blank')}
          >
            <FontAwesomeIcon icon={faCircleCheck} className='mt-1' size='lg' />
            <div>
              <h1 className='text-lg'>{offering.title}</h1>
              <p className='font-light text-sm'>{offering.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OneRealMortgageProductsAndOfferings;
