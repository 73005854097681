import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';

import OneRealMortgageLogo from '../../../assets/icons/real-mortgage/one-real-mortgage-logo.svg';
import {
  ORM_INSTAGRAM_URL,
  ORM_LINKEDIN_URL,
} from '../../../constants/MarketingCenterLinksConstants';
import SocialMediaLinksV2 from '../../../components/Zen/SocialMediaLinksV2';

interface OneRealMortgageHeaderProps {
  contactRef: React.RefObject<HTMLDivElement>;
}

const OneRealMortgageHeader: React.FC<OneRealMortgageHeaderProps> = ({
  contactRef,
}) => {
  return (
    <div className='flex items-center justify-between gap-3'>
      <img
        src={OneRealMortgageLogo}
        alt='one-real-mortgage-logo'
        className='block md:h-14 h-12 w-auto'
        data-testid='orm-logo'
      />
      <div className='flex items-center'>
        <div className='flex items-center gap-2 cursor-pointer'>
          <FontAwesomeIcon
            icon={faLocationDot}
            size='sm'
            className='text-primary-blue'
            data-testid='location-icon'
          />
          <button
            onClick={() =>
              contactRef.current?.scrollIntoView({ behavior: 'smooth' })
            }
            className='text-primary-blue text-sm sm:text-base'
          >
            Contact Info
          </button>
        </div>
        <div className='w-[1px] h-5 bg-slate-300 mx-4 flex-shrink-0' />
        <SocialMediaLinksV2
          label='Connect with us'
          socialLinks={[
            { icon: brands('instagram'), url: ORM_INSTAGRAM_URL },
            { icon: brands('linkedin'), url: ORM_LINKEDIN_URL },
          ]}
        />
      </div>
    </div>
  );
};

export default OneRealMortgageHeader;
