import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useHistory } from 'react-router-dom';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface ZenFeatureFlagsProps {}

const ZenFeatureFlags: React.FC<ZenFeatureFlagsProps> = () => {
  const history = useHistory();

  return (
    <div>
      <ZenResourceLinkCard
        title='Feature Flags'
        onClick={() => history.push('/feature-flags')}
        icon={solid('wand-magic-sparkles')}
        iconSize='2x'
      />
    </div>
  );
};

export default ZenFeatureFlags;
