import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps, ISelectOption } from '../types';
import FormErrorMessage from './FormErrorMessage';
import CustomImageSelectInput from './CustomImageSelectInput';

export interface ControlledImageSelectorInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  options: ISelectOption[];
  disabled?: boolean;
}

const ControlledImageSelectorInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  options,
  disabled = false,
  tooltipIndex,
  shouldUnregister = true,
  ...rest
}: ControlledImageSelectorInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      {...rest}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <div className='w-full'>
          <div className='flex flex-col'>
            {label && <label htmlFor={name}>{label}</label>}
            <div
              className='flex flex-row flex-wrap items-center gap-6 mt-3'
              data-tooltip-index={tooltipIndex}
            >
              {options.map((option) => (
                <CustomImageSelectInput
                  key={option.label}
                  item={option}
                  isActive={option.value === value}
                  disabled={disabled}
                  onChange={onChange}
                />
              ))}
            </div>
          </div>
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledImageSelectorInput;
