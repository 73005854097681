import { useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ZenResourceLinkCard from '../../../ZenResourceLinkCard';
import ZenChangeSponsorSidebarModal from './sidebarForms/ZenChangeSponsorSidebarModal';

interface ZenChangeSponsorProps {}

const ZenChangeSponsor: React.FC<ZenChangeSponsorProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div>
      <ZenResourceLinkCard
        title='Change Sponsors'
        onClick={() => setIsOpen(true)}
        icon={solid('person')}
        iconSize='2x'
      />
      {isOpen && (
        <ZenChangeSponsorSidebarModal isOpen onClose={() => setIsOpen(false)} />
      )}
    </div>
  );
};

export default ZenChangeSponsor;
