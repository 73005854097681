import { AgentWebsiteResponse } from '../../../../openapi/yenta';
import ZenContentRow from './ZenContentRow';

interface ZenFeaturedContentProps {
  agentWebsite: AgentWebsiteResponse | undefined;
}

const ZenFeaturedContent: React.FC<ZenFeaturedContentProps> = ({
  agentWebsite,
}) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-8 p-4'>
      <div className='flex flex-col space-y-8'>
        <ZenContentRow
          step={7}
          title='Feature 1 Title'
          content={agentWebsite?.feature1Title}
          defaultContent='Buy or sell today!'
        />
        <ZenContentRow
          step={8}
          title='Feature 1 Content'
          content={agentWebsite?.feature1Content}
          defaultContent='Use my site to search nearby properties and schedule appointments.'
        />
        <ZenContentRow
          step={9}
          title='Feature 2 Title'
          content={agentWebsite?.feature2Title}
          defaultContent='Proven track record'
        />
      </div>
      <div className='flex flex-col space-y-8'>
        <ZenContentRow
          step={10}
          title='Feature 2 Content'
          content={agentWebsite?.feature2Content}
          defaultContent='My experience and dedication set me apart, while my cutting technology and responsiveness assures a great outcome every time.'
        />
        <ZenContentRow
          step={11}
          title='Feature 3 Title'
          content={agentWebsite?.feature3Title}
          defaultContent='Cutting edge technology'
        />
        <ZenContentRow
          step={12}
          title='Feature 3 Content'
          content={agentWebsite?.feature3Content}
          defaultContent='Using a robust mobile platform and nimble real-time solution ensures that we never miss an opportunity to service your needs.'
        />
      </div>
    </div>
  );
};

export default ZenFeaturedContent;
