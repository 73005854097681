import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/pro-regular-svg-icons';
import { MouseEvent } from 'react';
import { cn } from '../../utils/classUtils';

export type ZenButtonVariant =
  | 'primary'
  | 'primary-outline'
  | 'primary-outline-selected'
  | 'primary-borderless'
  | 'secondary'
  | 'secondary-outline'
  | 'secondary-outline-selected'
  | 'secondary-light-outline'
  | 'secondary-light-outline-thin'
  | 'success'
  | 'warning'
  | 'danger'
  | 'danger-outline'
  | 'primary-link'
  | 'secondary-gray-outline'
  | 'danger-link'
  | 'secondary-gray-text-outline'
  | 'dark'
  | 'dark-outline'
  | 'rounded-white';

export type ZenButtonType = 'submit' | 'reset' | 'button';
export type ZenTextSize = 'sm' | 'md' | 'lg' | 'xl';

export interface ZenButtonProps {
  label?: string;
  value?: string;
  variant?: ZenButtonVariant;
  isDisabled?: boolean;
  LeftIconComponent?: React.ReactElement;
  RightIconComponent?: React.ReactElement;
  type?: ZenButtonType;
  isFullWidth?: boolean;
  onClick?:
    | ((e: MouseEvent<HTMLElement>) => void)
    | ((e: MouseEvent<HTMLButtonElement>) => void)
    | (() => void);
  isSubmitting?: boolean;
  textSize?: ZenTextSize;
  className?: string;
  disabledClassName?: string;
  noWrap?: boolean;
  fontstyle?: boolean;
  zeroPadding?: boolean;
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

const ZenButton: React.FC<ZenButtonProps> = ({
  label,
  variant = 'primary',
  isDisabled = false,
  LeftIconComponent,
  RightIconComponent,
  type = 'button',
  isFullWidth = false,
  isSubmitting = false,
  onClick,
  textSize = 'md',
  fontstyle = false,
  zeroPadding = false,
  className,
  noWrap = false,
  value,
  buttonProps,
  disabledClassName,
}) => {
  const variantClassMap: Record<ZenButtonVariant, string> = {
    primary: 'border-2 border-primary-blue bg-primary-blue text-white',
    'primary-outline':
      'border-2 border-primary-blue bg-white text-primary-blue',
    'primary-outline-selected':
      'border border-rezen-blue-600 bg-zen-light-blue text-rezen-blue-600 !font-zen-body !px-3',
    'primary-borderless': 'text-primary-blue',
    secondary: 'border-2 border-zen-dark-9 bg-zen-dark-9 text-white',
    'secondary-outline': 'border-2 border-zen-dark-9 bg-white text-zen-dark-9',
    'secondary-outline-selected':
      'border border-zen-dark-4 bg-white text-zen-dark-7 !font-zen-body !px-3',
    'secondary-light-outline-thin':
      'border border-zen-dark-4 bg-white text-zen-dark-9',
    'secondary-light-outline':
      'border-2 border-zen-dark-4 bg-white text-zen-dark-9',
    success: 'border-2 border-green-600 bg-green-600 text-white',
    warning: 'border-2 border-zen-warning bg-zen-warning text-white',
    danger: 'border-2 border-error bg-zen-danger text-white',
    'danger-outline': 'border-2 border-zen-danger bg-white text-zen-danger',
    'primary-link': 'bg-transparent text-primary-blue',
    'secondary-gray-outline': 'bg-white border-2 border-zen-dark-4 text-dark',
    'danger-link': 'bg-transparent text-zen-danger',
    'secondary-gray-text-outline':
      'border-2 border-zen-dark-4 bg-white text-zen-dark-7',
    dark: 'border-2 border-zen-dark-13 bg-zen-dark-13 text-white',
    'dark-outline': 'border-2 border-zen-dark-13 bg-white text-zen-dark-13',
    'rounded-white':
      'font-sembold text-zen- text-sm rounded-2xl bg-grey-100 py-0.5 hover:text-primary-blue',
  };

  const textSizeClassMap: Record<ZenTextSize, string> = {
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
  };

  return (
    <button
      className={cn(
        'flex items-center space-x-1 rounded-lg py-2 disabled:bg-zen-dark-4 disabled:text-white disabled:border-zen-dark-4 justify-center',
        variantClassMap[variant],
        textSizeClassMap[textSize],
        zeroPadding ? 'px-0' : 'px-4.5',
        {
          'w-full justify-center': isFullWidth,
        },
        {
          'w-max': noWrap,
        },
        fontstyle
          ? 'font-zen-body font-semibold'
          : 'font-zen-title font-medium',
        className,
        disabledClassName,
      )}
      type={type}
      onClick={onClick}
      disabled={isDisabled}
      value={value}
      {...buttonProps}
    >
      {isSubmitting && (
        <FontAwesomeIcon icon={faRotate} className='animate-spin mr-1' />
      )}
      {LeftIconComponent && <span>{LeftIconComponent}</span>}
      {label && <span>{label}</span>}
      {RightIconComponent && <span>{RightIconComponent}</span>}
    </button>
  );
};

export default ZenButton;
