import { faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';
import {
  faCircleBolt,
  faEye,
  faToggleOn,
  faUserGroup,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import {
  ParticipantValue,
  PaymentParticipantValue,
  TransactionResponse,
  UpdateParticipantRequest,
  UpdateParticipantRequestParticipantRoleEnum,
} from '../openapi/arrakis';
import {
  toggleTaxWithheld,
  updateInstantPaymentEligibility,
  updateTransactionParticipant,
} from '../slices/TransactionSlice';
import { FeatureFlagTypeEnum, RootState } from '../types';
import {
  getParticipantName,
  isAgentParticipant,
} from '../utils/ParticipantHelper';
import { formatPhoneNumber } from '../utils/StringUtils';
import {
  isCanadaTransaction,
  participantRoleDisplayName,
} from '../utils/TransactionHelper';
import AvatarPill from './AvatarPill';
import GridCard from './GridCard';
import IconButton from './IconButton';
import IconText from './IconText';
import SimpleConfirmationModal from './SimpleConfirmationModal';
import ToggleRow from './ToggleRow';

export interface ToggleableFieldConfig {
  value: boolean;
  onToggle: (value: boolean) => void;
  label: string;
  tooltip?: string;
}

export interface ParticipantCardProps {
  transaction?: TransactionResponse;
  participant: PaymentParticipantValue | ParticipantValue;
  isAdmin: boolean;
  onEdit(): void;
  onDelete(): void;
  isReadonly?: boolean;
  transactionOwner?: boolean;
  hideView?: boolean;
}

const ParticipantCard: React.FC<ParticipantCardProps> = ({
  transaction,
  participant,
  children,
  onEdit,
  onDelete,
  isReadonly,
  transactionOwner,
  isAdmin,
  hideView,
}) => {
  const dispatch = useDispatch();

  const toggleParticipant = async (
    flag: keyof UpdateParticipantRequest,
    value: boolean,
  ) => {
    await dispatch(
      updateTransactionParticipant(participant.id!, transaction?.id!, {
        paidByReal: !!participant.paidByReal,
        passThrough: !!participant.passThrough,
        participantRole: (participant.role as unknown) as UpdateParticipantRequestParticipantRoleEnum,
        paymentInstructions: participant.paymentInstructions,
        address: participant.address,
        payer: !!participant.commissionDocumentPayer,
        commissionDocumentRecipient: !!participant.commissionDocumentRecipient,
        firstName: participant.firstName,
        lastName: participant.lastName,
        phoneNumber: participant.phoneNumber,
        emailAddress: participant.emailAddress,
        personalDeal:
          'personalDeal' in participant ? !!participant.personalDeal : false,
        [flag]: value,
      }),
    );
  };

  const updateInstantCommissionToggle = async (value: boolean) => {
    await dispatch(
      updateInstantPaymentEligibility(transaction?.id!, participant?.id!, {
        instantPaymentEligible: value,
      }),
    );
  };

  const DEFAULT_USER_TOGGLES: ToggleableFieldConfig[] = [
    {
      value: !!participant['commissionDocumentRecipient'],
      onToggle: (toggle: boolean) =>
        toggleParticipant('commissionDocumentRecipient', toggle),
      label: isCanadaTransaction(transaction!)
        ? 'Receives Trade Record Sheet?'
        : 'Receives Invoice / Commission Document?',
    },
  ];

  const DEFAULT_ADMIN_TOGGLES: ToggleableFieldConfig[] = [
    {
      value: !!participant['paidByReal'],
      onToggle: (toggle: boolean) => toggleParticipant('paidByReal', toggle),
      label: 'Is Single Check?',
    },
    {
      value: !!participant['passThrough'],
      onToggle: (toggle: boolean) => toggleParticipant('passThrough', toggle),
      label: 'Pass Through Deal?',
    },
  ];

  const [isFlagsShown, setIsFlagsShown] = useState(false);
  const [
    isPersonalDealModalOpen,
    setIsPersonalDealModalOpen,
  ] = useState<boolean>(false);
  const { transactionPermissions } = useSelector(
    (state: RootState) => state.transaction,
  );
  const currentParticipantPermission = transactionPermissions?.participantPermissions?.find(
    (p) => p.participantId === participant?.id,
  );
  const isEligible = currentParticipantPermission?.callerAllowedToAssignInstantPaymentEligibility!;
  const DEFAULT_TOGGLES = [...DEFAULT_USER_TOGGLES];

  const instantPaymentEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.INSTANT_PAYMENTS_TOGGLE,
  );

  if (isEligible && instantPaymentEnabled) {
    DEFAULT_TOGGLES.push({
      value: !!participant['instantPaymentEligible'],
      onToggle: (toggle: boolean) => updateInstantCommissionToggle(toggle),
      label: 'Eligible For Instant Payment',
    });
  }

  const toggleableFieldConfig = isAdmin
    ? [...DEFAULT_TOGGLES, ...DEFAULT_ADMIN_TOGGLES]
    : DEFAULT_TOGGLES;

  if ('personalDeal' in participant) {
    toggleableFieldConfig.unshift({
      value: !!participant['personalDeal'],
      onToggle: async (toggle: boolean) => {
        setIsPersonalDealModalOpen(toggle);
        if (!toggle) {
          await toggleParticipant('personalDeal', false);
        }
      },
      label: 'Personal Deal?',
    });
  }

  if (isAdmin && 'taxWithheld' in participant) {
    toggleableFieldConfig.push({
      value: !!participant['taxWithheld'],
      onToggle: (toggle: boolean) =>
        dispatch(
          toggleTaxWithheld(transaction?.id!, participant?.id!, {
            taxWithheld: toggle,
          }),
        ),
      label: 'Tax Withheld?',
    });
  }

  const [isToggling, setIsToggling] = useState<Record<number, boolean>>({});

  const changeToggleLoading = (index: number, loading: boolean) => {
    const modifiedIsToggling = { ...isToggling };
    modifiedIsToggling[index] = loading;
    setIsToggling(modifiedIsToggling);
  };

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  const participantName = getParticipantName(participant);

  return (
    <section className='relative overflow-hidden' id='participantCard'>
      <GridCard
        items={[
          {
            name: '',
            value: (
              <div>
                <div className='flex items-center space-x-2'>
                  {isAgentParticipant(participant.type!) &&
                  participant?.yentaId ? (
                    // TODO: Add avatar when available in api
                    <Link to={`/people/${participant?.yentaId!}`}>
                      <AvatarPill imageUrl='' text={participantName || 'N/A'} />
                    </Link>
                  ) : (
                    // TODO: Add avatar when available in api
                    <AvatarPill imageUrl='' text={participantName || 'N/A'} />
                  )}
                  {transactionOwner && (
                    <div>
                      <div ref={setTriggerRef}>
                        <FontAwesomeIcon
                          icon={faUserGroup}
                          data-testid='Owner'
                        />
                      </div>
                      {visible && (
                        <div
                          ref={setTooltipRef}
                          {...getTooltipProps({ className: 'tooltip' })}
                        >
                          <div {...getArrowProps({ className: 't-arrow' })} />
                          Transaction Owner
                        </div>
                      )}
                    </div>
                  )}
                  {isAdmin && participant.instantPaymentEligible && (
                    <div>
                      <IconButton
                        leftIcon={
                          <FontAwesomeIcon
                            icon={faCircleBolt}
                            className='text-legacy-blue bg-primary-light rounded-full'
                            size='xl'
                          />
                        }
                        variant='none'
                        toolTipText='Instant Payment Eligible'
                      />
                    </div>
                  )}
                </div>
                <p className='mt-2 px-2'>
                  {participant.role
                    ? participantRoleDisplayName(participant.role)
                    : 'N/A'}
                </p>
              </div>
            ),
          },
          {
            name: 'contact information',
            value: (
              <div className='flex flex-col'>
                {participant.emailAddress && <p>{participant.emailAddress}</p>}
                {participant.phoneNumber && (
                  <p>{formatPhoneNumber(participant.phoneNumber)}</p>
                )}
                {!participant.emailAddress && !participant.phoneNumber && (
                  <p>N/A</p>
                )}
              </div>
            ),
          },
        ]}
      />
      {children}
      {!isReadonly ? (
        <div className='border border-t-0 border-gray-200 border-collapse w-full flex justify-center items-center'>
          <div
            className='flex justify-center w-full p-2 cursor-pointer'
            onClick={onEdit}
          >
            <IconText
              icon={<FontAwesomeIcon icon={faPen} className='text-grey-600' />}
              text='Edit'
            />
          </div>
          <div
            className='border-l border-r flex justify-center w-full p-2 cursor-pointer'
            onClick={() => setIsFlagsShown(true)}
          >
            <IconText
              icon={
                <FontAwesomeIcon
                  icon={faToggleOn}
                  className='rotate-180 text-gray-600'
                />
              }
              text='Toggle Flags'
            />
          </div>
          <div
            className='flex justify-center w-full p-2 cursor-pointer'
            onClick={onDelete}
          >
            <IconText
              icon={
                <FontAwesomeIcon icon={faTrash} className='text-grey-600' />
              }
              text='Delete'
            />
          </div>
        </div>
      ) : (
        !hideView && (
          <div className='border border-t-0 border-gray-200 border-collapse w-full flex justify-center items-center'>
            <div
              className='border-r flex justify-center w-full p-2 cursor-pointer'
              onClick={onEdit}
            >
              <IconText
                icon={
                  <FontAwesomeIcon icon={faEye} className='text-grey-600' />
                }
                text='View'
              />
            </div>
          </div>
        )
      )}
      {isFlagsShown && (
        <div className='absolute z-10 top-0 right-0 bottom-0 left-0 bg-black bg-opacity-70 flex flex-col justify-end'>
          <div className='flex justify-end'>
            <button
              type='button'
              className='focus:outline-none'
              onClick={() => setIsFlagsShown(false)}
            >
              <FontAwesomeIcon
                icon={faXmark}
                className='text-primary-light'
                title='Close'
              />
            </button>
          </div>
          <div className='bg-primary-light border animate-slide-in-up divide-y'>
            {toggleableFieldConfig.map(
              ({ value, onToggle, label, tooltip }, index) => (
                <ToggleRow
                  key={label}
                  value={value}
                  onChange={async (toggle) => {
                    changeToggleLoading(index, true);
                    await onToggle(toggle);
                    changeToggleLoading(index, false);
                  }}
                  title={label}
                  tooltip={tooltip}
                  loading={isToggling[index]}
                />
              ),
            )}
          </div>
        </div>
      )}
      <SimpleConfirmationModal
        title='Personal Deal?'
        subtitle='By selecting personal deal you acknowledge that you or your spouse are listed on the title of the property being sold/purchase or own the Entity (LLC, Trust, Corp) that owns the property being sold or purchased'
        isOpen={isPersonalDealModalOpen}
        variant='info'
        isSubmitting={false}
        onConfirm={async () => {
          setIsPersonalDealModalOpen(false);
          changeToggleLoading(0, true);
          await toggleParticipant('personalDeal', isPersonalDealModalOpen);
          changeToggleLoading(0, false);
        }}
        onClose={() => setIsPersonalDealModalOpen(false)}
      />
    </section>
  );
};

export default ParticipantCard;
