import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { RootState } from '../../../types';
import ZenAction from '../../Zen/ZenAction';
import ZenCardWithItems from '../../Zen/ZenCardWithItems';
import ZenChangePasswordForm from '../ZenChangePasswordForm';
import ZenUpdateEmailForm from '../ZenUpdateEmailForm';
import { fetchIdentityByKeymakerId } from '../../../slices/AuthSlice';
import { IdentitySummaryResponse } from '../../../openapi/keymaker';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import ResourceContainer from '../../ResourceContainer';
import { DemoModeContainer } from '../../../containers/DemoModeContainer';
import { useGemini } from '../../../hooks/useGemini';
import { cn } from '../../../utils/classUtils';

interface ZenSecurityProfileCardProps {}

const ZenSecurityProfileCard: React.FC<ZenSecurityProfileCardProps> = () => {
  const dispatch = useDispatch();
  const isGeminiEnabled = useGemini();
  const {
    agentDetail: {
      detailResponse: { data: agentDetailResponse },
    },
    auth: { identityByKeymakerIdResponse },
  } = useSelector((state: RootState) => state);

  const [isChangingEmail, setIsChangingEmail] = useState<boolean>(false);
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);

  const agentLoginDetails:
    | IdentitySummaryResponse
    | undefined = identityByKeymakerIdResponse.data![
    agentDetailResponse?.keyMakerId!
  ];

  useEffect(() => {
    if (!agentLoginDetails) {
      dispatch(fetchIdentityByKeymakerId(agentDetailResponse?.keyMakerId!));
    }
  }, [dispatch, agentDetailResponse?.keyMakerId, agentLoginDetails]);

  return (
    <AuthorizationContainer asyncResponse={identityByKeymakerIdResponse}>
      <ResourceContainer
        loading={!agentLoginDetails && identityByKeymakerIdResponse.loading}
        isEmpty={!agentLoginDetails}
        resourceName='My Login'
      >
        <ZenCardWithItems
          RightComponent={
            <DemoModeContainer hide>
              <ZenAction
                text='Update Email'
                icon={
                  <FontAwesomeIcon
                    icon={faPen}
                    className={cn(
                      isGeminiEnabled
                        ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                        : 'text-primary-blue text-sm',
                    )}
                  />
                }
                onClick={() => setIsChangingEmail(true)}
              />
            </DemoModeContainer>
          }
          items={[
            {
              name: 'Username',
              value: agentLoginDetails?.username,
            },
            {
              name: 'Email',
              value: agentLoginDetails?.email,
            },
            {
              name: 'Password',
              value: '',
              rightActionComponent: (
                <DemoModeContainer hide>
                  <ZenAction
                    text='Change Password'
                    onClick={() => setIsChangingPassword(true)}
                  />
                </DemoModeContainer>
              ),
            },
          ]}
          title='My Login'
        />
        {isChangingEmail && (
          <ZenUpdateEmailForm
            isOpen
            onClose={() => setIsChangingEmail(false)}
            identity={agentLoginDetails!}
          />
        )}
        {isChangingPassword && (
          <ZenChangePasswordForm
            isOpen
            onClose={() => setIsChangingPassword(false)}
          />
        )}
      </ResourceContainer>
    </AuthorizationContainer>
  );
};

export default ZenSecurityProfileCard;
