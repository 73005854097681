import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faLocationDot,
  faPencil,
  faPhone,
} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import ZenAction from '../Zen/ZenAction';
import { DirectoryCommonEntityResponse } from '../../query/directory/useDirectory';
import { formatPhoneNumber } from '../../utils/StringUtils';

interface ContactDetailSectionProps {
  contactType: string;
  data: DirectoryCommonEntityResponse;
  handleEdit: (e?: MouseEvent) => void;
  shouldAllowToEdit: boolean;
}

const ZenContactDetailSection: React.FC<ContactDetailSectionProps> = ({
  contactType,
  data,
  handleEdit,
  shouldAllowToEdit = false,
}) => {
  return (
    <div className='border rounded-2xl w-full p-4 gap-3 bg-zen-light-gray-1'>
      <div className='flex flex-col'>
        <div className='flex flex-row justify-between items-center'>
          <p className='text-primary-dark font-inter font-semibold text-base'>
            {contactType}
          </p>
          <div>
            {shouldAllowToEdit && (
              <ZenAction
                text='Edit'
                icon={
                  <FontAwesomeIcon
                    icon={faPencil}
                    size='sm'
                    className='text-primary-blue'
                  />
                }
                onClick={handleEdit}
              />
            )}
          </div>
        </div>
        <div className='flex flex-col gap-y-4 mt-2'>
          <div className='flex flex-row items-center gap-x-6'>
            <div style={{ minWidth: '20px' }}>
              <FontAwesomeIcon
                icon={faEnvelope}
                className='text-primary-blue leading-6 text-xl mt-1'
              />
            </div>
            <div>
              <p
                className='text-zen-dark-28 font-inter font-light text-base'
                style={{ minWidth: '100px' }}
              >
                {data?.emailAddress}
              </p>
            </div>
          </div>
          {data?.secondaryEmailAddress && (
            <div className='flex flex-row items-center gap-x-6'>
              <div style={{ minWidth: '20px' }}>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className='text-primary-blue leading-6 text-xl mt-1'
                />
              </div>
              <div>
                <p
                  className='text-zen-dark-28 font-inter font-light text-base'
                  style={{ minWidth: '100px' }}
                >
                  {data?.secondaryEmailAddress}
                </p>
              </div>
            </div>
          )}
          <div className='flex flex-row gap-x-6 items-center'>
            <div style={{ minWidth: '20px' }}>
              <FontAwesomeIcon
                icon={faPhone}
                className='text-primary-blue leading-6 text-xl'
              />
            </div>
            <div>
              <p className='text-zen-dark-28 font-inter font-light text-base'>
                {formatPhoneNumber(data?.phoneNumber)}
              </p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-x-6'>
            <div style={{ minWidth: '23px' }}>
              <FontAwesomeIcon
                icon={faLocationDot}
                className='text-primary-blue leading-6 text-xl'
                // style={{ fontSize: '16px' }}
              />
            </div>
            <div>
              <p className='text-zen-dark-28 font-inter font-light text-base flex-shrink-0'>
                {data?.address?.oneLine}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenContactDetailSection;
