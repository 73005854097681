import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { cn } from '../../utils/classUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import Hover from '../Hover';
import { Tier } from './CreateJointVentureSteps';
import TierSharesDistributionInfo from './TierSharesDistributionInfo';

export interface TierDistributionProps {
  label: string;
  tiers: Tier[];
  totalSharesCount: number;
  realPoolPercentShare: number;
  availablePoolShare: number;
  areAllTierSharesSet: boolean;
}

const TierDistribution: React.FC<TierDistributionProps> = ({
  label,
  tiers,
  totalSharesCount,
  realPoolPercentShare,
  availablePoolShare,
  areAllTierSharesSet,
}) => {
  const areTiersValid =
    totalSharesCount &&
    realPoolPercentShare &&
    tiers.every((el) => !!el.poolPercentShare && !!el.buyInShares);

  const cumulativeTierInfo = {
    agentCount: 0,
    sharePercent: 0,
    shareCount: 0,
  };

  const allTiers = [
    {
      name: 'Real',
      colorCode: '#3B82F6',
      poolPercentShare: realPoolPercentShare,
      buyInShares: undefined,
      customTierName: undefined,
    },
    ...tiers,
    ...(availablePoolShare
      ? [
          {
            name: 'Available',
            colorCode: '#439775',
            poolPercentShare: availablePoolShare,
          },
        ]
      : []),
  ];

  const getDefaultWidth = (tier: string) => {
    switch (tier) {
      case 'Real':
        return 55;
      case 'Silver':
      case 'Gold':
      case 'Platinum':
      default:
        return 45 / tiers.length;
    }
  };
  return (
    <div className='flex flex-col'>
      <span className='font-zen-title text-xl leading-7 text-zen-dark-9'>
        {label}
      </span>
      <div className='relative flex flex-row items-end mt-10'>
        {allTiers.map(
          (
            { name, customTierName, poolPercentShare, colorCode, buyInShares },
            index,
          ) => {
            const barWidth = Math.min(
              Math.round(
                areAllTierSharesSet
                  ? poolPercentShare!
                  : getDefaultWidth(customTierName || name!),
              ),
              100,
            );

            const shareCount = Math.floor(
              poolPercentShare! * totalSharesCount * 0.01,
            );

            if (index !== 0 && index <= tiers.length) {
              cumulativeTierInfo.shareCount += parseFloat(
                shareCount.toFixed(1),
              );
              cumulativeTierInfo.agentCount += buyInShares
                ? Math.floor(shareCount / buyInShares)
                : 0;
              cumulativeTierInfo.sharePercent += barWidth;
            }

            const tierShareInfoProps = {
              barWidth,
              buyInShares,
              shareCount,
              isRealTier: index === 0,
              colorCode,
              tierName: customTierName || capitalizeEnum(name!),
              areAllTierSharesSet,
            };

            const TierColorSpread = (
              <div
                className={cn(
                  'h-4 w-full mt-2 border',
                  index === 0 && 'rounded-l-full',
                  index === allTiers.length - 1 && 'rounded-r-full',
                )}
                style={{
                  backgroundColor: colorCode,
                }}
              />
            );

            return (
              <div
                key={customTierName || name!}
                className='flex flex-col relative'
                style={{
                  width: `${barWidth}%`,
                }}
              >
                {barWidth < 15 ? (
                  <div className='flex flex-col'>
                    <Hover
                      hoverComponent={
                        <TierSharesDistributionInfo {...tierShareInfoProps} />
                      }
                      config={{ placement: 'top' }}
                    >
                      {TierColorSpread}
                    </Hover>
                  </div>
                ) : (
                  <div className='flex flex-col'>
                    <TierSharesDistributionInfo {...tierShareInfoProps} />
                    {TierColorSpread}
                  </div>
                )}

                {areTiersValid && (
                  <div className='mt-2 h-2.5'>
                    {index !== 0 && index <= tiers.length ? (
                      <div
                        className={cn(
                          'h-2.5 border-zen-dark-13 border-b-[1.5px] text-white leading-[10px]',
                          index === 1 && 'border-l-[1.5px] rounded-bl-sm',
                          index === tiers.length &&
                            'border-r-[1.5px] rounded-br-sm',
                        )}
                      />
                    ) : (
                      <div className='leading-[11.5px] text-white h-2.5' />
                    )}
                  </div>
                )}
              </div>
            );
          },
        )}
        {areTiersValid && (
          <div className='flex flex-row absolute left-0 right-0 top-[110%] px-1 items-center justify-center text-dark'>
            <div style={{ width: `${realPoolPercentShare}%` }} />
            <div className='flex flex-row items-center'>
              <FontAwesomeIcon icon={faUser} size='sm' className='leading-7' />
              <span className='leading-7 font-zen-title text-xl ml-1'>
                {cumulativeTierInfo.agentCount}
              </span>
            </div>
            <div className='mx-6 leading-6 font-zen-body text-base'>
              {cumulativeTierInfo.sharePercent}%
            </div>
            <div className='leading-6 font-zen-body text-base'>
              {cumulativeTierInfo.shareCount} Shares
            </div>
            <div style={{ width: `${availablePoolShare}%` }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TierDistribution;
