import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { cn } from '../../utils/classUtils';

export interface TierSharesDistributionInfoProps {
  barWidth: number;
  buyInShares?: number;
  shareCount: number;
  isRealTier: boolean;
  colorCode: string;
  tierName: string;
  areAllTierSharesSet: boolean;
}

const TierSharesDistributionInfo: React.FC<TierSharesDistributionInfoProps> = ({
  barWidth,
  buyInShares,
  shareCount,
  isRealTier,
  colorCode,
  tierName,
  areAllTierSharesSet,
}) => {
  return (
    <div
      className={cn(
        'flex',
        {
          'w-44 p-2 box-shadow': barWidth < 15,
        },
        isRealTier ? 'items-center' : 'flex-col',
      )}
    >
      <div className={isRealTier ? 'mr-2' : 'mb-1'}>
        <span className='leading-6 font-zen-title text-zen-dark-9 text-lg'>
          {tierName}
        </span>
      </div>
      {areAllTierSharesSet && (
        <div className='flex flex-row'>
          {!isRealTier && buyInShares && (
            <div
              className='flex flex-row mr-3 items-center'
              style={{ color: colorCode }}
            >
              <span className='leading-6'>
                <FontAwesomeIcon icon={faUser} size='sm' />
              </span>
              <span
                className={cn(
                  'ml-1 leading-7 font-zen-title',
                  barWidth < 15 ? 'text-xl' : 'text-lg',
                )}
              >
                {Math.floor(shareCount / buyInShares)}
              </span>
            </div>
          )}
          <div
            className={cn(
              'leading-6 font-zen-body text-zen-dark-9 mr-1',
              barWidth < 15 ? 'text-base' : 'text-sm',
            )}
          >
            <span>{`${shareCount} Shares`}</span>
          </div>
          <div
            className={cn(
              'leading-6 font-zen-body',
              barWidth < 15 ? 'text-base' : 'text-sm',
            )}
            style={{
              color: colorCode !== '#FFFFFF' ? colorCode : '#000',
            }}
          >
            <span>{barWidth < 15 ? `${barWidth}%` : `(${barWidth}%)`}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TierSharesDistributionInfo;
