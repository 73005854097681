import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import { TransactionResponse } from '../../../openapi/arrakis';
import {
  CommentControllerApi,
  PostCommentBodyReaderRoleEnum,
  RezenObjectTypeEnum,
} from '../../../openapi/yada';
import ErrorService from '../../../services/ErrorService';
import { showErrorToastForErrorCode } from '../../../slices/ToastNotificationSlice';
import { AppDispatch } from '../../../types';
import { getYadaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenControlledRichTextInput from '../Input/ZenControlledRichTextInput';
import useTransactionMentions from '../../../hooks/useTransactionMentions';
import {
  geRichTexttMessage,
  richTextInputValidation,
} from '../../../utils/ReactQuillUtils';
import ZenSimpleConfirmationModal from './ZenSimpleConfirmationModal';

interface TerminateTransactionConfirmationModalProps {
  transaction: TransactionResponse;
  onClose(): void;
  onSubmit(): void;
  isOpen: boolean;
  checklistId: string;
  checklistName: string;
}

interface FormData {
  revisionRequestReason: string;
}
const BrokerPowerAuditConfirmationModal: React.FC<TerminateTransactionConfirmationModalProps> = ({
  onClose,
  onSubmit,
  isOpen,
  checklistId,
  transaction,
  checklistName,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();
  const reactQuillRef = useRef<ReactQuill>(null);
  const { getMentions } = useTransactionMentions();

  const onRevisionRequest = async () => {
    const [comment] = geRichTexttMessage(reactQuillRef, getMentions);
    const commentData = {
      richContent: {
        blocks: comment,
      },
      readerRole: PostCommentBodyReaderRoleEnum.Public,
    };
    try {
      await new CommentControllerApi(getYadaConfiguration()).postComment(
        RezenObjectTypeEnum.Transaction,
        transaction.id!,
        'USER',
        {
          richContent: commentData.richContent,
          readerRole: commentData.readerRole,
        },
        RezenObjectTypeEnum.ChecklistItem,
        checklistId,
        checklistName,
      );
      await onSubmit();
      onClose();
    } catch (e) {
      ErrorService.notify(
        'Unable to notify broker on transaction termination',
        e,
      );
      dispatch(
        showErrorToastForErrorCode(
          'We had a notifying the broker. Please try again later.',
          ErrorService.getErrorCode(e),
        ),
      );
    }
  };

  const setDefaultMention = useCallback(async () => {
    // @ts-ignore
    reactQuillRef.current?.getEditor().setContents('');
    const position = reactQuillRef.current?.getEditor()?.getLength()! - 1;
    const isAllAgentGroupAvaialable = (getMentions('') || [])?.find(
      (mention) => mention?.value === 'All Agents',
    );
    if (isAllAgentGroupAvaialable) {
      reactQuillRef.current?.getEditor().insertEmbed(position, 'mention', {
        emailAddress: isAllAgentGroupAvaialable?.emailAddress,
        id: isAllAgentGroupAvaialable?.id,
        value: isAllAgentGroupAvaialable?.value,
        denotationChar: '@',
      });
      reactQuillRef.current?.getEditor().insertText(position + 1, ' ');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      setDefaultMention();
    }
  }, [isOpen, setDefaultMention]);

  return (
    <ZenSimpleConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      variant='danger'
      customIcon={
        <FontAwesomeIcon icon={faClockRotateLeft} className='text-red-500' />
      }
      title='Request Revision'
      subtitle='Please provide the reason why this item needs revision'
      isSubmitting={isSubmitting}
      confirmButtonText='Request Revision'
      size='large'
      onConfirm={handleSubmit(onRevisionRequest)}
    >
      <div className='py-4'>
        <ZenControlledRichTextInput
          name='revisionRequestReason'
          control={control}
          label='Reason'
          reactQuillRef={reactQuillRef}
          rules={{
            ...richTextInputValidation(reactQuillRef, getMentions),
          }}
          placeholder='Please provide the revision for Agent.'
          getMentions={getMentions}
        />
      </div>
    </ZenSimpleConfirmationModal>
  );
};

export default BrokerPowerAuditConfirmationModal;
