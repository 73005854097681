import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailPageLoader from '../components/DetailPageLoader';
import ResourceContainer from '../components/ResourceContainer';
import GeminiCoverImage from '../components/Gemini/GeminiCoverImage';
import ZenRoute from '../components/Zen/ZenRoute';
import { fetchOfficeChecklistDefinition } from '../slices/ChecklistDefinitionSlice';
import {
  fetchOfficeDetailById,
  fetchOfficeGroups,
} from '../slices/OfficeSlice';
import { RootState } from '../types';
import { cn } from '../utils/classUtils';
import ZenButton from '../components/Zen/ZenButton';
import GeminiOfficeDetailCard from '../components/Gemini/GeminiOfficeDetailCard';
import GeminiOfficeDetailTab from '../components/Gemini/GeminiOfficeDetailTab';
import GeminiUpdateOfficeForm from '../components/office/GeminiUpdateOfficeForm';
import AdminOnly from '../components/auth/AdminOnly';
import GeminiPageLayout from '../components/Gemini/GeminiPageLayout';
import { ZenTab } from '../components/Zen/Tab/ZenRouterTabs';
import ZenRouterTabsSeparatedItems from '../components/Zen/Tab/ZenRouterTabsSeparatedItems';
import ZenOfficeFileCabinetRoute from '../components/Zen/Office/ZenOfficeFileCabinetRoute';
import Route404 from './Route404';

interface Match {
  id: string;
}

interface GeminiOfficeDetailProps extends RouteComponentProps<Match> {}

const GeminiOfficeDetailRoute: React.FC<GeminiOfficeDetailProps> = ({
  match,
}) => {
  const [openEditOfficeForm, setOpenEditOfficeForm] = useState<boolean>(false);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const dispatch = useDispatch();
  const officeId = useMemo(() => match.params.id, [match.params.id]);
  const {
    auth: { isAdmin },
    office: {
      loadingDetail,
      officeDetail,
      fetchDetailErrorCode,
      officeGroupsById,
    },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    const element = document.getElementById('main-page');
    const handleScroll = () => {
      if (element && element.scrollTop > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    dispatch(fetchOfficeDetailById(officeId));
  }, [dispatch, officeId]);

  const fetchOfficeCheckList = useCallback(() => {
    dispatch(fetchOfficeChecklistDefinition(officeId));
  }, [dispatch, officeId]);

  useEffect(() => {
    if (isAdmin) {
      fetchOfficeCheckList();
    }
  }, [fetchOfficeCheckList, isAdmin]);

  const fetchGroups = useCallback(() => {
    if (!officeGroupsById[officeId]) {
      dispatch(fetchOfficeGroups(officeId));
    }
  }, [dispatch, officeGroupsById, officeId]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const officeTabs: ZenTab[] = useMemo(() => {
    const array = [
      {
        label: 'Office Details',
        TabComponent: () => (
          <GeminiOfficeDetailTab officeId={officeId} isSticky={isSticky} />
        ),
        path: `/offices/${officeId}`,
        exact: true,
      },
    ];
    if (officeDetail?.dropboxId) {
      array.push({
        label: 'Documents',
        path: `/offices/${officeId}/documents`,
        exact: true,
        TabComponent: () => (
          <div
            className={cn('w-full', isSticky ? 'lg:px-3' : 'lg:px-6 lg:pb-32')}
          >
            <div className='text-stone-900 text-base font-medium font-inter leading-tight mt-4 md:mt-0'>
              Documents
            </div>
            <ZenOfficeFileCabinetRoute officeId={officeId} />
          </div>
        ),
      });
    }
    return array;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeId, officeDetail?.dropboxId]);

  const tabsPathRegex = useMemo(
    () => `(${officeTabs.map((tab) => tab.path).join('|')})`,
    [officeTabs],
  );

  return (
    <ZenRoute title='Office Details'>
      <ResourceContainer
        loading={loadingDetail}
        isEmpty={!officeDetail}
        errorCode={fetchDetailErrorCode}
        LoaderComponent={<DetailPageLoader />}
        resourceName='office'
      >
        <div className='relative bg-regent-100'>
          <GeminiPageLayout
            path={[
              { title: 'Offices', url: '/offices' },
              {
                title: `${officeDetail?.name}`,
                url: `/offices/${officeDetail?.id}`,
              },
            ]}
            stickyHeader
          >
            <Switch>
              <Route path={match.path}>
                <Switch>
                  <Route path={tabsPathRegex} exact>
                    <div
                      className={cn(
                        'z-0 relative',
                        isSticky
                          ? '-mb-[250px] md:px-4 lg:px-6 2xl:px-8 md:py-6 px-4'
                          : '-mb-[115px]',
                      )}
                    >
                      <AdminOnly>
                        <ZenButton
                          variant='primary-outline-selected'
                          className='absolute top-8 right-4 z-10'
                          label='Edit Office Details'
                          onClick={() => setOpenEditOfficeForm(true)}
                          LeftIconComponent={
                            <FontAwesomeIcon
                              icon={light('pen')}
                              className='h-4 w-4 text-rezen-blue-600'
                            />
                          }
                        />
                      </AdminOnly>
                      <GeminiCoverImage />
                    </div>
                    <div className={isSticky ? 'sticky z-10 top-14' : 'static'}>
                      <div className='relative'>
                        <div className='w-full mt-4 md:mt-0'>
                          <div
                            className={
                              isSticky
                                ? 'shadow-md'
                                : 'md:px-4 lg:px-6 2xl:px-8 md:py-6 px-4 rounded-lg'
                            }
                          >
                            <GeminiOfficeDetailCard
                              officeDetail={officeDetail!}
                              onClickEdit={() => setOpenEditOfficeForm(true)}
                              tabs={officeTabs}
                              isSticky={isSticky}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <ZenRouterTabsSeparatedItems tabs={officeTabs} />
                  </Route>
                  <Route path={`${match.path}/*`} component={Route404} />
                </Switch>
              </Route>
            </Switch>

            {!!officeDetail && openEditOfficeForm && (
              <GeminiUpdateOfficeForm
                isOpen={openEditOfficeForm}
                onClose={() => setOpenEditOfficeForm(false)}
                officeDetails={officeDetail}
              />
            )}
          </GeminiPageLayout>
        </div>
      </ResourceContainer>
    </ZenRoute>
  );
};

export default GeminiOfficeDetailRoute;
