import {
  faClose,
  faExternalLink,
  faShare,
} from '@fortawesome/pro-regular-svg-icons';
import { faCloudDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import config from '../../../../../config';
import { numberToMoney } from '../../../../../utils/CurrencyUtils';
import { getFileBlob } from '../../../../../utils/FileUtils';
import {
  handleLeoListingPosterDownload,
  ListingPosterItem,
} from '../../../../../utils/LeoUtils';
import DefaultLoader from '../../../../DefaultLoader';
import ZenButton from '../../../ZenButton';

type LeoSocialShareableMobileModalProps = {
  closeModal(): void;
  shareable: ListingPosterItem;
};

const LeoSocialShareableMobileModal: React.FC<LeoSocialShareableMobileModalProps> = ({
  closeModal,
  shareable,
}) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownload = async (posterLink: string) => {
    setIsDownloading(true);
    await handleLeoListingPosterDownload(posterLink);
    setIsDownloading(false);
  };

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div className='flex items-center justify-center w-full h-full'>
        <div
          className='z-10 overflow-hidden w-full h-full bg-[#0A0C16]'
          aria-label='simple-modal'
        >
          <div className='h-full w-full flex flex-col justify-between'>
            <div className=''>
              <div className='w-full py-4 px-2 bg-[#171821] flex justify-between items-center h-14'>
                <div className='w-10 h-10 flex items-center justify-center'>
                  <FontAwesomeIcon
                    icon={faClose}
                    className='text-white'
                    onClick={closeModal}
                  />
                </div>
                <p className='text-white text-sm font-inter font-medium text-center'>
                  1 of 4
                </p>
                {!isDownloading ? (
                  <div className='w-10 h-10 flex items-center justify-center'>
                    <FontAwesomeIcon
                      icon={faCloudDownload}
                      className='text-white cursor-pointer'
                      onClick={() => handleDownload(shareable.posterLink)}
                    />
                  </div>
                ) : (
                  <div className='w-10 h-10'>
                    <DefaultLoader iconSize='small' />
                  </div>
                )}
              </div>
              <div className='w-full p-4'>
                <div className=''>
                  <p className='text-grey-500 text-xs font-inter font-medium text-left pb-1'>
                    {shareable.listingDetails.code || 'N/A'}
                  </p>
                  <p className='text-white text-sm leading-5 font-inter font-medium text-left pb-2'>
                    {shareable.listingDetails.address.one_line || 'N/A'}
                  </p>
                  <p className='text-white text-sm font-inter font-medium text-left pb-3'>
                    {numberToMoney(shareable.listingDetails.price?.amount!)}
                  </p>
                </div>
                <div className='flex items-center'>
                  <FontAwesomeIcon
                    icon={faExternalLink}
                    className='text-rezen-blue-600 mr-2'
                  />
                  <p
                    className='text-rezen-blue-600 text-sm font-inter font-medium text-center cursor-pointer'
                    onClick={() =>
                      window.open(
                        `${config.reactAppHostUrl}/listings/${shareable.listingDetails.id}`,
                        '_blank',
                      )
                    }
                  >
                    Open Listing
                  </p>
                </div>
              </div>
              <div className='p-4'>
                <img
                  id='poster-image'
                  src={shareable.posterLink}
                  className='h-full w-full rounded-lg'
                  alt='poster'
                />
              </div>
            </div>
            <div className='w-full p-4 pb-8 bg-[#2F3149]'>
              <ZenButton
                label='Share Image'
                className='font-inter'
                isFullWidth
                onClick={async () => {
                  const fileBlob = await getFileBlob(shareable.posterLink);
                  if (!fileBlob) {
                    return;
                  }
                  navigator.share({
                    title: 'Rezen - Property Poster',
                    text: 'Check out this property poster',
                    url: shareable.posterLink,
                    files: [
                      new File([fileBlob], 'propertyPoster.jpg', {
                        type: fileBlob.type,
                      }),
                    ],
                  });
                }}
                LeftIconComponent={
                  <FontAwesomeIcon icon={faShare} className='mr-1' />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeoSocialShareableMobileModal;
