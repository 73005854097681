import { Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LearnworldsSsoRequest } from '../../openapi/yenta';
import { AppDispatch, RootState } from '../../types';
import useQueryParams from '../../hooks/useQueryParams';
import { signInToLearnworlds } from '../../slices/LearnworldsSlice';
import Logger from '../../utils/Logger';
import DefaultLoader from '../DefaultLoader';

interface Query {
  action: string;
  redirectUrl: string;
}

const LearnworldsSsoRedirect: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);
  let { action, redirectUrl } = useQueryParams<Query>();
  if (!action) {
    action = 'login';
  }

  if (!redirectUrl) {
    redirectUrl = '/';
  }

  useEffect(() => {
    const redirectToRealAcademy = async () => {
      Logger.debug('Learnworlds SSO: Calling endpoint.');

      const req: Required<LearnworldsSsoRequest> = {
        redirectUrl: redirectUrl || '/',
      };

      const response = await dispatch(
        signInToLearnworlds(userDetail?.id!, req),
      );
      const url = response?.data.redirectUrl;

      if (url) {
        Logger.debug('Learnworlds SSO: Redirecting to url:', url);

        window.location.assign(url);
      }
    };

    if (userDetail) {
      redirectToRealAcademy();
    }
  }, [dispatch, redirectUrl, userDetail]);

  if (!userDetail) {
    let url = '/login';
    const query = {
      redirectTo:
        '/learnworlds/sso?redirectUrl=' +
        encodeURIComponent(redirectUrl) +
        '&action=' +
        action,
    };
    const params = new URLSearchParams(query);
    url += '?' + params.toString();

    Logger.debug('Learnworlds SSO: Not logged in. Redirecting to url:', url);

    return <Redirect to={url} />;
  }

  return <DefaultLoader />;
};

export default LearnworldsSsoRedirect;
