import {
  faArrowsLeftRight,
  faCabinetFiling,
  faCalendar,
  faCheck,
  faCloudArrowDown,
  faComment,
  faCommentDots,
  faDollarSign,
  faEllipsis,
  faEnvelopeCircleCheck,
  faFileCertificate,
  faFileCheck,
  faHandshake,
  faHouseChimney,
  faMoneyCheckDollar,
  faPen,
  faPenToSquare,
  faPercentage,
  faRectangleList,
  faSackDollar,
  faTag,
  faThumbsUp,
  faTrash,
  faTrashCan,
  faUpload,
  faUsers,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { ReactComponent as ChecklistItemAdded } from '../../../assets/img/activity/checklist_item_added.svg';
import { ReactComponent as JourneyItemIncomplete } from '../../../assets/img/activity/journey_item_incomplete.svg';
import { ActivityType, EnumMap } from '../../../types';
import { getActivityTypeDisplayName } from '../../../utils/ActivityUtils';
import ActivityICon from './ActivityIcon';

interface ActivityTypeDisplayCellProps {
  type: string;
}

const ActivityTypeDisplayCell: React.FC<ActivityTypeDisplayCellProps> = ({
  type,
}) => {
  const activityTypeToIconMap: EnumMap<ActivityType, React.ReactNode> = {
    JOURNEY_ITEM_COMPLETED: <ActivityICon icon={faRectangleList} />,
    JOURNEY_ITEM_INCOMPLETE: <JourneyItemIncomplete fontSize={18} />,
    CHECKLIST_ITEM_UPDATED: <ActivityICon icon={faPen} />,
    CHECKLIST_ITEM_ADDED: <ChecklistItemAdded fontSize={18} />,
    CHECKLIST_ITEM_DELETED: <ActivityICon icon={faTrash} color='danger' />,
    CHECKLIST_ITEM_LABEL_ADDED: <ActivityICon icon={faTag} />,
    CHECKLIST_ITEM_LABEL_REMOVED: <ActivityICon icon={faTag} />,
    CHECKLIST_ITEM_NAME_UPDATED: <ActivityICon icon={faTag} />,
    CHECKLIST_ITEM_DESCRIPTION_UPDATED: <ActivityICon icon={faEllipsis} />,
    DOCUMENT_UPLOADED: <ActivityICon icon={faCloudArrowDown} />,
    DOCUMENT_DELETED: <ActivityICon icon={faTrash} color='danger' />,
    DOCUMENT_ATTACHED: <ActivityICon icon={faCabinetFiling} />,
    DOCUMENT_REMOVED: <ActivityICon icon={faCabinetFiling} />,
    NEW_VERSION_UPLOADED: <ActivityICon icon={faUpload} />,
    DROPBOX_RECEIVED_EMAIL: <ActivityICon icon={faEnvelopeCircleCheck} />,
    DROPBOX_FILE_RECEIVED: <ActivityICon icon={faCabinetFiling} />,
    DROPBOX_FILE_UPLOADED: <ActivityICon icon={faCabinetFiling} />,
    DROPBOX_FILE_VERSION_ADDED: <ActivityICon icon={faCabinetFiling} />,
    DROPBOX_FILE_DELETED: <ActivityICon icon={faCabinetFiling} />,
    DROPBOX_FILE_NAME_UPDATED: <ActivityICon icon={faCabinetFiling} />,
    DROPBOX_FILE_FROM_REAL_SIGNATURE: <ActivityICon icon={faCabinetFiling} />,
    COMMENT_CREATED: <ActivityICon icon={faComment} />,
    COMMENT_EDITED: <ActivityICon icon={faPenToSquare} />,
    COMMENT_DELETED: <ActivityICon icon={faTrashCan} />,
    COMMENT_READ: <ActivityICon icon={faCommentDots} />,
    COMMISSION_PAYER_ASSIGNED: <ActivityICon icon={faUsers} />,
    PAYMENT_PARTICIPANT_ADDED: <ActivityICon icon={faUsers} />,
    PAYMENT_PARTICIPANT_REMOVED: <ActivityICon icon={faUsers} />,
    OTHER_PARTICIPANT_ADDED: <ActivityICon icon={faUsers} />,
    OTHER_PARTICIPANT_REMOVED: <ActivityICon icon={faUsers} />,
    TRANSACTION_FEE_ADDED: <ActivityICon icon={faDollarSign} />,
    TRANSACTION_FEE_REMOVED: <ActivityICon icon={faDollarSign} />,
    TRANSACTION_ADDRESS_UPDATED: <ActivityICon icon={faHouseChimney} />,
    TRANSACTION_YEAR_BUILT_UPDATED: <ActivityICon icon={faHouseChimney} />,
    TRANSACTION_MLS_NUMBER_UPDATED: <ActivityICon icon={faHouseChimney} />,
    TRANSACTION_ESCROW_NUMBER_UPDATED: <ActivityICon icon={faHouseChimney} />,
    TRANSACTION_SALE_PRICE_UPDATED: <ActivityICon icon={faHouseChimney} />,
    TRANSACTION_LISTING_COMMISSION_UPDATED: <ActivityICon icon={faCalendar} />,
    TRANSACTION_SALE_COMMISSION_UPDATED: <ActivityICon icon={faCalendar} />,
    TRANSACTION_CONTRACT_ACCEPTANCE_DATE_UPDATED: (
      <ActivityICon icon={faCalendar} />
    ),
    TRANSACTION_ACTUAL_CLOSING_DATE_UPDATED: <ActivityICon icon={faCalendar} />,
    TRANSACTION_ESTIMATED_CLOSING_DATE_UPDATED: (
      <ActivityICon icon={faCalendar} />
    ),
    TRANSACTION_CREATED: <ActivityICon icon={faCheck} color='success' />,
    LISTING_CREATED: <ActivityICon icon={faCheck} color='success' />,
    REFERRAL_CREATED: <ActivityICon icon={faHandshake} color='success' />,
    TRANSACTION_CLOSED: <ActivityICon icon={faCheck} color='success' />,
    TRANSACTION_APPROVED_FOR_CLOSING: (
      <ActivityICon icon={faCheck} color='success' />
    ),
    TRANSACTION_PAYMENT_ACCEPTED: (
      <ActivityICon icon={faCheck} color='success' />
    ),
    TRANSACTION_PAYMENT_SCHEDULED: (
      <ActivityICon icon={faCheck} color='success' />
    ),
    TRANSACTION_SETTLED: <ActivityICon icon={faCheck} color='success' />,
    COMMISSION_VALIDATED: <ActivityICon icon={faFileCertificate} />,
    COMMISSION_DOCUMENT_GENERATED: <ActivityICon icon={faFileCheck} />,
    COMMISSION_DOCUMENT_APPROVED: <ActivityICon icon={faThumbsUp} />,
    COMMISSION_DOCUMENT_SENT: <ActivityICon icon={faEnvelopeCircleCheck} />,
    TRANSACTION_PARTICIPANT_UPDATED: <ActivityICon icon={faUsers} />,
    TRUST_DEPOSIT_SUBMITTED: <ActivityICon icon={faSackDollar} />,
    TRUST_DEPOSIT_INSTALLMENT_SUBMITTED: <ActivityICon icon={faSackDollar} />,
    TRUST_DEPOSIT_INSTALLMENT_CONFIRMED: <ActivityICon icon={faSackDollar} />,
    COMMISSION_DEPOSIT_SUBMITTED: <ActivityICon icon={faSackDollar} />,
    COMMISSION_DEPOSIT_INSTALLMENT_SUBMITTED: (
      <ActivityICon icon={faSackDollar} />
    ),
    COMMISSION_DEPOSIT_INSTALLMENT_CONFIRMED: (
      <ActivityICon icon={faSackDollar} />
    ),
    EXCESS_FUNDS_RELEASED: <ActivityICon icon={faSackDollar} />,
    TRANSACTION_TERMINATED: <ActivityICon icon={faXmark} color='danger' />,
    DOUBLE_ENDER_TRANSACTION_LINKED: <ActivityICon icon={faArrowsLeftRight} />,
    DOUBLE_ENDER_TRANSACTION_UNLINKED: (
      <ActivityICon icon={faArrowsLeftRight} />
    ),
    TRANSACTION_TAX_EXEMPT_UPDATED: <ActivityICon icon={faPercentage} />,
    TRANSACTION_TAX_PAID_FROM_COMMISSION_UPDATED: (
      <ActivityICon icon={faPercentage} />
    ),
    TRANSACTION_TAX_RATES_UPDATED: <ActivityICon icon={faPercentage} />,
    TRANSACTION_TAX_AMOUNT_UPDATED: <ActivityICon icon={faPercentage} />,
    TRANSACTION_TERMINATION_REQUESTED: (
      <ActivityICon icon={faXmark} color='danger' />
    ),
    TRANSACTION_FIRM_DATE_UPDATED: <ActivityICon icon={faCalendar} />,
    'Payment Settings Updated': <ActivityICon icon={faMoneyCheckDollar} />,
  };

  const getActivityTypeIcon = activityTypeToIconMap[type as ActivityType] ?? (
    <ActivityICon icon={faEllipsis} />
  );

  return (
    <div className='flex flex-row items-center'>
      <span className='w-5 h-5'>{getActivityTypeIcon}</span>
      <p className='font-zen-body font-normal text-sm leading-5 text-zen-dark-9 ml-2 whitespace-pre'>
        {getActivityTypeDisplayName(type as ActivityType)}
      </p>
    </div>
  );
};

export default ActivityTypeDisplayCell;
