import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AgentTeamMembershipResponse } from '../../../openapi/yenta';
import ZenSideBarItem from '../../ZenSideBarItem';

interface ZenUserTeamsSidebarMenuItemProps {
  teamMemberships: AgentTeamMembershipResponse[];
  isCollapsed: boolean;
}

const ZenUserTeamsSidebarMenuItem: React.FC<ZenUserTeamsSidebarMenuItemProps> = ({
  teamMemberships,
  isCollapsed,
}) => {
  if (teamMemberships.length === 0) {
    return null;
  }

  if (teamMemberships.length === 1) {
    return (
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={regular('users')} />}
        label='My Team'
        linkTo={`/teams/${teamMemberships[0].team!.id!}`}
        collapsed={isCollapsed}
      />
    );
  }

  return (
    <ZenSideBarItem
      icon={<FontAwesomeIcon icon={regular('users')} />}
      label='Teams'
      linkTo='/teams'
      collapsed={isCollapsed}
    />
  );
};

export default ZenUserTeamsSidebarMenuItem;
