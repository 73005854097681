import React from 'react';
import { cn } from '../../utils/classUtils';
import TierDiamond from './TierDiamond';

export type ShadeVariant = 'silver' | 'gold' | 'platinum';
export interface TierMembershipCardProps {
  tierType: ShadeVariant;
  label: string;
  subLabel?: string;
  wrap?: boolean;
}

const TierMembershipCard: React.FC<TierMembershipCardProps> = ({
  tierType,
  label,
  subLabel,
  wrap = false,
}) => {
  const backgroundVariantClassMap: Record<ShadeVariant, string> = {
    silver:
      'radial-gradient(82.57% 102.45% at 22.22% 9.74%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(84.79deg, #858D8F 2.91%, #9FA2A1 19.98%, #858B8B 36.57%, #808587 50.23%, #768286 75.1%, #838C8E 84.37%, #768286 96.56%)',
    gold:
      'radial-gradient(100.11% 101.99% at 26.57% -6.72%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(84.79deg, #A47C4E 2.91%, #F1D2AC 19.98%, #B48C5C 36.57%, #A47C4E 50.23%, #A47C4E 75.1%, #C99456 84.37%, #A47C4E 96.56%)',
    platinum:
      'radial-gradient(82.57% 102.45% at 22.22% 9.74%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(84.79deg, #576266 2.91%, #9FA2A1 19.98%, #858B8B 36.57%, #667377 50.23%, #6A797E 75.1%, #838C8E 84.37%, #576266 96.56%)',
  };

  const textVariantClassMap: Record<ShadeVariant, string> = {
    silver: 'text-grey-600',
    gold: 'text-yellow-900',
    platinum: 'text-grey-600',
  };

  return (
    <div
      className={cn(
        'flex items-center justify-center rounded-lg shadow-md box-content',
        wrap
          ? 'py-8 px-10 flex-col max-w-[380px] max-h-[223px]'
          : 'flex-row p-12 max-w-[390px] max-h-[155px]',
      )}
      style={{
        backgroundImage: backgroundVariantClassMap[tierType],
      }}
    >
      <TierDiamond tierType={tierType} size={wrap ? 'medium' : 'small'} />
      <div
        className={cn(
          'flex flex-col justify-between',
          wrap ? 'mt-8 text-center' : 'ml-4',
        )}
      >
        <text
          className={cn(
            'font-zen-title ',
            wrap
              ? 'text-[32px] leading-[38px] mb-2'
              : 'text-[22px] leading-[33px] mb-0.5',
            textVariantClassMap[tierType],
          )}
        >
          {label}
        </text>
        <text
          className={cn(
            'font-zen-body font-thin',
            wrap ? 'text-lg' : 'text-base leading-6',
            textVariantClassMap[tierType],
          )}
        >
          {subLabel}
        </text>
      </div>
    </div>
  );
};
export default TierMembershipCard;
