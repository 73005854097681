import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';

export const useRefresh = () => {
  const [key, setKey] = useState<number>(DateTime.local().millisecond);

  const refresh = useCallback(() => setKey(DateTime.local().millisecond), []);

  return { key, refresh };
};
