import React from 'react';
import ZenPopover from '../../../ZenPopover';

interface RealBEOPFeePopoverProps {}

const RealBEOPFeePopover: React.FC<RealBEOPFeePopoverProps> = () => {
  return (
    <div className='font-inter text-sm text-zen-dark-9'>
      Real BEOP Fee
      <ZenPopover
        title='Real BEOP Fee'
        description={
          <div className='text-zen-dark-9 text-base font-light'>
            <p>
              A <span className='font-bold'>$30 BEOP fee</span> per transaction,
              which covers broker review, E&O (professional liability)
              insurance, and processing will be assessed on every transaction in
              the United States the company processes.
            </p>
            <p className='mt-4'>
              Select the default percentage split for BEOP Fee between Team
              Leader and Teammates (or members within your team).
            </p>
          </div>
        }
      />
    </div>
  );
};

export default RealBEOPFeePopover;
