import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../types';
import { getAgentName } from '../../utils/AgentUtils';
import ZenUserPill from '../Zen/ZenUserPill';

interface LQMCreatedByCellProps {
  userId: string;
}

const LQMCreatedByCell: React.FC<LQMCreatedByCellProps> = ({ userId }: any) => {
  const {
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  const agentData = useMemo(() => {
    const agent = agentById[userId];

    return {
      name: getAgentName(agent!),
      avatar: agent?.avatar,
    };
  }, [agentById, userId]);

  const userPill = (
    <ZenUserPill
      name={agentData.name}
      imageUrl={agentData.avatar}
      backgroundVariant='background'
      noWrap
    />
  );

  return (
    <div
      className='w-max flex flex-row space-x-2 items-center'
      data-testid='lqm-createdBy-cell'
    >
      {userId ? (
        <Link to={`/people/${userId}`} target='_blank'>
          {userPill}
        </Link>
      ) : (
        <>{userPill}</>
      )}
    </div>
  );
};

export default LQMCreatedByCell;
