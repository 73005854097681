import classNames from 'classnames';
import pluralize from 'pluralize';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  TeamMemberResponseRolesEnum,
  TeamResponse,
} from '../../../openapi/yenta';
import { FeatureFlagTypeEnum, RootState } from '../../../types';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { getTeamMembersTotalCount } from '../../../utils/TeamHelper';

interface ZenTeamsNameCellProps {
  team: TeamResponse;
}

const ZenTeamsNameCell: React.FC<ZenTeamsNameCellProps> = ({ team }) => {
  const { isAdmin, userDetail } = useSelector((state: RootState) => state.auth);

  const teamMember = team?.agents?.find(
    (agent) => agent.agent?.id === userDetail?.id,
  );

  const isUserTeamLeader = teamMember?.roles?.includes(
    TeamMemberResponseRolesEnum.Leader,
  );

  const isUserTeamAdmin = teamMember?.roles?.includes(
    TeamMemberResponseRolesEnum.Admin,
  );

  const isSelfServiceFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.SELF_SERVICE_TEAM,
  );

  const isAdminOrTeamLeader = isAdmin || isUserTeamAdmin || isUserTeamLeader;

  const totalMemberCount = getTeamMembersTotalCount(
    false,
    isSelfServiceFlagEnabled && !!isAdminOrTeamLeader,
    team,
  );

  return (
    <div className={classNames('md:space-y-1')}>
      <div
        className={classNames(
          'font-zen-body font-normal text-sm text-zen-dark-9 ',
        )}
      >
        {team?.name}
      </div>
      {team.agents && (
        <div
          className={classNames(
            'text-sm font-zen-body font-medium text-zen-dark-6',
          )}
        >
          {totalMemberCount} {pluralize('Member', totalMemberCount)}
        </div>
      )}
    </div>
  );
};

export default ZenTeamsNameCell;
