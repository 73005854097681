import React from 'react';

interface CACommissionDepositReceiptFooterNoteProps {}

const CACommissionDepositReceiptFooterNote: React.FC<CACommissionDepositReceiptFooterNoteProps> = () => {
  return (
    <div className='flex flex-col pt-20 text-base'>
      <em>
        <span className='font-primary-bold'>NOTE:</span> In the event conditions
        in your Agreement of Purchase and Sale are not met and a Mutual Release
        directs return of deposit, funds will be returned as follows:
      </em>
      <div className='flex flex-col px-14'>
        <em>
          Wire transfers and bank drafts require 5 business days to be returned,
        </em>
        <em>Personal Cheques require 21 business days to be returned.</em>
      </div>
      <em>
        All return of deposits are done by EFT. Please provide the bank
        information for the return of deposit at the time of the fully executed
        Mutual Release
      </em>
    </div>
  );
};

export default CACommissionDepositReceiptFooterNote;
