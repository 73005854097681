import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useFormContext } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { BulkUpdateRequest, LeoQuestionManagerApi } from '../../openapi/leo';
import { LeoTableHeaderFormData } from '../../routes/LeoQuestionManagerRoute';
import ErrorService from '../../services/ErrorService';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { RootState } from '../../types';
import { ActionType } from '../../utils/LeoUtils';
import { getLeoConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';
import ZenAvatar from '../Zen/ZenAvatar';
import ZenDropdown from '../Zen/ZenDropdown';
import ZenIconCircle from '../Zen/ZenIconCircle';

export interface LQMBulkAssignmentProps {
  refresh(): void;
}

const LQMBulkAssignment: React.FC<LQMBulkAssignmentProps> = ({ refresh }) => {
  const {
    auth: { userDetail },
    userIds: { agentById },
    leo: { assigneeUserIds },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const { watch } = useFormContext<LeoTableHeaderFormData>();
  const { selectedPairIds } = watch();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [bulkAssigneeId, setBulkAssigneeId] = React.useState<string>('');

  const handleBulkAssignment = async () => {
    try {
      setIsSubmitting(true);
      const bulkAssignmentRequest: BulkUpdateRequest = {
        pair_ids: selectedPairIds,
        action: ActionType.Assign,
        assignee_id: bulkAssigneeId,
        updated_by: userDetail?.id!,
      };
      await new LeoQuestionManagerApi(
        getLeoConfiguration(),
      ).bulkUpdateLqmQapairBulkPatch(bulkAssignmentRequest);
      setIsOpen(false);
      refresh();
    } catch (error) {
      ErrorService.notify('Unable to assign the questions', error, {
        selectedPairIds,
      });
      dispatch(
        showErrorToast(
          'We had a problem assigning the questions',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  const assigneeItems = [
    {
      text: 'Me',
      onClick: () => {
        setIsOpen(true);
        setBulkAssigneeId(userDetail?.id!);
      },
      icon: (
        <ZenAvatar
          name='Me'
          size='xs'
          colorClassName='bg-zen-blue-1 text-white'
          imageUrl={agentById[userDetail?.id!]?.avatar}
        />
      ),
    },
    ...assigneeUserIds
      .filter((userId) => userId !== userDetail?.id)
      .map((assigneeId: string) => {
        return {
          text: `${agentById[assigneeId]?.firstName!} ${agentById[assigneeId]
            ?.lastName!}`,
          onClick: () => {
            setIsOpen(true);
            setBulkAssigneeId(assigneeId);
          },
          icon: (
            <ZenAvatar
              name={`${agentById[assigneeId]?.firstName!} ${agentById[
                assigneeId
              ]?.lastName!}`}
              size='xs'
              colorClassName='bg-zen-blue-1 text-white'
              imageUrl={agentById[assigneeId]?.avatar}
            />
          ),
        };
      }),
  ];

  return (
    <>
      <ZenDropdown
        containerStyle={{ minWidth: '10rem' }}
        title='Select Assignee'
        items={assigneeItems}
        icon={
          <ZenIconCircle
            icon={
              <FontAwesomeIcon
                icon={regular('user-plus')}
                className='text-zen-dark-8'
                aria-label='assign-selected-questions'
              />
            }
            variant='gray'
            size='xs'
          />
        }
      />
      {isOpen && (
        <ZenConfirmationModal
          isOpen={isOpen}
          title='Are you sure want to assign selected questions?'
          confirmButtonText='Confirm'
          hideIcon
          subtitle='Once confirm, questions will be assigned selected assignee.'
          onClose={() => setIsOpen(false)}
          onConfirm={handleBulkAssignment}
          isSubmitting={isSubmitting}
          isDisabled={isSubmitting}
        />
      )}
    </>
  );
};

export default LQMBulkAssignment;
