import React from 'react';
import SidebarModal from '../../../SidebarModal';

interface AddRealParticipantProps {
  isOpen: boolean;
  onClose(): void;
}

const RealTitleInformation: React.FC<AddRealParticipantProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <SidebarModal
      title='Real Title'
      subtitle='Tech-driven multi-state title company'
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='px-4'>
        <div className='flex justify-between items-center mt-6'>
          <p className='font-primary-medium text-base text-black mb-3'>
            What is Real Title?
          </p>
        </div>
        <p className='font-primary-regular text-base text-black mb-6'>
          Real Title is the tech-driven title company that has built technology
          to save our in-house agents valuable time in the closing. You will
          experience the technology, which aims on bringing more transparency,
          communication and accountability between agents, brokers, buyers and
          sellers.
        </p>
        <p className='font-primary-regular text-base text-black mb-6'>
          With our help agents can concentrate on enhancing their client-agent
          experience and minimize time spent on transaction management.
        </p>

        {/* <p>
          Click here to get acquainted with our services{' '}
          <a
            href='https://therealtitle.com'
            target='_blank'
            rel='noreferrer noopener'
            className='text-primary underline cursor-pointer'
          >
            {' '}
            therealtitle.com
          </a>
        </p> */}
      </div>
    </SidebarModal>
  );
};

export default RealTitleInformation;
