import { faHandshake, faEye } from '@fortawesome/pro-solid-svg-icons';
import React, { useMemo, useState } from 'react';
import ZenSidebarModal from '../../components/Zen/ZenSidebarModal';
import StepByStepContainer, {
  StepConfig,
} from '../../components/StepByStep/StepByStepContainer';
import { GooglePlaceLocationType } from '../../components/ControlledGoogleAutocompleteSearchInputV7';
import { ISelectOption } from '../../types';
import ReferralDetailStep from './ReferralDetailStep';
import ReferralReviewStep from './ReferralReviewStep';
import SimpleStepProgress from './components/SimpleStepProgress';
import ReferralCreatedConfirmationModal from './ReferralCreatedConfirmationModal';
import { ReferralStepsFormState } from './ReferralUtils';

interface CreateReferralProps {
  isOpen: boolean;
  onClose(): void;
}

export enum CreateReferralStepName {
  REFERRAL_DETAILS = 'REFERRAL_DETAILS',
  REVIEW = 'REVIEW',
}

export interface RefAddress {
  address: GooglePlaceLocationType;
  distance: ISelectOption;
}

const CreateReferral: React.FC<CreateReferralProps> = ({ isOpen, onClose }) => {
  const defaultValues: ReferralStepsFormState = {
    referralAddress: [
      {
        address: {
          formatted_address: '',
          geometry: {
            location: {
              lat: undefined,
              lng: undefined,
            },
          },
          place_id: '',
        },
        distance: {
          label: '5 miles',
          value: '5',
        },
      },
    ],
  };

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const steps: StepConfig<ReferralStepsFormState, CreateReferralStepName>[] = [
    {
      name: CreateReferralStepName.REFERRAL_DETAILS,
      Component: (props) => <ReferralDetailStep {...props} onClose={onClose} />,
      hidePagination: true,
    },
    {
      name: CreateReferralStepName.REVIEW,
      Component: (props) => <ReferralReviewStep {...props} onClose={onClose} />,
      hidePagination: true,
    },
  ];

  const stepToIcon = {
    [CreateReferralStepName.REFERRAL_DETAILS]: faHandshake,
    [CreateReferralStepName.REVIEW]: faEye,
  };

  const [stepCount, setStepCount] = useState<number>(0);

  const totalSteps = useMemo(() => {
    return steps.length;
  }, [steps.length]);

  const onSubmit = () => {
    setIsConfirmModalOpen(true);
  };

  const onStepChange = (step: any) => {
    const index = steps.findIndex((s) => s.name === step.name);
    setStepCount(index);
  };

  return (
    <ZenSidebarModal title='Create Referral' isOpen={isOpen} onClose={onClose}>
      <SimpleStepProgress
        currentStep={stepCount}
        totalSteps={totalSteps}
        subtitle='Complete all the steps to finish sending a Referral'
        title={steps[stepCount].name}
        icon={stepToIcon[steps[stepCount].name]}
      />
      <StepByStepContainer<ReferralStepsFormState, CreateReferralStepName>
        steps={steps}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        reValidateMode='onChange'
        mode='onChange'
        pageStyle='relative h-full w-full'
        onChangeStep={(step) => onStepChange(step)}
      />

      {isConfirmModalOpen && (
        <ReferralCreatedConfirmationModal
          isOpen={isConfirmModalOpen}
          onClose={() => {
            setIsConfirmModalOpen(false);
            onClose();
          }}
        />
      )}
    </ZenSidebarModal>
  );
};

export default CreateReferral;
