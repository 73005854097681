import React from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '../../IconButton';

interface ZenPowerAuditActionButtonCellProps {
  onClick?(): void;
}

const ZenPowerAuditActionButtonCell: React.FC<ZenPowerAuditActionButtonCellProps> = ({
  onClick,
}) => {
  return (
    <IconButton
      buttonStyle='border border-primary-blue bg-primary-blue rounded-lg'
      variant='none'
      leftIcon={
        <FontAwesomeIcon
          icon={icon({ name: 'square-bolt', family: 'sharp', style: 'solid' })}
          size='lg'
          className='h-6 w-6 text-white'
          aria-label='power-audit-link'
        />
      }
      onClick={onClick}
    />
  );
};

export default ZenPowerAuditActionButtonCell;
