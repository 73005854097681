import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faCommentDots, faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { ReactComponent as ZeroDocIcon } from '../../../assets/img/emptydocs.svg';
import { ReactComponent as PdfImage } from '../../../assets/img/pdf.svg';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { FileApi, FileResponse } from '../../../openapi/dropbox';
import {
  DocumentDto,
  ItemApi,
  ItemResponse,
  ItemResponseStatusEnum,
} from '../../../openapi/sherlock';
import ErrorService from '../../../services/ErrorService';
import {
  downloadChecklistDocument,
  fetchChecklistItemById,
} from '../../../slices/CheckListSlice';
import { downloadDropboxFileByVersion } from '../../../slices/DropboxSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { AppDispatch, FeatureFlagTypeEnum, RootState } from '../../../types';
import { getChecklistsWithFileReferences } from '../../../utils/ChecklistUtils';
import { cn } from '../../../utils/classUtils';
import {
  getDropboxConfiguration,
  getSherlockConfiguration,
} from '../../../utils/OpenapiConfigurationUtils';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';

interface ZenChecklistDocumentPopperProps {
  docs: DocumentDto[];
  dropboxDocs: FileResponse[];
  commentCount: number;
  isChecklistV2: boolean;
  item: ItemResponse;
  checklistId: string;
  openEditChecklistItemForm(): void;
}

const ZenChecklistDocumentPopper: React.FC<ZenChecklistDocumentPopperProps> = ({
  docs,
  dropboxDocs,
  commentCount,
  isChecklistV2,
  item,
  checklistId,
  openEditChecklistItemForm,
}) => {
  const {
    checklist: { checklistsById },
  } = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fileToDelete, setFileToDelete] = useState<FileResponse | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const docsCount = docs?.length || 0;
  const dropboxDocsCount = dropboxDocs?.length || 0;
  const totalDocsCount = isChecklistV2 ? dropboxDocsCount : docsCount;
  const isDeletingAllowed = item.status !== ItemResponseStatusEnum.Accepted;

  const isDropboxFilesTrashingEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.ARCHIVE_CHECKLIST_DROPBOX_FILES,
  );
  const handleDownloadFile = async (checklistDocument: DocumentDto) => {
    await dispatch(
      downloadChecklistDocument(checklistDocument.currentVersion?.id!),
    );
  };

  const handleDownloadDropboxFile = async (dropboxFile: FileResponse) => {
    await dispatch(
      downloadDropboxFileByVersion(
        dropboxFile.id!,
        dropboxFile.currentVersion?.id!,
      ),
    );
  };

  const handleDeleteDropboxFile = async () => {
    setIsDeleting(true);
    try {
      const checklistItems = checklistsById[checklistId]?.data?.items || [];
      let attachedChecklistArray: ItemResponse[] = getChecklistsWithFileReferences(
        checklistItems,
        fileToDelete?.id!,
      );

      const fileReferences = [
        {
          fileId: fileToDelete?.id!,
          filename: fileToDelete?.filename!,
        },
      ];

      await new ItemApi(getSherlockConfiguration()).removeFileReferences(
        item.id!,
        {
          references: fileReferences,
        },
      );

      // If the file is attached to less than 2 checklists, we can archive it from dropbox
      if (attachedChecklistArray.length < 2) {
        await new FileApi(getDropboxConfiguration()).moveFileToTrash(
          fileToDelete?.id!,
        );
      }

      await dispatch(fetchChecklistItemById(checklistId, item?.id!));

      dispatch(showSuccessToast('Document moved to archive successfully'));
    } catch (error) {
      ErrorService.notify('Unable to archive a file', error, {
        file: { fileToDelete },
      });
      dispatch(
        showErrorToast(
          'We had a problem archiving the document',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setIsDeleting(false);
      setFileToDelete(null);
    }
  };

  return (
    <div
      onMouseLeave={() => setIsOpen(false)}
      className='flex items-center'
      data-testid='document-popover'
    >
      <div
        className='mr-2 flex items-center'
        onClick={openEditChecklistItemForm}
      >
        <FontAwesomeIcon icon={faCommentDots} className='text-zen-dark-7' />
        <div
          className='text-zen-dark-7 ml-1'
          data-testid={`${commentCount} Comments`}
        >
          {commentCount}
        </div>
      </div>
      <Popover
        isOpen={isOpen}
        positions={['top']}
        onClickOutside={() => setIsOpen(false)}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor='white'
            arrowSize={10}
            arrowStyle={{
              bottom: 1,
              zIndex: 1,
            }}
            className='popover-arrow-container'
            arrowClassName='popover-arrow'
          >
            <div
              className={cn(
                'border rounded-[30px] border-zen-dark-5 bg-white divide-y-2 space-y-2 px-6 py-2 text-dark shadow-2xl',
                docsCount > 0 || dropboxDocsCount > 0
                  ? 'md:w-[470px] w-[400px]'
                  : 'md:w-[350px] w-[350px]',
              )}
              data-testid='document-popover-list'
            >
              {!isChecklistV2 && docsCount > 0 && (
                <div className='px-2 py-2'>
                  {docs?.map((doc) => {
                    return (
                      <div
                        key={doc.id}
                        className='flex items-start py-2'
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className='min-w-[32px] min-h-[32px] flex justify-center items-center rounded-md border border-zen-dark-5'>
                          <PdfImage fontSize={16} className='text-zen-dark-5' />
                        </div>
                        <div className='leading-tight mx-3'>
                          <div className='text-base break-all font-zen-body font-semibold text-zen-dark-9'>
                            {doc.name}
                          </div>
                          <div className='flex items-center text-sm space-x-1 font-zen-body font-semibold text-zen-dark-5'>
                            <div>Version {doc.currentVersion?.number} |</div>
                            <div>
                              Uploaded:{' '}
                              {DateTime.fromMillis(
                                doc.currentVersion?.createdAt!,
                              ).toLocaleString(DateTime.DATETIME_SHORT)}
                            </div>
                          </div>
                        </div>
                        <button
                          className='ml-auto bg-zen-light-gray-2 hover:bg-zen-dark-4 rounded-full min-w-[35px] min-h-[35px] w-[35px] h-[35px] flex items-center justify-center'
                          onClick={() => handleDownloadFile(doc)}
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            className='text-dark-8 text-lg'
                            title='View'
                          />
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}
              {isChecklistV2 && dropboxDocsCount > 0 && (
                <div className='px-2 py-2'>
                  {dropboxDocs?.map((doc) => {
                    return (
                      <div
                        key={doc.id}
                        className='flex items-start py-2'
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className='min-w-[32px] min-h-[32px] flex justify-center items-center rounded-md border border-zen-dark-5'>
                          <PdfImage fontSize={16} className='text-zen-dark-5' />
                        </div>
                        <div className='leading-tight mx-3'>
                          <div className='text-base break-all font-zen-body font-semibold text-zen-dark-9'>
                            {doc.filename}
                          </div>
                          <div className='flex items-center text-sm space-x-1 font-zen-body font-semibold text-zen-dark-5'>
                            <div>Version {doc.currentVersion?.version} |</div>
                            <div>
                              Uploaded:{' '}
                              {DateTime.fromMillis(
                                doc.currentVersion?.createdAt!,
                              ).toLocaleString(DateTime.DATETIME_SHORT)}
                            </div>
                          </div>
                        </div>
                        <button
                          className='ml-auto bg-zen-light-gray-2 hover:bg-zen-dark-4 rounded-full min-w-[35px] min-h-[35px] w-[35px] h-[35px] flex items-center justify-center'
                          onClick={() => handleDownloadDropboxFile(doc)}
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            className='text-dark-8 text-lg'
                            title='View'
                          />
                        </button>
                        {isChecklistV2 &&
                          isDropboxFilesTrashingEnabled &&
                          isDeletingAllowed && (
                            <button
                              className='ml-1 bg-zen-light-gray-2 hover:bg-zen-dark-4 rounded-full min-w-[35px] min-h-[35px] w-[35px] h-[35px] flex items-center justify-center'
                              onClick={() => {
                                setIsOpen(false);
                                setFileToDelete(doc);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={regular('box-archive')}
                                title='archive'
                                className='text-zen-danger text-sm'
                              />
                            </button>
                          )}
                      </div>
                    );
                  })}
                </div>
              )}
              {((!isChecklistV2 && !docsCount) ||
                (isChecklistV2 && !dropboxDocsCount)) && (
                <div className='grid justify-center'>
                  <div className='pt-4 grid justify-center'>
                    <ZeroDocIcon />
                  </div>
                  <div className='text-zen-dark-12 text-sm font-zen-body font-semibold pb-6 pt-1'>
                    No documents uploaded yet.
                  </div>
                </div>
              )}
            </div>
          </ArrowContainer>
        )}
      >
        <div
          onMouseEnter={() => setIsOpen(true)}
          className='cursor-pointer flex items-center'
          id={`document-count-${totalDocsCount}`}
          data-testid={`document-count-${totalDocsCount}`}
        >
          <FontAwesomeIcon
            icon={faPaperclip}
            className='text-zen-dark-7'
            aria-label='document-count'
          />
          <div
            className='text-zen-dark-7 ml-1'
            data-testid={`${totalDocsCount} Docs`}
          >
            {totalDocsCount}
          </div>
        </div>
      </Popover>
      <ZenConfirmationModal
        isOpen={!!fileToDelete}
        variant='danger'
        title={`Are you sure you want to archive: ${fileToDelete?.filename!}?`}
        confirmButtonText='Archive'
        hideIcon
        subtitle='Archiving this file will remove all previous versions as well.'
        onClose={() => setFileToDelete(null)}
        onConfirm={() => {
          handleDeleteDropboxFile();
        }}
        isSubmitting={isDeleting}
        isDisabled={isDeleting}
      />
    </div>
  );
};

export default ZenChecklistDocumentPopper;
