import { values } from 'lodash';
import { RevShareInsightsFilterEnum } from '../../utils/RevShareInsightsUtils';
import { cn } from '../../utils/classUtils';

const filters = values(RevShareInsightsFilterEnum);

interface RevShareInsightsDateFiltersProps {
  value: RevShareInsightsFilterEnum;
  loading?: boolean;
  onChange: (filter: RevShareInsightsFilterEnum) => void | Promise<void>;
}

const RevShareInsightsDateFilters: React.FC<RevShareInsightsDateFiltersProps> = ({
  value,
  loading,
  onChange,
}) => {
  return (
    <div
      className={cn(
        'space-x-2 md:space-x-3 flex',
        loading ? 'opacity-50' : 'opacity-100',
      )}
    >
      {filters.map((label) => {
        const isActive = label === value;
        return (
          <button
            key={label}
            disabled={loading}
            onClick={() => onChange(label)}
            className={cn(
              'capitalize font-zen-body text-sm  border rounded-md px-5 py-1.5',
              isActive ? 'border-primary-blue' : 'border-zen-dark-4',
              isActive ? 'bg-zen-light-blue-2' : 'bg-white',
              isActive ? 'text-primary-blue' : 'text-zen-dark-7',
            )}
          >
            {label}
          </button>
        );
      })}
    </div>
  );
};

export default RevShareInsightsDateFilters;
