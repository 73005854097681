import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { TransactionLifecycleStateValueStateEnum } from '../../openapi/arrakis';
import { transitionTransaction } from '../../slices/TransactionSlice';
import { FeatureFlagTypeEnum, RootState } from '../../types';
import PaymentParticipantCard from '../PaymentParticipantCard';
import { showSuccessToast } from '../../slices/ToastNotificationSlice';
import AmplitudeService, {
  AmplitudeEvent,
} from '../../services/AmplitudeService';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import TransactionCommissionIntro from './TransactionCommissionIntro';
import TransactionCommissionReviewFinal from './TransactionCommissionReviewFinal';
import TransactionCommissionSplitReview from './TransactionCommissionSplitReview';
import TransactionReviewWrapper from './TransactionReviewWrapper';

interface Match {
  id: string;
}

interface TransactionCommissionReviewProps extends RouteComponentProps<Match> {}

const TransactionCommissionReview: React.FC<TransactionCommissionReviewProps> = ({
  match,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    transactionDetailResponse: { data: transactionDetail, loading },
  } = useSelector((state: RootState) => state.transaction);
  const [index, setIndex] = useState<number>(0);
  const transactionId = match.params.id;
  const FIRST_STEP = 0;
  const SECOND_STEP = 1;
  const LAST_STEP =
    (transactionDetail?.paymentParticipants?.length || 0) + 1 + 1;

  const nextState = transactionDetail?.nextPrimaryUserTransition?.state;

  const isDoubleEnderEnabled = useFeatureFlag(FeatureFlagTypeEnum.DOUBLE_ENDER);

  useEffect(() => {
    if (
      nextState !== TransactionLifecycleStateValueStateEnum.CommissionValidated
    ) {
      history.push(`/transactions/${transactionId}/detail`);
    }
  }, [history, transactionId, nextState]);

  const goBack = () => {
    AmplitudeService.logEvent(
      AmplitudeEvent.TX_ONBOARDING_COMMISSION_VALIDATION_CANCEL,
      { currentStep: index },
    );

    history.push(`/transactions/${transactionId}/detail`);
  };

  const validateCommissionDocument = async () => {
    AmplitudeService.logEvent(
      AmplitudeEvent.TX_ONBOARDING_COMMISSION_VALIDATION_SUCCESS,
    );

    await dispatch(
      transitionTransaction(
        transactionDetail!,
        TransactionLifecycleStateValueStateEnum.CommissionValidated,
        undefined,
        { [FeatureFlagTypeEnum.DOUBLE_ENDER]: isDoubleEnderEnabled },
      ),
    );

    history.push(
      `/transactions/${transactionId}?${qs.stringify({
        showStatusOnboardings: true,
      })}`,
    );

    dispatch(
      showSuccessToast(
        'Transaction transitioned successfully.',
        'You have validated the commissions.',
      ),
    );
  };

  const renderReviewStep = () => {
    if (index === FIRST_STEP) {
      return (
        <TransactionReviewWrapper
          title='Review My Commission'
          address={transactionDetail?.address?.oneLine!}
          primaryActionOnPress={() => setIndex(index + 1)}
          primaryActionTitle='Next'
          secondaryActionTitle=''
          secondaryActionOnPress={() => {}}
        >
          <TransactionCommissionIntro />
        </TransactionReviewWrapper>
      );
    }

    if (index === SECOND_STEP) {
      return (
        <TransactionReviewWrapper
          title='Review My Commission'
          address={transactionDetail?.address?.oneLine!}
          primaryActionTitle='Yes, the splits are correct'
          primaryActionOnPress={() => setIndex(index + 1)}
          secondaryActionTitle='No, I need to make a change'
          secondaryActionOnPress={goBack}
        >
          <p className='font-primary-regular text-lg mb-4'>
            Are the commission splits correct?
          </p>
          <TransactionCommissionSplitReview
            commissionSplit={transactionDetail?.commissionSplits!}
            paymentParticipants={transactionDetail?.paymentParticipants!}
          />
        </TransactionReviewWrapper>
      );
    }

    if (index === LAST_STEP) {
      return (
        <TransactionReviewWrapper
          title='Review My Commission'
          address={transactionDetail?.address?.oneLine!}
          primaryActionTitle='I have approved all of the commission details'
          primaryActionOnPress={validateCommissionDocument}
          isPrimaryActionLoading={loading}
          secondaryActionTitle='No, I need to make a change'
          secondaryActionOnPress={goBack}
        >
          <TransactionCommissionReviewFinal transaction={transactionDetail!} />
        </TransactionReviewWrapper>
      );
    }

    return (
      <TransactionReviewWrapper
        title='Review My Commission'
        address={transactionDetail?.address?.oneLine!}
        primaryActionTitle='Yes, the commission details are correct'
        primaryActionOnPress={() => setIndex(index + 1)}
        secondaryActionTitle='No, I need to make a change'
        secondaryActionOnPress={goBack}
      >
        <PaymentParticipantCard
          defaultCollapsed={false}
          isReadonly
          onDelete={() => {}}
          onEdit={() => {}}
          isSingleCheck={false}
          transactionOwner={false}
          participant={transactionDetail?.paymentParticipants![index - 2]!}
          isAdmin={false}
        />
      </TransactionReviewWrapper>
    );
  };

  return <div>{renderReviewStep()}</div>;
};

export default TransactionCommissionReview;
