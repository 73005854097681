import React from 'react';
import { EnumMap } from '../types';
import { cn } from '../utils/classUtils';
import { AlertVariantType } from './Alert';

interface NotificationAlertProps {
  variant: AlertVariantType;
  title: string;
  subtitle?: string;
  icon?: JSX.Element;
}

const NotificationAlert: React.FC<NotificationAlertProps> = ({
  variant,
  title,
  subtitle,
  icon,
}) => {
  const alertVariantToClassNameMap: EnumMap<AlertVariantType, string> = {
    success: 'bg-success bg-opacity-20 text-white border-green-600',
    info: 'bg-primary bg-opacity-20 text-white border-primary',
    warning: 'bg-warning bg-opacity-10 text-orange-800 border-yellow-600',
    error: 'bg-error bg-opacity-20 text-white border-red-600',
    default: 'bg-gray-500 bg-opacity-20 text-white border-gray-600',
  };

  return (
    <div
      className={cn(
        'flex flex-row items-start rounded p-2 break-words space-x-1.5 border ',
        alertVariantToClassNameMap[variant],
      )}
    >
      <div className='flex items-center'>{icon}</div>
      <div className='flex flex-col items-start space-y-1'>
        <p className='font-primary-medium text-sm'>{title}</p>
        {subtitle && (
          <p className='font-primary-regular text-sm font-normal mr-3 whitespace-pre-line'>
            {subtitle}
          </p>
        )}
      </div>
    </div>
  );
};

export default NotificationAlert;
