import ContentLoader from 'react-content-loader';

const CallNumberLoader = () => {
  return (
    <ContentLoader
      width='100%'
      viewBox='0 0 600 82'
      backgroundColor='#eaeced'
      foregroundColor='#ffffff'
      title='Loading...'
    >
      <rect x='170' y='5' rx='5' ry='5' width='250' height='43' />
      <rect x='515' y='10' rx='5' ry='5' width='57' height='20' />
      <rect x='250' y='57' rx='5' ry='5' width='85' height='20' />
    </ContentLoader>
  );
};

export default CallNumberLoader;
