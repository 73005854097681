import React from 'react';
import { Segment } from '../../../openapi/atlantis';
import useValidateVisibleCondition from '../hooks/useValidateVisibleCondition';
import MortgageApplicationQuestionHeader from '../MortgageApplicationQuestionHeader';
import MortgageApplicationFields from './MortgageApplicationFields';

interface MortgageApplicationSegmentProps {
  segment: Segment;
  onSubmit: () => void;
  isReadOnly: boolean;
}

const MortgageApplicationSegment: React.FC<MortgageApplicationSegmentProps> = ({
  segment,
  onSubmit,
  isReadOnly,
}) => {
  const { validateSegmentVisibleCondition } = useValidateVisibleCondition();
  const isVisible = validateSegmentVisibleCondition(segment);

  if (!isVisible) {
    return null;
  }

  return (
    <div className='mb-8'>
      {segment.name && (
        <div className='mb-4'>
          <MortgageApplicationQuestionHeader
            title={segment.name}
            titleClassName='text-base font-poppins font-medium text-black'
            hideDivider
          />
        </div>
      )}
      <MortgageApplicationFields
        fields={segment.fields}
        onSubmit={onSubmit}
        isReadOnly={isReadOnly}
      />
    </div>
  );
};
export default MortgageApplicationSegment;
