import { isString } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { Popover } from 'react-tiny-popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { ReactComponent as FilterIcon } from '../../assets/img/filter.svg';
import ZenControlledDatePickerInput from '../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenButton from '../Zen/ZenButton';

interface ZenAgentFilesSearchFilterInputProps {
  onSearch(search: string): void;
  onFilter(filter: FormData): void;
  searchTitles: string[];
}

export interface FormData {
  fileName: string;
  description: string;
  updated_at: string;
  updated_at_start: string;
  updated_at_end: string;
  created_at: string;
  created_at_start: string;
  created_at_end: string;
}

const ZenAgentFilesSearchFilterInput: React.FC<ZenAgentFilesSearchFilterInputProps> = ({
  onSearch,
  onFilter,
  searchTitles,
}) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const { control, handleSubmit, reset, watch } = useForm<FormData>();
  const [
    updated_at_start,
    updated_at_end,
    created_at_start,
    created_at_end,
  ] = watch([
    'updated_at_start',
    'updated_at_end',
    'created_at_start',
    'created_at_end',
  ]);

  const filteredSearchTitles = searchTitles.filter((title) =>
    title?.toLowerCase()?.includes(search?.toLowerCase()),
  );

  const onFilterSubmit = (formData: FormData) => {
    onFilter(formData);
    setShowFilter(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);

    if (value && !isAutoCompleteOpen) {
      setIsAutoCompleteOpen(true);
    } else if (!value) {
      onSearch(value);
      setIsAutoCompleteOpen(false);
    }
  };

  const closeFilterModal = () => {
    for (const property in control._formValues) {
      if (
        isString(control._formValues[property]) &&
        control._formValues[property]?.trim()?.length
      ) {
        return;
      }
    }
    setShowFilter(false);
  };

  return (
    <Popover
      isOpen={showFilter}
      positions={['bottom', 'top', 'right']}
      align='start'
      padding={5}
      onClickOutside={closeFilterModal}
      content={
        <div className='w-full max-w-2xl bg-white border border-zen-dark-5 rounded-3xl shadow-side-popup'>
          <form onSubmit={handleSubmit(onFilterSubmit)}>
            <div className='flex justify-between items-center border-b border-zen-light-gray-2 px-6 py-4'>
              <div className='space-x-3 flex items-center'>
                <FilterIcon className='fill-zen-dark-7 text-xl' />
                <span className='font-zen-title text-xl font-medium pt-1'>
                  Filter
                </span>
              </div>
              <button
                type='button'
                className='font-zen-title text-lg font-light text-primary-blue mt-1'
                onClick={() => {
                  reset({
                    fileName: '',
                    description: '',
                    updated_at_start: '',
                    updated_at_end: '',
                    created_at_start: '',
                    created_at_end: '',
                  });
                }}
              >
                Clear
              </button>
            </div>
            <div className='px-6 py-4'>
              <div>
                <ZenControlledTextInput
                  control={control}
                  shouldUnregister={false}
                  name='fileName'
                  label='File name'
                  placeholder='E.g. Form 1099'
                />
              </div>

              <div className='pt-2'>
                <ZenControlledTextInput
                  control={control}
                  shouldUnregister={false}
                  name='description'
                  label='Description contains'
                  placeholder='E.g. Approved version'
                />
              </div>

              <div className='pt-2 space-y-1'>
                <label htmlFor='updated_at'>Updated on/between</label>
                <div className='flex flex-row items-center justify-between space-x-2'>
                  <ZenControlledDatePickerInput
                    control={control}
                    shouldUnregister={false}
                    name='updated_at_start'
                    placeholder='12/29/2021'
                    datePickerConfig={{
                      maxDate:
                        updated_at_end !== undefined && updated_at_end !== ''
                          ? new Date(updated_at_end)
                          : new Date(),
                    }}
                  />
                  <ZenControlledDatePickerInput
                    control={control}
                    shouldUnregister={false}
                    name='updated_at_end'
                    placeholder='12/29/2021'
                    datePickerConfig={{
                      minDate:
                        updated_at_start !== undefined &&
                        updated_at_start !== ''
                          ? new Date(updated_at_start)
                          : undefined,
                      maxDate: new Date(),
                    }}
                  />
                </div>
              </div>

              <div className='pt-2 space-y-1'>
                <label htmlFor='created_at'>Uploaded on/between</label>
                <div className='flex flex-row items-center justify-between space-x-2'>
                  <ZenControlledDatePickerInput
                    control={control}
                    shouldUnregister={false}
                    name='created_at_start'
                    placeholder='12/29/2021'
                    datePickerConfig={{
                      maxDate:
                        created_at_end !== undefined && created_at_end !== ''
                          ? new Date(created_at_end)
                          : new Date(),
                    }}
                  />
                  <ZenControlledDatePickerInput
                    control={control}
                    shouldUnregister={false}
                    name='created_at_end'
                    placeholder='12/29/2021'
                    datePickerConfig={{
                      minDate:
                        created_at_start !== undefined &&
                        created_at_start !== ''
                          ? new Date(created_at_start)
                          : undefined,
                      maxDate: new Date(),
                    }}
                  />
                </div>
              </div>
              <div className='flex flex-row items-center justify-between pt-4'>
                <ZenButton label='Apply' type='submit' isFullWidth />
              </div>
            </div>
          </form>
        </div>
      }
    >
      <div className='relative w-full max-w-2xl'>
        <div className='flex flex-row items-center flex-grow w-full h-10 px-2 py-1 border border-zen-dark-5 rounded-md'>
          <div className='flex flex-row items-center flex-grow px-1.5 py-2 border-r border-zen-dark-5 w-full'>
            <FontAwesomeIcon
              icon={faSearch}
              size='sm'
              className='text-primary-blue'
            />
            <input
              type='text'
              value={search}
              onChange={handleSearch}
              placeholder='Search'
              className='font-zen-body appearance-none p-1.5 capitalize w-full h-6 border-none focus:outline-none placeholder-zen-dark-8 focus:ring-0'
              onFocus={() => {
                if (search) {
                  setIsAutoCompleteOpen(true);
                }
              }}
            />
          </div>

          <div
            className='flex flex-row items-center space-x-1 px-2 py-2 cursor-pointer'
            onClick={() => setShowFilter(!showFilter)}
          >
            <div className='px-0.5 bg-white' aria-label='filter-icon'>
              <FilterIcon className='fill-zen-dark-7' />
            </div>
          </div>

          {isAutoCompleteOpen && (
            <div className='absolute left-0 z-10 w-full bg-white border rounded top-10'>
              {filteredSearchTitles.map((title) => (
                <button
                  onClick={() => {
                    onSearch(title);
                    setIsAutoCompleteOpen(false);
                    setSearch(title);
                  }}
                  className='w-full py-1 pl-8 pr-3 text-left truncate appearance-none focus:outline-none focus:border-0 font-zen-body'
                  key={title}
                >
                  {title}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </Popover>
  );
};

export default ZenAgentFilesSearchFilterInput;
