import React from 'react';
import ZenPopover from '../../../ZenPopover';

interface RealSplitFeePopoverProps {}

const RealSplitFeePopover: React.FC<RealSplitFeePopoverProps> = () => {
  return (
    <div className='font-inter text-sm text-zen-dark-9 mt-5'>
      Real Split
      <ZenPopover
        title='Real Split'
        description={
          <div className='text-zen-dark-9 text-base font-inter font-light'>
            <p>
              Real fees are charges that are collected only when you close
              transactions. Real does not charge any monthly fees.
            </p>
            <p className='mt-4'>
              Select the default percentage split for Real Fee between Team
              Leader and Teammates (or members within your team).
            </p>
          </div>
        }
      />
    </div>
  );
};

export default RealSplitFeePopover;
