import { useFieldArray, useForm } from 'react-hook-form-v7';
import { DateTime } from 'luxon';
import { AgentResponse } from '../../openapi/yenta';
import { capitalizeEnum } from '../../utils/StringUtils';
import ControlledDatePickerInputV7 from '../ControlledDatePickerInputV7';
import ControlledSignatureInputV7 from '../ControlledSignatureInputV7';
import ControlledTextInputV7 from '../ControlledTextInputV7';
import TamirsSignatureImg from '../../assets/img/tamirs-signature.png';
import AmplitudeEventOnLoad from '../Amplitude/AmplitudeEventOnLoad';
import { AmplitudeEvent } from '../../services/AmplitudeService';
import { TransactionLinkResponse } from '../../openapi/arrakis';
import { getInstantPaymentAmplitudeEventData } from '../../utils/InstantPaymentHelper';

interface TermsAndConditionProps {
  userDetail: AgentResponse;
  transaction: TransactionLinkResponse | undefined;
}

const TermsAndCondition: React.FC<TermsAndConditionProps> = ({
  userDetail,
  transaction,
}) => {
  const { control } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: userDetail.fullName,
      addendumDate: DateTime.local().toISODate(),
      signature: userDetail.fullName,
      ceo: 'Tamir Poleg',
      title: 'CEO',
      licenses:
        userDetail?.licenses?.map((license) => ({
          state: capitalizeEnum(license.administrativeArea?.stateOrProvince!),
          number: license.number,
        })) || [],
    },
  });

  const { fields } = useFieldArray({
    name: 'licenses',
    control,
  });
  return (
    <div className='bg-white m-10 text-justify font-arial'>
      <p className='font-primary-medium mb-5 text-center text-xl'>
        REAL BROKER
      </p>
      <p className='font-primary-medium mb-5 text-center text-xl'>
        COMMISSION PAYMENT ADDENDUM
      </p>

      <ControlledTextInputV7
        label='Name of Agent'
        name='name'
        defaultValue={userDetail.fullName}
        control={control}
        readOnly
      />

      {fields?.map((item, index) => (
        <div
          className='grid lg:grid-cols-2 print:grid-cols-2 grid-col-1 gap-x-16 pt-2'
          key={item.number}
        >
          <div>
            <ControlledTextInputV7
              label={
                index === 0
                  ? 'Primary State / Province Agent Licensed In'
                  : 'Additional State / Province'
              }
              control={control}
              name={`licenses.${index}.state` as const}
              readOnly
            />
          </div>
          <div className='lg:mt-0 print:mt-0 mt-2'>
            <ControlledTextInputV7
              label='License Number'
              control={control}
              name={`licenses.${index}.number` as const}
              readOnly
            />
          </div>
        </div>
      ))}
      <div className='my-2'>
        <ControlledDatePickerInputV7
          label='Date of Addendum'
          name='addendumDate'
          control={control}
          readOnly
        />
      </div>
      <p className='mt-4'>
        This Commission Payment Addendum (“<b>Addendum</b>”) is entered into by
        and among the real estate agent listed above (“<b>Agent</b>”) and The
        Real Brokerage, Inc. (together with any of its affiliates, including,
        but not limited to, Real Broker LLC, Real Broker Technologies, Inc.,
        Real Broker AZ LLC, Real Broker NY, Real Broker CT LLC, Real Broker MA
        LLC, or Real Broker NH LLC, the “Broker”, and collectively with the
        Agent, the “<b>Parties</b>”) as of the date written above. This Addendum
        serves as the written agreement of the Parties to modify the Section
        Commission of the Real Broker Independent Contractor Agreement (the “
        <b>Agreement</b>”) between the Parties.
      </p>
      <p className='my-2'>The Parties agree as follows:</p>
      <ol className='list-decimal space-y-3'>
        <li className='space-y-4'>
          <p>
            <span className='text-blue-800 font-semibold text-base'>
              Commission Payment Process.
            </span>{' '}
            Pursuant to a separate written sales agreement between a buyer and
            seller for the purchase and sale of the real property (the “
            <b>Property</b>”) involving Agent, Broker will earn a commission
            payable upon closing of the transaction for the sale of the Property
            (the “<b>Closing</b>”), and Agent will receive the portion of
            Broker’s commission set forth in the Agreement as the “
            <b>Commission</b>.” By completing a request through Broker’s smart
            phone application (the “<b>App</b>”) prior to Closing, Agent is
            applying with Broker to receive a percentage of Agent’s Commission
            prior to the Closing on the selected Property. The applicable
            percentage of the Commission available for request by Agent will be
            determined by Broker in its sole discretion and disclosed to Agent
            through the App. In no event will the percentage equal or exceed one
            hundred percent (100%) of the Commission owed under the Agreement.
          </p>
          <p>
            Upon processing the information and selections of Agent through the
            App, and the receipt of all other necessary information determined
            necessary by Broker in its sole discretion, Broker agrees to pay
            Agent such Commission in advance of Closing on the terms and
            conditions set forth herein.
          </p>
          <p>
            In order to receive the Commission in advance of Closing, Agent
            shall submit the request on the App after the execution of the sales
            agreement for the Property and prior to Closing. Submissions
            received by Broker through the App less than [five (5)] calendar
            days prior to Closing may not be processed in advance of Closing, in
            which event the request may be rejected and payment of the
            Commission will be processed in accordance with the Agreement. If
            the request is approved, Broker shall deposit payment of the
            Commission into Agent’s bank account identified by Agent in the App
            or otherwise provided to Broker. Agent agrees that Broker may rely
            on all information provided through the App and that Broker shall
            not be liable for any errors, including errors in payments,
            resulting from inaccurate information provided through the App.
          </p>
          <p>
            For purposes of clarification, the terms and provisions of this
            Addendum shall apply solely to purchase and sale transactions for
            Property and not to any leasing transactions in which a commission
            or other compensation may be due to Broker or Agent under the
            Agreement.
          </p>
        </li>
        <li className='space-y-4'>
          <p>
            <span className='text-blue-800 font-semibold text-base'>
              Processing Fee.
            </span>{' '}
            In connection with Broker’s processing of Agent’s request for
            payment, Agent shall pay an amount of between three percent (3%) and
            eight percent (8%) of the Commission as a non-refundable request
            processing fee. The exact amount to be paid will be shown on the App
            prior to Agent submitting his or her request. The processing fee
            will be deducted at Closing.
          </p>
        </li>
        <li className='space-y-4'>
          <p>
            <span className='text-blue-800 font-semibold text-base'>
              Best Efforts to Ensure Timely Closing.
            </span>{' '}
            Agent acknowledges and agrees that nothing in this Addendum
            otherwise alters or amends Agent’s obligations under the Agreement.
            Agent further agrees to use his or her best efforts to ensure that
            Closing occurs on the scheduled closing date set forth in the App.
          </p>
        </li>
        <li className='space-y-4'>
          <p>
            <span className='text-blue-800 font-semibold text-base'>
              Canceled or Terminated Closing; Repayment through Future
              Commissions; True Up.
            </span>
            In the event the Closing is canceled or terminated for any reason,
            including, without limitation, failure to satisfy closing conditions
            or termination otherwise by either or both buyer and seller, Agent
            shall repay the portion of the Commission requested through the App
            to Broker within three (3) business days of the cancellation or
            termination of the Closing. In the event Agent does not make
            repayment within such three (3) business day period, Agent shall be
            and remain liable to Broker for the repayment of the amount of the
            Commission. Agent hereby authorizes and agrees that Broker may
            deduct the amount of the Commission out of future commissions earned
            by Agent for real estate transactions involving Broker and Agent. To
            the extent Agent receives the Commission or any portion thereof,
            Agent will promptly, but in no event later than three (3) business
            days after the receipt thereof, deliver such Commission (or the
            portion thereof) to Broker pursuant to the instructions that will be
            provided by Broker to Agent.
          </p>
          <p>
            Any difference between the Commission owed by Broker to Agent at
            Closing and the amount previously paid to Agent through the request
            made on the App pursuant to this Addendum, less the processing fee,
            shall be settled between Agent and Broker at Closing pursuant to the
            Agreement.
          </p>
        </li>
        <li className='space-y-4'>
          <p>
            <span className='text-blue-800 font-semibold text-base'>
              Characterization of Commissions.
            </span>{' '}
            The Parties agree that the payment of the Commission: (a) represents
            payment of amounts owed and due to Agent by Broker at Closing for
            services actually rendered by Agent; (b) is due to Agent in
            connection with his or her business or professional activities and
            not with respect to any personal, family, or household activities;
            and (c) does not represent an advance or loan to Agent from Broker.
          </p>
        </li>
        <li className='space-y-4'>
          <p>
            <span className='text-blue-800 font-semibold text-base'>
              Severability of Provisions.
            </span>{' '}
            Each provision of this Addendum shall be considered severable and
            if, for any reason, any provision or provisions herein are
            determined to be invalid and contrary to any existing or future law
            or as it relates to the intent of the Parties not to treat payment
            of the Commission hereunder as an advance or loan, such provision or
            provisions shall be deemed struck from the Addendum without
            affecting the validity of the other provisions of this Addendum.
          </p>
        </li>
        <li className='space-y-4'>
          <p>
            <span className='text-blue-800 font-semibold text-base'>
              Entire Agreement.
            </span>{' '}
            This Addendum is incorporated into and made part of the Agreement
            and, except to the extent inconsistent with such Agreement as to the
            subject matter herein, does not otherwise amend or alter the
            Agreement. For sake of clarity, this Addendum serves as the separate
            written agreement of the Parties regarding the timing and process
            for the payment of Commissions, as discussed in the Commission
            Section of the Agreement. In the event of any inconsistencies
            between this Addendum and the Agreement, the terms and provisions of
            this Addendum shall govern. This Addendum may only be modified or
            amended with the written consent of the Parties. Any capitalized
            term used in this Addendum that is not defined herein is given the
            meaning set forth in the Agreement.
          </p>
        </li>
        <li className='space-y-4'>
          <p>
            <span className='text-blue-800 font-semibold text-base'>
              Counterparts.
            </span>{' '}
            This Addendum may be executed in counterparts and all so executed
            shall constitute one agreement binding on all parties hereto,
            notwithstanding that all the Parties have not signed the original or
            the same counterpart. Any counterpart hereof signed by a Party
            against whom enforcement of this Addendum is sought shall be
            admissible into evidence as an original hereof to prove the contents
            hereof.
          </p>
        </li>
      </ol>
      <p className='mt-5'>
        <b>IN WITNESS WHEREOF</b>, the Parties have caused this Addendum to be
        executed as of the date set forth above.
      </p>
      <div className='mt-5 flex flex-col space-y-3'>
        <b>AGENT</b>
        <ControlledSignatureInputV7
          label='Signed'
          control={control}
          name='signature'
          defaultValue={userDetail.fullName}
          readOnly
        />
        <ControlledTextInputV7
          label='Name'
          control={control}
          name='name'
          defaultValue={userDetail.fullName}
          readOnly
        />
        <b>BROKER</b>
        <img
          src={TamirsSignatureImg}
          alt="Tamir's Signature"
          className='h-10 mb-5 w-20'
        />
        <ControlledTextInputV7
          label='Name'
          control={control}
          name='ceo'
          defaultValue='Tamir Poleg'
          readOnly
        />
        <ControlledTextInputV7
          label='Title'
          control={control}
          name='title'
          defaultValue='CEO'
          readOnly
        />
      </div>
      <AmplitudeEventOnLoad
        eventName={AmplitudeEvent.INSTANT_PAYMENTS_TOC_VIEW}
        eventData={getInstantPaymentAmplitudeEventData(transaction)}
      />
    </div>
  );
};

export default TermsAndCondition;
