import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps, ISelectOption } from '../../types';
import ZenCustomGroupRadioButton from '../ZenCustomGroupRadioButton';
import { cn } from '../../utils/classUtils';
import ZenFormErrorMessage from './Input/ZenFormErrorMessage';

export interface ZenControlledGroupRadioButtonInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  options: Array<ISelectOption>;
  disabled?: boolean;
  isRequired?: boolean;
}

const ZenControlledGroupRadioButtonInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  disabled = false,
  options,
  tooltipIndex,
  shouldUnregister = true,
  isRequired,
  ...rest
}: ZenControlledGroupRadioButtonInputProps<TFieldValues, TName>) => {
  const {
    field: { onChange, value, name },
    fieldState: { error, invalid },
  } = useController<TFieldValues, TName>(rest);

  return (
    <div className='w-full space-y-2'>
      <div className='flex flex-col space-y-1'>
        {label && (
          <label className='inline-block' htmlFor={name}>
            <span
              className={cn(
                'font-zen-body font-semibold text-base',
                invalid ? 'text-zen-danger' : 'text-zen-dark-9',
              )}
            >
              {label}
            </span>
            {isRequired && <span className='text-zen-danger'>*</span>}
          </label>
        )}
        <div data-tooltip-index={tooltipIndex}>
          <ZenCustomGroupRadioButton
            items={options}
            onChange={onChange}
            value={value}
            disabled={disabled}
          />
        </div>
      </div>
      {error && <ZenFormErrorMessage message={error.message} />}
    </div>
  );
};

export default ZenControlledGroupRadioButtonInput;
