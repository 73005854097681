import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, ClientApiKeyState } from '../types';
import { getKeymakerConfiguration } from '../utils/OpenapiConfigurationUtils';
import ErrorService from '../services/ErrorService';
import {
  ApiKeyControllerApi,
  ApiKeyResponse,
  RevokeApiKeyRequest,
} from '../openapi/keymaker';
import { showApiErrorModal } from './ErrorSlice';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';

export const initialState: ClientApiKeyState = {
  clientApiKeys: [],
};

const ClientApiKeySlice = createSlice({
  name: 'clientApiKey',
  initialState,
  reducers: {
    updateClientApiKeys(state, action: PayloadAction<ApiKeyResponse[]>) {
      state.clientApiKeys = action.payload;
    },
  },
});

export const { updateClientApiKeys } = ClientApiKeySlice.actions;

export const fetchClientApiKeys = (): AppThunk => async (dispatch) => {
  try {
    const { data } = await new ApiKeyControllerApi(
      getKeymakerConfiguration(),
    ).getApiKeys();
    dispatch(updateClientApiKeys(data || []));
  } catch (e) {
    dispatch(updateClientApiKeys([]));
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch client API keys', e);
    dispatch(
      showErrorToast(
        'We had a problem fetching client API keys',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const revokeClientApiKey = (
  apiKey: RevokeApiKeyRequest,
): AppThunk => async (dispatch) => {
  try {
    await new ApiKeyControllerApi(getKeymakerConfiguration()).revokeApiKey(
      apiKey,
    );
    dispatch(showSuccessToast('API key revoked successfully.'));
    dispatch(fetchClientApiKeys());
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to revoke client API key', e);
    dispatch(
      showErrorToast(
        'We had a problem revoking client API key',
        'Please try again in a few moments.',
      ),
    );
  }
};

export default ClientApiKeySlice.reducer;
