import { useSelector } from 'react-redux';
import { Earning, EarningType } from '../WealthManagementTypes';
import { RootState } from '../../../types';
import { GoalDetails } from '../../../openapi/plutus';
import AddToMyPlanWithArrowButton, {
  AddToMyPlanWithArrowButtonProps,
} from './AddToMyPlanWithArrowButton';
import LandingPageHeader from './LandingPageHeader';
import { ZenLandingPageVideo } from './ZenLandingPageVideo';

interface ZenLandingPageIntroProps {
  earningType: EarningType;
  sloganImage: string;
  subtitle: string;
  showAddToMyPlan?: boolean;
  buttonProps?: AddToMyPlanWithArrowButtonProps;
  headerBackgroundProps?: ZenLandingPageHeaderBackgroundProps;
}

interface ZenLandingPageHeaderBackgroundProps {
  bgVariant?: 'black' | 'blue';
  styles?: React.CSSProperties;
}

export const ZenLandingPageHeaderBackground = ({
  styles = {},
  bgVariant = 'black',
}: ZenLandingPageHeaderBackgroundProps) => {
  const BgColorMap: Partial<Record<'black' | 'blue', string>> = {
    black: 'linear-gradient(58.78deg, #282826 -8.45%, #282826 108.33%)',
    blue: 'linear-gradient(58.78deg, #3B82F6 -8.45%, #05C3F9 108.33%)',
  };

  return (
    <div
      className='absolute h-8 w-full'
      style={{
        bottom: '-25px',
        left: '0px',
        transform: 'rotate(-1deg)',
        backgroundImage: BgColorMap[bgVariant],
        boxShadow:
          '0px -9.687317848205566px 38.749271392822266px 0px #00000029',
        ...styles,
      }}
    />
  );
};

const ParticipationMap: Partial<Record<EarningType, keyof GoalDetails>> = {
  [Earning.ELITE_CULTURAL_AWARD]: 'eliteAgentCulturalAwardParticipation',
  [Earning.ELITE_PRODUCTION_AWARD]: 'eliteAgentProductionAwardParticipation',
  [Earning.POST_CAP_SPP]: 'sppParticipation',
  [Earning.PRE_CAP_SPP]: 'sppParticipation',
  [Earning.REVENUE_SHARE_INCOME]: 'revShareParticipation',
  [Earning.STOCK_AWARD_FOR_ATTRACTING]: 'revShareParticipation',
};

const ZenLandingPageIntro = ({
  subtitle,
  sloganImage,
  earningType,
  buttonProps,
  headerBackgroundProps,
  showAddToMyPlan = false,
}: ZenLandingPageIntroProps) => {
  const {
    wealthGoalsResponse: { data },
  } = useSelector((state: RootState) => state.wealthManagement);
  const goals = data?.goalDetails;

  const participationKey = ParticipationMap[earningType];
  const isParticipating = !!goals?.[participationKey!];

  return (
    <div className='relative'>
      <div className='flex justify-center items-center p-4 flex-col gap-1 h-full bg-[#F5FAFF] relative'>
        <div className='my-6'>
          <LandingPageHeader type={earningType} />
        </div>
        <img src={sloganImage} alt='slogan' className='w-3/5' />
        <div className='md:text-2xl text-center font-inter font-light'>
          {subtitle}
        </div>
        {showAddToMyPlan && buttonProps && !isParticipating && (
          <div className='mt-5 mb-10'>
            <AddToMyPlanWithArrowButton {...buttonProps} />
          </div>
        )}
      </div>
      <ZenLandingPageVideo earningType={earningType} />
      <ZenLandingPageHeaderBackground {...headerBackgroundProps} />
    </div>
  );
};

export default ZenLandingPageIntro;
