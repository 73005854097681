import qs from 'qs';
import config from '../config';
import { isStringEmpty } from './StringUtils';

export const getWebAppLink = (path: string): string => {
  return `${config.reactAppHostUrl}${path}`;
};

export const parseUrl = (url: string): string | null => {
  if (isStringEmpty(url)) {
    return null;
  }

  const trimmedUrl = url.trim();

  if (!trimmedUrl.match(/^(https?):\/\//)) {
    return `https://${trimmedUrl}`;
  }

  return trimmedUrl;
};

export const getInboxLink = (search: string): string => {
  return `/inbox?${qs.stringify({
    ...qs.parse(search, { ignoreQueryPrefix: true }),
    brokerQueue: true,
  })}`;
};

export const isMenuItemActive = (paths: string[] | string): boolean => {
  const isActive = Array.isArray(paths)
    ? paths.some((path) => window.location.pathname.startsWith(path))
    : window.location.pathname.startsWith(paths);

  return isActive;
};

export const filterUrl = (url: string) => {
  return url.replace(/^(?:(https?):\/\/)?(?:www\.)?/, '');
};

export const constructLink = (url: string, params: any) => {
  const isEveryValueEmpty = Object.values(params).every((value) => !value);

  if (isEveryValueEmpty) {
    return url;
  }

  return `${url}?${qs.stringify(params)}`;
};

export const getRealSignatureUrl = (jwt: string) => {
  return `${config.realSignatureBaseUrl}/login?jwt=${jwt}`;
};

export const urlWithoutProtocol = (url: string): string | null => {
  if (isStringEmpty(url)) {
    return null;
  } else {
    return url.replace(/^(?:https?:\/\/)?(?:www\.)?|\/+$/g, '');
  }
};
