import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { AnalyticsEventEnum } from '../../../types';
import { formatMoneyValue } from '../../../utils/CurrencyUtils';
import ZenButton from '../../Zen/ZenButton';
import { WealthPlanTooltip } from '../WealthManagementUtils';

type TextSizeVariant = 'text-base' | 'text-3xl';

interface WealthPlanInfoCardProps {
  label: string;
  value?: string;
  labelStyles?: string;
  labelTooltip?: string;
  tooltipAnalyticsEvent?: AnalyticsEventEnum;
  valueStyles?: string;
  valueSizeVariant?: TextSizeVariant;
  subValue?: string | number;
  subValueStyles?: string;
  containerStyles?: string;
  editButtonText?: string;
  onEditClick?: () => void;
}

const WealthPlanInfoCard: React.FC<WealthPlanInfoCardProps> = ({
  label,
  labelStyles,
  labelTooltip,
  tooltipAnalyticsEvent,
  value,
  valueStyles,
  valueSizeVariant = 'text-base',
  subValue,
  subValueStyles,
  containerStyles,
  editButtonText,
  onEditClick,
}) => {
  const TextSizeVariantEnumMap: Record<TextSizeVariant, string> = {
    'text-base': 'text-xl font-medium text-zen-dark-7',
    'text-3xl': 'text-[32px] leading-[38px] font-semibold text-zen-dark-9',
  };

  return (
    <div
      className={classNames(
        'flex-1 border border-zen-light-gray-2 bg-white flex flex-col items-center justify-center space-y-1 py-4 rounded-[10px] shrink-0 md:px-0 px-2',
        containerStyles,
      )}
    >
      <div className='w-full flex flex-row items-center justify-center relative'>
        <div className='flex items-center space-x-0.5'>
          <p
            className={classNames(
              'font-zen-body md:text-base text-sm text-center leading-6 font-normal text-zen-dark-12',
              labelStyles,
            )}
          >
            {label}
            {labelTooltip && (
              <WealthPlanTooltip
                title={labelTooltip}
                analyticsEvent={tooltipAnalyticsEvent}
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className='text-sm text-zen-dark-9 ml-1'
                  data-testid='tooltip'
                />
              </WealthPlanTooltip>
            )}
          </p>
        </div>
        {!!editButtonText && (
          <ZenButton
            label={editButtonText}
            onClick={onEditClick}
            variant='primary-link'
            LeftIconComponent={<FontAwesomeIcon icon={faPencil} size='xs' />}
            className='absolute right-0 top-0 !py-0'
          />
        )}
      </div>
      {value && (
        <p
          className={classNames(
            'font-zen-body',
            TextSizeVariantEnumMap[valueSizeVariant],
            valueStyles,
          )}
        >
          {value}
        </p>
      )}
      {!!subValue && (
        <p
          className={classNames(
            'font-zen-body text-base leading-6 font-normal text-zen-dark-7',
            subValueStyles,
          )}
        >
          {typeof subValue === 'string'
            ? subValue
            : `${formatMoneyValue({ amount: subValue }).split('$')[1]} Shares`}
        </p>
      )}
    </div>
  );
};

export default WealthPlanInfoCard;
