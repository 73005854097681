import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum, YesNoType } from '../../types';
import { formatPhoneNumber } from '../../utils/StringUtils';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import ZenButton from '../Zen/ZenButton';
import {
  WalletApplicationFormState,
  WalletApplicationStepName,
} from './WalletApplicationSteps';
import WalletReviewCard from './WalletReviewCard';
import withWalletApplicationProgress from './withWalletApplicationProgress';

const WalletReviewApplicationStep: StepByStepComponent<
  WalletApplicationFormState,
  WalletApplicationStepName
> = ({
  navigateTo,
  form: {
    watch,
    formState: { isSubmitting },
  },
  onSubmit,
  onPrevious,
}) => {
  const [showSSN, setShowSSN] = useState(false);
  const formData = watch();

  const billingAddress = useMemo(() => {
    return [
      formData.street,
      formData.apartmentOrUnitOrSuit,
      formData.city,
      formData.state.label,
      formData.zipCode,
    ].join(' ');
  }, [formData]);

  const shippingAddress = useMemo(() => {
    return formData.isShippingAddressSameAsBilling.some(
      (x) => x === YesNoType.YES,
    )
      ? billingAddress
      : [
          formData.shippingStreet,
          formData.shippingApartmentOrUnitOrSuit,
          formData.shippingCity,
          formData.shippingState?.label,
          formData.shippingZipCode,
        ].join(' ');
  }, [formData, billingAddress]);

  const reviewCards = [
    {
      label: 'Your Name',
      value: `${formData?.firstName} ${formData?.lastName}`,
      step: WalletApplicationStepName.PERSONAL_INFORMATION_STEP,
      disableEdit: true,
    },
    {
      label: 'Email Address',
      value: formData.emailAddress,
      step: WalletApplicationStepName.PERSONAL_INFORMATION_STEP,
    },
    {
      label: 'Phone Number',
      value: formatPhoneNumber(formData.phoneNumber),
      step: WalletApplicationStepName.PERSONAL_INFORMATION_STEP,
    },
    {
      label: 'Billing Address',
      value: billingAddress,
      step: WalletApplicationStepName.PERSONAL_INFORMATION_STEP,
    },
    {
      label: 'Shipping Address',
      value: shippingAddress,
      step: WalletApplicationStepName.PERSONAL_INFORMATION_STEP,
    },
    {
      label: 'Date of Birth',
      value: DateTime.fromISO(formData.birthDate).toFormat('MM/dd/yyyy'),
      step: WalletApplicationStepName.SSN_STEP,
    },
    {
      label: 'SSN',
      value: (
        <div className='flex items-center'>
          <span>
            {showSSN ? formData.ssn : Array(formData.ssn.length).fill('•')}
          </span>
          <FontAwesomeIcon
            icon={showSSN ? faEyeSlash : faEye}
            className='cursor-pointer ml-2'
            onClick={() => setShowSSN(!showSSN)}
            title='show-ssn'
          />
        </div>
      ),
      step: WalletApplicationStepName.SSN_STEP,
    },
  ];

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-3xl mx-auto flex-grow'>
        <h4 className='font-semibold font-poppins text-primary-dark text-xl'>
          Please review your information
        </h4>
        <div className='mt-4'>
          {reviewCards.map((card, idx) => (
            <WalletReviewCard
              key={card.label}
              hideBorder={idx === reviewCards.length - 1}
              counter={<span className='print-review-counter' />}
              onEdit={() => navigateTo(card.step)}
              label={card.label}
              value={card.value}
              disableEdit={card?.disableEdit}
            />
          ))}
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-primary-light'>
        <div className='w-full mx-auto max-w-3xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-outline'
              className='!font-inter font-medium text-lg border-primary-dark'
              label='Back'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              label='Submit Application'
              className='!font-inter font-medium text-lg bg-rezen-blue-600 border-rezen-blue-600'
              isSubmitting={isSubmitting}
              isDisabled={isSubmitting}
              onClick={() => {
                onSubmit();
                AnalyticsService.instance().logEvent(
                  AnalyticsEventEnum.WALLET_SUBMIT_APPLICATION,
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withWalletApplicationProgress(WalletReviewApplicationStep);
