import { DateTime } from 'luxon';

export interface AttachmentRowProps {
  title: string;
  icon?: React.ReactElement;
  label: string | React.ReactElement;
  action?: {
    text: string;
    onClick(): void;
  };
  date: number | undefined;
}

const ZenAttachmentRow: React.FC<AttachmentRowProps> = ({
  title,
  icon,
  label,
  action,
  date,
}) => {
  return (
    <div>
      <p className='py-1'>{title}</p>
      <div className='border border-solid rounded-lg border-gray-200 bg-white flex flex-row items-center justify-between px-4 py-4'>
        <div className='items-center flex flex-row space-x-2'>
          {icon && (
            <div className='w-8 h-8 flex justify-center items-center rounded-md border border-zen-dark-5'>
              {icon}
            </div>
          )}
          <div className='leading-tight ml-3'>
            <div className='text-base font-zen-body font-semibold text-zen-dark-9'>
              {label}
            </div>
            <div className='flex items-center text-sm space-x-1 font-zen-body font-semibold text-zen-dark-5'>
              {date && (
                <div>
                  Uploaded:{' '}
                  {DateTime.fromMillis(date).toLocaleString(
                    DateTime.DATETIME_SHORT,
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {action && (
          <button
            onClick={action.onClick}
            className='text-primary-blue font-zen-body font-semibold text-base'
          >
            {action.text}
          </button>
        )}
      </div>
    </div>
  );
};

export default ZenAttachmentRow;
