import classNames from 'classnames';
import React from 'react';

export type VariantType = 'primary' | 'success' | 'warning' | 'danger' | 'dark';

export const VariantToBgMap: {
  [type in VariantType]: string;
} = {
  primary: 'bg-primary bg-opacity-20 text-primary',
  success: 'bg-success bg-opacity-20 text-success',
  warning: 'bg-zen-warning-light text-zen-warning-dark',
  danger: 'bg-error bg-opacity-20 text-error',
  dark: 'bg-dark text-white',
};

interface ZenAnnounceProps {
  variant: VariantType;
  title?: string;
  subtitle?: string;
  icon?: React.ReactElement;
  fullWidth?: boolean;
}

const ZenAnnounce: React.FC<ZenAnnounceProps> = ({
  variant = 'primary',
  title,
  subtitle,
  icon,
  fullWidth = false,
}) => {
  return (
    <div
      className={classNames(
        'bg-white rounded-[10px] overflow-hidden',
        fullWidth && 'w-full',
      )}
    >
      <div
        className={classNames(
          VariantToBgMap[variant],
          'px-6 py-3 space-y-1',
          fullWidth && 'w-full',
        )}
      >
        <div className='flex flex-row items-start justify-between'>
          <div className='flex flex-row items-center space-x-2'>
            <div className='flex flex-col space-y-0.5'>
              <div className='flex flex-row items-center space-x-2'>
                {!!icon && icon}
                <p className='font-zen-body font-semibold text-base text-dark leading-6'>
                  {title}
                </p>
              </div>
              {subtitle && (
                <div className='space-y-4 flex flex-col py-2'>
                  <p className='font-zen-body text-sm text-dark'>{subtitle}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenAnnounce;
