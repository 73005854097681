import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { kebabCase } from 'lodash';
import { cn } from '../../utils/classUtils';

export interface MortgageAccordionProps {
  title: string;
  isComplete?: boolean;
  isOpenDefault?: boolean;
}

const MortgageAccordion: React.FC<MortgageAccordionProps> = ({
  title,
  isComplete = false,
  isOpenDefault = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenDefault);

  return (
    <div className='border border-zen-dark-4 rounded-lg' id={kebabCase(title)}>
      <div
        className={cn(
          'py-3 px-10 w-full flex flex-row justify-between items-center rounded-t-lg cursor-pointer bg-grey-100',
          { 'rounded-lg': !isOpen },
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className='flex items-center'>
          {isComplete && (
            <FontAwesomeIcon
              icon={solid('circle-check')}
              className='mr-2 mt-0.5 text-green-600 text-base'
            />
          )}

          <p className='font-zen-body font-semibold text-base text-zen-dark-9'>
            {title}
          </p>
        </div>

        <FontAwesomeIcon
          icon={isOpen ? regular('angle-up') : regular('angle-down')}
          className='text-zen-dark-8 text-xl'
        />
      </div>
      {isOpen ? <div className='p-10 space-y-6'>{children}</div> : null}
    </div>
  );
};

export default MortgageAccordion;
