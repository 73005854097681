import React from 'react';
import { RiAddFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CellProps, Column, Row } from 'react-table';
import {
  TransactionLiteResponse,
  TransactionResponse,
  TransactionResponseCountryEnum,
} from '../../../openapi/arrakis';
import { createTransactionBuilder } from '../../../slices/TransactionBuilderSlice';
import { isSmScreen } from '../../../utils/BrowserUtils';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { getSearchableTransactions } from '../../../utils/TransactionUtils';
import ZenViewActionButtonCell from '../../table/Cells/ZenViewActionButtonCell';
import AddressStateEnumSelectColumnFilter from '../../table/Filters/AddressStateEnumSelectColumnFilter';
import DateColumnFilter from '../../table/Filters/DateColumnFilter';
import NumberColumnFilter from '../../table/Filters/NumberColumnFilter';
import TextColumnFilter from '../../table/Filters/TextColumnFilter';
import TransactionStatusSelectColumnFilter from '../../table/Filters/TransactionStatusSelectColumnFilter';
import TransactionTypeSelectColumnFilter from '../../table/Filters/TransactionTypeSelectColumnFilter';
import ZenResourceIndexContainer from '../Containers/ZenResourceIndexContainer';
import ZenAddressCell from '../Table/Cell/ZenAddressCell';
import ZenDateCell from '../Table/Cell/ZenDateCell';
import ZenTransactionTypeCell from '../Table/Cell/ZenTransactionTypeCell';
import ZenTransactionCodeCell from '../Transaction/ZenTransactionCodeCell';
import ZenTransactionStatus from '../Transaction/ZenTransactionStatus';
import ZenButton from '../ZenButton';

interface ZenListingResourceTableProps {
  search?: string;
  officeIds?: string[];
}

export const columns: Array<Column<TransactionResponse>> = [
  {
    Header: 'Listing Code',
    accessor: 'code',
    Cell: ({ row: { original } }) => (
      <ZenTransactionCodeCell
        linkTo={`/listings/${original.id}`}
        transaction={(original as unknown) as TransactionLiteResponse}
        hideInvoice
      />
    ),
    cardColSize: 7,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Type',
    accessor: 'transactionType',
    Cell: ({ value }) => <ZenTransactionTypeCell dealType={value!} />,
    Filter: TransactionTypeSelectColumnFilter,
    cardColSize: 5,
  },
  {
    Header: 'Address',
    accessor: 'address',
    id: 'street',
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, string>) => (
      <ZenAddressCell address={original.address} />
    ),
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Price',
    accessor: 'price',
    Cell: ({ row: { original } }: CellProps<TransactionLiteResponse>) => (
      <div>
        {displayAmount(original.price!, { hideCurrency: true })}
        <div className='text-sm	text-zen-gray-4'>{original.price?.currency}</div>
      </div>
    ),
    Filter: NumberColumnFilter,
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Status',
    accessor: 'lifecycleState',
    Cell: ({
      value,
      row: { original },
    }: React.PropsWithChildren<CellProps<TransactionLiteResponse>>) => (
      <ZenTransactionStatus
        status={value.state!}
        country={
          (original.country as unknown) as TransactionResponseCountryEnum
        }
      />
    ),
    Filter: TransactionStatusSelectColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Listing Date',
    accessor: 'listingDate',
    Cell: ({ value }) => <ZenDateCell date={value!} />,
    Filter: DateColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Expiration Date',
    accessor: 'listingExpirationDate',
    Cell: ({ value }) => <ZenDateCell date={value!} />,
    Filter: DateColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'City',
    accessor: 'address',
    id: 'city',
    Cell: ({ value }) => value?.city,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'State / Province',
    accessor: 'address',
    id: 'state',
    Cell: ({ value }) => value?.state,
    Filter: AddressStateEnumSelectColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Postal Code',
    accessor: 'address',
    id: 'zip',
    Cell: ({ value }) => value?.zip,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
];

export const columnsWithAction: Array<Column<TransactionResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<TransactionResponse> }) => (
      <Link to={`/listings/${row.original.id}`}>
        <ZenViewActionButtonCell />
      </Link>
    ),
  },
  ...columns,
];

const ZenListingResourceTable: React.FC<ZenListingResourceTableProps> = ({
  search,
  officeIds,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div className='px-4 py-0 mt-5'>
      <ZenResourceIndexContainer<TransactionResponse>
        header='Listings'
        columns={columnsWithAction}
        search={search}
        RightComponent={
          <div className='flex flex-row space-x-2'>
            <ZenButton
              label='Create Listing'
              variant='primary'
              LeftIconComponent={<RiAddFill className='text-lg mb-0.5' />}
              onClick={async () => {
                const transactionBuilderId = await dispatch(
                  createTransactionBuilder('LISTING'),
                );
                if (transactionBuilderId) {
                  history.push(`/listing/create/${transactionBuilderId}`);
                }
              }}
            />
          </div>
        }
        resourceName='Listings'
        hiddenColumns={['id', 'zip', 'city', 'state', 'street2']}
        fetchData={async (req, cancelToken) => {
          return await getSearchableTransactions(req, true, officeIds, {
            cancelToken,
          });
        }}
        showSearch={isSmScreen()}
        initialSort={{ listingExpirationDate: 'asc' }}
        stickyHeader
      />
    </div>
  );
};

export default ZenListingResourceTable;
