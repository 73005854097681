import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenButton from '../../ZenButton';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
} from './ZenCreateTransactionSteps';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';

const ZenTransactionZeroCommissionDealStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({ onPrevious, onNext }) => {
  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div>
          <p className='text-xl font-zen-title font-medium text-zen-dark-9 mb-4'>
            Is this a zero commission deal?
          </p>
          <p className='mb-4 font-zen-body font-normal text-zen-dark-9'>
            It seems like you are trying to enter a zero commission deal. This
            requires an exception to be made by the Real team. Our team will
            either approve the deal or reach out to you for further
            clarifications. Additional fees may apply based on the situation. If
            this is a mistake, please fix the commission numbers.
          </p>
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='primary-outline'
              label='Let me fix the commission numbers'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              label='Yes this is a zero commission deal'
              onClick={onNext}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withCreateTransactionProgress(
  ZenTransactionZeroCommissionDealStep,
);
