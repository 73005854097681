import {
  Controller,
  FieldValues,
  UseControllerProps,
  FieldPath,
} from 'react-hook-form-v7';
import { AsyncPaginate } from 'react-select-async-paginate';
import { GroupTypeBase, MenuPosition, Styles } from 'react-select';
import { Option } from 'react-select/src/filters';
import { AsyncSelectAdditional, AsyncSelectOption } from '../types';
import { DEFAULT_PAGE_NUM } from '../constants/AsyncSelectPaginationConstants';
import FormErrorMessage from './FormErrorMessage';

export const ASYNC_MULTI_SELECT_DEBOUNCE = 400;

interface ControlledAsyncSelectInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  isRequired?: boolean;
  styles?: Partial<Styles<any, boolean, GroupTypeBase<any>>>;
  fetchData(search: string, page?: number): Promise<Array<AsyncSelectOption>>;
  filterOption?:
    | ((option: Option, rawInput: string) => boolean)
    | null
    | undefined;
  menuPosition?: MenuPosition;
}

const ControlledAsyncSelectInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  placeholder = 'Select',
  disabled = false,
  isRequired = false,
  readOnly,
  fetchData,
  styles,
  shouldUnregister = true,
  filterOption,
  menuPosition,
  ...rest
}: ControlledAsyncSelectInputProps<TFieldValues, TName>) => {
  return (
    <div className='space-y-1 w-full'>
      <Controller
        shouldUnregister={shouldUnregister}
        {...rest}
        render={({
          field: { onChange, value, name, onBlur },
          fieldState: { error },
        }) => (
          <div className='space-y-1 w-full'>
            {label && (
              <label className='inline-block' htmlFor={name}>
                {label}
                {isRequired && <span className='text-error'>*</span>}
              </label>
            )}
            <AsyncPaginate
              styles={styles}
              placeholder={placeholder}
              value={value}
              name={name}
              menuPosition={menuPosition}
              isDisabled={disabled || readOnly}
              defaultValue={value}
              // @ts-ignore
              loadOptions={async (
                search,
                _additionalOptions,
                { page }: AsyncSelectAdditional,
              ) => {
                const data = await fetchData(search, page);
                return {
                  options: data,
                  hasMore: !!data.length,
                  additional: {
                    page: page + 1,
                  },
                };
              }}
              additional={{
                page: DEFAULT_PAGE_NUM,
              }}
              debounceTimeout={ASYNC_MULTI_SELECT_DEBOUNCE}
              onChange={onChange}
              onBlur={onBlur}
              className='react-select-container'
              classNamePrefix='react-select'
              inputId={name}
              filterOption={filterOption}
            />
            {error && <FormErrorMessage message={error.message} />}
          </div>
        )}
      />
    </div>
  );
};
export default ControlledAsyncSelectInputV7;
