import React, { useEffect } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import { getRealSignatureUrl } from '../utils/UrlUtils';
import { getAuthCookie } from '../utils/AuthUtils';
import Logger from '../utils/Logger';

interface RedirectToRealSignatureRouteProps {}

const RedirectToRealSignatureRoute: React.FC<RedirectToRealSignatureRouteProps> = () => {
  useEffect(() => {
    const jwt = getAuthCookie();

    if (jwt) {
      Logger.debug('Redirecting to Real Signature...');
      window.location.href = getRealSignatureUrl(jwt);
    }
  }, []);

  return <DefaultLoader />;
};

export default RedirectToRealSignatureRoute;
