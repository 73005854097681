import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../../routes/AgentWebsiteOnboardingRoute';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import AgentWebsiteThemePreview from '../AgentWebsiteThemePreview';
import LabelValueDisplay from '../LabelValueDisplay';
import EditOutlineActionButton from '../EditOutlineActionButton';

const AgentWebsiteOnboardingThemeReview: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ form: { watch }, navigateTo }) => {
  const themeColor = watch('themeColor');
  const photoSet = watch('photoSet');

  return (
    <div className='p-4'>
      <div className='grid lg:grid-cols-2 grid-cols-1 gap-5'>
        <AgentWebsiteThemePreview
          photoSet={photoSet!}
          themeColor={themeColor!}
        />
        <div>
          <LabelValueDisplay
            label='Theme Image'
            value={photoSet! && capitalizeEnum(photoSet)}
          />
          <div className='mt-2'>
            <LabelValueDisplay
              label='Theme color'
              value={themeColor! && capitalizeEnum(themeColor)}
            />
          </div>
        </div>
      </div>
      <div className='mt-5 mb-2'>
        <EditOutlineActionButton
          text='Edit theme'
          icon={<FontAwesomeIcon icon={faPen} className='mt-1 mr-1' />}
          onClick={() => navigateTo(AgentWebsiteOnboardingStepName.THEME)}
        />
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingThemeReview;
