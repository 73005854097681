import { FundsReleaseResponseFromBankAccountTypeEnum } from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenPill, { ZenPillVariant } from '../../Zen/ZenPill';

export interface FundsReleaseBankAccountTypeCellProps {
  type: FundsReleaseResponseFromBankAccountTypeEnum;
}

const statusToPillVariantMap: EnumMap<
  FundsReleaseResponseFromBankAccountTypeEnum,
  ZenPillVariant
> = {
  COMMISSION: 'primary',
  OPERATING: 'success',
  TRUST: 'warning',
};

const ZenFundsReleaseBankAccountTypeCell: React.FC<FundsReleaseBankAccountTypeCellProps> = ({
  type,
}) => {
  return (
    <div className='inline-flex'>
      <ZenPill
        title={capitalizeEnum(type)}
        variant={statusToPillVariantMap[type]}
        border={false}
        textStyle='px-2'
      />
    </div>
  );
};

export default ZenFundsReleaseBankAccountTypeCell;
