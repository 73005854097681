import { cn } from '../utils/classUtils';

export interface HighlightedTextProps {
  text: string;
  textClassName?: string;
}

const HighlightedText: React.FC<HighlightedTextProps> = ({
  text,
  textClassName,
}) => {
  return (
    <span className={cn('p-1 bg-warning bg-opacity-30', textClassName)}>
      {text}
    </span>
  );
};

export default HighlightedText;
