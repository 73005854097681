import {
  faCircleDollar,
  faMoneyCheckDollarPen,
  faUserCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import millify from 'millify';
import React from 'react';
import { ReferralCentralReferralDtoClientTypeEnum } from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';
import Dot from './Dot';

interface ReferralInfoProps {
  clientRole: ReferralCentralReferralDtoClientTypeEnum;
  referralFee: number;
  minimumPrice: number;
  maximumPrice: number;
}

const ReferralInfo: React.FC<ReferralInfoProps> = ({
  clientRole,
  referralFee,
  maximumPrice,
  minimumPrice,
}) => {
  return (
    <div className='flex flex-row items-center justify-start space-x-1'>
      {clientRole && (
        <div className='flex flex-row items-center space-x-1'>
          <FontAwesomeIcon icon={faUserCircle} className='text-grey-500' />
          <p className='text-grey-600'>{capitalizeEnum(clientRole)}</p>
        </div>
      )}
      <Dot />
      <div className='flex flex-row justify-start items-center space-x-1'>
        <FontAwesomeIcon
          icon={faMoneyCheckDollarPen}
          size='sm'
          className='text-green-600'
        />
        <p className='text-green-600'>{referralFee}%</p>
      </div>
      <Dot />
      <div className='flex flex-row items-center space-x-1'>
        <FontAwesomeIcon icon={faCircleDollar} className='text-grey-500' />
        <p className='text-grey-600'>
          {millify(minimumPrice)} - {millify(maximumPrice)}
        </p>
      </div>
    </div>
  );
};

export default ReferralInfo;
