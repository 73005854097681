import { useState } from 'react';
import PageLayout from '../components/PageLayout';
import ZenCalendarsTable from '../components/Zen/Calendars/ZenCalendarsTable';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenPasswordGuard from './ZenPasswordGuard';

export const CALENDARS_PASSWORD = 'calendars';

interface CalendarsRouteProps {}

const CalendarsRoute: React.FC<CalendarsRouteProps> = () => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  return (
    <ZenRoute title='Calendars'>
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Calendars', url: '/calendars' },
        ]}
      >
        {!authenticated ? (
          <ZenPasswordGuard
            password={CALENDARS_PASSWORD}
            setAuthenticated={setAuthenticated}
          />
        ) : (
          <ZenCalendarsTable />
        )}
      </PageLayout>
    </ZenRoute>
  );
};

export default CalendarsRoute;
