import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DoneAll } from '@material-ui/icons';
import { useState } from 'react';
import { EnumMap } from '../../types';
import { cn } from '../../utils/classUtils';

export type GeminiCopyToClipboardVariant =
  | 'primary'
  | 'secondary'
  | 'noBackground';
interface GeminiCopyToClipboardProps {
  label?: string;
  value: string;
  variant?: GeminiCopyToClipboardVariant;
  className?: string;
  textStyle?: string;
  iconStyle?: string;
  leftIconElement?: React.ReactElement;
}

const GeminiCopyToClipboard: React.FC<GeminiCopyToClipboardProps> = ({
  value,
  label = 'Copy to Clipboard',
  variant = 'primary',
  className,
  textStyle,
  iconStyle,
  leftIconElement,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [text] = useState(value);
  const onCopyText = () => {
    if (navigator.clipboard && window.isSecureContext)
      navigator.clipboard.writeText(text);
    else {
      let textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      new Promise<void>((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const divVariantToClassNameMap: EnumMap<
    GeminiCopyToClipboardVariant,
    string
  > = {
    primary: '',
    secondary: 'rounded-lg bg-gray-100',
    noBackground: '',
  };

  const buttonVariantToClassNameMap: EnumMap<
    GeminiCopyToClipboardVariant,
    string
  > = {
    primary: 'text-rezen-blue-600 p-0.5',
    secondary: 'text-zen-dark-8 pl-1',
    noBackground:
      'text-primary-dark text-base font-light leading-snug font-inter',
  };

  return (
    <div className='flex flex-row cursor-pointer' onClick={() => onCopyText()}>
      <div
        className={cn(
          'flex flex-row items-center p-2 space-x-2',
          divVariantToClassNameMap[variant],
          className,
        )}
      >
        {leftIconElement && (
          <div className='flex items-center'>{leftIconElement}</div>
        )}
        <div
          className={cn(
            'flex items-center space-x-1 focus:outline-none font-inter font-normal text-sm',
            buttonVariantToClassNameMap[variant],
            textStyle,
          )}
          aria-label='copy-to-clipboard'
        >
          <p className='max-w-[300px] truncate'>{label}</p>
        </div>
        {isCopied ? (
          <div className='flex items-center justify-center ml-2 space-x-1 text-rezen-blue-600'>
            <DoneAll style={{ fontSize: 18 }} />
            <p className='text-xs'>Copied</p>
          </div>
        ) : (
          <FontAwesomeIcon
            icon={faCopy}
            className={cn(
              'h-4 w-4 cursor-pointer text-rezen-blue-600',
              iconStyle,
            )}
          />
        )}
      </div>
    </div>
  );
};

export default GeminiCopyToClipboard;
