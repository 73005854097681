import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import {
  InstantPaymentItemRequest,
  InstantPaymentTransactionSummaryResponse,
  InstantPaymentTransactionSummaryResponseStateEnum,
  MoneyValueCurrencyEnum,
} from '../../openapi/arrakis';
import AmplitudeService, {
  AmplitudeEvent,
} from '../../services/AmplitudeService';
import {
  addInstantPayment,
  fetchFeeCalculation,
  fetchInstantPaymentsSummary,
} from '../../slices/InstantPaymentSlice';
import { RootState } from '../../types';
import { displayAmount } from '../../utils/CurrencyUtils';
import {
  calculatePrincipalAmount,
  getInstantPaymentAmplitudeEventData,
  getProcessingFeesPercentage,
} from '../../utils/InstantPaymentHelper';
import Alert from '../Alert';
import AmplitudeEventOnLoad from '../Amplitude/AmplitudeEventOnLoad';
import Button from '../Button';
import ControlledIncrementalSliderV7 from '../ControlledIncrementalSliderV7';
import DoubleConfirmationModal from '../DoubleConfirmationModal';
import ResourceContainer from '../ResourceContainer';
import SidebarModal from '../SidebarModal';
import TextInfoRow from '../TextInfoRow';

interface FormData {
  amount: number;
}

interface EligibleTransactionInstantPaymentSidebarModalProps {
  transactionSummary: InstantPaymentTransactionSummaryResponse;
  onClose(): void;
}

const EligibleTransactionInstantPaymentSidebarModal: React.FC<EligibleTransactionInstantPaymentSidebarModalProps> = ({
  transactionSummary,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    auth: { userDetail },
    instantPayment: {
      feeCalculation,
      isFeeCalculationLoading,
      feeCalculationError,
    },
  } = useSelector((state: RootState) => state);
  const defaultCurrency = (userDetail?.defaultCurrency! as unknown) as MoneyValueCurrencyEnum;
  const minValueAllowed = transactionSummary.minValueAvailable?.amount || 0;
  const maxValueAllowed = transactionSummary.maxValueAvailable?.amount || 0;
  const { control, watch } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      amount: minValueAllowed,
    },
  });
  const amount = watch('amount');
  const processingFeesPercentage = getProcessingFeesPercentage(
    feeCalculation?.fees || [],
  );
  const principalAmount = calculatePrincipalAmount(feeCalculation?.fees || []);
  const [isTransferingAmount, setIsTransferingAmount] = useState(false);
  const [showDoubleConfirmation, setShowDoubleConfirmation] = useState(false);

  const fetchCalculateFees = (finalAmount: number) => {
    const request: Required<InstantPaymentItemRequest> = {
      principal: { amount: finalAmount, currency: defaultCurrency },
      transactionId: transactionSummary.transaction?.id!,
    };

    dispatch(fetchFeeCalculation(userDetail?.id!, request));
  };

  const debounceCalculateFees = useCallback(
    debounce(fetchCalculateFees, 500),
    [],
  );

  const transferAmount = async () => {
    setIsTransferingAmount(true);
    await dispatch(
      addInstantPayment(userDetail?.id!, {
        checksum: feeCalculation?.checksum,
        principal: {
          amount: amount,
          currency: (userDetail?.defaultCurrency as unknown) as MoneyValueCurrencyEnum,
        },
        transactionId: transactionSummary.transaction?.id,
      }),
    );
    await dispatch(fetchInstantPaymentsSummary(userDetail?.id!, false));
    setIsTransferingAmount(false);
    onClose();
  };

  useEffect(() => {
    // Fixes an issue on first load.
    if (minValueAllowed && amount >= minValueAllowed) {
      debounceCalculateFees(amount);
    }
  }, [amount, debounceCalculateFees, minValueAllowed]);

  const showMaxMessage =
    transactionSummary.state ===
    InstantPaymentTransactionSummaryResponseStateEnum.EligibleBelowMax;

  const eventData = getInstantPaymentAmplitudeEventData(
    transactionSummary.transaction,
  );

  return (
    <SidebarModal
      title='Instant Payment'
      subtitle={transactionSummary.transaction?.addressOneLine}
      isOpen
      onClose={onClose}
    >
      <div className='p-4'>
        {showMaxMessage && (
          <Alert
            icon={<FontAwesomeIcon icon={faCircleInfo} />}
            className='mb-3'
            text='You are close to your instant payment limit. The maximum amount you can borrow for this transaction has been reduced.'
            variant='warning'
          />
        )}
        <div className='flex flex-row space-x-2 items-center pb-4 pt-2'>
          <p className='text-base text-dark'>Amount To Transfer: </p>
          <p className='text-xl'>
            {displayAmount(
              { amount, currency: defaultCurrency },
              { hideZeroCents: true },
            )}
          </p>
        </div>
        <div className='mb-4'>
          <ControlledIncrementalSliderV7<FormData, 'amount'>
            control={control}
            minValue={minValueAllowed}
            maxValue={maxValueAllowed}
            step={50}
            name='amount'
          />
          <div className='flex items-center justify-between font-primary-light'>
            <span>
              {displayAmount({
                amount: minValueAllowed,
                currency: defaultCurrency,
              })}
            </span>
            <span>
              {displayAmount({
                amount: maxValueAllowed,
                currency: defaultCurrency,
              })}
            </span>
          </div>
        </div>
        <div className='border-b w-full' />
        <p className='mt-4 font-primary-regular text-dark'>Cost Breakup</p>

        <ResourceContainer
          loading={isFeeCalculationLoading}
          errorCode={feeCalculationError}
          isEmpty={false}
          resourceName='fees'
        >
          <div className='flex flex-col space-y-2 mt-2'>
            <TextInfoRow
              title='Instant Payment amount'
              value={displayAmount({
                amount: principalAmount,
                currency: defaultCurrency,
              })}
            />
            <TextInfoRow
              title='Processing fees'
              value={
                processingFeesPercentage
                  ? `${processingFeesPercentage}%`
                  : 'N/A'
              }
            />
          </div>
        </ResourceContainer>
        <div className='mt-4'>
          <Button
            label='Transfer Amount'
            buttonType='button'
            isSubmitting={isTransferingAmount}
            fullWidth
            size='lg'
            onClick={() => {
              AmplitudeService.logEvent(
                AmplitudeEvent.INSTANT_PAYMENTS_CREATE_ATTEMPT,
                { ...eventData, amount },
              );
              setShowDoubleConfirmation(true);
            }}
          />
        </div>
      </div>
      <DoubleConfirmationModal
        title='Confirmation Required'
        subtitle={`By clicking confirm, you are accepting the terms and conditions of the instant payment plan and will be sent a payment of ${displayAmount(
          {
            amount: amount,
            currency: defaultCurrency,
          },
        )}`}
        variant='error'
        isOpen={showDoubleConfirmation}
        isSubmitting={isTransferingAmount}
        onClose={() => {
          AmplitudeService.logEvent(
            AmplitudeEvent.INSTANT_PAYMENTS_CREATE_CANCEL,
            { ...eventData, amount },
          );
          setShowDoubleConfirmation(false);
        }}
        onConfirm={() => {
          AmplitudeService.logEvent(
            AmplitudeEvent.INSTANT_PAYMENTS_CREATE_SUCCESS,
            { ...eventData, amount },
          );
          transferAmount();
        }}
      />
      <AmplitudeEventOnLoad
        eventName={AmplitudeEvent.INSTANT_PAYMENTS_CREATE_PAGE_VIEW}
        eventData={eventData}
      />
    </SidebarModal>
  );
};

export default EligibleTransactionInstantPaymentSidebarModal;
