import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as RealInfo } from '../../assets/icons/realInfo.svg';
import {
  FeaturesResponseEligibleEnum,
  TransactionControllerApi,
} from '../../openapi/arrakis';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import {
  fetchTransactionDetails,
  getTransactionFeatures,
} from '../../slices/TransactionSlice';
import { RootState } from '../../types';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { getTransactionRealTitleName } from '../../utils/RealTitleUtils';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../Zen/ZenButton';

interface FormData {
  qualiaId: string;
}

interface ConnectWithQualiaProps {
  transactionId: string;
}

const ConnectWithQualia: React.FC<ConnectWithQualiaProps> = ({
  transactionId,
}) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, trigger, clearErrors } = useForm<FormData>();
  const {
    transactionDetailResponse: { data: transaction },
    features,
  } = useSelector((state: RootState) => state.transaction);
  const isEligibleForTitle = !!features?.eligible?.includes(
    FeaturesResponseEligibleEnum.Title,
  );
  const usingTitle = !!transaction?.usingTitle;
  const [
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
  ] = useState<boolean>(false);
  const [isSubmittingQualiaId, setIsSubmittingQualiaId] = useState<boolean>(
    false,
  );
  const [isDisconnecting, setIsDisconnecting] = useState<boolean>(false);

  const onSubmit = async (data: FormData) => {
    setIsSubmittingQualiaId(true);
    try {
      await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).updateTitleOrder(transactionId, {
        qualiaOrderId: data?.qualiaId,
      });
      dispatch(showSuccessToast('Successfully updated qualia ID.'));
      dispatch(fetchTransactionDetails(transactionId));
      dispatch(getTransactionFeatures(transactionId));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error updating qualia ID', e, {
        transaction: { id: transactionId, qualiaID: data?.qualiaId },
      });
      dispatch(
        showErrorToast(
          'We had a problem updating qualia ID',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setIsSubmittingQualiaId(false);
      setIsConfirmationModalOpen(false);
    }
  };

  const handleDisconnectFromQualia = async () => {
    setIsDisconnecting(true);
    try {
      await new TransactionControllerApi(getArrakisConfiguration()).cancelTitle(
        transactionId,
      );
      dispatch(
        showSuccessToast('Successfully disconnected transaction from qualia.'),
      );
      dispatch(fetchTransactionDetails(transactionId));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error disconnecting transaction errors', e, {
        data: {
          transaction: transactionId,
        },
      });
      dispatch(
        showErrorToast(
          'We had a problem disconnecting transaction errors',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setIsDisconnecting(false);
      setIsConfirmationModalOpen(false);
    }
  };

  return (
    <div className='w-full p-4' data-testid='connect-with-qualia'>
      {usingTitle ? (
        <div className='w-full flex flex-row items-center justify-end'>
          <div className='flex md:flex-row flex-col items-center space-x-2'>
            <ZenButton
              label='Disconnect from Qualia'
              onClick={() => setIsConfirmationModalOpen(true)}
            />
            {isEligibleForTitle && (
              <div className='flex space-x-1.5 items-center'>
                <RealInfo />
                <div className='text-sm text-primary-blue font-zen-body font-medium'>
                  {`Closing With ${getTransactionRealTitleName(
                    transaction?.address?.state,
                  )}`}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className='md:w-1/2 w-full'>
          <div className='flex md:flex-row flex-col items-start space-x-2'>
            <ZenControlledTextInput<FormData, 'qualiaId'>
              name='qualiaId'
              control={control}
              placeholder='Qualia Order ID'
              rules={{
                required: 'Qualia Order ID is required',
              }}
              isRequired
            />
            <div className='flex-shrink-0'>
              <ZenButton
                label='Connect to Qualia'
                onClick={async () => {
                  const isValid = await trigger();
                  if (isValid) {
                    clearErrors();
                    setIsConfirmationModalOpen(true);
                  }
                }}
                isFullWidth
              />
            </div>
          </div>
        </div>
      )}
      <ZenConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        title={usingTitle ? 'Disconnect from Qualia' : 'Connect to Qualia'}
        variant={usingTitle ? 'danger' : 'primary'}
        confirmButtonText={usingTitle ? 'Disconnect' : 'Connect'}
        subtitle={
          usingTitle
            ? 'Are you sure you want to disconnect from Qualia?'
            : 'Are you sure you want to connect to Qualia?'
        }
        onConfirm={
          usingTitle ? handleDisconnectFromQualia : handleSubmit(onSubmit)
        }
        isSubmitting={usingTitle ? isDisconnecting : isSubmittingQualiaId}
        isDisabled={usingTitle ? isDisconnecting : isSubmittingQualiaId}
      />
    </div>
  );
};

export default ConnectWithQualia;
