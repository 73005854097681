import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CreateWebhookRequest,
  UpdateWebhookRequest,
  WebhookDto,
  WebhookEventsDto,
  WebhooksApi,
} from '../openapi/plutus';
import ErrorService from '../services/ErrorService';
import { AppThunk, ErrorCode, WebhooksState } from '../types';
import { getApiErrorMessage } from '../utils/ErrorUtils';
import { getWealthManagementConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from './ErrorSlice';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from './ToastNotificationSlice';

export const initialState: WebhooksState = {
  webhooksLoading: false,
  webhooks: undefined,
  webhooksByUserIdErrorCode: null,
  webhookDetails: {},
  webhookEvents: {
    events: {},
  },
};

const WebhookSlice = createSlice({
  name: 'webhooks',
  initialState,
  reducers: {
    changeWebhooksLoading(state, action: PayloadAction<boolean>) {
      state.webhooksLoading = action.payload;
    },
    saveWebhooks(state, action: PayloadAction<WebhookDto[]>) {
      state.webhooks = action.payload;
      state.webhooksByUserIdErrorCode = null;
    },
    errorFetchingWebhooksByUserId(state, action: PayloadAction<ErrorCode>) {
      state.webhooksByUserIdErrorCode = action.payload;
    },
    saveWebhookDetails(state, action: PayloadAction<WebhookDto>) {
      state.webhookDetails = action.payload;
    },
    saveWebhookEvents(state, action: PayloadAction<WebhookEventsDto>) {
      state.webhookEvents = action.payload;
    },
  },
});

export const {
  changeWebhooksLoading,
  saveWebhooks,
  errorFetchingWebhooksByUserId,
  saveWebhookDetails,
  saveWebhookEvents,
} = WebhookSlice.actions;

export const fetchWebhooksByUserId = (
  userId: string,
  loading: boolean = true,
): AppThunk => async (dispatch) => {
  if (loading) {
    dispatch(changeWebhooksLoading(true));
  }
  try {
    const { data } = await new WebhooksApi(
      await getWealthManagementConfiguration(),
    ).getWebhookByUserId(userId);
    dispatch(saveWebhooks(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch webhooks', e, {
      webhooks: { userId },
    });
  } finally {
    if (loading) {
      dispatch(changeWebhooksLoading(false));
    }
  }
};

export const fetchWebhookDetails = (webhookId: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new WebhooksApi(
      await getWealthManagementConfiguration(),
    ).getWebhook(webhookId);
    dispatch(saveWebhookDetails(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch webhook details', e, {
      webhooks: { webhookId },
    });
  }
};

export const fetchWebhookEvents = (): AppThunk => async (dispatch) => {
  dispatch(changeWebhooksLoading(true));
  try {
    const { data } = await new WebhooksApi(
      await getWealthManagementConfiguration(),
    ).getWebhookEvents();
    dispatch(saveWebhookEvents(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch webhook events', e);
  } finally {
    dispatch(changeWebhooksLoading(false));
  }
};

export const createWebhook = (
  webhookDetails: CreateWebhookRequest,
): AppThunk => async (dispatch) => {
  try {
    await new WebhooksApi(
      await getWealthManagementConfiguration(),
    ).createWebhook(webhookDetails);
    dispatch(showSuccessToast('Endpoint created successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to create the endpoint.',
        getApiErrorMessage(e),
      ),
    );

    ErrorService.notify('Unable to create the endpoint', e, {
      values: webhookDetails,
    });
  }
};

export const editWebhook = (
  webhookId: string,
  webhookDetails: UpdateWebhookRequest,
): AppThunk => async (dispatch) => {
  try {
    await new WebhooksApi(
      await getWealthManagementConfiguration(),
    ).updateWebhook(webhookId, webhookDetails);

    dispatch(showSuccessToast('Webhooks edited successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to edit the webhook.',
        getApiErrorMessage(e),
      ),
    );
    ErrorService.notify('Unable to edit the webhook', e, {
      values: webhookDetails,
    });
  }
};

export const deleteWebhook = (
  webhookId: string,
  userId: string,
): AppThunk => async (dispatch) => {
  try {
    await new WebhooksApi(
      await getWealthManagementConfiguration(),
    ).deleteWebhook(webhookId);

    dispatch(fetchWebhooksByUserId(userId, false));

    dispatch(showSuccessToast('Webhook deleted successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));

    dispatch(
      showErrorToastForErrorCode(
        'We were unable to delete the webhook.',
        ErrorService.getErrorCode(e),
      ),
    );

    ErrorService.notify('Unable to delete the webhook', e, {
      webhook: { webhookId },
    });
  }
};

export const updateWebhookStatus = (
  webhookId: string,
  status: boolean,
  userId: string,
): AppThunk => async (dispatch) => {
  try {
    await new WebhooksApi(
      await getWealthManagementConfiguration(),
    ).updateWebhook1(webhookId, { active: status });

    dispatch(fetchWebhooksByUserId(userId, false));

    dispatch(showSuccessToast('Webhook status updated successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));

    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update the webhook.',
        ErrorService.getErrorCode(e),
      ),
    );

    ErrorService.notify('Unable to update the webhook status', e, {
      webhook: { webhookId },
    });
  }
};

export default WebhookSlice.reducer;
