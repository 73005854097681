import React from 'react';
import classNames from 'classnames';
import LeoNoteBulb from '../../../../assets/img/leo/leo-note-bulb.png';

interface FeedbackNoteProps {
  hasAdditionalInfo: boolean;
  feedbackNote: string | null | undefined;
}

export const FeedbackNote: React.FC<FeedbackNoteProps> = ({
  hasAdditionalInfo,
  feedbackNote,
}) => {
  if (!feedbackNote) {
    return null;
  }

  return (
    <div
      className={classNames(
        'w-fit min-w-[250px] rounded-md p-3 relative bg-[#715E99] flex flex-row',
        {
          'mt-3': hasAdditionalInfo,
        },
      )}
    >
      <img src={LeoNoteBulb} alt='leo-note-feedback' className='h-10 pr-3' />
      <div className='flex-grow'>
        <p
          className={classNames(
            'text-white text-base whitespace-pre-wrap font-inter font-normal word-break',
          )}
        >
          {feedbackNote}
        </p>
      </div>
    </div>
  );
};
