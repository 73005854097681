import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps, ISelectOption } from '../../types';
import ZenCustomGroupRadioButton from '../ZenCustomGroupRadioButton';
import { cn } from '../../utils/classUtils';
import ZenFormErrorMessage from './Input/ZenFormErrorMessage';

export interface ZenControlledGroupRadioButtonInputV2Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  options: Array<ISelectOption>;
  disabled?: boolean;
  isRequired?: boolean;
  fullWidth?: boolean;
  minWidth?: string;
}

const ZenControlledGroupRadioButtonInputV2 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  disabled = false,
  options,
  tooltipIndex,
  shouldUnregister = true,
  isRequired,
  fullWidth = false,
  minWidth = 'w-20',
  ...rest
}: ZenControlledGroupRadioButtonInputV2Props<TFieldValues, TName>) => {
  const {
    field: { onChange, value, name },
    fieldState: { error, invalid },
  } = useController<TFieldValues, TName>(rest);

  return (
    <div className={cn(fullWidth ? 'w-full' : 'flex', 'space-y-2')}>
      <div className='flex flex-col space-y-1'>
        {label && (
          <label className='inline-block' htmlFor={name}>
            <span
              className={cn(
                'font-zen-body font-semibold text-base',
                invalid ? 'text-zen-danger' : 'text-zen-dark-9',
              )}
            >
              {label}
            </span>
            {isRequired && <span className='text-zen-danger'>*</span>}
          </label>
        )}
        <div data-tooltip-index={tooltipIndex} className={`${minWidth}`}>
          <ZenCustomGroupRadioButton
            items={options}
            onChange={onChange}
            value={value}
            disabled={disabled}
            variant='primary'
          />
        </div>
      </div>
      {error && <ZenFormErrorMessage message={error.message} />}
    </div>
  );
};

export default ZenControlledGroupRadioButtonInputV2;
