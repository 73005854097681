import PreCapJoinBlur from '../../../assets/img/wealthManagement/pre-cap-join-blur.png';
import OneToTwo from '../../../assets/img/wealthManagement/one-to-two.png';
import {
  CANADA_SUPPORT_EMAIL_ADDRESS,
  SUPPORT_EMAIL_ADDRESS,
} from '../../../utils/SupportUtils';
import AnalyticsService from '../../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../../types';
import { useIsUSAgent } from '../USOnly';

export const HowToJoinSpp = () => {
  const isUSAgent = useIsUSAgent();

  return (
    <div
      className='w-full relative bg-contain bg-no-repeat h-[450px]'
      style={{
        backgroundImage: `url(${PreCapJoinBlur})`,
        backgroundSize: '100% 100%',
      }}
    >
      <div className='flex justify-center items-center flex-col px-3 lg:px-40 mt-10'>
        <div className='border border-zen-dark-13 border-solid rounded-2xl p-10 flex w-full mt-10 shadow-md bg-white text-xl lg:text-3xl'>
          <img src={OneToTwo} alt='oneToTwo' className='h-40 bg-white' />
          <div className='flex flex-col justify-between pl-10 py-1 w-full bg-white'>
            <div className='text-zen-dark-13 font-semibold font-zen-body'>
              Elect SPP when you join Real
            </div>
            <hr />
            <div className='text-zen-dark-13 font-semibold font-zen-body'>
              Contact{' '}
              <a
                href={`mailto:${
                  isUSAgent
                    ? SUPPORT_EMAIL_ADDRESS
                    : CANADA_SUPPORT_EMAIL_ADDRESS
                }}`}
                className='text-primary-blue'
                onClick={() => {
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.WEALTH_PLAN_LP_PRE_CAP_SPP_CLICK_ON_SUPPORT,
                  );
                }}
              >
                Support
              </a>{' '}
              to join anytime
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
