import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenButton from '../../ZenButton';
import {
  CreateBeneficiaryV2FormData,
  CreateBeneficiaryV2StepName,
} from './CreateBeneficiaryScreenV2';
import Message from './Message';

const CreateBeneficiaryGotItStepV2: StepByStepComponent<
  CreateBeneficiaryV2FormData,
  CreateBeneficiaryV2StepName
> = ({ onNext }) => {
  return (
    <div className='w-full relative shadow-zen-web bg-white rounded-xl'>
      <Message
        title='Got it!'
        subtitleComponent={
          <p className='w-full px-6 font-zen-body text-base text-zen-dark-7 text-center leading-6'>
            <span>If you change your mind you can always find the </span>
            <span className='font-bold'>Designate beneficiary</span>{' '}
            <span>button in your profile under the Revenue Share section.</span>
          </p>
        }
      />
      <div className='absolute rounded-b-xl bg-white bottom-0 right-0 left-0 z-50 px-14'>
        <div className='justify-center flex flex-row items-center py-8'>
          <div className='w-1/2'>
            <ZenButton
              label='Okay'
              type='button'
              onClick={onNext}
              variant='primary-outline'
              isFullWidth
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBeneficiaryGotItStepV2;
