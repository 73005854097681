import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FututeMaintenanceResponse,
  MaintenanceControllerApi,
  MaintenanceResponse,
} from '../openapi/bff';
import ErrorService from '../services/ErrorService';
import { AppThunk, MaintenanceState } from '../types';
import { getBffConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showAndReportErrors } from '../utils/ErrorUtils';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: MaintenanceState = {
  maintenance: {},
  futureMaintenanceDetail: {},
};

const MaintenanceSlice = createSlice({
  name: 'maintenanceSlice',
  initialState,
  reducers: {
    saveMaintenance(state, action: PayloadAction<MaintenanceResponse>) {
      state.maintenance = action.payload;
    },
    saveFutureMaintenance(
      state,
      action: PayloadAction<FututeMaintenanceResponse>,
    ) {
      state.futureMaintenanceDetail = action.payload;
    },
  },
});

export const {
  saveMaintenance,
  saveFutureMaintenance,
} = MaintenanceSlice.actions;

export const fetchMaintenancePeriod = (): AppThunk => async (dispatch) => {
  try {
    const { data } = await new MaintenanceControllerApi(
      getBffConfiguration(),
    ).checkIfInMaintenancePeriod();
    dispatch(saveMaintenance(data));
  } catch (e) {
    if (showAndReportErrors(e?.response)) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to get the maintenance period', e);
    }
  }
};

export const fetchFutureMaintenancePeriod = (): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new MaintenanceControllerApi(
      getBffConfiguration(),
    ).checkFutureMaintenancePeriod();
    dispatch(saveFutureMaintenance(data));
  } catch (e) {
    if (showAndReportErrors(e?.response)) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to get the Future maintenance period', e);
    }
  }
};

export default MaintenanceSlice.reducer;
