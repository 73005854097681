import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { AppDispatch, RootState } from '../../../types';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import ResourceContainer from '../../ResourceContainer';
import { displayAmount } from '../../../utils/CurrencyUtils';
import DefaultLoader from '../../DefaultLoader';
import { getFullName } from '../../../utils/AgentHelper';
import ZenAvatar from '../../Zen/ZenAvatar';
import {
  RevshareContributionsByTierResponse,
  RevShareControllerApi,
} from '../../../openapi/arrakis';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import ErrorService from '../../../services/ErrorService';
import { FilterDates } from '../../../utils/RevShareInsightsUtils';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import RevShareInsightsTierFilters from '../RevShareInsightsTierFilters';
import RevShareInsightsContributionByTierCards from '../RevShareInsightsContributionByTierCards';
import ZenDateCell from '../../Zen/Table/Cell/ZenDateCell';

interface RevShareInsightsContributionsByTierSidebarProps {
  isOpen: boolean;
  onClose(): void;
  agentId: string;
  filterDates: FilterDates;
  initialTier: number;
}

const RevShareInsightsContributionsByTierSidebar: React.FC<RevShareInsightsContributionsByTierSidebarProps> = ({
  isOpen,
  onClose,
  agentId,
  filterDates,
  initialTier,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState(true);
  const [tier, setTier] = useState(initialTier);
  const [
    revShareContributionByTier,
    setRevShareContributionByTier,
  ] = useState<RevshareContributionsByTierResponse>();

  const {
    revenue: { revShareByTierResponse },
  } = useSelector((state: RootState) => state);

  const fetchData = useCallback(
    async (pageNum: number) => {
      if (pageNum === 0) {
        setLoading(true);
      }
      try {
        const { data } = await new RevShareControllerApi(
          getArrakisConfiguration(),
        ).getRevshareContributionsByTier(
          agentId,
          tier,
          filterDates.startDate,
          filterDates.endDate,
          pageNum,
          20,
        );
        if (pageNum === 0) {
          setRevShareContributionByTier(data);
        } else {
          setRevShareContributionByTier((prev) => ({
            ...data,
            revshareContributions: prev?.revshareContributions?.concat(
              data.revshareContributions || [],
            ),
          }));
        }
      } catch (e) {
        dispatch(showApiErrorModal(e));
        dispatch(
          showErrorToast(
            'We were unable to fetch information on your revenue share contributions by tier',
          ),
        );
        ErrorService.notify(
          'Unable to fetch information on revshare contributions by tier',
          e,
          {
            agent: { id: agentId },
          },
        );
      }
      setLoading(false);
    },
    [agentId, dispatch, filterDates.endDate, filterDates.startDate, tier],
  );

  useEffect(() => {
    fetchData(0);
  }, [fetchData]);

  const revShareByTier =
    revShareByTierResponse.data?.tierRevshareResponses || [];
  const contributions = revShareContributionByTier?.revshareContributions;
  const tierRevShare = revShareByTier[tier - 1];

  return (
    <ZenSidebarModal isOpen={isOpen} onClose={onClose} title='Contributions'>
      <div className='p-4'>
        <div className='mb-4 space-y-4'>
          <RevShareInsightsTierFilters
            tier={tier}
            loading={loading}
            onChange={setTier}
          />
          <RevShareInsightsContributionByTierCards
            tierRevShare={tierRevShare}
          />
        </div>
        <div className='-mx-4'>
          <div className='bg-grey-100 flex items-center justify-between px-4 py-2'>
            <p className='font-zen-body text-zen-dark-12 text-xs font-medium'>
              CONTRIBUTIONS ({revShareContributionByTier?.totalCount || 0})
            </p>
          </div>
          <ResourceContainer
            loading={loading}
            isEmpty={isEmpty(contributions)}
            resourceName='contribution'
          >
            <div className='divide-y divide-zen-light-gray-2 text-zen-dark-9 font-zen-body'>
              <InfiniteScroll
                hasMore={!!revShareContributionByTier?.hasNext}
                loader={<DefaultLoader />}
                dataLength={contributions?.length || 0}
                scrollableTarget='modal-scroll'
                next={() =>
                  fetchData((revShareContributionByTier?.pageNumber || 0) + 1)
                }
              >
                {contributions?.map((contribution) => {
                  const name = getFullName(contribution.agent) || 'N/A';

                  return (
                    <div
                      key={contribution.agent?.id}
                      aria-label='contribution-row'
                      className='px-4 py-2 flex items-center space-x-2'
                    >
                      <ZenAvatar
                        name={name}
                        imageUrl={contribution.agent?.imageUrl}
                      />
                      <div className='flex-grow'>
                        <p className='flex items-center space-x-2 font-medium'>
                          <span>{name}</span>
                          <FontAwesomeIcon
                            icon={faCircle}
                            className='w-1 h-1 text-zen-dark-12'
                          />
                          <span className='text-sm font-normal'>
                            Tier {contribution.tier}
                          </span>
                        </p>
                        <p className='text-sm text-zen-dark-12'>
                          {contribution.transaction?.addressOneLine}
                        </p>
                      </div>
                      <div>
                        <p className='text-right'>
                          {displayAmount(contribution.amount)}
                        </p>
                        <p className='flex items-center'>
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className='text-zen-gray-4 text-sm mr-1'
                          />
                          <ZenDateCell
                            date={contribution.transaction?.closedAt}
                          />
                        </p>
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          </ResourceContainer>
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default RevShareInsightsContributionsByTierSidebar;
