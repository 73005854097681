import { useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsService from '../../../../services/AnalyticsService';
import {
  getEliteAgentCulturalAward,
  setSelectedEarningsOverviewType,
  updateWealthGoals,
} from '../../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum, RootState } from '../../../../types';
import { formatMoneyValue } from '../../../../utils/CurrencyUtils';
import ZenControlledSquareToggleInput from '../../../Zen/Input/ZenControlledSquareToggleInput';
import IncomeProjectionChart from '../../V2/IncomeProjectionChart';
import { GoalsFormInput } from '../../V2/SetGoalsSideBarV2';
import WealthPlanInfoCard from '../../V2/WealthPlanInfoCard';
import WealthPlanSidebarContainer from './WealthPlanSidebarContainer';

const EliteAgentCulturalAwardSidebarContentV2 = () => {
  const dispatch = useDispatch();
  const {
    eliteAgentCulturalAwardResponse: {
      loading: eliteAgentCulturalAwardLoading,
      data: eliteAgentCulturalAwardResponse,
    },
    wealthGoalsResponse: { data: wealthGoalsResponse },
  } = useSelector((state: RootState) => state.wealthManagement);

  const {
    projectedEliteAgentCulturalAwardValue,
    currentEliteAgentCulturalAwardValue,
    currentCycleValue,
    projectedEliteAgentCulturalAwardShares,
    currentEliteAgentCulturalAwardShares,
    currentCycleShares,
  } = eliteAgentCulturalAwardResponse || {};

  const isLoading =
    eliteAgentCulturalAwardLoading && !eliteAgentCulturalAwardResponse;

  const { control } = useForm<GoalsFormInput>({
    defaultValues: {
      eliteAgentCulturalAwardParticipation: true,
    },
  });

  useEffect(() => {
    dispatch(getEliteAgentCulturalAward());
  }, [dispatch, wealthGoalsResponse]);

  const handleChange = () => {
    dispatch(setSelectedEarningsOverviewType(undefined));
    dispatch(
      updateWealthGoals({
        eliteAgentCulturalAwardParticipation: false,
      }),
    );
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.WEALTH_PLAN_ELITE_CULTURAL_AWARD_PARTICIPATION_TO_NO,
    );
  };

  return (
    <WealthPlanSidebarContainer
      type='ELITE_CULTURAL_AWARD'
      resourceName='Cultural Award'
      loading={isLoading}
      isEmpty={!eliteAgentCulturalAwardResponse}
    >
      <WealthPlanInfoCard
        label='5 Year Projected Value'
        labelTooltip='Adjust your Commissions transactions and volume to achieve Elite Agent status'
        valueSizeVariant='text-3xl'
        value={formatMoneyValue(projectedEliteAgentCulturalAwardValue)}
        subValue={projectedEliteAgentCulturalAwardShares}
      />
      <div className='flex flex-row justify-between space-x-3 my-3'>
        <WealthPlanInfoCard
          label='Value of Shares Earned-to-Date'
          value={formatMoneyValue(currentEliteAgentCulturalAwardValue)}
          subValue={currentEliteAgentCulturalAwardShares}
        />
        <WealthPlanInfoCard
          label='Number of Shares Earned-to-Date'
          value={formatMoneyValue(currentCycleValue)}
          subValue={currentCycleShares}
        />
      </div>
      <IncomeProjectionChart
        earningType='ELITE_CULTURAL_AWARD'
        title={{ text: '5 Year Projected Value' }}
      />
      <ZenControlledSquareToggleInput<
        GoalsFormInput,
        'eliteAgentCulturalAwardParticipation'
      >
        control={control}
        label='Will you participate in the Elite Agent Cultural Award?'
        name='eliteAgentCulturalAwardParticipation'
        onChangeSpy={handleChange}
      />
      <div className='border-b border-gray-300' />
    </WealthPlanSidebarContainer>
  );
};

export default EliteAgentCulturalAwardSidebarContentV2;
