import { duotone, light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { motion, Variants } from 'framer-motion';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import IconButton from '../IconButton';
import {
  getDefaultEarningsRequired,
  getDefaultNetworkSizeRequired,
  revShareInsightsBadgeDetailDescriptionMap,
  revShareInsightsBadgeDetailLockedMap,
  revShareInsightsBadgeDetailTitleMap,
  revShareInsightsBadgeDetailUnlockedMap,
  showCurrentEarnings,
  showCurrentNetwork,
  showZeroReferredAgentMsg,
} from '../../utils/RevShareInsightsUtils';
import { BadgeResponseNameEnum } from '../../openapi/insignia';
import { RootState } from '../../types';
import ZenColoredProgressBar from '../ZenColoredProgressBar';
import { compactNumber } from '../../utils/MathUtils';

const backDropVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const modalVariants: Variants = {
  initial: { translateY: '100%' },
  animate: { translateY: '0%' },
  exit: { translateY: '100%' },
};

const transition = { duration: 0.3 };

interface RevShareInsightsBadgeDetailsProps {
  onClose(): void;
  badgeName: BadgeResponseNameEnum;
}

const RevShareInsightsBadgeDetails: React.FC<RevShareInsightsBadgeDetailsProps> = ({
  onClose,
  badgeName,
}) => {
  const {
    revenue: {
      contributors,
      networkSizeByTierResponse,
      agentBadgeByName,
      generalBadgeByName,
    },
  } = useSelector((state: RootState) => state);

  const badge = agentBadgeByName[badgeName];
  const generalBadge = generalBadgeByName[badgeName];
  const unlocked = !!badge?.unlocked;
  const BadgeIcon = unlocked
    ? revShareInsightsBadgeDetailUnlockedMap[badgeName]
    : revShareInsightsBadgeDetailLockedMap[badgeName];
  const title = revShareInsightsBadgeDetailTitleMap[badgeName];
  const description = revShareInsightsBadgeDetailDescriptionMap[badgeName];

  const networkSize =
    badge?.milestoneCurrentValue ||
    networkSizeByTierResponse.data?.totalNetworkSize ||
    0;
  const networkSizeRequired =
    badge?.badge?.milestoneTargetValue ||
    generalBadge?.milestoneTargetValue ||
    getDefaultNetworkSizeRequired(badgeName);
  const earnings =
    badge?.milestoneCurrentValue || contributors?.amount?.amount || 0;
  const earningsRequired =
    badge?.badge?.milestoneTargetValue ||
    generalBadge?.milestoneTargetValue ||
    getDefaultEarningsRequired(badgeName);

  return (
    <div
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'
      aria-label='badge-details'
    >
      <motion.div
        variants={backDropVariants}
        initial='initial'
        animate='animate'
        exit='exit'
        transition={transition}
        className='absolute top-0 bottom-0 left-0 right-0 z-0 bg-black lg:block bg-opacity-40'
        role='button'
        onClick={onClose}
      />
      <motion.div
        variants={modalVariants}
        initial='initial'
        animate='animate'
        exit='exit'
        transition={transition}
        className='flex items-center justify-center w-full h-full scrollbar overflow-auto py-5 pt-10'
      >
        <div className='flex flex-col font-zen-body justify-center items-center w-full'>
          <div className='w-full max-w-xl bg-white border border-white rounded-3xl pt-8 pb-16 overflow-hidden space-y-6 z-10'>
            <div className='px-8 flex items-center justify-between'>
              <IconButton
                label='Back'
                leftIcon={<FontAwesomeIcon icon={light('arrow-left-long')} />}
                variant='outline'
                onClick={onClose}
              />
              <FontAwesomeIcon
                onClick={onClose}
                icon={duotone('circle-xmark')}
                size='xl'
                className='cursor-pointer text-white bg-zen-gray-4 border rounded-full overflow-hidden'
              />
            </div>
            <div className='px-8 text-center'>
              <div className='py-4 space-y-1 text-zen-dark-9 font-zen-body'>
                {unlocked ? (
                  <>
                    <p className='font-bold'>YOU HAVE UNLOCKED NEW BADGE!</p>
                    {!!badge?.unlockedAt && (
                      <p className='text-sm'>
                        {DateTime.fromMillis(badge?.unlockedAt).toLocaleString(
                          DateTime.DATE_FULL,
                        )}
                      </p>
                    )}
                  </>
                ) : (
                  <p className='font-bold'>LOCKED</p>
                )}
              </div>
              <div className='flex justify-center'>
                {!!BadgeIcon && <BadgeIcon />}
              </div>
              {unlocked && (
                <div className='font-zen-body py-4 text-zen-dark-9 space-y-1.5'>
                  {!!title && <p className='font-semibold'>{title}</p>}
                  {!!description && (
                    <p className='italic text-sm'>{description}</p>
                  )}
                </div>
              )}
              {showZeroReferredAgentMsg(badgeName, unlocked) && (
                <div className='mt-5 text-zen-dark-12 font-zen-body'>
                  <p className='text-xl font-medium'>0/1 Agent</p>
                  <p className='mt-1 text-sm font-semibold'>
                    Awarded for referring your first agent.
                  </p>
                </div>
              )}
              {showCurrentNetwork(badgeName, unlocked) && (
                <div className='mt-5 mx-auto w-full max-w-xs text-zen-dark-9 font-zen-body'>
                  <p className='font-semibold'>Your current network</p>
                  <div className='mt-2.5 mb-1 flex items-center- justify-between text-sm font-semibold'>
                    <p>My total network</p>
                    <p>
                      <FontAwesomeIcon icon={faUser} className='mr-1' />
                      {networkSize} / {networkSizeRequired}
                    </p>
                  </div>
                  <ZenColoredProgressBar
                    hidePercentage
                    height={8}
                    progressPercentage={
                      (networkSize / networkSizeRequired) * 100
                    }
                  />
                </div>
              )}
              {showCurrentEarnings(badgeName, unlocked) && (
                <div className='mt-5 mx-auto w-full max-w-xs text-zen-dark-9 font-zen-body'>
                  <p className='font-semibold'>Your current earnings</p>
                  <div className='mt-2.5 mb-1 flex items-center- justify-between text-sm font-semibold'>
                    <p>Total earnings</p>
                    <p>
                      ${compactNumber(earnings)} / $
                      {compactNumber(earningsRequired)}
                    </p>
                  </div>
                  <ZenColoredProgressBar
                    hidePercentage
                    height={8}
                    progressPercentage={(earnings / earningsRequired) * 100}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default RevShareInsightsBadgeDetails;
