import {
  faAt,
  faFaceLaugh,
  faGif,
  faLink,
  faPlusCircle,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import quillEmoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';
import { Linkify } from 'quill-linkify';
import 'quill-mention';
import ReactDOMServer from 'react-dom/server';
import { Quill } from 'react-quill';
import './zenQuill.snow.css';

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji } = quillEmoji;

ToolbarEmoji.DEFAULTS.buttonIcon = ReactDOMServer.renderToStaticMarkup(
  <div className='h-[27px] flex flex-row justify-center items-center'>
    <div className='h-5'>
      <FontAwesomeIcon icon={faFaceLaugh} />
    </div>
  </div>,
);

const icons = Quill.import('ui/icons');
Quill.register(
  {
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/linkify': Linkify,
  },
  true,
);

icons.mention = ReactDOMServer.renderToStaticMarkup(
  <div className='h-[27px] flex flex-row justify-center items-center'>
    <div className='h-5'>
      <FontAwesomeIcon icon={faAt} />
    </div>
  </div>,
);
icons.link = ReactDOMServer.renderToStaticMarkup(
  <div className='h-[27px] flex flex-row justify-center items-center'>
    <div className='h-5'>
      <FontAwesomeIcon icon={faLink} fontSize={210} />
    </div>
  </div>,
);
icons.gifs = ReactDOMServer.renderToStaticMarkup(
  <div className='h-[27px] flex flex-row justify-center items-center'>
    <div className='h-5'>
      <FontAwesomeIcon icon={faGif} fontSize={2330} />
    </div>
  </div>,
);
icons.moreOptions = ReactDOMServer.renderToStaticMarkup(
  <div
    className='h-[27px] flex flex-row justify-center items-center'
    data-testid='plus-icon'
  >
    <div className='h-5'>
      <FontAwesomeIcon icon={faPlusCircle} fontSize={2330} />
    </div>
  </div>,
);
