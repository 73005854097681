import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IllustrationOne from '../../assets/img/learn_title_illustration_one.svg';
import TitlePhone from '../../assets/img/title_phone_render.png';
import {
  RealTitleFeatures,
  WhyRealTitle,
} from '../../constants/JointVentureConstants';

interface LearnMoreWhyAndFeaturesProps {}

const LearnMoreWhyAndFeatures: React.FC<LearnMoreWhyAndFeaturesProps> = () => {
  return (
    <div>
      <div className='px-12 py-10'>
        <div className=''>
          <p className='text-dark text-xl font-zen-title font-medium leading-7'>
            Why Use Real Title?
          </p>
        </div>
        <div className='mt-6 grid grid-cols-2 gap-3'>
          {WhyRealTitle.map(({ title, content }) => (
            <div key={title} className='p-8 bg-zen-light-blue rounded-lg'>
              <div className='pb-4'>
                <img
                  src={IllustrationOne}
                  className='w-16 h-16'
                  alt='Profit Sharing'
                />
              </div>
              <div className='pb-2'>
                <p className='text-dark text-lg font-zen-body font-semibold leading-6'>
                  {title}
                </p>
              </div>
              <div className='pb-6'>
                <p className='text-dark text-base font-zen-body leading-6'>
                  {content}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='grid grid-cols-2 gap-6 items-center py-10 px-12 border-y border-zen-dark-4'>
        <div className='flex flex-row justify-end'>
          <div className=''>
            <div className='mb-4'>
              <p className='text-dark text-xl font-zen-title font-medium leading-7'>
                Transaction Details at Your Fingertips
              </p>
            </div>
            {RealTitleFeatures.map((feat) => (
              <div key={feat} className='mt-2 flex items-center'>
                <FontAwesomeIcon
                  icon={faCheck}
                  size='1x'
                  className='text-green-600'
                />
                <p className='ml-3.5 font-zen-body leading-6 text-dark text-base'>
                  {feat}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className='w-5/12'>
          <img src={TitlePhone} className='' alt='Learn More Phone Render' />
        </div>
      </div>
    </div>
  );
};

export default LearnMoreWhyAndFeatures;
