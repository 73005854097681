import { useEffect, useState } from 'react';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLoader from '../components/DefaultLoader';
import InstantPaymentEligibleTransactionResourceTable from '../components/instantPayments/InstantPaymentEligibleTransactionResourceTable';
import InstantPaymentResourceTable from '../components/instantPayments/InstantPaymentResourceTable';
import ResourceContainer from '../components/ResourceContainer';
import Tabs from '../components/Tabs';
import { InstantPaymentResponseStatusEnum } from '../openapi/arrakis';
import { fetchInstantPaymentsSummary } from '../slices/InstantPaymentSlice';
import { AppDispatch, RootState } from '../types';

interface InstantPaymentResourceContainerProps {
  agentId: string;
}

const InstantPaymentResourceContainer: React.FC<InstantPaymentResourceContainerProps> = ({
  agentId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<string>('Current');

  const {
    instantPayment: {
      instantPaymentsSummaryResponse: {
        data: instantPaymentsSummary,
        loading,
        error,
      },
    },
  } = useSelector((state: RootState) => state);

  const activeInstantPayments =
    instantPaymentsSummary?.instantPayments?.filter(
      (instantPayment) =>
        instantPayment.status !== InstantPaymentResponseStatusEnum.Repaid,
    ) || [];

  const archivedInstantPayments =
    instantPaymentsSummary?.instantPayments?.filter(
      (instantPayment) =>
        instantPayment.status === InstantPaymentResponseStatusEnum.Repaid,
    ) || [];

  useEffect(() => {
    dispatch(fetchInstantPaymentsSummary(agentId));
  }, [dispatch, agentId]);

  return (
    <ResourceContainer
      loading={loading}
      isEmpty={!instantPaymentsSummary}
      errorCode={error}
      emptyIcon={<FontAwesomeIcon icon={faBolt} fontSize={24} />}
      LoaderComponent={<DefaultLoader />}
      resourceName='instant payments summary'
    >
      <div className='px-0 md:px-4 lg:pt-5'>
        <Tabs
          tabs={[
            {
              name: 'Current',
              TabComponent: (
                <div className='my-5'>
                  <div>
                    <h1 className='text-xl'>Transactions</h1>
                    <div className='mt-5'>
                      <InstantPaymentEligibleTransactionResourceTable
                        key={instantPaymentsSummary?.transactionSummaries
                          ?.map((resp) => resp.transaction?.id)
                          .join(',')}
                        transactionSummaries={
                          instantPaymentsSummary?.transactionSummaries!
                        }
                      />
                    </div>
                  </div>
                  <div className='mt-10'>
                    <h1 className='text-xl mb-5'>Instant Payments</h1>
                    <div className='mt-5'>
                      <InstantPaymentResourceTable
                        key={activeInstantPayments
                          .map((resp) => resp.id)
                          .join(',')}
                        instantPayments={activeInstantPayments}
                      />
                    </div>
                  </div>
                </div>
              ),
            },
            {
              name: 'Archived',
              TabComponent: (
                <div className='mt-5'>
                  <div className='mb-10'>
                    <h1 className='text-xl mb-5'>Instant Payments</h1>
                    <InstantPaymentResourceTable
                      key={archivedInstantPayments
                        .map((resp) => resp.id)
                        .join(',')}
                      instantPayments={archivedInstantPayments}
                    />
                  </div>
                </div>
              ),
            },
          ]}
          selected={currentTab}
          onChange={setCurrentTab}
          variant='default'
        />
      </div>
    </ResourceContainer>
  );
};

export default InstantPaymentResourceContainer;
