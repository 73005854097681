import { LegacyRef, useRef } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { ReactComponent as NewFile } from '../../../assets/img/file-new.svg';
import { MAX_DOC_SIZE } from '../../../constants/FilesConstants';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { EnumMap } from '../../../types';
import {
  bytesToSize,
  isDocSizeInvalid,
  validDocs,
} from '../../../utils/FileUtils';
import DefaultLoader from '../../DefaultLoader';
import { cn } from '../../../utils/classUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';

type DocumentUploadInputVariant = 'default' | 'primary';
type InputHeightVariant = 'small' | 'medium' | 'large';

interface ZenControlledDraggableHorizontalInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  customPlaceHolder?: React.ReactElement;
  accept?: string;
  isUploading?: boolean;
  variant?: DocumentUploadInputVariant;
  maxUploadSize?: number;
  multiple?: boolean;
  iconHeight?: number;
  iconWidth?: number;
  inputHeight?: InputHeightVariant;
}

const ZenControlledDraggableHorizontalInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  customPlaceHolder,
  accept,
  rules,
  isUploading = false,
  variant = 'default',
  maxUploadSize = MAX_DOC_SIZE,
  multiple = true,
  iconHeight,
  iconWidth,
  inputHeight = 'medium',
  ...rest
}: ZenControlledDraggableHorizontalInputProps<TFieldValues, TName>) => {
  const fileInputRef = useRef<HTMLDivElement>();
  const dispatch = useDispatch();

  const variantToClassNameMap: EnumMap<DocumentUploadInputVariant, string> = {
    default: 'border-zen-dark-5 bg-white',
    primary: 'border-primary-blue bg-white text-primary-blue',
  };

  const variantToClassNameMapHover: EnumMap<
    DocumentUploadInputVariant,
    string
  > = {
    default: 'hover:bg-grey-100 text-zen-dark-7 rounded-md',
    primary: 'hover:bg-opacity-20 hover:bg-primary-blue',
  };

  const inputHeightMap: EnumMap<InputHeightVariant, string> = {
    small: 'py-2.5',
    medium: 'py-3.5',
    large: 'py-4.5',
  };

  const getDefaultPlaceholder = () => {
    return (
      <div className='px-4 flex flex-row justify-center items-center gap-y-2 font-zen-body text-sm font-semibold text-zen-dark-7 cursor-pointer'>
        <NewFile
          height={iconHeight ?? 45}
          width={iconWidth ?? 45}
          className='mr-2'
        />
        <p>
          <span className='text-primary-blue mr-1'>Browse Files</span>
          or drag & drop new file
        </p>
      </div>
    );
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      {...rest}
      render={({
        field: { name, onChange },
        fieldState: { error, invalid },
      }) => {
        return (
          <div className='w-full space-y-1'>
            {label && (
              <label
                className={cn(
                  'inline-block font-zen-body font-semibold',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                )}
                htmlFor={name}
              >
                {label}
              </label>
            )}
            <div
              className={cn(
                'border border-dashed rounded-lg cursor-default mb-1 w-full h-fit',
                variantToClassNameMap[variant],
                invalid && '!border-zen-danger',
              )}
            >
              <div
                className={cn(
                  variantToClassNameMapHover[variant],
                  'group min-h-[inherit]',
                )}
                onClick={() => fileInputRef.current?.click()}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => e.preventDefault()}
                onDragLeave={(e) => e.preventDefault()}
                onDrop={async (e) => {
                  e.preventDefault();
                  const files = Array.from(e.dataTransfer.files);

                  if (validDocs(files, maxUploadSize)?.length) {
                    if (multiple) {
                      onChange(validDocs(files, maxUploadSize));
                    } else {
                      onChange(validDocs(files?.slice(0, 1), maxUploadSize));
                    }
                  }

                  if (isDocSizeInvalid(files, maxUploadSize)) {
                    dispatch(
                      showErrorToast(
                        `File size exceeds maximum limit of ${bytesToSize(
                          maxUploadSize,
                        )}.`,
                      ),
                    );
                  }
                }}
              >
                <div
                  className={cn(
                    inputHeightMap[inputHeight],
                    'flex flex-col h-full min-h-[inherit]',
                  )}
                >
                  <input
                    id={name}
                    ref={
                      (fileInputRef as unknown) as LegacyRef<HTMLInputElement>
                    }
                    onChange={(e) => {
                      const files = Array.from(e.target.files!);
                      if (validDocs(files, maxUploadSize)?.length) {
                        onChange(validDocs(files, maxUploadSize));
                      }

                      if (isDocSizeInvalid(files, maxUploadSize)) {
                        dispatch(
                          showErrorToast(
                            `File size exceeds maximum limit of ${bytesToSize(
                              maxUploadSize,
                            )}.`,
                          ),
                        );
                      }
                      e.target.value = '';
                    }}
                    accept={accept}
                    type='file'
                    name={name}
                    className='hidden w-full h-full'
                    multiple
                  />
                  {isUploading ? (
                    <div className='flex flex-col justify-center items-center min-h-[inherit]'>
                      <DefaultLoader />
                    </div>
                  ) : (
                    <>
                      {customPlaceHolder
                        ? customPlaceHolder
                        : getDefaultPlaceholder()}
                    </>
                  )}
                </div>
              </div>
            </div>
            {error && <ZenFormErrorMessage message={error.message} />}
          </div>
        );
      }}
    />
  );
};

export default ZenControlledDraggableHorizontalInput;
