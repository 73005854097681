import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppDispatch, RootState } from '../../types';
import Logger from '../../utils/Logger';
import DefaultLoader from '../DefaultLoader';
import { DesignCenterControllerApi } from '../../openapi/yenta';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { showAndReportErrors } from '../../utils/ErrorUtils';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import ErrorService from '../../services/ErrorService';
import { REAL_DESIGN_CENTER } from '../../constants/MarketingCenterLinksConstants';

const DesignCenterRedirect: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    const redirectToDesignCenter = async () => {
      Logger.debug('Design Center SSO: Calling endpoint.');

      try {
        const { data } = await new DesignCenterControllerApi(
          getYentaConfiguration(),
        ).getJwtForAgent(userDetail?.id!);
        if (data?.jwt) {
          const url = `${REAL_DESIGN_CENTER}?jwt=${data?.jwt}`;
          Logger.debug('Design Center SSO: Redirecting to url:', url);

          window.location.assign(url);
        }
      } catch (e) {
        if (showAndReportErrors(e?.response)) {
          dispatch(showApiErrorModal(e));
          ErrorService.notifyIgnoreAuthErrors(
            'Unable to fetch jwt for agent',
            e,
            {
              agent: { id: userDetail?.id! },
            },
          );
        }
      }
    };

    if (userDetail) {
      redirectToDesignCenter();
    }
  }, [dispatch, userDetail]);

  if (!userDetail) {
    let url = '/login';
    const query = {
      redirectTo: '/designcenter/sso',
    };
    const params = new URLSearchParams(query);
    url += '?' + params.toString();

    Logger.debug('Design Center SSO: Not logged in. Redirecting to url:', url);

    return <Redirect to={url} />;
  }

  return <DefaultLoader />;
};

export default DesignCenterRedirect;
