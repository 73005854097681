import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { saveFutureMaintenance } from '../slices/MaintenanceSlice';
import { RootState } from '../types';

interface FutureMaintenanceAlertProps {}

const FutureMaintenanceAlert: React.FC<FutureMaintenanceAlertProps> = () => {
  const dispatch = useDispatch();
  const { futureMaintenanceDetail } = useSelector(
    (state: RootState) => state.maintenanceDetail,
  );

  if (!futureMaintenanceDetail.futureMaintenance) {
    return null;
  }

  return (
    <div className='bg-zen-warning-light py-4 px-8'>
      <div className='flex justify-between'>
        <p className='mb-1 text-zen-dark-9 font-zen-body font-semibold text-base'>
          {futureMaintenanceDetail.title}
        </p>
        <div
          className='cursor-pointer'
          onClick={() =>
            dispatch(
              saveFutureMaintenance({
                ...futureMaintenanceDetail,
                futureMaintenance: false,
              }),
            )
          }
        >
          <FontAwesomeIcon icon={faXmark} aria-label='closeMaintenance' />
        </div>
      </div>
      <p className='text-zen-dark-9 font-zen-body text-base'>
        {futureMaintenanceDetail.subTitle}
      </p>
    </div>
  );
};

export default FutureMaintenanceAlert;
