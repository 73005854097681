import classNames from 'classnames';
import React, { useState } from 'react';
import LeoChatIndex from './Chat/LeoChatIndex';
import LeoChatHistoryIndex from './ChatHistory/LeoChatHistoryIndex';

interface LeoModalProps {}

const LeoModal: React.FC<LeoModalProps> = () => {
  const [showHistory, setShowHistory] = useState<boolean>(false);

  return (
    <div
      className={classNames(
        'h-full w-full overflow-hidden flex-grow md:p-4 sm:p-3 mt-5 sm:mt-0 flex flex-row',
      )}
    >
      <div
        className={classNames(
          'flex sm:mr-3',
          showHistory ? 'sm:w-1/3 w-full' : 'sm:w-[50px] h-full',
        )}
      >
        <LeoChatHistoryIndex
          showHistory={showHistory}
          toggleHistory={(val) => setShowHistory(val)}
        />
      </div>
      <div
        className={classNames(
          'h-full',
          showHistory ? 'sm:block hidden sm:w-2/3' : 'sm:w-11/12 w-full',
        )}
      >
        <LeoChatIndex
          toggleHistory={setShowHistory}
          isShowingHistory={showHistory}
        />
      </div>
    </div>
  );
};

export default LeoModal;
