import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useHistory } from 'react-router-dom';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface ZenBankAccountsLinkProps {}

const ZenBankAccountsLink: React.FC<ZenBankAccountsLinkProps> = () => {
  const history = useHistory();

  return (
    <div>
      <ZenResourceLinkCard
        title='Bank Accounts'
        onClick={() => history.push('/bank-accounts')}
        icon={regular('building-columns')}
        iconSize='2x'
      />
    </div>
  );
};

export default ZenBankAccountsLink;
