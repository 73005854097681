import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export interface SocialMediaLink {
  icon: IconDefinition;
  url: string;
}

interface SocialMediaLinksProps {
  label: string;
  socialLinks: SocialMediaLink[];
}

const SocialMediaLinks: React.FC<SocialMediaLinksProps> = ({
  label,
  socialLinks,
}) => (
  <div className='flex items-center'>
    <p className='font-zen-body text-sm text-zen-dark-13 leading-4 mr-4'>
      {label}
    </p>
    {socialLinks.map((link) => (
      <FontAwesomeIcon
        key={link.url}
        title={link.url}
        icon={link.icon}
        className='mr-4 text-xl cursor-pointer'
        onClick={() => window.open(link.url, '_blank')}
      />
    ))}
  </div>
);

export default SocialMediaLinks;
