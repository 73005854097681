import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { cn } from '../../../utils/classUtils';

interface BrokerQueueTransactionOptionsProps {
  onMark(read: boolean): void;
  allOrNoneSelected: boolean;
  disabled: boolean;
}

const BrokerQueueTransactionOptions: React.FC<BrokerQueueTransactionOptionsProps> = ({
  onMark,
  allOrNoneSelected,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOptionSelect = (read: boolean) => {
    onMark(read);
    setIsOpen(false);
  };

  return (
    <Popover
      isOpen={isOpen}
      align='end'
      positions={['bottom']}
      onClickOutside={() => setIsOpen(false)}
      containerClassName='z-50'
      content={() => (
        <div className='bg-white py-1 border border-gray-200 rounded shadow-sm'>
          <div className='py-2 px-4 hover:bg-gray-100'>
            <button
              onClick={() => onOptionSelect(true)}
              className='appearance-none w-full flex items-start'
            >
              <p className='flex-grow text-left font-zen-body text-dark'>
                {allOrNoneSelected ? 'Mark all as Read' : 'Mark as Read'}
              </p>
            </button>
          </div>
          <div className='py-2 px-4 hover:bg-gray-100'>
            <button
              onClick={() => onOptionSelect(false)}
              className='appearance-none w-full flex items-start'
            >
              <p className='flex-grow text-left font-zen-body text-dark'>
                {allOrNoneSelected ? 'Mark all as Unread' : 'Mark as Unread'}
              </p>
            </button>
          </div>
        </div>
      )}
    >
      <div className='flex items-center justify-center cursor-pointer'>
        <button
          className={cn(
            'appearance-none p-1 rounded-full hover:bg-primary hover:bg-opacity-10',
            {
              'bg-primary bg-opacity-10': isOpen,
            },
          )}
          onClick={() => setIsOpen(!isOpen)}
          aria-label='message-option'
          disabled={disabled}
        >
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            className={cn('text-lg', disabled ? 'text-gray-200' : 'text-dark')}
          />
        </button>
      </div>
    </Popover>
  );
};

export default BrokerQueueTransactionOptions;
