import React, { useCallback, useEffect } from 'react';
import { Column } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { numberToMoney } from '../../utils/CurrencyUtils';
import {
  AgentDebtPreviewResponse,
  DebtRepaymentItemResponse,
} from '../../openapi/arrakis';
import { AppDispatch, RootState } from '../../types';
import TableLoader from '../TableLoader';
import ZenFixedDataTable from '../Zen/Table/ZenFixedDataTable';
import ResourceContainer from '../ResourceContainer';
import { getOutgoingRevenueSharePayments } from '../../slices/RevenueSlice';

interface RevshareDebtRepaymentProps {
  agentId: string;
  outgoingPaymentId: string;
}

interface RevshareDebtRepaymentResponse
  extends AgentDebtPreviewResponse,
    DebtRepaymentItemResponse {}

const RevshareDebtRepayment: React.FC<RevshareDebtRepaymentProps> = ({
  agentId,
  outgoingPaymentId,
}) => {
  const {
    revenue: { contributors, contributorsLoading, contributorsError },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch<AppDispatch>();
  const revSharePayments =
    contributors?.debtRepayments?.debtRepaymentResponses || [];

  const columns: Array<Column<RevshareDebtRepaymentResponse>> = [
    {
      Header: 'Repayment Amount',
      accessor: 'repaymentAmount',
      Cell: ({ value }) =>
        value ? (
          <p className='font-zen-body font-normal text-sm text-zen-dark-9'>
            {numberToMoney(value, { includeCurrencySymbol: true })}
          </p>
        ) : (
          <FontAwesomeIcon
            icon={regular('dash')}
            title='dash'
            className='text-zen-dark-9'
          />
        ),
      disableFilters: true,
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ value, row: { original } }) => (
        <div className='font-zen-body font-normal text-sm'>
          <p className='text-zen-dark-9'>{value ? value : 'N/A'}</p>
          {original?.id && (
            <div className='flex flex-row items-center space-x-1'>
              <span className='font-zen-body font-normal text-sm text-zen-gray-4 min-w-max'>
                Debt ID:
              </span>
              <a
                href={`/people/${agentId}/all-debts`}
                target='_blank'
                rel='noreferrer'
                className='min-w-max'
              >
                <span className='font-zen-body font-normal text-sm text-primary-blue'>
                  {original?.id}
                </span>
              </a>
            </div>
          )}
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  const fetchRevSharePayment = useCallback(async () => {
    if (isNil(contributors)) {
      await dispatch(
        getOutgoingRevenueSharePayments(agentId, outgoingPaymentId, 0, 10),
      );
    }
  }, [agentId, contributors, dispatch, outgoingPaymentId]);

  useEffect(() => {
    fetchRevSharePayment();
  }, [fetchRevSharePayment]);

  return (
    <div className='py-2'>
      <ResourceContainer
        isEmpty={false}
        loading={contributorsLoading}
        resourceName='revshare debt repayment'
        LoaderComponent={<TableLoader />}
        errorCode={contributorsError}
      >
        <ZenFixedDataTable
          key={revSharePayments?.length}
          columns={columns}
          resourceName='revshare debt repayment'
          data={revSharePayments?.map((debt) => ({
            ...debt?.debtPreviewResponse,
            ...debt?.repaymentItemResponse,
            id: debt?.debtPreviewResponse?.id,
          }))}
          hideFilters
          hidePagination
          hidePageSize
        />
      </ResourceContainer>
    </div>
  );
};

export default RevshareDebtRepayment;
