import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import WaveSurfer from 'wavesurfer.js';
import { RootState } from '../../../types';
import { WaveformCreateConfig } from '../../../utils/AnnouncementUtils';

export interface AnnouncementAudioProps {}

const AnnouncementAudio: React.FC<AnnouncementAudioProps> = () => {
  const { topAnnouncement } = useSelector(
    (state: RootState) => state.announcement,
  );
  const audioURL = topAnnouncement?.signedMsgAudioUrl;
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [waveForm, setWaveForm] = useState<WaveSurfer>();

  const handleWaveForm = () => {
    if (isPlaying) {
      waveForm?.pause();
    } else {
      waveForm?.play();
    }
    setIsPlaying(!isPlaying);
  };

  const formatTime = (time: number) => {
    if (time) {
      return [
        Math.floor((time % 3600) / 60),
        ('00' + Math.floor(time % 60)).slice(-2),
      ].join(':');
    }

    return '00:00';
  };

  const resetWaveForm = useCallback(async () => {
    if (!waveForm) {
      return;
    }
    waveForm.pause();
    waveForm.seekTo(0);
    setIsPlaying(false);
  }, [waveForm]);

  useEffect(() => {
    if (audioURL && !waveForm) {
      setWaveForm(WaveSurfer.create(WaveformCreateConfig));
    }
    if (audioURL && waveForm) {
      waveForm.load(audioURL);
      waveForm.on('finish', () => {
        waveForm.pause();
        waveForm.seekTo(0);
        setIsPlaying(false);
      });
    }
    return () => {
      if (waveForm) {
        waveForm.destroy();
      }
    };
  }, [audioURL, resetWaveForm, waveForm]);

  if (!audioURL) {
    return null;
  }

  return (
    <div>
      <p className='font-zen-body font-bold text-sm text-zen-dark-9 leading-6 mb-2.5'>
        Press play to hear this voice memo:
      </p>
      <div className='flex flex-row items-center justify-between h-16 mb-6 px-3 rounded-lg bg-regent-200'>
        <FontAwesomeIcon
          icon={isPlaying ? solid('circle-pause') : solid('circle-play')}
          size='2x'
          className='text-primary-blue mr-2 cursor-pointer'
          onClick={handleWaveForm}
        />
        <div
          className='w-full h-14'
          id='waveform'
          aria-label='announcement-memo'
        />
        <p className='font-zen-body text-base font-medium text-primary-blue'>
          {formatTime(waveForm?.getDuration()!)}
        </p>
      </div>
    </div>
  );
};

export default AnnouncementAudio;
