import { DragOverlay } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { ColumnInstance, Row } from 'react-table';
import { ResourceTableVariant } from '../../../containers/ResourceTable';
import { VerticalAlignmentVariant } from '../../../types';

interface ZenResourceTableRowProps<D extends object> {
  row: Row<D>;
  variant: ResourceTableVariant;
  renderToggleRowComponent?(d: Row<D>): React.ReactElement;
  getTableRowClassNames?(d: Row<D>): string;
  visibleColumns: ColumnInstance<D>[];
  cellVerticalAlignment: VerticalAlignmentVariant;
  allowSortingRows?: boolean;
  index: number;
  pageLength: number;
  hideRowBorder?: boolean;
  hideGroupMemberBorder?: boolean;
  compact?: boolean;
}

const ZenResourceTableRow = <D extends object>({
  row,
  variant,
  renderToggleRowComponent,
  getTableRowClassNames = (_row: Row<D>) => '',
  visibleColumns,
  cellVerticalAlignment,
  allowSortingRows,
  index,
  pageLength,
  hideRowBorder = false,
  hideGroupMemberBorder = false,
  compact = false,
}: ZenResourceTableRowProps<D>) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    transform,
    transition,
  } = useSortable({
    id: row.id,
    disabled: !allowSortingRows,
  });

  const style = {
    transform: CSS?.Transform?.toString(transform),
    transition: transition,
  };

  const { key, ...rest } = row.getRowProps();
  const ribbonCell = row.cells.find((cell) => cell?.column?.id === 'ribbon');

  return (
    <React.Fragment key={key}>
      <tr
        ref={setNodeRef}
        {...attributes}
        {...rest}
        style={style}
        className={classNames(
          {
            'border-b border-zen-dark-4':
              !hideGroupMemberBorder &&
              !hideRowBorder &&
              variant === ResourceTableVariant.ROW &&
              index !== pageLength - 1,
            'hover:bg-grey-100': variant === ResourceTableVariant.ROW,
            'grid grid-cols-12 grid-flow-row':
              variant === ResourceTableVariant.CARD,
          },
          getTableRowClassNames(row),
        )}
      >
        {isDragging ? (
          <td className='bg-grey-100 h-16' colSpan={row?.cells?.length + 1}>
            &nbsp;
          </td>
        ) : (
          <Fragment>
            {!!ribbonCell && variant !== ResourceTableVariant.CARD && (
              <td
                {...ribbonCell.getCellProps()}
                className={classNames(
                  'relative font-zen-body font-semibold text-sm text-zen-dark-9',
                  compact ? 'px-2 py-3' : 'px-5 py-3',
                  cellVerticalAlignment,
                )}
              >
                {ribbonCell.render('Cell')}
              </td>
            )}
            {allowSortingRows && (
              <td>
                <div {...listeners}>
                  <FontAwesomeIcon
                    icon={faGripDotsVertical}
                    size='lg'
                    className='text-zen-dark-6 cursor-move'
                  />
                </div>
              </td>
            )}
            {row.cells
              ?.filter((cell) => cell?.column?.id !== 'ribbon')
              ?.map((cell) => {
                return (
                  <td
                    //@ts-ignore
                    key={key}
                    {...cell.getCellProps()}
                    className={classNames(
                      'relative font-zen-body font-semibold text-sm text-zen-dark-9',
                      compact ? 'px-2 py-3' : 'px-5 py-3',
                      cellVerticalAlignment,
                      {
                        [`border col-span-${cell.column.cardColSize || 12}`]:
                          variant === ResourceTableVariant.CARD,
                      },
                    )}
                  >
                    {variant === ResourceTableVariant.CARD && (
                      <p className='text-xs text-zen-dark-5 font-semibold uppercase'>
                        {cell.column.render('Header')}
                      </p>
                    )}
                    {cell.render('Cell')}
                  </td>
                );
              })}
          </Fragment>
        )}
      </tr>
      {row.isExpanded && renderToggleRowComponent ? (
        <tr>
          <td colSpan={visibleColumns?.length}>
            {renderToggleRowComponent(row)}
          </td>
        </tr>
      ) : null}
      {isDragging && (
        <tr>
          <td colSpan={row?.cells?.length + 1}>
            <DragOverlay>
              <table className='w-full'>
                <tbody>
                  <tr
                    className='shadow-pop-over rotate-1 w-full bg-white'
                    {...row?.getRowProps()}
                  >
                    {!!ribbonCell && variant !== ResourceTableVariant.CARD && (
                      <td
                        {...ribbonCell.getCellProps()}
                        className={classNames(
                          'relative font-zen-body font-semibold text-sm text-zen-dark-9',
                          compact ? 'px-2 py-3' : 'px-5 py-3',
                          cellVerticalAlignment,
                        )}
                      >
                        {ribbonCell.render('Cell')}
                      </td>
                    )}
                    <td>
                      <div {...listeners}>
                        <FontAwesomeIcon
                          icon={faGripDotsVertical}
                          size='lg'
                          className='text-zen-dark-6 cursor-move'
                        />
                      </div>
                    </td>
                    {row.cells
                      ?.filter((cell) => cell?.column?.id !== 'ribbon')
                      ?.map((cell) => (
                        // eslint-disable-next-line react/jsx-key
                        <td
                          {...cell.getCellProps()}
                          className={classNames(
                            'font-zen-body font-semibold text-sm text-zen-dark-9',
                            compact ? 'px-2 py-3' : 'px-5 py-3',
                            cellVerticalAlignment,
                            {
                              [`border col-span-${
                                cell.column.cardColSize || 12
                              }`]: variant === ResourceTableVariant.CARD,
                            },
                          )}
                        >
                          {variant === ResourceTableVariant.CARD && (
                            <p className='text-xs text-zen-dark-5 font-semibold uppercase'>
                              {cell.column.render('Header')}
                            </p>
                          )}
                          {cell.render('Cell')}
                        </td>
                      ))}
                  </tr>
                </tbody>
              </table>
            </DragOverlay>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default ZenResourceTableRow;
