import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { InstantPaymentItemResponse } from '../../../openapi/arrakis';
import { displayAmount } from '../../../utils/CurrencyUtils';

interface ZenInstantPaymentsTransactionRowProps {
  repayment: InstantPaymentItemResponse;
}

const ZenInstantPaymentsTransactionRow: React.FC<ZenInstantPaymentsTransactionRowProps> = ({
  repayment,
}) => {
  return (
    <Link
      to={`/transactions/${repayment.repaymentTransaction?.id}`}
      className='flex flex-row items-center py-2'
    >
      <div className='flex-grow'>
        <div className='text-zen-dark-9 font-zen-body font-medium'>
          {displayAmount(repayment.amount, { abs: true })}
        </div>
        <div className='flex flex-col md:flex-row flex-nowrap sp md:space-x-1 text-coolGray-500 font-zen-body text-sm'>
          <p>
            {DateTime.fromMillis(repayment.createdAt!).toFormat('LL/dd/yyyy')}
          </p>
          <p className='hidden md:block'>•</p>
          <p className='overflow-ellipsis'>
            {repayment.repaymentTransaction?.addressOneLine || 'N/A'}
          </p>
        </div>
      </div>
      <FontAwesomeIcon
        icon={faChevronRight}
        size='lg'
        className='text-zen-dark-9'
      />
    </Link>
  );
};

export default ZenInstantPaymentsTransactionRow;
