import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useGemini } from '../../../hooks/useGemini';
import useZenPagination, { DOTS } from '../../../hooks/useZenPagination';
import { cn } from '../../../utils/classUtils';

interface ZenTablePaginationProps {
  currentPage: number;
  lastPage: number;
  totalCount: number;
  pageSize: number;
  goToPage(page: number): void;
}

const ZenTablePagination: React.FC<ZenTablePaginationProps> = ({
  currentPage,
  lastPage,
  goToPage,
  totalCount,
  pageSize,
}) => {
  const isGeminiFlagEnabled = useGemini();
  const paginationRange = useZenPagination({
    currentPage,
    totalCount,
    pageSize,
  });

  const onNext = () => {
    goToPage(currentPage + 1);
  };

  const onPrevious = () => {
    goToPage(currentPage - 1);
  };

  if (paginationRange.length < 2) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex items-stretch space-x-0.5',
        isGeminiFlagEnabled ? 'font-inter' : 'font-zen-body',
      )}
    >
      <button
        className='text-zen-dark disabled:text-zen-dark-4 hover:bg-grey-100 px-2 py-0.5 rounded'
        disabled={currentPage === 0}
        onClick={onPrevious}
        aria-label='go-previous'
      >
        <FontAwesomeIcon icon={faChevronLeft} size='xs' />
      </button>
      <div className='flex items-center text-base text-zen-dark-8 space-x-1'>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return <div key={`${pageNumber + index}`}>...</div>;
          }

          return (
            <button
              key={pageNumber}
              className={classNames(
                'px-2 py-0.5 rounded hover:bg-zen-light-gray-1',
                {
                  'bg-zen-light-gray-2': +pageNumber - 1 === currentPage,
                },
              )}
              onClick={() => goToPage(+pageNumber - 1)}
            >
              {pageNumber}
            </button>
          );
        })}
      </div>
      <button
        className='text-zen-dark disabled:text-zen-dark-4 hover:bg-grey-100 px-2 py-0.5 rounded'
        disabled={currentPage === lastPage - 1}
        onClick={onNext}
        aria-label='go-next'
      >
        <FontAwesomeIcon icon={faChevronRight} size='xs' />
      </button>
    </div>
  );
};

export default ZenTablePagination;
