import React from 'react';
import ZenPopover from '../../../ZenPopover';

interface MaximumLeadersPopoverProps {}

const MaximumLeadersPopover: React.FC<MaximumLeadersPopoverProps> = () => {
  return (
    <ZenPopover
      title='Maximum Leaders'
      description={
        <div className='text-zen-dark-9 text-base font-light'>
          <p>
            A Real Administrator can set the maximum number of leaders that the
            team is allowed to have.
          </p>
        </div>
      }
    />
  );
};

export default MaximumLeadersPopover;
