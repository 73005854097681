import React from 'react';
import { KeyValuePair } from '../types';
import Button from './Button';

interface ReviewRowProps {
  number: number;
  items: KeyValuePair<string, string>[];
  onClick(): void;
}

const StepsReviewRow: React.FC<ReviewRowProps> = ({
  number,
  items,
  onClick,
}) => {
  return (
    <div className='py-2 flex items-center'>
      <div>
        <div className='w-7 h-7 rounded-full bg-gray-200 flex justify-center items-center'>
          {number}
        </div>
      </div>
      <div className='flex-grow px-3 space-y-2'>
        {items.map((item) => (
          <div key={item.key}>
            <p className='leading-5'>{item.key}</p>
            <p className='leading-5 text-gray-500 whitespace-pre'>
              {item.value}
            </p>
          </div>
        ))}
      </div>
      <Button label='Edit' type='secondary' onClick={onClick} />
    </div>
  );
};

export default StepsReviewRow;
