import ZenResourceIndexContainer, {
  TableProps,
} from './ZenResourceIndexContainer';

interface FixedDataTableProps<D extends object>
  extends Omit<TableProps<D>, 'fetchData'> {}

const ZenFixedResourceIndexContainer = <D extends object>(
  props: FixedDataTableProps<D>,
) => {
  return (
    <ZenResourceIndexContainer<D>
      {...props}
      standalone={props.standalone ?? false}
    />
  );
};

export default ZenFixedResourceIndexContainer;
