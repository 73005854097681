import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { CreateInviteRequest } from '../../openapi/avalon';
import {
  fetchJointVentures,
  inviteAgentToJoinVenture,
} from '../../slices/JointVentureSlice';
import { AppDispatch, ISelectOption, RootState } from '../../types';
import { getTierAvailableShares } from '../../utils/JVUtils';
import { NUMBER_VALIDATIONS } from '../../utils/Validations';
import ResourceContainer from '../ResourceContainer';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenButton from '../Zen/ZenButton';

interface FormData {
  jointVenture: ISelectOption;
  tier: ISelectOption;
  interests: number;
}

export interface InviteJointVentureModalFormProps {
  title?: string;
  agentId: string;
  onClose(): void;
  closeButtonComponent?: React.ReactElement;
}

const InviteJointVentureModalForm: React.FC<InviteJointVentureModalFormProps> = ({
  title,
  onClose,
  closeButtonComponent,
  agentId,
}) => {
  const { control, handleSubmit, watch } = useForm<FormData>();
  const dispatch: AppDispatch = useDispatch();

  const {
    agentDetail: {
      detailResponse: { data: agentDetail },
    },
    jointVenture: {
      jointVenturesResponse: { loading, data },
    },
  } = useSelector((state: RootState) => state);
  const [selectedJVId, tierName] = watch(['jointVenture', 'tier']);
  const selectedJV = (data || []).find((v) => v.id === selectedJVId?.value!);
  const selectedTier = (selectedJV?.tiers || []).find(
    (v) => v.id === tierName?.value!,
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchJointVentures());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: FormData) => {
    setIsLoading(true);
    const req: CreateInviteRequest = {
      agentId,
      tierId: values.tier.value,
      interests: values.interests,
    };
    const isDone = await dispatch(
      inviteAgentToJoinVenture(req, agentDetail?.id!),
    );
    setIsLoading(false);
    if (isDone) {
      onClose();
    }
  };

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-60'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full scrollbar overflow-scroll'>
        <div className='flex flex-col min-h-750 font-zen-body justify-center items-center w-full z-10'>
          <div className='w-full rounded-2xl z-10 md:w-full max-w-[745px] shadow-sm shadow-gray-300 bg-white relative'>
            <div className='flex flex-row items-center justify-between p-5 border-b border-zen-dark-4'>
              <div className='flex-grow text-center'>
                <h1 className='font-bold text-base leading-6'>{title}</h1>
              </div>
              {closeButtonComponent ? (
                closeButtonComponent
              ) : (
                <div className='h-6 w-6 flex items-center justify-center bg-zen-dark-10 rounded-full'>
                  <FontAwesomeIcon
                    icon={faXmark}
                    className='text-white text-lg leading-5 cursor-pointer'
                    onClick={onClose}
                  />
                </div>
              )}
            </div>
            <ResourceContainer
              isEmpty={!data?.length}
              loading={loading}
              resourceName='joint venture'
            >
              <div className='flex flex-col p-10'>
                <ZenControlledSelectInput<FormData, 'jointVenture'>
                  control={control}
                  name='jointVenture'
                  placeholder='Select Joint Venture'
                  label='Joint Venture'
                  options={(data || [])?.map((v) => ({
                    label: v.name!,
                    value: v.id!,
                  }))}
                  rules={{ required: 'Please select venture' }}
                  isRequired
                />
                <div className='mt-4'>
                  <ZenControlledSelectInput<FormData, 'tier'>
                    control={control}
                    name='tier'
                    placeholder='Select Tier'
                    label='Tier'
                    options={(selectedJV?.tiers || []).map((v) => ({
                      label: v.name!,
                      value: v.id!,
                    }))}
                    rules={{ required: 'Please select tier' }}
                    isRequired
                  />
                </div>
                <div className='my-4'>
                  <ZenControlledTextInput<FormData, 'interests'>
                    control={control}
                    name='interests'
                    placeholder='Eg: 4'
                    label='Share Quantity'
                    type='number'
                    rules={{
                      ...NUMBER_VALIDATIONS,
                      max: {
                        value: getTierAvailableShares(selectedTier || {}),
                        message: `Please enter share quantity less than or equal to the available shares (${getTierAvailableShares(
                          selectedTier || {},
                        )})`,
                      },
                      min: {
                        value: 1,
                        message: 'Quantity cannot be less than 1',
                      },
                      required: 'Please provide a valid number',
                    }}
                    isRequired
                  />
                </div>
                <div className='flex justify-center'>
                  <ZenButton
                    label='Send Invitation'
                    type='submit'
                    isSubmitting={isLoading}
                    isDisabled={isLoading}
                    onClick={handleSubmit(onSubmit)}
                  />
                </div>
              </div>
            </ResourceContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteJointVentureModalForm;
