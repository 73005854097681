import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faClipboardList, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { AgentResponseAgentStatusEnum } from '../../openapi/yenta';
import { EmailSupportType, RootState } from '../../types';
import ApplicationSupport from '../../assets/img/application-support.png';
import TransactionSupport from '../../assets/img/transaction-support.png';
import OtherSupport from '../../assets/img/other-support.png';
import TechSupport from '../../assets/img/tech-support.png';
import { openEmailClientForSupportEmail } from '../../utils/SupportUtils';
import { toggleSupportModal } from '../../slices/SupportSlice';
import {
  KNOWLEDGE_BASE_LINK,
  getSupportPhoneNumberForCountry,
} from '../../constants/SupportConstants';
import BannerCallOut from './BannerCallout';
import MyTransactionSupport from './MyTransactionsSupport';

export interface SupportModalProps {
  isOpen: boolean;
}

const SupportModal: React.FC<SupportModalProps> = ({ isOpen }) => {
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const isLoggedIn = !!userDetail;
  const isCandidate =
    userDetail?.agentStatus === AgentResponseAgentStatusEnum.Candidate;
  const showApplicationHelp = !isLoggedIn || isCandidate;
  const showTransactionHelp =
    userDetail?.agentStatus === AgentResponseAgentStatusEnum.Active;
  const [transactionSupportForm, showTransactionSupportForm] = useState(false);
  const supportPhoneNumber = getSupportPhoneNumberForCountry(
    userDetail?.accountCountry,
  );

  if (!isOpen) {
    return null;
  }
  return (
    <div className='fixed md:right-5 md:bottom-20 h-full md:h-auto top-0 md:top-auto z-50 md:w-96 sm:w-full left-0 md:left-auto bg-white rounded-md border shadow-lg'>
      <div className='flex flex-col'>
        <div className='flex-row flex justify-between bg-blue-500 rounded-t-md pb-12 pt-5 px-8'>
          <div>
            <p className='font-primary-regular text-white text-2xl'>
              Support Team
            </p>
            <p className='font-primary-regular text-white text-lg opacity-60'>
              I need help with...
            </p>
          </div>
          <FontAwesomeIcon
            icon={faXmark}
            className='text-white cursor-pointer'
            onClick={() => dispatch(toggleSupportModal(!isOpen))}
          />
        </div>
        {!transactionSupportForm ? (
          <div className='flex flex-col space-y-3 m-4 -mt-10'>
            <BannerCallOut
              title='FAQs'
              subtitle='Get answers to frequently asked questions.'
              actionText='View Knowledge Base'
              imageIcon={
                <FontAwesomeIcon
                  icon={faClipboardList}
                  className='opacity-70'
                  size='2xl'
                />
              }
              onPress={() => window.open(KNOWLEDGE_BASE_LINK, '_blank')}
            />
            {showApplicationHelp && (
              <BannerCallOut
                title='My Application'
                subtitle='I need help or have questions about the application process.'
                actionText='Email Support'
                onPress={() =>
                  openEmailClientForSupportEmail(
                    EmailSupportType.MY_APPLICATION,
                    userDetail!,
                  )
                }
                imageSource={ApplicationSupport}
              />
            )}
            {showTransactionHelp && (
              <BannerCallOut
                title='My Transaction'
                subtitle='I need help or have a question about a particular transaction'
                actionText='Email Support'
                onPress={() => showTransactionSupportForm(true)}
                imageSource={TransactionSupport}
              />
            )}
            <BannerCallOut
              title='Technical Support'
              subtitle='I have questions about using the web app or want to report an issue.'
              actionText='Email Support'
              onPress={() =>
                openEmailClientForSupportEmail(
                  EmailSupportType.TECHNICAL_SUPPORT,
                  userDetail!,
                )
              }
              imageSource={TechSupport}
            />
            <BannerCallOut
              title='Other'
              subtitle='I want to ask a question about something not listed here.'
              actionText='Email Support'
              onPress={() =>
                openEmailClientForSupportEmail(
                  EmailSupportType.OTHERS,
                  userDetail!,
                )
              }
              imageSource={OtherSupport}
            />
            <BannerCallOut
              title='Call our Customer Support Team'
              actionText={supportPhoneNumber}
              imageIcon={
                <FontAwesomeIcon
                  icon={faPhone}
                  className='opacity-70'
                  size='xl'
                />
              }
              onPress={() => window.open(`tel:${supportPhoneNumber}`, '_blank')}
            />
          </div>
        ) : (
          <MyTransactionSupport
            navigateBack={() => showTransactionSupportForm(false)}
          />
        )}
      </div>
    </div>
  );
};

export default SupportModal;
