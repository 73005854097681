import {
  faCircleCheck,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React from 'react';
import { useSelector } from 'react-redux';
import useAgentsInfo from '../../../../hooks/useAgentsInfo';
import { TransactionResponse } from '../../../../openapi/arrakis';
import { RootState } from '../../../../types';

interface GeminiMarkAsCompliantStatusProps {
  transaction: TransactionResponse;
}

const GeminiMarkAsCompliantStatus: React.FC<GeminiMarkAsCompliantStatusProps> = ({
  transaction,
}) => {
  const { agentById } = useSelector((state: RootState) => state.userIds);
  const agentId = transaction?.complianceApprovedBy;
  const agent = agentById[agentId!];

  useAgentsInfo([agentId!]);

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col'>
        {!!transaction.compliantAt ? (
          <div className='flex items-center text-green-400 font-inter font-medium text-[14px] leading-[18px]'>
            <div className='flex items-start justify-center gap-2'>
              <FontAwesomeIcon
                icon={faCircleCheck}
                className='text-[16px] leading-[22.69px] font-black text-center'
              />
              <span>
                File was Reviewed and has been Marked Compliant by{' '}
                {agent && `${agent?.firstName} ${agent?.lastName}`} on{' '}
                {DateTime.fromMillis(transaction.compliantAt!).toFormat(
                  'LL/dd/yyyy',
                )}
                .
              </span>
            </div>
          </div>
        ) : (
          <div className='flex items-center text-yellow-400 font-inter font-medium leading-[18px]'>
            <div className=' flex items-start justify-center gap-2'>
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className='text-[16px] leading-[22.69px] font-black text-center'
              />
              <span>
                Waiting for all broker-required checklist items to be uploaded
                and accepted.
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GeminiMarkAsCompliantStatus;
