import { Link } from 'react-router-dom';
import AppStore from '../../assets/img/AppStore.png';
import IPhones from '../../assets/img/IPhones.png';
import RealLogoImg from '../../assets/img/new-rezen-black-logo.svg';
import PlayStore from '../../assets/img/PlayStore.png';
import WebApp from '../../assets/img/web-app.png';
import ZenRoute from '../../components/Zen/ZenRoute';

interface ContinueOnMobileRouteProps {}

const IPHONE_APP_STORE_LINK =
  'https://apps.apple.com/us/app/real-brokerage/id1548909981';

const ANDROID_APP_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.joinreal.realbrokerage';

const ContinueOnMobileRoute: React.FC<ContinueOnMobileRouteProps> = () => {
  return (
    <ZenRoute title='Download App'>
      <div className='container mx-auto h-screen flex flex-col items-center justify-end'>
        <div className='lg:w-1/3 md:w-1/2 p-10'>
          <div className='w-full flex justify-center items-center'>
            <img src={RealLogoImg} className='h-7 print:h-7' alt='Real Logo' />
          </div>
          <p className='mt-5 font-primary-medium text-2xl text-center'>
            Get started with our mobile apps
          </p>
          <p className='mt-5 font-primary-regular text-base text-center'>
            Download the official Real app for Agents.
          </p>
          <div className='mt-5 w-full flex space-x-3 justify-center items-center'>
            <a href={IPHONE_APP_STORE_LINK} target='_blank' rel='noreferrer'>
              <img
                src={AppStore}
                className='h-8 print:h-5'
                alt='App Store Logo'
              />
            </a>

            <a href={ANDROID_APP_STORE_LINK} target='_blank' rel='noreferrer'>
              <img
                src={PlayStore}
                className='h-8 print:h-5'
                alt='Play Store Logo'
              />
            </a>
          </div>
          <div className='relative text-center mt-4 mb-2'>
            <div className='absolute z-0 w-36 border-t border-coolGray-200 mx-auto right-1/2 transform translate-x-1/2 top-1/2' />
            <span className='relative z-10 bg-white text-xs text-gray-300 px-1'>
              OR
            </span>
          </div>
          <p className='font-primary-regular text-base text-center mb-2'>
            Login to our Web App
          </p>
          <Link to='/login'>
            <img
              src={WebApp}
              className='h-8 print:h-5 mx-auto'
              alt='Login to Web App'
            />
          </Link>
        </div>
        <div className='flex justify-center'>
          <img src={IPhones} className='' alt='Mobile App Previews' />
        </div>
      </div>
    </ZenRoute>
  );
};

export default ContinueOnMobileRoute;
