import { faXmark, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import GeminiSwitch from '../../../components/Gemini/input/GeminiSwitch';

interface PublishOrPauseReferralProps {
  isPublished: boolean;
  setIsPublished(value: boolean): void;
}

const PublishOrPauseReferral: React.FC<PublishOrPauseReferralProps> = ({
  isPublished,
  setIsPublished,
}) => {
  return (
    <div className='flex flex-row items-center justify-between bg-regent-200 px-3 py-2 rounded-b-xl'>
      <div>
        <p className='text-primary-dark font-normal text-base'>Published</p>
        <p className='text-grey-500 font-light text-sm'>
          Pause or publish your referral
        </p>
      </div>
      <GeminiSwitch
        leftIcon={<FontAwesomeIcon icon={faXmark} size='sm' />}
        rightIcon={
          <FontAwesomeIcon
            icon={faCheck}
            size='sm'
            className='text-rezen-blue-600'
          />
        }
        toggle={isPublished}
        onClick={() => setIsPublished(!isPublished)}
        testId='publish-or-pause'
      />
    </div>
  );
};

export default PublishOrPauseReferral;
