import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  EscrowDepositResponse,
  TransactionControllerApi,
  TransactionResponse,
} from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showErrorToast,
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { fetchTransactionDetails } from '../../../slices/TransactionSlice';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';

interface DeleteMoneyTransferConfirmationModalProps {
  isOpen: boolean;
  onClose(): void;
  deposit: EscrowDepositResponse;
  transaction: TransactionResponse;
  escrowId: string;
}

const ZenDeleteTrustDepositInstallmentModal: React.FC<DeleteMoneyTransferConfirmationModalProps> = ({
  isOpen,
  onClose,
  transaction,
  deposit,
  escrowId,
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleDelete = async (depositId: string) => {
    setIsSubmitting(true);
    try {
      await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).deleteEscrowDeposit(depositId);
      dispatch(showSuccessToast('Installment deleted successfully.'));
      dispatch(fetchTransactionDetails(transaction.id!));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      dispatch(showErrorToast('Unable to delete installment.'));
      ErrorService.notify('Unable to delete the escrow deposit', e, {
        escrow: { id: escrowId, deposit: { id: depositId } },
      });
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to delete the escrow deposit.',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ZenConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      variant='danger'
      title='Confirm Delete'
      subtitle='Are you sure you would like to delete the deposit installment?'
      onConfirm={() => handleDelete(deposit.id!)}
      isSubmitting={isSubmitting}
      isDisabled={isSubmitting}
    />
  );
};

export default ZenDeleteTrustDepositInstallmentModal;
