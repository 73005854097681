import React from 'react';

interface ZenNewBadgeProps {}

const ZenNewBadge: React.FC<ZenNewBadgeProps> = () => {
  return (
    <div
      className='h-[17px] w-auto flex items-center justify-center rounded-[40px] bg-aqua'
      aria-label='new-badge'
    >
      <p className='font-zen-body font-medium text-xs text-primary-navy px-2'>
        New
      </p>
    </div>
  );
};

export default ZenNewBadge;
