import { faArrowUpArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  AddressStateEnum,
  LocationInfoRequest,
  LocationInfoRequestStateEnum,
} from '../../../openapi/arrakis';
import {
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaResponseCountryEnum,
} from '../../../openapi/yenta';

import { saveLocationInfos } from '../../../slices/TransactionBuilderSlice';
import { AppDispatch, RootState } from '../../../types';
import { isCanadianUser } from '../../../utils/AgentHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { getAddressFromAddressComponent } from '../../../utils/TransactionHelper';
import {
  GOOGLE_AUTO_COMPLETE_VALIDATIONS,
  YEAR_VALIDATIONS,
} from '../../../utils/Validations';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenControlledGoogleAutocompleteSearchInput from '../../Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledHTMLSelectInput from '../../Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledStateOrProvinceInput from '../../Zen/Input/ZenControlledStateOrProvince';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../../Zen/ZenButton';
import withCreateListingProgress from '../CreateListing/withCreateListingProgress';
import {
  Match,
  ZenCreateListingFormState,
  ZenCreateListingStepName,
} from './ZenCreateListingSteps';

const ZenListingAddressStep: StepByStepComponent<
  ZenCreateListingFormState,
  ZenCreateListingStepName
> = ({ form: { control, setValue, watch, trigger }, onNext }) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [
    isManualAddress,
    address,
    location,
    mlsNumber,
    yearBuilt,
    unitNo,
  ] = watch([
    'isManualAddress',
    'address',
    'location',
    'mlsNumber',
    'yearBuilt',
    'unitNo',
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { transactionBuilderId } = useParams<Match>();

  const handleAddressStep = async () => {
    const isValid = await trigger();
    if (isValid) {
      const locationInfoRequest: LocationInfoRequest = {
        street:
          isManualAddress || !location?.place_id
            ? address?.street!
            : getAddressFromAddressComponent(location?.address_components!)
                ?.streetAddress1,
        street2:
          isManualAddress || !location?.place_id
            ? address?.street2!
            : getAddressFromAddressComponent(location?.address_components!)
                ?.streetAddress2,
        city:
          isManualAddress || !location?.place_id
            ? address?.city!
            : getAddressFromAddressComponent(location?.address_components!)
                ?.city!,
        state:
          isManualAddress || !location?.place_id
            ? ((address?.state! as unknown) as LocationInfoRequestStateEnum)
            : ((getAddressFromAddressComponent(location?.address_components!)
                ?.stateOrProvince! as unknown) as LocationInfoRequestStateEnum),
        zip:
          isManualAddress || !location?.place_id
            ? address?.zip!
            : getAddressFromAddressComponent(location?.address_components!)
                ?.zipOrPostalCode!,
        mlsNumber: mlsNumber!,
        yearBuilt: yearBuilt,
        unit: unitNo,
      };

      setLoading(true);
      const res = await dispatch(
        saveLocationInfos(transactionBuilderId!, locationInfoRequest),
      );
      setLoading(false);
      if (res) {
        onNext();
      }
    }
  };

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <p className='text-xl font-primary-medium py-4 text-dark'>
          Hi, {userDetail?.fullName} 👋
        </p>
        <p className='mb-4 font-primary-light'>
          We’ll guide you through a few steps to create a new listing directly.
        </p>
        {isManualAddress && (
          <div className='space-y-4'>
            <div>
              <ZenControlledTextInput<
                ZenCreateListingFormState,
                'address.street'
              >
                control={control}
                label='Street'
                name='address.street'
                shouldUnregister={false}
                placeholder='E.g. 270 1st Ave.'
                rules={{
                  required: 'Required',
                }}
                isRequired
              />
            </div>
            <div className='flex flex-row space-x-4'>
              <ZenControlledTextInput<
                ZenCreateListingFormState,
                'address.street2'
              >
                control={control}
                label='Street Line 2'
                name='address.street2'
                shouldUnregister={false}
                placeholder='E.g. 270 1st Ave.'
              />
              <ZenControlledHTMLSelectInput<
                ZenCreateListingFormState,
                'address.country'
              >
                name='address.country'
                control={control}
                label='Country'
                shouldUnregister={false}
                placeholder='Country'
                options={[
                  {
                    label: 'Choose Country',
                    value: '',
                    disabled: true,
                  },
                  ...values(AdministrativeAreaResponseCountryEnum).map(
                    (state) => ({
                      value: state,
                      label: capitalizeEnum(state),
                    }),
                  ),
                ]}
                rules={{
                  required: 'Please select country',
                }}
                isRequired
              />
            </div>
            <div className='flex flex-row space-x-4'>
              <ZenControlledTextInput<ZenCreateListingFormState, 'unitNo'>
                control={control}
                label='Unit No.'
                shouldUnregister={false}
                name='unitNo'
                placeholder='Eg: 123'
              />
              <ZenControlledTextInput<ZenCreateListingFormState, 'address.city'>
                control={control}
                label='City'
                name='address.city'
                shouldUnregister={false}
                placeholder='Eg. New York'
                rules={{
                  required: 'Required',
                }}
                isRequired
              />
            </div>

            <div className='flex flex-row space-x-4'>
              <ZenControlledStateOrProvinceInput<
                ZenCreateListingFormState,
                'address.state'
              >
                name='address.state'
                control={control}
                shouldUnregister={false}
                defaultValue={('' as unknown) as AddressStateEnum}
                setValue={setValue}
                selectedCountry={
                  (watch(
                    'address.country',
                  ) as unknown) as AdministrativeAreaRequestCountryEnum
                }
                rules={{
                  required: 'Required',
                }}
                isRequired
              />

              <ZenControlledTextInput<ZenCreateListingFormState, 'address.zip'>
                control={control}
                label='ZIP/Postal Code'
                name='address.zip'
                shouldUnregister={false}
                placeholder='E.g. 10001'
                rules={{
                  required: 'Required',
                }}
                isRequired
              />
            </div>
            {!isCanadianUser(userDetail?.accountCountry!) && (
              <div className='grid grid-cols-2 gap-x-4'>
                <ZenControlledTextInput<ZenCreateListingFormState, 'yearBuilt'>
                  control={control}
                  shouldUnregister={false}
                  label='Year Built'
                  name='yearBuilt'
                  placeholder='Eg: 2020'
                  rules={{
                    required: 'Year built is required',
                    ...YEAR_VALIDATIONS,
                  }}
                  isRequired
                  maxLength={4}
                />
              </div>
            )}
          </div>
        )}

        {!isManualAddress && (
          <div className='space-y-4'>
            <ZenControlledGoogleAutocompleteSearchInput<
              ZenCreateListingFormState,
              'location'
            >
              control={control}
              shouldUnregister={false}
              name='location'
              label='Search Address'
              subLabel='(Source by Google)'
              placeholder='E.g. 1st St. New York, NY 10010'
              rules={{
                ...GOOGLE_AUTO_COMPLETE_VALIDATIONS,
              }}
              isRequired
            />
            <div className='flex flex-row space-x-4'>
              <ZenControlledTextInput<ZenCreateListingFormState, 'unitNo'>
                control={control}
                shouldUnregister={false}
                label='Unit No.'
                name='unitNo'
                placeholder='Eg: 123'
              />
              {!isCanadianUser(userDetail?.accountCountry!) && (
                <ZenControlledTextInput<ZenCreateListingFormState, 'yearBuilt'>
                  control={control}
                  label='Year Built'
                  shouldUnregister={false}
                  rules={{
                    required: 'Year built is required',
                    ...YEAR_VALIDATIONS,
                  }}
                  isRequired
                  name='yearBuilt'
                  maxLength={4}
                  placeholder='Eg: 2020'
                />
              )}
            </div>
          </div>
        )}
        <div className='py-5'>
          <ZenButton
            label={
              isManualAddress ? 'Search Address' : 'Enter address manually'
            }
            LeftIconComponent={
              <FontAwesomeIcon
                icon={faArrowUpArrowDown}
                className='text-base'
              />
            }
            variant='primary-link'
            onClick={() => {
              setValue('isManualAddress', !isManualAddress);
            }}
          />
        </div>
        <div className='flex flex-row items-baseline space-x-4 mb-5 mt-12'>
          <ZenControlledTextInput<ZenCreateListingFormState, 'mlsNumber'>
            control={control}
            label='MLS Number'
            subLabel='(enter N/A if this is an exclusive / non-MLS deal)'
            name='mlsNumber'
            shouldUnregister={false}
            placeholder='E.g. 120001234AB'
            rules={{
              required: 'MLS number is required.',
            }}
            isRequired
          />
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-gray-outline'
              type='button'
              label='Cancel'
              onClick={() => setCancel(true)}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              type='submit'
              variant='primary'
              isDisabled={loading}
              label='Next'
              onClick={handleAddressStep}
            />
          </div>
        </div>
      </div>
      <ZenConfirmationModal
        variant='primary'
        title='Listing Saved!'
        subtitle='This listing will be saved in your drafts and will be available for future references.'
        confirmButtonText='OK'
        isOpen={cancel}
        onClose={() => setCancel(false)}
        onConfirm={() => history.push('/listings')}
        hideCancelButton
        hideIcon
      />
    </div>
  );
};

export default withCreateListingProgress(ZenListingAddressStep);
