import {
  faBuildings,
  faDollarSign,
  faHammer,
  faCalendarCheck,
  faCalendarClock,
  faCalendarPen,
  faEnvelope,
  faMoneySimpleFromBracket,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import {
  faHandshakeSimple,
  faTag,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as CopyIcon } from '../../../../assets/img/gemini/copy-icon.svg';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import {
  ParticipantValueRoleEnum,
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
  TransactionResponseTransactionTypeEnum,
} from '../../../../openapi/arrakis';
import { fetchDropbox } from '../../../../slices/DropboxSlice';
import { setActiveTransition } from '../../../../slices/QuickActionSlice';
import { getProcessTransaction } from '../../../../slices/TransactionSlice';
import { FeatureFlagTypeEnum, RootState } from '../../../../types';
import { displayFormattedAmountWithCurrency } from '../../../../utils/CurrencyUtils';
import { getParticipantName } from '../../../../utils/ParticipantHelper';
import {
  dateInPast,
  getAllTransactionParticipantsByRole,
  getTransactionActualClosingDate,
  getTransactionTransitionToReadableName,
  isCanadaTransaction,
  isTransactionInClosedState,
} from '../../../../utils/TransactionHelper';
import {
  getTransactionVariant,
  isPersonalDealTransaction,
} from '../../../../utils/TransactionUtils';
import ExcludeRealTitleOfficer from '../../../auth/ExcludeRealTitleOfficer';
import CopyToClipboardHover from '../../../CopyToClipboardHover';
import GeminiTransactionHeaderRowCell from '../../../Gemini/Transaction/Header/GeminiTransactionHeaderRowCell';
import Hover from '../../../Hover';
import ZenSimpleConfirmationModal from '../../../Zen/Modal/ZenSimpleConfirmationModal';
import ZenTransitionConfirmationModal from '../../../Zen/Transaction/Header/ZenTransitionConfirmationModal';
import ZenEditDealInformationForm from '../../../Zen/Transaction/ZenEditDealInformationForm';
import GeminiPill from '../../GeminiPill';
import GeminiMarkAsCompliantStatus from './GeminiMarkAsCompliantStatus';
import GeminiTransactionCheckType from './GeminiTransactionCheckType';
import GeminiTransactionCodeWithCopy from './GeminiTransactionCodeWithCopy';
import GeminiTransactionDealTypeLabel from './GeminiTransactionDealTypeLabel';
import GeminiTransactionHeaderStatus from './GeminiTransactionHeaderStatus';
import GeminiTransactionRepresentType from './GeminiTransactionRepresentType';

interface GeminiTransactionHeaderProps {
  transaction: TransactionResponse;
}

const GeminiTransactionHeader: React.FC<GeminiTransactionHeaderProps> = ({
  transaction,
}) => {
  const dispatch = useDispatch();
  const {
    quickAction: { activeTransition },
    dropbox: { dropboxById },
    team: { teamDetailOverview },
  } = useSelector((state: RootState) => state);
  const [
    retryTransition,
    setRetryTransition,
  ] = useState<TransactionLifecycleStateValueStateEnum>();
  const [openDealInfoSidebar, setOpenDealInfoSidebar] = useState(false);

  const allBuyers = getAllTransactionParticipantsByRole(
    transaction,
    ParticipantValueRoleEnum.Buyer,
  );
  const allTenants = getAllTransactionParticipantsByRole(
    transaction,
    ParticipantValueRoleEnum.Tenant,
  );

  const internalReferral = useFeatureFlag(
    FeatureFlagTypeEnum.INTERNAL_REFERRAL,
  );

  const isTransactionLeaseOrSale =
    transaction?.transactionType ===
      TransactionResponseTransactionTypeEnum.Lease ||
    transaction?.transactionType ===
      TransactionResponseTransactionTypeEnum.Sale;

  const allSellers = getAllTransactionParticipantsByRole(
    transaction,
    ParticipantValueRoleEnum.Seller,
  );
  const allLandlords = getAllTransactionParticipantsByRole(
    transaction,
    ParticipantValueRoleEnum.Landlord,
  );

  const allSellersAndLandlordsNames = [
    ...allSellers,
    ...allLandlords,
  ].map((participant) => getParticipantName(participant));

  const allBuyersAndTenantsNames = [
    ...allBuyers,
    ...allTenants,
  ].map((participant) => getParticipantName(participant));

  const dropboxId = transaction?.dropboxId!;
  const dropbox = dropboxById[dropboxId];

  useEffect(() => {
    if (!!dropboxId && dropbox?.id !== dropboxId) {
      dispatch(fetchDropbox(dropboxId));
    }
  }, [dispatch, dropbox?.id, dropboxId]);

  const closingDate = DateTime.fromISO(transaction.skySlopeEscrowClosingDate!, {
    zone: 'utc',
  }).toISODate();
  const today = DateTime.local({ zone: 'utc' }).toISODate();

  return (
    <header className='md:p-6 p-2 flex md:flex-row flex-col bg-primary-navy text-white rounded-[15px] md:mx-4 mx-2 my-1'>
      <div className='md:w-2/3 w-full md:pr-3'>
        <div className='flex md:flex-row flex-col'>
          <span className='font-zen-body-2 text-xl leading-7 font-bold md:mr-4 break-all'>
            {transaction.address?.oneLine}
          </span>
          <GeminiTransactionCodeWithCopy
            transactionCode={`Code: ${transaction.code}`}
          />
        </div>
        <div className='flex md:flex-row flex-col md:space-x-2 md:space-y-0 space-y-2 mt-4'>
          <div className='flex flex-row items-center flex-shrink-0'>
            <div className='h-6 w-6 flex items-center justify-center shrink-0'>
              <FontAwesomeIcon icon={faTag} className='text-lg leading-6' />
            </div>
            <span className='font-zen-body-2 font-normal text-lg leading-6 ml-1'>
              {displayFormattedAmountWithCurrency(transaction.price)}
            </span>
          </div>
          <GeminiTransactionDealTypeLabel
            dealType={transaction?.transactionType!}
          />
          {internalReferral ? (
            isTransactionLeaseOrSale && (
              <GeminiTransactionRepresentType transaction={transaction} />
            )
          ) : (
            <GeminiTransactionRepresentType transaction={transaction} />
          )}
          {isPersonalDealTransaction(transaction) && (
            <GeminiPill
              label='Personal Deal'
              containerClassNames='bg-midnight-green-300 text-midnight-green-900'
              icon={faHandshakeSimple}
            />
          )}
        </div>
        <div className='grid md:grid-cols-2 grid-cols-1 md:gap-x-6 md:gap-y-0 gap-y-4 mt-4 md:mt-8'>
          <div className='flex flex-col space-y-2.5'>
            <GeminiTransactionHeaderRowCell
              leftComponentIcon={faBuildings}
              label={
                <Link to={`/offices/${transaction.office?.id}`}>
                  {transaction.office?.name}
                </Link>
              }
              rightComponent={
                <GeminiTransactionCheckType transaction={transaction} />
              }
            />
            {!!transaction.listingTransaction?.price && (
              <GeminiTransactionHeaderRowCell
                leftComponentIcon={faDollarSign}
                label='List Price:'
                value={displayFormattedAmountWithCurrency(
                  transaction.listingTransaction?.price,
                )}
              />
            )}
            <GeminiTransactionHeaderRowCell
              leftComponentIcon={faDollarSign}
              label='Commission:'
              value={`${
                transaction.grossCommissionPercentage
              }|${displayFormattedAmountWithCurrency(
                transaction.grossCommission,
              )}`}
            />
            <ExcludeRealTitleOfficer>
              <GeminiTransactionHeaderRowCell
                leftComponentIcon={faUser}
                label='Buyer/Tenant:'
                value={
                  allBuyersAndTenantsNames?.length > 0
                    ? allBuyersAndTenantsNames.join(', ')
                    : 'N/A'
                }
                dataTestId='buyer-tenant-value'
              />
              <GeminiTransactionHeaderRowCell
                leftComponentIcon={faUser}
                label='Seller/Landlord:'
                value={
                  allSellersAndLandlordsNames?.length > 0
                    ? allSellersAndLandlordsNames.join(', ')
                    : 'N/A'
                }
              />
            </ExcludeRealTitleOfficer>
            <GeminiTransactionHeaderRowCell
              leftComponentIcon={faUser}
              label='Transaction Owner:'
              value={transaction?.transactionOwner?.name || 'N/A'}
            />
            {!!teamDetailOverview && (
              <GeminiTransactionHeaderRowCell
                leftComponentIcon={faUser}
                label='Team:'
                value={
                  ((
                    <Link to={`/teams/${teamDetailOverview.id}`}>
                      {teamDetailOverview?.name || 'N/A'}
                    </Link>
                  ) as unknown) as string
                }
              />
            )}
          </div>
          <div className='flex flex-col space-y-2.5'>
            {!isCanadaTransaction(transaction!) && (
              <GeminiTransactionHeaderRowCell
                leftComponentIcon={faHammer}
                label='Year Built:'
                value={
                  transaction.yearBuilt
                    ? transaction.yearBuilt.toString()
                    : 'N/A'
                }
              />
            )}
            <GeminiTransactionHeaderRowCell
              leftComponentIcon={faCalendarCheck}
              label='Acceptance Date:'
              labelHoverText='Contract Acceptance Date is the date the purchase contract was executed by both parties.'
              value={
                transaction.contractAcceptanceDate
                  ? DateTime.fromISO(
                      transaction.contractAcceptanceDate,
                    ).toFormat('MM/dd/yyyy')
                  : 'N/A'
              }
              dataTestId='acceptance-date-value'
            />
            <GeminiTransactionHeaderRowCell
              leftComponentIcon={faCalendarClock}
              label='Estimated Closing Date:'
              labelHoverText='Estimated Closing Date is the date that the property is expected to close and fully transfer to the other party. This date can be adjusted, please remember to upload the appropriate amendments and/or addenda to the checklist if this date changes.'
              value={
                !!transaction.skySlopeEscrowClosingDate
                  ? DateTime.fromISO(
                      transaction.skySlopeEscrowClosingDate,
                    ).toFormat('MM/dd/yyyy')
                  : 'N/A'
              }
              rightComponent={
                !!transaction.skySlopeEscrowClosingDate &&
                !isTransactionInClosedState(transaction) &&
                dateInPast(closingDate, today) ? (
                  <div>
                    <Hover
                      hoverComponent={
                        <div className='text-zen-dark-12 font-medium text-base font-zen-body px-6'>
                          Expired
                        </div>
                      }
                      config={{ trigger: 'hover', placement: 'top' }}
                    >
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className='text-base text-red-500 cursor-pointer px-2'
                        data-testid='expired'
                        onClick={() => setOpenDealInfoSidebar(true)}
                      />
                    </Hover>
                  </div>
                ) : undefined
              }
            />
            {isCanadaTransaction(transaction!) && (
              <GeminiTransactionHeaderRowCell
                leftComponentIcon={faCalendarPen}
                label='Firm Date:'
                labelHoverText='Firm Date is the date the transaction has become firm and all the conditions have been removed. If the contract is conditional, the Firm Date is the latest conditions date on the contract; if the contract is not conditional, the Firm Date is the date of contract acceptance.'
                value={
                  transaction.firmDate
                    ? DateTime.fromISO(transaction.firmDate).toFormat(
                        'MM/dd/yyyy',
                      )
                    : 'N/A'
                }
              />
            )}
            <GeminiTransactionHeaderRowCell
              leftComponentIcon={faCalendarCheck}
              label='Actual Closing Date:'
              labelHoverText='Actual Closing Date is the closing date that is verified by the Real Transactions Team and is entered when the Transactions Team Approves the Transaction for processing in reZEN.'
              value={getTransactionActualClosingDate(transaction)}
            />
            <GeminiTransactionHeaderRowCell
              leftComponentIcon={faMoneySimpleFromBracket}
              label='Transaction Processed:'
              labelHoverText='Transaction Process date is the date when the Transaction was processed for closing in reZEN, and if applicable, payments were scheduled.'
              value={
                transaction.rezenClosedAt
                  ? DateTime.fromMillis(transaction.rezenClosedAt).toFormat(
                      'MM/dd/yyyy',
                    )
                  : 'no date yet'
              }
            />
            {!!dropbox?.emailAddress && (
              <GeminiTransactionHeaderRowCell
                leftComponentIcon={faEnvelope}
                label='Transaction Email:'
                value={
                  <div className='flex items-center'>
                    <a
                      href={`mailto:${dropbox.emailAddress}`}
                      className='font-zen-body font-normal text-sm text-white whitespace-nowrap'
                    >
                      {dropbox.emailAddress}
                    </a>
                    <CopyToClipboardHover
                      value={dropbox.emailAddress}
                      copyToltipLabel='Copy Listing Email'
                      copyIcon={<CopyIcon className='cursor-pointer ml-1' />}
                    />
                  </div>
                }
              />
            )}
          </div>
        </div>
      </div>
      <div className='md:w-1/3 md:pl-3 mb-10'>
        <div className='mb-4'>
          <GeminiMarkAsCompliantStatus transaction={transaction} />
        </div>
        <GeminiTransactionHeaderStatus
          setRetryTransition={setRetryTransition}
          transaction={transaction}
        />
      </div>
      {!!retryTransition && (
        <ZenSimpleConfirmationModal
          isOpen
          variant='info'
          title={`Retry ${getTransactionTransitionToReadableName(
            transaction,
            retryTransition!,
          )}?`}
          subtitle={`Are you sure you'd like to reprocess this transaction?`}
          onClose={() => {
            setRetryTransition(undefined);
          }}
          onConfirm={async () => {
            await dispatch(getProcessTransaction(transaction.id!));
            setRetryTransition(undefined);
          }}
          confirmButtonText='Reprocess'
          isSubmitting={false}
        />
      )}
      {!!activeTransition && (
        <ZenTransitionConfirmationModal
          isOpen
          onClose={() => dispatch(setActiveTransition(undefined))}
          transaction={transaction}
          activeTransition={activeTransition}
          variant={getTransactionVariant(activeTransition!)}
        />
      )}
      {openDealInfoSidebar && (
        <ZenEditDealInformationForm
          isOpen
          onClose={() => setOpenDealInfoSidebar(false)}
          transaction={transaction}
        />
      )}
    </header>
  );
};

export default GeminiTransactionHeader;
