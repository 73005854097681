import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  OfficePreviewResponseTransactionTypeEnum,
  OfficeResponseTransactionTypeEnum,
} from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { ZenPillVariant } from '../../Zen/ZenPill';
import GeminiOfficeTransactionTypePill from './GeminiOfficeTransactionTypePill';

export interface GeminiOfficeTransactionTypeCellProps {
  transactionType:
    | OfficeResponseTransactionTypeEnum
    | OfficePreviewResponseTransactionTypeEnum;
}

export const transactionTypeToPillVariant: EnumMap<
  OfficeResponseTransactionTypeEnum,
  ZenPillVariant
> = {
  SINGLE_CHECK: 'primary-nooutline',
  SPLIT_CHECK: 'purple',
};

export const transactionTypeIconToPillVariant: EnumMap<
  OfficeResponseTransactionTypeEnum,
  React.ReactElement
> = {
  SINGLE_CHECK: (
    <FontAwesomeIcon
      icon={regular('money-check-dollar')}
      className='text-primary-navy'
    />
  ),
  SPLIT_CHECK: (
    <FontAwesomeIcon icon={regular('split')} className='text-primary-navy' />
  ),
};

const GeminiOfficeTransactionTypeCell: React.FC<GeminiOfficeTransactionTypeCellProps> = ({
  transactionType,
}) => {
  return (
    <div className='w-max'>
      <GeminiOfficeTransactionTypePill
        title={capitalizeEnum(transactionType)}
        variant={transactionTypeToPillVariant[transactionType]}
        leftIcon={transactionTypeIconToPillVariant[transactionType]}
        textStyle='text-sm text-primary-navy pl-0.5'
      />
    </div>
  );
};

export default GeminiOfficeTransactionTypeCell;
