import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { AgentJoinedBetween } from '../../../openapi/mercury';

interface AgentJoinedBetweenTrayProps {
  joinedBetweenCondition: AgentJoinedBetween;
}

const AgentJoinedBetweenTray: React.FC<AgentJoinedBetweenTrayProps> = ({
  joinedBetweenCondition,
}) => {
  const startDate = !!joinedBetweenCondition?.startDate
    ? DateTime.fromISO(joinedBetweenCondition.startDate!).toFormat('LL/dd/yy')
    : 'N/A';
  const endDate = !!joinedBetweenCondition?.endDate
    ? DateTime.fromISO(joinedBetweenCondition.endDate!).toFormat('LL/dd/yy')
    : 'N/A';

  return (
    <div className='w-max flex flex-row items-center font-zen-body font-normal text-sm space-x-1'>
      <FontAwesomeIcon icon={light('clock-two')} />
      <div className='flex flex-row items-center text-zen-dark-9'>
        <span>{startDate}</span>
        <div className='mx-1 w-2 h-px bg-zen-dark-6' />
        <span>{endDate}</span>
      </div>
    </div>
  );
};

export default AgentJoinedBetweenTray;
