import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../types';
import {
  getExperimentById,
  updateExperimentGroup,
} from '../slices/ExperimentSlice';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ResourceContainer from '../components/ResourceContainer';
import DetailPageLoader from '../components/DetailPageLoader';
import ZenExperimentHeaderSection from '../components/experiment/ZenExperimentHeaderSection';
import ZenButton from '../components/Zen/ZenButton';
import { AgentInfo } from '../openapi/yenta';
import useAgentsInfo from '../hooks/useAgentsInfo';
import ZenListPaginateContainer from '../components/Zen/Containers/ZenListPaginateContainer';
import ZenExperimentAgentsTable from '../components/experiment/ZenExperimentAgentsTable';
import ZenCard from '../components/Zen/ZenCard';
import ZenAdd from '../components/Zen/ZenAdd';
import ZenEditExperiment from '../components/experiment/ZenEditExperiment';
import ZenAddOverrideAgent from '../components/experiment/ZenAddOverrideAgent';
import { filterByNameOrEmail } from '../utils/AgentHelper';

type Match = {
  id: string;
};

const ZenExperimentDetailRoute: React.FC = () => {
  const dispatch = useDispatch();
  const [
    openEditExperimentSidebarModal,
    setOpenEditExperimentSidebarModal,
  ] = useState<boolean>(false);
  const [
    openAddAgentSidebarModal,
    setOpenAddAgentSidebarModal,
  ] = useState<boolean>(false);
  const [overrideAgents, setOverrideAgents] = useState<AgentInfo[]>([]);

  const { id: experimentId } = useParams() as Match;

  const {
    experiment: { experimentDetail, loadingDetail, fetchDetailErrorCode },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  let overrideAgentsIds =
    experimentDetail?.groups?.[0]?.overrideIds?.map((agentId) => {
      return agentId;
    }) || [];

  const { loading: isAgentsInfoLoading } = useAgentsInfo(overrideAgentsIds);

  useEffect(() => {
    setOverrideAgents(
      experimentDetail?.groups?.[0]?.overrideIds
        ?.map((agentId) => {
          return agentById[agentId]!;
        })
        .filter((agent) => !!agent) || [],
    );
  }, [agentById, experimentDetail?.groups]);

  const editExperiment = async (weight: string | undefined) => {
    if (weight) {
      await dispatch(
        updateExperimentGroup(
          experimentDetail?.groups![0].id!,
          {
            weight: Number(weight),
            overrideIds: experimentDetail?.groups?.[0]?.overrideIds,
          },
          experimentId,
        ),
      );
    }
  };

  const updateOverrideAgents = async (
    addOverrideAgentsIds: string[] | undefined,
  ) => {
    if (addOverrideAgentsIds?.length) {
      await dispatch(
        updateExperimentGroup(
          experimentDetail?.groups?.[0]?.id!,
          {
            weight: experimentDetail?.groups?.[0]?.weight!,
            overrideIds: [
              ...(experimentDetail?.groups?.[0]?.overrideIds! || []),
              ...(addOverrideAgentsIds || []),
            ],
          },
          experimentId,
        ),
      );
    }
  };

  const removeOverrideAgent = async (agentToRemove: AgentInfo | undefined) => {
    const overrideIds = experimentDetail?.groups?.[0]?.overrideIds?.filter(
      (overrideId) => overrideId !== agentToRemove?.id,
    );
    if (agentToRemove) {
      await dispatch(
        updateExperimentGroup(
          experimentDetail?.groups![0].id!,
          {
            weight: experimentDetail?.groups![0].weight!,
            overrideIds: overrideIds,
          },
          experimentDetail?.id,
        ),
      );
    }
  };

  useEffect(() => {
    dispatch(getExperimentById(experimentId));
  }, [dispatch, experimentId]);

  return (
    <ZenRoute title='Experiment Details'>
      <ZenPageLayout
        path={[
          { title: 'Engineering', url: '/engineering' },
          { title: 'Feature Flags', url: '/feature-flags' },
          {
            title: `${experimentDetail?.name}`,
            url: `/feature-flags/${experimentDetail?.id}`,
          },
        ]}
      >
        <ResourceContainer
          loading={loadingDetail}
          LoaderComponent={<DetailPageLoader />}
          isEmpty={!experimentDetail}
          errorCode={fetchDetailErrorCode}
          resourceName='Experiment'
        >
          <div className='px-4 mt-1 pb-6 space-y-5'>
            <ZenExperimentHeaderSection experiment={experimentDetail!} />
            <ZenButton
              variant='secondary-gray-outline'
              label='Edit Experiment'
              LeftIconComponent={
                <FontAwesomeIcon icon={faPencil} className='pr-1' />
              }
              onClick={() => {
                setOpenEditExperimentSidebarModal(true);
              }}
            />
            <div className='mt-4'>
              <ZenCard
                title='Agents'
                RightComponent={
                  <div className='flex flex-row items-center space-x-4'>
                    <ZenAdd
                      text='Add Agent'
                      onClick={() => setOpenAddAgentSidebarModal(true)}
                    />
                  </div>
                }
              >
                <div className='md:p-6 py-6'>
                  <ZenListPaginateContainer<AgentInfo>
                    initialLoading={isAgentsInfoLoading}
                    fetchData={async ({ search }) => {
                      let filteredOverrideAgents: AgentInfo[] = [];

                      if (search) {
                        filteredOverrideAgents = filterByNameOrEmail(
                          overrideAgents,
                          search,
                        );
                      } else {
                        filteredOverrideAgents = overrideAgents;
                      }

                      return {
                        data: filteredOverrideAgents,
                        total: filteredOverrideAgents?.length!,
                      };
                    }}
                    resourceName='agent'
                    searchPlaceholder='Search by name or email address'
                  >
                    {(agents) => {
                      return (
                        <ZenExperimentAgentsTable
                          agents={agents}
                          onDeleteActionClick={(value) => {
                            removeOverrideAgent(value);
                          }}
                        />
                      );
                    }}
                  </ZenListPaginateContainer>
                </div>
              </ZenCard>
            </div>
          </div>
        </ResourceContainer>
      </ZenPageLayout>
      {openEditExperimentSidebarModal && (
        <ZenEditExperiment
          experimentResponse={experimentDetail!}
          isOpen
          onClose={(value) => {
            editExperiment(value);
            setOpenEditExperimentSidebarModal(false);
          }}
        />
      )}
      {openAddAgentSidebarModal && (
        <ZenAddOverrideAgent
          isOpen
          overrideAgents={overrideAgents}
          onClose={(value) => {
            updateOverrideAgents(value);
            setOpenAddAgentSidebarModal(false);
          }}
        />
      )}
    </ZenRoute>
  );
};

export default ZenExperimentDetailRoute;
