import React from 'react';
import { useGemini } from '../../hooks/useGemini';
import { cn } from '../../utils/classUtils';

export interface InformationCardProps {
  title: string;
  titleIcon?: string;
}

const ZenInformationCard: React.FC<InformationCardProps> = ({
  title,
  titleIcon,
  children,
}) => {
  const isGeminiEnabled = useGemini();

  return (
    <div className='rounded-[10px] border border-zen-dark-4 overflow-hidden'>
      <div
        className={cn(
          'flex flex-row items-center bg-grey-100 h-[45px] px-3 space-x-1',
          isGeminiEnabled
            ? 'font-inter text-base font-medium leading-[22px] text-primary-dark'
            : 'font-title text-lg text-zen-dark-9',
        )}
      >
        {titleIcon && (
          <div className='md:hidden block'>
            <img src={titleIcon} alt={title} className='w-5 h-5 mb-1' />
          </div>
        )}
        <span>{title}</span>
      </div>
      {children}
    </div>
  );
};

export default ZenInformationCard;
