import ZenButton, { ZenButtonType } from '../Zen/ZenButton';

interface ZenSidebarModalActionFooterProps {
  submitButtonText?: string;
  cancelButtonText?: string;
  onClose(): void;
  isSubmitting?: boolean;
  submitButtonDisabled?: boolean;
  cancelButtonDisabled?: boolean;
  cancelButtonType?: ZenButtonType;
}

const ZenSidebarModalActionFooter: React.FC<ZenSidebarModalActionFooterProps> = ({
  submitButtonText = 'Save',
  cancelButtonText = 'Cancel',
  submitButtonDisabled = false,
  cancelButtonDisabled = false,
  cancelButtonType = 'button',
  onClose,
  isSubmitting = false,
}) => {
  return (
    <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2 w-full'>
      <ZenButton
        label={cancelButtonText}
        variant='primary-outline'
        onClick={onClose}
        isDisabled={cancelButtonDisabled || isSubmitting}
        isFullWidth
        type={cancelButtonType}
      />
      <ZenButton
        label={submitButtonText}
        variant='primary'
        isSubmitting={isSubmitting}
        isDisabled={submitButtonDisabled}
        isFullWidth
        type='submit'
      />
    </div>
  );
};

export default ZenSidebarModalActionFooter;
