import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsService from '../../../../services/AnalyticsService';
import {
  getPostCapSpp,
  setSelectedEarningsOverviewType,
} from '../../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum, RootState } from '../../../../types';
import { formatMoneyValue } from '../../../../utils/CurrencyUtils';
import { calculatePercentage } from '../../../../utils/MathUtils';
import IncomeProjectionPieChart from '../../V2/IncomeProjectionPieChart';
import WealthPlanInfoCard from '../../V2/WealthPlanInfoCard';
import { Earning } from '../../WealthManagementTypes';
import SppParticipationToggleV2 from './SppParticipationToggleV2';
import WealthPlanSidebarContainer from './WealthPlanSidebarContainer';

export const PostCapSppSidebarContentV2 = () => {
  const dispatch = useDispatch();
  const {
    postCapSppResponse: {
      loading: postCapSppLoading,
      data: postCapSppResponse,
    },
    wealthGoalsResponse: { data: wealthGoalsResponse },
  } = useSelector((state: RootState) => state.wealthManagement);

  const {
    currentPostCapSppValue,
    matchingGrantValue,
    projectedMatchingPostCapSppValue,
    projectedPostCapSppValue,
    projectedPurchasePostCapSppValue,
    currentPostCapSppShares,
    matchingGrantShares,
    projectedPostCapSppShares,
  } = postCapSppResponse || {};

  const isLoading = postCapSppLoading && !postCapSppResponse;
  const projectedPostCapSppValuePercent = calculatePercentage(
    projectedPurchasePostCapSppValue?.amount || 0,
    projectedPostCapSppValue?.amount! > 0
      ? projectedPostCapSppValue?.amount!
      : 100,
  );

  useEffect(() => {
    dispatch(getPostCapSpp());
  }, [dispatch, wealthGoalsResponse]);

  const handleEditClick = () => {
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.WEALTH_PLAN_POST_CAP_EDIT_VOLUME,
    );
    dispatch(setSelectedEarningsOverviewType(Earning.COMMISSION_INCOME));
  };

  return (
    <WealthPlanSidebarContainer
      type='POST_CAP_SPP'
      loading={isLoading}
      isEmpty={!postCapSppResponse}
      resourceName='SPP'
    >
      <WealthPlanInfoCard
        label='5 Year Portfolio Value'
        value={formatMoneyValue(projectedPostCapSppValue)}
        labelTooltip='Projected stock value, made up of the stock you purchased from your commission income plus the Real bonus.'
        subValue={projectedPostCapSppShares}
        valueSizeVariant='text-3xl'
        tooltipAnalyticsEvent={
          AnalyticsEventEnum.WEALTH_PLAN_POST_CAP_5_YEAR_PROJ_TOOLTIP
        }
      />
      <div className='flex flex-row justify-between space-x-3'>
        <WealthPlanInfoCard
          label='Current SPP Value'
          labelTooltip='Approximate value of stock purchased from your commission income contribution. Refer to your Morgan Stanley Shareworks account for exact value.'
          value={formatMoneyValue(currentPostCapSppValue)}
          subValue={currentPostCapSppShares}
          tooltipAnalyticsEvent={
            AnalyticsEventEnum.WEALTH_PLAN_POST_CAP_SPP_TO_DATE_TOOLTIP
          }
        />
        <WealthPlanInfoCard
          label='Matching Grant'
          value={formatMoneyValue(matchingGrantValue)}
          subValue={matchingGrantShares}
          labelTooltip='Approximate value of Real stock bonus awarded to you. Refer to your Morgan Stanley Shareworks account for exact value.'
          tooltipAnalyticsEvent={
            AnalyticsEventEnum.WEALTH_PLAN_POST_CAP_BONUS_TO_DATE_TOOLTIP
          }
        />
      </div>
      <WealthPlanInfoCard
        label='Average Volume Per Year'
        value={formatMoneyValue(wealthGoalsResponse?.goalDetails.volumePerYear)}
        valueStyles='text-xl font-medium text-zen-dark-7'
        editButtonText='Edit'
        onEditClick={handleEditClick}
      />
      <IncomeProjectionPieChart
        data={[
          {
            value:
              projectedPostCapSppValuePercent > 0
                ? projectedPostCapSppValuePercent
                : 0.5,
            name: formatMoneyValue(projectedPurchasePostCapSppValue),
            fill: '#439775',
            label: 'Agent Contribution',
          },
          {
            value: 100 - projectedPostCapSppValuePercent,
            name: formatMoneyValue(projectedMatchingPostCapSppValue),
            fill: '#8BB7FF',
            label: 'Real Bonus',
          },
        ]}
        title='5 Year Projected SPP Value'
      />
      <SppParticipationToggleV2 />
      <div className='border-b border-gray-300' />
    </WealthPlanSidebarContainer>
  );
};
