import React from 'react';
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  PathValue,
  UnpackNestedValue,
  UseControllerProps,
} from 'react-hook-form-v7';
import { ISelectOption } from '../../../types';
import { cn } from '../../../utils/classUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';

type ZenCheckboxInputVariantType = 'square' | 'circle';

type ZenCheckboxSizeType = 'sm' | 'md';

type ZenCheckboxBorderType = 'default' | 'danger';

export interface ZenCheckboxOptionComponentProps<
  TFieldValues extends FieldValues
> {
  value: string;
  name: string;
  checked: boolean;
  label: React.ReactNode;
  onChange(value: string): void;
  fieldValue: UnpackNestedValue<PathValue<any, any>>;
  control?: Control<TFieldValues, object>;
}

export type ZenCheckboxOptionComponent<
  TFieldValues extends FieldValues
> = React.FC<ZenCheckboxOptionComponentProps<TFieldValues>>;

interface ZenControlledCheckboxInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  options: Array<ISelectOption<string, React.ReactNode>>;
  variant?: ZenCheckboxInputVariantType;
  size?: ZenCheckboxSizeType;
  border?: ZenCheckboxBorderType;
  reverse?: boolean;
  disabled?: boolean;
  inlineOptions?: boolean;
  OptionComponent?: ZenCheckboxOptionComponent<TFieldValues>;
  optionsTextStyle?: string;
  onChangeSpy?(value: string[]): void;
  style?: React.CSSProperties;
}

const ZenControlledCheckboxInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  options,
  shouldUnregister = true,
  variant = 'square',
  size = 'md',
  border = 'default',
  reverse = false,
  inlineOptions = false,
  OptionComponent,
  optionsTextStyle,
  onChangeSpy,
  style,
  ...rest
}: ZenControlledCheckboxInputProps<TFieldValues, TName>) => {
  const shapeVariantClassMap: Record<ZenCheckboxInputVariantType, string> = {
    square: 'rounded',
    circle: 'rounded-full',
  };

  const sizeVariantClassMap: Record<ZenCheckboxSizeType, string> = {
    sm: 'w-4 h-4',
    md: 'w-6 h-6',
  };

  const borderVariantClassMap: Record<ZenCheckboxBorderType, string> = {
    default: 'border-zen-dark-4',
    danger: 'border-zen-danger',
  };

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <div className='space-y-1'>
          {label && (
            <label
              className='font-zen-body font-semibold text-zen-dark-9'
              htmlFor={name}
            >
              {label}
            </label>
          )}
          <div
            className={cn(
              inlineOptions
                ? 'flex items-end flex-wrap space-y-2'
                : 'space-y-2',
            )}
          >
            {options.map(({ value: optionValue, label }) => (
              <label
                key={optionValue}
                className={cn(
                  'flex items-center cursor-pointer',
                  reverse ? 'flex-row-reverse justify-end' : 'justify-between',
                )}
              >
                {OptionComponent ? (
                  <OptionComponent
                    name={name}
                    onChange={onChange}
                    label={label}
                    checked={value?.includes(optionValue)}
                    value={optionValue}
                    fieldValue={value}
                    control={rest.control}
                  />
                ) : (
                  <>
                    <p
                      className={cn(
                        'font-zen-body font-normal text-lg text-zen-dark-9',
                        reverse && 'pl-3 text-base',
                        inlineOptions && 'mr-3',
                        optionsTextStyle,
                      )}
                    >
                      {label}
                    </p>
                    <input
                      type='checkbox'
                      className={cn(
                        'border-2 text-primary-blue focus:outline-none focus:ring-0 cursor-pointer',
                        shapeVariantClassMap[variant],
                        sizeVariantClassMap[size],
                        borderVariantClassMap[border],
                      )}
                      style={value?.includes(optionValue) ? style : {}}
                      value={optionValue}
                      name={name}
                      onChange={({
                        target: { checked, value: checkboxValue },
                      }) => {
                        const updatedValues: string[] = checked
                          ? [...(value || []), checkboxValue]
                          : value?.filter((v: string) => v !== checkboxValue);
                        const values = updatedValues.length
                          ? updatedValues
                          : [];
                        onChange(values);
                        if (onChangeSpy) {
                          onChangeSpy(values);
                        }
                      }}
                      onBlur={onBlur}
                      checked={value?.includes(optionValue)}
                      ref={ref}
                    />
                  </>
                )}
              </label>
            ))}
          </div>
          {error && <ZenFormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenControlledCheckboxInput;
