import { GooglePlaceLocationType } from '../components/ControlledGoogleAutocompleteSearchInputV7';
import {
  AgentWebsiteResponse,
  CreateOrUpdateAgentWebsiteRequestCompetenciesEnum,
  CreateOrUpdateAgentWebsiteRequestPhotoSetEnum,
  CreateOrUpdateAgentWebsiteRequestThemeColorEnum,
  PropertyFilter,
  PropertyFilterBathroomsEnum,
  PropertyFilterBedroomsEnum,
  PropertyFilterCategoryTypeEnum,
  PropertyFilterResponseBathroomsEnum,
  PropertyFilterResponseBedroomsEnum,
  PropertyFilterResponseCategoryTypeEnum,
} from '../openapi/yenta';
import { AgentWebsiteOnboardingFormData } from '../routes/AgentWebsiteOnboardingRoute';
import { ISelectOption } from '../types';
import { getYentaImageUrl } from './ImgUtils';
import { capitalizeEnum } from './StringUtils';

export const BEDS_SELECT_OPTION: ISelectOption[] = [
  {
    label: 'Any',
    value: PropertyFilterBedroomsEnum.Any,
  },
  {
    label: 'Studio',
    value: PropertyFilterBedroomsEnum.Studio,
  },
  {
    label: '1+',
    value: PropertyFilterBedroomsEnum.OneOrMore,
  },
  {
    label: '2+',
    value: PropertyFilterBedroomsEnum.TwoOrMore,
  },
  {
    label: '3+',
    value: PropertyFilterBedroomsEnum.ThreeOrMore,
  },
  {
    label: '4+',
    value: PropertyFilterBedroomsEnum.FourOrMore,
  },
  {
    label: '5+',
    value: PropertyFilterBedroomsEnum.FiveOrMore,
  },
];

export const BATHS_SELECT_OPTION: ISelectOption[] = [
  {
    label: 'Any',
    value: PropertyFilterBathroomsEnum.Any,
  },
  {
    label: 'Studio',
    value: PropertyFilterBathroomsEnum.Studio,
  },
  {
    label: '1+',
    value: PropertyFilterBathroomsEnum.OneOrMore,
  },
  {
    label: '2+',
    value: PropertyFilterBathroomsEnum.TwoOrMore,
  },
  {
    label: '3+',
    value: PropertyFilterBathroomsEnum.ThreeOrMore,
  },
  {
    label: '4+',
    value: PropertyFilterBathroomsEnum.FourOrMore,
  },
  {
    label: '5+',
    value: PropertyFilterBathroomsEnum.FiveOrMore,
  },
];

export const CATEGORY_SELECT_OPTION: ISelectOption[] = [
  {
    label: capitalizeEnum(PropertyFilterCategoryTypeEnum.Buy),
    value: PropertyFilterCategoryTypeEnum.Buy,
  },
  {
    label: capitalizeEnum(PropertyFilterCategoryTypeEnum.Rent),
    value: PropertyFilterCategoryTypeEnum.Rent,
  },
  // {
  //   label: capitalizeEnum(PropertyFilterCategoryTypeEnum.Mls),
  //   value: PropertyFilterCategoryTypeEnum.Mls,
  // },
];

export const getDisplayBeds = (
  beds?: PropertyFilterResponseBedroomsEnum | PropertyFilterBedroomsEnum,
) =>
  BEDS_SELECT_OPTION.find((b) => b.value === beds)?.label ||
  PropertyFilterBedroomsEnum.Any;

export const getDisplayBaths = (
  baths?: PropertyFilterResponseBathroomsEnum | PropertyFilterBathroomsEnum,
) =>
  BATHS_SELECT_OPTION.find((b) => b.value === baths)?.label ||
  PropertyFilterBathroomsEnum.Any;

export const getDisplayCategory = (
  category?: PropertyFilterResponseCategoryTypeEnum,
) =>
  CATEGORY_SELECT_OPTION.find((c) => c.value === category)?.label ||
  PropertyFilterCategoryTypeEnum.Buy;

export const FACEBOOK_URL = 'https://facebook.com/';
export const LINKEDIN_URL = 'https://linkedin.com/';
export const INSTAGRAM_URL = 'https://instagram.com/';
export const TWITTER_URL = 'https://twitter.com/';
export const ZILLOW_URL = 'https://zillow.com/';
export const YELP_URL = 'https://yelp.com/';
export const YOUTUBE_URL = 'https://youtube.com/';
export const CLUBHOUSE_URL = 'https://clubhouse.com/';

const DEFAULT_MAIN_TITLE = 'Find your dream home!';
const DEFAULT_WEBSITE_TITLE = 'Agent';
const DEFAULT_MAIN_SUBTITLE = 'Unique Service Offerings in Your Area';
const DEFAULT_BOTTOM_TITLE = 'Helping you buy or sell a property is sacred!';
const DEFAULT_BOTTOM_SUBTITLE =
  'I take great pride in my work, and I am honored to have the privilege to help families just like yours';
const DEFAULT_FEATURE1_TITLE = 'Buy or sell today!';
const DEFAULT_FEATURE2_TITLE = 'Proven track record';
const DEFAULT_FEATURE3_TITLE = 'Cutting edge technology';
const DEFAULT_FEATURE1_CONTENT =
  'Use my site to search nearby properties and schedule appointments.';
const DEFAULT_FEATURE2_CONTENT =
  'My experience and dedication set me apart, while my technology and responsiveness help families.';
const DEFAULT_FEATURE3_CONTENT =
  'Using a robust mobile platform and nimble real-time solution ensures that we never miss an opportunity.';

export const getPathFromURL = (url?: string): string | undefined => {
  if (!url) {
    return url;
  }

  return url.replace(/^(https?:\/\/).*(.com\/)/, '');
};

export const formUrlFromPath = (
  domain: string,
  path?: string,
): string | undefined => {
  if (!path) {
    return path;
  }

  return `${domain}${getPathFromURL(path)}`;
};

export const getUrlWithPrefix = (domain: string): string => {
  return domain.replace(/^(https?):\/\//, 'www.');
};

export const getAgentWebsiteDefaultFormValues = (
  agentWebsiteRes?: AgentWebsiteResponse,
  place?: google.maps.GeocoderResult,
): AgentWebsiteOnboardingFormData => ({
  bottomSubtitle: agentWebsiteRes?.bottomSubtitle || DEFAULT_BOTTOM_SUBTITLE,
  bottomTitle: agentWebsiteRes?.bottomSubtitle || DEFAULT_BOTTOM_TITLE,
  clubhouseURL: getPathFromURL(agentWebsiteRes?.agentResponse?.clubhouseURL),
  competencies: ((agentWebsiteRes?.agentResponse?.competencies ||
    []) as unknown) as CreateOrUpdateAgentWebsiteRequestCompetenciesEnum[],
  facebookURL: getPathFromURL(agentWebsiteRes?.agentResponse?.facebookURL),
  feature1Content: agentWebsiteRes?.feature1Content || DEFAULT_FEATURE1_CONTENT,
  feature1Title: agentWebsiteRes?.feature1Title || DEFAULT_FEATURE1_TITLE,
  feature2Content: agentWebsiteRes?.feature2Content || DEFAULT_FEATURE2_CONTENT,
  feature2Title: agentWebsiteRes?.feature2Title || DEFAULT_FEATURE2_TITLE,
  feature3Content: agentWebsiteRes?.feature3Content || DEFAULT_FEATURE3_CONTENT,
  feature3Title: agentWebsiteRes?.feature3Title || DEFAULT_FEATURE3_TITLE,
  googleBusinessProfileURL:
    agentWebsiteRes?.agentResponse?.googleBusinessProfileURL,
  instagramURL: getPathFromURL(agentWebsiteRes?.agentResponse?.instagramURL),
  linkedInURL: getPathFromURL(agentWebsiteRes?.agentResponse?.linkedInURL),
  mainSubtitle: agentWebsiteRes?.mainSubtitle || DEFAULT_MAIN_SUBTITLE,
  mainTitle: agentWebsiteRes?.mainTitle || DEFAULT_MAIN_TITLE,
  photoSet:
    ((agentWebsiteRes?.photoSet as unknown) as CreateOrUpdateAgentWebsiteRequestPhotoSetEnum) ||
    CreateOrUpdateAgentWebsiteRequestPhotoSetEnum.Cabin,
  propertyFilter: (agentWebsiteRes?.propertyFilter as PropertyFilter) || {
    bathrooms: PropertyFilterBathroomsEnum.Any,
    bedrooms: PropertyFilterBedroomsEnum.Any,
    categoryType: PropertyFilterCategoryTypeEnum.Buy,
    minPrice: 0,
    maxPrice: 3750000,
  },
  serviceAreaZipcodes: agentWebsiteRes?.agentResponse?.serviceAreaZipcodes,
  showReferralLink: agentWebsiteRes?.showReferralLink,
  slug: agentWebsiteRes?.agentResponse?.slug,
  testimonials: agentWebsiteRes?.testimonials,
  themeColor:
    ((agentWebsiteRes?.themeColor as unknown) as CreateOrUpdateAgentWebsiteRequestThemeColorEnum) ||
    CreateOrUpdateAgentWebsiteRequestThemeColorEnum.Blue,
  twitterURL: getPathFromURL(agentWebsiteRes?.agentResponse?.twitterURL),
  websiteTitle: agentWebsiteRes?.websiteTitle || DEFAULT_WEBSITE_TITLE,
  yelpURL: getPathFromURL(agentWebsiteRes?.agentResponse?.yelpURL),
  youtubeURL: getPathFromURL(agentWebsiteRes?.agentResponse?.youtubeURL),
  zillowURL: getPathFromURL(agentWebsiteRes?.agentResponse?.zillowURL),
  location: {
    formatted_address: place?.formatted_address,
    place_id: agentWebsiteRes?.propertyFilter?.googlePlaceId!,
    geometry: {
      location: {
        lat: agentWebsiteRes?.propertyFilter?.latitude!,
        lng: agentWebsiteRes?.propertyFilter?.longitude!,
      },
    },
  } as GooglePlaceLocationType,
  priceRange: {
    min: agentWebsiteRes?.propertyFilter?.minPrice!,
    max: agentWebsiteRes?.propertyFilter?.maxPrice!,
  },
  photo: getYentaImageUrl(agentWebsiteRes?.agentResponse?.avatar),
  name: agentWebsiteRes?.agentResponse?.displayName,
});
