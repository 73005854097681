import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ZenRoute from '../../components/Zen/ZenRoute';
import { LoginRequest } from '../../openapi/keymaker';
import { fetchAuthUserDetail, loginUserOrThrow } from '../../slices/AuthSlice';
import { AppDispatch, RootState } from '../../types';
import { hasPastAgreementRequiredDate } from '../../utils/AgreementUtils';
import ChangePasswordSection from '../ChangePassword/ChangePasswordSection';

const RezenRoute: React.FC = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const {
    agentDetail: { signAgentAgreement, agentNonSignedAgreementsResponse },
  } = useSelector((state: RootState) => state);

  const hasPastRequiredDate = hasPastAgreementRequiredDate(
    agentNonSignedAgreementsResponse?.requiredAt!,
  );

  const onChangePasswordSuccess = async (values: LoginRequest) => {
    const req: LoginRequest = {
      usernameOrEmail: values.usernameOrEmail,
      password: values.password,
    };
    await dispatch(loginUserOrThrow(req));

    await dispatch(fetchAuthUserDetail(false));

    if (hasPastRequiredDate && signAgentAgreement) {
      history.push('/welcome-to-rezen/ica-signing-v2');
    } else {
      history.push('/welcome-to-rezen/mobile-app');
    }
  };

  return (
    <ZenRoute title='Welcome to ReZEN'>
      <ChangePasswordSection
        onChangePasswordSuccess={onChangePasswordSuccess}
        title='Welcome to Real'
        subtitle='Building your future, together'
        buttonText='Get Started'
      />
    </ZenRoute>
  );
};

export default RezenRoute;
