import React from 'react';
import { OfficeResponse } from '../../openapi/yenta';
import GeminiInformationCard from './GeminiInformationCard';
import GeminiLabelValueDisplayCard from './Office/GeminiLabelValueDisplayCard';

interface GeminiBankTrustInfoProps {
  officeDetail: OfficeResponse | undefined;
}

const GeminiBankTrustInfo: React.FC<GeminiBankTrustInfoProps> = ({
  officeDetail,
}) => {
  if (officeDetail?.bankAccount === null) return null;
  return (
    <GeminiInformationCard title='Bank Account'>
      <div className='space-y-6 hidden md:block py-5'>
        <div className='grid grid-cols-2 divide-x divide-grey-300'>
          <div className='px-7 py-2'>
            <GeminiLabelValueDisplayCard
              label='Bank Name'
              value={officeDetail?.bankAccount?.bankName! ?? 'N/A'}
              noPadding
            />
          </div>
          <div className='px-4 py-2'>
            <GeminiLabelValueDisplayCard
              label='Account Number'
              value={officeDetail?.bankAccount?.accountNumber! ?? 'N/A'}
              noPadding
            />
          </div>
        </div>
        <div className='grid grid-cols-2 divide-x divide-grey-300'>
          <div className='px-7 py-2'>
            <GeminiLabelValueDisplayCard
              label='Routing Number (For ACH)'
              value={officeDetail?.bankAccount?.bankRoutingNumber! ?? 'N/A'}
              noPadding
            />
          </div>
          <div className='px-4 py-2'>
            <GeminiLabelValueDisplayCard
              label='Routing Number (For Wires)'
              value={
                (officeDetail?.bankAccount?.wireRoutingNumber! ||
                  officeDetail?.bankAccount?.bankRoutingNumber!) ??
                'N/A'
              }
              noPadding
            />
          </div>
        </div>
        <div className='px-7 py-2'>
          <GeminiLabelValueDisplayCard
            label='Payable To'
            value={officeDetail?.company?.name ?? 'N/A'}
            noPadding
          />
        </div>
      </div>
      <div className='px-4 md:hidden block divide-y divide-grey-300'>
        <div className='py-4'>
          <GeminiLabelValueDisplayCard
            label='Bank Name'
            value={officeDetail?.bankAccount?.bankName! ?? 'N/A'}
            noPadding
          />
        </div>
        <div className='py-4'>
          <GeminiLabelValueDisplayCard
            label='Account Number'
            value={officeDetail?.bankAccount?.accountNumber! ?? 'N/A'}
            noPadding
          />
        </div>
        <div className='py-4'>
          <GeminiLabelValueDisplayCard
            label='Routing Number (For ACH)'
            value={officeDetail?.bankAccount?.bankRoutingNumber! ?? 'N/A'}
            noPadding
          />
        </div>
        <div className='py-4'>
          <GeminiLabelValueDisplayCard
            label='Routing Number (For Wires)'
            value={
              (officeDetail?.bankAccount?.wireRoutingNumber! ||
                officeDetail?.bankAccount?.bankRoutingNumber!) ??
              'N/A'
            }
            noPadding
          />
        </div>
        <div className='py-4'>
          <GeminiLabelValueDisplayCard
            label='Payable To'
            value={officeDetail?.company?.name ?? 'N/A'}
            noPadding
          />
        </div>
      </div>
    </GeminiInformationCard>
  );
};

export default GeminiBankTrustInfo;
