import { Chat, OpenInNew } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as AddCredit } from '../../assets/icons/add_credits.svg';
import { ReactComponent as Regenerate } from '../../assets/icons/regenerate.svg';
import ZenAddAgentCreditFormSidebarModal from '../../forms/ZenAddAgentCreditFormSidebarModal';
import {
  MoneyValueCurrencyEnum,
  TaxDocumentResponse,
  TaxDocumentResponseTaxDocumentStatusEnum,
  TaxDocumentResponseTaxDocumentTypeEnum,
} from '../../openapi/arrakis';
import {
  getYearlyTaxDocumentDetailsV2,
  regenerateTaxDocument,
  setTaxAlertDataV2,
} from '../../slices/TaxSlice';
import { AppDispatch, RootState } from '../../types';
import { getTaxType } from '../../utils/AgentHelper';
import { displayAmount } from '../../utils/CurrencyUtils';
import { isTaxDocumentNew } from '../../utils/TaxDocumentHelper';
import IconButton from '../IconButton';
import ResourceContainer from '../ResourceContainer';
import ZenSidebarModalActionFooter from '../SidebarModal/ZenSideBarModalActionFooter';
import ZenSimpleConfirmationModal from '../Zen/Modal/ZenSimpleConfirmationModal';
import ZenCardWithItems from '../Zen/ZenCardWithItems';
import ZenOutlineActionButton from '../Zen/ZenOutlineButton';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import AuthorizationContainer from '../auth/AuthorizationContainer';
import { capitalizeEnum } from '../../utils/StringUtils';
import TaxFormAlert from './TaxFormAlert';
import ZenTaxReportIssue from './ZenTaxReportIssue';

interface ZenTaxForm1099Props {
  isOpen: boolean;
  onClose(): void;
  year?: number;
  taxDocument: TaxDocumentResponse;
}

const ZenTaxForm1099: React.FC<ZenTaxForm1099Props> = ({
  isOpen,
  onClose,
  year = undefined,
  taxDocument,
}) => {
  const {
    agentDetail: {
      detailResponse: { data: detail },
    },
    auth: { isAdmin },
    taxDoc: { taxAlertV2, taxDocumentsDetailResponseV2 },
  } = useSelector((state: RootState) => state);
  const [openAddAgentForm, setAddAgentForm] = useState<boolean>(false);
  const [openIssueForm, setIssueForm] = useState<boolean>(false);
  const [isRecalculateModalOpen, setIsRecalculateModalOpen] = useState<boolean>(
    false,
  );
  const [isLoading, setLoading] = useState<boolean>(false);

  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    if (year) {
      dispatch(getYearlyTaxDocumentDetailsV2(detail?.id!, year));
    }
    dispatch(setTaxAlertDataV2(null));
  }, [dispatch, detail?.id, year]);
  const taxType = getTaxType(taxDocument?.taxDocumentType!);

  const handleRegenerateTaxDocument = async () => {
    setLoading(true);
    await dispatch(regenerateTaxDocument(detail?.id!, taxDocument?.year!));
    setLoading(false);
    setIsRecalculateModalOpen(false);
  };

  return (
    <ZenSidebarModal
      title={`Review ${taxType || ''} Form`}
      isOpen={isOpen}
      onClose={onClose}
      subtitle={`Review your ${taxType || ''} before the tax filing deadline.`}
    >
      <form
        className='p-4 flex flex-col min-h-full justify-between'
        onSubmit={() =>
          window.open(`/pdf/tax-documents/${taxDocument?.id}`, '_blank')
        }
      >
        <div className='space-y-4'>
          {taxAlertV2?.length && (
            <TaxFormAlert {...taxAlertV2[taxDocument?.id!]!} />
          )}

          {isTaxDocumentNew(taxDocument) && (
            <TaxFormAlert
              taxFormState='WARNING'
              title={`NEW ${taxType} FOR TAX YEAR ${taxDocument?.year}`}
              description={`A new ${taxType} has been generated for your review. Report any issues before the tax filing deadline.`}
            />
          )}

          {isAdmin && (
            <div className='flex flex-row items-center justify-start space-x-2 border-b pb-3'>
              <IconButton
                label='Add agent credits'
                variant='outline'
                leftIcon={<AddCredit />}
                onClick={() => setAddAgentForm(true)}
              />
              <IconButton
                label={`Recalculate ${taxType || ''}`}
                variant='outline'
                leftIcon={<Regenerate />}
                onClick={() => setIsRecalculateModalOpen(true)}
                disabled={taxDocumentsDetailResponseV2?.loading}
              />
            </div>
          )}
          <AuthorizationContainer asyncResponse={taxDocumentsDetailResponseV2}>
            <ResourceContainer
              isEmpty={!taxDocument}
              loading={taxDocumentsDetailResponseV2?.loading}
              errorCode={taxDocumentsDetailResponseV2?.error}
              resourceName='tax document'
            >
              <div className='space-y-4'>
                <div className='flex flex-col items-start'>
                  <p className='text-xl font-primary-medium'>
                    {taxType} Form - Tax Year {taxDocument?.year}{' '}
                    {taxDocument.stateOrProvince &&
                      `- ${capitalizeEnum(taxDocument.stateOrProvince)}`}
                  </p>
                  <p className='font-primary-light'>
                    Generated on{' '}
                    {DateTime.fromMillis(taxDocument?.createdAt! || 0).toFormat(
                      'LL/dd/yy',
                    )}
                  </p>
                </div>
                <ZenCardWithItems
                  variant='regular'
                  items={[
                    {
                      name: 'Total Net Commission',
                      value: displayAmount(taxDocument?.netCommissionPaid),
                      rightActionComponent: (
                        <ZenOutlineActionButton
                          direction='rl'
                          text='View'
                          icon={<OpenInNew fontSize='small' />}
                          onClick={() =>
                            window.open(
                              `/people/${
                                detail?.id
                              }/tax-documents/${taxDocument?.year!}/net-commissions`,
                              '_blank',
                            )
                          }
                          type='button'
                        />
                      ),
                    },
                    {
                      name: 'Total Rev. Share Paid',
                      value: displayAmount(taxDocument?.revsharePaid),
                      rightActionComponent: (
                        <ZenOutlineActionButton
                          direction='rl'
                          text='View'
                          icon={<OpenInNew fontSize='small' />}
                          onClick={() =>
                            window.open(
                              `/people/${
                                detail?.id
                              }/tax-documents/${taxDocument?.year!}/rev-share`,
                              '_blank',
                            )
                          }
                          type='button'
                        />
                      ),
                    },
                    {
                      name: `Total Equity Contribution (${detail?.defaultCurrency})`,
                      value: displayAmount(taxDocument?.equityContributed),
                      rightActionComponent: (
                        <ZenOutlineActionButton
                          direction='rl'
                          text='View'
                          icon={<OpenInNew fontSize='small' />}
                          onClick={() =>
                            window.open(
                              `/people/${
                                detail?.id
                              }/tax-documents/${taxDocument?.year!}/equity`,
                              '_blank',
                            )
                          }
                          type='button'
                        />
                      ),
                    },
                    {
                      name: `Total Equity Match (${detail?.defaultCurrency})`,
                      value: displayAmount(
                        taxDocument?.releasedEquityMatch ?? {
                          amount: 0,
                          currency:
                            detail?.defaultCurrency === 'CAD'
                              ? MoneyValueCurrencyEnum.Cad
                              : MoneyValueCurrencyEnum.Usd,
                        },
                      ),
                      rightActionComponent: (
                        <ZenOutlineActionButton
                          direction='rl'
                          text='View'
                          icon={<OpenInNew fontSize='small' />}
                          onClick={() =>
                            window.open(
                              `/people/${
                                detail?.id
                              }/tax-documents/${taxDocument?.year!}/equity-match`,
                              '_blank',
                            )
                          }
                          type='button'
                        />
                      ),
                    },
                    {
                      name: `Total Equity Earned by Awards (${detail?.defaultCurrency})`,
                      value: displayAmount(
                        taxDocument?.releasedAwardsEquity ?? {
                          amount: 0,
                          currency:
                            detail?.defaultCurrency === 'CAD'
                              ? MoneyValueCurrencyEnum.Cad
                              : MoneyValueCurrencyEnum.Usd,
                        },
                      ),
                      rightActionComponent: (
                        <ZenOutlineActionButton
                          direction='rl'
                          text='View'
                          icon={<OpenInNew fontSize='small' />}
                          onClick={() =>
                            window.open(
                              `/people/${
                                detail?.id
                              }/tax-documents/${taxDocument?.year!}/equity-awards`,
                              '_blank',
                            )
                          }
                          type='button'
                        />
                      ),
                    },
                  ]}
                />

                {!isAdmin && (
                  <div>
                    <ZenOutlineActionButton
                      text={`Report a ${taxType} issue`}
                      icon={<Chat fontSize='small' className='mr-1 -ml-2' />}
                      onClick={() => setIssueForm(true)}
                      type='button'
                    />
                    {openIssueForm && (
                      <ZenTaxReportIssue
                        isOpen={openIssueForm}
                        onClose={() => setIssueForm(false)}
                        taxDocumentDetails={taxDocument!}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className='bottom-20 absolute'>
                {taxDocument?.taxDocumentStatus ===
                TaxDocumentResponseTaxDocumentStatusEnum.Sent ? (
                  taxDocument?.taxDocumentType ===
                  TaxDocumentResponseTaxDocumentTypeEnum.Ten99 ? (
                    <p className='text-gray-500 text-xs'>
                      All 1099 forms have been processed and submitted to the
                      IRS. If you need to dispute and have an IRS extension,
                      please contact support for help.
                    </p>
                  ) : (
                    <p className='text-gray-500 text-xs'>
                      All T4A forms have been processed and submitted to the
                      CRA. If you need to dispute and have a CRA extension,
                      please contact support for help.
                    </p>
                  )
                ) : (
                  ''
                )}
              </div>
            </ResourceContainer>
          </AuthorizationContainer>
        </div>
        <ZenSidebarModalActionFooter
          onClose={onClose}
          submitButtonText='View PDF'
        />
      </form>

      <ZenSimpleConfirmationModal
        title='Are you sure you want to recalculate the tax document?'
        subtitle='A new amended tax document will be made available with the latest credits and information.'
        variant='warning'
        isOpen={isRecalculateModalOpen}
        onClose={() => setIsRecalculateModalOpen(false)}
        onConfirm={() => handleRegenerateTaxDocument()}
        isSubmitting={isLoading}
      />

      {openAddAgentForm && (
        <ZenAddAgentCreditFormSidebarModal
          isOpen={openAddAgentForm}
          onClose={() => setAddAgentForm(false)}
          agent={detail!}
          showOnlyTaxTypes
        />
      )}
    </ZenSidebarModal>
  );
};

export default ZenTaxForm1099;
