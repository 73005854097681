import React from 'react';
import { useSelector } from 'react-redux';
import ZenActivityTable from '../components/transactions/Activity/ZenActivityTable';
import ZenRoute from '../components/Zen/ZenRoute';
import { DEFAULT_PAGE_SIZE } from '../constants/TableConstants';
import {
  ActivityControllerApi,
  PagedActivityResponse,
} from '../openapi/memento';
import ErrorService from '../services/ErrorService';
import { RootState } from '../types';
import { getFullName } from '../utils/AgentHelper';
import { getBorrower } from '../utils/MortgageUtils';
import { getMementoConfiguration } from '../utils/OpenapiConfigurationUtils';

interface MortgageActivityRouteProps {
  borrowerId: string;
}

const MortgageActivityRoute: React.FC<MortgageActivityRouteProps> = ({
  borrowerId,
}) => {
  const {
    loanResponse: { data: loan },
  } = useSelector((state: RootState) => state.mortgage);
  const borrower = getBorrower(borrowerId, loan?.borrowers || []);

  const fetchActivities = async (
    pageNumber: number,
  ): Promise<PagedActivityResponse> => {
    const { data } = await new ActivityControllerApi(
      getMementoConfiguration(),
    ).getActivities(borrowerId, pageNumber, DEFAULT_PAGE_SIZE);

    return data;
  };

  return (
    <ZenRoute title={`Mortgage Activity - ${getFullName(borrower)}`}>
      <ZenActivityTable
        fetchActivities={fetchActivities}
        onError={(e) => {
          ErrorService.notify('Unable to fetch mortgage activity', e, {
            mortgage: {
              id: borrowerId,
            },
          });
        }}
        getPublicUserInfo
      />
    </ZenRoute>
  );
};

export default MortgageActivityRoute;
