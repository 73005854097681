import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import {
  BusinessEntityValue,
  NationalIdentificationValue,
  NationalIdentificationValueTypeEnum,
} from '../../openapi/yenta';
import {
  clearBusinessEntity,
  updateBusinessEntity,
} from '../../slices/AgentSlice';
import { RootState } from '../../types';
import { getIdValidations, isCanadianUser } from '../../utils/AgentHelper';
import { getTaxId } from '../../utils/TaxIDsUtils';
import ZenSidebarModalActionFooter from '../SidebarModal/ZenSideBarModalActionFooter';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenSimpleConfirmationModal from '../Zen/Modal/ZenSimpleConfirmationModal';
import ZenSidebarModal from '../Zen/ZenSidebarModal';

interface ZenBusinessEntityFormProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  name: string;
  ein?: string;
  bn?: string;
  gstId?: string;
  pstId?: string;
  hstId?: string;
  qstId?: string;
}

const ZenBusinessEntityForm: React.FC<ZenBusinessEntityFormProps> = ({
  isOpen,
  onClose,
}) => {
  const agentDetailResponse = useSelector(
    (state: RootState) => state.agentDetail?.detailResponse?.data,
  );
  const dispatch = useDispatch();
  const canadianUser = isCanadianUser(agentDetailResponse?.accountCountry!);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      name: agentDetailResponse?.paidViaBusinessEntity?.name,
      ein: getTaxId(
        NationalIdentificationValueTypeEnum.Ein,
        agentDetailResponse?.paidViaBusinessEntity?.nationalIds,
      ),
      bn: getTaxId(
        NationalIdentificationValueTypeEnum.Bn,
        agentDetailResponse?.paidViaBusinessEntity?.nationalIds,
      ),
      gstId: getTaxId(
        NationalIdentificationValueTypeEnum.GstId,
        agentDetailResponse?.paidViaBusinessEntity?.nationalIds,
      ),
      pstId: getTaxId(
        NationalIdentificationValueTypeEnum.PstId,
        agentDetailResponse?.paidViaBusinessEntity?.nationalIds,
      ),
      hstId: getTaxId(
        NationalIdentificationValueTypeEnum.HstId,
        agentDetailResponse?.paidViaBusinessEntity?.nationalIds,
      ),
      qstId: getTaxId(
        NationalIdentificationValueTypeEnum.QstId,
        agentDetailResponse?.paidViaBusinessEntity?.nationalIds,
      ),
    },
  });

  const onSubmit = async (formData: FormData) => {
    const nationalIds: Array<NationalIdentificationValue> = canadianUser
      ? [
          {
            id: formData.bn!,
            type: NationalIdentificationValueTypeEnum.Bn,
          },
          {
            id: formData.gstId!,
            type: NationalIdentificationValueTypeEnum.GstId,
          },
          {
            id: formData.pstId!,
            type: NationalIdentificationValueTypeEnum.PstId,
          },
          {
            id: formData.hstId!,
            type: NationalIdentificationValueTypeEnum.HstId,
          },
          {
            id: formData.qstId!,
            type: NationalIdentificationValueTypeEnum.QstId,
          },
        ].filter((item) => !!item.id)
      : [
          {
            id: formData.ein!,
            type: NationalIdentificationValueTypeEnum.Ein,
          },
        ];

    const finalData: BusinessEntityValue = {
      name: formData.name,
      nationalIds,
    };

    await dispatch(updateBusinessEntity(agentDetailResponse?.id!, finalData));
    onClose();
  };

  return (
    <ZenSidebarModal
      title={
        canadianUser
          ? 'Business Entity and Tax IDs'
          : 'Business Name and Tax ID'
      }
      subtitle={
        canadianUser
          ? 'Business entity and IDs for tax reporting purposes'
          : 'Business name and ID for tax reporting purposes'
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='m-4'>
        <form
          className='flex flex-col min-h-full'
          onSubmit={handleSubmit(onSubmit)}
          title='business-entity-form'
        >
          <div className='pb-6'>
            <ZenControlledTextInput<FormData, 'name'>
              control={control}
              name='name'
              label={canadianUser ? 'Business Entity name' : 'Business Name'}
              placeholder={
                canadianUser ? 'Ex. Dream Homes LTD.' : 'Ex. Dream Homes LLC.'
              }
              rules={{
                required: canadianUser
                  ? 'Business Entity Name is required.'
                  : 'Business Name is required.',
              }}
              shouldUnregister={false}
              isRequired
            />
            {!canadianUser ? (
              <div>
                <div className='mt-4'>
                  <ZenControlledTextInput<FormData, 'ein'>
                    control={control}
                    name='ein'
                    label='Employer identification number (EIN)'
                    placeholder='Ex. 12-1234567'
                    rules={{
                      required: 'EIN is required',
                      ...getIdValidations(
                        NationalIdentificationValueTypeEnum.Ein,
                      ),
                    }}
                    isRequired
                    // maskType='99-9999999'
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className='mt-4'>
                  <ZenControlledTextInput<FormData, 'bn'>
                    control={control}
                    name='bn'
                    label='Business Number (BN)'
                    placeholder='Ex. 123456789'
                    // maskType='999999999'
                    rules={{
                      ...getIdValidations(
                        NationalIdentificationValueTypeEnum.Bn,
                      ),
                    }}
                    isRequired
                  />
                </div>
                <div className='mt-4'>
                  <ZenControlledTextInput<FormData, 'gstId'>
                    control={control}
                    name='gstId'
                    label='Goods and services tax (GST)'
                    placeholder='Ex. 123456789 RT 0001'
                    rules={{
                      required: 'GST Number is required',
                      ...getIdValidations(
                        NationalIdentificationValueTypeEnum.GstId,
                      ),
                    }}
                    isRequired
                    // maskType='999999999 RT 9999'
                  />
                </div>
                <div className='mt-4'>
                  <ZenControlledTextInput<FormData, 'hstId'>
                    control={control}
                    name='hstId'
                    label='Harmonized sales tax (HST)'
                    placeholder='Ex. 123456789 RT 0002'
                    // maskType='999999999 RT 9999'
                    rules={{
                      ...getIdValidations(
                        NationalIdentificationValueTypeEnum.HstId,
                      ),
                    }}
                    isRequired
                  />
                </div>
                <div className='mt-4'>
                  <ZenControlledTextInput<FormData, 'pstId'>
                    control={control}
                    name='pstId'
                    label='Provincial sales tax (PST)'
                    placeholder='Ex. PST-1234-1234'
                    // maskType='PST-9999-9999'
                    rules={{
                      ...getIdValidations(
                        NationalIdentificationValueTypeEnum.PstId,
                      ),
                    }}
                    isRequired
                  />
                </div>
                <div className='mt-4'>
                  <ZenControlledTextInput<FormData, 'qstId'>
                    control={control}
                    name='qstId'
                    label='Quebec sales tax (QST)'
                    placeholder='Ex. 1234567890 TQ 1234'
                    // maskType='9999999999 TQ 9999'
                    rules={{
                      ...getIdValidations(
                        NationalIdentificationValueTypeEnum.QstId,
                      ),
                    }}
                    isRequired
                  />
                </div>
              </div>
            )}
          </div>
          <div className='pb-20'>
            {agentDetailResponse?.paidViaBusinessEntity?.name && (
              <div className='absolute bottom-24'>
                <button
                  onClick={() => setConfirmationModal(true)}
                  type='button'
                  className='appearance-none flex flex-row p-2 space-x-1 text-red-500 rounded cursor-pointer font-primary-medium focus:outline-none hover:bg-red-500 hover:bg-opacity-10'
                >
                  <FontAwesomeIcon icon={faTrash} size='sm' />
                  <div className='inline'>Delete Business Information</div>
                </button>
              </div>
            )}
          </div>

          <ZenSimpleConfirmationModal
            title='Remove Business Entity?'
            subtitle='Are you sure you’d like to remove your business entity? This may have unintended side effects. You can always add it back later.'
            variant='error'
            isOpen={confirmationModal}
            onClose={() => setConfirmationModal(false)}
            onConfirm={() => {
              dispatch(clearBusinessEntity(agentDetailResponse?.id!));
              setConfirmationModal(false);
              onClose();
            }}
            isSubmitting={false}
          />
          <ZenSidebarModalActionFooter
            onClose={onClose}
            isSubmitting={isSubmitting}
          />
        </form>
      </div>
    </ZenSidebarModal>
  );
};

export default ZenBusinessEntityForm;
