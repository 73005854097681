import { cn } from '../utils/classUtils';
import { EnumMap } from '../types';

export type IconCircleVariant =
  | 'success'
  | 'primary'
  | 'warning'
  | 'error'
  | 'default'
  | 'gray';

export type IconCircleSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface IconCircleProps {
  variant: IconCircleVariant;
  icon: React.ReactElement;
  size?: IconCircleSize;
}

const IconCircle: React.FC<IconCircleProps> = ({
  variant,
  icon,
  size = 'md',
}) => {
  const iconMapBgColor: EnumMap<IconCircleVariant, string> = {
    default: 'bg-white',
    primary: 'bg-primary bg-opacity-30',
    error: 'bg-error bg-opacity-30',
    warning: 'bg-warning bg-opacity-30',
    success: 'bg-success bg-opacity-30',
    gray: 'bg-zen-dark-8 bg-opacity-10 hover:bg-opacity-30',
  };

  const iconMapSize: EnumMap<IconCircleSize, string> = {
    xs: 'h-8 w-8',
    sm: 'h-12 w-12',
    md: 'h-16 w-16',
    lg: 'h-20 w-20',
    xl: 'h-24 w-24',
  };

  return (
    <div
      className={cn(
        `${iconMapSize[size]} rounded-full flex flex-row items-center justify-center`,
        iconMapBgColor[variant],
      )}
    >
      {icon}
    </div>
  );
};

export default IconCircle;
