import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';
import ZenShareWorksReleasesSidebarModal from '../../../forms/ZenShareWorksReleasesSidebarModal';

interface ZenShareWorksReleasesProps {}

const ZenShareWorksReleases: React.FC<ZenShareWorksReleasesProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <ZenResourceLinkCard
        title='Upload Shareworks Releases'
        onClick={() => setIsOpen(true)}
        icon={light('cloud-arrow-up')}
      />

      {!!isOpen && (
        <ZenShareWorksReleasesSidebarModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default ZenShareWorksReleases;
