import ZenControlledRadioInput from '../Zen/Input/ZenControlledRadioInput';
import {
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps,
} from '../../routes/MSDynamicsOnboardingRoute';
import { CreateTaxFormTypeEnum } from '../../openapi/yenta';
import ZenButton from '../Zen/ZenButton';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import withMSDynamicsProgress from './withMSDynamicsProgress';
import MsDynamicTaxFormOptionComponent from './MSDynamicTaxFormOptionComponent';

const MsDynamicOnboardingTaxInfoUS: StepByStepComponent<
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps
> = ({ form: { control }, onPrevious, onNext }) => {
  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow text-zen-dark-9 space-y-14'>
        <div className=' space-y-2'>
          <h1 className=' font-medium text-[26px] font-zen-title'>
            Next, add tax information
          </h1>
          <div className='font-zen-body space-y-6'>
            <p>
              We require tax information to send you the correct documentation
              for tax season.
            </p>
            <div className=''>
              <p>
                You will need to provide tax info if you meet one or more of
                these requirements:
              </p>
              <div>
                <div className='flex space-x-2 pl-2'>
                  <div className='text-3xl -mt-3.5'>.</div>
                  <p>You have an active US listing</p>
                </div>
                <div className='flex space-x-2 pl-2'>
                  <div className='text-3xl -mt-3.5'>.</div>
                  <p>You have a US payout method on file</p>
                </div>
                <div className='flex space-x-2 pl-2'>
                  <div className='text-3xl -mt-3.5'>.</div>
                  <p>You are a US resident according to our records</p>
                </div>
              </div>
            </div>
            <p>
              The American Rescue Plan Act (ARPA) of 2021 requires us to issue a
              valid tax document for all Agents earning over $600 annually and
              we need your tax info to do this. If we don’t receive it, any
              scheduled payments will be paused.
            </p>
          </div>
        </div>
        <div className='space-y-7 pb-12'>
          <h1 className=' font-medium text-[26px] font-zen-title leading-tight'>
            Select the form that applies to you
          </h1>
          <ZenControlledRadioInput<MSDynamicOnboardingFormData, 'taxFormType'>
            name='taxFormType'
            control={control}
            inlineOptions
            rules={{ required: 'Please select a form type' }}
            shouldUnregister={false}
            containerStyles='flex-col md:flex-row mx-auto space-x-0 md:space-x-2 space-y-3 md:space-y-0'
            options={[
              {
                label: 'W-9',
                subLabel:
                  ' I’m a US citizen or tax resident and receive payouts from listings in or outside the US.',
                value: CreateTaxFormTypeEnum.W9,
              },
              {
                label: 'W-8ECI',
                subLabel:
                  'I’m not a US tax resident, but receive payouts from listings in the US and file a US tax return. (Note: US TIN required)',
                value: CreateTaxFormTypeEnum.W8Eci,
              },
              {
                label: 'W-8BEN',
                subLabel:
                  'I’m not a US tax resident, but receive payouts from listings in the US and file a US tax return. (Note: US TIN required)',
                value: CreateTaxFormTypeEnum.W8Ben,
              },
            ]}
            OptionComponent={MsDynamicTaxFormOptionComponent}
          />
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-sm'>
          <div className='flex flex-row items-center py-8 shadow-top-sm justify-center space-x-5'>
            <div className='w-28'>
              <ZenButton
                isFullWidth
                variant='primary-outline'
                type='button'
                label='Previous'
                onClick={onPrevious}
              />
            </div>
            <div className='w-56'>
              <ZenButton
                isFullWidth
                type='submit'
                variant='primary'
                label='Next'
                onClick={onNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withMSDynamicsProgress(MsDynamicOnboardingTaxInfoUS);
