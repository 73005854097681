import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TierResponse } from '../../openapi/avalon';
import TierProgressBar from './TierProgressBar';

interface TiersListCardProps {
  tiers: TierResponse[];
}

const TiersListCard: React.FC<TiersListCardProps> = ({ tiers }) => {
  return (
    <div className='gap-3 grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))]'>
      {tiers.map(({ name, colorCode, occupancy }) => (
        <div
          key={name}
          className='flex flex-col bg-grey-100 w-full rounded px-5 py-3'
        >
          <div className='flex flex-row justify-between space-x-3 mb-2'>
            <span className='text-base leading-6 font-semibold text-grey-600'>
              {name}
            </span>
            <div className='flex flex-row items-center'>
              <FontAwesomeIcon
                icon={faUser}
                size='lg'
                className='text-grey-600 font-extrabold text-sm mr-1 leading-4'
              />
              <span className='text-base leading-6 font-semibold text-grey-600'>
                {occupancy?.occupied} / {occupancy?.capacity}
              </span>
            </div>
          </div>
          <TierProgressBar
            bookedShares={occupancy?.occupied!}
            totalShares={occupancy?.capacity!}
            utilization={occupancy?.utilization!}
            tierColor={colorCode!}
          />
        </div>
      ))}
    </div>
  );
};

export default TiersListCard;
