import React from 'react';
import {
  InviteResponseStatusEnum,
  MembershipResponseStatusEnum,
} from '../../openapi/avalon';
import InvitationStatusCard from './InvitationStatusCard';

interface ZenAgentJVCardProps {
  jointVentureName: string;
  tierName: string;
  colorCode: string;
  status: InviteResponseStatusEnum;
  membershipStatus: MembershipResponseStatusEnum;
  interests: number;
  inviteId: string;
  onClick?(): void;
}

const ZenAgentQJCard: React.FC<ZenAgentJVCardProps> = ({
  colorCode,
  interests,
  jointVentureName,
  status,
  tierName,
  membershipStatus,
  onClick,
}) => {
  const displayPillBasedOnStatus = () => {
    return (
      <InvitationStatusCard
        status={
          status === InviteResponseStatusEnum.Accepted
            ? membershipStatus
            : status
        }
        jointVentureTitle=''
        onClick={onClick}
      />
    );
  };

  return (
    <div className='mt-3'>
      <div className='flex justify-between flex-row items-center pr-4 shadow-md rounded-md'>
        <div className='flex items-center'>
          <div
            style={{ backgroundColor: colorCode }}
            className='w-4 h-[76px] rounded-l-md'
          />
          <div className='pr-2 pl-1'>
            <div className='flex flex-col px-2'>
              <div className='font-zen-body font-bold text-sm leading-5 text-zen-dark-7'>
                {tierName} Tier {interests ? `(${interests})` : null}
              </div>
              <div className='font-zen-body font-normal text-sm leading-5 text-zen-dark-5'>
                {jointVentureName}
              </div>
            </div>
          </div>
        </div>
        <div className='font-zen-body font-normal justify-between  text-sm leading-5'>
          {displayPillBasedOnStatus()}
        </div>
      </div>
    </div>
  );
};

export default ZenAgentQJCard;
