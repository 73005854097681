import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as ZenMenu } from '../assets/img/Menu.svg';
import ZenNavRealLogo from '../assets/img/new-rezen-white-logo.svg';
import { useGemini } from '../hooks/useGemini';
import { RootState } from '../types';
import { cn } from '../utils/classUtils';
import AgentRole from './Agent/AgentRole';
import Hover from './Hover';
import ZenLoanOfficerMenu from './Menu/BrokerQueue/ZenLoanOfficerMenu';
import ZenAdminMenu from './Menu/ZenMenu/ZenAdminMenu';
import ZenBrokerMenu from './Menu/ZenMenu/ZenBrokerMenu';
import ZenRealTitleOfficerMenu from './Menu/ZenMenu/ZenRealTitleOfficerMenu';
import ZenSuperAdminMenu from './Menu/ZenMenu/ZenSuperAdminMenu';
import ZenUserMenu from './Menu/ZenMenu/ZenUserMenu';
import ZenProfileImageCell from './Zen/Table/Cell/ZenProfileImageCell';
import ZenAvatar from './Zen/ZenAvatar';
import ZenLaunchNotes from './ZenLaunchNotes';
import { ZenMainMenuDemoModeBadge } from './ZenMainMenuDemoModeBadge';
import ZenSideBarItem from './ZenSideBarItem';
import ZenSideBarUserControl from './ZenSideBarUserControl';

interface SideBarProps {
  isCollapsed: boolean;
  toggleCollapse(str: boolean): void;
}

const ZenMainMenu: React.FC<SideBarProps> = ({
  isCollapsed,
  toggleCollapse,
}) => {
  const toggleMenu = () => toggleCollapse(!isCollapsed);
  const { auth } = useSelector((state: RootState) => state);
  const {
    userDetail,
    isAdmin,
    isBroker,
    isSuperAdmin,
    isRealTitleOfficer,
    isAnnouncer,
    isMortgageUserRole,
  } = auth;

  const isGeminiEnabled = useGemini();

  return (
    <div aria-label='mainMenu'>
      <div
        className={cn(
          'flex flex-row items-center lg:hidden py-3 px-4',
          isGeminiEnabled ? 'bg-primary-navy' : 'bg-zen-dark-21',
        )}
      >
        <ZenMenu className='h-6 w-6 cursor-pointer' onClick={toggleMenu} />
        <Link to='/'>
          <img src={ZenNavRealLogo} alt='real-logo' className='ml-4 h-6' />
        </Link>
      </div>
      <div
        className={cn('fixed left-0 top-0 bottom-0 right-0 lg:static z-50', {
          'hidden lg:block': isCollapsed,
        })}
      >
        <div
          className='absolute left-0 right-0 top-0 bottom-0 bg-primary-dark/30 lg:hidden'
          role='button'
          onClick={toggleMenu}
        />
        <div
          className={cn(
            'h-full-window flex flex-col justify-between scrollbar overflow-y-auto',
            isGeminiEnabled ? 'bg-primary-navy' : 'bg-zen-dark-21',
            isCollapsed
              ? 'w-20'
              : 'relative w-[196px] animate-slide-in-left lg:animate-none overflow-x-hidden',
          )}
        >
          <div className='px-2 py-3 flex flex-col justify-between flex-grow'>
            <div>
              <div
                className={cn(
                  'flex flex-row items-center text-center pt-3 pb-8',
                  isCollapsed ? 'justify-center' : 'justify-between pl-3',
                )}
              >
                {!isCollapsed && (
                  <div className='flex flex-row items-center space-x-1'>
                    <Link to='/'>
                      <img
                        src={ZenNavRealLogo}
                        alt='real-logo'
                        className='h-7'
                      />
                    </Link>
                  </div>
                )}
                <span
                  className={cn(
                    'material-icons text-primary-light cursor-pointer hidden lg:block',
                    {
                      'pr-2': !isCollapsed,
                    },
                  )}
                  onClick={toggleMenu}
                >
                  <FontAwesomeIcon
                    icon={isCollapsed ? solid('blinds') : regular('blinds')}
                    className='text-primary-light text-xl mt-1.5'
                    title='menu'
                  />
                </span>
              </div>
              {isCollapsed && (
                <Link to='/'>
                  <img
                    src={ZenNavRealLogo}
                    alt='real-logo'
                    className='h-4 ml-2 -mt-3 mb-4'
                  />
                </Link>
              )}
              {!isSuperAdmin && !isMortgageUserRole && !isRealTitleOfficer && (
                <div className={cn('p-4')}>
                  <ZenLaunchNotes isCollapsed={isCollapsed} />
                </div>
              )}
              <ZenMainMenuDemoModeBadge isCollapsed={isCollapsed} />
              {isSuperAdmin ? (
                <ZenSuperAdminMenu isCollapsed={isCollapsed} />
              ) : isAdmin ? (
                <ZenAdminMenu isCollapsed={isCollapsed} />
              ) : isBroker ? (
                <ZenBrokerMenu isCollapsed={isCollapsed} />
              ) : isMortgageUserRole ? (
                <ZenLoanOfficerMenu isCollapsed={isCollapsed} />
              ) : isRealTitleOfficer ? (
                <ZenRealTitleOfficerMenu isCollapsed={isCollapsed} />
              ) : (
                <ZenUserMenu isCollapsed={isCollapsed} />
              )}

              {isAnnouncer && (
                <ZenSideBarItem
                  icon={<FontAwesomeIcon icon={regular('bullhorn')} />}
                  label='Announcement'
                  linkTo='/announcement'
                  collapsed={isCollapsed}
                />
              )}
            </div>
          </div>
          {!isCollapsed ? (
            <div
              className={cn(
                'flex items-center justify-between bottom-0 px-2 py-2 sticky border-t border-zen-gray-6 w-full',
                isGeminiEnabled ? 'bg-primary-navy' : 'bg-zen-dark-21',
              )}
            >
              <div className='flex flex-row items-center'>
                <div>
                  <Link
                    to={
                      isMortgageUserRole
                        ? '/'
                        : `/people/${userDetail?.id}/profile`
                    }
                    title='profile-avatar'
                  >
                    <ZenProfileImageCell
                      name={userDetail?.fullName!}
                      imageUrl={userDetail?.avatar}
                    />
                  </Link>
                </div>

                <div
                  className={cn(
                    'flex flex-col pt-1.5 pl-1.5 text-base font-normal text-primary-light',
                    isGeminiEnabled
                      ? 'font-zen-body-2'
                      : 'font-primary-regular',
                  )}
                >
                  <Hover
                    hoverComponent={
                      <div className='font-semibold text-sm text-zen-dark-12 px-1'>
                        {userDetail?.fullName}
                      </div>
                    }
                    config={{ trigger: 'hover', placement: 'top' }}
                  >
                    <p className='-mb-2 text-sm overflow-hidden text-ellipsis whitespace-nowrap max-w-[120px]'>
                      {userDetail?.fullName}
                    </p>
                  </Hover>

                  <AgentRole />
                </div>
              </div>

              <ZenSideBarUserControl userDetail={userDetail!} />
            </div>
          ) : (
            <div
              className={cn(
                'flex items-center justify-center bottom-0 px-2 py-2 sticky border-t border-zen-gray-6 w-full',
                isGeminiEnabled ? 'bg-primary-navy' : 'bg-zen-dark-21',
              )}
            >
              <Link
                to={
                  isMortgageUserRole ? '/' : `/people/${userDetail?.id}/profile`
                }
                title='profile-avatar'
              >
                <ZenAvatar
                  imageUrl={userDetail?.avatar}
                  name={userDetail?.fullName!}
                  size='md'
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ZenMainMenu;
