import qs from 'qs';
import { useEffect, useRef } from 'react';
import { UseFormSetValue } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LeoTableHeaderFormData } from '../../../routes/LeoQuestionManagerRoute';
import {
  saveAssignedQueueDateFilterRange,
  saveAssignedQueueDateFilterType,
  saveCompletedQueueDateFilterRange,
  saveCompletedQueueDateFilterType,
  saveWorkQueueDateFilterRange,
  saveWorkQueueDateFilterType,
} from '../../../slices/LeoSlice';
import { AppDispatch, RootState } from '../../../types';
import { DateFilterEnum, DateRange } from '../../Zen/Calendars/LQMDateFilter';

interface UseQueryParamFilteringParams {
  assignedQueueAssigneeId: any;
  completedQueueAssigneeId: any;
  assignedDateFilterType: DateFilterEnum;
  assignedDateRange: DateRange;
  completedDateFilterType: DateFilterEnum;
  completedDateRange: DateRange;
  workQueueDateFilterType: DateFilterEnum;
  workQueueDateRange: DateRange;
  workQueueSearch: string;
  assignedSearch: string;
  completedSearch: string;
  setValue: UseFormSetValue<LeoTableHeaderFormData>;
  workQueueCountryFilter: string;
  workQueueStatesFilter: string[];
  assignedQueueCountryFilter: string;
  assignedQueueStatesFilter: string[];
  completedQueueCountryFilter: string;
  completedQueueStatesFilter: string[];
}

const useQueryParamFiltering = ({
  assignedDateFilterType,
  completedDateFilterType,
  completedDateRange,
  completedQueueAssigneeId,
  workQueueDateFilterType,
  workQueueDateRange,
  setValue,
  assignedDateRange,
  assignedQueueAssigneeId,
  workQueueSearch,
  assignedSearch,
  completedSearch,
  workQueueCountryFilter,
  workQueueStatesFilter,
  assignedQueueCountryFilter,
  assignedQueueStatesFilter,
  completedQueueCountryFilter,
  completedQueueStatesFilter,
}: UseQueryParamFilteringParams) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const didMountRef = useRef<boolean>(false);
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    const pathName = history.location.pathname;
    const queryParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    if (didMountRef.current) {
      const generateLabelObject = (labelData: any) => {
        return {
          label: labelData.value === userDetail?.id ? 'Me' : labelData.label,
          value: labelData.value,
        };
      };

      const updateHistory = (paramsObject: any) => {
        history.replace(`${pathName}?${qs.stringify(paramsObject)}`);
      };

      const paramsObject: any = { ...queryParams };

      if (pathName === '/leo-question-manager') {
        paramsObject.dateFilterType = workQueueDateFilterType;
        paramsObject.dateRange = workQueueDateRange;
        paramsObject.workQueueSearch = workQueueSearch;
        paramsObject.workQueueCountryFilter = workQueueCountryFilter;
        paramsObject.workQueueStatesFilter = workQueueStatesFilter;
      }

      if (
        assignedQueueAssigneeId &&
        pathName === '/leo-question-manager/assigned'
      ) {
        paramsObject.assignedQueueAssigneeId = generateLabelObject(
          assignedQueueAssigneeId,
        );
        paramsObject.dateFilterType = assignedDateFilterType;
        paramsObject.dateRange = assignedDateRange;
        paramsObject.assignedSearch = assignedSearch;
        paramsObject.assignedQueueCountryFilter = assignedQueueCountryFilter;
        paramsObject.assignedQueueStatesFilter = assignedQueueStatesFilter;
      }

      if (
        completedQueueAssigneeId &&
        pathName === '/leo-question-manager/completed'
      ) {
        paramsObject.completedQueueAssigneeId = generateLabelObject(
          completedQueueAssigneeId,
        );
        paramsObject.dateFilterType = completedDateFilterType;
        paramsObject.dateRange = completedDateRange;
        paramsObject.completedSearch = completedSearch;
        paramsObject.completedQueueCountryFilter = completedQueueCountryFilter;
        paramsObject.completedQueueStatesFilter = completedQueueStatesFilter;
      }

      updateHistory(paramsObject);
    } else {
      if (queryParams.dateFilterType) {
        const dateFilterTypeData = queryParams.dateFilterType as DateFilterEnum;
        if (pathName === '/leo-question-manager') {
          dispatch(saveWorkQueueDateFilterType(dateFilterTypeData));
        } else if (pathName === '/leo-question-manager/assigned') {
          dispatch(saveAssignedQueueDateFilterType(dateFilterTypeData));
        } else if (pathName === '/leo-question-manager/completed') {
          dispatch(saveCompletedQueueDateFilterType(dateFilterTypeData));
        }
      }
      if (queryParams.dateRange) {
        const dateRangeData = queryParams.dateRange as DateRange;
        if (pathName === '/leo-question-manager') {
          if (queryParams.dateFilterType === DateFilterEnum.CUSTOM_RANGE) {
            setValue('workQueueCustomStartDate', dateRangeData.start);
            setValue('workQueueCustomEndDate', dateRangeData.end);
          }
          dispatch(saveWorkQueueDateFilterRange(dateRangeData));
        } else if (pathName === '/leo-question-manager/assigned') {
          if (queryParams.dateFilterType === DateFilterEnum.CUSTOM_RANGE) {
            setValue('assignedCustomStartDate', dateRangeData.start);
            setValue('assignedCustomEndDate', dateRangeData.end);
          }
          dispatch(saveAssignedQueueDateFilterRange(dateRangeData));
        } else if (pathName === '/leo-question-manager/completed') {
          if (queryParams.dateFilterType === DateFilterEnum.CUSTOM_RANGE) {
            setValue('completedCustomStartDate', dateRangeData.start);
            setValue('completedCustomEndDate', dateRangeData.end);
          }
          dispatch(saveCompletedQueueDateFilterRange(dateRangeData));
        }
      }
      if (queryParams.assignedQueueAssigneeId) {
        setValue('assignedQueueAssigneeId', {
          label: (queryParams.assignedQueueAssigneeId as any).label,
          value: (queryParams.assignedQueueAssigneeId as any).value,
        });
      }
      if (queryParams.completedQueueAssigneeId) {
        setValue('completedQueueAssigneeId', {
          label: (queryParams.completedQueueAssigneeId as any).label,
          value: (queryParams.completedQueueAssigneeId as any).value,
        });
      }

      if (queryParams.workQueueSearch) {
        setValue('workQueueSearch', queryParams.workQueueSearch as string);
      }
      if (queryParams.assignedSearch) {
        setValue('assignedSearch', queryParams.assignedSearch as string);
      }
      if (queryParams.completedSearch) {
        setValue('completedSearch', queryParams.completedSearch as string);
      }
      if (queryParams.workQueueCountryFilter) {
        setValue(
          'workQueueCountryFilter',
          queryParams.workQueueCountryFilter as string,
        );
      }
      if (queryParams.workQueueStatesFilter) {
        setValue(
          'workQueueStatesFilter',
          queryParams.workQueueStatesFilter as string[],
        );
      }
      if (queryParams.assignedQueueCountryFilter) {
        setValue(
          'assignedQueueCountryFilter',
          queryParams.assignedQueueCountryFilter as string,
        );
      }
      if (queryParams.assignedQueueStatesFilter) {
        setValue(
          'assignedQueueStatesFilter',
          queryParams.assignedQueueStatesFilter as string[],
        );
      }
      if (queryParams.completedQueueCountryFilter) {
        setValue(
          'completedQueueCountryFilter',
          queryParams.completedQueueCountryFilter as string,
        );
      }
      if (queryParams.completedQueueStatesFilter) {
        setValue(
          'completedQueueStatesFilter',
          queryParams.completedQueueStatesFilter as string[],
        );
      }
      setValue('loading', false);
      didMountRef.current = true;
    }
  }, [
    assignedQueueAssigneeId,
    completedQueueAssigneeId,
    assignedDateFilterType,
    assignedDateRange,
    completedDateFilterType,
    completedDateRange,
    workQueueDateFilterType,
    workQueueDateRange,
    history.location.pathname,
    history,
    userDetail?.id,
    dispatch,
    setValue,
    workQueueSearch,
    assignedSearch,
    completedSearch,
    workQueueCountryFilter,
    workQueueStatesFilter,
    assignedQueueCountryFilter,
    assignedQueueStatesFilter,
    completedQueueCountryFilter,
    completedQueueStatesFilter,
  ]);
};

export default useQueryParamFiltering;
