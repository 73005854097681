import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BorrowerDto } from '../../openapi/atlantis';
import { fetchBorrowerProgress } from '../../slices/MortgageSlice';
import { RootState } from '../../types';
import { hasOnlyButtonFieldsInSection } from '../../utils/mortgage/MortgageApplicationFormUtils';
import AuthorizationContainer from '../auth/AuthorizationContainer';
import ZenResourceContainer from '../Zen/ZenResourceContainer';
import { MortgageSidebarSectionItem } from './MortgageApplicationForms/MortgageSidebarSectionItem';

interface MortgageSidebarSectionProps {
  borrower: BorrowerDto;
}

const MortgageSidebarSection: React.FC<MortgageSidebarSectionProps> = ({
  borrower,
}) => {
  const dispatch = useDispatch();
  const { borrowerProgress, borrowerApplications } = useSelector(
    (state: RootState) => state.mortgage,
  );
  const progress = borrowerProgress[borrower.id] || {};
  const application = borrowerApplications[borrower.id] || {};

  useEffect(() => {
    dispatch(fetchBorrowerProgress(borrower.id));
  }, [borrower.id, dispatch]);

  return (
    <AuthorizationContainer asyncResponse={progress}>
      <ZenResourceContainer
        loading={progress?.loading}
        isEmpty={!progress}
        resourceName='Progress'
      >
        <div className='mb-10'>
          <div className='relative'>
            <div className='absolute left-[11px] w-0.5 h-full bg-white' />
            {(progress?.data?.sectionProgresses || [])?.map((item) => {
              const section = application.data?.sections.find(
                (obj) => obj.name === item.name,
              );

              if (section && hasOnlyButtonFieldsInSection(section)) {
                return null;
              }

              return (
                <MortgageSidebarSectionItem key={item.name} section={item} />
              );
            })}
          </div>
        </div>
      </ZenResourceContainer>
    </AuthorizationContainer>
  );
};

export default MortgageSidebarSection;
