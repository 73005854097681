import { faCheck, faMultiply } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  TeamControllerApi,
  TeamResponse,
  TeamResponseTypeEnum,
} from '../../../../openapi/yenta';
import AnalyticsService from '../../../../services/AnalyticsService';
import ErrorService from '../../../../services/ErrorService';
import { showApiErrorModal } from '../../../../slices/ErrorSlice';
import { showToastByTimer } from '../../../../slices/ToastNotificationSlice';
import { AnalyticsEventEnum, ISelectOption } from '../../../../types';
import { getYentaConfiguration } from '../../../../utils/OpenapiConfigurationUtils';
import { EMAIL_VALIDATIONS } from '../../../../utils/Validations';
import { getDefaultTeamCapForMemberInvite } from '../../../../utils/teams/capUtils';
import AdminOnly from '../../../auth/AdminOnly';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../../Input/ZenControlledToggleInput';
import ZenSidebarModalActionFooterV2 from '../../Modal/ZenSidebarModalActionFooterV2';
import ZenControlledGroupRadioButtonInputV2 from '../../ZenControlledGroupRadioButtonInputV2';

interface ZenInviteAgentViaEmailProps {
  onClose(): void;
  options: ISelectOption[];
  team: TeamResponse;
  refetch(): void;
}

interface InviteMemberFormValues {
  firstName: string;
  lastName: string;
  email: string;
  realCap: string;
  waiveFees: boolean;
}

const ZenInviteAgentViaEmail: FC<ZenInviteAgentViaEmailProps> = ({
  onClose,
  options,
  team,
  refetch,
}) => {
  const isProTeam = team.type === TeamResponseTypeEnum.Pro;

  const { control, handleSubmit, formState, watch } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      realCap: getDefaultTeamCapForMemberInvite(options),
      waiveFees: false,
    },
  });

  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [realCap] = watch(['realCap']);

  useEffect(() => {
    const mapCapToEvent: Record<string, AnalyticsEventEnum> = {
      '4000':
        AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_SELECT_4K_CAP,
      '6000':
        AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_SELECT_6K_CAP,
      '12000':
        AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_SELECT_12K_CAP,
    };

    const event = mapCapToEvent[realCap];

    if (event) {
      AnalyticsService.instance().logEvent(event);
    }
  }, [realCap]);

  const logAnalyticsEvent = debounce((event: string) => {
    AnalyticsService.instance().logEvent(event);
  }, 500);

  const onSubmit = async (values: InviteMemberFormValues) => {
    setIsSubmitting(true);

    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      emailAddress: values.email,
      capLevel: isProTeam
        ? parseInt(values.realCap?.toString(), 10)
        : undefined,
      waiveFees: values.waiveFees,
    };

    try {
      await new TeamControllerApi(getYentaConfiguration()).createTeamInvitation(
        team.id!,
        payload,
      );

      dispatch(showToastByTimer('success', 'Team member invited successfully'));
      logAnalyticsEvent(
        AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_CLICK_INVITE,
      );

      refetch();
      onClose();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to update team', e, {
        team: { payload },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const realCapError = formState.errors.realCap;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      title='add-team-member-form'
      className='p-6 mt-2'
    >
      <div className='flex justify-between gap-5'>
        <ZenControlledTextInput
          label='First Name'
          name='firstName'
          control={control}
          placeholder='Enter First Name'
          rules={{ required: 'First Name is required' }}
          isRequired
          onChangeSpy={() =>
            logAnalyticsEvent(
              AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_INPUT_FIRST_NAME,
            )
          }
        />

        <ZenControlledTextInput
          label='Last Name'
          name='lastName'
          control={control}
          placeholder='Enter Last Name'
          rules={{ required: 'Last Name is required' }}
          isRequired
          onChangeSpy={() =>
            logAnalyticsEvent(
              AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_INPUT_LAST_NAME,
            )
          }
        />
      </div>

      <div className='mt-6'>
        <ZenControlledTextInput
          label='Email Address'
          name='email'
          placeholder='Enter Email Address'
          control={control}
          rules={{
            required: 'Email is required',
            ...EMAIL_VALIDATIONS,
          }}
          isRequired
          onChangeSpy={() =>
            logAnalyticsEvent(
              AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_INPUT_EMAIL,
            )
          }
        />
      </div>

      <div className='mt-6 flex justify-between items-center'>
        <label
          htmlFor='realCap'
          className={`font-zen-body font-semibold ${
            realCapError ? 'text-zen-danger' : 'text-zen-dark-9'
          }`}
        >
          Team Member’s Real Cap <span className='text-zen-danger'>*</span>
        </label>

        <div>
          <ZenControlledGroupRadioButtonInputV2
            control={control}
            options={options!}
            minWidth='w-60'
            name='realCap'
            shouldUnregister={false}
            fullWidth
            rules={{
              required: "Please select Team Member's Real Cap",
            }}
          />
        </div>
      </div>

      <AdminOnly>
        <div className='mt-6 -mx-2'>
          <ZenControlledToggleInput
            onChangeSpy={(waiveFee) => {
              const event = waiveFee
                ? AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_WAIVE_JOIN_FEE_ON
                : AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_WAIVE_JOIN_FEE_OFF;

              AnalyticsService.instance().logEvent(event);
            }}
            name='waiveFees'
            control={control}
            label='Waive Join Fee'
            labelClassName='text-md font-semibold font-zen-body '
            leftIcon={<FontAwesomeIcon icon={faMultiply} size='sm' />}
            rightIcon={<FontAwesomeIcon icon={faCheck} size='sm' />}
          />
        </div>
      </AdminOnly>

      <ZenSidebarModalActionFooterV2
        onClose={() => {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_CLICK_CANCEL,
          );

          onClose();
        }}
        isSubmitting={isSubmitting}
        submitButtonText='Invite'
      />
    </form>
  );
};

export default ZenInviteAgentViaEmail;
