import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faHandshakeSimple,
  faHouse,
  faHouseBuilding,
} from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { TransactionResponseTransactionTypeEnum } from '../../../../openapi/arrakis';
import { PartialEnumMap } from '../../../../types';
import { safePartialEnumMapGet } from '../../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import GeminiPill from '../../GeminiPill';

interface GeminiTransactionDealTypeLabelProps {
  dealType: TransactionResponseTransactionTypeEnum;
}

const GeminiTransactionDealTypeLabel: React.FC<GeminiTransactionDealTypeLabelProps> = ({
  dealType,
}) => {
  const dealTypeIconToEnumMap: PartialEnumMap<
    TransactionResponseTransactionTypeEnum,
    IconProp
  > = {
    SALE: faHouse,
    LEASE: faHouseBuilding,
    REFERRAL: faHandshakeSimple,
    INTERNAL_REFERRAL: faHandshakeSimple,
  };

  const dealTypeContainerStyleToEnumMap: PartialEnumMap<
    TransactionResponseTransactionTypeEnum,
    string
  > = {
    SALE: 'bg-rezen-light-blue-100 text-primary-navy',
    LEASE: 'bg-midnight-green-300 text-midnight-green-900',
    REFERRAL: 'bg-purple-300 text-purple-600',
    INTERNAL_REFERRAL: 'bg-sangria-300 text-sangria-900',
  };

  if (!dealType) {
    return null;
  }

  return (
    <GeminiPill
      label={capitalizeEnum(dealType)}
      icon={safePartialEnumMapGet(dealTypeIconToEnumMap, dealType, faHouse)}
      containerClassNames={safePartialEnumMapGet(
        dealTypeContainerStyleToEnumMap,
        dealType,
        'bg-rezen-light-blue-100 text-primary-navy',
      )}
    />
  );
};

export default GeminiTransactionDealTypeLabel;
