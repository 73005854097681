import { faMessage, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import { isStringEmpty } from '../../../utils/StringUtils';
import ReferralMenu from './ReferralMenu';

interface ReferralContactActionsProps {
  moreActions?: boolean;
  selectedAgent: string;
}

const ReferralContactActions: React.FC<ReferralContactActionsProps> = ({
  moreActions = false,
  selectedAgent,
}) => {
  const {
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  const agent = {
    name:
      agentById[selectedAgent]?.firstName +
      ' ' +
      agentById[selectedAgent]?.lastName,
    phoneNumber: agentById[selectedAgent]?.phoneNumber,
    id: agentById[selectedAgent]?.id,
  };

  const hasSelectedAgent = !isStringEmpty(selectedAgent);
  return (
    <div className='flex flex-row items-center'>
      {hasSelectedAgent && (
        <>
          {!!agent.phoneNumber && (
            <FontAwesomeIcon
              className='text-grey-600 hover:bg-grey-200 p-2 rounded-full'
              icon={faPhone}
            />
          )}
          <FontAwesomeIcon
            className='text-grey-600 hover:bg-grey-200 p-2 rounded-full'
            icon={faMessage}
          />
        </>
      )}
      {moreActions && <ReferralMenu agentSelected={hasSelectedAgent} />}
    </div>
  );
};

export default ReferralContactActions;
