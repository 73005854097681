import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import { cn } from '../../utils/classUtils';
import { useDetermineTabOrder } from '../../hooks/useDetermineTabOrder';
import { ZenMoreTab } from './Tab/ZenMoreTab';
import { ZenMoreDropdown } from './Tab/ZenMoreDropdown';

type Size = 'sm' | 'lg';

export interface ZenTab {
  name: string;
  subtitle?: string | number;
  headerComponent?: React.ReactElement;
  badgeComponent?: React.ReactElement;
  TabComponent: React.FC | React.ReactNode;
}

export interface ZenTabsProps {
  tabs: ZenTab[];
  selected?: string;
  RightComponent?: React.FC | React.ReactNode;
  equalWidths?: boolean;
  onChange(name: string): void;
  sortTabs?: boolean;
  size: Size;
  noBorder?: boolean;
}

const ZenTabs: React.FC<ZenTabsProps> = ({
  tabs,
  selected = tabs[0]?.name,
  RightComponent,
  equalWidths = false,
  onChange,
  sortTabs = false,
  size,
  noBorder = false,
}) => {
  const tabSelected = tabs.find((tab) => tab.name === selected);

  const containerRef = useRef<HTMLDivElement>(null);
  const moreRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef(tabs.map(() => React.createRef<HTMLDivElement>()));
  const [showDropdown, setShowDropdown] = useState(false);

  const activeTabIndex = tabs.findIndex((tab) => tab.name === selected);

  const sortedTabs = useMemo(
    () =>
      !sortTabs ? tabs : tabs.sort((a, b) => a.name.localeCompare(b.name)),
    [sortTabs, tabs],
  );

  const { showMoreTab, tabsWithVisibility } = useDetermineTabOrder(
    sortedTabs,
    containerRef,
    moreRef,
    tabRefs.current,
    activeTabIndex,
  );

  useEffect(() => {
    const scrollWidth = containerRef.current?.scrollWidth ?? 0;
    const offsetWidth = containerRef.current?.offsetWidth ?? 0;
    if (scrollWidth > offsetWidth && equalWidths) {
      console.warn(
        'Do not ignore. Tabs overflowing container. Consider setting equalWidths to false.',
      );
    }
  }, [equalWidths, tabsWithVisibility]);

  useEventListener('click', (e) => {
    if (
      showDropdown &&
      moreRef.current &&
      !moreRef.current.contains(e.target as Node)
    ) {
      setShowDropdown(false);
    }
  });

  const dropdownLabels = tabsWithVisibility
    .filter(({ isVisible }) => !isVisible)
    .map(({ name }) => name);

  const changeTab = (name: string) => {
    onChange(name);
    setShowDropdown(false);
  };

  return (
    <div className='flex flex-col w-full h-full relative'>
      <div className='flex flex-row flex-nowrap w-full'>
        <div
          ref={containerRef}
          className='flex flex-row w-full items-center border-b-2 border-b-regent-300'
          data-testid='zen-tabs'
        >
          {tabsWithVisibility.map(
            (
              { name, subtitle, headerComponent, badgeComponent, isVisible },
              index,
            ) => {
              const activeTab = name === selected;
              const invisible = !isVisible && !equalWidths;
              return (
                <div
                  className={cn('flex flex-row items-center', {
                    'flex-1': equalWidths,
                    'invisible absolute': invisible,
                  })}
                  key={name}
                  ref={tabRefs.current[index]}
                >
                  <div
                    className={cn('flex flex-col relative', {
                      'flex-1': equalWidths,
                    })}
                  >
                    <button
                      className={cn(
                        'focus:outline-none whitespace-pre',
                        'font-inter',
                        activeTab && 'font-medium',
                        activeTab ? 'text-primary-dark' : 'text-grey-500',
                        activeTab ? 'pt-3 pb-2' : 'py-2.5',
                        size === 'sm' && 'text-sm px-3',
                        size === 'lg' && 'text-base min-w-[118px] px-4',
                      )}
                      onClick={(evt) => {
                        evt.preventDefault();
                        changeTab(name);
                      }}
                    >
                      {headerComponent ?? (
                        <p
                          className={cn(
                            'flex flex-col',
                            subtitle
                              ? 'items-start'
                              : 'items-center justify-center min-w-max',
                          )}
                        >
                          <span>{name}</span>
                          {subtitle && (
                            <span className='font-primary-medium text-sm'>
                              {subtitle}
                            </span>
                          )}
                        </p>
                      )}
                    </button>
                    <div
                      className={cn(
                        'absolute bottom-[-2px] h-[2px] inset-x-0 z-[1]',
                        {
                          'bg-primary-blue': activeTab,
                        },
                      )}
                    />
                  </div>
                  {badgeComponent && badgeComponent}
                </div>
              );
            },
          )}
          <ZenMoreTab
            show={showMoreTab && !equalWidths}
            ref={moreRef}
            onClick={() => setShowDropdown(!showDropdown)}
            smallButton
          />
        </div>
        <div>{RightComponent}</div>
        {!noBorder && (
          <div className='absolute bottom-0 border-b-2 border-coolGray-100 w-full h-0' />
        )}
      </div>
      <ZenMoreDropdown
        labels={dropdownLabels}
        show={showDropdown}
        position='absolute'
        size='sm'
        containerRef={containerRef}
        moreRef={moreRef}
        onClick={changeTab}
      />
      {!!tabSelected && (
        <Fragment key={tabSelected.name}>{tabSelected.TabComponent}</Fragment>
      )}
    </div>
  );
};

export default ZenTabs;
