import { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import classNames from 'classnames';
import { FormFieldTooltipIndexProps } from '../../../types';
import ZenFormErrorMessage from './ZenFormErrorMessage';

interface ZenControlledSignatureInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  isRequired?: boolean;
}

const ZenControlledSignatureInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  placeholder,
  readOnly,
  tooltipIndex,
  shouldUnregister = true,
  isRequired,
  ...rest
}: ZenControlledSignatureInputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur },
        fieldState: { error, invalid },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              <span
                className={classNames(
                  'font-zen-body font-semibold text-base',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                )}
              >
                {label}
              </span>
              {isRequired && <span className='text-zen-danger'>*</span>}
            </label>
          )}
          <div
            className={classNames(
              'flex items-stretch border rounded-lg overflow-hidden',
              focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
              value && 'text-zen-dark-9',
              invalid && '!border-zen-danger',
            )}
          >
            <input
              id={name}
              value={value}
              onChange={onChange}
              onFocus={() => setFocus(true)}
              onBlur={() => {
                setFocus(false);
                onBlur();
              }}
              type='text'
              name={name}
              placeholder={placeholder}
              style={{ fontSize: '23px' }}
              readOnly={readOnly}
              className={classNames(
                'appearance-none p-2 w-full border-none focus:outline-none focus:ring-0 font-signature',
                {
                  'bg-gray-50': readOnly,
                },
              )}
              data-tooltip-index={tooltipIndex}
            />
          </div>
          {error && <ZenFormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenControlledSignatureInput;
