import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { BoardResponse, BoardSecretsResponse } from '../../../openapi/yenta';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { capitalizeEnum, formatPhoneNumber } from '../../../utils/StringUtils';
import GeminiAgentContactInfoDetailHeader from '../Agent/GeminiAgentContactInfoDetailHeader';
import GeminiCopyToClipboard from '../GeminiCopytoClipboard';
import GeminiProfileImage from '../GeminiProfileImage';
import GeminiTransactionHeaderRowCell from '../Transaction/Header/GeminiTransactionHeaderRowCell';
import GeminiMlsOrBoardStatusPill from './GeminiMlsOrBoardStatusPill';

interface GeminiBoardHeaderSectionProps {
  board?: BoardResponse;
  secrets?: BoardSecretsResponse;
}

export const Divider = () => (
  <div className='h-4.5 w-[1px] bg-grey-200 mx-1.5 sm:block hidden' />
);

const GeminiBoardHeaderSection: React.FC<GeminiBoardHeaderSectionProps> = ({
  board,
  secrets,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <header className='w-full bg-primary-navy rounded-2xl md:px-8 px-4 py-5 flex md:flex-row flex-col md:space-x-8 font-zen-body-2 text-white'>
      <section className='md:w-[16%] w-full h-full flex flex-col items-center md:mb-0 mb-4'>
        <GeminiProfileImage width={174} imageUrl={board?.logo} />
        <div className='w-full flex justify-center mt-6'>
          <GeminiMlsOrBoardStatusPill
            status={board?.status}
            className='w-full'
          />
        </div>
      </section>
      <section className='md:w-[50%] w-full h-full space-y-3 md:mb-0 mb-4'>
        <article className='md:px-5 px-2 md:py-4 py-2 rounded-lg bg-regent-900 font-zen-body-2 text-white flex flex-col space-y-2.5'>
          <h2 className='font-semibold text-[20px] leading-[26px]'>
            {board?.name}
          </h2>
          <GeminiTransactionHeaderRowCell
            label='Code:'
            value={board?.code}
            valueClassName='font-light'
          />
          <div className='flex flex-row flex-wrap md:items-center md:space-x-2.5'>
            <GeminiTransactionHeaderRowCell
              label='Office Code:'
              value={board?.officeCode ?? 'N/A'}
              valueClassName='font-light'
            />
            <span className='h-1 w-1 bg-grey-400 rounded-full' />
            <GeminiTransactionHeaderRowCell
              label='Broker Affiliation:'
              value={
                !!board?.brokerAffiliation
                  ? capitalizeEnum(board?.brokerAffiliation)
                  : 'N/A'
              }
              valueClassName='font-light'
            />
          </div>
          <div className='flex flex-row flex-wrap md:items-center md:space-x-2.5'>
            <GeminiTransactionHeaderRowCell
              label='Registration Fee:'
              value={
                !!board?.registrationFee
                  ? displayAmount(board?.registrationFee)
                  : 'N/A'
              }
              valueClassName='font-light'
            />
            <span className='h-1 w-1 bg-grey-400 rounded-full' />
            <GeminiTransactionHeaderRowCell
              label='Join Date:'
              value={
                !!board?.joinDate
                  ? DateTime.fromISO(board?.joinDate).toFormat('LL/dd/yy')
                  : 'N/A'
              }
              valueClassName='font-light'
            />
          </div>
        </article>
        <article className='md:px-5 px-2 md:py-4 py-2 rounded-lg bg-regent-900 font-zen-body-2 text-white flex flex-col'>
          <div className='flex flex-row flex-wrap md:items-start md:space-x-2.5'>
            <div className='md:flex-1 w-full'>
              <GeminiTransactionHeaderRowCell
                label='Billing Type:'
                value={
                  !!board?.billingType
                    ? capitalizeEnum(board?.billingType)
                    : 'N/A'
                }
                valueClassName='font-light'
              />
            </div>
            <Divider />
            <div className='md:flex-1 w-full'>
              <GeminiTransactionHeaderRowCell
                label='Billing Frequency:'
                value={
                  !!board?.billingFrequency
                    ? capitalizeEnum(board?.billingFrequency)
                    : 'N/A'
                }
                valueClassName='font-light'
              />
            </div>
          </div>
          <div className='flex flex-row flex-wrap md:items-start md:space-x-2.5 mt-3'>
            <div className='md:flex-1 w-full'>
              <GeminiTransactionHeaderRowCell
                label='Fee Amount:'
                value={!!board?.fee ? displayAmount(board?.fee) : 'N/A'}
                valueClassName='font-light'
              />
            </div>
            <Divider />
            <div className='md:flex-1 w-full'>
              <GeminiTransactionHeaderRowCell
                label='Payment Type:'
                value={
                  !!board?.paymentType
                    ? capitalizeEnum(board?.paymentType)
                    : 'N/A'
                }
                valueClassName='font-light'
              />
            </div>
          </div>
          <div className='flex flex-row flex-wrap md:items-start'>
            <div className='w-1/2 flex justify-between mr-2.5 mt-3'>
              <GeminiTransactionHeaderRowCell
                label='Next Due Date:'
                value={
                  !!board?.nextDueDate
                    ? DateTime.fromISO(board.nextDueDate!).toFormat('LL/dd/yy')
                    : 'No Date Yet'
                }
              />
              <div className='h-4.5 w-[1px] bg-grey-200 md:block hidden rounded-lg' />
            </div>
            <div className='mt-3'>
              <GeminiTransactionHeaderRowCell
                label='Unreported License Risk:'
                value={
                  !!board?.unreportedLicenseRisk
                    ? capitalizeEnum(board?.unreportedLicenseRisk)
                    : 'N/A'
                }
              />
            </div>
          </div>
        </article>
      </section>
      <section className='md:w-[34%] w-full h-full space-y-3'>
        <GeminiAgentContactInfoDetailHeader
          phoneNumber={
            board?.phoneNumber && formatPhoneNumber(board?.phoneNumber)
          }
          emailAddress={board?.email}
          addresses={
            !!board?.address
              ? board?.address?.oneLine
              : capitalizeEnum(board?.administrativeArea?.stateOrProvince!)
          }
        />
        <article className='md:px-5 px-2 md:py-4 py-2 rounded-lg bg-regent-900 font-zen-body-2 text-white flex flex-col space-y-2.5'>
          <div className='flex flex-col space-y-1.5'>
            <p className='font-inter font-medium text-sm'>Username/Email ID</p>
            <div className='flex flex-row items-center justify-between p-3 border border-regent-600 rounded-[5px]'>
              <span className='font-light text-sm leading-4'>
                {secrets?.username ?? 'N/A'}
              </span>
              <GeminiCopyToClipboard
                value={secrets?.username ?? ''}
                className='text-white'
                label=''
              />
            </div>
          </div>
          <div className='flex flex-col space-y-1.5'>
            <p className='font-inter font-medium text-sm'>Password</p>
            <div className='flex flex-row items-center justify-between py-2 px-3 border border-regent-600 rounded-[5px]'>
              <span className='font-light text-sm leading-4'>
                {showPassword
                  ? secrets?.password ?? 'N/A'
                  : Array(secrets?.password?.length).fill('*').join('')}
              </span>
              <div className='flex items-center space-x-4'>
                <button onClick={() => setShowPassword((prev) => !prev)}>
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className='text-sm'
                  />
                </button>
                <GeminiCopyToClipboard
                  value={secrets?.password ?? ''}
                  className='text-white'
                  label=''
                />
              </div>
            </div>
          </div>
        </article>
      </section>
    </header>
  );
};

export default GeminiBoardHeaderSection;
