import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form-v7';

const SortableItem: React.FC<{ id: string | number }> = ({ id, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

interface ZenControlledDndInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  subLabel?: string;
  isRequired?: boolean;
  formatValue?: (value: string) => string;
}

const ZenControlledDndInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  isRequired,
  formatValue,
  ...rest
}: ZenControlledDndInputProps<TFieldValues, TName>) => {
  const {
    field: { name, onChange, ref, value },
  } = useController(rest);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = value.indexOf(active.id);
      const newIndex = value.indexOf(over?.id);
      onChange(arrayMove(value, oldIndex, newIndex));
    }
  };

  return (
    <div>
      {label && (
        <label className='inline-block mb-1' htmlFor={name}>
          <span className='font-zen-body font-semibold text-zen-dark-9'>
            {label}
          </span>
          {!!subLabel && (
            <span className='font-zen-body text-sm text-zen-dark-6 ml-1'>
              {subLabel}
            </span>
          )}
          {isRequired && <span className='text-red-600'>*</span>}
        </label>
      )}
      <input ref={ref} name={name} className='hidden' value={value} readOnly />
      <div className='space-y-3'>
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          collisionDetection={closestCenter}
        >
          <SortableContext items={value} strategy={verticalListSortingStrategy}>
            {value.map((item: string) => (
              <SortableItem key={item} id={item}>
                <div
                  aria-label='sortable-item'
                  className='font-zen-body bg-white flex rounded-md gap-2 text-primary-dark p-2 border border-grey-300 items-center'
                >
                  <FontAwesomeIcon
                    icon={faGripDotsVertical}
                    className='mb-0.5'
                  />
                  <p>{formatValue?.(item) || item}</p>
                </div>
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default ZenControlledDndInput;
