import { faTableColumns } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGemini } from '../hooks/useGemini';
import { cn } from '../utils/classUtils';

export interface DefaultEmptyProps {
  message: string;
  icon?: React.ReactElement;
}

const DefaultEmpty: React.FC<DefaultEmptyProps> = ({
  message,
  icon = <FontAwesomeIcon icon={faTableColumns} size='2x' />,
}) => {
  const isGeminiEnabled = useGemini();
  return (
    <div className='w-full h-full p-5 flex flex-col items-center justify-center'>
      {icon}
      <p
        className={cn(
          'text-center',
          isGeminiEnabled
            ? 'text-primary-dark font-light font-zen-body-2 text-sm mt-3'
            : 'font-primary text-gray-400',
        )}
      >
        {message}
      </p>
    </div>
  );
};

export default DefaultEmpty;
