import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { YesNoType } from '../../types';
import { cn } from '../../utils/classUtils';
import { capitalizeEnum } from '../../utils/StringUtils';

interface ZenYesNoButtonProps {
  value: YesNoType;
}
const ZenYesNoButton: React.FC<ZenYesNoButtonProps> = ({ value }) => {
  const isYes = value === YesNoType.YES;

  return (
    <div
      className={cn(
        'flex flex-row items-center font-zen-body font-semibold text-sm space-x-1',
        isYes ? 'text-green-600' : 'text-zen-danger',
      )}
    >
      <FontAwesomeIcon icon={isYes ? faThumbsUp : faClose} />
      <span>{capitalizeEnum(value)}</span>
    </div>
  );
};

export default ZenYesNoButton;
