import { Link } from 'react-router-dom';
import Unauthorized401Img from '../assets/icons/401.svg';

interface Route401Props {}

const Route401: React.FC<Route401Props> = () => {
  return (
    <div className='h-screen flex flex-col justify-center items-center -mt-10'>
      <div>
        <img src={Unauthorized401Img} alt='401' />
        <p className='text-center font-primary-medium text-dark text-lg'>
          Access Restricted
        </p>
      </div>
      <Link
        to='/'
        className='bg-primary rounded text-white items-center p-2 my-4 mx-auto'
      >
        Go Home
      </Link>
    </div>
  );
};

export default Route401;
