import AgentClientApiKey from '../components/Agent/security/AgentClientApiKey';
import AgentEmailAndPasswordSecuritySettings from '../components/Agent/security/AgentEmailAndPasswordSecuritySettings';
import AgentTwoFactorAuthentication from '../components/Agent/security/AgentTwoFactorAuthentication';
import FeatureFlagEnabledOnly from '../components/FeatureFlagEnabledOnly';
import WebhookCard from '../components/Webhook/WebhookCard';
import ZenRoute from '../components/Zen/ZenRoute';
import { FeatureFlagTypeEnum } from '../types';

interface AgentDetailSecuritySettingsRouteProps {}

const AgentDetailSecuritySettingsRoute: React.FC<AgentDetailSecuritySettingsRouteProps> = () => {
  return (
    <ZenRoute title='Security'>
      <div className='p-4'>
        <p className='font-zen-body text-[22px] leading-7 text-black pt-1 pb-10'>
          Security
        </p>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-x-6 gap-y-8 justify-self-stretch'>
          <div className='grid'>
            <AgentEmailAndPasswordSecuritySettings />
          </div>
          <div className='grid'>
            <AgentTwoFactorAuthentication />
          </div>
          <div className='grid'>
            <AgentClientApiKey />
          </div>
          <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.WEBHOOKS}>
            <div className='grid'>
              <WebhookCard />
            </div>
          </FeatureFlagEnabledOnly>
        </div>
      </div>
    </ZenRoute>
  );
};
export default AgentDetailSecuritySettingsRoute;
