import {
  faArrowDownToSquare,
  faCircleCheck,
  faCircleXmark,
  faRotateRight,
} from '@fortawesome/pro-regular-svg-icons';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { ReactComponent as ZenCopyIcon } from '../../assets/icons/zen/zen-copy.svg';
import ZenEditOutgoingPaymentFormSidebarModal from '../../forms/ZenEditOutgoingPaymentFormSidebarModal';
import ZenOutgoingPaymentSidebarModal from '../../forms/ZenOutgoingPaymentSidebarModal';
import {
  OutgoingPaymentResponse,
  OutgoingPaymentResponseStatusEnum,
  OutgoingPaymentsControllerApi,
} from '../../openapi/arrakis';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import {
  showErrorToast,
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import { RootState } from '../../types';
import { displayAmount } from '../../utils/CurrencyUtils';
import { getFormattedDateISOString } from '../../utils/DateUtils';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { isPaymentInErrorState } from '../../utils/PaymentsHelper';
import { fetchAllOutgoingPayments } from '../../utils/TableUtils';
import AdminOnly from '../auth/AdminOnly';
import ControlledDatePickerInputV7 from '../ControlledDatePickerInputV7';
import CopyToClipboardHover from '../CopyToClipboardHover';
import IconButton from '../IconButton';
import NullableTextCell from '../table/Cells/NullableTextCell';
import ZenOutgoingPaymentStatusCell from '../table/Cells/ZenOutgoingPaymentStatusCell';
import ZenOutgoingPaymentSystemCell from '../table/Cells/ZenOutgoingPaymentSystemCell';
import ZenViewActionButtonCell from '../table/Cells/ZenViewActionButtonCell';
import DateColumnFilter from '../table/Filters/DateColumnFilter';
import NumberColumnFilter from '../table/Filters/NumberColumnFilter';
import OutgoingPaymentPaymentSystemSelectColumnFilter from '../table/Filters/OutgoingPaymentPaymentSystemSelectColumnFilter';
import OutgoingPaymentStatusSelectColumnFilter from '../table/Filters/OutgoingPaymentStatusSelectColumnFilter';
import TextColumnFilter from '../table/Filters/TextColumnFilter';
import ZenResourceIndexContainer from '../Zen/Containers/ZenResourceIndexContainer';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';
import ZenActionButtonCell from '../Zen/Table/Cell/ZenActionButtonCell';
import ZenDateCell from '../Zen/Table/Cell/ZenDateCell';
import ZenMilliDateCell from '../Zen/Table/Cell/ZenMilliDateCell';
import ZenButton from '../Zen/ZenButton';
import ZenOutgoingReleasePaymentEarlyModal from './ZenOutgoingReleasePaymentEarlyModal';

interface ZenOutgoingPaymentsComponentProps {
  agentId?: string;
  hiddenColumns?: string[];
}

export const columns: Array<Column<OutgoingPaymentResponse>> = [
  {
    Header: 'Transaction Code',
    accessor: 'transactionCode',
    Cell: ({ value }) => (
      <div className='flex items-center space-x-2 border border-zen-dark-4 rounded-lg px-1.5'>
        <Link to={`/transactions/code/${value}`}>
          <div className='text-sm whitespace-nowrap'>{value}</div>
        </Link>
        <CopyToClipboardHover
          value={value!}
          copyIcon={<ZenCopyIcon />}
          copyToltipLabel='Copy Code'
        />
      </div>
    ),
    Filter: TextColumnFilter,
  },
  {
    Header: 'Transaction Address',
    accessor: 'displayLine1',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Payment System',
    accessor: 'paymentSystem',
    Filter: OutgoingPaymentPaymentSystemSelectColumnFilter,
    Cell: ({ value }) => (
      <div className='md:w-max pt-1 md:pt-0'>
        <ZenOutgoingPaymentSystemCell type={value!} />
      </div>
    ),
  },
  {
    Header: 'Payment System Id',
    accessor: 'paymentSystemId',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Amount Paid',
    accessor: 'amount',
    Cell: ({ value }) => displayAmount(value),
    Filter: NumberColumnFilter,
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Confirmation Code',
    accessor: 'confirmationCode',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <div className='md:w-max pt-1 md:pt-0'>
        <ZenOutgoingPaymentStatusCell type={value!} />
      </div>
    ),
    Filter: OutgoingPaymentStatusSelectColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Paid At',
    accessor: 'paidAt',
    Cell: ({ value }) => <ZenMilliDateCell date={value} />,
    Filter: DateColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Paid On',
    accessor: 'actualPaidOn',
    Cell: ({ value }) => <ZenDateCell date={value} />,
    Filter: DateColumnFilter,
    cardColSize: 6,
  },
  {
    accessor: 'payerNote',
    disableSortBy: true,
    disableFilters: true,
  },
];

const ZenOutgoingPaymentsComponent: React.FC<ZenOutgoingPaymentsComponentProps> = ({
  agentId,
  hiddenColumns,
}) => {
  const dispatch = useDispatch();
  const { isAdmin, isBroker, userDetail } = useSelector(
    (state: RootState) => state.auth,
  );
  const [
    editingOutgoingPayment,
    setEditingOutgoingPayment,
  ] = useState<OutgoingPaymentResponse | null>(null);
  const [
    retryingOutgoingPayment,
    setRetryingOutgoingPayment,
  ] = useState<OutgoingPaymentResponse | null>(null);
  const [retrying, setRetrying] = useState<boolean>(false);
  const [
    currentOutgoingPayment,
    setCurrentOutgoingPayment,
  ] = useState<OutgoingPaymentResponse | null>(null);
  const [
    approvingOutgoingPayment,
    setApprovingOutgoingPayment,
  ] = useState<OutgoingPaymentResponse>();

  const getOutgoingPaymentById = useCallback(
    async (id: string) => {
      try {
        const { data } = await new OutgoingPaymentsControllerApi(
          getArrakisConfiguration(),
        ).getOutgoingPayment(id);
        setCurrentOutgoingPayment(data);
      } catch (e) {
        dispatch(
          showErrorToastForErrorCode(
            'We were unable to fetch the outgoing payment details',
            ErrorService.getErrorCode(e),
          ),
        );
      }
    },
    [dispatch],
  );

  const retryOutgoingPayment = async () => {
    setRetrying(true);
    try {
      const { data } = await new OutgoingPaymentsControllerApi(
        getArrakisConfiguration(),
      ).reScheduleOutGoingPayment(retryingOutgoingPayment?.id!);
      dispatch(
        showSuccessToast(
          `Successfully requested for payment to ${data.firstName} ${data.lastName}`,
        ),
      );
      setEditingOutgoingPayment(null);
      setRetryingOutgoingPayment(null);
    } catch (e) {
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to retry the outgoing payment',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setRetrying(false);
    }
  };

  const handleConfirmOutgoingPayments = async (
    payments: OutgoingPaymentResponse[],
    formData?: OutgoingPaymentResponse,
  ) => {
    const paymentIds = payments?.map((payment) => payment?.id!);

    try {
      await new OutgoingPaymentsControllerApi(
        getArrakisConfiguration(),
      ).confirmPayments({
        outgoingPaymentIds: paymentIds,
        actualPaidOn: formData?.actualPaidOn!,
      });
      dispatch(
        showSuccessToast('Outgoing Payments are confirmed successfully.'),
      );
    } catch (e) {
      ErrorService.notify('Unable to bulk confirm outgoing payments', e, {
        paymentIds,
      });
      dispatch(showApiErrorModal(e));
      dispatch(showErrorToast('Unable to confirm outgoing payments.'));
    }
  };

  const columnsWithAction: Array<Column<OutgoingPaymentResponse>> = [
    {
      Header: 'Actions',
      accessor: 'id',
      id: 'action',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row: { original } }) => (
        <div className='flex space-x-3 flex-nowrap md:w-max pt-1 md:pt-0'>
          <ZenViewActionButtonCell
            onClick={() => getOutgoingPaymentById(original.id!)}
          />
          <AdminOnly>
            <ZenActionButtonCell
              leftIcon={
                <FontAwesomeIcon
                  icon={faPencil}
                  title='Edit'
                  data-testid='Edit'
                  className='text-white p-1 text-lg'
                />
              }
              onClick={() => setEditingOutgoingPayment(original)}
            />
            {isPaymentInErrorState(original.status!) && (
              <ZenActionButtonCell
                leftIcon={
                  <FontAwesomeIcon
                    icon={faRotateRight}
                    title='Retry'
                    className='text-lg'
                  />
                }
                onClick={() => setRetryingOutgoingPayment(original)}
              />
            )}
            {original.status ===
              OutgoingPaymentResponseStatusEnum.Initiated && (
              <ZenActionButtonCell
                label='Release Payment Early'
                onClick={() => setApprovingOutgoingPayment(original)}
              />
            )}
            {original.actualPaidOn && (
              <IconButton
                leftIcon={
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    title='Confirm'
                    className='text-white text-lg'
                  />
                }
                buttonStyle='bg-green-600 border border-green-600 ring-green-600 rounded-lg'
                toolTipText={`Paid on ${getFormattedDateISOString(
                  original.actualPaidOn,
                )}`}
                toolTipConfig={{ placement: 'top' }}
              />
            )}
          </AdminOnly>
          {original.status ===
            OutgoingPaymentResponseStatusEnum.InvalidPaymentDetails && (
            <IconButton
              leftIcon={
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  title='Invalid Payments'
                  className='text-white text-base'
                />
              }
              buttonStyle='bg-zen-danger border border-zen-danger ring-zen-danger rounded-lg'
              toolTipText='Invalid Payment Details'
              toolTipConfig={{ placement: 'top' }}
            />
          )}
        </div>
      ),
    },
    {
      Header: 'Name',
      Filter: TextColumnFilter,
      Cell: ({ row: { original } }: CellProps<OutgoingPaymentResponse>) => (
        <div className='md:w-max'>
          {original.yentaUserId ? (
            <Link to={`/people/${original.yentaUserId}`}>
              <ZenButton
                LeftIconComponent={
                  <FontAwesomeIcon
                    icon={faArrowDownToSquare}
                    title='Link'
                    className='-rotate-90 mr-1 text-base'
                  />
                }
                variant='secondary-gray-outline'
                label={`${original.firstName} ${original.lastName}`}
              />
            </Link>
          ) : (
            <span>{`${original.firstName} ${original.lastName}`}</span>
          )}
        </div>
      ),
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      id: 'firstName',
      Cell: ({ value }) => value,
      disableSortBy: true,
      Filter: TextColumnFilter,
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      id: 'lastName',
      Cell: ({ value }) => value,
      disableSortBy: true,
      Filter: TextColumnFilter,
    },
    {
      Header: 'Company',
      accessor: 'company',
      Cell: ({ value }) => <NullableTextCell text={value} />,
      Filter: TextColumnFilter,
    },
    ...columns,
  ];

  return (
    <>
      <div className='px-4 py-3 lg:py-5'>
        <ZenResourceIndexContainer<OutgoingPaymentResponse>
          header='Outgoing Payments'
          columns={columnsWithAction}
          resourceName='Outgoing Payment'
          hiddenColumns={[
            'payerNote',
            'firstName',
            'lastName',
            ...(hiddenColumns || []),
          ]}
          fetchData={async (req, cancelToken) => {
            const officeIds =
              isBroker && userDetail?.offices
                ? (userDetail.offices
                    .map((office) => office.id)
                    .filter(Boolean) as string[])
                : undefined;

            const data = await fetchAllOutgoingPayments(
              req,
              agentId,
              officeIds,
              {
                cancelToken,
              },
            );

            return {
              data: data?.results || [],
              total: data?.totalCount || 0,
            };
          }}
          initialSort={{ paidAt: 'desc' }}
          allowSelection={isAdmin}
          selectionOptions={[
            {
              label: 'Confirm Selected Outgoing Payments',
              confirm: (payments) => ({
                title: 'Confirm Outgoing Payments',
                description: `Are you sure you would like to confirm these ${payments.length} outgoing payments? This action cannot be undone.`,
                modalType: 'success',
                primaryActionTitle: 'Confirm',
                childJSX: (control) => (
                  <div className='my-2'>
                    <ControlledDatePickerInputV7<
                      OutgoingPaymentResponse,
                      'actualPaidOn'
                    >
                      datePickerConfig={{
                        maxDate: DateTime.local().toJSDate(),
                      }}
                      control={control}
                      name='actualPaidOn'
                      label='Actual Paid On Date'
                      placeholder='MM/DD/YYYY'
                      rules={{
                        required: 'Please select a date',
                      }}
                      isRequired
                    />
                  </div>
                ),
              }),
              onAction: handleConfirmOutgoingPayments,
              isActionButtonEnabled: (payments) => {
                return payments.every((payment) => !payment?.actualPaidOn);
              },
            },
          ]}
          stickyHeader
        />
      </div>
      {!!editingOutgoingPayment && (
        <ZenEditOutgoingPaymentFormSidebarModal
          outgoingPayment={editingOutgoingPayment!}
          onClose={() => setEditingOutgoingPayment(null)}
          isOpen
        />
      )}
      {!!currentOutgoingPayment && (
        <ZenOutgoingPaymentSidebarModal
          isOpen
          onClose={() => setCurrentOutgoingPayment(null)}
          outgoingPayment={currentOutgoingPayment!}
        />
      )}
      <ZenConfirmationModal
        isOpen={!!retryingOutgoingPayment}
        onClose={() => setRetryingOutgoingPayment(null)}
        title='Retry Payment'
        subtitle={`Are you sure you want to retry payment of ${displayAmount(
          retryingOutgoingPayment?.amount,
        )} to ${retryingOutgoingPayment?.firstName} ${
          retryingOutgoingPayment?.lastName
        }`}
        onConfirm={retryOutgoingPayment}
        cancelButtonText='Cancel'
        confirmButtonText='Retry Payment'
        isDisabled={retrying}
        variant='danger'
      />
      {!!approvingOutgoingPayment && (
        <ZenOutgoingReleasePaymentEarlyModal
          isOpen
          approvingOutgoingPaymentId={approvingOutgoingPayment?.id!}
          onClose={() => setApprovingOutgoingPayment(undefined)}
        />
      )}
    </>
  );
};

export default ZenOutgoingPaymentsComponent;
