import { isForceReportingError } from '../utils/TypeUtils';

/**
 * Represents a custom error class for Datadog Handled errors.
 * https://docs.datadoghq.com/error_tracking/custom_grouping/?tab=browser#rum
 */

/**
 * Represents an error that is reported to Datadog for analysis and troubleshooting.
 */
export class DatadogError extends Error {
  /**
   * Represents a force reporting error.
   */
  static FORCE_REPORTING_ERROR = 'Force Reporting Error';

  /**
   * Represents a Datadog handled error.
   */
  static DATADOG_HANDLED_ERROR = 'Datadog Handled Error';

  /**
   * The fingerprint used for custom grouping of error messages in Datadog.
   * This property is used to group similar errors together for better analysis and troubleshooting.
   */
  dd_fingerprint: string;

  constructor(message: string, error: Error) {
    super(error.message);
    this.name = isForceReportingError(error)
      ? DatadogError.FORCE_REPORTING_ERROR
      : DatadogError.DATADOG_HANDLED_ERROR;
    this.stack = error.stack;
    this.message = `${message} - ${error.message}`;
    this.cause = error.cause;
    this.dd_fingerprint = this.message;
  }

  /**
   * Creates a new instance of DatadogError.
   *
   * @param message - The error message.
   * @param error - The underlying error object.
   * @returns A new instance of DatadogError.
   */
  static create(message: string, error: Error): DatadogError {
    return new DatadogError(message, error);
  }
}
