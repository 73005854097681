import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-regular-svg-icons';

interface CustomChipProps {
  label?: string;
  handleClick(): void;
}

const CustomChip: React.FC<CustomChipProps> = ({ label = '', handleClick }) => {
  return (
    <div
      className='flex items-center m-1 py-1.5 px-2.5 rounded-lg bg-grey-100 text-sm'
      data-dismissible='chip'
    >
      <div className='mr-2 mt-px font-zen-body text-dark'>{label}</div>

      <FontAwesomeIcon
        role='button'
        icon={faClose}
        className='mt-px'
        onClick={(e) => {
          e.stopPropagation();
          handleClick();
        }}
      />
    </div>
  );
};

export default CustomChip;
