import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { cn } from '../utils/classUtils';
import { MoneyValue, MoneyValueCurrencyEnum } from '../openapi/yenta';
import { getSelectOptionsForEnums } from '../utils/TableUtils';
import { FormFieldTooltipIndexProps } from '../types';
import FormErrorMessage from './FormErrorMessage';

type IMoneyValue = Record<string, MoneyValue>;

export interface ControlledMoneyInputV7Props<
  TFieldValues extends FieldValues | IMoneyValue = FieldValues | IMoneyValue,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  extraLabelComponent?: React.ReactElement;
  readOnly?: boolean;
  currencyReadOnly?: boolean;
  placeholder?: string;
}

const ControlledMoneyInputV7 = <
  TFieldValues extends FieldValues | IMoneyValue = FieldValues | IMoneyValue,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  readOnly,
  currencyReadOnly,
  placeholder,
  extraLabelComponent,
  shouldUnregister = true,
  ...rest
}: ControlledMoneyInputV7Props<TFieldValues, TName>) => {
  const options = getSelectOptionsForEnums(MoneyValueCurrencyEnum);

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <div className='flex items-center space-x-1'>
              <label className='inline-block' htmlFor={`${name}.amount`}>
                {label}
              </label>
              {extraLabelComponent && <span>{extraLabelComponent}</span>}
            </div>
          )}
          <div className='border border-gray-200 rounded overflow-hidden w-full flex flex-row'>
            <input
              id={`${name}.amount`}
              name={`${name}.amount`}
              value={value?.amount}
              onChange={({ target: { value: amount } }) => {
                onChange({
                  ...(value || {}),
                  amount,
                });
              }}
              onBlur={onBlur}
              readOnly={readOnly}
              placeholder={placeholder}
              className={cn(
                'appearance-none p-1.5 border-0 bg-white focus:outline-none focus:ring-0 flex-grow w-full',
                {
                  'bg-gray-50': readOnly,
                },
              )}
            />
            <div>
              <select
                id={`${name}.currency`}
                name={`${name}.currency`}
                value={value?.currency}
                onChange={(e) => {
                  onChange({ ...(value || {}), currency: e.target.value });
                }}
                className={cn(
                  'appearance-none p-1.5 bg-white focus:outline-none border-0 focus:ring-0 w-18',
                  {
                    'pointer-events-none bg-gray-50':
                      readOnly || currencyReadOnly,
                  },
                )}
              >
                {options.map((currency) => (
                  <option key={currency.value} value={currency.value}>
                    {currency.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledMoneyInputV7;
