import { ItemResponse } from '../../../openapi/sherlock';
import ZenJourneyStep from './ZenJourneyStep';

export enum ChecklistType {
  ROAD_TO_SUCCESS = 'ROAD_TO_SUCCESS',
  AGENT_JOURNEY = 'AGENT_JOURNEY',
}

export interface ZenVerticalJourneyStepProgressProps {
  steps: ItemResponse[];
  isJourneyProgress?: boolean;
}

const ZenVerticalJourneyStepProgress: React.FC<ZenVerticalJourneyStepProgressProps> = ({
  steps,
}) => {
  return (
    <ol>
      {steps.map((step, index) => {
        return (
          <ZenJourneyStep
            key={step.name}
            isLastStep={index === steps.length - 1}
            step={step}
          />
        );
      })}
    </ol>
  );
};

export default ZenVerticalJourneyStepProgress;
