import { createRef } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { ReactComponent as PhotoCamera } from '../../../assets/icons/camera.svg';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { isImageValid } from '../../../utils/AgentHelper';
import AgentProfileImagePreview from '../../onboarding/welcomeOnboarding/components/AgentProfileImagePreview';
import { isValidUserAvatarFile } from '../../../utils/FileUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';

interface ZenControlledProfileImageUploadProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  personName: string;
}

const ZenControlledProfileImageUpload = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  personName,
  shouldUnregister = true,
  ...rest
}: ZenControlledProfileImageUploadProps<TFieldValues, TName>) => {
  const ref = createRef<HTMLInputElement>();
  const dispatch = useDispatch();

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({ field: { name, onChange, value }, fieldState: { error } }) => (
        <div className='space-y-1'>
          {!!label && <label htmlFor={name}>{label}</label>}
          <div className='flex flex-col space-y-4'>
            <div>
              <div className='rounded-full border-2 border-zen-gray-4'>
                <AgentProfileImagePreview
                  name={personName}
                  imageUrl={
                    typeof value === 'object'
                      ? URL.createObjectURL(value)
                      : value
                  }
                />
              </div>
            </div>
            <div
              onClick={() => ref.current?.click()}
              className='flex flex-row items-center text-primary cursor-pointer space-x-1'
            >
              <PhotoCamera />
              <span className='font-primary-medium text-base'>
                Upload photo
              </span>
            </div>
            <input
              type='file'
              className='hidden'
              ref={ref}
              aria-label='uploadAvatar'
              onChange={(e) => {
                if (e.target?.files && e.target?.files?.[0]) {
                  if (isValidUserAvatarFile(e.target?.files?.[0]?.name)) {
                    if (isImageValid(e)) {
                      onChange(e.target!.files![0]);
                    } else {
                      dispatch(
                        showErrorToast(
                          'File size exceeds maximum limit of 10 MB.',
                        ),
                      );
                    }
                  } else {
                    dispatch(
                      showErrorToast(
                        'File type not supported. Upload jpg/jpeg, png, gif, bmp file only',
                      ),
                    );
                  }
                  e.target.value = '';
                }
              }}
            />
          </div>
          {error?.message && <ZenFormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenControlledProfileImageUpload;
