import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ItemRequest,
  ItemResponse,
  ItemDtoStatusEnum,
  ItemDtoRequiredForEnum,
} from '../../../openapi/sherlock';
import { updateChecklistItem } from '../../../slices/CheckListSlice';
import { AppDispatch } from '../../../types';
import ZenToggleRow from '../Input/ZenToggleRow';

interface ZenChecklistItemRequiredToggleProps {
  checklistItem: ItemResponse;
  checklistId: string;
}

const ZenChecklistItemRequiredToggle: React.FC<ZenChecklistItemRequiredToggleProps> = ({
  checklistItem,
  checklistId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const updateCheckListType = async () => {
    if (!checklistItem?.id) return;

    setLoading(true);

    const checklistItemReq: ItemRequest = {
      item: {
        ...(checklistItem || {}),
        position: checklistItem?.position,
        name: checklistItem.name!,
        status: (checklistItem?.status as unknown) as ItemDtoStatusEnum,
        urgent: checklistItem.urgent,
        assignee: checklistItem.assignee || undefined,
        required: !checklistItem.required,
        dueDate: checklistItem.dueDate,
        labels: checklistItem?.labels,
        requiredFor:
          ((checklistItem?.requiredFor as unknown) as ItemDtoRequiredForEnum) ||
          undefined,
      },
    };

    await dispatch(
      updateChecklistItem(
        checklistId,
        checklistItem.id!,
        checklistItemReq,
        false,
      ),
    );

    setLoading(false);
  };

  return (
    <ZenToggleRow
      loading={loading}
      value={!!checklistItem.required}
      onChange={updateCheckListType}
    />
  );
};

export default ZenChecklistItemRequiredToggle;
