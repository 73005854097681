import classNames from 'classnames';
import {
  Controller,
  FieldValues,
  UseControllerProps,
  FieldPath,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps } from '../../../types';
import ZenFormErrorMessage from './ZenFormErrorMessage';

export interface ZenControlledWebsiteURLTextInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  type?: 'text';
  inputMode?: 'text';
  label?: string;
  leftWebsiteSegment?: string;
  readOnly?: boolean;
  placeholder?: string;
  isRequired?: boolean;
}

const ZenControlledWebsiteURLTextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  type = 'text',
  inputMode = 'text',
  leftWebsiteSegment,
  readOnly,
  placeholder,
  tooltipIndex,
  shouldUnregister = true,
  isRequired,
  ...rest
}: ZenControlledWebsiteURLTextInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error, invalid },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              <span
                className={classNames(
                  'font-zen-body font-semibold',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                )}
              >
                {label}
              </span>
              {isRequired && <span className='text-zen-danger'>*</span>}
            </label>
          )}
          <div className='flex flex-row items-center'>
            <span className='font-zen-body text-gray-600 text-base pr-1'>
              {leftWebsiteSegment}
            </span>
            <input
              id={name}
              value={value}
              onBlur={(e) => {
                onChange(e.target.value?.trim());
                onBlur();
              }}
              onChange={onChange}
              type={type}
              inputMode={inputMode}
              name={name}
              readOnly={readOnly}
              placeholder={placeholder}
              className={classNames(
                'appearance-none p-2 border-gray-200 rounded-lg bg-white focus:outline-none focus:ring-0 w-full font-zen-body font-normal',
                {
                  'bg-gray-50': readOnly,
                },
              )}
              data-tooltip-index={tooltipIndex}
              aria-label={name}
            />
          </div>
          {error?.message && <ZenFormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenControlledWebsiteURLTextInput;
