import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEliteAgentProductionAward } from '../../../../slices/WealthManagementSlice';
import { RootState } from '../../../../types';
import { formatMoneyValue } from '../../../../utils/CurrencyUtils';
import IncomeProjectionChart from '../../V2/IncomeProjectionChart';
import WealthPlanInfoCard from '../../V2/WealthPlanInfoCard';
import WealthPlanSidebarContainer from './WealthPlanSidebarContainer';

const EliteAgentProductionAwardSidebarContentV2 = () => {
  const dispatch = useDispatch();
  const {
    eliteAgentProductionAwardResponse: {
      loading: eliteAgentProductionAwardLoading,
      data: eliteAgentProductionAwardResponse,
    },
    wealthGoalsResponse: { data: wealthGoalsResponse },
  } = useSelector((state: RootState) => state.wealthManagement);

  const isLoading =
    eliteAgentProductionAwardLoading && !eliteAgentProductionAwardResponse;

  const {
    currentEliteAgentProductionAwardValue,
    currentYearEliteAgentProductionAwardValue,
    projectedEliteAgentProductionAwardValue,
    currentEliteAgentProductionAwardShares,
    currentYearEliteAgentProductionAwardShares,
    projectedEliteAgentProductionAwardShares,
  } = eliteAgentProductionAwardResponse || {};

  useEffect(() => {
    dispatch(getEliteAgentProductionAward());
  }, [dispatch, wealthGoalsResponse]);

  return (
    <WealthPlanSidebarContainer
      type='ELITE_PRODUCTION_AWARD'
      loading={isLoading}
      isEmpty={!eliteAgentProductionAwardResponse}
      resourceName='Elite Agent Production Award'
    >
      <WealthPlanInfoCard
        label='5 Year Projected Value'
        labelTooltip='Adjust your Commissions transactions and volume to achieve Elite Agent status'
        valueSizeVariant='text-3xl'
        value={formatMoneyValue(projectedEliteAgentProductionAwardValue)}
        subValue={projectedEliteAgentProductionAwardShares}
      />
      <div className='flex flex-row justify-between space-x-3 my-3'>
        <WealthPlanInfoCard
          label='Value of Shares Earned-to-Date'
          value={formatMoneyValue(currentEliteAgentProductionAwardValue)}
          subValue={currentEliteAgentProductionAwardShares}
        />
        <WealthPlanInfoCard
          label='Number of Shares Earned-to-Date'
          value={formatMoneyValue(currentYearEliteAgentProductionAwardValue)}
          subValue={currentYearEliteAgentProductionAwardShares}
        />
      </div>
      <IncomeProjectionChart
        earningType='ELITE_PRODUCTION_AWARD'
        title={{ text: '5 Year Projected Value' }}
      />
    </WealthPlanSidebarContainer>
  );
};

export default EliteAgentProductionAwardSidebarContentV2;
