import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../../routes/AgentWebsiteOnboardingRoute';
import { capitalizeEnum, displayStringOrNA } from '../../../utils/StringUtils';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ReviewRow from '../../ReviewRow';
import EditOutlineActionButton from '../EditOutlineActionButton';
import { setDefaultAccordionOpenType } from '../../../slices/AgentWebsiteOnboardingSlice';
import { AgentWebsiteOnboardingSiteInfoAccordionName } from '../../../types';

const AgentWebsiteOnboardingAboutContentReview: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ form: { watch }, navigateTo }) => {
  const dispatch = useDispatch();

  const [competencies, serviceAreaZipcodes] = watch([
    'competencies',
    'serviceAreaZipcodes',
  ]);

  return (
    <div className='p-4'>
      <div className='grid lg:grid-cols-2 grid-cols-1 gap-5'>
        <ReviewRow
          label='I Work With'
          value={
            competencies?.length
              ? competencies.map((c) => capitalizeEnum(c)).join(', ')
              : 'N/A'
          }
        />
        <ReviewRow
          label='Service Area ZIP Codes'
          value={displayStringOrNA(serviceAreaZipcodes)}
        />
      </div>
      <div className='mt-5 mb-2'>
        <EditOutlineActionButton
          text='Edit about content'
          icon={<FontAwesomeIcon icon={faPen} className='mt-1 mr-1' />}
          onClick={() => {
            dispatch(
              setDefaultAccordionOpenType(
                AgentWebsiteOnboardingSiteInfoAccordionName.ABOUT_CONTENT,
              ),
            );
            navigateTo(AgentWebsiteOnboardingStepName.SITE_INFO);
          }}
        />
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingAboutContentReview;
