import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { faFloppyDisk } from '@fortawesome/pro-duotone-svg-icons';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Control,
  FormState,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as CheckDeposit } from '../../../assets/img/deposit_check.svg';
import {
  CheckDepositUploadResponse,
  MoneyValueCurrencyEnum,
} from '../../../openapi/arrakis';
import { saveUploadedCheckDeposits } from '../../../slices/CheckDepositsSlice';
import { AppDispatch, RootState } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import {
  ALPHA_NUMERIC_REGEX_NO_SPACE,
  getRoutingNumberForCountry,
} from '../../../utils/StringUtils';
import NotificationAlert from '../../NotificationAlert';
import ZenControlledCurrencyInput from '../../Zen/Input/ZenControlledCurrencyInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../../Zen/ZenButton';
import ZenEditOutlineActionButton from '../../agentWebsiteOnboarding/ZenEditOutlineActionButton';
import {
  DepositCheckFormData,
  Match,
} from '../../../routes/ZenTransactionDepositCheckRoute';
import ZenCheckInfoTableDataCell from './ZenCheckInfoTableDataCell';
import ZenCheckInfoTableHeaderCell from './ZenCheckInfoTableHeaderCell';
import ZenCheckReview from './ZenCheckReview';

interface CheckDepositReviewStepProps {
  onSubmit(): void;
  form: {
    control: Control<DepositCheckFormData, object>;
    watch: UseFormWatch<DepositCheckFormData>;
    formState: FormState<DepositCheckFormData>;
    trigger: UseFormTrigger<DepositCheckFormData>;
    setValue: UseFormSetValue<DepositCheckFormData>;
  };
  onPrevious(): void;
}

const ZenCheckDepositReviewStep: React.FC<CheckDepositReviewStepProps> = ({
  form: {
    control,
    watch,
    trigger,
    formState: { errors, isValid },
  },
  onPrevious,
  onSubmit,
}) => {
  const history = useHistory();
  const { id, escrowId } = useParams<Match>();
  const dispatch: AppDispatch = useDispatch();
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
      tokenTransactionDetail,
    },
    checkDeposits: { loading, uploadedCheckDeposits },
    auth: { userDetail },
  } = useSelector((state: RootState) => state);
  const [cancel, setCancel] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [cancelEdit, setCancelEdit] = useState<boolean>(false);
  const [editScannedAmount, setEditScannedAmount] = useState<boolean>(false);
  const isAmountMatched =
    uploadedCheckDeposits?.scannedAmount ===
    uploadedCheckDeposits?.submittedAmount;
  const isExternalCheckDepositUpload = watch('isExternalCheckDeposit');
  const currency = isExternalCheckDepositUpload
    ? tokenTransactionDetail?.currencyCode
    : transactionDetail?.currency;

  const [
    amount,
    frontCheckImage,
    backCheckImage,
    routingNo,
    accountNo,
    checkNo,
    scannedAmount,
  ] = watch([
    'amount',
    'frontCheckImage',
    'backCheckImage',
    'routingNo',
    'accountNo',
    'checkNo',
    'scannedAmount',
  ]);

  useEffect(() => {
    trigger();
  }, [
    trigger,
    routingNo,
    accountNo,
    checkNo,
    edit,
    amount,
    scannedAmount,
    uploadedCheckDeposits?.scannedAmount,
  ]);

  const getSubmittingAmountLabel = (): string => {
    if (!isAmountMatched && !edit) {
      return 'Entered deposit amount';
    } else if (edit && !isAmountMatched) {
      return 'Amount entered';
    } else {
      return 'Check amount';
    }
  };

  const handleEditUpdate = async () => {
    if (isEmpty(errors) && isValid) {
      const updatedData: CheckDepositUploadResponse = {
        ...uploadedCheckDeposits!,
        submittedAmount: parseFloat(amount!),
        abaNumber: routingNo,
        accountNumber: accountNo,
        checkNumber: checkNo,
      };

      await dispatch(saveUploadedCheckDeposits(updatedData));
      setEdit(false);
    }
  };

  const handleEditScannedAmount = async () => {
    const isValid = await trigger('scannedAmount');
    if (isValid) {
      const updatedData: CheckDepositUploadResponse = {
        ...uploadedCheckDeposits!,
        scannedAmount: parseFloat(scannedAmount!),
      };
      await dispatch(saveUploadedCheckDeposits(updatedData));
      setEditScannedAmount(false);
    }
  };

  const routingNumberPattern: RegExp = getRoutingNumberForCountry(
    userDetail?.accountCountry,
  );

  return (
    <div>
      {!isAmountMatched && (
        <div className='mt-4'>
          <NotificationAlert
            variant='warning'
            title='CHECK AMOUNT MISMATCH'
            subtitle={
              'The check amount scanned is not the same as the deposit amount you entered.\n' +
              'Please make sure that the scanned and the deposit amounts match.\n' +
              'If the scanned amount is wrong, click Override to change it. If the entered amount is wrong, correct the amount you entered.'
            }
            icon={
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className='text-warning text-[17px]'
              />
            }
          />
        </div>
      )}
      <div className='mt-4'>
        {!isAmountMatched || edit ? (
          <div className='mb-4'>
            <ZenControlledCurrencyInput
              name='amount'
              control={control}
              shouldUnregister={false}
              label={getSubmittingAmountLabel()}
              subLabel={`(${currency})`}
              placeholder='E.g. 10,000.00'
              startAdornment={
                <div className='flex items-center justify-center w-8 h-full bg-gray-100 text-zen-dark-9'>
                  <p className='text-sm font-medium font-primary-medium'>$</p>
                </div>
              }
              rules={{
                required: 'Check Amount is required',
                validate: async (value) => {
                  if (!!value && parseFloat(value) <= 0) {
                    return 'Check Amount should be greater than 0';
                  }

                  return +scannedAmount! !== parseFloat(value!)
                    ? 'Enter amount as it appears on the paper check. This should match scanned amount.'
                    : undefined;
                },
              }}
            />
          </div>
        ) : (
          <div className='my-4'>
            <h6 className='font-zen-body text-base text-zen-dark-9'>
              {`Check amount (${currency})`}
            </h6>
            <p className='font-primary-medium text-2xl text-zen-dark-9'>
              {displayAmount(
                {
                  amount: uploadedCheckDeposits?.submittedAmount,
                  currency: (currency as unknown) as MoneyValueCurrencyEnum,
                },
                {
                  hideCurrency: true,
                },
              )}
            </p>
          </div>
        )}
        <h6 className='font-zen-body text-base text-zen-dark-9'>
          {`Scanned Amount (${currency})`}
        </h6>
        {editScannedAmount ? (
          <div className='flex space-x-3 w-1/2'>
            <ZenControlledCurrencyInput
              name='scannedAmount'
              control={control}
              shouldUnregister={false}
              startAdornment={
                <div className='flex items-center justify-center w-8 h-full bg-gray-100 text-zen-dark-9'>
                  <p className='text-sm font-medium font-primary-medium'>$</p>
                </div>
              }
              rules={{
                required: 'Scanned amount is required',
                validate: (amount: string | undefined) =>
                  !!amount && parseFloat(amount) <= 0
                    ? 'Check Amount should be greater than 0'
                    : undefined,
              }}
            />
            <ZenEditOutlineActionButton
              text='Save'
              icon={
                <FontAwesomeIcon
                  icon={faFloppyDisk}
                  size='lg'
                  className='mx-1 mt-0.5'
                />
              }
              onClick={handleEditScannedAmount}
            />
          </div>
        ) : (
          <div className='flex items-center space-x-1'>
            <p className='font-primary-medium text-2xl text-zen-dark-9'>
              {displayAmount(
                {
                  amount: uploadedCheckDeposits?.scannedAmount,
                  currency: (currency as unknown) as MoneyValueCurrencyEnum,
                },
                {
                  hideCurrency: true,
                },
              )}
            </p>
            <ZenEditOutlineActionButton
              text='Override'
              icon={
                <FontAwesomeIcon
                  icon={faPen}
                  className='text-primary-blue font-bold mt-1.5'
                />
              }
              onClick={() => setEditScannedAmount(true)}
            />
          </div>
        )}
        <div className='flex mt-2'>
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className='text-zen-warning mt-0.5 text-lg'
          />
          <span className='ml-2'>
            Scanned amount not correct? Click Override to fix
          </span>
        </div>
      </div>
      <div className='flex flex-col md:flex-row md:justify-between md:items-center mt-6'>
        <ZenCheckReview
          label='Front of check'
          url={URL.createObjectURL(frontCheckImage as Blob)}
        />
        <ZenCheckReview
          label='Back of check'
          url={URL.createObjectURL(backCheckImage as Blob)}
        />
      </div>
      <div className='my-5'>
        <table className='w-full border-b border-gray-100 table-fixed'>
          <thead className='bg-gray-100'>
            <tr>
              <ZenCheckInfoTableHeaderCell data='Routing No.' />
              <ZenCheckInfoTableHeaderCell data='Account No.' />
              <ZenCheckInfoTableHeaderCell data='Check No.' />
            </tr>
          </thead>
          <tbody>
            <tr>
              {edit ? (
                <>
                  <td className='py-2 px-2 md:px-5 align-top'>
                    <ZenControlledTextInput
                      control={control}
                      name='routingNo'
                      placeholder='Enter Bank Routing Number'
                      defaultValue={uploadedCheckDeposits?.abaNumber}
                      rules={{
                        required: 'Please provide the bank routing number',
                        pattern: {
                          value: routingNumberPattern,
                          message: 'Please enter a valid bank routing number',
                        },
                      }}
                    />
                  </td>
                  <td className='py-2 px-2 md:px-5 align-top'>
                    <ZenControlledTextInput
                      control={control}
                      name='accountNo'
                      placeholder='Enter Account Number'
                      defaultValue={uploadedCheckDeposits?.accountNumber}
                      rules={{
                        required: 'Please provide an account number',
                        pattern: {
                          value: ALPHA_NUMERIC_REGEX_NO_SPACE,
                          message: 'Please enter a valid account number',
                        },
                      }}
                    />
                  </td>
                  <td className='py-2 px-2 md:px-5 align-top'>
                    <ZenControlledTextInput
                      control={control}
                      name='checkNo'
                      placeholder='Enter Check Number'
                      defaultValue={uploadedCheckDeposits?.checkNumber}
                      rules={{
                        required: 'Please provide the check number',
                        pattern: {
                          value: ALPHA_NUMERIC_REGEX_NO_SPACE,
                          message: 'Please enter a valid check number',
                        },
                      }}
                    />
                  </td>
                </>
              ) : (
                <>
                  <ZenCheckInfoTableDataCell
                    data={uploadedCheckDeposits?.abaNumber!}
                  />
                  <ZenCheckInfoTableDataCell
                    data={uploadedCheckDeposits?.accountNumber!}
                  />
                  <ZenCheckInfoTableDataCell
                    data={uploadedCheckDeposits?.checkNumber!}
                  />
                </>
              )}
            </tr>
          </tbody>
        </table>
        {!edit && (
          <div className='flex flex-row items-center space-x-1 pt-4'>
            <p className='font-primary-medium text-base text-zen-dark-9'>
              Something not correct?
            </p>
            <ZenEditOutlineActionButton
              text='Enter manually'
              icon={
                <FontAwesomeIcon
                  icon={faPen}
                  className='text-primary-blue font-bold mt-1.5 mx-0.5'
                />
              }
              onClick={() => setEdit(true)}
            />
          </div>
        )}
      </div>

      <div className='grid grid-cols-2 gap-8 py-12'>
        <ZenButton
          label='Cancel'
          variant='primary-outline'
          onClick={() => (edit ? setCancelEdit(true) : setCancel(true))}
        />
        {edit ? (
          <ZenButton
            type='button'
            isSubmitting={loading}
            isDisabled={loading}
            label='Save'
            onClick={handleEditUpdate}
          />
        ) : (
          <ZenButton
            LeftIconComponent={<CheckDeposit />}
            isSubmitting={loading}
            isDisabled={loading}
            label='Confirm Deposit'
            onClick={onSubmit}
          />
        )}
      </div>
      <ZenConfirmationModal
        variant='danger'
        cancelButtonVariant='primary-outline'
        title='Cancel check deposit?'
        subtitle='This action will delete the images and all the information captured from the check.'
        isOpen={cancel}
        onClose={() => {
          setCancel(false);
          onPrevious();
        }}
        onConfirm={() =>
          history.push(
            escrowId
              ? `/transactions/${id}/view-deposits`
              : `/transactions/${id}`,
          )
        }
        cancelButtonText='Go Back'
        confirmButtonText='Cancel'
      />

      <ZenConfirmationModal
        variant='danger'
        title='Discard manual entries?'
        subtitle='We notices you made some edits. Discard will undo these edits.'
        isOpen={cancelEdit}
        onClose={() => setCancelEdit(false)}
        onConfirm={() => {
          setEdit(false);
          setCancelEdit(false);
        }}
        cancelButtonText='Close'
        confirmButtonText='Discard'
        cancelButtonVariant='secondary'
      />
    </div>
  );
};

export default ZenCheckDepositReviewStep;
