import { faCircleInfo, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import { useEffect, useState } from 'react';
import {
  Control,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form-v7';
import { CapLevelsEnum, CapLevelsToAmountMap } from '../../types';
import { cn } from '../../utils/classUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import { NUMBER_VALIDATIONS } from '../../utils/Validations';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../Zen/Input/ZenControlledToggleInput';
import ZenIconCircleWithTooltipCell from '../Zen/Transaction/ZenIconCircleWithTooltipCell';
import {
  CreateJointVentureFormState,
  TierEnum,
} from './CreateJointVentureSteps';
import CreateTierLevel from './CreateTierLevel';
import JointVentureControlledCapInput from './JointVentureControlledCapInput';

export interface TierCardProps {
  tierIndex: number;
  control: Control<CreateJointVentureFormState, object>;
  watch: UseFormWatch<CreateJointVentureFormState>;
  setValue: UseFormSetValue<CreateJointVentureFormState>;
  remove(i: any): void;
  setError: UseFormSetError<CreateJointVentureFormState>;
  setShowError(val: boolean): void;
  clearErrors: UseFormClearErrors<CreateJointVentureFormState>;
}

const TierCard: React.FC<TierCardProps> = ({
  tierIndex,
  control,
  watch,
  setValue,
  remove,
  clearErrors,
  setError,
  setShowError,
}) => {
  const tiers = watch('tiers');
  const tier = tiers[tierIndex];
  const [color, setColor] = useState<string>(tier.colorCode || '#FFF');
  const isSadAndAlone = tiers.length === 1;
  const tierLevel = (tier.tierLevel?.value as unknown) as TierEnum;
  const isTierSet = !!tierLevel;
  const isCreateTier = isTierSet && tierLevel === TierEnum.CREATE_TIER;
  const includesNoTier = tiers.some(
    (el) => el.tierLevel?.value === TierEnum.NO_TIER,
  );
  const hasDuplicates = () =>
    tiers
      .map((v) => v.name || v.customTierName)
      .some((item, index, data) => item && data.indexOf(item) !== index);

  useEffect(() => {
    setValue(`tiers.${tierIndex}.colorCode`, color!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  useEffect(() => {
    if (tierLevel === TierEnum.CREATE_TIER && !tier.customTierName) {
      setValue(`tiers.${tierIndex}.customTierName`, '');
    } else if (tierLevel === TierEnum.NO_TIER) {
      setValue(`tiers.${tierIndex}.name`, 'Open Group');
    } else if (tierLevel !== TierEnum.CREATE_TIER) {
      setValue(`tiers.${tierIndex}.name`, tierLevel!);
    }
    if (tierLevel === TierEnum.CREATE_TIER && !!tier.name) {
      setValue(`tiers.${tierIndex}.name`, undefined);
    }
    if (tierLevel !== TierEnum.CREATE_TIER) {
      setValue(`tiers.${tierIndex}.colorCode`, tierBandColorMap[tierLevel]);
    } else {
      setValue(`tiers.${tierIndex}.colorCode`, tier.colorCode || color);
    }

    if (includesNoTier && tiers.length > 1) {
      setShowError(true);
      for (let index = 0; index < tiers.length; index++) {
        setError(`tiers.${index}.tierLevel`, {
          message: 'Cannot add more tiers when No Tier is added.',
        });
      }
    } else if (hasDuplicates()) {
      setShowError(false);
      for (let index = 0; index < tiers.length; index++) {
        setError(`tiers.${index}.tierLevel`, {
          message: 'Cant have the same name/tier multiple times.',
        });
        setError(`tiers.${index}.customTierName`, {
          message: 'Cant have the same name/tier multiple times.',
        });
      }
    } else {
      clearErrors();
      setShowError(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tierLevel, tier.customTierName, tiers.length]);

  const tierBandColorMap = {
    SILVER: '#8798A8',
    GOLD: '#D09F43',
    PLATINUM: '#BFC3CA',
    NO_TIER: '#FFFFFF',
  };

  return (
    <div className='w-full border border-zen-dark-4 bg-white rounded-lg font-zen-body'>
      <div className='p-5 flex items-center justify-between'>
        <div className='flex-grow font-primary-medium text-xl text-dark'>
          {isCreateTier
            ? tier.customTierName
            : tierLevel !== TierEnum.NO_TIER && capitalizeEnum(tier.name || '')}
        </div>
        {!isSadAndAlone && (
          <FontAwesomeIcon
            icon={faTrashCan}
            size='lg'
            className='text-zen-dark-7'
            onClick={() => remove(tierIndex)}
          />
        )}
      </div>

      <hr
        className={cn(isTierSet ? 'h-2' : 'h-px text-dark')}
        style={{
          backgroundColor: isCreateTier
            ? tier.colorCode || color
            : tierBandColorMap[tierLevel],
        }}
      />

      <div className='px-5 py-4'>
        <div className={cn({ 'pb-4': isTierSet })}>
          <ZenControlledSelectInput<
            CreateJointVentureFormState,
            `tiers.${number}.tierLevel`
          >
            name={`tiers.${tierIndex}.tierLevel`}
            control={control}
            label='Tier'
            placeholder='Select Tier'
            options={Object.values(TierEnum).map((item) => ({
              label: capitalizeEnum(item),
              value: item,
            }))}
            isRequired
            rules={{
              required: 'Please select a tier',
              validate: (opt) => {
                if (
                  (includesNoTier ||
                    ((opt?.value as any) as TierEnum) === TierEnum.NO_TIER) &&
                  tiers.length > 1
                ) {
                  return 'Cannot add more tiers when No Tier is added.';
                } else if (hasDuplicates()) {
                  return 'Cant have the same name/tier multiple times.';
                }
                return undefined;
              },
            }}
            shouldUnregister={false}
          />
          {isCreateTier && (
            <CreateTierLevel
              tierIndex={tierIndex}
              control={control}
              color={color!}
              setColor={setColor}
            />
          )}
        </div>
        {isTierSet && (
          <div>
            <hr className='h-px text-dark' />
            <div className='py-4'>
              <p className='pb-5 text-lg font-primary-medium text-dark'>
                Minimum Eligibility Criteria
              </p>
              <div className='pl-4'>
                <div className='pb-5 text-base'>
                  <div className='flex flex-row items-center justify-between'>
                    <p className='font-primary-thin text-base text-zen-dark-9'>
                      Tenure (months)
                    </p>
                    <div>
                      <ZenControlledToggleInput<
                        CreateJointVentureFormState,
                        `tiers.${number}.hasTenure`
                      >
                        name={`tiers.${tierIndex}.hasTenure`}
                        control={control}
                        shouldUnregister={false}
                      />
                    </div>
                  </div>

                  {tier?.hasTenure && (
                    <div className='pt-2.5'>
                      <ZenControlledTextInput<
                        CreateJointVentureFormState,
                        `tiers.${number}.eligibilityCriteria.minimumMonths`
                      >
                        name={`tiers.${tierIndex}.eligibilityCriteria.minimumMonths`}
                        control={control}
                        rules={{
                          required:
                            "Tenure is required. Please toggle it if you don't need it.",
                          ...NUMBER_VALIDATIONS,
                        }}
                        shouldUnregister={false}
                        isRequired
                      />
                    </div>
                  )}
                </div>

                <div className='pb-5'>
                  <div className='flex justify-between items-center'>
                    <div className='flex flex-grow items-center'>
                      <p className='font-primary-thin text-base text-zen-dark-9 mr-2'>
                        Transactions
                      </p>
                      <ZenIconCircleWithTooltipCell
                        hoverComponent={
                          <div className='w-64 text-dark font-zen-body p-1 bg-white'>
                            Transactions closed within the prior 12 months
                          </div>
                        }
                        onClick={() => {}}
                        icon={
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            size='sm'
                            className='text-zen-dark-7'
                          />
                        }
                        noWrap
                      />
                    </div>
                    <div>
                      <ZenControlledToggleInput<
                        CreateJointVentureFormState,
                        `tiers.${number}.hasTransactions`
                      >
                        name={`tiers.${tierIndex}.hasTransactions`}
                        control={control}
                        shouldUnregister={false}
                      />
                    </div>
                  </div>

                  {tier.hasTransactions && (
                    <div className='pt-2.5 w-full'>
                      <ZenControlledTextInput<
                        CreateJointVentureFormState,
                        `tiers.${number}.eligibilityCriteria.minimumCount`
                      >
                        name={`tiers.${tierIndex}.eligibilityCriteria.minimumCount`}
                        control={control}
                        rules={{
                          required:
                            "Transactions count is required. Please toggle it if you don't need it.",
                          ...NUMBER_VALIDATIONS,
                        }}
                        shouldUnregister={false}
                        isRequired
                      />
                    </div>
                  )}
                </div>

                <div className='pb-5'>
                  <div className='flex justify-between items-center'>
                    <div className='flex flex-grow items-center'>
                      <p className='font-primary-thin text-base text-zen-dark-9 mr-2'>
                        Cap Levels
                      </p>
                      <ZenIconCircleWithTooltipCell
                        hoverComponent={
                          <div className='w-64 text-dark font-zen-body p-1 bg-white'>
                            Agent has capped in the current or previous
                            anniversary year.
                          </div>
                        }
                        onClick={() => {}}
                        icon={
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            size='sm'
                            className='text-zen-dark-7'
                          />
                        }
                        noWrap
                      />
                    </div>
                    <div>
                      <ZenControlledToggleInput<
                        CreateJointVentureFormState,
                        `tiers.${number}.hasCapLevels`
                      >
                        name={`tiers.${tierIndex}.hasCapLevels`}
                        control={control}
                        shouldUnregister={false}
                      />
                    </div>
                  </div>

                  {tier.hasCapLevels && (
                    <div className='pt-2.5'>
                      <JointVentureControlledCapInput<
                        CreateJointVentureFormState,
                        `tiers.${number}.eligibilityCriteria.capLevels`
                      >
                        name={`tiers.${tierIndex}.eligibilityCriteria.capLevels`}
                        control={control}
                        options={values(CapLevelsEnum).map((el) => ({
                          label: el,
                          value: CapLevelsToAmountMap[el],
                        }))}
                        rules={{
                          validate: () => {
                            if (
                              tiers[tierIndex].hasCapLevels &&
                              !!tiers[tierIndex].eligibilityCriteria &&
                              !tiers[tierIndex].eligibilityCriteria!.capLevels
                                ?.length
                            ) {
                              return "Cap Levels is required. Please toggle it if you don't need it.";
                            }
                            return undefined;
                          },
                        }}
                        shouldUnregister={false}
                      />
                    </div>
                  )}
                </div>

                <div className='flex justify-between items-center'>
                  <div className='flex-grow font-primary-thin text-base text-zen-dark-9'>
                    <p>Elite</p>
                  </div>
                  <div>
                    <ZenControlledToggleInput<
                      CreateJointVentureFormState,
                      `tiers.${number}.eligibilityCriteria.achieved`
                    >
                      control={control}
                      name={`tiers.${tierIndex}.eligibilityCriteria.achieved`}
                      shouldUnregister={false}
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr className='h-px text-dark' />

            <div>
              <div className='py-5 text-lg font-primary-medium text-dark'>
                Invitation
              </div>
              <div className='flex justify-between'>
                <p className='text-base font-primary-thin text-zen-dark-9'>
                  Automatically invite eligible agents to join.
                </p>
                <div>
                  <ZenControlledToggleInput<
                    CreateJointVentureFormState,
                    `tiers.${number}.autoInvite`
                  >
                    control={control}
                    name={`tiers.${tierIndex}.autoInvite`}
                    shouldUnregister={false}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TierCard;
