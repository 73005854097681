import React from 'react';
import GeminiUserPill from '../../../components/Gemini/GeminiUserPill';

interface ReferralSelectedAgentProps {
  receivingAgentId: string;
}

const ReferralSelectedAgent: React.FC<ReferralSelectedAgentProps> = () => {
  return (
    <div>
      <div className='border-t border-dashed mx-3' />
      <div className='flex flex-row items-center justify-between py-3 mx-3'>
        <p className='text-sm text-grey-600'>Selected Agent</p>
        <div>
          <GeminiUserPill
            name='Taylor Swift'
            imageUrl=''
            fontWeight='font-normal'
          />
        </div>
      </div>
    </div>
  );
};

export default ReferralSelectedAgent;
