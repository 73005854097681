import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import GeminiCard from '../../Gemini/GeminiCard';
import ZenRoute from '../../Zen/ZenRoute';
import DoNotDisturb from './DoNotDisturb';
import ZenOutOfOffice from './OutOfOffice/ZenOutOfOffice';
import ZenWorkingHoursForm from './ZenWorkingHoursForm';

interface ZenAgentHoursProps {
  agentId: string;
}

const ZenOfficeHours: React.FC<ZenAgentHoursProps> = ({ agentId }) => {
  const agentName = useSelector(
    (state: RootState) => state.agentDetail.detailResponse.data?.fullName,
  );

  return (
    <ZenRoute title={`Office hours - ${agentName}`}>
      <div className='py-4 md:px-4 grid grid-cols-1 md:grid-cols-5 gap-6'>
        <div className='col-span-full' aria-label='do-not-disturb'>
          <DoNotDisturb agentId={agentId} />
        </div>
        <div className='md:col-span-3'>
          <GeminiCard title='Office Hours'>
            <ZenWorkingHoursForm agentId={agentId} />
          </GeminiCard>
        </div>
        <div className='md:col-span-2'>
          <GeminiCard title='Out of Office'>
            <ZenOutOfOffice agentId={agentId} />
          </GeminiCard>
        </div>
      </div>
    </ZenRoute>
  );
};

export default ZenOfficeHours;
