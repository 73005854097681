import React from 'react';

interface AnnouncementRecipientStateBubbleProps {
  state: string;
}

const AnnouncementRecipientStateBubble: React.FC<AnnouncementRecipientStateBubbleProps> = ({
  state,
}) => (
  <div className='bg-grey-100 rounded-2xl flex flex-row items-center justify-center px-3 py-1 mr-1'>
    <p
      className='font-zen-body font-medium text-zen-dark-8 text-center text-sm'
      aria-label='stateOrProvince'
    >
      {state}
    </p>
  </div>
);

export default AnnouncementRecipientStateBubble;
