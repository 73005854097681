import {
  PriceAndDateInfoRequestRepresentationTypeEnum,
  TransactionBuilderAddressStateEnum,
  TransactionBuilderResponse,
  TransactionResponseTransactionTypeEnum,
} from '../openapi/arrakis';

export const isAlbertaTransaction = (
  state: TransactionBuilderAddressStateEnum | undefined,
): boolean => {
  return state === TransactionBuilderAddressStateEnum.Alberta;
};

export const getTransactionDealTypes = (
  transactionBuilder: TransactionBuilderResponse | undefined,
  isLeaseAllowed: boolean = true,
) => {
  const TransactionDealTypes = [
    {
      label: 'Sale',
      value: TransactionResponseTransactionTypeEnum.Sale,
      disabled: false,
    },
  ];

  if (isLeaseAllowed) {
    TransactionDealTypes.push({
      label: 'Lease',
      value: TransactionResponseTransactionTypeEnum.Lease,
      disabled: isAlbertaTransaction(transactionBuilder?.address?.state),
    });
  }

  return TransactionDealTypes;
};

export const getSaleTransactionTypeOptions = (
  builtFromTransactionId: string,
) => {
  let select = [
    {
      label: 'Seller',
      value: PriceAndDateInfoRequestRepresentationTypeEnum.Seller,
    },
    {
      label: 'Dual (Seller & Buyer)',
      value: PriceAndDateInfoRequestRepresentationTypeEnum.Dual,
    },
  ];
  if (!builtFromTransactionId) {
    select.splice(1, 0, {
      label: 'Buyer',
      value: PriceAndDateInfoRequestRepresentationTypeEnum.Buyer,
    });
  }
  return select;
};

export const getLeaseTransactionTypeOptions = (
  builtFromTransactionId: string,
) => {
  let options = [
    {
      label: 'Landlord',
      value: PriceAndDateInfoRequestRepresentationTypeEnum.Landlord,
    },
    {
      label: 'Dual (Landlord & Tenant)',
      value: PriceAndDateInfoRequestRepresentationTypeEnum.Dual,
    },
  ];

  if (!builtFromTransactionId) {
    options.splice(0, 0, {
      label: 'Tenant',
      value: PriceAndDateInfoRequestRepresentationTypeEnum.Tenant,
    });
  }

  return options;
};
