import {
  Controller,
  FieldValues,
  UseControllerProps,
  FieldPath,
} from 'react-hook-form-v7';
import { cn } from '../utils/classUtils';
import { FormFieldTooltipIndexProps } from '../types';
import FormErrorMessage from './FormErrorMessage';

export interface ControlledWebsiteURLTextInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  type?: 'text';
  inputMode?: 'text';
  label?: string;
  leftWebsiteSegment?: string;
  readOnly?: boolean;
  placeholder?: string;
}

const ControlledWebsiteURLTextInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  type = 'text',
  inputMode = 'text',
  leftWebsiteSegment,
  readOnly,
  placeholder,
  tooltipIndex,
  shouldUnregister = true,
  ...rest
}: ControlledWebsiteURLTextInputV7Props<TFieldValues, TName>) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              {label}
            </label>
          )}
          <div className='flex flex-row items-center'>
            <span className='font-primary-regular text-gray-600 text-base pr-1'>
              {leftWebsiteSegment}
            </span>
            <input
              id={name}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              type={type}
              inputMode={inputMode}
              name={name}
              readOnly={readOnly}
              placeholder={placeholder}
              className={cn(
                'appearance-none p-1.5 border-gray-200 rounded bg-white focus:outline-none focus:ring-0 w-full',
                {
                  'bg-gray-50': readOnly,
                },
              )}
              data-tooltip-index={tooltipIndex}
              aria-label={name}
            />
          </div>
          {error?.message && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledWebsiteURLTextInputV7;
