import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface DefaultUnauthorizedErrorProps {
  sectionName: string;
}

const DefaultUnauthorizedError: React.FC<DefaultUnauthorizedErrorProps> = ({
  sectionName = 'this section',
}) => {
  return (
    <div className='w-full h-full p-5 flex flex-col items-center justify-center opacity-40'>
      <FontAwesomeIcon icon={faLockKeyhole} className='text-error' size='xl' />
      <p className='font-primary-medium text-center'>
        You are not authorized to view the {sectionName}.
      </p>
    </div>
  );
};

export default DefaultUnauthorizedError;
