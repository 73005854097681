import classNames from 'classnames';
import React from 'react';
import { ReactComponent as SingleCheckIcon } from '../../../../assets/icons/single-check.svg';
import { ReactComponent as SplitCheckIcon } from '../../../../assets/icons/split-check.svg';
import { TransactionResponse } from '../../../../openapi/arrakis';
import { OfficeResponseTransactionTypeEnum } from '../../../../openapi/yenta';
import { EnumMap } from '../../../../types';
import { capitalizeEnum } from '../../../../utils/StringUtils';

interface ZenTransactionCheckTypeProps {
  transaction: TransactionResponse;
}

const ZenTransactionCheckType: React.FC<ZenTransactionCheckTypeProps> = ({
  transaction,
}) => {
  const checkType = transaction?.office?.splitCheck
    ? OfficeResponseTransactionTypeEnum.SplitCheck
    : OfficeResponseTransactionTypeEnum.SingleCheck;

  const checkTypeToStyleMap: EnumMap<
    OfficeResponseTransactionTypeEnum,
    string
  > = {
    SINGLE_CHECK: 'bg-regent-200 text-primary-blue',
    SPLIT_CHECK: 'bg-zen-purple-light text-zen-purple-dark',
  };

  const transactionTypeIconToPillVariant: EnumMap<
    OfficeResponseTransactionTypeEnum,
    React.ReactElement
  > = {
    SINGLE_CHECK: (
      <SingleCheckIcon width={22} height={22} className='-mb-0.5' />
    ),
    SPLIT_CHECK: <SplitCheckIcon width={22} height={22} className='-mb-0.5' />,
  };

  return (
    <div
      className={classNames(
        'flex flex-row items-center justify-center rounded-md space-x-1 px-1.5 py-1 min-w-max',
        checkTypeToStyleMap[checkType],
      )}
    >
      {transactionTypeIconToPillVariant[checkType]}
      <p className='font-zen-body font-medium text-sm text-center'>
        {capitalizeEnum(checkType)}
      </p>
    </div>
  );
};

export default ZenTransactionCheckType;
