import { Priority } from 'kbar';
import { useDispatch } from 'react-redux';
import { openLeo } from '../../../slices/LeoSlice';
import useShowLeoTrigger from '../../Zen/Leo/useShowLeoTrigger';

export default function useLeoActions() {
  const dispatch = useDispatch();
  const { agentId, showLeoTrigger } = useShowLeoTrigger();
  const leoActions = showLeoTrigger
    ? [
        {
          id: 'askLeo',
          name: 'Ask Leo',
          shortcut: ['a', 'l'],
          keywords: 'ask leo',
          section: 'Leo',
          perform: () => {
            dispatch(openLeo(agentId!));
          },
          priority: Priority.NORMAL,
        },
      ]
    : [];
  return leoActions;
}
