import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { flatten, keys, values } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CellProps, Column } from 'react-table';
import AnnouncementRecipientCell from '../components/Zen/announcement/AnnouncementRecipientCell';
import AnnouncementStatusPill from '../components/Zen/announcement/AnnouncementStatusPill';
import AnnouncementTitleCell from '../components/Zen/announcement/AnnouncementTitleCell';
import PreviewAnnouncement from '../components/Zen/announcement/PreviewAnnouncement';
import CreateAnnouncementSidebarModal from '../components/Zen/Announcements/CreateAnnouncementSidebarModel';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenConfirmationModal from '../components/Zen/Modal/ZenConfirmationModal';
import ZenIconCircleWithTooltipCell from '../components/Zen/Transaction/ZenIconCircleWithTooltipCell';
import ZenButton from '../components/Zen/ZenButton';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import {
  AnnouncementDefControllerApi,
  AnnouncementDefLiteResponse,
  AnnouncementDefLiteResponseStatusEnum,
  AnnouncementDefRequest,
} from '../openapi/mercury';
import {
  saveEditAnnouncementId,
  savePreviewAnnouncementId,
  saveShowEditModal,
  updateAnnouncementStatus,
} from '../slices/AnnouncementSlice';
import { fetchAgentsInfo } from '../slices/UserIdsSlice';
import {
  EnumMap,
  PartialEnumMap,
  RootState,
  announcementDefinitionSortByType,
  announcementDefinitionSortDirectionType,
} from '../types';
import { safePartialEnumMapGet } from '../utils/EnumHelper';
import { getMercuryConfiguration } from '../utils/OpenapiConfigurationUtils';
import { fetchTeamsInfo } from '../slices/TeamSlice';

interface ZenAnnouncementRouteProps {}

export const columns: Array<Column<AnnouncementDefLiteResponse>> = [
  {
    Header: 'Title',
    accessor: 'msgTitle',
    Cell: ({ value }) => (
      <AnnouncementTitleCell
        title={value!}
        // mandatoryType={original?.mandatoryType}
      />
    ),
  },
  {
    Header: 'Created On',
    accessor: 'createdAt',
    Cell: ({ value }) => {
      if (!value) {
        return (
          <span className='font-zen-body font-normal text-sm text-zen-dark-5'>
            N/A
          </span>
        );
      }

      const date = DateTime.fromMillis(value);

      return (
        <div className='font-zen-body font-normal text-sm'>
          <div className='text-zen-dark-9'>{date?.toFormat('LL/dd/yy')}</div>
          <div className='text-zen-dark-6'>
            {date?.toFormat('hh:mm a')?.toLocaleLowerCase()}
          </div>
        </div>
      );
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => <AnnouncementStatusPill status={value!} />,
  },
  {
    Header: 'Recipients',
    accessor: 'displayCondition',
    Cell: ({ value }) => (
      <div data-testid='recipient-component'>
        <AnnouncementRecipientCell recipient={value!} />
      </div>
    ),
    disableSortBy: true,
  },
];

const ZenAnnouncementRoute: React.FC<ZenAnnouncementRouteProps> = () => {
  const dispatch = useDispatch();
  const {
    userIds: { agentById },
    team: { teamsById },
    announcement: {
      editAnnouncementId,
      previewAnnouncementId,
      showEditModal,
      loading,
    },
  } = useSelector((state: RootState) => state);

  const [announcement, setAnnouncement] = useState<
    AnnouncementDefLiteResponse | undefined
  >(undefined);

  const getTitleFromStatus: PartialEnumMap<
    AnnouncementDefLiteResponseStatusEnum,
    string
  > = {
    ACTIVE: 'Publish',
    SUSPENDED: 'Suspend',
    ARCHIVED: 'Delete',
  };

  const announcementStatus = safePartialEnumMapGet(
    getTitleFromStatus,
    announcement?.status!,
    'Publish',
  );

  const getAllAgentsDetails = useCallback(
    async (userIds: string[]) => {
      const agentIdsToFetch = userIds?.filter((id) => !agentById[id]);
      if (!!agentIdsToFetch?.length) {
        await dispatch(fetchAgentsInfo(agentIdsToFetch));
      }
    },
    [agentById, dispatch],
  );

  const getAllTeamDetails = useCallback(
    async (teamIds: string[]) => {
      const teamIdsToFetch = teamIds?.filter((id) => !teamsById[id]);
      if (!!teamIdsToFetch?.length) {
        await dispatch(fetchTeamsInfo(teamIdsToFetch));
      }
    },
    [teamsById, dispatch],
  );

  const updateStatus = async () => {
    if (!announcement) {
      return;
    }

    await dispatch(
      updateAnnouncementStatus(
        announcement?.id!,
        (announcement as unknown) as AnnouncementDefRequest,
      ),
    );
    setAnnouncement(undefined);
  };

  const columnsWithAction: Array<Column<AnnouncementDefLiteResponse>> = [
    ...columns,
    {
      Header: 'Actions',
      id: 'actions',
      Cell: ({ row: { original } }: CellProps<AnnouncementDefLiteResponse>) => {
        const isActiveAnnouncement =
          original?.status === AnnouncementDefLiteResponseStatusEnum.Active;

        return (
          <div className='flex items-center space-x-4'>
            <ZenIconCircleWithTooltipCell
              tooltipText='Preview'
              icon={
                <FontAwesomeIcon
                  icon={regular('eye')}
                  className='text-zen-dark-8'
                  aria-label='view'
                />
              }
              onClick={() => dispatch(savePreviewAnnouncementId(original.id))}
            />

            <ZenIconCircleWithTooltipCell
              tooltipText='Edit'
              icon={
                <FontAwesomeIcon
                  icon={regular('pencil')}
                  className='text-zen-dark-8'
                  aria-label='edit'
                />
              }
              onClick={() => dispatch(saveEditAnnouncementId(original.id))}
            />

            <ZenIconCircleWithTooltipCell
              tooltipText={isActiveAnnouncement ? 'Suspend' : 'Publish'}
              icon={
                <FontAwesomeIcon
                  icon={isActiveAnnouncement ? light('pause') : light('play')}
                  className='text-zen-dark-8'
                  aria-label='update-status'
                />
              }
              onClick={() =>
                setAnnouncement({
                  ...original,
                  status: isActiveAnnouncement
                    ? AnnouncementDefLiteResponseStatusEnum.Suspended
                    : AnnouncementDefLiteResponseStatusEnum.Active,
                })
              }
            />

            <ZenIconCircleWithTooltipCell
              tooltipText='Delete'
              icon={
                <FontAwesomeIcon
                  icon={regular('trash-can')}
                  className='text-zen-danger'
                  aria-label='delete'
                />
              }
              onClick={() => {
                setAnnouncement({
                  ...original,
                  status: AnnouncementDefLiteResponseStatusEnum.Archived,
                });
              }}
            />
          </div>
        );
      },
    },
  ];

  const announcementSortDirectionTypeEnum: EnumMap<
    string,
    announcementDefinitionSortDirectionType
  > = {
    asc: 'ASC',
    desc: 'DESC',
  };

  const announcementSortByTypeEnum: EnumMap<
    string,
    announcementDefinitionSortByType
  > = {
    msgTitle: ['MSG_TITLE'],
    createdAt: ['CREATED_AT'],
    status: ['STATUS'],
  };

  return (
    <ZenRoute title='Announcements'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Announcement', url: '/announcement' },
        ]}
      >
        <div className='px-4 lg:py-5'>
          <h1 className='font-zen-body text-xl text-zen-dark-9 block lg:hidden mb-4'>
            Announcement
          </h1>
          <ZenResourceIndexContainer<AnnouncementDefLiteResponse>
            header='Announcement'
            columns={columnsWithAction}
            resourceName='announcement'
            pageSize={10}
            initialSort={{ createdAt: 'desc' }}
            RightComponent={
              <ZenButton
                LeftIconComponent={
                  <FontAwesomeIcon
                    icon={faPlus}
                    className='mx-1'
                    fontSize='small'
                  />
                }
                label='Create New'
                variant='primary'
                onClick={() => dispatch(saveShowEditModal(true))}
              />
            }
            fetchData={async (req) => {
              const sortByKeys = keys(req?.sortBy)[0];
              const sortByValues = values(req?.sortBy)[0];
              const { data } = await new AnnouncementDefControllerApi(
                getMercuryConfiguration(),
              ).getDefinitions(
                undefined,
                undefined,
                undefined,
                announcementSortByTypeEnum[sortByKeys],
                announcementSortDirectionTypeEnum[sortByValues],
                req.page,
                req.pageSize,
                undefined,
              );

              const agentIds = (data?.list || [])?.map((l) => {
                const agentIdOneOfConditions = (
                  l?.displayCondition?.conditions || []
                )?.filter((c) => c?.$type === 'AgentIdOneOf');

                //@ts-ignore
                return agentIdOneOfConditions?.[0]?.ids || [];
              });

              const IdsToFetch = flatten(agentIds) || [];

              await getAllAgentsDetails(IdsToFetch);

              const teamIds = (data?.list || [])?.map((l) => {
                const agentMemberOfTeamConditions = (
                  l?.displayCondition?.conditions || []
                )?.filter((c) => c?.$type === 'AgentMemberOfTeam');

                //@ts-ignore
                return agentMemberOfTeamConditions?.[0]?.teamIds || [];
              });

              const teamIdsToFetch = flatten(teamIds) || [];

              await getAllTeamDetails(teamIdsToFetch);

              return {
                data: data?.list || [],
                total: data?.totalCount || 0,
              };
            }}
            hideFilters
          />
        </div>
        {(showEditModal || !!editAnnouncementId) && (
          <CreateAnnouncementSidebarModal
            isOpen
            announcementId={editAnnouncementId}
            onClose={() => {
              dispatch(saveEditAnnouncementId(undefined));
              dispatch(saveShowEditModal(false));
            }}
          />
        )}
        {!!previewAnnouncementId && (
          <PreviewAnnouncement announcementId={previewAnnouncementId} />
        )}
        <ZenConfirmationModal
          variant={announcementStatus === 'Publish' ? 'primary' : 'danger'}
          isOpen={!!announcement}
          onClose={() => setAnnouncement(undefined)}
          title={`${announcementStatus} Announcement`}
          subtitle='Are you sure you want perform this operation?'
          cancelButtonText='Cancel'
          confirmButtonText={announcementStatus}
          onConfirm={updateStatus}
          isSubmitting={loading}
          isDisabled={loading}
          hideIcon
        />
      </ZenPageLayout>
    </ZenRoute>
  );
};

export default ZenAnnouncementRoute;
