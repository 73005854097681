import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form-v7';
import { EMAIL_VALIDATIONS } from '../../../utils/Validations';
import { InternalControllerApi } from '../../../openapi/yenta';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import ErrorService from '../../../services/ErrorService';
import ZenSidebarModalForm from '../ZenSidebarModalForm';
import ZenControlledEmailInput from '../Input/ZenControlledEmailInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';

interface ZenEmailResetFormSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  fromEmail: string;
  toEmail: string;
}

const ZenEmailResetFormSidebarModal: React.FC<ZenEmailResetFormSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (values: FormData) => {
    try {
      await new InternalControllerApi(getYentaConfiguration()).fixEmail({
        fromEmail: values.fromEmail,
        toEmail: values.toEmail,
      });
      onClose();
      dispatch(showSuccessToast('Fixed email successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error fixing email', e, {
        data: {
          fromEmail: values.fromEmail,
          toEmail: values.toEmail,
        },
      });
      dispatch(
        showErrorToast(
          'We had a problem fixing email',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <ZenSidebarModalForm
      title='Fix Email'
      isOpen={isOpen}
      onClose={onClose}
      actionTitle='Save'
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <div className='mt-1'>
        <ZenControlledEmailInput<FormData, 'fromEmail'>
          control={control}
          label='From Email'
          name='fromEmail'
          placeholder='jane@example.com'
          rules={{
            required: 'From email is required',
            ...EMAIL_VALIDATIONS,
          }}
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledTextInput<FormData, 'toEmail'>
          control={control}
          label='To Email'
          name='toEmail'
          placeholder='jane@example.com'
          rules={{ required: 'To email is required', ...EMAIL_VALIDATIONS }}
          isRequired
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenEmailResetFormSidebarModal;
