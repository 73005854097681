interface WalletCreditComponentProps {
  isOpen: boolean;
  customerToken?: string;
  accountId?: string;
  onClose?(): void;
}

const WalletCreditComponent: React.FC<WalletCreditComponentProps> = ({
  isOpen,
  customerToken,
  accountId,
  onClose,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-60'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full scrollbar overflow-scroll py-5'>
        <div className='flex flex-col min-h-750 font-zen-body justify-center items-center w-fit z-10'>
          <div className='p-3 my-4 shadow-xl bg-white rounded-xl'>
            <span className='font-zen-body'>ACH Credit Component</span>
            {/* @ts-ignore */}
            <unit-elements-ach-credit-payment
              fee='1.5'
              customer-token={customerToken}
              account-id={accountId}
              is-auto-focus='true'
              with-plaid='false'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletCreditComponent;
