import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import HomeMiddleImage from '../../../../../assets/img/HomeMiddle.png';
import { AgentWebsiteResponse } from '../../../../../openapi/yenta';
import { saveAgentWebsiteDetails } from '../../../../../slices/AgentSlice';
import { TEXT_CONTENT_VALIDATIONS } from '../../../../../utils/Validations';
import ZenStepContentInput from '../../../../agentWebsiteOnboarding/ZenStepContentInput';
import ZenControlledTextAreaInput from '../../../../Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../../../../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../../../../Zen/ZenSidebarModalForm';

interface ZenEditFeaturedContentSidebarModalFormProps {
  isOpen: boolean;
  onClose(): void;
  agentWebsite: AgentWebsiteResponse;
}

interface FormData {
  featureOneTitle: string;
  featureOneContent: string;
  featureTwoTitle: string;
  featureTwoContent: string;
  featureThreeTitle: string;
  featureThreeContent: string;
}

const ZenEditFeaturedContentSidebarModalForm: React.FC<ZenEditFeaturedContentSidebarModalFormProps> = ({
  isOpen,
  onClose,
  agentWebsite,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    const updatedData: AgentWebsiteResponse = {
      ...agentWebsite,
      feature1Title: formData.featureOneTitle,
      feature1Content: formData.featureOneContent,
      feature2Title: formData.featureTwoTitle,
      feature2Content: formData.featureTwoContent,
      feature3Title: formData.featureThreeTitle,
      feature3Content: formData.featureThreeContent,
    };
    dispatch(saveAgentWebsiteDetails(updatedData));
    onClose();
  };

  return (
    <ZenSidebarModalForm
      title='Featured Content'
      subtitle='This content shows on the Home page below the main image.'
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Save'
      isSubmitting={isSubmitting}
    >
      <div className='flex justify-center items-center'>
        <img
          src={HomeMiddleImage}
          alt='home-middle'
          className='w-4/5 object-contain'
        />
      </div>
      <div className='mt-5'>
        <ZenStepContentInput step={7}>
          <ZenControlledTextInput<FormData, 'featureOneTitle'>
            control={control}
            name='featureOneTitle'
            label='Feature 1 title'
            subLabel='(255 characters max)'
            placeholder='Feature 1 title'
            rules={{
              required: 'Feature 1 title is required',
              ...TEXT_CONTENT_VALIDATIONS,
            }}
            defaultValue={agentWebsite.feature1Title}
            isRequired
          />
        </ZenStepContentInput>
      </div>
      <div className='mt-5'>
        <ZenStepContentInput step={8}>
          <ZenControlledTextAreaInput<FormData, 'featureOneContent'>
            control={control}
            name='featureOneContent'
            label='Feature 1 content'
            subLabel='(255 characters max)'
            rows={3}
            placeholder='Feature 1 content'
            rules={{
              required: 'Feature 1 content is required',
              ...TEXT_CONTENT_VALIDATIONS,
            }}
            defaultValue={agentWebsite.feature1Content}
            isRequired
          />
        </ZenStepContentInput>
      </div>
      <div className='mt-5'>
        <ZenStepContentInput step={9}>
          <ZenControlledTextInput<FormData, 'featureTwoTitle'>
            control={control}
            name='featureTwoTitle'
            label='Feature 2 title'
            subLabel='(255 characters max)'
            placeholder='Feature 2 title'
            rules={{
              required: 'Feature 2 title is required',
              ...TEXT_CONTENT_VALIDATIONS,
            }}
            defaultValue={agentWebsite.feature2Title}
            isRequired
          />
        </ZenStepContentInput>
      </div>
      <div className='mt-5'>
        <ZenStepContentInput step={10}>
          <ZenControlledTextAreaInput<FormData, 'featureTwoContent'>
            control={control}
            rows={3}
            name='featureTwoContent'
            label='Feature 2 content'
            subLabel='(255 characters max)'
            placeholder='Feature 2 content'
            rules={{
              required: 'Feature 2 content is required',
              ...TEXT_CONTENT_VALIDATIONS,
            }}
            defaultValue={agentWebsite.feature2Content}
            isRequired
          />
        </ZenStepContentInput>
      </div>
      <div className='mt-5'>
        <ZenStepContentInput step={11}>
          <ZenControlledTextInput<FormData, 'featureThreeTitle'>
            control={control}
            name='featureThreeTitle'
            label='Feature 3 title'
            subLabel='(255 characters max)'
            placeholder='Feature 3 title'
            rules={{
              required: 'Feature 3 title is required',
              ...TEXT_CONTENT_VALIDATIONS,
            }}
            defaultValue={agentWebsite.feature3Title}
            isRequired
          />
        </ZenStepContentInput>
      </div>
      <div className='mt-5'>
        <ZenStepContentInput step={12}>
          <ZenControlledTextAreaInput<FormData, 'featureThreeContent'>
            control={control}
            name='featureThreeContent'
            label='Feature 3 content'
            subLabel='(255 characters max)'
            rows={3}
            placeholder='Feature 3 content'
            rules={{
              required: 'Feature 3 content is required',
              ...TEXT_CONTENT_VALIDATIONS,
            }}
            defaultValue={agentWebsite.feature3Content}
            isRequired
          />
        </ZenStepContentInput>
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenEditFeaturedContentSidebarModalForm;
