import {
  TransactionSetupFormProps,
  TransactionSetupStepName,
} from '../../../containers/transaction/setup/TransactionSetupContainer';
import { YesNoType } from '../../../types';
import ControlledRadioInputV7 from '../../ControlledRadioInputV7';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';

const TransactionSetupPersonalDealStep: StepByStepComponent<
  TransactionSetupFormProps,
  TransactionSetupStepName
> = ({ form: { control } }) => {
  return (
    <div className='flex-grow flex flex-col justify-center w-full max-w-xl'>
      <div>
        <p className='text-xl font-primary-medium text-dark mb-4'>
          Is this a personal deal?
        </p>
        <ControlledRadioInputV7<TransactionSetupFormProps, 'personalDeal'>
          shouldUnregister={false}
          control={control}
          name='personalDeal'
          rules={{
            required: 'Required',
          }}
          options={[
            { label: 'Yes', value: YesNoType.YES },
            { label: 'No', value: YesNoType.NO },
          ]}
          inlineOptions
        />
        <p className='text-sm text-gray-400 mt-2'>
          A &quot;personal transaction&quot; is any transaction for which
          Licensee has an ownership interest in the subject property, whether
          through Licensee&apos;s own name and/or a spouse&apos;s name, or a
          business entity, or revocable trust that is owned and/or controlled by
          Licensee and/or Licensee&apos;s spouse.
        </p>
      </div>
    </div>
  );
};

export default TransactionSetupPersonalDealStep;
