import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BoltIcon from '../assets/icons/bolt.svg';
import { cn } from '../utils/classUtils';

export interface InstantPaymentAlertProps {
  text: string;
  border?: boolean;
  onClick?(): void;
}

const InstantPaymentAlert: React.FC<InstantPaymentAlertProps> = ({
  text,
  border = false,
  onClick,
}) => {
  return (
    <button
      className={cn(
        'bg-primary bg-opacity-30 items-center flex flex-row justify-between p-2 w-full',
        { 'border-2 rounded border-primary m-2': border },
      )}
      onClick={onClick}
    >
      <div className='flex flex-row space-x-1 items-center'>
        <img src={BoltIcon} alt='instant' className='h-4 w-4' />

        <p className='text-primary'>{text}</p>
      </div>
      <div>
        <FontAwesomeIcon
          icon={faChevronRight}
          size='sm'
          className='text-primary'
        />
      </div>
    </button>
  );
};

export default InstantPaymentAlert;
