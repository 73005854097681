import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Earning } from '../WealthManagementTypes';
import { LearnMoreTile } from './LearnMoreTile';

interface LearnMoreProps {}

const LearnMoreSection: React.FC<LearnMoreProps> = () => {
  return (
    <div className='py-2'>
      <div className='flex items-center mb-3'>
        <FontAwesomeIcon
          icon={faBullseyeArrow}
          className='text-zen-dark-13 text-[20px] font-[300] mr-3'
        />
        <p className='text-zen-dark-13 text-lg font-semibold'>Learn More</p>
      </div>
      <div className='flex overflow-x-scroll space-x-4'>
        {Object.values(Earning).map((earning) => (
          <LearnMoreTile key={earning} type={earning} />
        ))}
      </div>
    </div>
  );
};

export default LearnMoreSection;
