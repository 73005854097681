import {
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps,
} from '../../routes/MSDynamicsOnboardingRoute';
import ProgressBar from '../ProgressBar';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';

const WithMsDynamicsProgress = (
  Component: StepByStepComponent<
    MSDynamicOnboardingFormData,
    MSDynamicOnboardingSteps
  >,
): StepByStepComponent<
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps
> => (props) => {
  return (
    <>
      <div className='absolute top-0 left-0 w-full'>
        {props.progress.currentIndex > 0 ? (
          <ProgressBar
            completed={props.progress.currentIndex + 1}
            total={props.progress.lastIndex + 1}
          />
        ) : (
          <div />
        )}
      </div>
      <Component {...props} />
    </>
  );
};

export default WithMsDynamicsProgress;
