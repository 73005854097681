import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form-v7';
import { InvoiceControllerApi } from '../../../openapi/arrakis';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import ErrorService from '../../../services/ErrorService';
import ZenSidebarModalForm from '../ZenSidebarModalForm';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';

interface ZenInvoiceCancelFormSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  invoiceId: string;
}

const ZenInvoiceCancelFormSidebarModal: React.FC<ZenInvoiceCancelFormSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (values: FormData) => {
    try {
      await new InvoiceControllerApi(getArrakisConfiguration()).cancelInvoice(
        values.invoiceId,
      );
      onClose();
      dispatch(showSuccessToast('Invoice canceled successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error canceling invoice', e, {
        data: {
          invoiceId: values.invoiceId,
        },
      });
      dispatch(
        showErrorToast(
          'We had a problem canceling the invoice',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <ZenSidebarModalForm
      title='Cancel Invoice'
      isOpen={isOpen}
      onClose={() => onClose()}
      actionTitle='Save'
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <div>
        <div className='mt-5'>
          <ZenControlledTextInput<FormData, 'invoiceId'>
            label='Invoice ID'
            name='invoiceId'
            control={control}
            rules={{ required: 'Please provide an invoice ID' }}
          />
        </div>
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenInvoiceCancelFormSidebarModal;
