import InternalServer500Img from '../assets/icons/500.svg';

interface Route500Props {}

const Route500: React.FC<Route500Props> = () => {
  return (
    <div className='h-screen flex flex-col justify-center items-center -mt-10'>
      <div>
        <img src={InternalServer500Img} alt='500' />
      </div>
      <p className='text-center font-zen-body text-dark text-lg mt-4'>
        Something went wrong.
      </p>
      <p className='text-center font-zen-body text-zinc-400'>
        If this problem persists, please contact support.
      </p>
      <a
        href='/'
        className='bg-primary rounded text-white items-center p-2 my-4 mx-auto'
      >
        Go Back Home
      </a>
    </div>
  );
};

export default Route500;
