import { TransactionResponseCountryEnum } from '../../src/openapi/arrakis';
import { Session } from '../../src/openapi/leo/api';
import { EnumMap } from '../../src/types';
import config from '../config';
import { getAuthCookie } from './AuthUtils';
import { downloadFile } from './FileUtils';

export interface TransactionItem {
  id?: string | null;
  type?: string | null;
  address?: string | null;
  country?: TransactionResponseCountryEnum | null;
  price?: string | null;
  escrow_date?: string | null | null;
  listingExpirationDate?: string | null | null;
  commission?: string | null | null;
  text?: string | null;
  lifecycleState?: {
    description?: string | null;
    normalized_state?: string | null;
    state?: string | null;
  } | null;
  externalLifecycleState?: {} | null;
  isNew?: boolean | null;
  isSelected?: boolean | null;
}

export interface CourseItem {
  id?: string | null;
  title?: string | null;
  content?: string | null;
  url?: string | null;
  summary?: string | null;
  chunks?: any[];
}

export interface LeoResponseMessageContextDetails {
  category?: string | null;
  type?: string | null;
  message?: string | null;
  items?: TransactionItem[] | null;
  prompts?: {
    single_selection?: (string | null | undefined)[] | null;
    multi_selection?: (string | null | undefined)[] | null;
  } | null;
}
export enum MEDIA_ENUM {
  SHARRAN = 'SHARRAN',
  PROPERTY_LISTING_POSTER = 'PROPERTY_LISTING_POSTER',
}

export interface ListingDetails {
  id: string;
  code: string;
  address: {
    one_line: string;
  };
  price: {
    amount: number;
    currency: string;
  };
  year_built: number;
  listing_date: string;
  lifecycle_state: {
    state: string;
  };
  transaction_owner: {
    name: string;
    represents: string;
    email_address: string;
  };
}

export interface PropertyListingPosterMediaItem {
  type: MEDIA_ENUM.PROPERTY_LISTING_POSTER;
  mimetype: string;
  title: string;
  snippet: string;
  source_url: string | undefined;
  media_url: string | undefined;
  thumbnail: string;
  details: ListingDetails;
  duration: undefined;
}

export interface SharranMediaItem {
  type: MEDIA_ENUM.SHARRAN;
  mimetype: string;
  title: string;
  snippet: string;
  source_url: string | undefined;
  media_url: string | undefined;
  thumbnail: string;
  details: undefined;
  duration: number;
}

export type MediaResponse =
  | (SharranMediaItem | PropertyListingPosterMediaItem)[]
  | null;

export interface LeoResponseMessageContext {
  bot_message_id: string;
  completion: string;
  details: LeoResponseMessageContextDetails[];
  references: {
    courses: CourseItem[];
    documents: {
      id: string;
      title: string;
      content: string;
      url: string;
      summary: string | null;
      chunks: {
        id: string;
        title: string;
        content: string;
      }[];
    }[];
  };
  media: MediaResponse;
  note: string | null;
  create_ticket: boolean | null;
}

export const SampleMsgContextDetails: LeoResponseMessageContext = {
  details: [
    {
      category: null,
      type: null,
      message: null,
      items: [
        {
          id: 'idOne',
          type: null,
          address: null,
          price: null,
          escrow_date: null,
          listingExpirationDate: null,
          commission: null,
          text: null,
          lifecycleState: {
            description: null,
            normalized_state: null,
            state: null,
          },
          externalLifecycleState: null,
          isNew: null,
          isSelected: null,
        },
        {
          id: 'idTwo',
          type: null,
          address: null,
          price: null,
          escrow_date: null,
          listingExpirationDate: null,
          commission: null,
          text: null,
          lifecycleState: {
            description: null,
            normalized_state: null,
            state: null,
          },
          externalLifecycleState: null,
          isNew: null,
          isSelected: null,
        },
      ],
      // prompts: null,
      prompts: {
        single_selection: ['promptOne', null, 'promptTwo', undefined],
        multi_selection: [
          null,
          null,
          undefined,
          null,
          undefined,
          null,
          undefined,
          undefined,
          null,
        ],
      },
    },
  ],
  bot_message_id: '',
  completion: '',
  references: {
    courses: [],
    documents: [],
  },
  media: null,
  note: '',
  create_ticket: false,
};

export interface FollowUpItem {
  id: string;
}

export interface FollowUp {
  type: string;
  items: FollowUpItem[];
}

export const AbbreviatedMonthMap: EnumMap<string, string> = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sept',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec',
};

export interface SessionWithDisclaimer extends Session {
  id: string;
  disclaimer?: string;
}

export interface ContextualPrompt {
  prompt: string;
  title: string;
  handler_type: string;
}

export enum ActionType {
  Assign = 'assign',
  Unassign = 'unassign',
  Delete = 'delete',
}

export enum PathSpecificContext {
  Transaction = 'context_transaction',
  Profile = 'context_profile',
  None = 'context_none',
}

export enum VoteType {
  Upvote = 'upvote',
  Downvote = 'downvote',
}

export type ListingPosterItem = {
  listingDetails: ListingDetails;
  posterLink: string;
};

const link1 = 'https://i.imgur.com/dKgwpwp.jpeg';
const link = 'https://i.imgur.com/Yuoe5UZ.jpeg';
export const shareables: MediaResponse = [
  {
    type: MEDIA_ENUM.PROPERTY_LISTING_POSTER,
    mimetype: 'image/jpeg',
    title: '',
    snippet: '',
    thumbnail: link1,
    media_url: link,
    source_url: link,
    duration: undefined,
    details: {
      id: '12',
      code: '12',
      address: {
        one_line: '123 Main St, Anytown, USA',
      },
      price: {
        amount: 123456,
        currency: 'USD',
      },
      year_built: 2021,
      listing_date: '2021-10-01',
      lifecycle_state: {
        state: 'Active',
      },
      transaction_owner: {
        name: 'John Doe',
        represents: 'John Doe',
        email_address: 'john@mail.com',
      },
    },
  },
  {
    type: MEDIA_ENUM.PROPERTY_LISTING_POSTER,
    mimetype: 'image/jpeg',
    title: '',
    snippet: '',
    thumbnail: link1,
    media_url: link1,
    source_url: link,
    duration: undefined,
    details: {
      id: '12',
      code: '12',
      address: {
        one_line: '123 Main St, Anytown, USA',
      },
      price: {
        amount: 123456,
        currency: 'USD',
      },
      year_built: 2021,
      listing_date: '2021-10-01',
      lifecycle_state: {
        state: 'Active',
      },
      transaction_owner: {
        name: 'John Doe',
        represents: 'John Doe',
        email_address: 'john@mail.com',
      },
    },
  },
  {
    type: MEDIA_ENUM.PROPERTY_LISTING_POSTER,
    mimetype: 'image/jpeg',
    title: '',
    snippet: '',
    thumbnail: link1,
    media_url: link,
    source_url: link,
    duration: undefined,
    details: {
      id: '12',
      code: '12',
      address: {
        one_line: '123 Main St, Anytown, USA',
      },
      price: {
        amount: 123456,
        currency: 'USD',
      },
      year_built: 2021,
      listing_date: '2021-10-01',
      lifecycle_state: {
        state: 'Active',
      },
      transaction_owner: {
        name: 'John Doe',
        represents: 'John Doe',
        email_address: 'john@mail.com',
      },
    },
  },
  {
    type: MEDIA_ENUM.PROPERTY_LISTING_POSTER,
    mimetype: 'image/jpeg',
    title: '',
    snippet: '',
    thumbnail: link1,
    media_url: link,
    source_url: link,
    duration: undefined,
    details: {
      id: '12',
      code: '12',
      address: {
        one_line: '123 Main St, Anytown, USA',
      },
      price: {
        amount: 123456,
        currency: 'USD',
      },
      year_built: 2021,
      listing_date: '2021-10-01',
      lifecycle_state: {
        state: 'Active',
      },
      transaction_owner: {
        name: 'John Doe',
        represents: 'John Doe',
        email_address: 'john@mail.com',
      },
    },
  },
  {
    type: MEDIA_ENUM.PROPERTY_LISTING_POSTER,
    mimetype: 'image/jpeg',
    title: '',
    snippet: '',
    thumbnail: link1,
    media_url: link,
    source_url: link,
    duration: undefined,
    details: {
      id: '12',
      code: '12',
      address: {
        one_line: '123 Main St, Anytown, USA',
      },
      price: {
        amount: 123456,
        currency: 'USD',
      },
      year_built: 2021,
      listing_date: '2021-10-01',
      lifecycle_state: {
        state: 'Active',
      },
      transaction_owner: {
        name: 'John Doe',
        represents: 'John Doe',
        email_address: 'john@mail.com',
      },
    },
  },
  {
    type: MEDIA_ENUM.PROPERTY_LISTING_POSTER,
    mimetype: 'image/jpeg',
    title: '',
    snippet: '',
    thumbnail: link1,
    media_url: link,
    source_url: link,
    duration: undefined,
    details: {
      id: '12',
      code: '12',
      address: {
        one_line: '123 Main St, Anytown, USA',
      },
      price: {
        amount: 123456,
        currency: 'USD',
      },
      year_built: 2021,
      listing_date: '2021-10-01',
      lifecycle_state: {
        state: 'Active',
      },
      transaction_owner: {
        name: 'John Doe',
        represents: 'John Doe',
        email_address: 'john@mail.com',
      },
    },
  },
  {
    type: MEDIA_ENUM.PROPERTY_LISTING_POSTER,
    mimetype: 'image/jpeg',
    title: '',
    snippet: '',
    thumbnail: link1,
    media_url: link,
    source_url: link,
    duration: undefined,
    details: {
      id: '12',
      code: '12',
      address: {
        one_line: '123 Main St, Anytown, USA',
      },
      price: {
        amount: 123456,
        currency: 'USD',
      },
      year_built: 2021,
      listing_date: '2021-10-01',
      lifecycle_state: {
        state: 'Active',
      },
      transaction_owner: {
        name: 'John Doe',
        represents: 'John Doe',
        email_address: 'john@mail.com',
      },
    },
  },
  {
    type: MEDIA_ENUM.PROPERTY_LISTING_POSTER,
    mimetype: 'image/jpeg',
    title: '',
    snippet: '',
    thumbnail: link1,
    media_url: link,
    source_url: link,
    duration: undefined,
    details: {
      id: '12',
      code: '12',
      address: {
        one_line: '123 Main St, Anytown, USA',
      },
      price: {
        amount: 123456,
        currency: 'USD',
      },
      year_built: 2021,
      listing_date: '2021-10-01',
      lifecycle_state: {
        state: 'Active',
      },
      transaction_owner: {
        name: 'John Doe',
        represents: 'John Doe',
        email_address: 'john@mail.com',
      },
    },
  },
];

export const sharranVideos: SharranMediaItem[] = new Array(10).fill({
  type: 'SHARRAN',
  title:
    'The Real Title: The Ultimate Guide Closing Experience. The Real Title: The Ultimate Guide Closing Experience.',
  snippet:
    'Lorem ipsum dolor sit amet consectetur. Sagittis ut vitae sit mi nulla est interdum. Sit augue nascetur varius amet. Pellentesque vehicula lectus nunc habitant tellus mi. Etiam sapien tempor in pulvinar quis eleifend morbi arcu sed. Lorem ipsum dolor sit amet consectetur. Sagittis ut vitae sit mi nulla est interdum. Sit augue nascetur varius amet. Pellentesque vehicula lectus nunc habitant tellus mi. Etiam sapien tempor in pulvinar quis eleifend morbi arcu sed. Lore',
  thumbnail: '',
  source_url:
    'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
  media_url:
    'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
  duration: 90,
  mimetype: 'video/mp4',
});

export const getDownloadableImageUrl = (link: string) => {
  const imageDomain = `${config.apiBasePath.leo}/download/image/`;
  const encodedImageSrc = encodeURIComponent(link);
  return `${imageDomain}${encodedImageSrc}`;
};

export const handleLeoListingPosterDownload = async (link: string) => {
  const posterLink = getDownloadableImageUrl(link);
  await downloadFile(posterLink, 'listing-poster.png', {
    headers: new Headers({
      Authorization: `Bearer ${getAuthCookie()}`,
    }),
  });
};
