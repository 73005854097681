import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ZenCopyIcon } from '../../../assets/icons/zen/zen-copy.svg';
import {
  TransactionLinkResponse,
  TransactionLiteResponse,
} from '../../../openapi/arrakis';
import CopyToClipboardHover from '../../CopyToClipboardHover';
import NullableTextCell from '../../table/Cells/NullableTextCell';
import { isTransactionLiteResponse } from '../../../utils/TypeUtils';

interface ZenTransactionCodeCellProps {
  linkTo: string;
  transaction: TransactionLinkResponse | TransactionLiteResponse;
  hideInvoice?: boolean;
}

const ZenTransactionCodeCell: React.FC<ZenTransactionCodeCellProps> = ({
  linkTo,
  transaction,
  hideInvoice,
}) => {
  return (
    <div>
      <div className='inline-flex items-center space-x-2 border border-zen-dark-4 rounded-lg px-2'>
        <Link to={linkTo}>
          <div className='text-sm whitespace-nowrap'>{transaction?.code}</div>
        </Link>
        <CopyToClipboardHover
          value={transaction?.code!}
          copyIcon={<ZenCopyIcon className='text-zen-dark-9' />}
          copyToltipLabel='Copy Code'
        />
      </div>
      {!hideInvoice && isTransactionLiteResponse(transaction) && (
        <div className='mt-1 flex items-center space-x-1 text-zen-dark-5 text-xs'>
          <p>INVOICE:</p>
          <NullableTextCell text={transaction.commissionInvoiceNumber} />
        </div>
      )}
    </div>
  );
};

export default ZenTransactionCodeCell;
