import { AxiosError } from 'axios';
import ForceReportingError from '../errors/ForceReportingError';
import {
  TransactionLinkResponse,
  TransactionLiteResponse,
} from '../openapi/arrakis';
import {
  ApplicationStep,
  ContentContentTypeEnum,
  Question,
} from '../openapi/atlantis';
import {
  FileBlock,
  HashtagBlock,
  HyperlinkBlock,
  ImageBlock,
  MentionBlock,
  TextBlock,
  UnknownBlock,
  VoiceCallBlock,
} from '../openapi/yada';
import { MediaAudioBlock, ReferenceBlock } from '../openapi/yada/api';
import {
  TeamInvitationDto,
  ValidateGenericTeamInvitationResponse,
} from '../openapi/yenta';
import { ApiErrorResponse } from '../types';

// https://stackoverflow.com/a/58278753
// Learn more about user defined type guards here:
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
export const isEnumTypeGuardGenerator = <T>(e: T) => (
  token: any,
): token is T[keyof T] => {
  const values = Object.values(e as object); // Assert `e` as an object
  return values.includes(token as T[keyof T]);
};

export const isAxiosError = (error: any): error is AxiosError =>
  error?.isAxiosError === true;

export const isForceReportingError = (
  error: any,
): error is ForceReportingError => error?.forceReporting === true;

export const isAPICommonsError = (e: any): e is ApiErrorResponse =>
  !!e['com.real.commons.apierror.ApiError'];

export const isTextBlock = (obj: any): obj is TextBlock => obj.type === 'TEXT';

export const isImageBlock = (obj: any): obj is ImageBlock =>
  obj.type === 'IMAGE';

export const isHyperlinkBlock = (obj: any): obj is HyperlinkBlock =>
  obj.type === 'HYPERLINK';

export const isFileBlock = (obj: any): obj is FileBlock => obj.type === 'FILE';

export const isMentionBlock = (obj: any): obj is MentionBlock =>
  obj.type === 'MENTION';

export const isHashtagBlock = (obj: any): obj is HashtagBlock =>
  obj.type === 'HASHTAG';

export const isUnknownBlock = (obj: any): obj is UnknownBlock =>
  obj.type === 'UNKNOWN';

export const isReferenceBlock = (obj: any): obj is ReferenceBlock =>
  obj.type === 'REFERENCE';

export const isMediaAudioBlock = (obj: any): obj is MediaAudioBlock =>
  obj.type === 'MEDIA_AUDIO';

export const isVoiceCallBlock = (obj: any): obj is VoiceCallBlock =>
  obj.type === 'VOICE_CALL';

export const isApplicationQuestion = (
  step: ApplicationStep,
): step is ApplicationStep & { content: Question } => {
  return step?.content?.contentType === ContentContentTypeEnum.Question;
};

export const isTransactionLiteResponse = (
  obj: TransactionLinkResponse | TransactionLiteResponse,
): obj is TransactionLiteResponse => 'commissionInvoiceNumber' in obj;

export const isEmailInvite = (obj: any): obj is TeamInvitationDto => {
  return obj?.invitationId !== undefined;
};

export const isLinkInvite = (
  obj: any,
): obj is ValidateGenericTeamInvitationResponse => {
  return obj?.invitation !== undefined;
};
