import { DateTime } from 'luxon';
import {
  CheckDepositDto,
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
  TransactionResponseCountryEnum,
} from '../openapi/arrakis';
import { CheckDepositsCounter, EnumMap, onBoardingStepType } from '../types';
import { safeEnumMapGet } from './EnumHelper';

export const updateCheckDepositsRequestCounter = (
  reqCounter: CheckDepositsCounter[],
  transactionId: string,
): CheckDepositsCounter[] => {
  let updatedRequestCounter: CheckDepositsCounter[] = [];
  let isTransactionExist = !!reqCounter.find(
    (c) => c.transactionId === transactionId!,
  );
  if (isTransactionExist) {
    updatedRequestCounter = reqCounter.map((c) =>
      c.transactionId === transactionId!
        ? { ...c, counter: c.counter + 1 }
        : { ...c },
    );
  } else {
    updatedRequestCounter.push({
      counter: 1,
      transactionId: transactionId!,
    });
  }

  return updatedRequestCounter;
};

export const isUploadLimitExceed = (
  reqCounter: CheckDepositsCounter[],
  transactionId: string,
): boolean => {
  let requestCounter = reqCounter.find(
    (c) => c.transactionId === transactionId!,
  );

  return requestCounter?.counter! >= 3;
};

export const isShowDepositEnabled = (
  state: TransactionLifecycleStateValueStateEnum,
): boolean => {
  const map: EnumMap<TransactionLifecycleStateValueStateEnum, boolean> = {
    APPROVED_FOR_CLOSING: true,
    CALCULATE_LEDGER: false,
    CLOSED: true,
    COMMISSION_DOCUMENT_APPROVED: false,
    COMMISSION_DOCUMENT_GENERATED: false,
    COMMISSION_DOCUMENT_SENT: true,
    COMMISSION_VALIDATED: false,
    NEEDS_COMMISSION_VALIDATION: false,
    NEW: false,
    PAYMENT_ACCEPTED: false,
    PAYMENT_SCHEDULED: false,
    READY_FOR_COMMISSION_DOCUMENT_GENERATION: false,
    SETTLED: false,
    TERMINATED: false,
    WAITING_ON_PAYMENT: true,
    LISTING_ACTIVE: false,
    LISTING_CLOSED: true,
    LISTING_IN_CONTRACT: true,
    TERMINATION_REQUESTED: false,
  };

  return safeEnumMapGet<TransactionLifecycleStateValueStateEnum, boolean>(
    map,
    state,
    false,
  );
};

export const getTransactionClosingDate = (
  closeAt: number | undefined,
  checkDepositList: CheckDepositDto[] | undefined,
) => {
  if (closeAt) {
    return DateTime.fromMillis(closeAt).toFormat('y-LL-dd');
  }

  const closeDate = checkDepositList?.find((check) => check.transactionClosedOn)
    ?.transactionClosedOn;

  if (closeDate) {
    return DateTime.fromISO(closeDate).toFormat('y-LL-dd');
  }

  return undefined;
};

export const transactionCheckDepositPayAtClosingSteps = (
  showCertifyClosing: boolean,
) => {
  const steps: onBoardingStepType[] = [
    {
      name: "Deposit Real's Check",
    },
  ];

  if (showCertifyClosing) {
    steps.unshift({
      name: 'Certify Closing',
    });
  }
  return steps;
};

export const getMaxLimitModalSubtitle = (
  transaction: TransactionResponse | undefined,
): string => {
  const modalSubtitleMapToCountry: EnumMap<
    TransactionResponseCountryEnum,
    string
  > = {
    CANADA:
      'Sorry we were not able to capture the check information after 3 attempts. Please deposit the check at:',
    UNITED_STATES:
      'Sorry we were not able to capture the check information after 3 attempts. Please mail the check to the following address:',
  };

  if (!transaction?.country) {
    return '';
  }

  return modalSubtitleMapToCountry[transaction?.country];
};
