import { useParams } from 'react-router-dom';
import ZenRoute from '../components/Zen/ZenRoute';
import CDAForm from './CDAForm';

type Match = {
  id: string;
};

const CDAFormPdfRoute: React.FC = () => {
  const { id } = useParams() as Match;

  return (
    <ZenRoute title='CDA Form PDF'>
      <CDAForm commissionDocumentId={id} isPdf />
    </ZenRoute>
  );
};

export default CDAFormPdfRoute;
