import {
  faMoneyCheckDollar,
  faSplit,
} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { TransactionResponse } from '../../../../openapi/arrakis';
import { OfficeResponseTransactionTypeEnum } from '../../../../openapi/yenta';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import GeminiPill from '../../GeminiPill';

interface GeminiTransactionCheckTypeProps {
  transaction: TransactionResponse;
}

const GeminiTransactionCheckType: React.FC<GeminiTransactionCheckTypeProps> = ({
  transaction,
}) => {
  const checkType = transaction?.office?.splitCheck
    ? OfficeResponseTransactionTypeEnum.SplitCheck
    : OfficeResponseTransactionTypeEnum.SingleCheck;

  const isSplitCheck =
    checkType === OfficeResponseTransactionTypeEnum.SplitCheck;

  return (
    <GeminiPill
      label={capitalizeEnum(checkType)}
      icon={isSplitCheck ? faSplit : faMoneyCheckDollar}
      containerClassNames={
        isSplitCheck
          ? 'bg-purple-300 text-purple-600'
          : 'bg-rezen-light-blue-100 text-primary-navy'
      }
    />
  );
};

export default GeminiTransactionCheckType;
