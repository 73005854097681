import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faCheck,
  faClose,
} from '@fortawesome/pro-regular-svg-icons';
import { faHistory } from '@fortawesome/pro-solid-svg-icons';
import { uniq } from 'lodash';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  CellProps,
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import styled from 'styled-components';
import EmptyTray from '../assets/img/EmptyTray.png';
import AuthorizationContainer from '../components/auth/AuthorizationContainer';
import ChecklistItemCommentSection from '../components/Checklist/ChecklistItemCommentSection';
import DefaultLoader from '../components/DefaultLoader';
import Hover from '../components/Hover';
import IconButton from '../components/IconButton';
import ResourceContainer from '../components/ResourceContainer';
import Contacts from '../components/transactions/PowerAudit/Contacts';
import PDFsPreview from '../components/transactions/PowerAudit/PDFsPreview';
import PowerAuditTransactionHeader from '../components/transactions/PowerAudit/PowerAuditTransactionHeader';
import ZenChecklistFileNameCell from '../components/Zen/Checklist/ZenChecklistFileNameCell';
import ZenChecklistItemRequiredToggle from '../components/Zen/Checklist/ZenChecklistItemRequiredToggle';
import { TypeEnum } from '../components/Zen/Checklist/ZenChecklistSearchFilterInput';
import BrokerPowerAuditConfirmationModal from '../components/Zen/Modal/BrokerPowerAuditConfirmationModal';
import ZenResourceTable from '../components/Zen/Table/ZenResourceTable';
import ZenChecklistLabel from '../components/Zen/Transaction/ZenChecklistLabel';
import ZenButton from '../components/Zen/ZenButton';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenSearchBar from '../components/Zen/ZenSearchBar';
import ZenTabs from '../components/Zen/ZenTabs';
import { useFetchAsyncMappingResponse } from '../hooks/useFetchAsyncMappingResponse';
import useTransactionMentions from '../hooks/useTransactionMentions';
import { FileApi } from '../openapi/dropbox';
import {
  ChecklistApi,
  ChecklistResponse,
  ItemDtoRequiredForEnum,
  ItemDtoStatusEnum,
  ItemRequest,
  ItemResponse,
  ItemResponseStatusEnum,
} from '../openapi/sherlock';
import { RezenObjectTypeEnum } from '../openapi/yada';
import ErrorService from '../services/ErrorService';
import { fetchCheckLists, updateChecklistItem } from '../slices/CheckListSlice';
import { fetchDropbox } from '../slices/DropboxSlice';
import { setIsCollapsed } from '../slices/MainMenuSlice';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { fetchTransactionDetails } from '../slices/TransactionSlice';
import { Mapping, RootState } from '../types';
import { isV2Checklist } from '../utils/ChecklistUtils';
import { getDropboxFilesFromFileReferences } from '../utils/DropboxUtil';
import { getFileUrl } from '../utils/FileUtils';
import {
  getDropboxConfiguration,
  getSherlockConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { getFileNameFromUrl } from '../utils/StringUtils';
import { getPriorityWiseSortedData } from '../utils/TableUtils';
import { isHeic } from '../utils/TransactionHelper';

type Match = {
  transactionId: string;
};

export interface ChecklistData {
  checklistItemId?: string;
  checklistItemName?: string;
  id?: string;
  documentName?: string;
  url?: string;
  createdAt?: number;
}

const ChatWrapper = styled.div`
  .zen-prevent-overflow .ql-mention-list-container {
    left: 0 !important;
  }
`;

const BrokerPowerAudit: React.FC = () => {
  const dispatch = useDispatch();
  const { transactionId } = useParams() as Match;
  const [loadingPDF, setLoadingPDF] = useState(false);
  const history = useHistory();
  const {
    transaction: { transactionDetailResponse },
    checklist: { checklistsById },
    dropbox: { dropboxById },
    auth: { isBroker, isAdmin },
  } = useSelector((state: RootState) => state);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pdfUrls, setPdfUrls] = useState<ChecklistData[]>([]);
  const [initializeFirstPdf, setInitializeFirstPdf] = useState<boolean>(false);
  const [isRevisionRequested, setIsRevisionRequested] = useState(false);
  const [currentDoc, setCurrentDoc] = useState<ChecklistData | undefined>();
  const [currentChecklist, setCurrentChecklist] = useState<ItemResponse>();
  const [checklistFilteredDocuments, setChecklistFilteredDocuments] = useState<
    (ChecklistData[] | undefined)[]
  >([]);
  const [activeTab, setActiveTab] = useState('Details');
  const checklistId = transactionDetailResponse.data?.checklistId!;
  const { getMentions, callerGroupId } = useTransactionMentions();
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [isItemUrgent, setIsItemUrgent] = useState<Mapping<boolean>>({});
  const [isLoading, setLoading] = useState(false);

  // Checklist File Upload V2 Changes
  const isChecklistV2 = isV2Checklist(checklistsById[checklistId]?.data);
  const checklistItems = useMemo(
    () => checklistsById[checklistId]?.data?.items || [],
    [checklistId, checklistsById],
  );
  const dropboxId = transactionDetailResponse?.data?.dropboxId!;

  useEffect(() => {
    dispatch(setIsCollapsed(true));
    dispatch(fetchTransactionDetails(transactionId));
  }, [dispatch, transactionId]);

  const getCheckList = useCallback(() => {
    if (checklistId) {
      dispatch(fetchCheckLists(checklistId));
    }
  }, [checklistId, dispatch]);

  const checklistAsyncResponse = useFetchAsyncMappingResponse<ChecklistResponse>(
    {
      name: 'checklist',
      fetch: getCheckList,
      response: checklistsById[checklistId]!,
    },
  );

  const checklistDetails = checklistAsyncResponse?.data;
  const checklistItemIdMemo = useMemo(() => checklistDetails?.id, [
    checklistDetails?.id,
  ]);

  const isV2ChecklistMemo = useMemo(() => isV2Checklist(checklistDetails), [
    checklistDetails,
  ]);

  const bulkFetchDropboxDocuments = useCallback(async () => {
    setLoadingPDF(true);
    const checklistDocuments = (checklistDetails?.items || [])
      ?.map((item) => {
        const checklistItemName = item?.name!;
        const checklistItemId = item?.id!;

        if (
          item?.fileReferences?.references?.length !== 0 &&
          item?.status === ItemResponseStatusEnum.Pending
        )
          return getDropboxFilesFromFileReferences(
            item?.fileReferences?.references!,
            dropboxById!,
            dropboxId!,
          )?.map((item) => {
            const file = (dropboxById[dropboxId]?.files || [])?.find(
              (f) => f?.id === item?.id,
            );
            return {
              checklistItemId,
              checklistItemName,
              id: item?.id,
              fileVersionId: item?.currentVersion?.id!,
              documentName: item?.filename!,
              createdAt: file?.createdAt,
            };
          });

        return [];
      })
      .filter((item) => item?.length !== 0);

    setChecklistFilteredDocuments(checklistDocuments);

    const documentUrls = [];

    for (let i = 0; i < checklistDocuments[currentIndex]?.length!; i++) {
      try {
        const documentInfo = checklistDocuments[currentIndex]?.[i]!;
        const { data } = await new FileApi(
          getDropboxConfiguration(),
        ).getFileVersionUrl(documentInfo?.id!, documentInfo?.fileVersionId!);

        const fileName = getFileNameFromUrl(data) || documentInfo.documentName!;

        if (isHeic(fileName)) {
          const fileURL = await getFileUrl(data);

          documentUrls.push({
            url: fileURL,
            ...documentInfo,
          });
        } else {
          documentUrls.push({
            url: data,
            ...documentInfo,
          });
        }
      } catch (e) {
        dispatch(showErrorToast('Unable to download the file'));
        ErrorService.notify('Unable to download the checklist document', e, {
          checklistDocument: { id: checklistDocuments[currentIndex]?.[i]?.id! },
        });
      }
    }
    setLoadingPDF(false);
    setPdfUrls(documentUrls);
  }, [checklistDetails?.items, dropboxById, dropboxId, currentIndex, dispatch]);

  const bulkFetchDocuments = useCallback(async () => {
    setLoadingPDF(true);
    const checklistDocuments = (checklistDetails?.items || [])
      ?.map((item) => {
        const checklistItemName = item?.name!;
        const checklistItemId = item?.id!;

        if ((item?.documents || [])?.length !== 0)
          return item?.documents?.map((item) => {
            return {
              checklistItemId,
              checklistItemName,
              id: item?.currentVersion?.id!,
              documentName: item?.name!,
              createdAt: item?.createdAt,
            };
          });

        return [];
      })
      .filter((item) => (item || [])?.length !== 0);
    setChecklistFilteredDocuments(checklistDocuments);

    const documentUrls = [];

    for (let i = 0; i < checklistDocuments[currentIndex]?.length!; i++) {
      try {
        const documentInfo = checklistDocuments[currentIndex]?.[i]!;
        const { data } = await new ChecklistApi(
          getSherlockConfiguration(),
        ).getVersionUrl(documentInfo?.id!);

        const fileName = getFileNameFromUrl(data) || documentInfo.documentName!;

        if (isHeic(fileName)) {
          const fileURL = await getFileUrl(data);

          documentUrls.push({
            url: fileURL,
            ...documentInfo,
          });
        } else {
          documentUrls.push({
            url: data,
            ...documentInfo,
          });
        }
      } catch (e) {
        dispatch(showErrorToast('Unable to download the file'));
        ErrorService.notify('Unable to download the checklist document', e, {
          checklistDocument: { id: checklistDocuments[currentIndex]?.[i]?.id! },
        });
      }
    }
    setLoadingPDF(false);
    setPdfUrls(documentUrls);
  }, [checklistDetails?.items, currentIndex, dispatch]);

  useEffect(() => {
    if (checklistDetails?.id && currentIndex === 0 && !initializeFirstPdf) {
      if (isChecklistV2) {
        bulkFetchDropboxDocuments();
      } else {
        bulkFetchDocuments();
      }
    }
  }, [
    bulkFetchDropboxDocuments,
    bulkFetchDocuments,
    checklistDetails?.id,
    currentIndex,
    isChecklistV2,
    initializeFirstPdf,
  ]);

  useEffect(() => {
    if (checklistItemIdMemo) {
      if (isChecklistV2) {
        bulkFetchDropboxDocuments();
      } else {
        bulkFetchDocuments();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkFetchDropboxDocuments, bulkFetchDocuments]);

  const fetchAllAssociatedDropbox = useCallback(async () => {
    const checklistTemplateReferences = checklistItems
      ?.map((ele) => ele?.templateReferences?.references!)
      .flat();
    const templateReferenceDropboxIds = checklistTemplateReferences?.map(
      (ele) => ele.dropboxId!,
    );
    await Promise.all(
      uniq([...templateReferenceDropboxIds, dropboxId]).map((fileCabinetId) =>
        dispatch(fetchDropbox(fileCabinetId!)),
      ),
    );
  }, [checklistItems, dropboxId, dispatch]);

  useEffect(() => {
    if (isChecklistV2) {
      fetchAllAssociatedDropbox();
    }
  }, [fetchAllAssociatedDropbox, isChecklistV2]);

  const updateDoc = (
    checklistItemId: string,
    checklistItemName: string,
    documentName: string,
    id: string,
  ) => {
    if (currentDoc?.id !== id) {
      setCurrentDoc({
        checklistItemId,
        checklistItemName,
        documentName,
        id,
      });
      setCurrentChecklist(
        (checklistDetails?.items || [])?.find(
          (item) => item?.id === checklistItemId,
        ),
      );
    }
  };

  const initialize = useCallback(() => {
    if (
      currentDoc?.id === pdfUrls[pdfUrls.length - 1]?.id &&
      !initializeFirstPdf &&
      !!checklistDetails
    ) {
      setCurrentDoc({ ...pdfUrls[0] });
      setCurrentChecklist(
        (checklistDetails?.items || [])?.find(
          (item) => item?.id === pdfUrls[0].checklistItemId,
        ),
      );
      setInitializeFirstPdf(true);
    }
  }, [checklistDetails, currentDoc, initializeFirstPdf, pdfUrls]);

  const handleIsUrgent = useCallback(
    async (item: ItemResponse) => {
      if (!item?.id) return;

      setIsItemUrgent((map) => ({
        ...map,
        [item.id!]: true,
      }));
      const checklistItemReq: ItemRequest = {
        item: {
          ...(item || {}),
          position: item?.position,
          name: item?.name!,
          status: (item?.status as unknown) as ItemDtoStatusEnum,
          urgent: !item?.urgent,
          assignee: item?.assignee,
          required: item?.required,
          dueDate: item?.dueDate,
          labels: item?.labels,
          requiredFor: (item?.requiredFor as unknown) as ItemDtoRequiredForEnum,
        },
      };
      await dispatch(
        updateChecklistItem(checklistId, item.id!, checklistItemReq, false),
      );
      setTimeout(() => {
        setIsItemUrgent((map) => ({
          ...map,
          [item.id!]: false,
        }));
      }, 1000);
    },
    [checklistId, dispatch],
  );

  useEffect(() => {
    if (pdfUrls.length !== 0 && !!currentDoc) initialize();
  }, [currentDoc, initialize, pdfUrls]);

  const onSubmit = async (accepted: boolean) => {
    if (!currentDoc?.checklistItemId) return;

    setLoadingPDF(true);
    const checklistData = (checklistDetails?.items || [])?.find(
      (item) => item?.id === currentDoc?.checklistItemId,
    );

    const checklistItemReq: ItemRequest = {
      item: {
        ...(checklistData || {}),
        position: checklistData?.position,
        name: checklistData?.name!,
        status: accepted
          ? ItemDtoStatusEnum.Accepted
          : ItemDtoStatusEnum.RevisionRequested,
        urgent: checklistData?.urgent,
        assignee: checklistData?.assignee,
        required: checklistData?.required,
        dueDate: checklistData?.dueDate,
        labels: checklistData?.labels,
        requiredFor: (checklistData?.requiredFor as unknown) as ItemDtoRequiredForEnum,
      },
    };

    await dispatch(
      updateChecklistItem(
        checklistDetails?.id!,
        currentDoc?.checklistItemId!,
        checklistItemReq,
      ),
    );

    if (
      currentIndex > 0 &&
      currentIndex > checklistFilteredDocuments.length - 2
    ) {
      setCurrentIndex(currentIndex - 1);
    }
    setLoadingPDF(false);
  };

  useEffect(() => {
    if (checklistFilteredDocuments.length === 0) {
      setCurrentDoc(undefined);
    }
  }, [checklistFilteredDocuments]);

  useEffect(() => {
    setLoading(false);
  }, [currentChecklist, currentIndex]);

  const columns: Array<Column<ItemResponse>> = useMemo(() => {
    const checkListItems: Array<Column<ItemResponse>> = [
      {
        Header: 'Checklist Item',
        accessor: 'name',
        Cell: ({ value, row: { original } }) => (
          <Fragment>
            <ZenChecklistFileNameCell
              name={original.position + '. ' + value!}
              labels={original.labels?.map((l) => l.text!) || []}
              urgent={original.urgent || false}
              onFlagClick={() => handleIsUrgent(original)}
              isItemUrgent={isItemUrgent[original.id!]!}
              onClick={() => {
                if (currentIndex !== original.position! - 1) {
                  setLoading(true);
                  setCurrentIndex(original.position! - 1);
                  setCurrentChecklist(original);
                }
              }}
            />
            <div className='cursor-pointer mt-5 mb-1'>
              <ZenChecklistLabel
                status={original.status!}
                isRequired={original?.required!}
              />
            </div>
          </Fragment>
        ),
        sortType: (row1, row2) =>
          getPriorityWiseSortedData(
            row1.values.position + ' ' + row1.values.name,
            row2.values.position + ' ' + row2.values.name,
            [],
          ),
      },
    ];

    if (isAdmin || isBroker) {
      checkListItems.push({
        Header: (
          <span className='text-left w-[30px] whitespace-normal mr-0.5'>
            No.of Docs
          </span>
        ),
        accessor: 'documents',
        Cell: ({ row: { original } }) => {
          const isV2ChecklistItemDocVisible =
            isV2ChecklistMemo &&
            original?.status === ItemResponseStatusEnum.Pending;
          const isNoOfDocsCountVisible =
            !isV2ChecklistMemo || isV2ChecklistItemDocVisible;

          if (!isNoOfDocsCountVisible) {
            return null;
          }

          return (
            <div className='w-4 h-4 flex items-center justify-center'>
              <div
                className='h-4 w-auto flex items-center justify-center rounded-full bg-zen-dark-10'
                data-testid='no-of-docs-count'
              >
                <p className='font-zen-body text-xs text-white px-2'>
                  {isV2ChecklistMemo
                    ? (original?.fileReferences?.references || [])?.length
                    : (original?.documents || [])?.length}
                </p>
              </div>
            </div>
          );
        },
      });

      checkListItems?.push({
        Header: 'Required',
        accessor: (d) =>
          d['required'] ? TypeEnum.Required : TypeEnum.Optional,
        id: 'required',
        Cell: ({ row: { original } }: CellProps<ItemResponse, boolean>) => (
          <div className='flex row justify-center items-start'>
            <div className='w-20 -mt-10'>
              <ZenChecklistItemRequiredToggle
                checklistId={checklistId}
                checklistItem={original}
              />
            </div>
          </div>
        ),
      });
    }

    return checkListItems;
  }, [
    checklistId,
    currentIndex,
    handleIsUrgent,
    isAdmin,
    isBroker,
    isItemUrgent,
    isV2ChecklistMemo,
  ]);

  const onViewTransactionClick = () => {
    history.push(
      `/transactions/${transactionDetailResponse.data?.id}/checklist`,
    );
  };
  const filterChecklist = useMemo(() => {
    const checklist = (checklistDetails?.items || [])?.filter(
      (item) =>
        !item?.hidden &&
        item?.name?.toLowerCase().includes(search?.toLowerCase() || ''),
    );
    if (!search) {
      return checklist.map((item, index) => ({ ...item, position: index + 1 }));
    }
    return checklist;
  }, [checklistDetails?.items, search]);

  const tableInstance = useTable(
    {
      columns,
      data: filterChecklist,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      initialState: {
        pageIndex: 0,
        pageSize: 1,
      },
      // filterTypes: FIXED_TABLE_FILTER_TYPES,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    tableInstance?.setPageSize(
      !!filterChecklist?.length ? filterChecklist?.length : 1,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterChecklist?.length]);

  return (
    <ZenRoute title='Power Audit'>
      <div className='p-2 bg-zen-dark-13 flex flex-row items-center justify-between sticky top-0 z-50'>
        <div className='text-white text-center text-lg w-full'>Power Audit</div>
        <IconButton
          onClick={onViewTransactionClick}
          leftIcon={
            <FontAwesomeIcon icon={faClose} size='lg' data-testid='close' />
          }
          buttonStyle='text-white'
          variant='none'
        />
      </div>
      <AuthorizationContainer asyncResponse={transactionDetailResponse}>
        <ResourceContainer
          isEmpty={!transactionDetailResponse.data}
          loading={transactionDetailResponse.loading}
          errorCode={transactionDetailResponse.error}
          resourceName='Transaction Detail'
        >
          <div className='grid grid-flow-col grid-cols-4 overflow-hidden'>
            <div className='col-span-1 border-r h-[95vh] scrollbar overflow-y-auto'>
              <div className='p-2'>
                <div className='mb-4 my-1'>
                  <ZenSearchBar
                    placeholder='Search checklist item'
                    onChange={setSearch}
                    value={search || ''}
                  />
                </div>
                <div>
                  <ZenResourceTable<ItemResponse>
                    {...tableInstance}
                    resourceName='Checklist'
                    emptyIconComponent={
                      <img
                        src={EmptyTray}
                        alt='EmptyTrayImage'
                        className='h-20'
                      />
                    }
                    hidePagination
                    totalCount={filterChecklist?.length || 0}
                    checklistId={checklistId}
                    dropboxId={dropboxId}
                    isDropzone
                  />
                </div>
              </div>
            </div>
            <div className='relative col-span-2 z-40'>
              <div className='h-[95vh] bg-[#F3F3F3] scrollbar overflow-y-auto'>
                {checklistDetails && pdfUrls.length !== 0 && !loadingPDF && (
                  <div className='p-4 bg-grey-100 border-b border-b-zen-dark-4 flex flex-row items-center sticky top-0 z-50'>
                    <p className='text-xl'>
                      {currentDoc?.documentName} (
                      {currentDoc?.checklistItemName})
                    </p>
                  </div>
                )}

                <AuthorizationContainer asyncResponse={checklistAsyncResponse}>
                  <ResourceContainer
                    isEmpty={pdfUrls.length === 0}
                    loading={loadingPDF}
                    resourceName='Document'
                  >
                    <PDFsPreview
                      pdfUrls={pdfUrls?.sort(
                        (a, b) => b?.createdAt! - a?.createdAt!,
                      )}
                      updateDoc={updateDoc}
                    />
                  </ResourceContainer>
                </AuthorizationContainer>
                {checklistDetails && pdfUrls.length !== 0 && !loadingPDF && (
                  <div className='p-4 bg-zen-light-gray-2 flex flex-row items-center justify-around absolute bottom-0 border-t border-t-zen-dark-4 left-0 right-0 z-50'>
                    <div className='w-1/5'>
                      {currentIndex > 0 && (
                        <ZenButton
                          label='Previous'
                          variant='primary-outline'
                          isFullWidth
                          onClick={() => setCurrentIndex(currentIndex - 1)}
                        />
                      )}
                    </div>
                    <div className='flex flex-row justify-center items-center flex-grow flex-shrink w-full max-w-sm mx-auto px-4 space-x-2'>
                      <p className='font-zen-body font-semibold text-sm text-zen-dark-9'>
                        {currentDoc?.checklistItemName}
                      </p>
                      <div className='flex flex-row items-center space-x-2'>
                        <Hover
                          hoverComponent={
                            <p className='font-zen-body font-medium text-sm text-zen-dark-9 px-2'>
                              Accept
                            </p>
                          }
                          config={{ placement: 'top' }}
                        >
                          <IconButton
                            onClick={() => onSubmit(true)}
                            leftIcon={
                              <FontAwesomeIcon
                                icon={faCheck}
                                size='xl'
                                data-testid='Accept'
                                className='text-green-600'
                              />
                            }
                            buttonStyle='bg-green-100 bg-opacity-10 border border-green-600 rounded-lg hover:bg-opacity-20'
                            variant='none'
                          />
                        </Hover>
                        <Hover
                          hoverComponent={
                            <p className='font-zen-body font-medium text-sm text-zen-dark-9 px-2'>
                              Request for Revision
                            </p>
                          }
                          config={{ placement: 'top' }}
                        >
                          <IconButton
                            onClick={() => setIsRevisionRequested(true)}
                            leftIcon={
                              <FontAwesomeIcon
                                icon={faHistory}
                                data-testid='Request'
                                className='text-zen-danger'
                              />
                            }
                            buttonStyle='bg-zen-danger-light py-2 px-3 bg-opacity-10 border border-zen-danger rounded-lg hover:bg-opacity-20'
                            variant='none'
                          />
                        </Hover>
                        <BrokerPowerAuditConfirmationModal
                          isOpen={isRevisionRequested}
                          onClose={() => setIsRevisionRequested(false)}
                          onSubmit={() => onSubmit(false)}
                          checklistId={currentDoc?.checklistItemId!}
                          transaction={transactionDetailResponse.data!}
                          checklistName={currentDoc?.checklistItemName!}
                        />
                      </div>
                    </div>
                    <div className='w-1/5'>
                      <ZenButton
                        label='Next'
                        variant='primary'
                        isFullWidth
                        onClick={() => setCurrentIndex(currentIndex + 1)}
                        isDisabled={
                          currentIndex >= checklistFilteredDocuments.length! - 1
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ChatWrapper className='col-span-1 border-l static h-[95vh] overflow-x-hidden'>
              {isLoading ? (
                <DefaultLoader />
              ) : (
                <ZenTabs
                  selected={activeTab}
                  onChange={setActiveTab}
                  size='sm'
                  tabs={[
                    {
                      name: 'Details',
                      TabComponent: (
                        <div className='w-full'>
                          <PowerAuditTransactionHeader
                            transaction={transactionDetailResponse.data!}
                            checklistName={checklistDetails?.name!}
                          />
                          <div className='p-3'>
                            <ZenButton
                              RightIconComponent={
                                <FontAwesomeIcon
                                  size='lg'
                                  className='ml-1'
                                  icon={faArrowRight}
                                />
                              }
                              onClick={onViewTransactionClick}
                              label='View Transaction'
                              variant='primary-outline'
                              isFullWidth
                            />
                          </div>
                          <Contacts
                            transaction={transactionDetailResponse.data!}
                          />
                        </div>
                      ),
                    },
                    {
                      name: 'Comments',
                      TabComponent: !!currentChecklist && (
                        <div className='mt-5 px-4' aria-label='side-menu'>
                          <ChecklistItemCommentSection
                            checklistItem={currentChecklist}
                            containerType={RezenObjectTypeEnum.Transaction}
                            containerId={transactionId}
                            getMentions={getMentions}
                            callerGroupId={callerGroupId}
                            powerAudit
                          />
                          {/* <div className='bg-white bottom-0 px-3 h-20 absolute w-full' /> */}
                        </div>
                      ),
                    },
                  ]}
                />
              )}
            </ChatWrapper>
          </div>
        </ResourceContainer>
      </AuthorizationContainer>
    </ZenRoute>
  );
};
export default BrokerPowerAudit;
