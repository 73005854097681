import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface DefaultErrorProps {
  message: string;
}

const DefaultError: React.FC<DefaultErrorProps> = ({ message }) => {
  return (
    <div className='w-full h-full p-5 flex flex-col items-center justify-center'>
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        className='text-error'
        size='xl'
      />
      <p className='font-primary-medium'>{message}</p>
    </div>
  );
};

export default DefaultError;
