import React from 'react';
import { useSelector } from 'react-redux';
import { ReferralCentralReferralDto } from '../../../openapi/arrakis';
import { EnumMap, RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';

interface ReferralTransitionStatusProps {
  referralDetails: ReferralCentralReferralDto;
}
enum ReferralHeaderStatus {
  LISTING_NOT_CREATED = 'LISTING_NOT_CREATED',
  LISTING_CREATED = 'LISTING_CREATED',
  APPLIED = 'APPLIED',
  SENT = 'SENT',
}

const ReferralTransitionStatus: React.FC<ReferralTransitionStatusProps> = ({
  referralDetails,
}) => {
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  const pillStyle =
    'flex flex-row justify-center items-center space-x-0.5 rounded-full px-3 py-1 text-sm';
  const variantToClassNameMap: EnumMap<ReferralHeaderStatus, string> = {
    APPLIED: 'bg-grey-200 text-grey-600 border border-grey-200',
    SENT: 'bg-grey-200 text-grey-600 border border-grey-200',
    LISTING_NOT_CREATED:
      'bg-yellow-100 text-yellow-900 border border-yellow-100',
    LISTING_CREATED:
      'bg-green-100 text-midnight-green-900 border border-green-100',
    // PENDING_SIGNATURE: 'bg-red-100 text-red-600 border border-red-100',
  };

  const isReceivingAgent = userDetail?.id === referralDetails.receivingAgentId;
  const isReferralAgent = userDetail?.id === referralDetails.referralAgentId;
  const isApplicant = referralDetails.applicantAgentIds?.some(
    (applicant) => applicant.agentId === userDetail?.id,
  )!;

  if (
    !!referralDetails.transactionId &&
    (isReceivingAgent || isReferralAgent)
  ) {
    return (
      <div
        className={cn(
          pillStyle,
          variantToClassNameMap[ReferralHeaderStatus.LISTING_CREATED],
        )}
      >
        <span className={cn('font-inter font-medium')}>
          {capitalizeEnum(ReferralHeaderStatus.LISTING_CREATED)}
        </span>
      </div>
    );
  }

  if (!referralDetails.transactionId && (isReceivingAgent || isReferralAgent)) {
    return (
      <div
        className={cn(
          pillStyle,
          variantToClassNameMap[ReferralHeaderStatus.LISTING_NOT_CREATED],
        )}
      >
        <span className={cn('font-inter font-medium')}>
          {capitalizeEnum(ReferralHeaderStatus.LISTING_NOT_CREATED)}
        </span>
      </div>
    );
  }

  if (isApplicant && !referralDetails.transactionId) {
    return (
      <div
        className={cn(
          pillStyle,
          variantToClassNameMap[ReferralHeaderStatus.APPLIED],
        )}
      >
        <span className={cn('font-inter font-medium')}>
          {capitalizeEnum(ReferralHeaderStatus.APPLIED)}
        </span>
      </div>
    );
  }
  return null;
};
export default ReferralTransitionStatus;
