import { faPlus, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import React from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import {
  ChecklistDefinitionApi,
  DefinedConditionPairConditionNameEnum,
  DefinedConditionPairEventTypeEnum,
  EventSubscriptionEventEnum,
  ScriptConditionPairEventTypeEnum,
} from '../../../openapi/sherlock';
import ErrorService from '../../../services/ErrorService';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { AppDispatch, AsyncSelectOption } from '../../../types';
import { getSherlockConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { fetchSearchableChecklistItems } from '../../../utils/TableUtils';
import IconButton from '../../IconButton';
import ZenControlledAsyncSelectInput from '../Input/ZenControlledAsyncSelectInput';
import ZenControlledRadioInput from '../Input/ZenControlledRadioInput';
import ZenControlledSelectInput from '../Input/ZenControlledSelectInput';
import { Condition, CreateEditTriggerFormData } from './CreateEditTriggerModal';

interface CreateEditSubscriptionConditionsProps {
  subscriptionConditions: FieldArrayWithId<
    CreateEditTriggerFormData,
    'subscriptionConditions',
    'id'
  >[];
  field: any;
  index: number;
  control: Control<CreateEditTriggerFormData, object>;
  watch: UseFormWatch<CreateEditTriggerFormData>;
  setValue: UseFormSetValue<CreateEditTriggerFormData>;
  removeCondition: (index?: number | number[] | undefined) => void;
  updateConditions: (index: number, value: Partial<Condition>) => void;
}

const CreateEditSubscriptionConditions: React.FC<CreateEditSubscriptionConditionsProps> = ({
  field,
  index,
  subscriptionConditions,
  control,
  watch,
  setValue,
  removeCondition,
  updateConditions,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const checklistDefinitionId = watch(
    `subscriptionConditions.${index}.checklistDefinition`,
  );
  const checklistId = watch('checklistId');

  const isDisabled =
    watch(`subscriptionConditions.${index}.eventType`) ===
    DefinedConditionPairEventTypeEnum.Timer;

  useDidUpdateEffect(() => {
    if (isDisabled) {
      setValue(`subscriptionConditions.${index}.event`, undefined);
      setValue(
        `subscriptionConditions.${index}.checklistDefinition`,
        undefined,
      );
      setValue(`subscriptionConditions.${index}.itemDefinition`, undefined);
    } else {
      updateConditions(index, {
        eventType: DefinedConditionPairEventTypeEnum.System,
        event: undefined,
        checklistDefinition: undefined,
        itemDefinition: undefined,
        condition: watch(`subscriptionConditions.${index}.condition`),
      });
    }
  }, [isDisabled, setValue]);

  return (
    <div
      className='my-10 px-5 pb-7 border border-purple-400 rounded-lg'
      key={field.id}
    >
      <div className='relative mt-5 w-full text-center text-purple-400 text-base font-semibold'>
        Subscription-Condition {index + 1}
        {subscriptionConditions?.length > 1 && (
          <IconButton
            variant='none'
            buttonStyle='absolute top-0 right-0 bg-red-100 rounded-full'
            leftIcon={
              <FontAwesomeIcon
                icon={faTrashCan}
                size='sm'
                className='my-1 mx-0 p-0.5 text-red-600'
              />
            }
            onClick={() => removeCondition(index)}
          />
        )}
      </div>

      <div className='w-full mt-6'>
        <ZenControlledRadioInput<
          CreateEditTriggerFormData,
          `subscriptionConditions.${number}.eventType`
        >
          label='Event Type'
          name={`subscriptionConditions.${index}.eventType`}
          control={control}
          options={[
            {
              label: 'System',
              value: ScriptConditionPairEventTypeEnum.System,
            },
            {
              label: 'Timer',
              value: ScriptConditionPairEventTypeEnum.Timer,
            },
          ]}
          inlineOptions
          isRequired
          shouldUnregister={false}
        />
      </div>

      <div className='w-full mt-6'>
        <ZenControlledSelectInput<
          CreateEditTriggerFormData,
          `subscriptionConditions.${number}.event`
        >
          label='Event'
          name={`subscriptionConditions.${index}.event`}
          placeholder='Select an event'
          control={control}
          options={values(EventSubscriptionEventEnum).map((event) => ({
            label: capitalizeEnum(event),
            value: event,
          }))}
          shouldUnregister={false}
          rules={{ required: !isDisabled ? 'Event is required' : undefined }}
          isRequired={!isDisabled}
          isSearchable
          disabled={isDisabled}
        />
      </div>

      <div className='w-full mt-6'>
        <ZenControlledAsyncSelectInput<
          CreateEditTriggerFormData,
          `subscriptionConditions.${number}.checklistDefinition`
        >
          control={control}
          name={`subscriptionConditions.${index}.checklistDefinition`}
          label='Checklist Definition'
          placeholder='Select a checklist definition'
          fetchData={async (search, page) => {
            try {
              const { data } = await fetchSearchableChecklistItems({
                page: page || 0,
                pageSize: 20,
                search,
                sortBy: {
                  name: 'asc',
                },
              });

              const options: AsyncSelectOption[] = data?.map((resp) => ({
                value: `${resp?.id}`,
                label: `${resp?.name}`,
              }));

              return options;
            } catch (e) {
              ErrorService.notify(
                'Unable to search checklist definitions in create trigger modal',
                e,
                {
                  search: {
                    term: search,
                    page,
                  },
                },
              );
              dispatch(
                showErrorToast(
                  'An unexpected error occurred.',
                  'We were unable to search for checklist definitions. Please try again in a few moments or contact support.',
                ),
              );
            }

            return [];
          }}
          shouldUnregister={false}
          preHighlightedValue={checklistId}
          disabled={isDisabled}
        />
      </div>

      <div className='w-full mt-6'>
        <ZenControlledAsyncSelectInput<
          CreateEditTriggerFormData,
          `subscriptionConditions.${number}.itemDefinition`
        >
          key={JSON.stringify(checklistDefinitionId?.value)}
          control={control}
          name={`subscriptionConditions.${index}.itemDefinition`}
          label='Item Definition'
          placeholder='Select an item definition'
          fetchData={async (search, page) => {
            if (page === 0) {
              const { data } = await new ChecklistDefinitionApi(
                getSherlockConfiguration(),
              ).getChecklistDefinitionById(checklistDefinitionId?.value!);

              if (data) {
                const options: AsyncSelectOption[] = (data?.items || [])?.map(
                  (resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.name}`,
                  }),
                );

                const filterOptions = (data?.items || [])
                  ?.filter(
                    (item) =>
                      item?.name
                        ?.toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1,
                  )
                  .map((res) => ({
                    value: `${res.id}`,
                    label: `${res.name}`,
                  }));

                return search ? filterOptions : options;
              }
            }

            return [];
          }}
          disabled={isDisabled || !checklistDefinitionId?.value}
          shouldUnregister={false}
        />
      </div>

      <div className='mt-10 mb-4 flex items-center justify-center w-full h-[1px] bg-grey-400'>
        <div className='w-7 h-7 shrink-0 mr-2 flex justify-center items-center rounded-full border border-grey-400 bg-white overflow-hidden'>
          <FontAwesomeIcon icon={faPlus} className='text-grey-400' />
        </div>
      </div>

      <div className='w-full mt-6'>
        <ZenControlledSelectInput<
          CreateEditTriggerFormData,
          `subscriptionConditions.${number}.condition`
        >
          label='Condition'
          name={`subscriptionConditions.${index}.condition`}
          placeholder='Select a condition'
          control={control}
          options={values(DefinedConditionPairConditionNameEnum).map(
            (event) => ({
              label: capitalizeEnum(event),
              value: event,
            }),
          )}
          shouldUnregister={false}
          rules={{ required: 'Condition is required' }}
          isSearchable
          isRequired
        />
      </div>
    </div>
  );
};

export default CreateEditSubscriptionConditions;
