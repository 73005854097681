import React from 'react';
import { ReactComponent as ShakeHandsIcon } from '../../../../assets/img/zen/transactionHeader/handshake.svg';

interface ZenTransactionPersonalDealLabelProps {}

const ZenTransactionPersonalDealLabel: React.FC<ZenTransactionPersonalDealLabelProps> = () => {
  return (
    <div className='bg-green-100 text-green-600 w-32 rounded-lg'>
      <div className='flex flex-row justify-center items-center p-1'>
        <ShakeHandsIcon width={24} height={24} className='text-green-600' />
        <p className='font-zen-body font-medium text-sm min-w-max'>
          Personal Deal
        </p>
      </div>
    </div>
  );
};

export default ZenTransactionPersonalDealLabel;
