import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeySkeleton } from '@fortawesome/pro-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'react-hook-form-v7';
import Alert from '../components/Alert';
import SubmitButton from '../components/SubmitButton';
import ZenRoute from '../components/Zen/ZenRoute';
import { ResetPasswordRequest, UserControllerApi } from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { getErrorMessage } from '../utils/ErrorUtils';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { EMAIL_VALIDATIONS } from '../utils/Validations';
import ZenControlledEmailInput from '../components/Zen/Input/ZenControlledEmailInput';

interface ResetPasswordRouteProps {}

interface FormValues extends ResetPasswordRequest {
  submit: any;
}

const ResetPasswordRoute: React.FC<ResetPasswordRouteProps> = () => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isSubmitSuccessful, errors },
    clearErrors,
  } = useForm<FormValues>();

  const onSubmit = async (values: ResetPasswordRequest) => {
    const api = new UserControllerApi(getYentaConfiguration());
    try {
      await api.resetPassword(values);
    } catch (e) {
      setError('submit', {
        message: getErrorMessage(ErrorService.getErrorCode(e)),
      });
    }
  };

  return (
    <ZenRoute title='Reset Password'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='bg-gray-100'>
          <div className='container mx-auto h-screen flex items-center justify-center'>
            <div className='bg-white lg:w-1/3 p-10 border-2 border-gray-100'>
              <div className='text-center -mt-20 mb-5'>
                <div className='inline-block rounded-full p-6 bg-dark text-white'>
                  <FontAwesomeIcon
                    icon={faKeySkeleton}
                    className='-rotate-45 -scale-x-100 px-1'
                    size='2x'
                  />
                </div>
              </div>
              <p className='font-primary-medium text-3xl text-center'>
                Reset Password
              </p>
              {isSubmitSuccessful && (
                <Alert
                  text='An email has been sent with a link to reset your password.'
                  variant='success'
                  icon={
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      size='lg'
                      className='mx-0.5'
                    />
                  }
                />
              )}
              {errors.submit && (
                <div className='mt-3'>
                  <Alert
                    text={errors.submit.message}
                    variant='error'
                    icon={
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        size='lg'
                        className='mx-0.5'
                      />
                    }
                  />
                </div>
              )}
              <div className='mt-5'>
                <ZenControlledEmailInput
                  control={control}
                  label='Enter username or email'
                  name='usernameOrEmail'
                  placeholder='Example@joinreal.com'
                  rules={{
                    ...EMAIL_VALIDATIONS,
                    required: 'Email/Username required',
                  }}
                />
              </div>
              <SubmitButton
                label='Reset'
                isSubmitting={isSubmitting}
                onClick={() => {
                  if (errors.submit) {
                    clearErrors('submit');
                  }
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </ZenRoute>
  );
};

export default ResetPasswordRoute;
