import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';

interface SelfProps {
  agentId: string | undefined;
}

const SelfOnly: React.FC<SelfProps> = ({ agentId, children }) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  let isSelf = false;
  if (userDetail && agentId) {
    isSelf = userDetail.id === agentId;
  }

  if (!isSelf) {
    return null;
  }

  // This will be released in a new version of eslint. Disabling for now.
  // https://github.com/yannickcr/eslint-plugin-react/commit/9f1d618499b23e184a628365cb5e0ea48292cd6f
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Fragment>{children}</Fragment>;
};

export default SelfOnly;
