import React from 'react';

type GeminiCardProps = {
  title: React.ReactNode;
};

const GeminiCard: React.FC<GeminiCardProps> = ({ title, children }) => {
  return (
    <div className='w-full border border-grey-300 rounded-lg'>
      <div className='py-2.5 px-3 font-inter font-medium text-primary-dark bg-grey-100 text-[15px] rounded-t-lg'>
        {title}
      </div>
      <div>{children}</div>
    </div>
  );
};

export default GeminiCard;
