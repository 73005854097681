import {
  FlexTeamDtoCdaApproverEnum,
  TeamResponseCommissionDocumentApproverEnum,
} from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import GeminiPill from '../GeminiPill';

interface CommissionDocumentApproverPillProps {
  type?:
    | TeamResponseCommissionDocumentApproverEnum
    | FlexTeamDtoCdaApproverEnum;
}

const CommissionDocumentApproverPill: React.FC<CommissionDocumentApproverPillProps> = ({
  type,
}) => {
  const containerClassNameVariantEnumMap: EnumMap<
    TeamResponseCommissionDocumentApproverEnum,
    string
  > = {
    [TeamResponseCommissionDocumentApproverEnum.AnyTeamMembers]:
      'bg-rezen-light-blue-100 text-primary-navy',
    [TeamResponseCommissionDocumentApproverEnum.TeamLeader]:
      'bg-purple-300 text-purple-600',
    [TeamResponseCommissionDocumentApproverEnum.TeamLeaderAndTeamAdmins]:
      'bg-rezen-light-blue-100 text-primary-navy',
  };

  if (!type) {
    return null;
  }

  return (
    <GeminiPill
      label={capitalizeEnum(type)}
      containerClassNames={containerClassNameVariantEnumMap[type]}
    />
  );
};

export default CommissionDocumentApproverPill;
