export const parseCsv = (data: string, fieldSep = ',', newLine = '\n') => {
  fieldSep = fieldSep || ',';
  newLine = newLine || '\n';
  const nSep = '\x1D';
  const nSepRe = new RegExp(nSep, 'g');
  const qSep = '\x1E';
  const qSepRe = new RegExp(qSep, 'g');
  const cSep = '\x1F';
  const cSepRe = new RegExp(cSep, 'g');
  const fieldRe = new RegExp(
    '(^|[' +
      fieldSep +
      '\\n])"([^"]*(?:""[^"]*)*)"(?=($|[' +
      fieldSep +
      '\\n]))',
    'g',
  );
  return data
    .replace(/\r/g, '')
    .replace(/\n+$/, '')
    .replace(fieldRe, (_, p1, p2) => {
      return (
        p1 + p2.replace(/\n/g, nSep).replace(/""/g, qSep).replace(/,/g, cSep)
      );
    })
    .split(/\n/)
    .map((line) => {
      return line
        .split(fieldSep)
        .map((cell) =>
          cell
            .replace(nSepRe, newLine)
            .replace(qSepRe, '"')
            .replace(cSepRe, ','),
        );
    });
};
