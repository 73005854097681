import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useFieldArray, useForm } from 'react-hook-form-v7';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faHouseBuilding, faTrash } from '@fortawesome/pro-solid-svg-icons';
import {
  MlsPropertyTypeMappingMlsListingTypeEnum,
  MlsPropertyTypeMappingRealPropertyTypeEnum,
  MlsResponse,
} from '../../../openapi/yenta';
import ResourceContainer from '../../ResourceContainer';
import { capitalizeEnum } from '../../../utils/StringUtils';
import {
  createOrUpdatePropertyTypeMappingUsing,
  fetchPropertyTypesWanderer,
  getPropertyTypeMapping,
} from '../../../slices/MLSSlice';
import { AppDispatch, ISelectOption, RootState } from '../../../types';
import ZenSidebarModalForm from '../../Zen/ZenSidebarModalForm';
import ZenButton from '../../Zen/ZenButton';
import ZenControlledSelectInput from '../../Zen/Input/ZenControlledSelectInput';
import ZenFormErrorMessage from '../../Zen/Input/ZenFormErrorMessage';

interface ZenMlsPropertyTypesFormProps {
  isOpen: boolean;
  onClose(): void;
  mlsDetails?: MlsResponse;
}

interface FormData {
  mlsPropertyTypeMappings: {
    mlsListingType: ISelectOption;
    mlsPropertyType: ISelectOption;
    realPropertyType: ISelectOption;
  }[];
}

const validationSchema: yup.SchemaOf<FormData> = yup.object().shape({
  mlsPropertyTypeMappings: yup
    .array()
    .of(
      yup.object().shape({
        mlsListingType: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
            disabled: yup.boolean().nullable(),
          })
          .test('Value not exist', 'MLS listing type is required', (v) => {
            return !!v.value && !!v.label;
          }),
        mlsPropertyType: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
            disabled: yup.boolean().nullable(),
          })
          .test('Value not exist', 'MLS property type is required.', (v) => {
            return !!v.value && !!v.label;
          }),
        realPropertyType: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
            disabled: yup.boolean().nullable(),
          })
          .test('Value not exist', 'Real property type is required.', (v) => {
            return !!v.value && !!v.label;
          }),
      }),
    )
    .required('Property type is required'),
});

const ZenMlsPropertyTypesForm: React.FC<ZenMlsPropertyTypesFormProps> = ({
  isOpen,
  onClose,
  mlsDetails,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    resolver: (yupResolver(validationSchema) as unknown) as Resolver<FormData>,
  });

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'mlsPropertyTypeMappings',
  });

  const {
    loadingMlsPropertyTypes,
    mlsPropertyTypes,
    mlsPropertyTypesWanderer,
  } = useSelector((state: RootState) => state.mls);

  useEffect(() => {
    dispatch(getPropertyTypeMapping(mlsDetails?.id!));
    dispatch(fetchPropertyTypesWanderer(mlsDetails?.code!));
  }, [dispatch, mlsDetails]);

  useEffect(() => {
    if (mlsPropertyTypes) {
      reset({
        mlsPropertyTypeMappings: mlsPropertyTypes.mlsPropertyTypeMappings?.map(
          (types) => {
            return {
              mlsListingType: {
                label: capitalizeEnum(types.mlsListingType!),
                value: types.mlsListingType!,
              },
              mlsPropertyType: {
                label: types.mlsPropertyType,
                value: types.mlsPropertyType,
              },
              realPropertyType: {
                label: capitalizeEnum(types.realPropertyType!),
                value: types.realPropertyType,
              },
            };
          },
        ),
      });
    }
  }, [reset, mlsPropertyTypes]);

  const onSubmit = async (data: FormData) => {
    await dispatch(
      createOrUpdatePropertyTypeMappingUsing({
        mlsId: mlsDetails?.id,
        mlsPropertyTypeMappings: data.mlsPropertyTypeMappings.map((types) => {
          return {
            mlsListingType: (types.mlsListingType
              ?.value as unknown) as MlsPropertyTypeMappingMlsListingTypeEnum,
            mlsPropertyType: types.mlsPropertyType?.value,
            realPropertyType: (types.realPropertyType
              ?.value as unknown) as MlsPropertyTypeMappingRealPropertyTypeEnum,
          };
        }),
      }),
    );
    onClose();
  };

  const mlsPropertyTypeErrorMsg = get(
    errors.mlsPropertyTypeMappings,
    'message',
  );

  const residentialMlsPropertyTypes: string[] =
    mlsPropertyTypesWanderer['residential'] || [];

  const rentalMlsPropertyTypes: string[] =
    mlsPropertyTypesWanderer['rental'] || [];

  return (
    <ZenSidebarModalForm
      title='Edit MLS Property Types'
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Save'
      isSubmitting={isSubmitting}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='pr-2'>
        <div className='flex items-center justify-between'>
          <p className='font-zen-body font-semibold text-zen-dark-9'>
            Property Types
          </p>
          <ZenButton
            variant='secondary-gray-outline'
            type='button'
            label='Add'
            LeftIconComponent={
              <FontAwesomeIcon icon={faPlus} size='sm' className='mr-1' />
            }
            onClick={() =>
              prepend(
                {
                  mlsListingType: {
                    label: capitalizeEnum(
                      MlsPropertyTypeMappingMlsListingTypeEnum.Residential,
                    ),
                    value: MlsPropertyTypeMappingMlsListingTypeEnum.Residential,
                  },
                  mlsPropertyType: {
                    label: residentialMlsPropertyTypes[0],
                    value: residentialMlsPropertyTypes[0],
                  },
                  realPropertyType: {
                    label: capitalizeEnum(
                      MlsPropertyTypeMappingRealPropertyTypeEnum.Condo,
                    ),
                    value: MlsPropertyTypeMappingRealPropertyTypeEnum.Condo,
                  },
                },
                {
                  focusName: 'mlsPropertyTypeMappings.0.mlsPropertyType',
                  shouldFocus: true,
                },
              )
            }
          />
        </div>
        {mlsPropertyTypeErrorMsg && (
          <ZenFormErrorMessage message={mlsPropertyTypeErrorMsg} />
        )}
        <div>
          <ResourceContainer
            loading={loadingMlsPropertyTypes}
            isEmpty={!fields.length}
            resourceName='property type'
            emptyIcon={<FontAwesomeIcon icon={faHouseBuilding} size='2xl' />}
          >
            {fields.map((field, index) => {
              const watchMlsListingType = watch(
                `mlsPropertyTypeMappings.${index}.mlsListingType` as const,
              );
              const isMlsListingTypeResidential =
                watchMlsListingType?.value ===
                MlsPropertyTypeMappingMlsListingTypeEnum.Residential;
              const mlsPropertyTypesOptions = isMlsListingTypeResidential
                ? residentialMlsPropertyTypes
                : rentalMlsPropertyTypes;

              return (
                <div
                  className='relative p-6 mt-4 border rounded-lg'
                  key={field.id}
                >
                  <div className='mt-4'>
                    <ZenControlledSelectInput<
                      FormData,
                      `mlsPropertyTypeMappings.${number}.mlsListingType`
                    >
                      label='MLS Listing Type'
                      control={control}
                      options={[
                        MlsPropertyTypeMappingMlsListingTypeEnum.Residential,
                        MlsPropertyTypeMappingMlsListingTypeEnum.Rental,
                      ].map((type) => ({
                        value: type,
                        label: capitalizeEnum(type),
                      }))}
                      name={
                        `mlsPropertyTypeMappings.${index}.mlsListingType` as const
                      }
                      rules={{ required: 'MLS Listing Type is required.' }}
                      shouldUnregister={false}
                      isRequired
                    />
                  </div>
                  <div className='mt-4'>
                    <ZenControlledSelectInput<
                      FormData,
                      `mlsPropertyTypeMappings.${number}.mlsPropertyType`
                    >
                      control={control}
                      label='MLS Property Type'
                      options={mlsPropertyTypesOptions.map((type) => ({
                        value: type,
                        label: capitalizeEnum(type),
                      }))}
                      name={
                        `mlsPropertyTypeMappings.${index}.mlsPropertyType` as const
                      }
                      rules={{ required: 'MLS Property Type is required.' }}
                      shouldUnregister={false}
                      isRequired
                    />
                  </div>
                  <div className='mt-4'>
                    <ZenControlledSelectInput<
                      FormData,
                      `mlsPropertyTypeMappings.${number}.realPropertyType`
                    >
                      label='Real Property Type'
                      control={control}
                      options={[
                        MlsPropertyTypeMappingRealPropertyTypeEnum.House,
                        MlsPropertyTypeMappingRealPropertyTypeEnum.Condo,
                        MlsPropertyTypeMappingRealPropertyTypeEnum.Townhouse,
                      ].map((type) => ({
                        value: type,
                        label: capitalizeEnum(type),
                      }))}
                      name={
                        `mlsPropertyTypeMappings.${index}.realPropertyType` as const
                      }
                      rules={{ required: 'Real Property Type is required.' }}
                      shouldUnregister={false}
                      isRequired
                    />
                  </div>
                  <div className='absolute right-2 top-2'>
                    <ZenButton
                      variant='secondary-gray-outline'
                      LeftIconComponent={
                        <FontAwesomeIcon
                          icon={faTrash}
                          aria-label='delete'
                          size='xs'
                        />
                      }
                      onClick={() => remove(index)}
                    />
                  </div>
                </div>
              );
            })}
          </ResourceContainer>
        </div>
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenMlsPropertyTypesForm;
