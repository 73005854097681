import classNames from 'classnames';
import { isEqual, isMatch, map, merge } from 'lodash';
import qs from 'qs';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UnpackNestedValue } from 'react-hook-form-v7';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Column,
  Filters,
  IdType,
  PluginHook,
  Row,
  SortingRule,
  TableState,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import SearchBar from '../components/SearchBar';
import useAllowSelection from '../components/table/plugins/useAllowSelection';
import TableOptions from '../components/table/TableOptions';
import TableSelectCheckbox from '../components/table/TableSelectCheckbox';
import Tabs from '../components/Tabs';
import { DEFAULT_PAGE_SIZE } from '../constants/TableConstants';
import ResourceTable, {
  ResourceTableVariant,
} from '../containers/ResourceTable';
import {
  IPaginateReq,
  IPaginateRes,
  RowSelectionConfirmType,
  RowsSelectionAction,
  SearchParam,
  TabsVariant,
  VerticalAlignmentVariant,
} from '../types';
import { isSmScreen } from '../utils/BrowserUtils';
import { PaginateMockRealAPI } from '../utils/PaginateMockRealAPI';
import { parseQueryString } from '../utils/QueryStringUtils';
import {
  customFixedFilter,
  getPageCount,
  getPaginateReqFromState,
  getSelectionOptions,
  mapToStateFilter,
  mapToStateSortBy,
} from '../utils/TableUtils';

export interface IResourceIndexTab<D> {
  name: string;
  filter?: TableResourceFilter<D>;
  hiddenColumns?: IdType<D>[];
}

export type InitialSort<T> = {
  [key in keyof T]?: 'asc' | 'desc';
};

export type TableResourceFilter<T> = {
  [key in keyof T]?: T[key] | SearchParam;
};

export type ApiHandlerMethodType = keyof PaginateMockRealAPI;

export type TableSelectionOption<D extends object> = Omit<
  RowsSelectionAction<D>,
  'disabled' | 'confirm'
> & {
  confirm?:
    | RowSelectionConfirmType<D>
    | ((data: D[]) => RowSelectionConfirmType<D>);
  isActionButtonEnabled?(data: D[]): boolean;
};

export interface TableProps<D extends object> {
  header?: string;
  tabs?: Array<IResourceIndexTab<D>>;
  tabVariant?: TabsVariant;
  defaultSelectedTabName?: string;
  columns: Array<Column<D>>;
  hiddenColumns?: Array<IdType<D>>;
  filter?: TableResourceFilter<D>;
  initialSort?: InitialSort<D>;
  search?: string;
  resourceName: string;
  fetchData?: (req: IPaginateReq<D>) => Promise<IPaginateRes<D>>;
  data?: D[];
  hideFilters?: boolean;
  hidePagination?: boolean;
  hidePageSize?: boolean;
  pageSize?: number;
  RightComponent?: React.ReactElement;
  standalone?: boolean;
  allowSelection?: boolean;
  selectionOptions?: TableSelectionOption<D>[];
  showSearch?: boolean;
  emptyIconComponent?: React.ReactElement;
  customEmptyComponent?: React.ReactElement;
  cellVerticalAlignment?: VerticalAlignmentVariant;
  renderToggleRowComponent?(d: Row<D>): React.ReactElement;
  allowSortingRows?: boolean;
  onSortEnd?(oldIndex: number, newIndex: number, item: D): void;
  pageSizeOptions?: number[];
  setCurrentTab?: React.Dispatch<React.SetStateAction<string>>;
}

export interface TableQueryParamState<T extends object> {
  filter?: Filters<T>;
  pageSize: string;
  pageIndex: string;
  sortBy: SortingRule<T>[];
  search?: string;
}

interface DataWithOptionalId extends Object {
  id?: string;
}

const ResourceIndexContainer = <D extends DataWithOptionalId>({
  header,
  tabs = [],
  tabVariant,
  defaultSelectedTabName,
  columns,
  initialSort,
  hiddenColumns = [],
  filter,
  search,
  fetchData,
  data = [],
  resourceName,
  hideFilters = false,
  hidePagination = false,
  hidePageSize = false,
  pageSize = DEFAULT_PAGE_SIZE,
  RightComponent,
  standalone = true,
  allowSelection = false,
  selectionOptions = [],
  showSearch = false,
  allowSortingRows = false,
  emptyIconComponent,
  customEmptyComponent,
  cellVerticalAlignment,
  renderToggleRowComponent,
  onSortEnd,
  pageSizeOptions,
  setCurrentTab,
}: TableProps<D>): ReactElement => {
  const isNoTabs = !tabs.length;
  const [pageCount, setPageCount] = useState(0);
  const [paginatedRes, setPaginatedRes] = useState<IPaginateRes<D>>({
    total: data?.length ?? 0,
    data,
  });
  const location = useLocation();
  const history = useHistory();
  const {
    filter: filterFromQS,
    pageSize: pageSizeFromQS,
    pageIndex: pageFromQS,
    sortBy: sortByQS,
  }: TableQueryParamState<D> = parseQueryString<TableQueryParamState<D>>(
    location.search,
  );
  const isFixedTable = !fetchData;

  const initialActiveTab = tabs.find(
    (tab) => tab.name === defaultSelectedTabName || tabs[0]?.filter,
  );

  const initialState: Partial<TableState<D>> = {
    pageIndex: !!pageFromQS ? parseInt(pageFromQS, 10) : 0,
    pageSize: !!pageSizeFromQS
      ? parseInt(pageSizeFromQS, 10)
      : hidePagination
      ? Math.max(paginatedRes.data.length, 1)
      : pageSize,
    filters: !!filterFromQS?.length
      ? filterFromQS
      : mapToStateFilter({
          ...(filter || {}),
          ...(initialActiveTab?.filter || {}),
        }),
    sortBy: !!sortByQS?.length ? sortByQS : mapToStateSortBy(initialSort),
    globalFilter: search || undefined,
    hiddenColumns,
  };

  const plugins: PluginHook<D>[] = [
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  ];

  if (allowSelection) {
    plugins.push(useAllowSelection);
  }

  let columnsToUse = useMemo(() => {
    if (!isFixedTable) return columns;

    return columns.map((column) => ({
      ...column,
      filter: customFixedFilter,
    }));
  }, [columns, isFixedTable]);

  const tableInstance = useTable<D>(
    {
      columns: columnsToUse,
      data: paginatedRes.data,
      manualGlobalFilter: !isFixedTable,
      manualFilters: !isFixedTable,
      manualPagination: !isFixedTable,
      manualSortBy: !isFixedTable,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetPage: false,
      defaultCanFilter: false,
      initialState,
      pageCount,
      getRowId: (originalRow: D, index: number) =>
        'id' in originalRow ? originalRow.id! : index.toString(),
    },
    ...plugins,
  );
  const selectedRows = tableInstance.selectedFlatRows.map(
    (flatRows) => flatRows.original,
  );
  const options = getSelectionOptions<D>(selectedRows, selectionOptions);
  const getStateFilters = () =>
    merge(
      {},
      ...map(tableInstance.state.filters, (f) => ({ [f.id]: f.value })),
    );

  const selectedTabFromQS = tabs.find(
    (tab) => !!isMatch(getStateFilters(), tab.filter || {}),
  );
  const [selectedTab, setSelectedTab] = useState(
    selectedTabFromQS?.name || initialActiveTab?.name,
  );

  const handleFetch = async (
    req: IPaginateReq<D>,
  ): Promise<IPaginateRes<D>> => {
    const res = await fetchData!(req);
    setPaginatedRes(res);
    setPageCount(getPageCount(res.total, tableInstance.state.pageSize));
    return res;
  };

  const updateStateUrl = useCallback(
    () => {
      history.replace(
        `${history.location.pathname}?${qs.stringify({
          ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
          filter: tableInstance.state.filters,
          sortBy: tableInstance.state.sortBy,
          search: tableInstance.state.globalFilter,
          pageIndex: tableInstance.state.pageIndex,
          pageSize: tableInstance.state.pageSize,
        })}`,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tableInstance.state.filters,
      tableInstance.state.globalFilter,
      tableInstance.state.pageIndex,
      tableInstance.state.pageSize,
      tableInstance.state.sortBy,
    ],
  );

  const onSelectionOptionClicked = async (
    index: number,
    formData?: UnpackNestedValue<D>,
  ) => {
    await selectionOptions[index].onAction(
      tableInstance.selectedFlatRows.map((flatRows) => flatRows.original),
      formData,
    );
    tableInstance.toggleAllRowsSelected(false);
    const req = getPaginateReqFromState(tableInstance.state);
    if (!isFixedTable) {
      await handleFetch(req);
    }
  };

  const renderTable = () => (
    <ResourceTable<D>
      {...tableInstance}
      resourceName={resourceName}
      fetchData={!isFixedTable ? handleFetch : undefined}
      variant={
        isSmScreen() ? ResourceTableVariant.CARD : ResourceTableVariant.ROW
      }
      hidePagination={hidePagination}
      hidePageSize={hidePageSize}
      emptyIconComponent={emptyIconComponent}
      customEmptyComponent={customEmptyComponent}
      cellVerticalAlignment={cellVerticalAlignment}
      renderToggleRowComponent={renderToggleRowComponent}
      allowSortingRows={allowSortingRows}
      onSortEnd={onSortEnd}
      pageSizeOptions={pageSizeOptions}
    />
  );

  const renderTableOptions = () => {
    return (
      <div
        className={classNames('flex flex-row', {
          'mb-4': !hideFilters || RightComponent,
        })}
      >
        {!hideFilters && (
          <TableOptions<D>
            tableInstance={tableInstance}
            selectionOptions={options}
            onSelectionOptionClicked={onSelectionOptionClicked}
          />
        )}
        {!isSmScreen() && RightComponent && (
          <div className='ml-3'>{RightComponent}</div>
        )}
      </div>
    );
  };

  const filterTabs = tabs.map(({ name }: IResourceIndexTab<D>) => ({
    name,
    TabComponent: renderTable(),
  }));

  const handleTabChange = (tabName: string) => {
    setSelectedTab(tabName);
    // if tab is changed then we will reset the page to 0
    tableInstance.gotoPage(0);
  };

  const handleChange = useCallback(() => {
    if (!isNoTabs) {
      const currentTab = tabs.find((t) => t.name === selectedTab);
      tableInstance.setAllFilters(
        mapToStateFilter({
          ...(getStateFilters() || {}),
          ...(currentTab?.filter || {}),
        }),
      );

      tableInstance.setHiddenColumns(currentTab?.hiddenColumns || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    handleChange();
  }, [handleChange]);

  useEffect(() => {
    if (!isNoTabs && setCurrentTab) {
      setCurrentTab(selectedTab!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    if (tableInstance.state.globalFilter !== search) {
      tableInstance.setGlobalFilter(search || undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (standalone) {
      updateStateUrl();
    }
  }, [standalone, updateStateUrl]);

  useEffect(() => {
    if (isFixedTable && !isEqual(paginatedRes.data, data)) {
      setPaginatedRes({ data, total: data.length });

      // Reset the page to the new array length.
      if (hidePagination) {
        tableInstance.setPageSize(data?.length);
      }
    }
  }, [tableInstance, data, isFixedTable, paginatedRes?.data, hidePagination]);

  return (
    <div>
      <div>
        {isSmScreen() && RightComponent && (
          <div className='flex justify-end mb-4'>{RightComponent}</div>
        )}
        <div className={classNames('flex flex-row items-center')}>
          {header && <h1 className='text-xl hidden lg:block mb-4'>{header}</h1>}
          <div className='flex flex-row items-center flex-grow justify-end space-x-5'>
            {(isSmScreen() || isNoTabs) && showSearch && (
              <div className='flex-grow md:max-w-sm mb-4 ml-auto'>
                <SearchBar
                  onChange={(text) =>
                    tableInstance.setGlobalFilter(text || undefined)
                  }
                  value={tableInstance.state.globalFilter || ''}
                />
              </div>
            )}
            {(isSmScreen() || isNoTabs) && renderTableOptions()}
          </div>
        </div>
      </div>
      {isSmScreen() && allowSelection && (
        <div className='flex flex-row items-center space-x-2 self-start py-3'>
          <TableSelectCheckbox
            {...tableInstance?.getToggleAllPageRowsSelectedProps()}
          />
          <p className='font-primary-medium uppercase text-xs text-gray-400'>
            Select All Rows
          </p>
        </div>
      )}
      {isNoTabs ? (
        renderTable()
      ) : (
        <Tabs
          onChange={handleTabChange}
          selected={selectedTab}
          tabs={filterTabs}
          RightComponent={
            <div className='hidden md:block'>
              {!isSmScreen() && showSearch && (
                <div className='w-72 mb-4'>
                  <SearchBar
                    onChange={(text) =>
                      tableInstance.setGlobalFilter(text || undefined)
                    }
                    value={tableInstance.state.globalFilter || ''}
                  />
                </div>
              )}
              {renderTableOptions()}
            </div>
          }
          variant={
            tabVariant ? tabVariant : isSmScreen() ? 'spread' : 'default'
          }
          showBorderBottom={isSmScreen()}
        />
      )}
    </div>
  );
};

export default ResourceIndexContainer;
