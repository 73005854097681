import React from 'react';
import { AgentResponse } from '../../../openapi/yenta';
import { agentsTableFetchData } from '../../../utils/TableUtils';
import GeminiDefaultEmpty from '../../GeminiDefaultEmpty';
import GeminiInformationCard from '../GeminiInformationCard';
import GeminiOfficeAgentsTable from '../GeminiOfficeAgentsTable';
import GeminiListPaginateContainer from './GeminiListPaginateContainer';

interface GeminiAgentListContainerProps {
  officeId?: string[];
  mlsId?: string[];
  boardId?: string[];
  setAgents?: React.Dispatch<React.SetStateAction<AgentResponse[]>>;
}

const GeminiAgentListContainer: React.FC<GeminiAgentListContainerProps> = ({
  officeId,
  mlsId,
  boardId,
  setAgents,
}) => {
  return (
    <GeminiInformationCard title='Agents in Office'>
      <div>
        <GeminiListPaginateContainer<AgentResponse>
          fetchData={async (req) => {
            const { data, total } = await agentsTableFetchData(
              req,
              officeId,
              mlsId,
              boardId,
            );
            setAgents?.(data);
            return { data, total };
          }}
          resourceName='agent'
          EmptyComponent={
            <GeminiDefaultEmpty message='There are no agents to display' />
          }
          searchPlaceholder='Search Agent by name or email...'
        >
          {(agents) => {
            return <GeminiOfficeAgentsTable agents={agents} />;
          }}
        </GeminiListPaginateContainer>
      </div>
    </GeminiInformationCard>
  );
};

export default GeminiAgentListContainer;
