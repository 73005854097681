import React from 'react';

interface InfoCardProps {
  title: string;
}

const ZenInfoCard: React.FC<InfoCardProps> = ({ title, children }) => {
  return (
    <div className='relative border border-gray-300'>
      <p className='absolute left-3 -top-3 bg-white font-primary-regular font-bold text-sm text-zen-dark-9 px-2 py-1'>
        {title}
      </p>
      <div className='px-4 py-8'>{children}</div>
    </div>
  );
};

export default ZenInfoCard;
