import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import ZenProcessShareworksSidebarModal from '../../../forms/ZenProcessShareworksSidebarModal';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface ZenShareWorksProps {}

const ZenShareWorks: React.FC<ZenShareWorksProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <ZenResourceLinkCard
        title='Process Shareworks'
        onClick={() => setIsOpen(true)}
        icon={light('share-nodes')}
      />
      <ZenProcessShareworksSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenShareWorks;
