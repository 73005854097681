import { FundsReleaseResponseTypeEnum } from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenPill, { ZenPillVariant } from '../../Zen/ZenPill';

export interface FundsReleaseResponseTypeCellProps {
  type: FundsReleaseResponseTypeEnum;
}

const statusToPillVariantMap: EnumMap<
  FundsReleaseResponseTypeEnum,
  ZenPillVariant
> = {
  COMMISSION: 'success',
  DEPOSIT: 'primary',
};

const ZenFundsReleaseResponseTypeCell: React.FC<FundsReleaseResponseTypeCellProps> = ({
  type,
}) => {
  return (
    <div className='inline-flex'>
      <ZenPill
        title={capitalizeEnum(type)}
        variant={statusToPillVariantMap[type]}
        border={false}
        textStyle='px-2'
      />
    </div>
  );
};

export default ZenFundsReleaseResponseTypeCell;
