import { faCircleInfo, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import {
  AgreementResponseAgreementTypeEnum,
  UserAgreementControllerApi,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import {
  fetchAgreementsByUserAndAgreementTypeAndVersionDesc,
  saveAgentNonSignedAgreementsResponse,
} from '../../../slices/AgentSlice';
import { fetchAuthUserDetail } from '../../../slices/AuthSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showSuccessToast,
  showErrorToast,
} from '../../../slices/ToastNotificationSlice';
import {
  AppDispatch,
  RootState,
  SignatureWidgetModeEnum,
} from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import Hover from '../../Hover';
import SignatureWidget from '../../SignatureWidget/SignatureWidget';

interface AgentICASignatureFormModalProps {
  onClose(): void;
}

const AgentICASignatureFormModal: React.FC<AgentICASignatureFormModalProps> = ({
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    auth: { userDetail },
    agentDetail: { agentNonSignedAgreementsResponse },
  } = useSelector((state: RootState) => state);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const token = agentNonSignedAgreementsResponse?.signeeToken;
  const agreementId = agentNonSignedAgreementsResponse?.agreementId;
  const requiredAt = agentNonSignedAgreementsResponse?.requiredAt;

  const handleSignICA = useCallback(async () => {
    setIsLoading(true);
    try {
      await new UserAgreementControllerApi(
        getYentaConfiguration(),
      ).signAgreement({
        userId: userDetail?.id!,
        agreementId: agreementId!,
      });
      await dispatch(
        fetchAgreementsByUserAndAgreementTypeAndVersionDesc(
          userDetail?.id!,
          AgreementResponseAgreementTypeEnum.Ica,
          true,
        ),
      );
      await dispatch(saveAgentNonSignedAgreementsResponse(undefined));
      await dispatch(fetchAuthUserDetail(false));
      dispatch(showSuccessToast('You have successfully signed the agreement.'));
      onClose();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      dispatch(
        showErrorToast('Unable to sign the agreement. Please try again later.'),
      );
      ErrorService.notify('Error signing the agreement', e, {
        user: { id: userDetail?.id! },
      });
    } finally {
      setIsLoading(false);
    }
  }, [agreementId, dispatch, onClose, userDetail?.id]);

  const onDocumentSigned = useCallback(async () => {
    handleSignICA();
  }, [handleSignICA]);

  const onDocumentAlreadySigned = useCallback(() => {
    handleSignICA();
  }, [handleSignICA]);

  const onShowMore = () => {
    setShowMore((prev) => !prev);
  };

  return (
    <div className='w-full lg:min-w-[65rem] relative shadow-zen-web bg-white rounded-2xl'>
      <div className='flex flex-row items-center bg-zen-light-blue rounded-t-2xl px-4 py-4'>
        <div className='flex flex-grow flex-shrink flex-col items-center justify-center'>
          <p className='font-zen-body font-semibold text-zen-dark-9 text-lg text-center'>
            We&apos;ve made enhancements to our Independent Contractor Agreement
            (ICA)
          </p>
        </div>
        <FontAwesomeIcon
          icon={faCircleXmark}
          className='text-zen-dark-5 text-2xl cursor-pointer'
          onClick={() => onClose()}
        />
      </div>
      <div className='flex flex-row items-center bg-zen-light-gray-2 px-4 md:px-8 py-4'>
        <div className='flex flex-grow flex-shrink flex-col items-center justify-center'>
          <p className='font-zen-body font-normal text-zen-dark-9 text-base text-left lg:text-center'>
            <p className='flex flex-row justify-center'>
              We kindly ask you to review and put your digital signature on the
              updated version.
              <Hover
                hoverComponent={
                  <div className='text-zen-dark-9 font-semibold text-sm font-zen-body p-2'>
                    You can return to sign later by clicking the Sign button
                    under Contracts in your Dashboard.
                  </div>
                }
                config={{
                  trigger: 'hover',
                  placement: isMobile ? 'top-end' : 'right',
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className='ml-2 text-zen-dark-9 cursor-pointer'
                />
              </Hover>
            </p>
            <p>
              This ensures uninterrupted access to all the amazing features
              reZEN has to offer!
            </p>
            {!!showMore && (
              <p className='visible lg:hidden font-semibold'>
                Note: Starting{' '}
                {DateTime.fromMillis(requiredAt || 0).toFormat('LLL dd yyyy')},
                signing the updated ICA will be mandatory to continue accessing
                your reZEN account.
              </p>
            )}
            <p className='hidden lg:block font-semibold'>
              Note: Starting{' '}
              {DateTime.fromMillis(requiredAt || 0).toFormat('LLL dd yyyy')},
              signing the updated ICA will be mandatory to continue accessing
              your reZEN account.
            </p>
            <div
              className='visible lg:hidden mt-1 cursor-pointer text-rezen-blue-600 font-semibold text-sm font-zen-body'
              onClick={() => onShowMore()}
            >
              {showMore ? 'Show Less' : 'Show More'}
            </div>
          </p>
        </div>
      </div>
      <div
        className='w-full flex flex-col bg-grey-100 h-[400px] lg:h-[550px] overflow-y-hidden rounded-b-2xl'
        aria-label='sign-ica'
      >
        <SignatureWidget
          token={token!}
          mode={SignatureWidgetModeEnum.SIGN}
          onDocumentSigned={onDocumentSigned}
          onDocumentAlreadySigned={onDocumentAlreadySigned}
          showDownload
          showWebHelperButtons
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default AgentICASignatureFormModal;
