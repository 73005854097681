import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ConveyanceLetterRevisionResponseStatusEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import {
  fetchConveyanceDocuments,
  generateConveyanceDocument,
} from '../../../slices/ConveyanceDocumentSlice';
import { CardItem, RootState } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import AdminOnly from '../../auth/AdminOnly';
import ResourceContainer from '../../ResourceContainer';
import ZenConveyanceState from '../ConveyanceDocuments/ZenConveyanceState';
import ZenCard from '../ZenCard';
import ZenCardWithItems from '../ZenCardWithItems';
import ZenView from '../ZenView';

interface ZenConveyanceDocumentsCardProps {
  transaction: TransactionResponse;
}

const ZenConveyanceDocumentsCard: React.FC<ZenConveyanceDocumentsCardProps> = ({
  transaction,
}) => {
  const {
    auth: { isAdmin },
    conveyanceDocument: { conveyancesLoading, conveyances },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const history = useHistory();

  const allConveyanceDocsAvailable = isAdmin
    ? conveyances
    : conveyances.filter(
        (c) => c.status === ConveyanceLetterRevisionResponseStatusEnum.Valid,
      );

  useEffect(() => {
    dispatch(fetchConveyanceDocuments(transaction.id!));
  }, [dispatch, transaction.id]);

  const handleRegenerateConveyanceDocuments = () => {
    dispatch(generateConveyanceDocument(transaction.id!));
  };

  let conveyanceItems: CardItem[] = [];

  allConveyanceDocsAvailable
    .slice()
    .reverse()
    .forEach((conveyance) => {
      conveyanceItems.push({
        name: 'Document Set',
        value: capitalizeEnum(conveyance.name!),
      });

      conveyanceItems.push({
        name: 'Status',
        value: <ZenConveyanceState state={conveyance.status!} />,
      });
    });

  return (
    <div className='border border-gray-200 rounded-lg md:max-h-52 scrollbar overflow-auto'>
      <ResourceContainer
        loading={conveyancesLoading}
        isEmpty={!allConveyanceDocsAvailable.length}
        resourceName='conveyance'
        EmptyComponent={
          <ZenCard title='Conveyance Documents' variant='no-border'>
            <div className='font-zen-body text-zen-gray-4 text-center flex flex-col justify-center text-base py-10'>
              <p>No conveyance documents generated yet.</p>
              <AdminOnly>
                <p>
                  Click
                  <span
                    onClick={handleRegenerateConveyanceDocuments}
                    className='text-primary-blue cursor-pointer'
                  >
                    {` Generate `}
                  </span>
                  to start.
                </p>
              </AdminOnly>
            </div>
          </ZenCard>
        }
      >
        <ZenCardWithItems
          cardVariant='no-border'
          title='Conveyance Documents'
          RightComponent={
            !!allConveyanceDocsAvailable.length ? (
              <ZenView
                onClick={() =>
                  history.push(
                    `/transactions/${transaction.id!}/conveyance-documents`,
                  )
                }
                text='View Docs'
              />
            ) : (
              <div />
            )
          }
          items={conveyanceItems}
          variant='regular'
        />
      </ResourceContainer>
    </div>
  );
};

export default ZenConveyanceDocumentsCard;
