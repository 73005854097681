import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  Column,
  Filters,
  FilterValue,
  IdType,
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useParams } from 'react-router-dom';
import EmptyTray from '../../assets/img/EmptyTray.png';
import ZenAgentFilesSearchFilterInput, {
  FormData as ZenAgentFilesSearchFilterFormData,
} from '../../components/Agent/ZenAgentFilesSearchFilterInput';
import ZenAddAgentFileForm from '../../components/Agent/ZenAddAgentFileForm';
import DetailPageLoader from '../../components/DetailPageLoader';
import ZenAgentFileNameCell from '../../components/table/Cells/ZenAgentFileNameCell';
import ZenControlledMultiSelectInput from '../../components/Zen/Input/ZenControlledMultiSelectInput';
import ZenConfirmationModal from '../../components/Zen/Modal/ZenConfirmationModal';
import ZenResourceTable from '../../components/Zen/Table/ZenResourceTable';
import ZenIconCircleWithTooltipCell from '../../components/Zen/Transaction/ZenIconCircleWithTooltipCell';
import ZenButton from '../../components/Zen/ZenButton';
import ZenResourceContainer from '../../components/Zen/ZenResourceContainer';
import { DEFAULT_PAGE_SIZE } from '../../constants/TableConstants';
import { AgentDocumentResponse } from '../../openapi/yenta';
import {
  deleteAgentFile,
  fetchAgentFiles,
  getAgentFileLink,
} from '../../slices/AgentSlice';
import { ISelectOption, RootState } from '../../types';
import { getUniqueLabelOption } from '../../utils/AgentFileUtils';
import { FIXED_TABLE_FILTER_TYPES } from '../../utils/TableUtils';
import ZenMilliDateCell from '../../components/Zen/Table/Cell/ZenMilliDateCell';
import ZenRoute from '../../components/Zen/ZenRoute';

type Match = {
  id: string;
};

interface FormData {
  labels: ISelectOption[];
}

export const columns: Array<Column<AgentDocumentResponse>> = [
  {
    Header: 'File Name',
    accessor: 'name',
    Cell: ({ value, row }) => (
      <ZenAgentFileNameCell name={value!} labels={row.original.tags || []} />
    ),
    cardColSize: 2,
  },
  {
    Header: 'Description',
    accessor: 'description',
    disableSortBy: true,
    Cell: ({ value }) => value || 'N/A',
  },
  {
    Header: 'Updated date',
    accessor: 'updatedAt',
    filter: 'dateFilter',
    Cell: ({ value }) => <ZenMilliDateCell date={value} />,
  },
  {
    Header: 'Created date',
    accessor: 'createdAt',
    filter: 'dateFilter',
    Cell: ({ value }) => <ZenMilliDateCell date={value} />,
  },
  {
    Header: 'Labels',
    accessor: 'tags',
    filter: 'multiSelectFilter',
  },
];

const ZenAgentDetailFilesRoute: React.FC = () => {
  const { id } = useParams() as Match;
  const { agentFilesResponse } = useSelector(
    (state: RootState) => state.agentDetail,
  );
  const agentFiles = agentFilesResponse.data;
  const agentFilesError = agentFilesResponse.error;
  const agentFilesLoading = agentFilesResponse.loading;

  const dispatch = useDispatch();
  const [agentFile, setAgentFile] = useState<AgentDocumentResponse>();
  const [
    agentFileToDelete,
    setAgentFileToDelete,
  ] = useState<AgentDocumentResponse>();
  const [openAgentFileForm, setOpenAgentFileForm] = useState<boolean>(false);
  const { control, watch } = useForm<FormData>({
    defaultValues: { labels: [] },
  });
  const labelsToFilter = watch('labels');
  const labelOptions: ISelectOption[] = getUniqueLabelOption(
    agentFiles?.documents || [],
  );

  const columnsWithOptions: Array<Column<AgentDocumentResponse>> = useMemo(
    () => [
      ...columns,
      {
        Header: 'Options',
        id: 'options',
        Cell: ({ row }: { row: Row<AgentDocumentResponse> }) => (
          <div className='flex items-center space-x-4'>
            <ZenIconCircleWithTooltipCell
              tooltipText='Edit'
              icon={
                <FontAwesomeIcon
                  icon={regular('pencil')}
                  className='text-zen-dark-8 text-sm'
                  title='edit'
                />
              }
              onClick={() => setAgentFile(row.original)}
            />

            <ZenIconCircleWithTooltipCell
              tooltipText='Download'
              icon={
                <FontAwesomeIcon
                  icon={regular('cloud-arrow-down')}
                  className='text-zen-dark-8 text-sm'
                  title='download'
                />
              }
              onClick={() => dispatch(getAgentFileLink(id, row.original.id!))}
            />

            <ZenIconCircleWithTooltipCell
              tooltipText='Delete'
              icon={
                <FontAwesomeIcon
                  icon={regular('trash-can')}
                  className='text-zen-danger text-sm'
                  title='delete'
                />
              }
              onClick={() => setAgentFileToDelete(row.original)}
            />
          </div>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [dispatch, id],
  );

  const tableInstance = useTable(
    {
      columns: columnsWithOptions,
      data: agentFiles?.documents || [],
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      initialState: {
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        sortBy: [{ id: 'createdAt', desc: false }],
        hiddenColumns: ['tags'],
      },
      filterTypes: FIXED_TABLE_FILTER_TYPES,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  const deleteFile = () => {
    dispatch(deleteAgentFile(id, agentFileToDelete?.id!));
    setAgentFileToDelete(undefined);
  };

  const handleOnFilter = (data: ZenAgentFilesSearchFilterFormData) => {
    const filter: Filters<AgentDocumentResponse> = [];

    if (data.fileName) {
      filter.push({ id: 'name', value: data.fileName?.trim() });
    }

    if (data.description) {
      filter.push({
        id: 'description',
        value: data.description?.trim(),
      });
    }

    if (data.created_at_start || data.created_at_end) {
      filter.push({
        id: 'createdAt',
        value: [data.created_at_start, data.created_at_end],
      });
    }

    if (data.updated_at_start || data.updated_at_end) {
      filter.push({
        id: 'updatedAt',
        value: [data.updated_at_start, data.updated_at_end],
      });
    }

    tableInstance.setAllFilters(filter);
  };

  const updateFilterForID = useCallback(
    (id: IdType<AgentDocumentResponse>, value: FilterValue) => {
      const filteredValue = tableInstance.state.filters.filter(
        (f) => f.id !== id,
      );

      if (!!value) {
        filteredValue.push({
          id: id,
          value: value,
        });
      }

      tableInstance.setAllFilters(filteredValue);
    },
    [tableInstance],
  );

  useEffect(() => {
    dispatch(fetchAgentFiles(id));
  }, [id, dispatch]);

  useEffect(() => {
    updateFilterForID(
      'tags',
      labelsToFilter.map((label) => label.value),
    );
  }, [labelsToFilter, updateFilterForID]);

  return (
    <ZenRoute title='Agent Files'>
      <ZenResourceContainer
        loading={agentFilesLoading}
        LoaderComponent={<DetailPageLoader />}
        errorCode={agentFilesError}
        isEmpty={!agentFiles}
        resourceName='AgentFile'
      >
        {!!agentFiles && (
          <div className='w-full h-auto p-6 pb-20 scrollbar overflow-auto'>
            <h3 className='text-xl font-zen-title text-zen-dark-9'>
              Agent Files
            </h3>

            <div className='flex flex-col items-center justify-between py-6 space-y-3 flex:col md:flex-row md:space-y-0 md:space-x-3'>
              <div className='flex flex-col items-center flex-grow w-full max-w-4xl space-y-3 md:flex-row md:space-y-0 md:space-x-3'>
                <ZenAgentFilesSearchFilterInput
                  onFilter={handleOnFilter}
                  onSearch={(search) => updateFilterForID('name', search)}
                  searchTitles={(agentFiles?.documents || []).map(
                    (f) => f.name!,
                  )}
                />

                <ZenControlledMultiSelectInput<FormData, 'labels'>
                  control={control}
                  name='labels'
                  placeholderLabel='Labels:'
                  placeholder='Add label...'
                  options={labelOptions}
                />
              </div>

              <ZenButton
                label='Add File'
                onClick={() => setOpenAgentFileForm(true)}
              />
            </div>

            <ZenResourceTable<AgentDocumentResponse>
              {...tableInstance}
              resourceName='Agent File'
              totalCount={agentFiles.documents?.length || 0}
              emptyIconComponent={
                <img src={EmptyTray} alt='EmptyTrayImage' className='h-20' />
              }
            />
          </div>
        )}

        {(openAgentFileForm || !!agentFile) && (
          <ZenAddAgentFileForm
            isOpen
            onClose={() => {
              setOpenAgentFileForm(false);
              setAgentFile(undefined);
            }}
            agentFile={agentFile}
            labelOptions={labelOptions}
          />
        )}

        <ZenConfirmationModal
          isOpen={!!agentFileToDelete}
          variant='danger'
          title='Delete agent file'
          cancelButtonText='No'
          confirmButtonText='Yes'
          subtitle={`This will delete "${agentFileToDelete?.name}" and associated information permanently`}
          onClose={() => setAgentFileToDelete(undefined)}
          onConfirm={deleteFile}
        />
      </ZenResourceContainer>
    </ZenRoute>
  );
};

export default ZenAgentDetailFilesRoute;
