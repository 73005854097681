import React from 'react';
import {
  BsCheckCircleFill,
  BsExclamationCircleFill,
  BsFileEarmark,
} from 'react-icons/bs';
import { FiRefreshCw } from 'react-icons/fi';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { ConveyanceLetterRevisionResponseStatusEnum } from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { cn } from '../../../utils/classUtils';

export interface ZenConveyanceStateProps {
  state?: ConveyanceLetterRevisionResponseStatusEnum;
}

const ZenConveyanceState: React.FC<ZenConveyanceStateProps> = ({ state }) => {
  const variantToClassNameMap: EnumMap<
    ConveyanceLetterRevisionResponseStatusEnum,
    string
  > = {
    [ConveyanceLetterRevisionResponseStatusEnum.Draft]: 'text-zen-dark-6',
    [ConveyanceLetterRevisionResponseStatusEnum.Error]: 'text-zen-danger',
    [ConveyanceLetterRevisionResponseStatusEnum.Invalid]: 'text-zen-danger',
    [ConveyanceLetterRevisionResponseStatusEnum.ReadyForProcessing]:
      'text-zen-warning-dark',
    [ConveyanceLetterRevisionResponseStatusEnum.Valid]: 'text-green-600',
  };

  const variantToIconMap: EnumMap<
    ConveyanceLetterRevisionResponseStatusEnum,
    React.ReactElement
  > = {
    [ConveyanceLetterRevisionResponseStatusEnum.Draft]: (
      <BsFileEarmark className='mb-0.5' />
    ),
    [ConveyanceLetterRevisionResponseStatusEnum.Error]: (
      <BsExclamationCircleFill />
    ),
    [ConveyanceLetterRevisionResponseStatusEnum.Invalid]: (
      <IoCloseCircleSharp className='text-lg' />
    ),
    [ConveyanceLetterRevisionResponseStatusEnum.ReadyForProcessing]: (
      <FiRefreshCw />
    ),
    [ConveyanceLetterRevisionResponseStatusEnum.Valid]: (
      <BsCheckCircleFill className='ml-0.5' />
    ),
  };

  if (!state) {
    return (
      <p className='font-zen-body font-medium text-sm text-zen-dark-9'>N/A</p>
    );
  }

  return (
    <div
      className={cn(
        'flex flex-row items-center font-zen-body font-medium text-sm leading-4 space-x-1',
        safeEnumMapGet(variantToClassNameMap, state!, 'text-zen-warning'),
      )}
    >
      <span className='mt-0.5'>
        {safeEnumMapGet(
          variantToIconMap,
          state!,
          <BsFileEarmark className='mb-0.5' />,
        )}
      </span>
      <span>{capitalizeEnum(state!)}</span>
    </div>
  );
};

export default ZenConveyanceState;
