import { isEqual, uniq } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAgentsInfo } from '../slices/UserIdsSlice';
import { AppDispatch, RootState } from '../types';

interface UseAgentsInfo {
  loading: boolean;
}

const useAgentsInfo = (
  agentIds: string[],
  getPublicUserInfo: boolean = false,
): UseAgentsInfo => {
  const dispatch = useDispatch<AppDispatch>();
  const ref = useRef<string[]>();
  const { agentById } = useSelector((state: RootState) => state.userIds);
  const [loading, setLoading] = useState(false);

  const agentIdsToFetch = useMemo(
    () => uniq(agentIds).filter((id) => !!id && !agentById[id]),
    [agentById, agentIds],
  );

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      await dispatch(fetchAgentsInfo(agentIdsToFetch, getPublicUserInfo));
      setLoading(false);
    }

    if (!isEqual(ref.current, agentIdsToFetch) && agentIdsToFetch.length > 0) {
      fetch();
      ref.current = agentIdsToFetch;
    }
  }, [agentIdsToFetch, dispatch, getPublicUserInfo]);

  return { loading };
};

export default useAgentsInfo;
