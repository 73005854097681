import { values } from 'lodash';
import React, { useMemo, useState } from 'react';
import { MdOutlineDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import useAgentsInfo from '../../../../hooks/useAgentsInfo';
import { useSyncWatchparticipants } from '../../../../hooks/useSyncWatchParticipants';
import {
  addOpCityInfo,
  deleteReferralInfo,
} from '../../../../slices/TransactionBuilderSlice';
import { AppDispatch, RootState, YesNoType } from '../../../../types';
import { getParticipantName } from '../../../../utils/ParticipantHelper';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledRadioInput from '../../Input/ZenControlledRadioInput';
import ZenButton from '../../ZenButton';
import { getTransactionCountry } from '../../../../utils/TransactionHelper';
import { AddressRequestCountryEnum } from '../../../../openapi/yenta';
import { cn } from '../../../../utils/classUtils';
import ZenAddExternalParticipant from './SidebarForms/ZenAddExternalParticipant';
import ZenAddRealParticipant from './SidebarForms/ZenAddRealParticipant';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
  Match,
} from './ZenCreateTransactionSteps';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';

const ZenTransactionReferralFeeStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({
  form: {
    control,
    watch,
    setValue,
    trigger,
    getValues,
    formState: { isValidating },
  },
  onPrevious,
  onNext,
}) => {
  const {
    transactionBuilder: { transactionBuilder },
  } = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();
  const { transactionBuilderId } = useParams<Match>();
  const { agentById } = useSelector((state: RootState) => state.userIds);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    openExternalParticipantFormSidebarModal,
    setOpenExternalParticipantFormSidebarModal,
  ] = useState<boolean>(false);
  const [
    openRealParticipantFormSidebarModal,
    setOpenRealParticipantFormSidebarModal,
  ] = useState<boolean>(false);
  const [isOpCityLoading, setIsOpCityLoading] = useState<boolean>(false);
  const [isDeletingReferralInfo, setIsDeletingReferralInfo] = useState<boolean>(
    false,
  );
  const [referralFee, address, location] = watch([
    'referralFee',
    'address',
    'location',
  ]);
  const isUSTransaction =
    getTransactionCountry(address, location) ===
    AddressRequestCountryEnum.UnitedStates;

  const handleOnNext = async () => {
    const isValid = await trigger();
    if (referralFee === YesNoType.NO) {
      const allReferralParticipantInfo =
        transactionBuilder?.referralInfo?.allReferralParticipantInfo || [];
      setIsLoading(true);
      for (let participant of allReferralParticipantInfo) {
        await dispatch(
          deleteReferralInfo(transactionBuilder?.id!, participant?.id!),
        );
      }
      setIsLoading(false);
    }

    if (isValid) {
      onNext();
    }
  };

  useSyncWatchparticipants(
    agentById,
    setValue,
    transactionBuilder?.allCommissionRecipient,
    transactionBuilder?.commissionSplitsInfo,
  );

  const agentIds = useMemo(
    () =>
      transactionBuilder?.referralInfo?.allReferralParticipantInfo
        // @ts-ignore
        ?.map((p) => p.agentId!)
        .filter((p) => !!p) || [],
    [transactionBuilder?.referralInfo?.allReferralParticipantInfo],
  );

  useAgentsInfo(agentIds);

  const isOpCityReferralAvailable = (
    transactionBuilder?.referralInfo?.allReferralParticipantInfo || []
  )?.some(
    // @ts-ignore
    (participant) => participant?.opCityReferral,
  );

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <p className='text-xl font-zen-title font-medium text-zen-dark-9 mb-4'>
          Do you have to pay a referral fee for this transaction?
        </p>

        <ZenControlledRadioInput<CreateTransactionFormState, 'referralFee'>
          name='referralFee'
          control={control}
          inlineOptions
          shouldUnregister={false}
          options={values(YesNoType).map((value) => {
            return { label: capitalizeEnum(value), value };
          })}
          rules={{
            validate: (value) => {
              if (!value || !value.length) {
                return 'Please select an option';
              } else if (
                value === YesNoType.YES &&
                transactionBuilder?.referralInfo?.allReferralParticipantInfo
                  ?.length === 0 &&
                !openExternalParticipantFormSidebarModal &&
                !openRealParticipantFormSidebarModal &&
                isValidating
              ) {
                return 'It is required to add at least 1 participant';
              }
              return undefined;
            },
          }}
        />
        {referralFee === YesNoType.YES && (
          <div>
            <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between mt-10'>
              <p className='text-xl font-zen-title font-medium text-zen-dark-9'>
                Participants
              </p>
              <div className='flex flex-row items-center'>
                {isUSTransaction && (
                  <React.Fragment>
                    <ZenButton
                      label='OpCity'
                      isDisabled={isOpCityReferralAvailable}
                      disabledClassName='disabled:bg-transparent disabled:text-zen-dark-4 disabled:border-none'
                      LeftIconComponent={
                        <FontAwesomeIcon
                          icon={faUserPlus}
                          className={cn(
                            isOpCityReferralAvailable
                              ? 'text-zen-dark-4'
                              : 'text-primary-blue',
                          )}
                          size='sm'
                        />
                      }
                      onClick={async () => {
                        if (isOpCityLoading) {
                          return;
                        }
                        setIsOpCityLoading(true);
                        await dispatch(
                          addOpCityInfo(transactionBuilderId!, true),
                        );
                        setIsOpCityLoading(false);
                      }}
                      variant='primary-link'
                    />
                    <div className='h-4 border-r border-zen-dark-4 mx-3' />
                  </React.Fragment>
                )}
                <ZenButton
                  label='External agent'
                  LeftIconComponent={
                    <FontAwesomeIcon
                      icon={faUserPlus}
                      className='text-primary-blue'
                      size='sm'
                    />
                  }
                  onClick={() =>
                    setOpenExternalParticipantFormSidebarModal(true)
                  }
                  variant='primary-link'
                />
                <div className='h-4 border-r border-zen-dark-4 mx-3' />
                <ZenButton
                  label='REAL agent'
                  variant='primary-link'
                  LeftIconComponent={
                    <FontAwesomeIcon
                      icon={faUserPlus}
                      className='text-primary-blue'
                      size='sm'
                    />
                  }
                  onClick={() => setOpenRealParticipantFormSidebarModal(true)}
                />
              </div>
            </div>
            <div className='grid grid-flow-col grid-cols-2 bg-zen-light-gray-1 flex-row px-2 py-3.5 items-center mt-1 font-zen-body font-bold text-zen-gray-5 text-xs align-top uppercase'>
              <p>Name</p>
              <p className='-ml-5'>Role</p>
            </div>
            <div>
              {transactionBuilder?.referralInfo?.allReferralParticipantInfo?.map(
                (item) => {
                  let participantName: string;

                  // @ts-ignore
                  if (item.agentId) {
                    // @ts-ignore
                    participantName = agentById[item.agentId]
                      ? // @ts-ignore
                        getParticipantName(agentById[item.agentId])
                      : 'N/A';
                  } else {
                    participantName = getParticipantName(item);
                  }

                  return (
                    <div
                      className='grid grid-flow-col grid-cols-2 flex-row items-start px-2 py-3.5 font-zen-body font-semibold text-sm text-zen-dark-9'
                      key={item.id}
                    >
                      <p>{participantName}</p>
                      <p>{capitalizeEnum(item.role! || '')}</p>
                      <div className='cursor-pointer pr-5'>
                        <MdOutlineDelete
                          title='delete'
                          className='text-zen-danger'
                          size={20}
                          onClick={async () => {
                            if (isDeletingReferralInfo) {
                              return;
                            }
                            setIsDeletingReferralInfo(true);
                            await dispatch(
                              deleteReferralInfo(
                                transactionBuilderId!,
                                item?.id!,
                              ),
                            );
                            setIsDeletingReferralInfo(false);
                          }}
                        />
                      </div>
                    </div>
                  );
                },
              )}
              {transactionBuilder?.referralInfo?.allReferralParticipantInfo
                ?.length === 0 && (
                <div className='pb-4 border-b border-zen-dark-4'>
                  <p className='flex justify-center font-zen-body text-zen-dark-6 pt-2'>
                    No referrals have been added yet.
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
        {openExternalParticipantFormSidebarModal && (
          <ZenAddExternalParticipant
            isOpen
            onClose={() => setOpenExternalParticipantFormSidebarModal(false)}
            control={control}
            watch={watch}
            trigger={trigger}
            setValue={setValue}
            getValues={getValues}
          />
        )}

        {openRealParticipantFormSidebarModal && (
          <ZenAddRealParticipant
            isOpen
            onClose={() => setOpenRealParticipantFormSidebarModal(false)}
            control={control}
            watch={watch}
            trigger={trigger}
          />
        )}
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              label='Next'
              onClick={handleOnNext}
              isDisabled={isLoading}
              isSubmitting={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withCreateTransactionProgress(ZenTransactionReferralFeeStep);
