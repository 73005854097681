import React, { ChangeEvent } from 'react';
import OfficeBackground from '../../assets/img/gemini-office-background.png';
import { cn } from '../../utils/classUtils';

export type CoverImageVariant = 'office-detail-page';

export interface GeminiCoverImageProps {
  imageUrl?: string | undefined;
  editable?: boolean;
  variant?: CoverImageVariant;
  onImageChange?(e: ChangeEvent<HTMLInputElement>): void;
}

const GeminiCoverImage: React.FC<GeminiCoverImageProps> = ({
  imageUrl,
  variant = 'office-detail-page',
}) => {
  const variantMapClass: { [x in CoverImageVariant]: string } = {
    'office-detail-page': 'md:h-[212px] h-[180px]',
  };

  return (
    <div className='relative -z-10 md:z-0'>
      <div
        className={cn(variantMapClass[variant])}
        style={{
          backgroundImage: imageUrl ?? `url("${OfficeBackground}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      />
    </div>
  );
};

export default GeminiCoverImage;
