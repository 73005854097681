import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { cn } from '../../utils/classUtils';

export interface GeminiAccordionProps {
  title: string;
  isOpenDefault?: boolean;
  canToggle?: boolean;
  leftIcon?: IconDefinition;
}

const GeminiAccordion: React.FC<GeminiAccordionProps> = ({
  title,
  isOpenDefault = false,
  children,
  canToggle = true,
  leftIcon,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenDefault);
  useEffect(() => {
    setIsOpen(isOpenDefault);
  }, [isOpenDefault]);
  return (
    <div>
      <div
        className={cn(
          'w-full flex flex-row items-center justify-between p-4 border-b border-regent-300 cursor-pointer',
          isOpen && 'mb-3',
        )}
        onClick={() => {
          if (canToggle) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <div className='w-full flex flex-row gap-4 items-center justify-start'>
          {leftIcon && (
            <div className='p-2.5 rounded-full bg-rezen-blue-100 flex items-center justify-center'>
              <FontAwesomeIcon
                icon={leftIcon}
                className='text-primary-blue h-4 w-4'
              />
            </div>
          )}
          <div className='font-inter font-medium text-primary-dark'>
            {title}
          </div>
        </div>
        <div className='flex items-center'>
          {isOpen ? (
            <MdExpandLess size={25} className='text-primary-dark' />
          ) : (
            <MdExpandMore size={25} className='text-primary-dark' />
          )}
        </div>
      </div>
      <div className={cn(isOpen ? 'block' : 'hidden')}>{children}</div>
    </div>
  );
};

export default GeminiAccordion;
