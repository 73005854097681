import { useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import useQueryParams from '../hooks/useQueryParams';
import { RootState } from '../types';
import Logger from '../utils/Logger';
import ZenRedirect from './Navigation/ZenRedirect';

export interface GuestRouteProps extends RouteProps {}

interface Query {
  redirectTo?: string;
}

const GuestRoute: React.FC<GuestRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const { redirectTo } = useQueryParams<Query>();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userDetail && redirectTo) {
          Logger.debug('GuestRoute: redirecting to ZenRedirect=/');
        } else {
          Logger.debug('GuestRoute: redirecting to component.');
        }

        return userDetail ? (
          <ZenRedirect to={redirectTo ? redirectTo : '/'} />
        ) : (
          // @ts-ignore
          <Component {...props} />
        );
      }}
    />
  );
};

export default GuestRoute;
