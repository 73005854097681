import { flatten, uniq } from 'lodash';
import {
  AgentDebtResponse,
  DebtRepaymentItemResponse,
  DebtRepaymentItemResponsePaymentSourceTypeEnum,
  DebtRepaymentItemResponseStatusEnum,
} from '../openapi/arrakis';

export const isPaidNonInvoiceRepaymentItem = (
  repaymentItem: DebtRepaymentItemResponse,
) => {
  return (
    !!repaymentItem?.paymentSourceId &&
    repaymentItem?.paymentSourceType !==
      DebtRepaymentItemResponsePaymentSourceTypeEnum.Invoice &&
    repaymentItem?.status === DebtRepaymentItemResponseStatusEnum.Paid
  );
};

export const getTransactionIdsForPublicTransactionInfo = (
  debts: AgentDebtResponse[],
) => {
  const sourceIDs = debts
    ?.filter((debt) => !!debt?.sourceId)
    ?.map((debt) => debt?.sourceId!);

  const uniqSourceIDs = uniq(sourceIDs);

  const flattenRepaymentItems =
    flatten(debts?.map((debt) => debt?.repaymentItems || [])) || [];

  const paymentSourceIDs = flattenRepaymentItems
    ?.filter((item) => isPaidNonInvoiceRepaymentItem(item))
    ?.map((item) => item?.paymentSourceId!);

  const uniqPaymentSourceIDs = uniq(paymentSourceIDs);

  const IDsToFetch = [...uniqSourceIDs, ...uniqPaymentSourceIDs];

  const uniqIDsToFetch = uniq(IDsToFetch);

  return uniqIDsToFetch;
};

export const getDebtTypesInDescendingOrder = (debtTypes: string[]) => {
  return debtTypes.sort((a, b) => b.localeCompare(a));
};
