import {
  BoardResponseStatusEnum,
  MlsResponseStatusEnum,
} from '../../openapi/yenta';
import { EnumMap } from '../../types';
import { safeEnumMapGet } from '../../utils/EnumHelper';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenStatusPill, { PillVariantType } from './ZenStatusPill';

interface ZenMlsOrBoardPillProps {
  status: BoardResponseStatusEnum | MlsResponseStatusEnum;
}

const statusToVariant: EnumMap<
  BoardResponseStatusEnum | MlsResponseStatusEnum,
  PillVariantType
> = {
  ACTIVE: 'success',
  INACTIVE: 'danger',
  NOT_MEMBERS: 'warning',
  PENDING: 'warning',
  WILLING_TO_JOIN: 'primary',
};

const ZenMlsOrBoardPill: React.FC<ZenMlsOrBoardPillProps> = ({ status }) => {
  return (
    <ZenStatusPill
      text={capitalizeEnum(status)}
      variant={safeEnumMapGet(statusToVariant, status, 'default')}
      fontSize='text-lg'
    />
  );
};

export default ZenMlsOrBoardPill;
