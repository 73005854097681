import React from 'react';
import { parseInitials, stringToHexCode } from '../../../utils/StringUtils';
import { cn } from '../../../utils/classUtils';

interface AgentStackProps {
  avatarList: { name: string; id: string; avatar?: string | null }[];
}

const AgentStack: React.FC<AgentStackProps> = ({ avatarList }) => {
  const getStyleFromIndex = (index: number): string => {
    switch (index) {
      case 0:
        return 'bg-zen-blue-1 text-white';
      case 1:
        return 'bg-zen-blue-2 text-white';
      case 2:
        return 'bg-zen-blue-2 text-white';
      case 3:
        return 'bg-zen-blue-2 text-white';
      default:
        return 'bg-grey-600 text-white';
    }
  };

  const getCircleWithText = (text: string, style: string) => {
    return (
      <span
        className={cn(
          'flex items-center justify-center rounded-full h-6 w-6 ring-white ring-1 text-white',
          style,
        )}
        style={{
          backgroundColor: style,
        }}
      >
        <span className='font-zen-body font-normal text-[11px] leading-1'>
          {text}
        </span>
      </span>
    );
  };

  return (
    <div className='flex items-center'>
      {avatarList?.slice(0, 3).map((avatar, index) => {
        if (index < 4) {
          return (
            <div
              key={avatar?.id}
              className={cn('border rounded-full', {
                '-ml-3': index !== 0,
              })}
            >
              {getCircleWithText(
                parseInitials(avatar?.name),
                stringToHexCode(avatar?.name),
              )}
            </div>
          );
        }

        return (
          <div key={avatar?.id} className='-ml-3 z-0'>
            {getCircleWithText(
              `+${avatarList?.length - 2}`,
              getStyleFromIndex(index),
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AgentStack;
