import React from 'react';
import {
  getRealTitleFAQs,
  RealTitleFAQTypeEnum,
} from '../../utils/RealTitleUtils';
import ZenAccordion from '../Zen/ZenAccordion';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';

interface RealTitleAndEscrowFAQsSectionProps {}

const RealTitleAndEscrowFAQsSection: React.FC<RealTitleAndEscrowFAQsSectionProps> = () => {
  return (
    <div className='pt-8'>
      <h2 className='font-zen-title font-medium text-2xl text-black'>
        Frequently Asked Questions
      </h2>
      <div className='mt-6'>
        <div className='space-y-3 mt-4'>
          {getRealTitleFAQs[RealTitleFAQTypeEnum.FAQ].map((faq, index) => (
            <ZenAccordion
              title={faq.question}
              variant='secondary'
              key={faq.question}
              googleAnalytics={() => {
                AnalyticsService.instance().logEvent(
                  AnalyticsEventEnum.ONE_APP_TITLE_CLICKED_FAQ,
                );
              }}
            >
              <p
                className='font-zen-body text-base text-zen-dark-9 whitespace-pre-wrap mt-4'
                data-testid={`faq-answer-${index}`}
              >
                {faq.answer}
              </p>
            </ZenAccordion>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RealTitleAndEscrowFAQsSection;
