import { useSelector } from 'react-redux';
import { RootState } from '../../../types';

const useSelfOnly = (agentId: string) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  return userDetail?.id === agentId;
};

export default useSelfOnly;
