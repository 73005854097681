import { sum } from 'lodash';
import {
  InstantPaymentItemResponse,
  InstantPaymentItemResponseTypeEnum,
  InstantPaymentsSummary,
  InstantPaymentTransactionSummaryResponseStateEnum,
  TransactionLinkResponse,
  TransactionPreviewResponse,
  TransactionResponse,
} from '../openapi/arrakis';
import { EnumMap } from '../types';

export const getProcessingFeesPercentage = (
  items: InstantPaymentItemResponse[],
): number | undefined => {
  const processingFeesPercentage = items.find(
    (item) => item.type === InstantPaymentItemResponseTypeEnum.Interest,
  )?.percentage;

  return processingFeesPercentage;
};

export const calculateInterestAmount = (
  items: InstantPaymentItemResponse[],
): number => {
  const interestItems = items.filter(
    (item) => item.type === InstantPaymentItemResponseTypeEnum.Interest,
  );

  return sum(interestItems.map((item) => item.amount?.amount));
};

export const calculatePrincipalAmount = (
  items: InstantPaymentItemResponse[],
): number => {
  const interestItems = items.filter(
    (item) => item.type === InstantPaymentItemResponseTypeEnum.Principal,
  );

  return sum(interestItems.map((item) => item.amount?.amount));
};

export const isTransactionSummaryEligible = (
  state: InstantPaymentTransactionSummaryResponseStateEnum | undefined,
): boolean => {
  if (!state) {
    return false;
  }

  const stateMap: EnumMap<
    InstantPaymentTransactionSummaryResponseStateEnum,
    boolean
  > = {
    ELIGIBLE: true,
    ELIGIBLE_BELOW_MAX: true,
    INELIGIBLE_AGENT: false,
    INELIGIBLE_BELOW_MIN: false,
    INELIGIBLE_CLOSED: false,
    INELIGIBLE_NO_AGENT: false,
    INELIGIBLE_PASS_THROUGH: false,
    INELIGIBLE_PERSONAL_DEAL: false,
    INELIGIBLE_COMMISSION_DOCUMENT_SENT: false,
  };

  return stateMap[state];
};

export const showInstantPayments = (
  instantPaymentsSummary: InstantPaymentsSummary | undefined,
): boolean => {
  if (instantPaymentsSummary?.signedTsAndCs) {
    return true;
  }

  const eligibleTransactions = (
    instantPaymentsSummary?.transactionSummaries || []
  ).filter((transactionSummary) =>
    isTransactionSummaryEligible(transactionSummary.state!),
  );

  return eligibleTransactions.length > 0;
};

export const getInstantPaymentAmplitudeEventData = (
  transaction:
    | TransactionPreviewResponse
    | TransactionResponse
    | TransactionLinkResponse
    | undefined,
): Record<string, unknown> => {
  return {
    transactionCode: transaction?.code,
    transactionCommissionAmount: transaction?.grossCommission?.amount,
    transactionCommissionCurrency: transaction?.grossCommission?.currency,
  };
};
