import { debounce } from 'lodash';
import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form-v7';
import AnalyticsService from '../../../../services/AnalyticsService';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenButton from '../../../Zen/ZenButton';
import CommissionIncomeFormInputs from '../../V2/Forms/CommissionIncomeFormInputs';
import RevenueShareIncomeFormInputs from '../../V2/Forms/RevenueShareIncomeFormInputs';
import StockPurchasePlanSppFormInputs from '../../V2/Forms/StockPurchasePlanSppFormInputs';
import StockForecastSliderInput from '../../V2/StockForecastSliderInput';
import { WealthPlanRateBanner } from '../WealthPlanRate';
import {
  SendWealthPlanStepByStepFormData,
  WealthPlanFormStepName,
} from './SendWealthPlanStepByStepFormV2';

const SetGoalsPlanV2: StepByStepComponent<
  SendWealthPlanStepByStepFormData,
  WealthPlanFormStepName
> = ({ form: formControls, onNext, onPrevious }) => {
  const { watch, control } = formControls;
  const firstName = watch('firstName');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string) => {
      AnalyticsService.instance().logEvent(event);
    }, 500),
    [],
  );

  return (
    <FormProvider {...(formControls as any)}>
      <div className='w-full scrollbar overflow-y-scroll pb-20'>
        <div className='w-full flex flex-col justify-start relative items-start gap-1'>
          <p className='font-medium text-xl font-zen-body text-dark'>
            Set Sub Goals
          </p>
          <p className='text-zen-dark-8 font-zen-body'>
            {`Please provide some information so we can create a plan for ${firstName} `}
          </p>
          <WealthPlanRateBanner />
          <div className='w-full mt-2 space-y-4'>
            <CommissionIncomeFormInputs logAnalyticsEvent={logAnalyticsEvent} />
            <StockPurchasePlanSppFormInputs
              logAnalyticsEvent={logAnalyticsEvent}
            />
            <RevenueShareIncomeFormInputs
              logAnalyticsEvent={logAnalyticsEvent}
            />
            <StockForecastSliderInput
              formControlParam={control as any}
              formWatchParam={watch as any}
              noContainer={false}
            />
          </div>
        </div>
      </div>
      <div className='sticky p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-2 flex flex-row justify-end items-center left-0 right-0 px-3 py-2 w-full'>
        <ZenButton
          variant='primary-outline'
          label='Back'
          onClick={() => onPrevious()}
        />
        <ZenButton label='Next' onClick={() => onNext()} />
      </div>
    </FormProvider>
  );
};

export default SetGoalsPlanV2;
