import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToSquare, faEye } from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BankActivityDto } from '../../../openapi/arrakis';
import { fetchTransactionDetails } from '../../../slices/TransactionSlice';
import { RootState } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { renderTransactionStatusByCountry } from '../../../utils/TransactionHelper';
import Hover from '../../Hover';
import IconButton from '../../IconButton';
import ResourceContainer from '../../ResourceContainer';
import ZenSidebarModal from '../ZenSidebarModal';
import ZenReconcileTransactionSidebarModalHeader from './ZenReconcileTransactionSidebarModalHeader';

interface ZenReconcileTransactionSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  selectedTransaction: BankActivityDto | null;
}

const ZenReconcileTransactionSidebarModal: React.FC<ZenReconcileTransactionSidebarModalProps> = ({
  isOpen,
  onClose,
  selectedTransaction,
}) => {
  const dispatch = useDispatch();
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail, loading },
    },
  } = useSelector((state: RootState) => state);
  const transactionId = selectedTransaction?.confirmedMatch?.transactionId!;

  useEffect(() => {
    dispatch(fetchTransactionDetails(transactionId!));
  }, [dispatch, transactionId]);

  return (
    <ZenSidebarModal
      title='Reconciled Transaction'
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <ZenReconcileTransactionSidebarModalHeader
        selectedTransaction={selectedTransaction}
      />

      <div className='p-5 mb-20'>
        <div>
          <p className='font-zen-body font-semibold text-base text-zen-dark-6'>
            Transaction Overview
          </p>
          <div className='border border-zen-dark-4 rounded-lg mt-3'>
            <div className='bg-grey-100 rounded-lg'>
              <div className='px-4 py-4 border-b border-zen-dark-4'>
                <div className='flex justify-between flex-row items-center'>
                  <label className='flex items-center space-x-3 cursor-pointer'>
                    <span className='font-zen-body text-base text-zen-dark-9 font-normal'>
                      {selectedTransaction?.confirmedMatch?.transactionAddress!}
                    </span>
                  </label>
                  <div className='ml-4'>
                    <Hover
                      hoverComponent={
                        <div className='text-dark'>View Transaction</div>
                      }
                      config={{ trigger: 'hover', placement: 'top' }}
                    >
                      <IconButton
                        variant='none'
                        size='xs'
                        leftIcon={
                          <FontAwesomeIcon
                            icon={faEye}
                            title='View Transaction'
                            size='xl'
                          />
                        }
                        buttonType='button'
                        buttonStyle='bg-grey-200 rounded-full h-9 w-9 hover:bg-zen-dark-4 flex justify-center'
                        onClick={() =>
                          window.open(
                            `/transactions/${transactionDetail?.id}`,
                            '_blank',
                          )
                        }
                      />
                    </Hover>
                  </div>
                </div>
              </div>
            </div>
            <ResourceContainer
              isEmpty={!transactionDetail}
              loading={loading}
              resourceName='transaction detail'
            >
              {transactionDetail && (
                <div className='bg-white rounded-lg p-4'>
                  <div className='grid grid-cols-3 gap-4'>
                    <div>
                      <p className='font-zen-body font-semibold text-zen-dark-7 text-sm mb-1'>
                        Transaction Code
                      </p>
                      <Link to={`/transactions/${transactionDetail?.id!}`}>
                        <IconButton
                          leftIcon={
                            <FontAwesomeIcon
                              icon={faArrowDownToSquare}
                              title='Link'
                              className='-rotate-90 mx-0.5'
                            />
                          }
                          buttonStyle='border border-gray-200 rounded-lg'
                          variant='none'
                          label={transactionDetail?.code}
                        />
                      </Link>
                    </div>
                    <div>
                      <p className='font-zen-body font-semibold text-zen-dark-7 text-sm mb-1'>
                        Deal Type
                      </p>
                      <p>
                        {transactionDetail?.transactionType &&
                          capitalizeEnum(transactionDetail?.transactionType!)}
                      </p>
                    </div>
                    <div>
                      <p className='font-zen-body font-semibold text-zen-dark-7 text-sm mb-1'>
                        Sale Price
                      </p>
                      <p>{displayAmount(transactionDetail?.price!)}</p>
                    </div>
                    <div>
                      <p className='font-zen-body font-semibold text-zen-dark-7 text-sm mb-1'>
                        Estimated Closing Date
                      </p>
                      <p>
                        {transactionDetail?.skySlopeEscrowClosingDate
                          ? DateTime.fromISO(
                              transactionDetail?.skySlopeEscrowClosingDate,
                            ).toFormat('MM/dd/yyyy')
                          : 'N/A'}
                      </p>
                    </div>
                    <div>
                      <p className='font-zen-body font-semibold text-zen-dark-7 text-sm mb-1'>
                        Actual Closing Date
                      </p>
                      <p>
                        {transactionDetail?.skySlopeActualClosingDate
                          ? DateTime.fromISO(
                              transactionDetail?.skySlopeActualClosingDate,
                            ).toFormat('MM/dd/yyyy')
                          : 'N/A'}
                      </p>
                    </div>
                    <div>
                      <p className='font-zen-body font-semibold text-zen-dark-7 text-sm mb-1'>
                        Current Status
                      </p>
                      <p>
                        {renderTransactionStatusByCountry(
                          transactionDetail?.lifecycleState?.state!,
                          transactionDetail?.country!,
                        )}
                      </p>
                    </div>
                    <div>
                      <p className='font-zen-body font-semibold text-zen-dark-7 text-sm mb-1'>
                        Commission Payer
                      </p>
                      <p>{`${transactionDetail?.cdPayer?.firstName} ${transactionDetail?.cdPayer?.lastName}`}</p>
                    </div>
                  </div>
                </div>
              )}
            </ResourceContainer>
          </div>
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default ZenReconcileTransactionSidebarModal;
