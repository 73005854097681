import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { cn } from '../utils/classUtils';

interface ButtonV2Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon?: IconDefinition;
  label: string;
  variant?: 'filled' | 'text';
}

const ButtonV2: React.FC<ButtonV2Props> = ({
  icon,
  label,
  onClick,
  className,
  variant = 'filled',
  ...props
}) => {
  return (
    <button
      className={cn(
        'flex items-center gap-2 w-fit h-fit rounded-md cursor-pointer',
        className,
        {
          'bg-gray-900 py-2 px-6 text-white': variant === 'filled',
          'text-rezen-blue-600': variant === 'text',
        },
      )}
      onClick={onClick}
      {...props}
    >
      <p className='font-inter'>{label}</p>
      {icon && <FontAwesomeIcon icon={icon} size='sm' />}
    </button>
  );
};

export default ButtonV2;
