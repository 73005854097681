interface ZenTestimonialRowProps {
  step: number;
  clientName?: string;
  testimonial?: string;
}

const ZenTestimonialRow: React.FC<ZenTestimonialRowProps> = ({
  step,
  clientName,
  testimonial,
}) => {
  return (
    <div className='flex flex-col space-y-4 font-zen-body text-base text-primary-dark'>
      <div className='flex flex-row items-start space-x-3'>
        <div className='w-6 h-6 bg-grey-200 rounded-full flex flex-shrink-0 justify-center items-center mt-1'>
          <span className='font-zen-title text-sm mt-0.5'>{step}</span>
        </div>
        <div className='flex flex-col space-y-3'>
          <div className='flex flex-col'>
            <label className='font-zen-title font-medium'>Client Name</label>
            {clientName ? (
              <p className='text-sm break-all'>{clientName}</p>
            ) : (
              <p className='text-grey-600 text-sm'>Client Name</p>
            )}
          </div>
          <div className='flex flex-col'>
            <label className='font-zen-title font-medium'>Testimonial</label>
            {testimonial ? (
              <p className='text-sm break-all'>{testimonial}</p>
            ) : (
              <p className='text-grey-600 text-sm'>Testimonial</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenTestimonialRow;
