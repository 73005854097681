import { Fragment } from 'react';
import { ZenAvatarSizeType, ZenAvatarVariantType } from '../../types';
import { cn } from '../../utils/classUtils';
import ZenAvatar, {
  avatarSizeToClassNameMap,
  avatarStyleToClassNameMap,
  textSizeToClassNameMap,
  ZenAvatarProps,
} from './ZenAvatar';

export interface ZenAvatarStackProps {
  avatars: ZenAvatarProps[];
  size?: ZenAvatarSizeType;
  variant?: ZenAvatarVariantType;
  numToDisplay?: number;
  displayOverflow?: boolean;
  marginClassName?: string;
  overrideOverflowText?: string;
  showRing?: boolean;
}

const ZenAvatarStack: React.FC<ZenAvatarStackProps> = ({
  avatars,
  numToDisplay = 2,
  variant = 'circle',
  size = 'md',
  displayOverflow = true,
  marginClassName = '-ml-2',
  overrideOverflowText,
  showRing,
}) => {
  const margin =
    numToDisplay === 1 || avatars.length === 1 ? '' : marginClassName;

  return (
    <div className='flex items-center'>
      <div className='flex flex-wrap'>
        {avatars.slice(0, numToDisplay).map((avatar, index) => {
          return (
            <Fragment key={[avatar.name, index].join(',')}>
              <div className={margin}>
                <ZenAvatar
                  {...avatar}
                  size={size}
                  showTooltip
                  showRing={showRing}
                  imageUrl={avatar?.avatar}
                />
              </div>
            </Fragment>
          );
        })}
        {displayOverflow && avatars.length > numToDisplay && (
          <span
            className={cn(
              'inline-flex items-center justify-center bg-sky-500',
              avatarStyleToClassNameMap[variant],
              avatarSizeToClassNameMap[size],
              margin,
            )}
          >
            <span
              className={cn(
                'font-zen-title font-normal leading-1 text-white',
                textSizeToClassNameMap[size],
              )}
            >
              {overrideOverflowText || `+${avatars.length - numToDisplay}`}
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

export default ZenAvatarStack;
