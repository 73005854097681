import React from 'react';
import classNames from 'classnames';
import {
  Address,
  TransactionBuilderAddress,
} from '../../../../openapi/arrakis';
import NullableTextCell from '../../../table/Cells/NullableTextCell';
import { capitalizeEnum, formatAddress } from '../../../../utils/StringUtils';
import { AddressResponse } from '../../../../openapi/yenta';

interface ZenAddressCellProps {
  address?: Address | TransactionBuilderAddress | AddressResponse;
  placeholder?: React.ReactElement | string;
}

const ZenAddressCell: React.FC<ZenAddressCellProps> = ({
  address,
  placeholder,
}) => {
  if (!address && placeholder) {
    return <div>{placeholder}</div>;
  }

  if (!address) {
    return <NullableTextCell text={undefined} />;
  }

  const getAddressPropertyValue = (
    key1: keyof Address | keyof Omit<TransactionBuilderAddress, 'unit'>,
    key2: keyof AddressResponse,
  ): string => {
    if (key1 in (address as Address)) {
      return (address as Address)[key1]! as string;
    }
    if (key2 in (address as AddressResponse)) {
      return (address as AddressResponse)[key2]!;
    }
    return '';
  };

  return (
    <div
      className={classNames({ 'w-48 overflow-hidden text-ellipsis': address })}
    >
      {formatAddress(
        getAddressPropertyValue('street', 'streetAddress1'),
        getAddressPropertyValue('street2', 'streetAddress2'),
        getAddressPropertyValue('city', 'city'),
        capitalizeEnum(getAddressPropertyValue('state', 'stateOrProvince')),
        getAddressPropertyValue('zip', 'zipOrPostalCode'),
      )}
    </div>
  );
};

export default ZenAddressCell;
