import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  InvoiceControllerApi,
  InvoiceResponse,
} from '../../../openapi/arrakis';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import ErrorService from '../../../services/ErrorService';
import { AppDispatch } from '../../../types';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';

interface ZenRetryInvoiceModalProps {
  invoice?: InvoiceResponse;
  isOpen: boolean;
  onClose(): void;
}

const ZenRetryInvoiceModal: React.FC<ZenRetryInvoiceModalProps> = ({
  invoice,
  isOpen,
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [retrying, setRetrying] = useState(false);

  const retryInvoice = async () => {
    setRetrying(true);
    try {
      const { data } = await new InvoiceControllerApi(
        getArrakisConfiguration(),
      ).retryInvoice(invoice?.id!);
      dispatch(
        showSuccessToast(
          `Successfully requested for retry invoice to ${data.firstName} ${data.lastName}`,
        ),
      );
    } catch (e) {
      ErrorService.notify('Unable to retry an invoice', e, {
        invoice: { ...(invoice || {}) },
      });
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to retry the invoice',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setRetrying(false);
      onClose();
    }
  };

  return (
    <ZenConfirmationModal
      variant='danger'
      subtitle={`Are you sure you want to retry invoice of ${displayAmount(
        invoice?.invoicedAmount!,
      )} to ${invoice?.firstName} ${invoice?.lastName}?`}
      title='Retry Invoice'
      onClose={onClose}
      onConfirm={retryInvoice}
      isSubmitting={retrying}
      isOpen={isOpen}
      confirmButtonText='Retry Invoice'
      hideIcon
    />
  );
};

export default ZenRetryInvoiceModal;
