import {
  faMoneyCheckDollar,
  faSplit,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OfficeResponseTransactionTypeEnum } from '../../openapi/yenta';
import { cn } from '../../utils/classUtils';
import { capitalizeEnum } from '../../utils/StringUtils';

interface OfficeCheckPillProps {
  isSingleCheck: boolean;
}

const OfficeCheckPill: React.FC<OfficeCheckPillProps> = ({ isSingleCheck }) => {
  return (
    <div
      className={cn(
        'flex flex-row justify-center items-center space-x-1 rounded-md px-2 py-1.5 text-base',
        isSingleCheck
          ? 'bg-regent-200 text-primary-blue'
          : 'bg-zen-purple-light text-zen-purple-dark',
      )}
    >
      {isSingleCheck ? (
        <FontAwesomeIcon icon={faMoneyCheckDollar} fontSize={14} />
      ) : (
        <FontAwesomeIcon icon={faSplit} fontSize={14} />
      )}
      <span className='hidden md:inline font-inter font-medium text-sm'>
        {capitalizeEnum(
          isSingleCheck
            ? OfficeResponseTransactionTypeEnum.SingleCheck
            : OfficeResponseTransactionTypeEnum.SplitCheck,
        )}
      </span>
    </div>
  );
};

export default OfficeCheckPill;
