import React from 'react';
import { flatten, values } from 'lodash';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from '@fortawesome/pro-regular-svg-icons';
import { MlsPropertyTypeMapping, MlsResponse } from '../../../openapi/yenta';
import { MlsPropertyTypesWandererType } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { capitalizeEnum, formatPhoneNumber } from '../../../utils/StringUtils';
import ZenBooleanButton from '../../Agent/V2/ZenBooleanButton';
import AdminOnly from '../../auth/AdminOnly';
import ProfileImage from '../../ProfileImage';
import ZenMlsOrBoardPill from '../../Zen/ZenMlsOrBoardPill';

interface ZenMLSHeaderSectionProps {
  mls: MlsResponse;
  mlsPropertyTypesMappings?: MlsPropertyTypeMapping[];
  mlsPropertyTypesWanderer: MlsPropertyTypesWandererType;
}

const ZenMLSHeaderSection: React.FC<ZenMLSHeaderSectionProps> = ({
  mls,
  mlsPropertyTypesMappings,
  mlsPropertyTypesWanderer,
}) => {
  return (
    <section className='md:px-8 py-6 flex flex-col w-full lg:flex-row justify-between items-start bg-zen-dark-13 text-white rounded-[15px]'>
      <div className='flex flex-col md:flex-row md:space-x-4'>
        <div className='flex flex-col items-center'>
          <div>
            <ProfileImage imageUrl={mls.logo!} width={120} variant='rounded' />
          </div>
          <div className='mt-2'>
            <ZenMlsOrBoardPill status={mls.status!} />
          </div>
        </div>
        <div className='font-zen-body md:ml-4 py-2'>
          <h1 className='text-2xl font-medium mb-4'>{mls.name}</h1>
          <p className='mb-2'>Code : {mls.code}</p>
          <p className='mb-2'>
            Joining Date :{' '}
            {DateTime.fromISO(mls.joinDate!).toFormat('LL/dd/yy')}
          </p>
          <p className='mb-2'>
            Registration fee : {displayAmount(mls.registrationFee)}
          </p>
          <AdminOnly>
            <table className='mt-4'>
              <tr>
                <td className='py-1'>Access to MLS property types:</td>
                <td className='py-1 pl-1'>
                  <ZenBooleanButton
                    value={!!flatten(values(mlsPropertyTypesWanderer)).length}
                  />
                </td>
              </tr>
              <tr>
                <td className='py-2.5'>Provisioned property types:</td>
                <td className='py-2.5 pl-1'>
                  <ZenBooleanButton
                    value={!!mlsPropertyTypesMappings?.length}
                  />
                </td>
              </tr>
            </table>
          </AdminOnly>
        </div>
      </div>
      <div className='font-zen-body flex flex-col items-center w-full max-w-sm pt-3 space-y-4 lg:items-start lg:pt-0'>
        <div className='w-full'>
          <div>
            <span className='text-lg text-white font-medium'>
              Contact Information
            </span>
          </div>
          <div className='font-zen-body p-4 border border-grey-600 rounded-lg mt-2'>
            <div className='flex flex-row items-center mb-2 gap-2'>
              <FontAwesomeIcon
                icon={faLocationDot}
                className='text-gray-200 text-base'
              />
              <p className='text-trueGray-300'>
                {mls.administrativeAreas
                  ?.map((area) => capitalizeEnum(area.stateOrProvince!))
                  .join(', ') ?? 'N/A'}
              </p>
            </div>
            <div className='flex flex-row items-center mb-2 space-x-2'>
              <FontAwesomeIcon
                icon={faPhone}
                className='text-gray-200 text-base'
              />
              <p className='text-trueGray-300'>
                {mls.phoneNumber ? formatPhoneNumber(mls.phoneNumber)! : 'N/A'}
              </p>
            </div>
            <div className='flex flex-row items-center mb-2 space-x-2'>
              <FontAwesomeIcon
                icon={faEnvelope}
                className='text-gray-200 text-base'
              />
              <p className='text-trueGray-300'>
                {mls?.email ? mls?.email : 'N/A'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ZenMLSHeaderSection;
