import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DefaultLoader from '../../DefaultLoader';
import { TransactionResponse } from '../../../openapi/arrakis';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import ErrorService from '../../../services/ErrorService';
import { showErrorToastForErrorCode } from '../../../slices/ToastNotificationSlice';
import { AppDispatch } from '../../../types';
import { fetchLatestCommissionDocumentsForTransaction } from '../../../utils/TableUtils';
import CDAForm from '../../../routes/CDAForm';
import {
  getCommissionDocumentName,
  isSaleTransaction,
} from '../../../utils/TransactionHelper';
import InvoiceForm from '../../../routes/InvoiceForm';
import InvoiceFormContainer from '../../../containers/InvoiceFormContainer';
import ZenResourceContainer from '../../Zen/ZenResourceContainer';
import ZenButton from '../../Zen/ZenButton';

interface ZenViewCommissionDocumentProps {
  transaction: TransactionResponse;
  onClick(): void;
}

const ZenViewCommissionDocument: React.FC<ZenViewCommissionDocumentProps> = ({
  transaction,
  onClick,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [commissionDocumentId, setCommissionDocumentId] = useState<string>();

  const fetchCommissionDocument = useCallback(async () => {
    setLoading(true);
    try {
      const commissionDocuments = await fetchLatestCommissionDocumentsForTransaction(
        transaction.id!,
      );
      setCommissionDocumentId(commissionDocuments?.[0]?.id!);
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to fetch commission document', e, {
        transaction: { id: transaction.id },
      });
      dispatch(
        showErrorToastForErrorCode(
          `We encountered an error fetching the ${getCommissionDocumentName(
            transaction.country!,
          ).toLowerCase()}.`,
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, transaction]);

  useEffect(() => {
    fetchCommissionDocument();
  }, [fetchCommissionDocument]);

  if (loading) {
    return <DefaultLoader />;
  }

  return (
    <div className='w-full h-full'>
      <ZenResourceContainer
        loading={loading}
        isEmpty={!commissionDocumentId}
        resourceName={getCommissionDocumentName(transaction.country!)}
      >
        <div className='bg-gray-100 flex justify-center py-14'>
          <div className='bg-white w-full md:w-11/12 lg:w-3/4 relative'>
            {isSaleTransaction(transaction.transactionType!) ? (
              <CDAForm
                commissionDocumentId={commissionDocumentId!}
                isPdf={false}
              />
            ) : (
              !!commissionDocumentId && (
                <InvoiceFormContainer id={commissionDocumentId}>
                  {(invoiceResponse) => (
                    <InvoiceForm
                      asyncResponse={invoiceResponse}
                      isPdf={false}
                    />
                  )}
                </InvoiceFormContainer>
              )
            )}
          </div>
        </div>

        <div className='p-4 bg-white border-t border-coolGray-200 fixed bottom-0 w-full flex justify-center'>
          <div className='w-11/12 flex justify-end'>
            <ZenButton label='Next' onClick={onClick} />
          </div>
        </div>
      </ZenResourceContainer>
    </div>
  );
};

export default ZenViewCommissionDocument;
