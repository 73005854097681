import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import React from 'react';
import { Control } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  AddGroupMemberRequestRoleEnum,
  GroupMemberResponse,
} from '../../openapi/yenta';
import { AppDispatch } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import { searchForAllRegisteredAgents } from '../../utils/TableUtils';
import GeminiButton from '../Gemini/GeminiButton';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import { GeminiGroupMemberFormData } from './GeminiOfficeGroupCard';

interface OfficeGroupCardHeaderProps {
  isVisible: boolean;
  groupMembers?: GroupMemberResponse[];
  control: Control<GeminiGroupMemberFormData, object>;
  onSubmit: () => void;
  onCancel: () => void;
  isSubmitting?: boolean;
}

const OfficeGroupCardHeader: React.FC<OfficeGroupCardHeaderProps> = ({
  isVisible,
  control,
  groupMembers,
  onSubmit,
  onCancel,
  isSubmitting,
}) => {
  const dispatch: AppDispatch = useDispatch();

  if (!isVisible) {
    return null;
  }

  return (
    <div className='flex flex-col justify-between p-5 transition ease-in-out duration-600 md:min-h-[435px]'>
      <div className='mt-9 space-y-8'>
        <ZenControlledAsyncSelectInput<GeminiGroupMemberFormData, 'userId'>
          control={control}
          name='userId'
          placeholder='Search'
          label='Search Agent'
          hideChevronDown
          startAdornment={
            <div className='flex w-full h-full items-center justify-center px-3'>
              <FontAwesomeIcon
                className='text-primary-blue'
                icon={regular('magnifying-glass')}
              />
            </div>
          }
          fetchData={async (search, page) => {
            const agents = await dispatch(
              searchForAllRegisteredAgents(page, search),
            );

            return (agents || []).map((agent) => ({
              value: `${agent?.id}`,
              label: `${agent?.firstName} ${agent?.lastName} - ${agent?.emailAddress}`,
            }));
          }}
          filterOption={(option) =>
            ![
              ...(groupMembers || [])?.map((agent) => agent?.user?.id!)!,
            ].includes(option?.value)
          }
          rules={{
            validate: (v) => {
              if (!v?.value) {
                return 'Please select agent';
              }
              return undefined;
            },
          }}
          isRequired
        />
        <ZenControlledSelectInput<GeminiGroupMemberFormData, 'role'>
          name='role'
          control={control}
          label='Role'
          placeholder='Select Role'
          options={[
            ...values(AddGroupMemberRequestRoleEnum).map((role) => ({
              value: role,
              label: capitalizeEnum(role),
            })),
          ]}
          rules={{
            required: 'Please select role',
          }}
          isRequired
        />
      </div>
      <div className='flex justify-end gap-4'>
        <GeminiButton
          label='Cancel'
          variant='primary-outline'
          onClick={onCancel}
        />
        <GeminiButton
          label='Add Member'
          type='submit'
          variant='primary'
          leftIcon={faPlus}
          isSubmitting={isSubmitting}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default OfficeGroupCardHeader;
