import React from 'react';
import ZenPopover from '../../../ZenPopover';

interface LeadersRealCapPopoverProps {}

const LeadersRealCapPopover: React.FC<LeadersRealCapPopoverProps> = () => {
  return (
    <ZenPopover
      title="Leader's Real Cap"
      description={
        <div className='text-zen-dark-9 text-base font-inter font-light'>
          <p>
            Leader&apos;s Real cap is the maximum amount of Real Split the
            leader has to pay to Real. Once this amount is reached, the Real
            Split will no longer be applied. Instead, the leader will pay the
            Real Post Cap Transaction Fee.
          </p>
        </div>
      }
    />
  );
};

export default LeadersRealCapPopover;
