import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import OneRealTitleResourcesImg from '../../assets/img/zen/real-title/resources.png';
import OneRealTitleWorkplaceImg from '../../assets/img/zen/real-title/workplace.png';
import {
  ONE_REAL_ESCROW_WORKPLACE_URL,
  ONE_REAL_TITLE_MARKETING_RESOURCES_URL,
  ONE_REAL_TITLE_WORKPLACE_URL,
} from '../../constants/RealTitleConstants';
import ButtonV2 from '../ButtonV2';

const RealClosingServicesPortalAndWorkplace: React.FC = () => {
  return (
    <div
      className='border border-gray-600 bg-regent-200 p-4 md:p-10 rounded-2xl'
      data-testid='real-closing-services-portal-and-workplace'
    >
      <div className='flex flex-col md:flex-row gap-6 md:gap-12'>
        <div className='w-full md:w-1/2'>
          <p className='font-inter px-3 py-1 rounded-md bg-primary-navy w-fit text-primary-light'>
            Portal
          </p>
          <h1 className='font-poppins text-[40px] font-semibold leading-[48px] flex flex-col my-3'>
            <span>Access our Agent</span> <span>Resources</span>
          </h1>
          <p className='font-inter text-primary-dark text-lg font-light my-4 max-w-[700px]'>
            Browse the One Real Title portal on Marketing Center for branded
            social shareables, presentations, and more.
          </p>
          <ButtonV2
            variant='text'
            label='One Real Title Marketing Center'
            icon={faArrowUpRightFromSquare}
            className='mt-5'
            onClick={() =>
              window.open(ONE_REAL_TITLE_MARKETING_RESOURCES_URL, '_blank')
            }
          />
        </div>
        <img
          src={OneRealTitleResourcesImg}
          alt='resources'
          className='w-full md:w-1/2 object-contain'
          data-testid='real-closing-services-portal-img'
        />
      </div>
      <div className='flex flex-col md:flex-row-reverse gap-6 md:gap-12 mt-6'>
        <div className='w-full md:w-1/2'>
          <p className='font-inter px-3 py-1 rounded-md bg-primary-navy w-fit text-primary-light'>
            Workplace
          </p>
          <h1 className='font-poppins text-[40px] font-semibold leading-[48px] flex flex-col my-3'>
            Join us on Workplace
          </h1>
          <p className='font-inter text-primary-dark text-lg font-light my-4 max-w-[700px]'>
            The One Real Title and One Real Escrow of California groups are a
            place to ask questions, share reviews, and learn the latest news at
            One Real Title!
          </p>
          <div className='flex flex-col md:flex-row md:items-center mt-5'>
            <ButtonV2
              variant='text'
              label='Join One Real Title'
              icon={faArrowUpRightFromSquare}
              onClick={() =>
                window.open(ONE_REAL_TITLE_WORKPLACE_URL, '_blank')
              }
            />
            <div className='h-[1px] md:h-5 w-20 md:w-[1.5px] bg-gray-400 my-2 md:my-0 md:mx-3' />
            <ButtonV2
              variant='text'
              label='Join One Real Escrow'
              icon={faArrowUpRightFromSquare}
              onClick={() =>
                window.open(ONE_REAL_ESCROW_WORKPLACE_URL, '_blank')
              }
            />
          </div>
        </div>
        <img
          src={OneRealTitleWorkplaceImg}
          alt='resources'
          className='w-full md:w-1/2 object-contain'
          data-testid='real-closing-services-workplace-img'
        />
      </div>
    </div>
  );
};

export default RealClosingServicesPortalAndWorkplace;
