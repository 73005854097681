import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { RefundAttachmentResponse } from '../../../openapi/arrakis';
import IconButton from '../../IconButton';
import {
  ModifiedRefundAttachmentResponse,
  deleteAttachment,
  deleteRefundAttachment,
  fetchRefundAttachmentUrl,
} from '../../../slices/ReleaseFundsSlice';
import { AppDispatch } from '../../../types';
import { fileTypeToIconMap } from '../../Zen/Transaction/FileCabinet/ZenFileCabinetFileNameCell';
import { getExtensionFromFileName } from '../../../utils/FileUtils';
import ZenIconCircleWithTooltipCell from '../../Zen/Transaction/ZenIconCircleWithTooltipCell';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ReplaceAttachment from './ReplaceAttachment';

interface AttachmentRowProps {
  attachment: ModifiedRefundAttachmentResponse;
  refundId?: string;
  readonly?: boolean;
}

const AttachmentRow: React.FC<AttachmentRowProps> = ({
  attachment,
  refundId,
  readonly,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [viewAttachment, setViewAttachment] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [deletingAttachment, setDeletingAttachment] = useState<
    RefundAttachmentResponse | undefined
  >();
  const [isDeletingAttachment, setIsDeletingAttachment] = useState<boolean>(
    false,
  );
  const [isReplaceModalOpen, setIsReplaceModalOpen] = useState<boolean>(false);

  const handleDownloadAttachment = async () => {
    let res: string | undefined = undefined;
    if (!attachment?.isLocal) {
      res = await dispatch(fetchRefundAttachmentUrl(attachment?.id!));
    } else {
      res = attachment?.path;
    }

    if (res) {
      window.open(res, '_blank');
    }
  };

  return (
    <React.Fragment>
      <div className='flex flex-row justify-between items-start py-3'>
        <div className='flex flex-row flex-grow flex-shrink items-start space-x-4'>
          <div>
            <IconButton
              buttonStyle='border border-primary-blue bg-primary-blue rounded-lg !py-1.5'
              variant='none'
              leftIcon={
                <FontAwesomeIcon
                  icon={solid('eye')}
                  className='h-4 w-4 text-white'
                  aria-label='view'
                />
              }
              onClick={async () => {
                setViewAttachment(true);
                await handleDownloadAttachment();
                setViewAttachment(false);
              }}
              disabled={viewAttachment}
            />
          </div>
          <div className='flex flex-row items-start space-x-2'>
            <div className='w-8 h-8 flex flex-shrink-0 items-center justify-center border border-zen-dark-5 rounded-[5px] text-zen-dark-5'>
              {
                fileTypeToIconMap[
                  getExtensionFromFileName(
                    attachment?.isLocal ? attachment?.name! : attachment?.path!,
                  )
                ]
              }
            </div>
            <div className='flex flex-col'>
              <span className='font-zen-body font-semibold text-base text-zen-dark-9'>
                {attachment?.name}
              </span>
              <span className='font-zen-body font-semibold text-sm text-zen-dark-5'>{`Uploaded: ${
                attachment?.updatedAt
                  ? DateTime.fromMillis(attachment?.updatedAt!).toFormat(
                      'LL/dd/yyyy hh:mm a',
                    )
                  : 'N/A'
              }`}</span>
            </div>
          </div>
        </div>
        <div className='flex flex-row items-start space-x-3 ml-6'>
          {!readonly && !attachment?.isLocal && (
            <ZenIconCircleWithTooltipCell
              tooltipText='Edit'
              icon={
                <FontAwesomeIcon
                  icon={regular('pencil')}
                  title='edit'
                  className='text-zen-dark-8 text-sm'
                />
              }
              onClick={() => setIsReplaceModalOpen(true)}
            />
          )}

          <ZenIconCircleWithTooltipCell
            tooltipText='Download'
            icon={
              <FontAwesomeIcon
                icon={regular('cloud-arrow-down')}
                title='download'
                className='text-zen-dark-8 text-sm'
              />
            }
            onClick={async () => {
              setIsDownloading(true);
              await handleDownloadAttachment();
              setIsDownloading(false);
            }}
            disabled={isDownloading}
          />

          {!readonly && (
            <ZenIconCircleWithTooltipCell
              tooltipText='Delete'
              iconVariant='error'
              icon={
                <FontAwesomeIcon
                  icon={regular('trash-can')}
                  title='delete'
                  className='text-zen-danger text-sm'
                />
              }
              onClick={() => {
                setDeletingAttachment(attachment);
                setIsDeleteModalOpen(true);
              }}
            />
          )}
        </div>
      </div>
      <ZenConfirmationModal
        variant='danger'
        title='Are you sure you want to delete this attachment?'
        subtitle='Deleting this attachment will remove it from the refund.'
        confirmButtonText='Delete'
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={async () => {
          setIsDeletingAttachment(true);
          if (!attachment?.isLocal) {
            await dispatch(
              deleteRefundAttachment(deletingAttachment?.id!, refundId!),
            );
          } else {
            dispatch(deleteAttachment(deletingAttachment?.id!));
          }
          setIsDeletingAttachment(false);
          setIsDeleteModalOpen(false);
          setDeletingAttachment(undefined);
        }}
        isSubmitting={isDeletingAttachment}
        isDisabled={isDeletingAttachment}
      />
      {isReplaceModalOpen && (
        <ReplaceAttachment
          attachment={attachment}
          onClose={() => setIsReplaceModalOpen(false)}
        />
      )}
    </React.Fragment>
  );
};

export default AttachmentRow;
