import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import RecipientStack from './RecipientStack';

interface AnnouncementAvatarStackProps {
  ids?: string[];
}

const AnnouncementAvatarStack: React.FC<AnnouncementAvatarStackProps> = ({
  ids,
}) => {
  const {
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  const agents = (ids || [])?.map((id) => {
    const agentName = !!agentById[id]
      ? `${agentById[id]?.firstName} ${agentById[id]?.lastName}`
      : 'Agent Name';

    return {
      name: agentName,
      id: id,
    };
  });

  return <RecipientStack avatarList={agents} />;
};

export default AnnouncementAvatarStack;
