import { ParticipantValue, ParticipantValueRoleEnum } from '../openapi/arrakis';
import {
  MentionBlock,
  MentionBlockMentionTypeEnum,
  RichText,
  UploadMediaRichBlocksParameterInner,
} from '../openapi/yada';

export const addAutoTagMentionsInComments = (
  isAutoTagAdminInCommentEnabled: boolean,
  otherParticipants: ParticipantValue[] | undefined,
  comment?: RichText,
): RichText | undefined => {
  if (!comment?.blocks?.length) {
    return undefined;
  }

  const commentBlocks = comment?.blocks || [];

  // get all admin participants
  const adminParticipants = (otherParticipants || [])?.filter(
    (participant) => participant?.role === ParticipantValueRoleEnum.Admin,
  );

  // get all admin participants yentaIds
  const adminParticipantsYentaIds = adminParticipants?.map(
    (participant) => participant?.yentaId || '',
  );

  // check if agent is tagged in the comment excluding admin participants
  const isAgentTagged = commentBlocks?.some(
    (block) =>
      (block as MentionBlock)?.type === 'MENTION' &&
      (block as MentionBlock)?.mentionType ===
        MentionBlockMentionTypeEnum.User &&
      !!(block as MentionBlock)?.userId &&
      !adminParticipantsYentaIds?.includes((block as MentionBlock)?.userId!),
  );

  // if auto tag is not enabled or agent is not tagged, return the comment as is
  if (!isAutoTagAdminInCommentEnabled || !isAgentTagged) {
    return comment;
  }

  // find all admin participants that are already tagged in the comment
  const alreadyTaggedAdminsYentaIds = commentBlocks
    ?.filter(
      (block) =>
        !!(block as MentionBlock)?.userId &&
        adminParticipantsYentaIds?.includes((block as MentionBlock)?.userId!),
    )
    ?.map((block) => (block as MentionBlock)?.userId || '');

  // filter out admin participants that are already tagged in the comment
  const updatedAdminParticipants = adminParticipants?.filter(
    (participant) =>
      !alreadyTaggedAdminsYentaIds?.includes(participant?.yentaId!),
  );

  if (!adminParticipants?.length) {
    return comment;
  }

  // create mention blocks for admin participants that are not tagged in the comment yet
  const adminMentions: MentionBlock[] = updatedAdminParticipants?.map(
    (participant) => {
      return {
        type: 'MENTION',
        userId: participant?.yentaId!,
        name: participant?.fullName!,
        autoTag: true,
        mentionType: MentionBlockMentionTypeEnum.User,
      };
    },
  );

  //  add admin mentions at the end of the comment blocks
  commentBlocks?.splice(
    commentBlocks?.length - 1,
    0,
    ...[
      {
        text: ' ',
        type: 'TEXT',
      },
      ...adminMentions,
    ],
  );

  return {
    blocks: commentBlocks,
    preview: comment?.preview,
  };
};

export const getExcludedAutoTagMentionCommentBlocks = (
  blocks: UploadMediaRichBlocksParameterInner[] | undefined,
) => {
  const filterBlocks = (blocks || [])?.filter(
    (block: UploadMediaRichBlocksParameterInner) =>
      !(block as MentionBlock)?.autoTag,
  );

  return filterBlocks;
};

export const getExcludedAutoTagMentionPreview = (
  richText: RichText | undefined,
) => {
  const isAutoTagAdminInCommentEnabled = (richText?.blocks || [])?.some(
    (block) => !!(block as MentionBlock)?.autoTag,
  );

  if (!isAutoTagAdminInCommentEnabled) {
    return richText?.preview;
  }

  const filterBlocks = getExcludedAutoTagMentionCommentBlocks(
    richText?.blocks || [],
  );

  const previewText = filterBlocks?.map((block) => block?.preview).join('');

  return previewText;
};
