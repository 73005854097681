import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownToSquare,
  faArrowRightToBracket,
} from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AgentTwoFactorAuthentication from '../components/Agent/security/AgentTwoFactorAuthentication';
import { RootState } from '../types';
import ZenButton from '../components/Zen/ZenButton';
import { useLogout } from '../hooks/useLogout';
import useQueryParams from '../hooks/useQueryParams';

interface EnableTwoFactorAuthenticationRouteProps {}

interface Query {
  redirectTo?: string;
}

const EnableTwoFactorAuthenticationRoute: React.FC<EnableTwoFactorAuthenticationRouteProps> = () => {
  const history = useHistory();
  const logout = useLogout();
  const { redirectTo } = useQueryParams<Query>();
  const { keymakerCurrentUser } = useSelector((state: RootState) => state.auth);
  const finalUrl = redirectTo ? redirectTo : '/';

  useEffect(() => {
    if (!!keymakerCurrentUser?.mfaType) {
      history.replace(finalUrl);
    }
  }, [finalUrl, history, keymakerCurrentUser]);

  return (
    <div className='bg-gray-100'>
      <div className='flex items-center justify-center h-screen mx-auto'>
        <div className='p-10 bg-white border-2 border-gray-100 lg:w-1/3'>
          <div className='mb-5 -mt-20 text-center'>
            <div className='inline-block p-10 text-primary-light rounded-full bg-primary-dark relative'>
              <FontAwesomeIcon
                icon={faArrowRightToBracket}
                className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-1/3 w-1/3'
              />
            </div>
          </div>
          <div>
            <AgentTwoFactorAuthentication isForceMfa />
          </div>
        </div>
      </div>
      <div className='absolute bottom-0 left-8 px-2 sm:px-4 py-4 sm:py-8'>
        <ZenButton
          label='Logout'
          variant='secondary-outline'
          LeftIconComponent={
            <FontAwesomeIcon
              size='lg'
              icon={faArrowDownToSquare}
              className='-rotate-90 mr-1'
            />
          }
          type='button'
          onClick={async () => {
            await logout();
            history.push(finalUrl);
          }}
        />
      </div>
    </div>
  );
};

export default EnableTwoFactorAuthenticationRoute;
