import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { cn } from '../../utils/classUtils';

interface ZenSearchBarProps {
  value: string;
  onChange: (str: string) => void;
  placeholder?: string;
  showTransition?: boolean;
}

const ZenSearchBar: React.FC<ZenSearchBarProps> = ({
  value,
  onChange,
  placeholder = 'Search',
  showTransition = false,
}) => {
  const [focus, setFocus] = useState<boolean>(false);

  return (
    <div
      className={cn(
        showTransition
          ? `transition-all ease-out duration-700 delay-0 ${
              focus ? 'w-full' : 'w-1/2'
            }`
          : 'w-full',
      )}
    >
      <div
        className={cn(
          'w-full flex flex-row bg-white items-center px-3 font-zen-body text-base border rounded-lg',
          focus ? 'border-zen-dark' : ' border-zen-dark-4',
        )}
      >
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className='text-base text-primary-blue'
        />
        <input
          type='text'
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={value}
          className='w-full appearance-none p-2 border-none bg-transparent focus:outline-none focus:ring-0 primary-light flex-grow'
        />
        {!!value && (
          <FontAwesomeIcon
            icon={faXmark}
            className='text-zen-danger cursor-pointer'
            size='sm'
            onClick={() => onChange('')}
            data-testid='clear-search'
          />
        )}
      </div>
    </div>
  );
};

export default ZenSearchBar;
