import { values } from 'lodash';
import { AdministrativeAreaResponseCountryEnum } from '../../../openapi/yenta';
import { capitalizeEnum } from '../../../utils/StringUtils';
import SelectColumnFilter, {
  SelectColumnFilterProps,
} from './Base/SelectColumnFilter';

export interface CountrySelectColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const CountrySelectColumnFilter = <D extends object>(
  props: CountrySelectColumnFilterProps<D>,
) => {
  return (
    <SelectColumnFilter
      {...props}
      options={values(AdministrativeAreaResponseCountryEnum).map((country) => ({
        value: country,
        label: capitalizeEnum(country),
      }))}
    />
  );
};

export default CountrySelectColumnFilter;
