import { values } from 'lodash';
import { useEffect, useState } from 'react';
import { LicenseResponse } from '../../../openapi/yenta';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenTabs from '../../Zen/ZenTabs';
import ZenTeamAgentLicenseDetail from './ZenTeamAgentLicenseDetails';

interface ZenTeamAgentLicensesProps {
  licenses?: Array<LicenseResponse>;
}

const ZenTeamAgentLicenses: React.FC<ZenTeamAgentLicensesProps> = ({
  licenses,
}) => {
  const tabs = values(licenses).map((license: LicenseResponse) => {
    return {
      name: capitalizeEnum(license?.administrativeArea?.stateOrProvince!),
      TabComponent: <ZenTeamAgentLicenseDetail license={license} />,
    };
  });

  const tabName = tabs[0]?.name?.toString();
  const [licenseTab, setLicenseTab] = useState(tabName);

  useEffect(() => {
    setLicenseTab(tabName);
  }, [licenses, tabName]);

  return (
    <div>
      <ZenTabs
        tabs={tabs}
        selected={licenseTab}
        onChange={setLicenseTab}
        size='sm'
      />
    </div>
  );
};

export default ZenTeamAgentLicenses;
