import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import WebhookTableActionCell from '../components/Webhook/WebhookTableActionCell';
import WebhookTableEventsTooltipCell from '../components/Webhook/WebhookTableEventsTooltipCell';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenButton from '../components/Zen/ZenButton';
import ZenResourceContainer from '../components/Zen/ZenResourceContainer';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenStatusPill from '../components/Zen/ZenStatusPill';
import { WebhookDto } from '../openapi/plutus';
import { fetchWebhooksByUserId } from '../slices/WebhookSlice';
import { RootState } from '../types';

export const columns: Column<WebhookDto>[] = [
  {
    Header: 'Endpoint Name',
    accessor: 'name',
    Cell: ({ value }) => (
      <p className='font-zen-body font-semibold text-sm text-zen-dark-9'>
        {value || 'N/A'}
      </p>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'URL',
    accessor: 'url',
    Cell: ({ value }) => (
      <p className='font-zen-body font-normal text-sm text-zen-dark-9'>
        {value || 'N/A'}
      </p>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Created On',
    accessor: 'createdAt',
    Cell: ({ value }) => {
      if (!value) {
        return (
          <span className='font-zen-body font-normal text-sm text-zen-dark-5'>
            N/A
          </span>
        );
      }

      const date = DateTime.fromMillis(value);

      return (
        <div className='font-normal text-sm font-zen-body'>
          <p> {date.toFormat('LL/dd/yyyy')}</p>
          <p className='text-zen-dark-6'>{date.toFormat('hh:mm a')}</p>
        </div>
      );
    },
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: 'Events',
    accessor: 'subscriptions',
    Cell: ({ value }) => <WebhookTableEventsTooltipCell events={value} />,
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: 'Status',
    accessor: 'active',
    Cell: ({ value }) => (
      <ZenStatusPill
        text={value ? 'Active' : 'Disabled'}
        variant={value ? 'success' : 'danger'}
      />
    ),
    disableFilters: true,
    disableSortBy: false,
  },
];

interface WebhookTableRouteProps {}

const WebhookTableRoute: React.FC<WebhookTableRouteProps> = () => {
  const {
    auth: { userDetail },
    webhooks: { webhooks, webhooksLoading, webhooksByUserIdErrorCode },
  } = useSelector((state: RootState) => state);

  const history = useHistory();
  const dispatch = useDispatch();

  const columnsWithAction: Column<WebhookDto>[] = [
    ...columns,
    {
      Header: 'Action',
      id: 'actions',
      Cell: ({ row: { original } }: CellProps<WebhookDto>) => (
        <WebhookTableActionCell webhook={original} />
      ),
      disableFilters: true,
      disableSortBy: false,
    },
  ];

  useEffect(() => {
    if (isEmpty(webhooks) && userDetail?.id) {
      dispatch(fetchWebhooksByUserId(userDetail?.id));
    }
  }, [webhooks, userDetail?.id, dispatch]);

  return (
    <ZenRoute title='Webhooks'>
      <div className='p-4'>
        <ZenResourceContainer
          isEmpty={isEmpty(webhooks)}
          loading={webhooksLoading}
          errorCode={webhooksByUserIdErrorCode}
          resourceName='Webhook'
        >
          <ZenResourceIndexContainer
            header='Webhooks'
            columns={columnsWithAction}
            data={webhooks}
            resourceName='webhook'
            RightComponent={
              <ZenButton
                label='Add endpoint'
                variant='primary'
                LeftIconComponent={<FontAwesomeIcon icon={regular('plus')} />}
                onClick={() => history.push('/webhook/create')}
              />
            }
            showSearch={false}
            hideFilters
            hidePagination
          />
        </ZenResourceContainer>
      </div>
    </ZenRoute>
  );
};
export default WebhookTableRoute;
