import { faCabinetFiling, faDesktop } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  CHECKLIST_ALLOWED_FILE_TYPES,
  MAX_DOC_SIZE_100MB,
} from '../../../constants/FilesConstants';
import { FileResponse } from '../../../openapi/dropbox';
import { DocumentDto, ItemResponse } from '../../../openapi/sherlock';
import {
  fetchChecklistItemById,
  uploadChecklistDocument,
  uploadToDropboxAndAddFileReferences,
} from '../../../slices/CheckListSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { AppDispatch, RootState } from '../../../types';
import {
  isV2Checklist,
  uploadDocumentsOneByOne,
} from '../../../utils/ChecklistUtils';
import { cn } from '../../../utils/classUtils';
import {
  isDocSizeInvalid,
  validDocs,
  validFormatDocs,
} from '../../../utils/FileUtils';
import DefaultLoader from '../../DefaultLoader';
import ZenDropdown from '../ZenDropdown';
import ZenChecklistDocumentPopper from './ZenChecklistDocumentPopper';

interface ZenChecklistItemDocumentUploadCellProps {
  commentId: string;
  item: ItemResponse;
  containerId: string;
  checklistId: string;
  onClickFileCabinet(): void;
  docs: DocumentDto[];
  dropboxDocs: FileResponse[];
  openEditChecklistItemForm(): void;
  dropboxId: string;
}

const ZenChecklistItemDocumentUploadCell: React.FC<ZenChecklistItemDocumentUploadCellProps> = ({
  commentId,
  item,
  containerId,
  checklistId,
  onClickFileCabinet,
  docs,
  dropboxDocs,
  openEditChecklistItemForm,
  dropboxId,
}) => {
  const {
    auth: { userDetail },
    checklist: {
      documentUploadLoading,
      checklistItemCountById,
      checklistsById,
    },
  } = useSelector((state: RootState) => state);

  const dispatch: AppDispatch = useDispatch();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const isChecklistV2 = isV2Checklist(checklistsById[checklistId]?.data);

  const handleDrop = async (acceptedFiles: File[]) => {
    setIsUploading(true);
    if (validFormatDocs(acceptedFiles, CHECKLIST_ALLOWED_FILE_TYPES)) {
      if (isChecklistV2) {
        const validFiles = validDocs(acceptedFiles, MAX_DOC_SIZE_100MB);
        await dispatch(
          uploadToDropboxAndAddFileReferences(
            checklistId,
            item.id!,
            dropboxId!,
            userDetail?.id!,
            validFiles,
          ),
        );
      } else {
        await uploadDocumentsOneByOne(
          validDocs(acceptedFiles, MAX_DOC_SIZE_100MB),
          (file) =>
            dispatch(
              uploadChecklistDocument(
                item.id!,
                file.name,
                '',
                userDetail?.id!,
                file,
                containerId,
                checklistId,
              ),
            ),
        );
      }
      if (validDocs(acceptedFiles, MAX_DOC_SIZE_100MB)?.length) {
        await dispatch(fetchChecklistItemById(checklistId, item?.id!));
      }
      if (isDocSizeInvalid(acceptedFiles, MAX_DOC_SIZE_100MB)) {
        dispatch(showErrorToast('File size exceeds maximum limit of 100 MB.'));
      }
    } else {
      dispatch(
        showErrorToast(
          `Allowed file types (${CHECKLIST_ALLOWED_FILE_TYPES.join(',')})`,
        ),
      );
    }
    setIsUploading(false);
  };

  const count = checklistItemCountById[commentId]!;

  return (
    <div className='flex items-center text-dark'>
      <div className='border-r border-zen-dark-5 px-3 cursor-pointer'>
        <ZenChecklistDocumentPopper
          docs={docs}
          dropboxDocs={dropboxDocs}
          commentCount={count}
          isChecklistV2={isChecklistV2}
          item={item}
          checklistId={checklistId}
          openEditChecklistItemForm={openEditChecklistItemForm}
        />
      </div>
      <div>
        {documentUploadLoading[item.id!] || isUploading ? (
          <div className='-my-2.5'>
            <DefaultLoader iconSize='small' />
          </div>
        ) : (
          <Dropzone
            noClick
            onDrop={async (acceptedFiles) => {
              handleDrop(acceptedFiles);
            }}
          >
            {({ getRootProps, getInputProps, isDragActive, open }) => (
              <ZenDropdown
                containerClassName='z-50'
                items={[
                  {
                    text: 'From this device',
                    onClick: () => open(),
                    icon: <FontAwesomeIcon icon={faDesktop} size='xs' />,
                  },
                  {
                    text: 'From File Cabinet',
                    onClick: onClickFileCabinet,
                    icon: <FontAwesomeIcon icon={faCabinetFiling} size='sm' />,
                  },
                ]}
                icon={
                  <div
                    {...getRootProps()}
                    className={cn(
                      'flex items-center p-1 text-sm text-primary hover:bg-primary hover:bg-opacity-10 border-2 rounded space-x-1.5 cursor-pointer',
                      isDragActive
                        ? 'border-primary border-dotted'
                        : 'border-transparent',
                    )}
                  >
                    <input
                      {...getInputProps()}
                      id={`file-${item.id}`}
                      data-testid={`file-${item.id}`}
                      type='file'
                      accept='.xlsx, .xls, .doc, .docx, .pdf'
                    />

                    <p className='font-zen-body font-semibold text-sm text-primary-blue'>
                      Upload
                    </p>
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className='ml-1 text-primary-blue'
                      title='file-option'
                    />
                  </div>
                }
                noBorder
              />
            )}
          </Dropzone>
        )}
      </div>
    </div>
  );
};

export default ZenChecklistItemDocumentUploadCell;
