import React from 'react';
import { BankAccountDto } from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { bankAccountTypeVariant } from '../../../utils/TransactionHelper';
import { cn } from '../../../utils/classUtils';

interface MoneyTransferAccountCellProps {
  bankAccount: BankAccountDto;
}

const MoneyTransferAccountCell: React.FC<MoneyTransferAccountCellProps> = ({
  bankAccount,
}) => {
  return (
    <div className='flex flex-row items-center space-x-2'>
      <div
        className={cn(
          'w-1.5 h-12 rounded',
          bankAccountTypeVariant[bankAccount.bankAccountType!],
        )}
      />
      <div className='flex flex-col'>
        <div className='flex flex-row items-center font-primary-regular text-base text-dark space-x-1 min-w-max'>
          <p>{bankAccount.bankName}</p>
          <p>{capitalizeEnum(bankAccount.bankAccountType!)}</p>
        </div>
        <div className='flex flex-row items-center font-primary-regular text-base text-dark space-x-1 min-w-max'>
          <p>{`${bankAccount.bankName} -`}</p>
          <p>{`*${bankAccount.accountNumber?.slice(-4)}`}</p>
        </div>
      </div>
    </div>
  );
};

export default MoneyTransferAccountCell;
