import React from 'react';
import { EnumMap } from '../../../types';
import { cn } from '../../../utils/classUtils';

export type ZenPillVariant =
  | 'primary'
  | 'primary-nooutline'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'error'
  | 'danger'
  | 'purple'
  | 'secondary-purple'
  | 'default'
  | 'default-nooutline'
  | 'gray'
  | 'newestblue'
  | 'success-dark'
  | 'danger-dark';

interface GeminiOfficeTransactionTypePillProps {
  title: string;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  variant: ZenPillVariant;
  border?: boolean;
  textStyle?: string;
}

const GeminiOfficeTransactionTypePill: React.FC<GeminiOfficeTransactionTypePillProps> = ({
  title,
  leftIcon,
  rightIcon,
  variant = 'default',
  border = true,
  textStyle,
}) => {
  const borderClass = border ? '' : 'border-0';

  const variantToClassNameMap: EnumMap<ZenPillVariant, string> = {
    default: 'bg-white text-gray-400 border border-gray-100',
    'default-nooutline': 'bg-white text-gray-400',
    primary:
      'bg-zen-primary text-zen-primary bg-opacity-20 border border-zen-primary',
    'primary-nooutline': 'bg-rezen-light-blue-100 text-primary-navy',
    danger:
      'bg-zen-danger text-zen-danger bg-opacity-20 border border-zen-danger',
    success:
      'bg-zen-success text-zen-success bg-opacity-20 border border-zen-success',
    secondary:
      'bg-secondary-gray text-secondary-gray bg-opacity-20 border border-secondary-gray',
    warning:
      'bg-zen-warning text-zen-warning bg-opacity-20 border border-zen-warning',
    purple: 'bg-purple-300 text-purple-600',
    'secondary-purple': 'bg-zen-purple-light text-zen-purple-dark',
    error: 'bg-zen-danger-light text-zen-danger',
    gray: 'bg-zen-dark-10 text-white',
    newestblue: 'bg-zen-primary-4 text-zen-primary',
    'success-dark': 'bg-zen-success-light text-zen-success-dark',
    'danger-dark': 'bg-zen-danger-light text-zen-danger',
  };

  return (
    <div
      className={cn(
        'flex flex-row justify-center items-center space-x-0.5 rounded-full px-3 py-1 text-sm',
        variantToClassNameMap[variant],
        borderClass,
      )}
    >
      {leftIcon && <span>{leftIcon}</span>}
      <span className={cn('font-inter font-medium', textStyle)}>{title}</span>
      {rightIcon && <span>{rightIcon}</span>}
    </div>
  );
};

export default GeminiOfficeTransactionTypePill;
