import { DateTime } from 'luxon';
import React from 'react';
import {
  ReferralCentralReferralDto,
  ReferralCentralReferralDtoStatusEnum,
} from '../../../openapi/arrakis';
import ReferralTransitionStatus from './ReferralTransitionStatus';

interface MyReferralCardHeaderProps {
  referralDetails: ReferralCentralReferralDto;
}

const MyReferralCardHeader: React.FC<MyReferralCardHeaderProps> = ({
  referralDetails,
}) => {
  const ReferralStatus = () => {
    if (
      referralDetails.status === ReferralCentralReferralDtoStatusEnum.Posted ||
      referralDetails.status === ReferralCentralReferralDtoStatusEnum.Paused ||
      referralDetails.status === ReferralCentralReferralDtoStatusEnum.Accepted
    ) {
      return (
        <p className='text-grey-500 text-sm pt-1 pb-[5px]'>
          Created :{' '}
          {DateTime.fromMillis(referralDetails.createdAt! || 0).toLocaleString(
            DateTime.DATE_MED,
          )}
        </p>
      );
    } else {
      return <p className='text-grey-500 text-sm'>Status</p>;
    }
  };

  return (
    <div className='flex flex-row items-center justify-between px-3 py-2'>
      <ReferralStatus />
      {referralDetails.status !== ReferralCentralReferralDtoStatusEnum.Posted &&
        referralDetails.status !==
          ReferralCentralReferralDtoStatusEnum.Paused && (
          <ReferralTransitionStatus referralDetails={referralDetails} />
        )}
    </div>
  );
};

export default MyReferralCardHeader;
