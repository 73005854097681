import classNames from 'classnames';

import {
  faBadgeCheck,
  faCircleCheck,
  faEnvelopeOpen,
  faFileCircleCheck,
  faFileCircleExclamation,
  faFileSignature,
  faHandHoldingDollar,
  faHourglassHalf,
  faHouseChimneyUser,
  faHouseCircleCheck,
  faLink,
  faMoneyBillTransfer,
  faSackDollar,
  faSackXmark,
  faSparkles,
  faThumbsUp,
  faUser,
  faUserCheck,
  faUserMinus,
  faUsersMedical,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  TeamInvitationSimplifiedDtoInvitationStatusEnum,
  UserPreviewResponseAgentStatusEnum,
} from '../../../../openapi/yenta';
import { EnumMap } from '../../../../types';
import { safeEnumMapGet } from '../../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import { PillVariantType } from '../../ZenStatusPill';

interface ZenInvitedMemberStatusCellProps {
  status:
    | TeamInvitationSimplifiedDtoInvitationStatusEnum
    | UserPreviewResponseAgentStatusEnum;
}

const statusToPillType: EnumMap<
  | TeamInvitationSimplifiedDtoInvitationStatusEnum
  | UserPreviewResponseAgentStatusEnum,
  PillVariantType
> = {
  STARTED: 'primary',
  ICA_SIGNED: 'warning',
  LETTER_OF_INTENT_SIGNED: 'warning',
  SIGN_LETTER_OF_INTENT: 'warning',
  REJECTED: 'danger',
  APPROVED: 'success',
  BOARD_JOINED: 'warning',
  FEES_PAID: 'warning',
  JOIN_BOARD: 'warning',
  JOIN_MLS: 'warning',
  LICENSE_TRANSFERRED: 'warning',
  MLS_JOINED: 'warning',
  PAY_FEES: 'warning',
  PENDING_APPROVAL: 'warning',
  SIGN_ICA: 'warning',
  TRANSFER_LICENSE: 'warning',
  CREATED: 'primary',
  FEES_WAIVED: 'warning',
  EMAILED: 'success',
  EMAIL_CLICKED: 'success',
  CANCELLED: 'danger',
  PENDING: 'warning',
  REDEEMED: 'success',
  CANDIDATE: 'default',
  ACTIVE: 'success',
  INACTIVE: 'danger',
  WAITING_ON_LICENSE_TRANSFER: 'primary',
  LINK_CLICKED: 'default',
};

const invitationStatusIconMap: EnumMap<
  | TeamInvitationSimplifiedDtoInvitationStatusEnum
  | UserPreviewResponseAgentStatusEnum,
  React.ReactElement
> = {
  STARTED: <FontAwesomeIcon icon={faSparkles} />,
  APPROVED: <FontAwesomeIcon icon={faThumbsUp} />,
  REJECTED: <FontAwesomeIcon icon={faXmark} />,
  FEES_PAID: <FontAwesomeIcon icon={faSackDollar} />,
  ICA_SIGNED: <FontAwesomeIcon icon={faFileSignature} />,
  BOARD_JOINED: <FontAwesomeIcon icon={faBadgeCheck} />,
  LETTER_OF_INTENT_SIGNED: <FontAwesomeIcon icon={faFileCircleCheck} />,
  SIGN_LETTER_OF_INTENT: <FontAwesomeIcon icon={faFileSignature} />,
  JOIN_BOARD: <FontAwesomeIcon icon={faUsersMedical} />,
  JOIN_MLS: <FontAwesomeIcon icon={faHouseChimneyUser} />,
  LICENSE_TRANSFERRED: <FontAwesomeIcon icon={faCircleCheck} />,
  MLS_JOINED: <FontAwesomeIcon icon={faHouseCircleCheck} />,
  PAY_FEES: <FontAwesomeIcon icon={faHandHoldingDollar} />,
  PENDING_APPROVAL: <FontAwesomeIcon icon={faFileCircleExclamation} />,
  SIGN_ICA: <FontAwesomeIcon icon={faHourglassHalf} />,
  TRANSFER_LICENSE: <FontAwesomeIcon icon={faMoneyBillTransfer} />,
  CREATED: <FontAwesomeIcon icon={faSparkles} />,
  FEES_WAIVED: <FontAwesomeIcon icon={faSackXmark} />,
  CANCELLED: <FontAwesomeIcon icon={faXmark} />,
  PENDING: <FontAwesomeIcon icon={faFileCircleExclamation} />,
  EMAILED: <FontAwesomeIcon icon={faEnvelopeOpen} />,
  EMAIL_CLICKED: <FontAwesomeIcon icon={faEnvelopeOpen} />,
  REDEEMED: <FontAwesomeIcon icon={faThumbsUp} />,
  ACTIVE: <FontAwesomeIcon icon={faUserCheck} />,
  CANDIDATE: <FontAwesomeIcon icon={faUser} />,
  INACTIVE: <FontAwesomeIcon icon={faUserMinus} />,
  WAITING_ON_LICENSE_TRANSFER: <FontAwesomeIcon icon={faMoneyBillTransfer} />,
  LINK_CLICKED: <FontAwesomeIcon icon={faLink} />,
};

export const pillVariantTypeToClassNameMap: {
  [type in PillVariantType]: string;
} = {
  default: 'text-dark',
  primary: 'text-primary-blue',
  success: 'text-green-600',
  warning: 'text-zen-warning',
  danger: 'text-zen-danger',
  purple: 'text-zen-purple',
  warningTwo: 'text-zen-warning-3',
};

const ZenInvitedMemberStatusCell: React.FC<ZenInvitedMemberStatusCellProps> = ({
  status,
}) => {
  return (
    <div
      className={classNames(
        'w-max flex items-center space-x-1.5 flex-grow flex-shrink-0 py-1.5 px-2 rounded-full md:whitespace-nowrap',
        pillVariantTypeToClassNameMap[
          safeEnumMapGet(statusToPillType, status, 'primary')
        ],
      )}
    >
      {safeEnumMapGet(
        invitationStatusIconMap,
        status,
        <FontAwesomeIcon icon={faSparkles} />,
      )}
      <p className='font-zen-title font-normal text-sm flex-grow mt-1'>
        {capitalizeEnum(status)}
      </p>
    </div>
  );
};

export default ZenInvitedMemberStatusCell;
