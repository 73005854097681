import { AxiosRequestConfig } from 'axios';
import { DateTime } from 'luxon';
import {
  AuthorizedPersonDto,
  AuthorizedPersonDtoTypeEnum,
  BorrowerDto,
  BorrowerDtoStatusEnum,
  LeadLoanDto,
  LoanDto,
  LoanDtoLoanTypeEnum,
  LoansApi,
} from '../openapi/atlantis';
import { EnumMap, IPaginateReq, ISelectOption, SearchParam } from '../types';
import { getFullName } from './AgentHelper';
import { getAtlantisConfiguration } from './OpenapiConfigurationUtils';
import { SortDirectionTypeEnum } from './TableUtils';

export enum PropertyTypeEnum {
  SingleFamily = 'SINGLE_FAMILY',
  Condo = 'CONDO',
  Townhouse = 'TOWNHOUSE',
  TwoFourUnitResidence = '2-4_UNIT_RESIDENCE',
}

export enum PropertyOwnEnum {
  PrimaryResidence = 'PRIMARY_RESIDENCE',
  FHASecondaryResidence = 'FHA_SECONDARY_RESIDENCE',
  SecondHome = 'SECOND_HOME',
  InvestmentProperty = 'INVESTMENT_PROPERTY',
}

export enum PropertyPurposeEnum {
  PrimaryResidence = 'PRIMARY_RESIDENCE',
  SecondaryResidence = 'SECONDARY_RESIDENCE',
  Investment = 'INVESTMENT',
}

export enum AccountTypeEnum {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
  MoneyMarketFund = 'MONEY_MARKET_FUND',
  Investment = 'INVESTMENT',
  CashValueOfLifeInsurance = 'CASH_VALUE_OF_LIFE_INSURANCE',
  Retirement = 'Retirement',
  Gift = 'GIFT',
  Other = 'OTHER',
}

export enum PrimaryResidenceEnum {
  Own = 'OWN',
  Rent = 'RENT',
  LiveRentFree = 'LIVE_RENT_FREE',
}

export enum EmploymentTypeEnum {
  Employed = 'EMPLOYED',
  SelfEmployed = 'SELF_EMPLOYED',
  RetiredWithPassiveIncome = 'RETIRED_/_PASSIVE_INCOME',
  Unemployed = 'I_AM_NOT_EMPLOYED',
}

export enum CollectionMethodEnum {
  Phone = 'PHONE',
  EmailOrInternet = 'EMAIL_OR_INTERNET',
  FaceToFace = 'FACE_TO_FACE_(INCLUDING_VIDEO_CONFERENCE)',
  FaxOrMail = 'FAX_OR_MAIL',
}

export enum SuffixEnum {
  Junior = 'Jr.',
  Senior = 'Sr.',
  Second = 'II',
  Third = 'III',
  Fourth = 'IV',
}

export enum CitizenshipEnum {
  USCitizen = 'US_CITIZEN',
  PermanentResident = 'PERMANENT_RESIDENT',
  VisaHolder = 'VISA_HOLDER',
}

export enum MilitaryServiceEnum {
  ActiveDuty = 'CURRENTLY_ON_ACTIVE_DUTY',
  Retired = 'RETIRED,_DISCHARGED,_OR_SEPARATED_FROM_SERVICE',
  NonActivated = 'NON-ACTIVATED_RESERVE_OR_NATIONAL_GUARD_MEMBER',
  SurvivingSpouse = 'SURVIVING_SPOUSE',
  NotServed = 'HAVE_NOT_SERVED_IN_ARMED_FORCES',
}

export enum TitleMethodEnum {
  ByYourself = 'BY_YOURSELF',
  JointlyWithSpouse = 'JOINTLY_WITH_YOUR_SPOUSE',
  JointlyWithAnother = 'JOINTLY_WITH_ANOTHER_PERSON',
}

export enum HispanicOrLatinoEnum {
  Mexican = 'MEXICAN',
  PuertoRican = 'PUERTO_RICAN',
  Cuban = 'CUBAN',
  Other = 'OTHER_HISPANIC_OR_LATINO',
}

export enum EthnicityEnum {
  HispanicOrLatino = 'HISPANIC_OR_LATINO',
  NotHispanicOrLatino = 'NOT_HISPANIC_OR_LATINO',
  DoNotWishToProvide = 'DO_NOT_WISH_TO_PROVIDE',
}

export enum RaceEnum {
  AmericanIndianOrAlaskaNative = 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  Asian = 'ASIAN',
  BlackOrAfricanAmerican = 'BLACK_OR_AFRICAN_AMERICAN',
  HawaiianOrPacificIslander = 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  White = 'WHITE',
  DoNotWishToProvide = 'I_DO_NOT_WISH_TO_PROVIDE_THIS_INFORMATION',
}

export enum AsianEnum {
  AsianIndian = 'ASIAN_INDIAN',
  Chinese = 'CHINESE',
  FIlipino = 'FILIPINO',
  Japanese = 'JAPANESE',
  Korean = 'KOREAN',
  Vietnamese = 'VIETNAMESE',
  OtherAsian = 'OTHER_ASIAN',
}

export enum HawaiianPacificIslanderEnum {
  NativeHawaiian = 'NATIVE_HAWAIIAN',
  Samoan = 'SAMOAN',
  GuamanianOrChamorro = 'GUAMANIAN_OR_CHAMORRO',
  OtherPacificIslander = 'OTHER_PACIFIC_ISLANDER',
}

export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  DoNotWishToProvide = 'I_DO_NOT_WISH_TO_PROVIDE_THIS_INFORMATION',
}

export const WhatYouEarnShowEmploymentFields = (
  employmentType: EmploymentTypeEnum,
) => {
  return [
    EmploymentTypeEnum.Employed,
    EmploymentTypeEnum.SelfEmployed,
  ].includes(employmentType);
};

export const isLessThanTwoYears = (date: string): boolean => {
  if (date) {
    return DateTime.fromISO(date).diffNow('years').years > -2;
  }

  return false;
};

export const getIndexesFromPosition = <T>(
  array: T[],
  startPosition: number,
): number[] => {
  return (array || []).slice(startPosition).map((_, i) => i + startPosition);
};

export const getBorrower = (
  borrowerId: string | undefined,
  borrowers: BorrowerDto[],
) => {
  if (!borrowerId) {
    return;
  }

  return borrowers?.find((borrower) => borrower?.id === borrowerId);
};

export interface MortgageSplitPDFType {
  loanId?: string;
  borrowerId?: string;
  flag: boolean;
}

export const canUploadPreApprovalLetter = (
  loan?: LoanDto,
  activeBorrower?: BorrowerDto,
) => {
  const isLoanTypeRefinance = loan?.loanType === LoanDtoLoanTypeEnum.Refinance;

  if (isLoanTypeRefinance || !activeBorrower?.status) {
    return false;
  }

  return [
    BorrowerDtoStatusEnum.ApplicationSubmitted,
    BorrowerDtoStatusEnum.LoanPreApproved,
  ].includes(activeBorrower?.status);
};

export const canSubmitApplication = (activeBorrower?: BorrowerDto) => {
  if (!activeBorrower?.status) {
    return false;
  }

  return [
    BorrowerDtoStatusEnum.AccountCreated,
    BorrowerDtoStatusEnum.ApplicationInProgress,
  ].includes(activeBorrower.status);
};

export const stripTags = (text: string | undefined) => {
  return text?.replace(/<\/?[^>]+(>|$)/g, '');
};

export const getAuthorizedPerson = (
  authorizedPersons: AuthorizedPersonDto[] = [],
  type: AuthorizedPersonDtoTypeEnum,
): ISelectOption | undefined => {
  const authorizedPerson = authorizedPersons.find(
    (authorizedPerson) => authorizedPerson.type === type,
  );

  if (!authorizedPerson) {
    return undefined;
  }

  return {
    value: authorizedPerson.id,
    label: getFullName(authorizedPerson)!,
  };
};

export type MortgageLeadAndLoansSortByType = Parameters<
  typeof LoansApi.prototype.getLeadsAndLoansForAuthorizedUser
>[11];

export const MortgageLeadAndLoansSortByTypeEnum: EnumMap<
  string,
  MortgageLeadAndLoansSortByType
> = {
  clientName: ['BORROWER_FIRST_NAME', 'BORROWER_LAST_NAME'],
  status: ['STATUS'],
  location: ['STATE'],
  createdAt: ['CREATED_AT'],
};

export const fetchMortgageLeadAndLoans = async (
  req: IPaginateReq<LeadLoanDto>,
  axiosOptions?: AxiosRequestConfig,
) => {
  const sortKey = Object.keys(req.sortBy || {})[0];
  const sortType = Object.values(req.sortBy || {})[0];

  const { data } = await new LoansApi(
    getAtlantisConfiguration(),
  ).getLeadsAndLoansForAuthorizedUser(
    req.search,
    (req.filter?.status as SearchParam)?.values as any[],
    (req.filter?.location as SearchParam)?.values as any[],
    req.filter?.loanNumber as string,
    req.filter?.clientName as string,
    req.filter?.email as string,
    req.filter?.loanOfficer as string,
    req.filter?.loanProcessor as string,
    req.filter?.realOriginateLoanOfficer as string,
    req.page,
    req.pageSize,
    MortgageLeadAndLoansSortByTypeEnum[sortKey!],
    SortDirectionTypeEnum[sortType!],
    axiosOptions,
  );

  return {
    data: data?.content || [],
    total: data?.totalElements || 0,
  };
};
