import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { kebabCase } from 'lodash';
import {
  SectionProgressDto,
  SectionProgressDtoStatusEnum,
} from '../../../openapi/atlantis';
import { cn } from '../../../utils/classUtils';

interface MortgageSidebarSectionItemProps {
  section: SectionProgressDto;
}

export const MortgageSidebarSectionItem: React.FC<MortgageSidebarSectionItemProps> = ({
  section,
}) => {
  const isCompleted = section.status === SectionProgressDtoStatusEnum.Completed;
  const inProgress = section.status === SectionProgressDtoStatusEnum.InProgress;

  const handleScrollToSection = (id: string) => {
    document.getElementById('main-page')?.scrollTo({
      top: document.getElementById(id)?.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <div key={section.name} className='mb-5 flex items-center'>
      <div
        className={cn(
          'z-[1] w-6 h-6 shrink-0 flex items-center justify-center rounded-full hover:cursor-pointer',
          isCompleted ? 'bg-primary-blue' : 'bg-white',
        )}
      >
        <FontAwesomeIcon
          icon={isCompleted ? solid('check') : solid('circle')}
          fontSize={isCompleted ? 12 : 18}
          className={
            inProgress
              ? 'text-primary-blue'
              : isCompleted
              ? 'text-white'
              : 'text-gray-200'
          }
        />
      </div>
      <button
        className={cn(
          'ml-2 font-zen-body text-xs font-normal text-left',
          inProgress ? 'text-primary-blue' : 'text-zen-dark-9',
        )}
        onClick={() => handleScrollToSection(kebabCase(section.name))}
      >
        {section.name}
      </button>
    </div>
  );
};

export default MortgageSidebarSectionItem;
