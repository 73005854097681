import React from 'react';
import RealLogoImg from '../../../assets/img/new-rezen-black-logo.svg';
import ZenSimpleModal from './ZenSimpleModal';

interface ZenBrowserPopupModalProps {
  browserName: string;
}

const ZenBrowserPopupModal: React.FC<ZenBrowserPopupModalProps> = ({
  browserName,
}) => {
  return (
    <ZenSimpleModal
      title='Your Browser is Incompatible'
      isOpen
      onClose={() => {}}
      hideIcon
    >
      <div className='m-6 flex flex-col'>
        <div className='flex flex-row justify-center pb-4 px-4'>
          <img src={RealLogoImg} className='h-7' alt='Real Logo' />
        </div>
        <p className='font-zen-body text-base text-zen-dark-9'>
          You are using an older version of {browserName} that is incompatible
          with reZEN. Please upgrade to the latest version of {browserName} to
          continue to use reZEN.
        </p>
      </div>
    </ZenSimpleModal>
  );
};

export default ZenBrowserPopupModal;
