import { cn } from '../utils/classUtils';

export interface ApplicationProgressBarCardProps {
  onStep: number;
  total: number;
  bgColorClassName?: string;
}

const ApplicationProgressBarCard: React.FC<ApplicationProgressBarCardProps> = ({
  onStep,
  total,
  bgColorClassName = 'bg-dark',
}) => {
  const progress = Math.round(((onStep - 1) / total) * 100);

  return (
    <div className='text-sm border border-gray-200 rounded px-3 pt-1.5 pb-3 w-full'>
      <div className='flex justify-between'>
        <p>{progress}% Completed</p>
        <p>
          {onStep}/{total}
        </p>
      </div>
      <div className='mt-1 bg-gray-100 rounded'>
        <div
          className={cn('rounded h-1.5', bgColorClassName)}
          style={{
            width: `${progress}%`,
          }}
        />
      </div>
    </div>
  );
};

export default ApplicationProgressBarCard;
