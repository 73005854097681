import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faTableColumns } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { useGemini } from '../../hooks/useGemini';
import { WebhookDto } from '../../openapi/plutus';
import { fetchWebhooksByUserId } from '../../slices/WebhookSlice';
import { RootState } from '../../types';
import { cn } from '../../utils/classUtils';
import DefaultEmpty from '../DefaultEmpty';
import ZenFixedDataTable from '../Zen/Table/ZenFixedDataTable';
import ZenAction from '../Zen/ZenAction';
import ZenCard from '../Zen/ZenCard';
import ZenResourceContainer from '../Zen/ZenResourceContainer';
import ZenStatusPill from '../Zen/ZenStatusPill';

const Columns: Column<WebhookDto>[] = [
  {
    Header: 'Endpoint Name',
    accessor: 'name',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => {
      return (
        <p className='font-zen-body font-normal text-sm text-zen-dark-9'>
          {value}
        </p>
      );
    },
  },
  {
    Header: 'URL',
    accessor: 'url',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => {
      return (
        <p className='w-60 break-all font-zen-body font-normal text-sm text-zen-dark-9'>
          {value}
        </p>
      );
    },
  },
  {
    Header: 'Status',
    accessor: 'active',
    Cell: ({ value }) => (
      <div className='!font-primary-regular font-medium my-2'>
        <ZenStatusPill
          text={value ? 'Active' : 'Disabled'}
          variant={value ? 'success' : 'danger'}
        />
      </div>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
];

interface WebhookCardProps {}

const WebhookCard: React.FC<WebhookCardProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    auth: { userDetail },
    webhooks: { webhooks, webhooksLoading, webhooksByUserIdErrorCode },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (userDetail?.id) {
      dispatch(fetchWebhooksByUserId(userDetail?.id));
    }
  }, [userDetail?.id, dispatch]);

  const isGeminiEnabled = useGemini();

  return (
    <ZenCard
      title='Webhooks'
      RightComponent={
        <ZenAction
          text='Add Webhook'
          icon={
            <FontAwesomeIcon
              icon={faPlus}
              className={cn(
                isGeminiEnabled
                  ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                  : 'text-primary-blue',
              )}
            />
          }
          onClick={() => history.push('/webhook/create')}
        />
      }
    >
      <ZenResourceContainer
        isEmpty={isEmpty(webhooks)}
        loading={webhooksLoading}
        errorCode={webhooksByUserIdErrorCode}
        resourceName='Webhook'
        EmptyComponent={
          <div className='my-28'>
            <DefaultEmpty
              message='There are no webhooks added in here'
              icon={<FontAwesomeIcon icon={faTableColumns} size='2x' />}
            />
          </div>
        }
      >
        <div className='flex flex-col justify-between min-h-[415px]'>
          <ZenFixedDataTable
            data={webhooks?.slice(0, 4) || []}
            columns={Columns}
            resourceName='Webhook'
            hideFilters
            hidePageSize
            hidePagination
            allowSortingRows={false}
            headerVariant='light'
          />

          <div className='flex items-center justify-center py-3 border-t'>
            <p
              className='text-base text-primary-blue cursor-pointer'
              onClick={() => history.push(`/people/${userDetail?.id}/webhooks`)}
            >
              View All
            </p>
            <FontAwesomeIcon
              icon={regular('arrow-right-long')}
              className='text-primary-blue ml-2 -mt-px'
            />
          </div>
        </div>
      </ZenResourceContainer>
    </ZenCard>
  );
};

export default WebhookCard;
