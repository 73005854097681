import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../types';
import { waiveApplicationFeeById } from '../../slices/ApplicationSlice';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';

interface ZenApplicationWaiveFeeProps {
  id: string;
  isOpen: boolean;
  onClose(): void;
}

const ZenApplicationWaiveFeeModal: React.FC<ZenApplicationWaiveFeeProps> = ({
  id,
  isOpen,
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <ZenConfirmationModal
      variant='primary'
      title='Are you sure you want to waive fees for this application?'
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => {
        dispatch(waiveApplicationFeeById(id));
        onClose();
      }}
      confirmButtonText='Confirm'
      cancelButtonText='Cancel'
    />
  );
};

export default ZenApplicationWaiveFeeModal;
