import React from 'react';
import PageTitle from '../helmet/PageTitle';

interface ZenRouteProps {
  title: string;
}

const ZenRoute: React.FC<ZenRouteProps> = ({ title, children }) => {
  return (
    <>
      <PageTitle title={title} />
      {children}
    </>
  );
};

export default ZenRoute;
