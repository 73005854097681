import { getFormattedDateTimeWithTimezone } from '../../../utils/DateUtils';

export interface MilliDateTimeCellProps {
  date: number | undefined;
}

const MilliDateTimeCell: React.FC<MilliDateTimeCellProps> = ({ date }) => {
  return <span>{date ? getFormattedDateTimeWithTimezone(date) : 'N/A'}</span>;
};

export default MilliDateTimeCell;
