import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CommissionAdvanceResponse } from '../../../openapi/arrakis';
import { RootState } from '../../../types';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import ZenSimpleConfirmationModal from '../../Zen/Modal/ZenSimpleConfirmationModal';
import ZenAdd from '../../Zen/ZenAdd';
import ZenCard from '../../Zen/ZenCard';
import ZenAddAgentCommissionAdvancesSidebarForm from '../ZenAddAgentCommissionAdvancesSidebarForm';
import ZenEditAgentCommissionAdvancesSidebarForm from '../ZenEditAgentCommissionAdvancesSidebarForm';
import { DemoModeContainer } from '../../../containers/DemoModeContainer';
import AdminOnly from '../../auth/AdminOnly';
import { useRefresh } from '../../../hooks/useRefresh';
import ZenAgentCommissionAdvancesTable from './ZenAgentCommissionAdvancesTable';

interface ZenAgentCommissionAdvancesProps {
  agentId: string;
}

const ZenAgentCommissionAdvances: React.FC<ZenAgentCommissionAdvancesProps> = ({
  agentId,
}) => {
  const {
    agentDetail: { detailResponse, commissionAdvancesResponse },
  } = useSelector((state: RootState) => state);
  const [
    addingCommissionAdvances,
    setAddingCommissionAdvances,
  ] = useState<boolean>(false);
  const [
    editingCommissionAdvances,
    setEditingCommissionAdvances,
  ] = useState<CommissionAdvanceResponse | null>(null);
  const [
    deletingCommissionAdvances,
    setDeletingCommissionAdvances,
  ] = useState<CommissionAdvanceResponse | null>(null);
  const { key, refresh } = useRefresh();

  return (
    <div>
      <DemoModeContainer hide>
        <ZenCard
          title='Commission Advances'
          RightComponent={
            <AdminOnly>
              <div className='flex flex-row items-center space-x-4'>
                <ZenAdd onClick={() => setAddingCommissionAdvances(true)} />
              </div>
            </AdminOnly>
          }
        >
          <AuthorizationContainer asyncResponse={commissionAdvancesResponse}>
            <ZenAgentCommissionAdvancesTable
              agentId={agentId}
              setEditingCommissionAdvances={setEditingCommissionAdvances}
              key={key}
            />
          </AuthorizationContainer>
        </ZenCard>
      </DemoModeContainer>

      {addingCommissionAdvances && !!detailResponse?.data && (
        <ZenAddAgentCommissionAdvancesSidebarForm
          isOpen={addingCommissionAdvances}
          onClose={() => setAddingCommissionAdvances(false)}
          agent={detailResponse?.data}
          refresh={refresh}
        />
      )}
      <ZenEditAgentCommissionAdvancesSidebarForm
        isOpen={!!editingCommissionAdvances}
        onClose={() => setEditingCommissionAdvances(null)}
        commissionAdvance={editingCommissionAdvances!}
        refresh={refresh}
      />
      <ZenSimpleConfirmationModal
        variant='warning'
        title='Are you sure?'
        subtitle='This commission advance will be permanently deleted.'
        isOpen={!!deletingCommissionAdvances}
        onClose={() => setDeletingCommissionAdvances(null)}
        onConfirm={() => {}}
        isSubmitting={false}
      />
    </div>
  );
};

export default ZenAgentCommissionAdvances;
