import { faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React from 'react';
import { CommentDto } from '../../../../openapi/yada';
import { AgentCommentDetails } from '../../../../openapi/yenta';
import { Mapping } from '../../../../types';
import { getExcludedAutoTagMentionPreview } from '../../../../utils/CommentUtils';
import ZenAvatar from '../../ZenAvatar';

interface ZenLastCommentCellProps {
  comment: CommentDto;
  agentByKeymakerId: Mapping<AgentCommentDetails>;
  onClick: () => void;
}

const ZenLastCommentCell: React.FC<ZenLastCommentCellProps> = ({
  comment,
  agentByKeymakerId,
  onClick,
}) => {
  const agentData = agentByKeymakerId[comment?.createdById!];

  return (
    <div
      className='w-80 border border-zen-dark-5 rounded-lg p-2 bg-opacity-40'
      onClick={onClick}
    >
      <div className='flex items-center justify-between'>
        {comment ? (
          <div className='w-full flex items-start space-x-2'>
            {agentData && (
              <ZenAvatar
                name={`${agentData?.firstName} ${agentData?.lastName}`}
                imageUrl={agentData?.avatar!}
                size='xs'
              />
            )}
            <span className='max-h-28 overflow-x-hidden scrollbar overflow-y-auto font-zen-body text-sm font-normal text-zen-dark-8 flex-grow word-break'>
              <span className='m-0 p-0'>
                {getExcludedAutoTagMentionPreview(comment?.richContent)}
              </span>
              <span className='text-xs text-zen-dark-8 whitespace-nowrap pl-1'>
                {DateTime.fromMillis(comment.createdAt || 0).toFormat(
                  'LL/dd/yyyy',
                )}
              </span>
            </span>
          </div>
        ) : (
          <span className='font-zen-body font-normal text-sm text-zen-dark-8'>
            Add comment
          </span>
        )}
        <FontAwesomeIcon
          icon={faPaperPlaneTop}
          className='text-zen-dark-6 text-lg'
        />
      </div>
    </div>
  );
};

export default ZenLastCommentCell;
