import { useCallback, useEffect, useMemo, useState } from 'react';
import { Column, IdType } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  InvoiceControllerApi,
  InvoiceResponse,
  InvoiceResponseStatusEnum,
  TransactionControllerApi,
  TransactionLifecycleStateValueStateEnum,
} from '../../../openapi/arrakis';
import IconButton from '../../IconButton';
import TextColumnFilter from '../../table/Filters/TextColumnFilter';
import NullableTextCell from '../../table/Cells/NullableTextCell';
import { displayAmount } from '../../../utils/CurrencyUtils';
import NumberColumnFilter from '../../table/Filters/NumberColumnFilter';
import InvoiceStatusSelectColumnFilter from '../../table/Filters/InvoiceStatusSelectColumnFilter';
import TextStrictCaseColumnFilter from '../../table/Filters/TextStrictCaseColumnFilter';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import {
  showErrorToast,
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import ErrorService from '../../../services/ErrorService';
import { getInvoiceTableFetchData } from '../../../utils/TableUtils';
import { AppDispatch, FeatureFlagTypeEnum, RootState } from '../../../types';
import { transitionTransaction } from '../../../slices/TransactionSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import ZenResourceIndexContainer from '../../Zen/Containers/ZenResourceIndexContainer';
import ZenViewActionButtonCell from '../../table/Cells/ZenViewActionButtonCell';
import ZenTransactionCodeCell from '../Transaction/ZenTransactionCodeCell';
import ZenInvoicesStatusCell from '../Table/Cell/ZenInvoicesStatusCell';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import ZenRetryInvoiceModal from './ZenRetryInvoiceModal';
import ZenInvoiceFormSidebarModal from './ZenInvoiceFormSidebarModal';

interface ZenInvoiceResourceTableProps {
  hiddenColumns?: Array<IdType<InvoiceResponse>>;
  allowSelection?: boolean;
  search?: string | undefined;
  isTransactionPaymentInvoices?: boolean;
}

export const columns: Array<Column<InvoiceResponse>> = [
  {
    Header: 'Name',
    accessor: 'firstName',
    Filter: TextColumnFilter,
    Cell: ({ value, row: { original } }) => (
      <p className='w-max'>{`${value} ${original.lastName}`}</p>
    ),
  },
  {
    Header: 'Transaction Code',
    accessor: 'transactionCode',
    Cell: ({ row: { original } }) => (
      <ZenTransactionCodeCell
        linkTo={`/transactions/code/${original.transactionCode}`}
        transaction={{ ...original, code: original.transactionCode }}
        hideInvoice
      />
    ),
    Filter: TextColumnFilter,
  },
  {
    Header: 'Invoice Number',
    accessor: 'invoiceNumber',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Amount',
    accessor: 'invoicedAmount',
    Cell: ({ value }) => (value ? displayAmount(value) : 'N/A'),
    Filter: NumberColumnFilter,
    disableFilters: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Filter: InvoiceStatusSelectColumnFilter,
    Cell: ({ value }) => <ZenInvoicesStatusCell type={value!} />,
  },
  {
    Header: 'Company',
    accessor: 'company',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Payment System Id',
    accessor: 'paymentSystemId',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => value,
    Filter: TextStrictCaseColumnFilter,
    cardColSize: 6,
  },
];

const ZenInvoiceResourceTable: React.FC<ZenInvoiceResourceTableProps> = ({
  hiddenColumns,
  allowSelection = true,
  search,
  isTransactionPaymentInvoices = false,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const isDoubleEnderEnabled = useFeatureFlag(FeatureFlagTypeEnum.DOUBLE_ENDER);

  const [invoiceId, setInvoiceId] = useState<string | null>(null);
  const [currentInvoice, setCurrentInvoice] = useState<InvoiceResponse | null>(
    null,
  );
  const [retryingInvoice, setRetryingInvoice] = useState<
    InvoiceResponse | undefined
  >(undefined);
  const [transactionCode, setTransactionCode] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {
    transactionDetailResponse: { data: transactionDetail },
  } = useSelector((state: RootState) => state.transaction);

  const getInvoiceDetailsById = useCallback(
    async (id: string) => {
      try {
        const { data } = await new InvoiceControllerApi(
          getArrakisConfiguration(),
        ).getInvoice(id);

        setCurrentInvoice(data);
      } catch (e) {
        dispatch(
          showErrorToastForErrorCode(
            'We were unable to fetch the invoice details',
            ErrorService.getErrorCode(e),
          ),
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (!!invoiceId) {
      getInvoiceDetailsById(invoiceId);
    }
  }, [invoiceId, getInvoiceDetailsById]);

  const transitionTransactionToPaymentAccepted = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).getTransactionByCode(transactionCode!);

      await dispatch(
        transitionTransaction(
          data,
          TransactionLifecycleStateValueStateEnum.PaymentAccepted,
          undefined,
          { [FeatureFlagTypeEnum.DOUBLE_ENDER]: isDoubleEnderEnabled },
        ),
      );
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to fetch transction with the code', e, {
        transaction: { code: transactionCode },
      });
      dispatch(
        showErrorToastForErrorCode(
          'Unable to fetch transction with the code',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setIsSubmitting(false);
      setTransactionCode(null);
    }
  };

  const modifiedColumns = useMemo(() => {
    const columnsWithAction: Array<Column<InvoiceResponse>> = [
      {
        Header: 'Action',
        accessor: 'id',
        id: 'action',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <div className='flex space-x-2'>
            <ZenViewActionButtonCell
              onClick={() => setInvoiceId(original.id!)}
            />
            {original.status === InvoiceResponseStatusEnum.NetsuiteError && (
              <IconButton
                buttonStyle='border border-primary-blue bg-primary-blue rounded-lg'
                variant='none'
                leftIcon={
                  <FontAwesomeIcon
                    icon={faRotateRight}
                    title='Retry'
                    className='text-lg mx-1 text-white'
                  />
                }
                onClick={() => setRetryingInvoice(original)}
              />
            )}
          </div>
        ),
      },
      ...columns,
    ];

    return columnsWithAction.map((column) => {
      if (column.accessor === 'transactionCode') {
        return {
          ...column,
          disableFilters: isTransactionPaymentInvoices,
        };
      }
      return column;
    });
  }, [isTransactionPaymentInvoices]);

  const handleApproveInvoice = async (invoices: InvoiceResponse[]) => {
    const invoiceIds = invoices.map((invoice) => invoice.id!);
    try {
      await new InvoiceControllerApi(
        getArrakisConfiguration(),
      ).approveInvoices({ invoiceIds });
      dispatch(showSuccessToast('Invoices are approved successfully.'));
    } catch (e) {
      ErrorService.notify('Unable to bulk approve invoices', e, {
        invoiceIds,
      });
      dispatch(showApiErrorModal(e));
      dispatch(showErrorToast('Unable to approve invoices.'));
    }
  };

  return (
    <>
      <div className='px-4 lg:py-5'>
        <ZenResourceIndexContainer<InvoiceResponse>
          header='Invoices'
          columns={modifiedColumns}
          resourceName='Invoice'
          search={search}
          hiddenColumns={hiddenColumns}
          initialSort={{ firstName: 'asc' }}
          fetchData={async (req, cancelToken) => {
            const res = await getInvoiceTableFetchData(
              {
                ...req,
                filter: {
                  ...req.filter,
                  transactionCode: isTransactionPaymentInvoices
                    ? transactionDetail?.code
                    : req.filter?.transactionCode,
                },
              },
              { cancelToken },
            );

            return {
              data: res.data || [],
              total: res.total || 0,
            };
          }}
          allowSelection={allowSelection}
          selectionOptions={[
            {
              label: 'Approve Selected',
              confirm: (invoices) => ({
                title: 'Approve Invoices',
                description: `Are you sure you would like to approve these ${invoices.length} invoices? This action cannot be undone.`,
                modalType: 'success',
                primaryActionTitle: 'Approve',
              }),
              onAction: handleApproveInvoice,
              isActionButtonEnabled: (invoices) => {
                const allowedStatuses = [
                  InvoiceResponseStatusEnum.Open,
                  InvoiceResponseStatusEnum.InvoiceCreated,
                ];
                return invoices.every((invoice) =>
                  allowedStatuses.includes(invoice.status!),
                );
              },
            },
          ]}
          stickyHeader
        />
      </div>

      <ZenInvoiceFormSidebarModal
        isOpen={!!invoiceId && !!currentInvoice}
        onClose={() => {
          setInvoiceId(null);
          setCurrentInvoice(null);
        }}
        invoice={currentInvoice!}
      />

      <ZenConfirmationModal
        variant='warning'
        subtitle='Once done, payments will be sent out to all suitable parties. This action cannot be undone.'
        title='Are you sure you want to mark this as received?'
        onClose={() => setTransactionCode(null)}
        onConfirm={transitionTransactionToPaymentAccepted}
        isSubmitting={isSubmitting}
        isOpen={!!transactionCode?.length}
        confirmButtonText='Confirm'
        size='large'
      />

      <ZenRetryInvoiceModal
        isOpen={!!retryingInvoice}
        onClose={() => setRetryingInvoice(undefined)}
        invoice={retryingInvoice}
      />
    </>
  );
};

export default ZenInvoiceResourceTable;
