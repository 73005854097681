import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownAZ, faArrowDownZA } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowDownShortWide,
  faArrowUpArrowDown,
  faArrowUpShortWide,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { TableInstance } from 'react-table';
import { cn } from '../../../utils/classUtils';
import FontAwesomeIconWithWrapper from '../../FontAwesomeIconWithWrapper';

export type GeminiExpandableSortOptionsIconVariant = 'variant-1' | 'variant-2';

export interface GeminiExpandableSortOptionsProps<D extends object> {
  tableInstance: TableInstance<D>;
  showExpandAll?: boolean;
  sortIconVariant?: GeminiExpandableSortOptionsIconVariant;
}

const GeminiExpandableSortOptions = <D extends object>({
  tableInstance,
  showExpandAll = true,
  sortIconVariant = 'variant-1',
}: GeminiExpandableSortOptionsProps<D>) => {
  const { isAllRowsExpanded, toggleAllRowsExpanded } = tableInstance;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const sortingItems = tableInstance?.headerGroups
    ?.map((headerGroup) => {
      return headerGroup.headers.filter((c) => c.canSort);
    })
    .flat();

  const selectedItem =
    sortingItems?.find((item) => item.isSorted) || sortingItems?.[0];

  // Ref for the popup container
  const popupRef = useRef<HTMLDivElement>(null);

  // Effect to handle clicks outside the popup
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const sortIconAscVariantMap: Record<
    GeminiExpandableSortOptionsIconVariant,
    IconProp
  > = {
    'variant-1': faArrowDownShortWide,
    'variant-2': faArrowDownAZ,
  };

  const sortIconDescVariantMap: Record<
    GeminiExpandableSortOptionsIconVariant,
    IconProp
  > = {
    'variant-1': faArrowUpShortWide,
    'variant-2': faArrowDownZA,
  };

  return (
    <div className='flex md:flex-row flex-col items-center space-x-3 text-primary-dark'>
      <div className='h-8 flex flex-row items-center space-x-3'>
        <div className='h-full flex items-center px-3 py-1 border border-zen-dark-4 rounded-[4px] bg-white'>
          <span className='font-zen-body-2 text-sm font-medium text-grey-500 truncate'>
            Sort By:
          </span>
          {!!selectedItem?.id && (
            <div
              ref={popupRef}
              className='relative ml-2 font-inter text-primary-dark'
            >
              <button
                className='flex flex-wrap items-center w-full'
                onClick={() => setIsOpen((prev) => !prev)}
              >
                <span className='max-w-full flex-1 truncate font-inter font-normal text-sm leading-6'>
                  {selectedItem?.render('Header')}
                </span>
                <FontAwesomeIcon
                  icon={isOpen ? faChevronUp : faChevronDown}
                  className='font-inter text-sm ml-2 flex-shrink-0'
                />
              </button>
              {isOpen && (
                <div className='absolute -right-3 top-8 z-50 bg-white border border-regent-400 rounded-xl py-4 px-2 min-w-[250px]'>
                  <div className='flex items-center space-x-2 px-4 pb-2 border-b border-b-grey-300'>
                    <FontAwesomeIcon
                      icon={faArrowUpArrowDown}
                      className='text-sm'
                    />
                    <span className='text-base font-medium leading-7'>
                      Sort By
                    </span>
                  </div>
                  <p className='uppercase text-xs font-medium text-grey-500 pl-8 py-4'>
                    Sort by
                  </p>
                  <div className='flex flex-col space-y-2 px-4'>
                    {sortingItems?.map((item) => {
                      const isActive = selectedItem?.id === item.id;

                      return (
                        <button
                          onClick={() => {
                            item?.toggleSortBy(
                              item.isSorted && !item.isSortedDesc,
                            );
                            setIsOpen(false);
                          }}
                          key={item.id}
                          className='w-full'
                        >
                          <div className='flex items-center'>
                            {isActive && (
                              <FontAwesomeIconWithWrapper
                                icon={faCheck}
                                className='h-4 w-4 text-rezen-blue-600 mr-1 -ml-1'
                                size='sm'
                              />
                            )}
                            <span
                              className={cn('text-base', !isActive && 'pl-4')}
                            >
                              {item.render('Header')}
                            </span>
                          </div>
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <button
          className='h-full flex items-center justify-center px-2 py-1 border border-regent-400 rounded-[4px] bg-white group hover:border-primary-blue'
          onClick={() =>
            selectedItem?.toggleSortBy(
              selectedItem.isSorted && !selectedItem.isSortedDesc,
            )
          }
        >
          <FontAwesomeIcon
            icon={
              selectedItem?.isSortedDesc
                ? sortIconDescVariantMap[sortIconVariant]
                : sortIconAscVariantMap[sortIconVariant]
            }
            className='text-sm group-hover:text-primary-blue'
          />
        </button>
      </div>
      {showExpandAll && (
        <button
          onClick={() => toggleAllRowsExpanded(!isAllRowsExpanded)}
          className='h-8 flex-shrink-0 flex items-center justify-end pl-4 m-0 md:border-l border-l-grey-400 min-w-[100px]'
        >
          <span className='font-zen-body-2 text-sm font-medium text-right text-rezen-blue-600'>
            {isAllRowsExpanded ? 'Collapse All' : 'Expand All'}
          </span>
        </button>
      )}
    </div>
  );
};

export default GeminiExpandableSortOptions;
