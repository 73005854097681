import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import PageLayout from '../components/PageLayout';
import AddTemplate from '../components/transactions/CheckListAndRoadToSuccessManage/AddTemplate';
import TemplateHeader from '../components/transactions/CheckListAndRoadToSuccessManage/TemplateHeader';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenConfirmationModal from '../components/Zen/Modal/ZenConfirmationModal';
import ZenIconCircleWithTooltipCell from '../components/Zen/Transaction/ZenIconCircleWithTooltipCell';
import ZenRoute from '../components/Zen/ZenRoute';
import { ChecklistDefinitionDto } from '../openapi/sherlock';
import { fetchSearchableChecklistItems } from '../utils/TableUtils';

const columns: Array<Column<ChecklistDefinitionDto>> = [
  {
    Header: 'ITEM',
    accessor: 'name',
    Cell: ({ value, row: { original } }) => (
      <Link to={`/resources/templates/${original?.id}`}>
        <p className='font-zen-body font-normal text-zen-dark-9 py-2'>
          {value}
        </p>
      </Link>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
];

export const columnsToFetch = [
  'id',
  ...columns.map((c) => c.accessor! as string),
];

const ZenResourcesTemplatesRoute: React.FC = () => {
  const [checklistDef, setChecklistDef] = useState<
    ChecklistDefinitionDto | undefined
  >();
  const [search, setSearch] = useState<string | undefined>('');
  const [create, setCreate] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);

  const columnsWithActions: Array<Column<ChecklistDefinitionDto>> = [
    ...columns,
    {
      Header: 'ACTION',
      accessor: 'id',
      Cell: ({ row }) => (
        <ZenIconCircleWithTooltipCell
          tooltipText='Edit/Rename'
          icon={
            <FontAwesomeIcon
              icon={faPencil}
              className='text-zen-dark-9 text-lg'
            />
          }
          onClick={() => {
            setChecklistDef({
              name: row.original.name,
              id: row.original.id!,
            });
            setCreate(true);
          }}
        />
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  return (
    <ZenRoute title='Templates'>
      <PageLayout path={[{ title: 'Templates', url: '/resources/templates' }]}>
        <div className='my-4 px-4'>
          <TemplateHeader
            title='Templates'
            addButtonText='Add New Template'
            addButtonAction={() => {
              setChecklistDef(undefined);
              setCreate(true);
            }}
            search={search || ''}
            setSearch={setSearch}
          />

          <ZenResourceIndexContainer<any>
            columns={columnsWithActions}
            search={search}
            initialSort={{ name: 'asc' }}
            fetchData={fetchSearchableChecklistItems}
            resourceName='template'
            hideFilters
          />

          {!!create && (
            <AddTemplate
              isOpen={create}
              onClose={() => setCreate(false)}
              checklistDef={checklistDef}
            />
          )}
          {!!confirm && (
            <ZenConfirmationModal
              isOpen={confirm}
              size='large'
              variant='danger'
              title='Are you sure you want to archive this Template?'
              subtitle='Archiving this template will remove it from your database. You can always reach out to support and they’ll retrieve it for you if necessary.'
              confirmButtonText='Archive'
              onClose={() => setConfirm(false)}
              onConfirm={() => setConfirm(false)}
            />
          )}
        </div>
      </PageLayout>
    </ZenRoute>
  );
};

export default ZenResourcesTemplatesRoute;
