import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AnnouncementControllerApi,
  AnnouncementDefControllerApi,
  AnnouncementDefRequest,
  AnnouncementDefRequestStatusEnum,
  AnnouncementDefResponse,
  AnnouncementDefResponseStatusEnum,
  AnnouncementResponse,
  MediaInfoResponse,
  UpdateAnnouncementRequestActionEnum,
} from '../openapi/mercury';
import ErrorService from '../services/ErrorService';
import { AnnouncementState, AppThunk, AsyncResponse } from '../types';
import { getMercuryConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showErrorModal } from './ErrorSlice';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { performAsyncRequest } from './utils/SliceUtil';

export const initialState: AnnouncementState = {
  loading: false,
  showEditModal: false,
  announcementDefinitionDetail: undefined,
  editAnnouncementId: undefined,
  topAnnouncement: undefined,
  topAnnouncementLoading: false,
  previewAnnouncementId: undefined,
  announcementDefinitionMediaInfo: {
    loading: false,
    name: 'announcement media info',
    error: null,
  },
};

const AnnouncementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    changeLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    saveShowEditModal(state, action: PayloadAction<boolean>) {
      state.showEditModal = action.payload;
    },
    changeTopAnnouncementLoading(state, action: PayloadAction<boolean>) {
      state.topAnnouncementLoading = action.payload;
    },
    saveTopAnnouncement(
      state,
      action: PayloadAction<AnnouncementResponse | undefined>,
    ) {
      state.topAnnouncement = action.payload;
    },
    saveAnnouncementDefinitionDetail(
      state,
      action: PayloadAction<AnnouncementDefResponse | undefined>,
    ) {
      state.announcementDefinitionDetail = action.payload;
    },
    saveEditAnnouncementId(state, action: PayloadAction<string | undefined>) {
      state.editAnnouncementId = action.payload;
    },
    savePreviewAnnouncementId(
      state,
      action: PayloadAction<string | undefined>,
    ) {
      state.previewAnnouncementId = action.payload;
    },
    saveAnnouncementDefinitionMediaInfo(
      state,
      action: PayloadAction<AsyncResponse<MediaInfoResponse>>,
    ) {
      state.announcementDefinitionMediaInfo = action.payload;
    },
  },
});

export const {
  changeLoading,
  saveShowEditModal,
  changeTopAnnouncementLoading,
  saveTopAnnouncement,
  saveAnnouncementDefinitionDetail,
  saveEditAnnouncementId,
  savePreviewAnnouncementId,
  saveAnnouncementDefinitionMediaInfo,
} = AnnouncementSlice.actions;

export const createAnnouncement = (
  req: AnnouncementDefRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    let isMediaUploadPassed = false;
    const { video, audio, banner, ...announcementReq } = req;
    const isMediaAvailable = !!(video || audio || banner);
    const { data } = await new AnnouncementDefControllerApi(
      await getMercuryConfiguration(),
    ).createDefinition({
      ...announcementReq,
      status: isMediaAvailable
        ? AnnouncementDefRequestStatusEnum.Draft
        : announcementReq?.status,
    });
    if (data?.id) {
      isMediaUploadPassed = await dispatch(
        handleMediaDeleteAndUpload(data?.id, video, audio, banner),
      );
    }
    if (
      isMediaAvailable &&
      isMediaUploadPassed &&
      announcementReq.status === AnnouncementDefRequestStatusEnum.Active
    ) {
      await dispatch(
        updateAnnouncementStatus(data?.id!, {
          ...announcementReq,
          status: AnnouncementDefRequestStatusEnum.Active,
        }),
      );
      dispatch(showSuccessToast('Announcement created successfully'));
      return true;
    }
    if (!isMediaUploadPassed && isMediaAvailable) {
      dispatch(
        showSuccessToast(
          'Saving Announcement as draft, due to media upload failure',
        ),
      );
      return true;
    }
    dispatch(showSuccessToast('Announcement saved as draft successfully'));
    return true;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem creating announcement.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('unable to create announcement', e, {
      announcement: { req },
    });
    return false;
  }
};

export const updateAnnouncement = (
  announcementId: string,
  req: AnnouncementDefRequest,
): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
  try {
    const { video, audio, banner, ...announcementReq } = req;
    let isMediaUploadPassed = false;
    const isMediaAvailable = !!(video || audio || banner);
    isMediaUploadPassed = await dispatch(
      handleMediaDeleteAndUpload(announcementId, video, audio, banner, true),
    );
    const {
      announcement: { announcementDefinitionDetail },
    } = getState();
    const isActiveAnnouncement =
      announcementDefinitionDetail?.status ===
      AnnouncementDefResponseStatusEnum.Active;
    let status = req?.status;
    if (!isActiveAnnouncement && !isMediaUploadPassed) {
      status = AnnouncementDefRequestStatusEnum.Draft;
    }

    await new AnnouncementDefControllerApi(
      await getMercuryConfiguration(),
    ).updateDefinition(announcementId, { ...announcementReq, status });
    if (isMediaAvailable && !isMediaUploadPassed) {
      dispatch(
        showErrorToast(
          'We had a problem updating announcement media.',
          'Please try again in a few moments.',
        ),
      );
      return true;
    }
    dispatch(showSuccessToast('Announcement updated successfully'));
    return true;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem updating announcement.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('unable to update the announcement', e, {
      announcement: { req },
    });
    return false;
  }
};

export const handleMediaDeleteAndUpload = (
  announcementId: string,
  video: any,
  audio: any,
  banner: any,
  isUpdate: boolean = false,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  let isAudioUploadSucceeded = true;
  let isVideoUploadSucceeded = true;
  let isBannerUploadSucceeded = true;
  if (!audio && isUpdate) {
    await dispatch(deleteAudioFromAnnouncement(announcementId));
  } else if (typeof audio === 'object') {
    isAudioUploadSucceeded = await dispatch(
      addAudioToAnnouncement(announcementId, audio),
    );
  }

  if (!video && isUpdate) {
    await dispatch(deleteVideoFromAnnouncement(announcementId));
  } else if (typeof video === 'object') {
    isVideoUploadSucceeded = await dispatch(
      addVideoToAnnouncement(announcementId, video),
    );
  }

  if (!banner && isUpdate) {
    await dispatch(deleteBannerFromAnnouncement(announcementId));
  } else if (typeof banner === 'object') {
    isBannerUploadSucceeded = await dispatch(
      addBannerToAnnouncement(announcementId, banner),
    );
  }

  return (
    isAudioUploadSucceeded && isVideoUploadSucceeded && isBannerUploadSucceeded
  );
};

export const dismissAnnouncement = (
  definitionId: string,
  action: UpdateAnnouncementRequestActionEnum,
): AppThunk => async (dispatch) => {
  try {
    await new AnnouncementControllerApi(
      getMercuryConfiguration(),
    ).updateTopAnnouncementForLoggedInUser({ definitionId, action });
    dispatch(saveTopAnnouncement(undefined));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem dismissing announcement.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('unable to dismiss announcement', e, {
      announcement: { definitionId, action },
    });
  }
};

export const updateAnnouncementStatus = (
  annId: string,
  reqBody: AnnouncementDefRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    await new AnnouncementDefControllerApi(
      await getMercuryConfiguration(),
    ).updateDefinition(annId, reqBody);
    if (reqBody?.status === AnnouncementDefRequestStatusEnum.Archived) {
      dispatch(showSuccessToast('Announcement deleted successfully'));
    } else {
      dispatch(showSuccessToast('Announcement status updated successfully'));
    }
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem updating announcement.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('unable to update the announcement', e, {
      announcement: { reqBody },
    });
  } finally {
    dispatch(changeLoading(false));
  }
};

export const addAudioToAnnouncement = (
  id: string,
  audio: File,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new AnnouncementDefControllerApi(
      await getMercuryConfiguration(),
    ).uploadAudio(id, audio);
    return true;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem adding audio file to announcement.',
        'Please try again in a few moments.',
      ),
    );
    dispatch(showErrorModal(e));
    ErrorService.notify('unable to add audio file to announcement', e, {
      announcement: { id },
    });
    return false;
  }
};

export const deleteAudioFromAnnouncement = (id: string): AppThunk => async (
  dispatch,
) => {
  try {
    await new AnnouncementDefControllerApi(
      await getMercuryConfiguration(),
    ).deleteAudio(id);
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem deleting audio file from this announcement.',
        'Please try again in a few moments.',
      ),
    );
    dispatch(showErrorModal(e));
    ErrorService.notify('unable to delete audio file from announcement', e, {
      announcement: { id },
    });
  }
};

export const addVideoToAnnouncement = (
  id: string,
  video: File,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new AnnouncementDefControllerApi(
      await getMercuryConfiguration(),
    ).uploadVideo(id, video);
    return true;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem adding video file to announcement.',
        'Please try again in a few moments.',
      ),
    );
    dispatch(showErrorModal(e));
    ErrorService.notify('unable to add video file to announcement', e, {
      announcement: { id },
    });
    return false;
  }
};

export const deleteVideoFromAnnouncement = (id: string): AppThunk => async (
  dispatch,
) => {
  try {
    await new AnnouncementDefControllerApi(
      await getMercuryConfiguration(),
    ).deleteVideo(id);
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem deleting video file from this announcement.',
        'Please try again in a few moments.',
      ),
    );
    dispatch(showErrorModal(e));
    ErrorService.notify('unable to delete video file from announcement', e, {
      announcement: { id },
    });
  }
};

export const addBannerToAnnouncement = (
  id: string,
  banner: File,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new AnnouncementDefControllerApi(
      await getMercuryConfiguration(),
    ).uploadBanner(id, banner);
    return true;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem adding banner to announcement.',
        'Please try again in a few moments.',
      ),
    );
    dispatch(showErrorModal(e));
    ErrorService.notify('unable to add banner to announcement', e, {
      announcement: { id },
    });
    return false;
  }
};

export const deleteBannerFromAnnouncement = (id: string): AppThunk => async (
  dispatch,
) => {
  try {
    await new AnnouncementDefControllerApi(
      await getMercuryConfiguration(),
    ).deleteBanner(id);
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem deleting banner from this announcement.',
        'Please try again in a few moments.',
      ),
    );
    dispatch(showErrorModal(e));
    ErrorService.notify('unable to delete banner from announcement', e, {
      announcement: { id },
    });
  }
};

export const fetchTopAnnouncement = (): AppThunk => async (dispatch) => {
  dispatch(changeTopAnnouncementLoading(true));
  try {
    const { data } = await new AnnouncementControllerApi(
      getMercuryConfiguration(),
    ).getTopAnnouncementForLoggedInUser();
    dispatch(saveTopAnnouncement(data));
  } catch (e) {
    const statusCodes = [401, 403, 404];
    if (!statusCodes?.includes(e.response?.status!)) {
      dispatch(
        showErrorToast(
          'We could not fetch the top announcement.',
          'Please try again in a few moments.',
        ),
      );
    }
    ErrorService.notifyIgnoreStatusCodes(
      statusCodes,
      'unable to fetch top announcement',
      e,
      {},
    );
  } finally {
    dispatch(changeTopAnnouncementLoading(false));
  }
};

export const fetchAnnouncementDefinitionById = (id: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new AnnouncementDefControllerApi(
      await getMercuryConfiguration(),
    ).getDefinition(id);
    dispatch(saveAnnouncementDefinitionDetail(data));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem fetching announcement definition.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('unable to fetch announcement definition', e, {
      announcement: { id },
    });
  } finally {
    dispatch(changeLoading(false));
  }
};

export const fetchAnnouncementDefinitionMediaInfo = (
  id: string,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new AnnouncementDefControllerApi(
      getMercuryConfiguration(),
    ).getDefinitionMediaInfo(id);

  performAsyncRequest(
    //@ts-ignore
    dispatch,
    'announcement media info',
    saveAnnouncementDefinitionMediaInfo,
    fetch,
    { skipAuthDatadog: true },
  );
};

export default AnnouncementSlice.reducer;
