import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DetailPageLoader from '../components/DetailPageLoader';
import PageLayout from '../components/PageLayout';
import ResourceContainer from '../components/ResourceContainer';
import ZenMarketingCenterCard from '../components/Zen/Marketing/ZenMarketingCenterCard';
import SocialMediaLinks from '../components/Zen/SocialMediaLinks';
import ZenRoute from '../components/Zen/ZenRoute';
import { DesignCenterControllerApi } from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { RootState } from '../types';
import { showAndReportErrors } from '../utils/ErrorUtils';
import {
  getSocialMediaLinks,
  getZenMarketingCenterItems,
} from '../utils/MarketingCenterUtils';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { cn } from '../utils/classUtils';

interface ZenMarketingCenterRouteProps {}

const ZenMarketingCenterRoute: React.FC<ZenMarketingCenterRouteProps> = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [jwt, setJwt] = useState<string>();
  const {
    auth: { userDetail },
    mainMenu: { isCollapsed },
  } = useSelector((state: RootState) => state);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await new DesignCenterControllerApi(
        getYentaConfiguration(),
      ).getJwtForAgent(userDetail?.id!);
      setJwt(data.jwt!);
    } catch (e) {
      if (showAndReportErrors(e?.response)) {
        dispatch(showApiErrorModal(e));
        ErrorService.notifyIgnoreAuthErrors(
          'Unable to fetch jwt for agent',
          e,
          {
            agent: { id: userDetail?.id! },
          },
        );
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch, userDetail?.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <ZenRoute title='Marketing Center'>
      <ResourceContainer
        loading={loading}
        LoaderComponent={<DetailPageLoader />}
        isEmpty={false}
        resourceName='marketing center'
      >
        <PageLayout
          path={[
            { title: 'Home', url: '/' },
            { title: 'Marketing Center', url: '/resources/marketing-center' },
          ]}
        >
          <div className='md:px-8 md:py-9 p-4'>
            <div className='flex md:flex-row flex-col md:items-center mb-6 justify-between'>
              <p className='text-2xl leading-8 zen-dark font-zen-body font-semibold md:mb-0 mb-2'>
                Marketing Center
              </p>
              <SocialMediaLinks
                label='Connect with us'
                socialLinks={getSocialMediaLinks()}
              />
            </div>
            <div
              className={cn(
                'grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-4 gap-6',
                isCollapsed ? 'lg:grid-cols-4' : 'lg:grid-cols-3',
              )}
            >
              {getZenMarketingCenterItems(jwt!).map((item) => (
                <ZenMarketingCenterCard key={item.title} item={item} />
              ))}
            </div>
          </div>
        </PageLayout>
      </ResourceContainer>
    </ZenRoute>
  );
};

export default ZenMarketingCenterRoute;
