import React, { useState } from 'react';
import { Column } from 'react-table';
import ConfirmDeleteRecordModal from '../components/ConfirmDeleteRecordModal';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenDateCell from '../components/Zen/Table/Cell/ZenDateCell';
import ZenAction from '../components/Zen/ZenAction';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenAddAgentCreditFormSidebarModal from '../forms/ZenAddAgentCreditFormSidebarModal';
import { CreditResponse } from '../openapi/arrakis';
import { AgentResponse } from '../openapi/yenta';
import { displayAmount } from '../utils/CurrencyUtils';
import { capitalizeEnum } from '../utils/StringUtils';
import { fetchAgentCredits } from '../utils/TableUtils';

interface AgentDetailCreditsRouteProps {
  agent: AgentResponse;
}

export const columns: Array<Column<CreditResponse>> = [
  {
    Header: 'Title',
    accessor: 'title',
    Cell: ({ value }) => value,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => capitalizeEnum(value!),
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }) =>
      row.original.amount
        ? displayAmount(row.original.amount)
        : row.original.number,
  },
  {
    Header: 'Issued On',
    accessor: 'issuedOn',
    Cell: ({ value }) => <ZenDateCell date={value} />,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Issuer Note',
    accessor: 'issuerNote',
    disableFilters: true,
    disableSortBy: true,
  },
];

const AgentDetailCreditsRoute: React.FC<AgentDetailCreditsRouteProps> = ({
  agent,
}) => {
  const [addAgentCredit, setAddAgentCredit] = useState<boolean>(false);
  const [
    deletingAgentCredit,
    setDeletingAgentCredit,
  ] = useState<CreditResponse | null>(null);

  const columnsWithAction: Array<Column<CreditResponse>> = [...columns];

  return (
    <ZenRoute title='Credits'>
      <div className='px-4 mt-4'>
        <div className='flex flex-row items-center justify-between mb-4'>
          <span className='font-bold text-xl mr-3'>Credits</span>
          <ZenAction text='Add' onClick={() => setAddAgentCredit(true)} />
        </div>
        <ZenResourceIndexContainer<CreditResponse>
          resourceName='credit'
          columns={columnsWithAction}
          fetchData={async (req, cancelToken) => {
            const res = await fetchAgentCredits(req, agent?.id!, {
              cancelToken,
            });
            return {
              data: res.results || [],
              total: res.totalCount || 0,
            };
          }}
          initialSort={{ issuedOn: 'desc' }}
          hideFilters
        />
        {!!addAgentCredit && (
          <ZenAddAgentCreditFormSidebarModal
            isOpen={addAgentCredit}
            onClose={() => setAddAgentCredit(false)}
            agent={agent}
          />
        )}
        {!!deletingAgentCredit && (
          <ConfirmDeleteRecordModal
            onDelete={() => {}}
            title='Are you sure?'
            subtitle='This agent credit will be permanently deleted.'
            isOpen={!!deletingAgentCredit}
            onClose={() => setDeletingAgentCredit(null)}
          />
        )}
      </div>
    </ZenRoute>
  );
};
export default AgentDetailCreditsRoute;
