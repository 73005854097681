import { FieldError } from 'react-hook-form';

type ErrorComponentProps = {
  errors?: FieldError;
};

export const ErrorComponent: React.FC<ErrorComponentProps> = ({ errors }) => {
  const errorMessages = Object.values({
    defaultError: errors?.message,
    ...errors?.types,
  })
    .map((error: any) => error)
    .flat();

  const uniqErrorMessages = Array.from(new Set(errorMessages));

  return (
    <>
      {uniqErrorMessages.map((error) => (
        <div key={error}>{error}</div>
      ))}
    </>
  );
};
