import React from 'react';
import { BreadCrumbsPath, FeatureFlagTypeEnum } from '../../types';
import { cn } from '../../utils/classUtils';
import BreadCrumbsWithUrls from '../BreadCrumbsWithUrls';
import FeatureFlagEnabledOnly from '../FeatureFlagEnabledOnly';
import DoNotDisturbBanner from '../Roar/OfficeHours/DoNotDisturbBanner';
import OutOfOfficeBanner from '../Roar/OfficeHours/OutOfOffice/OutOfOfficeBanner';

export interface ZenPageLayoutProps {
  headerRef?: React.RefObject<HTMLDivElement>;
  path: BreadCrumbsPath[];
  RightComponent?: React.FC | React.ReactElement;
  hideLayoutPaths?: boolean;
  stickyHeader?: boolean;
  textClassNames?: string;
}

const ZenPageLayout: React.FC<ZenPageLayoutProps> = ({
  headerRef,
  path,
  RightComponent,
  hideLayoutPaths = false,
  stickyHeader = false,
  children,
  textClassNames,
}) => {
  return (
    <div>
      {!hideLayoutPaths && (
        <div
          ref={headerRef}
          className={cn(
            'px-4 py-2 lg:border-b border-trueGray-200 flex flex-row flex-nowrap items-center bg-white',
            { 'sticky top-0 z-40': stickyHeader },
          )}
        >
          <div
            className={cn(
              'py-3 font-zen-title font-medium text-base text-zen-dark-9',
              textClassNames,
            )}
          >
            <BreadCrumbsWithUrls path={path} />
          </div>
          {RightComponent}
        </div>
      )}
      <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.ROAR}>
        <DoNotDisturbBanner />
        <OutOfOfficeBanner />
      </FeatureFlagEnabledOnly>
      {children}
    </div>
  );
};

export default ZenPageLayout;
