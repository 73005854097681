import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  BrokerControllerApi,
  UserResponseAgentStatusEnum,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { AsyncSelectOption } from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { searchForAgents } from '../../../utils/TableUtils';
import ZenControlledAsyncSelectInput from '../Input/ZenControlledAsyncSelectInput';
import ZenSidebarModalForm from '../ZenSidebarModalForm';

interface ZenPromoteUserAsBrokerSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  userId: AsyncSelectOption;
}

const ZenPromoteUserAsBrokerSidebarModal: React.FC<ZenPromoteUserAsBrokerSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (values: FormData) => {
    try {
      await new BrokerControllerApi(getYentaConfiguration()).markUserAsBroker({
        userId: values.userId.value,
      });
      onClose();
      dispatch(showSuccessToast('Promoted the user as a broker.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error Promoting the user as a broker', e, {
        data: {
          userId: values.userId,
        },
      });
      dispatch(
        showErrorToast(
          'We had a problem Promoting the user as a broker',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <ZenSidebarModalForm
      title='Promote User as Broker'
      isOpen={isOpen}
      onClose={onClose}
      actionTitle='Save'
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <div>
        <ZenControlledAsyncSelectInput<FormData, 'userId'>
          control={control}
          name='userId'
          placeholder='Search Member'
          label='User'
          fetchData={async (search, page) => {
            try {
              const { data } = await searchForAgents({
                page,
                search,
                filterBy: {
                  agentStatus: [UserResponseAgentStatusEnum.Active],
                },
              });

              const options: AsyncSelectOption[] = data.map((resp) => ({
                value: `${resp.id}`,
                label: `${resp.firstName} ${resp.lastName} - ${resp.emailAddress}`,
              }));

              return options;
            } catch (e) {
              ErrorService.notify(
                'Unable to search request registered agents in add team member form',
                e,
                {
                  search: {
                    term: search,
                    page,
                  },
                },
              );
              dispatch(
                showErrorToast(
                  'An unexpected error occurred.',
                  'We were unable to search for an registered agent. Please try again in a few moments or contact support.',
                ),
              );
            }

            return [];
          }}
          rules={{
            required: 'Please select user',
          }}
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenPromoteUserAsBrokerSidebarModal;
