import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import MortgageGenerateReportSidebar, {
  GenerateReportFormData,
} from '../components/Mortgage/MortgageGenerateReportSidebar';
import MortgageReportStatus from '../components/Mortgage/MortgageReportStatus';
import MortgageViewReportCell from '../components/Mortgage/MortgageViewReportCell';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenButton from '../components/Zen/ZenButton';
import ZenRoute from '../components/Zen/ZenRoute';
import { useRefresh } from '../hooks/useRefresh';
import {
  BankingApi,
  BankReportSummaryDto,
  BankReportSummaryDtoStatusEnum,
  GenerateBankReportRequest,
  GenerateBankReportRequestBankReportTypeEnum,
} from '../openapi/atlantis';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { AppDispatch, RootState } from '../types';
import { getFullName } from '../utils/AgentHelper';
import { isSmScreen } from '../utils/BrowserUtils';
import { getBorrower } from '../utils/MortgageUtils';
import { getAtlantisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../utils/StringUtils';

const columns: Array<Column<BankReportSummaryDto>> = [
  {
    Header: 'Date Created',
    accessor: 'createdAt',
    Cell: ({ value }) => (
      <p className='font-zen-body font-normal text-sm text-zen-dark-9'>
        {DateTime.fromSeconds(value).isValid
          ? DateTime.fromSeconds(value).toFormat('LLL dd, yyyy')
          : 'N/A'}
      </p>
    ),
    disableSortBy: true,
  },

  {
    Header: 'Report ID',
    accessor: 'reportId',
    Cell: ({ value }) => (
      <p className='font-zen-body font-normal text-sm text-zen-dark-9'>
        {value || 'N/A'}
      </p>
    ),
    disableSortBy: true,
  },

  {
    Header: 'Report Type',
    accessor: 'type',
    Cell: ({ value }) => (
      <p className='font-zen-body font-normal text-sm text-zen-dark-9'>
        {!!value ? capitalizeEnum(value)?.toLocaleUpperCase() : 'N/A'}
      </p>
    ),
    disableSortBy: true,
  },
];

interface MortgageAccountsRouteProps {
  borrowerId: string;
}

const MortgageAccountsRoute: React.FC<MortgageAccountsRouteProps> = ({
  borrowerId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { key, refresh } = useRefresh();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    isGenerateReportSidebarOpen,
    setIsGenerateReportSidebarOpen,
  ] = useState<boolean>(false);
  const {
    loanResponse: { data: loan },
  } = useSelector((state: RootState) => state.mortgage);
  const borrower = getBorrower(borrowerId, loan?.borrowers || []);

  const handleGenerateBankReport = async (formData: GenerateReportFormData) => {
    setIsLoading(true);
    const generateBankReportRequest: GenerateBankReportRequest = {
      bankReportType: formData.reportType
        ?.value as GenerateBankReportRequestBankReportTypeEnum,
    };

    try {
      await new BankingApi(getAtlantisConfiguration()).generateBankReport(
        borrowerId,
        generateBankReportRequest,
      );
      setIsGenerateReportSidebarOpen(false);
      refresh();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notifyIgnoreHandled('Unable to generate bank report', e, {
        borrower: { ...borrower },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columnsWithAction: Array<Column<BankReportSummaryDto>> = useMemo(() => {
    return [
      ...columns,
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value, row: { original } }) => {
          return (
            <div className='flex gap-x-4'>
              <MortgageReportStatus status={value} />
              {value === BankReportSummaryDtoStatusEnum.Success && (
                <MortgageViewReportCell
                  borrowerId={borrowerId}
                  reportId={original.reportId}
                />
              )}
            </div>
          );
        },
        disableSortBy: true,
      },
    ];
  }, [borrowerId]);

  return (
    <ZenRoute title={`Mortgage Accounts - ${getFullName(borrower)}`}>
      <div className='px-4 py-0 mt-5'>
        <ZenResourceIndexContainer<BankReportSummaryDto>
          header='Asset Reports'
          resourceName='report'
          key={key}
          columns={columnsWithAction}
          showSearch={isSmScreen()}
          hideFilters
          hidePageSize
          hidePagination
          fetchData={async () => {
            const { data } = await new BankingApi(
              getAtlantisConfiguration(),
            ).getBankReportSummaries(borrowerId);

            return {
              data: data?.list || [],
              total: data?.list?.length || 0,
            };
          }}
          RightComponent={
            <ZenButton
              variant='primary-outline'
              label='Generate Report'
              onClick={() => setIsGenerateReportSidebarOpen(true)}
              isDisabled={isLoading}
              isSubmitting={isLoading}
            />
          }
        />
      </div>

      <MortgageGenerateReportSidebar
        isOpen={isGenerateReportSidebarOpen}
        onClose={() => setIsGenerateReportSidebarOpen(false)}
        onSubmit={handleGenerateBankReport}
      />
    </ZenRoute>
  );
};

export default MortgageAccountsRoute;
