import { faAdd } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApiKeyResponse } from '../../../openapi/keymaker';
import { RootState } from '../../../types';
import { isAgentClientApiKeyExpired } from '../../../utils/AgentHelper';
import GeminiFeatureFlagButton from '../../GeminiFeatureFlagButton';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import ZenTabs from '../../Zen/ZenTabs';
import AgentClientApiKeyTabs from './AgentClientApiKeyTabs';

interface AgentClientApiKeySideBarModalProps {
  isOpen: boolean;
  onClose(): void;
  handleOpenGenerateApiKeyModal(): void;
  handleOpenRevokeApiModal(apiKey?: ApiKeyResponse): void;
}

const AgentClientApiKeySideBarModal: React.FC<AgentClientApiKeySideBarModalProps> = ({
  isOpen,
  onClose,
  handleOpenGenerateApiKeyModal,
  handleOpenRevokeApiModal,
}) => {
  const { clientApiKeys } = useSelector(
    (state: RootState) => state.clientApiKey,
  );
  const [activeKeysData, setActiveKeysData] = useState<ApiKeyResponse[]>([]);
  const [expiredKeysData, setExpiredKeysData] = useState<ApiKeyResponse[]>([]);
  const [revokedKeysData, setRevokedKeysData] = useState<ApiKeyResponse[]>([]);
  const [apiKeyTab, setApiKeyTab] = useState<string>();

  useEffect(() => {
    const activeKeys: ApiKeyResponse[] = [];
    const expiredKeys: ApiKeyResponse[] = [];
    const revokedKeys: ApiKeyResponse[] = [];

    clientApiKeys.forEach((apiKey) => {
      if (isAgentClientApiKeyExpired(apiKey?.expirationDate || '')) {
        expiredKeys.push(apiKey as ApiKeyResponse);
      } else if (
        !isAgentClientApiKeyExpired(apiKey?.expirationDate || '') &&
        !apiKey?.active &&
        apiKey?.revokedAt !== null
      ) {
        revokedKeys.push(apiKey as ApiKeyResponse);
      } else {
        activeKeys.push(apiKey as ApiKeyResponse);
      }
    });
    setApiKeyTab(`Active (${activeKeys.length})`);
    setActiveKeysData(activeKeys);
    setExpiredKeysData(expiredKeys);
    setRevokedKeysData(revokedKeys);
  }, [clientApiKeys]);

  const tabs = [
    {
      name: `Active (${activeKeysData.length})`,
      TabComponent: (
        <AgentClientApiKeyTabs
          currentTab='Active'
          tabData={activeKeysData}
          handleOpenGenerateApiKeyModal={handleOpenGenerateApiKeyModal}
          handleOpenRevokeApiModal={handleOpenRevokeApiModal}
        />
      ),
    },
    {
      name: `Expired (${expiredKeysData.length})`,
      TabComponent: (
        <AgentClientApiKeyTabs
          currentTab='Expired'
          tabData={expiredKeysData}
          handleOpenGenerateApiKeyModal={handleOpenGenerateApiKeyModal}
          handleOpenRevokeApiModal={handleOpenRevokeApiModal}
        />
      ),
    },
    {
      name: `Revoked (${revokedKeysData.length})`,
      TabComponent: (
        <AgentClientApiKeyTabs
          currentTab='Revoked'
          tabData={revokedKeysData}
          handleOpenGenerateApiKeyModal={handleOpenGenerateApiKeyModal}
          handleOpenRevokeApiModal={handleOpenRevokeApiModal}
        />
      ),
    },
  ];

  return (
    <ZenSidebarModal isOpen={isOpen} onClose={onClose} title='Manage API Keys'>
      <div className='px-4 py-6'>
        <div className='pt-4 pb-8'>
          <GeminiFeatureFlagButton
            label='Generate New API Key'
            type='button'
            LeftIconComponent={<FontAwesomeIcon icon={faPlus} size='lg' />}
            leftIcon={faAdd}
            onClick={() => handleOpenGenerateApiKeyModal()}
          />
        </div>
        <div>
          <ZenTabs
            tabs={tabs}
            selected={apiKeyTab}
            onChange={setApiKeyTab}
            size='sm'
          />
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default AgentClientApiKeySideBarModal;
