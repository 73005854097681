import SuccessCheckBadge from '../../../../assets/img/success_check_badge.png';

interface MessageProps {
  title: string;
  subtitleComponent: React.ReactElement;
}

const Message: React.FC<MessageProps> = ({ title, subtitleComponent }) => {
  return (
    <div
      className='w-full p-4 pt-8 pb-14 md:p-14 mb-14 flex flex-col items-center'
      aria-label='beneficiary-message'
    >
      <div className='w-18 h-18'>
        <img
          src={SuccessCheckBadge}
          className='w-18 h-18'
          alt='addBeneficiaryLater'
        />
      </div>
      <p className='w-full font-zen-body font-semibold text-2xl text-zen-dark-13 text-center my-4'>
        {title}
      </p>
      {subtitleComponent}
    </div>
  );
};

export default Message;
