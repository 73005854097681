import React from 'react';
import { useParams } from 'react-router-dom';
import TrustDepositReceipt from '../components/transactions/TrustDeposits/TrustDepositReceipt';
import ZenRoute from '../components/Zen/ZenRoute';

type Match = {
  id: string;
  transactionId: string;
};

const TrustDepositReceiptPdfRoute: React.FC = () => {
  const { id, transactionId } = useParams() as Match;

  return (
    <ZenRoute title='Trust Deposit Receipt PDF'>
      <TrustDepositReceipt
        transactionId={transactionId}
        escrowDepositId={id}
        isPdf
      />
    </ZenRoute>
  );
};

export default TrustDepositReceiptPdfRoute;
