import { cn } from '../utils/classUtils';

export interface SubmitButtonProps {
  label: string;
  isSubmitting: boolean;
  onClick?(): void;
  inline?: boolean;
  disabled?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  label,
  isSubmitting,
  onClick,
  inline = false,
  disabled = false,
}) => {
  return (
    <button
      type='submit'
      disabled={isSubmitting}
      onClick={onClick}
      className={cn(
        'mt-5 p-2 rounded text-white',
        disabled ? 'bg-gray-500' : 'bg-primary',
        {
          'opacity-70': isSubmitting,
          'w-full': !inline,
        },
      )}
    >
      {label}
    </button>
  );
};

export default SubmitButton;
