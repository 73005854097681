import { BoardFormData } from '../components/board/Gemini/GeminiCreateBoardForm';
import { GooglePlaceLocationType } from '../components/Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import {
  AddressRequest,
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
  AddressResponse,
  AddressResponseStateOrProvinceEnum,
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
  AgentResponseDefaultCurrencyEnum,
  BoardControllerApi,
  BoardRequestBillingFrequencyEnum,
  BoardRequestBillingTypeEnum,
  BoardRequestBrokerAffiliationEnum,
  BoardRequestPaymentTypeEnum,
  BoardRequestStatusEnum,
  BoardRequestUnreportedLicenseRiskEnum,
  BoardResponse,
  BoardSecretsResponse,
  MoneyValueCurrencyEnum,
} from '../openapi/yenta';
import { EnumMap } from '../types';
import { getAddressFromAddressComponent } from './TransactionHelper';

export type BoardsSortDirectionType = Parameters<
  typeof BoardControllerApi.prototype.searchBoards
>[1];

export type BoardsSortByType = Parameters<
  typeof BoardControllerApi.prototype.searchBoards
>[0];

export type BoardsStateType = Parameters<
  typeof BoardControllerApi.prototype.searchBoards
>[8];

export const BoardsSortDirectionTypeEnum: EnumMap<
  string,
  BoardsSortDirectionType
> = {
  asc: 'ASC',
  desc: 'DESC',
};

export const BoardsSortByTypeEnum: EnumMap<string, BoardsSortByType> = {
  id: ['ID'],
  name: ['NAME'],
  code: ['CODE'],
  joinDate: ['JOIN_DATE'],
  status: ['STATUS'],
};

export const getDefaultFormValuesForBoardForm = (
  details?: BoardResponse,
  secrets?: BoardSecretsResponse,
  currency?: AgentResponseDefaultCurrencyEnum,
): BoardFormData => {
  return {
    logo: details?.logo,
    name: details?.name,
    code: details?.code,
    isManualAddress: false,
    location: !!details?.address
      ? getGoogleLocationFromAddress(details?.address)
      : (undefined as any),
    address: (details?.address as unknown) as AddressRequest,
    officeCode: details?.officeCode,
    brokerAffiliation: (details?.brokerAffiliation ||
      '') as BoardRequestBrokerAffiliationEnum,
    administrativeArea: {
      country: (details?.administrativeArea?.country ||
        '') as AdministrativeAreaRequestCountryEnum,
      stateOrProvince: (details?.administrativeArea?.stateOrProvince ||
        '') as AdministrativeAreaRequestStateOrProvinceEnum,
    },
    registrationFee: {
      amount: details?.registrationFee?.amount,
      currency:
        details?.registrationFee?.currency ??
        ((currency as unknown) as MoneyValueCurrencyEnum),
    },
    joinDate: details?.joinDate,
    status: (details?.status || '') as BoardRequestStatusEnum,

    phoneNumber: details?.phoneNumber,
    email: details?.email,

    username: secrets?.username,
    password: secrets?.password,

    billingType: (details?.billingType || '') as BoardRequestBillingTypeEnum,
    billingFrequency: (details?.billingFrequency ||
      '') as BoardRequestBillingFrequencyEnum,
    fee: {
      amount: details?.fee?.amount,
      currency:
        details?.fee?.currency ??
        ((currency as unknown) as MoneyValueCurrencyEnum),
    },
    paymentType: (details?.paymentType || '') as BoardRequestPaymentTypeEnum,
    nextDueDate: details?.nextDueDate,
    unreportedLicenseRisk: (details?.unreportedLicenseRisk ||
      '') as BoardRequestUnreportedLicenseRiskEnum,
  };
};

export const getAddressFromGoogleLocation = (
  location: GooglePlaceLocationType,
) => {
  const addressComponents = location.address_components!;

  const address = {
    streetAddress1: getAddressFromAddressComponent(addressComponents)
      ?.streetAddress1,
    streetAddress2: getAddressFromAddressComponent(addressComponents)
      ?.streetAddress2,
    city: getAddressFromAddressComponent(addressComponents)?.city!,
    stateOrProvince: getAddressFromAddressComponent(addressComponents)
      ?.stateOrProvince! as AddressRequestStateOrProvinceEnum,
    zipOrPostalCode: getAddressFromAddressComponent(addressComponents)
      ?.zipOrPostalCode!,
    country: getAddressFromAddressComponent(addressComponents)
      ?.country! as AddressRequestCountryEnum,
    type: '',
  };

  return address;
};

export const getGoogleLocationFromAddress = (address: AddressResponse) => {
  return {
    formatted_address: address?.oneLine,
    address_components: [],
    geometry: { location: { lat: undefined, lng: undefined } },
    place_id: '',
  };
};

export const getDefaultStateOrProvince = (tab: string) => {
  const stateOrProvinceValue = tab?.replace(/ +/g, '');
  const stateOrProvince =
    AddressResponseStateOrProvinceEnum[
      stateOrProvinceValue as keyof typeof AddressResponseStateOrProvinceEnum
    ];

  return stateOrProvince ?? undefined;
};

export const isCollapsible = (
  formValues: any,
  errors: any,
  fields: string[],
) => {
  return fields.every((field) => {
    const hasValue = formValues.hasOwnProperty(field) && !!formValues[field];
    const hasNoError = !errors.hasOwnProperty(field);

    // Check for nested objects
    if (field.split('.').length > 1) {
      const [parent, child] = field.split('.');
      return !errors?.[parent]?.[child] && formValues?.[parent]?.[child];
    }

    return hasValue && hasNoError;
  });
};
