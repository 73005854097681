import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import {
  Earning,
  EarningIcon,
  EarningNumber,
  EarningTitle,
  EarningType,
  LearnMoreBackground,
  PrimaryColor,
} from '../WealthManagementTypes';
import { WealthCircleIcon } from '../WealthCircleIcon';
import { setWealthKnowledgeLandingPage } from '../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum } from '../../../types';
import AnalyticsService from '../../../services/AnalyticsService';

const EarningTypeToEventMap: Record<EarningType, AnalyticsEventEnum> = {
  [Earning.COMMISSION_INCOME]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_COMMISSION_INCOME,
  [Earning.PRE_CAP_SPP]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_PRE_CAP,
  [Earning.POST_CAP_SPP]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_POST_CAP,
  [Earning.ELITE_PRODUCTION_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_ELITE_AGENT_PRODUCTION_AWARD,
  [Earning.ELITE_CULTURAL_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_ELITE_AGENT_CULTURAL_AWARD,
  [Earning.STOCK_AWARD_FOR_CAPPING]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_CAPPING_AWARD,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_STOCK_AWARD_FOR_ATTRACTING,
  [Earning.REVENUE_SHARE_INCOME]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_REVENUE_SHARE_INCOME,
};

interface LearnMoreTileProps {
  type: EarningType;
}

export const LearnMoreTile: React.FC<LearnMoreTileProps> = ({ type }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    AnalyticsService.instance().logEvent(EarningTypeToEventMap[type]);
    dispatch(setWealthKnowledgeLandingPage(type));
  };

  return (
    <div
      className='relative h-60 min-w-[220px] rounded-xl cursor-pointer overflow-hidden'
      onClick={handleClick}
      style={{
        background: LearnMoreBackground[type],
      }}
    >
      <div className='absolute -bottom-2 -left-7'>
        <WealthCircleIcon
          icon={EarningIcon[type]}
          iconColor={PrimaryColor[type]}
          iconBackgroundColor={LearnMoreBackground[type]}
          size='xl'
          hasOpacity
        />
      </div>
      <div className='absolute top-4 left-4 bg-white border rounded-full h-7 w-7'>
        <div className='text-zen-dark-7 text-sm flex items-center justify-center pt-1'>
          {EarningNumber[type]}
        </div>
      </div>
      <div className='absolute left-5 top-12 w-2/3 text-lg'>
        {EarningTitle[type]}
      </div>
      <FontAwesomeIcon
        icon={faChevronRight}
        size='lg'
        className='absolute right-4 bottom-7'
      />
    </div>
  );
};
