export const calculatePercentage = (
  value: number,
  total: number,
  precision: number = 2,
): number => {
  const percentage = Math.floor((value / total) * 100);

  return +percentage.toPrecision(precision);
};

export const compactNumber = (
  num: number,
  fixed: number | null = 0,
): string => {
  const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
  // eslint-disable-next-line no-bitwise
  const tier = (Math.log10(Math.abs(num)) / 3) | 0;

  if (tier === 0) {
    return num.toString();
  }

  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = num / scale;

  if (fixed === null) {
    return scaled + suffix;
  }

  return scaled.toFixed(fixed) + suffix;
};

export const getLabelAlternateNumber = (
  totalLabelsCount: number,
  labelsToShow: number = 7,
) => Math.round(Math.max(totalLabelsCount / labelsToShow, 1));
