import { ActivityType, EnumMap } from '../../src/types';

export const activityTypeToDisplayNameMap: EnumMap<ActivityType, string> = {
  JOURNEY_ITEM_COMPLETED: 'Journey Item Completed',
  JOURNEY_ITEM_INCOMPLETE: 'Incomplete Journey Item',
  CHECKLIST_ITEM_UPDATED: 'Checklist Item Updated',
  CHECKLIST_ITEM_ADDED: 'Checklist Item Added',
  CHECKLIST_ITEM_DELETED: 'Checklist Item Deleted',
  CHECKLIST_ITEM_LABEL_ADDED: 'Checklist Item Label Added',
  CHECKLIST_ITEM_LABEL_REMOVED: 'Checklist Item Label Removed',
  CHECKLIST_ITEM_NAME_UPDATED: 'Checklist Item Name Updated',
  CHECKLIST_ITEM_DESCRIPTION_UPDATED: 'Checklist Item Description Updated',
  DOCUMENT_UPLOADED: 'Document Uploaded',
  DOCUMENT_DELETED: 'Document Deleted',
  DOCUMENT_ATTACHED: 'Document Attached',
  DOCUMENT_REMOVED: 'Document Removed',
  NEW_VERSION_UPLOADED: 'New Version Uploaded',
  DROPBOX_RECEIVED_EMAIL: 'Email Received',
  DROPBOX_FILE_RECEIVED: 'Document Received',
  DROPBOX_FILE_UPLOADED: 'Document Uploaded',
  DROPBOX_FILE_VERSION_ADDED: 'New Version Uploaded',
  DROPBOX_FILE_DELETED: 'Document Deleted',
  DROPBOX_FILE_NAME_UPDATED: 'Document Name Edited',
  DROPBOX_FILE_FROM_REAL_SIGNATURE: 'Real Signature',
  COMMENT_CREATED: 'Comment Created',
  COMMENT_EDITED: 'Comment Edited',
  COMMENT_DELETED: 'Comment Deleted',
  COMMENT_READ: 'Comment Read',
  COMMISSION_PAYER_ASSIGNED: 'Commission Payer Assigned',
  PAYMENT_PARTICIPANT_ADDED: 'Payment Participant Added',
  PAYMENT_PARTICIPANT_REMOVED: 'Payment Participant Removed',
  OTHER_PARTICIPANT_ADDED: 'Other Participant Added',
  OTHER_PARTICIPANT_REMOVED: 'Other Participant Removed',
  TRANSACTION_FEE_ADDED: 'Transaction Fee Added',
  TRANSACTION_FEE_REMOVED: 'Transaction Fee Removed',
  TRANSACTION_ADDRESS_UPDATED: 'Transaction Address Updated',
  TRANSACTION_YEAR_BUILT_UPDATED: 'Transaction Year Built Updated',
  TRANSACTION_MLS_NUMBER_UPDATED: 'Transaction MLS Number Updated',
  TRANSACTION_ESCROW_NUMBER_UPDATED: 'Transaction Escrow Number Updated',
  TRANSACTION_SALE_PRICE_UPDATED: 'Transaction Sale Price Updated',
  TRANSACTION_LISTING_COMMISSION_UPDATED:
    'Transaction Listing Commission Updated',
  TRANSACTION_SALE_COMMISSION_UPDATED: 'Transaction Sale Commission Updated',
  TRANSACTION_CONTRACT_ACCEPTANCE_DATE_UPDATED:
    'Transaction Contract Acceptance Date Updated',
  TRANSACTION_ACTUAL_CLOSING_DATE_UPDATED:
    'Transaction Actual Closing Date Updated',
  TRANSACTION_ESTIMATED_CLOSING_DATE_UPDATED:
    'Transaction Estimated Closing Date Updated',
  TRANSACTION_CREATED: 'Transaction Created',
  LISTING_CREATED: 'Listing Created',
  REFERRAL_CREATED: 'Referral Created',
  TRANSACTION_CLOSED: 'Transaction Closed',
  TRANSACTION_APPROVED_FOR_CLOSING: 'Transaction Approved For Closing',
  TRANSACTION_PAYMENT_ACCEPTED: 'Transaction Payment Accepted',
  TRANSACTION_PAYMENT_SCHEDULED: 'Transaction Payment Scheduled',
  TRANSACTION_SETTLED: 'Transaction Settled',
  COMMISSION_VALIDATED: 'Commission Validated',
  COMMISSION_DOCUMENT_GENERATED: 'Commission Document Generated',
  COMMISSION_DOCUMENT_APPROVED: 'Commission Document Approved',
  COMMISSION_DOCUMENT_SENT: 'Commission Document Sent',
  TRANSACTION_PARTICIPANT_UPDATED: 'Transaction Participant Updated',
  TRUST_DEPOSIT_SUBMITTED: 'Trust Deposit Submitted',
  TRUST_DEPOSIT_INSTALLMENT_SUBMITTED: 'Trust Deposit Installment Submitted',
  TRUST_DEPOSIT_INSTALLMENT_CONFIRMED: 'Trust Deposit Installment Confirmed',
  COMMISSION_DEPOSIT_SUBMITTED: 'Commission Deposit Submitted',
  COMMISSION_DEPOSIT_INSTALLMENT_SUBMITTED:
    'Commission Deposit Installment Submitted',
  COMMISSION_DEPOSIT_INSTALLMENT_CONFIRMED:
    'Commission Deposit Installment Confirmed',
  EXCESS_FUNDS_RELEASED: 'Excess Funds Released',
  TRANSACTION_TERMINATED: 'Transaction Terminated',
  DOUBLE_ENDER_TRANSACTION_LINKED: 'Double Ender Transaction Linked',
  DOUBLE_ENDER_TRANSACTION_UNLINKED: 'Double Ender Transaction Unlinked',
  TRANSACTION_TAX_EXEMPT_UPDATED: 'Transaction Tax Exempt Updated',
  TRANSACTION_TAX_PAID_FROM_COMMISSION_UPDATED:
    'Transaction Tax Paid From Commission Updated',
  TRANSACTION_TAX_RATES_UPDATED: 'Transaction Tax Rate Updated',
  TRANSACTION_TAX_AMOUNT_UPDATED: 'Transaction Tax Amount Updated',
  TRANSACTION_TERMINATION_REQUESTED: 'Transaction Termination Requested',
  TRANSACTION_FIRM_DATE_UPDATED: 'Transaction Firm Date Updated',
  'Payment Settings Updated': 'Payment Settings Updated',
};

export const getActivityTypeDisplayName = (type: ActivityType) => {
  return activityTypeToDisplayNameMap[type] ?? 'All Other Activity Types';
};
