import React from 'react';
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPhoneNumber } from '../../utils/StringUtils';
import ZenIconText from '../Zen/ZenIconText';
import ZenInformationCard from '../Zen/ZenInformationCard';
import TitleIcon from '../../assets/img/person_icon.png';
import ZenLink from '../Zen/ZenLink';
import ZenAvatar from '../Zen/ZenAvatar';

export interface ProfileDisplayCardProps {
  title: string;
  imageUrl?: string;
  name?: string;
  phoneNumber?: string;
  emailAddress?: string;
  profileLink?(): void;
}

const ProfileDisplayCard: React.FC<ProfileDisplayCardProps> = ({
  title,
  phoneNumber,
  emailAddress,
  imageUrl,
  name,
  profileLink,
}) => {
  return (
    <ZenInformationCard title={title} titleIcon={TitleIcon}>
      <div className='flex md:flex-row md:p-6 p-4 md:space-x-2 space-x-1'>
        <div className='pt-2 pr-2'>
          <ZenAvatar name={name!} imageUrl={imageUrl} size='xl' />
        </div>
        <div className='space-y-2 font-zen-body text-base'>
          {name && <p>{name}</p>}
          {phoneNumber && (
            <ZenIconText
              text={formatPhoneNumber(phoneNumber)!}
              icon={<FontAwesomeIcon icon={faPhone} />}
            />
          )}
          {emailAddress && (
            <ZenIconText
              text={emailAddress}
              icon={<FontAwesomeIcon icon={faEnvelope} />}
            />
          )}
          <div>
            <ZenLink size='small' label='See Profile' onClick={profileLink} />
          </div>
        </div>
      </div>
    </ZenInformationCard>
  );
};

export default ProfileDisplayCard;
