import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import MainContentImage from '../../../../../assets/img/Homepage.png';
import { AgentWebsiteResponse } from '../../../../../openapi/yenta';
import { saveAgentWebsiteDetails } from '../../../../../slices/AgentSlice';
import { TEXT_CONTENT_VALIDATIONS } from '../../../../../utils/Validations';
import ZenStepContentInput from '../../../../agentWebsiteOnboarding/ZenStepContentInput';
import ZenControlledTextAreaInput from '../../../../Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../../../../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../../../../Zen/ZenSidebarModalForm';

interface ZenEditMainContentSidebarModalFormProps {
  isOpen: boolean;
  onClose(): void;
  agentWebsite: AgentWebsiteResponse;
}

interface FormData {
  name: string;
  websiteTitle: string;
  mainTitle: string;
  mainSubtitle: string;
  bottomTitle: string;
  bottomSubtitle: string;
}

const ZenEditMainContentSidebarModalForm: React.FC<ZenEditMainContentSidebarModalFormProps> = ({
  isOpen,
  onClose,
  agentWebsite,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    const updatedData: AgentWebsiteResponse = {
      ...agentWebsite,
      websiteTitle: formData.websiteTitle,
      mainTitle: formData.mainTitle,
      mainSubtitle: formData.mainSubtitle,
      bottomTitle: formData.bottomTitle,
      bottomSubtitle: formData.bottomSubtitle,
    };
    dispatch(saveAgentWebsiteDetails(updatedData));
    onClose();
  };

  return (
    <ZenSidebarModalForm
      title='Main Content'
      subtitle='This content shows on the Home page'
      isOpen={isOpen}
      onClose={onClose}
      actionTitle='Save'
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex justify-center items-center'>
        <img
          src={MainContentImage}
          alt='main-content'
          className='w-4/5 object-contain'
        />
      </div>
      <div className='mt-5'>
        <ZenStepContentInput step={1}>
          <ZenControlledTextInput<FormData, 'name'>
            control={control}
            name='name'
            label='Your display name'
            placeholder='Display Name'
            readOnly
            rules={{ required: 'Display Name is required' }}
            defaultValue={agentWebsite.agentResponse?.displayName}
            isRequired
          />
        </ZenStepContentInput>
      </div>
      <div className='mt-5'>
        <ZenStepContentInput step={2}>
          <ZenControlledTextInput<FormData, 'websiteTitle'>
            control={control}
            name='websiteTitle'
            label='Your title/certification'
            placeholder='title/certification'
            rules={{
              required: 'Title is required',
              ...TEXT_CONTENT_VALIDATIONS,
            }}
            defaultValue={agentWebsite.websiteTitle}
            isRequired
          />
        </ZenStepContentInput>
      </div>
      <div className='mt-5'>
        <ZenStepContentInput step={3}>
          <ZenControlledTextInput<FormData, 'mainTitle'>
            control={control}
            name='mainTitle'
            label='Home page main title'
            placeholder='main title'
            rules={{
              required: 'main title is required',
              ...TEXT_CONTENT_VALIDATIONS,
            }}
            defaultValue={agentWebsite.mainTitle}
            isRequired
          />
        </ZenStepContentInput>
      </div>
      <div className='mt-5'>
        <ZenStepContentInput step={4}>
          <ZenControlledTextInput<FormData, 'mainSubtitle'>
            control={control}
            name='mainSubtitle'
            label='Subtitle'
            placeholder='Subtitle'
            rules={{
              required: 'Subtitle is required',
              ...TEXT_CONTENT_VALIDATIONS,
            }}
            defaultValue={agentWebsite.mainSubtitle}
            isRequired
          />
        </ZenStepContentInput>
      </div>
      <div className='mt-5'>
        <ZenStepContentInput step={5}>
          <ZenControlledTextInput<FormData, 'bottomTitle'>
            control={control}
            name='bottomTitle'
            label='Message title'
            placeholder='Message title'
            rules={{
              required: 'Message title is required',
              ...TEXT_CONTENT_VALIDATIONS,
            }}
            defaultValue={agentWebsite.bottomTitle}
            isRequired
          />
        </ZenStepContentInput>
      </div>
      <div className='mt-5'>
        <ZenStepContentInput step={6}>
          <ZenControlledTextAreaInput<FormData, 'bottomSubtitle'>
            control={control}
            name='bottomSubtitle'
            label='Message content'
            subLabel='(255 characters max)'
            rows={3}
            placeholder='Message content'
            rules={{
              required: 'Message content is required',
              ...TEXT_CONTENT_VALIDATIONS,
            }}
            defaultValue={agentWebsite.bottomSubtitle}
            isRequired
          />
        </ZenStepContentInput>
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenEditMainContentSidebarModalForm;
