import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import HappySupportFeedbackModal from '../components/transactions/HappyFeature/HappySupportFeedbackModal';
import { createTransactionBuilder } from '../slices/TransactionBuilderSlice';
import { RootState } from '../types';
import ZenButton from '../components/Zen/ZenButton';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenMyTransactions from '../components/Zen/Transaction/ZenMyTransactions';
import { fetchTransactionsCountGroupByLifecycleState } from '../slices/TransactionSlice';

const ZenMyTransactionsIndexRoute: React.FC = () => {
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );

  const PageLayoutComponent =
    location.pathname === '/transactions/draft'
      ? ZenPageLayout
      : ZenPageLayoutWithSearch;

  useEffect(() => {
    dispatch(
      fetchTransactionsCountGroupByLifecycleState(
        userDetail?.id!,
        'TRANSACTION',
      ),
    );
  }, [userDetail?.id, dispatch]);

  return (
    <PageLayoutComponent
      path={[
        { title: 'Home', url: '/' },
        { title: 'My Transactions', url: '/transactions' },
      ]}
      search={search}
      onSearchChange={setSearch}
    >
      <div className='md:flex items-center md:justify-between px-2 md:px-4 py-4 space-y-2 md:space-y-0'>
        <div className='text-xl font-zen-body font-semibold'>
          My Transactions
        </div>
        <div className='flex items-center justify-between md:space-x-4'>
          <ZenButton
            variant='primary-outline'
            label='Create Referral'
            LeftIconComponent={
              <FontAwesomeIcon
                className='text-primary-blue mr-1'
                size='sm'
                icon={faPlus}
              />
            }
            onClick={() => history.push('/transaction/referral')}
          />
          <ZenButton
            variant='primary'
            label='Create Transaction'
            LeftIconComponent={
              <FontAwesomeIcon
                className='text-white mr-1'
                size='sm'
                icon={faPlus}
              />
            }
            onClick={async () => {
              const transactionBuilderId = await dispatch(
                createTransactionBuilder(),
              );
              if (transactionBuilderId) {
                history.push(`/transaction/create/${transactionBuilderId}`);
              }
            }}
          />
        </div>
      </div>
      <div className='lg:py-1.5'>
        <ZenMyTransactions agentId={userDetail?.id!} search={search} />
      </div>
      <HappySupportFeedbackModal />
    </PageLayoutComponent>
  );
};

export default ZenMyTransactionsIndexRoute;
