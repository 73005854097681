import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { cn } from '../../../../utils/classUtils';

interface LeoVideoModalProps {
  title: string;
  play: boolean;
  setPlay: (play: boolean) => void;
  videoUrl: string;
  onClose?: () => void;
}

const LeoVideoModal: React.FC<LeoVideoModalProps> = ({
  title,
  play,
  setPlay,
  videoUrl,
  onClose = () => {},
}) => {
  const ref = useRef<HTMLIFrameElement>(null);

  return (
    <div
      className={cn(
        'inset-0 bg-black bg-opacity-75 z-50 transition',
        play ? 'fixed' : 'hidden',
      )}
      onClick={() => {
        setPlay(false);
        if (ref.current) {
          // stop video from playing in background
          ref.current.src = '';
          ref.current.src = videoUrl;
        }
        onClose();
      }}
      data-testid='video-iframe-container'
    >
      <div className='w-full h-full flex items-center justify-center'>
        <div className='relative w-5/6 sm:w-1/2 h-1/4 sm:h-1/2'>
          <iframe
            ref={ref}
            title={title}
            src={videoUrl}
            allow='autoplay'
            allowFullScreen
            className='w-full h-full rounded'
            data-testid='video-iframe'
          />
          <div className='absolute -top-3 -right-4 sm:-top-3 sm:-right-3 bg-regent-400 w-6 h-6 rounded-full flex items-center justify-center'>
            <FontAwesomeIcon
              icon={faClose}
              className='text-white bg-regent-400 cursor-pointer text-sm'
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeoVideoModal;
