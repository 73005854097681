import qs from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLoader from '../components/DefaultLoader';
import PageLayout from '../components/PageLayout';
import ZenEventsFilter from '../components/Zen/Event/ZenEventsFilter';
import ZenEventsTabComponent from '../components/Zen/Event/ZenEventsTabComponent';
import ZenSearchBar from '../components/Zen/ZenSearchBar';
import ZenTabs from '../components/Zen/ZenTabs';
import {
  fetchAgentCalendars,
  fetchEventsByType,
  fetchEventsCountForAgent,
} from '../slices/CalendarSlice';
import { RootState } from '../types';
import { isSmScreen } from '../utils/BrowserUtils';
import { EventTabEnum } from '../utils/EventHelper';
import { capitalizeEnum } from '../utils/StringUtils';
import ZenRoute from '../components/Zen/ZenRoute';

const AgentEventsRoute: React.FC = () => {
  const dispatch = useDispatch();
  const {
    auth: { userDetail },
    calendar: { agentCalendarsResponse, eventsCountResponse },
  } = useSelector((state: RootState) => state);
  const history = useHistory();
  const location = useLocation();
  const [search, setSearch] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>(EventTabEnum.UPCOMING);

  const calendarIds = useMemo(() => {
    const { calendarIds } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as { calendarIds: string[] };

    return calendarIds;
  }, [location.search]);

  const getEventTabComponentHeader = (tab: EventTabEnum, count: number) => {
    return <span>{`${capitalizeEnum(tab)} (${count})`}</span>;
  };

  const fetchEvents = useCallback(
    async (tab: EventTabEnum, calendarIds?: string[]) => {
      const userDetailId = userDetail?.id!;
      await dispatch(
        fetchEventsCountForAgent(userDetailId, calendarIds, false),
      );
      await dispatch(fetchEventsByType(tab!, userDetailId, calendarIds));
    },
    [dispatch, userDetail?.id],
  );

  const fetchData = useCallback(async () => {
    await dispatch(fetchEventsCountForAgent(userDetail?.id!));
    await dispatch(fetchAgentCalendars(userDetail?.id!));
  }, [dispatch, userDetail?.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (eventsCountResponse.loading || agentCalendarsResponse.loading) {
    return <DefaultLoader />;
  }

  return (
    <ZenRoute title='My Events'>
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'My Events', url: '/my-events' },
        ]}
      >
        <div className='md:flex items-center justify-between md:p-8 p-4'>
          <div className='text-2xl font-zen-body text-zen-dark font-semibold'>
            My Events
          </div>
          <div className='flex flex-row items-center space-x-4 md:mt-0 mt-4 md:w-2/5'>
            <div className='md:w-3/4 flex flex-row justify-end'>
              <ZenSearchBar
                value={search}
                onChange={setSearch}
                placeholder='Search by keywords or ID'
                showTransition={!isSmScreen()}
              />
            </div>
            <div className='md:w-1/4 self-end md:self-start'>
              <ZenEventsFilter
                calendars={agentCalendarsResponse.data?.calendars || []}
                onSubmit={({ calendarIds }) =>
                  history.replace(
                    `/my-events?${qs.stringify({
                      calendarIds,
                    })}`,
                  )
                }
                calendarIds={calendarIds}
              />
            </div>
          </div>
        </div>
        <ZenTabs
          tabs={[
            {
              name: EventTabEnum.YES,
              headerComponent: getEventTabComponentHeader(
                EventTabEnum.YES,
                eventsCountResponse?.data?.rsvpYesCount!,
              ),
              TabComponent: (
                <ZenEventsTabComponent
                  tab={EventTabEnum.YES}
                  fetchEvents={fetchEvents}
                  calendarIds={calendarIds}
                  search={search}
                />
              ),
            },
            {
              name: EventTabEnum.NO,
              headerComponent: getEventTabComponentHeader(
                EventTabEnum.NO,
                eventsCountResponse.data?.rsvpNoCount!,
              ),
              TabComponent: (
                <ZenEventsTabComponent
                  tab={EventTabEnum.NO}
                  fetchEvents={fetchEvents}
                  calendarIds={calendarIds}
                  search={search}
                />
              ),
            },
            {
              name: EventTabEnum.MAYBE,
              headerComponent: getEventTabComponentHeader(
                EventTabEnum.MAYBE,
                eventsCountResponse.data?.rsvpMaybeCount!,
              ),
              TabComponent: (
                <ZenEventsTabComponent
                  tab={EventTabEnum.MAYBE}
                  fetchEvents={fetchEvents}
                  calendarIds={calendarIds}
                  search={search}
                />
              ),
            },
            {
              name: EventTabEnum.UPCOMING,
              headerComponent: getEventTabComponentHeader(
                EventTabEnum.UPCOMING,
                eventsCountResponse.data?.upcomingCount!,
              ),
              TabComponent: (
                <ZenEventsTabComponent
                  tab={EventTabEnum.UPCOMING}
                  fetchEvents={fetchEvents}
                  calendarIds={calendarIds}
                  search={search}
                />
              ),
            },
            {
              name: EventTabEnum.PAST,
              headerComponent: getEventTabComponentHeader(
                EventTabEnum.PAST,
                eventsCountResponse.data?.pastCount!,
              ),
              TabComponent: (
                <ZenEventsTabComponent
                  tab={EventTabEnum.PAST}
                  fetchEvents={fetchEvents}
                  calendarIds={calendarIds}
                  search={search}
                />
              ),
            },
          ]}
          selected={selectedTab}
          onChange={setSelectedTab}
          size='sm'
        />
      </PageLayout>
    </ZenRoute>
  );
};

export default AgentEventsRoute;
