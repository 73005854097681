import { faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { WillBeneficiaryAndTypeResponse } from '../../../openapi/yenta';
import UpdateBeneficiaryScreenV2 from '../RevenueShare/V2/UpdateBeneficiaryScreenV2';

export interface UpdateBeneficiaryModalProps {
  isOpen: boolean;
  beneficiary: WillBeneficiaryAndTypeResponse;
  onClose(): void;
}

const UpdateBeneficiaryModal: React.FC<UpdateBeneficiaryModalProps> = ({
  isOpen,
  beneficiary,
  onClose,
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'
      title='zen-modal'
    >
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full lg:p-0 scrollbar overflow-scroll'>
        <div
          className='md:w-1/2 w-11/12 rounded-xl z-10 relative'
          aria-label='simple-modal'
        >
          <UpdateBeneficiaryScreenV2
            close={onClose}
            beneficiary={beneficiary}
          />
          <div className='absolute top-0 right-0 my-10 mr-8 md:mr-12'>
            <FontAwesomeIcon
              icon={faX}
              className='text-sm p-1 px-1.5 bg-dark bg-opacity-10 text-regent-100 rounded-full cursor-pointer'
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateBeneficiaryModal;
