import { faBoxArchive } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FileCabinetActionCard from './FileCabinetActionCard';

const tabs = [
  { name: 'View All' },
  { name: 'Archive', icon: <FontAwesomeIcon icon={faBoxArchive} /> },
];

interface FileCabinetActionProps {
  onViewAll: () => void;
  onArchive: () => void;
}

const FileCabinetAction: React.FC<FileCabinetActionProps> = ({
  onViewAll,
  onArchive,
}) => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState('View All');

  useEffect(() => {
    if (location.search === '?isArchive=true') {
      setSelectedTab('Archive');
    }
  }, [location]);

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
    if (tabName === 'View All') {
      onViewAll();
    } else if (tabName === 'Archive') {
      onArchive();
    }
  };

  return (
    <div className='rounded-lg w-full overflow-x-auto py-1 px-2 bg-regent-300'>
      <div className='flex flex-row justify-between items-center gap-8'>
        {tabs.map((val) => (
          <FileCabinetActionCard
            onClick={() => handleTabClick(val.name)}
            selected={selectedTab === val.name}
            key={val.name}
            name={val.name}
            icon={val.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default FileCabinetAction;
