import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryScatter,
  VictoryTheme,
} from 'victory';
import ChartIcon from '../../../assets/img/wealthManagement/chart-mixed-up-circle-dollar-light.svg';
import { CommissionGraphDataPoint, MoneyValue } from '../../../openapi/plutus';
import { RootState } from '../../../types';
import { displayFormattedFixedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import { DEFAULT_REGULAR_FONT } from '../../../utils/fonts.constants';
import { compactNumber } from '../../../utils/MathUtils';
import ResourceContainer from '../../ResourceContainer';
import { WealthManagementCard } from '../WealthManagementCard';
import { Earning, EarningType, PrimaryColor } from '../WealthManagementTypes';
import { dateDifference, WealthPlanTooltip } from '../WealthManagementUtils';

const TEAM_SPLIT_PERCENT_THRESHOLD = 10;

interface ContainerProps {
  noContainer?: boolean;
  children: React.ReactNode;
}

const ChartContainer: React.FC<ContainerProps> = ({
  noContainer,
  children,
}) => {
  if (noContainer) {
    return <div>{children}</div>;
  } else {
    return <WealthManagementCard>{children}</WealthManagementCard>;
  }
};

interface IncomeProjectionChartProps {
  earningType: EarningType;
  title?: {
    text: string;
    tooltip?: string;
  };
  noContainer?: boolean;
}

interface ChartData {
  legend?: Array<{
    name: string;
    color: string;
  }>;
  data: CommissionGraphDataPoint[] | undefined;
  data2?: CommissionGraphDataPoint[] | undefined;
  tooltip?: {
    text: string;
    onPress: () => void;
  };
  color: string;
  color2?: string;
  projectedIncome?: MoneyValue;
}

const LabelComponent = (props: any) => (
  <VictoryLabel
    {...props}
    backgroundPadding={{
      left: 7,
      right: 7,
      top: 6,
      bottom: 3,
    }}
    dx={props.dx || -60}
    dy={props.dy || 0}
    backgroundStyle={{
      fill: 'white',
      strokeWidth: 1,
      stroke: props.borderColor || '#EDEFF3',
      rx: 2,
    }}
    style={{
      fill: '#666666',
      fontSize: 10,
    }}
  />
);

const IncomeProjectionChart: React.FC<IncomeProjectionChartProps> = ({
  earningType,
  title = {
    text: 'Projected Earnings',
    tooltip: '',
  },
  noContainer = false,
}) => {
  const {
    agentDetail: {
      incomeOverviewResponse: { data: incomeOverviewResponse },
    },
    wealthManagement: {
      commissionIncomeResponse,
      eliteAgentCulturalAwardResponse,
      eliteAgentProductionAwardResponse,
      revenueShareIncomeResponse,
      cappingAwardResponse,
      attractingStockAwardResponse,
    },
  } = useSelector((state: RootState) => state);

  const projectedTeamNetCommissionIncome =
    commissionIncomeResponse?.data?.projectedTeamNetCommissionIncome?.amount ??
    0;
  const projectedIndividualNetCommissionIncome =
    commissionIncomeResponse?.data?.projectedIndividualNetCommissionIncome
      ?.amount ?? 0;

  const chartData: { [key: string]: ChartData } = {
    [Earning.COMMISSION_INCOME]: {
      data:
        commissionIncomeResponse.data?.individualCommissionFiveYearGraphData,
      data2:
        projectedTeamNetCommissionIncome > 0
          ? commissionIncomeResponse.data?.teamCommissionFiveYearGraphData
          : undefined,
      legend: [
        {
          name: 'Individual',
          color: PrimaryColor.COMMISSION_INCOME,
        },
        {
          name: 'Team Split',
          color: '#FABE05',
        },
      ],
      color: PrimaryColor.COMMISSION_INCOME,
      color2: '#FABE05',
      projectedIncome:
        commissionIncomeResponse.data?.projectedNetCommissionIncome,
    },
    [Earning.ELITE_CULTURAL_AWARD]: {
      data: eliteAgentCulturalAwardResponse.data?.fiveYearGraphData,
      color: PrimaryColor.ELITE_CULTURAL_AWARD,
    },
    [Earning.ELITE_PRODUCTION_AWARD]: {
      data: eliteAgentProductionAwardResponse.data?.fiveYearGraphData,
      color: PrimaryColor.ELITE_PRODUCTION_AWARD,
    },
    [Earning.REVENUE_SHARE_INCOME]: {
      data: revenueShareIncomeResponse.data?.fiveYearGraphData,
      color: PrimaryColor.REVENUE_SHARE_INCOME,
    },
    [Earning.STOCK_AWARD_FOR_ATTRACTING]: {
      data: attractingStockAwardResponse.data?.fiveYearGraphData,
      color: PrimaryColor.STOCK_AWARD_FOR_ATTRACTING,
    },
    [Earning.STOCK_AWARD_FOR_CAPPING]: {
      data: cappingAwardResponse.data?.fiveYearGraphData,
      color: PrimaryColor.STOCK_AWARD_FOR_CAPPING,
    },
  };

  const dataPoints = (chartData[earningType].data ?? []).map((d) => ({
    date: new Date(d.date ?? 0),
    amount: d.value?.amount ?? 0,
  }));

  const dataPoints2 = (chartData[earningType].data2 ?? []).map((d, i) => {
    if (
      !!chartData[earningType].data2 &&
      i === chartData[earningType].data2?.length! - 1
    ) {
      const amount = d.value?.amount! > 0 ? d.value?.amount! : 0;
      return {
        date: new Date(d.date ?? 0),
        amount: projectedIndividualNetCommissionIncome + amount,
      };
    }

    return {
      date: new Date(d.date ?? 0),
      amount: d.value?.amount ?? 0,
    };
  });

  const sumOfData1Amounts = dataPoints.reduce((acc, curr) => {
    curr.amount += acc;
    return curr.amount;
  }, 0);

  const sumOfData2Amounts = dataPoints2.reduce((acc, curr) => {
    curr.amount += acc;
    return curr.amount;
  }, 0);

  const graph1Label =
    earningType === Earning.COMMISSION_INCOME ? 'Individual' : 'Projected';

  const cycleStartDate = dataPoints?.[0]?.date;

  const formatYAxisTick = (tick: number) => {
    return `$${compactNumber(parseFloat(tick.toFixed(2)))}`;
  };

  const formatXAxisTick = (tick: number) => {
    const date = new Date(cycleStartDate.toString());
    date.setDate(date.getDate() + tick);

    return date.getFullYear();
  };

  if (dataPoints.length === 0 && dataPoints2.length === 0) {
    return null;
  }

  const yAxis =
    Math.max(
      ...dataPoints.map((d) => d.amount),
      ...dataPoints2.map((d) => d.amount),
    ) * 1.1;

  if (sumOfData1Amounts === 0 && sumOfData2Amounts === 0) {
    return null;
  }

  const showTeamSplit = (chartData[earningType]?.data2?.length || 0) > 0;
  const teamSplitPercentage = Math.floor(
    (projectedTeamNetCommissionIncome /
      (projectedIndividualNetCommissionIncome +
        projectedTeamNetCommissionIncome)) *
      100,
  );

  return (
    <ResourceContainer
      loading={false}
      isEmpty={!dataPoints?.length}
      resourceName='Income Projection'
      emptyMessage='No data to show graph'
      emptyIcon={
        <img src={ChartIcon} className='w-7 h-7 mb-2' alt='chart-icon' />
      }
    >
      <ChartContainer noContainer={noContainer}>
        <div className='flex items-center'>
          <img
            src={ChartIcon}
            className='w-[17px] h-[15px] mr-1 -mt-1'
            alt='chart-icon'
          />
          <span className='text-base font-semibold text-zen-dark-9'>
            {title.text}
          </span>
          {title.tooltip && (
            <div className='ml-1'>
              <WealthPlanTooltip title={title.tooltip}>
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className='text-zen-dark-8 text-sm'
                />
              </WealthPlanTooltip>
            </div>
          )}
        </div>
        {!!incomeOverviewResponse?.anniversaryDate && (
          <p className='text-zen-dark-6 text-base mt-2'>
            Cap Resets on:{' '}
            {DateTime.fromISO(incomeOverviewResponse?.anniversaryDate).toFormat(
              'MM/dd/yyyy',
            )}
          </p>
        )}
        <div className='flex flex-col items-center justify-center w-[98%]'>
          <VictoryChart
            theme={VictoryTheme.grayscale}
            domain={{
              y: [0, yAxis > 0 ? yAxis : 1000],
            }}
          >
            <defs>
              <linearGradient
                id='projectionGradient'
                x1={0}
                x2={0}
                y1={0}
                y2={1}
              >
                <stop
                  offset='0%'
                  stopColor={chartData[earningType].color}
                  stopOpacity={0.4}
                />
                <stop
                  offset='100%'
                  stopColor={chartData[earningType].color}
                  stopOpacity={0}
                />
              </linearGradient>
              <linearGradient
                id='projectionGradient2'
                x1={0}
                x2={0}
                y1={0}
                y2={0.7}
              >
                <stop
                  offset='0%'
                  stopColor={chartData[earningType].color2}
                  stopOpacity={0.4}
                />
                <stop
                  offset='100%'
                  stopColor={chartData[earningType].color2}
                  stopOpacity={0}
                />
              </linearGradient>
              <filter
                id='shadow-blue'
                x='-50%'
                y='-50%'
                width='200%'
                height='200%'
              >
                <feDropShadow
                  dx='0'
                  dy='0'
                  stdDeviation='2'
                  floodColor={PrimaryColor[earningType]}
                />
              </filter>
            </defs>
            <VictoryAxis // Y Axis
              dependentAxis
              tickFormat={formatYAxisTick}
              style={{
                grid: { stroke: '#D2D5DB', strokeWidth: 0.25 },
                axis: { stroke: '#D2D5DB', strokeWidth: 1 },
                tickLabels: {
                  fill: '#D2D5DB',
                  fontFamily: DEFAULT_REGULAR_FONT,
                },
              }}
            />
            <VictoryAxis // X Axis
              tickFormat={formatXAxisTick}
              tickValues={[365, 730, 1095, 1460, 1825]}
              style={{
                axis: { stroke: '#D2D5DB', strokeWidth: 1 },
                tickLabels: {
                  fill: '#D2D5DB',
                  fontFamily: DEFAULT_REGULAR_FONT,
                },
              }}
            />

            <VictoryArea // Chart
              style={{
                data: {
                  fill: 'url(#projectionGradient)',
                  stroke: PrimaryColor[earningType],
                  strokeWidth: 0.5,
                  strokeDasharray: '5, 4',
                },
              }}
              data={dataPoints.map((data) => ({
                x: dateDifference(dataPoints[0].date, data.date),
                y: data.amount,
              }))}
            />

            {showTeamSplit && (
              <VictoryArea
                style={{
                  data: {
                    fill: 'url(#projectionGradient2)',
                    stroke: chartData[earningType].color2,
                    strokeWidth: 0.5,
                    strokeDasharray: '5, 4',
                  },
                }}
                data={dataPoints2.map((data) => ({
                  x: dateDifference(dataPoints2[0].date, data.date),
                  y: data.amount,
                }))}
              />
            )}

            <VictoryScatter // Last data point
              data={[
                {
                  x: dateDifference(
                    dataPoints[0].date,
                    dataPoints[dataPoints.length - 1].date,
                  ),
                  y: dataPoints[dataPoints.length - 1].amount,
                  label: `${graph1Label} ${displayFormattedFixedAmountWithCurrency(
                    { amount: dataPoints[dataPoints.length - 1].amount },
                    false,
                    0,
                  )}`,
                },
              ]}
              size={3}
              style={{
                data: {
                  fill: chartData[earningType].color,
                  filter: 'url(#shadow-blue)',
                },
              }}
              labelComponent={
                <LabelComponent
                  {...(earningType !== Earning.COMMISSION_INCOME && {
                    dx: -56,
                  })}
                  dy={0}
                  borderColor={PrimaryColor[earningType]}
                />
              }
            />

            {showTeamSplit && (
              <VictoryScatter
                data={[
                  {
                    x: dateDifference(
                      dataPoints2[0].date,
                      dataPoints2[dataPoints2.length - 1].date,
                    ),
                    y: dataPoints2[dataPoints2.length - 1].amount,
                    label: `Team Split ${displayFormattedFixedAmountWithCurrency(
                      { amount: projectedTeamNetCommissionIncome },
                      false,
                      0,
                    )}`,
                  },
                ]}
                size={3}
                style={{
                  data: {
                    fill: chartData[earningType].color2,
                    filter: 'url(#shadow-blue)',
                  },
                }}
                labelComponent={
                  <LabelComponent
                    dy={
                      teamSplitPercentage <= TEAM_SPLIT_PERCENT_THRESHOLD
                        ? -20 *
                          ((TEAM_SPLIT_PERCENT_THRESHOLD -
                            teamSplitPercentage) /
                            TEAM_SPLIT_PERCENT_THRESHOLD)
                        : 0
                    }
                    borderColor='#FABE05'
                  />
                }
              />
            )}
          </VictoryChart>
          <div className='w-full flex justify-center items-center space-x-10'>
            {!isEmpty(chartData[earningType].legend) &&
              chartData[earningType].legend?.map(({ name, color }, i) => {
                if (i === 0 && sumOfData1Amounts === 0) return null;
                if (i === 1 && sumOfData2Amounts === 0) return null;
                return (
                  <div key={name} className='flex items-center'>
                    <div
                      className='w-2 h-2 rounded-full mr-2'
                      style={{ backgroundColor: color }}
                    />
                    <span className='font-zen-body text-base text-zen-dark-6'>
                      {name}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </ChartContainer>
    </ResourceContainer>
  );
};

export default IncomeProjectionChart;
