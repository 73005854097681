import React from 'react';
import { ReactComponent as NoteAdd } from '../../../assets/img/note_add.svg';
import ZenButton from '../ZenButton';

interface ZenDocumentTitleHeaderProps {
  documentNumber: number;
  title: string;
  isDraft?: boolean;
  onAddNote?(): void;
}

const ZenDocumentTitleHeader: React.FC<ZenDocumentTitleHeaderProps> = ({
  documentNumber,
  title,
  isDraft = false,
  onAddNote,
}) => {
  return (
    <div
      className='sticky top-0 space-x-2 flex flex-row items-center bg-grey-300 px-4 py-2'
      id={`header-${documentNumber}`}
    >
      <p className='font-zen-body text-lg text-dark'>
        {`${documentNumber}. ${title}`}
      </p>
      {isDraft && (
        <ZenButton
          label='Add Note'
          variant='secondary-outline'
          LeftIconComponent={
            <NoteAdd fontSize={16} className='text-zen-dark-9 mb-1' />
          }
          onClick={onAddNote}
        />
      )}
    </div>
  );
};

export default ZenDocumentTitleHeader;
