import React from 'react';
import { CellProps, Column } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { TierNetworkSize } from '../../openapi/yenta';
import { TierRevshareResponse } from '../../openapi/arrakis';
import { MoneyValue } from '../../openapi/bff';
import ZenFixedDataTable from '../Zen/Table/ZenFixedDataTable';
import {
  revShareInsightsTierIconLockedMap,
  revShareInsightsTierIconUnlockedMap,
} from '../../utils/RevShareInsightsUtils';
import { displayAmount } from '../../utils/CurrencyUtils';

export type ContributionColumnData = {
  isTierUnlocked: boolean;
  networkTier: TierNetworkSize;
  revShareByTier?: TierRevshareResponse;
  totalContribution?: MoneyValue;
  onClickTier(): void;
  onClickTierAgents(): void;
};

export const columns: Column<ContributionColumnData>[] = [
  {
    Header: 'Contributors',
    id: 'contributors',
    Cell: ({ row: { original, index } }: CellProps<ContributionColumnData>) => {
      const tier = original.networkTier?.tier! || index + 1;
      const TierIcon = original.isTierUnlocked
        ? revShareInsightsTierIconUnlockedMap[tier]
        : revShareInsightsTierIconLockedMap[tier];

      return (
        <div className='flex items-center space-x-2 text-zen-dark-9'>
          {!!TierIcon && <TierIcon width={25} height={25} />}
          <p className='-mt-2'>
            <FontAwesomeIcon
              icon={solid('period')}
              className='text-zen-dark-12'
            />
          </p>
          <button
            className='flex items-center space-x-1 text-primary-blue'
            onClick={original.onClickTierAgents}
            aria-label={`view-tier${tier}-contributors`}
          >
            <FontAwesomeIcon icon={regular('user')} size='sm' />
            <p>{original.revShareByTier?.numberOfContributors || 0}</p>
          </button>
        </div>
      );
    },
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Earned',
    id: 'earned',
    Cell: ({ row: { original } }: CellProps<ContributionColumnData>) => (
      <p className='font-semibold text-zen-dark-9'>
        {displayAmount(original.revShareByTier?.earnedRevshareAmount, {
          hideZeroCents: true,
        })}
      </p>
    ),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Missed',
    Cell: ({ row: { original } }: CellProps<ContributionColumnData>) => (
      <p className='font-semibold text-zen-dark-12 space-x-1'>
        <FontAwesomeIcon icon={faLock} />
        <span>
          {displayAmount(original.revShareByTier?.missedRevshareAmount, {
            hideZeroCents: true,
          })}
        </span>
      </p>
    ),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: '',
    id: 'link',
    Cell: ({ row: { original, index } }: CellProps<ContributionColumnData>) => {
      const tier = original.networkTier?.tier || index + 1;

      return (
        <FontAwesomeIcon
          icon={regular('arrow-up-right-from-square')}
          size='sm'
          className='text-primary-blue cursor-pointer'
          onClick={original.onClickTier}
          aria-label={`view-tier${tier}-contributions`}
        />
      );
    },
    disableSortBy: true,
    disableFilters: true,
  },
];

interface RevShareInsightsContributionTableProps {
  data: ContributionColumnData[];
}

const RevShareInsightsContributionTable: React.FC<RevShareInsightsContributionTableProps> = ({
  data,
}) => {
  return (
    <div aria-label='contribution-table'>
      <ZenFixedDataTable
        columns={columns}
        resourceName='contribution'
        data={data}
        hidePagination
        hidePageSize
        hideFilters
      />
    </div>
  );
};

export default RevShareInsightsContributionTable;
