import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsis, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniq } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { ReactComponent as EmptyState } from '../../../assets/img/emptyState.svg';
import { useGemini } from '../../../hooks/useGemini';
import { ChecklistDefinitionDto } from '../../../openapi/yenta';
import {
  deleteOfficeChecklistDefinition,
  fetchChecklistDefinition,
} from '../../../slices/ChecklistDefinitionSlice';
import { AppDispatch, RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import FixedDataTable from '../../FixedDataTable';
import FontAwesomeIconWithWrapper from '../../FontAwesomeIconWithWrapper';
import ResourceContainer from '../../ResourceContainer';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../../Zen/ZenButton';
import ZenDropdown from '../../Zen/ZenDropdown';
import AddOfficeTemplate from './AddOfficeTemplate';
import OfficeDealTypeCell from './OfficeDealTypeCell';
import OfficeSetDefaultTemplatesSidebarModal from './OfficeSetDefaultTemplatesSidebarModal';

interface OfficeTemplatesProps {
  officeId: string;
}

const columns: Array<Column<ChecklistDefinitionDto>> = [
  {
    Header: 'Template Type',
    accessor: 'type',
    Cell: ({ value }) => (
      <p className='font-zen-body text-sm font-semibold text-dark'>
        {capitalizeEnum(value)}
      </p>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Contract',
    accessor: 'targetType',
    Cell: ({ value }) => (
      <p className='font-zen-body text-sm font-semibold text-dark'>
        {capitalizeEnum(value)}
      </p>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Representation',
    accessor: 'representee',
    Cell: ({ value }) => (
      <p className='font-zen-body text-sm font-semibold text-dark'>
        {capitalizeEnum(value)}
      </p>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Deal Type',
    accessor: 'dealType',
    Cell: ({ value }) => <OfficeDealTypeCell dealType={value} />,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Property Type',
    accessor: 'propertyTypes',
    Cell: ({ value }) => (
      <p className='font-zen-body text-sm font-semibold text-dark'>
        {value?.map((v) => capitalizeEnum(v)).join(', ')}
      </p>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
];

const OfficeTemplates: React.FC<OfficeTemplatesProps> = ({ officeId }) => {
  const dispatch: AppDispatch = useDispatch();
  const isGeminiEnabled = useGemini();

  const {
    checkListDefinition: {
      checklistDefinitionByOfficeId,
      checklistDefinitionById,
    },
    office: { officeDetailById },
  } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const [
    isDeletingOfficeChecklist,
    setIsDeletingOfficeChecklist,
  ] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const officeCheckList = checklistDefinitionByOfficeId[officeId];
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState<boolean>(
    false,
  );
  const [isSetDefaults, setIsSetDefaults] = useState<boolean>(false);
  const [checkList, setCheckList] = useState<
    ChecklistDefinitionDto | undefined
  >(undefined);

  const columnsWithActions: Array<Column<ChecklistDefinitionDto>> = [
    {
      Header: 'Item Name',
      accessor: 'checklistDefinitionId',
      Cell: ({ value }) => (
        <p className='font-zen-body text-black'>
          {checklistDefinitionById[value!]?.data?.name}
        </p>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    ...columns,
    {
      Header: '',
      accessor: 'id',
      Cell: ({ row: { original } }) => (
        <div className='flex items-center px-2'>
          <ZenDropdown
            items={[
              //Commented out for now
              // {
              //   text: 'Edit Rules',
              //   onClick: () => {
              //     setCheckList(original);
              //     setIsTemplateModalOpen(true);
              //   },
              //   colorType: 'default',
              //   icon: <Edit fontSize='small' />,
              // },
              {
                text: 'Delete',
                onClick: () => {
                  setCheckList(original);
                  setIsDeleteModalOpen(true);
                },
                colorType: 'danger',
                icon: <FontAwesomeIcon icon={faTrashCan} />,
              },
            ]}
            icon={
              <FontAwesomeIconWithWrapper
                icon={faEllipsis}
                size='lg'
                className='h-4 w-4 p-1'
                aria-label='action-row'
              />
            }
          />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const checkListDefinitionIds = useMemo(
    () =>
      officeCheckList?.data
        ?.filter((checkList) => !!checkList?.checklistDefinitionId)
        .map((checklist) => checklist?.checklistDefinitionId!),
    [officeCheckList],
  );

  useEffect(() => {
    const checkListDefinitionIdsToFetch = uniq(checkListDefinitionIds)?.filter(
      (id) => !checklistDefinitionById[id],
    );

    const fetchChecklistDefinitionForIds = async () => {
      if (checkListDefinitionIdsToFetch.length > 0) {
        setLoading(true);
        await Promise.all(
          checkListDefinitionIdsToFetch?.map((id) =>
            dispatch(fetchChecklistDefinition(id)),
          ),
        );
        setLoading(false);
      }
    };

    fetchChecklistDefinitionForIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkListDefinitionIds, dispatch]);

  return (
    <div className='mb-20 border border-zen-dark-4 rounded-xl overflow-hidden'>
      <div className='flex flex-col md:flex-row items-start md:items-center justify-between bg-grey-100 px-2.5 py-3'>
        <p
          className={cn(
            'font-medium',
            isGeminiEnabled
              ? 'font-inter text-[16px] leading-[22px] text-primary-dark text-center'
              : 'font-zen-title text-xl text-black',
          )}
        >
          Checklists/Road to Success
        </p>
        <div className='flex flex-row items-center'>
          <ZenButton
            label='Set Defaults'
            type='button'
            variant='danger-link'
            LeftIconComponent={
              <FontAwesomeIcon icon={faPlus} className='text-base mr-0.5' />
            }
            onClick={() => setIsSetDefaults(true)}
          />
          <ZenButton
            label='Add Template'
            type='button'
            variant='primary-link'
            LeftIconComponent={
              <FontAwesomeIcon icon={faPlus} className='text-base mr-0.5' />
            }
            onClick={() => setIsTemplateModalOpen(true)}
          />
        </div>
      </div>
      <ResourceContainer
        isEmpty={false}
        loading={officeCheckList?.loading || loading}
        resourceName='Template'
      >
        <FixedDataTable<ChecklistDefinitionDto>
          columns={columnsWithActions}
          resourceName='Template'
          data={officeCheckList?.data || []}
          hideFilters
          hidePageSize
          hidePagination
          customEmptyComponent={
            <div className='flex justify-center items-center'>
              <div className='flex flex-col items-center gap-y-2'>
                <EmptyState className='w-full h-32' />
                <p className='font-zen-body font-semibold text-sm text-zen-dark-7'>
                  Add template(s) to this office.
                </p>
                <ZenButton
                  label='Add Template'
                  type='button'
                  variant='primary-link'
                  LeftIconComponent={
                    <FontAwesomeIcon
                      size='lg'
                      icon={faPlus}
                      className='mx-1.5 mt-0.5'
                    />
                  }
                  onClick={() => setIsTemplateModalOpen(true)}
                />
              </div>
            </div>
          }
        />
      </ResourceContainer>

      {isTemplateModalOpen && (
        <AddOfficeTemplate
          isOpen={isTemplateModalOpen}
          onClose={() => {
            setIsTemplateModalOpen(false);
            setCheckList(undefined);
          }}
          officeId={officeId}
          checkList={checkList}
        />
      )}
      {isSetDefaults && (
        <OfficeSetDefaultTemplatesSidebarModal
          isOpen={isSetDefaults}
          onClose={() => setIsSetDefaults(false)}
          office={officeDetailById[officeId]!}
        />
      )}
      <ZenConfirmationModal
        variant='danger'
        size='large'
        title={`Delete ${
          checklistDefinitionById[checkList?.checklistDefinitionId!]?.data?.name
        } template?`}
        subtitle='Are you sure you would like to remove this template? This action will delete the template permanently'
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setCheckList(undefined);
        }}
        onConfirm={async () => {
          setIsDeletingOfficeChecklist(true);
          await dispatch(
            deleteOfficeChecklistDefinition(officeId, checkList?.id!),
          );
          setIsDeletingOfficeChecklist(false);
          setIsDeleteModalOpen(false);
          setCheckList(undefined);
        }}
        isSubmitting={isDeletingOfficeChecklist}
      />
    </div>
  );
};

export default OfficeTemplates;
