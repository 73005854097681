import classNames from 'classnames';
import { createRef } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { faCamera } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { isImageValid } from '../../../utils/AgentHelper';
import Hover from '../../Hover';
import ZenFormErrorMessage from './ZenFormErrorMessage';

interface ZenControlledImageUploadProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  infoToolTipText?: string;
  uploadText?: string;
  isRequired?: boolean;
}

const ZenControlledImageUpload = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  infoToolTipText,
  uploadText = 'Upload Photo',
  shouldUnregister = true,
  isRequired = false,
  ...rest
}: ZenControlledImageUploadProps<TFieldValues, TName>) => {
  const ref = createRef<HTMLInputElement>();
  const dispatch = useDispatch();

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, onChange, value },
        fieldState: { error, invalid },
      }) => (
        <div>
          <div className='space-y-1'>
            <div className='flex flex-row items-center justify-between'>
              {!!label && (
                <label
                  className={classNames(
                    'flex flex-row items-center font-zen-body font-semibold',
                    invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                  )}
                  htmlFor={name}
                >
                  {label}
                  {!!infoToolTipText && (
                    <Hover
                      hoverComponent={
                        <div className='font-zen-body font-normal text-sm text-zen-dark-7 px-2'>
                          {infoToolTipText}
                        </div>
                      }
                      config={{ trigger: 'hover', placement: 'top' }}
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        className='text-primary-blue text-sm mx-1 cursor-pointer'
                        aria-label='info-icon'
                      />
                    </Hover>
                  )}
                  {isRequired && (
                    <span className='text-zen-danger text-xs'>*</span>
                  )}
                </label>
              )}
              <div
                onClick={() => ref.current?.click()}
                className='flex flex-row items-center text-primary-blue cursor-pointer space-x-1'
              >
                <FontAwesomeIcon
                  icon={faCamera}
                  className='text-primary-blue'
                  size='lg'
                />
                <span className='font-primary-medium text-base'>
                  {uploadText}
                </span>
              </div>
            </div>
            <div className='flex flex-col space-y-4'>
              {value && (
                <div className='border border-dashed p-2'>
                  <img
                    className='object-cover w-1/2 h-auto mx-auto'
                    src={
                      typeof value === 'object'
                        ? URL.createObjectURL(value)
                        : value
                    }
                    alt='avatar'
                  />
                </div>
              )}
            </div>
            <input
              type='file'
              className='hidden'
              ref={ref}
              aria-label='uploadAvatar'
              onChange={(e) => {
                if (isImageValid(e)) {
                  onChange(e.target!.files![0]);
                } else {
                  dispatch(
                    showErrorToast('File size exceeds maximum limit of 10 MB.'),
                  );
                }
              }}
            />
          </div>
          {error?.message && <ZenFormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenControlledImageUpload;
