import React from 'react';
import { useSelector } from 'react-redux';
import RealLogoWhite from '../assets/img/real_logo_white.png';
import MaintenanceImg from '../assets/img/Maintenance.png';
import LogoR from '../assets/img/logo_R.png';
import LogoL from '../assets/img/logo_L.png';
import { RootState } from '../types';

interface MaintenanceRouteProps {}

const MaintenanceRoute: React.FC<MaintenanceRouteProps> = () => {
  const {
    maintenanceDetail: { maintenance },
  } = useSelector((state: RootState) => state);

  return (
    <div className='bg-gradient-to-b from-primary-blue to-primary min-h-screen md:relative'>
      <div className='py-12 flex justify-center md:justify-start px-2 md:px-12'>
        <img src={RealLogoWhite} alt='Logo' />
      </div>
      <div className='md:absolute top-0 right-0 left-0 h-full'>
        <div className='flex items-center justify-center h-full'>
          <div className='bg-white md:max-w-4xl mx-2 md:mx-10 lg:mx-auto p-5 md:p-10 flex border border-zen-dark-5 rounded-tr-lg rounded-bl-lg mb-12'>
            <div>
              <div className='md:h-16 md:w-10'>
                <img src={LogoR} alt='Logo_R' />
              </div>
            </div>
            <div className='md:flex'>
              <div className='mt-9 mr-4 lg:mr-16'>
                <div className='md:h-64 md:w-72'>
                  <img src={MaintenanceImg} alt='Maintenance' />
                </div>
              </div>
              <div className='mt-12 max-w-sm'>
                <p className='font-zen-title text-4xl'>
                  We’ll be back shortly.
                </p>
                <p className='font-zen-body text-lg mt-7'>
                  {maintenance.message}
                </p>
                <p className='font-zen-body text-lg mt-7 mb-20'>
                  Rezen (Bolt), OneReal, JoinReal, and the Real App won’t be
                  available during this time.
                </p>
              </div>
            </div>
            <div className='flex'>
              <div className='self-end'>
                <div className='md:h-16 md:w-10'>
                  <img src={LogoL} alt='Logo_L' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceRoute;
