import { useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ZenResourceLinkCard from '../../../ZenResourceLinkCard';
import ZenCancelCapDefermentSidebarModal from './sidebarForms/ZenCancelCapDefermentSidebarModal';

interface ZenCancelCapDefermentProps {}

const ZenCancelCapDeferment: React.FC<ZenCancelCapDefermentProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div>
      <ZenResourceLinkCard
        title='Cancel Cap Deferment'
        onClick={() => setIsOpen(true)}
        icon={solid('xmark')}
        iconSize='2x'
      />
      <ZenCancelCapDefermentSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenCancelCapDeferment;
