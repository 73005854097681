import {
  Controller,
  FieldValues,
  UseControllerProps,
  FieldPath,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps } from '../types';
import { cn } from '../utils/classUtils';
import FormErrorMessage from './FormErrorMessage';

interface ControlledTextAreaInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  subLabel?: string;
  placeholder?: string;
  rows?: number;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  noScroll?: boolean;
  noResize?: boolean;
  borderPrimary?: boolean;
  border?: boolean;
  className?: string;
}

const ControlledTextAreaInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  placeholder,
  label,
  subLabel,
  startAdornment,
  endAdornment,
  noScroll = false,
  noResize = false,
  borderPrimary = false,
  border = true,
  rows = 1,
  className,
  shouldUnregister = true,
  ...rest
}: ControlledTextAreaInputV7Props<TFieldValues, TName>) => {
  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              <span>{label}</span>
              {!!subLabel && (
                <span className='font-primary-regular text-sm text-gray-400 ml-1'>
                  {subLabel}
                </span>
              )}
            </label>
          )}
          <div
            className={cn(
              'flex rounded bg-white',
              border &&
                (borderPrimary
                  ? 'border border-primary'
                  : 'border border-gray-200'),
            )}
          >
            {startAdornment && <div>{startAdornment}</div>}
            <textarea
              id={name}
              ref={ref}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              placeholder={placeholder}
              rows={rows}
              className={cn(
                'appearance-none p-1.5 focus:outline-none focus:ring-0 w-full border-none',
                { 'overflow-hidden': noScroll },
                { 'resize-none': noResize },
                className,
              )}
            />
            {endAdornment && <div>{endAdornment}</div>}
          </div>
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledTextAreaInputV7;
