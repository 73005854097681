import React, { useContext } from 'react';
import { ReactComponent as LockKeySvg } from '../../../../assets/img/roar/lock-key.svg';
import { ZenCommentSectionContext } from '../ZenCommentSectionLayout';

type VoiceCallDisclaimerProps = {};

const VoiceCallDisclaimer: React.FC<VoiceCallDisclaimerProps> = () => {
  const { showVoiceCallDisclaimer } = useContext(ZenCommentSectionContext);

  return (
    <div className='flex-1 pt-3 pb-5 px-4 bg-seaglass border-t border-regent-300 rounded-t-xl flex items-start space-x-1 md:space-x-2.5'>
      <LockKeySvg width={35} height={40} />
      <p className='flex-1 font-inter text-primary-dark font-normal text-sm'>
        Only you, your broker team, and company-authorized personnel will have
        access to the contents of the call.{' '}
        <span
          className='cursor-pointer underline text-rezen-blue-600 font-medium'
          onClick={showVoiceCallDisclaimer}
        >
          Learn More
        </span>
        .
      </p>
    </div>
  );
};

export default VoiceCallDisclaimer;
