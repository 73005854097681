export interface SlimDataRowProps {
  name: string;
  value?: any;
}

const SlimDataRow: React.FC<SlimDataRowProps> = ({ name, value }) => {
  return (
    <div className='text-xs font-thin text-gray-600 space-y-1 tracking-wider print:tracking-normal'>
      <div className='flex flex-row space-x-1'>
        <p>{name}</p>
        {value && <p className='break-all'>{value}</p>}
      </div>
    </div>
  );
};

export default SlimDataRow;
