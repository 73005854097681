import { drop, values } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Control,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form-v7';
import {
  AddressCountryEnum,
  AttachedFeeRequestRecipientTypeEnum,
  AttachedFeeValueFeeTypeEnum,
} from '../../../../../openapi/arrakis';
import { RootState } from '../../../../../types';
import {
  MoneyValue,
  MoneyValueCurrencyEnum,
} from '../../../../../openapi/yenta';
import { getParticipantName } from '../../../../../utils/ParticipantHelper';
import { capitalizeEnum } from '../../../../../utils/StringUtils';
import { participantRoleDisplayName } from '../../../../../utils/TransactionHelper';
import { MONEY_VALUE_VALIDATIONS } from '../../../../../utils/Validations';
import ZenControlledFormattedMoneyInput from '../../../Input/ZenControlledFormattedMoneyInput';
import ZenControlledHTMLSelectInput from '../../../Input/ZenControlledHTMLSelectInput';
import ZenControlledTextAreaInput from '../../../Input/ZenControlledTextAreaInput';
import ZenButton from '../../../ZenButton';
import ZenSidebarModal from '../../../ZenSidebarModal';
import { CreateTransactionFormState } from '../ZenCreateTransactionSteps';
import { getAttachedFeeTypes } from '../../../../../utils/TransactionUtils';

interface ZenAddAdditionalFeeAndRebatesProps {
  isOpen: boolean;
  onClose(): void;
  control: Control<CreateTransactionFormState, object>;
  watch: UseFormWatch<CreateTransactionFormState>;
  setValue: UseFormSetValue<CreateTransactionFormState>;
  trigger: UseFormTrigger<CreateTransactionFormState>;
}

const ZenAddAdditionalFeeAndRebates: React.FC<ZenAddAdditionalFeeAndRebatesProps> = ({
  control,
  watch,
  isOpen,
  onClose,
  setValue,
  trigger,
}) => {
  const [country, feeType, participants] = watch([
    'address.country',
    'additionalFeesAndRebate.feeType',
    'commissionParticipant',
  ]);
  const {
    office: { officeDetailById },
    transactionBuilder: { transactionBuilder },
  } = useSelector((state: RootState) => state);
  const officeDetail =
    officeDetailById[transactionBuilder?.agentsInfo?.officeId!];
  const [participantLabel, setParticipantLabel] = useState('Participant');
  const [descriptionLabel, setDescriptionLabel] = useState('Description');

  useEffect(() => {
    setDescriptionLabel('Description');
    if (
      feeType ===
      ((AttachedFeeValueFeeTypeEnum.AdditionalCommission as unknown) as AttachedFeeValueFeeTypeEnum)
    ) {
      setParticipantLabel('Who is receiving this amount?');
    }
    if (
      feeType ===
      ((AttachedFeeValueFeeTypeEnum.Mls as unknown) as AttachedFeeValueFeeTypeEnum)
    ) {
      setParticipantLabel('Who is paying this fee?');
    }
    if (
      feeType ===
      ((AttachedFeeValueFeeTypeEnum.Reimbursement as unknown) as AttachedFeeValueFeeTypeEnum)
    ) {
      setParticipantLabel('Who is getting reimbursed?');
    }
    if (
      feeType ===
      ((AttachedFeeValueFeeTypeEnum.TransactionCoordinator as unknown) as AttachedFeeValueFeeTypeEnum)
    ) {
      setParticipantLabel('Who is paying this fee?');
      setDescriptionLabel('Transaction Coordinator Name');
    }
    if (
      feeType ===
      ((AttachedFeeValueFeeTypeEnum.Rebate as unknown) as AttachedFeeValueFeeTypeEnum)
    ) {
      setParticipantLabel('Who is offering the rebate?');
    }
    if (
      feeType ===
      ((AttachedFeeValueFeeTypeEnum.OneRealImpactFund as unknown) as AttachedFeeValueFeeTypeEnum)
    ) {
      setParticipantLabel('Agent’s fund towards One Real Impact');
    }
  }, [feeType, setValue]);

  const onAdditionalFeeSave = async () => {
    const isValid = await trigger();
    if (isValid) {
      const additionalFeesAndRebate = watch('additionalFeesAndRebate');
      const additionalFeesAndRebates = watch('additionalFeesAndRebates');
      const fullName = additionalFeesAndRebate.id?.split(' ')!;
      const participantName = drop(fullName).join(' ');
      const finalData = {
        ...additionalFeesAndRebate,
        amount: {
          amount: +additionalFeesAndRebate.amount?.amount!,
          currency:
            country === AddressCountryEnum.UnitedStates
              ? MoneyValueCurrencyEnum.Usd
              : MoneyValueCurrencyEnum.Cad,
        } as MoneyValue,
        id: fullName[0],
        name: participantName,
      };
      additionalFeesAndRebates.push(finalData);
      setValue('additionalFeesAndRebates', additionalFeesAndRebates);
      setValue('additionalFeesAndRebate', {});
      onClose();
    }
  };

  useEffect(() => {
    if (feeType) {
      setValue('additionalFeesAndRebate.description', '');
    }
  }, [feeType, setValue]);

  return (
    <ZenSidebarModal
      title='Add Additional Fee / Rebate'
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='flex flex-col justify-between min-h-full'>
        <div className='p-4'>
          <div>
            <ZenControlledHTMLSelectInput<
              CreateTransactionFormState,
              'additionalFeesAndRebate.feeType'
            >
              control={control}
              label='Fee Type'
              name='additionalFeesAndRebate.feeType'
              placeholder='Fee Type'
              rules={{ required: 'Fee Type is required' }}
              options={[
                { label: 'Fee Type', value: '' },
                ...values(getAttachedFeeTypes(officeDetail)).map((c) => ({
                  value: c,
                  label: capitalizeEnum(c),
                })),
              ]}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledHTMLSelectInput<
              CreateTransactionFormState,
              'additionalFeesAndRebate.id'
            >
              control={control}
              name='additionalFeesAndRebate.id'
              placeholder='Search Member'
              label={participantLabel}
              options={[
                { label: 'Choose Participant', value: '' },
                ...participants!.map((resp) => ({
                  value: `${resp.id} ${getParticipantName(resp)}`,
                  label: `${getParticipantName(
                    resp,
                  )} - (${participantRoleDisplayName(
                    resp.participantRole || '',
                  )})`,
                })),
              ]}
              rules={{
                required: 'Participant is required',
              }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledFormattedMoneyInput<
              CreateTransactionFormState,
              'additionalFeesAndRebate.amount'
            >
              control={control}
              label='Enter Amount'
              name='additionalFeesAndRebate.amount'
              placeholder='2000'
              rules={MONEY_VALUE_VALIDATIONS}
              defaultValue={{
                currency:
                  country === AddressCountryEnum.UnitedStates
                    ? MoneyValueCurrencyEnum.Usd
                    : MoneyValueCurrencyEnum.Cad,
              }}
              isRequired
              currencyReadOnly
            />
          </div>
          {feeType === AttachedFeeValueFeeTypeEnum.Rebate && (
            <div className='mt-5'>
              <ZenControlledHTMLSelectInput<
                CreateTransactionFormState,
                'additionalFeesAndRebate.recipientType'
              >
                control={control}
                label='Recipient Type'
                name='additionalFeesAndRebate.recipientType'
                placeholder='recipient type'
                rules={{
                  required: 'Please select recipient type',
                }}
                options={[
                  {
                    label: 'Select recipient type',
                    value: '',
                  },
                  ...values(AttachedFeeRequestRecipientTypeEnum).map(
                    (type) => ({
                      label: capitalizeEnum(type),
                      value: type,
                    }),
                  ),
                ]}
                onChangeSpy={(event) => {
                  if (event?.target?.value) {
                    setValue(
                      'additionalFeesAndRebate.description',
                      `Recipient : ${capitalizeEnum(event?.target?.value)}`,
                    );
                  } else {
                    setValue('additionalFeesAndRebate.description', '');
                  }
                }}
                isRequired
              />
            </div>
          )}
          <div className='mt-5'>
            <ZenControlledTextAreaInput<
              CreateTransactionFormState,
              'additionalFeesAndRebate.description'
            >
              name='additionalFeesAndRebate.description'
              control={control}
              label={descriptionLabel}
              placeholder={descriptionLabel}
              rules={{
                required: 'Description is required',
              }}
              rows={3}
              isRequired
            />
          </div>
        </div>
      </div>
      <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2 w-full'>
        <ZenButton
          variant='secondary-outline'
          onClick={onClose}
          label='Cancel'
          isFullWidth
        />
        <ZenButton label='Save' onClick={onAdditionalFeeSave} isFullWidth />
      </div>
    </ZenSidebarModal>
  );
};

export default ZenAddAdditionalFeeAndRebates;
