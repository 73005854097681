import React from 'react';
import { useSelector } from 'react-redux';
import ZenSidebarModal from '../../../ZenSidebarModal';
import { RootState } from '../../../../../types';
import { getTransactionRealTitleName } from '../../../../../utils/RealTitleUtils';

interface AddRealParticipantProps {
  isOpen: boolean;
  onClose(): void;
}

const RealTitleInformation: React.FC<AddRealParticipantProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    transactionBuilder: { transactionBuilder },
  } = useSelector((state: RootState) => state);

  return (
    <ZenSidebarModal
      title={getTransactionRealTitleName(transactionBuilder?.address?.state)}
      subtitle='Tech-driven multi-state title company'
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='px-4 font-zen-body text-base text-zen-dark-9'>
        <div className='flex justify-between items-center mt-6'>
          <p className='font-semibold mb-3'>
            What is{' '}
            {getTransactionRealTitleName(transactionBuilder?.address?.state)}?
          </p>
        </div>
        <p className='font-normal mb-6'>
          {getTransactionRealTitleName(transactionBuilder?.address?.state)} is
          the tech-driven title company that has built technology to save our
          in-house agents valuable time in the closing. You will experience the
          technology, which aims on bringing more transparency, communication
          and accountability between agents, brokers, buyers and sellers.
        </p>
        <p className='font-normal mb-6'>
          With our help agents can concentrate on enhancing their client-agent
          experience and minimize time spent on transaction management.
        </p>
      </div>
    </ZenSidebarModal>
  );
};

export default RealTitleInformation;
