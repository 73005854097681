import React from 'react';
import ZenSimpleModal from './Zen/Modal/ZenSimpleModal';

interface EmailBannedPopupModalProps {
  onClose: () => void;
}

const EmailBannedPopupModal: React.FC<EmailBannedPopupModalProps> = ({
  onClose,
}) => {
  return (
    <ZenSimpleModal
      size='large'
      title='Email Banned'
      isOpen
      onClose={() => onClose()}
    >
      <div className='p-5 font-zen-body text-zen-dark-9 text-base font-normal'>
        <div className='mb-2'>
          We regret to inform you that we are unable to send any emails to your
          provided email address. This is because your email provider has either
          bounced our messages or marked them as spam.
        </div>
        <div className='mb-2'>
          To resolve this issue, we kindly request you to visit your email
          provider&apos;s settings and add our email addresses:{' '}
          <b>&ldquo;emails@emailrealbrokerage.com&ldquo;</b>,{' '}
          <b>&ldquo;emails@joinreal.com&ldquo;</b> to your allowlist. If you are
          uncertain about the steps involved in adding{' '}
          <b>&ldquo;emails@emailrealbrokerage.com&ldquo;</b>,{' '}
          <b>&ldquo;emails@joinreal.com&ldquo;</b> to your allowlist, our
          support team would be delighted to assist you. Please don&apos;t
          hesitate to reach out to them for guidance.
        </div>
        <div>
          Once you have completed this step, please contact our support team to
          reinstate your email forwarding service. Alternatively, if you prefer,
          you can switch to a new email address. This will not require you to
          reach out to support.
        </div>
      </div>
    </ZenSimpleModal>
  );
};

export default EmailBannedPopupModal;
