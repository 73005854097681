import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSend } from '@fortawesome/pro-light-svg-icons';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import GeminiButton from '../../components/Gemini/GeminiButton';
import { StepByStepComponent } from '../../components/StepByStep/StepByStepContainer';
import SignatureWidget from '../../components/SignatureWidget/SignatureWidget';
import { SignatureWidgetModeEnum } from '../../types';
import { ReferralStepsFormState } from './ReferralUtils';
import { ApplyToReferralStepName } from './ApplyToReferralSteps';
import InputInfoBanner from './InfoBanner';
import { SendReferralFormState, SendReferralStepName } from './SendReferral';

const SendAgreementStep: StepByStepComponent<
  ReferralStepsFormState | SendReferralFormState,
  ApplyToReferralStepName | SendReferralStepName
> = ({ onPrevious, onNext, onClose }) => {
  return (
    <div className='h-fit'>
      <div className='font-inter border border-gray-200 px-3 mb-8 py-2 rounded-lg m-4'>
        <div className='flex flex-row justify-between items-center py-4'>
          <h4 className='font-medium text-base font-inter'>Document Preview</h4>
          <div className='flex flex-row items-center space-x-1'>
            <FontAwesomeIcon
              icon={faEye}
              className='text-rezen-blue-600 text-sm'
            />
            <p className='text-rezen-blue-600 font-inter font-normal text-sm'>
              Preview
            </p>
          </div>
        </div>
        <div
          className='w-full flex flex-col bg-grey-100 h-[550px] overflow-y-hidden rounded-b-2xl'
          aria-label='sign-ica'
        >
          <SignatureWidget
            mode={SignatureWidgetModeEnum.SIGN}
            token='9442b5749475c9c4994b4b525f8ff0eaac99a6087efffeff'
            isLoading={false}
          />
        </div>

        <InputInfoBanner message='Upon clicking send document this agreement will be sent to you, the receiving agent, the Originating Managing Broker, and the Receiving Managing Broker for signature' />
      </div>
      <div className='sticky w-full bottom-0 right-0 left-0 bg-primary-light border-t'>
        <div className='flex flex-row items-center justify-between'>
          <GeminiButton
            type='button'
            label='Cancel'
            variant='primary-link'
            onClick={onClose}
          />
          <div className='flex flex-row items-center justify-between p-4 space-x-4 '>
            <div className='flex flex-row w-40'>
              <GeminiButton
                type='button'
                label='Back'
                variant='primary-outline'
                onClick={onPrevious}
                leftIcon={faArrowLeft}
                isFullWidth
              />
            </div>

            <div className='flex flex-row w-48'>
              <GeminiButton
                type='button'
                label='Send Document'
                variant='primary'
                isFullWidth
                onClick={onNext}
                rightIcon={faSend}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendAgreementStep;
