import React, { FormEventHandler } from 'react';
import SidebarModal from './SidebarModal';
import SidebarModalActionFooter from './SidebarModal/SideBarModalActionFooter';

export interface SidebarModalFormProps {
  title: string;
  subtitle?: string;
  isOpen: boolean;
  actionTitle: string;
  hideFooterActions?: boolean;
  isSubmitting: boolean;
  onClose(): void;
  onSubmit: FormEventHandler;
}

const SidebarModalForm: React.FC<SidebarModalFormProps> = ({
  title,
  subtitle,
  isOpen,
  onClose,
  hideFooterActions: hideFooterAction = false,
  children,
  actionTitle,
  isSubmitting,
  onSubmit,
}) => {
  return (
    <SidebarModal
      title={title}
      subtitle={subtitle}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={onSubmit}
      >
        <div className='p-4 flex-auto mb-20'>{children}</div>
        {!hideFooterAction && (
          <SidebarModalActionFooter
            onClose={onClose}
            isSubmitting={isSubmitting}
            submitButtonText={actionTitle}
          />
        )}
      </form>
    </SidebarModal>
  );
};

export default SidebarModalForm;
