import RealTitleLogo from '../assets/img/zen/real-title/real-title-logo.svg';
import PageLayout from '../components/PageLayout';
import RealTitleAndEscrowFAQsSection from '../components/RealTitle/RealTitleAndEscrowFAQsSection';
import RealTitleLearnMoreSection from '../components/RealTitle/RealTitleLearnMoreSection';
import RealTitleOperatesSection from '../components/RealTitle/RealTitleOperatesSection';
import RealTitleSendTransactionSection from '../components/RealTitle/RealTitleSendTransactionSection';
import SocialMediaLinks from '../components/Zen/SocialMediaLinks';
import ZenRoute from '../components/Zen/ZenRoute';
import { getSocialMediaLinks } from '../utils/MarketingCenterUtils';

interface RealTitleRouteProps {}

const RealTitleRoute: React.FC<RealTitleRouteProps> = () => {
  return (
    <ZenRoute title='Real Title'>
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Real Title', url: '/real-title' },
        ]}
      >
        <div className='md:p-8 p-4'>
          <div className='flex md:flex-row flex-col md:items-center items-start justify-between md:space-y-0 space-y-5'>
            <img
              src={RealTitleLogo}
              alt='real-title-logo'
              className='block md:h-6 h-4 w-auto'
            />
            <SocialMediaLinks
              label='Connect with us'
              socialLinks={getSocialMediaLinks()}
            />
          </div>
          <RealTitleOperatesSection />
          <RealTitleLearnMoreSection />
          <RealTitleSendTransactionSection />
          <RealTitleAndEscrowFAQsSection />
        </div>
      </PageLayout>
    </ZenRoute>
  );
};

export default RealTitleRoute;
