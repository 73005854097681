import React from 'react';
import HomeIcon from '../../assets/img/home_icon.png';
import { AgentResponse } from '../../openapi/yenta';
import { agentsTableFetchData } from '../../utils/TableUtils';
import OfficeAgentsTable from '../office/OfficeAgentsTable';
import ZenListPaginateContainer from './Containers/ZenListPaginateContainer';
import ZenInformationCard from './ZenInformationCard';

interface ZenAgentListContainerProps {
  officeId?: string[];
  mlsId?: string[];
  boardId?: string[];
  setAgents?: React.Dispatch<React.SetStateAction<AgentResponse[]>>;
}

const ZenAgentListContainer: React.FC<ZenAgentListContainerProps> = ({
  officeId,
  mlsId,
  boardId,
  setAgents,
}) => {
  return (
    <ZenInformationCard title='Agents' titleIcon={HomeIcon}>
      <div className='md:p-6 py-6'>
        <ZenListPaginateContainer<AgentResponse>
          fetchData={async (req) => {
            const { data, total } = await agentsTableFetchData(
              req,
              officeId,
              mlsId,
              boardId,
            );
            setAgents?.(data);
            return { data, total };
          }}
          resourceName='agent'
          searchPlaceholder='Search by name or postal code'
        >
          {(agents) => {
            return <OfficeAgentsTable agents={agents} />;
          }}
        </ZenListPaginateContainer>
      </div>
    </ZenInformationCard>
  );
};

export default ZenAgentListContainer;
