import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import EmptyTray from '../../assets/img/EmptyTray.png';
import {
  MoneyValueCurrencyEnum,
  TaxDocumentLineItemResponse,
  TaxDocumentLineItemResponseSourceEnum,
  TaxDocumentLineItemResponseTypeEnum,
} from '../../openapi/arrakis';
import { getYearlyTaxDocumentDetails } from '../../slices/TaxSlice';
import { RootState } from '../../types';
import { displayAmount, zeroMoneyValue } from '../../utils/CurrencyUtils';
import AuthorizationContainer from '../auth/AuthorizationContainer';
import ZenRoute from '../Zen/ZenRoute';
import ZenResourceContainer from '../Zen/ZenResourceContainer';
import ZenTabs from '../Zen/ZenTabs';
import ZenFixedDataTable from '../Zen/Table/ZenFixedDataTable';
import ZenDateCell from '../Zen/Table/Cell/ZenDateCell';
import ZenMilliDateCell from '../Zen/Table/Cell/ZenMilliDateCell';
import { capitalizeEnum } from '../../utils/StringUtils';

export const netCommissionColumns: Array<
  Column<TaxDocumentLineItemResponse>
> = [
  {
    Header: 'Transaction',
    accessor: 'transactionAddress',
    Cell: ({ value, row: { original } }) => (
      <Link to={`/transactions/${original.transactionId}`}>
        <p>{value?.oneLine}</p>
      </Link>
    ),
    cardColSize: 7,
  },
  {
    Header: 'Closed Date',
    accessor: 'transactionRezenClosedAt',
    Cell: ({ value }) => <ZenMilliDateCell date={value} />,
    disableFilters: true,
  },
];

export const netCommissionCreditColumns: Array<
  Column<TaxDocumentLineItemResponse>
> = [
  {
    Header: 'Credit Type',
    accessor: 'agentCreditType',
    Cell: ({ value }) => <p>{value ? capitalizeEnum(value) : 'N/A'}</p>,
    cardColSize: 7,
  },
  {
    Header: 'Issuer Title',
    accessor: 'agentCreditTitle',
    Cell: ({ value }) => <p>{value}</p>,
    disableFilters: true,
  },
  {
    Header: 'Issuer On',
    accessor: 'agentCreditIssuedOn',
    Cell: ({ value }) => <ZenDateCell date={value} />,
    disableFilters: true,
  },
];

type Match = {
  id: string;
  year: string;
};

const ZenTaxNetCommission: React.FC = () => {
  const {
    taxDoc: { taxDocumentsDetailResponse },
    agentDetail: {
      detailResponse: { data: detail },
    },
  } = useSelector((state: RootState) => state);
  const { id, year } = useParams() as Match;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getYearlyTaxDocumentDetails(id, +year));
  }, [dispatch, id, year]);

  const dynamicNetCommissionColumns: Array<
    Column<TaxDocumentLineItemResponse>
  > = [
    ...netCommissionColumns,
    {
      Header: `Net Commission (${detail?.defaultCurrency})`,
      accessor: 'amount',
      id: 'net',
      Cell: ({ value }) => displayAmount(value, { hideCurrency: true }),
      disableFilters: true,
    },
  ];

  const dynamicNetCommissionCreditColumns: Array<
    Column<TaxDocumentLineItemResponse>
  > = [
    ...netCommissionCreditColumns,
    {
      Header: `Amount (${detail?.defaultCurrency})`,
      accessor: 'amount',
      Cell: ({ value }) => displayAmount(value, { hideCurrency: true }),
      disableFilters: true,
    },
  ];
  const netCommission = taxDocumentsDetailResponse.data?.lineItems?.filter(
    (item) =>
      item.type === TaxDocumentLineItemResponseTypeEnum.NetCommission &&
      item.source !== TaxDocumentLineItemResponseSourceEnum.Credit,
  );
  const netCommissionCredits = taxDocumentsDetailResponse.data?.lineItems?.filter(
    (item) =>
      item.type === TaxDocumentLineItemResponseTypeEnum.NetCommission &&
      item.source === TaxDocumentLineItemResponseSourceEnum.Credit,
  );
  const [activeTab, setActiveTab] = useState('Net Commissions');
  const creditAmount =
    netCommissionCredits
      ?.map((item) => item.amount!.amount!)
      .reduce((prev, curr) => prev + curr, 0) || 0;

  return (
    <ZenRoute title={`${year} Net Commissions & Credits`}>
      <div className='p-4'>
        <div className='space-y-4'>
          <p className='font-primary-medium text-xl'>
            {year} Net Commissions & Credits:{' '}
            {displayAmount(
              taxDocumentsDetailResponse.data?.netCommissionPaid ||
                zeroMoneyValue(detail?.defaultCurrency!),
            )}
          </p>

          <AuthorizationContainer asyncResponse={taxDocumentsDetailResponse}>
            <ZenResourceContainer
              loading={taxDocumentsDetailResponse.loading}
              isEmpty={false}
              resourceName='net commission'
            >
              <ZenTabs
                tabs={[
                  {
                    name: 'Net Commissions',
                    subtitle: `${displayAmount({
                      amount:
                        (taxDocumentsDetailResponse.data?.netCommissionPaid
                          ?.amount || 0) - creditAmount,
                      currency: (detail?.defaultCurrency! as unknown) as MoneyValueCurrencyEnum,
                    })}`,
                    TabComponent: (
                      <div className='pt-4 space-y-2'>
                        <p>Commissions ({netCommission?.length || 0})</p>
                        <ZenFixedDataTable<TaxDocumentLineItemResponse>
                          columns={dynamicNetCommissionColumns}
                          data={netCommission || []}
                          resourceName='Net Commission'
                          hidePagination
                          hideFilters
                          emptyIconComponent={
                            <img
                              src={EmptyTray}
                              alt='EmptyTrayImage'
                              className='h-20'
                            />
                          }
                        />
                      </div>
                    ),
                  },
                  {
                    name: 'Credits',
                    subtitle: `${displayAmount({
                      amount:
                        netCommissionCredits
                          ?.map((item) => item.amount!.amount!)
                          .reduce((prev, curr) => prev + curr, 0) || 0,
                      currency: (detail?.defaultCurrency! as unknown) as MoneyValueCurrencyEnum,
                    })}`,
                    TabComponent: (
                      <div className='pt-4 space-y-2'>
                        <p>Credits ({netCommissionCredits?.length || 0})</p>
                        <ZenFixedDataTable<TaxDocumentLineItemResponse>
                          columns={dynamicNetCommissionCreditColumns}
                          data={netCommissionCredits || []}
                          resourceName='Net Commission Credit'
                          hidePagination
                          hideFilters
                          emptyIconComponent={
                            <img
                              src={EmptyTray}
                              alt='EmptyTrayImage'
                              className='h-20'
                            />
                          }
                        />
                      </div>
                    ),
                  },
                ]}
                selected={activeTab}
                onChange={setActiveTab}
                size='sm'
              />
            </ZenResourceContainer>
          </AuthorizationContainer>
        </div>
      </div>
    </ZenRoute>
  );
};

export default ZenTaxNetCommission;
