import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightToBracket,
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';
import { Alert } from '../components/commons/Alert';
import {
  JwtAuthenticationResponseMfaTypeEnum,
  MfaControllerApi,
} from '../openapi/keymaker';
import ErrorService from '../services/ErrorService';
import { fetchAuthUserDetail, handleLogout } from '../slices/AuthSlice';
import { getAuthCookie, setAuthCookie } from '../utils/AuthUtils';
import { getApiErrorMessage } from '../utils/ErrorUtils';
import { getKeymakerConfiguration } from '../utils/OpenapiConfigurationUtils';
import useQueryParams from '../hooks/useQueryParams';
import { constructLink } from '../utils/UrlUtils';
import { AppDispatch, ErrorCode, RootState } from '../types';
import { Button } from '../components/commons/Button';
import { PinInput } from '../components/commons/PinInput';
import { useBaseMutation } from '../query/base/useBaseMutation';
import { queryKeys } from '../query/base/queryKeys';

interface Query {
  redirectTo?: string;
}

const ZenLoginTwoFactorAuthenticationRoute: React.FC = () => {
  const [code, setCode] = useState('');
  const [invalidCode, setInvalidCode] = useState(false);
  const [serverError, setServerError] = useState<string>();
  const {
    auth: { currentUserJwtResponse },
  } = useSelector((state: RootState) => state);
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const { redirectTo }: Query = useQueryParams<Query>();
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [enableResendCode, setEnableResendCode] = useState<boolean>(false);

  const isSmsTypeMFA =
    currentUserJwtResponse?.mfaType ===
    JwtAuthenticationResponseMfaTypeEnum.Sms;

  const {
    mutateAsync: resendCode,
    isLoading: resendCodeLoading,
  } = useBaseMutation({
    queryKey: queryKeys.login.resendCode.queryKey,
    mutationFn: async () => {
      try {
        setEnableResendCode(true);
        setTimeout(() => {
          setEnableResendCode(false);
        }, 10000);
        await new MfaControllerApi(getKeymakerConfiguration()).sendMfaSms();
      } catch (e) {
        const errorCode = ErrorService.getErrorCode(e);
        if (
          errorCode === ErrorCode.UNAUTHORIZED ||
          errorCode === ErrorCode.FORBIDDEN
        ) {
          dispatch(handleLogout());
        }
        ErrorService.notifyIgnoreAuthErrors(
          'Unable to resend authentication code to phone number during 2fa sign in',
          e,
        );
      }
    },
  });

  const { mutateAsync: submitCode, isLoading } = useBaseMutation({
    queryKey: queryKeys.login.twoFactor.queryKey,
    mutationFn: async (code: string) => {
      try {
        const { data } = await new MfaControllerApi(
          getKeymakerConfiguration(),
        ).signInWithMfa({
          code: +code,
        });
        setAuthCookie(data.accessToken!);
        await dispatch(fetchAuthUserDetail());
      } catch (e) {
        setCode('');
        setInvalidCode(true);
        if (
          e?.response?.data['com.real.commons.apierror.ApiError']?.message ===
          undefined
        ) {
          setServerError('You session has expired. Please log back in.');
          setTimeout(() => {
            history.replace(constructLink('/login', { redirectTo }));
          }, 2000);
        } else {
          setServerError(getApiErrorMessage(e));
        }
        ErrorService.notifyIgnoreAuthErrors(
          'Unable to signing with two factor authentication',
          e,
          {
            data: {
              code,
            },
          },
        );
      }
    },
  });

  useEffect(() => {
    if (!getAuthCookie()) {
      history.push(constructLink('/login', { redirectTo }));
    }
  }, [history, redirectTo]);

  return (
    <form>
      <div className='bg-gray-100'>
        <div className='flex items-center justify-center h-screen mx-auto'>
          <div className='p-10 bg-white border-2 border-gray-100 min-w-1/3'>
            <div className='mb-5 -mt-20 text-center'>
              <div className='inline-block p-10 text-primary-light rounded-full bg-primary-dark relative'>
                <FontAwesomeIcon
                  icon={faArrowRightToBracket}
                  className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-1/3 w-1/3'
                />
              </div>
            </div>
            <p className='text-3xl text-center font-inter'>
              Two-Factor Authentication
            </p>
            {serverError && (
              <div className='mt-3'>
                <Alert
                  title={serverError}
                  color='red'
                  icon={<FontAwesomeIcon icon={faInfoCircle} />}
                />
              </div>
            )}
            <div className='py-5 font-inter text-sm font-light text-center'>
              <p>Two-factor authentication is enabled for your account.</p>
              <p>
                {isSmsTypeMFA
                  ? `Enter the code we texted to (***) *** ${currentUserJwtResponse?.phoneNumber!.slice(
                      -4,
                    )}.`
                  : `Enter the code from the authenticator app on your mobile device.`}
              </p>
            </div>
            <div>
              <PinInput
                length={6}
                onComplete={(code: string) => {
                  // See comment below
                  hiddenInputRef.current?.focus();
                  submitCode(code);
                }}
                value={code}
                onChange={(code: string) => {
                  setCode(code);
                  setInvalidCode(false);
                }}
                oneTimeCode
                disabled={isLoading}
                error={invalidCode}
                data-testid='code-input'
              />
            </div>
            {/**
             * When an incorrect pin is enter, the code is being cleared
             * Because of the component library, the last input still thinks that it has focus and will not display the placeholder (or the cursor)
             * This input is being used to take the focus once a code has been entered so all placeholders will appear
             */}
            <input ref={hiddenInputRef} className='h-0 leading-0 w-0' />
            <div className='mt-1 flex flex-col gap-3 items-center justify-center'>
              {isSmsTypeMFA && enableResendCode ? (
                <p className='text-md font-primary-regular text-dark'>
                  Text message sent! Enter code above
                </p>
              ) : (
                <Button
                  type='button'
                  onClick={resendCode}
                  disabled={resendCodeLoading || isLoading}
                  loading={resendCodeLoading}
                  variant='filled'
                  color='blue'
                  fullWidth
                >
                  Resend Code
                </Button>
              )}
              <Button
                type='button'
                onClick={() =>
                  history.push(constructLink('/login', { redirectTo }))
                }
                fullWidth
                disabled={isLoading}
                variant='outline'
                color='blue'
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ZenLoginTwoFactorAuthenticationRoute;
