import Slider from 'rc-slider';
import CurrencyInput from 'react-currency-input-field';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form-v7';
import { cn } from '../../utils/classUtils';
import ZenFormErrorMessage from './Input/ZenFormErrorMessage';

interface ZenControlledInputSliderProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  autofocus?: boolean;
  min?: number;
  max?: number;
  step?: number;
  label?: string;
  minValueLabel?: string;
  maxValueLabel?: string;
  disabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  startAdornment?: React.ReactElement;
  prefix?: string;
  showSlider?: boolean;
  onChangeSpy?: (
    value: string | number | undefined,
    source: 'input' | 'slider',
  ) => void;
}

const ZenControlledInputSlider = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  autofocus = false,
  label,
  minValueLabel,
  maxValueLabel,
  min = 1,
  max = 200,
  step = 1,
  disabled,
  placeholder,
  isRequired,
  prefix,
  startAdornment,
  showSlider = true,
  onChangeSpy,
  ...rest
}: ZenControlledInputSliderProps<TFieldValues, TName>) => {
  const {
    field: { onChange, value, name },
    fieldState: { error },
  } = useController<TFieldValues, TName>(rest);

  return (
    <div className='space-y-1 w-full'>
      <div className='flex md:flex-row flex-col gap-4 place-content-between'>
        {!!label && (
          <label
            htmlFor={`${name}.input`}
            className='font-zen-body font-semibold text-zen-dark-9'
          >
            {label}
          </label>
        )}
        <div className='space-y-0.5 md:max-w-[220px]'>
          <div
            className={cn(
              'flex items-center border rounded-lg overflow-hidden justify-center',
              value && 'text-zen-dark-9',
            )}
          >
            {startAdornment && (
              <div className='p-1 ml-2 mt-1'>{startAdornment}</div>
            )}
            <CurrencyInput
              id={`${name}.input`}
              name={`${name}.input`}
              value={value}
              onValueChange={(value) => {
                const valueAsNumber = parseInt(value ?? '', 10);
                onChange(isNaN(valueAsNumber) ? null : valueAsNumber);
                onChangeSpy?.(valueAsNumber, 'input');
              }}
              autoFocus={autofocus}
              readOnly={disabled}
              placeholder={placeholder}
              className={cn(
                'appearance-none p-2 border-none rounded-lg w-full focus:outline-none focus:ring-0 font-zen-body font-normal',
                {
                  'bg-gray-50': disabled,
                },
              )}
              allowDecimals={false}
              prefix={prefix}
              allowNegativeValue={false}
            />
          </div>
          {!!error && !showSlider && (
            <ZenFormErrorMessage message={error.message} />
          )}
        </div>
      </div>
      {showSlider && (
        <div className='py-4 px-3'>
          <Slider
            value={value ?? min}
            min={min}
            max={max}
            step={step}
            onChange={(value) => {
              onChange(value);
              onChangeSpy?.(value, 'slider');
            }}
            disabled={disabled}
            dotStyle={{
              backgroundColor: disabled ? '#D6D6D6' : '#3B82F6',
              borderColor: disabled ? '#D6D6D6' : '#3B82F6',
              height: 12,
              width: 12,
            }}
            handleStyle={{
              borderColor: disabled ? '#D6D6D6' : '#3B82F6',
              borderWidth: 8,
              top: 0,
              left: 8,
              height: 25,
              width: 25,
              backgroundColor: '#FFF',
              boxShadow:
                '0px 4px 2px rgba(1, 62, 80, 0.12), 0px 2px 2px rgba(1, 62, 80, 0.24)',
            }}
            trackStyle={{
              backgroundColor: disabled ? '#D6D6D6' : '#3B82F6',
              height: 6,
              borderRadius: 15,
              opacity: 0.8,
            }}
            railStyle={{
              backgroundColor: disabled ? '#D6D6D6' : '#3B82F6',
              opacity: 0.2,
              height: 6,
              borderRadius: 10,
            }}
          />
        </div>
      )}
      {!!error && showSlider && <ZenFormErrorMessage message={error.message} />}
    </div>
  );
};
export default ZenControlledInputSlider;
