import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Column, Row } from 'react-table';
import AuthorizationContainer from '../components/auth/AuthorizationContainer';
import DetailPageLoader from '../components/DetailPageLoader';
import PageTitle from '../components/helmet/PageTitle';
import ZenViewActionButtonCell from '../components/table/Cells/ZenViewActionButtonCell';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenCronJobsStatusCell from '../components/Zen/Table/Cell/ZenCronJobsStatusCell';
import ZenMilliDateTimeCell from '../components/Zen/Table/Cell/ZenMilliDateTimeCell';
import ZenResourceContainer from '../components/Zen/ZenResourceContainer';
import { JobExecutionResponse } from '../openapi/yenta';
import { getLatestCronJobs } from '../slices/CronJobs';
import { RootState } from '../types';

interface ZenCronJobsRouteProps {}

export const columns: Array<Column<JobExecutionResponse>> = [
  {
    Header: 'Name',
    accessor: 'jobExecutionName',
    Cell: ({ value }) => value,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'jobExecutionStatus',
    Cell: ({ value }) => <ZenCronJobsStatusCell status={value!} />,
  },
  {
    Header: 'Log',
    accessor: 'errorLog',
    Cell: ({ value }) => value,
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Notes',
    accessor: 'notes',
    Cell: ({ value }) => value,
    disableSortBy: true,
  },
  {
    Header: 'Started At',
    accessor: 'startedAt',
    Cell: ({ value }) => <ZenMilliDateTimeCell date={value!} />,
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Completed At',
    accessor: 'completedAt',
    Cell: ({ value }) => <ZenMilliDateTimeCell date={value!} />,
    disableSortBy: true,
    cardColSize: 6,
  },
];

export const columnsWithAction: Array<Column<JobExecutionResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<JobExecutionResponse> }) => (
      <Link to={`/jobs/${row.original.jobExecutionName}/history`}>
        <ZenViewActionButtonCell />
      </Link>
    ),
  },
  ...columns,
];

const ZenCronJobsRoute: React.FC<ZenCronJobsRouteProps> = () => {
  const dispatch = useDispatch();
  const fetchData = useCallback(async () => {
    await dispatch(getLatestCronJobs());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    cronJobs: { cronJobsResponse },
  } = useSelector((state: RootState) => state);
  const { loading, data, error } = cronJobsResponse;

  const cronJobs = data?.jobExecutionResponses;

  return (
    <AuthorizationContainer asyncResponse={cronJobsResponse}>
      <PageTitle title='Jobs' />
      <div className='px-4 lg:py-5'>
        <ZenResourceContainer
          loading={loading}
          isEmpty={!cronJobs?.length}
          errorCode={error}
          LoaderComponent={<DetailPageLoader />}
          resourceName='Jobs'
        >
          <ZenResourceIndexContainer
            header='Jobs'
            columns={columnsWithAction}
            resourceName='Job'
            data={cronJobs || []}
            hidePagination
            hideFilters
            initialSort={{ startedAt: 'desc' }}
          />
        </ZenResourceContainer>
      </div>
    </AuthorizationContainer>
  );
};
export default ZenCronJobsRoute;
