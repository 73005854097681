import { values } from 'lodash';
import { useEffect, useState } from 'react';
import { LicenseResponse } from '../../../openapi/yenta';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenTabs from '../../Zen/ZenTabs';
import ZenAgentLicenseDetail from './ZenAgentLicenseDetail';

interface ZenAgentLicensesProps {
  licenses?: Array<LicenseResponse>;
}

const ZenAgentLicenses: React.FC<ZenAgentLicensesProps> = ({ licenses }) => {
  const tabs = values(licenses).map((license: LicenseResponse) => {
    return {
      name: capitalizeEnum(license.administrativeArea?.stateOrProvince!),
      TabComponent: <ZenAgentLicenseDetail license={license} />,
    };
  });

  const tabName = tabs[0]?.name?.toString();
  const [licenseTab, setLicenseTab] = useState(tabName);

  useEffect(() => {
    setLicenseTab(tabName);
  }, [licenses, tabName]);

  return (
    <div>
      {!!tabs.length && (
        <ZenTabs
          tabs={tabs}
          selected={licenseTab}
          onChange={setLicenseTab}
          size='sm'
        />
      )}
    </div>
  );
};

export default ZenAgentLicenses;
