import ConfirmationModal, { ConfirmationModalProps } from './ConfirmationModal';
import Button from './Button';

interface ConfirmDeleteRecordModalProps
  extends Omit<ConfirmationModalProps, 'variant'> {
  onDelete(): void;
}

const ConfirmDeleteRecordModal: React.FC<ConfirmDeleteRecordModalProps> = ({
  title,
  isOpen,
  subtitle,
  onClose,
  onDelete,
}) => {
  return (
    <ConfirmationModal
      variant='error'
      title={title}
      subtitle={subtitle}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='d-flex space-x-4 flex-nowrap mt-4'>
        <Button label='Delete' type='danger' onClick={onDelete} />
        <Button label='Cancel' type='secondary' onClick={onClose} />
      </div>
    </ConfirmationModal>
  );
};

export default ConfirmDeleteRecordModal;
