import { faHandsHoldingDollar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import ZenControlledCurrencyInput from '../../../components/Zen/Input/ZenControlledCurrencyInput';
import ZenButton from '../../../components/Zen/ZenButton';
import { GoalDetails, MoneyValueCurrencyEnum } from '../../../openapi/plutus';
import AnalyticsService from '../../../services/AnalyticsService';
import {
  fetchCurrentWealth,
  fetchDefaultWealthGoals,
  setWealthGoals,
} from '../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum, RootState } from '../../../types';
import { displayFormattedFixedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import {
  getAverageFixedCommissionEndOfDeal,
  getTeamInfo,
} from '../../../utils/WealthPlanUtils';
import ResourceContainer from '../../ResourceContainer';
import { useDebounce } from '../utils';
import { selectAgentCurrency } from '../WealthManagementUtils';
import SetGoalsSideBarV2, { GoalsFormInput } from './SetGoalsSideBarV2';

interface FormInput {
  goal: number;
}

const SetWealthGoalPageV2: React.FC = () => {
  const [showEditSideBar, setShowEditSideBar] = React.useState(false);

  const {
    auth: { userDetail },
    wealthManagement: { currentWealthResponse, setWealthGoalsResponse },
    agentCurrency,
  } = useSelector((state: RootState) => ({
    ...state,
    agentCurrency: selectAgentCurrency(state),
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCurrentWealth());
    dispatch(fetchDefaultWealthGoals());
  }, [dispatch]);

  const {
    control,
    getValues,
    handleSubmit,
    trigger,
    watch,
  } = useForm<FormInput>({
    defaultValues: {
      goal: undefined,
    },
  });

  const goal = watch('goal');

  const onGetStartedClicked = () => {
    setShowEditSideBar(true);
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.WEALTH_PLAN_WELCOME_SCREEN_CLICK_GET_STARTED,
    );
  };

  const handleSubmitGoals = (formData: GoalsFormInput) => {
    const request: GoalDetails = {
      ...getAverageFixedCommissionEndOfDeal(formData, agentCurrency),
      ...getTeamInfo(formData, agentCurrency),
      additionalRealShares: formData.additionalRealShares,
      agentReferredPerYear: formData.agentReferredPerYear,
      agentsAgentReferredPerYear: formData.agentsAgentReferredPerYear,
      averageHomeSalePrice: {
        amount: formData.averageHomeSalePrice,
        currency: agentCurrency,
      },
      earningsGoalValue: {
        amount: getValues('goal'),
        currency: agentCurrency,
      },
      eliteAgentCulturalAwardParticipation: false,
      eliteAgentProductionAwardParticipation: false,
      planId: '',
      planStartDate: new Date().toISOString(),
      projectionYears: 5,
      realStockFuturePrice: {
        amount: formData.realStockFuturePrice,
        currency: MoneyValueCurrencyEnum.Usd,
      },
      realTitleJVParticipation: false,
      realTitleJVShares: 0,
      revShareParticipation:
        formData.agentReferredPerYear > 0 ||
        formData.agentsAgentReferredPerYear > 0,
      sppParticipation: formData.sppParticipation,
      transactionsPerYear: formData.transactionsPerYear,
      volumePerYear: {
        amount: formData.volumePerYear,
        currency: agentCurrency,
      },
    };
    dispatch(setWealthGoals(request));
  };

  useEffect(() => {
    trigger('goal').catch(() => {
      // ignoring error. Error is because goal is undefined and not a number
    });
  }, [goal, trigger]);

  const currentWealth = displayFormattedFixedAmountWithCurrency(
    currentWealthResponse?.data?.wealthValue,
    false,
    0,
  );

  const currentWealthAmount =
    currentWealthResponse.data?.wealthValue.amount ?? 0;

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (!setWealthGoalsResponse.loading || goal !== undefined) {
        handleSubmit(onGetStartedClicked)();
      }
    }
  };

  useDebounce(
    () => {
      if (goal !== undefined) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.WEALTH_PLAN_WELCOME_SCREEN_5_YEAR_GOAL_INPUT,
        );
      }
    },
    {
      dependencies: [goal],
      delay: 1000,
    },
  );

  return (
    <ResourceContainer
      loading={setWealthGoalsResponse.loading || currentWealthResponse.loading}
      isEmpty={false}
      resourceName='Set Wealth Goals'
    >
      <div className='w-full h-full flex justify-center items-center'>
        <div className='relative border-2 border-gray-200 p-5 max-w-md items-center flex flex-col '>
          <div className='absolute top-[-40px] left-1/2 transform -translate-x-1/2  h-20 w-20 rounded-full flex flex-row items-center justify-center bg-primary bg-gradient-to-r from-blue-500 to-bg-primary border-4 border-slate-200'>
            <FontAwesomeIcon
              icon={faHandsHoldingDollar}
              size='xl'
              className='text-white'
            />
          </div>
          <div className='text-coolGray-500 mt-12'>Welcome</div>
          <div className=' text-lg'>{userDetail!.firstName}!</div>
          <div className='text-coolGray-500 mt-12 w-3/4 mb-6 text-center'>
            The Real WealthPlan is designed to help you forecast and grow your
            wealth, and is comprised of the many ways you can earn income as a
            Real agent.
          </div>
          <div className='border-2 border-gray-100 rounded-lg px-4 py-2 w-3/4 flex flex-col items-center mb-8'>
            <div className='text-coolGray-500'>Real Wealth Earned-to-Date:</div>
            <div className='text-3xl'>{currentWealth}</div>
          </div>
          <div>Set your goal amount</div>
          <div className='text-coolGray-500'>Real Wealth Goal in 5 Years:</div>
          <div className='w-3/4 mt-3 mb-12'>
            <ZenControlledCurrencyInput<FormInput, 'goal'>
              name='goal'
              control={control}
              placeholder='Enter Amount'
              rules={{
                required: 'Please set a goal amount.',
                min: {
                  value: currentWealthAmount,
                  message: 'Goal amount must be greater than current wealth.',
                },
              }}
              defaultValue={undefined}
              startAdornment={
                <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
                  $
                </div>
              }
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className='w-3/4 mb-6'>
            <ZenButton
              label='Get Started'
              isFullWidth
              onClick={handleSubmit(onGetStartedClicked)}
              isDisabled={setWealthGoalsResponse.loading || goal === undefined}
            />
          </div>
          {showEditSideBar && (
            <SetGoalsSideBarV2
              isOpen
              allowWealthGoalEdit={false}
              onClose={() => setShowEditSideBar(false)}
              onSubmit={handleSubmitGoals}
            />
          )}
        </div>
      </div>
    </ResourceContainer>
  );
};

export default SetWealthGoalPageV2;
