import React, { useCallback, useEffect, useState } from 'react';
import { Control } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import {
  faChevronDown,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TeamControllerApi, TeamMemberResponse } from '../../openapi/yenta';
import { ReportFormData } from '../../routes/ReportsRoute';
import ErrorService from '../../services/ErrorService';
import { RootState } from '../../types';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';
import { getTeamsWithAdminOrLeaderRole } from '../../utils/TeamHelper';
import AvatarLabelComponent from './AvatarLabelComponent';

interface TeamAdminAgentSelectorProps {
  control: Control<ReportFormData, object>;
}

const TeamAdminAgentSelector: React.FC<TeamAdminAgentSelectorProps> = ({
  control,
}) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [agents, setAgents] = useState<TeamMemberResponse[]>([]);

  //TODO: show only unique agents
  const fetchTeamMembers = useCallback(
    async (teams: (string | undefined)[]): Promise<any> => {
      setAgents([]);
      let teamMembers = [];
      for (const teamId of teams) {
        try {
          const {
            data: { agents: teamMemberList },
          } = await new TeamControllerApi(getYentaConfiguration()).getTeamById(
            teamId!,
          );

          teamMembers.push(...(teamMemberList || []));
        } catch (e) {
          ErrorService.notify('Error while fetching team members', e);
        }
      }

      setAgents(teamMembers);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (userDetail) {
      const adminOrLeaderTeams = getTeamsWithAdminOrLeaderRole(userDetail);
      const teamIds = adminOrLeaderTeams.map((team) => team.teamId!);

      fetchTeamMembers(teamIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail, fetchTeamMembers]);

  return (
    <ZenControlledAsyncSelectInput<ReportFormData, 'agent'>
      control={control}
      name='agent'
      placeholder='Search Agent'
      label='Select Agent'
      startAdornment={
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className='text-blue-500 mx-2'
        />
      }
      endAdornment={
        <FontAwesomeIcon icon={faChevronDown} className='text-gray-500 mx-3' />
      }
      selectClassName='border-r'
      fetchData={async (search, page) => {
        // We always show all of the info in one page.
        if (page !== 0) {
          return [];
        }

        const filteredAgents = agents.filter((teamMember) => {
          return (
            teamMember.agent?.firstName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            teamMember.agent?.lastName
              ?.toLowerCase()
              .includes(search.toLowerCase())
          );
        });

        return filteredAgents.map((teamMember) => ({
          label: (
            <AvatarLabelComponent
              avatar={teamMember.agent!.avatar!}
              firstName={teamMember.agent!.firstName!}
              lastName={teamMember.agent!.lastName!}
              emailAddress={teamMember.agent!.emailAddress!}
              administrativeAreas={teamMember.agent!.administrativeAreaIds!}
            />
          ),
          value: teamMember.agent!.id!,
        }));
      }}
      rules={{
        required: 'Select an agent for whom you want to generate a report',
      }}
    />
  );
};

export default TeamAdminAgentSelector;
