import { useSelector } from 'react-redux';
import { AgentWebsiteResponse } from '../../../../openapi/yenta';
import { RootState } from '../../../../types';
import {
  FACEBOOK_URL,
  getPathFromURL,
  getUrlWithPrefix,
  INSTAGRAM_URL,
  LINKEDIN_URL,
} from '../../../../utils/AgentWebsiteUtils';
import { formatPhoneNumber } from '../../../../utils/StringUtils';
import ZenContentRow from './ZenContentRow';

interface ZenAgentWebsiteContactInfoProps {
  agentWebsite: AgentWebsiteResponse | undefined;
}

const ZenAgentWebsiteContactInfo: React.FC<ZenAgentWebsiteContactInfoProps> = ({
  agentWebsite,
}) => {
  const { agentDetail: agentDetailObject } = useSelector(
    (state: RootState) => state,
  );
  const agentDetail = {
    ...agentDetailObject,
    detail: agentDetailObject.detailResponse.data,
  };
  return (
    <div className='flex flex-col space-y-4 p-4'>
      <ZenContentRow
        title='Real Website'
        content={`www.joinreal.com/${agentWebsite?.agentResponse?.slug}`}
        defaultContent={agentDetail.detail?.slug!}
      />
      <ZenContentRow
        title='Email'
        content={agentWebsite?.agentResponse?.joinRealEmail}
        defaultContent={agentDetail.detail?.joinRealEmail!}
      />
      <ZenContentRow
        title='Phone'
        content={formatPhoneNumber(agentWebsite?.agentResponse?.phoneNumber)}
        defaultContent={agentDetail.detail?.phoneNumber!}
      />
      <ZenContentRow
        title='Show Agent Referral Link'
        content={agentWebsite?.showReferralLink ? 'true' : 'false'}
        defaultContent='false'
      />
      <ZenContentRow
        title='Social Media'
        content={
          `${getUrlWithPrefix(INSTAGRAM_URL)}${getPathFromURL(
            agentWebsite?.agentResponse?.instagramURL,
          )}` || `${getUrlWithPrefix(INSTAGRAM_URL)}`
        }
        defaultContent='www.Instagram.com/'
      >
        {agentWebsite?.agentResponse?.facebookURL ? (
          <p className='text-sm'>
            {getUrlWithPrefix(FACEBOOK_URL)}
            {getPathFromURL(agentWebsite?.agentResponse?.facebookURL)}
          </p>
        ) : (
          <p className='text-sm'>{getUrlWithPrefix(FACEBOOK_URL)}</p>
        )}

        {agentWebsite?.agentResponse?.linkedInURL ? (
          <p className='text-sm'>
            {getUrlWithPrefix(LINKEDIN_URL)}
            {getPathFromURL(agentWebsite?.agentResponse?.linkedInURL)}
          </p>
        ) : (
          <p className='text-sm'>{getUrlWithPrefix(LINKEDIN_URL)}</p>
        )}
      </ZenContentRow>
    </div>
  );
};

export default ZenAgentWebsiteContactInfo;
