import { TransactionResponseTransactionTypeEnum } from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';
import MultiSelectColumnFilter from './Base/MultiSelectColumnFilter';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';

export interface TransactionTypeSelectColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

export const transactionTypes = [
  TransactionResponseTransactionTypeEnum.Sale,
  TransactionResponseTransactionTypeEnum.InternalReferral,
  TransactionResponseTransactionTypeEnum.Referral,
  TransactionResponseTransactionTypeEnum.Lease,
];

const TransactionTypeSelectColumnFilter = <D extends object>(
  props: TransactionTypeSelectColumnFilterProps<D>,
) => {
  const options = transactionTypes.map((value) => ({
    label: capitalizeEnum(value),
    value: value,
  }));

  return <MultiSelectColumnFilter {...props} options={options} />;
};

export default TransactionTypeSelectColumnFilter;
