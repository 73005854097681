import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AgentDivisionCard from '../../components/Agent/AgentDivision/AgentDivisionCard';
import SelfOrAdminOnly from '../../components/auth/SelfOrAdminOnly';
import ZenAgentFiles from '../../components/Agent/V2/ZenAgentFiles';
import ZenAgentPriorUser from '../../components/Agent/V2/ZenAgentPriorUser';
import ZenAgentRoles from '../../components/Agent/V2/ZenAgentRoles';
import ZenBasicInformationCard from '../../components/Agent/V2/ZenBasicInformationCard';
import ZenBooleanButton from '../../components/Agent/V2/ZenBooleanButton';
import ZenProfileProgressRows from '../../components/Agent/V2/ZenProfileProgressRows';
import ZenButton from '../../components/Zen/ZenButton';
import ZenCard from '../../components/Zen/ZenCard';
import ZenCardWithItems from '../../components/Zen/ZenCardWithItems';
import ZenCopyToClipboard from '../../components/Zen/ZenCopyToClipBoard';
import ZenRoute from '../../components/Zen/ZenRoute';
import AdminOnly from '../../components/auth/AdminOnly';
import ZenProfileEditForm from '../../forms/ZenProfileEditForm';
import {
  AgentControllerApi,
  AgentProfileScoreResponse,
  UserControllerApi,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { fetchAgentDetail } from '../../slices/AgentSlice';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { RootState } from '../../types';
import { isImageValid } from '../../utils/AgentHelper';
import { isValidUserAvatarFile } from '../../utils/FileUtils';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import AdminOrBrokerOnly from '../../components/auth/AdminOrBrokerOnly';
import AgentPersonalAccountCard from '../../components/Agent/AgentPersonalAccount/AgentPersonalAccountCard';
import { isBroker } from '../../utils/AuthUtils';
import { cn } from '../../utils/classUtils';
import { useGemini } from '../../hooks/useGemini';

interface ZenAgentProfileRouteProps {
  agentId: string;
  setOpenEditAgentDetailsSideModal(isOpen: boolean): void;
}

const ZenAgentProfileRoute: React.FC<ZenAgentProfileRouteProps> = ({
  setOpenEditAgentDetailsSideModal,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openProfileUpdateForm, setOpenProfileUpdateForm] = useState<boolean>(
    false,
  );
  const {
    agentDetail: {
      detailResponse: { data: agentDetailResponse },
      agentFilesResponse,
    },
    auth: { isAdmin, userDetail },
  } = useSelector((state: RootState) => state);
  const [profileScore, setProfileScore] = useState<AgentProfileScoreResponse>();

  const isSelfOrAdmin = isAdmin || agentDetailResponse?.id! === userDetail?.id;
  const isGeminiEnabled = useGemini();

  const fetchProfileScore = useCallback(async () => {
    try {
      const { data } = await new AgentControllerApi(
        getYentaConfiguration(),
      ).getProfileScore(agentDetailResponse!.id!);
      setProfileScore(data);
    } catch (e) {
      ErrorService.notify('Error fetching profile score', e, {
        agent: { id: agentDetailResponse!.id },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentDetailResponse?.id]);

  useEffect(() => {
    fetchProfileScore();
  }, [fetchProfileScore]);

  const hasAddedWebsites = !!profileScore?.hasCreatedAgentWebsite;

  const onImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (isImageValid(e)) {
      try {
        await new UserControllerApi(getYentaConfiguration()).uploadAvatar(
          agentDetailResponse?.id!,
          e.target.files![0]!,
        );
        await dispatch(fetchAgentDetail(agentDetailResponse?.id!));
        await fetchProfileScore();
      } catch (e) {
        dispatch(showApiErrorModal(e));
        ErrorService.notify('Error uploading user avatar', e);
      }
    } else {
      dispatch(showErrorToast('File size exceeds maximum limit of 10 MB.'));
    }
  };

  return (
    <ZenRoute title={`Profile - ${agentDetailResponse?.fullName}`}>
      <div className='px-0 md:px-4 py-5 space-y-5'>
        {isSelfOrAdmin && (
          <div className='flex justify-end'>
            <ZenButton
              label='Edit Profile'
              variant='primary-outline'
              LeftIconComponent={<FontAwesomeIcon icon={faPencil} size='sm' />}
              textSize='lg'
              onClick={() => setOpenProfileUpdateForm(true)}
            />
          </div>
        )}
        <div className='grid grid-cols-1 md:grid-cols-3 gap-6 justify-self-stretch'>
          <div className='md:col-span-2 col-span-1'>
            <ZenBasicInformationCard
              agent={agentDetailResponse!}
              setOpenEditAgentDetailsSideModal={
                setOpenEditAgentDetailsSideModal
              }
              readonly
            />
          </div>

          <div className='grid grid-cols-1 grid-flow-row gap-y-4'>
            <ZenCard title={`Profile Score: ${profileScore?.percentage}`}>
              <div className='mx-3 my-2 bg-gray-100 rounded-full'>
                <div
                  className='loading-bar'
                  style={{
                    width: `${profileScore?.percentage}`,
                    height: '14px',
                  }}
                >
                  <span />
                </div>
              </div>
              <ZenProfileProgressRows
                profileScoreItems={[
                  {
                    label: 'Photo Uploaded',
                    completed: !!profileScore?.hasUploadedPhoto,
                    rightActionUpload: isSelfOrAdmin
                      ? {
                          text: !!profileScore?.hasUploadedPhoto
                            ? 'Update'
                            : 'Upload',
                          onClick: (e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target?.files && e.target?.files?.[0]) {
                              if (
                                isValidUserAvatarFile(
                                  e.target?.files?.[0]?.name,
                                )
                              ) {
                                onImageChange(e);
                              } else {
                                dispatch(
                                  showErrorToast(
                                    'File type not supported. Upload jpg/jpeg, png, gif, bmp file only',
                                  ),
                                );
                              }
                              e.target.value = '';
                            }
                          },
                          icon: undefined,
                        }
                      : undefined,
                  },
                  {
                    label: hasAddedWebsites
                      ? 'Website created'
                      : 'Website not created',
                    completed: hasAddedWebsites,
                    rightAction: isSelfOrAdmin
                      ? {
                          text: hasAddedWebsites ? 'Edit' : 'Create',
                          onClick: () => {
                            if (hasAddedWebsites) {
                              history.push(
                                `/people/${agentDetailResponse?.id}/agent-website`,
                              );
                            } else {
                              history.push(
                                `/people/${agentDetailResponse?.id}/agent-website-onboarding`,
                              );
                            }
                          },
                          icon: undefined,
                        }
                      : undefined,
                  },
                  // {
                  //   label: 'Licenses updated',
                  //   completed: !!profileScore?.hasUpdatedZipcodes,
                  //   rightAction: {
                  //     text: !!profileScore?.hasUpdatedZipcodes ? 'Update' : 'Add',
                  //     actionUrl: '',
                  //     icon: undefined,
                  //   },
                  // },
                  {
                    label: 'Service area postal codes',
                    completed: !!profileScore?.hasUpdatedZipcodes,
                    rightAction: isSelfOrAdmin
                      ? {
                          text: !!profileScore?.hasUpdatedZipcodes
                            ? 'Edit'
                            : 'Add',
                          onClick: () => setOpenProfileUpdateForm(true),
                          icon: undefined,
                        }
                      : undefined,
                  },
                  {
                    label: 'Bio/about me written',
                    completed: !!profileScore?.hasWrittenBio,
                    rightAction: isSelfOrAdmin
                      ? {
                          text: !!profileScore?.hasWrittenBio ? 'Edit' : 'Add',
                          onClick: () => setOpenProfileUpdateForm(true),
                          icon: undefined,
                        }
                      : undefined,
                  },
                  {
                    label: 'Social media accounts added',
                    completed: !!profileScore?.hasAddedSocialMediaAccounts,
                    rightAction: isSelfOrAdmin
                      ? {
                          text: !!profileScore?.hasAddedSocialMediaAccounts
                            ? 'Edit'
                            : 'Add',
                          onClick: () => setOpenProfileUpdateForm(true),
                          icon: undefined,
                        }
                      : undefined,
                  },
                ]}
              />
            </ZenCard>
          </div>
          <AdminOnly>
            <ZenCardWithItems
              title='Third Party Software'
              items={[
                {
                  name: 'Tipalti Integrated?',
                  value: (
                    <ZenBooleanButton
                      value={agentDetailResponse?.tipaltiConfirmed!}
                    />
                  ),
                },
                {
                  name: 'Tipalti ID',
                  value: !!agentDetailResponse?.tipaltiId ? (
                    <ZenCopyToClipboard
                      value={agentDetailResponse?.tipaltiId}
                      label={agentDetailResponse?.tipaltiId}
                      variant='primary'
                    />
                  ) : (
                    <p
                      className={cn(
                        'text-sm',
                        isGeminiEnabled
                          ? 'font-zen-body-2 font-light text-primary-dark'
                          : 'font-zen-body font-semibold text-zen-dark-9',
                      )}
                    >
                      N/A
                    </p>
                  ),
                },
              ]}
            />
          </AdminOnly>

          <AdminOrBrokerOnly>
            <div className='md:col-span-2 col-span-1'>
              <ZenAgentFiles
                id={agentDetailResponse?.id!}
                agentFiles={agentFilesResponse}
              />
            </div>
          </AdminOrBrokerOnly>

          <AdminOnly>
            <ZenAgentRoles />
          </AdminOnly>

          <AdminOnly>
            <ZenAgentPriorUser />
          </AdminOnly>

          <AdminOnly>
            {isBroker(agentDetailResponse) && <AgentPersonalAccountCard />}
          </AdminOnly>

          <SelfOrAdminOnly agentId={agentDetailResponse?.id!}>
            <AgentDivisionCard />
          </SelfOrAdminOnly>

          {!!agentDetailResponse && openProfileUpdateForm && (
            <ZenProfileEditForm
              isOpen={openProfileUpdateForm}
              onClose={() => setOpenProfileUpdateForm(false)}
              agent={agentDetailResponse!}
            />
          )}
        </div>
      </div>
    </ZenRoute>
  );
};

export default ZenAgentProfileRoute;
