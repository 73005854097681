import { values } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import {
  EscrowDepositRequest,
  EscrowDepositRequestExemptAmountTypeEnum,
  EscrowDepositResponse,
  MoneyValue,
  MoneyValueCurrencyEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import {
  addDepositPayment,
  getDepositInstallmentReceipt,
  updateDepositPayment,
} from '../../../slices/TransactionSlice';
import { AppDispatch, ISelectOption, RootState } from '../../../types';
import {
  capitalizeEnum,
  getFileNameFromUrl,
  MONEY_AMOUNT_REGEX,
} from '../../../utils/StringUtils';
import { isCanadaTransaction } from '../../../utils/TransactionHelper';
import {
  DATE_FORMAT_VALIDATIONS,
  DATE_SELECT_VALIDATIONS,
  FILE_VALIDATIONS,
  MONEY_NON_ZERO_VALUE_VALIDATIONS,
} from '../../../utils/Validations';
import ZenControlledDatePickerInputV2 from '../../Zen/Input/ZenControlledDatePickerInputV2';
import ZenControlledFileUploadInput from '../../Zen/Input/ZenControlledFileUploadInput';
import ZenControlledFormattedMoneyInput from '../../Zen/Input/ZenControlledFormattedMoneyInput';
import ZenControlledSelectInput from '../../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextAreaInput from '../../Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalActionFooter from '../../Zen/Modal/ZenSidebarModalActionFooter';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import AdminOnly from '../../auth/AdminOnly';
import { getISelectOptionDefaultValue } from '../../../utils/FormUtils';
import {
  getBankAccountISelectOptions,
  getDepositAccountISelectOption,
} from '../../../utils/DepositsUtil';
import DeleteCommissionDepositInstallmentModal from './DeleteCommissionDepositInstallmentModal';

interface CommissionDepositAddFormProps {
  onClose(): void;
  commissionDepositDetails?: EscrowDepositResponse;
  transaction: TransactionResponse;
  escrowId: string;
}

interface FormData {
  amount: MoneyValue;
  dateReceived: string;
  commissionDepositorName: string;
  receiptNumber: string;
  commissionAccount: ISelectOption;
  dateDeposited?: string;
  commissionDepositMethod?: string;
  commissionDepositorInfo?: string;
  sourceAccount?: string;
  note?: string;
  exemptAmountType?: ISelectOption;
  exemptAmount?: MoneyValue;
  exemptReason?: string;
  receipt?: File[];
}

const ZenCommissionDepositAddForm: React.FC<CommissionDepositAddFormProps> = ({
  escrowId,
  onClose,
  transaction,
  commissionDepositDetails,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [deleteDeposit, setDeleteDeposit] = useState<EscrowDepositResponse>();
  const [receiptFileUrl, setReceiptFileUrl] = useState<string | undefined>();
  const { officeDetailById } = useSelector((state: RootState) => state.office);
  const officeDetails = officeDetailById[transaction?.office?.id!];

  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      amount: commissionDepositDetails?.amount ?? {
        currency: (transaction.currency as unknown) as MoneyValueCurrencyEnum,
      },
      dateReceived: commissionDepositDetails?.dateReceived
        ? DateTime.fromISO(commissionDepositDetails?.dateReceived).toFormat(
            'LL/dd/yyyy',
          )
        : undefined,
      dateDeposited: commissionDepositDetails?.dateDeposited
        ? DateTime.fromISO(commissionDepositDetails?.dateDeposited).toFormat(
            'LL/dd/yyyy',
          )
        : undefined,
      commissionDepositMethod: commissionDepositDetails?.depositMethod,
      commissionDepositorInfo: commissionDepositDetails?.depositorInfo,
      sourceAccount: commissionDepositDetails?.sourceAccount,
      commissionAccount: getDepositAccountISelectOption(
        commissionDepositDetails,
        officeDetails?.bankAccount,
      ),
      commissionDepositorName: commissionDepositDetails?.depositorName,
      receiptNumber: commissionDepositDetails?.receiptNumber,
      note: commissionDepositDetails?.note,
      exemptAmount: commissionDepositDetails?.exemptAmount! ?? {
        currency: (transaction.currency as unknown) as MoneyValueCurrencyEnum,
      },
      exemptAmountType: getISelectOptionDefaultValue(
        commissionDepositDetails?.exemptAmountType,
      ),
      exemptReason: commissionDepositDetails?.exemptReason,
    },
  });

  const [receipt, exemptAmount] = watch(['receipt', 'exemptAmount']);

  useEffect(() => {
    const getReceiptUrl = async () => {
      if (commissionDepositDetails?.receiptImagePath) {
        const url = await dispatch(
          getDepositInstallmentReceipt(
            transaction?.id!,
            escrowId,
            commissionDepositDetails?.id!,
          ),
        );
        setReceiptFileUrl(url);
      }
    };

    getReceiptUrl();
  }, [
    commissionDepositDetails?.id,
    commissionDepositDetails?.receiptImagePath,
    dispatch,
    escrowId,
    transaction?.id,
  ]);

  useEffect(() => {
    if (isCanadaTransaction(transaction) && !exemptAmount?.amount) {
      clearErrors('exemptAmountType');
    }
  }, [clearErrors, exemptAmount, transaction]);

  const onSubmit = async (data: FormData) => {
    const final: EscrowDepositRequest = {
      depositorId: commissionDepositDetails?.depositorId,
      depositorAddress: commissionDepositDetails?.depositorAddress,
      depositorName:
        data.commissionDepositorName ||
        commissionDepositDetails?.depositorName!,
      receiptNumber:
        data.receiptNumber || commissionDepositDetails?.receiptNumber,
      amount: data.amount || commissionDepositDetails?.amount,
      dateReceived: !!data.dateReceived
        ? DateTime.fromFormat(data?.dateReceived, 'LL/dd/yyyy').toISODate()
        : commissionDepositDetails?.dateReceived!,
      dateDeposited: !!data.dateDeposited
        ? DateTime.fromFormat(data?.dateDeposited, 'LL/dd/yyyy').toISODate()
        : commissionDepositDetails?.dateDeposited,
      depositMethod:
        data.commissionDepositMethod || commissionDepositDetails?.depositMethod,
      depositorInfo:
        data.commissionDepositorInfo || commissionDepositDetails?.depositorInfo,
      sourceAccount:
        data.sourceAccount || commissionDepositDetails?.sourceAccount,
      trustAccount: data.commissionAccount?.value,
      note: data.note || commissionDepositDetails?.note,
      exemptAmount: !!data?.exemptAmount?.amount
        ? {
            amount: +data?.exemptAmount?.amount!,
            currency: data?.exemptAmount?.currency!,
          }
        : undefined,
      exemptAmountType: !!(data?.exemptAmountType
        ?.value as EscrowDepositRequestExemptAmountTypeEnum)
        ? (data?.exemptAmountType
            ?.value as EscrowDepositRequestExemptAmountTypeEnum)
        : undefined,
      exemptReason: data.exemptReason || commissionDepositDetails?.exemptReason,
    };

    let isDepositSaved: boolean;
    if (commissionDepositDetails) {
      isDepositSaved = await dispatch(
        updateDepositPayment(
          transaction?.id!,
          escrowId,
          commissionDepositDetails.id!,
          final,
          receipt?.[0],
        ),
      );
    } else {
      isDepositSaved = await dispatch(
        addDepositPayment(transaction.id!, escrowId, final, receipt?.[0]),
      );
    }

    if (isDepositSaved) {
      onClose();
    }
  };

  return (
    <ZenSidebarModal
      title={
        commissionDepositDetails
          ? 'Edit Commission Installment'
          : 'Add Commission Installment'
      }
      isOpen
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full mb-20'
        onSubmit={handleSubmit(onSubmit)}
        title='commission-deposit-form'
      >
        <div>
          <div className='m-4'>
            <div>
              <ZenControlledFormattedMoneyInput<FormData, 'amount'>
                control={control}
                label={`Amount (${transaction.currency})`}
                shouldUnregister={!commissionDepositDetails}
                name='amount'
                placeholder='Please enter the amount for deposits'
                currencyReadOnly
                rules={MONEY_NON_ZERO_VALUE_VALIDATIONS}
                isRequired
              />
            </div>
            <div className='mt-5'>
              <ZenControlledDatePickerInputV2<FormData, 'dateReceived'>
                name='dateReceived'
                shouldUnregister={!commissionDepositDetails}
                control={control}
                label='Date Received'
                placeholder='Date amount was received'
                datePickerConfig={{
                  maxDate: DateTime.local().toJSDate(),
                }}
                rules={{
                  required: 'Please select a date',
                  ...DATE_FORMAT_VALIDATIONS,
                  ...DATE_SELECT_VALIDATIONS,
                }}
                isRequired
              />
            </div>
            <div className='mt-5'>
              <ZenControlledDatePickerInputV2<FormData, 'dateDeposited'>
                name='dateDeposited'
                shouldUnregister={!commissionDepositDetails}
                control={control}
                label='Date Deposited'
                placeholder='Date amount was Deposited'
                datePickerConfig={{
                  maxDate: DateTime.local().toJSDate(),
                }}
                rules={{
                  required:
                    'Please enter the date on which the amount was deposited',
                  ...DATE_FORMAT_VALIDATIONS,
                  ...DATE_SELECT_VALIDATIONS,
                }}
                isRequired
              />
            </div>
            <div className='mt-5'>
              <ZenControlledTextInput<FormData, 'commissionDepositMethod'>
                name='commissionDepositMethod'
                shouldUnregister={!commissionDepositDetails}
                control={control}
                label='Deposit Type'
                placeholder='Wire, Check, EFT, Cash, etc.'
                rules={{ required: 'Please enter a type of deposit' }}
                isRequired
              />
            </div>
            <div className='mt-5'>
              <ZenControlledTextInput<FormData, 'commissionDepositorName'>
                name='commissionDepositorName'
                shouldUnregister={!commissionDepositDetails}
                control={control}
                label='Depositor Name'
                placeholder='Depositor Name'
                rules={{
                  required: 'Please select depositor name',
                }}
                isRequired
              />
            </div>
            <div className='mt-5'>
              <ZenControlledTextInput<FormData, 'receiptNumber'>
                name='receiptNumber'
                shouldUnregister={!commissionDepositDetails}
                control={control}
                label='Deposit Reference Number / Check Number'
                placeholder='Deposit Reference Number / Check Number'
                rules={{ required: 'Please enter a receipt number ' }}
                isRequired
              />
            </div>
            {isCanadaTransaction(transaction) && (
              <>
                <div className='mt-5'>
                  <ZenControlledTextInput<FormData, 'commissionDepositorInfo'>
                    name='commissionDepositorInfo'
                    shouldUnregister={!commissionDepositDetails}
                    control={control}
                    label='Bank Branch'
                    placeholder='Bank where funds were deposited'
                  />
                </div>
                <div className='mt-5'>
                  <ZenControlledTextInput<FormData, 'sourceAccount'>
                    name='sourceAccount'
                    shouldUnregister={!commissionDepositDetails}
                    control={control}
                    label='Bank Transit Number'
                  />
                </div>
              </>
            )}
            <div className='mt-5'>
              <ZenControlledSelectInput<FormData, 'commissionAccount'>
                name='commissionAccount'
                shouldUnregister={!commissionDepositDetails}
                control={control}
                label='Commission Account'
                placeholder='Commission Account'
                options={getBankAccountISelectOptions(
                  officeDetails?.bankAccount,
                )}
                rules={{
                  required: 'Please provide a commission account',
                }}
                isRequired
              />
            </div>
            {isCanadaTransaction(transaction) && (
              <AdminOnly>
                <div className='mt-5'>
                  <ZenControlledSelectInput<FormData, 'exemptAmountType'>
                    name='exemptAmountType'
                    control={control}
                    shouldUnregister={!commissionDepositDetails}
                    label='Exempt Amount Type'
                    placeholder='Select Amount Type'
                    options={[
                      { label: 'Select Amount Type', value: '' },
                      ...values(EscrowDepositRequestExemptAmountTypeEnum).map(
                        (type) => {
                          return {
                            label: capitalizeEnum(type),
                            value: type,
                          };
                        },
                      ),
                    ]}
                    isRequired={!!exemptAmount?.amount}
                    rules={{
                      validate: (v: ISelectOption | undefined) => {
                        if (!!exemptAmount?.amount && !v?.value) {
                          return 'Please select the type of exempt amount';
                        }
                        return undefined;
                      },
                    }}
                  />
                </div>
                <div className='mt-5'>
                  <ZenControlledFormattedMoneyInput<FormData, 'exemptAmount'>
                    control={control}
                    label={`Exempt Amount (${transaction.currency})`}
                    shouldUnregister={!commissionDepositDetails}
                    name='exemptAmount'
                    placeholder='Please enter the exempt amount'
                    currencyReadOnly
                    rules={{
                      validate: (v: MoneyValue | undefined) => {
                        if (!!v?.amount && v?.amount <= 0) {
                          return 'Amount should be greater than 0';
                        }
                        if (
                          !!v?.amount &&
                          !MONEY_AMOUNT_REGEX.test(v?.amount?.toString()!)
                        ) {
                          return 'Please enter valid amount';
                        }
                        return undefined;
                      },
                    }}
                  />
                </div>
                <div className='mt-5'>
                  <ZenControlledTextAreaInput<FormData, 'exemptReason'>
                    name='exemptReason'
                    shouldUnregister={!commissionDepositDetails}
                    control={control}
                    label='Exempt Reason'
                    placeholder='E.g. Please provide the exempt reason'
                    rows={2}
                  />
                </div>
              </AdminOnly>
            )}
            <div className='mt-5'>
              <ZenControlledFileUploadInput<FormData, 'receipt'>
                name='receipt'
                control={control}
                label='Upload Receipt'
                placeholder={
                  getFileNameFromUrl(
                    commissionDepositDetails?.receiptImagePath!,
                  ) || 'E.g. Receipt.png'
                }
                accept='.png,.jpg,.pdf'
                rightAction={
                  commissionDepositDetails?.receiptImagePath
                    ? {
                        text: 'View Receipt',
                        onClick: () => window.open(receiptFileUrl, '_blank'),
                      }
                    : undefined
                }
                rules={{
                  ...FILE_VALIDATIONS,
                }}
              />
            </div>
            <div className='mt-5'>
              <ZenControlledTextInput<FormData, 'note'>
                name='note'
                shouldUnregister={!commissionDepositDetails}
                control={control}
                label='Note'
                placeholder='Additional notes for the deposit'
              />
            </div>
          </div>
          {!!commissionDepositDetails && (
            <div className='inline-block p-4'>
              <div
                className='flex items-center cursor-pointer hover:bg-red-50 p-2 rounded'
                onClick={() => setDeleteDeposit(commissionDepositDetails)}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  className='text-error mb-1 mr-2'
                />
                <p className='text-error font-primary-medium text-sm'>
                  Delete Commission Installment
                </p>
              </div>
            </div>
          )}
          <DeleteCommissionDepositInstallmentModal
            isOpen={!!deleteDeposit}
            onClose={() => setDeleteDeposit(undefined)}
            commissionDeposit={deleteDeposit!}
            transaction={transaction}
            escrowId={escrowId}
          />
        </div>
        <ZenSidebarModalActionFooter
          isSubmitting={isSubmitting}
          onClose={onClose}
          submitButtonText={commissionDepositDetails ? 'Update' : 'Add'}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenCommissionDepositAddForm;
