import React from 'react';
import { TableToggleAllRowsSelectedProps } from 'react-table';

const TableSelectCheckbox = React.forwardRef<
  HTMLInputElement,
  TableToggleAllRowsSelectedProps
>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef<HTMLInputElement>(null);
  const resolvedRef = (ref || defaultRef) as React.RefObject<HTMLInputElement>;

  React.useEffect(() => {
    if (resolvedRef.current) {
      resolvedRef.current.indeterminate = indeterminate!;
    }
  }, [resolvedRef, indeterminate]);

  return (
    <input
      type='checkbox'
      ref={resolvedRef}
      {...rest}
      className='rounded-full border-none ring-1 ring-gray-200 focus:outline-none focus:ring-0 h-4 w-4 text-primary'
    />
  );
});

export default TableSelectCheckbox;
