import { faMicrophone } from '@fortawesome/pro-light-svg-icons';
import { faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons';
import { faRotate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { findLastIndex, isEmpty } from 'lodash';
import { Popover } from 'react-tiny-popover';
import * as Quill from 'quill';
import quillEmoji from 'quill-emoji';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import useFFmpeg from '../../hooks/useFFmpeg';
import { useRefresh } from '../../hooks/useRefresh';
import {
  CommentDtoReaderRoleEnum,
  CommentTemplateControllerApi,
  FileBlock,
  HashtagBlock,
  HyperlinkBlock,
  ImageBlock,
  MentionBlock,
  MentionBlockMentionTypeEnum,
  PostCommentBodyReaderRoleEnum,
  ReferenceBlock,
  RichText,
  TextBlock,
  UnknownBlock,
  UpdateCommentBodyReaderRoleEnum,
} from '../../openapi/yada';
import ErrorService from '../../services/ErrorService';
import { saveCommentTemplates } from '../../slices/TransactionSlice';
import { getYadaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ZenMoreOptions from '../transactions/Comments/ZenMoreOptions';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { FeatureFlagTypeEnum, RootState } from '../../types';
import { getFullName } from '../../utils/AgentHelper';
import { cn } from '../../utils/classUtils';
import { isTextBlock } from '../../utils/TypeUtils';
import MentionRow from '../CommentWidget/MentionRow';
import FeatureFlagEnabledOnly from '../FeatureFlagEnabledOnly';
import SelectGiphy from '../transactions/Comments/SelectGiphy';
import { MentionSource } from '../transactions/Comments/ZenCommentSection';
import ZenFormErrorMessage from '../Zen/Input/ZenFormErrorMessage';
import ZenButton from '../Zen/ZenButton';
import './init-zenQuill';
import RecordMemo from './RecordMemo';
import RecordMemoPreview from './RecordMemoPreview';
import { BlockItem } from './ZenRichCommentRow';

interface CommentType {
  type: string;
  text?: string;
}

export type CommentOnSubmitParams = {
  comment: RichText;
  role:
    | PostCommentBodyReaderRoleEnum
    | UpdateCommentBodyReaderRoleEnum
    | CommentDtoReaderRoleEnum;
  commentDelta?: any;
  mediaType?: 'AUDIO';
  filePath?: string;
  fileType?: string;
  autoTag?: boolean;
};

interface ZenCommentWidgetProps {
  comment?: BlockItem[];
  editable?: boolean;
  defaultValue?: string | Quill.Delta;
  onSubmit(params: CommentOnSubmitParams): Promise<void>;
  onEdit?(value: boolean): void;
  getMentions?(searchTerm?: string): MentionSource[];
  checklist?: boolean;
  powerAudit?: boolean;
  hideVoiceMemo?: boolean;
}

const ZenCommentWidget: React.FC<ZenCommentWidgetProps> = ({
  defaultValue,
  onSubmit,
  comment,
  onEdit,
  editable,
  getMentions,
  checklist = false,
  powerAudit = false,
  hideVoiceMemo = false,
}) => {
  const dispatch = useDispatch();
  const {
    userIds: { agentById },
    transaction: { commentsMentionUsers },
  } = useSelector((state: RootState) => state);
  const location = useLocation();
  const reactQuillRef = useRef<ReactQuill>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const editSaveButtonRef = useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGiphyOpen, setIsGiphyOpen] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [
    showTemplateCommentsOption,
    setShowTemplateCommentsOption,
  ] = useState<boolean>(false);
  const isTemplateCommentsEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.TEMPLATE_COMMENTS,
  );
  const [mentionError, setMentionError] = useState<string | undefined>(
    undefined,
  );
  const [isRecording, setIsRecording] = useState(false);
  const isRoarEnabled = useFeatureFlag(FeatureFlagTypeEnum.ROAR);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerActionRef = useRef<HTMLDivElement>(null);
  const [recordingData, setRecordingData] = useState<{
    path: string;
    duration: number;
  }>();
  const ffmpeg = useFFmpeg();

  const isMortgageRoute = location.pathname.includes('mortgages');

  const formattedComment = useCallback(async (blocks: BlockItem[]) => {
    // @ts-ignore
    reactQuillRef.current?.getEditor().setContents('');
    for (let index = 0; index < blocks.length; index++) {
      const position = reactQuillRef.current?.getEditor().getLength()! - 1;
      if (blocks[index].type === 'TEXT') {
        reactQuillRef.current
          ?.getEditor()
          .insertText(position, blocks[index].preview!);
      } else if (blocks[index].type === 'MENTION') {
        const agent = agentById[blocks[index].userId!];
        const isGroup = (commentsMentionUsers?.mentionableGroups || [])?.find(
          (group) => group?.groupId === blocks[index]?.userId,
        );
        const fullName = !!isGroup
          ? isGroup?.groupName
          : commentsMentionUsers?.mentionableUsers?.find(
              (user) => user?.yentaId === blocks[index].userId!,
            )?.displayName || getFullName(agent);
        reactQuillRef.current?.getEditor().insertEmbed(position, 'mention', {
          emailAddress: agent?.emailAddress,
          id: blocks[index].userId,
          value: fullName,
          denotationChar: '@',
          mentionType: isGroup
            ? MentionBlockMentionTypeEnum.Group
            : MentionBlockMentionTypeEnum.User,
        });
      } else if (blocks[index].type === 'EMOJI') {
        const name = quillEmoji.ShortNameEmoji.DEFAULTS.emojiList.find(
          (emoji: any) => emoji.unicode === blocks[index].preview,
        ).name;
        reactQuillRef.current?.getEditor().insertEmbed(position, 'emoji', name);
      } else if (blocks[index].type === 'HYPERLINK') {
        const delta = {
          ops: [
            { retain: position },
            {
              insert: blocks[index].text!,
              attributes: { link: blocks[index].url! },
            },
          ],
        };
        // @ts-expect-error
        reactQuillRef.current?.getEditor().updateContents(delta);
      } else {
        reactQuillRef.current
          ?.getEditor()
          .insertText(position, blocks[index].preview!);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const focusQuillEditor = () => {
    reactQuillRef?.current?.getEditor().focus();
  };

  useEffect(() => {
    if (comment) {
      formattedComment(comment);
    }
  }, [comment, formattedComment]);

  const getMessage = (): [MentionBlock[], Quill.Delta] => {
    const contents = reactQuillRef.current?.getEditor().getContents()!;

    const message = contents.ops!.map((item) => {
      if (item.insert.mention) {
        const mentionId = item.insert.mention.id;
        const agent = getMentions?.('').find((m) => m.id === mentionId);
        return {
          type: 'MENTION',
          userId: mentionId,
          name: agent?.value?.replace(/^@+/, ''),
          mentionType: agent?.mentionType,
        } as MentionBlock;
      }

      if (item.insert.emoji) {
        const code = quillEmoji.ShortNameEmoji.DEFAULTS.emojiList.find(
          (emoji: any) => emoji.name === item.insert.emoji,
        ).unicode;
        return {
          type: 'TEXT',
          text: String.fromCodePoint(parseInt(code, 16)),
        } as TextBlock;
      }

      if (item.attributes?.link) {
        return {
          type: 'HYPERLINK',
          text: item.insert,
          url: item.attributes.link,
        };
      }

      return { type: 'TEXT', text: item.insert } as TextBlock;
    });

    return [message, contents];
  };

  const getCommentTemplates = useCallback(async () => {
    if (!isTemplateCommentsEnabled) {
      return;
    }
    try {
      const { data } = await new CommentTemplateControllerApi(
        getYadaConfiguration(),
      ).getCommentTemplates();
      dispatch(saveCommentTemplates(data.allCommentTemplates));
      setShowTemplateCommentsOption(true);
    } catch (error) {
      setShowTemplateCommentsOption(false);
      if (error.response?.status === 403) {
        return;
      }
      ErrorService.notify('Failed to fetch comment templates', error);
      dispatch(
        showErrorToast(
          'Failed to fetch comment templates',
          'Please try again in a few moments.',
        ),
      );
    }
  }, [dispatch, isTemplateCommentsEnabled]);

  useEffect(() => {
    focusQuillEditor();
    getCommentTemplates();
  }, [getCommentTemplates]);

  const trimLastTextComment = (comment: any[] | undefined) => {
    if (!Array.isArray(comment)) {
      return;
    }
    const lastIdx = findLastIndex<CommentType>(
      comment,
      (c) => c.type === 'TEXT',
    );
    if (lastIdx >= 0 && comment[lastIdx].text) {
      comment[lastIdx].text = comment[lastIdx].text.trimEnd();
    }
  };

  const handleSubmit = async (
    blocks?: (
      | FileBlock
      | HashtagBlock
      | HyperlinkBlock
      | ImageBlock
      | MentionBlock
      | ReferenceBlock
      | TextBlock
      | UnknownBlock
    )[],
  ) => {
    const [comment, commentDelta] = getMessage();

    if (comment.length <= 0) {
      return;
    }

    if (editable) setIsSubmitting(true);
    else setIsLoading(true);
    trimLastTextComment(comment);

    try {
      if (recordingData) {
        await ffmpeg.load();
        const processedFilePath = await ffmpeg.transcode(
          recordingData.path,
          'voice-memo.webm',
          'voice-memo.m4a',
        );
        const filteredBlocks = (blocks || comment)?.filter((block) => {
          // remove blank spaces
          if (isTextBlock(block)) {
            return block.text;
          }
          return true;
        });
        await onSubmit({
          comment: { blocks: filteredBlocks },
          role: PostCommentBodyReaderRoleEnum.Public,
          commentDelta,
          mediaType: 'AUDIO' as 'AUDIO',
          filePath: processedFilePath,
          fileType: 'audio/mp4',
        });
      } else {
        await onSubmit({
          comment: { blocks: !!blocks?.length ? blocks : comment },
          role: PostCommentBodyReaderRoleEnum.Public,
          commentDelta,
        });
      }

      // we need to clear the editor only if the comment is not editable
      if (!editable) {
        // @ts-ignore
        reactQuillRef.current?.getEditor().setContents('');
      }
    } catch (e) {
      ErrorService.notify('Error creating or updating comment', e);
      dispatch(
        showErrorToast(
          'Something went wrong while posting comment. Please try after some time.',
        ),
      );
    } finally {
      if (editable) {
        setIsSubmitting(false);
        onEdit!(false);
      } else setIsLoading(false);
      setRecordingData(undefined);
    }
  };

  const mention = useCallback(() => {
    reactQuillRef.current?.getEditor()?.getModule('mention').openMenu(' @');
  }, [reactQuillRef]);

  const moreOptions = useCallback(() => {
    setShowMoreOptions(!showMoreOptions);
    reactQuillRef.current?.getEditor()?.getModule('moreOptions');
    setIsGiphyOpen(false);
  }, [showMoreOptions, setShowMoreOptions]);

  const gifs = useCallback(() => {
    setIsGiphyOpen(!isGiphyOpen);
    setShowMoreOptions(false);
  }, [isGiphyOpen, setIsGiphyOpen]);

  const source = useCallback(
    async (searchTerm: string, renderList: any) => {
      const mentions = await getMentions?.(searchTerm);
      const cloneMentions = JSON.parse(JSON.stringify(mentions));
      cloneMentions?.forEach((mention: MentionSource) => {
        mention['value'] = `@${mention?.value}`;
      });

      renderList(cloneMentions);
    },
    [getMentions],
  );

  const renderMentionItem = useCallback(
    (item: any) =>
      ReactDOMServer.renderToStaticMarkup(
        <MentionRow
          name={item.value[0] === '@' ? item.value.substring(1) : item.value}
          emailAddress={item?.emailAddress}
          avatar={agentById[item.id]?.avatar}
        />,
      ),
    [agentById],
  );

  const isEmptyZenCommentWidget = () => {
    const ops = reactQuillRef.current?.getEditor().getContents().ops || [];
    const isEmojiPresent = (ops || []).find(
      (op: Quill.DeltaOperation) => op?.insert?.emoji,
    );
    const isMentionPresent = (ops || []).find(
      (op: Quill.DeltaOperation) => op?.insert?.mention,
    );
    if (ops.length > 0) {
      if (isEmojiPresent || isMentionPresent) {
        return false;
      }

      if (typeof ops[0]?.insert === 'object' && isEmpty(ops[0]?.insert)) {
        return true;
      }

      return !((ops[0]?.insert || '')?.trim() !== '');
    }
    return true;
  };

  const submitHandler = () => {
    if (!isEmptyZenCommentWidget()) {
      if (!isMortgageRoute) {
        const [comment] = getMessage();
        const isMentionPresent = (comment || [])?.some(
          (c: any) => c?.type === 'MENTION',
        );
        const textComments: TextBlock[] = (comment || [])?.filter(
          (c: any) => c?.type === 'TEXT',
        );
        let atMentionCharLength = 0;
        let isLastIndex = false;
        for (let textComment of textComments) {
          atMentionCharLength += (textComment?.text?.match(/@/g) || [])?.length;
          isLastIndex =
            textComment.text?.lastIndexOf('@') ===
            (textComment.text?.length || 1) - 2;
        }
        if (!isMentionPresent) {
          setMentionError('@ mention is required');
          if (atMentionCharLength < 1 || !isLastIndex) {
            mention();
          }

          return;
        } else {
          setMentionError(undefined);
        }
      }
      handleSubmit();
    }
  };

  const getFormats = () => {
    const formats = ['link', 'emoji', 'gifs', 'audio', 'moreOptions'];

    if (!!getMentions) {
      formats.splice(-1, 0, 'mention');
    }

    return formats;
  };

  const getEnabledModules = () => {
    const container = [['emoji'], ['link'], ['gifs']];

    if (isTemplateCommentsEnabled && showTemplateCommentsOption) {
      container.push(['moreOptions']);
    }

    if (!!getMentions) {
      container.splice(1, 0, ['mention']);
    }

    const modules: Quill.StringMap = {
      toolbar: {
        container,
        handlers: { mention, gifs, moreOptions },
      },
      linkify: {
        url: true,
        mail: true,
        phoneNumber: false,
      },
      'emoji-shortname': true,
      'emoji-toolbar': true,
      'emoji-textarea': false,
    };

    if (!!getMentions) {
      modules.mention = {
        allowedChars: /^[A-Za-z0-9\s]*$/,
        mentionDenotationChars: ['@'],
        offsetTop: 20,
        defaultMenuOrientation: 'top',
        showDenotationChar: false,
        dataAttributes: ['id', 'value', 'emailAddress', 'mentionType'],
        renderItem: renderMentionItem,
        source,
      };
    }

    return modules;
  };

  const handleTemplateSelect = async (itembody: string) => {
    setShowMoreOptions(false);
    const editor = reactQuillRef.current?.getEditor();
    editor?.focus();
    const cursorPosition = editor?.getSelection()?.index || 0;
    editor?.insertText(cursorPosition, itembody);
    editor?.setSelection(cursorPosition + itembody.length, 0);
  };

  useEffect(() => {
    return () => {
      if (recordingData?.path) {
        URL.revokeObjectURL(recordingData.path);
      }
    };
  }, [recordingData?.path]);

  const { refresh } = useRefresh();

  return (
    <div
      className={classNames(
        'sticky left-0 right-0 bg-white z-40',
        editable ? 'ml-20' : 'ml-0 ',
        !powerAudit && checklist ? 'bottom-16 pb-6' : 'bottom-0 pb-2.5',
      )}
    >
      <div className='border rounded-lg px-2 py-2 border-zen-dark-6'>
        {editable && (
          <p className='text-gray-500 font-primary-light text-sm'>
            Editing Comment
          </p>
        )}
        {editable ? (
          <div className='zen-edit-comment flex flex-row items-start'>
            <div
              className={cn('w-full relative', editable ? 'mr-0' : '-mr-16')}
            >
              {isGiphyOpen && (
                <SelectGiphy
                  onClickOutside={() => setIsGiphyOpen(false)}
                  onGiphySelect={(gif) => {
                    handleSubmit([
                      {
                        type: 'IMAGE',
                        url: gif.images.original.webp,
                        altText: gif.slug,
                        preview: gif.images.preview.url,
                      },
                    ]);
                    setIsGiphyOpen(false);
                  }}
                />
              )}
              {showMoreOptions && (
                <div className='relative'>
                  <div
                    className='fixed inset-0 bg-black bg-opacity-0 '
                    onClick={() => setShowMoreOptions(false)}
                  />
                  <Popover
                    isOpen
                    positions={
                      powerAudit
                        ? ['right', 'left', 'top', 'bottom']
                        : ['top', 'bottom', 'right', 'left']
                    }
                    align='start'
                    content={
                      <div className='bg-white w-[274px] h-[249px] border border-zen-dark-4 rounded-md shadow-lg'>
                        <ZenMoreOptions
                          handleTemplateSelect={handleTemplateSelect}
                        />
                      </div>
                    }
                  >
                    <div />
                  </Popover>
                </div>
              )}
              <ReactQuill
                ref={reactQuillRef}
                theme='snow'
                defaultValue={defaultValue}
                onKeyDown={(event) => {
                  if (
                    (event.metaKey || event.ctrlKey) &&
                    event.key === 'Enter'
                  ) {
                    submitHandler();
                  }
                }}
                onChange={() => {
                  if (editable) {
                    refresh();
                  }
                }}
                modules={getEnabledModules()}
                formats={getFormats()}
                placeholder='Type something'
              />
              <div className='flex flex-row space-x-2 justify-end p-2 -mt-3'>
                <ZenButton
                  label='Cancel'
                  variant='secondary-gray-outline'
                  onClick={() => onEdit!(false)}
                />
                <button
                  type='button'
                  ref={editSaveButtonRef}
                  onClick={submitHandler}
                  className={cn(
                    'border-2 border-primary-blue bg-primary-blue text-white flex items-center space-x-1 rounded-lg py-2 px-4.5 font-zen-title font-medium disabled:bg-zen-dark-4 disabled:text-white disabled:border-zen-dark-4 disabled:cursor-not-allowed cursor-pointer justify-center',
                    { 'opacity-40 cursor-not-allowed': isLoading },
                  )}
                >
                  {isSubmitting && (
                    <FontAwesomeIcon
                      icon={faRotate}
                      className='animate-spin mx-1'
                    />
                  )}
                  <span className='pt-0.5'>Save</span>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className='zen-create-comment flex flex-row items-start'>
            <div
              className={cn(
                'w-full min-w-0 zen-prevent-overflow',
                isRoarEnabled ? '-mr-[2rem]' : '-mr-16',
                (isRecording || recordingData) && '-mr-[5.5rem]',
                editable && 'mr-8',
              )}
              ref={containerRef}
            >
              {isGiphyOpen && (
                <SelectGiphy
                  onClickOutside={() => setIsGiphyOpen(false)}
                  onGiphySelect={(gif) => {
                    handleSubmit([
                      {
                        type: 'IMAGE',
                        url: gif.images.original.webp,
                        altText: gif.slug,
                        preview: gif.images.preview.url,
                      },
                    ]);
                    setIsGiphyOpen(false);
                  }}
                />
              )}
              {showMoreOptions && (
                <div className='relative'>
                  <div
                    className='fixed inset-0 bg-black bg-opacity-0'
                    onClick={() => setShowMoreOptions(false)}
                  />
                  <Popover
                    isOpen
                    positions={
                      powerAudit
                        ? ['right', 'left', 'top', 'bottom']
                        : ['top', 'bottom', 'right', 'left']
                    }
                    align='start'
                    content={
                      <div className='bg-white w-[274px] h-[249px] border border-zen-dark-4 rounded-md shadow-lg'>
                        <ZenMoreOptions
                          handleTemplateSelect={handleTemplateSelect}
                        />
                      </div>
                    }
                  >
                    <div />
                  </Popover>
                </div>
              )}
              <ReactQuill
                ref={reactQuillRef}
                theme='snow'
                defaultValue={defaultValue}
                onKeyDown={(event) => {
                  if (
                    (event.metaKey || event.ctrlKey) &&
                    event.key === 'Enter'
                  ) {
                    submitHandler();
                  }
                }}
                onChange={() => {
                  if (!editable) {
                    refresh();
                  }
                }}
                modules={getEnabledModules()}
                formats={getFormats()}
                placeholder='Type something'
              />
            </div>
          </div>
        )}
        {!editable && (
          <div
            ref={containerActionRef}
            className={cn(
              'flex justify-end items-end z-0',
              isRoarEnabled && 'items-center space-x-3',
            )}
          >
            {!hideVoiceMemo && (
              <FeatureFlagEnabledOnly
                flag={FeatureFlagTypeEnum.ROAR_VOICE_MEMO}
              >
                {isRecording || recordingData ? (
                  <div className='w-4/5'>
                    {recordingData ? (
                      <RecordMemoPreview
                        path={recordingData.path}
                        duration={recordingData.duration}
                        onClose={() => {
                          setRecordingData(undefined);
                        }}
                      />
                    ) : (
                      <RecordMemo
                        onCancel={() => {
                          setIsRecording(false);
                          setRecordingData(undefined);
                        }}
                        onRecordingFinish={(data) => {
                          setIsRecording(false);
                          setRecordingData(data);
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        setIsRecording(true);
                      }}
                      aria-label='record-audio'
                    >
                      <FontAwesomeIcon
                        icon={faMicrophone}
                        fontSize={20}
                        className='cursor-pointer'
                      />
                    </button>
                    <div className='w-0.5 bg-regent-300 h-10' />
                  </>
                )}
              </FeatureFlagEnabledOnly>
            )}
            <button
              type='button'
              ref={submitButtonRef}
              onClick={submitHandler}
              disabled={isLoading || isRecording || isEmptyZenCommentWidget()}
              className={cn(
                'pb-1 flex items-center gap-x-1 font-primary-regular text-lg text-white disabled:bg-zen-dark-4 disabled:opacity-40 disabled:cursor-not-allowed cursor-pointer bg-primary-blue px-3 py-1 rounded-md ',
                {
                  'opacity-40 cursor-not-allowed':
                    isLoading || isRecording || isEmptyZenCommentWidget(),
                },
              )}
            >
              <FontAwesomeIcon icon={faPaperPlaneTop} />
              <span className='pl-2 pt-0.5'>Send</span>
            </button>
          </div>
        )}
      </div>
      {mentionError && (
        <div className='mt-2'>
          <ZenFormErrorMessage message={mentionError} />
        </div>
      )}
    </div>
  );
};

export default ZenCommentWidget;
