import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import SidebarModalForm from '../SidebarModalForm';
import ControlledTextInputV7 from '../ControlledTextInputV7';
import { AgentResponse, AgentControllerApi } from '../../openapi/yenta';
import { UserControllerApi } from '../../openapi/hermes';
import {
  getHermesConfiguration,
  getYentaConfiguration,
} from '../../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import ErrorService from '../../services/ErrorService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import { AppDispatch } from '../../types';
import config from '../../config';
import { saveDetail } from '../../slices/AgentSlice';
import { WHITESPACE_REGEX } from '../../utils/StringUtils';

interface FormData {
  joinRealEmail: string;
}

interface EditAgentJoinRealEmailSidebarModelProps {
  agentDetail: AgentResponse;
  isOpen: boolean;
  onClose(): void;
}

const EditAgentJoinRealEmailSidebarModal: React.FC<EditAgentJoinRealEmailSidebarModelProps> = ({
  agentDetail,
  isOpen,
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({ mode: 'onChange', reValidateMode: 'onChange' });

  const onSubmit = async (formValues: FormData) => {
    try {
      const { data } = await new AgentControllerApi(
        getYentaConfiguration(),
      ).updateJoinRealEmail(agentDetail.id!, {
        newJoinRealEmail: `${formValues.joinRealEmail.trim()}@${
          config.joinRealEmailDomain
        }`,
      });
      dispatch(saveDetail({ name: 'detail', loading: false, data }));
      dispatch(showSuccessToast('Successfully updated the joinreal email.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to update the joinreal email', e, {
        agent: { id: agentDetail.id },
      });
      dispatch(
        showErrorToast(
          'We had a problem updating the joinreal email',
          'Please try again in a few moments.',
        ),
      );
    }
    onClose();
  };

  const index = agentDetail.joinRealEmail?.indexOf('@');
  const joinRealEmail = agentDetail.joinRealEmail?.substring(0, index);

  return (
    <SidebarModalForm
      isOpen={isOpen}
      onClose={onClose}
      isSubmitting={isSubmitting}
      title='Edit JoinReal Email'
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Save'
    >
      <ControlledTextInputV7<FormData, 'joinRealEmail'>
        name='joinRealEmail'
        control={control}
        type='text'
        defaultValue={joinRealEmail}
        endAdornment={
          <span className='flex items-center h-full px-4 bg-gray-100'>
            <p>@{config.joinRealEmailDomain}</p>
          </span>
        }
        rules={{
          required: 'Required',
          validate: async (value) => {
            if (value.includes('@')) {
              return "Don't include @ symbol and the domain name";
            }

            if (WHITESPACE_REGEX.test(value?.trim())) {
              return 'Cannot contain any whitespace.';
            }

            try {
              const { data: emailAddress } = await new UserControllerApi(
                getHermesConfiguration(),
              ).isJoinRealEmailAvailable(
                `${value.trim()}@${config.joinRealEmailDomain}`,
              );
              return !emailAddress.available
                ? 'Email is already taken'
                : undefined;
            } catch (e) {
              ErrorService.notify(
                'Unable to verify if the joinreal email is valid or not',
                e,
                {
                  agent: { id: agentDetail.id },
                },
              );
              return "Couldn't verify the email. Please try again later.";
            }
          },
        }}
      />
    </SidebarModalForm>
  );
};

export default EditAgentJoinRealEmailSidebarModal;
