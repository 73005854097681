import classNames from 'classnames';
import React from 'react';

interface ZenCustomSwitchProps {
  label?: string;
  rightIcon?: string | React.ReactElement;
  leftIcon?: string | React.ReactElement;
  toggle: boolean;
  onClick?(): void;
  disabled?: boolean;
  testId?: string;
}

const ZenCustomSwitch: React.FC<ZenCustomSwitchProps> = ({
  label = '',
  toggle,
  leftIcon,
  rightIcon,
  onClick,
  disabled,
  testId,
}) => {
  return (
    <div
      className={classNames(
        'flex flex-row space-x-3 cursor-pointer items-center',
        {
          'cursor-not-allowed': disabled,
        },
      )}
      data-testid={testId || 'toggle-switch'}
      onClick={() => onClick && !disabled && onClick()}
    >
      {!!label && <p>{label}</p>}
      <button
        type='button'
        data-testid='toggle-switch-button'
        className={classNames(
          'relative inline-flex flex-shrink-0 h-6 w-11 px-1 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ',
          toggle ? 'bg-primary-blue' : 'bg-gray-200',
          {
            'cursor-not-allowed': disabled,
          },
        )}
        disabled={disabled}
      >
        <span
          className={classNames(
            'translate-x-0 pointer-events-none relative inline-block h-3.5 w-3.5 self-center rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            toggle ? 'translate-x-5' : 'translate-x-0',
          )}
        >
          <span
            className={classNames(
              'ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
              toggle
                ? 'opacity-0 ease-out duration-100'
                : 'opacity-100 ease-in duration-200',
            )}
          >
            <div
              className={classNames(
                'text-gray-400',
                'text-xs',
                toggle ? 'hidden' : 'flex',
              )}
              data-testid='toggle-switch-left-icon'
            >
              {leftIcon}
            </div>
          </span>
          <span
            className={classNames(
              'ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
              toggle
                ? 'opacity-100 ease-in duration-200'
                : 'opacity-0 ease-out duration-100',
            )}
          >
            <div
              className={classNames(
                'text-gray-400',
                'text-xs',
                toggle ? 'flex' : 'hidden',
              )}
              data-testid='toggle-switch-right-icon'
            >
              {rightIcon}
            </div>
          </span>
        </span>
      </button>
    </div>
  );
};

export default ZenCustomSwitch;
