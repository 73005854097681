import { Fragment } from 'react';
import { AvatarSizeType, EnumMap } from '../../types';
import { getFullName } from '../../utils/AgentHelper';
import Avatar from '../Avatar';
import Hover from '../Hover';
import { cn } from '../../utils/classUtils';
import { ReadReceiptProps } from '../ZenCommentWidget/ZenRichCommentRow';

export interface ReadReceiptsListProps {
  users: ReadReceiptProps[];
  numToDisplay?: number;
  variant?: AvatarSizeType;
  displayOverflow?: boolean;
}

const ReadReceiptsList: React.FC<ReadReceiptsListProps> = ({
  users,
  numToDisplay = 2,
  variant = 'xs',
  displayOverflow = true,
}) => {
  const avatarSizeToClassNameMap: EnumMap<AvatarSizeType, string> = {
    xxs: 'h-6 w-6',
    xs: 'h-8 w-8',
    sm: 'h-10 w-10',
    lg: 'h-20 w-20',
    xl: 'h-28 w-28',
  };

  const marginLeft = numToDisplay === 1 || users.length === 1 ? '' : '-ml-4';

  const renderAllUserList = () => {
    return (
      <div className='flex flex-col'>
        {users.map((user) => {
          return (
            <a
              key={user.id}
              className='text-blue-700'
              href={`/people/${user.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <div className='flex flex-row items-center space-x-2 p-2'>
                <Avatar
                  name={`${user.firstName} ${user.lastName}`}
                  imageUrl={user?.imageUrl}
                  size='xxs'
                />
                <p>{`${user.firstName} ${user.lastName}`}</p>
              </div>
            </a>
          );
        })}
      </div>
    );
  };
  return (
    <div className='flex items-center ml-4' data-testid='read-receipts'>
      <div className='flex'>
        {users.slice(0, numToDisplay).map((user, index) => {
          return (
            user.id && (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`${user?.imageUrl || user.firstName}-${index}`}>
                <div
                  className={cn(
                    'mr-2 rounded-full hover:z-10 mt-0.5 ring-2 ring-white',
                    marginLeft,
                  )}
                >
                  <Hover
                    hoverComponent={
                      <a
                        className='text-blue-700'
                        href={`/people/${user.id}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <div className='flex flex-row items-center space-x-2 p-2'>
                          <Avatar
                            name={`${user.firstName} ${user.lastName}`}
                            imageUrl={user?.imageUrl}
                            size='xxs'
                          />
                          <p>{`${user.firstName} ${user.lastName}`}</p>
                        </div>
                      </a>
                    }
                    config={{ trigger: 'hover', placement: 'top' }}
                  >
                    <Avatar
                      name={getFullName(user)!}
                      imageUrl={user?.imageUrl}
                      size='xxs'
                    />
                  </Hover>
                </div>
              </Fragment>
            )
          );
        })}

        {displayOverflow && users.length > numToDisplay && (
          <Hover
            hoverComponent={renderAllUserList()}
            config={{ trigger: 'hover', placement: 'top' }}
          >
            <span
              className={cn(
                'flex items-center justify-center font-semibold text-gray-600  rounded-full bg-gray-200 border-4 border-white -ml-4',
                avatarSizeToClassNameMap[variant],
              )}
            >
              +{users.length - numToDisplay}
            </span>
          </Hover>
        )}
      </div>
    </div>
  );
};

export default ReadReceiptsList;
