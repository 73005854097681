import { cn } from '../utils/classUtils';

export type PageAnnouncementVariant =
  | 'default'
  | 'success'
  | 'warning'
  | 'danger';

export interface PageAnnouncementProps {
  titleIcon: React.ReactElement;
  title: string;
  content?: string;
  show: boolean;
  variant: PageAnnouncementVariant;
  RightComponent?: React.FC | React.ReactNode;
}

const ZenPageAnnouncement: React.FC<PageAnnouncementProps> = ({
  titleIcon,
  title,
  content,
  show,
  variant = 'default',
  RightComponent,
}) => {
  const variantClassMap: { [x in PageAnnouncementVariant]: string } = {
    default: 'bg-primary-blue text-primary-blue',
    danger: 'bg-zen-danger text-zen-danger',
    success: 'bg-green-600 text-green-600',
    warning: 'bg-zen-warning text-zen-warning',
  };

  if (!show) {
    return null;
  }

  return (
    <div className='border border-solid rounded-xl border-zen-warning-3 bg-zen-warning-1 mx-6'>
      <div className='flex flex-col lg:flex-row flex-wrap lg:items-center py-2 px-4 relative'>
        <div
          className={cn(
            'flex items-center justify-center py-1 px-2 bg-opacity-10 rounded lg:mr-3 mb-2 lg:mb-0',
            variantClassMap[variant],
          )}
        >
          {titleIcon}
          <span className='ml-1 font-zen-body font-semibold text-base'>
            {title}
          </span>
        </div>
        <p className='flex-1 font-zen-body font-semibold text-base'>
          {content}
        </p>
        {RightComponent}
      </div>
    </div>
  );
};

export default ZenPageAnnouncement;
