import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import DebtTabComponent, {
  ACTIVE_TAB_STATUS,
  HISTORY_TAB_STATUS,
} from '../components/AgentOwesReal/DebtTabComponent';
import ZenButton from '../components/Zen/ZenButton';
import ZenTabs from '../components/Zen/ZenTabs';
import { AnalyticsEventEnum } from '../types';
import AnalyticsService from '../services/AnalyticsService';
import DebtAddEditForm from '../components/AgentOwesReal/DebtAddEditForm';
import { AgentDebtResponse } from '../openapi/arrakis';
import AdminOnly from '../components/auth/AdminOnly';
import { changeCreateInvoiceLoading } from '../slices/AgentSlice';

interface Match {
  id: string;
}

interface AllDebtsRouteProps extends RouteComponentProps<Match> {}

const AllDebtsRoute: React.FC<AllDebtsRouteProps> = ({ match }) => {
  const { id: agentId } = match.params;
  const [currentTab, setCurrentTab] = useState('Active');
  const [
    isAddEditDebtsSidebarOpen,
    setIsAddEditDebtsSidebarOpen,
  ] = useState<boolean>(false);
  const [agentDebtInfo, setAgentDebtInfo] = useState<AgentDebtResponse>();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(changeCreateInvoiceLoading({}));
    };
  }, [dispatch]);

  return (
    <div className='p-4.5'>
      <div className='flex flex-row items-start justify-between'>
        <h1 className='font-zen-body font-semibold text-xl text-black mb-4'>
          Debts
        </h1>
        <AdminOnly>
          <ZenButton
            LeftIconComponent={
              <FontAwesomeIcon icon={faPlus} size='sm' className='mx-1 pt-1' />
            }
            label='Add Debt'
            variant='primary'
            onClick={() => {
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.AGENT_OWES_REAL_ADD_DEBT_BUTTON_CLICK,
              );
              setIsAddEditDebtsSidebarOpen(true);
            }}
          />
        </AdminOnly>
      </div>

      <ZenTabs
        tabs={[
          {
            name: 'Active',
            TabComponent: (
              <DebtTabComponent
                agentId={agentId}
                status={ACTIVE_TAB_STATUS}
                setAgentDebtInfo={setAgentDebtInfo}
                setIsAddEditDebtsSidebarOpen={setIsAddEditDebtsSidebarOpen}
              />
            ),
          },
          {
            name: 'History',
            TabComponent: (
              <DebtTabComponent
                agentId={agentId}
                status={HISTORY_TAB_STATUS}
                setAgentDebtInfo={setAgentDebtInfo}
                setIsAddEditDebtsSidebarOpen={setIsAddEditDebtsSidebarOpen}
              />
            ),
          },
        ]}
        selected={currentTab}
        onChange={setCurrentTab}
        size='sm'
      />
      {isAddEditDebtsSidebarOpen && (
        <DebtAddEditForm
          isOpen
          onClose={() => {
            setIsAddEditDebtsSidebarOpen(false);
            setAgentDebtInfo(undefined);
          }}
          agentDebt={agentDebtInfo}
          agentId={agentId}
          currentTab={currentTab}
        />
      )}
    </div>
  );
};

export default AllDebtsRoute;
