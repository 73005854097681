import { faCircleCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';
import {
  faExclamation,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommissionDocumentResponseStatusEnum } from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenPill, { ZenPillVariant } from '../../Zen/ZenPill';

export interface ZenCommissionDocumentStatusCellProps {
  status: CommissionDocumentResponseStatusEnum;
}

export interface CommissionDocumentStatusCellProps {
  status: CommissionDocumentResponseStatusEnum;
}

const statusToVariant: EnumMap<
  CommissionDocumentResponseStatusEnum,
  ZenPillVariant
> = {
  [CommissionDocumentResponseStatusEnum.Approved]: 'success',
  [CommissionDocumentResponseStatusEnum.Invalidated]: 'danger',
  [CommissionDocumentResponseStatusEnum.Pending]: 'warning',
  [CommissionDocumentResponseStatusEnum.Uninitialized]: 'primary',
  [CommissionDocumentResponseStatusEnum.Closing]: 'primary',
};

const statusToIcon: EnumMap<
  CommissionDocumentResponseStatusEnum,
  React.ReactElement
> = {
  [CommissionDocumentResponseStatusEnum.Invalidated]: (
    <FontAwesomeIcon icon={faXmark} />
  ),
  [CommissionDocumentResponseStatusEnum.Approved]: (
    <FontAwesomeIcon icon={faCircleCheck} />
  ),
  [CommissionDocumentResponseStatusEnum.Pending]: (
    <FontAwesomeIcon icon={faTriangleExclamation} />
  ),
  [CommissionDocumentResponseStatusEnum.Uninitialized]: (
    <FontAwesomeIcon icon={faExclamation} />
  ),
  [CommissionDocumentResponseStatusEnum.Closing]: (
    <FontAwesomeIcon icon={faCircleCheck} />
  ),
};

const ZenCommissionDocumentStatusCell: React.FC<CommissionDocumentStatusCellProps> = ({
  status,
}) => {
  const variant: ZenPillVariant = safeEnumMapGet(
    statusToVariant,
    status,
    'default',
  );
  const icon: React.ReactElement = safeEnumMapGet(
    statusToIcon,
    status,
    statusToIcon[CommissionDocumentResponseStatusEnum.Uninitialized],
  );

  return (
    <ZenPill
      title={capitalizeEnum(status)}
      variant={variant!}
      leftIcon={icon!}
    />
  );
};

export default ZenCommissionDocumentStatusCell;
