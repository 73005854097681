import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import {
  MoneyValue,
  MoneyValueCurrencyEnum,
} from '../../../../openapi/arrakis';
import { RootState } from '../../../../types';
import { MONEY_VALIDATIONS } from '../../../../utils/Validations';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledDatePickerInput from '../../Input/ZenControlledDatePickerInput';
import ZenControlledFormattedMoneyInput from '../../Input/ZenControlledFormattedMoneyInput';
import ZenStickyBottomPagination from '../../ZenStickyBottomPagination';
import withZenCreateReferralTransactionProgress from './withZenCreateReferralTransactionProgress';
import {
  ReferralTransactionFormState,
  ReferralTransactionStepName,
} from './ZenReferralTransactionSteps';

const ZenDealInformationStep: StepByStepComponent<
  ReferralTransactionFormState,
  ReferralTransactionStepName
> = ({ form: { control }, onPrevious, onNext }) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div>
          <p className='text-xl font-zen-body font-semibold text-zen-dark-9 mb-4'>
            Deal Information
          </p>
          <div className='space-y-5 mb-5'>
            <div>
              <ZenControlledFormattedMoneyInput<
                ReferralTransactionFormState,
                'price'
              >
                control={control}
                label={`Expected Referral Amount (${userDetail?.defaultCurrency})`}
                name='price'
                shouldUnregister={false}
                placeholder='e.g. 1,250'
                rules={{
                  ...MONEY_VALIDATIONS,
                  validate: (val: MoneyValue) =>
                    !val?.amount
                      ? 'Please provide Expected Referral Amount'
                      : undefined,
                }}
                currencyReadOnly
                defaultValue={{
                  currency: (userDetail?.defaultCurrency as unknown) as MoneyValueCurrencyEnum,
                }}
                isRequired
              />
            </div>
            <div>
              <ZenControlledDatePickerInput<
                ReferralTransactionFormState,
                'expectedClosingDate'
              >
                control={control}
                label='Approximate Closing Date'
                name='expectedClosingDate'
                shouldUnregister={false}
                placeholder='MM/DD/YYYY'
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    title='calendar'
                    className='text-gray-700 mb-0.5'
                    size='lg'
                  />
                }
                rules={{
                  required: 'Please provide Expected Closing Date',
                }}
                isRequired
              />
            </div>
          </div>
        </div>
      </div>

      <ZenStickyBottomPagination
        maxWidth='2xl'
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </div>
  );
};

export default withZenCreateReferralTransactionProgress(ZenDealInformationStep);
