import { useSelector } from 'react-redux';
import {
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps,
} from '../../routes/MSDynamicsOnboardingRoute';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import ZenButton from '../Zen/ZenButton';
import ZenVerticalStepProgress from '../Zen/ZenVerticalStepProgress';
import { RootState } from '../../types';
import WithMsDynamicsProgress from './WithMsDynamicOnboardingProgress';

const MsDynamicsOnboardingIntro: StepByStepComponent<
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps
> = ({ onNext }) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  return (
    <div className='mt-10 w-full max-w-3xl mb-40 flex flex-col md:flex-row md:items-start md:justify-between'>
      <div className='flex-shrink-0 hidden md:block'>
        <ZenVerticalStepProgress
          steps={[
            { name: 'CONTACT INFORMATION' },
            { name: 'SETUP PAYOUT METHOD' },
            { name: 'TAX INFORMATION' },
          ]}
          inProgressIndex={0}
        />
      </div>
      <div className='w-full max-w-md space-y-5'>
        <p className='font-zen-body font-semibold text-zen-dark-9 text-xl'>
          Hi {userDetail?.fullName},
        </p>
        <p className='font-zen-body text-base font-normal text-zen-dark-9'>
          Please take a moment to provide tax, payment and bank account
          information.
        </p>
        <div className='font-zen-body text-base font-normal text-zen-dark-9'>
          <p className='mb-1'>You will need:</p>
          <ul className='pl-9 space-y-1 list-disc'>
            <li>Contact information, such as legal name and address</li>
            <li>
              Bank account information, such as account number, routing number,
              type of bank account
            </li>
            <li>Taxpayer IDs</li>
          </ul>
        </div>
        <div className='md:hidden pb-2'>
          <ZenVerticalStepProgress
            steps={[
              { name: 'CONTACT INFORMATION' },
              { name: 'SETUP PAYOUT METHOD' },
              { name: 'TAX INFORMATION' },
            ]}
            inProgressIndex={0}
          />
        </div>
        <div className='mt-2'>
          <ZenButton label="Let's start" onClick={onNext} isFullWidth />
        </div>
      </div>
    </div>
  );
};

export default WithMsDynamicsProgress(MsDynamicsOnboardingIntro);
