import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form-v7';
import { cn } from '../../../utils/classUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';

interface ZenControlledCheckboxV2Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  disabled?: boolean;
  labelStyles?: string;
}

const ZenControlledCheckboxV2 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  disabled,
  labelStyles,
  ...rest
}: ZenControlledCheckboxV2Props<TFieldValues, TName>) => {
  const {
    field: { name, onBlur, onChange, ref, value },
    fieldState: { invalid, error },
  } = useController(rest);
  return (
    <div>
      <div className='flex items-center'>
        <input
          type='checkbox'
          name={name}
          checked={value}
          disabled={disabled}
          onChange={(event) => onChange(event.target.checked)}
          onBlur={onBlur}
          ref={ref}
          data-testid={name}
          className={cn(
            'w-6 h-6 border-2 border-zen-dark-4 rounded focus:outline-none focus:ring-0 cursor-pointer',
            invalid && 'border-red-600',
            disabled && 'cursor-not-allowed bg-gray-50 text-gray-200',
          )}
        />
        {!!label && (
          <label
            htmlFor={name}
            className={cn(
              'font-zen-body font-normal text-zen-dark-9 ml-4',
              labelStyles,
            )}
          >
            {label}
          </label>
        )}
      </div>
      {error && <ZenFormErrorMessage message={error.message} />}
    </div>
  );
};

export default ZenControlledCheckboxV2;
