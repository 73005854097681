import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import MainFooter from '../containers/MainFooter';
import MainSidebar from '../containers/MainSidebar';
import RenderRoleBasedComponent, {
  RouteComponentUserRoleMappingConfig,
} from '../containers/RenderRoleBasedComponent';
import UseBrokerQueue from '../hooks/useBrokerQueue';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { AgentResponseAgentStatusEnum } from '../openapi/yenta';
import { FeatureFlagTypeEnum, RootState } from '../types';
import Logger from '../utils/Logger';
import { isTransactionDetailRouteActive } from '../utils/TransactionUtils';
import { getLoginURLWithRedirection } from '../utils/NavigationHelper';
import AgentICADocumentSignatureModalIndex from './Agent/ICADocumentsV2/AgentICADocumentModalIndex';
import BrokerQueueEmptyRoute from './BrokerQueueEmptyRoute';
import FutureMaintenanceAlert from './FutureMaintenanceAlert';
import JointVentureVideoModal from './JointVenture/JointVentureVideoModal';
import BreakRoomIndex from './Kbar/BreakRoom/BreakRoomIndex';
import KBar from './Kbar/KBar';
import Announcement from './Zen/announcement/Announcement';
import LeoIndex from './Zen/Leo/LeoIndex';
import DesignateBeneficiaryModalIndex from './Zen/RevenueShare/V2/DesignateBeneficiaryModalIndex';

export interface AuthenticatedSidebarRouteProps
  extends Omit<RouteProps, 'render'> {
  config?: RouteComponentUserRoleMappingConfig;
  hideFutureMaintenanceAlert?: boolean;
}

const AuthenticatedSidebarRoute: React.FC<AuthenticatedSidebarRouteProps> = ({
  component: Component,
  config,
  hideFutureMaintenanceAlert = false,
  ...rest
}) => {
  const {
    auth: { userDetail, keymakerCurrentUser, isMortgageUserRole },
  } = useSelector((state: RootState) => state);
  const { isBrokerQueueActive } = UseBrokerQueue();
  const showBrokerQueueEmptyPage =
    isBrokerQueueActive && !isTransactionDetailRouteActive();

  const isRealTitleEnabled = useFeatureFlag(FeatureFlagTypeEnum.REAL_TITLE);

  return (
    <KBar>
      <Route
        {...rest}
        render={(props) => {
          if (!userDetail) {
            Logger.debug('Not logged in. Redirecting to /login');
            return <Redirect to={getLoginURLWithRedirection()} />;
          }

          if (
            userDetail?.agentStatus === AgentResponseAgentStatusEnum.Candidate
          ) {
            Logger.debug(
              'AuthenticatedSidebarRoute: user is candidate, going to application form',
            );
            return <Redirect to='/onboarding/application-form' />;
          }
          if (keymakerCurrentUser?.forceMfa && !keymakerCurrentUser?.mfaType) {
            Logger.debug(
              'AuthenticatedSidebarRoute: user has not enabled two factor authentication, going to enable 2FA page',
            );
            return <Redirect to='/enable-2fa' />;
          }

          return (
            <div className='flex flex-col lg:flex-row min-h-screen'>
              <MainSidebar />
              <div
                id='main-page'
                className='flex-grow lg:max-h-screen scrollbar overflow-y-auto overflow-x-hidden'
              >
                <div className='relative min-h-[calc(100vh_-_50px)]'>
                  {!isMortgageUserRole && <LeoIndex />}
                  {!hideFutureMaintenanceAlert && <FutureMaintenanceAlert />}
                  {showBrokerQueueEmptyPage ? (
                    <BrokerQueueEmptyRoute />
                  ) : !!config ? (
                    <RenderRoleBasedComponent config={config} {...props} />
                  ) : Component ? (
                    <Component {...props} />
                  ) : null}
                </div>
                <MainFooter />
              </div>
            </div>
          );
        }}
      />
      <AgentICADocumentSignatureModalIndex />
      {!isMortgageUserRole && <DesignateBeneficiaryModalIndex />}
      {isRealTitleEnabled && <JointVentureVideoModal />}
      <Announcement />
      <BreakRoomIndex />
    </KBar>
  );
};

export default AuthenticatedSidebarRoute;
