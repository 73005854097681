import {
  AttachedFeeResponse,
  AttachedFeeResponseFeeTypeEnum,
  CommissionDocumentResponse,
  CommissionDocumentResponseTransactionRepresentationTypeEnum,
} from '../openapi/arrakis';
import { capitalizeEnum } from './StringUtils';
import { getPriorityWiseSortedData } from './TableUtils';

export const getCdaAdditionalFeeTypeColValue = (
  fee: AttachedFeeResponse,
): string => {
  if (!fee.feeType?.length) {
    return '';
  }
  let value = capitalizeEnum(fee.feeType).toUpperCase();
  if (fee.recipientType) {
    value += ' TO ' + fee.recipientType;
  }
  return value;
};

export const isCdaListingSideOrDualRepresentation = (
  cda: CommissionDocumentResponse,
): boolean => {
  return (
    cda.transactionRepresentationType ===
      CommissionDocumentResponseTransactionRepresentationTypeEnum.Seller ||
    cda.transactionRepresentationType ===
      CommissionDocumentResponseTransactionRepresentationTypeEnum.Dual
  );
};

export const getCdaAdditionalFees = (
  cda: CommissionDocumentResponse,
): AttachedFeeResponse[] | undefined => {
  return cda.attachedOrAdditionalFees
    ?.filter((fee) => {
      switch (fee.feeType) {
        case AttachedFeeResponseFeeTypeEnum.Rebate:
        case AttachedFeeResponseFeeTypeEnum.AdditionalCommission:
        case AttachedFeeResponseFeeTypeEnum.Reimbursement:
          return true;
        default:
          return false;
      }
    })
    .sort((a, b) =>
      getPriorityWiseSortedData(a.feeType!, b.feeType!, [
        AttachedFeeResponseFeeTypeEnum.Rebate,
        AttachedFeeResponseFeeTypeEnum.AdditionalCommission,
        AttachedFeeResponseFeeTypeEnum.Reimbursement,
      ]),
    );
};
