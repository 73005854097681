export interface EmptyConveyanceProps {
  children?: React.ReactElement[];
}

const EmptyConveyance: React.FC<EmptyConveyanceProps> = ({ children }) => {
  return (
    <div className='w-full h-full p-5 flex flex-col items-center justify-center text-gray-500'>
      {children}
    </div>
  );
};

export default EmptyConveyance;
