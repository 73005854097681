import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ReferralCentralReferralDto,
  ReferralCentralReferralDtoStatusEnum,
} from '../../openapi/arrakis';
import { RootState } from '../../types';
import { isStringEmpty } from '../../utils/StringUtils';
import MyReferralCardHeader from './components/MyReferralCardHeader';
import PublishOrPauseReferral from './components/PublishOrPauseReferral';
import ReferralAddressTimelineStatus from './components/ReferralAddressTimelineStatus';
import ReferralCardActions from './components/ReferralCardActions';
import ReferralContactActions from './components/ReferralContactActions';
import ReferralInfo from './components/ReferralInfo';
import ReferralSelectedAgent from './components/ReferralSelectedAgent';
import ReferralDetailAgentSelectedSidebar from './sidebars/ReferralDetailAgentSelectedSidebar';
import ReferralDetailUnpublishedSidebar from './sidebars/ReferralDetailUnpublishedSidebar';

interface MyReferralCreatedCardProps {
  referralDetails: ReferralCentralReferralDto;
}

const MyReferralCreatedCard: React.FC<MyReferralCreatedCardProps> = ({
  referralDetails,
}) => {
  const [isPublished, setIsPublished] = useState(
    referralDetails.status === ReferralCentralReferralDtoStatusEnum.Posted,
  );
  const [isDetailSidebarOpen, setDetailSidebarOpen] = useState<boolean>(false);
  const [
    isAgentSelectedSidebarOpen,
    setAgentSelectedSidebarOpen,
  ] = useState<boolean>(false);
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  const isReceivingAgent = userDetail?.id === referralDetails.receivingAgentId;
  const isReferralAgent = userDetail?.id === referralDetails.referralAgentId;
  const isApplicant = referralDetails.applicantAgentIds?.some(
    (applicant) => applicant.agentId === userDetail?.id,
  )!;

  const openSidebar = () => {
    if (!isStringEmpty(referralDetails.receivingAgentId)) {
      setAgentSelectedSidebarOpen(true);
    } else {
      setDetailSidebarOpen(true);
    }
  };

  return (
    <div className='border border-grey-300 rounded-xl font-inter h-[19.1rem]'>
      <MyReferralCardHeader referralDetails={referralDetails} />
      <div className='flex flex-col justify-between border border-grey-300 border-b-0 shadow-top-lg rounded-xl h-[85.2%] -mx-px'>
        <div className='pl-3 pr-1 py-2'>
          <div className='flex flex-row items-center justify-between'>
            <ReferralInfo
              clientRole={referralDetails.clientType!}
              maximumPrice={referralDetails.priceRange?.maximum?.amount!}
              minimumPrice={referralDetails.priceRange?.minimum?.amount!}
              referralFee={referralDetails.referralFeePercentage!}
            />
            <ReferralContactActions
              selectedAgent={referralDetails.receivingAgentId!}
              moreActions={
                !isApplicant ||
                referralDetails.status ===
                  ReferralCentralReferralDtoStatusEnum.Posted ||
                referralDetails.status ===
                  ReferralCentralReferralDtoStatusEnum.Paused
              }
            />
          </div>
          <ReferralAddressTimelineStatus
            address={referralDetails.locations!}
            applicants={referralDetails.applicantAgentIds!}
            status={referralDetails.status}
            timeline={referralDetails.timeline!}
            personalNickname={referralDetails.nickname}
          />
        </div>
        <div>
          <ReferralCardActions
            isApplicant={isApplicant}
            isReceivingAgent={isReceivingAgent}
            isReferralAgent={isReferralAgent}
            referralDetails={referralDetails}
            setIsReferralSidebarOpen={openSidebar}
          />

          {(isReferralAgent || isReceivingAgent) &&
          referralDetails.status !==
            ReferralCentralReferralDtoStatusEnum.Paused &&
          referralDetails.status !==
            ReferralCentralReferralDtoStatusEnum.Posted ? (
            <ReferralSelectedAgent
              receivingAgentId={referralDetails?.receivingAgentId!}
            />
          ) : (
            <PublishOrPauseReferral
              isPublished={isPublished}
              setIsPublished={setIsPublished}
            />
          )}
        </div>
      </div>
      <ReferralDetailUnpublishedSidebar
        referralDetails={referralDetails}
        isOpen={isDetailSidebarOpen}
        onClose={() => setDetailSidebarOpen(false)}
      />
      <ReferralDetailAgentSelectedSidebar
        referralDetails={referralDetails}
        isOpen={isAgentSelectedSidebarOpen}
        onClose={() => setAgentSelectedSidebarOpen(false)}
      />
    </div>
  );
};

export default MyReferralCreatedCard;
