import { cn } from '../../utils/classUtils';

type ClassNameMap = Record<string, string | undefined> | undefined;

export const forceImportant = (value: string | undefined) =>
  value
    ?.split(' ')
    .map((v) => (v.includes(':') ? v : `!${v}`))
    .join(' ');

export const applyImportant = <T extends Partial<ClassNameMap> | undefined>(
  classnames: T,
): T => {
  if (!classnames) {
    return {} as T;
  }
  const result = {} as T;
  if (!result) {
    return {} as T;
  }
  for (const [key, value] of Object.entries(classnames)) {
    result[key] = forceImportant(value);
  }
  return result as T;
};

/**
 * The function combines default classnames with custom classnames.
 * If a custom classnames is provided, it will overwrite the default classname.
 *
 * example:
 *  Default classnames: { button: 'bg-primary-blue text-blue-100' }
 *  Custom classnames: { button: 'bg-red-600', input: 'text-primary-dark' }
 *  Result: { button: 'bg-red-600 text-blue-100', input: 'text-primary-dark' }
 */
export const combineDefaultClassnamesWithCustomClassnames = <
  T extends Partial<ClassNameMap> | undefined
>(
  defaults: T,
  ...custom: T[]
): T => {
  if (!defaults) {
    return (
      custom?.reduce<T>((acc, current) => ({ ...acc, ...current }), {} as T) ??
      ({} as T)
    );
  }
  if (!custom || custom.length === 0) {
    return defaults;
  }

  const combined = Object.keys(defaults).reduce<T>((acc, key: unknown) => {
    const typedKey = key as keyof T;
    const customValue = custom.reduce<string | undefined>(
      (combinedValue, current) => {
        return cn(combinedValue, current?.[typedKey]);
      },
      undefined,
    );
    return {
      ...acc,
      // Defaults should be first so custom can overwrite them
      [typedKey]: cn(defaults?.[typedKey], customValue),
    };
  }, {} as T);

  const customMerged = custom.reduce<T>(
    (acc, current) => ({ ...acc, ...current }),
    {} as T,
  );

  return {
    ...customMerged,
    // Since custom has been merged with defaults, we want to merge the combined result last
    ...combined,
  };
};
