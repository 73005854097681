import classNames from 'classnames';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDoubleUp } from '@fortawesome/pro-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { openLeo, saveIsLeoModalVisible } from '../../../slices/LeoSlice';
import { AppDispatch, RootState } from '../../../types';
import LeoIdle from '../../../assets/img/leo/leo-idle.gif';
import './LeoIndex.css';
import LeoModalContainer from './LeoModalContainer';
import LeoChatPrompts from './Chat/LeoChatPrompts';
import useShowLeoTrigger from './useShowLeoTrigger';

interface LeoIndexProps {}

const LeoIndex: React.FC<LeoIndexProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const {
    mainMenu: { isCollapsed },
    leo: { isLeoModalVisible },
  } = useSelector((state: RootState) => state);

  const { showLeoTrigger, agentId } = useShowLeoTrigger();

  useEffect(() => {
    dispatch(saveIsLeoModalVisible(false));
  }, [dispatch, location.pathname]);

  useEffect(() => {
    // hide background scroll on mobile
    if (isMobile) {
      document.body.style.overflow = isLeoModalVisible ? 'hidden' : 'auto';
    }

    return () => {
      if (isMobile) {
        document.body.style.overflow = 'auto';
      }
    };
  }, [isLeoModalVisible]);

  if (!showLeoTrigger) {
    return null;
  }

  return (
    <div>
      <button
        className='w-full bg-black appearance-none'
        onClick={() => dispatch(openLeo(agentId!))}
      >
        <img
          src={LeoIdle}
          className='w-full h-10 object-cover'
          alt='leo-idle'
        />
        <div
          className={classNames(
            'leo-trigger w-full h-18 bg-transparent animate-slide-in-down transition-all ease-in-out',
          )}
        >
          <div className='w-full bg-black -mt-3 h-full'>
            <div className='w-full flex flex-row justify-center'>
              <p className='text-white text-sm'>
                Hi! I am Leo. Click on me to ask me anything!
              </p>
              <FontAwesomeIcon
                icon={faChevronDoubleUp}
                className='text-white ml-1.5 mt-0.5 pt-px'
                size='xs'
              />
            </div>
            <LeoChatPrompts isSimba />
          </div>
        </div>
      </button>
      {isLeoModalVisible && (
        <div
          aria-label='leo'
          className={classNames(
            'fixed top-0 right-0 h-full-window left-0 z-50',
            isCollapsed ? 'lg:left-[80px]' : 'lg:left-[196px]',
          )}
        >
          <LeoModalContainer />
        </div>
      )}
    </div>
  );
};

export default LeoIndex;
