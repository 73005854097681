import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { SvgIcon } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import ControlledToggleInputV7 from '../ControlledToggleInputV7';
import { SwitchVariant } from '../CustomSwitch';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import { ReactComponent as PushNotification } from '../../assets/icons/push-notification.svg';
import { ReactComponent as Mail } from '../../assets/icons/mail.svg';
import { PublisherTypeEnum, RootState } from '../../types';
import {
  getNotificationStatus,
  updateNotificationStatus,
} from '../../slices/TransactionSlice';
import { parseRealUsername } from '../../utils/AuthUtils';
import {
  PublisherNotificationPreferencesDtoMutedChannelsEnum,
  UpdatePublisherNotificationPreferencesDtoMuteChannelsEnum,
  UpdatePublisherNotificationPreferencesDtoUnmuteChannelsEnum,
} from '../../openapi/hermes';

interface SilenceNotificationProps {
  publisherType?: PublisherTypeEnum;
}

interface FormData {
  emailsMuted: boolean;
  pushNotificationsMuted: boolean;
}

const SilenceNotification: React.FC<SilenceNotificationProps> = ({
  publisherType = PublisherTypeEnum.TRANSACTION,
}) => {
  const dispatch = useDispatch();
  const {
    transaction: {
      notificationSetting,
      transactionDetailResponse: { data: transactionDetail },
    },
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [realUsername, setRealUsername] = useState<string | undefined>(
    undefined,
  );
  const transactionId = transactionDetail?.id;

  const { control, watch } = useForm<FormData>();

  const [emailsMuted, pushNotificationsMuted] = watch([
    'emailsMuted',
    'pushNotificationsMuted',
  ]);

  const getChannelType = () => {
    let mutedChannels = [];
    let unMutedChannels = [];
    if (!emailsMuted) {
      mutedChannels.push(
        UpdatePublisherNotificationPreferencesDtoMuteChannelsEnum.Email,
      );
    } else {
      unMutedChannels.push(
        UpdatePublisherNotificationPreferencesDtoUnmuteChannelsEnum.Email,
      );
    }

    if (!pushNotificationsMuted) {
      mutedChannels.push(
        UpdatePublisherNotificationPreferencesDtoMuteChannelsEnum.Push,
      );
    } else {
      unMutedChannels.push(
        UpdatePublisherNotificationPreferencesDtoUnmuteChannelsEnum.Push,
      );
    }

    return { mutedChannels, unMutedChannels };
  };

  useEffect(() => {
    if (userDetail) {
      const username = parseRealUsername(userDetail?.joinRealEmail);
      setRealUsername(username);
    }
  }, [userDetail]);

  useEffect(() => {
    if (!!realUsername && !!transactionId)
      dispatch(
        getNotificationStatus(realUsername, publisherType, transactionId!),
      );
  }, [realUsername, dispatch, transactionId, publisherType]);

  useEffect(() => {
    if (notificationSetting?.publisher.id! === transactionId! && realUsername) {
      const { mutedChannels, unMutedChannels } = getChannelType();
      dispatch(
        updateNotificationStatus(realUsername, {
          publisher: notificationSetting?.publisher!,
          muteChannels: mutedChannels,
          unmuteChannels: unMutedChannels,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailsMuted, pushNotificationsMuted]);

  if (!realUsername) {
    return null;
  }

  return (
    <>
      <div className='ml-auto flex w-7 h-full items-center justify-center'>
        <FontAwesomeIcon
          icon={faBell}
          className='text-xl leading-5 text-primary-dark font-light hover:cursor-pointer hover:animate-wiggle'
          onClick={() => setIsOpen(true)}
          aria-label='notification_bell'
        />
      </div>
      <ZenSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title='Notification Settings'
      >
        {notificationSetting && (
          <div className='mx-5 my-8 border rounded-xl border-gray-300 divide-y'>
            <div className='flex flex-row items-center p-6'>
              <SvgIcon component={Mail} viewBox='0 0 26 26' className='mr-2' />
              <ControlledToggleInputV7
                name='emailsMuted'
                control={control}
                defaultValue={
                  !notificationSetting?.mutedChannels?.includes(
                    PublisherNotificationPreferencesDtoMutedChannelsEnum.Email,
                  )
                }
                label='Allow Email Notification'
                variant={SwitchVariant.zen}
                leftIcon={
                  <FontAwesomeIcon
                    icon={faXmark}
                    className='text-primary-blue'
                  />
                }
                rightIcon={
                  <FontAwesomeIcon
                    icon={faCheck}
                    className='text-primary-blue'
                  />
                }
              />
            </div>
            <div className='flex flex-row items-center p-6'>
              <SvgIcon
                component={PushNotification}
                viewBox='0 0 26 26'
                className='mr-2'
              />
              <ControlledToggleInputV7
                name='pushNotificationsMuted'
                control={control}
                defaultValue={
                  !notificationSetting?.mutedChannels?.includes(
                    PublisherNotificationPreferencesDtoMutedChannelsEnum.Push,
                  )
                }
                label='Allow Push Notification'
                variant={SwitchVariant.zen}
                leftIcon={
                  <FontAwesomeIcon
                    icon={faXmark}
                    className='text-primary-blue'
                  />
                }
                rightIcon={
                  <FontAwesomeIcon
                    icon={faCheck}
                    className='text-primary-blue'
                  />
                }
              />
            </div>
          </div>
        )}
      </ZenSidebarModal>
    </>
  );
};

export default SilenceNotification;
