import { Controller, UseControllerOptions } from 'react-hook-form-v6';
import { get } from 'lodash';
import { FieldValues } from 'react-hook-form-v6/dist/types';
import { FieldErrors } from 'react-hook-form-v6/dist/types/errors';
import { FormFieldTooltipIndexProps } from '../types';
import { cn } from '../utils/classUtils';
import FormErrorMessage from './FormErrorMessage';

export type ControlledTextInputType =
  | 'text'
  | 'email'
  | 'number'
  | 'hidden'
  | 'date'
  | 'datetime-local'
  | 'image'
  | 'month'
  | 'password'
  | 'search'
  | 'tel'
  | 'time'
  | 'url'
  | 'week';

export type ControlledTextInputModeProps =
  | 'none'
  | 'text'
  | 'tel'
  | 'url'
  | 'email'
  | 'numeric'
  | 'decimal'
  | 'search';

export interface ControlledTextInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends UseControllerOptions<TFieldValues>,
    FormFieldTooltipIndexProps {
  errors: FieldErrors<TFieldValues>;
  type?: ControlledTextInputType;
  inputMode?: ControlledTextInputModeProps;
  label?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
}

const ControlledTextInput: React.FC<ControlledTextInputProps> = ({
  errors,
  control,
  name,
  label,
  defaultValue,
  rules,
  type = 'text',
  inputMode = 'text',
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  tooltipIndex,
}) => {
  const error = get(errors, name);

  return (
    <div className='space-y-1 w-full'>
      {label && (
        <label className='inline-block' htmlFor={name}>
          {label}
        </label>
      )}
      <Controller
        id={name}
        control={control}
        name={name}
        defaultValue={defaultValue || ''}
        rules={rules}
        render={({ name, value, onChange, onBlur, ref }) => (
          <input
            id={name}
            ref={ref}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            type={type}
            inputMode={inputMode}
            name={name}
            readOnly={readOnly}
            placeholder={placeholder}
            className={cn(
              'appearance-none p-1.5 border-gray-200 rounded bg-white focus:outline-none focus:ring-0 w-full',
              {
                'bg-gray-50': readOnly,
              },
              {
                'border-none': noBorder,
              },
            )}
            data-tooltip-index={tooltipIndex}
          />
        )}
      />
      {error && !hideErrorMessage && (
        <FormErrorMessage message={error.message} />
      )}
    </div>
  );
};

export default ControlledTextInput;
