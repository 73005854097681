import { useEffect, useRef, useState } from 'react';
import { useEventListener } from 'usehooks-ts';

interface UseDebounceOptions {
  dependencies?: any[];
  delay?: number;
  skipFirstRender?: boolean;
}

export const useDebounce = (
  callback: () => void,
  {
    dependencies = [],
    delay = 200,
    skipFirstRender = true,
  }: UseDebounceOptions,
) => {
  const firstRenderRef = useRef(skipFirstRender);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    const timer = setTimeout(callback, delay);
    return () => clearTimeout(timer);
  }, [...dependencies, delay]);
};

export enum BreakPoint {
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
}

const isClient = typeof window === 'object';

const DEFAULT_SIZE = {
  width: isClient ? window.innerWidth : 0,
  height: isClient ? window.innerHeight : 0,
  screen: BreakPoint.xl,
};

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState(DEFAULT_SIZE);

  useEventListener('resize', () => {
    const width = isClient ? window.innerWidth : 0;
    const height = isClient ? window.innerHeight : 0;

    let screen = BreakPoint.xl; // Default to 'xl' for widths >= 1200
    if (width < 576) {
      screen = BreakPoint.xs;
    } else if (width < 768) {
      screen = BreakPoint.s;
    } else if (width < 992) {
      screen = BreakPoint.m;
    } else if (width < 1200) {
      screen = BreakPoint.l;
    }

    setScreenSize({ width, height, screen });
  });

  return screenSize;
};
