import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { faPen, faHourglass } from '@fortawesome/pro-light-svg-icons';
import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { CardItem, RootState } from '../../../types';
import { isTransactionReadOnly } from '../../../utils/TransactionHelper';
import IconButton from '../../IconButton';
import ZenCardWithItems from '../ZenCardWithItems';
import ZenPill from '../ZenPill';
import ZenUserPill from '../ZenUserPill';
import { getFullName } from '../../../utils/AgentHelper';
import ZenEditOfficeDoubleEnderSidebar from './ZenEditOfficeDoubleEnderSidebar';
import ZenTransactionStatus from './ZenTransactionStatus';

interface ZenOfficeDoubleEnderProps {
  transaction: TransactionResponse;
}

const ZenOfficeDoubleEnder: React.FC<ZenOfficeDoubleEnderProps> = ({
  transaction,
}) => {
  const {
    auth: { userDetail },
    transaction: { doubleEnderAgent },
  } = useSelector((state: RootState) => state);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);

  const agentInfo = doubleEnderAgent.data;

  const items: CardItem[] = [
    {
      name: 'Representing Other Side',
      value: agentInfo ? (
        <Link to={`/people/${agentInfo.id}`}>
          <ZenUserPill
            name={getFullName(agentInfo)!}
            imageUrl={agentInfo.avatar}
            backgroundVariant='background'
            noWrap
          />
        </Link>
      ) : (
        '--'
      ),
    },
    {
      name: 'Other Side Transaction',
      value: transaction.doubleEnderInfo?.otherTxCode ? (
        <Link
          to={`/transactions/${transaction.doubleEnderInfo
            ?.otherTxId!}?showStatusOnboardings=true`}
        >
          <IconButton
            leftIcon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
            variant='outline'
            buttonStyle='border-gray-100 rounded-lg'
            label={transaction.doubleEnderInfo?.otherTxCode!}
          />
        </Link>
      ) : (
        <ZenPill
          title='Not Connected Yet'
          variant='danger'
          leftIcon={<FontAwesomeIcon icon={faHourglass} className='mr-1' />}
          textStyle='text-sm whitespace-pre'
        />
      ),
    },
    {
      name: 'Other Side Trans. Status',
      value: transaction.doubleEnderInfo?.otherTxStatus ? (
        <div className='mr-[-12px]'>
          <ZenTransactionStatus
            status={
              (transaction.doubleEnderInfo
                ?.otherTxStatus as unknown) as TransactionLifecycleStateValueStateEnum
            }
            country={transaction.country!}
          />
        </div>
      ) : (
        '--'
      ),
    },
  ];

  return (
    <div className='h-fit'>
      <ZenCardWithItems
        title='Office Double Ender'
        items={items}
        variant='regular'
        RightComponent={
          !isTransactionReadOnly(transaction, userDetail!) ? (
            <button
              onClick={() => setShowSidebar(true)}
              className='flex flex-row items-center justify-center space-x-1'
              data-testid='edit-office-double-ender'
            >
              <span className='text-primary-blue'>
                <FontAwesomeIcon icon={faPen} size='xs' />
              </span>
              <p className='mt-[2px] text-primary-blue'>Edit</p>
            </button>
          ) : undefined
        }
      />
      {showSidebar && (
        <ZenEditOfficeDoubleEnderSidebar
          isOpen
          onClose={() => setShowSidebar(false)}
          transaction={transaction}
        />
      )}
    </div>
  );
};

export default ZenOfficeDoubleEnder;
