interface ZenContentRowProps {
  step?: number;
  title: string;
  content: string | undefined;
  defaultContent: string;
}

const ZenContentRow: React.FC<ZenContentRowProps> = ({
  step,
  title,
  content,
  defaultContent,
  children,
}) => {
  return (
    <div className='flex flex-row items-start space-x-3 font-zen-body text-base text-primary-dark'>
      {step && (
        <div className='w-6 h-6 bg-grey-200 rounded-full flex flex-shrink-0 justify-center items-center mt-1'>
          <span className='font-zen-title text-sm mt-0.5'>{step}</span>
        </div>
      )}
      <div className='flex flex-col'>
        <span className='font-zen-title font-medium'>{title}</span>
        {content ? (
          <span className='text-sm'>{content}</span>
        ) : (
          <span className='text-grey-600 text-sm'>{defaultContent}</span>
        )}
        {children}
      </div>
    </div>
  );
};

export default ZenContentRow;
