import { useEffect } from 'react';
import { AsyncResponse } from '../types';

interface Props<T> {
  name: string;
  fetch: () => void;
  response: AsyncResponse<T>;
}

export const useFetchAsyncMappingResponse = <T,>({
  name,
  fetch,
  response,
}: Props<T>): AsyncResponse<T> => {
  useEffect(() => {
    fetch();
  }, [fetch]);

  if (!response) {
    return {
      loading: false,
      name: name,
    };
  }

  return response;
};
