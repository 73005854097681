import { useSelector } from 'react-redux';
import { ReactComponent as RightArrow } from '../../../assets/img/arrow_right.svg';
import {
  DepositCheckFormData,
  DepositCheckStepName,
} from '../../../routes/ZenTransactionDepositCheckRoute';
import { RootState } from '../../../types';
import { transactionCheckDepositPayAtClosingSteps } from '../../../utils/CheckDepositsHelper';
import ZenApplicationStepProgressHeader from '../../onboarding/ZenApplicationStepProgressHeader';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenButton from '../../Zen/ZenButton';
import ZenCheckDepositConfirmationStep from './ZenCheckDepositConfirmationStep';
import ZenCheckDepositHeader from './ZenCheckDepositHeader';

const ZenTransactionCheckDepositConfirmation: StepByStepComponent<
  DepositCheckFormData,
  DepositCheckStepName
> = ({ onSubmit, form }) => {
  const { watch } = form;
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
      tokenTransactionDetail,
    },
  } = useSelector((state: RootState) => state);
  const [closeAt, closingDate, showClosing, isExternalCheckDeposit] = watch([
    'closeAt',
    'closingDate',
    'showClosing',
    'isExternalCheckDeposit',
  ]);

  return (
    <div className='w-full h-full max-w-3xl py-4 mx-auto bg-white'>
      {showClosing && (
        <div className='flex justify-center mb-3'>
          <ZenApplicationStepProgressHeader
            steps={transactionCheckDepositPayAtClosingSteps(!closeAt)}
            inProgressIndex={1}
          />
        </div>
      )}
      <ZenCheckDepositHeader
        transactionAddress={
          isExternalCheckDeposit
            ? tokenTransactionDetail?.propertyAddress
            : transactionDetail?.address?.oneLine
        }
        closingDate={closingDate}
      />

      <ZenCheckDepositConfirmationStep
        form={form}
        FooterComponent={() => (
          <div className='w-72'>
            {isExternalCheckDeposit ? (
              <ZenButton
                label='Learn more about Real'
                variant='secondary'
                isFullWidth
                onClick={onSubmit}
                RightIconComponent={<RightArrow className='ml-1 mb-0.5' />}
              />
            ) : (
              <ZenButton label='Done' isFullWidth onClick={onSubmit} />
            )}
          </div>
        )}
      />
    </div>
  );
};

export default ZenTransactionCheckDepositConfirmation;
