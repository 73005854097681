import React, { useState } from 'react';
import { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form-v7';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { FormFieldTooltipIndexProps } from '../../../types';
import ZenControlledTextInput, {
  ControlledTextInputModeProps,
  ControlledTextInputType,
} from './ZenControlledTextInput';

interface ZenControlledSecretTextInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  type?: ControlledTextInputType;
  inputMode?: ControlledTextInputModeProps;
  label?: string;
  subLabel?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
}

const ZenControlledSecretTextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  endAdornment,
  type,
  ...rest
}: ZenControlledSecretTextInputProps<TFieldValues, TName>) => {
  const [showText, setShowText] = useState<boolean>(true);
  return (
    <ZenControlledTextInput
      {...rest}
      type={!showText ? 'password' : type}
      endAdornment={
        <div className='flex items-center text-center px-3 h-full'>
          {showText ? (
            <span className='cursor-pointer' onClick={() => setShowText(false)}>
              <FontAwesomeIcon icon={faEyeSlash} className='text-gray-500' />
            </span>
          ) : (
            <span className='cursor-pointer' onClick={() => setShowText(true)}>
              <FontAwesomeIcon icon={faEye} className='text-gray-500' />
            </span>
          )}
        </div>
      }
    />
  );
};

export default ZenControlledSecretTextInput;
