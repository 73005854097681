import DocumentHeader from './DocumentHeader';
import SlimDocumentHeader from './SlimDocumentHeader';
import DocumentFooter from './DocumentFooter';

type Header = 'slim' | 'canada';

interface FormLayoutProps {
  title: string;
  identifier: string;
  companyName: string;
  companyAddress: string;
  revision?: number;
  createdAt?: number;
  header?: Header;
  isPdf?: boolean;
}

const FormLayout: React.FC<FormLayoutProps> = ({
  identifier,
  title,
  companyName,
  companyAddress,
  children,
  createdAt,
  revision,
  header = 'slim',
  isPdf = false,
}) => {
  const renderHeader = () => {
    if (header === 'canada') {
      return (
        <SlimDocumentHeader
          companyName={companyName}
          companyAddress={companyAddress}
          title={title}
          createdAt={createdAt!}
          revision={revision!}
          isPdf={isPdf}
        />
      );
    }
    return (
      <DocumentHeader
        companyName={companyName}
        companyAddress={companyAddress}
        title={title}
        createdAt={createdAt}
        revision={revision}
        isPdf={isPdf}
      />
    );
  };
  return (
    <div>
      {renderHeader()}
      <div className='px-5 pb-5 pt-3'>{children}</div>
      <div className='print:fixed bottom-0 w-full'>
        <DocumentFooter identifier={identifier} />
      </div>
    </div>
  );
};

export default FormLayout;
