import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DEFAULT_CHAT_PROMPTS } from '../../../constants/LeoConstants';
import { ChatControllerApi, PromptLibraryRequest } from '../../../openapi/leo';
import { queryKeys } from '../../../query/base/queryKeys';
import { useSimpleQuery } from '../../../query/base/useSimpleQuery';
import { RootState } from '../../../types';
import { ContextualPrompt, FollowUpItem } from '../../../utils/LeoUtils';
import { getLeoConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { UUID_REGEX } from '../../../utils/StringUtils';

interface useShowLeoTriggerReturn {
  agentId?: string;
  isAgent: boolean;
  showLeoTrigger: boolean;
  simbaFollowUpItem: FollowUpItem | undefined;
  prompts: ContextualPrompt[];
}

const useShowLeoTrigger = (): useShowLeoTriggerReturn => {
  const location = useLocation();
  const {
    auth: { userDetail, isSuperAdmin, isRealTitleOfficer, isAdmin, isBroker },
    agentDetail: {
      detailResponse: { data },
    },
  } = useSelector((state: RootState) => state);

  const isAgent = useMemo(
    () => !isSuperAdmin && !isAdmin && !isBroker && !isRealTitleOfficer,
    [isAdmin, isBroker, isSuperAdmin, isRealTitleOfficer],
  );
  const isTransactionDetailRouteRegex = new RegExp(
    `/transactions/${UUID_REGEX}`,
  );
  const isTransactionDetailRoute = isTransactionDetailRouteRegex.test(
    location.pathname,
  );
  const transactionId = isTransactionDetailRoute
    ? location.pathname.split('/')[2]
    : '';
  const isProfileRouteRegex = new RegExp(`/people/${UUID_REGEX}`);
  const isProfileRoute = isProfileRouteRegex.test(location.pathname);

  const agentData = useMemo(() => (isAgent ? userDetail : data), [
    data,
    isAgent,
    userDetail,
  ]);
  const showLeoTrigger = useMemo(() => isAgent || (isAdmin && isProfileRoute), [
    isAdmin,
    isAgent,
    isProfileRoute,
  ]);

  const promptReq: PromptLibraryRequest = useMemo(
    () => ({
      source: location.pathname.slice(1),
      limit: 10,
      offset: 0,
    }),
    [location.pathname],
  );

  const simbaFollowUpItem = useMemo(() => {
    if (transactionId) {
      return { id: transactionId! };
    }
    return undefined;
  }, [transactionId]);

  const { data: prompts = DEFAULT_CHAT_PROMPTS } = useSimpleQuery({
    queryKey: queryKeys.leoPrompts.list(promptReq).queryKey,
    queryFn: async () => {
      const { data } = await new ChatControllerApi(
        await getLeoConfiguration(),
      ).getPromptSuggestionsChatPromptLibraryPost(promptReq);
      return data.items?.length ? data.items : DEFAULT_CHAT_PROMPTS;
    },
    options: {
      logErrorMessage: '[LEO] - Failed to fetch leo prompts.',
      toastErrorMessage: 'Failed to fetch leo prompts.',
      enabled: showLeoTrigger,
    },
  });

  return {
    agentId: agentData?.id,
    isAgent,
    showLeoTrigger,
    simbaFollowUpItem,
    prompts,
  };
};

export default useShowLeoTrigger;
