import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { useSelector } from 'react-redux';
import { fetchMLSSearchData } from '../../../utils/MLSUtils';
import { MlsResponse } from '../../../openapi/yenta';
import { isSmScreen } from '../../../utils/BrowserUtils';
import { RootState } from '../../../types';
import ZenViewActionButtonCell from '../../table/Cells/ZenViewActionButtonCell';
import AddressStateEnumSelectColumnFilter from '../../table/Filters/AddressStateEnumSelectColumnFilter';
import MlsResponseStatusEnumSelectColumnFilter from '../../table/Filters/MlsResponseStatusEnumSelectColumnFilter';
import TextColumnFilter from '../../table/Filters/TextColumnFilter';
import TextStrictCaseColumnFilter from '../../table/Filters/TextStrictCaseColumnFilter';
import ZenResourceIndexContainer from '../Containers/ZenResourceIndexContainer';
import ZenDateCell from '../Table/Cell/ZenDateCell';
import ZenMlsStatusPill from '../Table/Cell/ZenMlsStatusPill';

interface ZenMLSResourceTableProps {
  search?: string;
  RightComponent?: React.ReactElement;
}

export const columns: Array<Column<MlsResponse>> = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Logo',
    accessor: 'logo',
    Cell: ({ value }) =>
      value ? <img src={value} className='h-12' alt='logo' /> : 'N/A',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Code',
    accessor: 'code',
    Filter: TextColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'State',
    accessor: 'administrativeAreas',
    id: 'administrativeAreas:id',
    Filter: AddressStateEnumSelectColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => <ZenMlsStatusPill status={value!} />,
    Filter: MlsResponseStatusEnumSelectColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Joined Date',
    accessor: 'joinDate',
    Cell: ({ value }) => <ZenDateCell date={value} />,
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => value,
    Filter: TextStrictCaseColumnFilter,
  },
];

const columnsWithAction: Array<Column<MlsResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<MlsResponse> }) => (
      <Link to={`/mls/${row.original.id}`}>
        <ZenViewActionButtonCell />
      </Link>
    ),
  },
  ...columns,
];

const ZenMLSResourceTable: React.FC<ZenMLSResourceTableProps> = ({
  search,
  RightComponent,
}) => {
  const {
    auth: { isAdmin, isBroker },
  } = useSelector((state: RootState) => state);

  const modifiedColumns = useMemo(() => {
    return columnsWithAction?.map((column) => {
      if (column?.id === 'administrativeAreas:id' && isBroker) {
        return {
          ...column,
          disableFilters: true,
        };
      }
      return column;
    });
  }, [isBroker]);

  return (
    <div className='px-4 lg:py-5'>
      <ZenResourceIndexContainer<MlsResponse>
        header='MLS'
        search={search}
        columns={modifiedColumns}
        RightComponent={isAdmin ? RightComponent : undefined}
        initialSort={{ name: 'asc' }}
        fetchData={async (req, cancelToken) =>
          await fetchMLSSearchData(req, search, { cancelToken })
        }
        resourceName='MLS'
        showSearch={isSmScreen()}
        hiddenColumns={['administrativeAreas:id']}
        stickyHeader
      />
    </div>
  );
};
export default ZenMLSResourceTable;
