import { AxiosResponse } from 'axios';
import { Action } from 'redux';
import ErrorService from '../../services/ErrorService';
import { AppDispatch, AsyncResponse } from '../../types';
import { showAndReportErrors } from '../../utils/ErrorUtils';
import { showApiErrorModal } from '../ErrorSlice';
import { showErrorToast } from '../ToastNotificationSlice';

interface AsyncRequestOptions<P> {
  errorMetadata?: { [key: string]: object };
  changeLoading?: boolean;
  skipAuthDatadog?: boolean;
  ignoreHandledDatadog?: boolean;
  ignoreStatusCodesDatadog?: number[];
  errorMessage?: string;
  additionalProps?: P;
}

export const performAsyncRequest = async <
  T,
  P extends Record<string, any> = {}
>(
  dispatch: AppDispatch,
  name: string,
  saveCall: (data: AsyncResponse<T, P>) => Action,
  fetch: () => Promise<AxiosResponse<T>>,
  options: AsyncRequestOptions<P> = {
    errorMetadata: {},
    changeLoading: true,
    skipAuthDatadog: false,
    ignoreHandledDatadog: false,
  },
): Promise<AxiosResponse<T> | void> => {
  const errorMetadata = options.errorMetadata ?? {};
  const changeLoading = options.changeLoading ?? true;
  const skipAuthDatadog = options.skipAuthDatadog ?? false; // ignore 401 / 403
  const ignoreHandledDatadog = options.ignoreHandledDatadog ?? false; // ignore != 500
  const errorIgnoreStatusCodes = new Set(
    options.ignoreStatusCodesDatadog ?? [],
  );
  const errorMessage =
    options.errorMessage ?? `We had a problem fetching ${name}`;

  if (skipAuthDatadog) {
    errorIgnoreStatusCodes.add(401);
    errorIgnoreStatusCodes.add(403);
  }

  if (changeLoading) {
    dispatch(
      saveCall({
        name,
        loading: true,
        additionalProps: options.additionalProps,
      }),
    );
  }

  try {
    const response = await fetch();
    dispatch(
      saveCall({
        name,
        data: response.data,
        loading: false,
        additionalProps: options.additionalProps,
      }),
    );
    return response;
  } catch (e) {
    dispatch(
      saveCall({
        name,
        error: ErrorService.getErrorCode(e),
        loading: false,
        additionalProps: options.additionalProps,
      }),
    );

    if (showAndReportErrors(e?.response)) {
      dispatch(showApiErrorModal(e));
      dispatch(
        showErrorToast(errorMessage, 'Please try again in a few moments.'),
      );
    }

    if (ignoreHandledDatadog) {
      ErrorService.notifyIgnoreHandled(errorMessage, e, errorMetadata);
    } else {
      ErrorService.notifyIgnoreStatusCodes(
        [...errorIgnoreStatusCodes],
        errorMessage,
        e,
        errorMetadata,
      );
    }
  }
};
