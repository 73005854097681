import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowLeftLong } from '@fortawesome/pro-light-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../components/PageLayout';
import ReferralFilterSearchBy from './ReferralFilterSearchBy';
import ReferralApplicantCard from './ReferralApplicantCard';

interface ReferralApplicantsProps {}

type Match = {
  id: string;
};

const ReferralApplicants: React.FC<ReferralApplicantsProps> = () => {
  const history = useHistory();
  // static id until api is ready
  const { id } = { id: '123' } as Match;

  return (
    <PageLayout
      path={[
        { title: 'Home', url: '/' },
        { title: 'Referral Central', url: '/referrals' },
        { title: 'My Referrals', url: `${id}/applicants` },
      ]}
    >
      <div className='flex flex-row items-center px-6 py-6'>
        <div
          onClick={() => history.push('/referrals')}
          className='hover:cursor-pointer flex flex-row items-center'
        >
          <FontAwesomeIcon
            icon={faArrowLeftLong}
            className='text-primary-dark font-light'
          />
          <p className='text-lg font-inter ml-2 font-medium'>
            Created Referrals
          </p>
        </div>
        <FontAwesomeIcon
          icon={faChevronRight}
          className='text-grey-500 text-xs ml-2 font-inter font-extrabold text-center'
          size='xs'
        />
        <FontAwesomeIcon
          icon={faLocationDot}
          className='ml-2 font-inter  text-primary-dark'
          size='sm'
        />
        <p className='text-lg font-inter ml-2 font-medium'>
          Fort Walton Beach, Florida
        </p>
      </div>
      <div className='h-full-window'>
        <div className='p-4'>
          <ReferralFilterSearchBy hideFilter />
          <div className='grid grid-flow-row grid-cols-3 gap-5 py-4'>
            <ReferralApplicantCard
              totalSales={9200}
              address={[
                'Walton Beach, Florida',
                'Miami, Florida',
                'Orlando, Florida',
              ]}
              minimumPrice={9900}
              maximumPrice={90000}
              name='Tom Cruise'
              numberOfListings={26}
              note='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil tempora dolore, sunt quibusdam saepe adipisci'
            />
            <ReferralApplicantCard
              totalSales={9200000}
              address={['Walton Beach, Florida']}
              minimumPrice={90000}
              maximumPrice={900000}
              name='James Tyler'
              numberOfListings={26}
              note='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil tempora dolore, sunt quibusdam saepe adipisci'
            />
            <ReferralApplicantCard
              totalSales={9200000}
              address={['Walton Beach, Florida', 'Miami, Florida']}
              minimumPrice={90000}
              maximumPrice={900000}
              name='James Tyler'
              numberOfListings={26}
              note='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil tempora dolore, sunt quibusdam saepe adipisci'
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ReferralApplicants;
