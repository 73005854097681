import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Control } from 'react-hook-form-v7';
import ZenControlledDatePickerInput from './ZenControlledDatePickerInput';

interface ZenControlledDateRangeInputProps {
  control: Control<any, object>;
}
const ZenControlledDateRangeInput: React.FC<ZenControlledDateRangeInputProps> = ({
  control,
}) => {
  return (
    <div className='relative border-t px-4 pb-14'>
      <p className='py-3'>Date Range</p>
      <div className='flex flex-row items-center w-11/12 justify-center absolute space-x-2 z-50 mx-auto pb-5'>
        <ZenControlledDatePickerInput
          control={control}
          shouldUnregister={false}
          name='dateFrom'
          placeholder='Start'
          datePickerConfig={{
            dropdownMode: 'scroll',
            dateFormatCalendar: 'LLLL - yyyy',
          }}
          endAdornment={
            <FontAwesomeIcon
              icon={faCalendar}
              className='text-gray-500'
              size='lg'
            />
          }
        />
        <ZenControlledDatePickerInput
          control={control}
          shouldUnregister={false}
          name='dateTo'
          placeholder='End'
          datePickerConfig={{
            dateFormatCalendar: 'LLLL - yyyy',
            dropdownMode: 'scroll',
          }}
          endAdornment={
            <FontAwesomeIcon
              icon={faCalendar}
              className='text-gray-500'
              size='lg'
            />
          }
        />
      </div>
    </div>
  );
};

export default ZenControlledDateRangeInput;
