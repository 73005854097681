import {
  ParticipantResponse,
  RefundResponseStatusEnum,
} from '../openapi/arrakis';
import { EnumMap } from '../types';
import { safeEnumMapGet } from './EnumHelper';
import { getParticipantName } from './ParticipantHelper';

export const isRefundRequestEditable = (
  status: RefundResponseStatusEnum,
  isAdmin: boolean,
  isBroker: boolean,
) => {
  const statusToBooleanMap: EnumMap<RefundResponseStatusEnum, boolean> = {
    NEW: false,
    SUBMITTED: true,
    PENDING_APPROVAL: isAdmin || isBroker,
    FINANCE_APPROVED: isAdmin,
    READY_FOR_RELEASE: isAdmin,
    COMPLETE: false,
    CANCELLED: isAdmin || isBroker,
  };

  return safeEnumMapGet(statusToBooleanMap, status, false);
};

export const isRefundRequestAbleToView = (
  status: RefundResponseStatusEnum,
  isAgent: boolean,
) => {
  const statusToBooleanMap: EnumMap<RefundResponseStatusEnum, boolean> = {
    NEW: false,
    SUBMITTED: false,
    PENDING_APPROVAL: isAgent,
    FINANCE_APPROVED: true,
    READY_FOR_RELEASE: true,
    COMPLETE: true,
    CANCELLED: isAgent,
  };

  return safeEnumMapGet(statusToBooleanMap, status, false);
};

export const isRefundRequestAbleToCancel = (
  status: RefundResponseStatusEnum,
  isAgent: boolean,
  isAdmin: boolean,
  isBroker: boolean,
) => {
  const statusToBooleanMap: EnumMap<RefundResponseStatusEnum, boolean> = {
    NEW: false,
    SUBMITTED: isAgent || isAdmin,
    PENDING_APPROVAL: isBroker || isAdmin,
    FINANCE_APPROVED: isAdmin,
    READY_FOR_RELEASE: isAdmin,
    COMPLETE: false,
    CANCELLED: false,
  };

  return safeEnumMapGet(statusToBooleanMap, status, false);
};

export const isRefundRequestAbleToRevert = (
  status: RefundResponseStatusEnum,
  isFinanceRole: boolean,
) => {
  const statusToBooleanMap: EnumMap<RefundResponseStatusEnum, boolean> = {
    NEW: false,
    SUBMITTED: false,
    PENDING_APPROVAL: false,
    FINANCE_APPROVED: false,
    READY_FOR_RELEASE: false,
    COMPLETE: isFinanceRole,
    CANCELLED: false,
  };

  return safeEnumMapGet(statusToBooleanMap, status, false);
};

export const getPayableToFromParticipants = (
  participants: ParticipantResponse[],
) => {
  const payableTos = (participants || [])?.map((participant) =>
    getParticipantName(participant),
  );

  return payableTos.join(', ');
};
