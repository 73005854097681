import { Range } from 'rc-slider';

export interface RangeSliderProps {
  value: number[];
  min: number;
  max: number;
  step: number;
  onChange(value: number[]): void;
  disabled?: boolean;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  value,
  min,
  max,
  step,
  onChange,
  disabled,
}) => {
  return (
    <Range
      value={value}
      min={min}
      max={max}
      onChange={onChange}
      step={step}
      disabled={disabled}
      allowCross={false}
      style={{ backgroundColor: 'transparent' }}
      trackStyle={[
        {
          backgroundColor: disabled ? '#D6D6D6' : '#05C3F9',
          height: 12,
          borderRadius: 15,
          opacity: 0.8,
        },
      ]}
      handleStyle={[
        {
          borderColor: disabled ? '#D6D6D6' : '#05C3F9',
          top: 1,
          left: 8,
          height: 30,
          width: 30,
          backgroundColor: disabled ? '#FFF' : '#05C3F9',
          boxShadow:
            '0px 4px 2px rgba(1, 62, 80, 0.12), 0px 2px 2px rgba(1, 62, 80, 0.24)',
        },
        {
          borderColor: disabled ? '#D6D6D6' : '#05C3F9',
          top: 1,
          left: 8,
          height: 30,
          width: 30,
          backgroundColor: disabled ? '#FFF' : '#05C3F9',
          boxShadow:
            '0px 4px 2px rgba(1, 62, 80, 0.12), 0px 2px 2px rgba(1, 62, 80, 0.24)',
        },
      ]}
      railStyle={{
        backgroundColor: disabled ? '#D6D6D6' : '#05C3F9',
        opacity: 0.2,
        height: 12,
        borderRadius: 10,
      }}
    />
  );
};

export default RangeSlider;
