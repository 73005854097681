import Button from '../Button';

interface SidebarModalActionFooterProps {
  submitButtonText?: string;
  cancelButtonText?: string;
  onClose(): void;
  isSubmitting: boolean;
}

const SidebarModalActionFooter: React.FC<SidebarModalActionFooterProps> = ({
  submitButtonText = 'Save',
  cancelButtonText = 'Cancel',
  onClose,
  isSubmitting,
}) => {
  return (
    <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2 w-full'>
      <Button
        buttonType='button'
        onClick={onClose}
        label={cancelButtonText}
        type='secondary'
        size='lg'
        fullWidth
      />
      <Button
        isSubmitting={isSubmitting}
        buttonType='submit'
        label={submitButtonText}
        size='lg'
        fullWidth
      />
    </div>
  );
};

export default SidebarModalActionFooter;
