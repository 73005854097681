import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { EscrowResponse, TransactionResponse } from '../../../openapi/arrakis';

interface ZenAddInstallmentOptionsProps {
  onSelectManual(): void;
  showCheckDeposit: boolean;
  transaction: TransactionResponse;
  escrow: EscrowResponse;
}

const ZenAddInstallmentOptions: React.FC<ZenAddInstallmentOptionsProps> = ({
  onSelectManual,
  showCheckDeposit,
  transaction,
  escrow,
}) => {
  const history = useHistory();

  return (
    <div className='mt-2 lg:mt-0 flex flex-col lg:flex-row lg:items-center lg:pl-3 lg:space-x-0.5'>
      <div className='lg:whitespace-nowrap text-zen-dark-6 text-sm'>
        Add Installment:
      </div>
      <div className='mt-1 lg:mt-0 lg:flex lg:items-center lg:space-x-1.5'>
        <button
          onClick={onSelectManual}
          data-testid='addManually'
          className='flex items-center'
        >
          <FontAwesomeIcon
            icon={light('money-check-dollar-pen')}
            size='lg'
            className='text-primary-blue p-1.5'
          />
          <p className='lg:whitespace-nowrap font-zen-body text-primary-blue'>
            Manual
          </p>
        </button>
        <div className='hidden lg:block pl-1.5'>
          <div className='bg-zen-dark-4 w-px h-6' />
        </div>
        {showCheckDeposit && (
          <button
            onClick={() => {
              history.push(
                `/transactions/${
                  transaction.id
                }/deposit-check/escrows/${escrow.id!}`,
              );
            }}
            className='flex items-center'
          >
            <FontAwesomeIcon
              icon={light('money-from-bracket')}
              size='lg'
              className='text-primary-blue p-1.5'
            />
            <p className='lg:whitespace-nowrap font-zen-body text-primary-blue'>
              Deposit Real’s Check
            </p>
          </button>
        )}
      </div>
    </div>
  );
};

export default ZenAddInstallmentOptions;
