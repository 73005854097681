import qs from 'qs';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ZenInvoiceResourceTable from '../components/Zen/Invoices/ZenInvoiceResourceTable';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenRoute from '../components/Zen/ZenRoute';

const ZenInvoicesIndexRoute: React.FC = () => {
  const location = useLocation();
  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );

  return (
    <ZenRoute title='Invoices'>
      <ZenPageLayoutWithSearch
        path={[
          { title: 'Home', url: '/' },
          { title: 'Invoices', url: '/invoices' },
        ]}
        search={search}
        onSearchChange={setSearch}
      >
        <ZenInvoiceResourceTable search={search} />
      </ZenPageLayoutWithSearch>
    </ZenRoute>
  );
};

export default ZenInvoicesIndexRoute;
