import classNames from 'classnames';
import { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { MoneyValue, MoneyValueCurrencyEnum } from '../../../openapi/yenta';
import { FormFieldTooltipIndexProps } from '../../../types';
import { getSelectOptionsForEnums } from '../../../utils/TableUtils';
import { ControlledTextInputModeProps } from './ZenControlledTextInput';
import ZenFormErrorMessage from './ZenFormErrorMessage';

type IMoneyValue = Record<string, MoneyValue>;

export interface ZenControlledMoneyInputProps<
  TFieldValues extends FieldValues | IMoneyValue = FieldValues | IMoneyValue,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  inputMode?: ControlledTextInputModeProps;
  label?: string;
  subLabel?: string;
  extraLabelComponent?: React.ReactElement;
  readOnly?: boolean;
  currencyReadOnly?: boolean;
  placeholder?: string;
  isRequired?: boolean;
}

const ZenControlledMoneyInput = <
  TFieldValues extends FieldValues | IMoneyValue = FieldValues | IMoneyValue,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  readOnly,
  currencyReadOnly,
  placeholder,
  extraLabelComponent,
  shouldUnregister = true,
  inputMode = 'text',
  isRequired = false,
  ...rest
}: ZenControlledMoneyInputProps<TFieldValues, TName>) => {
  const options = getSelectOptionsForEnums(MoneyValueCurrencyEnum);
  const [focus, setFocus] = useState<boolean>(false);
  const isCurrencyReadOnly = readOnly || currencyReadOnly;

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error, invalid },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label className='inline-block' htmlFor={`${name}.amount`}>
              <span
                className={classNames(
                  'font-zen-body font-semibold',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                )}
              >
                {label}
              </span>
              {!!subLabel && (
                <span className='font-zen-body text-sm text-zen-dark-12 ml-1'>
                  {subLabel}
                </span>
              )}
              {isRequired && <span className='text-zen-danger'>*</span>}
            </label>
          )}

          <div
            className={classNames(
              'border  rounded-lg overflow-hidden w-full flex flex-row',
              value?.amount && 'text-zen-dark-9',
              invalid && '!border-zen-danger',
              focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
            )}
          >
            <input
              id={`${name}.amount`}
              name={`${name}.amount`}
              value={value?.amount}
              onChange={({ target: { value: amount } }) => {
                onChange({
                  ...(value || {}),
                  amount,
                });
              }}
              inputMode={inputMode}
              onFocus={() => setFocus(true)}
              onBlur={() => {
                setFocus(false);
                onBlur();
              }}
              readOnly={readOnly}
              placeholder={placeholder}
              className={classNames(
                'appearance-none p-2 w-full border-none focus:outline-none focus:ring-0 font-zen-body font-normal',
                {
                  'bg-gray-50': readOnly && inputMode !== 'none',
                },
              )}
              type='number'
            />
            <div>
              <select
                id={`${name}.currency`}
                name={`${name}.currency`}
                value={value?.currency}
                onChange={(e) => {
                  onChange({ ...(value || {}), currency: e.target.value });
                }}
                className={classNames(
                  'font-zen-body font-normal appearance-none p-1.5 bg-white focus:outline-none border-0 focus:ring-0 w-18 h-full',
                  {
                    'bg-gray-50': isCurrencyReadOnly,
                  },
                )}
                disabled={isCurrencyReadOnly}
                data-testid='currency-select'
              >
                {options.map((currency) => (
                  <option key={currency.value} value={currency.value}>
                    {currency.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {error && <ZenFormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenControlledMoneyInput;
