import React from 'react';
import classNames from 'classnames';
import ZenPopover from '../../../ZenPopover';

interface TeamPreCapFeePopoverProps {
  noMarginTop?: boolean;
}

const TeamPreCapFeePopover: React.FC<TeamPreCapFeePopoverProps> = ({
  noMarginTop = false,
}) => {
  return (
    <div
      className={classNames(
        'font-inter text-sm text-zen-dark-9 mt-7',
        noMarginTop ? 'mt-0' : 'mt-7',
      )}
    >
      Team Pre Cap Fee <span className='text-error'>*</span>
      <ZenPopover
        title='Team Pre Cap Fee'
        description={
          <div className='text-zen-dark-9 text-base font-inter font-light'>
            <p>
              The administrator can optionally configure the team to
              automatically apply a flat pre-cap fee (dollar amount). In that
              case, the Teammate will see a Team Pre Cap fee deduction on each
              transaction until the Team Cap is reached.
            </p>
          </div>
        }
      />
    </div>
  );
};

export default TeamPreCapFeePopover;
