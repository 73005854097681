import React from 'react';
import { cn } from '../../../utils/classUtils';
import { BreakPoint, useScreenSize } from '../../WeathManagement/utils';

type Size = 'sm' | 'lg';

export interface ZenMoreDropdownProps {
  labels: string[];
  show: boolean;
  position: 'absolute' | 'fixed';
  containerRef: React.RefObject<HTMLDivElement>;
  moreRef: React.RefObject<HTMLDivElement>;
  onClick(label: string): void;
  size: Size;
}

const ALWAYS_ABSOLUTE_SCREEN_SIZES = [
  BreakPoint.xs,
  BreakPoint.s,
  BreakPoint.m,
];

const DROPDOWN_MENU_MARGIN: Record<Size, number> = {
  sm: 20,
  lg: 40,
};

export const ZenMoreDropdown: React.FC<ZenMoreDropdownProps> = ({
  labels,
  show,
  containerRef,
  moreRef,
  position,
  onClick,
  size,
}) => {
  const { screen } = useScreenSize();
  if (!show) {
    return null;
  }

  const isAbsolute = ALWAYS_ABSOLUTE_SCREEN_SIZES.includes(screen);

  const {
    right: containerRight,
  } = containerRef.current!.getBoundingClientRect();
  const { right: moreRight } = moreRef.current!.getBoundingClientRect();

  const relativeRight = Math.max(containerRight - moreRight, 10);

  return (
    <div
      className='mt-2 bg-primary-light border border-grey-300 rounded-md z-[1000]'
      style={{
        position: isAbsolute ? 'absolute' : position,
        top: `${
          (containerRef.current?.offsetTop || 0) + DROPDOWN_MENU_MARGIN[size]
        }px`,
        right: `${relativeRight}px`,
      }}
    >
      {labels.map((label) => (
        <div
          key={`dropdown-tab-${label}`}
          className={cn(
            'hover:bg-rezen-light-blue-100  font-zen-body text-primary-dark cursor-pointer',
            size === 'sm' && 'text-sm px-5 py-1.5 font-light',
            size === 'lg' && 'text-base px-4 py-2.5 font-normal',
          )}
          onClick={() => onClick(label)}
        >
          {label}
        </div>
      ))}
    </div>
  );
};
