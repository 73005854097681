import { useSelector } from 'react-redux';
import AdminFeatureFlagService from '../services/AdminFeatureFlagService';
import { FeatureFlagTypeEnum, RootState } from '../types';

export const useFeatureFlag = (flag: FeatureFlagTypeEnum): boolean => {
  const { experiments } = useSelector((state: RootState) => state.experiment);

  let calculatedEnabled: boolean = AdminFeatureFlagService.isAvailable(flag);

  calculatedEnabled =
    calculatedEnabled ||
    experiments.findIndex((experiment) => experiment.name === flag) !== -1;

  return calculatedEnabled;
};
