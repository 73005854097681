import config from '../config';
import Logger from '../utils/Logger';
import { AgentResponse } from '../openapi/yenta';
import { IdentitySummaryResponse } from '../openapi/keymaker';
import { getGeneralizedRole } from '../utils/AuthUtils';
import { AnalyticsEventEnum } from '../types';
import IAnalyticsService from './interface/IAnalyticsService';

class GoogleAnalyticsGateway implements IAnalyticsService {
  initialize() {
    Logger.debug('[Google Analytics] -- initializing.');

    // disable page view tracking
    // @ts-ignore
    const options: Gtag.CustomParams = { send_page_view: false };

    // https://support.google.com/analytics/answer/7201382#zippy=%2Cgoogle-tag-manager
    // To disable debug mode, exclude the 'debug_mode' field. Setting the field to false won't disable debug mode.
    if (config.debug) {
      options.debug_mode = true;
    }

    (window as any).gtag(
      'config',
      config.googleAnalyticsMeasurementId,
      options,
    );
  }

  setUser(
    userDetail: AgentResponse,
    keymakerCurrentUser: IdentitySummaryResponse,
  ) {
    const userProperties: Record<string, unknown> = {
      userId: userDetail.id,
      userEmail: userDetail.emailAddress,
      userCountry: userDetail.accountCountry,
      userFirstName: userDetail.firstName,
      userLastName: userDetail.lastName,
      userRoles: getGeneralizedRole(userDetail, keymakerCurrentUser),
    };

    Logger.debug('[Google Analytics] -- setting user to', userProperties);

    (window as any).gtag('set', 'user_properties', userProperties);
  }

  logEvent(
    eventName: AnalyticsEventEnum,
    eventData: Record<string, unknown> = {},
  ) {
    Logger.debug(
      '[Google Analytics] -- firing event',
      eventName,
      'with data',
      eventData,
    );

    (window as any).gtag('event', eventName, {
      send_to: config.googleAnalyticsMeasurementId,
      ...eventData,
    });
  }
}

export default GoogleAnalyticsGateway;
