import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { faTrashCan, faCalendar } from '@fortawesome/pro-regular-svg-icons';
import {
  BankAccountDto,
  MoneyTransferCreateRequest,
  MoneyTransferResponse,
  MoneyTransferUpdateRequest,
  MoneyValue,
  MoneyValueCurrencyEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import {
  createMoneyTransfer,
  updateMoneyTransferDispatch,
} from '../../../slices/MoneyTransferSlice';
import { MONEY_AMOUNT_REGEX } from '../../../utils/StringUtils';
import {
  bankAccountTypeOptionBarColor,
  getBankAccountFormattedString,
} from '../../../utils/TransactionHelper';
import { CustomSelectOptions } from '../../ControlledCustomSelectInputV7';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledMoneyInput from '../../Zen/Input/ZenControlledMoneyInput';
import ZenControlledTextAreaInput from '../../Zen/Input/ZenControlledTextAreaInput';
import ZenSidebarModalForm from '../../Zen/ZenSidebarModalForm';
import ZenControlledCustomSelectInput from '../TrustDeposits/ZenControlledCustomSelectInput';
import ZenDeleteMoneyTransferConfirmationModal from './ZenDeleteMoneyTransferConfirmationModal';

const getBankAccountOptionStyle = (color: string) => {
  return {
    alignItems: 'center',
    display: 'flex',
    ':before': {
      backgroundColor: color,
      borderRadius: 3,
      content: '" "',
      display: 'block',
      marginRight: 5,
      height: 28,
      width: 5,
    },
  };
};

interface FormData {
  amount: MoneyValue;
  fromBankAccountId: CustomSelectOptions;
  toBankAccountId: CustomSelectOptions;
  transferAt: string;
  reason: string;
}

interface AddOrEditMoneyTransferSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
  moneyTransfer?: MoneyTransferResponse;
  bankAccounts: BankAccountDto[];
}

const ZenAddOrEditMoneyTransferSidebarModal: React.FC<AddOrEditMoneyTransferSidebarModalProps> = ({
  isOpen,
  onClose,
  transaction,
  moneyTransfer,
  bankAccounts,
}) => {
  const dispatch = useDispatch();
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const isEditMoneyTransfer = !!moneyTransfer;
  const fromBankAccount = bankAccounts.find(
    (bankAccount) => bankAccount.id === moneyTransfer?.fromBankAccountId,
  );
  const toBankAccount = bankAccounts.find(
    (bankAccount) => bankAccount.id === moneyTransfer?.toBankAccountId,
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: isEditMoneyTransfer
      ? {
          amount: { amount: moneyTransfer?.amount?.amount },
          fromBankAccountId: {
            label: getBankAccountFormattedString(fromBankAccount!),
            value: moneyTransfer?.fromBankAccountId,
            color:
              bankAccountTypeOptionBarColor[fromBankAccount?.bankAccountType!],
          },
          toBankAccountId: {
            label: getBankAccountFormattedString(toBankAccount!),
            value: moneyTransfer?.toBankAccountId,
            color:
              bankAccountTypeOptionBarColor[toBankAccount?.bankAccountType!],
          },
          reason: moneyTransfer?.reason,
          transferAt: moneyTransfer?.transferredAt,
        }
      : undefined,
  });

  const onSubmit = async (data: FormData) => {
    if (isEditMoneyTransfer) {
      const updateMoneyTransferRequest: Required<
        Omit<MoneyTransferUpdateRequest, 'transferredBy' | 'doubleEnder'>
      > = {
        amount: {
          amount: data.amount.amount,
          currency:
            data.amount.currency ||
            ((transaction.currency as unknown) as MoneyValueCurrencyEnum),
        },
        fromBankAccountId: data.fromBankAccountId.value,
        toBankAccountId: data.toBankAccountId.value,
        transferredAt: data.transferAt,
        reason: data.reason,
      };

      await dispatch(
        updateMoneyTransferDispatch(
          moneyTransfer.id!,
          updateMoneyTransferRequest,
        ),
      );
    } else {
      const createMoneyTransferRequest: Required<
        Omit<MoneyTransferCreateRequest, 'doubleEnder'>
      > = {
        transactionId: transaction.id!,
        amount: {
          amount: data.amount.amount,
          currency:
            data.amount.currency ||
            ((transaction.currency as unknown) as MoneyValueCurrencyEnum),
        },
        fromBankAccountId: data.fromBankAccountId.value,
        toBankAccountId: data.toBankAccountId.value,
        transferredAt: data.transferAt,
        reason: data.reason,
      };

      await dispatch(createMoneyTransfer(createMoneyTransferRequest));
    }

    onClose();
  };

  return (
    <ZenSidebarModalForm
      isOpen={isOpen}
      onClose={onClose}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Save'
      title={isEditMoneyTransfer ? 'Edit Transfer' : 'Add a Transfer'}
      subtitle={transaction.address?.oneLine}
    >
      <div className='w-1/2'>
        <ZenControlledMoneyInput<FormData, 'amount'>
          name='amount'
          control={control}
          label='Amount'
          inputMode='numeric'
          placeholder='E.g. 500,000.00'
          rules={{
            validate: (v: MoneyValue) => {
              if (!!v && !v?.amount) {
                return 'Please enter a amount';
              } else {
                return !MONEY_AMOUNT_REGEX?.test(String(v?.amount))
                  ? 'Please enter a valid amount'
                  : undefined;
              }
            },
          }}
          currencyReadOnly
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledCustomSelectInput<FormData, 'fromBankAccountId'>
          name='fromBankAccountId'
          control={control}
          label='FROM Account:'
          placeholder='Select FROM Account'
          options={bankAccounts.map((bankAccount) => ({
            value: `${bankAccount.id}`,
            label: getBankAccountFormattedString(bankAccount),
            color: bankAccountTypeOptionBarColor[bankAccount.bankAccountType!],
          }))}
          styles={{
            singleValue: (styles, { data }) => ({
              ...styles,
              ...getBankAccountOptionStyle(data.color),
            }),
            option: (styles, { data }) => ({
              ...styles,
              ...getBankAccountOptionStyle(data.color),
            }),
          }}
          rules={{ required: 'Please select from account' }}
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledCustomSelectInput<FormData, 'toBankAccountId'>
          name='toBankAccountId'
          control={control}
          label='TO Account:'
          placeholder='Select To Account'
          options={bankAccounts.map((bankAccount) => ({
            value: `${bankAccount.id}`,
            label: getBankAccountFormattedString(bankAccount),
            color: bankAccountTypeOptionBarColor[bankAccount.bankAccountType!],
          }))}
          styles={{
            singleValue: (styles, { data }) => ({
              ...styles,
              ...getBankAccountOptionStyle(data.color),
            }),
            option: (styles, { data }) => ({
              ...styles,
              ...getBankAccountOptionStyle(data.color),
            }),
          }}
          rules={{ required: 'Please select to account' }}
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledDatePickerInput<FormData, 'transferAt'>
          name='transferAt'
          control={control}
          label='Date of Transfer'
          placeholder='MM/DD/YYYY'
          rules={{ required: 'Date of transfer is required' }}
          icon={<FontAwesomeIcon icon={faCalendar} className='text-gray-600' />}
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledTextAreaInput<FormData, 'reason'>
          name='reason'
          control={control}
          label='Reason:'
          placeholder='Reason for this transfer'
          rows={3}
          rules={{ required: 'Reason is required' }}
          isRequired
        />
      </div>
      {isEditMoneyTransfer && (
        <div className='inline-block py-2'>
          <div
            className='flex items-center cursor-pointer hover:bg-red-100 p-2 rounded'
            onClick={() => setDeleting(true)}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              className='text-red-600 mb-1 mr-1'
            />
            <p className='text-red-600 font-primary-medium'>Delete Transfer</p>
          </div>
        </div>
      )}
      <ZenDeleteMoneyTransferConfirmationModal
        isOpen={isDeleting}
        onClose={() => {
          setDeleting(false);
          onClose();
        }}
        moneyTransfer={moneyTransfer!}
        bankAccounts={bankAccounts}
      />
    </ZenSidebarModalForm>
  );
};

export default ZenAddOrEditMoneyTransferSidebarModal;
