import {
  faArrowRightLong,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SendAReferral from '../../../assets/img/wealthManagement/sendReferral.svg';
import { getRateLimit } from '../../../slices/WealthManagementSlice';
import { RootState } from '../../../types';
import ResourceContainer from '../../ResourceContainer';
import ZenSimpleConfirmationModal from '../../Zen/Modal/ZenSimpleConfirmationModal';
import ZenButton from '../../Zen/ZenButton';
import SendWealthPlanStepByStepFormV2 from '../SendWealthPlan/V2/SendWealthPlanStepByStepFormV2';

interface SendToFriendCardProps {}

const SendToFriendCard: React.FC<SendToFriendCardProps> = () => {
  const [showSendPlan, setShowSendPlan] = useState(false);
  const [showPlanWarning, setSetPlanWarning] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    auth: { userDetail },
    wealthManagement: { rateLimitResponse },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (userDetail) {
      dispatch(getRateLimit());
    }
  }, [dispatch, userDetail]);

  const handleGetStarted = () => {
    if (!!rateLimitResponse?.data) {
      if (rateLimitResponse?.data?.numberOfReferralsLeftThisMonth! > 0) {
        setShowSendPlan(true);
      } else {
        setShowSendPlan(false);
        setSetPlanWarning(true);
      }
    }
  };

  const handleViewDashboard = () => {
    history.push('/wealthplan/referral/dashboard');
  };

  return (
    <>
      <div className='relative w-full h-full bg-regent-100 rounded-xl flex flex-col items-center px-6 overflow-hidden shadow-zen-card'>
        <ResourceContainer
          isEmpty={false}
          resourceName='Send WealthPlan Card'
          loading={rateLimitResponse?.loading}
          errorCode={rateLimitResponse?.error}
        >
          <h2 className='font-zen-title-2 text-base font-medium text-black mt-24'>
            Send a WealthPlan!
          </h2>
          <div className='mt-10'>
            <img src={SendAReferral} alt='send a referral' className='h-24' />
          </div>
          <p className='text-black font-zen-body-2 text-sm leading-6 mt-8 text-center'>
            Build a WealthPlan for an agent you&apos;re attracting to Real.
          </p>
          <ZenButton
            label='Get Started'
            className='!rounded-full mt-12 mb-28'
            onClick={handleGetStarted}
          />
          <div className='absolute bottom-0 px-1.5 py-1 bg-white w-full flex items-center justify-center'>
            <ZenButton
              variant='primary-link'
              label='View Dashboard'
              RightIconComponent={
                <FontAwesomeIcon icon={faArrowRightLong} className='text-sm' />
              }
              onClick={handleViewDashboard}
              isFullWidth
            />
          </div>
        </ResourceContainer>
      </div>
      <ZenSimpleConfirmationModal
        isOpen={showPlanWarning}
        onClose={() => setSetPlanWarning(false)}
        size='large'
        variant='warning'
        isSubmitting={false}
        hideIcon
        hideBottomButtons
      >
        <div className='flex items-center justify-center p-4 flex-col gap-3 relative'>
          <div className='mx-2 my-3 absolute right-0 -top-5'>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className='text-zen-dark-10 cursor-pointer text-2xl'
              onClick={() => setSetPlanWarning(false)}
            />
          </div>
          <div className='flex justify-center items-center w-16 h-16 rounded-full p-1 border-zen-danger border-2'>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              size='xl'
              className='text-zen-danger'
            />
          </div>
          <div className='font-bold font-zen-body mt-5'>
            You&apos;ve reached the Invitation limit
          </div>
          <div className='text-zen-dark-8 font-zen-body font-normal'>
            You may send 30 WealthPlans to agents per month
          </div>
          <div className='w-3/4 mt-5'>
            <ZenButton
              label='Okay, Got it'
              isFullWidth
              onClick={() => setSetPlanWarning(false)}
            />
          </div>
        </div>
      </ZenSimpleConfirmationModal>
      <SendWealthPlanStepByStepFormV2
        isOpen={showSendPlan}
        close={() => setShowSendPlan(false)}
      />
    </>
  );
};

export default SendToFriendCard;
