import qs from 'qs';
import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../openapi/arrakis';
import { isCanadaTransaction } from '../utils/TransactionHelper';
import AmplitudeService, { AmplitudeEvent } from '../services/AmplitudeService';

const useTransactionOnboardingRedirect = (
  doRedirectToTransactionSetup: boolean,
  transactionId: string,
  transactionDetail: TransactionResponse | undefined,
  onBeforeRedirect?: () => void,
): boolean => {
  const history = useHistory();
  const location = useLocation();
  const { showStatusOnboardings } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const isTransactionDetailLoaded = transactionDetail?.id! === transactionId;
  const hasErrors = !!transactionDetail?.errors?.length;
  const nextState = transactionDetail?.nextPrimaryUserTransition?.state;

  const canStartOnboarding =
    showStatusOnboardings === 'true' && isTransactionDetailLoaded && !hasErrors;

  const showTransactionSetup =
    isTransactionDetailLoaded &&
    transactionDetail?.showOnboardingScreen &&
    doRedirectToTransactionSetup;

  const willTransitionToApproveCDAOnboarding =
    canStartOnboarding &&
    !isCanadaTransaction(transactionDetail!) &&
    nextState ===
      TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved;

  const handleOnboardingRedirection = useCallback(async () => {
    if (willTransitionToApproveCDAOnboarding && onBeforeRedirect) {
      onBeforeRedirect();
    }

    if (showTransactionSetup) {
      history.replace(`/transactions/${transactionDetail?.id}/setup`);
    } else if (willTransitionToApproveCDAOnboarding) {
      AmplitudeService.logEvent(
        AmplitudeEvent.TX_ONBOARDING_CDA_APPROVAL_ATTEMPT,
      );

      history.push(
        `/transactions/${transactionId}/approve-commission-document`,
      );
    }
  }, [
    willTransitionToApproveCDAOnboarding,
    showTransactionSetup,
    onBeforeRedirect,
    history,
    transactionDetail?.id,
    transactionId,
  ]);

  useEffect(() => {
    handleOnboardingRedirection();
  }, [handleOnboardingRedirection]);

  return showTransactionSetup || willTransitionToApproveCDAOnboarding;
};

export default useTransactionOnboardingRedirect;
