import { faRotate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Config, usePopperTooltip } from 'react-popper-tooltip';
import { ButtonSizeType } from '../types';
import { cn } from '../utils/classUtils';
import { ButtonTypeType } from './Button';

export type IconButtonVariant =
  | 'default'
  | 'slim'
  | 'pill'
  | 'outline'
  | 'danger'
  | 'danger-outline'
  | 'dark'
  | 'dark-outline'
  | 'no-outline'
  | 'none'
  | 'outline-primary'
  | 'outline-danger'
  | 'grey-outline';

export interface IconButtonProps {
  rightIcon?: React.ReactElement;
  leftIcon?: React.ReactElement;
  size?: ButtonSizeType;
  label?: string;
  onClick?(): void;
  variant?: IconButtonVariant;
  toolTipText?: string;
  buttonType?: ButtonTypeType;
  fullWidth?: boolean;
  disabled?: boolean;
  isSubmitting?: boolean;
  buttonStyle?: string;
  textStyle?: string;
  toolTipStyles?: string;
  toolTipConfig?: Config;
}

const IconButton: React.FC<IconButtonProps> = ({
  leftIcon,
  rightIcon,
  size = 'sm',
  onClick,
  label,
  variant = 'default',
  toolTipText = '',
  buttonType = 'button',
  fullWidth,
  disabled,
  isSubmitting = false,
  buttonStyle,
  textStyle,
  toolTipStyles,
  toolTipConfig = { placement: 'bottom' },
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(toolTipConfig);

  let textVariantStyle: string;
  let buttonVariantStyle: string;

  if (variant === 'outline') {
    textVariantStyle = 'text-trueGray-700';
    buttonVariantStyle =
      'bg-white border border-gray-200 ring-gray-200 ring-1 border-1';
  } else if (variant === 'outline-danger') {
    textVariantStyle = 'text-error';
    buttonVariantStyle =
      'bg-white border border-error ring-error ring-1 border-1';
  } else if (variant === 'outline-primary') {
    textVariantStyle = 'text-primary';
    buttonVariantStyle =
      'bg-white border border-primary ring-primary ring-1 border-1';
  } else if (variant === 'no-outline') {
    textVariantStyle = 'text-white';
    buttonVariantStyle =
      'bg-transparent border ring-1 border-1 ring-transparent border-transparent hover:bg-trueGray-700';
  } else if (variant === 'pill') {
    textVariantStyle = 'text-white';
    buttonVariantStyle = 'bg-primary rounded-full px-2';
  } else if (variant === 'slim') {
    textVariantStyle = 'text-white';
    buttonVariantStyle =
      'bg-black border border-black ring-black ring-1 border-1';
  } else if (variant === 'danger') {
    textVariantStyle = 'text-white';
    buttonVariantStyle =
      'bg-error border border-error ring-error ring-1 border-1';
  } else if (variant === 'danger-outline') {
    textVariantStyle = 'text-error';
    buttonVariantStyle =
      'bg-white border border-error ring-error ring-1 border-1';
  } else if (variant === 'dark') {
    textVariantStyle = 'text-white';
    buttonVariantStyle = 'bg-dark border border-dark ring-dark ring-1 border-1';
  } else if (variant === 'dark-outline') {
    textVariantStyle = 'text-dark';
    buttonVariantStyle =
      'bg-white border border-dark ring-dark ring-1 border-1';
  } else if (variant === 'grey-outline') {
    textVariantStyle = 'text-primary-dark';
    buttonVariantStyle =
      'bg-white border border-regent-400 ring-regent-400 ring-1 border-1';
  } else if (variant === 'none') {
    textVariantStyle = '';
    buttonVariantStyle = '';
  } else {
    textVariantStyle = 'text-white';
    buttonVariantStyle =
      'bg-primary border border-primary ring-primary ring-1 border-1';
  }

  const sizeToClassNameMap: { [key in ButtonSizeType]: string } = {
    xs: 'py-0.5 px-1.5',
    sm: 'py-1 px-2',
    lg: 'py-1.5 px-5',
    xl: 'py-2 px-8',
  };

  const fontToClassNameMap: { [type in ButtonSizeType]: string } = {
    xs: 'text-xs',
    sm: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
  };

  const isDisabled = disabled || isSubmitting;

  return (
    <button
      type={buttonType}
      onClick={onClick}
      ref={setTriggerRef}
      disabled={isDisabled}
      className={cn(
        'flex flex-row rounded items-center focus:outline-none hover:opacity-90',
        buttonVariantStyle,
        sizeToClassNameMap[size],
        fontToClassNameMap[size],
        { 'w-full justify-between': fullWidth },
        { 'opacity-60 hover:opacity-60 cursor-not-allowed': isDisabled },
        buttonStyle,
      )}
      data-testid='icon-button'
    >
      <div className='items-center flex flex-row space-x-1'>
        {isSubmitting && (
          <FontAwesomeIcon
            icon={faRotate}
            className={cn('animate-spin mr-1', textVariantStyle)}
          />
        )}
        {!isSubmitting && leftIcon && (
          <span className={cn('flex items-center', textVariantStyle)}>
            {leftIcon}
          </span>
        )}
        {label && (
          <span
            className={cn(
              'px-1 items-center whitespace-nowrap',
              textVariantStyle,
              textStyle,
            )}
          >
            {label}
          </span>
        )}
      </div>
      {!isSubmitting && rightIcon && (
        <span className={cn('flex items-center', textVariantStyle)}>
          {rightIcon}
        </span>
      )}
      {visible && toolTipText && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip' })}>
          <div {...getArrowProps({ className: 't-arrow' })} />
          <div className={cn('whitespace-normal w-60', toolTipStyles)}>
            {toolTipText}
          </div>
        </div>
      )}
    </button>
  );
};

export default IconButton;
