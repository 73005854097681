import { faPause, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import WaveSurfer from 'wavesurfer.js';
import { WaveSurferParams } from 'wavesurfer.js/types/params';
import EventEmitter from '../../../../services/EventEmitter';
import { cn } from '../../../../utils/classUtils';
import { formatTime } from '../../../../utils/DateUtils';

const AudioLoader: React.FC = () => {
  return (
    <ContentLoader
      width='100%'
      viewBox='0 0 540 64'
      backgroundColor='#eaeced'
      foregroundColor='#ffffff'
    >
      <rect x='0' y='0' rx='3' ry='3' width='540' height='64' />
    </ContentLoader>
  );
};

const eventEmitter = new EventEmitter();
const EVENT_NAME = 'VOICE_CALL';

type VoiceCallPlayerProps = {
  url: string;
  id: string;
  duration: number;
};

const VoiceCallPlayer: React.FC<VoiceCallPlayerProps> = ({
  url,
  id,
  duration,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [waveForm, setWaveForm] = useState<WaveSurfer>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const handleWaveForm = () => {
    eventEmitter.emit(EVENT_NAME, id);
    if (!waveForm) {
      return;
    }
    if (isPlaying) {
      waveForm.pause();
      setIsPlaying(false);
    } else {
      waveForm.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    const subscribe = eventEmitter.addListener(EVENT_NAME, (callId) => {
      if (callId !== id) {
        waveForm?.pause();
        setIsPlaying(false);
      }
    });

    return () => {
      subscribe.remove();
    };
  }, [id, waveForm]);

  useEffect(() => {
    if (!url) {
      return;
    }

    const config: WaveSurferParams = {
      barWidth: 2,
      barRadius: 2,
      barGap: 3,
      cursorWidth: 1,
      container: `#waveform-${id}`,
      backend: 'WebAudio',
      height: 60,
      progressColor: '#4967FD',
      responsive: true,
      waveColor: '#DADADA',
      cursorColor: 'transparent',
      closeAudioContext: true,
      removeMediaElementOnDestroy: true,
      hideScrollbar: true,
      barMinHeight: 2,
    };

    const waveSurfer = WaveSurfer.create(config);
    waveSurfer?.load(url);
    waveSurfer?.on('finish', () => {
      waveSurfer?.pause();
      waveSurfer?.seekTo(0);
      setIsPlaying(false);
    });
    waveSurfer?.on('ready', () => {
      setLoaded(true);
    });

    setWaveForm(waveSurfer);

    return () => {
      waveSurfer?.destroy();
    };
  }, [url, id]);

  return (
    <div
      className='flex flex-row items-center justify-between space-x-4 h-16 px-3 rounded-lg'
      aria-label='audio-player-container'
    >
      <div className='relative w-4 h-3.5'>
        <div
          className={cn(
            'absolute top-0 left-0',
            isPlaying ? 'invisible' : 'visible',
          )}
        >
          <button disabled={!loaded} onClick={handleWaveForm} type='button'>
            <FontAwesomeIcon
              icon={faPlay}
              fontSize={18}
              className={cn(
                'text-primary-blue mr-2 cursor-pointer',
                !loaded && 'opacity-70 cursor-auto',
              )}
            />
          </button>
        </div>
        <div
          className={cn(
            'absolute top-0 left-0',
            isPlaying ? 'visible' : 'invisible',
          )}
        >
          <button disabled={!loaded} onClick={handleWaveForm} type='button'>
            <FontAwesomeIcon
              icon={faPause}
              fontSize={18}
              className={cn(
                'text-primary-blue mr-2 cursor-pointer',
                !loaded && 'opacity-70 cursor-auto',
              )}
              onClick={handleWaveForm}
            />
          </button>
        </div>
      </div>
      <div className='relative w-full'>
        <div
          className='w-full h-14 overflow-hidden'
          id={`waveform-${id}`}
          aria-label='voice-recording'
        />
        {!loaded && (
          <div className='absolute inset-0'>
            <AudioLoader />
          </div>
        )}
      </div>
      <p className='text-sm text-grey-400'>
        {formatTime(duration || waveForm?.getDuration()!)}
      </p>
    </div>
  );
};

export default VoiceCallPlayer;
