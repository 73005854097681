interface EventSubscription {
  remove: () => void;
}

class EventEmitter {
  callbacks: Record<any, Array<(...args: any[]) => void> | undefined>;

  constructor() {
    this.callbacks = {};
  }

  addListener(event: string, cb: (...args: any[]) => void): EventSubscription {
    if (!this.callbacks[event]) {
      this.callbacks[event] = [];
    }
    this.callbacks[event]?.push(cb);
    return {
      remove: () => {
        delete this.callbacks[event];
      },
    };
  }

  emit(event: string, ...params: any[]) {
    const cbs = this.callbacks[event];
    if (!cbs) {
      return;
    }
    for (const cb of cbs) {
      cb(...params);
    }
  }
}

export default EventEmitter;
