import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useSelector, useDispatch } from 'react-redux';
import Awesome from '../../../assets/img/awesome.png';
import AwfulImg from '../../../assets/img/awful.png';
import Ehh from '../../../assets/img/ehh.png';
import ItsCool from '../../../assets/img/its_cool.png';
import NotSoGood from '../../../assets/img/not_so_good.png';
import {
  CreateSentimentRequestContextTypeEnum,
  CreateSentimentRequestSatisfactionRateEnum,
  SentimentDisplayRequestContextTypeEnum,
  SentimentDisplayResponseContextTypeEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import ControlledTextAreaInputV7 from '../../ControlledTextAreaInputV7';
import Support from '../../../assets/img/support.png';
import {
  createNewSentimentForCurrentContext,
  getSentimentDisplayStatus,
  saveSentimentDetails,
} from '../../../slices/TransactionSlice';
import { AppDispatch, RootState } from '../../../types';
import ZenButton from '../../Zen/ZenButton';
import ControlledSentimentFeedbackInput from './ControlledSentimentFeedbackInput';

interface HappySupportFeedbackModalDetailProps {
  transaction?: TransactionResponse;
}

interface FormData {
  satisfactionRate: CreateSentimentRequestSatisfactionRateEnum;
  feedback?: string;
}

const HappySupportFeedbackModal: React.FC<HappySupportFeedbackModalDetailProps> = () => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const dispatch: AppDispatch = useDispatch();
  const {
    auth: { userDetail },
    transaction: { sentimentDetails },
  } = useSelector((state: RootState) => state);
  const [contactedSupport, setContactedSupport] = useState<boolean>(false);

  const satisfactionRate = watch('satisfactionRate');

  const handleFeedback = async (values: FormData) => {
    await dispatch(
      createNewSentimentForCurrentContext(userDetail?.id!, {
        contextId: userDetail?.id!,
        contextType: CreateSentimentRequestContextTypeEnum.Support,
        satisfactionRate: values.satisfactionRate,
        feedback: values.feedback,
      }),
    );
  };

  const handleNope = () => {
    dispatch(saveSentimentDetails(undefined));
    handleFeedback({
      satisfactionRate:
        CreateSentimentRequestSatisfactionRateEnum.NotApplicable,
    });
  };

  useEffect(() => {
    dispatch(
      getSentimentDisplayStatus({
        contextId: userDetail?.id!,
        contextType: SentimentDisplayRequestContextTypeEnum.Support,
      }),
    );
  }, [dispatch, userDetail?.id]);

  if (
    !sentimentDetails?.displaySentiment ||
    sentimentDetails?.contextType !==
      SentimentDisplayResponseContextTypeEnum.Support
  ) {
    return null;
  }

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-60' />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0 '>
        <div className='w-full sm:w-8/12 md:w-[675px] flex flex-col justify-center items-center bg-white rounded-2xl z-10  max-w-3xl shadow-sm shadow-gray-300 py-10 px-4 md:px-[30px] lg:px-[40px]'>
          <form
            className='flex flex-col justify-between items-center min-h-full'
            onSubmit={handleSubmit(handleFeedback)}
          >
            {!contactedSupport ? (
              <div className='flex flex-col justify-center items-center text-center p-4 lg:px-[116px] lg:py-[65px]'>
                <div className='w-full lg:w-[230px] mb-10 mx-4'>
                  <img src={Support} alt='avatar' />
                </div>
                <div className='text-lg sm:text-xl mb-5 mx-4'>
                  Hi 👋, quick question!
                </div>
                <div className='text-xl sm:text-3xl px-4 sm:px-0'>
                  Have you contacted
                  <span className='text-primary-blue'> support </span>
                  in the last 30 days?
                </div>
                <div className='w-full flex flex-row justify-between items-center space-x-4 mt-8 px-4'>
                  <ZenButton
                    variant='primary-outline'
                    label='Nope!'
                    isFullWidth
                    onClick={handleNope}
                  />
                  <ZenButton
                    variant='primary'
                    label='Yes'
                    isFullWidth
                    onClick={() => setContactedSupport(true)}
                  />
                </div>
              </div>
            ) : (
              <div className='text-center'>
                <div className='text-base md:text-lg'>Sounds great! ⚡️</div>
                <div className='text-xl md:text-3xl my-3 lg:w-2/3 lg:mx-auto'>
                  What did you think of the support provided?
                </div>
                <div className='my-7'>
                  <ControlledSentimentFeedbackInput<
                    FormData,
                    'satisfactionRate'
                  >
                    label=''
                    options={[
                      {
                        icon: (
                          <img
                            src={AwfulImg}
                            className='h-10 w-10'
                            alt='Awful!'
                          />
                        ),
                        label: 'Awful!',
                        value:
                          CreateSentimentRequestSatisfactionRateEnum.Terrible,
                      },
                      {
                        icon: (
                          <img
                            src={NotSoGood}
                            className='h-10 w-10'
                            alt='Not so good'
                          />
                        ),
                        label: 'Not so good',
                        value: CreateSentimentRequestSatisfactionRateEnum.Bad,
                      },
                      {
                        icon: (
                          <img src={Ehh} className='h-10 w-10' alt='Ehhh..' />
                        ),
                        label: 'Ehhh..',
                        value:
                          CreateSentimentRequestSatisfactionRateEnum.Neutral,
                      },
                      {
                        icon: (
                          <img
                            src={ItsCool}
                            className='h-10 w-10'
                            alt='It’s Cool'
                          />
                        ),
                        label: 'It’s Cool',
                        value: CreateSentimentRequestSatisfactionRateEnum.Good,
                      },
                      {
                        icon: (
                          <img
                            src={Awesome}
                            className='h-10 w-10'
                            alt='Awesome!'
                          />
                        ),
                        label: 'Awesome!',
                        value:
                          CreateSentimentRequestSatisfactionRateEnum.Excellent,
                      },
                    ]}
                    shouldUnregister={false}
                    control={control}
                    name='satisfactionRate'
                    rules={{
                      required:
                        'Please choose an emoji to describe your experience!',
                    }}
                  />
                </div>
                <ControlledTextAreaInputV7<FormData, 'feedback'>
                  control={control}
                  name='feedback'
                  label=''
                  rows={5}
                  placeholder='Please share any feedback with us (either positive or constructive)'
                  shouldUnregister={false}
                  className='font-primary-light'
                  noResize
                />
                <div className='w-full flex flex-col justify-center items-center space-y-3 mt-5'>
                  <ZenButton
                    variant='primary'
                    type='submit'
                    label={isSubmitting ? 'Submitting...' : 'All done!'}
                    isDisabled={isSubmitting}
                  />

                  {!satisfactionRate && (
                    <p className='text-error'>
                      {errors.satisfactionRate?.message}
                    </p>
                  )}
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default HappySupportFeedbackModal;
