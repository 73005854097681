import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import RealLogo from '../../assets/img/new-rezen-black-logo.svg';
import AmplitudeService, {
  AmplitudeEvent,
} from '../../services/AmplitudeService';
import IconButton from '../IconButton';

export interface TransactionReviewWrapperProps {
  title: string;
  address: string;
  primaryActionTitle: string;
  secondaryActionTitle?: string;
  primaryActionOnPress(): void;
  secondaryActionOnPress?(): void;
  isPrimaryActionLoading?: boolean;
}

const TransactionReviewWrapper: React.FC<TransactionReviewWrapperProps> = ({
  title,
  children,
  address,
  primaryActionTitle,
  secondaryActionTitle,
  primaryActionOnPress,
  secondaryActionOnPress,
  isPrimaryActionLoading,
}) => {
  const history = useHistory();

  const exit = () => {
    AmplitudeService.logEvent(
      AmplitudeEvent.TX_ONBOARDING_COMMISSION_VALIDATION_CANCEL,
    );

    history.push(`/transactions`);
  };

  return (
    <div className='fixed top-0 right-0 bottom-0 left-0 w-full h-full bg-white scrollbar overflow-auto'>
      <div className='flex flex-col w-full h-full'>
        <header className='p-4 lg:p-6 border-b border-coolGray-300 flex items-center justify-between'>
          <div className='flex flex-row items-center'>
            <img src={RealLogo} className='h-7' alt='Logo' />
            <div className='h-7 border-l border-coolGray-300 mx-6' />
            <h1 className='font-primary-medium text-xl text-dark'>{title}</h1>
          </div>
          <div className='flex flex-row items-center space-x-4'>
            <h6 className='font-primary-thin text-base text-gray-700'>
              {address}
            </h6>
            <IconButton
              label='Close'
              variant='outline'
              rightIcon={<FontAwesomeIcon icon={faXmark} />}
              buttonType='button'
              onClick={exit}
            />
          </div>
        </header>
        <div className='w-full px-4 lg:max-w-2xl mx-auto mt-10 lg:mt-32 space-y-3'>
          {children}
          <div className='flex flex-row items-center justify-between space-x-2 mt-4'>
            {secondaryActionTitle && (
              <IconButton
                variant='outline'
                label={secondaryActionTitle}
                onClick={secondaryActionOnPress}
              />
            )}

            <IconButton
              variant='default'
              label={primaryActionTitle}
              onClick={primaryActionOnPress}
              disabled={isPrimaryActionLoading}
              isSubmitting={isPrimaryActionLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionReviewWrapper;
