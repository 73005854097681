import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ErrorCode } from '../types';
import DetailPageLoader from '../components/DetailPageLoader';
import ResourceContainer from '../components/ResourceContainer';
import { TransactionControllerApi } from '../openapi/arrakis';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';

type Match = {
  code: string;
};

const TransactionDetailsByCodeRoute: React.FC = () => {
  const history = useHistory();
  const { code: transactionCode } = useParams() as Match;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorCode, setErrorCode] = useState<ErrorCode>();

  useEffect(() => {
    const fetchTransaction = async () => {
      setIsLoading(true);
      try {
        const { data } = await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).getTransactionByCode(transactionCode);

        history.replace(`/transactions/${data.id}`);
      } catch (e) {
        dispatch(showApiErrorModal(e));
        setErrorCode(ErrorService.getErrorCode(e));
      } finally {
        setIsLoading(false);
      }
    };

    fetchTransaction();
  }, [transactionCode, history]);

  return (
    <ResourceContainer
      loading={isLoading}
      LoaderComponent={<DetailPageLoader />}
      isEmpty={false}
      errorCode={errorCode}
      resourceName='transaction'
    />
  );
};

export default TransactionDetailsByCodeRoute;
