import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FeatureFlagTypeEnum } from '../../types';
import { cn } from '../../utils/classUtils';
import {
  disableGeminiUI,
  enableGeminiUI,
  isGeminiUIEnabled,
} from '../../utils/switchToGeminiUtils';
import FeatureFlagEnabledOnly from '../FeatureFlagEnabledOnly';
import { ZenSideBarItemProps } from '../ZenSideBarItem';

const SwitchToGeminiToggle: React.FC<ZenSideBarItemProps> = ({ collapsed }) => {
  const history = useHistory();
  const [toggle, setToggle] = useState<boolean>(!!isGeminiUIEnabled());

  const handleToggle = () => {
    setToggle((prev) => !prev);

    if (toggle) {
      disableGeminiUI();
    } else {
      enableGeminiUI();
    }

    history.push('/');
  };

  return (
    <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.GEMINI_REDESIGN}>
      <div
        className={cn(
          'flex items-center pt-[14px] border-t border-t-regent-400 px-2 shrink-0 mt-2',
          collapsed && 'justify-center',
        )}
      >
        <button
          data-testid='switch-to-gemini'
          type='button'
          className={cn(
            'relative inline-flex flex-shrink-0 h-3.5 w-[26px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
            toggle ? 'bg-aqua' : 'bg-regent-400',
          )}
          onClick={handleToggle}
        >
          <span
            className={cn(
              'translate-x-0 pointer-events-none relative inline-block h-[9px] w-[9px] self-center rounded-full bg-primary-navy shadow transform ring-0 transition ease-in-out duration-200',
              toggle ? 'translate-x-3' : 'translate-x-0',
            )}
          >
            <span
              className={cn(
                'ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                toggle
                  ? 'opacity-0 ease-out duration-100'
                  : 'opacity-100 ease-in duration-200',
              )}
            />
            <span
              className={cn(
                'ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                toggle
                  ? 'opacity-100 ease-in duration-200'
                  : 'opacity-0 ease-out duration-100',
              )}
            />
          </span>
        </button>
        {!collapsed && (
          <span className='ml-2 text-sm font-zen-body-2 font-normal text-white shrink-0'>
            Switch to Gemini
          </span>
        )}
      </div>
    </FeatureFlagEnabledOnly>
  );
};

export default SwitchToGeminiToggle;
