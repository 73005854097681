import { AgentResponseAccountCountryEnum } from '../../../../openapi/yenta';
import { EnumMap } from '../../../../types';

interface ZenCountryCellProps {
  country: AgentResponseAccountCountryEnum | undefined;
}

export const ZenCountryToEmojiMap: EnumMap<
  AgentResponseAccountCountryEnum,
  string
> = {
  CANADA: `🇨🇦`,
  UNITED_STATES: `🇺🇸`,
};

const ZenCountryCell: React.FC<ZenCountryCellProps> = ({ country }) => {
  const value = country ? ZenCountryToEmojiMap[country] : 'N/A';

  return <p className='text-center text-base'>{value}</p>;
};

export default ZenCountryCell;
