export const LINE_GRAPH_PATH_DATA_ONE =
  'M312.5 1.15625L259.806 13.5L206.612 32L159.5 42.4986L120.426 53.9455C118.811 54.4186 117.162 54.7636 115.492 54.9772L55.5 62.6562L1 68.6562';
export const LINE_GRAPH_PATH_DATA_TWO =
  'M0.8125 101.156L33.8125 90.6562L65.3125 85.1562L113.312 76.3776L159.312 64.1562L221.312 42.6562L280.312 17.1562L325.812 0.65625';
export const LINE_GRAPH_PATH_DATA_THREE =
  'M1.5 89.4688L27.5 80.9688L55.244 69.5983C57.0774 68.8469 58.9766 68.2674 60.9171 67.8673L106.5 58.4688L165.5 47.0508L226 32.4688L274.5 15.9688L319.5 0.96875';
export const LINE_GRAPH_PATH_DATA_FOUR =
  'M314.5 0.65625L275.5 12.0859L253 17.1562L221 25.1548L184 38.1548L126.5 54.6548L76 70.6548L1 88.1562';
export const LINE_GRAPH_PATH_DATA_FIVE =
  'M314.5 0.65625L275.5 12.0859L253 17.1562L221 25.1548L184 38.1548L126.5 54.6548L76 70.6548L1 88.1562';

export const WEALTH_PLAN_PIE_CHART_RADIUS = 170;
export const MINIMUM_SLICE_PERCENTAGE = 5;
