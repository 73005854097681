import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CommissionDocumentControllerApi,
  CommissionDocumentResponse,
} from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { fetchTransactionDetails } from '../../../slices/TransactionSlice';
import { RootState } from '../../../types';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import TableLoader from '../../TableLoader';
import { isTeamAdminOrTeamLeader } from '../../../utils/TeamHelper';
import ZenCommissionDocumentsTable from './ZenCommissionDocumentsTable';

interface ZenCommissionDocumentsProps {
  transactionId: string;
  headerName: string;
  isTradeRecordSheet?: boolean;
  isCDA?: boolean;
}

const ZenCommissionDocuments: React.FC<ZenCommissionDocumentsProps> = ({
  transactionId,
  headerName,
  isTradeRecordSheet = false,
  isCDA = false,
}) => {
  const dispatch = useDispatch();
  const {
    auth: { userDetail, isAdmin },
    transaction: { transactionDetailResponse },
  } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const [commissionDocuments, setCommissionDocuments] = useState<
    CommissionDocumentResponse[]
  >([]);
  const isFetchTransaction =
    transactionDetailResponse.data?.id !== transactionId;

  const isAdminOrTeamLeader = isTradeRecordSheet
    ? isAdmin || isTeamAdminOrTeamLeader(userDetail!)
    : true;

  const isTransactionOwner =
    transactionDetailResponse.data?.transactionOwner?.id === userDetail?.id;

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await new CommissionDocumentControllerApi(
        getArrakisConfiguration(),
      ).getAllCommissionDocumentsByTransactionId(transactionId);

      if (isFetchTransaction) {
        await dispatch(fetchTransactionDetails(transactionId));
      }

      setCommissionDocuments(data);
    } catch (e) {
      ErrorService.notifyIgnoreAuthErrors(
        'Unable to fetch commission documents',
        e,
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, isFetchTransaction, transactionId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <TableLoader />;
  }

  return (
    <div className='w-full p-4'>
      <p className='font-zen-body font-regular font-bold text-xl text-zen-dark-9 py-3'>
        {headerName}
      </p>
      <ZenCommissionDocumentsTable
        transaction={transactionDetailResponse.data!}
        commissionDocuments={commissionDocuments}
        isAdminOrTeamLeader={isAdminOrTeamLeader!}
        isTransactionOwner={isTransactionOwner!}
        authUserDetail={userDetail!}
        fetchData={fetchData}
        isTradeRecordSheet={isTradeRecordSheet!}
        isCDA={isCDA}
      />
    </div>
  );
};

export default ZenCommissionDocuments;
