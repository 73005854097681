import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ZenRoadToSuccessSection from '../components/Zen/RoadToSuccess/ZenRoadToSuccessSection';
import ZenRoute from '../components/Zen/ZenRoute';
import {
  ChecklistApi,
  ChecklistResponse,
  ItemResponse,
} from '../openapi/sherlock';
import { RezenObjectTypeEnum } from '../openapi/yada';
import ErrorService from '../services/ErrorService';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { AppDispatch, RootState } from '../types';
import { getFullName } from '../utils/AgentHelper';
import { getBorrower } from '../utils/MortgageUtils';
import { getSherlockConfiguration } from '../utils/OpenapiConfigurationUtils';

interface MortgageRoadToSuccessRouteProps {
  borrowerId: string;
}

const MortgageRoadToSuccessRoute: React.FC<MortgageRoadToSuccessRouteProps> = ({
  borrowerId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    loanResponse: { data: loan },
  } = useSelector((state: RootState) => state.mortgage);
  const borrower = getBorrower(borrowerId, loan?.borrowers || []);

  const fetchJourney = async (): Promise<ChecklistResponse> => {
    const { data } = await new ChecklistApi(
      getSherlockConfiguration(),
    ).getChecklistById(borrower?.roadToSuccessId!);

    return data;
  };

  const onFetchJourneyError = (e: any) => {
    ErrorService.notify('Unable to fetch journey checklist', e, {
      mortgage: {
        id: borrower?.roadToSuccessId!,
      },
    });
  };

  const toggleJourneyItem = async (
    stepId: string,
    toggleState: boolean,
  ): Promise<ItemResponse> => {
    const { data } = await new ChecklistApi(
      getSherlockConfiguration(),
    ).updateComplete(stepId, toggleState);

    return data;
  };

  const onToggleJourneyItemError = (
    e: any,
    stepId: string,
    toggleState: boolean,
  ) => {
    dispatch(
      showErrorToast(
        'We had a problem updating journey status.',
        'Please try again in a few moments.',
      ),
    );

    ErrorService.notify('Unable to update the journey status', e, {
      journey: { itemId: stepId, completed: toggleState },
    });
  };

  return (
    <ZenRoute title={`Mortgage Road To Success - ${getFullName(borrower)}`}>
      <ZenRoadToSuccessSection
        containerType={RezenObjectTypeEnum.Borrower}
        fetchJourney={fetchJourney}
        onFetchJourneyError={onFetchJourneyError}
        toggleJourneyItem={toggleJourneyItem}
        onToggleJourneyItemError={onToggleJourneyItemError}
        getPublicUserInfo
      />
    </ZenRoute>
  );
};

export default MortgageRoadToSuccessRoute;
