import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, values } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { Country, LeoQuestionManagerApi, PairBase } from '../../openapi/leo';
import {
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaResponseCountryEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import { showErrorToastForErrorCode } from '../../slices/ToastNotificationSlice';
import { ISelectOption, RootState } from '../../types';
import { getISelectOptionDefaultValue } from '../../utils/FormUtils';
import { getLeoConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { CANADIAN_STATES, US_STATES } from '../../utils/StateUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenControlledDatePickerInput from '../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledMultiSelectInput from '../Zen/Input/ZenControlledMultiSelectInput';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextAreaInput from '../Zen/Input/ZenControlledTextAreaInput';
import ZenToggleRow from '../Zen/Input/ZenToggleRow';
import ZenButton from '../Zen/ZenButton';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import LQMDeleteModal from './LQMDeleteModal';

interface LQMSidebarModalFormProps {
  isOpen: boolean;
  onClose(): void;
  questionDetails?: PairBase;
  refresh(): void;
}

export enum CountryAll {
  All = 'ALL',
}

type CountryEnum = CountryAll | AdministrativeAreaResponseCountryEnum;

interface FormData {
  is_enabled: boolean;
  assignee_id: ISelectOption | undefined;
  question: string;
  answer: string;
  start_date: string;
  end_date: string;
  country?: ISelectOption<CountryEnum>;
  states?:
    | ISelectOption<AdministrativeAreaResponseStateOrProvinceEnum>[]
    | undefined;
}

const LQMSidebarModalForm: React.FC<LQMSidebarModalFormProps> = ({
  isOpen,
  onClose,
  questionDetails,
  refresh,
}) => {
  const {
    auth: { userDetail },
    userIds: { agentById },
    leo: { assigneeUserIds },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      is_enabled: questionDetails?.is_enabled || false,
      assignee_id: questionDetails?.assignee_id
        ? {
            value: (questionDetails.assignee_id as unknown) as string,
            label:
              questionDetails.assignee_id === userDetail?.id
                ? 'Me'
                : `${agentById[
                    (questionDetails.assignee_id! as unknown) as string
                  ]?.firstName!} ${agentById[
                    (questionDetails.assignee_id! as unknown) as string
                  ]?.lastName!}`,
          }
        : undefined,
      question: questionDetails?.question,
      answer: questionDetails?.answer,
      start_date: questionDetails?.start_date,
      end_date: questionDetails?.end_date,
      country: getISelectOptionDefaultValue<CountryEnum>(
        (questionDetails?.country as AdministrativeAreaResponseCountryEnum) ||
          CountryAll.All,
      ),
      states: questionDetails?.state_province?.map(
        (state: AdministrativeAreaResponseStateOrProvinceEnum) =>
          getISelectOptionDefaultValue(state),
      ),
    },
  });

  const [is_enabled, start_date, end_date, assignee_id, country] = watch([
    'is_enabled',
    'start_date',
    'end_date',
    'assignee_id',
    'country',
  ]);

  const stateOptions = useMemo(() => {
    const stateValues =
      ((country?.value as unknown) as AdministrativeAreaRequestCountryEnum) ===
      AdministrativeAreaRequestCountryEnum.UnitedStates
        ? US_STATES
        : CANADIAN_STATES;

    return values(stateValues)
      .sort((a, z) => a.localeCompare(z))
      .map<ISelectOption>((state) => ({
        value: state,
        label: capitalizeEnum(state),
      }));
  }, [country]);

  useDidUpdateEffect(() => {
    setValue('states', []);
  }, [country]);

  const onSubmit = async (formValues: FormData) => {
    const questionData = {
      is_enabled: formValues.is_enabled,
      assignee_id: formValues.assignee_id?.value,
      question: formValues.question,
      answer: !!formValues.answer ? formValues.answer : undefined,
      start_date: formValues.start_date,
      end_date: formValues.end_date,
      country:
        formValues.country?.value === CountryAll.All
          ? undefined
          : ((formValues.country?.value as unknown) as Country),
      state_province: isEmpty(formValues.states)
        ? undefined
        : formValues.states?.map(({ value }) => value),
    };

    if (questionDetails) {
      try {
        await new LeoQuestionManagerApi(
          getLeoConfiguration(),
        ).updateQaPairLqmQapairPairIdPost(
          (questionDetails.pair_id! as unknown) as string,
          {
            ...questionData,
            updated_by: userDetail!.id!,
          },
        );
        refresh();
        onClose();
      } catch (e) {
        dispatch(showApiErrorModal(e));
        ErrorService.notify('Unable to update the question', e, {
          values: questionData,
        });
        dispatch(
          showErrorToastForErrorCode(
            'We were unable to update the question. Please try again in a few moments.',
            ErrorService.getErrorCode(e),
          ),
        );
      }
    } else {
      try {
        await new LeoQuestionManagerApi(
          getLeoConfiguration(),
        ).createQaPairLqmQapairPost({
          ...questionData,
          user_id: userDetail!.id!,
        });
        refresh();
        onClose();
      } catch (e) {
        dispatch(showApiErrorModal(e));
        ErrorService.notify('Unable to create a new question', e, {
          values: questionData,
        });
        dispatch(
          showErrorToastForErrorCode(
            'We were unable to create a new question. Please try again in a few moments.',
            ErrorService.getErrorCode(e),
          ),
        );
      }
    }
  };

  return (
    <ZenSidebarModal
      title={questionDetails ? 'Edit Question' : 'New Draft'}
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <form
        className='flex flex-col justify-between '
        onSubmit={handleSubmit(onSubmit)}
        title='edit-leo-questionnaire-form'
      >
        <div className='border-b border-coolGray-200 p-4'>
          <div className='flex align-center justify-between items-center'>
            <div>
              <ZenToggleRow
                value={is_enabled}
                onChange={(value) => setValue('is_enabled', value)}
                title={is_enabled ? 'Enabled' : 'Disabled'}
              />
            </div>
            <div className='flex items-center'>
              <p className='min-w-fit pr-3'>Assigned To</p>
              <div className='w-80'>
                <ZenControlledSelectInput<FormData, `assignee_id`>
                  control={control}
                  name='assignee_id'
                  placeholder='Select User'
                  options={[
                    {
                      value: userDetail?.id!,
                      label: 'Me',
                    },
                    ...assigneeUserIds
                      .filter((userId) => userId !== userDetail?.id)
                      .map((assigneeId: string) => {
                        return {
                          value: assigneeId,
                          label:
                            assigneeId === userDetail?.id
                              ? 'Me'
                              : `${agentById[assigneeId]
                                  ?.firstName!} ${agentById[assigneeId]
                                  ?.lastName!}`,
                        };
                      }),
                  ]}
                  shouldUnregister={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='px-4 pt-4 pb-72'>
          <ZenControlledTextAreaInput<FormData, 'question'>
            control={control}
            label='Question'
            placeholder='Type your question here'
            name='question'
            rows={3}
            rules={{
              required: 'Question is required',
            }}
            isRequired
          />

          <div className='mt-4'>
            <ZenControlledTextAreaInput<FormData, 'answer'>
              control={control}
              label='Answer'
              placeholder='Type your answer here'
              name='answer'
              rows={7}
              rules={{
                validate: (value) => {
                  if (is_enabled && !value) {
                    return 'Answer is required for enabled question';
                  }
                  if (value) {
                    const errorMessage =
                      "Please remove the answer in order to change the assignee. You cannot change the assignee when there's an existing answer.";
                    if (
                      questionDetails &&
                      questionDetails.assignee_id &&
                      questionDetails.assignee_id !== assignee_id?.value &&
                      assignee_id?.value !== userDetail?.id
                    ) {
                      return errorMessage;
                    }
                    if (
                      !questionDetails &&
                      assignee_id?.value &&
                      assignee_id?.value !== userDetail?.id
                    ) {
                      return errorMessage;
                    }
                  }
                  return undefined;
                },
              }}
              isRequired={is_enabled}
            />
          </div>

          <div className='mt-4'>
            <ZenControlledSelectInput<FormData, 'country'>
              name='country'
              control={control}
              label='Country'
              subLabel='(Please select a country to see states selection)'
              placeholder='Select Country'
              options={[
                ...values({
                  ...CountryAll,
                  ...AdministrativeAreaResponseCountryEnum,
                }).map((country) => ({
                  value: country,
                  label: capitalizeEnum(country),
                })),
              ]}
            />
          </div>

          {country?.value !== CountryAll.All && (
            <div className='mt-4'>
              <ZenControlledMultiSelectInput<FormData, 'states'>
                name='states'
                label='States'
                placeholder='Select States'
                control={control}
                options={stateOptions}
                closeMenuOnSelect={false}
              />
            </div>
          )}

          <div className='border border-coolGray-200 p-4 mt-4 rounded-lg'>
            <div className='flex items-center space-x-5'>
              <div className='flex items-center'>
                <p className='min-w-fit pr-3 font-zen-body font-normal text-zen-dark-7 text-base'>
                  Start On
                </p>
                <ZenControlledDatePickerInput<FormData, 'start_date'>
                  control={control}
                  name='start_date'
                  placeholder='MM/DD/YYYY'
                  datePickerConfig={{
                    minDate: DateTime.now().toJSDate(),
                    maxDate: end_date
                      ? DateTime.fromFormat(end_date, 'yyyy-MM-dd')
                          .minus({ days: 1 })
                          .toJSDate()
                      : undefined,
                  }}
                  icon={
                    <FontAwesomeIcon
                      icon={faCalendar}
                      title='calendar'
                      className='text-primary-blue'
                      size='lg'
                    />
                  }
                />
              </div>

              <div className='flex items-center'>
                <p className='min-w-fit pr-3 font-zen-body font-normal text-zen-dark-7 text-base'>
                  End On
                </p>
                <ZenControlledDatePickerInput<FormData, 'end_date'>
                  control={control}
                  name='end_date'
                  placeholder='MM/DD/YYYY'
                  datePickerConfig={{
                    minDate: start_date
                      ? DateTime.fromFormat(start_date, 'yyyy-MM-dd')
                          .plus({ days: 1 })
                          .toJSDate()
                      : DateTime.now().plus({ days: 1 }).toJSDate(),
                  }}
                  icon={
                    <FontAwesomeIcon
                      icon={faCalendar}
                      title='calendar'
                      className='text-primary-blue'
                      size='lg'
                    />
                  }
                />
              </div>
            </div>
            <div className='flex mt-4'>
              <FontAwesomeIcon
                icon={solid('circle-info')}
                size='lg'
                className='mr-2 mt-1'
              />
              <p className='font-zen-body font-normal text-zen-dark-12 text-sm'>
                If the start date is left empty, the question will be shown
                starting immediately. If the end date is left empty, then the
                question will be shown indefinitely into the future.
              </p>
            </div>
          </div>
        </div>

        <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-between items-center left-0 right-0 px-3 absolute py-2 w-full'>
          {questionDetails ? (
            <div className='w-40'>
              <ZenButton
                type='button'
                onClick={() => setIsDeleteModalOpen(true)}
                label='Delete'
                variant='danger-outline'
                LeftIconComponent={
                  <FontAwesomeIcon
                    icon={regular('trash')}
                    size='lg'
                    className='mr-2 mt-1'
                  />
                }
                isFullWidth
              />
            </div>
          ) : (
            <div className='w-40'>
              <ZenButton
                type='button'
                onClick={onClose}
                label='Cancel'
                variant='secondary-outline'
                isFullWidth
              />
            </div>
          )}
          <div className='w-40'>
            <ZenButton
              isDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              type='submit'
              label='Submit'
              isFullWidth
            />
          </div>
        </div>
      </form>

      {isDeleteModalOpen && (
        <LQMDeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          questionDetails={questionDetails}
          refresh={refresh}
        />
      )}
    </ZenSidebarModal>
  );
};

export default LQMSidebarModalForm;
