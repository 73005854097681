import { isEmpty } from 'lodash';
import {
  PriceAndDateInfoRequestRepresentationTypeEnum,
  TransactionResponse,
} from '../openapi/arrakis';
import { AgentResponse, OfficeResponse } from '../openapi/yenta';
import { CreateTransactionFormState } from '../components/Zen/Transaction/CreateTransaction/ZenCreateTransactionSteps';
import { isSaleTransaction } from './TransactionHelper';
import { isCanadianUser } from './AgentHelper';

export function showDoubleEnderParticipantInTx(
  tx: TransactionResponse,
  office?: OfficeResponse,
): boolean {
  if (
    tx?.buySideRepresentation ||
    isEmpty(tx?.doubleEnderInfo?.otherCommissionInfo)
  ) {
    return false;
  }
  if (isSaleTransaction(tx?.transactionType!)) {
    return !!office?.paysOtherAgent;
  }
  return true;
}

export function showTxBuilderDoubleEnderCommissionPayerStep({
  isDoubleEnderEnabled,
  userDetail,
  formValues,
}: {
  isDoubleEnderEnabled: boolean;
  userDetail: AgentResponse | null;
  formValues: CreateTransactionFormState;
}): boolean {
  return !(
    isDoubleEnderEnabled &&
    !!formValues.otherBrokerageInfo.realAgent?.value &&
    isCanadianUser(userDetail?.accountCountry!) &&
    formValues.transactionOwnerRepresent ===
      PriceAndDateInfoRequestRepresentationTypeEnum.Buyer
  );
}
