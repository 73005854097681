import { flatten } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import {
  AiFillFacebook,
  AiFillGoogleCircle,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillYoutube,
  AiOutlineGlobal,
  AiOutlineTwitter,
} from 'react-icons/ai';
import { FaYelp } from 'react-icons/fa';
import { IoMdMicrophone } from 'react-icons/io';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { SiZillow } from 'react-icons/si';
import { useDispatch } from 'react-redux';
import useHideBrokerNumberFromAgent from '../../../hooks/useHideBrokerNumbersFromAgent';
import { AgentResponse } from '../../../openapi/yenta';
import { fetchDriverLicense } from '../../../slices/AgentSlice';
import { AppDispatch } from '../../../types';
import { hasSocialMediaLinks } from '../../../utils/AgentHelper';
import { cn } from '../../../utils/classUtils';
import {
  capitalizeEnum,
  formatAddressObject,
  formatPhoneNumber,
} from '../../../utils/StringUtils';
import SelfOrAdminOnly from '../../auth/SelfOrAdminOnly';
import DataTable from '../../DataTable';
import ZenAction from '../../Zen/ZenAction';
import ZenCard from '../../Zen/ZenCard';
import ZenDataRow from '../../Zen/ZenDataRow';

interface ZenBasicInformationCardProps {
  agent: AgentResponse;
  setOpenEditAgentDetailsSideModal?(val: boolean): void;
  readonly?: boolean;
}

const ZenBasicInformationCard: React.FC<ZenBasicInformationCardProps> = ({
  agent,
  setOpenEditAgentDetailsSideModal,
  readonly,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const handleDownloadDocument = useCallback(
    async (id: string) => {
      let data = await dispatch(fetchDriverLicense(id));
      if (!!data) {
        window.open(data, '_blank');
      }
    },
    [dispatch],
  );

  const hideBrokerNumber = useHideBrokerNumberFromAgent(agent.type!);

  return (
    <div>
      <ZenCard
        title='Basic Information'
        RightComponent={
          !readonly ? (
            <ZenAction
              text='Edit'
              onClick={() => setOpenEditAgentDetailsSideModal?.(true)}
              icon={
                <MdOutlineModeEditOutline
                  className='text-primary-blue'
                  size={20}
                />
              }
            />
          ) : undefined
        }
      >
        <DataTable>
          <ZenDataRow
            name='Birthday'
            value={
              agent.birthDate
                ? `${DateTime.fromISO(agent.birthDate).toFormat('MMMM d')}`
                : 'N/A'
            }
          />
          <ZenDataRow
            name='Joined Real'
            value={
              agent.joinDate
                ? `${DateTime.fromISO(agent.joinDate!).toFormat('LL/dd/yyyy')}`
                : 'N/A'
            }
          />
          <ZenDataRow name='Title' value={agent.title || 'N/A'} />
          {!hideBrokerNumber && (
            <ZenDataRow
              name='Phone No.'
              value={formatPhoneNumber(agent.phoneNumber) || 'N/A'}
            />
          )}
          <ZenDataRow
            name='Address'
            value={
              !!agent.addresses?.length
                ? agent.addresses.map((address) => (
                    <p key={address.type}>
                      {address.type && capitalizeEnum(address.type)}:{' '}
                      <span>{formatAddressObject(address)}</span>
                    </p>
                  ))
                : 'N/A'
            }
          />
          <SelfOrAdminOnly agentId={agent.id}>
            <ZenDataRow
              name='Driver License'
              value={
                !!agent?.driverLicenseImagePath ? (
                  <button
                    onClick={() => handleDownloadDocument(agent?.id!)}
                    className='font-zen-body font-semibold text-sm text-primary-blue'
                  >
                    View
                  </button>
                ) : (
                  'N/A'
                )
              }
            />
          </SelfOrAdminOnly>
          <ZenDataRow
            name='Languages'
            value={
              !!agent.languages?.length
                ? `Speaks ${agent.languages
                    ?.map((language) => capitalizeEnum(language))
                    .join(', ')}`
                : 'N/A'
            }
          />
          <ZenDataRow name='Bio' value={agent.about || 'N/A'} />
          <ZenDataRow
            name='Work With'
            value={
              agent.competencies?.map((c) => capitalizeEnum(c)).join(', ') ||
              'N/A'
            }
          />
          <ZenDataRow
            name='State/Province'
            value={
              flatten(
                agent!.applications!.map((a) =>
                  (a.doesBusinessInExtended || []).map((b) =>
                    capitalizeEnum(
                      b.licenseResponse?.administrativeArea!.stateOrProvince!,
                    ),
                  ),
                ),
              ).join(', ') || 'N/A'
            }
          />
          <ZenDataRow
            name='Service Area Postal Codes'
            value={agent.serviceAreaZipcodes || 'N/A'}
          />
          <ZenDataRow
            name='Social Media'
            value={
              <div className='flex flex-col divide-y'>
                <div
                  className={cn(
                    'grid justify-items-start pr-4 grid-flow-col visible',
                    !hasSocialMediaLinks(agent) && 'hidden',
                  )}
                >
                  {agent.facebookURL && (
                    <a
                      href={agent.facebookURL}
                      className='py-1'
                      target='_blank'
                      rel='noreferrer'
                      data-testid='anchor'
                    >
                      <AiFillFacebook size={20} />
                    </a>
                  )}
                  {agent.twitterURL && (
                    <a
                      href={agent.twitterURL}
                      className='py-1'
                      target='_blank'
                      rel='noreferrer'
                      data-testid='anchor'
                    >
                      <AiOutlineTwitter size={20} />
                    </a>
                  )}
                  {agent.instagramURL && (
                    <a
                      href={agent.instagramURL}
                      className='py-1'
                      target='_blank'
                      rel='noreferrer'
                      data-testid='anchor'
                    >
                      <AiFillInstagram size={20} />
                    </a>
                  )}
                  {agent.youtubeURL && (
                    <a
                      href={agent.youtubeURL}
                      className='py-1'
                      target='_blank'
                      rel='noreferrer'
                      data-testid='anchor'
                    >
                      <AiFillYoutube size={20} />
                    </a>
                  )}
                  {agent.personalWebsiteURL && (
                    <a
                      href={agent.personalWebsiteURL}
                      target='_blank'
                      className='py-1'
                      rel='noreferrer'
                      data-testid='anchor'
                    >
                      <AiOutlineGlobal size={20} />
                    </a>
                  )}
                  {agent.clubhouseURL && (
                    <a
                      href={agent.clubhouseURL}
                      className='py-1'
                      target='_blank'
                      rel='noreferrer'
                      data-testid='anchor'
                    >
                      <IoMdMicrophone size={20} />
                    </a>
                  )}
                  {agent.googleBusinessProfileURL && (
                    <a
                      href={agent.googleBusinessProfileURL}
                      target='_blank'
                      rel='noreferrer'
                      data-testid='anchor'
                    >
                      <AiFillGoogleCircle size={20} />
                    </a>
                  )}
                  {agent.linkedInURL && (
                    <a
                      href={agent.linkedInURL}
                      className='py-1'
                      target='_blank'
                      rel='noreferrer'
                      data-testid='anchor'
                    >
                      <AiFillLinkedin size={20} />
                    </a>
                  )}
                  {agent.yelpURL && (
                    <a
                      href={agent.yelpURL}
                      className='py-1'
                      target='_blank'
                      rel='noreferrer'
                      data-testid='anchor'
                    >
                      <FaYelp size={20} />
                    </a>
                  )}
                  {agent.zillowURL && (
                    <a
                      href={agent.zillowURL}
                      className='py-1'
                      target='_blank'
                      rel='noreferrer'
                      data-testid='anchor'
                    >
                      <SiZillow size={20} />
                    </a>
                  )}
                </div>
              </div>
            }
          />
        </DataTable>
      </ZenCard>
    </div>
  );
};

export default ZenBasicInformationCard;
