import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';
import ZenUserPill from '../Zen/ZenUserPill';

const AssigneeCell = ({ original }: any) => {
  const {
    userIds: { agentById },
  } = useSelector((state: RootState) => state);
  const agent = useMemo(
    () => agentById[(original?.assignee_id! as unknown) as string],
    [agentById, original?.assignee_id],
  );

  return (
    <div className='w-max'>
      <ZenUserPill
        name={
          agent?.firstName && agent?.lastName
            ? `${agent?.firstName} ${agent?.lastName}`
            : 'N/A'
        }
        imageUrl={agent?.avatar}
        backgroundVariant='background'
        noWrap
      />
    </div>
  );
};

export default AssigneeCell;
