import React from 'react';

interface GeminiProfileHeaderRowCellProps {
  leftComponentIcon?: string;
  label: string | React.ReactElement;
  value?: string;
  rightComponent?: React.ReactElement;
  dataTestId?: string;
}
const GeminiProfileHeaderRowCell: React.FC<GeminiProfileHeaderRowCellProps> = ({
  leftComponentIcon,
  label,
  value,
  rightComponent,
  dataTestId,
}) => {
  return (
    <div
      className='flex flex-row items-center font-zen-body-2 text-sm text-white py-1'
      data-testId={dataTestId}
    >
      <div className='flex items-center shrink-0'>
        {leftComponentIcon && (
          <span className='h-7 w-7 flex items-center justify-center shrink-0'>
            <img src={leftComponentIcon} alt='img' />
          </span>
        )}
        <span className='font-semibold leading-4 shrink-0'>{label}</span>
      </div>
      <div className='flex items-center flex-wrap'>
        {value && (
          <span className='font-light leading-4 ml-1 break-all'>{value}</span>
        )}
        {rightComponent && rightComponent}
      </div>
    </div>
  );
};

export default GeminiProfileHeaderRowCell;
