import {
  FaRegCheckCircle,
  FaRegQuestionCircle,
  FaRegThumbsDown,
} from 'react-icons/fa';
import { CalendarEventResponseRsvpStatusEnum } from '../../../openapi/yenta';
import { cn } from '../../../utils/classUtils';

type ZenSelectActiveVariant =
  | 'primary-outline'
  | 'danger-outline'
  | 'black-outline';

export interface ZenSelectOption {
  label: string;
  value: CalendarEventResponseRsvpStatusEnum;
  disabled?: boolean;
}

export interface ZenEventGroupRadioButtonProps {
  options: Array<ZenSelectOption>;
  onSubmit: (status: CalendarEventResponseRsvpStatusEnum) => void;
  selected?: CalendarEventResponseRsvpStatusEnum;
}

const ZenEventGroupRadioButton: React.FC<ZenEventGroupRadioButtonProps> = ({
  options,
  onSubmit,
  selected,
}) => {
  const activeVariantClassMap: Record<ZenSelectActiveVariant, string> = {
    'primary-outline': 'border-2 border-primary-blue text-primary-blue',
    'danger-outline': 'border-2 border-zen-danger text-zen-danger',
    'black-outline': 'border-2 border-zen-dark text-zen-dark',
  };

  const rsvpStatusToVariantMap: Record<
    CalendarEventResponseRsvpStatusEnum,
    ZenSelectActiveVariant
  > = {
    [CalendarEventResponseRsvpStatusEnum.Yes]: 'primary-outline',
    [CalendarEventResponseRsvpStatusEnum.No]: 'danger-outline',
    [CalendarEventResponseRsvpStatusEnum.Maybe]: 'black-outline',
  };

  const rsvpStatusToIconMap: Record<
    CalendarEventResponseRsvpStatusEnum,
    React.ReactElement
  > = {
    [CalendarEventResponseRsvpStatusEnum.Yes]: (
      <FaRegCheckCircle fontSize={18} />
    ),
    [CalendarEventResponseRsvpStatusEnum.No]: <FaRegThumbsDown fontSize={18} />,
    [CalendarEventResponseRsvpStatusEnum.Maybe]: (
      <FaRegQuestionCircle fontSize={18} />
    ),
  };

  return (
    <div
      className='flex flex-row items-center md:space-x-5 space-x-2'
      key={options.map((i) => i.value).join()}
    >
      {options.map((options) => {
        const isActive = options.value === selected;

        return (
          <button
            name={options.label}
            key={options.label}
            disabled={options.disabled}
            onClick={() => onSubmit(options.value)}
            className={cn(
              'flex items-center md:px-5 px-2 py-2 md:space-x-3 space-x-1 rounded-[40px]',
              isActive
                ? activeVariantClassMap[rsvpStatusToVariantMap[options.value]]
                : 'border border-zen-dark-7 text-zen-dark-7',
            )}
          >
            {rsvpStatusToIconMap[options.value]}
            <span className='font-zen-body font-semibold md:text-base text-sm'>
              {options.label}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default ZenEventGroupRadioButton;
