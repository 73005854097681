import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import ButtonV2 from '../ButtonV2';
import ServicePill from '../ServicePill';
import {
  REAL_TITLE_QUOTE,
  RESOURCES,
} from '../../constants/RealTitleConstants';

const RealClosingServicesNeedEstimateSection: React.FC = () => {
  return (
    <div
      className='md:px-10'
      data-testid='real-closing-services-need-estimate-section'
    >
      <div className='flex flex-col md:flex-row md:justify-between md:items-center gap-4 md:gap-12'>
        <div>
          <h1 className='font-poppins text-[40px] font-semibold leading-[48px]'>
            <span>Need a</span>
            <span className='text-rezen-blue-600'> Quote</span>
            <span> or</span>
            <span className='text-rezen-blue-600'> Estimate?</span>
          </h1>
          <p className='font-inter text-primary-dark text-lg font-light mt-2 max-w-[700px]'>
            One Real Title is here to provide quotes, estimates and more with
            Real Title fees. Here’s a list of resources we can get provide.
          </p>
        </div>
        <ButtonV2
          label='Request a Quote'
          icon={faArrowUpRightFromSquare}
          className='whitespace-nowrap'
          onClick={() => window.open(REAL_TITLE_QUOTE, '_blank')}
        />
      </div>
      <div className='h-[1px] w-full bg-gray-200 my-3 md:my-5' />
      <div className='flex gap-3 flex-wrap'>
        {RESOURCES.map((resource) => (
          <ServicePill key={resource} label={resource} />
        ))}
      </div>
    </div>
  );
};

export default RealClosingServicesNeedEstimateSection;
