import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { faStar, faTrophyStar, faUser } from '@fortawesome/pro-solid-svg-icons';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { debounce } from 'lodash';
import millify from 'millify';
import { Earning } from '../WealthManagementTypes';
import ZenButton from '../../Zen/ZenButton';
import {
  getOfficialPlan,
  setWealthKnowledgeLandingPage,
} from '../../../slices/WealthManagementSlice';
import CelebrateEmoji from '../../../assets/img/wealthManagement/zen-celebrate-emoji.png';
import NavigationIcon from '../../../assets/img/wealthManagement/navigation.png';
import CommissionIncomeGraph from '../../../assets/img/wealthManagement/commission-income-graph.png';
import MakeMoreMoney from '../../../assets/img/wealthManagement/make-more-money.svg';
import { USOnly } from '../USOnly';
import { AnalyticsEventEnum, RootState } from '../../../types';
import AnalyticsService from '../../../services/AnalyticsService';
import { displayFormattedFixedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import { LearnMoreCarousel } from './LearnMoreCarousel';
import { PrimaryGradientText } from './PrimaryGradientText';
import ZenLandingPageIntro from './ZenLandingPageIntro';

const CommissionIncomeInformation = () => {
  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );

  const preCapCommissionSplit =
    officialPlanAndAwardData?.commissionPlan?.preCapRealCommissionSplit ?? 0;
  return (
    <div
      className='pb-10 pt-32 mt-5 relative'
      style={{
        backgroundColor: '#E5F2FF',
      }}
    >
      <div className='flex flex-col justify-center items-center my-10'>
        <div className='text-5xl mb-5 font-extrabold'>
          <PrimaryGradientText>
            <span className=' border-primary-blue'>
              {100 - preCapCommissionSplit}/{preCapCommissionSplit}
            </span>{' '}
            SPLIT
          </PrimaryGradientText>
        </div>
        <div className='text-2xl text-neutral-800 lg:w-1/2 font-zen-body-2 text-center'>
          After capping, <span className='font-bold'>Agents keep 100%</span> and{' '}
          <span className=' font-bold'>pay a fixed transaction fee</span>
        </div>
      </div>
    </div>
  );
};

const CappingInformation = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const dispatch = useDispatch();

  const handelViewEliteAwardDetails = () => {
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.WEALTH_PLAN_LP_COMMISSION_INCOME_CLICK_ELITE_AWARD_DETAILS,
    );
    dispatch(setWealthKnowledgeLandingPage(Earning.ELITE_PRODUCTION_AWARD));
  };

  return (
    <div className='flex flex-col justify-center items-center bg-black px-10 lg:px-36 py-10'>
      <div className='text-4xl font-bold text-white lg:w-2/4 text-center mb-5'>
        <PrimaryGradientText>Different caps</PrimaryGradientText> based on your
        business model
      </div>
      <div className='text-white lg:w-3/4 text-center mb-5 font-zen-body-2'>
        Reach capped status by meeting your annual cap contribution requirement,
        which resets on your anniversary date
      </div>
      <div className='lg:w-4/5 mb-5'>
        <div className='flex w-full bg-gray-900 rounded-tr-lg rounded-tl-lg'>
          <div
            onClick={() => {
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.WEALTH_PLAN_LP_COMMISSION_INCOME_CLICK_12K_CAP,
              );
              setSelectedTab(0);
            }}
            className={`py-2 px-6 rounded-tr-lg rounded-tl-lg w-1/3 text-center cursor-pointer ${
              selectedTab === 0
                ? 'rounded-tl-lg text-primary-dark'
                : 'bg-gray-900 text-gray-400 border-l border-r border-t border-gray-700 '
            }`}
            style={{
              ...(selectedTab === 0 && {
                background: '#DBECFF',
              }),
            }}
          >
            $12K CAP
          </div>
          <div
            onClick={() => {
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.WEALTH_PLAN_LP_COMMISSION_INCOME_CLICK_6K_CAP,
              );
              setSelectedTab(1);
            }}
            className={`py-2 px-6 rounded-tr-lg rounded-tl-lg w-1/3 text-center cursor-pointer ${
              selectedTab === 1
                ? 'rounded-tl-lg text-primary-dark'
                : 'bg-gray-900 text-gray-400 border-r border-t border-gray-700 '
            }`}
            style={{
              ...(selectedTab === 1 && {
                background: '#DBECFF',
              }),
            }}
          >
            $6K CAP
          </div>
          <div
            onClick={() => {
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.WEALTH_PLAN_LP_COMMISSION_INCOME_CLICK_4K_CAP,
              );
              setSelectedTab(2);
            }}
            className={`py-2 px-6 rounded-tr-lg rounded-tl-lg w-1/3 text-center cursor-pointer ${
              selectedTab === 2
                ? 'rounded-tl-lg text-primary-dark'
                : 'bg-gray-900 text-gray-400 border-r border-t border-gray-700'
            }`}
            style={{
              ...(selectedTab === 2 && {
                background: '#DBECFF',
              }),
            }}
          >
            $4K CAP
          </div>
        </div>
        <div className='bg-[#ECF5FF] items-center flex flex-col py-5 px-3 rounded-br-md rounded-bl-md mb-7'>
          <div>Independent Agents and Team Leaders</div>
          <div className='font-extrabold mb-6'>
            receive 100% of their commission after they cap at{' '}
            <span className='bg-black text-white py-1 px-2 rounded'>
              $
              {
                {
                  0: '12,000',
                  1: '6,000',
                  2: '4,000',
                }[selectedTab]
              }
              .
            </span>
          </div>
          {selectedTab === 0 && (
            <>
              <div className='bg-[#D3E8FF] w-full flex p-3 rounded-lg mb-3'>
                <div className='rounded-lg h-16 w-24 py-5 px-9 bg-[#EFF7FF] mr-5'>
                  <FontAwesomeIcon
                    size='lg'
                    icon={faUser}
                    className='text-zen-light-blue-1'
                  />
                </div>
                <div>
                  <div className='text-primary-dark font-extrabold'>
                    INDEPENDENT AGENTS
                  </div>
                  <div>Independent agents are not a member of any team.</div>
                </div>
              </div>
              <div className='bg-[#D3E8FF] w-full flex p-3 rounded-lg'>
                <div className='rounded-lg h-16 w-24 py-5 px-9 bg-[#EFF7FF] mr-5'>
                  <FontAwesomeIcon
                    size='lg'
                    icon={faUsers}
                    style={{
                      // @ts-ignore
                      '--fa-primary-color': '#B384E9',
                      // @ts-ignore
                      '--fa-secondary-color': '#2879FF',
                    }}
                  />
                </div>
                <div>
                  <div className='text-primary-dark font-extrabold'>
                    TEAM LEADERS
                  </div>
                  <div>
                    Team leaders carry a $12K cap, giving their team members a
                    unique advantage with lower cap options.
                  </div>
                </div>
              </div>
            </>
          )}
          {selectedTab === 1 && (
            <div className='bg-[#D3E8FF] w-full flex p-3 rounded-lg'>
              <div className='rounded-lg h-16 w-24 py-5 px-9 bg-[#EFF7FF] mr-5'>
                <FontAwesomeIcon
                  size='lg'
                  icon={faUsers}
                  style={{
                    // @ts-ignore
                    '--fa-primary-color': '#B384E9',
                    // @ts-ignore
                    '--fa-secondary-color': '#B384E9',
                  }}
                />
              </div>
              <div>
                <div className='text-primary-dark font-extrabold'>
                  STANDARD TEAM MEMBER
                </div>
                <div>
                  Team members can participate in all the benefits of Real
                  including training, revenue share and stock purchase programs.
                </div>
              </div>
            </div>
          )}
          {selectedTab === 2 && (
            <div className='bg-[#D3E8FF] w-full flex p-3 rounded-lg'>
              <div className='rounded-lg h-16 w-24 py-5 px-9 bg-[#EFF7FF] mr-5'>
                <FontAwesomeIcon
                  size='lg'
                  icon={faStar}
                  className='text-amber-500'
                />
              </div>
              <div>
                <div className='text-primary-dark font-extrabold'>
                  PLATINUM TEAM MEMBER
                </div>
                <div>
                  Platinum team members enjoy a lower, $4K cap.
                  <br />
                  <br />
                  To qualify as Platinum, a team must have at least $100 million
                  in annual sales volume and a minimum of 12 members.
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='flex flex-col items-center text-lg'>
          <div className='text-white'>After capping, agents can unlock the</div>
          <div className='text-[#F5C058] mb-3 uppercase lg:w-3/5 text-center'>
            Elite Agent Production Award and earn a $16k stock award
          </div>
          <div>
            <ZenButton
              label='Elite Award Details →'
              isFullWidth
              onClick={handelViewEliteAwardDetails}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const RealJourney = () => {
  const dispatch = useDispatch();
  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );

  const handleEliteAwardDetails = () => {
    dispatch(setWealthKnowledgeLandingPage(Earning.ELITE_PRODUCTION_AWARD));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string) => {
      AnalyticsService.instance().logEvent(event);
    }, 500),
    [],
  );

  return (
    <div className='mb-10'>
      <div className='flex justify-between'>
        <div>
          <div className='p-10 flex justify-end'>
            <img
              src={NavigationIcon}
              alt='navigation icon'
              className='w-20 h-20 lg:w-80 lg:h-80 lg:opacity-20'
            />
          </div>
          <div className='text-5xl font-bold text-primary-dark pl-20 pb-10 -mt-24'>
            Your <PrimaryGradientText>Real Journey</PrimaryGradientText>
          </div>
        </div>
      </div>
      <div className='px-5'>
        <div className='relative'>
          <div className='absolute border-b-8 border-[#ECECEC] w-full top-2 left-5' />
        </div>
        <div
          className='flex overflow-x-scroll relative w-full gap-5 px-5 pb-10'
          onScroll={() =>
            logAnalyticsEvent(
              AnalyticsEventEnum.WEALTH_PLAN_LP_COMMISSION_INCOME_SCROLL_YOUR_JOURNEY,
            )
          }
        >
          <div>
            <div className='relative mb-1 z-50'>
              <div className='bg-primary-blue w-8 h-6 text-white rounded relative text-center'>
                1.
                <div className='border-r-2 border-primary-blue h-6 w-1 absolute left-[48%] -translate-x-1/2 ' />
                <div className='bg-primary-blue w-2 h-2 rotate-45 absolute left-1/2 -translate-x-1/2 bottom-[-2rem]' />
              </div>
            </div>
            <div className='bg-zen-light-blue rounded drop-shadow-lg w-[350px] p-5 pt-8 mt-3 '>
              <PrimaryGradientText>JOIN REAL</PrimaryGradientText>
              <ul className='list-disc list-outside  text-zen-dark-13 font-zen-body ml-6'>
                <li className='font-bold'>No Monthly Fees!</li>
                <li>
                  Launch your business immediately with our tools and platform
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div className='relative mb-1 z-50'>
              <div className='bg-white w-8 h-6 text-black border border-black rounded relative text-center'>
                2.
                <div className='border-r-2 border-black h-6 w-1 absolute left-[48%] -translate-x-1/2 ' />
                <div className='bg-black w-2 h-2 rotate-45 absolute left-1/2 -translate-x-1/2 bottom-[-2rem]' />
              </div>
            </div>
            <div className='bg-zen-light-blue rounded drop-shadow-lg w-80 p-5 pt-8 mt-3 '>
              <PrimaryGradientText>START PRODUCING</PrimaryGradientText>
              <div className=' text-zen-dark-13 font-zen-body'>
                Focus on your sales, and we’ll do the rest! Only after you start
                producing do the following fees apply:
              </div>
              <ul className='list-disc list-outside  text-zen-dark-13 font-zen-body ml-6'>
                <li>
                  <span className='font-bold'>
                    {displayFormattedFixedAmountWithCurrency(
                      officialPlanAndAwardData?.commissionPlan
                        ?.annualBrokerageFee,
                      false,
                      0,
                    )}{' '}
                    Annual Brokerage Fee{' '}
                  </span>
                  is paid in 3 installments from your first 3 transactions.
                </li>
                <USOnly>
                  <li>
                    <span className='font-bold'>
                      {displayFormattedFixedAmountWithCurrency(
                        officialPlanAndAwardData?.commissionPlan?.beopFee,
                        false,
                        0,
                      )}{' '}
                      BEOP fee/transaction.
                    </span>{' '}
                    BEOP covers broker review, E&O (professional liability)
                    insurance and processing.
                  </li>
                </USOnly>
              </ul>
            </div>
          </div>
          <div>
            <div className='relative mb-1 z-50'>
              <div className='bg-white w-8 h-6 text-black border border-black rounded relative text-center'>
                3.
                <div className='border-r-2 border-black h-6 w-1 absolute left-[48%] -translate-x-1/2 ' />
                <div className='bg-black w-2 h-2 rotate-45 absolute left-1/2 -translate-x-1/2 bottom-[-2rem]' />
              </div>
            </div>
            <div className='bg-zen-light-blue rounded drop-shadow-lg w-80 p-5 pt-8 mt-3 '>
              <PrimaryGradientText>
                REACH YOUR CAP{' '}
                <img
                  src={CelebrateEmoji}
                  className='w-6 inline-block'
                  alt='Celebrate Emoji'
                />
              </PrimaryGradientText>
              <div className='text-zen-dark-13 font-zen-body'>
                Congrats! You’ve reached your cap. Post-cap, you keep{' '}
                <span className='font-bold'>100% commission</span> and only pay
                a fixed{' '}
                {displayFormattedFixedAmountWithCurrency(
                  officialPlanAndAwardData?.commissionPlan
                    ?.postCapSalesTransactionFee,
                  false,
                  0,
                )}{' '}
                per transaction.
              </div>

              <img
                src={CommissionIncomeGraph}
                alt='commission income graph'
                className='w-[120%]'
              />
              <div className='text-gray-400 text-sm text-center mt-5'>
                *see commission cap structure above
              </div>
            </div>
          </div>
          <div>
            <div className='relative mb-1 z-50'>
              <div className='bg-white w-8 h-6 text-black border border-black rounded relative text-center'>
                4.
                <div className='border-r-2 border-black h-6 w-1 absolute left-[48%] -translate-x-1/2 ' />
                <div className='bg-black w-2 h-2 rotate-45 absolute left-1/2 -translate-x-1/2 bottom-[-2rem]' />
              </div>
            </div>
            <div className='bg-zen-light-blue rounded drop-shadow-lg w-80 p-5 pt-8 mt-3 relative'>
              <div className='absolute -top-4 -right-4 text-amber-400 opacity-20'>
                <FontAwesomeIcon icon={faTrophyStar} size='4x' />
              </div>
              <PrimaryGradientText>
                ACHIEVE ELITE AGENT STATUS
              </PrimaryGradientText>
              <div className='text-zen-dark-13 font-zen-body'>
                Congrats again! Upon achieving Elite Agent status, Real grants
                you a{' '}
                <span className=' font-bold'>
                  $
                  {millify(
                    officialPlanAndAwardData?.eliteEquityAwardPlan?.award
                      ?.amount ?? 0,
                  )}{' '}
                  stock award
                </span>{' '}
                and the transaction fee drops to{' '}
                {displayFormattedFixedAmountWithCurrency(
                  officialPlanAndAwardData?.eliteEquityAwardPlan
                    ?.postEliteSalesTransactionFee,
                  false,
                  0,
                )}
                !
                <div
                  onClick={handleEliteAwardDetails}
                  className='text-primary-blue cursor-pointer'
                >
                  Elite Award Details →
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SpecialBenefits = () => {
  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );
  return (
    <div className='lg:px-20'>
      <div className='bg-black p-5 m-5 rounded-lg relative'>
        <div className='text-4xl font-bold text-white mb-5'>
          Unique <PrimaryGradientText>Benefits</PrimaryGradientText>
        </div>
        <div className='flex gap-2 mb-2'>
          <div className='h-6 w-6 bg-gradient-to-r from-primary-blue to-blue-400 rounded-full'>
            <FontAwesomeIcon icon={faCheck} className='text-white p-1' />
          </div>
          <div className='text-white'>
            <span className='text-lg'>Personal Transaction:</span> Keep 100%
            commission on up to 3 personal transactions per anniversary year for
            just a{' '}
            {displayFormattedFixedAmountWithCurrency(
              officialPlanAndAwardData?.commissionPlan?.personalDealFee,
              false,
              0,
            )}{' '}
            transaction fee
          </div>
        </div>
        <div className='flex gap-2 mb-2'>
          <div className='h-6 w-6 bg-gradient-to-r from-primary-blue to-blue-400 rounded-full'>
            <FontAwesomeIcon icon={faCheck} className='text-white p-1' />
          </div>
          <div className='text-white'>
            <span className='text-lg'>Tools:</span> Get access to
            industry-leading 3rd party CRM Lofty at 80% discount for just
            $40/month
          </div>
        </div>
        <div className='flex gap-2 mb-2'>
          <div className='h-6 w-6 bg-gradient-to-r from-primary-blue to-blue-400 rounded-full'>
            <FontAwesomeIcon icon={faCheck} className='text-white p-1' />
          </div>
          <div className='text-white'>
            <span className='text-lg'>Transaction Platform:</span> Get access to
            reZen, Real&apos;s award-winning transaction management platform and
            integrated broker support for free
          </div>
        </div>
        <div className='flex gap-2 mb-2'>
          <div className='h-6 w-6 bg-gradient-to-r from-primary-blue to-blue-400 rounded-full'>
            <FontAwesomeIcon icon={faCheck} className='text-white p-1' />
          </div>
          <div className='text-white'>
            <span className='text-lg'>Training:</span> Get access to Real
            Academy and over 750 hours of organized and searchable live and
            produced masterclasses on topics to build and grow your business
            from practitioners
          </div>
        </div>
        <div className='flex gap-2 mb-2'>
          <div className='h-6 w-6 bg-gradient-to-r from-primary-blue to-blue-400 rounded-full'>
            <FontAwesomeIcon icon={faCheck} className='text-white p-1' />
          </div>
          <div className='text-white'>
            <span className='text-lg'>Single App:</span> Manage your entire
            business, commissions, and transactions from one app always in the
            palm of your hand
          </div>
        </div>
      </div>
    </div>
  );
};

export const ZenCommissionIncomeKnowledgePage = () => {
  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (officialPlanAndAwardData === undefined) {
      dispatch(getOfficialPlan());
    }
  }, [dispatch, officialPlanAndAwardData]);

  return (
    <>
      <ZenLandingPageIntro
        earningType={Earning.COMMISSION_INCOME}
        sloganImage={MakeMoreMoney}
        subtitle="Let Real's industry leading commission plan work for you"
        headerBackgroundProps={{
          bgVariant: 'blue',
        }}
      />
      <CommissionIncomeInformation />
      <CappingInformation />
      <RealJourney />
      <SpecialBenefits />
      <LearnMoreCarousel />
    </>
  );
};
