import { useParams } from 'react-router-dom';
import ZenRoute from '../components/Zen/ZenRoute';
import InvoiceFormContainer from '../containers/InvoiceFormContainer';
import InvoiceForm from './InvoiceForm';

type Match = {
  id: string;
};

const InvoiceFormPdfRoute: React.FC = () => {
  const { id } = useParams() as Match;

  return (
    <ZenRoute title='Invoice Form'>
      <InvoiceFormContainer id={id}>
        {(invoiceResponse) => (
          <InvoiceForm asyncResponse={invoiceResponse} isPdf />
        )}
      </InvoiceFormContainer>
    </ZenRoute>
  );
};

export default InvoiceFormPdfRoute;
