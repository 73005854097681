import {
  faChevronLeft,
  faChevronRight,
  faRotateRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { ApplicationResponseNextStatusEnum } from '../openapi/yenta';
import { cn } from '../utils/classUtils';
import { getAllDataTooltips } from '../utils/FormUtils';
import { FormBasedOnTypeProps } from './FormBasedOnType';
import IconButton from './IconButton';

const withNextPrevTooltip = () => (
  Component: React.FunctionComponent<FormBasedOnTypeProps<any>>,
) => (props: FormBasedOnTypeProps<any>) => {
  const [index, setIndex] = useState<number>(-1);
  const [tooltipSelectors, setToolTipSelectors] = useState<HTMLElement[]>([]);
  const isTooltipVisible =
    (!props.application ||
      (props.application.nextStatus ===
        ApplicationResponseNextStatusEnum.SignLetterOfIntent &&
        !props.application.loiSignedAt) ||
      (props.application.nextStatus ===
        ApplicationResponseNextStatusEnum.SignIca &&
        !props.application.icaSignedAt)) &&
    tooltipSelectors.length > 0;
  const showPrevious = index > 0 && index < tooltipSelectors.length;

  const getCurrentSelector = useCallback(
    (): HTMLElement | null =>
      tooltipSelectors && index < tooltipSelectors.length
        ? tooltipSelectors![index]
        : document.querySelector('[type="submit"]'),
    [index, tooltipSelectors],
  );

  const nextField = async () => {
    const cssSelector = getCurrentSelector();
    if (cssSelector) {
      cssSelector.classList.remove('form-field-highlight');
    }
    const nextIndex = index < tooltipSelectors.length ? index + 1 : 0;
    setIndex(nextIndex);
  };

  const prevField = async () => {
    const cssSelector = getCurrentSelector();
    if (cssSelector) {
      cssSelector.classList.remove('form-field-highlight');
    }
    setIndex(index - 1);
  };

  const handleIndexChange = useCallback(() => {
    const cssSelector: HTMLElement | null = getCurrentSelector();
    if (cssSelector) {
      cssSelector.classList.add('form-field-highlight');

      cssSelector.focus();

      cssSelector.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      });
    }
  }, [getCurrentSelector]);

  useEffect(() => {
    setToolTipSelectors(getAllDataTooltips());
  }, []);

  useEffect(() => {
    handleIndexChange();
  }, [handleIndexChange]);

  return (
    <div className='w-full'>
      {isTooltipVisible && (
        <div className='sticky top-0 bg-gray-100 py-3 z-50 px-4 lg:px-0'>
          <div
            className={cn(
              'flex flex-row space-x-3 flex-nowrap items-center md:justify-end',
              showPrevious ? 'justify-between' : 'justify-end',
            )}
          >
            {showPrevious && (
              <IconButton
                label='Previous'
                onClick={prevField}
                leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
              />
            )}
            <IconButton
              label={index === tooltipSelectors.length ? 'Start Again' : 'Next'}
              onClick={() => nextField()}
              rightIcon={
                index === tooltipSelectors.length ? (
                  <FontAwesomeIcon icon={faRotateRight} className='text-lg' />
                ) : (
                  <FontAwesomeIcon icon={faChevronRight} />
                )
              }
            />
          </div>
        </div>
      )}
      <Component {...props} />
    </div>
  );
};

export default withNextPrevTooltip;
