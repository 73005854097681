import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { EMAIL_VALIDATIONS } from '../../../utils/Validations';
import ZenControlledEmailInput from '../Input/ZenControlledEmailInput';
import ZenSidebarModalForm from '../ZenSidebarModalForm';
import { UserControllerApi } from '../../../openapi/hermes';
import { getHermesConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showSuccessToast,
  showErrorToast,
} from '../../../slices/ToastNotificationSlice';
import AnalyticsService from '../../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../../types';

interface ZenEmailUnbanFormSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  email: string;
}

const ZenEmailUnbanFormSidebarModal: React.FC<ZenEmailUnbanFormSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<FormData>();

  const onSubmit = async (values: FormData) => {
    try {
      await new UserControllerApi(getHermesConfiguration()).unblockUser(
        values.email,
      );
      dispatch(showSuccessToast('Unblocked email successfully'));
      AnalyticsService.instance().logEvent(AnalyticsEventEnum.UNBAN_USER_EMAIL);
      onClose();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error unblocking email', e, {
        data: {
          email: values.email,
        },
      });
      dispatch(
        showErrorToast(
          'We had a problem unblocking email',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <ZenSidebarModalForm
      title='Unban Email'
      isOpen={isOpen}
      onClose={onClose}
      actionTitle='Save'
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <div className='mt-5 space-y-5'>
        <ZenControlledEmailInput<FormData, 'email'>
          control={control}
          label='Email'
          name='email'
          placeholder='jane@example.com'
          rules={{
            required: 'Email is required',
            ...EMAIL_VALIDATIONS,
          }}
          isRequired
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenEmailUnbanFormSidebarModal;
