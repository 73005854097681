import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import IconButton from '../IconButton';

interface NextPrevPaginationProps {
  currentPage: number;
  lastPage: number;
  goToPage(page: number): void;
}

const NextPrevPagination: React.FC<NextPrevPaginationProps> = ({
  currentPage,
  lastPage,
  goToPage,
}) => {
  return (
    <nav className='inline-block' title='pagination'>
      <ul className='flex pl-0 rounded list-none flex-wrap space-x-2'>
        {currentPage !== 0 && (
          <li>
            <IconButton
              variant='outline'
              onClick={() => goToPage(currentPage - 1)}
              label='Previous'
              rightIcon={
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  fontSize='inherit'
                  className='-mr-1 ml-1 my-1'
                  title='first-page'
                />
              }
            />
          </li>
        )}
        {currentPage !== lastPage - 1 && (
          <li>
            <IconButton
              variant='outline'
              onClick={() => goToPage(currentPage + 1)}
              label='Next'
              rightIcon={
                <FontAwesomeIcon
                  icon={faChevronRight}
                  fontSize='inherit'
                  className='my-1'
                  title='last-page'
                />
              }
            />
          </li>
        )}
      </ul>
    </nav>
  );
};

export default NextPrevPagination;
