import React from 'react';
import T4AField, { T4AFieldProps } from './T4AField';

interface FieldWithBoxCaseProps extends T4AFieldProps {
  value?: string;
}

const T4AFieldWithBoxCase: React.FC<FieldWithBoxCaseProps> = ({
  value,
  ...rest
}) => {
  return (
    <div className='flex space-x-2 items-end'>
      <div>
        <p className='mb-1 text-center'>Box-Case</p>
        <p className='border-2 border-black h-14 px-2 pt-1'>{value}</p>
      </div>
      <div className='flex-grow'>
        <T4AField {...rest} />
      </div>
    </div>
  );
};

export default T4AFieldWithBoxCase;
