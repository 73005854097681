import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faAddressBook, faPlus } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowUpArrowDown,
  faEnvelope,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useAgentsInfo from '../../../../hooks/useAgentsInfo';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useSyncWatchparticipants } from '../../../../hooks/useSyncWatchParticipants';
import {
  ExternalParticipantInfoRoleEnum,
  TransactionBuilderResponse,
} from '../../../../openapi/arrakis';
import {
  AgentResponseAccountCountryEnum,
  DirectoryVendorResponseRoleEnum,
} from '../../../../openapi/yenta/api';
import {
  DirectoryCommonEntityResponse,
  useDirectoryAddress,
} from '../../../../query/directory/useDirectory';
import AnalyticsService from '../../../../services/AnalyticsService';
import ErrorService from '../../../../services/ErrorService';
import { showErrorToast } from '../../../../slices/ToastNotificationSlice';
import {
  addDoubleEnderAgent,
  saveParticipantInfo,
} from '../../../../slices/TransactionBuilderSlice';
import {
  AnalyticsEventEnum,
  AppDispatch,
  AsyncSelectOptionReactElement,
  FeatureFlagTypeEnum,
  RootState,
  YesNoType,
} from '../../../../types';
import { STATE_OR_PROVINCE_ABBREVIATIONS } from '../../../../utils/AnnouncementUtils';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import { searchActiveAgents } from '../../../../utils/TableUtils';
import {
  EMAIL_VALIDATIONS,
  GOOGLE_AUTO_COMPLETE_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../../utils/Validations';
import AvatarLabelComponent from '../../../AgentReports/AvatarLabelComponent';
import ZenRoleDirectoryForm from '../../../Directory/ZenRoleDirectoryForm';
import FeatureFlagDisabledOnly from '../../../FeatureFlagDisabledOnly';
import FeatureFlagEnabledOnly from '../../../FeatureFlagEnabledOnly';
import IconButton from '../../../IconButton';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenContactListSidebar from '../../../Directory/ZenContactListSidebar';
import ZenControlledAsyncCustomSelectInput from '../../Input/ZenControlledAsyncCustomSelectInput';
import ZenControlledEmailInput from '../../Input/ZenControlledEmailInput';
import ZenControlledGoogleAutocompleteSearchInput from '../../Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledPhoneNumberInput from '../../Input/ZenControlledPhoneNumberInput';
import ZenControlledRadioInput from '../../Input/ZenControlledRadioInput';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenButton from '../../ZenButton';
import ZenAnnounce from './ZenAnnounce';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
  Match,
  OtherAgentTypeEnum,
} from './ZenCreateTransactionSteps';
import { FieldArrayPrefix } from './ZenTransactionBuyerAndSellerStep';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';

const ZenTransactionOtherBrokerageInfoStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({
  form: { control, watch, trigger, setValue, getValues },
  onPrevious,
  onNext,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { transactionBuilderId } = useParams<Match>();
  const {
    auth: { userDetail },
    transactionBuilder: { transactionBuilder },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [roleSibebarOpen, setRoleSidebarOpen] = useState<boolean>(false);
  const [currentFormContact, setCurrentFormContact] = useState<
    DirectoryCommonEntityResponse | undefined
  >();
  const [
    isOtherSideUnrepresentedOld,
    otherBrokerageInfo,
    isSearchAddress,
  ] = watch([
    'isOtherSideUnrepresented',
    'otherBrokerageInfo',
    'otherBrokerageInfo.isSearchAddress',
  ]);
  const isDoubleEnderEnabled = useFeatureFlag(FeatureFlagTypeEnum.DOUBLE_ENDER);
  const { getGeocodeAddress } = useDirectoryAddress();
  const handleSidebarToggle = () => () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleSave = (
    _fieldArrayPrefix?: FieldArrayPrefix,
    contact?: DirectoryCommonEntityResponse,
  ) => {
    setValue(
      'otherBrokerageInfo.firstName',
      contact?.firstName ?? contact?.linkedPersons?.[0]?.firstName,
    );
    setValue(
      'otherBrokerageInfo.lastName',
      contact?.lastName ?? contact?.linkedPersons?.[0]?.lastName,
    );
    setValue('otherBrokerageInfo.email', contact?.emailAddress);
    setValue('otherBrokerageInfo.phoneNumber', contact?.phoneNumber);
    setValue('otherBrokerageInfo.companyName', contact?.name);
    setValue('otherBrokerageInfo.address', contact?.address?.oneLine);
  };
  const handleRoleSidebarToggle = () => async () => {
    const values = getValues(`otherBrokerageInfo`);
    const geoAddress =
      values?.location ?? (await getGeocodeAddress(values?.address!));
    setCurrentFormContact({
      ...values,
      emailAddress: values?.email,
      address: {
        oneLine: geoAddress?.formatted_address ?? undefined,
      },
      addressComponents: geoAddress?.address_components ?? [],
      placeId: geoAddress?.place_id,
      name: values?.companyName,
      role: DirectoryVendorResponseRoleEnum.OtherAgent,
    });
    setRoleSidebarOpen((prevState) => !prevState);
  };

  const isOtherSideUnrepresented = isDoubleEnderEnabled
    ? otherBrokerageInfo.otherAgentType === OtherAgentTypeEnum.UNREPRESENTED
    : isOtherSideUnrepresentedOld === YesNoType.YES;
  const isExternalAgent = isDoubleEnderEnabled
    ? otherBrokerageInfo.otherAgentType === OtherAgentTypeEnum.EXTERNAL_AGENT
    : isOtherSideUnrepresentedOld === YesNoType.NO;
  const isRealBrokerageAgent =
    otherBrokerageInfo.otherAgentType === OtherAgentTypeEnum.REAL_AGENT;

  const agentIds = useMemo(() => {
    const coAgents =
      transactionBuilder?.agentsInfo?.coAgents
        // @ts-ignore
        ?.map((p) => p.agentId!)
        .filter((p) => !!p) || [];

    const ownerAgent =
      transactionBuilder?.agentsInfo?.ownerAgent
        ?.map((p) => p.agentId!)
        .filter((p) => !!p) || [];

    const doubleEnderAgentId =
      transactionBuilder?.doubleEnderInfo?.doubleEnderAgentId;
    const doubleEnderIds = doubleEnderAgentId ? [doubleEnderAgentId] : [];

    return [...coAgents, ...ownerAgent, ...doubleEnderIds];
  }, [
    transactionBuilder?.agentsInfo?.coAgents,
    transactionBuilder?.agentsInfo?.ownerAgent,
    transactionBuilder?.doubleEnderInfo?.doubleEnderAgentId,
  ]);

  useAgentsInfo(agentIds);

  useSyncWatchparticipants(
    agentById,
    setValue,
    transactionBuilder?.allCommissionRecipient,
    transactionBuilder?.commissionSplitsInfo,
  );

  useEffect(() => {
    if (isOtherSideUnrepresented || isRealBrokerageAgent) {
      setValue('otherBrokerageInfo.firstName', undefined);
      setValue('otherBrokerageInfo.lastName', undefined);
      setValue('otherBrokerageInfo.email', undefined);
      setValue('otherBrokerageInfo.phoneNumber', undefined);
      setValue('otherBrokerageInfo.companyName', undefined);
      setValue('otherBrokerageInfo.address', undefined);
    }
  }, [setValue, isOtherSideUnrepresented, isRealBrokerageAgent]);

  const handleOtherBrokerageInfoStep = async () => {
    const isValid = await trigger();
    if (isValid) {
      setLoading(true);
      let res: TransactionBuilderResponse | undefined;
      if (isDoubleEnderEnabled && isRealBrokerageAgent) {
        res = await dispatch(
          addDoubleEnderAgent(transactionBuilderId!, {
            agentId: otherBrokerageInfo.realAgent?.value!,
          }),
        );
        if (res) {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.DOUBLE_ENDER_CHOOSE_REAL_AGENT_AS_OTHER_AGENT,
          );
        }
      } else {
        const addressInfo = otherBrokerageInfo.isSearchAddress
          ? otherBrokerageInfo.location?.formatted_address
          : otherBrokerageInfo.address;
        if (addressInfo) {
          AnalyticsService.instance().logEvent(
            otherBrokerageInfo.isSearchAddress
              ? AnalyticsEventEnum.CREATE_TX_OTHER_AGENT_ADDRESS_ENTERED_W_GOOGLE_SEARCH
              : AnalyticsEventEnum.CREATE_TX_OTHER_AGENT_ADDRESS_ENTERED_MANUALLY,
          );
        }
        res = await dispatch(
          saveParticipantInfo(
            transactionBuilderId!,
            ExternalParticipantInfoRoleEnum.OtherAgent,
            otherBrokerageInfo.firstName,
            otherBrokerageInfo.lastName,
            otherBrokerageInfo.companyName,
            otherBrokerageInfo.email,
            otherBrokerageInfo.phoneNumber,
            addressInfo,
          ),
        );
      }
      setLoading(false);
      if (res) {
        onNext();
      }
    }
  };
  const Country =
    userDetail?.accountCountry === AgentResponseAccountCountryEnum.Canada
      ? 'CANADA'
      : 'UNITED_STATES';

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div className='space-y-4 mb-5'>
          <FeatureFlagDisabledOnly flag={FeatureFlagTypeEnum.DOUBLE_ENDER}>
            <div className='flex justify-between'>
              <p className='text-xl font-zen-title font-medium text-zen-dark-9'>
                Who represents the Other Side?
              </p>
            </div>
            <div className='flex flex-row mt-5 justify-between'>
              <div>
                <ZenControlledRadioInput<
                  CreateTransactionFormState,
                  `isOtherSideUnrepresented`
                >
                  control={control}
                  label='Is the other side unrepresented?'
                  name='isOtherSideUnrepresented'
                  options={[
                    { value: YesNoType.YES, label: 'Yes' },
                    { value: YesNoType.NO, label: 'No' },
                  ]}
                  inlineOptions
                  shouldUnregister={false}
                  rules={{ required: 'Other Side Unrepresented is required' }}
                  isRequired
                />
              </div>
              {!isOtherSideUnrepresented && (
                <div className='whitespace-nowrap mr-1'>
                  <ZenButton
                    label='Directory'
                    variant='primary-link'
                    LeftIconComponent={<FontAwesomeIcon icon={faAddressBook} />}
                    onClick={handleSidebarToggle()}
                    zeroPadding
                    fontstyle
                  />
                </div>
              )}
            </div>
          </FeatureFlagDisabledOnly>
          <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.DOUBLE_ENDER}>
            {isRealBrokerageAgent && (
              <div className='mb-5'>
                <ZenAnnounce
                  variant='warning'
                  icon={
                    <FontAwesomeIcon
                      className='text-zen-warning'
                      icon={solid('triangle-exclamation')}
                    />
                  }
                  title='Note that the other Real agent will need to create a separate transaction'
                  fullWidth
                />
              </div>
            )}
            <div className='flex justify-between'>
              <p className='text-xl font-zen-title font-medium text-zen-dark-9'>
                Who is the other agent representing the other side?
              </p>
            </div>
            <div className='flex flex-row mt-5 items-center justify-between'>
              <div className='flex'>
                <ZenControlledRadioInput<
                  CreateTransactionFormState,
                  `otherBrokerageInfo.otherAgentType`
                >
                  control={control}
                  name='otherBrokerageInfo.otherAgentType'
                  options={values(OtherAgentTypeEnum).map((option) => ({
                    label: capitalizeEnum(option),
                    value: option,
                  }))}
                  inlineOptions
                  shouldUnregister={false}
                  rules={{
                    required: 'Required',
                  }}
                  isRequired
                />
              </div>
              {isExternalAgent && (
                <div className='whitespace-nowrap mr-1'>
                  <ZenButton
                    label='Directory'
                    variant='primary-link'
                    LeftIconComponent={<FontAwesomeIcon icon={faAddressBook} />}
                    onClick={handleSidebarToggle()}
                    zeroPadding
                    fontstyle
                  />
                </div>
              )}
            </div>
          </FeatureFlagEnabledOnly>
          {isExternalAgent && (
            <div className='flex flex-col'>
              <div className='flex flex-row space-x-4 mt-5'>
                <ZenControlledTextInput<
                  CreateTransactionFormState,
                  `otherBrokerageInfo.firstName`
                >
                  control={control}
                  label='First Name'
                  name='otherBrokerageInfo.firstName'
                  placeholder='E.g. John'
                  shouldUnregister={false}
                  rules={{ required: 'First Name is required' }}
                  maxLength={50}
                  isRequired
                />

                <ZenControlledTextInput<
                  CreateTransactionFormState,
                  `otherBrokerageInfo.lastName`
                >
                  control={control}
                  label='Last Name'
                  name='otherBrokerageInfo.lastName'
                  shouldUnregister={false}
                  placeholder='E.g. Doe'
                  rules={{ required: 'Last Name is required' }}
                  maxLength={50}
                  isRequired
                />
              </div>
              <div className='flex flex-row space-x-4 mt-5'>
                <ZenControlledEmailInput<
                  CreateTransactionFormState,
                  `otherBrokerageInfo.email`
                >
                  control={control}
                  label='Email Address'
                  name='otherBrokerageInfo.email'
                  shouldUnregister={false}
                  placeholder='Eg. johndoe@business.com'
                  rules={{
                    required: 'Email Address Required',
                    ...EMAIL_VALIDATIONS,
                  }}
                  isRequired
                  startAdornment={
                    <div className='h-full flex items-center justify-center pl-2'>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className='text-primary-blue'
                      />
                    </div>
                  }
                />

                <ZenControlledPhoneNumberInput<
                  CreateTransactionFormState,
                  `otherBrokerageInfo.phoneNumber`
                >
                  control={control}
                  label='Phone Number'
                  name='otherBrokerageInfo.phoneNumber'
                  shouldUnregister={false}
                  placeholder='E.g. +1 (123) 456 7890'
                  rules={{
                    required: 'Please provide a phone number',
                    ...PHONE_NUMBER_VALIDATIONS,
                  }}
                  isRequired
                />
              </div>
              <div className='flex flex-col mt-5'>
                <div className='flex flex-col'>
                  <ZenControlledTextInput<
                    CreateTransactionFormState,
                    `otherBrokerageInfo.companyName`
                  >
                    control={control}
                    label='Brokerage Name'
                    name='otherBrokerageInfo.companyName'
                    placeholder='E.g. Other Brokerage Name LLC.'
                    shouldUnregister={false}
                    rules={{ required: 'Company Name is required' }}
                    isRequired
                  />
                </div>
                <div className='flex flex-col mt-7 relative'>
                  {isSearchAddress ? (
                    <div className=''>
                      <ZenControlledGoogleAutocompleteSearchInput<
                        CreateTransactionFormState,
                        `otherBrokerageInfo.location`
                      >
                        control={control}
                        shouldUnregister={false}
                        name='otherBrokerageInfo.location'
                        label='Search Address'
                        subLabel='(Source by Google)'
                        placeholder='E.g. 1st St. New York, NY 10010'
                        rules={{
                          ...GOOGLE_AUTO_COMPLETE_VALIDATIONS,
                        }}
                        data-testid='search-address'
                        isRequired
                      />
                    </div>
                  ) : (
                    <div className=''>
                      <ZenControlledTextInput<
                        CreateTransactionFormState,
                        `otherBrokerageInfo.address`
                      >
                        control={control}
                        label='Brokerage Address'
                        name='otherBrokerageInfo.address'
                        placeholder='11011 Street, Block 11..'
                        shouldUnregister={false}
                        rules={{ required: 'Brokerage Address is required' }}
                        isRequired
                      />
                    </div>
                  )}

                  <div className='absolute -top-3 right-0'>
                    <IconButton
                      label={
                        isSearchAddress
                          ? 'Enter address manually'
                          : 'Search Address'
                      }
                      variant='none'
                      buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
                      leftIcon={
                        <FontAwesomeIcon
                          icon={faArrowUpArrowDown}
                          className='p-1'
                        />
                      }
                      onClick={() => {
                        setValue(
                          'otherBrokerageInfo.isSearchAddress',
                          !isSearchAddress,
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              {isExternalAgent && (
                <div className='flex items-end justify-end'>
                  <ZenButton
                    label='Add to My Directory'
                    variant='primary-link'
                    LeftIconComponent={
                      <FontAwesomeIcon
                        icon={faPlus}
                        className='text-primary-blue cursor-pointer'
                      />
                    }
                    onClick={handleRoleSidebarToggle()}
                    fontstyle
                  />
                </div>
              )}
            </div>
          )}
          <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.DOUBLE_ENDER}>
            {isRealBrokerageAgent && (
              <div className='pt-5'>
                <ZenControlledAsyncCustomSelectInput<
                  CreateTransactionFormState,
                  'otherBrokerageInfo.realAgent'
                >
                  control={control}
                  name='otherBrokerageInfo.realAgent'
                  placeholder='Search'
                  label='Real Agent Name'
                  fetchData={async (search, page) => {
                    try {
                      const searchResponse = await searchActiveAgents(
                        page,
                        [Country],
                        50,
                        search,
                        undefined,
                      );
                      const options: AsyncSelectOptionReactElement[] = searchResponse.data?.map(
                        (resp) => ({
                          value: `${resp.id}`,
                          label: (
                            <AvatarLabelComponent
                              avatar={resp?.avatar!}
                              firstName={resp?.firstName!}
                              lastName={resp?.lastName!}
                              emailAddress={resp?.emailAddress!}
                              administrativeAreas={resp?.administrativeAreas?.map(
                                (e) => {
                                  if (!e.stateOrProvince) {
                                    return 'N/A';
                                  }
                                  return STATE_OR_PROVINCE_ABBREVIATIONS[
                                    e.stateOrProvince
                                  ];
                                },
                              )}
                            />
                          ),
                        }),
                      );

                      return options;
                    } catch (e) {
                      ErrorService.notify(
                        'Unable to search for registered agents in other brokerage info step',
                        e,
                        {
                          search: {
                            term: search,
                            page,
                            country: userDetail?.accountCountry!,
                          },
                        },
                      );
                      dispatch(
                        showErrorToast(
                          'An unexpected error occurred.',
                          'We were unable to search for an agent. Please try again in a few moments or contact support.',
                        ),
                      );
                    }
                    return [];
                  }}
                  shouldUnregister={false}
                  rules={{ required: 'Please select an agent' }}
                  isRequired
                />
              </div>
            )}
          </FeatureFlagEnabledOnly>
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              isDisabled={loading}
              label='Next'
              onClick={handleOtherBrokerageInfoStep}
            />
          </div>
        </div>
      </div>
      {isSidebarOpen && (
        <ZenContactListSidebar
          currentFieldArrayPrefixNotRequired
          isOpen={isSidebarOpen}
          onClose={handleSidebarToggle()}
          onSave={handleSave}
          isExternalAgent
        />
      )}
      {roleSibebarOpen && (
        <ZenRoleDirectoryForm
          isOpen={roleSibebarOpen}
          existingVendorOrPerson={currentFormContact}
          mode='populate'
          onClose={handleRoleSidebarToggle()}
          isExternal
        />
      )}
    </div>
  );
};

export default withCreateTransactionProgress(
  ZenTransactionOtherBrokerageInfoStep,
);
