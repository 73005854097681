import React, { useState } from 'react';
import ZenButton, { ZenButtonVariant } from '../../Zen/ZenButton';

interface RefundActionButtonProps {
  label: string;
  variant?: ZenButtonVariant;
  onClick?(): void;
  isDisabled?: boolean;
}

const RefundActionButton: React.FC<RefundActionButtonProps> = ({
  label,
  variant = 'primary-outline',
  onClick,
  isDisabled,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className='min-w-[155px]'>
      <ZenButton
        variant={variant}
        label={label}
        isFullWidth
        onClick={async () => {
          setLoading(true);
          if (onClick) {
            await onClick();
          }
          setLoading(false);
        }}
        isDisabled={loading || isDisabled}
        isSubmitting={loading}
      />
    </div>
  );
};

export default RefundActionButton;
