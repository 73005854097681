import React from 'react';
import { faBoxArchive, faBoxOpen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '../IconButton';

interface ArchiveToggleSectionProps {
  isArchiveType: boolean;
  onClick: () => void;
}

const ZenArchiveToggleSection: React.FC<ArchiveToggleSectionProps> = ({
  isArchiveType,
  onClick,
}) => {
  const IconComponent = isArchiveType ? faBoxOpen : faBoxArchive;
  return (
    <div className='mt-4'>
      <IconButton
        label={isArchiveType ? 'Move to Active' : 'Archive Contact'}
        variant='none'
        buttonStyle={`font-zen-body font-semibold text-base ${
          isArchiveType ? 'text-green-600' : 'text-red-600'
        }`}
        leftIcon={<FontAwesomeIcon icon={IconComponent} />}
        onClick={onClick}
      />
    </div>
  );
};

export default ZenArchiveToggleSection;
