import { useSelector } from 'react-redux';
import {
  AgentResponseTypeEnum,
  GroupMemberResponseRoleEnum,
  OfficeAgentPreviewResponseTypeEnum,
} from '../openapi/yenta';
import { FeatureFlagTypeEnum, RootState } from '../types';
import { useFeatureFlag } from './useFeatureFlag';

const useHideBrokerNumberFromAgent = (
  role:
    | GroupMemberResponseRoleEnum
    | AgentResponseTypeEnum
    | OfficeAgentPreviewResponseTypeEnum,
) => {
  const { isAdmin, isBroker } = useSelector((state: RootState) => state.auth);
  const isRoarEnabled = useFeatureFlag(FeatureFlagTypeEnum.ROAR);

  if (!isRoarEnabled || isAdmin || isBroker) {
    return false;
  }

  switch (role) {
    case AgentResponseTypeEnum.Broker:
    case OfficeAgentPreviewResponseTypeEnum.Broker:
    case GroupMemberResponseRoleEnum.DesignatedBroker:
    case GroupMemberResponseRoleEnum.ManagingBroker:
    case GroupMemberResponseRoleEnum.RegionalBroker:
      return true;
    default:
      return false;
  }
};

export default useHideBrokerNumberFromAgent;
