import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RealLogo from '../../assets/img/Logo.png';
import { declineJointVentureInvitation } from '../../slices/JointVentureSlice';
import { RootState } from '../../types';
import ZenSimpleConfirmationModal from '../Zen/Modal/ZenSimpleConfirmationModal';
import ZenButton from '../Zen/ZenButton';

export interface SignUpLayoutProps {
  onClose?(): void;
  header: React.ReactNode;
  footer?: React.ReactNode;
  onPrevious?(): void;
  onNext?(): void;
  nextText?: string;
  hideNavigation?: boolean;
  hideLogo?: boolean;
  hideClose?: boolean;
}

const SignUpLayout: React.FC<SignUpLayoutProps> = ({
  children,
  header,
  footer,
  onPrevious,
  onNext,
  nextText = 'Next',
  hideNavigation = false,
  hideLogo = false,
  hideClose = false,
}) => {
  const {
    jointVenture: {
      showInviteResponse: { data: inviteDetails },
    },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const history = useHistory();
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [isDeclining, setIsDeclining] = useState<boolean>(false);

  const handleMaybeLater = () => {
    setOpenCancelModal(false);
    history.push('/real-title/joint-ventures');
  };

  const handleConfirm = async () => {
    setIsDeclining(true);
    if (inviteDetails?.id) {
      dispatch(declineJointVentureInvitation(inviteDetails.id));
    }
    setIsDeclining(false);
    handleMaybeLater();
  };

  return (
    <div className='fixed top-0 right-0 bottom-0 left-0 w-full h-full scrollbar overflow-auto z-10'>
      <div className='flex flex-col w-full h-full'>
        <header className='fixed w-full z-10 bg-zen-dark-13 p-4 border-b border-coolGray-300 flex items-center justify-between'>
          <div className='flex items-center ml-2 my-3'>
            {!hideLogo && <img src={RealLogo} className='h-6' alt='Logo' />}
          </div>
          {header}
          <div className='w-6 h-6'>
            {!hideClose && (
              <div className='bg-white rounded-full w-6 h-6'>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  size='xl'
                  className='text-zen-dark-10 cursor-pointer mb-0.5'
                  onClick={() => setOpenCancelModal(true)}
                />
              </div>
            )}
          </div>
        </header>

        <div className='flex flex-grow flex-col pt-20'>
          <div className='h-full bg-white'>{children}</div>
        </div>
        {!hideNavigation && (
          <div className='sticky bottom-0 bg-white p-4 border-t border-coolGray-300 flex items-center justify-between'>
            <div className='w-32'>
              {onPrevious && (
                <ZenButton
                  variant='primary-outline'
                  label='Back'
                  onClick={onPrevious}
                  isFullWidth
                />
              )}
            </div>
            <div className='w-32'>
              <ZenButton
                variant='primary'
                label={nextText}
                onClick={onNext}
                isFullWidth
              />
            </div>
          </div>
        )}
        {footer && footer}
        <ZenSimpleConfirmationModal
          variant='danger'
          title={`Are you sure you do not want to join ${inviteDetails?.jointVenture?.name}?`}
          confirmButtonText='Decline Invitation'
          cancelButtonText='Maybe Later'
          onClose={handleMaybeLater}
          onConfirm={handleConfirm}
          isOpen={openCancelModal}
          size='large'
          isSubmitting={isDeclining}
          hideIcon
        />
      </div>
    </div>
  );
};

export default SignUpLayout;
