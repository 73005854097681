import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ReferralAgentDetailItemProps {
  label: string;
  data?: string;
  icon: IconProp;
  content?: JSX.Element;
}
const ReferralAgentDetailItem: React.FC<ReferralAgentDetailItemProps> = ({
  label,
  data,
  icon,
  content,
}) => {
  return (
    <div className='w-full justify-between items-center flex py-4 font-inter'>
      <div className='justify-start items-center gap-1.5 flex w-4/12'>
        <FontAwesomeIcon
          icon={icon}
          className='text-gray-600 text-sm font-light leading-tight'
        />
        <div className='text-gray-600 text-sm font-medium'>{label}</div>
      </div>
      {data && (
        <div className='text-primary-dark text-base font-light leading-snug w-8/12 flex justify-end'>
          {data}
        </div>
      )}
      {content && <div className='w-8/12'>{content}</div>}
    </div>
  );
};
export default ReferralAgentDetailItem;
