import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../../routes/AgentWebsiteOnboardingRoute';
import { displayStringOrNA } from '../../../utils/StringUtils';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import StepContentInput from '../StepContentInput';
import ReviewRow from '../../ReviewRow';
import EditOutlineActionButton from '../EditOutlineActionButton';

const AgentWebsiteOnboardingTestimonialsReview: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ form: { watch }, navigateTo }) => {
  const testimonials = watch('testimonials');

  return (
    <div className='p-4'>
      <div className='grid lg:grid-cols-2 grid-cols-1 gap-10'>
        {!testimonials?.length ? (
          <p className='font-primary-thin text-sm text-gray-500'>
            No testimonials added
          </p>
        ) : (
          testimonials.map((testimonial, index) => (
            <div key={testimonial.clientName}>
              <StepContentInput step={index + 1}>
                <div className='flex flex-col space-y-3'>
                  <ReviewRow
                    label='Client Name'
                    value={displayStringOrNA(testimonial.clientName)}
                  />
                  <ReviewRow
                    label='Testimonial'
                    value={displayStringOrNA(testimonial.content)}
                  />
                </div>
              </StepContentInput>
            </div>
          ))
        )}
      </div>
      <div className='mt-5 mb-2'>
        <EditOutlineActionButton
          text='Edit testimonials'
          icon={<FontAwesomeIcon icon={faPen} className='mt-1 mr-1' />}
          onClick={() =>
            navigateTo(AgentWebsiteOnboardingStepName.TESTIMONIALS)
          }
        />
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingTestimonialsReview;
