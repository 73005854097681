import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faMoneyCheckDollar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import GeminiButton from '../../components/Gemini/GeminiButton';
import GeminiSearchBar from '../../components/Gemini/GeminiSearchBar';
import GeminiPillTabs from '../../components/Gemini/Tabs/GeminiPillTab';
import PageLayout from '../../components/PageLayout';
import ZenTabs from '../../components/Zen/ZenTabs';
import { DefaultReferralAppliedPaginationResponse } from '../../fixtures/api/referral/DefaultReferralAppliedPaginationResponse';
import { DefaultReferralCreatedPaginationResponse } from '../../fixtures/api/referral/DefaultReferralCreatedPaginationResponse';
import { DefaultReferralReceivedPaginationResponse } from '../../fixtures/api/referral/DefaultReferralReceivedPaginationResponse';
import ReferralFilters from './components/ReferralFilters';
import CreateReferral from './CreateReferral';
import DiscoverReferralCard from './DiscoverReferralCard';
import MyReferralAppliedCard from './MyReferralAppliedCard';
import MyReferralCreatedCard from './MyReferralCreatedCard';
import MyReferralReceivedCard from './MyReferralReceivedCard';
import ReferralApplicantCard from './ReferralApplicantCard';
import ReferralFilterSearchBy from './ReferralFilterSearchBy';
import SendReferral from './SendReferral';

interface ReferralRouteProps {}

const ReferralRoute: React.FC<ReferralRouteProps> = () => {
  const [selected, setSelected] = useState<string>();
  const [
    isCreateReferralSidebar,
    setIsCreateReferralSidebar,
  ] = useState<boolean>(false);
  const [selectedPill, setSelectedPill] = useState<string>();
  const [isSendReferralSidebar, setIsSendReferralSidebar] = useState<boolean>(
    false,
  );

  // for empty state referrance
  const isCreateReferralEmpty = false;

  return (
    <PageLayout
      path={[
        { title: 'Home', url: '/' },
        { title: 'Referral Central', url: '/referrals' },
      ]}
    >
      <div className='flex flex-row items-center justify-between px-6 py-6'>
        <p className='text-lg font-inter'>Referral Central</p>
        <GeminiButton
          label='Create Referral'
          onClick={() => setIsCreateReferralSidebar(true)}
          leftIcon={faPlus}
        />
      </div>

      <ZenTabs
        selected={selected}
        onChange={setSelected}
        size='lg'
        noBorder
        tabs={[
          {
            name: 'My Referrals (5)',
            TabComponent: (
              <GeminiPillTabs
                selected={selectedPill}
                onChange={setSelectedPill}
                isTabComponentEmpty={isCreateReferralEmpty}
                emptyStateButtonLeftIcon={faPlus}
                emptyStateButtonText='Create Referral'
                emptyStateIcon={
                  <FontAwesomeIcon
                    icon={faMoneyCheckDollar}
                    className='text-4xl text-regent-900 font-inter font-light'
                  />
                }
                emptyStateMessage='There are no referrals to display'
                emptyStateButtonOnClick={() => setIsCreateReferralSidebar(true)}
                size='sm'
                RightComponent={
                  <div className='flex flex-row items-center space-x-2 mr-6 flex-1'>
                    <div className='min-w-max flex flex-row justify-end'>
                      <GeminiSearchBar
                        value=''
                        onChange={() => {}}
                        placeholder='Search By Name, City ... '
                        showTransition
                      />
                    </div>
                    <div>
                      <ReferralFilters />
                    </div>
                  </div>
                }
                tabs={[
                  {
                    name: `Created (${DefaultReferralCreatedPaginationResponse.totalCount})`,
                    TabComponent: (
                      <div className='grid md:grid-flow-row grid-flow-row md:grid-cols-3 grid-cols-1 gap-5 p-4'>
                        {DefaultReferralCreatedPaginationResponse.items.map(
                          (referral) => (
                            <MyReferralCreatedCard
                              key={referral.id}
                              referralDetails={referral}
                            />
                          ),
                        )}
                      </div>
                    ),
                  },
                  {
                    name: `Applied (${DefaultReferralAppliedPaginationResponse.totalCount})`,
                    TabComponent: (
                      <div className='grid md:grid-flow-row grid-flow-row md:grid-cols-3 grid-cols-1 gap-5 p-4'>
                        {DefaultReferralAppliedPaginationResponse.items.map(
                          (referral) => (
                            <MyReferralAppliedCard
                              key={referral.id}
                              referralDetails={referral}
                            />
                          ),
                        )}
                      </div>
                    ),
                  },
                  {
                    name: `Received (${DefaultReferralReceivedPaginationResponse.totalCount})`,
                    TabComponent: (
                      <div className='grid md:grid-flow-row grid-flow-row md:grid-cols-3 grid-cols-1 gap-5 p-4'>
                        {DefaultReferralReceivedPaginationResponse.items.map(
                          (referral) => (
                            <MyReferralReceivedCard
                              key={referral.id}
                              referralDetails={referral}
                            />
                          ),
                        )}
                      </div>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            name: 'Discover Referrals (1)',
            TabComponent: (
              <div className='p-4'>
                <ReferralFilterSearchBy />

                <div className='grid md:grid-flow-row grid-flow-row md:grid-cols-3 grid-cols-1 gap-5 pt-5 -mt-2px'>
                  {DefaultReferralReceivedPaginationResponse.items.map(
                    (referral) => (
                      <DiscoverReferralCard
                        key={referral.id}
                        referralDetails={referral}
                      />
                    ),
                  )}
                </div>
              </div>
            ),
          },
          {
            name: 'Find an Agent',
            TabComponent: (
              <div className='p-4'>
                <ReferralFilterSearchBy />
                <div className='grid grid-flow-row grid-cols-3 gap-5 py-4'>
                  <ReferralApplicantCard
                    setIsSendReferralSidebarOpen={setIsSendReferralSidebar}
                    totalSales={9200}
                    address={[
                      'Walton Beach, Florida',
                      'Miami, Florida',
                      'Orlando, Florida',
                    ]}
                    minimumPrice={9900}
                    maximumPrice={90000}
                    name='Tom Cruise'
                    numberOfListings={26}
                    note='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil tempora dolore, sunt quibusdam saepe adipisci'
                  />
                  <ReferralApplicantCard
                    setIsSendReferralSidebarOpen={setIsSendReferralSidebar}
                    totalSales={9200000}
                    address={['Walton Beach, Florida']}
                    minimumPrice={90000}
                    maximumPrice={900000}
                    name='James Tyler'
                    numberOfListings={26}
                    note='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil tempora dolore, sunt quibusdam saepe adipisci'
                  />

                  <ReferralApplicantCard
                    setIsSendReferralSidebarOpen={setIsSendReferralSidebar}
                    totalSales={9200000}
                    address={['Walton Beach, Florida', 'Miami, Florida']}
                    minimumPrice={90000}
                    maximumPrice={900000}
                    name='James Tyler'
                    numberOfListings={26}
                    note='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil tempora dolore, sunt quibusdam saepe adipisci'
                  />
                </div>
              </div>
            ),
          },
        ]}
      />

      <CreateReferral
        isOpen={isCreateReferralSidebar}
        onClose={() => setIsCreateReferralSidebar(false)}
      />

      <SendReferral
        isOpen={isSendReferralSidebar}
        onClose={() => setIsSendReferralSidebar(false)}
      />
    </PageLayout>
  );
};

export default ReferralRoute;
