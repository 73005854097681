import { faBadgePercent } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import config from '../../config';
import useRedirectAwayOnboardingEffect from '../../hooks/useRedirectAwayOnboardingEffect';
import {
  AmountDiscountResponse,
  ApplicationControllerApi,
  ApplicationResponse,
  ApplicationResponseNextStatusEnum,
  FeePaymentRequest,
  MoneyValueCurrencyEnum,
} from '../../openapi/yenta';
import { useInvitationCoupon } from '../../query/team/useInvitationCoupon';
import { InvitationEnum } from '../../routes/JoinByInvitationRoute';
import ErrorService from '../../services/ErrorService';
import { applyCoupon } from '../../slices/ApplicationSlice';
import { saveApplication } from '../../slices/AuthSlice';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { AppDispatch, RootState } from '../../types';
import { StateOrProvinceToDisplayName } from '../../utils/DisplayHelpers';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ApplicationLayout from '../ApplicationLayout';
import ControlledTextInputV7 from '../ControlledTextInputV7';
import PaymentCard from '../PaymentCard';
import ZenButton from '../Zen/ZenButton';
import ZenResourceContainer from '../Zen/ZenResourceContainer';

interface FormData {
  coupon: string;
}

const ApplicationFee: React.FC = () => {
  const history = useHistory();
  const stripePromise = loadStripe(config.stripe.publishableKey);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({ mode: 'onChange' });

  useRedirectAwayOnboardingEffect(ApplicationResponseNextStatusEnum.PayFees);

  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [processing, setProcessing] = useState(false);
  const lastApplicationIndex = userDetail!.applications!.length - 1;
  const application: ApplicationResponse = userDetail!.applications![
    lastApplicationIndex
  ];
  const invitationId =
    application.teamInvitation?.invitationId ||
    application.genericTeamInvitation?.invitationId;
  const invitationType = application.teamInvitation
    ? InvitationEnum.EMAIL
    : InvitationEnum.LINK;
  const dispatch: AppDispatch = useDispatch();
  const signUpFee: number = application.doesBusinessInExtended![0]
    ?.licenseResponse?.administrativeArea!.signupFee!.amount!;
  const { data: amountDiscount, isFetching } = useInvitationCoupon({
    fnArgs: [invitationId!, invitationType, application.id!, signUpFee],
  });
  const [couponDetails, setCouponDetails] = useState<
    AmountDiscountResponse | undefined
  >();

  const paymentBlocks = application.doesBusinessInExtended!.map((item) => {
    return (
      <div
        key={item!.licenseResponse?.administrativeArea!.stateOrProvince!}
        className='flex flex-row justify-between space-x-2'
      >
        <p>
          {StateOrProvinceToDisplayName(
            item!.licenseResponse?.administrativeArea!.stateOrProvince!,
          )}{' '}
          License Transfer Fees
        </p>

        <p>
          $
          {
            item.licenseResponse?.administrativeArea!.licenseTransferFee!
              .amount!
          }
        </p>
      </div>
    );
  });

  const currency: MoneyValueCurrencyEnum = application.doesBusinessInExtended![0]
    .licenseResponse?.administrativeArea!.signupFee!.currency!;

  const oneTimeFee = application
    .doesBusinessInExtended!.map(
      (item) =>
        item.licenseResponse?.administrativeArea!.licenseTransferFee!.amount!,
    )
    .reduce((prev, curr) => prev + curr, 0);

  const subTotal = signUpFee + oneTimeFee;

  const discountedFee = Math.max(
    0,
    signUpFee - (couponDetails?.calculatedDiscount || 0),
  );

  const totalFee = oneTimeFee + discountedFee;

  const retrieveCoupon = useCallback(
    async (values: FormData) => {
      const couponDetail = await dispatch(
        applyCoupon(application?.id!, values.coupon, signUpFee),
      );
      if (
        couponDetail &&
        couponDetail.calculatedDiscount &&
        couponDetail.originalAmount
      ) {
        couponDetail.calculatedDiscount = couponDetail.calculatedDiscount / 100;
        couponDetail.originalAmount = couponDetail.originalAmount / 100;
      }
      setCouponDetails(couponDetail);
    },
    [application?.id, dispatch, signUpFee],
  );

  const checkForSkipFee = () => {
    if (totalFee < 0.5) {
      return false;
    }
    return true;
  };

  const onSkipFee = async () => {
    setProcessing(true);

    try {
      const paymentDetail: FeePaymentRequest = {
        fees: {
          currency: currency,
          amount: totalFee * 100, // converting dollar to cents
        },
        applicationId: application.id!,
        paymentMethod: '',
        promoCode: couponDetails?.couponCode ?? '',
      };

      const { data } = await new ApplicationControllerApi(
        getYentaConfiguration(),
      ).payApplicationFees(paymentDetail!);

      dispatch(saveApplication(data));
      history.push('/onboarding/application-fee/success');
    } catch (e) {
      ErrorService.notify('Unable to process the application fee', e);
      dispatch(
        showErrorToast(
          'Unable to process the application fee.',
          'Please try again later.',
        ),
      );
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    // If user is joined via invite code and fees is waved,
    // then amountDiscount will be present, so set couponDetails.
    if (amountDiscount) {
      setCouponDetails(amountDiscount);
    }
  }, [amountDiscount]);

  return (
    <ApplicationLayout>
      <div className='flex items-center justify-center w-full'>
        <div className='w-full lg:max-w-7xl'>
          <div className='p-4 lg:p-8'>
            <p className='text-3xl font-primary-regular text-center text-gray-800'>
              Application Fee
            </p>
          </div>
          <ZenResourceContainer
            resourceName='Application fee'
            isEmpty={false}
            loading={isFetching}
          >
            <div className='mx-auto max-w-lg'>
              <div className='bg-dark py-4 px-4 lg:py-12 lg:px-8 text-white text-center space-y-4'>
                <p className='text-3xl'>One-time Fee</p>
                <div className='p-3 bg-trueGray-600 rounded flex flex-col'>
                  <p className='mx-auto px-2 py-0.5 border border-gray-500 rounded-full bg-dark -mt-6 text-xs'>
                    Includes
                  </p>
                  <div className='space-y-2 pt-2'>
                    <div className='flex flex-row justify-between space-x-2'>
                      <p>Real Brokerage Application Fee</p>
                      <p>${signUpFee}</p>
                    </div>
                    {paymentBlocks}
                  </div>
                </div>
                <div className='flex flex-row items-center justify-between'>
                  <p>Sub Total</p>
                  <p>${subTotal}</p>
                </div>
                {!couponDetails ? (
                  <div className='text-black flex flex-col items-start'>
                    <p className='text-white text-sm'>Apply promo code</p>
                    <form
                      onSubmit={handleSubmit(retrieveCoupon)}
                      className='flex flex-row items-center space-x-4 w-full'
                    >
                      <ControlledTextInputV7
                        control={control}
                        name='coupon'
                        type='text'
                        placeholder='Enter promo code'
                        hideErrorMessage
                        rules={{ required: 'Please enter a coupon code' }}
                      />
                      <ZenButton
                        label='Apply'
                        variant='primary-outline'
                        type='submit'
                        isSubmitting={isSubmitting}
                        isDisabled={isSubmitting}
                      />
                    </form>
                    {errors?.coupon && (
                      <div>
                        <p className='text-error py-1 rounded-lg'>
                          {errors?.coupon?.message!}
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='flex flex-row items-start justify-between w-full'>
                    <div className='flex flex-col items-start'>
                      <div className='flex flex-row items-center space-x-2'>
                        <p>
                          {!!amountDiscount
                            ? 'Application Fees Waived'
                            : 'Less promo discount'}
                        </p>
                        <FontAwesomeIcon icon={faBadgePercent} />
                        {!amountDiscount && (
                          <button
                            onClick={() => setCouponDetails(undefined)}
                            className='text-zen-danger hover:underline'
                          >
                            Remove
                          </button>
                        )}
                      </div>
                      {!amountDiscount && (
                        <div>
                          <p className='font-primary-light text-sm'>
                            {couponDetails?.couponCode} promo code applied
                          </p>
                        </div>
                      )}
                    </div>
                    <div>
                      <p>-${couponDetails?.calculatedDiscount}</p>
                    </div>
                  </div>
                )}
                <div className='flex flex-row items-center space-x-3 justify-between pb-2'>
                  <p className='text-lg font-semibold'>Total</p>
                  <p className='text-xl font-bold'>
                    ${totalFee} {currency}
                  </p>
                </div>
                {checkForSkipFee() ? (
                  <div>
                    <p className='flex items-start'>Enter your card details</p>
                    <Elements stripe={stripePromise}>
                      <PaymentCard
                        totalFee={totalFee}
                        currency={currency}
                        applicationId={application.id!}
                        couponCode={couponDetails?.couponCode!}
                      />
                    </Elements>
                  </div>
                ) : (
                  <div>
                    <p className='flex items-start pb-2'>No payment required</p>
                    <ZenButton
                      label='Continue'
                      variant='primary'
                      isFullWidth
                      isDisabled={processing}
                      isSubmitting={processing}
                      type='button'
                      onClick={() => onSkipFee()}
                    />
                  </div>
                )}
              </div>
            </div>
          </ZenResourceContainer>
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default ApplicationFee;
