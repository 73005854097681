import {
  faArrowRotateLeft,
  faArrowUpRightFromSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { faFile } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import useAgentsInfo from '../../../hooks/useAgentsInfo';
import { ItemResponse, DocumentDto } from '../../../openapi/sherlock';
import { downloadChecklistDocument } from '../../../slices/CheckListSlice';
import { RootState } from '../../../types';
import { getFullName } from '../../../utils/AgentHelper';
import { getYentaImageUrl } from '../../../utils/ImgUtils';
import ZenDocumentDateCell from '../../dropbox/cell/ZenDocumentDateCell';
import ProfileImage from '../../ProfileImage';
import ZenFixedDataTable from '../ZenFixedDataTable';
import ZenChecklistItemDocumentDropdown from './ZenChecklistDocumentDropdown';
import ZenCheckListDocumentVersions from './ZenChecklistDocumentVersions';

export interface ZenChecklistItemDocumentRowProps {
  checklistItem: ItemResponse;
  checklistId: string;
}

const ZenChecklistItemDocumentRow: React.FC<ZenChecklistItemDocumentRowProps> = ({
  checklistItem,
  checklistId,
}) => {
  const dispatch = useDispatch();
  const {
    userIds: { agentById },
  } = useSelector((state: RootState) => state);
  const [
    isShowingDocumentVersion,
    setIsShowingDocumentVersion,
  ] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<DocumentDto>({});
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useAgentsInfo([
    ...(checklistItem?.documents || []).map(
      (file) => file?.currentVersion?.uploaderId!,
    ),
  ]);

  const handleOpenFile = async (checklistDocument: DocumentDto) => {
    await dispatch(
      downloadChecklistDocument(checklistDocument.currentVersion?.id!),
    );
  };

  const columns: Array<Column<DocumentDto>> = [
    {
      Header: 'Name / Description',
      accessor: 'name',
      Cell: ({ value, row: { original } }) => (
        <div className='flex'>
          <div className='mt-1'>
            <div className='border border-zen-dark-5 rounded p-1'>
              <FontAwesomeIcon icon={faFile} className='text-zen-dark-5' />
            </div>
          </div>
          <div className='inline-block ml-2'>
            <div className='text-sm font-zen-body font-normal text-zen-dark-9 flex items-start gap-2'>
              {value}
              <button
                type='button'
                className='appearance-none'
                onClick={() => {
                  handleOpenFile(original);
                }}
                aria-label='file-open'
              >
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className='text-primary-blue text-base'
                />
              </button>
            </div>
            <div className='text-xs text-zen-dark-6 leading-tight pt-1 contents'>
              {original.description}
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: 'Version',
      accessor: 'versions',
      Cell: ({ value }) => (
        <div className='flex items-center md:justify-center gap-1'>
          <FontAwesomeIcon icon={faArrowRotateLeft} size='sm' />
          {value![value?.length! - 1]?.number}
        </div>
      ),
    },
    {
      Header: 'Uploaded By',
      accessor: 'currentVersion',
      Cell: ({ value }) =>
        agentById[value?.uploaderId!] ? (
          <div className='font-zen-body text-sm items-center w-max gap-2 bg-zen-light-blue px-2 py-1.5 rounded-full inline-flex'>
            <ProfileImage
              imageUrl={getYentaImageUrl(agentById[value?.uploaderId!]?.avatar)}
              width={17}
              variant='circle'
            />
            {getFullName(agentById[value?.uploaderId!])}
          </div>
        ) : (
          ''
        ),
    },
    {
      Header: 'Upload Date',
      accessor: 'createdAt',
      Cell: ({ row: { original } }) => (
        <ZenDocumentDateCell date={original.currentVersion?.createdAt!} />
      ),
    },
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row: { original, index } }) => (
        <ZenChecklistItemDocumentDropdown
          checklistDocument={original}
          checklistItem={checklistItem}
          checklistId={checklistId}
          setIsShowingDocumentVersion={() => {
            setSelectedDocument(original);
            setSelectedIndex(index);
            setIsShowingDocumentVersion(true);
          }}
        />
      ),
    },
  ];

  return (
    <div className='mt-5'>
      <ZenFixedDataTable<DocumentDto>
        key={checklistItem?.documents?.length}
        columns={columns}
        resourceName='Uploaded Documents'
        data={checklistItem?.documents}
        hideFilters
        hidePagination
      />
      <ZenCheckListDocumentVersions
        isOpen={isShowingDocumentVersion}
        onClose={() => setIsShowingDocumentVersion(false)}
        checklistDocument={selectedDocument}
        checklistItem={checklistItem}
        checklistId={checklistId}
        index={selectedIndex}
      />
    </div>
  );
};

export default ZenChecklistItemDocumentRow;
