import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { AgentControllerApi as ArrakisAgentControllerApi } from '../../../../../openapi/arrakis';
import {
  AgentControllerApi,
  AgentResponse,
} from '../../../../../openapi/yenta';
import ErrorService from '../../../../../services/ErrorService';
import { showApiErrorModal } from '../../../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../slices/ToastNotificationSlice';
import { AsyncSelectOption } from '../../../../../types';
import {
  getArrakisConfiguration,
  getYentaConfiguration,
} from '../../../../../utils/OpenapiConfigurationUtils';
import { searchForAgents } from '../../../../../utils/TableUtils';
import DefaultLoader from '../../../../DefaultLoader';
import DoubleConfirmationModal from '../../../../DoubleConfirmationModal';
import ZenControlledAsyncSelectInput from '../../../Input/ZenControlledAsyncSelectInput';
import ZenControlledDatePickerInput from '../../../Input/ZenControlledDatePickerInput';
import ZenAvatarPill from '../../../ZenAvatarPill';
import ZenButton from '../../../ZenButton';
import ZenSidebarModal from '../../../ZenSidebarModal';

interface ZenChangeAnniversaryDateSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  anniversaryDate: string | null;
  joinDate: string | null;
  realAgent: AsyncSelectOption;
}

const ZenChangeAnniversaryDateSidebarModal: React.FC<ZenChangeAnniversaryDateSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
  });
  const [realAgent, setRealAgent] = useState<AgentResponse>();
  const [loading, setLoading] = useState(false);
  const [sponsorAgentChange, setSponsorAgentChange] = useState(false);
  const realAgentWatch = watch('realAgent');

  const fetchAgent = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const { data } = await new AgentControllerApi(
          getYentaConfiguration(),
        ).getAgentById(id);
        setRealAgent(data);
      } catch (e) {
        ErrorService.notify('Error fetching real agent', e);
        dispatch(
          showErrorToast(
            'We had a problem fetching the real agent',
            'Please try again in a few moments.',
          ),
        );
      } finally {
        setLoading(false);
      }
    },
    [dispatch],
  );

  const onSubmit = async (values: FormData) => {
    try {
      await new ArrakisAgentControllerApi(
        getArrakisConfiguration(),
      ).updateJoinDateAndAnniversaryDate(values.realAgent.value, {
        newAnniversaryDate: values.anniversaryDate!,
        newJoinDate: values.joinDate!,
      });
      onClose();
      dispatch(showSuccessToast('Updated anniversary date successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error updating anniversary date', e);
      dispatch(
        showErrorToast(
          'We had a problem updating the anniversary date agent',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setSponsorAgentChange(false);
    }
  };

  useEffect(() => {
    if (realAgentWatch) {
      fetchAgent(realAgentWatch.value);
    } else {
      setRealAgent(undefined);
    }
  }, [fetchAgent, realAgentWatch]);

  return (
    <ZenSidebarModal
      title='Anniversary Date Adjustment'
      isOpen={isOpen}
      onClose={() => {
        setRealAgent(undefined);
        onClose();
      }}
    >
      <form className='flex flex-col justify-between min-h-full'>
        <div className='px-4'>
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<FormData, 'realAgent'>
              control={control}
              name='realAgent'
              placeholder='Search'
              shouldUnregister
              label='Select an agent'
              fetchData={async (search, page) => {
                try {
                  const { data } = await searchForAgents({
                    page,
                    search,
                  });

                  const options: AsyncSelectOption[] = data.map((resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.firstName} ${resp.lastName} (${resp.emailAddress})`,
                  }));

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search request registered agents in change anniversary date form',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                      },
                    },
                  );
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an registered agent. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          <div className='mt-4'>
            {!loading ? (
              realAgent && (
                <div className='flex flex-col space-y-2'>
                  <div className='flex flex-row items-center space-x-2'>
                    <p>View Profile:</p>
                    <a
                      href={`/people/${realAgent.id}`}
                      target='_blank'
                      rel='noreferrer noopener'
                    >
                      <ZenAvatarPill
                        text={realAgent.fullName!}
                        imageUrl={realAgent.avatar!}
                      />
                    </a>
                  </div>

                  <p className='font-primary-regular'>
                    Current anniversary date:{' '}
                    {realAgent.anniversaryDate || 'N/A'}
                  </p>
                  <p className='font-primary-regular'>
                    Current join date: {realAgent.joinDate || 'N/A'}
                  </p>
                </div>
              )
            ) : (
              <DefaultLoader />
            )}
          </div>
          <div className='mt-5'>
            <ZenControlledDatePickerInput<FormData, 'anniversaryDate'>
              control={control}
              name='anniversaryDate'
              label='New Anniversary Date (Must be before today)'
              placeholder='Select a date'
              shouldUnregister
              rules={{
                required: 'Required',
              }}
              datePickerConfig={{
                minDate: DateTime.local().minus({ year: 1 }).toJSDate(),
                maxDate: DateTime.local().toJSDate(),
                filterDate: (date) => date.getDate() === 1,
              }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledDatePickerInput<FormData, 'joinDate'>
              control={control}
              name='joinDate'
              label='New Join Date (Must be before today)'
              placeholder='Select a date'
              shouldUnregister
              rules={{
                required: 'Required',
              }}
              datePickerConfig={{
                minDate: DateTime.local().minus({ year: 1 }).toJSDate(),
                maxDate: DateTime.local().toJSDate(),
              }}
            />
          </div>
        </div>
        <div className='sticky bottom-0 flex flex-row p-4 space-x-5 bg-white border-t border-gray-200'>
          <ZenButton
            isFullWidth
            type='button'
            onClick={onClose}
            label='Cancel'
            variant='primary-outline'
            textSize='lg'
          />
          <ZenButton
            isFullWidth
            isSubmitting={isSubmitting}
            isDisabled={isSubmitting || !isValid}
            onClick={() => setSponsorAgentChange(true)}
            label='Update'
            textSize='lg'
          />
        </div>
        <DoubleConfirmationModal
          onConfirm={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          isOpen={sponsorAgentChange}
          variant='info'
          title='Confirm Anniversary Date Change'
          onClose={() => setSponsorAgentChange(false)}
          subtitle='You are about to change the anniversary date and join date.'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenChangeAnniversaryDateSidebarModal;
