import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as EditIcon } from '../../../assets/img/editPencil.svg';
import { ReactComponent as SaveFile } from '../../../assets/img/save_file_transaction.svg';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenOutlineActionButton from '../../Zen/ZenOutlineActionButton';
import ZenStickyBottomPagination from '../../Zen/ZenStickyBottomPagination';
import {
  capitalizeEnum,
  formatAddress,
  formatPhoneNumber,
} from '../../../utils/StringUtils';
import { filterUrl } from '../../../utils/UrlUtils';
import { RootState, YesNoType } from '../../../types';
import ZenSimpleConfirmationModal from '../../Zen/Modal/ZenSimpleConfirmationModal';
import withApplicactionFormProgress from './withWelcomeOnboardingFormProgress';
import {
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName,
} from './ZenWelcomeOnboardingForm';
import AgentProfileImagePreview from './components/AgentProfileImagePreview';

const ReviewStep: StepByStepComponent<
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName
> = ({
  form: {
    formState: { isSubmitting },
    watch,
  },
  onPrevious,
  navigateTo,
  onSubmit,
}) => {
  const formData = watch();
  const [confirm, setConfirm] = useState<boolean>(false);
  const languagesKnown = formData.agentUpdateRequest.languages?.map(
    (language) => capitalizeEnum(language.value),
  );
  const competencies = (
    formData.agentUpdateRequest?.competencies || []
  ).map((comp) => capitalizeEnum(comp));
  const address = formData.agentUpdateRequest.addressRequest || {};

  const { userDetail } = useSelector((state: RootState) => state.auth);
  const addressType = watch('agentUpdateRequest.addressRequest.type');

  return (
    <div className='w-full max-w-lg flex flex-col h-full justify-between mt-10 relative'>
      <div className='w-full flex-grow'>
        <h4 className='font-zen-title font-medium text-zen-dark-9 text-0.5xl mb-6 '>
          Review Information and Submit
        </h4>
        {/* PROFILE_STEP */}
        <div className='pt-2 pb-4 mb-4 flex flex-row border-b items-start justify-between border-coolGray-300'>
          <div className='flex flex-row flex-grow'>
            <div className='w-7 pt-1 h-7 rounded-full bg-gray-200 flex justify-center items-center shrink-0'>
              <span className='print-review-counter' />
            </div>
            <div className='flex-grow px-5 space-y-1'>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-y-4 md:gap-y-0 md:gap-8  border-coolGray-100'>
                <div className='space-y-2'>
                  <div className='font-zen-body font-normal text-base text-zen-dark-7 leading-6'>
                    Profile Picture
                  </div>
                  <div className='inline-block rounded-full border-2 border-zen-gray-4'>
                    <AgentProfileImagePreview
                      name={userDetail?.fullName!}
                      imageUrl={
                        formData.avatar
                          ? URL.createObjectURL(formData.avatar)
                          : undefined
                      }
                      size='medium'
                    />
                  </div>
                </div>
                <div className=' space-y-1 font-primary-medium'>
                  <p className='font-zen-body font-normal text-base text-zen-dark-7 leading-6'>
                    Bio
                  </p>
                  <p className='font-zen-body font-normal text-base text-zen-dark-9 leading-snug'>
                    {formData.agentUpdateRequest.about}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=''>
            <ZenOutlineActionButton
              text='Edit'
              icon={<EditIcon fontSize={24} className='text-primary-blue' />}
              onClick={() =>
                navigateTo(WelcomeOnboardingFormStepName.PROFILE_PIC_INFO_STEP)
              }
            />
          </div>
        </div>
        {/* ADDRESS_STEP */}
        <div className='pt-2 pb-4 mb-4 flex flex-row border-b items-start justify-between border-coolGray-300'>
          <div className='flex flex-row flex-grow'>
            <div className='w-7 pt-1 h-7 rounded-full bg-gray-200 flex justify-center items-center shrink-0'>
              <span className='print-review-counter' />
            </div>
            <div className='flex-grow px-5 space-y-1'>
              <div className=' grid grid-cols-1 md:grid-cols-2 gap-y-4 md:gap-y-0 md:gap-8  border-coolGray-100'>
                <div className='space-y-2'>
                  <div className='font-zen-body font-normal text-base text-zen-dark-7 leading-6'>
                    Receive mail at my
                  </div>
                  <div className='font-zen-body font-normal text-base text-zen-dark-9 leading-snug'>
                    {`${capitalizeEnum(addressType)} Address`}
                  </div>
                </div>
                <div className=' space-y-1 font-primary-medium'>
                  <p className='font-zen-body font-normal text-base text-zen-dark-7 leading-6'>
                    {`${capitalizeEnum(addressType)} Address`}
                  </p>

                  <p className='font-zen-body font-normal text-base text-zen-dark-9 leading-snug'>
                    {formatAddress(
                      address.streetAddress1,
                      address.streetAddress2,
                      address.city ?? '',
                      address.stateOrProvince?.value
                        ? capitalizeEnum(address.stateOrProvince?.value)
                        : '',
                      address.zipOrPostalCode,
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ZenOutlineActionButton
            text='Edit'
            icon={<EditIcon fontSize={24} className='text-primary-blue' />}
            onClick={() =>
              navigateTo(WelcomeOnboardingFormStepName.ADDRESS_INFO_STEP)
            }
          />
        </div>
        {/* PROFESSIONAL_INFO_STEP */}
        <div className='pt-2 pb-4 mb-4 flex flex-row border-b items-start justify-between border-coolGray-300'>
          <div className='flex flex-row flex-grow'>
            <div className='w-7 pt-1 h-7 rounded-full bg-gray-200 flex justify-center items-center shrink-0'>
              <span className='print-review-counter' />
            </div>
            <div className='flex-grow px-5 space-y-1'>
              <div className=' grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4 md:gap-y-2 border-coolGray-100'>
                <div className='space-y-2'>
                  <div className='font-zen-body font-normal text-base text-zen-dark-7 leading-6'>
                    Title
                  </div>
                  <p className='font-zen-body font-normal text-base text-zen-dark-9 leading-snug'>
                    {formData.agentUpdateRequest?.title
                      ? formData.agentUpdateRequest?.title
                      : '---'}
                  </p>
                </div>
                <div className=' space-y-1 font-primary-medium row-span-2'>
                  <p className='font-zen-body font-normal text-base text-zen-dark-7 leading-6'>
                    I Work With
                  </p>
                  <p className='font-zen-body font-normal text-base text-zen-dark-9 leading-snug'>
                    {!!competencies.length ? competencies.join(', ') : '---'}
                  </p>
                </div>
                <div className='space-y-1'>
                  <div className='font-zen-body font-normal text-base text-zen-dark-7 leading-6'>
                    Business Phone No.
                  </div>
                  <p className='font-zen-body font-normal text-base text-zen-dark-9 leading-snug'>
                    {formData.agentUpdateRequest?.phoneNumber
                      ? formatPhoneNumber(
                          formData.agentUpdateRequest?.phoneNumber,
                        )
                      : '---'}
                  </p>
                </div>
                <div className=' space-y-1 font-primary-medium'>
                  <p className='font-zen-body font-normal text-base text-zen-dark-7 leading-6'>
                    Languages Spoken
                  </p>
                  <p className='font-zen-body font-normal text-base text-zen-dark-9 leading-snug'>
                    {languagesKnown?.length
                      ? languagesKnown?.join(', ')
                      : '---'}
                  </p>
                </div>
                <div className='space-y-1'>
                  <div className='font-zen-body font-normal text-base text-zen-dark-7 leading-6'>
                    Personal Phone No.
                  </div>
                  <p className='font-zen-body font-normal text-base text-zen-dark-9 leading-snug'>
                    {formData.agentUpdateRequest?.personalPhoneNumber
                      ? formatPhoneNumber(
                          formData.agentUpdateRequest?.personalPhoneNumber,
                        )
                      : '---'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ZenOutlineActionButton
            text='Edit'
            icon={<EditIcon fontSize={24} className='text-primary-blue' />}
            onClick={() =>
              navigateTo(WelcomeOnboardingFormStepName.PROFESSIONAL_INFO_STEP)
            }
          />
        </div>
        {/* SOCIAL_MEDIA_STEP */}
        <div className='pt-2 pb-4 mb-4 flex flex-row border-b items-start justify-between border-coolGray-300'>
          <div className='w-full flex flex-row'>
            <div className='w-7 pt-1 h-7 rounded-full bg-gray-200 flex justify-center items-center shrink-0'>
              <span className='print-review-counter' />
            </div>
            <div className='flex-grow px-5 space-y-1'>
              <div className='font-primary-medium text-zen-dark-9 pb-1 text-lg'>
                Social Media
              </div>
              <div className=' grid grid-cols-1 md:grid-cols-2 md:gap-x-8 gap-y-4 md:gap-y-1 border-coolGray-100'>
                <div className='flex items-center   flex-wrap'>
                  <div className='font-zen-body font-normal text-base text-zen-dark-7'>
                    My website:
                  </div>
                  <p className='font-zen-body font-normal break-all text-base text-zen-dark-9'>
                    www.
                    {formData.agentUpdateRequest?.personalWebsiteURL
                      ? filterUrl(
                          formData.agentUpdateRequest?.personalWebsiteURL,
                        )
                      : '---'}
                  </p>
                </div>
                <div className='flex items-center  flex-wrap'>
                  <div className='font-zen-body font-normal break-all text-base text-zen-dark-7'>
                    {`www.twitter.com/${
                      formData.agentUpdateRequest?.twitterURL
                        ? filterUrl(formData.agentUpdateRequest?.twitterURL)
                        : '---'
                    }`}
                  </div>
                </div>
                <div className='flex items-center  flex-wrap'>
                  <div className='font-zen-body font-normal break-all text-base text-zen-dark-7'>
                    {`www.facebook.com/${
                      formData.agentUpdateRequest?.facebookURL
                        ? filterUrl(formData.agentUpdateRequest?.facebookURL)
                        : '---'
                    }`}
                  </div>
                </div>
                <div className='flex items-center flex-wrap'>
                  <div className='font-zen-body font-normal break-all text-base text-zen-dark-7'>
                    {`www.youtube.com/${
                      formData.agentUpdateRequest?.youtubeURL
                        ? filterUrl(formData.agentUpdateRequest?.youtubeURL)
                        : '---'
                    }`}
                  </div>
                </div>
                <div className='flex items-center flex-wrap'>
                  <div className='font-zen-body font-normal break-all text-base text-zen-dark-7'>
                    {`www.instagram.com/${
                      formData.agentUpdateRequest?.instagramURL
                        ? filterUrl(formData.agentUpdateRequest?.instagramURL)
                        : '---'
                    }`}
                  </div>
                </div>
                <div className='flex items-center flex-wrap'>
                  <div className='font-zen-body font-normal break-all text-base text-zen-dark-7'>
                    {`www.zillow.com/${
                      formData.agentUpdateRequest?.zillowURL
                        ? filterUrl(formData.agentUpdateRequest?.zillowURL)
                        : '---'
                    }`}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ZenOutlineActionButton
            text='Edit'
            icon={<EditIcon fontSize={24} className='text-primary-blue' />}
            onClick={() =>
              navigateTo(WelcomeOnboardingFormStepName.SOCIAL_MEDIA)
            }
          />
        </div>
        {/* LOFTY SUBSCRIPTION STEP */}
        <div className='pt-2 pb-4 mb-4 flex flex-row border-b items-start justify-between border-coolGray-300'>
          <div className='flex flex-row flex-grow'>
            <div className='w-7 pt-1 h-7 rounded-full bg-gray-200 flex justify-center items-center shrink-0'>
              <span className='print-review-counter' />
            </div>
            <div className='flex-grow px-5 space-y-1'>
              <div className=' grid grid-cols-1 md:grid-cols-2 gap-y-4 md:gap-y-0 md:gap-8  border-coolGray-100'>
                <div className='space-y-2'>
                  <div className='font-zen-body font-normal text-base text-zen-dark-7 leading-6'>
                    Lofty Subscription
                  </div>
                  <div className='font-zen-body font-normal text-base text-zen-dark-9 leading-snug'>
                    {formData.loftySubscription === YesNoType.YES
                      ? 'Yes, I would like to subscribe'
                      : 'No, I would not like to subscribe'}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ZenOutlineActionButton
            text='Edit'
            icon={<EditIcon fontSize={24} className='text-primary-blue' />}
            onClick={() =>
              navigateTo(WelcomeOnboardingFormStepName.LOFTY_SUBSCRIPTION)
            }
          />
        </div>
      </div>
      <ZenStickyBottomPagination
        onPrevious={onPrevious}
        nextButtonText='Submit'
        onNext={() => setConfirm(true)}
        maxWidth='3xl'
      />
      <ZenSimpleConfirmationModal
        isOpen={confirm}
        variant='info'
        cancelButtonText='No'
        hideIcon
        customIcon={<SaveFile fontSize={18} className='text-primary-blue' />}
        title='Create profile?'
        subtitle='You will be able to update this information at any time in your profile.'
        confirmButtonText='Yes'
        onConfirm={async () => {
          await onSubmit();
          setConfirm(false);
        }}
        onClose={() => setConfirm(false)}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default withApplicactionFormProgress(ReviewStep);
