import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { useGemini } from '../../hooks/useGemini';
import { cn } from '../../utils/classUtils';

interface ZenAddProps {
  text?: string;
  onClick?(): void;
  disabled?: boolean;
}

const ZenAdd: React.FC<ZenAddProps> = ({ text = 'Add', onClick, disabled }) => {
  const isGeminiEnabled = useGemini();

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={cn(
        'flex flex-row items-center justify-center space-x-1',
        isGeminiEnabled
          ? 'font-inter font-medium text-base leading-5 text-rezen-blue-600'
          : 'font-zen-body font-semibold text-primary-blue',
      )}
    >
      <FontAwesomeIcon
        icon={faPlus}
        className={cn(
          'font-light text-[13px] leading-[23px]',
          disabled && 'text-zen-gray-5',
        )}
      />
      <p className={cn(disabled && 'text-zen-gray-5')}>{text}</p>
    </button>
  );
};

export default ZenAdd;
