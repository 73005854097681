import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { RefundResponseStatusEnum } from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';
import ZenStatusPill, { PillVariantType } from '../../Zen/ZenStatusPill';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';

interface RefundStatusPillProps {
  status: RefundResponseStatusEnum;
}

const statusToVariantClassnameMap: EnumMap<
  RefundResponseStatusEnum,
  PillVariantType
> = {
  [RefundResponseStatusEnum.Submitted]: 'warning',
  [RefundResponseStatusEnum.FinanceApproved]: 'primary',
  [RefundResponseStatusEnum.Cancelled]: 'danger',
  [RefundResponseStatusEnum.PendingApproval]: 'purple',
  [RefundResponseStatusEnum.ReadyForRelease]: 'primary',
  [RefundResponseStatusEnum.New]: 'default',
  [RefundResponseStatusEnum.Complete]: 'success',
};

export const RefundStatusToLabelMap: EnumMap<
  RefundResponseStatusEnum,
  string
> = {
  [RefundResponseStatusEnum.Submitted]: 'Submitted',
  [RefundResponseStatusEnum.FinanceApproved]: 'Ready for Release',
  [RefundResponseStatusEnum.Cancelled]: 'Cancelled',
  [RefundResponseStatusEnum.PendingApproval]: 'Pending Approval',
  [RefundResponseStatusEnum.ReadyForRelease]: 'Ready for Release',
  [RefundResponseStatusEnum.New]: 'New',
  [RefundResponseStatusEnum.Complete]: 'Funds Released',
};

export const statusToIcon: EnumMap<
  RefundResponseStatusEnum,
  React.ReactElement
> = {
  [RefundResponseStatusEnum.Cancelled]: (
    <FontAwesomeIcon icon={solid('xmark')} fontSize={18} />
  ),
  [RefundResponseStatusEnum.Complete]: (
    <FontAwesomeIcon icon={solid('circle-check')} fontSize={18} />
  ),
  [RefundResponseStatusEnum.PendingApproval]: (
    <FontAwesomeIcon icon={regular('hourglass-half')} fontSize={18} />
  ),
  [RefundResponseStatusEnum.ReadyForRelease]: (
    <FontAwesomeIcon icon={regular('thumbs-up')} fontSize={18} />
  ),
  [RefundResponseStatusEnum.FinanceApproved]: (
    <FontAwesomeIcon icon={regular('thumbs-up')} fontSize={18} />
  ),
  [RefundResponseStatusEnum.Submitted]: (
    <FontAwesomeIcon icon={regular('clipboard-check')} fontSize={18} />
  ),
  [RefundResponseStatusEnum.New]: (
    <FontAwesomeIcon icon={solid('triangle-exclamation')} fontSize={18} />
  ),
};

export const RefundStatusPill: React.FC<RefundStatusPillProps> = ({
  status,
}) => {
  return (
    <ZenStatusPill
      text={safeEnumMapGet(
        RefundStatusToLabelMap,
        status,
        capitalizeEnum(status),
      )}
      variant={safeEnumMapGet(statusToVariantClassnameMap, status, 'default')}
      icon={safeEnumMapGet(
        statusToIcon,
        status,
        <FontAwesomeIcon icon={solid('triangle-exclamation')} fontSize={18} />,
      )}
    />
  );
};

export default RefundStatusPill;
