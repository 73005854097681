import React from 'react';
import { Link } from 'react-router-dom';
import { InviteResponseStatusEnum } from '../../openapi/avalon';
import { cn } from '../../utils/classUtils';
import ZenButton from '../Zen/ZenButton';
import ZenStatusPill from '../Zen/ZenStatusPill';

interface ZenAgentJVCardProps {
  jointVentureName: string;
  tierName: string;
  colorCode: string;
  status: InviteResponseStatusEnum;
  interests: number;
  inviteId: string;
  inSidebar?: boolean;
  isRounded?: boolean;
}

const ZenAgentJVCard: React.FC<ZenAgentJVCardProps> = ({
  colorCode,
  interests,
  jointVentureName,
  status,
  tierName,
  inSidebar = false,
  isRounded = false,
  inviteId,
}) => {
  const displayPillBasedOnStatus = () => {
    if (
      status === InviteResponseStatusEnum.Viewed ||
      status === InviteResponseStatusEnum.Created
    ) {
      return <ZenStatusPill text='New' variant='warning' fontSize='text-xs' />;
    } else {
      return (
        <div className='text-primary-blue text-xs px-2'>
          {interests} Available
        </div>
      );
    }
  };

  return (
    <div>
      <div
        className={cn(
          'flex justify-between flex-row items-center pr-4 border border-l-0',
          isRounded ? 'rounded-md' : '',
        )}
      >
        <div className='flex items-center'>
          <div
            style={{ backgroundColor: colorCode }}
            className={cn('w-[16px] h-[76px]', isRounded ? 'rounded-l-md' : '')}
          />
          <div className='pr-2 pl-1'>
            <div className='flex flex-col px-2'>
              <div className='font-zen-body font-bold text-sm leading-5 text-zen-dark-7'>
                {tierName} Tier {interests ? `(${interests})` : null}
              </div>
              <div className='font-zen-body font-normal text-xs leading-5 text-zen-dark-5'>
                {jointVentureName}
              </div>
            </div>
          </div>
        </div>
        <div className='font-zen-body font-normal justify-between text-sm leading-5'>
          {inSidebar && !isRounded ? (
            <Link to={`/real-title/${inviteId}/signup`} className='flex-1'>
              <ZenButton variant='primary-link' label='Join Now' isFullWidth />
            </Link>
          ) : (
            displayPillBasedOnStatus()
          )}
        </div>
      </div>
    </div>
  );
};

export default ZenAgentJVCard;
