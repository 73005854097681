import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import {
  TransactionResponse,
  TransactionControllerApi,
} from '../../openapi/arrakis';
import ErrorService from '../../services/ErrorService';
import { RootState, EmailSupportType, ISelectOption } from '../../types';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { openEmailClientForSupportEmail } from '../../utils/SupportUtils';
import { getWebAppLink } from '../../utils/UrlUtils';
import Button from '../Button';
import ControlledSelectInputV7 from '../ControlledSelectInputV7';
import ResourceContainer from '../ResourceContainer';

interface MyTransactionSupportIndexProps {
  navigateBack(): void;
}

interface FormData {
  transactionId: string;
}

const MyTransactionSupport: React.FC<MyTransactionSupportIndexProps> = ({
  navigateBack,
}) => {
  const [openTransactions, setOpenTransactions] = useState<
    TransactionResponse[]
  >([]);
  const [closeTransactions, setCloseTransactions] = useState<
    TransactionResponse[]
  >([]);
  const [loading, setLoading] = useState(true);
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const yentaId = userDetail?.id!;
  const allTransactions = [...openTransactions, ...closeTransactions];
  const transactionOptions: ISelectOption[] = allTransactions.map((t) => ({
    label: `${t.address?.oneLine} - ${t.code}`,
    value: t.id!,
  }));
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: { transactionId: '' },
  });

  const fetchTransactions = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await new TransactionControllerApi(
        await getArrakisConfiguration(),
      ).getAllTransactions(yentaId);

      setOpenTransactions(data.openTransactions || []);
      setCloseTransactions(data.closedTransactions || []);
    } catch (e) {
      ErrorService.notify('Unable to fetch all transactions', e, {
        agent: { agentYentaId: yentaId },
      });
    } finally {
      setLoading(false);
    }
  }, [yentaId]);

  const onSubmit = (data: FormData) => {
    const transaction = allTransactions.find(
      (t) => t.id === data.transactionId,
    )!;

    const body = `\n\n-------\n\n${transaction.address?.oneLine}\n${
      transaction.code
    }\n\n${getWebAppLink(`/transactions/${transaction.id}`)}\n\n
    `;

    return openEmailClientForSupportEmail(
      EmailSupportType.MY_TRANSACTION,
      userDetail!,
      encodeURIComponent(body),
    );
  };

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  return (
    <div className='space-y-2 m-4 -mt-10 bg-white shadow-md rounded'>
      <div className='p-4'>
        <div className='flex space-x-2'>
          <FontAwesomeIcon
            icon={faArrowLeft}
            size='lg'
            onClick={navigateBack}
          />
          <p className='text-lg'>Transactions Support</p>
        </div>
        <ResourceContainer
          loading={loading}
          isEmpty={false}
          resourceName='Support'
        >
          <ControlledSelectInputV7<FormData>
            label='Which transaction do you have questions about?'
            options={[{ label: '', value: '' }, ...transactionOptions]}
            name='transactionId'
            rules={{
              required: 'Please choose a transaction to proceed',
            }}
            control={control}
          />
          <div className='mt-4'>
            <Button
              label='Email Support'
              type='primary'
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
              fullWidth
            />
          </div>
        </ResourceContainer>
      </div>
    </div>
  );
};

export default MyTransactionSupport;
