import React from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  AuthControllerApi,
  IdentitySummaryResponse,
  UserControllerApi,
} from '../../openapi/keymaker';
import ErrorService from '../../services/ErrorService';
import { fetchIdentityByKeymakerId } from '../../slices/AuthSlice';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import { showSuccessToast } from '../../slices/ToastNotificationSlice';
import { getKeymakerConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { EMAIL_VALIDATIONS } from '../../utils/Validations';
import ZenSidebarModalActionFooter from '../SidebarModal/ZenSideBarModalActionFooter';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenSidebarModal from '../Zen/ZenSidebarModal';

interface ZenUpdateEmailFormProps {
  isOpen: boolean;
  onClose(): void;
  identity: IdentitySummaryResponse;
}

interface EditEmailFormData {
  email: string;
}

const ZenUpdateEmailForm: React.FC<ZenUpdateEmailFormProps> = ({
  isOpen,
  onClose,
  identity,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<EditEmailFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { email: identity?.email },
  });

  const handleEmailUpdate = async (values: EditEmailFormData) => {
    try {
      await new UserControllerApi(
        getKeymakerConfiguration(),
      ).updateEmailForUser(identity.id!, {
        newEmail: values.email,
      });

      await dispatch(fetchIdentityByKeymakerId(identity.id!));

      onClose();

      dispatch(showSuccessToast('Email address updated successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to update new email', e);
    }
  };

  return (
    <ZenSidebarModal title='Change Email' isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(handleEmailUpdate)}>
        <div className='p-4'>
          <ZenControlledTextInput<EditEmailFormData, 'email'>
            control={control}
            label='Email'
            name='email'
            placeholder='Email'
            rules={{
              required: 'Email is required',
              ...EMAIL_VALIDATIONS,
              validate: async (value) => {
                const { data: email } = await new AuthControllerApi(
                  getKeymakerConfiguration(),
                ).checkEmailAvailability(value);
                return !email.available ? 'Email is already taken' : undefined;
              },
            }}
            isRequired
          />
        </div>
        <ZenSidebarModalActionFooter
          onClose={onClose}
          isSubmitting={isSubmitting}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenUpdateEmailForm;
