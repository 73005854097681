import { Alert as MAlert, AlertProps as MAlertProps } from '@mantine/core';
import { cn } from '../../utils/classUtils';
import { combineDefaultClassnamesWithCustomClassnames } from './utils';

type Color = 'red' | 'blue' | 'green' | 'yellow' | 'gray';

export type AlertProps = Omit<
  MAlertProps,
  'color' | 'radius' | 'variant' | 'children'
> & {
  color?: Color;
  children?: string;
};

type LightStyles = {
  background: string;
  color: string;
  [key: string]: string;
};

const lightStyles: Record<Color, LightStyles> = {
  red: {
    background: 'bg-red-100',
    color: 'text-red-600',
  },
  blue: {
    background: 'bg-blue-100',
    color: 'text-blue-600',
  },
  green: {
    background: 'bg-green-100',
    color: 'text-green-600',
  },
  yellow: {
    background: 'bg-yellow-100',
    color: 'text-yellow-600',
  },
  gray: {
    background: 'bg-gray-100',
    color: 'text-gray-600',
  },
};

const getColor = (color: Color): MAlertProps['classNames'] => {
  return {
    wrapper: cn(Object.values(lightStyles[color])),
  };
};

export const Alert: React.FC<AlertProps> = (props) => {
  return (
    <MAlert
      unstyled
      {...props}
      classNames={combineDefaultClassnamesWithCustomClassnames(
        {
          wrapper: 'flex gap-2.5 py-2.5 px-4 relative',
          root:
            'rounded-md font-inter font-light w-full overflow-hidden text-base relative',
          icon: 'flex text-lg items-center justify-start h-6',
          closeButton: 'flex mt-1',
          message: 'text-sm',
          body: 'flex-1 gap-1 flex flex-col',
        },
        getColor(props.color || 'gray'),
        props.classNames,
      )}
    >
      {props.children}
    </MAlert>
  );
};
