import {
  faArrowLeft,
  faArrowRight,
  faCalendar,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import GeminiButton from '../../components/Gemini/GeminiButton';
import { StepByStepComponent } from '../../components/StepByStep/StepByStepContainer';
import ZenControlledDatePickerInput from '../../components/Zen/Input/ZenControlledDatePickerInput';
import ZenControlledGoogleAutocompleteSearchInput from '../../components/Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledPhoneNumberInput from '../../components/Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledTextInput from '../../components/Zen/Input/ZenControlledTextInput';
import {
  EMAIL_VALIDATIONS,
  GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
} from '../../utils/Validations';
import { ReferralStepsFormState } from './ReferralUtils';
import { ApplyToReferralStepName } from './ApplyToReferralSteps';
import InputInfoBanner from './InfoBanner';
import { SendReferralStepName } from './SendReferral';

const ReferralClientDetailStep: StepByStepComponent<
  ReferralStepsFormState,
  ApplyToReferralStepName | SendReferralStepName
> = ({ form: { control }, onPrevious, onNext, onClose }) => {
  return (
    <>
      <div className='h-fit'>
        <div className='font-inter border border-gray-200 px-3 mb-8 py-2 rounded-lg m-4'>
          <h4 className='mt-4 mb-6 font-medium text-base'>
            Enter Client Details
          </h4>
          <div className='flex flex-row items-center justify-between space-x-4 mt-1'>
            <ZenControlledTextInput<
              ReferralStepsFormState,
              'clientInformation.firstName'
            >
              name='clientInformation.firstName'
              control={control}
              shouldUnregister={false}
              label='First Name'
              placeholder='Enter first name'
            />

            <ZenControlledTextInput<
              ReferralStepsFormState,
              'clientInformation.lastName'
            >
              name='clientInformation.lastName'
              control={control}
              shouldUnregister={false}
              label='Last Name'
              placeholder='Enter last name'
            />
          </div>

          <div className='mt-6'>
            <ZenControlledGoogleAutocompleteSearchInput<
              ReferralStepsFormState,
              `clientInformation.address`
            >
              control={control}
              shouldUnregister={false}
              name='clientInformation.address'
              label='Address'
              placeholder='Enter address, pincode ...'
              rules={{
                ...GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
              }}
              isRequired
            />
          </div>

          <div className='mt-6'>
            <div className='flex flex-row items-center justify-between space-x-4 mt-1'>
              <ZenControlledTextInput<
                ReferralStepsFormState,
                'clientInformation.email'
              >
                name='clientInformation.email'
                control={control}
                shouldUnregister={false}
                label='Email Address'
                placeholder='Enter email address'
                rules={EMAIL_VALIDATIONS}
              />

              <ZenControlledPhoneNumberInput<
                ReferralStepsFormState,
                'clientInformation.phoneNumber'
              >
                name='clientInformation.phoneNumber'
                control={control}
                shouldUnregister={false}
                label='Phone Number'
                placeholder='Enter phone number'
              />
            </div>
          </div>

          <div className='mt-6'>
            <ZenControlledDatePickerInput<
              ReferralStepsFormState,
              'clientInformation.expirationDate'
            >
              name='clientInformation.expirationDate'
              control={control}
              shouldUnregister={false}
              label='Enter Expiration Date'
              placeholder='MM/DD/YYYY'
              icon={
                <FontAwesomeIcon
                  icon={faCalendar}
                  title='calendar'
                  className='text-primary-blue text-base ml-2'
                />
              }
              datePickerConfig={{
                minDate: DateTime.local().toJSDate(),
              }}
            />
            <InputInfoBanner message='The entered date must be greater than the current date  ' />
          </div>
        </div>
      </div>
      <div className='sticky w-full bottom-0 right-0 left-0 bg-primary-light border-t'>
        <div className='flex flex-row items-center justify-between p-4'>
          <GeminiButton
            type='button'
            label='Cancel'
            variant='primary-link'
            onClick={onClose}
          />
          <div className='flex flex-row items-center justify-between space-x-4 w-1/2'>
            <GeminiButton
              type='button'
              label='Back'
              variant='primary-outline'
              onClick={onPrevious}
              leftIcon={faArrowLeft}
              isFullWidth
            />

            <GeminiButton
              type='button'
              label='Next'
              variant='primary'
              isFullWidth
              onClick={onNext}
              rightIcon={faArrowRight}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralClientDetailStep;
