import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { cn } from '../../../../utils/classUtils';
import Hover from '../../../Hover';

interface GeminiTransactionHeaderRowCellProps {
  leftComponentIcon?: IconProp;
  label: string | React.ReactElement;
  labelHoverText?: string;
  value?: string | React.ReactElement;
  valueClassName?: string;
  rightComponent?: React.ReactElement;
  dataTestId?: string;
}

const LabelHoverText: React.FC<{ hoverText?: string }> = ({
  hoverText,
  children,
}) => {
  if (!hoverText) return <>{children}</>;

  return (
    <Hover
      hoverComponent={
        <div className='text-zen-dark-12 font-medium text-sm font-zen-body px-2'>
          {hoverText}
        </div>
      }
      config={{ trigger: 'hover', placement: 'top' }}
    >
      <div className='cursor-pointer'>{children}</div>
    </Hover>
  );
};

const GeminiTransactionHeaderRowCell: React.FC<GeminiTransactionHeaderRowCellProps> = ({
  leftComponentIcon,
  label,
  labelHoverText,
  value,
  valueClassName,
  rightComponent,
  dataTestId,
}) => {
  return (
    <div
      className='flex flex-row items-center text-white space-x-2'
      data-testId={dataTestId}
    >
      {!!leftComponentIcon && (
        <span className='h-7 w-7 flex items-center justify-center shrink-0'>
          <FontAwesomeIcon
            icon={leftComponentIcon}
            className='text-base font-light text-center'
          />
        </span>
      )}
      <div className='flex items-center flex-wrap'>
        <LabelHoverText hoverText={labelHoverText}>
          <span className='font-zen-body-2 font-semibold text-sm leading-4 shrink-0 mr-2'>
            {label}
          </span>
        </LabelHoverText>
        {value && (
          <span
            className={cn(
              'font-zen-body-2 font-normal text-sm leading-4',
              valueClassName,
            )}
          >
            {value}
          </span>
        )}
        {rightComponent && rightComponent}
      </div>
    </div>
  );
};

export default GeminiTransactionHeaderRowCell;
