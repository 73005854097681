import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../types';
import config from '../config';

interface AdminOrCIOnlyProps {}

const AdminOrCIOnly: React.FC<AdminOrCIOnlyProps> = ({ children }) => {
  const { isAdmin } = useSelector((state: RootState) => state.auth);

  if (!isAdmin && !config.ci) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};

export default AdminOrCIOnly;
