import {
  faCircleCheck,
  faUpRightFromSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CSVLink } from 'react-csv';
import { isEmpty } from 'lodash';
import { ReactComponent as FileExcelImage } from '../../../assets/icons/zen/transaction/file-excel.svg';
import { displayAmount, zeroMoneyValue } from '../../../utils/CurrencyUtils';
import ZenAvatar from '../../Zen/ZenAvatar';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import {
  exportPendingRevenueSharePayments,
  fetchPendingRevenueSharePayments,
} from '../../../slices/RevenueSlice';
import { AppDispatch, RootState } from '../../../types';
import DefaultLoader from '../../DefaultLoader';
import ZenButton from '../../Zen/ZenButton';
import ZenSimpleConfirmationModal from '../../Zen/Modal/ZenSimpleConfirmationModal';
import ResourceContainer from '../../ResourceContainer';
import { getFullName } from '../../../utils/AgentHelper';
import { RevSharePaymentResponse } from '../../../openapi/arrakis';
import { parseCsv } from '../../../utils/reportsUtils';
import ZenDateCell from '../../Zen/Table/Cell/ZenDateCell';

type RevShareInsightsPendingPaymentSidebarProps = {
  isOpen: boolean;
  onClose(): void;
  agentId: string;
};

const RevShareInsightsPendingPaymentSidebar: React.FC<RevShareInsightsPendingPaymentSidebarProps> = ({
  isOpen,
  onClose,
  agentId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    agentDetail: {
      detailResponse: { data: detail },
    },
    revenue: { pendingPaymentsError },
  } = useSelector((state: RootState) => state);

  const [isLoading, setIsLoading] = useState(true);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [exportData, setExportData] = useState<string | undefined>(undefined);
  const [
    pendingPayments,
    setPendingPayments,
  ] = useState<RevSharePaymentResponse>();

  const fetchData = useCallback(
    async (pageNumber = 0) => {
      const res = await dispatch(
        fetchPendingRevenueSharePayments(agentId, pageNumber, 20, true),
      );
      if (pageNumber === 0) {
        setPendingPayments(res);
      } else {
        setPendingPayments((prev) => ({
          ...(res || {}),
          contributions: prev?.contributions?.concat(res?.contributions || []),
        }));
      }
      setIsLoading(false);
    },
    [dispatch, agentId],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const exportToCSV = useCallback(async () => {
    const csvData = await dispatch(exportPendingRevenueSharePayments(agentId));
    setExportData(csvData);
  }, [agentId, dispatch]);

  return (
    <ZenSidebarModal
      isOpen={isOpen}
      onClose={onClose}
      title='Revenue Payments'
      subtitle='Paid monthly on the 15th'
    >
      <div className='p-4'>
        <div className='rounded-lg px-6 py-2 bg-zen-warning bg-opacity-10 text-zen-warning'>
          <div className='flex items-start'>
            <FileExcelImage width={20} height={20} className='mt-1' />
            <span className='font-primary-regular ml-3 pt-0.5'>
              Your pending payment includes contribution from closed and settled
              transactions. Other contributions will be added as they get
              settled and processed.
            </span>
          </div>
        </div>
        <ResourceContainer
          loading={isLoading}
          isEmpty={isEmpty(pendingPayments)}
          errorCode={pendingPaymentsError}
          resourceName='contribution'
        >
          <>
            <div className='my-5 p-4 rounded-lg border border-zen-dark-4'>
              <p className='font-zen-body text-3xl text-zen-dark-9 text-center'>
                {displayAmount(pendingPayments?.amount, { hideCurrency: true })}
                <span className='text-sm'>
                  {pendingPayments?.amount?.currency}
                </span>
              </p>
              <p className='font-zen-body text-base text-zen-dark-12 text-center'>
                Estimated Revenue Share amount
              </p>
            </div>
            <div className='-mx-4'>
              <div className='bg-grey-100 flex items-center justify-between px-4 py-2'>
                <p className='font-zen-body text-zen-dark-12 text-sm'>
                  Contributions ({pendingPayments?.totalCount || 0})
                </p>
                <button
                  onClick={() => {
                    setIsOpenExport(true);
                    exportToCSV();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUpRightFromSquare}
                    aria-label='export'
                    className='text-zen-dark-7 text-sm'
                  />
                </button>
              </div>
              <ResourceContainer
                loading={false}
                isEmpty={isEmpty(pendingPayments?.contributions)}
                resourceName='contribution'
              >
                <div className='divide-y divide-zen-light-gray-2 text-zen-dark-9 font-zen-body'>
                  <InfiniteScroll
                    hasMore={!!pendingPayments?.hasNext}
                    loader={<DefaultLoader />}
                    dataLength={pendingPayments?.contributions?.length || 0}
                    scrollableTarget='modal-scroll'
                    next={() =>
                      fetchData((pendingPayments?.pageNumber || 0) + 1)
                    }
                  >
                    {pendingPayments?.contributions?.map((value) => {
                      const name = getFullName(value.agent) || 'N/A';

                      return (
                        <div
                          key={value.agent?.id}
                          aria-label='contributor'
                          className='px-4 py-2 flex items-center space-x-2'
                        >
                          <ZenAvatar
                            name={name}
                            imageUrl={value.agent?.imageUrl}
                            showPlaceholder
                          />
                          <div className='flex-grow'>
                            <p className='flex items-center space-x-2 font-medium'>
                              <span>{name}</span>
                              <FontAwesomeIcon
                                icon={faCircle}
                                className='w-1 h-1 text-zen-dark-12'
                              />
                              <span className='text-sm font-normal'>
                                Tier {value.tier}
                              </span>
                            </p>
                            <p className='text-sm text-zen-dark-12'>
                              {value.transaction?.addressOneLine}
                            </p>
                          </div>
                          <div>
                            <p className='text-right'>
                              {displayAmount(
                                value.amount ||
                                  zeroMoneyValue(detail?.defaultCurrency!),
                                { hideCurrency: true },
                              )}
                            </p>
                            <p className='flex items-center'>
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                className='text-zen-gray-4 text-sm mr-1'
                              />
                              <ZenDateCell date={value.transaction?.closedAt} />
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              </ResourceContainer>
            </div>
          </>
        </ResourceContainer>
      </div>
      <ZenSimpleConfirmationModal
        variant='info'
        title='Export to CSV?'
        isSubmitting={false}
        isOpen={isOpenExport}
        onClose={() => setIsOpenExport(false)}
        hideBottomButtons
      >
        <div className='mt-6 space-x-3 flex flex-row items-start'>
          {exportData ? (
            <CSVLink
              data={parseCsv(exportData! || '')}
              filename='revshare-payments-pending.csv'
              target='_blank'
              className='bg-primary-blue text-white rounded-lg py-2 px-4.5 w-full flex items-center justify-center text-center border-2 border-primary-blue'
              onClick={() => setIsOpenExport(false)}
            >
              Download
            </CSVLink>
          ) : (
            <ZenButton isSubmitting />
          )}
          <ZenButton
            label='Cancel'
            variant='secondary-gray-outline'
            onClick={() => setIsOpenExport(false)}
            isFullWidth
          />
        </div>
      </ZenSimpleConfirmationModal>
    </ZenSidebarModal>
  );
};

export default RevShareInsightsPendingPaymentSidebar;
