import React from 'react';
import { useSelector } from 'react-redux';
import {
  AddressRequest,
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
  WillBeneficiaryAndTypeResponse,
  WillBeneficiaryAndTypeResponseTypeEnum,
} from '../../../../openapi/yenta';
import { AgentAddressTypeEnum, RootState } from '../../../../types';
import StepByStepContainer, {
  StepConfig,
} from '../../../StepByStep/StepByStepContainer';
import CreateBeneficiaryGotItStepV2 from './CreateBeneficiaryGotItStepV2';
import CreateBeneficiaryInformationStepV2 from './CreateBeneficiaryInformationStepV2';
import CreateBeneficiaryIntroStepV2 from './CreateBeneficiaryIntroStepV2';
import CreateBeneficiarySignAddendumStepV2 from './CreateBeneficiarySignAddendumStepV2';
import CreateBeneficiarySuccessStepV2 from './CreateBeneficiarySuccessStepV2';

export interface CreateBeneficiaryV2Props {
  beneficiary?: WillBeneficiaryAndTypeResponse;
  close(): void;
}

export interface CreateBeneficiaryV2FormData {
  firstName: string;
  middleName: string;
  lastName: string;
  address: AddressRequest;
  birthDate: string;
  phoneNumber: string;
  maybeLater: boolean;
  beneficiaryType: WillBeneficiaryAndTypeResponseTypeEnum;
  trustInformation: string;
}

export enum CreateBeneficiaryV2StepName {
  INTRO = 'INTRO',
  GOT_IT = 'GOT_IT',
  SIGN_ADDENDUM = 'SIGN_ADDENDUM',
  BENEFICIARY_INFO = 'BENEFICIARY_INFO',
  SUCCESS = 'SUCCESS',
}

const steps: StepConfig<
  CreateBeneficiaryV2FormData,
  CreateBeneficiaryV2StepName
>[] = [
  {
    Component: CreateBeneficiaryIntroStepV2,
    name: CreateBeneficiaryV2StepName.INTRO,
    hidePagination: true,
  },
  {
    Component: CreateBeneficiaryGotItStepV2,
    name: CreateBeneficiaryV2StepName.GOT_IT,
    showStepIf: ({ maybeLater }) => maybeLater,
    hidePagination: true,
  },
  {
    Component: CreateBeneficiarySignAddendumStepV2,
    name: CreateBeneficiaryV2StepName.SIGN_ADDENDUM,
    showStepIf: ({ maybeLater }) => !maybeLater,
    hidePagination: true,
  },
  {
    Component: CreateBeneficiaryInformationStepV2,
    name: CreateBeneficiaryV2StepName.BENEFICIARY_INFO,
    showStepIf: ({ maybeLater }) => !maybeLater,
    hidePagination: true,
  },
  {
    Component: CreateBeneficiarySuccessStepV2,
    name: CreateBeneficiaryV2StepName.SUCCESS,
    showStepIf: ({ maybeLater }) => !maybeLater,
    hidePagination: true,
  },
];

const CreateBeneficiaryScreenV2: React.FC<CreateBeneficiaryV2Props> = ({
  close,
}) => {
  const {
    auth: { userDetail: agentDetail },
  } = useSelector((state: RootState) => state);

  const defaultValues: CreateBeneficiaryV2FormData = {
    firstName: '',
    middleName: '',
    lastName: '',
    address: {
      type: AgentAddressTypeEnum.HOME,
      streetAddress1: '',
      city: '',
      zipOrPostalCode: '',
      country: (agentDetail?.accountCountry as unknown) as AddressRequestCountryEnum,
      stateOrProvince: '' as AddressRequestStateOrProvinceEnum,
    },
    birthDate: '',
    phoneNumber: '',
    maybeLater: false,
    beneficiaryType: WillBeneficiaryAndTypeResponseTypeEnum.Individual,
    trustInformation: '',
  };

  return (
    <StepByStepContainer<
      CreateBeneficiaryV2FormData,
      CreateBeneficiaryV2StepName
    >
      defaultValues={defaultValues}
      steps={steps}
      onSubmit={close}
      mode='onChange'
    />
  );
};

export default CreateBeneficiaryScreenV2;
