interface ExistingBeneficiaryRowV2Props {
  label: string;
  value?: string;
}

const ExistingBeneficiaryRowV2: React.FC<ExistingBeneficiaryRowV2Props> = ({
  label,
  value,
}) => {
  return (
    <div className='col-span-1 grid grid-cols-5 gap-x-2'>
      <div className='col-span-2'>
        <p className='text-black text-base font-zen-body font-medium'>
          {label}
        </p>
      </div>
      <div className='col-span-3'>
        <p className='text-black text-base font-zen-body'>{value}</p>
      </div>
    </div>
  );
};

export default ExistingBeneficiaryRowV2;
