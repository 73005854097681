import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faEye,
  faHourglassHalf,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import {
  InviteResponseStatusEnum,
  MembershipResponseStatusEnum,
} from '../../../openapi/avalon';
import { EnumMap } from '../../../types';
import { safePartialEnumMapGet } from '../../../utils/EnumHelper';
import GeminiPill from '../GeminiPill';
import GeminiJVStatusButton from './GeminiJVStatusButton';

export interface GeminiJVInvitationStatusCardProps {
  status:
    | InviteResponseStatusEnum
    | MembershipResponseStatusEnum
    | 'NOT_INVITED';
  jointVentureTitle: string;
  onClick?(): void;
}

export const variantToClassNameMap: EnumMap<
  InviteResponseStatusEnum | MembershipResponseStatusEnum | 'NOT_INVITED',
  string
> = {
  ACCEPTED: 'bg-green-100 text-green-600',
  DECLINED: 'bg-red-100 text-red-500',
  CREATED: 'bg-green-100 text-green-600',
  VIEWED: 'bg-grey-200 text-grey-600',
  WITHDRAWN: 'bg-red-100 text-red-500',
  ACTIVE: 'bg-green-100 text-green-600',
  NOT_INVITED: 'bg-red-100 text-red-500',
  PENDING_SIGNATURE: 'bg-yellow-100 text-yellow-900',
  PENDING_PAYMENT: 'bg-yellow-100 text-yellow-900',
  PAYMENT_FAILED: 'bg-red-100 text-red-500',
};

export const iconVariantToClassNameMap: EnumMap<
  InviteResponseStatusEnum | MembershipResponseStatusEnum | 'NOT_INVITED',
  IconProp | undefined
> = {
  ACCEPTED: faCheck,
  WITHDRAWN: faXmark,
  DECLINED: faXmark,
  CREATED: faCheck,
  VIEWED: faEye,
  PENDING_SIGNATURE: faHourglassHalf,
  PAYMENT_FAILED: faXmark,
  ACTIVE: faCheck,
  PENDING_PAYMENT: faHourglassHalf,
  NOT_INVITED: faXmark,
};

const GeminiJVInvitationStatusCard: React.FC<GeminiJVInvitationStatusCardProps> = ({
  jointVentureTitle,
  status,
  onClick,
}) => {
  const getTitle = (prefix: string, jointVentureTitle?: string) => {
    return jointVentureTitle ? `${prefix}: ${jointVentureTitle}` : prefix;
  };

  const titleVariantToClassNameMap: EnumMap<
    InviteResponseStatusEnum | MembershipResponseStatusEnum | 'NOT_INVITED',
    string
  > = {
    ACCEPTED: getTitle('Invitation Accepted', jointVentureTitle),
    DECLINED: getTitle('Invitation Declined', jointVentureTitle),
    CREATED: getTitle('Invitation Sent', jointVentureTitle),
    VIEWED: getTitle('Invitation Viewed', jointVentureTitle),
    WITHDRAWN: getTitle('Invitation Withdrawn', jointVentureTitle),
    PENDING_SIGNATURE: getTitle('Awaiting Signature', jointVentureTitle),
    PAYMENT_FAILED: getTitle('Payment Failed', jointVentureTitle),
    PENDING_PAYMENT: getTitle('Payment Pending', jointVentureTitle),
    ACTIVE: getTitle('Membership Active', jointVentureTitle),
    NOT_INVITED: 'No',
  };

  return (
    <div className='flex md:flex-row flex-col md:items-center flex-wrap'>
      <GeminiPill
        label={safePartialEnumMapGet(titleVariantToClassNameMap, status, 'No')}
        containerClassNames={safePartialEnumMapGet(
          variantToClassNameMap,
          status,
          'bg-red-100 text-red-500',
        )}
        icon={safePartialEnumMapGet(iconVariantToClassNameMap, status, faXmark)}
      />
      <div className='h-4.5 w-[1px] bg-grey-200 ml-2.5 md:block hidden' />
      <GeminiJVStatusButton status={status} onClick={onClick} />
    </div>
  );
};

export default GeminiJVInvitationStatusCard;
