import { capitalize, last } from 'lodash';
import { DateTime } from 'luxon';
import qs from 'qs';
import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressCard,
  faLocationDot,
  faPhone,
} from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import RealTitle from '../../../assets/img/real-title.svg';
import stocks from '../../../assets/img/stocks_18px_two_tone.svg';
import tipalti from '../../../assets/img/tipalti-vector-logo.png';
import config from '../../../config';
import { DemoModeContainer } from '../../../containers/DemoModeContainer';
import { AgentResponse as ArrakisAgentResponse } from '../../../openapi/arrakis';
import { InviteResponse } from '../../../openapi/avalon';
import {
  AgentResponse,
  AgentResponseAccountCountryEnum,
  UserControllerApi,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { fetchAuthUserDetail } from '../../../slices/AuthSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  AgentTransactionCard,
  FeatureFlagTypeEnum,
  RootState,
} from '../../../types';
import { isAgentActive, isImageValid } from '../../../utils/AgentHelper';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { resizeFile } from '../../../utils/FileUtils';
import { getYentaImageUrl } from '../../../utils/ImgUtils';
import {
  MSDynamicStatusEnum,
  getMSDynamicsStatus,
} from '../../../utils/MSDynamicHelper';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import {
  capitalizeEnum,
  formatAddressObject,
  formatPhoneNumber,
} from '../../../utils/StringUtils';
import { getBaseStripeUrl } from '../../../utils/StripeUtils';
import { parseUrl } from '../../../utils/UrlUtils';
import { cn } from '../../../utils/classUtils';
import FeatureFlagEnabledOnly from '../../FeatureFlagEnabledOnly';
import InvitationStatusCard from '../../JointVenture/InvitationStatusCard';
import InviteJointVentureModalForm from '../../JointVenture/InviteJointVentureModalForm';
import ZenJointVentureSideBarModel from '../../JointVenture/ZenJointVentureSideBarModel';
import ProfileImage from '../../ProfileImage';
import ZenButton from '../../Zen/ZenButton';
import ZenCopyToClipBoard from '../../Zen/ZenCopyToClipBoard';
import ZenPill from '../../Zen/ZenPill';
import AdminOnly from '../../auth/AdminOnly';
import AdminOrSuperAdmin from '../../auth/AdminOrSuperAdmin';
import SelfOnly from '../../auth/SelfOnly';
import SelfOrAdminOnly from '../../auth/SelfOrAdminOnly';
import EditAgentJoinRealEmailSidebarModal from '../EditAgentJoinRealEmailSidebarModal';
import ZenBooleanButton from './ZenBooleanButton';
import ZenStatisticsBlock from './ZenStatisticsCard';

export interface AgentDetailHeaderProps {
  agentId: string;
  performance: ArrakisAgentResponse | undefined;
  detail: AgentResponse | undefined;
  transactionOverview?: AgentTransactionCard[];
  listingOverview?: AgentTransactionCard[];
  invitedJointVentures?: InviteResponse[];
}

export const MS_DYNAMICS_LABEL = {
  [MSDynamicStatusEnum.NOT_STARTED]: 'Not Started',
  [MSDynamicStatusEnum.CONNECTED]: 'Connected',
  [MSDynamicStatusEnum.INVALID]: 'Action Required',
};

const MSDynamicsStatusToClassNameMap: Record<MSDynamicStatusEnum, string> = {
  'Not Started': 'text-warning',
  Connected: 'text-success',
  Invalid: 'text-error',
};

const AgentDetailHeader: React.FC<AgentDetailHeaderProps> = ({
  detail,
  performance,
  listingOverview,
  transactionOverview,
  invitedJointVentures,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const { isAdmin, userDetail, isBroker } = useSelector(
    (state: RootState) => state.auth,
  );

  const { agentPayableInfo } = useSelector(
    (state: RootState) => state.agentDetail,
  );

  const [
    isJoinVentureInvitationModalOpen,
    setIsJoinVentureInvitationModalOpen,
  ] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const [
    isEditJoinRealEmailOpen,
    setIsEditJoinRealEmailOpen,
  ] = useState<boolean>(false);

  const getJointVentureText = (
    invitedJointVentures: InviteResponse[] | undefined,
  ) => {
    if (invitedJointVentures && invitedJointVentures.length) {
      const jointVentureList = invitedJointVentures.map(
        (item) => item.jointVenture,
      );
      if (jointVentureList && jointVentureList.length) {
        const tierList = invitedJointVentures.map((item) => item.tier);

        return `JVS : ${jointVentureList.length} | TIERS:  ${tierList.length}`;
      }
    }
    return 'JVS : 0 | TIERS:  0';
  };

  const onResizedImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (isImageValid(event)) {
      try {
        setLoading(true);
        const uploadedFile = event.target.files![0]!;
        const resizedImage = await resizeFile(uploadedFile);
        await new UserControllerApi(
          getYentaConfiguration(),
        ).uploadAvatarProvided(detail?.id!, (resizedImage! as unknown) as File);
        setLoading(false);
        await dispatch(fetchAuthUserDetail());
      } catch (err) {
        setLoading(false);
        dispatch(showApiErrorModal(err));
        ErrorService.notifyIgnoreHandled('Error uploading user avatar', err);
      }
    } else {
      dispatch(showErrorToast('File size exceeds maximum limit of 10 MB.'));
    }
  };

  const referralLink = `${config.reactAppHostUrl}/register?${qs.stringify({
    sponsorCode: detail!.sponsorCode,
    sponsorName: `${detail!.firstName} ${detail!.lastName}`,
  })}`;

  const isSelf = userDetail?.id === detail?.id;
  const agentDivision = detail?.divisions?.[0];

  const agentMSDynamicsStatus = getMSDynamicsStatus(agentPayableInfo.data);

  return (
    <div className='w-full'>
      <header className='grid grid-cols-1 lg:grid-cols-12 bg-zen-dark-13 p-5 rounded-2xl'>
        <div className='md:col-span-8 flex flex-col md:flex-row'>
          <div className='flex flex-col justify-between gap-y-3 lg:gap-y-0'>
            <div className='pb-3 pr-5 mx-auto space-y-4 md:pb-0 md:mx-0'>
              <div
                className={cn(
                  'border-2 rounded-full',
                  isAgentActive(detail?.agentStatus)
                    ? 'border-success'
                    : 'border-gray-400',
                  'max-w-min',
                  'mx-auto',
                  'self-center',
                )}
              >
                <ProfileImage
                  width={150}
                  imageUrl={getYentaImageUrl(detail?.avatar!)}
                  editable={isAdmin || isSelf}
                  onImageChange={onResizedImageChange}
                  isSubmitting={loading}
                />
              </div>
              <div className='space-y-2'>
                <AdminOrSuperAdmin>
                  <div
                    className={cn(
                      'flex flex-row justify-center space-x-2 cursor-default rounded-full py-1',
                      isAgentActive(detail?.agentStatus)
                        ? 'bg-green-100'
                        : 'bg-red-100',
                    )}
                  >
                    <h4
                      className={cn(
                        'font-zen-body text-base font-semibold',
                        isAgentActive(detail?.agentStatus)
                          ? 'text-green-800'
                          : 'text-red-800',
                      )}
                    >
                      {capitalize(detail?.agentStatus)}
                    </h4>
                  </div>
                </AdminOrSuperAdmin>
                <div
                  className={cn(
                    'flex flex-row justify-center space-x-2 cursor-default rounded-full py-1',
                    performance?.producingStatus
                      ? 'bg-rezen-light-blue-100'
                      : 'bg-gray-400',
                  )}
                >
                  <h4
                    className={cn(
                      'font-zen-body text-base font-semibold',
                      performance?.producingStatus
                        ? 'text-primary-blue'
                        : 'text-dark',
                    )}
                  >
                    {performance?.producingStatus
                      ? 'Producing'
                      : 'Non Producing'}
                  </h4>
                </div>
                <AdminOrSuperAdmin>
                  {performance?.permanentlyProducing && (
                    <div className='rounded-full px-5 text-center py-1 bg-zen-warning-light'>
                      <h4 className='font-zen-body text-base font-semibold text-zen-warning'>
                        Permanently Producing
                      </h4>
                    </div>
                  )}
                </AdminOrSuperAdmin>
              </div>
            </div>
          </div>

          <div className='col-span-12 flex md:px-4'>
            <div className='flex flex-col space-y-3 justify-between'>
              <div className='flex flex-col space-y-3'>
                <div className='pt-2'>
                  <div className='flex flex-col lg:space-y-0 space-y-1 md:items-center md:space-x-4 lg:flex-row'>
                    <p className='text-xl font-semibold text-white lg:text-2xl'>
                      {detail?.fullName}
                    </p>
                    <AdminOrSuperAdmin>
                      <div className='flex flex-row space-x-4'>
                        <div className='p-1 bg-white bg-opacity-20 rounded-lg text-sm font-primary-light text-white px-2'>
                          {`${capitalizeEnum(
                            `${detail?.planMembership?.commissionPlan?.name}`,
                          )} Plan`}
                        </div>
                      </div>
                    </AdminOrSuperAdmin>
                    {agentDivision && (
                      <div className='p-1 flex items-center bg-white bg-opacity-20 rounded-lg text-sm font-primary-light text-white px-2'>
                        <img
                          className='object-contain w-5 h-5'
                          src={agentDivision?.logoUrl}
                          alt='agent-division'
                        />
                        <div className='ml-2'>
                          {agentDivision?.name || '--'}
                        </div>
                      </div>
                    )}
                  </div>

                  {!!performance?.activeDebtExists && (
                    <SelfOrAdminOnly agentId={detail?.id}>
                      <DemoModeContainer hide>
                        <div className='flex flex-row items-center space-x-2 pt-2'>
                          <p className='text-trueGray-300 whitespace-nowrap'>
                            Active Debts:
                          </p>
                          <div>
                            <span className='px-3.5 py-1.5 text-sm space-x-1 text-center bg-zen-danger-light text-error rounded-full'>
                              {displayAmount(performance?.totalDebt!, {
                                hideCurrency: true,
                              })}
                            </span>
                          </div>
                        </div>
                      </DemoModeContainer>
                    </SelfOrAdminOnly>
                  )}

                  <div className='flex flex-row flex-wrap items-center space-x-2 pt-2 font-primary-light'>
                    <p className='text-trueGray-300'>
                      License:{' '}
                      {!!detail?.licenses?.length
                        ? last(detail?.licenses)?.number
                        : 'N/A'}
                    </p>

                    <p className='text-trueGray-300'>•</p>
                    <p className='text-trueGray-300'>
                      Expires:{' '}
                      {!!detail?.licenses?.length
                        ? DateTime.fromISO(
                            `${last(detail?.licenses)?.expirationDate}`,
                          ).toFormat('MM/dd/yyyy')
                        : 'N/A'}
                    </p>
                  </div>
                </div>

                <div className='leading-tight'>
                  <AdminOrSuperAdmin>
                    <div className='flex flex-row items-center space-x-2 text-white'>
                      <p className='text-base font-semibold text-white '>
                        Real ID:{' '}
                      </p>
                      <p className='text-trueGray-300'> #{detail?.id}</p>
                      <div className='min-w-max'>
                        <ZenCopyToClipBoard
                          label='Copy'
                          value={detail?.id!}
                          variant='primary'
                        />
                      </div>
                    </div>
                    <div className='flex flex-row items-center space-x-2 text-white'>
                      <p className='text-base font-semibold text-white '>
                        Keymaker ID:{' '}
                      </p>
                      <p className='text-trueGray-300'>
                        #{detail?.keyMakerId!}
                      </p>
                      <div className='min-w-max'>
                        <ZenCopyToClipBoard
                          label='Copy'
                          value={detail?.keyMakerId!}
                          variant='primary'
                        />
                      </div>
                    </div>
                  </AdminOrSuperAdmin>
                  <div className='flex flex-col md:flex-row md:flex-wrap items-start md:items-center mb-4 md:mb-0 md:space-x-2 text-white'>
                    <AdminOrSuperAdmin>
                      <div className='flex flex-row items-center space-x-2'>
                        <p className='text-base font-semibold'>Slug:</p>
                        <p className='text-white'>{detail?.slug}</p>
                      </div>
                      <div className='flex flex-col md:flex-row md:flex-wrap md:items-center md:space-x-2 md:gap-x-0 md:gap-y-0'>
                        <ZenCopyToClipBoard
                          label='Copy Slug'
                          value={detail?.slug!}
                          variant='primary'
                        />
                        <AdminOnly>
                          <p className='border-r hidden md:block'>&nbsp;</p>
                          <ZenCopyToClipBoard
                            label='Copy Sponsor Link'
                            value={referralLink}
                            variant='primary'
                          />
                          <p className='border-l hidden md:block'>&nbsp;</p>
                          {detail?.stripeCustomerId && (
                            <a
                              href={`${getBaseStripeUrl()}/customers/${
                                detail?.stripeCustomerId
                              }`}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='inline-flex items-center space-x-1 focus:outline-none text-primary-blue p-2 md:p-0'
                              style={{ marginTop: '1.5px' }}
                            >
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className='inline-block'
                                size='xs'
                              />
                              <span style={{ paddingTop: '2px' }}>
                                Stripe Acct.
                              </span>
                            </a>
                          )}
                        </AdminOnly>
                      </div>
                    </AdminOrSuperAdmin>
                    {!isAdmin && (
                      <SelfOnly agentId={detail?.id}>
                        <ZenCopyToClipBoard
                          label='Copy Sponsor Link'
                          value={referralLink}
                          variant='primary'
                        />
                      </SelfOnly>
                    )}
                  </div>

                  <div className='flex flex-col md:flex-row md:items-center text-white'>
                    <p>JoinReal Email:</p>
                    <div className='flex flex-col md:flex-row md:flex-wrap md:items-center md:divide-x my-2 space-y-1 md:space-y-0'>
                      <div className='flex flex-row items-start md:items-center'>
                        <p className='md:px-2.5 pr-2'>
                          {detail?.joinRealEmail
                            ? detail?.joinRealEmail
                            : 'N/A'}
                        </p>
                      </div>

                      <div className='md:px-2.5'>
                        {detail?.accountCountry !==
                          AgentResponseAccountCountryEnum.Canada &&
                          (detail?.hasAgentWebsite ? (
                            <a
                              href={`${parseUrl(config.joinRealEmailDomain)}/${
                                detail?.slug
                              }`}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='inline-flex flex-row items-center space-x-1 text-primary-blue'
                            >
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className='inline-block'
                                size='xs'
                              />
                              <span>Agent Website</span>
                            </a>
                          ) : (
                            <button
                              onClick={() =>
                                history.push(
                                  `/people/${detail?.id}/agent-website-onboarding`,
                                )
                              }
                              className='inline-flex flex-row items-center space-x-1 text-primary-blue'
                            >
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className='inline-block'
                                size='xs'
                              />
                              <span>Create Website</span>
                            </button>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='flex flex-row items-center space-x-2 text-white'>
                  <div className='flex flex-row space-x-1 items-center'>
                    <img src={stocks} alt='stocks' />
                    <p className='pb-0 text-trueGray-300 whitespace-nowrap'>
                      Stock Purchase Plan Opted In:
                    </p>
                  </div>
                  <div>
                    <ZenBooleanButton
                      value={!!detail?.planMembership?.optInToEquityPlan!}
                    />
                  </div>
                  <div className='flex flex-row space-x-1 items-center'>
                    <p className='text-base font-semibold text-white '>
                      Shareworks ID:{' '}
                    </p>
                  </div>
                  <p className='text-trueGray-300'> #{detail?.shareworksId}</p>
                </div>
                <AdminOnly>
                  <div className='flex flex-row items-center space-x-2'>
                    <div className='flex flex-row space-x-1 items-center'>
                      <img src={tipalti} alt='tipalti' />
                      <p className='text-trueGray-300 whitespace-nowrap'>
                        Tipalti Integrated?
                      </p>
                    </div>
                    <div>
                      <ZenBooleanButton value={detail?.tipaltiConfirmed!} />
                    </div>
                  </div>
                </AdminOnly>
                <AdminOnly>
                  <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.REAL_TITLE}>
                    <div className='flex md:flex-row flex-col md:space-x-5 space-y-2 md:space-y-0 '>
                      <div className='flex flex-row space-x-1 pt-2'>
                        <img
                          src={RealTitle}
                          alt='real-title'
                          className='h-5 w-5'
                        />
                        <p className='font-zen-title text-base font-normal text-grey-100'>
                          Joint Venture:
                        </p>
                        <ZenButton
                          label={getJointVentureText(invitedJointVentures)}
                          type='button'
                          variant='rounded-white'
                          onClick={() => setIsOpen(true)}
                        />
                        {!invitedJointVentures?.length && (
                          <InvitationStatusCard
                            status='NOT_INVITED'
                            jointVentureTitle=''
                            onClick={() =>
                              setIsJoinVentureInvitationModalOpen(true)
                            }
                          />
                        )}
                        {isOpen && (
                          <ZenJointVentureSideBarModel
                            isOpen={isOpen}
                            onClose={() => setIsOpen(false)}
                            jointVentures={invitedJointVentures}
                            detail={detail}
                          />
                        )}
                      </div>
                    </div>
                  </FeatureFlagEnabledOnly>
                </AdminOnly>
                <AdminOrSuperAdmin>
                  <div className='flex flex-row space-x-1 items-center'>
                    <p className='text-base font-semibold text-white '>
                      MS Dynamics:
                    </p>
                    <div
                      className={cn(
                        MSDynamicsStatusToClassNameMap[agentMSDynamicsStatus],
                        'pl-2',
                      )}
                    >
                      <ZenPill
                        title={MS_DYNAMICS_LABEL[agentMSDynamicsStatus]}
                        variant={
                          agentMSDynamicsStatus === 'Connected'
                            ? 'success-dark'
                            : 'danger-dark'
                        }
                        textStyle='!py-0 !text-sm'
                      />
                    </div>
                  </div>
                </AdminOrSuperAdmin>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col items-center md:col-span-4 pt-3 space-y-4 lg:items-start lg:pt-0'>
          <div className='w-full'>
            <div>
              <span className='text-lg text-white font-primary-medium'>
                Contact Information
              </span>
            </div>
            <div className='p-4 mt-1 border border-gray-600 rounded-xl'>
              <div className='flex flex-row mb-2 space-x-2'>
                <FontAwesomeIcon
                  icon={faPhone}
                  className='text-gray-200 w-6'
                  size='lg'
                />
                <p className='text-trueGray-300'>
                  {formatPhoneNumber(detail?.phoneNumber) || 'N/A'}
                </p>
              </div>

              <div className='flex flex-row mb-2 space-x-2'>
                <FontAwesomeIcon
                  icon={faAddressCard}
                  className='text-gray-200 w-6'
                  size='lg'
                />
                <p className='text-trueGray-300'>{detail?.emailAddress}</p>
              </div>

              <div className='flex flex-row space-x-2'>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className='text-gray-200 w-6'
                  size='lg'
                />
                <p className='text-trueGray-300'>
                  {!!detail?.addresses?.length
                    ? formatAddressObject(last(detail?.addresses)!)
                    : 'N/A'}
                </p>
              </div>
            </div>
          </div>
          {transactionOverview && (
            <div className='w-full'>
              <div className='flex flex-row items-center space-x-4'>
                <div>
                  <span className='text-lg text-white font-primary-medium'>
                    Transactions
                  </span>
                </div>
                <div>
                  <Link
                    to={
                      isAdmin || isBroker
                        ? `/people/${detail?.id}/transactions`
                        : '/transactions'
                    }
                  >
                    <button className='text-primary-blue font-primary-medium focus:outline-none hover:opacity-70'>
                      See all
                    </button>
                  </Link>
                </div>
              </div>

              <ZenStatisticsBlock
                items={transactionOverview.map((transaction) => ({
                  ...transaction,
                  subtitle: capitalize(transaction.subtitle),
                }))}
                statisticType='transactions'
              />
            </div>
          )}
          {listingOverview && (
            <div className='w-full'>
              <div className='flex flex-row items-center space-x-4'>
                <div>
                  <span className='text-lg text-white font-primary-medium'>
                    Listings
                  </span>
                </div>
                <div>
                  <Link
                    to={
                      isAdmin || isBroker
                        ? `/people/${detail?.id}/listings`
                        : '/listings'
                    }
                  >
                    <button className='text-primary-blue font-primary-medium focus:outline-none hover:opacity-70'>
                      See all
                    </button>
                  </Link>
                </div>
              </div>

              <ZenStatisticsBlock
                items={listingOverview.map((listing) => ({
                  ...listing,
                  subtitle: capitalize(listing.subtitle),
                }))}
                statisticType='listings'
              />
            </div>
          )}
        </div>
      </header>

      {isEditJoinRealEmailOpen && (
        <EditAgentJoinRealEmailSidebarModal
          agentDetail={detail!}
          isOpen={isEditJoinRealEmailOpen}
          onClose={() => setIsEditJoinRealEmailOpen(false)}
        />
      )}
      {isJoinVentureInvitationModalOpen && (
        <InviteJointVentureModalForm
          title={`Invite ${detail?.fullName} to Join`}
          agentId={detail?.id!}
          onClose={() => setIsJoinVentureInvitationModalOpen(false)}
        />
      )}
    </div>
  );
};

export default AgentDetailHeader;
