import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import ResourceContainer from '../components/ResourceContainer';
import { SwaggerControllerApi } from '../openapi/bff';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { getBffConfiguration } from '../utils/OpenapiConfigurationUtils';

interface ApiDocsRouteProps {}

const ApiDocsRoute: React.FC<ApiDocsRouteProps> = () => {
  const dispatch = useDispatch();
  const [specData, setSpecData] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchSpec = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await new SwaggerControllerApi(
        getBffConfiguration(),
      ).swaggerApiDoc();
      setSpecData(data);
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify(
        'Unable to get the api documentation. Please try again in a few moments.',
        e,
      );
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchSpec();
  }, [fetchSpec]);

  return (
    <ResourceContainer
      isEmpty={!specData}
      loading={isLoading}
      resourceName='Swagger Api'
    >
      {/* Setting supportedSubmitMethods to [] effectively turns off the Try It Out feature. */}
      <SwaggerUI spec={specData} supportedSubmitMethods={[]} />
    </ResourceContainer>
  );
};

export default ApiDocsRoute;
