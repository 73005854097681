import ContentLoader from 'react-content-loader';

const TableLoader: React.FC = () => {
  return (
    <ContentLoader
      width='100%'
      viewBox='0 0 910 550'
      backgroundColor='#eaeced'
      foregroundColor='#ffffff'
      title='Loading Table...'
    >
      <rect x='1' y='1' rx='3' ry='3' width='906' height='17' />
      <circle cx='829' cy='79' r='11' />
      <circle cx='864' cy='79' r='11' />
      <rect x='54' y='71' rx='3' ry='3' width='141' height='15' />
      <rect x='255' y='70' rx='3' ry='3' width='299' height='15' />
      <rect x='611' y='70' rx='3' ry='3' width='141' height='15' />
      <rect x='5' y='111' rx='3' ry='3' width='897' height='2' />
      <circle cx='830' cy='140' r='11' />
      <circle cx='865' cy='140' r='11' />
      <rect x='55' y='132' rx='3' ry='3' width='141' height='15' />
      <rect x='256' y='131' rx='3' ry='3' width='299' height='15' />
      <rect x='612' y='131' rx='3' ry='3' width='141' height='15' />
      <rect x='6' y='172' rx='3' ry='3' width='897' height='2' />
      <circle cx='831' cy='198' r='11' />
      <circle cx='866' cy='198' r='11' />
      <rect x='56' y='190' rx='3' ry='3' width='141' height='15' />
      <rect x='257' y='189' rx='3' ry='3' width='299' height='15' />
      <rect x='613' y='189' rx='3' ry='3' width='141' height='15' />
      <rect x='7' y='230' rx='3' ry='3' width='897' height='2' />
      <circle cx='832' cy='259' r='11' />
      <circle cx='867' cy='259' r='11' />
      <rect x='57' y='251' rx='3' ry='3' width='141' height='15' />
      <rect x='258' y='250' rx='3' ry='3' width='299' height='15' />
      <rect x='614' y='250' rx='3' ry='3' width='141' height='15' />
      <rect x='8' y='291' rx='3' ry='3' width='897' height='2' />
      <circle cx='831' cy='319' r='11' />
      <circle cx='866' cy='319' r='11' />
      <rect x='56' y='311' rx='3' ry='3' width='141' height='15' />
      <rect x='257' y='310' rx='3' ry='3' width='299' height='15' />
      <rect x='613' y='310' rx='3' ry='3' width='141' height='15' />
      <rect x='7' y='351' rx='3' ry='3' width='897' height='2' />
      <circle cx='832' cy='380' r='11' />
      <circle cx='867' cy='380' r='11' />
      <rect x='57' y='372' rx='3' ry='3' width='141' height='15' />
      <rect x='258' y='371' rx='3' ry='3' width='299' height='15' />
      <rect x='614' y='371' rx='3' ry='3' width='141' height='15' />
      <rect x='5' y='409' rx='3' ry='3' width='897' height='2' />
      <rect x='1' y='5' rx='3' ry='3' width='2' height='465' />
      <rect x='905' y='49' rx='3' ry='3' width='2' height='465' />
      <circle cx='832' cy='440' r='11' />
      <circle cx='867' cy='440' r='11' />
      <rect x='57' y='432' rx='3' ry='3' width='141' height='15' />
      <rect x='258' y='431' rx='3' ry='3' width='299' height='15' />
      <rect x='614' y='431' rx='3' ry='3' width='141' height='15' />
      <rect x='5' y='469' rx='3' ry='3' width='897' height='2' />
      <rect x='2' y='36' rx='3' ry='3' width='906' height='17' />
      <rect x='3' y='13' rx='3' ry='3' width='68' height='33' />
      <rect x='172' y='10' rx='3' ry='3' width='149' height='33' />
      <rect x='494' y='11' rx='3' ry='3' width='137' height='33' />
      <rect x='732' y='12' rx='3' ry='3' width='72' height='33' />
      <rect x='883' y='10' rx='3' ry='3' width='24' height='33' />
    </ContentLoader>
  );
};

export default TableLoader;
