import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps, ISelectOption } from '../types';
import FormErrorMessage from './FormErrorMessage';
import CustomGroupRadioButton from './CustomGroupRadioButton';

export interface ControlledGroupRadioButtonInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  options: Array<ISelectOption>;
  disabled?: boolean;
}

const ControlledGroupRadioButtonInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  disabled = false,
  options,
  tooltipIndex,
  shouldUnregister = true,
  ...rest
}: ControlledGroupRadioButtonInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      {...rest}
      shouldUnregister={shouldUnregister}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <div className='w-full space-y-2'>
          <div className='flex flex-col space-y-1'>
            {label && <label htmlFor={name}>{label}</label>}
            <div data-tooltip-index={tooltipIndex}>
              <CustomGroupRadioButton
                items={options}
                onChange={onChange}
                value={value}
                disabled={disabled}
              />
            </div>
          </div>
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledGroupRadioButtonInput;
