import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { cn } from '../../utils/classUtils';

export interface ZenAccordionProps {
  title: string;
  isOpenDefault?: boolean;
  canToggle?: boolean;
  variant?: ZenAccordionVariant;
  googleAnalytics?(): void;
}

export type ZenAccordionVariant = 'default' | 'secondary';

const ZenAccordion: React.FC<ZenAccordionProps> = ({
  title,
  isOpenDefault = false,
  children,
  canToggle = true,
  variant = 'default',
  googleAnalytics = () => {},
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenDefault);

  const variantClassMap: Record<ZenAccordionVariant, string> = {
    default: 'p-3 border-b',
    secondary: 'items-center rounded-xl',
  };

  const variantTextClassMap: Record<ZenAccordionVariant, string> = {
    default: 'font-primary-regular text-dark',
    secondary: 'font-zen-title font-medium text-xl text-zen-dark-9',
  };

  const variantOpenClassMap: Record<ZenAccordionVariant, string> = {
    default: 'bg-zen-blue-2 border-zen-blue-2',
    secondary: '',
  };

  const variantCloseClassMap: Record<ZenAccordionVariant, string> = {
    default: 'bg-gray-100 border-gray-200',
    secondary: '',
  };

  const variantIconCloseClassMap: Record<ZenAccordionVariant, string> = {
    default: 'text-primary-blue',
    secondary: 'text-zen-dark-8 p-1 rounded-full shadow-accordion',
  };

  const variantIconOpenClassMap: Record<ZenAccordionVariant, string> = {
    default: 'text-primary-blue',
    secondary: 'text-white bg-zen-dark-13 p-1 rounded-full',
  };

  const variantContainerOpenClassMap: Record<ZenAccordionVariant, string> = {
    default: '',
    secondary: 'px-6 py-4.5 rounded-xl shadow-accordion',
  };

  const variantContainerCloseClassMap: Record<ZenAccordionVariant, string> = {
    default: '',
    secondary: 'px-6 py-4.5 rounded-xl border border-zen-light-gray-2',
  };

  const containerClassName = isOpen
    ? variantContainerOpenClassMap[variant]
    : variantContainerCloseClassMap[variant];

  const headerClassName = cn(
    'w-full flex flex-row justify-between cursor-pointer',
    isOpen ? variantOpenClassMap[variant] : variantCloseClassMap[variant],
    variantClassMap[variant],
  );

  const textClassName = variantTextClassMap[variant];

  const iconClassName = isOpen
    ? variantIconOpenClassMap[variant]
    : variantIconCloseClassMap[variant];

  const handleAccordionClick = () => {
    if (!isOpen) googleAnalytics();
    if (canToggle) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className={cn(containerClassName)}>
      <div className={cn(headerClassName)} onClick={handleAccordionClick}>
        <div className={cn(textClassName)}>{title}</div>
        <div className={cn(iconClassName, 'flex items-center mr-1')}>
          <FontAwesomeIcon
            icon={isOpen ? faChevronUp : faChevronDown}
            size='sm'
          />
        </div>
      </div>
      {isOpen && children}
    </div>
  );
};

export default ZenAccordion;
