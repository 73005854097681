interface TextInfoRowProps {
  title: string;
  value: string;
}

const TextInfoRow: React.FC<TextInfoRowProps> = ({ title, value }) => {
  return (
    <div className='flex items-center justify-between'>
      <span className='text-base text-gray-600 font-primary-light'>
        {title}
      </span>
      <span className='text-base text-dark font-primary-medium'>{value}</span>
    </div>
  );
};

export default TextInfoRow;
