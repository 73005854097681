import { faCalendar, faUser } from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import { FlexTeamDto } from '../../../../openapi/yenta';
import { RootState } from '../../../../types';
import { cn } from '../../../../utils/classUtils';
import AdminOnly from '../../../auth/AdminOnly';
import GeminiTransactionCodeWithCopy from '../../Transaction/Header/GeminiTransactionCodeWithCopy';
import GeminiTransactionHeaderRowCell from '../../Transaction/Header/GeminiTransactionHeaderRowCell';
import CommissionDocumentApproverPill from '../CommissionDocumentApproverPill';
import GeminiTeamsType from '../GeminiTeamsType';

interface GeminiProTeamDetailHeaderSectionProps {
  teamDetailOverview: FlexTeamDto;
  totalMemberCount?: number;
}

const Divider = () => <div className='w-[1px] bg-grey-200 h-[18px]' />;

const GeminiProTeamDetailHeaderSection: React.FC<GeminiProTeamDetailHeaderSectionProps> = ({
  teamDetailOverview,
  totalMemberCount,
}) => {
  const { isAdmin, isBroker } = useSelector((state: RootState) => state.auth);

  const isAgent = !(isAdmin || isBroker);
  return (
    <header
      data-testid='pro-team-detail-header'
      className={cn(
        'md:p-6 p-2 flex md:flex-row flex-col bg-primary-navy text-white rounded-[15px] md:mx-4 mx-2 my-1',
        isAgent && 'items-center',
      )}
    >
      <div className='md:w-11/12 w-full'>
        <div className='flex md:flex-row flex-col items-center space-x-3'>
          <span className='font-zen-body-2 text-xl leading-7 font-bold'>
            {teamDetailOverview.name}
          </span>
          <AdminOnly>
            <GeminiTransactionCodeWithCopy
              transactionCode={teamDetailOverview.id}
            />
          </AdminOnly>
          <Divider />
          <GeminiTransactionHeaderRowCell
            leftComponentIcon={faUser}
            label={`${totalMemberCount} ${pluralize(
              'Member',
              totalMemberCount,
            )}`}
          />
          <Divider />
          <GeminiTransactionHeaderRowCell
            leftComponentIcon={faCalendar}
            label='Created On:'
            value={DateTime.fromMillis(teamDetailOverview.createdAt!).toFormat(
              'MMMM dd, yyyy',
            )}
          />
        </div>
        <AdminOnly>
          <div className='mt-10'>
            <GeminiTransactionHeaderRowCell
              leftComponentIcon={faUser}
              label='Commission Document Approver'
              rightComponent={
                <CommissionDocumentApproverPill
                  type={teamDetailOverview?.cdaApprover}
                />
              }
            />
          </div>
        </AdminOnly>
      </div>
      <div className='md:w-1/12 w-full flex md:justify-end'>
        <GeminiTeamsType type={teamDetailOverview.config?.teamType!} />
      </div>
    </header>
  );
};

export default GeminiProTeamDetailHeaderSection;
