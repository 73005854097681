import ZenSimpleModal from '../Zen/Modal/ZenSimpleModal';
import ZenButton from '../Zen/ZenButton';

interface OptInModalProps {
  onClose(): void;
  onOptIn(): void;
  disabled?: boolean;
}

const OptInModal: React.FC<OptInModalProps> = ({
  onClose,
  onOptIn,
  disabled,
}) => {
  return (
    <ZenSimpleModal
      size='extraLarge'
      isOpen
      title='Opt-in to receive text message'
      onClose={onClose}
    >
      <div className='p-6 text-center font-inter'>
        You have not opted-in to receiving text messages. To resume receiving
        transactional text messages from Real or its affiliates at the business
        phone number you provided in your profile, please select &quot;Yes&quot;
      </div>
      <div className='flex justify-center space-x-4 pb-6'>
        <ZenButton
          onClick={onClose}
          className='w-40'
          label='No'
          variant='dark-outline'
        />
        <ZenButton
          onClick={onOptIn}
          className='w-40'
          label='Yes'
          variant='primary'
          isDisabled={disabled}
          isSubmitting={disabled}
        />
      </div>
    </ZenSimpleModal>
  );
};

export default OptInModal;
