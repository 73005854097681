import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faEnvelope,
  faSignature,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  InviteResponseStatusEnum,
  MembershipResponseStatusEnum,
} from '../../openapi/avalon';
import { EnumMap } from '../../types';
import ZenButton from '../Zen/ZenButton';

export interface JVStatusPillProps {
  status:
    | InviteResponseStatusEnum
    | MembershipResponseStatusEnum
    | 'NOT_INVITED';
  onClick?(): void;
}

const buttonIconVariantToClassNameMap: EnumMap<
  InviteResponseStatusEnum | MembershipResponseStatusEnum | 'NOT_INVITED',
  IconProp | undefined
> = {
  ACCEPTED: undefined,
  CREATED: faXmark,
  ACTIVE: undefined,
  DECLINED: faEnvelope,
  PAYMENT_FAILED: undefined,
  PENDING_PAYMENT: undefined,
  WITHDRAWN: faEnvelope,
  PENDING_SIGNATURE: faSignature,
  VIEWED: faXmark,
  NOT_INVITED: faEnvelope,
};

const buttonLabelVariantToClassNameMap: EnumMap<
  InviteResponseStatusEnum | MembershipResponseStatusEnum | 'NOT_INVITED',
  string | undefined
> = {
  ACCEPTED: undefined,
  CREATED: 'Withdraw Invitation',
  ACTIVE: undefined,
  DECLINED: 'Invite to Join',
  PAYMENT_FAILED: undefined,
  PENDING_PAYMENT: undefined,
  WITHDRAWN: 'Invite to Join',
  PENDING_SIGNATURE: 'Agent Signed',
  VIEWED: 'Withdraw Invitation',
  NOT_INVITED: 'Invite to Join',
};

const JVStatusPill: React.FC<JVStatusPillProps> = ({ status, onClick }) => {
  return (
    <div className='w-max'>
      <ZenButton
        onClick={onClick}
        variant='primary-link'
        label={buttonLabelVariantToClassNameMap[status]}
        LeftIconComponent={
          <FontAwesomeIcon
            icon={buttonIconVariantToClassNameMap[status]!}
            className='text-primary-blue'
            size='sm'
          />
        }
      />
    </div>
  );
};

export default JVStatusPill;
