import qs from 'qs';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ZenAgentResourceTable from '../components/Zen/Agent/ZenAgentResourceTable';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenRoute from '../components/Zen/ZenRoute';

const ZenPeopleSearchRoute: React.FC = () => {
  const location = useLocation();
  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );

  return (
    <ZenRoute title='People'>
      <ZenPageLayoutWithSearch
        path={[
          { title: 'Home', url: '/' },
          { title: 'People', url: '/people' },
        ]}
        search={search}
        onSearchChange={setSearch}
      >
        <ZenAgentResourceTable search={search} />
      </ZenPageLayoutWithSearch>
    </ZenRoute>
  );
};

export default ZenPeopleSearchRoute;
