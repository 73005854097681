import React from 'react';
import { useForm } from 'react-hook-form-v7';
import { InviteResponse } from '../../../openapi/avalon';
import { getTotalAvailableShares } from '../../../utils/JVUtils';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';

export interface JVSignUpBannerProps {
  inviteResponse?: InviteResponse;
}
interface FormData {
  quantity: number;
}

const JVSignUpBanner: React.FC<JVSignUpBannerProps> = ({ inviteResponse }) => {
  const { control } = useForm<FormData>();

  const getTotalSignUpFee = () => {
    const issuePrice = inviteResponse?.jointVenture?.issuePrice;
    if (issuePrice?.amount) {
      return issuePrice?.amount * (inviteResponse?.interests || 1);
    }
    return 0;
  };

  if (!inviteResponse) {
    return null;
  }

  return (
    <div className='bg-grey-100 flex justify-center items-center py-10 w-full'>
      <div className='w-full xl:w-1/2 lg:w-2/3 rounded-xl border bg-white p-6'>
        <div className='flex flex-row justify-between space-between'>
          <div className='flex flex-col flex-grow'>
            <div className='font-zen-title font-medium text-4xl leading-10 text-zen-dark-9'>
              {inviteResponse?.jointVenture?.name
                ? inviteResponse?.jointVenture?.name
                : ''}
            </div>
            <div className='flex flex-row items-center'>
              <p className='font-zen-body font-normal text-base leading-6 text-zen-dark-7'>
                {inviteResponse?.tier?.name} tier
              </p>
              <div className='h-full'>
                <p className='text-zen-dark-7 mx-2 text-lg pt-1'>&bull;</p>
              </div>
              <p className='font-zen-body font-normal text-sm leading-6 text-zen-dark-9'>
                ${inviteResponse?.jointVenture?.issuePrice?.amount} per tier
              </p>
            </div>
          </div>
          <div className='flex flex-col space-y-2'>
            <div>
              <p className='font-zen-body text-right font-normal text-base text-zen-dark-9'>
                Available Quantity {getTotalAvailableShares(inviteResponse)}
              </p>
            </div>
            <div className='flex flex-row items-center'>
              <p className='mr-3 font-zen-body font-normal text-base text-zen-dark-6'>
                Qty
              </p>
              <div className='w-[150px]'>
                <ZenControlledTextInput
                  name='quantity'
                  control={control}
                  shouldUnregister={false}
                  readOnly
                  defaultValue={inviteResponse?.interests}
                  direction='rtl'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-row space-between items-end mt-4 pt-4'>
          <div>
            <p className='font-zen-body font-normal text-base  text-zen-dark-9'>
              Total:
            </p>
          </div>
          <div className='border-dashed border-b-2 flex-grow mx-3' />
          <div>
            <p className='font-zen-title font-medium text-zen-dark-9 text-4xl -mb-2'>
              ${getTotalSignUpFee()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JVSignUpBanner;
