import { faCircleInfo, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ZenNavRealLogo from '../assets/img/nav_real_logo_blue.png';
import ResourceContainer from '../components/ResourceContainer';
import { KnowledgePageSidebar } from '../components/WeathManagement/EarningLandingPages/KnowledgePageSidebar';
import {
  Earning,
  EarningType,
} from '../components/WeathManagement/WealthManagementTypes';
import { WealthPlanTooltip } from '../components/WeathManagement/WealthManagementUtils';
import EditReferralGoalsSideBarV2, {
  GoalsFormInput,
} from '../components/WeathManagement/WealthPlanReferral/V2/EditReferralGoalsSidebarV2';
import {
  queryParamsToWealthPlanReferralGoals,
  wealthGoalsInputToGoalDetails,
} from '../components/WeathManagement/WealthPlanReferral/V2/wealthPlanReferralUtilsV2';
import { WealthPlanReferralPageV2 } from '../components/WeathManagement/WealthPlanReferral/WealthPlanReferralPageV2';
import ZenButton from '../components/Zen/ZenButton';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenStatusPill from '../components/Zen/ZenStatusPill';
import MainFooter from '../containers/MainFooter';
import {
  AgentWealthManagementApi,
  MoneyValueCurrencyEnum,
  ProspectiveAgentInfoCountryEnum,
} from '../openapi/plutus';
import ErrorService from '../services/ErrorService';
import {
  fetchDefaultWealthGoals,
  fetchReferralGoalsPreview,
  fetchReferralInfo,
  fetchWealthGoals,
  getEarningsOverview,
  setWealthGoals,
  setWealthKnowledgeLandingPage,
  setWealthPlanReferralToken,
} from '../slices/WealthManagementSlice';
import { RootState } from '../types';
import { getWealthManagementConfiguration } from '../utils/OpenapiConfigurationUtils';

const isStringEarningType = (value: string): value is EarningType =>
  Object.values(Earning).includes(value as EarningType);

const WealthPlanReferralRouteV2: React.FC = () => {
  const location = useLocation();
  const [editGoalsSideBarOpen, setEditGoalsSideBarOpen] = useState(false);
  const params = useMemo(() => new URLSearchParams(location.search), [
    location,
  ]);
  const token = params.get('token');
  const isPreviewMode = !token;
  const previewGoals = useMemo(
    () => queryParamsToWealthPlanReferralGoals(params),
    [params],
  );
  const landingPage = params.get('landingpage');

  const {
    wealthManagement: {
      wealthPlanReferralToken,
      readonlyMode,
      earningsOverviewResponse,
      wealthGoalsResponse,
      referralInfo,
    },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (landingPage && isStringEarningType(landingPage)) {
      dispatch(setWealthKnowledgeLandingPage(landingPage));
    }
  }, [dispatch, landingPage]);

  useEffect(() => {
    if (token) {
      dispatch(setWealthPlanReferralToken(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    const callReferralViewed = async () => {
      if (!wealthPlanReferralToken) {
        return;
      }
      try {
        await new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).referralViewed(wealthPlanReferralToken);
      } catch (e) {
        ErrorService.notify(
          `Failed to call referralViewed [token=${wealthPlanReferralToken}`,
          e,
        );
      }
    };

    if (wealthPlanReferralToken) {
      dispatch(fetchWealthGoals());
      dispatch(fetchReferralInfo());
      dispatch(fetchDefaultWealthGoals());
      callReferralViewed();
    }
  }, [dispatch, wealthPlanReferralToken]);

  useEffect(() => {
    if (wealthPlanReferralToken) {
      dispatch(getEarningsOverview());
    }
  }, [dispatch, wealthGoalsResponse.data, wealthPlanReferralToken]);

  useEffect(() => {
    if (earningsOverviewResponse.error || earningsOverviewResponse.data) {
      return;
    }
    if (isPreviewMode && !!previewGoals) {
      dispatch(fetchReferralGoalsPreview(previewGoals));
    }
  }, [
    dispatch,
    earningsOverviewResponse.data,
    earningsOverviewResponse.error,
    isPreviewMode,
    previewGoals,
  ]);

  const firstname =
    params.get('referringAgentFirstName') ?? referralInfo.data?.firstName;
  const lastname =
    params.get('referringAgentLastName') ?? referralInfo.data?.lastName;

  const isEmpty =
    !!earningsOverviewResponse.error || (isPreviewMode && !previewGoals);

  return (
    <div className='flex flex-col lg:flex-row min-h-screen'>
      <div
        id='main-page'
        className='flex-grow lg:max-h-screen scrollbar overflow-y-auto overflow-x-hidden'
      >
        <div className='relative min-h-[calc(100vh_-_50px)]'>
          <div className=' bg-zen-dark-21 h-14 w-full py-3 px-3 flex items-center'>
            <img src={ZenNavRealLogo} alt='real-logo' className='ml-4 h-5' />
          </div>
          <ZenRoute title='WealthPlan Referral'>
            <ResourceContainer
              loading={
                earningsOverviewResponse.loading &&
                !earningsOverviewResponse.data
              }
              isEmpty={isEmpty}
              emptyMessage='WealthPlan Referral not found'
              resourceName='WealthPlan Referral'
            >
              <div className='p-5 h-full'>
                <div className='flex justify-between my-5'>
                  {firstname && lastname && (
                    <div className='text-xl flex items-center gap-3'>
                      Welcome, {firstname} {lastname}
                      {isPreviewMode && (
                        <ZenStatusPill
                          text={
                            (
                              <WealthPlanTooltip title='WealthPlan is not editable in Preview mode. Close this page to return to edit or send the WealthPlan.'>
                                <div>
                                  Preview{' '}
                                  <FontAwesomeIcon icon={faCircleInfo} />
                                </div>
                              </WealthPlanTooltip>
                            ) as any
                          }
                          variant='warning'
                          fontSize='text-sm'
                        />
                      )}
                    </div>
                  )}

                  {!readonlyMode && (
                    <div className='flex-shrink-0'>
                      <ZenButton
                        variant='primary-outline'
                        onClick={() => setEditGoalsSideBarOpen(true)}
                        label={
                          (
                            <div className='px-3'>
                              <FontAwesomeIcon
                                icon={faPencil}
                                className='mr-1'
                              />
                              Edit Goal
                            </div>
                          ) as any
                        }
                      />
                    </div>
                  )}
                </div>
                <WealthPlanReferralPageV2
                  openGoalsSideBar={() => setEditGoalsSideBarOpen(true)}
                />
                {editGoalsSideBarOpen && (
                  <EditReferralGoalsSideBarV2
                    isOpen
                    onClose={() => setEditGoalsSideBarOpen(false)}
                    onSubmit={(values: GoalsFormInput) => {
                      const currency =
                        referralInfo.data?.country ===
                        ProspectiveAgentInfoCountryEnum.Canada
                          ? MoneyValueCurrencyEnum.Cad
                          : MoneyValueCurrencyEnum.Usd;
                      const request = wealthGoalsInputToGoalDetails(
                        values,
                        currency,
                      );
                      dispatch(setWealthGoals(request));
                      setEditGoalsSideBarOpen(false);
                    }}
                  />
                )}
                <KnowledgePageSidebar />
              </div>
            </ResourceContainer>
          </ZenRoute>
        </div>
        <MainFooter />
      </div>
    </div>
  );
};

export default WealthPlanReferralRouteV2;
