import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import CopyToClipboardHover from '../../../CopyToClipboardHover';

interface GeminiTransactionCodeWithCopyProps {
  transactionCode?: string;
}

const GeminiTransactionCodeWithCopy: React.FC<GeminiTransactionCodeWithCopyProps> = ({
  transactionCode,
}) => {
  if (!transactionCode) return null;

  return (
    <div className='flex flex-row flex-wrap items-center px-2 py-1 bg-regent-200 rounded-md flex-grow-0 border border-regent-300'>
      <p className='font-zen-body-2 text-sm font-normal text-primary-dark max-w-full flex-1 truncate'>
        {transactionCode}
      </p>
      <div className='h-5 w-5 flex items-center justify-center ml-1 flex-shrink-0'>
        <CopyToClipboardHover
          value={transactionCode}
          copyToltipLabel='Copy Code'
          copyIcon={
            <FontAwesomeIcon
              icon={faCopy}
              className='text-primary-dark text-sm'
              data-testid='copy-to-clipboard-copy-icon'
            />
          }
        />
      </div>
    </div>
  );
};

export default GeminiTransactionCodeWithCopy;
