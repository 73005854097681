import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { ListingPosterItem } from '../../../../../utils/LeoUtils';
import LeoSocialShareablesInfoSection from '../Shareables/LeoSocialShareablesInfoSection';

type LeoSocialShareabllesSmallModalProps = {
  closeModal(): void;
  shareable: ListingPosterItem;
};

const LeoSocialShareabllesSmallModal: React.FC<LeoSocialShareabllesSmallModalProps> = ({
  closeModal,
  shareable,
}) => {
  return (
    <div
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'
      title='Leo Social Shareable Modal'
    >
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-60'
        role='button'
        onClick={(e) => {
          e.preventDefault();
          closeModal();
        }}
      />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
        <div
          className={classNames(
            'max-h-[85%] rounded-xl z-10 scrollbar overflow-hidden w-full lg:w-11/12 xl:w-3/4',
          )}
          aria-label='simple-modal'
        >
          <div key={shareable.listingDetails.id} className='relative bg-white'>
            <div className='flex flex-col sm:flex-row rounded-xl overflow-y-scroll h-[500px] shadow-zen-web'>
              <div className='bg-grey-200 p-4 rounded-lg'>
                <img
                  id='poster-image'
                  src={shareable.posterLink}
                  className='h-full w-full'
                  alt='poster'
                />
              </div>
              <div className='rounded-lg'>
                <LeoSocialShareablesInfoSection shareablesInfo={shareable} />
              </div>
              <div className='absolute top-2 right-2 bg-regent-400 w-6 h-6 rounded-full flex items-center justify-center'>
                <FontAwesomeIcon
                  icon={faClose}
                  className='text-white bg-regent-400 cursor-pointer text-sm'
                  onClick={(e) => {
                    e.preventDefault();
                    closeModal();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeoSocialShareabllesSmallModal;
