export const getTabsWithActiveTabFirst = (
  tabWidths: number[],
  activeTabIndex: number,
) => {
  if (activeTabIndex < 0 || activeTabIndex >= tabWidths.length) {
    return [...tabWidths];
  }

  if (tabWidths.length === 0) {
    return [];
  }

  const tabWidthsCopy = [...tabWidths];

  if (activeTabIndex !== -1) {
    const activeTab = tabWidthsCopy[activeTabIndex];
    tabWidthsCopy.splice(activeTabIndex, 1);
    tabWidthsCopy.unshift(activeTab);
  }

  return tabWidthsCopy;
};

export const canFitLastTab = (
  containerWidth: number,
  totalWidthOfTabsToShow: number,
  tabWidth: number,
  isLastTab: boolean,
) => {
  if (!isLastTab) {
    return false;
  }
  return totalWidthOfTabsToShow + tabWidth <= containerWidth;
};

export const canFitTabAndMoreTab = (
  containerWidth: number,
  totalWidthOfVisibleTabs: number,
  tabWidth: number,
  moreTabWidth: number,
) => {
  return totalWidthOfVisibleTabs + tabWidth + moreTabWidth <= containerWidth;
};
