import React from 'react';
import ContentLoader from 'react-content-loader';

const RevShareInsightsLoader: React.FC = () => {
  return (
    <ContentLoader
      width='100%'
      viewBox='0 0 910 600'
      backgroundColor='#eaeced'
      foregroundColor='#ffffff'
    >
      <rect x='10' y='8' rx='3' ry='3' width='60' height='6' />
      <rect x='450' y='8' rx='3' ry='3' width='60' height='6' />
      <rect x='840' y='8' rx='3' ry='3' width='60' height='6' />
      <circle cx='30' cy='55' r='25' />
      <circle cx='90' cy='55' r='25' />
      <circle cx='150' cy='55' r='25' />
      <circle cx='210' cy='55' r='25' />
      <circle cx='270' cy='55' r='25' />
      <circle cx='330' cy='55' r='25' />
      <circle cx='390' cy='55' r='25' />
      <circle cx='450' cy='55' r='25' />
      <circle cx='510' cy='55' r='25' />
      <circle cx='570' cy='55' r='25' />
      <circle cx='630' cy='55' r='25' />
      <circle cx='690' cy='55' r='25' />
      <circle cx='750' cy='55' r='25' />
      <circle cx='810' cy='55' r='25' />
      <circle cx='870' cy='55' r='25' />
      <rect x='20' y='110' rx='5' ry='5' width='210' height='65' />
      <rect x='240' y='110' rx='5' ry='5' width='210' height='65' />
      <rect x='465' y='110' rx='5' ry='5' width='430' height='120' />
      <rect x='20' y='185' rx='5' ry='5' width='430' height='150' />
      <rect x='20' y='345' rx='5' ry='5' width='430' height='235' />
      <rect x='465' y='240' rx='5' ry='5' width='430' height='340' />
    </ContentLoader>
  );
};

export default RevShareInsightsLoader;
