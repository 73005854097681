import React from 'react';
import { useGemini } from '../../../hooks/useGemini';
import { cn } from '../../../utils/classUtils';

interface ZenSideBarMenuItemProps {
  icon: React.ReactElement;
  label: string;
}

const ZenSideBarMenuItem: React.FC<ZenSideBarMenuItemProps> = ({
  icon,
  label,
}) => {
  const isGeminiEnabled = useGemini();

  return (
    <div className='flex flex-row items-center py-2 my-1 px-4 rounded-lg group'>
      <span
        className={cn(
          'text-primary-light pr-2',
          isGeminiEnabled
            ? 'group-hover:text-aqua'
            : 'group-hover:text-zen-primary-6',
        )}
      >
        {icon}
      </span>
      <span
        className={cn(
          'text-primary-light text-sm mb-0.5',
          isGeminiEnabled
            ? 'group-hover:text-aqua font-zen-body-2'
            : 'group-hover:text-zen-primary-6 font-zen-body',
        )}
      >
        {label}
      </span>
    </div>
  );
};

export default ZenSideBarMenuItem;
