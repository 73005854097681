import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DetailPageLoader from '../components/DetailPageLoader';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenResourceContainer from '../components/Zen/ZenResourceContainer';
import { fetchApplicationById } from '../slices/ApplicationSlice';
import { RootState } from '../types';
import ZenApplicationFormContent from './ZenApplicationFormContent';

type Match = {
  id: string;
};

const ZenApplicationFormDetailRoute: React.FC = () => {
  const dispatch = useDispatch();
  const { id: applicationId } = useParams() as Match;
  const {
    applicationDetail,
    loadingDetail,
    fetchDetailErrorCode,
  } = useSelector((state: RootState) => state.applicationDetail);

  useEffect(() => {
    dispatch(fetchApplicationById(applicationId));
  }, [dispatch, applicationId]);

  return (
    <ZenResourceContainer
      loading={loadingDetail}
      LoaderComponent={<DetailPageLoader />}
      errorCode={fetchDetailErrorCode}
      isEmpty={!applicationDetail}
      resourceName='ApplicationForm'
    >
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Applications', url: '/applications' },
          {
            title: `${applicationDetail?.firstName} ${applicationDetail?.lastName}`,
            url: `/applications/${applicationDetail?.id}`,
          },
        ]}
      >
        {applicationDetail && (
          <ZenApplicationFormContent
            application={applicationDetail}
            id={applicationDetail.id!}
            name={`${applicationDetail?.firstName} ${applicationDetail?.lastName}`}
          />
        )}
      </ZenPageLayout>
    </ZenResourceContainer>
  );
};

export default ZenApplicationFormDetailRoute;
