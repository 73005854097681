import { useState } from 'react';
import {
  ApplicationResponse,
  ApplicationResponseNextStatusEnum,
  ApplicationResponseStatusEnum,
} from '../../openapi/yenta';
import ZenButton from '../Zen/ZenButton';
import ZenApplicationWaiveFeeModal from './ZenApplicationWaiveFeeModal';

interface ZenApplicationDetailActionBarProps {
  application: ApplicationResponse;
  onRejectClicked(): void;
}
const ZenApplicationDetailActionBar: React.FC<ZenApplicationDetailActionBarProps> = ({
  application,
  onRejectClicked,
}) => {
  const [showWaiveFeeModal, setShowWaiveFeeModal] = useState<boolean>(false);

  const isFeeWaiveable =
    !application.feesPaidAt &&
    !application.feesWaivedAt &&
    application.status !== ApplicationResponseStatusEnum.Rejected;

  const hideRejectButton = [
    ApplicationResponseNextStatusEnum.PendingApproval,
    ApplicationResponseNextStatusEnum.Approved,
    ApplicationResponseNextStatusEnum.Rejected,
  ].includes(application.nextStatus!);

  return (
    <div className='flex flex-row items-center justify-end border-solid rounded-xl border-zen-warning-3 bg-zen-warning-1 mx-6 mt-4'>
      {(isFeeWaiveable || !hideRejectButton) && (
        <div className='space-x-4 px-4 my-2 flex flex-row items-center'>
          {isFeeWaiveable && (
            <ZenButton
              label='Waive Fees'
              onClick={() => setShowWaiveFeeModal(true)}
            />
          )}
          {!hideRejectButton && (
            <ZenButton
              label='Reject'
              variant='danger'
              onClick={onRejectClicked}
            />
          )}
        </div>
      )}
      <ZenApplicationWaiveFeeModal
        id={application.id!}
        isOpen={showWaiveFeeModal}
        onClose={() => setShowWaiveFeeModal(false)}
      />
    </div>
  );
};
export default ZenApplicationDetailActionBar;
