import keys from 'lodash/keys';
import values from 'lodash/values';
import {
  faArrowRight,
  faBuildingUser,
  faHandHoldingDollar,
  faHouseUser,
  faMagnifyingGlassLocation,
  faPlus,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFieldArray } from 'react-hook-form-v7';
import ZenControlledCurrencyInput from '../../components/Zen/Input/ZenControlledCurrencyInput';
import ZenControlledGoogleAutocompleteSearchInput from '../../components/Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledPercentageInput from '../../components/Zen/Input/ZenControlledPercentageInput';
import ZenControlledTextAreaInput from '../../components/Zen/Input/ZenControlledTextAreaInput';
import { LanguageType, ReferralClientRole, Timeline } from '../../types';
import {
  GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
  PERCENT_VALIDATION,
} from '../../utils/Validations';
import ZenControlledMultiSelectInput from '../../components/Zen/Input/ZenControlledMultiSelectInput';
import { capitalizeEnum, isStringEmpty } from '../../utils/StringUtils';
import GeminiButton from '../../components/Gemini/GeminiButton';
import { StepByStepComponent } from '../../components/StepByStep/StepByStepContainer';
import ZenControlledSelectInput from '../../components/Zen/Input/ZenControlledSelectInput';
import { CreateReferralStepName } from './CreateReferral';
import ControlledSingleSelectInput from './ControlledSingleSelectInput';
import Map, { AddressData } from './components/Map';
import { SendReferralStepName } from './SendReferral';
import InputInfoBanner from './components/InputInfoBanner';
import { ReferralStepsFormState } from './ReferralUtils';
import { ApplyToReferralStepName } from './ApplyToReferralSteps';

const ReferralDetailStep: StepByStepComponent<
  ReferralStepsFormState,
  CreateReferralStepName | ApplyToReferralStepName | SendReferralStepName
> = ({ form: { control, watch }, onNext, onClose }) => {
  const [mapData, setMapData] = useState<AddressData[]>([]);

  const { fields, append, remove } = useFieldArray<
    ReferralStepsFormState,
    'referralAddress'
  >({
    control,
    name: 'referralAddress',
    shouldUnregister: false,
  });

  const address = watch('referralAddress');

  const plotCircle = useCallback(() => {
    const data = [] as AddressData[];

    for (let i = 0; i < address.length; i++) {
      if (
        !!address[i].address?.geometry?.location.lat &&
        !isStringEmpty(address[i].distance?.value || '')
      ) {
        data.push({
          name: address[i].address.formatted_address,
          circle: {
            radius: +address[i].distance?.value * 1000,
            options: {
              strokeColor: '#629BF8',
              fillColor: '#629BF8',
            },
          },
          latitude: address[i].address?.geometry?.location.lat,
          longitude: address[i].address?.geometry?.location.lng,
        });
      }
    }

    setMapData(data);
  }, [address]);

  useEffect(() => {
    plotCircle();
  }, [plotCircle]);

  return (
    <>
      <div className='h-fit'>
        <div className='font-inter border border-gray-200 px-3 mb-8 py-2 rounded-lg m-4'>
          <div className='mt-2'>
            <ControlledSingleSelectInput<ReferralStepsFormState, 'clientRole'>
              name='clientRole'
              colsPerRow='grid-cols-4'
              options={[
                {
                  label: capitalizeEnum(ReferralClientRole.BUYER),
                  value: ReferralClientRole.BUYER,
                  icon: (
                    <FontAwesomeIcon size='lg' icon={faHandHoldingDollar} />
                  ),
                },
                {
                  label: capitalizeEnum(ReferralClientRole.SELLER),
                  value: ReferralClientRole.SELLER,
                  icon: <FontAwesomeIcon size='lg' icon={faBuildingUser} />,
                },
                {
                  label: capitalizeEnum(ReferralClientRole.LANDLORD),
                  value: ReferralClientRole.LANDLORD,
                  icon: (
                    <FontAwesomeIcon
                      size='lg'
                      icon={faMagnifyingGlassLocation}
                    />
                  ),
                },
                {
                  label: capitalizeEnum(ReferralClientRole.TENANT),
                  value: ReferralClientRole.TENANT,
                  icon: <FontAwesomeIcon size='lg' icon={faHouseUser} />,
                },
              ]}
              shouldUnregister={false}
              control={control}
              label='Select Client Type'
              rules={{
                required: 'Please select a timeline',
              }}
              isRequired
            />
          </div>
          <div className='mt-6'>
            <Map
              places={mapData}
              options={{
                disableDefaultUI: true,
                disableDoubleClickZoom: true,
                fullscreenControl: false,
              }}
            />
          </div>
          {fields.map((field, index) => {
            return (
              <div className='mt-3 grid grid-cols-6 gap-4' key={field.id}>
                <div className='col-span-4'>
                  <ZenControlledGoogleAutocompleteSearchInput<
                    ReferralStepsFormState,
                    `referralAddress.${number}.address`
                  >
                    control={control}
                    shouldUnregister={false}
                    name={`referralAddress.${index}.address`}
                    label={`Location ${index + 1}`}
                    placeholder='Type Address'
                    rules={{
                      ...GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
                    }}
                    isRequired
                    onChangeSpy={(value) => {
                      const distance = watch('referralAddress')[index]
                        ?.distance;
                      if (distance?.value && value) {
                        plotCircle();
                      }
                    }}
                  />
                </div>
                <div className='col-span-2 flex flex-row items-center justify-end w-full'>
                  <ZenControlledSelectInput<
                    ReferralStepsFormState,
                    `referralAddress.${number}.distance`
                  >
                    name={`referralAddress.${index}.distance`}
                    control={control}
                    shouldUnregister={false}
                    onChangeSpy={(value) => {
                      const address = watch('referralAddress')[index]?.address;
                      if (address?.formatted_address && value) {
                        plotCircle();
                      }
                    }}
                    // use miles from BE or move to types
                    options={[
                      {
                        label: '1 Mile',
                        value: '1',
                      },
                      {
                        label: '2 Miles',
                        value: '2',
                      },
                      {
                        label: '3 Miles',
                        value: '3',
                      },
                      {
                        label: '5 Miles',
                        value: '5',
                      },
                      {
                        label: '10 Miles',
                        value: '10',
                      },
                      {
                        label: '15 Miles',
                        value: '15',
                      },
                      {
                        label: '20 Miles',
                        value: '20',
                      },
                      {
                        label: '25 Miles',
                        value: '25',
                      },
                      {
                        label: '30 Miles',
                        value: '30',
                      },
                    ]}
                    label={`Distance ${index + 1}`}
                    closeMenuOnSelect
                    isRequired
                    rules={{
                      required: 'Please select a distance',
                    }}
                  />
                  {fields.length > 1 && (
                    <div className='pt-6 px-0 -mx-3'>
                      <GeminiButton
                        onClick={() => remove(index)}
                        variant='danger-link'
                        leftIcon={faTrashCan}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <div className='pt-6'>
            <GeminiButton
              label='Add More'
              onClick={async () => {
                append({
                  address: {
                    formatted_address: '',
                    geometry: {
                      location: {
                        lat: undefined,
                        lng: undefined,
                      },
                    },
                    place_id: '',
                  },
                });
              }}
              leftIcon={faPlus}
              variant='primary-link'
            />
          </div>
          <div className='mt-6'>
            <ZenControlledPercentageInput<ReferralStepsFormState, 'referralFee'>
              name='referralFee'
              control={control}
              shouldUnregister={false}
              label='Referral Fee'
              placeholder='Enter Referral Fee'
              isRequired
              startAdornment={
                <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
                  %
                </div>
              }
              rules={{
                required: 'Please enter a percentage',
                ...PERCENT_VALIDATION,
              }}
            />
          </div>
          <div className='mt-6'>
            <ControlledSingleSelectInput<ReferralStepsFormState, 'timeline'>
              name='timeline'
              options={[
                ...values(Timeline).map((value) => ({
                  label: value,
                  value,
                })),
              ]}
              shouldUnregister={false}
              control={control}
              label='Timeline'
              rules={{
                required: 'Please select a timeline',
              }}
              isRequired
            />
          </div>
          <div>
            <label className='block mt-6 font-zen-body font-semibold text-base text-black'>
              Price Range <span className='text-error'>*</span>
            </label>
            <div className='flex flex-row items-center justify-between space-x-4 mt-1'>
              <ZenControlledCurrencyInput<ReferralStepsFormState, 'minimum'>
                name='minimum'
                control={control}
                shouldUnregister={false}
                placeholder='Minimum'
                startAdornment={
                  <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
                    $
                  </div>
                }
                rules={{
                  required: 'Please enter a minimum price',
                }}
                isRequired
              />

              <ZenControlledCurrencyInput<ReferralStepsFormState, 'maximum'>
                name='maximum'
                control={control}
                shouldUnregister={false}
                placeholder='Maximum'
                startAdornment={
                  <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
                    $
                  </div>
                }
                rules={{
                  required: 'Please enter a maximum price',
                }}
                isRequired
              />
            </div>
          </div>
          <div className='mt-6'>
            <ZenControlledMultiSelectInput<ReferralStepsFormState, 'language'>
              control={control}
              name='language'
              label='Language Preference (optional)'
              shouldUnregister={false}
              placeholder='Enter Client Language Preference'
              options={keys(LanguageType).map((language) => ({
                label: capitalizeEnum(language),
                value: language,
              }))}
              isSearchable
              closeMenuOnSelect={false}
            />
          </div>
          <div className='mt-6'>
            <ZenControlledTextAreaInput<ReferralStepsFormState, 'notes'>
              name='notes'
              placeholder='Please provide any additional context or insights about this referral'
              label='Notes (optional)'
              shouldUnregister={false}
              rows={4}
              control={control}
            />
          </div>
          <div className='mt-6'>
            <ZenControlledTextAreaInput<
              ReferralStepsFormState,
              'personalNickname'
            >
              name='personalNickname'
              placeholder='Enter Custom Label'
              label='Personal Nickname (optional)'
              shouldUnregister={false}
              rows={1}
              control={control}
            />
            <InputInfoBanner message='This Personal Nickname will be visible only to you on the referral' />
          </div>
        </div>
      </div>

      <div className='sticky w-full bottom-0 right-0 left-0 bg-primary-light border-t'>
        <div className='flex flex-row items-center justify-between p-4'>
          <GeminiButton
            type='button'
            label='Cancel'
            variant='primary-link'
            onClick={onClose}
          />
          <div className='flex flex-row w-40'>
            <GeminiButton
              type='button'
              label='Next'
              variant='primary'
              isFullWidth
              onClick={onNext}
              rightIcon={faArrowRight}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralDetailStep;
