import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FundsReleaseResponseStatusEnum } from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenStatusPill, { PillVariantType } from '../../Zen/ZenStatusPill';

interface ZenFundsReleaseResponseStatusCellProps {
  type: FundsReleaseResponseStatusEnum;
}

const ZenFundsReleaseResponseStatusCell: React.FC<ZenFundsReleaseResponseStatusCellProps> = ({
  type,
}) => {
  const statusToVariantMap: EnumMap<
    FundsReleaseResponseStatusEnum,
    PillVariantType
  > = {
    COMPLETED: 'success',
    REVERTED: 'danger',
  };

  const statusToIconMap: EnumMap<
    FundsReleaseResponseStatusEnum,
    React.ReactElement
  > = {
    COMPLETED: <FontAwesomeIcon icon={solid('circle-check')} fontSize={18} />,
    REVERTED: <FontAwesomeIcon icon={solid('xmark')} fontSize={18} />,
  };

  return (
    <ZenStatusPill
      text={capitalizeEnum(type)}
      variant={safeEnumMapGet(statusToVariantMap, type, 'default')}
      icon={safeEnumMapGet(
        statusToIconMap,
        type,
        <FontAwesomeIcon icon={solid('triangle-exclamation')} fontSize={18} />,
      )}
    />
  );
};

export default ZenFundsReleaseResponseStatusCell;
