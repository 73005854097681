import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteSessionHistory,
  fetchSessionChat,
  saveShowIntro,
} from '../../../../slices/LeoSlice';
import { RootState } from '../../../../types';
import { displayTimeAndDate } from '../../../../utils/DateUtils';
import DefaultLoader from '../../../DefaultLoader';
import { SessionHistory } from './LeoChatHistory';

interface ChatHistoryCardProps extends SessionHistory {}

const ChatHistoryCard: React.FC<ChatHistoryCardProps> = ({
  id,
  title,
  start_time,
  agent_id,
}) => {
  const dispatch = useDispatch();
  const { session: activeSession, deletingSessionId } = useSelector(
    (state: RootState) => state.leo,
  );
  const isSessionActive = activeSession?.id === id;
  const isSessionDeleting = deletingSessionId === id;
  return (
    <button
      type='button'
      className='appearance-none w-full bg-[#171821] rounded-md flex flex-col px-6 pt-3.5 pb-2.5 mt-3'
      onClick={() => {
        dispatch(saveShowIntro(false));
        dispatch(fetchSessionChat(id, agent_id));
      }}
      disabled={!!isSessionDeleting}
      title='session-history-card'
    >
      <div>
        <p className='text-sm text-left text-zen-light-gray-2 font-inter font-regular word-break'>
          {title}
        </p>
      </div>
      <div className='w-full h-6 flex flex-row items-center mt-4'>
        <div className='flex-grow pr-1'>
          <p className='text-[#FEB95A] font-medium font-inter text-left text-xs leading-4'>
            {displayTimeAndDate(start_time)}
          </p>
        </div>
        {!isSessionActive && (
          <div className='w-6 flex flex-row justify-center items-center'>
            {isSessionDeleting ? (
              <DefaultLoader noPadding iconSize='small' />
            ) : (
              <button
                type='button'
                className='appearance-none h-fit'
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(deleteSessionHistory(id));
                }}
              >
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className='text-[#A0A0A0] text-xs hover:text-[13px] hover:text-white disabled:text-[#A0A0A0]'
                />
              </button>
            )}
          </div>
        )}
      </div>
    </button>
  );
};

export default ChatHistoryCard;
