import { arrayMove } from '@dnd-kit/sortable';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChecklistDefinitionApi,
  ChecklistDefinitionDto,
  CreateTriggerDefRequest,
  ItemDefinitionApi,
  ItemDefinitionDto,
  TriggerDefinitionDto,
  UpdateItemDefinitionRequest,
} from '../openapi/sherlock';
import {
  ChecklistDefinitionDto as YentaChecklistDefinitionDto,
  ChecklistDefinitionDtoTypeEnum,
  OfficeControllerApi,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import {
  AppDispatch,
  AppThunk,
  AsyncResponse,
  ChecklistDefinitionState,
} from '../types';
import {
  getSherlockConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { performAsyncRequest } from './utils/SliceUtil';

export const initialState: ChecklistDefinitionState = {
  checklistDefinitionById: {},
  itemDefinitionById: {},
  checklistDefinitionByOfficeId: {},
  documentUploadLoading: {},
  showCreateTriggerModal: false,
  showEditTriggerModal: false,
  selectedTrigger: {},
};

interface SaveChecklistDefinitionItemPayload {
  checklistId: string;
  checklistItemRes: ItemDefinitionDto;
}

interface SortChecklistItemPayload {
  oldIndex: number;
  newIndex: number;
  checklistId: string;
}

const ChecklistDefinitionSlice = createSlice({
  name: 'checklistDefinition',
  initialState,
  reducers: {
    changeDocumentUploadLoading(
      state,
      action: PayloadAction<{ checklistItemId: string; loading: boolean }>,
    ) {
      state.documentUploadLoading[action.payload.checklistItemId] =
        action.payload.loading;
    },
    saveCheckListDefinition(
      state,
      action: PayloadAction<
        AsyncResponse<ChecklistDefinitionDto, { id: string }>
      >,
    ) {
      state.checklistDefinitionById[action.payload.additionalProps?.id!] =
        action.payload;
    },
    saveOfficeCheckListDefinition(
      state,
      action: PayloadAction<
        AsyncResponse<YentaChecklistDefinitionDto[], { officeId: string }>
      >,
    ) {
      state.checklistDefinitionByOfficeId[
        action.payload.additionalProps?.officeId!
      ] = {
        ...action?.payload,
        data: [
          ...(state.checklistDefinitionByOfficeId[
            action.payload.additionalProps?.officeId!
          ]?.data || []),
          ...(action.payload?.data || []),
        ],
      };
    },
    saveChecklistItem(
      state,
      action: PayloadAction<SaveChecklistDefinitionItemPayload>,
    ) {
      const index = state.checklistDefinitionById[
        action.payload.checklistId
      ]?.data?.items?.findIndex(
        (checklistItem) =>
          checklistItem.id === action.payload.checklistItemRes.id,
      );

      if (typeof index !== 'undefined' && index !== -1) {
        state.checklistDefinitionById[action.payload.checklistId]!.data!.items![
          index
        ] = action.payload.checklistItemRes;
      }
    },
    changeItemPosition(state, action: PayloadAction<SortChecklistItemPayload>) {
      state.checklistDefinitionById[
        action.payload.checklistId
      ]!.data!.items = arrayMove(
        state.checklistDefinitionById[action.payload.checklistId]?.data?.items!,
        action.payload.oldIndex,
        action.payload.newIndex,
      );

      state.checklistDefinitionById[
        action.payload.checklistId
      ]!.data?.items?.forEach((item, index) => {
        item.position = index + 1;
      });
    },
    toggleCreateTriggerModal(state, action: PayloadAction<boolean>) {
      state.showCreateTriggerModal = action.payload;
    },
    toggleEditTriggerModal(state, action: PayloadAction<boolean>) {
      state.showEditTriggerModal = action.payload;
    },
    setEditTrigger(state, action: PayloadAction<TriggerDefinitionDto>) {
      state.selectedTrigger = action.payload;
      state.showEditTriggerModal = true;
    },
    saveItemDefinition(
      state,
      action: PayloadAction<AsyncResponse<ItemDefinitionDto, { id: string }>>,
    ) {
      state.itemDefinitionById[action.payload.additionalProps?.id!] =
        action.payload;
    },
  },
});

export const {
  changeDocumentUploadLoading,
  saveChecklistItem,
  saveCheckListDefinition,
  saveOfficeCheckListDefinition,
  changeItemPosition,
  toggleCreateTriggerModal,
  toggleEditTriggerModal,
  setEditTrigger,
  saveItemDefinition,
} = ChecklistDefinitionSlice.actions;

export default ChecklistDefinitionSlice.reducer;

export const fetchOfficeChecklistDefinition = (
  officeId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  const fetchOfficeChecklists = () =>
    new OfficeControllerApi(
      getYentaConfiguration(),
    ).getChecklistDefinitionInformationsForOffice(
      officeId,
      ChecklistDefinitionDtoTypeEnum.Checklist,
    );

  const fetchOfficeJourneys = () =>
    new OfficeControllerApi(
      getYentaConfiguration(),
    ).getChecklistDefinitionInformationsForOffice(
      officeId,
      ChecklistDefinitionDtoTypeEnum.Journey,
    );

  await Promise.all([
    await performAsyncRequest<
      YentaChecklistDefinitionDto[],
      { officeId: string }
    >(
      dispatch as AppDispatch,
      'fetchOfficeChecklist',
      saveOfficeCheckListDefinition,
      fetchOfficeChecklists,
      {
        errorMetadata: { office: { id: officeId } },
        additionalProps: { officeId },
        skipAuthDatadog: true,
      },
    ),
    await performAsyncRequest(
      dispatch as AppDispatch,
      'fetchOfficeJourney',
      saveOfficeCheckListDefinition,
      fetchOfficeJourneys,
      {
        errorMetadata: { office: { id: officeId } },
        additionalProps: { officeId },
        skipAuthDatadog: true,
      },
    ),
  ]);
};

export const fetchChecklistDefinition = (
  id: string,
  loading: boolean = true,
): AppThunk<Promise<void>> => async (dispatch) => {
  const fetch = () =>
    new ChecklistDefinitionApi(
      getSherlockConfiguration(),
    ).getChecklistDefinitionById(id);

  await performAsyncRequest<ChecklistDefinitionDto, { id: string }>(
    dispatch as AppDispatch,
    'checklist definition',
    saveCheckListDefinition,
    fetch,
    {
      changeLoading: loading,
      additionalProps: { id },
      skipAuthDatadog: true,
      errorMetadata: { checklistDefinition: { id } },
    },
  );
};

export const deleteOfficeChecklistDefinition = (
  id: string,
  checklistId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new OfficeControllerApi(
      getYentaConfiguration(),
    ).removeChecklistDefInfo(id, checklistId);
    dispatch(fetchOfficeChecklistDefinition(id));
    dispatch(showSuccessToast('checklist definition deleted successfully.'));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem removing checklist definition for office.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to remove checklist definition for office', e, {
      officeId: { id },
      checklistId: { checklistId },
    });
  }
};

export const saveOfficeTemplate = (
  officeId: string,
  req: YentaChecklistDefinitionDto,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new OfficeControllerApi(getYentaConfiguration()).addChecklist(
      officeId,
      req,
    );
    dispatch(fetchOfficeChecklistDefinition(officeId));
    dispatch(showSuccessToast('checklist definition added successfully.'));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem adding checklist definition for office.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to add checklist definition for office', e, {
      officeId: { officeId },
      request: { req },
    });
  }
};

export const fetchChecklistDefinitionItemById = (
  checklistId: string,
  checklistItemId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await new ChecklistDefinitionApi(
      getSherlockConfiguration(),
    ).getItemDefinitionById(checklistItemId);
    dispatch(saveChecklistItem({ checklistId, checklistItemRes: data }));
  } catch (e) {
    ErrorService.notify('Unable to fetch checklist definition item', e, {
      checklist: { id: checklistId, itemId: checklistItemId },
    });
  }
};

export const uploadCheckListDefinitionDocument = (
  checklistId: string,
  checklistItemId: string,
  file: File,
  description?: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(changeDocumentUploadLoading({ checklistItemId, loading: true }));
  try {
    await new ChecklistDefinitionApi(getSherlockConfiguration()).uploadDocument(
      checklistItemId,
      file,
      description,
    );
    await dispatch(
      fetchChecklistDefinitionItemById(checklistId, checklistItemId),
    );
    dispatch(showSuccessToast('Document Uploaded.'));
  } catch (e) {
    dispatch(showErrorToast('Unable to upload document'));
    ErrorService.notify(
      'Unable to upload document to checklist definition item',
      e,
      {
        checklistItem: { id: checklistItemId },
      },
    );
  } finally {
    dispatch(
      changeDocumentUploadLoading({
        checklistItemId: checklistItemId,
        loading: false,
      }),
    );
  }
};

export const addCheckListDefinitionItem = (
  checklistId: string,
  req: ItemDefinitionDto[],
): AppThunk<Promise<void | ItemDefinitionDto[]>> => async (dispatch) => {
  try {
    const { data } = await new ChecklistDefinitionApi(
      getSherlockConfiguration(),
    ).createItemDefinitions(checklistId, req);
    dispatch(showSuccessToast('checklist definition item added successfully.'));
    return data;
  } catch (e) {
    dispatch(showErrorToast('Unable to add checklist definition item'));
    ErrorService.notify('Unable to add checklist definition item', e, {
      checklist: { id: checklistId },
    });
    return undefined;
  }
};

export const updateCheckListDefinitionItem = (
  checklistId: string,
  checklistItemId: string,
  req: UpdateItemDefinitionRequest,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await new ChecklistDefinitionApi(
      getSherlockConfiguration(),
    ).updateItemDefinition(checklistItemId, req);
    dispatch(saveChecklistItem({ checklistId, checklistItemRes: data }));
    dispatch(
      showSuccessToast('checklist definition item updated successfully.'),
    );
  } catch (e) {
    dispatch(showErrorToast('Unable to update checklist definition item'));
    ErrorService.notify('Unable to update checklist definition item', e, {
      checklist: { checklistItemId, req },
    });
  }
};

export const deleteCheckListDefinitionItem = (
  checklistId: string,
  checklistItemId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new ChecklistDefinitionApi(
      getSherlockConfiguration(),
    ).deleteItemDefinitionById(checklistItemId);
    dispatch(fetchChecklistDefinition(checklistId));
    dispatch(
      showSuccessToast('checklist definition item deleted successfully.'),
    );
  } catch (e) {
    dispatch(showErrorToast('Unable to update checklist definition item'));
    ErrorService.notify('Unable to delete checklist definition item', e, {
      checklist: { checklistItemId },
    });
  }
};

export const saveTemplate = (
  req: ChecklistDefinitionDto,
): AppThunk<Promise<ChecklistDefinitionDto | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new ChecklistDefinitionApi(
      getSherlockConfiguration(),
    ).createChecklistDefinition(req);
    dispatch(showSuccessToast('template added successfully.'));
    return data;
  } catch (e) {
    dispatch(showErrorToast('Unable to add template'));
    ErrorService.notify('Unable to add template', e, {
      req: { req },
    });
    return undefined;
  }
};

export const updateTemplate = (
  id: string,
  req: ChecklistDefinitionDto,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new ChecklistDefinitionApi(
      getSherlockConfiguration(),
    ).updateChecklistDefinition(id, req);
    dispatch(showSuccessToast('template updated successfully.'));
  } catch (e) {
    dispatch(showErrorToast('Unable to update template'));
    ErrorService.notify('Unable to update template', e, {
      req: { req },
    });
  }
};

export const addLabelForChecklistItem = (
  checklistItemId: string,
  label: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new ChecklistDefinitionApi(getSherlockConfiguration()).createLabel(
      checklistItemId,
      label,
    );
  } catch (e) {
    ErrorService.notify('Unable to add new label for checklist item', e, {
      checklistItem: { id: checklistItemId, labelText: label },
    });
    dispatch(showErrorToast('Unable to add label'));
  }
};

export const removeLabelForChecklistItem = (
  checklistItemId: string,
  label: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new ChecklistDefinitionApi(
      getSherlockConfiguration(),
    ).removeLabelDefinition(checklistItemId, label);
  } catch (e) {
    ErrorService.notify('Unable to remove label for checklist item', e, {
      checklistItem: { id: checklistItemId, labelText: label },
    });
    dispatch(showErrorToast('Unable to remove label'));
  }
};

export const changeChecklistItemPosition = (
  checklistId: string,
  checklistItemId: string,
  oldIndex: number,
  newIndex: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(changeItemPosition({ oldIndex, newIndex, checklistId }));
    await new ChecklistDefinitionApi(
      getSherlockConfiguration(),
    ).changeItemDefinitionPosition(checklistItemId, newIndex);
  } catch (e) {
    dispatch(showErrorToast('Unable to update checklist definition item'));
    ErrorService.notify('Unable to update checklist definition item', e, {
      checklist: { id: checklistId, itemId: checklistItemId },
    });
  }
};

export const deleteChecklistDefinitionItemDocument = (
  documentId: string,
  checklistId: string,
  itemId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new ChecklistDefinitionApi(
      getSherlockConfiguration(),
    ).deleteDocumentDefinition(documentId);
    await dispatch(fetchChecklistDefinitionItemById(checklistId, itemId));
    dispatch(showSuccessToast('Document deleted successfully.'));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem deleting the document',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify(
      'Error deleting checklist definition item document',
      e,
      {
        document: { id: documentId },
      },
    );
  }
};

export const saveTrigger = (
  itemDefId: string,
  templateItemId: string,
  req: CreateTriggerDefRequest,
): AppThunk => async (dispatch) => {
  try {
    await new ItemDefinitionApi(
      getSherlockConfiguration(),
    ).addTriggersToItemDefinition(itemDefId, req);
    dispatch(fetchChecklistDefinition(templateItemId, false));
    dispatch(showSuccessToast('trigger added successfully.'));
  } catch (e) {
    dispatch(showErrorToast('Unable to add trigger'));
    ErrorService.notify('Unable to add trigger', e, {
      req: { req },
      itemDefinition: { id: itemDefId },
    });
  }
};

export const editTrigger = (
  itemDefId: string,
  triggerDefId: string,
  templateItemId: string,
  req: CreateTriggerDefRequest,
): AppThunk => async (dispatch) => {
  try {
    await new ItemDefinitionApi(
      getSherlockConfiguration(),
    ).editTriggerDefinition(itemDefId, triggerDefId, req);
    await dispatch(fetchChecklistDefinition(templateItemId, false));
    dispatch(showSuccessToast('Trigger updated successfully.'));
  } catch (e) {
    dispatch(showErrorToast('Unable to update trigger'));
    ErrorService.notify('Unable to update trigger', e, {
      req: { req },
      itemDefinition: { id: itemDefId },
    });
  }
};

export const removeTriggerFromItem = (
  templateItemId: string,
  itemDefId: string,
  triggerDefId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new ItemDefinitionApi(
      getSherlockConfiguration(),
    ).removeTriggersFromItemDefinition(itemDefId, triggerDefId);
    await dispatch(fetchChecklistDefinition(templateItemId, false));
    dispatch(showSuccessToast('Trigger deleted successfully.'));
  } catch (e) {
    dispatch(showErrorToast('Unable to remove trigger'));
    ErrorService.notify('Unable to remove trigger for checklist item', e, {
      checklistItem: { triggerId: triggerDefId, itemId: itemDefId },
    });
  }
};

export const fetchItemDefinition = (
  id: string,
  loading: boolean = true,
): AppThunk<Promise<void>> => async (dispatch) => {
  const fetch = () =>
    new ChecklistDefinitionApi(
      getSherlockConfiguration(),
    ).getItemDefinitionById(id);

  await performAsyncRequest<ItemDefinitionDto, { id: string }>(
    dispatch as AppDispatch,
    'item definition',
    saveItemDefinition,
    fetch,
    {
      changeLoading: loading,
      additionalProps: { id },
      skipAuthDatadog: true,
      errorMetadata: { itemDefinition: { id } },
    },
  );
};
