import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PostCapCandleStick from '../../../assets/img/wealthManagement/postcap-candle-stick.svg';
import AnalyticsService from '../../../services/AnalyticsService';
import { getOfficialPlan } from '../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum, RootState } from '../../../types';
import { Earning } from '../WealthManagementTypes';
import InvestInReal from '../../../assets/img/wealthManagement/invest-in-real.svg';
import { SppAgreementDetails } from './SppAgreementDetails';
import { HowToJoinSpp } from './HowToJoinSpp';
import { SppContributionSection } from './SppContributionSection';
import ZenLandingPageIntro from './ZenLandingPageIntro';
import { LearnMoreCarousel } from './LearnMoreCarousel';
import { PrimaryGradientText } from './PrimaryGradientText';

export const ZenPostCapSppKnowledgePage = () => {
  const [expandExample, setExpandExample] = useState(0);
  const [scrollToExample, setScrollToExample] = useState(0);

  const Header = () => {
    return (
      <ZenLandingPageIntro
        earningType={Earning.POST_CAP_SPP}
        subtitle='Let Real invest back in you'
        sloganImage={InvestInReal}
        showAddToMyPlan
        buttonProps={{
          earningType: Earning.POST_CAP_SPP,
        }}
        headerBackgroundProps={{
          bgVariant: 'blue',
        }}
      />
    );
  };

  const SPPInformation = () => {
    const handleGotoExample = () => {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.WEALTH_PLAN_LP_POST_CAP_SPP_CLICK_ON_SEE_EXAMPLE,
      );
      setExpandExample(expandExample + 1);
      setScrollToExample(scrollToExample + 1);
    };

    return (
      <SppContributionSection
        type={Earning.POST_CAP_SPP}
        scrollToExample={handleGotoExample}
      />
    );
  };

  const SppParticipation = () => {
    return (
      <div className='flex flex-col w-full items-center'>
        <div className='flex justify-center w-full overflow-hidden'>
          <img
            src={PostCapCandleStick}
            alt='PostCapCandleStick'
            className='w-full opacity-40 -mt-20 lg:-mt-44 px-3 lg:px-20'
          />
        </div>
        <div className='flex justify-center items-center flex-col px-3 lg:px-40'>
          <div className='mt-10 text-4xl lg:text-6xl font-bold text-center mb-5 text-black'>
            <div>How do you join the</div>
            <div>
              <PrimaryGradientText>Stock Purchase Plan?</PrimaryGradientText>
            </div>
          </div>
        </div>
        <HowToJoinSpp />
        <SppAgreementDetails
          expand={expandExample}
          scrollToExample={scrollToExample}
          type={Earning.POST_CAP_SPP}
        />
      </div>
    );
  };

  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (officialPlanAndAwardData === undefined) {
      dispatch(getOfficialPlan());
    }
  }, [dispatch, officialPlanAndAwardData]);

  return (
    <>
      <Header />
      <SPPInformation />
      <SppParticipation />
      <LearnMoreCarousel />
    </>
  );
};
