import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import RevShareInsightsBadges from '../components/RevShareInsights/RevShareInsightsBadges';
import RevShareInsightsPendingPaymentSidebar from '../components/RevShareInsights/sidebar/RevShareInsightsPendingPaymentSidebar';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  fetchNetworkSizeByTier,
  fetchRevShareByTier,
  fetchRevShareHistory,
  fetchRevShareInsightsBadges,
  getPendingRevenueSharePaymentsOverview,
  getPreviousRevenueSharePayments,
} from '../slices/RevenueSlice';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { AppDispatch, RootState } from '../types';
import { getInsigniaConfiguration } from '../utils/OpenapiConfigurationUtils';
import {
  FilterDates,
  RevShareInsightsFilterEnum,
  revShareInsightsNetworksAnalyticsMap,
} from '../utils/RevShareInsightsUtils';
import ResourceContainer from '../components/ResourceContainer';
import RevShareInsightsReferNow from '../components/RevShareInsights/RevShareInsightsReferNow';
import RevShareInsightsPaymentHistory from '../components/RevShareInsights/RevShareInsightsPaymentHistory';
import RevShareInsightsContributionPerTier from '../components/RevShareInsights/RevShareInsightsContributionPerTier';
import RevShareInsightsPaidEarnings from '../components/RevShareInsights/RevShareInsightsPaidEarnings';
import RevShareInsightsMyTierProgress from '../components/RevShareInsights/RevShareInsightsMyTierProgress';
import RevShareInsightsEarningsGraph, {
  EARNING_GRAPH_MONTHS,
} from '../components/RevShareInsights/RevShareInsightsEarningsGraph';
import RevShareInsightsLoader from '../components/RevShareInsights/RevShareInsightsLoader';
import RevShareInsightsNetworksSidebar from '../components/RevShareInsights/sidebar/RevShareInsightsNetworksSidebar';
import RevShareInsightsContributionsByTierSidebar from '../components/RevShareInsights/sidebar/RevShareInsightsContributionsByTierSidebar';
import { BadgeApi, BadgeResponseNameEnum } from '../openapi/insignia';
import RevShareInsightsContributorsByTierSidebar from '../components/RevShareInsights/sidebar/RevShareInsightsContributorsByTierSidebar';
import { EarningType } from '../components/WeathManagement/WealthManagementTypes';
import { KnowledgePageSidebar } from '../components/WeathManagement/EarningLandingPages/KnowledgePageSidebar';
import { setWealthKnowledgeLandingPage } from '../slices/WealthManagementSlice';
import AnalyticsService from '../services/AnalyticsService';
import { fetchZenFrontLineForAgent } from '../slices/NetworkSlice';
import ZenUpline from '../components/upline/ZenUpline';
import { FrontLineAgentResponseStatusEnum } from '../openapi/arrakis';
import ZenNetwork from '../components/network/ZenNetwork';

type Match = {
  id: string;
};

const ZenAgentDetailRevShareInsights: React.FC = () => {
  const { id: agentId } = useParams() as Match;
  const dispatch = useDispatch<AppDispatch>();

  const [showPendingPayments, setShowPendingPayments] = React.useState(false);
  const [contributorTier, setContributorTier] = React.useState<number>();
  const [contributionTier, setContributionTier] = React.useState<number>();
  const [networkTier, setNetworkTier] = useState<number>();
  const [filterDates, setFilterDates] = useState<FilterDates>({});
  const [initialFilterLoading, setInitialFilterLoading] = useState(true);
  const [showUplineAgents, setShowUplineAgents] = useState(false);
  const [showOldNetworks, setShowOldNetworks] = useState(false);

  const {
    agentDetail: { detailResponse },
    revenue: { agentBadgesResponse, generalBadgesResponse },
    network: { zenNetworksById },
  } = useSelector((state: RootState) => state);

  const agentDetailResponse = detailResponse.data;
  const frontLineInfo = zenNetworksById[agentId];

  const fetchDetails = useCallback(
    async (filter: RevShareInsightsFilterEnum, initialFetch = false) => {
      const today = DateTime.now().toISODate();
      const previousDate = DateTime.now()
        .plus({ [filter]: -1 })
        .toISODate();
      const previousDateForHistory = DateTime.now()
        .minus({ month: EARNING_GRAPH_MONTHS })
        .toISODate();

      const requests = [
        dispatch(fetchRevShareByTier(agentId, previousDate, today)),
      ];
      if (isEmpty(frontLineInfo)) {
        requests.push(
          dispatch(fetchZenFrontLineForAgent(agentId)) as Promise<void>,
        );
      }
      if (initialFetch) {
        requests.push(
          dispatch(fetchNetworkSizeByTier(agentId, today)),
          dispatch(
            fetchRevShareHistory(agentId, previousDateForHistory, today),
          ),
          dispatch(fetchRevShareInsightsBadges(agentId)),
          dispatch(
            getPreviousRevenueSharePayments(agentId, 0, 20),
          ) as Promise<void>,
          dispatch(getPendingRevenueSharePaymentsOverview(agentId)),
        );
      }
      await Promise.all(requests);

      setFilterDates({ startDate: previousDate, endDate: today });
      if (initialFetch) {
        setInitialFilterLoading(false);
      }
    },
    [agentId, dispatch, frontLineInfo],
  );

  useEffect(() => {
    if (initialFilterLoading) {
      fetchDetails(RevShareInsightsFilterEnum.month, initialFilterLoading);
    }
  }, [fetchDetails, initialFilterLoading]);

  useEffect(() => {
    if (networkTier) {
      AnalyticsService.instance().logEvent(
        revShareInsightsNetworksAnalyticsMap[networkTier],
      );
    }
  }, [networkTier]);

  const isIncomeScholar = agentBadgesResponse.data?.badges?.find(
    (badge) => badge.badge?.name === BadgeResponseNameEnum.IncomeScholar,
  )?.unlocked;
  const incomeScholarId = generalBadgesResponse?.data?.badges?.find(
    (badge) => badge.name === BadgeResponseNameEnum.IncomeScholar,
  )?.id;

  const handleUnlockingIncomeScholar = async (earning?: EarningType) => {
    const isRevShareIncome = earning === 'REVENUE_SHARE_INCOME';
    if (isRevShareIncome && !isIncomeScholar && incomeScholarId) {
      try {
        await new BadgeApi(getInsigniaConfiguration()).unlockAgentBadge(
          incomeScholarId,
        );
        await dispatch(fetchRevShareInsightsBadges(agentId));
      } catch (e) {
        dispatch(showApiErrorModal(e));
        ErrorService.notify('Unable to unlock agent badge', e, {
          agent: { id: agentId },
        });
        dispatch(
          showErrorToast(
            'Failed to unlock income scholar badge. Please try again in a few moments',
          ),
        );
      }
    }
  };

  return (
    <ResourceContainer
      loading={initialFilterLoading}
      isEmpty={false}
      resourceName='revshare insight'
      LoaderComponent={<RevShareInsightsLoader />}
    >
      <div className='px-4 py-3 bg-regent-100'>
        <RevShareInsightsBadges />
      </div>
      <div className='md:w-full mx-auto p-4 flex flex-col md:flex-row md:items-stretch md:space-x-6 space-y-4 md:space-y-0'>
        <div className='md:w-full flex-grow flex flex-col space-y-4'>
          <div className='flex flex-col sm:flex-row sm:items-stretch sm:space-x-4 space-y-2 sm:space-y-0'>
            <div className='flex-1'>
              <RevShareInsightsPaidEarnings />
            </div>
            <div className='flex-1'>
              <RevShareInsightsReferNow
                onClickLearnMore={() =>
                  dispatch(
                    setWealthKnowledgeLandingPage('REVENUE_SHARE_INCOME'),
                  )
                }
              />
            </div>
          </div>
          <RevShareInsightsEarningsGraph />
          <RevShareInsightsPaymentHistory
            agentId={agentId}
            onClickPendingPayment={setShowPendingPayments}
          />
        </div>
        <div className='md:w-full lg:max-w-md xl:max-w-lg 2xl:max-w-xl space-y-4'>
          <RevShareInsightsMyTierProgress
            agentId={agentId}
            onClickTierAgents={setNetworkTier}
            onClickMyUpline={setShowUplineAgents}
            onClickMyNetwork={setShowOldNetworks}
          />
          <RevShareInsightsContributionPerTier
            agentId={agentId}
            onClickTier={setContributionTier}
            onChangeFilter={fetchDetails}
            onClickTierAgents={setContributorTier}
          />
        </div>
      </div>
      {!!networkTier && (
        <RevShareInsightsNetworksSidebar
          isOpen
          onClose={() => setNetworkTier(undefined)}
          initialTier={networkTier}
          agentId={agentId}
        />
      )}
      {showPendingPayments && (
        <RevShareInsightsPendingPaymentSidebar
          isOpen={showPendingPayments}
          onClose={() => setShowPendingPayments(false)}
          agentId={agentId}
        />
      )}
      {!!contributorTier && (
        <RevShareInsightsContributorsByTierSidebar
          isOpen
          onClose={() => setContributorTier(undefined)}
          agentId={agentId}
          filterDates={filterDates}
          initialTier={contributorTier}
        />
      )}
      {!!contributionTier && (
        <RevShareInsightsContributionsByTierSidebar
          isOpen
          onClose={() => setContributionTier(undefined)}
          agentId={agentId}
          filterDates={filterDates}
          initialTier={contributionTier}
        />
      )}
      {showOldNetworks && (
        <ZenNetwork
          isOpen
          onClose={() => setShowOldNetworks(false)}
          selectAgents={[
            {
              id: agentDetailResponse?.id!,
              firstName: agentDetailResponse?.firstName!,
              lastName: agentDetailResponse?.lastName!,
              phoneNumber: agentDetailResponse?.phoneNumber!,
              emailAddress: agentDetailResponse?.emailAddress!,
              avatar: agentDetailResponse?.avatar!,
              status: (agentDetailResponse?.agentStatus! as unknown) as FrontLineAgentResponseStatusEnum,
              sizeOfNetwork: agentDetailResponse?.sizeOfNetwork!,
            },
          ]}
        />
      )}
      {showUplineAgents && (
        <ZenUpline
          isOpen
          onClose={() => setShowUplineAgents(false)}
          agentId={agentId}
        />
      )}
      <KnowledgePageSidebar onUnmount={handleUnlockingIncomeScholar} />
    </ResourceContainer>
  );
};

export default ZenAgentDetailRevShareInsights;
