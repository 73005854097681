import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { faFileExclamation } from '@fortawesome/pro-thin-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { Earning, EarningType } from '../WealthManagementTypes';
import { AnalyticsEventEnum } from '../../../types';
import AnalyticsService from '../../../services/AnalyticsService';

const EarningEventMap: Partial<Record<EarningType, AnalyticsEventEnum>> = {
  [Earning.PRE_CAP_SPP]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_PRE_CAP_SPP_EXPAND_TERMS_AND_CONDITIONS,
  [Earning.STOCK_AWARD_FOR_CAPPING]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_CAPPING_AWARD_EXPAND_TERMS_AND_CONDITIONS,
  [Earning.POST_CAP_SPP]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_POST_CAP_SPP_EXPAND_TERMS_AND_CONDITIONS,
  [Earning.ELITE_PRODUCTION_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_ELITE_PRODUCTION_AWARD_EXPAND_TERMS_AND_CONDITIONS,
  [Earning.ELITE_CULTURAL_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_ELITE_CULTURAL_AWARD_EXPAND_TERMS_AND_CONDITIONS,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_STOCK_AWARD_ATTRACTING_EXPAND_TERMS_AND_CONDITIONS,
};

interface TermsAndConditionsAccordionProps {
  earningType: EarningType;
}

export const ZenTermsAndConditionsAccordion: React.FC<TermsAndConditionsAccordionProps> = ({
  earningType,
}) => {
  return (
    <Accordion
      className='w-4/5 mb-8 border border-zen-dark-13'
      style={{
        borderRadius: '10px',
      }}
      onChange={() => {
        const event = EarningEventMap[earningType];
        if (event) {
          AnalyticsService.instance().logEvent(event);
        }
      }}
    >
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <div className='flex gap-5 items-center justify-start text-zen-dark-13 font-semibold font-zen-body text-xl'>
          <FontAwesomeIcon
            icon={faFileExclamation}
            className='text-primary-blue'
          />
          Terms & Conditions
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div className='flex gap-2'>
            <div>
              <FontAwesomeIcon
                icon={faCheck}
                size='sm'
                className='text-green-600'
              />
            </div>
            Shares are purchased based on the closing fair market value, on the
            last trading day of the month in which the award is distributed.
          </div>
          <div className='flex gap-2'>
            <div>
              <FontAwesomeIcon
                icon={faCheck}
                size='sm'
                className='text-green-600'
              />
            </div>{' '}
            All shares in this program are Restricted Stock Units (RSUs) and
            have a 3-year vesting period. Agents must remain in good standing
            throughout the vesting period.
          </div>
          <div className='flex gap-2'>
            <div>
              <FontAwesomeIcon
                icon={faCheck}
                size='sm'
                className='text-green-600'
              />
            </div>
            Agents can opt in and out of the program at any time, but they can
            only opt in once per anniversary year.
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
