import { Priority, useKBar } from 'kbar';
import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransactionControllerApi } from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { saveContextSearchResults } from '../../../slices/KBarSlice';
import { RootState } from '../../../types';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import useKbarRedirect from '../useKbarRedirect';

export default function useSearchTransactionsListingsActions() {
  const dispatch = useDispatch();
  const { redirectTo } = useKbarRedirect();
  const {
    auth: { userDetail },
    kBar: { contextSearchResults },
  } = useSelector((state: RootState) => state);
  const { search } = useKBar((state) => ({
    search: state.searchQuery,
  }));

  const searchTransactionsListingsActions = [
    ...contextSearchResults.transactions.map((csr) => ({
      id: `${csr.id}`,
      name: `${csr.address?.oneLine}`,
      keywords: 'open transactions',
      section: {
        name: 'Open Transactions',
        priority: Priority.LOW,
      },
      perform: () => {
        redirectTo(`/transactions/${csr.id}/detail`);
      },
    })),
    ...contextSearchResults.listings.map((csr) => ({
      id: `${csr.id}`,
      name: `${csr.address?.oneLine}`,
      keywords: 'open listings',
      section: {
        name: 'Open Listings',
        priority: Priority.LOW,
      },
      perform: () => {
        redirectTo(`/listings/${csr.id}/detail`);
      },
    })),
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (query: string) => {
      try {
        const { data: transactionsData } = await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).getTransactionsByStateGroupPaginated(
          userDetail?.id!,
          'OPEN',
          0,
          5,
          query,
        );
        const { data: listingsData } = await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).getListingTransactionsByStateGroupPaginated(
          userDetail?.id!,
          'OPEN',
          0,
          5,
          query,
        );
        dispatch(
          saveContextSearchResults({
            transactions: transactionsData?.transactions || [],
            listings: listingsData?.transactions || [],
          }),
        );
      } catch (error) {
        ErrorService.notify(
          '[KBar]: Failed to do context search for transactions/listings',
          error,
          {
            metadata: { search: query },
          },
        );
        dispatch(saveContextSearchResults({ transactions: [], listings: [] }));
      }
    }, 1000),
    [],
  );

  useEffect(() => {
    if (!search) {
      dispatch(saveContextSearchResults({ transactions: [], listings: [] }));
    } else {
      debouncedSearch(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, search]);

  return searchTransactionsListingsActions;
}
