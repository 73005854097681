import React from 'react';
import { useSelector } from 'react-redux';
import IcTitleAgentSvg from '../../assets/img/ic-title-agent.svg';
import { getSupportEmail } from '../../utils/SupportUtils';
import { RootState } from '../../types';

interface TitleProgressEmptyProps {}

const TitleProgressEmpty: React.FC<TitleProgressEmptyProps> = () => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  return (
    <div className='flex flex-col items-center justify-center font-primary-medium text-lg py-10'>
      <img src={IcTitleAgentSvg} alt='title-agent' />
      <div className='mt-5 w-full max-w-sm text-center text-dark leading-tight'>
        <p>Your order has been placed.</p>
        <p className='mt-1'>
          This page will display your order status, title contact information
          and wire instructions.
        </p>
        <p className='mt-5'>
          Title agent will be assigned within the next 24h or sooner.
        </p>
        <p className='mt-5'>
          Please{' '}
          <a
            href={`mailto:${getSupportEmail(userDetail?.accountCountry)}`}
            className='text-primary underline'
          >
            reach out
          </a>{' '}
          if any questions.
        </p>
      </div>
    </div>
  );
};

export default TitleProgressEmpty;
