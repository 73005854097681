import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AdministrativeAreaRequestCountryEnum,
  CreateWillBeneficiaryRequest,
  UpdateWillBeneficiaryRequest,
  WillBeneficiaryAndTypeResponseTypeEnum,
  WillBeneficiaryResponse,
  WillBeneficiaryTrustRequest,
  WillBeneficiaryTrustResponse,
} from '../../../../openapi/yenta';
import {
  createAgentIndividualWillBeneficiary,
  createAgentTrustWillBeneficiary,
  updateAgentIndividualWillBeneficiary,
  updateAgentTrustWillBeneficiary,
} from '../../../../slices/AgentSlice';
import { AnalyticsEventEnum, AppDispatch, RootState } from '../../../../types';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenButton from '../../ZenButton';
import AnalyticsService from '../../../../services/AnalyticsService';
import { isBeneficiaryFormValid } from '../../../../utils/RevenueShareUtils';
import AddBeneficiaryV2 from './AddBeneficiaryV2';
import {
  CreateBeneficiaryV2FormData,
  CreateBeneficiaryV2StepName,
} from './CreateBeneficiaryScreenV2';
import { UpdateBeneficiaryV2StepName } from './UpdateBeneficiaryScreenV2';

const CreateBeneficiaryInformationStepV2: StepByStepComponent<
  CreateBeneficiaryV2FormData,
  CreateBeneficiaryV2StepName | UpdateBeneficiaryV2StepName
> = ({ form: { control, watch, trigger, setValue }, onPrevious, onNext }) => {
  const dispatch: AppDispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {
    auth: { userDetail: agentDetail },
    agentDetail: {
      willBeneficiaryAndTypeResponse: {
        data: beneficiary,
        loading: loadingBeneficiary,
      },
    },
  } = useSelector((state: RootState) => state);

  const formData = watch();

  const isSubmitDisabled =
    isSubmitting || loadingBeneficiary || !isBeneficiaryFormValid(formData);
  const currentBeneficiaryTypeNone =
    !beneficiary ||
    beneficiary?.type === WillBeneficiaryAndTypeResponseTypeEnum.None;
  const selectedBeneficiaryTypeTrust =
    formData.beneficiaryType === WillBeneficiaryAndTypeResponseTypeEnum.Trust;

  const onSubmit = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    setIsSubmitting(true);

    const trustReq: WillBeneficiaryTrustRequest = {
      text: formData.trustInformation,
    };
    let individualReq:
      | CreateWillBeneficiaryRequest
      | UpdateWillBeneficiaryRequest = {
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      birthDate: formData.birthDate,
      address: formData.address,
      phoneNumber: formData.phoneNumber,
    };
    let data:
      | WillBeneficiaryResponse
      | WillBeneficiaryTrustResponse
      | undefined;
    if (
      currentBeneficiaryTypeNone ||
      formData?.beneficiaryType !== beneficiary?.type
    ) {
      if (selectedBeneficiaryTypeTrust) {
        data = await dispatch(
          createAgentTrustWillBeneficiary(agentDetail?.id!, trustReq),
        );
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.WILLABLE_REVSHARE_TRUST_TYPE_SELECTED,
        );
      } else {
        data = await dispatch(
          createAgentIndividualWillBeneficiary(agentDetail?.id!, individualReq),
        );
      }
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.WILLABLE_REVSHARE_BENEFICIARY_ADDED,
      );
    } else {
      if (selectedBeneficiaryTypeTrust) {
        data = await dispatch(
          updateAgentTrustWillBeneficiary(agentDetail?.id!, trustReq),
        );
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.WILLABLE_REVSHARE_TRUST_TYPE_SELECTED,
        );
      } else {
        data = await dispatch(
          updateAgentIndividualWillBeneficiary(agentDetail?.id!, individualReq),
        );
      }
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.WILLABLE_REVSHARE_BENEFICIARY_UPDATED,
      );
    }
    setIsSubmitting(false);

    if (data?.agentId) {
      onNext();
    }
  };

  return (
    <div className='w-full relative shadow-zen-web bg-white rounded-xl'>
      <div className='p-4 md:p-8 mb-28 max-h-[550px] scrollbar overflow-y-auto'>
        <AddBeneficiaryV2
          control={control}
          country={
            (formData.address
              .country as unknown) as AdministrativeAreaRequestCountryEnum
          }
          beneficiary={beneficiary}
          setValue={setValue}
          beneficiaryType={formData.beneficiaryType}
        />
      </div>
      <div className='absolute bottom-0 right-0 left-0 rounded-b-xl bg-white z-1 py-8'>
        <div className='justify-center flex md:flex-row flex-col-reverse items-center px-10 md:px-32'>
          {currentBeneficiaryTypeNone && (
            <div className='md:w-1/2 w-full md:mr-2 md:mt-0 mt-3'>
              <ZenButton
                label='Go Back'
                onClick={onPrevious}
                type='button'
                variant='primary-outline'
                isFullWidth
              />
            </div>
          )}
          <div className='md:w-1/2 w-full md:ml-2'>
            <ZenButton
              label={`${currentBeneficiaryTypeNone ? 'Designate' : 'Update'}`}
              type='button'
              onClick={onSubmit}
              variant='primary'
              isDisabled={isSubmitDisabled}
              isSubmitting={isSubmitting}
              isFullWidth
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBeneficiaryInformationStepV2;
