import { SelectProps as MSelectProps } from '@mantine/core';
import { FieldValues, FieldPath, useController } from 'react-hook-form';
import { Select as BaseSelectInput } from '../Select';
import { HookFormControllerProps } from './types';

type HookFormSelectInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = HookFormControllerProps<TFieldValues, TName> & MSelectProps;

export const HookFormSelectInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  onChange,
  ...props
}: HookFormSelectInputProps<TFieldValues, TName>) => {
  const {
    formState: { defaultValues },
    field,
    fieldState,
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <BaseSelectInput
      {...props}
      defaultValue={defaultValues?.[props.name]}
      onChange={(val: string) => {
        field.onChange(val);
        onChange?.(val);
      }}
      value={field.value}
      onBlur={field.onBlur}
      error={fieldState.error?.message}
    />
  );
};
