import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Hover from '../../Hover';
import ZenCustomSwitch from '../Input/ZenCustomSwitch';
import DefaultLoader from '../../DefaultLoader';

interface ZenIconToggleRowProps {
  value: boolean;
  onChange(value: boolean): void;
  title?: string;
  tooltip?: string;
  loading?: boolean;
  leftIcon?: string | React.ReactElement;
  rightIcon?: string | React.ReactElement;
  icon?: React.ReactElement;
  isCollapsible?: boolean;
  readOnly?: boolean;
}

const ZenIconToggleRow: React.FC<ZenIconToggleRowProps> = ({
  value,
  onChange,
  title,
  tooltip,
  loading,
  leftIcon,
  rightIcon,
  icon,
  isCollapsible = true,
  readOnly,
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  if (isCollapsible) {
    return (
      <div className='flex flex-row items-center justify-center space-x-1.5 md:space-x-2 px-1.5 md:px-3'>
        {icon && (
          <Hover
            hoverComponent={
              <div className='font-zen-body font-medium text-sm text-grey-600 px-2'>
                {title}
              </div>
            }
            config={{ trigger: 'hover', placement: 'top' }}
          >
            <div className='w-[22px] h-[22px] flex justify-center items-center'>
              {icon}
            </div>
          </Hover>
        )}
        {loading ? (
          <div className='px-2.5'>
            <DefaultLoader noPadding iconSize='default' />
          </div>
        ) : (
          <ZenCustomSwitch
            toggle={value}
            onClick={() => {
              if (!loading && !readOnly) {
                onChange(!value);
              }
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div className='flex justify-between items-center'>
      <div className='flex flex-row py-1 items-center font-zen-body font-normal text-base space-x-3 px-2'>
        <ZenCustomSwitch
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          toggle={value}
          onClick={() => {
            if (!loading && !readOnly) {
              onChange(!value);
            }
          }}
        />
        {title && !icon && <p>{title}</p>}
        {title && icon && (
          <div className='flex flex-row items-start space-x-2'>
            <div className='w-[22px] h-[22px] flex justify-center items-center'>
              {icon}
            </div>
            <p>{title}</p>
          </div>
        )}
        {tooltip && (
          <div>
            <div ref={setTriggerRef}>
              <FontAwesomeIcon icon={faCircleInfo} size='lg' />
            </div>
            {visible && (
              <div
                ref={setTooltipRef}
                {...getTooltipProps({ className: 'tooltip' })}
              >
                <div {...getArrowProps({ className: 't-arrow' })} />
                {tooltip}
              </div>
            )}
          </div>
        )}
      </div>
      {loading && (
        <div className='pr-3'>
          <DefaultLoader noPadding iconSize='default' />
        </div>
      )}
    </div>
  );
};

export default ZenIconToggleRow;
