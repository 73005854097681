import React, { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import {
  FundsReleaseResponse,
  MoneyValue,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { displayAmount } from '../../../utils/CurrencyUtils';
import NullableTextCell from '../../table/Cells/NullableTextCell';
import ZenFundsReleaseBankAccountTypeCell from '../../table/Cells/ZenFundsReleaseBankAccountTypeCell';
import ZenFundsReleaseResponseTypeCell from '../../table/Cells/ZenFundsReleaseResponseTypeCell';
import ZenFixedDataResourceIndexContainer from '../../Zen/Containers/ZenFixedDataResourceIndexContainer';
import ZenFundsReleaseResponseStatusCell from '../../table/Cells/ZenFundsReleaseStatusCell';

interface ZenReleaseDepositsTableProps {
  transaction: TransactionResponse;
}

const ZenReleaseDepositsTable: React.FC<ZenReleaseDepositsTableProps> = ({
  transaction,
}) => {
  const columns: Array<Column<FundsReleaseResponse>> = useMemo(
    () => [
      {
        Header: `Type`,
        accessor: 'type',
        Cell: ({ value }) => <ZenFundsReleaseResponseTypeCell type={value!} />,
      },
      {
        Header: `Status`,
        accessor: 'status',
        Cell: ({ value }) => (
          <ZenFundsReleaseResponseStatusCell type={value!} />
        ),
      },
      {
        Header: `Recipient First Name`,
        accessor: 'recipientFirstname',
        Cell: ({ value }) => <p>{value}</p>,
      },
      {
        Header: `Recipient Last Name`,
        accessor: 'recipientLastname',
        Cell: ({ value }) => <p>{value}</p>,
      },
      {
        Header: `Recipient Company`,
        accessor: 'recipientCompany',
        Cell: ({ value }) => <p>{value}</p>,
      },
      {
        Header: `From Bank Account Type`,
        accessor: 'fromBankAccountType',
        Cell: ({ value }) => (
          <ZenFundsReleaseBankAccountTypeCell type={value!} />
        ),
      },
      {
        Header: `From Bank Account Name`,
        accessor: 'fromBankAccountName',
        Cell: ({ value }) => <NullableTextCell text={value} />,
      },
      {
        Header: `Amount (${transaction?.currency})`,
        accessor: (d) => d['amount']?.amount,
        Cell: ({
          row: { original },
        }: CellProps<FundsReleaseResponse, MoneyValue>) =>
          displayAmount(original.amount, { hideCurrency: true }),
      },
    ],
    [transaction],
  );

  return (
    <div>
      <p className='font-primary-regular font-bold text-xl text-black py-3'>
        Funds Releases
      </p>
      <div>
        <ZenFixedDataResourceIndexContainer<FundsReleaseResponse>
          key={transaction.fundsReleases?.length}
          columns={columns}
          data={transaction.fundsReleases}
          resourceName='funds release'
          hidePagination
          hideFilters
        />
      </div>
    </div>
  );
};

export default ZenReleaseDepositsTable;
