import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { values } from 'lodash';
import { useDispatch } from 'react-redux';
import { StepByStepComponent } from '../components/StepByStep/StepByStepContainer';
import HasTransactionClosedModalHeader from '../components/transactions/HasTransactionClosedModalHeader';
import ZenControlledDatePickerInput from '../components/Zen/Input/ZenControlledDatePickerInput';
import ZenControlledRadioInput from '../components/Zen/Input/ZenControlledRadioInput';
import ZenControlledTextAreaInput from '../components/Zen/Input/ZenControlledTextAreaInput';
import ZenButton from '../components/Zen/ZenButton';
import ZenLink from '../components/Zen/ZenLink';
import { TransactionResponseAgentReportedMoneyStatusEnum } from '../openapi/arrakis';
import { toggleClosedModal } from '../slices/TransactionSlice';
import { capitalizeEnum } from '../utils/StringUtils';
import {
  TransactionClosedFormData,
  TransactionClosedStepName,
} from './HasTransactionClosedModal';

const HasTransactionClosedYesStep: StepByStepComponent<
  TransactionClosedFormData,
  TransactionClosedStepName
> = ({
  form: {
    control,
    watch,
    formState: { isSubmitting },
  },
  onNext,
  navigateTo,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const [agentReportedTransactionClosingDate, moneyStatus] = watch([
    'agentReportedTransactionClosingDate',
    'moneyStatus',
  ]);

  const isSubmitAbleOption =
    moneyStatus ===
      TransactionResponseAgentReportedMoneyStatusEnum.HeldInTrust ||
    moneyStatus === TransactionResponseAgentReportedMoneyStatusEnum.Unknown;

  const handleStepSubmit = () => {
    if (isSubmitAbleOption) {
      onSubmit();
    } else {
      onNext();
    }
  };

  return (
    <div className='w-full rounded-lg z-10 md:w-full max-w-[745px] shadow-sm shadow-gray-300 bg-white relative'>
      <HasTransactionClosedModalHeader
        title='Transaction Update'
        onClose={() => dispatch(toggleClosedModal(false))}
      />
      <div className='w-full px-6 md:px-10 lg:px-[129px] py-6 font-zen-body text-black'>
        <div className='w-full flex flex-col items-center justify-center'>
          <div className='w-full'>
            <div className='w-full mt-4'>
              <ZenControlledDatePickerInput<
                TransactionClosedFormData,
                'agentReportedTransactionClosingDate'
              >
                control={control}
                label='When did this transaction close?'
                name='agentReportedTransactionClosingDate'
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className='text-primary-blue ml-0.5'
                    size='lg'
                  />
                }
                placeholder='MM/DD/YYYY'
                shouldUnregister={false}
                isRequired
                rules={{ required: 'Required' }}
              />
            </div>
            <div className='w-full mt-4'>
              <ZenControlledRadioInput<TransactionClosedFormData, 'moneyStatus'>
                control={control}
                label='Was the money sent to Real?'
                name='moneyStatus'
                options={[
                  ...values(
                    TransactionResponseAgentReportedMoneyStatusEnum,
                  )?.map((status) => ({
                    value: status,
                    label:
                      status ===
                      TransactionResponseAgentReportedMoneyStatusEnum.Unknown
                        ? "I Don't Know"
                        : capitalizeEnum(status),
                  })),
                ]}
                inlineOptions
                shouldUnregister={false}
                isRequired
                rules={{ required: 'Required' }}
              />
            </div>

            {moneyStatus ===
              TransactionResponseAgentReportedMoneyStatusEnum.Unknown && (
              <div className='w-full mt-6 -mb-16'>
                <ZenControlledTextAreaInput<
                  TransactionClosedFormData,
                  'missingMoneyNote'
                >
                  name='missingMoneyNote'
                  shouldUnregister={false}
                  label='Other Notes? (optional)'
                  placeholder='....'
                  control={control}
                  rows={4}
                  className='border px-3 py-2 text-sm text-gray-800 rounded-2xl'
                  noResize
                />
              </div>
            )}
            <div className='mt-32 text-sm flex row items-center space-x-6'>
              <div className='whitespace-nowrap'>
                <ZenLink
                  label='Go Back'
                  size='small'
                  onClick={() =>
                    navigateTo(TransactionClosedStepName.HAS_TRANSACTION_CLOSED)
                  }
                />
              </div>
              <ZenButton
                label={isSubmitAbleOption ? 'Complete' : 'Next'}
                variant='primary'
                onClick={handleStepSubmit}
                isSubmitting={isSubmitting}
                isFullWidth
                isDisabled={
                  !agentReportedTransactionClosingDate || !moneyStatus
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HasTransactionClosedYesStep;
