import { values } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  FlexTeamConfigDtoLeaderOverridablePropertiesEnum,
  FlexTeamDto,
  FlexTeamDtoPaymentDetailsVisibilityEnum,
  UpdateFlexTeam,
  UpdateFlexTeamCdaApproverEnum,
  UpdateFlexTeamPaymentDetailsVisibilityEnum,
  UpdateFlexTeamPermittedTransactionEditorsEnum,
} from '../../../../openapi/yenta';
import {
  getFlexTeamDetailOverview,
  updateFlexTeam,
} from '../../../../slices/TeamSlice';
import { AppDispatch, ISelectOption } from '../../../../types';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import ZenControlledSelectInput from '../../Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../../Input/ZenControlledToggleInput';
import ZenSidebarModalActionFooterV2 from '../../Modal/ZenSidebarModalActionFooterV2';

interface FormData {
  name: string;
  eligibleInstantPayments: boolean;
  paidAtClose: boolean;
  commissionDocumentApprover?: ISelectOption;
  paymentDetailsVisibility: boolean;
  permittedTransactionEditors: ISelectOption;
}

interface ZenEditProTeamDetailTabProps {
  team: FlexTeamDto;
  onClose(): void;
  readOnly?: boolean;
}

const ZenEditProTeamDetailTab: React.FC<ZenEditProTeamDetailTabProps> = ({
  team,
  onClose,
  readOnly,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const defaultCDAApprover = team?.cdaApprover || team.config?.cdaApprover;
  const defaultPermittedTransactionEditors =
    team?.permittedTransactionEditors ||
    team.config?.permittedTransactionEditors;
  const label = defaultCDAApprover
    ? capitalizeEnum(defaultCDAApprover)
    : undefined;
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      name: team?.name,
      commissionDocumentApprover: {
        label,
        value: defaultCDAApprover,
      },
      paymentDetailsVisibility:
        team.paymentDetailsVisibility ===
        FlexTeamDtoPaymentDetailsVisibilityEnum.Full
          ? true
          : false,
      permittedTransactionEditors: defaultPermittedTransactionEditors
        ? {
            value: defaultPermittedTransactionEditors,
            label: capitalizeEnum(defaultPermittedTransactionEditors ?? ''),
          }
        : undefined,
    },
  });
  const showPaymentVisibilityToggle = team.config?.leaderOverridableProperties?.includes(
    FlexTeamConfigDtoLeaderOverridablePropertiesEnum.PaymentDetailsVisibility,
  );

  const onSubmit = async (values: FormData) => {
    setIsSubmitting(true);
    //@ts-ignore
    let formData: UpdateFlexTeam = {
      permittedTransactionEditors: (team?.config
        ?.permittedTransactionEditors as unknown) as UpdateFlexTeamPermittedTransactionEditorsEnum,
      name: values.name,
      ...(showPaymentVisibilityToggle && {
        paymentDetailsVisibility: values.paymentDetailsVisibility
          ? UpdateFlexTeamPaymentDetailsVisibilityEnum.Full
          : UpdateFlexTeamPaymentDetailsVisibilityEnum.None,
      }),
    };

    if (
      team.config?.leaderOverridableProperties?.includes(
        FlexTeamConfigDtoLeaderOverridablePropertiesEnum.CdaApprovalRoles,
      )
    ) {
      formData = {
        ...formData,
        //@ts-ignore
        cdaApprover: values.commissionDocumentApprover
          ?.value! as UpdateFlexTeamCdaApproverEnum,
      };
    }
    if (
      team.config?.leaderOverridableProperties?.includes(
        FlexTeamConfigDtoLeaderOverridablePropertiesEnum.PermittedTransactionEditorRoles,
      )
    ) {
      formData = {
        ...formData,
        //@ts-ignore
        permittedTransactionEditors: (values.permittedTransactionEditors
          .value as unknown) as UpdateFlexTeamPermittedTransactionEditorsEnum,
      };
    }

    await dispatch(updateFlexTeam(team.id!, formData));
    onClose();
    dispatch(getFlexTeamDetailOverview(team.id!));
    setIsSubmitting(false);
  };

  return (
    <form
      className='flex flex-col justify-between max-h-[95%] overflow-y-auto'
      onSubmit={handleSubmit(onSubmit)}
      title='edit-team-form'
    >
      <div className='p-5 max-h-full pb-40'>
        <div className='mt-3'>
          <ZenControlledTextInput<FormData, 'name'>
            control={control}
            name='name'
            placeholder='Team Name'
            shouldUnregister={false}
            label='Name'
            isRequired
            rules={{
              required: 'Please enter a team name',
            }}
            readOnly={readOnly}
          />
        </div>
        {team.config?.leaderOverridableProperties?.includes(
          FlexTeamConfigDtoLeaderOverridablePropertiesEnum.CdaApprovalRoles,
        ) && (
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, 'commissionDocumentApprover'>
              name='commissionDocumentApprover'
              placeholder='Commission Document Approver'
              control={control}
              shouldUnregister={false}
              isRequired
              rules={{
                required: 'Please select a commission document approver',
              }}
              options={[
                ...values(UpdateFlexTeamCdaApproverEnum).map((role) => ({
                  value: role,
                  label: capitalizeEnum(role),
                })),
              ]}
              readOnly={readOnly}
              label='Commission Document Approver'
            />
          </div>
        )}
        {team.config?.leaderOverridableProperties?.includes(
          FlexTeamConfigDtoLeaderOverridablePropertiesEnum.PermittedTransactionEditorRoles,
        ) && (
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, 'permittedTransactionEditors'>
              name='permittedTransactionEditors'
              placeholder='Permitted Transaction Editors'
              control={control}
              shouldUnregister={false}
              isRequired
              rules={{
                required: 'Please select a transaction editor',
              }}
              options={[
                ...values(UpdateFlexTeamPermittedTransactionEditorsEnum).map(
                  (role) => ({
                    value: role,
                    label: capitalizeEnum(role),
                  }),
                ),
              ]}
              readOnly={readOnly}
              label='Transaction Editors'
            />
          </div>
        )}

        {showPaymentVisibilityToggle && (
          <div className='mt-8'>
            <div className='border border-zen-dark-4 rounded-lg'>
              <div className='p-3'>
                <ZenControlledToggleInput<FormData, 'paymentDetailsVisibility'>
                  name='paymentDetailsVisibility'
                  control={control}
                  shouldUnregister={false}
                  readOnly={readOnly}
                  label='Allow Teammate to See Others Payment Details'
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <ZenSidebarModalActionFooterV2
        onClose={onClose}
        buttonType='submit'
        isDisabled={isSubmitting || readOnly}
        isSubmitting={isSubmitting}
        submitButtonText='Update'
      />
    </form>
  );
};

export default ZenEditProTeamDetailTab;
