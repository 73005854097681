import { UseFormSetValue } from 'react-hook-form-v7';
import { LeaderDto } from '../../routes/ZenTeamsProDetailsRoute';
import {
  AgentFlexTeamMembershipResponse,
  FeeSplitFeeTypeEnum,
  FlexTeammateDto,
  FlexTeammateDtoRolesEnum,
  FlexTeamDto,
  FeeSplit,
} from '../../openapi/yenta';
import { ISelectOption } from '../../types';
import {
  ProTeamMember,
  TeamMemberDto,
} from '../../components/Zen/Teams/ProTeams/ZenEditProTeamMember';
import { getRemainingPercentage } from '../TeamHelper';
import { getParticipantName } from '../ParticipantHelper';
import { ProTeammateFeeSplitsResponse } from '../../openapi/arrakis';

export interface LeaderDetails {
  teamLeader: ISelectOption;
  leaderSplit: number;
  realSplit: number;
  beopFee: number;
  realFee: number;
  transactionFee: number;
  originalLeaderSplit: number;
  originalRealSplit: number;
  originalBeopFee: number;
  originalRealFee: number;
  originalTransactionFee: number;
}

export const getMemberSplit = (
  teammate: FlexTeammateDto | undefined,
): number => {
  if (!teammate) {
    return 0;
  }

  const totalLeaderSplitAmount =
    teammate.computedLeaderSplits!.reduce(
      (prev, curr) => prev + curr.leaderSplit,
      0,
    ) || 0;

  return 100 - totalLeaderSplitAmount;
};

export const calculateDefaultLeaderDetailsForTeammate = (
  leader: LeaderDto,
  teamMate: TeamMemberDto,
): LeaderDetails => {
  const originalLeaderSplit = leader?.leaderDetails?.leaderSplit;
  const originalRealSplit = getSplitPercentage(
    FeeSplitFeeTypeEnum.RealSplit,
    leader.leaderDetails?.feeSplits,
  );
  const originalBeopFee = getSplitPercentage(
    FeeSplitFeeTypeEnum.BeopFee,
    leader.leaderDetails?.feeSplits,
  );
  const originalRealFee = getSplitPercentage(
    FeeSplitFeeTypeEnum.BrokerageFee,
    leader.leaderDetails?.feeSplits,
  );
  const originalTransactionFee = getSplitPercentage(
    FeeSplitFeeTypeEnum.TransactionFee,
    leader.leaderDetails?.feeSplits,
  );

  const flattenFeeSplits = teamMate?.teammateDetails?.feeSplits?.find(
    (split) => split?.leaderId === leader.leaderDetails?.id,
  )!;

  const modifiedLeaderSplit =
    teamMate?.teammateDetails?.leaderSplits?.find(
      (lead) => lead?.leaderId === leader.leaderDetails?.id,
    )?.leaderSplit ?? originalLeaderSplit;

  const modifiedRealSplit =
    getSplitPercentage(
      FeeSplitFeeTypeEnum.RealSplit,
      flattenFeeSplits?.feeSplits,
    ) ?? originalRealSplit;
  const modifiedBeopFee =
    getSplitPercentage(
      FeeSplitFeeTypeEnum.BeopFee,
      flattenFeeSplits?.feeSplits,
    ) ?? originalBeopFee;
  const modifiedRealFee =
    getSplitPercentage(
      FeeSplitFeeTypeEnum.BrokerageFee,
      flattenFeeSplits?.feeSplits,
    ) ?? originalRealFee;
  const modifiedTransactionFee =
    getSplitPercentage(
      FeeSplitFeeTypeEnum.TransactionFee,
      flattenFeeSplits?.feeSplits,
    ) ?? originalTransactionFee;

  return {
    teamLeader: {
      value: leader.leaderDetails?.id!,
      label: getParticipantName(leader?.agent),
    },
    leaderSplit:
      originalLeaderSplit !== modifiedLeaderSplit
        ? modifiedLeaderSplit!
        : originalLeaderSplit!,
    realSplit:
      originalRealSplit !== modifiedRealSplit
        ? modifiedRealSplit!
        : originalRealSplit!,
    beopFee:
      originalBeopFee !== modifiedBeopFee ? modifiedBeopFee! : originalBeopFee!,
    realFee:
      originalRealFee !== modifiedRealFee ? modifiedRealFee! : originalRealFee!,
    transactionFee:
      originalTransactionFee !== modifiedTransactionFee
        ? modifiedTransactionFee!
        : originalTransactionFee!,

    originalLeaderSplit: originalLeaderSplit!,
    originalRealSplit: originalRealSplit!,
    originalBeopFee: originalBeopFee!,
    originalRealFee: originalRealFee!,
    originalTransactionFee: originalTransactionFee!,
  };
};

export const getTeamCapDetails = (
  team: FlexTeamDto,
  teamMate: TeamMemberDto,
) => {
  const originalCapAmount = team?.teamCap?.capAmount;
  const originalPostCapFee = team?.teamCap?.postCapFee;
  const originalPreCapFee = team?.teamCap?.preCapFee;

  const modifiedCapAmount =
    teamMate?.teammateDetails?.teamCap?.capAmount ?? originalCapAmount;
  const modifiedPostCapFee =
    teamMate?.teammateDetails?.teamCap?.postCapFee ?? originalPostCapFee;
  const modifiedPreCapFee =
    teamMate?.teammateDetails?.teamCap?.preCapFee ?? originalPreCapFee;

  return {
    capAmount:
      originalCapAmount !== modifiedCapAmount
        ? modifiedCapAmount!
        : originalCapAmount!,
    postCapFee:
      originalPostCapFee !== modifiedPostCapFee
        ? modifiedPostCapFee!
        : originalPostCapFee!,
    preCapFee:
      originalPreCapFee !== modifiedPreCapFee
        ? modifiedPreCapFee!
        : originalPreCapFee!,
  };
};

export const isTeammate = (
  agentId: string,
  flexTeamMembership: AgentFlexTeamMembershipResponse,
): boolean => {
  return (flexTeamMembership.teammates || []).some(
    (teammate) => teammate.agentId === agentId && teammate?.roles?.length === 0,
  );
};

export interface VisibilityPermission {
  teamConfigEdit: boolean;
  teamConfigView: boolean;
  teamDetailEdit: boolean;
  teamAddOrRemoveMember: boolean;
  teamEditMember: boolean;
  teamViewMember: boolean;
  showCapColumns: boolean;
  viewSelf: boolean;
  canInviteMembers: boolean;
}

export const getTeamVisibility = (
  team: FlexTeamDto,
  userId: string,
  isAdmin: boolean = false,
): VisibilityPermission => {
  const teamMember = team?.teammates?.find(
    (agent) => agent?.agentId === userId,
  );

  const isUserTeamLeader = !!team?.leaders?.find(
    (agent) => agent?.agentId === userId,
  );

  const isUserTeamAdmin = teamMember?.roles?.includes(
    FlexTeammateDtoRolesEnum.Admin,
  );

  const isUserTeamMember = teamMember?.roles?.length === 0;

  if (isAdmin) {
    return {
      teamConfigEdit: true,
      teamConfigView: true,
      teamDetailEdit: true,
      teamAddOrRemoveMember: true,
      teamEditMember: true,
      teamViewMember: true,
      showCapColumns: true,
      viewSelf: true,
      canInviteMembers: true,
    };
  }

  if (isUserTeamLeader) {
    return {
      teamConfigEdit: false,
      teamConfigView: true,
      teamDetailEdit: true,
      teamAddOrRemoveMember: false,
      teamEditMember: true,
      teamViewMember: true,
      showCapColumns: true,
      viewSelf: true,
      canInviteMembers: true,
    };
  }

  if (isUserTeamAdmin) {
    return {
      teamConfigEdit: false,
      teamConfigView: true,
      teamDetailEdit: false,
      teamAddOrRemoveMember: false,
      teamEditMember: true,
      teamViewMember: true,
      showCapColumns: true,
      viewSelf: true,
      canInviteMembers: true,
    };
  }

  if (isUserTeamMember) {
    return {
      teamConfigEdit: false,
      teamConfigView: false,
      teamDetailEdit: false,
      teamAddOrRemoveMember: false,
      teamEditMember: false,
      teamViewMember: false,
      showCapColumns: false,
      viewSelf: true,
      canInviteMembers: false,
    };
  }

  return {
    teamConfigEdit: false,
    teamConfigView: false,
    teamDetailEdit: false,
    teamAddOrRemoveMember: false,
    teamEditMember: false,
    teamViewMember: false,
    showCapColumns: false,
    viewSelf: false,
    canInviteMembers: false,
  };
};

export const calculateDefaultFeeSplits = (
  setValue: UseFormSetValue<ProTeamMember>,
  leaders: LeaderDto[],
) => {
  const leaderDetails = leaders.map((leader) => leader.leaderDetails);
  let feeTypeTotals: {
    [key: string]: number;
  } = {
    [FeeSplitFeeTypeEnum.RealSplit]: 0,
    [FeeSplitFeeTypeEnum.BeopFee]: 0,
    [FeeSplitFeeTypeEnum.BrokerageFee]: 0,
    [FeeSplitFeeTypeEnum.TransactionFee]: 0,
  };
  leaderDetails.forEach((item) => {
    item?.feeSplits?.forEach((feeSplit) => {
      const { feeType, percent } = feeSplit;
      feeTypeTotals[feeType] = (feeTypeTotals[feeType] || 0) + percent;
    });
  });
  setDefaultFeeSplits(setValue, feeTypeTotals);
};

export const setDefaultFeeSplits = (
  setValue: UseFormSetValue<ProTeamMember>,
  feeTypeTotals: { [key: string]: number },
) => {
  setValue(
    'individualRealSplit',
    getRemainingPercentage(feeTypeTotals[FeeSplitFeeTypeEnum.RealSplit]),
  );
  setValue(
    'individualBEOPFee',
    getRemainingPercentage(feeTypeTotals[FeeSplitFeeTypeEnum.BeopFee]),
  );
  setValue(
    'individualRealFee',
    getRemainingPercentage(feeTypeTotals[FeeSplitFeeTypeEnum.BrokerageFee]),
  );
  setValue(
    'individualTransactionFee',
    getRemainingPercentage(feeTypeTotals[FeeSplitFeeTypeEnum.TransactionFee]),
  );
};

export const getSplitPercentage = (
  feeType: FeeSplitFeeTypeEnum,
  feeSplits?: FeeSplit[],
) => {
  return feeSplits?.find((split) => split.feeType === feeType)?.percent! ?? '';
};

export interface FeeDetails {
  realSplit: number | string;
  beopFee: number | string;
  realFee: number | string;
  transactionFee: number | string;
  leaderSplit?: number | string;
}

export const combineFeeSplits = (feeDetails: FeeDetails): FeeSplit[] => {
  const feeTypes: { key: keyof FeeDetails; type: FeeSplitFeeTypeEnum }[] = [
    { key: 'realSplit', type: FeeSplitFeeTypeEnum.RealSplit },
    { key: 'beopFee', type: FeeSplitFeeTypeEnum.BeopFee },
    { key: 'realFee', type: FeeSplitFeeTypeEnum.BrokerageFee },
    { key: 'transactionFee', type: FeeSplitFeeTypeEnum.TransactionFee },
  ];

  return feeTypes.reduce<FeeSplit[]>((acc, { key, type }) => {
    const value = feeDetails[key];
    if (value) {
      acc.push({
        feeType: type,
        percent: typeof value === 'string' ? Number(value) : value,
      });
    }
    return acc;
  }, []);
};

export const getMaxFeeForLeaderValidation = (leaderList: LeaderDto[]) => {
  let realSplit = 0,
    BEOPFee = 0,
    brokerageFee = 0,
    transactionFee = 0,
    leaderSplit = 0;

  for (let i = 0; i < leaderList.length; i++) {
    let leader = leaderList[i];
    leaderSplit += leader.leaderDetails?.leaderSplit!;
    realSplit += getSplitPercentage(
      FeeSplitFeeTypeEnum.RealSplit,
      leader.leaderDetails?.feeSplits,
    );
    BEOPFee += getSplitPercentage(
      FeeSplitFeeTypeEnum.BeopFee,
      leader.leaderDetails?.feeSplits,
    );
    brokerageFee += getSplitPercentage(
      FeeSplitFeeTypeEnum.BrokerageFee,
      leader.leaderDetails?.feeSplits,
    );
    transactionFee += getSplitPercentage(
      FeeSplitFeeTypeEnum.TransactionFee,
      leader.leaderDetails?.feeSplits,
    );
  }

  return calculateRemainingPercentage(
    leaderSplit,
    realSplit,
    BEOPFee,
    brokerageFee,
    transactionFee,
  );
};

export const getMaxFeeForAgentValidation = (leaderList: FeeDetails[]) => {
  let realSplit = 0,
    BEOPFee = 0,
    brokerageFee = 0,
    transactionFee = 0,
    leaderSplit = 0;

  for (let i = 0; i < leaderList.length; i++) {
    let leader = leaderList[i];

    leaderSplit += +leader?.leaderSplit!;
    realSplit += +leader?.realSplit;
    BEOPFee += +leader?.beopFee;
    brokerageFee += +leader?.realFee;
    transactionFee += +leader?.transactionFee;
  }
  return calculateRemainingPercentage(
    leaderSplit,
    realSplit,
    BEOPFee,
    brokerageFee,
    transactionFee,
  );
};

const calculateRemainingPercentage = (
  leaderSplit: number,
  realSplit: number,
  BEOPFee: number,
  brokerageFee: number,
  transactionFee: number,
) => {
  const maxRealSplit = getRemainingPercentage(realSplit);

  const maxBEOPFee = getRemainingPercentage(BEOPFee);

  const maxBrokerageFee = getRemainingPercentage(brokerageFee);

  const maxTransactionFee = getRemainingPercentage(transactionFee);

  const maxLeaderSplit = getRemainingPercentage(leaderSplit);

  return {
    maxLeaderSplit: parseFloat(maxLeaderSplit),
    maxRealSplit: parseFloat(maxRealSplit),
    maxBEOPFee: parseFloat(maxBEOPFee),
    maxBrokerageFee: parseFloat(maxBrokerageFee),
    maxTransactionFee: parseFloat(maxTransactionFee),
  };
};

export const getOriginalLeaderDetails = (leaders: LeaderDto[]) => {
  const originalLeaderDetails = leaders.map((leader) => ({
    teamLeader: {
      value: leader.leaderDetails?.id!,
      label: leader.agent.firstName! + ' ' + leader.agent.lastName,
    },
    leaderSplit: leader.leaderDetails?.leaderSplit!,
    realSplit: getSplitPercentage(
      FeeSplitFeeTypeEnum.RealSplit,
      leader.leaderDetails?.feeSplits,
    ),
    beopFee: getSplitPercentage(
      FeeSplitFeeTypeEnum.BeopFee,
      leader.leaderDetails?.feeSplits,
    ),
    realFee: getSplitPercentage(
      FeeSplitFeeTypeEnum.BrokerageFee,
      leader.leaderDetails?.feeSplits,
    ),
    transactionFee: getSplitPercentage(
      FeeSplitFeeTypeEnum.TransactionFee,
      leader.leaderDetails?.feeSplits,
    ),
  }));
  return originalLeaderDetails;
};
export const getAmountBasedOnFeeType = (
  feeType: FeeSplitFeeTypeEnum,
  amount: number,
) => {
  if (feeType === FeeSplitFeeTypeEnum.RealSplit) {
    return { realSplit: amount };
  }
  if (feeType === FeeSplitFeeTypeEnum.BeopFee) {
    return { beopFee: amount };
  }
  if (feeType === FeeSplitFeeTypeEnum.BrokerageFee) {
    return { realFee: amount };
  }
  if (feeType === FeeSplitFeeTypeEnum.TransactionFee) {
    return { transactionFee: amount };
  }
  return {};
};

export const cleanTeamSplits = (data: ProTeammateFeeSplitsResponse) => {
  let teamSplits = [];
  for (let i = 0; i < data.splitsByLeader?.length!; i++) {
    let feeData = {
      leaderId: data.splitsByLeader![i].leaderParticipantId,
    };

    for (let j = 0; j < data.splitsByLeader![i].splits.length!; j++) {
      feeData = {
        ...feeData,
        ...getAmountBasedOnFeeType(
          data.splitsByLeader![i].splits[j].feeType,
          data.splitsByLeader![i].splits[j].paidByLeader,
        ),
      };
    }

    teamSplits.push(feeData);
  }

  return teamSplits;
};

export const isValidFee = (value: any) => {
  return value !== null && value !== undefined && value >= 0;
};
