import { displayAmount } from '../utils/CurrencyUtils';
import { MoneyValue } from '../openapi/yenta';

export interface SignedNumberProps {
  value: MoneyValue;
}

const SignedNumber: React.FC<SignedNumberProps> = ({ value }) => {
  if (value.amount! > 0) {
    return <p className='text-green-500'>{displayAmount(value)}</p>;
  } else if (value.amount! < 0) {
    return <p className='text-red-500'>{displayAmount(value)}</p>;
  } else {
    return <p className='text-black'>{displayAmount(value)}</p>;
  }
};

export default SignedNumber;
