import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  TransactionControllerApi,
  TransactionResponse,
  UpdateTransactionFirmDateRequest,
} from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { saveTransactionDetailResponse } from '../../../slices/TransactionSlice';
import { YesNoType } from '../../../types';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import {
  getConditionalDateErrors,
  isConditionalDatesPresent,
} from '../../../utils/TransactionUtils';
import ZenControlledDatePickerInput from '../Input/ZenControlledDatePickerInput';
import ZenControlledRadioInput from '../Input/ZenControlledRadioInput';
import ZenSidebarModalForm from '../ZenSidebarModalForm';

interface Props {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
}

interface FormData {
  firmDate: string;
  conditionalDates: YesNoType;
  financingConditionsExpirationDate: string;
  propertyInspectionExpirationDate: string;
  saleOfBuyersPropertyExpirationDate: string;
  condoDocumentsExpirationDate: string;
  otherConditionsExpirationDate: string;
}

const ZenChangeFirmDateSidebarModal: React.FC<Props> = ({
  isOpen,
  onClose,
  transaction,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      firmDate: transaction.firmDate,
      conditionalDates: isConditionalDatesPresent(transaction)
        ? YesNoType.YES
        : YesNoType.NO,
      financingConditionsExpirationDate:
        transaction.financingConditionsExpirationDate,
      propertyInspectionExpirationDate:
        transaction.propertyInspectionExpirationDate,
      saleOfBuyersPropertyExpirationDate:
        transaction.saleOfBuyersPropertyExpirationDate,
      condoDocumentsExpirationDate: transaction.condoDocumentsExpirationDate,
      otherConditionsExpirationDate: transaction.otherConditionsExpirationDate,
    },
  });

  const [
    conditionalDates,
    financingConditionsExpirationDate,
    propertyInspectionExpirationDate,
    saleOfBuyersPropertyExpirationDate,
    condoDocumentsExpirationDate,
    otherConditionsExpirationDate,
  ] = watch([
    'conditionalDates',
    'financingConditionsExpirationDate',
    'propertyInspectionExpirationDate',
    'saleOfBuyersPropertyExpirationDate',
    'condoDocumentsExpirationDate',
    'otherConditionsExpirationDate',
  ]);

  useEffect(() => {
    if (conditionalDates === YesNoType.NO) {
      setValue('firmDate', transaction?.contractAcceptanceDate!);
    } else {
      setValue('firmDate', transaction?.firmDate!);
    }
  }, [
    conditionalDates,
    setValue,
    transaction?.contractAcceptanceDate,
    transaction?.firmDate,
  ]);

  const onSubmit = async (values: FormData) => {
    try {
      const isConditionalDate = values.conditionalDates === YesNoType.YES;
      const req: UpdateTransactionFirmDateRequest = {
        financingConditionsExpirationDate: isConditionalDate
          ? values.financingConditionsExpirationDate
          : undefined,
        propertyInspectionExpirationDate: isConditionalDate
          ? values.propertyInspectionExpirationDate
          : undefined,
        saleOfBuyersPropertyExpirationDate: isConditionalDate
          ? values.saleOfBuyersPropertyExpirationDate
          : undefined,
        condoDocumentsExpirationDate: isConditionalDate
          ? values.condoDocumentsExpirationDate
          : undefined,
        otherConditionsExpirationDate: isConditionalDate
          ? values.otherConditionsExpirationDate
          : undefined,
      };
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).updateFirmDateForTransaction(transaction.id!, req);
      dispatch(
        saveTransactionDetailResponse({
          name: 'transactionDetail',
          loading: false,
          data,
        }),
      );
      onClose();
      dispatch(showSuccessToast('Updated firm date successfully.'));
    } catch (e) {
      const apiError = getConditionalDateErrors(e);
      if (!!apiError?.length) {
        apiError?.forEach((error) => {
          setError(error?.field as keyof FormData, {
            message: error?.errorMessage,
          });
        });
        return;
      }
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error updating firm date', e);
      dispatch(
        showErrorToast(
          'We had a problem updating the firm date',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <ZenSidebarModalForm
      isOpen={isOpen}
      onClose={onClose}
      title='Change Firm / Conditions Date'
      actionTitle='Update'
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <React.Fragment>
        <div className='mt-5'>
          <ZenControlledRadioInput<FormData, 'conditionalDates'>
            name='conditionalDates'
            label='Is this transaction conditional?'
            rules={{
              required: 'Please choose one',
            }}
            options={[
              {
                label: 'Yes',
                value: YesNoType.YES,
              },
              {
                label: 'No',
                value: YesNoType.NO,
              },
            ]}
            control={control}
            inlineOptions
            shouldUnregister={false}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledDatePickerInput<FormData, 'firmDate'>
            control={control}
            name='firmDate'
            label='Firm Date'
            placeholder='MM/DD/YYYY'
            shouldUnregister={false}
            icon={
              <FontAwesomeIcon
                icon={faCalendar}
                className='text-primary-blue'
                size='lg'
                title='calendar'
              />
            }
            readOnly
            noBorder
          />
        </div>
        {conditionalDates === YesNoType.YES && (
          <div className='mt-5'>
            <div>
              <p className='font-zen-body font-bold text-base text-zen-dark-9'>
                Conditions Date
              </p>
            </div>
            <div className='mt-2'>
              <ZenControlledDatePickerInput<
                FormData,
                'financingConditionsExpirationDate'
              >
                control={control}
                name='financingConditionsExpirationDate'
                label='Financing Conditions'
                placeholder='MM/DD/YYYY'
                shouldUnregister={false}
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className='text-primary-blue'
                    size='lg'
                    title='calendar'
                  />
                }
                rules={{
                  validate: (value: string) => {
                    if (
                      !value &&
                      !propertyInspectionExpirationDate &&
                      !saleOfBuyersPropertyExpirationDate &&
                      !condoDocumentsExpirationDate &&
                      !otherConditionsExpirationDate
                    ) {
                      return 'At least one date is required';
                    }

                    return undefined;
                  },
                }}
              />
            </div>
            <div className='mt-5'>
              <ZenControlledDatePickerInput<
                FormData,
                'propertyInspectionExpirationDate'
              >
                control={control}
                name='propertyInspectionExpirationDate'
                label='Property Inspection'
                placeholder='MM/DD/YYYY'
                shouldUnregister={false}
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className='text-primary-blue'
                    size='lg'
                    title='calendar'
                  />
                }
                rules={{
                  validate: (value: string) => {
                    if (
                      !value &&
                      !financingConditionsExpirationDate &&
                      !saleOfBuyersPropertyExpirationDate &&
                      !condoDocumentsExpirationDate &&
                      !otherConditionsExpirationDate
                    ) {
                      return 'At least one date is required';
                    }

                    return undefined;
                  },
                }}
              />
            </div>
            <div className='mt-5'>
              <ZenControlledDatePickerInput<
                FormData,
                'saleOfBuyersPropertyExpirationDate'
              >
                control={control}
                name='saleOfBuyersPropertyExpirationDate'
                label="Sale Of Buyer's Property"
                placeholder='MM/DD/YYYY'
                shouldUnregister={false}
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className='text-primary-blue'
                    size='lg'
                    title='calendar'
                  />
                }
                rules={{
                  validate: (value: string) => {
                    if (
                      !value &&
                      !financingConditionsExpirationDate &&
                      !propertyInspectionExpirationDate &&
                      !condoDocumentsExpirationDate &&
                      !otherConditionsExpirationDate
                    ) {
                      return 'At least one date is required';
                    }

                    return undefined;
                  },
                }}
              />
            </div>
            <div className='mt-5'>
              <ZenControlledDatePickerInput<
                FormData,
                'condoDocumentsExpirationDate'
              >
                control={control}
                name='condoDocumentsExpirationDate'
                label='Condo Documents'
                placeholder='MM/DD/YYYY'
                shouldUnregister={false}
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className='text-primary-blue'
                    size='lg'
                    title='calendar'
                  />
                }
                rules={{
                  validate: (value: string) => {
                    if (
                      !value &&
                      !financingConditionsExpirationDate &&
                      !propertyInspectionExpirationDate &&
                      !saleOfBuyersPropertyExpirationDate &&
                      !otherConditionsExpirationDate
                    ) {
                      return 'At least one date is required';
                    }

                    return undefined;
                  },
                }}
              />
            </div>
            <div className='mt-5'>
              <ZenControlledDatePickerInput<
                FormData,
                'otherConditionsExpirationDate'
              >
                control={control}
                name='otherConditionsExpirationDate'
                label='Other Conditions'
                placeholder='MM/DD/YYYY'
                shouldUnregister={false}
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className='text-primary-blue'
                    size='lg'
                    title='calendar'
                  />
                }
                rules={{
                  validate: (value: string) => {
                    if (
                      !value &&
                      !financingConditionsExpirationDate &&
                      !propertyInspectionExpirationDate &&
                      !saleOfBuyersPropertyExpirationDate &&
                      !condoDocumentsExpirationDate
                    ) {
                      return 'At least one date is required';
                    }

                    return undefined;
                  },
                }}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    </ZenSidebarModalForm>
  );
};

export default ZenChangeFirmDateSidebarModal;
