import { faBadgeCheck, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Match } from '@testing-library/react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ActionMeta } from 'react-select';
import { useDebounceValue } from 'usehooks-ts';
import { getDirectoryVendorRoleLabel } from '../components/Directory/DirectoryUtils';
import ZenDirectoryCard from '../components/Directory/ZenDirectoryCard';
import ZenDirectoryFilter from '../components/Directory/ZenDirectoryFilter';
import ZenDirectoryReadForm from '../components/Directory/ZenDirectoryReadForm';
import ZenRoleDirectoryForm from '../components/Directory/ZenRoleDirectoryForm';
import ResourceContainer from '../components/ResourceContainer';
import { CustomSelectOptions } from '../components/Zen/Input/ZenControlledSelectInput';
import ZenSelectInput from '../components/Zen/Input/ZenSelectInput';
import ZenTablePageSize from '../components/Zen/Table/ZenTablePageSize';
import ZenTablePagination from '../components/Zen/Table/ZenTablePagination';
import ZenButton from '../components/Zen/ZenButton';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenSearchBar from '../components/Zen/ZenSearchBar';
import {
  ZenToggleButton,
  ZenToggleButtonGroup,
} from '../components/ZenToggleButton';
import {
  DirectoryEntryResponse,
  DirectoryEntryResponseRoleEnum,
  DirectoryEntryResponseStatusEnum,
  DirectoryEntryResponseTypeEnum,
} from '../openapi/yenta';
import { usePageQueryParams } from '../query/base/usePageQueryParams';
import { useDirectory } from '../query/directory/useDirectory';
import AnalyticsService from '../services/AnalyticsService';
import { AnalyticsEventEnum, ISelectOption, RootState } from '../types';
import { cleanPhoneNumber } from '../utils/StringUtils';
import { getPageCount } from '../utils/TableUtils';

interface EventsRouteProps extends RouteComponentProps<Match> {}

type RoleFilter = 'agents' | 'clients';

export interface FilterValuesFormData {
  participantRole?: ISelectOption[];
  id?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
}

const quickFiltersOptions = [
  { label: 'All', value: 'all' },
  { label: 'My Contacts', value: 'myContacts' },
  { label: 'Clients', value: 'clients' },
  { label: 'External Agents', value: 'agents' },
  { label: 'Archived', value: 'archived' },
];

const getRoleFilter = (
  filter: RoleFilter,
  queryParamRoles?: Array<DirectoryEntryResponseRoleEnum>,
): Array<DirectoryEntryResponseRoleEnum> | undefined => {
  const baseAgentRoles = [DirectoryEntryResponseRoleEnum.OtherAgent];
  const baseClientRoles = [DirectoryEntryResponseRoleEnum.Client];

  if (filter === 'agents' && queryParamRoles) {
    return uniq([...baseAgentRoles, ...queryParamRoles]);
  }
  if (filter === 'agents') {
    return baseAgentRoles;
  }

  if (filter === 'clients' && queryParamRoles) {
    return uniq([...baseClientRoles, ...queryParamRoles]);
  }
  if (filter === 'clients') {
    return baseClientRoles;
  }

  if (queryParamRoles) {
    return queryParamRoles;
  }

  return undefined;
};

const ZenDirectoryRoute: React.FC<EventsRouteProps> = () => {
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  const filterRef = useRef<HTMLDivElement | null>(null);
  const {
    parsed: queryParams,
    putNewQuery,
    pushNewQuery,
  } = usePageQueryParams();
  const [search, setSearch] = useState<string>(
    (queryParams?.query as string) || '',
  );
  const queryParamType = queryParams?.type as string;
  const isTypeValid =
    queryParamType?.toUpperCase() === DirectoryEntryResponseTypeEnum.Person ||
    queryParamType?.toUpperCase() === DirectoryEntryResponseTypeEnum.Vendor;
  const shouldShowReadView = Boolean(queryParams?.id) && isTypeValid;

  const [debouncedSearch] = useDebounceValue(search, 500);
  const [isRoleDirectoryFormOpen, setIsRoleDirectoryFormOpen] = useState(false);
  const [isOpenReadView, setIsOpenReadView] = useState<boolean>(
    shouldShowReadView || false,
  );
  const [selectContact, setSelectContact] = useState<
    DirectoryEntryResponse | undefined
  >(
    shouldShowReadView
      ? {
          id: queryParams?.id as string,
          type: queryParamType?.toUpperCase() as DirectoryEntryResponseTypeEnum,
        }
      : undefined,
  );

  const onFilterApplied: SubmitHandler<FilterValuesFormData> = (data) => {
    const { name, email, phoneNumber, id } = data;
    pushNewQuery({
      id,
      email,
      name,
      pageIndex: 0,
      participantRole: data.participantRole?.map((role) => role?.value),
      phoneNumber: phoneNumber ? cleanPhoneNumber(phoneNumber) : undefined,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string, eventData?: Record<string, unknown>) => {
      AnalyticsService.instance().logEvent(event, eventData);
    }, 500),
    [],
  );

  const handleSearch = (value: string) => {
    setSearch(value);
    putNewQuery({ pageIndex: 0 });
    if (value) {
      logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_SEARCH, {
        searchQuery: value,
      });
    }
  };

  const handleIsVerifiedToggleChange = (
    _e: React.MouseEvent<HTMLButtonElement>,
    _values: string | string[],
  ) => {
    if (queryParams?.isVerified === 'true') {
      putNewQuery({ isVerified: undefined, pageIndex: 0 });
      logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_REAL_VERIFIED_NO);
    } else {
      putNewQuery({ isVerified: true, pageIndex: 0 });
      logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_REAL_VERIFIED_YES);
    }
  };

  const handleQuickFilterChange = (
    option: CustomSelectOptions | null,
    _action: ActionMeta<CustomSelectOptions>,
  ) => {
    if (option?.value) {
      putNewQuery({
        pageIndex: 0,
        quickFilter: option?.value,
      });

      logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_QUICK_FILTER, {
        filter: option?.label,
      });
    }
  };

  const toggleRoleDirectoryForm = () => {
    setIsRoleDirectoryFormOpen(!isRoleDirectoryFormOpen);
  };

  const handleCloseReadView = () => {
    setIsOpenReadView(false);
    setSelectContact(undefined);
    // resets queryParams.type on close
    putNewQuery({ type: undefined });
  };
  const handleReadView = (selectContact?: DirectoryEntryResponse) => {
    setIsOpenReadView(!isOpenReadView);
    setSelectContact(selectContact);
    logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_CARD_CLICK);
  };

  const pageIndex = parseInt((queryParams?.pageIndex as string) ?? 0);
  const pageSize = parseInt((queryParams?.pageSize as string) ?? 20);

  const { data, isLoading } = useDirectory({
    fnArgs: [
      pageIndex,
      pageSize,
      queryParams?.quickFilter === 'archived' ? true : undefined,
      queryParams?.isVerified === 'true' ? true : undefined,
      debouncedSearch.length === 0 ? undefined : debouncedSearch,
      undefined, // nationalBusinessId
      queryParams?.name ? (queryParams?.name as string) : undefined, // name
      queryParams?.email ? (queryParams?.email as string) : undefined, // emailAddress
      queryParams?.phoneNumber
        ? (queryParams?.phoneNumber as string)
        : undefined, // phoneNumber
      undefined, // street
      undefined, // city
      undefined, // postal
      undefined, // state
      undefined, // country
      undefined, // administrativeAreaIds
      getRoleFilter(
        queryParams?.quickFilter as RoleFilter,
        queryParams?.participantRole as Array<DirectoryEntryResponseRoleEnum>,
      ), // roles
      queryParams?.quickFilter === 'myContacts' ? userDetail?.id : undefined, // createdBy
      (queryParams?.id as string) || undefined, // id
    ],
  });

  useEffect(() => {
    // sync query params
    if (debouncedSearch !== queryParams?.query) {
      putNewQuery({
        query: debouncedSearch.length === 0 ? undefined : debouncedSearch,
      });
    }
  }, [debouncedSearch, putNewQuery, queryParams?.query]);

  return (
    <ZenRoute title='Directory'>
      <ZenPageLayout path={[{ title: 'Directory', url: '/directory' }]}>
        <div className='p-4'>
          <div className='flex flex-row justify-between'>
            <div className='text-xl font-zen-body text-zen-dark font-semibold'>
              Directory
            </div>
            <div className='flex items-center gap-2'>
              <div
                className='w-44 whitespace-nowrap'
                data-testid='quick-filter-wrapper'
              >
                <ZenSelectInput
                  name='roles'
                  label=''
                  placeholder='Category'
                  options={quickFiltersOptions}
                  value={
                    quickFiltersOptions.find(
                      ({ value }) => value === queryParams?.quickFilter,
                    ) || quickFiltersOptions[0]
                  }
                  onChange={handleQuickFilterChange}
                />
              </div>
              <ZenToggleButtonGroup
                value={queryParams?.isVerified ? 'verified' : ''}
                onChange={handleIsVerifiedToggleChange}
              >
                <ZenToggleButton
                  label='Real Verified'
                  value='verified'
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faBadgeCheck}
                      color='green'
                      className='mr-1 text-lg'
                    />
                  }
                />
              </ZenToggleButtonGroup>
              <div className='relative' ref={filterRef}>
                <ZenDirectoryFilter
                  parentRef={filterRef}
                  onFilterApplied={onFilterApplied}
                />
              </div>
              <ZenButton
                LeftIconComponent={
                  <FontAwesomeIcon icon={faPlus} className='ml-0.5 pr-1' />
                }
                label='Add Contact'
                variant='primary'
                onClick={toggleRoleDirectoryForm}
              />
            </div>
          </div>
          <div className='mt-4 mb-12 relative'>
            <div className='flex items-center gap-2'>
              <div className='grow basis-0 flex'>
                <ZenSearchBar
                  value={search}
                  onChange={handleSearch}
                  placeholder='Directory Search'
                />
              </div>
            </div>
            <ResourceContainer
              loading={isLoading}
              resourceName='contact'
              isEmpty={isEmpty(data?.directoryEntryResponseList)}
            >
              {data?.directoryEntryResponseList &&
                Object.entries(data?.directoryEntryResponseList).map(
                  ([letter, contacts]) => (
                    <div key={letter}>
                      <div
                        className='font-zen-body font-bold mt-4 text-xl'
                        data-testid={`directory-header-${letter}`}
                      >
                        {letter}
                      </div>
                      <div className='flex flex-wrap items-start gap-4'>
                        {contacts.map((contact) => (
                          <ZenDirectoryCard
                            {...contact}
                            key={contact.id}
                            role={getDirectoryVendorRoleLabel(contact.role)}
                            company={contact.linkedName}
                            isRealVerified={
                              contact.status ===
                              DirectoryEntryResponseStatusEnum.Verified
                            }
                            type={contact.type!}
                            onClick={() => {
                              handleReadView(contact);
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  ),
                )}
            </ResourceContainer>
          </div>
        </div>
        {data && (
          <div
            className={classNames(
              'absolute left-4 bottom-4 pr-12 flex items-center justify-between w-full',
            )}
          >
            <div className='hidden lg:inline-block'>
              <ZenTablePageSize
                itemsToShow={pageSize ?? 20}
                setPageSize={(size) => {
                  pushNewQuery({ pageSize: size });
                  logAnalyticsEvent(
                    AnalyticsEventEnum.DIRECTORY_PAGE_SIZE_CHANGED,
                    { pageSize: size },
                  );
                }}
                pageSizeOptions={[10, 20, 40]}
              />
            </div>
            <ZenTablePagination
              currentPage={pageIndex}
              pageSize={pageSize}
              lastPage={getPageCount(data.totalCount ?? 0, pageSize)}
              totalCount={data.totalCount ?? 0}
              goToPage={(page) => {
                pushNewQuery({ pageIndex: page });
                logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_PAGE_CHANGED, {
                  pageNumber: page + 1,
                });
              }}
            />
          </div>
        )}
      </ZenPageLayout>
      {isRoleDirectoryFormOpen && (
        <ZenRoleDirectoryForm
          isOpen={isRoleDirectoryFormOpen}
          onClose={toggleRoleDirectoryForm}
        />
      )}
      {isOpenReadView && selectContact && selectContact.id && (
        <ZenDirectoryReadForm
          isOpen={isOpenReadView}
          onClose={handleCloseReadView}
          selectContactId={selectContact.id}
          selectContactType={selectContact.type!}
        />
      )}
    </ZenRoute>
  );
};

export default ZenDirectoryRoute;
