import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  FlexTeamConfigDtoCountryEnum,
  TeamResponseTypeEnum,
  UserResponseAgentStatusEnum,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import {
  getTeamDetailOverview,
  updateTeamMember,
} from '../../../slices/TeamSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { AsyncSelectOption, ISelectOption } from '../../../types';
import { NUMBER_REGEX } from '../../../utils/StringUtils';
import { searchForAgents } from '../../../utils/TableUtils';
import { mapTeamRoleToType } from '../../../utils/TeamHelper';
import ZenControlledAsyncSelectInput from '../Input/ZenControlledAsyncSelectInput';
import ZenControlledSelectInput from '../Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenSidebarModalActionFooterV2 from '../Modal/ZenSidebarModalActionFooterV2';
import ZenSidebarModal from '../ZenSidebarModal';

interface ZenAddTeamMemberProps {
  isOpen: boolean;
  onClose(): void;
  teamId: string;
  teamType: TeamResponseTypeEnum;
  fetchTeamCapInfo: (loading: boolean) => Promise<void>;
  country?: FlexTeamConfigDtoCountryEnum;
}

interface FormData {
  agentId?: ISelectOption;
  commissionSplit: number | '';
  role?: ISelectOption;
}

const ZenAddTeamMember: React.FC<ZenAddTeamMemberProps> = ({
  isOpen,
  onClose,
  teamType,
  teamId,
  fetchTeamCapInfo,
  country,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      agentId: undefined,
      commissionSplit: '',
      role: undefined,
    },
  });

  const dispatch = useDispatch();
  const agentCommission = watch('commissionSplit');
  const leaderCommission = `Leader Commission: ${
    100 - parseFloat(String(agentCommission || 0))
  }%`;

  const onSubmit = async (data: any) => {
    await dispatch(
      updateTeamMember(teamId, {
        createRequests: [
          {
            memberAgentId: data.agentId.value,
            memberCommissionSplit: data.commissionSplit || undefined,
            roles: [data.role?.value],
          },
        ],
        updateRequests: [],
        userIdsToDelete: [],
      }),
    );
    fetchTeamCapInfo(false);
    dispatch(getTeamDetailOverview(teamId));
    onClose();
  };

  return (
    <ZenSidebarModal isOpen={isOpen} onClose={onClose} title='Add New Member'>
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='add-team-member-form'
      >
        <div className='p-5'>
          <div className='mt-3'>
            <ZenControlledAsyncSelectInput<FormData, 'agentId'>
              control={control}
              name='agentId'
              placeholder='Search Member'
              label='Search Member'
              fetchData={async (search, page) => {
                try {
                  const { data } = await searchForAgents({
                    page,
                    search,
                    filterBy: {
                      country: [country!],
                      agentStatus: [UserResponseAgentStatusEnum.Active],
                    },
                  });

                  const options: AsyncSelectOption[] = data.map((resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.firstName} ${resp.lastName} - ${resp.emailAddress}`,
                  }));

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search request registered agents in add team member form',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                      },
                    },
                  );
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an registered agent. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              isRequired
              rules={{
                required: 'Please select a team member',
              }}
              startAdornment={
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className='text-primary-blue mx-3'
                  size='sm'
                />
              }
              endAdornment={
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className='text-zen-dark mx-5'
                  size='sm'
                />
              }
            />
          </div>
          {teamType !== TeamResponseTypeEnum.Domestic && (
            <div className='mt-8'>
              <ZenControlledTextInput<FormData, 'commissionSplit'>
                control={control}
                type='number'
                label='Agent Commission'
                name='commissionSplit'
                placeholder='Commission Split'
                rules={{
                  min: { value: 0, message: 'Number cannot be less than 0' },
                  max: {
                    value: 100,
                    message: 'Number cannot be greater than 100',
                  },
                  pattern: {
                    value: NUMBER_REGEX,
                    message: 'Please enter a valid number',
                  },
                }}
                helperText={leaderCommission}
              />
            </div>
          )}
          <div className='mt-8'>
            <ZenControlledSelectInput<FormData, 'role'>
              control={control}
              name='role'
              label='Select Role'
              placeholder='Select Role'
              options={mapTeamRoleToType(teamType)}
              isRequired
              rules={{
                required: 'Please select a role',
              }}
            />
          </div>
        </div>
        <ZenSidebarModalActionFooterV2
          onClose={onClose}
          isSubmitting={isSubmitting}
          submitButtonText='Save'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenAddTeamMember;
