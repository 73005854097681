import React, { PropsWithChildren } from 'react';
import { AsyncResponse, ErrorCode } from '../../types';

interface AuthorizedOnlyProps<T> {
  asyncResponse: AsyncResponse<T>;
}

const AuthorizedOnly = <T extends object>({
  asyncResponse,
  children,
}: PropsWithChildren<AuthorizedOnlyProps<T>>) => {
  if (asyncResponse.error === ErrorCode.UNAUTHORIZED) {
    return null;
  }

  if (asyncResponse.error === ErrorCode.FORBIDDEN) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthorizedOnly;
