import { uniq } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAgentsInfoKeymakerId } from '../slices/UserIdsSlice';
import { RootState } from '../types';

const useAgentsInfoFromKeymakerId = (
  agentIds: string[],
  getPublicUserInfo: boolean = false,
) => {
  const dispatch = useDispatch();
  const { agentByKeymakerId } = useSelector(
    (state: RootState) => state.userIds,
  );

  const agentIdsToFetch = useMemo(
    () => uniq(agentIds).filter((id) => !agentByKeymakerId[id]),
    [agentByKeymakerId, agentIds],
  );

  useEffect(() => {
    if (agentIdsToFetch.length > 0) {
      dispatch(fetchAgentsInfoKeymakerId(agentIdsToFetch, getPublicUserInfo));
    }
  }, [agentIdsToFetch, dispatch, getPublicUserInfo]);
};

export default useAgentsInfoFromKeymakerId;
