import React from 'react';
import { cn } from '../../utils/classUtils';
import TierDiamond from './TierDiamond';

export type ShadeVariant = 'silver' | 'gold' | 'platinum';
export interface TierMembershipAmountCardProps {
  tierType: ShadeVariant;
  label: string;
  amount: number;
}

const TierMembershipAmountCard: React.FC<TierMembershipAmountCardProps> = ({
  tierType,
  label,
  amount,
}) => {
  const backgroundVariantClassMap: Record<ShadeVariant, string> = {
    silver:
      'radial-gradient(82.57% 102.45% at 22.22% 9.74%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(84.79deg, #858D8F 2.91%, #9FA2A1 19.98%, #858B8B 36.57%, #808587 50.23%, #768286 75.1%, #838C8E 84.37%, #768286 96.56%)',
    gold:
      'radial-gradient(100.11% 101.99% at 26.57% -6.72%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(84.79deg, #A47C4E 2.91%, #F1D2AC 19.98%, #B48C5C 36.57%, #A47C4E 50.23%, #A47C4E 75.1%, #C99456 84.37%, #A47C4E 96.56%)',
    platinum:
      'radial-gradient(82.57% 102.45% at 22.22% 9.74%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(84.79deg, #576266 2.91%, #9FA2A1 19.98%, #858B8B 36.57%, #667377 50.23%, #6A797E 75.1%, #838C8E 84.37%, #667377 98.56%)',
  };

  const textVariantClassMap: Record<ShadeVariant, string> = {
    silver: 'text-grey-600',
    gold: 'text-yellow-900',
    platinum: 'text-grey-600',
  };

  return (
    <div
      className='flex flex-row items-center justify-between max-w-[380px] max-h-[76px] p-4 pt-6 rounded-lg shadow-md box-content'
      style={{
        backgroundImage: backgroundVariantClassMap[tierType],
      }}
    >
      <TierDiamond tierType={tierType} size='xs' />
      <text
        className={cn(
          'md:text-2xl text-xl leading-9 font-zen-title ml-4 mr-2 flex-grow',
          textVariantClassMap[tierType],
        )}
      >
        {label}
      </text>
      <text
        className={cn(
          'text-lg leading-6 font-zen-body text-right',
          textVariantClassMap[tierType as ShadeVariant],
        )}
      >
        ${amount}
      </text>
    </div>
  );
};

export default TierMembershipAmountCard;
