import { AgentResponseAccountCountryEnum } from '../openapi/yenta';
import { EnumMap } from '../types';

export const KNOWLEDGE_BASE_LINK =
  'https://therealbrokerage.zendesk.com/hc/en-us';

export const REAL_DEPOSITS_FAQ_LINK =
  'https://therealbrokerage.zendesk.com/hc/en-us/articles/5605285206295-Closing-How-To-Use-Real-Deposits';

export const countryMapSupportPhoneNumber: EnumMap<
  AgentResponseAccountCountryEnum,
  string
> = {
  [AgentResponseAccountCountryEnum.UnitedStates]: '1-646-859-2368',
  [AgentResponseAccountCountryEnum.Canada]: '1-782-820-4098',
};

export const getSupportPhoneNumberForCountry = (
  country?: AgentResponseAccountCountryEnum,
) =>
  countryMapSupportPhoneNumber[
    country || AgentResponseAccountCountryEnum.UnitedStates
  ];

export const CANADIAN_AGENT_FAQ_AND_SUPPORT_LINK =
  'https://therealbrokerage.zendesk.com/hc/en-us/sections/6379130859159-License-Transfers-Renewals-Canada';

export const US_AGENT_LICENSE_TRANSFERS_LINK =
  'https://therealbrokerage.zendesk.com/hc/en-us/sections/6356758211863-License-Transfers-';

export const SUPPORT_EMAIL_ID = 'support@therealbrokerage.com';
