import { sum } from 'lodash';
import {
  PaymentParticipantValue,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { PaidAtLabel } from '../../PaidAtLabel';
import ZenCollapsibleTableRow from '../ZenCollapsibleTableRow';
import ZenLedgerItemRow from '../ZenLedgerItemRow';
import ZenSignedNumber from '../ZenSignedNumber';
import ZenParticipantCard from './ZenParticipantCard';

export interface ZenPaymentParticipantCardProps {
  transaction?: TransactionResponse;
  participant: PaymentParticipantValue;
  isAdmin: boolean;
  onEdit(): void;
  onDelete(): void;
  isReadonly?: boolean;
  transactionOwner: boolean;
  isSingleCheck: boolean;
  defaultCollapsed?: boolean;
  hideView?: boolean;
  isPaymentDetailsHidden?: boolean;
}

const ZenPaymentParticipantCard: React.FC<ZenPaymentParticipantCardProps> = ({
  transaction,
  participant,
  onEdit,
  onDelete,
  isReadonly,
  transactionOwner,
  isAdmin,
  hideView,
  defaultCollapsed = false,
  isPaymentDetailsHidden = false,
}) => {
  const isNoPaymentDetailsToShow =
    isPaymentDetailsHidden &&
    !(
      participant.grossPayment ||
      participant.netPayout ||
      participant.payoutSetting ||
      participant.commissionTotal
    );

  return (
    <div className='w-full h-full'>
      <ZenParticipantCard
        transaction={transaction}
        participant={participant}
        onDelete={onDelete}
        onEdit={onEdit}
        isReadonly={isReadonly}
        transactionOwner={transactionOwner}
        isAdmin={isAdmin}
        hideView={hideView}
        isPaymentDetailsHidden={isPaymentDetailsHidden}
      >
        <div className='pb-10'>
          {isNoPaymentDetailsToShow ? (
            <div className='flex flex-col flex-grow justify-center items-center py-10'>
              <p className='text-gray-400 text-center'>No view permission</p>
            </div>
          ) : (
            <div className='m-3 py-1 bg-grey-100 rounded-[10px]'>
              <ZenCollapsibleTableRow
                name='Commission Split'
                value={
                  !participant.paid
                    ? participant.payoutSetting?.percentage?.string
                    : undefined
                }
                ValueComponent={
                  participant.paid ? (
                    <PaidAtLabel paidAt={participant.paidAt!} />
                  ) : undefined
                }
                showBorder={false}
                defaultCollapsed={defaultCollapsed}
              />
              <ZenCollapsibleTableRow
                name='Total'
                value={displayAmount(participant.commissionTotal)}
                defaultCollapsed={defaultCollapsed}
              />
              <ZenCollapsibleTableRow
                name='Real'
                ValueComponent={
                  <ZenSignedNumber
                    value={{
                      amount: sum(
                        participant?.realItems?.map(
                          (item) => item.amount?.amount,
                        ),
                      ),
                      currency: participant.commissionTotal?.currency!,
                    }}
                  />
                }
                defaultCollapsed={defaultCollapsed}
              >
                {participant?.realItems?.length !== 0 &&
                  participant?.realItems?.map((item, index) => (
                    <ZenLedgerItemRow
                      key={item?.id}
                      item={item}
                      participant={participant}
                      showBorder={index > 0}
                    />
                  ))}
              </ZenCollapsibleTableRow>
              <ZenCollapsibleTableRow
                name='Fees And Rebates'
                ValueComponent={
                  <ZenSignedNumber
                    value={{
                      amount: sum(
                        participant.feeAndRebateItems?.map(
                          (item) => item.amount?.amount,
                        ),
                      ),
                      currency: participant.commissionTotal?.currency!,
                    }}
                  />
                }
                defaultCollapsed={defaultCollapsed}
              >
                {participant?.feeAndRebateItems?.length !== 0 &&
                  participant?.feeAndRebateItems?.map((item, i) => (
                    <ZenLedgerItemRow
                      key={item.id}
                      item={item}
                      participant={participant}
                      showBorder={i > 0}
                    />
                  ))}
              </ZenCollapsibleTableRow>
              <ZenCollapsibleTableRow
                name='Net Payout'
                ValueComponent={
                  <ZenSignedNumber value={participant.netPayout!} />
                }
                defaultCollapsed={defaultCollapsed}
              />
            </div>
          )}
        </div>
      </ZenParticipantCard>
    </div>
  );
};

export default ZenPaymentParticipantCard;
