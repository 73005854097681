import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  AgentControllerApi,
  UserResponseAgentStatusEnum,
} from '../../../../../openapi/yenta';
import ErrorService from '../../../../../services/ErrorService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../slices/ToastNotificationSlice';
import { getYentaConfiguration } from '../../../../../utils/OpenapiConfigurationUtils';

import { showApiErrorModal } from '../../../../../slices/ErrorSlice';
import { AsyncSelectOption } from '../../../../../types';
import { searchForAgents } from '../../../../../utils/TableUtils';
import DefaultLoader from '../../../../DefaultLoader';
import DoubleConfirmationModal from '../../../../DoubleConfirmationModal';
import ZenButton from '../../../ZenButton';
import ZenSidebarModal from '../../../ZenSidebarModal';
import ZenControlledAsyncSelectInput from '../../../Input/ZenControlledAsyncSelectInput';

interface ZenCancelCapDefermentSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  realAgent: AsyncSelectOption;
}

const ZenCancelCapDefermentSidebarModal: React.FC<ZenCancelCapDefermentSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    watch,
    control,
    handleSubmit,
    formState,
    setError,
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const { isSubmitting, isValid } = formState;
  const realAgentWatch = watch('realAgent');
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    async function fetchAgent(id: string) {
      setLoading(true);
      try {
        const { data } = await new AgentControllerApi(
          getYentaConfiguration(),
        ).getAgentById(id);

        if (!data.capDeferred) {
          setError('realAgent', {
            type: 'value',
            message: 'The agent must be cap deferred',
          });
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);
        ErrorService.notify('Error fetching real agent', e);
        dispatch(
          showErrorToast(
            'We had a problem fetching the real agent',
            'Please try again in a few moments.',
          ),
        );
      }
    }

    if (realAgentWatch) {
      fetchAgent(realAgentWatch.value);
    }
  }, [dispatch, realAgentWatch]);

  const onSubmit = async (values: FormData) => {
    try {
      await new AgentControllerApi(getYentaConfiguration()).cancelDeferredCap(
        values.realAgent.value,
      );
      onClose();
      dispatch(showSuccessToast('Updated cap deferred successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify(
        'Unable to fetch yenta agent by yentaId and numberOfMonths',
        e,
        {
          agent: {
            id: values.realAgent.value,
          },
        },
      );
    } finally {
      setShowConfirmationModal(false);
    }
  };

  return (
    <ZenSidebarModal
      title='Cancel Cap Deferment'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form className='flex flex-col justify-between min-h-full'>
        <div className='px-4'>
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<FormData, 'realAgent'>
              control={control}
              name='realAgent'
              placeholder='Search'
              label='Select Agent to Cancel Cap Deferment'
              isRequired
              fetchData={async (search, page) => {
                try {
                  const { data } = await searchForAgents({
                    page,
                    search,
                    filterBy: {
                      agentStatus: [UserResponseAgentStatusEnum.Active],
                    },
                  });

                  const options: AsyncSelectOption[] = data.map((resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.firstName} ${resp.lastName} (${resp.emailAddress})`,
                  }));

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search for agents to mark cap defered',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                      },
                    },
                  );
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an agent. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              rules={{
                required: 'Required',
              }}
            />
          </div>

          {loading && <DefaultLoader />}
        </div>
        <div className='sticky bottom-0 flex flex-row p-4 space-x-5 bg-white border-t border-gray-200'>
          <ZenButton
            isFullWidth
            type='button'
            onClick={onClose}
            label='Cancel'
            variant='primary-outline'
            textSize='lg'
          />
          <ZenButton
            isFullWidth
            isDisabled={isSubmitting || !isValid || loading}
            onClick={() => setShowConfirmationModal(true)}
            label='Submit'
            textSize='lg'
          />
        </div>
        <DoubleConfirmationModal
          onConfirm={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          isOpen={showConfirmationModal}
          variant='info'
          title='Confirm Cancel Cap Deferment?'
          onClose={() => setShowConfirmationModal(false)}
          subtitle='Are you sure you want to cancel the cap deferment for this agent?'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenCancelCapDefermentSidebarModal;
