import { faCircleCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AgentResponseAgentStatusEnum } from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import Pill, { PillVariantType } from '../../Pill';

export interface AgentStatusCellProps {
  status: AgentResponseAgentStatusEnum;
  showIcon?: boolean;
}

const statusToPillVariantMap: EnumMap<
  AgentResponseAgentStatusEnum,
  PillVariantType
> = {
  [AgentResponseAgentStatusEnum.Rejected]: 'danger',
  [AgentResponseAgentStatusEnum.Inactive]: 'danger',
  [AgentResponseAgentStatusEnum.Active]: 'success',
  [AgentResponseAgentStatusEnum.Candidate]: 'warning',
};

const statusToPillIconMap: EnumMap<
  AgentResponseAgentStatusEnum,
  React.ReactElement
> = {
  [AgentResponseAgentStatusEnum.Inactive]: <FontAwesomeIcon icon={faXmark} />,
  [AgentResponseAgentStatusEnum.Rejected]: <FontAwesomeIcon icon={faXmark} />,
  [AgentResponseAgentStatusEnum.Active]: (
    <FontAwesomeIcon icon={faCircleCheck} />
  ),
  [AgentResponseAgentStatusEnum.Candidate]: (
    <FontAwesomeIcon icon={faCircleCheck} />
  ),
};

const AgentStatusCell: React.FC<AgentStatusCellProps> = ({
  status,
  showIcon = false,
}) => {
  return (
    <Pill
      text={capitalizeEnum(status)}
      variant={statusToPillVariantMap[status]}
      icon={showIcon ? statusToPillIconMap[status] : undefined}
    />
  );
};

export default AgentStatusCell;
