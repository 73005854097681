import { values } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { DateTime } from 'luxon';
import {
  AddressCountryEnum,
  AddressResponseCountryEnum,
  AddressStateOrProvinceEnum,
  CreateTaxFormTypeEnum,
  CreateTaxFormW8BEN,
  CreateTaxFormW8BENCountryOfIncorporationEnum,
  NationalIdentificationValueTypeEnum,
  UpdateTaxFormTypeEnum,
  UpdateTaxFormW8BEN,
  UpdateTaxFormW8BENCountryOfIncorporationEnum,
} from '../../openapi/yenta';
import {
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps,
} from '../../routes/MSDynamicsOnboardingRoute';
import {
  AdditionalIdentifications,
  getIdValidations,
  getMaskTypeForIdentificationType,
} from '../../utils/AgentHelper';
import { capitalizeEnum } from '../../utils/StringUtils';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import ZenControlledCheckboxInput from '../Zen/Input/ZenControlledCheckboxInput';
import ZenControlledDatePickerInput from '../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import {
  AgentAddressTypeEnum,
  AppDispatch,
  RootState,
  YesNoType,
} from '../../types';
import {
  createTaxForm,
  updateTaxFormInfo,
} from '../../slices/TaxInformationSlice';
import ZenButton from '../Zen/ZenButton';
import ZenControlledStateOrProvince from '../Zen/Input/ZenControlledStateOrProvince';
import ZenControlledHTMLSelectInput from '../Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledSignatureInput from '../Zen/Input/ZenControlledSignatureInput';
import ZenControlledMaskTextInput from '../Zen/Input/ZenControlledMaskTextInput';
import { getPostalCodeValidation } from '../../utils/Validations';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import withMSDynamicsProgress from './withMSDynamicsProgress';

const MsDynamicOnboardingTaxFormW8Ben: StepByStepComponent<
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps
> = ({ form: { control, watch, trigger, setValue }, onPrevious, onNext }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [
    mailAddressSameAsPermanent,
    country,
    mailingCountry,
    taxFormID,
  ] = watch([
    'w8BEN.mailingAddressSameAsPermanent',
    'w8BEN.country',
    'w8BEN.mailingCountry',
    'w8BEN.id',
  ]);
  const mailingAddressSameAsPermanent = mailAddressSameAsPermanent?.[0];
  const [loading, setloading] = useState(false);

  const { userDetail, availableCountries } = useSelector(
    (state: RootState) => state.auth,
  );

  const countries = availableCountries.countries || [];

  const handleNext = async () => {
    const isValid = await trigger();
    if (!isValid) {
      dispatch(showErrorToast('There are fields which need to be filled out'));
      return;
    }
    setloading(true);
    const formValues = watch().w8BEN;
    const isMailingAddressSameAsPermanent =
      formValues.mailingAddressSameAsPermanent;
    let isSuccess: boolean;
    if (formValues.id) {
      const req: UpdateTaxFormW8BEN = {
        type: UpdateTaxFormTypeEnum.W8Ben,
        mailingAddress: {
          type: AgentAddressTypeEnum.MAILING,
          zipOrPostalCode: isMailingAddressSameAsPermanent
            ? formValues.zipCode
            : formValues.mailingZipCode,
          streetAddress1: isMailingAddressSameAsPermanent
            ? formValues.addressLine
            : formValues.mailingAddressLine,
          stateOrProvince: ((isMailingAddressSameAsPermanent
            ? formValues.state
            : formValues.mailingState) as unknown) as AddressStateOrProvinceEnum,
          country: isMailingAddressSameAsPermanent
            ? formValues.country
            : formValues.mailingCountry,
          city: isMailingAddressSameAsPermanent
            ? formValues.city
            : formValues.mailingCity,
        },
        countryOfIncorporation: formValues.countryOfCitizenship
          .value as UpdateTaxFormW8BENCountryOfIncorporationEnum,
        foreignTaxId: formValues.foreignTaxId,
        name: formValues.taxPayer,
        nationalIds: [
          {
            type:
              formValues.usTaxIdType ?? NationalIdentificationValueTypeEnum.Ssn,
            id: formValues.usTaxIdNumber,
          },
        ],
        residenceAddress: {
          type: AgentAddressTypeEnum.HOME,
          city: formValues.city,
          country: AddressCountryEnum.UnitedStates,
          streetAddress1: formValues.addressLine,
          stateOrProvince: (formValues.state as unknown) as AddressStateOrProvinceEnum,
          zipOrPostalCode: formValues.zipCode,
        },
      };
      isSuccess = await dispatch(
        updateTaxFormInfo(userDetail?.id!, formValues.id, req),
      );
    } else {
      const req: CreateTaxFormW8BEN = {
        type: CreateTaxFormTypeEnum.W8Ben,
        mailingAddress: {
          type: AgentAddressTypeEnum.MAILING,
          zipOrPostalCode: isMailingAddressSameAsPermanent
            ? formValues.zipCode
            : formValues.mailingZipCode,
          streetAddress1: isMailingAddressSameAsPermanent
            ? formValues.addressLine
            : formValues.mailingAddressLine,
          stateOrProvince: ((isMailingAddressSameAsPermanent
            ? formValues.state
            : formValues.mailingState) as unknown) as AddressStateOrProvinceEnum,
          country: isMailingAddressSameAsPermanent
            ? formValues.country
            : formValues.mailingCountry,
          city: isMailingAddressSameAsPermanent
            ? formValues.city
            : formValues.mailingCity,
        },
        countryOfIncorporation: formValues.countryOfCitizenship
          .value as CreateTaxFormW8BENCountryOfIncorporationEnum,
        foreignTaxId: formValues.foreignTaxId,
        name: formValues.taxPayer,
        nationalIds: [
          {
            type:
              formValues.usTaxIdType ?? NationalIdentificationValueTypeEnum.Ssn,
            id: formValues.usTaxIdNumber,
          },
        ],
        residenceAddress: {
          type: AgentAddressTypeEnum.HOME,
          city: formValues.city,
          country: AddressCountryEnum.UnitedStates,
          streetAddress1: formValues.addressLine,
          stateOrProvince: (formValues.state as unknown) as AddressStateOrProvinceEnum,
          zipOrPostalCode: formValues.zipCode,
        },
        signedAt: DateTime.fromISO(formValues.signDate).toMillis(),
        typedSignature: formValues.signature,
      };
      isSuccess = await dispatch(createTaxForm(userDetail?.id!, req));
    }
    if (isSuccess) {
      onNext();
    }
    setloading(false);
  };

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow text-zen-dark-9 space-y-14'>
        <div className='space-y-7'>
          <h1 className='font-primary-medium text-xl'>
            Substitute Form W-8BEN
          </h1>
          <div className='space-y-8'>
            <h2 className='font-zen-body font-bold'>
              Identification of beneficial owner
            </h2>
            <div>
              <ZenControlledTextInput<
                MSDynamicOnboardingFormData,
                'w8BEN.taxPayer'
              >
                control={control}
                label='Full name'
                placeholder='Full name'
                name='w8BEN.taxPayer'
                rules={{ required: 'Name is required' }}
                shouldUnregister={false}
                isRequired
              />
              <p className='font-zen-body text-sm text-zen-dark-6'>
                As shown on your income tax return
              </p>
            </div>
            <div>
              <ZenControlledSelectInput<
                MSDynamicOnboardingFormData,
                'w8BEN.countryOfCitizenship'
              >
                control={control}
                label='Country'
                name='w8BEN.countryOfCitizenship'
                placeholder='Country'
                options={countries.map((val) => ({ label: val, value: val }))}
                shouldUnregister={false}
                rules={{ required: 'Please select a country' }}
                isRequired
              />
              <p className='font-zen-body text-sm text-zen-dark-6'>
                If you are a corporation, enter the country of incorporation. If
                you are filing for another type of entity, enter the country
                under whose laws the entity is organized. If you are an
                individual, provide your country of residence for tax purposes.
              </p>
            </div>
            <div>
              <ZenControlledTextInput<
                MSDynamicOnboardingFormData,
                'w8BEN.addressLine'
              >
                control={control}
                name='w8BEN.addressLine'
                label='Permanent residence address'
                placeholder='Permanent residence address'
                shouldUnregister={false}
                rules={{ required: 'Please enter an address' }}
                isRequired
              />
              <p className='font-zen-body text-sm text-zen-dark-6'>
                Include apartment or suite no. if applicable
              </p>
            </div>
            <div className='flex flex-col md:flex-row md:items-center space-y-8 md:space-y-0 md:space-x-2.5'>
              <div className='flex-1'>
                <ZenControlledTextInput<
                  MSDynamicOnboardingFormData,
                  'w8BEN.city'
                >
                  label='City'
                  placeholder='City'
                  control={control}
                  name='w8BEN.city'
                  shouldUnregister={false}
                  rules={{ required: 'Please enter a city' }}
                  isRequired
                />
              </div>
              <div className='flex-1'>
                <ZenControlledStateOrProvince<
                  MSDynamicOnboardingFormData,
                  'w8BEN.state'
                >
                  control={control}
                  name='w8BEN.state'
                  setValue={setValue}
                  selectedCountry={country}
                  rules={{ required: 'Please select a state.' }}
                  shouldUnregister={false}
                  isRequired
                />
              </div>
              <div className='flex-1'>
                <ZenControlledTextInput<
                  MSDynamicOnboardingFormData,
                  'w8BEN.zipCode'
                >
                  control={control}
                  label='Zipcode'
                  placeholder='Zipcode'
                  name='w8BEN.zipCode'
                  rules={{
                    required: 'Please enter a zip code',
                    ...getPostalCodeValidation(country),
                  }}
                  shouldUnregister={false}
                  isRequired
                  type='number'
                />
              </div>
            </div>
            <div className='space-y-6'>
              <ZenControlledHTMLSelectInput<
                MSDynamicOnboardingFormData,
                'w8BEN.country'
              >
                control={control}
                label='Country'
                name='w8BEN.country'
                placeholder='Country'
                shouldUnregister={false}
                options={[
                  { label: 'Country', value: '' },
                  ...values(AddressResponseCountryEnum).map((country) => ({
                    label: capitalizeEnum(country),
                    value: country,
                  })),
                ]}
                rules={{ required: 'Please select a country' }}
                isRequired
              />
              <div className='font-zen-body text-sm text-zen-dark-6'>
                <ZenControlledCheckboxInput<
                  MSDynamicOnboardingFormData,
                  'w8BEN.mailingAddressSameAsPermanent'
                >
                  name='w8BEN.mailingAddressSameAsPermanent'
                  shouldUnregister={false}
                  options={[
                    {
                      label:
                        'My mailing address is the same as my permanent residence',
                      value: YesNoType.YES,
                    },
                  ]}
                  control={control}
                  reverse
                />
              </div>
            </div>

            {!mailingAddressSameAsPermanent && (
              <div className='space-y-8'>
                <div>
                  <ZenControlledTextInput<
                    MSDynamicOnboardingFormData,
                    'w8BEN.mailingAddressLine'
                  >
                    control={control}
                    name='w8BEN.mailingAddressLine'
                    label='Mailing address'
                    placeholder='Mailing address'
                    shouldUnregister={false}
                    rules={{ required: 'Please enter an address' }}
                    isRequired
                  />
                  <p className='font-zen-body text-sm text-zen-dark-6'>
                    If different than permanent address (optional)
                  </p>
                </div>
                <div className='flex flex-col md:flex-row md:items-center space-y-8 md:space-y-0 md:space-x-2.5'>
                  <div className='flex-1'>
                    <ZenControlledTextInput<
                      MSDynamicOnboardingFormData,
                      'w8BEN.mailingCity'
                    >
                      label='City'
                      placeholder='City'
                      control={control}
                      name='w8BEN.mailingCity'
                      shouldUnregister={false}
                      rules={{ required: 'Please enter a city' }}
                      isRequired
                    />
                  </div>
                  <div className='flex-1'>
                    <ZenControlledStateOrProvince<
                      MSDynamicOnboardingFormData,
                      'w8BEN.mailingState'
                    >
                      control={control}
                      name='w8BEN.mailingState'
                      setValue={setValue}
                      selectedCountry={mailingCountry}
                      rules={{ required: 'Please select a state.' }}
                      isRequired
                      shouldUnregister={false}
                    />
                  </div>
                  <div className='flex-1'>
                    <ZenControlledTextInput<
                      MSDynamicOnboardingFormData,
                      'w8BEN.mailingZipCode'
                    >
                      control={control}
                      label='Zipcode'
                      placeholder='Zipcode'
                      name='w8BEN.mailingZipCode'
                      rules={{
                        required: 'Please enter a zip code',
                        ...getPostalCodeValidation(mailingCountry),
                      }}
                      shouldUnregister={false}
                      isRequired
                      type='number'
                    />
                  </div>
                </div>
                <ZenControlledHTMLSelectInput<
                  MSDynamicOnboardingFormData,
                  'w8BEN.mailingCountry'
                >
                  control={control}
                  label='Country'
                  name='w8BEN.mailingCountry'
                  placeholder='Country'
                  shouldUnregister={false}
                  options={[
                    { label: 'Country', value: '' },
                    ...values(AddressResponseCountryEnum).map((country) => ({
                      label: capitalizeEnum(country),
                      value: country,
                    })),
                  ]}
                  rules={{ required: 'Please select a country' }}
                  isRequired
                />
              </div>
            )}

            <div className='space-y-2'>
              <ZenControlledMaskTextInput<
                MSDynamicOnboardingFormData,
                'w8BEN.usTaxIdNumber'
              >
                control={control}
                label='US tax ID number'
                placeholder='US tax ID number'
                name='w8BEN.usTaxIdNumber'
                rules={{
                  required: 'Please enter tax id number',
                  ...getIdValidations(AdditionalIdentifications.Tin),
                }}
                maskType={getMaskTypeForIdentificationType(
                  AdditionalIdentifications.Tin,
                )}
                shouldUnregister={false}
                setValue={setValue}
                isRequired
              />
              <p className='font-zen-body text-sm text-zen-dark-6'>
                SSN or ITIN (if required)
              </p>
            </div>
            <div>
              <ZenControlledTextInput<
                MSDynamicOnboardingFormData,
                'w8BEN.foreignTaxId'
              >
                control={control}
                label='Foreign tax ID number'
                placeholder='Foreign tax ID number'
                name='w8BEN.foreignTaxId'
                rules={{
                  required: 'Please enter a foreign tax id number',
                }}
                shouldUnregister={false}
                isRequired
              />
            </div>
          </div>
        </div>
        <div className='font-zen-body text-zen-dark-9 space-y-5'>
          <div className='space-y-2.5'>
            <div className='font-bold'>Certification</div>
            <p className='leading-snug'>
              Under penalties of perjury, I declare that I have examined the
              information on this form and to the best of my knowledge and
              believe it is true, correct, and complete. I further certify under
              penalties of perjury that:
            </p>
          </div>

          <ol className='pl-10 list-disc leading-snug space-y-3'>
            <li>
              I am the individual that is the beneficial owner (or am authorized
              to sign for the individual that is the beneficial owner) of all
              the income to which this form relates or
            </li>
            <li>
              am using this form to document myself as an individual that is an
              owner of a foreign financial institution,
            </li>
            <li>
              {' '}
              The person named on line 1 of this form is not a US person,
            </li>
            <li className='space-y-3'>
              <p>The income to which this form relates is:</p>
              <ul className='pl-2 space-y-2 list-none'>
                <li>
                  a. not effectively connected with the conduct of a trade or
                  business in the United States.
                </li>
                <li>
                  b. effectively connected but is not subject to tax under an
                  applicable income tax treaty, or
                </li>
                <li>
                  c. the partners share of a partnerships effectively connected
                  income,
                </li>
              </ul>
            </li>
            <li>
              The person named on line 1 of this form is a resident of the
              treaty country listed on line 9 of the form (if any) within the
              meaning of the income tax treaty between the United States and
              that country, and
            </li>
            <li>
              For broker transactions or barter exchanges, the beneficial owner
              is an exempt foreign person as defined in the instructions.
            </li>
          </ol>
          <div className='leading-snug'>
            Furthermore, I authorize this form to be provided to any withholding
            agent that has control, receipt, or custody of the income of which I
            am the beneficial owner or any withholding agent that can disburse
            or make payments of the income of which I am the beneficial owner. I
            agree that I will submit a new form within 30 days if any
            certification made on this form becomes incorrect.
          </div>
          <div className='leading-snug'>
            The Internal Revenue Service does not require your consent to any
            provisions of this document other than the certifications required
            to establish your status as a non-US individual and, if applicable,
            obtain a reduced rate of withholding.
          </div>
        </div>
        <div className='space-y-12'>
          <div className='space-y-7'>
            <div>
              {' '}
              <div className='space-y-2'>
                <ZenControlledSignatureInput<
                  MSDynamicOnboardingFormData,
                  'w8BEN.signature'
                >
                  control={control}
                  label='Your Signature'
                  placeholder='Type Full Name'
                  name='w8BEN.signature'
                  rules={{ required: 'Please enter a signature' }}
                  shouldUnregister={false}
                  isRequired
                  readOnly={!!taxFormID}
                />
                <p className='font-zen-body text-sm text-zen-dark-6'>
                  Typing your name acts as your signature and certifies that you
                  have capacity to sign for the person/entity identified on this
                  form
                </p>
              </div>
            </div>
            <div>
              <div className='space-y-2'>
                <ZenControlledDatePickerInput<
                  MSDynamicOnboardingFormData,
                  'w8BEN.signDate'
                >
                  control={control}
                  name='w8BEN.signDate'
                  label='Sign Date'
                  placeholder='mm/dd/yyyy'
                  shouldUnregister={false}
                  isRequired
                  rules={{ required: 'Please pick a sign date' }}
                  readOnly={!!taxFormID}
                />
                <p className='font-zen-body text-sm text-zen-dark-6'>
                  Use mm-dd-yyyy format, e.g., 06-30-2024
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-sm'>
          <div className='flex flex-row items-center py-8 shadow-top-sm justify-center space-x-5'>
            <div className='w-28'>
              <ZenButton
                isFullWidth
                variant='primary-outline'
                type='button'
                label='Previous'
                onClick={onPrevious}
              />
            </div>
            <div className='w-56'>
              <ZenButton
                isFullWidth
                type='submit'
                variant='primary'
                label='Next'
                isSubmitting={loading}
                isDisabled={loading}
                onClick={handleNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withMSDynamicsProgress(MsDynamicOnboardingTaxFormW8Ben);
