import { useCallback } from 'react';
import useAsyncRequest from '../hooks/useAsyncRequest';
import {
  CommissionDocumentControllerApi,
  CommissionDocumentResponse,
} from '../openapi/arrakis';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { AsyncResponse } from '../types';

interface InvoiceFormContainerProps {
  id: string;
  children(
    response: AsyncResponse<CommissionDocumentResponse>,
  ): React.ReactNode;
}

const InvoiceFormContainer: React.FC<InvoiceFormContainerProps> = ({
  id,
  children,
}) => {
  const invoiceResponse = useAsyncRequest(
    'Invoice',
    useCallback(
      () =>
        new CommissionDocumentControllerApi(
          getArrakisConfiguration(),
        ).getCommissionDocumentById(id),
      [id],
    ),
    { data: { id } },
  );

  return <>{children(invoiceResponse)}</>;
};

export default InvoiceFormContainer;
