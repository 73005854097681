import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '../../utils/classUtils';

export interface StepByStepContainerPaginationProps {
  previousText?: string;
  nextText?: string;
  showSkip?: boolean;
  isNextLoading?: boolean;
  onPrevious?(): void;
  onNext?(): void;
  onSkip?(): void;
}

const StepByStepContainerPagination: React.FC<StepByStepContainerPaginationProps> = ({
  previousText,
  nextText,
  showSkip,
  isNextLoading,
  onPrevious,
  onNext,
  onSkip,
}) => (
  <div
    className={cn(
      'flex flex-row items-center py-8 shadow-top-sm',
      nextText && previousText
        ? 'justify-between'
        : nextText
        ? 'justify-end'
        : 'justify-start',
    )}
  >
    {!!previousText && (
      <button
        type='button'
        className='focus:outline-none hover:opacity-70 py-2 w-1/3 text-lg border border-gray-200 bg-white text-gray-400 rounded'
        onClick={onPrevious}
      >
        <span>{previousText}</span>
      </button>
    )}
    {!!showSkip && (
      <button
        className={cn(
          'focus:outline-none hover:opacity-70',
          previousText ? 'mr-4' : 'mx-4',
        )}
        onClick={onSkip}
      >
        <span className='font-primary-regular text-gray-500'>Skip</span>
      </button>
    )}
    {!!nextText && (
      <button
        type='button'
        className={cn(
          'focus:outline-none hover:opacity-70 py-2 w-1/3 text-white text-lg rounded',
          isNextLoading ? 'bg-gray-500' : 'bg-primary',
        )}
        onClick={onNext}
        disabled={isNextLoading}
      >
        {isNextLoading && (
          <FontAwesomeIcon
            icon={faArrowsRotate}
            className='animate-spin mr-2 text-base'
          />
        )}
        <span>{nextText}</span>
      </button>
    )}
  </div>
);

export default StepByStepContainerPagination;
