import React from 'react';
import { times } from 'lodash';
import { Virtuoso } from 'react-virtuoso';
import { ImageContent } from '../../../../types';
import ZenRenderPDFItem from './ZenRenderPDFItem';

interface RenderPDFProps {
  pdfImages: ImageContent[];
  width: number;
  selectedPages?: ImageContent[];
  setSelectedPages?(pages: ImageContent[]): void;
  className?: string;
}

const RenderPDF: React.FC<RenderPDFProps> = ({
  pdfImages,
  width,
  selectedPages,
  setSelectedPages,
  className,
}) => {
  return (
    <Virtuoso
      className={className}
      data={times(pdfImages.length!)}
      itemContent={(pageIndex) => (
        <ZenRenderPDFItem
          pageIndex={pageIndex}
          pdfImages={pdfImages}
          width={width}
          selectedPages={selectedPages}
          setSelectedPages={setSelectedPages}
        />
      )}
    />
  );
};

export default RenderPDF;
