import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import RealLogo from '../assets/img/new-rezen-black-logo.svg';
import WebhookEventSelectInput from '../components/Webhook/WebhookEventSelectInput';
import ZenControlledTextAreaInput from '../components/Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenCopyToClipBoard from '../components/Zen/ZenCopyToClipBoard';
import ZenResourceContainer from '../components/Zen/ZenResourceContainer';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenStickyBottomPagination from '../components/Zen/ZenStickyBottomPagination';
import ZenAgentOnboardingLayout from '../components/ZenAgentOnboardingLayout';
import {
  CreateWebhookRequest,
  CreateWebhookRequestEventNamesEnum,
  SubscriptionDtoEventNameEnum,
  UpdateWebhookRequest,
} from '../openapi/plutus';
import {
  createWebhook,
  editWebhook,
  fetchWebhookDetails,
  fetchWebhookEvents,
} from '../slices/WebhookSlice';
import { RootState } from '../types';
import {
  getEventsFromWebhook,
  WebhookEndpointExample,
  WebhookEvent,
  WebhookEventGroup,
} from '../utils/WebhookUtils';

interface Params {
  webhookId: string;
}

interface FormData {
  endpointURL: string;
  endpointName: string;
  description?: string;
}

export const getSelectedEvents = (eventsList: WebhookEventGroup[]) => {
  const selectedEvents: WebhookEvent[] = eventsList?.flatMap(
    (item: WebhookEventGroup) =>
      item?.events?.filter((event: WebhookEvent) => event.isChecked),
  );
  return selectedEvents;
};

const CreateWebhookRoute: React.FC = () => {
  const {
    auth: { userDetail },
    webhooks: { webhookEvents, webhookDetails },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const history = useHistory();
  const { webhookId } = useParams<Params>();

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const [eventsList, setEventsList] = useState<WebhookEventGroup[]>([]);
  const [eventsToDisplay, setEventsToDisplay] = useState<WebhookEvent[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [endpointURL, endpointName] = watch(['endpointURL', 'endpointName']);
  const disableAddEndpointButton: boolean =
    isEmpty(endpointURL) || isEmpty(endpointName) || isEmpty(eventsToDisplay);

  // Toggles individual event
  const handleToggleEvent = (
    eventEnum: CreateWebhookRequestEventNamesEnum,
    removeFlag: boolean = false,
  ) => {
    const updatedEventsList: WebhookEventGroup[] = eventsList.map((group) => {
      const updatedGroup = { ...group };
      updatedGroup.events = group.events.map((event) => {
        if (event.value === eventEnum) {
          event.isChecked = !event.isChecked;
        }
        return event;
      });
      updatedGroup.isGroupSelected = group.events.every(
        (event) => event.isChecked,
      );
      return updatedGroup;
    });

    setEventsList(updatedEventsList);

    if (removeFlag) {
      setEventsToDisplay(getSelectedEvents(updatedEventsList));
    }
  };

  // Toggles entire group of events
  const handleToggleGroup = (groupIndex: number, toggle: boolean) => {
    if (groupIndex < 0 && groupIndex >= eventsList.length) {
      return;
    }

    const updatedEventsList: WebhookEventGroup[] = [...eventsList];

    updatedEventsList[groupIndex] = {
      ...updatedEventsList[groupIndex],
      isGroupSelected: toggle,
      events: updatedEventsList[groupIndex].events.map((event) => ({
        ...event,
        isChecked: toggle,
      })),
    };

    setEventsList(updatedEventsList);
  };

  // Toggles all the events
  const handleToggleAll = (toggle: boolean) => {
    const updatedEventsList: WebhookEventGroup[] = eventsList.map(
      (eventGroup) => ({
        ...eventGroup,
        isGroupSelected: toggle,
        events: eventGroup.events.map((event) => ({
          ...event,
          isChecked: toggle,
        })),
      }),
    );

    setEventsList(updatedEventsList);
  };

  const handleEventSubmit = () => {
    setEventsToDisplay(getSelectedEvents(eventsList));
  };

  const onSubmit = async (data: FormData) => {
    const eventNames = getSelectedEvents(eventsList)?.map((item) => {
      return item?.value;
    });

    const requestBody = {
      name: data.endpointName,
      description: data?.description,
      url: data.endpointURL,
      eventNames: eventNames,
    };

    if (isEdit) {
      await dispatch(
        editWebhook(
          webhookId,
          (requestBody as unknown) as UpdateWebhookRequest,
        ),
      );
    } else {
      await dispatch(createWebhook(requestBody as CreateWebhookRequest));
    }

    history.push(`/people/${userDetail?.id}/security-settings`);
  };

  useEffect(() => {
    setIsAllSelected(eventsList.every((group) => group.isGroupSelected));
  }, [eventsList]);

  useEffect(() => {
    if (!eventsList.length) {
      setEventsList(getEventsFromWebhook(webhookEvents));
    }
  }, [webhookEvents, eventsList.length]);

  useEffect(() => {
    if (isEdit && webhookDetails) {
      setValue('endpointName', webhookDetails?.name!);
      setValue('endpointURL', webhookDetails?.url!);
      setValue('description', webhookDetails?.description);

      if (!!webhookDetails.subscriptions?.length) {
        const eventNames = webhookDetails.subscriptions.map(
          (subscription) => subscription.eventName,
        );

        const updatedEventsList = eventsList.map((eventGroup) => ({
          ...eventGroup,
          events: eventGroup.events.map((event) => ({
            ...event,
            isChecked: eventNames.includes(
              (event.value as unknown) as SubscriptionDtoEventNameEnum,
            ),
          })),
        }));

        setEventsList(updatedEventsList);
        setEventsToDisplay(getSelectedEvents(updatedEventsList));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, setValue, webhookDetails]);

  useEffect(() => {
    if (webhookId) {
      setIsEdit(true);

      if (!isEmpty(webhookEvents.events)) {
        dispatch(fetchWebhookDetails(webhookId));
      }
    }
  }, [dispatch, webhookId, webhookEvents]);

  useEffect(() => {
    dispatch(fetchWebhookEvents());
  }, [dispatch, webhookId]);

  return (
    <ZenRoute title={`${isEdit ? 'Edit' : 'Add'} Webhook Endpoint`}>
      <ZenResourceContainer
        isEmpty={false}
        loading={isEdit && isEmpty(webhookDetails)}
        resourceName='Webhook'
      >
        <ZenAgentOnboardingLayout
          title={`${isEdit ? 'Edit' : 'Add'} Webhook Endpoint`}
          onClose={() =>
            history.push(`/people/${userDetail?.id}/security-settings`)
          }
          modalTitle={`Cancel ${isEdit ? 'Editing' : 'Add'} Webhook Endpoint?`}
          modalSubtitle='The information won’t be saved and you’ll go back to the the Security Settings'
          modalSubmitText='Cancel'
          LeftHeaderComponent={
            <img src={RealLogo} className='h-7' alt='Logo' />
          }
          hideLogout
        >
          <div className='grid lg:grid-cols-12 sm:grid-cols-2 xl:grid-cols-12'>
            <div className='h-[calc(100vh_-_80px)] scrollbar overflow-auto p-6 pb-0 col-span-6 sm:relative'>
              <p className='text-zen-dark-9 text-xl font-zen-body font-medium'>
                {`${isEdit ? 'Edit' : 'Add An'} Endpoint`}
              </p>
              <p className=' text-zen-dark-8 mt-3 font-zen-body font-normal'>
                {`${
                  isEdit ? 'Edit' : 'Set up'
                } your webhook endpoint to receive live events from Real
              reZen.`}
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex flex-col gap-5 mt-6'>
                  <ZenControlledTextInput<FormData, 'endpointURL'>
                    control={control}
                    label='Endpoint URL'
                    name='endpointURL'
                    shouldUnregister={false}
                    placeholder='Add Link Address'
                    rules={{
                      required: 'Endpoint URL is Required',
                      validate: (value) => {
                        try {
                          new URL(value);
                          return true;
                        } catch (error) {
                          return 'Please enter a valid URL';
                        }
                      },
                    }}
                    isRequired
                  />
                  <ZenControlledTextInput<FormData, 'endpointName'>
                    control={control}
                    label='Endpoint name'
                    name='endpointName'
                    shouldUnregister={false}
                    placeholder='Enter Name'
                    rules={{
                      required: 'Endpoint Name is Required',
                    }}
                    isRequired
                  />
                  <ZenControlledTextAreaInput<FormData, 'description'>
                    control={control}
                    label='Description'
                    placeholder='Enter Description'
                    subLabel='(optional)'
                    name='description'
                    rows={3}
                  />
                  <WebhookEventSelectInput
                    name='events'
                    placeholder='Select Events to Add'
                    eventsList={eventsList}
                    eventsToDisplay={eventsToDisplay}
                    isAllSelected={isAllSelected}
                    handleToggleEvent={handleToggleEvent}
                    handleToggleGroup={handleToggleGroup}
                    handleToggleAll={handleToggleAll}
                    handleEventSubmit={handleEventSubmit}
                  />
                  <div className='sticky bottom-0 2xl:absolute 2xl:bottom-0 2xl:w-full'>
                    <ZenStickyBottomPagination
                      previousButtonText='Cancel'
                      nextButtonText={`${isEdit ? 'Edit' : 'Add'} Endpoint`}
                      isSubmitting={isSubmitting}
                      isNextButtonDisabled={disableAddEndpointButton}
                      onNext={() => onSubmit}
                      nextButtonType='submit'
                      onPrevious={() =>
                        history.push(
                          `/people/${userDetail?.id}/security-settings`,
                        )
                      }
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className='h-[calc(100vh_-_80px)] scrollbar overflow-auto bg-regent-100 col-span-6'>
              <div className='border-b p-4 flex flex-row justify-between items-center mt-2 mb-4'>
                <p className='text-xl font-zen-body text-dark font-medium'>
                  Sample endpoint
                </p>
                <ZenCopyToClipBoard
                  label='Copy'
                  value={WebhookEndpointExample.toString()
                    .split(',')
                    .join(',\n')}
                  variant='default'
                  textStyle='text-dark'
                />
              </div>
              {WebhookEndpointExample.map((line, index) => (
                <div
                  key={index.toString()}
                  className='text-zen-dark-8 text-sm font-zen-body px-4 font-normal flex'
                >
                  <p className='text-zen-dark-12 mr-5 mb-3.5 w-4 text-right'>
                    {index + 1}
                  </p>
                  {line}
                </div>
              ))}
            </div>
          </div>
        </ZenAgentOnboardingLayout>
      </ZenResourceContainer>
    </ZenRoute>
  );
};

export default CreateWebhookRoute;
