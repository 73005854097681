import {
  faArrowUpRightFromSquare,
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flatten } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import {
  MoneyValue,
  TransactionBuilderControllerApi,
  TransactionBuilderResponse,
  TransactionControllerApi,
  TransactionLiteResponse,
  TransactionResponseCountryEnum,
} from '../../../openapi/arrakis';
import { fetchChecklistProgressById } from '../../../slices/CheckListSlice';
import { deleteDraft } from '../../../slices/TransactionSlice';
import { fetchAgentsInfo } from '../../../slices/UserIdsSlice';
import { AppDispatch, EnumMap, RootState, TabsVariant } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { isListingNotActionable } from '../../../utils/TransactionUtils';
import Hover from '../../Hover';
import IconButton from '../../IconButton';
import ZenViewActionButtonCell from '../../table/Cells/ZenViewActionButtonCell';
import ZenResourceIndexContainer from '../Containers/ZenResourceIndexContainer';
import ZenSimpleConfirmationModal from '../Modal/ZenSimpleConfirmationModal';
import ZenRouterTabs, { ZenTab } from '../Tab/ZenRouterTabs';
import ZenAddressCell from '../Table/Cell/ZenAddressCell';
import ZenDateCell from '../Table/Cell/ZenDateCell';
import ZenTransactionTypeCell from '../Table/Cell/ZenTransactionTypeCell';
import ZenAgentsInfoPopper from '../Transaction/ZenAgentsInfoPopper';
import ZenChecklistPopper from '../Transaction/ZenChecklistPopper';
import ZenJourneyPopper from '../Transaction/ZenJourneyPopper';
import ZenTransactionCodeCell from '../Transaction/ZenTransactionCodeCell';
import ZenTransactionStatus from '../Transaction/ZenTransactionStatus';
import ZenRoute from '../ZenRoute';

interface ZenMyListingsProps {
  agentId: string;
  tabVariant?: TabsVariant;
  search?: string;
}

export const columns: Array<Column<TransactionLiteResponse>> = [
  {
    Header: '',
    accessor: 'id',
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, string>) => (
      <Link to={`/listings/${original.id}`}>
        <ZenViewActionButtonCell />
      </Link>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Listing Code',
    accessor: 'code',
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, string>) => (
      <ZenTransactionCodeCell
        linkTo={`/listings/${original.id}`}
        transaction={original}
        hideInvoice
      />
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Link',
    accessor: 'inContractTransactionId',
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, string>) => {
      const { inContractTransactionId } = original;
      if (isListingNotActionable(original!) && !!inContractTransactionId) {
        return (
          <Link to={`/transactions/${inContractTransactionId}/detail`}>
            <IconButton
              buttonStyle='bg-primary-blue rounded-lg'
              variant='none'
              leftIcon={
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className='m-1 text-base text-white'
                  title='Link'
                />
              }
              toolTipText='View Associated Transaction'
            />
          </Link>
        );
      }
      return null;
    },
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'transactionType',
    Cell: ({ value }) => <ZenTransactionTypeCell dealType={value!} />,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Address',
    accessor: (d) => d['address']?.street,
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, string>) => (
      <Link to={`/listings/${original.id}`}>
        <ZenAddressCell address={original.address} />
      </Link>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'List Price',
    accessor: 'price',
    id: 'price',
    Cell: ({ row: { original } }: CellProps<TransactionLiteResponse>) => (
      <Link to={`/listings/${original.id}`}>
        {displayAmount(original.price!, { hideCurrency: true })}
        <div className='text-sm	text-zen-gray-4'>{original.price?.currency}</div>
      </Link>
    ),
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Status',
    accessor: ({ lifecycleState }) => lifecycleState?.state,
    id: 'lifecycleState',
    Cell: ({
      value,
      row: { original },
    }: React.PropsWithChildren<CellProps<TransactionLiteResponse>>) => (
      <ZenTransactionStatus
        status={value}
        country={
          (original.country as unknown) as TransactionResponseCountryEnum
        }
      />
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Listing Date',
    accessor: 'listingDate',
    Cell: ({ value }) => <ZenDateCell date={value!} />,
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Expiration Date',
    accessor: 'listingExpirationDate',
    Cell: ({ value }) => <ZenDateCell date={value!} />,
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Checklist Complete',
    accessor: 'checklistId',
    Cell: ({ row: { original } }) => (
      <ZenChecklistPopper
        transactionId={original.id}
        checklistId={original.checklistId}
        isListing={original.listing}
      />
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Road To Success',
    accessor: 'journeyId',
    Cell: ({ row: { original } }) => (
      <ZenJourneyPopper
        transactionId={original.id}
        journeyId={original.journeyId}
        isListing={original.listing}
      />
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Agents',
    accessor: 'participantYentaIds',
    Cell: ({ value, row: { original } }) => (
      <ZenAgentsInfoPopper
        participants={value || []}
        represents={original.transactionOwner?.represents}
      />
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
];

export const draftColumns: Array<Column<TransactionBuilderResponse>> = [
  {
    Header: 'Type',
    accessor: 'dealType',
    Cell: ({ value }) =>
      value ? <ZenTransactionTypeCell dealType={value!} /> : '--',
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Address',
    accessor: (d) => d['address']?.street,
    Cell: ({
      row: { original },
    }: CellProps<TransactionBuilderResponse, string>) => (
      <Link to={`/listing/create/${original.id}`}>
        <ZenAddressCell
          address={original.address}
          placeholder={<p className='text-gray-500'>No Address</p>}
        />
      </Link>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'List Price',
    accessor: 'salePrice',
    id: 'price',
    Cell: ({ value }) => (
      <div>
        {value ? (
          <div>
            {displayAmount(value as MoneyValue, {
              hideCurrency: true,
            })}
            <div className='text-sm	text-zen-gray-4'>{value.currency}</div>
          </div>
        ) : (
          '--'
        )}
      </div>
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Listing Date',
    accessor: 'listingDate',
    Cell: ({ value }) => (value ? <ZenDateCell date={value!} /> : '--'),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Expiration Date',
    accessor: 'listingExpirationDate',
    Cell: ({ value }) => (value ? <ZenDateCell date={value!} /> : '--'),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Checklist Complete',
    accessor: 'checklistId',
    Cell: ({ row: { original }, value }) => (
      <ZenChecklistPopper checklistId={value} transactionId={original.id} />
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Journey Progress',
    accessor: 'journeyId',
    Cell: ({ row: { original }, value }) => (
      <ZenJourneyPopper journeyId={value} transactionId={original.id} />
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Agents',
    accessor: 'allCommissionRecipient',
    Cell: ({ value, row: { original } }) => (
      <ZenAgentsInfoPopper
        participants={
          // @ts-ignore
          value?.filter((v) => !!v.agentId).map((v) => v.agentId) || []
        }
        represents={original.agentsInfo?.representationType}
      />
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
];

const ListingSortByTypeEnum: EnumMap<
  string,
  'ESCROW_CLOSING_DATE' | 'ACTUAL_CLOSING_DATE' | 'PRICE' | 'EXPIRATION_DATE'
> = {
  listingExpirationDate: 'EXPIRATION_DATE',
  skySlopeEscrowClosingDate: 'ESCROW_CLOSING_DATE',
  skySlopeActualClosingDate: 'ACTUAL_CLOSING_DATE',
  price: 'PRICE',
};

const ListingSortDirectionTypeEnum: EnumMap<string, 'ASC' | 'DESC'> = {
  asc: 'ASC',
  desc: 'DESC',
};

const ZenMyListings: React.FC<ZenMyListingsProps> = ({ agentId, search }) => {
  const dispatch: AppDispatch = useDispatch();

  const {
    userIds: { agentById },
    transaction: { listingCountByLifecycleGroup },
  } = useSelector((state: RootState) => state);

  const [transactionBuilderId, setTransactionBuilderId] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const draftColumnsWithActions: Array<
    Column<TransactionBuilderResponse>
  > = useMemo(() => {
    const dynamicDraftColumns: Array<Column<TransactionBuilderResponse>> = [
      {
        Header: 'Listing Code',
        accessor: 'id',
        Cell: ({
          row: { original },
        }: CellProps<TransactionBuilderResponse, string>) => {
          return (
            <>
              {original.id ? (
                <div className='flex items-center space-x-1'>
                  <Link to={`/listing/create/${original.id}`}>
                    <div className='text-sm flex flex-col'>
                      <p className='whitespace-nowrap'>XXX-XXX-XXX-XXXX</p>
                      <p className='text-error'>[Draft]</p>
                    </div>
                  </Link>
                  <Hover
                    hoverComponent={
                      <div className='text-dark'>Discard Draft</div>
                    }
                    config={{ trigger: 'hover', placement: 'top' }}
                  >
                    <div
                      className='cursor-pointer'
                      onClick={() => setTransactionBuilderId(original.id)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        className='text-error hover:bg-red-100 rounded-full p-0.5'
                      />
                    </div>
                  </Hover>
                </div>
              ) : (
                'N/A'
              )}
            </>
          );
        },
        cardColSize: 6,
        disableFilters: true,
        disableSortBy: true,
      },
    ];

    return [...dynamicDraftColumns, ...draftColumns];
  }, []);

  const handleDeleteDraftListing = useCallback(
    async (id: string) => {
      setIsSubmitting(true);
      await dispatch(deleteDraft(id, agentId, 'LISTING'));
      setTransactionBuilderId(undefined);
      setIsSubmitting(false);
    },
    [agentId, dispatch],
  );

  const getChecklistProgress = useCallback(
    async (checklistIds: string[]) => {
      if (!!checklistIds?.length) {
        await dispatch(fetchChecklistProgressById(checklistIds));
      }
    },
    [dispatch],
  );

  const getAllAgentsDetails = useCallback(
    async (userIds: string[]) => {
      const agentIdsToFetch = userIds.filter((id) => !agentById[id]);
      await dispatch(fetchAgentsInfo(agentIdsToFetch));
    },
    [agentById, dispatch],
  );

  const tabs: ZenTab[] = useMemo(() => {
    const array = [
      {
        label: `Active (${listingCountByLifecycleGroup.OPEN})`,
        path: '/listings',
        exact: true,
        TabComponent: () => (
          <ZenRoute title='Active Listings'>
            <div className='mt-4'>
              <ZenResourceIndexContainer<any>
                columns={columns}
                resourceName='listing'
                hideFilters
                search={search}
                pageSize={10}
                initialSort={{ listingExpirationDate: 'asc' }}
                fetchData={async (req, cancelToken) => {
                  const sortKey = Object.keys(req.sortBy || {})[0];
                  const sortType = Object.values(req.sortBy || {})[0];
                  const { data } = await new TransactionControllerApi(
                    getArrakisConfiguration(),
                  ).getListingTransactionsByStateGroupPaginated(
                    agentId,
                    'OPEN',
                    req.page,
                    req.pageSize,
                    req.search,
                    ListingSortByTypeEnum[sortKey],
                    ListingSortDirectionTypeEnum[sortType!],
                    undefined,
                    { cancelToken },
                  );

                  const agentIds =
                    flatten(
                      data.transactions?.map(
                        (builder) => builder.participantYentaIds!,
                      ),
                    ) || [];

                  await getAllAgentsDetails(agentIds);

                  const checklistIds = flatten(
                    data.transactions?.map((builder) => [
                      builder.checklistId!,
                      builder.journeyId!,
                    ]),
                  ).filter((id) => !!id);

                  await getChecklistProgress(checklistIds);

                  return {
                    data: data.transactions!,
                    total: data.totalCount!,
                  };
                }}
                stickyHeader
              />
            </div>
          </ZenRoute>
        ),
      },
      {
        label: `Closed (${listingCountByLifecycleGroup.CLOSED})`,
        path: '/listings/closed',
        exact: true,
        TabComponent: () => (
          <ZenRoute title='Closed Listings'>
            <div className='mt-4'>
              <ZenResourceIndexContainer<any>
                columns={columns}
                resourceName='listing'
                search={search}
                hideFilters
                pageSize={10}
                initialSort={{ listingExpirationDate: 'asc' }}
                fetchData={async (req, cancelToken) => {
                  const sortKey = Object.keys(req.sortBy || {})[0];
                  const sortType = Object.values(req.sortBy || {})[0];
                  const { data } = await new TransactionControllerApi(
                    getArrakisConfiguration(),
                  ).getListingTransactionsByStateGroupPaginated(
                    agentId,
                    'CLOSED',
                    req.page,
                    req.pageSize,
                    req.search,
                    ListingSortByTypeEnum[sortKey],
                    ListingSortDirectionTypeEnum[sortType!],
                    undefined,
                    { cancelToken },
                  );

                  const agentIds =
                    flatten(
                      data.transactions?.map(
                        (builder) => builder.participantYentaIds!,
                      ),
                    ) || [];

                  await getAllAgentsDetails(agentIds);

                  const checklistIds = flatten(
                    data.transactions?.map((builder) => [
                      builder.checklistId!,
                      builder.journeyId!,
                    ]),
                  ).filter((id) => !!id);

                  await getChecklistProgress(checklistIds);

                  return {
                    data: data.transactions!,
                    total: data.totalCount!,
                  };
                }}
                stickyHeader
              />
            </div>
          </ZenRoute>
        ),
      },
      {
        label: `Terminated (${listingCountByLifecycleGroup.TERMINATED})`,
        path: '/listings/terminated',
        exact: true,
        TabComponent: () => (
          <ZenRoute title='Terminated Listings'>
            <div className='mt-4'>
              <ZenResourceIndexContainer<any>
                columns={columns}
                hiddenColumns={['inContractTransactionId']}
                resourceName='listing'
                search={search}
                hideFilters
                pageSize={10}
                initialSort={{ listingExpirationDate: 'asc' }}
                fetchData={async (req, cancelToken) => {
                  const sortKey = Object.keys(req.sortBy || {})[0];
                  const sortType = Object.values(req.sortBy || {})[0];
                  const { data } = await new TransactionControllerApi(
                    getArrakisConfiguration(),
                  ).getListingTransactionsByStateGroupPaginated(
                    agentId,
                    'TERMINATED',
                    req.page,
                    req.pageSize,
                    req.search,
                    ListingSortByTypeEnum[sortKey],
                    ListingSortDirectionTypeEnum[sortType!],
                    undefined,
                    { cancelToken },
                  );

                  const agentIds =
                    flatten(
                      data.transactions?.map(
                        (builder) => builder.participantYentaIds!,
                      ),
                    ) || [];

                  await getAllAgentsDetails(agentIds);

                  const checklistIds = flatten(
                    data.transactions?.map((builder) => [
                      builder.checklistId!,
                      builder.journeyId!,
                    ]),
                  ).filter((id) => !!id);

                  await getChecklistProgress(checklistIds);

                  return {
                    data: data.transactions!,
                    total: data.totalCount!,
                  };
                }}
                stickyHeader
              />
            </div>
          </ZenRoute>
        ),
      },
      {
        label: `Drafts (${listingCountByLifecycleGroup.DRAFT})`,
        path: '/listings/draft',
        exact: true,
        TabComponent: () => (
          <ZenRoute title='Draft Listings'>
            <div className='mt-4'>
              <ZenResourceIndexContainer<any>
                columns={draftColumnsWithActions}
                resourceName='listing'
                hiddenColumns={['closed']}
                hideFilters
                pageSize={10}
                initialSort={{ listingExpirationDate: 'asc' }}
                fetchData={async ({ page, pageSize }, cancelToken) => {
                  const { data } = await new TransactionBuilderControllerApi(
                    getArrakisConfiguration(),
                  ).getTransactionBuildersPaginated(
                    pageSize,
                    page,
                    agentId,
                    'LISTING',
                    undefined,
                    {
                      cancelToken,
                    },
                  );

                  const agentIds = flatten(
                    (data?.results || []).map((builder) =>
                      builder.allCommissionRecipient
                        // @ts-ignore
                        ?.filter((participant) => !!participant.agentId)
                        // @ts-ignore
                        ?.map((participant) => participant.agentId),
                    ),
                  );

                  await getAllAgentsDetails(agentIds);

                  const checklistIds = flatten(
                    (data?.results || []).map((builder) => [
                      builder.checklistId!,
                      builder.journeyId!,
                    ]),
                  ).filter((id) => !!id);

                  await getChecklistProgress(checklistIds);

                  return {
                    data: data.results || [],
                    total: listingCountByLifecycleGroup.DRAFT,
                  };
                }}
                stickyHeader
              />
            </div>
          </ZenRoute>
        ),
      },
    ];
    return array;
  }, [
    listingCountByLifecycleGroup.OPEN,
    listingCountByLifecycleGroup.CLOSED,
    listingCountByLifecycleGroup.TERMINATED,
    listingCountByLifecycleGroup.DRAFT,
    search,
    agentId,
    getAllAgentsDetails,
    getChecklistProgress,
    draftColumnsWithActions,
  ]);

  return (
    <div className='mt-4'>
      <ZenRouterTabs tabs={tabs} />
      <ZenSimpleConfirmationModal
        isOpen={!!transactionBuilderId}
        variant='danger'
        title='Discard Draft Listing?'
        subtitle='This draft listing will be permanently deleted. Are you sure?'
        customIcon={<FontAwesomeIcon icon={faTriangleExclamation} size='lg' />}
        onClose={() => setTransactionBuilderId(undefined)}
        onConfirm={() => handleDeleteDraftListing(transactionBuilderId!)}
        isSubmitting={isSubmitting}
        confirmButtonText='Yes'
      />
    </div>
  );
};

export default ZenMyListings;
