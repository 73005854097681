import React, { useState } from 'react';
import {
  DEFAULT_SHOWN_PROPERTY_POSTERS_COUNT,
  DEFAULT_SHOWN_PROPERTY_POSTERS_COUNT_ON_MOBILE,
} from '../../../../../constants/LeoConstants';
import { ListingPosterItem } from '../../../../../utils/LeoUtils';
import { useScreenSize } from '../../../../WeathManagement/utils';
import LeoSocialShareableMobileModal from '../Modal/LeoSocialShareableMobileModal';
import LeoSocialShareablesModal from '../Modal/LeoSocialShareableModal';
import { cn } from '../../../../../utils/classUtils';
import LeoSocialShareabllesSmallModal from '../Modal/LeoSocialShareabllesSmallModal';
import ShareablePagination from './ShareablePagination';
import LeoSocialShareablesMobileCard from './LeoSocialShareablesMobileCard';
import LeoSocialShareablesCard from './LeoSocialShareablesCard';

type LeoSocialShareablesProps = {
  shareables: ListingPosterItem[];
  isShowingHistory: boolean;
};

const LeoSocialShareables: React.FC<LeoSocialShareablesProps> = ({
  shareables,
}) => {
  const { width } = useScreenSize();
  const [activeShareableIndex, setActiveShareableIndex] = useState<number>(0);
  const [showShareablesModal, setShowShareablesModal] = React.useState<boolean>(
    false,
  );
  const [page, setPage] = React.useState<number>(0);

  const pageSize =
    width <= 500
      ? DEFAULT_SHOWN_PROPERTY_POSTERS_COUNT_ON_MOBILE
      : DEFAULT_SHOWN_PROPERTY_POSTERS_COUNT;
  const totalCount = shareables ? shareables.length : 0;
  const lastPage = Math.ceil(totalCount / pageSize);

  if (width <= 500) {
    return (
      <div className='pt-3'>
        <p className='text-base font-semibold text-white pb-3'>
          Checkout these posters for your property
        </p>
        <div className='flex flex-wrap items-center gap-3'>
          {shareables
            .slice(page * pageSize, page * pageSize + pageSize)
            .map(({ posterLink, listingDetails: { id } }, ind) => (
              <div key={id} className=''>
                <img
                  id='poster-image'
                  src={posterLink}
                  className={cn(
                    'rounded-lg cursor-pointer',
                    width > 320 ? 'w-32 h-32' : 'w-24 h-24',
                  )}
                  alt='poster'
                  onClick={() => {
                    setActiveShareableIndex(ind);
                    setShowShareablesModal(true);
                  }}
                />
              </div>
            ))}
        </div>
        <ShareablePagination
          totalCount={totalCount}
          pageSize={pageSize}
          lastPage={lastPage}
          goToPage={(page) => setPage(page)}
          currentPage={page}
        />
        {showShareablesModal && (
          <LeoSocialShareableMobileModal
            closeModal={() => {
              setShowShareablesModal(false);
            }}
            shareable={shareables[activeShareableIndex]}
          />
        )}
      </div>
    );
  }

  return (
    <div className='pt-3'>
      <p className='text-base font-semibold text-white pb-3'>
        Checkout these posters for your property
      </p>
      <div className='flex flex-wrap items-center gap-5'>
        {shareables
          .slice(page * pageSize, page * pageSize + pageSize)
          .map(({ posterLink, listingDetails: { id } }, ind) =>
            width <= 768 ? (
              <LeoSocialShareablesMobileCard
                posterLink={posterLink}
                key={id}
                openModal={() => {
                  setActiveShareableIndex(ind);
                  setShowShareablesModal(true);
                }}
              />
            ) : (
              <LeoSocialShareablesCard
                posterLink={posterLink}
                key={id}
                openModal={() => {
                  setActiveShareableIndex(ind);
                  setShowShareablesModal(true);
                }}
              />
            ),
          )}
      </div>

      <ShareablePagination
        totalCount={totalCount}
        pageSize={pageSize}
        lastPage={lastPage}
        goToPage={(page) => setPage(page)}
        currentPage={page}
      />

      <div>
        {showShareablesModal &&
          (width < 768 ? (
            <LeoSocialShareabllesSmallModal
              closeModal={() => {
                setShowShareablesModal(false);
              }}
              shareable={shareables[activeShareableIndex]}
            />
          ) : (
            <LeoSocialShareablesModal
              closeModal={() => {
                setShowShareablesModal(false);
              }}
              shareables={shareables}
              activeShareableIndex={activeShareableIndex}
            />
          ))}
      </div>
    </div>
  );
};

export default LeoSocialShareables;
