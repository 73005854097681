import {
  faBullhorn,
  faCrown,
  faHouse,
  faScreenUsers,
  faStarChristmas,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  TeamPreviewResponse,
  TeamResponseTypeEnum,
} from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { teamTypeToDisplayName } from '../../../utils/TeamHelper';

interface ZenTeamsTeamTypeSectionProps {
  team?: TeamPreviewResponse;
}

const ZenTeamsTeamTypeSection: React.FC<ZenTeamsTeamTypeSectionProps> = ({
  team,
}) => {
  const { type } = team as { type: TeamResponseTypeEnum };

  const iconVariantClassNameMap: EnumMap<TeamResponseTypeEnum, string> = {
    [TeamResponseTypeEnum.Group]: 'text-purple-600',
    [TeamResponseTypeEnum.Domestic]: 'text-green-600',
    [TeamResponseTypeEnum.Normal]: 'text-rezen-blue-600',
    [TeamResponseTypeEnum.Platinum]: 'text-yellow-900 ',
    [TeamResponseTypeEnum.Pro]: 'text-sangria-900',
  };

  const iconVariantMap: EnumMap<TeamResponseTypeEnum, React.ReactElement> = {
    [TeamResponseTypeEnum.Group]: <FontAwesomeIcon icon={faBullhorn} />,
    [TeamResponseTypeEnum.Domestic]: <FontAwesomeIcon icon={faHouse} />,
    [TeamResponseTypeEnum.Normal]: <FontAwesomeIcon icon={faScreenUsers} />,
    [TeamResponseTypeEnum.Platinum]: <FontAwesomeIcon icon={faCrown} />,
    [TeamResponseTypeEnum.Pro]: <FontAwesomeIcon icon={faStarChristmas} />,
  };

  return (
    <div className='flex items-center'>
      <Link to={`/teams/${team?.id}`}>
        <span className='text-primary-dark font-inter font-medium text-sm'>
          {team?.name}
        </span>
      </Link>
      <span className='border-r border-grey-400 mx-1 w-1 h-4' />
      <div
        className={cn(
          'flex flex-row items-center justify-center gap-1 ml-0.5',
          iconVariantClassNameMap[type],
        )}
      >
        <span className='mt-0.5'>{iconVariantMap[type]}</span>
        <span className='font-inter font-medium text-xs'>
          {teamTypeToDisplayName(type)}
        </span>
      </div>
    </div>
  );
};

export default ZenTeamsTeamTypeSection;
