import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'react-hook-form-v7';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenAlert from '../components/Zen/ZenAlert';
import ZenButton from '../components/Zen/ZenButton';

interface ZenPasswordGuardProps {
  password: string;
  setAuthenticated(value: boolean): void;
}

interface FormData {
  password: string;
  submit: string;
}

const ZenPasswordGuard: React.FC<ZenPasswordGuardProps> = ({
  password,
  setAuthenticated,
}) => {
  const {
    control,
    handleSubmit,
    clearErrors,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormData>();

  const onSubmit = (value: FormData) => {
    if (value.password === password) {
      return setAuthenticated(true);
    } else {
      setError('submit', {
        message: 'Wrong Password',
      });
      return setAuthenticated(false);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='container mx-auto mt-20 flex justify-center'>
        <div className='bg-white lg:w-1/3 p-10 border-2 rounded border-gray-100'>
          <p className='font-zen-body font-semibold text-3xl text-center'>
            Restricted Area
          </p>
          {errors.submit && (
            <div className='mt-3'>
              <ZenAlert
                text={errors.submit.message!}
                variant='error'
                icon={
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    size='lg'
                    className='mx-0.5'
                  />
                }
              />
            </div>
          )}

          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'password'>
              control={control}
              type='password'
              name='password'
              placeholder='Password'
              rules={{
                required: 'Password required',
              }}
            />
          </div>
          <div className='mt-5'>
            <ZenButton
              label='Submit'
              type='submit'
              isFullWidth
              isSubmitting={isSubmitting}
              onClick={() => {
                if (errors.submit) {
                  clearErrors('submit');
                }
              }}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ZenPasswordGuard;
