import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createChatSession } from '../../../../slices/LeoSlice';
import { RootState } from '../../../../types';
import ZenButton from '../../ZenButton';

interface SessionRetryProps {}

const SessionRetry: React.FC<SessionRetryProps> = () => {
  const dispatch = useDispatch();
  const {
    leo: { loading },
    auth: { isAdmin, isSuperAdmin, isBroker, userDetail, isRealTitleOfficer },
    agentDetail: {
      detailResponse: { data },
    },
  } = useSelector((state: RootState) => state);
  const isAgent = !isSuperAdmin && !isRealTitleOfficer && !isAdmin && !isBroker;
  const id = isAgent ? userDetail?.id : data?.id;
  return (
    <div className='flex-grow flex flex-col pb-2 items-center justify-center h-full'>
      <p className='text-center text-white text-lg pb-6'>
        We&apos;re having trouble connecting to Leo. Please try again.
      </p>
      <ZenButton
        onClick={() => {
          if (id) {
            dispatch(createChatSession(id));
          }
        }}
        label='Try Again'
        isDisabled={loading}
        isSubmitting={loading}
      />
    </div>
  );
};

export default SessionRetry;
