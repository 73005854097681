import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BatchCreatePaymentMethods,
  PaymentDetailsControllerApi,
  PaymentMethodResponse,
  PaymentMethodsResponse,
  UpdatePaymentMethod,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import {
  AppDispatch,
  AppThunk,
  AsyncResponse,
  PaymentMethodState,
} from '../types';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from './ErrorSlice';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { performAsyncRequest } from './utils/SliceUtil';

interface Options {
  silent: boolean;
}

export const initialState: PaymentMethodState = {
  paymentMethodsResponseByAgentId: {},
};

const PaymentMethodSlice = createSlice({
  name: 'PaymentMethod',
  initialState,
  reducers: {
    savePaymentMethods(
      state,
      action: PayloadAction<
        AsyncResponse<PaymentMethodsResponse, { agentId: string }>
      >,
    ) {
      const agentId = action.payload.additionalProps?.agentId!;
      state.paymentMethodsResponseByAgentId[agentId] = action.payload;
    },
    addPaymentMethods(
      state,
      action: PayloadAction<{ agentId: string; data: PaymentMethodResponse[] }>,
    ) {
      const agentId = action.payload.agentId;
      const newPayments = [
        ...(state.paymentMethodsResponseByAgentId[agentId]?.data
          ?.paymentMethods || []),
        ...action.payload.data,
      ];
      state.paymentMethodsResponseByAgentId[agentId] = {
        name: 'payment method',
        loading: false,
        data: { paymentMethods: newPayments },
      };
    },
    editPaymentMethod(
      state,
      action: PayloadAction<{ agentId: string; data: PaymentMethodResponse }>,
    ) {
      const agentId = action.payload.agentId;
      const { data } = state.paymentMethodsResponseByAgentId[agentId] || {};
      const paymentMethodIndex = data?.paymentMethods?.findIndex(
        (paymentMethod) => paymentMethod.id === action.payload.data.id,
      );

      if (
        typeof paymentMethodIndex !== 'undefined' &&
        paymentMethodIndex !== -1
      ) {
        state.paymentMethodsResponseByAgentId[agentId]!.data!.paymentMethods![
          paymentMethodIndex
        ] = action.payload.data;
      }
    },
    deletePaymentMethod(
      state,
      action: PayloadAction<{ agentId: string; paymentMethodId: string }>,
    ) {
      const agentId = action.payload.agentId;
      if (state.paymentMethodsResponseByAgentId[agentId]?.data) {
        state.paymentMethodsResponseByAgentId[
          agentId
        ]!.data!.paymentMethods = state.paymentMethodsResponseByAgentId[
          agentId
        ]!.data?.paymentMethods?.filter(
          (form) => form.id !== action.payload.paymentMethodId,
        );
      }
    },
  },
});

export const {
  savePaymentMethods,
  addPaymentMethods,
  editPaymentMethod,
  deletePaymentMethod,
} = PaymentMethodSlice.actions;

export const fetchPaymentMethods = (userId: string): AppThunk => async (
  dispatch,
) => {
  const fetch = () =>
    new PaymentDetailsControllerApi(getYentaConfiguration()).getPaymentMethods(
      userId,
    );

  await performAsyncRequest(
    dispatch as AppDispatch,
    'payment methods',
    savePaymentMethods,
    fetch,
    {
      errorMetadata: { agent: { id: userId } },
      skipAuthDatadog: true,
      additionalProps: { agentId: userId },
    },
  );
};

export const createPaymentMethod = (
  userId: string,
  paymentMethodCreateRequest: BatchCreatePaymentMethods,
  options: Options = { silent: false },
): AppThunk<Promise<(string | undefined)[] | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new PaymentDetailsControllerApi(
      getYentaConfiguration(),
    ).batchCreatePaymentMethods(userId, paymentMethodCreateRequest);
    dispatch(
      addPaymentMethods({ agentId: userId, data: data.paymentMethods || [] }),
    );
    if (!options.silent) {
      dispatch(showSuccessToast('Payment method created successfully.'));
    }
    return data?.paymentMethods?.map((paymentMethod) => paymentMethod?.id!);
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create payment method', e);
    dispatch(
      showErrorToast(
        'We had a problem creating payment method.',
        'Please try again in a few moments.',
      ),
    );
    return paymentMethodCreateRequest.paymentMethods?.map(() => undefined);
  }
};

export const updatePaymentMethod = (
  userId: string,
  methodId: string,
  paymentMethodUpdateRequest: UpdatePaymentMethod,
  options: Options = { silent: false },
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new PaymentDetailsControllerApi(
      getYentaConfiguration(),
    ).patchPaymentMethod(userId, methodId, paymentMethodUpdateRequest);
    dispatch(editPaymentMethod({ agentId: userId, data }));
    if (!options.silent) {
      dispatch(showSuccessToast('Payment method updated successfully.'));
    }
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update payment method', e);
    dispatch(
      showErrorToast(
        'We had a problem updating payment method.',
        'Please try again in a few moments.',
      ),
    );
    return false;
  }
};

export const archivePaymentMethod = (
  userId: string,
  methodId: string,
  options: Options = { silent: false },
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new PaymentDetailsControllerApi(
      getYentaConfiguration(),
    ).archivePaymentMethod1(userId, methodId);
    dispatch(
      deletePaymentMethod({ agentId: userId, paymentMethodId: methodId }),
    );

    if (!options.silent) {
      dispatch(showSuccessToast('Payment method archived successfully.'));
    }
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to archive payment method', e);
    dispatch(
      showErrorToast(
        'We had a problem archiving payment method.',
        'Please try again in a few moments.',
      ),
    );
    return false;
  }
};

export default PaymentMethodSlice.reducer;
