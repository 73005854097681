import { sum, times } from 'lodash';
import { useEffect, useState } from 'react';
import { capitalizeEnum } from '../../utils/StringUtils';
import Alert from '../Alert';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import CircleCheckStepProgress from './CircleCheckStepProgress';
import {
  CreateJointVentureFormState,
  CreateJointVentureStepName,
} from './CreateJointVentureSteps';
import RealPoolShareDistributionCard from './RealPoolShareDistributionCard';
import ShareDistributionCard from './ShareDistributionCard';
import TierDistribution from './TierDistribution';
import TitleLayout from './TitleLayout';

const SharesStep: StepByStepComponent<
  CreateJointVentureFormState,
  CreateJointVentureStepName
> = ({
  form: { control, watch, clearErrors, setError },
  progress: { currentIndex, lastIndex },
  onPrevious,
  onSubmit,
}) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [shares, realPoolPercentShare, tiers] = watch([
    'shares',
    'realPoolPercentShare',
    'tiers',
  ]);

  const allocatedSharesPercentage =
    +realPoolPercentShare +
    sum(
      tiers
        .filter((tier) => !!tier.poolPercentShare)
        .map((tier) => +tier.poolPercentShare!),
    );
  const areAllTierSharesSet =
    shares && realPoolPercentShare
      ? tiers.every((el) => !!el.poolPercentShare)
      : false;

  const availablePoolShare =
    areAllTierSharesSet && allocatedSharesPercentage < 100
      ? 100 - allocatedSharesPercentage
      : 0;

  useEffect(() => {
    if (allocatedSharesPercentage > 100 || availablePoolShare) {
      times(tiers.length).map((ind) =>
        setError(`tiers.${ind}.poolPercentShare`, {}),
      );
      setShowError(true);
    } else if (showError) {
      clearErrors();
      setShowError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocatedSharesPercentage, availablePoolShare]);

  return (
    <TitleLayout
      onNext={onSubmit}
      nextText='Create JV'
      onPrevious={onPrevious}
      header={
        <CircleCheckStepProgress
          stepNames={Object.values(CreateJointVentureStepName)}
          totalStepsCount={lastIndex + 1}
          inProgressIndex={currentIndex}
        />
      }
    >
      <div className='w-full max-w-4xl mx-auto flex-grow p-5'>
        <p className='text-xl font-primary-medium text-dark text-center pt-5'>
          Shares Distribution
        </p>
        <div className='pt-8'>
          <RealPoolShareDistributionCard title='General' control={control} />

          {showError && (
            <div className='pt-5'>
              <Alert
                text='Pool share percentages must add up to 100.'
                variant='error'
              />
            </div>
          )}
          <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-5 pt-5'>
            {tiers.map((tier, i) => (
              <div key={tier.tierLevel?.value} className='w-full'>
                <ShareDistributionCard
                  tierIndex={i}
                  tierName={
                    capitalizeEnum(tier.name || '') || tier.customTierName!
                  }
                  tierColor={tier.colorCode!}
                  control={control}
                  showErrorExclamation={showError}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='w-full max-w-6xl mx-auto flex-grow pt-10 pb-20 px-5'>
        <TierDistribution
          label='Tiers Distribution'
          totalSharesCount={shares}
          realPoolPercentShare={realPoolPercentShare}
          availablePoolShare={availablePoolShare}
          areAllTierSharesSet={areAllTierSharesSet}
          tiers={tiers}
        />
      </div>
    </TitleLayout>
  );
};

export default SharesStep;
