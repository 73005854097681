import { isEmpty } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import {
  BrokerControllerApi,
  ChecklistFollowup,
  TransactionResponseCountryEnum,
} from '../../openapi/arrakis';
import { AdministrativeAreaResponseStateOrProvinceEnum } from '../../openapi/yenta';
import { convertMillisToHuman } from '../../utils/DateUtils';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum, formatAddress } from '../../utils/StringUtils';
import { IFilter } from '../../utils/TableUtils';
import StateOrProvinceIconCell from '../table/Cells/StateOrProvinceIconCell';
import ZenViewActionButtonCell from '../table/Cells/ZenViewActionButtonCell';
import OfficeAsyncMultiSelectColumnFilter from '../table/Filters/OfficeAsyncMultiSelectColumnFilter';
import ZenResourceIndexContainer from '../Zen/Containers/ZenResourceIndexContainer';
import ZenDateCell from '../Zen/Table/Cell/ZenDateCell';
import ZenTransactionTypeCell from '../Zen/Table/Cell/ZenTransactionTypeCell';
import ZenTransactionStatus from '../Zen/Transaction/ZenTransactionStatus';
import ZenUserPill from '../Zen/ZenUserPill';

interface TerminatedWithPendingDocumentToReviewResourceTableProps {}

export const columns: Array<Column<ChecklistFollowup>> = [
  {
    Header: 'Action',
    accessor: 'transactionId',
    Cell: ({ value }) => (
      <Link to={`/transactions/${value}/checklist`}>
        <ZenViewActionButtonCell />
      </Link>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'State / Province',
    accessor: 'stateOrProvince',
    Cell: ({ value }) => {
      if (value) {
        return (
          <StateOrProvinceIconCell
            state={
              (value as unknown) as AdministrativeAreaResponseStateOrProvinceEnum
            }
          />
        );
      }
      return 'N/A';
    },
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Office Name',
    accessor: 'officeName',
    Cell: ({ value }) => <p>{value ? value : 'N/A'}</p>,
    Filter: OfficeAsyncMultiSelectColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Close Date',
    accessor: 'closeDate',
    Cell: ({ value }) => <ZenDateCell date={value!} />,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Address',
    accessor: (d) => d['address']?.street,
    Cell: ({
      row: {
        original: { address },
      },
    }: CellProps<ChecklistFollowup>) => (
      <div className='w-56'>
        {!isEmpty(address)
          ? formatAddress(
              address?.street!,
              address?.street2!,
              address?.city!,
              capitalizeEnum(address?.state!),
              address?.zip!,
            )
          : 'N/A'}
      </div>
    ),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Pending Time',
    accessor: 'oldestPendingTime',
    Cell: ({ value }) => <p>{value ? convertMillisToHuman(value) : 'N/A'}</p>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'transactionType',
    Cell: ({ value }) =>
      value ? <ZenTransactionTypeCell dealType={value!} /> : 'N/A',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Represents',
    accessor: 'represents',
    Cell: ({ value }) => <p>{value ? capitalizeEnum(value) : 'N/A'}</p>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'lifecycleState',
    Cell: ({
      row: {
        original: { lifecycleState, address },
      },
    }: CellProps<ChecklistFollowup>) => {
      if (lifecycleState?.state && address?.country) {
        return (
          <ZenTransactionStatus
            status={lifecycleState?.state!}
            country={
              (address?.country as unknown) as TransactionResponseCountryEnum
            }
          />
        );
      }
      return 'N/A';
    },
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Agent Name',
    accessor: 'ownerName',
    Cell: ({ value, row: { original } }) =>
      original?.ownerAgentId ? (
        <Link to={`/people/${original?.ownerAgentId}`}>
          <div className='w-max'>
            <ZenUserPill name={`${value}`} backgroundVariant='background' />
          </div>
        </Link>
      ) : (
        <div className='w-max'>
          <ZenUserPill name={`${value}`} backgroundVariant='background' />
        </div>
      ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }) => <ZenDateCell date={value} />,
    disableFilters: true,
    disableSortBy: true,
  },
];

const TerminatedWithPendingDocumentToReviewResourceTable: React.FC<TerminatedWithPendingDocumentToReviewResourceTableProps> = () => {
  return (
    <div>
      <h1 className='font-zen-body text-xl text-zen-dark-9 block lg:hidden mb-4'>
        Terminated With Pending Documents To Review
      </h1>
      <ZenResourceIndexContainer<ChecklistFollowup>
        columns={columns}
        header='Terminated With Pending Documents To Review'
        resourceName='Terminated With Pending Documents To Review'
        pageSize={10}
        fetchData={async (req) => {
          const offices = req.filter?.officeName as IFilter;
          const { data } = await new BrokerControllerApi(
            getArrakisConfiguration(),
          ).getDocsToReviewForTransactionTermination(
            req.page,
            req.pageSize,
            offices?.values || [],
          );

          return {
            data: data?.checklistFollowupList || [],
            total: data?.totalCount! || 0,
          };
        }}
        standalone={false}
        hideFilters
      />
    </div>
  );
};

export default TerminatedWithPendingDocumentToReviewResourceTable;
