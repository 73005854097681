import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RezenAboutLogo from '../assets/img/rezenAbout.png';
import LineImage from '../assets/img/lines.png';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenPageLayout from '../components/Zen/ZenPageLayout';

interface AboutRouteProps {}

const AboutRoute: React.FC<AboutRouteProps> = () => {
  const Features = [
    'Step-by-step guided transaction entry',
    'Transaction-centric chat interface',
    'Road to Success progress tracker',
    'Digital file cabinet',
    'Real signature for e-signing',
    'Electronic deposits',
    'Agent Production Dashboard',
  ];

  const featureRow = (feature: string) => {
    return (
      <div className='flex flex-row items-center space-x-2'>
        <FontAwesomeIcon
          icon={solid('circle-check')}
          size='sm'
          className='text-primary-blue'
        />
        <p className='font-zen-body font-normal text-lg text-zen-dark-9'>
          {feature}
        </p>
      </div>
    );
  };

  return (
    <ZenRoute title='About'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'About', url: '/about' },
        ]}
      >
        <div className='flex- flex-col px-4 py-12'>
          <p className='font-zen-body font-bold text-lg text-zen-dark-9 px-0 md:px-12'>
            ReZEN is the cutting-edge proprietary software of Real Brokerage,
            available on both web and mobile platforms. ReZEN provides Real
            agents with a distinct competitive edge by offering them
            unparalleled visibility and control over their transactions.
          </p>
          <div className='flex justify-end'>
            <img
              src={LineImage}
              alt='lines'
              className='w-10 h-10 object-contain'
            />
          </div>
          <div className='flex flex-col md:flex-row items-center md:space-x-10 md:px-6 bg-zen-light-blue rounded-3xl mx-4 md:mx-6 pb-4 md:pb-0'>
            <img
              src={RezenAboutLogo}
              alt='rezenAboutLogo'
              className='w-36 h-36 object-contain py-4'
            />
            <p className='font-zen-body font-normal text-lg text-zen-dark-9 break-words mx-4 md:mx-0'>
              <span className='font-bold'>ReZEN</span> is a comprehensive
              application for transaction entry, document storage, document
              signing, broker review, compliance approval, communication,
              transaction document generation, payment processing, and account
              reconciliation.
            </p>
          </div>
          <div className='flex flex-col md:px-12 space-y-5 mt-4'>
            <p className='font-zen-body font-bold text-lg text-zen-dark-9'>
              ReZEN maximizes agent success with the following key features:
            </p>
            <div className='flex flex-col px-3 md:px-6 space-y-1'>
              {Features?.map((feature, index) => (
                <div key={`${feature + index}`}>{featureRow(feature)}</div>
              ))}
            </div>
          </div>
          <p className='font-zen-body font-normal text-lg text-zen-dark-9 mt-10 md:px-12'>
            Developed by REAL, owned by REAL, and designed exclusively for REAL
            agents. ReZEN is the ultimate tool to unlock your real estate
            potential!
          </p>
        </div>
      </ZenPageLayout>
    </ZenRoute>
  );
};

export default AboutRoute;
