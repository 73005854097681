import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import DetailPageLoader from '../components/DetailPageLoader';
import ResourceContainer from '../components/ResourceContainer';
import ZenOfficeDetailCard from '../components/Zen/Office/ZenOfficeDetailCard';
import ZenOfficeDetailIndexRoute from '../components/Zen/Office/ZenOfficeDetailIndexRoute';
import ZenOfficeFileCabinetRoute from '../components/Zen/Office/ZenOfficeFileCabinetRoute';
import ZenRouterTabs, { ZenTab } from '../components/Zen/Tab/ZenRouterTabs';
import ZenCoverImage from '../components/Zen/ZenCoverImage';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenUpdateOfficeForm from '../components/office/ZenUpdateOfficeForm';
import { fetchOfficeChecklistDefinition } from '../slices/ChecklistDefinitionSlice';
import {
  fetchOfficeDetailById,
  fetchOfficeGroups,
} from '../slices/OfficeSlice';
import { BreadcrumbMatcherType, RootState } from '../types';
import { getBreadcrumbMatched } from '../utils/TransactionHelper';

type Match = {
  id: string;
};

const ZenOfficeDetailRoute: React.FC = () => {
  const location = useLocation();
  const { id: officeId } = useParams() as Match;
  const [openEditOfficeForm, setOpenEditOfficeForm] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    auth: { isAdmin },
    office: {
      loadingDetail,
      officeDetail,
      fetchDetailErrorCode,
      officeGroupsById,
    },
  } = useSelector((state: RootState) => state);

  const tabs: ZenTab[] = useMemo(() => {
    const array = [
      {
        label: 'Office Details',
        path: `/offices/${officeId}`,
        exact: true,
        TabComponent: () => <ZenOfficeDetailIndexRoute officeId={officeId} />,
      },
    ];

    if (officeDetail?.dropboxId) {
      array.push({
        label: 'Documents',
        path: `/offices/${officeId}/documents`,
        exact: true,
        TabComponent: () => (
          <div className='w-full lg:pl-0 lg:pr-6 lg:pb-32'>
            <ZenOfficeFileCabinetRoute officeId={officeId} />
          </div>
        ),
      });
    }

    return array;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeDetail?.dropboxId, officeId]);

  const path = [
    { title: 'Home', url: '/' },
    { title: 'Offices', url: '/offices' },
  ];

  if (!!officeDetail) {
    path.push({
      title: `${officeDetail?.name}`,
      url: `/offices/${officeDetail?.id}`,
    });

    const breadcrumbsToMatch: BreadcrumbMatcherType[] = [
      {
        title: 'Documents',
        url: `/offices/${officeDetail?.id}/documents`,
      },
    ];

    const breadcrumbMatched = getBreadcrumbMatched(
      location?.pathname,
      breadcrumbsToMatch,
    );

    if (breadcrumbMatched) {
      path.push(breadcrumbMatched);
    }
  }

  useEffect(() => {
    dispatch(fetchOfficeDetailById(officeId));
  }, [dispatch, officeId]);

  const fetchOfficeCheckList = useCallback(() => {
    dispatch(fetchOfficeChecklistDefinition(officeId));
  }, [dispatch, officeId]);

  useEffect(() => {
    if (isAdmin) {
      fetchOfficeCheckList();
    }
  }, [fetchOfficeCheckList, isAdmin]);

  const fetchGroups = useCallback(() => {
    if (!officeGroupsById[officeId]) {
      dispatch(fetchOfficeGroups(officeId));
    }
  }, [dispatch, officeGroupsById, officeId]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return (
    <ZenRoute title='Office Details'>
      <ResourceContainer
        loading={loadingDetail}
        isEmpty={!officeDetail}
        errorCode={fetchDetailErrorCode}
        LoaderComponent={<DetailPageLoader />}
        resourceName='office'
      >
        <div className='relative'>
          <ZenPageLayout path={path} stickyHeader>
            {!!officeDetail && (
              <div>
                <div className='md:hidden block'>
                  <ZenCoverImage />
                </div>
                <div className='md:sticky md:z-10' style={{ top: 0 }}>
                  <div className='relative'>
                    <div className='md:absolute md:top-12 md:w-2/5 2xl:w-1/3 w-full -mt-20 md:mt-0 z-20'>
                      <div className='md:px-4 lg:px-6 2xl:px-8 md:py-6 px-4'>
                        <ZenOfficeDetailCard
                          officeDetail={officeDetail}
                          onClickEdit={() => setOpenEditOfficeForm(true)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='md:block hidden'>
                  <ZenCoverImage />
                </div>

                <div className='flex md:flex-row flex-col'>
                  <div className='md:w-2/5 2xl:w-1/3' />
                  <div className='md:w-3/5 2xl:w-2/3 w-full'>
                    <ZenRouterTabs tabs={tabs} />
                  </div>
                </div>
              </div>
            )}
            {!!officeDetail && openEditOfficeForm && (
              <ZenUpdateOfficeForm
                isOpen={openEditOfficeForm}
                onClose={() => setOpenEditOfficeForm(false)}
                officeDetails={officeDetail}
              />
            )}
          </ZenPageLayout>
        </div>
      </ResourceContainer>
    </ZenRoute>
  );
};

export default ZenOfficeDetailRoute;
