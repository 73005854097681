import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../../types';

export interface ZenStatisticsBlockItems {
  title: string | number;
  subtitle: string;
}

export type StatisticType = 'transactions' | 'listings';

export interface ZenStatisticsBlockProps {
  items: ZenStatisticsBlockItems[];
  statisticType: StatisticType;
}

const ZenStatisticsBlock: React.FC<ZenStatisticsBlockProps> = ({
  items,
  statisticType,
}) => {
  const {
    auth: { isAdmin, isBroker },
    agentDetail: { detailResponse: agentDetailResponse },
  } = useSelector((state: RootState) => state);

  return (
    <div className='flex flex-row p-3 border border-gray-600 rounded-lg'>
      {items.map((item) => {
        const statisticStatus =
          item.subtitle === 'Active' ? undefined : item.subtitle.toLowerCase();

        const checkLinkValue = () => {
          const { id } = agentDetailResponse.data || {};

          if (isAdmin || isBroker) {
            const baseLink = `/people/${id}/${statisticType}`;
            return statisticStatus
              ? `${baseLink}/${statisticStatus}`
              : baseLink;
          }

          const baseLink = `/${statisticType}`;
          return statisticStatus ? `${baseLink}/${statisticStatus}` : baseLink;
        };

        return (
          <Link key={item.title + item.subtitle} to={checkLinkValue()}>
            <div className='flex flex-col px-3 text-white w-28'>
              <span className='block text-2xl font-zen-title'>
                {item.title}
              </span>
              <p className='font-zen-body whitespace-pre'>{item.subtitle}</p>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default ZenStatisticsBlock;
