import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { AgentResponse } from '../../openapi/yenta';
import { formatPhoneNumber } from '../../utils/StringUtils';
import ZenAgentStatusCell from '../Zen/Table/Cell/ZenAgentStatusCell';
import ZenAvatar from '../Zen/ZenAvatar';
import ZenIconText from '../Zen/ZenIconText';
import ZenUserPill from '../Zen/ZenUserPill';

interface OfficeAgentsTableProps {
  agents: AgentResponse[];
}

const OfficeAgentsTable: React.FC<OfficeAgentsTableProps> = ({ agents }) => {
  return (
    <div className='p-4 scrollbar overflow-x-scroll'>
      <table
        className='w-full break-inside-avoid font-zen-body text-sm text-zen-dark-9 md:table hidden'
        data-testid='medium-screen'
      >
        <thead>
          <tr className='border-b'>
            <th className='font-semibold p-2 text-left'>Agent Name</th>
            <th className='font-semibold p-2 text-left'>Division</th>
            <th className='font-semibold p-2 text-left'>Email Address</th>
            <th className='font-semibold p-2 text-left'>Phone Number</th>
            <th className='font-semibold p-2 text-left'>Status</th>
          </tr>
        </thead>
        {agents.map((agent) => {
          const division = agent.divisions?.[0];

          return (
            <tbody key={agent.id}>
              <tr className='font-zen-body text-sm text-zen-dark-9'>
                <td className='p-2 text-left'>
                  <Link to={`/people/${agent.id}`}>
                    <ZenUserPill
                      name={`${agent.firstName} ${agent.lastName}`}
                      imageUrl={agent.avatar}
                      backgroundVariant='background'
                    />
                  </Link>
                </td>
                <td className='p-2 text-left'>
                  <div className='mr-2'>
                    {division ? (
                      <ZenIconText
                        icon={
                          <img
                            className='object-contain w-5 h-5'
                            src={division.logoUrl}
                            alt='agent-division'
                          />
                        }
                        variant='small'
                        text={division.name || '--'}
                        textClassName='text-zen-dark-9 whitespace-pre'
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={regular('dash')}
                        className='text-center'
                      />
                    )}
                  </div>
                </td>
                <td className='p-2 text-left'>
                  <Link to={`/people/${agent.id}`}>{agent.emailAddress}</Link>
                </td>
                <td className='p-2 text-left'>
                  {formatPhoneNumber(agent.phoneNumber)}
                </td>
                <td className='p-2 text-left'>
                  {agent.agentStatus ? (
                    <ZenAgentStatusCell status={agent.agentStatus!} />
                  ) : (
                    'N/A'
                  )}
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
      <div className='md:hidden block' data-testid='small-screen'>
        {agents.map((agent) => (
          <div
            key={agent.id}
            className='flex flex-row space-x-2 py-3 border-b border-gray-300'
          >
            <div>
              <Link to={`/people/${agent.id}`}>
                <ZenAvatar
                  name={`${agent.firstName} ${agent.lastName}`}
                  size='md'
                  imageUrl={agent.avatar!}
                />
              </Link>
            </div>
            <div className='flex flex-col space-y-1'>
              <p className='font-zen-body font-semibold text-sm'>{`${agent.firstName} ${agent.lastName}`}</p>
              <ZenIconText
                text={agent.emailAddress || 'N/A'}
                icon={<FontAwesomeIcon icon={faEnvelope} />}
                variant='small'
              />
              <ZenIconText
                text={formatPhoneNumber(agent.phoneNumber) || 'N/A'}
                icon={<FontAwesomeIcon icon={faPhone} />}
                variant='small'
              />
              {agent.agentStatus && (
                <ZenAgentStatusCell status={agent.agentStatus} />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OfficeAgentsTable;
