import { FilterProps } from 'react-table';
import React from 'react';
import { FilterColumnsToProcess, ISelectOption } from '../../../../types';
import { PROCESS_FILTER_COLUMN } from '../../../../utils/TableUtils';
import ControlledSelectInputV7 from '../../../ControlledSelectInputV7';

export interface SelectColumnFilterProps<D extends object>
  extends FilterProps<D> {
  options: Array<ISelectOption>;
}

const SelectColumnFilter = <D extends object>({
  column,
  control,
  options,
}: SelectColumnFilterProps<D>): React.ReactElement => {
  const prefixFieldName = `${PROCESS_FILTER_COLUMN}.${FilterColumnsToProcess.ENUM}.${column.id}`;

  // Check if column has a custom header
  const columnName =
    typeof column.Header === 'object'
      ? (column.render('Header') as React.ReactElement)?.props?.children
      : column.render('Header');

  return (
    <ControlledSelectInputV7
      control={control}
      defaultValue={column.filterValue?.value?.value ?? ''}
      shouldUnregister={false}
      options={[
        {
          label: `Select ${columnName}`,
          value: '',
          disabled: true,
        },
        ...options,
      ]}
      name={prefixFieldName}
      rules={{
        required: `${columnName} is required`,
      }}
    />
  );
};

export default SelectColumnFilter;
