import React from 'react';
import { AttachedFeeValueFeeTypeEnum } from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenStatusPill, { PillVariantType } from '../ZenStatusPill';

export interface ZenFeeTypeCellProps {
  status: AttachedFeeValueFeeTypeEnum;
}

const statusToVariant: EnumMap<AttachedFeeValueFeeTypeEnum, PillVariantType> = {
  ADDITIONAL_COMMISSION: 'warning',
  MLS: 'purple',
  REBATE: 'warningTwo',
  TRANSACTION_COORDINATOR: 'primary',
  REIMBURSEMENT: 'success',
  ONE_REAL_IMPACT_FUND: 'success',
};

const ZenFeeTypeCell: React.FC<ZenFeeTypeCellProps> = ({ status }) => {
  return (
    <ZenStatusPill
      text={capitalizeEnum(status)}
      variant={safeEnumMapGet(statusToVariant, status, 'default')}
    />
  );
};

export default ZenFeeTypeCell;
