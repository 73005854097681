import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FileApi } from '../../openapi/dropbox';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import { getDropboxConfiguration } from '../../utils/OpenapiConfigurationUtils';

interface SharedApprovalLetterProps {
  name: string;
  preApprovalFileId: string;
}

const SharedApprovalLetter: React.FC<SharedApprovalLetterProps> = ({
  name,
  preApprovalFileId,
}) => {
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState(false);

  const handleViewFile = async () => {
    setDownloading(true);
    try {
      const { data: fileUrl } = await new FileApi(
        getDropboxConfiguration(),
      ).getFileUrl(preApprovalFileId);

      window.open(fileUrl, '_blank');
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to download a file', e, {
        file: { id: preApprovalFileId },
      });
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div className='border border-zen-dark-4 rounded-lg py-2 px-4 mb-4 font-zen-body'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          {/* <div className='mr-3 h-9 w-9 min-w-[36px] flex items-center justify-center border border-zen-dark-5 rounded-[5px] text-zen-dark-5'>
            <FontAwesomeIcon
              icon={solid('file-pdf')}
              className='text-zen-dark-5'
              fontSize={20}
            />
          </div> */}
          <div>
            <p className='text-sm font-semibold'>{name}</p>
            {/* <div className='text-xs text-zen-dark-5 space-x-0.5'>
              <span>Version {version}</span>
              <span>|</span>
              <span>Uploaded: {date}</span>
            </div> */}
          </div>
        </div>
        <FontAwesomeIcon
          icon={regular('arrow-up-right-from-square')}
          className='text-zen-dark-9 cursor-pointer'
          aria-label='pre-approval-letter-link'
          fontSize={16}
          onClick={async () => {
            if (downloading) {
              return;
            }

            await handleViewFile();
          }}
        />
      </div>
    </div>
  );
};

export default SharedApprovalLetter;
