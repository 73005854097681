import React from 'react';
import Avatar from '../Avatar';
import { getFullName } from '../../utils/AgentHelper';

interface AvatarLabelComponentProps {
  firstName: string;
  lastName: string;
  avatar: string;
  emailAddress?: string;
  administrativeAreas?: string[] | undefined;
}

const AvatarLabelComponent: React.FC<AvatarLabelComponentProps> = ({
  avatar,
  firstName,
  lastName,
  emailAddress,
  administrativeAreas,
}) => {
  const labelArray = [
    getFullName({ firstName, lastName }),
    emailAddress,
    administrativeAreas?.length ? administrativeAreas.join(', ') : null,
  ].filter(Boolean);

  const label = labelArray.join(' | ');

  return (
    <div className='space-x-2 flex flex-row items-center'>
      <Avatar
        name={`${firstName!} ${lastName!}`}
        size='xxs'
        imageUrl={avatar!}
      />
      <p>{label}</p>
    </div>
  );
};

export default AvatarLabelComponent;
