import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RealTitleIcon from '../../assets/img/real_title_icon.svg';
import { InviteResponse } from '../../openapi/avalon';
import {
  declineJointVentureInvitation,
  toggleJVInvitationModal,
} from '../../slices/JointVentureSlice';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenSimpleConfirmationModal from '../Zen/Modal/ZenSimpleConfirmationModal';
import ZenButton from '../Zen/ZenButton';
import TierMembershipCard, { ShadeVariant } from './TierMembershipCard';

export interface InvitationCardProps {
  jointVentureDetails: InviteResponse;
}

const InvitationCard: React.FC<InvitationCardProps> = ({
  jointVentureDetails,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const tierName = (
    jointVentureDetails?.tier?.name || ''
  ).toLowerCase() as ShadeVariant;

  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [isDeclining, setIsDeclining] = useState<boolean>(false);

  const handleClose = () => {
    setOpenCancelModal(false);

    dispatch(toggleJVInvitationModal(false));
  };

  const handleDecline = async () => {
    setIsDeclining(true);
    await dispatch(declineJointVentureInvitation(jointVentureDetails?.id!));
    setIsDeclining(false);
    handleClose();
  };

  return (
    <div>
      <header className='flex flex-col lg:flex-row items-center justify-between bg-zen-dark-13 p-5 w-full lg:h-52 rounded-2xl'>
        <div className='flex flex-col justify-between mx-auto space-y-5 lg:pb-0 lg:mx-0 min-w-10 mb-4 md:mb-0'>
          <div className='flex  flex-row items-center'>
            <img src={RealTitleIcon} alt='icon' />
            <span className='text-lg leading-6 text-white  font-zen-body mx-2'>
              Real Title JV
            </span>
          </div>
          <div className='flex flex-row  items-center text-white'>
            <span className='text-base text-center  lg:text-left lg:text-xl leading-6  font-zen-title'>
              You&apos;ve qualified to join the{' '}
              {jointVentureDetails.jointVenture?.name}!
            </span>
          </div>
          <div className='flex md:flex-row flex-col items-center md:space-x-2 space-y-4 md:space-y-0 min-w-full'>
            <ZenButton
              label='Learn More'
              isFullWidth
              variant='primary-outline'
              onClick={() =>
                history.push(`/real-title/${jointVentureDetails.id}/learn-more`)
              }
            />
            <ZenButton
              label='Join'
              isFullWidth
              variant='primary'
              onClick={() =>
                history.push(`/real-title/${jointVentureDetails.id}/signup`)
              }
            />
            <ZenButton
              isFullWidth
              onClick={() => setOpenCancelModal(true)}
              variant='primary-link'
              label="I'm not interested"
            />
          </div>
        </div>
        <div>
          <TierMembershipCard
            tierType={tierName}
            label={`${capitalizeEnum(tierName)} Membership`}
            subLabel={jointVentureDetails.jointVenture?.name!}
          />
        </div>
        <ZenSimpleConfirmationModal
          variant='danger'
          title={`Are you sure you do not want to join ${jointVentureDetails?.jointVenture?.name}?`}
          confirmButtonText='Decline Invitation'
          cancelButtonText='Maybe Later'
          onClose={handleClose}
          onConfirm={handleDecline}
          isOpen={openCancelModal}
          size='large'
          isSubmitting={isDeclining}
          hideIcon
        />
      </header>
    </div>
  );
};

export default InvitationCard;
