import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faXmark, faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  InviteResponseStatusEnum,
  MembershipResponseStatusEnum,
} from '../../openapi/avalon';
import { EnumMap } from '../../types';
import { cn } from '../../utils/classUtils';
import { safePartialEnumMapGet } from '../../utils/EnumHelper';
import JVStatusPill from './JVStatusPill';

export interface InvitationStatusCardProps {
  status:
    | InviteResponseStatusEnum
    | MembershipResponseStatusEnum
    | 'NOT_INVITED';
  jointVentureTitle: string;
  onClick?(): void;
}

export const variantToClassNameMap: EnumMap<
  InviteResponseStatusEnum | MembershipResponseStatusEnum | 'NOT_INVITED',
  string
> = {
  ACCEPTED: 'bg-green-100 text-green-600',
  DECLINED: 'bg-zen-danger-light text-error',
  CREATED: 'bg-primary-blue text-white',
  VIEWED: 'bg-primary-blue text-white',
  WITHDRAWN: 'bg-zen-danger-light text-error',
  ACTIVE: 'bg-green-100 text-green-600',
  NOT_INVITED: 'bg-zen-danger-light text-error',
  PENDING_SIGNATURE: 'bg-zen-warning-1 text-zen-warning-dark',
  PENDING_PAYMENT: 'bg-zen-warning-1 text-zen-warning-dark',
  PAYMENT_FAILED: 'bg-zen-danger-light text-error',
};

export const iconVariantToClassNameMap: EnumMap<
  InviteResponseStatusEnum | MembershipResponseStatusEnum | 'NOT_INVITED',
  IconProp | undefined
> = {
  ACCEPTED: faCheck,
  WITHDRAWN: faXmark,
  DECLINED: faXmark,
  CREATED: faCheck,
  VIEWED: faEye,
  PENDING_SIGNATURE: undefined,
  PAYMENT_FAILED: faXmark,
  ACTIVE: faCheck,
  PENDING_PAYMENT: undefined,
  NOT_INVITED: faXmark,
};

const InvitationStatusCard: React.FC<InvitationStatusCardProps> = ({
  jointVentureTitle,
  status,
  onClick,
}) => {
  const titleVariantToClassNameMap: EnumMap<
    InviteResponseStatusEnum | MembershipResponseStatusEnum | 'NOT_INVITED',
    string
  > = {
    ACCEPTED: jointVentureTitle,
    DECLINED: jointVentureTitle.length
      ? `Invitation Declined: ${jointVentureTitle}`
      : 'Invitation Declined',
    CREATED: jointVentureTitle.length
      ? `Invitation Sent: ${jointVentureTitle}`
      : 'Invitation Sent',
    VIEWED: jointVentureTitle.length
      ? `Invitation Viewed: ${jointVentureTitle}`
      : 'Invitation Viewed',
    WITHDRAWN: jointVentureTitle.length
      ? `Invitation Withdrawn: ${jointVentureTitle}`
      : 'Invitation Withdrawn',
    PENDING_SIGNATURE: jointVentureTitle.length
      ? `Awaiting Signature: ${jointVentureTitle}`
      : 'Awaiting Signature',
    PAYMENT_FAILED: jointVentureTitle.length
      ? `Payment Failed: ${jointVentureTitle}`
      : 'Payment Failed',
    PENDING_PAYMENT: jointVentureTitle.length
      ? `Payment Pending: ${jointVentureTitle}`
      : 'Payment Pending',
    ACTIVE: jointVentureTitle.length
      ? `Membership Active: ${jointVentureTitle}`
      : 'Membership Active',
    NOT_INVITED: 'No',
  };

  return (
    <div className='flex flex-row items-center'>
      <div
        className={cn(
          'flex items-center space-x-1 py-1.5 px-2 rounded-full md:whitespace-nowrap font-zen-body text-xs font-semibold',
          safePartialEnumMapGet(
            variantToClassNameMap,
            status,
            'bg-zen-danger-light text-error',
          ),
        )}
      >
        {iconVariantToClassNameMap[status] && (
          <FontAwesomeIcon
            icon={safePartialEnumMapGet(
              iconVariantToClassNameMap,
              status,
              faXmark,
            )}
            className='text-xs'
          />
        )}
        <span>
          {safePartialEnumMapGet(titleVariantToClassNameMap, status, 'No')}
        </span>
      </div>
      <JVStatusPill status={status} onClick={onClick} />
    </div>
  );
};

export default InvitationStatusCard;
