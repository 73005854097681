import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ConveyanceControllerApi,
  ConveyanceResponse,
} from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import { AppThunk, ConveyanceDocumentState, ErrorCode } from '../types';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: ConveyanceDocumentState = {
  conveyancesLoading: false,
  conveyances: [],
  conveyancesErrorCode: null,
  conveyanceDocument: null,
  conveyanceDocumentErrorCode: null,
  conveyanceDocumentLoading: false,
};

const ConveyanceDocumentSlice = createSlice({
  name: 'conveyanceDocument',
  initialState,
  reducers: {
    changeConveyancesLoading(state, action: PayloadAction<boolean>) {
      state.conveyancesLoading = action.payload;
    },
    saveConveyances(state, action: PayloadAction<ConveyanceResponse[]>) {
      state.conveyances = action.payload;
      state.conveyancesErrorCode = null;
    },
    errorFetchingConveyances(state, action: PayloadAction<ErrorCode>) {
      state.conveyancesErrorCode = action.payload;
    },
    changeConveyanceDocumentLoading(state, action: PayloadAction<boolean>) {
      state.conveyanceDocumentLoading = action.payload;
    },
    saveConveyanceDocument(state, action: PayloadAction<ConveyanceResponse>) {
      state.conveyanceDocument = action.payload;
      state.conveyanceDocumentErrorCode = null;
    },
    errorFetchingConveyanceDocument(state, action: PayloadAction<ErrorCode>) {
      state.conveyanceDocumentErrorCode = action.payload;
    },
  },
});

export const {
  changeConveyancesLoading,
  saveConveyances,
  errorFetchingConveyances,
  changeConveyanceDocumentLoading,
  saveConveyanceDocument,
  errorFetchingConveyanceDocument,
} = ConveyanceDocumentSlice.actions;

export const generateConveyanceDocument = (
  id: string,
  showLoading = true,
): AppThunk => async (dispatch) => {
  if (showLoading) {
    dispatch(changeConveyancesLoading(true));
  }
  try {
    await new ConveyanceControllerApi(
      getArrakisConfiguration(),
    ).generateForTransaction(id);

    await dispatch(fetchConveyanceDocuments(id, false));
  } catch (e) {
    dispatch(showErrorToast('Unable to generate conveyance documents'));
    ErrorService.notify('Unable to generate conveyance documents', e, {
      transaction: { id },
    });
    dispatch(showApiErrorModal(e));
  } finally {
    if (showLoading) {
      dispatch(changeConveyancesLoading(false));
    }
  }
};

export const fetchConveyanceDocuments = (
  id: string,
  showLoading = true,
): AppThunk => async (dispatch) => {
  if (showLoading) {
    dispatch(changeConveyancesLoading(true));
  }
  try {
    const { data } = await new ConveyanceControllerApi(
      getArrakisConfiguration(),
    ).getByTransaction(id);
    dispatch(saveConveyances(data));
  } catch (e) {
    dispatch(showErrorToast('Unable to get conveyance documents'));
    ErrorService.notify('Unable to get conveyance documents', e, {
      transaction: { id },
    });
    dispatch(errorFetchingConveyanceDocument(ErrorService.getErrorCode(e)));
  } finally {
    if (showLoading) {
      dispatch(changeConveyancesLoading(false));
    }
  }
};

export const addNotesToConveyanceDocuments = (
  transactionId: string,
  conveyanceIdToNote: Record<string, string>,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new ConveyanceControllerApi(
      getArrakisConfiguration(),
    ).addAdditionalNoteToConveyances({ conveyanceIdToNote });
    await dispatch(fetchConveyanceDocuments(transactionId));
    dispatch(
      showSuccessToast('Conveyance document PDFs are now being generated'),
    );
  } catch (e) {
    dispatch(showErrorToast('Unable to get conveyance documents'));
    ErrorService.notify('Unable to get conveyance documents', e, {
      transaction: { transactionId },
    });
    dispatch(errorFetchingConveyanceDocument(ErrorService.getErrorCode(e)));
    return false;
  }

  return true;
};

export default ConveyanceDocumentSlice.reducer;
