import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import ApplicationStepCompleteMessage from '../../ApplicationStepCompleteMessage';
import ApplicationLayout from '../../ApplicationLayout';
import ApplicationVerticalStepProgress from '../ApplicationVerticalStepProgress';

const ApplicationFeeSuccess: React.FC = () => {
  const history = useHistory();
  return (
    <ApplicationLayout>
      <div className='w-full mx-auto px-4 my-10 lg:max-w-6xl gap-10 grid grid-cols-1 lg:grid-cols-5'>
        <div className='lg:col-span-3'>
          <ApplicationStepCompleteMessage
            icon={<FontAwesomeIcon icon={faCheck} size='2xl' />}
            header='Thank you, we’ve received your payment.'
            nextStepName='Transfer License '
            onNextStepClicked={() =>
              history.push('/onboarding/application-transfer-license')
            }
          />
        </div>
        <div className='lg:col-span-2'>
          <ApplicationVerticalStepProgress />
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default ApplicationFeeSuccess;
