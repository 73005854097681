import { Fragment } from 'react';
import { FeatureFlagTypeEnum } from '../types';
import { useFeatureFlag } from '../hooks/useFeatureFlag';

interface FeatureResourceProps {
  flag: FeatureFlagTypeEnum;
}

const FeatureFlagEnabledOnly: React.FC<FeatureResourceProps> = ({
  flag,
  children,
}) => {
  const isEnabled = useFeatureFlag(flag);

  if (!isEnabled) {
    return null;
  }

  // This will be released in a new version of eslint. Disabling for now.
  // https://github.com/yannickcr/eslint-plugin-react/commit/9f1d618499b23e184a628365cb5e0ea48292cd6f
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Fragment>{children}</Fragment>;
};

export default FeatureFlagEnabledOnly;
