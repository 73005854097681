import { useDispatch, useSelector } from 'react-redux';
import { SSE_URL } from '../../constants/CommonConstants';
import { ItemResponse } from '../../openapi/sherlock';
import {
  CommentControllerApi,
  CommentDto,
  PostCommentBodyReaderRoleEnum,
  QueryCommentsRs,
  RezenObjectTypeEnum,
  UpdateCommentBodyReaderRoleEnum,
} from '../../openapi/yada';
import { saveLatestComment } from '../../slices/CheckListSlice';
import { AppDispatch, RootState } from '../../types';
import { getYadaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { getFileNameFromUrl } from '../../utils/StringUtils';
import ZenCommentSection, {
  MentionSource,
} from '../transactions/Comments/ZenCommentSection';
import { CommentOnSubmitParams } from '../ZenCommentWidget/ZenCommentWidget';
import { addAutoTagMentionsInComments } from '../../utils/CommentUtils';

interface ChecklistItemCommentSectionProps {
  checklistItem: ItemResponse;
  containerType: RezenObjectTypeEnum;
  containerId: string;
  getMentions?(searchTerm?: string): MentionSource[];
  callerGroupId?: string;
  powerAudit?: boolean;
}

const LOAD_MORE_COMMENT_COUNT = 10;

const ChecklistItemCommentSection: React.FC<ChecklistItemCommentSectionProps> = ({
  checklistItem,
  containerType,
  containerId,
  getMentions,
  callerGroupId,
  powerAudit,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
    },
  } = useSelector((state: RootState) => state);
  const isAutoTagAdminInCommentEnabled =
    containerType === RezenObjectTypeEnum.Transaction && !powerAudit;

  const onDeleteComment = async (comment: CommentDto) => {
    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).deleteComment(
      comment?.container?.type!,
      comment?.container?.id!,
      comment?.id! as string,
    );

    return data;
  };

  const fetchComments = async (
    pageStart: string | undefined,
  ): Promise<QueryCommentsRs> => {
    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).getCommentsByReference(
      RezenObjectTypeEnum.ChecklistItem,
      checklistItem?.id!,
      LOAD_MORE_COMMENT_COUNT,
      pageStart,
    );

    return data;
  };

  const handleSubmit = async (values: CommentOnSubmitParams) => {
    if (values.mediaType) {
      const _file = await fetch(values.filePath!);
      const fileBlob = await _file.blob();
      const fileName = getFileNameFromUrl(values.filePath!);
      const file = new File([fileBlob], fileName! + '.m4a', {
        type: values.fileType,
      });

      await new CommentControllerApi(getYadaConfiguration()).uploadMedia(
        containerType, // containerType
        containerId, // containerId
        'USER', // authorType
        values.mediaType, // mediaType
        // @ts-expect-error
        JSON.stringify(values.comment.blocks!), // richBlocks
        file, // file
        RezenObjectTypeEnum.ChecklistItem, // refersToType
        checklistItem?.id, // refersToId
        checklistItem.name, // refersToName
        callerGroupId, // callerGroupId
        undefined, // triggerActivity
        undefined, // readerRole
        undefined, // fileDescription
        undefined, // axios config
      );

      return;
    }

    const updatedRichContent = addAutoTagMentionsInComments(
      isAutoTagAdminInCommentEnabled,
      transactionDetail?.otherParticipants || [],
      values?.comment,
    );

    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).postComment(
      containerType,
      containerId,
      'USER',
      {
        richContent: updatedRichContent,
        readerRole: values.role as PostCommentBodyReaderRoleEnum,
      },
      RezenObjectTypeEnum.ChecklistItem,
      checklistItem?.id!,
      checklistItem.name,
      callerGroupId,
    );

    dispatch(
      saveLatestComment({ checklistItemId: checklistItem.id!, comment: data }),
    );

    return data;
  };

  const onEditComment = async (
    values: CommentOnSubmitParams,
    commentId: string,
  ): Promise<CommentDto> => {
    const updatedRichContent = addAutoTagMentionsInComments(
      isAutoTagAdminInCommentEnabled,
      transactionDetail?.otherParticipants || [],
      values?.comment,
    );

    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).updateComment(containerType, containerId, commentId, {
      richContent: updatedRichContent,
      readerRole: values.role as UpdateCommentBodyReaderRoleEnum,
    });

    return data;
  };

  return (
    <div className='w-full'>
      <div className='mb-1 flex'>
        <div>Comments</div>
      </div>
      <div className='flex flex-col space-y-6'>
        <ZenCommentSection
          fetchComments={fetchComments}
          onDelete={onDeleteComment}
          onEdit={onEditComment}
          onSubmit={handleSubmit}
          getMentions={getMentions}
          htmlElementScrollableParent={
            document?.getElementById('modal-scroll')!
          }
          isReferred
          checklist
          containerId={containerId}
          containerType={containerType}
          getPublicUserInfo={containerType === RezenObjectTypeEnum.Borrower}
          powerAudit={powerAudit}
          sseURL={`${SSE_URL}?containerType=${containerType}&containerId=${containerId}&refersToType=${RezenObjectTypeEnum.ChecklistItem}&refersToId=${checklistItem?.id}`}
        />
      </div>
    </div>
  );
};

export default ChecklistItemCommentSection;
