import React from 'react';
import { ReactComponent as LeaseIcon } from '../../../assets/img/lease.svg';
import { ReactComponent as SaleIcon } from '../../../assets/img/sale.svg';
import { ChecklistDefinitionDtoDealTypeEnum } from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';

interface OfficeDealTypeCellProps {
  dealType: ChecklistDefinitionDtoDealTypeEnum;
}

const OfficeDealTypeCell: React.FC<OfficeDealTypeCellProps> = ({
  dealType,
}) => {
  const typeToImageMap: EnumMap<
    ChecklistDefinitionDtoDealTypeEnum,
    React.ReactElement
  > = {
    LEASE: <LeaseIcon />,
    SALE: <SaleIcon />,
  };

  return (
    <div className='flex flex-row items-center gap-x-1 font-zen-body text-sm font-semibold text-primary-blue'>
      {typeToImageMap[dealType]}
      <span>{capitalizeEnum(dealType)}</span>
    </div>
  );
};

export default OfficeDealTypeCell;
