import { SvgIcon } from '@material-ui/core';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Delete } from '../../assets/icons/zen-delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/zen-edit-pencil.svg';
import { ItemResponse } from '../../openapi/sherlock';
import {
  FileBlock,
  HashtagBlock,
  HyperlinkBlock,
  ImageBlock,
  MentionBlock,
  ReadReceiptDto,
  ReferenceBlock,
  RezenObject,
  RezenObjectTypeEnum,
  TextBlock,
  UnknownBlock,
} from '../../openapi/yada';
import { RootState } from '../../types';
import {
  isFileBlock,
  isHashtagBlock,
  isHyperlinkBlock,
  isImageBlock,
  isMediaAudioBlock,
  isMentionBlock,
  isReferenceBlock,
  isTextBlock,
  isVoiceCallBlock,
} from '../../utils/TypeUtils';
import Avatar from '../Avatar';
import Hover from '../Hover';
import AttachedFile from '../transactions/Comments/AttachedFile';
import ChecklistReference from '../transactions/Comments/ChecklistReference';
import VoiceCallCard from '../transactions/Comments/Roar/VoiceCallCard';
import VoiceMemo from '../transactions/Comments/Roar/VoiceMemo';
import ZenMentionAgent from '../transactions/Comments/ZenMentionAgent';
import ReadReceiptsList from '../transactions/ReadReceiptsList';
import { cn } from '../../utils/classUtils';

export interface ZenRichCommentRowProps {
  side?: 'left' | 'right';
  fullName?: string;
  role: string;
  checkListItem?: ItemResponse;
  blocks?: Array<
    | FileBlock
    | HashtagBlock
    | HyperlinkBlock
    | ImageBlock
    | MentionBlock
    | ReferenceBlock
    | TextBlock
    | UnknownBlock
  >;
  avatarUrl?: string;
  timestamp: number;
  readReceipts: ReadReceiptDto[];
  onDelete?(): void;
  onEdit?(): void;
  refersTo?: RezenObject;
  isReferred?: boolean;
  isEdited?: boolean;
}

export interface BlockItem {
  text?: string;
  entity?: {
    id?: string;
    type?: string;
  };
  type?: string;
  url?: string;
  altText?: string;
  userId?: string;
  preview?: string;
}

export interface ReadReceiptProps {
  imageUrl?: string;
  firstName?: string;
  lastName?: string;
  id?: string;
  keymakerId?: string;
}

const ZenRichCommentRow: React.FC<ZenRichCommentRowProps> = ({
  side = 'left',
  fullName,
  role,
  blocks,
  avatarUrl,
  readReceipts,
  timestamp,
  onDelete,
  onEdit,
  refersTo,
  isReferred = false,
  isEdited,
}) => {
  const {
    userIds: { agentByKeymakerId },
    auth: { isAdmin, hasMortgagePermission },
  } = useSelector((state: RootState) => state);

  const users: ReadReceiptProps[] =
    agentByKeymakerId &&
    readReceipts?.map((item) => ({
      imageUrl: agentByKeymakerId[item.readerId]?.avatar,
      firstName: agentByKeymakerId[item.readerId]?.firstName,
      lastName: agentByKeymakerId[item.readerId]?.lastName,
      id: agentByKeymakerId[item.readerId]?.id,
      keymakerId: agentByKeymakerId[item.readerId]?.keymakerId,
    }));

  const headerElement = useMemo(() => {
    return (
      <div className='flex items-center space-x-2'>
        <Avatar name={fullName!} size='xxs' imageUrl={avatarUrl} />
        <p className='font-primary-regular text-sm text-gray-400 pr-4'>{`${fullName} - ${role}`}</p>
      </div>
    );
  }, [avatarUrl, fullName, role]);

  const footerElement = useMemo(() => {
    return (
      <div className='flex flex-row items-center justify-end py-1'>
        {role === 'Admin' && (
          <div className='font-primary-regular text-xs text-gray-400 mr-1'>
            (Visible to {role} Only) -
          </div>
        )}
        {isEdited && (
          <div className='font-primary-regular text-xs text-gray-400 mr-1'>
            Edited -
          </div>
        )}
        <div className='font-primary-regular text-xs text-gray-400'>
          {DateTime.fromMillis(timestamp || 0).toLocaleString(
            DateTime.TIME_SIMPLE,
          )}
        </div>
        {users && (
          <div>
            <ReadReceiptsList users={users} numToDisplay={3} />
          </div>
        )}
      </div>
    );
  }, [isEdited, role, timestamp, users]);

  const blockContainsGiphy = blocks?.find((el) => el.type === 'IMAGE');
  const voiceRecordingBlock = blocks?.find(isVoiceCallBlock);
  const mediaAudioBlock = blocks?.find((block) => isMediaAudioBlock(block));
  if (voiceRecordingBlock) {
    if (!voiceRecordingBlock.recordingAccessible) {
      return null;
    }

    const mentionBlock = blocks?.find(isMentionBlock);

    return (
      <VoiceCallCard
        recordingBlock={voiceRecordingBlock}
        mentionBlock={mentionBlock!}
        headerElement={headerElement}
        footerElement={footerElement}
        onDeleteOptionClick={onDelete}
      />
    );
  }

  return (
    <div
      className={classNames('flex flex-col pb-4', {
        'items-start': side === 'left',
        'items-end': side === 'right',
      })}
    >
      <div
        className={classNames(
          'flex flex-row items-start space-x-2 max-w-xl w-full',
          {
            'flex-row-reverse': side === 'right',
          },
        )}
      >
        <div
          className={classNames(side === 'left' ? '-mt-1' : 'mt-1', {
            'ml-2': side === 'right',
          })}
        >
          <Avatar name={fullName!} size='xxs' imageUrl={avatarUrl} />
        </div>
        <div className='flex-1 flex flex-row items-center space-x-3'>
          <div className='flex-1 flex flex-col space-y-1'>
            <div
              className={classNames('flex flex-grow flex-row items-center', {
                'justify-between': side === 'left',
                'justify-start': side === 'right',
              })}
            >
              {side === 'left' && (
                <p className='font-primary-regular text-sm text-gray-400 pr-4'>{`${fullName} - ${role}`}</p>
              )}
            </div>
            <div
              className={classNames('overflow-hidden', {
                'rounded-r-2xl rounded-bl-2xl border border-gray-200 -ml-8 mt-2':
                  side === 'left',
                'rounded-l-2xl rounded-br-2xl': side === 'right',
              })}
            >
              <div className='flex flex-col'>
                {!isReferred &&
                  refersTo?.type === RezenObjectTypeEnum.ChecklistItem && (
                    <ChecklistReference refersTo={refersTo} />
                  )}
                <div
                  className={classNames('px-4 py-2', {
                    'bg-primary-blue': side === 'right',
                    'bg-zen-light-blue': side === 'left',
                  })}
                >
                  <div
                    className={classNames(
                      'font-primary-regular text-base py-2 space-x-1',
                      {
                        'text-white': side === 'right',
                        'text-zen-dark': side === 'left',
                      },
                    )}
                  >
                    {blocks?.map((block) => {
                      if (isTextBlock(block)) {
                        return (
                          <span className='word-break whitespace-pre-wrap'>
                            {block.text}
                          </span>
                        );
                      }
                      if (isReferenceBlock(block)) {
                        return <p className='word-break'>{block.preview}</p>;
                      }
                      if (isImageBlock(block))
                        return (
                          <div className='w-full'>
                            <img src={block.url} alt={block.altText} />
                          </div>
                        );

                      if (isHyperlinkBlock(block))
                        return (
                          <a
                            href={block.url}
                            target='_blank'
                            className={classNames(
                              'px-2',
                              'py-0.5',
                              side === 'right'
                                ? 'bg-zen-primary-7'
                                : 'bg-zen-gray-2',
                              'flex-row',
                              side === 'right'
                                ? 'text-white'
                                : 'text-zen-dark-9',
                              'underline',
                              'font-zen-body-regular',
                              'text-sm',
                              'rounded-full',
                            )}
                            rel='noreferrer'
                          >
                            {block.text}
                          </a>
                        );

                      if (isFileBlock(block)) {
                        return (
                          <AttachedFile
                            altText={block.altText!}
                            url={block.url!}
                          />
                        );
                      }

                      if (isMentionBlock(block)) {
                        return (
                          <ZenMentionAgent id={block.userId!} side={side} />
                        );
                      }

                      if (isHashtagBlock(block)) {
                        return (
                          <p className='text-blue-700 font-primary-regular word-break'>
                            {block.hashtag}
                          </p>
                        );
                      }

                      if (isMediaAudioBlock(block)) {
                        return (
                          <div className='mt-4'>
                            <VoiceMemo
                              // @ts-expect-error
                              key={block.id}
                              audioBlock={block}
                            />
                          </div>
                        );
                      }

                      return block.preview;
                    })}
                  </div>
                  {(isAdmin || hasMortgagePermission) && side === 'right' && (
                    <div className='flex flex-row items-center justify-end space-x-1'>
                      {!blockContainsGiphy && (
                        <Hover
                          hoverComponent={
                            <div>
                              <p className='text-base font-semibold font-zen-body text-zen-dark-12 px-2'>
                                Edit
                              </p>
                            </div>
                          }
                          config={{
                            trigger: !!mediaAudioBlock ? null : 'hover',
                            placement: 'top',
                          }}
                        >
                          <button
                            disabled={!!mediaAudioBlock}
                            data-testid='editIcon'
                            className={cn(
                              'rounded-full p-1',
                              !!mediaAudioBlock
                                ? 'opacity-50'
                                : 'opacity-100 hover:bg-zen-light-blue-1 cursor-pointer',
                            )}
                            onClick={onEdit}
                          >
                            <SvgIcon
                              component={Edit}
                              viewBox='0 0 19 19'
                              className='ml-1 mt-1'
                            />
                          </button>
                        </Hover>
                      )}
                      <Hover
                        hoverComponent={
                          <div>
                            <p className='text-base font-semibold font-zen-body text-zen-dark-12 px-2'>
                              Delete
                            </p>
                          </div>
                        }
                        config={{ trigger: 'hover', placement: 'top' }}
                      >
                        <div
                          data-testid='deleteIcon'
                          className='cursor-pointer hover:bg-zen-light-blue-1 rounded-full p-1'
                          onClick={onDelete}
                        >
                          <SvgIcon
                            viewBox='0 0 19 19'
                            className='ml-1 mt-1'
                            component={Delete}
                          />
                        </div>
                      </Hover>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {footerElement}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenRichCommentRow;
