import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SponsorAgentInfoStatusEnum } from '../../openapi/yenta';
import { capitalizeEnum, formatPhoneNumber } from '../../utils/StringUtils';
import Hover from '../Hover';
import ZenAvatar from '../Zen/ZenAvatar';

export interface ZenUplineRowProps {
  name: string;
  level?: number;
  imageUrl: string;
  email: string;
  phone: string;
  sponsorSplit?: number;
  status: SponsorAgentInfoStatusEnum;
}

const ZenUplineRow: React.FC<ZenUplineRowProps> = ({
  name,
  level,
  imageUrl,
  email,
  phone,
  sponsorSplit,
  status,
}) => {
  const safeSponsorSplit = sponsorSplit === 45 ? 50 : sponsorSplit;

  return (
    <div className='py-3 flex flex-row justify-between items-start space-x-2'>
      <div className='flex flex-row items-center'>
        <ZenAvatar name={name} imageUrl={imageUrl} size='md' />
        <div className='flex flex-col pl-2'>
          <div className='flex flex-row items-start space-x-2'>
            <p className='font-zen-body text-zen-dark-9 text-base font-medium break-all'>
              {name}
            </p>
            <Hover
              hoverComponent={
                <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                  {email || 'N/A'}
                </div>
              }
              config={{ trigger: 'hover', placement: 'top' }}
            >
              <FontAwesomeIcon
                icon={regular('envelope')}
                title='email'
                className='mt-1 cursor-pointer text-primary-blue'
              />
            </Hover>
            <Hover
              hoverComponent={
                <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                  {formatPhoneNumber(phone) || 'N/A'}
                </div>
              }
              config={{ trigger: 'hover', placement: 'top' }}
            >
              <FontAwesomeIcon
                icon={regular('phone')}
                title='phone'
                className='mt-1 cursor-pointer text-base text-primary-blue'
              />
            </Hover>
          </div>
          {status && (
            <p className='font-zen-body font-medium text-sm text-zen-dark-7 opacity-80 -mt-1'>
              {capitalizeEnum(status)}
            </p>
          )}
        </div>
      </div>
      <div className='flex flex-row items-start flex-shrink-0'>
        <div className='flex flex-col items-center mr-3 font-zen-body text-sm'>
          <p className='font-semibold text-zen-dark-7'>
            {level ? `Level ${level}` : 'N/A'}
          </p>
          <p className='mt-0.5 font-medium text-zen-dark-7 opacity-80'>
            {sponsorSplit ? `${safeSponsorSplit}%` : 'N/A'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ZenUplineRow;
