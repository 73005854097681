import {
  AddressRequestCountryEnum,
  AddressResponseCountryEnum,
  AddressStateOrProvinceEnum,
  NationalIdentificationValue,
  NationalIdentificationValueTypeEnum,
  TaxValueTypeEnum,
} from '../openapi/yenta';

export const getTaxId = (
  type: NationalIdentificationValueTypeEnum,
  identifications: Array<NationalIdentificationValue> | undefined,
): string => {
  return (
    identifications?.find((identification) => identification.type === type)
      ?.id || ''
  );
};

export const getValidTaxIdTypes = (
  country: AddressRequestCountryEnum | AddressResponseCountryEnum,
): TaxValueTypeEnum[] => {
  if (!country) {
    return [];
  }

  if (country === AddressRequestCountryEnum.Canada) {
    return [
      TaxValueTypeEnum.Gst,
      TaxValueTypeEnum.Pst,
      TaxValueTypeEnum.Hst,
      TaxValueTypeEnum.Qst,
    ];
  }

  return [
    TaxValueTypeEnum.BAndO,
    TaxValueTypeEnum.Grt,
    TaxValueTypeEnum.Get,
    TaxValueTypeEnum.LaCbt,
    TaxValueTypeEnum.Cbj,
  ];
};

export const HST_ID_PROVINCES = [AddressStateOrProvinceEnum.Ontario];
export const GST_ID_PROVINCES = [
  AddressStateOrProvinceEnum.Alberta,
  AddressStateOrProvinceEnum.BritishColumbia,
  AddressStateOrProvinceEnum.Manitoba,
];
