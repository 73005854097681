import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import ZenIconCircleWithTooltipCell from '../Zen/Transaction/ZenIconCircleWithTooltipCell';
import { ISelectOption } from '../../types';
import { PairBase } from '../../openapi/leo';
import LQMSidebarModalForm from './LQMSidebarModalForm';
import LQMDeleteModal from './LQMDeleteModal';
import LQMAssignment from './LQMAssignment';

interface LQMActionCellProps {
  questionDetails: PairBase;
  refresh(): void;
}

export interface LQMActionsFormData {
  assigneeId: ISelectOption;
}

const LQMActionCell: React.FC<LQMActionCellProps> = ({
  questionDetails,
  refresh,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  return (
    <div>
      <div className='flex items-center space-x-4'>
        <div className='flex items-center px-2'>
          <LQMAssignment questionDetails={questionDetails} refresh={refresh} />
        </div>

        <ZenIconCircleWithTooltipCell
          tooltipText='Edit'
          icon={
            <FontAwesomeIcon
              icon={light('pencil')}
              className='text-zen-dark-8'
              aria-label='edit'
            />
          }
          onClick={() => setIsEditModalOpen(true)}
        />

        <ZenIconCircleWithTooltipCell
          tooltipText='Delete'
          icon={
            <FontAwesomeIcon
              icon={regular('trash-can')}
              className='text-zen-danger'
              aria-label='delete'
            />
          }
          onClick={() => setIsDeleteModalOpen(true)}
        />
      </div>
      {isDeleteModalOpen && (
        <LQMDeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          questionDetails={questionDetails}
          refresh={refresh}
        />
      )}
      {isEditModalOpen && (
        <LQMSidebarModalForm
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          questionDetails={questionDetails}
          refresh={refresh}
        />
      )}
    </div>
  );
};

export default LQMActionCell;
