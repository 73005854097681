import {
  AddressResponseStateOrProvinceEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
  NationalIdentificationValue,
  NationalIdentificationValueTypeEnum,
  OfficePreviewResponseTransactionTypeEnum,
} from '../openapi/yenta';
import { EnumMap } from '../types';
import { showType } from '../components/Zen/Transaction/ZenTaxInformationCard';

const transactionTypeMap: EnumMap<
  OfficePreviewResponseTransactionTypeEnum,
  string
> = {
  SINGLE_CHECK: 'Single Check',
  SPLIT_CHECK: 'Split Check',
};

export const transactionDisbursementTypeToDisplayName = (
  transactionType: OfficePreviewResponseTransactionTypeEnum,
) => {
  return transactionTypeMap[transactionType];
};

export const StateOrProvinceMap: EnumMap<
  AdministrativeAreaResponseStateOrProvinceEnum,
  string
> = {
  ALABAMA: 'Alabama',
  ALASKA: 'Alaska',
  ALBERTA: 'Alberta',
  ARIZONA: 'Arizona',
  ARKANSAS: 'Arkansas',
  BRITISH_COLUMBIA: 'British Columbia',
  CALIFORNIA: 'California',
  COLORADO: 'Colorado',
  CONNECTICUT: 'Connecticut',
  DELAWARE: 'Delaware',
  DISTRICT_OF_COLUMBIA: 'District Of Columbia',
  FLORIDA: 'Florida',
  GEORGIA: 'Georgia',
  HAWAII: 'Hawaii',
  IDAHO: 'Idaho',
  ILLINOIS: 'Illinois',
  INDIANA: 'Indiana',
  IOWA: 'Iowa',
  KANSAS: 'Kansas',
  KENTUCKY: 'Kentucky',
  LOUISIANA: 'Louisiana',
  MAINE: 'Maine',
  MANITOBA: 'Manitoba',
  MARYLAND: 'Maryland',
  MASSACHUSETTS: 'Massachusetts',
  MICHIGAN: 'Michigan',
  MINNESOTA: 'Minnesota',
  MISSISSIPPI: 'Mississippi',
  MISSOURI: 'Missouri',
  MONTANA: 'Montana',
  NEBRASKA: 'Nebraska',
  NEVADA: 'Nevada',
  NEWFOUNDLAND_AND_LABRADOR: 'Newfoundland And Labrador',
  NEW_BRUNSWICK: 'New Brunswick',
  NEW_HAMPSHIRE: 'New Hampshire',
  NEW_JERSEY: 'New Jersey',
  NEW_MEXICO: 'New Mexico',
  NEW_YORK: 'New York',
  NORTH_CAROLINA: 'North Carolina',
  NORTH_DAKOTA: 'North Dakota',
  NOVA_SCOTIA: 'Nova Scotia',
  OHIO: 'Ohio',
  OKLAHOMA: 'Oklahoma',
  ONTARIO: 'Ontario',
  OREGON: 'Oregon',
  PENNSYLVANIA: 'Pennsylvania',
  PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
  PUERTO_RICO: 'Puerto Rico',
  QUEBEC: 'Quebec',
  RHODE_ISLAND: 'Rhode Island',
  SASKATCHEWAN: 'Saskatchewan',
  SOUTH_CAROLINA: 'South Carolina',
  SOUTH_DAKOTA: 'South Dakota',
  TENNESSEE: 'Tennessee',
  TEXAS: 'Texas',
  UTAH: 'Utah',
  VERMONT: 'Vermont',
  VIRGINIA: 'Virginia',
  WASHINGTON: 'Washington',
  WEST_VIRGINIA: 'West Virginia',
  WISCONSIN: 'Wisconsin',
  WYOMING: 'Wyoming',
};

export const StateOrProvinceToDisplayName = (
  AdministrativeAreaResponseStateOrProvinceEnum:
    | AddressResponseStateOrProvinceEnum
    | AdministrativeAreaRequestStateOrProvinceEnum
    | AdministrativeAreaResponseStateOrProvinceEnum,
) => {
  return StateOrProvinceMap[AdministrativeAreaResponseStateOrProvinceEnum];
};

export const showEncryptedTextForNationIDs = (
  _value: NationalIdentificationValue,
): string => {
  // const NationIDsToEncryptMap: EnumMap<
  //   NationalIdentificationValueTypeEnum,
  //   string
  // > = {
  //   SSN: `••• - •• - ${value.id?.slice(value.id.length - 4)}`,
  //   SIN: `••• - ••• - ${value.id?.slice(value.id.length - 4)}`,
  //   BN: '.•••••••••',
  //   EIN: `•• - ${value.id?.slice(value.id.length - 7)}`,
  //   GST_ID: `•••••••••RT${value.id?.slice(value.id.length - 4)}`,
  //   PST_ID:
  //     value.id?.length !== 13
  //       ? 'PST - •••• - ••••'
  //       : `PST - •••• - ${value.id?.slice(value.id.length - 4)}`,
  //   HST_ID: `•••••••••RT${value.id?.slice(value.id.length - 4)}`,
  //   QST_ID: `••••••••••TQ${value.id?.slice(value.id.length - 4)}`,
  // };
  //
  // const encryptId = NationIDsToEncryptMap[value.type!];
  // return encryptId;

  return '•••••••••';
};

export const isVisibleTaxIds = (
  stateArray: showType[],
  type: NationalIdentificationValueTypeEnum,
): boolean => {
  return stateArray.find((id) => id.type === type && id.show === true)
    ? true
    : false;
};

export const showEncryptedApiKey = (value: string): string => {
  const dots = new Array(value.length - 6).fill('•').join('');
  const lastSixChars = value?.substring(value.length - 6);
  return dots + lastSixChars;
};

export const showEncryptedString = (value: string, limit = 4): string => {
  if (!value) {
    return value;
  }
  const diff = Math.max(value.length - limit, 0);
  const dots = new Array(diff).fill('*').join('');
  const remainingChars = value.substring(diff);
  return dots + remainingChars;
};
