import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faBadgeCheck, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ZenAvatar from '../Zen/ZenAvatar';

interface UserProfileProps {
  name?: string;
  linkedName?: string;
  avatarUrl?: string;
  role?: string;
  isRealVerified: boolean;
  isSelected?: boolean;
  onSelect(): void;
}

const ZenUserProfileCard: React.FC<UserProfileProps> = ({
  name,
  linkedName,
  role,
  isSelected,
  onSelect,
  isRealVerified,
}) => {
  const fullName = name ?? linkedName ?? '';

  return (
    <div
      className='flex flex-col cursor-pointer'
      onClick={onSelect}
      data-testid='directory-contact-list-card'
    >
      <div className='flex flex-row items-center justify-between'>
        <div className='flex flex-row items-center space-x-4'>
          <ZenAvatar
            name={fullName}
            colorClassName='bg-zen-blue-1'
            size='sm'
            textOverride='font-zen-body font-semibold text-white'
          />
          <div className='flex flex-col'>
            <div className='flex flex-shrink flex-wrap'>
              <p className='text-base font-semibold font-zen-body text-zen-dark-9'>
                {fullName}
              </p>
            </div>
            <div className='text-sm font-medium font-zen-body text-zen-dark-7'>
              <p>{role ?? ''}</p>
            </div>
          </div>
          {isRealVerified && (
            <FontAwesomeIcon
              icon={faBadgeCheck}
              className='w-5 h-6 text-green-600'
            />
          )}
        </div>
        <div>
          {isSelected ? (
            <FontAwesomeIcon
              icon={faCircleCheck}
              data-testid='directory-contact-list-card-selected'
              className='text-primary-blue w-6 h-6'
            />
          ) : (
            <FontAwesomeIcon
              icon={faCircle}
              data-testid='directory-contact-list-card-unselected'
              className='text-zen-dark-4 w-6 h-6'
            />
          )}
        </div>
      </div>
      <hr className='border-t border-gray-200 my-4' />
    </div>
  );
};

export default ZenUserProfileCard;
