import {
  faArrowUpRightFromSquare,
  faBullseyeArrow,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import AnalyticsService from '../../../services/AnalyticsService';
import { AnalyticsEventEnum, RootState } from '../../../types';
import { displayFormattedFixedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import { extraEarningPercentage } from '../../../utils/WealthPlanUtils';
import ResourceContainer from '../../ResourceContainer';
import {
  SHARRANS_PLAYBOOK_URL,
  WealthPlanTooltip,
} from '../WealthManagementUtils';

interface WealthGoalInfoProps {}

const WealthGoalInfo: React.FC<WealthGoalInfoProps> = () => {
  const {
    earningsOverviewResponse: { data, loading },
  } = useSelector((state: RootState) => state.wealthManagement);

  const goalAchieved =
    (data?.projectedEarningsValue.amount ?? 0) >= (data?.goalValue.amount ?? 0);

  const projectedEarnings = displayFormattedFixedAmountWithCurrency(
    data?.projectedEarningsValue,
    false,
    0,
  );

  const goalValue = displayFormattedFixedAmountWithCurrency(
    data?.goalValue,
    false,
    0,
  );

  const extraPercentage = extraEarningPercentage(
    data?.goalValue.amount! > 0 ? data?.goalValue.amount! : 1,
    data?.projectedEarningsValue.amount ?? 0,
  );

  return (
    <ResourceContainer
      loading={loading && !data}
      isEmpty={!data}
      resourceName='Wealth goal info'
    >
      <div className='rounded-xl bg-zen-light-blue font-zen-body-2 px-3 py-6 border border-zen-light-gray-2'>
        <div className='flex flex-row items-center space-x-2 text-zen-dark-13'>
          <FontAwesomeIcon icon={faBullseyeArrow} className='text-base' />
          <h2 className='text-base font-medium font-zen-title-2'>
            Your Projected Goal
          </h2>
        </div>
        <div className='mt-3 flex items-center justify-between'>
          <span className='text-sm leading-6 text-zen-dark-12'>
            Real Wealth Goal in 5 Years
          </span>
          <span className='text-[22px] font-semibold leading-10 text-zen-dark-9'>
            {goalValue}
          </span>
        </div>
        <div className='border-b border-zen-dark-4 self-stretch my-4' />
        <div className='flex items-center justify-between space-x-5'>
          <span className='text-sm leading-6 text-zen-dark-12 flex-shrink'>
            Projected Earnings in 5 Years
            <WealthPlanTooltip title='Projected Earnings in 5 Years represent your potential Real earnings based on the assumptions provided and includes your Real Wealth Earned-to-Date.'>
              <FontAwesomeIcon
                icon={faCircleInfo}
                className='text-sm text-zen-dark-8 ml-1 cursor-pointer'
              />
            </WealthPlanTooltip>
          </span>
          <span className='text-[22px] font-semibold leading-10 text-zen-dark-9'>
            {projectedEarnings}
          </span>
        </div>
        <div className='flex mt-3 px-3 py-2.5 text-zen-dark-9 rounded-md bg-regent-100 border border-zen-light-gray-2'>
          {goalAchieved ? (
            <div className='flex items-center space-x-2.5'>
              <div className='h-6 w-6 flex items-center justify-center'>
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  className='text-base'
                />
              </div>
              <p className='text-sm'>{`You're on track to earn ${extraPercentage} more than your goal!`}</p>
            </div>
          ) : (
            <div
              className='w-full text-base cursor-pointer'
              onClick={() => {
                AnalyticsService.instance().logEvent(
                  AnalyticsEventEnum.WEALTH_PLAN_OVERVIEW_SCREEN_GO_TO_SHARRAN_PLAYBOOK,
                );
                window.open(SHARRANS_PLAYBOOK_URL, '_blank');
              }}
            >
              <div className='flex items-center justify-between'>
                <div>
                  <p className='text-zen-dark-9 font-medium'>
                    Let&apos;s help you achieve your goal.
                  </p>
                  <span className='text-sm font-normal mt-2'>
                    Go to Sharran&apos;s Playbook
                  </span>
                </div>
                <div className='h-8 w-8 flex items-center justify-center bg-grey-100 shrink-0 rounded-full'>
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className='text-sm text-zen-dark-9'
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </ResourceContainer>
  );
};

export default WealthGoalInfo;
