import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import { transactionCheckDepositPayAtClosingSteps } from '../../../utils/CheckDepositsHelper';
import {
  EMAIL_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../utils/Validations';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenControlledEmailInput from '../../Zen/Input/ZenControlledEmailInput';
import ZenControlledPhoneNumberInput from '../../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenButton from '../../Zen/ZenButton';
import ApplicationStepProgressHeader from '../../onboarding/ApplicationStepProgressHeader';
import {
  DepositCheckFormData,
  DepositCheckStepName,
} from '../../../routes/ZenTransactionDepositCheckRoute';
import CheckDepositHeader from './CheckDepositHeader';
import withZenExternalCheckDepositProgress from './WithZenExternalCheckDepositProgress';

const ZenExternalDepositorDetailsStep: StepByStepComponent<
  DepositCheckFormData,
  DepositCheckStepName
> = ({ form: { watch, control }, onNext }) => {
  const {
    transaction: { tokenTransactionDetail },
  } = useSelector((state: RootState) => state);
  const [closingDate, showClosing, isExternalCheckDepositUpload] = watch([
    'closingDate',
    'showClosing',
    'isExternalCheckDeposit',
  ]);

  return (
    <div className='w-full h-full max-w-3xl py-4 mx-auto bg-white'>
      <div className='w-full mb-8'>
        {!isExternalCheckDepositUpload && showClosing && (
          <div className='flex justify-center mb-3'>
            <ApplicationStepProgressHeader
              steps={transactionCheckDepositPayAtClosingSteps(
                !watch('closeAt'),
              )}
              inProgressIndex={0}
            />
          </div>
        )}
      </div>
      <CheckDepositHeader
        transactionAddress={tokenTransactionDetail?.propertyAddress}
        closingDate={closingDate}
      />
      <div className='flex flex-row items-start space-x-2 mt-12'>
        <FontAwesomeIcon
          icon={faInfoCircle}
          className='text-zen-dark-8 mr-2 mt-1'
          size='2x'
        />
        <p className='font-primary-regular text-base text-zen-dark-8'>
          After entering the information below, on the next step, you will need
          to upload pictures of the front and back of the check.
        </p>
      </div>
      <div>
        <div className='mt-8'>
          <ZenControlledTextInput<DepositCheckFormData, 'depositorName'>
            name='depositorName'
            control={control}
            shouldUnregister={false}
            label='Depositor’s Full Name'
            placeholder='E.g. James Adams'
            rules={{ required: 'Depositor’s Full Name is required' }}
            isRequired
          />
        </div>
        <div className='mt-8'>
          <ZenControlledEmailInput<DepositCheckFormData, 'depositorEmail'>
            name='depositorEmail'
            control={control}
            shouldUnregister={false}
            label='Email Address'
            placeholder='E.g. jamesadams@example.com'
            rules={{
              required: 'Email Address is required',
              ...EMAIL_VALIDATIONS,
            }}
            isRequired
          />
        </div>
        <div className='mt-8'>
          <ZenControlledPhoneNumberInput<
            DepositCheckFormData,
            'depositorPhoneNumber'
          >
            name='depositorPhoneNumber'
            control={control}
            shouldUnregister={false}
            label='Phone Number'
            placeholder='E.g. + 1 (123) 123-1234'
            rules={{
              required: 'Phone number is required',
              ...PHONE_NUMBER_VALIDATIONS,
            }}
            isRequired
          />
        </div>
        <div className='w-full mt-8'>
          <ZenButton
            label='Next'
            variant='primary'
            onClick={onNext}
            isFullWidth
          />
        </div>
      </div>
    </div>
  );
};

export default withZenExternalCheckDepositProgress(
  ZenExternalDepositorDetailsStep,
);
