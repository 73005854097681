import { filter } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faPlus } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowRight,
  faArrowUpArrowDown,
} from '@fortawesome/pro-regular-svg-icons';
import { ExternalParticipantInfoRoleEnum } from '../../../../openapi/arrakis';
import AnalyticsService from '../../../../services/AnalyticsService';
import { saveParticipantInfo } from '../../../../slices/TransactionBuilderSlice';
import { AnalyticsEventEnum, AppDispatch } from '../../../../types';
import {
  EMAIL_VALIDATIONS,
  GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../../utils/Validations';
import IconButton from '../../../IconButton';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledGoogleAutocompleteSearchInput from '../../Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledPhoneNumberInput from '../../Input/ZenControlledPhoneNumberInput';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenButton from '../../ZenButton';
import ZenContactListSidebar from '../../../Directory/ZenContactListSidebar';
import {
  DirectoryCommonEntityResponse,
  useDirectoryAddress,
} from '../../../../query/directory/useDirectory';
import { DirectoryPersonResponseRoleEnum } from '../../../../openapi/yenta';
import ZenRoleDirectoryForm from '../../../Directory/ZenRoleDirectoryForm';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
  Match,
} from './ZenCreateTransactionSteps';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';
import { FieldArrayPrefix } from './ZenTransactionBuyerAndSellerStep';

const ZenSellerLawyerInformationStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({
  form: { control, watch, trigger, setValue, getValues },
  onPrevious,
  onNext,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { transactionBuilderId } = useParams<Match>();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [directoryFormOpen, setDirectoryFormOpen] = useState<boolean>(false);
  const [currentFormContact, setCurrentFormContact] = useState<
    DirectoryCommonEntityResponse | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [isManual, setIsManual] = useState<boolean>(true);
  const { getGeocodeAddress } = useDirectoryAddress();

  const sellerLawyerInfo = watch('sellerLawyerInfo');

  const handleSellerLawyerInfoStep = async () => {
    const isValid = await trigger();
    if (
      isValid &&
      Object.keys(filter(sellerLawyerInfo, (f) => !!f)).length > 1
    ) {
      setLoading(true);
      const sellerLawyerInfoAddress = isManual
        ? sellerLawyerInfo.address
        : sellerLawyerInfo.autoCompleteAddress?.formatted_address;
      if (sellerLawyerInfoAddress) {
        AnalyticsService.instance().logEvent(
          isManual
            ? AnalyticsEventEnum.CREATE_TX_SELLER_LAWYER_ADDRESS_ENTERED_MANUALLY
            : AnalyticsEventEnum.CREATE_TX_SELLER_LAWYER_ADDRESS_ENTERED_W_GOOGLE_SEARCH,
        );
      }
      const res = await dispatch(
        saveParticipantInfo(
          transactionBuilderId!,
          ExternalParticipantInfoRoleEnum.SellersLawyer,
          sellerLawyerInfo.firstName,
          sellerLawyerInfo.lastName,
          sellerLawyerInfo.companyName,
          sellerLawyerInfo.email,
          sellerLawyerInfo.phoneNumber,
          sellerLawyerInfoAddress,
        ),
      );
      setLoading(false);
      if (res) {
        onNext();
      }
    } else {
      onNext();
    }
  };

  const handleSidebarToggle = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleSave = (
    _fieldArrayPrefix?: FieldArrayPrefix | string,
    contact?: DirectoryCommonEntityResponse,
  ) => {
    setValue('sellerLawyerInfo.address', contact?.address?.oneLine);
    setValue(
      'sellerLawyerInfo.firstName',
      contact?.linkedPersons?.[0]?.firstName! || contact?.firstName!,
    );
    setValue(
      'sellerLawyerInfo.lastName',
      contact?.linkedPersons?.[0]?.lastName! || contact?.lastName!,
    );
    setValue('sellerLawyerInfo.email', contact?.emailAddress!);
    setValue('sellerLawyerInfo.phoneNumber', contact?.phoneNumber!);
    setValue('sellerLawyerInfo.companyName', contact?.name!);
  };

  const handleAddToMyDirectory = async () => {
    const values = getValues('sellerLawyerInfo');
    const geoAddress =
      values?.autoCompleteAddress ??
      (await getGeocodeAddress(values?.address || ''));
    setCurrentFormContact({
      ...values,
      emailAddress: values?.email,
      address: {
        oneLine: geoAddress?.formatted_address ?? undefined,
      },
      addressComponents: geoAddress?.address_components ?? [],
      placeId: geoAddress?.place_id,
      name: values?.companyName!,
      role: DirectoryPersonResponseRoleEnum.Lawyer,
    });
    setDirectoryFormOpen((prevState) => !prevState);
  };

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <p className='text-xl font-zen-title font-medium text-zen-dark-9 mb-4'>
          Who is the seller’s lawyer?
        </p>
        <div className='flex flex-row-reverse md:whitespace-pre -mt-1.5'>
          <div className='whitespace-nowrap'>
            <ZenButton
              label='Directory'
              variant='primary-link'
              LeftIconComponent={<FontAwesomeIcon icon={faAddressBook} />}
              onClick={handleSidebarToggle}
              fontstyle
              zeroPadding
            />
          </div>
        </div>
        <div className='space-y-4'>
          <ZenControlledTextInput<
            CreateTransactionFormState,
            'sellerLawyerInfo.companyName'
          >
            control={control}
            label='Law Firm Name'
            name='sellerLawyerInfo.companyName'
            shouldUnregister={false}
            placeholder='E.g. Dream Home LLC.'
          />
          <div className='flex flex-col  relative'>
            {isManual ? (
              <div className='mt-3'>
                <ZenControlledTextInput<
                  CreateTransactionFormState,
                  'sellerLawyerInfo.address'
                >
                  control={control}
                  label='Law Firm Address'
                  name='sellerLawyerInfo.address'
                  shouldUnregister={false}
                  placeholder='E.g. 1st St. New York, NY 10010'
                />
              </div>
            ) : (
              <div className='mt-3'>
                <ZenControlledGoogleAutocompleteSearchInput<
                  CreateTransactionFormState,
                  `sellerLawyerInfo.autoCompleteAddress`
                >
                  control={control}
                  shouldUnregister={false}
                  name='sellerLawyerInfo.autoCompleteAddress'
                  label='Search Address'
                  subLabel='(Source by Google)'
                  placeholder='E.g. 1st St. New York, NY 10010'
                  rules={{
                    ...GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
                  }}
                  data-testid='search-address'
                />
              </div>
            )}

            <div className='absolute top-0 right-0'>
              <IconButton
                label={isManual ? 'Search Address' : 'Enter address manually'}
                variant='none'
                buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
                leftIcon={
                  <FontAwesomeIcon icon={faArrowUpArrowDown} className='p-1' />
                }
                onClick={() => {
                  setIsManual(!isManual);
                }}
              />
            </div>
          </div>

          <div className='flex flex-row space-x-4'>
            <ZenControlledTextInput<
              CreateTransactionFormState,
              'sellerLawyerInfo.firstName'
            >
              control={control}
              label='Lawyer First Name'
              name='sellerLawyerInfo.firstName'
              shouldUnregister={false}
              placeholder='E.g. John'
              maxLength={50}
            />
            <ZenControlledTextInput<
              CreateTransactionFormState,
              'sellerLawyerInfo.lastName'
            >
              control={control}
              label='Lawyer Last Name'
              name='sellerLawyerInfo.lastName'
              shouldUnregister={false}
              placeholder='E.g. Doe'
              maxLength={50}
            />
          </div>
          <div className='flex flex-row space-x-4'>
            <ZenControlledTextInput<
              CreateTransactionFormState,
              'sellerLawyerInfo.email'
            >
              control={control}
              label='Email'
              name='sellerLawyerInfo.email'
              shouldUnregister={false}
              placeholder='E.g. John@example.com'
              rules={{ ...EMAIL_VALIDATIONS }}
            />
            <ZenControlledPhoneNumberInput<
              CreateTransactionFormState,
              'sellerLawyerInfo.phoneNumber'
            >
              control={control}
              label='Phone Number'
              name='sellerLawyerInfo.phoneNumber'
              shouldUnregister={false}
              placeholder='E.g. +1 (123) 456 789'
              rules={{ ...PHONE_NUMBER_VALIDATIONS }}
            />
          </div>
          <div className='pb-10 pt-5 font-zen-body'>
            <div className='flex justify-between items-center mb-1'>
              <p>Don&apos;t have Lawyer information right now?</p>
              <ZenButton
                label='Add to My Directory'
                variant='primary-link'
                isSubmitting={false}
                isDisabled={false}
                LeftIconComponent={
                  <FontAwesomeIcon
                    icon={faPlus}
                    className='text-primary-blue cursor-pointer'
                  />
                }
                onClick={handleAddToMyDirectory}
                fontstyle
              />
            </div>
            <ZenButton
              label='Skip this section'
              onClick={onNext}
              variant='primary-outline'
              RightIconComponent={
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className='text-primary-blue mt-1'
                />
              }
            />
          </div>
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              isDisabled={loading}
              label='Next'
              onClick={handleSellerLawyerInfoStep}
            />
          </div>
        </div>
      </div>
      {isSidebarOpen && (
        <ZenContactListSidebar
          isOpen={isSidebarOpen}
          onClose={handleSidebarToggle}
          onSave={handleSave}
          participantRole='LAWYER'
        />
      )}
      {directoryFormOpen && (
        <ZenRoleDirectoryForm
          isOpen={directoryFormOpen}
          existingVendorOrPerson={currentFormContact}
          mode='populate'
          onClose={handleAddToMyDirectory}
          isExternal
        />
      )}
    </div>
  );
};

export default withCreateTransactionProgress(ZenSellerLawyerInformationStep);
