import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form-v7';
import { EMAIL_VALIDATIONS } from '../utils/Validations';
import { UserControllerApi } from '../openapi/keymaker';
import { getKeymakerConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showSuccessToast } from '../slices/ToastNotificationSlice';
import { showApiErrorModal } from '../slices/ErrorSlice';
import ErrorService from '../services/ErrorService';
import ZenSidebarModal from '../components/Zen/ZenSidebarModal';
import ZenSidebarModalActionFooter from '../components/SidebarModal/ZenSideBarModalActionFooter';
import ZenControlledEmailInput from '../components/Zen/Input/ZenControlledEmailInput';

interface ZenPromoteAdministratorFormSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  email: string;
}

const ZenPromoteAdministratorFormSidebarModal: React.FC<ZenPromoteAdministratorFormSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit = async (data: FormData) => {
    try {
      await new UserControllerApi(getKeymakerConfiguration()).makeAdministrator(
        {
          usernameOrEmail: data.email,
        },
      );
      onClose();
      dispatch(showSuccessToast('Promoted to administrator successfully!'));
    } catch (event) {
      dispatch(showApiErrorModal(event));
      ErrorService.notifyIgnoreAuthErrors(
        'Error promoting to administrator',
        event,
        {
          data: {
            usernameOrEmail: data.email,
          },
        },
      );
    }
  };

  return (
    <ZenSidebarModal
      title='Promote Administrator'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='px-4'>
          <div className='mt-5'>
            <ZenControlledEmailInput
              name='email'
              label='Login Email'
              subLabel='(You can find this email in their security profile. It may be different from their business email)'
              control={control}
              placeholder='johndoe@gmail.com'
              rules={{
                required: 'Email address is required.',
                ...EMAIL_VALIDATIONS,
              }}
            />
          </div>
        </div>
        <ZenSidebarModalActionFooter
          onClose={onClose}
          isSubmitting={isSubmitting}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenPromoteAdministratorFormSidebarModal;
