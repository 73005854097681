import React from 'react';
import ZenPopover from '../../../ZenPopover';

interface RealBrokerageFeePopoverProps {}

const RealBrokerageFeePopover: React.FC<RealBrokerageFeePopoverProps> = () => {
  return (
    <div className='font-inter text-sm text-zen-dark-9'>
      Real Brokerage Fee
      <ZenPopover
        title='Real Brokerage Fee'
        description={
          <div className='text-zen-dark-9 text-base font-light'>
            <p>
              Real charges a{' '}
              <span className='font-bold'>$750 annual brokerage fee</span>. The
              Brokerage Fee is collected from the first three transactions each
              anniversary year.
            </p>
            <p className='mt-4'>
              Select the default percentage split for the Annual Brokerage Fee
              between Team Leader and Teammates (or members within your team).
            </p>
          </div>
        }
      />
    </div>
  );
};

export default RealBrokerageFeePopover;
