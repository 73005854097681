import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateTaxPaidFromCommission } from '../../../slices/TransactionSlice';
import ZenToggleRow from '../Input/ZenToggleRow';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import { AnalyticsEventEnum, AppDispatch } from '../../../types';
import AnalyticsService from '../../../services/AnalyticsService';

interface ZenTransactionTaxPaidFromCommissionProps {
  id: string;
  taxPaidFromCommission: boolean;
  isReadonly?: boolean;
}

const ZenTransactionTaxPaidFromCommission: React.FC<ZenTransactionTaxPaidFromCommissionProps> = ({
  id,
  taxPaidFromCommission,
  isReadonly,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <div className='flex flex-row items-center'>
      {!isReadonly && (
        <ZenToggleRow
          onChange={() => setIsOpen(true)}
          value={taxPaidFromCommission}
        />
      )}
      <p>{taxPaidFromCommission ? 'Yes' : 'No'}</p>

      <ZenConfirmationModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        variant='primary'
        isSubmitting={isSubmitting}
        isDisabled={isSubmitting}
        onConfirm={async () => {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.TAX_COLLECTION_CLICK_TAX_PAID_FROM_COMMISSION_ON_TRANSACTION,
          );
          setIsSubmitting(true);
          await dispatch(
            updateTaxPaidFromCommission(id, {
              taxPaidFromCommission: !taxPaidFromCommission,
            }),
          );
          setIsSubmitting(false);
          setIsOpen(false);
        }}
        cancelButtonText='No'
        confirmButtonText='Yes'
        title={
          taxPaidFromCommission
            ? 'Set as tax not paid from commission?'
            : 'Set transaction as tax paid from commission?'
        }
        subtitle={
          taxPaidFromCommission
            ? 'This action will set the transaction to tax not paid from commission. Please make sure to upload required documentation. Set transaction to tax not paid from commission?'
            : 'This action will set this transaction as tax paid from commission. Please make sure to upload required documentation. Set as tax paid from commission?'
        }
      />
    </div>
  );
};

export default ZenTransactionTaxPaidFromCommission;
