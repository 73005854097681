import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BankAccountDto,
  MoneyTransferControllerApi,
  MoneyTransferCreateRequest,
  MoneyTransferResponse,
  MoneyTransferUpdateRequest,
  TransactionControllerApi,
} from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import { AppThunk, ErrorCode, MoneyTransferState } from '../types';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showErrorToast } from './ToastNotificationSlice';

export const initialState: MoneyTransferState = {
  moneyTransfers: [],
  moneyTransferLoading: false,
  moneyTransferErrorCode: null,
  bankAccounts: [],
};

const MoneyTransferSlice = createSlice({
  name: 'MoneyTransfer',
  initialState,
  reducers: {
    changeMoneyTransferLoading(state, action: PayloadAction<boolean>) {
      state.moneyTransferLoading = action.payload;
    },
    errorFetchingMoneyTransferErrorCode(
      state,
      action: PayloadAction<ErrorCode>,
    ) {
      state.moneyTransferErrorCode = action.payload;
    },
    saveMoneyTransfers(state, action: PayloadAction<MoneyTransferResponse[]>) {
      state.moneyTransfers = action.payload;
    },
    addMoneyTransfer(state, action: PayloadAction<MoneyTransferResponse>) {
      state.moneyTransfers = [...state.moneyTransfers, action.payload];
    },
    updateMoneyTransfer(state, action: PayloadAction<MoneyTransferResponse>) {
      const moneyTransferIndex = state.moneyTransfers?.findIndex(
        (moneyTransfer) => moneyTransfer.id === action.payload.id,
      );

      if (moneyTransferIndex !== -1) {
        state.moneyTransfers![moneyTransferIndex!] = action.payload;
      }
    },
    removeMoneyTransfer(state, action: PayloadAction<string>) {
      state.moneyTransfers = state.moneyTransfers.filter(
        (moneyTransfer) => moneyTransfer.id !== action.payload,
      );
    },
    saveBankAccounts(state, action: PayloadAction<BankAccountDto[]>) {
      state.bankAccounts = action.payload;
    },
  },
});

export const {
  changeMoneyTransferLoading,
  errorFetchingMoneyTransferErrorCode,
  saveMoneyTransfers,
  addMoneyTransfer,
  updateMoneyTransfer,
  removeMoneyTransfer,
  saveBankAccounts,
} = MoneyTransferSlice.actions;

export const fetchMoneyTransfers = (transactionId: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeMoneyTransferLoading(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).getTransactionMoneyTransfers(transactionId);
    dispatch(saveMoneyTransfers(data));
  } catch (e) {
    dispatch(errorFetchingMoneyTransferErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem fetching money transfers.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notifyIgnoreAuthErrors('Unable to fetch money transfers', e);
  } finally {
    dispatch(changeMoneyTransferLoading(true));
  }
};

export const createMoneyTransfer = (
  moneyTransferCreateRequest: MoneyTransferCreateRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeMoneyTransferLoading(true));
  try {
    const { data } = await new MoneyTransferControllerApi(
      getArrakisConfiguration(),
    ).createMoneyTransfer(moneyTransferCreateRequest);
    dispatch(addMoneyTransfer(data));
  } catch (e) {
    dispatch(errorFetchingMoneyTransferErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem creating money transfer.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to creating money transfers', e);
  } finally {
    dispatch(changeMoneyTransferLoading(true));
  }
};

export const updateMoneyTransferDispatch = (
  moneyTransferId: string,
  moneyTransferUpdateRequest: MoneyTransferUpdateRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeMoneyTransferLoading(true));
  try {
    const { data } = await new MoneyTransferControllerApi(
      getArrakisConfiguration(),
    ).updateMoneyTransfer(moneyTransferId, moneyTransferUpdateRequest);
    dispatch(updateMoneyTransfer(data));
  } catch (e) {
    dispatch(errorFetchingMoneyTransferErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem updating money transfer.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to updating money transfers', e);
  } finally {
    dispatch(changeMoneyTransferLoading(true));
  }
};

export const deleteMoneyTransfer = (
  moneyTransferId: string,
): AppThunk => async (dispatch) => {
  dispatch(changeMoneyTransferLoading(true));
  try {
    await new MoneyTransferControllerApi(
      getArrakisConfiguration(),
    ).deleteMoneyTransfer(moneyTransferId);
    dispatch(removeMoneyTransfer(moneyTransferId));
  } catch (e) {
    dispatch(errorFetchingMoneyTransferErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem deleting money transfer.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to deleting money transfers', e);
  } finally {
    dispatch(changeMoneyTransferLoading(true));
  }
};

export const confirmMoneyTransfer = (
  moneyTransferId: string,
): AppThunk => async (dispatch) => {
  dispatch(changeMoneyTransferLoading(true));
  try {
    const { data } = await new MoneyTransferControllerApi(
      getArrakisConfiguration(),
    ).confirmMoneyTransfer(moneyTransferId);
    dispatch(updateMoneyTransfer(data));
  } catch (e) {
    dispatch(errorFetchingMoneyTransferErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem confirming money transfer.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to confirming money transfers', e);
  } finally {
    dispatch(changeMoneyTransferLoading(true));
  }
};

export const fetchBankAccounts = (transactionId: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).getBankAccounts(transactionId);
    dispatch(saveBankAccounts(data));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem fetching bank accounts.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notifyIgnoreAuthErrors('Unable to fetch bank accounts', e);
  }
};

export default MoneyTransferSlice.reducer;
