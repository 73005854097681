import React, { useState } from 'react';
import ZenToggleRow from '../Input/ZenToggleRow';

interface ZenTransactionToggleDocsReceiveProps {
  receivesDocs: boolean;
  onToggle(): Promise<void>;
}

const ZenTransactionToggleDocsReceive: React.FC<ZenTransactionToggleDocsReceiveProps> = ({
  receivesDocs,
  onToggle,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className='flex justify-start md:justify-center'>
      <ZenToggleRow
        loading={loading}
        value={receivesDocs}
        onChange={async () => {
          setLoading(true);
          await onToggle();
          setLoading(false);
        }}
      />
    </div>
  );
};

export default ZenTransactionToggleDocsReceive;
