import { AsyncPaginate } from 'react-select-async-paginate';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import React from 'react';
import { MenuPosition } from 'react-select';
import {
  AsyncSelectAdditional,
  AsyncSelectOption,
  FormFieldTooltipIndexProps,
} from '../types';
import { DEFAULT_PAGE_NUM } from '../constants/AsyncSelectPaginationConstants';
import FormErrorMessage from './FormErrorMessage';
import IconButton from './IconButton';

export const ASYNC_MULTI_SELECT_DEBOUNCE = 400;

interface ControlledAsyncMultiSelectInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  subLabel?: string;
  isRequired?: boolean;
  placeholder?: string;
  fetchData(search: string, page?: number): Promise<Array<AsyncSelectOption>>;
  disabled?: boolean;
  rightActionText?: string;
  rightActionIcon?: React.ReactElement;
  disableRightText?: boolean;
  onRightAction?: () => void;
  menuPosition?: MenuPosition;
}

const ControlledAsyncMultiSelectInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  isRequired = false,
  placeholder = 'Select',
  disabled = false,
  fetchData,
  disableRightText = false,
  rightActionText,
  rightActionIcon,
  onRightAction,
  shouldUnregister = true,
  menuPosition,
  ...rest
}: ControlledAsyncMultiSelectInputV7Props<TFieldValues, TName>) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <div className='flex flex-row items-center'>
              <label
                className='inline-block flex-grow flex-shrink'
                htmlFor={name}
              >
                <span>{label}</span>
                {!!subLabel && (
                  <span className='font-primary-regular text-sm text-gray-400 ml-1'>
                    {subLabel}
                  </span>
                )}
                {isRequired && <span className='text-error'>*</span>}
              </label>
              {rightActionText && (
                <IconButton
                  buttonType='button'
                  variant='none'
                  label={rightActionText}
                  textStyle='text-primary'
                  leftIcon={rightActionIcon}
                  onClick={onRightAction}
                />
              )}
            </div>
          )}
          <AsyncPaginate
            placeholder={placeholder}
            value={value}
            name={name}
            isMulti
            menuPosition={menuPosition}
            defaultValue={value}
            aria-label={label}
            closeMenuOnSelect={false}
            // @ts-ignore
            loadOptions={async (
              search,
              _additionalOptions,
              { page }: AsyncSelectAdditional,
            ) => {
              const data = await fetchData(search, page);
              return {
                options: data,
                hasMore: !!data.length,
                additional: {
                  page: page + 1,
                },
              };
            }}
            additional={{
              page: DEFAULT_PAGE_NUM,
            }}
            debounceTimeout={ASYNC_MULTI_SELECT_DEBOUNCE}
            onChange={onChange}
            className='react-select-container'
            classNamePrefix='react-select'
            inputId={name}
            isDisabled={disabled}
          />
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledAsyncMultiSelectInputV7;
