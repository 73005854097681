import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';
import { WebhookDto } from '../../openapi/plutus';
import { deleteWebhook, updateWebhookStatus } from '../../slices/WebhookSlice';
import { RootState } from '../../types';
import ZenSimpleModal from '../Zen/Modal/ZenSimpleModal';
import ZenButton from '../Zen/ZenButton';

interface WebhookTableActionCellProps {
  webhook: WebhookDto;
}

type ModalTypes = 'active' | 'disabled' | 'delete';

const modalTypeToTitleMap: {
  [type in ModalTypes]: string;
} = {
  active: 'Are you sure you want to disable this Endpoint?',
  disabled: 'Are you sure you want to enable this Endpoint?',
  delete: 'Are you sure you want to delete this Endpoint?',
};

const modalTypeToDescriptionMap: {
  [type in ModalTypes]: string;
} = {
  active:
    'This webhook endpoint may be temporarily disabled so that it will not receive notifications until it is enabled again. Real will not retry any notifications that are generated while the endpoint is disabled.',
  disabled: `This webhook is disabled and no longer receives notifications. This may have been done automatically because we detected an extended period of failures. If you've corrected the issue, you can re-enable the webhook endpoint here. Real will not retry any notifications that were generated in the intervening period, and if we continue to detect failures on this endpoint, we will disable the endpoint again.`,
  delete:
    'After deleting this endpoint no future webhook will be sent to its URL.',
};

const WebhookTableActionCell: React.FC<WebhookTableActionCellProps> = ({
  webhook,
}) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const history = useHistory();

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalTypes | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const isWebhookActive = !!webhook?.active;

  const handleModalSubmit = async () => {
    setIsSubmitting(true);

    if (modalType === 'delete') {
      await dispatch(deleteWebhook(webhook?.id!, userDetail?.id!));
    } else {
      await dispatch(
        updateWebhookStatus(webhook?.id!, !isWebhookActive, userDetail?.id!),
      );
    }

    setIsSubmitting(false);
    setModalType(undefined);
  };

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom']}
        reposition={false}
        padding={5}
        align='end'
        onClickOutside={() => setIsPopoverOpen(false)}
        containerClassName='fixed z-50'
        content={() => {
          return (
            <div className='bg-white rounded-md py-2 items-start text-dark drop-shadow-md flex flex-col gap-2.5 pl-3 pr-5'>
              <div
                className='flex gap-1 cursor-pointer'
                onClick={() => history.push(`/webhook/create/${webhook?.id}`)}
              >
                <FontAwesomeIcon
                  icon={regular('pencil')}
                  className='mx-1 mt-2'
                />

                <span className='mt-0.5 -ml-0.5 font-zen-body text-base'>
                  Edit
                </span>
              </div>

              <div
                className='flex gap-1 cursor-pointer'
                onClick={() => {
                  setModalType(isWebhookActive ? 'active' : 'disabled');
                  setIsPopoverOpen(false);
                }}
              >
                <FontAwesomeIcon
                  icon={isWebhookActive ? regular('ban') : regular('check')}
                  className='text-sm mx-1 mt-2'
                />
                <span className='mt-0.5 font-zen-body text-base'>
                  {isWebhookActive ? 'Disable' : 'Enable'}
                </span>
              </div>

              <div
                className='flex flex-row text-zen-danger items-start justify-center gap-1 cursor-pointer'
                onClick={() => {
                  setModalType('delete');
                  setIsPopoverOpen(false);
                }}
              >
                <FontAwesomeIcon
                  icon={regular('trash-can')}
                  className='text-sm mx-1 mt-1.5'
                />
                <span className='mt-px font-zen-body'>Delete</span>
              </div>
            </div>
          );
        }}
      >
        <FontAwesomeIcon
          aria-label='user_options'
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          icon={regular('ellipsis-vertical')}
          className='text-zen-dark-6 text-lg cursor-pointer'
        />
      </Popover>

      {modalType && (
        <ZenSimpleModal
          isOpen={!!modalType}
          title={modalTypeToTitleMap[modalType]}
          onClose={() => setModalType(undefined)}
          size='extraLarge'
        >
          <div className='px-10 py-5'>
            <p className='text-base text-center text-zen-dark-13 font-normal font-zen-body'>
              {modalTypeToDescriptionMap[modalType]}
            </p>

            <div className='items-center gap-2 justify-center flex mt-7 space-x-3'>
              <div className='w-40'>
                <ZenButton
                  label='Cancel'
                  variant='secondary-outline'
                  onClick={() => setModalType(undefined)}
                  isDisabled={isSubmitting}
                  isFullWidth
                />
              </div>

              <div className='w-40'>
                <ZenButton
                  label={
                    modalType === 'active'
                      ? 'Disable'
                      : modalType === 'delete'
                      ? 'Delete'
                      : 'Enable'
                  }
                  isSubmitting={isSubmitting}
                  isDisabled={isSubmitting}
                  variant={modalType === 'delete' ? 'danger' : 'primary'}
                  onClick={handleModalSubmit}
                  isFullWidth
                />
              </div>
            </div>
          </div>
        </ZenSimpleModal>
      )}
    </>
  );
};

export default WebhookTableActionCell;
