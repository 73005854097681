import { cn } from '../utils/classUtils';
import { ISelectOption } from '../types';

interface CustomGroupRadioButtonProps {
  items: Array<ISelectOption>;
  onChange(value: string): void;
  value: string;
  disabled?: boolean;
}

const CustomGroupRadioButton: React.FC<CustomGroupRadioButtonProps> = ({
  items,
  onChange,
  value,
  disabled,
}) => {
  return (
    <div
      className='flex flex-row items-center'
      key={items.map((i) => i.value).join()}
    >
      {items.map((item, index) => {
        const isActive = item.value === value;
        return (
          <button
            className={cn(
              'flex-grow flex-shrink items-center text-center px-2 py-3 border outline-none focus:outline-none',
              isActive
                ? 'bg-primary border-primary bg-opacity-20'
                : 'bg-white border-gray-300 bg-opacity-95',
              index === items.length - 1
                ? 'rounded-r-md'
                : index === 0
                ? 'rounded-l-md'
                : 'rounded-none',
            )}
            type='button'
            name={item.label}
            key={item.label}
            onClick={() => onChange(item.value)}
            disabled={disabled}
          >
            <span
              className={cn(
                'font-primary-medium flex-wrap text-sm',
                isActive
                  ? 'text-gray-700 font-semibold'
                  : 'text-gray-600 font-medium',
              )}
            >
              {item.label}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default CustomGroupRadioButton;
