import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CreateOrUpdateAgentWebsiteRequestPhotoSetEnum } from '../openapi/yenta';
import { agentCoverSetMap } from '../testUtils/AgentWebsiteOnboardingUtils';
import { ISelectOption } from '../types';
import { cn } from '../utils/classUtils';

interface CustomImageSelectInputProps {
  item: ISelectOption;
  isActive: boolean;
  onChange(value: string): void;
  disabled?: boolean;
}

const CustomImageSelectInput: React.FC<CustomImageSelectInputProps> = ({
  item,
  isActive,
  onChange,
  disabled,
}) => {
  return (
    <div className='flex flex-col items-start space-y-3'>
      <div
        className='w-48 h-24'
        style={{
          backgroundImage: `url(${
            agentCoverSetMap[
              item.value as CreateOrUpdateAgentWebsiteRequestPhotoSetEnum
            ].small
          })`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <button
          className={cn(
            'w-full h-full outline-none focus:outline-none relative',
            isActive ? 'border-4' : 'border-0',
            isActive ? 'border-primary' : 'border-white',
          )}
          type='button'
          onClick={() => onChange(item.value)}
          aria-label={item.label}
          disabled={disabled}
        >
          <div
            className={cn(
              'absolute w-5 h-5 rounded-full flex justify-center items-center',
              isActive ? 'bg-primary' : 'bg-white',
              isActive ? 'bottom-2.5' : 'bottom-3',
              isActive ? 'right-2' : 'right-3',
            )}
          >
            {isActive && (
              <FontAwesomeIcon
                icon={faCheck}
                size='sm'
                className='mt-0.5 text-white'
              />
            )}
          </div>
        </button>
      </div>
      <p
        className={cn(
          'font-primary-regular text-sm',
          isActive ? 'font-semibold' : 'font-normal',
          isActive ? 'text-dark' : 'text-gray-500',
        )}
      >
        {item.label}
      </p>
    </div>
  );
};

export default CustomImageSelectInput;
