import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveBreakRoomType } from '../../../slices/KBarSlice';
import { BreakRoomEnum, RootState } from '../../../types';
import BreakRoomModal from '../../Zen/Modal/BreakRoomModal';
import SnakeGame from './SnakeGame';

export interface BreakRoomIndexProps {}

const BreakRoomIndex: React.FC<BreakRoomIndexProps> = () => {
  const dispatch = useDispatch();
  const {
    kBar: { breakRoomType },
  } = useSelector((state: RootState) => state);

  const breakRoom = useMemo(() => {
    switch (breakRoomType) {
      case BreakRoomEnum.SNAKE_GAME:
        return <SnakeGame />;
      default:
        return <div> THIS IS A BREAK ROOM </div>;
    }
  }, [breakRoomType]);

  useEffect(() => {
    const isScrollVisible = window.innerWidth < 1000;
    if (isScrollVisible) {
      document.body.style.overflow = !!breakRoomType ? 'hidden' : 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [breakRoomType]);

  if (!breakRoomType) {
    return null;
  }

  return (
    <BreakRoomModal
      isOpen
      onClose={() => dispatch(saveBreakRoomType(undefined))}
    >
      {breakRoom}
    </BreakRoomModal>
  );
};

export default BreakRoomIndex;
