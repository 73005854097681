import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'react-table';
import pluralize from 'pluralize';
import {
  EscrowDepositResponse,
  EscrowResponse,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { RootState } from '../../../types';
import {
  isFTNISettlementIdAvailableForTrustAccount,
  isTransactionOrFinanceAdmin,
  isTransactionReadOnly,
} from '../../../utils/TransactionHelper';
import { cn } from '../../../utils/classUtils';
import ZenDepositAddForm from '../ZenDepositAddForm';
import { isAllDepositsConfirmed } from '../../../utils/DepositsUtil';
import ZenAddInstallmentOptions from './ZenAddInstallmentOptions';

interface ZenTrustDepositInstallmentsCellProps {
  deposits: EscrowDepositResponse[];
  escrow: EscrowResponse;
  transaction: TransactionResponse;
  row: Row<EscrowResponse>;
}

const ZenTrustDepositInstallmentsCell: React.FC<ZenTrustDepositInstallmentsCellProps> = ({
  escrow,
  transaction,
  row,
  deposits,
}) => {
  const [isOpenDepositModal, SetIsOpenDepositModal] = useState<boolean>(false);
  const [deposit, setDeposit] = useState<EscrowDepositResponse>();

  const {
    // userIds: { agentById },
    auth: { userDetail, keymakerCurrentUser },
    office: { officeDetailById },
  } = useSelector((state: RootState) => state);

  const office = transaction?.office?.id
    ? officeDetailById[transaction.office.id]
    : undefined;

  useEffect(() => {
    if (deposits.length && !row.isExpanded) {
      row.toggleRowExpanded();
    }
  }, [deposits.length, row]);

  const showAddInstallment =
    !isAllDepositsConfirmed(escrow) &&
    (!isTransactionReadOnly(transaction, userDetail!, {
      skipBrokerCheck: true,
    }) ||
      (isTransactionOrFinanceAdmin(keymakerCurrentUser?.activeRoles!) &&
        transaction.terminated));

  return (
    <div>
      <div
        data-testid='toggleInstallment'
        className='-mt-1 lg:inline-flex items-center text-zen-dark-9 font-zen-body font-medium'
      >
        <div className='md:whitespace-nowrap text-zen-dark-9 text-sm font-medium font-zen-body'>
          {deposits.length} {pluralize('Installments', deposits.length)}
        </div>
        <div
          className={cn({
            invisible: !showAddInstallment,
          })}
        >
          <ZenAddInstallmentOptions
            onSelectManual={() => {
              setDeposit(undefined);
              SetIsOpenDepositModal(true);
            }}
            showCheckDeposit={isFTNISettlementIdAvailableForTrustAccount(
              office,
            )}
            transaction={transaction}
            escrow={escrow}
          />
        </div>
      </div>

      {isOpenDepositModal && (
        <ZenDepositAddForm
          onClose={() => SetIsOpenDepositModal(false)}
          depositDetails={deposit}
          transaction={transaction}
          escrowId={escrow.id!}
        />
      )}
    </div>
  );
};

export default ZenTrustDepositInstallmentsCell;
