import { DateTime } from 'luxon';
import { WaveSurferParams } from 'wavesurfer.js/types/params';
import { values } from 'lodash';
import { CreateAnnouncementFormData } from '../components/Zen/Announcements/CreateAnnouncementSidebarModel';
import {
  AgentIdOneOf,
  AgentJoinedBetween,
  AgentStateOneOf,
  AgentStateOneOfStateOrProvincesEnum,
  AnnouncementDefLiteResponseStatusEnum,
  AnnouncementDefRequest,
  AnnouncementDefRequestDriverEnum,
  AnnouncementDefRequestStatusEnum,
  AnnouncementDefResponse,
  AnnouncementResponse,
  DisplayCondition,
  PlatformOneOf,
  PlatformOneOfPlatformsEnum,
  AgentHasCapStatus,
  UserRoleOneOf,
  AgentMemberOfTeam,
} from '../openapi/mercury';
import { AgentResponseAccountCountryEnum } from '../openapi/yenta';
import { EnumMap, YesNoType } from '../types';
import { MAX_DISMISSAL_COUNT } from '../constants/AnnouncementConstants';
import { US_STATES, CANADIAN_STATES } from './StateUtils';
import { capitalizeEnum } from './StringUtils';
import { parseUrl } from './UrlUtils';

export enum ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION {
  AgentHasCapStatus = 'AgentHasCapStatus',
  AgentIdOneOf = 'AgentIdOneOf',
  AgentJoinedBetween = 'AgentJoinedBetween',
  AgentHasPreCapStatus = 'AgentHasPreCapStatus',
  AgentHasEliteStatus = 'AgentHasEliteStatus',
  UserRoleOneOf = 'UserRoleOneOf',
  AgentMemberOfTeam = 'AgentMemberOfTeam',
}

export enum OTHER_ANNOUNCEMENT_RECIPIENT_CONDITION {
  AgentStateOneOf = 'AgentStateOneOf',
  NoDisplayCondition = 'NoDisplayCondition',
  AllConditionsMet = 'AllConditionsMet',
  PlatformOneOf = 'PlatformOneOf',
}

export enum ANNOUNCEMENT_LOCATION_TYPE {
  countries = 'Countries',
  states = 'States',
}

export enum ANNOUNCEMENT_PLATFORM_TYPE {
  both = 'BOTH',
  bolt = 'BOLT',
  app = 'APP',
}

export enum ANNOUNCEMENT_USER_ROLE {
  agent = 'AGENT',
  broker = 'BROKER',
}

export const AnnounceRecipientEnumAndLabelMapping: EnumMap<
  ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION,
  string
> = {
  [ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentJoinedBetween]:
    'Agents joined within specific date range',
  [ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentIdOneOf]: 'Individual Users',
  [ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentHasCapStatus]: 'Capped Agents',
  [ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.UserRoleOneOf]: 'User Roles',
  [ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentMemberOfTeam]: 'Teams',
  [ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentHasPreCapStatus]: 'Pre-Capped',
  [ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentHasEliteStatus]: 'Elite',
};

export const getRequestRecipientStates = (
  states: (
    | AgentStateOneOfStateOrProvincesEnum
    | AgentResponseAccountCountryEnum
  )[],
) => {
  const isUnitedStatesSelected = states.includes(
    AgentResponseAccountCountryEnum.UnitedStates,
  );
  const isCanadaSelected = states.includes(
    AgentResponseAccountCountryEnum.Canada,
  );

  if (isUnitedStatesSelected && isCanadaSelected) {
    return [...US_STATES, ...CANADIAN_STATES];
  }

  if (isUnitedStatesSelected) {
    return US_STATES;
  }

  if (isCanadaSelected) {
    return CANADIAN_STATES;
  }

  return states;
};

export const getConditions = (
  values: CreateAnnouncementFormData,
): DisplayCondition[] => {
  const {
    announcementFor,
    announcementForJoinedBetween,
    allUserIds: ids,
    stateOrProvinces,
    selectSpecificRecipients,
    platformType,
    userRole,
  } = values;

  let conditions = [];

  if (selectSpecificRecipients === YesNoType.NO) {
    conditions.push({
      $type: OTHER_ANNOUNCEMENT_RECIPIENT_CONDITION.NoDisplayCondition,
    });
    return conditions;
  }

  if (
    announcementFor?.value ===
    ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentJoinedBetween
  ) {
    conditions.push({
      startDate: announcementForJoinedBetween.startDate,
      endDate: announcementForJoinedBetween.endDate,
      $type: announcementFor?.value,
    });
  }
  if (
    announcementFor?.value ===
    ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentIdOneOf
  ) {
    conditions.push({
      ids,
      $type: announcementFor?.value,
    });
  }
  if (
    announcementFor?.value ===
    ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentHasCapStatus
  ) {
    conditions.push({
      capped: true,
      $type: announcementFor?.value,
    });
  }
  if (
    announcementFor?.value ===
    ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentHasPreCapStatus
  ) {
    conditions.push({
      capped: false,
      $type: ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentHasCapStatus,
    });
  }
  if (
    announcementFor?.value ===
    ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentHasEliteStatus
  ) {
    conditions.push({
      elite: true,
      $type: announcementFor?.value,
    });
  }
  if (!!stateOrProvinces?.length) {
    conditions.push({
      stateOrProvinces: getRequestRecipientStates(
        stateOrProvinces.map(({ value }) => value),
      ),
      $type: OTHER_ANNOUNCEMENT_RECIPIENT_CONDITION.AgentStateOneOf,
    });
  }
  if (platformType) {
    let platformTypeArray =
      platformType === ANNOUNCEMENT_PLATFORM_TYPE.both
        ? [ANNOUNCEMENT_PLATFORM_TYPE.app, ANNOUNCEMENT_PLATFORM_TYPE.bolt]
        : [platformType];
    conditions.push({
      platforms: platformTypeArray,
      $type: OTHER_ANNOUNCEMENT_RECIPIENT_CONDITION.PlatformOneOf,
    });
  }
  if (
    announcementFor?.value ===
    ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.UserRoleOneOf
  ) {
    conditions.push({
      roles: [userRole],
      $type: announcementFor?.value,
    });
  }
  if (
    announcementFor?.value ===
    ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentMemberOfTeam
  ) {
    conditions.push({
      teamIds: values.allTeamIds,
      $type: announcementFor?.value,
    });
  }

  return conditions;
};

export const getCreateAnnouncementRequest = (
  values: CreateAnnouncementFormData,
): AnnouncementDefRequest => {
  const req: AnnouncementDefRequest = {
    driver: values?.driver,
    dismissible: !values?.mandatoryActionRequired,
    status: values?.status,
    maxDismissalCount: MAX_DISMISSAL_COUNT,
    deliverAfter: values?.deliverAfter
      ? DateTime.fromISO(values?.deliverAfter).toMillis()
      : undefined,
    expiresAt: values?.expiresAt
      ? DateTime.fromISO(values?.expiresAt).toMillis()
      : undefined,
    msgTitle: values?.msgTitle,
    msgDetails: values?.msgDetails,
    msgHyperlinkTitle: values?.msgHyperlinkTitle,
    msgHyperlink: parseUrl(values?.msgHyperlink) ?? '',
    video: (values?.video as unknown) as File,
    audio: (values?.audio as unknown) as File,
    banner: (values?.banner as unknown) as File,
    displayCondition: {
      conditions: getConditions(values),
      $type: OTHER_ANNOUNCEMENT_RECIPIENT_CONDITION.AllConditionsMet,
    },
  };

  return req;
};

export const announcementStatusToDisplayName = (
  status: AnnouncementDefLiteResponseStatusEnum,
) => {
  if (status === AnnouncementDefLiteResponseStatusEnum.Active) {
    return 'Published';
  }

  return capitalizeEnum(status);
};

export const WaveformCreateConfig: WaveSurferParams = {
  barWidth: 3,
  barRadius: 4,
  barGap: 6,
  cursorWidth: 1,
  container: '#waveform',
  backend: 'WebAudio',
  height: 56,
  progressColor: '#3B82F6',
  responsive: true,
  waveColor: '#CDDAFC',
  cursorColor: 'transparent',
  closeAudioContext: true,
  removeMediaElementOnDestroy: true,
};

export const getFormRecipientStates = (
  states: AgentStateOneOfStateOrProvincesEnum[],
) => {
  // checking if currently added states were added as countries or states.
  // If every current state is in US_STATES or CANADIAN_STATES or both,
  // then locationType will be country and stateAndProvince will be US or Canada or both
  const isUS = US_STATES.every((el) =>
    states.includes((el as any) as AgentStateOneOfStateOrProvincesEnum),
  );
  const isCanada = CANADIAN_STATES.every((el) =>
    states.includes((el as any) as AgentStateOneOfStateOrProvincesEnum),
  );

  // Adding countries when locationType is country
  if (isUS && isCanada) {
    return {
      sops: [
        AgentResponseAccountCountryEnum.UnitedStates,
        AgentResponseAccountCountryEnum.Canada,
      ],
    };
  }
  if (isUS) {
    return {
      sops: [AgentResponseAccountCountryEnum.UnitedStates],
    };
  }
  if (isCanada) {
    return {
      sops: [AgentResponseAccountCountryEnum.Canada],
    };
  }

  // not US or Canada
  return {
    sops: states,
    isLocationTypeStates: true,
  };
};

export const getAnnouncementDefaultValues = (
  announcement?: AnnouncementDefResponse,
): CreateAnnouncementFormData => {
  let announcementFor, allUserIds, allTeamIds, stateOrProvinces;
  let announcementForJoinedBetween: {
    startDate: string | undefined;
    endDate: string | undefined;
  } = {
    startDate: undefined,
    endDate: undefined,
  };
  let selectSpecificRecipients = !!announcement?.displayCondition
    ? YesNoType.YES
    : YesNoType.NO;
  let locationType = ANNOUNCEMENT_LOCATION_TYPE.countries;
  let platformType = ANNOUNCEMENT_PLATFORM_TYPE.both;
  let userRole = ANNOUNCEMENT_USER_ROLE.agent;

  announcement?.displayCondition?.conditions?.forEach((el) => {
    const condition = el['$type'] as
      | ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION
      | OTHER_ANNOUNCEMENT_RECIPIENT_CONDITION;
    // setting top condition as default agentType condition. It should never be OTHER_ANNOUNCEMENT_RECIPIENT_CONDITION.
    if (
      values(ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION).includes(
        condition as ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION,
      )
    ) {
      announcementFor = {
        label:
          AnnounceRecipientEnumAndLabelMapping[
            condition as ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION
          ],
        value: condition,
      };
    }
    if (
      condition === OTHER_ANNOUNCEMENT_RECIPIENT_CONDITION.NoDisplayCondition
    ) {
      selectSpecificRecipients = YesNoType.NO;
      return;
    }
    if (
      condition === ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentJoinedBetween
    ) {
      announcementForJoinedBetween = {
        startDate: (el as AgentJoinedBetween)?.startDate,
        endDate: (el as AgentJoinedBetween)?.endDate,
      };
      return;
    }
    if (condition === ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentIdOneOf) {
      allUserIds = (el as AgentIdOneOf).ids;
      return;
    }
    if (condition === OTHER_ANNOUNCEMENT_RECIPIENT_CONDITION.AgentStateOneOf) {
      const { sops, isLocationTypeStates } = getFormRecipientStates(
        (el as AgentStateOneOf).stateOrProvinces,
      );
      stateOrProvinces = sops.map((el) => ({
        label: capitalizeEnum(el),
        value: el,
      }));

      if (isLocationTypeStates) {
        locationType = ANNOUNCEMENT_LOCATION_TYPE.states;
      }
      return;
    }
    if (
      condition === ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentHasCapStatus &&
      (el as AgentHasCapStatus).capped === false
    ) {
      announcementFor = {
        label:
          AnnounceRecipientEnumAndLabelMapping[
            ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentHasPreCapStatus
          ],
        value: ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentHasPreCapStatus,
      };
      return;
    }
    if (condition === OTHER_ANNOUNCEMENT_RECIPIENT_CONDITION.PlatformOneOf) {
      let platformOneOf = (el as PlatformOneOf).platforms;
      if (
        platformOneOf.includes(PlatformOneOfPlatformsEnum.Bolt) &&
        platformOneOf.includes(PlatformOneOfPlatformsEnum.App)
      ) {
        platformType = ANNOUNCEMENT_PLATFORM_TYPE.both;
      } else if (platformOneOf.includes(PlatformOneOfPlatformsEnum.Bolt)) {
        platformType = ANNOUNCEMENT_PLATFORM_TYPE.bolt;
      } else if (platformOneOf.includes(PlatformOneOfPlatformsEnum.App)) {
        platformType = ANNOUNCEMENT_PLATFORM_TYPE.app;
      }
      return;
    }
    if (condition === ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.UserRoleOneOf) {
      userRole = ((el as UserRoleOneOf)
        .roles[0] as unknown) as ANNOUNCEMENT_USER_ROLE;
      return;
    }
    if (
      condition === ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentMemberOfTeam
    ) {
      allTeamIds = (el as AgentMemberOfTeam).teamIds;
      return;
    }
  });

  const defaultValues = {
    maxDismissalCount: announcement?.maxDismissalCount ?? 0,
    msgRepeatIntervalInHours: announcement?.msgRepeatIntervalInHours ?? 0,
    msgTitle: announcement?.msgTitle ?? '',
    msgDetails: announcement?.msgDetails ?? '',
    msgHyperlinkTitle: announcement?.msgHyperlinkTitle ?? '',
    msgHyperlink: '',
    ...(announcement as AnnouncementResponse),
    mandatoryActionRequired: !!announcement
      ? !announcement?.dismissible
      : false,
    driver:
      ((announcement?.driver as unknown) as AnnouncementDefRequestDriverEnum) ||
      AnnouncementDefRequestDriverEnum.Time,
    status:
      ((announcement?.status as any) as AnnouncementDefRequestStatusEnum) ||
      AnnouncementDefRequestStatusEnum.Draft,
    audio: announcement?.signedMsgAudioUrl,
    video: announcement?.signedMsgVideoUrl,
    banner: announcement?.signedMsgBannerUrl,
    deliverAfter: announcement?.deliverAfter
      ? DateTime.fromMillis(announcement?.deliverAfter!).toISO()
      : undefined,
    expiresAt: announcement?.expiresAt
      ? DateTime.fromMillis(announcement?.expiresAt!).toISO()
      : undefined,
    announcementFor,
    announcementForJoinedBetween,
    allUserIds,
    allTeamIds,
    existingAudioDeleted: false,
    existingVideoDeleted: false,
    selectSpecificRecipients,
    stateOrProvinces,
    locationType,
    platformType,
    userRole,
  };

  return defaultValues;
};

export const createAnnouncementResponseFromDefinition = (
  announcementDef: AnnouncementDefResponse,
): AnnouncementResponse => {
  return {
    dismissible: true,
    dismissCount: 0,
    maxDismissalCount: 10,
    msgTitle: announcementDef?.msgTitle,
    msgDetails: announcementDef?.msgDetails,
    msgHyperlink: announcementDef?.msgHyperlink,
    msgHyperlinkTitle: announcementDef?.msgHyperlinkTitle,
    msgVideoLocation: announcementDef?.msgVideoLocation,
    msgAudioLocation: announcementDef?.msgAudioLocation,
    signedMsgVideoUrl: announcementDef?.signedMsgVideoUrl,
    signedMsgAudioUrl: announcementDef?.signedMsgAudioUrl,
    signedMsgBannerUrl: announcementDef?.signedMsgBannerUrl,
  };
};

export const STATE_OR_PROVINCE_ABBREVIATIONS = {
  UNITED_STATES: 'US',
  CANADA: 'CANADA',
  ALABAMA: 'AL',
  ALASKA: 'AK',
  ARIZONA: 'AZ',
  ARKANSAS: 'AR',
  CALIFORNIA: 'CA',
  COLORADO: 'CO',
  CONNECTICUT: 'CT',
  DELAWARE: 'DE',
  DISTRICT_OF_COLUMBIA: 'DC',
  FLORIDA: 'FL',
  GEORGIA: 'GA',
  GUAM: 'GU',
  HAWAII: 'HI',
  IDAHO: 'ID',
  ILLINOIS: 'IL',
  INDIANA: 'IN',
  IOWA: 'IA',
  KANSAS: 'KS',
  KENTUCKY: 'KY',
  LOUISIANA: 'LA',
  MAINE: 'ME',
  MICHIGAN: 'MI',
  MINNESOTA: 'MN',
  MASSACHUSETTS: 'MA',
  MARYLAND: 'MD',
  MISSISSIPPI: 'MS',
  MISSOURI: 'MO',
  MONTANA: 'MT',
  NEBRASKA: 'NE',
  NEVADA: 'NV',
  NEW_HAMPSHIRE: 'NH',
  NORTH_CAROLINA: 'NC',
  NEW_JERSEY: 'NJ',
  NEW_MEXICO: 'NM',
  NEW_YORK: 'NY',
  NORTH_DAKOTA: 'ND',
  OHIO: 'OH',
  OKLAHOMA: 'OK',
  OREGON: 'OR',
  PENNSYLVANIA: 'PA',
  PUERTO_RICO: 'PR',
  RHODE_ISLAND: 'RI',
  SOUTH_CAROLINA: 'SC',
  SOUTH_DAKOTA: 'SD',
  TENNESSEE: 'TN',
  TEXAS: 'TX',
  UTAH: 'UT',
  VERMONT: 'VT',
  VIRGINIA: 'VA',
  WASHINGTON: 'WA',
  WEST_VIRGINIA: 'WV',
  WISCONSIN: 'WI',
  WYOMING: 'WY',
  ALBERTA: 'AB',
  BRITISH_COLUMBIA: 'BC',
  MANITOBA: 'MB',
  NEW_BRUNSWICK: 'NB',
  NEWFOUNDLAND_AND_LABRADOR: 'NL',
  NOVA_SCOTIA: 'NS',
  ONTARIO: 'ON',
  PRINCE_EDWARD_ISLAND: 'PE',
  QUEBEC: 'QC',
  SASKATCHEWAN: 'SK',
};
