import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import ZenResourceContainer from '../../../Zen/ZenResourceContainer';
import OutOfOfficeEmpty from './OutOfOfficeEmpty';
import OutOfOfficeForm from './OutOfOfficeForm';
import OutOfOfficeStatus from './OutOfOfficeStatus';
import RemoveOutOfOffice from './RemoveOutOfOffice';

type ZenOutOfOfficeProps = {
  agentId: string;
};

const ZenOutOfOffice: React.FC<ZenOutOfOfficeProps> = ({ agentId }) => {
  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const agentDetail = useSelector(
    (state: RootState) => state.agentDetail.detailResponse.data,
  );
  const outOfOffice = agentDetail?.availability?.outOfOffice?.[0];

  return (
    <>
      <ZenResourceContainer
        loading={false}
        isEmpty={isEmpty(outOfOffice)}
        resourceName='out of office'
        EmptyComponent={
          <OutOfOfficeEmpty onClick={() => setShowScheduleModal(true)} />
        }
      >
        <OutOfOfficeStatus
          agentDetail={agentDetail}
          onClickEdit={() => setShowScheduleModal(true)}
          onClickDelete={() => setShowDeleteModal(true)}
        />
      </ZenResourceContainer>
      {showScheduleModal && (
        <OutOfOfficeForm
          agentId={agentId}
          agentDetail={agentDetail}
          onClose={() => setShowScheduleModal(false)}
        />
      )}
      {showDeleteModal && (
        <RemoveOutOfOffice
          agentId={agentId}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};

export default ZenOutOfOffice;
