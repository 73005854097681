import React from 'react';
import PageLayout from '../components/PageLayout';
import ResourceContainer from '../components/ResourceContainer';
import ZenRoute from '../components/Zen/ZenRoute';
import { MetabaseDashboardUrlResponse } from '../openapi/yenta';

interface MetabaseBrokerDashboardProps {
  metabaseUrl: MetabaseDashboardUrlResponse;
  isLoading: boolean;
}

const MetabaseBrokerDashboard: React.FC<MetabaseBrokerDashboardProps> = ({
  isLoading,
  metabaseUrl,
}) => {
  return (
    <ZenRoute title='Broker Dashboard'>
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Broker Dashboard', url: '/dashboard' },
        ]}
      >
        <ResourceContainer
          isEmpty={!metabaseUrl.urls?.length}
          loading={isLoading}
          resourceName='Metabase Dashboard'
        >
          {metabaseUrl?.urls?.map((url) => {
            return (
              <div className='flex h-[calc(100vh_-_50px)]' key={url}>
                <iframe title='metabase' src={url} width='100%' height='100%' />
              </div>
            );
          })}
        </ResourceContainer>
      </PageLayout>
    </ZenRoute>
  );
};

export default MetabaseBrokerDashboard;
