import React from 'react';
import ZenRoute from '../components/Zen/ZenRoute';
import PageLayout from '../components/PageLayout';
import RealClosingServicesHeaderSection from '../components/RealTitle/RealClosingServicesHeaderSection';
import RealClosingServicesLandingSection from '../components/RealTitle/RealClosingServicesLandingSection';
import RealClosingServicesCoverageSection from '../components/RealTitle/RealClosingServicesCoverageSection';
import RealClosingServicesNeedEstimateSection from '../components/RealTitle/RealClosingServicesNeedEstimateSection';
import RealClosingServicesSubmitOrderSection from '../components/RealTitle/RealClosingServicesSubmitOrderSection';
import RealClosingServicesPortalAndWorkplace from '../components/RealTitle/RealClosingServicesPortalAndWorkplace';
import RealClosingServicesRequestEventSupport from '../components/RealTitle/RealClosingServicesRequestEventSupport';
import RealClosingServicesServicesSection from '../components/RealTitle/RealClosingServicesServicesSection';

const RealClosingServicesRoute: React.FC = () => {
  return (
    <ZenRoute title='Real Closing Services'>
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Real Closing Services', url: '/real-title' },
        ]}
      >
        <div className='md:p-10 p-4 flex flex-col gap-12'>
          <div>
            <RealClosingServicesHeaderSection />
            <RealClosingServicesLandingSection />
          </div>
          <RealClosingServicesCoverageSection />
          <RealClosingServicesServicesSection />
          <RealClosingServicesSubmitOrderSection />
          <RealClosingServicesNeedEstimateSection />
          <RealClosingServicesPortalAndWorkplace />
          <RealClosingServicesRequestEventSupport />
        </div>
      </PageLayout>
    </ZenRoute>
  );
};

export default RealClosingServicesRoute;
