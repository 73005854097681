import React from 'react';
import { Control } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  faChevronDown,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserResponseAgentStatusEnum } from '../../openapi/yenta';
import { ReportFormData } from '../../routes/ReportsRoute';
import ErrorService from '../../services/ErrorService';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { AsyncSelectOptionReactElement } from '../../types';
import { STATE_OR_PROVINCE_ABBREVIATIONS } from '../../utils/AnnouncementUtils';
import { searchForAgents } from '../../utils/TableUtils';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';
import AvatarLabelComponent from './AvatarLabelComponent';

interface AdminAgentSelectorProps {
  control: Control<ReportFormData, object>;
}

const AdminAgentSelector: React.FC<AdminAgentSelectorProps> = ({ control }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <ZenControlledAsyncSelectInput<ReportFormData, 'agent'>
        control={control}
        name='agent'
        placeholder='Search Agent'
        label='Select Agent'
        startAdornment={
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className='text-blue-500 mx-2'
          />
        }
        endAdornment={
          <FontAwesomeIcon
            icon={faChevronDown}
            className='text-gray-500 mx-3'
          />
        }
        selectClassName='border-r'
        fetchData={async (search, page) => {
          try {
            const { data } = await searchForAgents({
              page,
              search,
              filterBy: {
                agentStatus: [
                  UserResponseAgentStatusEnum.Active,
                  UserResponseAgentStatusEnum.Inactive,
                ],
              },
            });

            const options: AsyncSelectOptionReactElement[] = data.map(
              (agent) => ({
                value: `${agent.id}`,
                label: (
                  <AvatarLabelComponent
                    avatar={agent?.avatar!}
                    firstName={agent?.firstName!}
                    lastName={agent?.lastName!}
                    emailAddress={agent?.emailAddress!}
                    administrativeAreas={agent?.administrativeAreas?.map(
                      (e) => {
                        if (!e.stateOrProvince) {
                          return 'N/A';
                        }
                        return STATE_OR_PROVINCE_ABBREVIATIONS[
                          e.stateOrProvince
                        ];
                      },
                    )}
                  />
                ),
              }),
            );

            return options;
          } catch (e) {
            ErrorService.notify(
              'Unable to search for registered agents in create team form',
              e,
              {
                search: {
                  term: search,
                  page: 50,
                },
              },
            );
            dispatch(
              showErrorToast(
                'An unexpected error occurred.',
                'We were unable to search for an agent. Please try again in a few moments or contact support.',
              ),
            );
          }

          return [];
        }}
        rules={{
          required: 'Select an agent for whom you want to generate a report',
        }}
      />
    </div>
  );
};

export default AdminAgentSelector;
