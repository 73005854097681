import { BoardResponseStatusEnum } from '../../../../openapi/yenta';
import { EnumMap } from '../../../../types';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import ZenStatusPill, { PillVariantType } from '../../ZenStatusPill';
import { safeEnumMapGet } from '../../../../utils/EnumHelper';

interface ZenBoardStatusPillProps {
  status: BoardResponseStatusEnum;
}

const statusToVariant: EnumMap<BoardResponseStatusEnum, PillVariantType> = {
  ACTIVE: 'success',
  INACTIVE: 'danger',
  NOT_MEMBERS: 'warning',
  PENDING: 'warning',
  WILLING_TO_JOIN: 'primary',
};

const ZenBoardStatusPill: React.FC<ZenBoardStatusPillProps> = ({ status }) => {
  const variant = safeEnumMapGet(statusToVariant, status, 'default');
  return <ZenStatusPill text={capitalizeEnum(status)} variant={variant} />;
};

export default ZenBoardStatusPill;
