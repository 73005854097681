import { AgentResponse } from '../../../openapi/yenta';
import ZenCoverImage from '../ZenCoverImage';
import ZenPublicProfileDetailCard from './ZenPublicProfileDetailCard';

interface ZenPublicProfileDetailHeaderProps {
  agentInfo: AgentResponse;
}

const ZenPublicProfileDetailHeader: React.FC<ZenPublicProfileDetailHeaderProps> = ({
  agentInfo,
}) => {
  return (
    <div>
      <div className='md:hidden block'>
        <ZenCoverImage />
      </div>
      <div className='md:sticky md:z-10' style={{ top: 0 }}>
        <div className='relative px-4 md:px-0'>
          <ZenPublicProfileDetailCard agentInfo={agentInfo} />
        </div>
      </div>
      <div className='md:block hidden'>
        <ZenCoverImage />
      </div>
    </div>
  );
};

export default ZenPublicProfileDetailHeader;
