import React from 'react';

interface ZenTransactionHeaderRowCellProps {
  icon: React.ReactElement;
  content: React.ReactElement;
}

const ZenTransactionHeaderRowCell: React.FC<ZenTransactionHeaderRowCellProps> = ({
  icon,
  content,
}) => {
  return (
    <div className='flex flex-row items-center space-x-2'>
      <div className='p-0.5 rounded-full bg-grey-600'>{icon}</div>
      <div>{content}</div>
    </div>
  );
};

export default ZenTransactionHeaderRowCell;
