import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sum } from 'lodash';
import {
  MoneyValueCurrencyEnum,
  TransactionResponse,
} from '../../openapi/arrakis';
import { displayAmount } from '../../utils/CurrencyUtils';
import { getCommissionDocumentName } from '../../utils/TransactionHelper';
import IconText from '../IconText';
import ZenTitleHr from '../Zen/ZenTitleHr';
import TransactionCommissionSplitRow from './TransactionCommissionSplitRow';

interface TransactionCommissionReviewFinalProps {
  transaction: TransactionResponse;
}

const TransactionCommissionReviewFinal: React.FC<TransactionCommissionReviewFinalProps> = ({
  transaction,
}) => {
  const totalFeesAndRebates = transaction.paymentParticipants?.reduce<number>(
    (prevValue, currentValue) => {
      const total = sum(
        (currentValue.feeAndRebateItems || []).map(
          (item) => item.amount?.amount || 0,
        ),
      );

      return prevValue + total;
    },
    0,
  );

  const totalReal = transaction.paymentParticipants?.reduce<number>(
    (prevValue, currentValue) => {
      const total = sum(
        (currentValue.realItems || []).map((item) => item.amount?.amount || 0),
      );

      return prevValue + total;
    },
    0,
  );

  return (
    <div>
      <p className='text-xl font-primary-medium'>You&apos;re almost there!</p>
      <p className='text-xl font-primary-medium mt-3'>Let&apos;s review:</p>
      <div className='mt-2'>
        <IconText
          text='Participants added.'
          icon={
            <FontAwesomeIcon
              icon={faCircleCheck}
              className='text-success mt-0.5'
            />
          }
        />
      </div>
      <div className='mt-2'>
        <IconText
          text='Fees and rebates added.'
          icon={
            <FontAwesomeIcon
              icon={faCircleCheck}
              className='text-success mt-0.5'
            />
          }
        />
      </div>
      <div className='mt-2'>
        <IconText
          text='Commissions confirmed.'
          icon={
            <FontAwesomeIcon
              icon={faCircleCheck}
              className='text-success mt-0.5'
            />
          }
        />
      </div>
      <div className='mt-4'>
        <ZenTitleHr title='Commission Breakdown' />
        {transaction.paymentParticipants?.map((participant) => (
          <div key={participant.id} className='mt-2'>
            <TransactionCommissionSplitRow
              title={`${participant.firstName} ${participant.lastName}`}
              amount={displayAmount(participant.netPayout!)}
            />
          </div>
        ))}

        {!!totalFeesAndRebates && (
          <div className='mt-2'>
            <TransactionCommissionSplitRow
              title='Fees and Rebates'
              amount={displayAmount({
                amount: Math.abs(totalFeesAndRebates!),
                currency: (transaction.currency! as unknown) as MoneyValueCurrencyEnum,
              })}
            />
          </div>
        )}

        <div className='mt-2'>
          <TransactionCommissionSplitRow
            title='Real'
            amount={displayAmount({
              amount: Math.abs(totalReal!),
              currency: (transaction.currency! as unknown) as MoneyValueCurrencyEnum,
            })}
          />
        </div>
      </div>
      <div className='mt-2'>
        <TransactionCommissionSplitRow
          title='Total'
          amount={displayAmount(transaction.grossCommission!)}
        />
      </div>
      <div className='mt-4'>
        <p className='font-primary-medium text-base mb-1'>What happens next?</p>
        <p className='font-primary-light text-base'>
          - You will wait for your broker to approve your file unless it has
          already been approved.
        </p>
        <p className='font-primary-light text-base'>
          {` - A ${getCommissionDocumentName(
            transaction.country!,
          ).toLowerCase()} will be generated for your review.`}
        </p>
        <p className='font-primary-light text-base'>
          {`- Don't worry, the ${getCommissionDocumentName(
            transaction.country!,
          ).toLowerCase()} will only be sent after
          you review and approve it.`}
        </p>
      </div>
    </div>
  );
};

export default TransactionCommissionReviewFinal;
