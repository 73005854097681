import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as NewFile } from '../../assets/img/file-new.svg';
import { MAX_DOC_SIZE_100MB } from '../../constants/FilesConstants';
import { BorrowerDto } from '../../openapi/atlantis';
import { uploadPreApprovalLetter } from '../../slices/MortgageSlice';
import { RootState } from '../../types';
import ZenControlledDraggableDocumentUploadInput from '../Zen/Input/ZenControlledDraggableDocumentUploadInput';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import SharedApprovalLetter from './SharedPreApprovalLetter';

interface MortgageUploadPreApprovalLetterSidebarProps {
  isOpen: boolean;
  onClose(): void;
  borrower: BorrowerDto;
}

interface FormData {
  preApprovalLetter: File[];
}

const MortgageUploadPreApprovalLetterSidebar: React.FC<MortgageUploadPreApprovalLetterSidebarProps> = ({
  isOpen,
  onClose,
  borrower,
}) => {
  const dispatch = useDispatch();
  const {
    loanResponse: { data: loan },
    uploadingPreApprovalLetter,
  } = useSelector((state: RootState) => state.mortgage);

  const { control, watch, setValue } = useForm<FormData>();
  const files = watch('preApprovalLetter');

  const { preApprovalFileId } = borrower;

  useEffect(() => {
    if (!!files?.length && !!loan?.id) {
      dispatch(uploadPreApprovalLetter(loan?.id, files[0]));
      setValue('preApprovalLetter', []);
    }
  }, [dispatch, files, loan?.id, setValue]);

  return (
    <div>
      <ZenSidebarModal
        title='Upload Pre-Approval Letter'
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className='p-5 text-base font-zen-body'>
          <p className='text-zen-dark-9'>
            Drag & drop or upload the pre-approval letter (PDF) from your
            computer.
          </p>
          <p className='text-green-600 mb-6'>
            Once uploaded, the PDF will be sent automatically to the client.
          </p>

          <ZenControlledDraggableDocumentUploadInput<
            FormData,
            'preApprovalLetter'
          >
            name='preApprovalLetter'
            control={control}
            height='small'
            accept='application/pdf'
            isUploading={uploadingPreApprovalLetter}
            customPlaceHolder={
              <div className='flex md:flex-row flex-col justify-center items-center'>
                <NewFile className='w-8 h-8 text-primary-blue' />
                <span className='font-zen-body font-semibold text-sm text-primary-blue ml-2'>
                  Browse Files
                </span>
                <p className='font-zen-body text-sm font-semibold text-zen-dark-7 ml-1'>
                  or drag & drop
                </p>
              </div>
            }
            multiple={false}
            maxUploadSize={MAX_DOC_SIZE_100MB}
          />

          {!!preApprovalFileId && (
            <div className='mt-8 mb-4'>
              <div className='w-full flex items-center'>
                <p className='shrink-0 font-zen-body text-lg text-zen-dark-6'>
                  Previously Shared
                </p>
                <div className='ml-4 flex-grow h-px bg-zen-light-gray-2' />
              </div>
            </div>
          )}

          {!!preApprovalFileId && (
            <SharedApprovalLetter
              name='Pre Approval Letter'
              preApprovalFileId={preApprovalFileId}
            />
          )}
        </div>
      </ZenSidebarModal>

      {/* <ZenConfirmationModal
        size='large'
        isOpen={sendLetterModalOpen}
        onClose={() => setSendLetterModalOpen(false)}
        title='Send letter to client'
        hideFooterButtons
      >
        <div className='pb-8 px-6'>
          <p className='text-base text-center text-gray-500 font-zen-body p-4'>
            {`You have uploaded the pre-approval letter. Do you want to send it to ${borrowerName}?`}
          </p>
          <SharedApprovalLetter
            name='Commission Deposit'
            date='01/30/2022 7:00 AM'
          />
          <div className='flex items-center justify-center mt-8'>
            <IconButton
              label='Delete File'
              variant='dark-outline'
              textStyle='font-primary-regular font-semibold text-zen-dark-13'
              buttonStyle='mr-3 !px-6 !py-1.5 !rounded-lg'
              onClick={() => setSendLetterModalOpen(false)}
            />

            <IconButton
              label='Send'
              textStyle='font-primary-regular font-semibold'
              buttonStyle='mr-4 !px-11 !py-2 bg-primary-blue border-none ring-0 !rounded-lg'
              onClick={() => setSendLetterModalOpen(false)}
            />
          </div>
        </div>
      </ZenConfirmationModal> */}
    </div>
  );
};

export default MortgageUploadPreApprovalLetterSidebar;
