import { BoardResponseStatusEnum } from '../../../openapi/yenta';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumMultiSelectColumnGenerator from './EnumMultiSelectColumnGenerator';

const BoardResponseStatusEnumSelectColumnFilter = <D extends object>(
  props: SelectColumnFilterProps<D>,
) => {
  return EnumMultiSelectColumnGenerator<D>(BoardResponseStatusEnum)(props);
};

export default BoardResponseStatusEnumSelectColumnFilter;
