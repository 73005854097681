import { faPencil, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import AnalyticsService from '../../../services/AnalyticsService';
import { AnalyticsEventEnum, RootState } from '../../../types';
import { displayFormattedFixedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import ZenButton from '../../Zen/ZenButton';
import { selectIsWealthPlanReferral } from '../wealthPlanSelectors';

interface AdditionalRealStockCardProps {
  onEditClick: () => void;
}

const AdditionalRealStockCard: React.FC<AdditionalRealStockCardProps> = ({
  onEditClick,
}) => {
  const {
    earningsOverviewResponse,
    wealthGoalsResponse,
    readonlyMode,
    isWealthPlanReferral,
  } = useSelector((state: RootState) => ({
    ...state.wealthManagement,
    isWealthPlanReferral: selectIsWealthPlanReferral(state),
  }));

  const additionalRealShares =
    wealthGoalsResponse.data?.goalDetails.additionalRealShares ?? 0;
  const showAdditionalSharesCard =
    additionalRealShares > 0 && !readonlyMode && !isWealthPlanReferral;
  const externalShareValue = displayFormattedFixedAmountWithCurrency(
    earningsOverviewResponse.data?.netEarningsPlusProjections
      .externalSharesValue,
    false,
    0,
  );

  if (!showAdditionalSharesCard) {
    return null;
  }

  return (
    <div>
      <div className='w-full relative my-4 p-2 flex items-center justify-center'>
        <div className='w-full absolute top-1/2 border-b border-zen-dark-4' />
        <div className='bg-white px-2 z-10'>
          <div className='h-7 w-7 flex items-center justify-center p-[7px] rounded-full border border-zen-dark-4'>
            <FontAwesomeIcon
              icon={faPlus}
              className='text-base text-zen-dark-5 leading-5'
            />
          </div>
        </div>
      </div>
      <div className='relative font-zen-body-2 border border-zen-dark-4 rounded-xl bg-regent-100 py-4'>
        <p className='text-center text-sm font-medium text-zen-dark-8'>
          Additional Real Stock
        </p>
        <p className='text-center text-[32px] font-normal text-zen-dark-9 mt-1.5'>
          {externalShareValue}
        </p>
        <div className='absolute right-0 top-2'>
          <ZenButton
            label='Edit'
            variant='primary-link'
            onClick={() => {
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.WEALTH_PLAN_OVERVIEW_SCREEN_SHARES_EDIT_GOALS,
              );
              onEditClick();
            }}
            aria-label='edit goals'
            RightIconComponent={
              <FontAwesomeIcon icon={faPencil} className='text-sm' />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AdditionalRealStockCard;
