import { isNil } from 'lodash';
import {
  EscrowDepositResponse,
  EscrowResponse,
  EscrowResponseEscrowStatusEnum,
} from '../openapi/arrakis';
import { BankAccountResponse } from '../openapi/yenta';
import { ISelectOption } from '../types';

export const isAllDepositsConfirmed = (deposit: EscrowResponse): boolean => {
  const totalSumDeposits = deposit.deposits?.reduce(
    (acc, curr) => acc + curr.amount?.amount!,
    0,
  );

  return (
    deposit.escrowStatus === EscrowResponseEscrowStatusEnum.Received &&
    deposit.amount?.amount! <= totalSumDeposits! &&
    !deposit.deposits?.some((deposit) => isNil(deposit.confirmedAt))
  );
};

export const isExternDeposit = (deposits: EscrowDepositResponse[]): boolean => {
  const isExternal =
    (deposits || [])?.length === 1 &&
    !!deposits?.[0]?.checkDepositId &&
    !deposits?.[0]?.createdBy;

  return isExternal;
};

export const getDepositAccountISelectOption = (
  depositDetail?: EscrowDepositResponse,
  bankAccount?: BankAccountResponse,
): ISelectOption | undefined => {
  if (depositDetail?.trustAccount) {
    return {
      label: depositDetail?.trustAccount,
      value: depositDetail?.trustAccount,
    };
  }

  if (bankAccount?.bankName && bankAccount?.accountNumber) {
    return {
      label: `${bankAccount?.bankName} - ${bankAccount?.accountNumber}`,
      value: bankAccount?.accountNumber,
    };
  }

  return undefined;
};

export const getBankAccountISelectOptions = (
  bankAccount?: BankAccountResponse,
) => {
  if (bankAccount?.bankName && bankAccount?.accountNumber) {
    return [
      {
        label: `${bankAccount?.bankName} - ${bankAccount?.accountNumber}`,
        value: bankAccount?.accountNumber,
      },
    ];
  }

  return [];
};
