import { faCheckDouble } from '@fortawesome/pro-regular-svg-icons';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { cn } from '../../utils/classUtils';

interface GeminiCopyToClipboardProps {
  label?: string;
  value: string;
  className?: string;
}

const GeminiCopyToClipboard: React.FC<GeminiCopyToClipboardProps> = ({
  label = 'Copy to Clipboard',
  value,
  className,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [text] = useState(value);

  const onCopyText = () => {
    if (navigator.clipboard && window.isSecureContext)
      navigator.clipboard.writeText(text);
    else {
      let textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      new Promise<void>((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div
      className={cn(
        'flex flex-row items-center shrink-0 font-zen-body-2 text-sm leading-4 font-medium text-avatar-blue-200',
        className,
      )}
    >
      <button
        className='flex items-center space-x-1 font-medium leading-4'
        onClick={onCopyText}
        type='button'
        aria-label='copy-to-clipboard'
      >
        <FontAwesomeIcon icon={faCopy} />
        <span>{label}</span>
      </button>
      {isCopied && (
        <div className='flex items-center ml-2 space-x-1'>
          <FontAwesomeIcon icon={faCheckDouble} />
          <span className='text-xs'>Copied</span>
        </div>
      )}
    </div>
  );
};

export default GeminiCopyToClipboard;
