import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ItemDefinitionDto,
  UpdateItemDefinitionRequest,
  UpdateItemDefinitionRequestDefaultAssigneeEnum,
  UpdateItemDefinitionRequestRequiredForEnum,
} from '../../../../openapi/sherlock';
import { updateCheckListDefinitionItem } from '../../../../slices/ChecklistDefinitionSlice';
import { AppDispatch } from '../../../../types';
import ZenToggleRow from '../../Input/ZenToggleRow';

interface ZenTemplateItemTogglerProps {
  item: ItemDefinitionDto;
  toggleKey?: 'required' | 'hidden';
  checklistId: string;
}

const ZenTemplateItemToggler: React.FC<ZenTemplateItemTogglerProps> = ({
  item,
  toggleKey = 'required',
  checklistId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleToggle = async () => {
    const req: UpdateItemDefinitionRequest = {
      name: item?.name,
      [toggleKey]: !item[toggleKey],
      daysUntilDue: item?.daysUntilDue!,
      description: item?.description,
      defaultAssignee: (item?.defaultAssignee as unknown) as UpdateItemDefinitionRequestDefaultAssigneeEnum,
      labels: item?.labels,
      requiredFor: (item?.requiredFor as unknown) as UpdateItemDefinitionRequestRequiredForEnum,
    };

    setLoading(true);
    await dispatch(
      updateCheckListDefinitionItem(checklistId, item?.id!, {
        ...req,
        position: item?.position,
        documents: item?.documents,
      }),
    );
    setLoading(false);
  };

  return (
    <p className='flex items-center justify-between '>
      <ZenToggleRow
        loading={loading}
        value={item[toggleKey]!}
        onChange={handleToggle}
      />
    </p>
  );
};

export default ZenTemplateItemToggler;
