import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { Link } from 'react-router-dom';
import { YesNoType } from '../../types';
import { cn } from '../../utils/classUtils';
import ZenFormErrorMessage from './Input/ZenFormErrorMessage';

type ZenCheckboxInputVariantType = 'square' | 'circle';

type ZenCheckboxSizeType = 'sm' | 'md';

type ZenCheckboxBorderType = 'default' | 'danger';

interface ZenTermsAndConditionProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  inputValue?: YesNoType;
  variant?: ZenCheckboxInputVariantType;
  size?: ZenCheckboxSizeType;
  border?: ZenCheckboxBorderType;
  disabled?: boolean;
  url: string;
}

const ZenTermsAndCondition = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  inputValue,
  shouldUnregister = true,
  variant = 'square',
  size = 'md',
  border = 'default',
  url = '/',
  ...rest
}: ZenTermsAndConditionProps<TFieldValues, TName>) => {
  const shapeVariantClassMap: Record<ZenCheckboxInputVariantType, string> = {
    square: 'rounded',
    circle: 'rounded-full',
  };

  const sizeVariantClassMap: Record<ZenCheckboxSizeType, string> = {
    sm: 'w-4 h-4',
    md: 'w-6 h-6',
  };

  const borderVariantClassMap: Record<ZenCheckboxBorderType, string> = {
    default: 'border-zen-dark-4',
    danger: 'border-zen-danger',
  };

  return (
    <Controller
      {...rest}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <div className='space-y-1'>
          <div className='space-y-2'>
            <label
              key={YesNoType.YES}
              className={cn('flex items-start flex-row-reverse justify-end')}
            >
              <p className='font-zen-body font-normal text-base text-zen-dark-9 pl-4'>
                {label}
                {' I accept the '}
                <Link
                  to={url}
                  target='_blank'
                  className={cn('text-primary-blue hover:underline')}
                >
                  terms of service
                </Link>
              </p>
              <input
                type='checkbox'
                className={cn(
                  'border-2 text-primary-blue focus:outline-none focus:ring-0',
                  shapeVariantClassMap[variant],
                  sizeVariantClassMap[size],
                  borderVariantClassMap[border],
                )}
                value={inputValue}
                name={name}
                onChange={({ target: { checked, value: checkboxValue } }) => {
                  const updatedValues = checked
                    ? [...(value || []), checkboxValue]
                    : value?.filter((v: string) => v !== checkboxValue);
                  onChange(updatedValues.length ? updatedValues : []);
                }}
                onBlur={onBlur}
                checked={value?.includes(inputValue)}
                ref={ref}
              />
            </label>
          </div>
          {error && <ZenFormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenTermsAndCondition;
