import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faXmark,
  faBolt,
} from '@fortawesome/pro-solid-svg-icons';
import { TransactionLinkResponse } from '../../openapi/arrakis';
import { AmplitudeEvent } from '../../services/AmplitudeService';
import { getInstantPaymentAmplitudeEventData } from '../../utils/InstantPaymentHelper';
import AmplitudeEventOnLoad from '../Amplitude/AmplitudeEventOnLoad';
import Button from '../Button';
import ConfirmationModal from '../ConfirmationModal';

interface InstantPaymentsSplashModalProps {
  isOpen: boolean;
  transaction: TransactionLinkResponse | undefined;
  onClose(): void;
  onNext(): void;
  onDecline(): void;
}

const InstantPaymentsSplashModal: React.FC<InstantPaymentsSplashModalProps> = ({
  isOpen,
  transaction,
  onClose,
  onNext,
  onDecline,
}) => {
  return (
    <ConfirmationModal
      variant='info'
      isOpen={isOpen}
      onClose={onClose}
      hideIcon
    >
      <div className='p-3 relative'>
        <FontAwesomeIcon
          icon={faXmark}
          className='absolute text-2xl  -top-1 -right-1 text-trueGray-500 cursor-pointer'
          onClick={onDecline}
          aria-label='close'
        />
        <div className='flex justify-center'>
          <div className='bg-primary rounded-full bg-opacity-20 p-4 pr-5 pl-5'>
            <FontAwesomeIcon icon={faBolt} size='xl' className='text-primary' />
          </div>
        </div>
        <p className='text-2xl font-primary-medium text-center text-dark my-5'>
          Receive payments for your commissions instantly
        </p>
        <div className='flex flex-row items-center'>
          <p className='text-primary font-primary-medium whitespace-nowrap pr-3'>
            What&apos;s included
          </p>
          <p className='border-t-2 my-3 w-full' />
        </div>
        <ul className='flex flex-col gap-1 my-5'>
          <li className='flex space-x-2'>
            <FontAwesomeIcon
              icon={faCircleCheck}
              className='text-success mt-0.5 text-lg'
            />
            <p>Upto 70% of your commission</p>
          </li>
          <li className='flex space-x-2'>
            <FontAwesomeIcon
              icon={faCircleCheck}
              className='text-success mt-0.5 text-lg'
            />
            <p>Minimal 3% processing fees</p>
          </li>
          <li className='flex space-x-2'>
            <FontAwesomeIcon
              icon={faCircleCheck}
              className='text-success mt-0.5 text-lg'
            />
            <p>Payment within 24 Hrs</p>
          </li>
          <li className='flex space-x-2'>
            <FontAwesomeIcon
              icon={faCircleCheck}
              className='text-success mt-0.5 text-lg'
            />
            <p>Simple Repayment Plan</p>
          </li>
        </ul>
        <Button
          label='Get Started'
          type='primary'
          fullWidth
          className='!py-2'
          onClick={onNext}
        />
        <AmplitudeEventOnLoad
          eventName={AmplitudeEvent.INSTANT_PAYMENTS_SPLASH_VIEW}
          eventData={getInstantPaymentAmplitudeEventData(transaction)}
        />
      </div>
    </ConfirmationModal>
  );
};

export default InstantPaymentsSplashModal;
