import { ErrorCode } from '../../../types';
import { getPageCount } from '../../../utils/TableUtils';
import DefaultLoader from '../../DefaultLoader';
import ResourceContainer from '../../ResourceContainer';
import ZenTablePagination from '../../Zen/Table/ZenTablePagination';
import GeminiSearchBar from '../GeminiSearchBar';
import GeminiTablePageSize from './GeminiTablePageSize';

interface ZenListPaginateProps<D> {
  listItems: Array<D>;
  search?: string;
  itemsToShow: number;
  page: number;
  totalItems: number;
  loading: boolean;
  errorCode: null | ErrorCode;
  searchPlaceholder?: string;
  resourceName: string;
  emptyIcon?: React.ReactElement;
  EmptyComponent?: React.ReactElement;
  onSearch(searchValue?: string): void;
  goToPage(page: number): void;
  updateTotalItemsToShow(itemsToShow: number): void;
  children(data: Array<D>): React.ReactNode;
}

const GeminiListPaginate = <D extends Object>({
  listItems,
  search,
  itemsToShow,
  page,
  totalItems,
  loading,
  errorCode,
  EmptyComponent,
  emptyIcon,
  onSearch,
  goToPage,
  updateTotalItemsToShow,
  searchPlaceholder = 'Search...',
  resourceName,
  children,
}: ZenListPaginateProps<D>) => {
  const lastPage = getPageCount(totalItems, itemsToShow);
  return (
    <>
      <div className='w-full relative'>
        <div className='md:px-7 px-3 pt-5 md:pb-5'>
          <GeminiSearchBar
            value={search || ''}
            onChange={(search) => onSearch(search || undefined)}
            placeholder={searchPlaceholder}
          />
        </div>
        <ResourceContainer
          loading={!listItems.length && loading}
          isEmpty={!listItems.length}
          errorCode={errorCode}
          resourceName={resourceName}
          EmptyComponent={EmptyComponent}
          emptyIcon={emptyIcon}
        >
          {loading && (
            <div className='absolute left-0 right-0 bottom-0 top-0 flex items-center justify-center bg-white bg-opacity-50'>
              <DefaultLoader />
            </div>
          )}
          {children(listItems)}
        </ResourceContainer>
      </div>
      {listItems?.length! > 0 && (
        <div className='p-6 flex flex-row items-center md:justify-between justify-center'>
          <div className='md:block hidden'>
            <GeminiTablePageSize
              itemsToShow={itemsToShow}
              setPageSize={updateTotalItemsToShow}
              noOfListItems={totalItems}
            />
          </div>
          <ZenTablePagination
            currentPage={page}
            pageSize={itemsToShow}
            lastPage={lastPage}
            totalCount={totalItems}
            goToPage={goToPage}
          />
        </div>
      )}
    </>
  );
};

export default GeminiListPaginate;
