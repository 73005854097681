import { flatten, uniq } from 'lodash';
import { AgentDocumentResponse } from '../openapi/yenta';
import { ISelectOption } from '../types';

export const getUniqueLabelOption = (
  documents: AgentDocumentResponse[],
): ISelectOption[] =>
  uniq(
    flatten(
      documents
        .map((doc) => doc.tags)
        .filter((tags) => tags && tags.length > 0),
    ),
  ).map((label) => ({
    value: label!,
    label: label!,
  }));
