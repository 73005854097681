import { Fragment } from 'react';
import { EnumMap, TabsVariant } from '../types';
import { cn } from '../utils/classUtils';

export interface ITab {
  name: string;
  badgeContent?: string | number;
  subtitle?: string | number;
  TabComponent: React.FC | React.ReactNode;
}

export interface TabsProps {
  tabs: ITab[];
  variant?: TabsVariant;
  selected?: string;
  RightComponent?: React.FC | React.ReactNode;
  showBorderBottom?: boolean;
  onChange(name: string): void;
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  variant = 'default',
  RightComponent,
  showBorderBottom = false,
  selected = tabs[0].name,
  onChange,
}) => {
  const tabSelected = tabs.find((tab) => tab.name === selected);

  const tabVariantClassMap: EnumMap<TabsVariant, string> = {
    default: 'px-3 pb-1',
    spread: 'px-3 pb-1 flex-grow',
    material: 'px-5 py-1 border-b-2',
    material_lg: 'px-6 py-3 border-b-2 text-lg h-14 text-dark',
    material_full_border: 'px-6 py-1 rounded border-2 text-sm h-9 text-dark',
  };

  const activeTabVariantClassMap: EnumMap<TabsVariant, string> = {
    default: 'border-primary border-b-2 font-primary-regular',
    spread: 'border-primary border-b-2 font-primary-regular',
    material:
      'border-primary bg-primary bg-opacity-10 text-primary font-primary-regular',
    material_lg: 'border-primary bg-primary bg-opacity-10 text-dark',
    material_full_border: 'border-primary bg-primary bg-opacity-10 text-dark',
  };

  const textVariantClassMap = (
    subtitle: string | number | undefined,
    isTabActive: boolean,
  ): string | undefined => {
    const textVariant: EnumMap<TabsVariant, string> = {
      default: !!subtitle ? 'font-primary-medium' : 'font-primary-light',
      spread: !!subtitle ? 'font-primary-medium' : 'font-primary-light',
      material: !!subtitle ? 'font-primary-medium' : 'font-primary-light',
      material_lg: isTabActive
        ? 'font-primary-medium leading-none max-w-min'
        : 'font-primary-thin leading-none max-w-min',
      material_full_border: isTabActive
        ? 'font-primary-medium leading-none max-w-min'
        : 'font-primary-thin leading-none max-w-min',
    };

    return textVariant[variant];
  };

  return (
    <div>
      <div className='flex flex-row items-center justify-start pt-1 bg-white flex-nowrap'>
        <div className='flex-grow scrollbar overflow-x-auto flex flex-row'>
          {tabs.map(({ name, badgeContent, subtitle }) => {
            const activeTab = name === selected;

            return (
              <button
                key={name}
                className={cn(
                  'flex flex-col focus:outline-none',
                  subtitle
                    ? 'items-start'
                    : 'items-center justify-center min-w-max',
                  tabVariantClassMap[variant],
                  activeTab ? activeTabVariantClassMap[variant] : '',
                  {
                    'border-b-2 border-coolGray-200':
                      !activeTab && showBorderBottom,
                  },
                )}
                onClick={() => onChange(name)}
              >
                <p
                  className={cn(
                    'flex flex-row focus:outline-none items-center justify-center',
                    textVariantClassMap(subtitle, activeTab),
                  )}
                >
                  {name}
                  {badgeContent && (
                    <span className='bg-black text-white text-xs rounded-full px-1.5 py-0.5 ml-1.5'>
                      {badgeContent}
                    </span>
                  )}
                </p>
                <p className='font-primary-medium text-sm'>{subtitle}</p>
              </button>
            );
          })}
        </div>
        {RightComponent}
      </div>
      {!!tabSelected && (
        <Fragment key={tabSelected.name}>{tabSelected.TabComponent}</Fragment>
      )}
    </div>
  );
};

export default Tabs;
