import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import React, { useState } from 'react';
import {
  Control,
  FormState,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { MoneyValueCurrencyEnum } from '../../../openapi/arrakis';
import { RootState } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import ZenSimpleModal from '../../Zen/Modal/ZenSimpleModal';
import ZenButton from '../../Zen/ZenButton';
import { DepositCheckFormData } from '../../../routes/ZenTransactionDepositCheckRoute';
import ZenCheckInfoDataColumn from './ZenCheckInfoDataColumn';

interface CheckDepositConfirmationStepProps {
  form: {
    control: Control<DepositCheckFormData, object>;
    watch: UseFormWatch<DepositCheckFormData>;
    formState: FormState<DepositCheckFormData>;
    trigger: UseFormTrigger<DepositCheckFormData>;
    setValue: UseFormSetValue<DepositCheckFormData>;
  };
  FooterComponent: React.FC;
}

const ZenCheckDepositConfirmationStep: React.FC<CheckDepositConfirmationStepProps> = ({
  form: { watch },
  FooterComponent,
}) => {
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
      tokenTransactionDetail,
    },
    checkDeposits: { uploadedCheckDeposits, confirmationId },
  } = useSelector((state: RootState) => state);
  const isExternalCheckDepositUpload = watch('isExternalCheckDeposit');
  const [openModal, setOpenModal] = useState<boolean>(true);
  const currency = isExternalCheckDepositUpload
    ? tokenTransactionDetail?.currencyCode
    : transactionDetail?.currency;

  return (
    <div className='flex flex-col items-center justify-center py-12'>
      <FontAwesomeIcon
        className='text-success'
        icon={faCheckCircle}
        size='2x'
      />
      <div className='my-10 text-center'>
        <h6 className='font-zen-body text-base text-zen-dark-9 py-2'>
          Amount
          <span className='text-xs text-gray-400 ml-1'>({`${currency}`})</span>
        </h6>
        <p className='font-primary-medium text-2xl text-zen-dark-9'>
          {displayAmount(
            {
              amount: parseFloat(watch('amount')!),
              currency: (currency as unknown) as MoneyValueCurrencyEnum,
            },
            {
              hideCurrency: true,
            },
          )}
        </p>
      </div>
      <div className='flex justify-center items-center'>
        <ZenCheckInfoDataColumn
          data={[
            {
              label: 'Routing No.',
              value: uploadedCheckDeposits?.abaNumber!,
            },
            {
              label: 'Account No.',
              value: uploadedCheckDeposits?.accountNumber!,
            },
            {
              label: 'Check No.',
              value: uploadedCheckDeposits?.checkNumber!,
            },
          ]}
          showDivider
        />
      </div>
      <div className='flex flex-col justify-center items-center mt-6'>
        <ZenCheckInfoDataColumn
          data={[
            { label: 'Confirmation ID:', value: confirmationId!.toString() },
          ]}
          showDivider={false}
        />
        <p className='w-full max-w-xs text-center font-primary-medium text-base text-zen-dark-9 py-10 mx-auto'>
          Keep the check for at least 90 days after this deposit is confirmed
          and settled
        </p>
      </div>
      <div className='flex flex-col py-12'>
        <FooterComponent />
      </div>
      <ZenSimpleModal
        title='Your check has been successfully uploaded!'
        isOpen={openModal}
        onClose={() => {}}
        hideIcon
      >
        <div className='px-5 py-4 space-y-2'>
          <p className='text-lg text-zen-dark-9  font-zen-body'>
            Please hold on to this check until you receive confirmation of
            deposit. There is no need to mail this check into the office. We
            will update you once your check is successfully processed.
          </p>
          <div className='flex justify-end'>
            <ZenButton
              onClick={() => setOpenModal(false)}
              label='Close'
              variant='secondary-gray-outline'
            />
          </div>
        </div>
      </ZenSimpleModal>
    </div>
  );
};

export default ZenCheckDepositConfirmationStep;
