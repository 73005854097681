import React from 'react';

interface GeminiTablePageSizeProps {
  itemsToShow: number;
  pageSizeOptions?: number[];
  setPageSize(size: number): void;
  noOfListItems: number;
}

const GeminiTablePageSize: React.FC<GeminiTablePageSizeProps> = ({
  itemsToShow,
  pageSizeOptions = [10, 20, 30, 40, 50],
  setPageSize,
  noOfListItems,
}) => {
  return (
    <div className='flex items-center font-zen-body-2 text-base text-grey-600'>
      <label htmlFor='pageSize'>View</label>
      <select
        id='pageSize'
        value={itemsToShow}
        onChange={(e) => setPageSize(parseInt(e.target.value, 10))}
        className='block focus:ring-0 border-grey-200 rounded-lg mx-2 py-1.5'
      >
        {pageSizeOptions.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
      <label htmlFor='pageSize'>{`of ${noOfListItems}`}</label>
    </div>
  );
};

export default GeminiTablePageSize;
