import { useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { SubscriptionDto } from '../../openapi/plutus';
import { capitalizeEnum } from '../../utils/StringUtils';

interface WebhookTableEventsTooltipCellProps {
  events: SubscriptionDto[] | undefined;
}

const WebhookTableEventsTooltipCell: React.FC<WebhookTableEventsTooltipCellProps> = ({
  events = [],
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div onMouseLeave={() => setIsOpen(false)}>
      <Popover
        isOpen={isOpen}
        positions={['top']}
        padding={8}
        align='center'
        onClickOutside={() => setIsOpen(false)}
        containerClassName='fixed z-50'
        content={({ position, childRect, popoverRect }) => {
          return (
            <ArrowContainer
              position={position}
              popoverRect={popoverRect}
              childRect={childRect}
              arrowColor='#FEFEFE'
              arrowSize={10}
              className='popover-arrow-container drop-shadow-md'
              arrowClassName='popover-arrow'
            >
              <div className='bg-white p-5 pb-4 font-semibold rounded-3xl'>
                <p className='text-zen-dark-6 mb-3 text-sm font-zen-body'>
                  Event Added ({events?.length})
                </p>
                <div className='w-96 h-32 flex flex-col scrollbar overflow-y-auto gap-y-3 pr-1'>
                  {events.map((event: SubscriptionDto) => {
                    const eventName = event['eventName'];
                    return (
                      <p
                        key={eventName}
                        className='text-zen-dark-9 text-sm font-normal font-zen-body break-words'
                      >
                        {eventName ? capitalizeEnum(eventName) : '--'}
                      </p>
                    );
                  })}
                </div>
              </div>
            </ArrowContainer>
          );
        }}
      >
        <div
          onMouseEnter={() => setIsOpen(true)}
          className='cursor-pointer font-normal text-zen-dark-9'
        >
          {events?.length} Events
        </div>
      </Popover>
    </div>
  );
};

export default WebhookTableEventsTooltipCell;
