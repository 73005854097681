import {
  FaBolt,
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
} from 'react-icons/fa';
import { InstantPaymentDetailResponseStatusEnum } from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenAlert, { ZenAlertVariantType } from '../ZenAlert';

interface ZenInstantPaymentStatusRowProps {
  status: InstantPaymentDetailResponseStatusEnum;
}

const ZenInstantPaymentStatusRow: React.FC<ZenInstantPaymentStatusRowProps> = ({
  status,
}) => {
  const enumMapIcons: EnumMap<
    InstantPaymentDetailResponseStatusEnum,
    React.ReactElement
  > = {
    [InstantPaymentDetailResponseStatusEnum.Repaid]: (
      <FaCheckCircle size={18} className='text-white' />
    ),
    [InstantPaymentDetailResponseStatusEnum.Disbursed]: (
      <FaBolt size={18} className='text-white' />
    ),
    [InstantPaymentDetailResponseStatusEnum.PartiallyRepaid]: (
      <FaExclamationCircle size={18} className='text-white' />
    ),
    [InstantPaymentDetailResponseStatusEnum.InProgress]: (
      <FaExclamationTriangle size={18} className='text-white' />
    ),
  };
  const enumMapStatusText: EnumMap<
    InstantPaymentDetailResponseStatusEnum,
    string
  > = {
    [InstantPaymentDetailResponseStatusEnum.Repaid]: 'Repaid',
    [InstantPaymentDetailResponseStatusEnum.Disbursed]: 'Disbursed',
    [InstantPaymentDetailResponseStatusEnum.PartiallyRepaid]:
      'Partially Repaid',
    [InstantPaymentDetailResponseStatusEnum.InProgress]: 'In Progress',
  };
  const enumMapStatusVariant: EnumMap<
    InstantPaymentDetailResponseStatusEnum,
    ZenAlertVariantType
  > = {
    [InstantPaymentDetailResponseStatusEnum.Repaid]: 'success',
    [InstantPaymentDetailResponseStatusEnum.Disbursed]: 'info',
    [InstantPaymentDetailResponseStatusEnum.PartiallyRepaid]: 'default',
    [InstantPaymentDetailResponseStatusEnum.InProgress]: 'warning',
  };

  return (
    <ZenAlert
      text={safeEnumMapGet(enumMapStatusText, status, capitalizeEnum(status))}
      icon={safeEnumMapGet(
        enumMapIcons,
        status,
        <FaExclamationTriangle size={18} className='text-white' />,
      )}
      variant={safeEnumMapGet(enumMapStatusVariant, status, 'warning')}
    />
  );
};

export default ZenInstantPaymentStatusRow;
