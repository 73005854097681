import React from 'react';
import { Link } from 'react-router-dom';
import { CardItem } from '../types';
import { cn } from '../utils/classUtils';
import Card from './Card';

export type CardValueVariant = 'bold' | 'regular';
export interface CardWithItemsProps {
  items: CardItem[];
  title?: string;
  bottomActionText?: string;
  onBottomActionClick?(): void;
  variant?: CardValueVariant;
  RightComponent?: React.ReactElement;
}

const CardWithItems: React.FC<CardWithItemsProps> = ({
  items,
  bottomActionText,
  onBottomActionClick,
  title,
  variant = 'bold',
  RightComponent,
}) => {
  return (
    <Card
      bottomActionText={bottomActionText}
      title={title}
      onBottomActionClick={onBottomActionClick}
      RightComponent={RightComponent}
    >
      {items.map((item, index) => {
        return (
          <div key={item.name}>
            {items.length + 1 !== index && index > 0 && (
              <div className='border-b' />
            )}

            <div className='px-4 py-2 justify-between flex-row flex'>
              <div className='flex flex-col'>
                {item.url ? (
                  <Link to={item.url}>
                    <span className='text-primary font-semibold hover:underline'>
                      {item.name}
                    </span>
                  </Link>
                ) : (
                  <span className='font-primary-light text-gray-600'>
                    {item.name}
                  </span>
                )}
                <span className='text-gray-500 font-medium'>
                  {item.subtitle}
                </span>
              </div>
              <div className='flex flex-row items-center space-x-2 justify-between'>
                <span
                  className={cn(
                    variant === 'bold'
                      ? 'font-primary-medium'
                      : 'font-primary-regular',
                  )}
                >
                  {item.value}
                </span>
                <div>{item?.rightActionComponent}</div>
              </div>
            </div>
          </div>
        );
      })}
    </Card>
  );
};

export default CardWithItems;
