import React from 'react';
import ZenButton from '../ZenButton';

export interface CreateEditTriggerModalFooterProps {
  onNext?(): void;
  onPrevious?(): void;
  isSubmitting?: boolean;
  previousLabelText?: string;
  nextLabelText?: string;
  previousButtonLeftIcon?: React.ReactElement;
  nextButtonLeftIcon?: React.ReactElement;
  nextButtonRightIcon?: React.ReactElement;
}

const CreateEditTriggerModalFooter: React.FC<CreateEditTriggerModalFooterProps> = ({
  onNext,
  onPrevious,
  isSubmitting = false,
  previousLabelText = 'Previous',
  nextLabelText = 'Next',
  previousButtonLeftIcon,
  nextButtonLeftIcon,
  nextButtonRightIcon,
}) => {
  return (
    <div className='pr-4.5 py-5 flex flex-col md:flex-row justify-center md:justify-between items-center bg-white rounded-b-lg border-t-2 border-zen-light-gray-2 space-y-2 md:space-y-0'>
      <ZenButton
        label={previousLabelText}
        variant='primary-link'
        LeftIconComponent={previousButtonLeftIcon}
        onClick={onPrevious}
      />
      <ZenButton
        label={nextLabelText}
        LeftIconComponent={nextButtonLeftIcon}
        RightIconComponent={nextButtonRightIcon}
        onClick={onNext}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default CreateEditTriggerModalFooter;
