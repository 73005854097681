import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
import RealTitleLogo from '../../assets/img/RealTitleLogo.svg';
import { JOIN_IN_REAL_URL } from '../../constants/AncillaryConstants';
import {
  declineJointVentureInvitation,
  fetchJVInvite,
  toggleJVInvitationModal,
} from '../../slices/JointVentureSlice';
import { RootState } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenSimpleConfirmationModal from '../Zen/Modal/ZenSimpleConfirmationModal';
import ZenButton from '../Zen/ZenButton';
import TierMembershipAmountCard, {
  ShadeVariant,
} from './TierMembershipAmountCard';

export interface Props {}

const JointVentureVideoModal: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const {
    auth: { userDetail, isAdmin, isBroker },
    jointVenture: {
      showInviteResponse: { data: inviteDetails },
      modalToggle,
    },
  } = useSelector((state: RootState) => state);

  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [isDeclining, setIsDeclining] = useState<boolean>(false);

  const tierName = (
    inviteDetails?.tier?.name || ''
  ).toLowerCase() as ShadeVariant;

  useEffect(() => {
    if (userDetail?.id && !isAdmin && !isBroker) {
      dispatch(fetchJVInvite());
    }
  }, [dispatch, isAdmin, isBroker, userDetail?.id]);

  const handleClose = () => {
    setOpenCancelModal(false);

    dispatch(toggleJVInvitationModal(false));
  };

  const handleDecline = async () => {
    setIsDeclining(true);
    await dispatch(declineJointVentureInvitation(inviteDetails?.id!));
    setIsDeclining(false);
    handleClose();
  };

  const handleInviteClose = () => setOpenCancelModal(true);

  if (!inviteDetails || !modalToggle) {
    return null;
  }

  return (
    <div
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'
      title='zen-modal'
    >
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'
        role='button'
      />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
        <div
          className='w-[420px] h-[85%] bg-black rounded-xl z-10 shadow-zen-web'
          aria-label='joint-venture-video-modal'
        >
          <div className='w-full h-full flex flex-col'>
            <div className='w-full flex flex-row items-center bg-zen-light-blue rounded-t-xl px-3'>
              <div className='flex flex-grow items-center py-2.5 mt-2.5'>
                <img src={RealTitleLogo} className='w-[132px] h-8' alt='' />
              </div>
              <div className='mx-2 my-3'>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className='bg-zen-dark-5 text-white rounded-full cursor-pointer text-xl'
                  onClick={handleInviteClose}
                />
              </div>
            </div>
            <div className='flex-grow overflow-y-hidden relative'>
              <div className='h-full w-full'>
                <video src={JOIN_IN_REAL_URL} controls autoPlay />
              </div>

              <div className='absolute bottom-[6%] w-full px-4'>
                <div className='font-zen-title md:text-[32px] md:leading-[38px] text-xl text-white mb-4'>
                  <p className='px-3 text-center mx-1'>
                    You&apos;ve qualified to join the{' '}
                    {inviteDetails?.jointVenture?.name}!
                  </p>
                </div>
                <TierMembershipAmountCard
                  tierType={tierName}
                  label={`${capitalizeEnum(tierName)} Membership`}
                  amount={inviteDetails?.jointVenture?.issuePrice?.amount!}
                />
              </div>
            </div>
            <div className='w-full bg-white flex space-x-4 p-4 rounded-b-xl flex-shrink-0'>
              <Link
                to={`/real-title/${inviteDetails.id}/learn-more`}
                className='flex-1'
                onClick={handleClose}
              >
                <ZenButton
                  variant='primary-outline'
                  label='Learn More'
                  isFullWidth
                />
              </Link>
              <Link
                to={`/real-title/${inviteDetails.id}/signup`}
                className='flex-1'
                onClick={handleClose}
              >
                <ZenButton variant='primary' label='Join' isFullWidth />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ZenSimpleConfirmationModal
        variant='danger'
        title={`Are you sure you do not want to join ${inviteDetails?.jointVenture?.name}?`}
        confirmButtonText='Decline Invitation'
        cancelButtonText='Maybe Later'
        onClose={handleClose}
        onConfirm={handleDecline}
        isOpen={openCancelModal}
        size='large'
        isSubmitting={isDeclining}
        hideIcon
      />
    </div>
  );
};

export default JointVentureVideoModal;
