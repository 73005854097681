import React from 'react';
import ZenPopover from '../../../ZenPopover';

interface RealPostCapTransactionFeePopoverProps {}

const RealPostCapTransactionFeePopover: React.FC<RealPostCapTransactionFeePopoverProps> = () => {
  return (
    <div className='font-inter text-sm text-zen-dark-9'>
      Real Post Cap Transaction Fee
      <ZenPopover
        title='Real Post Cap Transaction Fee'
        description={
          <div className='text-zen-dark-9 text-base font-light'>
            <p>
              A Post Cap Transaction Fee is a fee that is assessed by Real
              Brokerage on each transaction after an agent has reached their
              annual cap. The fee is <span className='font-bold'>$285</span> per
              transaction, up to $6,000 per year, and is split between all
              agents on a transaction in proportion to the percentage of
              commission each agent earns. However, if an agent is considered an
              Elite Agent, the Post Cap Transaction Fee is reduced to{' '}
              <span className='font-bold'>$129</span> per transaction.
            </p>
            <p className='mt-4'>
              Select the default percentage split for the Post-Cap Transaction
              Fee between Team Leader and Teammates (or members within your
              team).
            </p>
          </div>
        }
      />
    </div>
  );
};

export default RealPostCapTransactionFeePopover;
