import { values } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import mlsCheck from '../../../../../assets/img/mls_check.png';
import useGooglePlaceId from '../../../../../hooks/useGooglePlaceId';
import {
  AgentWebsiteResponse,
  PropertyFilterResponseBathroomsEnum,
  PropertyFilterResponseBedroomsEnum,
  PropertyFilterResponseCategoryTypeEnum,
  PropertyFilterResponseHomeJunctionPropertyTypesEnum,
} from '../../../../../openapi/yenta';
import { saveAgentWebsiteDetails } from '../../../../../slices/AgentSlice';
import {
  BATHS_SELECT_OPTION,
  BEDS_SELECT_OPTION,
  CATEGORY_SELECT_OPTION,
} from '../../../../../utils/AgentWebsiteUtils';
import { capitalizeEnum } from '../../../../../utils/StringUtils';
import ZenControlledGoogleAutocompleteSearchInput, {
  GooglePlaceLocationType,
} from '../../../../Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledCheckboxGroup from '../../../../Zen/Input/ZenControlledGroupCheckboxInput';
import ZenControlledTextInput from '../../../../Zen/Input/ZenControlledTextInput';
import ZenButton from '../../../../Zen/ZenButton';
import ZenControlledGroupRadioButtonInput from '../../../../Zen/ZenControlledGroupRadioButtonInput';
import ZenControlledRangeSlider from '../../../../Zen/ZenControlledRangeSlider';
import ZenSidebarModalForm from '../../../../Zen/ZenSidebarModalForm';

interface ZenEditAgentWebsitePropertyFilterFormProps {
  isOpen: boolean;
  onClose(): void;
  agentWebsite: AgentWebsiteResponse;
}
export interface PriceRange {
  min: number;
  max: number;
}

export interface PropertyFilterFormData {
  categoryType: PropertyFilterResponseCategoryTypeEnum;
  bathrooms: PropertyFilterResponseBathroomsEnum;
  bedrooms: PropertyFilterResponseBedroomsEnum;
  propertyType: PropertyFilterResponseHomeJunctionPropertyTypesEnum[];
  mlsNumber: string;
  priceRange: PriceRange;
  location?: GooglePlaceLocationType;
}

const ZenEditAgentWebsitePropertyFilterForm: React.FC<ZenEditAgentWebsitePropertyFilterFormProps> = ({
  isOpen,
  onClose,
  agentWebsite,
}) => {
  const dispatch = useDispatch();
  const { place } = useGooglePlaceId(
    agentWebsite?.propertyFilter?.googlePlaceId,
  );
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm<PropertyFilterFormData>({
    defaultValues: {
      categoryType: agentWebsite.propertyFilter?.categoryType,
      mlsNumber: agentWebsite.propertyFilter?.mlsNumber,
      location: {
        formatted_address: '',
        place_id: agentWebsite?.propertyFilter?.googlePlaceId,
        geometry: {
          location: {
            lat: agentWebsite?.propertyFilter?.latitude,
            lng: agentWebsite?.propertyFilter?.latitude,
          },
        },
      },
      propertyType: agentWebsite.propertyFilter?.homeJunctionPropertyTypes,
      priceRange: {
        min: agentWebsite.propertyFilter?.minPrice!,
        max: agentWebsite.propertyFilter?.maxPrice!,
      },
      bathrooms: agentWebsite.propertyFilter?.bathrooms,
      bedrooms: agentWebsite.propertyFilter?.bedrooms,
    },
  });

  const categoryType = watch('categoryType');

  useEffect(() => {
    setValue('location', {
      formatted_address: place?.formatted_address || '',
      place_id: place?.place_id || '',
      geometry: {
        location: {
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng(),
        },
      },
      address_components: undefined,
    });
  }, [place, setValue]);

  const onSubmit = (formData: PropertyFilterFormData) => {
    const updatedData: AgentWebsiteResponse = {
      ...agentWebsite,
      propertyFilter: {
        ...agentWebsite.propertyFilter,
        categoryType: formData.categoryType as PropertyFilterResponseCategoryTypeEnum,
        bedrooms: formData.bedrooms!,
        bathrooms: formData.bathrooms!,
        homeJunctionPropertyTypes: formData.propertyType.map(
          (p) => p,
        ) as PropertyFilterResponseHomeJunctionPropertyTypesEnum[],
        minPrice: +formData.priceRange.min,
        maxPrice: +formData.priceRange.max,
        mlsNumber: formData?.mlsNumber,
        googlePlaceId: formData?.location?.place_id,
        latitude: formData?.location?.geometry?.location?.lat,
        longitude: formData?.location?.geometry?.location?.lng,
      },
    };
    dispatch(saveAgentWebsiteDetails(updatedData));
    onClose();
  };

  return (
    <ZenSidebarModalForm
      title='Listings (Search Criteria)'
      subtitle='The top 4 results show on the Home page'
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      actionTitle='Save'
    >
      <div>
        <ZenControlledGroupRadioButtonInput<
          PropertyFilterFormData,
          'categoryType'
        >
          name='categoryType'
          control={control}
          shouldUnregister={false}
          label='Category'
          rules={{ required: 'Category is required' }}
          options={CATEGORY_SELECT_OPTION}
          isRequired
        />
      </div>
      {categoryType === PropertyFilterResponseCategoryTypeEnum.Mls ? (
        <div className='mt-5 relative'>
          <ZenControlledTextInput<PropertyFilterFormData, 'mlsNumber'>
            control={control}
            shouldUnregister={false}
            label='MLS Number/Name'
            name='mlsNumber'
            placeholder='1234567'
            rules={{ required: 'MLS Number is required' }}
            isRequired
          />
          <img
            src={mlsCheck}
            alt='check'
            className='w-6 h-6 absolute top-9 right-2'
          />
        </div>
      ) : (
        <div className='mt-5'>
          <ZenControlledGoogleAutocompleteSearchInput<
            PropertyFilterFormData,
            'location'
          >
            control={control}
            shouldUnregister={false}
            name='location'
            label='Location'
            subLabel='(Search powered by Google)'
            placeholder='Address, ZIP, or Neighborhood'
            rules={{
              validate: (value) =>
                !value?.formatted_address ? 'Location is required.' : undefined,
            }}
          />
        </div>
      )}
      <div className='mt-5'>
        <ZenControlledCheckboxGroup
          control={control}
          shouldUnregister={false}
          options={values(
            PropertyFilterResponseHomeJunctionPropertyTypesEnum,
          ).map((p) => ({ label: capitalizeEnum(p), value: p }))}
          label='Property type'
          name='propertyType'
        />
      </div>
      <div className='mt-5'>
        <ZenControlledRangeSlider<PropertyFilterFormData, 'priceRange'>
          label='Price range (USD)'
          min={0}
          max={3750000}
          name='priceRange'
          minValueLabel='Minimum price'
          maxValueLabel='Maximum price'
          step={5000}
          control={control}
          shouldUnregister={false}
          minValuePlaceholder='Any'
          maxValuePlaceholder='Any'
          rules={{ required: 'Price Range is required' }}
          isRequired
        />
      </div>
      <div className='mt-5'>
        <label className='font-zen-title font-medium text-lg'>
          Beds and Baths
        </label>
        <div className='mt-4 px-4'>
          <ZenControlledGroupRadioButtonInput<
            PropertyFilterFormData,
            'bedrooms'
          >
            name='bedrooms'
            control={control}
            shouldUnregister={false}
            label='Bedrooms'
            rules={{ required: 'Bedrooms type is required' }}
            options={BEDS_SELECT_OPTION}
            isRequired
          />
        </div>
        <div className='mt-4 px-4'>
          <ZenControlledGroupRadioButtonInput<
            PropertyFilterFormData,
            'bathrooms'
          >
            name='bathrooms'
            control={control}
            shouldUnregister={false}
            label='Bathrooms'
            rules={{ required: 'Bathrooms type is required' }}
            options={BATHS_SELECT_OPTION}
            isRequired
          />
        </div>
      </div>
      <div className='mt-10 flex flex-row justify-between'>
        <ZenButton
          label='Reset all to default'
          variant='danger-link'
          LeftIconComponent={
            <FontAwesomeIcon
              icon={faArrowRotateLeft}
              className='text-zen-danger'
            />
          }
          onClick={() => {
            reset({
              bathrooms: PropertyFilterResponseBathroomsEnum.Any,
              bedrooms: PropertyFilterResponseBedroomsEnum.Any,
              categoryType: PropertyFilterResponseCategoryTypeEnum.Buy,
              mlsNumber: '',
              propertyType: [],
              location: {
                formatted_address: '',
                place_id: '',
                geometry: {
                  location: {
                    lat: undefined,
                    lng: undefined,
                  },
                },
              },
              priceRange: { min: 0, max: 3750000 },
            });
          }}
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenEditAgentWebsitePropertyFilterForm;
