import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';

interface ExcludeRealTitleOfficerProps {}

const ExcludeRealTitleOfficer: React.FC<ExcludeRealTitleOfficerProps> = ({
  children,
}) => {
  const { isRealTitleOfficer } = useSelector((state: RootState) => state.auth);

  if (isRealTitleOfficer) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};

export default ExcludeRealTitleOfficer;
