import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { useDebounceValue, useIntersectionObserver } from 'usehooks-ts';
import { SubmitHandler, useForm } from 'react-hook-form-v7';
import { debounce, isEmpty } from 'lodash';
import ZenButton from '../Zen/ZenButton';
import ZenSearchBar from '../Zen/ZenSearchBar';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import { useInfiniteDirectory } from '../../query/directory/useInfiniteDirectory';
import { DirectoryCommonEntityResponse } from '../../query/directory/useDirectory';
import {
  DirectoryEntryResponseTypeEnum,
  DirectoryVendorCreateRequestRoleEnum,
  DirectoryVendorResponseStatusEnum,
} from '../../openapi/yenta';
import ResourceContainer from '../ResourceContainer';
import {
  AnalyticsEventEnum,
  CreateTransactionParticipantType,
} from '../../types';
import { FieldArrayPrefix } from '../Zen/Transaction/CreateTransaction/ZenTransactionBuyerAndSellerStep';
import DefaultLoader from '../DefaultLoader';
import { ZenToggleButton } from '../ZenToggleButton';
import { FilterValuesFormData } from '../../routes/ZenDirectoryRoute';
import AnalyticsService from '../../services/AnalyticsService';
import AnalyticsEventOnLoad from '../Analytics/AnalyticsEventOnLoad';
import ZenUserProfileCard from './ZenUserProfileCard';
import { getDirectoryVendorRoleLabel } from './DirectoryUtils';
import { sortAndGroupContacts } from './utils';
import { prepareDataForApi } from './filterUtils';
import ZenDirectoryContactListFilter from './ZenDirectoryContactListFilter';

export interface ZenContactListSidebarProps {
  isOpen: boolean;
  onClose(): void;
  onSave: (
    prefix?: FieldArrayPrefix,
    contact?: DirectoryCommonEntityResponse,
  ) => void;
  isExternalAgent?: boolean;
  participantRole?: string;
  currentFieldArrayPrefixNotRequired?: boolean;
  participantType?: CreateTransactionParticipantType;
  currentFieldArrayPrefix?: FieldArrayPrefix;
}

const getDefaultClientRole = () => [
  {
    value: DirectoryVendorCreateRequestRoleEnum.Client,
    label: getDirectoryVendorRoleLabel(
      DirectoryVendorCreateRequestRoleEnum.Client,
    )!,
  },
];

const getDefaultVendorRole = () => [
  {
    value: DirectoryVendorCreateRequestRoleEnum.Other,
    label: getDirectoryVendorRoleLabel(
      DirectoryVendorCreateRequestRoleEnum.Other,
    )!,
  },
  {
    value: DirectoryVendorCreateRequestRoleEnum.OtherAgent,
    label: getDirectoryVendorRoleLabel(
      DirectoryVendorCreateRequestRoleEnum.OtherAgent,
    )!,
  },
];

const getExternalAgentRole = () => [
  {
    value: DirectoryVendorCreateRequestRoleEnum.OtherAgent,
    label: getDirectoryVendorRoleLabel(
      DirectoryVendorCreateRequestRoleEnum.OtherAgent,
    )!,
  },
];
const getLawyerRole = () => [
  {
    value: DirectoryVendorCreateRequestRoleEnum.Lawyer,
    label: getDirectoryVendorRoleLabel(
      DirectoryVendorCreateRequestRoleEnum.Lawyer,
    )!,
  },
];
const getTitleEscrow = () => [
  {
    value: DirectoryVendorCreateRequestRoleEnum.TitleEscrow,
    label: getDirectoryVendorRoleLabel(
      DirectoryVendorCreateRequestRoleEnum.TitleEscrow,
    )!,
  },
];

const getfilters = (
  participantType: string,
  currentFieldArrayPrefixNotRequired?: boolean,
  participantRole?: string,
) => {
  if (participantType === 'PERSON' && currentFieldArrayPrefixNotRequired) {
    return getDefaultClientRole();
  }
  if (currentFieldArrayPrefixNotRequired) {
    return getExternalAgentRole();
  }
  switch (participantRole || participantType) {
    case 'PERSON':
      return getDefaultClientRole();
    case 'CLIENT':
      return getDefaultClientRole();
    case 'VENDOR':
      return getDefaultVendorRole();
    case 'EXTERNAL_AGENT':
      return getExternalAgentRole();
    case 'LAWYER':
      return getLawyerRole();
    case 'TITLE_ESCROW':
      return getTitleEscrow();
    case 'COMPANY':
      return getDefaultVendorRole();
    case 'OTHER':
      return getExternalAgentRole();
    default:
      return getDefaultClientRole();
  }
};

const ZenContactListSidebar: React.FC<ZenContactListSidebarProps> = ({
  isOpen,
  onClose,
  onSave,
  currentFieldArrayPrefixNotRequired,
  participantRole,
  participantType,
  currentFieldArrayPrefix,
  isExternalAgent = false,
}) => {
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounceValue(search, 500);
  const queryType =
    participantType === 'PERSON' || ['CLIENT'].includes(participantRole!)
      ? DirectoryEntryResponseTypeEnum.Person
      : DirectoryEntryResponseTypeEnum.Vendor;
  const [
    directoryFilters,
    setDirectoryFilters,
  ] = useState<FilterValuesFormData>(
    prepareDataForApi({
      participantRole: getfilters(
        participantType!,
        currentFieldArrayPrefixNotRequired,
        participantRole,
      ),
      name: '',
      email: '',
      phoneNumber: '',
    }),
  );

  const [
    selectedContact,
    setSelectedContact,
  ] = useState<DirectoryCommonEntityResponse | null>(null);
  const [isVerified, setIsVerified] = useState(false);

  const {
    formState: { isSubmitting },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string, eventData?: Record<string, unknown>) => {
      AnalyticsService.instance().logEvent(event, eventData);
    }, 500),
    [],
  );

  const handleSearch = (value: string) => {
    setSearch(value);
    if (value) {
      logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_SEARCH, {
        searchQuery: value,
      });
    }
  };

  const handleSelect = (contact: DirectoryCommonEntityResponse) => {
    setSelectedContact((currentContact) => {
      if (currentContact?.id !== contact.id) {
        logAnalyticsEvent(
          AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_SELECT_CONTACT,
        );
        return contact;
      }

      return null;
    });
  };

  const handleSave = () => {
    if (
      selectedContact &&
      (currentFieldArrayPrefix ||
        currentFieldArrayPrefixNotRequired ||
        participantRole)
    ) {
      onSave(currentFieldArrayPrefix, selectedContact);
    }
    onClose();
    logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_CLICK_SAVE);
  };

  const {
    data,
    isInitialLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteDirectory(queryType, {
    isExternalAgent: isExternalAgent,
    searchQuery: debouncedSearch,
    isVerified: isVerified,
    ...directoryFilters,
  });

  const filterRef = React.useRef<HTMLDivElement>(null);
  const sortedAndGroupedContacts = sortAndGroupContacts(data);

  const loadMore = () => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  };

  const onFilterApplied: SubmitHandler<FilterValuesFormData> = (data) => {
    const { name, email, phoneNumber, participantRole } = data;
    setDirectoryFilters({
      participantRole,
      name,
      email,
      phoneNumber,
    });
  };

  const { ref } = useIntersectionObserver({
    rootMargin: '200px',
    threshold: 0.5,
    freezeOnceVisible: false, // Keep observing the sentinel element
    onChange: (isIntersecting) => {
      if (isIntersecting) {
        loadMore();
      }
    },
  });

  return (
    <ZenSidebarModal
      title='Directory'
      isOpen={isOpen}
      onClose={() => {
        onClose();
        logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_CLICK_X);
      }}
    >
      <form className='flex flex-col min-h-full space-y-4 justify-between p-4'>
        <div className='flex flex-col'>
          <div className='flex flex-row items-center gap-2'>
            <ZenSearchBar value={search} onChange={handleSearch} />
            {queryType === DirectoryEntryResponseTypeEnum.Vendor && (
              <ZenToggleButton
                label=''
                value='verified'
                leftIcon={
                  <FontAwesomeIcon
                    icon={faBadgeCheck}
                    className='w-4 h-4 text-green-600'
                  />
                }
                onClick={() => {
                  setIsVerified(!isVerified);
                  logAnalyticsEvent(
                    isVerified
                      ? AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_REAL_VERIFIED_NO
                      : AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_REAL_VERIFIED_YES,
                  );
                }}
                isSelected={isVerified}
              />
            )}
            <div className='relative' ref={filterRef}>
              <ZenDirectoryContactListFilter
                parentRef={filterRef}
                onFilterApplied={onFilterApplied}
                defaultFilters={directoryFilters}
              />
            </div>
          </div>
          <ResourceContainer
            loading={isInitialLoading}
            isEmpty={isEmpty(sortedAndGroupedContacts)}
            resourceName='directory contact'
          >
            <div className='flex flex-col justify-between min-h-full'>
              <div className='pl-2'>
                {Object.entries(sortedAndGroupedContacts).map(
                  ([letter, contacts]) => (
                    <div key={letter}>
                      <div className='font-bold mt-4 text-xl'>{letter}</div>
                      {contacts.map((contact) => {
                        return (
                          <ZenUserProfileCard
                            key={contact.id}
                            name={contact.name}
                            role={getDirectoryVendorRoleLabel(contact.role)}
                            isRealVerified={
                              contact.status ===
                              DirectoryVendorResponseStatusEnum.Verified
                            }
                            onSelect={() => handleSelect(contact)}
                            isSelected={selectedContact?.id === contact.id}
                          />
                        );
                      })}
                    </div>
                  ),
                )}
              </div>
              {/* Sentinel element for triggering more data to load */}
              <div ref={ref} style={{ height: '20px' }} />
              <div>{isFetchingNextPage ? <DefaultLoader /> : null}</div>
              <div className='flex items-center, justify-center bg-transparent text-zen-dark-6'>
                {!isFetching && !hasNextPage
                  ? 'No more contacts to load.'
                  : null}
              </div>
            </div>
          </ResourceContainer>
        </div>
        <div className='p-4 bg-white border-t border-gray-200 sticky bottom-0 space-x-5'>
          <div className='flex flex-row justify-end items-center space-x-3'>
            <ZenButton
              type='button'
              onClick={() => {
                onClose();
                logAnalyticsEvent(
                  AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_CLICK_CANCEL,
                );
              }}
              label='Cancel'
              variant='primary-outline'
              buttonProps={{ style: { width: '150px' } }}
            />
            <ZenButton
              label='Save'
              variant='primary'
              isDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              onClick={(e: { preventDefault: () => void }) => {
                e.preventDefault();
                handleSave();
              }}
              buttonProps={{ style: { width: '150px' } }}
            />
          </div>
        </div>
      </form>

      <AnalyticsEventOnLoad
        eventName={AnalyticsEventEnum.DIRECTORY_CONTACT_LIST_OPENED}
      />
    </ZenSidebarModal>
  );
};

export default ZenContactListSidebar;
