import { DateTime } from 'luxon';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { Validate } from 'react-hook-form-v6';
import { FormFieldTooltipIndexProps } from '../types';
import { MAX_YEAR_ALLOWED } from '../utils/DateUtils';
import { cn } from '../utils/classUtils';
import FormErrorMessage from './FormErrorMessage';

interface ControlledDatePickerInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  subLabel?: string;
  placeholder?: string;
  readOnly?: boolean;
  customInput?: React.ReactElement;
  datePickerConfig?: Omit<
    ReactDatePickerProps,
    'name' | 'onChange' | 'selected' | 'onBlur' | 'readOnly'
  >;
  icon?: React.ReactElement;
  isRequired?: boolean;
}

const ControlledDatePickerInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  defaultValue,
  placeholder,
  readOnly,
  customInput,
  datePickerConfig = {},
  tooltipIndex,
  shouldUnregister = true,
  icon,
  rules,
  isRequired = false,
  ...rest
}: ControlledDatePickerInputV7Props<TFieldValues, TName>) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      rules={{
        ...(rules || {}),
        validate: (val) => {
          if (val && DateTime.fromISO(val).year > MAX_YEAR_ALLOWED) {
            return `Year cannot be greater than ${MAX_YEAR_ALLOWED}.`;
          }
          return (rules?.validate as Validate)?.(val);
        },
      }}
      {...rest}
      render={({
        field: { value, onChange, name, onBlur },
        fieldState: { error },
      }) => (
        <div className='w-full space-y-1'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              {label}
              {isRequired && <span className='text-error'>*</span>}
              {!!subLabel && (
                <span className='font-primary-regular text-sm text-gray-400 ml-1'>
                  {subLabel}
                </span>
              )}
            </label>
          )}
          <div
            className='rounded flex flex-row items-center'
            data-tooltip-index={tooltipIndex}
          >
            {icon && (
              <div className='bg-gray-100 py-1.5 rounded-l px-2 border border-r-0'>
                {icon}
              </div>
            )}
            <DatePicker
              {...datePickerConfig}
              name={name}
              selected={value ? DateTime.fromISO(value).toJSDate() : null}
              onChange={(date: Date) => {
                if (datePickerConfig?.showTimeSelect) {
                  onChange(DateTime.fromJSDate(date).toISO());
                } else {
                  onChange(DateTime.fromJSDate(date).toISODate());
                }
              }}
              onBlur={onBlur}
              placeholderText={placeholder}
              autoComplete='off'
              readOnly={readOnly}
              dateFormatCalendar='MMMM'
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              id={name}
              disabledKeyboardNavigation
              customInput={customInput}
              className={cn(
                !customInput &&
                  'appearance-none border rounded-r border-gray-200 p-1.5 focus:ring-0 focus:border-gray-200  bg-white w-full',
                icon ? 'border-l-0' : 'rounded-l',
                {
                  'bg-gray-50': readOnly,
                },
              )}
            />
          </div>
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledDatePickerInputV7;
