/**
 * For react-query's `cacheTime`: number | Infinity
 * React-query defaults to 5 * 60 * 1000 (5 minutes) or Infinity during SSR
 * The time in milliseconds that unused/inactive cache data remains in memory.
 * When a query's cache becomes unused or inactive, that cache data will be garbage collected after this duration.
 * When different cache times are specified, the longest one will be used.
 * If set to Infinity, will disable garbage collection
 */
export const DEFAULT_CACHE_TIME = 30 * 60 * 1000; // 30 minutes

/**
 * For react-query's `staleTime`: number | Infinity
 * React-query defaults to 0, if `staleTime` is not specified.
 * The time in milliseconds after data is considered stale.
 * This value only applies to the hook it is defined on.
 * If set to Infinity, the data will never be considered stale
 */
export const DEFAULT_STALE_TIME = 30 * 60 * 1000; // 30 minutes
