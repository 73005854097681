import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp,
  faFileCheck,
  faEnvelopeCircleCheck,
  faTriangleExclamation,
  faCircleCheck,
  faMoneyCheckDollarPen,
  faHouseLock,
  faFileCertificate,
  faFileExcel,
  faXmark,
  faCircleDot,
  faFileContract,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponseCountryEnum,
} from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { renderTransactionStatusByCountry } from '../../../utils/TransactionHelper';
import { cn } from '../../../utils/classUtils';

type TransactionStatusVariantType =
  | 'default'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'outline'
  | 'inactive'
  | 'selected'
  | 'alert';

export const variantToClassNameMap: EnumMap<
  TransactionStatusVariantType,
  string
> = {
  default: 'bg-primary-light text-primary-dark',
  primary: 'bg-regent-200 text-primary-blue',
  success: 'bg-green-100 text-green-600',
  warning: 'bg-yellow-100/50 text-zen-warning-dark',
  danger: 'bg-red-100 text-red-600',
  outline: 'bg-orange-100 text-orange-600',
  inactive: 'bg-grey-100 text-grey-400',
  selected: 'bg-grey-100 text-grey-600',
  alert: 'bg-purple-100 text-purple-400',
};

export const statusToVariant: EnumMap<
  TransactionLifecycleStateValueStateEnum,
  TransactionStatusVariantType
> = {
  [TransactionLifecycleStateValueStateEnum.ApprovedForClosing]: 'success',
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved]:
    'primary',
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentGenerated]:
    'outline',
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentSent]: 'primary',
  [TransactionLifecycleStateValueStateEnum.ReadyForCommissionDocumentGeneration]:
    'selected',
  [TransactionLifecycleStateValueStateEnum.Settled]: 'success',
  [TransactionLifecycleStateValueStateEnum.CalculateLedger]: 'selected',
  [TransactionLifecycleStateValueStateEnum.WaitingOnPayment]: 'success',
  [TransactionLifecycleStateValueStateEnum.Closed]: 'success',
  [TransactionLifecycleStateValueStateEnum.CommissionValidated]: 'primary',
  [TransactionLifecycleStateValueStateEnum.NeedsCommissionValidation]:
    'warning',
  [TransactionLifecycleStateValueStateEnum.New]: 'selected',
  [TransactionLifecycleStateValueStateEnum.PaymentAccepted]: 'success',
  [TransactionLifecycleStateValueStateEnum.PaymentScheduled]: 'success',
  [TransactionLifecycleStateValueStateEnum.Terminated]: 'danger',
  [TransactionLifecycleStateValueStateEnum.ListingActive]: 'success',
  [TransactionLifecycleStateValueStateEnum.ListingClosed]: 'inactive',
  [TransactionLifecycleStateValueStateEnum.ListingInContract]: 'warning',
  [TransactionLifecycleStateValueStateEnum.TerminationRequested]: 'alert',
};

export interface ZenTransactionLifeCycleStateProps {
  status: TransactionLifecycleStateValueStateEnum;
  country: TransactionResponseCountryEnum;
}

const ZenTransactionStatus: React.FC<ZenTransactionLifeCycleStateProps> = ({
  status,
  country,
}) => {
  const transactionStatusIconMap: EnumMap<
    TransactionLifecycleStateValueStateEnum,
    IconDefinition
  > = {
    [TransactionLifecycleStateValueStateEnum.ApprovedForClosing]: faThumbsUp,
    [TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved]: faThumbsUp,
    [TransactionLifecycleStateValueStateEnum.CommissionDocumentGenerated]: faFileCheck,
    [TransactionLifecycleStateValueStateEnum.CommissionDocumentSent]: faEnvelopeCircleCheck,
    [TransactionLifecycleStateValueStateEnum.ReadyForCommissionDocumentGeneration]: faTriangleExclamation,
    [TransactionLifecycleStateValueStateEnum.Settled]: faCircleCheck,
    [TransactionLifecycleStateValueStateEnum.CalculateLedger]: faTriangleExclamation,
    [TransactionLifecycleStateValueStateEnum.WaitingOnPayment]: faMoneyCheckDollarPen,
    [TransactionLifecycleStateValueStateEnum.Closed]: faHouseLock,
    [TransactionLifecycleStateValueStateEnum.CommissionValidated]: faFileCertificate,
    [TransactionLifecycleStateValueStateEnum.NeedsCommissionValidation]: faFileExcel,
    [TransactionLifecycleStateValueStateEnum.New]: faTriangleExclamation,
    [TransactionLifecycleStateValueStateEnum.PaymentAccepted]: faMoneyCheckDollarPen,
    [TransactionLifecycleStateValueStateEnum.PaymentScheduled]: faMoneyCheckDollarPen,
    [TransactionLifecycleStateValueStateEnum.Terminated]: faXmark,
    [TransactionLifecycleStateValueStateEnum.ListingActive]: faCircleDot,
    [TransactionLifecycleStateValueStateEnum.ListingClosed]: faCircleCheck,
    [TransactionLifecycleStateValueStateEnum.ListingInContract]: faFileContract,
    [TransactionLifecycleStateValueStateEnum.TerminationRequested]: faXmark,
  };

  const variant = safeEnumMapGet(statusToVariant, status, 'default');

  const icon = safeEnumMapGet(
    transactionStatusIconMap,
    status,
    transactionStatusIconMap['NEW'],
  );

  return (
    <div
      className={cn(
        'w-max flex items-center space-x-1.5 flex-grow flex-shrink-0 py-1.5 px-2 rounded-full md:whitespace-nowrap',
        variantToClassNameMap[variant],
      )}
    >
      <FontAwesomeIcon icon={icon} className='text-sm' />
      <span>{renderTransactionStatusByCountry(status, country)}</span>
    </div>
  );
};

export default ZenTransactionStatus;
