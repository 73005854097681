interface ReviewRowProps {
  label: string;
  value: string | string[];
}

const ReviewRow: React.FC<ReviewRowProps> = ({ label, value }) => {
  const content = Array.isArray(value) ? value : [value];

  return (
    <div className='flex flex-col space-y-1'>
      <label className='font-primary-light text-sm text-dark'>{label}</label>
      {content.map((text) => (
        <p
          key={text}
          className='font-primary-regular font-bold text-sm text-dark'
        >
          {text}
        </p>
      ))}
    </div>
  );
};

export default ReviewRow;
