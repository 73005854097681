import { faCheck, faHourglassHalf } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { cn } from '../../../../utils/classUtils';

interface GeminiCompliancePopoverProps {
  isComplianceVerified: boolean;
}
const GeminiCompliancePopover: React.FC<GeminiCompliancePopoverProps> = ({
  isComplianceVerified,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['top']}
      content={() => (
        <div className='px-2 py-1 rounded-md border border-primary-dark bg-white mb-2 shrink-0'>
          <span className='font-zen-body-2 text-sm leading-4 font-normal text-center'>
            {isComplianceVerified
              ? 'Compliance Verified'
              : 'Compliance Not Verified'}
          </span>
        </div>
      )}
    >
      <button
        className='flex items-center justify-center'
        onMouseEnter={() => setIsPopoverOpen(true)}
        onMouseLeave={() => setIsPopoverOpen(false)}
      >
        <FontAwesomeIcon
          icon={isComplianceVerified ? faCheck : faHourglassHalf}
          className={cn(
            'text-base',
            isComplianceVerified ? 'text-green-600' : 'text-yellow-600',
          )}
          data-testid='gemini-transaction-compliance-icon'
        />
      </button>
    </Popover>
  );
};

export default GeminiCompliancePopover;
