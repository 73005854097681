import { DropboxResponse, FileResponse } from '../openapi/dropbox';
import { FileReferenceDto, TemplateReferenceDto } from '../openapi/sherlock';

export const getDropboxFilesFromTemplateReferences = (
  templateReferences: TemplateReferenceDto[],
  dropboxById: Record<string, DropboxResponse | undefined>,
) => {
  let preAttachedDropboxFiles: FileResponse[] = [];
  templateReferences?.forEach((ref) => {
    const dropboxFile = dropboxById[ref?.dropboxId!]?.files?.find(
      (file) => file.id === ref.fileId,
    );
    if (dropboxFile) {
      preAttachedDropboxFiles.push(dropboxFile);
    }
  });

  return preAttachedDropboxFiles;
};

export const getDropboxFilesFromFileReferences = (
  fileReferences: FileReferenceDto[],
  dropboxById: Record<string, DropboxResponse | undefined>,
  dropboxId: string,
) => {
  const allDropboxFiles = dropboxById[dropboxId!]?.files;
  let dropboxFiles: FileResponse[] = [];
  fileReferences?.forEach((fileReference) => {
    const dropboxFile = allDropboxFiles?.find(
      (file) => file.id === fileReference.fileId,
    );
    if (dropboxFile) {
      dropboxFiles.push(dropboxFile);
    }
  });

  return dropboxFiles;
};
