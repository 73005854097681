import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { faBuildings } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthorizationContainer from '../components/auth/AuthorizationContainer';
import FeatureFlagEnabledOnly from '../components/FeatureFlagEnabledOnly';
import ResourceContainer from '../components/ResourceContainer';
import OptSingleCheck from '../components/SingleCheck/OptSingleCheck';
import ZenBankAccountTabs from '../components/Zen/ZenBankAccountTabs';
import ZenButton from '../components/Zen/ZenButton';
import ZenCard from '../components/Zen/ZenCard';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenTaxTabs from '../components/Zen/ZenTaxTabs';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { fetchAgentDetail } from '../slices/AgentSlice';
import { getAvailableCountries } from '../slices/AuthSlice';
import {
  archivePaymentMethod,
  fetchPaymentMethods,
} from '../slices/PaymentMethodSlice';
import { archiveTaxForm, getTaxForms } from '../slices/TaxInformationSlice';
import { AppDispatch, FeatureFlagTypeEnum, RootState } from '../types';
import { showSingleCheckPreference } from '../utils/SingleCheckUtils';

interface AgentDetailSecuritySettingsRouteProps {
  agentId: string;
}

const AgentDetailPaymentSettingsRoute: React.FC<AgentDetailSecuritySettingsRouteProps> = ({
  agentId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const {
    auth: { userDetail, isAdmin },
    taxInformation,
    paymentMethod,
    agentDetail: { detailResponse },
  } = useSelector((state: RootState) => state);
  const isSelf = userDetail?.id === agentId;
  const id = isSelf ? userDetail?.id : agentId;

  const [isStartingOver, setIsStartingOver] = useState(false);
  const [loadingPreferSingleCheck, setLoadingPreferSingleCheck] = useState(
    true,
  );
  const isMSDynamicsOnboardingEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.MS_DYNAMICS_ONBOARDING,
  );

  const isStartOverButtonDisabled = !isSelf || isStartingOver;

  const fetchTaxAndPayment = useCallback(() => {
    if (isMSDynamicsOnboardingEnabled && id) {
      dispatch(getAvailableCountries(id));
      dispatch(getTaxForms(id));
      dispatch(fetchPaymentMethods(id));
    }
  }, [dispatch, id, isMSDynamicsOnboardingEnabled]);

  useEffect(() => {
    fetchTaxAndPayment();
  }, [fetchTaxAndPayment]);

  useEffect(() => {
    dispatch(fetchAgentDetail(id!, false)).finally(() => {
      setLoadingPreferSingleCheck(false);
    });
  }, [id, dispatch]);

  const taxForms = taxInformation.taxFormResponseByAgentId[id!];
  const bankAccounts = paymentMethod.paymentMethodsResponseByAgentId[id!];

  const handleStartOver = async () => {
    setIsStartingOver(true);
    const requests: Promise<boolean>[] = [];
    taxForms?.data?.taxForms?.forEach((taxForm) => {
      requests.push(
        dispatch(archiveTaxForm(id!, taxForm.id!, { silent: true })),
      );
    });
    bankAccounts?.data?.paymentMethods?.forEach((bankAccount) => {
      requests.push(
        dispatch(
          archivePaymentMethod(id!, bankAccount.id!, {
            silent: true,
          }),
        ),
      );
    });
    await Promise.all(requests);
    // resetting
    await Promise.all([
      dispatch(fetchPaymentMethods(id!)),
      dispatch(getTaxForms(id!)),
    ]);
    // non-admins will be redirected with useAgentRedirect
    if (isAdmin) {
      history.push('/payment-onboarding');
    }
    setIsStartingOver(false);
  };

  return (
    <ZenRoute title='Payment Settings'>
      <div className='px-4 py-8'>
        {showSingleCheckPreference(detailResponse.data) && (
          <ZenCard
            testId='opt-single-check-card'
            title='Direct Deposit Option for Commissions'
            LeftComponent={
              <FontAwesomeIcon
                icon={faUser}
                size='sm'
                className='text-zen-dark-9'
              />
            }
          >
            <ResourceContainer
              isEmpty={false}
              loading={loadingPreferSingleCheck}
              resourceName='single check preference'
            >
              <OptSingleCheck />
            </ResourceContainer>
          </ZenCard>
        )}

        <FeatureFlagEnabledOnly
          flag={FeatureFlagTypeEnum.MS_DYNAMICS_ONBOARDING}
        >
          <div className='flex flex-row justify-end items-center my-4'>
            <ZenButton
              label={`${isStartingOver ? 'Starting' : 'Start'} Over`}
              textSize='sm'
              variant='primary-outline'
              onClick={handleStartOver}
              isDisabled={isStartOverButtonDisabled}
              isSubmitting={isStartingOver}
            />
          </div>
          <div className='grid md:grid-cols-2 grid-cols-1 gap-x-8 gap-y-8 justify-self-stretch'>
            <div className='grid'>
              <ZenCard testId='offices-card' title='Tax Forms'>
                <AuthorizationContainer asyncResponse={taxForms}>
                  <ResourceContainer
                    loading={!!taxForms?.loading}
                    isEmpty={!taxForms?.data?.taxForms?.length}
                    resourceName='tax form'
                    emptyIcon={
                      <FontAwesomeIcon
                        icon={faBuildings}
                        size='2x'
                        className='mb-1 scale-x-[-1]'
                      />
                    }
                  >
                    <ZenTaxTabs
                      agentId={id!}
                      taxData={taxForms?.data?.taxForms!}
                    />
                  </ResourceContainer>
                </AuthorizationContainer>
              </ZenCard>
            </div>
            <div className='grid'>
              <ZenCard testId='offices-card' title='Bank Accounts'>
                <AuthorizationContainer asyncResponse={bankAccounts}>
                  <ResourceContainer
                    loading={!!bankAccounts?.loading}
                    isEmpty={!bankAccounts?.data?.paymentMethods?.length}
                    resourceName='bank account'
                    emptyIcon={
                      <FontAwesomeIcon
                        icon={faBuildings}
                        size='2x'
                        className='mb-1 scale-x-[-1]'
                      />
                    }
                  >
                    <ZenBankAccountTabs
                      agentId={id!}
                      bankAccountData={bankAccounts?.data?.paymentMethods!}
                    />
                  </ResourceContainer>
                </AuthorizationContainer>
              </ZenCard>
            </div>
          </div>
        </FeatureFlagEnabledOnly>
      </div>
    </ZenRoute>
  );
};
export default AgentDetailPaymentSettingsRoute;
