import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';

interface ExcludeMortgagerUserRoleProps {}

const ExcludeMortgagerUserRole: React.FC<ExcludeMortgagerUserRoleProps> = ({
  children,
}) => {
  const { isMortgageUserRole } = useSelector((state: RootState) => state.auth);

  if (isMortgageUserRole) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};

export default ExcludeMortgagerUserRole;
