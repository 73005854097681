import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import EventsImg from '../../assets/img/zen/real-title/events.png';
import { REQUEST_EVENT_SUPPORT_URL } from '../../constants/RealTitleConstants';
import ButtonV2 from '../ButtonV2';

const RealClosingServicesRequestEventSupport: React.FC = () => {
  return (
    <div
      className='bg-seaglass p-6 md:p-12 md:px-20 flex flex-col-reverse md:flex-row gap-8 md:gap-12 justify-between overflow-clip'
      data-testid='real-closing-services-request-event-support'
    >
      <div className='w-full md:w-1/2 h-fit max-w-[445px] object-contain mx-auto md:mx-0 relative'>
        <img
          src={EventsImg}
          alt='events'
          className='relative z-10'
          data-testid='events-img'
        />
        <div className='h-full w-[calc(100%_+_96px)] absolute top-10 md:-top-14 -left-36 grid grid-cols-[200px_1fr] grid-rows-[60%_40%] md:grid-rows-[40%_60%]'>
          <div className='border-b-2 border-r-2 md:border-r-0 border-coral-red row-start-1 row-end-2' />
          <div className='md:border-r-2 border-coral-red row-start-2 row-end-3' />
          <div className='border-r-2 border-t-2 md:border-t-0 md:border-b-2 border-coral-red col-span-2 row-start-1 row-end-3 col-start-2 col-end-3' />
        </div>
      </div>
      <div className='w-full md:w-1/2'>
        <h1 className='font-poppins text-[40px] font-semibold leading-[48px] flex flex-col'>
          <span>Let us co-sponsor your</span>
          <span className='text-rezen-blue-600'> upcoming event!</span>
        </h1>
        <p className='font-inter text-primary-dark text-lg font-light my-4'>
          Whether it’s swag, food, or more, we’re here to support your events
        </p>
        <ButtonV2
          label='Request Event Support'
          className='mt-5'
          icon={faArrowUpRightFromSquare}
          onClick={() => window.open(REQUEST_EVENT_SUPPORT_URL, '_blank')}
        />
      </div>
    </div>
  );
};

export default RealClosingServicesRequestEventSupport;
