import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import DefaultLoader from '../../../components/DefaultLoader';
import ResourceContainer from '../../../components/ResourceContainer';
import ActivityTypeDisplayCell from '../../../components/table/Cells/ActivityTypeDisplayCell';
import MilliDateTimeCell from '../../../components/table/Cells/MilliDateTimeCell';
import ZenResourceIndexContainer from '../../../components/Zen/Containers/ZenResourceIndexContainer';
import ZenUserPill from '../../../components/Zen/ZenUserPill';
import useAgentsInfoFromKeymakerId from '../../../hooks/useAgentInfosFromKeymakerId';
import {
  ActivityResponse,
  PagedActivityResponse,
} from '../../../openapi/memento';
import { RootState } from '../../../types';
import { isSmScreen } from '../../../utils/BrowserUtils';
import { getActivityUserFullName } from '../../../utils/TransactionHelper';

interface ZenActivityTableProps {
  fetchActivities(pageNumber: number): Promise<PagedActivityResponse>;
  onError: (e: any) => void;
  getPublicUserInfo?: boolean;
}

const ZenActivityTable: React.FC<ZenActivityTableProps> = ({
  fetchActivities,
  onError,
  getPublicUserInfo = false,
}) => {
  const [page, setPage] = useState<number>(0);
  const [hasNext, setIsNext] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activities, setActivities] = useState<ActivityResponse[]>([]);

  const {
    userIds: { agentByKeymakerId },
  } = useSelector((state: RootState) => state);

  const handleFetch = useCallback(
    async (page: number, loading: boolean = true) => {
      if (loading) {
        setIsLoading(true);
      }

      try {
        const response = await fetchActivities(page);

        if (page === 0) {
          setActivities(response.activities || []);
        } else {
          setActivities([...activities, ...(response.activities || [])]);
        }

        setIsNext(!!response.hasNext);

        if (!!response.hasNext) {
          setPage((page) => page + 1);
        }
      } catch (e) {
        onError(e);
      } finally {
        if (loading) {
          setIsLoading(false);
        }
      }
    },
    [activities, fetchActivities, onError],
  );

  useEffect(() => {
    handleFetch(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agentIds = useMemo(
    () =>
      activities
        ?.filter((activity) => !activity.createdByExternal)
        .map((act) => act.createdBy!) || [],
    [activities],
  );

  useAgentsInfoFromKeymakerId(agentIds, getPublicUserInfo);

  const columns: Array<Column<ActivityResponse>> = [
    {
      Header: 'NAME',
      accessor: 'createdBy',
      Cell: ({ row }) => (
        <div className='whitespace-pre py-3'>
          <ZenUserPill
            name={
              getActivityUserFullName(
                agentByKeymakerId[row.original?.createdBy!],
                row.original?.createdByExternal!,
              ) || 'N/A'
            }
            imageUrl={
              !!agentByKeymakerId[row.original.createdBy!]! &&
              agentByKeymakerId[row.original.createdBy!]?.avatar
            }
            backgroundVariant='no-background'
            size='sm'
            fontWeight='font-normal'
          />
        </div>
      ),
    },
    {
      Header: 'TYPE',
      accessor: 'activityType',
      Cell: ({ value }) => <ActivityTypeDisplayCell type={value!} />,
    },
    {
      Header: 'DESCRIPTION',
      accessor: 'description',
      Cell: ({ value }) => (
        <p className='font-zen-body font-normal text-sm text-zen-dark-9'>
          {value}
        </p>
      ),
    },
    {
      Header: 'DATE & TIME',
      accessor: 'createdAt',
      Cell: ({ value }) => (
        <div className='font-zen-body font-semibold text-sm whitespace-pre text-zen-dark-6'>
          <MilliDateTimeCell date={value} />
        </div>
      ),
    },
  ];

  return (
    <div className='px-[13px] py-[21px]' id='scrollableDiv'>
      <ResourceContainer
        isEmpty={!activities?.length}
        loading={isLoading}
        resourceName='Activity'
        emptyMessage='There are no activities to display.'
      >
        <InfiniteScroll
          hasMore={hasNext}
          loader={<DefaultLoader />}
          dataLength={activities?.length || 0}
          scrollableTarget={!isSmScreen() ? 'main-page' : undefined}
          next={() => handleFetch(page, false)}
        >
          <ZenResourceIndexContainer
            columns={columns}
            resourceName='activity'
            data={activities || []}
            hidePageSize
            hidePagination
            hideFilters
          />
        </InfiniteScroll>
      </ResourceContainer>
    </div>
  );
};

export default ZenActivityTable;
