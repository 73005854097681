import { isEmpty } from 'lodash';
import {
  AccountApplicationsApi,
  CreateCreditAccountApplication,
  CreateCustomerToken,
  CreateDebitAccountApplication,
  CreateMfaTokenMfaChannelEnum,
  CustomerTokenApi,
  CustomerTokenDto,
  EligibleOfferingsDto,
  MfaTokenDto,
  OfferingApi,
  WalletApi,
  WalletDto,
} from '../../openapi/wallet';
import ErrorService from '../../services/ErrorService';
import { getWalletConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { queryKeys } from '../base/queryKeys';
import { useBaseMutation } from '../base/useBaseMutation';
import { useSimpleQuery } from '../base/useSimpleQuery';

export const useWallet = () => {
  const fetchWalletDetails = async () => {
    const { data } = await new WalletApi(
      getWalletConfiguration(),
    ).getMyWallet();

    return data;
  };

  const queryResult = useSimpleQuery<WalletDto>({
    queryKey: queryKeys.wallet.details.queryKey,
    queryFn: fetchWalletDetails,
    options: {
      toastErrorMessage: 'Failed to fetch wallet!',
      logErrorMessage: 'Failed to fetch wallet!',
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isFetching,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
  };
};

export const useFetchEligibleOfferings = () => {
  const fetchCreditDetails = async () => {
    const { data } = await new OfferingApi(
      getWalletConfiguration(),
    ).getEligibleOfferings();

    return data;
  };

  const queryResult = useSimpleQuery<EligibleOfferingsDto>({
    queryKey: queryKeys.wallet._def,
    queryFn: fetchCreditDetails,
    options: {
      toastErrorMessage: 'Failed to fetch offerings!',
      logErrorMessage: 'Failed to fetch offerings!',
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isFetching,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
  };
};

export const useDebitApplication = () => {
  const createDebitApplication = async (
    application: CreateDebitAccountApplication,
  ) => {
    const { data } = await new AccountApplicationsApi(
      getWalletConfiguration(),
    ).createDebitAccountApplication(application);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.wallet._def,
    mutationFn: createDebitApplication,
  });
};

export const useCreditApplication = () => {
  const createCreditApplication = async (
    application: CreateCreditAccountApplication,
  ) => {
    const { data } = await new AccountApplicationsApi(
      getWalletConfiguration(),
    ).createCreditAccountApplication(application);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.wallet._def,
    mutationFn: createCreditApplication,
  });
};

export const useCreateMfaToken = (bankingPartnershipId?: string) => {
  const createMfaToken = async () => {
    const { data } = await new CustomerTokenApi(
      getWalletConfiguration(),
    ).createMfaToken({
      bankingPartnershipId: bankingPartnershipId!,
      mfaChannel: CreateMfaTokenMfaChannelEnum.Sms,
    });

    return data;
  };

  const queryResult = useSimpleQuery<MfaTokenDto>({
    queryKey: queryKeys.wallet.mfa.queryKey,
    queryFn: createMfaToken,
    options: {
      toastErrorMessage: 'Failed to create MFA token!',
      logErrorMessage: 'Failed to create MFA token!',
      enabled: !!bankingPartnershipId,
      cacheTime: 5 * 60 * 1000, // The time cache data remains in memory.
      staleTime: 0, // The time in milliseconds after data is considered stale
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isFetching,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
  };
};

export const useCreateSensitiveToken = (
  customerTokenRequest: CreateCustomerToken,
) => {
  const createSensitiveToken = async () => {
    const { data } = await new CustomerTokenApi(
      getWalletConfiguration(),
    ).createSensitiveToken(customerTokenRequest);

    return data;
  };

  const enabled =
    !isEmpty(customerTokenRequest.mfa?.token) &&
    !isEmpty(customerTokenRequest?.bankingPartnershipId);

  const queryResult = useSimpleQuery<CustomerTokenDto>({
    queryKey: queryKeys.wallet.customer.queryKey,
    queryFn: createSensitiveToken,
    options: {
      toastErrorMessage: 'Failed to create sensitive token!',
      logErrorMessage: 'Failed to create sensitive token!',
      enabled: enabled,
      cacheTime: 5 * 60 * 1000, // The time cache data remains in memory. BE token is valid for 10 minutes
      staleTime: 0, // The time in milliseconds after data is considered stale
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isFetching,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
  };
};
