import { keys } from 'lodash';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import MultiSelectColumnFilter from './Base/MultiSelectColumnFilter';

const EnumMultiSelectColumnGenerator = <T extends object>(SomeEnum: any) => {
  return (props: SelectColumnFilterProps<T>) => {
    const options = keys(SomeEnum).map((key) => ({
      label: capitalizeEnum(SomeEnum[key]),
      value: SomeEnum[key],
    }));

    return (
      <MultiSelectColumnFilter {...props} options={props.options ?? options} />
    );
  };
};

export default EnumMultiSelectColumnGenerator;
