/* eslint-disable react/jsx-no-undef */
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flatten } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import {
  TransactionControllerApi,
  TransactionLiteResponse,
  TransactionResponseCountryEnum,
} from '../../../openapi/arrakis';
import { fetchChecklistProgressById } from '../../../slices/CheckListSlice';
import { fetchAgentsInfo } from '../../../slices/UserIdsSlice';
import { AppDispatch, EnumMap, RootState } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { isListingNotActionable } from '../../../utils/TransactionUtils';
import IconButton from '../../IconButton';
import ZenViewActionButtonCell from '../../table/Cells/ZenViewActionButtonCell';
import TransactionTypeSelectColumnFilter from '../../table/Filters/TransactionTypeSelectColumnFilter';
import ZenResourceIndexContainer from '../Containers/ZenResourceIndexContainer';
import ZenRouterTabs, { ZenTab } from '../Tab/ZenRouterTabs';
import ZenAddressCell from '../Table/Cell/ZenAddressCell';
import ZenDateCell from '../Table/Cell/ZenDateCell';
import ZenTransactionDealTypeLabel from '../Transaction/Header/ZenTransactionDealTypeLabel';
import ZenAgentsInfoPopper from '../Transaction/ZenAgentsInfoPopper';
import ZenChecklistPopper from '../Transaction/ZenChecklistPopper';
import ZenJourneyPopper from '../Transaction/ZenJourneyPopper';
import ZenTransactionCodeCell from '../Transaction/ZenTransactionCodeCell';
import ZenTransactionStatus from '../Transaction/ZenTransactionStatus';
import ZenRoute from '../ZenRoute';

export const columns: Array<Column<TransactionLiteResponse>> = [
  {
    Header: '',
    accessor: 'id',
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, string>) => (
      <Link to={`/listings/${original.id}`}>
        <ZenViewActionButtonCell />
      </Link>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Listing Code',
    accessor: 'code',
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, string>) => (
      <ZenTransactionCodeCell
        linkTo={`/listings/${original.id}`}
        transaction={original}
        hideInvoice
      />
    ),
    cardColSize: 6,
    disableSortBy: true,
  },
  {
    Header: 'Link',
    accessor: 'inContractTransactionId',
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, string>) => {
      const { inContractTransactionId } = original;
      if (isListingNotActionable(original) && !!inContractTransactionId) {
        return (
          <Link to={`/transactions/${inContractTransactionId}/detail`}>
            <IconButton
              variant='none'
              leftIcon={
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className='m-1 text-base text-white'
                  title='Link'
                />
              }
              toolTipText='View Associated Transaction'
              buttonStyle='border-primary-blue bg-primary-blue rounded-lg'
            />
          </Link>
        );
      }
      return null;
    },
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Deal Type',
    accessor: 'transactionType',
    Cell: ({ value }) => <ZenTransactionDealTypeLabel dealType={value!} />,
    Filter: TransactionTypeSelectColumnFilter,
    filter: 'multiSelectFilter',
    disableSortBy: true,
  },
  {
    Header: 'Address',
    accessor: (d) => d['address']?.street,
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, string>) => (
      <Link to={`/listings/${original.id}`}>
        <ZenAddressCell address={original.address} />
      </Link>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Agents',
    accessor: 'participantYentaIds',
    Cell: ({ value, row: { original } }) => (
      <ZenAgentsInfoPopper
        participants={value || []}
        represents={original.transactionOwner?.represents}
      />
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Checklist Complete',
    accessor: 'checklistId',
    Cell: ({ row: { original }, value }) => (
      <ZenChecklistPopper checklistId={value} transactionId={original.id} />
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Journey Progress',
    accessor: 'journeyId',
    Cell: ({ row: { original }, value }) => (
      <ZenJourneyPopper journeyId={value} transactionId={original.id} />
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'List Price',
    accessor: 'price',
    id: 'price',
    Cell: ({ row: { original } }: CellProps<TransactionLiteResponse>) => (
      <Link to={`/listings/${original.id}`}>
        {displayAmount(original.price!, { hideCurrency: true })}
        <div className='text-sm	text-zen-gray-4'>{original.price?.currency}</div>
      </Link>
    ),
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Status',
    accessor: ({ lifecycleState }) => lifecycleState?.state,
    id: 'lifecycleState',
    Cell: ({
      value,
      row: { original },
    }: React.PropsWithChildren<CellProps<TransactionLiteResponse>>) => (
      <ZenTransactionStatus
        status={value}
        country={
          (original.country as unknown) as TransactionResponseCountryEnum
        }
      />
    ),
    disableFilters: true,
    cardColSize: 6,
    disableSortBy: true,
  },
  {
    Header: 'Listing Date',
    accessor: 'listingDate',
    Cell: ({ value }) => <ZenDateCell date={value!} />,
    disableFilters: true,
    cardColSize: 6,
  },
  {
    Header: 'Expiration Date',
    accessor: 'listingExpirationDate',
    Cell: ({ value }) => <ZenDateCell date={value!} />,
    disableFilters: true,
    cardColSize: 6,
  },
];

const ListingSortByTypeEnum: EnumMap<
  string,
  'ESCROW_CLOSING_DATE' | 'ACTUAL_CLOSING_DATE' | 'PRICE' | 'EXPIRATION_DATE'
> = {
  listingExpirationDate: 'EXPIRATION_DATE',
  skySlopeEscrowClosingDate: 'ESCROW_CLOSING_DATE',
  skySlopeActualClosingDate: 'ACTUAL_CLOSING_DATE',
  price: 'PRICE',
};

const ListingSortDirectionTypeEnum: EnumMap<string, 'ASC' | 'DESC'> = {
  asc: 'ASC',
  desc: 'DESC',
};

interface ZenAgentListingsProps {
  agentId: string;
  search?: string;
}

const ZenAgentListings: React.FC<ZenAgentListingsProps> = ({
  agentId,
  search,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    userIds: { agentById },
    transaction: {
      pagedOpenListingTransactions,
      pagedClosedListingTransactions,
      pagedTerminatedListingTransactions,
    },
    agentDetail: { detailResponse },
  } = useSelector((state: RootState) => state);

  const getChecklistProgress = useCallback(
    async (checklistIds: string[]) => {
      if (!!checklistIds?.length) {
        await dispatch(fetchChecklistProgressById(checklistIds));
      }
    },
    [dispatch],
  );

  const getAllAgentsDetails = useCallback(
    async (userIds: string[]) => {
      const agentIdsToFetch = userIds.filter((id) => !agentById[id]);
      await dispatch(fetchAgentsInfo(agentIdsToFetch));
    },
    [agentById, dispatch],
  );

  const tabs: ZenTab[] = useMemo(() => {
    const array = [
      {
        label: `Active (${pagedOpenListingTransactions?.totalCount || 0})`,
        path: `/people/${agentId}/listings`,
        exact: true,
        TabComponent: () => (
          <ZenRoute
            title={`Active Listings - ${detailResponse.data?.fullName}`}
          >
            <div className='mt-4'>
              <ZenResourceIndexContainer<any>
                columns={columns}
                resourceName='listing'
                hideFilters
                search={search}
                hidePageSize
                pageSize={10}
                initialSort={{ listingExpirationDate: 'asc' }}
                fetchData={async (req, cancelToken) => {
                  const sortKey = Object.keys(req.sortBy || {})[0];
                  const sortType = Object.values(req.sortBy || {})[0];
                  const { data } = await new TransactionControllerApi(
                    getArrakisConfiguration(),
                  ).getListingTransactionsByStateGroupPaginated(
                    agentId,
                    'OPEN',
                    req.page,
                    req.pageSize,
                    req.search,
                    ListingSortByTypeEnum[sortKey],
                    ListingSortDirectionTypeEnum[sortType!],
                    undefined,
                    { cancelToken },
                  );

                  const agentIds =
                    flatten(
                      data.transactions?.map(
                        (builder) => builder.participantYentaIds!,
                      ),
                    ) || [];

                  await getAllAgentsDetails(agentIds);

                  const checklistIds = flatten(
                    data.transactions?.map((builder) => [
                      builder.checklistId!,
                      builder.journeyId!,
                    ]),
                  ).filter((id) => !!id);

                  await getChecklistProgress(checklistIds);

                  return {
                    data: data.transactions!,
                    total: data.totalCount!,
                  };
                }}
              />
            </div>
          </ZenRoute>
        ),
      },
      {
        label: `Closed (${pagedClosedListingTransactions?.totalCount || 0})`,
        path: `/people/${agentId}/listings/closed`,
        exact: true,
        TabComponent: () => (
          <ZenRoute
            title={`Closed Listings - ${detailResponse.data?.fullName}`}
          >
            <div className='mt-4'>
              <ZenResourceIndexContainer<any>
                columns={columns}
                resourceName='listing'
                search={search}
                hideFilters
                hidePageSize
                pageSize={10}
                initialSort={{ listingExpirationDate: 'asc' }}
                fetchData={async (req) => {
                  const sortKey = Object.keys(req.sortBy || {})[0];
                  const sortType = Object.values(req.sortBy || {})[0];
                  const { data } = await new TransactionControllerApi(
                    getArrakisConfiguration(),
                  ).getListingTransactionsByStateGroupPaginated(
                    agentId,
                    'CLOSED',
                    req.page,
                    req.pageSize,
                    req.search,
                    ListingSortByTypeEnum[sortKey],
                    ListingSortDirectionTypeEnum[sortType!],
                  );

                  const agentIds =
                    flatten(
                      data.transactions?.map(
                        (builder) => builder.participantYentaIds!,
                      ),
                    ) || [];

                  await getAllAgentsDetails(agentIds);

                  const checklistIds = flatten(
                    data.transactions?.map((builder) => [
                      builder.checklistId!,
                      builder.journeyId!,
                    ]),
                  ).filter((id) => !!id);

                  await getChecklistProgress(checklistIds);

                  return {
                    data: data.transactions!,
                    total: data.totalCount!,
                  };
                }}
              />
            </div>
          </ZenRoute>
        ),
      },
      {
        label: `Terminated (${
          pagedTerminatedListingTransactions?.totalCount || 0
        })`,
        path: `/people/${agentId}/listings/terminated`,
        exact: true,
        TabComponent: () => (
          <ZenRoute
            title={`Terminated Listings - ${detailResponse.data?.fullName}`}
          >
            <div className='mt-4'>
              <ZenResourceIndexContainer<any>
                columns={columns}
                hiddenColumns={['inContractTransactionId']}
                resourceName='listing'
                search={search}
                hideFilters
                hidePageSize
                pageSize={10}
                initialSort={{ listingExpirationDate: 'asc' }}
                fetchData={async (req) => {
                  const sortKey = Object.keys(req.sortBy || {})[0];
                  const sortType = Object.values(req.sortBy || {})[0];
                  const { data } = await new TransactionControllerApi(
                    getArrakisConfiguration(),
                  ).getListingTransactionsByStateGroupPaginated(
                    agentId,
                    'TERMINATED',
                    req.page,
                    req.pageSize,
                    req.search,
                    ListingSortByTypeEnum[sortKey],
                    ListingSortDirectionTypeEnum[sortType!],
                  );

                  const agentIds =
                    flatten(
                      data.transactions?.map(
                        (builder) => builder.participantYentaIds!,
                      ),
                    ) || [];

                  await getAllAgentsDetails(agentIds);

                  const checklistIds = flatten(
                    data.transactions?.map((builder) => [
                      builder.checklistId!,
                      builder.journeyId!,
                    ]),
                  ).filter((id) => !!id);

                  await getChecklistProgress(checklistIds);

                  return {
                    data: data.transactions!,
                    total: data.totalCount!,
                  };
                }}
              />
            </div>
          </ZenRoute>
        ),
      },
    ];
    return array;
  }, [
    agentId,
    getAllAgentsDetails,
    getChecklistProgress,
    pagedClosedListingTransactions?.totalCount,
    pagedOpenListingTransactions?.totalCount,
    pagedTerminatedListingTransactions?.totalCount,
    search,
  ]);

  return <ZenRouterTabs tabs={tabs} />;
};

export default ZenAgentListings;
