interface ZenTextInfoRowProps {
  title: string;
  value: string;
}

const ZenTextInfoRow: React.FC<ZenTextInfoRowProps> = ({ title, value }) => {
  return (
    <div className='flex items-center justify-between'>
      <span className='text-base text-gray-600 font-zen-body font-light'>
        {title}
      </span>
      <span className='text-base text-zen-dark-9 font-zen-body font-medium'>
        {value}
      </span>
    </div>
  );
};

export default ZenTextInfoRow;
