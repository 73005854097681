import { regular, thin } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddGroupMemberRequestRoleEnum,
  GroupResponse,
} from '../../openapi/yenta';
import { addOfficeGroupMember } from '../../slices/OfficeSlice';
import { AsyncSelectOption, ISelectOption, RootState } from '../../types';
import AdminOnly from '../auth/AdminOnly';
import GeminiButton from '../Gemini/GeminiButton';
import ResourceContainer from '../ResourceContainer';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import GeminiOfficeGroupCardHeader from './GeminiOfficeGroupCardHeader';
import GeminiOfficeGroupCardRow from './GeminiOfficeGroupCardRow';

interface GeminiOfficeGroupCardProps {
  group: GroupResponse;
  officeId: string;
}

export interface GeminiGroupMemberFormData {
  userId?: AsyncSelectOption;
  role?: ISelectOption<AddGroupMemberRequestRoleEnum>;
}
interface SearchForm {
  search: string;
}

const GeminiOfficeGroupCard: React.FC<GeminiOfficeGroupCardProps> = ({
  group,
  officeId,
}) => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const dispatch = useDispatch();
  const { isAdmin } = useSelector((state: RootState) => state.auth);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<GeminiGroupMemberFormData>({
    defaultValues: {
      role: undefined,
      userId: undefined,
    },
  });

  const { control: searchControl, watch } = useForm<SearchForm>({
    defaultValues: {
      search: '',
    },
  });

  const search = watch('search');

  const onSubmit = async (formData: GeminiGroupMemberFormData) => {
    await dispatch(
      addOfficeGroupMember(officeId, group?.id!, {
        role: formData?.role?.value!,
        userId: formData?.userId?.value,
      }),
    );
    setValue('userId', {
      label: 'Search',
      value: '',
    });
    setValue('role', { label: '', value: '' as AddGroupMemberRequestRoleEnum });
    setIsHeaderVisible(false);
  };

  const handleAddMemberClicked = (): void => {
    handleSubmit(onSubmit)();
  };

  return (
    <div className='rounded-[10px] border border-grey-200 bg-white'>
      <div className='flex flex-row justify-between items-center bg-grey-100 h-[45px] px-3 text-lg font-inter text-primary-dark space-x-1 rounded-t-[10px]'>
        <span className='flex flex-grow flex-shrink font-medium text-sm md:text-base font-inter'>
          {group?.groupName}
        </span>
        {group?.groupMembers?.length !== 0 && isAdmin && !isHeaderVisible && (
          <div className='flex flex-shrink-0 flex-row items-center space-x-2'>
            <div className='flex justify-center items-center'>
              <GeminiButton
                label='Add Member'
                variant='primary-link'
                leftIcon={faPlus}
                onClick={() => setIsHeaderVisible(!isHeaderVisible)}
                size='sm'
              />
            </div>
          </div>
        )}
      </div>
      <GeminiOfficeGroupCardHeader
        isVisible={isHeaderVisible}
        groupMembers={group?.groupMembers}
        control={control}
        onSubmit={handleAddMemberClicked}
        onCancel={() => setIsHeaderVisible(!isHeaderVisible)}
        isSubmitting={isSubmitting}
      />
      <ResourceContainer
        isEmpty={!isHeaderVisible && !group?.groupMembers?.length}
        loading={false}
        resourceName='Member'
        errorCode={null}
        EmptyComponent={
          <div className='flex flex-col justify-center items-center p-5 min-h-[434px]'>
            <FontAwesomeIcon
              icon={thin('user-xmark')}
              className='text-regent-600 text-5xl font-extralight mb-3'
              size='sm'
            />
            <p className='text-center text-regent-600 mb-4 font-inter'>
              No Member Added Yet.
            </p>
            <AdminOnly>
              <GeminiButton
                label='Add Member'
                leftIcon={faPlus}
                onClick={() => setIsHeaderVisible(!isHeaderVisible)}
                size='sm'
              />
            </AdminOnly>
          </div>
        }
      >
        <div className='flex flex-col px-2'>
          {!isHeaderVisible && (
            <>
              <div className='px-2 mt-4'>
                <ZenControlledTextInput
                  name='search'
                  control={searchControl}
                  type='search'
                  placeholder='Search for Broker by name or email...'
                  startAdornment={
                    <div className='flex w-full h-full items-center justify-center pl-2'>
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={regular('search')}
                      />
                    </div>
                  }
                />
              </div>
              <div className='flex flex-col min-h-[380px] max-h-[380px] overflow-y-auto'>
                {group?.groupMembers
                  ?.filter((member) => {
                    const fullName = member.user?.fullName || '';
                    const emailAddress = member.user?.emailAddress || '';
                    const searchString = (search || '').toLowerCase();
                    return (
                      fullName.toLowerCase().includes(searchString) ||
                      emailAddress.toLowerCase().includes(searchString)
                    );
                  })
                  .map((member) => {
                    return (
                      <GeminiOfficeGroupCardRow
                        key={member?.user?.id}
                        member={member}
                        groupId={group?.id!}
                        officeId={officeId}
                        isVisible={!isHeaderVisible}
                        length={group?.groupMembers?.length}
                      />
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </ResourceContainer>
    </div>
  );
};

export default GeminiOfficeGroupCard;
