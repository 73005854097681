import React, { useRef } from 'react';
import Bannerimage from '../assets/img/zen/real-mortgage/V2/banner.png';
import MortgageMapImage from '../assets/img/zen/real-mortgage/V2/mortgage-map.png';
import AnalyticsEventOnLoad from '../components/Analytics/AnalyticsEventOnLoad';
import PageLayout from '../components/PageLayout';
import OneRealMortgageContactUs from '../components/RealMortgage/V2/OneRealMortgageContactUs';
import OneRealMortgageGetQuote from '../components/RealMortgage/V2/OneRealMortgageGetQuote';
import OneRealMortgageHeader from '../components/RealMortgage/V2/OneRealMortgageHeader';
import OneRealMortgageJoinUs from '../components/RealMortgage/V2/OneRealMortgageJoinUs';
import OneRealMortgageLandingSection from '../components/RealMortgage/V2/OneRealMortgageLandingSection';
import OneRealMortgagePreApproved from '../components/RealMortgage/V2/OneRealMortgagePreApproved';
import OneRealMortgageProductsAndOfferings from '../components/RealMortgage/V2/OneRealMortgageProductsAndOfferings';
import OneRealMortgageTestimonialsCarousal from '../components/RealMortgage/V2/OneRealMortgageTestimonialsCarousal';
import ZenRoute from '../components/Zen/ZenRoute';
import { AnalyticsEventEnum } from '../types';

interface OneRealMortgageRouteV2Props {
  onGetClientPreApproved?: () => void;
}

const OneRealMortgageRouteV2: React.FC<OneRealMortgageRouteV2Props> = ({
  onGetClientPreApproved,
}) => {
  const handleGetClientPreApproved = () => {
    if (onGetClientPreApproved) {
      onGetClientPreApproved();
    } else {
      window.open('/mortgages', '_blank');
    }
  };

  const contactRef = useRef<HTMLDivElement>(null);

  return (
    <ZenRoute title='One Real Mortgage'>
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Real Mortgage', url: '/onereal-mortgage' },
        ]}
      >
        <div className='font-inter flex flex-col'>
          <div className='md:p-8 p-4'>
            <OneRealMortgageHeader contactRef={contactRef} />
            <div className='mt-5 flex flex-col gap-10 sm:gap-16'>
              <div className='-mr-4 md:-mr-8'>
                <OneRealMortgageLandingSection
                  onGetClientPreApproved={handleGetClientPreApproved}
                />
              </div>
              <OneRealMortgageGetQuote />
              <OneRealMortgagePreApproved
                onGetClientPreApproved={handleGetClientPreApproved}
              />
              <OneRealMortgageProductsAndOfferings />
              <OneRealMortgageJoinUs />
            </div>
          </div>
          <div className='md:pl-8 pl-4 md:py-8 py-4 '>
            <OneRealMortgageTestimonialsCarousal />
          </div>
          <div className='flex flex-col gap-10 sm:gap-16 md:p-8 p-4'>
            <img
              src={Bannerimage}
              alt='banner'
              className='hidden sm:block object-cover'
            />
            <div className='flex flex-col md:flex-row gap-8 sm:gap-12'>
              <OneRealMortgageContactUs contactRef={contactRef} />
              <div className='relative mx-auto'>
                <div className='md:-mb-4 flex items-center justify-end'>
                  <p className='text-primary-navy text-end font-semibold font-poppins'>
                    Where we operate
                  </p>
                </div>
                <img
                  src={MortgageMapImage}
                  alt='map'
                  className='w-full max-w-2xl'
                  data-testid='orm-map'
                />
              </div>
            </div>
          </div>
        </div>
        <AnalyticsEventOnLoad
          eventName={AnalyticsEventEnum.MC_WELCOME_SCREEN_VIEWED}
        />
      </PageLayout>
    </ZenRoute>
  );
};

export default OneRealMortgageRouteV2;
