import { DateTime } from 'luxon';
import React from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  MoneyValue,
  MoneyValueCurrencyEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { addConfirmedCommissionDeposit } from '../../../slices/TransactionSlice';
import { AppDispatch } from '../../../types';
import { MONEY_VALUE_VALIDATIONS } from '../../../utils/Validations';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenSidebarModalActionFooter from '../../Zen/Modal/ZenSidebarModalActionFooter';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import ZenControlledMoneyInput from '../TrustDeposits/ZenControlledMoneyInputV7';

interface AddConfirmedCommissionDepositProps {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
}

interface FormData {
  amount: MoneyValue;
  dateReceived: string;
}

const ZenAddConfirmedCommissionDeposit: React.FC<AddConfirmedCommissionDepositProps> = ({
  isOpen,
  onClose,
  transaction,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      amount: {
        currency: (transaction?.currency as unknown) as MoneyValueCurrencyEnum,
      },
      dateReceived: DateTime.local().toISODate(),
    },
  });

  const onSubmit = async (data: FormData) => {
    await dispatch(
      addConfirmedCommissionDeposit(transaction?.id!, {
        amount: data?.amount!,
        dateReceived: data?.dateReceived!,
      }),
    );
    onClose();
  };

  return (
    <ZenSidebarModal
      title='Add Confirmed Deposit'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <div className='m-4'>
            <div>
              <ZenControlledMoneyInput<FormData, 'amount'>
                control={control}
                label={`Amount (${transaction?.currency})`}
                shouldUnregister={false}
                name='amount'
                placeholder='Please enter the amount for deposits'
                currencyReadOnly
                rules={MONEY_VALUE_VALIDATIONS}
                isRequired
              />
            </div>
            <div className='mt-5'>
              <ZenControlledDatePickerInput<FormData, 'dateReceived'>
                name='dateReceived'
                shouldUnregister={false}
                control={control}
                label='Date Received'
                placeholder='Date amount was received'
                datePickerConfig={{
                  maxDate: DateTime.local().toJSDate(),
                }}
                rules={{
                  required: 'Please select a date',
                }}
                isRequired
              />
            </div>
          </div>
        </div>
        <ZenSidebarModalActionFooter
          submitButtonText='Add'
          cancelButtonText='Cancel'
          isSubmitting={isSubmitting}
          onClose={onClose}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenAddConfirmedCommissionDeposit;
