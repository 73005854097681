import { DateTime } from 'luxon';
import RealLogoImg from '../assets/img/RealLogoBlack.png';

export interface DocumentMastHeadProps {
  companyName: string;
  companyAddress: string;
  revision?: number;
  createdAt?: number;
  isPdf?: boolean;
}

const DocumentMastHead: React.FC<DocumentMastHeadProps> = ({
  companyName,
  companyAddress,
  createdAt,
  revision,
  isPdf = false,
}) => {
  return (
    <div>
      {revision && (
        <p className='text-center mb-2 print:text-xs print:absolute print:top-2 print:right-2 absolute top-2 right-5'>
          Revision {revision} |{' '}
          {isPdf
            ? DateTime.fromMillis(createdAt!)
                .setZone('America/New_York')
                .toFormat('MMM d, yyyy | hh:mma ZZZZ')
            : DateTime.fromMillis(createdAt!).toFormat('MMM d, yyyy - HH:mm ')}
        </p>
      )}
      <div className='flex flex-col items-center px-4 pb-1 flex-grow pt-4 print:-space-y-0.5'>
        <img src={RealLogoImg} className='h-7 print:h-5 mb-2' alt='Real Logo' />
        <p className='text-xl print:text-lg font-primary-medium text-center'>
          {companyName}
        </p>
        <p className='font-primary-medium text-center print:text-xs'>
          {companyAddress}
        </p>
      </div>
    </div>
  );
};

export default DocumentMastHead;
