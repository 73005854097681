import {
  CommissionPayerDisplayValueRoleEnum,
  PaymentParticipantValueRoleEnum,
} from '../../openapi/arrakis';
import {
  AddressCountryEnum,
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
  AgentResponseAccountCountryEnum,
  DirectoryEntryResponseRoleEnum,
  DirectoryPersonCreateRequest,
  DirectoryPersonCreateRequestRoleEnum,
  DirectoryPersonResponseRoleEnum,
  DirectoryVendorCreateRequestRoleEnum,
  DirectoryVendorResponseRoleEnum,
  NationalBusinessIdentification,
} from '../../openapi/yenta';
import { ISelectOption, PartialEnumMap } from '../../types';
import { safeEnumMapGet, safePartialEnumMapGet } from '../../utils/EnumHelper';
import { capitalizeEnum } from '../../utils/StringUtils';
import { getAddressFromAddressComponent } from '../../utils/TransactionHelper';
import { GooglePlaceLocationType } from '../ControlledGoogleAutocompleteSearchInputV7';
import { getNationalBusinessIds } from './utils';

export interface CreateDirectoryRequestFormData {
  firstName: string;
  lastName: string;
  company: string;
  emailAddress: string;
  phoneNumber: string;
  businessPhoneNumber: string;
  commissionDocumentRecipient: boolean;
  autoComplete: GooglePlaceLocationType;
  participantRole: ISelectOption;
  w9form?: File[];
  gstId: string;
  hstId: string;
  qstId: string;
  nationalIds: Array<NationalBusinessIdentification>;
  autoCompletebusinessAddress: GooglePlaceLocationType;
  businessStreet: string;
  businessStreet2: string;
  businessCountry: AddressRequestCountryEnum;
  businessManualAddressUnitNo: string;
  businessCity: string;
  businessState: AddressRequestStateOrProvinceEnum;
  businessZip: string;
  ein: string;
  bn: string;
  isrequired: boolean;
  country: string;
  street: string;
  street2: string;
  primaryEmailAddress: string;
  secondaryEmailAddress: string;
  manualAddressUnitNo: string;
  city: string;
  state: AddressRequestStateOrProvinceEnum;
  istoggle: boolean;
  zip: string;
}

export const getDirectoryVendorRoleLabel = (
  role?:
    | DirectoryVendorCreateRequestRoleEnum
    | DirectoryPersonCreateRequestRoleEnum
    | DirectoryEntryResponseRoleEnum
    | DirectoryPersonResponseRoleEnum
    | DirectoryVendorResponseRoleEnum,
) => {
  const convertingRolesforFrontend: Record<
    DirectoryVendorCreateRequestRoleEnum | DirectoryPersonCreateRequestRoleEnum,
    string
  > = {
    CLIENT: 'Client',
    TITLE_ESCROW: 'Title/Escrow',
    LENDER: 'Lender',
    LAWYER: 'Lawyer',
    TRUSTEE: 'Trustee',
    OTHER_AGENT: 'External Agent',
    OTHER: 'Other',
    /*Not used in the frontend*/
    LANDLORD: '',
    REFERRAL: '',
    TENANT: '',
    VENDOR: '',
  };
  if (!role) return;
  return safeEnumMapGet(convertingRolesforFrontend, role, capitalizeEnum(role));
};

export const getDirectoryVendorRolesByUserCountry = (
  country: AddressCountryEnum | AgentResponseAccountCountryEnum,
) => {
  const roles: ISelectOption[] = [];
  const isCanadianUser = country === AddressCountryEnum.Canada;

  Object.values(DirectoryVendorCreateRequestRoleEnum).forEach((role) => {
    if (
      (isCanadianUser &&
        role === DirectoryVendorCreateRequestRoleEnum.TitleEscrow) ||
      !getDirectoryVendorRoleLabel(role as DirectoryVendorCreateRequestRoleEnum)
    ) {
      return;
    }

    roles.push({
      value: role,
      label:
        getDirectoryVendorRoleLabel(
          role as DirectoryVendorCreateRequestRoleEnum,
        ) || '',
    });
  });

  return roles;
};

export const getPersonCreateData = (
  formData: CreateDirectoryRequestFormData,
  isManual: boolean,
) => {
  return {
    firstName: formData?.firstName,
    lastName: formData?.lastName,
    role: formData?.participantRole
      ?.value as DirectoryPersonCreateRequestRoleEnum,
    emailAddress: formData?.emailAddress,
    phoneNumber: formData?.phoneNumber,
    address: {
      type: !isManual ? 'auto' : 'manual',
      streetAddress1: !isManual
        ? getAddressFromAddressComponent(
            formData?.autoComplete?.address_components!,
          )?.streetAddress1
        : formData?.street,
      streetAddress2: !isManual
        ? getAddressFromAddressComponent(
            formData?.autoComplete?.address_components!,
          )?.streetAddress2
        : formData?.street2,
      city: !isManual
        ? getAddressFromAddressComponent(
            formData?.autoComplete?.address_components!,
          )?.city
        : formData?.city,
      stateOrProvince: !isManual
        ? getAddressFromAddressComponent(
            formData?.autoComplete?.address_components!,
          )?.stateOrProvince
        : ((formData?.state as unknown) as AddressRequestStateOrProvinceEnum),
      country: !isManual
        ? (getAddressFromAddressComponent(
            formData?.autoComplete?.address_components!,
          )?.country as AddressRequestCountryEnum)
        : ((formData?.country as unknown) as AddressRequestCountryEnum),
      zipOrPostalCode: !isManual
        ? getAddressFromAddressComponent(
            formData?.autoComplete?.address_components!,
          )?.zipOrPostalCode
        : formData?.zip,
    },
  };
};

export const getVendorCreateData = (
  formData: CreateDirectoryRequestFormData,
  isManual: boolean,
  personCreateData?: DirectoryPersonCreateRequest,
) => {
  const { gstId, hstId, qstId, ein, bn } = formData;
  return {
    name: formData?.company,
    role: formData?.participantRole
      ?.value as DirectoryVendorCreateRequestRoleEnum,
    emailAddress: formData?.primaryEmailAddress,
    secondaryEmailAddress: formData?.secondaryEmailAddress,
    phoneNumber: formData?.businessPhoneNumber,
    address: {
      type: !isManual ? 'auto' : 'manual',
      streetAddress1: !isManual
        ? getAddressFromAddressComponent(
            formData?.autoCompletebusinessAddress?.address_components!,
          )?.streetAddress1
        : formData?.businessStreet,
      streetAddress2: !isManual
        ? getAddressFromAddressComponent(
            formData?.autoCompletebusinessAddress?.address_components!,
          )?.streetAddress2
        : formData?.businessStreet2,
      city: !isManual
        ? getAddressFromAddressComponent(
            formData?.autoCompletebusinessAddress?.address_components!,
          )?.city
        : formData.businessCity,
      stateOrProvince: !isManual
        ? getAddressFromAddressComponent(
            formData?.autoCompletebusinessAddress?.address_components!,
          )?.stateOrProvince
        : formData.businessState,
      country: !isManual
        ? (getAddressFromAddressComponent(
            formData?.autoCompletebusinessAddress?.address_components!,
          )?.country as AddressRequestCountryEnum)
        : ((formData?.businessCountry as unknown) as AddressRequestCountryEnum),
      zipOrPostalCode: !isManual
        ? getAddressFromAddressComponent(
            formData?.autoCompletebusinessAddress?.address_components!,
          )?.zipOrPostalCode
        : formData?.businessZip,
    },
    personCreateRequests: personCreateData ? [personCreateData] : undefined,
    nationalBusinessIdentifications: getNationalBusinessIds({
      gstId,
      hstId,
      qstId,
      ein,
      bn,
    }),
  };
};

export const updatePersonRequest = (
  formData: CreateDirectoryRequestFormData,
  isManual: boolean = false,
) => {
  return {
    firstName: formData?.firstName,
    lastName: formData?.lastName,
    emailAddress: formData?.emailAddress,
    phoneNumber: formData?.phoneNumber,
    address: {
      type: !isManual ? 'auto' : 'manual',
      streetAddress1:
        !isManual || formData?.autoComplete?.place_id
          ? getAddressFromAddressComponent(
              formData?.autoComplete?.address_components!,
            )?.streetAddress1
          : formData?.street,
      streetAddress2:
        !isManual || formData?.autoComplete?.place_id
          ? getAddressFromAddressComponent(
              formData?.autoComplete?.address_components!,
            )?.streetAddress2
          : formData?.street2,
      city:
        !isManual || formData?.autoComplete?.place_id
          ? getAddressFromAddressComponent(
              formData?.autoComplete?.address_components!,
            )?.city
          : formData?.city,
      stateOrProvince:
        !isManual || formData?.autoComplete?.place_id
          ? getAddressFromAddressComponent(
              formData?.autoComplete?.address_components!,
            )?.stateOrProvince
          : ((formData?.state as unknown) as AddressRequestStateOrProvinceEnum),
      country:
        !isManual || formData?.autoComplete?.place_id
          ? (getAddressFromAddressComponent(
              formData?.autoComplete?.address_components!,
            )?.country as AddressRequestCountryEnum)
          : ((formData?.country as unknown) as AddressRequestCountryEnum),
      zipOrPostalCode:
        !isManual || formData?.autoComplete?.place_id
          ? getAddressFromAddressComponent(
              formData?.autoComplete?.address_components!,
            )?.zipOrPostalCode
          : formData?.zip,
    },
  };
};

export const updateVendorCreateData = (
  formData: CreateDirectoryRequestFormData,
  isManualBusinessAddress: boolean = false,
) => {
  const { gstId, hstId, qstId, ein, bn } = formData;
  return {
    name: formData?.company,
    emailAddress: formData?.primaryEmailAddress,
    secondaryEmailAddress: formData?.secondaryEmailAddress,
    phoneNumber: formData?.businessPhoneNumber,
    address: {
      type: !isManualBusinessAddress ? 'auto' : 'manual',
      streetAddress1:
        !isManualBusinessAddress ||
        formData?.autoCompletebusinessAddress.place_id
          ? getAddressFromAddressComponent(
              formData?.autoCompletebusinessAddress?.address_components!,
            )?.streetAddress1
          : formData?.businessStreet,
      streetAddress2:
        !isManualBusinessAddress ||
        formData?.autoCompletebusinessAddress.place_id
          ? getAddressFromAddressComponent(
              formData?.autoCompletebusinessAddress?.address_components!,
            )?.streetAddress2
          : formData?.businessStreet2,
      city:
        !isManualBusinessAddress ||
        formData?.autoCompletebusinessAddress.place_id
          ? getAddressFromAddressComponent(
              formData?.autoCompletebusinessAddress?.address_components!,
            )?.city
          : formData.businessCity,
      stateOrProvince:
        !isManualBusinessAddress ||
        formData?.autoCompletebusinessAddress.place_id
          ? getAddressFromAddressComponent(
              formData?.autoCompletebusinessAddress?.address_components!,
            )?.stateOrProvince
          : formData.businessState,
      country:
        !isManualBusinessAddress ||
        formData?.autoCompletebusinessAddress.place_id
          ? (getAddressFromAddressComponent(
              formData?.autoCompletebusinessAddress?.address_components!,
            )?.country as AddressRequestCountryEnum)
          : ((formData?.businessCountry as unknown) as AddressRequestCountryEnum),
      zipOrPostalCode:
        !isManualBusinessAddress ||
        formData?.autoCompletebusinessAddress.place_id
          ? getAddressFromAddressComponent(
              formData?.autoCompletebusinessAddress?.address_components!,
            )?.zipOrPostalCode
          : formData?.businessZip,
    },
    nationalBusinessIdentifications: getNationalBusinessIds({
      gstId,
      hstId,
      qstId,
      ein,
      bn,
    }),
  };
};

export const getDirectoryRoleFromParticipantRole = (
  participantRole:
    | PaymentParticipantValueRoleEnum
    | CommissionPayerDisplayValueRoleEnum,
): DirectoryEntryResponseRoleEnum | undefined => {
  const roleMap: PartialEnumMap<
    PaymentParticipantValueRoleEnum | CommissionPayerDisplayValueRoleEnum,
    DirectoryEntryResponseRoleEnum | undefined
  > = {
    ATTORNEY: DirectoryEntryResponseRoleEnum.Lawyer,
    BUYER: DirectoryEntryResponseRoleEnum.Client,
    BUYERS_LAWYER: DirectoryEntryResponseRoleEnum.Lawyer,
    ESCROW: DirectoryEntryResponseRoleEnum.TitleEscrow,
    EXTERNAL_AGENT: DirectoryEntryResponseRoleEnum.OtherAgent,
    LANDLORD: DirectoryEntryResponseRoleEnum.Client,
    LENDER: DirectoryEntryResponseRoleEnum.Lender,
    MANAGEMENT_COMPANY: DirectoryEntryResponseRoleEnum.OtherAgent,
    OTHER_AGENT: DirectoryEntryResponseRoleEnum.OtherAgent,
    OTHER: DirectoryEntryResponseRoleEnum.Other,
    REFERRING_AGENT: DirectoryEntryResponseRoleEnum.OtherAgent,
    SELLER: DirectoryEntryResponseRoleEnum.Client,
    SELLERS_LAWYER: DirectoryEntryResponseRoleEnum.Lawyer,
    TENANT: DirectoryEntryResponseRoleEnum.Client,
    TITLE: DirectoryEntryResponseRoleEnum.TitleEscrow,
    TRANSACTION_COORDINATOR: DirectoryEntryResponseRoleEnum.Other,
    TRUSTEE: DirectoryEntryResponseRoleEnum.Trustee,
  };

  return safePartialEnumMapGet(roleMap, participantRole, undefined);
};

export const companyVerify = 'Verify Company Address and Tax Information';

export const getDirectoryRoleFromParticipantRolee = (
  participantRole?: string,
): string | undefined => {
  const roleMap: PartialEnumMap<string, string> = {
    'Title/Escrow': DirectoryEntryResponseRoleEnum.TitleEscrow,
    Seller: DirectoryEntryResponseRoleEnum.Client,
    Buyer: DirectoryEntryResponseRoleEnum.Client,
    "Seller's Lawyer": DirectoryEntryResponseRoleEnum.Lawyer,
    "Buyer's Lawyer": DirectoryEntryResponseRoleEnum.Lawyer,
    Attorney: DirectoryEntryResponseRoleEnum.Lawyer,
    'Listing Brokerage': DirectoryEntryResponseRoleEnum.Other,
    OTHER_AGENT: DirectoryEntryResponseRoleEnum.OtherAgent,
    EXTERNAL_AGENT: DirectoryEntryResponseRoleEnum.OtherAgent,
    MANAGEMENT_COMPANY: DirectoryEntryResponseRoleEnum.OtherAgent,
  };

  return roleMap[participantRole!];
};

export const mapEntryRoleToCommonRole = (
  role: DirectoryEntryResponseRoleEnum | undefined,
):
  | DirectoryPersonResponseRoleEnum
  | DirectoryVendorResponseRoleEnum
  | undefined => {
  switch (role) {
    case DirectoryEntryResponseRoleEnum.Client:
      return DirectoryPersonResponseRoleEnum.Client;
    case DirectoryEntryResponseRoleEnum.Lawyer:
      return DirectoryPersonResponseRoleEnum.Lawyer;
    case DirectoryEntryResponseRoleEnum.Lender:
      return DirectoryPersonResponseRoleEnum.Lender;
    case DirectoryEntryResponseRoleEnum.TitleEscrow:
      return DirectoryPersonResponseRoleEnum.TitleEscrow;
    case DirectoryEntryResponseRoleEnum.Trustee:
      return DirectoryPersonResponseRoleEnum.Trustee;
    case DirectoryEntryResponseRoleEnum.Other:
      return DirectoryVendorResponseRoleEnum.Other;
    case DirectoryEntryResponseRoleEnum.OtherAgent:
      return DirectoryVendorResponseRoleEnum.OtherAgent;
    default:
      return undefined;
  }
};
