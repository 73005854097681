import { cn } from '../../utils/classUtils';

export type ZenTextIconVariant = 'small' | 'medium' | 'large';

export interface ZenIconTextProps {
  icon: React.ReactElement | undefined;
  text: React.ReactElement | string;
  variant?: ZenTextIconVariant;
  containerClassName?: string;
  textClassName?: string;
}

const GeminiIconText: React.FC<ZenIconTextProps> = ({
  icon,
  text,
  variant = 'medium',
  containerClassName,
  textClassName,
}) => {
  const variantMapClass: { [x in ZenTextIconVariant]: string } = {
    small: 'text-sm',
    medium: 'text-base',
    large: 'text-lg',
  };

  return (
    <div
      className={cn(
        'flex flex-row items-center flex-nowrap text-zen-dark-9',
        variantMapClass[variant],
        containerClassName,
      )}
    >
      <p className='mb-0.5'>{icon}</p>
      <p className={cn('pl-2 font-inter break-all', textClassName)}>{text}</p>
    </div>
  );
};

export default GeminiIconText;
