import { values } from 'lodash';
import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../../routes/AgentWebsiteOnboardingRoute';
import { CreateOrUpdateAgentWebsiteRequestCompetenciesEnum } from '../../../openapi/yenta';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ControlledTextAreaInputV7 from '../../ControlledTextAreaInputV7';
import ControlledCheckboxGroupV7 from '../../ControlledCheckboxGroupV7';
import { TEXT_CONTENT_VALIDATIONS } from '../../../utils/Validations';

const AgentWebsiteOnboardingAboutContent: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ form: { control } }) => {
  const worksWithOptions = values(
    CreateOrUpdateAgentWebsiteRequestCompetenciesEnum,
  ).map((service) => ({
    value: service,
    label: capitalizeEnum(service),
  }));

  return (
    <div className='p-4'>
      <div className='grid lg:grid-cols-2 lg:gap-20 grid-cols-1 gap-5'>
        <div>
          <ControlledCheckboxGroupV7<
            AgentWebsiteOnboardingFormData,
            'competencies'
          >
            label='Works with:'
            control={control}
            shouldUnregister={false}
            name='competencies'
            options={worksWithOptions}
          />
        </div>
        <div className='mt-3'>
          <ControlledTextAreaInputV7<
            AgentWebsiteOnboardingFormData,
            'serviceAreaZipcodes'
          >
            control={control}
            shouldUnregister={false}
            name='serviceAreaZipcodes'
            label='Service area ZIP codes'
            rows={2}
            rules={{ ...TEXT_CONTENT_VALIDATIONS }}
          />
        </div>
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingAboutContent;
