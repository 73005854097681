import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../routes/AgentWebsiteOnboardingRoute';
import Button from '../Button';
import AgentWebsiteImg from '../../assets/img/agent-website.svg';
import { AgentControllerApi } from '../../openapi/yenta';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import { saveUserDetail } from '../../slices/AuthSlice';
import ErrorService from '../../services/ErrorService';

const AgentWebsiteOnboardingWelcome: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ onNext }) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSkip = async () => {
    try {
      const { data } = await new AgentControllerApi(
        await getYentaConfiguration(),
      ).updateAgentNeedsWebsiteOnboarding(id, {
        needsWebsiteOnboarding: false,
      });
      if (!!data) {
        dispatch(saveUserDetail(data));
      }
      dispatch(showSuccessToast('Website onboarding skipped.'));
      history.push(`/people/${id}`);
    } catch (e) {
      ErrorService.notify('Unable to skip to welcome onboarding', e, {
        agent: { id },
      });
      dispatch(showErrorToast('We were unable to skip the onboarding'));
    }
  };

  return (
    <div className='h-full flex flex-col items-center max-w-md mx-auto'>
      <img src={AgentWebsiteImg} className='mt-10' alt='website' />
      <div className='font-primary-regular text-justify mt-5'>
        <p className='font-primary-medium text-2xl'>
          Real hosts your website for you!
        </p>
        <p className='mb-5 mt-2'>
          Stand out with a professional website. Make it yours in a few easy
          steps. Share contact info, testimonials, and listings. Review,
          publish, and you’re done!
        </p>
        <p className='mb-10'>Let’s make your Real website shine!</p>
      </div>
      <div className='my-1 w-full'>
        <Button label='Start' onClick={onNext} size='lg' fullWidth />
      </div>
      <div className='my-1 w-full'>
        <Button
          label='Skip'
          onClick={handleSkip}
          size='lg'
          fullWidth
          type='secondary'
        />
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingWelcome;
