import { values } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddressCountryEnum,
  AddressStateOrProvinceEnum,
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaResponseCountryEnum,
  CreateTaxFormCanada,
  CreateTaxFormTypeEnum,
  NationalIdentification,
  NationalIdentificationTypeEnum,
  NationalIdentificationValueTypeEnum,
  UpdateTaxFormCanada,
  UpdateTaxFormTypeEnum,
} from '../../openapi/yenta';
import {
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps,
} from '../../routes/MSDynamicsOnboardingRoute';
import {
  createTaxForm,
  updateTaxFormInfo,
} from '../../slices/TaxInformationSlice';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { AgentAddressTypeEnum, AppDispatch, RootState } from '../../types';
import { getIdValidations } from '../../utils/AgentHelper';
import { capitalizeEnum } from '../../utils/StringUtils';
import { getPostalCodeValidation } from '../../utils/Validations';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import ZenControlledDatePickerInput from '../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledHTMLSelectInput from '../Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledSignatureInput from '../Zen/Input/ZenControlledSignatureInput';
import ZenControlledStateOrProvinceInput from '../Zen/Input/ZenControlledStateOrProvince';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenButton from '../Zen/ZenButton';
import ZenControlledToggleInput from '../Zen/Input/ZenControlledToggleInput';
import { GST_ID_PROVINCES, HST_ID_PROVINCES } from '../../utils/TaxIDsUtils';
import withMSDynamicsProgress from './withMSDynamicsProgress';

const MSDynamicsCanadaTaxes: StepByStepComponent<
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps
> = ({ form: { control, setValue, trigger, watch }, onNext, onPrevious }) => {
  const dispatch = useDispatch<AppDispatch>();
  const taxFormID = watch('canadaTaxes.id');

  const [loading, setLoading] = useState(false);

  const { userDetail } = useSelector((state: RootState) => state.auth);

  const handleNext = async () => {
    const isValid = await trigger();
    if (!isValid) {
      dispatch(showErrorToast('There are fields which need to be filled out'));
      return;
    }
    setLoading(true);
    const formValues = watch().canadaTaxes;
    const nationalIds: NationalIdentification[] = [
      {
        type: NationalIdentificationTypeEnum.Sin,
        id: formValues.sinId,
      },
      {
        type: NationalIdentificationTypeEnum.Bn,
        id: formValues.taxpayerBusinessNumber,
      },
      {
        type: NationalIdentificationTypeEnum.GstId,
        id: formValues.gstId,
      },
      {
        type: NationalIdentificationTypeEnum.HstId,
        id: formValues.hstId,
      },
    ].filter((nationalId) => !!nationalId.id);
    let isSuccess: boolean;
    const taxpayerName = `${formValues.taxPayerFirstname} ${formValues.taxPayerSurname}`;
    const businessName = isIncorporated
      ? formValues.taxpayerBusinessName
      : taxpayerName;
    if (formValues.id) {
      const req: UpdateTaxFormCanada = {
        type: UpdateTaxFormTypeEnum.Canada,
        businessName,
        taxpayerName,
        nationalIds: nationalIds,
        residenceAddress: {
          type: AgentAddressTypeEnum.HOME,
          city: formValues.city,
          country: AddressCountryEnum.Canada,
          streetAddress1: formValues.addressLine1,
          streetAddress2: formValues.addressLine2,
          stateOrProvince: (formValues.provinceOrRegion as unknown) as AddressStateOrProvinceEnum,
          zipOrPostalCode: formValues.zipCode,
        },
      };
      isSuccess = await dispatch(
        updateTaxFormInfo(userDetail?.id!, formValues.id, req),
      );
    } else {
      const req: CreateTaxFormCanada = {
        type: CreateTaxFormTypeEnum.Canada,
        businessName,
        taxpayerName,
        nationalIds: nationalIds,
        residenceAddress: {
          type: AgentAddressTypeEnum.HOME,
          city: formValues.city,
          country: AddressCountryEnum.Canada,
          streetAddress1: formValues.addressLine1,
          streetAddress2: formValues.addressLine2,
          stateOrProvince: (formValues.provinceOrRegion as unknown) as AddressStateOrProvinceEnum,
          zipOrPostalCode: formValues.zipCode,
        },
        signedAt: DateTime.fromISO(formValues.signDate).toMillis(),
        typedSignature: formValues.signature,
      };
      isSuccess = await dispatch(createTaxForm(userDetail?.id!, req));
    }
    if (isSuccess) {
      onNext();
    }
    setLoading(false);
  };

  const activeLicenses = userDetail?.licenses?.filter(
    (license) => license.active,
  );
  const showHstId = activeLicenses?.some((license) =>
    HST_ID_PROVINCES.includes(
      license.administrativeArea?.stateOrProvince as any,
    ),
  );
  const showGstId = activeLicenses?.some(
    (license) =>
      !showHstId &&
      GST_ID_PROVINCES.includes(
        license.administrativeArea?.stateOrProvince as any,
      ),
  );
  const isIncorporated = watch('canadaTaxes.incorporated');

  return (
    <div className='w-full mx-auto max-w-2xl mt-10 relative'>
      <div>
        <p className='text-xl font-primary-medium mb-2'>Canada Taxes</p>
        <p className='font-zen-body text-base font-normal text-zen-dark-6 mb-4'>
          Add information about your taxpayer status that will display on your
          T4A
        </p>
      </div>
      <div className='flex flex-col md:flex-row flex-grow mx-auto md:justify-center'>
        <div className='order-2 md:order-1 flex-grow'>
          <div className='space-y-4 mb-5'>
            <ZenControlledTextInput<
              MSDynamicOnboardingFormData,
              'canadaTaxes.taxPayerFirstname'
            >
              control={control}
              label='Taxpayer First Name'
              name='canadaTaxes.taxPayerFirstname'
              placeholder='First name'
              rules={{
                required: 'Please enter a first name',
              }}
              isRequired
              shouldUnregister={false}
            />
            <ZenControlledTextInput<
              MSDynamicOnboardingFormData,
              'canadaTaxes.taxPayerSurname'
            >
              control={control}
              label='Taxpayer Surname'
              name='canadaTaxes.taxPayerSurname'
              placeholder='Surname'
              rules={{
                required: 'Please enter a surname',
              }}
              isRequired
              shouldUnregister={false}
            />
            <ZenControlledTextInput<
              MSDynamicOnboardingFormData,
              'canadaTaxes.sinId'
            >
              control={control}
              label='Social Insurance Number (SIN)'
              name='canadaTaxes.sinId'
              placeholder='123-123-123'
              shouldUnregister={false}
              rules={{
                required: 'Please enter a Social Insurance Number',
                ...getIdValidations(NationalIdentificationValueTypeEnum.Sin),
              }}
              isRequired
            />
            <ZenControlledTextInput<
              MSDynamicOnboardingFormData,
              'canadaTaxes.taxpayerBusinessNumber'
            >
              control={control}
              label='CRA Business Number (BN)'
              name='canadaTaxes.taxpayerBusinessNumber'
              placeholder='123456789'
              rules={{
                required: 'Please enter a business number',
                ...getIdValidations(NationalIdentificationTypeEnum.Bn),
              }}
              isRequired
              shouldUnregister={false}
            />
            <p className='text-zen-gray-10 text-sm'>
              If you don&apos;t already have a CRA Business Number (BN), visit
              CRA Business Registration Online at{' '}
              <a
                href='https://www.canada.ca/business-registration-online'
                className='text-primary-blue cursor-pointer'
                target='_blank'
                rel='noreferrer'
              >
                canada.ca/business-registration-online.
              </a>
            </p>
            <ZenControlledToggleInput<
              MSDynamicOnboardingFormData,
              'canadaTaxes.incorporated'
            >
              control={control}
              name='canadaTaxes.incorporated'
              label='Are you incorporated?'
              shouldUnregister={false}
            />
            {isIncorporated && (
              <ZenControlledTextInput<
                MSDynamicOnboardingFormData,
                'canadaTaxes.taxpayerBusinessName'
              >
                control={control}
                label='Corporation Name'
                name='canadaTaxes.taxpayerBusinessName'
                placeholder='Ex: Real Broker, LLC'
                rules={{
                  required: 'Please enter a corporation name',
                }}
                isRequired
                shouldUnregister={false}
              />
            )}
            {showHstId && (
              <ZenControlledTextInput<
                MSDynamicOnboardingFormData,
                'canadaTaxes.hstId'
              >
                control={control}
                label='HST Number'
                name='canadaTaxes.hstId'
                placeholder='123456789 RT0001'
                rules={{
                  ...getIdValidations(
                    NationalIdentificationValueTypeEnum.HstId,
                  ),
                }}
                isRequired={showHstId}
                shouldUnregister={false}
              />
            )}
            {showGstId && (
              <ZenControlledTextInput<
                MSDynamicOnboardingFormData,
                'canadaTaxes.gstId'
              >
                control={control}
                label='GST Number'
                name='canadaTaxes.gstId'
                placeholder='123456789 RT0001'
                rules={{
                  ...getIdValidations(
                    NationalIdentificationValueTypeEnum.GstId,
                  ),
                }}
                isRequired={showGstId}
                shouldUnregister={false}
              />
            )}
            <p className='text-zen-gray-10 text-sm'>
              We encourage all Real agents to register for a GST/HST number with
              the CRA. To obtain a GST/HST number, register with CRA and apply
              for a Business Number (BN). Unincorporated agents operating as a
              sole proprietor will obtain a BN for GST purposes, while
              incorporated agents operating through a corporation will have
              their BN assigned to a GST/HST account. Visit CRA Business
              Registration Online at{' '}
              <a
                href='https://www.canada.ca/business-registration-online'
                className='text-primary-blue cursor-pointer'
                target='_blank'
                rel='noreferrer'
              >
                canada.ca/business-registration-online.
              </a>
            </p>
            <ZenControlledTextInput<
              MSDynamicOnboardingFormData,
              'canadaTaxes.addressLine1'
            >
              control={control}
              label='Address Line 1'
              name='canadaTaxes.addressLine1'
              placeholder='Address Line 1'
              rules={{
                required: 'Please enter an address',
              }}
              isRequired
              shouldUnregister={false}
            />
            <ZenControlledTextInput<
              MSDynamicOnboardingFormData,
              'canadaTaxes.addressLine2'
            >
              control={control}
              label='Address Line 2'
              name='canadaTaxes.addressLine2'
              placeholder='Address Line 2'
              shouldUnregister={false}
            />
            <ZenControlledTextInput<
              MSDynamicOnboardingFormData,
              'canadaTaxes.city'
            >
              control={control}
              label='City'
              name='canadaTaxes.city'
              placeholder='City'
              shouldUnregister={false}
              rules={{ required: 'Please enter a city' }}
              isRequired
            />
            <ZenControlledStateOrProvinceInput<
              MSDynamicOnboardingFormData,
              'canadaTaxes.provinceOrRegion'
            >
              name='canadaTaxes.provinceOrRegion'
              control={control}
              shouldUnregister={false}
              setValue={setValue}
              disableChooseOption={false}
              selectedCountry={AdministrativeAreaRequestCountryEnum.Canada}
              rules={{ required: 'Please select a state or province' }}
              isRequired
            />
            <ZenControlledTextInput<
              MSDynamicOnboardingFormData,
              'canadaTaxes.zipCode'
            >
              control={control}
              label='Postal Code'
              name='canadaTaxes.zipCode'
              shouldUnregister={false}
              placeholder='E.g. T5H 3V9'
              rules={{
                required: 'Please enter a zip or postal code',
                ...getPostalCodeValidation(AddressCountryEnum.Canada),
              }}
              isRequired
            />
            <ZenControlledHTMLSelectInput<
              MSDynamicOnboardingFormData,
              'canadaTaxes.country'
            >
              name='canadaTaxes.country'
              control={control}
              label='Country'
              shouldUnregister={false}
              options={[
                {
                  label: 'Select Country',
                  value: '',
                },
                ...values(AdministrativeAreaResponseCountryEnum).map(
                  (country) => ({
                    value: country,
                    label: capitalizeEnum(country),
                  }),
                ),
              ]}
              rules={{ required: 'Please select a country' }}
              readOnly
              isRequired
            />
            <div className='space-y-4'>
              <div>
                <div className='space-y-1'>
                  <ZenControlledSignatureInput<
                    MSDynamicOnboardingFormData,
                    'canadaTaxes.signature'
                  >
                    control={control}
                    label='Your Signature'
                    placeholder='Type Full Name'
                    name='canadaTaxes.signature'
                    rules={{ required: 'Please enter a signature' }}
                    shouldUnregister={false}
                    isRequired
                    readOnly={!!taxFormID}
                  />
                  <p className='font-zen-body text-sm text-zen-dark-6'>
                    Typing your name acts as your signature and certifies that
                    you have capacity to sign for the person/entity identified
                    on this form
                  </p>
                </div>
              </div>
              <div>
                <div className='space-y-1'>
                  <ZenControlledDatePickerInput<
                    MSDynamicOnboardingFormData,
                    'canadaTaxes.signDate'
                  >
                    control={control}
                    name='canadaTaxes.signDate'
                    label='Sign Date'
                    placeholder='mm/dd/yyyy'
                    shouldUnregister={false}
                    isRequired
                    rules={{ required: 'Please pick a sign date' }}
                    readOnly={!!taxFormID}
                  />
                  <p className='font-zen-body text-sm text-zen-dark-6'>
                    Use MM/DD/YYYY format, e.g., 06/30/2024
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-sm'>
          <div className='flex flex-row items-center py-8 shadow-top-sm justify-center space-x-5'>
            <div className='w-28'>
              <ZenButton
                isFullWidth
                variant='primary-outline'
                type='button'
                label='Previous'
                onClick={onPrevious}
              />
            </div>
            <div className='w-56'>
              <ZenButton
                isFullWidth
                type='submit'
                variant='primary'
                label='Next'
                isSubmitting={loading}
                isDisabled={loading}
                onClick={handleNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withMSDynamicsProgress(MSDynamicsCanadaTaxes);
