import React, { PropsWithChildren } from 'react';
import { AsyncResponse, ErrorCode } from '../../types';
import DefaultUnauthorizedError from '../Errors/DefaultUnauthorizedError';
import DefaultForbiddenError from '../Errors/DefaultForbiddenError';
import { cn } from '../../utils/classUtils';

interface AuthorizationContainerProps<T> {
  asyncResponse: AsyncResponse<T> | undefined;
  parentClassNames?: string;
  UnauthorizedErrorComponent?: React.ReactElement;
  ForbiddenErrorComponent?: React.ReactElement;
}

const AuthorizationContainer = <T extends object>({
  asyncResponse,
  parentClassNames = '',
  UnauthorizedErrorComponent,
  ForbiddenErrorComponent,
  children,
}: PropsWithChildren<AuthorizationContainerProps<T>>) => {
  if (asyncResponse?.error === ErrorCode.UNAUTHORIZED) {
    return (
      UnauthorizedErrorComponent || (
        <div className={cn(parentClassNames, 'w-full', 'h-full')}>
          <DefaultUnauthorizedError sectionName={asyncResponse.name} />
        </div>
      )
    );
  }

  if (asyncResponse?.error === ErrorCode.FORBIDDEN) {
    return (
      ForbiddenErrorComponent || (
        <div className={cn(parentClassNames, 'w-full', 'h-full')}>
          <DefaultForbiddenError sectionName={asyncResponse.name} />
        </div>
      )
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthorizationContainer;
