import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import Awesome from '../../../assets/img/awesome.png';
import AwfulImg from '../../../assets/img/awful.png';
import Ehh from '../../../assets/img/ehh.png';
import ItsCool from '../../../assets/img/its_cool.png';
import NotSoGood from '../../../assets/img/not_so_good.png';
import {
  CreateSentimentRequestContextTypeEnum,
  CreateSentimentRequestSatisfactionRateEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { createNewSentimentForCurrentContext } from '../../../slices/TransactionSlice';
import { AppDispatch, RootState } from '../../../types';
import ControlledTextAreaInputV7 from '../../ControlledTextAreaInputV7';
import FeedbackModal from '../../FeedbackModal';
import ControlledSentimentFeedbackInput from './ControlledSentimentFeedbackInput';

interface HappyFeatureModalDetailProps {
  transaction: TransactionResponse;
}

interface FormData {
  satisfactionRate: CreateSentimentRequestSatisfactionRateEnum;
  feedbackMessage: string;
}

const HappyFeatureModal: React.FC<HappyFeatureModalDetailProps> = ({
  transaction,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const dispatch: AppDispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [openFeedbackModal, setOpenFeedbackModal] = useState<boolean>(true);
  if (!transaction) {
    return null;
  }

  const satisfactionRate = watch('satisfactionRate');

  const handleFeedback = (values: FormData) => {
    dispatch(
      createNewSentimentForCurrentContext(userDetail?.id!, {
        contextId: transaction.id!,
        contextType: CreateSentimentRequestContextTypeEnum.Transaction,
        satisfactionRate: values.satisfactionRate,
        feedback: values.feedbackMessage,
        contextState: transaction.lifecycleState?.state,
      }),
    );
    setOpenFeedbackModal(false);
  };

  return (
    <FeedbackModal
      isOpen={openFeedbackModal}
      title='Please help us improve your experience'
    >
      <form
        className='flex flex-col justify-between items-center min-h-full space-y-10 mt-8'
        onSubmit={handleSubmit(handleFeedback)}
      >
        <ControlledSentimentFeedbackInput<FormData, 'satisfactionRate'>
          label='1. How would you rate your overall experience with Real   during this transaction?'
          options={[
            {
              icon: <img src={AwfulImg} className='h-10 w-10' alt='Awful!' />,
              label: 'Awful!',
              value: CreateSentimentRequestSatisfactionRateEnum.Terrible,
            },
            {
              icon: (
                <img src={NotSoGood} className='h-10 w-10' alt='Not so good' />
              ),
              label: 'Not so good',
              value: CreateSentimentRequestSatisfactionRateEnum.Bad,
            },
            {
              icon: <img src={Ehh} className='h-10 w-10' alt='Ehhh..' />,
              label: 'Ehhh..',
              value: CreateSentimentRequestSatisfactionRateEnum.Neutral,
            },
            {
              icon: <img src={ItsCool} className='h-10 w-10' alt='It’s Cool' />,
              label: 'It’s Cool',
              value: CreateSentimentRequestSatisfactionRateEnum.Good,
            },
            {
              icon: <img src={Awesome} className='h-10 w-10' alt='Awesome!' />,
              label: 'Awesome!',
              value: CreateSentimentRequestSatisfactionRateEnum.Excellent,
            },
          ]}
          shouldUnregister={false}
          control={control}
          name='satisfactionRate'
          rules={{
            required: 'Please choose an emoji to describe your experience!',
          }}
        />
        <ControlledTextAreaInputV7<FormData, 'feedbackMessage'>
          control={control}
          name='feedbackMessage'
          label='2. What’s your top concern or complaint? (optional)'
          rows={5}
          placeholder='Please provide us with any specific details on how we can improve.'
          shouldUnregister={false}
          className='font-primary-light'
        />
        <div className='flex flex-col justify-center items-center space-y-3'>
          <button
            type='submit'
            className='px-5 pb-1  text-white rounded-md pt-2 bg-dark'
          >
            Complete
          </button>
          {!satisfactionRate && (
            <p className='text-error'>{errors.satisfactionRate?.message}</p>
          )}
        </div>
      </form>
    </FeedbackModal>
  );
};

export default HappyFeatureModal;
