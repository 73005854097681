import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark,
  faPen,
} from '@fortawesome/pro-solid-svg-icons';
import { faRotateRight } from '@fortawesome/pro-regular-svg-icons';
import { isNull } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CellProps, Column } from 'react-table';
import ZenEditOutgoingPaymentFormSidebarModal from '../../../../forms/ZenEditOutgoingPaymentFormSidebarModal';
import ZenOutgoingPaymentSidebarModal from '../../../../forms/ZenOutgoingPaymentSidebarModal';
import {
  FundsReleaseResponseFromBankAccountTypeEnum,
  MoneyValue,
  OutgoingPaymentResponse,
  OutgoingPaymentResponsePaymentSystemEnum,
  OutgoingPaymentResponseStatusEnum,
  OutgoingPaymentsControllerApi,
  OutgoingPaymentWithBankInfoResponse,
  OutgoingPaymentWithBankInfoResponseStatusEnum,
} from '../../../../openapi/arrakis';
import ErrorService from '../../../../services/ErrorService';
import { showApiErrorModal } from '../../../../slices/ErrorSlice';
import {
  showErrorToast,
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../../../../slices/ToastNotificationSlice';
import { fetchTransactionOutgoingPayments } from '../../../../slices/TransactionSlice';
import { displayAmount } from '../../../../utils/CurrencyUtils';
import { getFormattedDateISOString } from '../../../../utils/DateUtils';
import { getArrakisConfiguration } from '../../../../utils/OpenapiConfigurationUtils';
import { isPaymentInErrorState } from '../../../../utils/PaymentsHelper';
import AdminOnly from '../../../auth/AdminOnly';
import IconButton from '../../../IconButton';
import ZenOutgoingReleasePaymentEarlyModal from '../../../outgoingPayment/ZenOutgoingReleasePaymentEarlyModal';
import NullableTextCell from '../../../table/Cells/NullableTextCell';
import ZenFundsReleaseBankAccountTypeCell from '../../../table/Cells/ZenFundsReleaseBankAccountTypeCell';
import ZenOutgoingPaymentStatusCell from '../../../table/Cells/ZenOutgoingPaymentStatusCell';
import ZenOutgoingPaymentSystemCell from '../../../table/Cells/ZenOutgoingPaymentSystemCell';
import ZenViewActionButtonCell from '../../../table/Cells/ZenViewActionButtonCell';
import DateColumnFilter from '../../../table/Filters/DateColumnFilter';
import NumberColumnFilter from '../../../table/Filters/NumberColumnFilter';
import OutgoingPaymentPaymentSystemSelectColumnFilter from '../../../table/Filters/OutgoingPaymentPaymentSystemSelectColumnFilter';
import OutgoingPaymentStatusSelectColumnFilter from '../../../table/Filters/OutgoingPaymentStatusSelectColumnFilter';
import TextColumnFilter from '../../../table/Filters/TextColumnFilter';
import ZenFixedDataResourceIndexContainer from '../../../Zen/Containers/ZenFixedDataResourceIndexContainer';
import ZenControlledDatePickerInput from '../../../Zen/Input/ZenControlledDatePickerInput';
import ZenConfirmationModal from '../../../Zen/Modal/ZenConfirmationModal';
import ZenActionButtonCell from '../../../Zen/Table/Cell/ZenActionButtonCell';
import ZenDateCell from '../../../Zen/Table/Cell/ZenDateCell';
import ZenMilliDateCell from '../../../Zen/Table/Cell/ZenMilliDateCell';
import { RootState } from '../../../../types';

interface ZenTransactionOutgoingPaymentsTableProps {
  transactionId: string;
  transactionOutgoingPayments: OutgoingPaymentWithBankInfoResponse[];
}

const ZenTransactionOutgoingPaymentsTable: React.FC<ZenTransactionOutgoingPaymentsTableProps> = ({
  transactionId,
  transactionOutgoingPayments,
}) => {
  const dispatch = useDispatch();
  const { isAdmin } = useSelector((state: RootState) => state.auth);
  const [
    editingOutgoingPayment,
    setEditingOutgoingPayment,
  ] = useState<OutgoingPaymentWithBankInfoResponse | null>(null);
  const [
    retryingOutgoingPayment,
    setRetryingOutgoingPayment,
  ] = useState<OutgoingPaymentWithBankInfoResponse | null>(null);
  const [retrying, setRetrying] = useState<boolean>(false);
  const [
    currentOutgoingPayment,
    setCurrentOutgoingPayment,
  ] = useState<OutgoingPaymentResponse | null>(null);
  const [
    approvingOutgoingPayment,
    setApprovingOutgoingPayment,
  ] = useState<OutgoingPaymentWithBankInfoResponse>();

  const getOutgoingPaymentById = useCallback(
    async (id: string) => {
      try {
        const { data } = await new OutgoingPaymentsControllerApi(
          getArrakisConfiguration(),
        ).getOutgoingPayment(id);
        setCurrentOutgoingPayment(data);
      } catch (e) {
        dispatch(
          showErrorToastForErrorCode(
            'We were unable to fetch the outgoing payment details',
            ErrorService.getErrorCode(e),
          ),
        );
      }
    },
    [dispatch],
  );

  const retryOutgoingPayment = async () => {
    setRetrying(true);
    try {
      const { data } = await new OutgoingPaymentsControllerApi(
        getArrakisConfiguration(),
      ).reScheduleOutGoingPayment(retryingOutgoingPayment?.id!);
      dispatch(
        showSuccessToast(
          `Successfully requested for payment to ${data.firstName} ${data.lastName}`,
        ),
      );
      setEditingOutgoingPayment(null);
      setRetryingOutgoingPayment(null);
      dispatch(fetchTransactionOutgoingPayments(transactionId));
    } catch (e) {
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to retry the outgoing payment',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setRetrying(false);
    }
  };

  const handleConfirmOutgoingPayments = async (
    payments: OutgoingPaymentWithBankInfoResponse[],
    formData?: OutgoingPaymentWithBankInfoResponse,
  ) => {
    const paymentIds = payments?.map((payment) => payment?.id!);

    try {
      await new OutgoingPaymentsControllerApi(
        getArrakisConfiguration(),
      ).confirmPayments({
        outgoingPaymentIds: paymentIds,
        actualPaidOn: formData?.actualPaidOn!,
      });
      await dispatch(fetchTransactionOutgoingPayments(transactionId));
      dispatch(
        showSuccessToast('Outgoing Payments are confirmed successfully.'),
      );
    } catch (e) {
      ErrorService.notify('Unable to bulk confirm outgoing payments', e, {
        paymentIds,
      });
      dispatch(showApiErrorModal(e));
      dispatch(showErrorToast('Unable to confirm outgoing payments.'));
    }
  };

  const columns: Array<Column<OutgoingPaymentWithBankInfoResponse>> = useMemo(
    () => [
      {
        Header: 'Actions',
        accessor: 'id',
        id: 'action',
        Cell: ({ row: { original } }) => (
          <div className='flex space-x-3 flex-nowrap'>
            <ZenViewActionButtonCell
              onClick={() => getOutgoingPaymentById(original.id!)}
            />
            <AdminOnly>
              <ZenActionButtonCell
                leftIcon={
                  <FontAwesomeIcon
                    icon={faPen}
                    className='text-white mx-1'
                    title='Edit'
                    data-testid='Edit'
                  />
                }
                onClick={() => setEditingOutgoingPayment(original)}
              />
              {isPaymentInErrorState(original.status!) && (
                <ZenActionButtonCell
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faRotateRight}
                      className='mx-0.5'
                      size='lg'
                      title='Retry'
                    />
                  }
                  onClick={() => setRetryingOutgoingPayment(original)}
                />
              )}
              {original.status ===
                OutgoingPaymentWithBankInfoResponseStatusEnum.Initiated && (
                <ZenActionButtonCell
                  label='Release Payment Early'
                  onClick={() => setApprovingOutgoingPayment(original)}
                />
              )}
              {original.actualPaidOn && (
                <IconButton
                  leftIcon={
                    <FontAwesomeIcon
                      title='Confirm'
                      icon={faCircleCheck}
                      className='text-white mx-0.5'
                    />
                  }
                  buttonStyle='bg-green-600 border border-green-600 ring-green-600 rounded-lg'
                  toolTipText={`Paid on ${getFormattedDateISOString(
                    original.actualPaidOn,
                  )}`}
                  toolTipConfig={{ placement: 'top' }}
                />
              )}
            </AdminOnly>
            {original.status ===
              OutgoingPaymentWithBankInfoResponseStatusEnum.InvalidPaymentDetails && (
              <IconButton
                leftIcon={
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    title='Invalid Payments'
                    className='text-white mx-0.5'
                  />
                }
                buttonStyle='bg-zen-danger border border-zen-danger ring-zen-danger rounded-lg'
                toolTipText='Invalid Payment Details'
                toolTipConfig={{ placement: 'top' }}
              />
            )}
          </div>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: (d) => `${d.payeeFirstName} ${d.payeeLastName}`,
        Cell: ({
          row: { original },
        }: CellProps<OutgoingPaymentWithBankInfoResponse, string>) => (
          <NullableTextCell
            text={`${original.payeeFirstName} ${original.payeeLastName}`}
          />
        ),
        Filter: TextColumnFilter,
      },
      {
        Header: 'Company',
        accessor: 'payeeCompany',
        Cell: ({ value }) => <NullableTextCell text={value} />,
        Filter: TextColumnFilter,
      },
      {
        Header: 'Payment System',
        accessor: 'paymentSystem',
        Cell: ({ value }) => (
          <ZenOutgoingPaymentSystemCell
            type={
              (value as unknown) as OutgoingPaymentResponsePaymentSystemEnum
            }
          />
        ),
        Filter: OutgoingPaymentPaymentSystemSelectColumnFilter,
        filter: 'multiSelectFilter',
      },
      {
        Header: 'Payment System Id',
        accessor: 'paymentSystemId',
        Cell: ({ value }) => <NullableTextCell text={value} />,
        Filter: TextColumnFilter,
      },
      {
        Header: 'Amount Paid',
        accessor: (d) => d['amount']?.amount,
        Cell: ({
          row: { original },
        }: CellProps<OutgoingPaymentWithBankInfoResponse, MoneyValue>) =>
          displayAmount(original.amount),
        Filter: NumberColumnFilter,
        filter: 'numberFilter',
      },
      {
        Header: 'Confirmation Code',
        accessor: 'confirmationCode',
        Cell: ({ value }) => <NullableTextCell text={value} />,
        Filter: TextColumnFilter,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <ZenOutgoingPaymentStatusCell
            type={(value as unknown) as OutgoingPaymentResponseStatusEnum}
          />
        ),
        Filter: OutgoingPaymentStatusSelectColumnFilter,
        filter: 'multiSelectFilter',
      },
      {
        Header: 'Paid At',
        accessor: 'paidAt',
        Cell: ({ value }) => <ZenMilliDateCell date={value} />,
        Filter: DateColumnFilter,
        filter: 'dateFilter',
      },
      {
        Header: 'Paid On',
        accessor: 'actualPaidOn',
        Cell: ({ value }) => <ZenDateCell date={value} />,
        Filter: DateColumnFilter,
      },
      {
        Header: 'Bank Account Name',
        accessor: 'fromBankAccountName',
        Cell: ({ value }) => <NullableTextCell text={value} />,
        Filter: TextColumnFilter,
      },
      {
        Header: 'Bank Account Type',
        accessor: 'fromBankAccountType',
        Filter: TextColumnFilter,
        Cell: ({ value }) =>
          value ? (
            <ZenFundsReleaseBankAccountTypeCell
              type={
                (value as unknown) as FundsReleaseResponseFromBankAccountTypeEnum
              }
            />
          ) : (
            'N/A'
          ),
      },
    ],
    [getOutgoingPaymentById],
  );

  return (
    <div className='pb-24'>
      <ZenFixedDataResourceIndexContainer<OutgoingPaymentWithBankInfoResponse>
        columns={columns}
        resourceName='Outgoing Payment'
        data={transactionOutgoingPayments}
        hidePagination
        standalone={false}
        initialSort={{ paidAt: 'desc' }}
        allowSelection={isAdmin}
        selectionOptions={[
          {
            label: 'Confirm Selected Outgoing Payments',
            confirm: (payments) => ({
              title: 'Confirm Outgoing Payments',
              description: `Are you sure you would like to confirm these ${payments.length} outgoing payments? This action cannot be undone.`,
              modalType: 'success',
              primaryActionTitle: 'Confirm',
              childJSX: (control) => (
                <div className='my-2'>
                  <ZenControlledDatePickerInput<
                    OutgoingPaymentWithBankInfoResponse,
                    'actualPaidOn'
                  >
                    datePickerConfig={{
                      maxDate: DateTime.local().toJSDate(),
                    }}
                    control={control}
                    name='actualPaidOn'
                    label='Actual Paid On Date'
                    placeholder='MM/DD/YYYY'
                    rules={{
                      required: 'Please select a date',
                    }}
                    isRequired
                  />
                </div>
              ),
            }),
            onAction: handleConfirmOutgoingPayments,
            isActionButtonEnabled: (payments) => {
              return payments.every((payment) => !payment?.actualPaidOn);
            },
          },
        ]}
      />
      {!isNull(editingOutgoingPayment) && (
        <ZenEditOutgoingPaymentFormSidebarModal
          isOpen
          outgoingPayment={editingOutgoingPayment!}
          onClose={() => {
            dispatch(fetchTransactionOutgoingPayments(transactionId));
            setEditingOutgoingPayment(null);
          }}
        />
      )}
      <ZenOutgoingPaymentSidebarModal
        isOpen={!!currentOutgoingPayment}
        onClose={async () => {
          await dispatch(fetchTransactionOutgoingPayments(transactionId));
          setCurrentOutgoingPayment(null);
        }}
        outgoingPayment={currentOutgoingPayment!}
      />
      <ZenConfirmationModal
        isOpen={!!retryingOutgoingPayment}
        onClose={() => setRetryingOutgoingPayment(null)}
        title='Retry Payment'
        subtitle={`Are you sure you want to retry payment of ${displayAmount(
          retryingOutgoingPayment?.amount,
        )} to ${retryingOutgoingPayment?.payeeFirstName} ${
          retryingOutgoingPayment?.payeeLastName
        }`}
        onConfirm={retryOutgoingPayment}
        cancelButtonText='Cancel'
        confirmButtonText='Retry Payment'
        isDisabled={retrying}
        variant='danger'
      />
      {!!approvingOutgoingPayment && (
        <ZenOutgoingReleasePaymentEarlyModal
          isOpen={!!approvingOutgoingPayment}
          transactionId={transactionId}
          approvingOutgoingPaymentId={approvingOutgoingPayment?.id!}
          onClose={() => setApprovingOutgoingPayment(undefined)}
        />
      )}
    </div>
  );
};

export default ZenTransactionOutgoingPaymentsTable;
