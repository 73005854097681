import { createSlice } from '@reduxjs/toolkit';
import { DemoModeSliceState } from '../types';

const DEMO_MODE_STORAGE_KEY = 'demoMode';

export const initialState: DemoModeSliceState = {
  enabled: window.localStorage.getItem(DEMO_MODE_STORAGE_KEY) === 'true',
};

const DemoModeSlice = createSlice({
  name: 'demoMode',
  initialState,
  reducers: {
    clearDemoMode: (state) => {
      state.enabled = false;
      try {
        window.localStorage.removeItem(DEMO_MODE_STORAGE_KEY);
      } catch (e) {
        // ignore
      }
    },
    toggleDemoMode: (state) => {
      try {
        state.enabled = !state.enabled;
        window.localStorage.setItem(DEMO_MODE_STORAGE_KEY, `${state.enabled}`);
      } catch (e) {
        // ignore
      }
    },
  },
});

export const { clearDemoMode, toggleDemoMode } = DemoModeSlice.actions;

export const DemoModeReducer = DemoModeSlice.reducer;
