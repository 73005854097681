import classNames from 'classnames';

interface WealthManagementCardV2Props {
  title?: string | React.ReactNode;
  onClickText?: string;
  onClick?: () => void;
  noContainer?: boolean;
  className?: React.HTMLAttributes<HTMLDivElement> | string;
}
const WealthManagementCardV2: React.FC<WealthManagementCardV2Props> = ({
  title,
  onClickText,
  onClick,
  className,
  noContainer = false,
  children,
}) => (
  <div
    className={classNames(
      !noContainer && 'border border-zen-dark-4 rounded-xl p-6 bg-white',
      className,
    )}
  >
    {typeof title === 'string' ? (
      <div className='flex items-center space-x-2'>
        <span className='font-inter text-lg leading-6 text-zen-dark-9'>
          {title}
        </span>
        {!!onClickText && (
          <>
            <span className='h-1 w-1 bg-zen-dark-4 shrink-0 rounded-full' />
            <span
              className='font-zen-body text-base font-semibold text-primary-blue cursor-pointer'
              onClick={onClick}
            >
              {onClickText}
            </span>
          </>
        )}
      </div>
    ) : (
      title
    )}
    <div className='mt-4'>{children}</div>
  </div>
);

export default WealthManagementCardV2;
