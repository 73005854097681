import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../types';
import { clearDemoMode } from '../slices/DemoModeSlice';

interface ZenMainMenuDemoModeBadgeProps {
  isCollapsed: boolean;
}

export const ZenMainMenuDemoModeBadge: React.FC<ZenMainMenuDemoModeBadgeProps> = ({
  isCollapsed,
}) => {
  const { enabled } = useSelector((state: RootState) => state.demoMode);
  const dispatch = useDispatch();

  if (!enabled) {
    return null;
  }

  const handleClearDemoMode = () => {
    dispatch(clearDemoMode());
  };

  return (
    <div
      className={`rounded-full ${
        isCollapsed ? 'px-2' : 'px-5'
      } text-center py-1 bg-zen-warning-light flex justify-between items-center mb-4`}
    >
      <h6 className='font-zen-body text-base font-semibold text-zen-warning'>
        {isCollapsed ? 'Demo' : 'Demo Mode'}
      </h6>
      {!isCollapsed && (
        <FontAwesomeIcon
          title='Exit Demo Mode'
          icon={regular('circle-xmark')}
          className='text-zen-warning cursor-pointer'
          onClick={handleClearDemoMode}
        />
      )}
    </div>
  );
};
