import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createRef } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form-v7';
import { UseControllerProps } from 'react-hook-form-v7/dist/types/controller';
import { useDispatch } from 'react-redux';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { isImageValid } from '../utils/AgentHelper';
import { isValidUserAvatarFile } from '../utils/FileUtils';
import AgentProfileImagePreview from './AgentProfileImagePreview';
import FormErrorMessage from './FormErrorMessage';

interface ControlledProfileImageUploadProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  personName: string;
}

const ControlledProfileImageUpload = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  personName,
  shouldUnregister = true,
  ...rest
}: ControlledProfileImageUploadProps<TFieldValues, TName>) => {
  const ref = createRef<HTMLInputElement>();
  const dispatch = useDispatch();

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({ field: { name, onChange, value }, fieldState: { error } }) => (
        <div className='space-y-1'>
          {!!label && <label htmlFor={name}>{label}</label>}
          <div className='flex flex-row items-end'>
            <div>
              <div className='rounded-full border-4 border-amber-500'>
                <AgentProfileImagePreview
                  size='xl'
                  name={personName}
                  imageUrl={
                    typeof value === 'object'
                      ? URL.createObjectURL(value)
                      : value
                  }
                />
              </div>
            </div>
            <div
              onClick={() => ref.current?.click()}
              className='flex-row items-center text-primary cursor-pointer space-x-1'
            >
              <FontAwesomeIcon icon={faPen} className='mr-0.5' />
              <span className='font-primary-regular text-base'>
                Update photo
              </span>
            </div>
            <input
              type='file'
              className='hidden'
              ref={ref}
              aria-label='uploadAvatar'
              onChange={(e) => {
                if (e.target?.files && e.target?.files?.[0]) {
                  if (isValidUserAvatarFile(e.target?.files?.[0]?.name)) {
                    if (isImageValid(e)) {
                      onChange(e.target!.files![0]);
                    } else {
                      dispatch(
                        showErrorToast(
                          'File size exceeds maximum limit of 10 MB.',
                        ),
                      );
                    }
                  } else {
                    dispatch(
                      showErrorToast(
                        'File type not supported. Upload jpg/jpeg, png, gif, bmp file only',
                      ),
                    );
                  }
                  e.target.value = '';
                }
              }}
            />
          </div>
          {error?.message && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledProfileImageUpload;
