import { find, isMatch } from 'lodash';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTheme,
} from 'victory';
import {
  DEFAULT_MEDIUM_FONT,
  DEFAULT_REGULAR_FONT,
} from '../../utils/fonts.constants';
import { compactNumber } from '../../utils/MathUtils';

export interface BarChartProps<D> {
  blurYAxis?: boolean;
  data: Array<D>;
  highlightedData?: Array<D>;
  inactiveTintColor?: string;
  activeTintColor?: string;
  xKey: keyof D;
  yKey: keyof D;
  xShowAlternate?: number;
  yShowAlternate?: number;
  horizontal?: boolean;
}

const BarChart = <D extends object>({
  blurYAxis = false,
  data,
  highlightedData = [],
  xKey,
  yKey,
  inactiveTintColor = '#D2D5DB',
  activeTintColor = '#000',
  xShowAlternate = 1,
  yShowAlternate = 1,
  horizontal = false,
}: BarChartProps<D>) => {
  const shouldHighlight = (index: number | string) =>
    !!find(highlightedData, (highlight) =>
      isMatch(data[parseInt(`${index}`, 10)], highlight),
    );
  const formatYAxisTick = (
    tick: number,
    index: number,
    ticks: Array<number>,
  ) => {
    const oldTick =
      index !== 0
        ? `$${compactNumber(parseFloat(ticks[index - 1].toFixed(2)))}`
        : undefined;
    const newTick = `$${compactNumber(parseFloat(tick.toFixed(2)))}`;
    return index % yShowAlternate === 0 && (index === 0 || oldTick !== newTick)
      ? newTick
      : '';
  };

  return (
    <div className='flex-1 bg-white overflow-hidden'>
      <div className='-mt-8 mb-2 -mr-8'>
        <VictoryChart theme={VictoryTheme.grayscale} domainPadding={{ x: 30 }}>
          <defs>
            <filter id='blur-filter' x='0' y='0'>
              <feGaussianBlur in='SourceGraphic' stdDeviation='5' />
            </filter>
          </defs>
          <VictoryBar
            data={data}
            x={String(xKey)}
            y={String(yKey)}
            horizontal={horizontal}
            barRatio={0.8}
            cornerRadius={{
              topLeft: 2,
              topRight: 2,
            }}
            style={{
              data: {
                fill: ({ index }) =>
                  shouldHighlight(index) ? activeTintColor : inactiveTintColor,
              },
            }}
          />
          <VictoryAxis
            tickLabelComponent={
              <VictoryLabel
                style={{
                  fill: ({ index }: any) =>
                    shouldHighlight(index) ? '#3E3F3C' : '#AFADAC',
                  fontFamily: ({ index }) =>
                    shouldHighlight(index)
                      ? DEFAULT_MEDIUM_FONT
                      : DEFAULT_REGULAR_FONT,
                  fontSize: '11px',
                }}
                textAnchor='end'
                verticalAnchor='middle'
                angle={-90}
                dx={8}
              />
            }
            tickFormat={(tick: string, index: number) =>
              index % xShowAlternate === 0 ? tick : ''
            }
            style={{
              axis: { stroke: '#D2D5DB' },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={formatYAxisTick}
            style={{
              axis: { stroke: 'none' },
              tickLabels: {
                fill: '#D2D5DB',
                fontFamily: DEFAULT_REGULAR_FONT,
                ...(blurYAxis && { filter: 'url(#blur-filter)' }),
              },
            }}
          />
        </VictoryChart>
      </div>
    </div>
  );
};

export default BarChart;
