import { useForm } from 'react-hook-form-v7';
import GeminiSearchBar from '../../components/Gemini/GeminiSearchBar';
import GeminiControlledSelectInput from '../../components/Gemini/input/GeminiControlledSelectInput';
import ZenControlledGoogleAutocompleteSearchInput, {
  GooglePlaceLocationType,
} from '../../components/Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import { ISelectOption } from '../../types';
import { cn } from '../../utils/classUtils';

interface ReferralFilterSearchByProps {
  onChange?: (str: string) => void;
  hideFilter?: boolean;
}

interface FilterForm {
  Options: ISelectOption;
  location: GooglePlaceLocationType;
}

const ReferralFilterSearchBy: React.FC<ReferralFilterSearchByProps> = ({
  onChange,
  hideFilter = false,
}) => {
  const { watch, control } = useForm<FilterForm>({}); // Add type for form data

  const options = watch('Options');

  const isLocationSelected = options?.value === 'Location';

  return (
    <div className='grid grid-cols-10 gap-4 items-center'>
      <div className={cn(!hideFilter ? 'col-span-8' : 'col-span-full')}>
        {!isLocationSelected ? (
          <GeminiSearchBar
            value=''
            onChange={onChange!}
            placeholder='Search Agents by Name, City...'
          />
        ) : (
          <ZenControlledGoogleAutocompleteSearchInput<FilterForm, 'location'>
            name='location'
            control={control}
            placeholder='Search Agents by State, City...'
          />
        )}
      </div>
      {!hideFilter && (
        <div className='flex items-center col-span-2'>
          <p className='text-regent-600 text-base font-inter w-24 font-normal'>
            Filter by:
          </p>
          <GeminiControlledSelectInput<FilterForm, 'Options'>
            options={[
              { label: 'Location', value: 'Location' },
              { label: 'Agent', value: 'Agent' },
            ]}
            name='Options'
            control={control}
          />
        </div>
      )}
    </div>
  );
};

export default ReferralFilterSearchBy;
