import { DateTime } from 'luxon';
import React from 'react';
import { FaPencilAlt, FaRegCalendarAlt, FaRegClock } from 'react-icons/fa';
import { CalendarEventResponse } from '../../../openapi/yenta';
import { getCurrentUserTimeZone } from '../../../utils/DateUtils';
import { getEventTemplateBasedOnTitle } from '../../../utils/EventHelper';
import { getCalendarImageUrl } from '../../../utils/ImgUtils';
import ZenStatusLabelWithIcon from '../ZenStatusLabelWithIcon';
import ZenRealWhiteLogo from '../../../assets/img/zen/zen-real-white-logo.png';
import AdminOnly from '../../auth/AdminOnly';

export interface ZenEventCardProps {
  event: CalendarEventResponse;
  onOpen: (event: CalendarEventResponse) => void;
  onEdit?: (event: CalendarEventResponse) => void;
}

const ZenEventCard: React.FC<ZenEventCardProps> = ({
  event,
  onOpen,
  onEdit,
}) => {
  return (
    <div
      onClick={() => onOpen(event)}
      className='cursor-pointer'
      title='event-card'
    >
      <div className='bg-white rounded-3xl h-full relative border border-r-2 border-b-2'>
        <div className='relative h-52'>
          {!!event.imageUrl ? (
            <img
              src={getCalendarImageUrl(event.imageUrl)}
              className='h-full w-full bg-center bg-cover bg-no-repeat rounded-t-3xl'
              alt='event background'
            />
          ) : (
            <div
              className='h-full rounded-t-3xl bg-center bg-cover bg-no-repeat'
              style={{
                backgroundImage: `url(${getEventTemplateBasedOnTitle(
                  event.title!,
                )})`,
              }}
            >
              <div className='px-6 py-8'>
                <img src={ZenRealWhiteLogo} className='w-18' alt='real logo' />
                <p className='font-zen-body text-lg font-bold text-white mt-10'>
                  {event.title}
                </p>
              </div>
            </div>
          )}
          <AdminOnly>
            {onEdit && (
              <button
                type='button'
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(event);
                }}
                className='absolute top-5 right-5 bg-gray-200 bg-opacity-50 p-3 rounded-xl'
              >
                <FaPencilAlt className='text-white' size={14} title='edit' />
              </button>
            )}
          </AdminOnly>
        </div>
        <div className='p-4'>
          <div className='flex items-center justify-between space-x-5'>
            <div className='flex space-x-2'>
              <div className='flex space-x-1'>
                <FaRegCalendarAlt
                  className='text-zen-dark-12 mt-0.5'
                  fontSize={14}
                />
                <span className='font-zen-body text-zen-dark-12 text-sm font-normal'>
                  {DateTime.fromMillis(event.startAt!).toFormat('LLL dd, yyyy')}
                </span>
              </div>
              <div className='flex space-x-1'>
                <FaRegClock className='text-zen-dark-12 mt-0.5' fontSize={14} />
                <span className='font-zen-body text-zen-dark-12 text-sm font-normal'>
                  {DateTime.fromMillis(event.startAt!).toFormat('hh:mm a')} -{' '}
                  {DateTime.fromMillis(event.endAt!).toFormat('hh:mm a')}{' '}
                  {getCurrentUserTimeZone(event?.startAt)}
                </span>
              </div>
            </div>
          </div>
          <div className='mt-3 space-y-2 mb-14'>
            <p className='font-zen-body text-zen-dark-9 text-base font-semibold'>
              {event.title}
            </p>
            <div className='font-zen-body text-sm font-light text-gray-500 line-clamp-3 loading-4 h-16 overflow-hidden'>
              {event.description ? (
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: event.description }}
                />
              ) : (
                <div>No description available</div>
              )}
            </div>
          </div>
          <div className='absolute bottom-5 w-full'>
            <div className='flex items-center'>
              <ZenStatusLabelWithIcon text={event.calendarName!} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenEventCard;
