import React from 'react';
import ZenButton, { ZenButtonType, ZenButtonVariant } from './ZenButton';

interface ZenStickyBottomPaginationProps {
  previousButtonText?: string;
  nextButtonText?: string;
  isSubmitting?: boolean;
  isNextButtonDisabled?: boolean;
  onPrevious(): void;
  onNext(): void;
  previousButtonVariant?: ZenButtonVariant;
  nextButtonVarint?: ZenButtonVariant;
  nextButtonType?: ZenButtonType;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
}

const ZenStickyBottomPagination: React.FC<ZenStickyBottomPaginationProps> = ({
  previousButtonText = 'Previous',
  nextButtonText = 'Next',
  onPrevious,
  onNext,
  isNextButtonDisabled = false,
  isSubmitting = false,
  previousButtonVariant = 'primary-outline',
  nextButtonVarint = 'primary',
  nextButtonType = 'button',
  maxWidth = '2xl',
}) => {
  return (
    <div className='sticky w-full bottom-0 z-0 bg-white'>
      <div className={`w-full mx-auto max-w-${maxWidth}`}>
        <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
          <ZenButton
            isFullWidth
            variant={previousButtonVariant}
            label={previousButtonText}
            onClick={onPrevious}
          />
          <ZenButton
            isFullWidth
            variant={nextButtonVarint}
            isSubmitting={isSubmitting}
            isDisabled={isNextButtonDisabled}
            label={nextButtonText}
            type={nextButtonType}
            onClick={onNext}
          />
        </div>
      </div>
    </div>
  );
};

export default ZenStickyBottomPagination;
