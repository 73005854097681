import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { DEFAULT_SHOWN_ITEMS_COUNT } from '../../../../constants/LeoConstants';
import AnalyticsService from '../../../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../../../types';
import { CourseItem } from '../../../../utils/LeoUtils';
import LeoResponseItemCard from './LeoResponseItemCard';

interface CoursesLinksProps {
  courses: CourseItem[];
  isShowingHistory: boolean;
}

export const CoursesLinks: React.FC<CoursesLinksProps> = ({
  courses,
  isShowingHistory,
}) => {
  const [shownCoursesLength, setShownCoursesLength] = React.useState<number>(
    DEFAULT_SHOWN_ITEMS_COUNT,
  );

  const isShowingMoreCourses = useMemo(
    () => shownCoursesLength > DEFAULT_SHOWN_ITEMS_COUNT,
    [shownCoursesLength],
  );

  const isShowingAllCoursesByDefault = useMemo(
    () => courses.length <= DEFAULT_SHOWN_ITEMS_COUNT,
    [courses.length],
  );

  useEffect(() => {
    setShownCoursesLength(
      courses.length >= DEFAULT_SHOWN_ITEMS_COUNT
        ? DEFAULT_SHOWN_ITEMS_COUNT
        : courses.length,
    );
  }, [courses.length]);

  if (!courses.length) {
    return null;
  }

  return (
    <div className='w-full'>
      <p className='text-white font-zen-body-medium text-base my-2 mx-1'>
        You can check out these courses to learn more.
      </p>
      <div
        className={classNames(
          'w-full grid gap-3 sm:grid-cols-1',
          isShowingHistory ? 'lg:grid-cols-2' : 'lg:grid-cols-3 md:grid-cols-2',
        )}
      >
        {courses.slice(0, shownCoursesLength).map((course) => (
          <LeoResponseItemCard
            key={course.id}
            {...course}
            onSelect={() => {
              if (!course.url) {
                return;
              }
              window.open(course.url, '_blank');
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.LEO_MESSAGE_HISTORY_CLICK,
                {
                  link: course.url,
                },
              );
            }}
            msgContextType='courses'
          />
        ))}
      </div>
      <div className='flex flex-row justify-between mt-3'>
        <div className='flex flex-row items-center px-1'>
          <p className='text-white font-zen-body-medium text-sm'>
            {`${shownCoursesLength} of ${courses.length}`}
          </p>
        </div>
        <button
          type='button'
          className={classNames(
            'flex flex-row items-center',
            isShowingAllCoursesByDefault ? 'opacity-50' : 'opacity-100',
          )}
          onClick={() =>
            setShownCoursesLength(
              isShowingMoreCourses ? DEFAULT_SHOWN_ITEMS_COUNT : courses.length,
            )
          }
          disabled={isShowingAllCoursesByDefault}
        >
          {!isShowingAllCoursesByDefault && (
            <FontAwesomeIcon
              className='text-[#80CDFF] mr-1.5 mb-0.5'
              icon={isShowingMoreCourses ? faAngleUp : faAngleDown}
              size='sm'
            />
          )}
          <p className='text-white font-zen-body-medium text-[#80CDFF] text-sm'>
            {isShowingAllCoursesByDefault
              ? 'Showing All'
              : isShowingMoreCourses
              ? 'Show Less'
              : 'Show More'}
          </p>
        </button>
      </div>
    </div>
  );
};
