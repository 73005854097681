import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownToLine } from '@fortawesome/pro-solid-svg-icons';
import { ReactComponent as CheckDeposit } from '../assets/img/deposit_check.svg';
import DefaultLoader from '../components/DefaultLoader';
import ZenViewCheckDepositStatusCell from '../components/table/Cells/ZenViewCheckDepositStatusCell';
import ZenViewCheckDepositSidebarForm from '../components/transactions/CheckDeposit/ZenViewCheckDepositSidebarForm';
import ZenButton from '../components/Zen/ZenButton';
import ZenFixedDataTable from '../components/Zen/ZenFixedDataTable';
import {
  CheckDepositDto,
  CheckDepositDtoTypeEnum,
  MoneyValue,
} from '../openapi/arrakis';
import { getCheckDepositsList } from '../slices/CheckDepositsSlice';
import { RootState } from '../types';
import { isShowDepositEnabled } from '../utils/CheckDepositsHelper';
import { displayAmount } from '../utils/CurrencyUtils';
import {
  encodeTransactionAccountNumber,
  isFTNISettlementIdAvailable,
} from '../utils/TransactionHelper';

type Match = {
  id: string;
};

const ZenTransactionViewCheckDepositRoute: React.FC = () => {
  const history = useHistory();
  const { id: transactionId } = useParams() as Match;
  const {
    auth: { isAdmin },
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
    },
    office: { officeDetailById },
    checkDeposits: { checkDepositsList, checkDepositsListLoading },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [checkDeposit, setCheckDeposit] = useState<CheckDepositDto>();
  const officeDetails = officeDetailById[transactionDetail?.office?.id!];
  const isFTNICheckDepositShow =
    isShowDepositEnabled(transactionDetail?.lifecycleState?.state!) &&
    isFTNISettlementIdAvailable(officeDetails) &&
    !checkDepositsListLoading;

  useEffect(() => {
    dispatch(getCheckDepositsList(transactionId));
  }, [dispatch, transactionId, transactionDetail]);

  const ViewDepositColumn: Array<Column<CheckDepositDto>> = [
    {
      Header: 'Confirmation ID',
      accessor: 'confirmationNumber',
      Cell: ({ value }) => <p>{value || 'N/A'}</p>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) =>
        value ? <ZenViewCheckDepositStatusCell status={value} /> : 'N/A',
    },
    {
      Header: 'Submitted Date',
      accessor: 'createdAt',
      Cell: ({ value }) => (
        <p>{DateTime.fromMillis(value!).toFormat('L/d/y')}</p>
      ),
    },
    {
      Header: `Amount (${transactionDetail?.currency})`,
      accessor: 'submittedAmount',
      Cell: ({ value }) => (
        <p>
          {displayAmount(value as MoneyValue, {
            hideCurrency: true,
          })}
        </p>
      ),
    },
    {
      Header: 'Check No.',
      accessor: 'checkNumber',
      Cell: ({ value }) => <p>{value}</p>,
    },
    {
      Header: 'Account No.',
      accessor: 'accountNumber',
      Cell: ({ value }) => <p>{encodeTransactionAccountNumber(value!)}</p>,
      disableSortBy: true,
    },
    {
      Header: 'Routing No.',
      accessor: 'routingNumber',
      Cell: ({ value }) => <p>{value}</p>,
      disableSortBy: true,
    },
  ];

  if (isAdmin) {
    ViewDepositColumn.push({
      Header: 'Bank',
      accessor: 'type',
      Cell: ({ value }) => (
        <p>
          {value === CheckDepositDtoTypeEnum.Trust
            ? officeDetails?.trustAccount?.bankName
            : officeDetails?.bankAccount?.bankName}
        </p>
      ),
      disableSortBy: true,
    });
  }

  const columnWithAction: Array<Column<CheckDepositDto>> = [
    ...ViewDepositColumn,
    {
      Header: 'Front & Back Images',
      accessor: 'frontImagePath',
      id: 'front-back',
      disableSortBy: true,
      Cell: ({ row: { original } }) => (
        <ZenButton
          onClick={() => setCheckDeposit(original)}
          label='Download'
          LeftIconComponent={
            <FontAwesomeIcon
              icon={faDownToLine}
              data-testid='View'
              className='text-primary-blue mr-0.5'
              size='xs'
            />
          }
          variant='primary-link'
        />
      ),
    },
  ];

  if (checkDepositsListLoading && !checkDepositsList) {
    return <DefaultLoader />;
  }

  return (
    <div className='w-full px-4 py-6'>
      <ZenFixedDataTable<CheckDepositDto>
        columns={columnWithAction}
        data={checkDepositsList! || []}
        resourceName='View Check Deposit'
        header='Deposit History'
        RightComponent={
          isFTNICheckDepositShow ? (
            <ZenButton
              label='Deposit a New Check'
              LeftIconComponent={<CheckDeposit />}
              onClick={() =>
                history.push(`/transactions/${transactionId}/deposit-check`)
              }
            />
          ) : undefined
        }
        hidePagination
        hideFilters
      />
      {!!checkDeposit && !!transactionDetail && (
        <ZenViewCheckDepositSidebarForm
          checkDepositResponse={checkDeposit}
          isOpen={!!checkDeposit}
          onClose={() => setCheckDeposit(undefined)}
          transaction={transactionDetail}
        />
      )}
    </div>
  );
};

export default ZenTransactionViewCheckDepositRoute;
