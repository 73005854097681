import React from 'react';
import ZenPopover from '../../../ZenPopover';

interface TeamPostCapFeePopoverProps {}

const TeamPostCapFeePopover: React.FC<TeamPostCapFeePopoverProps> = () => {
  return (
    <div className='font-inter text-sm text-zen-dark-9'>
      Team Post Cap Fee <span className='text-error'>*</span>
      <ZenPopover
        title='Team Post Cap Fee'
        description={
          <div className='text-zen-dark-9 text-base font-light'>
            <p>
              The administrator can optionally configure the team to
              automatically apply a flat post-cap fee (dollar amount). In that
              case, the Teammate will see a Team Post Cap fee deduction on each
              transaction after the Team Cap is reached. If the Team Cap is not
              specified, this fee will never be applied.
            </p>
          </div>
        }
      />
    </div>
  );
};

export default TeamPostCapFeePopover;
