import { CoverPhotoSet, onBoardingStepType } from '../types';
import {
  AgentWebsiteResponsePhotoSetEnum,
  CreateOrUpdateAgentWebsiteRequestPhotoSetEnum,
  CreateOrUpdateAgentWebsiteRequestThemeColorEnum,
} from '../openapi/yenta';

export const AGENT_COVER_IMAGE_SET_BASE_URL =
  'https://bolt-custom-assets.therealbrokerage.com/agent-website/images/themes';

export const COVER_PHOTO_PATH = 'social_card';

export const agentCoverSetMap: Record<
  | CreateOrUpdateAgentWebsiteRequestPhotoSetEnum
  | AgentWebsiteResponsePhotoSetEnum,
  CoverPhotoSet
> = {
  CABIN: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/country2_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/country2_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/country2_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/country2_2x.jpg`,
  },
  LUXURY: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/beach1_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/beach1_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/beach1_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/beach1_2x.jpg`,
  },
  URBAN: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/urban1_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/urban1_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/urban1_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/urban1_2x.jpg`,
  },
  COUNTRY: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/country1_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/country1_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/country1_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/country1_2x.jpg`,
  },
  PHOTO_SET_5: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/interiors2_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/interiors2_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/interiors2_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/interiors2_2x.jpg`,
  },
  PHOTO_SET_6: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/urban2_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/urban2_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/urban2_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/urban2_2x.jpg`,
  },
  PHOTO_SET_7: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/suburban1_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/suburban1_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/suburban1_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/suburban1_2x.jpg`,
  },
  PHOTO_SET_8: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/suburban2_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/suburban2_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/suburban2_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/suburban2_2x.jpg`,
  },
  PHOTO_SET_9: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/beach2_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/beach2_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/beach2_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/beach2_2x.jpg`,
  },
  PHOTO_SET_10: {
    small: `${AGENT_COVER_IMAGE_SET_BASE_URL}/interiors1_1x.jpg`,
    medium: `${AGENT_COVER_IMAGE_SET_BASE_URL}/interiors1_2x.jpg`,
    large: `${AGENT_COVER_IMAGE_SET_BASE_URL}/interiors1_3x.jpg`,
    cloudinaryFragment: `${COVER_PHOTO_PATH}/interiors1_2x.jpg`,
  },
};

export const AGENT_ONBOARDING_STEPS: onBoardingStepType[] = [
  { name: 'Theme' },
  { name: 'Site Info' },
  { name: 'Testimonials' },
  { name: 'Listings' },
  { name: 'Review & Publish' },
];

export const agentWebsiteThemeColors: Record<
  CreateOrUpdateAgentWebsiteRequestThemeColorEnum,
  string
> = {
  GREEN: '#02CC7D',
  BLACK: '#3E3F3C',
  BLUE: '#05C3F9',
  RED: '#F84C6C',
  YELLOW: '#FABE05',
  MAGENTA: '#9A40BD',
};
