import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import { Control, UseFormWatch } from 'react-hook-form-v7';
import { FlexTeamConfigDtoCountryEnum } from '../../../../openapi/yenta';
import ErrorService from '../../../../services/ErrorService';
import {
  AsyncSelectOptionReactElement,
  FlexTeamRoles,
} from '../../../../types';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import { searchForAgents } from '../../../../utils/TableUtils';
import { NUMBER_VALIDATIONS } from '../../../../utils/Validations';
import ZenControlledAsyncSelectInput from '../../Input/ZenControlledAsyncSelectInput';
import ZenControlledSelectInput from '../../Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../../Input/ZenControlledToggleInput';
import ZenSidebarModalActionFooterV2 from '../../Modal/ZenSidebarModalActionFooterV2';
import ZenButton from '../../ZenButton';
import RealBEOPFeePopover from './ProTeamPopover/RealBEOPFeePopover';
import RealBrokerageFeePopover from './ProTeamPopover/RealBrokerageFeePopover';
import RealPostCapTransactionFeePopover from './ProTeamPopover/RealPostCapTransactionFeePopover';
import RealSplitFeePopover from './ProTeamPopover/RealSplitFeePopover';
import { ProTeamMember, TeamMemberDto } from './ZenEditProTeamMember';

interface ZenAddProAdminTeamMemberFormProps {
  onClose(): void;
  control: Control<ProTeamMember, object>;
  isSubmitting: boolean;
  isEdit?: boolean;
  onSubmit: () => any;
  buttonText: string;
  watch: UseFormWatch<ProTeamMember>;
  teammate?: TeamMemberDto;
  setMemberToDelete?(teammate: TeamMemberDto): void;
  readOnly?: boolean;
  removeMember?: boolean;
  country?: FlexTeamConfigDtoCountryEnum;
}

const ZenAddProAdminTeamMemberForm: React.FC<ZenAddProAdminTeamMemberFormProps> = ({
  onClose,
  buttonText,
  control,
  isSubmitting,
  onSubmit,
  watch,
  setMemberToDelete,
  teammate,
  isEdit,
  readOnly,
  removeMember = false,
  country,
}) => {
  const adminSplit = watch('adminSplit');

  return (
    <div>
      <div className='p-5 max-h-full overflow-y-auto'>
        {!isEdit && (
          <div className='mb-8'>
            <ZenControlledAsyncSelectInput<ProTeamMember, 'agentId'>
              control={control}
              name='agentId'
              placeholder='Search Agent by Email or Name'
              label='Search Agent'
              shouldUnregister={false}
              fetchData={async (search, page) => {
                try {
                  const { data } = await searchForAgents({
                    page,
                    search,
                    filterBy: {
                      country: [country!],
                      agentStatus: ['ACTIVE'],
                    },
                  });

                  const options: AsyncSelectOptionReactElement[] = data.map(
                    (resp) => ({
                      value: `${resp.id}`,
                      label: `${resp.firstName} ${resp.lastName} - ${resp.emailAddress}`,
                    }),
                  );

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search request registered agents in add team member form',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                      },
                    },
                  );
                }

                return [];
              }}
              isRequired
              rules={{
                required: 'Please select a team member',
              }}
              startAdornment={
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className='text-primary-blue mx-3'
                  size='sm'
                />
              }
              endAdornment={
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className='text-zen-dark mx-5'
                  size='sm'
                />
              }
            />
          </div>
        )}
        <div className='my-8'>
          <ZenControlledSelectInput<ProTeamMember, 'roles'>
            name='roles'
            placeholder='Role'
            control={control}
            shouldUnregister={false}
            isRequired
            readOnly={readOnly || isEdit}
            options={[
              ...values(FlexTeamRoles).map((role) => ({
                value: role,
                label: capitalizeEnum(role),
              })),
            ]}
            label='Select Team Member Role'
            rules={{
              required: 'Please select a role',
            }}
          />
        </div>
        <div className='my-8'>
          <div className='border border-zen-dark-4 rounded-lg'>
            <div className='p-3 border-zen-dark-4'>
              <ZenControlledToggleInput<ProTeamMember, 'adminSplit'>
                name='adminSplit'
                control={control}
                shouldUnregister={false}
                readOnly={readOnly}
                label='Does this Admin pay a Leader Split?'
              />
            </div>
          </div>
        </div>
        {/* //TODO: remove this entire block */}
        {adminSplit && (
          <div className='mt-5 pb-20'>
            <div className='font-normal font-primary-medium text-lg text-zen-dark-9'>
              Individual Fee
            </div>
            <div className='h-px bg-zen-dark-5 mt-2' />
            <div className='mt-5 font-inter text-sm text-zen-dark-7'>
              Here you can select default splits for leader and agents in each
              category individually.
            </div>
            <div className='space-y-2'>
              <div className='flex flex-row justify-between items-center mt-5'>
                <RealSplitFeePopover />
                <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                  <ZenControlledTextInput<ProTeamMember, 'adminRealSplit'>
                    label='Leader Pays'
                    control={control}
                    type='text'
                    placeholder='Real Split'
                    startAdornment={
                      <div className='flex w-full h-full items-center justify-center pl-2'>
                        <FontAwesomeIcon
                          className='text-primary-blue'
                          icon={solid('percentage')}
                        />
                      </div>
                    }
                    rules={{
                      ...NUMBER_VALIDATIONS,
                      min: {
                        value: 0,
                        message: 'Number cannot be less than 0',
                      },
                      max: {
                        value: 100,
                        message: 'Number cannot be greater than 100',
                      },
                    }}
                    readOnly={readOnly}
                    name='adminRealSplit'
                    shouldUnregister={false}
                  />
                </div>
              </div>
              <div className='flex flex-row justify-between items-center'>
                <RealBEOPFeePopover />
                <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                  <ZenControlledTextInput<ProTeamMember, 'adminBEOPFee'>
                    control={control}
                    type='text'
                    placeholder='Real BEOP Fee'
                    startAdornment={
                      <div className='flex w-full h-full items-center justify-center pl-2'>
                        <FontAwesomeIcon
                          className='text-primary-blue'
                          icon={solid('percentage')}
                        />
                      </div>
                    }
                    rules={{
                      ...NUMBER_VALIDATIONS,
                      min: {
                        value: 0,
                        message: 'Number cannot be less than 0',
                      },
                      max: {
                        value: 100,
                        message: 'Number cannot be greater than 100',
                      },
                    }}
                    readOnly={readOnly}
                    name='adminBEOPFee'
                    shouldUnregister={false}
                  />
                </div>
              </div>
              <div className='flex flex-row justify-between items-center'>
                <RealBrokerageFeePopover />
                <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                  <ZenControlledTextInput<ProTeamMember, 'adminRealFee'>
                    control={control}
                    type='text'
                    rules={{
                      ...NUMBER_VALIDATIONS,
                      min: {
                        value: 0,
                        message: 'Number cannot be less than 0',
                      },
                      max: {
                        value: 100,
                        message: 'Number cannot be greater than 100',
                      },
                    }}
                    name='adminRealFee'
                    startAdornment={
                      <div className='flex w-full h-full items-center justify-center pl-2'>
                        <FontAwesomeIcon
                          className='text-primary-blue'
                          icon={solid('percentage')}
                        />
                      </div>
                    }
                    readOnly={readOnly}
                    placeholder='Real Brokerage Fee'
                    shouldUnregister={false}
                  />
                </div>
              </div>
              <div className='flex flex-row justify-between items-center'>
                <RealPostCapTransactionFeePopover />
                <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                  <ZenControlledTextInput<ProTeamMember, 'adminTransactionFee'>
                    control={control}
                    type='text'
                    rules={{
                      ...NUMBER_VALIDATIONS,
                      min: {
                        value: 0,
                        message: 'Number cannot be less than 0',
                      },
                      max: {
                        value: 100,
                        message: 'Number cannot be greater than 100',
                      },
                    }}
                    readOnly={readOnly}
                    startAdornment={
                      <div className='flex w-full h-full items-center justify-center pl-2'>
                        <FontAwesomeIcon
                          className='text-primary-blue'
                          icon={solid('percentage')}
                        />
                      </div>
                    }
                    name='adminTransactionFee'
                    placeholder='Real Post Cap Transaction Fee'
                    shouldUnregister={false}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {isEdit && setMemberToDelete && teammate && removeMember && (
          <ZenButton
            variant='danger-link'
            LeftIconComponent={<FontAwesomeIcon icon={regular('trash-can')} />}
            label='Remove Member'
            onClick={() => setMemberToDelete(teammate!)}
          />
        )}
      </div>
      <ZenSidebarModalActionFooterV2
        onClose={onClose}
        isSubmitting={isSubmitting}
        onClick={onSubmit}
        isDisabled={isSubmitting || readOnly}
        submitButtonText={buttonText}
      />
    </div>
  );
};

export default ZenAddProAdminTeamMemberForm;
