import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import WalletBookPaymentComponent from '../components/Wallet/WalletBookPaymentComponent';
import WalletCreditComponent from '../components/Wallet/WalletCreditComponent';
import ZenButton from '../components/Zen/ZenButton';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenResourceContainer from '../components/Zen/ZenResourceContainer';
import { AccountDtoTypeEnum } from '../openapi/wallet';
import {
  useCreateMfaToken,
  useCreateSensitiveToken,
  useWallet,
} from '../query/wallet/useWallet';
import { findAccountWithBankingPartner } from '../utils/WalletUtils';

interface Params {
  accountId: string;
}

export const scopes = [
  'customers',
  'accounts',
  'payments',
  'ach-payments-write',
  'counterparties',
  'cards',
  'cards-write',
  'cards-sensitive',
  'cards-sensitive-write',
  'transactions',
  'authorizations',
  'check-deposits',
  'statements',
];

interface WalletUnitRouteProps {}

const WalletUnitComponentsRoute: React.FC<WalletUnitRouteProps> = () => {
  const { accountId } = useParams<Params>();
  const [showCreditModal, setShowCreditModal] = useState<boolean>(false);
  const [showBookPaymentModal, setShowBookPaymentModal] = useState<boolean>(
    false,
  );

  const {
    data: wallet,
    isLoading: walletLoading,
    errorCode: walletError,
  } = useWallet();

  const { account, partner } = findAccountWithBankingPartner(
    accountId,
    wallet?.bankingPartnerships,
  );

  const {
    data: mfa,
    isLoading: mfaLoading,
    errorCode: mfaError,
  } = useCreateMfaToken(partner?.id);

  const {
    data: unitToken,
    isLoading: tokenLoading,
    errorCode: tokenError,
  } = useCreateSensitiveToken({
    bankingPartnershipId: partner?.id!,
    scopes: scopes,
    mfa: { token: mfa?.mfaToken!, code: '000001' },
  });

  const isLoading = walletLoading || mfaLoading || tokenLoading;
  const errorCode = walletError || mfaError || tokenError;

  return (
    <ZenPageLayout
      path={[
        { title: 'Home', url: '/' },
        { title: 'Wallet', url: '/wallet' },
        { title: `Unit's white label components`, url: '/wallet/unit' },
      ]}
    >
      <ZenResourceContainer
        loading={isLoading}
        isEmpty={!unitToken?.token}
        resourceName='wallet component'
        errorCode={errorCode}
      >
        <div className='h-full w-full flex flex-col items-center'>
          {/* Card Component */}
          {account?.cards?.[0]?.externalId && (
            <div className='p-3 my-4'>
              <span>Card Component</span>
              {/* @ts-ignore */}
              <unit-elements-card
                card-id={account.cards[0].externalId}
                customer-token={unitToken?.token}
                learn-more-url='www.google.com'
              />
            </div>
          )}

          {/* Book Payment Component */}
          <div className='p-3 my-4'>
            <ZenButton
              label='Book Payment Component'
              onClick={() => setShowBookPaymentModal(true)}
            />
          </div>
          <WalletBookPaymentComponent
            isOpen={showBookPaymentModal}
            accountId={account?.externalId}
            customerToken={unitToken?.token}
            onClose={() => setShowBookPaymentModal(false)}
          />

          {/* ACH Credit Component */}
          <div className='p-3 my-4'>
            <ZenButton
              label='ACH Credit Component'
              onClick={() => setShowCreditModal(true)}
            />
          </div>
          <WalletCreditComponent
            isOpen={showCreditModal}
            accountId={account?.externalId}
            customerToken={unitToken?.token}
            onClose={() => setShowCreditModal(false)}
          />

          {/* Activity Component */}
          <div className='h-[500px] w-4/5 p-3 my-4 shadow-xl overflow-y-auto'>
            <span>Activity Component</span>
            {/* @ts-ignore */}
            <unit-elements-activity
              account-id={account?.externalId}
              customer-token={unitToken?.token}
              pagination-type='infiniteScroll'
            />
          </div>

          {/* Account Component */}
          <div className='w-4/5 my-4 shadow-xl bg-transparent'>
            <span>Account Component</span>
            {/* @ts-ignore */}
            <unit-elements-account
              account-id={account?.externalId}
              customer-token={unitToken?.token}
            />
          </div>

          {/* Check Deposit Component */}
          {account?.type === AccountDtoTypeEnum.Debit && (
            <div className='w-4/5 p-3 my-4 shadow-xl bg-transparent'>
              <span>Check Deposit Component</span>
              {/* @ts-ignore */}
              <unit-elements-check-deposit
                account-id={account?.externalId}
                fee='$1.5'
                customer-token={unitToken?.token}
              />
            </div>
          )}

          {/* Multiple Cards Component */}
          <div className='w-4/5 h-fit p-3 my-4 shadow-xl bg-transparent'>
            <span>Multiple Cards Component</span>
            {/* @ts-ignore */}
            <unit-elements-multiple-cards
              customer-token={unitToken?.token}
              pagination-type='infiniteScroll'
            />
          </div>

          {/* Next Repayment Component */}
          {account?.type === AccountDtoTypeEnum.Credit && (
            <div className='w-4/5 p-3 my-4 shadow-xl bg-transparent'>
              <span>Next Repayment Component</span>
              {/* @ts-ignore */}
              <unit-elements-next-repayment
                customer-token={unitToken?.token}
                account-id={account?.externalId}
                hide-title='false'
              />
            </div>
          )}

          {/* Program Details Component */}
          {account?.type === AccountDtoTypeEnum.Credit && (
            <div className='w-4/5 p-3 my-4 shadow-xl bg-transparent'>
              <span>Program Details Component</span>
              {/* @ts-ignore */}
              <unit-elements-program-details
                customer-token={unitToken?.token}
                account-id={account?.externalId}
                hide-title='false'
              />
            </div>
          )}
        </div>
      </ZenResourceContainer>
    </ZenPageLayout>
  );
};
export default WalletUnitComponentsRoute;
