import React from 'react';
import { useGemini } from '../hooks/useGemini';
import GeminiButton, { GeminiButtonProps } from './Gemini/GeminiButton';
import ZenButton, { ZenButtonProps } from './Zen/ZenButton';

interface GeminiFeatureFlagButtonProps
  extends Omit<ZenButtonProps, 'variant'>,
    Omit<GeminiButtonProps, 'variant'> {
  zenVariant?: ZenButtonProps['variant'];
  geminiVariant?: GeminiButtonProps['variant'];
}

const GeminiFeatureFlagButton: React.FC<GeminiFeatureFlagButtonProps> = ({
  zenVariant = 'primary',
  geminiVariant = 'primary',
  ...props
}) => {
  const isGeminiFlagEnabled = useGemini();

  if (isGeminiFlagEnabled) {
    return <GeminiButton {...props} variant={geminiVariant} />;
  }

  return <ZenButton {...props} variant={zenVariant} />;
};

export default GeminiFeatureFlagButton;
