import { useSelector } from 'react-redux';
import {
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
  WillBeneficiaryAndTypeResponseTypeEnum,
} from '../../../../openapi/yenta';
import { AgentAddressTypeEnum, RootState } from '../../../../types';
import StepByStepContainer, {
  StepConfig,
} from '../../../StepByStep/StepByStepContainer';
import CreateBeneficiaryInformationStepV2 from './CreateBeneficiaryInformationStepV2';
import {
  CreateBeneficiaryV2FormData,
  CreateBeneficiaryV2Props,
} from './CreateBeneficiaryScreenV2';
import CreateBeneficiarySuccessStepV2 from './CreateBeneficiarySuccessStepV2';

export enum UpdateBeneficiaryV2StepName {
  BENEFICIARY_INFO = 'BENEFICIARY_INFO',
  SUCCESS = 'SUCCESS',
}

const steps: StepConfig<
  CreateBeneficiaryV2FormData,
  UpdateBeneficiaryV2StepName
>[] = [
  {
    Component: CreateBeneficiaryInformationStepV2,
    name: UpdateBeneficiaryV2StepName.BENEFICIARY_INFO,
    hidePagination: true,
  },
  {
    Component: CreateBeneficiarySuccessStepV2,
    name: UpdateBeneficiaryV2StepName.SUCCESS,
    showStepIf: ({ maybeLater }) => !maybeLater,
    hidePagination: true,
  },
];

const UpdateBeneficiaryScreenV2: React.FC<CreateBeneficiaryV2Props> = ({
  close,
}) => {
  const {
    auth: { userDetail: agentDetail },
  } = useSelector((state: RootState) => state);

  const defaultValues: CreateBeneficiaryV2FormData = {
    firstName: '',
    middleName: '',
    lastName: '',
    address: {
      type: AgentAddressTypeEnum.HOME,
      streetAddress1: '',
      city: '',
      zipOrPostalCode: '',
      country: (agentDetail?.accountCountry as unknown) as AddressRequestCountryEnum,
      stateOrProvince: '' as AddressRequestStateOrProvinceEnum,
    },
    birthDate: '',
    phoneNumber: '',
    maybeLater: false,
    beneficiaryType: WillBeneficiaryAndTypeResponseTypeEnum.Individual,
    trustInformation: '',
  };

  return (
    <StepByStepContainer<
      CreateBeneficiaryV2FormData,
      UpdateBeneficiaryV2StepName
    >
      defaultValues={defaultValues}
      steps={steps}
      onSubmit={close}
      mode='onChange'
    />
  );
};

export default UpdateBeneficiaryScreenV2;
