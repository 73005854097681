import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '../../../utils/classUtils';

interface ZenBooleanButtonProps {
  icon?: boolean;
  value: boolean;
  className?: string;
}
const ZenBooleanButton: React.FC<ZenBooleanButtonProps> = ({
  icon = true,
  value,
  className,
}) => {
  return value ? (
    <span
      className={cn(
        'px-2 py-1.5 text-sm space-x-1',
        className ?? 'bg-green-100 text-green-600 rounded-full',
      )}
    >
      {icon && <FontAwesomeIcon icon={faCheck} />} Yes
    </span>
  ) : (
    <span
      className={cn(
        'px-2 py-1.5 text-sm space-x-1',
        className ?? 'bg-zen-danger-light text-error rounded-full',
      )}
    >
      {icon && <FontAwesomeIcon icon={faXmark} size='lg' />} No
    </span>
  );
};

export default ZenBooleanButton;
