import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Control, useFormContext } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { PaginatedPairResponse, PairBase } from '../../openapi/leo';
import {
  LeoTableHeaderFormData,
  TABS_ENUM,
  fetchQueueParams,
} from '../../routes/LeoQuestionManagerRoute';
import {
  saveCompletedQueueDateFilterRange,
  saveCompletedQueueDateFilterType,
} from '../../slices/LeoSlice';
import { IPaginateRes, RootState } from '../../types';
import { ActionBarButtonsProps } from '../TableRowActionBar';
import { DateFilterEnum, DateRange } from '../Zen/Calendars/LQMDateFilter';
import ZenResourceIndexContainer from '../Zen/Containers/ZenResourceIndexContainer';
import ZenRoute from '../Zen/ZenRoute';
import { CountryAll } from './LQMSidebarModalForm';
import LQMTableHeader from './LQMTableHeader';

export interface LQMCompletedQueueTabProps {
  key: string | number;
  columns: Column<PairBase>[];
  actionBarButtons: ActionBarButtonsProps<PairBase>[];
  fetchData(req: fetchQueueParams): Promise<PaginatedPairResponse>;
  control: Control<LeoTableHeaderFormData, object>;
  assigneeId: string;
  searchQueue(req: fetchQueueParams): Promise<PaginatedPairResponse>;
}

const LQMCompletedQueueTab: React.FC<LQMCompletedQueueTabProps> = ({
  key,
  columns,
  actionBarButtons,
  fetchData,
  control,
  assigneeId,
  searchQueue,
}) => {
  const {
    leoQuestionManager: {
      completedQueue: { dateFilterType, dateRange },
    },
  } = useSelector((state: RootState) => state.leo);
  const dispatch = useDispatch();

  const { watch } = useFormContext<LeoTableHeaderFormData>();
  const [
    completedSearch,
    completedQueueCountryFilter,
    completedQueueStatesFilter,
  ] = watch([
    'completedSearch',
    'completedQueueCountryFilter',
    'completedQueueStatesFilter',
  ]);
  const [completedSearchKey, setCompletedSearchKey] = useState(completedSearch);

  const fetchCompletedQueue = useCallback(
    async ({ page, pageSize }): Promise<IPaginateRes<PairBase>> => {
      const api =
        completedSearch ||
        completedQueueCountryFilter ||
        completedQueueStatesFilter
          ? searchQueue
          : fetchData;
      const reqParams: fetchQueueParams = {
        isCompleted: true,
        isWorkQueue: false,
        searchString: completedSearch,
        pageNumber: page + 1,
        pageSize: pageSize,
        startDate: dateRange.start,
        endDate: dateRange.end,
        assigneeId: assigneeId === 'all' ? undefined : assigneeId,
      };

      if (
        completedSearch ||
        completedQueueCountryFilter ||
        completedQueueStatesFilter
      ) {
        reqParams.searchString = completedSearch || '';
        reqParams.country =
          (completedQueueCountryFilter === CountryAll.All
            ? undefined
            : completedQueueCountryFilter) || undefined;
        reqParams.states = completedQueueStatesFilter || undefined;
      }

      const data = await api(reqParams);

      return {
        data: data.data,
        total: data.total_count,
      };
    },
    [
      completedSearch,
      searchQueue,
      fetchData,
      dateRange,
      assigneeId,
      completedQueueCountryFilter,
      completedQueueStatesFilter,
    ],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetCompletedSearchKey = useCallback(
    debounce(() => setCompletedSearchKey(completedSearch), 500),
    [completedSearch],
  );

  useEffect(() => {
    debouncedSetCompletedSearchKey();

    return debouncedSetCompletedSearchKey.cancel;
  }, [debouncedSetCompletedSearchKey, completedSearch]);

  return (
    <ZenRoute title='Work Queue Questionnaire'>
      <LQMTableHeader
        tabName={TABS_ENUM.COMPLETED_QUEUE}
        dateFilterLabel={dateFilterType}
        setDateFilterLabel={(dateFilterType: DateFilterEnum) => {
          dispatch(saveCompletedQueueDateFilterType(dateFilterType));
        }}
        setDateFilter={(range: DateRange) => {
          dispatch(saveCompletedQueueDateFilterRange(range));
        }}
        control={control}
      />
      <div className='mt-4'>
        <ZenResourceIndexContainer<PairBase>
          key={`${key}-${assigneeId}-${dateRange.start}-${dateRange.end}-${completedSearchKey}-${completedQueueCountryFilter}-${completedQueueStatesFilter}`}
          allowSelection
          showSelectionActionBar
          columns={columns}
          resourceName='completed-queue-question'
          fetchData={fetchCompletedQueue}
          actionBarButtons={actionBarButtons}
          pageSize={10}
          hideFilters
        />
      </div>
    </ZenRoute>
  );
};

export default LQMCompletedQueueTab;
