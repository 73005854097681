import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AnalyticsEventEnum,
  RootState,
  SignatureWidgetModeEnum,
} from '../../../../types';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import AnalyticsService from '../../../../services/AnalyticsService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../slices/ToastNotificationSlice';
import SignatureWidget from '../../../SignatureWidget/SignatureWidget';
import { generateWillableRevshareAgreement } from '../../../../slices/AgentSlice';
import { UserAgreementControllerApi } from '../../../../openapi/yenta';
import { getYentaConfiguration } from '../../../../utils/OpenapiConfigurationUtils';
import ErrorService from '../../../../services/ErrorService';
import { showApiErrorModal } from '../../../../slices/ErrorSlice';
import {
  CreateBeneficiaryV2FormData,
  CreateBeneficiaryV2StepName,
} from './CreateBeneficiaryScreenV2';

const CreateBeneficiarySignAddendumStepV2: StepByStepComponent<
  CreateBeneficiaryV2FormData,
  CreateBeneficiaryV2StepName
> = ({ onPrevious, onNext }) => {
  const dispatch = useDispatch();
  const {
    auth: { userDetail },
    agentDetail: { willableRevshareAgreementResponse },
  } = useSelector((state: RootState) => state);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const token = willableRevshareAgreementResponse?.signeeToken!;

  const fetchAgreementDetails = useCallback(async () => {
    if (userDetail?.id) {
      await dispatch(generateWillableRevshareAgreement(userDetail?.id));
    }
  }, [dispatch, userDetail?.id]);

  useEffect(() => {
    fetchAgreementDetails();
  }, [fetchAgreementDetails]);

  const signedWillableRevshareAgreement = useCallback(async () => {
    setIsLoading(true);
    try {
      await new UserAgreementControllerApi(
        getYentaConfiguration(),
      ).willableRevshareAgreementSigned(userDetail?.id!);
      dispatch(showSuccessToast('You have successfully signed the agreement.'));
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.WILLABLE_REVSHARE_AGREE_TO_ADDENDUM,
      );
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.WILLABLE_REVSHARE_ADDENDUM_NEXT_BTN_CLK,
      );
      onNext();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      dispatch(
        showErrorToast('Unable to sign the agreement. Please try again later.'),
      );
      ErrorService.notifyIgnoreHandled('Error signing the agreement', e, {
        user: { id: userDetail?.id! },
      });
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, onNext, userDetail?.id]);

  const onDocumentSigned = useCallback(async () => {
    signedWillableRevshareAgreement();
  }, [signedWillableRevshareAgreement]);

  const onDocumentAlreadySigned = useCallback(() => {
    onNext();
  }, [onNext]);

  return (
    <div className='w-full lg:min-w-[65rem] relative shadow-zen-web bg-white rounded-2xl'>
      <div
        className={classNames(
          'flex flex-row items-center bg-zen-light-blue rounded-t-2xl px-4 py-4',
        )}
      >
        <div className='flex flex-grow flex-shrink flex-col items-center justify-center'>
          <p className='font-zen-body font-semibold text-zen-dark-9 text-lg text-center'>
            Willable revenue share program
          </p>
        </div>
        <FontAwesomeIcon
          icon={faCircleXmark}
          className='text-zen-dark-5 text-2xl cursor-pointer'
          onClick={onPrevious}
        />
      </div>
      <div
        className={classNames(
          'flex flex-row items-center bg-zen-light-gray-2 px-4 md:px-8 py-4',
        )}
      >
        <div className='flex flex-grow flex-shrink flex-col items-center justify-center'>
          <p className='font-zen-body font-normal text-zen-dark-9 text-base text-center'>
            Please take a few seconds to read through document and confirm with
            signature.
          </p>
        </div>
      </div>
      <div
        className='w-full flex flex-col bg-grey-100 h-[550px] overflow-y-hidden rounded-b-2xl'
        aria-label='add-beneficiary'
      >
        <SignatureWidget
          token={token}
          mode={SignatureWidgetModeEnum.SIGN}
          onDocumentSigned={onDocumentSigned}
          showMobileTooltip
          onDocumentAlreadySigned={onDocumentAlreadySigned}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default CreateBeneficiarySignAddendumStepV2;
