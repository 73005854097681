export const BOOK_A_MEETING = 'https://calendly.com/agentinsider/30min';
export const BECOME_A_PARTNER =
  'https://myhome.onerealmortgage.com/partner/myhome@onerealmortgage.com';
export const LEARN_MORE = 'https://www.onerealmortgage.com/lending/purchase';
export const VIDEO_URL = '/videos/one-real-mortgage.mp4';
export const CONTACT_ADDRESS_1 =
  '8000 Corporate Center Dr Ste 206, Charlotte, NC 28226';
export const CONTACT_ADDRESS_2 = '485 US 1 S, Bldg E Ste 250. Iselin, NJ 08830';
export const CONTACT_EMAIL = 'info@onerealmortgage.com';
export const CONTACT_PHONE_NUMBER = '855 549 7001';
export const ONE_REAL_MORTGAGE_URL = 'https://www.onerealmortgage.com/';
export const MARKETING_RESOURCES_URL =
  'https://onereal.widencollective.com/portals/o4jvml5u/OneRealMortgage';
export const WORKPLACE_JOIN_US_URL =
  'https://realbroker.workplace.com/groups/6478746298805557';
export const PROGRAMS_URL = 'https://www.onerealmortgage.com/programs';
export const REAL_FAST_14_URL = 'https://www.onerealmortgage.com/real-fast-14';
