import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  ApplicantAgentDto,
  ReferralCentralReferralDtoStatusEnum,
  ReferralCentralReferralDtoTimelineEnum,
  ReferralCentralReferralLocationDto,
} from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';
import AppliedAgents from './AppliedAgents';
import Dot from './Dot';
import ReferralTag from './ReferralTag';

interface ReferralAddressTimelineStatusProps {
  address: ReferralCentralReferralLocationDto[];
  timeline: ReferralCentralReferralDtoTimelineEnum;
  status?: ReferralCentralReferralDtoStatusEnum;
  applicants?: ApplicantAgentDto[];
  personalNickname?: string;
}

const ReferralAddressTimelineStatus: React.FC<ReferralAddressTimelineStatusProps> = ({
  address,
  timeline,
  status,
  applicants = [],
  personalNickname,
}) => {
  let remainingAddress = address.length - 1;

  return (
    <div className='pr-2'>
      <p className='font-normal flex-nowrap text-primary-dark pb-px'>
        {address[0].city}, {capitalizeEnum(address[0].stateOrProvince ?? '')}{' '}
        {remainingAddress > 0 && `+${remainingAddress} more`}
      </p>
      <div className='flex flex-row items-center space-x-1'>
        <div className='flex flex-row items-center space-x-1'>
          <FontAwesomeIcon icon={faCalendar} className='text-grey-500' />
          <p className='text-grey-600 pt-0.5 text-sm'>
            {capitalizeEnum(timeline)}
          </p>
        </div>

        {status === ReferralCentralReferralDtoStatusEnum.Posted &&
          applicants?.length > 0 && (
            <>
              <Dot />
              <AppliedAgents applicants={applicants!} />
            </>
          )}
      </div>

      {personalNickname && <ReferralTag personalNickname={personalNickname} />}
    </div>
  );
};

export default ReferralAddressTimelineStatus;
