/* eslint-disable react/jsx-key */
/* disabling eslint because .map keys is handled by plugin */
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { findIndex } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, TableInstance, useAsyncDebounce } from 'react-table';
import ControlDisplayItems from '../../components/table/ControlDisplayItems';
import NextPrevPagination from '../../components/table/NextPrevPagination';
import ResourceTableRow from '../../components/table/ResourceTableRow';
import TableLoader from '../../components/TableLoader';
import TableResourceContainer from '../../components/TableResourceContainer';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import {
  AppDispatch,
  IPaginateReq,
  IPaginateRes,
  VerticalAlignmentVariant,
} from '../../types';
import { cn } from '../../utils/classUtils';
import Logger from '../../utils/Logger';
import { getPaginateReqFromState } from '../../utils/TableUtils';

export enum ResourceTableVariant {
  ROW = 'row',
  CARD = 'card',
}

interface GeneralTableProps<D extends object> extends TableInstance<D> {
  fetchData?: (
    req: IPaginateReq<D>,
  ) => Promise<IPaginateRes<D>> | IPaginateRes<D>;
  resourceName: string;
  variant?: ResourceTableVariant;
  hidePagination?: boolean;
  hidePageSize?: boolean;
  emptyIconComponent?: React.ReactElement;
  customEmptyComponent?: React.ReactElement;
  cellVerticalAlignment?: VerticalAlignmentVariant;
  renderToggleRowComponent?(d: Row<D>): React.ReactElement;
  allowSortingRows?: boolean;
  onSortEnd?(oldIndex: number, newIndex: number, item: D): void;
  pageSizeOptions?: number[];
}

const GeminiResourceTable = <D extends object>({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  visibleColumns,
  gotoPage,
  setPageSize,
  resourceName,
  state,
  data = [],
  fetchData,
  pageCount,
  variant = ResourceTableVariant.ROW,
  hidePagination = false,
  hidePageSize = false,
  filteredRows,
  emptyIconComponent,
  customEmptyComponent,
  cellVerticalAlignment = 'align-middle',
  renderToggleRowComponent,
  allowSortingRows,
  onSortEnd,
  pageSizeOptions,
  rows,
}: GeneralTableProps<D>) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(!!fetchData);
  const [isError, setIsError] = useState<boolean>(false);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor),
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (active?.id !== over?.id && onSortEnd) {
      const oldIndex = findIndex(rows, (item: Row<D>) => item.id === active.id);
      const newIndex = findIndex(rows, (item: Row<D>) => item.id === over?.id);
      onSortEnd(oldIndex, newIndex, rows[oldIndex].original);
    }
  };

  const onStateChange = useAsyncDebounce(
    async (): Promise<IPaginateRes<D> | void> => {
      setLoading(true);
      try {
        Logger.debug('Fetching API with state:', state);

        const req: IPaginateReq<D> = getPaginateReqFromState<D>(state);
        const res = await fetchData!(req);
        setIsError(false);
        return res;
      } catch (e) {
        ErrorService.notifyIgnoreAuthErrors('Unable to fetch data', e);
        dispatch(showApiErrorModal(e));

        setIsError(true);
      } finally {
        setLoading(false);
      }
    },
    400,
  );

  useEffect(() => {
    if (!!fetchData) {
      onStateChange();
    }
  }, [
    state.filters,
    state.sortBy,
    state.globalFilter,
    state.pageIndex,
    state.pageSize,
    state.hiddenColumns,
  ]);

  if (loading && !data.length) {
    return <TableLoader />;
  }

  return (
    <div className='w-full scrollbar overflow-x-auto'>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={rows} strategy={verticalListSortingStrategy}>
          <table {...getTableProps()} className='relative table-auto w-full'>
            {variant === ResourceTableVariant.ROW && (
              <thead className='bg-primary-light border-y border-grey-200 py-1'>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {allowSortingRows && (
                      <th className='font-primary-regular  font-normal text-red-400 align-top p-2 whitespace-nowrap' />
                    )}
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className='font-inter text-primary-dark font-medium text-sm align-top py-3 px-6 whitespace-nowrap'
                      >
                        <div
                          className={cn('flex flex-row flex-nowrap', {
                            'cursor-pointer': column.canSort,
                          })}
                          onClick={() => {
                            if (column.canSort) {
                              column.toggleSortBy(
                                column.isSorted && !column.isSortedDesc,
                              );
                            }
                          }}
                        >
                          {column.render('Header')}
                          {column.canSort && (
                            <div className='flex flex-col flex-nowrap justify-end'>
                              <ArrowDropUpIcon
                                className={cn(
                                  'text-gray-400 -mt-1',
                                  column.isSorted && !column.isSortedDesc
                                    ? 'invisible'
                                    : 'visible',
                                )}
                                fontSize='small'
                              />
                              <ArrowDropDownIcon
                                className={cn(
                                  'text-gray-400 -mt-3.5',
                                  column.isSorted && column.isSortedDesc
                                    ? 'invisible'
                                    : 'visible',
                                )}
                                fontSize='small'
                              />
                            </div>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            )}

            <tbody
              {...getTableBodyProps()}
              className={cn({
                'grid grid-flow-row grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4':
                  variant === ResourceTableVariant.CARD,
              })}
            >
              <TableResourceContainer
                loading={loading}
                isEmpty={!filteredRows.length}
                isError={isError}
                resourceName={resourceName}
                colSpan={visibleColumns.length}
                emptyIconComponent={emptyIconComponent}
                customEmptyComponent={customEmptyComponent}
              >
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <ResourceTableRow<D>
                      key={row.id}
                      cellVerticalAlignment={cellVerticalAlignment}
                      row={row}
                      variant={variant}
                      visibleColumns={visibleColumns}
                      renderToggleRowComponent={renderToggleRowComponent}
                      allowSortingRows={allowSortingRows}
                    />
                  );
                })}
              </TableResourceContainer>
            </tbody>

            {data?.length! > 0 && !hidePagination && (
              <tfoot>
                <tr>
                  <td
                    colSpan={visibleColumns.length}
                    className='text-left py-5'
                  >
                    <div className='flex flex-row items-center justify-start'>
                      <div className='sticky left-0'>
                        <div className='hidden lg:inline-block'>
                          {!hidePageSize && (
                            <ControlDisplayItems
                              itemsToShow={state.pageSize}
                              setPageSize={setPageSize}
                              itemsOptions={pageSizeOptions}
                            />
                          )}
                        </div>
                        <NextPrevPagination
                          currentPage={state.pageIndex}
                          lastPage={pageCount}
                          goToPage={gotoPage}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default GeminiResourceTable;
