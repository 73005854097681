import { InstantPaymentTransactionSummaryResponseStateEnum } from '../../openapi/arrakis';
import Pill, { PillVariantType } from '../Pill';
import { EnumMap } from '../../types';
import { safeEnumMapGet } from '../../utils/EnumHelper';
import { capitalizeEnum } from '../../utils/StringUtils';

interface InstantPaymentTransactionSummaryStatePillProps {
  state: InstantPaymentTransactionSummaryResponseStateEnum;
}

const InstantPaymentTransactionSummaryStatePill: React.FC<InstantPaymentTransactionSummaryStatePillProps> = ({
  state,
}) => {
  const textMap: EnumMap<
    InstantPaymentTransactionSummaryResponseStateEnum,
    string
  > = {
    ELIGIBLE: 'Eligible',
    ELIGIBLE_BELOW_MAX: 'Eligible - Close To Limit',
    INELIGIBLE_AGENT: 'Ineligible',
    INELIGIBLE_BELOW_MIN: 'Ineligible - Below Minimum',
    INELIGIBLE_CLOSED: 'Ineligible - Closed',
    INELIGIBLE_NO_AGENT: 'Ineligible - Not Primary Agent',
    INELIGIBLE_PASS_THROUGH: 'Ineligible - Passthrough',
    INELIGIBLE_PERSONAL_DEAL: 'Ineligible - Personal Deal',
    INELIGIBLE_COMMISSION_DOCUMENT_SENT: 'Ineligible - CDA Sent',
  };

  const variantMap: EnumMap<
    InstantPaymentTransactionSummaryResponseStateEnum,
    PillVariantType
  > = {
    ELIGIBLE: 'success',
    ELIGIBLE_BELOW_MAX: 'success',
    INELIGIBLE_AGENT: 'danger',
    INELIGIBLE_BELOW_MIN: 'danger',
    INELIGIBLE_CLOSED: 'danger',
    INELIGIBLE_NO_AGENT: 'danger',
    INELIGIBLE_PASS_THROUGH: 'danger',
    INELIGIBLE_PERSONAL_DEAL: 'danger',
    INELIGIBLE_COMMISSION_DOCUMENT_SENT: 'danger',
  };

  return (
    <Pill
      text={safeEnumMapGet(textMap, state, capitalizeEnum(state))}
      variant={safeEnumMapGet(variantMap, state, 'warning')}
    />
  );
};

export default InstantPaymentTransactionSummaryStatePill;
