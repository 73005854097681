import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import DefaultLoader from '../../DefaultLoader';
import ZenButton from '../../Zen/ZenButton';

interface ZenCheckDepositsImagePreviewProps {
  label: string;
  imageUrl: string;
}

const ZenCheckDepositsImagePreview: React.FC<ZenCheckDepositsImagePreviewProps> = ({
  label,
  imageUrl,
}) => {
  return (
    <div className='flex flex-col space-y-1 font-zen-body font-medium'>
      <div className='flex flex-row justify-between items-center text-lg'>
        <label className='font-zen-title'>{label}</label>
        <ZenButton
          label='Download'
          LeftIconComponent={
            <FontAwesomeIcon icon={faArrowDownToLine} className='mr-1' />
          }
          onClick={() => window.open(imageUrl, '_blank')}
          variant='primary-link'
        />
      </div>
      <div className='flex flex-col justify-center items-center p-4 border border-dashed rounded-lg bg-white border-zen-dark-5'>
        {!imageUrl ? (
          <DefaultLoader />
        ) : (
          <img src={imageUrl} alt='front-check' className='w-full h-auto' />
        )}
      </div>
    </div>
  );
};

export default ZenCheckDepositsImagePreview;
