import { OutgoingPaymentResponseStatusEnum } from '../../../openapi/arrakis';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumMultiSelectColumnGenerator from './EnumMultiSelectColumnGenerator';

export interface OutgoingPaymentstatusSelectColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const OutgoingPaymentStatusSelectColumnFilter = <D extends object>(
  props: OutgoingPaymentstatusSelectColumnFilterProps<D>,
) => {
  return EnumMultiSelectColumnGenerator<D>(OutgoingPaymentResponseStatusEnum)(
    props,
  );
};

export default OutgoingPaymentStatusSelectColumnFilter;
