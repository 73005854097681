import classNames from 'classnames';
import React from 'react';

interface ZenTeamDetailHeaderInfoRowProps {
  leftIcon?: React.ReactElement;
  label: string;
  subLabel?: string;
  rightIcon?: React.ReactElement;
}

const ZenTeamDetailHeaderInfoRow: React.FC<ZenTeamDetailHeaderInfoRowProps> = ({
  label,
  subLabel,
  leftIcon,
  rightIcon,
}) => {
  return (
    <div className='flex flex-row items-center space-x-2'>
      <div className='bg-grey-600  rounded-full min-w-[25px] min-h-[25px] w-[25px] h-[25px] flex items-center justify-center'>
        {leftIcon}
      </div>
      <div
        className={classNames('font-zen-body text-sm font-semibold', {
          'flex flex-row items-center space-x-1': subLabel,
        })}
      >
        <span>{label}</span>
        {subLabel && (
          <span className='font-zen-body text-sm font-normal'>{subLabel}</span>
        )}
      </div>
      {rightIcon}
    </div>
  );
};

export default ZenTeamDetailHeaderInfoRow;
