import { ProspectiveAgentInfoCountryEnum } from '../../../../openapi/plutus';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledSelectInput from '../../../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../../../Zen/Input/ZenControlledTextInput';
import ZenButton from '../../../Zen/ZenButton';
import { WealthPlanRateBanner } from '../WealthPlanRate';
import {
  SendWealthPlanStepByStepFormData,
  WealthPlanFormStepName,
} from './SendWealthPlanStepByStepFormV2';

const AccountInfoPlanV2: StepByStepComponent<
  SendWealthPlanStepByStepFormData,
  WealthPlanFormStepName
> = ({ form: { control }, onNext }) => {
  return (
    <div className='w-full h-full min-h-full scrollbar overflow-y-auto '>
      <div className='w-full h-full flex flex-col justify-start relative items-start gap-1'>
        <p className='font-medium text-xl font-zen-body text-dark'>
          Who are you creating a WealthPlan for?
        </p>
        <p className='text-zen-dark-8 font-zen-body'>
          Please provide some information so we can create a plan
        </p>
        <WealthPlanRateBanner />
        <div className='mt-2 font-zen-body flex items-center'>
          <div className='w-58'>
            <p>Country of Agent&apos;s license:</p>
          </div>
          <div className='w-28'>
            <ZenControlledSelectInput<
              SendWealthPlanStepByStepFormData,
              'country'
            >
              control={control}
              shouldUnregister={false}
              data-testid='country'
              styles={{
                singleValue: (styles) => ({
                  ...styles,
                  color: '#3B82F6',
                }),
              }}
              name='country'
              customClassName='border-none text-primary-blue'
              options={[
                {
                  label: 'USA',
                  value: ProspectiveAgentInfoCountryEnum.UnitedStates,
                },
                {
                  label: 'CAN',
                  value: ProspectiveAgentInfoCountryEnum.Canada,
                },
              ]}
            />
          </div>
        </div>
        <div className='flex flex-row gap-2 w-full mt-2'>
          <div className='w-1/2'>
            <ZenControlledTextInput<
              SendWealthPlanStepByStepFormData,
              'firstName'
            >
              control={control}
              shouldUnregister={false}
              name='firstName'
              label='First Name'
              placeholder='First Name'
              variant='light'
              rules={{ required: 'Please enter the first name' }}
              isRequired
            />
          </div>
          <div className='w-1/2'>
            <ZenControlledTextInput<
              SendWealthPlanStepByStepFormData,
              'lastName'
            >
              control={control}
              shouldUnregister={false}
              variant='light'
              name='lastName'
              label='Last Name'
              placeholder='Last Name'
              isRequired
              rules={{ required: 'Please enter the last name' }}
            />
          </div>
        </div>
      </div>
      <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-end items-center left-0 right-0 px-3 absolute py-2 w-full'>
        <ZenButton label='Next' onClick={() => onNext()} />
      </div>
    </div>
  );
};

export default AccountInfoPlanV2;
