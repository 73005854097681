import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import PageLayout from '../components/PageLayout';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenButton from '../components/Zen/ZenButton';
import ZenRoute from '../components/Zen/ZenRoute';
import CreateBankAccountSidebarModal from '../forms/BankAccountSidebarModal';
import { BankAccountResponse } from '../openapi/yenta';
import { bankAccountSearchRequest } from '../slices/OfficeSlice';
import { AppDispatch, EnumMap } from '../types';
import { capitalizeEnum, formatAddress } from '../utils/StringUtils';

export const columns: Array<Column<BankAccountResponse>> = [
  {
    Header: 'Account Name',
    accessor: 'accountName',
    Cell: ({ value, row }) => (
      <Link to={`/bank-accounts/${row.original.id}/reconcile-transactions`}>
        <p className='font-zen-body font-normal'> {value}</p>
      </Link>
    ),
    disableFilters: true,
  },
  {
    Header: 'Bank Name',
    accessor: 'bankName',
    Cell: ({ value, row }) => (
      <Link to={`/bank-accounts/${row.original.id}/reconcile-transactions`}>
        <p className='font-zen-body font-normal'> {value}</p>
      </Link>
    ),
    disableFilters: true,
  },
  {
    Header: 'Address',
    accessor: 'bankAddress',
    id: 'streetAddress1',
    Cell: ({ value, row }) => (
      <Link to={`/bank-accounts/${row.original.id}/reconcile-transactions`}>
        <div className='w-56'>
          <p className='font-zen-body font-normal'>
            {formatAddress(
              value?.streetAddress1!,
              value?.streetAddress2,
              value?.city!,
              capitalizeEnum(value?.stateOrProvince || ''),
              value?.zipOrPostalCode!,
            )}
          </p>
        </div>
      </Link>
    ),
    disableSortBy: true,
  },
  {
    Header: 'Account No.',
    accessor: 'accountNumber',
    Cell: ({ value, row }) => (
      <Link to={`/bank-accounts/${row.original.id}/reconcile-transactions`}>
        <p className='font-zen-body font-normal'> {value}</p>
      </Link>
    ),
    disableFilters: true,
  },

  {
    Header: 'Routing No.',
    accessor: 'bankRoutingNumber',
    Cell: ({ value, row }) => (
      <Link to={`/bank-accounts/${row.original.id}/reconcile-transactions`}>
        <p className='font-zen-body font-normal'> {value}</p>
      </Link>
    ),
    disableFilters: true,
  },
  {
    Header: 'Wire Routing',
    accessor: 'wireRoutingNumber',
    Cell: ({ value, row }) => (
      <Link to={`/bank-accounts/${row.original.id}/reconcile-transactions`}>
        <p className='font-zen-body font-normal'>{value || 'N/A'}</p>
      </Link>
    ),
    disableFilters: true,
  },
];

export const columnsToFetch = [
  ...columns.map((col) => col.accessor as string),
  'id',
];

export const BankAccountsSortByMap: EnumMap<
  string,
  | 'ACCOUNT_NAME'
  | 'BANK_NAME'
  | 'ACCOUNT_NUMBER'
  | 'BANK_ROUTING_NUMBER'
  | 'WIRE_ROUTING_NUMBER'
> = {
  accountName: 'ACCOUNT_NAME',
  bankName: 'BANK_NAME',
  accountNumber: 'ACCOUNT_NUMBER',
  bankRoutingNumber: 'BANK_ROUTING_NUMBER',
  wireRoutingNumber: 'WIRE_ROUTING_NUMBER',
};

const SortDirection: EnumMap<string, 'ASC' | 'DESC'> = {
  asc: 'ASC',
  desc: 'DESC',
};

const BankAccountsRoute: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [isCreating, setIsCreating] = useState<boolean>(false);

  return (
    <ZenRoute title='Bank Account Details'>
      <PageLayout
        path={[
          { title: 'Finance', url: '/finance' },
          { title: 'Bank Accounts', url: '/bank-accounts' },
        ]}
      >
        <div className='px-4 lg:pt-5'>
          <div className='flex justify-between mb-5'>
            <div className='flex items-center space-x-3'>
              <h1 className='font-zen-body font-semibold text-xl'>
                Bank Accounts
              </h1>
            </div>
            <div>
              <ZenButton
                label='Create Bank Account'
                LeftIconComponent={
                  <FontAwesomeIcon
                    icon={faPlus}
                    fontSize={14}
                    className='text-white mt-1'
                  />
                }
                variant='primary'
                onClick={() => setIsCreating(true)}
                isFullWidth
              />
            </div>
          </div>
          <ZenResourceIndexContainer<BankAccountResponse>
            columns={columns}
            initialSort={{ accountName: 'asc' }}
            fetchData={async (req) => {
              const sortKey = Object.keys(req.sortBy || {})[0];
              const sortType = Object.values(req.sortBy || {})[0];

              const response = await dispatch(
                bankAccountSearchRequest(
                  [BankAccountsSortByMap[sortKey!]],
                  SortDirection[sortType],
                  req.search,
                  req.page,
                  req.pageSize,
                ),
              );

              return {
                data: response?.results || [],
                total: response?.totalCount || 0,
              };
            }}
            resourceName='bank account'
            hideFilters
          />
          <CreateBankAccountSidebarModal
            isOpen={isCreating}
            onClose={() => setIsCreating(false)}
          />
        </div>
      </PageLayout>
    </ZenRoute>
  );
};

export default BankAccountsRoute;
