import ProgressBar from '../../ProgressBar';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import {
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName,
} from './ZenWelcomeOnboardingForm';

const withApplicactionFormProgress = (
  Component: StepByStepComponent<
    WelcomeOnboardingFormState,
    WelcomeOnboardingFormStepName
  >,
): StepByStepComponent<
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName
> => (props) => {
  return (
    <>
      <div className='absolute top-0 left-0 w-full'>
        {props.progress.currentIndex > 0 ? (
          <ProgressBar
            completed={props.progress.currentIndex + 1}
            total={props.progress.lastIndex + 1}
          />
        ) : (
          <div className='flex relative w-full bg-success h-8 justify-center items-center'>
            <p className='text-base text-white text-center block md:hidden'>
              Just a few steps to complete your application!
            </p>
            <p className='text-base text-white text-center hidden md:block'>
              Just a few steps to create your very own Real agent profile!
            </p>
          </div>
        )}
      </div>
      <Component {...props} />
    </>
  );
};

export default withApplicactionFormProgress;
