import { useScreenSize } from '../utils';
import EarningOverview from '../V2/EarningOverview';
import { GoalOverviewCard } from '../V2/GoalOverviewCard';
import LearnMoreSection from '../V2/LearnMoreSection';
import { WealthPlanDisclaimer } from '../WealthPlanDisclaimer';
import LearnMoreAboutRealCard from './V2/LearnMoreAboutRealCard';
import WealthPlanReferralInfoCardV2 from './V2/WealthPlanReferralInfoCard';
import WealthPlanReferralVideoCard from './V2/WealthPlanReferralVideoCard';

interface WealthPlanReferralPageV2Props {
  openGoalsSideBar(): void;
}

export const WealthPlanReferralPageV2: React.FC<WealthPlanReferralPageV2Props> = ({
  openGoalsSideBar,
}) => {
  const screenSize = useScreenSize();
  const isSmallScreen = screenSize.screen === 'xs' || screenSize.screen === 's';
  if (isSmallScreen) {
    return (
      <div className='w-full flex flex-col space-y-4'>
        <WealthPlanReferralInfoCardV2 />
        <GoalOverviewCard isReferral />
        <EarningOverview openGoalsSideBar={openGoalsSideBar} />
        <WealthPlanReferralVideoCard />
        <LearnMoreAboutRealCard />
        <LearnMoreSection />
        <WealthPlanDisclaimer />
      </div>
    );
  }
  return (
    <div className='flex flex-col space-y-6'>
      <div className='flex flex-row space-x-3.5'>
        <div className='md:w-[33%] w-full flex flex-col justify-between space-y-5'>
          <WealthPlanReferralInfoCardV2 />
          <WealthPlanReferralVideoCard />
        </div>
        <div className='flex md:w-[40%] w-full'>
          <GoalOverviewCard isReferral />
        </div>
        <div className='md:w-[27%] w-full flex-1'>
          <LearnMoreAboutRealCard />
        </div>
      </div>
      <EarningOverview openGoalsSideBar={openGoalsSideBar} />
      <LearnMoreSection />
      <WealthPlanDisclaimer />
    </div>
  );
};
