import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Column,
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import NullableTextCell from '../components/table/Cells/NullableTextCell';
import PhoneNumberCell from '../components/table/Cells/PhoneNumberCell';
import ZenOfficeTransactionTypeCell from '../components/table/Cells/ZenOfficeTransactionTypeCell';
import ZenViewActionButtonCell from '../components/table/Cells/ZenViewActionButtonCell';
import OfficeResponseTransactionTypeSelectColumnFilter from '../components/table/Filters/OfficeResponseTransactionTypeSelectColumnFilter';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import ZenAddressCell from '../components/Zen/Table/Cell/ZenAddressCell';
import ZenResourceTable from '../components/Zen/Table/ZenResourceTable';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenRoute from '../components/Zen/ZenRoute';
import { ResourceTableVariant } from '../containers/ResourceTable';
import { OfficePreviewResponse } from '../openapi/yenta';
import { RootState } from '../types';
import { isSmScreen } from '../utils/BrowserUtils';
import { capitalizeEnum } from '../utils/StringUtils';

export const columns: Array<Column<OfficePreviewResponse>> = [
  {
    Header: 'Office',
    accessor: 'name',
    Cell: ({ value, row }) => (
      <Link to={`/offices/${row.original.id}`}>
        <p className='font-zen-body font-normal'>{value}</p>
      </Link>
    ),
    Filter: TextColumnFilter,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Transaction Type',
    accessor: 'transactionType',
    Cell: ({ value, row }) => (
      <Link to={`/offices/${row.original.id}`}>
        <ZenOfficeTransactionTypeCell transactionType={value!} />
      </Link>
    ),
    Filter: OfficeResponseTransactionTypeSelectColumnFilter,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Address',
    accessor: 'address',
    Cell: ({ value, row }) => (
      <Link
        to={`/offices/${row.original.id}`}
        className='font-zen-body font-normal'
      >
        <ZenAddressCell address={value} />
      </Link>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'State/Province',
    accessor: 'address',
    id: 'stateOrProvince',
    Cell: ({ value, row }) => (
      <Link
        to={`/offices/${row.original.id}`}
        className='font-zen-body font-normal'
      >
        {' '}
        <NullableTextCell
          text={value ? capitalizeEnum(value?.stateOrProvince!) : null}
        />
      </Link>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    Cell: ({ value, row }) => (
      <Link
        to={`/offices/${row.original.id}`}
        className='font-zen-body font-normal'
      >
        <PhoneNumberCell phoneNumber={value} />
      </Link>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
];

export const columnsWithAction: Array<Column<OfficePreviewResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<OfficePreviewResponse> }) => (
      <Link to={`/offices/${row.original.id}`}>
        <ZenViewActionButtonCell />
      </Link>
    ),
  },
  ...columns,
];

interface ZenMyOfficesIndexProps {}

const ZenMyOfficesIndex: React.FC<ZenMyOfficesIndexProps> = () => {
  const [search, setSearch] = useState<string>();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const data = useMemo(() => userDetail?.offices || [], [userDetail?.offices]);
  const filteredData = useMemo(
    () =>
      data.filter((value) => {
        if (!search) {
          return true;
        }
        const officeName = value.name?.toLocaleLowerCase();
        return officeName?.includes(search.toLocaleLowerCase());
      }),
    [data, search],
  );

  const tableInstance = useTable<OfficePreviewResponse>(
    {
      columns: columnsWithAction,
      data: filteredData,
      initialState: {
        pageSize: 1000,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  return (
    <ZenRoute title='Offices'>
      <ZenPageLayoutWithSearch
        path={[
          { title: 'Home', url: '/' },
          { title: 'Offices', url: '/offices' },
        ]}
        search={search}
        onSearchChange={(val) => setSearch(val)}
      >
        <div className='px-4 lg:py-5'>
          <div className=' py-4 text-xl font-zen-body font-semibold'>
            Offices
          </div>
          <div className='py-4'>
            <ZenResourceTable<OfficePreviewResponse>
              {...tableInstance}
              resourceName='Office'
              variant={
                isSmScreen()
                  ? ResourceTableVariant.CARD
                  : ResourceTableVariant.ROW
              }
              totalCount={data.length}
              hidePagination
              hidePageSize
              stickyHeader
            />
          </div>
        </div>
      </ZenPageLayoutWithSearch>
    </ZenRoute>
  );
};

export default ZenMyOfficesIndex;
