import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Control, useFormContext } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { PaginatedPairResponse, PairBase } from '../../openapi/leo';
import {
  LeoTableHeaderFormData,
  TABS_ENUM,
  fetchQueueParams,
} from '../../routes/LeoQuestionManagerRoute';
import {
  saveAssignedQueueDateFilterRange,
  saveAssignedQueueDateFilterType,
} from '../../slices/LeoSlice';
import { IPaginateRes, RootState } from '../../types';
import { ActionBarButtonsProps } from '../TableRowActionBar';
import { DateFilterEnum, DateRange } from '../Zen/Calendars/LQMDateFilter';
import ZenResourceIndexContainer from '../Zen/Containers/ZenResourceIndexContainer';
import ZenRoute from '../Zen/ZenRoute';
import { CountryAll } from './LQMSidebarModalForm';
import LQMTableHeader from './LQMTableHeader';

export interface LQMAssignedQueueTabProps {
  key: string | number;
  columns: Column<PairBase>[];
  actionBarButtons: ActionBarButtonsProps<PairBase>[];
  fetchData(req: fetchQueueParams): Promise<PaginatedPairResponse>;
  control: Control<LeoTableHeaderFormData, object>;
  assigneeId: string;
  searchQueue(req: fetchQueueParams): Promise<PaginatedPairResponse>;
}

const LQMAssignedQueueTab: React.FC<LQMAssignedQueueTabProps> = ({
  key,
  columns,
  actionBarButtons,
  fetchData,
  control,
  assigneeId,
  searchQueue,
}) => {
  const {
    leoQuestionManager: {
      assignedQueue: { dateFilterType, dateRange },
    },
  } = useSelector((state: RootState) => state.leo);
  const dispatch = useDispatch();
  const { watch } = useFormContext<LeoTableHeaderFormData>();
  const [
    assignedSearch,
    assignedQueueCountryFilter,
    assignedQueueStatesFilter,
  ] = watch([
    'assignedSearch',
    'assignedQueueCountryFilter',
    'assignedQueueStatesFilter',
  ]);
  const [assignedSearchKey, setAssignedSearchKey] = useState(assignedSearch);

  const fetchAssignedQueue = useCallback(
    async ({ page, pageSize }): Promise<IPaginateRes<PairBase>> => {
      const api =
        assignedSearch ||
        assignedQueueCountryFilter ||
        assignedQueueStatesFilter
          ? searchQueue
          : fetchData;
      const reqParams: fetchQueueParams = {
        isCompleted: false,
        isWorkQueue: false,
        searchString: assignedSearch,
        pageNumber: page + 1,
        pageSize: pageSize,
        startDate: dateRange.start,
        endDate: dateRange.end,
        assigneeId: assigneeId === 'all' ? undefined : assigneeId,
      };

      if (
        assignedSearch ||
        assignedQueueCountryFilter ||
        assignedQueueStatesFilter
      ) {
        reqParams.searchString = assignedSearch || '';
        reqParams.country =
          (assignedQueueCountryFilter === CountryAll.All
            ? undefined
            : assignedQueueCountryFilter) || undefined;
        reqParams.states = assignedQueueStatesFilter || undefined;
      }

      const data = await api(reqParams);

      return {
        data: data.data,
        total: data.total_count,
      };
    },
    [
      assignedSearch,
      searchQueue,
      fetchData,
      dateRange,
      assigneeId,
      assignedQueueCountryFilter,
      assignedQueueStatesFilter,
    ],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetAssignedSearchKey = useCallback(
    debounce(() => setAssignedSearchKey(assignedSearch), 500),
    [assignedSearch],
  );

  useEffect(() => {
    debouncedSetAssignedSearchKey();

    return debouncedSetAssignedSearchKey.cancel;
  }, [debouncedSetAssignedSearchKey, assignedSearch]);

  return (
    <ZenRoute title='Work Queue Questionnaire'>
      <LQMTableHeader
        tabName={TABS_ENUM.ASSIGNED_QUEUE}
        dateFilterLabel={dateFilterType}
        setDateFilterLabel={(dateFilterType: DateFilterEnum) => {
          dispatch(saveAssignedQueueDateFilterType(dateFilterType));
        }}
        setDateFilter={(range: DateRange) => {
          dispatch(saveAssignedQueueDateFilterRange(range));
        }}
        control={control}
      />
      <div className='mt-4'>
        <ZenResourceIndexContainer<PairBase>
          key={`${key}-${assigneeId}-${dateRange.start}-${dateRange.end}-${assignedSearchKey}-${assignedQueueCountryFilter}-${assignedQueueStatesFilter}`}
          allowSelection
          showSelectionActionBar
          columns={columns}
          resourceName='assigned-queue-question'
          fetchData={fetchAssignedQueue}
          actionBarButtons={actionBarButtons}
          pageSize={10}
          hideFilters
        />
      </div>
    </ZenRoute>
  );
};

export default LQMAssignedQueueTab;
