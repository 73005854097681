import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import ButtonV2 from '../ButtonV2';
import { SUBMIT_ORDER_LINK } from '../../constants/RealTitleConstants';

const RealClosingServicesSubmitOrderSection: React.FC = () => {
  return (
    <div
      className='bg-seaglass p-6 py-12 md:p-12 relative overflow-clip'
      data-testid='real-closing-services-submit-order-section'
    >
      <div className='relative z-10'>
        <h1 className='font-poppins text-[32px] leading-10 font-semibold leading-[48px] text-center'>
          Ready to send a Transaction to
          <span className='text-rezen-blue-600'> One Real Title?</span>
        </h1>
        <p className='font-inter text-primary-dark text-lg font-light my-4 text-center mx-auto'>
          Click the link to start your order with us!
        </p>
        <ButtonV2
          label='Submit an Order'
          icon={faArrowUpRightFromSquare}
          className='mx-auto mt-6'
          onClick={() => window.open(SUBMIT_ORDER_LINK, '_blank')}
        />
      </div>
      <div className='absolute -top-28 md:-top-20 left-[calc(100%_-_224px)] rotate-[30deg] border-2 border-coral-red w-64 h-32 p-4 md:p-5'>
        <div className='w-full h-full border-2 border-coral-red' />
      </div>
    </div>
  );
};

export default RealClosingServicesSubmitOrderSection;
