import { useEffect, useState } from 'react';
import bowser from 'bowser';

const BROWSER_ALIAS_MAP = {
  chrome: 'Chrome',
  firefox: 'Firefox',
  edge: 'Microsoft Edge',
  opera: 'Opera',
  safari: 'Safari',
  electron: 'Electron',
};

const useBrowserCheck = () => {
  const [isSupported, setIsSupported] = useState<boolean>(true);
  const [browserName, setBrowserName] = useState<string>('');

  useEffect(() => {
    const browser = bowser.getParser(window.navigator.userAgent);
    const name: string = browser.getBrowserName();
    const version: number = parseFloat(browser.getBrowserVersion());

    const minSupportedVersions: { [key: string]: { minVersion: number } } = {
      [BROWSER_ALIAS_MAP.chrome]: { minVersion: 85 },
      [BROWSER_ALIAS_MAP.edge]: { minVersion: 85 },
      [BROWSER_ALIAS_MAP.firefox]: { minVersion: 105 },
      [BROWSER_ALIAS_MAP.safari]: { minVersion: 14.1 },
      [BROWSER_ALIAS_MAP.opera]: { minVersion: 78 },
      [BROWSER_ALIAS_MAP.electron]: { minVersion: 11 },
    };
    if (Object.values(BROWSER_ALIAS_MAP).indexOf(name) > -1) {
      if (
        !window.location.pathname?.includes('/pdf') &&
        (!minSupportedVersions[name] ||
          (!isNaN(version) && version < minSupportedVersions[name].minVersion))
      ) {
        setIsSupported(false);
      } else {
        setIsSupported(true);
      }
    }
    setBrowserName(name);
  }, []);

  return { isSupported, browserName };
};

export default useBrowserCheck;
