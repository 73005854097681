import React from 'react';
import { BsFlagFill, BsFlag } from 'react-icons/bs';
import { ItemDefinitionDto } from '../../../../openapi/sherlock';
import TemplateItemLabel from '../../../transactions/CheckListAndRoadToSuccessManage/TemplateItemLabel';

interface ZenTemplateItemNameCellProps {
  item: ItemDefinitionDto;
}

const ZenTemplateItemNameCell: React.FC<ZenTemplateItemNameCellProps> = ({
  item,
}) => {
  return (
    <div className='flex flex-row items-center gap-x-2.5 py-2'>
      <div className='flex flex-col'>
        <div className='space-x-2'>
          <div className='inline font-zen-body font-semibold text-sm text-zen-dark-9'>
            {item?.name}
          </div>
          <div className='inline-block align-text-top'>
            {item.required ? (
              <BsFlagFill
                fontSize={18}
                className='text-zen-danger'
                data-testid='flagged'
              />
            ) : (
              <BsFlag
                fontSize={18}
                className='text-zen-dark-5'
                data-testid='unflagged'
              />
            )}
          </div>
        </div>

        {!!item?.labels?.length && (
          <div className='flex flex-wrap flex-row items-center gap-x-2.5'>
            {item?.labels?.map((label) => (
              <TemplateItemLabel key={label} label={label} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ZenTemplateItemNameCell;
