import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, createRef } from 'react';
import { useDispatch } from 'react-redux';
import { faRotate, faCamera } from '@fortawesome/pro-regular-svg-icons';
import { cn } from '../utils/classUtils';
import { isValidUserAvatarFile } from '../utils/FileUtils';
import { showErrorToast } from '../slices/ToastNotificationSlice';

export type ProfileImageVariant = 'rounded' | 'circle';

export interface ProfileImageProps {
  imageUrl?: string | undefined;
  width: number;
  variant?: ProfileImageVariant;
  editable?: boolean;
  hideEdit?: boolean;
  subtitle?: string;
  isSubmitting?: boolean;
  onImageChange?(e: ChangeEvent<HTMLInputElement>): void;
}

const ProfileImage: React.FC<ProfileImageProps> = ({
  imageUrl,
  width = 50,
  variant = 'circle',
  editable = false,
  onImageChange,
  hideEdit = false,
  subtitle,
  isSubmitting = false,
}) => {
  const ref = createRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const variantMapClass: { [x in ProfileImageVariant]: string } = {
    rounded: 'rounded',
    circle: 'rounded-full',
  };

  const handleChangeImage = () => {
    if (!!onImageChange) {
      ref.current?.click();
    }
  };

  return (
    <div
      className={cn('relative', {
        'cursor-pointer': !!editable,
      })}
      onClick={handleChangeImage}
    >
      {isSubmitting && (
        <FontAwesomeIcon
          icon={faRotate}
          className='animate-spin text-primary-blue absolute right-0 top-0 left-0 bottom-0 mx-auto my-auto'
          size='xl'
        />
      )}
      <div
        className={cn({
          'border p-2 flex justify-center': !!subtitle,
        })}
      >
        <div
          style={{ width, height: width }}
          className={cn('overflow-hidden', variantMapClass[variant])}
        >
          <img
            className='w-full h-auto'
            src={imageUrl || '/img/default-avatar.svg'}
            width={width}
            alt='profileimage'
          />
          {subtitle && (
            <p className='bg-dark w-full text-white text-center absolute -bottom-4 inset-x-0'>
              {subtitle}
            </p>
          )}
        </div>
      </div>

      {editable && (
        <div
          className={cn(
            'flex items-center justify-center w-8 h-8 absolute right-0 bottom-1 rounded-full p-1',
            { 'bg-white': !hideEdit },
          )}
        >
          {!hideEdit && (
            <FontAwesomeIcon icon={faCamera} className='text-primary-blue' />
          )}
          <input
            type='file'
            className='hidden'
            ref={ref}
            aria-label='uploadAvatar'
            onChange={(e) => {
              if (e.target?.files && e.target?.files?.[0]) {
                if (
                  onImageChange &&
                  isValidUserAvatarFile(e.target?.files?.[0]?.name)
                ) {
                  onImageChange(e);
                } else {
                  dispatch(
                    showErrorToast(
                      'File type not supported. Upload jpg/jpeg, png, gif, bmp file only',
                    ),
                  );
                }
                e.target.value = '';
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileImage;
