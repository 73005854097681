import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { useParams } from 'react-router-dom';
import ZenAgentDetailHeader from '../components/Agent/V2/ZenAgentDetailHeader';
import AuthorizationContainer from '../components/auth/AuthorizationContainer';
import DetailPageLoader from '../components/DetailPageLoader';
import GeminiAgentDetailHeader from '../components/Gemini/Agent/GeminiAgentDetailHeader';
import PageLayout from '../components/PageLayout';
import ResourceContainer from '../components/ResourceContainer';
import GrantorRolesTableEmptyComponent from '../components/SuperAdmin/GrantorRolesTableEmptyComponent';
import SuperAdminGrantRolesSideBarModal from '../components/SuperAdmin/SuperAdminGrantRolesSideBarModal';
import ZenFixedDataResourceIndexContainer from '../components/Zen/Containers/ZenFixedDataResourceIndexContainer';
import ZenConfirmationModal from '../components/Zen/Modal/ZenConfirmationModal';
import ZenButton from '../components/Zen/ZenButton';
import ZenLink from '../components/Zen/ZenLink';
import ZenTabs from '../components/Zen/ZenTabs';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { IdentityRoleResponse } from '../openapi/keymaker';
import { fetchAgentDetail, fetchAgentPerformance } from '../slices/AgentSlice';
import { fetchIdentityByKeymakerId } from '../slices/AuthSlice';
import {
  getGrantorRoles,
  revokeRole as agentRevokeRole,
} from '../slices/RoleSlice';
import { AppDispatch, FeatureFlagTypeEnum, RootState } from '../types';
import { capitalizeEnum } from '../utils/StringUtils';
import {
  getOnlyGrantorRoles,
  getRoleDescription,
} from '../utils/SuperAdminUtils';

type Match = {
  id: string;
};

export const grantorRolesHistoryColumns: Array<Column<IdentityRoleResponse>> = [
  {
    Header: 'Role',
    accessor: 'role',
    Cell: ({ value }) => (
      <p className='font-zen-body font-semibold text-zen-dark-9 text-sm'>
        {capitalizeEnum(value!)}
      </p>
    ),
    disableFilters: true,
  },
  {
    Header: 'Action',
    accessor: 'revokedAt',
    Cell: ({ value }) => (
      <p>
        {value ? (
          <p className='font-zen-body font-semibold text-zen-danger text-sm'>
            Revoked
          </p>
        ) : (
          <p className='font-zen-body font-semibold text-green-600 text-sm'>
            Granted
          </p>
        )}
      </p>
    ),
    disableFilters: true,
  },
  {
    Header: 'By super admin',
    accessor: 'grantedBy',
    Cell: ({ value, row: { original } }) =>
      original.revokedAt ? (
        <p className='font-zen-body font-normal text-zen-dark-9 text-sm'>
          {original.revokedBy}
        </p>
      ) : (
        <p className='font-zen-body font-normal text-zen-dark-9 text-sm'>
          {value}
        </p>
      ),
    disableFilters: true,
  },
  {
    Header: 'date',
    accessor: 'grantedAt',
    Cell: ({ value, row: { original } }) =>
      original.revokedAt ? (
        <p className='font-zen-body font-normal text-zen-dark-9 text-sm'>
          {DateTime.fromMillis(original.revokedAt).toLocaleString(
            DateTime.DATETIME_SHORT,
          )}
        </p>
      ) : value ? (
        <p className='font-zen-body font-normal text-zen-dark-9 text-sm'>
          {DateTime.fromMillis(value).toLocaleString(DateTime.DATETIME_SHORT)}
        </p>
      ) : (
        'N/A'
      ),
    disableFilters: true,
  },
];

const SuperAdminAgentDetailRoute: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { id: agentId } = useParams() as Match;
  const {
    auth: { identityByKeymakerIdResponse },
    agentDetail: {
      detailResponse: { data: agentDetailResponse },
      performanceResponse: { data: performance },
    },
    roles: { grantorRolesResponse },
  } = useSelector((state: RootState) => state);

  const keyMakerId = agentDetailResponse?.keyMakerId!;

  const [selected, setSelected] = useState<string>();
  const [revokeRole, setRevokeRole] = useState<string | undefined>();
  const [revokeRoleLoading, setRevokeRoleLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const agentKeymakerDetails = identityByKeymakerIdResponse.data![keyMakerId];
  const agentActiveGrantorRoles = getOnlyGrantorRoles(
    agentKeymakerDetails?.activeRoles || [],
    grantorRolesResponse.data || [],
  );
  const agentGrantorRolesHistory = getOnlyGrantorRoles(
    agentKeymakerDetails?.revokedRoles || [],
    grantorRolesResponse.data || [],
  );

  const path = [
    { title: 'Home', url: '/' },
    { title: 'People', url: '/people' },
    {
      title: agentDetailResponse?.fullName!,
      url: `/people/${agentDetailResponse?.id}`,
    },
  ];

  const assignedColumnsWithActions: Array<
    Column<IdentityRoleResponse>
  > = useMemo(
    () => [
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ value }) => (
          <p className='font-zen-body font-semibold text-zen-dark-9 text-sm'>
            {capitalizeEnum(value!)}
          </p>
        ),
        disableFilters: true,
      },
      {
        Header: 'Description',
        accessor: 'role',
        id: 'description',
        Cell: ({ row: { original } }) => (
          <p className='font-zen-body font-normal text-zen-dark-9 text-sm'>
            {!!agentActiveGrantorRoles &&
              getRoleDescription(
                grantorRolesResponse.data || [],
                original.role!,
              )}
          </p>
        ),
        disableFilters: true,
        cardColSize: 7,
      },
      {
        Header: 'Granted by',
        accessor: 'grantedBy',
        Cell: ({ value }) => (
          <div className='flex flex-col'>
            <p className='font-zen-body font-normal text-zen-dark-9 text-sm'>
              {capitalizeEnum(value!)}
            </p>
            <p className='font-zen-body font-normal text-zen-dark-4 text-sm'>
              Super Admin
            </p>
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: 'Date granted',
        accessor: 'grantedAt',
        Cell: ({ value }) =>
          value ? (
            <p className='font-zen-body font-normal text-zen-dark-9 text-sm'>
              {DateTime.fromMillis(value).toLocaleString(
                DateTime.DATETIME_SHORT,
              )}
            </p>
          ) : (
            'N/A'
          ),
        disableFilters: true,
      },
      {
        Header: 'Action',
        accessor: 'revokedBy',
        id: 'actions',
        Cell: ({ row: { original } }) => {
          return (
            <ZenLink
              label='Revoke'
              leftIconComponent={
                <FontAwesomeIcon icon={faClose} size='lg' className='mr-1.5' />
              }
              onClick={() => setRevokeRole(original.role)}
              variant='danger'
            />
          );
        },
        disableFilters: true,
      },
    ],
    [agentActiveGrantorRoles, grantorRolesResponse],
  );

  const isLoading =
    agentId !== agentDetailResponse?.id ||
    (keyMakerId !== agentKeymakerDetails?.id &&
      agentId === agentDetailResponse?.id) ||
    (!agentKeymakerDetails &&
      (grantorRolesResponse.loading || identityByKeymakerIdResponse.loading));

  useEffect(() => {
    if (agentId !== agentDetailResponse?.id) {
      dispatch(fetchAgentDetail(agentId));
      dispatch(fetchAgentPerformance(agentId));
    }
  }, [agentId, agentDetailResponse?.id, dispatch]);

  useEffect(() => {
    if (!grantorRolesResponse.data?.length) {
      dispatch(getGrantorRoles());
    }
  }, [dispatch, grantorRolesResponse.data?.length]);

  useEffect(() => {
    if (
      keyMakerId !== agentKeymakerDetails?.id &&
      agentId === agentDetailResponse?.id
    ) {
      dispatch(fetchIdentityByKeymakerId(keyMakerId));
    }
  }, [
    agentId,
    agentKeymakerDetails?.id,
    agentDetailResponse?.id,
    dispatch,
    keyMakerId,
  ]);

  const geminiFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.GEMINI_REDESIGN,
  );

  const HeaderComponent = geminiFeatureFlagEnabled
    ? GeminiAgentDetailHeader
    : ZenAgentDetailHeader;

  return (
    <AuthorizationContainer asyncResponse={identityByKeymakerIdResponse}>
      <ResourceContainer
        loading={isLoading}
        LoaderComponent={<DetailPageLoader />}
        isEmpty={!agentKeymakerDetails}
        resourceName='agent'
      >
        <PageLayout path={path}>
          <div className='py-8 px-4 md:px-6'>
            <HeaderComponent
              agentId={agentId}
              detail={agentDetailResponse}
              performance={performance}
            />
          </div>
          <div className='flex md:flex-row flex-col justify-between md:items-center px-4 md:px-6 space-y-2 md:space-y-0'>
            <h2 className='font-zen-title text-xl leading-5 font-medium text-zen-dark-9'>
              Grantor Roles
            </h2>
            <div>
              <ZenButton
                label='Assign a Grantor Role'
                onClick={async () => setIsOpen(true)}
                LeftIconComponent={
                  <FontAwesomeIcon
                    icon={faPlus}
                    className='text-lg text-white'
                  />
                }
              />
            </div>
          </div>
          <div className='py-4'>
            <ZenTabs
              selected={selected}
              onChange={setSelected}
              size='sm'
              tabs={[
                {
                  name: 'Assigned',
                  TabComponent: (
                    <div className='p-6'>
                      <ZenFixedDataResourceIndexContainer<IdentityRoleResponse>
                        key={agentActiveGrantorRoles?.length}
                        columns={assignedColumnsWithActions}
                        data={agentActiveGrantorRoles || []}
                        resourceName='assigned'
                        initialSort={{ grantedAt: 'desc' }}
                        customEmptyComponent={
                          <GrantorRolesTableEmptyComponent
                            emptyMessage='No Roles have been assigned yet.'
                            actionText='Assign a Grantor Role'
                            onActionClick={() => setIsOpen(true)}
                          />
                        }
                        hidePageSize
                        hidePagination
                        hideFilters
                      />
                    </div>
                  ),
                },
                {
                  name: 'History',
                  TabComponent: (
                    <div className='p-6'>
                      <ZenFixedDataResourceIndexContainer<IdentityRoleResponse>
                        key={agentGrantorRolesHistory?.length}
                        columns={grantorRolesHistoryColumns}
                        data={agentGrantorRolesHistory || []}
                        initialSort={{ grantedAt: 'desc' }}
                        customEmptyComponent={
                          <GrantorRolesTableEmptyComponent
                            emptyMessage='Long story short, no history.'
                            actionText='Assign a Grantor Role'
                            onActionClick={() => setIsOpen(true)}
                          />
                        }
                        resourceName='revoked'
                        hidePageSize
                        hidePagination
                        hideFilters
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </PageLayout>
        {!!agentActiveGrantorRoles && isOpen && (
          <SuperAdminGrantRolesSideBarModal
            onClose={() => setIsOpen(false)}
            keyMakerId={keyMakerId}
            agentActiveGrantorRoles={agentActiveGrantorRoles}
            setRevokeRole={setRevokeRole}
          />
        )}
        {!!revokeRole && (
          <ZenConfirmationModal
            isOpen={!!revokeRole}
            variant='danger'
            size='small'
            title={`Revoke ${capitalizeEnum(revokeRole!)} Role?`}
            subtitle={`You are about to revoke ${capitalizeEnum(
              revokeRole!,
            )} Role from ${agentDetailResponse?.fullName}.`}
            onClose={() => setRevokeRole(undefined)}
            confirmButtonText='Revoke'
            cancelButtonText='No'
            onConfirm={async () => {
              setRevokeRoleLoading(true);
              await dispatch(agentRevokeRole(keyMakerId, revokeRole!));
              setRevokeRoleLoading(false);
              setRevokeRole(undefined);
            }}
            isSubmitting={revokeRoleLoading}
          />
        )}
      </ResourceContainer>
    </AuthorizationContainer>
  );
};

export default SuperAdminAgentDetailRoute;
