import React from 'react';
import { useSelector } from 'react-redux';
import ZenInvoiceResourceTable from '../components/Zen/Invoices/ZenInvoiceResourceTable';
import { RootState } from '../types';
import { getPageTitleForTransaction } from '../utils/TransactionUtils';
import ZenRoute from '../components/Zen/ZenRoute';

interface ZenTransactionPaymentInvoicesRouteProps {}

const ZenTransactionPaymentInvoicesRoute: React.FC<ZenTransactionPaymentInvoicesRouteProps> = () => {
  const {
    transactionDetailResponse: { data: transactionDetail },
  } = useSelector((state: RootState) => state.transaction);

  return (
    <ZenRoute
      title={`${getPageTitleForTransaction(
        transactionDetail,
      )} Payment Invoices - ${transactionDetail?.address?.oneLine}`}
    >
      <div className='-mx-4 md:mx-0 pt-4 md:pt-0'>
        <ZenInvoiceResourceTable
          hiddenColumns={['transactionCode']}
          allowSelection={false}
          isTransactionPaymentInvoices
        />
      </div>
    </ZenRoute>
  );
};

export default ZenTransactionPaymentInvoicesRoute;
