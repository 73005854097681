import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { debounce, isNil } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import ZenControlledPercentageInput from '../Input/ZenControlledPercentageInput';
import {
  TaxConfigTypeEnum,
  TaxResponse,
  UpdateTransactionTaxRequestTypeEnum,
} from '../../../openapi/arrakis';
import { AnalyticsEventEnum, AppDispatch } from '../../../types';
import { updateTransactionTaxRate } from '../../../slices/TransactionSlice';
import DefaultLoader from '../../DefaultLoader';
import { GRT_TAX_RATE_INFO_LINK } from '../../../constants/TransactionConstants';
import Hover from '../../Hover';
import AnalyticsService from '../../../services/AnalyticsService';

type TaxRateFormData = {
  overriddenTaxRate: string;
};

interface ZenTransactionTaxRateProps {
  transactionId: string;
  taxes?: TaxResponse[];
  readonly: boolean;
}

const ZenTransactionTaxRate: React.FC<ZenTransactionTaxRateProps> = ({
  transactionId,
  taxes,
  readonly,
}) => {
  const DECIMAL_LIMIT = 4;
  const dispatch = useDispatch<AppDispatch>();
  const taxConfig = taxes?.[0]?.config;
  const taxRate = +(
    ((taxConfig?.overriddenRate ?? taxConfig?.rate) || 0) * 100
  ).toFixed(DECIMAL_LIMIT);
  const { control, watch } = useForm<TaxRateFormData>({
    defaultValues: {
      overriddenTaxRate: taxRate?.toString(),
    },
  });
  const [loading, setLoading] = useState(false);
  const previousTaxRate = useRef(taxRate);

  const overriddenTaxRate = +watch('overriddenTaxRate');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateTaxRate = useCallback(
    debounce(async (taxRate: number) => {
      if (previousTaxRate.current === taxRate) {
        return;
      }
      setLoading(true);
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.TAX_COLLECTION_EDIT_TAX_PERCENTAGE_ON_TRANSACTION,
        { transaction: { id: transactionId, taxRate } },
      );
      previousTaxRate.current = taxRate;
      await dispatch(
        updateTransactionTaxRate(transactionId, {
          updateTransactionTaxRequests: [
            {
              type: (taxConfig?.type as unknown) as UpdateTransactionTaxRequestTypeEnum,
              overriddenTaxRate: taxRate / 100,
            },
          ],
        }),
      );
      setLoading(false);
    }, 2000),
    [],
  );

  useEffect(() => {
    if (!isNil(overriddenTaxRate) && !isNaN(overriddenTaxRate)) {
      updateTaxRate(overriddenTaxRate);
    }
  }, [overriddenTaxRate, updateTaxRate]);

  if (readonly) {
    return <p>{taxRate || 'N/A'}%</p>;
  }

  const isTaxTypeGRT = taxConfig?.type === TaxConfigTypeEnum.Grt;

  return (
    <div className='flex items-center space-x-2'>
      {isTaxTypeGRT && (
        <Hover
          hoverComponent={
            <p className='text-sm text-zen-dark-9'>GRT Tax Map</p>
          }
          config={{
            trigger: 'hover',
            placement: 'top',
          }}
        >
          <a href={GRT_TAX_RATE_INFO_LINK} target='_blank' rel='noreferrer'>
            <FontAwesomeIcon icon={faCircleQuestion} />
          </a>
        </Hover>
      )}
      {loading && (
        <span>
          <DefaultLoader iconSize='small' noPadding />
        </span>
      )}
      <div className={classNames('w-24', { 'bg-gray-50': loading })}>
        <ZenControlledPercentageInput<TaxRateFormData, 'overriddenTaxRate'>
          name='overriddenTaxRate'
          control={control}
          decimalsLimit={DECIMAL_LIMIT}
          textAlign='text-right'
          readOnly={loading}
          startAdornment={
            <p className='p-2 h-full flex items-center justify-center'>
              <FontAwesomeIcon
                icon={light('percentage')}
                className='text-primary-blue'
              />
            </p>
          }
        />
      </div>
    </div>
  );
};

export default ZenTransactionTaxRate;
