import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTicket } from '../../../../slices/LeoSlice';
import { RootState } from '../../../../types';
import {
  ListingPosterItem,
  MEDIA_ENUM,
  SharranMediaItem,
  TransactionItem,
  VoteType,
} from '../../../../utils/LeoUtils';
import Markdown from '../../../MarkdownRender/Markdown';
import ZenButton from '../../ZenButton';
import { ContextItems } from './ContextItems';
import { CoursesLinks } from './CoursesLinks';
import { FeedbackNote } from './FeedbackNote';
import { LearningLinks } from './LearningLinks';
import './LeoChatBubble.css';
import { CurrentChat } from './LeoChatIndex';
import LeoChatResponseActions from './LeoChatResponseActions';
import { TimeStamp } from './TimeStamp';
import LeoSocialShareables from './Shareables/LeoSocialShareables';
import LeoEmbedVideoResponses from './LeoEmbedVideoResponses';

interface LeoChatBubbleProps extends CurrentChat {
  isShowingHistory: boolean;
  isDisclaimer: boolean;
}

const LeoChatBubble: React.FC<LeoChatBubbleProps> = ({
  isShowingHistory,
  isDisclaimer,
  ...msg
}) => {
  const dispatch = useDispatch();
  const {
    msgContext: context,
    isCreatingTicket,
    isTicketCreated,
  } = useSelector((state: RootState) => state.leo);
  const isLatestResponse = msg.id === context?.bot_message_id;
  const msgContext = isLatestResponse ? context : undefined;
  const showCreateTicket =
    isLatestResponse &&
    !isTicketCreated &&
    (!!msgContext?.create_ticket || msg.vote_type === VoteType.Downvote);
  const [items, setItems] = React.useState<TransactionItem[]>([]);

  const courses =
    msgContext?.references?.courses?.filter(
      (el) => !!el && !!Object.keys(el).length,
    ) || [];

  const learningLinks = msgContext?.references?.documents
    ?.map(({ title, url }) => ({ title, url }))
    .filter((el) => !!el.url);

  const shareablePosters: ListingPosterItem[] = msgContext?.media
    ? msgContext?.media
        ?.filter(
          (el) =>
            el.type === MEDIA_ENUM.PROPERTY_LISTING_POSTER && !!el.media_url,
        )
        .map((el) => ({
          posterLink: el.media_url!,
          listingDetails: el.details!,
        }))
    : [];

  const sharranVideos: SharranMediaItem[] = (msgContext?.media
    ? msgContext?.media?.filter((el) => el.type === MEDIA_ENUM.SHARRAN)
    : []) as SharranMediaItem[];

  const hasAdditionalInfo =
    !!items.length ||
    !!courses.length ||
    !!learningLinks?.length ||
    !!sharranVideos.length ||
    !!shareablePosters.length;

  const hasAdditionalInfoOrNote = hasAdditionalInfo || !!msgContext?.note;

  return (
    <div className='mb-6 w-full'>
      <div
        className={classNames(
          hasAdditionalInfo ? 'lg:w-11/12 sm:w-11/12' : 'lg:w-4/5 sm:w-2/3',
          {
            'bg-[#171821] rounded-lg p-5 mb-2': hasAdditionalInfoOrNote,
          },
        )}
      >
        <div
          className={classNames(
            'min-w-[250px] rounded-md p-3 relative bg-[#2F3149]',
            hasAdditionalInfoOrNote ? 'mb-6' : 'mb-2',
          )}
        >
          <div
            className={classNames(
              'leo text-white text-base font-inter font-normal whitespace-pre-wrap word-break',
            )}
          >
            <Markdown content={msg.message!} />
          </div>
          {showCreateTicket && (
            <div className='flex flex-row items-center mt-3'>
              <ZenButton
                label='Create Support Ticket'
                LeftIconComponent={
                  !isCreatingTicket ? (
                    <FontAwesomeIcon
                      icon={faPlus}
                      className='text-white mt-0.5'
                      size='sm'
                    />
                  ) : undefined
                }
                onClick={() =>
                  dispatch(
                    createTicket({
                      session_id: msg.session_id,
                      message_id: msg.id!,
                    }),
                  )
                }
                isSubmitting={isCreatingTicket}
                isDisabled={isCreatingTicket}
              />
            </div>
          )}
          {!isDisclaimer && <LeoChatResponseActions {...msg} />}
        </div>
        {!!sharranVideos.length && (
          <LeoEmbedVideoResponses
            sharranVideos={sharranVideos}
            isShowingHistory={isShowingHistory}
          />
        )}
        {!!shareablePosters.length && (
          <LeoSocialShareables
            shareables={shareablePosters}
            isShowingHistory={isShowingHistory}
          />
        )}
        <ContextItems
          msgContext={msgContext}
          items={items}
          setItems={setItems}
          isShowingHistory={isShowingHistory}
        />
        <CoursesLinks courses={courses} isShowingHistory={isShowingHistory} />
        <LearningLinks learningLinks={learningLinks} />
        <FeedbackNote
          feedbackNote={msgContext?.note}
          hasAdditionalInfo={hasAdditionalInfo}
        />
      </div>
      <TimeStamp messageTime={msg.created_at} />
    </div>
  );
};

export default LeoChatBubble;
