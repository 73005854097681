import { VictoryPie } from 'victory';
import { cn } from '../../../utils/classUtils';

export interface HalfPieProps {
  progress: number;
  text: string;
  activeTintColor?: string;
  inactiveTintColor?: string;
  blurText?: boolean;
}

const ZenHalfPieProgress: React.FC<HalfPieProps> = ({
  progress,
  text,
  activeTintColor = '#3E3F3C',
  inactiveTintColor = 'rgba(62, 63, 60, 0.1)',
  blurText = false,
}) => {
  const done = (progress * 180) / 100;
  const remaining = 180 - done;

  return (
    <svg viewBox='0 0 300 160'>
      <text
        x='50%'
        y='62%'
        dominantBaseline='middle'
        textAnchor='middle'
        className={cn(
          'font-primary-medium text-4xl',
          blurText ? 'blur-md' : '',
        )}
        fill={activeTintColor}
      >
        {progress}%
      </text>
      {!!text && (
        <text
          x='50%'
          y='83%'
          dominantBaseline='middle'
          textAnchor='middle'
          className={cn('font-primary-thin text-xl', blurText ? 'blur-md' : '')}
        >
          {text}
        </text>
      )}
      <VictoryPie
        standalone={false}
        width={300}
        height={300}
        startAngle={90}
        endAngle={-90}
        labels={() => null}
        innerRadius={150}
        radius={130}
        cornerRadius={({ datum }) => datum.y * 5}
        data={[
          { y: remaining, fill: inactiveTintColor, opacity: 0 },
          { y: done, fill: activeTintColor, cornerRadius: 50 },
        ]}
        style={{
          data: {
            fill: ({ datum }) => datum.fill,
            opacity: ({ datum }) => datum.opacity,
          },
        }}
      />
      <VictoryPie
        standalone={false}
        width={300}
        height={300}
        startAngle={90}
        endAngle={-90}
        labels={() => null}
        innerRadius={150}
        radius={130}
        data={[{ y: remaining, fill: inactiveTintColor, opacity: 100 }]}
        style={{
          data: {
            fill: ({ datum }) => datum.fill,
            opacity: ({ datum }) => datum.opacity,
          },
        }}
      />
    </svg>
  );
};

export default ZenHalfPieProgress;
