import { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsFilter } from '@fortawesome/pro-regular-svg-icons';
import { flatten, map, merge, omit } from 'lodash';
import { FilterValue, IdType, TableInstance } from 'react-table';
import { useForm } from 'react-hook-form-v7';
import IconButton from '../../IconButton';
import {
  getProcessedFilterData,
  PROCESS_FILTER_COLUMN,
} from '../../../utils/TableUtils';
import { FilterColumnsToProcess, YesNoType } from '../../../types';
import ControlledTextInputV7 from '../../ControlledTextInputV7';

interface FilterOptionProps<D extends object> {
  tableInstance: TableInstance<D>;
}

const FilterOption = <D extends object>({
  tableInstance,
}: FilterOptionProps<D>) => {
  const [open, setOpen] = useState(false);
  const {
    columns,
    setAllFilters,
    state: { filters },
  } = tableInstance;
  const getNewFilters = useCallback(
    () => merge({}, ...filters.map((f) => ({ [f.id]: f.value }))),
    [filters],
  );
  const [openColumns, setOpenColumns] = useState<string[]>([]);
  const useFormInstance = useForm({
    defaultValues: getNewFilters(),
  });
  const { handleSubmit, reset, getValues } = useFormInstance;
  const filterColumnIds = columns.filter((c) => c.canFilter).map((c) => c.id);
  const appliedFiltersLength = filters.filter((key) =>
    filterColumnIds.includes(key.id),
  ).length;

  const openColumn = (id: string) => setOpenColumns([...openColumns, id]);

  const closeColumn = (id: string) =>
    setOpenColumns(openColumns.filter((c) => c !== id));

  useEffect(() => {
    setOpenColumns(filters.map((f) => f.id));
  }, [filters]);

  useEffect(() => {
    reset({
      ...getValues(),
      ...getNewFilters(),
    });
  }, [getValues, getNewFilters, reset]);

  const onSubmit = (values: FilterValue) => {
    const unProcessedData = omit(values, [PROCESS_FILTER_COLUMN]);
    const processedData = Object.assign(
      {},
      ...flatten(
        map(
          values[PROCESS_FILTER_COLUMN],
          (values, key: FilterColumnsToProcess) =>
            getProcessedFilterData(values, key),
        ),
      ),
    );
    setAllFilters(
      map(
        { ...unProcessedData, ...processedData },
        (value: FilterValue, id: IdType<D>) => ({
          id,
          value,
        }),
      ),
    );
    tableInstance.gotoPage(0);
    setOpen(false);
  };

  const handleReset = async () => {
    reset(
      Object.assign(
        {},
        getNewFilters(),
        ...filterColumnIds.map((id) => ({ [id]: undefined })),
      ),
    );
    setOpenColumns([]);
  };

  return (
    <section className='relative' title='table-options'>
      <IconButton
        leftIcon={<FontAwesomeIcon icon={faBarsFilter} title='filter' />}
        label={`${
          appliedFiltersLength ? `Applied (${appliedFiltersLength})` : 'Filters'
        }`}
        variant='outline'
        onClick={() => setOpen(!open)}
      />

      {open && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          onReset={handleReset}
          className='absolute right-0 z-10 mt-1 bg-white rounded shadow-lg w-60 ring-2 ring-gray-200'
        >
          <div className='flex flex-row items-center justify-between p-2'>
            <IconButton variant='outline' label='Clear' buttonType='reset' />
            <p className='text-gray-600'>Filters</p>
            <IconButton variant='default' label='Apply' buttonType='submit' />
          </div>
          {columns.map((column) => {
            const isColumnApplied = openColumns.includes(column.id);
            return (
              <div key={`${column.id}`}>
                {column.canFilter ? (
                  <>
                    <div className='p-2 border-t border-gray-200'>
                      <label className='flex items-center justify-start space-x-2'>
                        <input
                          key={`${column.id}-${
                            isColumnApplied ? YesNoType.YES : YesNoType.NO
                          }`}
                          type='checkbox'
                          className='w-4 h-4 border-none rounded-full ring-1 ring-gray-200 focus:outline-none focus:ring-0 text-primary'
                          onChange={() =>
                            isColumnApplied
                              ? closeColumn(column.id)
                              : openColumn(column.id)
                          }
                          checked={isColumnApplied}
                        />
                        <div>{column.render('Header')}</div>
                      </label>
                    </div>
                    {isColumnApplied && (
                      <div className='p-2 bg-gray-100'>
                        {column.render('Filter', useFormInstance)}
                      </div>
                    )}
                  </>
                ) : (
                  <div className='invisible'>
                    <ControlledTextInputV7
                      name={column.id}
                      shouldUnregister={false}
                      {...useFormInstance}
                      type='hidden'
                    />
                  </div>
                )}
              </div>
            );
          })}
        </form>
      )}
    </section>
  );
};

export default FilterOption;
