import React from 'react';
import { FaLink } from 'react-icons/fa';
import { MdOutlineLocationOn } from 'react-icons/md';
import {
  EventLocationTypeEnum,
  getEventLocationType,
} from '../../../utils/EventHelper';
import ZenButton from '../ZenButton';

interface ZenEventLocationProps {
  location: string;
}

export const ZenEventLocation: React.FC<ZenEventLocationProps> = ({
  location,
}) => {
  const locationToComponentMap: Record<
    EventLocationTypeEnum,
    React.ReactElement
  > = {
    [EventLocationTypeEnum.MEET_LINK]: (
      <ZenButton
        type='submit'
        label='Join Meet'
        variant='primary'
        onClick={() => window.open(location, '_blank')}
      />
    ),
    [EventLocationTypeEnum.ZOOM_LINK]: (
      <ZenButton
        type='submit'
        label='Join Zoom'
        variant='primary'
        onClick={() => window.open(location, '_blank')}
      />
    ),
    [EventLocationTypeEnum.NORMAL_LINK]: (
      <a
        href={location}
        className='font-zen-body font-semibold text-lg text-primary-blue flex items-center space-x-1 cursor-pointer'
        target='_blank'
        rel='noreferrer'
      >
        <FaLink fontSize={14} />
        <span>{location}</span>
      </a>
    ),
    [EventLocationTypeEnum.NON_LINK]: (
      <div className='flex space-x-1'>
        <MdOutlineLocationOn className='text-zen-dark-9 mt-0.5' fontSize={18} />
        <p className='font-zen-body font-semibold text-zen-dark-9 text-md'>
          {location}
        </p>
      </div>
    ),
  };

  return locationToComponentMap[getEventLocationType(location)];
};

export default ZenEventLocation;
