import React from 'react';
import GeminiButton, { GeminiButtonVariant } from '../GeminiButton';
import { cn } from '../../../utils/classUtils';

interface GeminiSidebarModalActionFooterProps {
  submitButtonText?: string;
  submitButtonVariant?: GeminiButtonVariant;
  cancelButtonText?: string;
  onClose(): void;
  isSubmitting: boolean;
  isDisabled?: boolean;
  alignRight?: boolean;
}

const GeminiSidebarModalActionFooter: React.FC<GeminiSidebarModalActionFooterProps> = ({
  submitButtonText = 'Save',
  submitButtonVariant = 'primary',
  cancelButtonText = 'Cancel',
  onClose,
  isSubmitting,
  isDisabled,
  alignRight = false,
}) => {
  return (
    <div className='py-6 px-5 bg-white border-t border-gray-200 flex flex-row items-center justify-end w-full gap-4 absolute left-0 bottom-0'>
      <div
        className={cn('w-full', {
          'w-[174px]': alignRight,
        })}
      >
        <GeminiButton
          type='button'
          onClick={onClose}
          label={cancelButtonText}
          variant='primary-outline'
          isFullWidth
        />
      </div>
      <div
        className={cn('w-full', {
          'w-[174px]': alignRight,
        })}
      >
        <GeminiButton
          variant={submitButtonVariant}
          isDisabled={isSubmitting || isDisabled}
          isSubmitting={isSubmitting}
          type='submit'
          label={submitButtonText}
          isFullWidth
        />
      </div>
    </div>
  );
};

export default GeminiSidebarModalActionFooter;
