import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import {
  ApiKeyControllerApi,
  GenerateApiKeyRequest,
} from '../../../openapi/keymaker';
import ErrorService from '../../../services/ErrorService';
import { fetchClientApiKeys } from '../../../slices/ClientApiKeySlice';
import { showSuccessToast } from '../../../slices/ToastNotificationSlice';
import { YesNoType } from '../../../types';
import { getKeymakerConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import GeminiFeatureFlagButton from '../../GeminiFeatureFlagButton';
import ZenDefaultError from '../../Zen/Error/ZenDefaultError';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSimpleModal from '../../Zen/Modal/ZenSimpleModal';
import ZenTermsAndCondition from '../../Zen/ZenTermsAndCondition';

interface AgentGenerateKeyFormModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  name: string;
  expirationDate: string;
  acceptTermsAndConditions: YesNoType;
}

const AgentGenerateKeyFormModal: React.FC<AgentGenerateKeyFormModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();
  const [generateApiKeyError, setGenerateApiKeyError] = useState<boolean>(
    false,
  );
  const [
    generateApiKeyErrorText,
    setGenerateApiKeyErrorText,
  ] = useState<string>('');

  const onSubmit = async (formData: FormData) => {
    const generateClientApiKeyRequest: Required<GenerateApiKeyRequest> = {
      name: formData.name,
      expirationDate: formData.expirationDate,
    };
    try {
      await new ApiKeyControllerApi(getKeymakerConfiguration()).generateApiKey(
        generateClientApiKeyRequest,
      );
      dispatch(showSuccessToast('API key generated successfully.'));
      dispatch(fetchClientApiKeys());
      setGenerateApiKeyError(false);
      setGenerateApiKeyErrorText('');
      onClose();
    } catch (e) {
      ErrorService.notify('Unable to generate client API key', e);
      setGenerateApiKeyError(true);
      setGenerateApiKeyErrorText(
        `${e?.response?.data['com.real.commons.apierror.ApiError']
          ?.message!} - ${e?.response?.data[
          'com.real.commons.apierror.ApiError'
        ]?.subErrors[0]?.message!}`,
      );
    }
  };

  return (
    <ZenSimpleModal
      isOpen={isOpen}
      onClose={onClose}
      title='Generate API Key'
      size='large'
    >
      <div className='md:px-12 px-6 py-6'>
        {generateApiKeyError && (
          <div className='mb-6'>
            <ZenDefaultError message={generateApiKeyErrorText} />
          </div>
        )}
        <p className='font-zen-body text-sm text-zen-dark-9 pb-6'>
          Provide a name to identify your API Key and set an expiration date.
          You will find your newly generated key in the Active tab.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <ZenControlledTextInput<FormData, 'name'>
              name='name'
              control={control}
              label='Name'
              isRequired
              placeholder='Name your API key'
              rules={{ required: 'API key name is required' }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledDatePickerInput
              control={control}
              label='Expiration Date'
              name='expirationDate'
              icon={
                <FontAwesomeIcon
                  icon={faCalendar}
                  size='lg'
                  className='text-primary-blue'
                />
              }
              datePickerConfig={{
                minDate: DateTime.local().plus({ day: 1 }).toJSDate(),
              }}
              placeholder='MM/DD/YYYY'
              shouldUnregister={false}
              rules={{ required: 'Required' }}
              isRequired
            />
          </div>
          <div className='mt-8'>
            <ZenTermsAndCondition<FormData, 'acceptTermsAndConditions'>
              label='By generating an API Key,'
              inputValue={YesNoType.YES}
              name='acceptTermsAndConditions'
              control={control}
              url='/terms/api-generation'
              rules={{
                validate: (val) => {
                  if (!val?.length) {
                    return 'Please accept the terms of service';
                  }

                  return undefined;
                },
              }}
            />
          </div>
          <div className='mt-10 mb-2'>
            <div className='flex items-center space-x-6'>
              <div className='flex-1 pl-10'>
                <GeminiFeatureFlagButton
                  zenVariant='primary-outline'
                  geminiVariant='primary-outline'
                  onClick={() => onClose()}
                  label='Cancel'
                  type='button'
                  isFullWidth
                />
              </div>
              <div className='flex-1 pr-10'>
                <GeminiFeatureFlagButton
                  type='submit'
                  label='Generate'
                  isFullWidth
                  isSubmitting={isSubmitting}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </ZenSimpleModal>
  );
};

export default AgentGenerateKeyFormModal;
