import { values } from 'lodash';
import React from 'react';
import { Control } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  AddGroupMemberRequestRoleEnum,
  GroupMemberResponse,
} from '../../openapi/yenta';
import { AppDispatch } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import { searchForAllRegisteredAgents } from '../../utils/TableUtils';
import ZenControlledAsyncCustomSelectInput from '../Zen/Input/ZenControlledAsyncCustomSelectInput';
import ZenControlledHTMLSelectInput from '../Zen/Input/ZenControlledHTMLSelectInput';
import { GroupMemberFormData } from './OfficeGroupCard';

interface OfficeGroupCardHeaderProps {
  isVisible: boolean;
  groupMembers?: GroupMemberResponse[];
  control: Control<GroupMemberFormData, object>;
}

const OfficeGroupCardHeader: React.FC<OfficeGroupCardHeaderProps> = ({
  isVisible,
  control,
  groupMembers,
}) => {
  const dispatch: AppDispatch = useDispatch();

  if (!isVisible) {
    return null;
  }

  return (
    <div className='flex flex-col space-y-2 p-2 border-b bg-gray-50 border-gray-200 transition ease-in-out duration-600'>
      <div>
        <ZenControlledAsyncCustomSelectInput<GroupMemberFormData, 'userId'>
          control={control}
          name='userId'
          placeholder='Search'
          label='Search Agent'
          fetchData={async (search, page) => {
            const agents = await dispatch(
              searchForAllRegisteredAgents(page, search),
            );

            return (agents || []).map((agent) => ({
              value: `${agent?.id}`,
              label: `${agent?.firstName} ${agent?.lastName} - ${agent?.emailAddress}`,
            }));
          }}
          filterOption={(option) =>
            ![
              ...(groupMembers || [])?.map(
                (agent) => agent?.userInfo?.agentId!,
              )!,
            ].includes(option?.value)
          }
          rules={{
            validate: (v) => {
              if (!v?.value) {
                return 'Please select agent';
              }
              return undefined;
            },
          }}
          isRequired
        />
      </div>
      <div>
        <ZenControlledHTMLSelectInput<GroupMemberFormData, 'role'>
          name='role'
          control={control}
          label='Role'
          placeholder='Role'
          options={[
            {
              label: 'Select Role',
              value: '',
            },
            ...values(AddGroupMemberRequestRoleEnum).map((role) => ({
              value: role,
              label: capitalizeEnum(role),
            })),
          ]}
          rules={{
            required: 'Please select role',
          }}
          isRequired
        />
      </div>
    </div>
  );
};

export default OfficeGroupCardHeader;
