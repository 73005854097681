import { faCloudArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import { ChecklistData } from '../../../routes/BrokerPowerAuditRoute';
import { getFileNameFromUrl } from '../../../utils/StringUtils';
import { isImg, isPdfOrImg } from '../../../utils/TransactionHelper';
import { cn } from '../../../utils/classUtils';
import ZenButton from '../../Zen/ZenButton';
//import RenderPDFFromUrl from './RenderPDFFromUrl';

interface PDFsPreviewProps {
  pdfUrls: ChecklistData[];
  updateDoc(
    checklistItemId: string,
    checklistItemName: string,
    docName: string,
    id: string,
  ): void;
}

const PDFsPreview: React.FC<PDFsPreviewProps> = ({ pdfUrls, updateDoc }) => {
  return (
    <div>
      {pdfUrls?.map((item, index) => {
        const canRender = isPdfOrImg(item?.documentName!);

        const fileName = getFileNameFromUrl(item?.url!) || item?.documentName!;

        return (
          <div key={item?.id} data-testid='PDFsPreview'>
            {index !== 0 && (
              <div className='p-4 flex flex-row items-center'>
                <p className='text-xl px-5'>{item?.documentName}</p>
                <div className='border border-b w-full' />
              </div>
            )}
            <Waypoint
              onEnter={() =>
                updateDoc(
                  item.checklistItemId!,
                  item.checklistItemName!,
                  item.documentName!,
                  item.id!,
                )
              }
            >
              <div
                className={cn(
                  'pt-10',
                  pdfUrls?.length - 1 === index ? 'pb-28' : 'pb-5',
                )}
              >
                {isImg(fileName) ? (
                  <img
                    src={item?.url!}
                    alt={item?.url!}
                    style={{ width: '100%', height: '100%' }}
                  />
                ) : canRender ? (
                  <iframe
                    title={item?.url!}
                    src={`${item?.url!}#zoom=100&navpanes=0`}
                    width='100%'
                    className='h-screen flex relative'
                    data-testid={item?.documentName!}
                  />
                ) : (
                  <div className='w-full h-full flex flex-col items-center justify-center'>
                    <p className='text-xl'>Unrecognized file type.</p>
                    <p className='text-sm text-gray-500 mb-3'>
                      You can download the file to view it.
                    </p>
                    <ZenButton
                      label='Download File'
                      RightIconComponent={
                        <FontAwesomeIcon icon={faCloudArrowDown} />
                      }
                      onClick={() => window.open(item.url, '_blank')}
                    />
                  </div>
                )}
              </div>
            </Waypoint>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(PDFsPreview, (prevProps, nextProps) => {
  const prevIds = prevProps.pdfUrls.map((item) => item.id);
  const nextIds = nextProps.pdfUrls.map((item) => item.id);

  return isEqual(prevIds, nextIds);
});
