import { faUserTie } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { TransactionResponse } from '../../../../openapi/arrakis';
import { EnumMap } from '../../../../types';
import { safeEnumMapGet } from '../../../../utils/EnumHelper';
import GeminiPill from '../../GeminiPill';

export enum RepresentationTypeEnum {
  Buyer = 'Buy Side Representation',
  Dual = 'Dual Representation',
  Seller = 'Listing Side Representation',
}

interface GeminiTransactionRepresentTypeProps {
  transaction: TransactionResponse;
}

const GeminiTransactionRepresentType: React.FC<GeminiTransactionRepresentTypeProps> = ({
  transaction,
}) => {
  const representationType = transaction?.transactionOwner
    ?.represents as RepresentationTypeEnum;

  const repTypeContainerStylesEnumMap: EnumMap<
    RepresentationTypeEnum,
    string
  > = {
    [RepresentationTypeEnum.Buyer]: 'bg-rezen-light-blue-100 text-primary-navy',
    [RepresentationTypeEnum.Dual]: 'bg-sangria-300 text-sangria-900',
    [RepresentationTypeEnum.Seller]: 'bg-purple-300 text-purple-600',
  };

  return (
    <GeminiPill
      icon={faUserTie}
      label={`Rep: ${representationType || 'N/A'}`}
      containerClassNames={safeEnumMapGet(
        repTypeContainerStylesEnumMap,
        representationType,
        'bg-rezen-light-blue-100 text-primary-navy',
      )}
    />
  );
};

export default GeminiTransactionRepresentType;
