export interface ZenStatusLabelWithIconProps {
  text: string;
}

const ZenStatusLabelWithIcon: React.FC<ZenStatusLabelWithIconProps> = ({
  text,
}) => {
  return (
    <div className='inline-block py-1.5 px-2.5 rounded-full bg-rezen-light-blue-100 text-primary-blue'>
      <div className='flex items-center'>
        <p className='font-zen-body font-semibold text-sm'>{text}</p>
      </div>
    </div>
  );
};

export default ZenStatusLabelWithIcon;
