import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '../../../utils/classUtils';
import { Agreement } from '../../../utils/WalletAgreementUtils';

interface WalletAccordionProps {
  agreement: Agreement;
  checkbox: JSX.Element;
  isOpen: boolean;
  onToggle: () => void;
}

const WalletAccordion: React.FC<WalletAccordionProps> = ({
  agreement,
  checkbox,
  isOpen,
  onToggle,
}) => {
  const { title, briefText, completeComponent } = agreement;

  return (
    <div className='h-auto border rounded-xl mt-4 overflow-hidden'>
      <div className='p-4'>
        <div
          className='mb-2 flex justify-between items-center cursor-pointer'
          onClick={onToggle}
        >
          <p className='font-inter font-medium text-base text-primary-dark'>
            {title}
          </p>
          <div className='w-6 h-6 flex justify-center items-center'>
            <FontAwesomeIcon
              icon={isOpen ? faChevronDown : faChevronRight}
              size='sm'
            />
          </div>
        </div>
        {isOpen ? (
          completeComponent
        ) : (
          <p className='w-11/12 font-inter text-sm font-light'>{briefText}</p>
        )}
      </div>

      <div
        className={cn('flex justify-start items-center gap-2 px-4 pb-4', {
          'pt-5 shadow-top-sm': isOpen,
        })}
      >
        {checkbox}
      </div>
    </div>
  );
};

export default WalletAccordion;
