import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenControlledProfileImageUpload from '../../Zen/Input/ZenControlledProfileImageUpload';
import ZenControlledTextAreaInput from '../../Zen/Input/ZenControlledTextAreaInput';
import ZenStickyBottomPagination from '../../Zen/ZenStickyBottomPagination';
import {
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName,
} from './ZenWelcomeOnboardingForm';
import withApplicactionFormProgress from './withWelcomeOnboardingFormProgress';

const WelcomeOnboardingProfilePic: StepByStepComponent<
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName
> = ({ form: { control }, onNext, onPrevious }) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  return (
    <div className='w-full max-w-md text-zen-dark-9 flex flex-col h-full justify-between mt-8'>
      <div>
        <p className='text-0.5xl font-medium font-zen-title text-zen-dark-9'>
          Profile Picture
        </p>
        <div className='space-y-16 mt-7'>
          <div className='flex flex-col items-center space-y-3'>
            <div className='pb-5'>
              <ZenControlledProfileImageUpload
                name='avatar'
                control={control}
                shouldUnregister={false}
                personName={userDetail?.fullName!}
              />
            </div>
          </div>
          <div className='w-full'>
            <div className='text-xl font-primary-medium'>Bio</div>
            <ZenControlledTextAreaInput<
              WelcomeOnboardingFormState,
              'agentUpdateRequest.about'
            >
              name='agentUpdateRequest.about'
              control={control}
              rows={4}
              noResize
              placeholder='Ex. I’m a real estate agent specialized in residential sales...'
              shouldUnregister={false}
            />
          </div>
        </div>
      </div>
      <ZenStickyBottomPagination
        onPrevious={onPrevious}
        onNext={onNext}
        maxWidth='xl'
      />
    </div>
  );
};

export default withApplicactionFormProgress(WelcomeOnboardingProfilePic);
