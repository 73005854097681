import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flatten, uniq } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CellProps, Column } from 'react-table';
import {
  faCircleCheck,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import {
  CheckDepositDtoTypeEnum,
  EscrowResponse,
  EscrowResponseEscrowTypeEnum,
  MonetaryAmount,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { fetchAgentsInfo } from '../../../slices/UserIdsSlice';
import { AppDispatch } from '../../../types';
import { displayFormattedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import {
  isCommissionDocumentGenerated,
  isFTNISettlementIdAvailable,
} from '../../../utils/TransactionHelper';
import AdminOnly from '../../auth/AdminOnly';
import IconButton from '../../IconButton';
import ZenFixedDataResourceIndexContainer from '../../Zen/Containers/ZenFixedDataResourceIndexContainer';
import ZenManageCommissionDeposits from '../TrustDeposits/ZenManageCommissionDeposits';
import ZenButton from '../../Zen/ZenButton';
import { OfficeResponse } from '../../../openapi/yenta';
import { isAllDepositsConfirmed } from '../../../utils/DepositsUtil';
import DateCell from '../../table/Cells/DateCell';
import { isSmScreen } from '../../../utils/BrowserUtils';
import { ZenExternalDepositLink } from '../TrustDeposits/ZenExternalDepositLink';
import ZenAddConfirmedCommissionDeposit from './ZenAddConfirmedCommissionDeposit';
import ZenCommissionDepositInstallmentsCell from './ZenCommissionDepositInstallmentsCell';
import ZenCommissionDepositInstallmentDetailsTable from './ZenCommissionDepositInstallmentDetailsTable';

interface CommissionDepositsSectionProps {
  transaction: TransactionResponse;
  office: OfficeResponse | undefined;
}

const ZenCommissionDepositsSection: React.FC<CommissionDepositsSectionProps> = ({
  transaction,
  office,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isConfirmDepositOpen, setConfirmDepositOpen] = useState<boolean>(
    false,
  );

  const commissionDeposits = useMemo(
    () =>
      transaction.escrows?.filter(
        (escrow) =>
          escrow.escrowType === EscrowResponseEscrowTypeEnum.Commission,
      ) || [],
    [transaction.escrows],
  );

  const columns: Array<Column<EscrowResponse>> = useMemo(
    () => [
      {
        Header: 'Amount',
        accessor: (d) => d['amount']?.amount,
        headerContentClassName: 'md:w-56',
        Cell: ({
          row: { original },
        }: CellProps<EscrowResponse, MonetaryAmount>) => {
          return (
            <div className='py-2 flex space-x-3 items-center text-zen-dark-9'>
              <div>
                {isAllDepositsConfirmed(original) ? (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    color='#439775'
                    size='lg'
                    data-testid='check_status'
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    color='#F84C6C'
                  />
                )}
              </div>
              <p className='text-ellipsis overflow-hidden'>
                {displayFormattedAmountWithCurrency(original.amount)}
              </p>
            </div>
          );
        },
      },
      {
        Header: 'Installments',
        accessor: 'deposits',
        headerContentClassName: 'md:w-[500px]',
        Cell: ({ row, value }) => (
          <div className='py-2 text-zen-dark-9'>
            <ZenCommissionDepositInstallmentsCell
              commissionDeposits={value!}
              escrow={row.original}
              transaction={transaction}
              row={row}
            />
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Required By',
        accessor: 'requiredByDate',
        headerContentClassName: 'invisible md:w-28',
        Cell: ({ value }) => (
          <div className='invisible py-2 text-sm text-zen-dark-9'>
            <DateCell date={value} />
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Held By',
        accessor: 'heldByReal',
        disableFilters: true,
        headerContentClassName: 'invisible md:w-28',
        Cell: ({ row }) => (
          <p className='invisible md:whitespace-nowrap py-2 text-zen-dark-9'>
            {row.original.heldByReal ? 'Real Brokerage' : 'Other Brokerage'}
          </p>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Notes',
        accessor: 'note',
        disableFilters: true,
        headerContentClassName: 'md:w-60',
        Cell: ({ value }) => (
          <p className='py-2 font-regular font-zen-body text-zen-dark-9'>
            {value || 'N/A'}
          </p>
        ),
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const hiddenColumns = useMemo(() => {
    const cols = [];
    // hide invisible columns in mobile view
    if (isSmScreen()) {
      cols.push('heldByReal', 'requiredByDate');
    }
    return cols;
  }, []);

  const agentIds = useMemo(() => {
    const confirmedByIds = uniq(
      flatten(
        (commissionDeposits || []).map((desposit) =>
          (desposit?.deposits || []).map((d) => d?.confirmedBy!),
        ),
      ).filter((deposit) => !!deposit),
    );

    const commissionDepositsCreatedByIds = uniq(
      (commissionDeposits || [])
        ?.map((desposit) => desposit?.createdBy!)
        ?.filter((id) => !!id),
    );

    const installmentCreatedByIds = uniq(
      flatten(
        (commissionDeposits || []).map((desposit) =>
          (desposit?.deposits || []).map((d) => d?.createdBy!),
        ),
      ).filter((id) => !!id),
    );

    return uniq([
      ...confirmedByIds,
      ...commissionDepositsCreatedByIds,
      ...installmentCreatedByIds,
    ]);
  }, [commissionDeposits]);

  useEffect(() => {
    if (agentIds && agentIds.length > 0) {
      dispatch(fetchAgentsInfo(agentIds));
    }
  }, [agentIds, dispatch]);

  return (
    <div className='w-full p-4'>
      <div className='flex flex-col md:flex-row flex-wrap md:justify-between md:items-center space-y-3 md:space-y-0 py-3'>
        <p className='font-semibold text-xl text-zen-dark-9'>
          Commission Deposits
        </p>
        <div className='flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-3'>
          {isFTNISettlementIdAvailable(office) && (
            <ZenExternalDepositLink
              transaction={transaction}
              depositType={CheckDepositDtoTypeEnum.Commission}
            />
          )}
          {isCommissionDocumentGenerated(transaction) && (
            <AdminOnly>
              <IconButton
                label='Add Confirmed Deposit'
                buttonStyle='border-primary-blue ring-primary-blue hover:bg-primary-blue hover:bg-opacity-10 px-2 py-1'
                textStyle='!text-primary-blue'
                variant='outline'
                leftIcon={
                  <FontAwesomeIcon
                    icon={regular('plus')}
                    size='sm'
                    className='text-primary-blue mb-0.5'
                  />
                }
                onClick={() => setConfirmDepositOpen(true)}
              />
            </AdminOnly>
          )}
          <ZenButton
            label='Manage Commission Deposits'
            onClick={() => setIsOpen(true)}
            LeftIconComponent={
              <FontAwesomeIcon
                icon={regular('gear')}
                className='text-white mt-1 mr-1'
              />
            }
          />
        </div>
      </div>
      <div className='table-align-top'>
        <ZenFixedDataResourceIndexContainer<EscrowResponse>
          columns={columns}
          data={commissionDeposits}
          resourceName='Commission Deposit'
          hiddenColumns={hiddenColumns}
          hidePagination
          hideFilters
          renderToggleRowComponent={(row) => (
            <ZenCommissionDepositInstallmentDetailsTable
              row={row}
              transaction={transaction}
            />
          )}
        />
        <ZenManageCommissionDeposits
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          depositPayments={commissionDeposits}
          transaction={transaction}
        />
        {isConfirmDepositOpen && (
          <ZenAddConfirmedCommissionDeposit
            isOpen={isConfirmDepositOpen}
            onClose={() => setConfirmDepositOpen(false)}
            transaction={transaction}
          />
        )}
      </div>
    </div>
  );
};

export default ZenCommissionDepositsSection;
