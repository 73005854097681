import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import React from 'react';
import { useSelector } from 'react-redux';
import Warning from '../../../assets/img/warning.svg';
import useAgentsInfo from '../../../hooks/useAgentsInfo';
import { TransactionResponse } from '../../../openapi/arrakis';
import { RootState } from '../../../types';

interface MarkAsCompliantStatusProps {
  transaction: TransactionResponse;
}

const MarkAsCompliantStatus: React.FC<MarkAsCompliantStatusProps> = ({
  transaction,
}) => {
  const { agentById } = useSelector((state: RootState) => state.userIds);
  const agentId = transaction?.complianceApprovedBy;
  const agent = agentById[agentId!];

  useAgentsInfo([agentId!]);

  return (
    <div className='flex md:flex-row flex-col'>
      {!!transaction.compliantAt ? (
        <div className='text-green-500 font-primary-medium flex flex-row space-x-1 items-start mt-2'>
          <FontAwesomeIcon icon={faCheckCircle} />
          <p className='ml-2 text-sm'>
            Compliant. Verified on{' '}
            {DateTime.fromMillis(transaction.compliantAt!).toFormat(
              'LL/dd/yyyy',
            )}{' '}
            by {agent && `${agent?.firstName} ${agent?.lastName}`}
          </p>
        </div>
      ) : (
        <div className='text-yellow-500 font-primary-medium flex flex-row space-x-1 items-start mt-2 '>
          <img src={Warning} alt='warning' />
          <p>Compliance not verified.</p>
        </div>
      )}
    </div>
  );
};

export default MarkAsCompliantStatus;
