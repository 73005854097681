import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ResourceContainer from '../components/ResourceContainer';
import { fetchCDAFormDetail } from '../slices/CDAFormSlice';
import { AppDispatch, RootState } from '../types';
// import ScanQRCode from '../components/ScanQRCode';
import AuthorizationContainer from '../components/auth/AuthorizationContainer';
import CanadaCDA from '../components/cda/CanadaCDA';
import UnitedStatesCDA from '../components/cda/UnitedStatesCDA';
import DivContainer from '../components/DivContainer';
import FormPaymentParticipantsSection from '../components/FormPaymentParticipantsSection';
import PagedPrint from '../components/PagedPrint';
import {
  CommissionDocumentResponseCountryEnum,
  TransactionControllerApi,
  TransactionResponse,
} from '../openapi/arrakis';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { navigateTo500ErrorPage } from '../utils/BrowserUtils';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';

export interface CDAFormProps {
  commissionDocumentId: string;
  participantId?: string;
  isPdf: boolean;
}

const CDAForm: React.FC<CDAFormProps> = ({
  commissionDocumentId,
  participantId,
  isPdf,
}) => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { cdaFormDetailResponse } = useSelector(
    (state: RootState) => state.cdaForm,
  );
  const Container = isPdf ? PagedPrint : DivContainer;
  const cdaFormDetail = cdaFormDetailResponse.data;
  const isCanada =
    cdaFormDetail?.country === CommissionDocumentResponseCountryEnum.Canada;
  const [isLoading, setIsLoading] = useState<boolean>(isCanada);
  const [transaction, setTransaction] = useState<TransactionResponse>();

  const fetchCDData = useCallback(async () => {
    setIsLoading(true);

    const commissionDocRes = await dispatch(
      fetchCDAFormDetail(commissionDocumentId),
    );

    if (!commissionDocRes && isPdf) {
      navigateTo500ErrorPage(history);
    } else if (!!commissionDocRes && !!commissionDocRes?.transactionCode) {
      try {
        const { data } = await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).getTransactionByCode(commissionDocRes?.transactionCode);
        setTransaction(data);
      } catch (e) {
        dispatch(showApiErrorModal(e));
      }
    }

    setIsLoading(false);
  }, [commissionDocumentId, dispatch, history, isPdf]);

  useEffect(() => {
    fetchCDData();
  }, [fetchCDData]);

  return (
    <AuthorizationContainer asyncResponse={cdaFormDetailResponse}>
      <ResourceContainer
        loading={cdaFormDetailResponse.loading || isLoading}
        isEmpty={!cdaFormDetail}
        errorCode={cdaFormDetailResponse.error}
        resourceName='Commission Disbursement Authorization'
      >
        <Container>
          {!!cdaFormDetail && !!transaction && (
            <div>
              {isCanada ? (
                <CanadaCDA
                  cdaFormDetail={cdaFormDetail}
                  participantId={participantId}
                  transaction={transaction}
                  isPdf={isPdf}
                />
              ) : (
                <>
                  <UnitedStatesCDA
                    cdaFormDetail={cdaFormDetail}
                    transaction={transaction}
                    isPdf={isPdf}
                  />
                  {!isPdf && (
                    <FormPaymentParticipantsSection
                      paymentParticipants={
                        cdaFormDetail?.paymentParticipants || []
                      }
                      doubleEnderCommissionInfo={
                        cdaFormDetail.doubleEnderCommissionInfo
                      }
                      transaction={transaction}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </Container>
      </ResourceContainer>
    </AuthorizationContainer>
  );
};

export default CDAForm;
