import { InvoiceResponseStatusEnum } from '../../../openapi/arrakis';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumMultiSelectColumnGenerator from './EnumMultiSelectColumnGenerator';

export interface InvoiceSelectColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const InvoiceStatusSelectColumnFilter = <D extends object>(
  props: InvoiceSelectColumnFilterProps<D>,
) => {
  return EnumMultiSelectColumnGenerator<D>(InvoiceResponseStatusEnum)(props);
};

export default InvoiceStatusSelectColumnFilter;
