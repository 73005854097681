import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { cn } from '../../utils/classUtils';
import { BankReportSummaryDtoStatusEnum } from '../../openapi/atlantis';
import { EnumMap } from '../../types';
import { safeEnumMapGet } from '../../utils/EnumHelper';
import { capitalizeEnum } from '../../utils/StringUtils';

type MortgageStatusVariantType = 'default' | 'primary' | 'success' | 'danger';

export const variantToClassNameMap: Partial<
  EnumMap<MortgageStatusVariantType, string>
> = {
  default: 'bg-white text-black border border-black',
  primary: 'bg-regent-200 text-primary-blue border border-primary-blue',
  success: 'bg-green-100 text-green-600 border border-green-600',
  danger: 'bg-zen-danger-light text-error border border-error',
};

export const reportStatusToVariant: EnumMap<
  BankReportSummaryDtoStatusEnum,
  MortgageStatusVariantType
> = {
  [BankReportSummaryDtoStatusEnum.Success]: 'success',
  [BankReportSummaryDtoStatusEnum.InProgress]: 'primary',
  [BankReportSummaryDtoStatusEnum.Failure]: 'danger',
};

export interface MortgageStatusProps {
  status: BankReportSummaryDtoStatusEnum;
}

const MortgageReportStatus: React.FC<MortgageStatusProps> = ({ status }) => {
  const mortgageReportStatusIconMap: EnumMap<
    BankReportSummaryDtoStatusEnum,
    React.ReactElement
  > = {
    [BankReportSummaryDtoStatusEnum.Success]: (
      <FontAwesomeIcon icon={regular('circle-check')} />
    ),
    [BankReportSummaryDtoStatusEnum.InProgress]: (
      <FontAwesomeIcon icon={regular('circle')} />
    ),
    [BankReportSummaryDtoStatusEnum.Failure]: (
      <FontAwesomeIcon icon={regular('xmark')} />
    ),
  };

  const variant = safeEnumMapGet(reportStatusToVariant, status, 'default');

  const icon = safeEnumMapGet(
    mortgageReportStatusIconMap,
    status,
    mortgageReportStatusIconMap['IN_PROGRESS'],
  );

  return (
    <div className='flex items-center justify-between'>
      <div
        className={cn(
          'w-max flex items-center space-x-1.5 flex-shrink-0 py-1.5 px-2 mr-1 rounded-full md:whitespace-nowrap',
          variantToClassNameMap[variant],
        )}
      >
        {icon}
        <span className='-mt-0.5 font-zen-body text-sm font-medium'>
          {capitalizeEnum(status)}
        </span>
      </div>
    </div>
  );
};

export default MortgageReportStatus;
