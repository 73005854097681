import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { EnumMap } from '../../../types';
import { InstantPaymentResponseStatusEnum } from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import ZenStatusPill, { PillVariantType } from '../ZenStatusPill';

export interface ZenInstantPaymentStatusCellProps {
  status: InstantPaymentResponseStatusEnum;
}

export const instantPaymentStatusToVariant: EnumMap<
  InstantPaymentResponseStatusEnum,
  PillVariantType
> = {
  [InstantPaymentResponseStatusEnum.Repaid]: 'success',
  [InstantPaymentResponseStatusEnum.PartiallyRepaid]: 'success',
  [InstantPaymentResponseStatusEnum.InProgress]: 'warning',
  [InstantPaymentResponseStatusEnum.Disbursed]: 'danger',
};

export const instantPaymentStatusToIcon: EnumMap<
  InstantPaymentResponseStatusEnum,
  React.ReactElement
> = {
  [InstantPaymentResponseStatusEnum.Repaid]: (
    <FontAwesomeIcon icon={faCheckCircle} />
  ),
  [InstantPaymentResponseStatusEnum.PartiallyRepaid]: (
    <FontAwesomeIcon icon={faCheckCircle} />
  ),
  [InstantPaymentResponseStatusEnum.InProgress]: (
    <FontAwesomeIcon icon={faCheckCircle} />
  ),
  [InstantPaymentResponseStatusEnum.Disbursed]: (
    <FontAwesomeIcon icon={faCheckCircle} />
  ),
};

const ZenInstantPaymentStatusCell: React.FC<ZenInstantPaymentStatusCellProps> = ({
  status,
}) => {
  const variant: PillVariantType = safeEnumMapGet(
    instantPaymentStatusToVariant,
    status,
    'warning',
  );
  const icon: React.ReactElement = safeEnumMapGet(
    instantPaymentStatusToIcon,
    status,
    <FontAwesomeIcon icon={faCheckCircle} />,
  );

  return (
    <ZenStatusPill
      text={capitalizeEnum(status || '')}
      variant={variant!}
      icon={icon!}
    />
  );
};

export default ZenInstantPaymentStatusCell;
