import { createAction } from 'kbar';
import { useDispatch } from 'react-redux';
import { saveTheme } from '../../../slices/KBarSlice';
import { showSuccessToast } from '../../../slices/ToastNotificationSlice';
import { KBarThemeEnum } from '../../../types';

const useThemeActions = () => {
  const dispatch = useDispatch();

  const themeActions = [
    {
      id: 'themeActions',
      name: 'Change Theme',
      shortcut: ['?'],
      keywords: 'preferences themes',
      section: 'Preferences',
    },
    {
      id: 'classicTheme',
      name: 'Classic',
      parent: 'themeActions',
      keywords: 'classic theme',
      section: 'Choose Theme',
      perform: () => {
        dispatch(saveTheme(KBarThemeEnum.CLASSIC));
        dispatch(showSuccessToast('Command Bar Theme Changed to Classic'));
      },
    },
    {
      id: 'oceanTheme',
      name: 'Ocean',
      parent: 'themeActions',
      keywords: 'ocean theme',
      section: 'Choose Theme',
      perform: () => {
        dispatch(saveTheme(KBarThemeEnum.OCEAN));
        dispatch(showSuccessToast('Command Bar Theme Changed to Ocean'));
      },
    },
  ];

  return themeActions.map((action) => createAction(action));
};

export default useThemeActions;
