import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import {
  FrontLineAgentResponse,
  FrontLineAgentResponseStatusEnum,
} from '../../openapi/arrakis';
import { EnumMap, RootState } from '../../types';
import ZenAvatar from '../Zen/ZenAvatar';
import Hover from '../Hover';
import { capitalizeEnum, formatPhoneNumber } from '../../utils/StringUtils';
import { getCommissionAmount, getFullName } from '../../utils/AgentHelper';
import ZenStatusPill, { PillVariantType } from '../Zen/ZenStatusPill';
import { safeEnumMapGet } from '../../utils/EnumHelper';
import { cn } from '../../utils/classUtils';

const statusToPillVariantMap: EnumMap<
  FrontLineAgentResponseStatusEnum,
  PillVariantType
> = {
  [FrontLineAgentResponseStatusEnum.Candidate]: 'primary',
  [FrontLineAgentResponseStatusEnum.Active]: 'success',
  [FrontLineAgentResponseStatusEnum.Inactive]: 'danger',
  [FrontLineAgentResponseStatusEnum.Rejected]: 'danger',
};

interface RevShareInsightsNetworkRowProps {
  agent: FrontLineAgentResponse;
  hideExtraInfo?: boolean;
}

const RevShareInsightsNetworkRow: React.FC<RevShareInsightsNetworkRowProps> = ({
  agent,
  hideExtraInfo = false,
}) => {
  const {
    detailResponse: { data },
  } = useSelector((state: RootState) => state.agentDetail);
  const safeSponsorSplit = agent.sponsorSplit === 100 ? 100 : 50;
  const name = getFullName(agent) || 'N/A';

  return (
    <div
      className='py-3 flex justify-between items-center'
      aria-label='network-agent-row'
    >
      <div className='flex items-center'>
        <ZenAvatar name={name} imageUrl={agent.avatar} size='md' />

        <div className='px-3'>
          <p className='font-zen-body text-zen-dark-9 text-base font-medium'>
            {name}
          </p>

          {!hideExtraInfo && (
            <div className='flex items-center font-zen-body font-normal space-x-2'>
              <Hover
                hoverComponent={
                  <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                    {agent.unlocking
                      ? `Is a tier-unlocking agent: the agent is active and has at least ${getCommissionAmount(
                          data?.accountCountry!,
                        )} in closed commission in the last 6 months.`
                      : `Is not a tier-unlocking agent: either the agent is inactive or does not have at least ${getCommissionAmount(
                          data?.accountCountry!,
                        )} in closed commission in the last 6 months.`}
                  </div>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <FontAwesomeIcon
                  icon={
                    agent.unlocking ? solid('unlock-keyhole') : solid('lock')
                  }
                  className={cn(
                    'cursor-pointer',
                    agent.unlocking ? 'text-green-600' : 'text-gray-300',
                  )}
                  aria-label='lockUnlockIcon'
                />
              </Hover>
              {agent.unlocking && agent.unlockingExpiryDate && (
                <Hover
                  hoverComponent={
                    <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                      Date when tier-unlocking status expires.
                    </div>
                  }
                  config={{ trigger: 'hover', placement: 'top' }}
                >
                  <p className='font-normal text-sm text-zen-dark-7 opacity-80'>
                    Exp:{' '}
                    {DateTime.fromISO(agent.unlockingExpiryDate).toFormat(
                      'LL/dd/yy',
                    )}
                  </p>
                </Hover>
              )}
              <FontAwesomeIcon
                icon={solid('circle')}
                className='text-zen-dark-12'
                fontSize={4}
              />
              <p className='font-normal text-sm text-zen-dark-7 opacity-80'>
                {safeSponsorSplit}%
              </p>
              {!agent.unlocking && (
                <ZenStatusPill
                  text={capitalizeEnum(agent.status!)}
                  variant={safeEnumMapGet(
                    statusToPillVariantMap,
                    agent.status!,
                    'primary',
                  )}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div className='flex items-center space-x-5'>
        <Hover
          hoverComponent={
            <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
              {agent.emailAddress || 'N/A'}
            </div>
          }
          config={{ trigger: 'hover', placement: 'top' }}
        >
          <FontAwesomeIcon
            icon={regular('envelope')}
            title='email'
            aria-label='emailIcon'
            className='cursor-pointer text-lg text-primary-blue'
          />
        </Hover>
        <Hover
          hoverComponent={
            <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
              {formatPhoneNumber(agent.phoneNumber) || 'N/A'}
            </div>
          }
          config={{ trigger: 'hover', placement: 'top' }}
        >
          <FontAwesomeIcon
            icon={regular('phone')}
            title='phone'
            aria-label='phoneIcon'
            className='cursor-pointer text-lg text-primary-blue'
          />
        </Hover>
      </div>
    </div>
  );
};

export default RevShareInsightsNetworkRow;
