import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { useForm } from 'react-hook-form-v7';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import {
  SentimentDisplayRequestContextTypeEnum,
  TransactionLifecycleStateValue,
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../../../openapi/arrakis';
import {
  getSentimentDisplayStatus,
  transitionTransaction,
} from '../../../../slices/TransactionSlice';
import { AppDispatch, FeatureFlagTypeEnum } from '../../../../types';
import {
  getTransactionTransitionToReadableName,
  getTransitionModalSubTitle,
} from '../../../../utils/TransactionHelper';
import ControlledDatePickerInputV7 from '../../../ControlledDatePickerInputV7';
import ZenSimpleConfirmationModal, {
  ZenModalVariantType,
} from '../../Modal/ZenSimpleConfirmationModal';
import {
  UNVERIFIED_COMPLIANCE_CDA,
  UNVERIFIED_COMPLIANCE_HEADLINE,
} from '../../../../constants/TransactionConstants';
import { pillVariantTypeToClassNameMap } from '../../ZenStatusPill';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';

interface ZenTransitionConfirmationModalProps {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
  activeTransition: TransactionLifecycleStateValue | undefined;
  variant?: ZenModalVariantType;
}

interface FormData {
  transactionClosedOn?: string;
}

const ZenTransitionConfirmationModal: React.FC<ZenTransitionConfirmationModalProps> = ({
  isOpen,
  onClose,
  transaction,
  activeTransition,
  variant = 'info',
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const isDoubleEnderEnabled = useFeatureFlag(FeatureFlagTypeEnum.DOUBLE_ENDER);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      transactionClosedOn: transaction.skySlopeActualClosingDate
        ? DateTime.fromISO(transaction.skySlopeActualClosingDate).toISODate()
        : '',
    },
    reValidateMode: 'onChange',
  });
  const onSubmit = async (formData: FormData) => {
    await dispatch(
      transitionTransaction(
        transaction,
        activeTransition?.state!,
        {
          transactionClosedOn: formData.transactionClosedOn || '',
        },
        { [FeatureFlagTypeEnum.DOUBLE_ENDER]: isDoubleEnderEnabled },
      ),
    );
    dispatch(
      getSentimentDisplayStatus({
        contextId: transaction.id!,
        contextType: SentimentDisplayRequestContextTypeEnum.Transaction,
        contextState: transaction.lifecycleState?.state,
      }),
    );
    onClose();
  };
  const isWarningAlertVisible =
    !!transaction.office?.splitCheck &&
    !transaction.compliantAt &&
    activeTransition?.state ===
      TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved;

  return (
    <ZenSimpleConfirmationModal
      isOpen={isOpen}
      variant={variant}
      title={
        getTransactionTransitionToReadableName(
          transaction,
          activeTransition?.state!,
        ) || ''
      }
      subtitle={getTransitionModalSubTitle(transaction, activeTransition)}
      onClose={onClose}
      size={isWarningAlertVisible ? 'large' : 'medium'}
      isSubmitting={isSubmitting}
      onConfirm={handleSubmit(onSubmit)}
      confirmButtonText='Yes, please continue'
    >
      {activeTransition?.state ===
        TransactionLifecycleStateValueStateEnum.ApprovedForClosing && (
        <div className='mt-2 text-black'>
          <ControlledDatePickerInputV7<FormData, 'transactionClosedOn'>
            datePickerConfig={{
              maxDate: new Date(),
            }}
            control={control}
            name='transactionClosedOn'
            label='Actual Closing Date'
            rules={{
              required: 'Please enter the date the transaction closed.',
            }}
          />
        </div>
      )}
      {isWarningAlertVisible && (
        <div className='bg-white rounded my-2'>
          <div
            className={classNames(
              pillVariantTypeToClassNameMap['warning'],
              'p-2',
            )}
          >
            <div className='flex flex-row space-x-2'>
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className='mt-0.5'
              />
              <p className='font-primary-medium'>
                {UNVERIFIED_COMPLIANCE_HEADLINE}
              </p>
            </div>
            <p className='pl-7 text-sm'>{UNVERIFIED_COMPLIANCE_CDA}</p>
          </div>
        </div>
      )}
    </ZenSimpleConfirmationModal>
  );
};

export default ZenTransitionConfirmationModal;
