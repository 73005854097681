import React from 'react';
import { Control } from 'react-hook-form-v7';
import { MONEY_VALIDATIONS, NUMBER_VALIDATIONS } from '../../utils/Validations';
import ZenControlledCurrencyInput from '../Zen/Input/ZenControlledCurrencyInput';
import ZenControlledPercentageInput from '../Zen/Input/ZenControlledPercentageInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import { CreateJointVentureFormState } from './CreateJointVentureSteps';

export interface RealPoolShareDistributionCardProps {
  title: string;
  control: Control<any, object>;
}

const RealPoolShareDistributionCard: React.FC<RealPoolShareDistributionCardProps> = ({
  title,
  control,
}) => {
  return (
    <div className='rounded-lg border border-zen-dark-4 bg-white overflow-hidden'>
      <div className='flex flex-row items-center justify-between p-5 bg-zen-light-blue'>
        <span className='font-zen-title font-medium text-xl leading-5 text-zen-dark-9'>
          {title}
        </span>
      </div>
      <div className='p-5 w-full flex md:flex-row flex-col md:space-x-3 md:space-y-0 space-x-0 space-y-3'>
        <ZenControlledTextInput<CreateJointVentureFormState, 'shares'>
          control={control}
          name='shares'
          label='Total Shares Available'
          type='number'
          rules={{
            ...NUMBER_VALIDATIONS,
            required: 'Total Shares is required',
          }}
          shouldUnregister={false}
        />
        <ZenControlledPercentageInput<
          CreateJointVentureFormState,
          'realPoolPercentShare'
        >
          control={control}
          label='Real Pool Share (%)'
          name='realPoolPercentShare'
          rules={{
            ...NUMBER_VALIDATIONS,
            min: {
              value: 0,
              message: 'Number cannot be less than 0',
            },
            max: {
              value: 100,
              message: 'Number cannot be greater than 100',
            },
            required: 'Real Pool Share (%) is required',
          }}
          shouldUnregister={false}
        />
        <ZenControlledCurrencyInput<CreateJointVentureFormState, 'issuePrice'>
          control={control}
          name='issuePrice'
          startAdornment={
            <div className='font-zen-body text-base font-medium h-full pl-3 flex items-center justify-center'>
              $
            </div>
          }
          shouldUnregister={false}
          rules={{
            ...MONEY_VALIDATIONS,
            required: 'Share price is required',
          }}
          label='Share Price'
        />
      </div>
    </div>
  );
};

export default RealPoolShareDistributionCard;
