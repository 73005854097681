import { FilterProps } from 'react-table';
import { PROCESS_FILTER_COLUMN } from '../../../utils/TableUtils';
import { FilterColumnsToProcess } from '../../../types';
import { UUID_RE } from '../../../utils/StringUtils';
import ControlledTextInputV7 from '../../ControlledTextInputV7';

export interface TextStrictCaseColumnFilterProps<D extends object>
  extends FilterProps<D> {}

const TextStrictCaseColumnFilter = <D extends object>({
  column,
  control,
}: TextStrictCaseColumnFilterProps<D>): React.ReactElement => {
  const prefixFieldName = `${PROCESS_FILTER_COLUMN}.${FilterColumnsToProcess.STRICT_CASE}.${column.id}`;

  return (
    <ControlledTextInputV7
      control={control}
      name={prefixFieldName}
      shouldUnregister={false}
      placeholder={`Enter ${column.render('Header')}...`}
      rules={{
        required: `${column.render('Header')} is required`,
        pattern: {
          value: UUID_RE,
          message: 'Please enter a valid ID',
        },
      }}
      defaultValue={column.filterValue?.value}
    />
  );
};

export default TextStrictCaseColumnFilter;
