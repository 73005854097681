import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, createRef, Fragment } from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

interface ProfileScoreItem {
  label: string;
  completed: boolean;
  rightAction?: RightAction;
  rightActionUpload?: RightActionUpload;
}

interface RightAction {
  text: string;
  onClick(): void;
  icon?: React.ReactElement;
}

interface RightActionUpload {
  text: string;
  onClick(e: ChangeEvent<HTMLInputElement>): void;
  icon?: React.ReactElement;
}

interface Props {
  profileScoreItems: ProfileScoreItem[];
}

const ZenProfileProgressRows: React.FC<Props> = ({ profileScoreItems }) => {
  const pathLength = profileScoreItems.length;
  const hiddenFileInputRef = createRef<HTMLInputElement>();

  const handleFileUpload = () => {
    hiddenFileInputRef.current?.click();
  };

  return (
    <div className='flex flex-col'>
      {profileScoreItems.map(
        ({ label, completed, rightAction, rightActionUpload }, index) => {
          return (
            <Fragment key={label}>
              <div className='flex flex-row flex-grow flex-shrink justify-between items-center'>
                <span className='flex flex-row items-center font-zen-body font-normal text-base text-zen-dark-7 px-4 py-2'>
                  <span className='mr-2'>{label}</span>
                  {completed ? (
                    <FontAwesomeIcon
                      data-testid='complete'
                      icon={faBadgeCheck}
                      size='lg'
                      className='text-green-600'
                    />
                  ) : (
                    <HiOutlineExclamationCircle
                      size={22}
                      data-testid='incomplete'
                      className='text-zen-danger'
                    />
                  )}
                </span>
                {rightAction && (
                  <button onClick={rightAction.onClick}>
                    <div className='flex flex-row items-center space-x-1 mx-2'>
                      <h6 className='font-zen-body font-semibold text-sm text-primary-blue'>
                        {rightAction.text}
                      </h6>
                      {rightAction.icon}
                    </div>
                  </button>
                )}

                {rightActionUpload && (
                  <button onClick={handleFileUpload}>
                    <div className='flex flex-row items-center space-x-1 mx-2'>
                      <h6 className='font-zen-body font-semibold text-sm text-primary-blue'>
                        {rightActionUpload.text}
                      </h6>
                      {rightActionUpload.icon}
                      <input
                        type='file'
                        className='hidden'
                        ref={hiddenFileInputRef}
                        aria-label='uploadFile'
                        onChange={rightActionUpload.onClick}
                      />
                    </div>
                  </button>
                )}
              </div>
              {pathLength - 1 !== index && <div className='border-b' />}
            </Fragment>
          );
        },
      )}
    </div>
  );
};

export default ZenProfileProgressRows;
