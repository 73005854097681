import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ItemResponse } from '../../../openapi/sherlock';
import { RezenObjectTypeEnum } from '../../../openapi/yada';
import { RootState } from '../../../types';
import { getFullName } from '../../../utils/AgentHelper';
import { getYentaImageUrl } from '../../../utils/ImgUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ProfileImage from '../../ProfileImage';
import ExcludeAgent from '../../auth/ExcludeAgent';
import ExcludeBroker from '../../auth/ExcludeBroker';
import ZenToggleRow from '../Input/ZenToggleRow';
import ZenJourneyStatusIcon from './ZenJourneyStatusIcon';
import { ChecklistType } from './ZenVerticalJourneyStepProgress';
import ZenAgentJourneyDescription from './ZenAgentJourneyDescription';

interface ZenRoadToSuccessJourneyStepProps {
  containerType: RezenObjectTypeEnum;
  step: ItemResponse;
  isLastStep: boolean;
  checklistType: ChecklistType;
  handleToggleJourneyItem(stepId: string, toggleState: boolean): Promise<void>;
}

const ZenRoadToSuccessJourneyStep: React.FC<ZenRoadToSuccessJourneyStepProps> = ({
  containerType,
  step,
  isLastStep,
  handleToggleJourneyItem,
  checklistType,
}) => {
  const {
    userIds: { agentById },
  } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState(false);
  const fullName = getFullName(agentById[step.assignee!]);
  const markedFullName = getFullName(agentById[step?.markedCompletedBy!]);
  const isStepCompleted = step.complete && !!step.completedAt;

  const ToggleWrapper =
    checklistType === ChecklistType.AGENT_JOURNEY
      ? ExcludeBroker
      : ExcludeAgent;

  const updateJourneyProgress = async () => {
    setLoading(true);
    await handleToggleJourneyItem(step.id!, !step.complete);
    setLoading(false);
  };

  return (
    <li
      key={step.name}
      className={classNames('relative flex items-center', {
        'pb-10': !isLastStep,
      })}
    >
      <div className='flex-grow'>
        {!isLastStep && (
          <div
            className={classNames(
              'ml-10 absolute w-2 h-full pb-2 bg-zen-light-gray-2 top-11',
            )}
          />
        )}
        <div className='relative flex items-center ml-12'>
          <div className='absolute flex items-center justify-center'>
            <ZenJourneyStatusIcon status={step.status} />
          </div>
          <div className='mx-10 min-w-[32rem] w-full max-w-lg flex flex-col border border-zen-dark-4 rounded-lg px-8 py-4'>
            <span className='text-base font-zen-body font-semibold text-zen-dark-9 whitespace-pre-wrap'>
              {step.name}
            </span>
            {checklistType === ChecklistType.AGENT_JOURNEY &&
              step?.description && (
                <ZenAgentJourneyDescription description={step?.description} />
              )}
            {isStepCompleted &&
              checklistType === ChecklistType.ROAD_TO_SUCCESS && (
                <div className='flex font-zen-body text-sm text-zen-dark-7 pt-1'>
                  <span className='font-semibold whitespace-pre-wrap'>
                    Completed:{' '}
                  </span>
                  <span className={classNames('font-normal  text-gray-500')}>
                    {DateTime.fromMillis(step.completedAt!).toFormat(
                      'DDDD hh:mm a ZZZZ',
                    )}
                  </span>
                </div>
              )}
            {checklistType === ChecklistType.ROAD_TO_SUCCESS && (
              <ExcludeAgent>
                {isStepCompleted && !!markedFullName && (
                  <div className='flex flex-row items-center font-zen-body font-normal text-sm text-zen-dark-7 pt-1 space-x-1'>
                    <div>by</div>
                    <ProfileImage
                      imageUrl={getYentaImageUrl(
                        agentById[step.markedCompletedBy!]?.avatar,
                      )}
                      width={17}
                      variant='circle'
                    />
                    <div>{markedFullName}</div>
                  </div>
                )}
                {!isStepCompleted && !!fullName && (
                  <div className='flex flex-row items-center font-zen-body font-normal text-sm text-zen-dark-7 pt-1 space-x-1'>
                    <div>{capitalizeEnum(step?.status)} by</div>
                    <ProfileImage
                      imageUrl={getYentaImageUrl(
                        agentById[step.assignee!]?.avatar,
                      )}
                      width={17}
                      variant='circle'
                    />
                    <div>{fullName}</div>
                  </div>
                )}
              </ExcludeAgent>
            )}
          </div>
          {containerType !== RezenObjectTypeEnum.Borrower && (
            <ToggleWrapper>
              <div className='ml-2'>
                <div className='w-48'>
                  <ZenToggleRow
                    loading={loading}
                    value={step.complete!}
                    onChange={updateJourneyProgress}
                    title={step.complete ? 'Complete' : 'Incomplete'}
                  />
                </div>
              </div>
            </ToggleWrapper>
          )}
        </div>
      </div>
    </li>
  );
};

export default ZenRoadToSuccessJourneyStep;
