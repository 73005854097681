import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form-v7';
import { GiAnticlockwiseRotation } from 'react-icons/gi';
import { HiOutlineCloudArrowDown } from 'react-icons/hi2';
import { Column } from 'react-table';
import { v4 as uuid } from 'uuid';
import {
  CHECKLIST_ALLOWED_FILE_TYPES,
  MAX_DOC_SIZE_100MB,
} from '../../../../constants/FilesConstants';
import useAgentsInfo from '../../../../hooks/useAgentsInfo';
import { FileResponse, FileVersionDto } from '../../../../openapi/dropbox';
import ZenControlledDraggableDocumentUploadInput from '../../Input/ZenControlledDraggableDocumentUploadInput';
import ZenControlledTextAreaInput from '../../Input/ZenControlledTextAreaInput';
import { ReactComponent as NewFile } from '../../../../assets/img/file-new.svg';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenFixedDataTable from '../../ZenFixedDataTable';
import ZenSidebarModalForm from '../../ZenSidebarModalForm';
import ZenIconCircleWithTooltipCell from '../ZenIconCircleWithTooltipCell';
import ZenFileCabinetFileNameCell from './ZenFileCabinetFileNameCell';

interface FileCabinetEditDocumentProps {
  isOpen: boolean;
  onClose(): void;
  file?: FileResponse;
  handleEdit(formData: FCFormData): Promise<void>;
  handleOpenFile(fileId: string, fileVersionId: string): Promise<void>;
}

export interface FCFormData {
  fileName: string;
  fileDescription: string;
  docFile: File[];
}

const FileCabinetEditDocument: React.FC<FileCabinetEditDocumentProps> = ({
  isOpen,
  onClose,
  file,
  handleEdit,
  handleOpenFile,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<FCFormData>();
  const [docFile] = watch(['docFile']);
  const dropboxFiles = (file?.fileVersions || []).map((doc) => ({
    ...doc,
    uniqueId: uuid(),
  }));
  useAgentsInfo([file?.uploadedBy!]);

  const onSubmit = async (data: FCFormData) => {
    await handleEdit(data);
    onClose();
  };

  const columns = useMemo<Column<FileVersionDto>[]>(() => {
    return [
      {
        Header: 'Name/Description',
        accessor: 'filename',
        Cell: ({ row: { original } }) => (
          <ZenFileCabinetFileNameCell
            file={original}
            handleOpenFile={() => handleOpenFile(file?.id!, original?.id!)}
            hideIcon
          />
        ),
      },
      {
        Header: 'Version',
        accessor: 'version',
        Cell: ({ value }) => (
          <div className='flex items-center md:justify-center gap-1'>
            <GiAnticlockwiseRotation />
            {value}
          </div>
        ),
      },
      {
        Header: 'Upload Date',
        accessor: 'createdAt',
        Cell: ({ row: { original } }) => {
          const date = DateTime.fromMillis(original?.createdAt!).setZone(
            'America/New_York',
          );
          return (
            <div className='font-zen-body text-sm'>
              <div className='font-normal text-zen-dark-9'>
                {date.toFormat('MMM d, yyyy')}
              </div>
              <div className='font-semibold text-zen-dark-6'>
                {date.toFormat('hh:mm a').toLocaleLowerCase()}
              </div>
            </div>
          );
        },
      },
      {
        Header: '',
        id: 'id',
        accessor: 'id',
        Cell: ({ row: { original } }) => (
          <ZenIconCircleWithTooltipCell
            tooltipText='Download'
            icon={
              <HiOutlineCloudArrowDown className='text-zen-dark-8 text-lg' />
            }
            onClick={() => {
              handleOpenFile(file?.id!, original?.id!);
            }}
          />
        ),
        disableFilters: true,
        disableSortBy: true,
      },
    ];
  }, [file?.id, handleOpenFile]);

  return (
    <ZenSidebarModalForm
      title='Edit Document'
      isOpen={isOpen}
      onClose={onClose}
      actionTitle='Save'
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <div className='mb-5'>
          <ZenControlledTextInput<FCFormData, 'fileName'>
            name='fileName'
            control={control}
            defaultValue={file?.filename}
            label='Document Name'
            placeholder='Document Name'
            rules={{
              required: 'Document name is required',
              validate: (val) =>
                !val?.trim() ? 'Please enter a valid document name' : undefined,
            }}
            isRequired
          />
        </div>

        <div className='mb-8'>
          <ZenControlledTextAreaInput<FCFormData, 'fileDescription'>
            name='fileDescription'
            control={control}
            defaultValue={file?.description || ''}
            label='Description'
            placeholder='Add Description'
            className='font-zen-body text-base text-zen-dark-9 font-normal'
            rows={4}
            noResize
          />
        </div>

        <div className='mb-5'>
          <ZenControlledDraggableDocumentUploadInput<FCFormData, 'docFile'>
            name='docFile'
            label='Replace with a new version'
            control={control}
            height='small'
            customPlaceHolder={
              <div className='flex md:flex-row flex-col justify-center items-center px-4'>
                <NewFile className='w-8 h-8' />
                {!!docFile?.[0]?.name ? (
                  <span className='font-zen-body font-semibold text-sm text-primary-blue ml-2 text-center'>
                    {docFile[0].name}
                  </span>
                ) : (
                  <>
                    <span className='font-zen-body font-semibold text-sm text-primary-blue ml-2'>
                      Browse Files
                    </span>
                    <p className='font-zen-body text-sm font-semibold text-zen-dark-7 ml-1'>
                      or drag & drop new file
                    </p>
                  </>
                )}
              </div>
            }
            multiple={false}
            accept={CHECKLIST_ALLOWED_FILE_TYPES.join(',')}
            maxUploadSize={MAX_DOC_SIZE_100MB}
            isUploading={docFile && isSubmitting}
          />
        </div>

        <div className='pb-5'>
          <div className='mb-2 ml-1 text-base font-semibold font-zen-body text-zen-dark-9'>
            Previous Versions
          </div>
          <ZenFixedDataTable<FileVersionDto>
            key={dropboxFiles?.length}
            columns={columns}
            resourceName='version'
            data={dropboxFiles}
            hideFilters
            standalone={false}
            hidePagination
          />
        </div>

        {/* LINK CHECKLIST | TODO: UNCOMMENT & IMPLEMENT AFTER VERIFICATION */}
        {/* <div className='px-4 pb-6'>
          <div className='mb-2 ml-1 text-base font-semibold font-zen-body text-zen-dark-9'>
            Checklist Item
          </div>
          <div className='w-full h-28 flex justify-center items-center text-base font-primary-medium text-primary-blue border rounded-lg border-gray-200 cursor-pointer'>
            + Link document to checklist item
          </div>
        </div> */}
      </div>
    </ZenSidebarModalForm>
  );
};

export default FileCabinetEditDocument;
