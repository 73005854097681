import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export interface ZenApplicationModalProps {
  title: string;
  subtitle?: string;
  isOpen: boolean;
  onClose?(): void;
}

const ZenApplicationModal: React.FC<ZenApplicationModalProps> = ({
  isOpen,
  onClose,
  title,
  subtitle,
  children,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-60'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full p-2 lg:p-0'>
        <div className='flex flex-col justify-center w-full bg-white border rounded-xl z-10 md:w-full max-w-xl shadow-sm shadow-gray-300 px-2 md:px-5 py-3'>
          <div className='flex flex-col justify-center'>
            <div className='space-y-1.5'>
              <div className='flex flex-row space-x-2 items-center'>
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  size='xl'
                  className='text-primary-blue'
                />
                <div className='mt-2'>
                  {title && (
                    <h2 className='text-md font-zen-body font-bold text-zen-dark-9 tracking-wide'>
                      {title}
                    </h2>
                  )}
                </div>
              </div>
              {subtitle && (
                <p className='text-[15px] text-gray-600'>{subtitle}</p>
              )}
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenApplicationModal;
