import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import DefaultLoader from '../../../components/DefaultLoader';
import {
  ConveyanceControllerApi,
  ConveyanceLetterRevisionResponseStatusEnum,
} from '../../../openapi/arrakis';
import Route404 from '../../../routes/Route404';
import ErrorService from '../../../services/ErrorService';
import {
  addNotesToConveyanceDocuments,
  fetchConveyanceDocuments,
} from '../../../slices/ConveyanceDocumentSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { fetchTransactionDetails } from '../../../slices/TransactionSlice';
import { AppDispatch, RootState, AnalyticsEventEnum } from '../../../types';
import AnalyticsService from '../../../services/AnalyticsService';
import { getConveyanceDocumentHtml } from '../../../utils/ConveyanceDocumentHelper';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenControlledTextAreaInput from '../Input/ZenControlledTextAreaInput';
import ZenRoute from '../ZenRoute';
import ZenConveyanceDocumentPreviewHeader from './ZenConveyanceDocumentPreviewHeader';
import ZenDocumentTitleHeader from './ZenDocumentTitleHeader';

type FormData = Record<string, string>;

type Match = {
  id: string;
  revisionId: string;
};

const ZenTransactionConveyanceDocumentPreviewRoute: React.FC = () => {
  const { id, revisionId } = useParams() as Match;
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const {
    auth: { isAdmin },
    transaction: {
      transactionDetailResponse: { data: transactionDetail, loading },
    },
    conveyanceDocument: { conveyances, conveyancesLoading },
  } = useSelector((state: RootState) => state);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const conveyanceDocument = conveyances?.find(
    (conveyance) => conveyance.revision === +revisionId,
  );

  const isDraft =
    ConveyanceLetterRevisionResponseStatusEnum.Draft ===
    conveyanceDocument?.status;

  const onSubmit = async (data: FormData) => {
    const success = await dispatch(
      addNotesToConveyanceDocuments(transactionDetail?.id!, data),
    );

    if (success) {
      history.push(
        `/transactions/${transactionDetail?.id}/conveyance-documents`,
      );
    }

    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.CONVEYANCE_DOCUMENT_VALIDATED,
    );
  };

  const handleDownload = async (docId: string | string[]) => {
    try {
      const { data } = await new ConveyanceControllerApi(
        getArrakisConfiguration(),
      ).getPreSignedUrl(transactionDetail?.id!);

      const foundDocumentDownloadLinkMap = data.filter((link) =>
        typeof docId === 'string'
          ? link.id === docId
          : docId.includes(link.id!),
      );

      if (!foundDocumentDownloadLinkMap?.length) {
        dispatch(
          showErrorToast('Cannot find conveyance document link to download'),
        );
      } else {
        foundDocumentDownloadLinkMap.forEach((link) => {
          window.open(link.url, '_blank');
        });
      }
    } catch (e) {
      ErrorService.notify('Unable to download conveyance document', e, {
        data: { docId },
      });
    }
  };

  useEffect(() => {
    dispatch(fetchTransactionDetails(id));
    dispatch(fetchConveyanceDocuments(id));
  }, [dispatch, id]);

  if (loading || conveyancesLoading) {
    return <DefaultLoader />;
  }

  if (!conveyanceDocument) {
    return <Route404 />;
  }

  return (
    <ZenRoute title='Conveyance Document Preview'>
      <div className='w-full h-mobile-screen lg:h-full flex flex-col bg-white scrollbar overflow-y-auto'>
        <ZenConveyanceDocumentPreviewHeader
          transactionAddress={transactionDetail?.address?.oneLine!}
          numberOfDocuments={
            (conveyanceDocument.conveyanceDocuments || []).length
          }
          conveyanceDocument={conveyanceDocument}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit(onSubmit)}
          onDownload={() =>
            handleDownload(
              conveyanceDocument?.conveyanceDocuments?.map((doc) => doc.id!) ||
                [],
            )
          }
        />
        <div className='flex-grow h-full scrollbar overflow-y-scroll'>
          {conveyanceDocument?.conveyanceDocuments?.map((conveyance, index) => (
            <div key={conveyance.name}>
              <ZenDocumentTitleHeader
                title={conveyance.name!}
                documentNumber={index + 1}
                isDraft={isDraft}
                onAddNote={() =>
                  document.querySelectorAll('textarea')[index].focus()
                }
              />
              <div
                className='w-full max-w-3xl mx-auto p-6'
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: getConveyanceDocumentHtml(conveyance),
                }}
              />

              {isDraft && isAdmin && (
                <div className='w-full max-w-3xl mx-auto p-6'>
                  <div className='border border-zen-dark-5 rounded-md overflow-hidden'>
                    <p className='font-primary-regular text-base text-dark px-2 py-1'>
                      Add a Note{' '}
                      <span className='font-primary-regular text-base text-gray-400'>
                        (this will be included in the final PDF)
                      </span>
                    </p>

                    <hr className='border-zen-dark-5' />

                    <ZenControlledTextAreaInput<FormData, string>
                      control={control}
                      name={conveyance.id!}
                      rows={3}
                      placeholder='Type a note here'
                      border={false}
                      defaultValue=''
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </ZenRoute>
  );
};

export default ZenTransactionConveyanceDocumentPreviewRoute;
