import React from 'react';
import { AgentDebtResponseStatusEnum } from '../../openapi/arrakis';
import { EnumMap, PartialEnumMap } from '../../types';
import { safeEnumMapGet } from '../../utils/EnumHelper';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenStatusPill, { PillVariantType } from '../Zen/ZenStatusPill';

interface DebtStatusProps {
  status: AgentDebtResponseStatusEnum;
}

const DebtStatus: React.FC<DebtStatusProps> = ({ status }) => {
  const debtStatusToVariantMap: EnumMap<
    AgentDebtResponseStatusEnum,
    PillVariantType
  > = {
    ACTIVE: 'primary',
    PAID: 'success',
    CANCELLED: 'danger',
  };

  const statusToDisplayNameMap: PartialEnumMap<
    AgentDebtResponseStatusEnum,
    string
  > = {
    CANCELLED: 'Void',
  };

  const variant = safeEnumMapGet(debtStatusToVariantMap, status, 'primary');
  const debtStatus = statusToDisplayNameMap[status] ?? capitalizeEnum(status);

  return (
    <div className='w-max'>
      <ZenStatusPill text={debtStatus} fontSize='text-sm' variant={variant} />
    </div>
  );
};

export default DebtStatus;
