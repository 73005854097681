import { debounce } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form-v7';
import { ImageContent } from '../../../../types';
import { NUMBER_REGEX } from '../../../../utils/StringUtils';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import RenderPDF from './RenderPDF';
import { SplitPDFFormData } from './ZenSplitAndAttachPDF';

interface ZenPreviewPDFPagesProps {
  form: UseFormReturn<SplitPDFFormData>;
  pdfImages: ImageContent[];
  selectedPages: ImageContent[];
  setSelectedPages(pages: ImageContent[]): void;
}

const ZenPreviewPDFPages: React.FC<ZenPreviewPDFPagesProps> = ({
  form: {
    control,
    watch,
    formState: { errors },
    setValue,
  },
  pdfImages,
  setSelectedPages,
  selectedPages,
}) => {
  const totalPages = pdfImages?.length || 0;
  const [selectStartPageNumber, selectEndPageNumber] = watch([
    'selectStartPageNumber',
    'selectEndPageNumber',
  ]);

  const handlePageRangeChange = (start: number, end: number) => {
    if (
      !isNaN(start) &&
      !isNaN(end) &&
      !errors.selectStartPageNumber &&
      !errors.selectEndPageNumber
    ) {
      setSelectedPages(pdfImages.slice(start - 1, end)! || []);
    }
  };

  const debounceHandlePageRangeChange = useCallback(
    debounce(handlePageRangeChange, 1000),
    [],
  );

  useEffect(() => {
    if (selectStartPageNumber && selectEndPageNumber) {
      debounceHandlePageRangeChange(
        parseInt(selectStartPageNumber, 10),
        parseInt(selectEndPageNumber, 10),
      );
    }
  }, [selectStartPageNumber, selectEndPageNumber]);

  return (
    <div className='font-zen-body text-zen-dark-9'>
      <div className='p-3'>
        <p className='text-sm mb-1'>Bulk Select Pages:</p>
        <div className='grid grid-cols-2 gap-1 mb-2 transform'>
          <ZenControlledTextInput<SplitPDFFormData, 'selectStartPageNumber'>
            name='selectStartPageNumber'
            placeholder='Start'
            control={control}
            rules={{
              pattern: {
                value: NUMBER_REGEX,
                message: 'Please input a valid number.',
              },
              validate: (val) => {
                return !!val &&
                  (parseInt(val) < 1 ||
                    parseInt(val) > (selectEndPageNumber || totalPages))
                  ? 'Out of range'
                  : undefined;
              },
            }}
          />
          <ZenControlledTextInput<SplitPDFFormData, 'selectEndPageNumber'>
            name='selectEndPageNumber'
            placeholder='End'
            control={control}
            rules={{
              pattern: {
                value: NUMBER_REGEX,
                message: 'Please input a valid number.',
              },
              validate: (val) => {
                return !!val &&
                  (parseInt(val) < (selectStartPageNumber || 1) ||
                    parseInt(val) > totalPages)
                  ? 'Out of range'
                  : undefined;
              },
            }}
          />
        </div>
        {!!selectedPages?.length && (
          <button
            className='flex items-center justify-center w-full space-x-1 rounded-lg py-1 px-1 text-sm border border-zen-dark-4'
            type='button'
            onClick={() => {
              setValue('selectStartPageNumber', '');
              setValue('selectEndPageNumber', '');
              setSelectedPages([]);
            }}
          >
            Deselect All
          </button>
        )}
      </div>
      <div className='mt-2'>
        <p className='text-sm mb-1.5 ml-3'>Pages:</p>
        {!!pdfImages && (
          <RenderPDF
            pdfImages={pdfImages}
            width={90}
            selectedPages={selectedPages}
            setSelectedPages={setSelectedPages}
            className='!w-full !h-[calc(100vh-140px)]'
          />
        )}
      </div>
    </div>
  );
};

export default ZenPreviewPDFPages;
