import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  CheckDepositDto,
  MoneyValue,
  TransactionResponse,
} from '../../../openapi/arrakis';
import {
  getCheckDepositsBackImageUrl,
  getCheckDepositsFrontImageUrl,
} from '../../../slices/CheckDepositsSlice';
import { AppDispatch } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { encodeTransactionAccountNumber } from '../../../utils/TransactionHelper';
import ZenButton from '../../Zen/ZenButton';
import ZenSidebarModalForm from '../../Zen/ZenSidebarModalForm';
import ZenCheckDepositsImagePreview from './ZenCheckDepositsImagePreview';
import ZenViewCheckInfoColumn from './ZenViewCheckInfoColumn';

interface ZenViewCheckDepositSidebarFormProps {
  checkDepositResponse: CheckDepositDto;
  transaction: TransactionResponse;
  isOpen: boolean;
  onClose(): void;
}

const ZenViewCheckDepositSidebarForm: React.FC<ZenViewCheckDepositSidebarFormProps> = ({
  checkDepositResponse,
  transaction,
  isOpen,
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [showAccountNo, setShowAccountNo] = useState<boolean>(false);
  const [frontImageUrl, setFrontImageUrl] = useState<string | undefined>(
    undefined,
  );
  const [backImageUrl, setBackImageUrl] = useState<string | undefined>(
    undefined,
  );

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CheckDepositDto>();

  const fetchImageUrls = useCallback(async () => {
    const frontImage = await dispatch(
      getCheckDepositsFrontImageUrl(transaction.id!, checkDepositResponse.id!),
    );
    const backImage = await dispatch(
      getCheckDepositsBackImageUrl(transaction.id!, checkDepositResponse.id!),
    );
    if (frontImage) {
      setFrontImageUrl(frontImage);
    }

    if (backImage) {
      setBackImageUrl(backImage);
    }
  }, [dispatch, transaction, checkDepositResponse]);

  useEffect(() => {
    fetchImageUrls();
  }, [fetchImageUrls]);

  const onSubmit = () => {
    [frontImageUrl, backImageUrl].forEach((url) => {
      window.open(url, '_blank');
    });
  };

  return (
    <ZenSidebarModalForm
      isOpen={isOpen}
      onClose={onClose}
      title={`Check Deposit for ${displayAmount(
        checkDepositResponse.scannedAmount as MoneyValue,
        {
          hideCurrency: true,
        },
      )} ${transaction.currency}`}
      subtitle={transaction.address?.oneLine!}
      actionTitle='Download Images (2)'
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      cancelTitle='Close'
    >
      <div className='grid grid-flow-row grid-cols-3 grid-rows-2 gap-4 my-5'>
        <ZenViewCheckInfoColumn
          label='Confirmation ID'
          value={checkDepositResponse.confirmationNumber}
        />
        <ZenViewCheckInfoColumn
          label='Submitted Date'
          value={DateTime.fromMillis(checkDepositResponse.createdAt!).toFormat(
            'L/d/y',
          )}
        />
        <ZenViewCheckInfoColumn
          label={`Amount (${transaction.currency})`}
          bold
          value={displayAmount(
            checkDepositResponse.submittedAmount as MoneyValue,
            {
              hideCurrency: true,
            },
          )}
        />
        <ZenViewCheckInfoColumn
          label='Routing No.'
          value={checkDepositResponse.routingNumber}
        />
        <ZenViewCheckInfoColumn
          label='Account No.'
          value={
            <div className='inline-flex flex-row items-center'>
              <span>
                {showAccountNo
                  ? checkDepositResponse.accountNumber
                  : encodeTransactionAccountNumber(
                      checkDepositResponse.accountNumber!,
                      '•',
                    )}
              </span>
              <ZenButton
                onClick={() => setShowAccountNo(!showAccountNo)}
                variant='primary-link'
                label={showAccountNo ? 'Hide' : 'Show'}
              />
            </div>
          }
        />
        <ZenViewCheckInfoColumn
          label='Check No.'
          value={checkDepositResponse.checkNumber}
        />
      </div>
      <div className='mt-5'>
        <ZenCheckDepositsImagePreview
          label='Front of check'
          imageUrl={frontImageUrl!}
        />
      </div>
      <div className='mt-5 mb-20'>
        <ZenCheckDepositsImagePreview
          label='Back of check'
          imageUrl={backImageUrl!}
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenViewCheckDepositSidebarForm;
