import { faBoxArchive, faBoxOpen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  AddressResponseCountryEnum,
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
  DirectoryEntryResponseTypeEnum,
  DirectoryPersonResponseStatusEnum,
  DirectoryVendorResponseCurrentUserPermissionEnum,
  DirectoryVendorResponseRoleEnum,
  DirectoryVendorResponseStatusEnum,
} from '../../openapi/yenta';
import {
  useArchivePerson,
  useArchiveVendor,
  useDirectoryEntity,
  useDirectoryVendorW9Url,
  useUpdateVendorAdminAreas,
  useVerifyVendor,
} from '../../query/directory/useDirectory';
import { countryMapState } from '../../testUtils/OnboardingUtils';
import { ISelectOption, RootState } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenControlledMultiSelectInput from '../Zen/Input/ZenControlledMultiSelectInput';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../Zen/ZenButton';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import ResourceSidebarContainer from '../ResourceSidebarContainer';
import { getDirectoryVendorRoleLabel } from './DirectoryUtils';
import ZenArchiveToggleSection from './ZenArchiveToggleSection';
import ZenContactDetailSection from './ZenContactDetailSection';
import ZenDirectoryWarning from './ZenDirectoryWarning';
import ZenTaxDetailSection from './ZenTaxDetailSection';
import ZenVerificationSection from './ZenVerificationSection';
import {
  determineVerificationEligibility,
  getReadableStateOrProvinceName,
  lookupStateOrProvinceCode,
} from './utils';
import ContactLocation from './ContactLocation';
import ZenDirectoryReadHeader from './ZenDirectoryReadHeader';
import ZenRoleDirectoryForm from './ZenRoleDirectoryForm';

interface ZenDirectoryReadFormProps {
  isOpen: boolean;
  onClose(): void;
  selectContactId: string;
  selectContactType: DirectoryEntryResponseTypeEnum;
}

interface FormData {
  isVerifiedToggled: boolean;
  locationId: ISelectOption[];
  country: AdministrativeAreaRequestCountryEnum;
}

const ZenDirectoryReadForm: React.FC<ZenDirectoryReadFormProps> = ({
  isOpen,
  onClose,
  selectContactId,
  selectContactType,
}) => {
  const { data, isLoading, isFetching } = useDirectoryEntity(
    selectContactId,
    selectContactType,
  );

  const isVendorSelected =
    selectContactType?.toUpperCase() === DirectoryEntryResponseTypeEnum.Vendor;

  const isPersonSelected =
    selectContactType?.toUpperCase() === DirectoryEntryResponseTypeEnum.Person;

  const name = isPersonSelected
    ? `${data?.firstName} ${data?.lastName}`
    : data?.name;

  const role = data?.role;

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: {
      isSubmitting,
      dirtyFields: {
        locationId: isLocationIdDirty,
        isVerifiedToggled: isVerifiedToggledDirty,
      },
    },
  } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      locationId: data?.administrativeAreaIds?.map((state) => ({
        value: getReadableStateOrProvinceName(state),
        label: capitalizeEnum(getReadableStateOrProvinceName(state)),
      })),
      isVerifiedToggled:
        data?.status === DirectoryVendorResponseStatusEnum.Verified,
    },
    // reValidateMode: 'onChange',
  });
  const { isAdmin } = useSelector((state: RootState) => state.auth);
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
  const [isLocationEdit, setIsLocationEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const contactType =
    selectContactType === DirectoryEntryResponseTypeEnum.Person
      ? 'Person details'
      : 'Business details';
  const isArchived =
    data?.status === DirectoryPersonResponseStatusEnum.Archived ||
    data?.status === DirectoryVendorResponseStatusEnum.Archived;

  const handleLocationChange = (selectedOptions: ISelectOption[]) => {
    const allSelected = selectedOptions?.find(
      (option) => option?.value === 'states' || option?.value === 'provinces',
    );

    if (allSelected) {
      setValue('locationId', [allSelected]);
    } else {
      setValue('locationId', selectedOptions);
    }
  };

  const isVendorExternalAgent =
    data?.role === DirectoryVendorResponseRoleEnum.OtherAgent &&
    isVendorSelected;
  const isCanadaVendor =
    data?.address?.country === AddressResponseCountryEnum.Canada &&
    isVendorSelected;
  const isUSVendor =
    data?.address?.country === AddressResponseCountryEnum.UnitedStates &&
    isVendorSelected;

  const isVerifiable = determineVerificationEligibility({
    hasW9: data?.hasW9 || false,
    isCanadaVendor,
    isUSVendor,
    isVendorExternalAgent,
    isVendorSelected,
    nationalBusinessIdentifications:
      data?.nationalBusinessIdentifications || [],
  });

  const { data: vendorW9Url } = useDirectoryVendorW9Url(
    selectContactId,
    data?.hasW9,
  );

  const { mutate: updateVendorAdminAreas } = useUpdateVendorAdminAreas();
  const { mutate: verifyVendor } = useVerifyVendor(selectContactId);
  const { mutate: archiveVendor } = useArchiveVendor(selectContactId);
  const { mutate: archivePerson } = useArchivePerson(selectContactId);

  const handleEdit = (e?: MouseEvent) => {
    e?.preventDefault();

    setIsEditing((current) => !current);
  };

  const toggleArchiveConfirmation = () => {
    setShowArchiveConfirmation(!showArchiveConfirmation);
  };

  const handleArchiveConfirm = () => {
    if (isPersonSelected) {
      archivePerson({ id: selectContactId!, archive: true });
    } else {
      archiveVendor({ id: selectContactId!, archive: true });
    }
    toggleArchiveConfirmation();
  };

  const handleMoveToActive = () => {
    if (isPersonSelected) {
      archivePerson({ id: selectContactId!, archive: false });
    } else {
      archiveVendor({ id: selectContactId!, archive: false });
    }
    toggleArchiveConfirmation();
  };

  // sync selected location values with what is in the data
  useEffect(() => {
    if ((data?.administrativeAreaIds?.length || 0) > 0) {
      const locationOptions = data?.administrativeAreaIds?.map((state) => ({
        value: state,
        label: getReadableStateOrProvinceName(state),
      }));

      setValue('locationId', locationOptions!);
    }
  }, [data, setValue]);

  // sync isVerifiedToggle button with the vendor's verified status
  useEffect(() => {
    setValue(
      'isVerifiedToggled',
      data?.status === DirectoryVendorResponseStatusEnum.Verified,
    );
  }, [data?.status, setValue]);

  const [isVerifiedToggled, locationId] = watch([
    'isVerifiedToggled',
    'locationId',
  ]);

  const onSubmit = async (formData: FormData) => {
    const isAllStatesSelected = formData.locationId?.some(
      (option) => option.value === 'states',
    );

    const isAllProvincesSelected = formData.locationId?.some(
      (option) => option.value === 'provinces',
    );

    let locationIds = [];
    if (isAllStatesSelected) {
      locationIds = countryMapState[
        AdministrativeAreaRequestCountryEnum.UnitedStates
      ].map((option) => option?.value);
    } else if (isAllProvincesSelected) {
      locationIds = countryMapState[
        AdministrativeAreaRequestCountryEnum.Canada
      ].map((option) => option?.value);
    } else {
      locationIds = formData.locationId?.map((option) => option?.value);
    }

    if (
      isVerifiedToggled &&
      !isPersonSelected &&
      (isLocationIdDirty || isVerifiedToggledDirty)
    ) {
      updateVendorAdminAreas(
        {
          id: data?.id!,
          administrativeAreaRequest: locationIds.map((stateOrProvince) => ({
            stateOrProvince: lookupStateOrProvinceCode(
              stateOrProvince,
            ) as AdministrativeAreaRequestStateOrProvinceEnum,
            country: isCanadaVendorVerifiable
              ? AdministrativeAreaRequestCountryEnum.Canada
              : AdministrativeAreaRequestCountryEnum.UnitedStates,
          })),
        },
        {
          onSuccess: () => {
            if (data?.status !== DirectoryVendorResponseStatusEnum.Verified)
              verifyVendor(data?.id!);
            onClose();
          },
        },
      );
    }
  };

  const isCanadaVendorVerifiable = isVerifiable && isCanadaVendor;
  // Must be admin, must be a business
  // Only external agent MUST HAVE EIN + W9 form for verification
  const shouldShowVerifyLocation = isAdmin && isVendorSelected && isVerifiable;

  const shouldAllowEditAndArchive =
    data?.currentUserPermission ===
    DirectoryVendorResponseCurrentUserPermissionEnum.ReadWrite;

  const locationOptions = isCanadaVendorVerifiable
    ? [
        { label: 'All Provinces', value: 'provinces' },
        ...countryMapState[AdministrativeAreaRequestCountryEnum.Canada],
      ]
    : [
        { label: 'All States', value: 'states' },
        ...countryMapState[AdministrativeAreaRequestCountryEnum.UnitedStates],
      ];
  const locationIdValues = (locationId || []).map(({ value }) =>
    lookupStateOrProvinceCode(value),
  );
  const filteredLocationOptions =
    locationOptions?.filter(
      (location) => !locationIdValues.includes(location.value),
    ) || [];

  return (
    <div>
      <ZenSidebarModal
        customHeader={
          <ZenDirectoryReadHeader
            selectContactType={selectContactType}
            title={!isEmpty(data) ? name : ''}
            subtitle={!isEmpty(data) ? getDirectoryVendorRoleLabel(role!) : ''}
            onClose={onClose}
            isVerified={
              data?.status === DirectoryVendorResponseStatusEnum.Verified
            }
          />
        }
        isOpen={isOpen}
        onClose={onClose}
      >
        <ResourceSidebarContainer
          loading={isLoading || isFetching}
          resourceName='directory'
          isEmpty={isEmpty(data)}
        >
          <form
            className='flex flex-col h-full'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='mt-5 flex-1'>
              <div>
                {shouldShowVerifyLocation && (
                  <ZenVerificationSection
                    control={control}
                    isVerified={isVerifiedToggled}
                    setIsLocationEdit={setIsLocationEdit}
                    isDisabled={
                      data?.status ===
                      DirectoryVendorResponseStatusEnum.Verified
                    }
                  />
                )}
                {shouldShowVerifyLocation && !isLocationEdit && (
                  <ContactLocation
                    onClick={() => setIsLocationEdit(true)}
                    administrativeAreaIds={data?.administrativeAreaIds}
                  />
                )}
                {isLocationEdit && (
                  <div className='mt-2 p-6'>
                    <ZenControlledMultiSelectInput<FormData, 'locationId'>
                      isSearchable
                      name='locationId'
                      control={control}
                      label='Select Location'
                      placeholder='Please select a location'
                      rules={{ required: 'Please select a location' }}
                      options={filteredLocationOptions}
                      closeMenuOnSelect={false}
                      isRequired
                      onChangeSpy={handleLocationChange}
                    />
                  </div>
                )}
                <div className='p-6'>
                  <ZenContactDetailSection
                    contactType={contactType}
                    data={data!}
                    handleEdit={handleEdit}
                    shouldAllowToEdit={shouldAllowEditAndArchive}
                  />
                  <div>
                    {isVendorSelected && (
                      <div className='mt-7'>
                        <ZenTaxDetailSection
                          handleEdit={handleEdit}
                          shouldAllowToEdit={shouldAllowEditAndArchive}
                          vendorData={data!}
                          w9Url={vendorW9Url}
                        />
                      </div>
                    )}
                    {isVendorSelected && isVerifiable === false && (
                      <div className='mt-8'>
                        <ZenDirectoryWarning />
                      </div>
                    )}
                  </div>
                  {shouldAllowEditAndArchive && (
                    <ZenArchiveToggleSection
                      isArchiveType={isArchived}
                      onClick={toggleArchiveConfirmation}
                    />
                  )}
                </div>
              </div>
            </div>
            {isVerifiedToggled &&
              (isLocationIdDirty || isVerifiedToggledDirty) && (
                <div className='p-4 bg-white border-t border-gray-200 space-x-5 flex-none'>
                  <div className='flex flex-row justify-end items-center space-x-3'>
                    <ZenButton
                      type='button'
                      onClick={() => {
                        onClose();
                      }}
                      label='Cancel'
                      variant='primary-outline'
                      buttonProps={{ style: { width: '150px' } }}
                    />
                    <ZenButton
                      type='submit'
                      label='Save'
                      variant='primary'
                      isDisabled={isSubmitting}
                      isSubmitting={isSubmitting}
                      buttonProps={{ style: { width: '150px' } }}
                    />
                  </div>
                </div>
              )}
          </form>
        </ResourceSidebarContainer>
      </ZenSidebarModal>
      {shouldAllowEditAndArchive && (
        <ZenConfirmationModal
          title={isArchived ? 'Move to Active' : 'Archive Contact'}
          size='large'
          variant={isArchived ? 'success' : 'danger'}
          isOpen={showArchiveConfirmation}
          onClose={toggleArchiveConfirmation}
          onConfirm={isArchived ? handleMoveToActive : handleArchiveConfirm}
          subtitle={
            <span className='text-base text-primary-dark font-inter font-normal text-center'>
              Please note that this action will {isArchived ? 'move' : 'remove'}{' '}
              <strong className='font-bold text-base font-inter'>
                {name} -{' '}
                {isArchived
                  ? getDirectoryVendorRoleLabel(data?.role)
                  : getDirectoryVendorRoleLabel(data?.role)}
              </strong>{' '}
              {isArchived
                ? 'from the archived contacts list.'
                : 'from the active contacts list.'}
            </span>
          }
          confirmButtonText={isArchived ? 'Move to Active' : 'Archive'}
          cancelButtonText='Cancel'
          confirmButtonLeftIcon={
            isArchived ? (
              <FontAwesomeIcon icon={faBoxOpen} className='mr-2' />
            ) : (
              <FontAwesomeIcon icon={faBoxArchive} className='mr-2' />
            )
          }
        >
          {isArchived ? (
            <p className='text-base text-center text-primary-dark font-inter font-normal p-6 mb-10'>
              Are you sure you want to move{' '}
              <strong className='font-bold text-base font-inter '>
                {name} - {getDirectoryVendorRoleLabel(data?.role)}
              </strong>{' '}
              back to the active contacts section?
            </p>
          ) : (
            <p className='text-base text-center text-primary-dark font-inter font-normal p-6'>
              However, you can still access their information through the
              archived contacts section if needed.
            </p>
          )}
        </ZenConfirmationModal>
      )}
      {isEditing && (
        <ZenRoleDirectoryForm
          isOpen={isEditing}
          onClose={handleEdit}
          mode='edit'
          existingType={selectContactType}
          existingVendorOrPerson={data}
          // w9Url={vendorW9Url}
        />
      )}
    </div>
  );
};

export default ZenDirectoryReadForm;
