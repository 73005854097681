import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faEnvelope,
  faSignature,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import {
  InviteResponseStatusEnum,
  MembershipResponseStatusEnum,
} from '../../../openapi/avalon';
import { EnumMap } from '../../../types';
import GeminiPill from '../GeminiPill';

export interface GeminiJVStatusButtonProps {
  status:
    | InviteResponseStatusEnum
    | MembershipResponseStatusEnum
    | 'NOT_INVITED';
  onClick?(): void;
}

const GeminiJVStatusButton: React.FC<GeminiJVStatusButtonProps> = ({
  status,
  onClick,
}) => {
  const buttonIconVariantToClassNameMap: EnumMap<
    InviteResponseStatusEnum | MembershipResponseStatusEnum | 'NOT_INVITED',
    IconProp | undefined
  > = {
    ACCEPTED: undefined,
    CREATED: faXmark,
    ACTIVE: undefined,
    DECLINED: faEnvelope,
    PAYMENT_FAILED: undefined,
    PENDING_PAYMENT: undefined,
    WITHDRAWN: faEnvelope,
    PENDING_SIGNATURE: faSignature,
    VIEWED: faXmark,
    NOT_INVITED: faEnvelope,
  };

  const buttonLabelVariantToClassNameMap: EnumMap<
    InviteResponseStatusEnum | MembershipResponseStatusEnum | 'NOT_INVITED',
    string | undefined
  > = {
    ACCEPTED: undefined,
    CREATED: 'Withdraw Invitation',
    ACTIVE: undefined,
    DECLINED: 'Invite to Join',
    PAYMENT_FAILED: undefined,
    PENDING_PAYMENT: undefined,
    WITHDRAWN: 'Invite to Join',
    PENDING_SIGNATURE: 'Agent Signed',
    VIEWED: 'Withdraw Invitation',
    NOT_INVITED: 'Invite to Join',
  };

  if (!buttonLabelVariantToClassNameMap[status]) {
    return null;
  }

  return (
    <button onClick={onClick}>
      <GeminiPill
        icon={buttonIconVariantToClassNameMap[status]}
        label={buttonLabelVariantToClassNameMap[status]!}
        containerClassNames='text-avatar-blue-200'
      />
    </button>
  );
};

export default GeminiJVStatusButton;
