import { useMemo } from 'react';
import { Remarkable } from 'remarkable';
import { linkify } from 'remarkable/linkify';

interface AnnouncementContentProps {
  title: string;
  details?: string;
}
var md = new Remarkable().use(linkify);

md.set({
  html: false,
  breaks: true,
  typographer: true,
  linkTarget: '_blank',
});
md.core.ruler.enable(['abbr']);
md.block.ruler.enable(['footnote', 'deflist']);
md.inline.ruler.enable(['footnote_inline', 'ins', 'mark', 'sub', 'sup']);

const AnnouncementContent: React.FC<AnnouncementContentProps> = ({
  title,
  details,
}) => {
  const parsedMarkdown = useMemo(() => md.render(details ?? ''), [details]);

  return (
    <div>
      <p className='mb-4 font-zen-body font-semibold text-lg text-zen-dark-9'>
        {title}
      </p>
      {!!details && (
        <div
          id='agent-journey-description-markdown'
          className='mb-6'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: parsedMarkdown }}
        />
      )}
    </div>
  );
};

export default AnnouncementContent;
