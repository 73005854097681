import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../constants/TableConstants';
import {
  CalendarResponse,
  CalendarUpdateRequest,
  AdministrativeAreaControllerApi,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { updateCalendar } from '../../../slices/CalendarSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { AsyncSelectOption } from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { officeTableFetchData } from '../../../utils/TableUtils';
import ZenControlledAsyncSelectInput from '../Input/ZenControlledAsyncSelectInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenButton from '../ZenButton';
import ZenSidebarModal from '../ZenSidebarModal';

interface FormData {
  calendarName: string;
  calendarUrl: string;
  administrativeAreaIds: AsyncSelectOption[];
  officeIds: AsyncSelectOption[];
}

interface ZenEditCalendarSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  calendar: CalendarResponse;
}

const ZenEditCalendarSidebarModal: React.FC<ZenEditCalendarSidebarModalProps> = ({
  isOpen,
  onClose,
  calendar,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      calendarName: calendar.name,
      calendarUrl: calendar.url,
      administrativeAreaIds: calendar.administrativeAreaIds?.map((id) => ({
        label: id,
        value: id,
      })),
    },
  });

  const fetchOffices = useCallback(async () => {
    const { data } = await officeTableFetchData(
      {
        page: 0,
        pageSize: 20,
      },
      calendar.officeIds,
    );

    const options: AsyncSelectOption[] = data?.map((resp) => ({
      value: `${resp.id}`,
      label: `${resp.name}`,
    }));

    setValue('officeIds', options);
  }, [calendar.officeIds, setValue]);

  useEffect(() => {
    if (!!calendar.officeIds?.length) {
      fetchOffices();
    }
  }, [calendar.officeIds, fetchOffices]);

  const onSubmit = async (values: FormData) => {
    const calendarUpdateRequest: CalendarUpdateRequest = {
      calendarName: values.calendarName,
      calendarUrl: values.calendarUrl,
      officeIds: (values.officeIds || []).map((office) => office.value),
      administrativeAreaIds: (values.administrativeAreaIds || []).map(
        (office) => office.value,
      ),
    };

    await dispatch(updateCalendar(calendar.calendarId!, calendarUpdateRequest));

    onClose();
  };

  return (
    <ZenSidebarModal
      title='Edit Calendar'
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <div className='py-4 md:px-8 px-4'>
        <div>
          <ZenControlledTextInput<FormData, 'calendarName'>
            name='calendarName'
            control={control}
            label='Calendar Name'
            rules={{ required: 'Calendar name is required' }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledTextInput<FormData, 'calendarUrl'>
            name='calendarUrl'
            control={control}
            label='Calendar Url'
            rules={{ required: 'Calendar url is required' }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledAsyncSelectInput<FormData, 'administrativeAreaIds'>
            control={control}
            name='administrativeAreaIds'
            label='Administrative Areas'
            fetchData={async (search, page) => {
              try {
                const {
                  data: searchResponse,
                } = await new AdministrativeAreaControllerApi(
                  getYentaConfiguration(),
                ).searchAdministrativeAreas(
                  page,
                  DEFAULT_PAGE_SIZE,
                  'ASC',
                  ['ID'],
                  undefined,
                  undefined,
                  search,
                );

                const options: AsyncSelectOption[] = (
                  searchResponse?.results || []
                ).map((resp) => ({
                  value: `${resp.id}`,
                  label: `${resp.id}`,
                }));

                return options;
              } catch (e) {
                ErrorService.notify(
                  'Unable to search request administrative areas in edit calendar form',
                  e,
                );

                dispatch(
                  showErrorToast(
                    'An unexpected error occurred.',
                    'We were unable to search for an administrative area. Please try again in a few moments or contact support.',
                  ),
                );
              }

              return [];
            }}
            isMulti
          />
        </div>
        <div className='mt-5'>
          <ZenControlledAsyncSelectInput<FormData, 'officeIds'>
            control={control}
            name='officeIds'
            label='Offices'
            fetchData={async (search, page = 0) => {
              try {
                const { data } = await officeTableFetchData({
                  page,
                  pageSize: 20,
                  search,
                });

                const options: AsyncSelectOption[] = data.map((resp) => ({
                  value: `${resp.id}`,
                  label: `${resp.name}`,
                }));

                return options;
              } catch (e) {
                ErrorService.notify(
                  'Unable to search request offices in edit calendar form',
                  e,
                  {
                    search: {
                      term: search,
                      page,
                    },
                  },
                );
                dispatch(
                  showErrorToast(
                    'An unexpected error occurred.',
                    'We were unable to search for an office. Please try again in a few moments or contact support.',
                  ),
                );
              }
              return [];
            }}
            isMulti
          />
        </div>
        <div className='absolute bottom-0 left-0 right-0 bg-white flex flex-row justify-end space-x-4 items-center w-full py-4 md:px-8 px-4 border border-gray-200'>
          <div className='w-40'>
            <ZenButton
              type='button'
              label='Cancel'
              variant='primary-outline'
              onClick={onClose}
              isFullWidth
            />
          </div>
          <div className='w-40'>
            <ZenButton
              type='submit'
              label='Update'
              variant='primary'
              isDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              isFullWidth
            />
          </div>
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default ZenEditCalendarSidebarModal;
