import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from '../../../../openapi/leo';
import { saveCurrentChat, saveShowIntro } from '../../../../slices/LeoSlice';
import { RootState } from '../../../../types';
import LeoChat from './LeoChat';
import LeoChatFooter from './LeoChatFooter';
import LeoChatHeader from './LeoChatHeader';
import LeoIntro from './LeoIntro';
import SessionRetry from './SessionRetry';

interface LeoChatIndexProps {
  toggleHistory(show: boolean): void;
  isShowingHistory: boolean;
}

export enum SENDER_RECEIVER_ENUM {
  SYSTEM = 'system',
  USER = 'user',
}

export interface CurrentChat extends Omit<Message, 'receiver' | 'sender'> {
  id: string;
  session_id: string;
  receiver?: SENDER_RECEIVER_ENUM;
  sender: SENDER_RECEIVER_ENUM;
}

export interface ChatFormData {
  askLeoText?: string;
}

const LeoChatIndex: React.FC<LeoChatIndexProps> = ({
  toggleHistory,
  isShowingHistory,
}) => {
  const dispatch = useDispatch();
  const formControls = useForm<ChatFormData>({
    defaultValues: {},
    mode: 'onChange',
  });
  const { showIntro, currentChat, session, loading } = useSelector(
    (state: RootState) => state.leo,
  );

  useEffect(() => {
    return () => {
      dispatch(saveCurrentChat([]));
      dispatch(saveShowIntro(true));
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentChat.length === 0) {
      dispatch(saveShowIntro(true));
    }
  }, [currentChat, dispatch]);

  if (!loading && !session?.id) {
    return <SessionRetry />;
  }

  return (
    <motion.div
      className='flex-grow flex flex-col h-full'
      layout
      transition={{ duration: 0.1 }}
      aria-label='leo-chat-index'
    >
      <FormProvider {...formControls}>
        <form className='flex-grow flex flex-col h-full'>
          <div className='flex-grow flex flex-col h-full sm:bg-black sm:bg-opacity-50 sm:rounded-2xl'>
            {!showIntro && <LeoChatHeader toggleHistory={toggleHistory} />}
            {showIntro ? (
              <LeoIntro />
            ) : (
              <LeoChat isShowingHistory={isShowingHistory} />
            )}
            <LeoChatFooter isIntro={showIntro} />
          </div>
        </form>
      </FormProvider>
    </motion.div>
  );
};

export default LeoChatIndex;
