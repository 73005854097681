import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  faUser,
  faBriefcase,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DirectoryEntryResponseTypeEnum } from '../../openapi/yenta';

interface ZenDirectoryReadHeaderProps {
  title?: string;
  subtitle?: string;
  selectContactType: DirectoryEntryResponseTypeEnum;
  onClose: () => void;
  isVerified?: boolean;
}

const ZenDirectoryReadHeader = ({
  title,
  subtitle,
  onClose,
  isVerified,
  selectContactType,
}: ZenDirectoryReadHeaderProps) => {
  return (
    <div className='flex items-center justify-between px-5 py-4 bg-rezen-blue-100'>
      <div className='space-y-1.5'>
        <div className='flex items-center gap-1'>
          {selectContactType === DirectoryEntryResponseTypeEnum.Person ? (
            <FontAwesomeIcon icon={faUser} />
          ) : (
            <FontAwesomeIcon icon={faBriefcase} />
          )}
          {title && (
            <h2 className='text-md font-zen-body font-bold text-primary-dark tracking-wide'>
              {title}
            </h2>
          )}
          {isVerified && <FontAwesomeIcon icon={faBadgeCheck} color='green' />}
        </div>
        {subtitle && <p className='text-[15px] text-gray-600'>{subtitle}</p>}
      </div>
      <div className='flex justify-center items-center w-7 h-7 bg-grey-400 rounded-full'>
        <FontAwesomeIcon
          icon={faXmark}
          size='lg'
          className='text-white cursor-pointer'
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default ZenDirectoryReadHeader;
