import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { AgentWebsiteResponse } from '../../../../../openapi/yenta';
import { saveAgentWebsiteDetails } from '../../../../../slices/AgentSlice';
import { validateSlug } from '../../../../../utils/AgentHelper';
import { getPathFromURL } from '../../../../../utils/AgentWebsiteUtils';
import { TEXT_CONTENT_VALIDATIONS } from '../../../../../utils/Validations';
import ZenControlledEmailInput from '../../../../Zen/Input/ZenControlledEmailInput';
import ZenControlledPhoneNumberInput from '../../../../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledToggleInput from '../../../../Zen/Input/ZenControlledToggleInput';
import ZenControlledWebsiteURLTextInput from '../../../../Zen/Input/ZenControlledWebsiteURLTextInput';
import ZenSidebarModalForm from '../../../../Zen/ZenSidebarModalForm';

interface ZenEditContactInfoSidebarModalFormProps {
  isOpen: boolean;
  onClose(): void;
  agentWebsite: AgentWebsiteResponse;
}

interface FormData {
  slug: string;
  email: string;
  phone: string;
  referralLink: boolean;
  instaUrl: string;
  facebookUrl: string;
  linkedInUrl: string;
}

const ZenEditContactInfoSidebarModalForm: React.FC<ZenEditContactInfoSidebarModalFormProps> = ({
  isOpen,
  onClose,
  agentWebsite,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({ mode: 'onChange', reValidateMode: 'onChange' });

  const onSubmit = (formData: FormData) => {
    const updatedData: AgentWebsiteResponse = {
      ...agentWebsite,
      agentResponse: {
        ...agentWebsite.agentResponse,
        facebookURL: formData.facebookUrl,
        instagramURL: formData.instaUrl,
        linkedInURL: formData.linkedInUrl,
        slug: formData.slug,
      },
      showReferralLink: formData.referralLink,
    };
    dispatch(saveAgentWebsiteDetails(updatedData));
    onClose();
  };

  return (
    <ZenSidebarModalForm
      title='Contact Info'
      subtitle='This content shows on Home and About pages. Changes here will also update agent’s profile information.'
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Save'
      isSubmitting={isSubmitting}
    >
      <div>
        <ZenControlledWebsiteURLTextInput<FormData, 'slug'>
          name='slug'
          label='Real website'
          placeholder='Slug'
          shouldUnregister={false}
          leftWebsiteSegment='www.joinreal.com/'
          defaultValue={agentWebsite.agentResponse?.slug}
          control={control}
          rules={{
            required: 'slug is required',
            validate: (slug: string) =>
              !!slug && agentWebsite.agentResponse?.slug !== slug
                ? validateSlug(slug)
                : undefined,
            ...TEXT_CONTENT_VALIDATIONS,
          }}
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledEmailInput<FormData, 'email'>
          control={control}
          name='email'
          label='About Me'
          placeholder='email'
          defaultValue={agentWebsite.agentResponse?.emailAddress}
          readOnly
        />
      </div>
      <div className='mt-5'>
        <ZenControlledPhoneNumberInput<FormData, 'phone'>
          control={control}
          label='Phone'
          name='phone'
          placeholder='+1 (702) 123-4567'
          readOnly
          defaultValue={agentWebsite.agentResponse?.phoneNumber}
        />
      </div>
      <div className='mt-5 -mx-2'>
        <ZenControlledToggleInput<FormData, 'referralLink'>
          label='Show agent referral link'
          shouldUnregister={false}
          name='referralLink'
          control={control}
          defaultValue={agentWebsite?.showReferralLink}
        />
      </div>
      <div className='mt-5 flex flex-col space-y-3'>
        <ZenControlledWebsiteURLTextInput<FormData, 'instaUrl'>
          name='instaUrl'
          label='Social Media'
          placeholder='instagram url'
          shouldUnregister={false}
          leftWebsiteSegment='www.instagram.com/'
          rules={{ ...TEXT_CONTENT_VALIDATIONS }}
          control={control}
          defaultValue={getPathFromURL(
            agentWebsite.agentResponse?.instagramURL,
          )}
        />
        <ZenControlledWebsiteURLTextInput<FormData, 'facebookUrl'>
          name='facebookUrl'
          placeholder='facebook url'
          shouldUnregister={false}
          leftWebsiteSegment='www.facebook.com/'
          control={control}
          rules={{ ...TEXT_CONTENT_VALIDATIONS }}
          defaultValue={getPathFromURL(agentWebsite.agentResponse?.facebookURL)}
        />
        <ZenControlledWebsiteURLTextInput<FormData, 'linkedInUrl'>
          name='linkedInUrl'
          placeholder='linkedIn url'
          shouldUnregister={false}
          leftWebsiteSegment='www.linkedin.com/'
          control={control}
          rules={{ ...TEXT_CONTENT_VALIDATIONS }}
          defaultValue={getPathFromURL(agentWebsite.agentResponse?.linkedInURL)}
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenEditContactInfoSidebarModalForm;
