import pluralize from 'pluralize';
import { ErrorCode } from '../types';
import { getErrorMessage } from '../utils/ErrorUtils';
import DefaultEmpty from './DefaultEmpty';
import DefaultError from './Errors/DefaultError';
import DefaultLoader from './DefaultLoader';

export interface ResourceSidebarContainerProps {
  loading: boolean;
  LoaderComponent?: React.ReactElement;
  isEmpty: boolean;
  resourceName: string;
  emptyMessage?: string;
  emptyIcon?: React.ReactElement;
  EmptyComponent?: React.ReactElement;
  ServerErrorComponent?: React.ReactElement;
  errorCode?: null | ErrorCode;
}

/**
 * Spin-off of `ResourceContainer`, this container component differs in that
 * it does not route to a 404 page or return a wrapper div, which can lead to issues with the sidebar's height.
 * Commonly used within `ZenSidebarModal` to display a loading spinner, empty state, or error message.
 *
 * Usage:
 * <ZenSidebarModal>
 *  <ResourceSidebarContainer
 *   loading={loading}
 *   isEmpty={isEmpty}
 *   resourceName='Contacts'
 *  >
 *   {children}
 *  </ResourceSidebarContainer>
 * </ZenSidebarModal>
 *
 */
const ResourceSidebarContainer: React.FC<ResourceSidebarContainerProps> = ({
  loading,
  LoaderComponent,
  isEmpty,
  resourceName,
  emptyMessage,
  emptyIcon,
  EmptyComponent,
  errorCode,
  ServerErrorComponent,
  children,
}) => {
  const finalEmptyMessage =
    emptyMessage ||
    `There are no ${pluralize(resourceName.toLowerCase())} to display.`;

  if (errorCode === ErrorCode.NOT_FOUND) {
    return (
      <DefaultError
        message={getErrorMessage(ErrorCode.NOT_FOUND, resourceName)}
      />
    );
  }

  if (errorCode === ErrorCode.UNAUTHORIZED) {
    return (
      <DefaultError
        message={getErrorMessage(ErrorCode.UNAUTHORIZED, resourceName)}
      />
    );
  }

  if (errorCode === ErrorCode.FORBIDDEN) {
    return (
      <DefaultError
        message={getErrorMessage(ErrorCode.FORBIDDEN, resourceName)}
      />
    );
  }

  if (errorCode === ErrorCode.SERVER_ERROR) {
    return (
      ServerErrorComponent || (
        <DefaultError message={getErrorMessage(ErrorCode.SERVER_ERROR)} />
      )
    );
  }

  if (loading) {
    return LoaderComponent || <DefaultLoader />;
  }

  if (isEmpty) {
    return (
      EmptyComponent || (
        <DefaultEmpty message={finalEmptyMessage} icon={emptyIcon} />
      )
    );
  }

  return <>{children}</>;
};

export default ResourceSidebarContainer;
