import {
  TransactionError,
  TransactionErrorSeverityEnum,
} from '../../openapi/arrakis';
import Alert, { AlertVariantType } from '../Alert';
import { EnumMap } from '../../types';

interface TransactionDetailErrorsProps {
  errors?: TransactionError[];
}

const TransactionDetailErrors: React.FC<TransactionDetailErrorsProps> = ({
  errors,
}) => {
  const transactionErrorVariantMap: EnumMap<
    TransactionErrorSeverityEnum,
    AlertVariantType
  > = {
    BLOCKER: 'error',
    CRITICAL: 'error',
    WARNING: 'warning',
  };

  return (
    <div className='mx-1'>
      {errors?.map((error) => (
        <div key={error.message} className='my-1'>
          <Alert
            text={error.message!}
            variant={transactionErrorVariantMap[error.severity!]}
          />
        </div>
      ))}
    </div>
  );
};

export default TransactionDetailErrors;
