import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { chunk, flatten } from 'lodash';
import {
  AddFlexTeamMemberRequest,
  AgentPlanControllerApi,
  CommissionPlanResponse,
  CreateFlexTeamAndConfig,
  CreateTeamRequest,
  FlexTeamConfigControllerApi,
  FlexTeamControllerApi,
  FlexTeamDto,
  GenericSearchResponseTeamResponse,
  TeamControllerApi,
  TeamResponse,
  TransferAgentBetweenTeamsRequest,
  UpdateFlexTeam,
  UpdateFlexTeamConfig,
  UpdateFlexTeamMemberRequest,
  UpdateTeamMembersRequest,
  UpdateTeamRequest,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { AppThunk, ErrorCode, TeamState } from '../types';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { messageMapToTeamAction, TeamActionStatus } from '../utils/TeamHelper';
import { showApiErrorModal } from './ErrorSlice';
import {
  showErrorToast,
  showErrorToastForErrorCode,
  showSuccessToast,
} from './ToastNotificationSlice';

export const initialState: TeamState = {
  loading: false,
  teamDetailOverview: null,
  fetchErrorCode: null,
  flexTeamDetailOverview: null,
  teamsById: {},
};

const TeamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    changeLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    errorFetching(state, action: PayloadAction<ErrorCode>) {
      state.fetchErrorCode = action.payload;
    },
    saveTeamDetailOverview(state, action: PayloadAction<TeamResponse | null>) {
      state.teamDetailOverview = action.payload;
      state.fetchErrorCode = null;
    },
    saveFlexTeamDetailOverview(state, action: PayloadAction<FlexTeamDto>) {
      state.flexTeamDetailOverview = action.payload;
      state.fetchErrorCode = null;
    },
    saveTeamById(state, action: PayloadAction<Array<TeamResponse>>) {
      action.payload.forEach((team) => {
        state.teamsById[team.id!] = team;
      });
    },
  },
});

export const {
  changeLoading,
  saveTeamDetailOverview,
  errorFetching,
  saveFlexTeamDetailOverview,
  saveTeamById,
} = TeamSlice.actions;

export const getAgentAllTeamsDetailOverview = (
  teamIds: string[],
  axiosOptions?: AxiosRequestConfig,
): AppThunk<Promise<TeamResponse[] | undefined>> => async (dispatch) => {
  dispatch(changeLoading(true));
  dispatch(saveTeamDetailOverview(null));
  try {
    const data: TeamResponse[] = await Promise.all(
      teamIds.map(async (id) => {
        const { data } = await new TeamControllerApi(
          getYentaConfiguration(),
        ).getTeamById(id!, axiosOptions);
        return data;
      }),
    );
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to fetch agents all team details',
      e,
      { teamIds },
    );
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoading(false));
  }
  return undefined;
};

export const getTeamDetailOverview = (id: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).getTeamById(id);
    dispatch(saveTeamDetailOverview(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors('Unable to fetch team detail', e, {
      team: { id },
    });
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoading(false));
  }
};

export const updateTeam = (
  id: string,
  req: UpdateTeamRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).updateTeam(id, req);
    dispatch(saveTeamDetailOverview(data));
    dispatch(showSuccessToast('Team updated successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update team', e, {
      team: { req },
    });
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem updating your team.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoading(false));
  }
};

export const createTeam = (
  req: CreateTeamRequest,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).createTeam(req);
    dispatch(saveTeamDetailOverview(data));
    dispatch(showSuccessToast(`Team created successfully.`));
    return data.id;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create team', e);
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem creating your team.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoading(false));
  }

  return undefined;
};

export const updateTeamMember = (
  id: string,
  req: UpdateTeamMembersRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).updateTeamMembers(id, req);
    dispatch(saveTeamDetailOverview(data));
    dispatch(
      showSuccessToast(
        `Team member ${messageMapToTeamAction(
          TeamActionStatus.SUCCESS_TOAST,
          req,
        )} successfully.`,
      ),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(
      `Unable to ${messageMapToTeamAction(
        TeamActionStatus.ERROR_SERVICE,
        req,
      )} team member`,
      e,
    );
    dispatch(
      showErrorToastForErrorCode(
        `We had a problem ${messageMapToTeamAction(
          TeamActionStatus.ERROR_TOAST,
          req,
        )} your team member.`,
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoading(false));
  }
};

export const markTeamAsInactive = (teamId: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).inactivateTeam(teamId);
    dispatch(saveTeamDetailOverview(data));
    dispatch(showSuccessToast(`Team successfully deactivated.`));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to deactivate team', e, {
      team: { id: teamId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem deactivating the team.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoading(false));
  }
};

export const transferAgentBetweenTeams = (
  req: TransferAgentBetweenTeamsRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new TeamControllerApi(
      getYentaConfiguration(),
    ).transferAgentBetweenTeams(req);
    dispatch(
      showSuccessToast('Agent successfully transfered to another team.'),
    );
    dispatch(getTeamDetailOverview(req.fromTeamId!));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Error transfering agent', e, {
      data: req!,
    });
    dispatch(
      showErrorToast(
        'We had a problem transfering this agent.',
        'Please try again in a few moments.',
      ),
    );
    return false;
  }
};

export const getFlexTeamDetailOverview = (
  id: string,
  isLoading: boolean = true,
): AppThunk => async (dispatch) => {
  if (isLoading) dispatch(changeLoading(true));
  try {
    const { data } = await new FlexTeamControllerApi(
      getYentaConfiguration(),
    ).getFlexTeamById(id);
    dispatch(saveFlexTeamDetailOverview(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors('Unable to fetch team detail', e, {
      team: { id },
    });
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
  } finally {
    if (isLoading) dispatch(changeLoading(false));
  }
};

export const createFlexTeamAndConfig = (
  req: CreateFlexTeamAndConfig,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new FlexTeamControllerApi(
      getYentaConfiguration(),
    ).createFlexTeamAndConfig(req);
    dispatch(showSuccessToast(`Team created successfully.`));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors('Unable to create flex team', e);
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem creating the team.',
        'Please try again in a few moments.',
      ),
    );
    return undefined;
  }
};

export const updateFlexTeam = (
  id: string,
  req: UpdateFlexTeam,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new FlexTeamControllerApi(getYentaConfiguration()).updateFlexTeam(
      id,
      req,
    );
    dispatch(showSuccessToast(`Team details updated successfully.`));

    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to update flex team detail',
      e,
      {
        team: { id },
      },
    );
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem update the team details.',
        'Please try again in a few moments.',
      ),
    );
  } finally {
    return false;
  }
};

export const addFlexTeamMember = (
  teamId: string,
  req: AddFlexTeamMemberRequest,
): AppThunk => async (dispatch) => {
  try {
    await new FlexTeamControllerApi(getYentaConfiguration()).addFlexTeamMember(
      teamId,
      req,
    );
    dispatch(showSuccessToast(`Team member added successfully.`));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to add flex team member',
      e,
      {},
    );
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem adding the member.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const updateFlexTeamMember = (
  teamId: string,
  memberId: string,
  req: UpdateFlexTeamMemberRequest,
): AppThunk => async (dispatch) => {
  try {
    await new FlexTeamControllerApi(
      getYentaConfiguration(),
    ).updateFlexTeamMember(teamId, memberId, req);
    dispatch(showSuccessToast(`Updated successfully.`));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to update flex team member',
      e,
      {},
    );
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem updating the details.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const removeFlexTeamMember = (
  teamId: string,
  memberId: string,
): AppThunk => async (dispatch) => {
  try {
    await new FlexTeamControllerApi(
      getYentaConfiguration(),
    ).removeFlexTeamMember(teamId, memberId);
    dispatch(getTeamDetailOverview(teamId));
    dispatch(showSuccessToast(`Removed member successfully.`));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to remove flex team member',
      e,
      {},
    );
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem removing the member.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const updateFlexTeamConfigurations = (
  id: string,
  req: UpdateFlexTeamConfig,
): AppThunk => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    await new FlexTeamConfigControllerApi(
      getYentaConfiguration(),
    ).updateFlexTeamConfig(id, req);
    dispatch(showSuccessToast(`Updated team configuration successfully.`));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to update team configurations',
      e,
      {},
    );
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem updating the configurations.',
        'Please try again in a few moments.',
      ),
    );
  } finally {
    dispatch(changeLoading(false));
  }
};

export const getCommissionPlan = (
  id: string,
): AppThunk<Promise<CommissionPlanResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new AgentPlanControllerApi(
      getYentaConfiguration(),
    ).getCommissionPlan(id);
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to fetch commission plan',
      e,
      {},
    );
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
    return undefined;
  }
};

export const deleteFlexTeam = (id: string): AppThunk => async (dispatch) => {
  try {
    await new FlexTeamControllerApi(getYentaConfiguration()).deleteFlexTeam(id);
    dispatch(showSuccessToast(`Team deleted successfully.`));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors('Unable to delete team', e, {
      team: { id },
    });
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem deleting the team.',
        'Please try again in a few moments.',
      ),
    );
  }
};
export const searchForTeams = (
  page: number = 0,
  pageSize: number = 20,
  sortDirection: Parameters<
    typeof TeamControllerApi.prototype.search
  >[2] = 'ASC',
  sortBy: Parameters<typeof TeamControllerApi.prototype.search>[3] = [],
  status: Parameters<typeof TeamControllerApi.prototype.search>[7] = 'ACTIVE',
  id?: string,
  name?: string,
  searchText?: string,
  createdAtStart?: string,
  createdAtEnd?: string,
  teamType?: Parameters<typeof TeamControllerApi.prototype.search>[10],
): AppThunk<Promise<GenericSearchResponseTeamResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).search(
      page,
      pageSize,
      sortDirection,
      sortBy,
      id,
      name,
      searchText,
      status,
      createdAtStart,
      createdAtEnd,
      teamType,
    );

    return data;
  } catch (e) {
    ErrorService.notify('Error fetching active teams', e, {
      req: {
        page,
        pageSize,
        sortDirection,
        name,
      },
    });
    dispatch(
      showErrorToast(
        'We had a problem fetching active teams.',
        'Please try again in a few moments.',
      ),
    );
    return undefined;
  }
};

export const fetchTeamsInfo = (
  teamIds: string[],
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const fetchApi = async (ids: string[]) => {
      const { data } = await new TeamControllerApi(
        getYentaConfiguration(),
      ).getTeamsByIdsBatch({ teamIds: ids });
      return data?.teams || [];
    };
    const allTeamsInfo = chunk(teamIds, 100).map(async (ids) => {
      const response = await fetchApi(ids);
      return response || [];
    });

    const allIds = await Promise.all(allTeamsInfo);

    dispatch(saveTeamById(flatten(allIds)));
  } catch (e) {
    ErrorService.notifyIgnoreAuthErrors('Unable to fetch team info', e, {
      team: { teamIds },
    });
  }
};
export default TeamSlice.reducer;
