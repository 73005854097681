import React from 'react';
import { cn } from '../../utils/classUtils';

interface Ten99FormInputFieldProps {
  label: string;
  value?: string;
  size?: TextInputHeightSizeType;
}

export type TextInputHeightSizeType = 'xs' | 'sm' | 'lg' | 'xl' | '2xl' | '3xl';

const Ten99FormInputField: React.FC<Ten99FormInputFieldProps> = ({
  label,
  value,
  size = 'xs',
}) => {
  const sizeToClassNameMap: { [key in TextInputHeightSizeType]: string } = {
    xs: 'h-6',
    sm: 'h-9',
    lg: 'h-12',
    xl: 'h-16',
    '2xl': 'h-24',
    '3xl': 'h-28',
  };

  return (
    <div>
      <p className='px-2'>{label}</p>
      <p
        className={cn(
          'bg-white px-2 font-primary-medium text-base print:text-xs text-dark whitespace-pre',
          sizeToClassNameMap[size],
        )}
      >
        {value}
      </p>
    </div>
  );
};

export default Ten99FormInputField;
