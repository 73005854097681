import { useState } from 'react';
import { MdOutlineBolt } from 'react-icons/md';
import { Column, Row } from 'react-table';
import {
  InstantPaymentTransactionSummaryResponse,
  TransactionLiteResponse,
} from '../../../openapi/arrakis';
import { displayAmount } from '../../../utils/CurrencyUtils';
import NumberColumnFilter from '../../table/Filters/NumberColumnFilter';
import TextColumnFilter from '../../table/Filters/TextColumnFilter';
import AgentOnly from '../../auth/AgentOnly';
import { isTransactionSummaryEligible } from '../../../utils/InstantPaymentHelper';
import ZenFixedDataTable from '../Table/ZenFixedDataTable';
import ZenTransactionCodeCell from '../Transaction/ZenTransactionCodeCell';
import ZenButton from '../ZenButton';
import ZenInstantPaymentTransactionSummaryStatePill from './ZenInstantPaymentTransactionSummaryStatePill';
import ZenEligibleTransactionInstantPaymentSidebarModal from './ZenEligibleTransactionInstantPaymentSidebarModal';

export const columns: Column<InstantPaymentTransactionSummaryResponse>[] = [
  {
    Header: 'Transaction Code',
    accessor: 'transaction',
    id: 'transactionCode',
    Cell: ({ value }) => (
      <ZenTransactionCodeCell
        linkTo={`/transactions/${value?.id}`}
        transaction={value! as TransactionLiteResponse}
        hideInvoice
      />
    ),
    cardColSize: 7,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Address',
    accessor: 'transaction',
    id: 'address',
    Cell: ({ value }) => <div className='w-56'>{value?.addressOneLine}</div>,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'state',
    Cell: ({ value }) => (
      <div className='w-max pt-1 md:pt-0'>
        <ZenInstantPaymentTransactionSummaryStatePill state={value!} />
      </div>
    ),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Total Commission Amount',
    accessor: 'transaction',
    Cell: ({ value }) => (
      <div>
        {displayAmount(value?.grossCommission, { hideCurrency: true })}
        <div className='font-zen-body text-sm	text-zen-gray-4'>
          {value?.grossCommission?.currency!}
        </div>
      </div>
    ),

    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Minimum Payment Allowed',
    accessor: 'minValueAvailable',
    Cell: ({ value }) => (
      <div>
        {displayAmount(value, { hideCurrency: true })}
        <div className='font-zen-body text-sm	text-zen-gray-4'>
          {value?.currency!}
        </div>
      </div>
    ),
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Maximum Payment Allowed',
    accessor: 'maxValueAvailable',
    Cell: ({ value }) => (
      <div>
        {displayAmount(value, { hideCurrency: true })}
        <div className='font-zen-body text-sm	text-zen-gray-4'>
          {value?.currency!}
        </div>
      </div>
    ),
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
];

interface ZenInstantPaymentEligibleTransactionResourceTableProps {
  transactionSummaries: InstantPaymentTransactionSummaryResponse[];
}

const ZenInstantPaymentEligibleTransactionResourceTable: React.FC<ZenInstantPaymentEligibleTransactionResourceTableProps> = ({
  transactionSummaries,
}) => {
  const [
    transactionSummary,
    setTransactionSummary,
  ] = useState<InstantPaymentTransactionSummaryResponse | null>(null);

  const columnsWithAction = [...columns];
  columnsWithAction.unshift({
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<InstantPaymentTransactionSummaryResponse> }) => (
      <AgentOnly>
        {isTransactionSummaryEligible(row.original.state) && (
          <div className='w-max'>
            <ZenButton
              label='Pay Me'
              LeftIconComponent={<MdOutlineBolt fontSize={20} />}
              onClick={() => setTransactionSummary(row.original)}
            />
          </div>
        )}
      </AgentOnly>
    ),
    disableSortBy: true,
  });

  return (
    <div>
      <ZenFixedDataTable<InstantPaymentTransactionSummaryResponse>
        columns={columnsWithAction}
        data={transactionSummaries}
        resourceName='Eligible Transaction'
        hidePagination
        hideFilters
      />
      {!!transactionSummary && (
        <ZenEligibleTransactionInstantPaymentSidebarModal
          transactionSummary={transactionSummary}
          onClose={() => setTransactionSummary(null)}
        />
      )}
    </div>
  );
};

export default ZenInstantPaymentEligibleTransactionResourceTable;
