import React from 'react';
import { ReactComponent as CompanyIcon } from '../../../assets/icons/zen/transaction/company-outline.svg';
import { ReactComponent as HomeIcon } from '../../../assets/icons/zen/transaction/home-outline.svg';
import { ReactComponent as ShakeHandsIcon } from '../../../assets/icons/zen/transaction/shakehands-outline.svg';
import { TransactionResponseTransactionTypeEnum } from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { getTransactionPriceLabel } from '../../../utils/TransactionHelper';

interface ZenTransactionDealTypeItemProps {
  dealType: TransactionResponseTransactionTypeEnum;
}

const ZenTransactionDealTypeItem: React.FC<ZenTransactionDealTypeItemProps> = ({
  dealType,
}) => {
  const getTransactionTypeComponent = (
    transactionType: TransactionResponseTransactionTypeEnum,
  ) => {
    if (getTransactionPriceLabel(transactionType) === 'Lease Price') {
      return <CompanyIcon className='mr-1' />;
    }

    if (getTransactionPriceLabel(transactionType) === 'Sale Price') {
      return <HomeIcon className='mr-1' />;
    }

    if (getTransactionPriceLabel(transactionType) === 'Referral Amount') {
      return <ShakeHandsIcon className='mt-1 mr-1' />;
    }

    return <HomeIcon className='mb-[3px] mr-1' />;
  };

  return (
    <div className='flex items-center'>
      {getTransactionTypeComponent(dealType)}
      <span>{capitalizeEnum(dealType)}</span>
    </div>
  );
};

export default ZenTransactionDealTypeItem;
