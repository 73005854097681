/* tslint:disable */
/* eslint-disable */
/**
 * Insignia API
 * Insignia Main Application
 *
 * The version of the OpenAPI document: 4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AgentBadgeResponse
 */
export interface AgentBadgeResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentBadgeResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBadgeResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {BadgeResponse}
     * @memberof AgentBadgeResponse
     */
    'badge'?: BadgeResponse;
    /**
     * 
     * @type {number}
     * @memberof AgentBadgeResponse
     */
    'milestoneCurrentValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentBadgeResponse
     */
    'unlocked'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentBadgeResponse
     */
    'unlockedAt'?: number;
}
/**
 * 
 * @export
 * @interface AgentBadgesResponse
 */
export interface AgentBadgesResponse {
    /**
     * 
     * @type {Array<AgentBadgeResponse>}
     * @memberof AgentBadgesResponse
     */
    'badges'?: Array<AgentBadgeResponse>;
}
/**
 * 
 * @export
 * @interface BadgeResponse
 */
export interface BadgeResponse {
    /**
     * 
     * @type {string}
     * @memberof BadgeResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeResponse
     */
    'name'?: BadgeResponseNameEnum;
    /**
     * 
     * @type {string}
     * @memberof BadgeResponse
     */
    'milestoneType'?: BadgeResponseMilestoneTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof BadgeResponse
     */
    'milestoneTargetValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof BadgeResponse
     */
    'description'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BadgeResponseNameEnum {
    IncomeScholar = 'INCOME_SCHOLAR',
    RisingStar = 'RISING_STAR',
    NetworkBuilder = 'NETWORK_BUILDER',
    PeakConqueror = 'PEAK_CONQUEROR',
    AdmiralAchiever = 'ADMIRAL_ACHIEVER',
    Locksmith = 'LOCKSMITH',
    MasterRecruiter = 'MASTER_RECRUITER',
    CollaborationChampion = 'COLLABORATION_CHAMPION',
    MilestoneMaker = 'MILESTONE_MAKER',
    QuarterQuasar = 'QUARTER_QUASAR',
    MidpointMaestro = 'MIDPOINT_MAESTRO',
    MilestoneMonarch = 'MILESTONE_MONARCH',
    SixFigureSovereign = 'SIX_FIGURE_SOVEREIGN'
}
/**
    * @export
    * @enum {string}
    */
export enum BadgeResponseMilestoneTypeEnum {
    LearnAboutRevshare = 'LEARN_ABOUT_REVSHARE',
    RevshareEarned = 'REVSHARE_EARNED',
    FrontLineSize = 'FRONT_LINE_SIZE',
    NetworkSize = 'NETWORK_SIZE'
}

/**
 * 
 * @export
 * @interface BadgesResponse
 */
export interface BadgesResponse {
    /**
     * 
     * @type {Array<BadgeResponse>}
     * @memberof BadgesResponse
     */
    'badges'?: Array<BadgeResponse>;
}

/**
 * BadgeApi - axios parameter creator
 * @export
 */
export const BadgeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all badges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentBadges: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/badges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all agent badges
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentBadges1: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getAgentBadges1', 'agentId', agentId)
            const localVarPath = `/api/v1/badges/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlock agent badge
         * @param {string} badgeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockAgentBadge: async (badgeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'badgeId' is not null or undefined
            assertParamExists('unlockAgentBadge', 'badgeId', badgeId)
            const localVarPath = `/api/v1/badges/{badgeId}/unlock`
                .replace(`{${"badgeId"}}`, encodeURIComponent(String(badgeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BadgeApi - functional programming interface
 * @export
 */
export const BadgeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BadgeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all badges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentBadges(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BadgesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentBadges(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all agent badges
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentBadges1(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentBadgesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentBadges1(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unlock agent badge
         * @param {string} badgeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockAgentBadge(badgeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlockAgentBadge(badgeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BadgeApi - factory interface
 * @export
 */
export const BadgeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BadgeApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all badges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentBadges(options?: any): AxiosPromise<BadgesResponse> {
            return localVarFp.getAgentBadges(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all agent badges
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentBadges1(agentId: string, options?: any): AxiosPromise<AgentBadgesResponse> {
            return localVarFp.getAgentBadges1(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlock agent badge
         * @param {string} badgeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockAgentBadge(badgeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unlockAgentBadge(badgeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BadgeApi - object-oriented interface
 * @export
 * @class BadgeApi
 * @extends {BaseAPI}
 */
export class BadgeApi extends BaseAPI {
    /**
     * 
     * @summary Get all badges
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public getAgentBadges(options?: AxiosRequestConfig) {
        return BadgeApiFp(this.configuration).getAgentBadges(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all agent badges
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public getAgentBadges1(agentId: string, options?: AxiosRequestConfig) {
        return BadgeApiFp(this.configuration).getAgentBadges1(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlock agent badge
     * @param {string} badgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public unlockAgentBadge(badgeId: string, options?: AxiosRequestConfig) {
        return BadgeApiFp(this.configuration).unlockAgentBadge(badgeId, options).then((request) => request(this.axios, this.basePath));
    }
}


