import React, { useEffect } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import classNames from 'classnames';
import * as Quill from 'quill';
import ReactQuill from 'react-quill';
import ReactDOMServer from 'react-dom/server';
import { EnumMap } from '../../../types';
import MentionRow from '../../CommentWidget/MentionRow';
import { MentionSource } from '../../transactions/Comments/ZenCommentSection';
import ZenFormErrorMessage from './ZenFormErrorMessage';

export type ZenControlledRichTextInputVariant = 'light' | 'transparent';

interface ZenControlledRichTextInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  autoFocus?: boolean;
  label?: string;
  subLabel?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  isRequired?: boolean;
  maxLength?: number;
  variant?: ZenControlledRichTextInputVariant;
  getMentions?(searchTerm?: string): MentionSource[];
  reactQuillRef: React.RefObject<ReactQuill>;
}

const ZenControlledRichTextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  autoFocus = false,
  label,
  subLabel,
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  shouldUnregister = true,
  startAdornment,
  endAdornment,
  isRequired = false,
  maxLength,
  variant = 'light',
  getMentions,
  reactQuillRef,
  ...rest
}: ZenControlledRichTextInputProps<TFieldValues, TName>) => {
  const clearMention = () => {
    document
      .querySelectorAll('.ql-mention-list-container')
      .forEach((element) => {
        element.remove();
      });
  };

  useEffect(() => {
    return () => {
      clearMention();
    };
  }, []);

  const handleScroll = () => {
    const mentionItemEls = document.querySelectorAll('.ql-mention-list-item');

    if (mentionItemEls.length > 0) {
      const mentionPopup = document.querySelector(
        '.ql-mention-list-container.ql-mention-list-container-bottom',
      );

      if (mentionPopup) {
        mentionPopup.remove();
      }
    }
  };

  useEffect(() => {
    const mainPageEl = document.getElementById('main-page');

    if (mainPageEl) {
      mainPageEl.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (mainPageEl) {
        mainPageEl.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({ field: { value, name }, fieldState: { error, invalid } }) => {
        const variantToBackgroundStyleMap: EnumMap<
          ZenControlledRichTextInputVariant,
          string
        > = {
          light: 'bg-white',
          transparent: 'bg-transparent',
        };

        const variantToTextStyleMap: EnumMap<
          ZenControlledRichTextInputVariant,
          string
        > = {
          light: invalid ? 'text-zen-danger' : 'text-zen-dark-9',
          transparent: invalid ? 'text-zen-danger' : 'text-white',
        };

        const source = async (searchTerm: string, renderList: any) => {
          const mentions = await getMentions?.(searchTerm);
          const cloneMentions = JSON.parse(JSON.stringify(mentions));
          cloneMentions?.forEach((mention: MentionSource) => {
            mention['value'] = `@${mention?.value}`;
          });
          clearMention();
          renderList(cloneMentions);
        };

        const getFormats = () => {
          const formats = ['link', 'emoji', 'send'];

          if (!!getMentions) {
            formats.splice(2, 0, 'mention');
          }

          return formats;
        };

        const getEnabledModules = () => {
          const modules: Quill.StringMap = {
            toolbar: false,
            linkify: {
              url: true,
              mail: true,
              phoneNumber: false,
            },
            'emoji-shortname': true,
            'emoji-toolbar': true,
            'emoji-textarea': false,
          };

          if (!!getMentions) {
            modules.mention = {
              allowedChars: /^[A-Za-z0-9\s]*$/,
              mentionDenotationChars: ['@'],
              offsetTop: 20,
              defaultMenuOrientation: 'bottom',
              showDenotationChar: false,
              dataAttributes: ['id', 'value', 'emailAddress'],
              positioningStrategy: 'fixed',
              onSelect: (item: any, insertItem: any) => {
                insertItem(item);
              },
              renderItem: (item: any) =>
                ReactDOMServer.renderToStaticMarkup(
                  <MentionRow
                    name={
                      item.value[0] === '@'
                        ? item.value.substring(1)
                        : item.value
                    }
                    emailAddress={item?.emailAddress}
                  />,
                ),
              source,
            };
          }

          return modules;
        };

        return (
          <div className='w-full space-y-1'>
            {label && (
              <label className='inline-block' htmlFor={name}>
                <span
                  className={classNames(
                    'font-zen-body font-semibold',
                    variantToTextStyleMap[variant],
                  )}
                >
                  {label}
                </span>
                {!!subLabel && (
                  <span className='font-zen-body text-sm text-zen-dark-6 ml-1'>
                    {subLabel}
                  </span>
                )}
                {isRequired && <span className='text-zen-danger'>*</span>}
              </label>
            )}
            <div
              className={classNames(
                'flex items-stretch border rounded-lg overflow-hidden flex-grow',
                variantToBackgroundStyleMap[variant],
                value && 'text-zen-dark-9',
                invalid && '!border-zen-danger',
                {
                  'border-none': noBorder,
                  'border-zen-dark-7 pointer-events-none':
                    variant === 'transparent' && !!readOnly,
                },
              )}
            >
              {startAdornment && <div>{startAdornment}</div>}
              <ReactQuill
                ref={reactQuillRef}
                className='w-full px-2 py-1 overflow-auto rich-text-input'
                modules={getEnabledModules()}
                formats={getFormats()}
                placeholder={placeholder}
                readOnly={readOnly}
                theme='bubble'
              />

              {endAdornment && <div>{endAdornment}</div>}
            </div>
            <div className='flex'>
              {!!error && !hideErrorMessage && (
                <ZenFormErrorMessage message={error.message} />
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default ZenControlledRichTextInput;
