import { useMemo } from 'react';
import { flatten } from 'lodash';
import { useDispatch } from 'react-redux';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  DirectoryControllerApi,
  DirectoryEntryResponseTypeEnum,
} from '../../openapi/yenta';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ErrorService from '../../services/ErrorService';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { queryKeys } from '../base/queryKeys';
import { ISelectOption } from '../../types';
import { DirectoryCommonEntityResponse } from './useDirectory';

export const useInfiniteDirectory = (
  queryType: DirectoryEntryResponseTypeEnum,
  filters: Record<string, any> = {},
) => {
  const dispatch = useDispatch();

  const fetchDirectory = async ({
    pageParam = 0,
    filters = {},
  }: {
    pageParam?: number;
    filters?: Record<string, any>;
  }) => {
    const { data } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).getAllEntries(
      pageParam,
      20,
      undefined,
      filters.isVerified || undefined,
      filters?.searchQuery || undefined,
      undefined, // nationalBusinessId
      filters.name || undefined, // name
      filters.email || undefined, // emailAddress
      filters?.phoneNumber || undefined, // phoneNumber
      undefined, // street
      undefined, // city
      undefined, // postal
      undefined, // state
      undefined, // country
      undefined, // administrativeAreaIds
      filters.participantRole?.map((role: ISelectOption) => role?.value) ||
        undefined, // roles
      undefined, // createdBy
    );

    return {
      data,
      nextPage: data.hasNext ? pageParam + 1 : null,
    };
  };

  const fetchPersonEntries = async ({
    pageParam = 0,
    filters = {},
  }: {
    pageParam?: number;
    filters?: Record<string, any>;
  }) => {
    const { data } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).getAllPersons(
      pageParam,
      20,
      undefined, // isArchived
      filters?.isExternalAgent || undefined, // public
      undefined,
      filters?.searchQuery || undefined, // searchText
      filters?.name || undefined, // firstName
      undefined, // lastName
      filters?.email, // emailAddress
      filters?.phoneNumber, // phoneNumber
      filters.participantRole?.map((role: ISelectOption) => role?.value) ||
        undefined, // role
      ['FIRST_NAME'], // sortby
    );

    return {
      data,
      nextPage: data.hasNext ? pageParam + 1 : null,
    };
  };

  const fetchVendorEntries = async ({
    pageParam = 0,
    filters = {},
  }: {
    pageParam?: number;
    filters?: Record<string, any>;
  }) => {
    const { data } = await new DirectoryControllerApi(
      getYentaConfiguration(),
    ).getAllVendors(
      pageParam,
      20,
      filters.isArchived === 'archived', // isArchived
      filters.isVerified || undefined, // isVerified
      undefined, // hasLinkedPersons
      filters?.searchQuery || undefined, // searchText
      undefined, // nationalBusinessId
      filters?.name || undefined, // name
      filters?.email, // emailAddress
      filters?.phoneNumber, // phoneNumber
      undefined, // street
      undefined, // city
      undefined, // postal
      undefined, // state
      undefined, // country
      undefined, // administrativeAreaIds
      filters.participantRole?.map((role: ISelectOption) => role?.value) ||
        undefined, // roles
    );

    return {
      data,
      nextPage: data.hasNext ? pageParam + 1 : null,
    };
  };

  const queryResult = useInfiniteQuery({
    queryKey: queryKeys.directoryInfinite.list(filters).queryKey,
    queryFn: filters?.isExternalAgent
      ? ({ pageParam = 0 }) => fetchDirectory({ pageParam, filters })
      : queryType === DirectoryEntryResponseTypeEnum.Person
      ? ({ pageParam = 0 }) => fetchPersonEntries({ pageParam, filters })
      : ({ pageParam = 0 }) => fetchVendorEntries({ pageParam, filters }),
    getNextPageParam: (lastPage) => {
      if (lastPage.data.hasNext) {
        return lastPage.nextPage;
      }
      return undefined;
    },
    onError: (error: Error) => {
      dispatch(showErrorToast('Error fetching directory data.'));
      ErrorService.notify('Error fetching directory data.', error);
    },
  });

  const flattenedData = useMemo(() => {
    return flatten(
      queryResult.data?.pages.map((page) => {
        if (filters.isExternalAgent) {
          return (
            (page.data
              .directoryEntryResponseList as DirectoryCommonEntityResponse[]) ??
            []
          );
        }
        if (queryType === DirectoryEntryResponseTypeEnum.Person) {
          const personPage = page.data as {
            directoryPersonResponseList: DirectoryCommonEntityResponse[];
          };
          return personPage.directoryPersonResponseList ?? [];
        }
        const vendorPage = page.data as {
          directoryVendorResponseList: DirectoryCommonEntityResponse[];
        };
        return vendorPage.directoryVendorResponseList ?? [];
      }),
    ).map((contact: DirectoryCommonEntityResponse) => ({
      name:
        'firstName' in contact && 'lastName' in contact
          ? `${contact.firstName} ${contact.lastName}`
          : contact.name,
      ...contact,
    }));
  }, [queryResult.data, queryType, filters.isExternalAgent]);

  return {
    data: flattenedData,
    isFetching: queryResult.isFetching,
    isInitialLoading: queryResult.isInitialLoading,
    isLoading: queryResult.isLoading,
    hasNextPage: queryResult.hasNextPage,
    fetchNextPage: queryResult.fetchNextPage,
    isFetchingNextPage: queryResult.isFetchingNextPage,
  };
};
