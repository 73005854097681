import { faUserGroup } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  AgentResponse,
  AgentTeamMembershipResponseRolesEnum,
  TeamResponseTypeEnum,
} from '../../../openapi/yenta';
import { AsyncResponse } from '../../../types';
import { getCombinedMembershipsTeam } from '../../../utils/TeamHelper';
import DefaultEmpty from '../../DefaultEmpty';
import ResourceContainer from '../../ResourceContainer';
import ZenTeamsRoleTypePill from '../../Zen/Teams/ZenTeamsRoleTypePill';
import ZenTeamsTeamTypeSection from '../../Zen/Teams/ZenTeamsTeamTypeSection';
import ZenCard from '../../Zen/ZenCard';
import AuthorizationContainer from '../../auth/AuthorizationContainer';

interface ZenTeamsProps {
  asyncResponse: AsyncResponse<AgentResponse, {}>;
}

const ZenTeams: React.FC<ZenTeamsProps> = ({ asyncResponse }) => {
  const allTeams = getCombinedMembershipsTeam(asyncResponse.data);

  return (
    <ZenCard title='Teams'>
      <AuthorizationContainer asyncResponse={asyncResponse}>
        <ResourceContainer
          loading={asyncResponse.loading}
          isEmpty={allTeams?.length === 0}
          resourceName='flexMemberships'
          EmptyComponent={
            <DefaultEmpty
              message='You do not belong to any teams.'
              icon={
                <FontAwesomeIcon
                  icon={faUserGroup}
                  size='xl'
                  className='py-2'
                />
              }
            />
          }
        >
          <div className='max-h-72 scrollbar overflow-y-scroll overflow-x-hidden'>
            {allTeams?.map((team, index) => {
              const isLastItem = index === allTeams?.length - 1;

              const isMemberCommissionSplit = team.roles?.includes(
                AgentTeamMembershipResponseRolesEnum.Leader,
              )
                ? ''
                : `Split ${team.memberCommissionSplit}/${
                    100 - team.memberCommissionSplit!
                  }`;

              return (
                <div key={team.team?.id}>
                  <div>
                    <div className=' flex flex-col justify-between gap-2.5 px-4 py-3'>
                      <ZenTeamsTeamTypeSection team={team.team} />
                      <div className='flex flex-row items-center gap-2'>
                        <ZenTeamsRoleTypePill
                          roles={team.roles!}
                          isProTeam={
                            (team?.team?.type as TeamResponseTypeEnum) ===
                            TeamResponseTypeEnum.Pro
                          }
                        />
                        {isMemberCommissionSplit && (
                          <>
                            <span className='h-1 w-1 rounded-full bg-regent-400' />
                            <span className='font-normal text-sm font-inter'>
                              {isMemberCommissionSplit}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {!isLastItem && <div className='border-b mx-4' />}
                </div>
              );
            })}
          </div>
        </ResourceContainer>
      </AuthorizationContainer>
    </ZenCard>
  );
};

export default ZenTeams;
