import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { isFunction } from 'lodash';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import { RootState } from '../../../types';
import { Earning, EarningTitle, EarningType } from '../WealthManagementTypes';
import { setWealthKnowledgeLandingPage } from '../../../slices/WealthManagementSlice';
import { ZenCommissionIncomeKnowledgePage } from './ZenCommissonIncomeKnowledgePage';
import { ZenEliteAgentProductionAwardKnowledgePage } from './ZenEliteAgentProductionAwardKnowledgePage';
import { ZenStockAwardForAttractingKnowledgePage } from './ZenStockAwardForAttractingKnowledgePage';
import { ZenEliteAgentCulturalAwardKnowledgePage } from './ZenEliteAgentCulturalAwardKnowledgePage';
import { ZenRevenueShareIncomeKnowledgePage } from './ZenRevenueShareIncomeKnowledgePage';
import { ZenStockAwardForCappingKnowledgePage } from './ZenStockAwardForCappingKnowledgePage';
import { ZenPreCapSppKnowledgePage } from './ZenPreCapSppKnowledgePage';
import { ZenPostCapSppKnowledgePage } from './ZenPostCapSppKnowledgePage';

const ZenKnowledgePageMap: Record<EarningType, React.FC> = {
  [Earning.COMMISSION_INCOME]: ZenCommissionIncomeKnowledgePage,
  [Earning.PRE_CAP_SPP]: ZenPreCapSppKnowledgePage,
  [Earning.STOCK_AWARD_FOR_CAPPING]: ZenStockAwardForCappingKnowledgePage,
  [Earning.POST_CAP_SPP]: ZenPostCapSppKnowledgePage,
  [Earning.ELITE_PRODUCTION_AWARD]: ZenEliteAgentProductionAwardKnowledgePage,
  [Earning.ELITE_CULTURAL_AWARD]: ZenEliteAgentCulturalAwardKnowledgePage,
  [Earning.REVENUE_SHARE_INCOME]: ZenRevenueShareIncomeKnowledgePage,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]: ZenStockAwardForAttractingKnowledgePage,
};

interface KnowledgePageSidebarProps {
  onUnmount?(value?: EarningType): Promise<void> | void;
}

export const KnowledgePageSidebar: React.FC<KnowledgePageSidebarProps> = ({
  onUnmount,
}) => {
  const { tempKnowlegeLandingPage } = useSelector(
    (state: RootState) => state.wealthManagement,
  );

  const dispatch = useDispatch();

  const divContainer = useRef<HTMLDivElement>(null);

  const Content = tempKnowlegeLandingPage
    ? ZenKnowledgePageMap[tempKnowlegeLandingPage]
    : undefined;

  useEffect(() => {
    divContainer.current?.parentElement?.scrollTo(0, 0);

    return () => {
      if (isFunction(onUnmount)) {
        onUnmount(tempKnowlegeLandingPage);
      }
    };
  }, [onUnmount, tempKnowlegeLandingPage]);

  return (
    <ZenSidebarModal
      isOpen={!!tempKnowlegeLandingPage}
      onClose={() => dispatch(setWealthKnowledgeLandingPage(undefined))}
      title={
        tempKnowlegeLandingPage ? EarningTitle[tempKnowlegeLandingPage] : ''
      }
      size='lg'
    >
      <div ref={divContainer}>{Content && <Content />}</div>
    </ZenSidebarModal>
  );
};
