import qs from 'qs';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ZenListingResourceTable from '../components/Zen/listings/ZenListingResourceTable';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenRoute from '../components/Zen/ZenRoute';
import { RootState } from '../types';

const ZenBrokerListingsRoute: React.FC = () => {
  const location = useLocation();
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );

  const officeIds = (userDetail?.offices || [])
    ?.map((office) => office.id)
    .filter(Boolean) as string[];

  return (
    <ZenRoute title='Listings'>
      <ZenPageLayoutWithSearch
        path={[
          { title: 'Home', url: '/' },
          { title: 'Listings', url: '/listings' },
        ]}
        search={search}
        onSearchChange={setSearch}
      >
        <ZenListingResourceTable search={search} officeIds={officeIds} />
      </ZenPageLayoutWithSearch>
    </ZenRoute>
  );
};

export default ZenBrokerListingsRoute;
