import { sum } from 'lodash';
import { useMemo, useState } from 'react';
import { useFieldArray } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useAgentsInfo from '../../../../hooks/useAgentsInfo';
import {
  CommissionSplitsRequest,
  MoneyValueCurrencyEnum,
} from '../../../../openapi/arrakis';
import { saveCommissionSplitsInfos } from '../../../../slices/TransactionBuilderSlice';
import { AppDispatch, RootState } from '../../../../types';
import { calculateCommissionSplitErrors } from '../../../../utils/CommissionHelper';
import { displayAmount, parseMoney } from '../../../../utils/CurrencyUtils';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenButton from '../../ZenButton';
import ZenCommissionSplitContent from '../ZenCommissionSplitContent';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
  Match,
} from './ZenCreateTransactionSteps';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';

const ZenTransactionCommissionStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({
  form: {
    control,
    watch,
    formState: { errors },
    setValue,
    trigger,
  },
  onPrevious,
  onNext,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { transactionBuilderId } = useParams<Match>();
  const {
    transactionBuilder: { transactionBuilder },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(true);
  const [watchParticipants, listingPrice] = watch([
    'commissionParticipant',
    'price',
  ]);

  const totalPercentage = sum(
    watchParticipants
      .filter((p: any) => !p.isDollar)
      .map((item: any) => +item.percent?.value || 0) || 0,
  );

  const { fields } = useFieldArray({
    control,
    name: 'commissionParticipant',
  });

  const totalAmount = parseMoney(
    sum(
      watchParticipants
        .filter((p) => p.isDollar)
        .map((item) => +item.money?.amount! || 0),
    ),
  );

  const isPercentageTypeSplitAvailable = !!watchParticipants.find(
    (p) => !p.isDollar,
  );

  const commissionSplitErrors = calculateCommissionSplitErrors(
    {
      amount:
        transactionBuilder?.grossCommission?.commissionAmount?.amount || 0,
      currency: listingPrice.currency,
    },
    totalPercentage,
    totalAmount,
    errors,
    !!transactionBuilder?.zeroCommissionDeal,
    isPercentageTypeSplitAvailable,
  );

  const toggleSwitches = async (index: number) => {
    const newParticipants = [...watchParticipants];
    newParticipants[index] = {
      ...newParticipants[index],
      isDollar: watchParticipants[index].isDollar,
      id: watchParticipants[index].id,
    };

    setValue('commissionParticipant', newParticipants, {
      shouldValidate: true,
    });
    await trigger('commissionParticipant');
  };

  const handleCommissionSplitStep = async () => {
    const isValid = await trigger();
    const isValidCommission = isValid && !commissionSplitErrors?.length;
    setValid(isValidCommission);
    if (isValidCommission) {
      const commissionSplitsReq: CommissionSplitsRequest[] = watchParticipants.map(
        (participant) => {
          if (!participant.isDollar) {
            return {
              participantId: participant?.id!,
              commission: {
                commissionPercent: participant.percent?.value,
                percentEnabled: true,
              },
            };
          }

          return {
            participantId: participant?.id!,
            commission: {
              commissionAmount: {
                amount: +participant.money?.amount!,
                currency: listingPrice.currency,
              },
              percentEnabled: false,
            },
          };
        },
      );

      setLoading(true);
      const res = await dispatch(
        saveCommissionSplitsInfos(transactionBuilderId!, commissionSplitsReq),
      );
      setLoading(false);
      if (res) {
        onNext();
      }
    }
  };

  const agentIds = useMemo(
    () =>
      transactionBuilder?.allParticipants
        // @ts-ignore
        ?.map((p) => p.agentId!)
        .filter((p) => !!p) || [],
    [transactionBuilder?.allParticipants],
  );

  useAgentsInfo(agentIds);

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div className='space-y-2 divide-y'>
          <div className='flex flex-row justify-between items-center px-2 md:px-4'>
            <p className='text-xl font-zen-title font-medium text-zen-dark-9'>
              Total Commission:{' '}
              {displayAmount({
                amount:
                  transactionBuilder?.grossCommission?.commissionAmount?.amount,
                currency: transactionBuilder?.grossCommission?.commissionAmount
                  ?.currency! as MoneyValueCurrencyEnum,
              })}
            </p>
          </div>
          <ZenCommissionSplitContent
            totalAmount={totalAmount}
            totalPercentage={totalPercentage}
            watchParticipants={watchParticipants}
            commissionSplitErrors={!valid ? commissionSplitErrors : []}
            control={control}
            currency={listingPrice.currency!}
            fields={fields}
            toggleSwitches={toggleSwitches}
            errors={errors}
            participants={
              transactionBuilder?.allParticipants?.map((p) => ({
                id: p.id,
                // @ts-ignore
                firstName: p.agentId
                  ? // @ts-ignore
                    agentById[p.agentId]?.firstName
                  : // @ts-ignore
                    p.firstName,
                // @ts-ignore
                lastName: p.agentId
                  ? // @ts-ignore
                    agentById[p.agentId]?.lastName
                  : // @ts-ignore
                    p.lastName,
                // @ts-ignore
                paidViaBusinessEntity: { name: p.companyName },
                // @ts-ignore
                agentId: p.agentId,
                // @ts-ignore
                participantRole: (p.role as unknown) as ParticipantResponseParticipantRoleEnum,
              }))!
            }
            totalCommission={
              transactionBuilder?.grossCommission?.commissionAmount?.amount || 0
            }
            defaultPercent={fields.length === 1 ? '100' : ''}
            defaultAmount=''
            isRequired
          />
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              isDisabled={loading}
              label='Next'
              onClick={handleCommissionSplitStep}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withCreateTransactionProgress(ZenTransactionCommissionStep);
