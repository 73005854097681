import { Control, UseFormSetValue } from 'react-hook-form-v7';
import AddAnnounceCoverIcon from '../../../assets/img/AddAnnounceCoverIcon.png';
import ZenControlledDraggableImageUploadInput from '../Input/ZenControlledDraggableImageUploadInput';
import { CreateAnnouncementFormData } from './CreateAnnouncementSidebarModel';

interface UploadCoverImageProps {
  control: Control<CreateAnnouncementFormData, object>;
  setValue: UseFormSetValue<CreateAnnouncementFormData>;
}

const UploadCoverImage: React.FC<UploadCoverImageProps> = ({
  control,
  setValue,
}) => {
  return (
    <div>
      <ZenControlledDraggableImageUploadInput<
        CreateAnnouncementFormData,
        'banner'
      >
        name='banner'
        control={control}
        customPlaceholder={
          <div className='flex flex-col justify-center items-center gap-y-2 py-2'>
            <img
              src={AddAnnounceCoverIcon}
              className='w-12 mt-4'
              alt='custom-file-icon'
            />
            <p className='font-zen-body text-sm font-semibold text-zen-dark-7'>
              Drag & drop cover photo (optional)
            </p>
            <p className='font-zen-body text-sm font-semibold text-zen-dark-6'>
              Recommended Dimensions 630 x 370
            </p>
            <div className='w-32 flex flex-row justify-center items-center my-3'>
              <div className='h-px flex-grow bg-zen-dark-5 my-3' />
              <div className='w-9 px-2 py-0.5'>
                <p className='text-zen-dark-6 font-zen-body text-sm font-semibold'>
                  OR
                </p>
              </div>
              <div className='h-px flex-grow bg-zen-dark-5 my-3' />
            </div>
            <span className='font-zen-body font-semibold text-base text-primary-blue mb-4'>
              Browse Files
            </span>
          </div>
        }
        onDelete={() => setValue('banner', '')}
      />
    </div>
  );
};

export default UploadCoverImage;
