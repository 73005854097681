import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import {
  UserAvailabilityResponse,
  UserControllerApi,
} from '../../../../openapi/yenta';
import { queryKeys } from '../../../../query/base/queryKeys';
import { useBaseMutation } from '../../../../query/base/useBaseMutation';
import { saveLoggedInAgentDetails } from '../../../../slices/AgentSlice';
import { AppDispatch, RootState } from '../../../../types';
import { getYentaConfiguration } from '../../../../utils/OpenapiConfigurationUtils';
import ZenCustomSwitch from '../../../Zen/Input/ZenCustomSwitch';

const ZenDoNotDisturbMenuItem: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userDetails = useSelector((state: RootState) => state.auth.userDetail);
  const agentId = userDetails?.id!;
  const userAvailability = (userDetails?.availability ||
    {}) as UserAvailabilityResponse;
  const timeZone =
    userDetails?.availability?.timeZone || DateTime.local().zoneName;

  const { mutate, isLoading } = useBaseMutation({
    queryKey: queryKeys.roar.officeHours(agentId).queryKey,
    mutationFn: async (doNotDisturb: boolean) => {
      const { data } = await new UserControllerApi(
        getYentaConfiguration(),
      ).patchUser(agentId, {
        userAvailability: {
          ...userAvailability,
          timeZone,
          doNotDisturb,
        },
      });
      dispatch(
        saveLoggedInAgentDetails({
          ...userDetails,
          availability: data.availability,
        }),
      );
      return data;
    },
    successMessage: 'Settings Saved',
    errorMessage: 'Failed to save settings',
  });

  const toggle = !!userAvailability.doNotDisturb;

  return (
    <div className='flex flex-row items-center text-white group z-0 rounded-xl justify-start px-2 py-2 my-1 hover:bg-zen-dark-22'>
      <div className='px-1 flex flex-row items-center font-zen-body space-x-2 font-semibold'>
        <span className='items-center text-sm'>Do Not Disturb</span>
        <ZenCustomSwitch
          disabled={isLoading}
          toggle={toggle}
          onClick={() => mutate(!toggle)}
          testId='do-not-disturb'
        />
      </div>
    </div>
  );
};

export default ZenDoNotDisturbMenuItem;
