import { getFormattedDate } from '../../../../utils/DateUtils';

export interface ZenMilliDateCellProps {
  date: number | undefined;
  placeholder?: React.ReactElement | string;
}

const ZenMilliDateCell: React.FC<ZenMilliDateCellProps> = ({
  date,
  placeholder,
}) => {
  if (!date && placeholder) {
    return <div>{placeholder}</div>;
  }

  return <span>{date ? getFormattedDate(date) : 'N/A'}</span>;
};

export default ZenMilliDateCell;
