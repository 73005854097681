import { useSelector } from 'react-redux';
import { DemoModeContainer } from '../../../containers/DemoModeContainer';
import { AgentResponse as ArrakisAgentResponse } from '../../../openapi/arrakis';
import { AgentResponse } from '../../../openapi/yenta';
import { RootState } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import AdminOrSuperAdmin from '../../auth/AdminOrSuperAdmin';
import SelfOrAdminOnly from '../../auth/SelfOrAdminOnly';
import GeminiPill from '../GeminiPill';

interface GeminiAgentResidentialDetailProps {
  detail: AgentResponse | undefined;
  performance: ArrakisAgentResponse | undefined;
}

const GeminiAgentResidentialDetail: React.FC<GeminiAgentResidentialDetailProps> = ({
  detail,
  performance,
}) => {
  const { isAdmin, isSuperAdmin } = useSelector(
    (state: RootState) => state.auth,
  );
  const adminOrSuperAdmin = isAdmin || isSuperAdmin;
  const agentDivision = detail?.divisions?.[0];
  const commissionPlanName = adminOrSuperAdmin
    ? detail?.planMembership?.commissionPlan?.name
    : undefined;
  const hasDivisionData = agentDivision?.name || agentDivision?.logoUrl;
  const hasActiveDebt =
    performance?.activeDebtExists && performance.totalDebt !== undefined;

  const shouldRenderComponent =
    commissionPlanName || hasDivisionData || hasActiveDebt;

  if (!shouldRenderComponent) {
    return null;
  }

  return (
    <div className='px-5 py-2.5 rounded-lg bg-regent-900 font-zen-body-2 text-white'>
      {(commissionPlanName || hasDivisionData) && (
        <div className='flex items-center'>
          {commissionPlanName && (
            <AdminOrSuperAdmin>
              {`${capitalizeEnum(`${commissionPlanName}`)}`}
            </AdminOrSuperAdmin>
          )}
          {commissionPlanName && hasDivisionData && (
            <AdminOrSuperAdmin>
              <div className='h-4.5 w-[1px] bg-grey-200 mx-2.5' />
            </AdminOrSuperAdmin>
          )}
          {hasDivisionData && (
            <div className='flex items-center space-x-2'>
              {agentDivision?.logoUrl && (
                <img
                  className='object-contain w-5 h-5'
                  src={agentDivision?.logoUrl}
                  alt='agent-division'
                />
              )}
              {agentDivision?.name && (
                <span className='text-sm font-medium'>
                  {agentDivision?.name ?? '--'}
                </span>
              )}
            </div>
          )}
        </div>
      )}
      {hasActiveDebt && (
        <SelfOrAdminOnly agentId={detail?.id}>
          <DemoModeContainer hide>
            <div className='flex items-center mt-3 space-x-2'>
              <span className='text-sm font-semibold leading-4'>
                Active Debts:
              </span>
              <GeminiPill
                label={displayAmount(performance?.totalDebt!, {
                  hideCurrency: true,
                })}
                containerClassNames='bg-red-100 text-red-600'
              />
            </div>
          </DemoModeContainer>
        </SelfOrAdminOnly>
      )}
    </div>
  );
};
export default GeminiAgentResidentialDetail;
