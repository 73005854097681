import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import TextareaAutosize from 'react-textarea-autosize';
import {
  MULTILINE_INPUT_MAX_ROWS,
  MULTILINE_INPUT_MIN_ROWS,
} from '../../../constants/LeoConstants';
import { FormFieldTooltipIndexProps } from '../../../types';
import { cn } from '../../../utils/classUtils';

export interface LeoControlledTextInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  isRequired?: boolean;
  helperText?: string;
  maxLength?: number;
  disabled?: boolean;
  onSubmit?(): void;
}

const LeoControlledTextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  noBorder,
  placeholder,
  hideErrorMessage = false,
  tooltipIndex,
  shouldUnregister = true,
  helperText,
  isRequired = false,
  maxLength,
  disabled = false,
  onSubmit,
  ...rest
}: LeoControlledTextInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({ field: { onChange, value, name, onBlur, ref } }) => (
        <div className='w-full space-y-1'>
          <div
            className={cn(
              'relative flex bg-[#171821] items-stretch rounded-3xl overflow-hidden',
              value ? 'text-white' : 'text-zen-dark-9',
            )}
          >
            <TextareaAutosize
              id={name}
              ref={ref}
              value={value}
              onChange={onChange}
              onBlur={(e) => {
                onChange(e.target.value?.trim());
                onBlur();
              }}
              inputMode='text'
              name={name}
              placeholder={placeholder}
              className='appearance-none py-2 px-4 bg-[#171821] w-full resize-none focus:rounded-3xl border-transparent focus:border focus:border-opacity-30 focus:border-primary-blue font-zen-body font-normal text-base m-px'
              wrap='soft'
              minRows={MULTILINE_INPUT_MIN_ROWS}
              maxRows={MULTILINE_INPUT_MAX_ROWS}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && onSubmit) {
                  onSubmit();
                }
              }}
              maxLength={maxLength}
              data-tooltip-index={tooltipIndex}
              disabled={disabled}
            />
            <div
              className={cn(
                'absolute right-0 bottom-0 bg-[#171821] pl-2 pr-4 z-0',
                !!value ? 'visible' : 'invisible',
              )}
            >
              <p className='text-zen-dark-6 text-xs'>press enter to send</p>
            </div>
          </div>
        </div>
      )}
    />
  );
};
export default LeoControlledTextInput;
