import qs from 'qs';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ZenAgentTransactions from '../components/Zen/Transaction/ZenAgentTransactions';
import ZenSearchBar from '../components/Zen/ZenSearchBar';

type Match = {
  id: string;
};

const ZenAgentDetailTransactionsRoute: React.FC = () => {
  const location = useLocation();
  const { id: agentId } = useParams() as Match;

  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );

  return (
    <div className='p-4'>
      <div className='w-full md:w-72 ml-auto mb-5 md:mb-0'>
        <ZenSearchBar onChange={setSearch} value={search!} />
      </div>
      <ZenAgentTransactions agentId={agentId} search={search} />
    </div>
  );
};
export default ZenAgentDetailTransactionsRoute;
