import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowRight,
  faCheck,
  faEnvelope,
  faRotate,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../../../openapi/arrakis';
import { fetchTransactionPaymentInvoices } from '../../../../slices/TransactionSlice';
import { AppDispatch, EnumMap, RootState } from '../../../../types';
import { cn } from '../../../../utils/classUtils';
import { getTransactionTransitionToButtonText } from '../../../../utils/TransactionHelper';
import {
  GeminiTransactionHeaderVariantType,
  getTransactionStatusVariant,
} from './GeminiTransactionStatusInfo';

const getIconForTransactionStatus = (
  status: TransactionLifecycleStateValueStateEnum,
): IconProp => {
  switch (status) {
    case TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved:
      return faEnvelope;
    case TransactionLifecycleStateValueStateEnum.CommissionValidated:
      return faCheck;
    default:
      return faArrowRight;
  }
};

interface GeminiTransactionActionProps {
  action: TransactionLifecycleStateValueStateEnum;
  onClick(): void;
  transaction: TransactionResponse;
}

const GeminiTransactionAction: React.FC<GeminiTransactionActionProps> = ({
  action,
  onClick,
  transaction,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { hasPaymentInvoices } = useSelector(
    (state: RootState) => state.transaction,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchInvoices = async () => {
      setIsLoading(true);
      await dispatch(fetchTransactionPaymentInvoices(transaction));
      setIsLoading(false);
    };

    if (action === TransactionLifecycleStateValueStateEnum.PaymentAccepted) {
      fetchInvoices();
    }
  }, [dispatch, action, transaction]);

  const isPaymentAccepted =
    action === TransactionLifecycleStateValueStateEnum.PaymentAccepted;
  const isPaymentWithoutInvoices = isPaymentAccepted && !hasPaymentInvoices;

  const statusVariant = getTransactionStatusVariant(
    transaction.lifecycleState?.state!,
  );

  const transactionActionButtonClasses: EnumMap<
    GeminiTransactionHeaderVariantType,
    string
  > = {
    default: 'text-grey-600',
    primary: 'bg-primary-navy',
    success: 'bg-midnight-green-900',
    warning: 'bg-yellow-900',
    danger: 'bg-zen-danger',
    alert: 'bg-purple-600',
  };

  return (
    <button
      type='button'
      onClick={onClick}
      disabled={isPaymentWithoutInvoices}
      className={cn(
        'w-full flex items-center justify-center px-4 py-2 rounded-lg self-center shrink-0 text-white',
        transactionActionButtonClasses[statusVariant],
      )}
    >
      {isLoading && (
        <FontAwesomeIcon icon={faRotate} className='animate-spin mr-2' />
      )}
      <span className='flex flex-row items-center font-zen-body-2 font-medium text-sm capitalize'>
        <FontAwesomeIcon icon={getIconForTransactionStatus(action)} size='lg' />
        {!!getTransactionTransitionToButtonText(transaction!, action) && (
          <span className='ml-1'>
            {getTransactionTransitionToButtonText(transaction!, action)}
          </span>
        )}
      </span>
    </button>
  );
};

export default GeminiTransactionAction;
