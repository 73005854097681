import PhoneInput from 'react-phone-input-2';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import 'react-phone-input-2/lib/style.css';
import { FormFieldTooltipIndexProps } from '../types';
import FormErrorMessage from './FormErrorMessage';

export interface ControlledPhoneNumberInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  readOnly?: boolean;
  placeholder?: string;
  isRequired?: boolean;
}

const ControlledPhoneNumberInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  readOnly,
  placeholder,
  tooltipIndex,
  shouldUnregister = true,
  isRequired = false,
  ...rest
}: ControlledPhoneNumberInputV7Props<TFieldValues, TName>) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              {label}
              {isRequired && <span className='text-error'>*</span>}
            </label>
          )}
          <PhoneInput
            onlyCountries={['us', 'ca']}
            country='us'
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            inputProps={{
              readOnly,
              name,
              type: 'text',
              'data-tooltip-index': tooltipIndex,
            }}
            inputStyle={{
              width: '100%',
              backgroundColor: readOnly ? 'rgb(250 250 250)' : '',
            }}
          />
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledPhoneNumberInputV7;
