import { faMessage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { displayDate } from '../../../../utils/DateUtils';
import ChatHistoryCard from './ChatHistoryCard';
import { SessionHistory } from './LeoChatHistory';

interface ChatHistoryByDayCardProps {
  history: SessionHistory[];
}

const ChatHistoryByDayCard: React.FC<ChatHistoryByDayCardProps> = ({
  history,
}) => {
  return (
    <div className='flex flex-col mt-5'>
      <div className='flex flex-row items-center'>
        <FontAwesomeIcon icon={faMessage} className='text-zen-dark-6 text-xs' />
        <p className='flex-grow text-zen-dark-6 text-xs ml-2'>
          {displayDate(history[0].start_time)}
        </p>
      </div>
      <div className='flex-grow'>
        {history.map((historyData) => (
          <ChatHistoryCard key={historyData.id} {...historyData} />
        ))}
      </div>
    </div>
  );
};

export default ChatHistoryByDayCard;
