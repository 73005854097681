import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { displayAmount } from '../../utils/CurrencyUtils';
import { TierRevshareResponse } from '../../openapi/arrakis';

interface RevShareInsightsContributionByTierCardsProps {
  tierRevShare?: TierRevshareResponse;
}

const RevShareInsightsContributionByTierCards: React.FC<RevShareInsightsContributionByTierCardsProps> = ({
  tierRevShare,
}) => {
  return (
    <div className='md:flex items-center space-y-4 md:space-y-0 md:space-x-3 font-zen-body'>
      <div
        className='flex-1 p-4 rounded-lg border border-zen-dark-4'
        aria-label='earned-card'
      >
        <p className='text-center font-semibold text-xl md:text-2xl text-zen-dark-9'>
          {displayAmount(tierRevShare?.earnedRevshareAmount)}
        </p>
        <p className='mt-1 text-center text-zen-dark-12'>Earned</p>
      </div>
      <div
        className='flex-1 p-4 rounded-lg border border-zen-dark-4 bg-grey-100'
        aria-label='missed-card'
      >
        <p className='text-center font-semibold text-xl md:text-2xl text-zen-dark-12'>
          {displayAmount(tierRevShare?.missedRevshareAmount)}
        </p>
        <p className='mt-1 text-center text-zen-dark-12'>
          <FontAwesomeIcon icon={faLock} className='text-zen-orange-1 mr-1' />
          Missed
        </p>
      </div>
    </div>
  );
};

export default RevShareInsightsContributionByTierCards;
