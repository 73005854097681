import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import { RootState } from '../../../types';
import {
  fetchPriorAgentDetail,
  savePriorDetail,
  updatePriorAgent,
} from '../../../slices/AgentSlice';
import ZenAdd from '../../Zen/ZenAdd';
import ZenRemove from '../../Zen/ZenRemove';
import ResourceContainer from '../../ResourceContainer';
import ZenCard from '../../Zen/ZenCard';
import ZenAvatar from '../../Zen/ZenAvatar';
import { getAgentFullName } from '../../../utils/AgentHelper';
import Hover from '../../Hover';
import DefaultLoader from '../../DefaultLoader';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import ZenAgentManagePreviousUserModal from './ZenAgentManagePreviousUserSidebarModal';

interface ZenAgentPriorUserProps {}

const ZenAgentPriorUser: React.FC<ZenAgentPriorUserProps> = () => {
  const {
    agentDetail: { detailResponse, priorAgentDetailResponse },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isRemovePriorAgent, setIsRemovePriorAgent] = useState<boolean>(false);
  const { data: agentDetailResponse } = detailResponse;

  const fetchPriorAgentInfo = useCallback(async () => {
    if (agentDetailResponse?.priorUserId) {
      await dispatch(fetchPriorAgentDetail(agentDetailResponse?.priorUserId));
    } else {
      await dispatch(
        savePriorDetail({
          loading: false,
          name: 'priorAgentDetail',
          data: undefined,
          error: null,
        }),
      );
    }
  }, [agentDetailResponse?.priorUserId, dispatch]);

  useEffect(() => {
    fetchPriorAgentInfo();
  }, [fetchPriorAgentInfo]);

  const handleRemovePriorAgent = async () => {
    setIsRemovePriorAgent(true);
    await dispatch(updatePriorAgent(agentDetailResponse?.id!, null, true));
    setIsRemovePriorAgent(false);
  };

  return (
    <React.Fragment>
      <AuthorizationContainer asyncResponse={detailResponse}>
        <ZenCard
          title='Previous Users'
          testId='previous-users'
          RightComponent={
            <ZenAdd
              disabled={!!agentDetailResponse?.priorUserId}
              onClick={() => setIsOpenModal(true)}
            />
          }
        >
          <ResourceContainer
            loading={priorAgentDetailResponse?.loading}
            isEmpty={!priorAgentDetailResponse?.data}
            errorCode={priorAgentDetailResponse?.error}
            resourceName='Previous Users'
            emptyIcon={
              <FontAwesomeIcon
                icon={solid('triangle-exclamation')}
                className='text-gray-400'
              />
            }
            emptyMessage='No previous user found.'
          >
            <div className='flex flex-row items-center justify-between px-4 py-2'>
              <Link
                to={`/people/${priorAgentDetailResponse?.data?.id}/profile`}
                className='flex flex-row items-center space-x-2'
              >
                <ZenAvatar
                  name={getAgentFullName(priorAgentDetailResponse?.data!)}
                  imageUrl={priorAgentDetailResponse?.data?.avatar}
                  size='sm'
                />
                <p className='font-zen-body font-normal text-base text-zen-dark-9'>
                  {getAgentFullName(priorAgentDetailResponse?.data!)}
                </p>
              </Link>
              <div className='flex flex-row items-center'>
                <Hover
                  config={{ trigger: 'hover', placement: 'top' }}
                  hoverComponent={
                    <p className='font-zen-body font-normal text-sm text-zen-dark-9 px-1.5 py-0.5'>
                      Remove
                    </p>
                  }
                >
                  <ZenRemove
                    onClick={handleRemovePriorAgent}
                    disabled={isRemovePriorAgent}
                  />
                </Hover>
                {isRemovePriorAgent && (
                  <DefaultLoader noPadding iconSize='small' />
                )}
              </div>
            </div>
          </ResourceContainer>
        </ZenCard>
      </AuthorizationContainer>
      {isOpenModal && (
        <ZenAgentManagePreviousUserModal
          onClose={() => setIsOpenModal(false)}
          agentId={agentDetailResponse?.id!}
        />
      )}
    </React.Fragment>
  );
};

export default ZenAgentPriorUser;
