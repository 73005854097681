import React, { useEffect, useRef } from 'react';
import { Remarkable } from 'remarkable';
import { linkify } from 'remarkable/linkify';
import { v4 as uuid } from 'uuid';
import AnalyticsService from '../../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../../types';

interface ZenAgentJourneyDescriptionProps {
  description: string;
}

interface Link {
  id: string;
  url: string;
}

const renderHtml = (string: string) => {
  var md = new Remarkable().use(linkify);

  md.set({
    html: true,
    breaks: true,
    typographer: true,
    linkTarget: '_blank',
  });

  let links: Link[] = [];

  md.renderer.rules.link_open = function (tokens, idx) {
    const linkId = uuid();
    links.push({ id: linkId, url: tokens[idx].href });

    return (
      '<a id="' + linkId + '" target="_blank" href="' + tokens[idx].href + '">'
    );
  };

  return { htmlstring: md.render(string), links };
};

const ZenAgentJourneyDescription: React.FC<ZenAgentJourneyDescriptionProps> = ({
  description,
}) => {
  const descRef = useRef<HTMLDivElement>(null);

  const descriptionLinkHandler = (url: string) => {
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.ONBOARDING_CHECKLIST_CLICKED_LINK,
      {
        link: url,
      },
    );
  };

  useEffect(() => {
    if (description) {
      const { htmlstring, links } = renderHtml(description);
      if (descRef?.current) {
        descRef.current.innerHTML = htmlstring;
      }

      if (!!links?.length) {
        links?.forEach(({ id, url }) => {
          document
            .getElementById(id)
            ?.addEventListener('click', () => descriptionLinkHandler(url));
        });
      }
    }

    return () => {
      const { links } = renderHtml(description);
      if (!!links?.length) {
        links?.forEach(({ id, url }) => {
          document
            .getElementById(id)
            ?.removeEventListener('click', () => descriptionLinkHandler(url));
        });
      }
    };
  }, [description]);

  return (
    <div
      id='agent-journey-description-markdown'
      className='font-zen-body font-normal text-sm text-zen-dark-9'
      ref={descRef}
    />
  );
};

export default ZenAgentJourneyDescription;
