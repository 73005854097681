import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import flag from '../../../../assets/img/flag.png';
import { ItemResponseStatusEnum } from '../../../../openapi/sherlock';
import { useFetchChecklistById } from '../../../../query/transaction/useChecklist';
import { RootState } from '../../../../types';
import ResourceContainer from '../../../ResourceContainer';
import ZenChecklistLabel from '../../../Zen/Transaction/ZenChecklistLabel';
import GeminiChecklistProgressCell from '../../Transaction/GeminiChecklistProgressCell';

interface GeminiChecklistPopperProps {
  checklistId: string | undefined;
}

const GeminiChecklistPopper: React.FC<GeminiChecklistPopperProps> = ({
  checklistId,
}) => {
  const { checklistProgressById } = useSelector(
    (state: RootState) => state.checklist,
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const checklistProgress = checklistId
    ? checklistProgressById[checklistId]
    : undefined;

  const { data, isLoading } = useFetchChecklistById(checklistId, isOpen);

  const filterChecklist = useMemo(() => {
    const checklist = (data?.items || [])
      ?.filter((item) => !item?.hidden)
      .filter(
        (item) =>
          item?.status === ItemResponseStatusEnum.RevisionRequested ||
          !!item?.required,
      );

    return checklist;
  }, [data]);

  const CompletedItemCount = filterChecklist.reduce((count, item) => {
    if (item.complete) {
      return count + 1;
    }
    return count;
  }, 0);

  return (
    <div onMouseLeave={() => setIsOpen(false)}>
      <Popover
        isOpen={isOpen}
        positions={['top', 'bottom']}
        onClickOutside={() => setIsOpen(false)}
        parentElement={document.getElementById('main-page')!}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor='white'
            arrowSize={10}
            arrowStyle={{
              bottom: 1,
              zIndex: 1,
            }}
            className='popover-arrow-container'
            arrowClassName='popover-arrow'
          >
            <div className='md:w-[420px] w-[400px] border rounded-[20px] border-gray-300 bg-white divide-y-2 space-y-2 py-2 text-dark shadow-2xl'>
              <div className='flex items-center justify-between px-6'>
                <div className='text-base pt-2'>
                  Checklist Completed: {CompletedItemCount}/
                  {filterChecklist?.length ?? 0}
                </div>
                <div
                  onClick={() => setIsOpen(false)}
                  className='bg-zen-dark-10 rounded-full w-5 h-5 flex items-center justify-center text-white'
                >
                  <MdOutlineClose size={15} />
                </div>
              </div>
              <div className='px-6 max-h-64 scrollbar overflow-y-auto'>
                <ResourceContainer
                  loading={isLoading}
                  isEmpty={false}
                  resourceName='Checklist'
                >
                  <div className='py-5 space-y-4'>
                    {(filterChecklist || [])?.map((checklist) => (
                      <div className='flex items-center' key={checklist.id}>
                        <div className='w-3/4'>
                          <div className='text-base'>
                            {checklist.position}. {checklist.name}
                          </div>
                          <div className='flex items-center space-x-1'>
                            <div
                              className={classNames('text-sm ', {
                                'text-red-500': checklist.required,
                              })}
                            >
                              {checklist.required ? 'Required' : 'Optional'}
                            </div>
                            {checklist.urgent && (
                              <div className='flex items-center space-x-1'>
                                <img
                                  src={flag}
                                  alt='flag'
                                  className='-mt-1 h-4 w-4 object-contain'
                                />
                                <div className='text-sm text-red-500'>
                                  Urgent
                                </div>
                              </div>
                            )}
                            {checklist.dueDate && (
                              <div className='flex items-center space-x-1'>
                                <div className='text-gray-400 text-sm ml-2'>
                                  Due:
                                  {DateTime.fromISO(checklist.dueDate).toFormat(
                                    ' LL/dd/y',
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          <ZenChecklistLabel
                            status={checklist.status}
                            isRequired={checklist?.required!}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </ResourceContainer>
              </div>
            </div>
          </ArrowContainer>
        )}
      >
        {!!checklistId ? (
          <div
            onMouseEnter={() => setIsOpen(true)}
            aria-label='checklist-popper'
            className='cursor-default'
          >
            <GeminiChecklistProgressCell
              total={checklistProgress?.itemCount || 0}
              completed={checklistProgress?.completedCount || 0}
            />
          </div>
        ) : (
          <div className='pl-2' aria-label='checklist-popper'>
            N/A
          </div>
        )}
      </Popover>
    </div>
  );
};

export default GeminiChecklistPopper;
