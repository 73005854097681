import { faArrowDownToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { last } from 'lodash';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ApplicationResponseApplicationTypeEnum } from '../openapi/yenta';
import {
  getIndexFromStatus,
  getIndexFromStatusWithLOI,
  onboardingSteps,
  onboardingStepsWithLOI,
} from '../testUtils/OnboardingUtils';
import { RootState } from '../types';
import { useLogout } from '../hooks/useLogout';
import IconButton from './IconButton';
import ApplicationStepProgressHeader from './onboarding/ApplicationStepProgressHeader';
import RealHeader from './RealHeader';
import SupportWidget from './support/SupportWidget';

export interface ApplicationLayoutProps {
  showFooter?: boolean;
}

const ApplicationLayout: React.FC<ApplicationLayoutProps> = ({
  children,
  showFooter = true,
}) => {
  const location = useLocation();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const application = last(userDetail?.applications);
  const { loi } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const showLOIStep: boolean =
    loi === 'true' ||
    application?.applicationType ===
      ApplicationResponseApplicationTypeEnum.LetterOfIntent;

  const logout = useLogout();

  return (
    <div className='h-full-window scrollbar overflow-y-auto w-full flex flex-col'>
      <RealHeader />
      <div className='py-4'>
        <ApplicationStepProgressHeader
          steps={showLOIStep ? onboardingStepsWithLOI : onboardingSteps}
          inProgressIndex={
            showLOIStep
              ? getIndexFromStatusWithLOI(application?.nextStatus!)
              : getIndexFromStatus(application?.nextStatus!)
          }
        />
      </div>
      <div className='flex-auto'>{children}</div>
      {showFooter && (
        <div className='flex flex-row justify-between items-center p-4'>
          <IconButton
            label='Logout'
            variant='outline'
            leftIcon={
              <FontAwesomeIcon
                icon={faArrowDownToSquare}
                className='-rotate-90 mr-1 text-lg'
              />
            }
            buttonType='button'
            onClick={async () => await logout()}
          />

          <SupportWidget />
        </div>
      )}
    </div>
  );
};

export default ApplicationLayout;
