import { useCallback, useEffect, useState } from 'react';
import { MdOutlineDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchOfficeDetailById } from '../../../../slices/OfficeSlice';
import {
  AdditionalFeesRequest,
  AttachedFeeRequest,
  AttachedFeeRequestFeeTypeEnum,
  AttachedFeeValueFeeTypeEnum,
  AttachedFeeValueRecipientTypeEnum,
  MoneyValueCurrencyEnum,
} from '../../../../openapi/arrakis';
import { saveAdditionalFeesInfos } from '../../../../slices/TransactionBuilderSlice';
import { AppDispatch, RootState, YesNoType } from '../../../../types';
import { displayAmount, zeroMoneyValue } from '../../../../utils/CurrencyUtils';
import { getParticipantName } from '../../../../utils/ParticipantHelper';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledRadioInput from '../../Input/ZenControlledRadioInput';
import ZenButton from '../../ZenButton';
import ZenAddAdditionalFeeAndRebates from './SidebarForms/ZenAddAdditionalFeeAndRebates';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
  ExtendedAttachedFeeValue,
  Match,
} from './ZenCreateTransactionSteps';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';

const ZenTransactionAdditionalFeeStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({
  form: {
    control,
    watch,
    setValue,
    trigger,
    formState: { isValidating },
  },
  onPrevious,
  onNext,
}) => {
  const {
    userIds: { agentById },
    transactionBuilder: { transactionBuilder },
  } = useSelector((state: RootState) => state);
  const [
    openAdditionalFormSidebarModal,
    setOpenAdditionalFormSidebarModal,
  ] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const { transactionBuilderId } = useParams<Match>();

  const builderOfficeId = transactionBuilder?.agentsInfo?.officeId;

  const hasRebate = watch('hasRebate');

  const attachedFeesAndRebates = watch('additionalFeesAndRebates');

  const deleteFees = (index: number) => {
    const additionalFeesAndRebates = watch('additionalFeesAndRebates');
    additionalFeesAndRebates.splice(index, 1);

    setValue('additionalFeesAndRebates', additionalFeesAndRebates);
  };

  const handleAdditionalFeesStep = async () => {
    const isValid = await trigger();
    if (isValid) {
      let additionalFeeRequest: AdditionalFeesRequest;
      if (hasRebate === YesNoType.NO) {
        setValue('additionalFeesAndRebates', []);
        additionalFeeRequest = {
          hasAdditionalFees: false,
          additionalFeesParticipantInfos: [],
        };
      } else {
        additionalFeeRequest = {
          hasAdditionalFees: true,
          additionalFeesParticipantInfos: attachedFeesAndRebates.map(
            (participant) =>
              ({
                amount: participant.amount,
                description: participant.description,
                feeType: participant.feeType as AttachedFeeRequestFeeTypeEnum,
                participantId: participant.id,
                recipientType: participant.recipientType,
                addedBySystem: participant.addedBySystem,
              } as AttachedFeeRequest),
          ),
        };
      }

      setLoading(true);
      const res = await dispatch(
        saveAdditionalFeesInfos(transactionBuilderId!, additionalFeeRequest),
      );
      setLoading(false);
      if (res) {
        onNext();
      }
    }
  };

  useEffect(() => {
    const isFMLSFeeApplicable = watch('isFMLSProperty');
    const additionalFee: ExtendedAttachedFeeValue[] = !!transactionBuilder
      ?.additionalFeesInfo?.additionalFeesParticipantInfos?.length
      ? transactionBuilder?.additionalFeesInfo?.additionalFeesParticipantInfos.map(
          (p) => {
            const participant = transactionBuilder.allParticipants?.find(
              (participant) => participant.id === p.participantId,
            );

            return {
              name: participant
                ? // @ts-ignore
                  getParticipantName(agentById[participant?.agentId!])
                : 'N/A',
              amount: p?.amount,
              feeType: (p?.feeType! as unknown) as AttachedFeeValueFeeTypeEnum,
              recipientType: (p?.recipientType! as unknown) as AttachedFeeValueRecipientTypeEnum,
              description: p?.description,
              id: p?.participantId,
              addedBySystem: p.addedBySystem ?? false,
            };
          },
        )
      : [];

    if (isFMLSFeeApplicable === YesNoType.YES) {
      if (additionalFee.length > 0) setValue('hasRebate', YesNoType.YES);
      setValue('additionalFeesAndRebates', additionalFee);
    } else {
      const filterOnNoFee = additionalFee.filter(
        (fee) => fee.description !== 'FMLS Closing Fee',
      );
      if (filterOnNoFee.length === 0) setValue('hasRebate', YesNoType.NO);
      setValue('additionalFeesAndRebates', filterOnNoFee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOfficeDetails = useCallback(async () => {
    if (!builderOfficeId) {
      return;
    }

    await dispatch(fetchOfficeDetailById(builderOfficeId));
  }, [builderOfficeId, dispatch]);

  useEffect(() => {
    fetchOfficeDetails();
  }, [fetchOfficeDetails]);

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <p className='text-xl font-zen-title font-medium text-zen-dark-9 mb-4'>
          Are there any additional fees or rebates?
        </p>
        <ZenControlledRadioInput<CreateTransactionFormState, 'hasRebate'>
          name='hasRebate'
          control={control}
          inlineOptions
          shouldUnregister={false}
          options={Object.values(YesNoType).map((value) => {
            return { label: capitalizeEnum(value), value };
          })}
          defaultValue={YesNoType.NO}
          rules={{
            validate: (value) => {
              if (
                value === YesNoType.YES &&
                attachedFeesAndRebates.length === 0 &&
                !openAdditionalFormSidebarModal &&
                isValidating
              ) {
                return 'It is required to add at least 1 fee/rebate';
              }
              return undefined;
            },
          }}
        />
        {hasRebate === YesNoType.YES && (
          <div>
            <div className='flex flex-row items-center justify-between mt-10'>
              <p className='text-xl font-zen-title font-medium text-zen-dark-9'>
                Additional Fees & Rebates
              </p>
              <div>
                <ZenButton
                  label='Add fees & rebates'
                  variant='primary-link'
                  LeftIconComponent={
                    <FontAwesomeIcon
                      icon={faCirclePlus}
                      className='text-primary-blue mr-1'
                    />
                  }
                  onClick={() => setOpenAdditionalFormSidebarModal(true)}
                />
              </div>
            </div>
            <div className='grid grid-cols-5 bg-zen-light-gray-1 px-2 py-3.5 mt-2 font-zen-body font-bold text-zen-gray-5 text-xs uppercase items-start'>
              <p>Participant</p>
              <p>Amount</p>
              <p>Fee Type</p>
              <p>Description</p>
            </div>
            <div>
              {attachedFeesAndRebates?.map((item, index) => {
                return (
                  <div
                    className='grid grid-cols-5 px-2 py-3.5 font-zen-body font-semibold text-sm text-zen-dark-9 items-start'
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item.id}-${index}`}
                  >
                    <p>{item.name}</p>
                    <p>
                      {displayAmount(
                        item.amount ||
                          zeroMoneyValue(MoneyValueCurrencyEnum.Usd),
                        {
                          hideCurrency: true,
                          hideZeroCents: true,
                        },
                      )}
                    </p>
                    <p>{capitalizeEnum(item.feeType! || '')}</p>
                    <p className='overflow-ellipsis overflow-hidden'>
                      {item.description}
                    </p>
                    <div className='cursor-pointer flex justify-center items-center'>
                      <MdOutlineDelete
                        title='delete'
                        className='text-zen-danger'
                        size={20}
                        onClick={() => deleteFees(index)}
                      />
                    </div>
                  </div>
                );
              })}
              {attachedFeesAndRebates.length === 0 && (
                <div className='pb-4 border-b border-zen-dark-4'>
                  <p className='flex justify-center font-zen-body text-zen-dark-6 pt-2'>
                    No additional fees and rebates
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
        {openAdditionalFormSidebarModal && (
          <ZenAddAdditionalFeeAndRebates
            isOpen
            onClose={() => setOpenAdditionalFormSidebarModal(false)}
            control={control}
            watch={watch}
            setValue={setValue}
            trigger={trigger}
          />
        )}
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              isDisabled={loading}
              label='Next'
              onClick={handleAdditionalFeesStep}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withCreateTransactionProgress(ZenTransactionAdditionalFeeStep);
