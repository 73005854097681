import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import ZenMyListings from '../components/Zen/listings/ZenMyListings';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenButton from '../components/Zen/ZenButton';
import { createTransactionBuilder } from '../slices/TransactionBuilderSlice';
import { RootState } from '../types';
import { fetchTransactionsCountGroupByLifecycleState } from '../slices/TransactionSlice';

const ZenMyListingsIndexRoute: React.FC = () => {
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );

  const PageLayoutComponent =
    location.pathname === '/listings/draft'
      ? ZenPageLayout
      : ZenPageLayoutWithSearch;

  useEffect(() => {
    dispatch(
      fetchTransactionsCountGroupByLifecycleState(userDetail?.id!, 'LISTING'),
    );
  }, [userDetail?.id, dispatch]);

  return (
    <PageLayoutComponent
      path={[
        { title: 'Home', url: '/' },
        { title: 'My Listings', url: '/listings' },
      ]}
      search={search}
      onSearchChange={setSearch}
    >
      <div className='md:flex items-center justify-between p-4 py-4'>
        <div className='text-xl font-zen-body font-semibold'>My Listings</div>
        <div className='flex items-center space-x-4'>
          <ZenButton
            variant='primary'
            label='Create Listing'
            LeftIconComponent={
              <FontAwesomeIcon
                className='text-white mr-1'
                size='sm'
                icon={faPlus}
              />
            }
            onClick={async () => {
              const transactionBuilderId = await dispatch(
                createTransactionBuilder('LISTING'),
              );
              if (transactionBuilderId) {
                history.push(`/listing/create/${transactionBuilderId}`);
              }
            }}
          />
        </div>
      </div>
      <div className='lg:py-1.5'>
        <ZenMyListings agentId={userDetail?.id!} search={search} />
      </div>
    </PageLayoutComponent>
  );
};

export default ZenMyListingsIndexRoute;
