import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackgroundVector from '../../../../assets/img/wealthManagement/bg_vector.svg';
import RealLogo from '../../../../assets/img/wealthManagement/RealLogo.svg';
import { ABOUT_REAL_URL } from '../../WealthManagementUtils';

const LearnMoreAboutRealCard: React.FC = () => {
  return (
    <article className='h-full w-full p-6 bg-white rounded-xl font-zen-body-2 flex flex-col relative shadow-zen-card'>
      <header className='w-full flex items-start'>
        <img src={RealLogo} className='h-6' alt='Real Logo' />
      </header>
      <img
        src={BackgroundVector}
        alt='Logo'
        className='absolute right-0 top-0'
      />
      <div className='mt-6'>
        <section>
          <p className='text-2xl text-primary-navy'>
            Turn your thing into{' '}
            <span className='text-zen-danger'>everything</span>.
          </p>
        </section>
        <section className='text-sm text-grey-600 mt-3'>
          The real estate industry is changing, and we&apos;re revolutionizing
          how you work, build wealth, and thrive. Turns out you can have it all.
        </section>
        <section className='mt-5'>
          <b className='text-2xl font-medium text-mineshaft'>50+</b>
          <p className='mt-1 text-sm font-medium text-zen-dark-12'>
            States, DC, 4 Canadian Provinces
          </p>
        </section>
        <section className='mt-5'>
          <b className='text-2xl font-medium text-mineshaft'>18,000+</b>
          <p className='mt-1 text-sm font-medium text-zen-dark-12'>
            Licensed Real Estate Agents Across the Company
          </p>
        </section>
        <section className='mt-5'>
          <b className='text-2xl font-medium text-mineshaft'>$29.3B</b>
          <p className='mt-1 text-sm font-medium text-zen-dark-12'>
            Value of Homes Sold in the Last 12 months (Q1/2024)
          </p>
        </section>
      </div>
      <section
        onClick={() => window.open(ABOUT_REAL_URL, '_blank')}
        className='px-4 py-3 border-t text-primary-blue border-regent-300 mx-[-24px] mb-[-24px] mt-6 flex items-center justify-center cursor-pointer'
      >
        <span className='text-base font-medium capitalize'>
          Learn More About Real
        </span>
        <FontAwesomeIcon
          icon={faArrowUpRightFromSquare}
          className='text-sm font-normal ml-1.5'
        />
      </section>
    </article>
  );
};

export default LearnMoreAboutRealCard;
