import { DateTime } from 'luxon';

type WealthPlanDashboardDateCountProps = {
  date: DateTime;
  count: number;
  label: string;
};
export const WealthPlanDashboardDateCount: React.FC<WealthPlanDashboardDateCountProps> = ({
  date,
  count,
  label,
}) => {
  return (
    <div
      className='font-normal text-sm font-zen-body'
      data-testid={`date-count-${label}`}
    >
      <p> {date.toFormat('LL/dd/yyyy')}</p>
      <p className='text-zen-dark-6'>{date.toFormat('hh:mm a')}</p>
      <div className='inline-block py-1 px-2 rounded-lg whitespace-nowrap bg-gray-100'>
        <div className='flex items-center'>
          <p className='text-gray-500 pr-1'>{label} </p>
          <p className='text-gray-800'>
            {count} {count === 1 ? 'time' : 'times'}
          </p>
        </div>
      </div>
    </div>
  );
};
