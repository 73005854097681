import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Updated import
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7'; // Updated import
import { useSelector } from 'react-redux';
import { OptionsType, OptionTypeBase } from 'react-select';
import { Popover } from 'react-tiny-popover';
import { ReactComponent as FilterIcon } from '../../assets/icons/zen/zen-filter.svg';
import { DirectoryVendorCreateRequestRoleEnum } from '../../openapi/yenta';
import { usePageQueryParams } from '../../query/base/usePageQueryParams';
import { FilterValuesFormData } from '../../routes/ZenDirectoryRoute';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum, ISelectOption, RootState } from '../../types';
import { cn } from '../../utils/classUtils';
import ZenControlledMultiSelectInput from '../Zen/Input/ZenControlledMultiSelectInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenButton from '../Zen/ZenButton';
import {
  getDirectoryVendorRoleLabel,
  getDirectoryVendorRolesByUserCountry,
} from './DirectoryUtils';
import { countAppliedFilters, prepareDataForApi } from './filterUtils';

/**
 * By default, Popover is injected into document.body, this would cause scrolling
 * within the Popover to stop working.
 *
 * Pass in a parentRef from parent component fixes the scrolling issue.
 */
const ZenDirectoryFilter = ({
  parentRef,
  onFilterApplied,
}: {
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
  onFilterApplied: (data: FilterValuesFormData) => void;
}) => {
  const {
    handleSubmit,
    control,
    reset,
    getValues,
  } = useForm<FilterValuesFormData>(); // Updated usage

  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [rolesLength, setRolesLength] = useState<number>(0);
  const [appliedFilters, setAppliedFilters] = useState<FilterValuesFormData[]>(
    [],
  );
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const { parsed: queryParams } = usePageQueryParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string, eventData?: Record<string, unknown>) => {
      AnalyticsService.instance().logEvent(event, eventData);
    }, 500),
    [],
  );

  const handleRoleChange = (roles: OptionsType<OptionTypeBase>) => {
    if (roles?.length > rolesLength) {
      logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_FILTERS_ADD_ROLE, {
        role: roles.at(-1)?.label,
      });
    }
    setRolesLength(roles.length);
  };

  const handleApplyFilters = (data: FilterValuesFormData) => {
    const prepData = prepareDataForApi(data);
    setAppliedFilters([prepData]);
    onFilterApplied(prepData);
    setPopoverOpen(false);
  };

  const handleClickOutside = (e: MouseEvent) => {
    e.stopPropagation();
    const data = getValues();
    handleApplyFilters(data);
    logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_FILTERS_CLOSED);
  };

  const onSubmit = (data: FilterValuesFormData) => {
    handleApplyFilters(data);
    logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_FILTERS_APPLIED);
  };

  const handleClear = () => {
    reset({
      participantRole: [],
      name: '',
      id: '',
      email: '',
      phoneNumber: '',
    });
    setAppliedFilters([]);
    onFilterApplied({
      participantRole: undefined,
      name: undefined,
      id: undefined,
      email: undefined,
      phoneNumber: undefined,
    });
    logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_FILTERS_CLEARED);
  };
  const roleOptionsByCountry = getDirectoryVendorRolesByUserCountry(
    userDetail?.accountCountry!,
  );

  useEffect(() => {
    if (
      queryParams.participantRole ||
      queryParams.id ||
      queryParams.name ||
      queryParams.email ||
      queryParams.phoneNumber
    ) {
      setAppliedFilters([
        {
          participantRole:
            ((queryParams.participantRole as string[])?.map((val: string) => ({
              label: getDirectoryVendorRoleLabel(
                val as DirectoryVendorCreateRequestRoleEnum,
              ),
              value: val,
            })) as ISelectOption[]) ?? [],
          id: (queryParams.id as string) ?? undefined,
          name: (queryParams.name as string) ?? undefined,
          email: (queryParams.email as string) ?? undefined,
          phoneNumber: (queryParams.phoneNumber as string) ?? undefined,
        },
      ]);

      reset({
        participantRole:
          ((queryParams.participantRole as string[])?.map((val: string) => ({
            label: getDirectoryVendorRoleLabel(
              val as DirectoryVendorCreateRequestRoleEnum,
            ),
            value: val,
          })) as ISelectOption[]) ?? [],
        id: (queryParams.id as string) ?? '',
        name: (queryParams.name as string) ?? '',
        email: (queryParams.email as string) ?? '',
        phoneNumber: (queryParams.phoneNumber as string) ?? '',
      });
    }
  }, [
    queryParams.name,
    queryParams.id,
    queryParams.participantRole,
    queryParams.email,
    queryParams.phoneNumber,
    setAppliedFilters,
    reset,
  ]);

  const appliedFiltersLength = countAppliedFilters(appliedFilters);

  const height = parentRef.current?.getBoundingClientRect().height ?? 48;
  const offset = 4;
  return (
    <div>
      <Popover
        isOpen={isPopoverOpen}
        containerClassName='z-10 w-80 !transform-none'
        containerStyle={{
          position: 'absolute',
          left: 'unset',
          right: '0',
          top: `${height + offset}px`,
        }}
        parentElement={parentRef.current ?? undefined}
        boundaryElement={parentRef.current ?? undefined}
        clickOutsideCapture
        onClickOutside={handleClickOutside}
        content={
          <div data-testid='directory-filter-popover'>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='w-full bg-white border border-zen-dark-5 divide-y divide-[#ECECEC] rounded-lg shadow-side-popup'
            >
              <div className='flex flex-row justify-between space-x-4 items-center p-4'>
                <ZenButton
                  label='Clear'
                  variant='secondary-outline'
                  onClick={handleClear}
                />
                <p className='text-base font-semibold'>Filters</p>
                <ZenButton type='submit' label='Apply' variant='primary' />
              </div>
              <div>
                <div className='p-4'>
                  <ZenControlledMultiSelectInput<
                    FilterValuesFormData,
                    'participantRole'
                  >
                    name='participantRole'
                    control={control}
                    label='Role'
                    labelClassName='font-inter font-normal text-base'
                    placeholder='Search'
                    options={roleOptionsByCountry}
                    onChangeSpy={handleRoleChange}
                    endAdornment={
                      <FontAwesomeIcon
                        icon={regular('angle-down')}
                        className='text-zen-dark-9 px-3'
                      />
                    }
                    isSearchable
                  />
                </div>
                <hr className='border-t border-zen-dark-5 mt-1' />
                <div className='p-4'>
                  <div>
                    <ZenControlledTextInput<FilterValuesFormData, 'id'>
                      control={control}
                      label='ID'
                      labelClassName='font-inter font-normal text-base'
                      name='id'
                      placeholder='Search'
                      shouldUnregister={false}
                      onChangeSpy={() =>
                        logAnalyticsEvent(
                          AnalyticsEventEnum.DIRECTORY_FILTERS_ID_ENTERED,
                        )
                      }
                    />
                  </div>
                  <div className='mt-4'>
                    <ZenControlledTextInput<FilterValuesFormData, 'name'>
                      control={control}
                      label='Name'
                      labelClassName='font-inter font-normal text-base'
                      name='name'
                      placeholder='Search'
                      shouldUnregister={false}
                      onChangeSpy={() =>
                        logAnalyticsEvent(
                          AnalyticsEventEnum.DIRECTORY_FILTERS_NAME_ENTERED,
                        )
                      }
                    />
                  </div>
                  <div className='mt-4'>
                    <ZenControlledTextInput<FilterValuesFormData, 'email'>
                      control={control}
                      name='email'
                      label='Email'
                      labelClassName='font-inter font-normal text-base'
                      placeholder='Search'
                      shouldUnregister={false}
                      onChangeSpy={() =>
                        logAnalyticsEvent(
                          AnalyticsEventEnum.DIRECTORY_FILTERS_EMAIL_ENTERED,
                        )
                      }
                    />
                  </div>
                  <div className='mt-4'>
                    <ZenControlledTextInput<FilterValuesFormData, 'phoneNumber'>
                      control={control}
                      label='Phone'
                      labelClassName='font-inter font-normal text-base'
                      name='phoneNumber'
                      placeholder='Search'
                      shouldUnregister={false}
                      onChangeSpy={() =>
                        logAnalyticsEvent(
                          AnalyticsEventEnum.DIRECTORY_FILTERS_PHONE_ENTERED,
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        }
      >
        <div>
          <ZenButton
            LeftIconComponent={
              <FilterIcon
                className={cn(
                  'mr-1 mb-0.5',
                  appliedFiltersLength
                    ? 'text-primary-blue'
                    : 'text-zen-dark-7',
                )}
                role='img'
                aria-label='filter'
              />
            }
            label={`${
              appliedFiltersLength
                ? `Applied (${appliedFiltersLength})`
                : 'Filters'
            }`}
            variant={`${
              appliedFiltersLength
                ? 'secondary-light-outline-thin'
                : 'secondary-outline-selected'
            }`}
            onClick={() => {
              setPopoverOpen(!isPopoverOpen);
              if (!isPopoverOpen) {
                logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_FILTERS_OPENED);
              }
            }}
          />
        </div>
      </Popover>
    </div>
  );
};

export default ZenDirectoryFilter;
