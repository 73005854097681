import React, { useState } from 'react';
import { Control, UseFormTrigger, UseFormWatch } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { ParticipantResponseParticipantRoleEnum } from '../../../../../openapi/arrakis';
import { AgentResponseAccountCountryEnum } from '../../../../../openapi/yenta';
import ErrorService from '../../../../../services/ErrorService';
import { showErrorToast } from '../../../../../slices/ToastNotificationSlice';
import { saveReferralInfos } from '../../../../../slices/TransactionBuilderSlice';
import {
  AppDispatch,
  AsyncSelectOptionReactElement,
  ParticipantType,
  RootState,
} from '../../../../../types';
import { STATE_OR_PROVINCE_ABBREVIATIONS } from '../../../../../utils/AnnouncementUtils';
import { searchActiveAgents } from '../../../../../utils/TableUtils';
import { getValidParticipantRoles } from '../../../../../utils/TransactionHelper';
import AvatarLabelComponent from '../../../../AgentReports/AvatarLabelComponent';
import ZenControlledAsyncSelectInput from '../../../Input/ZenControlledAsyncSelectInput';
import ZenControlledHTMLSelectInput from '../../../Input/ZenControlledHTMLSelectInput';
import ZenButton from '../../../ZenButton';
import ZenSidebarModal from '../../../ZenSidebarModal';
import { CreateTransactionFormState } from '../ZenCreateTransactionSteps';

interface ZenAddRealParticipantProps {
  isOpen: boolean;
  onClose(): void;
  control: Control<CreateTransactionFormState, object>;
  watch: UseFormWatch<CreateTransactionFormState>;
  trigger: UseFormTrigger<CreateTransactionFormState>;
}

const ZenAddRealParticipant: React.FC<ZenAddRealParticipantProps> = ({
  control,
  watch,
  isOpen,
  onClose,
  trigger,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    auth: { userDetail },
    transactionBuilder: { transactionBuilder },
  } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const role = watch('realParticipant.participantRole');
  const country = userDetail?.accountCountry;

  const onExternalParticipantSave = async () => {
    const isValid = await trigger();
    if (isValid) {
      const realParticipant = watch('realParticipant');
      const firstName = ((realParticipant.participant
        ?.label as unknown) as React.ReactElement)?.props?.firstName;
      const lastName = ((realParticipant.participant
        ?.label as unknown) as React.ReactElement)?.props?.lastName;

      setLoading(true);
      await dispatch(
        saveReferralInfos(
          transactionBuilder?.id!,
          realParticipant?.participantRole,
          firstName,
          lastName,
          realParticipant?.paidViaBusinessEntity?.name,
          realParticipant?.email,
          realParticipant?.phoneNumber,
          realParticipant?.address,
          realParticipant.participant?.value,
          realParticipant?.file,
          false,
          'AGENT',
          realParticipant?.ein,
        ),
      );
      setLoading(false);
      onClose();
    }
  };

  return (
    <ZenSidebarModal title='Add Participant' isOpen={isOpen} onClose={onClose}>
      <div className='p-4'>
        <div>
          <ZenControlledHTMLSelectInput<
            CreateTransactionFormState,
            'realParticipant.participantRole'
          >
            name='realParticipant.participantRole'
            control={control}
            label='Role'
            placeholder='Role'
            readOnly
            rules={{ required: 'Please select a role' }}
            options={[
              {
                label: `Select Role`,
                value: '',
                disabled: true,
              },
              ...getValidParticipantRoles(
                transactionBuilder?.dealType!,
                undefined,
                ParticipantType.AGENT,
              ),
            ]}
            defaultValue={
              role || ParticipantResponseParticipantRoleEnum.ReferringAgent
            }
            isRequired
          />
        </div>
        <div>
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<
              CreateTransactionFormState,
              'realParticipant.participant'
            >
              control={control}
              name='realParticipant.participant'
              placeholder='Search'
              label='Name of Participant'
              fetchData={async (search, page) => {
                try {
                  const searchResponse = await searchActiveAgents(
                    page,
                    new Array(
                      country === AgentResponseAccountCountryEnum.Canada
                        ? 'CANADA'
                        : 'UNITED_STATES',
                    ),
                    50,
                    search,
                    undefined,
                  );
                  const options: AsyncSelectOptionReactElement[] = searchResponse.data?.map(
                    (resp) => ({
                      value: `${resp.id}`,
                      label: (
                        <AvatarLabelComponent
                          avatar={resp?.avatar!}
                          firstName={resp?.firstName!}
                          lastName={resp?.lastName!}
                          emailAddress={resp?.emailAddress!}
                          administrativeAreas={resp?.administrativeAreas?.map(
                            (e) => {
                              if (!e.stateOrProvince) {
                                return 'N/A';
                              }
                              return STATE_OR_PROVINCE_ABBREVIATIONS[
                                e.stateOrProvince
                              ];
                            },
                          )}
                        />
                      ),
                    }),
                  );

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search for registered agents in add participants',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                        country: country!,
                      },
                    },
                  );
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an agent. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              rules={{
                required: 'Participant is required',
              }}
              isRequired
            />
          </div>
        </div>
      </div>
      <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2 w-full'>
        <ZenButton
          label='Cancel'
          variant='secondary-outline'
          onClick={onClose}
          isFullWidth
        />
        <ZenButton
          label='Save'
          onClick={onExternalParticipantSave}
          isSubmitting={loading}
          isFullWidth
        />
      </div>
    </ZenSidebarModal>
  );
};

export default ZenAddRealParticipant;
