import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { faArrowDownToSquare, faEye } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InstantPaymentResponse } from '../../openapi/arrakis';
import { displayAmount } from '../../utils/CurrencyUtils';
import FixedDataTable from '../FixedDataTable';
import IconButton from '../IconButton';
import InstantPaymentStatusCell from '../table/Cells/InstantPaymentStatusCell';
import NumberColumnFilter from '../table/Filters/NumberColumnFilter';
import TextColumnFilter from '../table/Filters/TextColumnFilter';
import MilliDateCell from '../table/Cells/MilliDateCell';
import InstantPaymentStatusSidebarModal from './InstantPaymentStatusSidebarModal';

export const columns: Column<InstantPaymentResponse>[] = [
  {
    Header: 'Transaction Code',
    accessor: 'transaction',
    id: 'transactionCode',
    Cell: ({ value }) => (
      <Link to={`/transactions/${value?.id}`}>
        <IconButton
          leftIcon={
            <FontAwesomeIcon
              icon={faArrowDownToSquare}
              className='fa-rotate-270'
              aria-label='Link'
            />
          }
          variant='outline'
          label={value?.code!}
        />
      </Link>
    ),
    cardColSize: 7,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Address',
    accessor: 'transaction',
    id: 'address',
    Cell: ({ value }) => <div className='w-56'>{value?.addressOneLine}</div>,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => <InstantPaymentStatusCell status={value!} />,
    Filter: TextColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Principal',
    accessor: 'principal',
    Cell: ({ value }) => displayAmount(value),
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Amount Repaid',
    accessor: 'amountRepaid',
    Cell: ({ value }) => displayAmount(value),
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Amount Repay',
    accessor: 'amountToRepay',
    Cell: ({ value }) => displayAmount(value),
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }) => <MilliDateCell date={value} />,
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Paid At',
    accessor: 'paymentConfirmationTime',
    Cell: ({ value }) => <MilliDateCell date={value} />,
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
];

interface InstantPaymentResourceTableProps {
  instantPayments: InstantPaymentResponse[];
}

const InstantPaymentResourceTable: React.FC<InstantPaymentResourceTableProps> = ({
  instantPayments,
}) => {
  const [
    activeInstantPayment,
    setActiveInstantPayment,
  ] = useState<InstantPaymentResponse | null>(null);

  const columnsWithAction = [
    {
      Header: 'Actions',
      id: 'actions',
      Cell: ({ row }: { row: Row<InstantPaymentResponse> }) => (
        <IconButton
          leftIcon={
            <FontAwesomeIcon
              icon={faEye}
              aria-label='View'
              className='text-lg'
            />
          }
          onClick={() => setActiveInstantPayment(row.original)}
        />
      ),
      disableSortBy: true,
    },
    ...columns,
  ];

  return (
    <div>
      <FixedDataTable<InstantPaymentResponse>
        columns={columnsWithAction}
        data={instantPayments}
        resourceName='Instant Payment'
        hidePagination
        hideFilters
      />
      {!!activeInstantPayment && (
        <InstantPaymentStatusSidebarModal
          instantPayment={activeInstantPayment}
          onClose={() => setActiveInstantPayment(null)}
        />
      )}
    </div>
  );
};

export default InstantPaymentResourceTable;
