import React from 'react';
import styled from 'styled-components';
import { TaxDocumentResponse } from '../../openapi/arrakis';
import { isTaxDocumentAmended } from '../../utils/TaxDocumentHelper';
import T4AFormHeader from './T4AFormHeader';
import T4AFormMid from './T4AFormMid';
import T4AFormFooter from './T4AFormFooter';

interface T4AFormProps {
  taxDocument: TaxDocumentResponse;
}

const T4AForm: React.FC<T4AFormProps> = ({ taxDocument }) => {
  const Print = styled.div`
    width: 1024px;
    margin: 0 auto;

    @media print {
      @page {
        size: A4;
        margin: 20pt 0 35pt;
      }
    }
  `;

  return (
    <Print>
      <div className='flex w-full print:text-xs p-4 print:p-2'>
        <p
          className='transform rotate-180 pt-4'
          style={{ writingMode: 'vertical-lr' }}
        >
          T4A (21) <span className='font-primary-medium'>Protected B</span> when
          completed/ <span className='font-primary-medium'>Protégé</span>B une
          fois rempli
        </p>
        <div className='flex-grow'>
          {isTaxDocumentAmended(taxDocument) && (
            <p className='underline font-bold text-center mb-2'>AMENDED</p>
          )}

          <T4AFormHeader
            taxYear={taxDocument?.year!}
            realBusinessName={taxDocument?.realBusinessName}
            realBusinessAddress={taxDocument?.realBusinessAddress}
          />
          <T4AFormMid
            equityContributionsPaid={taxDocument?.equityContributed!}
            netCommissionPaid={taxDocument?.netCommissionPaid!}
            releasedEquityMatch={taxDocument?.releasedEquityMatch!}
            releaseAwardsEquity={taxDocument?.releasedAwardsEquity!}
            einNumber={taxDocument?.realTaxId}
            sinNumber={taxDocument?.personalTaxId}
            businessNumber={taxDocument?.businessTaxId}
          />
          <T4AFormFooter
            isBusinessEntity={!!taxDocument?.businessTaxId}
            revsharePaid={taxDocument?.revsharePaid!}
            name={taxDocument?.name}
            firstName={taxDocument?.firstName}
            lastName={taxDocument?.lastName}
            mailingAddress={taxDocument?.mailingAddress}
          />
        </div>
      </div>
    </Print>
  );
};

export default T4AForm;
