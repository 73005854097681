import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { WEALTH_PLAN_PIE_CHART_RADIUS } from '../../../../constants/WealthPlanConstants';
import useEarningOnEditClick from '../../../../hooks/useEarningOnEditClick';
import { EarningsChartData } from '../../../../hooks/useEarningsChartData';
import {
  setSelectedEarningsOverviewType,
  setWealthKnowledgeLandingPage,
} from '../../../../slices/WealthManagementSlice';
import { displayFormattedFixedAmountWithCurrency } from '../../../../utils/CurrencyUtils';
import { UnlockEarningModal } from '../../UnlockEarningModal';
import {
  EarningIcon,
  EarningNumber,
  EarningType,
  PrimaryColor,
} from '../../WealthManagementTypes';

export type SliceHoverPosition =
  | 'top-right'
  | 'top-left'
  | 'bottom-left'
  | 'bottom-right';

export interface SliceProps {
  x: number;
  y: number;
  type: EarningType;
  position: SliceHoverPosition;
}

interface SliceHoverElementProps extends SliceProps {
  earningsData: EarningsChartData[];
  sliceMouseEventActive: boolean;
  setEarningsData: React.Dispatch<React.SetStateAction<EarningsChartData[]>>;
  setSliceProps: React.Dispatch<React.SetStateAction<SliceProps | null>>;
  setSliceHoverElementMouseEventActive: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const SliceHoverElement: React.FC<SliceHoverElementProps> = ({
  x,
  y,
  earningsData,
  type,
  position,
  sliceMouseEventActive,
  setEarningsData,
  setSliceProps,
  setSliceHoverElementMouseEventActive,
}) => {
  const dispatch = useDispatch();

  const { percentage, earnings, title, locked } = earningsData.find(
    (d) => d.type === type,
  ) as EarningsChartData;

  const {
    onClickEnumMap,
    selectedEarning,
    showParticipationModal,
    setSelectedEarning,
    setShowParticipationModal,
  } = useEarningOnEditClick();

  const handleMouseLeave = () => {
    if (sliceMouseEventActive) return;
    setSliceHoverElementMouseEventActive(false);
    setSliceProps(null);
    setEarningsData((prev) => {
      const newData = prev.map((d) => ({
        ...d,
        padding: 0,
        radius: WEALTH_PLAN_PIE_CHART_RADIUS,
      }));
      return newData;
    });
  };

  const slicePositionEnumMap: Record<SliceHoverPosition, string> = {
    'top-right': '!rounded-bl-none',
    'top-left': '!rounded-br-none',
    'bottom-left': '!rounded-tr-none',
    'bottom-right': '!rounded-tl-none',
  };

  return (
    <div
      className={classNames(
        'absolute z-50 bg-white border border-zen-dark-4 rounded-xl font-zen-body-2 px-3 py-2.5 md:w-[250px] w-[200px]',
        slicePositionEnumMap[position],
      )}
      style={{
        top: `${y}px`,
        left: `${x}px`,
      }}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={() => setSliceHoverElementMouseEventActive(true)}
    >
      <div className='flex items-start gap-2'>
        <div
          className='rounded-full w-[22px] h-[22px] flex items-center justify-center flex-shrink-0'
          style={{
            backgroundColor: locked ? '#C8C8C8' : PrimaryColor[type],
          }}
        >
          <FontAwesomeIcon icon={EarningIcon[type]} size='xs' color='white' />
        </div>
        <span className='text-zen-dark-7 text-sm'>
          {EarningNumber[type]}. {title}
        </span>
      </div>
      {locked ? (
        <div className='mt-2.5'>
          <div className='flex items-start space-x-1'>
            <div className='h-5 w-5 flex items-center justify-center'>
              <FontAwesomeIcon
                icon={faLock}
                className='text-xs text-zen-warning-dark'
              />
            </div>
            <p className='text-sm text-zen-dark-7'>
              You don&apos;t participate in this way to earn
            </p>
          </div>
          <div className='flex justify-end mt-3'>
            <button
              className='font-zen-title-2 text-sm text-primary-blue font-semibold'
              onClick={onClickEnumMap[type]}
            >
              Unlock
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className='flex flex-row justify-between items-center mt-2.5'>
            <span className='text-xs leading-6 text-zen-dark-6'>Earnings:</span>
            <span className='text-sm left-6 font-medium text-zen-dark-7'>
              {displayFormattedFixedAmountWithCurrency(
                { amount: earnings },
                false,
                0,
              )}
            </span>
          </div>
          <div className='flex flex-row justify-between items-center'>
            <span className='text-xs leading-6 text-zen-dark-6'>
              % of Total
            </span>
            <span className='text-sm left-6 font-medium text-zen-dark-7'>
              {parseFloat((percentage ?? 0).toFixed(2))}%
            </span>
          </div>
          <div className='flex justify-end mt-2.5'>
            <button
              className='font-zen-title-2 text-sm leading-6 text-primary-blue font-semibold'
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setWealthKnowledgeLandingPage(type));
              }}
            >
              Learn More
            </button>
          </div>
        </>
      )}
      <UnlockEarningModal
        isOpen={!!showParticipationModal}
        onClose={(openSidebar) => {
          if (!openSidebar) {
            dispatch(setSelectedEarningsOverviewType(undefined));
            setSelectedEarning(null);
          } else {
            dispatch(setSelectedEarningsOverviewType(selectedEarning!));
          }
          setShowParticipationModal(undefined);
        }}
        wealthGoalProperty={showParticipationModal!}
        earningType={selectedEarning}
      />
    </div>
  );
};

export default SliceHoverElement;
