import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/pro-duotone-svg-icons';
import { useParams } from 'react-router-dom';
import IconButton from '../components/IconButton';
import TrustDepositReceipt from '../components/transactions/TrustDeposits/TrustDepositReceipt';
import ZenRoute from '../components/Zen/ZenRoute';
import config from '../config';
import { getAuthCookie } from '../utils/AuthUtils';

type Match = {
  transactionId: string;
  id: string;
};

const TrustDepositReceiptRoute: React.FC = () => {
  const { transactionId, id } = useParams() as Match;

  return (
    <ZenRoute title='Trust Deposit Receipt'>
      <div className='flex justify-end space-x-3 p-5'>
        <IconButton
          label='Download'
          variant='outline'
          buttonStyle='hover:bg-gray-100'
          textStyle='text-dark'
          leftIcon={
            <FontAwesomeIcon
              icon={faCloudArrowDown}
              className='pb-1 text-gray-800'
            />
          }
          onClick={() => {
            window.open(
              `${config.apiBasePath.dali}/api/pdf?token=${
                config.daliAuthToken
              }&jwt=${getAuthCookie()}&url=${
                config.reactAppHostUrl
              }/pdf/transactions/${transactionId}/trust-deposit-receipt/${id}`,
              '_blank',
            );
          }}
        />
      </div>
      <TrustDepositReceipt
        transactionId={transactionId}
        escrowDepositId={id}
        isPdf={false}
      />
    </ZenRoute>
  );
};

export default TrustDepositReceiptRoute;
