import React from 'react';
import { NavLink } from 'react-router-dom';
import { useGemini } from '../hooks/useGemini';
import { cn } from '../utils/classUtils';
import { isMenuItemActive } from '../utils/UrlUtils';
import Hover from './Hover';

export interface ZenSideBarItemProps {
  icon?: React.ReactElement;
  label?: string;
  linkTo?: string;
  collapsed?: boolean;
  exact?: boolean;
  hideActive?: boolean;
  target?: string;
  BadgeComponent?: React.ReactElement;
  isSubTab?: boolean;
  onClick?(): void;
  isExternal?: boolean;
}

interface RenderLinkProps extends ZenSideBarItemProps {
  className?: string;
}

const RenderLink: React.FC<RenderLinkProps> = ({
  isExternal,
  linkTo,
  exact,
  target,
  onClick,
  ...props
}) => {
  if (isExternal) {
    return (
      <a
        href={linkTo}
        target='_blank'
        rel='noreferrer'
        className={props.className}
      >
        {props.children}
      </a>
    );
  }

  return (
    <NavLink
      to={linkTo}
      onClick={onClick}
      className={props.className}
      exact={exact}
      target={target}
    >
      {props.children}
    </NavLink>
  );
};

const ZenSideBarItem: React.FC<ZenSideBarItemProps> = ({
  icon,
  label,
  linkTo = '/',
  collapsed = false,
  exact = false,
  hideActive = false,
  target,
  BadgeComponent,
  isSubTab = false,
  children,
  onClick,
  isExternal = false,
}) => {
  const isGeminiEnabled = useGemini();

  const isActive = isMenuItemActive(linkTo);

  const Children = children ? (
    children
  ) : (
    <div className='flex flex-row items-center space-x-2'>
      <span
        className={cn(
          'items-center text-sm',
          isGeminiEnabled ? 'font-zen-body-2' : 'font-zen-body',
        )}
      >
        {label}
      </span>
      {BadgeComponent}
    </div>
  );

  return (
    <Hover
      config={{
        placement: 'right',
        trigger: collapsed ? 'hover' : null,
      }}
      hoverComponent={
        <p className='px-2 whitespace-pre font-zen-body-2 text-sm'>{label}</p>
      }
      variant='dark'
    >
      <RenderLink
        {...{ isExternal, linkTo, exact, target, onClick }}
        className={cn(
          'flex flex-row items-center text-white group z-0 rounded-lg my-2',
          collapsed ? 'justify-center py-2.5' : 'justify-start px-2 py-1',
          {
            'hover:text-zen-primary-6': isSubTab && !isGeminiEnabled,
            'hover:text-aqua': isSubTab && isGeminiEnabled,
          },
          {
            'hover:bg-zen-dark-22': !isSubTab && !isGeminiEnabled,
            'hover:bg-regent-900': !isSubTab && isGeminiEnabled,
          },
          {
            'bg-zen-dark-22':
              !hideActive && isMenuItemActive(linkTo) && !isGeminiEnabled,
            'bg-regent-900':
              !hideActive &&
              !isSubTab &&
              isMenuItemActive(linkTo) &&
              isGeminiEnabled,
            '!bg-zen-dark-21 text-zen-primary-6':
              !hideActive &&
              isMenuItemActive(linkTo) &&
              isSubTab &&
              !isGeminiEnabled,
            'text-aqua':
              !hideActive &&
              isMenuItemActive(linkTo) &&
              isSubTab &&
              isGeminiEnabled,
          },
        )}
      >
        {icon && (
          <span
            className={cn(
              'h-4 w-4 flex items-center justify-center',
              collapsed ? 'text-xl' : 'text-sm',
              isGeminiEnabled
                ? 'group-hover:text-aqua'
                : 'group-hover:text-zen-primary-6',
              {
                'text-zen-primary-6': isActive && !isGeminiEnabled,
                'text-aqua': isActive && isGeminiEnabled,
              },
            )}
          >
            {icon}
          </span>
        )}
        {!collapsed && (
          <span
            className={cn(
              'items-center flex flex-row gap-1 text-sm p-1',
              isSubTab ? 'pl-7' : 'pl-2',
            )}
          >
            {Children}
          </span>
        )}
      </RenderLink>
    </Hover>
  );
};

export default ZenSideBarItem;
