import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLoader from '../components/DefaultLoader';
import ZenTransactionOutgoingPaymentsTable from '../components/transactions/OutgoingPayments/zen/ZenTransactionOutgoingPaymentsTable';
import ZenRoute from '../components/Zen/ZenRoute';
import { fetchTransactionOutgoingPayments } from '../slices/TransactionSlice';
import { RootState } from '../types';
import { getPageTitleForTransaction } from '../utils/TransactionUtils';

const ZenTransactionOutgoingPaymentRoute: React.FC = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const {
    transactionDetailResponse: { data: transactionDetail },
    transactionOutgoingPayments,
  } = useSelector((state: RootState) => state.transaction);

  const fetchData = useCallback(async () => {
    setLoading(true);
    await dispatch(fetchTransactionOutgoingPayments(transactionDetail?.id!));
  }, [dispatch, transactionDetail?.id]);

  useEffect(() => {
    fetchData().finally(() => {
      setLoading(false);
    });
  }, [fetchData]);

  if (loading) {
    return <DefaultLoader />;
  }

  return (
    <ZenRoute
      title={`${getPageTitleForTransaction(
        transactionDetail,
      )} Outgoing Payments - ${transactionDetail?.address?.oneLine}`}
    >
      <div className='w-full p-4'>
        <ZenTransactionOutgoingPaymentsTable
          transactionId={transactionDetail?.id!}
          transactionOutgoingPayments={transactionOutgoingPayments}
        />
      </div>
    </ZenRoute>
  );
};

export default ZenTransactionOutgoingPaymentRoute;
