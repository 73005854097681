import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import EmptyComponent from '../../assets/img/NoRolesEmptyComponent.svg';
import ZenLink from '../Zen/ZenLink';

interface GrantorRolesTableEmptyComponentProps {
  emptyMessage: string;
  actionText: string;
  onActionClick?(): void;
}

const GrantorRolesTableEmptyComponent: React.FC<GrantorRolesTableEmptyComponentProps> = ({
  emptyMessage,
  actionText,
  onActionClick,
}) => {
  return (
    <div className='flex flex-col flex-1 justify-center items-center pt-12'>
      <div>
        <img src={EmptyComponent} className='h-44 w-auto' alt='empty roles' />
      </div>
      <p className='text-lg font-zen-body font-normal text-zen-dark-7 mt-12 text-center'>
        {emptyMessage}
      </p>
      <div className='mt-4'>
        <ZenLink
          label={actionText}
          onClick={onActionClick}
          leftIconComponent={
            <FontAwesomeIcon
              icon={faPlus}
              className='text-lg text-primary-blue'
            />
          }
          variant='primary-with-no-hover-effect'
        />
      </div>
    </div>
  );
};

export default GrantorRolesTableEmptyComponent;
