import { useForm } from 'react-hook-form-v7';
import { Column } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { BsCloudArrowDown } from 'react-icons/bs';
import { FaFile } from 'react-icons/fa';
import { GiAnticlockwiseRotation } from 'react-icons/gi';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { compact } from 'lodash';
import {
  ItemResponse,
  ItemDtoStatusEnum,
  ItemDtoRequiredForEnum,
} from '../../../openapi/sherlock';
import { AppDispatch, Mapping, RootState } from '../../../types';
import {
  fetchChecklistItemById,
  updateChecklistItem,
} from '../../../slices/CheckListSlice';
import ZenFixedDataTable from '../ZenFixedDataTable';
import ZenSidebarModal from '../ZenSidebarModal';
import ZenButton from '../ZenButton';
import ZenControlledTextAreaInput from '../Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenDocumentDateCell from '../../dropbox/cell/ZenDocumentDateCell';
import DefaultLoader from '../../DefaultLoader';
import ResourceContainer from '../../ResourceContainer';
import { ReactComponent as NewFile } from '../../../assets/img/file-new.svg';
import { FileVersionDto, FileResponse } from '../../../openapi/dropbox';
import {
  downloadDropboxFileByVersion,
  downloadDropboxFileAndUploadAsNewVersion,
  renameFile,
  uploadFileNewVersion,
  downloadAndUploadFilesToDropbox,
} from '../../../slices/DropboxSlice';
import ZenControlledDraggableDocumentUploadInput from '../Input/ZenControlledDraggableDocumentUploadInput';
import ZenFromFileCabinetButton from '../../dropbox/ZenFromFileCabinetButton';
import {
  CHECKLIST_ALLOWED_FILE_TYPES,
  MAX_DOC_SIZE_100MB,
} from '../../../constants/FilesConstants';

interface ZenCheckListDropboxDocumentVersionsProps {
  isOpen: boolean;
  onClose(): void;
  index: number;
  checklistDocument: FileResponse;
  checklistItem: ItemResponse;
  checklistId: string;
  dropboxId: string;
  secondaryDropboxId?: string;
}

interface FormData {
  name: string;
  description: string;
  docFile: File[];
}

const ZenCheckListDropboxDocumentVersions: React.FC<ZenCheckListDropboxDocumentVersionsProps> = ({
  isOpen,
  onClose,
  index,
  checklistDocument,
  checklistItem,
  checklistId,
  dropboxId,
  secondaryDropboxId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);
  const [isDownloadingDocument, setIsDownloadingDocument] = useState<
    Mapping<boolean>
  >({});

  const columns: Array<Column<FileVersionDto>> = [
    {
      Header: 'Name / Description',
      accessor: 'filename',
      Cell: ({ value, row: { original } }) => (
        <div className='flex'>
          <div className='mt-1'>
            <div className='border border-zen-dark-5 rounded p-1'>
              <FaFile className='text-zen-dark-5' />
            </div>
          </div>
          <div className='inline-block ml-2'>
            <div className='text-sm font-zen-body font-normal text-zen-dark-9 flex items-start gap-2'>
              {value}
            </div>
            <div className='text-xs text-zen-dark-6 leading-tight pt-1 contents'>
              {original.description}
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: 'Version',
      accessor: 'version',
      Cell: ({ value }) => (
        <div className='flex items-center md:justify-center gap-1'>
          <GiAnticlockwiseRotation />
          {value}
        </div>
      ),
    },
    {
      Header: 'Uploaded Date',
      accessor: 'createdAt',
      Cell: ({ value }) => <ZenDocumentDateCell date={value!} />,
    },
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ value }) => (
        <ResourceContainer
          loading={isDownloadingDocument[value!]!}
          isEmpty={false}
          resourceName='download-item'
          LoaderComponent={
            <div className='pr-3'>
              <DefaultLoader noPadding iconSize='small' />
            </div>
          }
        >
          <button
            onClick={() => handleDownload(value!)}
            type='button'
            className='bg-zen-light-gray-2 hover:bg-zen-dark-4 rounded-full min-w-[35px] min-h-[35px] w-[35px] h-[35px] flex items-center justify-center cursor-pointer'
          >
            <BsCloudArrowDown className='text-lg text-zen-dark-8' />
          </button>
        </ResourceContainer>
      ),
    },
  ];

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<FormData>({
    defaultValues: {
      name: checklistDocument.filename,
      description: checklistDocument.description
        ? checklistDocument.description
        : '',
    },
  });

  const [name, description, docFile] = watch([
    'name',
    'description',
    'docFile',
  ]);

  const handleDownload = async (versionId: string) => {
    setIsDownloadingDocument((map) => ({ ...map, [versionId!]: true }));
    await dispatch(
      downloadDropboxFileByVersion(checklistDocument.id!, versionId!),
    );
    setIsDownloadingDocument((map) => ({ ...map, [versionId!]: false }));
  };

  const handleOnAttach = async (
    files: FileResponse[],
    uploadAndAttach?: boolean,
  ) => {
    if (!!uploadAndAttach) {
      const data = await dispatch(
        downloadAndUploadFilesToDropbox(files, dropboxId, userDetail?.id!),
      );
      files = compact(data);
    }
    const data = await dispatch(
      downloadDropboxFileAndUploadAsNewVersion(
        files[0],
        checklistDocument.id!,
        dropboxId,
        userDetail?.id!,
        name,
        description,
      ),
    );
    if (data) {
      await dispatch(
        updateChecklistItem(
          checklistId,
          checklistItem.id!,
          {
            item: {
              ...checklistItem,
              name: checklistItem.name!,
              status: ItemDtoStatusEnum.Pending,
              requiredFor: (checklistItem.requiredFor as unknown) as ItemDtoRequiredForEnum,
            },
          },
          false,
        ),
      );
      await dispatch(fetchChecklistItemById(checklistId, checklistItem.id!));
      onClose();
    }

    return data;
  };

  const onSubmit = async (data: FormData) => {
    let result: boolean = false;
    if (data.docFile) {
      result = await dispatch(
        uploadFileNewVersion(
          dropboxId,
          checklistDocument.id!,
          data.docFile[0],
          data.name,
          data.description,
          userDetail?.id!,
        ),
      );
    } else {
      result = await dispatch(
        renameFile(
          dropboxId,
          checklistDocument.id!,
          data.name,
          data.description,
        ),
      );
    }

    if (result) {
      await dispatch(
        updateChecklistItem(
          checklistId,
          checklistItem.id!,
          {
            item: {
              ...checklistItem,
              name: checklistItem.name!,
              status: ItemDtoStatusEnum.Pending,
              requiredFor: (checklistItem.requiredFor as unknown) as ItemDtoRequiredForEnum,
            },
          },
          false,
        ),
      );
      await dispatch(fetchChecklistItemById(checklistId, checklistItem.id!));
      onClose();
    }
  };

  return (
    <ZenSidebarModal
      title={`Document: ${index + 1}. ${checklistDocument.filename}`}
      subtitle={`Version ${
        checklistDocument.currentVersion?.version
      } | Uploaded ${
        checklistDocument.currentVersion?.createdAt
          ? DateTime.fromMillis(
              checklistDocument.currentVersion?.createdAt,
            ).toLocaleString(DateTime.DATETIME_SHORT)
          : 'N/A'
      }`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='p-4 space-y-5'>
        <div className='flex flex-col h-full space-y-5 pb-20'>
          <div className='flex-grow space-y-5'>
            <div>
              <ZenControlledTextInput<FormData, 'name'>
                control={control}
                label='Document Name'
                name='name'
                placeholder='Ex. Tax form 1099'
                rules={{ required: 'Please enter file name.' }}
                defaultValue={checklistDocument.filename}
              />
            </div>
            <div>
              <div className='pt-1'>
                <ZenControlledTextAreaInput<FormData, 'description'>
                  control={control}
                  placeholder='Description'
                  name='description'
                  rows={3}
                  label='Description'
                  defaultValue={
                    checklistDocument.description
                      ? checklistDocument.description
                      : ''
                  }
                />
              </div>
            </div>
            <div>
              <div className='flex flex-row items-center space-x-4'>
                <div className='mt-0.5 flex-grow'>
                  <ZenControlledDraggableDocumentUploadInput<
                    FormData,
                    'docFile'
                  >
                    name='docFile'
                    label='Replace with a new version'
                    control={control}
                    height='small'
                    customPlaceHolder={
                      <div className='flex md:flex-row flex-col justify-center items-center px-4'>
                        <NewFile className='w-8 h-8' />
                        {!!docFile?.[0]?.name ? (
                          <span className='font-zen-body font-semibold text-sm text-primary-blue ml-2 text-center'>
                            {docFile[0].name}
                          </span>
                        ) : (
                          <>
                            <span className='font-zen-body font-semibold text-sm text-primary-blue ml-2'>
                              Browse Files
                            </span>
                            <p className='font-zen-body text-sm font-semibold text-zen-dark-7 ml-1'>
                              or drag & drop new file
                            </p>
                          </>
                        )}
                      </div>
                    }
                    multiple={false}
                    accept={CHECKLIST_ALLOWED_FILE_TYPES.join(',')}
                    maxUploadSize={MAX_DOC_SIZE_100MB}
                  />
                </div>
                {!!dropboxId && (
                  <div className='pt-8'>
                    <ZenFromFileCabinetButton
                      checklistId={checklistId}
                      dropboxId={dropboxId}
                      secondaryDropboxId={secondaryDropboxId}
                      onAttach={handleOnAttach}
                      isMultiple={false}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='mt-5 space-y-1'>
            <div className='text-base font-zen-body font-semibold text-zen-dark-9'>
              Previous Versions
            </div>
            <ZenFixedDataTable<FileVersionDto>
              key={checklistDocument.fileVersions?.length}
              columns={columns}
              resourceName='Previous Versions'
              data={checklistDocument.fileVersions}
              hideFilters
              hidePagination
            />
          </div>
          <div className='py-3 px-3 md:py-4 md:pr-3 md:pl-[40%] absolute w-full bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 z-40'>
            <ZenButton
              type='button'
              onClick={onClose}
              label='Cancel'
              variant='primary-outline'
              isFullWidth
            />
            <ZenButton
              isSubmitting={isSubmitting}
              type='button'
              label='Save'
              isFullWidth
              onClick={handleSubmit(onSubmit)}
              isDisabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default ZenCheckListDropboxDocumentVersions;
