import { datadogRum } from '@datadog/browser-rum';
import { uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import AdminFeatureFlagService from '../../../services/AdminFeatureFlagService';
import { FeatureFlagTypeEnum, RootState } from '../../../types';
import Logger from '../../../utils/Logger';

type DatadogFeatureFlagItem = {
  name: FeatureFlagTypeEnum;
  type: 'client-side' | 'server-side';
};

const useDatadogEvaluateFeatureFlags = () => {
  const { experiments } = useSelector((state: RootState) => state.experiment);

  const allExperiments: Array<DatadogFeatureFlagItem> = useMemo(() => {
    // experiments from API
    const allExp = experiments.map(
      (experiment) =>
        ({
          name: experiment.name as FeatureFlagTypeEnum,
          type: 'server-side',
        } as DatadogFeatureFlagItem),
    );

    // local experiments
    const localExperiments = Object.values(FeatureFlagTypeEnum)
      .filter((flag) => AdminFeatureFlagService.isAvailable(flag))
      .map(
        (flag) =>
          ({
            name: flag as FeatureFlagTypeEnum,
            type: 'client-side',
          } as DatadogFeatureFlagItem),
      );

    const uniqExperiments = uniqBy(localExperiments, 'name');

    // all experiments
    allExp.push(...uniqExperiments);

    return allExp;
  }, [experiments]);

  const dispatchFeatureFlagEnabled = useCallback(() => {
    Logger.debug('[Datadog]: all flags enabled', allExperiments);

    for (let { name, type } of allExperiments) {
      datadogRum.addFeatureFlagEvaluation(name, type);
    }
  }, [allExperiments]);

  useEffect(() => {
    dispatchFeatureFlagEnabled();
  }, [dispatchFeatureFlagEnabled]);
};

export default useDatadogEvaluateFeatureFlags;
