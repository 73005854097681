import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportWidgetState } from '../types';

export const initialState: SupportWidgetState = {
  supportModal: false,
};

const SupportSlice = createSlice({
  name: 'supportSlice',
  initialState,
  reducers: {
    toggleSupportModal(state, action: PayloadAction<boolean>) {
      state.supportModal = action.payload;
    },
  },
});

export const { toggleSupportModal } = SupportSlice.actions;

export default SupportSlice.reducer;
