import {
  WillBeneficiaryAndTypeResponse,
  WillBeneficiaryAndTypeResponseTypeEnum,
} from '../../../../openapi/yenta';
import { displayDate } from '../../../../utils/DateUtils';
import {
  capitalizeEnum,
  formatPhoneNumber,
} from '../../../../utils/StringUtils';
import ExistingBeneficiaryRowV2 from './ExistingBeneficiaryRowV2';

interface ExistingBeneficiaryTableV2Props {
  beneficiary?: WillBeneficiaryAndTypeResponse;
}

const ExistingBeneficiaryTableV2: React.FC<ExistingBeneficiaryTableV2Props> = ({
  beneficiary,
}) => {
  const isBeneficiaryTypeNone =
    !beneficiary ||
    beneficiary?.type === WillBeneficiaryAndTypeResponseTypeEnum.None;
  const individualBeneficiary =
    beneficiary?.type === WillBeneficiaryAndTypeResponseTypeEnum.Individual
      ? beneficiary?.individualBeneficiary
      : undefined;

  if (isBeneficiaryTypeNone) {
    return null;
  }

  return (
    <div className='w-full border border-zen-dark-4 rounded-lg p-3 mt-8'>
      <p className='text-zen-dark-13 pb-4 mb-4 text-base font-zen-body font-semibold border-b border-zen-dark-4 text-center'>
        Existing Beneficiary
      </p>
      {individualBeneficiary ? (
        <div className='grid grid-flow-row md:grid-cols-2 grid-cols-1 w-full gap-y-2 md:gap-x-4 mb-2'>
          <ExistingBeneficiaryRowV2
            label='Name'
            value={`${individualBeneficiary.firstName} ${individualBeneficiary.middleName} ${individualBeneficiary.lastName}`}
          />
          <ExistingBeneficiaryRowV2
            label='Address'
            value={individualBeneficiary.address?.streetAddress1!}
          />
          <ExistingBeneficiaryRowV2
            label='City'
            value={individualBeneficiary.address?.city}
          />
          <ExistingBeneficiaryRowV2
            label='State/Province'
            value={capitalizeEnum(
              individualBeneficiary.address?.stateOrProvince!,
            )}
          />
          <ExistingBeneficiaryRowV2
            label='Country'
            value={capitalizeEnum(individualBeneficiary.address?.country!)}
          />
          <ExistingBeneficiaryRowV2
            label='Postal Code'
            value={individualBeneficiary.address?.zipOrPostalCode}
          />
          <ExistingBeneficiaryRowV2
            label='Date of Birth'
            value={displayDate(individualBeneficiary.birthDate!)}
          />
          <ExistingBeneficiaryRowV2
            label='Telephone'
            value={formatPhoneNumber(individualBeneficiary.phoneNumber)}
          />
        </div>
      ) : (
        <div className='grid grid-flow-row grid-cols-1 w-full mb-2'>
          <ExistingBeneficiaryRowV2
            label='Trust Information'
            value={`${beneficiary?.trustBeneficiary?.text}`}
          />
        </div>
      )}
    </div>
  );
};

export default ExistingBeneficiaryTableV2;
