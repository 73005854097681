import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFetchPersonalAccount } from '../../../query/agent/useAgent';
import ErrorService from '../../../services/ErrorService';
import { RootState } from '../../../types';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import AuthorizedOnly from '../../auth/AuthorizedOnly';
import ResourceContainer from '../../ResourceContainer';
import ZenAdd from '../../Zen/ZenAdd';
import ZenCard from '../../Zen/ZenCard';
import AddPersonalAccountForm from './AddPersonalAccountForm';
import AddPersonalAccountRow from './AddPersonalAccountRow';

interface AgentPersonalAccountCardProps {}

const AgentPersonalAccountCard: React.FC<AgentPersonalAccountCardProps> = () => {
  const {
    agentDetail: {
      detailResponse: { data: agentDetailResponse },
    },
  } = useSelector((state: RootState) => state);

  const [addAccount, setAddAccount] = useState(false);

  const {
    data: personalAccounts,
    isLoading,
    error,
    isFetching,
  } = useFetchPersonalAccount(agentDetailResponse?.id!, true);

  const asyncPersonalAccount = {
    name: 'personal account',
    data: personalAccounts,
    error: ErrorService.getErrorCode(error),
    loading: isLoading || isFetching,
  };

  return (
    <ZenCard
      title='Personal (Payment) Account'
      RightComponent={
        <AuthorizedOnly asyncResponse={asyncPersonalAccount}>
          <ZenAdd onClick={() => setAddAccount(true)} />
        </AuthorizedOnly>
      }
    >
      <AuthorizationContainer asyncResponse={asyncPersonalAccount}>
        <ResourceContainer
          loading={isLoading || isFetching}
          isEmpty={!personalAccounts}
          resourceName='personal account'
          emptyMessage='No personal account found.'
          emptyIcon={
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className='text-gray-400'
            />
          }
        >
          <div className='p-4'>
            <Link to={`/people/${personalAccounts?.id}`}>
              <AddPersonalAccountRow
                name={personalAccounts?.fullName!}
                imageUrl={personalAccounts?.avatar!}
                email={personalAccounts?.emailAddress!}
                phone={personalAccounts?.phoneNumber!}
              />
            </Link>
          </div>
        </ResourceContainer>
        {addAccount && (
          <AddPersonalAccountForm isOpen onClose={() => setAddAccount(false)} />
        )}
      </AuthorizationContainer>
    </ZenCard>
  );
};

export default AgentPersonalAccountCard;
