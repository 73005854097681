import { capitalize, values } from 'lodash';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import ZenControlledAsyncSelectInput from '../components/Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledHTMLSelectInput from '../components/Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledStateOrProvinceInput from '../components/Zen/Input/ZenControlledStateOrProvince';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../components/Zen/ZenSidebarModalForm';
import { DEFAULT_PAGE_SIZE } from '../constants/TableConstants';
import {
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
  AdministrativeAreaControllerApi,
  CompanyControllerApi,
  CompanyResponse,
  CreateCompanyRequest,
  EditCompanyRequest,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToast,
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { AgentAddressTypeEnum, AsyncSelectOption } from '../types';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../utils/StringUtils';

interface FormData {
  name: string;
  ein: string;
  city: string;
  country: AddressRequestCountryEnum;
  stateOrProvince: AddressRequestStateOrProvinceEnum;
  streetAddress1: string;
  streetAddress2?: string;
  addressType: string;
  zipOrPostalCode: string;
  administrativeAreaId: {
    label: string;
    value: string;
  };
}

interface ZenCompanyFormSidebarModalProps {
  isOpen: boolean;
  company?: CompanyResponse;
  readOnly?: boolean;
  onClose(): void;
}

const ZenCompanyFormSidebarModal: React.FC<ZenCompanyFormSidebarModalProps> = ({
  isOpen,
  company,
  onClose,
  readOnly,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      name: company?.name || '',
      ein: company?.ein || '',
      city: company?.address?.city || '',
      country: (company?.administrativeArea?.country
        ? company?.administrativeArea?.country
        : '') as AddressRequestCountryEnum,
      stateOrProvince: (company?.administrativeArea?.stateOrProvince
        ? company?.administrativeArea?.stateOrProvince
        : '') as AddressRequestStateOrProvinceEnum,
      streetAddress1: company?.address?.streetAddress1 || '',
      streetAddress2: company?.address?.streetAddress2 || '',
      addressType: company?.address?.type || '',
      zipOrPostalCode: company?.address?.zipOrPostalCode || '',
      administrativeAreaId: company
        ? {
            label: company?.administrativeArea!.id || '',
            value: company?.administrativeArea!.id || '',
          }
        : undefined,
    },
  });
  const selectedCountry = watch('country');

  const onSubmit = async (data: FormData) => {
    const mainData: CreateCompanyRequest | EditCompanyRequest = {
      name: data.name,
      ein: data.ein,
      address: {
        city: data.city,
        country: data.country,
        stateOrProvince: data.stateOrProvince,
        streetAddress1: data.streetAddress1,
        streetAddress2: data.streetAddress2,
        zipOrPostalCode: data.zipOrPostalCode,
        type: data.addressType,
      },
      administrativeAreaId: data.administrativeAreaId.value,
    };

    try {
      if (!!company) {
        await new CompanyControllerApi(getYentaConfiguration()).editCompany(
          company.id!,
          mainData,
        );
      } else {
        await new CompanyControllerApi(getYentaConfiguration()).createCompany(
          mainData as CreateCompanyRequest,
        );
      }
      dispatch(
        showSuccessToast(
          `Successfully ${!!company ? 'updated' : 'created'} the company.`,
        ),
      );
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify(
        `Error ${!!company ? 'updating' : 'creating'} the company`,
        e,
      );
      dispatch(
        showErrorToastForErrorCode(
          `We encountered an error while ${
            !!company ? 'updating' : 'creating'
          } the company, Please try again in a few moments.`,
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      onClose();
    }
  };

  const title = readOnly
    ? `Viewing ${company?.name}`
    : !!company
    ? 'Edit Company'
    : 'Create Company';

  return (
    <ZenSidebarModalForm
      title={title}
      isOpen={isOpen}
      actionTitle='Save'
      isSubmitting={isSubmitting}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      hideFooterActions={readOnly}
    >
      <div className='space-y-5'>
        <ZenControlledTextInput<FormData, 'name'>
          control={control}
          label='Company Name'
          name='name'
          shouldUnregister={false}
          placeholder='Enter Company Name'
          rules={{ required: 'Please provide a company name' }}
          readOnly={readOnly}
        />
        <ZenControlledTextInput<FormData, 'ein'>
          control={control}
          label='Company EIN'
          name='ein'
          placeholder='Enter Company EIN'
          shouldUnregister={false}
          rules={{
            required: 'Please provide a company ein',
          }}
          readOnly={readOnly}
        />
        <ZenControlledHTMLSelectInput<FormData, 'addressType'>
          control={control}
          label='Address Type'
          name='addressType'
          shouldUnregister={false}
          options={[
            {
              label: 'Choose address type',
              value: '',
              disabled: true,
            },
            ...values(AgentAddressTypeEnum).map((type) => ({
              value: type,
              label: capitalize(type),
            })),
          ]}
          rules={{
            required: 'Please select address type',
          }}
          readOnly={readOnly}
        />
        <ZenControlledHTMLSelectInput<FormData, 'country'>
          name='country'
          control={control}
          label='Country'
          placeholder='Country'
          shouldUnregister={false}
          options={[
            {
              label: 'Select Country',
              value: '',
              disabled: true,
            },
            ...values(AddressRequestCountryEnum).map((state) => ({
              value: state,
              label: capitalizeEnum(state),
            })),
          ]}
          rules={{
            required: 'Please select a country',
          }}
          readOnly={readOnly}
        />
        <ZenControlledTextInput<FormData, 'streetAddress1'>
          control={control}
          name='streetAddress1'
          placeholder='Enter the address'
          rules={{ required: 'Please enter the street address' }}
          label='Street Address Line 1'
          readOnly={readOnly}
          shouldUnregister={false}
        />
        <ZenControlledTextInput<FormData, 'streetAddress2'>
          control={control}
          name='streetAddress2'
          placeholder='#100'
          label='Street Address Line 2'
          readOnly={readOnly}
          shouldUnregister={false}
        />
        <ZenControlledTextInput<FormData, 'city'>
          control={control}
          label='City'
          name='city'
          placeholder='City'
          rules={{
            required: 'Please enter the city',
          }}
          readOnly={readOnly}
          shouldUnregister={false}
        />
        <ZenControlledStateOrProvinceInput<FormData, 'stateOrProvince'>
          name='stateOrProvince'
          control={control}
          setValue={setValue}
          shouldUnregister={false}
          selectedCountry={selectedCountry}
          rules={{
            required: 'Please select a state / province.',
          }}
          readOnly={readOnly}
        />
        <ZenControlledTextInput<FormData, 'zipOrPostalCode'>
          control={control}
          label='Postal Code'
          name='zipOrPostalCode'
          placeholder='Postal Code'
          rules={{
            required: 'Please enter the postal code',
          }}
          readOnly={readOnly}
          shouldUnregister={false}
        />
        <ZenControlledAsyncSelectInput<FormData, 'administrativeAreaId'>
          control={control}
          name='administrativeAreaId'
          label='Administrative Area'
          placeholder='Search Administrative Area'
          fetchData={async (search, page) => {
            try {
              const {
                data: searchResponse,
              } = await new AdministrativeAreaControllerApi(
                getYentaConfiguration(),
              ).searchAdministrativeAreas(
                page,
                DEFAULT_PAGE_SIZE,
                'ASC',
                ['ID'],
                undefined,
                undefined,
                search,
              );

              const options: AsyncSelectOption[] = (
                searchResponse?.results || []
              ).map((resp) => ({
                value: `${resp.id}`,
                label: `${resp.id}`,
              }));

              return options;
            } catch (e) {
              ErrorService.notify(
                'Unable to search request administrative areas offices in create office form',
                e,
                { search: { searchQuery: search } },
              );
              dispatch(
                showErrorToast(
                  'An unexpected error occurred.',
                  'We were unable to search for an administrative area. Please try again in a few moments or contact support.',
                ),
              );
            }

            return [];
          }}
          rules={{ required: 'Please select the administrative area' }}
          shouldUnregister={false}
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenCompanyFormSidebarModal;
