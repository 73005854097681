import classNames from 'classnames';
import {
  PaymentMethodResponse,
  PaymentMethodResponseTypeEnum,
} from '../openapi/yenta';
import { capitalizeEnum } from '../utils/StringUtils';
import { showEncryptedString } from '../utils/DisplayHelpers';

interface Props {
  agentId: string;
  data: PaymentMethodResponse;
}
const BankAccountTabDetails: React.FC<Props> = ({ data }) => {
  return (
    <div className='divide-y'>
      {/* {data.map((data) => ( */}
      <div key={data.id} className='px-7 py-6 flex flex-col'>
        <div className='flex flex-row items-center justify-between w-full space-x-4'>
          <div className='flex flex-row items-center space-x-3'>
            <p className='capitalize font-zen-body font-semibold text-base text-zen-dark-9'>
              {data.nickname}
            </p>
            <p
              className={classNames(
                'px-3 py-2 rounded-full text-sm font-zen-title font-medium bg-opacity-10',
                data.type === PaymentMethodResponseTypeEnum.Savings
                  ? 'bg-zen-purple-dark text-zen-purple-dark'
                  : 'bg-primary-blue text-primary-blue',
              )}
            >
              {capitalizeEnum(data.type!)}
            </p>
          </div>
        </div>

        <div className='space-y-1'>
          {!!data.usRoutingNumber && (
            <div className='flex font-zen-body space-x-1 text-zen-dark-7'>
              <p className='font-semibold'>
                Routing No: {data.usRoutingNumber}
              </p>
            </div>
          )}
          {!!data.canadaRoutingNumber?.institutionNumber && (
            <div className='flex font-zen-body space-x-1 text-zen-dark-7'>
              <p className='font-semibold'>
                Institution No:{' '}
                {showEncryptedString(
                  data.canadaRoutingNumber.institutionNumber,
                  0,
                )}
              </p>
            </div>
          )}
          {!!data.canadaRoutingNumber?.branchNumber && (
            <div className='flex font-zen-body space-x-1 text-zen-dark-7'>
              <p className='font-semibold'>
                Branch No:{' '}
                {showEncryptedString(data.canadaRoutingNumber.branchNumber, 0)}
              </p>
            </div>
          )}
          <div className='flex font-zen-body space-x-1 text-zen-dark-7'>
            <p className='font-semibold'>
              Account No: {showEncryptedString(data.accountNumber)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankAccountTabDetails;
