import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMetabaseSignedUrl } from '../slices/BrokerQueueSlice';
import { RootState } from '../types';
import BrokerDashboardRoute from './BrokerDashboardRoute';
import MetabaseBrokerDashboard from './MetabaseBrokerDashboard';

const BrokerDashboardDecider: React.FC = () => {
  const { metabaseUrl } = useSelector((state: RootState) => state.brokerQueue);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMetabaseSignedUrl());
  }, [dispatch]);

  return (
    <>
      {!!metabaseUrl.data ? (
        <MetabaseBrokerDashboard
          isLoading={metabaseUrl.loading}
          metabaseUrl={metabaseUrl.data!}
        />
      ) : (
        <BrokerDashboardRoute />
      )}
    </>
  );
};

export default BrokerDashboardDecider;
