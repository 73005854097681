import {
  TeamMemberResponse,
  TeamMemberResponseRolesEnum,
  UserPreviewResponse,
} from '../../../../openapi/yenta';
import ZenTeamAgentNameCell from './ZenTeamAgentNameCell';
import ZenMultiMemberNameCell from './ZenMultiMemberNameCell';

export interface ZenTeamMembersNameCellProps {
  agents: TeamMemberResponse[];
  excludeName?: boolean;
}

const ZenTeamMembersNameCell: React.FC<ZenTeamMembersNameCellProps> = ({
  agents,
  excludeName = false,
}) => {
  const members: UserPreviewResponse[] = (agents || [])?.map(
    (agent) => agent?.agent!,
  );

  if (!members?.length) {
    return (
      <div className='font-zen-body font-normal text-base text-zen-gray-5'>
        N/A
      </div>
    );
  }

  if (members?.length === 1) {
    return (
      <ZenTeamAgentNameCell
        agent={members[0]}
        role={TeamMemberResponseRolesEnum.Member}
        showLeaderIcon={false}
        excludeName={excludeName}
      />
    );
  }

  return (
    <div className='flex items-center'>
      <ZenMultiMemberNameCell users={members} numToDisplay={2} />
    </div>
  );
};

export default ZenTeamMembersNameCell;
