import React from 'react';

import NullableText from '../../../table/Cells/NullableTextCell';
import { getFormattedDateISOString } from '../../../../utils/DateUtils';

export interface ZenDateCellProps {
  date?: string;
  placeholder?: React.ReactElement | string;
}

const ZenDateCell: React.FC<ZenDateCellProps> = ({ date, placeholder }) => {
  if (!date && placeholder) {
    return <div>{placeholder}</div>;
  }

  return <NullableText text={date && getFormattedDateISOString(date)} />;
};

export default ZenDateCell;
