import React from 'react';

interface USCommissionDepositReceiptFooterNoteProps {}

const USCommissionDepositReceiptFooterNote: React.FC<USCommissionDepositReceiptFooterNoteProps> = () => {
  return (
    <div className='flex flex-col pt-20 text-base'>
      <em>
        <span className='font-primary-bold'>NOTE:</span> In the event conditions
        in your Purchase Agreement are not met and a Mutual Release directs
        return of deposit, funds will be returned as follows:
      </em>
      <div className='flex flex-col px-14'>
        <em>
          1. ACH will be processed at no cost and will be completed within 3
          business days.
        </em>
        <em>
          2. Wires will be subject to a $35 wire fee and will be completed
          within 3 business days.
        </em>
        <em>
          3. Checks will be mailed and may take up to 21 days for delivery.
        </em>
      </div>
      <em>
        All return of deposits are done by EFT. Please provide the bank
        information for the return of deposit at the time of the fully executed
        Mutual Release.
      </em>
    </div>
  );
};

export default USCommissionDepositReceiptFooterNote;
