import React, { useState } from 'react';
import { FlexTeamDto } from '../../../../openapi/yenta';
import ZenSidebarModal from '../../ZenSidebarModal';
import ZenTabs from '../../ZenTabs';
import { LeaderDto } from '../../../../routes/ZenTeamsProDetailsRoute';
import ZenEditProTeamDetailTab from './ZenEditProTeamDetailsTab';
import ZenTeamLeaderFeeSplitsTab from './ZenTeamLeaderFeeSplitsTab';
import ZenAgentFeesTab from './ZenAgentFeesTab';
import ZenTeamFeesTab from './ZenTeamFeesTab';
import EditConfigTeams from './EditConfigTeams';

interface ZenEditProTeamFormProps {
  isOpen: boolean;
  onClose(): void;
  team: FlexTeamDto;
  leaders?: LeaderDto[];
  readOnly?: boolean;
  teamDetailReadOnly?: boolean;
}

const ZenEditProTeamForm: React.FC<ZenEditProTeamFormProps> = ({
  isOpen,
  onClose,
  team = {},
  leaders,
  readOnly = true,
  teamDetailReadOnly = true,
}) => {
  const [selected, setSelected] = useState<string>();

  return (
    <ZenSidebarModal title='Edit Team' isOpen={isOpen} onClose={onClose}>
      <div className='h-full'>
        <ZenTabs
          equalWidths
          tabs={[
            {
              name: 'Team Config',
              TabComponent: (
                <EditConfigTeams
                  readOnly={readOnly}
                  team={team!}
                  onClose={onClose}
                />
              ),
            },
            {
              name: 'Team Details',
              TabComponent: (
                <ZenEditProTeamDetailTab
                  readOnly={teamDetailReadOnly && readOnly}
                  team={team}
                  onClose={onClose}
                />
              ),
            },
            {
              name: 'Leader Fees',
              TabComponent: (
                <ZenTeamLeaderFeeSplitsTab
                  readOnly={readOnly}
                  teamId={team?.id!}
                  leaders={leaders || []}
                  onClose={onClose}
                />
              ),
            },
            {
              name: 'Agent Fees',
              TabComponent: (
                <ZenAgentFeesTab onClose={onClose} leaders={leaders || []} />
              ),
            },
            {
              name: 'Team Fees',
              TabComponent: (
                <ZenTeamFeesTab
                  readOnly={readOnly}
                  onClose={onClose}
                  team={team}
                />
              ),
            },
          ]}
          selected={selected}
          onChange={setSelected}
          size='sm'
        />
      </div>
    </ZenSidebarModal>
  );
};

export default ZenEditProTeamForm;
