import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

interface ActivityIconProps {
  icon: IconProp;
  iconSize?: SizeProp;
  color?: string;
}

const ActivityIcon: React.FC<ActivityIconProps> = ({
  icon,
  iconSize = 'xs',
  color = 'primary',
}) => {
  return (
    <div
      className={`rounded-full border border-zen-${color} w-full h-full flex items-center justify-center`}
    >
      <FontAwesomeIcon
        icon={icon}
        className={`text-zen-${color} opacity-70`}
        size={iconSize}
      />
    </div>
  );
};

export default ActivityIcon;
