import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { InstantPaymentItemResponse } from '../../openapi/arrakis';
import { displayAmount } from '../../utils/CurrencyUtils';

interface InstantPaymentsTransactionRowProps {
  repayment: InstantPaymentItemResponse;
}

const InstantPaymentsTransactionRow: React.FC<InstantPaymentsTransactionRowProps> = ({
  repayment,
}) => {
  return (
    <Link
      to={`/transactions/${repayment.repaymentTransaction?.id}`}
      className='flex flex-row items-center py-2'
    >
      <div className='flex-grow'>
        <div className='text-dark font-primary-medium'>
          {displayAmount(repayment.amount, { abs: true })}
        </div>
        <div className='flex flex-col md:flex-row flex-nowrap sp md:space-x-1 text-coolGray-500 text-sm'>
          <p>
            {DateTime.fromMillis(repayment.createdAt!).toFormat('LL/dd/yyyy')}
          </p>
          <p className='hidden md:block'>•</p>
          <p className='overflow-ellipsis'>
            {repayment.repaymentTransaction?.addressOneLine || 'N/A'}
          </p>
        </div>
      </div>
      <FontAwesomeIcon icon={faChevronRight} size='lg' className='text-dark' />
    </Link>
  );
};

export default InstantPaymentsTransactionRow;
