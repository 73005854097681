import {
  Controller,
  FieldValues,
  FieldPath,
  UseControllerProps,
} from 'react-hook-form-v7';
import { cn } from '../utils/classUtils';
import { FormFieldTooltipIndexProps } from '../types';
import FormErrorMessage from './FormErrorMessage';

interface ControlledSignatureInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
}

const ControlledSignatureInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  placeholder,
  readOnly,
  tooltipIndex,
  shouldUnregister = true,
  ...rest
}: ControlledSignatureInputV7Props<TFieldValues, TName>) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              {label}
            </label>
          )}
          <input
            id={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            type='text'
            name={name}
            placeholder={placeholder}
            style={{ fontSize: '23px' }}
            readOnly={readOnly}
            className={cn(
              'appearance-none p-1.5 border-gray-200 rounded bg-white focus:outline-none focus:ring-0 w-full font-signature',
              {
                'bg-gray-50': readOnly,
              },
            )}
            data-tooltip-index={tooltipIndex}
          />
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledSignatureInputV7;
