import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  AppDispatch,
  AppThunk,
  AsyncResponse,
  LearnworldsState,
} from '../types';
import {
  LearnworldsControllerApi,
  LearnworldsSsoRequest,
  LearnworldsSsoResponse,
} from '../openapi/yenta';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { performAsyncRequest } from './utils/SliceUtil';

export const initialState: LearnworldsState = {
  signOnResponse: { loading: false, name: 'sign on response ' },
};

const LearnworldsSlice = createSlice({
  name: 'learnworlds',
  initialState,
  reducers: {
    saveSignOnResponse(
      state,
      action: PayloadAction<AsyncResponse<LearnworldsSsoResponse>>,
    ) {
      state.signOnResponse = action.payload;
    },
  },
});

export const { saveSignOnResponse } = LearnworldsSlice.actions;

export const signInToLearnworlds = (
  agentId: string,
  req: LearnworldsSsoRequest,
): AppThunk<
  Promise<AxiosResponse<LearnworldsSsoResponse> | undefined>
> => async (dispatch) => {
  const fetch = () =>
    new LearnworldsControllerApi(getYentaConfiguration()).signInToLearnworlds(
      agentId,
      req,
    );

  const res = await performAsyncRequest<LearnworldsSsoResponse>(
    dispatch as AppDispatch,
    'sign on response',
    saveSignOnResponse,
    fetch,
  );

  if (!res) {
    return undefined;
  }

  return res;
};

export default LearnworldsSlice.reducer;
