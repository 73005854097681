import React, { FormEvent, FormEventHandler } from 'react';
import ZenSidebarModalActionFooterV2 from './Modal/ZenSidebarModalActionFooterV2';
import ZenSidebarModal, { ZenSidebarModalProps } from './ZenSidebarModal';

export interface ZenSidebarModalFormProps extends ZenSidebarModalProps {
  actionTitle: string;
  cancelTitle?: string;
  hideFooterActions?: boolean;
  isSubmitting: boolean;
  isDisabled?: boolean;
  onSubmit: FormEventHandler;
  isEqualWidth?: boolean;
}

const ZenSidebarModalForm: React.FC<ZenSidebarModalFormProps> = ({
  hideFooterActions: hideFooterAction = false,
  children,
  actionTitle,
  cancelTitle = 'Cancel',
  isSubmitting,
  isDisabled,
  onSubmit,
  isEqualWidth = false,
  ...props
}) => {
  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    onSubmit(evt);
  };

  return (
    <ZenSidebarModal {...props}>
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit}
        title='sidebar-form'
      >
        <div className='p-4 flex-auto mb-20'>{children}</div>
        {!hideFooterAction && (
          <ZenSidebarModalActionFooterV2
            submitButtonText={actionTitle}
            cancelButtonText={cancelTitle}
            onClose={props?.onClose}
            isSubmitting={isSubmitting}
            isDisabled={isSubmitting || isDisabled}
            isEqualWidth={isEqualWidth}
          />
        )}
      </form>
    </ZenSidebarModal>
  );
};

export default ZenSidebarModalForm;
