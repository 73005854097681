import { values } from 'lodash';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { ADDRESS_PLACEHOLDER } from '../../constants/PlaceholderConstants';
import {
  CommissionAdvanceResponse,
  UpdateCommissionAdvanceRequest,
} from '../../openapi/arrakis';
import { MoneyValue } from '../../openapi/yenta';
import { updateAgentCommissionAdvances } from '../../slices/AgentSlice';
import {
  ISelectOption,
  UpdateCommissionAdvanceRequestAdvanceStatusEnum,
} from '../../types';
import { getFileNameFromUrl } from '../../utils/StringUtils';
import { EMAIL_VALIDATIONS, FILE_VALIDATIONS } from '../../utils/Validations';
import AdminOnly from '../auth/AdminOnly';
import { CommissionStatusToLabelMap } from '../table/Cells/ZenAgentCommissionAdvancesStatusCell';
import ZenControlledEmailInput from '../Zen/Input/ZenControlledEmailInput';
import ZenControlledFileUploadInput from '../Zen/Input/ZenControlledFileUploadInput';
import ZenControlledMoneyInput from '../Zen/Input/ZenControlledMoneyInput';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalActionFooterV2 from '../Zen/Modal/ZenSidebarModalActionFooterV2';
import ZenSidebarModal from '../Zen/ZenSidebarModal';

interface ZenEditAgentCommissionAdvancesSidebarFormProps {
  isOpen: boolean;
  onClose(): void;
  commissionAdvance: CommissionAdvanceResponse;
  refresh(): void;
}

interface FormData {
  amount: MoneyValue;
  companyName: string;
  companyEmail: string;
  companyAddress: string;
  agreementPath: File[];
  advanceStatus: ISelectOption;
  referenceNumber: string;
  notes: string;
}

const ZenEditAgentCommissionAdvancesSidebarForm: React.FC<ZenEditAgentCommissionAdvancesSidebarFormProps> = ({
  isOpen,
  onClose,
  commissionAdvance,
  refresh,
}) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    const updateCommissionAdvanceRequest: UpdateCommissionAdvanceRequest = {
      amount: data.amount,
      companyName: data.companyName,
      companyEmail: data.companyEmail,
      companyAddress: data.companyAddress,
      referenceNumber: data.referenceNumber,
      advanceStatus: ((data.advanceStatus?.value as unknown) ||
        commissionAdvance?.advanceStatus) as UpdateCommissionAdvanceRequestAdvanceStatusEnum,
      notes: data.notes,
    };

    await dispatch(
      updateAgentCommissionAdvances(
        commissionAdvance?.id!,
        updateCommissionAdvanceRequest,
        //@ts-ignore
        data.agreementPath?.length ? data.agreementPath[0] : undefined,
      ),
    );
    refresh();
    onClose();
  };

  return (
    <ZenSidebarModal
      title='Edit a Commission Advance'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col justify-between min-h-full'
      >
        <div className='px-4 pb-24'>
          <div className='mt-5'>
            <ZenControlledMoneyInput<FormData, 'amount'>
              control={control}
              label='Amount'
              name='amount'
              placeholder='2000'
              defaultValue={commissionAdvance?.amount}
              rules={{
                required: 'Please enter an amount',
              }}
              currencyReadOnly
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Company Name'
              name='companyName'
              defaultValue={commissionAdvance?.companyName}
              rules={{ required: 'Please enter a company name' }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledEmailInput
              control={control}
              label='Company Email'
              name='companyEmail'
              defaultValue={commissionAdvance?.companyEmail}
              rules={{
                required: 'Please enter a company email',
                ...EMAIL_VALIDATIONS,
              }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Company Address'
              name='companyAddress'
              placeholder={ADDRESS_PLACEHOLDER}
              defaultValue={commissionAdvance?.companyAddress}
              rules={{
                required: "Please enter the company's address",
              }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledFileUploadInput
              control={control}
              label='Agreement'
              name='agreementPath'
              placeholder={getFileNameFromUrl(
                commissionAdvance?.agreementPath!,
              )}
              rules={{
                ...FILE_VALIDATIONS,
              }}
            />
          </div>
          <AdminOnly>
            <div className='mt-5'>
              <ZenControlledSelectInput
                control={control}
                label='Status'
                name='advanceStatus'
                options={values(
                  UpdateCommissionAdvanceRequestAdvanceStatusEnum,
                ).map((c) => ({
                  value: c,
                  label: CommissionStatusToLabelMap[c],
                }))}
                defaultValue={{
                  label: !!commissionAdvance?.advanceStatus
                    ? CommissionStatusToLabelMap[
                        commissionAdvance?.advanceStatus!
                      ]
                    : '',
                  value: commissionAdvance?.advanceStatus!,
                }}
                rules={{ required: 'Please select a status' }}
                isRequired
              />
            </div>
          </AdminOnly>
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Reference Number (Optional)'
              name='referenceNumber'
              defaultValue={commissionAdvance?.referenceNumber}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput
              control={control}
              label='Notes (Optional)'
              name='notes'
              defaultValue={commissionAdvance?.notes}
            />
          </div>
        </div>
        <ZenSidebarModalActionFooterV2
          onClose={onClose}
          isSubmitting={isSubmitting}
          isDisabled={isSubmitting}
          submitButtonText='Save'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenEditAgentCommissionAdvancesSidebarForm;
