import {
  faCheck,
  faLink,
  faMultiply,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';
import { AnalyticsEventEnum, ISelectOption } from '../../../../types';

import {
  CreateGenericTeamInvitationRequestDto,
  TeamControllerApi,
  TeamResponse,
  TeamResponseTypeEnum,
} from '../../../../openapi/yenta';
import AnalyticsService from '../../../../services/AnalyticsService';
import ErrorService from '../../../../services/ErrorService';
import { showApiErrorModal } from '../../../../slices/ErrorSlice';
import { getYentaConfiguration } from '../../../../utils/OpenapiConfigurationUtils';
import {
  getPersonalizedInvitationLink,
  getTeamCountry,
} from '../../../../utils/TeamHelper';
import AdminOnly from '../../../auth/AdminOnly';
import DefaultLoader from '../../../DefaultLoader';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../../Input/ZenControlledToggleInput';
import ZenSidebarModalActionFooterV2 from '../../Modal/ZenSidebarModalActionFooterV2';
import ZenControlledGroupRadioButtonInputV2 from '../../ZenControlledGroupRadioButtonInputV2';

interface ZenInviteAgentsViaLinkProps {
  onClose: () => void;
  options?: ISelectOption[];
  team: TeamResponse;
}

const ZenInviteAgentsViaLink: React.FC<ZenInviteAgentsViaLinkProps> = ({
  onClose,
  options,
  team,
}) => {
  const { control, watch, getValues, setValue } = useForm({
    defaultValues: {
      inviteLink: '',
      realCap: '',
      waiveFee: false,
    },
  });

  const dispatch = useDispatch();
  const [realCap, waiveFee] = watch(['realCap', 'waiveFee']);
  const [copied, setCopied] = useCopyToClipboard();

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);

  const isProTeam = team.type === TeamResponseTypeEnum.Pro;
  const disableWaiveFee = isProTeam && !realCap;
  const copyButtonLabel = copied ? 'Link Copied!' : 'Copy Link';

  const getGenericLink = async () => {
    setLoading(true);
    setCopied(null);

    const payload: CreateGenericTeamInvitationRequestDto = {
      teamId: team.id!,
      capLevel: realCap ? +realCap : undefined,
      waiveFees: waiveFee,
    };

    try {
      const { data } = await new TeamControllerApi(
        getYentaConfiguration(),
      ).generateGenericInvitation(team.id!, payload);

      if (data?.invitationId) {
        const inviteLink = getPersonalizedInvitationLink(
          data.invitationId,
          team.name!,
          payload.capLevel,
          getTeamCountry(team),
        );
        setValue('inviteLink', inviteLink);
        setActiveStep(2);
      } else {
        throw new Error('No invitation ID received');
      }
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to update team', e, {
        team: { payload },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (realCap || waiveFee || !isProTeam) {
      getGenericLink();
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realCap, isProTeam, waiveFee]);

  useEffect(() => {
    const mapCapToEvent: Record<string, AnalyticsEventEnum> = {
      '4000':
        AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_SELECT_4K_CAP,
      '6000':
        AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_SELECT_6K_CAP,
      '12000':
        AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_SELECT_12K_CAP,
    };

    const event = mapCapToEvent[realCap];

    if (event) {
      AnalyticsService.instance().logEvent(event);
    }
  }, [realCap]);

  const handleCopy = () => {
    setCopied(getValues('inviteLink')!);
  };

  return (
    <div className='p-6 mt-2'>
      {isProTeam && (
        <div className='mb-6'>
          <ZenControlledGroupRadioButtonInputV2
            label="Select Agent's Real Cap"
            control={control}
            options={options!}
            minWidth='w-60'
            name='realCap'
            shouldUnregister={false}
            isRequired
            rules={{
              required: 'Please select a Real Cap',
            }}
          />
        </div>
      )}

      <AdminOnly>
        <div className='-mx-2 mb-6'>
          <ZenControlledToggleInput
            name='waiveFee'
            control={control}
            label='Waive Join Fee'
            labelClassName='text-md font-semibold font-zen-body'
            leftIcon={<FontAwesomeIcon icon={faMultiply} size='sm' />}
            rightIcon={<FontAwesomeIcon icon={faCheck} size='sm' />}
            disabled={disableWaiveFee}
            onChangeSpy={(waiveFee) => {
              const event = waiveFee
                ? AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_LINK_WAIVE_JOIN_FEE_ON
                : AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_LINK_WAIVE_JOIN_FEE_OFF;

              AnalyticsService.instance().logEvent(event);
            }}
          />
        </div>
      </AdminOnly>

      {loading ? (
        <DefaultLoader />
      ) : (
        <div
          onClick={() => {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_LINK_CLICK_INSIDE_SHARE_INVITE_LINK_BOX,
            );
          }}
        >
          <ZenControlledTextInput
            label='Share Invite Link'
            control={control}
            name='inviteLink'
            readOnly
            shouldUnregister={false}
          />
        </div>
      )}

      <div className='bg-grey-100 rounded-lg py-4 px-4 mt-7'>
        <p className='text-sm font-inter font-semibold'>How does it work?</p>
        <p className='mt-2 text-sm font-inter'>
          This link contains team information and member cap, but not personal
          details like first name, last name, or email address. To access a
          personalized link with these details, you must first invite the agent.
          After inviting them, you can find the personalized link on the Team
          Dashboard for that agent.
        </p>
      </div>

      <ZenSidebarModalActionFooterV2
        onClose={() => {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_LINK_CLICK_CANCEL,
          );

          onClose();
        }}
        isSubmitting={false}
        isDisabled={!activeStep || loading}
        submitButtonText={copyButtonLabel}
        buttonType='button'
        onClick={async () => {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_LINK_CLICK_COPY_LINK,
          );

          handleCopy();
        }}
        SubmitButtonLeftIconComponent={
          <FontAwesomeIcon icon={faLink} title='copy' className='mr-1' />
        }
      />
    </div>
  );
};

export default ZenInviteAgentsViaLink;
