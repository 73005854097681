import { sum } from 'lodash';
import { ColoredProgressBarItem } from '../types';
import { cn } from '../utils/classUtils';

export interface ColoredProgressBarProps {
  items: ColoredProgressBarItem[];
}

const ColoredProgressBar: React.FC<ColoredProgressBarProps> = ({ items }) => {
  const total = sum(items.map((item) => item.value));

  return (
    <div className='overflow-hidden h-3 flex rounded-full'>
      {items.map((item) => {
        const barWidth = (item.value / total) * 100;

        return (
          <div
            key={item.value}
            data-testid='colored-progress-bar'
            style={{
              width: `${barWidth}%`,
            }}
            className={cn(
              'shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center',
              barWidth >= 100 ? 'bg-primary-blue' : 'bg-yellow-400',
            )}
          />
        );
      })}
    </div>
  );
};

export default ColoredProgressBar;
