import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { cn } from '../../utils/classUtils';

type ZenIconTextLinkTextVariant = 'small' | 'medium' | 'large';

interface ZenIconTextLinkProps {
  icon: IconProp;
  text: React.ReactElement | string;
  lineClamp?: number;
  variant?: ZenIconTextLinkTextVariant;
  containerClassName?: string;
  textClassName?: string;
  onClick?(): void;
}

const ZenIconTextLink: React.FC<ZenIconTextLinkProps> = ({
  icon,
  text,
  lineClamp,
  variant = 'medium',
  onClick,
  containerClassName,
  textClassName,
}) => {
  const variantMapClass: Record<ZenIconTextLinkTextVariant, string> = {
    small: 'text-sm',
    medium: 'text-base',
    large: 'text-lg',
  };

  const containerClasses = cn(
    'flex flex-row items-center flex-nowrap font-zen-body',
    variantMapClass[variant],
    containerClassName,
  );

  const textClasses = cn(
    'pl-2 pb-0.5',
    lineClamp ? `line-clamp-${lineClamp}` : 'line-clamp-none',
    textClassName,
  );

  return (
    <button onClick={onClick}>
      <div className={containerClasses}>
        <FontAwesomeIcon icon={icon} />
        <p className={textClasses}>{text}</p>
      </div>
    </button>
  );
};

export default ZenIconTextLink;
