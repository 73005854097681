import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'react-table';
import { cn } from '../../../utils/classUtils';
import {
  CardItemPosition,
  CustomColumn,
} from '../Containers/ZenResourceIndexContainer';

interface ZenResourceCardProps<D extends object> {
  row: Row<D>;
  isExpandable?: boolean;
}

const ZenResourceTableCard = <D extends object>({
  row,
  isExpandable = true,
}: ZenResourceCardProps<D>) => {
  const { key, ...rest } = row.getRowProps();

  const extractElements = (position: CardItemPosition) => {
    return row.cells
      ?.filter(
        (cell) => (cell.column as CustomColumn<D>)?.position === position,
      )
      .filter((cell) => cell?.column?.id !== 'ribbon');
  };

  const headerLeftElements = extractElements('top-left');
  const headerRightElements = extractElements('top-right');
  const mainElements = extractElements('main');
  const collapsibleElements = extractElements('collapsible');

  return (
    <div
      className='w-full px-4 py-3 rounded-[10px] border border-grey-200 bg-white shadow-zen-card'
      key={key}
      data-testid='gemini-table-card'
    >
      <div className='w-full'>
        <div {...rest}>
          <div className='flex items-center justify-between pb-1.5 border-b border-grey-200 space-x-5'>
            <div className='flex flex-row items-center justify-start flex-shrink'>
              {headerLeftElements?.map((cell, index) => (
                <div
                  {...cell.getCellProps()}
                  key={cell?.column?.id}
                  className='flex items-center flex-shrink'
                >
                  {cell.render('Cell')}
                  {index !== headerLeftElements?.length - 1 && (
                    <div className='h-6 w-[1px] bg-grey-200 mx-2' />
                  )}
                </div>
              ))}
            </div>
            <div className='flex items-center flex-shrink-0 w-max'>
              {headerRightElements?.map((cell) => (
                <div {...cell.getCellProps()} key={cell?.column?.id}>
                  {cell.render('Cell')}
                </div>
              ))}
              {isExpandable && (
                <button
                  onClick={() => row.toggleRowExpanded()}
                  className='w-7 h-7 flex items-center justify-center rounded-full shrink-0 ml-2.5 hover:bg-regent-300'
                >
                  <FontAwesomeIcon
                    icon={row.isExpanded ? faChevronUp : faChevronDown}
                    size='sm'
                    className='text-primary-dark font-light'
                    data-testid='transaction-card-toggle-arrow-button'
                  />
                </button>
              )}
            </div>
          </div>
          <div className='flex flex-row space-x-5 mt-2'>
            <div className='w-[18%] flex flex-col space-y-2'>
              {mainElements?.map((cell) => (
                <div {...cell.getCellProps()} key={cell?.column?.id}>
                  {cell.render('Cell')}
                </div>
              ))}
            </div>
            <div
              className={cn(
                'w-[82%]',
                isExpandable
                  ? 'flex flex-row flex-wrap'
                  : 'grid 2xl:grid-cols-7 xl:grid-cols-6 lg:grid-cols-5 grid-cols-4 gap-y-5',
              )}
              style={{
                height: !row.isExpanded && isExpandable ? 66 : 'auto',
                overflow: 'hidden',
              }}
            >
              {collapsibleElements?.map((cell) => (
                <div
                  {...cell.getCellProps()}
                  key={cell?.column?.id}
                  className={cn(isExpandable && 'px-10')}
                  style={{ minHeight: 66 }}
                >
                  {cell.render('Cell')}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenResourceTableCard;
