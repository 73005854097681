import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  JobExecutionControllerApi,
  JobExecutionsResponse,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import {
  AppDispatch,
  AppThunk,
  AsyncResponse,
  CronJobsState,
  ErrorCode,
} from '../types';
import { showAndReportErrors } from '../utils/ErrorUtils';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from './ErrorSlice';
import { performAsyncRequest } from './utils/SliceUtil';

export const initialState: CronJobsState = {
  cronJobsResponse: { loading: false, name: 'jobs' },
  cronJobHistoryLoading: false,
  cronJobHistory: [],
  fetchCronJobHistoryErrorCode: null,
};

const CronJobsSlice = createSlice({
  name: 'cronjobs',
  initialState,
  reducers: {
    saveCronJobResponse(
      state,
      action: PayloadAction<AsyncResponse<JobExecutionsResponse>>,
    ) {
      state.cronJobsResponse = action.payload;
    },
    changeCronJobHistoryLoading(state, action: PayloadAction<boolean>) {
      state.cronJobHistoryLoading = action.payload;
    },
    saveCronJobHistory(state, action: PayloadAction<JobExecutionsResponse>) {
      state.cronJobHistory = action.payload.jobExecutionResponses;
      state.fetchCronJobHistoryErrorCode = null;
    },
    errorFetchingCronJobHistory(state, action: PayloadAction<ErrorCode>) {
      state.fetchCronJobHistoryErrorCode = action.payload;
    },
  },
});

export const {
  saveCronJobResponse,
  changeCronJobHistoryLoading,
  saveCronJobHistory,
  errorFetchingCronJobHistory,
} = CronJobsSlice.actions;

export const getLatestCronJobs = (): AppThunk => async (dispatch) => {
  const fetch = () =>
    new JobExecutionControllerApi(
      getYentaConfiguration(),
    ).getLatestJobExecutions();

  await performAsyncRequest<JobExecutionsResponse>(
    dispatch as AppDispatch,
    'jobs',
    saveCronJobResponse,
    fetch,
    { skipAuthDatadog: true },
  );
};

export const getCronJobsHistory = (jobName: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeCronJobHistoryLoading(true));
  try {
    const { data } = await new JobExecutionControllerApi(
      await getYentaConfiguration(),
    ).getJobExecutions(jobName);
    dispatch(saveCronJobHistory(data));
  } catch (e) {
    dispatch(errorFetchingCronJobHistory(ErrorService.getErrorCode(e)));
    if (showAndReportErrors(e?.response)) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to fetch cron jobs history', e);
    }
  } finally {
    dispatch(changeCronJobHistoryLoading(false));
  }
};

export default CronJobsSlice.reducer;
