import {
  faArrowUpRight,
  faClose,
  faPhoneXmark,
  faUserGroup,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { VoiceCallBlockRecordingTypeEnum } from '../../../../openapi/yada';
import { RootState } from '../../../../types';
import { getFullName } from '../../../../utils/AgentHelper';
import { cn } from '../../../../utils/classUtils';
import { formatTime } from '../../../../utils/DateUtils';
import { isCallCancelled } from '../../../../utils/RoarUtils';
import BrokerOnly from '../../../auth/BrokerOnly';
import ZenAvatar from '../../../Zen/ZenAvatar';
import ZenButton from '../../../Zen/ZenButton';
import ZenSidebarModal from '../../../Zen/ZenSidebarModal';
import TranscribedByLeoPill from './TranscribedByLeoPill';
import VoiceCallStatusPill from './VoiceCallStatusPill';

type VoiceCallTranscriptionDetailSidebarProps = {
  recordingId: string;
  onClose(): void;
  onClickEditSpeakers(params: {
    recordingId: string;
    closable?: boolean;
  }): void;
};

const VoiceCallTranscriptionDetailSidebar: React.FC<VoiceCallTranscriptionDetailSidebarProps> = ({
  recordingId,
  onClose,
  onClickEditSpeakers,
}) => {
  const {
    auth: { userDetail },
    userIds: { agentById },
    transaction: { recordingDataById },
  } = useSelector((state: RootState) => state);

  const recordingBlock = recordingDataById[recordingId]?.recordingBlock || {};
  const mentionBlock = recordingDataById[recordingId]?.mentionBlock || {};

  const { transcript, speakers } = recordingBlock.recordingTranscription || {};

  const agentIds = useMemo(() => {
    const arr: string[] = [];
    if (recordingBlock.callerId) {
      arr.push(recordingBlock.callerId);
    }
    if (recordingBlock.calleeId) {
      arr.push(recordingBlock.calleeId);
    }
    return arr;
  }, [recordingBlock.calleeId, recordingBlock.callerId]);

  const agents = useMemo(() => {
    return agentIds.map((agentId) => {
      const agentInfo = agentById[agentId];
      return {
        name: getFullName(agentInfo) || '',
        firstName: agentInfo?.firstName,
        lastName: agentInfo?.lastName,
        avatar: agentInfo?.avatar,
        showPlaceHolder: true,
      };
    });
  }, [agentById, agentIds]);

  const callCancelled = isCallCancelled(recordingBlock);
  const isVoiceCall =
    recordingBlock.recordingType === VoiceCallBlockRecordingTypeEnum.Call;
  const userId = userDetail?.id;
  const canEditSpeakers =
    userId === recordingBlock.calleeId || userId === recordingBlock.callerId;

  return (
    <ZenSidebarModal
      isOpen
      onClose={onClose}
      title='Transcription Details'
      customHeader={
        <div className='flex items-center justify-between px-5 py-4 bg-zen-light-blue'>
          <div className='flex items-center space-x-2'>
            <h2 className='text-base font-zen-body font-bold text-zen-dark-9 tracking-wide'>
              Transcription Details
            </h2>
            <TranscribedByLeoPill />
          </div>
          <button
            className='bg-zen-dark-10 rounded-full py-0.5 px-1 w-6 h-6 flex items-center justify-center'
            onClick={onClose}
          >
            <FontAwesomeIcon
              icon={faClose}
              className='text-white'
              fontSize={16}
            />
          </button>
        </div>
      }
    >
      <div className='pt-4 px-4 pb-20 font-zen-body text-primary-dark flex flex-col space-y-6'>
        <div className='flex items-center justify-between'>
          <div className='flex-1 flex items-center space-x-1.5'>
            <p className='flex-grow-0 flex-shrink text-base text-regent-900 line-clamp-1'>
              {agents[0]?.name || 'Unknown'}
            </p>
            {isVoiceCall && (
              <>
                <FontAwesomeIcon
                  icon={callCancelled ? faPhoneXmark : faArrowUpRight}
                  className={cn(
                    callCancelled ? 'text-red-600' : 'text-green-600',
                  )}
                  fontSize={12}
                />
                <p className='flex-grow-0 flex-shrink text-base text-regent-900 line-clamp-1'>
                  {callCancelled
                    ? mentionBlock?.name
                    : agents[1]?.name || 'Unknown'}
                </p>
              </>
            )}
          </div>
          <VoiceCallStatusPill recordingBlock={recordingBlock} />
        </div>
        <div className='mt-2.5 space-y-5'>
          {transcript?.segments?.map((segment) => {
            const speakerId = speakers?.find(
              (speaker) => speaker?.label === segment?.speaker,
            )?.userId;
            const speaker = agentById[speakerId!];

            return (
              <div
                key={`${segment?.speaker} - ${segment?.start}`}
                className='flex items-start space-x-3'
              >
                <p className='text-grey-600 flex-shrink-0'>
                  {formatTime(segment?.start || 0)}
                </p>
                <div>
                  <div className='flex items-center space-x-2'>
                    <ZenAvatar
                      name={getFullName(speaker) || 'Unknown'}
                      imageUrl={speaker?.avatar}
                      size='xs'
                    />
                    <p className='font-medium text-sm'>
                      {getFullName(speaker) || 'Unknown'}
                    </p>
                  </div>
                  <p className='mt-2 font-light font-inter'>{segment?.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2 w-full'>
        <div className='w-full flex justify-end space-x-4'>
          <BrokerOnly>
            {canEditSpeakers && (
              <ZenButton
                label='Edit Speakers'
                variant='primary-outline'
                LeftIconComponent={
                  <FontAwesomeIcon icon={faUserGroup} fontSize={14} />
                }
                onClick={() => onClickEditSpeakers({ recordingId })}
              />
            )}
          </BrokerOnly>
          <ZenButton
            label='Done'
            className='!px-14 py-2.5'
            onClick={onClose}
            fontstyle
          />
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default VoiceCallTranscriptionDetailSidebar;
