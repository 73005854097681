import { faLongArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveIsLeoModalVisible } from '../../../../slices/LeoSlice';
import { RootState } from '../../../../types';
import DefaultLoader from '../../../DefaultLoader';
import LeoOrb from '../../../../assets/img/leo/leoOrb.png';
import LeoChatPrompts from './LeoChatPrompts';

interface LeoIntroProps {}

const LeoIntro: React.FC<LeoIntroProps> = () => {
  const dispatch = useDispatch();
  const {
    leo: { loading },
  } = useSelector((state: RootState) => state);

  return (
    <div className='flex-grow flex flex-col pb-2'>
      <div className='h-[200px] flex-grow flex flex-col items-center px-10 overflow-y-auto scrollbar-hide'>
        <div className='w-full mt-4 -ml-10 block sm:hidden'>
          <button
            type='button'
            className='appearance-none'
            onClick={() => dispatch(saveIsLeoModalVisible(false))}
          >
            <FontAwesomeIcon
              icon={faLongArrowLeft}
              className='text-lg text-white'
            />
          </button>
        </div>
        <div className='w-fit py-5 md:mb-10'>
          <img
            className='w-28 h-28 rounded-full mx-auto'
            src={LeoOrb}
            alt='leo-orb'
          />
          <div className='mt-3 sm:mt-8'>
            <div>
              <p className='text-center text-[32px] text-white'>
                Hi!{' '}
                <span className='primary-gradient-text font-primary-medium'>
                  I&apos;m Leo
                </span>
              </p>
            </div>
            <div className='mt-2 flex md:flex-row flex-col items-center justify-center'>
              <p className='text-center text-white text-lg md:mr-1'>
                Ask me anything!
              </p>
              <p className='text-center text-white text-lg'>
                Here are a few examples:
              </p>
            </div>
          </div>
        </div>
        {loading ? (
          <DefaultLoader />
        ) : (
          <LeoChatPrompts isIntro promptSizeVariant='md' />
        )}
      </div>
    </div>
  );
};

export default LeoIntro;
