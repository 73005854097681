import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransactionResponse } from '../../../openapi/arrakis';
import {
  fetchBankAccounts,
  fetchMoneyTransfers,
} from '../../../slices/MoneyTransferSlice';
import { RootState } from '../../../types';
import DefaultLoader from '../../DefaultLoader';
import ZenAccountBalanceRow from './ZenAccountBalanceRow';
import ZenInfoCard from './ZenInfoCard';
import ZenMoneyTransfersTable from './ZenMoneyTransfersTable';
import ZenReleaseDepositsTable from './ZenReleaseDepositsTable';

interface MoneyTransfersSectionProps {
  transaction: TransactionResponse;
}

const ZenMoneyTransfersSection: React.FC<MoneyTransfersSectionProps> = ({
  transaction,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const { moneyTransfers, bankAccounts } = useSelector(
    (state: RootState) => state.moneyTransfer,
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    return Promise.all([
      dispatch(fetchMoneyTransfers(transaction?.id!)),
      dispatch(fetchBankAccounts(transaction?.id!)),
    ]);
  }, [dispatch, transaction?.id]);

  useEffect(() => {
    fetchData().finally(() => {
      setLoading(false);
    });
  }, [fetchData]);

  if (loading) {
    return <DefaultLoader />;
  }

  return (
    <div className='w-full p-4'>
      <ZenMoneyTransfersTable
        moneyTransfers={moneyTransfers}
        bankAccounts={bankAccounts}
        transaction={transaction}
      />
      <div className='w-full md:max-w-xl mt-5'>
        <ZenInfoCard title='Account Balance'>
          <div className='flex flex-col space-y-3'>
            {bankAccounts.map((bankAccount) => (
              <ZenAccountBalanceRow
                key={bankAccount.id}
                bankAccount={bankAccount}
              />
            ))}
          </div>
        </ZenInfoCard>
      </div>
      <div className='my-5'>
        <ZenReleaseDepositsTable transaction={transaction} />
      </div>
    </div>
  );
};

export default ZenMoneyTransfersSection;
