import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResourceContainer from '../components/ResourceContainer';
import { KnowledgePageSidebar } from '../components/WeathManagement/EarningLandingPages/KnowledgePageSidebar';
import SetGoalsSideBarV2, {
  GoalsFormInput,
} from '../components/WeathManagement/V2/SetGoalsSideBarV2';
import SetWealthGoalPageV2 from '../components/WeathManagement/V2/SetWealthGoalPageV2';
import { WealthManagementOverviewPageV2 } from '../components/WeathManagement/V2/WealthManagementOverviewPageV2';
import { selectAgentCurrency } from '../components/WeathManagement/WealthManagementUtils';
import ZenButton from '../components/Zen/ZenButton';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import useWealthPlanEvents from '../hooks/useWealthPlanEvents';
import { AgentWealthManagementApi } from '../openapi/plutus';
import AnalyticsService from '../services/AnalyticsService';
import ErrorService from '../services/ErrorService';
import { fetchYearlyIncomePerformanceOverview } from '../slices/AgentSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import {
  failedToFetchWealthManagementApi,
  fetchWealthGoals,
  getEarningsOverview,
  updateWealthGoalsData,
} from '../slices/WealthManagementSlice';
import { AnalyticsEventEnum, RootState } from '../types';
import { getWealthManagementConfiguration } from '../utils/OpenapiConfigurationUtils';
import { getUpdateWealthPlanRequest } from '../utils/WealthPlanUtils';

const WealthManagementRouteV2 = () => {
  const dispatch = useDispatch();
  const [showEditSideBar, setShowEditSideBar] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {
    auth: { userDetail },
    wealthManagement: {
      wealthGoalsResponse: { loading: goalsLoading, data: wealthGoalsResponse },
    },
    agentCurrency,
  } = useSelector((state: RootState) => ({
    ...state,
    agentCurrency: selectAgentCurrency(state),
  }));
  const agentId = userDetail?.id!;

  useEffect(() => {
    dispatch(fetchWealthGoals());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEarningsOverview());
  }, [dispatch, wealthGoalsResponse]);

  useEffect(() => {
    dispatch(fetchYearlyIncomePerformanceOverview(agentId));
  }, [agentId, dispatch]);

  useWealthPlanEvents(agentId);

  const handleUpdateGoals = async (formData: GoalsFormInput) => {
    setIsSubmitting(true);
    try {
      const request = getUpdateWealthPlanRequest(
        formData,
        agentCurrency,
        wealthGoalsResponse,
      );
      const { data } = await new AgentWealthManagementApi(
        await getWealthManagementConfiguration(),
      ).setWealthGoals(agentId, request);
      dispatch(updateWealthGoalsData(data));
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_UPDATE_PLAN,
      );
      dispatch(showSuccessToast('Wealth goals updated successfully'));
    } catch (error) {
      dispatch(
        failedToFetchWealthManagementApi({
          apiName: 'setWealthGoalsResponse',
          setWealthGoalsResponse: {
            error,
          },
        }),
      );
      dispatch(
        showErrorToast(
          'Unable to update wealth goals',
          'Please try again in a few moments.',
        ),
      );
      ErrorService.notify('Failed to update wealth goals', error, {
        agent: { id: agentId },
      });
    } finally {
      setShowEditSideBar(false);
      setIsSubmitting(false);
    }
  };

  const handleEditGoalsClicked = () => {
    setShowEditSideBar(true);
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.WEALTH_PLAN_OVERVIEW_SCREEN_EDIT_GOALS,
    );
  };

  return (
    <ZenRoute title='WealthPlan'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'WealthPlan', url: '/wealthplan' },
        ]}
      >
        <ResourceContainer
          loading={goalsLoading && !wealthGoalsResponse}
          isEmpty={false}
          resourceName='WealthPlan page'
        >
          <div className='md:p-5 p-3 h-full'>
            <div className='mb-4 flex place-content-between'>
              <div className='text-2xl'>WealthPlan</div>
              {!!wealthGoalsResponse && (
                <ZenButton
                  variant='primary-outline'
                  onClick={handleEditGoalsClicked}
                  label={
                    (
                      <div className='px-3'>
                        <FontAwesomeIcon icon={faPencil} className='mr-1' />
                        Edit Goal
                      </div>
                    ) as any
                  }
                />
              )}
            </div>

            {!!wealthGoalsResponse ? (
              <WealthManagementOverviewPageV2
                openGoalsSideBar={() => setShowEditSideBar(true)}
              />
            ) : (
              <SetWealthGoalPageV2 />
            )}
            {!!showEditSideBar && (
              <SetGoalsSideBarV2
                isOpen
                allowWealthGoalEdit
                onClose={() => setShowEditSideBar(false)}
                onSubmit={handleUpdateGoals}
                isSubmitting={isSubmitting}
              />
            )}
          </div>
        </ResourceContainer>
      </ZenPageLayout>
      <KnowledgePageSidebar />
    </ZenRoute>
  );
};

export default WealthManagementRouteV2;
