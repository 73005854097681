import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React from 'react';
import { revShareInsightsTierIconUnlockedMap } from '../../../utils/RevShareInsightsUtils';
import { ReactComponent as LockedTier1 } from '../../../assets/icons/revshare-insights/tiers/off/tier1.svg';
import { ReactComponent as LockedTier2 } from '../../../assets/icons/revshare-insights/tiers/off/tier2.svg';
import { ReactComponent as LockedTier3 } from '../../../assets/icons/revshare-insights/tiers/off/tier3.svg';
import { ReactComponent as LockedTier4 } from '../../../assets/icons/revshare-insights/tiers/off/tier4.svg';
import { ReactComponent as LockedTier5 } from '../../../assets/icons/revshare-insights/tiers/off/tier5.svg';
import { ReactComponent as UnlockedTier1 } from '../../../assets/icons/revshare-insights/tiers/on/tier1.svg';
import { ReactComponent as UnlockedTier2 } from '../../../assets/icons/revshare-insights/tiers/on/tier2.svg';
import { ReactComponent as UnlockedTier3 } from '../../../assets/icons/revshare-insights/tiers/on/tier3.svg';
import { ReactComponent as UnlockedTier4 } from '../../../assets/icons/revshare-insights/tiers/on/tier4.svg';
import { ReactComponent as UnlockedTier5 } from '../../../assets/icons/revshare-insights/tiers/on/tier5.svg';
import { cn } from '../../../utils/classUtils';

const tiers = [
  {
    color: '#05C3F9',
    unlockedIcon: UnlockedTier1,
    lockedIcon: LockedTier1,
    num: '01',
  },
  {
    color: '#439775',
    unlockedIcon: UnlockedTier2,
    lockedIcon: LockedTier2,
    num: '05',
  },
  {
    color: '#EA8039',
    unlockedIcon: UnlockedTier3,
    lockedIcon: LockedTier3,
    num: '15',
  },
  {
    color: '#F84C6C',
    unlockedIcon: UnlockedTier4,
    lockedIcon: LockedTier4,
    num: '20',
  },
  {
    color: '#5F2EEA',
    unlockedIcon: UnlockedTier5,
    lockedIcon: LockedTier5,
    num: '25',
  },
];

interface RevenueShareIncomeTierProgressProps {
  sizeOfNetwork?: number;
  numberOfFrontLineAgentsUnlockingTier: number;
  unlockValue: number;
  agentsRequiredToUnlockNextTier: number;
  unlockedTier: number;
}

export const RevenueShareIncomeTierProgress: React.FC<RevenueShareIncomeTierProgressProps> = ({
  sizeOfNetwork,
  numberOfFrontLineAgentsUnlockingTier,
  unlockValue,
  agentsRequiredToUnlockNextTier,
  unlockedTier,
}) => {
  const UnlockedBadge = revShareInsightsTierIconUnlockedMap[unlockValue || 1];

  const getTierProgress = (tier: number) => {
    if (tier === unlockedTier) {
      //  tier 2 to tier 3 requires 10 agents
      if (tier === 2) {
        return ((10 - agentsRequiredToUnlockNextTier) / 10) * 100;
      }
      // rest of the tiers requires 5 agents
      return ((5 - agentsRequiredToUnlockNextTier) / 5) * 100;
    }
    if (tier < unlockedTier) {
      return 100;
    }
    return 0;
  };

  return (
    <>
      <div className='flex items-center justify-between'>
        <div className='space-x-0.5'>
          {sizeOfNetwork ? (
            <p>My Network : {sizeOfNetwork ?? 0}</p>
          ) : (
            <p>Tier Details</p>
          )}
          <p className='font-semibold'>
            Tier 1 agents : {numberOfFrontLineAgentsUnlockingTier ?? 0}
          </p>
        </div>
        {!!UnlockedBadge && <UnlockedBadge />}
      </div>
      <p className='mt-3 pt-2 border-t border-zen-light-gray-2 text-center font-zen-body font-medium text-zen-dark-9'>
        Tier {unlockValue} Unlocked
      </p>
      <div className='mt-5'>
        <div className='relative flex flex-row items-center'>
          {tiers.map((tier, index) => {
            const isFirstTier = index === 0;
            const isLastTier = index === tiers.length - 1;
            const Icon =
              unlockedTier > index ? tier.unlockedIcon : tier.lockedIcon;
            const progress = getTierProgress(index + 1);

            if (isLastTier) {
              return (
                <div
                  key={tier.color}
                  className='absolute -top-2.5 -right-2 z-10'
                >
                  <Icon width={25} height={25} />
                  <p className='ml-1.5 mt-0.5 font-zen-body text-xs text-zen-dark-7'>
                    {tier.num}
                  </p>
                </div>
              );
            }

            return (
              <div
                key={tier.color}
                className='flex-1 relative pb-6'
                style={{ zIndex: index + 1 }}
              >
                <div
                  className={cn(
                    'flex-1 h-1.5',
                    isFirstTier ? 'rounded-full' : 'rounded-none',
                  )}
                  style={{ backgroundColor: '#EDEFF3' }}
                />
                <motion.div
                  initial={{ width: '0%' }}
                  animate={{ width: progress + '%' }}
                  transition={{ duration: 1.5 }}
                  style={{ backgroundColor: tier.color }}
                  className={cn(
                    'absolute h-1.5 top-0 inset-x-0',
                    isFirstTier ? 'rounded-full' : 'rounded-r',
                  )}
                />
                <div className='absolute -top-2.5 -left-2 z-10'>
                  <Icon width={25} height={25} />
                  <p className='ml-1.5 mt-0.5 font-zen-body text-xs text-zen-dark-7'>
                    {tier.num}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {unlockValue < 5 && (
        <div className='pt-4 flex items-center justify-center space-x-2 text-center text-zen-dark-7'>
          <FontAwesomeIcon icon={faLock} />
          <p>Next tier {unlockValue + 1}</p>
          <div className='w-1.5 h-1.5 bg-zen-dark-12 rounded-full' />
          <p>{agentsRequiredToUnlockNextTier || 'N/A'} agents to unlock</p>
        </div>
      )}
    </>
  );
};
