import { faArrowRightLong } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../types';
import { isOutOfOffice } from '../../../../utils/RoarUtils';
import { UUID_REGEX } from '../../../../utils/StringUtils';
import OutOfOfficeForm from './OutOfOfficeForm';

type OutOfOfficeBannerProps = {};

const OutOfOfficeBanner: React.FC<OutOfOfficeBannerProps> = () => {
  const history = useHistory();
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const userDetail = useSelector((state: RootState) => state.auth.userDetail);
  const outOfOffice = isOutOfOffice(userDetail);
  const officeHoursRouteRegExp = new RegExp(
    `/people/${UUID_REGEX}/office-hours`,
  );
  const isOfficeHoursRouteActive = officeHoursRouteRegExp.test(
    history.location.pathname,
  );

  if (isOfficeHoursRouteActive || !outOfOffice) {
    return null;
  }

  return (
    <>
      <div className='flex items-center m-4 py-3 px-4 md:px-6 md:py-3 border border-yellow-100 bg-yellow-100 rounded-[10px] justify-between space-x-3 transition duration-300'>
        <div className='flex items-center space-x-3'>
          <FontAwesomeIcon icon={faInfoCircle} className='text-regent-900' />
          <p className='text-base font-inter font-medium text-primary-navy'>
            You are currently out of office. Would you like to end your
            scheduled time off early?
          </p>
        </div>
        <button
          onClick={() => setShowScheduleModal(true)}
          className='font-inter font-medium text-rezen-blue-600 space-x-1.5'
        >
          <span>Update Out Of Office</span>
          <FontAwesomeIcon icon={faArrowRightLong} />
        </button>
      </div>
      {showScheduleModal && (
        <OutOfOfficeForm
          onClose={() => setShowScheduleModal(false)}
          agentId={userDetail?.id!}
          agentDetail={userDetail}
        />
      )}
    </>
  );
};

export default OutOfOfficeBanner;
