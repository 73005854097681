import { values } from 'lodash';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
  AdministrativeAreaResponseCountryEnum,
  BoardRequest,
  BoardRequestStatusEnum,
  BoardResponse,
  BoardResponseStatusEnum,
} from '../../../openapi/yenta';
import { createBoard, updateBoard } from '../../../slices/BoardSlice';
import { AppDispatch, RootState } from '../../../types';
import { defaultMoneyValue } from '../../../utils/CurrencyUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import {
  EMAIL_VALIDATIONS,
  MONEY_VALUE_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../utils/Validations';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledEmailInput from '../../Zen/Input/ZenControlledEmailInput';
import ZenControlledHTMLSelectInput from '../../Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledMoneyInput from '../../Zen/Input/ZenControlledMoneyInput';
import ZenControlledPhoneNumberInput from '../../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledStateOrProvinceInput from '../../Zen/Input/ZenControlledStateOrProvince';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../../Zen/ZenSidebarModalForm';

interface ZenCreateBoardFormProps {
  isOpen: boolean;
  boardDetails?: BoardResponse;
  onClose(): void;
}

const ZenCreateBoardForm: React.FC<ZenCreateBoardFormProps> = ({
  isOpen,
  onClose,
  boardDetails,
}) => {
  const history = useHistory();
  const { userDetail } = useSelector((state: RootState) => state.auth);

  let defaultAdministrativeArea = {
    country: (boardDetails?.administrativeArea?.country
      ? boardDetails?.administrativeArea?.country
      : '') as AdministrativeAreaRequestCountryEnum,
    stateOrProvince: (boardDetails?.administrativeArea?.stateOrProvince
      ? boardDetails?.administrativeArea?.stateOrProvince
      : '') as AdministrativeAreaRequestStateOrProvinceEnum,
  };

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<BoardRequest>({
    defaultValues: {
      code: boardDetails?.code,
      joinDate: boardDetails?.joinDate,
      name: boardDetails?.name,
      email: boardDetails?.email,
      phoneNumber: boardDetails?.phoneNumber,
      registrationFee:
        boardDetails?.registrationFee ||
        defaultMoneyValue(userDetail?.defaultCurrency!),
      administrativeArea: defaultAdministrativeArea,
      status: (boardDetails?.status || '') as BoardRequestStatusEnum,
    },
  });
  const dispatch: AppDispatch = useDispatch();
  const selectedCountry = watch('administrativeArea.country');

  const onSubmit = async (data: BoardRequest) => {
    if (boardDetails) {
      await dispatch(updateBoard(boardDetails.id!, data));
      onClose();
    } else {
      const boardId = await dispatch(createBoard(data));
      if (boardId) {
        history.push(`/boards/${boardId}`);
      }
    }
  };

  return (
    <ZenSidebarModalForm
      title={boardDetails ? 'Edit Board Details' : 'Add New Board'}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionTitle={boardDetails ? 'Update' : 'Create'}
      isSubmitting={isSubmitting}
    >
      <div>
        <div>
          <ZenControlledTextInput<BoardRequest, 'name'>
            control={control}
            shouldUnregister={!boardDetails}
            label='Board Name'
            name='name'
            placeholder='Enter Board Name'
            rules={{ required: 'Please provide a board name' }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledTextInput<BoardRequest, 'code'>
            control={control}
            shouldUnregister={!boardDetails}
            label='Board Code'
            name='code'
            placeholder='Enter Board Code'
            rules={{
              required: 'Please provide a board code',
            }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledMoneyInput<BoardRequest, 'registrationFee'>
            control={control}
            shouldUnregister={!boardDetails}
            label='Registration Fee'
            name='registrationFee'
            placeholder='Enter registration fee'
            rules={MONEY_VALUE_VALIDATIONS}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledDatePickerInput<BoardRequest, 'joinDate'>
            name='joinDate'
            control={control}
            shouldUnregister={!boardDetails}
            label='Join Date'
            placeholder='Join Date'
            rules={{
              required: 'Please provide a join date',
            }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledPhoneNumberInput<BoardRequest, 'phoneNumber'>
            control={control}
            shouldUnregister={!boardDetails}
            label='Phone Number'
            name='phoneNumber'
            placeholder='+1 (702) 123-4567'
            rules={{
              required: 'Please provide a phone number',
              ...PHONE_NUMBER_VALIDATIONS,
            }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledEmailInput<BoardRequest, 'email'>
            control={control}
            shouldUnregister={!boardDetails}
            label='Email Address'
            name='email'
            placeholder='Email Address'
            rules={{
              required: 'Please enter an email address',
              ...EMAIL_VALIDATIONS,
            }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledHTMLSelectInput<
            BoardRequest,
            'administrativeArea.country'
          >
            name='administrativeArea.country'
            control={control}
            shouldUnregister={!boardDetails}
            label='Country'
            placeholder='Country'
            options={[
              {
                label: 'Select Country',
                value: '',
                disabled: true,
              },
              ...values(AdministrativeAreaResponseCountryEnum).map(
                (country) => ({
                  value: country,
                  label: capitalizeEnum(country),
                }),
              ),
            ]}
            rules={{
              required: 'Please select country',
            }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledStateOrProvinceInput<
            BoardRequest,
            'administrativeArea.stateOrProvince'
          >
            selectedCountry={
              (selectedCountry as unknown) as AdministrativeAreaRequestCountryEnum
            }
            name='administrativeArea.stateOrProvince'
            control={control}
            shouldUnregister={!boardDetails}
            rules={{
              required: 'Please select state or province.',
            }}
            setValue={setValue}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledHTMLSelectInput<BoardRequest, 'status'>
            name='status'
            control={control}
            shouldUnregister={!boardDetails}
            label='Status'
            placeholder='Status'
            options={[
              { label: 'Select Status', value: '', disabled: true },
              ...values(BoardResponseStatusEnum).map((state) => ({
                value: state,
                label: capitalizeEnum(state),
              })),
            ]}
            rules={{
              required: 'Please select board status',
            }}
            isRequired
          />
        </div>
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenCreateBoardForm;
