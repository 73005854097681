import { useDispatch } from 'react-redux';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../../routes/AgentWebsiteOnboardingRoute';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import { displayStringOrNA } from '../../../utils/StringUtils';
import ReviewRow from '../../ReviewRow';
import StepContentInput from '../StepContentInput';
import EditOutlineActionButton from '../EditOutlineActionButton';
import { setDefaultAccordionOpenType } from '../../../slices/AgentWebsiteOnboardingSlice';
import { AgentWebsiteOnboardingSiteInfoAccordionName } from '../../../types';

const AgentWebsiteOnboardingMainContentReview: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ form: { watch }, navigateTo }) => {
  const dispatch = useDispatch();

  const [
    name,
    websiteTitle,
    mainTitle,
    mainSubtitle,
    bottomTitle,
    bottomSubtitle,
  ] = watch([
    'name',
    'websiteTitle',
    'mainTitle',
    'mainSubtitle',
    'bottomTitle',
    'bottomSubtitle',
  ]);

  return (
    <div className='p-4'>
      <div className='grid lg:grid-cols-3 grid-cols-1 gap-5'>
        <div className='flex flex-col space-y-5'>
          <StepContentInput step={1}>
            <ReviewRow label='Your display name:' value={name!} />
          </StepContentInput>
          <StepContentInput step={2}>
            <ReviewRow
              label='Your title/certification:'
              value={displayStringOrNA(websiteTitle)}
            />
          </StepContentInput>
        </div>
        <div className='flex flex-col space-y-5'>
          <StepContentInput step={3}>
            <ReviewRow
              label='Homepage Title:'
              value={displayStringOrNA(mainTitle)}
            />
          </StepContentInput>
          <StepContentInput step={4}>
            <ReviewRow
              label='Subtitle:'
              value={displayStringOrNA(mainSubtitle)}
            />
          </StepContentInput>
        </div>
        <div className='flex flex-col space-y-5'>
          <StepContentInput step={5}>
            <ReviewRow
              label='Message Title:'
              value={displayStringOrNA(bottomTitle)}
            />
          </StepContentInput>
          <StepContentInput step={6}>
            <ReviewRow
              label='Message Content:'
              value={displayStringOrNA(bottomSubtitle)}
            />
          </StepContentInput>
        </div>
      </div>
      <div className='mt-5 mb-2'>
        <EditOutlineActionButton
          text='Edit main content'
          icon={<FontAwesomeIcon icon={faPen} className='mt-1 mr-1' />}
          onClick={() => {
            dispatch(
              setDefaultAccordionOpenType(
                AgentWebsiteOnboardingSiteInfoAccordionName.MAIN_CONTENT,
              ),
            );
            navigateTo(AgentWebsiteOnboardingStepName.SITE_INFO);
          }}
        />
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingMainContentReview;
