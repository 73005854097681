import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../../routes/AgentWebsiteOnboardingRoute';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ControlledTextAreaInputV7 from '../../ControlledTextAreaInputV7';
import ControlledTextInputV7 from '../../ControlledTextInputV7';
import StepContentInput from '../StepContentInput';
import AgentWebsiteFeaturedContent from '../../../assets/img/agent-website-featured-content.png';
import { TEXT_CONTENT_VALIDATIONS } from '../../../utils/Validations';

const AgentWebsiteOnboardingFeaturedContent: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ form: { control } }) => {
  return (
    <div className='p-4'>
      <img
        src={AgentWebsiteFeaturedContent}
        className='my-5 mx-auto'
        alt='website'
      />
      <div className='grid lg:grid-cols-3 grid-cols-1 gap-10'>
        <div className='flex flex-col space-y-10'>
          <StepContentInput step={7}>
            <ControlledTextInputV7<
              AgentWebsiteOnboardingFormData,
              'feature1Title'
            >
              name='feature1Title'
              control={control}
              shouldUnregister={false}
              label='Feature 1 title'
              subLabel='(255 characters max)'
              rules={{ ...TEXT_CONTENT_VALIDATIONS }}
            />
          </StepContentInput>
          <StepContentInput step={8}>
            <ControlledTextAreaInputV7<
              AgentWebsiteOnboardingFormData,
              'feature1Content'
            >
              name='feature1Content'
              control={control}
              rows={3}
              shouldUnregister={false}
              subLabel='(255 characters max)'
              label='Feature 1 content'
              rules={{ ...TEXT_CONTENT_VALIDATIONS }}
            />
          </StepContentInput>
        </div>
        <div className='flex flex-col space-y-10'>
          <StepContentInput step={9}>
            <ControlledTextInputV7<
              AgentWebsiteOnboardingFormData,
              'feature2Title'
            >
              name='feature2Title'
              control={control}
              shouldUnregister={false}
              label='Feature 2 title'
              subLabel='(255 characters max)'
              rules={{ ...TEXT_CONTENT_VALIDATIONS }}
            />
          </StepContentInput>
          <StepContentInput step={10}>
            <ControlledTextAreaInputV7<
              AgentWebsiteOnboardingFormData,
              'feature2Content'
            >
              name='feature2Content'
              control={control}
              rows={3}
              shouldUnregister={false}
              subLabel='(255 characters max)'
              label='Feature 2 content'
              rules={{ ...TEXT_CONTENT_VALIDATIONS }}
            />
          </StepContentInput>
        </div>
        <div className='flex flex-col space-y-10'>
          <StepContentInput step={11}>
            <ControlledTextInputV7<
              AgentWebsiteOnboardingFormData,
              'feature3Title'
            >
              name='feature3Title'
              control={control}
              shouldUnregister={false}
              label='Feature 3 title'
              subLabel='(255 characters max)'
              rules={{ ...TEXT_CONTENT_VALIDATIONS }}
            />
          </StepContentInput>
          <StepContentInput step={12}>
            <ControlledTextAreaInputV7<
              AgentWebsiteOnboardingFormData,
              'feature3Content'
            >
              name='feature3Content'
              control={control}
              rows={3}
              shouldUnregister={false}
              subLabel='(255 characters max)'
              label='Feature 3 content'
              rules={{ ...TEXT_CONTENT_VALIDATIONS }}
            />
          </StepContentInput>
        </div>
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingFeaturedContent;
