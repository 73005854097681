import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { CreateNoteRequestEntityTypeEnum } from '../../../openapi/yenta';
import { FeatureFlagTypeEnum, RootState } from '../../../types';
import { isCanadaTransaction } from '../../../utils/TransactionHelper';
import { getPageTitleForTransaction } from '../../../utils/TransactionUtils';
import FeatureFlagEnabledOnly from '../../FeatureFlagEnabledOnly';
import AdminOnly from '../../auth/AdminOnly';
import ZenCard from '../ZenCard';
import ZenRoute from '../ZenRoute';
import AdminOrBrokerOnly from '../../auth/AdminOrBrokerOnly';
import ZenAttachedFees from './ZenAttachedFee';
import ZenCdPayerInformationCard from './ZenCdPayerInformationCard';
// import ZenCommissionSplitCard from './ZenCommissionSplitCard';
import ZenConveyanceDocumentsCard from './ZenConveyanceDocumentsCard';
import ZenOfficeDoubleEnder from './ZenOfficeDoubleEnder';
import ZenTransactionInformation from './ZenTransactionInformation';
import ZenTransactionNotesContainer from './ZenTransactionNotesContainer';
import ZenTransactionOtherParticipants from './ZenTransactionOtherParticipants';
import ZenTransactionParticipantsSection from './ZenTransactionParticipantsSection';
import ZenTaxInformationCard from './ZenTaxInformationCard';
import ZenInternalReferralTransaction from './ZenInternalReferralTransaction';

interface ZenTransactionDetailProps {}

const ZenTransactionDetail: React.FC<ZenTransactionDetailProps> = () => {
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
    },
  } = useSelector((state: RootState) => state);

  if (!transactionDetail) {
    return null;
  }

  const isCanada = isCanadaTransaction(transactionDetail);

  const isDoubleEnderTx = !isEmpty(transactionDetail.doubleEnderInfo);

  return (
    <ZenRoute
      title={`${getPageTitleForTransaction(transactionDetail)} Details - ${
        transactionDetail?.address?.oneLine
      }`}
    >
      <div className='p-4'>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-6 grid-flow-row py-4'>
          <div>
            <ZenTransactionInformation transaction={transactionDetail} />
            <div className='mt-6'>
              {transactionDetail?.taxInformation && (
                <ZenTaxInformationCard transaction={transactionDetail} />
              )}
            </div>
          </div>

          <div>
            {/* <div className='mb-6'>
              <ZenCommissionSplitCard transaction={transactionDetail} />
            </div> */}
            <ZenCdPayerInformationCard transaction={transactionDetail} />
            {isDoubleEnderTx && (
              <AdminOnly>
                <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.DOUBLE_ENDER}>
                  <div className='mt-6'>
                    <ZenOfficeDoubleEnder transaction={transactionDetail} />
                  </div>
                </FeatureFlagEnabledOnly>
              </AdminOnly>
            )}
            <FeatureFlagEnabledOnly
              flag={FeatureFlagTypeEnum.INTERNAL_REFERRAL}
            >
              <AdminOnly>
                <div className='mt-6'>
                  <ZenInternalReferralTransaction
                    transaction={transactionDetail}
                  />
                </div>
              </AdminOnly>
            </FeatureFlagEnabledOnly>
            {isCanada && (
              <div className='mt-6'>
                <ZenConveyanceDocumentsCard transaction={transactionDetail} />
              </div>
            )}
          </div>
        </div>

        <ZenTransactionParticipantsSection
          transactionDetail={transactionDetail!}
        />
        {/* TODO: Enable once we can mark some taxes as exempt */}
        {/*{isCanadaTransaction(transaction) && (*/}
        {/*  <TransactionTaxesSection />*/}
        {/*)}*/}
        <ZenAttachedFees
          attachedFees={transactionDetail.attachedFees!}
          transaction={transactionDetail}
          participants={transactionDetail.participants!}
        />
        <ZenTransactionOtherParticipants transaction={transactionDetail} />
        <AdminOrBrokerOnly>
          <div className='py-4'>
            <ZenCard title='Internal Notes'>
              <ZenTransactionNotesContainer
                entityId={transactionDetail.id!}
                entityType={CreateNoteRequestEntityTypeEnum.Transaction}
              />
            </ZenCard>
          </div>
        </AdminOrBrokerOnly>
      </div>
    </ZenRoute>
  );
};

export default ZenTransactionDetail;
