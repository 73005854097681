import React from 'react';
import ZenPopover from '../../../ZenPopover';

interface TeamCapPopoverProps {}

const TeamCapPopover: React.FC<TeamCapPopoverProps> = () => {
  return (
    <div className='font-inter text-sm text-zen-dark-9'>
      Team Cap <span className='text-error'>*</span>
      <ZenPopover
        title='Team Cap'
        description={
          <div className='text-zen-dark-9 text-base font-inter font-light'>
            <p>
              If the Teammate is configured to pay a percentage Team
              Leader&apos;s split from each transaction, the administrator can
              optionally set a maximum Team Cap limit for the anniversary cycle.
              After Team Cap is reached, the Leader&apos;s Split will no longer
              be charged until the end of the anniversary year.
            </p>
          </div>
        }
      />
    </div>
  );
};

export default TeamCapPopover;
