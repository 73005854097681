interface ZenPrimaryGradientTextProps {
  children: React.ReactNode;
}

export const ZenPrimaryGradientText = ({
  children,
}: ZenPrimaryGradientTextProps) => {
  return (
    <span className='bg-gradient-to-r from-primary-blue to-primary bg-clip-text text-transparent font-zen-primary-regular'>
      {children}
    </span>
  );
};
