import {
  faArrowUpToLine,
  faCircleXmark,
  faTrash as faTrashRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCheck,
  faCircleExclamation,
  faCircleInfo,
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form-v7';
import { EnumMap } from '../types';
import { cn } from '../utils/classUtils';
import Button from './Button';
import { ModalVariantType } from './ConfirmationModal';
import ZenControlledTextInput from './Zen/Input/ZenControlledTextInput';

interface DoubleConfirmationModalProps {
  variant: ModalVariantType;
  title: string;
  isOpen: boolean;
  subtitle?: string;
  onClose(): void;
  isSubmitting: boolean;
  onConfirm(): void;
}

const CONFIRMATION_STRING_REQUIRED = 'CONFIRM';

const DoubleConfirmationModal: React.FC<DoubleConfirmationModalProps> = ({
  variant,
  title,
  isOpen,
  subtitle,
  onClose,
  isSubmitting,
  onConfirm,
}) => {
  const { control, watch } = useForm({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const classMapStyle: EnumMap<ModalVariantType, string> = {
    error: 'bg-error text-error',
    info: 'bg-primary text-primary',
    success: 'bg-success text-success',
    warning: 'bg-warning text-warning',
    danger: 'bg-danger text-danger',
    upload: 'bg-primary text-primary',
    cancel: 'bg-error text-error',
    close: 'bg-error text-error',
  };

  const IconComponent: EnumMap<ModalVariantType, React.ReactElement> = {
    error: <FontAwesomeIcon icon={faCircleExclamation} />,
    info: <FontAwesomeIcon icon={faCircleInfo} />,
    success: <FontAwesomeIcon icon={faCheck} />,
    warning: <FontAwesomeIcon icon={faTriangleExclamation} />,
    danger: <FontAwesomeIcon icon={faTrash} />,
    upload: <FontAwesomeIcon icon={faArrowUpToLine} />,
    cancel: <FontAwesomeIcon icon={faTrashRegular} />,
    close: <FontAwesomeIcon icon={faCircleXmark} />,
  };

  if (!isOpen) {
    return null;
  }

  const watchConfirm = watch('confirm');
  const isDisabled = watchConfirm?.trim() !== CONFIRMATION_STRING_REQUIRED;

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
        <div className='z-10 w-full p-4 bg-white rounded-lg md:w-1/2 lg:w-1/4'>
          <div className='flex items-start'>
            <div className='mr-3'>
              <div
                className={cn(
                  'flex justify-center items-center w-10 h-10 bg-primary bg-opacity-20 rounded-full p-1',
                  classMapStyle[variant],
                )}
              >
                {IconComponent[variant]}
              </div>
            </div>
            <div>
              <p className='text-lg font-primary-regular text-dark'>{title}</p>
              {subtitle && (
                <p className='text-base text-gray-500 font-primary-regular'>
                  {subtitle}
                </p>
              )}
              <div className='mt-3'>
                <ZenControlledTextInput
                  control={control}
                  name='confirm'
                  type='text'
                  placeholder={`Please type ${CONFIRMATION_STRING_REQUIRED} to continue.`}
                />
              </div>
              <div className='flex flex-row mt-3 space-x-3'>
                <Button
                  fullWidth
                  label='Cancel'
                  type='secondary'
                  onClick={onClose}
                />
                <Button
                  fullWidth
                  label={isSubmitting ? 'Submitting...' : 'Confirm'}
                  disabled={isDisabled}
                  type={isDisabled ? 'secondary' : 'primary'}
                  onClick={onConfirm}
                  isSubmitting={isSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoubleConfirmationModal;
