import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LeoQuestionManagerApi, PairBase } from '../../openapi/leo';
import { getLeoConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import ErrorService from '../../services/ErrorService';
import { showErrorToastForErrorCode } from '../../slices/ToastNotificationSlice';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';

interface LQMDeleteModalProps {
  isOpen: boolean;
  onClose(): void;
  questionDetails?: PairBase;
  refresh(): void;
}

const LQMDeleteModal: React.FC<LQMDeleteModalProps> = ({
  isOpen,
  onClose,
  questionDetails,
  refresh,
}) => {
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleDeleteQuestion = async () => {
    setIsDeleting(true);
    try {
      await new LeoQuestionManagerApi(
        getLeoConfiguration(),
      ).deleteQaPairLqmQapairPairIdDeletePatch(
        (questionDetails?.pair_id! as unknown) as string,
      );
      refresh();
      onClose();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to delete the question', e, {
        questionnaire: { id: questionDetails?.pair_id!.toString() },
      });
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to delete the question. Please try again in some time.',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <ZenConfirmationModal
      isOpen={isOpen}
      variant='danger'
      title='Are you sure want to delete this question?'
      confirmButtonText='Delete'
      hideIcon
      subtitle='Once deleted, question will be removed from Leo question manager and cannot be retrieved.'
      onClose={onClose}
      onConfirm={handleDeleteQuestion}
      isSubmitting={isDeleting}
      isDisabled={isDeleting}
      cancelButtonLeftIcon={
        <FontAwesomeIcon icon={regular('x')} size='sm' className='mr-1' />
      }
      confirmButtonLeftIcon={
        <FontAwesomeIcon icon={regular('trash')} size='sm' className='mr-1' />
      }
    />
  );
};

export default LQMDeleteModal;
