import { flatten } from 'lodash';
import qs from 'qs';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import {
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  MoneyValue,
  TransactionBuilderControllerApi,
  TransactionBuilderResponse,
  TransactionControllerApi,
  TransactionLiteResponse,
  TransactionResponseCountryEnum,
} from '../../../openapi/arrakis';
import { fetchChecklistProgressById } from '../../../slices/CheckListSlice';
import {
  deleteDraft,
  saveTransactionPaginatedResultsByIdWithFilters,
} from '../../../slices/TransactionSlice';
import { fetchAgentsInfo } from '../../../slices/UserIdsSlice';
import { AppDispatch, EnumMap, RootState } from '../../../types';
import { isCanadianUser } from '../../../utils/AgentHelper';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { getTransactionSystemActualClosingDate } from '../../../utils/TransactionHelper';
import { getPaginatedResults } from '../../../utils/TransactionUtils';
import ZenBooleanButton from '../../Agent/V2/ZenBooleanButton';
import Button from '../../Button';
import ConfirmationModal from '../../ConfirmationModal';
import Hover from '../../Hover';
import NullableTextCell from '../../table/Cells/NullableTextCell';
import ZenViewActionButtonCell from '../../table/Cells/ZenViewActionButtonCell';
import TransactionCompliantToggleColumnFilter from '../../table/Filters/TransactionCompliantToggleColumnFilter';
import ZenResourceIndexContainer from '../Containers/ZenResourceIndexContainer';
import ZenRouterTabs, { ZenTab } from '../Tab/ZenRouterTabs';
import ZenAddressCell from '../Table/Cell/ZenAddressCell';
import ZenDateCell from '../Table/Cell/ZenDateCell';
import ZenTransactionTypeCell from '../Table/Cell/ZenTransactionTypeCell';
import ZenRoute from '../ZenRoute';
import ZenAgentsInfoPopper from './ZenAgentsInfoPopper';
import ZenChecklistPopper from './ZenChecklistPopper';
import ZenJourneyPopper from './ZenJourneyPopper';
import ZenTransactionCodeCell from './ZenTransactionCodeCell';
import ZenTransactionStatus from './ZenTransactionStatus';

interface TransactionBuilderResponseModified
  extends TransactionBuilderResponse {}

export const columns: Array<Column<TransactionLiteResponse>> = [
  {
    Header: '',
    accessor: 'id',
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, string>) => (
      <div className='ml-0 md:ml-5'>
        {original?.showOnboardingScreen && (
          <div>
            <div
              className='absolute -top-[2px] left-0'
              style={{
                width: 0,
                height: 0,
                borderBottom: '50px solid transparent',
                borderLeft: '50px solid #48B084',
              }}
            />
            <span className='absolute top-[4px] left-[4px] text-white text-[10px]'>
              NEW
            </span>
          </div>
        )}
        <Link
          to={`/transactions/${original.id}?${qs.stringify({
            showStatusOnboardings: true,
          })}`}
        >
          <ZenViewActionButtonCell />
        </Link>
      </div>
    ),
    disableSortBy: true,
  },
  {
    Header: 'Transaction Code',
    accessor: 'code',
    id: 'code',
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, string>) => (
      <ZenTransactionCodeCell
        linkTo={`/transactions/${original.id}?${qs.stringify({
          showStatusOnboardings: true,
        })}`}
        transaction={original}
      />
    ),
    cardColSize: 7,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'transactionType',
    id: 'transactionType',
    Cell: ({ value }) => <ZenTransactionTypeCell dealType={value!} />,
    cardColSize: 5,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Address',
    accessor: (d) => d['address']?.street,
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, string>) => (
      <Link
        to={`/transactions/${original.id}?${qs.stringify({
          showStatusOnboardings: true,
        })}`}
      >
        <ZenAddressCell address={original.address} />
      </Link>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Price',
    accessor: 'price',
    id: 'price',
    Cell: ({ row: { original } }: CellProps<TransactionLiteResponse>) => (
      <div>
        {displayAmount(original.price!, { hideCurrency: true })}
        <div className='text-sm	text-zen-gray-4'>{original.price?.currency}</div>
      </div>
    ),
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Status',
    accessor: ({ lifecycleState }) => lifecycleState?.state,
    id: 'lifecycleState',
    Cell: ({
      value,
      row: { original },
    }: React.PropsWithChildren<CellProps<TransactionLiteResponse>>) => (
      <ZenTransactionStatus
        status={value}
        country={
          (original.country as unknown) as TransactionResponseCountryEnum
        }
      />
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: (
      <span className='text-left w-[90px] whitespace-normal'>
        Estimated Closing Date
      </span>
    ),
    accessor: 'skySlopeEscrowClosingDate',
    Cell: ({ value }) => <ZenDateCell date={value} />,
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Checklist',
    accessor: 'checklistId',
    Cell: ({ row: { original } }) => (
      <ZenChecklistPopper
        transactionId={original.id}
        checklistId={original.checklistId}
      />
    ),
    cardColSize: 4,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Road To Success',
    accessor: 'journeyId',
    Cell: ({ row: { original } }) => (
      <ZenJourneyPopper
        transactionId={original.id}
        journeyId={original.journeyId}
      />
    ),
    cardColSize: 4,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Agents',
    accessor: 'participantYentaIds',
    Cell: ({ value, row: { original } }) => (
      <ZenAgentsInfoPopper
        participants={value || []}
        represents={original.transactionOwner?.represents}
      />
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Gross Commission',
    accessor: (d) => d['grossCommission']?.amount,
    Cell: ({ row: { original } }: CellProps<TransactionLiteResponse>) => (
      <div>
        {displayAmount(original.grossCommission!, { hideCurrency: true })}
        <div className='text-sm	text-zen-gray-4'>
          {original.grossCommission?.currency}
        </div>
      </div>
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Net Payout',
    accessor: (d) => d['myNetPayout']?.amount,
    Cell: ({ row: { original } }: CellProps<TransactionLiteResponse>) => (
      <div>
        {displayAmount(original.myNetPayout!, { hideCurrency: true })}
        <div className='text-sm	text-zen-gray-4'>
          {original.myNetPayout?.currency}
        </div>
      </div>
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: (
      <span className='text-left w-[90px] whitespace-normal'>
        System Closing Date
      </span>
    ),
    accessor: 'rezenClosedAt',
    Cell: ({ row: { original } }: CellProps<TransactionLiteResponse>) => (
      <NullableTextCell
        text={getTransactionSystemActualClosingDate(original)}
      />
    ),
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Marked Compliant',
    accessor: 'compliantAt',
    Cell: ({ value }) => (
      <div className='mt-2 md:mt-0'>
        <ZenBooleanButton value={!!value} />
      </div>
    ),
    Filter: TransactionCompliantToggleColumnFilter,
    disableSortBy: true,
  },
];

export const draftColumns: Array<Column<TransactionBuilderResponseModified>> = [
  {
    Header: 'Type',
    accessor: 'dealType',
    Cell: ({ value }) =>
      value ? <ZenTransactionTypeCell dealType={value} /> : '--',
    cardColSize: 5,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Address',
    accessor: (d) => d['address']?.street,
    Cell: ({
      row: { original },
    }: CellProps<TransactionBuilderResponse, string>) => (
      <Link to={`/transaction/create/${original.id}`}>
        <div className='w-56'>
          <ZenAddressCell
            address={original.address}
            placeholder={<p className='text-gray-500'>No Address</p>}
          />
        </div>
      </Link>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Price',
    accessor: 'salePrice',
    id: 'price',
    Cell: ({ value }) => (
      <div>
        {value ? (
          <div>
            {displayAmount(value as MoneyValue, {
              hideCurrency: true,
            })}
            <div className='text-sm	text-zen-gray-4'>{value.currency}</div>
          </div>
        ) : (
          <p>--</p>
        )}
      </div>
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Closing Date estimated',
    accessor: 'estimatedClosingDate',
    Cell: ({ value }) => <ZenDateCell date={value!} placeholder='--' />,
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Checklist Complete',
    accessor: 'checklistId',
    Cell: ({ row: { original }, value }) => (
      <ZenChecklistPopper checklistId={value} transactionId={original.id} />
    ),
    cardColSize: 4,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Journey Progress',
    accessor: 'journeyId',
    Cell: ({ row: { original }, value }) => (
      <ZenJourneyPopper journeyId={value} transactionId={original.id} />
    ),
    cardColSize: 4,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Agents',
    accessor: 'allCommissionRecipient',
    Cell: ({ value, row: { original } }) => (
      <ZenAgentsInfoPopper
        participants={
          // @ts-ignore
          value?.filter((v) => !!v.agentId).map((v) => v.agentId) || []
        }
        represents={original.agentsInfo?.representationType}
      />
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Gross Commission',
    accessor: 'grossCommission',
    Cell: ({ value }) => (
      <div>
        {value ? (
          <div>
            {displayAmount(value.commissionAmount as MoneyValue, {
              hideCurrency: true,
            })}
            <div className='text-sm	text-zen-gray-4'>
              {value.commissionAmount?.currency}
            </div>
          </div>
        ) : (
          <p>--</p>
        )}
      </div>
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'My Net Payout',
    Cell: () => '--',
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Acceptance Date',
    accessor: 'acceptanceDate',
    Cell: ({ value }) => <ZenDateCell date={value!} placeholder='--' />,
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
];

interface ZenMyTransactionsProps {
  agentId: string;
  search?: string;
}

const TransactionSortByTypeEnum: EnumMap<
  string,
  'ESCROW_CLOSING_DATE' | 'ACTUAL_CLOSING_DATE' | 'PRICE'
> = {
  skySlopeEscrowClosingDate: 'ESCROW_CLOSING_DATE',
  rezenClosedAt: 'ACTUAL_CLOSING_DATE',
  price: 'PRICE',
};

const TransactionSortDirectionTypeEnum: EnumMap<string, 'ASC' | 'DESC'> = {
  asc: 'ASC',
  desc: 'DESC',
};

const ZenMyTransactions: React.FC<ZenMyTransactionsProps> = ({
  agentId,
  search,
}) => {
  const [transactionBuilderId, setTransactionBuilderId] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    userIds: { agentById },
    auth: { userDetail },
    transaction: { transactionsCountByLifecycleGroup },
  } = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();

  const modifiedColumns: Array<
    Column<TransactionLiteResponse>
  > = useMemo(() => {
    let updatedColumns: Array<Column<TransactionLiteResponse>> = [...columns];

    if (isCanadianUser(userDetail?.accountCountry!)) {
      updatedColumns.splice(7, 0, {
        Header: 'Firm Date',
        accessor: 'firmDate',
        Cell: ({ value }) => <ZenDateCell date={value} />,
        cardColSize: 6,
        disableFilters: true,
        disableSortBy: true,
      });
    }

    return updatedColumns;
  }, [userDetail?.accountCountry]);

  const draftColumnsWithActions: Array<
    Column<TransactionBuilderResponseModified>
  > = useMemo(() => {
    const dynamicDraftColumns: Array<
      Column<TransactionBuilderResponseModified>
    > = [
      {
        Header: 'Transaction Code',
        accessor: 'id',
        Cell: ({
          row: { original },
        }: CellProps<TransactionBuilderResponse, string>) => {
          return (
            <div>
              {original.id ? (
                <div className='flex items-center space-x-1'>
                  <Link to={`/transaction/create/${original.id}`}>
                    <div className='text-sm flex flex-col'>
                      <p className='whitespace-nowrap'>XXX-XXX-XXX-XXXX</p>
                      <p className='text-error'>[Draft]</p>
                    </div>
                  </Link>
                  <Hover
                    hoverComponent={
                      <div className='text-dark'>Discard Draft</div>
                    }
                    config={{ trigger: 'hover', placement: 'top' }}
                  >
                    <div
                      className='cursor-pointer'
                      onClick={() => setTransactionBuilderId(original.id)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        className='text-error hover:bg-red-100 rounded-full p-0.5'
                      />
                    </div>
                  </Hover>
                </div>
              ) : (
                'N/A'
              )}
            </div>
          );
        },
        cardColSize: 7,
        disableSortBy: true,
      },
    ];

    return [...dynamicDraftColumns, ...draftColumns];
  }, []);

  const handleDeleteDraftTransaction = useCallback(
    async (id: string) => {
      setIsSubmitting(true);
      await dispatch(deleteDraft(id, agentId, 'TRANSACTION'));
      setTransactionBuilderId(undefined);
      setIsSubmitting(false);
    },
    [agentId, dispatch],
  );

  const getChecklistProgress = useCallback(
    async (checklistIds: string[]) => {
      if (!!checklistIds?.length) {
        await dispatch(fetchChecklistProgressById(checklistIds));
      }
    },
    [dispatch],
  );

  const getAllAgentsDetails = useCallback(
    async (userIds: string[]) => {
      const agentIdsToFetch = userIds.filter((id) => !agentById[id]);
      await dispatch(fetchAgentsInfo(agentIdsToFetch));
    },
    [agentById, dispatch],
  );

  const fetchAgentDetailsAndChecklistProgress = useCallback(
    async (userIds: string[], checklistIds: string[]) => {
      return await Promise.all([
        getAllAgentsDetails(userIds),
        getChecklistProgress(checklistIds),
      ]);
    },
    [getAllAgentsDetails, getChecklistProgress],
  );

  const tabs: ZenTab[] = useMemo(() => {
    const array = [
      {
        label: `Active (${transactionsCountByLifecycleGroup.OPEN})`,
        path: '/transactions',
        exact: true,
        TabComponent: () => (
          <ZenRoute title='Active Transactions'>
            <div className='mt-4'>
              <ZenResourceIndexContainer<TransactionLiteResponse>
                columns={modifiedColumns}
                resourceName='transaction'
                hideFilters
                search={search}
                pageSize={10}
                hiddenColumns={['rezenClosedAt']}
                initialSort={{ skySlopeEscrowClosingDate: 'asc' }}
                fetchData={async (req, cancelToken) => {
                  const sortKey = Object.keys(req.sortBy || {})[0];
                  const sortType = Object.values(req.sortBy || {})[0];
                  const { data } = await new TransactionControllerApi(
                    getArrakisConfiguration(),
                  ).getTransactionsByStateGroupPaginated(
                    agentId,
                    'OPEN',
                    req.page,
                    req.pageSize,
                    req.search,
                    TransactionSortByTypeEnum[sortKey],
                    TransactionSortDirectionTypeEnum[sortType!],
                    undefined,
                    { cancelToken },
                  );

                  dispatch(
                    saveTransactionPaginatedResultsByIdWithFilters({
                      hasNext: data.hasNext,
                      totalCount: data.totalCount,
                      results: getPaginatedResults(
                        data.pageNumber!,
                        data.pageSize!,
                        data.transactions!,
                      ),
                      filters: req as any,
                      officeIds: [],
                      agentId,
                      lifecycleGroup: 'OPEN',
                    }),
                  );

                  const agentIds =
                    flatten(
                      data.transactions?.map(
                        (builder) => builder.participantYentaIds!,
                      ),
                    ) || [];

                  const checklistIds = flatten(
                    data.transactions?.map((builder) => [
                      builder.checklistId!,
                      builder.journeyId!,
                    ]),
                  ).filter((id) => !!id);

                  await fetchAgentDetailsAndChecklistProgress(
                    agentIds,
                    checklistIds,
                  );

                  return {
                    data: data.transactions!,
                    total: data.totalCount!,
                  };
                }}
                stickyHeader
              />
            </div>
          </ZenRoute>
        ),
      },
      {
        label: `Closed (${transactionsCountByLifecycleGroup.CLOSED})`,
        path: '/transactions/closed',
        exact: true,
        TabComponent: () => (
          <ZenRoute title='Closed Transactions'>
            <div className='mt-4'>
              <ZenResourceIndexContainer<TransactionLiteResponse>
                columns={modifiedColumns}
                resourceName='transaction'
                search={search}
                initialSort={{ rezenClosedAt: 'desc' }}
                hideFilters
                pageSize={10}
                fetchData={async (req, cancelToken) => {
                  const sortKey = Object.keys(req.sortBy || {})[0];
                  const sortType = Object.values(req.sortBy || {})[0];
                  const { data } = await new TransactionControllerApi(
                    getArrakisConfiguration(),
                  ).getTransactionsByStateGroupPaginated(
                    agentId,
                    'CLOSED',
                    req.page,
                    req.pageSize,
                    req.search,
                    TransactionSortByTypeEnum[sortKey],
                    TransactionSortDirectionTypeEnum[sortType!],
                    undefined,
                    { cancelToken },
                  );

                  dispatch(
                    saveTransactionPaginatedResultsByIdWithFilters({
                      hasNext: data.hasNext,
                      totalCount: data.totalCount,
                      results: getPaginatedResults(
                        data.pageNumber!,
                        data.pageSize!,
                        data.transactions!,
                      ),
                      filters: req as any,
                      officeIds: [],
                      agentId,
                      lifecycleGroup: 'CLOSED',
                    }),
                  );

                  const agentIds =
                    flatten(
                      data.transactions?.map(
                        (builder) => builder.participantYentaIds!,
                      ),
                    ) || [];

                  const checklistIds = flatten(
                    data.transactions?.map((builder) => [
                      builder.checklistId!,
                      builder.journeyId!,
                    ]),
                  ).filter((id) => !!id);

                  await fetchAgentDetailsAndChecklistProgress(
                    agentIds,
                    checklistIds,
                  );

                  return {
                    data: data.transactions!,
                    total: data.totalCount!,
                  };
                }}
                stickyHeader
              />
            </div>
          </ZenRoute>
        ),
      },
      {
        label: `Terminated (${transactionsCountByLifecycleGroup.TERMINATED})`,
        path: '/transactions/terminated',
        exact: true,
        TabComponent: () => (
          <ZenRoute title='Terminated Transactions'>
            <div className='mt-4'>
              <ZenResourceIndexContainer<TransactionLiteResponse>
                columns={modifiedColumns}
                resourceName='transaction'
                search={search}
                initialSort={{ skySlopeEscrowClosingDate: 'asc' }}
                hideFilters
                pageSize={10}
                fetchData={async (req, cancelToken) => {
                  const sortKey = Object.keys(req.sortBy || {})[0];
                  const sortType = Object.values(req.sortBy || {})[0];
                  const { data } = await new TransactionControllerApi(
                    getArrakisConfiguration(),
                  ).getTransactionsByStateGroupPaginated(
                    agentId,
                    'TERMINATED',
                    req.page,
                    req.pageSize,
                    req.search,
                    TransactionSortByTypeEnum[sortKey],
                    TransactionSortDirectionTypeEnum[sortType!],
                    undefined,
                    { cancelToken },
                  );

                  dispatch(
                    saveTransactionPaginatedResultsByIdWithFilters({
                      hasNext: data.hasNext,
                      totalCount: data.totalCount,
                      results: getPaginatedResults(
                        data.pageNumber!,
                        data.pageSize!,
                        data.transactions!,
                      ),
                      filters: req as any,
                      officeIds: [],
                      agentId,
                      lifecycleGroup: 'TERMINATED',
                    }),
                  );

                  const agentIds =
                    flatten(
                      data.transactions?.map(
                        (builder) => builder.participantYentaIds!,
                      ),
                    ) || [];

                  const checklistIds = flatten(
                    data.transactions?.map((builder) => [
                      builder.checklistId!,
                      builder.journeyId!,
                    ]),
                  ).filter((id) => !!id);

                  await fetchAgentDetailsAndChecklistProgress(
                    agentIds,
                    checklistIds,
                  );

                  return {
                    data: data.transactions!,
                    total: data.totalCount!,
                  };
                }}
                stickyHeader
              />
            </div>
          </ZenRoute>
        ),
      },
      {
        label: `Drafts (${transactionsCountByLifecycleGroup.DRAFT})`,
        path: '/transactions/draft',
        exact: true,
        TabComponent: () => (
          <ZenRoute title='Draft Transactions'>
            <div className='mt-4'>
              <ZenResourceIndexContainer<TransactionBuilderResponseModified>
                columns={draftColumnsWithActions}
                resourceName='transaction'
                hiddenColumns={['closed']}
                hideFilters
                pageSize={10}
                fetchData={async ({ page, pageSize }, cancelToken) => {
                  const { data } = await new TransactionBuilderControllerApi(
                    getArrakisConfiguration(),
                  ).getTransactionBuildersPaginated(
                    pageSize,
                    page,
                    agentId,
                    'TRANSACTION',
                    undefined,
                    {
                      cancelToken,
                    },
                  );

                  const agentIds = flatten(
                    (data?.results || []).map((builder) =>
                      builder.allCommissionRecipient
                        // @ts-ignore
                        ?.filter((participant) => !!participant.agentId)
                        // @ts-ignore
                        ?.map((participant) => participant.agentId),
                    ),
                  );

                  const checklistIds = flatten(
                    (data?.results || []).map((builder) => [
                      builder.checklistId!,
                      builder.journeyId!,
                    ]),
                  ).filter((id) => !!id);

                  await fetchAgentDetailsAndChecklistProgress(
                    agentIds,
                    checklistIds,
                  );

                  return {
                    data: data?.results || [],
                    total: transactionsCountByLifecycleGroup.DRAFT,
                  };
                }}
                stickyHeader
              />
            </div>
          </ZenRoute>
        ),
      },
    ];

    return array;
  }, [
    agentId,
    dispatch,
    draftColumnsWithActions,
    fetchAgentDetailsAndChecklistProgress,
    modifiedColumns,
    search,
    transactionsCountByLifecycleGroup.CLOSED,
    transactionsCountByLifecycleGroup.DRAFT,
    transactionsCountByLifecycleGroup.OPEN,
    transactionsCountByLifecycleGroup.TERMINATED,
  ]);

  return (
    <div className='mt-4'>
      <ZenRouterTabs tabs={tabs} />
      <ConfirmationModal
        isOpen={!!transactionBuilderId}
        variant='danger'
        title='Discard Draft Transaction?'
        subtitle='This draft transaction will be permanently deleted. Are you sure?'
        onClose={() => setTransactionBuilderId(undefined)}
        customIcon={<FontAwesomeIcon icon={faTriangleExclamation} />}
      >
        <div className='flex items-center mt-5 space-x-3'>
          <Button
            label='No'
            type='outline'
            onClick={() => setTransactionBuilderId(undefined)}
            className='!px-12 !py-1.5 !border !border-gray-300'
            fullWidth
          />
          <Button
            label='Yes'
            type='danger'
            isSubmitting={isSubmitting}
            onClick={() => handleDeleteDraftTransaction(transactionBuilderId!)}
            className='!px-12 !py-1.5 !border !border-gray-300'
            fullWidth
          />
        </div>
      </ConfirmationModal>
    </div>
  );
};

export default ZenMyTransactions;
