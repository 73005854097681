import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  AgentControllerApi,
  AgentResponse,
  UserResponseAgentStatusEnum,
} from '../../../../../openapi/yenta';
import ErrorService from '../../../../../services/ErrorService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../slices/ToastNotificationSlice';
import { getYentaConfiguration } from '../../../../../utils/OpenapiConfigurationUtils';

import { showApiErrorModal } from '../../../../../slices/ErrorSlice';
import { AsyncSelectOption } from '../../../../../types';
import { searchForAgents } from '../../../../../utils/TableUtils';
import ControlledAsyncSelectInputV7 from '../../../../ControlledAsyncSelectInputV7';
import ControlledTextInputV7 from '../../../../ControlledTextInputV7';
import DefaultLoader from '../../../../DefaultLoader';
import DoubleConfirmationModal from '../../../../DoubleConfirmationModal';
import ZenButton from '../../../ZenButton';
import ZenSidebarModal from '../../../ZenSidebarModal';

interface MarkAsCapDeferredSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  realAgent: AsyncSelectOption;
  taxDeferredMonths: string;
}

const ZenMarkAsCapDeferredSidebarModal: React.FC<MarkAsCapDeferredSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<FormData>({ mode: 'onChange' });

  const realAgentWatch = watch('realAgent');
  const noOfMonthsWatch = watch('taxDeferredMonths');
  const [realAgent, setRealAgent] = useState<AgentResponse>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function fetchAgent(id: string) {
      setLoading(true);
      try {
        const { data } = await new AgentControllerApi(
          getYentaConfiguration(),
        ).getAgentById(id);
        setRealAgent(data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        ErrorService.notify('Error fetching real agent', e);
        dispatch(
          showErrorToast(
            'We had a problem fetching the real agent',
            'Please try again in a few moments.',
          ),
        );
      }
    }

    if (realAgentWatch) {
      fetchAgent(realAgentWatch.value);
    } else {
      setRealAgent(undefined);
    }
  }, [dispatch, realAgentWatch]);
  const [
    markAsCapDeferredChange,
    setMarkAsCapDeferredChange,
  ] = useState<boolean>(false);

  const onSubmit = async (values: FormData) => {
    try {
      await new AgentControllerApi(getYentaConfiguration()).setDeferredCap(
        values.realAgent.value,
        parseInt(values.taxDeferredMonths, 10),
      );
      onClose();
      dispatch(showSuccessToast('Updated cap deferred successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify(
        'Unable to fetch yenta agent by yentaId and numberOfMonths',
        e,
        {
          agent: {
            id: values.realAgent.value,
            numberOfMonths: parseInt(values.taxDeferredMonths, 10),
          },
        },
      );
    } finally {
      setMarkAsCapDeferredChange(false);
    }
  };

  return (
    <ZenSidebarModal
      title='Mark as Cap Deferred'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form className='flex flex-col justify-between min-h-full'>
        <div className='px-4'>
          <div className='mt-5'>
            <ControlledAsyncSelectInputV7<FormData, 'realAgent'>
              control={control}
              name='realAgent'
              placeholder='Search'
              label='Which agent would you like to change?'
              fetchData={async (search, page) => {
                try {
                  const { data } = await searchForAgents({
                    page,
                    search,
                    filterBy: {
                      agentStatus: [UserResponseAgentStatusEnum.Active],
                    },
                  });

                  const options: AsyncSelectOption[] = data.map((resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.firstName} ${resp.lastName} (${resp.emailAddress})`,
                  }));

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search for agents to mark cap defered',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                      },
                    },
                  );
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an agent. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          {realAgentWatch && (
            <div className='mt-4'>
              {!loading ? (
                <div className='flex flex-row items-center font-primary-regular'>
                  {realAgent?.capDeferred ? (
                    <p>
                      The agent is already Cap Deferred with the expiry date of{' '}
                      {realAgent.deferredCapEndDate}
                    </p>
                  ) : (
                    <p className='font-primary-regular'>
                      The agent is not yet Cap Deferred.
                    </p>
                  )}
                </div>
              ) : (
                <DefaultLoader />
              )}
            </div>
          )}

          <div className='mt-5'>
            <ControlledTextInputV7<FormData, 'taxDeferredMonths'>
              type='number'
              control={control}
              label='Please enter the number of months that are tax deferred'
              name='taxDeferredMonths'
              placeholder='Enter number of months. Eg: 5'
              rules={{
                required: 'Please enter the number of months',
              }}
            />
          </div>
        </div>
        <div className='sticky bottom-0 flex flex-row p-4 space-x-5 bg-white border-t border-gray-200'>
          <ZenButton
            isFullWidth
            type='button'
            onClick={onClose}
            label='Cancel'
            variant='primary-outline'
            textSize='lg'
          />
          <ZenButton
            isFullWidth
            isDisabled={isSubmitting || !isValid}
            onClick={() => setMarkAsCapDeferredChange(true)}
            label='Update'
            textSize='lg'
          />
        </div>
        <DoubleConfirmationModal
          onConfirm={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          isOpen={markAsCapDeferredChange}
          variant='info'
          title='Confirm Cap Deferred Change'
          onClose={() => setMarkAsCapDeferredChange(false)}
          subtitle={`You are about to mark this agent as cap deferred. Their cap will be reset in ${noOfMonthsWatch} months. Is this correct?`}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenMarkAsCapDeferredSidebarModal;
