import { FeatureFlagTypeEnum } from '../types';

export default class AdminFeatureFlagService {
  static readonly featureFlagKey: string = 'featureFlagsEnabled';

  static getAllFeatureFlags(): FeatureFlagTypeEnum[] {
    let flags = null;
    try {
      flags = window.localStorage.getItem(
        AdminFeatureFlagService.featureFlagKey,
      );
    } catch {
      flags = null;
    }
    return flags ? JSON.parse(flags) : [];
  }

  static setAllFeatureFlags(flag: FeatureFlagTypeEnum[]) {
    try {
      return window.localStorage.setItem(
        AdminFeatureFlagService.featureFlagKey,
        JSON.stringify(flag),
      );
    } catch {
      return null;
    }
  }

  static clear() {
    try {
      return window.localStorage.removeItem(
        AdminFeatureFlagService.featureFlagKey,
      );
    } catch {
      return null;
    }
  }

  static isAvailable(flag: FeatureFlagTypeEnum): boolean {
    const flagsEnabled = AdminFeatureFlagService.getAllFeatureFlags();
    return flagsEnabled.includes(flag);
  }

  static enable(flag: FeatureFlagTypeEnum): void {
    const flagsEnabled = AdminFeatureFlagService.getAllFeatureFlags();
    if (!flagsEnabled.includes(flag)) {
      AdminFeatureFlagService.setAllFeatureFlags([...flagsEnabled, flag]);
    }
  }

  static disable(flag: FeatureFlagTypeEnum): void {
    const flagsEnabled = AdminFeatureFlagService.getAllFeatureFlags();
    if (flagsEnabled.includes(flag)) {
      AdminFeatureFlagService.setAllFeatureFlags(
        flagsEnabled.filter((f) => f !== flag),
      );
    }
  }
}
