import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, isEmpty, isEqual, values } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  light,
  regular,
  solid,
} from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import ZenSidebarModalActionFooterV2 from '../Zen/Modal/ZenSidebarModalActionFooterV2';
import { MoneyValue, MoneyValueCurrencyEnum } from '../../openapi/yenta';
import {
  AnalyticsEventEnum,
  AppDispatch,
  ISelectOption,
  RootState,
} from '../../types';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import { capitalizeEnum, MONEY_AMOUNT_REGEX } from '../../utils/StringUtils';
import ZenControlledMoneyInput from '../Zen/Input/ZenControlledMoneyInput';
import ZenControlledDatePickerInput from '../Zen/Input/ZenControlledDatePickerInput';
import {
  AgentDebtCreateRequestDebtTypeEnum,
  AgentDebtCreateRequestRecoveryTypeEnum,
  AgentDebtResponse,
  AgentDebtResponseDebtTypeEnum,
  AgentDebtResponseRecoveryTypeEnum,
  AgentDebtUpdateRequestDebtTypeEnum,
  AgentDebtUpdateRequestRecoveryTypeEnum,
  DebtInvoiceRequest,
  DebtRepaymentItemResponsePaymentSourceTypeEnum,
  PercentageValue,
} from '../../openapi/arrakis';
import {
  NUMBER_VALIDATIONS,
  PERCENT_VALIDATION,
} from '../../utils/Validations';
import { getISelectOptionDefaultValue } from '../../utils/FormUtils';
import {
  createAgentDebt,
  createAgentDebtInvoice,
  getPaginatedAgentDebts,
  updateAgentDebt,
} from '../../slices/AgentSlice';
import { defaultMoneyValue } from '../../utils/CurrencyUtils';
import AnalyticsService from '../../services/AnalyticsService';
import { getRecoveryTypeToDisplayName } from '../../utils/AgentHelper';
import ZenControlledPercentageInput from '../Zen/Input/ZenControlledPercentageInput';
import ZenControlledToggleInput from '../Zen/Input/ZenControlledToggleInput';
import ZenControlledCurrencyInput from '../Zen/Input/ZenControlledCurrencyInput';
import Hover from '../Hover';
import { cn } from '../../utils/classUtils';
import { getDebtTypesInDescendingOrder } from '../../utils/DebtUtils';
import { ACTIVE_TAB_STATUS, HISTORY_TAB_STATUS } from './DebtTabComponent';

interface DebtAddEditFormProps {
  isOpen: boolean;
  onClose(): void;
  agentDebt?: AgentDebtResponse;
  agentId: string;
  currentTab: string;
}

interface Collection {
  percent?: PercentageValue;
  money?: MoneyValue;
  isDollar?: boolean;
}

interface AddEditDebtFormData {
  debtType: ISelectOption<
    | AgentDebtCreateRequestDebtTypeEnum
    | AgentDebtUpdateRequestDebtTypeEnum
    | AgentDebtResponseDebtTypeEnum
  >;
  description: string;
  title: string;
  amount: MoneyValue;
  startDate?: string;
  dueDate?: string;
  collectionMethod: ISelectOption<
    | AgentDebtCreateRequestRecoveryTypeEnum
    | AgentDebtResponseRecoveryTypeEnum
    | AgentDebtUpdateRequestRecoveryTypeEnum
  >;
  maxCollection: Collection;
  daysUntilDue: string;
  autoCollectAfterDueDate?: boolean;
  invoiceDueDate?: string;
  // docFiles: File[];
}

const DebtAddEditForm: React.FC<DebtAddEditFormProps> = ({
  isOpen,
  onClose,
  agentDebt,
  agentId,
  currentTab,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    agentDetail: {
      detailResponse: { data: agentDetailResponse },
    },
  } = useSelector((state: RootState) => state);

  const invoiceRepayItem = (agentDebt?.repaymentItems || [])?.find(
    (item) =>
      item?.paymentSourceType ===
      DebtRepaymentItemResponsePaymentSourceTypeEnum.Invoice,
  );

  const {
    setValue,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, dirtyFields, errors },
  } = useForm<AddEditDebtFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: !isEmpty(agentDebt?.amount)
        ? agentDebt?.amount
        : defaultMoneyValue(agentDetailResponse?.defaultCurrency!),
      collectionMethod: getISelectOptionDefaultValue<
        | AgentDebtResponseRecoveryTypeEnum
        | AgentDebtCreateRequestRecoveryTypeEnum
        | AgentDebtUpdateRequestRecoveryTypeEnum
      >(
        agentDebt?.recoveryType,
        getRecoveryTypeToDisplayName(agentDebt?.recoveryType!),
      ),
      debtType: getISelectOptionDefaultValue<
        | AgentDebtResponseDebtTypeEnum
        | AgentDebtCreateRequestDebtTypeEnum
        | AgentDebtUpdateRequestDebtTypeEnum
      >(agentDebt?.debtType),
      description: agentDebt?.description,
      dueDate: agentDebt?.endDate,
      startDate: agentDebt?.startDate,
      title: agentDebt?.title,
      maxCollection: {
        isDollar: !!agentDebt?.maxAllowedCollectionAmount,
        money: agentDebt?.maxAllowedCollectionAmount
          ? {
              amount: agentDebt?.maxAllowedCollectionAmount,
              currency: (agentDetailResponse?.defaultCurrency as unknown) as MoneyValueCurrencyEnum,
            }
          : undefined,
        percent:
          !!agentDebt?.collectionTiers?.length &&
          agentDebt?.collectionTiers?.[0]?.collectionPercent
            ? {
                string: String(
                  agentDebt?.collectionTiers?.[0]?.collectionPercent,
                ),
                value: +agentDebt?.collectionTiers?.[0]?.collectionPercent,
              }
            : undefined,
      },
      invoiceDueDate: invoiceRepayItem?.invoiceDueDate,
      autoCollectAfterDueDate: invoiceRepayItem?.autoCollectAfterDueDate,
    },
  });

  const [
    amount,
    description,
    dueDate,
    collectionMethod,
    debtType,
    startDate,
    isMaxCollectionDollar,
    maxCollectionMoney,
    maxCollectionPercent,
  ] = watch([
    'amount',
    'description',
    'dueDate',
    'collectionMethod',
    'debtType',
    'startDate',
    'maxCollection.isDollar',
    'maxCollection.money',
    'maxCollection.percent',
  ]);

  const isMaxCollectionErrorPresent =
    !!errors?.maxCollection?.percent?.value?.message ||
    !!errors?.maxCollection?.money?.amount?.message;

  const handleDescriptionEntered = () => {
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.AGENT_OWES_REAL_DEBT_DESCRIPTION_ENTERED,
    );
  };

  const handleAmountEntered = () => {
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.AGENT_OWES_REAL_DEBT_DEDUCTION_ENTERED,
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceDescriptionEntered = useCallback(
    debounce(handleDescriptionEntered, 1000),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceAmountEntered = useCallback(
    debounce(handleAmountEntered, 1000),
    [],
  );

  useEffect(() => {
    if (dirtyFields?.debtType) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.AGENT_OWES_REAL_DEBT_TYPE_SELECTED,
        {
          debtType: debtType?.value,
        },
      );
    }
  }, [debtType?.value, dirtyFields?.debtType]);

  useEffect(() => {
    if (dirtyFields?.amount) {
      debounceAmountEntered();
    }
  }, [amount.amount, debounceAmountEntered, dirtyFields?.amount]);

  useEffect(() => {
    if (dirtyFields?.description) {
      debounceDescriptionEntered();
    }
  }, [debounceDescriptionEntered, dirtyFields?.description, description]);

  useEffect(() => {
    if (dirtyFields?.dueDate) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.AGENT_OWES_REAL_DEBT_DUE_DATE_SELECTED,
      );
    }
  }, [dirtyFields?.dueDate, dueDate]);

  useEffect(() => {
    if (dirtyFields?.startDate) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.AGENT_OWES_REAL_START_DATE_SELECTED,
      );
    }
  }, [dirtyFields?.startDate, startDate]);

  useEffect(() => {
    if (dirtyFields?.collectionMethod) {
      if (
        collectionMethod?.value === AgentDebtResponseRecoveryTypeEnum.Invoice
      ) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.AGENT_OWES_REAL_MNL_DEDUCTION_SELECTED,
        );
      } else {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.AGENT_OWES_REAL_AUTO_DEDUCTION_SELECTED,
        );
      }
    }
  }, [collectionMethod, dirtyFields?.collectionMethod]);

  const createInvoice = useCallback(
    (debtId: string, req: DebtInvoiceRequest) => {
      return dispatch(
        createAgentDebtInvoice(
          debtId,
          agentDetailResponse?.id!,
          currentTab === 'History' ? HISTORY_TAB_STATUS : ACTIVE_TAB_STATUS,
          req,
        ),
      );
    },
    [agentDetailResponse?.id, currentTab, dispatch],
  );

  const onSubmit = async (values: AddEditDebtFormData) => {
    if (!agentDebt) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.AGENT_OWES_REAL_ADD_DEBT_POPUP_ADD_CLICK,
        {
          debtType: values.debtType?.value,
        },
      );
      const createdDebt = await dispatch(
        createAgentDebt({
          debtType: values.debtType
            ?.value as AgentDebtCreateRequestDebtTypeEnum,
          description: values.description,
          amount: values.amount?.amount
            ? {
                amount: +values.amount?.amount,
                currency: values?.amount?.currency,
              }
            : undefined,
          startDate: values?.startDate ?? undefined,
          endDate: values.dueDate ?? undefined,
          agentId: agentDetailResponse?.id!,
          title: values?.title,
          recoveryType: values.collectionMethod
            ?.value as AgentDebtCreateRequestRecoveryTypeEnum,
          maxAllowedCollectionAmount:
            values?.maxCollection?.isDollar &&
            values?.maxCollection?.money?.amount
              ? +values?.maxCollection?.money?.amount
              : undefined,
          collectionTiers:
            !values?.maxCollection?.isDollar &&
            values?.maxCollection?.percent?.value &&
            values?.collectionMethod?.value !==
              AgentDebtResponseRecoveryTypeEnum.Invoice
              ? [
                  {
                    collectionPercent: +values?.maxCollection?.percent?.value,
                  },
                ]
              : undefined,
        }),
      );

      if (
        createdDebt?.id &&
        values.collectionMethod.value ===
          AgentDebtCreateRequestRecoveryTypeEnum.Invoice
      ) {
        await createInvoice(createdDebt.id, {
          autoCollectAfterDueDate: values.autoCollectAfterDueDate,
          daysUntilDue: +values.daysUntilDue,
        });
      }
    } else {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.AGENT_OWES_REAL_ADD_DEBT_POPUP_SAVED,
        {
          debtType: values.debtType?.value,
        },
      );
      const updatedDebt = await dispatch(
        updateAgentDebt(agentDebt?.id!, {
          debtType: values.debtType
            ?.value as AgentDebtUpdateRequestDebtTypeEnum,
          description: values.description,
          amount: values.amount?.amount
            ? {
                amount: +values.amount?.amount,
                currency: values?.amount?.currency,
              }
            : undefined,
          startDate: values?.startDate ?? undefined,
          endDate: values.dueDate ?? undefined,
          title: values?.title,
          recoveryType: values.collectionMethod
            ?.value as AgentDebtUpdateRequestRecoveryTypeEnum,
          maxAllowedCollectionAmount:
            values?.maxCollection?.isDollar &&
            values?.maxCollection?.money?.amount
              ? +values?.maxCollection?.money?.amount
              : undefined,
          collectionTiers:
            !values?.maxCollection?.isDollar &&
            values?.maxCollection?.percent?.value &&
            values?.collectionMethod?.value !==
              AgentDebtResponseRecoveryTypeEnum.Invoice
              ? [
                  {
                    collectionPercent: +values?.maxCollection?.percent?.value,
                  },
                ]
              : undefined,
        }),
      );
      const isInvoiceCreated = updatedDebt?.repaymentItems?.find(
        (item) =>
          item?.paymentSourceType ===
          DebtRepaymentItemResponsePaymentSourceTypeEnum.Invoice,
      );
      if (
        !isInvoiceCreated &&
        updatedDebt?.id &&
        values.collectionMethod.value ===
          AgentDebtCreateRequestRecoveryTypeEnum.Invoice
      ) {
        await createInvoice(updatedDebt?.id, {
          autoCollectAfterDueDate: values.autoCollectAfterDueDate,
          daysUntilDue: +values.daysUntilDue,
        });
      }
    }
    await dispatch(
      getPaginatedAgentDebts(
        agentId,
        (currentTab === 'History'
          ? HISTORY_TAB_STATUS
          : ACTIVE_TAB_STATUS) as Array<'ACTIVE' | 'PAID' | 'CANCELLED'>,
        undefined,
        0,
        1000,
      ),
    );
    onClose();
  };

  useEffect(() => {
    if (!agentDebt) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.AGENT_OWES_REAL_ADD_DEBT_POPUP_OPEN,
      );
    } else {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.AGENT_OWES_REAL_EDIT_DEBT_POPUP_OPEN,
        {
          debtType: agentDebt?.debtType,
        },
      );
    }
  }, [agentDebt]);

  useEffect(() => {
    if (!isEqual(startDate, agentDebt?.startDate)) {
      setValue('dueDate', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, startDate]);

  return (
    <ZenSidebarModal
      title={!agentDebt ? 'Add a Debt' : 'Edit Debt'}
      isOpen={isOpen}
      onClose={() => {
        if (!agentDebt) {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.AGENT_OWES_REAL_ADD_DEBT_POPUP_CLOSED,
          );
        } else {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.AGENT_OWES_REAL_EDIT_DEBT_POPUP_CLOSED,
            {
              debtType: agentDebt?.debtType,
            },
          );
        }
        onClose();
      }}
    >
      <form
        className='flex flex-col justify-between min-h-full pb-44'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4'>
          <div>
            <ZenControlledSelectInput<AddEditDebtFormData, 'debtType'>
              control={control}
              name='debtType'
              placeholder='Select Type'
              label='Select Type'
              options={getDebtTypesInDescendingOrder(
                values(AgentDebtCreateRequestDebtTypeEnum),
              ).map((type) => ({
                label: capitalizeEnum(type),
                value: type,
              }))}
              rules={{ required: 'Please select a debt type' }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<AddEditDebtFormData, 'title'>
              control={control}
              label='Debt Name'
              name='title'
              placeholder='Add Debt Name'
              isRequired
              rules={{
                required: 'Debt name is required',
              }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<AddEditDebtFormData, 'description'>
              control={control}
              label='Description'
              name='description'
              placeholder='Add Description'
              isRequired
              rules={{
                required: 'Description is required',
                maxLength: {
                  value: 1000,
                  message: 'Description must be under 1000 characters',
                },
              }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledMoneyInput<AddEditDebtFormData, 'amount'>
              control={control}
              label='Total Debt Amount'
              name='amount'
              placeholder='0.00'
              rules={{
                validate: (v: MoneyValue) => {
                  if (
                    !v?.amount &&
                    !maxCollectionMoney?.amount &&
                    !maxCollectionPercent?.value
                  ) {
                    return 'Total debt amount is required';
                  }

                  if (v?.amount && !v?.currency) {
                    return 'Please select currency';
                  }
                  if (v?.amount && v?.amount <= 0) {
                    return 'Amount should be greater than 0';
                  }
                  if (
                    v?.amount &&
                    !MONEY_AMOUNT_REGEX.test(v.amount?.toString()!)
                  ) {
                    return 'Please enter valid amount';
                  }

                  if (
                    maxCollectionMoney?.amount ||
                    maxCollectionPercent?.value
                  ) {
                    return undefined;
                  }

                  return undefined;
                },
              }}
              isRequired
            />
          </div>
          {collectionMethod?.value !==
            AgentDebtResponseRecoveryTypeEnum.Invoice && (
            <div className='mt-5 space-y-1'>
              <label
                htmlFor='max_collection'
                className='flex flex-row items-center'
              >
                <span
                  className={cn('font-zen-body font-semibold', {
                    'text-zen-danger': isMaxCollectionErrorPresent,
                    'text-zen-dark-9': !isMaxCollectionErrorPresent,
                  })}
                >
                  Collection (Max $ or %)
                </span>
                <span className='text-zen-danger mr-2'>*</span>
                <Hover
                  config={{ placement: 'top', trigger: 'hover' }}
                  hoverComponent={
                    <div className='flex flex-col space-y-3 p-3'>
                      <p className='font-zen-title font-medium text-lg text-zen-dark-9'>
                        Collection (Max $ or %)
                      </p>
                      <p className='font-zen-body font-normal text-base text-zen-dark-9'>
                        <span className='font-zen-body font-semibold text-base text-zen-dark-9'>
                          Max collection amount
                        </span>{' '}
                        is the absolute limit on the total sum that can be
                        recovered from an agent&apos;s payments.
                      </p>
                      <p className='font-zen-body font-normal text-base text-zen-dark-9'>
                        <span className='font-zen-body font-semibold text-base text-zen-dark-9'>
                          Collection percentage
                        </span>{' '}
                        is a relative limit on the total sum that can be
                        recovered.
                      </p>
                    </div>
                  }
                >
                  <FontAwesomeIcon
                    icon={regular('circle-info')}
                    className='text-zen-dark-12 text-lg flex items-center justify-center cursor-pointer'
                  />
                </Hover>
              </label>
              <div className='flex flex-row items-center space-x-2'>
                <div>
                  <ZenControlledToggleInput<
                    AddEditDebtFormData,
                    'maxCollection.isDollar'
                  >
                    label=''
                    name='maxCollection.isDollar'
                    control={control}
                    rightIcon='$'
                    leftIcon='%'
                    hideErrorMessage
                  />
                </div>
                <div className='flex-grow'>
                  {isMaxCollectionDollar ? (
                    <ZenControlledCurrencyInput<
                      AddEditDebtFormData,
                      'maxCollection.money.amount'
                    >
                      control={control}
                      name='maxCollection.money.amount'
                      placeholder='Amount'
                      startAdornment={
                        <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
                          <FontAwesomeIcon
                            icon={regular('dollar-sign')}
                            className='text-primary-blue text-base'
                          />
                        </div>
                      }
                      rules={{
                        validate: (v) => {
                          if (isMaxCollectionDollar && !v && !amount?.amount) {
                            return 'Collection amount is required';
                          }

                          if (v && v <= 0) {
                            return 'Amount should be greater than 0';
                          }
                          if (v && !MONEY_AMOUNT_REGEX.test(v?.toString()!)) {
                            return 'Please enter valid amount';
                          }

                          if (amount?.amount) {
                            return undefined;
                          }

                          return undefined;
                        },
                      }}
                      hideErrorMessage
                      shouldUnregister={false}
                    />
                  ) : (
                    <ZenControlledPercentageInput<
                      AddEditDebtFormData,
                      'maxCollection.percent.value'
                    >
                      control={control}
                      name='maxCollection.percent.value'
                      placeholder='Percentage'
                      startAdornment={
                        <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
                          <FontAwesomeIcon
                            icon={regular('percent')}
                            className='text-primary-blue text-base'
                          />
                        </div>
                      }
                      rules={{
                        validate: (v: number | undefined) => {
                          if (!isMaxCollectionDollar && !v && !amount?.amount) {
                            return 'Collection percentage is required';
                          }

                          if (v && v <= 0) {
                            return 'Collection percentage should be greater than 0';
                          }

                          if (amount?.amount) {
                            return undefined;
                          }

                          return undefined;
                        },
                        ...PERCENT_VALIDATION,
                      }}
                      hideErrorMessage
                      shouldUnregister={false}
                    />
                  )}
                </div>
              </div>
              {isMaxCollectionErrorPresent && (
                <p className='font-zen-body text-zen-danger text-sm pt-1'>
                  {!isMaxCollectionDollar
                    ? errors?.maxCollection?.percent?.value?.message
                    : errors?.maxCollection?.money?.amount?.message}
                </p>
              )}
            </div>
          )}
          <div className='mt-5'>
            <div className='flex flex-row items-center space-x-2'>
              <div className='flex-grow flex-shrink'>
                <ZenControlledDatePickerInput<AddEditDebtFormData, 'startDate'>
                  control={control}
                  name='startDate'
                  placeholder='MM/DD/YYYY'
                  label='Start Date'
                  icon={
                    <FontAwesomeIcon
                      icon={light('calendar')}
                      className='text-primary-blue text-lg flex items-center justify-center'
                    />
                  }
                />
              </div>
              <div className='flex justify-center mt-8'>
                <FontAwesomeIcon
                  icon={solid('dash')}
                  className='text-zen-dark-4'
                  size='xs'
                />
              </div>
              <div className='flex-grow flex-shrink'>
                <ZenControlledDatePickerInput<AddEditDebtFormData, 'dueDate'>
                  control={control}
                  name='dueDate'
                  label='End Date'
                  placeholder='MM/DD/YYYY'
                  icon={
                    <FontAwesomeIcon
                      icon={light('calendar')}
                      className='text-primary-blue text-lg flex items-center justify-center'
                    />
                  }
                  datePickerConfig={{
                    minDate: DateTime.fromISO(startDate!).toJSDate(),
                  }}
                />
              </div>
            </div>
          </div>
          <div className='mt-5'>
            <ZenControlledSelectInput<AddEditDebtFormData, 'collectionMethod'>
              control={control}
              name='collectionMethod'
              placeholder='Select Collection Method'
              label='Collection Method'
              options={values(AgentDebtCreateRequestRecoveryTypeEnum)
                .map((method) => ({
                  label: getRecoveryTypeToDisplayName(method),
                  value: method,
                }))
                .sort((a: ISelectOption, b: ISelectOption) =>
                  a.label.localeCompare(b.label),
                )}
              rules={{ required: 'Please select a collection method' }}
              isRequired
            />
          </div>
          {collectionMethod?.value ===
            AgentDebtResponseRecoveryTypeEnum.Invoice && (
            <div>
              <div className='mt-5 flex flex-row items-center'>
                <span className='font-zen-body font-semibold mr-2'>
                  Switch to auto
                </span>
                <Hover
                  config={{ placement: 'top', trigger: 'hover' }}
                  hoverComponent={
                    <div className='flex flex-col space-y-3 p-3'>
                      <p className='font-zen-body font-normal text-base text-zen-dark-9'>
                        When turned on, any debt that is not paid prior to the
                        set{' '}
                        <span className='font-zen-body font-semibold text-base text-zen-dark-9'>
                          &quot;Days until due&quot;
                        </span>{' '}
                        below will automatically switch over to be collected on
                        the next commission or revenue share payment.
                      </p>
                    </div>
                  }
                >
                  <FontAwesomeIcon
                    icon={regular('circle-info')}
                    className='text-zen-dark-12 text-lg flex items-center justify-center cursor-pointer'
                    aria-label='circle-info-icon'
                  />
                </Hover>
                <div>
                  <ZenControlledToggleInput<
                    AddEditDebtFormData,
                    'autoCollectAfterDueDate'
                  >
                    name='autoCollectAfterDueDate'
                    control={control}
                    disabled={!!invoiceRepayItem}
                  />
                </div>
              </div>
              {!invoiceRepayItem ? (
                <div className='mt-5'>
                  <ZenControlledTextInput<AddEditDebtFormData, 'daysUntilDue'>
                    control={control}
                    name='daysUntilDue'
                    label='Days until due'
                    placeholder='Add Days until due'
                    rules={{
                      required: 'Days until due is required',
                      ...NUMBER_VALIDATIONS,
                    }}
                    isRequired
                    shouldUnregister={false}
                  />
                </div>
              ) : (
                <div className='mt-5'>
                  <ZenControlledDatePickerInput<
                    AddEditDebtFormData,
                    'invoiceDueDate'
                  >
                    control={control}
                    name='invoiceDueDate'
                    label='Invoice Due Date'
                    placeholder='MM/DD/YYYY'
                    icon={
                      <FontAwesomeIcon
                        icon={light('calendar')}
                        className='text-primary-blue text-lg flex items-center justify-center'
                      />
                    }
                    readOnly
                    shouldUnregister={false}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <ZenSidebarModalActionFooterV2
          submitButtonText={!agentDebt ? 'Add' : 'Save'}
          onClose={() => {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.AGENT_OWES_REAL_ADD_DEBT_POPUP_CXL_CLICK,
            );
            onClose();
          }}
          isSubmitting={isSubmitting}
          isDisabled={isSubmitting}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default DebtAddEditForm;
