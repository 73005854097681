import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

interface LearnMoreCardProps {
  icon: FontAwesomeIconProps['icon'];
  iconColor: string;
  iconBackgroundColor: string;
  size: 'sm' | 'md' | 'lg' | '4lg' | 'xl';
  hasOpacity?: boolean;
}

const ICON_WIDTH_HEIGHT = {
  sm: 'h-8 w-8',
  md: 'h-12 w-12',
  lg: 'h-16 w-16',
  '4lg': 'h-32 w-32',
  xl: 'h-32 w-32',
} as const;

const ICON_SIZE: Record<
  'sm' | 'md' | 'lg' | '4lg' | 'xl',
  FontAwesomeIconProps['size']
> = {
  sm: undefined, // md
  md: 'lg',
  lg: 'xl',
  '4lg': '4x',
  xl: '6x',
};

export const WealthCircleIcon: React.FC<LearnMoreCardProps> = ({
  icon,
  iconColor,
  iconBackgroundColor,
  size = 'md',
  hasOpacity = false,
}) => {
  return (
    <div
      style={{
        backgroundColor: hasOpacity ? undefined : iconBackgroundColor,
      }}
      className={`${ICON_WIDTH_HEIGHT[size]} rounded-full flex flex-row items-center justify-center`}
    >
      <FontAwesomeIcon
        icon={icon}
        size={ICON_SIZE[size]}
        style={{
          color: iconColor,
          opacity: hasOpacity ? 0.3 : 1,
        }}
      />
    </div>
  );
};
