import {
  CreateWebhookRequestEventNamesEnum,
  WebhookEventsDto,
} from '../openapi/plutus';
import { capitalizeFirstLetter } from './StringUtils';

export const WebhookEndpointExample = [
  '// server.js',
  '//',
  '// Use this sample code to handle webhook events in your integration.',
  '//',
  '// 1) Paste this code into a new file (server.js)',
  '//',
  '// 2) Install dependencies: npm install express body-parser',
  '// npm install stripe',
  '// npm install express',
  '//',
  '// 3) Run the server on http://localhost:4242',
  '// node server.js',
  '//',
  '// The library needs to be configured with your account&aspos;s secret key.',
  '// Ensure the key is kept out of any version control system you might be using.',
  `const stripe = require('stripe')('sk_test_...');`,
  `const express = require('express');`,
  `const app = express();`,
  `//`,
  `//`,
  `// This is your Stripe CLI webhook secret for testing your endpoint locally.`,
  `const endpointSecret = "whsec_25de8ab1addcb18cf375b85bb790f08a6838b30d3681b7c4380293c2a9278ee4";`,
  `//`,
  `app.post('/webhook'`,
  ` express.raw({type: 'application/json'})`,
  `(request`,
  `response) => {`,
  `const sig = request.headers['stripe-signature'];`,
  `//`,
  `let event;`,
  `//`,
  `  }`,
  `try {`,
  `event = stripe.webhooks.constructEvent(request.body`,
  ` sig`,
  ` endpointSecret);`,
  `} catch (err) {`,
  `response.status(400).send(Webhook Error: err.message);`,
  `return;`,
  `}`,
  `//`,
  `// Handle the event`,
  `// switch (event.type) {`,
  `// case 'account.updated':`,
  `// const accountUpdated = event.data.object;`,
  `// Then define and call a function to handle the event account.updated`,
];

export interface WebhookEvent {
  label: string;
  value: CreateWebhookRequestEventNamesEnum;
  isChecked: boolean;
}

export interface WebhookEventGroup {
  title: string;
  isGroupSelected: boolean;
  events: WebhookEvent[];
}

export const getEventsFromWebhook = (webhookEvents: WebhookEventsDto) => {
  const keys = Object.keys(webhookEvents.events);

  const eventGroups: WebhookEventGroup[] = keys.map((item) => ({
    title: `${item} EVENTS`,
    isGroupSelected: false,
    events: webhookEvents?.events[item].map(
      (event) =>
        ({
          label: capitalizeFirstLetter(event),
          value: event,
          isChecked: false,
        } as WebhookEvent),
    ),
  }));

  return eventGroups;
};
