import React from 'react';
import AvatarPlaceHolderImg from '../../assets/img/avatar-placeholder.svg';
import {
  EnumMap,
  GeminiAvatarSizeType,
  ZenAvatarStatus,
  ZenAvatarStatusPosition,
  ZenAvatarVariantType,
} from '../../types';
import { getYentaImageUrl } from '../../utils/ImgUtils';
import {
  isStringEmpty,
  parseInitials,
  stringToHexCode,
} from '../../utils/StringUtils';
import Hover, { HoverProps } from '../Hover';
import { cn } from '../../utils/classUtils';

export const textSizeToClassNameMap: EnumMap<GeminiAvatarSizeType, string> = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-xl',
  xl: 'text-3xl',
  xxl: 'text-4xl',
  '3xl': 'text-5xl',
};

export const avatarSizeToClassNameMap: EnumMap<GeminiAvatarSizeType, string> = {
  xs: 'h-6 w-6',
  sm: 'h-8 w-8',
  md: 'h-10 w-10',
  lg: 'h-12 w-12',
  xl: 'h-14 w-14',
  xxl: 'h-16 w-16',
  '3xl': 'h-28 w-28',
};

export const avatarStyleToClassNameMap: EnumMap<
  ZenAvatarVariantType,
  string
> = {
  circle: 'rounded-full',
  rounded: 'rounded-md',
};

export interface GeminiAvatarProps {
  name: string;
  imageUrl?: string;
  variant?: ZenAvatarVariantType;
  size?: GeminiAvatarSizeType;
  status?: ZenAvatarStatus;
  statusPosition?: ZenAvatarStatusPosition;
  showPlaceholder?: boolean;
  showTooltip?: boolean;
  colorClassName?: string;
  showRing?: boolean;
  excludeYentaPrefix?: boolean;
  textOverride?: string;
}

const GeminiAvatar: React.FC<GeminiAvatarProps> = ({
  imageUrl,
  name,
  size = 'md',
  variant = 'circle',
  status,
  statusPosition = 'top',
  showPlaceholder = false,
  showTooltip = false,
  colorClassName,
  showRing = false,
  excludeYentaPrefix = false,
  textOverride,
}) => {
  const avatarStyleToClassNameMap: EnumMap<ZenAvatarVariantType, string> = {
    circle: 'rounded-full',
    rounded: 'rounded-md',
  };

  const avatarStatusIconToClassNameMap: EnumMap<
    GeminiAvatarSizeType,
    string
  > = {
    xs: 'h-1.5 w-1.5',
    sm: 'h-2 w-2',
    md: 'h-2.5 w-2.5',
    lg: 'h-3 w-3',
    xl: 'h-3.5 w-3.5',
    xxl: 'h-4 w-4',
    '3xl': 'h-4.5 w-4.5',
  };

  const avatarStatusIconPositionTopToClassNameMap: EnumMap<
    GeminiAvatarSizeType,
    string
  > = {
    xs: 'top-0.5 -right-0.5 ring-1',
    sm: '-top-1 -right-1',
    md: '-top-1 -right-1',
    lg: '-top-1.5 -right-1.5',
    xl: '-top-2 -right-2',
    xxl: '-top-2 -right-2',
    '3xl': '-top-2.5 -right-2.5',
  };

  const avatarStatusIconPositionBottomToClassNameMap: EnumMap<
    GeminiAvatarSizeType,
    string
  > = {
    xs: '-bottom-1 -right-0.5 ring-1',
    sm: '-bottom-1 -right-1',
    md: '-bottom-1 -right-1',
    lg: '-bottom-1.5 -right-1.5',
    xl: '-bottom-2 -right-2',
    xxl: '-bottom-2 -right-2',
    '3xl': '-bottom-2.5 -right-2.5',
  };

  const avatarStatusToClassNameMap: EnumMap<ZenAvatarStatus, string> = {
    online: 'bg-zen-success',
    offline: 'bg-zen-danger',
    away: 'bg-zen-dark-light',
  };

  const Container: React.FC<HoverProps> = showTooltip
    ? Hover
    : (props) => <div>{props.children}</div>;

  const GeminiStatusIcon = () => {
    return (
      <div
        className={cn(
          'absolute right-0 rounded-full ring-2 ring-white',
          status && avatarStatusToClassNameMap[status],
          avatarStatusIconToClassNameMap[size],
          statusPosition === 'top' ? 'top-0 ' : 'bottom-0',
          variant === 'rounded' &&
            statusPosition === 'top' &&
            avatarStatusIconPositionTopToClassNameMap[size],
          variant === 'rounded' &&
            statusPosition === 'bottom' &&
            avatarStatusIconPositionBottomToClassNameMap[size],
        )}
      />
    );
  };

  const getImageUrl = (url: string) => {
    if (isStringEmpty(url) || excludeYentaPrefix) {
      return url;
    }
    return getYentaImageUrl(url);
  };

  if (imageUrl || showPlaceholder) {
    return (
      <Container
        hoverComponent={
          <div>
            <p className='font-zen-body font-semibold text-sm text-zen-dark-9 px-1'>
              {name}
            </p>
          </div>
        }
        config={{ trigger: 'hover', placement: 'top' }}
      >
        <div
          className={cn(
            'relative',
            'cursor-pointer',
            avatarSizeToClassNameMap[size],
          )}
        >
          {status && <GeminiStatusIcon />}
          <img
            className={cn(
              'object-cover',
              avatarStyleToClassNameMap[variant],
              avatarSizeToClassNameMap[size],
              {
                'ring-2 ring-white': showRing,
              },
            )}
            src={getImageUrl(imageUrl!) ?? AvatarPlaceHolderImg}
            alt='avatar'
          />
        </div>
      </Container>
    );
  }

  return (
    <Container
      hoverComponent={
        <div>
          <p className='font-zen-body font-semibold text-sm text-zen-dark-9 px-1'>
            {name}
          </p>
        </div>
      }
      config={{ trigger: 'hover', placement: 'top' }}
    >
      <div
        className={cn(
          'relative',
          'cursor-pointer',
          avatarSizeToClassNameMap[size],
        )}
      >
        {status && <GeminiStatusIcon />}
        <span
          className={cn(
            'inline-flex items-center justify-center',
            avatarStyleToClassNameMap[variant],
            avatarSizeToClassNameMap[size],
            colorClassName,
            { 'ring-2 ring-white': showRing },
          )}
          style={{
            backgroundColor: !colorClassName ? stringToHexCode(name) : 'auto',
          }}
        >
          <span
            className={cn(
              textOverride ?? 'font-zen-title font-normal leading-1 text-white',
              textSizeToClassNameMap[size],
            )}
          >
            {parseInitials(name)}
          </span>
        </span>
      </div>
    </Container>
  );
};

export default GeminiAvatar;
