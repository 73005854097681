import { useForm } from 'react-hook-form-v7';
import { GenerateBankReportRequestBankReportTypeEnum } from '../../openapi/atlantis';
import { ISelectOption } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenSidebarModalForm from '../Zen/ZenSidebarModalForm';

interface MortgageGenerateReportSidebarProps {
  isOpen: boolean;
  onClose(): void;
  onSubmit(formData: GenerateReportFormData): Promise<void>;
}

export interface GenerateReportFormData {
  reportType: ISelectOption;
}

const MortgageGenerateReportSidebar: React.FC<MortgageGenerateReportSidebarProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<GenerateReportFormData>();

  return (
    <ZenSidebarModalForm
      isOpen={isOpen}
      onClose={onClose}
      isSubmitting={isSubmitting}
      title='Generate Report'
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Generate Report'
      cancelTitle='Cancel'
    >
      <ZenControlledSelectInput<GenerateReportFormData, 'reportType'>
        control={control}
        name='reportType'
        label='Report Type'
        placeholder='Select Report Type'
        options={[GenerateBankReportRequestBankReportTypeEnum.Voai].map(
          (item) => ({
            label: capitalizeEnum(item).toLocaleUpperCase(),
            value: item,
          }),
        )}
      />
    </ZenSidebarModalForm>
  );
};

export default MortgageGenerateReportSidebar;
