import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { values } from 'lodash';
import { DateTime } from 'luxon';
import ZenControlledDatePickerInput from '../components/Zen/Input/ZenControlledDatePickerInput';
import ZenControlledHTMLSelectInput from '../components/Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledTextAreaInput from '../components/Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../components/Zen/ZenSidebarModalForm';
import {
  AgentControllerApi,
  TerminateAgentRequest,
  TerminateAgentRequestTerminationReasonEnum,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { YesNoType } from '../types';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../utils/StringUtils';

interface TerminateAgentSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  agentId: string;
  rehire: string;
  terminatedAsOfDate: string;
  terminationReason: TerminateAgentRequestTerminationReasonEnum;
  terminationReasonDetails?: string;
}

const ZenTerminateAgentSidebarModal: React.FC<TerminateAgentSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const isTerminationReasonDetailRequired =
    watch('terminationReason') ===
    TerminateAgentRequestTerminationReasonEnum.Other;

  const onSubmit = async (formData: FormData) => {
    let agentReq: TerminateAgentRequest = {
      eligibleForRehire: YesNoType.YES === formData.rehire,
      terminatedAsOf: formData.terminatedAsOfDate,
      terminationReason: formData.terminationReason,
      terminationReasonDetails: formData.terminationReasonDetails,
    };

    try {
      await new AgentControllerApi(getYentaConfiguration()).terminateAgent(
        formData.agentId,
        agentReq,
      );
      onClose();
      dispatch(showSuccessToast('Agent terminated successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error terminating agent', e, {
        agent: {
          id: formData.agentId,
        },
        terminationRequest: agentReq,
      });
      dispatch(
        showErrorToast(
          'We had a problem terminating an agent',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <ZenSidebarModalForm
      isOpen={isOpen}
      onClose={onClose}
      title='Terminate Agent'
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      actionTitle='Terminate'
    >
      <div>
        <ZenControlledTextInput
          control={control}
          label='Agent ID'
          name='agentId'
          placeholder='12345678-1111-2222-3333-12345678'
          rules={{ required: 'Agent ID is required' }}
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledHTMLSelectInput
          label='Eligible for Rehire?'
          name='rehire'
          control={control}
          options={[
            { label: 'Yes', value: YesNoType.YES },
            { label: 'No', value: YesNoType.NO },
          ]}
          defaultValue={YesNoType.YES}
          rules={{
            required: 'Eligible for Rehire is required.',
          }}
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledDatePickerInput
          name='terminatedAsOfDate'
          control={control}
          label='Terminated As Of'
          placeholder='Eg: 01/01/2021'
          minDate={DateTime.local().minus({ years: 5 }).toJSDate()}
          maxDate={DateTime.local().toJSDate()}
          rules={{
            required: 'Terminated As Of Date is required',
          }}
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledHTMLSelectInput
          label='Termination Reason'
          name='terminationReason'
          control={control}
          options={[
            {
              label: 'Select a reason',
              value: '',
            },
            ...values(TerminateAgentRequestTerminationReasonEnum)?.map(
              (reason) => ({
                label: capitalizeEnum(reason),
                value: reason,
              }),
            ),
          ]}
          rules={{
            required: 'Termination Reason is required',
          }}
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledTextAreaInput
          control={control}
          label='Termination Reason Details'
          name='terminationReasonDetails'
          rows={3}
          placeholder='Termination reason details'
          rules={{
            required: isTerminationReasonDetailRequired
              ? 'Termination reason details is required'
              : undefined,
          }}
          isRequired={isTerminationReasonDetailRequired}
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenTerminateAgentSidebarModal;
