import { ISelectOption } from '../../../types';
import { cn } from '../../../utils/classUtils';

interface ZenControlledCheckButtonInputItemProps {
  value: string[];
  items: ISelectOption[];
  onToggle(newValue: string[]): void;
  variant?: 'default' | 'primary';
}

const ZenControlledCheckButtonInputItem = ({
  items,
  onToggle,
  value = [],
  variant = 'primary',
}: ZenControlledCheckButtonInputItemProps) => {
  const handleToggle = (checked: boolean, valueToUpdate: string) => {
    let newValue: string[];

    if (checked) {
      newValue = [...value, valueToUpdate];
    } else {
      newValue = value.filter((v) => v !== valueToUpdate);
    }

    onToggle(newValue);
  };

  return (
    <div
      className='flex flex-row items-center'
      key={items.map((i) => i.value).join()}
    >
      {items.map((item, index) => {
        const checked = !!value?.find((val: string) => val === item.value);

        const variantToBackgroundStyleMap = {
          default: checked
            ? 'bg-primary-blue border-primary-blue bg-opacity-20'
            : 'bg-white border-zen-dark-4 bg-opacity-95',
          primary: checked
            ? 'bg-zen-light-blue border-primary-blue'
            : 'bg-white border-zen-dark-5',
        };

        const variantToTextStyleMap = {
          default: checked
            ? 'text-zen-dark-9 font-semibold'
            : 'text-zen-dark-6 font-medium',
          primary: checked
            ? 'text-primary-blue font-medium'
            : 'text-zen-dark-9 font-medium',
        };

        return (
          <button
            className={cn(
              'flex-grow flex-shrink items-center text-center px-2 py-3 border outline-none focus:outline-none',
              variantToBackgroundStyleMap[variant],
              index === items.length - 1
                ? 'rounded-r-lg'
                : index === 0
                ? 'rounded-l-lg'
                : 'rounded-none',
              {
                'bg-gray-50 text-gray-300': item.disabled,
              },
            )}
            type='button'
            name={item.label}
            key={item.label}
            onClick={() => handleToggle(!checked, item.value)}
            disabled={item.disabled}
          >
            <span
              className={cn(
                'font-zen-title flex-wrap text-sm',
                variantToTextStyleMap[variant],
              )}
            >
              {item.label}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default ZenControlledCheckButtonInputItem;
