import { values } from 'lodash';
import { useEffect, useMemo } from 'react';
import {
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
} from '../../../openapi/yenta';
import { countryMapState } from '../../../testUtils/OnboardingUtils';
import { AgentAddressTypeEnum, ISelectOption } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenControlledRadioInput from '../../Zen/Input/ZenControlledRadioInput';
import ZenControlledSelectInput, {
  CustomSelectOptions,
} from '../../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenStickyBottomPagination from '../../Zen/ZenStickyBottomPagination';
import { getPostalCodeValidation } from '../../../utils/Validations';
import {
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName,
} from './ZenWelcomeOnboardingForm';
import withApplicactionFormProgress from './withWelcomeOnboardingFormProgress';

const AddressInfo: StepByStepComponent<
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName
> = ({ form: { control, watch, setValue }, onNext, onPrevious }) => {
  const [country, state] = watch([
    'agentUpdateRequest.addressRequest.country',
    'agentUpdateRequest.addressRequest.stateOrProvince',
  ]);
  const stateOptions: CustomSelectOptions[] = useMemo(
    () => [
      {
        label: 'Select a State/Province',
        value: '',
        disabled: true,
      },
      ...countryMapState[
        (country.value as unknown) as AddressRequestCountryEnum
      ],
    ],
    [country.value],
  );

  useEffect(() => {
    const invalid = !stateOptions.find((opt) => opt.value === state?.value);
    if (invalid) {
      setValue(
        'agentUpdateRequest.addressRequest.stateOrProvince',
        (stateOptions[0] as unknown) as ISelectOption<AddressRequestStateOrProvinceEnum>,
      );
    }
  }, [setValue, state?.value, stateOptions]);

  return (
    <div className='w-full flex flex-col justify-between flex-grow mt-10 relative max-w-md'>
      <div className='w-full max-w-2xl mx-auto flex-grow text-zen-dark-9 space-y-6 mb-10'>
        <div className='space-y-0.5'>
          <div className='text-0.5xl font-medium font-zen-title text-zen-dark-9'>
            What’s your mailing address?
          </div>
          <div className='text-zen-dark-7'>
            This is where you receive real estate related documents
          </div>
        </div>
        <div className=''>
          <ZenControlledRadioInput<
            WelcomeOnboardingFormState,
            'agentUpdateRequest.addressRequest.type'
          >
            label='This address is my:'
            name='agentUpdateRequest.addressRequest.type'
            options={values(AgentAddressTypeEnum).map((val) => ({
              label: capitalizeEnum(val),
              value: val,
            }))}
            rules={{
              required: 'Please choose one',
            }}
            isRequired
            control={control}
            inlineOptions
            shouldUnregister={false}
          />
        </div>
        <div className=''>
          <ZenControlledTextInput<
            WelcomeOnboardingFormState,
            'agentUpdateRequest.addressRequest.streetAddress1'
          >
            name='agentUpdateRequest.addressRequest.streetAddress1'
            label='Address Line 1'
            placeholder='E.g. 27 West, 24th St.'
            control={control}
            rules={{
              required: 'Required',
            }}
            isRequired
            shouldUnregister={false}
          />
        </div>
        <div className=''>
          <ZenControlledTextInput<
            WelcomeOnboardingFormState,
            'agentUpdateRequest.addressRequest.streetAddress2'
          >
            name='agentUpdateRequest.addressRequest.streetAddress2'
            label='Address Line 2'
            placeholder='E.g. Apt. 123'
            control={control}
            shouldUnregister={false}
          />
        </div>

        <div className='space-y-6'>
          <div className='flex items-center space-x-5 w-full justify-between'>
            <div className='flex-1'>
              <ZenControlledTextInput<
                WelcomeOnboardingFormState,
                'agentUpdateRequest.addressRequest.city'
              >
                name='agentUpdateRequest.addressRequest.city'
                label='City'
                control={control}
                placeholder='Ex. New York'
                rules={{
                  required: 'Required',
                }}
                isRequired
                shouldUnregister={false}
              />
            </div>
            <div className='flex-1'>
              <ZenControlledSelectInput<
                WelcomeOnboardingFormState,
                'agentUpdateRequest.addressRequest.stateOrProvince'
              >
                name='agentUpdateRequest.addressRequest.stateOrProvince'
                control={control}
                rules={{
                  required: true,
                  validate: (state) => {
                    if (!state?.value) {
                      return 'Please select a state / province';
                    }
                    return undefined;
                  },
                }}
                isRequired
                options={stateOptions}
                label='State/Province'
                shouldUnregister={false}
              />
            </div>
          </div>
          <div className='flex items-center space-x-5 w-full justify-between'>
            <div className='flex-1'>
              <ZenControlledTextInput<
                WelcomeOnboardingFormState,
                'agentUpdateRequest.addressRequest.zipOrPostalCode'
              >
                name='agentUpdateRequest.addressRequest.zipOrPostalCode'
                control={control}
                label='Postal/ZIP Code'
                placeholder='Ex. 10001'
                isRequired
                rules={{
                  required: 'Please provide the postal code',
                  ...getPostalCodeValidation(country.value),
                }}
                shouldUnregister={false}
              />
            </div>
            <div className='flex-1'>
              <ZenControlledSelectInput<
                WelcomeOnboardingFormState,
                'agentUpdateRequest.addressRequest.country'
              >
                label='Country'
                name='agentUpdateRequest.addressRequest.country'
                options={[
                  { label: 'Select a Country', value: '', disabled: true },
                  ...values(AddressRequestCountryEnum)?.map((country) => ({
                    value: country,
                    label: capitalizeEnum(country),
                  })),
                ]}
                rules={{
                  required: true,
                  validate: (country) => {
                    if (!country?.value) {
                      return 'Please select a country';
                    }
                    return undefined;
                  },
                }}
                isRequired
                control={control}
                shouldUnregister={false}
              />
            </div>
          </div>
        </div>
      </div>
      <ZenStickyBottomPagination
        onPrevious={onPrevious}
        onNext={onNext}
        maxWidth='xl'
      />
    </div>
  );
};

export default withApplicactionFormProgress(AddressInfo);
