import { combineReducers } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { RootState } from '../types';
import { RESET_APP } from './actions/authActions';
import ActivitySlice from './ActivitySlice';
import AgentSlice from './AgentSlice';
import AgentWebsiteOnboardingSlice from './AgentWebsiteOnboardingSlice';
import AnnouncementSlice from './AnnouncementSlice';
import ApplicationSlice from './ApplicationSlice';
import AuthSlice from './AuthSlice';
import BankSlice from './BankSlice';
import BoardSlice from './BoardSlice';
import BrokerQueueSlice from './BrokerQueueSlice';
import CalendarSlice from './CalendarSlice';
import CDAFormSlice from './CDAFormSlice';
import CheckDepositsSlice from './CheckDepositsSlice';
import ChecklistDefinitionSlice from './ChecklistDefinitionSlice';
import CheckListSlice from './CheckListSlice';
import ClientApiKeySlice from './ClientApiKeySlice';
import ConveyanceDocumentSlice from './ConveyanceDocumentSlice';
import CronJobs from './CronJobs';
import DropboxSlice from './DropboxSlice';
import ErrorSlice from './ErrorSlice';
import ExperimentSlice from './ExperimentSlice';
import InstantPaymentSlice from './InstantPaymentSlice';
import JointVentureSlice from './JointVentureSlice';
import LearnworldsSlice from './LearnworldsSlice';
import LeoSlice from './LeoSlice';
import MainMenuSlice from './MainMenuSlice';
import MaintenanceSlice from './MaintenanceSlice';
import MLSSlice from './MLSSlice';
import MoneyTransferSlice from './MoneyTransferSlice';
import MortgageSlice from './MortgageSlice';
import NetworkSlice from './NetworkSlice';
import OfficeSlice from './OfficeSlice';
import PaymentMethodSlice from './PaymentMethodSlice';
import QuickActionSlice from './QuickActionSlice';
import ReleaseFundsSlice from './ReleaseFundsSlice';
import RevenueSlice from './RevenueSlice';
import RoleSlice from './RoleSlice';
import SupportSlice from './SupportSlice';
import TaxInformationSlice from './TaxInformationSlice';
import TaxSlice from './TaxSlice';
import TeamSlice from './TeamSlice';
import ToastNotificationSlice from './ToastNotificationSlice';
import TransactionBuilderSlice from './TransactionBuilderSlice';
import TransactionSlice from './TransactionSlice';
import UserIdsSlice from './UserIdsSlice';
import { DemoModeReducer } from './DemoModeSlice';
import KBarSlice from './KBarSlice';
import WealthManagementSlice from './WealthManagementSlice';
import WebhookSlice from './WebhookSlice';
import ReferralSlice from './ReferralSlice';
import TransactionCoordinatorSlice from './TransactionCoordinatorSlice';

const appReducer = combineReducers({
  activityList: ActivitySlice,
  agentDetail: AgentSlice,
  agentWebsiteOnboarding: AgentWebsiteOnboardingSlice,
  announcement: AnnouncementSlice,
  applicationDetail: ApplicationSlice,
  auth: AuthSlice,
  bank: BankSlice,
  board: BoardSlice,
  brokerQueue: BrokerQueueSlice,
  calendar: CalendarSlice,
  cdaForm: CDAFormSlice,
  checkDeposits: CheckDepositsSlice,
  checkListDefinition: ChecklistDefinitionSlice,
  checklist: CheckListSlice,
  clientApiKey: ClientApiKeySlice,
  conveyanceDocument: ConveyanceDocumentSlice,
  cronJobs: CronJobs,
  demoMode: DemoModeReducer,
  dropbox: DropboxSlice,
  errorDetails: ErrorSlice,
  experiment: ExperimentSlice,
  instantPayment: InstantPaymentSlice,
  jointVenture: JointVentureSlice,
  learnworlds: LearnworldsSlice,
  leo: LeoSlice,
  mainMenu: MainMenuSlice,
  maintenanceDetail: MaintenanceSlice,
  mls: MLSSlice,
  moneyTransfer: MoneyTransferSlice,
  mortgage: MortgageSlice,
  releaseFunds: ReleaseFundsSlice,
  network: NetworkSlice,
  office: OfficeSlice,
  paymentMethod: PaymentMethodSlice,
  quickAction: QuickActionSlice,
  revenue: RevenueSlice,
  roles: RoleSlice,
  support: SupportSlice,
  taxDoc: TaxSlice,
  taxInformation: TaxInformationSlice,
  team: TeamSlice,
  toastNotification: ToastNotificationSlice,
  transaction: TransactionSlice,
  transactionBuilder: TransactionBuilderSlice,
  userIds: UserIdsSlice,
  kBar: KBarSlice,
  wealthManagement: WealthManagementSlice,
  webhooks: WebhookSlice,
  referralCentral: ReferralSlice,
  transactionCoordinator: TransactionCoordinatorSlice,
});

const rootReducer = (state: RootState | undefined, action: Action) => {
  if (action.type === RESET_APP) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
