import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  GoogleMap,
  Marker,
  Circle,
  useJsApiLoader,
} from '@react-google-maps/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationCheck } from '@fortawesome/pro-light-svg-icons';
import MapMarker from '../../../assets/icons/mapMarker.svg';
import RCMap from '../../../assets/img/rc-map.png';
import config from '../../../config';

interface MapOptions {
  strokeColor: string;
  fillColor: string;
}

interface MapCircle {
  radius: number;
  options: MapOptions;
}

export interface AddressData {
  name?: string;
  circle?: MapCircle;
  latitude?: number;
  longitude?: number;
}

interface MapProps {
  places: AddressData[];
  customContainerStyle?: React.CSSProperties;
  options?: google.maps.MapOptions;
}

const containerStyle = {
  height: '400px',
};

const center = {
  // New York
  lat: 40.64,
  lng: -73.96,
};

const Map: React.FC<MapProps> = (props) => {
  const [map, setMap] = useState<any>();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.gMapsAPIKey,
  });

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const resizeBounds = useCallback(
    (map) => {
      const bounds = new window.google.maps.LatLngBounds(
        props.places[0]?.latitude!
          ? {
              lat: props.places[0]?.latitude!,
              lng: props.places[0]?.longitude!,
            }
          : center,
      );

      props.places.forEach((place: any) => {
        if (!place.latitude || !place.longitude) return;

        bounds.extend({
          lat: parseFloat(place.latitude),
          lng: parseFloat(place.longitude),
        });
      });

      map.fitBounds(bounds, 10);

      const zoom = map.getZoom();
      map.setZoom(zoom > 10 ? 10 : zoom);
    },
    [props.places],
  );

  useEffect(() => {
    if (map) {
      resizeBounds(map);
    }
  }, [map, resizeBounds, props]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  if (props.places.length === 0 || !isLoaded) {
    return (
      <div className='relative'>
        <div className='bg-white p-5 absolute top-[30%] left-[30%] w-64 rounded-lg'>
          <FontAwesomeIcon
            icon={faLocationCheck}
            fontSize={36}
            className='flex mx-auto text-rezen-blue-600 pb-4'
          />
          <p className='text-center text-sm text-dark'>
            Entered referral location will be displayed on this map
          </p>
        </div>
        <img src={RCMap} alt='map' />
      </div>
    );
  }

  return (
    <GoogleMap
      mapContainerStyle={props.customContainerStyle ?? containerStyle}
      options={props.options}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {props.places.map((place: any) => {
        return (
          <Fragment key={place.id}>
            <Marker
              icon={{
                url: MapMarker,
              }}
              position={{
                lat: parseFloat(place.latitude),
                lng: parseFloat(place.longitude),
              }}
            />
            {place.circle && (
              <Circle
                center={{
                  lat: parseFloat(place.latitude),
                  lng: parseFloat(place.longitude),
                }}
                radius={place.circle.radius}
                options={place.circle.options}
              />
            )}
          </Fragment>
        );
      })}
    </GoogleMap>
  );
};

export default React.memo(Map);
