import classNames from 'classnames';
import React from 'react';
import { ReactComponent as CompanyIcon } from '../../../../assets/img/zen/transactionHeader/company.svg';
import { ReactComponent as HomeIcon } from '../../../../assets/img/zen/transactionHeader/home.svg';
import { ReactComponent as ShakeHandsIcon } from '../../../../assets/img/zen/transactionHeader/shakehands.svg';
import {
  TransactionBuilderResponseDealTypeEnum,
  TransactionLiteResponseTransactionTypeEnum,
  TransactionResponseTransactionTypeEnum,
  PendingReviewAndMarkCompliantTransactionTypeEnum,
  ListingToApproveTransactionTypeEnum,
  ChecklistFollowupTransactionTypeEnum,
  TransactionToApproveForCDATransactionTypeEnum,
} from '../../../../openapi/arrakis';
import { PartialEnumMap } from '../../../../types';
import { safePartialEnumMapGet } from '../../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../../utils/StringUtils';

export type DealTypeEnum =
  | TransactionResponseTransactionTypeEnum
  | TransactionLiteResponseTransactionTypeEnum
  | TransactionBuilderResponseDealTypeEnum
  | PendingReviewAndMarkCompliantTransactionTypeEnum
  | ListingToApproveTransactionTypeEnum
  | ChecklistFollowupTransactionTypeEnum
  | TransactionToApproveForCDATransactionTypeEnum;

interface ZenTransactionTypeCellProps {
  dealType: DealTypeEnum;
}

const ZenTransactionTypeCell: React.FC<ZenTransactionTypeCellProps> = ({
  dealType,
}) => {
  const dealTypeToStyleMap: PartialEnumMap<DealTypeEnum, string> = {
    SALE: 'bg-zen-light-blue text-primary-blue w-18',
    LEASE: 'bg-green-100 text-green-400 w-20',
    REFERRAL: 'bg-red-100 text-zen-red-2 w-24',
    INTERNAL_REFERRAL: 'bg-red-100 text-zen-red-2 w-40',
  };

  const style = safePartialEnumMapGet(
    dealTypeToStyleMap,
    dealType,
    'bg-zen-light-blue text-primary-blue w-18',
  );

  const dealTypeToIconMap: PartialEnumMap<DealTypeEnum, React.ReactElement> = {
    SALE: <HomeIcon width={24} height={24} className='text-primary-blue' />,
    LEASE: <CompanyIcon width={24} height={24} className='text-green-400' />,
    REFERRAL: (
      <ShakeHandsIcon width={24} height={24} className='text-zen-red-2' />
    ),
    INTERNAL_REFERRAL: (
      <ShakeHandsIcon width={24} height={24} className='text-zen-red-2' />
    ),
  };

  const Icons = safePartialEnumMapGet(
    dealTypeToIconMap,
    dealType,
    <HomeIcon width={24} height={24} className='text-primary-blue' />,
  );

  return (
    <div className={classNames('rounded-full', style)}>
      <div className='flex flex-row justify-center items-center p-1'>
        {Icons}
        <p className='font-zen-body font-medium text-sm min-w-max'>
          {capitalizeEnum(dealType)}
        </p>
      </div>
    </div>
  );
};

export default ZenTransactionTypeCell;
