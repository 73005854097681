import { useParams } from 'react-router-dom';
import ZenConveyanceResourceTable from './ZenConveyanceResourceTable';

type Match = {
  id: string;
};

const ZenConveyanceDocumentsRoute: React.FC = () => {
  const { id } = useParams() as Match;

  return <ZenConveyanceResourceTable transactionId={id} />;
};

export default ZenConveyanceDocumentsRoute;
