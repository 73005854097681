import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form-v7';
import {
  TransactionBuilderResponse,
  TransactionBuilderResponseDealTypeEnum,
} from '../openapi/arrakis';
import { CreateTransactionFormState } from '../components/Zen/Transaction/CreateTransaction/ZenCreateTransactionSteps';
import { getTransactionRealTitleName } from '../utils/RealTitleUtils';

export const useUpdateCommissionPayerChanges = (
  transactionBuilder: TransactionBuilderResponse,
  setValue: UseFormSetValue<CreateTransactionFormState>,
) => {
  const isParticipantUnavailable = !transactionBuilder.commissionPayerInfo;
  const isOneRealTitleEnabled = !!transactionBuilder?.titleInfo?.useRealTitle;

  const selectedParticipant = transactionBuilder.allParticipants?.find(
    (participant) =>
      participant.id === transactionBuilder.commissionPayerInfo?.participantId,
  );

  const isParticipantReset =
    !!transactionBuilder.commissionPayerInfo?.participantId &&
    !selectedParticipant;

  useEffect(() => {
    if (isParticipantReset || isParticipantUnavailable) {
      setValue(
        'commissionPayerInfo',
        {
          role: isOneRealTitleEnabled
            ? 'Title/Escrow'
            : transactionBuilder.dealType ===
                TransactionBuilderResponseDealTypeEnum.Lease &&
              !transactionBuilder.commissionPayerInfo?.role
            ? 'Landlord'
            : '',
          firstName: '',
          lastName: '',
          companyName: isOneRealTitleEnabled
            ? `${getTransactionRealTitleName(
                transactionBuilder?.address?.state,
              )}`
            : '',
          email: '',
          isInfoUnknown: [],
          participant: '',
          phoneNumber: '',
        },
        { shouldValidate: false },
      );
    }
  }, [
    isParticipantReset,
    isParticipantUnavailable,
    setValue,
    transactionBuilder.commissionPayerInfo?.role,
    transactionBuilder.dealType,
    transactionBuilder.address?.state,
    isOneRealTitleEnabled,
  ]);
};
