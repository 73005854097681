import { FilterProps } from 'react-table';
import { PROCESS_FILTER_COLUMN } from '../../../../utils/TableUtils';
import { FilterColumnsToProcess } from '../../../../types';
import ControlledSwitchInputV7 from '../../../ControlledSwitchInputV7';

export interface SwitchNullableColumnFilterProps<D extends object>
  extends FilterProps<D> {
  label: string;
}

const SwitchNullableColumnFilter = <D extends object>({
  column,
  control,
  label,
}: SwitchNullableColumnFilterProps<D>): React.ReactElement => {
  const prefixFieldName = `${PROCESS_FILTER_COLUMN}.${FilterColumnsToProcess.BOOLEAN_NULLABLE}.${column.id}`;

  return (
    <ControlledSwitchInputV7
      control={control}
      name={prefixFieldName}
      shouldUnregister={false}
      label={label}
      defaultValue={column.filterValue?.value}
    />
  );
};

export default SwitchNullableColumnFilter;
