import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useFormContext } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { setWealthKnowledgeLandingPage } from '../../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum } from '../../../../types';
import ZenControlledSquareToggleInput from '../../../Zen/Input/ZenControlledSquareToggleInput';
import ZenControlledInputSlider from '../../../Zen/ZenControlledInputSlider';
import { Earning } from '../../WealthManagementTypes';
import { WealthPlanTooltip } from '../../WealthManagementUtils';
import { GoalsFormInput } from '../SetGoalsSideBarV2';
import WealthManagementCardV2 from '../WealthManagementCardV2';

const ContainerWrapper: React.FC<{
  noContainer: boolean;
  logAnalyticsEvent: (event: string) => void;
}> = ({ noContainer, logAnalyticsEvent, children }) => {
  const dispatch = useDispatch();

  if (noContainer) {
    return <>{children}</>;
  }

  return (
    <WealthManagementCardV2
      title='Revenue Share Income'
      onClickText='Learn More'
      onClick={() => {
        logAnalyticsEvent(
          AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_LEARN_MORE_REV_SHARE,
        );
        dispatch(setWealthKnowledgeLandingPage(Earning.REVENUE_SHARE_INCOME));
      }}
      className='md:!p-4 !p-3'
    >
      {children}
    </WealthManagementCardV2>
  );
};

interface RevenueShareIncomeFormInputsProps {
  logAnalyticsEvent: (event: string) => void;
  showOnlyAttractionInputs?: boolean;
  noContainer?: boolean;
}

const RevenueShareIncomeFormInputs: React.FC<RevenueShareIncomeFormInputsProps> = ({
  logAnalyticsEvent,
  showOnlyAttractionInputs = false,
  noContainer = false,
}) => {
  const { control, watch, setValue } = useFormContext<GoalsFormInput>();

  const [agentReferredPerYear, agentsAgentReferredPerYear] = watch([
    'agentReferredPerYear',
    'agentsAgentReferredPerYear',
  ]);

  return (
    <ContainerWrapper
      noContainer={noContainer}
      logAnalyticsEvent={logAnalyticsEvent}
    >
      {!showOnlyAttractionInputs && (
        <>
          <ZenControlledSquareToggleInput<
            GoalsFormInput,
            'revShareParticipation'
          >
            control={control}
            name='revShareParticipation'
            label='Will you attract agents to Real?'
            defaultValue={
              agentReferredPerYear > 0 || agentsAgentReferredPerYear > 0
            }
            onChangeSpy={(value) => {
              if (value) {
                setValue('agentReferredPerYear', 1);
              } else {
                setValue('agentReferredPerYear', 0);
                setValue('agentsAgentReferredPerYear', 0);
              }
              logAnalyticsEvent(
                value
                  ? AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_REVSHARE_TO_YES
                  : AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_REVSHARE_TO_NO,
              );
            }}
          />
          <div className='border-b border-gray-200 my-4' />
        </>
      )}
      <ZenControlledInputSlider<GoalsFormInput, 'agentReferredPerYear'>
        control={control}
        min={0}
        max={100}
        step={1}
        name='agentReferredPerYear'
        startAdornment={
          <FontAwesomeIcon
            icon={faUsers}
            className='text-primary-blue'
            size='sm'
          />
        }
        label={
          (
            <div>
              How many agents will you personally attract/refer per year?
              <WealthPlanTooltip
                title='You will receive 5% of the income from each transaction from an agent you personally refer'
                analyticsEvent={
                  AnalyticsEventEnum.WEALTH_PLAN_REV_SHARE_AGENTS_REFERRED_TOOLTIP
                }
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  size='sm'
                  className='ml-1'
                />
              </WealthPlanTooltip>
            </div>
          ) as any
        }
        rules={{
          required: 'Please set a value.',
          min: {
            value: 0,
            message: 'Must be greater than or equal to 0',
          },
          max: {
            value: 100,
            message: 'Must be less than or equal to 100',
          },
        }}
        onChangeSpy={(_, source) => {
          logAnalyticsEvent(
            source === 'input'
              ? AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_AGENTS_REFERRED_INPUT
              : AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_AGENTS_REFERRED_SLIDER,
          );
        }}
      />
      <div className='border-b border-gray-200 my-4' />
      <ZenControlledInputSlider<GoalsFormInput, 'agentsAgentReferredPerYear'>
        control={control}
        min={0}
        max={100}
        step={1}
        name='agentsAgentReferredPerYear'
        startAdornment={
          <FontAwesomeIcon
            icon={faUsers}
            className='text-primary-blue'
            size='sm'
          />
        }
        label={
          (
            <div>
              How many agents will each agent in your tier 1 attract per year?
              <WealthPlanTooltip
                title='You will receive 4% of the income from each transaction of these agents, subject to terms and conditions'
                analyticsEvent={
                  AnalyticsEventEnum.WEALTH_PLAN_REV_SHARE_AGENTS_AGENTS_REFERRED_TOOLTIP
                }
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  size='sm'
                  className='ml-1'
                />
              </WealthPlanTooltip>
            </div>
          ) as any
        }
        rules={{
          required: 'Please set a value.',
          min: {
            value: 0,
            message: 'Must be greater than or equal to 0',
          },
          max: {
            value: 100,
            message: 'Must be less than or equal to 100',
          },
        }}
        onChangeSpy={(_, source) => {
          logAnalyticsEvent(
            source === 'input'
              ? AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_AGENTS_AGENTS_REFERRED_INPUT
              : AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_AGENTS_AGENTS_REFERRED_SLIDER,
          );
        }}
      />
    </ContainerWrapper>
  );
};

export default RevenueShareIncomeFormInputs;
