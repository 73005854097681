import React, { useState } from 'react';
import { faXmark, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ZenSidebarModal from '../../../components/Zen/ZenSidebarModal';
import GeminiButton from '../../../components/Gemini/GeminiButton';
import GeminiSwitch from '../../../components/Gemini/input/GeminiSwitch';
import { ReferralCentralReferralDto } from '../../../openapi/arrakis';
import ReferralDetailCard from '../components/ReferralDetailCard';

interface ReferralDetailUnpublishedSidebarProps {
  referralDetails: ReferralCentralReferralDto;
  onClose: () => void;
  isOpen: boolean;
}

const ReferralDetailUnPublishedSidebar: React.FC<ReferralDetailUnpublishedSidebarProps> = ({
  referralDetails,
  onClose,
  isOpen,
}) => {
  const [isPublished, setIsPublished] = useState(false);
  return (
    <ZenSidebarModal title='Referral Details' isOpen={isOpen} onClose={onClose}>
      <div className='flex flex-col w-full'>
        <div className='p-5 border border-regent-300 rounded-lg m-4'>
          <ReferralDetailCard referralDetails={referralDetails} />
        </div>

        <div className='py-20' />
        <div className='bg-white flex flex-col justify-end w-full absolute bottom-0'>
          <div>
            <div className='flex flex-row items-center justify-between bg-regent-200 p-3 px-5 rounded-b-xl'>
              <div>
                <p className='text-primary-dark font-normal text-base'>
                  Published
                </p>
                <p className='text-grey-500 font-light text-sm'>
                  Pause or publish your referral
                </p>
              </div>
              <GeminiSwitch
                leftIcon={<FontAwesomeIcon icon={faXmark} size='sm' />}
                rightIcon={
                  <FontAwesomeIcon
                    icon={faCheck}
                    size='sm'
                    className='text-rezen-blue-600'
                  />
                }
                toggle={isPublished}
                onClick={() => setIsPublished(!isPublished)}
                testId='publish-or-pause'
              />
            </div>
          </div>
          <div className='px-5 border-t border-gray-200 justify-end flex flex-row'>
            <div className='w-36 p-5'>
              <GeminiButton
                type='button'
                onClick={onClose}
                label='Back'
                size='md'
                isFullWidth
                variant='primary-outline'
              />
            </div>
          </div>
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default ReferralDetailUnPublishedSidebar;
