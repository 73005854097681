import { values } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { faArrowUpArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AddressCountryEnum,
  FeaturesResponseEligibleEnum,
  LocationInfoRequest,
  LocationInfoRequestStateEnum,
  TransactionControllerApi,
  TransactionResponse,
  TransactionResponseTransactionTypeEnum,
} from '../../../openapi/arrakis';
import {
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaResponseCountryEnum,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { toggleEditTransactionAddressMlsEscrow } from '../../../slices/QuickActionSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import {
  fetchTransactionDetails,
  updateFMLSInfo,
} from '../../../slices/TransactionSlice';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { getAddressFromAddressComponent } from '../../../utils/TransactionHelper';
import {
  GOOGLE_AUTO_COMPLETE_VALIDATIONS,
  YEAR_VALIDATIONS,
} from '../../../utils/Validations';
import ZenControlledGoogleAutocompleteSearchInput, {
  GooglePlaceLocationType,
} from '../Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledHTMLSelectInput from '../Input/ZenControlledHTMLSelectInput';
import ZenControlledStateOrProvinceInput from '../Input/ZenControlledStateOrProvince';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenButton from '../ZenButton';
import ZenSidebarModalForm from '../ZenSidebarModalForm';
import ZenControlledToggleInput from '../Input/ZenControlledToggleInput';
import { AnalyticsEventEnum, RootState } from '../../../types';
import AnalyticsService from '../../../services/AnalyticsService';

interface ZenEditTransactionAddressMlsEscrowProps {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
}

interface FormData extends LocationInfoRequest {
  location?: GooglePlaceLocationType;
  country: AddressCountryEnum;
  isManualAddress: boolean;
  propertyListedOnFmls: boolean;
}

const ZenEditTransactionAddressMlsEscrow: React.FC<ZenEditTransactionAddressMlsEscrowProps> = ({
  isOpen,
  onClose,
  transaction,
}) => {
  const dispatch = useDispatch();
  const {
    transaction: { features },
  } = useSelector((state: RootState) => state);

  const isFMLSFeeApplicable = !!features?.eligible?.includes(
    FeaturesResponseEligibleEnum.Fmls,
  );

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      street: transaction.address?.street,
      street2: transaction.address?.street2,
      city: transaction.address?.city,
      state: (transaction.address
        ?.state as unknown) as LocationInfoRequestStateEnum,
      country: transaction.address?.country,
      zip: transaction.address?.zip,
      escrowNumber: transaction.escrowNumber,
      mlsNumber: transaction.mlsNum,
      yearBuilt: transaction.yearBuilt,
      propertyListedOnFmls: transaction.fmlsInfo?.propertyListedOnFmls,
      location: {
        address_components: [
          {
            long_name: transaction.address?.street!,
            short_name: transaction.address?.street!,
            types: ['route'],
          },
          {
            long_name: transaction.address?.street2!,
            short_name: transaction.address?.street2!,
            types: ['neighborhood'],
          },
          {
            long_name: transaction.address?.city!,
            short_name: transaction.address?.city!,
            types: ['locality'],
          },
          {
            long_name: transaction.address?.state!,
            short_name: transaction.address?.state!,
            types: ['administrative_area_level_1'],
          },
          {
            long_name: transaction.address?.country!,
            short_name: transaction.address?.country!,
            types: ['country'],
          },
          {
            long_name: transaction.address?.zip!,
            short_name: transaction.address?.zip!,
            types: ['postal_code'],
          },
        ],
        formatted_address: transaction.address?.oneLine,
      },
    },
  });
  const isManualAddress = watch('isManualAddress');
  const onSubmit = async (formData: FormData) => {
    try {
      const isPlaceIdNull = !formData.location?.place_id;
      const locationInfoRequestData: LocationInfoRequest = {
        street:
          formData.isManualAddress || isPlaceIdNull
            ? formData.street!
            : getAddressFromAddressComponent(
                formData.location?.address_components!,
              )?.streetAddress1,
        street2:
          formData.isManualAddress || isPlaceIdNull
            ? formData.street2!
            : getAddressFromAddressComponent(
                formData.location?.address_components!,
              )?.streetAddress2,
        city:
          formData.isManualAddress || isPlaceIdNull
            ? formData.city!
            : getAddressFromAddressComponent(
                formData.location?.address_components!,
              )?.city!,
        state:
          formData.isManualAddress || isPlaceIdNull
            ? (formData.state! as LocationInfoRequestStateEnum)
            : ((getAddressFromAddressComponent(
                formData.location?.address_components!,
              )?.stateOrProvince! as unknown) as LocationInfoRequestStateEnum),
        zip:
          formData.isManualAddress || isPlaceIdNull
            ? formData.zip!
            : getAddressFromAddressComponent(
                formData.location?.address_components!,
              )?.zipOrPostalCode!,
        mlsNumber: formData.mlsNumber!,
        yearBuilt: formData.yearBuilt,
        escrowNumber: formData.escrowNumber,
      };
      if (isFMLSFeeApplicable) {
        await dispatch(
          updateFMLSInfo(transaction.id!, {
            propertyListedOnFmls: formData.propertyListedOnFmls,
          }),
        );
        if (formData.propertyListedOnFmls) {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.FMLS_TOGGLED_TO_YES_IN_EDIT,
          );
        } else {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.FMLS_TOGGLED_TO_NO_IN_EDIT,
          );
        }
      }
      await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).updateLocation(transaction.id!, locationInfoRequestData);
      await dispatch(fetchTransactionDetails(transaction.id!, false));
      dispatch(
        showSuccessToast(
          'Successfully updated the Address, MLS No. & Escrow No. information.',
        ),
      );
    } catch (e) {
      dispatch(showApiErrorModal(e));
      dispatch(
        showErrorToast(
          'We encountered an error while updating the Address, MLS No. & Escrow No. information. Please try again in a few moments.',
        ),
      );
      ErrorService.notify('Unable to update Address, MLS No. & Escrow No.', e);
    } finally {
      await dispatch(toggleEditTransactionAddressMlsEscrow(false));
    }
  };

  const isReferralTransaction =
    transaction.transactionType ===
    TransactionResponseTransactionTypeEnum.Referral;

  return (
    <ZenSidebarModalForm
      title='Edit Address, MLS No. & Escrow No.'
      subtitle={transaction.address?.oneLine}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Save'
      isSubmitting={isSubmitting}
    >
      <div className='mb-20'>
        <div className='flex flex-row items-center justify-between mb-5'>
          <p className='font-zen-title font-medium'>ADDRESS</p>
          <div>
            <ZenButton
              onClick={() => {
                setValue('isManualAddress', !isManualAddress);
              }}
              label={isManualAddress ? 'Search Address' : 'Enter manually'}
              variant='primary-outline'
              LeftIconComponent={
                <FontAwesomeIcon
                  icon={faArrowUpArrowDown}
                  className='text-primary-blue'
                />
              }
            />
          </div>
        </div>
        {isManualAddress && (
          <div className='space-y-4'>
            <div>
              <ZenControlledTextInput<FormData, 'street'>
                control={control}
                label='Street'
                name='street'
                shouldUnregister={false}
                placeholder='E.g. 270 1st Ave.'
                rules={{
                  required: 'Street is required',
                }}
                isRequired
              />
            </div>
            <div className='flex flex-row space-x-4'>
              <ZenControlledTextInput<FormData, 'street2'>
                control={control}
                label='Street Line 2'
                name='street2'
                shouldUnregister={false}
                placeholder='E.g. 270 1st Ave.'
              />
              <ZenControlledHTMLSelectInput<FormData, 'country'>
                name='country'
                control={control}
                label='Country'
                shouldUnregister={false}
                placeholder='Country'
                options={[
                  {
                    label: 'Select Country',
                    value: '',
                    disabled: true,
                  },
                  ...values(AdministrativeAreaResponseCountryEnum).map(
                    (country) => ({
                      value: country,
                      label: capitalizeEnum(country),
                    }),
                  ),
                ]}
                rules={{
                  required: 'Please select country',
                }}
                isRequired
              />
            </div>
            <div className='flex flex-row space-x-4'>
              <ZenControlledTextInput<FormData, 'city'>
                control={control}
                label='City'
                name='city'
                shouldUnregister={false}
                placeholder='Eg. New York'
                rules={{
                  required: 'City is required',
                }}
                isRequired
              />
              <ZenControlledStateOrProvinceInput<FormData, 'state'>
                name='state'
                control={control}
                shouldUnregister={false}
                setValue={setValue}
                selectedCountry={
                  (watch(
                    'country',
                  ) as unknown) as AdministrativeAreaRequestCountryEnum
                }
                rules={{
                  required: 'Please select state',
                }}
                isRequired
              />
            </div>
            <div className='flex flex-row space-x-4'>
              <ZenControlledTextInput<FormData, 'zip'>
                control={control}
                label='ZIP/Postal Code'
                name='zip'
                shouldUnregister={false}
                placeholder='E.g. 10001'
                rules={{
                  required: 'ZIP/Postal Code is required',
                }}
                isRequired
              />
              <ZenControlledTextInput<FormData, 'yearBuilt'>
                control={control}
                shouldUnregister={false}
                label='Year Built'
                name='yearBuilt'
                placeholder='Eg: 2020'
                rules={{
                  ...YEAR_VALIDATIONS,
                }}
                maxLength={4}
              />
            </div>
          </div>
        )}

        {!isManualAddress && (
          <div className='space-y-4'>
            <ZenControlledGoogleAutocompleteSearchInput<FormData, 'location'>
              control={control}
              shouldUnregister={false}
              name='location'
              label='Search Address'
              subLabel='(Source by Google)'
              placeholder='E.g. 1st St. New York, NY 10010'
              rules={{
                ...GOOGLE_AUTO_COMPLETE_VALIDATIONS,
              }}
              isRequired
            />
            <div className='grid grid-cols-2 gap-x-4'>
              <ZenControlledTextInput<FormData, 'yearBuilt'>
                control={control}
                label='Year Built'
                shouldUnregister={false}
                rules={{
                  ...YEAR_VALIDATIONS,
                }}
                name='yearBuilt'
                placeholder='Eg: 2020'
                maxLength={4}
              />
            </div>
          </div>
        )}

        <div className='flex flex-row space-x-4 mt-5 border-t pt-5'>
          <ZenControlledTextInput<FormData, 'mlsNumber'>
            control={control}
            label='MLS Number'
            name='mlsNumber'
            shouldUnregister={false}
            placeholder='E.g. 120001234AB'
            rules={{
              required: isReferralTransaction
                ? undefined
                : 'MLS number is required.',
            }}
            isRequired={!isReferralTransaction}
          />
          <ZenControlledTextInput<FormData, 'escrowNumber'>
            control={control}
            shouldUnregister={false}
            label='Escrow Number'
            name='escrowNumber'
            placeholder='E.g. 01-12345-ABC'
          />
        </div>
        {isFMLSFeeApplicable && (
          <div className='mt-5'>
            <div className='border divide-y rounded-[10px] p-2'>
              <ZenControlledToggleInput<FormData, 'propertyListedOnFmls'>
                name='propertyListedOnFmls'
                shouldUnregister={false}
                control={control}
                label='Property is listed in FMLS'
              />
            </div>
          </div>
        )}
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenEditTransactionAddressMlsEscrow;
