import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import TableLoader from '../components/TableLoader';
import ZenMilliDateCell from '../components/Zen/Table/Cell/ZenMilliDateCell';
import ZenTeamLeaderNameCell from '../components/Zen/Table/Cell/ZenTeamLeaderNameCell';
import ZenResourceTable, {
  ResourceTableVariant,
} from '../components/Zen/Table/ZenResourceTable';
import ZenTeamsCodeCell from '../components/Zen/Teams/ZenTeamsCodeCell';
import ZenTeamsNameCell from '../components/Zen/Teams/ZenTeamsNameCell';
import ZenTeamsType from '../components/Zen/Teams/ZenTeamsType';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenResourceContainer from '../components/Zen/ZenResourceContainer';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenTeamStatusCell from '../components/table/Cells/ZenTeamStatusCell';
import ZenViewActionButtonCell from '../components/table/Cells/ZenViewActionButtonCell';
import { TeamResponse, TeamResponseTypeEnum } from '../openapi/yenta';
import { getAgentAllTeamsDetailOverview } from '../slices/TeamSlice';
import { AppDispatch, RootState } from '../types';
import { isSmScreen } from '../utils/BrowserUtils';
import { getCombinedMembershipsTeam } from '../utils/TeamHelper';

interface ZenMyTeamsIndexRouteProps {}

const ZenMyTeamsIndexRoute: React.FC<ZenMyTeamsIndexRouteProps> = () => {
  const dispatch: AppDispatch = useDispatch();
  const [search, setSearch] = useState<string>();
  const [agentTeamsDetails, setAgentTeamsDetails] = useState<TeamResponse[]>(
    [],
  );
  const {
    auth: { userDetail },
    team: { loading },
  } = useSelector((state: RootState) => state);

  const teams = useMemo(() => {
    const allTeams = getCombinedMembershipsTeam(userDetail!);
    return allTeams;
  }, [userDetail]);

  const handleGetTeamsInfo = useCallback(
    async (teamIds) => {
      const data: TeamResponse[] | undefined = await dispatch(
        getAgentAllTeamsDetailOverview(teamIds),
      );
      if (!!data) {
        setAgentTeamsDetails(data);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const teamIds = teams.map((ele) => ele.team?.id!);
    handleGetTeamsInfo(teamIds);
  }, [handleGetTeamsInfo, teams]);

  const filteredTeams = useMemo(
    () =>
      agentTeamsDetails.filter((t) => {
        if (!search) {
          return true;
        }
        const teamName = t?.name?.toLocaleLowerCase();
        return teamName?.includes(search.toLocaleLowerCase());
      }),
    [search, agentTeamsDetails],
  );

  const columns: Column<TeamResponse>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        Cell: ({ value }) => (
          <Link to={`/teams/${value}`}>
            <ZenViewActionButtonCell />
          </Link>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
        Cell: ({ row }) => <ZenTeamsNameCell team={row.original} />,
      },
      {
        Header: 'Type',
        accessor: 'type',
        id: 'type',
        Cell: ({ value }) => (
          <ZenTeamsType type={value as TeamResponseTypeEnum} />
        ),
        cardColSize: 6,
      },
      {
        Header: 'Created On',
        accessor: 'createdAt',
        id: 'createdAt',
        Cell: ({ value }) => (
          <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
            <ZenMilliDateCell date={value} />
          </div>
        ),
        cardColSize: 6,
      },
      {
        Header: 'Leader',
        accessor: 'agents',
        Cell: ({ value }) => <ZenTeamLeaderNameCell agents={value!} />,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <ZenTeamStatusCell status={value!} />,
      },
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ row }) => (
          <ZenTeamsCodeCell team={row.original} variant='primary' />
        ),
      },
    ],
    [],
  );

  const tableInstance = useTable(
    {
      columns: columns,
      data: filteredTeams,
      initialState: {
        pageSize: 1000,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  return (
    <ZenRoute title='Teams'>
      <ZenPageLayoutWithSearch
        path={[
          { title: 'Home', url: '/' },
          { title: 'Teams', url: '/teams' },
        ]}
        search={search}
        onSearchChange={(val) => setSearch(val)}
      >
        <div className='px-4 lg:py-5'>
          <div className=' py-4 text-xl font-zen-body font-semibold'>Teams</div>
          <div className='py-4'>
            <ZenResourceContainer
              resourceName='team'
              loading={loading}
              isEmpty={isEmpty(filteredTeams)}
              LoaderComponent={<TableLoader />}
            >
              <ZenResourceTable<TeamResponse>
                {...tableInstance}
                resourceName='Team'
                variant={
                  isSmScreen()
                    ? ResourceTableVariant.CARD
                    : ResourceTableVariant.ROW
                }
                totalCount={filteredTeams?.length}
                hidePagination
                hidePageSize
                stickyHeader
              />
            </ZenResourceContainer>
          </div>
        </div>
      </ZenPageLayoutWithSearch>
    </ZenRoute>
  );
};

export default ZenMyTeamsIndexRoute;
