import { History } from 'history';
import qs from 'qs';
import config from '../config';
import { ContextualPrompt, FollowUp } from './LeoUtils';

export const isSmScreen = () => window.innerWidth <= 768;

export const navigateTo500ErrorPage = (history: History) =>
  history.push('/500');

export const getLeoResponseSteamUrl = (
  question: string,
  sessionId: string,
  followUps?: FollowUp[],
  helperPrompt?: ContextualPrompt,
) => {
  const params: { [key: string]: any } = {
    question,
    session_id: sessionId,
  };
  if (followUps && followUps?.length > 0) {
    params.follow_ups = JSON.stringify(followUps);
  }
  if (helperPrompt) {
    params.helper_prompt = JSON.stringify({
      title: helperPrompt.title,
      prompt: helperPrompt.prompt,
    });
  }
  const url = `${config.apiBasePath.leo}/chat/generate_stream?${qs.stringify(
    params,
  )}`;
  return url;
};
