import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AgentResponseAccountCountryEnum } from '../../../openapi/yenta';
import { TaxAndNationalIDFormFieldName } from '../../../testUtils/OnboardingUtils';
import { RootState } from '../../../types';
import AgentOnboardingLayout from '../../AgentOnboardingLayout';
import Button from '../../Button';
import ZenRoute from '../../Zen/ZenRoute';
import TaxAndNationalIDOnboardingFormSteps from './TaxAndNationalIDOnboardingFormSteps';

interface TaxAndNationalIDOnboardingFormProps {}

export type TaxAndNationalIDFormDataType = {
  [X in TaxAndNationalIDFormFieldName]: any;
};

const TaxAndNationalIDOnboardingForm: React.FC<TaxAndNationalIDOnboardingFormProps> = () => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [formData, setFormData] = useState<Partial<any>>({});
  const history = useHistory();

  const message =
    userDetail?.accountCountry === AgentResponseAccountCountryEnum.UnitedStates
      ? 'Please have your Social Security Number ready. If you do business via an entity, you will be asked to provide the EIN of the entity.'
      : 'Please have your GST Number ready. If you do business via an entity, you will be asked to provide the Business Number and tax information for the entity.';

  return (
    <ZenRoute title='Tax Information'>
      <AgentOnboardingLayout
        title='Tax Information'
        hideClose={userDetail?.needsTaxIdOnboarding}
        onClose={() => history.push('/')}
      >
        <div className='h-full flex flex-col justify-center items-center w-full max-w-md mx-auto'>
          {currentIndex === -1 ? (
            <div>
              <p className='text-xl font-primary-medium mb-4'>
                Hi, {userDetail?.fullName} 👋
              </p>
              <p className='mb-4'>
                We need you to provide some additional tax information.
              </p>
              <p className='mb-4'>{message}</p>
              <Button
                label="Let's Get Started"
                fullWidth
                className='!py-2'
                onClick={() => setCurrentIndex(0)}
              />
            </div>
          ) : (
            <TaxAndNationalIDOnboardingFormSteps
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              formData={formData}
              setFormData={setFormData}
            />
          )}
        </div>
      </AgentOnboardingLayout>
    </ZenRoute>
  );
};

export default TaxAndNationalIDOnboardingForm;
