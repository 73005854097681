import { faEnvelope, faPhone } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { AgentResponse } from '../../openapi/yenta';
import { formatPhoneNumber } from '../../utils/StringUtils';
import ZenAvatar from '../Zen/ZenAvatar';
import GeminiAgentStatusCell from './GeminiAgentStatusCell';
import GeminiIconText from './GeminiIconText';
import GeminiUserPill from './GeminiUserPill';

interface OfficeAgentsTableProps {
  agents: AgentResponse[];
}

const GeminiOfficeAgentsTable: React.FC<OfficeAgentsTableProps> = ({
  agents,
}) => {
  return (
    <div className='scrollbar overflow-x-scroll'>
      <table
        className='w-full break-inside-avoid font-zen-body text-sm text-zen-dark-9 md:table hidden'
        data-testid='medium-screen'
      >
        <thead>
          <tr className='border-y px-6 font-medium font-inter bg-primary-light text-primary-dark text-sm'>
            <th className='text-left font-medium px-6 py-3'>Agent</th>
            <th className='text-left font-medium px-6 py-3'>Division</th>
            <th className='text-left font-medium px-6 py-3'>Email Address</th>
            <th className='text-left font-medium px-6 py-3'>Phone Number</th>
            <th className='text-left font-medium px-6 py-3'>Agent Status</th>
          </tr>
        </thead>
        {agents.map((agent) => {
          const division = agent.divisions?.[0];

          return (
            <tbody key={agent.id}>
              <tr className=' text-sm text-primary-dark font-inter divide-y border-b'>
                <td className='py-5 px-6 text-left border-b'>
                  <Link to={`/people/${agent.id}`}>
                    <GeminiUserPill
                      name={`${agent.firstName} ${agent.lastName}`}
                      imageUrl={agent.avatar}
                      backgroundVariant='background'
                      fontWeight='font-normal'
                      size='sm'
                    />
                  </Link>
                </td>
                <td className='py-5 px-6 text-left'>
                  <div className='mr-2'>
                    {division ? (
                      <GeminiIconText
                        icon={
                          <div className='w-[28px] h-[20px]'>
                            <img
                              className='w-[28px] object-fill h-[20px]'
                              src={division.logoUrl}
                              alt='agent-division'
                            />
                          </div>
                        }
                        variant='small'
                        text={division.name || '--'}
                        textClassName='whitespace-pre font-light'
                      />
                    ) : (
                      <p className='text-primary-dark'>- - -</p>
                    )}
                  </div>
                </td>
                <td className='py-5 px-6 text-left font-light'>
                  <Link to={`/people/${agent.id}`}>{agent.emailAddress}</Link>
                </td>
                <td className='py-5 px-6 text-left font-light'>
                  {formatPhoneNumber(agent.phoneNumber)}
                </td>
                <td className='py-5 px-6 text-left'>
                  {agent.agentStatus ? (
                    <GeminiAgentStatusCell
                      status={agent.agentStatus!}
                      pillContainerStyle='w-[119px]'
                    />
                  ) : (
                    'N/A'
                  )}
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
      <div className='md:hidden block' data-testid='small-screen'>
        {agents.map((agent) => {
          const division = agent.divisions?.[0];
          return (
            <div
              key={agent.id}
              className='flex flex-row space-x-4 py-5 px-3 border-b border-gray-300'
            >
              <div className='w-[66px] flex flex-col items-center -space-y-1.5'>
                <Link to={`/people/${agent.id}`}>
                  <ZenAvatar
                    name={`${agent.firstName} ${agent.lastName}`}
                    size='xl'
                    imageUrl={agent.avatar!}
                  />
                </Link>
                {agent.agentStatus && (
                  <div className='z-10'>
                    <GeminiAgentStatusCell status={agent.agentStatus} />
                  </div>
                )}
              </div>
              <div className='flex flex-col space-y-2.5'>
                <p className='font-inter text-primary-dark font-medium'>{`${agent.firstName} ${agent.lastName}`}</p>
                {division && (
                  <GeminiIconText
                    icon={
                      <div className='w-[28px] h-[20px]'>
                        <img
                          className='w-[28px] object-fill h-[20px]'
                          src={division.logoUrl}
                          alt='agent-division'
                        />
                      </div>
                    }
                    variant='small'
                    text={division.name || '--'}
                    textClassName='whitespace-pre font-light'
                  />
                )}
                <GeminiIconText
                  text={formatPhoneNumber(agent.phoneNumber) || 'N/A'}
                  icon={
                    <FontAwesomeIcon
                      icon={faPhone}
                      className='text-zen-dark-9'
                    />
                  }
                  variant='small'
                  textClassName='whitespace-pre font-light text-zen-dark-9'
                />
                <GeminiIconText
                  text={agent.emailAddress || 'N/A'}
                  icon={
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className='text-zen-dark-9'
                    />
                  }
                  variant='small'
                  textClassName='whitespace-pre font-light text-zen-dark-9'
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GeminiOfficeAgentsTable;
