import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import ZenButton from '../../Zen/ZenButton';
import ZenSearchBar from '../../Zen/ZenSearchBar';

interface TemplateHeaderProps {
  title: string;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
  addButtonText: string;
  addButtonAction(): void;
  showBackIcon?: boolean;
  goBackRoute?: string;
}

const TemplateHeader: React.FC<TemplateHeaderProps> = ({
  title,
  search,
  setSearch,
  addButtonText,
  addButtonAction,
  goBackRoute,
  showBackIcon,
}) => {
  return (
    <div className='flex flex-col md:flex-row md:items-center justify-between bg-white py-4 gap-y-2 md:gap-y-0'>
      <div className='flex flex-row items-center gap-x-2'>
        {showBackIcon && (
          <Link to={goBackRoute}>
            <FontAwesomeIcon icon={faArrowLeftLong} className='mx-1' />
          </Link>
        )}
        <p className='font-zen-title text-xl text-black'>{title}</p>
      </div>
      <div className='flex md:flex-row flex-col md:items-center md:justify-end md:space-x-4 space-x-0 md:space-y-0 space-y-4 md:mt-0 mt-4 md:flex-grow md:flex-shrink'>
        <div className='md:w-72 flex flex-row justify-end'>
          <ZenSearchBar
            onChange={(text) => setSearch(text || undefined)}
            value={search || ''}
          />
        </div>
        <div className='self-end md:self-start'>
          <ZenButton
            label={addButtonText}
            variant='primary'
            LeftIconComponent={
              <FontAwesomeIcon icon={faPlus} size='lg' className='mr-1' />
            }
            onClick={addButtonAction}
          />
        </div>
      </div>
    </div>
  );
};

export default TemplateHeader;
