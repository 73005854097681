import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  GroupMemberResponse,
  GroupMemberResponseRoleEnum,
  OfficeResponseVoiceCallPriorityOrderEnum,
  UpdateOfficeRequestVoiceCallPriorityOrderEnum,
  VoiceCallHoldTimeGroupEnum,
} from '../../openapi/yenta';
import { updateOffice } from '../../slices/OfficeSlice';
import { AppDispatch, ISelectOption, RootState } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenControlledDndInput from '../Zen/Input/ZenControlledDndInput';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenSimpleModal from '../Zen/Modal/ZenSimpleModal';
import ZenButton from '../Zen/ZenButton';

enum HoldTimeSecsEnum {
  TEN = '10',
  FIFTEEN = '15',
  TWENTY = '20',
}

const DEFAULT_HOLD_TIME = 20;

const timesList = [
  { label: '10 Seconds', value: HoldTimeSecsEnum.TEN },
  { label: '15 Seconds', value: HoldTimeSecsEnum.FIFTEEN },
  { label: '20 Seconds', value: HoldTimeSecsEnum.TWENTY },
];

interface SetCallPriorityModalProps {
  onClose(): void;
  officeId: string;
  groups?: GroupMemberResponse[];
}

interface IFormData {
  callPriority: OfficeResponseVoiceCallPriorityOrderEnum[];
  holdTime: ISelectOption;
}

const SetCallPriorityModal: React.FC<SetCallPriorityModalProps> = ({
  onClose,
  officeId,
  groups,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { officeDetail } = useSelector((state: RootState) => state.office);
  const holdTimeSecs =
    officeDetail?.voiceCallHoldTimeSecs?.[0]?.holdTimeSecs || DEFAULT_HOLD_TIME;
  const defaultCallPriorityOrder = useMemo(() => {
    const roles = groups?.map((group) => group.role!);
    if (!roles?.length) {
      return [OfficeResponseVoiceCallPriorityOrderEnum.DesignatedBroker];
    }
    const arr = [
      OfficeResponseVoiceCallPriorityOrderEnum.DesignatedBroker,
      OfficeResponseVoiceCallPriorityOrderEnum.ManagingBroker,
      OfficeResponseVoiceCallPriorityOrderEnum.RegionalBroker,
    ].filter((role) =>
      roles.includes((role as unknown) as GroupMemberResponseRoleEnum),
    );
    return sortBy(
      arr,
      (a, b) =>
        roles.indexOf((a as unknown) as GroupMemberResponseRoleEnum) -
        roles.indexOf((b as unknown) as GroupMemberResponseRoleEnum),
    );
  }, [groups]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IFormData>({
    defaultValues: {
      callPriority:
        officeDetail?.voiceCallPriorityOrder ?? defaultCallPriorityOrder,
      holdTime: timesList.find((t) => t.value === holdTimeSecs?.toString()),
    },
  });

  const onSubmit = async (formData: IFormData) => {
    const isSuccess = await dispatch(
      updateOffice(officeId, {
        voiceCallPriorityOrder: (formData.callPriority as unknown) as UpdateOfficeRequestVoiceCallPriorityOrderEnum[],
        voiceCallHoldTimeSecs: [
          {
            group: VoiceCallHoldTimeGroupEnum.BrokerTeam,
            holdTimeSecs: +formData.holdTime.value,
          },
        ],
      }),
    );
    if (isSuccess) {
      onClose();
    }
  };

  return (
    <ZenSimpleModal
      isOpen
      title='Set Call Priority'
      onClose={onClose}
      overflowY={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='px-6 pt-4 pb-10'>
          <ZenControlledDndInput
            control={control}
            name='callPriority'
            formatValue={capitalizeEnum}
          />
          <div className='mt-6'>
            <ZenControlledSelectInput
              customClassName='border-grey-300'
              control={control}
              options={timesList}
              name='holdTime'
              label='Select Maximum Waiting Time for Agent'
            />
          </div>
        </div>
        <div className='rounded-b-xl bottom-0 left-0 right-0  w-full bg-grey-100 py-4 flex justify-center'>
          <ZenButton
            label='Save'
            type='submit'
            className='!px-12 !py-1.5'
            isDisabled={isSubmitting}
          />
        </div>
      </form>
    </ZenSimpleModal>
  );
};

export default SetCallPriorityModal;
