import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { BoardResponse } from '../../../openapi/yenta';
import { isSmScreen } from '../../../utils/BrowserUtils';
import ZenViewActionButtonCell from '../../table/Cells/ZenViewActionButtonCell';
import AddressStateEnumSelectColumnFilter from '../../table/Filters/AddressStateEnumSelectColumnFilter';
import BoardResponseStatusEnumSelectColumnFilter from '../../table/Filters/BoardResponseStatusEnumSelectColumnFilter';
import TextColumnFilter from '../../table/Filters/TextColumnFilter';
import TextStrictCaseColumnFilter from '../../table/Filters/TextStrictCaseColumnFilter';
import ZenResourceIndexContainer from '../Containers/ZenResourceIndexContainer';
import ZenBoardStatusPill from '../Table/Cell/ZenBoardStatusPill';
import ZenDateCell from '../Table/Cell/ZenDateCell';
import { AppDispatch, RootState } from '../../../types';
import { fetchBoards } from '../../../slices/BoardSlice';

interface ZenBoardResourceTableProps {
  search?: string;
  RightComponent?: React.ReactElement;
}

export const columns: Array<Column<BoardResponse>> = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Logo',
    accessor: 'logo',
    Cell: ({ value }) =>
      value ? <img src={value} className='h-12' alt='logo' /> : 'N/A',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Code',
    accessor: 'code',
    Filter: TextColumnFilter,
    cardColSize: 4,
  },
  {
    Header: 'State',
    accessor: 'administrativeArea',
    id: 'administrativeArea:id',
    Filter: AddressStateEnumSelectColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => <ZenBoardStatusPill status={value!} />,
    Filter: BoardResponseStatusEnumSelectColumnFilter,
    cardColSize: 4,
  },
  {
    Header: 'Joined Date',
    accessor: 'joinDate',
    Cell: ({ value }) => <ZenDateCell date={value} />,
    cardColSize: 4,
    disableFilters: true,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => value,
    Filter: TextStrictCaseColumnFilter,
  },
];

const columnsWithAction: Array<Column<BoardResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<BoardResponse> }) => (
      <Link to={`/boards/${row.original.id}`}>
        <ZenViewActionButtonCell />
      </Link>
    ),
  },
  ...columns,
];

const ZenBoardResourceTable: React.FC<ZenBoardResourceTableProps> = ({
  search,
  RightComponent,
}) => {
  const {
    auth: { isAdmin, isBroker },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch<AppDispatch>();

  const modifiedColumns = useMemo(() => {
    return columnsWithAction?.map((column) => {
      if (column?.id === 'administrativeArea:id' && isBroker) {
        return {
          ...column,
          disableFilters: true,
        };
      }
      return column;
    });
  }, [isBroker]);

  return (
    <div className='px-4 lg:pt-5'>
      <ZenResourceIndexContainer<BoardResponse>
        header='Board'
        search={search}
        columns={modifiedColumns}
        initialSort={{ name: 'asc' }}
        fetchData={async (req, cancelToken) => {
          const res = await dispatch(fetchBoards(req, { cancelToken }));
          return {
            data: res?.results || [],
            total: res?.totalCount || 0,
          };
        }}
        RightComponent={isAdmin ? RightComponent : undefined}
        resourceName='Board'
        showSearch={isSmScreen()}
        hiddenColumns={['administrativeArea:id']}
        stickyHeader
      />
    </div>
  );
};

export default ZenBoardResourceTable;
