import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

const useDidUpdateEffect = (fn: EffectCallback, deps?: DependencyList) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    } else {
      didMountRef.current = true;
    }
  }, deps);
};

export default useDidUpdateEffect;
