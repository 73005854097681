import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryStack,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { every, isEmpty, keys } from 'lodash';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import { compactNumber } from '../../utils/MathUtils';
import { displayAmount } from '../../utils/CurrencyUtils';
import { RootState } from '../../types';
import { MoneyValueCurrencyEnum } from '../../openapi/bff';

export const EARNING_GRAPH_MONTHS = 8;

const COLORS: string[] = ['#3B82F6', '#BFC3CA'];

const ChartWrapper = styled.div`
  svg {
    overflow: visible;
  }
`;

type GraphPoint = {
  x: string;
  y: number;
};

interface RevShareInsightsEarningsGraphProps {}

const RevShareInsightsEarningsGraph: React.FC<RevShareInsightsEarningsGraphProps> = () => {
  const {
    auth: { userDetail },
    revenue: { revshareHistoryResponse },
  } = useSelector((state: RootState) => state);

  const [isPopOverOpen, setIsPopOverOpen] = useState(false);

  const revshareHistory = useMemo(
    () => revshareHistoryResponse.data?.revshareHistory || [],
    [revshareHistoryResponse.data?.revshareHistory],
  );

  const {
    earningsData,
    missedOpportunitiesData,
    currMonthMissedOpportunities,
  } = useMemo(() => {
    const map: Record<
      string,
      { earned: number; missed: number } | undefined
    > = {};

    // prefill graph with 0 values
    const months = new Array(EARNING_GRAPH_MONTHS).fill(0);
    months.forEach((_, index) => {
      const month = DateTime.now().minus({ months: index }).toFormat('LLL');
      map[month] = { earned: 0, missed: 0 };
    });

    revshareHistory.forEach((item) => {
      const month = DateTime.fromISO(item.payoutDate!).toFormat('LLL');
      const prev = map[month];
      const earned = (prev?.earned || 0) + (item.amount?.amount || 0);
      const missed = (prev?.missed || 0) + (item.missedAmount?.amount || 0);
      map[month] = { earned, missed };
    });

    const xLabels = keys(map);
    const earningsData = new Array(xLabels.length) as GraphPoint[];
    const missedOpportunitiesData = new Array(xLabels.length) as GraphPoint[];
    const currMonth = DateTime.now().toFormat('LLL');
    const currMonthMissedOpportunities = map[currMonth]?.missed || 0;

    keys(map).forEach((data, index) => {
      earningsData[index] = {
        x: data,
        y: map[data]?.earned || 0,
      };
      missedOpportunitiesData[index] = {
        x: data,
        y: map[data]?.missed || 0,
      };
    });

    return {
      earningsData,
      missedOpportunitiesData,
      currMonthMissedOpportunities,
    };
  }, [revshareHistory]);

  const isEarningGraphEmpty = isEmpty(revshareHistory);
  const isMissedOpportunitiesGraphEmpty = every(
    missedOpportunitiesData,
    (y) => y.y === 0,
  );

  const currency =
    revshareHistory[0]?.amount?.currency ||
    ((userDetail?.defaultCurrency as unknown) as MoneyValueCurrencyEnum);

  return (
    <div className='font-zen-body space-y-1 px-4 py-4 border border-zen-dark-4 rounded-[10px]'>
      <div className='flex items-center justify-end space-x-3.5 pr-5 font-zen-body text-sm text-zen-dark-8'>
        {!isEarningGraphEmpty && (
          <div className='flex items-center space-x-1'>
            <div className='w-3 h-3 rounded-sm bg-primary-blue' />
            <p>Earned</p>
          </div>
        )}
        {!isMissedOpportunitiesGraphEmpty && (
          <div className='flex items-center space-x-1'>
            <div className='w-3 h-3 rounded-sm bg-zen-dark-5' />
            <p>
              Missed opportunities
              <Popover
                isOpen={isPopOverOpen}
                positions={['bottom', 'left', 'right', 'top']}
                padding={5}
                align='center'
                onClickOutside={() => setIsPopOverOpen(false)}
                content={({ position, childRect, popoverRect }) => {
                  return (
                    <ArrowContainer
                      position={position}
                      popoverRect={popoverRect}
                      childRect={childRect}
                      arrowColor='white'
                      arrowSize={10}
                      className='popover-arrow-container drop-shadow-lg'
                    >
                      <div className='bg-white py-3 px-4 rounded-md font-zen-body text-sm text-zen-dark-9'>
                        Amount produced by agents in the locked tiers*
                      </div>
                    </ArrowContainer>
                  );
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className='text-zen-dark-9 ml-1 cursor-pointer'
                  onClick={() => setIsPopOverOpen(true)}
                />
              </Popover>
            </p>
          </div>
        )}
      </div>
      <div className='-mx-4 xl:mx-0 max-w-4xl'>
        <ChartWrapper>
          <VictoryChart
            domainPadding={{ x: 30 }}
            theme={VictoryTheme.grayscale}
            width={800}
            height={220}
            containerComponent={
              <VictoryVoronoiContainer
                voronoiDimension='x'
                labels={({ datum }) => {
                  if (datum.y === 0) {
                    return '';
                  }
                  if (datum._stack === 2) {
                    return `Missed opportunities: $${datum.y}`;
                  }
                  return `Earned: $${datum.y}`;
                }}
                labelComponent={
                  <VictoryTooltip
                    flyoutPadding={{
                      top: 5,
                      bottom: 5,
                      left: 10,
                      right: 20,
                    }}
                    flyoutStyle={{
                      fill: '#FFFFFF',
                      stroke: '#F5F6F8',
                      filter: 'drop-shadow(3px 3px 2px rgba(0, 0, 0, .24))',
                    }}
                  />
                }
              />
            }
          >
            <VictoryAxis
              dependentAxis
              style={{
                axis: { stroke: '#BFC3CA' },
                tickLabels: {
                  fill: '#BFC3CA',
                  fontSize: 9,
                  fontFamily: 'Open Sans',
                },
              }}
              tickFormat={(tick, index) => {
                if (isEarningGraphEmpty) {
                  return `${currency} ${100 * (index + 1)}`;
                }
                return `${currency} ${compactNumber(tick, null)}`;
              }}
            />
            <VictoryAxis
              style={{
                axis: { stroke: '#BFC3CA' },
                tickLabels: {
                  textTransform: 'uppercase',
                  fontSize: 10,
                  fontFamily: 'Open Sans',
                  fill: (value: any) => {
                    return value.index > 0 ? '#BFC3CA' : '#3B82F6';
                  },
                },
              }}
            />
            <VictoryStack colorScale={COLORS}>
              <VictoryBar
                barRatio={0.65}
                data={earningsData}
                cornerRadius={{
                  top: ({ index }) =>
                    missedOpportunitiesData[index as number]?.y === 0 ? 4 : 0,
                }}
                style={{
                  labels: {
                    fill: '#3E3F3C',
                    fontSize: 12,
                    fontFamily: 'Open Sans',
                    textAnchor: 'left',
                    fontWeight: 600,
                  },
                }}
              />
              <VictoryBar
                barRatio={0.656}
                cornerRadius={{ top: 4 }}
                data={missedOpportunitiesData}
                style={{
                  labels: {
                    fill: '#3E3F3C',
                    fontSize: 12,
                    fontFamily: 'Open Sans',
                    textAnchor: 'left',
                  },
                }}
              />
            </VictoryStack>
          </VictoryChart>
        </ChartWrapper>
        {!!currMonthMissedOpportunities && (
          <p
            className='md:ml-4 text-primary-blue font-zen-body font-normal text-sm'
            aria-label='current-missed-opportunity'
          >
            <FontAwesomeIcon icon={faCircleInfo} />
            <span className='ml-1'>
              You are missing opportunity of{' '}
              {displayAmount(
                {
                  amount: currMonthMissedOpportunities,
                  currency:
                    revshareHistory[0]?.amount?.currency ||
                    ((userDetail?.defaultCurrency as unknown) as MoneyValueCurrencyEnum),
                },
                { hideZeroCents: true },
              )}{' '}
              in current month.
              <span> Unlock tiers, earn more!</span>
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default RevShareInsightsEarningsGraph;
