import { faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface ApplicationNoteProps {}

const ApplicationNote: React.FC<ApplicationNoteProps> = () => {
  return (
    <div>
      <div className='border border-grey-200 rounded-lg mx-4 p-4 font-inter'>
        <div className='flex flex-row items-center justify-between'>
          <p className='text-base text-primary-dark font-medium'>
            Application Note
          </p>
          <FontAwesomeIcon
            icon={faPen}
            className='text-primary-dark p-2 bg-regent-300 rounded-full'
          />
        </div>
        <p className='text-base text-primary-dark font-light pt-4'>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
          exercitationem quibusdam unde commodi nemo consequatur earum
          perspiciatis magni officiis, aspernatur odio voluptate necessitatibus
          incidunt vitae fugiat? Dolore asperiores et error!
        </p>
      </div>
    </div>
  );
};

export default ApplicationNote;
