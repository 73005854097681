import { values } from 'lodash';
import { useEffect, useState } from 'react';
import {
  PaymentMethodResponse,
  PaymentMethodResponsePurposeEnum,
} from '../../openapi/yenta';
import BankAccountTabDetails from '../../routes/BankAccountTabDetails';
import ZenTabs from './ZenTabs';

interface Props {
  agentId: string;
  bankAccountData: PaymentMethodResponse[];
}

const ZenBankAccountTabs: React.FC<Props> = ({ agentId, bankAccountData }) => {
  const tabs = values(bankAccountData).map((data: PaymentMethodResponse) => {
    let purpose =
      data.purpose! === PaymentMethodResponsePurposeEnum.Transaction
        ? 'Commission'
        : data.purpose! === PaymentMethodResponsePurposeEnum.Revshare
        ? 'Revenue Share'
        : 'All Payments';

    return {
      name: purpose,
      TabComponent: <BankAccountTabDetails data={data!} agentId={agentId} />,
    };
  });

  let newTabs = [];
  for (let i = 0; i < tabs.length; i++) {
    if (tabs[i].name === 'All Payments') {
      newTabs.push(tabs[i]);
    }
  }
  const tabName = newTabs.length
    ? newTabs[0].name.toString()
    : tabs[0].name.toString();
  const [taxTab, setTaxTab] = useState(tabName);

  useEffect(() => {
    // set active tab after removing/adding the form
    setTaxTab(tabName);
  }, [bankAccountData, tabName]);

  return (
    <div>
      <ZenTabs
        tabs={newTabs.length ? newTabs : tabs}
        selected={taxTab}
        onChange={setTaxTab}
        size='sm'
      />
    </div>
  );
};

export default ZenBankAccountTabs;
