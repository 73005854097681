import { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import {
  getFlexTeamDetailOverview,
  removeFlexTeamMember,
} from '../../../../slices/TeamSlice';
import { isSmScreen } from '../../../../utils/BrowserUtils';
import { getParticipantName } from '../../../../utils/ParticipantHelper';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenConfirmationModal from '../../Modal/ZenConfirmationModal';
import { FlexTeamRoles } from '../../../../types';
import { TeamMemberDto } from './ZenEditProTeamMember';

interface ZenDeleteProTeamMemberModalProps {
  teamId: string;
  memberToDelete: TeamMemberDto;
  onClose(): void;
  setMemberToDelete(): void;
  refetch(): void;
}

interface DeleteTeamFormData {
  confirm: string;
}

const ZenDeleteProTeamMemberModal: React.FC<ZenDeleteProTeamMemberModalProps> = ({
  teamId,
  memberToDelete,
  onClose,
  setMemberToDelete,
  refetch,
}) => {
  const { control, watch } = useForm<DeleteTeamFormData>();

  const CONFIRMATION_STRING_REQUIRED = 'CONFIRM';
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const watchConfirm = watch('confirm');
  const isDisabled = watchConfirm?.trim() !== CONFIRMATION_STRING_REQUIRED;
  const memberId = memberToDelete.roles.includes(FlexTeamRoles.Leader)
    ? memberToDelete?.leaderDetails?.id!
    : memberToDelete?.teammateDetails?.id!;
  return (
    <ZenConfirmationModal
      isOpen={!!memberToDelete}
      variant='danger'
      title={`Are you sure you want to remove ${getParticipantName(
        memberToDelete?.agent,
      )}?`}
      subtitle={
        <div className='flex-col'>
          <div className='text-zen-dark-9 font-zen-body'>
            You can always add them back.
          </div>
          <div className='mt-2 flex justify-center'>
            <div className='w-1/2'>
              <ZenControlledTextInput
                control={control}
                name='confirm'
                type='text'
                placeholder={`Please type ${CONFIRMATION_STRING_REQUIRED}`}
              />
            </div>
          </div>
        </div>
      }
      onClose={setMemberToDelete}
      onConfirm={async () => {
        setLoading(true);
        await dispatch(removeFlexTeamMember(teamId, memberId));
        await dispatch(getFlexTeamDetailOverview(teamId));
        await refetch();
        setLoading(false);
        setMemberToDelete();
        onClose();
      }}
      isSubmitting={loading}
      isDisabled={isDisabled}
      confirmButtonText='Remove Member'
      size='large'
      hideIcon={isSmScreen()}
      confirmButtonLeftIcon={
        <FaRegTrashAlt fontSize={16} className='mr-1 mb-0.5' />
      }
    />
  );
};

export default ZenDeleteProTeamMemberModal;
