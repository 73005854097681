import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faTriangleExclamation,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { ToastNotificationData } from '../types';

export interface ToastNotificationProps extends ToastNotificationData {
  onClose(): void;
}

const ToastNotification: React.FC<ToastNotificationProps> = ({
  type,
  title,
  subtitle,
  onClose,
}) => {
  return (
    <div className='flex items-start bg-dark rounded text-white p-4 w-full max-w-md'>
      <div className='mr-2'>
        {type === 'success' && (
          <FontAwesomeIcon
            icon={faCircleCheck}
            className='text-success'
            size='lg'
          />
        )}
        {type === 'warning' && (
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className='text-warning'
            size='lg'
          />
        )}
        {type === 'info' && (
          <FontAwesomeIcon
            icon={faCircleInfo}
            className='text-primary'
            size='lg'
          />
        )}
        {type === 'error' && (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className='text-error'
            size='lg'
          />
        )}
      </div>
      <div className='flex-grow'>
        <p className='font-primary-regular'>{title}</p>
        {subtitle && <p className='font-primary-light'>{subtitle}</p>}
      </div>
      <button
        type='button'
        onClick={onClose}
        className='cursor-pointer'
        data-testid='close-toast-notification'
      >
        <FontAwesomeIcon icon={faXmark} size='lg' />
      </button>
    </div>
  );
};

export default ToastNotification;
