import { last } from 'lodash';
import { DateTime } from 'luxon';
import qs from 'qs';
import { FiExternalLink } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import config from '../../../config';
import {
  AgentResponse,
  AgentResponseAccountCountryEnum,
} from '../../../openapi/yenta';
import { RootState } from '../../../types';
import { getBaseStripeUrl } from '../../../utils/StripeUtils';
import AdminOnly from '../../auth/AdminOnly';
import AdminOrSuperAdmin from '../../auth/AdminOrSuperAdmin';
import SelfOnly from '../../auth/SelfOnly';
import GeminiCopyToClipboard from '../GeminiCopytoClipboard';
import GeminiAgentWebsiteHeader from './GeminiAgentWebsiteHeader';
import GeminiProfileHeaderRowCell from './GeminiProfileHeaderRowCell';

interface GeminiIdentityInfoHeaderDetailsProps {
  detail: AgentResponse | undefined;
}

export const Divider = () => (
  <div className='h-4.5 w-[1px] bg-grey-200 mx-1.5 sm:block hidden' />
);

const GeminiIdentityInfoHeaderDetails: React.FC<GeminiIdentityInfoHeaderDetailsProps> = ({
  detail,
}) => {
  const { isAdmin } = useSelector((state: RootState) => state.auth);
  const referralLink = `${config.reactAppHostUrl}/register?${qs.stringify({
    sponsorCode: detail!.sponsorCode,
    sponsorName: `${detail!.firstName} ${detail!.lastName}`,
  })}`;

  const isUsAgent =
    detail?.accountCountry !== AgentResponseAccountCountryEnum.Canada;

  return (
    <div className='px-5 py-2.5 rounded-lg bg-regent-900 font-zen-body-2 text-white space-y-2.5'>
      <div className='flex flex-col gap-1 sm:flex-row sm:items-center sm:gap-1'>
        <GeminiProfileHeaderRowCell
          label='License:'
          value={
            !!detail?.licenses?.length ? last(detail?.licenses)?.number : 'N/A'
          }
        />
        <div className='hidden sm:block h-1 w-1 bg-grey-400 mx-1.5 rounded-full' />
        <GeminiProfileHeaderRowCell
          label='Expires:'
          value={
            !!detail?.licenses?.length
              ? DateTime.fromISO(
                  `${last(detail?.licenses)?.expirationDate}`,
                ).toFormat('MM/dd/yyyy')
              : 'N/A'
          }
        />
      </div>
      <AdminOrSuperAdmin>
        <GeminiProfileHeaderRowCell
          label='Real ID:'
          value={`#${detail?.id}`}
          rightComponent={
            <GeminiCopyToClipboard
              label='Copy'
              value={detail?.id!}
              className='ml-1.5'
            />
          }
        />
        <GeminiProfileHeaderRowCell
          label='Keymaker ID:'
          value={`#${detail?.keyMakerId}`}
          rightComponent={
            <GeminiCopyToClipboard
              label='Copy'
              value={detail?.keyMakerId!}
              className='ml-1.5'
            />
          }
        />
        <div className='flex flex-col gap-1 sm:flex-row sm:items-center sm:gap-1'>
          <GeminiProfileHeaderRowCell
            label='Slug:'
            value={detail?.slug}
            rightComponent={
              <GeminiCopyToClipboard
                label='Copy Slug'
                value={detail?.slug!}
                className='ml-1.5'
              />
            }
          />
          <AdminOnly>
            <Divider />
            <GeminiCopyToClipboard
              label='Copy Sponsor Link'
              value={referralLink}
            />
            {!!detail?.stripeCustomerId && (
              <>
                <Divider />
                <a
                  href={`${getBaseStripeUrl()}/customers/${
                    detail?.stripeCustomerId
                  }`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='flex items-center font-zen-body-2 text-sm leading-4 font-medium text-avatar-blue-200 space-x-1'
                >
                  <FiExternalLink className='text-sm' />
                  <span>Stripe Acct.</span>
                </a>
              </>
            )}
          </AdminOnly>
        </div>
      </AdminOrSuperAdmin>
      <div className='flex flex-col gap-1 sm:flex-row sm:items-center sm:gap-1'>
        <GeminiProfileHeaderRowCell
          label='JoinReal Email:'
          value={detail?.joinRealEmail ?? 'N/A'}
          rightComponent={
            isUsAgent && isAdmin ? (
              <GeminiAgentWebsiteHeader detail={detail} />
            ) : undefined
          }
        />
      </div>
      {!isAdmin && (
        <SelfOnly agentId={detail?.id}>
          <div className='flex flex-row items-center space-x-1.5'>
            <GeminiCopyToClipboard
              label='Copy Sponsor Link'
              value={referralLink}
            />
            {isUsAgent ? (
              <GeminiAgentWebsiteHeader detail={detail} />
            ) : undefined}
          </div>
        </SelfOnly>
      )}
    </div>
  );
};
export default GeminiIdentityInfoHeaderDetails;
