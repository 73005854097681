import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { LeadLoanDtoStatusEnum } from '../../openapi/atlantis';
import { EnumMap } from '../../types';
import { cn } from '../../utils/classUtils';
import { safeEnumMapGet } from '../../utils/EnumHelper';
import { capitalizeEnum } from '../../utils/StringUtils';

type MortgageStatusVariantType =
  | 'default'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'secondary-warning'
  | 'purple';

export const variantToClassNameMap: Partial<
  EnumMap<MortgageStatusVariantType, string>
> = {
  default: 'bg-white text-black',
  primary: 'bg-regent-200 text-primary-blue',
  success: 'bg-green-100 text-green-600',
  warning: 'bg-zen-warning-1 text-zen-warning-dark',
  danger: 'bg-zen-danger-light text-error',
  purple: 'bg-zen-purple-light text-zen-purple-dark',
  'secondary-warning': 'bg-zen-warning-2 text-zen-warning-3',
};

export const statusToVariant: EnumMap<
  LeadLoanDtoStatusEnum,
  MortgageStatusVariantType
> = {
  [LeadLoanDtoStatusEnum.Created]: 'warning',
  [LeadLoanDtoStatusEnum.InvitationSent]: 'primary',
  [LeadLoanDtoStatusEnum.AccountCreated]: 'warning',
  [LeadLoanDtoStatusEnum.ApplicationInProgress]: 'secondary-warning',
  [LeadLoanDtoStatusEnum.ApplicationSubmitted]: 'purple',
  [LeadLoanDtoStatusEnum.LoanPreApproved]: 'success',
  [LeadLoanDtoStatusEnum.SubmittedToLender]: 'primary',
  [LeadLoanDtoStatusEnum.Underwriting]: 'warning',
  [LeadLoanDtoStatusEnum.ConditionalApproval]: 'secondary-warning',
  [LeadLoanDtoStatusEnum.ClearToClose]: 'purple',
  [LeadLoanDtoStatusEnum.Closing]: 'success',
  [LeadLoanDtoStatusEnum.Funding]: 'success',
};

export interface MortgageStatusProps {
  status: LeadLoanDtoStatusEnum;
}

const MortgageStatus: React.FC<MortgageStatusProps> = ({ status }) => {
  const mortgageStatusIconMap: EnumMap<
    LeadLoanDtoStatusEnum,
    React.ReactElement
  > = {
    [LeadLoanDtoStatusEnum.Created]: (
      <FontAwesomeIcon icon={regular('user-check')} />
    ),
    [LeadLoanDtoStatusEnum.AccountCreated]: (
      <FontAwesomeIcon icon={regular('user-check')} />
    ),
    [LeadLoanDtoStatusEnum.ApplicationInProgress]: (
      <FontAwesomeIcon icon={regular('loader')} />
    ),
    [LeadLoanDtoStatusEnum.ApplicationSubmitted]: (
      <FontAwesomeIcon icon={regular('file-check')} />
    ),
    [LeadLoanDtoStatusEnum.InvitationSent]: (
      <FontAwesomeIcon icon={regular('envelope')} />
    ),
    [LeadLoanDtoStatusEnum.LoanPreApproved]: (
      <FontAwesomeIcon icon={regular('circle-check')} />
    ),
    [LeadLoanDtoStatusEnum.SubmittedToLender]: (
      <FontAwesomeIcon icon={regular('file-import')} />
    ),
    [LeadLoanDtoStatusEnum.Underwriting]: (
      <FontAwesomeIcon icon={regular('list-check')} />
    ),
    [LeadLoanDtoStatusEnum.ConditionalApproval]: (
      <FontAwesomeIcon icon={regular('stamp')} />
    ),
    [LeadLoanDtoStatusEnum.ClearToClose]: (
      <FontAwesomeIcon icon={regular('timer')} />
    ),
    [LeadLoanDtoStatusEnum.Closing]: (
      <FontAwesomeIcon icon={regular('signature')} />
    ),
    [LeadLoanDtoStatusEnum.Funding]: (
      <FontAwesomeIcon icon={regular('dollar-sign')} />
    ),
  };

  const variant = safeEnumMapGet(statusToVariant, status, 'default');

  const icon = safeEnumMapGet(
    mortgageStatusIconMap,
    status,
    mortgageStatusIconMap[LeadLoanDtoStatusEnum.AccountCreated],
  );

  const isBGTransparent = [
    LeadLoanDtoStatusEnum.Created,
    LeadLoanDtoStatusEnum.InvitationSent,
    LeadLoanDtoStatusEnum.AccountCreated,
    LeadLoanDtoStatusEnum.ApplicationInProgress,
    LeadLoanDtoStatusEnum.ApplicationSubmitted,
    LeadLoanDtoStatusEnum.LoanPreApproved,
  ].includes(status);

  return (
    <div className='flex items-center justify-between'>
      <div
        className={cn(
          'w-max flex items-center space-x-1.5 flex-shrink-0 py-1.5 px-2 mr-1 rounded-full md:whitespace-nowrap',
          variantToClassNameMap[variant],
          { '!bg-transparent': isBGTransparent },
        )}
      >
        {icon}
        <span className='-mt-0.5 font-zen-body text-sm font-medium'>
          {capitalizeEnum(status)}
        </span>
      </div>
    </div>
  );
};

export default MortgageStatus;
