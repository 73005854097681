import { capitalize, flatten, uniq } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import {
  CheckDepositDtoTypeEnum,
  EscrowResponseEscrowTypeEnum,
  ExternalCheckDepositsControllerApi,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { cn } from '../../../utils/classUtils';
import { fetchAgentsInfo } from '../../../slices/UserIdsSlice';
import { AppDispatch, RootState } from '../../../types';
import { isTransactionReadOnly } from '../../../utils/TransactionHelper';
import IconButton from '../../IconButton';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ErrorService from '../../../services/ErrorService';
import ZenSimpleModal from '../../Zen/Modal/ZenSimpleModal';
import ZenButton from '../../Zen/ZenButton';
import ZenExternalDepositLinkSidebar from './ZenExternalTrustDepositLinkSidebar';

interface ZenExternalDepositLinkProps {
  transaction: TransactionResponse;
  depositType: CheckDepositDtoTypeEnum;
}

export const ZenExternalDepositLink: React.FC<ZenExternalDepositLinkProps> = ({
  transaction,
  depositType,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [externalDepositLink, setExternalDepositLink] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const dispatch: AppDispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const deposits = useMemo(
    () =>
      transaction.escrows?.filter(
        (escrow) =>
          escrow.escrowType === EscrowResponseEscrowTypeEnum.TrustDeposit,
      ) || [],
    [transaction.escrows],
  );

  const agentIds = useMemo(
    () =>
      uniq(
        flatten(
          deposits.map((desposit) =>
            (desposit.deposits || []).map((d) => d.confirmedBy!),
          ),
        ).filter((deposit) => !!deposit),
      ),
    [deposits],
  );

  useEffect(() => {
    if (agentIds && agentIds.length > 0) {
      dispatch(fetchAgentsInfo(agentIds));
    }
  }, [agentIds, dispatch]);

  const isTransactionDisabled = isTransactionReadOnly(
    transaction,
    userDetail!,
    { skipBrokerCheck: true },
  );

  const fetchExternalLink = useCallback(
    async (transactionId: string) => {
      try {
        const { data } = await new ExternalCheckDepositsControllerApi(
          getArrakisConfiguration(),
        ).getExternalDepositLink(transactionId, depositType);
        setExternalDepositLink(data);
      } catch (e) {
        setShowModal(false);
        setExternalDepositLink(undefined);
        const errorMessages = Object.values(e.response?.data ?? [])
          .map((d: any) => d?.message)
          .filter(Boolean);
        const errors = errorMessages.join('\n');
        if (errors) {
          setErrorMessage(errors);
        }
        ErrorService.notify('Error fetching external deposit link', e, {
          transaction: { transactionId },
        });
      }
    },
    [depositType],
  );

  return (
    <>
      <IconButton
        label={`Get External ${capitalize(depositType)} Deposit Link`}
        buttonStyle={cn(
          '!ring-1 !border-1 px-2 py-1',
          isTransactionDisabled
            ? 'hover:bg-gray-600/10 hover:ring-gray-600/10 !hover:border-gray-600'
            : 'hover:bg-zen-primary/10 hover:ring-zen-primary/10 !hover:border-primary-blue',
        )}
        textStyle={
          isTransactionDisabled ? '!text-gray-400' : '!text-primary-blue'
        }
        variant='no-outline'
        leftIcon={
          <FontAwesomeIcon
            icon={faLink}
            className={
              isTransactionDisabled ? 'text-gray-400' : 'text-primary-blue mb-1'
            }
          />
        }
        onClick={() => {
          setShowModal(true);
          fetchExternalLink(transaction.id!);
        }}
        disabled={isTransactionDisabled}
      />

      <ZenExternalDepositLinkSidebar
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        transaction={transaction}
        depositType={depositType}
        externalLink={externalDepositLink}
      />
      <ZenSimpleModal
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(undefined)}
        title={`External ${capitalize(depositType)} Deposit Link`}
      >
        <div className='p-5 text-center'>{errorMessage}</div>
        <div className='flex p-5 justify-center items-center'>
          <ZenButton label='Close' onClick={() => setErrorMessage(undefined)} />
        </div>
      </ZenSimpleModal>
    </>
  );
};
