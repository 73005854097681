import {
  TaxDocumentResponse,
  TaxDocumentResponseTaxDocumentStatusEnum,
} from '../openapi/arrakis';
import { AgentResponseAccountCountryEnum } from '../openapi/yenta';
import { EnumMap } from '../types';
import { safeEnumMapGet } from './EnumHelper';

export const isTaxDocumentNew = (
  taxDocument: TaxDocumentResponse | undefined | null,
): boolean => {
  return (
    taxDocument?.taxDocumentStatus ===
      TaxDocumentResponseTaxDocumentStatusEnum.Active &&
    taxDocument.revision === 0
  );
};

export const isTaxDocumentAmended = (
  taxDocument: TaxDocumentResponse | undefined | null,
): boolean => {
  return taxDocument?.revision !== 0;
};

export const getTaxDocumentName = (
  country: AgentResponseAccountCountryEnum,
): string => {
  const taxDocumentNameMap: EnumMap<AgentResponseAccountCountryEnum, string> = {
    CANADA: 'T4A',
    UNITED_STATES: '1099',
  };

  return safeEnumMapGet<AgentResponseAccountCountryEnum, string>(
    taxDocumentNameMap,
    country,
    'Tax Document',
  );
};
