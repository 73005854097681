interface CollapsibleTableRowItemProps {
  name: string;
  value: React.ReactElement;
}

const CollapsibleTableRowItem: React.FC<CollapsibleTableRowItemProps> = ({
  name,
  value,
}) => {
  return (
    <tr>
      <td className='py-2 px-3 font-primary-light'>- {name}</td>
      <td className='border-l-2 text-right pr-3'>{value}</td>
    </tr>
  );
};

export default CollapsibleTableRowItem;
