import React from 'react';
import { Address } from '../../openapi/arrakis/api';
import CanadaFlagImg from '../../assets/img/black-white-flag-canada.jpg';

interface T4AFormHeaderProps {
  taxYear: number | null;
  realBusinessName: string | undefined;
  realBusinessAddress: Address | undefined;
}

const T4AFormHeader: React.FC<T4AFormHeaderProps> = ({
  taxYear,
  realBusinessName,
  realBusinessAddress,
}) => {
  return (
    <div className='grid grid-cols-9 items-start'>
      <div className='col-span-4 border-2 border-b-0 border-black h-36 p-2'>
        <p>Payer&apos;s name – Nom du payeur</p>
        {realBusinessName && <p>{realBusinessName}</p>}
        {realBusinessAddress && <p>{realBusinessAddress.oneLine}</p>}
      </div>
      <div className='col-span-5 flex items-start space-x-1.5 border-b-2 border-black justify-between pl-2'>
        <div>
          <div className='flex items-start space-x-3'>
            <img
              src={CanadaFlagImg}
              alt='canada-flag'
              className='w-14 object-contain block'
            />
            <p className='leading-4 text-xs font-primary-regular'>
              Canada Revenue Agency
            </p>
            <p className='leading-4 text-xs font-primary-regular'>
              Agence du revenu du Canada
            </p>
          </div>
          <div className='flex space-x-5 items-center'>
            <div>
              <p className='mt-2 text-center leading-4 text-base'>Year</p>
              <p className='text-center leading-4 text-base'>Année</p>
            </div>
            <div className='mt-2 w-18 h-8 border-2 border-black flex justify-center items-center'>
              <p>{taxYear}</p>
            </div>
          </div>
        </div>
        <div className='w-full max-w-sm flex-grow'>
          <p className='print:text-xl text-2xl text-center font-primary-medium'>
            T4A
          </p>
          <p className='text-center leading-5 print:text-xs font-primary-medium'>
            Statement of Pension, Retirement, Annuity, and Other Income
          </p>
          <p
            className='leading-5 text-center print:text-xs font-primary-medium'
            style={{ fontSize: '0.5rem' }}
          >
            État du revenu de pension, de retraite, de rente ou d&apos;autres
            sources
          </p>
        </div>
      </div>
    </div>
  );
};

export default T4AFormHeader;
