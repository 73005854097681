import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  EscrowDepositResponse,
  TransactionControllerApi,
  TransactionResponse,
} from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showSuccessToast,
  showErrorToast,
  showErrorToastForErrorCode,
} from '../../../slices/ToastNotificationSlice';
import { fetchTransactionDetails } from '../../../slices/TransactionSlice';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import Button from '../../Button';
import ConfirmationModal from '../../ConfirmationModal';

interface DeleteCommissionDepositInstallmentModalProps {
  isOpen: boolean;
  onClose(): void;
  commissionDeposit: EscrowDepositResponse;
  transaction: TransactionResponse;
  escrowId: string;
}

const DeleteCommissionDepositInstallmentModal: React.FC<DeleteCommissionDepositInstallmentModalProps> = ({
  commissionDeposit,
  escrowId,
  isOpen,
  onClose,
  transaction,
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleDelete = async (depositId: string) => {
    setIsSubmitting(true);
    try {
      await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).deleteEscrowDeposit(depositId);
      dispatch(
        showSuccessToast('Commission Installment deleted successfully.'),
      );
      dispatch(fetchTransactionDetails(transaction.id!));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      dispatch(showErrorToast('Unable to delete commission installment.'));
      ErrorService.notify('Unable to delete the escrow deposit', e, {
        escrow: { id: escrowId, deposit: { id: depositId } },
      });
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to delete the escrow deposit.',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      variant='danger'
      title='Confirm Delete'
      subtitle='Are you sure you would like to delete the commission deposit installment?'
    >
      <div className='flex flex-row justify-between space-x-5 mt-5'>
        <Button
          label='Cancel'
          type='outline'
          className='!text-gray-400 !py-2'
          fullWidth
          onClick={onClose}
        />
        <Button
          label='Delete'
          type='danger'
          className='!py-2'
          fullWidth
          onClick={() => handleDelete(commissionDeposit.id!)}
          isSubmitting={isSubmitting}
        />
      </div>
    </ConfirmationModal>
  );
};

export default DeleteCommissionDepositInstallmentModal;
