import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { AgentControllerApi, MlsResponse } from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { fetchLiteMLSDetails, mlsDetail } from '../../../slices/MLSSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { AppDispatch, ISelectOption } from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenControlledAsyncSelectInput from '../../Zen/Input/ZenControlledAsyncSelectInput';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../../Zen/ZenButton';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import { DEFAULT_PAGE_SIZE } from '../../../constants/TableConstants';

interface ZenMoveAllAgentsToMLSProps {
  isOpen: boolean;
  onClose(): void;
  mls: MlsResponse;
}

interface FormData {
  mls: ISelectOption;
}

const ZenMoveAllAgentsToMLS: React.FC<ZenMoveAllAgentsToMLSProps> = ({
  isOpen,
  onClose,
  mls,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { control, watch, handleSubmit } = useForm<FormData>();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const mlsValue = watch('mls');

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      await new AgentControllerApi(getYentaConfiguration()).migrateBoard({
        fromId: mls.id!,
        toId: mlsValue.value,
      });
      dispatch(showSuccessToast('MLS agents moved successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to move the agents', e, {
        mls: { fromId: mls.id, toId: mlsValue.value },
      });
      dispatch(
        showErrorToast(
          'We had a problem moving the agents',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      dispatch(mlsDetail(mls.id!));
      setIsSubmitting(false);
      setOpenConfirmModal(false);
      onClose();
    }
  };

  return (
    <ZenSidebarModal
      title='Move Agents to Another MLS'
      subtitle='Migrates all agents from this MLS to another MLS'
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='h-full'>
        <form className='flex flex-col justify-between min-h-full'>
          <div className='py-6 px-4'>
            <ZenControlledAsyncSelectInput<FormData, 'mls'>
              control={control}
              name='mls'
              label='Move To MLS'
              placeholder='Search'
              fetchData={async (search, page) => {
                const res = await dispatch(
                  fetchLiteMLSDetails(page, DEFAULT_PAGE_SIZE, 'ASC', search),
                );
                return (res?.results || []).map((resp) => ({
                  value: `${resp?.id}`,
                  label: `${resp?.name}`,
                }));
              }}
              rules={{
                required: 'Please select MLS',
              }}
              isRequired
            />
          </div>
          <div className=' p-4 h-20  border-t border-gray-200'>
            <div className='flex items-center space-x-3'>
              <div className='flex-1'>
                <ZenButton
                  type='button'
                  onClick={() => onClose()}
                  label='Cancel'
                  variant='secondary-gray-outline'
                  isFullWidth
                />
              </div>
              <div className='flex-1'>
                <ZenButton
                  isSubmitting={isSubmitting}
                  label='Move all agents'
                  onClick={handleSubmit(() => setOpenConfirmModal(true))}
                  isFullWidth
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <ZenConfirmationModal
        title='Confirm you want to move all agents.'
        subtitle={`You’re about to move all agents from ${mls.name} to ${mlsValue?.label}`}
        isOpen={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        variant='primary'
        onConfirm={onSubmit}
        isSubmitting={isSubmitting}
        isDisabled={isSubmitting}
        confirmButtonText='Confirm'
        cancelButtonText='Cancel'
      />
    </ZenSidebarModal>
  );
};

export default ZenMoveAllAgentsToMLS;
