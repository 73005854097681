import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CheckDepositDto,
  CheckDepositsControllerApi,
  CheckDepositSubmitRequest,
  CheckDepositUploadResponse,
  ExternalCheckDepositsControllerApi,
} from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import {
  AppThunk,
  CheckDepositsCounter,
  CheckDepositState,
  ErrorCode,
} from '../types';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from './ErrorSlice';
import { showErrorToast } from './ToastNotificationSlice';

export const initialState: CheckDepositState = {
  loading: false,
  uploadedCheckDepositsErrorCode: null,
  uploadedCheckDepositsErrorMessages: undefined,
  uploadedCheckDeposits: undefined,
  checkDepositsDetails: undefined,
  checkDepositsList: undefined,
  checkDepositsListErrorCode: null,
  checkDepositsListLoading: false,
  confirmationId: null,
  submitCheckDepositsErrorCode: null,
  requestCounter: [],
};

const CheckDepositsSlice = createSlice({
  name: 'checkDeposits',
  initialState,
  reducers: {
    changeLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    changeRequestCounter(state, action: PayloadAction<CheckDepositsCounter[]>) {
      state.requestCounter = action.payload;
    },
    resetCounter(state, action: PayloadAction<string>) {
      const index = state.requestCounter?.findIndex(
        (t: CheckDepositsCounter) => t.transactionId === action.payload,
      );
      if (index !== -1) {
        state.requestCounter[index].counter = 0;
      }
    },
    saveUploadedCheckDeposits(
      state,
      action: PayloadAction<CheckDepositUploadResponse | undefined>,
    ) {
      state.uploadedCheckDeposits = action.payload;
      state.uploadedCheckDepositsErrorCode = null;
    },
    errorUploadingCheckDeposits(state, action: PayloadAction<ErrorCode>) {
      state.uploadedCheckDepositsErrorCode = action.payload;
    },
    errorMessageUploadingCheckDeposits(
      state,
      action: PayloadAction<string | undefined>,
    ) {
      state.uploadedCheckDepositsErrorMessages = action.payload;
    },
    saveCheckDepositsDetail(
      state,
      action: PayloadAction<CheckDepositDto | undefined>,
    ) {
      state.checkDepositsDetails = action.payload;
    },
    changeCheckDepositsLoading(state, action: PayloadAction<boolean>) {
      state.checkDepositsListLoading = action.payload;
    },
    saveCheckDeposits(state, action: PayloadAction<CheckDepositDto[]>) {
      state.checkDepositsList = action.payload;
      state.checkDepositsListErrorCode = null;
    },
    errorFetchingCheckDeposits(state, action: PayloadAction<ErrorCode>) {
      state.checkDepositsListErrorCode = action.payload;
    },
    saveConfirmationId(state, action: PayloadAction<number>) {
      state.confirmationId = action.payload;
      state.submitCheckDepositsErrorCode = null;
    },
    errorSubmittingCheckDeposits(state, action: PayloadAction<ErrorCode>) {
      state.submitCheckDepositsErrorCode = action.payload;
    },
  },
});

export const {
  changeLoading,
  changeRequestCounter,
  resetCounter,
  saveUploadedCheckDeposits,
  errorUploadingCheckDeposits,
  errorMessageUploadingCheckDeposits,
  saveCheckDepositsDetail,
  changeCheckDepositsLoading,
  saveCheckDeposits,
  errorFetchingCheckDeposits,
  saveConfirmationId,
  errorSubmittingCheckDeposits,
} = CheckDepositsSlice.actions;

export const uploadCheckDeposits = (
  transactionId: string,
  amount: number,
  checkImageFront: File,
  checkImageBack: File,
  requestCounter: CheckDepositsCounter[],
): AppThunk<Promise<CheckDepositUploadResponse | undefined>> => async (
  dispatch,
) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new CheckDepositsControllerApi(
      getArrakisConfiguration(),
    ).uploadCheck(transactionId, amount, checkImageFront, checkImageBack);
    dispatch(saveUploadedCheckDeposits(data));
    return data;
  } catch (e) {
    dispatch(errorUploadingCheckDeposits(ErrorService.getErrorCode(e)));
    dispatch(
      errorMessageUploadingCheckDeposits(ErrorService.getErrorMessage(e)),
    );
    ErrorService.notify('Error uploading check deposit', e, {
      transaction: { transactionId },
    });
    return undefined;
  } finally {
    dispatch(changeLoading(false));
    dispatch(changeRequestCounter(requestCounter));
  }
};

export const submitCheckDeposits = (
  transactionId: string,
  checkDepositId: string,
  checkDepositSubmitRequest: CheckDepositSubmitRequest,
): AppThunk<Promise<number | undefined>> => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new CheckDepositsControllerApi(
      getArrakisConfiguration(),
    ).submitCheck(transactionId, checkDepositId, checkDepositSubmitRequest);
    dispatch(saveConfirmationId(data.confirmationNumber!));
    return data.confirmationNumber;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(showErrorToast('Unable to submit check deposits'));
    ErrorService.notify('Error submitting check deposit', e, {
      transaction: { transactionId },
      checkDepositId: { checkDepositId },
    });
    return undefined;
  } finally {
    dispatch(changeLoading(false));
  }
};

export const getCheckDepositsList = (transactionId: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeCheckDepositsLoading(true));
  try {
    const { data } = await new CheckDepositsControllerApi(
      getArrakisConfiguration(),
    ).getTransactionCheckDeposits(transactionId);
    dispatch(saveCheckDeposits(data));
  } catch (e) {
    dispatch(showErrorToast('Unable to fetch check deposits'));
    dispatch(errorUploadingCheckDeposits(ErrorService.getErrorCode(e)));
    dispatch(
      errorMessageUploadingCheckDeposits(ErrorService.getErrorMessage(e)),
    );
    ErrorService.notifyIgnoreHandled('Error fetching check deposits', e, {
      transaction: { transactionId },
    });
  } finally {
    dispatch(changeCheckDepositsLoading(false));
  }
};

export const getCheckDepositsDetails = (
  transactionId: string,
  checkDepositId: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new CheckDepositsControllerApi(
      getArrakisConfiguration(),
    ).getTransactionCheckDeposit(transactionId, checkDepositId);
    dispatch(saveCheckDepositsDetail(data));
  } catch (e) {
    dispatch(showErrorToast('Unable to fetch check deposits details'));
    dispatch(errorUploadingCheckDeposits(ErrorService.getErrorCode(e)));
    dispatch(
      errorMessageUploadingCheckDeposits(ErrorService.getErrorMessage(e)),
    );
    ErrorService.notify('Error fetching check deposits details', e, {
      transaction: { transactionId },
      checkDepositId: { checkDepositId },
    });
  }
};

export const getCheckDepositsFrontImageUrl = (
  transactionId: string,
  checkDepositId: string,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new CheckDepositsControllerApi(
      getArrakisConfiguration(),
    ).getCheckFrontImageUrl(transactionId, checkDepositId);
    return data;
  } catch (e) {
    dispatch(showErrorToast('Unable to fetch check deposits front image url'));
    dispatch(errorUploadingCheckDeposits(ErrorService.getErrorCode(e)));
    dispatch(
      errorMessageUploadingCheckDeposits(ErrorService.getErrorMessage(e)),
    );
    ErrorService.notify('Error fetching check deposits front image url', e, {
      transaction: { transactionId },
      checkDepositId: { checkDepositId },
    });
    return undefined;
  }
};

export const getCheckDepositsBackImageUrl = (
  transactionId: string,
  checkDepositId: string,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new CheckDepositsControllerApi(
      getArrakisConfiguration(),
    ).getCheckBackImageUrl(transactionId, checkDepositId);
    return data;
  } catch (e) {
    dispatch(showErrorToast('Unable to fetch check deposits back image url'));
    dispatch(errorUploadingCheckDeposits(ErrorService.getErrorCode(e)));
    dispatch(
      errorMessageUploadingCheckDeposits(ErrorService.getErrorMessage(e)),
    );
    ErrorService.notify('Error fetching check deposits back image url', e, {
      transaction: { transactionId },
      checkDepositId: { checkDepositId },
    });
    return undefined;
  }
};

export const uploadEscrowCheckDeposits = (
  escrowId: string,
  amount: number,
  checkImageFront: File,
  checkImageBack: File,
  requestCounter: CheckDepositsCounter[],
): AppThunk<Promise<CheckDepositUploadResponse | undefined>> => async (
  dispatch,
) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new CheckDepositsControllerApi(
      getArrakisConfiguration(),
    ).uploadEscrowDepositCheck(
      escrowId,
      amount,
      checkImageFront,
      checkImageBack,
    );
    dispatch(saveUploadedCheckDeposits(data));
    return data;
  } catch (e) {
    dispatch(errorUploadingCheckDeposits(ErrorService.getErrorCode(e)));
    dispatch(
      errorMessageUploadingCheckDeposits(ErrorService.getErrorMessage(e)),
    );
    ErrorService.notify('Error uploading trust check deposit', e, {
      escrow: { escrowId },
    });
    return undefined;
  } finally {
    dispatch(changeLoading(false));
    dispatch(changeRequestCounter(requestCounter));
  }
};

export const submitEscrowCheckDeposits = (
  escrowId: string,
  checkDepositId: string,
  checkDepositRequest: CheckDepositSubmitRequest,
): AppThunk<Promise<number | undefined>> => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new CheckDepositsControllerApi(
      getArrakisConfiguration(),
    ).submitEscrowDepositCheck(escrowId, checkDepositId, checkDepositRequest);
    dispatch(saveConfirmationId(data.confirmationNumber!));
    return data.confirmationNumber;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(showErrorToast('Unable to submit trust check deposits'));
    ErrorService.notify('Error submitting trust check deposit', e, {
      trustDeposit: { escrowId },
      checkDepositId: { checkDepositId },
    });
    return undefined;
  } finally {
    dispatch(changeLoading(false));
  }
};

export const uploadExternalCheckDeposit = (
  transactionId: string,
  amount: number,
  checkImageFront: File,
  checkImageBack: File,
  depositorFullName: string,
  depositorEmailAddress: string,
  depositorPhoneNumber: string,
  requestCounter: CheckDepositsCounter[],
): AppThunk<Promise<CheckDepositUploadResponse | undefined>> => async (
  dispatch,
) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new ExternalCheckDepositsControllerApi(
      getArrakisConfiguration(),
    ).uploadExternalCheckDeposit(
      transactionId,
      amount,
      checkImageFront,
      checkImageBack,
      depositorFullName,
      depositorEmailAddress,
      depositorPhoneNumber,
    );
    dispatch(saveUploadedCheckDeposits(data));
    return data;
  } catch (e) {
    dispatch(errorUploadingCheckDeposits(ErrorService.getErrorCode(e)));
    dispatch(
      errorMessageUploadingCheckDeposits(ErrorService.getErrorMessage(e)),
    );
    ErrorService.notify('Error uploading check deposit', e, {
      transaction: { transactionId },
    });
    return undefined;
  } finally {
    dispatch(changeLoading(false));
    dispatch(changeRequestCounter(requestCounter));
  }
};

export const submitExternalCheckDeposit = (
  tokenId: string,
  checkDepositId: string,
  checkDepositSubmitRequest: CheckDepositSubmitRequest,
): AppThunk<Promise<number | undefined>> => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new ExternalCheckDepositsControllerApi(
      getArrakisConfiguration(),
    ).submitExternalDeposit(tokenId, checkDepositId, checkDepositSubmitRequest);
    dispatch(saveConfirmationId(data.confirmationNumber!));
    return data.confirmationNumber;
  } catch (e) {
    dispatch(showErrorToast('Unable to submit check deposits'));
    ErrorService.notify('Error submitting check deposit', e, {
      tokenTransaction: { tokenId },
      checkDepositId: { checkDepositId },
    });
    return undefined;
  } finally {
    dispatch(changeLoading(false));
  }
};

export default CheckDepositsSlice.reducer;
