import { DateTime } from 'luxon';
import { useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
  Validate,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { MAX_YEAR_ALLOWED } from '../../../utils/DateUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';

interface ZenControlledDatePickerInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  labelClassName?: string;
  subLabel?: string;
  placeholder?: string;
  readOnly?: boolean;
  customInput?: React.ReactElement;
  datePickerConfig?: Omit<
    ReactDatePickerProps,
    'name' | 'onChange' | 'selected' | 'onBlur' | 'readOnly'
  >;
  icon?: React.ReactElement;
  endAdornment?: React.ReactElement;
  isRequired?: boolean;
  noBorder?: boolean;
  minDate?: Date;
  maxDate?: Date;
  onChangeSpy?: (value: Date) => void;
  onBlurSpy?: () => void;
}

const ZenControlledDatePickerInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  labelClassName,
  subLabel,
  defaultValue,
  placeholder,
  readOnly,
  customInput,
  datePickerConfig = {},
  tooltipIndex,
  shouldUnregister = true,
  icon,
  noBorder,
  endAdornment,
  isRequired = false,
  minDate,
  rules,
  maxDate,
  onChangeSpy,
  onBlurSpy,
  ...rest
}: ZenControlledDatePickerInputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      rules={{
        ...(rules || {}),
        validate: (val) => {
          if (val && DateTime.fromISO(val).year > MAX_YEAR_ALLOWED) {
            return `Year cannot be greater than ${MAX_YEAR_ALLOWED}.`;
          }
          return (rules?.validate as Validate<TFieldValues>)?.(val);
        },
      }}
      {...rest}
      render={({
        field: { value, onChange, name, onBlur },
        fieldState: { error, invalid },
      }) => {
        const selectedDate =
          value && DateTime.fromISO(value).isValid
            ? DateTime.fromISO(value).toJSDate()
            : null;

        return (
          <div className='w-full space-y-1'>
            {label && (
              <label className='inline-block' htmlFor={name}>
                <span
                  className={cn(
                    'font-zen-body font-semibold',
                    invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                    labelClassName,
                  )}
                >
                  {label}
                </span>
                {!!subLabel && (
                  <span className='font-zen-body text-sm text-zen-dark-12 ml-1'>
                    {subLabel}
                  </span>
                )}
                {isRequired && <span className='text-zen-danger'>*</span>}
              </label>
            )}
            <div
              className={cn(
                'rounded-lg flex flex-row items-center overflow-hidden bg-white',
                {
                  'border ': !noBorder,
                },
                focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
                value && 'text-zen-dark-9',
                invalid && '!border-zen-danger',
              )}
              data-tooltip-index={tooltipIndex}
            >
              {icon && (
                <div
                  className={cn('bg-white p-2 rounded-l', {
                    'bg-gray-50': readOnly,
                  })}
                >
                  {icon}
                </div>
              )}
              <DatePicker
                name={name}
                selected={selectedDate}
                onChange={(date: Date) => {
                  if (datePickerConfig?.showTimeSelect) {
                    onChange(DateTime.fromJSDate(date).toISO());
                  } else {
                    onChange(DateTime.fromJSDate(date).toISODate());
                  }
                  if (onChangeSpy) {
                    onChangeSpy(date);
                  }
                  setFocus(false);
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => {
                  onBlur();

                  if (onBlurSpy) {
                    onBlurSpy();
                  }
                }}
                onSelect={() => {
                  if (onBlurSpy) {
                    onBlurSpy();
                  }
                }}
                placeholderText={placeholder}
                autoComplete='off'
                readOnly={readOnly}
                dateFormatCalendar='MMMM'
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                id={name}
                disabledKeyboardNavigation
                customInput={customInput}
                minDate={minDate}
                maxDate={maxDate}
                className={cn(
                  !customInput &&
                    'appearance-none border-0 rounded-r border-zen-dark-5 p-2 focus:ring-0 focus:border-zen-dark-5  bg-white w-full font-zen-body font-normal',
                  icon ? 'border-l-0' : 'rounded-l',
                  {
                    'bg-gray-50': readOnly,
                  },
                )}
                {...datePickerConfig}
              />
              {endAdornment && (
                <div className='flex items-center py-2.5 rounded-r-md pr-3 bg-white'>
                  {endAdornment}
                </div>
              )}
            </div>
            {error && <ZenFormErrorMessage message={error.message} />}
          </div>
        );
      }}
    />
  );
};

export default ZenControlledDatePickerInput;
