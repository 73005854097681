import classNames from 'classnames';
import { values } from 'lodash';
import { DateTime } from 'luxon';
import { Control, UseFormSetValue } from 'react-hook-form-v7';
import {
  AddressRequestCountryEnum,
  AdministrativeAreaRequestCountryEnum,
  WillBeneficiaryAndTypeResponse,
  WillBeneficiaryAndTypeResponseTypeEnum,
} from '../../../../openapi/yenta';
import { ISelectOption } from '../../../../types';
import { capitalizeEnum, NAME_REGEX } from '../../../../utils/StringUtils';
import {
  PHONE_NUMBER_VALIDATIONS,
  TEXT_CONTENT_VALIDATIONS,
} from '../../../../utils/Validations';
import ZenControlledDatePickerInput from '../../Input/ZenControlledDatePickerInput';
import ZenControlledHTMLSelectInput from '../../Input/ZenControlledHTMLSelectInput';
import ZenControlledPhoneNumberInput from '../../Input/ZenControlledPhoneNumberInput';
import ZenControlledRadioInput from '../../Input/ZenControlledRadioInput';
import ZenControlledStateOrProvinceInput from '../../Input/ZenControlledStateOrProvince';
import ZenControlledTextAreaInput from '../../Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import { CreateBeneficiaryV2FormData } from './CreateBeneficiaryScreenV2';
import ExistingBeneficiaryTableV2 from './ExistingBeneficiaryTableV2';

interface AddBeneficiaryV2Props {
  country?: AdministrativeAreaRequestCountryEnum;
  beneficiary?: WillBeneficiaryAndTypeResponse;
  control: Control<CreateBeneficiaryV2FormData, object>;
  setValue: UseFormSetValue<CreateBeneficiaryV2FormData>;
  beneficiaryType: WillBeneficiaryAndTypeResponseTypeEnum;
}

const AddBeneficiaryV2: React.FC<AddBeneficiaryV2Props> = ({
  country,
  beneficiary,
  control,
  setValue,
  beneficiaryType,
}) => {
  const benificiaryOptions: ISelectOption[] = [
    {
      label: 'Individual',
      value: WillBeneficiaryAndTypeResponseTypeEnum.Individual,
    },
    { label: 'Trust', value: WillBeneficiaryAndTypeResponseTypeEnum.Trust },
  ];
  const currentBeneficiaryTypeNone =
    !beneficiary ||
    beneficiary?.type === WillBeneficiaryAndTypeResponseTypeEnum.None;
  const selectedBeneficiaryTypeIndividual =
    beneficiaryType === WillBeneficiaryAndTypeResponseTypeEnum.Individual;
  const selectedBeneficiaryTypeTrust =
    beneficiaryType === WillBeneficiaryAndTypeResponseTypeEnum.Trust;

  return (
    <div
      className='w-full flex flex-col items-center'
      aria-label='add-beneficiary'
    >
      {currentBeneficiaryTypeNone && (
        <div className='w-full md:mb-6 mb-4'>
          <p className='text-zen-dark-6 text-xs leading-4 font-medium'>
            Step 2/2
          </p>
        </div>
      )}
      <div className='w-full mb-6 relative'>
        <p className='w-full font-zen-body font-semibold text-lg text-zen-dark-13 text-center'>
          Beneficiary Information
        </p>

        <p className='w-full font-zen-body font-semibold text-zen-dark-13 text-center my-2'>
          By adding a beneficiary’s information, you agree that you have
          obtained that beneficiary’s consent to share their personal
          information with The Real Brokerage Inc. and its affiliates.
        </p>
      </div>
      <div className='w-full mb-6 flex flex-row justify-between items-center'>
        <p className='w-full font-zen-body font-normal text-base text-zen-dark-13 text-left'>
          Beneficiary Type
        </p>

        <ZenControlledRadioInput<CreateBeneficiaryV2FormData, `beneficiaryType`>
          control={control}
          label=''
          name='beneficiaryType'
          options={benificiaryOptions}
          inlineOptions
          shouldUnregister={false}
          radioButtonStyle='text-base'
          containerStyles='justify-end'
        />
      </div>
      {selectedBeneficiaryTypeIndividual ? (
        <div className='grid md:grid-rows-3 w-full h-full gap-y-4'>
          <div className='grid md:grid-cols-3 grid-cols-1 md:gap-x-4 gap-y-5'>
            <ZenControlledTextInput<CreateBeneficiaryV2FormData, 'firstName'>
              control={control}
              name='firstName'
              label='First Name'
              placeholder='First Name'
              rules={{
                required: selectedBeneficiaryTypeIndividual
                  ? 'First name is required'
                  : undefined,
                pattern: {
                  value: NAME_REGEX,
                  message: 'Please enter a valid first name',
                },
              }}
              shouldUnregister={false}
              isRequired
            />
            <ZenControlledTextInput<CreateBeneficiaryV2FormData, 'middleName'>
              control={control}
              name='middleName'
              label='Middle Name'
              placeholder='Middle Name'
              shouldUnregister={false}
              rules={{
                pattern: {
                  value: NAME_REGEX,
                  message: 'Please enter a valid middle name',
                },
              }}
            />
            <ZenControlledTextInput<CreateBeneficiaryV2FormData, 'lastName'>
              control={control}
              name='lastName'
              label='Last Name'
              placeholder='Last Name'
              rules={{
                required: selectedBeneficiaryTypeIndividual
                  ? 'Last name is required'
                  : undefined,
                pattern: {
                  value: NAME_REGEX,
                  message: 'Please enter a valid last name',
                },
              }}
              shouldUnregister={false}
              isRequired
            />
          </div>
          <div className='grid grid-cols-1 gap-x-4'>
            <ZenControlledTextInput<
              CreateBeneficiaryV2FormData,
              'address.streetAddress1'
            >
              control={control}
              name='address.streetAddress1'
              label='Address'
              placeholder='Address'
              rules={{
                required: selectedBeneficiaryTypeIndividual
                  ? 'Address is required'
                  : undefined,
              }}
              shouldUnregister={false}
              isRequired
            />
          </div>
          <div className='grid md:grid-cols-2 grid-cols-1 md:gap-x-4 gap-y-5'>
            <ZenControlledHTMLSelectInput<
              CreateBeneficiaryV2FormData,
              'address.country'
            >
              control={control}
              name='address.country'
              label='Country'
              placeholder='Country'
              options={values(AddressRequestCountryEnum).map((value) => ({
                label: capitalizeEnum(value),
                value,
              }))}
              rules={{
                required: 'Country is required',
              }}
              shouldUnregister={false}
              isRequired
            />
            <ZenControlledStateOrProvinceInput<
              CreateBeneficiaryV2FormData,
              'address.stateOrProvince'
            >
              control={control}
              name='address.stateOrProvince'
              selectedCountry={country}
              rules={{
                required: selectedBeneficiaryTypeIndividual
                  ? 'State/Province is required'
                  : undefined,
              }}
              isRequired
              shouldUnregister={false}
              setValue={setValue}
            />
          </div>
          <div className='grid md:grid-cols-2 grid-cols-1 md:gap-x-4 gap-y-5'>
            <ZenControlledTextInput<CreateBeneficiaryV2FormData, 'address.city'>
              control={control}
              name='address.city'
              label='City'
              placeholder='City'
              rules={{
                required: selectedBeneficiaryTypeIndividual
                  ? 'City is required'
                  : undefined,
              }}
              shouldUnregister={false}
              isRequired
            />
            <ZenControlledTextInput<
              CreateBeneficiaryV2FormData,
              'address.zipOrPostalCode'
            >
              control={control}
              name='address.zipOrPostalCode'
              label='Postal Code'
              placeholder='Postal Code'
              rules={{
                required: selectedBeneficiaryTypeIndividual
                  ? 'Postal code is required'
                  : undefined,
              }}
              shouldUnregister={false}
              isRequired
            />
          </div>
          <div className='grid md:grid-cols-2 grid-cols-1 md:gap-x-4 gap-y-5'>
            <ZenControlledDatePickerInput<
              CreateBeneficiaryV2FormData,
              'birthDate'
            >
              control={control}
              name='birthDate'
              label='Date of Birth'
              placeholder='Date of Birth'
              datePickerConfig={{
                maxDate: DateTime.local().toJSDate(),
              }}
              rules={{
                required: selectedBeneficiaryTypeIndividual
                  ? 'Date of birth is required'
                  : undefined,
              }}
              shouldUnregister={false}
              isRequired
            />
            <div className='pb-10 md:pb-0'>
              <ZenControlledPhoneNumberInput<
                CreateBeneficiaryV2FormData,
                'phoneNumber'
              >
                control={control}
                name='phoneNumber'
                label='Telephone Number'
                placeholder='Telephone Number'
                rules={{
                  required: selectedBeneficiaryTypeIndividual
                    ? 'Telephone number is required'
                    : undefined,
                  ...PHONE_NUMBER_VALIDATIONS,
                }}
                shouldUnregister={false}
                isRequired
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={classNames('w-full h-full', {
            'mb-24': currentBeneficiaryTypeNone,
          })}
        >
          <ZenControlledTextAreaInput<
            CreateBeneficiaryV2FormData,
            'trustInformation'
          >
            control={control}
            name='trustInformation'
            label='Trust Information'
            placeholder='Enter information here..'
            rules={{
              required: selectedBeneficiaryTypeTrust
                ? 'Trust information is required'
                : undefined,
              ...TEXT_CONTENT_VALIDATIONS,
            }}
            shouldUnregister={false}
            isRequired
            rows={8}
            noResize
          />
        </div>
      )}
      <ExistingBeneficiaryTableV2 beneficiary={beneficiary} />
    </div>
  );
};

export default AddBeneficiaryV2;
