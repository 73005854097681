import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import useZenPagination, { DOTS } from '../../../../hooks/useZenPagination';
import { cn } from '../../../../utils/classUtils';
import { DEFAULT_SHOWN_ITEMS_COUNT } from '../../../../constants/LeoConstants';
import { SharranMediaItem } from '../../../../utils/LeoUtils';

type LeoVideoPaginationProps = {
  sharranVideos: SharranMediaItem[];
  setPageItems: React.Dispatch<React.SetStateAction<SharranMediaItem[]>>;
};

const LeoVideoPagination: React.FC<LeoVideoPaginationProps> = ({
  sharranVideos,
  setPageItems,
}) => {
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [showingCount, setShowingCount] = React.useState<number>(
    DEFAULT_SHOWN_ITEMS_COUNT,
  );
  const pageSize = DEFAULT_SHOWN_ITEMS_COUNT;
  const totalCount = sharranVideos.length;
  const lastPage = Math.ceil(totalCount / pageSize);

  const goToPage = (page: number) => {
    setCurrentPage(page);
  };

  const onPrevious = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    goToPage(currentPage - 1);
  };
  const onNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    goToPage(currentPage + 1);
  };

  const paginationRange = useZenPagination({
    currentPage,
    totalCount,
    pageSize,
  });

  useEffect(() => {
    if (totalCount) {
      const start = currentPage * pageSize;
      const end = start + pageSize;
      const slicedItems = sharranVideos.slice(start, end);
      setPageItems(slicedItems);
      setShowingCount(slicedItems.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharranVideos, currentPage]);

  return (
    <div className='bg-[#0D0F1C] flex flex-row justify-between items-center mt-1 px-5 py-3 rounded-b-md'>
      <p className='text-white font-zen-body-medium text-sm'>
        {`${showingCount} of ${totalCount}`}
      </p>
      <div className='flex space-x-1 items-center'>
        <button
          className='bg-[#2E2F37] disabled:cursor-not-allowed flex items-center justify-center rounded-full w-7 h-7'
          disabled={currentPage === 0}
          onClick={(e) => onPrevious(e)}
          aria-label='go-previous'
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={cn(currentPage === 0 ? 'text-black' : 'text-white')}
          />
        </button>
        <div className='flex items-center font-zen-body text-base text-white space-x-1'>
          {paginationRange.map((pageNumber) => {
            if (pageNumber === DOTS) {
              return <div key={pageNumber + 1}>...</div>;
            }

            return (
              <button
                key={pageNumber}
                className={classNames('px-2 py-0.5 rounded', {
                  'bg-[#2E2F37]': +pageNumber - 1 === currentPage,
                })}
                onClick={(e) => {
                  e.preventDefault();
                  goToPage(+pageNumber - 1);
                }}
              >
                {pageNumber}
              </button>
            );
          })}
        </div>
        <button
          className='bg-[#2E2F37] disabled:cursor-not-allowed flex items-center justify-center rounded-full w-7 h-7'
          disabled={currentPage === lastPage - 1}
          onClick={(e) => {
            e.preventDefault();
            onNext(e);
          }}
          aria-label='go-next'
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            className={cn(
              currentPage === lastPage - 1 ? 'text-black' : 'text-white',
            )}
          />
        </button>
      </div>
    </div>
  );
};

export default LeoVideoPagination;
