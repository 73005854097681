import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenButton from '../../Zen/ZenButton';

import {
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName,
} from './ZenWelcomeOnboardingForm';
import withApplicactionFormProgress from './withWelcomeOnboardingFormProgress';

const InitialStep: StepByStepComponent<
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName
> = ({ onNext }) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  return (
    <div className='py-12 mx-auto md:w-full md:max-w-5xl md:pl-8 h-full flex flex-col md:flex-row md:space-x-10 lg:space-x-14 text-zen-dark-9'>
      <div className='space-y-7 max-w-md mx-auto'>
        <p className='text-0.5xl font-medium font-zen-title text-zen-dark-9'>
          {`${userDetail?.firstName}, lets start your profile!`}
        </p>
        <div className='space-y-6 text-lg'>
          <p className='mb-4'>
            Please take a moment to complete your Real agent profile! You will
            need to provide:
          </p>
          <ul className='mb-4 list-disc pl-4 space-y-2'>
            <li>A profile picture of yourself and bio (optional)</li>
            <li>Personal/Professional contact information</li>
            <li>Social media links, if any</li>
            <li>Bank account information to create a Tipalti account</li>
            <li>SSN or EIN (if paid as entity)</li>
          </ul>
        </div>
        <div className='pt-4'>
          <ZenButton
            label="Let's Start"
            onClick={onNext}
            variant='primary'
            isFullWidth
          />
        </div>
      </div>
    </div>
  );
};

export default withApplicactionFormProgress(InitialStep);
