import classNames from 'classnames';
import XMarkImage from '../../../../assets/icons/zen/transaction/xmark.svg';
import Approved from '../../../../assets/img/application/approved.svg';
import BoardJoined from '../../../../assets/img/application/board-joined.svg';
import Fees_Paid from '../../../../assets/img/application/fees-paid.svg';
import ICASigned from '../../../../assets/img/application/ica-signed.svg';
import JoinBoard from '../../../../assets/img/application/join-board.svg';
import Join_MLS from '../../../../assets/img/application/join-mls.svg';
import License_Transferred from '../../../../assets/img/application/license-transferred.svg';
import MLS_Joined from '../../../../assets/img/application/mls-joined.svg';
import Pay_Fees from '../../../../assets/img/application/pay-fees.svg';
import Pending_Approval from '../../../../assets/img/application/pending-approval.svg';
import SignIca from '../../../../assets/img/application/sign-ica.svg';
import Started from '../../../../assets/img/application/started.svg';
import Transfer_License from '../../../../assets/img/application/transfer-license.svg';
import { ApplicationResponseStatusEnum } from '../../../../openapi/yenta';
import { EnumMap } from '../../../../types';
import { safeEnumMapGet } from '../../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import {
  PillVariantType,
  pillVariantTypeToClassNameMap,
} from '../../ZenStatusPill';

interface ZenApplicationStatusProps {
  status: ApplicationResponseStatusEnum;
}

const statusToPillType: EnumMap<
  ApplicationResponseStatusEnum,
  PillVariantType
> = {
  STARTED: 'primary',
  ICA_SIGNED: 'warning',
  LETTER_OF_INTENT_SIGNED: 'warning',
  SIGN_LETTER_OF_INTENT: 'warning',
  REJECTED: 'danger',
  APPROVED: 'success',
  BOARD_JOINED: 'warning',
  FEES_PAID: 'warning',
  JOIN_BOARD: 'warning',
  JOIN_MLS: 'warning',
  LICENSE_TRANSFERRED: 'warning',
  MLS_JOINED: 'warning',
  PAY_FEES: 'warning',
  PENDING_APPROVAL: 'warning',
  SIGN_ICA: 'warning',
  TRANSFER_LICENSE: 'warning',
  CREATED: 'primary',
  FEES_WAIVED: 'warning',
};

const applicationStatusIconMap: EnumMap<
  ApplicationResponseStatusEnum,
  React.ReactElement
> = {
  STARTED: <img src={Started} alt='started' className='w-3.5 mb-0.5' />,

  APPROVED: <img src={Approved} alt='approved' className='w-3.5 mb-0.5' />,
  REJECTED: <img src={XMarkImage} alt='rejected' className='w-3 mb-0.5' />,
  FEES_PAID: <img src={Fees_Paid} alt='fee paid' className='w-4.5 mb-0.5' />,
  ICA_SIGNED: <img src={ICASigned} alt='ica-signed' className='w-4 mb-0.5' />,
  BOARD_JOINED: (
    <img src={BoardJoined} alt='board joined' className='w-3.5 mb-0.5' />
  ),

  LETTER_OF_INTENT_SIGNED: (
    <img src={ICASigned} alt='letter of intent signed' className='w-4 mb-0.5' />
  ),
  SIGN_LETTER_OF_INTENT: (
    <img src={SignIca} alt='signed letter of intent' className='w-4 mb-0.5' />
  ),
  JOIN_BOARD: <img src={JoinBoard} alt='join board' className='w-3.5 mb-0.5' />,
  JOIN_MLS: <img src={Join_MLS} alt='join mls' className='w-4 mb-0.5' />,
  LICENSE_TRANSFERRED: (
    <img
      src={License_Transferred}
      alt='license transferred'
      className='w-3.5 mb-0.5'
    />
  ),
  MLS_JOINED: (
    <img src={MLS_Joined} alt='mls joined' className='w-4.5 mb-0.5' />
  ),
  PAY_FEES: <img src={Pay_Fees} alt='pay fee' className='w-4 mb-0.5' />,
  PENDING_APPROVAL: (
    <img src={Pending_Approval} alt='pending approval' className='w-3 mb-0.5' />
  ),
  SIGN_ICA: <img src={SignIca} alt='sign ica' className='w-4 mb-0.5' />,
  TRANSFER_LICENSE: (
    <img
      src={Transfer_License}
      alt='transfer-license'
      className='w-3.5 mb-0.5'
    />
  ),
  CREATED: <img src={Started} alt='started' className='w-3.5 mb-0.5' />,
  FEES_WAIVED: (
    <img src={Fees_Paid} alt='fee waived' className='w-4.5 mb-0.5' />
  ),
};

const ZenApplicationStatus: React.FC<ZenApplicationStatusProps> = ({
  status,
}) => {
  return (
    <div
      className={classNames(
        'w-max flex items-center space-x-1.5 flex-grow flex-shrink-0 py-1.5 px-2 rounded-full md:whitespace-nowrap',
        pillVariantTypeToClassNameMap[
          safeEnumMapGet(statusToPillType, status, 'primary')
        ],
      )}
    >
      {safeEnumMapGet(
        applicationStatusIconMap,
        status,
        <img src={Started} alt={capitalizeEnum(status)} />,
      )}
      <p className='font-zen-title font-normal text-sm flex-grow'>
        {capitalizeEnum(status)}
      </p>
    </div>
  );
};

export default ZenApplicationStatus;
