import {
  Controller,
  FieldValues,
  UseControllerProps,
  FieldPath,
} from 'react-hook-form-v7';
import CurrencyInput from 'react-currency-input-field';
import { cn } from '../utils/classUtils';
import RangeSlider from './RangeSlider';

interface RangeValue {
  min: number;
  max: number;
}

type IRangeValue = Record<string, RangeValue>;

interface ControlledRangeSliderV7Props<
  TFieldValues extends FieldValues | IRangeValue = FieldValues | IRangeValue,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  min: number;
  max: number;
  step: number;
  label: string;
  minValueLabel: string;
  maxValueLabel: string;
  disabled?: boolean;
  minValuePlaceholder?: string;
  maxValuePlaceholder?: string;
}

const ControlledRangeSliderV7 = <
  TFieldValues extends FieldValues | IRangeValue = FieldValues | IRangeValue,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  minValueLabel,
  maxValueLabel,
  min,
  max,
  step,
  disabled,
  minValuePlaceholder,
  maxValuePlaceholder,
  ...rest
}: ControlledRangeSliderV7Props<TFieldValues, TName>) => {
  return (
    <Controller
      {...rest}
      render={({ field: { name, onChange, value } }) => (
        <div className='space-y-1 w-full'>
          <label className='inline-block' htmlFor={name}>
            {label}
          </label>
          <div className='flex flex-row justify-between space-x-8'>
            <div>
              <CurrencyInput
                id={`${name}.min`}
                name={`${name}.min`}
                value={value?.min}
                onValueChange={(min) => {
                  onChange({ min, max: value.max });
                }}
                readOnly={disabled}
                placeholder={minValuePlaceholder}
                className={cn(
                  'appearance-none p-1.5 border-gray-200 rounded bg-white focus:outline-none focus:ring-0 w-full',
                  {
                    'bg-gray-50': disabled,
                  },
                )}
                allowDecimals={false}
                prefix='$ '
              />
              <label
                className='inline-block mt-1 text-gray-500'
                htmlFor={`${name}.min`}
              >
                {minValueLabel}
              </label>
            </div>
            <div>
              <CurrencyInput
                id={`${name}.max`}
                name={`${name}.max`}
                value={value?.max}
                onValueChange={(max) => {
                  onChange({ min: value.min, max });
                }}
                readOnly={disabled}
                placeholder={maxValuePlaceholder}
                className={cn(
                  'appearance-none p-1.5 border-gray-200 rounded bg-white focus:outline-none focus:ring-0 w-full',
                  {
                    'bg-gray-50': disabled,
                  },
                )}
                allowDecimals={false}
                prefix='$ '
              />
              <label
                className='inline-block mt-1 text-gray-500'
                htmlFor={`${name}.max`}
              >
                {maxValueLabel}
              </label>
            </div>
          </div>
          <div className='p-4'>
            <RangeSlider
              value={[value?.min!, value?.max!]}
              min={min}
              max={max}
              step={step}
              onChange={(number) => {
                onChange({ min: number[0], max: number[1] });
              }}
              disabled={disabled}
            />
          </div>
        </div>
      )}
    />
  );
};
export default ControlledRangeSliderV7;
