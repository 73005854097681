import { DragOverlay } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import React, { Fragment, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnInstance, Row } from 'react-table';
import { ResourceTableVariant } from '../../../containers/ResourceTable';
import {
  fetchChecklistItemById,
  uploadChecklistDocument,
  uploadToDropboxAndAddFileReferences,
} from '../../../slices/CheckListSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  AppDispatch,
  RootState,
  VerticalAlignmentVariant,
} from '../../../types';
import {
  isDocSizeInvalid,
  validDocs,
  validFormatDocs,
} from '../../../utils/FileUtils';
import DefaultLoader from '../../DefaultLoader';
import {
  ALLOWED_FILE_TYPES,
  CHECKLIST_ALLOWED_FILE_TYPES,
  MAX_DOC_SIZE_100MB,
} from '../../../constants/FilesConstants';
import { isV2Checklist } from '../../../utils/ChecklistUtils';

interface ZenResourceChecklistDropzoneTableRowProps<D extends object> {
  row: Row<D>;
  variant: ResourceTableVariant;
  renderToggleRowComponent?(d: Row<D>): React.ReactElement;
  visibleColumns: ColumnInstance<D>[];
  cellVerticalAlignment: VerticalAlignmentVariant;
  allowSortingRows?: boolean;
  index: number;
  pageLength: number;
  isDropzone: boolean;
  checklistId: string;
  hideRowBorder?: boolean;
  hideGroupMemberBorder?: boolean;
  dropboxId: string;
  compact?: boolean;
}

const ZenResourceDropzoneTableRow = <D extends object>({
  row,
  variant,
  renderToggleRowComponent,
  visibleColumns,
  cellVerticalAlignment,
  allowSortingRows,
  index,
  pageLength,
  isDropzone,
  checklistId = '',
  hideRowBorder = false,
  hideGroupMemberBorder = false,
  dropboxId,
  compact = false,
}: ZenResourceChecklistDropzoneTableRowProps<D>) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    transform,
    transition,
  } = useSortable({
    id: row.id,
    disabled: !allowSortingRows,
  });

  const style = {
    transform: CSS?.Transform?.toString(transform),
    transition: transition,
  };
  const dispatch: AppDispatch = useDispatch();
  const {
    auth: { userDetail },
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
    },
    checklist: { checklistsById },
  } = useSelector((state: RootState) => state);
  const { key, ...rest } = row.getRowProps();
  const [isDragged, setIsDragged] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const rowHeight = document.getElementById('row' + row.values.actions!)
    ?.offsetHeight;
  const isChecklistV2 = isV2Checklist(checklistsById[checklistId]?.data);

  const handleDrag = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragged(true);
    } else if (e.type === 'dragleave') {
      setIsDragged(false);
    }
    setIsUploading(false);
  };

  const handleDrop = useCallback(
    async (e) => {
      e.preventDefault();
      setIsUploading(true);
      let acceptedFiles = [...e.dataTransfer.files];
      if (
        validFormatDocs(
          acceptedFiles,
          checklistId ? CHECKLIST_ALLOWED_FILE_TYPES : ALLOWED_FILE_TYPES,
        )
      ) {
        const validFiles = validDocs(acceptedFiles, MAX_DOC_SIZE_100MB);
        if (isChecklistV2) {
          await dispatch(
            uploadToDropboxAndAddFileReferences(
              checklistId,
              row.values.actions!,
              dropboxId,
              userDetail?.id!,
              validFiles,
            ),
          );
        } else {
          await Promise.all(
            Array.from(validFiles).map((file: any) =>
              dispatch(
                uploadChecklistDocument(
                  row.values.actions!,
                  file.name,
                  '',
                  userDetail?.id!,
                  file,
                  transactionDetail?.id!,
                  checklistId,
                ),
              ),
            ),
          );
        }
        if (validFiles?.length) {
          await dispatch(
            fetchChecklistItemById(checklistId, row.values.actions!),
          );
        }
        if (isDocSizeInvalid(acceptedFiles, MAX_DOC_SIZE_100MB)) {
          dispatch(
            showErrorToast('File size exceeds maximum limit of 100 MB.'),
          );
        }
      } else {
        await dispatch(
          showErrorToast(
            `Allowed file types (${ALLOWED_FILE_TYPES.join(',')}).`,
          ),
        );
      }
      setIsUploading(false);
      setIsDragged(false);
    },
    [
      checklistId,
      dispatch,
      row.values.actions,
      transactionDetail?.id,
      userDetail?.id,
      isChecklistV2,
      dropboxId,
    ],
  );

  return (
    <React.Fragment key={key}>
      <tr
        ref={setNodeRef}
        {...attributes}
        {...rest}
        style={style}
        className={classNames({
          'border-b border-zen-dark-4':
            !hideGroupMemberBorder &&
            !hideRowBorder &&
            variant === ResourceTableVariant.ROW &&
            index !== pageLength - 1,
          'grid grid-cols-12 grid-flow-row':
            variant === ResourceTableVariant.CARD,
        })}
        onDragEnter={handleDrag}
      >
        {isDragging ? (
          <td className='bg-grey-100 h-16' colSpan={row?.cells?.length + 1}>
            &nbsp;
          </td>
        ) : (
          <Fragment>
            {allowSortingRows && (
              <td>
                <div {...listeners}>
                  <FontAwesomeIcon
                    icon={faGripDotsVertical}
                    size='lg'
                    className='text-zen-dark-6 cursor-move'
                  />
                </div>
              </td>
            )}
            {row.cells.map((cell, index) => {
              return (
                <td
                  id={'row' + row.values.actions!}
                  //@ts-ignore
                  key={key}
                  {...cell.getCellProps()}
                  className={classNames(
                    'relative font-zen-body font-semibold text-sm text-zen-dark-9',
                    compact ? 'px-2 py-3' : 'px-5 py-3',
                    cellVerticalAlignment,
                    {
                      [`border col-span-${cell.column.cardColSize || 12}`]:
                        variant === ResourceTableVariant.CARD,
                    },
                    {
                      'bg-regent-100 opacity-30':
                        !!isDropzone && !!isDragged && index !== 0,
                    },
                  )}
                >
                  {variant === ResourceTableVariant.CARD && (
                    <p className='text-xs text-zen-dark-5 font-semibold uppercase'>
                      {cell.column.render('Header')}
                    </p>
                  )}
                  {cell.render('Cell')}
                </td>
              );
            })}
            {!!isDropzone && !!isDragged && (
              <div
                className={classNames(
                  'flex justify-center items-center absolute left-0 w-full border-dashed border border-primary-blue',
                )}
                style={{ height: rowHeight + 'px' }}
                onDrop={handleDrop}
                onDragOver={handleDrag}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
              >
                {!isUploading ? (
                  <div className='font-zen-body text-sm font-semibold text-zen-dark-12 px-20 py-4 bg-rezen-light-blue-100/[0.5] border border-rezen-light-blue-100 rounded-xl'>
                    Drag & Drop File Here
                  </div>
                ) : (
                  <div className='px-20'>
                    <DefaultLoader />
                  </div>
                )}
              </div>
            )}
          </Fragment>
        )}
      </tr>
      {row.isExpanded && renderToggleRowComponent ? (
        <tr>
          <td colSpan={visibleColumns?.length}>
            {renderToggleRowComponent(row)}
          </td>
        </tr>
      ) : null}
      {isDragging && (
        <tr>
          <td colSpan={row?.cells?.length + 1}>
            <DragOverlay>
              <table className='w-full'>
                <tbody>
                  <tr
                    className='shadow-pop-over rotate-1 w-full bg-white'
                    {...row?.getRowProps()}
                  >
                    <td>
                      <div {...listeners}>
                        <FontAwesomeIcon
                          icon={faGripDotsVertical}
                          size='lg'
                          className='text-zen-dark-6 cursor-move'
                        />
                      </div>
                    </td>
                    {row?.cells?.map((cell) => (
                      // eslint-disable-next-line react/jsx-key
                      <td
                        {...cell.getCellProps()}
                        className={classNames(
                          'font-zen-body font-semibold text-sm text-zen-dark-9',
                          compact ? 'px-2 py-3' : 'px-5 py-3',
                          cellVerticalAlignment,
                          {
                            [`border col-span-${
                              cell.column.cardColSize || 12
                            }`]: variant === ResourceTableVariant.CARD,
                          },
                        )}
                      >
                        {variant === ResourceTableVariant.CARD && (
                          <p className='text-xs text-zen-dark-5 font-semibold uppercase'>
                            {cell.column.render('Header')}
                          </p>
                        )}
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </DragOverlay>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default ZenResourceDropzoneTableRow;
