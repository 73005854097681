import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import DetailPageLoader from '../components/DetailPageLoader';
import GeminiTransactionHeader from '../components/Gemini/Transaction/Header/GeminiTransactionHeader';
import PageLayout from '../components/PageLayout';
import ConnectWithQualia from '../components/Qualia/ConnectWithQualia';
import ResourceContainer from '../components/ResourceContainer';
import TransactionServerError from '../components/transactions/TransactionServerError';
import ZenRouterTabs, { ZenTab } from '../components/Zen/Tab/ZenRouterTabs';
import ZenTransactionHeader from '../components/Zen/Transaction/Header/ZenTransactionHeader';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import {
  fetchTransactionDetails,
  getTransactionFeatures,
  saveTransactionFeatures,
} from '../slices/TransactionSlice';
import {
  BreadcrumbMatcherType,
  FeatureFlagTypeEnum,
  RootState,
} from '../types';
import { getBreadcrumbMatched } from '../utils/TransactionHelper';
import TransactionTitleProgressRoute from './TransactionTitleProgressRoute';

type Match = {
  id: string;
};

const ZenRealTitleOfficerTransactionDetailRoute: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { id: transactionId } = useParams() as Match;
  const {
    transactionDetailResponse: {
      data: transactionDetail,
      loading: isLoading,
      error,
    },
  } = useSelector((state: RootState) => state.transaction);

  useEffect(() => {
    dispatch(fetchTransactionDetails(transactionId));
  }, [dispatch, transactionId]);

  const path = [
    { title: 'Home', url: '/' },
    { title: 'Transactions', url: '/transactions' },
  ];

  if (transactionDetail) {
    path.push({
      title: `${transactionDetail?.address?.oneLine}`,
      url: `/transactions/${transactionDetail?.id}`,
    });

    const breadcrumbsToMatch: BreadcrumbMatcherType[] = [
      {
        title: 'Title Progress',
        url: `/transactions/${transactionDetail?.id}/title-progress`,
      },
    ];

    const breadcrumbMatched = getBreadcrumbMatched(
      location.pathname,
      breadcrumbsToMatch,
    );

    if (breadcrumbMatched) {
      path.push(breadcrumbMatched);
    }
  }

  const transactionTabs = useMemo(() => {
    const tabs: ZenTab[] = [
      {
        label: 'Title Progress',
        TabComponent: TransactionTitleProgressRoute,
        path: `/transactions/${transactionDetail?.id}/title-progress`,
        exact: true,
      },
    ];
    return tabs;
  }, [transactionDetail]);

  const tabsPathRegex = useMemo(
    () => `(${transactionTabs.map((tab) => tab.path).join('|')})`,
    [transactionTabs],
  );

  useEffect(() => {
    dispatch(getTransactionFeatures(transactionId));

    return () => {
      dispatch(saveTransactionFeatures(undefined));
    };
  }, [dispatch, transactionId]);

  useEffect(() => {
    if (
      transactionDetail?.id === transactionId &&
      !!transactionDetail.usingTitle
    ) {
      history.replace(`/transactions/${transactionDetail.id}/title-progress`);
    }
  }, [history, transactionDetail, transactionId]);

  const geminiRedesignFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum?.GEMINI_REDESIGN,
  );

  const HeaderComponent = geminiRedesignFlagEnabled
    ? GeminiTransactionHeader
    : ZenTransactionHeader;

  return (
    <ResourceContainer
      loading={isLoading}
      LoaderComponent={<DetailPageLoader />}
      isEmpty={!transactionDetail}
      errorCode={error}
      ServerErrorComponent={
        <TransactionServerError transactionId={transactionId} />
      }
      resourceName='transaction'
    >
      <PageLayout path={path}>
        <HeaderComponent transaction={transactionDetail || {}} />
        <ConnectWithQualia transactionId={transactionDetail?.id!} />
        {!!transactionDetail && !!transactionDetail.usingTitle && (
          <Switch>
            <Route path={match.path}>
              <Switch>
                <Route path={tabsPathRegex} exact>
                  <ZenRouterTabs tabs={transactionTabs} stickyTabs />
                </Route>
                <Route
                  path={`${match.path}/*`}
                  component={() => (
                    <Redirect
                      to={`/transactions/${transactionDetail?.id}/title-progress`}
                    />
                  )}
                />
              </Switch>
            </Route>
          </Switch>
        )}
      </PageLayout>
    </ResourceContainer>
  );
};

export default ZenRealTitleOfficerTransactionDetailRoute;
