import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';

interface paidAtProps {
  paidAt: number;
}

export const PaidAtLabel: React.FC<paidAtProps> = ({ paidAt }) => {
  return (
    <div className='inline-block py-1 px-3 rounded bg-success bg-opacity-20 text-success'>
      <div className='flex items-center'>
        <FontAwesomeIcon icon={faCheck} title='check' />
        <p className='font-primary-medium text-sm px-1'>
          Paid on {DateTime.fromMillis(paidAt! || 0).toFormat('LL/dd/yy')}
        </p>
      </div>
    </div>
  );
};
