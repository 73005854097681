import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CalendarControllerApi,
  CalendarCountOverviewResponse,
  CalendarCreateRequest,
  CalendarEventInviteePaginatedResponse,
  CalendarEventInviteeResponseRsvpStatusEnum,
  CalendarEventResponse,
  CalendarEventResponseRsvpStatusEnum,
  CalendarEventUpdateRequest,
  CalendarInfosResponse,
  CalendarInviteesCountResponse,
  CalendarResponse,
  CalendarUpdateRequest,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import {
  AppDispatch,
  AppThunk,
  AsyncResponse,
  CalendarState,
  ErrorCode,
} from '../types';
import { EventTabEnum } from '../utils/EventHelper';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from './ErrorSlice';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { performAsyncRequest } from './utils/SliceUtil';

interface saveEventsPayloadActionType {
  tab: EventTabEnum;
  events: CalendarEventResponse[];
}

interface removeEventPayloadActionType {
  tab: EventTabEnum;
  event: CalendarEventResponse;
  status: CalendarEventResponseRsvpStatusEnum;
}

interface saveEventInviteesPayloadActionType {
  status: CalendarEventInviteeResponseRsvpStatusEnum;
  data: CalendarEventInviteePaginatedResponse;
}

export const initialState: CalendarState = {
  calendars: [],
  calendarLoading: false,
  calendarErrorCode: null,
  agentCalendarsResponse: { loading: false, name: 'agent calendars' },
  eventsByTab: {},
  eventDetail: undefined,
  eventsCountResponse: { loading: false, name: 'events count' },
  pagedEventInviteesByStatus: {},
  eventInviteesCount: undefined,
  eventInviteesLoading: false,
  eventInviteesErrorCode: null,
  allEventsResponse: { loading: false, name: 'events' },
};

const CalendarSlice = createSlice({
  name: 'Calendar',
  initialState,
  reducers: {
    changeCalendarLoading(state, action: PayloadAction<boolean>) {
      state.calendarLoading = action.payload;
    },
    errorFetchingCalendarErrorCode(state, action: PayloadAction<ErrorCode>) {
      state.calendarErrorCode = action.payload;
    },
    saveCalendars(state, action: PayloadAction<CalendarResponse[]>) {
      state.calendars = action.payload;
    },
    saveAgentCalendarsResponse(
      state,
      action: PayloadAction<AsyncResponse<CalendarInfosResponse>>,
    ) {
      state.agentCalendarsResponse = action.payload;
    },
    addCalendar(state, action: PayloadAction<CalendarResponse>) {
      state.calendars = [...state.calendars, action.payload];
    },
    editCalendar(state, action: PayloadAction<CalendarResponse>) {
      const calendarIndex = state.calendars?.findIndex(
        (calendar) => calendar?.calendarId === action.payload.calendarId,
      );

      if (calendarIndex !== -1) {
        state.calendars![calendarIndex!] = action.payload;
      }
    },
    editEvent(state, action: PayloadAction<CalendarEventResponse>) {
      const eventIndex = state.allEventsResponse?.data?.findIndex(
        (event) => event?.id === action.payload.id,
      );

      if (typeof eventIndex !== 'undefined' && eventIndex !== -1) {
        state.allEventsResponse.data![eventIndex!] = action.payload;
      }
    },
    saveEventsByTab(state, action: PayloadAction<saveEventsPayloadActionType>) {
      state.eventsByTab[action.payload.tab] = action.payload.events;
    },
    removeEventByTab(
      state,
      action: PayloadAction<removeEventPayloadActionType>,
    ) {
      const { tab, event, status } = action.payload;

      state.eventsByTab[tab] = [...(state.eventsByTab[tab] || [])].filter(
        (e) => e.id !== event.id,
      );

      state.eventsByTab[status] = [
        ...(state.eventsByTab[status] || []),
        { ...event, rsvpStatus: status },
      ];
    },
    saveAllEventsResponse(
      state,
      action: PayloadAction<AsyncResponse<CalendarEventResponse[]>>,
    ) {
      state.allEventsResponse = action.payload;
    },
    saveEventDetail(
      state,
      action: PayloadAction<CalendarEventResponse | undefined>,
    ) {
      state.eventDetail = action.payload;
    },
    saveEventsCountResponse(
      state,
      action: PayloadAction<AsyncResponse<CalendarCountOverviewResponse>>,
    ) {
      state.eventsCountResponse = action.payload;
    },
    saveEventInviteesCount(
      state,
      action: PayloadAction<CalendarInviteesCountResponse>,
    ) {
      state.eventInviteesCount = action.payload;
    },
    changeEventInviteesLoading(state, action: PayloadAction<boolean>) {
      state.eventInviteesLoading = action.payload;
    },
    errorFetchingEventInviteesErrorCode(
      state,
      action: PayloadAction<ErrorCode>,
    ) {
      state.eventInviteesErrorCode = action.payload;
    },
    savePagedEventInviteesByStatus(
      state,
      action: PayloadAction<saveEventInviteesPayloadActionType>,
    ) {
      const { status, data } = action.payload;

      if (data.pageNumber === 0) {
        state.pagedEventInviteesByStatus[status] = data;
      } else {
        state.pagedEventInviteesByStatus[status] = {
          ...state.pagedEventInviteesByStatus[status],
          hasNext: data?.hasNext,
          pageNumber: data?.pageNumber,
          invitees: [
            ...(state.pagedEventInviteesByStatus[status]!.invitees || []),
            ...(data.invitees || []),
          ],
        };
      }
    },
  },
});

export const {
  changeCalendarLoading,
  errorFetchingCalendarErrorCode,
  saveCalendars,
  saveAgentCalendarsResponse,
  addCalendar,
  editCalendar,
  saveAllEventsResponse,
  editEvent,
  saveEventsByTab,
  removeEventByTab,
  saveEventsCountResponse,
  saveEventDetail,
  saveEventInviteesCount,
  savePagedEventInviteesByStatus,
  changeEventInviteesLoading,
  errorFetchingEventInviteesErrorCode,
} = CalendarSlice.actions;

export const fetchCalendars = (): AppThunk => async (dispatch) => {
  dispatch(changeCalendarLoading(true));
  try {
    const { data } = await new CalendarControllerApi(
      getYentaConfiguration(),
    ).getAllCalendarInfos();
    dispatch(saveCalendars(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch calendars', e);
    dispatch(errorFetchingCalendarErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem fetching calendars.',
        'Please try again in a few moments.',
      ),
    );
  } finally {
    dispatch(changeCalendarLoading(false));
  }
};

export const fetchAgentCalendars = (agentId: string): AppThunk => async (
  dispatch,
) => {
  const fetch = () =>
    new CalendarControllerApi(getYentaConfiguration()).getCalendarsForAgent(
      agentId,
    );

  await performAsyncRequest(
    dispatch as AppDispatch,
    'agent calendars',
    saveAgentCalendarsResponse,
    fetch,
  );
};

export const createCalendar = (
  calendarCreateRequest: CalendarCreateRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeCalendarLoading(true));
  try {
    const { data } = await new CalendarControllerApi(
      getYentaConfiguration(),
    ).createCalendar(calendarCreateRequest);
    dispatch(addCalendar(data));
    dispatch(showSuccessToast('Calendar created successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create calendars', e);
    dispatch(errorFetchingCalendarErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem creating calendars.',
        'Please try again in a few moments.',
      ),
    );
  } finally {
    dispatch(changeCalendarLoading(false));
  }
};

export const updateCalendar = (
  calendarId: string,
  calendarUpdateRequest: CalendarUpdateRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeCalendarLoading(true));
  try {
    const { data } = await new CalendarControllerApi(
      getYentaConfiguration(),
    ).updateCalendar(calendarId, calendarUpdateRequest);
    dispatch(editCalendar(data));
    dispatch(showSuccessToast('Calendar updated successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to sync calendar', e);
    dispatch(errorFetchingCalendarErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem syncing calendar.',
        'Please try again in a few moments.',
      ),
    );
  } finally {
    dispatch(changeCalendarLoading(false));
  }
};

export const syncAllCalendars = (): AppThunk => async (dispatch) => {
  dispatch(changeCalendarLoading(true));
  try {
    const { data } = await new CalendarControllerApi(
      getYentaConfiguration(),
    ).syncAllCalendars();
    dispatch(saveCalendars(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to sync all calendars', e);
    dispatch(errorFetchingCalendarErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem syncing all calendars.',
        'Please try again in a few moments.',
      ),
    );
  } finally {
    dispatch(changeCalendarLoading(false));
  }
};

export const syncCalendar = (calendarId: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeCalendarLoading(true));
  try {
    const { data } = await new CalendarControllerApi(
      getYentaConfiguration(),
    ).syncCalendar(calendarId);
    dispatch(editCalendar(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to sync calendar', e);
    dispatch(errorFetchingCalendarErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem syncing calendar.',
        'Please try again in a few moments.',
      ),
    );
  } finally {
    dispatch(changeCalendarLoading(false));
  }
};

export const fetchAllEvents = (calendarIds?: string[]): AppThunk => async (
  dispatch,
) => {
  const fetch = () =>
    new CalendarControllerApi(getYentaConfiguration()).getAllEvents(
      calendarIds,
    );

  await performAsyncRequest(
    dispatch as AppDispatch,
    'events',
    saveAllEventsResponse,
    fetch,
    {
      skipAuthDatadog: true,
    },
  );
};

export const fetchEventsByType = (
  tab: EventTabEnum,
  yentaId: string,
  calendarIds?: string[],
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new CalendarControllerApi(
      getYentaConfiguration(),
    ).getEventsForAgent(tab, yentaId, calendarIds);
    dispatch(saveEventsByTab({ tab, events: data }));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(`Unable to fetch ${tab.toLowerCase()} events`, e);
    dispatch(
      showErrorToast(
        `We had a problem fetching ${tab.toLowerCase()} events.`,
        'Please try again in a few moments.',
      ),
    );
  }
};

export const fetchEventsCountForAgent = (
  yentaId: string,
  calendarIds?: string[],
  changeLoading = true,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new CalendarControllerApi(getYentaConfiguration()).countEventsForAgent(
      yentaId,
      calendarIds,
    );

  await performAsyncRequest(
    dispatch as AppDispatch,
    'events count',
    saveEventsCountResponse,
    fetch,
    {
      errorMetadata: {
        agent: { id: yentaId, calendars: { ids: calendarIds } },
      },
      changeLoading,
    },
  );
};

export const updateEvent = (
  calendarEventId: string,
  calendarEventUpdateRequest: CalendarEventUpdateRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new CalendarControllerApi(
      getYentaConfiguration(),
    ).updateCalendarEvent(calendarEventId, calendarEventUpdateRequest);
    dispatch(editEvent(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update event', e);
    dispatch(errorFetchingCalendarErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem updating event.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const uploadEventImage = (
  calendarEventId: string,
  imageFile: File,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new CalendarControllerApi(
      getYentaConfiguration(),
    ).updateCalendarEventImage(calendarEventId, imageFile);
    dispatch(editEvent(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to upload event image', e);
    dispatch(errorFetchingCalendarErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem uploading event image.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const rsvpToCalendarEvent = (
  event: CalendarEventResponse,
  yentaId: string,
  status: CalendarEventResponseRsvpStatusEnum,
  isAdmin: boolean,
  calendarIds?: string[],
  tab?: EventTabEnum,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new CalendarControllerApi(
      getYentaConfiguration(),
    ).rsvpToCalendarEvent(event.id!, yentaId, status);

    await dispatch(saveEventDetail(data));

    if (isAdmin) {
      await dispatch(editEvent(data));
    } else if (tab) {
      await dispatch(removeEventByTab({ tab, event, status }));

      await dispatch(fetchEventsCountForAgent(yentaId, calendarIds, false));
    }
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to change calendar rsvp status', e);
    dispatch(errorFetchingCalendarErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem changing calendar rsvp status.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const deleteEventImage = (calendarEventId: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new CalendarControllerApi(
      getYentaConfiguration(),
    ).deleteCalendarEventImage(calendarEventId);
    await dispatch(editEvent(data));
    dispatch(showSuccessToast('Event image deleted successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to delete event image', e);
    dispatch(errorFetchingCalendarErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem deleting event image.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const fetchEventInviteesCount = (
  calendarEventId: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new CalendarControllerApi(
      getYentaConfiguration(),
    ).getCalendarEventInviteesCount(calendarEventId);
    await dispatch(saveEventInviteesCount(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch event invitees count', e);
    dispatch(
      showErrorToast(
        'We had a problem fetching event invitees count.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const fetchEventInviteesByStatus = (
  calendarEventId: string,
  status: CalendarEventInviteeResponseRsvpStatusEnum,
  pageNumber: number,
  pageSize: number,
  refresh: boolean = false,
): AppThunk => async (dispatch) => {
  if (refresh) {
    dispatch(changeEventInviteesLoading(true));
  }

  try {
    const { data } = await new CalendarControllerApi(
      getYentaConfiguration(),
    ).getCalendarEventInviteesPaginated(
      calendarEventId,
      status,
      pageNumber,
      pageSize,
    );
    dispatch(savePagedEventInviteesByStatus({ status, data }));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(
      `Unable to fetch ${status.toLowerCase()} event invitees`,
      e,
    );
    dispatch(errorFetchingEventInviteesErrorCode(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        `We had a problem fetching ${status.toLowerCase()} event invitees.`,
        'Please try again in a few moments.',
      ),
    );
  } finally {
    if (refresh) {
      dispatch(changeEventInviteesLoading(false));
    }
  }
};

export const fetchEventDetail = (calendarEventId: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new CalendarControllerApi(
      getYentaConfiguration(),
    ).getCalendarEvent(calendarEventId);
    dispatch(saveEventDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(`Unable to fetch event detail`, e);
    dispatch(
      showErrorToast(
        `We had a problem fetching event detail.`,
        'Please try again in a few moments.',
      ),
    );
  }
};

export default CalendarSlice.reducer;
