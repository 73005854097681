import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Control, UseFormSetValue } from 'react-hook-form-v7';
import { FlexTeamDto } from '../../../../openapi/yenta';
import { MONEY_VALIDATIONS } from '../../../../utils/Validations';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenSidebarModalActionFooterV2 from '../../Modal/ZenSidebarModalActionFooterV2';
import ZenButton from '../../ZenButton';
import TeamCapPopover from './ProTeamPopover/TeamCapPopover';
import TeamPostCapFeePopover from './ProTeamPopover/TeamPostCapFeePopover';
import TeamPreCapFeePopover from './ProTeamPopover/TeamPreCapFeePopover';
import { ProTeamMember } from './ZenEditProTeamMember';

interface ZenTeamMemberTeamFeesTabProps {
  control: Control<ProTeamMember, object>;
  onSubmit(): any;
  onClose(): void;
  isSubmitting?: boolean;
  buttonText: string;
  setValue: UseFormSetValue<ProTeamMember>;
  team: FlexTeamDto;
  readOnly?: boolean;
}

const ZenTeamMemberTeamFeesTab: React.FC<ZenTeamMemberTeamFeesTabProps> = ({
  control,
  onSubmit,
  onClose,
  buttonText,
  isSubmitting = false,
  setValue,
  team,
  readOnly,
}) => {
  const resetToDefault = () => {
    setValue('capAmount', team.teamCap?.capAmount?.toString() ?? '');
    setValue('teamPreCapFee', team.teamCap?.preCapFee?.toString() ?? '');
    setValue('teamPostCapFee', team.teamCap?.postCapFee?.toString() ?? '');
  };
  return (
    <div className='flex flex-col justify-between max-h-[95%] pb-14'>
      <div className='p-5 max-h-full overflow-y-auto'>
        <div className='p-5'>
          <div className='font-normal font-primary-light text-sm text-zen-dark-7'>
            Choose a team cap amount. This is the default that will be used for
            all the Teammates. This can also be adjusted individually for each
            teammate when editing their personal settings.
          </div>
          <div className='flex flex-row justify-between items-center mt-5'>
            <TeamCapPopover />
            <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
              <ZenControlledTextInput<ProTeamMember, 'capAmount'>
                control={control}
                name='capAmount'
                shouldUnregister={false}
                placeholder='Team Cap'
                startAdornment={
                  <div className='flex w-full h-full items-center justify-center pl-2'>
                    <FontAwesomeIcon
                      className='text-primary-blue'
                      icon={solid('dollar')}
                    />
                  </div>
                }
                isRequired
                readOnly={readOnly}
                rules={{
                  ...MONEY_VALIDATIONS,
                  min: {
                    value: 1,
                    message: 'Team Cap must be greater than 0',
                  },
                  required: 'Please enter a team cap amount',
                }}
              />
            </div>
          </div>
          <div className='mt-5'>
            <div className='font-normal font-primary-medium text-lg text-zen-dark-9'>
              Fixed Fees
            </div>
            <div className='h-px bg-zen-dark-5 mt-2' />
            <div className='mt-5 font-inter text-sm text-zen-dark-7'>
              Choose team pre cap & post cap fee amount. This is the default
              that will be used for all the Teammates. This can also be adjusted
              individually for each teammate when editing their personal
              settings.
            </div>
            <div className='space-y-2'>
              <div className='flex flex-row justify-between items-center mt-5'>
                <TeamPreCapFeePopover />
                <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                  <ZenControlledTextInput<ProTeamMember, 'teamPreCapFee'>
                    label='Agent Pays to Real'
                    control={control}
                    name='teamPreCapFee'
                    shouldUnregister={false}
                    placeholder='Team Pre Cap Fee'
                    readOnly={readOnly}
                    startAdornment={
                      <div className='flex w-full h-full items-center justify-center pl-2'>
                        <FontAwesomeIcon
                          className='text-primary-blue'
                          icon={solid('dollar')}
                        />
                      </div>
                    }
                    isRequired
                    rules={{
                      ...MONEY_VALIDATIONS,
                      required: 'Please enter a team pre cap fee amount',
                    }}
                  />
                </div>
              </div>
              <div className='flex flex-row justify-between items-center'>
                <TeamPostCapFeePopover />
                <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                  <ZenControlledTextInput<ProTeamMember, 'teamPostCapFee'>
                    control={control}
                    name='teamPostCapFee'
                    shouldUnregister={false}
                    placeholder='Team Post Cap Fee'
                    readOnly={readOnly}
                    startAdornment={
                      <div className='flex w-full h-full items-center justify-center pl-2'>
                        <FontAwesomeIcon
                          className='text-primary-blue'
                          icon={solid('dollar')}
                        />
                      </div>
                    }
                    isRequired
                    rules={{
                      ...MONEY_VALIDATIONS,
                      required: 'Please enter a team post cap fee amount',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-end'>
            <ZenButton
              variant='primary-link'
              label='Reset All to Defaults'
              onClick={resetToDefault}
            />
          </div>
        </div>
      </div>
      <ZenSidebarModalActionFooterV2
        onClose={onClose}
        buttonType='submit'
        onClick={onSubmit}
        isDisabled={isSubmitting || readOnly}
        isSubmitting={isSubmitting}
        submitButtonText={buttonText}
      />
    </div>
  );
};

export default ZenTeamMemberTeamFeesTab;
