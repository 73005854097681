import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MfaControllerApi } from '../../../openapi/keymaker';
import ErrorService from '../../../services/ErrorService';
import { showSuccessToast } from '../../../slices/ToastNotificationSlice';
import { getKeymakerConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import Button from '../../Button';
import ConfirmationModal from '../../ConfirmationModal';

interface AdminDelete2FAModalProps {
  isOpen: boolean;
  onClose(): void;
  keymakerId: string;
}

const AdminDelete2FAModal: React.FC<AdminDelete2FAModalProps> = ({
  isOpen,
  onClose,
  keymakerId,
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      await new MfaControllerApi(getKeymakerConfiguration()).deleteMfaByUserId(
        keymakerId,
      );
      dispatch(
        showSuccessToast(
          'Successfully deleted two factor authentication for this user.',
        ),
      );
    } catch (e) {
      ErrorService.notifyIgnoreAuthErrors(
        'Unable to delete two factor authentication',
        e,
        {
          data: { keymakerId },
        },
      );
    } finally {
      onClose();
      setIsSubmitting(false);
    }
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      variant='danger'
      title='Delete Two-Factor Authentication'
      size='large'
    >
      <p className='font-primary-regular text-gray-500 my-3'>
        By removing this protection, this account will be less secure. Are you
        sure you want to delete?
      </p>
      <div className='flex flex-row justify-between space-x-5 mt-5'>
        <Button
          label='Cancel'
          type='outline'
          className='!text-gray-400 !py-2'
          fullWidth
          onClick={onClose}
        />
        <Button
          label='Delete'
          onClick={onSubmit}
          type='danger'
          className='!py-2'
          fullWidth
          isSubmitting={isSubmitting}
        />
      </div>
    </ConfirmationModal>
  );
};

export default AdminDelete2FAModal;
