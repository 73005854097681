import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'react-table';
import DefaultLoader from './DefaultLoader';
import Hover from './Hover';

export interface ActionBarButtonsProps<D extends object> {
  hoverText: string;
  icon: React.ReactElement;
  onClick: (docs: D[]) => void;
  isLoading?: boolean;
}

interface TableRowActionBarProps<D extends object> {
  items: Row<D>[];
  handleCancel: () => void;
  actionBarButtons?: Array<ActionBarButtonsProps<D>>;
}

const TableRowActionBar = <D extends object>({
  items,
  handleCancel,
  actionBarButtons,
}: TableRowActionBarProps<D>) => {
  return (
    <div
      className='h-10 flex items-center pl-6 rounded-full bg-grey-100'
      title='table-action-bar'
    >
      <div
        className='mr-5 cursor-pointer flex items-center'
        onClick={handleCancel}
      >
        <FontAwesomeIcon
          icon={faTimes}
          className='text-xl text-zen-dark-9'
          aria-label='download-all-documents'
        />
      </div>
      <div className='mr-10 font-zen-body'>{items?.length} Selected</div>

      {actionBarButtons?.map((button) => (
        <div
          className='mr-11 cursor-pointer'
          key={button.hoverText}
          onClick={() => {
            button.onClick(items.map((item) => item.original));
          }}
        >
          <Hover
            hoverComponent={
              <div className='text-zen-dark-12 font-zen-body text-base px-3 whitespace-pre'>
                {button.hoverText}
              </div>
            }
            config={{ trigger: 'hover', placement: 'top-start' }}
          >
            <div className='flex items-center'>
              {button?.isLoading ? (
                <DefaultLoader noPadding iconSize='small' isDark />
              ) : (
                button.icon
              )}
            </div>
          </Hover>
        </div>
      ))}
    </div>
  );
};

export default TableRowActionBar;
