import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column, Row } from 'react-table';
import ZenAddAgentCreditFormSidebarModal from '../../../forms/ZenAddAgentCreditFormSidebarModal';
import { useRefresh } from '../../../hooks/useRefresh';
import { CreditResponse } from '../../../openapi/arrakis';
import { AgentResponse } from '../../../openapi/yenta';
import { fetchAgentCreditsForOverview } from '../../../slices/AgentSlice';
import { AsyncResponse, RootState } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import ConfirmDeleteRecordModal from '../../ConfirmDeleteRecordModal';
import ZenResourceIndexContainer from '../../Zen/Containers/ZenResourceIndexContainer';
import ZenDateCell from '../../Zen/Table/Cell/ZenDateCell';
import ZenAdd from '../../Zen/ZenAdd';
import ZenCard from '../../Zen/ZenCard';
import ZenView from '../../Zen/ZenView';
import ZenResourceContainer from '../../Zen/ZenResourceContainer';

export const columns: Array<Column<CreditResponse>> = [
  {
    Header: 'Title',
    accessor: 'title',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => capitalizeEnum(value!),
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }: { row: Row<CreditResponse> }) =>
      row.original.amount
        ? displayAmount(row.original.amount)
        : row.original.number,
  },
  {
    Header: 'Issued On',
    accessor: 'issuedOn',
    Cell: ({ value }) => <ZenDateCell date={value} />,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Issuer Note',
    accessor: 'issuerNote',
    disableFilters: true,
    disableSortBy: true,
  },
];

interface ZenAgentCreditsProps {
  agentResponse: AsyncResponse<AgentResponse>;
}

const ZenAgentCredits: React.FC<ZenAgentCreditsProps> = ({ agentResponse }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { agentCredits } = useSelector((state: RootState) => state.agentDetail);
  const { key, refresh } = useRefresh();
  const [addAgentCredit, setAddAgentCredit] = useState<boolean>(false);
  const [
    deletingAgentCredit,
    setDeletingAgentCredit,
  ] = useState<CreditResponse | null>(null);

  const agent = agentResponse?.data;
  const totalCount = agentCredits?.data?.totalCount || 0;

  useEffect(() => {
    dispatch(fetchAgentCreditsForOverview(agent?.id!));
  }, [agent?.id, dispatch, key]);

  return (
    <ZenCard
      title='Credits'
      RightComponent={
        <div className='flex flex-row items-center space-x-4'>
          <ZenAdd onClick={() => setAddAgentCredit(true)} />
          {totalCount > 10 && (
            <ZenView
              text='View All'
              onClick={() => history.push(`/people/${agent?.id}/credits`)}
            />
          )}
        </div>
      }
    >
      <AuthorizationContainer asyncResponse={agentCredits}>
        <ZenResourceContainer
          loading={agentCredits?.loading}
          isEmpty={false}
          resourceName='agent credits'
        >
          <ZenResourceIndexContainer<CreditResponse>
            key={key}
            resourceName='credit'
            headerVariant='light'
            columns={columns}
            data={agentCredits?.data?.results || []}
            initialSort={{ issuedOn: 'desc' }}
            hideFilters
            hidePageSize
            hidePagination
            standalone={false}
          />
        </ZenResourceContainer>

        {!!addAgentCredit && (
          <ZenAddAgentCreditFormSidebarModal
            isOpen={addAgentCredit}
            onClose={() => setAddAgentCredit(false)}
            agent={agent!}
            refreshCredits={refresh}
          />
        )}

        <ConfirmDeleteRecordModal
          onDelete={() => {}}
          title='Are you sure?'
          subtitle='This agent credit will be permanently deleted.'
          isOpen={!!deletingAgentCredit}
          onClose={() => setDeletingAgentCredit(null)}
        />
      </AuthorizationContainer>
    </ZenCard>
  );
};
export default React.memo(ZenAgentCredits);
