import { values } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  AttachedFeeRequest,
  AttachedFeeRequestFeeTypeEnum,
  AttachedFeeRequestRecipientTypeEnum,
  AttachedFeeValue,
  AttachedFeeValueFeeTypeEnum,
  MoneyValue,
  MoneyValueCurrencyEnum,
  TransactionResponse,
} from '../../openapi/arrakis';
import { toggleAdditionalFee } from '../../slices/QuickActionSlice';
import {
  addAttachedFee,
  updateAttachedFee,
} from '../../slices/TransactionSlice';
import { RootState, YesNoType } from '../../types';
import { capitalizeEnum, MONEY_AMOUNT_REGEX } from '../../utils/StringUtils';
import { getParticipantsBasedOnFeeType } from '../../utils/TransactionHelper';
import Button from '../Button';
import ControlledMoneyInputV7 from '../ControlledMoneyInputV7';
import ControlledSelectInputV7 from '../ControlledSelectInputV7';
import ControlledTextInputV7 from '../ControlledTextInputV7';
import SidebarModal from '../SidebarModal';

interface AddAttachedFeeProps {
  isOpen: boolean;
  onClose(): void;
  attachedFee?: AttachedFeeValue;
  transaction: TransactionResponse;
  preselectedFeeType?: AttachedFeeValueFeeTypeEnum;
}

interface FormData {
  amount: MoneyValue;
  description: string;
  feeType: AttachedFeeRequestFeeTypeEnum;
  participantId: string;
  passthrough: YesNoType;
  transactionCoordinator: string;
  recipientType: AttachedFeeRequestRecipientTypeEnum;
}

const AddAttachedFee: React.FC<AddAttachedFeeProps> = ({
  isOpen,
  onClose,
  attachedFee,
  transaction,
  preselectedFeeType,
}) => {
  const dispatch = useDispatch();
  const {
    transactionDetailResponse: { data: transactionDetail },
  } = useSelector((state: RootState) => state.transaction);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      amount: {
        currency:
          attachedFee?.amount?.currency ||
          ((transaction.currency as unknown) as MoneyValueCurrencyEnum),
        amount: attachedFee?.amount?.amount,
      },
      description: attachedFee?.description?.replace(
        'Transaction Coordinator: ',
        '',
      ),
      feeType: ((preselectedFeeType ||
        attachedFee?.feeType ||
        '') as unknown) as AttachedFeeRequestFeeTypeEnum,
      participantId: attachedFee?.counterpartyId || '',
      recipientType:
        ((attachedFee?.recipientType as unknown) as AttachedFeeRequestRecipientTypeEnum) ||
        '',
    },
  });

  const feeType = watch('feeType');
  const participantId = watch('participantId', attachedFee?.counterpartyId);
  const [participantLabel, setParticipantLabel] = useState('Participant');
  const [descriptionLabel, setDescriptionLabel] = useState('Description');

  useEffect(() => {
    setDescriptionLabel('Description');
    if (
      feeType ===
      ((AttachedFeeValueFeeTypeEnum.AdditionalCommission as unknown) as AttachedFeeRequestFeeTypeEnum)
    ) {
      setParticipantLabel('Who is receiving this amount?');
    }
    if (
      feeType ===
      ((AttachedFeeValueFeeTypeEnum.Mls as unknown) as AttachedFeeRequestFeeTypeEnum)
    ) {
      setParticipantLabel('Who is paying this fee?');
    }
    if (
      feeType ===
      ((AttachedFeeValueFeeTypeEnum.Reimbursement as unknown) as AttachedFeeRequestFeeTypeEnum)
    ) {
      setParticipantLabel('Who is getting reimbursed?');
    }
    if (
      feeType ===
      ((AttachedFeeValueFeeTypeEnum.TransactionCoordinator as unknown) as AttachedFeeRequestFeeTypeEnum)
    ) {
      setParticipantLabel('Who is paying this fee?');
      setDescriptionLabel('Transaction Coordinator Name');
    }
    if (
      feeType ===
      ((AttachedFeeValueFeeTypeEnum.Rebate as unknown) as AttachedFeeRequestFeeTypeEnum)
    ) {
      setParticipantLabel('Who is offering the rebate?');
    }
  }, [attachedFee, feeType, participantId, setValue, transactionDetail]);

  const onSubmit = async (data: FormData) => {
    if (attachedFee) {
      const finalData: AttachedFeeRequest = {
        amount: {
          currency: attachedFee?.amount?.currency,
          amount: +data.amount.amount!,
        },
        description:
          data.feeType === AttachedFeeRequestFeeTypeEnum.TransactionCoordinator
            ? `Transaction Coordinator: ${data.description}`
            : data.description,
        feeType: data.feeType,
        participantId: data.participantId,
        recipientType: data.recipientType || undefined,
      };
      await dispatch(
        updateAttachedFee(attachedFee?.id!, transaction?.id!, finalData),
      );
    } else {
      const finalData: AttachedFeeRequest = {
        amount: {
          amount: +data.amount.amount!,
          currency: data.amount.currency,
        },
        description:
          data.feeType === AttachedFeeRequestFeeTypeEnum.TransactionCoordinator
            ? `Transaction Coordinator: ${data.description}`
            : data.description,
        feeType: data.feeType,
        participantId: data.participantId,
        recipientType: data.recipientType || undefined,
      };
      await dispatch(addAttachedFee(transaction?.id!, finalData));
    }
    dispatch(toggleAdditionalFee({ isOpen: false }));
    onClose();
  };

  return (
    <SidebarModal
      title={
        attachedFee?.id
          ? 'Edit Additional Fee / Rebate'
          : 'Add Additional Fee / Rebate'
      }
      subtitle={transactionDetail?.address?.oneLine}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='fee-form'
      >
        <div className='p-4'>
          <div>
            <ControlledSelectInputV7<FormData, 'feeType'>
              control={control}
              label='Fee Type'
              name='feeType'
              placeholder='Fee Type'
              rules={{ required: 'Fee Type is required' }}
              options={[
                { label: 'Fee Type', disabled: true, value: '' },
                ...values(AttachedFeeRequestFeeTypeEnum).map((c) => ({
                  value: c,
                  label: capitalizeEnum(c),
                })),
              ]}
            />
          </div>
          <div className='mt-5'>
            <ControlledSelectInputV7<FormData, 'participantId'>
              control={control}
              name='participantId'
              placeholder='Search Member'
              label={participantLabel}
              options={[
                { label: 'Participant', disabled: true, value: '' },
                ...getParticipantsBasedOnFeeType(transactionDetail!),
              ]}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledMoneyInputV7<FormData, 'amount'>
              control={control}
              label='Enter Amount *'
              name='amount'
              placeholder='2000'
              rules={{
                validate: (v: MoneyValue) => {
                  if (!!v && !v?.amount) {
                    return 'Please enter a amount';
                  } else {
                    return !MONEY_AMOUNT_REGEX?.test(String(v?.amount))
                      ? 'Please enter a valid amount'
                      : undefined;
                  }
                },
              }}
              currencyReadOnly
            />
          </div>
          {feeType === AttachedFeeRequestFeeTypeEnum.Rebate && (
            <div className='mt-5'>
              <ControlledSelectInputV7<FormData, 'recipientType'>
                control={control}
                label='Recipient Type'
                name='recipientType'
                placeholder='recipient type'
                rules={{
                  required: 'Please select recipient type',
                }}
                options={[
                  {
                    label: 'Select recipient type',
                    value: '',
                    disabled: true,
                  },
                  ...values(AttachedFeeRequestRecipientTypeEnum).map(
                    (type) => ({
                      label: capitalizeEnum(type),
                      value: type,
                    }),
                  ),
                ]}
              />
            </div>
          )}
          <div className='mt-5'>
            <ControlledTextInputV7<FormData, 'description'>
              name='description'
              control={control}
              label={descriptionLabel}
              placeholder={descriptionLabel}
              rules={{
                required: 'Required',
              }}
            />
          </div>
        </div>
        <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2 w-full'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Save'
            size='lg'
            isSubmitting={isSubmitting}
          />
          <Button
            buttonType='button'
            onClick={onClose}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default AddAttachedFee;
