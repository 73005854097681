import { useEffect, useState } from 'react';
import { FaFile } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { BsCloudArrowDown } from 'react-icons/bs';
import { FileResponse } from '../../../openapi/dropbox';
import { TemplateReferenceDto } from '../../../openapi/sherlock';
import { downloadDropboxFileByVersion } from '../../../slices/DropboxSlice';
import { Mapping, RootState } from '../../../types';
import { getDropboxFilesFromTemplateReferences } from '../../../utils/DropboxUtil';
import ZenFixedDataTable from '../ZenFixedDataTable';
import DefaultLoader from '../../DefaultLoader';
import ResourceContainer from '../../ResourceContainer';

export interface ZenChecklistItemEmbeddedDocumentsListProps {
  templateReferences: TemplateReferenceDto[];
}

const ZenChecklistItemEmbeddedDocumentsList: React.FC<ZenChecklistItemEmbeddedDocumentsListProps> = ({
  templateReferences,
}) => {
  const dispatch = useDispatch();
  const {
    dropbox: { dropboxById },
  } = useSelector((state: RootState) => state);
  const [isDownloadingDocument, setIsDownloadingDocument] = useState<
    Mapping<boolean>
  >({});
  const [checklistItemDropboxFiles, setChecklistItemDropboxFiles] = useState<
    FileResponse[]
  >([]);

  useEffect(() => {
    const dropboxFiles = getDropboxFilesFromTemplateReferences(
      templateReferences,
      dropboxById!,
    );
    setChecklistItemDropboxFiles(dropboxFiles);
  }, [templateReferences, dropboxById]);

  const handleOpenFile = async (dropboxFile: FileResponse) => {
    setIsDownloadingDocument((map) => ({ ...map, [dropboxFile?.id!]: true }));
    await dispatch(
      downloadDropboxFileByVersion(
        dropboxFile?.id!,
        dropboxFile?.currentVersion?.id!,
      ),
    );
    setIsDownloadingDocument((map) => ({
      ...map,
      [dropboxFile?.id!]: false,
    }));
  };

  const columns: Array<Column<FileResponse>> = [
    {
      Header: 'Template Name',
      accessor: 'filename',
      disableSortBy: true,
      Cell: ({ value }) => (
        <div className='flex'>
          <div className='mt-1'>
            <div className='border border-zen-dark-5 rounded p-1'>
              <FaFile className='text-zen-dark-5' />
            </div>
          </div>
          <div className='inline-block ml-2'>
            <div className='text-sm font-zen-body font-normal text-zen-dark-9'>
              {value}
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: 'Template Description',
      accessor: 'description',
      disableSortBy: true,
      Cell: ({ value }) => (
        <div className='text-sm font-zen-body font-normal text-zen-dark-9'>
          {value}
        </div>
      ),
    },
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ value, row: { original } }) => (
        <ResourceContainer
          loading={isDownloadingDocument[value!]!}
          isEmpty={false}
          resourceName='download-item'
          LoaderComponent={
            <div className='flex justify-end'>
              <div className='w-[35px] h-[35px]'>
                <DefaultLoader noPadding iconSize='small' />
              </div>
            </div>
          }
        >
          <div className='flex justify-end'>
            <button
              onClick={() => handleOpenFile(original)}
              className='bg-zen-light-gray-2 hover:bg-zen-dark-4 rounded-full min-w-[35px] min-h-[35px] w-[35px] h-[35px] flex items-center justify-center cursor-pointer'
            >
              <BsCloudArrowDown className='text-lg text-zen-dark-8' />
            </button>
          </div>
        </ResourceContainer>
      ),
    },
  ];

  return (
    <div className='mt-5'>
      <ZenFixedDataTable<FileResponse>
        key={checklistItemDropboxFiles?.length}
        columns={columns}
        resourceName='template document'
        data={checklistItemDropboxFiles}
        hideFilters
        hidePagination
      />
    </div>
  );
};

export default ZenChecklistItemEmbeddedDocumentsList;
