import { arrayMove } from '@dnd-kit/sortable';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CellProps, Column } from 'react-table';
import {
  AgentDebtResponse,
  AgentDebtResponseRecoveryTypeEnum,
  AgentDebtResponseStatusEnum,
  DebtPrecedence,
  DebtRepaymentItemResponse,
  DebtRepaymentItemResponsePaymentSourceTypeEnum,
  DebtRepaymentItemResponseStatusEnum,
} from '../../openapi/arrakis';
import AnalyticsService from '../../services/AnalyticsService';
import {
  cancelAgentDebt,
  changeDebtPosition,
  createAgentDebtInvoice,
  fetchPublicTransactionInfo,
  getPaginatedAgentDebts,
} from '../../slices/AgentSlice';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { AnalyticsEventEnum, AppDispatch, RootState } from '../../types';
import { isAutoAgentDebt } from '../../utils/AgentHelper';
import { getTransactionIdsForPublicTransactionInfo } from '../../utils/DebtUtils';
import { cn } from '../../utils/classUtils';
import DefaultLoader from '../DefaultLoader';
import ResourceContainer from '../ResourceContainer';
import TableLoader from '../TableLoader';
import ZenResourceIndexContainer from '../Zen/Containers/ZenResourceIndexContainer';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';
import ZenMilliDateCell from '../Zen/Table/Cell/ZenMilliDateCell';
import ZenIconCircleWithTooltipCell from '../Zen/Transaction/ZenIconCircleWithTooltipCell';
import AgentOnly from '../auth/AgentOnly';
import AuthorizationContainer from '../auth/AuthorizationContainer';
import ZenViewActionButtonCell from '../table/Cells/ZenViewActionButtonCell';
import AllDebtsDeductionCell from './AllDebtsDeductionCell';
import DebtRepaymentItems from './DebtRepaymentItems';
import DebtStatus from './DebtStatus';
import DebtTypeCol from './DebtTypeCol';

interface DebtTabComponentProps {
  status: string[];
  agentId: string;
  setAgentDebtInfo: React.Dispatch<
    React.SetStateAction<AgentDebtResponse | undefined>
  >;
  setIsAddEditDebtsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HISTORY_TAB_STATUS = ['PAID', 'CANCELLED'];
export const ACTIVE_TAB_STATUS = ['ACTIVE'];

const DebtTabComponent: React.FC<DebtTabComponentProps> = ({
  status,
  agentId,
  setAgentDebtInfo,
  setIsAddEditDebtsSidebarOpen,
}) => {
  const [loading, setLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [cancelDebtId, setCancelDebtId] = useState<string | undefined>();
  const [isOpenRepaymentItemsModal, setIsOpenRepaymentItemsModal] = useState(
    false,
  );
  const [repaymentItems, setRepaymentItems] = useState<
    DebtRepaymentItemResponse[] | undefined
  >();
  const dispatch = useDispatch<AppDispatch>();
  const {
    agentDetail: {
      paginatedAgentDebtsResponse,
      debtCreateInvoiceLoading,
      publicTransactionResponseById,
    },
    auth: { isAdmin },
  } = useSelector((state: RootState) => state);

  const columns: Array<Column<AgentDebtResponse>> = [
    {
      Header: '',
      id: 'ribbon',
      accessor: 'recoveryType',
      Cell: ({ value }) => (
        <div
          className={cn(
            'absolute top-0 md:-top-0.5 left-0 border-t-[44px] border-r-[52px] border-r-transparent cursor-default',
            {
              'border-t-green-600': isAutoAgentDebt(value!),
              'border-t-primary-blue': !isAutoAgentDebt(value!),
            },
          )}
        >
          <div
            className='absolute top-[-42px] left-[4px] text-[10px] font-zen-body font-semibold text-white'
            title={isAutoAgentDebt(value!) ? 'Automatic' : 'Invoice (Stripe)'}
          >
            {isAutoAgentDebt(value!) ? 'AUTO' : 'INV'}
          </div>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: '',
      accessor: 'repaymentItems',
      Cell: ({ value, row: { original } }) => {
        const filteredRepaymentItems = (value || [])?.filter(
          (item) => item?.status === DebtRepaymentItemResponseStatusEnum.Paid,
        );

        if (
          isAutoAgentDebt(original?.recoveryType!) &&
          !!filteredRepaymentItems?.length
        )
          return (
            <ZenViewActionButtonCell
              onClick={() => {
                AnalyticsService.instance().logEvent(
                  AnalyticsEventEnum.AGENT_OWES_REAL_DEBT_ITEM_CLICKED,
                  {
                    debtType: original?.debtType,
                  },
                );
                setRepaymentItems(filteredRepaymentItems);
                setIsOpenRepaymentItemsModal(true);
                AnalyticsService.instance().logEvent(
                  AnalyticsEventEnum.AGENT_OWES_REAL_REPAY_ITEMS_POPUP_OPEN,
                  {
                    debtType: original?.debtType,
                  },
                );
              }}
            />
          );

        return null;
      },
      disableSortBy: true,
    },
    {
      Header: 'Debt Name',
      accessor: 'title',
      Cell: ({ value, row: { original } }) => (
        <div className='font-zen-body font-normal text-sm'>
          <p className='text-zen-dark-9'>{value}</p>
          {publicTransactionResponseById[original?.sourceId!]
            ?.addressOneLine && (
            <div className='flex flex-row items-center space-x-1'>
              <span className='font-zen-body font-normal text-sm text-zen-gray-4 min-w-max'>
                Property address:
              </span>
              <a
                href={`/transactions/${original?.sourceId}`}
                target='_blank'
                rel='noreferrer'
                className='min-w-max'
              >
                <span className='font-zen-body font-normal text-sm text-primary-blue'>
                  {
                    publicTransactionResponseById[original?.sourceId!]
                      ?.addressOneLine
                  }
                </span>
              </a>
            </div>
          )}
          <p className='text-zen-gray-4 min-w-max'>
            ID: {original?.id || 'N/A'}
          </p>
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ value }) =>
        value ? (
          <p className='font-zen-body font-normal text-sm text-zen-dark-9 w-60'>
            {value}
          </p>
        ) : (
          <FontAwesomeIcon
            icon={regular('dash')}
            title='dash'
            className='text-zen-dark-9'
          />
        ),
      disableSortBy: true,
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      Cell: ({ value }) =>
        value ? (
          <div className='font-zen-body font-normal text-sm text-zen-dark-9 min-w-max'>
            <ZenMilliDateCell date={value} />
          </div>
        ) : (
          <FontAwesomeIcon
            icon={regular('dash')}
            title='dash'
            className='text-zen-dark-9'
          />
        ),
      disableSortBy: true,
    },
    {
      Header: 'Debt Type',
      accessor: 'debtType',
      Cell: ({ row: { original } }) => <DebtTypeCol agentDebt={original} />,
      disableSortBy: true,
    },
    {
      Header: 'Total Debt Amount',
      accessor: (d) => d['amount']?.amount,
      Cell: ({
        row: { original },
      }: React.PropsWithChildren<CellProps<AgentDebtResponse>>) => (
        <AllDebtsDeductionCell debt={original} />
      ),
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => <DebtStatus status={value!} />,
      disableSortBy: true,
    },
  ];

  const columnsWithAction: Array<Column<AgentDebtResponse>> = [
    ...columns,
    {
      Header: 'Actions',
      id: 'actions',
      Cell: ({ row: { original } }: CellProps<AgentDebtResponse>) => {
        if (original.status === AgentDebtResponseStatusEnum.Cancelled) {
          return null;
        }

        const isNew = original.status === AgentDebtResponseStatusEnum.Active;
        const isInvoiceCreated = original?.repaymentItems?.find(
          (item) =>
            item?.paymentSourceType ===
            DebtRepaymentItemResponsePaymentSourceTypeEnum.Invoice,
        );
        const isCreateInvoiceVisible =
          isNew &&
          !isAutoAgentDebt(original?.recoveryType!) &&
          !isInvoiceCreated &&
          isAdmin;

        return (
          <div
            className={cn('flex items-center space-x-4', {
              'justify-end': isAdmin,
              'justify-start': !isAdmin,
            })}
          >
            {isCreateInvoiceVisible && (
              <ZenIconCircleWithTooltipCell
                tooltipText='Create Invoice'
                wrapText={false}
                iconVariant='purple'
                icon={
                  debtCreateInvoiceLoading[original?.id!] ? (
                    <DefaultLoader iconSize='small' />
                  ) : (
                    <FontAwesomeIcon
                      icon={regular('file-invoice-dollar')}
                      className='text-zen-purple-dark'
                      aria-label='create-invoice'
                    />
                  )
                }
                disabled={debtCreateInvoiceLoading[original?.id!]}
                onClick={async () => {
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.AGENT_OWES_REAL_CREATE_INVOICE_CLICKED,
                    {
                      debtType: original?.debtType,
                    },
                  );
                  await dispatch(
                    createAgentDebtInvoice(original?.id!, agentId, status, {
                      daysUntilDue: 0,
                    }),
                  );
                }}
              />
            )}
            <AgentOnly>
              {!!isInvoiceCreated && (
                <ZenIconCircleWithTooltipCell
                  tooltipText='Pay Now'
                  wrapText={false}
                  iconVariant='success'
                  icon={
                    <FontAwesomeIcon
                      icon={regular('dollar-sign')}
                      className='text-green-600'
                      aria-label='pay-now'
                    />
                  }
                  onClick={() => {
                    AnalyticsService.instance().logEvent(
                      AnalyticsEventEnum.AGENT_OWES_REAL_PAY_NOW_CLICKED,
                      {
                        debtType: original?.debtType,
                      },
                    );
                    window.open(isInvoiceCreated?.invoicePaymentUrl, '_blank');
                  }}
                />
              )}
            </AgentOnly>

            {!!isInvoiceCreated && isAdmin && (
              <button
                className='appearance-none font-zen-body font-medium text-sm text-primary-blue'
                onClick={() => {
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.AGENT_OWES_REAL_INVOICE_LINK_CLICKED,
                    {
                      debtType: original?.debtType,
                    },
                  );
                  window.open(isInvoiceCreated?.invoiceDownloadUrl, '_blank');
                }}
              >
                <span>Invoice</span>
              </button>
            )}
            {(isCreateInvoiceVisible || !!isInvoiceCreated) && isAdmin && (
              <div className='h-5 w-px bg-zen-dark-6' />
            )}

            {isNew && isAdmin && (
              <ZenIconCircleWithTooltipCell
                tooltipText='Void'
                iconVariant='error'
                icon={
                  <FontAwesomeIcon
                    icon={light('xmark')}
                    className='text-zen-danger'
                    aria-label='void-debt'
                  />
                }
                onClick={() => {
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.AGENT_OWES_REAL_VOID_DEBT_BUTTON_CLICK,
                    {
                      debtType: original?.debtType,
                    },
                  );
                  setCancelDebtId(original?.id);
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.AGENT_OWES_REAL_VOID_DEBT_POPUP_OPEN,
                    {
                      debtType: original?.debtType,
                    },
                  );
                }}
              />
            )}

            {isNew && isAdmin && (
              <ZenIconCircleWithTooltipCell
                tooltipText='Edit'
                icon={
                  <FontAwesomeIcon
                    icon={light('pencil')}
                    className='text-zen-dark-8'
                    aria-label='edit-debt'
                  />
                }
                onClick={() => {
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.AGENT_OWES_REAL_EDIT_DEBT_BUTTON_CLICK,
                    {
                      debtType: original?.debtType,
                    },
                  );
                  setAgentDebtInfo(original);
                  setIsAddEditDebtsSidebarOpen(true);
                }}
              />
            )}
          </div>
        );
      },
    },
  ];

  const getPublicTransactionInfo = useCallback(
    async (transactionIds: string[]) => {
      const transactionIdsToFetch = transactionIds.filter(
        (id) => !publicTransactionResponseById[id],
      );

      if (transactionIdsToFetch?.length > 0) {
        await dispatch(fetchPublicTransactionInfo(transactionIdsToFetch));
      }
    },
    [dispatch, publicTransactionResponseById],
  );

  const getAgentDebts = useCallback(
    async (id: string, loading = true) => {
      if (loading) {
        setLoading(true);
      }
      const paginatedDebts = await dispatch(
        getPaginatedAgentDebts(
          id,
          status as Array<'ACTIVE' | 'PAID' | 'CANCELLED'>,
          undefined,
          0,
          1000,
          undefined,
          undefined,
        ),
      );

      const transactionIds = getTransactionIdsForPublicTransactionInfo(
        paginatedDebts?.data?.agentDebts || [],
      );

      await getPublicTransactionInfo(transactionIds);

      if (loading) {
        setLoading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, status],
  );

  useEffect(() => {
    getAgentDebts(agentId);
  }, [agentId, getAgentDebts]);

  return (
    <AuthorizationContainer asyncResponse={paginatedAgentDebtsResponse}>
      <div className='py-2'>
        <ResourceContainer
          isEmpty={false}
          loading={loading}
          resourceName='agent debt'
          LoaderComponent={<TableLoader />}
          errorCode={paginatedAgentDebtsResponse?.error}
        >
          <ZenResourceIndexContainer<AgentDebtResponse>
            columns={columnsWithAction}
            resourceName='debt'
            pageSize={1000}
            data={paginatedAgentDebtsResponse?.data?.agentDebts || []}
            hideFilters
            hidePageSize
            hidePagination
            standalone={false}
            allowSortingRows={status === ACTIVE_TAB_STATUS && isAdmin}
            onSortEnd={async (oldIndex, newIndex, item, items) => {
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.AGENT_OWES_REAL_DRAG_AND_DROP_DEBT,
              );
              const sortedItems = arrayMove(items, oldIndex, newIndex);
              const precedenceList: DebtPrecedence[] = (sortedItems || [])
                ?.filter(
                  (i) =>
                    i.original.status === AgentDebtResponseStatusEnum.Active &&
                    isAutoAgentDebt(i.original.recoveryType!),
                )
                ?.map((i, index) => ({
                  debtId: i.original.id!,
                  precedence: index,
                }));
              const isDraggingManualDebt =
                items?.[oldIndex]?.original?.recoveryType ===
                AgentDebtResponseRecoveryTypeEnum.Invoice;
              const isDropingToManualDebt =
                items?.[newIndex]?.original?.recoveryType ===
                AgentDebtResponseRecoveryTypeEnum.Invoice;

              if (isDraggingManualDebt || isDropingToManualDebt) {
                dispatch(
                  showErrorToast(
                    'Dragging and Dropping a Manual Debt is not allowed.',
                  ),
                );
              } else {
                await dispatch(
                  changeDebtPosition(
                    agentId,
                    item?.id!,
                    oldIndex,
                    newIndex,
                    precedenceList,
                  ),
                );
                await getAgentDebts(agentId, false);
              }
            }}
          />
        </ResourceContainer>
        {!!cancelDebtId && (
          <ZenConfirmationModal
            variant='danger'
            isOpen
            onClose={() => {
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.AGENT_OWES_REAL_VOID_DEBT_POPUP_CANCEL,
              );
              setCancelDebtId(undefined);
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.AGENT_OWES_REAL_VOID_DEBT_POPUP_CLOSED,
              );
            }}
            title='Are you sure you want to void this Debt?'
            subtitle='You cannot edit this debt once it is voided'
            confirmButtonText='Void'
            onConfirm={async () => {
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.AGENT_OWES_REAL_VOID_DEBT_POPUP_VOID,
              );
              setCancelLoading(true);
              await dispatch(cancelAgentDebt(cancelDebtId));
              await getAgentDebts(agentId, false);
              setCancelLoading(false);
              setCancelDebtId(undefined);
            }}
            isSubmitting={cancelLoading}
            isDisabled={cancelLoading}
            hideIcon
          />
        )}

        {isOpenRepaymentItemsModal && (
          <DebtRepaymentItems
            isOpen
            onClose={() => {
              setRepaymentItems(undefined);
              setIsOpenRepaymentItemsModal(false);
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.AGENT_OWES_REAL_REPAY_ITEMS_POPUP_CLOSED,
              );
            }}
            repaymentItems={repaymentItems}
            agentId={agentId}
          />
        )}
      </div>
    </AuthorizationContainer>
  );
};

export default DebtTabComponent;
