import ResourceIndexContainer, {
  TableProps,
} from '../containers/ResourceIndexContainer';

interface FixedDataTableProps<D extends object>
  extends Omit<TableProps<D>, 'fetchData'> {}

const FixedDataTable = <D extends object>(props: FixedDataTableProps<D>) => {
  return (
    <ResourceIndexContainer<D>
      {...props}
      standalone={props.standalone ?? false}
    />
  );
};

export default FixedDataTable;
