import { last } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import ZenViewActionButtonCell from '../components/table/Cells/ZenViewActionButtonCell';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenMilliDateCell from '../components/Zen/Table/Cell/ZenMilliDateCell';
import ZenBarChart from '../components/Zen/ZenBarChart';
import ZenResourceContainer from '../components/Zen/ZenResourceContainer';
import ZenRoute from '../components/Zen/ZenRoute';
import { RevSharePaymentPreviewResponse } from '../openapi/arrakis';
import {
  fetchRevenueSharePaymentsForTable,
  getPendingRevenueSharePaymentsOverview,
} from '../slices/RevenueSlice';
import { AppDispatch, RootState } from '../types';
import {
  displayAmount,
  DisplayAmountOptions,
  zeroMoneyValue,
} from '../utils/CurrencyUtils';

type Match = {
  id: string;
};

export const columns: Array<Column<RevSharePaymentPreviewResponse>> = [
  {
    Header: 'Payment Date',
    accessor: 'createdAt',
    Cell: ({ value }) => (
      <div className='flex flex-row items-center space-x-2'>
        {value ? <ZenMilliDateCell date={value} /> : <p>Pending</p>}
      </div>
    ),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Contribution',
    accessor: 'numOfContributions',
    Cell: ({ value }) => value,
    disableSortBy: true,
    disableFilters: true,
  },
];

const ZenAgentDetailRevSharePayments: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const { id: agentId } = useParams() as Match;
  const {
    agentDetail: {
      detailResponse: { data: detail },
    },
    revenue: {
      previousPayments,
      pendingPaymentsOverview,
      pendingPaymentsLoading,
    },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(getPendingRevenueSharePaymentsOverview(agentId));
  }, [agentId, dispatch]);

  const barChartData = [
    ...([...(previousPayments?.payments || [])].reverse().map((item) => {
      return {
        total: item.amount?.amount,
        month: `${DateTime.fromMillis(item.createdAt!).toFormat('MM/yy')}`,
      };
    }) || []),
    {
      total: pendingPaymentsOverview?.amount?.amount || 0,
      month: 'Next\n Payment*',
    },
  ];

  const highlightedMonth = last(barChartData);

  const displayAmountOptions: DisplayAmountOptions = { hideZeroCents: true };

  const columnsWithAction: Array<
    Column<RevSharePaymentPreviewResponse>
  > = useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'outgoingPaymentId',
        Cell: ({ value, row }) => (
          <ZenViewActionButtonCell
            onClick={() =>
              row.index === 0
                ? history.push(`/people/${agentId}/revshare/pending-payments`)
                : history.push(`/people/${agentId}/revshare/payments/${value}`)
            }
          />
        ),
        disableSortBy: true,
        disableFilters: true,
      },
      ...columns,
      {
        Header: `Amount (${detail?.defaultCurrency})`,
        accessor: 'amount',
        Cell: ({ value }) => (
          <div className='flex flex-row justify-end w-24'>
            <p className='md:text-right'>
              {displayAmount(
                value || zeroMoneyValue(detail?.defaultCurrency!),
                { hideCurrency: true },
              )}
            </p>
          </div>
        ),
        disableSortBy: true,
        disableFilters: true,
      },
    ],
    [detail?.defaultCurrency, history, agentId],
  );

  return (
    <ZenRoute title='Revenue Share Payments'>
      <div className='p-4'>
        <div className='flex flex-row items-center mb-4'>
          <span className='font-bold  text-xl'>Revenue Share Payments</span>
        </div>
        <ZenResourceContainer
          loading={pendingPaymentsLoading}
          isEmpty={false}
          resourceName='payment graph'
        >
          <div className='grid grid-flow-row md:grid-cols-3 grid-cols-1 gap-4'>
            <div className='col-span-2'>
              <ZenResourceIndexContainer<RevSharePaymentPreviewResponse>
                columns={columnsWithAction}
                resourceName='Payment'
                fetchData={async (req) =>
                  dispatch(
                    fetchRevenueSharePaymentsForTable(
                      agentId,
                      req.page,
                      req.pageSize,
                      pendingPaymentsOverview,
                      !pendingPaymentsOverview,
                    ),
                  )
                }
                hideFilters
              />
            </div>
            <div>
              <div className='border rounded-md'>
                <div className='pt-2 flex flex-row justify-between'>
                  <span className='text-lg text-gray-600 font-primary-thin px-4'>
                    Total Earnings
                  </span>
                  <div className='px-4'>
                    <span className='text-xl text-gray-800 font-primary-medium pl-4'>
                      {displayAmount(
                        previousPayments?.amount,
                        displayAmountOptions,
                      )}
                    </span>
                    <span className='font-primary-light text-lg relative bottom-2'>
                      ✝
                    </span>
                  </div>
                </div>
                <div className='px-4'>
                  <ZenBarChart
                    data={barChartData || []}
                    highlightedData={[highlightedMonth!]}
                    xKey='month'
                    yKey='total'
                  />
                </div>
              </div>

              <p className='font-primary-light text-sm pt-4 text-gray-500'>
                * Your next payment includes contributions from closed and
                settled transactions. Other contributions will be added as they
                get settled and processed.
              </p>

              <p className='font-primary-light text-sm pt-2 text-gray-500'>
                ✝ The total earnings does not include any pending earnings.
              </p>
            </div>
          </div>
        </ZenResourceContainer>
      </div>
    </ZenRoute>
  );
};
export default ZenAgentDetailRevSharePayments;
