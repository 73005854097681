import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  TransactionSetupFormProps,
  TransactionSetupStepName,
} from '../../../containers/transaction/setup/TransactionSetupContainer';
import { AttachedFeeValueFeeTypeEnum } from '../../../openapi/arrakis';
import { RootState, YesNoType } from '../../../types';
import { getTransactionAttachedFeeByType } from '../../../utils/TransactionHelper';
import ControlledRadioInputV7 from '../../ControlledRadioInputV7';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import TransactionAttachedFeeSelectionRow from './TransactionAttachedFeeSelectionRow';

const TransactionSetupAttachedFeeAdditionalCommissionStep: StepByStepComponent<
  TransactionSetupFormProps,
  TransactionSetupStepName
> = ({ form: { control, watch, trigger } }) => {
  const {
    transactionDetailResponse: { data: transactionDetail },
  } = useSelector((state: RootState) => state.transaction);

  const attachedFeeAdditionalCommissions = useMemo(
    () =>
      getTransactionAttachedFeeByType(
        transactionDetail!,
        AttachedFeeValueFeeTypeEnum.AdditionalCommission,
      ),
    [transactionDetail],
  );

  const needsPayingAdditionalFees: boolean =
    watch('chargingClients') === YesNoType.YES;

  useEffect(() => {
    trigger('chargingClients');
  }, [attachedFeeAdditionalCommissions, trigger]);

  return (
    <div className='flex-grow flex flex-col mt-10 w-full max-w-xl'>
      <div>
        <p className='text-xl font-primary-medium text-dark mb-4'>
          Are you charging your clients any additional commission amount?
        </p>
        <ControlledRadioInputV7<TransactionSetupFormProps, 'chargingClients'>
          shouldUnregister={false}
          control={control}
          name='chargingClients'
          rules={{
            required: 'Required',
            validate: (value: YesNoType) => {
              return value === YesNoType.YES &&
                !attachedFeeAdditionalCommissions?.length
                ? 'Please add an additional commission fee / rebate'
                : undefined;
            },
          }}
          options={[
            { label: 'Yes', value: YesNoType.YES },
            { label: 'No', value: YesNoType.NO },
          ]}
          inlineOptions
        />
        {needsPayingAdditionalFees && (
          <div className='mt-3'>
            <TransactionAttachedFeeSelectionRow
              transaction={transactionDetail!}
              attachedFeeType={AttachedFeeValueFeeTypeEnum.AdditionalCommission}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionSetupAttachedFeeAdditionalCommissionStep;
