import React from 'react';
import ZenSidebarModal from '../../../components/Zen/ZenSidebarModal';
import GeminiButton from '../../../components/Gemini/GeminiButton';
import { ReferralCentralReferralDto } from '../../../openapi/arrakis';
import ReferralDetailCard from '../components/ReferralDetailCard';
import ReferralAgentProfile from '../components/ReferralAgentProfile';

interface ReferralDetailDiscoverSidebarProps {
  referralDetails: ReferralCentralReferralDto;
  onClose: () => void;
  isOpen: boolean;
}

const ReferralDetailDiscoverSidebar: React.FC<ReferralDetailDiscoverSidebarProps> = ({
  referralDetails,
  onClose,
  isOpen,
}) => {
  return (
    <ZenSidebarModal title='Referral Details' isOpen={isOpen} onClose={onClose}>
      <div className='flex flex-col gap-4 w-full pt-5'>
        <div className='p-5 border border-regent-300 rounded-lg mx-4'>
          <ReferralDetailCard referralDetails={referralDetails} />
        </div>
        <div className='border border-grey-200 rounded-lg mx-4 p-4 font-inter'>
          <ReferralAgentProfile isShort />
        </div>
        <div className='pt-20' />
        <div className='p-5 bg-white border-t border-gray-200 flex flex-row justify-between w-full gap-4 absolute bottom-0'>
          <GeminiButton
            type='button'
            onClick={onClose}
            label='Back'
            size='md'
            variant='primary-link'
          />
          <div className='w-36'>
            <GeminiButton
              type='button'
              onClick={onClose}
              label='Apply'
              size='md'
              isFullWidth
              variant='primary-outline'
            />
          </div>
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default ReferralDetailDiscoverSidebar;
