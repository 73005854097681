import React from 'react';
import IconButton from '../../../IconButton';

interface ZenActionButtonCellProps {
  leftIcon?: React.ReactElement;
  onClick?(): void;
  tooltipText?: string;
  label?: string;
}

const ZenActionButtonCell: React.FC<ZenActionButtonCellProps> = ({
  label,
  leftIcon,
  onClick,
  tooltipText,
}) => {
  return (
    <IconButton
      buttonStyle='border border-primary-blue bg-primary-blue rounded-lg text-white'
      textStyle='font-zen-body'
      variant='none'
      leftIcon={leftIcon}
      onClick={onClick}
      toolTipText={tooltipText}
      label={label}
    />
  );
};

export default ZenActionButtonCell;
