import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import AgentOnboardingLayout from '../../../components/AgentOnboardingLayout';
import ResourceContainer from '../../../components/ResourceContainer';
import TransactionSetupContainer from '../../../containers/transaction/setup/TransactionSetupContainer';
import {
  fetchTransactionDetails,
  getTransactionFeatures,
  saveTransactionFeatures,
} from '../../../slices/TransactionSlice';
import { RootState } from '../../../types';

type Match = {
  id: string;
};

const TransactionSetup: React.FC = () => {
  const { id } = useParams() as Match;
  const dispatch = useDispatch();
  const {
    transactionDetailResponse: { data: transactionDetail, loading, error },
  } = useSelector((state: RootState) => state.transaction);

  useEffect(() => {
    if (!transactionDetail || id !== transactionDetail.id) {
      dispatch(fetchTransactionDetails(id));
    }
  }, [dispatch, id, transactionDetail]);

  useEffect(() => {
    dispatch(getTransactionFeatures(id));

    return () => {
      dispatch(saveTransactionFeatures(undefined));
    };
  }, [dispatch, id]);

  return (
    <ResourceContainer
      isEmpty={!transactionDetail}
      loading={loading}
      errorCode={error}
      resourceName='transaction'
    >
      <AgentOnboardingLayout
        title='Setup Your Transaction'
        hideLogout
        hideClose
        RightHeaderComponent={
          <Link to='/transactions'>
            <span className='text-primary font-primary-medium'>Not Now</span>
          </Link>
        }
      >
        {!!transactionDetail && (
          <TransactionSetupContainer transaction={transactionDetail} />
        )}
      </AgentOnboardingLayout>
    </ResourceContainer>
  );
};

export default TransactionSetup;
