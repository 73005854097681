import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LeoQuestionManagerApi, PairBase } from '../../openapi/leo';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import { showErrorToastForErrorCode } from '../../slices/ToastNotificationSlice';
import { RootState } from '../../types';
import { getLeoConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ZenDropdown from '../Zen/ZenDropdown';
import ZenAvatar from '../Zen/ZenAvatar';
import ZenIconCircle from '../Zen/ZenIconCircle';

export interface LQMAssignmentProps {
  questionDetails: PairBase;
  refresh(): void;
}

const LQMAssignment: React.FC<LQMAssignmentProps> = ({
  questionDetails,
  refresh,
}) => {
  const {
    auth: { userDetail },
    userIds: { agentById },
    leo: { assigneeUserIds },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleAssignment = async (assigneeId: string) => {
    try {
      await new LeoQuestionManagerApi(
        getLeoConfiguration(),
      ).updateAssignmentLqmQapairPairIdAssignmentPatch(
        questionDetails.pair_id as string,
        assigneeId,
      );
      refresh();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to add assignee to the question', e, {
        leoQuestion: { id: questionDetails.pair_id },
      });
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to add assignee to the question',
          ErrorService.getErrorCode(e),
        ),
      );
    }
  };

  const assigneeItems = [
    {
      text: 'Me',
      onClick: () => {
        handleAssignment(userDetail?.id!);
      },
      icon: (
        <ZenAvatar
          name='Me'
          size='xs'
          colorClassName='bg-zen-blue-1 text-white'
          imageUrl={agentById[userDetail?.id!]?.avatar}
        />
      ),
    },
    ...assigneeUserIds
      .filter((userId) => userId !== userDetail?.id)
      .map((assigneeId: string) => {
        return {
          text: `${agentById[assigneeId]?.firstName!} ${agentById[assigneeId]
            ?.lastName!}`,
          onClick: () => {
            handleAssignment(assigneeId);
          },
          icon: (
            <ZenAvatar
              name={`${agentById[assigneeId]?.firstName!} ${agentById[
                assigneeId
              ]?.lastName!}`}
              size='xs'
              colorClassName='bg-zen-blue-1 text-white'
              imageUrl={agentById[assigneeId]?.avatar}
            />
          ),
        };
      }),
  ];

  return (
    <ZenDropdown
      containerStyle={{ minWidth: '10rem' }}
      title='Select Assignee'
      items={assigneeItems}
      icon={
        <ZenIconCircle
          icon={
            <FontAwesomeIcon
              icon={
                questionDetails?.assignee_id
                  ? regular('user-pen')
                  : regular('user-plus')
              }
              className='text-zen-dark-8'
              aria-label='assign'
            />
          }
          variant='gray'
          size='xs'
        />
      }
    />
  );
};

export default LQMAssignment;
