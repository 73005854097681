import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flatten, map, uniq } from 'lodash';
import { fetchLatestCommentForChecklistIds } from '../slices/CheckListSlice';
import { RootState } from '../types';
import { ChecklistResponse } from '../openapi/sherlock';
import { fetchAgentsInfoKeymakerId } from '../slices/UserIdsSlice';
import { PostCommentParamsAuthorTypeEnum } from '../openapi/yada';

const useLatestCommentsForChecklistIds = (
  checklist: ChecklistResponse | undefined,
) => {
  const dispatch = useDispatch();
  const {
    checklist: { latestCommentsByChecklistItemId },
    userIds: { agentByKeymakerId },
  } = useSelector((state: RootState) => state);

  const checklistItemIds = useMemo(
    () =>
      checklist?.items
        ?.filter((item) => !latestCommentsByChecklistItemId.data?.[item.id!])
        .map((item) => item.id!),
    [checklist?.items, latestCommentsByChecklistItemId.data],
  );

  const agentIdsToFetch = useMemo(
    () =>
      uniq(
        flatten(
          map(
            latestCommentsByChecklistItemId.data,
            (comment) => comment.createdById!,
          ),
        ),
      ).filter((id) => !!id && !agentByKeymakerId[id]),
    [agentByKeymakerId, latestCommentsByChecklistItemId.data],
  );

  useEffect(() => {
    if (checklistItemIds?.length) {
      dispatch(
        fetchLatestCommentForChecklistIds(
          checklistItemIds,
          PostCommentParamsAuthorTypeEnum.User,
          1,
        ),
      );
    }
  }, [checklistItemIds, dispatch]);

  useEffect(() => {
    if (agentIdsToFetch.length > 0) {
      dispatch(fetchAgentsInfoKeymakerId(agentIdsToFetch));
    }
  }, [agentIdsToFetch, dispatch]);
};

export default useLatestCommentsForChecklistIds;
