import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../../routes/AgentWebsiteOnboardingRoute';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import StepContentInput from '../StepContentInput';
import ControlledTextInputV7 from '../../ControlledTextInputV7';
import ControlledTextAreaInputV7 from '../../ControlledTextAreaInputV7';
import AgentWebsiteMainContent from '../../../assets/img/agent-website-main-content.png';
import { TEXT_CONTENT_VALIDATIONS } from '../../../utils/Validations';

const AgentWebsiteOnboardingMainContent: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ form: { control } }) => {
  return (
    <div className='p-4'>
      <img
        src={AgentWebsiteMainContent}
        className='my-5 mx-auto'
        alt='website'
      />
      <div className='grid lg:grid-cols-2 grid-cols-1 gap-10'>
        <div className='flex flex-col space-y-10'>
          <StepContentInput step={1}>
            <ControlledTextInputV7<AgentWebsiteOnboardingFormData, 'name'>
              control={control}
              shouldUnregister={false}
              name='name'
              label='Your display name'
              readOnly
            />
          </StepContentInput>
          <StepContentInput step={2}>
            <ControlledTextInputV7<
              AgentWebsiteOnboardingFormData,
              'websiteTitle'
            >
              control={control}
              shouldUnregister={false}
              name='websiteTitle'
              label='Your title/certification'
              rules={{
                required: 'Required',
                ...TEXT_CONTENT_VALIDATIONS,
              }}
            />
          </StepContentInput>
          <StepContentInput step={3}>
            <ControlledTextInputV7<AgentWebsiteOnboardingFormData, 'mainTitle'>
              name='mainTitle'
              control={control}
              shouldUnregister={false}
              label='Home page main title'
              rules={{
                required: 'Required',
                ...TEXT_CONTENT_VALIDATIONS,
              }}
            />
          </StepContentInput>
        </div>
        <div className='flex flex-col space-y-10'>
          <StepContentInput step={4}>
            <ControlledTextInputV7<
              AgentWebsiteOnboardingFormData,
              'mainSubtitle'
            >
              name='mainSubtitle'
              control={control}
              shouldUnregister={false}
              label='Subtitle'
              rules={{
                ...TEXT_CONTENT_VALIDATIONS,
              }}
            />
          </StepContentInput>
          <StepContentInput step={5}>
            <ControlledTextInputV7<
              AgentWebsiteOnboardingFormData,
              'bottomTitle'
            >
              name='bottomTitle'
              control={control}
              shouldUnregister={false}
              label='Message title'
              rules={{
                required: 'Required',
                ...TEXT_CONTENT_VALIDATIONS,
              }}
            />
          </StepContentInput>
          <StepContentInput step={6}>
            <ControlledTextAreaInputV7<
              AgentWebsiteOnboardingFormData,
              'bottomSubtitle'
            >
              name='bottomSubtitle'
              control={control}
              rows={2}
              shouldUnregister={false}
              label='Message content'
              subLabel='(255 characters max)'
              rules={{
                ...TEXT_CONTENT_VALIDATIONS,
              }}
            />
          </StepContentInput>
        </div>
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingMainContent;
