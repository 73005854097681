interface ZenStepContentInputProps {
  step: number;
}

const ZenStepContentInput: React.FC<ZenStepContentInputProps> = ({
  step,
  children,
}) => {
  return (
    <div className='flex flex-row items-start space-x-3'>
      <div className='w-6 h-6 bg-grey-200 rounded-full flex flex-shrink-0 justify-center items-center mt-1'>
        <span className='font-zen-title text-sm mt-0.5'>{step}</span>
      </div>
      {children}
    </div>
  );
};

export default ZenStepContentInput;
