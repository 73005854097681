import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { ReactComponent as TriggerIcon } from '../assets/icons/zen/autoToggle/trigger-new.svg';
import DetailPageLoader from '../components/DetailPageLoader';
import PageLayout from '../components/PageLayout';
import ResourceContainer from '../components/ResourceContainer';
import AddTemplateItem from '../components/transactions/CheckListAndRoadToSuccessManage/AddTemplateItem';
import TemplateHeader from '../components/transactions/CheckListAndRoadToSuccessManage/TemplateHeader';
import ManageTriggerSidebarModal from '../components/Zen/AutoToggle/ManageTriggerSidebarModal';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenConfirmationModal from '../components/Zen/Modal/ZenConfirmationModal';
import ZenTemplateItemNameCell from '../components/Zen/Transaction/ChecklistOrJourneyTemplates/ZenTemplateItemNameCell';
import ZenTemplateItemToggler from '../components/Zen/Transaction/ChecklistOrJourneyTemplates/ZenTemplateItemToggler';
import ZenIconCircleWithTooltipCell from '../components/Zen/Transaction/ZenIconCircleWithTooltipCell';
import { useFetchAsyncMappingResponse } from '../hooks/useFetchAsyncMappingResponse';
import { ChecklistDefinitionDto, ItemDefinitionDto } from '../openapi/sherlock';
import {
  changeChecklistItemPosition,
  deleteCheckListDefinitionItem,
  fetchChecklistDefinition,
} from '../slices/ChecklistDefinitionSlice';
import { fetchGlobalDropbox } from '../slices/DropboxSlice';
import { AppDispatch, RootState } from '../types';
import { capitalizeEnum } from '../utils/StringUtils';

type Match = {
  templateItemId: string;
};

const columns: Array<Column<ItemDefinitionDto>> = [
  {
    Header: 'NO',
    accessor: 'position',
    Cell: ({ row: { index } }) => (
      <div className='w-8 h-8 shrink-0 flex justify-center items-center rounded-full bg-zen-dark-10 overflow-hidden'>
        <span className='font-zen-body font-semibold text-base text-white'>
          {index + 1}
        </span>
      </div>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'ITEM NAME',
    accessor: 'name',
    Cell: ({ row: { original } }) => (
      <ZenTemplateItemNameCell item={original} />
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'ROLE',
    accessor: 'defaultAssignee',
    Cell: ({ value }) => (
      <p className='font-zen-body text-sm font-normal text-zen-dark-9'>
        {value ? capitalizeEnum(value!) : '--'}
      </p>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'DAYS DUE',
    accessor: 'daysUntilDue',
    Cell: ({ value }) =>
      value ? (
        <p className='font-zen-body text-sm font-normal text-zen-dark-9'>{`${value} days`}</p>
      ) : (
        '--'
      ),
    disableFilters: true,
    disableSortBy: true,
  },
];

const ZenResourcesTemplateItemsRoute: React.FC = () => {
  const { templateItemId } = useParams() as Match;
  const dispatch: AppDispatch = useDispatch();
  const {
    checkListDefinition: { checklistDefinitionById },
  } = useSelector((state: RootState) => state);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [create, setCreate] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<string | undefined>();
  const [deleteItemLoading, setDeleteItemLoading] = useState<boolean>(false);
  const [checkListItem, setCheckListItem] = useState<
    ItemDefinitionDto | undefined
  >(undefined);
  const [itemId, setItemId] = useState<string | undefined>();
  const [readonly, setReadonly] = useState(false);
  const { data: checkListDefinition } =
    checklistDefinitionById[templateItemId] || {};

  const getChecklistDefinition = useCallback(() => {
    if (!checkListDefinition) {
      dispatch(fetchChecklistDefinition(templateItemId, true));
    }
  }, [checkListDefinition, dispatch, templateItemId]);

  const fetchDropbox = useCallback(async () => {
    await dispatch(fetchGlobalDropbox());
  }, [dispatch]);

  useEffect(() => {
    fetchDropbox();
  }, [fetchDropbox]);

  const {
    loading,
    error,
    data: checkList,
  } = useFetchAsyncMappingResponse<ChecklistDefinitionDto>({
    name: 'checklist definition',
    fetch: getChecklistDefinition,
    response: checklistDefinitionById[templateItemId]!,
  });

  const columnsWithActions: Array<Column<ItemDefinitionDto>> = [
    ...columns,
    {
      Header: 'REQUIRED',
      accessor: 'required',
      Cell: ({ row: { original } }) => (
        <ZenTemplateItemToggler item={original} checklistId={checkList?.id!} />
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'HIDDEN',
      accessor: 'hidden',
      Cell: ({ row: { original } }) => (
        <ZenTemplateItemToggler
          item={original}
          toggleKey='hidden'
          checklistId={checkList?.id!}
        />
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'REQUIRED FOR',
      accessor: 'requiredFor',
      Cell: ({ value }) => (
        <p className='font-zen-body text-sm font-normal text-zen-dark-9'>
          {value ? capitalizeEnum(value!) : '--'}
        </p>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'ATTACHMENTS',
      accessor: 'documents',
      Cell: ({ row: { original } }) => (
        <div className='flex flex-row items-center gap-x-1'>
          <FontAwesomeIcon
            icon={faPaperclip}
            className='text-zen-dark-9 mr-1'
            size='xl'
          />
          <span className='font-zen-body text-sm font-semibold text-zen-dark-9'>
            {original.templateReferences?.references?.length!}
          </span>
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ value, row: { original } }) => (
        <div className='flex items-center space-x-4'>
          <ZenIconCircleWithTooltipCell
            tooltipText='Edit'
            icon={
              <FontAwesomeIcon
                icon={faPencil}
                className='text-zen-dark-8 text-lg'
                data-testid={`${value}-edit-icon`}
              />
            }
            onClick={() => setCheckListItem(original)}
          />

          <ZenIconCircleWithTooltipCell
            tooltipText='Triggers'
            icon={
              <TriggerIcon
                width={20}
                height={20}
                className='text-zen-dark-8'
                data-testid={`${value}-trigger-icon`}
              />
            }
            onClick={() => setItemId(original?.id)}
            count={original?.triggerDefinitions?.length! || 0}
          />

          <ZenIconCircleWithTooltipCell
            tooltipText='Delete'
            icon={
              <FontAwesomeIcon
                icon={faTrashCan}
                className='text-zen-danger'
                data-testid={`${value}-delete-icon`}
              />
            }
            onClick={() => setDeleteItem(value)}
          />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  return (
    <ResourceContainer
      loading={loading}
      isEmpty={!checkList?.items}
      errorCode={error}
      LoaderComponent={<DetailPageLoader />}
      resourceName='resource templates item'
    >
      <PageLayout
        path={[
          { title: 'Templates', url: '/resources/templates' },
          {
            title: `${checkList?.name}`,
            url: `/resources/templates/${templateItemId}`,
          },
        ]}
      >
        <div className='my-4 px-4'>
          <TemplateHeader
            title={checkList?.name!}
            addButtonText='Add New Item'
            addButtonAction={() => setCreate(true)}
            search={search || ''}
            setSearch={setSearch}
            showBackIcon
            goBackRoute='/resources/templates'
          />
          <ZenResourceIndexContainer<ItemDefinitionDto>
            columns={columnsWithActions}
            resourceName='Template Item'
            data={checkList?.items! || []}
            search={search}
            hideFilters
            standalone
            allowSortingRows
            pageSizeOptions={[10, 20, 30, 40, 50, 75, 100, 150]}
            onSortEnd={async (oldIndex, newIndex, item) => {
              await dispatch(
                changeChecklistItemPosition(
                  checkList?.id!,
                  item?.id!,
                  oldIndex,
                  newIndex,
                ),
              );
              await dispatch(fetchChecklistDefinition(templateItemId, false));
            }}
          />
          {(create || !!checkListItem) && (
            <AddTemplateItem
              isOpen={create || !!checkListItem}
              onClose={() => {
                setCreate(false);
                setCheckListItem(undefined);
                if (readonly) {
                  setReadonly(false);
                }
              }}
              checklistItem={checkListItem}
              checkList={checkList!}
              setItemId={setItemId}
              readonly={readonly}
            />
          )}
          {!!itemId && (
            <ManageTriggerSidebarModal
              isOpen
              onClose={() => setItemId(undefined)}
              itemId={itemId}
              templateId={templateItemId}
              checklist={checkList!}
            />
          )}
          <ZenConfirmationModal
            isOpen={!!deleteItem}
            variant='danger'
            title='Delete Checklist Item?'
            subtitle='This Checklist Item will be permanently deleted. Are you sure?'
            onClose={() => setDeleteItem(undefined)}
            confirmButtonText='Yes'
            cancelButtonText='No'
            onConfirm={async () => {
              setDeleteItemLoading(true);
              await dispatch(
                deleteCheckListDefinitionItem(checkList?.id!, deleteItem!),
              );
              setDeleteItemLoading(false);
              setDeleteItem(undefined);
            }}
            isSubmitting={deleteItemLoading}
            isDisabled={deleteItemLoading}
          />
        </div>
      </PageLayout>
    </ResourceContainer>
  );
};

export default ZenResourcesTemplateItemsRoute;
