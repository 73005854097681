import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../types';
import { getLoginURLWithRedirection } from '../utils/NavigationHelper';
import FutureMaintenanceAlert from './FutureMaintenanceAlert';

export interface AuthenticatedRouteProps extends RouteProps {
  hideFutureMaintenanceAlert?: boolean;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  component: Component,
  hideFutureMaintenanceAlert = false,
  ...rest
}) => {
  const { userDetail, keymakerCurrentUser } = useSelector(
    (state: RootState) => state.auth,
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (keymakerCurrentUser?.forceMfa && !keymakerCurrentUser.mfaType) {
          return <Redirect to='/enable-2fa' />;
        }

        if (userDetail) {
          return (
            <div>
              {!hideFutureMaintenanceAlert && <FutureMaintenanceAlert />}
              {Component ? <Component {...props} /> : null}
            </div>
          );
        }

        return <Redirect to={getLoginURLWithRedirection()} />;
      }}
    />
  );
};

export default AuthenticatedRoute;
