import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import ErrorService from '../services/ErrorService';
import {
  CreateRefundRequest,
  CreateRefundResponse,
  FinanceApproveRequest,
  RefundAttachmentResponse,
  RefundControllerApi,
  RefundResponse,
  UpdateRefundRequest,
} from '../openapi/arrakis';
import { AppThunk, ErrorCode, ReleaseFundsState } from '../types';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { showApiErrorModal } from './ErrorSlice';
import { fetchAgentsInfo } from './UserIdsSlice';

export interface ModifiedRefundAttachmentResponse
  extends RefundAttachmentResponse {
  isLocal: boolean;
}

export const initialState: ReleaseFundsState = {
  refunds: {
    data: [],
    loading: false,
    name: 'refunds',
    error: null,
  },
  refundAttachments: [],
};

const refundSlice = createSlice({
  name: 'refund',
  initialState,
  reducers: {
    changeRefundsLoaing(state, action: PayloadAction<boolean>) {
      state.refunds.loading = action.payload;
    },
    saveRefunds(state, action: PayloadAction<RefundResponse[]>) {
      state.refunds.data = action.payload;
      state.refunds.error = null;
    },
    updateRefund(state, action: PayloadAction<RefundResponse>) {
      const refundIndex = (state.refunds.data || [])?.findIndex(
        (refund) => refund.id === action.payload.id,
      );
      if (refundIndex !== -1) {
        state.refunds.data![refundIndex] = action.payload;
      }
    },
    errorFetchingRefunds(state, action: PayloadAction<ErrorCode>) {
      state.refunds.error = action.payload;
    },
    saveAttachments(
      state,
      action: PayloadAction<ModifiedRefundAttachmentResponse[]>,
    ) {
      state.refundAttachments = action.payload;
    },
    updateAttachment(
      state,
      action: PayloadAction<ModifiedRefundAttachmentResponse[]>,
    ) {
      state.refundAttachments = [...state.refundAttachments, ...action.payload];
    },
    replaceAttachment(
      state,
      action: PayloadAction<{
        attachmentId: string;
        attachment: ModifiedRefundAttachmentResponse;
      }>,
    ) {
      const attachmentIndex = (state.refundAttachments || [])?.findIndex(
        (attachment) => attachment?.id === action.payload.attachmentId,
      );

      if (attachmentIndex !== -1) {
        state.refundAttachments![attachmentIndex] = action.payload.attachment;
      }
    },
    deleteAttachment(state, action: PayloadAction<string>) {
      const attachmentIndex = (state.refundAttachments || []).findIndex(
        (attachment) => attachment?.id === action.payload,
      );
      if (attachmentIndex !== -1) {
        state.refundAttachments?.splice(attachmentIndex, 1);
      }
    },
  },
});

export const {
  saveRefunds,
  updateRefund,
  changeRefundsLoaing,
  errorFetchingRefunds,
  saveAttachments,
  updateAttachment,
  replaceAttachment,
  deleteAttachment,
} = refundSlice.actions;

export const fetchRefundsByTransactionId = (
  transactionId: string,
  loading: boolean = true,
): AppThunk<Promise<RefundResponse[] | undefined>> => async (
  dispatch,
  getState,
) => {
  if (loading) {
    changeRefundsLoaing(true);
  }
  try {
    const { data } = await new RefundControllerApi(
      getArrakisConfiguration(),
    ).getRefundsByTransactionId(transactionId);
    dispatch(saveRefunds(data));
    if (data) {
      const updatedByIds = (data || [])
        ?.map((refund) => refund?.updatedBy!)
        ?.filter((id) => !!id);
      const {
        userIds: { agentById },
      } = getState();
      const uniqIds = uniq(updatedByIds)?.filter((id) => !agentById[id]);

      if (uniqIds?.length > 0) {
        await dispatch(fetchAgentsInfo(uniqIds));
      }
    }
    return data;
  } catch (e) {
    dispatch(errorFetchingRefunds(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem fetching refunds for transaction.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to fetch the refunds for transaction.',
      e,
      { transaction: { id: transactionId } },
    );
    return undefined;
  } finally {
    if (loading) {
      dispatch(changeRefundsLoaing(false));
    }
  }
};

export const createRefundRequest = (
  createRefundRequest: CreateRefundRequest,
  loading?: boolean,
): AppThunk<Promise<CreateRefundResponse | undefined>> => async (dispatch) => {
  try {
    const { data } = await new RefundControllerApi(
      getArrakisConfiguration(),
    ).createRefundRequest(createRefundRequest);
    await dispatch(
      fetchRefundsByTransactionId(createRefundRequest.transactionId, loading),
    );
    dispatch(showSuccessToast('Refund request created successfully.'));
    return data;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem creating new refund request.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to create new refund request.', e, {
      req: { createRefundRequest },
    });
    return undefined;
  }
};

export const updateRefundRequest = (
  refundId: string,
  updateRefundRequest: UpdateRefundRequest,
  transactionId: string,
  loading?: boolean,
): AppThunk<Promise<RefundResponse | undefined>> => async (dispatch) => {
  try {
    const { data } = await new RefundControllerApi(
      getArrakisConfiguration(),
    ).updateRefund(refundId, updateRefundRequest);
    await dispatch(fetchRefundsByTransactionId(transactionId, loading));
    dispatch(showSuccessToast('Refund request updated successfully.'));
    return data;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem updating refund request.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to update the refund request.', e, {
      transactionId: { Id: transactionId },
      requestId: { Id: refundId },
      req: { updateRefundRequest },
    });
    return undefined;
  }
};

export const cancelRefundRequest = (
  refundId: string,
  transactionId: string,
  loading?: boolean,
  isAgent: boolean = false,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    if (isAgent) {
      await new RefundControllerApi(
        getArrakisConfiguration(),
      ).agentCancelRefund(refundId);
    } else {
      await new RefundControllerApi(getArrakisConfiguration()).cancelRefund(
        refundId,
      );
    }
    await dispatch(fetchRefundsByTransactionId(transactionId, loading));
    dispatch(showSuccessToast('Refund request cancelled successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to cancel the refund request.', e, {
      transactionId: { Id: transactionId },
      requestId: { Id: refundId },
    });
  }
};

export const uploadRefundAttachment = (
  refundId: string,
  name: string,
  attachment: File,
  description?: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new RefundControllerApi(getArrakisConfiguration()).uploadAttachment(
      refundId,
      name,
      attachment,
      description,
    );
    return true;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem uploading the refund attachment.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to upload the refund attachment.', e, {
      requestId: { Id: refundId },
      req: { name, description },
    });
    return false;
  }
};

export const fetchRefundAttachmentUrl = (
  attachmentId: string,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new RefundControllerApi(
      getArrakisConfiguration(),
    ).getAttachmentURl(attachmentId);
    return data;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem fetching the refund attachment.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to fetch the refund attachment.', e, {
      attachmentId: { Id: attachmentId },
    });
    return undefined;
  }
};

export const deleteRefundAttachment = (
  attachmentId: string,
  refundId: string,
): AppThunk => async (dispatch) => {
  try {
    await new RefundControllerApi(
      await getArrakisConfiguration(),
    ).deleteAttachment(attachmentId);
    dispatch(deleteAttachment(attachmentId));
    dispatch(showSuccessToast('Refund attachment deleted successfully.'));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem deleting the refund attachment.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to delete the refund attachment.', e, {
      transaction: { attachmentId },
      requestId: { Id: refundId },
    });
  }
};

export const fetchAllRefundAttachments = (refundId: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new RefundControllerApi(
      await getArrakisConfiguration(),
    ).getAllAttachmentForRefund(refundId);
    const attachments = data?.map((attachment) => ({
      ...attachment,
      isLocal: false,
    }));
    dispatch(saveAttachments(attachments));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem fetching the refund attachments.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to fetch the refund attachments.', e, {
      requestId: { Id: refundId },
    });
  }
};

export const replaceRefundAttachment = (
  attachmentId: string,
  attachment: File,
  refundId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new RefundControllerApi(
      getArrakisConfiguration(),
    ).replaceAttachment(attachmentId, attachment);
    dispatch(
      replaceAttachment({
        attachmentId,
        attachment: { ...data, isLocal: false },
      }),
    );
    dispatch(showSuccessToast('Refund attachment replaced successfully.'));
    return true;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem replacing the refund attachment.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to replace the refund attachment.', e, {
      req: { requestId: refundId, attachmentId },
    });
    return false;
  }
};

export const restoreRefundRequest = (
  transactionId: string,
  refundId: string,
): AppThunk => async (dispatch) => {
  try {
    await new RefundControllerApi(
      await getArrakisConfiguration(),
    ).restoreRefund(refundId);
    await dispatch(fetchRefundsByTransactionId(transactionId, false));
    dispatch(showSuccessToast('Refund request restored successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to restore the refund request.', e, {
      req: { transactionId, requestId: refundId },
    });
  }
};

export const brokerApproveRefundRequest = (
  transactionId: string,
  refundId: string,
): AppThunk => async (dispatch) => {
  try {
    await new RefundControllerApi(
      await getArrakisConfiguration(),
    ).brokerApproveRefund(refundId);
    await dispatch(fetchRefundsByTransactionId(transactionId, false));
    dispatch(showSuccessToast('Refund request approved successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to approve the refund request.', e, {
      req: { transactionId, requestId: refundId },
    });
  }
};

export const financeApproveRefundRequest = (
  transactionId: string,
  refundId: string,
  request?: FinanceApproveRequest,
): AppThunk => async (dispatch) => {
  try {
    await new RefundControllerApi(
      await getArrakisConfiguration(),
    ).financeApproveRefund(refundId, request!);
    await dispatch(fetchRefundsByTransactionId(transactionId, false));
    dispatch(showSuccessToast('Refund request mark as reviewed successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to approve the refund request.', e, {
      req: { transactionId, requestId: refundId },
    });
  }
};

export const revertRefundRequest = (
  transactionId: string,
  refundId: string,
): AppThunk => async (dispatch) => {
  try {
    await new RefundControllerApi(await getArrakisConfiguration()).revertRefund(
      refundId,
    );
    await dispatch(fetchRefundsByTransactionId(transactionId, false));
    dispatch(showSuccessToast('Refund request reverted successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to revert the refund request.', e, {
      req: { transactionId, requestId: refundId },
    });
  }
};

export const releaseRefundRequest = (
  transactionId: string,
  refundId: string,
): AppThunk => async (dispatch) => {
  try {
    await new RefundControllerApi(
      await getArrakisConfiguration(),
    ).releaseRefund(refundId);
    await dispatch(fetchRefundsByTransactionId(transactionId, false));
    dispatch(showSuccessToast('Refund request released successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to release the refund request.', e, {
      req: { transactionId, requestId: refundId },
    });
  }
};

export default refundSlice.reducer;
