import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form-v7';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import {
  AgentTestimonialResponse,
  AgentWebsiteResponse,
} from '../../../../../openapi/yenta';
import { saveAgentWebsiteDetails } from '../../../../../slices/AgentSlice';
import { TEXT_CONTENT_VALIDATIONS } from '../../../../../utils/Validations';
import ZenStepContentInput from '../../../../agentWebsiteOnboarding/ZenStepContentInput';
import ZenControlledTextAreaInput from '../../../../Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../../../../Zen/Input/ZenControlledTextInput';
import ZenButton from '../../../../Zen/ZenButton';
import ZenSidebarModalForm from '../../../../Zen/ZenSidebarModalForm';

interface ZenEditTestimonialSidebarModalFormProps {
  isOpen: boolean;
  onClose(): void;
  agentWebsite: AgentWebsiteResponse;
}

interface FormData {
  testimonials: AgentTestimonialResponse[];
}

const ZenEditTestimonialSidebarModalForm: React.FC<ZenEditTestimonialSidebarModalFormProps> = ({
  isOpen,
  onClose,
  agentWebsite,
}) => {
  const dispatch = useDispatch();
  const defaultValues = useMemo(
    () => ({
      testimonials:
        agentWebsite?.testimonials?.map((testimonial) => testimonial) || [],
    }),
    [agentWebsite?.testimonials],
  );
  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'testimonials',
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = async (formData: FormData) => {
    const updatedData: AgentWebsiteResponse = {
      ...agentWebsite,
      testimonials: formData.testimonials.map((testimonial) => ({
        clientName: testimonial.clientName,
        content: testimonial.content,
      })),
    };
    dispatch(saveAgentWebsiteDetails(updatedData));
    onClose();
  };

  return (
    <ZenSidebarModalForm
      title='Testimonials'
      subtitle='This content shows on the Home page'
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      actionTitle='Save'
    >
      <div>
        {fields.map((item: any, index: number) => {
          return (
            <div key={item.id} className='mb-10 relative'>
              <ZenStepContentInput step={index + 1}>
                <div className='flex flex-col flex-auto'>
                  <ZenControlledTextInput<
                    FormData,
                    `testimonials.${number}.clientName`
                  >
                    label='Client name'
                    control={control}
                    placeholder='Client Name'
                    rules={{ required: 'Client Name is required' }}
                    name={`testimonials.${index}.clientName` as const}
                    shouldUnregister={false}
                    isRequired
                  />
                  <div className='mt-2'>
                    <ZenControlledTextAreaInput<
                      FormData,
                      `testimonials.${number}.content`
                    >
                      control={control}
                      label='Testimonial'
                      subLabel='(255 characters max)'
                      rows={3}
                      name={`testimonials.${index}.content` as const}
                      placeholder='Testimonial'
                      rules={{
                        required: 'Testimonial Content is required',
                        ...TEXT_CONTENT_VALIDATIONS,
                      }}
                      shouldUnregister={false}
                      isRequired
                    />
                  </div>
                </div>
                <div className='absolute right-0 cursor-pointer'>
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    onClick={() => remove(index)}
                    className='text-zen-danger'
                    aria-label='delete'
                    fontSize={16}
                  />
                </div>
              </ZenStepContentInput>
            </div>
          );
        })}
      </div>
      <div className='py-3 flex flex-row justify-between'>
        <ZenButton
          label='Add a testimonial'
          LeftIconComponent={
            <FontAwesomeIcon
              icon={faCirclePlus}
              className='text-primary-blue'
            />
          }
          onClick={() =>
            append({
              clientName: '',
              content: '',
            })
          }
          variant='primary-link'
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenEditTestimonialSidebarModalForm;
