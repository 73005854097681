import ZenButton from '../../components/Zen/ZenButton';
import ErrorService from '../../services/ErrorService';
import { TransactionControllerApi } from '../../openapi/arrakis';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { isAxiosError } from '../../utils/TypeUtils';
import ForceReportingAxiosError from '../../errors/ForceReportingAxiosError';

interface ErrorIndexProps {}

const ErrorIndex: React.FC<ErrorIndexProps> = () => {
  return (
    <div className='max-w-xl mx-auto'>
      <h1 className='font-primary-medium mt-10 text-xl mb-1 flex'>
        Error Services
      </h1>
      <div className='flex flex-col items-start gap-4 mt-10'>
        <ZenButton
          label='Test Error (Handled)'
          onClick={() =>
            ErrorService.notify('Test Error', new Error('Test Error!'))
          }
        />
        <ZenButton
          label='Test Error with Data (Handled)'
          onClick={() =>
            ErrorService.notify('Test Error', new Error('Test Error!'), {
              data: { key: 'value' },
            })
          }
        />
        <ZenButton
          label='Test Error (Un-handled)'
          onClick={() => {
            throw new Error('Test Error!');
          }}
        />

        <ZenButton
          label='Test Error (Axios)'
          onClick={async () => {
            try {
              await new TransactionControllerApi(
                getArrakisConfiguration(),
              ).getTransaction('blah');
            } catch (e) {
              const forceReportedError = isAxiosError(e)
                ? new ForceReportingAxiosError(e)
                : e;

              ErrorService.notify(
                '[Test] Unable to fetch transaction by id',
                forceReportedError,
                { transaction: { id: 'blah' } },
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default ErrorIndex;
