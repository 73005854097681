import { ApplicationResponseStatusEnum } from '../../../openapi/yenta';
import { capitalizeEnum } from '../../../utils/StringUtils';
import MultiSelectColumnFilter from './Base/MultiSelectColumnFilter';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';

export const IN_PROGRESS_APPLICATION_STATUS = [
  ApplicationResponseStatusEnum.Started,
  ApplicationResponseStatusEnum.SignIca,
  ApplicationResponseStatusEnum.IcaSigned,
  ApplicationResponseStatusEnum.PayFees,
  ApplicationResponseStatusEnum.FeesPaid,
  ApplicationResponseStatusEnum.TransferLicense,
  ApplicationResponseStatusEnum.LicenseTransferred,
  ApplicationResponseStatusEnum.JoinMls,
  ApplicationResponseStatusEnum.MlsJoined,
  ApplicationResponseStatusEnum.JoinBoard,
  ApplicationResponseStatusEnum.BoardJoined,
  ApplicationResponseStatusEnum.PendingApproval,
  ApplicationResponseStatusEnum.SignLetterOfIntent,
  ApplicationResponseStatusEnum.LetterOfIntentSigned,
];

const ApplicationResponseStatusEnumSelectColumnFilter = <D extends object>(
  props: SelectColumnFilterProps<D>,
) => {
  const options = IN_PROGRESS_APPLICATION_STATUS.map((key) => ({
    label: capitalizeEnum(key),
    value: key,
  }));

  return <MultiSelectColumnFilter {...props} options={options} />;
};

export default ApplicationResponseStatusEnumSelectColumnFilter;
