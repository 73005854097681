import ZenResourceIndexContainer, {
  TableProps,
} from './ZenResourceIndexContainer';

interface FixedDataTableProps<D extends object>
  extends Omit<TableProps<D>, 'fetchData'> {}

const ZenFixedDataResourceIndexContainer = <D extends object>(
  props: FixedDataTableProps<D>,
) => {
  return <ZenResourceIndexContainer<D> {...props} standalone={false} />;
};

export default ZenFixedDataResourceIndexContainer;
