import React from 'react';
import { cn } from '../../../utils/classUtils';

export type LabelValueDisplayCardBorderVariant =
  | 'none'
  | 'left'
  | 'top'
  | 'right'
  | 'bottom';

export interface LabelValueDisplayCardProps {
  label: string;
  value: string | React.ReactElement;
  border?: LabelValueDisplayCardBorderVariant;
  noPadding?: boolean;
  noSpace?: boolean;
  leftIcon?: React.ReactElement;
}

const GeminiLabelValueDisplayCard: React.FC<LabelValueDisplayCardProps> = ({
  label,
  value,
  border = 'none',
  noPadding = false,
  leftIcon,
  noSpace = false,
}) => {
  const variantMapClass: {
    [x in LabelValueDisplayCardBorderVariant]: string;
  } = {
    none: 'border-b-0',
    left: 'md:border-l',
    right: 'md:border-r',
    bottom: 'md:border-b',
    top: 'md:border-t',
  };
  return (
    <div
      className={cn(
        'flex flex-row',
        noPadding ? 'p-0' : 'p-6 md:border-0 border-b',
        cn(variantMapClass[border!]),
      )}
    >
      {leftIcon && (
        <div className='flex flex-shrink-0 justify-center items-center h-7 w-7 rounded-full bg-zen-light-gray-2 mr-3'>
          {leftIcon}
        </div>
      )}
      <div
        className={cn(
          'flex-shrink font-inter text-grey-500 text-sm leading-[1.125rem]',
          noSpace && 'space-y-1.5',
        )}
      >
        <p className='font-medium'>{label}</p>
        <p className='font-light text-zen-dark-9'>{value}</p>
      </div>
    </div>
  );
};

export default GeminiLabelValueDisplayCard;
