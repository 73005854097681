import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { cn } from '../../../utils/classUtils';
import { BreakPoint, useScreenSize } from '../../WeathManagement/utils';

interface ZenMoreTabProps {
  show: boolean;
  onClick(): void;
  smallButton?: boolean;
}

const USE_ELLIPSIS_SCREEN_SIZES = [BreakPoint.xs, BreakPoint.s];

export const ZenMoreTab = React.forwardRef<HTMLDivElement, ZenMoreTabProps>(
  ({ show, onClick, smallButton = false }, ref) => {
    const { screen } = useScreenSize();
    const isSmallScreen = USE_ELLIPSIS_SCREEN_SIZES.includes(screen);

    return (
      <div
        className={cn('px-2 mr-5 relative', {
          'invisible absolute': !show,
        })}
        data-testid='more-tab'
        ref={ref}
      >
        <div className='flex flex-row items-center point'>
          <div className='block relative focus:outline-none text-base text-grey-500 font-normal font-zen-body'>
            <div
              className={cn(
                'py-2 flex flex-row items-center cursor-pointer',
                isSmallScreen || smallButton
                  ? 'px-2 text-sm'
                  : 'px-6 text-base',
              )}
              data-testid='more-tab-button'
              onClick={onClick}
            >
              {isSmallScreen || smallButton ? (
                <FontAwesomeIcon icon={faEllipsis} />
              ) : (
                <>
                  <p className='whitespace-pre mr-2'>More</p>
                  <FontAwesomeIcon icon={faCaretDown} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
);
