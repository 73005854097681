import { RezenObjectTypeEnum } from '../openapi/yada';

export const getBrokerQueuePathName = (
  transactionId: string,
  type: RezenObjectTypeEnum,
) => {
  if (type === RezenObjectTypeEnum.Listing) {
    return `/listings/${transactionId}/comments?brokerQueue=true`;
  }

  return `/transactions/${transactionId}/comments?brokerQueue=true`;
};

export const getMortgageQueuePathName = (
  loanId?: string,
  borrowerId?: string,
) => {
  if (!loanId && !borrowerId) {
    return '/mortgages';
  }

  if (loanId && !borrowerId) {
    return `/mortgages/${loanId}?brokerQueue=true`;
  }

  return `/mortgages/${loanId}/borrower/${borrowerId}/comments?brokerQueue=true`;
};
