import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { cn } from '../utils/classUtils';
import { FormFieldTooltipIndexProps, ISelectOption } from '../types';
import FormErrorMessage from './FormErrorMessage';

interface ControlledRadioInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  subLabel?: string;
  options: Array<ISelectOption>;
  inlineOptions?: boolean;
  inlineLabel?: boolean;
  disabled?: boolean;
  radioButtonStyle?: string;
  isRequired?: boolean;
}

const ControlledRadioInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  options,
  inlineOptions = false,
  inlineLabel = false,
  disabled = false,
  tooltipIndex,
  shouldUnregister = true,
  isRequired = false,
  radioButtonStyle,
  ...rest
}: ControlledRadioInputV7Props<TFieldValues, TName>) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <div className='w-full space-y-1'>
          <div
            className={cn('flex', {
              'justify-between': inlineLabel,
              'flex-col space-y-1': !inlineLabel,
            })}
          >
            {label && (
              <label htmlFor={name}>
                <span>{label}</span>
                {!!subLabel && (
                  <span className='ml-1 text-sm text-gray-400 font-primary-regular'>
                    {subLabel}
                  </span>
                )}
                {isRequired && <span className='text-error'>*</span>}
              </label>
            )}
            <div
              className={cn(inlineOptions ? 'flex space-x-3' : 'space-y-1')}
              data-tooltip-index={tooltipIndex}
            >
              {options.map(({ value: optionValue, label, disabled }) => (
                <label
                  key={optionValue}
                  className={cn(
                    'flex items-center justify-start space-x-2 text-black',
                    disabled ? 'text-opacity-50' : '',
                    radioButtonStyle,
                  )}
                >
                  <input
                    type='radio'
                    className='w-4 h-4 border-none rounded-full ring-1 ring-gray-200 focus:outline-none focus:ring-0 text-primary'
                    value={optionValue}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={value === optionValue}
                    disabled={disabled}
                  />
                  <p>{label}</p>
                </label>
              ))}
            </div>
          </div>
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledRadioInputV7;
