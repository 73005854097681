interface WealthManagementCardProps {
  classNames?: React.HTMLAttributes<HTMLDivElement>['className'];
  title?: React.ReactNode;
  titleBackground?: string;
  titleClassName?: React.HTMLAttributes<HTMLDivElement>['className'];
}
export const WealthManagementCard: React.FC<WealthManagementCardProps> = ({
  children,
  classNames = '',
  title,
  titleBackground = 'unset',
  titleClassName = '',
}) => (
  <div className={`border border-gray-200 rounded-xl mb-4 ${classNames}`}>
    {title && (
      <div
        className={`text-xl font-primary-light mb-2 bg-[${titleBackground}] ${titleClassName}`}
      >
        <div className='p-3'>{title}</div>
      </div>
    )}
    <div className='p-3'>{children}</div>
  </div>
);
