import { AnnouncementResponse } from '../../../openapi/mercury';
import AnnouncementDetails from './AnnouncementDetails';

export interface AnnouncementModalProps {
  announcement: AnnouncementResponse;
  preview?: boolean;
}

const AnnouncementModal: React.FC<AnnouncementModalProps> = ({
  announcement,
  preview = false,
}) => {
  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40' />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
        <div className='z-10 w-5/6 sm:w-4/6 md:w-1/3 sm:max-w-[550px] sm:min-w-[475px] max-h-[85%] rounded-2xl bg-white flex flex-col'>
          <AnnouncementDetails preview={preview} announcement={announcement} />
        </div>
      </div>
    </div>
  );
};

export default AnnouncementModal;
