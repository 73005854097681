import { getTeamTypeSelectOptions } from '../../../utils/TeamHelper';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import MultiSelectColumnFilter from './Base/MultiSelectColumnFilter';

export interface TeamTypeSelectColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const TeamTypeSelectColumnFilter = <D extends object>(
  props: TeamTypeSelectColumnFilterProps<D>,
) => {
  return (
    <MultiSelectColumnFilter {...props} options={getTeamTypeSelectOptions()} />
  );
};

export default TeamTypeSelectColumnFilter;
