import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  AgentControllerApi,
  OneRealImpactFundConfigDto,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { saveDetail } from '../../slices/AgentSlice';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenButton from '../Zen/ZenButton';
import ZenSidebarModalForm from '../Zen/ZenSidebarModalForm';

interface OneRealImpactSidebarModalProps {
  agentId: string;
  oneRealImpactFund?: OneRealImpactFundConfigDto;
  isOpen: boolean;
  onClose(): void;
}

const OneRealImpactSidebarModal: React.FC<OneRealImpactSidebarModalProps> = ({
  agentId,
  oneRealImpactFund,
  isOpen,
  onClose,
}) => {
  const { amount, percent, percentEnabled } = oneRealImpactFund || {};
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    watch,
  } = useForm<OneRealImpactFundConfigDto>({
    defaultValues: {
      amount,
      percent,
      percentEnabled,
    },
  });
  const isPercentageEnabled = watch('percentEnabled');

  const onSubmit = async (values: OneRealImpactFundConfigDto) => {
    try {
      const { data } = await new AgentControllerApi(
        getYentaConfiguration(),
      ).updateOneRealImpactFund(agentId, values);
      if (!!data) {
        dispatch(saveDetail({ name: 'detail', loading: false, data }));
      }
      dispatch(
        showSuccessToast('Successfully donated to the One Real Impact Fund!'),
      );
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error donating to the One Real Impact Fund', e);
      dispatch(
        showErrorToastForErrorCode(
          'We encountered an error while donating to the One Real Impact Fund, Please try again in a few moments.',
          ErrorService.getErrorCode(e),
        ),
      );
    }
    onClose();
  };

  return (
    <ZenSidebarModalForm
      title='Donate to One Real Impact'
      isOpen={isOpen}
      onClose={onClose}
      actionTitle='Save'
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='p-6 mt-1 rounded-2xl border border-zen-dark-4 bg-white'>
        <p className='font-zen-body text-xl font-medium text-zen-dark-9'>
          Donate a portion of your commission per transaction to the One Real
          Impact Fund.
        </p>
        <div className='font-zen-body text-base font-normal text-zen-dark-8'>
          <p>
            By selecting one of the options below, there will be a deduction on
            each transaction in the specified amount.
          </p>
          <p>
            You can cancel this at any time and adjust per transaction if
            needed. For more information, please visit
            <a
              className='text-primary-blue ml-1 font-zen-body font-semibold'
              href='http://impact.onereal.com/'
              target='_blank'
              rel='noreferrer'
            >
              One Real Impact{' '}
              <FontAwesomeIcon
                icon={regular('arrow-up-right-from-square')}
                className='font-zen-body font-semibold text-sm text-primary-blue'
              />
            </a>
          </p>
        </div>
        <div className='w-full flex flex-row mt-4'>
          <div className='w-1/2 mr-2'>
            <ZenButton
              isFullWidth
              label='Donate as dollars'
              LeftIconComponent={
                <FontAwesomeIcon icon={regular('badge-dollar')} />
              }
              variant={
                isPercentageEnabled
                  ? 'secondary-gray-outline'
                  : 'primary-outline'
              }
              onClick={() => setValue('percentEnabled', undefined)}
            />
          </div>
          <div className='w-1/2 ml-2'>
            <ZenButton
              isFullWidth
              label='Donate as percentage'
              LeftIconComponent={
                <FontAwesomeIcon icon={regular('badge-percent')} />
              }
              variant={
                isPercentageEnabled
                  ? 'primary-outline'
                  : 'secondary-gray-outline'
              }
              onClick={() => setValue('percentEnabled', true)}
            />
          </div>
        </div>
        <div className='w-full flex flex-row mt-4'>
          <ZenControlledTextInput<
            OneRealImpactFundConfigDto,
            `amount` | `percent`
          >
            name={isPercentageEnabled ? 'percent' : 'amount'}
            control={control}
            type='number'
            placeholder={
              isPercentageEnabled ? 'Enter Percentage' : 'Enter Amount'
            }
            rules={{
              required: isPercentageEnabled
                ? 'Percentage required'
                : 'Amount required',
              validate: (value) => {
                if (!!value && value < 0) {
                  return 'Value must be greater than or equal to 0';
                } else if (!!value && isPercentageEnabled && value > 100) {
                  return 'Value must be less than or equal to 100';
                }
                return;
              },
            }}
            startAdornment={
              <div className='flex w-full h-full items-center justify-center pl-4'>
                <FontAwesomeIcon
                  className='text-primary-blue'
                  icon={
                    isPercentageEnabled ? regular('percent') : regular('dollar')
                  }
                />
              </div>
            }
          />
        </div>
      </div>
    </ZenSidebarModalForm>
  );
};

export default OneRealImpactSidebarModal;
