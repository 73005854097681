import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as FileCabinetIcon } from '../../assets/icons/cabinet.svg';
import { AppDispatch, RootState } from '../../types';
import ErrorService from '../../services/ErrorService';
import { FileResponse } from '../../openapi/dropbox';
import { showSuccessToast } from '../../slices/ToastNotificationSlice';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import { isV2Checklist } from '../../utils/ChecklistUtils';
import ZenManageFileCabinetSidebarModal from './ZenManageFileCabinetSidebarModal';

interface ZenFromFileCabinetButtonProps {
  checklistId: string;
  dropboxId: string;
  secondaryDropboxId?: string;
  onAttach(
    files: FileResponse[],
    uploadAndAttach?: boolean,
  ): boolean | Promise<boolean>;
  isMultiple?: boolean;
}

const ZenFromFileCabinetButton: React.FC<ZenFromFileCabinetButtonProps> = ({
  checklistId,
  dropboxId,
  secondaryDropboxId,
  onAttach,
  isMultiple,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    checklist: { checklistsById },
  } = useSelector((state: RootState) => state);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isChecklistV2 = isV2Checklist(checklistsById[checklistId]?.data);

  const handleAttach = async (
    files: FileResponse[],
    uploadAndAttach?: boolean,
  ) => {
    try {
      const isAttached = await onAttach(files, uploadAndAttach);
      if (isAttached) {
        setIsOpen(false);
        if (!isChecklistV2) {
          dispatch(showSuccessToast('File is attached successfully!'));
        }
      }
    } catch (e) {
      if (!isChecklistV2) {
        dispatch(showApiErrorModal(e));
        ErrorService.notify('Unable to attach a file', e, {
          files,
        });
      }
    }
  };

  return (
    <div className='h-full'>
      <button
        type='button'
        className='border border-zen-light-blue bg-zen-light-blue rounded-lg appearance-none h-full w-40 flex flex-col items-center justify-center p-6'
        onClick={() => setIsOpen(true)}
      >
        <FileCabinetIcon className='text-primary-blue' />
        <div className='pt-1 text-center text-sm leading-6 font-zen-body font-semibold text-primary-blue h-full'>
          File Cabinet
        </div>
      </button>
      {isOpen && (
        <ZenManageFileCabinetSidebarModal
          isOpen
          onClose={() => setIsOpen(false)}
          dropboxId={dropboxId}
          secondaryDropboxId={secondaryDropboxId}
          attach={handleAttach}
          isMultiple={isMultiple}
        />
      )}
    </div>
  );
};

export default ZenFromFileCabinetButton;
