import { values } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/pro-regular-svg-icons';
import mlsCheck from '../../assets/img/mls_check.png';
import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../routes/AgentWebsiteOnboardingRoute';
import { AGENT_ONBOARDING_STEPS } from '../../testUtils/AgentWebsiteOnboardingUtils';
import { AgentWebsiteOnboardingStepEnum } from '../../types';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import {
  PropertyFilterBathroomsEnum,
  PropertyFilterBedroomsEnum,
  PropertyFilterCategoryTypeEnum,
  PropertyFilterHomeJunctionPropertyTypesEnum,
} from '../../openapi/yenta';
import { capitalizeEnum } from '../../utils/StringUtils';
import {
  BATHS_SELECT_OPTION,
  BEDS_SELECT_OPTION,
  CATEGORY_SELECT_OPTION,
} from '../../utils/AgentWebsiteUtils';
import ApplicationStepProgressHeader from '../onboarding/ApplicationStepProgressHeader';
import ControlledTextInputV7 from '../ControlledTextInputV7';
import ControlledGoogleAutocompleteSearchInputV7 from '../ControlledGoogleAutocompleteSearchInputV7';
import ControlledGroupRadioButtonInput from '../ControlledGroupRadioButtonInput';
import ControlledRangeSliderV7 from '../ControlledRangeSliderV7';
import ControlledCheckboxGroupV7 from '../ControlledCheckboxGroupV7';

const AgentWebsiteOnboardingListing: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ form: { control, watch, setValue } }) => {
  const category = watch('propertyFilter.categoryType');

  return (
    <div className='h-full w-full flex flex-col items-center max-w-6xl mx-auto'>
      <ApplicationStepProgressHeader
        steps={AGENT_ONBOARDING_STEPS}
        inProgressIndex={AgentWebsiteOnboardingStepEnum.LISTINGS}
      />
      <div className='w-full'>
        <div className='my-4 font-primary-regular text-base text-dark leading-loose'>
          <p>
            Set a search criteria to display properties from your area. Your
            home page will show the top 4.
          </p>
          <p>Customers can navigate to see your full listings.</p>
        </div>
        <div className='grid lg:grid-cols-2 grid-cols-1 lg:gap-x-20 lg:gap-y-3 gap-y-10'>
          <div>
            <ControlledGroupRadioButtonInput<
              AgentWebsiteOnboardingFormData,
              'propertyFilter.categoryType'
            >
              label='Category'
              control={control}
              shouldUnregister={false}
              name='propertyFilter.categoryType'
              options={CATEGORY_SELECT_OPTION}
            />
          </div>
          <div>
            <ControlledCheckboxGroupV7<
              AgentWebsiteOnboardingFormData,
              'propertyFilter.homeJunctionPropertyTypes'
            >
              control={control}
              label='Property type'
              name='propertyFilter.homeJunctionPropertyTypes'
              shouldUnregister={false}
              options={values(PropertyFilterHomeJunctionPropertyTypesEnum).map(
                (type) => ({
                  value: type,
                  label: capitalizeEnum(type),
                }),
              )}
            />
          </div>
          <div>
            {category === PropertyFilterCategoryTypeEnum.Mls ? (
              <div className='relative'>
                <ControlledTextInputV7<
                  AgentWebsiteOnboardingFormData,
                  'propertyFilter.mlsNumber'
                >
                  name='propertyFilter.mlsNumber'
                  control={control}
                  placeholder='MLS number'
                  shouldUnregister={false}
                  label='MLS Number/Name'
                />
                <img
                  src={mlsCheck}
                  alt='check'
                  className='w-6 h-6 absolute top-9 right-2'
                />
              </div>
            ) : (
              <ControlledGoogleAutocompleteSearchInputV7<
                AgentWebsiteOnboardingFormData,
                'location'
              >
                label='Location'
                control={control}
                shouldUnregister={false}
                name='location'
                rules={{
                  validate: (value) =>
                    !value?.formatted_address
                      ? 'Location is required.'
                      : undefined,
                }}
              />
            )}
          </div>
          <div>
            <ControlledGroupRadioButtonInput<
              AgentWebsiteOnboardingFormData,
              'propertyFilter.bedrooms'
            >
              name='propertyFilter.bedrooms'
              shouldUnregister={false}
              control={control}
              label='Bedrooms'
              options={BEDS_SELECT_OPTION}
            />
          </div>
          <div>
            <ControlledRangeSliderV7<
              AgentWebsiteOnboardingFormData,
              'priceRange'
            >
              control={control}
              label='Price range'
              name='priceRange'
              min={0}
              max={3750000}
              step={5000}
              minValueLabel='Minimum price'
              maxValueLabel='Maximum price'
              minValuePlaceholder='Any'
              maxValuePlaceholder='Any'
              shouldUnregister={false}
            />
          </div>
          <div>
            <ControlledGroupRadioButtonInput<
              AgentWebsiteOnboardingFormData,
              'propertyFilter.bathrooms'
            >
              name='propertyFilter.bathrooms'
              control={control}
              label='Bathrooms'
              options={BATHS_SELECT_OPTION}
              shouldUnregister={false}
            />
          </div>
        </div>
        <div className='lg:mt-2 my-5 text-right'>
          <button
            type='button'
            onClick={() => {
              setValue('propertyFilter', {
                bathrooms: PropertyFilterBathroomsEnum.Any,
                bedrooms: PropertyFilterBedroomsEnum.Any,
                categoryType: PropertyFilterCategoryTypeEnum.Buy,
                minPrice: 0,
                maxPrice: 3750000,
                googlePlaceId: undefined,
                latitude: undefined,
                longitude: undefined,
                mlsNumber: undefined,
              });
              setValue('propertyFilter.homeJunctionPropertyTypes', []);
              setValue('priceRange', { min: 0, max: 3750000 });
              setValue('location.formatted_address', '');
              setValue('location.geometry.location.lat', undefined);
              setValue('location.geometry.location.lng', undefined);
              setValue('location.place_id', '');
              setValue('location.formatted_address', '');
              setValue('propertyFilter.mlsNumber', '');
            }}
            className='focus:outline-none'
          >
            <div className='flex flex-row items-center hover:bg-error hover:bg-opacity-10 p-2 rounded'>
              <FontAwesomeIcon
                icon={faRotateRight}
                size='sm'
                className='text-error'
              />
              <span className='text-error ml-2 pt-0.5'>
                Reset all to default
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingListing;
