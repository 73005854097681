import { values } from 'lodash';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  FinanceApproveRequestFundingSourceEnum,
  RefundResponse,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { financeApproveRefundRequest } from '../../../slices/ReleaseFundsSlice';
import { AnalyticsEventEnum, AppDispatch } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenControlledHTMLSelectInput from '../../Zen/Input/ZenControlledHTMLSelectInput';
import ZenSidebarModalForm from '../../Zen/ZenSidebarModalForm';
import AnalyticsService from '../../../services/AnalyticsService';

interface FormData {
  fundingType: FinanceApproveRequestFundingSourceEnum;
}
interface TrustFundMarkAsReviewModalProps {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
  refundRequest: RefundResponse;
}

const TrustFundMarkAsReviewModal: React.FC<TrustFundMarkAsReviewModalProps> = ({
  isOpen,
  onClose,
  transaction,
  refundRequest,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();
  const dispatch: AppDispatch = useDispatch();

  const onSubmit = async (data: FormData) => {
    await dispatch(
      financeApproveRefundRequest(transaction?.id!, refundRequest?.id!, {
        fundingSource: data.fundingType,
      }),
    );
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.EXCESS_FUNDS_CLICKED_MARK_REVIEW_REQUEST,
      {
        requestId: refundRequest?.id,
        paymentType: refundRequest?.paymentType,
      },
    );
    onClose();
  };

  return (
    <ZenSidebarModalForm
      title='Mark as Reviewed'
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Confirm'
      isSubmitting={isSubmitting}
    >
      <div>
        <ZenControlledHTMLSelectInput
          options={[
            {
              label: 'Select Funding Source',
              value: '',
            },
            ...values(FinanceApproveRequestFundingSourceEnum)?.map(
              (source) => ({
                value: source,
                label: capitalizeEnum(source),
              }),
            ),
          ]}
          name='fundingType'
          label='Funding Source'
          control={control}
          isRequired
          rules={{ required: 'Please select funding source' }}
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default TrustFundMarkAsReviewModal;
