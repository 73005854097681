import {
  faHouseBuilding,
  faPen,
  faPersonWalkingArrowRight,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flatten, values } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  AgentResponse,
  MlsControllerApi,
  MlsPropertyTypeMapping,
  MlsResponse,
  MlsSecretsResponse,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import {
  FeatureFlagTypeEnum,
  MlsPropertyTypesWandererType,
} from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../../Zen/ZenButton';
import GeminiCreateMLSForm from '../Gemini/GeminiCreateMLSForm';
import ZenCreateMLSForm from './ZenCreateMLSForm';
import ZenMlsPropertyTypesForm from './ZenMLSPropertyTypesForm';
import ZenMoveAllAgentsToMLS from './ZenMoveAllAgentsToMLS';

interface ZenMLSActionHeaderProps {
  mls: MlsResponse;
  secrets?: MlsSecretsResponse;
  agents: AgentResponse[];
  mlsPropertyTypesMappings?: MlsPropertyTypeMapping[];
  mlsPropertyTypesWanderer: MlsPropertyTypesWandererType;
  refetch: () => void;
}

const ZenMLSActionHeader: React.FC<ZenMLSActionHeaderProps> = ({
  mls,
  secrets,
  agents,
  mlsPropertyTypesMappings,
  mlsPropertyTypesWanderer,
  refetch,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openEditMLSForm, setOpenEditMLSForm] = useState<boolean>(false);
  const [isDeleteMls, setIsDeleteMls] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [
    openEditMLSPropertyForm,
    setOpenEditMLSPropertyForm,
  ] = useState<boolean>(false);
  const [isMoveAllAgents, setIsMoveAllAgents] = useState<boolean>(false);

  const isEditPropertyTypesDisabled =
    !flatten(values(mlsPropertyTypesWanderer)).length &&
    !mlsPropertyTypesMappings?.length;

  const handleDeleteMls = async () => {
    setIsSubmitting(true);
    try {
      await new MlsControllerApi(getYentaConfiguration()).deleteMls(mls?.id!);
      history.push('/mls');
      dispatch(showSuccessToast('MLS deleted successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to delete mls', e, {
        mls: { id: mls?.id },
      });
      dispatch(
        showErrorToast(
          'We had a problem deleting mls',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setIsSubmitting(false);
      setIsDeleteMls(false);
    }
  };

  const isBoardAndMlsImprovementsFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.BOARD_AND_MLS_IMPROVEMENTS,
  );
  const CreateMlsSidebarModal = isBoardAndMlsImprovementsFlagEnabled
    ? GeminiCreateMLSForm
    : ZenCreateMLSForm;

  return (
    <div className='flex justify-start space-x-3'>
      <ZenButton
        variant='secondary-gray-outline'
        label='Edit MLS'
        LeftIconComponent={<FontAwesomeIcon icon={faPen} className='mr-1' />}
        onClick={() => setOpenEditMLSForm(true)}
      />
      <ZenButton
        variant='secondary-gray-outline'
        label='Edit Property Types'
        LeftIconComponent={
          <FontAwesomeIcon icon={faHouseBuilding} className='mr-1 text-lg' />
        }
        onClick={() => setOpenEditMLSPropertyForm(true)}
        isDisabled={isEditPropertyTypesDisabled}
      />
      <ZenButton
        variant='secondary-gray-outline'
        label='Move All Agents To'
        LeftIconComponent={
          <FontAwesomeIcon
            icon={faPersonWalkingArrowRight}
            className='mr-1 text-lg'
          />
        }
        onClick={() => setIsMoveAllAgents(true)}
        isDisabled={!agents?.length}
      />
      {!agents?.length && (
        <ZenButton
          variant='danger-outline'
          label='Delete MLS'
          LeftIconComponent={
            <FontAwesomeIcon
              icon={faTrashCan}
              className='text-error text-sm mr-1'
            />
          }
          onClick={() => setIsDeleteMls(true)}
        />
      )}
      {openEditMLSForm && (
        <CreateMlsSidebarModal
          isOpen
          secrets={secrets}
          onClose={() => {
            refetch();
            setOpenEditMLSForm(false);
          }}
          mlsDetails={mls}
        />
      )}
      {openEditMLSPropertyForm && (
        <ZenMlsPropertyTypesForm
          isOpen
          onClose={() => setOpenEditMLSPropertyForm(false)}
          mlsDetails={mls}
        />
      )}
      <ZenConfirmationModal
        title='Delete this MlS?'
        subtitle={`This will delete "${mls?.name}" permanently.`}
        variant='danger'
        isOpen={isDeleteMls}
        onClose={() => setIsDeleteMls(false)}
        onConfirm={handleDeleteMls}
        isSubmitting={isSubmitting}
        cancelButtonText='No'
        confirmButtonText='Yes'
      />
      {isMoveAllAgents && (
        <ZenMoveAllAgentsToMLS
          isOpen={isMoveAllAgents}
          onClose={() => setIsMoveAllAgents(false)}
          mls={mls}
        />
      )}
    </div>
  );
};

export default ZenMLSActionHeader;
