import React, { HTMLAttributes } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { cn } from '../../../utils/classUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';
import ZenToggleRow from './ZenToggleRow';

interface ZenControlledToggleInputProp<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  tooltip?: string;
  reverse?: boolean;
  rightIcon?: string | React.ReactElement;
  leftIcon?: string | React.ReactElement;
  readOnly?: boolean;
  hideErrorMessage?: boolean;
  labelClassName?: HTMLAttributes<HTMLDivElement>['className'];
  onChangeSpy?: (value: boolean) => void;
  disabled?: boolean;
}

const ZenControlledToggleInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  tooltip,
  reverse = false,
  leftIcon,
  rightIcon,
  readOnly = false,
  hideErrorMessage = false,
  labelClassName = '',
  onChangeSpy,
  disabled,
  ...rest
}: ZenControlledToggleInputProp<TFieldValues, TName>) => {
  return (
    <Controller
      {...rest}
      render={({
        field: { value, onChange, name },
        fieldState: { error, invalid },
      }) => (
        <div className='space-y-1 w-full'>
          <div
            className={cn('flex flex-row items-center', {
              'justify-between': !reverse,
            })}
          >
            {!!label && (
              <label
                htmlFor={name}
                className={cn(
                  'font-primary-regular text-dark px-2',
                  reverse ? 'order-last' : 'order-first',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                  labelClassName,
                )}
              >
                {label}
              </label>
            )}

            <ZenToggleRow
              value={value}
              onChange={(checked) => {
                if (!readOnly) {
                  onChangeSpy?.(checked);
                  onChange(checked);
                }
              }}
              tooltip={tooltip}
              leftIcon={leftIcon}
              rightIcon={rightIcon}
              disabled={disabled}
            />
          </div>
          {error && !hideErrorMessage && (
            <ZenFormErrorMessage message={error.message} />
          )}
        </div>
      )}
    />
  );
};

export default ZenControlledToggleInput;
