import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form-v7';

import {
  BuilderParticipant,
  CommissionSplit,
  MoneyValueCurrencyEnum,
} from '../openapi/arrakis';
import { AgentInfo } from '../openapi/yenta';
import { Mapping } from '../types';
import { CreateTransactionFormState } from '../components/Zen/Transaction/CreateTransaction/ZenCreateTransactionSteps';

export const useSyncWatchparticipants = (
  agentById: Mapping<AgentInfo>,
  setValue: UseFormSetValue<CreateTransactionFormState>,
  allCommissionRecipient?: Array<BuilderParticipant>,
  commissionSplitsInfo?: Array<CommissionSplit>,
) => {
  useEffect(() => {
    setValue(
      'commissionParticipant',
      (allCommissionRecipient || []).map((p) => {
        const splitInfo = commissionSplitsInfo?.find(
          (splitInfo) => splitInfo.participantId === p.id,
        );

        const percentage =
          (allCommissionRecipient || []).length > 1
            ? splitInfo?.commission?.commissionPercent ?? ''
            : splitInfo?.commission?.commissionPercent;

        return {
          money: {
            amount: splitInfo?.commission.commissionAmount?.amount!,
            currency: splitInfo?.commission.commissionAmount
              ?.currency! as MoneyValueCurrencyEnum,
          },
          percent: {
            value: percentage as any,
            string: `${splitInfo?.commission.commissionPercent! || 0}%`,
          },
          isDollar:
            typeof splitInfo?.commission.percentEnabled === 'undefined'
              ? false
              : !splitInfo?.commission.percentEnabled,
          id: p.id,
          //@ts-ignore
          agentId: p?.agentId,
          //@ts-ignore
          firstName: p.agentId ? agentById[p?.agentId]?.firstName : p.firstName,
          //@ts-ignore
          lastName: p.agentId ? agentById[p?.agentId]?.lastName : p.lastName,
          //@ts-ignore
          participantRole: p?.role,
          //@ts-ignore
          paidViaBusinessEntity: { name: p?.companyName },
        };
      }),
    );
  }, [agentById, setValue, allCommissionRecipient, commissionSplitsInfo]);
};
