import config from '../config';

export const getBaseStripeUrl = () => {
  let url = `https://dashboard.stripe.com`;

  if (config.stripe.publishableKey.includes('test')) {
    url += '/test';
  }

  return url;
};
