import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OfficePreviewResponse } from '../../openapi/yenta';
import ZenSideBarItem from '../ZenSideBarItem';

interface UserOfficesSidebarMenuItemProps {
  offices: OfficePreviewResponse[];
  isCollapsed: boolean;
}

const UserOfficesSidebarMenuItem: React.FC<UserOfficesSidebarMenuItemProps> = ({
  offices,
  isCollapsed,
}) => {
  if (offices.length === 0) {
    return null;
  }

  if (offices.length === 1) {
    return (
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={regular('building')} />}
        label='My Office'
        linkTo={`/offices/${offices[0].id!}`}
        collapsed={isCollapsed}
      />
    );
  }

  return (
    <ZenSideBarItem
      icon={<FontAwesomeIcon icon={regular('building')} />}
      label='Offices'
      linkTo='/offices'
      collapsed={isCollapsed}
    />
  );
};

export default UserOfficesSidebarMenuItem;
