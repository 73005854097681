import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../../routes/AgentWebsiteOnboardingRoute';
import { displayStringOrNA } from '../../../utils/StringUtils';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ReviewRow from '../../ReviewRow';
import StepContentInput from '../StepContentInput';
import EditOutlineActionButton from '../EditOutlineActionButton';
import { setDefaultAccordionOpenType } from '../../../slices/AgentWebsiteOnboardingSlice';
import { AgentWebsiteOnboardingSiteInfoAccordionName } from '../../../types';

const AgentWebsiteOnboardingFeaturedContentReview: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ form: { watch }, navigateTo }) => {
  const dispatch = useDispatch();

  const [
    feature1Title,
    feature2Title,
    feature3Title,
    feature1Content,
    feature2Content,
    feature3Content,
  ] = watch([
    'feature1Title',
    'feature2Title',
    'feature3Title',
    'feature1Content',
    'feature2Content',
    'feature3Content',
  ]);

  return (
    <div className='p-4'>
      <div className='grid lg:grid-cols-3 grid-cols-1 gap-5'>
        <div className='flex flex-col space-y-5'>
          <StepContentInput step={7}>
            <ReviewRow
              label='Feature 1 Title:'
              value={displayStringOrNA(feature1Title)}
            />
          </StepContentInput>
          <StepContentInput step={8}>
            <ReviewRow
              label='Feature 1 Content:'
              value={displayStringOrNA(feature1Content)}
            />
          </StepContentInput>
        </div>
        <div className='flex flex-col space-y-5'>
          <StepContentInput step={9}>
            <ReviewRow
              label='Feature 2 Title:'
              value={displayStringOrNA(feature2Title)}
            />
          </StepContentInput>
          <StepContentInput step={10}>
            <ReviewRow
              label='Feature 2 Content:'
              value={displayStringOrNA(feature2Content)}
            />
          </StepContentInput>
        </div>
        <div className='flex flex-col space-y-5'>
          <StepContentInput step={11}>
            <ReviewRow
              label='Feature 3 Title:'
              value={displayStringOrNA(feature3Title)}
            />
          </StepContentInput>
          <StepContentInput step={12}>
            <ReviewRow
              label='Feature 3 Content:'
              value={displayStringOrNA(feature3Content)}
            />
          </StepContentInput>
        </div>
      </div>
      <div className='mt-5 mb-2'>
        <EditOutlineActionButton
          text='Edit featured content'
          icon={<FontAwesomeIcon icon={faPen} className='mt-1 mr-1' />}
          onClick={() => {
            dispatch(
              setDefaultAccordionOpenType(
                AgentWebsiteOnboardingSiteInfoAccordionName.FEATURED_CONTENT,
              ),
            );
            navigateTo(AgentWebsiteOnboardingStepName.SITE_INFO);
          }}
        />
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingFeaturedContentReview;
