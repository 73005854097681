import { useEffect, useRef, useCallback } from 'react';

interface IframeProps {
  onClick: (iframe: HTMLIFrameElement) => void;
}

export const useIFrameInferredClick = (props: IframeProps) => {
  const iframeRef = useRef<null | HTMLIFrameElement>(null);

  const iframeCallbackRef = useCallback(
    (node: null | HTMLIFrameElement): void => {
      iframeRef.current = node;
    },
    [],
  );

  useEffect(() => {
    const onBlur = () => {
      if (
        document.activeElement &&
        document.activeElement.nodeName.toLowerCase() === 'iframe' &&
        iframeRef.current &&
        iframeRef.current === document.activeElement
      ) {
        props.onClick(iframeRef.current);
      }
    };

    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return { iframeRef: iframeCallbackRef };
};
