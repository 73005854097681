import classNames from 'classnames';
import { useDemoMode } from '../hooks/useDemoMode';

interface DemoModeContainerProps {
  hide?: boolean;
}

export const DemoModeContainer: React.FC<DemoModeContainerProps> = ({
  children,
  hide,
}) => {
  const enabled = useDemoMode();

  if (enabled && hide) {
    return null;
  }

  return (
    <div
      className={classNames({
        'blur-md': enabled,
      })}
    >
      {children}
    </div>
  );
};
