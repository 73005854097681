import React, { useEffect, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { useForm } from 'react-hook-form-v7';
import { values } from 'lodash';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenControlledRadioInput from '../../Zen/Input/ZenControlledRadioInput';
import { cn } from '../../../utils/classUtils';
import { BrokerQueueFilterEnum } from './BrokerQueueMenu';

interface FormData {
  filter?: BrokerQueueFilterEnum;
}

interface BrokerQueueTransactionFilterOptionsProps {
  filter: BrokerQueueFilterEnum;
  onFilterChange(filter: BrokerQueueFilterEnum): void;
}

const BrokerQueueTransactionFilterOptions: React.FC<BrokerQueueTransactionFilterOptionsProps> = ({
  filter,
  onFilterChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { control, watch } = useForm<FormData>({ defaultValues: { filter } });

  const filterType = watch('filter');

  useEffect(() => {
    onFilterChange(filterType!);
    setIsOpen(false);
  }, [filterType]);

  return (
    <Popover
      isOpen={isOpen}
      align='end'
      positions={['bottom']}
      containerClassName='z-50'
      onClickOutside={() => setIsOpen(false)}
      content={() => (
        <div className='bg-primary-light py-3 px-4 border border-grey-200 rounded shadow-sm'>
          <ZenControlledRadioInput<FormData, 'filter'>
            name='filter'
            control={control}
            options={values(BrokerQueueFilterEnum).map((option) => ({
              label: capitalizeEnum(option),
              value: option,
            }))}
            shouldUnregister={false}
          />
        </div>
      )}
    >
      <div
        className={cn(
          'flex p-1 h-8 w-8 items-center justify-center rounded-full cursor-pointer hover:bg-rezen-light-blue-100',
          { 'bg-rezen-light-blue-100': isOpen },
        )}
        onClick={() => setIsOpen(!isOpen)}
        aria-label='filter-message'
      >
        <FontAwesomeIcon
          icon={faBarsFilter}
          size='sm'
          className='text-primary-dark'
        />
      </div>
    </Popover>
  );
};

export default BrokerQueueTransactionFilterOptions;
