import React from 'react';
import { useSelector } from 'react-redux';
import { RezenObjectTypeEnum } from '../../openapi/yada';
import { RootState } from '../../types';
import { getFullName } from '../../utils/AgentHelper';
import { getBorrower } from '../../utils/MortgageUtils';

import ZenChecklistContainer from '../Zen/Checklist/ZenChecklistContainer';
import ZenRoute from '../Zen/ZenRoute';

interface MortgageChecklistRouteProps {
  borrowerId: string;
}

const MortgageChecklistRoute: React.FC<MortgageChecklistRouteProps> = ({
  borrowerId,
}) => {
  const {
    loanResponse: { data: loan },
  } = useSelector((state: RootState) => state.mortgage);
  const borrower = getBorrower(borrowerId, loan?.borrowers || []);

  return (
    <ZenRoute title={`Mortgage Checklist - ${getFullName(borrower)}`}>
      <ZenChecklistContainer
        checklistId={borrower?.checklistId!}
        containerId={borrower?.id!}
        containerType={RezenObjectTypeEnum.Borrower}
        dropboxId={borrower?.dropboxId!}
        assigneeList={[
          { label: 'Unassigned', value: (null as unknown) as string },
          {
            label: `${borrower?.firstName} ${borrower?.lastName}`,
            value: `${borrower?.id}`,
          },
        ]}
      />
    </ZenRoute>
  );
};

export default MortgageChecklistRoute;
