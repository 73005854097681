import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppThunk,
  BreakRoomEnum,
  KBarActionEnum,
  kBarContextSearchResults,
  KBarState,
  KBarThemeEnum,
} from '../types';

export const initialState: KBarState = {
  theme: KBarThemeEnum.CLASSIC,
  cardInFocus: undefined,
  sidebarToOpen: undefined,
  isContextSearching: false,
  contextSearchResults: {
    transactions: [],
    listings: [],
  },
  breakRoomType: undefined,
};

const KBarSlice = createSlice({
  name: 'kBarSlice',
  initialState,
  reducers: {
    saveTheme(state, action: PayloadAction<KBarThemeEnum>) {
      state.theme = action.payload;
    },
    saveCardInFocus(state, action: PayloadAction<KBarActionEnum | undefined>) {
      state.cardInFocus = action.payload;
    },
    saveSidebarToOpen(
      state,
      action: PayloadAction<KBarActionEnum | undefined>,
    ) {
      state.sidebarToOpen = action.payload;
    },
    saveIsContextSearching(state, action: PayloadAction<boolean>) {
      state.isContextSearching = action.payload;
    },
    saveContextSearchResults(
      state,
      action: PayloadAction<kBarContextSearchResults>,
    ) {
      state.contextSearchResults = action.payload;
    },
    saveBreakRoomType(state, action: PayloadAction<BreakRoomEnum | undefined>) {
      state.breakRoomType = action.payload;
    },
  },
});

export const {
  saveTheme,
  saveCardInFocus,
  saveSidebarToOpen,
  saveIsContextSearching,
  saveContextSearchResults,
  saveBreakRoomType,
} = KBarSlice.actions;

export const openSidebar = (action: KBarActionEnum): AppThunk => async (
  dispatch,
) => {
  dispatch(saveSidebarToOpen(action));
};

export default KBarSlice.reducer;
