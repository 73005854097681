import {
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import GeminiResourceTableWithNestedRouterTabs, {
  CustomColumn,
} from '../components/Gemini/Containers/GeminiResourceTableWithNestedRouterTabs';
import ListLabelWithValue from '../components/Gemini/ListLabelWithValue';
import {
  agentTransactionOrListingListColumns,
  agentTransactionOrListingListDraftColumns,
} from '../components/Gemini/Transaction/TransactionOrListingListUtils';
import Hover from '../components/Hover';
import { TabsConfig } from '../components/NestedTabsWithRouter';
import TransactionsOrListingsRouteHeader from '../components/transactions/TransactionsOrListingsRouteHeader';
import { TableQueryParamState } from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenSimpleConfirmationModal from '../components/Zen/Modal/ZenSimpleConfirmationModal';
import useQueryParams from '../hooks/useQueryParams';
import {
  TransactionBuilderControllerApi,
  TransactionBuilderResponse,
  TransactionLiteResponse,
} from '../openapi/arrakis';
import { useFetchChecklistProgressByIds } from '../query/transaction/useChecklist';
import {
  useFetchAgentDraftTransactions,
  useFetchAgentNonDraftTransactions,
  useFetchAgentTransactionsCount,
} from '../query/transaction/useTransaction';
import { useFetchAgentsInfoByIds } from '../query/transaction/useUser';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { saveTransactionPaginatedResultsByIdWithFilters } from '../slices/TransactionSlice';
import {
  RootState,
  TransactionsMainTabType,
  TransactionsSubTabType,
} from '../types';
import { isCanadianUser } from '../utils/AgentHelper';
import { displayDate } from '../utils/DateUtils';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import {
  convertQueryFilters,
  getDraftTransactionListByAgentIdParams,
  getPaginatedResults,
  getTransactionListByAgentIdParams,
  transactionLifecycleGroupEnumMap,
  transactionTypesByPathnameEnumMap,
} from '../utils/TransactionUtils';

interface GeminiAgentTransactionOrListingTableProps {
  type: TransactionsMainTabType;
  lifecycle: TransactionsSubTabType;
  isListing?: boolean;
}

const GeminiAgentTransactionOrListingTable: React.FC<GeminiAgentTransactionOrListingTableProps> = ({
  lifecycle,
  type,
  isListing = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQueryParams<TableQueryParamState<any>>();
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const agentId = userDetail?.id!;
  const country = userDetail?.accountCountry!;
  const isDraftTab = lifecycle === 'draft';

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [transactionBuilderId, setTransactionBuilderId] = useState<string>();

  /**
   * Fetching non-draft transactions
   */
  const {
    isLoading: isTransactionLoading,
    data: transactionsData,
    error: transactionError,
  } = useFetchAgentNonDraftTransactions({
    isListing,
    fnArgs: getTransactionListByAgentIdParams(agentId, lifecycle, type, query),
    options: { enabled: !!lifecycle && !!type && !isDraftTab },
  });

  const {
    isLoading: isCountLoading,
    data: transactionsCountByLifecycle,
  } = useFetchAgentTransactionsCount({
    isListing,
    fnArgs: getTransactionListByAgentIdParams(agentId, lifecycle, type, query),
    options: { enabled: !!lifecycle && !!type },
  });

  /**
   * Fetching draft transactions
   */
  const {
    isLoading: isDraftLoading,
    data: draftsData,
    refetch: refetchDrafts,
    error: draftError,
  } = useFetchAgentDraftTransactions({
    fnArgs: getDraftTransactionListByAgentIdParams(
      agentId,
      isListing ? 'LISTING' : 'TRANSACTION',
      type,
      query,
    ),
    options: { enabled: !!lifecycle && !!type },
  });

  /**
   * Memoized all checklist ids from both non-draft and draft transactions
   *
   * @returns Array of checklist ids (both non-draft and draft)
   */
  const allChecklistIds = useMemo(() => {
    const nonDraftIds = (transactionsData?.transactions || []).flatMap(
      ({ checklistId, journeyId }) =>
        [checklistId, journeyId].filter(Boolean) as string[],
    );
    const draftIds = (draftsData?.results || []).flatMap(
      ({ checklistId, journeyId }) =>
        [checklistId, journeyId].filter(Boolean) as string[],
    );
    return [...nonDraftIds, ...draftIds];
  }, [transactionsData?.transactions, draftsData?.results]);

  /**
   * Memoized all agent ids from both non-draft and draft transactions
   *
   * @returns Array of agent ids without duplicates (both non-draft and draft)
   */
  const allAgentIds = useMemo(() => {
    const nonDraftAgentIds = (transactionsData?.transactions || [])
      .flatMap((transaction) => transaction.participantYentaIds || [])
      .filter(Boolean);
    const draftAgentIds = (draftsData?.results || [])
      .flatMap((builder) => builder.allCommissionRecipient || [])
      .filter((agent) => !!agent && (agent as any)?.agentId)
      .map((agent) => (agent as any)?.agentId!);
    return Array.from(new Set([...nonDraftAgentIds, ...draftAgentIds]));
  }, [transactionsData?.transactions, draftsData?.results]);

  /**
   * Fetching checklist progress for all checklist ids
   *
   * @returns - Array of checklist progress objects and saving in the store via saveChecklistProgressById
   */
  useFetchChecklistProgressByIds(allChecklistIds, !!allChecklistIds.length);

  /**
   * Fetching agent info for all agent ids
   *
   * @returns - Array of agent info objects and saving in the store via saveAgentById
   */
  useFetchAgentsInfoByIds(allAgentIds);

  /**
   * Navigate to transaction detail page
   *
   * @param path - Path to navigate
   * We are saving the results for showing pagination in detail page.
   */
  const handleNavigate = useCallback(
    (path: string) => {
      history.push(path);
      if (!isListing) {
        dispatch(
          saveTransactionPaginatedResultsByIdWithFilters({
            hasNext: transactionsData?.hasNext,
            totalCount: transactionsData?.totalCount,
            results: getPaginatedResults(
              transactionsData?.pageNumber ?? 0,
              transactionsData?.pageSize ?? 10,
              transactionsData?.transactions || [],
            ),
            filters: convertQueryFilters(query, 10),
            agentId,
            lifecycleGroup: transactionLifecycleGroupEnumMap[lifecycle],
            transactionTypes: transactionTypesByPathnameEnumMap[type]!,
          }),
        );
      }
    },
    [
      agentId,
      dispatch,
      history,
      isListing,
      lifecycle,
      query,
      transactionsData,
      type,
    ],
  );

  /**
   * Memoized columns for non-draft transactions with firm date for Canadian users
   */
  const modifiedColumns: Array<
    CustomColumn<TransactionLiteResponse>
  > = useMemo(() => {
    const columns: Array<CustomColumn<TransactionLiteResponse>> = [
      ...agentTransactionOrListingListColumns(isListing, handleNavigate),
    ];

    if (isCanadianUser(country) && !isListing) {
      columns.splice(12, 0, {
        accessor: 'firmDate',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='Firm Date'
            value={value ? displayDate(value) : 'N/A'}
          />
        ),
        position: 'collapsible',
        disableFilters: true,
        disableSortBy: true,
      });
    }

    return columns;
  }, [country, handleNavigate, isListing]);

  /**
   * Memoized columns for draft transactions with actions
   */
  const draftColumnsWithActions: Array<
    CustomColumn<TransactionBuilderResponse>
  > = useMemo(() => {
    return [
      {
        Header: 'Transaction Code',
        accessor: 'id',
        Cell: ({ row: { original } }) => (
          <div>
            {!!original.id ? (
              <div className='flex items-center space-x-1'>
                <Link
                  to={`/${isListing ? 'listing' : 'transaction'}/create/${
                    original.id
                  }`}
                >
                  <div className='text-sm flex flex-col'>
                    <p className='whitespace-nowrap'>XXX-XXX-XXX-XXXX</p>
                    <p className='text-error'>[Draft]</p>
                  </div>
                </Link>
                <Hover
                  hoverComponent={
                    <div className='text-dark'>Discard Draft</div>
                  }
                  config={{ trigger: 'hover', placement: 'top' }}
                >
                  <button
                    className='cursor-pointer'
                    onClick={() => setTransactionBuilderId(original.id)}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className='text-error hover:bg-red-100 rounded-full p-0.5'
                    />
                  </button>
                </Hover>
              </div>
            ) : (
              'N/A'
            )}
          </div>
        ),
        cardColSize: 7,
        disableSortBy: true,
        position: 'top-left',
      },
      ...agentTransactionOrListingListDraftColumns(isListing),
    ];
  }, [isListing]);

  const handleDeleteDraft = async () => {
    setIsSubmitting(true);
    try {
      await new TransactionBuilderControllerApi(
        getArrakisConfiguration(),
      ).deleteTransactionBuilder(transactionBuilderId!);
      dispatch(
        showSuccessToast(
          `Removed draft ${
            isListing ? 'listing' : 'transaction'
          } successfully.`,
        ),
      );
      refetchDrafts();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify(
        `Unable to remove the draft ${isListing ? 'listing' : 'transaction'}`,
        e,
        { transactionBuilder: { transactionBuilderId } },
      );
      dispatch(
        showErrorToast(
          `We had a problem removing the draft ${
            isListing ? 'listing' : 'transaction'
          }`,
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setIsSubmitting(false);
      setTransactionBuilderId(undefined);
    }
  };

  const tabsConfig: TabsConfig<any> = useMemo(() => {
    const baseTabs = [
      { key: 'all', title: 'All' },
      { key: 'sale', title: 'Sale' },
      { key: 'lease', title: 'Lease' },
      { key: 'referrals', title: 'Referrals' },
    ].filter((tab) => !isListing || tab.key !== 'referrals');

    return {
      MAIN_TABS: {
        queryKey: 'type',
        tabs: baseTabs,
      },
      SUB_TABS: {
        queryKey: 'lifecycle',
        tabs: [
          {
            key: 'active',
            title: `Active (${transactionsCountByLifecycle?.OPEN ?? 0})`,
          },
          {
            key: 'closed',
            title: `Closed (${transactionsCountByLifecycle?.CLOSED ?? 0})`,
          },
          {
            key: 'terminated',
            title: `Terminated (${
              transactionsCountByLifecycle?.TERMINATED ?? 0
            })`,
          },
          { key: 'draft', title: `Draft (${draftsData?.totalCount ?? 0})` },
        ],
      },
    };
  }, [
    draftsData?.totalCount,
    isListing,
    transactionsCountByLifecycle?.CLOSED,
    transactionsCountByLifecycle?.OPEN,
    transactionsCountByLifecycle?.TERMINATED,
  ]);

  return (
    <>
      <GeminiResourceTableWithNestedRouterTabs<any>
        routerTabs={{
          basePath: isListing ? '/listings' : '/transactions',
          tabsConfig,
          clearQueryParamsOnTabChange: isDraftTab,
        }}
        search={query?.search}
        columns={isDraftTab ? draftColumnsWithActions : modifiedColumns}
        header={isListing ? 'Listings' : 'Transactions'}
        RightComponent={
          <TransactionsOrListingsRouteHeader isListing={isListing} />
        }
        data={
          isDraftTab
            ? draftsData?.results || []
            : transactionsData?.transactions || []
        }
        initialSort={
          isListing
            ? { listingExpirationDate: 'asc' }
            : { skySlopeEscrowClosingDate: 'asc' }
        }
        isLoading={
          (isDraftTab ? isDraftLoading : isTransactionLoading) || isCountLoading
        }
        resourceName={isListing ? 'listing' : 'transaction'}
        total={
          isDraftTab
            ? draftsData?.totalCount ?? 0
            : transactionsData?.totalCount ?? 0
        }
        pageSize={10}
        resourceContainerDisplayVariant='CARDS'
        showSortOptions={!isDraftTab}
        error={isDraftTab ? draftError : transactionError}
        isDisplayVariantCardExpandable={!isListing}
        showExpandAllButton={!isListing}
        showTotalCountInPagination
        hideFilters
      />
      <ZenSimpleConfirmationModal
        isOpen={!!transactionBuilderId}
        variant='danger'
        title={`Discard Draft ${isListing ? 'Listing' : 'Transaction'}?`}
        subtitle={`This draft ${
          isListing ? 'listing' : 'transaction'
        } will be permanently deleted. Are you sure?`}
        customIcon={<FontAwesomeIcon icon={faTriangleExclamation} />}
        onClose={() => setTransactionBuilderId(undefined)}
        onConfirm={handleDeleteDraft}
        isSubmitting={isSubmitting}
        confirmButtonText='Yes'
      />
    </>
  );
};

export default GeminiAgentTransactionOrListingTable;
