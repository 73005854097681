import {
  AddressStateEnum,
  TransactionBuilderAddressStateEnum,
} from '../openapi/arrakis';
import ArizonaImg from '../assets/img/zen/real-title/states/arizona.png';
import CaliforniaImg from '../assets/img/zen/real-title/states/california.png';
import FloridaImg from '../assets/img/zen/real-title/states/florida.png';
import GeorgiaImg from '../assets/img/zen/real-title/states/georgia.png';
import MichiganImg from '../assets/img/zen/real-title/states/michigan.png';
import MinnesotaImg from '../assets/img/zen/real-title/states/minnesota.png';
import NevadaImg from '../assets/img/zen/real-title/states/nevada.png';
import TennesseeImg from '../assets/img/zen/real-title/states/tennessee.png';
import TexasImg from '../assets/img/zen/real-title/states/texas.png';
import UtahImg from '../assets/img/zen/real-title/states/utah.png';
import WisconsinImg from '../assets/img/zen/real-title/states/wisconsin.png';
import SouthCarolinaImg from '../assets/img/zen/real-title/states/south-carolina.png';
import NorthCarolinaImg from '../assets/img/zen/real-title/states/north-carolina.png';
import MarylandImg from '../assets/img/zen/real-title/states/maryland.png';
import VirginiaImg from '../assets/img/zen/real-title/states/virginia.png';
import DistrictOfColumbiaImg from '../assets/img/zen/real-title/states/dc.png';
import IllinoisImg from '../assets/img/zen/real-title/states/illinois.png';

export enum RealTitleOperationStatesEnum {
  Arizona = 'ARIZONA',
  California = 'CALIFORNIA',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Nevada = 'NEVADA',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  Utah = 'UTAH',
  Wisconsin = 'WISCONSIN',
  NorthCarolina = 'NORTH_CAROLINA',
  SouthCarolina = 'SOUTH_CAROLINA',
  Maryland = 'MARYLAND',
  Virginia = 'VIRGINIA',
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
  Illinois = 'ILLINOIS',
}

export interface RealTitleContactInfo {
  oneLineAddress: string;
  phoneNumber: string;
  emailAddress: string;
  isEscrow?: boolean;
}

export const getRealTitleContactInfo = (
  state: RealTitleOperationStatesEnum,
): RealTitleContactInfo | undefined => {
  switch (state) {
    case RealTitleOperationStatesEnum.Texas:
      return {
        oneLineAddress: '305 W 1st Street, Suite 102, Justin, TX 76247',
        phoneNumber: '(430) 888-4283',
        emailAddress: 'texas@onerealtitle.com',
      };
    case RealTitleOperationStatesEnum.Florida:
      return {
        oneLineAddress:
          '350 Lincoln Rd 2nd Floor, Ste 351 Miami Beach, FL 33139',
        phoneNumber: '(786) 400-2539',
        emailAddress: 'florida@onerealtitle.com',
      };

    case RealTitleOperationStatesEnum.Michigan:
      return {
        oneLineAddress:
          '350 Lincoln Rd 2nd Floor, Ste 351 Miami Beach, FL 33139',
        phoneNumber: '(786) 400-2539',
        emailAddress: 'michigan@onerealtitle.com',
      };
    case RealTitleOperationStatesEnum.Minnesota:
      return {
        oneLineAddress:
          '350 Lincoln Rd 2nd Floor, Ste 351 Miami Beach, FL 33139',
        phoneNumber: '(786) 400-2539',
        emailAddress: 'minnesota@onerealtitle.com',
      };
    case RealTitleOperationStatesEnum.Tennessee:
      return {
        oneLineAddress:
          '350 Lincoln Rd 2nd Floor, Ste 351 Miami Beach, FL 33139',
        phoneNumber: '(786) 400-2539',
        emailAddress: 'tennessee@onerealtitle.com',
      };
    case RealTitleOperationStatesEnum.Georgia:
      return {
        oneLineAddress: '384 North Yards Blvd, Ste 190 Atlanta, GA 30313',
        phoneNumber: '(786) 400-2539',
        emailAddress: 'georgia@onerealtitle.com',
      };
    case RealTitleOperationStatesEnum.Utah:
      return {
        oneLineAddress:
          '13894 South Bangerter Parkway, Ste 200 Draper, UT 84020',
        phoneNumber: '(385) 386-5388',
        emailAddress: 'utah@onerealtitle.com',
      };
    case RealTitleOperationStatesEnum.Wisconsin:
      return {
        oneLineAddress:
          '350 Lincoln Rd 2nd Floor, Ste 351 Miami Beach, FL 33139',
        phoneNumber: '(786) 400-2539',
        emailAddress: 'wisconsin@onerealtitle.com',
      };
    case RealTitleOperationStatesEnum.Nevada:
      return {
        oneLineAddress:
          '6671 S. Las Vegas Blvd., Bldg. D, Suite 210 , Las Vegas NV 89119',
        phoneNumber: '(786) 400-2539',
        emailAddress: 'nevada@onerealtitle.com',
      };
    case RealTitleOperationStatesEnum.Arizona:
      return {
        oneLineAddress: 'One South Church Ave Tucson, AZ 85701',
        phoneNumber: '(786) 400-2539',
        emailAddress: 'arizona@onerealtitle.com',
      };
    case RealTitleOperationStatesEnum.California:
      return {
        oneLineAddress:
          '1230 Rosecrans Avenue, Ste 300 Manhattan Beach, CA 90266',
        phoneNumber: '(213) 816-8247',
        emailAddress: 'california@onerealescrow.com',
        isEscrow: true,
      };
    case RealTitleOperationStatesEnum.NorthCarolina:
      return {
        oneLineAddress:
          'Brady & Kosofsky, PA 1395 Ballantyne Corporate Place, Suite 400 Charlotte, NC 28277',
        phoneNumber: '(704) 815-8149',
        emailAddress: 'northcarolina@onerealtitle.com',
      };
    case RealTitleOperationStatesEnum.SouthCarolina:
      return {
        oneLineAddress:
          'Brady & Kosofsky, PA 1395 Ballantyne Corporate Place, Suite 400 Charlotte, NC 28277',
        phoneNumber: '(704) 815-8150',
        emailAddress: 'southcarolina@onerealtitle.com',
      };
    case RealTitleOperationStatesEnum.Maryland:
      return {
        oneLineAddress: '350 Lincoln Rd 2nd Floor, Ste 351',
        phoneNumber: '(786) 400-2539',
        emailAddress: 'maryland@onerealtitle.com',
      };
    case RealTitleOperationStatesEnum.Virginia:
      return {
        oneLineAddress: '350 Lincoln Rd 2nd Floor, Ste 351',
        phoneNumber: '(786) 400-2539',
        emailAddress: 'virginia@onerealtitle.com',
      };
    case RealTitleOperationStatesEnum.DistrictOfColumbia:
      return {
        oneLineAddress: '350 Lincoln Rd 2nd Floor, Ste 351',
        phoneNumber: '(786) 400-2539',
        emailAddress: 'DC@onerealtitle.com',
      };
    case RealTitleOperationStatesEnum.Illinois:
      return {
        oneLineAddress:
          '350 Lincoln Rd 2nd Floor, Ste 351, Miami Beach, FL 33139',
        phoneNumber: '(786) 400-2539',
        emailAddress: 'illinois@onerealtitle.com',
      };
    default:
      return undefined;
  }
};

export enum RealTitleFAQTypeEnum {
  FAQ = 'FAQ',
}

export interface RealTitleFAQQuestion {
  question: string;
  answer: string;
}

export const getRealTitleFAQs: Record<
  RealTitleFAQTypeEnum,
  RealTitleFAQQuestion[]
> = {
  [RealTitleFAQTypeEnum.FAQ]: [
    {
      question:
        'What states does One Real Title and One Real Escrow operate in?',
      answer: `We operate in Arizona, California, Colorado, Florida, Georgia, Michigan, Minnesota, Nevada, Tennessee, Texas, Utah, and Wisconsin. California is operated under Real Escrow of California.`,
    },
    {
      question: 'Do I have to use One Real Title if I am an agent with Real?',
      answer: `There is no requirement to use One Real Title and we are here to support your title and escrow needs regardless if you use us. Real Brokerage acquired us in January 2022, and as part of The Real Brokerage we work together to provide the best title and escrow services to our agents. When One Real Title grows, so does The Real Brokerage so we hope you will try our services and our goal is to continue to expand across the nation.`,
    },
    {
      question: 'How experienced are the title agents?',
      answer: `Our team of agents offer decades of industry experience and go through a competitive vetting process when applying. We assure you will be in the best hands when working with us.`,
    },
    {
      question: 'Who are your underwriters?',
      answer: `We have multiple underwriters including: Fidelity National Title, First National Title, First American.`,
    },
    {
      question: 'Are there documents I need to have my client sign?',
      answer: `Yes, we have to get an ABA (affiliated business disclosure) signed from your client(s). Your state broker should have a copy. If not, reach out to us and we will provide a copy as well.`,
    },
  ],
};

export const getTransactionRealTitleName = (
  state?: TransactionBuilderAddressStateEnum | AddressStateEnum,
): string => {
  if (state === TransactionBuilderAddressStateEnum.California) {
    return 'One Real Escrow';
  }
  return 'One Real Title';
};

export const getStateImageSrc = (
  state: RealTitleOperationStatesEnum,
): string => {
  switch (state) {
    case RealTitleOperationStatesEnum.Arizona:
      return ArizonaImg;
    case RealTitleOperationStatesEnum.California:
      return CaliforniaImg;
    case RealTitleOperationStatesEnum.Florida:
      return FloridaImg;
    case RealTitleOperationStatesEnum.Georgia:
      return GeorgiaImg;
    case RealTitleOperationStatesEnum.Michigan:
      return MichiganImg;
    case RealTitleOperationStatesEnum.Minnesota:
      return MinnesotaImg;
    case RealTitleOperationStatesEnum.Nevada:
      return NevadaImg;
    case RealTitleOperationStatesEnum.Tennessee:
      return TennesseeImg;
    case RealTitleOperationStatesEnum.Texas:
      return TexasImg;
    case RealTitleOperationStatesEnum.Utah:
      return UtahImg;
    case RealTitleOperationStatesEnum.Wisconsin:
      return WisconsinImg;
    case RealTitleOperationStatesEnum.NorthCarolina:
      return NorthCarolinaImg;
    case RealTitleOperationStatesEnum.SouthCarolina:
      return SouthCarolinaImg;
    case RealTitleOperationStatesEnum.Maryland:
      return MarylandImg;
    case RealTitleOperationStatesEnum.Virginia:
      return VirginiaImg;
    case RealTitleOperationStatesEnum.DistrictOfColumbia:
      return DistrictOfColumbiaImg;
    case RealTitleOperationStatesEnum.Illinois:
      return IllinoisImg;
  }
};

export const OneRealTitleServiceFeesByState: Record<
  RealTitleOperationStatesEnum,
  {
    name: string;
    oneRealTitleFees: string | { label: string; value: string }[];
    competitorFees: string;
  }[]
> = {
  ARIZONA: [
    {
      name: 'Residential Escrow fee',
      oneRealTitleFees: '$1,000',
      competitorFees: '$1,200',
    },
    {
      name: 'Commercial Escrow fee',
      oneRealTitleFees: '$1,000',
      competitorFees: '$1,200',
    },
    {
      name: 'Title Flex Report',
      oneRealTitleFees: '$100',
      competitorFees: '>$100',
    },
    {
      name: 'Short Sale fee',
      oneRealTitleFees: '$250',
      competitorFees: '$350',
    },
    {
      name: 'Document Preparation',
      oneRealTitleFees: '$150',
      competitorFees: '$200',
    },
  ],
  CALIFORNIA: [
    {
      name: 'Escrow Fees (based on sales price)',
      oneRealTitleFees: [
        { label: 'per thousand+', value: '$2' },
        { label: 'base fee', value: '$250' },
      ],
      competitorFees: 'Same as Buyer Fees',
    },
    {
      name: 'Document Preparation Fee',
      oneRealTitleFees: '$100',
      competitorFees: '$150',
    },
    {
      name: 'Solar Processing Fee',
      oneRealTitleFees: '$100',
      competitorFees: '$100',
    },
    {
      name: 'HOA Processing Fee',
      oneRealTitleFees: '$100',
      competitorFees: '$100',
    },
    {
      name: 'Loan Tie In Fee',
      oneRealTitleFees: '$750',
      competitorFees: '-',
    },
    {
      name: 'Wire Fee',
      oneRealTitleFees: '$45',
      competitorFees: '$45',
    },
    {
      name: 'Messenger Fee',
      oneRealTitleFees: '$150',
      competitorFees: '$150',
    },
    {
      name: 'Deed Preparation',
      oneRealTitleFees: '$75',
      competitorFees: '$75',
    },
    {
      name: '1031 Exchange Fee Processing',
      oneRealTitleFees: '$100',
      competitorFees: '$100',
    },
  ],
  FLORIDA: [
    {
      name: 'Residential Settlement fee',
      oneRealTitleFees: '$699',
      competitorFees: '$1,000+',
    },
    {
      name: 'Commercial Settlement fee',
      oneRealTitleFees: '$699',
      competitorFees: '$1,000+',
    },
    {
      name: 'Full Time Search (30 years)',
      oneRealTitleFees: '$200',
      competitorFees: '$250',
    },
    {
      name: 'Municipal Lien Search',
      oneRealTitleFees: 'Standard',
      competitorFees: 'Standard',
    },
    {
      name: 'O&E Search',
      oneRealTitleFees: '$200',
      competitorFees: '$250',
    },
    {
      name: 'Short Sale Processing',
      oneRealTitleFees: '1.5%',
      competitorFees: '2%',
    },
    {
      name: 'Preparing Seller Documents',
      oneRealTitleFees: '$950',
      competitorFees: '$1,495',
    },
  ],
  GEORGIA: [
    {
      name: 'Residential Settlement fee',
      oneRealTitleFees: '$499',
      competitorFees: '$699+',
    },
    {
      name: 'Commercial Settlement fee',
      oneRealTitleFees: '$499',
      competitorFees: '$699+',
    },
    {
      name: 'Full Time Search (30 years)',
      oneRealTitleFees: '$150',
      competitorFees: '$200',
    },
    {
      name: 'Municipal Lien Search',
      oneRealTitleFees: 'Standard',
      competitorFees: 'Standard',
    },
    {
      name: 'O&E Search',
      oneRealTitleFees: '$150',
      competitorFees: '$200',
    },
    {
      name: 'Short Sale Processing',
      oneRealTitleFees: '1.5%',
      competitorFees: '2%',
    },
    {
      name: 'Preparing Seller Documents',
      oneRealTitleFees: '$600',
      competitorFees: '$750',
    },
  ],
  MICHIGAN: [
    {
      name: 'Residential Settlement fee',
      oneRealTitleFees: '$350',
      competitorFees: '$400+',
    },
    {
      name: 'Commercial Settlement fee',
      oneRealTitleFees: '$350',
      competitorFees: '$400',
    },
  ],
  MINNESOTA: [
    {
      name: 'Residential Settlement fee',
      oneRealTitleFees: '$350',
      competitorFees: '$400+',
    },
    {
      name: 'Commercial Settlement fee',
      oneRealTitleFees: '$350',
      competitorFees: '$400',
    },
    {
      name: 'Title Search Fee',
      oneRealTitleFees: '$385',
      competitorFees: '$450',
    },
    {
      name: 'Conservation Fee',
      oneRealTitleFees: '$10',
      competitorFees: '$15',
    },
  ],
  NEVADA: [
    {
      name: 'Residential Settlement fee',
      oneRealTitleFees: 'Minimum $900',
      competitorFees: '$1,100+',
    },
    {
      name: 'Commercial Settlement fee',
      oneRealTitleFees: 'Minimum $900',
      competitorFees: '$1,100+',
    },
    {
      name: 'Title Search Fee',
      oneRealTitleFees: '$200',
      competitorFees: '$250',
    },
    {
      name: 'Approved Short Sale',
      oneRealTitleFees: '$650',
      competitorFees: '$750',
    },
    {
      name: 'Loan Tie In Fee',
      oneRealTitleFees: '$150',
      competitorFees: '$200',
    },
  ],
  TENNESSEE: [
    {
      name: 'Residential Settlement fee',
      oneRealTitleFees: '$450',
      competitorFees: '$500+',
    },
    {
      name: 'Commercial Settlement fee',
      oneRealTitleFees: '$450',
      competitorFees: '$500+',
    },
    {
      name: 'Full Time Search',
      oneRealTitleFees: '$285',
      competitorFees: '$300',
    },
    {
      name: 'Lien Release',
      oneRealTitleFees: '$20',
      competitorFees: '$30',
    },
    {
      name: 'Document Preparation',
      oneRealTitleFees: '$50',
      competitorFees: '$75',
    },
  ],
  TEXAS: [
    {
      name: 'Residential Settlement fee',
      oneRealTitleFees: '$575',
      competitorFees: '$699+',
    },
    {
      name: 'Commercial Settlement fee',
      oneRealTitleFees: '$575',
      competitorFees: '$699+',
    },
    {
      name: 'Municipal Lien Search',
      oneRealTitleFees: '$65',
      competitorFees: 'Standard',
    },
    {
      name: 'O&E Search',
      oneRealTitleFees: '$200',
      competitorFees: '$300',
    },
    {
      name: 'Short Sale Processing',
      oneRealTitleFees: '1.5%',
      competitorFees: '2%',
    },
    {
      name: 'Tax Certificate',
      oneRealTitleFees: '$65',
      competitorFees: '$99',
    },
    {
      name: 'Attorney Document Preparation',
      oneRealTitleFees: '$150',
      competitorFees: '$200',
    },
    {
      name: 'Preparing Seller Documents',
      oneRealTitleFees: '$150',
      competitorFees: '$750',
    },
  ],
  UTAH: [
    {
      name: 'Residential Settlement fee',
      oneRealTitleFees: '$395',
      competitorFees: '$495+',
    },
    {
      name: 'Commercial Settlement fee',
      oneRealTitleFees: '$395',
      competitorFees: '$495+',
    },
    {
      name: 'Full Time Search (30 years)',
      oneRealTitleFees: '$200',
      competitorFees: '$300',
    },
    {
      name: 'Recon Tracking Fee (per payoff, if applicable)',
      oneRealTitleFees: '$75',
      competitorFees: '$85',
    },
    {
      name: 'Tax Certificate',
      oneRealTitleFees: '$65',
      competitorFees: '$7 5',
    },
    {
      name: 'Notary Fee',
      oneRealTitleFees: '$150',
      competitorFees: '$250',
    },
  ],
  WISCONSIN: [
    {
      name: 'Residential Settlement fee',
      oneRealTitleFees: '$350',
      competitorFees: '$400+',
    },
    {
      name: 'Commercial Settlement fee',
      oneRealTitleFees: '$350',
      competitorFees: '$400',
    },
  ],
  NORTH_CAROLINA: [],
  SOUTH_CAROLINA: [],
  MARYLAND: [],
  VIRGINIA: [],
  DISTRICT_OF_COLUMBIA: [],
  ILLINOIS: [],
};
