import {
  faCalendar,
  faHandHoldingDollar,
  faMoneyBill,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import {
  AgentResponseAccountCountryEnum,
  TeamResponse,
} from '../../../openapi/yenta';
import { RootState, YesNoType } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { isTeamTypeEligibleForInstantPayments } from '../../../utils/TeamHelper';
import { getInstantCommissionName } from '../../../utils/TransactionHelper';
import AdminOnly from '../../auth/AdminOnly';
import GeminiYesOrNoPill from '../GeminiYesOrNoPill';
import GeminiTransactionCodeWithCopy from '../Transaction/Header/GeminiTransactionCodeWithCopy';
import GeminiTransactionHeaderRowCell from '../Transaction/Header/GeminiTransactionHeaderRowCell';
import CommissionDocumentApproverPill from './CommissionDocumentApproverPill';
import GeminiTeamsType from './GeminiTeamsType';

interface GeminiTeamDetailHeaderSectionProps {
  teamDetailOverview: TeamResponse;
  country: AgentResponseAccountCountryEnum;
  totalMemberCount?: number;
}

const Divider = () => <div className='w-[1px] bg-grey-200 h-[18px]' />;

const GeminiTeamDetailHeaderSection: React.FC<GeminiTeamDetailHeaderSectionProps> = ({
  teamDetailOverview,
  country,
  totalMemberCount,
}) => {
  const { isAdmin, isBroker } = useSelector((state: RootState) => state.auth);

  const isAgent = !(isAdmin || isBroker);

  return (
    <header
      className={cn(
        'md:p-6 p-2 flex md:flex-row flex-col bg-primary-navy text-white rounded-[15px] md:mx-4 mx-2 my-1',
        isAgent && 'items-center',
      )}
    >
      <div className='md:w-11/12 w-full'>
        <div className='flex md:flex-row flex-col items-center space-x-3'>
          <span className='font-zen-body-2 text-xl leading-7 font-bold'>
            {teamDetailOverview.name}
          </span>
          <AdminOnly>
            <GeminiTransactionCodeWithCopy
              transactionCode={teamDetailOverview.id}
            />
          </AdminOnly>
          <Divider />
          <GeminiTransactionHeaderRowCell
            leftComponentIcon={faUser}
            label={`${totalMemberCount} ${pluralize(
              'Member',
              totalMemberCount,
            )}`}
          />
          <Divider />
          <GeminiTransactionHeaderRowCell
            leftComponentIcon={faCalendar}
            label='Created On:'
            value={DateTime.fromMillis(teamDetailOverview.createdAt!).toFormat(
              'MMMM dd, yyyy',
            )}
          />
        </div>
        <AdminOnly>
          <div className='mt-10'>
            <div className='flex md:flex-row flex-col items-center md:space-x-3'>
              <GeminiTransactionHeaderRowCell
                leftComponentIcon={faMoneyBill}
                label={`Eligible for ${getInstantCommissionName(country)}?`}
                rightComponent={
                  <GeminiYesOrNoPill
                    value={
                      teamDetailOverview.paidAtClosing
                        ? YesNoType.YES
                        : YesNoType.NO
                    }
                  />
                }
              />
              <Divider />
              <GeminiTransactionHeaderRowCell
                leftComponentIcon={faUser}
                label='Commission Document Approver'
                rightComponent={
                  <CommissionDocumentApproverPill
                    type={teamDetailOverview.commissionDocumentApprover}
                  />
                }
              />
            </div>
          </div>
          {isTeamTypeEligibleForInstantPayments(teamDetailOverview.type!) && (
            <div className='mt-3.5'>
              <GeminiTransactionHeaderRowCell
                leftComponentIcon={faHandHoldingDollar}
                label='Eligible For Instant Payments?'
                rightComponent={
                  <GeminiYesOrNoPill
                    value={
                      teamDetailOverview.instantPaymentEligible
                        ? YesNoType.YES
                        : YesNoType.NO
                    }
                  />
                }
              />
            </div>
          )}
        </AdminOnly>
      </div>
      <div className='md:w-1/12 w-full flex md:justify-end'>
        <GeminiTeamsType type={teamDetailOverview.type!} />
      </div>
    </header>
  );
};

export default GeminiTeamDetailHeaderSection;
