import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BankingApi } from '../../openapi/atlantis';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import { AppDispatch } from '../../types';
import { getAtlantisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ZenButton from '../Zen/ZenButton';

interface MortgageViewReportCellProps {
  borrowerId: string;
  reportId: string;
}

const MortgageViewReportCell: React.FC<MortgageViewReportCellProps> = ({
  borrowerId,
  reportId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleGetBankReport = async () => {
    setIsLoading(true);
    try {
      const { data } = await new BankingApi(
        getAtlantisConfiguration(),
      ).getBankReport(borrowerId, reportId);

      if (data?.signedPdfReportLocation) {
        window.open(data?.signedPdfReportLocation, '_blank');
      }
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notifyIgnoreHandled('Unable to open report', e, {
        data: {
          borrowerId,
          reportId,
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ZenButton
      variant='primary-outline'
      label='View Report'
      isSubmitting={isLoading}
      isDisabled={isLoading}
      onClick={handleGetBankReport}
    />
  );
};

export default MortgageViewReportCell;
