import {
  faAngleDown,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Option } from 'react-select/src/filters';
import { DEFAULT_PAGE_NUM } from '../../../constants/AsyncSelectPaginationConstants';
import {
  AsyncSelectAdditional,
  AsyncSelectOption,
  AsyncSelectOptionReactElement,
} from '../../../types';
import IconButton from '../../IconButton';
import { cn } from '../../../utils/classUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';
import { ASYNC_MULTI_SELECT_DEBOUNCE } from './constants';

interface ZenControlledAsyncCustomSelectInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  noBorder?: boolean;
  readOnly?: boolean;
  fetchData(
    search: string,
    page?: number,
  ): Promise<Array<AsyncSelectOption | AsyncSelectOptionReactElement>>;
  onClick?(): void;
  filterOption?:
    | ((option: Option, rawInput: string) => boolean)
    | null
    | undefined;
  showAddButton?: boolean;
  hideErrorMessage?: boolean;
  isRequired?: boolean;
}

const ZenControlledAsyncCustomSelectInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  placeholder = 'Select',
  disabled = false,
  readOnly,
  noBorder,
  fetchData,
  shouldUnregister = true,
  onClick,
  filterOption,
  hideErrorMessage = false,
  showAddButton = false,
  isRequired = false,
  ...rest
}: ZenControlledAsyncCustomSelectInputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);
  return (
    <div className='space-y-1 w-full'>
      <Controller
        shouldUnregister={shouldUnregister}
        {...rest}
        render={({
          field: { onChange, value, name, onBlur },
          fieldState: { error, invalid },
        }) => (
          <div className='space-y-1 w-full'>
            {label && (
              <label className='inline-block' htmlFor={name}>
                <span
                  className={cn(
                    'font-zen-body font-semibold text-base',
                    invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                  )}
                >
                  {label}
                </span>
                {isRequired && <span className='text-zen-danger'>*</span>}
              </label>
            )}
            <div
              className={cn(
                'flex flex-row flex-grow items-center border rounded divide-x',
                focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
                value && 'text-zen-dark-9',
                invalid && '!border-zen-danger',
                {
                  'border-none': noBorder,
                  'bg-gray-50': disabled || readOnly,
                },
              )}
            >
              <div className='flex flex-row items-center flex-grow'>
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className='text-primary-blue ml-2 px-2'
                />
                <div className='w-full'>
                  <AsyncPaginate
                    placeholder={placeholder}
                    value={value}
                    name={name}
                    isDisabled={disabled || readOnly}
                    defaultValue={value}
                    // @ts-ignore
                    loadOptions={async (
                      search,
                      _additionalOptions,
                      { page }: AsyncSelectAdditional,
                    ) => {
                      const data = await fetchData(search, page);
                      return {
                        options: data,
                        hasMore: !!data.length,
                        additional: {
                          page: page + 1,
                        },
                      };
                    }}
                    additional={{
                      page: DEFAULT_PAGE_NUM,
                    }}
                    debounceTimeout={ASYNC_MULTI_SELECT_DEBOUNCE}
                    onChange={(v) => {
                      onChange(v);
                      if (!showAddButton && onClick) {
                        onClick();
                      }
                    }}
                    onFocus={() => setFocus(true)}
                    onBlur={() => {
                      setFocus(false);
                      onBlur();
                    }}
                    className='react-select-container font-zen-body font-normal'
                    classNamePrefix='react-select-noborder'
                    inputId={name}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    filterOption={filterOption}
                  />
                </div>
                <div
                  className={cn(
                    'h-10 flex flex-row items-center border-l px-5',
                    focus ? 'border-l-zen-dark-9' : 'border-l-zen-dark-5',
                  )}
                >
                  <FontAwesomeIcon icon={faAngleDown} />
                </div>
              </div>
              {showAddButton && (
                <div>
                  <IconButton label='Add' variant='none' onClick={onClick} />
                </div>
              )}
            </div>
            {!!error && !hideErrorMessage && (
              <ZenFormErrorMessage message={error.message} />
            )}
          </div>
        )}
      />
    </div>
  );
};
export default ZenControlledAsyncCustomSelectInput;
