import React, { useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';
import ZenPromoteAdministratorFormSidebarModal from '../../../forms/ZenPromoteAdministratorFormSidebarModal';

interface ZenPromoteAdministratorProps {}

const ZenPromoteAdministrator: React.FC<ZenPromoteAdministratorProps> = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <ZenResourceLinkCard
        title='Promote Administrator'
        onClick={() => setIsOpen(true)}
        icon={solid('contact-book')}
        iconSize='2x'
      />
      <ZenPromoteAdministratorFormSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenPromoteAdministrator;
