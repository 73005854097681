import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HealthCare from '../assets/img/healthcare.png';
import JoinReal from '../assets/img/join_real.png';
import MegaPhone from '../assets/img/megaPhone.png';
import ZenPresentationDeck from '../assets/img/presentation_deck.png';
import RecoLogin from '../assets/img/reco_login.png';
import ZenBrokerBay from '../assets/img/zen/zen-broker-bay.png';
import ZenWorkplace from '../assets/img/zen/zen-workplace.png';
import PageLayout from '../components/PageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenResourceLinkCard from '../components/ZenResourceLinkCard';
import { REAL_DESIGN_CENTER } from '../constants/MarketingCenterLinksConstants';
import {
  BROKER_BAY_LINK_URL,
  CANADIAN_PRESENTATION_DECK_LINK_URL,
  JOIN_REAL_LINK_URL,
  REAL_AGENT_BENEFITS_CA_URL,
  REAL_AGENT_BENEFITS_USA_URL,
  RECO_LOGIN_LINK_URL,
  WORK_PLACE_LINK_URL,
} from '../constants/QuicklinksConstants';
import {
  AgentResponseAccountCountryEnum,
  DesignCenterControllerApi,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { RootState } from '../types';
import { showAndReportErrors } from '../utils/ErrorUtils';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';

const ZenResourcesLinksRoute = () => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const path = [
    { title: 'Resources', url: '/resources/links' },
    { title: 'Quick Links', url: '/resources/links' },
  ];

  const isCanada =
    userDetail?.accountCountry === AgentResponseAccountCountryEnum.Canada;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [jwt, setJwt] = useState<string>();
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await new DesignCenterControllerApi(
        getYentaConfiguration(),
      ).getJwtForAgent(userDetail?.id!);
      setJwt(data.jwt!);
    } catch (e) {
      if (showAndReportErrors(e?.response)) {
        dispatch(showApiErrorModal(e));
        ErrorService.notifyIgnoreAuthErrors(
          'Unable to fetch jwt for agent',
          e,
          {
            agent: { id: userDetail?.id! },
          },
        );
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch, userDetail?.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const allQuickLinks = [
    {
      title: 'JoinReal',
      imageSource: JoinReal,
      link: JOIN_REAL_LINK_URL,
      visible: true,
    },
    {
      title: 'BrokerBay',
      imageSource: ZenBrokerBay,
      link: BROKER_BAY_LINK_URL,
      visible: isCanada,
    },
    {
      title: 'Workplace',
      imageSource: ZenWorkplace,
      link: WORK_PLACE_LINK_URL,
      visible: isCanada,
    },
    {
      title: 'Canadian Presentation Deck',
      imageSource: ZenPresentationDeck,
      link: CANADIAN_PRESENTATION_DECK_LINK_URL,
      visible: isCanada,
    },
    {
      title: 'My Reco Login',
      imageSource: RecoLogin,
      link: RECO_LOGIN_LINK_URL,
      visible: isCanada,
    },
    {
      title: 'Real Agent Benefits',
      imageSource: HealthCare,
      link: isCanada ? REAL_AGENT_BENEFITS_CA_URL : REAL_AGENT_BENEFITS_USA_URL,
      visible: true,
    },
    {
      title: 'Design Center',
      imageSource: MegaPhone,
      link: `${REAL_DESIGN_CENTER}?jwt=${jwt}`,
      visible: !loading,
    },
  ];

  const filteredQuickLinks = allQuickLinks.filter((link) => link.visible);

  return (
    <ZenRoute title='Quick Links'>
      <PageLayout path={path}>
        <div className='lg:w-4/5 w-full mx-auto p-10'>
          <div className='font-primary-medium font-bold text-center md:text-6xl text-4xl'>
            Quick Links
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 my-10'>
            {filteredQuickLinks.map((link) => (
              <div key={link.title}>
                <ZenResourceLinkCard
                  title={link.title}
                  href={link.link}
                  imageSource={link.imageSource}
                />
              </div>
            ))}
          </div>
        </div>
      </PageLayout>
    </ZenRoute>
  );
};

export default ZenResourcesLinksRoute;
