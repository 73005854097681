import { useSelector } from 'react-redux';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  CommissionInfoResponse,
  DoubleEnderResponse,
  ParticipantValueRoleEnum,
} from '../../../openapi/arrakis';
import ZenUserPill from '../ZenUserPill';
import { RootState } from '../../../types';
import Hover from '../../Hover';
import { capitalizeEnum, formatPhoneNumber } from '../../../utils/StringUtils';
import ZenCopyToClipboard from '../ZenCopyToClipBoard';
import { participantRoleDisplayName } from '../../../utils/TransactionHelper';
import ZenCollapsibleTableRow from '../ZenCollapsibleTableRow';
import { displayAmount } from '../../../utils/CurrencyUtils';
import ZenSignedNumber from '../ZenSignedNumber';
import { MoneyValue } from '../../../openapi/yenta';

export interface ZenDoubleEnderParticipantProps {
  doubleEnderInfo?: DoubleEnderResponse;
  commissionInfo: CommissionInfoResponse;
}

const ZenDoubleEnderParticipant: React.FC<ZenDoubleEnderParticipantProps> = ({
  doubleEnderInfo,
  commissionInfo,
}) => {
  const { agentById } = useSelector((state: RootState) => state.userIds);

  const doubleEnderAgent = agentById[doubleEnderInfo?.otherAgentId!] || {};
  const currency =
    doubleEnderInfo?.otherCommissionInfo?.grossCommission.currency ||
    commissionInfo.grossCommission.currency;
  const totalTaxValue: MoneyValue = {
    amount: 0,
    currency,
  };
  commissionInfo?.taxes?.forEach((curr) => {
    if (typeof totalTaxValue.amount === 'number') {
      totalTaxValue.amount += curr?.amount?.amount || 0;
    }
    totalTaxValue.currency = curr?.amount?.currency;
  });

  return (
    <section className='flex flex-row bg-grey-100 rounded-[10px]'>
      <div
        className='relative overflow-hidden border zen-dark-4 rounded-[10px] w-full bg-white'
        id='doubleEnderAgent-card'
      >
        <div className='flex items-center m-3 justify-between flex-wrap'>
          <div className='flex items-center space-x-3'>
            <ZenUserPill
              name={doubleEnderInfo?.otherAgentName || 'N/A'}
              backgroundVariant='background'
            />
            <p className='font-zen-body font-semibold  text-xs leading-5 text-zen-dark-9'>
              {participantRoleDisplayName(ParticipantValueRoleEnum.OtherAgent)}
            </p>
          </div>

          <div className='flex items-center space-x-4'>
            {doubleEnderAgent.phoneNumber && (
              <Hover
                hoverComponent={
                  <div className='flex items-center  text-zen-dark-12'>
                    {formatPhoneNumber(doubleEnderAgent.phoneNumber)}
                    <ZenCopyToClipboard
                      label=''
                      value={
                        formatPhoneNumber(doubleEnderAgent.phoneNumber) || ''
                      }
                      variant='primary'
                    />
                  </div>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <FontAwesomeIcon
                  icon={regular('phone')}
                  className='cursor-pointer text-primary-blue'
                  aria-label='doubleEnderAgent-phone'
                />
              </Hover>
            )}
            {doubleEnderAgent.emailAddress && (
              <Hover
                hoverComponent={
                  <div className='flex items-center text-zen-dark-12'>
                    {doubleEnderAgent.emailAddress}
                    <ZenCopyToClipboard
                      label=''
                      value={doubleEnderAgent.emailAddress!}
                      variant='primary'
                    />
                  </div>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <FontAwesomeIcon
                  icon={regular('envelope')}
                  className='cursor-pointer text-primary-blue'
                  aria-label='doubleEnderAgent-email'
                />
              </Hover>
            )}
          </div>
        </div>
        <div className='border-b-[1px] border-b-zen-dark-4 mb-3' />
        <div className='m-3 py-1 bg-grey-100 rounded-[10px]'>
          <ZenCollapsibleTableRow
            name='Commission Split'
            showBorder={false}
            // defaultCollapsed={defaultCollapsed}
          />
          <ZenCollapsibleTableRow
            name='Total'
            value={displayAmount(commissionInfo?.grossCommission)}
            // defaultCollapsed={defaultCollapsed}
          />
          <ZenCollapsibleTableRow
            name='Real'
            ValueComponent={<ZenSignedNumber value={totalTaxValue} />}
            defaultCollapsed={false}
          >
            {commissionInfo?.taxes?.map((item, index) => (
              <div key={item.type}>
                {index > 0 && <div className='border-b' />}
                <div className='flex justify-between items-center p-2 font-zen-bod'>
                  <div className='flex flex-row items-center space-x-1'>
                    <span className='text-gray-400 text-[12px]'>
                      {capitalizeEnum(item.type!)} Tax
                    </span>
                  </div>
                  <span className='font-semibold'>
                    <ZenSignedNumber value={item.amount!} textSize='xs' />
                  </span>
                </div>
              </div>
            ))}
          </ZenCollapsibleTableRow>
          <ZenCollapsibleTableRow
            name='Fees And Rebates'
            ValueComponent={
              <ZenSignedNumber
                value={{
                  amount: 0,
                  currency,
                }}
              />
            }
            defaultCollapsed={false}
          />
          <ZenCollapsibleTableRow
            name='Net Payout'
            ValueComponent={
              <ZenSignedNumber value={commissionInfo?.netPayout || {}} />
            }
            defaultCollapsed={false}
          />
        </div>
      </div>
    </section>
  );
};

export default ZenDoubleEnderParticipant;
