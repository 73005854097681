import pluralize from 'pluralize';
import qs from 'qs';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Column, Row } from 'react-table';
import StateOrProvinceIconCell from '../components/table/Cells/StateOrProvinceIconCell';
import ZenViewActionButtonCell from '../components/table/Cells/ZenViewActionButtonCell';
import AddressStateEnumSelectColumnFilter from '../components/table/Filters/AddressStateEnumSelectColumnFilter';
import ApplicationResponseStatusEnumSelectColumnFilter, {
  IN_PROGRESS_APPLICATION_STATUS,
} from '../components/table/Filters/ApplicationResponseStatusEnumSelectColumnFilter';
import DateColumnFilter from '../components/table/Filters/DateColumnFilter';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import YesNoSelectColumnFilter from '../components/table/Filters/YesNoSelectColumnFilter';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenApplicationStatus from '../components/Zen/Table/Cell/ZenApplicationStatus';
import ZenMilliDateCell from '../components/Zen/Table/Cell/ZenMilliDateCell';
import ZenPhoneNumberCell from '../components/Zen/Table/Cell/ZenPhoneNumberCell';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenRoute from '../components/Zen/ZenRoute';
import {
  ApplicationControllerApi,
  ApplicationResponse,
  ApplicationResponseStatusEnum,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { SearchParamOperatorEnum } from '../types';
import { applicationTableFetchData } from '../utils/applicationUtils';
import { isSmScreen } from '../utils/BrowserUtils';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';

interface ZenApplicationFormIndexProps {}

export const columns: Array<Column<ApplicationResponse>> = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Email',
    accessor: 'emailAddress',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    Cell: ({ value }) => <ZenPhoneNumberCell phoneNumber={value} />,
    cardColSize: 6,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'State / Province',
    id: 'state',
    accessor: 'doesBusinessInExtended',
    Cell: ({ value }) => {
      const stateOrProvince =
        value &&
        value.length &&
        value[0].licenseResponse!.administrativeArea!.stateOrProvince;

      return (
        stateOrProvince && <StateOrProvinceIconCell state={stateOrProvince} />
      );
    },
    Filter: AddressStateEnumSelectColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'License Number',
    id: 'licenseNumber',
    accessor: 'doesBusinessInExtended',
    Cell: ({ value }) => {
      return value && value.length && value[0]!.licenseResponse!.number;
    },
    disableFilters: true,
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: (
      <span className='text-left w-[140px] whitespace-normal'>
        Previous Brokerage Transactions
      </span>
    ),
    accessor: 'hasPendingTransactionsWithCurrentBrokerage',
    Cell: ({ value }) => (!!value ? 'Yes' : 'No'),
    Filter: YesNoSelectColumnFilter,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }) => <ZenMilliDateCell date={value!} />,
    Filter: DateColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Updated At',
    accessor: 'updatedAt',
    Cell: ({ value }) => <ZenMilliDateCell date={value!} />,
    Filter: DateColumnFilter,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => value,
    disableFilters: true,
    disableSortBy: true,
  },
];

export const columnsToFetch = [
  ...columns.map((col) => col.accessor as string),
  'feesWaivedAt',
];

const ZenApplicationFormIndex: React.FC<ZenApplicationFormIndexProps> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState('In Progress');
  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );

  const handleWaiveFees = async (applications: ApplicationResponse[]) => {
    const applicationIds = applications.map((application) => application.id!);
    try {
      await new ApplicationControllerApi(
        getYentaConfiguration(),
      ).waiveApplicationFeesInBulk({ applicationIds });
      dispatch(showSuccessToast('Applications fee waived successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to waive bulk applications fees', e, {
        applicationIds,
      });
      dispatch(showErrorToast('Unable to waiving bulk applications fees.'));
    }
  };

  const columnsWithAction: Array<Column<ApplicationResponse>> = useMemo(
    () => {
      const modifiedColumn = [
        {
          Header: 'Actions',
          id: 'actions',
          Cell: ({ row }: { row: Row<ApplicationResponse> }) => (
            <Link to={`/applications/${row.original.id}`}>
              <ZenViewActionButtonCell />
            </Link>
          ),
        },
        ...columns,
      ];

      modifiedColumn.splice(4, 0, {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <ZenApplicationStatus status={value!} />,
        Filter:
          currentTab === 'In Progress'
            ? ApplicationResponseStatusEnumSelectColumnFilter
            : undefined,
        disableFilters: currentTab !== 'In Progress',
        cardColSize: 6,
      });

      return modifiedColumn;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTab],
  );

  return (
    <ZenRoute title='Applications'>
      <ZenPageLayoutWithSearch
        path={[
          { title: 'Home', url: '/' },
          { title: 'Applications', url: '/applications' },
        ]}
        search={search}
        onSearchChange={setSearch}
      >
        <div className='px-4 lg:py-5'>
          <ZenResourceIndexContainer<ApplicationResponse>
            header='Applications'
            columns={columnsWithAction}
            search={search}
            resourceName='application'
            initialSort={{ updatedAt: 'desc' }}
            fetchData={(req, cancelToken) =>
              applicationTableFetchData(req, { cancelToken })
            }
            showSearch={isSmScreen()}
            selectionOptions={[
              {
                label: 'Waive Fees',
                onAction: handleWaiveFees,
                confirm: (applications) => ({
                  title: 'Waive Application Fees',
                  description: `Are you sure you would like to waive fees for ${
                    applications.length
                  } ${pluralize(
                    'application',
                    applications.length,
                  )}? This action cannot be undone.`,
                  modalType: 'info',
                  primaryActionTitle: 'Waive Fees',
                }),
                isActionButtonEnabled: (applications) => {
                  const allowedStatuses = [
                    ApplicationResponseStatusEnum.Started,
                    ApplicationResponseStatusEnum.SignIca,
                    ApplicationResponseStatusEnum.IcaSigned,
                    ApplicationResponseStatusEnum.PayFees,
                  ];

                  const isEnabled = applications.every(
                    (application) =>
                      allowedStatuses.includes(application.status!) &&
                      !application.feesWaivedAt,
                  );

                  return isEnabled;
                },
              },
            ]}
            allowSelection={currentTab === 'In Progress'}
            tabs={[
              {
                name: 'In Progress',
                filter: {
                  status: {
                    column: 'status',
                    values: IN_PROGRESS_APPLICATION_STATUS,
                    operator: SearchParamOperatorEnum.In,
                  },
                },
              },
              {
                name: 'Approved',
                filter: {
                  status: {
                    column: 'status',
                    values: [ApplicationResponseStatusEnum.Approved],
                    operator: SearchParamOperatorEnum.In,
                  },
                },
              },
              {
                name: 'Rejected',
                filter: {
                  status: {
                    column: 'status',
                    values: [ApplicationResponseStatusEnum.Rejected],
                    operator: SearchParamOperatorEnum.In,
                  },
                },
              },
            ]}
            setCurrentTab={setCurrentTab}
            stickyHeader
          />
        </div>
      </ZenPageLayoutWithSearch>
    </ZenRoute>
  );
};

export default ZenApplicationFormIndex;
