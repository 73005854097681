import { useMemo } from 'react';
import {
  AmountDiscountResponse,
  ApplicationControllerApi,
} from '../../openapi/yenta';
import { InvitationEnum } from '../../routes/JoinByInvitationRoute';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { isEmailInvite, isLinkInvite } from '../../utils/TypeUtils';
import { queryKeys } from '../base/queryKeys';
import { QueryOptions, useSimpleQuery } from '../base/useSimpleQuery';
import { useInvitation } from './useInvitation';

interface UseInvitationCouponProps {
  /**
   * Same `options` as you would pass to `useQuery` in react-query, but with some extra options.
   * See `QueryOptions` type for details.
   */
  options?: Partial<QueryOptions<AmountDiscountResponse | null>>;
  /**
   * All the arguments the API function is expecting.
   */
  fnArgs: [string, InvitationEnum, string, number];
}

export const useInvitationCoupon = ({
  options,
  fnArgs,
}: UseInvitationCouponProps) => {
  const [id, type, applicationId, amount] = fnArgs;

  const { data: invitation, isLoading, isFetching } = useInvitation({
    fnArgs: [id, type],
  });

  const coupon = useMemo(() => {
    let coupon: string | null = null;

    if (isEmailInvite(invitation)) {
      coupon = invitation.couponCode!;
    } else if (isLinkInvite(invitation)) {
      coupon = invitation.invitation?.couponCode!;
    }

    return coupon;
  }, [invitation]);

  const queryResult = useSimpleQuery<AmountDiscountResponse | null>({
    queryKey: queryKeys.invite.coupon(id, type, applicationId, amount).queryKey,
    queryFn: async () => {
      const { data } = await new ApplicationControllerApi(
        getYentaConfiguration(),
      ).calculateAmountDiscountInfo(applicationId, coupon!, amount * 100);

      return {
        ...data,
        calculatedDiscount: data.calculatedDiscount! / 100,
        originalAmount: data.originalAmount! / 100,
      };
    },
    options: {
      toastErrorMessage: 'Failed to waive application fees!',
      logErrorMessage: 'Failed to waive application fees!',
      enabled: !!invitation && !!coupon,
      ...options,
    },
  });

  return {
    data: queryResult.data,
    isLoading: isLoading || queryResult.isLoading,
    isFetching: isFetching || queryResult.isFetching,
  };
};
