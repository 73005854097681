import { AgentResponseAccountCountryEnum } from '../../../openapi/yenta';
import { EnumMap } from '../../../types';

interface CountryCellProps {
  country: AgentResponseAccountCountryEnum | undefined;
}

export const countryToEmojiMap: EnumMap<
  AgentResponseAccountCountryEnum,
  string
> = {
  CANADA: `🇨🇦`,
  UNITED_STATES: `🇺🇸`,
};

const CountryCell: React.FC<CountryCellProps> = ({ country }) => {
  const value = country ? countryToEmojiMap[country] : 'N/A';

  return <p>{value}</p>;
};

export default CountryCell;
