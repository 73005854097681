import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { recalculateCommission } from '../../slices/TransactionSlice';
import { EnumMap, RootState } from '../../types';
import { SUPPORT_EMAIL_ADDRESS } from '../../utils/SupportUtils';
import ZenSimpleConfirmationModal from '../Zen/Modal/ZenSimpleConfirmationModal';
import ZenButton from '../Zen/ZenButton';

type ServerErrorType = 'TRANSACTION' | 'LISTING';

interface TransactionServerErrorProps {
  type?: ServerErrorType;
  transactionId: string;
}

const TransactionServerError: React.FC<TransactionServerErrorProps> = ({
  type = 'TRANSACTION',
  transactionId,
}) => {
  const dispatch = useDispatch();
  const {
    transaction: { loadingTransactionDetail },
  } = useSelector((state: RootState) => state);
  const typeToMessageMap: EnumMap<ServerErrorType, string> = {
    TRANSACTION:
      'Oops! Something went wrong. Would you like to recalculate the transaction?',
    LISTING: 'Oops! Something went wrong.',
  };
  const [
    isRecalculatingCommission,
    setIsRecalculatingCommission,
  ] = useState<boolean>(false);

  return (
    <div className='w-full h-full p-7 flex flex-col items-center justify-center'>
      <p className='font-primary-medium text-center'>
        {typeToMessageMap[type]}
      </p>
      {type === 'TRANSACTION' && (
        <div className='my-2'>
          <ZenButton
            label='Recalculate Commission'
            onClick={() => setIsRecalculatingCommission(true)}
          />
        </div>
      )}
      <p className='font-primary-medium text-center'>
        If the problem persists, please contact{' '}
        <a
          href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}
          className='text-primary-blue'
        >
          {SUPPORT_EMAIL_ADDRESS}
        </a>{' '}
        for assistance.
      </p>
      <ZenSimpleConfirmationModal
        variant='warning'
        size='large'
        title="You're about to recalculate the commission."
        subtitle='You can force a recalculation if you believe there is an error or inconsistency in the commission splits. This will transition the transaction back to the Needs Commission Validation status.'
        isOpen={isRecalculatingCommission}
        onClose={() => setIsRecalculatingCommission(false)}
        isSubmitting={loadingTransactionDetail}
        onConfirm={async () => {
          await dispatch(recalculateCommission(transactionId!));
          setIsRecalculatingCommission(false);
        }}
      />
    </div>
  );
};

export default TransactionServerError;
