import qs from 'qs';
import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { TableQueryParamState } from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import useQueryParams from '../hooks/useQueryParams';
import { TransactionResponse } from '../openapi/arrakis';
import { RootState, TransactionsRouteUrlParams } from '../types';
import GeminiAdminOrBrokerTransactionOrListingTable from './GeminiAdminOrBrokerTransactionOrListingTable';

interface Props extends RouteComponentProps {}

const GeminiAdminOrBrokerListingsIndexRoute: React.FC<Props> = ({
  history,
  location,
  match,
}) => {
  const query = useQueryParams<TableQueryParamState<TransactionResponse>>();
  const params = match?.params as TransactionsRouteUrlParams;

  const { isBroker, userDetail } = useSelector(
    (state: RootState) => state.auth,
  );

  /**
   * if logged in as broker, filter transactions by offices
   */
  const officeIds = isBroker
    ? (userDetail?.offices || [])
        .filter((office) => !!office && !!office?.id)
        .map((office) => office.id!)
    : undefined;

  const PageLayoutWrapper =
    params?.lifecycle === 'draft' ? ZenPageLayout : ZenPageLayoutWithSearch;

  return (
    <PageLayoutWrapper
      path={[
        { title: 'Home', url: '/' },
        {
          title: 'My Listings',
          url: '/listings',
        },
      ]}
      search={query?.search}
      onSearchChange={(search: string | undefined) =>
        history.replace(
          `${location?.pathname}?${qs.stringify({ ...query, search })}`,
        )
      }
    >
      <GeminiAdminOrBrokerTransactionOrListingTable
        lifecycle={params?.lifecycle}
        type={params?.type}
        officeIds={officeIds}
        isListing
      />
    </PageLayoutWrapper>
  );
};

export default GeminiAdminOrBrokerListingsIndexRoute;
