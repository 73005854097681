import React, { useEffect, useMemo, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import {
  revShareInsightsBadgeLockedIconMap,
  revShareInsightsBadgeUnlockedIconMap,
} from '../../utils/RevShareInsightsUtils';
import { BadgeResponseNameEnum } from '../../openapi/insignia';
import { AnalyticsEventEnum, RootState } from '../../types';
import { getPriorityWiseSortedData } from '../../utils/TableUtils';
import AnalyticsService from '../../services/AnalyticsService';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import RevShareInsightsBadge from './RevShareInsightsBadge';
import RevShareInsightsBadgeDetails from './RevShareInsightsBadgeDetails';

const badgesPriority: BadgeResponseNameEnum[] = [
  BadgeResponseNameEnum.IncomeScholar,
  BadgeResponseNameEnum.RisingStar,
  BadgeResponseNameEnum.NetworkBuilder,
  BadgeResponseNameEnum.PeakConqueror,
  BadgeResponseNameEnum.AdmiralAchiever,
  BadgeResponseNameEnum.Locksmith,
  BadgeResponseNameEnum.MasterRecruiter,
  BadgeResponseNameEnum.CollaborationChampion,
  BadgeResponseNameEnum.MilestoneMaker,
  BadgeResponseNameEnum.QuarterQuasar,
  BadgeResponseNameEnum.MidpointMaestro,
  BadgeResponseNameEnum.MilestoneMonarch,
  BadgeResponseNameEnum.SixFigureSovereign,
];

interface ScrollMenuHeaderProps {
  unlockedCount?: number;
  totalBadgesCount?: number;
}

const ScrollMenuHeader: React.FC<ScrollMenuHeaderProps> = ({
  unlockedCount = 0,
  totalBadgesCount = 0,
}) => {
  const {
    initComplete,
    isFirstItemVisible,
    isLastItemVisible,
    scrollPrev,
    scrollNext,
    visibleElements,
  } = React.useContext(VisibilityContext);

  const [prevDisabled, setPrevDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible),
  );
  const [nextDisabled, setNextDisabled] = React.useState(
    !visibleElements.length && isLastItemVisible,
  );

  useEffect(() => {
    if (visibleElements.length) {
      setPrevDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  useEffect(() => {
    if (visibleElements.length) {
      setNextDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <div className='mb-2.5 md:px-2 flex items-center justify-between space-x-2 text-zen-dark-9 font-zen-body text-sm'>
      <p>My badges</p>
      <p>
        Awarded : {unlockedCount} / {totalBadgesCount}
      </p>
      {(!prevDisabled || !nextDisabled) && (
        <div className='flex items-center space-x-2'>
          <button
            disabled={prevDisabled}
            onClick={() => scrollPrev()}
            className='px-2 py-1.5 border border-zen-dark-light-gray-2 rounded-md disabled:bg-zen-dark-4 disabled:text-white disabled:border-zen-dark-4 disabled:cursor-not-allowed'
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            disabled={nextDisabled}
            onClick={() => scrollNext()}
            className='px-2 py-1.5 border border-zen-dark-light-gray-2 rounded-md disabled:bg-zen-dark-4 disabled:text-white disabled:border-zen-dark-4 disabled:cursor-not-allowed'
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      )}
    </div>
  );
};

interface RevShareInsightsBadgesProps {}

const RevShareInsightsBadges: React.FC<RevShareInsightsBadgesProps> = () => {
  const [
    currentBadgeName,
    setCurrentBadgeName,
  ] = useState<BadgeResponseNameEnum>();

  const {
    agentBadgesResponse,
    generalBadgesResponse,
    agentBadgeByName,
  } = useSelector((state: RootState) => state.revenue);

  const agentBadges = useMemo(() => agentBadgesResponse.data?.badges || [], [
    agentBadgesResponse.data?.badges,
  ]);

  const badges = useMemo(
    () =>
      generalBadgesResponse.data?.badges
        ?.slice()
        .sort((a, b) =>
          getPriorityWiseSortedData(a.name!, b.name!, badgesPriority),
        ) || [],
    [generalBadgesResponse.data?.badges],
  );

  const unlockedCount = useMemo(() => {
    return agentBadges.filter((badge) => badge.unlocked).length;
  }, [agentBadges]);

  return (
    <div aria-label='badges'>
      <ScrollMenu
        Header={() => (
          <ScrollMenuHeader
            unlockedCount={unlockedCount}
            totalBadgesCount={badges.length}
          />
        )}
        scrollContainerClassName='space-x-2'
      >
        {badges.map((b) => {
          const badgeName = b.name!;
          const badge = agentBadgeByName[badgeName];
          const unlocked = !!badge?.unlocked;
          const Icon = unlocked
            ? revShareInsightsBadgeUnlockedIconMap[badgeName]
            : revShareInsightsBadgeLockedIconMap[badgeName];
          const dimension = unlocked ? 80 : 60;

          return (
            <RevShareInsightsBadge
              key={badgeName}
              badge={badge}
              icon={
                Icon ? <Icon width={dimension} height={dimension} /> : <div />
              }
              onClick={() => {
                AnalyticsService.instance().logEvent(
                  AnalyticsEventEnum.RSI_OPEN_BADGE,
                  { badgeName: badgeName },
                );
                setCurrentBadgeName(badgeName);
              }}
            />
          );
        })}
      </ScrollMenu>
      <AnimatePresence>
        {!!currentBadgeName && (
          <RevShareInsightsBadgeDetails
            onClose={() => setCurrentBadgeName(undefined)}
            badgeName={currentBadgeName}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default RevShareInsightsBadges;
