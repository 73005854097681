import { AddressStateEnum } from '../../../openapi/arrakis';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumMultiSelectColumnGenerator from './EnumMultiSelectColumnGenerator';

const AddressStateEnumSelectColumnFilter = <D extends object>(
  props: SelectColumnFilterProps<D>,
) => {
  return EnumMultiSelectColumnGenerator<D>(AddressStateEnum)(props);
};

export default AddressStateEnumSelectColumnFilter;
