import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CommentShieldSvg } from '../../../../assets/img/roar/comment-shield.svg';
import { ReactComponent as LockKeyWhiteSvg } from '../../../../assets/img/roar/lock-key-white.svg';
import { ReactComponent as RecordingMicSvg } from '../../../../assets/img/roar/recording-mic.svg';
import { ReactComponent as UsersSvg } from '../../../../assets/img/roar/users.svg';
import { EmailSupportType, RootState } from '../../../../types';
import { openEmailClientForSupportEmail } from '../../../../utils/SupportUtils';
import { Button } from '../../../commons/Button';
import GeminiSimpleModal from '../../../Gemini/GeminiSimpleModal';

type VoiceCallDisclaimerModalProps = {
  onClose(): void;
};

const VoiceCallDisclaimerModal: React.FC<VoiceCallDisclaimerModalProps> = ({
  onClose,
}) => {
  const userDetail = useSelector((state: RootState) => state.auth.userDetail);

  return (
    <GeminiSimpleModal
      isOpen
      hideHeader
      title='some'
      onClose={onClose}
      containerClassNames='w-full md:w-full lg:w-full max-w-lg md:max-w-lg lg:max-w-lg'
    >
      <div className='p-6 flex flex-col items-center'>
        <LockKeyWhiteSvg width={55} height={60} />
        <div className='mt-5 w-full p-4 rounded-lg bg-regent-200'>
          <div className='flex items-start space-x-2.5'>
            <CommentShieldSvg width={20} height={25} />
            <p className='flex-1 font-inter text-primary-dark text-sm'>
              Conversations that are recorded by the Company&apos;s ROAR product
              will be transcribed and available in the relevant transaction of
              the agent in reZEN.
            </p>
          </div>
          <div className='mt-3 pt-3 border-t border-grey-300 flex items-start space-x-2.5'>
            <UsersSvg width={20} height={25} />
            <p className='flex-1 font-inter text-primary-dark text-sm'>
              Only you, your state brokers, and company-authorized personnel
              will have access to the contents of the call, which includes the
              recording, transcript and summary.
            </p>
          </div>
          <div className='mt-3 pt-3 border-t border-grey-300 flex items-start space-x-2.5'>
            <RecordingMicSvg width={20} height={25} />
            <p className='flex-1 font-inter text-primary-dark text-sm'>
              ROAR analyzes your voice to identify you in these transcripts and
              summaries. If you do not want to be identified, please email{' '}
              <span
                className='cursor-pointer text-rezen-blue-600 underline'
                onClick={() =>
                  openEmailClientForSupportEmail(
                    EmailSupportType.VOICE_RECORDING,
                    userDetail!,
                  )
                }
              >
                Support
              </span>
              .
            </p>
          </div>
        </div>
        <Button className='mt-5' fullWidth onClick={onClose}>
          Okay
        </Button>
      </div>
    </GeminiSimpleModal>
  );
};

export default VoiceCallDisclaimerModal;
