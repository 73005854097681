import { DateTime, LocaleOptions } from 'luxon';
import { useCallback, useMemo } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form-v7';
import { generateTimeSlots } from '../../../utils/DateUtils';
import ZenControlledSelectInput, {
  ZenControlledSelectInputProps,
} from './ZenControlledSelectInput';

interface ZenControlledTimeSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<ZenControlledSelectInputProps<TFieldValues, TName>, 'options'> {
  interval?: 15 | 30 | 60;
  min?: string;
  max?: string;
}

const LOCALE_OPTIONS: LocaleOptions = { locale: 'en-US' };

export const getTimeLabel = (time?: string): string => {
  if (!time) {
    return '';
  }
  return DateTime.fromFormat(time, 'TT', LOCALE_OPTIONS).toFormat(
    't',
    LOCALE_OPTIONS,
  );
};

const ZenControlledTimeSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  interval = 30,
  min,
  max,
  ...props
}: ZenControlledTimeSelectProps<TFieldValues, TName>) => {
  const checkMinMax = useCallback(
    (time: DateTime) => {
      if (min) {
        const minTime = DateTime.fromISO(min);
        return time.diff(minTime, 'hours').toObject().hours! < interval / 60;
      }
      if (max) {
        const maxTime = DateTime.fromISO(max);
        return time.diff(maxTime, 'hours').toObject().hours! > 0;
      }
      return false;
    },
    [interval, max, min],
  );

  const options = useMemo(() => {
    const timeSlots = generateTimeSlots(interval);
    return timeSlots.map((time) => ({
      label: time.toFormat('t', LOCALE_OPTIONS),
      value: time.toFormat('T', LOCALE_OPTIONS),
      disabled: checkMinMax(time),
    }));
  }, [interval, checkMinMax]);

  return (
    <ZenControlledSelectInput {...props} options={options} hideIndicatorsIcon />
  );
};

export default ZenControlledTimeSelect;
