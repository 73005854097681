import { faCheckDouble } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { ReactComponent as SecondaryClipboard } from '../../assets/img/zen-clipboard-secondary.svg';
import { ReactComponent as Clipboard } from '../../assets/img/zen-clipboard.svg';
import { EnumMap } from '../../types';
import { cn } from '../../utils/classUtils';

export type ZenCopyToClipboardVariant = 'primary' | 'secondary' | 'default';
interface ZenCopyToClipboardProps {
  label?: string;
  value: string;
  variant?: 'primary' | 'default' | 'secondary';
  className?: string;
  textStyle?: string;
  iconStyle?: string;
}

const ZenCopyToClipboard: React.FC<ZenCopyToClipboardProps> = ({
  value,
  label = 'Copy to Clipboard',
  variant = 'primary',
  className,
  textStyle,
  iconStyle,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [text] = useState(value);
  const onCopyText = () => {
    if (navigator.clipboard && window.isSecureContext)
      navigator.clipboard.writeText(text);
    else {
      let textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      new Promise<void>((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const divVariantToClassNameMap: EnumMap<ZenCopyToClipboardVariant, string> = {
    default: 'border border-zen-dark-4 rounded-lg',
    primary: '',
    secondary: 'rounded-3xl bg-zen-light-gray-2',
  };

  const buttonVariantToClassNameMap: EnumMap<
    ZenCopyToClipboardVariant,
    string
  > = {
    default: 'text-zen-dark-9 p-0.5',
    primary: 'text-primary-blue p-0.5',
    secondary: 'text-zen-dark-8 text-base pl-1',
  };

  return (
    <div className='flex flex-row'>
      <div
        className={cn(
          'flex flex-row items-center p-1.5 space-x-2',
          divVariantToClassNameMap[variant],
          className,
        )}
      >
        <button
          className={cn(
            'flex items-center space-x-1 focus:outline-none font-zen-body font-semibold text-sm whitespace-nowrap',
            buttonVariantToClassNameMap[variant],
            textStyle,
          )}
          onClick={() => onCopyText()}
          type='button'
          aria-label='copy-to-clipboard'
        >
          {variant === 'secondary' ? (
            <SecondaryClipboard className={cn('h-6 w-4', iconStyle)} />
          ) : (
            <Clipboard className={cn(iconStyle)} />
          )}
          <p className='text-xs md:text-sm'>{label}</p>
        </button>
      </div>
      {isCopied && (
        <div className='flex items-center justify-center space-x-1 text-primary-blue'>
          <FontAwesomeIcon icon={faCheckDouble} />
          <p className='text-xs pr-0.5'>Copied</p>
        </div>
      )}
    </div>
  );
};

export default ZenCopyToClipboard;
