import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faClose,
  faHourglass,
} from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import ImgLogo from '../assets/img/add_pdf.png';
import ZenBooleanButton from '../components/Agent/V2/ZenBooleanButton';
import ZenApplicationApproveModal from '../components/applicationForm/ZenApplicationApproveModal';
import ZenApplicationDetailActionBar from '../components/applicationForm/ZenApplicationDetailActionBar';
import ZenApplicationDetailCard from '../components/applicationForm/ZenApplicationDetailCard';
import ZenApplicationEditForm from '../components/applicationForm/ZenApplicationEditForm';
import ZenApplicationWaiveFeeModal from '../components/applicationForm/ZenApplicationWaiveFeeModal';
import DataTable from '../components/DataTable';
import ZenApplicationStepsProgress from '../components/onboarding/ZenApplicationStepsProgress';
import ZenConfirmationModal from '../components/Zen/Modal/ZenConfirmationModal';
import ZenTransactionNotesContainer from '../components/Zen/Transaction/ZenTransactionNotesContainer';
import ZenAction from '../components/Zen/ZenAction';
import ZenButton from '../components/Zen/ZenButton';
import ZenCard from '../components/Zen/ZenCard';
import ZenDataRow from '../components/Zen/ZenDataRow';
import ZenPill from '../components/Zen/ZenPill';
import ZenApplicationHeader from '../components/ZenApplicationHeader';
import ZenAttachmentRow from '../components/ZenAttachmentRow';
import ZenPageAnnouncement from '../components/ZenPageAnnouncement';
import { useGemini } from '../hooks/useGemini';
import {
  ApplicationResponse,
  ApplicationResponseApplicationTypeEnum,
  ApplicationResponseNextStatusEnum,
  ApplicationResponseStatusEnum,
  CreateNoteRequestEntityTypeEnum,
} from '../openapi/yenta';
import {
  agentJoinedBoard,
  agentLicenseTransferred,
  rejectApplicationById,
} from '../slices/ApplicationSlice';
import {
  APPLICATION_FEE_INDEX,
  getIndexFromStatus,
  JOIN_THE_BOARD_INDEX,
  onboardingSteps,
  TRANSFER_LICENSE_INDEX,
} from '../testUtils/OnboardingUtils';
import { cn } from '../utils/classUtils';
import { capitalizeEnum, formatPhoneNumber } from '../utils/StringUtils';
import { getBaseStripeUrl } from '../utils/StripeUtils';

interface ZenApplicationFormContentProps {
  id: string;
  name: string;
  application: ApplicationResponse;
}

const ZenApplicationFormContent: React.FC<ZenApplicationFormContentProps> = ({
  id,
  name,
  application,
}) => {
  const isGeminiEnabled = useGemini();
  const [
    openApplicationEditFormSidebarModal,
    setOpenApplicationEditFormSidebarModal,
  ] = useState<boolean>(false);
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false);
  const [
    openTransferLicenseModal,
    setOpenTransferLicenseModal,
  ] = useState<boolean>(false);
  const [openJoinBoardModal, setOpenJoinBoardModal] = useState<boolean>(false);
  const [showWaiveFeeModal, setShowWaiveFeeModal] = useState<boolean>(false);

  // const [stepsWithAction, setActionState] = useState(onboardingSteps);
  const dispatch = useDispatch();

  const licenseInfo =
    application.doesBusinessInExtended && application.doesBusinessInExtended[0];

  // We need to clone this array to make sure we get a new copy every time
  const stepsWithAction = onboardingSteps.map((obj) => ({ ...obj }));

  if (application.nextStatus === ApplicationResponseNextStatusEnum.PayFees) {
    stepsWithAction[APPLICATION_FEE_INDEX].action = {
      buttonText: 'Waive Fees',
      onClick: () => setShowWaiveFeeModal(true),
    };
  }
  if (
    application.nextStatus === ApplicationResponseNextStatusEnum.TransferLicense
  ) {
    stepsWithAction[TRANSFER_LICENSE_INDEX].action = {
      buttonText: 'Mark as Complete',
      onClick: () => setOpenTransferLicenseModal(true),
    };
  } else if (
    application.nextStatus === ApplicationResponseNextStatusEnum.JoinBoard
  ) {
    stepsWithAction[JOIN_THE_BOARD_INDEX].action = {
      buttonText: 'Mark as Complete',
      onClick: () => setOpenJoinBoardModal(true),
    };
  }

  const hasMadePayment: boolean = !!application.feesStripeChargeId;
  const hasSkippedPayment: boolean =
    !!application.feesPaidAt && !hasMadePayment;

  const onApplicationReject = () => {
    dispatch(rejectApplicationById(id, { agentId: application.agentId! }));
    setOpenRejectModal(false);
  };

  return (
    <div>
      <ZenApplicationDetailActionBar
        application={application}
        onRejectClicked={() => setOpenRejectModal(true)}
      />
      {application.nextStatus ===
        ApplicationResponseNextStatusEnum.PendingApproval && (
        <div className='mt-1'>
          <ZenPageAnnouncement
            content='Application is pending for approval'
            title='Pending for Review'
            variant='warning'
            titleIcon={
              <FontAwesomeIcon
                icon={regular('circle-exclamation')}
                color='text-zen-warning-3'
                size='lg'
                className='items-center'
              />
            }
            RightComponent={
              <div className='flex flex-row space-x-1'>
                <ZenButton
                  label='Approve'
                  type='button'
                  variant='primary'
                  onClick={() => setOpenApproveModal(true)}
                />
                <ZenButton
                  label='Reject'
                  variant='danger'
                  onClick={() => setOpenRejectModal(true)}
                />
              </div>
            }
            show
          />
        </div>
      )}
      {application.status === ApplicationResponseStatusEnum.Approved && (
        <ZenPageAnnouncement
          content={`Application was approved on ${DateTime.fromMillis(
            application.approvedAt!,
          ).toLocaleString()}`}
          title='Approved'
          variant='success'
          titleIcon={
            <FontAwesomeIcon icon={faCheck} size='lg' className='ml-0.5 mr-1' />
          }
          show
        />
      )}
      {application.status === ApplicationResponseStatusEnum.Rejected && (
        <ZenPageAnnouncement
          content={`Application was rejected on ${DateTime.fromMillis(
            application.rejectedAt!,
          ).toLocaleString()}`}
          title='Rejected'
          variant='danger'
          titleIcon={
            <FontAwesomeIcon icon={faClose} size='lg' className='ml-1 mr-1.5' />
          }
          show
        />
      )}
      <div className='-mt-2'>
        <ZenApplicationHeader
          id={application.id!}
          name={name}
          state={
            licenseInfo
              ? capitalizeEnum(
                  licenseInfo?.licenseResponse?.administrativeArea!
                    .stateOrProvince!,
                )
              : 'N/A'
          }
          expiryDate={licenseInfo?.licenseResponse?.expirationDate!}
          licenseNumber={licenseInfo?.licenseResponse?.number!}
          phoneNumber={formatPhoneNumber(application.phoneNumber)!}
          primaryEmail={application.emailAddress!}
          agentStatus={application.status!}
          agentId={application.agentId!}
        />
      </div>
      <div className='p-4'>
        <div className='grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-5'>
          <div>
            <ZenCard
              title='Application Details'
              RightComponent={
                <ZenAction
                  text='Edit'
                  onClick={() => setOpenApplicationEditFormSidebarModal(true)}
                  icon={
                    <MdOutlineModeEditOutline
                      className={cn(
                        isGeminiEnabled
                          ? 'font-medium text-[13px] leading-[23px] text-rezen-blue-600'
                          : 'text-lg text-primary-blue',
                      )}
                    />
                  }
                />
              }
            >
              <ZenApplicationDetailCard application={application} />
            </ZenCard>
          </div>
          <div className='space-y-5'>
            <div>
              <ZenCard
                title='Additional Information'
                titleAdjacentComponent={
                  <FontAwesomeIcon
                    icon={regular('circle-info')}
                    className='mr-2'
                  />
                }
                titleIconPlacement='left'
              >
                <div className='mt-4 mx-4'>
                  <div className='mb-3'>
                    <ZenAttachmentRow
                      date={
                        !hasSkippedPayment ? application.feesPaidAt : undefined
                      }
                      action={
                        !application.feesWaivedAt &&
                        !hasSkippedPayment &&
                        hasMadePayment
                          ? {
                              text: 'View on Stripe',
                              onClick: () => {
                                window.open(
                                  `${getBaseStripeUrl()}/payments/${
                                    application.feesStripeChargeId
                                  }`,
                                  '_blank',
                                );
                              },
                            }
                          : undefined
                      }
                      label={
                        hasSkippedPayment ? (
                          <ZenPill
                            title='Paid Fee via Coupon'
                            variant='primary-nooutline'
                          />
                        ) : application.feesWaivedAt ? (
                          <ZenPill title='Waived Fee' variant='success' />
                        ) : hasMadePayment ? (
                          'Invoice'
                        ) : (
                          'Waiting on Payment'
                        )
                      }
                      icon={
                        !hasSkippedPayment && !application.feesWaivedAt ? (
                          hasMadePayment ? (
                            <img
                              src={ImgLogo}
                              className='h-8'
                              alt='Real Logo'
                            />
                          ) : (
                            <FontAwesomeIcon icon={faHourglass} size='lg' />
                          )
                        ) : undefined
                      }
                      title='Payment'
                    />
                  </div>
                  {application.applicationType ===
                    ApplicationResponseApplicationTypeEnum.LetterOfIntent && (
                    <div className='mt-2'>
                      <ZenCard title='Letter Of Intent'>
                        {application.loiSignedAt ? (
                          <DataTable>
                            <ZenDataRow
                              customValueStyle='px-4 py-4 w-[30%]'
                              customNameStyle='px-4 py-4 w-[70%]'
                              name='Name'
                              value={application.loiName}
                            />
                            <ZenDataRow
                              customValueStyle='px-4 py-4 w-[30%]'
                              customNameStyle='px-4 py-4 w-[70%]'
                              name='Resident In'
                              value={application.loiResidentIn}
                            />
                            <ZenDataRow
                              customValueStyle='px-4 py-4 w-[30%]'
                              customNameStyle='px-4 py-4 w-[70%]'
                              name='Number of Days Until Joining'
                              value={application.loiNumDaysUntilJoining}
                            />
                            <ZenDataRow
                              customValueStyle='px-4 py-4 w-[30%]'
                              customNameStyle='px-4 py-4 w-[70%]'
                              name='Signed Date'
                              value={DateTime.fromMillis(
                                application.loiSignedAt,
                              ).toLocaleString()}
                            />
                            <ZenDataRow
                              name='Status'
                              customNameStyle='px-4 py-4 w-[70%]'
                              customValueStyle='px-4 py-4 w-[30%]'
                            >
                              <label>
                                <span
                                  className={classNames(
                                    'px-2 py-1.5 text-sm space-x-1 bg-green-100 text-green-600 rounded-full',
                                  )}
                                >
                                  <FontAwesomeIcon icon={faCheck} size='lg' />{' '}
                                  Agreement Signed
                                </span>
                              </label>
                            </ZenDataRow>
                          </DataTable>
                        ) : (
                          <DataTable>
                            <ZenDataRow
                              name='Status'
                              customNameStyle='px-4 py-4 w-[70%]'
                              customValueStyle='px-4 py-4 w-[30%]'
                            >
                              <label>
                                <span
                                  className={classNames(
                                    'px-2 py-1.5 text-sm space-x-1 bg-zen-warning-light text-zen-warning-dark font-semibold rounded-full',
                                  )}
                                >
                                  <FontAwesomeIcon
                                    icon={regular('hourglass-half')}
                                  />{' '}
                                  Signature Pending
                                </span>
                              </label>
                            </ZenDataRow>
                          </DataTable>
                        )}
                      </ZenCard>
                    </div>
                  )}
                  <div className='mt-4'>
                    <ZenCard title='Independent Contractor Agreement'>
                      {application.icaSignedAt ? (
                        <DataTable>
                          <ZenDataRow
                            name='Name'
                            value={application.icaName}
                            customValueStyle='px-4 py-4 w-[30%]'
                            customNameStyle='px-4 py-4 w-[70%]'
                          />
                          <ZenDataRow
                            customValueStyle='px-4 py-4 w-[30%]'
                            customNameStyle='px-4 py-4  w-[70%]'
                            name='Emergency Contact Name'
                            value={application.icaEmergencyContactName}
                          />
                          <ZenDataRow
                            customValueStyle='px-4 py-4 w-[30%]'
                            customNameStyle='px-4 py-4 w-[70%]'
                            name='Emergency Contact Phone Number'
                            value={formatPhoneNumber(
                              application.icaEmergencyContactPhoneNumber,
                            )}
                          />
                          <ZenDataRow
                            customValueStyle='px-4 py-4 w-[30%]'
                            customNameStyle='px-4 py-4 w-[70%]'
                            name='Emergency Contact Email Address'
                            value={application.icaEmergencyContactEmailAddress}
                          />
                          <ZenDataRow
                            customValueStyle='px-4 py-4 w-[30%]'
                            customNameStyle='px-4 py-4 w-[70%]'
                            name='Emergency Contact Relationship'
                            value={application.icaEmergencyContactRelationship}
                          />
                          <ZenDataRow
                            customValueStyle='px-4 py-4 w-[30%]'
                            customNameStyle='px-4 py-4 w-[70%]'
                            name='Referral Agent'
                            value={
                              application.icaSponsoringAgents ? (
                                <>
                                  {application.icaSponsoringAgents.map(
                                    ({ name, email }) => (
                                      <div key={`${name}:${email}`}>
                                        {name} {email ? `(${email})` : ''}
                                      </div>
                                    ),
                                  )}
                                </>
                              ) : (
                                'N/A'
                              )
                            }
                          />
                          <ZenDataRow
                            name='Opt-in To Stock Plan'
                            customValueStyle='px-4 py-4 w-[30%]'
                            customNameStyle='px-4 py-4 w-[70%]'
                          >
                            <ZenBooleanButton
                              value={application.optInToStockPlan!}
                            />
                          </ZenDataRow>
                          <ZenDataRow
                            name='Anniversary Date'
                            value={DateTime.fromISO(
                              application.icaAnniversaryDateRequested!,
                            ).toLocaleString()}
                            customValueStyle='px-4 py-4 w-[30%]'
                            customNameStyle='px-4 py-4 w-[70%]'
                          />
                          <ZenDataRow
                            name='Signed Date'
                            value={DateTime.fromMillis(
                              application.icaSignedAt,
                            ).toLocaleString()}
                            customValueStyle='px-4 py-4 w-[30%]'
                            customNameStyle='px-4 py-4 w-[70%]'
                          />
                          <ZenDataRow
                            name='Status'
                            customValueStyle='px-4 py-4 w-[30%]'
                            customNameStyle='px-4 py-4 w-[70%]'
                          >
                            <label>
                              <span
                                className={classNames(
                                  'px-2 py-1.5 text-sm space-x-1 bg-green-100 text-green-600 rounded-full',
                                )}
                              >
                                <FontAwesomeIcon icon={faCheck} size='lg' />{' '}
                                Agreement Signed
                              </span>
                            </label>
                          </ZenDataRow>
                        </DataTable>
                      ) : (
                        <DataTable>
                          <ZenDataRow
                            name='Status'
                            customValueStyle='px-4 py-4 w-[30%]'
                            customNameStyle='px-4 py-4 w-[70%]'
                          >
                            <label>
                              <span
                                className={classNames(
                                  'px-2 py-1.5 text-sm space-x-1 bg-zen-warning-light text-zen-warning-dark font-semibold rounded-full',
                                )}
                              >
                                <FontAwesomeIcon
                                  icon={regular('hourglass-half')}
                                />{' '}
                                Signature Pending
                              </span>
                            </label>
                          </ZenDataRow>
                        </DataTable>
                      )}
                    </ZenCard>
                  </div>
                </div>
                <div>
                  <div className='ml-4 mt-4'>
                    <p>Onboarding Progress</p>
                    <div className='mt-4 mb-6'>
                      <ZenApplicationStepsProgress
                        steps={stepsWithAction}
                        inProgressIndex={getIndexFromStatus(
                          application.nextStatus!,
                        )}
                      />
                    </div>
                  </div>
                </div>
              </ZenCard>
            </div>
          </div>
          <div className='flex-shrink-0'>
            <ZenCard title='Notes'>
              <ZenTransactionNotesContainer
                entityId={id}
                entityType={CreateNoteRequestEntityTypeEnum.Application}
              />
            </ZenCard>
          </div>
        </div>
      </div>
      {openApplicationEditFormSidebarModal && (
        <ZenApplicationEditForm
          isOpen
          onClose={() => setOpenApplicationEditFormSidebarModal(false)}
          application={application}
        />
      )}

      <ZenApplicationApproveModal
        isOpen={openApproveModal}
        onClose={() => setOpenApproveModal(false)}
        application={application}
        agentName={name}
        teamId={
          application.teamInvitation?.teamId ||
          application.genericTeamInvitation?.teamId
        }
      />

      <ZenConfirmationModal
        isOpen={openRejectModal}
        variant='danger'
        title='Reject Application'
        subtitle={`Are you sure you want to reject application of ${name}?`}
        onClose={() => {
          setOpenRejectModal(false);
        }}
        onConfirm={() => {
          onApplicationReject();
        }}
        cancelButtonText='Cancel'
        confirmButtonText='Reject'
      />
      <ZenConfirmationModal
        isOpen={openTransferLicenseModal}
        variant='primary'
        title='License Transfer'
        subtitle='Are you sure you want to mark license transfer as completed?'
        onClose={() => setOpenTransferLicenseModal(false)}
        onConfirm={() => {
          dispatch(agentLicenseTransferred(application.id!));
          setOpenTransferLicenseModal(false);
        }}
        cancelButtonText='Cancel'
        confirmButtonText='Mark as Complete'
      />
      <ZenConfirmationModal
        isOpen={openJoinBoardModal}
        variant='primary'
        title='Join Board'
        subtitle='Are you sure you want to mark join the boards as completed?'
        onClose={() => {
          setOpenJoinBoardModal(false);
        }}
        onConfirm={() => {
          dispatch(agentJoinedBoard(application.id!));
          setOpenJoinBoardModal(false);
        }}
        cancelButtonText='Cancel'
        confirmButtonText='Mark as Complete'
      />
      <ZenApplicationWaiveFeeModal
        id={application.id!}
        isOpen={showWaiveFeeModal}
        onClose={() => setShowWaiveFeeModal(false)}
      />
    </div>
  );
};

export default ZenApplicationFormContent;
