import { isEmpty } from 'lodash';
import React, { ReactElement } from 'react';

interface ListLabelWithValueProps {
  label: string;
  value?: ReactElement | ReactElement[] | string[] | string;
  isExpanded?: boolean;
}

const ListLabelWithValue: React.FC<ListLabelWithValueProps> = ({
  label,
  value = 'N/A',
  isExpanded = false,
}) => {
  const getDisplayValue = (
    value: ReactElement | ReactElement[] | string[] | string,
  ) => {
    if (isEmpty(value) || typeof value === 'string') {
      return value || 'N/A';
    } else if (Array.isArray(value)) {
      return isExpanded ? expandedValue(value) : collapsedValue(value);
    } else {
      return value;
    }
  };

  const collapsedValue = (value: ReactElement[] | string[]) => (
    <div className='flex items-center'>
      {value[0]}
      {value.length > 1 && (
        <span className='font-medium left-5 ml-1'>(+{value.length - 1})</span>
      )}
    </div>
  );

  const expandedValue = (value: ReactElement[] | string[]) => (
    <div className='flex flex-col space-y-1.5'>
      {value?.map((v, i) =>
        typeof v === 'string' ? <span key={[v, i].join('.')}>{v}</span> : v,
      )}
    </div>
  );

  return (
    <div className='flex flex-col font-zen-body-2'>
      <span className='text-xs font-medium text-regent-600 uppercase'>
        {label}
      </span>
      <div className='text-sm leading-[18px] font-light text-primary-dark mt-1.5'>
        {getDisplayValue(value)}
      </div>
    </div>
  );
};

export default ListLabelWithValue;
