import React, { useState } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { cn } from '../utils/classUtils';

interface HoverItemProps {
  onClick(): void;
  label: string;
  icon: JSX.Element;
  disabled?: boolean;
}

const HoverItem: React.FC<HoverItemProps> = ({
  onClick,
  label,
  icon,
  disabled,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  return (
    <div className=''>
      {' '}
      <Popover
        isOpen={isPopoverOpen}
        positions={['top']} // preferred positions by priority
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor='white'
            arrowSize={10}
            arrowStyle={{
              bottom: 1,
              zIndex: 1,
            }}
            className='popover-arrow-container'
            arrowClassName='popover-arrow'
          >
            <div className=' border rounded-[4px] shadow-2xl border-[#D9D9D9] text-zen-dark-12 font-zen-body font-semibold bg-white px-2 py-1.5'>
              {label}
            </div>
          </ArrowContainer>
        )}
      >
        <button
          onClick={onClick}
          disabled={disabled}
          data-testid='hover-icon'
          onMouseEnter={() => setIsPopoverOpen(true)}
          onMouseLeave={() => setIsPopoverOpen(false)}
          className={cn('rounded-full px-2 py-2', {
            'bg-zen-light-gray-2 hover:bg-zen-dark-4': !disabled,
            'opacity-60': disabled,
          })}
        >
          {icon}
        </button>
      </Popover>
    </div>
  );
};

export default HoverItem;
