import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CircleSplitChart from '../CircleSplitChart';
import {
  getRevShareInsightsEarnedPercentage,
  getRevShareTierTotalActiveContribution,
  revShareInsightsContributionsAnalyticsMap,
  revShareInsightsContributorsAnalyticsMap,
  revShareInsightsFilterAnalyticsMap,
  RevShareInsightsFilterEnum,
} from '../../utils/RevShareInsightsUtils';
import { displayAmount } from '../../utils/CurrencyUtils';
import DefaultLoader from '../DefaultLoader';
import { RootState } from '../../types';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import AnalyticsService from '../../services/AnalyticsService';
import { cn } from '../../utils/classUtils';
import RevShareInsightsDateFilters from './RevShareInsightsDateFilters';
import RevShareInsightsContributionTable, {
  ContributionColumnData,
} from './RevShareInsightsContributionTable';

const colorScale = ['#0BC3F9', '#459774', '#EA8039', '#F84C6C', '#5F2EEA'];
const pieChartDataEmpty = [{ x: 0, y: 100, fill: '#D9D8D8' }];
const tierColorMap: Record<number, string> = {
  1: 'bg-primary text-primary',
  2: 'bg-green-600 text-green-600',
  3: 'bg-zen-warning-3 text-zen-warning-3',
  4: 'bg-zen-danger text-zen-danger',
  5: 'bg-zen-purple-2 text-zen-purple-2',
};

interface RevShareInsightsContributionPerTierProps {
  agentId: string;
  onClickTier(tier: number): void;
  onChangeFilter(filter: RevShareInsightsFilterEnum): Promise<void>;
  onClickTierAgents(tier: number): void;
}

const RevShareInsightsContributionPerTier: React.FC<RevShareInsightsContributionPerTierProps> = ({
  agentId,
  onClickTier,
  onChangeFilter,
  onClickTierAgents,
}) => {
  const {
    agentDetail: { incomeOverviewResponse },
    network: { zenNetworksById },
    revenue: { networkSizeByTierResponse, revShareByTierResponse },
  } = useSelector((state: RootState) => state);

  const frontLineInfo = zenNetworksById[agentId];
  const currentNetworkSizeByTier = networkSizeByTierResponse.data;

  const revShareByTier = useMemo(
    () => revShareByTierResponse.data?.tierRevshareResponses || [],
    [revShareByTierResponse.data?.tierRevshareResponses],
  );

  const pieChartData = useMemo(
    () =>
      revShareByTier.map((curr, index) => {
        const amount =
          curr?.earnedRevshareAmount?.amount ||
          curr?.missedRevshareAmount?.amount ||
          0;

        return {
          x: 0,
          y: amount,
          fill:
            curr?.earnedRevshareAmount?.amount === 0
              ? '#D9D8D8'
              : colorScale[index],
        };
      }),
    [revShareByTier],
  );

  const [splitChartData, setSplitChartData] = useState(
    pieChartData.map(() => pieChartDataEmpty[0]),
  );

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(RevShareInsightsFilterEnum.month);

  useEffect(() => {
    setSplitChartData(pieChartData);
    setLoading(false);
  }, [pieChartData]);

  useDidUpdateEffect(() => {
    setLoading(true);
    AnalyticsService.instance().logEvent(
      revShareInsightsFilterAnalyticsMap[filter],
    );
    onChangeFilter(filter).finally(() => setLoading(false));
  }, [filter, onChangeFilter]);

  const isPieChartEmpty = useMemo(() => pieChartData.every((c) => c.y === 0), [
    pieChartData,
  ]);
  const totalContributions = getRevShareTierTotalActiveContribution(
    revShareByTier,
  );

  const tableData: ContributionColumnData[] = useMemo(
    () =>
      (currentNetworkSizeByTier?.tierNetworkSizes || []).map(
        (networkTier, index) => {
          const unlocked =
            networkTier?.tier! <=
            incomeOverviewResponse.data?.revShareStatus?.unlockedRevShareTier!;

          return {
            isTierUnlocked: unlocked,
            networkTier: networkTier,
            revShareByTier: revShareByTier[index],
            onClickTier: () => {
              AnalyticsService.instance().logEvent(
                revShareInsightsContributionsAnalyticsMap[networkTier?.tier!],
              );
              onClickTier(networkTier.tier!);
            },
            totalContribution: totalContributions,
            onClickTierAgents: () => {
              AnalyticsService.instance().logEvent(
                revShareInsightsContributorsAnalyticsMap[networkTier?.tier!],
              );
              onClickTierAgents(networkTier.tier!);
            },
          };
        },
      ),
    [
      currentNetworkSizeByTier,
      incomeOverviewResponse.data?.revShareStatus?.unlockedRevShareTier,
      onClickTier,
      onClickTierAgents,
      revShareByTier,
      totalContributions,
    ],
  );

  return (
    <div className='rounded-[10px] border border-zen-dark-4 overflow-hidden'>
      <p className='px-4 py-2.5 bg-grey-100 font-zen-body text-zen-dark-9 font-medium'>
        Contributions per Tier
      </p>
      <div className='mt-3 w-full flex justify-center'>
        <RevShareInsightsDateFilters
          value={filter}
          loading={loading}
          onChange={setFilter}
        />
      </div>
      <div className='pt-6'>
        <div className='w-full flex flex-col items-center'>
          <div className='font-zen-body'>
            {isPieChartEmpty ? (
              <div className='w-full max-w-xs text-base font-normal text-zen-dark-9'>
                <p className='font-semibold'>Oops!</p>
                <p className='leading-tight'>
                  Looks like there&apos;s nothing to show right now
                </p>
                <p className='mt-1 text-sm text-zen-dark-6'>
                  Every great network starts with a single agent - you. As you
                  refer more agents, this chart will come alive with
                  contributions!
                </p>
              </div>
            ) : (
              <div className='md:-ml-8 leading-tight'>
                <p className='text-zen-dark-7'>Total Contribution</p>
                <p className='text-center text-zen-dark-9 text-2xl font-semibold'>
                  {displayAmount(totalContributions, {
                    hideZeroCents: true,
                  })}
                </p>
              </div>
            )}
          </div>
          <div className='relative -mt-2.5 md:-ml-8'>
            {loading && (
              <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                <DefaultLoader />
              </div>
            )}
            <div
              className={cn({
                'opacity-50 blur-[1px]': loading,
              })}
            >
              <CircleSplitChart
                data={isPieChartEmpty ? pieChartDataEmpty : splitChartData}
                width={320}
                xKey='x'
                yKey='y'
                innerRadius={30}
                padAngle={0}
                animate={{ duration: 1500 }}
              />
            </div>
          </div>
          <div className='-mt-4 flex items-center space-x-1 md:space-x-2'>
            {revShareByTier?.map((value) => {
              const unlocked =
                value?.tier! <=
                (frontLineInfo?.tierUnlocked ||
                  incomeOverviewResponse.data?.revShareStatus
                    ?.unlockedRevShareTier ||
                  0);

              return (
                <p
                  key={value?.tier}
                  aria-label={`tier${value?.tier}-percentage-pill`}
                  className={cn(
                    'px-3 py-1 rounded-full text-sm font-zen-body font-semibold bg-opacity-5 text-center',
                    unlocked
                      ? tierColorMap[value?.tier!]
                      : 'bg-zen-dark-7 text-zen-dark-7',
                  )}
                >
                  {getRevShareInsightsEarnedPercentage(
                    value?.earnedRevshareAmount,
                    totalContributions,
                  )}
                  %
                </p>
              );
            })}
          </div>
        </div>
      </div>
      <div className='mt-4'>
        <RevShareInsightsContributionTable data={tableData} />
      </div>
    </div>
  );
};

export default RevShareInsightsContributionPerTier;
