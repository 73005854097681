import { sum } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form-v7';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import {
  MoneyValue,
  ParticipantPaymentValue,
  PercentageValue,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { toggleAddCommissionParticipant } from '../../../slices/QuickActionSlice';
import { updateCommissionPayouts } from '../../../slices/TransactionSlice';
import { calculateCommissionSplitErrors } from '../../../utils/CommissionHelper';
import { displayAmount, parseMoney } from '../../../utils/CurrencyUtils';
import Logger from '../../../utils/Logger';
import { isStringEmpty } from '../../../utils/StringUtils';
import ZenButton from '../ZenButton';
import ZenSidebarModalForm from '../ZenSidebarModalForm';
import ZenCommissionSplitContent from './ZenCommissionSplitContent';

interface CommissionSplitFormProps {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
}

interface ParticipantCommissionSplit {
  percent?: PercentageValue;
  money?: MoneyValue;
  isDollar: boolean;
  id: string;
}

interface IFormValues {
  commissionParticipant: ParticipantCommissionSplit[];
}

const ZenCommissionSplitForm: React.FC<CommissionSplitFormProps> = ({
  isOpen,
  onClose,
  transaction,
}) => {
  const dispatch = useDispatch();
  const totalCommission = transaction.grossCommission?.amount!;
  const propertyName = transaction.address?.oneLine!;
  const participants = transaction?.possiblePayableParticipants!;
  const defaultValues: any = useMemo<IFormValues>(
    () => ({
      commissionParticipant:
        participants.map((participant) => {
          return {
            percent: {
              value: participant.payment?.percent
                ? parseFloat((participant.payment?.percent! * 100).toFixed(2))
                : 0,
              string: `${((participant.payment?.percent || 0)! * 100).toFixed(
                2,
              )}%`,
            },
            money: {
              amount: parseMoney(participant.payment?.amount?.amount! || 0),
              currency: participant.payment?.amount?.currency,
            },

            isDollar: !!participant.payment?.amount,
            id: participant.id!,
          };
        }) || [],
    }),
    [participants],
  );
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<any>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { fields } = useFieldArray({
    control,
    name: 'commissionParticipant',
  });
  const watchParticipants = watch('commissionParticipant', []);
  const totalPercentage = sum(
    watchParticipants
      .filter((p: any) => !p.isDollar)
      .map((item: any) => +item.percent?.value || 0) || 0,
  );
  const totalAmount = parseMoney(
    sum(
      watchParticipants
        .filter((p: ParticipantCommissionSplit) => p.isDollar)
        .map((item: ParticipantCommissionSplit) => +item.money?.amount! || 0),
    ),
  );

  const isPercentageTypeSplitAvailable = !!watchParticipants.find(
    (p: ParticipantCommissionSplit) => !p.isDollar,
  );

  const commissionSplitErrors = calculateCommissionSplitErrors(
    {
      amount: totalCommission,
      currency: transaction.grossCommission?.currency,
    },
    totalPercentage,
    totalAmount,
    errors,
    !!transaction.zeroCommissionDeal,
    isPercentageTypeSplitAvailable,
  );

  useEffect(() => {
    // This looks the same as using reset, however, calling reset was causing issues where the percentages/amounts were getting assigned incorrectly to the participants.
    setValue('commissionParticipant', defaultValues?.commissionParticipant);
  }, [defaultValues, setValue]);

  const onSubmit = async (data: any) => {
    let finalData: Array<ParticipantPaymentValue> = data.commissionParticipant?.map(
      (item: any) => {
        let moneyValue: MoneyValue | undefined = undefined;
        let percentageValue: PercentageValue | undefined = undefined;

        if (!item.isDollar && !isStringEmpty(item.percent.value)) {
          percentageValue = {
            value: item.percent.value,
            string: `${item.percent.value}%`,
          };
        } else if (!isStringEmpty(item.money.amount)) {
          moneyValue = {
            amount: item.money.amount,
            currency: item.money.currency,
          };
        } else {
          Logger.debug(
            'Both percentage and amount are null. Skipping over.',
            item,
          );
          return null;
        }

        const participantPaymentValue: ParticipantPaymentValue = {
          paymentValue: {
            money: moneyValue,
            percentage: percentageValue,
          },
          participantId: item.id,
        };

        return participantPaymentValue;
      },
    );

    finalData = finalData.filter((d) => d !== null);
    await dispatch(
      updateCommissionPayouts(transaction.id!, { payments: finalData }),
    );

    onClose();
  };

  const toggleSwitches = (index: number) => {
    const newParticipants = [...watchParticipants];
    newParticipants[index] = {
      ...newParticipants[index],
      isDollar: watchParticipants[index].isDollar,
      id: watchParticipants[index].id,
    };

    setValue('commissionParticipant', newParticipants, {
      shouldValidate: true,
    });
    setValue(
      `commissionParticipant[${index}].percent`,
      {
        value: newParticipants[index]?.percent?.value || 0,
        string: newParticipants[index]?.percent?.string || '0%',
      },
      {
        shouldValidate: true,
      },
    );
    setValue(
      `commissionParticipant[${index}].money`,
      {
        amount: parseMoney(+newParticipants[index]?.money?.amount) || 0,
        currency: transaction.price?.currency,
      },
      {
        shouldValidate: true,
      },
    );
  };

  return (
    <ZenSidebarModalForm
      title={`Total Commission: ${
        transaction.grossCommission
          ? displayAmount(transaction.grossCommission)
          : 'N/A'
      }`}
      subtitle={`Property: ${propertyName}`}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Update'
      isSubmitting={isSubmitting}
      isDisabled={!!commissionSplitErrors.length}
    >
      <div>
        <div className='space-y-6 divide-y'>
          <div className='flex flex-row justify-between items-center px-2 pt-2 md:px-4'>
            <p className='text-lg font-zen-body font-medium text-primary-dark'>
              Participants ({watchParticipants.length})
            </p>
            <ZenButton
              variant='primary-outline'
              label='Add Participant'
              LeftIconComponent={
                <FontAwesomeIcon icon={faPlus} size='sm' className='mr-0.5' />
              }
              onClick={() =>
                dispatch(toggleAddCommissionParticipant({ isOpen: true }))
              }
            />
          </div>
          <ZenCommissionSplitContent
            totalAmount={totalAmount}
            totalPercentage={totalPercentage}
            watchParticipants={watchParticipants}
            commissionSplitErrors={commissionSplitErrors}
            control={control}
            currency={transaction.price?.currency!}
            errors={errors}
            fields={fields}
            toggleSwitches={toggleSwitches}
            participants={participants}
            totalCommission={totalCommission}
          />
        </div>
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenCommissionSplitForm;
