import { last } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FrontLineAgentResponse } from '../../openapi/arrakis';
import { fetchZenFrontLineForAgent } from '../../slices/NetworkSlice';
import { RootState } from '../../types';
import { getSortedNetworks } from '../../utils/AgentHelper';
import DetailPageLoader from '../DetailPageLoader';
import ResourceContainer from '../ResourceContainer';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import BreadCrumbsWithActions from './BreadCrumbsWithActions';
import ZenNetworkHeader from './ZenNetworkHeader';
import ZenNetworkRow from './ZenNetworkRow';

interface ZenNetworkProps {
  isOpen: boolean;
  onClose(): void;
  selectAgents: FrontLineAgentResponse[];
}

const ZenNetwork: React.FC<ZenNetworkProps> = ({
  isOpen,
  onClose,
  selectAgents,
}) => {
  const dispatch = useDispatch();
  const {
    auth: { isAdmin },
  } = useSelector((state: RootState) => state);

  const { zenNetworksById, zenLoading, zenFetchErrorCode } = useSelector(
    (state: RootState) => state.network,
  );
  const [tier, setTier] = useState<number>(selectAgents.length);
  const [currentAgent, setCurrentAgent] = useState<FrontLineAgentResponse>(
    last(selectAgents)!,
  );
  const [agents, setAgents] = useState<FrontLineAgentResponse[]>([
    ...selectAgents,
  ]);

  useEffect(() => {
    dispatch(fetchZenFrontLineForAgent(currentAgent?.id!));
  }, [currentAgent.id, dispatch]);

  return (
    <ZenSidebarModal
      title='My Network'
      subtitle='List of people in your network'
      isOpen={isOpen}
      onClose={onClose}
    >
      <BreadCrumbsWithActions
        paths={[
          {
            title: 'My Network',
          },
          ...agents.map((agent: FrontLineAgentResponse, i: number) => ({
            title: `${agent.firstName} ${agent.lastName}`,
            onClick: () => {
              setCurrentAgent(agent);
              setTier(i + 1);
              setAgents(agents.slice(0, i + 1));
            },
          })),
        ]}
      />
      <ZenNetworkHeader
        name={`${currentAgent.firstName} ${currentAgent.lastName}`}
        status={currentAgent?.status!}
        imageUrl={currentAgent.avatar!}
        tier={tier}
      />
      <ResourceContainer
        loading={zenLoading}
        isEmpty={!zenNetworksById[currentAgent?.id!]?.frontLineAgents?.length}
        errorCode={zenFetchErrorCode}
        LoaderComponent={<DetailPageLoader />}
        resourceName='sponsor agent'
        emptyMessage={`${currentAgent?.firstName} ${currentAgent?.lastName} has not referred any other agents.`}
      >
        <div className='divide-y divide-gray-200'>
          {getSortedNetworks(
            zenNetworksById[currentAgent?.id!]?.frontLineAgents || [],
          )?.map((agent) => (
            <div key={agent.id} className='px-4'>
              <ZenNetworkRow
                id={agent.id!}
                name={`${agent?.firstName} ${agent?.lastName}`}
                imageUrl={agent?.avatar!}
                tier={tier}
                sizeOfNetwork={agent?.sizeOfNetwork!}
                email={agent?.emailAddress!}
                phone={agent?.phoneNumber!}
                sponsorSplit={agent?.sponsorSplit!}
                unlocking={agent?.unlocking!}
                expiryDate={agent?.unlockingExpiryDate!}
                status={agent?.status!}
                openAgentProfile={isAdmin}
                onClick={() => {
                  setCurrentAgent(agent);
                  setTier(tier + 1);
                  setAgents([...agents, agent]);
                }}
              />
            </div>
          ))}
        </div>
      </ResourceContainer>
    </ZenSidebarModal>
  );
};

export default ZenNetwork;
