import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

export interface MortgageScrollToTopProps {}

const MortgageScrollToTop: React.FC<MortgageScrollToTopProps> = () => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    document
      .getElementById('main-page')
      ?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const element = document.getElementById('main-page');

    const listener = () => {
      if (element) {
        const scrolledToBottom =
          element.scrollTop >=
          (element.scrollHeight - element.clientHeight) * 0.6; // show after 60% of the page is scrolled
        setShowButton(scrolledToBottom);
      }
    };

    if (element) {
      element.addEventListener('scroll', listener);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', listener);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [1]);

  if (!showButton) {
    return <span />;
  }

  return (
    <div className='fixed bottom-4 right-4'>
      <button
        className='inline-flex items-center justify-center w-16 h-16  shrink-0 bg-primary-blue rounded-full'
        onClick={scrollToTop}
      >
        <FontAwesomeIcon icon={solid('up')} className='text-white text-3xl' />
      </button>
    </div>
  );
};

export default MortgageScrollToTop;
