import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { JointVentureResponse } from '../../openapi/avalon';
import TierProgressBar from './TierProgressBar';
import TiersListCard from './TiersListCard';

const NoTierColorCode = '#3B82F6';
export interface JointVentureCardProps {
  jointVentureDetails: JointVentureResponse;
}

const JointVentureCard: React.FC<JointVentureCardProps> = ({
  jointVentureDetails,
}) => {
  const noTier = jointVentureDetails.tiers!.some((tier) => !tier.name);

  return (
    <div className='rounded-lg border border-zen-dark-4 bg-white overflow-hidden'>
      <div className='flex flex-row items-center justify-between px-5 py-4 bg-grey-100'>
        <span className='font-zen-title font-medium text-xl leading-5 text-zen-dark-9'>
          {jointVentureDetails.name}
        </span>
        <div className='flex flex-row space-x-5 text-base leading-8 font-medium text-zen-dark-9 items-center'>
          <div className='flex flex-row items-center'>
            <FontAwesomeIcon
              icon={faUser}
              size='lg'
              className='text-zen-dark-9 font-extrabold text-sm mr-1 leading-4'
            />
            <span>{`${
              jointVentureDetails.occupancy?.occupied
            } / ${jointVentureDetails.occupancy?.capacity!}`}</span>
          </div>

          <span>{jointVentureDetails.occupancy?.utilization}%</span>
        </div>
      </div>
      <div className='p-5 w-full'>
        {noTier ? (
          <TierProgressBar
            bookedShares={jointVentureDetails.occupancy?.occupied!}
            totalShares={jointVentureDetails.occupancy?.capacity!}
            utilization={jointVentureDetails.occupancy?.utilization!}
            tierColor={NoTierColorCode}
          />
        ) : (
          <TiersListCard tiers={jointVentureDetails.tiers!} />
        )}
      </div>
    </div>
  );
};

export default JointVentureCard;
