import React, { useCallback, useMemo } from 'react';
import { Control, useFormContext } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { PaginatedPairResponse, PairBase } from '../../openapi/leo';
import {
  LeoTableHeaderFormData,
  TABS_ENUM,
  fetchQueueParams,
} from '../../routes/LeoQuestionManagerRoute';
import {
  saveWorkQueueDateFilterRange,
  saveWorkQueueDateFilterType,
} from '../../slices/LeoSlice';
import { IPaginateRes, RootState } from '../../types';
import { ActionBarButtonsProps } from '../TableRowActionBar';
import { DateFilterEnum, DateRange } from '../Zen/Calendars/LQMDateFilter';
import ZenResourceIndexContainer from '../Zen/Containers/ZenResourceIndexContainer';
import ZenRoute from '../Zen/ZenRoute';
import { CountryAll } from './LQMSidebarModalForm';
import LeoQuestionnaireTableHeader from './LQMTableHeader';

export interface LQMWorkQueueTabProps {
  key: string | number;
  control: Control<LeoTableHeaderFormData, object>;
  columns: Column<PairBase>[];
  actionBarButtons: ActionBarButtonsProps<PairBase>[];
  fetchData(req: fetchQueueParams): Promise<PaginatedPairResponse>;
  searchQueue(req: fetchQueueParams): Promise<PaginatedPairResponse>;
}

const LQMWorkQueueTab: React.FC<LQMWorkQueueTabProps> = ({
  key,
  control,
  columns,
  actionBarButtons,
  fetchData,
  searchQueue,
}) => {
  const {
    leoQuestionManager: {
      workQueue: { dateFilterType, dateRange },
    },
  } = useSelector((state: RootState) => state.leo);
  const dispatch = useDispatch();

  const { watch } = useFormContext<LeoTableHeaderFormData>();
  const [
    workQueueSearch,
    workQueueCountryFilter,
    workQueueStatesFilter,
  ] = watch([
    'workQueueSearch',
    'workQueueCountryFilter',
    'workQueueStatesFilter',
  ]);

  const fetchWorkQueue = useCallback(
    async ({ page, pageSize }): Promise<IPaginateRes<PairBase>> => {
      const api =
        workQueueSearch || workQueueCountryFilter || workQueueStatesFilter
          ? searchQueue
          : fetchData;
      const reqParams: fetchQueueParams = {
        isCompleted: false,
        isWorkQueue: true,
        pageNumber: page + 1,
        pageSize: pageSize,
        startDate: dateRange.start,
        endDate: dateRange.end,
        assigneeId: undefined,
      };

      if (workQueueSearch || workQueueCountryFilter || workQueueStatesFilter) {
        reqParams.searchString = workQueueSearch || '';
        reqParams.country =
          (workQueueCountryFilter === CountryAll.All
            ? undefined
            : workQueueCountryFilter) || undefined;
        reqParams.states = workQueueStatesFilter || undefined;
      }

      const data = await api(reqParams);

      return {
        data: data.data,
        total: data.total_count,
      };
    },
    [
      workQueueSearch,
      searchQueue,
      fetchData,
      dateRange,
      workQueueCountryFilter,
      workQueueStatesFilter,
    ],
  );

  const table = useMemo(
    () => (
      <div className='mt-4'>
        <ZenResourceIndexContainer<PairBase>
          key={key}
          allowSelection
          showSelectionActionBar
          columns={columns.filter(
            (column) => column.accessor !== 'assignee_id',
          )}
          resourceName='work-queue-question'
          fetchData={fetchWorkQueue}
          actionBarButtons={actionBarButtons}
          pageSize={10}
          hideFilters
        />
      </div>
    ),
    [columns, key, actionBarButtons, fetchWorkQueue],
  );

  return (
    <ZenRoute title='Work Queue Questionnaire'>
      <LeoQuestionnaireTableHeader
        tabName={TABS_ENUM.WORK_QUEUE}
        dateFilterLabel={dateFilterType}
        setDateFilterLabel={(dateFilterType: DateFilterEnum) => {
          dispatch(saveWorkQueueDateFilterType(dateFilterType));
        }}
        setDateFilter={(range: DateRange) => {
          dispatch(saveWorkQueueDateFilterRange(range));
        }}
        control={control}
      />
      {table}
    </ZenRoute>
  );
};

export default LQMWorkQueueTab;
