import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Column, Row } from 'react-table';
import ZenTeamStatusCell from '../components/table/Cells/ZenTeamStatusCell';
import ZenViewActionButtonCell from '../components/table/Cells/ZenViewActionButtonCell';
import DateColumnFilter from '../components/table/Filters/DateColumnFilter';
import TeamTypeSelectColumnFilter from '../components/table/Filters/TeamTypeSelectColumnFilter';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import TextStrictCaseColumnFilter from '../components/table/Filters/TextStrictCaseColumnFilter';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenMilliDateCell from '../components/Zen/Table/Cell/ZenMilliDateCell';
import ZenTeamLeaderNameCell from '../components/Zen/Table/Cell/ZenTeamLeaderNameCell';
import ZenCreateTeamForm from '../components/Zen/Teams/ZenCreateTeamForm';
import ZenTeamsCodeCell from '../components/Zen/Teams/ZenTeamsCodeCell';
import ZenTeamsNameCell from '../components/Zen/Teams/ZenTeamsNameCell';
import ZenTeamsType from '../components/Zen/Teams/ZenTeamsType';
import ZenButton from '../components/Zen/ZenButton';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenRoute from '../components/Zen/ZenRoute';
import { TeamResponse, TeamResponseTypeEnum } from '../openapi/yenta';
import { ActiveInactiveType } from '../types';
import { isSmScreen } from '../utils/BrowserUtils';
import { enumFilter, teamSearchRequest } from '../utils/TableUtils';

export const columns: Array<Column<TeamResponse>> = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ row }) => <ZenTeamsNameCell team={row.original} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => <ZenTeamsType type={value! as TeamResponseTypeEnum} />,
    Filter: TeamTypeSelectColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Created On',
    accessor: 'createdAt',
    Cell: ({ value }) => (
      <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
        <ZenMilliDateCell date={value!} />
      </div>
    ),
    Filter: DateColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Leader',
    accessor: 'updatedAt',
    Cell: ({ row }) => <ZenTeamLeaderNameCell agents={row.original.agents!} />,
    disableFilters: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => <ZenTeamStatusCell status={value!} />,
    disableFilters: true,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ row }) => (
      <ZenTeamsCodeCell team={row.original} variant='primary' />
    ),
    Filter: TextStrictCaseColumnFilter,
  },
];

export const columnsWithAction: Array<Column<TeamResponse>> = [
  {
    Header: '',
    id: 'actions',
    Cell: ({ row }: { row: Row<TeamResponse> }) => (
      <Link to={`/teams/${row.original.id}`}>
        <ZenViewActionButtonCell />
      </Link>
    ),
  },
  ...columns,
];

const ZenTeamsSearchRoute: React.FC = () => {
  const location = useLocation();
  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );
  const [openCreateTeamForm, setOpenCreateTeamForm] = useState<boolean>(false);

  return (
    <ZenRoute title='Teams'>
      <ZenPageLayoutWithSearch
        path={[
          { title: 'Home', url: '/' },
          { title: 'Teams', url: '/teams' },
        ]}
        search={search}
        onSearchChange={setSearch}
      >
        <div className='px-4 lg:py-5'>
          <ZenResourceIndexContainer<TeamResponse>
            header='Teams'
            columns={columnsWithAction}
            search={search}
            resourceName='team'
            initialSort={{ name: 'asc' }}
            RightComponent={
              <ZenButton
                LeftIconComponent={<FontAwesomeIcon icon={faPlus} />}
                label='Create a Team'
                variant='primary'
                onClick={() => setOpenCreateTeamForm(true)}
                className='whitespace-nowrap'
              />
            }
            tabs={[
              {
                name: 'Active',
                filter: {
                  status: enumFilter('active', ActiveInactiveType.ACTIVE),
                },
              },
              {
                name: 'Inactive',
                filter: {
                  status: enumFilter('status', ActiveInactiveType.INACTIVE),
                },
              },
            ]}
            fetchData={async (req, cancelToken) => {
              const res = await teamSearchRequest(req, { cancelToken });

              return {
                data: res?.data || [],
                total: res?.total || 0,
              };
            }}
            showSearch={isSmScreen()}
            stickyHeader
          />
        </div>
        {openCreateTeamForm && (
          <ZenCreateTeamForm
            isOpen
            onClose={() => setOpenCreateTeamForm(false)}
          />
        )}
      </ZenPageLayoutWithSearch>
    </ZenRoute>
  );
};

export default ZenTeamsSearchRoute;
