/* eslint-disable react/no-unescaped-entities */
const WalletElectronicCommunications = (
  <div className='font-inter font-medium text-base h-48 overflow-auto pr-10 w-[96%]'>
    <p className='font-inter font-light text-sm'>
      In this agreement (“Agreement”), "we," "us," "our," and "REAL" mean Real
      Broker Financial, LLC and its affiliates, service providers and bank
      partners, including, but not limited to, Thread Bank
      (“Bank”). "You" and "your" refer to you and/or the business entity signing
      up for a REAL-branded account or card issued by Bank, or using a related
      REAL product.  "Communications" means disclosures, notices, agreements,
      fee schedules, privacy policies, statements, records, documents, and other
      information we provide to you, or that you sign and submit or agree to at
      our request. It includes any account agreement, cardholder agreement,
      payment authorization, or related agreement or disclosure we may provide
      to you in connection with any REAL-branded banking service offered by
      Bank.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      REAL is dedicated to providing the best online banking experience
      possible, including providing information to you electronically. By
      accepting this Agreement, you agree that you are willing and able to
      receive Communications in electronic form, and consent to receive
      Communications in electronic form. If you do not give your consent to
      receive Communications in electronic and not paper form, you may not open
      an account. If you withdraw your consent to electronic Communications at
      any point after providing consent hereunder, we reserve the right to
      terminate your account.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>Delivery Methods</p>
    <p className='font-inter font-light text-sm mt-4'>
      We will deliver Communications to you electronically, either through the
      reZEN website, at{' '}
      <a href='https://bolt.therealbrokerage.com' className='text-blue-600'>
        https://bolt.therealbrokerage.com
      </a>{' '}
      (&quot;reZEN Website&quot;); the Real reZEN mobile app (&quot;App&quot;);
      text or SMS messages (&quot;Text Messages&quot;); or through electronic
      mail (&quot;E-mail&quot;). If we do not deliver Communications to you
      through the above manners, we will tell you where you can go to receive
      such Communications.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We may be required by law to deliver certain Communications to you on
      paper even though you have consented to receive it electronically.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      Hardware and Software Requirements
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      To receive and retain electronic Communications from REAL, you will need
      the following:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        A computer or mobile device with an operating system that supports
        everything below;
      </li>
      <li>An internet connection;</li>
      <li>
        A current version of a web browser that we support, including: Edge
        version 42 or higher, Firefox version 62 or higher, Safari version 12 or
        higher, or Chrome version 69 or higher;
      </li>
      <li>A hard drive or other method of storing data;</li>
      <li>A valid, active email address; and</li>
      <li>A current version of a program that displays PDF files.</li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      We may update these requirements periodically in order to maintain our
      ability to provide electronic Communications. If these requirements change
      in a substantial way, we will notify you of the changes.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      Updating Contact Information
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      It is your responsibility to maintain accurate and current contact
      information so that we may send you electronic Communications. You can
      update your email address and other contact information by logging into
      your online account at the{' '}
      <a href='https://bolt.therealbrokerage.com' className='text-blue-600'>
        reZEN Website
      </a>{' '}
      or by emailing us at{' '}
      <a href='mailto:support@therealbrokerage.com' className='text-blue-600'>
        support@therealbrokerage.com
      </a>
      .
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      Requesting Paper Copies
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      If we choose to make paper copies of certain electronic Communications
      available, we do not create any obligation to do so at other times. With
      respect to Communications concerning any personal (non-business)
      transactions or transactions involving any personal, family, or household
      purpose accounts that we may permit from time to time, you may obtain a
      paper copy of an electronic Communication by printing it or by requesting
      we mail a paper copy. You may make requests for paper copies by emailing
      us at{' '}
      <a href='mailto:support@therealbrokerage.com' className='text-blue-600'>
        support@therealbrokerage.com
      </a>
      .
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      Communications in Writing
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We are required by law to give you certain information in writing. All
      Communications delivered to you in either electronic or paper format will
      be taken as information delivered &quot;in writing.&quot;
    </p>
    <p className='font-inter font-medium text-sm mt-4'>Withdrawing Consent</p>
    <p className='font-inter font-light text-sm mt-4'>
      You have the right to withdraw your consent to this Agreement at any time.
      Withdrawing consent will terminate your REAL-branded account, card, and
      related banking services, including access to the services through the
      reZEN Website and App. A balance refund check may be issued to you upon
      account termination in accordance with this Agreement. You may withdraw
      consent by emailing us at{' '}
      <a href='mailto:support@therealbrokerage.com' className='text-blue-600'>
        support@therealbrokerage.com
      </a>
    </p>
  </div>
);

const WalletTermsOfService = (
  <div className='font-inter font-medium text-base h-48 overflow-auto pr-10 w-[96%]'>
    <p className='font-inter font-light text-sm'>
      Please read this document carefully. It contains very important
      information concerning your rights and obligations, as well as limitations
      and exclusions that may apply to you
    </p>
    <p className='font-inter font-medium text-sm mt-4'>1. Your Acceptance</p>
    <p className='font-inter font-light text-sm mt-4'>
      The Real Brokerage Inc. and its subsidiaries, including, but not limited
      to, Real Broker, LLC; Real Brokerage Technologies, Inc.; Real Broker AZ,
      LLC; Real Broker NY, LLC; Real Broker CT, LLC; Real Broker MA, LLC; Real
      Broker NH, LLC; Real Broker Commercial, LLC; Real Broker NE, LLC; Real
      Broker AB Ltd.; Real Broker B.C. Ltd.; Real Broker Ontario Ltd.; and Real
      Broker Manitoba Ltd. (collectively “REAL”) make its internet accessible
      websites, including www.joinreal.com, www.therealbrokerage.com, and
      www.onereal.com, (“Site”) and related mobile applications or mobile
      accessible websites (“App”), including all information, documents,
      communications, files, text, graphics, and software available through the
      Site and App (collectively, the “Content”) and any products and services,
      including real estate related documents, products, and services, provided
      and operated by REAL through the Site and App (collectively, the
      “Services”), available for your use subject to the terms of use set forth
      in this document, any changes to this document that REAL may publish from
      time to time, and any other relevant terms of use incorporated by REAL,
      including the terms of use applicable to REAL’s electronic signature
      software application, which can be viewed at{' '}
      <a
        href='https://www.joinreal.com/page/legal?tab=terms-of-use#legal-tab'
        className='text-blue-600'
      >
        https://www.joinreal.com/page/legal?tab=terms-of-use#legal-tab
      </a>{' '}
      (collectively, “Agreement” or “Terms of Use”).
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Throughout this Agreement, Site and App, and their related Content and
      Services, are collectively referred to as the “System”, and the words
      “us,” “we,” and “our,” also refer to our companies, The Real Brokerage
      Inc. and its subsidiaries, including, but not limited to, Real Broker,
      LLC; Real Brokerage Technologies, Inc.; Real Broker AZ, LLC; Real Broker
      NY, LLC; Real Broker CT, LLC; Real Broker MA, LLC; Real Broker NH, LLC;
      Real Broker Commercial, LLC; Real Broker NE, LLC; Real Broker AB Ltd.;
      Real Broker B.C. Ltd.; Real Broker Ontario Ltd.; and Real Broker Manitoba
      Ltd., and the System, as is appropriate in the context of the use of the
      words.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      REAL believes in protecting your privacy. Please review our current
      privacy statement (“Privacy Policy”), which can be found at
      https://www.joinreal.com/page/legal, and is incorporated herein by
      reference, to understand and agree to our policies regarding your privacy.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      You may not interfere with the security of or otherwise abuse the System
      or any system resources, Content, and/or Services or networks connected to
      or accessible through the System. You may only use the System for lawful
      purposes.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      BY ACCESSING OR USING THIS SYSTEM, WHETHER OR NOT AS A REGISTERED USER, IN
      ANY WAY, INCLUDING, WITHOUT LIMITATION, USE OF ANY OF THE SERVICES OR
      CONTENT, POSTING/UPLOADING OF ANY CONTENT, DOWNLOADING OF ANY CONTENT, OR
      MERELY BROWSING THE SITE OR APP, YOU REPRESENT THAT YOU ARE OVER 18 YEARS
      OF AGE AND AGREE TO, AND ARE BOUND BY, THE TERMS OF USE AND PRIVACY
      POLICY. YOUR AGREEMENT TO BE BOUND BY THE TERMS OF USE AND PRIVACY POLICY
      IS BETWEEN REAL AND YOU.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      IF THESE TERMS OF USE ARE NOT COMPLETELY ACCEPTABLE TO YOU, YOU MUST
      IMMEDIATELY TERMINATE YOUR USE OF THE SYSTEM.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      This System, including Content and Services, is directed to those
      individuals and entities located in the United States and Canada where
      REAL is offering Content and Services. It is not directed to any person or
      entity in any jurisdiction where (by reason of location, business
      decision, nationality, residence, citizenship or otherwise) REAL is not
      offering Content and Services or where the publication or availability of
      the System and its Content and Services are unavailable or otherwise
      contrary to local laws or regulations. If this exclusion applies to you,
      you are not authorized to access or use any of the information on the
      System. REAL makes no representation that the information, opinions, or
      other Content or Services, whether or not through the System, is correct,
      appropriate, or that the System, Content, or Services are available
      outside of the United States or outside of REAL’s specific operating
      locations. Those who choose to access this System, Content, or Services
      from other locations do so at their own risk and are responsible for
      compliance with applicable laws.{' '}
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      REAL reserves the right to update or modify the Terms of Use at any time
      without prior notice. Your continued use of any portion of the System,
      including any portion of the Site and/or App, whether or not as a
      registered user, following any such change constitutes your agreement to
      follow and be bound by the Terms of Use as changed. For this reason, you
      are advised to review these Terms of Use whenever you use the System. The
      Terms of Use may be found at any time on the Site and App, and REAL will
      provide you with a copy of the Terms of Use upon written request.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>2. Eligibility</p>
    <p className='font-inter font-light text-sm mt-4'>
      In order to use our Service, you must meet a number of conditions,
      including but not limited to:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        You must not be in violation of any embargoes, export controls, or other
        laws of the United States or other countries having jurisdiction over
        this Agreement, REAL, and yourself. For example, if the Office of
        Foreign Assets Control prohibits conducting financial transactions with
        nationals, residents, or banks of your country, you must not use our
        System.
      </li>
      <li>
        You must be the minimum age required to enter into a contract in the
        area in which you reside, and, in any event, must not be less than 18
        years of age.
      </li>
      <li>
        If you are an agent or other real estate professional using our System
        to find buyers or sellers of property or otherwise facilitate real
        estate transactions, you must be licensed as a real estate agent or
        otherwise authorized to act in such as a capacity (e.g., a licensed
        attorney) in the jurisdiction in relation to which you intend to use the
        System.
      </li>
      <li>
        You must provide us with personal information, payment information, and
        other information that we deem necessary to provide you with access to
        our System.
      </li>
    </ul>
    <p className='font-inter font-medium text-sm mt-4'>
      3. Account Registration and Payment
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      You are prohibited from violating or attempting to violate the security of
      the System, including, without limitation, (a) accessing data not intended
      for such user or logging onto a server or an account which the user is not
      authorized to access; or (b) attempting to probe, scan or test the
      vulnerability of a system or network or to breach security or
      authentication measures without proper authorization; or (c) accessing or
      using the System or any portion thereof without authorization, in
      violation of these Terms of Use or in violation of applicable law.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Violations of system or network security may result in civil or criminal
      liability. Company will investigate occurrences that may involve such
      violations and may involve, and cooperate with, law enforcement
      authorities in prosecuting users who are involved in such violations. You
      agree not to use any device, software or routine to interfere or attempt
      to interfere with the proper working of the System or any activity being
      conducted on the System.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      In the event access to the System or a portion thereof is limited
      requiring a user ID and password (“
      <span className='font-medium'>Protected Areas</span>
      ”), you agree to access Protected Areas using only your user ID and
      password as provided to you by Company. You agree to protect the
      confidentiality of your user ID and password, and not to share or disclose
      your user ID or password to any third party. You agree that you are fully
      responsible for all activity occurring under your user ID. Your access to
      the System may be revoked by Company at any time with or without cause.
      You agree to defend, indemnify and hold Company harmless from and against
      all third party claims, damages and expenses (including reasonable
      attorneys’ fees) against or incurred by Company arising out of your breach
      of these Terms of Use or violation of applicable law, your use or access
      of the System, or access by anyone accessing the System using your user ID
      and password.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Depending on Services and Content requested, users of the System may be
      required to set up and maintain, keeping all user information correct and
      current, a unique user account for each specific user (regardless of
      whether that user is an employee or agent of any other user). Such user
      account will require user to provide and keep current a valid and working
      email address, where the user may be contacted. It is your responsibility
      to keep your email address current so that REAL can communicate with you
      electronically. You understand and agree that if we send you a
      communication but you do not receive it because your email address on file
      is incorrect, out of date, blocked by your service provider, or you are
      otherwise unable to receive electronic communications, we will be deemed
      to have provided the communication to you; however, we may deem your
      account inactive. You may not be able to use our Services until we receive
      a valid, working email address from you. You may update your email address
      or other information by logging into your user account (if applicable) or
      by emailing us at support@therealbrokerage.com.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We may collect personal information during the account creation process as
      disclosed by our Privacy Policy. Each and every unique user must agree to
      the Terms of Use and operate the System in accordance with the Terms of
      Use and instructions provided by REAL on the Site and App and as may
      otherwise be required by separate written contract between Real and you or
      the user. You may not: (i) select or use a login name of another person
      with the intent to impersonate that person; (ii) use a name subject to the
      rights of any other person without authorization; or (iii) use a login
      name that REAL, in its sole discretion, deems inappropriate or offensive.
      You shall notify REAL of any known or suspected unauthorized use(s) of
      your account, or any known or suspected breach of security, including
      loss, theft, or unauthorized disclosure of your password. You shall be
      responsible for maintaining the confidentiality of your password. Any
      fraudulent, abusive, or otherwise illegal activity may be grounds for
      termination of your account, at REAL’s sole discretion, and you may be
      reported to appropriate law-enforcement agencies.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      You agree that your use of the System, and any Services or Content
      provided by or through the System, is limited to you alone, and you agree
      to accept full responsibility and indemnify, defend, and hold REAL
      harmless for any liability or use associated with any third party’s use of
      the System, Services, and/or Content under you or on your behalf. You
      agree that your limited license granted by REAL to use the SYSTEM is a
      non-transferable LIMITED license to use the SYSTEM.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Any “Fees” to use portions of the System and its Content and/or Services
      are set by REAL, from time to time, in its sole discretion, and is subject
      to change without notice. Unless otherwise stated, the Fees do not include
      taxes or duties of any kind, and all taxes and duties are responsibility
      of the user.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      You agree to pay to REAL all Fees, as set forth on the System, at the time
      specified on the Site and/or App, or if there is no such time specified,
      all payments for Fees must be paid as directed by REAL, or prior to using
      such portion of the System. If Fees are not paid, Content and Services may
      not be provided or performed. All payments of Fees shall be made in full
      and without any deduction, whether by set-off, counterclaim, discount,
      abatement, or otherwise, unless you have REAL’s prior written approval or
      a valid order from a court having proper jurisdiction. You agree to abide
      by any terms of use applicable to any payment processing services
      available through the System, including terms of use of any third party
      payment processing services.
    </p>
    <p className='font-inter font-light text-sm  mt-4'>
      If you have any issues with your account or wish to cancel your account
      please contact us at support@therealbrokerage.com. After we have been
      notified of your desire to cancel your REAL account, we will verify that
      all transactions and obligations have been completed under your account.
      Once such verification has occurred you will receive a notification from
      us verifying that your account has been cancelled. Please be aware that
      once you initiate the cancellation process, your account may become
      immediately inaccessible.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>4. Our Service</p>
    <p className='font-inter font-light text-sm mt-4'>
      REAL is a real estate broker and a platform that assists in connecting
      users looking to complete a property transaction (“Buyers”) with real
      estate agents or other real estate professionals (“Real Estate Agents”),
      as well as provides Content and Services to support and facilitate a
      property transaction. REAL does not endorse or otherwise verify the
      reliability of any Real Estate Agents that are not affiliated with our
      brokerage. Users agree to assume all liability for the use of our System
      and agree to hold REAL harmless for any losses arising from or relating to
      your use of our System, whether as a Buyer, Real Estate Agent, or
      otherwise. No fiduciary relationship, agency, joint venture or partnership
      is created between any users of the System and REAL. You agree that REAL
      is not a party to any contracts entered between you and any other third
      parties encountered through our System or otherwise.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      Modification of System
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We reserve the right to alter, update, or remove our System at any time.
      We may conduct such modifications to our System for security reasons,
      intellectual property or other legal reasons, or various other reasons at
      our discretion, and we are not required to explain such modifications. For
      example, we may provide updates to fix security flaws, or respond to legal
      demands. Please note that this is a non-binding illustration of how we
      might exercise our rights under this section, and nothing in this section
      obligates us to take measures to update the System for security, legal or
      other purposes.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      5. Our License Grant to You
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We make our Content and Services available to you through our System. When
      you use our System, we grant you a, personal, non-exclusive, revocable,
      limited license to use our System, including accessing our Site and App.
      This means you may not resell our System, including any Content or
      Services, anywhere else, share your license to use our System with anyone
      else, reverse engineer, decompile, modify or otherwise attempt to copy our
      System.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      This license may be terminated if you violate any provisions listed in
      this Agreement or our Privacy Policy. Additionally, this license may be
      terminated if you are engaged in any activities that we feel may damage
      the rights of REAL or if your activities are in violation of any
      applicable laws. If you wish to terminate this license please simply stop
      using our System or notify us and cancel your account.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>6. Use of REAL</p>
    <p className='font-inter font-light text-sm mt-4'>
      You are responsible for your use of the System, and for any use of the
      System made using your account. Specifically, you agree that:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        You will not copy, distribute or disclose any part of the System in any
        medium, including without limitation by any automated or non-automated
        “scraping”;
      </li>
      <li>
        If you are a Real Estate Agent you will comply with any state laws and
        state regulations, and your use of our System will not violate any laws,
        rules, or obligations that you may have with any state or any other
        third party;
      </li>
      <li>
        Unless you are a Real Estate Agent, you are using this System for
        non-commercial and personal reasons;
      </li>
      <li>
        You are solely responsible for your use of the System, including any
        results or outcomes from your use of the System, whether or not in
        relation to a real estate transaction;
      </li>
      <li>
        Your use of our System does not violate any prior obligation, contract,
        or agreement that you may have with a third party.
      </li>
      <li>
        You will not attempt to interfere with, compromise the system integrity
        or security, or decipher any transmissions to or from the servers
        running the System;
      </li>
      <li>
        You will not take any action that imposes, or may impose at our sole
        discretion, an unreasonable or disproportionately large load on our
        infrastructure;
      </li>
      <li>
        You will not collect or harvest any personally identifiable information,
        including account names, from the Service;
      </li>
      <li>
        You agree not to stalk, harass, bully or harm another individual who
        uses our System;
      </li>
      <li>
        You agree not to impersonate any person or entity or misrepresent your
        affiliation with a person or entity;
      </li>
      <li>
        You agree that you will not hold REAL responsible or liable for your use
        of our System, including your use of any Content or Services to
        facilitate a real estate transaction;
      </li>
      <li>
        You agree not to violate any requirements, procedures, policies or
        regulations of networks connected to the System or REAL;
      </li>
      <li>You agree not to interfere with or disrupt the System;</li>
      <li>
        You agree not to hack, spam or phish us or other users of the System;
      </li>
      <li>You agree to provide truthful and accurate content;</li>
      <li>
        You agree to not violate any law or regulation and you are responsible
        for such violations;
      </li>
      <li>
        You will not use our System to post any false, misleading, unlawful,
        defamatory, obscene, invasive, threatening, harassing, inflammatory,
        fraudulent content; ;
      </li>
      <li>
        You agree not to cause, or aid in, the destruction, manipulation,
        removal, disabling, or impairment of any portion of our System,
        including the de-indexing or de-caching of any portion of our Site from
        a third party’s website, such as by requesting its removal from a search
        engine;
      </li>
      <li>
        You will not upload any content to our System that includes any third
        party intellectual property unless you have permission from the owner to
        use such content.
      </li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      If you are discovered to be undertaking any of the aforementioned actions
      your privileges to use our System may at our discretion be terminated or
      suspended. We may also suspend or terminate your access to REAL if we feel
      that any of your activities through our System may harm the rights of
      REAL. Generally, we will provide an explanation for any suspension or
      termination of your use of any of our System, but REAL reserves the right
      to suspend or terminate any account at any time without notice or
      explanation.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      7. Your Content on the System
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      When submitting any Content, please ensure that such Content is accurate.
      We are not required to host, display, utilize, or distribute any of your
      Content or posts and we may refuse to accept or transmit any Content or
      delete your Content from the System at any time. You understand that when
      using the System you may be exposed to Content from a variety of sources,
      and that REAL is not responsible for the accuracy, validity, or usefulness
      of Content created by others. You agree that you are solely responsible
      for any Content submitted and that we cannot guarantee the absolute safety
      and security of any such information.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      You further understand and acknowledge that you may be exposed to Content
      that is inaccurate, offensive, indecent, or objectionable, and you agree
      to waive, and hereby do waive, any legal or equitable rights or remedies
      you have or may have against REAL with respect thereto, and agree to
      indemnify and hold REAL, its owners/operators, affiliates, and/or
      licensors, harmless to the fullest extent allowed by law regarding all
      matters related to your use of the System.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      When you submit any Content to us, you grant REAL and its affiliates,
      users, representatives and assigns a non-exclusive, fully-paid,
      royalty-free, irrevocable, world-wide, universal, transferable license to
      display, publicly perform, distribute, store, broadcast, transmit,
      reproduce, modify, prepare derivative works and otherwise use and reuse
      all or part of your Content and anything we may make with your Content
      through REAL or any other medium currently invented or invented in the
      future. We reserve the right to display advertisements in connection with
      your Content, and to use your Content to advertise and promote REAL.
      Additionally, although you own all Content submitted by you, we own all
      layouts, arrangement, metadata and images that are used to render your
      Content through our System.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      By submitting any Content to our System, you hereby represent and warrant
      that you own all rights to your Content or, alternatively, that you have
      the right to give us the license described above. You also promise that
      you have paid and will pay in full all fees or other payments related to
      the use of your post or submission of any Content. Finally, you agree that
      your submitted post or Content does not infringe on the intellectual
      property rights, privacy rights, publicity rights, or other legal rights
      of any third party.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Beneficiary Information and Emergency Contacts. In connection with certain
      REAL Services or programs, such as the willable revenue share program, you
      may have the ability to designate a beneficiary, designate an emergency
      contact, or the like. If you provide information for a beneficiary,
      emergency contact, or the like, you represent and warrant to us that you
      have the legal right and authorization, through consent or otherwise, to
      provide the such personal information to us.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      8. Public Display of Content
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      When submitting any Content to us, you understand that this Content may be
      viewable by the public or other users. It is recommended that Content that
      you submit to our System be tasteful and up to community standards. You
      agree that we at our sole discretion may remove any Content that we
      determine is not up to community standards.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      9. Consent for Electronic Transactions
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      REAL may offer Services that facilitate electronic transactions, including
      Services that allow users to electronically sign and verify documents.
      Such Services are compliant with the Electronic Signatures in Global and
      National Commerce Act (the “E-SIGN Act”), which facilitates the use of
      electronic signatures, communications, and records in interstate and
      foreign commerce by ensuring the validity and legal effect of contracts
      entered into electronically.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      By using REAL’s E-SIGN Act compliant electronic signature Services, you
      are consenting and agreeing (i) to receive notices and disclosures in a
      reasonably accessible manner from REAL in electronic form, (ii) use REAL’s
      electronic signature Services on the documents you elect to execute or
      otherwise process through REAL’s electronic signature Services, (iii) to
      conduct proposed transactions electronically, with the use of electronic
      signatures, including electronic initials, for the documents to be
      executed, (iv) that electronic signatures and initials contained on these
      documents are equivalent to hand written signatures and initials for
      purposes of evaluating validity, enforceability, and admissibility of such
      documents, and (v) that your signatures and initials are accurate and will
      constitute an electronic, valid, and binding representation of your
      signature and initials for the purpose of legal documents and contracts.
      By delivering to REAL documents to be executed, you are authorizing REAL
      to imprint thereon your signature and that of other parties who have
      provided us with their consent, and to distribute copies of the executed
      version to all parties.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      REAL will initiate electronic signature transactions through its Services
      by requiring you to verify your identity, including by registering an
      account with REAL, responding to an email invitation from REAL, providing
      identifying documentation to REAL, obtaining your IP address, or otherwise
      using reasonable methods to verify your identity. REAL shall provide you
      the documents for execution by electronic message, such as an email, upon
      which to proceed you must confirm receipt of the electronic message and
      acknowledge that you are able to download, save, and print electronic
      documents. Your consent to conduct transactions electronically, including
      by using REAL’s electronic signature Services, applies to all documents
      that you receive or transact using such Services until such time as you
      withdraw your consent.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      You may withdraw your consent to receive Records under this Notice by
      emailing us at support@therealbrokerage.com or writing us at 39899
      Balentine Dr, Suite 200, Newark, CA 94560. Your withdrawal will be
      effective within twenty (24) business hours from the time we receive your
      withdrawal notice. Any withdrawal request must be made to “Attn: E-Sign
      Consent Withdrawal.”
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      To access and retain electronic records, you must have a valid email
      address, an electronic device, such as a computer, mobile phone, tablet,
      or similar device with internet access and a current web browser, where
      such device has software making it capable of receiving, accessing,
      displaying, printing, saving, and storing documents and other records
      (with sufficient data storage to store such documents and records)
      received in electronic form.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Should you desire paper copies of any documents executed using REAL’s
      electronic signature Services, you may request such documents directly
      from your REAL agent, by emailing us at support@therealbrokerage.com, or
      by writing us at 39899 Balentine Dr, Suite 200, Newark, CA 94560, Attn:
      E-Sign Document Request.”
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      REAL provides its electronic signature Services as a way for parties to
      execute agreements. REAL is not a party to any such agreement, and shall
      not have any liability or responsibility with respect to the validity or
      enforceability, the breach by any party in the performance of its
      obligations under that agreement, or your failure to obtain the outcome
      you were seeking to achieve. REAL shall not have any obligation with
      respect to the performance by any party to any agreement executed using
      our Services. If any dispute arises between or among any parties to an
      agreement that has been executed using our Services, REAL shall not have
      any responsibility or liability with respect to that dispute. Without
      limiting the generality of the foregoing, REAL will not have any
      obligation to assist in mediating any such dispute, to locate any other
      party to the agreement, or otherwise to facilitate a resolution of the
      dispute.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      ANY STATEMENTS MADE BY REAL ABOUT THE VALIDITY OF ELECTRONIC CONTRACTS AND
      THE SIGNATURES ON AGREEMENTS THAT ARE ELECTRONICALLY EXECUTED ARE GENERAL
      IN NATURE AND ARE NOT INTENDED, AND SHOULD NOT BE CONSTRUED, AS LEGAL
      ADVICE. REAL HEREBY DISCLAIMS ANY RESPONSIBILITY FOR ENSURING THAT
      AGREEMENTS THAT ARE ELECTRONICALLY EXECUTED THROUGH REAL’S SERVICES ARE
      VALID OR ENFORCEABLE UNDER THE LAWS OF ANY PARTICULAR STATE OR OTHER
      JURISDICTION. IF YOU WISH TO VERIFY THE VALIDITY OR ENFORCEABILITY OF ANY
      AGREEMENT YOU PLAN TO EXECUTE OR HAVE EXECUTED USING REAL’S SERVICES, THEN
      YOU SHOULD CONSULT A LICENSED ATTORNEY FOR APPROPRIATE LEGAL ADVICE.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      10. Compliance with all Laws and Regulations
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      REAL is not responsible for your violation of any laws while using our
      System. Users must comply with all local, state, or federal laws regarding
      your use of our System. Our System is void where prohibited.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>11. Termination</p>
    <p className='font-inter font-light text-sm mt-4'>
      Subject to any other contracts you may have with REAL, you may stop using
      the System at any time. REAL may suspend or terminate the System at any
      time at REAL’s discretion and without notice. REAL may also terminate,
      limit or suspend your access to the System for any reason at any time and
      without notice. For example, REAL may terminate your access to this System
      if you are not complying with these Terms of Use or if REAL determines
      that you are a repeat infringer of third-party intellectual property
      rights.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      12. DIGITAL MILLENNIUM COPYRIGHT ACT (“DMCA”)
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      REAL respects the intellectual property rights of others and expects our
      affiliated agents to do the same. Per the DMCA, REAL will respond
      expeditiously to claims of copyright infringement on the Site or App if
      submitted to REAL’s Copyright Agent as described below. Upon receipt of a
      notice alleging copyright infringement, REAL will take whatever action it
      deems appropriate within its sole discretion, including removal of the
      allegedly infringing materials.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      If you believe that your intellectual property rights have been violated
      by REAL or by a third party who has uploaded materials to the Site or App,
      please provide the following information to REAL’s designated Copyright
      Agent listed below:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        A description of the copyrighted work or other intellectual property
        that you claim has been infringed;
      </li>
      <li>
        A description of where the material that you claim is infringing is
        located on the Site or App;
      </li>
      <li>
        An address, telephone number, and e-mail address where REAL can contact
        you and, if different, an e-mail address where the alleged infringing
        party, if not REAL, can contact you;
      </li>
      <li>
        A statement that you have a good-faith belief that the use is not
        authorized by the copyright owner or other intellectual property rights
        owner, by its agent, or by law;
      </li>
      <li>
        A statement by you under penalty of perjury that the information in your
        notice is accurate and that you are the copyright or intellectual
        property owner or are authorized to act on the owner’s behalf;
      </li>
      <li>Your electronic or physical signature</li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      REAL may request additional information before removing any allegedly
      infringing material. In the event REAL removes the allegedly infringing
      materials, REAL will immediately notify the person responsible for posting
      such materials that REAL removed or disabled access to the materials.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      REAL may also provide the responsible person with your e-mail address so
      that the person may respond to your allegations.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      REAL reserves the right to terminate, limit or suspend any user’s access
      to the System in the event of repeated infringing activity. If you believe
      that a user of this System is a repeat infringer, please follow the above
      instructions to contact REAL’s Copyright Agent. Please include sufficient
      information to assist REAL in determining that the user repeatedly engaged
      in infringing activity.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      REAL registered a designated agent with the Copyright Office pursuant to
      17 U.S.C. 512(c). REAL’s designated Copyright Agent is:
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Ron Dunagan Real Broker, LLC
    </p>
    <p className='font-inter font-light text-sm'>
      39899 Balentine Dr, Suite 200,
    </p>
    <p className='font-inter font-light text-sm'>Newark, CA 94560</p>
    <p className='font-inter font-light text-sm'>512-777-9581</p>
    <p className='font-inter font-light text-sm'>646-859-2368</p>
    <a href='mailto:ron@therealbrokerage.com' className='text-blue-600'>
      ron@therealbrokerage.com
    </a>
    <p className='font-inter font-medium text-sm mt-4'>
      13. Intellectual Property Rights
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      All Content and Services of the System, and the System itself, including
      its design and operation, are owned by REAL and are subject to trademark,
      service mark, trade dress, internet domain name, copyright, patent, and/or
      other intellectual property rights or licenses held by REAL, one of its
      affiliates, or by third parties who have licensed their materials to REAL.
      All intellectual property rights in the System attributable to REAL, and
      any derivative rights, shall remain REAL’s sole property. Provided that
      you comply with these Terms of Use, including your obligations as to use
      of the System and otherwise, REAL grants to you a limited, terminable at
      REAL’s sole discretion, non-exclusive, and non-assignable license to use
      the intellectual property, excluding trademarks, logos, and service marks
      (“Marks”), of the System, with any such use not to exceed the scope and
      intent for use of the System as set forth in these Terms of Use or
      otherwise limited by REAL. You may not grant any sub-licenses nor transfer
      the benefit of the license hereby granted without the prior written
      consent of REAL. If this Agreement terminates and/or if you are in default
      of these Terms of Use, this license shall automatically terminate.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      The Marks displayed on or in connection with the System are the property
      of REAL or other third parties. You are not permitted to use the Marks
      without the prior written consent of REAL or such third party that may own
      the Marks.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      You may not, nor have third party’s on your behalf, copy, download,
      reproduce, modify, publish, distribute, transmit, transfer, create
      derivative works from, reverse engineer, improve, import, export, offer
      for sale, or sell the System, or any Content or Services of the System,
      without first obtaining written permission from REAL, and you agree to
      assign and do hereby assign to REAL all rights, title, and interests in
      any work product or other interests resulting from such actions, including
      intellectual property developed or derived by or from improvements or
      derivations of the System attributable to you.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      14. Real Estate Agents
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      License Status Notification
    </p>
    <p className='font-inter font-light text-sm'>
      Many of our Services and Content are intended for use by licensed Real
      Estate Agents. If you access, download, or use any such Content or
      Services in connection with the System, you represent and warrant that you
      are a validly licensed Real Estate Agent in good standing or otherwise
      hold a valid license allowing you to practice in real estate transactions
      (e.g., licensed attorney). As a Real Estate Agent using our System, you
      agree to notify us immediately of any changes in the status of any real
      estate or other licenses held by you that affect your ability to legally
      conduct real estate transactions or otherwise work in the real estate
      industry. Additionally, you will notify us of any pending actions that may
      affect the status of any real estate licenses. Failure of a Real Estate
      Agent to notify REAL may result in suspension or termination of her/his
      access and use of the System and/or legal action.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>15. System Disclaimer</p>
    <p className='font-inter font-light text-sm mt-4'>
      We do not guarantee that the System will always be available, work, or be
      accessible at any particular time. Although we aim to provide you with
      accurate data and content through our System, please be aware that our
      System and any information, including Content, found within it are offered
      “as-is.” Only users who are eligible to use our System may do so. We
      reserve the right to terminate access for anyone for any reason.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      You agree that any information listed by us or a third party on our System
      may be inaccurate, unsubstantiated or possibly even incorrect. We cannot
      guarantee that using our System will give you any desired results. We do
      not endorse, represent, warrant, or otherwise verify any third parties who
      use our Service or post Content on our Site or App. We assume no
      responsibility for the accuracy or completeness of any information listed
      or available on our System. You assume all responsibility for any such
      information listed or available on our System. You agree to release us
      from any liability that we may have to in relation to your use of our
      System.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      You agree that it is your responsibility to verify any credentials of any
      third parties you may encounter through our System. You agree that we are
      not responsible for verifying the licensing of any Real Estate Agents and
      that you are solely responsible for such verification.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      16. Representations and Warranties
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      THE SYSTEM, INCLUDING, WITHOUT LIMITATION, THE SERVICE, CONTENT, SITE, AND
      APP, IS PROVIDED ON AN &quot;AS IS&quot;, &quot;AS AVAILABLE&quot; AND
      &quot;WITH ALL FAULTS&quot; BASIS. TO THE FULLEST EXTENT PERMISSIBLE BY
      LAW, NEITHER REAL NOR ANY OF THEIR EMPLOYEES, MANAGERS, OFFICERS OR AGENTS
      MAKE ANY REPRESENTATIONS OR WARRANTIES OR ENDORSEMENTS OF ANY KIND
      WHATSOEVER, EXPRESS OR IMPLIED, AS TO: (A) THE SYSTEM; (B) ANY SERVICES
      OFFERED THROUGH THE SYSTEM; (C) OUR CONTENT AND ANY USER CONTENT; OR (D)
      SECURITY ASSOCIATED WITH THE TRANSMISSION OF INFORMATION TO REAL OR VIA
      THE SYSTEM. IN ADDITION, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
      INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, FITNESS
      FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, CUSTOM, TRADE, QUIET
      ENJOYMENT, SYSTEM INTEGRATION AND FREEDOM FROM COMPUTER VIRUSES.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      REAL DOES NOT REPRESENT OR WARRANT THAT THE SYSTEM WILL BE ERROR-FREE OR
      UNINTERRUPTED; THAT DEFECTS WILL BE CORRECTED; OR THAT THE SYSTEM OR THE
      SERVER THAT MAKES THE SYSTEM AVAILABLE IS FREE FROM ANY HARMFUL
      COMPONENTS, INCLUDING, WITHOUT LIMITATION, VIRUSES. REAL DOES NOT MAKE ANY
      REPRESENTATIONS OR WARRANTIES THAT THE INFORMATION (INCLUDING ANY
      INSTRUCTIONS) ON THE SYSTEM IS ACCURATE, COMPLETE, OR USEFUL. YOU
      ACKNOWLEDGE THAT YOUR USE OF THE SYSTEM IS AT YOUR SOLE RISK. REAL DOES
      NOT REPRESENT OR WARRANT THAT YOUR USE OF REAL’S SYSTEM WILL RESULT IN THE
      IDENTIFICATION OF A REAL ESTATE AGENT WHO WILL BE ABLE TO SUCCESSFULLY
      CONCLUDE YOUR DESIRED REAL ESTATE TRANSACTION, OR THAT ANY REAL ESTATE
      SALES OR LEASE AGREEMENT THAT YOU CONTEMPLATE OR ENTER INTO, WITH OR
      WITHOUT THE ASSISTANCE OF A REAL ESTATE AGNET, WILL BE CLOSED OR BE
      CONSUMMATED, OR WILL OCCUR WITHOUT RISK TO YOU. WE RELY ON VARIOUS SOURCES
      OF INFORMATION IN IDENTIFYING THE REAL ESTATE AGENTS FOUND ON THE SYSTEM,
      BUT DO NOT VERIFY THE ACCURACY OF SUCH INFORMATION. OUR REFERENCE TO OR
      SUGGESTION THAT YOU ENGAGE ANY REAL ESTATE AGENT THROUGH THE SYSTEM OR
      OTHERWISE DOES NOT MEAN OR IMPLY THAT REAL IS ASSUMING ANY RESPONSIBILITY
      FOR THE ACTUAL SERVICES RENDERED TO YOU BY ANY REAL ESTATE AGENT OR OUR
      SPONSORSHIP OF, OR AFFILIATION WITH ANY SUCH REAL ESTATE AGENT. REAL DOES
      NOT WARRANT THAT YOUR USE OF THE SYSTEM IS LAWFUL IN ANY PARTICULAR
      JURISDICTION, AND REAL SPECIFICALLY DISCLAIMS ANY SUCH WARRANTIES. SOME
      JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER
      WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY TO YOU TO THE EXTENT SUCH
      JURISDICTION&apos;S LAW IS APPLICABLE TO YOU AND THIS AGREEMENT. BY
      ACCESSING OR USING THE SYSTEM YOU REPRESENT AND WARRANT THAT YOUR
      ACTIVITIES ARE LAWFUL IN EVERY JURISDICTION WHERE YOU ACCESS OR USE THE
      SYSTEM. REAL DOES NOT ENDORSE ANY CONTENT OR SERVICES AND SPECIFICALLY
      DISCLAIMS ANY RESPONSIBILITY OR LIABILITY TO ANY PERSON OR ENTITY FOR ANY
      LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE OR OTHERWISE),
      INJURY, CLAIM, LIABILITY OR OTHER CAUSE OF ANY KIND OR CHARACTER BASED
      UPON OR RESULTING FROM ANY CONTENT OR SERVICES FOUND ON OR THROUGH REAL.
      IN THE EVENT OF ANY ISSUE OR PROBLEM WITH THE SYSTEM, INCLUDING ITS
      CONTENT OR SERVICES, YOU AGREE THAT YOUR SOLE REMEDY, IF ANY, IS THROUGH
      THIS AGREEMENT.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      17. Limitation of Liability and Release
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      IN NO EVENT SHALL REAL, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE
      LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR
      CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM (I) YOUR USE OR INABILITY
      TO USE THE SYSTEM DUE TO ANY ERRORS, MISTAKES, OR INACCURACIES OF CONTENT,
      (II) ANY PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
      RESULTING FROM YOUR ACCESS TO AND USE OF OUR SYSTEM, (III) ANY
      INTERRUPTION,MISINFORMATION, INCOMPLETE INFORMATION, OR CESSATION OF
      TRANSMISSION TO OR FROM OUR SYSTEM TO YOU, (IV) ANY BUGS, VIRUSES, TROJAN
      HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH OUR SYSTEM BY
      ANY THIRD PARTY, (V) ANY FAILURE OF ANY THIRD PARTY INFORMATION OR
      PRODUCTS LISTED AND SOLD ON OUR SYSTEM, INCLUDING ANY FAILURES OR
      DISRUPTIONS WHETHER INTENTIONAL OR UNINTENTIONAL , (VI) ANY ACTION TAKEN
      IN CONNECTION WITH COPYRIGHT OR OTHER INTELLECTUAL PROPERTY OWNERS OR
      (VII) ANY ERRORS OR OMISSIONS IN OUR SYSTEM FOR ANY LOSS OR DAMAGE OF ANY
      KIND INCURRED AS A RESULT OF YOUR USE OF OUR SYSTEM, WHETHER BASED ON
      WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
      THE COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING
      LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW
      IN THE APPLICABLE JURISDICTION.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      For Jurisdictions that do not allow us to limit our liability:
      Notwithstanding any provision of these Terms of Use, if your jurisdiction
      has provisions specific to waiver or liability that conflict with the
      above then our liability is limited to the smallest extent possible by
      law. Specifically, in those jurisdictions not allowed, we do not disclaim
      liability for: (a) death or personal injury caused by its negligence or
      that of any of its officers, employees or agents; or (b) fraudulent
      misrepresentation; or (c) any liability which it is not lawful to exclude
      either now or in the future.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      IF YOU ARE A RESIDENT OF A JURISDICTION THAT REQUIRES A SPECIFIC STATEMENT
      REGARDING RELEASE THEN THE FOLLOWING APPLIES. FOR EXAMPLE, CALIFORNIA
      RESIDENTS MUST, AS A CONDITION OF THIS AGREEMENT, WAIVE THE APPLICABILITY
      OF CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES, “A GENERAL RELEASE
      DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO
      EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF
      KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT
      WITH THE DEBTOR.&quot; YOU HEREBY WAIVE THIS SECTION OF THE CALIFORNIA
      CIVIL CODE. YOU HEREBY WAIVE ANY SIMILAR PROVISION IN LAW, REGULATION, OR
      CODE THAT HAS THE SAME INTENT OR EFFECT AS THE AFOREMENTIONED RELEASE.
      REAL IS NOT RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF
      THIRD PARTIES, AND YOU RELEASE US, OUR DIRECTORS, OFFICERS, EMPLOYEES, AND
      AGENTS FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR
      IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD
      PARTIES. YOUR ABILITY TO USE OUR SYSTEM IS CONTINGENT ON YOUR AGREEMENT
      WITH THIS AND ALL OTHER SECTIONS OF THIS AGREEMENT. NOTWITHSTANDING THE
      FOREGOING, IN THE EVENT THAT WE MAY BE FOUND LIABLE TO YOU FOR ANY ISSUES
      REGARDING OUR SYSTEM, YOU AGREE OUR TOTAL LIABILITY TO YOU WILL NOT EXCEED
      $100 USD OR THE TOTAL AMOUNT YOU SPENT WHILE USING OUR SYSTEM WITHIN THE
      LAST SIX MONTHS, WHICHEVER IS GREATER.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      No Liability of App Stores and Other Third Parties. Your wireless carrier,
      the manufacturer and retailer of your mobile device, the developer of the
      operating system for your mobile device, the operator of any application
      store, marketplace, or similar service through which you obtain the App,
      and their respective affiliates, suppliers, and licensors (collectively,
      “Third Parties”) are not parties to this Agreement and they do not own and
      are not responsible for the App. Company, and not any Third Parties, is
      responsible for addressing any claims raised by you or any third party
      regarding the App or your use or possession thereof, including claims
      related to product liability, legal or regulatory requirements, and
      consumer protection or similar legislation. You are responsible for
      complying with all application store and other applicable Third Party
      terms of use. YOU AGREE (I) THE THIRD PARTIES DISCLAIM ALL WARRANTIES,
      EXPRESS AND IMPLIED, WITH RESPECT TO THE APP, INCLUDING, BUT NOT LIMITED
      TO, THE IMPLIED WARRANTIES OF NON-INFRINGEMENT, TITLE, MERCHANTABILITY,
      QUIET ENJOYMENT, QUALITY OF INFORMATION, AND FITNESS FOR A PARTICULAR
      PURPOSE; (II) IN NO EVENT WILL THE THIRD PARTIES BE LIABLE TO YOU OR ANY
      THIRD PARTY FOR ANY DIRECT, INDIRECT, PUNITIVE, EXEMPLARY, INCIDENTAL,
      SPECIAL, OR CONSEQUENTIAL DAMAGES (WHETHER IN CONTRACT, TORT (INCLUDING
      NEGLIGENCE), OR OTHERWISE) ARISING OUT OF THIS AGREEMENT OR THE APP, EVEN
      IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES;
      (III) IN ANY EVENT, THE MAXIMUM LIABILITY OF ANY THIRD PARTY FOR ALL
      CLAIMS (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE) OF
      EVERY KIND WILL IN NOT EXCEED FIVE HUNDRED DOLLARS ($500.00); AND (IV) YOU
      WAIVE ANY AND ALL CLAIMS, NOW KNOWN OR LATER DISCOVERED, THAT YOU MAY HAVE
      AGAINST THE THIRD PARTIES ARISING OUT OF THE APP AND THIS AGREEMENT. THE
      THIRD PARTIES ARE INTENDED THIRD PARTY BENEFICIARIES OF THIS AGREEMENT,
      CAPABLE OF DIRECTLY ENFORCING ITS TERMS. NOTHING CONTAINED IN THIS
      AGREEMENT WILL BE CONSTRUED AS MODIFYING OR AMENDING ANY AGREEMENTS OR
      OTHER TERMS BETWEEN YOU AND THE THIRD PARTIES WITH REGARD TO THEIR SUBJECT
      MATTER. In the event of any claim that the App or your possession and use
      of the App infringes a third party’s intellectual property rights, the
      Third Parties are not responsible for the investigation, defense,
      settlement, or discharge of the infringement claim.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      18. Class Action Waiver
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      You and REAL agree that any proceedings to resolve or litigate any dispute
      whether through a court of law or arbitration shall be solely conducted on
      an individual basis. You agree that you will not seek to have any dispute
      heard as a class action, representative action, collective action, or
      private attorney general action.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>19. Indemnity</p>
    <p className='font-inter font-light text-sm mt-4'>
      You agree to defend, indemnify and hold harmless REAL, its officers,
      directors, employees and agents, from and against any and all claims,
      damages, obligations, losses, liabilities, costs or debt, and expenses
      (including but not limited to attorney&apos;s fees) arising from:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>your use of and access to the System;</li>
      <li>your violation of any term of these Terms of Use;</li>
      <li>
        any claim that your actions caused harm, injury, or damage to a third
        party;
      </li>
      <li>
        your violation of any third party right, including without limitation
        any copyright, property, or privacy right.
      </li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      This defense and indemnification obligation will survive this Agreement
      and your use of the System. You also agree that you have a duty to defend
      us against such claims and we may require you to pay for an attorney(s) of
      our choice in such cases. You agree that this indemnity extends to
      requiring you to pay for our reasonable attorneys’ fees, court costs, and
      disbursements. In the event of a claim such as one described in this
      paragraph, we may elect to settle with the party/parties making the claim
      and you shall be liable for the damages as though we had proceeded with a
      trial.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>20. Choice of Law</p>
    <p className='font-inter font-light text-sm mt-4'>
      This Agreement shall be governed by the laws in force in the state of
      Texas. The offer and acceptance of this contract is deemed to have
      occurred in Texas.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>21. Forum of Dispute</p>
    <p className='font-inter font-light text-sm mt-4'>
      At REAL’s sole discretion, it may require you to submit any disputes
      arising from these Terms of Use or use of the System, including disputes
      arising from or concerning their interpretation, violation, invalidity,
      non-performance, or termination, to final and binding arbitration under
      the Rules of Arbitration of the American Arbitration Association applying
      Texas law.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      If you bring a dispute in a manner other than in accordance with this
      section, you agree that we may move to have it dismissed, and that you
      will be responsible for our reasonable attorneys’ fees, court costs, and
      disbursements in doing so.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      You agree that the unsuccessful party in any dispute arising from or
      relating to this Agreement will be responsible for the reimbursement of
      the successful party’s reasonable attorneys’ fees, court costs, and
      disbursements.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
      THESE TERMS OF USE OR THE SYSTEM MUST BE COMMENCED WITHIN ONE (1) YEAR
      AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR
      CLAIM IS PERMANENTLY BARRED.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>22. Force Majeure</p>
    <p className='font-inter font-light text-sm mt-4'>
      You agree that we are not responsible to you for anything that we may
      otherwise be responsible for, if it is the result of events beyond our
      control, including, but not limited to, acts of God, war, insurrection,
      riots, terrorism, crime, labor shortages (including lawful and unlawful
      strikes), embargoes, postal disruption, communication disruption, failure
      or shortage of infrastructure, shortage of materials, or any other event
      beyond our control.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>23. Severability</p>
    <p className='font-inter font-light text-sm mt-4'>
      In the event that a provision of this Agreement is found to be unlawful,
      conflicting with another provision of the Agreement, or otherwise
      unenforceable, the Agreement will remain in force as though it had been
      entered into without that unenforceable provision being included in it.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      If two or more provisions of this Agreement are deemed to conflict with
      each other’s operation, REAL shall have the sole right to elect which
      provision remains in force. Subject to any additional written contracts
      entered into between you and REAL, this Agreement is deemed to be the
      entire Agreement between you and REAL. In the event of a conflicting
      provision between this Agreement and any additional written contract
      entered into between you and REAL, REAL shall have the sole right to elect
      which provision remains in force.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>24. Non-Waiver</p>
    <p className='font-inter font-light text-sm mt-4'>
      We reserve all rights permitted to us under this Agreement as well as
      under the provisions of any applicable law. Our non-enforcement of any
      particular provision or provisions of this Agreement or any applicable law
      should not be construed as our waiver of the right to enforce that same
      provision under the same or different circumstances at any time in the
      future.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>25. Third Parties</p>
    <p className='font-inter font-light text-sm mt-4'>
      REAL or its users may post links to third party websites on its Site or
      App, which may include information that we have no control over. Whether
      you access third party websites through a link on our Site or App, or as a
      consequence of using our System, you acknowledge that you are aware that
      these third party websites are not screened for privacy or security issues
      by us, and you release us from any liability for the conduct of these
      third party websites. Furthermore, REAL does not endorse, verify, or
      otherwise ensure the accuracy of any information posted or linked through
      third party websites including any services or products sold by such third
      parties.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      26. Termination of Your Service
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      If we determine that any of your actions may harm REAL, we may terminate
      or suspend your account, or your use of our System without notice, though
      we will strive to provide a timely explanation in most cases. All
      provisions of this Agreement which by their nature should survive
      termination shall survive termination, including, without limitation,
      ownership provisions, warranty disclaimers, indemnity and limitations of
      liability. You agree that we are not required to provide you with access
      to our System and may terminate our System at any time and for any reason.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>27. Assignments</p>
    <p className='font-inter font-light text-sm mt-4'>
      You may not assign your rights and/or obligations under this Agreement to
      any other party without our prior written consent. We may assign our
      rights and/or obligations under this Agreement to any other party at our
      discretion.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>28. Amendments</p>
    <p className='font-inter font-light text-sm mt-4'>
      We may amend this Agreement from time to time. When we amend this
      Agreement, we will update this page and indicate the date that it was last
      modified or we may email you or otherwise provide you with written notice.
      You may refuse to agree to the amendments, but if you do, you must
      immediately cease using our System.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>29. Headings</p>
    <p className='font-inter font-light text-sm mt-4'>
      The headings in this Agreement are included for convenience only and will
      not affect the construction or interpretation of any provision in the
      Agreement or any of the rights or obligations of the parties to these
      Terms of Use.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      30. Electronic Communications
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      The communications between you and REAL use electronic means, whether you
      visit the System or send REAL e-mails or other electronic communications,
      or whether REAL posts notices on the System or communications with you via
      e-mail or other electronic means. For contractual purposes, you (1)
      consent to receive communications from REAL in an electronic form; (2)
      agree that all terms, conditions, agreements, notices, disclosures, and
      other communications that REAL provides to you electronically satisfy any
      legal requirement that such communications would satisfy if it were to be
      in writing, including electronic execution of documents. By submitting
      your contact information to us, you consent to being contacted by email,
      text message, or phone to receive updates and company information and
      related marketing. We will user your personal information in accordance
      with our Privacy Policy, if you have any questions or wish to stop
      receiving communications from us, please contact us at{' '}
      <a href='mailto:support@therealbrokerage.com' className='text-blue-600'>
        support@therealbrokerage.com{' '}
      </a>
      . The foregoing does not affect your statutory rights.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      30. California Users and Residents
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Pursuant to California Civil Code Section 1789.3, any questions about
      pricing, complaints, or inquiries about REAL must be sent to our agent for
      notice to:{' '}
      <a href='mailto:support@therealbrokerage.com' className='text-blue-600'>
        support@therealbrokerage.com{' '}
      </a>
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Lastly, California users are also entitled to the following specific
      consumer rights notice: The Complaint Assistance Unit of the Division of
      Consumer Services of the California Department of Consumer Affairs may be
      contacted in writing at 1625 North Market Blvd., Sacramento, CA 95834, or
      by telephone at (916) 445-1254 or (800) 952-5210. ‍
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Last Updated: July 2023 ‍
    </p>
  </div>
);

const WalletPrivacyPolicy = (
  <div className='font-inter font-medium text-base h-48 overflow-auto pr-10 w-[96%]'>
    <p className='font-inter font-light text-sm'>
      This Privacy Policy describes how The Real Brokerage, Inc. collects and
      uses Personal Data through our Website, mobile applications, email, text,
      and other electronic communications.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>1. Introduction</p>
    <p className='font-inter font-light text-sm mt-4'>
      The Real Brokerage Inc. and its subsidiaries, including, but not limited
      to, Real Broker, LLC; Real Brokerage Technologies, Inc.; Real Broker AZ,
      LLC; Real Broker NY, LLC; Real Broker CT, LLC; Real Broker MA, LLC; Real
      Broker NH, LLC; Real Broker Commercial, LLC; Real Broker NE, LLC; Real
      Broker AB Ltd.; Real Broker B.C. Ltd.; Real Broker Ontario Ltd.; and Real
      Broker Manitoba Ltd. (collectively
      <span className='font-medium'> "Company"</span>, or
      <span className='font-medium'> "we"</span>,
      <span className='font-medium'> "our"</span>, or
      <span className='font-medium'> "us"</span>) respect your privacy, and we
      are committed to protecting it through our compliance with this policy.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      This Privacy Policy (our “
      <span className='font-medium'>Privacy Policy</span>
      ”) describes the types of information we may collect from individual
      consumers, business representatives, real estate agents, and website
      visitors who visit or use our website located at www.joinreal.com (our “
      <span className='font-medium'>Website</span>
      ”) or any of our related mobile applications (our “
      <span className='font-medium'>App</span>
      ”) and our practices for collecting, using, maintaining, protecting, and
      disclosing that information. For purposes of this Privacy Policy, our
      Website, App and all related services and functionality that we provide or
      make available through them are referred to as our “
      <span className='font-medium'>Digital Services</span>”.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      This policy applies to information we collect:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>on our Digital Services;</li>
      <li>
        in email, text, and other electronic messages between you and our
        Digital Services;
      </li>
      <li>
        when you interact with our advertising and applications on third party
        websites and services, if those applications or advertising include
        links to this policy.
      </li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      It <span className='font-medium'>does not apply</span> to information
      collected by:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        us offline or through any other means, including on any other website
        operated by Company or any third party;
      </li>
      <li>
        any third party, including through any application or content (including
        advertising) that may link to or be accessible from or on the Digital
        Services.
      </li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      Please read this policy carefully to understand our policies and practices
      regarding your information and how we will treat it. If you do not agree
      with our policies and practices, your choice is not to use our Services.
      This Privacy Policy may change from time to time (see Changes to Our
      Privacy Policy, referenced below). Your continued use of our Digital
      Services after we make changes is deemed to be acceptance of those
      changes, so please check this Privacy Policy periodically for updates.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>2. Age Requirement</p>
    <p className='font-inter font-light text-sm mt-4'>
      Our Services are not intended for children under 18 years of age. No one
      under age 18 may provide any information to or through the Digital
      Services. We do not knowingly collect Personal Data from children under
      18. If you are under 18, do not use or provide any information on or in
      our Digital Services or on or through any of their features, including
      your name, address, telephone number, email address, or any screen name or
      username you may use. If we learn we have collected or received Personal
      Data from a child under 18 without verification of parental consent, we
      will delete that information. If you believe we might have any information
      from a child under 18, please email us at{' '}
      <a href='mailto:support@therealbrokerage.com' className='text-blue-600'>
        support@therealbrokerage.com{' '}
      </a>
      or call us at 646-859-2368 (United States) or 782-820-4098 (Canada).
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      3. Information We Collect About You and How We Collect It
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We collect different types of information about you, including information
      that may directly identify you, information that is about you but
      individually does not personally identify you, and information that we
      combine with our other users. This includes information that we collect
      directly from you or through automated collection technologies.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>Generally</p>
    <p className='font-inter font-light text-sm mt-4'>
      We collect several types of information from and about users of our
      Digital Services, specifically information:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        by which you may be personally identified, such as name, postal address,
        billing address, shipping address, e-mail address, home, work, and
        mobile telephone numbers, date of birth, credit or debit card number
        (for payment purposes only), and/or real estate agent licensure
        information;
      </li>
      <li>
        that is about you but individually does not identify you, such as
        traffic data, logs, referring/exit pages, date and time of your visit to
        or use of our Digital Services, error information, clickstream data, and
        other communication data and the resources that you access and use on or
        through our Digital Services; or
      </li>
      <li>
        about your Internet connection, the equipment you use to access or use
        our Digital Services and usage details.
      </li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      Collectively, we refer to this information in this policy as “
      <span className='font-medium'>Personal Data.</span>”
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We collect this information:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>directly from you when you provide it to us;</li>
      <li>
        automatically as you navigate through or use our Digital Services.
        Information collected automatically may include usage details, IP
        addresses, and information collected through cookies, web beacons,
        pixels tags and other tracking technologies; and
      </li>
      <li>
        from third parties, for example, data aggregators and our business
        partners.
      </li>
    </ul>
    <p className='font-inter font-medium text-sm mt-4'>
      Information You Provide to Us
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      The Personal Data we collect on or through our Digital Services is:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        Information that you provide by filling in forms on our Digital
        Services. This includes information provided at the time of registering
        to use our Digital Services, using our services or other services
        available through the Digital Services, purchasing products, or
        requesting further services. We may also ask you for information when
        you report a problem with our Digital Services;
      </li>
      <li>
        Records and copies of your correspondence (including email addresses),
        if you contact us;
      </li>
      <li>
        Your responses to surveys that we might ask you to complete for
        research, development and marketing purposes; and
      </li>
      <li>
        Details of transactions, including electronic signature transactions,
        you carry out through our Digital Services and/or of the fulfillment of
        your orders or services. You may be required to provide financial
        information before placing an order or requesting certain orders or
        services through our Digital Services.
      </li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      You also may provide information to be published or displayed
      (hereinafter, “<span className='font-medium'>posted</span>
      ”) on public areas of the Digital Services or transmitted to other users
      of the Digital Services or third parties (collectively, “
      <span className='font-medium'>User Contributions</span>
      ”). Your User Contributions are posted on and transmitted to others at
      your own risk. Although we limit access to certain pages, please be aware
      that no security measures are perfect or impenetrable. Additionally, we
      cannot control the actions of other users of the Digital Services with
      whom you may choose to share your User Contributions. Therefore, we cannot
      and do not guarantee that your User Contributions will not be viewed by
      unauthorized persons.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      <span className='font-medium'>Beneficiary Information.</span> You have the
      ability to designate a beneficiary for revenue share amounts we owe to
      you. If you provide beneficiary information, you represent and warrant to
      us that you have the legal right and authorization, through consent or
      otherwise, to provide the beneficiary’s personal information to us.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      Information We Collect Through Automatic Data Collection Technologies
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      As you navigate through and interact with our Digital Services, we may use
      automatic data collection technologies to collect certain information
      about your equipment, browsing actions, and patterns. For example, we
      collect, record and store information about your activity when you access
      and use our Digital Services, the Internet, and other online activity
      information, such as browsing history, search history, and information
      regarding your interaction with our Digital Services and other websites.
      We use various technologies to collect and store location information,
      including cookies, pixels or pixel tags, local storage, such as browser
      web storage or application data caches, databases, session recording and
      replay technology, and server logs.
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        <span className='font-medium'>Usage Details.</span> Details of your
        visits to our Digital Services, such as traffic data, location, logs,
        referring/exit pages, date and time of your visit to or use of our
        Digital Services, error information, clickstream data, and other
        communication data and the resources that you access and use on or in
        the Digital Services; and
      </li>
      <li>
        <span className='font-medium'>Device Information.</span> Information
        about your computer, mobile device, and Internet connection,
        specifically your IP address, operating system, browser type, and App
        version information.
      </li>
      <li>
        <span className='font-medium'> Location Data.</span> Information about
        your location collected through Geolocation technology.
      </li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      The information we collect automatically may include Personal Data or we
      may maintain it or associate it with Personal Data we collect in other
      ways or receive from third parties. It helps us to improve our Digital
      Services and to deliver a better and more personalized service by enabling
      us to:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>estimate our audience size and usage patterns;</li>
      <li>improve our product and services offering;</li>
      <li>
        store information about your preferences, allowing us to customize our
        Digital Services according to your individual interests;
      </li>
      <li>recognize you when you return to our Digital Services.</li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      The technologies we use for this automatic data collection may include:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        <span className='font-medium'>Cookies (or browser cookies).</span> We
        and our service providers may use cookies, web beacons, and other
        technologies to receive and store certain types of information whenever
        you interact with our Digital Services through your computer or mobile
        device. A cookie is a small file or piece of data sent from a website
        and stored on the hard drive of your computer or mobile device. On your
        computer, you may refuse to accept browser cookies by activating the
        appropriate setting on your browser, and you may have similar
        capabilities on your mobile device in the preferences for your operating
        system or browser. However, if you select this setting you may be unable
        to access or use certain parts of our Digital Services. Unless you have
        adjusted your browser or operating system setting so that it will refuse
        cookies, our system will issue cookies when you direct your browser to
        our Website or use our App.
      </li>
      <li>
        <span className='font-medium'> Pixels and Web Beacons.</span> Pages on
        our Digital Services, or our e-mails, may contain small electronic files
        known as web beacons (also referred to as clear gifs, pixel tags and
        single-pixel gifs) that permit us, for example, to count users who have
        visited those pages or opened an e-mail and for other related Digital
        Services statistics (for example, recording the popularity of certain
        Digital Services content and verifying system and server integrity).
      </li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      <span className='font-medium'> Google Analytics.</span> We may use Google
      Analytics, a web analytics service provided by Google, Inc. (“
      <span className='font-medium'>Google</span>
      ”) to collect certain information relating to your use of our Website.
      Google Analytics uses “<span className='font-medium'>cookies</span>
      ”, which are text files placed on your computer, to help our Website
      analyze how users use the site. You can find out more about how Google
      uses data when you visit our Website by visiting “How Google uses data
      when you use our partners’ sites or apps”, (located at
      www.google.com/policies/privacy/partners/). We may also use Google
      Analytics Advertising Features or other advertising networks to provide
      you with interest-based advertising based on your online activity. For
      more information regarding Google Analytics please visit Google’s website,
      and pages that describe Google Analytics, such as
      www.google.com/analytics/learn/privacy.html.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      <span className='font-medium'>Intercom.</span> We may use Intercom, a web
      analytics service provided by Intercom, Inc. (“
      <span className='font-medium'>Intercom</span>
      ”) to collect certain information relating to your use of our Website.
      Intercom uses “<span className='font-medium'>cookies</span>
      ”, which are text files placed on your computer, to help our Website
      analyze how users use the site. You can find out more about how Intercom
      uses data when you visit our Website by visiting
      https://www.intercom.com/legal/privacy. We may also use Intercom to
      provide advertising features or other advertising networks to provide you
      with interest-based advertising based on your online activity.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      4. How We Use Your Information
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We use information that we collect about you or that you provide to us,
      including any Personal Data:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        to provide our Website and its functionality, contents and services to;
      </li>
      <li>to provide our App and its functionality, contents and services;</li>
      <li>to provide our products and services to you;</li>

      <li>
        to provide you with information, products, or services that you request
        from us or that may be of interest to you;
      </li>
      <li>
        to process, fulfill, support, and administer transactions and orders for
        products and services ordered by you;
      </li>
      <li>
        to personalize your experience with our Digital Services and to deliver
        content and product and service offerings relevant to your interests;
      </li>
      <li>
        to market our financial products and services, such as corporate charge
        cards and debit cards;
      </li>
      <li>
        to determine your eligibility for our financial products and services,
        such as corporate charge cards and debit cards;
      </li>
      <li>to provide you with notices about your Company account;</li>
      <li>to contact you in response to a request;</li>
      <li>to fulfill any other purpose for which you provide it;</li>
      <li>
        to carry out our obligations and enforce our rights arising from any
        contracts entered into between you and us, including for billing and
        collection;
      </li>
      <li>
        to enforce our financial partners’ rights under their cardholder
        agreements with you;
      </li>
      <li>
        to notify you about changes to our Digital Services or any products or
        services we offer or provide though them;
      </li>
      <li>
        in any other way we may describe when you provide the information; and
      </li>
      <li>for any other purpose with your consent.</li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      We may also use your information to contact you about goods and services
      that may be of interest to you, including through newsletters. If you wish
      to opt-out of receiving such communications, you may do so at any time by
      clicking unsubscribe at the bottom of these communications or by visiting
      your Account Preferences page. For more information, see Choices About How
      We Use and Disclose Your Information, referenced below.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      5. Disclosure of Your Information
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We do not share, sell, share, or otherwise disclose your Personal Data for
      purposes other than those outlined in this Privacy Policy. However, we may
      disclose aggregated information about our users, and information that does
      not identify any individual, without restriction.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We may disclose Personal Data that we collect or you provide as described
      in this privacy policy:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        to affiliates, contractors, service providers, and other third parties
        we use to support our business. The services provided by these
        organizations include providing IT and infrastructure support services,
        advertising, financial services, marketing, and payment processing
        services.
      </li>
      <li>
        to advertising and marketing partners such as Facebook, Instagram,
        Google and other advertising networks may receive and use your Personal
        Data to assist us with our advertising and marketing efforts.
      </li>
      <li>
        to a buyer or other successor in the event of a merger, divestiture,
        restructuring, reorganization, dissolution, or other sale or transfer of
        some or all of our assets, whether as a going concern or as part of
        bankruptcy, liquidation, or similar proceeding, in which Personal Data
        held by Company about our Digital Services users are among the assets
        transferred;
      </li>

      <li>to fulfill the purpose for which you provide it;</li>
      <li>
        for any other purpose disclosed by us when you provide the information;
      </li>
      <li>with your consent.</li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      We may also disclose your Personal Data:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        to comply with any court order, law, subpoena, or legal process,
        including to respond to any government or regulatory request;
      </li>
      <li>
        to affiliates and third parties to market or advertise their products or
        services to you if you have not opted out of these disclosures. This
        type of sharing may be deemed a “sale” or “share” of Personal Data under
        some laws or jurisdictions. For example, Facebook, Instagram, Google and
        other advertising networks may have the ability to use your Personal
        Data for their own marketing, advertising or business purposes. For more
        information about your opt-out rights, see Choices About How We Use and
        Disclose Your Information, referenced below;
      </li>
      <li>
        to enforce or apply our Terms of Use, located at
        https://www.joinreal.com/page/legal, and other agreements, including for
        billing and collection purposes; and
      </li>
      <li>
        if we believe disclosure is necessary or appropriate to protect the
        rights, property, or safety of Company, our customers, or others. This
        includes exchanging information with other companies and organizations
        for the purposes of fraud protection and credit risk reduction.
      </li>
    </ul>
    <p className='font-inter font-medium text-sm mt-4'>
      6. Commercial Charge Cards and Other Financial Products and Services
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We offer real estate agents’ businesses financial products and services
      from our financial institution partners, such as a corporate charge card
      or debit card (collectively, “Financial Products”), to enable the business
      to purchase goods and services for a commercial purpose and conduct other
      financial activities. This section describes how we collect, use, and
      disclose Personal Data in connection with businesses’ applications for and
      use of the Financial Products.
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li className='mt-4'>
        <span className='font-medium'>Information Businesses Provide Us.</span>{' '}
        To obtain a Financial Product, the business (“
        <span className='font-medium'>you”</span>, “
        <span className='font-medium'>your”</span>) will need to submit an
        application that contains information to assist us in the underwriting
        process. The information you provide will include contact information
        (such as your name, phone number, and email and mailing addresses),
        financial information about your business (such as your bank account and
        routing numbers, account balances, business revenue and expected
        revenue, and credit history), and personal, professional, and financial
        information about your individual business principals. When you or your
        business principals use the Financial Products, we will also collect
        information about your transactions (such as information associated with
        your purchases, bill payments, reimbursements, and card transactions),
        as well as receipt and invoice data.
      </li>
      <li className='mt-4'>
        <span className='font-medium'>
          Information We Obtain from Other Sources.
        </span>{' '}
        To complete the underwriting process, we will obtain financial
        information from our financial institution partners (such as the bank
        issuing your card or other Financial Product), credit reporting
        agencies, and other entities that provide or support delivery of
        financial services. We may also receive information from identity
        verification, fraud prevention, and compliance monitoring providers to
        help us supplement our understanding of your business and prevent fraud,
        as well as from referrals and reward partners that we engage for our
        referral and rewards programs. If you link your card or other Financial
        Product to a business or personal bank account, we may receive bank
        account information about the linked account, such as the bank routing
        and account numbers and account balance. We may continue to access and
        receive bank account data from connected accounts until you disconnect
        them.
      </li>
      <li className='mt-4'>
        <span className='font-medium'>Information Use.</span> We will only use
        the information we collect in connection with the Financial Products to
        provide our services, determine eligibility for and facilitate our
        rewards programs, conduct internal auditing and reporting, maintain
        security and fraud prevention, and comply with our legal obligations.
      </li>
      <li className='mt-4'>
        <span className='font-medium'>Information Disclosure.</span> We will
        disclose the information we collect in connection with a Financial
        Product to our financial partners so that they can determine your
        eligibility to receive and use a Financial Product, to determine
        eligibility for and facilitate rewards programs, and to support their
        customer identification, risk, and compliance programs. We may also
        disclose this information to our vendors that help us provide our
        services, and to law enforcement and other third parties to comply with
        our legal obligations.
      </li>
    </ul>
    <p className='font-inter font-medium text-sm mt-4'>
      7. Marketing to Real Estate Agents Affiliated with Real by Real Affiliates
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We may share the personal information of real estate agents (including
      brokers) affiliated with our Real brokerage entities with Real affiliate
      businesses that offer mortgage broker (“One Real Mortgage”), title
      insurance (“One Real Title”) and other products and services. The shared
      information may include personal contact information, commission
      information and other information that was provided to Real brokerage
      entities, including information provided through the reZEN application.
      The Real affiliates, including One Real Mortgage and One Real Title and
      its affiliates, may use the information for their business and commercial
      purposes including to send marketing communications about their products
      and services. Real estate agents may elect to not have their information
      shared with Real affiliates at any time by contacting us at
      support@therealbrokerage.com or Real Broker, LLC, c/o Real Support, 39889
      Balentine Dr., Suite 200, Newark, CA 94560, and stating, in the e-mail or
      letter, “I hereby elect to not have my information shared with Real’s
      affiliates, and elect to not have Real’s affiliates use my information to
      market to me”.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>8. Job Applicants</p>
    <p className='font-inter font-light text-sm mt-4'>
      When you apply for one of our open positions, we collect the information
      that you provide in connection with your job application. This includes
      name, contact information, professional credentials and skills,
      educational and work history, and other information that may be included
      in a resume or provided during interviews (which may be recorded). This
      may also include demographic or diversity information that you voluntarily
      provide. We may also conduct background checks and receive related
      information.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We use applicants’ information to facilitate our recruitment activities
      and process employment applications, including evaluating candidates and
      monitoring recruitment statistics. We also use successful applicants’
      information to administer the employment relationship. We may also use and
      disclose applicants’ information to improve our website and for the
      compliance and protection purposes described above.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      When you apply for a job with us, we may offer you the option to engage in
      SMS text conversations about your job application. Consent to receive
      automated SMS text messages is not a condition of for employment. Message
      frequency may vary depending on the status of your job application, and
      message and data rates may apply. If you opt in to receive SMS text
      messages about your job application, your consent data, mobile number, and
      personal information will be collected and stored solely for the purpose
      of providing you with updates and information related to your job
      application or other job opportunities at the Company. No mobile
      information will be shared with third parties/affiliates for
      marketing/promotional purposes. To stop receiving text messages regarding
      your job application or other job opportunities at the Company, reply STOP
      to cancel and HELP for help to the text message.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We may also use data aggregators to collect information about potential
      job applicants from certain third-party publicly-available websites, and
      contact these individuals through phone calls or text messages about
      employment opportunities.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      9. Choices About How We Use and Disclose Your Information
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We offer you choices on how you can opt out of our use of tracking
      technology, disclosure of your Personal Data for our advertising to you,
      and other targeted advertising.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We do not control the collection and use of your information collected by
      third parties described above in Disclosure of Your Information. These
      third parties may aggregate the information they collect with information
      from their other customers for their own purposes.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      In addition, we strive to provide you with choices regarding the Personal
      Data you provide to us. We have created mechanisms to provide you with
      control over your Personal Data:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        <span className='font-medium'>
          Tracking Technologies and Advertising.
        </span>{' '}
        You can set your browser or operating to refuse all or some cookies, or
        to alert you when cookies are being sent. If you disable or refuse
        cookies, please note that some parts of our Digital Services may then be
        inaccessible or not function properly.
      </li>
      <li>
        <span className='font-medium'> Promotional Offers from Company.</span>{' '}
        If you do not wish to have your email address used by Company to promote
        our own products and services, you can opt-out at any time by clicking
        the unsubscribe link at the bottom of any email or other marketing
        communications you receive from us or logging onto your Account
        Preferences page. This opt out does not apply to information provided to
        Company as a result of a product purchase, or your use of our services.
      </li>
      <li>
        <span className='font-medium'>
          Disclosure of Your Information to Affiliates and Third Parties.
        </span>{' '}
        If you wish to unsubscribe from affiliates and third parties’
        promotions, you can do so by clicking the unsubscribe link at the bottom
        of any email or other marketing communications you receive from them. If
        you wish to opt-out of such sharing, please change your preferences in
        your Account Preferences page or email us{' '}
        <a href='mailto:support@therealbrokerage.com' className='text-blue-600'>
          support@therealbrokerage.com
        </a>
        .
      </li>
      <li>
        <span className='font-medium'>
          Interest-Based Targeted Advertising.
        </span>{' '}
        We may use your Personal Data to provide interest-based advertisements
        from us and that are customized to your particular preferences,
        including for companies that are not affiliated with us. We partner with
        third party advertising networks that collect IP addresses, unique
        device identifiers, browser type, operating system, time zone, country,
        referring pages, and other information through the use of cookies, pixel
        tags, and server logs on our Digital Services. They use this information
        to provide you with interest-based advertisements that are customized to
        your particular preferences, including for companies that are not
        affiliated with us.
      </li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      You may see these interest-based advertisements on our Digital Services,
      as well as on third party websites and apps, and across different devices
      you use. We may use this process to help us manage and improve the
      effectiveness of our marketing efforts. It also allows to display ads to
      our users about Company after they leave our Digital Services, and to
      track users after they see or click on an advertisement, keep track of
      users who access our Digital Services or advertisements from different
      devices, and better provide advertisements to our target audiences. The
      data collected through these tracking technologies is also saved and
      processed by our advertising and marketing service provider partners.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We do not control third parties’ collection or use of your information to
      serve interest-based advertising. However, these third parties may provide
      you with ways to choose not to have your information collected or used in
      this way. You can learn more about interest-based advertisements and your
      opt-out rights and options from members of the Network Advertising
      Initiative (“
      <span className='font-medium'>NAI</span>
      ”) on its website (
      <a href='https://www.networkadvertising.org' className='text-blue-600'>
        www.networkadvertising.org
      </a>
      ) and from members of the Digital Advertising Alliance on its website (
      <a href='https://www.aboutads.info' className='text-blue-600'>
        www.aboutads.info
      </a>
      ).
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      10. Your Rights and Choices Regarding Your Information and Accessing and
      Correcting Your Information
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      You may contact us to access or change your Personal Data. You may also be
      able to review and change your personal information by logging into the
      Digital Service and visiting your Account Preferences page.
    </p>

    <p className='font-inter font-light text-sm mt-4'>
      You can Contact Us to access and/or find out what information we have
      about you, and to correct that information. You can also review and change
      your Personal Data by logging into our Digital Services and visiting
      either the Settings or Account Preferences sections of our Digital
      Services. You may also notify us through the Contact Information below of
      any changes or errors in any Personal Data we have about you to ensure
      that it is complete, accurate, and as current as possible or to delete
      your account. We cannot delete your personal information except by also
      deleting your account with us. We may also not be able to accommodate your
      request if we believe it would violate any law or legal requirement or
      cause the information to be incorrect.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      <span className='font-medium'>Cookies.</span> You can change the cookie
      settings that will be placed when you use our Digital Services by changing
      the settings on your Internet browser. You can also block cookies by
      activating the setting on your browser that allows you to refuse the
      setting of all or some cookies. Please note that internet browsers allow
      you to change your cookie settings. These settings are usually found in
      the &apos;options&apos; or &apos;preferences&apos; menu of your internet
      browser. However, if you use your browser settings to block all cookies
      (including strictly necessary cookies), you may not be able to access or
      use all or areas and aspects of our Digital Services.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      <span className='font-medium'>
        Personalized and interest-based advertising.
      </span>{' '}
      You can generally opt out of receiving personalized and interest-based
      advertisements from third party advertisers and ad networks who are
      members of the Network Advertising Initiative (NAI) or who follow the
      Digital Advertising Alliance’s Self-Regulatory Principles for Online
      Behavioral Advertising by visiting the opt out pages on the NAI website
      and DAA website. We do not control third parties’ collection or use of
      your information to serve interest-based advertising. However, these third
      parties may provide you with ways to choose not to have your information
      collected or used in this way. You can also opt out of receiving targeted
      ads from members of the NAI on its website. You can learn more about
      interest-based advertisements and your opt out rights and options on the
      NAI website and DAA website.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      <span className='font-medium'>Messages.</span> If you do not wish to have
      your email address used by Company to send you advertising messages and
      content, you can opt out at any time by clicking the unsubscribe link at
      the bottom of any marketing emails you receive from us. You may have other
      options with respect to marketing and communication preferences through
      our Digital Services.
    </p>

    <p className='font-inter font-medium text-sm mt-4'>
      11. Do Not Track Signals
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We currently do not use automated data collection technologies to track
      you across websites. We currently do not honor do-not-track signals that
      may be sent by some browsers.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Some web browsers permit you to broadcast a signal to websites and online
      services indicating a preference that they “do not track” your online
      activities. At this time, we do not honor such signals.
    </p>

    <p className='font-inter font-medium text-sm mt-4'>12. Data Security</p>
    <p className='font-inter font-light text-sm mt-4'>
      Information transmitted over the Internet is not completely secure, but we
      do our best to protect your Personal Data. You can help protect your
      Personal Data and other information by keeping your password to our
      Digital Services confidential.
    </p>

    <p className='font-inter font-light text-sm mt-4'>
      We have implemented measures designed to secure your Personal Data from
      accidental loss and from unauthorized access, use, alteration, and
      disclosure. We use encryption technology for information sent and received
      by us.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      The safety and security of your information also depends on you. Where you
      have chosen a password for the use of our Digital Services, you are
      responsible for keeping this password confidential. We ask you not to
      share your password with anyone.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Unfortunately, the transmission of information via the Internet is not
      completely secure. Although we do our best to protect your Personal Data,
      we cannot guarantee the security of your Personal Data transmitted to, on
      or through our Digital Services. Any transmission of Personal Data is at
      your own risk. We are not responsible for circumvention of any privacy
      settings or security measures contained on the Website, in your operating
      system, or in the App.
    </p>

    <p className='font-inter font-medium text-sm mt-4'>
      13. PRIVACY RIGHTS IN CALIFORNIA AND CERTAIN OTHER US STATES
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      In addition to the disclosures and rights set forth elsewhere in this
      Policy, you and other users may have certain rights based on applicable
      state law, for example, the California Consumer Privacy Act, as updated by
      the California Privacy Rights Act (CCPA). Other states have passed
      consumer privacy laws that may be applicable to Real and your use of this
      Site. These supplemental disclosures and rights apply to you and your use
      of our Digital Services to the extent applicable state law applies to Real
      and your use of our Digital Services. Please contact us if you have any
      questions regarding your rights under applicable state law.
    </p>

    <p className='font-inter font-medium text-sm mt-4'>
      Categories of Personal Information Company Collects
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      As described in more detail in other areas of our Privacy Policy, we
      collect and/or disclose Personal Data (termed “Personal Information” in
      the CCPA) about you when you visit use our Digital Services, including
      information about you that you provide to us, and information that we
      automatically collect from you or your computer or device as you use our
      Digital Services. Please refer to Information We Collect About You and How
      We Collect It, referenced herein, for additional information and details.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Personal information does not include information that is: (a) publicly
      available information from government records; (b) de-identified or
      aggregated consumer information; or (c) certain information excluded from
      the scope of applicable state law (e.g., PHI covered under HIPAA and
      medical information may be covered under other state laws).
    </p>

    <p className='font-inter font-medium text-sm mt-4'>
      Categories of Sources from which Company has collected Personal
      Information
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      As described in more detail elsewhere in this Policy, we collect Personal
      Information directly from you, for example when you provide it to us, when
      you contact us through our Digital Services, when you create a Company
      account; and indirectly from you automatically through your computer or
      device as you use our Digital Services. We may also collect Personal
      Information about you from our advertising partners and service providers.
    </p>

    <p className='font-inter font-medium text-sm mt-4'>
      Use of Personal Information
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We may use or disclose the personal information we collect for our
      business purposes described elsewhere in this Privacy Policy (for example,
      please refer to “How We Use Your Information” and “Disclosure of Your
      Information”).
      <br />{' '}
      <b className='font-inter font-medium text-sm mt-4'>
        Disclosures of Personal Information for Business Purposes:
      </b>
    </p>

    <p className='font-inter font-light text-sm mt-4'>
      As described in more detail elsewhere in this Policy, we may disclose your
      Personal Information for our business purposes, such as your contact
      information, other information you have provided to us and unique
      identifiers that identify you to us or to our service providers, such as
      companies that assist us with marketing and advertising. Please refer to
      Information We Collect About You and How We Collect It, referenced herein
      for additional information and details.
    </p>

    <p className='font-inter font-light text-sm mt-4'>
      We disclose your Personal Information to certain third parties such as our
      vendors, business partners, service providers, including companies that
      assist us with marketing and advertising. For additional information
      please refer to “How We Use Your Information” and “Disclosure of Your
      Information”).
    </p>

    <p className='font-inter font-medium text-sm mt-4'>Access Request Rights</p>
    <p className='font-inter font-light text-sm mt-4'>
      You may have the right to request that Company disclose certain
      information to you about our collection and use of your Personal
      Information for the above business and commercial purposes. To submit an
      access request, see Exercising Access and Deletion Rights, referenced
      herein. Once we receive and confirm your verifiable consumer request, we
      will disclose to you:
    </p>

    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>The categories of Personal Information we collected about you.</li>
      <li>
        The categories of sources for the Personal Information we collected
        about you.
      </li>
      <li>
        Our business or commercial purpose for collecting that Personal
        Information.
      </li>
      <li>
        The categories of third parties with whom we share that Personal
        Information.
      </li>
      <li>
        The specific pieces of Personal Information we collected about you.
      </li>
      <li>
        If we sold or disclosed your Personal Information for a business
        purpose, two separate lists disclosing:
      </li>
      <li>
        sales, identifying the Personal Information categories that each
        category of recipient purchased; and
      </li>
      <li>
        disclosures for a business purpose, identifying the Personal Information
        categories that each category of recipient obtained.
      </li>
    </ul>

    <p className='font-inter font-medium text-sm mt-4'>
      Deletion Request Rights
    </p>
    <p className='font-inter font-light text-sm'>
      You may have the right to request that Company delete your Personal
      Information that we collected from you and retained, subject to certain
      exceptions. Once we receive and confirm your verifiable consumer request,
      we will delete (and direct our service providers to delete) your Personal
      Information from our records, unless certain exceptions apply.
    </p>

    <p className='font-inter font-medium text-sm mt-4'>
      Correction Request Rights
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      You may have the right to ask us to correct Personal Information that is
      inaccurate or out of date. You may also be able to review and change your
      Personal Information by logging into the Digital Service and visiting your
      Account Preferences page.
    </p>

    <p className='font-inter font-medium text-sm mt-4'>
      Right to Opt Out of Selling or Sharing Personal Information for Targeted
      Advertising
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      As explained in the Interest-Based Targeted Advertising section above, we
      may share Personal Information with advertising partners that display
      targeted advertisements to users around the web, including through the use
      of cookies and other similar technologies on our Websites. This may
      qualify as a “sale” or “share” of Personal Information under applicable
      privacy laws. You can opt out of sharing for these purposes by clicking
      Your Privacy Choices on the footer of our website or contacting us as
      described below.
    </p>

    <p className='font-inter font-medium text-sm mt-4'>Exporting Data</p>
    <p className='font-inter font-light text-sm mt-4'>
      If you need to export or obtain a copy of your data, please let us know
      and we will assist you with your request. We retain the data we collect
      for different periods of time depending on what it is, how we use it and
      applicable legal requirements. We may retain some data for longer periods
      of time than other data when necessary for legitimate business or legal
      purposes, such as security, fraud and abuse prevention, or financial
      record-keeping.
    </p>

    <p className='font-inter font-medium text-sm mt-4'>
      Exercising Access, Correction, and Deletion Rights
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      To exercise the access, correction, and deletion rights described above,
      please submit a verifiable consumer request to us by either:
    </p>

    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        Calling us at 646-859-2368 (United States) or 782-820-4098 (Canada).
      </li>
      <li>Emailing us at support@therealbrokerage.com.</li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      Only you, or your legal representative authorized to act on your behalf,
      may make a verifiable consumer request related to your Personal
      Information. You may only make a verifiable consumer request for access
      twice within a 12-month period. The verifiable consumer request must:
    </p>

    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>
        Provide sufficient information that allows us to reasonably verify you
        are the person about whom we collected Personal Information or an
        authorized representative.
      </li>
      <li>
        Describe your request with sufficient detail that allows us to properly
        understand, evaluate, and respond to it.
      </li>
    </ul>
    <p className='font-inter font-light text-sm mt-4'>
      Please note that applicable law limits your rights by, for example,
      prohibiting us from providing certain sensitive information in response to
      an access request and limiting the circumstances in which we must comply
      with a deletion request. If we deny your request, we will communicate our
      decision to you.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>Non-Discrimination</p>
    <p className='font-inter font-light text-sm mt-4'>
      We will not discriminate against you for exercising any of your rights
      under applicable state law. Unless permitted by applicable law, we will
      not:
    </p>
    <ul className='font-inter font-light text-sm list-disc ml-5 mt-4'>
      <li>Deny you goods or services.</li>
      <li>
        Charge you different prices or rates for goods or services, including
        through granting discounts or other benefits, or imposing penalties.
      </li>
      <li>Provide you a different level or quality of goods or services.</li>
      <li>
        Suggest that you may receive a different price or rate for goods or
        services or a different level or quality of goods or services.
      </li>
    </ul>

    <p className='font-inter font-medium text-sm mt-4'>
      14. Users Outside of the United States
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Our Website is intended for use by residents in the United States and
      Canada. Our Website is not intended for use by and is not directed to
      residents of the European Union. All data is stored and processed in the
      United States. By using and accessing our site, users who reside or are
      located in countries outside of the United States (including users in
      Canada) agree and consent to the transfer to and processing of personal
      information on servers located outside of the country where they reside,
      and that the protection of such information may be different than required
      under the laws of their residence or location.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      15. Changes to Our Privacy Policy
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We will post any changes to our Privacy Policy on our Website. If we make
      material changes to our Privacy Policy, we may notify you of such changes
      through your contact information and invite you to review (and accept, if
      necessary) the changes.
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      We may change this Privacy Policy at any time. It is our policy to post
      any changes we make to our Privacy Policy on this page with a notice that
      the Privacy Policy has been updated on the Website’s home page or the
      App’s home screen. If we make material changes to how we treat our users’
      Personal Data, we will notify you by email to the email address specified
      in your account and/or through a notice on the Website’s home page or the
      App’s home screen. The date this Privacy Policy was last revised is
      identified at the top of the page. You are responsible for ensuring we
      have an up-to-date active and deliverable email address for you, and for
      periodically accessing the App or visiting our Website and reviewing this
      Privacy Policy to check for any changes.
    </p>
    <p className='font-inter font-medium text-sm mt-4'>
      16. Contact Information
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      If you have any questions, concerns, complaints or suggestions regarding
      our Privacy Policy or otherwise need to contact us, you may contact us at
      the contact information below or through the “Contact Us” page on or in
      our Digital Services.
    </p>
    <p className='font-inter font-light text-sm mt-4'>How to Contact Us:</p>
    <p className='font-inter font-light text-sm mt-4'>
      Telephone: 646-859-2368 (United States) or 782-820-4098 (Canada).
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Email:{' '}
      <a href='mailto:support@therealbrokerage.com' className='text-blue-600'>
        support@therealbrokerage.com
      </a>
    </p>
    <p className='font-inter font-light text-sm mt-4'>
      Last updated: February 2024
    </p>
  </div>
);

export interface Agreement {
  title: string;
  briefText: string;
  completeComponent: React.ReactNode;
  isChecked: boolean;
  keyName: string;
}

interface WalletAgreement {
  electronicConsent: Agreement;
  termsOfServices: Agreement;
  privacyPolicy: Agreement;
}

export const WalletAgreements: WalletAgreement = {
  electronicConsent: {
    title: 'Electronic Disclosure and Consent',
    briefText:
      'In this agreement (“Agreement”), "we," "us," "our," and "REAL" mean Real Broker Financial, LLC and its affiliates, service providers and bank partners, including, but not limited to,...',
    isChecked: false,
    completeComponent: WalletElectronicCommunications,
    keyName: 'first',
  },

  termsOfServices: {
    title: 'Terms of Service',
    briefText:
      'Please read this document carefully. It contains very important information concerning your rights and obligations, as well as limitations and exclusions that may apply to you...',
    completeComponent: WalletTermsOfService,
    isChecked: false,
    keyName: 'second',
  },

  privacyPolicy: {
    title: 'Privacy Policy',
    briefText:
      'This Privacy Policy describes how The Real Brokerage, Inc. collects and uses Personal Data through our Website, mobile applications, email, text, and other electronic...',
    // 'This Privacy Policy describes how The Real Brokerage, Inc. collects and uses Personal Data through our Website, mobile applications, email, text, and other electronic...',
    completeComponent: WalletPrivacyPolicy,
    isChecked: false,
    keyName: 'third',
  },
};
