import { parseInitials, stringToNumber } from '../utils/StringUtils';
import { AvatarSizeType, EnumMap } from '../types';
import { cn } from '../utils/classUtils';

interface AgentProfileImagePreviewProps {
  imageUrl?: string;
  name: string;
  size: AvatarSizeType;
}

const AgentProfileImagePreview: React.FC<AgentProfileImagePreviewProps> = ({
  imageUrl,
  name,
  size,
}) => {
  const colors = [
    'bg-primary',
    'bg-success',
    'bg-error',
    'bg-warning',
    'bg-dark',
  ];

  const avatarSizeToClassNameMap: EnumMap<AvatarSizeType, string> = {
    xxs: 'h-6 w-6',
    xs: 'h-8 w-8',
    sm: 'h-10 w-10',
    lg: 'h-20 w-20',
    xl: 'h-28 w-28',
  };

  const textSizeToClassNameMap: EnumMap<AvatarSizeType, string> = {
    xxs: 'text-xs',
    xs: 'text-sm',
    sm: 'text-lg',
    lg: 'text-3xl',
    xl: 'text-4xl',
  };

  if (imageUrl) {
    return (
      <img
        className={cn(
          'rounded-full object-cover',
          avatarSizeToClassNameMap[size],
        )}
        src={imageUrl}
        alt='avatar'
      />
    );
  }

  return (
    <span
      className={cn(
        'inline-flex items-center justify-center rounded-full',
        avatarSizeToClassNameMap[size],
        colors[stringToNumber(name) % colors.length],
      )}
    >
      <span
        className={cn(
          'font-primary-medium leading-1 text-white',
          textSizeToClassNameMap[size],
        )}
      >
        {parseInitials(name)}
      </span>
    </span>
  );
};

export default AgentProfileImagePreview;
