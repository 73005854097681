import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import {
  TransactionBuilderControllerApi,
  TransactionResponse,
} from '../../openapi/arrakis';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import { AppDispatch, KBarActionEnum, RootState } from '../../types';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { isListingNotActionable } from '../../utils/TransactionUtils';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../Zen/ZenButton';

interface ZenMarkListingAsInContractButtonContainerProps {
  listing: TransactionResponse;
}

const ZenMarkListingAsInContractButtonContainer: React.FC<ZenMarkListingAsInContractButtonContainerProps> = ({
  listing,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const {
    kBar: { sidebarToOpen },
  } = useSelector((state: RootState) => state);

  const [isCreatingTransaction, setIsCreatingTransaction] = useState<boolean>(
    false,
  );
  const [isCreatingContract, setIsCreatingContract] = useState<boolean>(false);

  const handleCreateTransactionBuilder = async () => {
    setIsCreatingContract(true);
    try {
      const { data } = await new TransactionBuilderControllerApi(
        getArrakisConfiguration(),
      ).createBuilderFromTransaction(listing?.id!);

      history.replace(`/transaction/create/${data.id}`);
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify(
        'Unable to create transaction builder for a transaction',
        e,
        { listing },
      );
    } finally {
      setIsCreatingContract(false);
      setIsCreatingTransaction(false);
    }
  };

  const navigateToAssociatedTransaction = (transactionId: string) => {
    history.push(`/transactions/${transactionId}/detail`);
  };

  const disabled = isListingNotActionable(listing);

  useEffect(() => {
    if (sidebarToOpen === KBarActionEnum.MARK_LISTING_IN_CONTRACT) {
      setIsCreatingTransaction(true);
    }
  }, [sidebarToOpen]);

  return (
    <>
      {!listing?.terminated && (
        <div>
          {!disabled && (
            <ZenButton
              label='Mark as In Contract'
              LeftIconComponent={
                <FontAwesomeIcon icon={faArrowRight} className='mr-1' />
              }
              variant='primary'
              onClick={() => setIsCreatingTransaction(true)}
            />
          )}

          {disabled && !!listing?.inContractTransactionId && (
            <ZenButton
              label='View Associated Transaction'
              LeftIconComponent={
                <FontAwesomeIcon icon={faArrowRight} className='mr-1' />
              }
              variant='primary'
              onClick={() =>
                navigateToAssociatedTransaction(
                  listing?.inContractTransactionId!,
                )
              }
            />
          )}
        </div>
      )}

      <ZenConfirmationModal
        title='Mark Listing as In Contract?'
        subtitle="We'll start a walkthrough to create a new transaction with information based on this listing."
        onClose={() => setIsCreatingTransaction(false)}
        isOpen={isCreatingTransaction}
        variant='primary'
        confirmButtonText={isCreatingContract ? 'Creating...' : 'Create'}
        onConfirm={handleCreateTransactionBuilder}
        isSubmitting={isCreatingContract}
      />
    </>
  );
};

export default ZenMarkListingAsInContractButtonContainer;
