import {
  PatchTeamInvitationRequest,
  TeamControllerApi,
  TeamInvitationDto,
  TeamPublicControllerApi,
  ValidateGenericTeamInvitationResponse,
} from '../../openapi/yenta';
import { InvitationEnum } from '../../routes/JoinByInvitationRoute';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { isEmailInvite } from '../../utils/TypeUtils';
import { queryKeys } from '../base/queryKeys';
import { useBaseMutation } from '../base/useBaseMutation';
import { QueryOptions, useSimpleQuery } from '../base/useSimpleQuery';

interface UseInvitationProps {
  /**
   * Same `options` as you would pass to `useQuery` in react-query, but with some extra options.
   * See `QueryOptions` type for details.
   */
  options?: Partial<
    QueryOptions<TeamInvitationDto | ValidateGenericTeamInvitationResponse>
  >;
  /**
   * All the arguments the API function is expecting.
   */
  fnArgs: [string, InvitationEnum];
}

export const useInvitation = ({ options, fnArgs }: UseInvitationProps) => {
  const [id, type] = fnArgs;

  const queryResult = useSimpleQuery<
    TeamInvitationDto | ValidateGenericTeamInvitationResponse
  >({
    queryKey: queryKeys.invite.get(id, type).queryKey,
    queryFn: async () => {
      if (type === InvitationEnum.EMAIL) {
        const { data } = await new TeamPublicControllerApi(
          getYentaConfiguration(),
        ).getTeamInvitation(id);

        return data;
      }

      if (type === InvitationEnum.LINK) {
        const { data } = await new TeamPublicControllerApi(
          getYentaConfiguration(),
        ).validateGenericInvitation(id);

        return data;
      }

      throw new Error('Invalid invitation type!');
    },
    options: {
      toastErrorMessage: 'Failed to fetch invitation!',
      logErrorMessage: 'Failed to fetch invitation!',
      enabled: !!id,
      ...options,
    },
  });

  return queryResult;
};

export const useRedeemInvitation = (id: string, type: InvitationEnum) => {
  const redeem = async ({
    invitation,
    applicationId,
  }: {
    invitation: TeamInvitationDto | ValidateGenericTeamInvitationResponse;
    applicationId: string;
  }) => {
    const invitationId = isEmailInvite(invitation)
      ? invitation.invitationId!
      : invitation.invitation?.invitationId!;

    if (isEmailInvite(invitation)) {
      const { data } = await new TeamControllerApi(
        getYentaConfiguration(),
      ).redeemTeamInvitation({
        applicationId,
        invitationId,
      });

      return data;
    }

    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).redeemGenericInvitation({
      applicationId,
      invitationId,
    });

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.invite.get(id, type).queryKey,
    mutationFn: redeem,
    errorMessage: 'Failed to redeem invitation',
  });
};

export const useRevokeInvitation = (
  invitationId: string,
  type: InvitationEnum,
) => {
  const revoke = async ({
    invitationId,
    req,
  }: {
    invitationId: string;
    req: PatchTeamInvitationRequest;
  }) => {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).updateTeamInvitation(invitationId, req);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.invite.get(invitationId, type).queryKey,
    mutationFn: revoke,
    successMessage: 'Team member invitation revoked successfully!',
    errorMessage: 'Failed to revoke team member invitation',
  });
};
