import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { last } from 'lodash';
import { useHistory } from 'react-router-dom';
import { RootState } from '../types';
import { ApplicationResponseNextStatusEnum } from '../openapi/yenta';
import { getNextUrlForApplicationStatus } from '../testUtils/OnboardingUtils';

const useRedirectAwayOnboardingEffect = (
  currentStep: ApplicationResponseNextStatusEnum,
) => {
  const history = useHistory();
  const { userDetail } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!!userDetail?.applications?.length) {
      const application = last(userDetail?.applications)!;
      if (currentStep !== application.nextStatus) {
        history.push(getNextUrlForApplicationStatus(application.nextStatus!));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useRedirectAwayOnboardingEffect;
