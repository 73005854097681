import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import Countdown from 'react-countdown';
import ZenCardWithItems from '../../ZenCardWithItems';
import DefaultLoader from '../../../DefaultLoader';
import { bytesToSize } from '../../../../utils/FileUtils';

interface ZenPDFLoadingProps {
  onBack: () => void;
  pdf: File;
}

const ZenPDFLoading: React.FC<ZenPDFLoadingProps> = ({ onBack, pdf }) => {
  const estimateTime = () => {
    const estimatedSeconds = (pdf?.size || 0) / 100000;
    return (
      <Countdown date={Date.now() + estimatedSeconds * 500}>
        <span className='text-center text-sm mb-2 text-zen-dark-9  font-zen-body'>
          Its taking longer than expected, please wait while we process the pdf.
        </span>
      </Countdown>
    );
  };

  return (
    <div className='h-screen w-screen flex flex-col items-center justify-center relative'>
      <FontAwesomeIcon
        icon={faXmark}
        size='lg'
        className='absolute right-3 top-3 cursor-pointer text-zen-dark-9'
        title='close'
        onClick={onBack}
      />
      <div className='w-full max-w-2xl'>
        <p className='text-center text-lg mb-2 text-zen-dark-9 font-zen-body font-medium'>
          Processing PDF <DefaultLoader />
        </p>
        {!!pdf ? (
          <p className='text-center text-lg mb-2 text-zen-dark-9 font-zen-body font-medium'>
            Estimated Time: {estimateTime()}
          </p>
        ) : (
          <p className='text-center text-lg mb-2 text-zen-dark-9 font-zen-body font-medium'>
            Please wait while we process the pdf.
          </p>
        )}
        {!!pdf && (
          <div>
            <ZenCardWithItems
              cardVariant='border'
              items={[
                {
                  name: 'Name',
                  value: pdf.name,
                },
                {
                  name: 'Size',
                  value: bytesToSize(pdf.size || 0),
                },
                {
                  name: 'Type',
                  value: pdf.type.split('/')[1],
                },
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ZenPDFLoading;
