import React from 'react';
import ZenSidebarModal from '../../../Zen/ZenSidebarModal';
import {
  Earning,
  EarningTitle,
  EarningType,
} from '../../WealthManagementTypes';
import CommissionIncomeEarningsSidebarContentV2 from './CommissionIncomeEarningsSidebarContentV2';
import EliteAgentCulturalAwardSidebarContentV2 from './EliteAgentCulturalAwardSidebarContentV2';
import EliteAgentProductionAwardSidebarContentV2 from './EliteAgentProductionAwardSidebarContentV2';
import { PostCapSppSidebarContentV2 } from './PostCapSppSidebarContentV2';
import PreCapSppSidebarContentV2 from './PreCapSppSidebarContentV2';
import RevenueShareIncomeSidebarContentV2 from './RevenueShareIncomeSidebarContentV2';
import StockAwardForAttractingSidebarContentV2 from './StockAwardForAttractingSidebarContentV2';
import StockAwardForCappingSidebarContentV2 from './StockAwardForCappingSidebarContentV2';

const EarningContentMap: Record<EarningType, React.FC> = {
  [Earning.COMMISSION_INCOME]: CommissionIncomeEarningsSidebarContentV2,
  [Earning.PRE_CAP_SPP]: PreCapSppSidebarContentV2,
  [Earning.STOCK_AWARD_FOR_CAPPING]: StockAwardForCappingSidebarContentV2,
  [Earning.POST_CAP_SPP]: PostCapSppSidebarContentV2,
  [Earning.ELITE_PRODUCTION_AWARD]: EliteAgentProductionAwardSidebarContentV2,
  [Earning.ELITE_CULTURAL_AWARD]: EliteAgentCulturalAwardSidebarContentV2,
  [Earning.REVENUE_SHARE_INCOME]: RevenueShareIncomeSidebarContentV2,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]: StockAwardForAttractingSidebarContentV2,
};

interface EarningsSidebarV2Props {
  earningType: EarningType;
  onClose: () => void;
  isOpen: boolean;
}

const EarningsSidebarV2: React.FC<EarningsSidebarV2Props> = ({
  earningType,
  isOpen,
  onClose,
}) => {
  const EarningContentComponent = EarningContentMap[earningType];

  return (
    <ZenSidebarModal
      isOpen={isOpen}
      onClose={onClose}
      title={EarningTitle[earningType]}
    >
      <div className='p-4'>
        <EarningContentComponent />
      </div>
    </ZenSidebarModal>
  );
};

export default EarningsSidebarV2;
