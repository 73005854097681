import { useRegisterActions } from 'kbar';
import useDefaultActions from './useDefaultActions';
import useLeoActions from './useLeoActions';
import useListingActions from './useListingActions';
import useThemeActions from './useThemeActions';
import useTransactionActions from './useTransactionActions';
import useSearchTransactionsListingsActions from './useSearchTransactionsListingsActions';

export default function useActions() {
  const defaultActions = useDefaultActions();
  const leoActions = useLeoActions();
  const searchResultsActions = useSearchTransactionsListingsActions();
  const transactionActions = useTransactionActions();
  const listingActions = useListingActions();
  const themeActions = useThemeActions();

  let actions = [
    ...transactionActions,
    ...listingActions,
    ...searchResultsActions,
    ...defaultActions,
    ...leoActions,
    ...themeActions,
  ];

  useRegisterActions(actions, [actions]);
}
