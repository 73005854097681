import ProgressBar from '../../../ProgressBar';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import {
  ReferralTransactionFormState,
  ReferralTransactionStepName,
} from './ZenReferralTransactionSteps';

const withZenCreateReferralTransactionProgress = (
  Component: StepByStepComponent<
    ReferralTransactionFormState,
    ReferralTransactionStepName
  >,
): StepByStepComponent<
  ReferralTransactionFormState,
  ReferralTransactionStepName
> => (props) => {
  return (
    <>
      <div className='absolute top-0 left-0 w-full'>
        {props.progress.currentIndex > 0 ? (
          <ProgressBar
            completed={props.progress.currentIndex + 1}
            total={props.progress.lastIndex + 1}
          />
        ) : (
          <div className='flex relative w-full bg-success justify-center items-center'>
            <p className='text-base text-white text-center'>
              Your new referral transaction is just a few minutes away!
            </p>
          </div>
        )}
      </div>
      <Component {...props} />
    </>
  );
};

export default withZenCreateReferralTransactionProgress;
