import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  AdministrativeAreaResponseCountryEnum,
  AddressResponseCountryEnum,
  OfficeResponse,
} from '../../openapi/yenta';
import {
  formatAddressObject,
  formatFaxNumber,
  formatPhoneNumber,
} from '../../utils/StringUtils';
import AdminOnly from '../auth/AdminOnly';
import StateIcon from '../StateIcon';
import { ZenTab } from '../../components/Zen/Tab/ZenRouterTabs';
import ZenRouterTabsInInHeader from '../Zen/Tab/ZenRouterTabsInInHeader';
import GeminiStatusPill from './GeminiStatusPill';
import GeminiHover from './GeminiHover';
import GeminiCopyToClipboard from './GeminiCopyToClipborad';

interface GeminiOfficeDetailCardProps {
  officeDetail: OfficeResponse;
  onClickEdit(): void;
  tabs: ZenTab[];
  isSticky?: boolean;
}

const GeminiOfficeDetailCard: React.FC<GeminiOfficeDetailCardProps> = ({
  officeDetail,
  onClickEdit,
  tabs,
  isSticky,
}) => {
  return (
    <>
      <div
        data-testid='office-card'
        className='rounded-lg bg-white border-b border-zinc-100'
      >
        <div className='px-4 py-4.5 relative space-y-3 w-full'>
          <div className='absolute right-3 top-3 flex flex-row items-center space-x-1.5 md:space-x-3'>
            {isSticky && (
              <AdminOnly>
                <GeminiHover
                  config={{ trigger: 'hover', placement: 'bottom' }}
                  hoverComponent={
                    <p className='font-zen-body font-normal text-sm text-primary-dark px-1.5 py-0.5'>
                      Edit
                    </p>
                  }
                >
                  <button
                    className='flex items-center justify-center rounded-full md:p-3 p-2.5 cursor-pointer bg-regent-300 hover:bg-grey-300 shadow-lg'
                    onClick={onClickEdit}
                  >
                    <FontAwesomeIcon
                      icon={light('pen')}
                      className='text-primary-dark'
                    />
                  </button>
                </GeminiHover>
              </AdminOnly>
            )}
          </div>
          <div className='flex flex-col md:flex-row xl:justify-between'>
            <div className='flex gap-4 xl:w-[375px]'>
              <div className='flex flex-col items-center justify-center mb-2'>
                <div className='border-2 border-zen-salon-blue rounded-full h-20 w-20 md:h-28 md:w-28 flex items-center justify-center'>
                  {!!officeDetail?.imageUrl ? (
                    <img
                      className='bg-center bg-cover bg-no-repeat h-19 w-19 md:h-24 md:w-24 rounded-full aspect-square'
                      src={officeDetail?.imageUrl}
                      alt='officeImage'
                    />
                  ) : (
                    <StateIcon
                      state={officeDetail.administrativeArea?.stateOrProvince!}
                      width={110}
                      variant='circle'
                    />
                  )}
                </div>
                <div className='w-20 flex justify-center -mt-4'>
                  <GeminiStatusPill
                    text={!!officeDetail.active ? 'Active' : 'Inactive'}
                    variant={!!officeDetail.active ? 'primary' : 'disabled'}
                  />
                </div>
              </div>
              <div className='font-inter flex flex-col justify-center w-full'>
                <p className='text-lg leading-5 font-semibold text-zen-dark-9 font-inter'>
                  {officeDetail.name}
                </p>
                <div className='flex items-center gap-3 mt-4'>
                  <p className='text-zen-dark-9 text-base leading-5 font-inter'>
                    {officeDetail.administrativeArea?.country ===
                    AdministrativeAreaResponseCountryEnum.Canada
                      ? 'BN'
                      : 'EIN'}
                    :
                  </p>

                  <div className='border border-grey-300 rounded-lg text-rezen-blue-600'>
                    <GeminiCopyToClipboard
                      label={officeDetail!.company!.ein!}
                      value={officeDetail!.company!.ein!}
                      textStyle='text-sm'
                      iconStyle='h-6 w-4'
                      className='px-1.5 py-0.5'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full flex justify-center md:hidden my-8'>
              <hr className='h-0.5 w-1/2 bg-grey-300 mr-8 md:hidden flex justify-center' />
            </div>
            <div className='flex items-center'>
              <hr className=' h-1/3 w-0.5 bg-grey-300 mx-8 2xl:mx-14 hidden md:block' />
              <div className='flex flex-col gap-4 xl:w-[375px]'>
                <div className='space-y-1'>
                  <p className='text-grey-500 text-sm leading-5 font-medium font-inter'>
                    Mailing & Payment Processing Address
                  </p>
                  <div className='flex space-x-1.5'>
                    <div className=''>
                      <FontAwesomeIcon
                        icon={light('location-dot')}
                        className='mt-1 ml-1'
                      />
                    </div>
                    <h6 className='text-sm text-primary-dark max-w-[400px] font-inter leading-5 font-light'>
                      {(officeDetail.address &&
                        formatAddressObject(officeDetail.address!)) ||
                        'N/A'}
                    </h6>
                  </div>
                </div>
                {officeDetail.stateOfficeAddress && (
                  <div className='space-y-1'>
                    <p className=' text-grey-500 text-sm leading-5 font-medium font-inter'>
                      {officeDetail.address?.country ===
                      AddressResponseCountryEnum.Canada
                        ? 'Province Office Address'
                        : 'State Office Address'}
                    </p>
                    <div className='flex space-x-1.5'>
                      <div className=''>
                        <FontAwesomeIcon
                          icon={light('location-dot')}
                          className='mt-1 ml-1'
                        />
                      </div>
                      <h6 className='text-sm text-primary-dark max-w-[400px] font-inter leading-5 font-light'>
                        {formatAddressObject(officeDetail.stateOfficeAddress!)}
                      </h6>
                    </div>
                  </div>
                )}
                {officeDetail.branchOfficeAddress && (
                  <div className='space-y-1'>
                    <p className=' text-grey-500 text-sm leading-5 font-medium font-inter'>
                      Branch Office Address
                    </p>
                    <div className='flex space-x-1.5'>
                      <div className=''>
                        <FontAwesomeIcon
                          icon={light('location-dot')}
                          className='mt-1 ml-1'
                        />
                      </div>
                      <h6 className='text-sm text-primary-dark max-w-[400px] font-inter font-light'>
                        {formatAddressObject(officeDetail.branchOfficeAddress!)}
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='w-full flex justify-center md:hidden my-8'>
              <hr className='h-0.5 w-1/2 bg-grey-300 mr-8 md:hidden flex justify-center' />
            </div>
            <div className='font-inter flex items-center mb-6 md:mb-0'>
              <hr className='h-1/3 w-0.5 bg-grey-300 mx-8 2xl:mx-14 hidden md:block' />
              <div className='flex flex-col gap-4 text-primary-dark xl:w-[264px]'>
                <div className='flex space-x-1.5'>
                  <div className=''>
                    <FontAwesomeIcon
                      icon={light('envelope')}
                      className='mt-1'
                    />
                  </div>
                  <h6 className='text-sm font-inter font-light text-primary-dark'>
                    {officeDetail.emailAddress || 'N/A'}
                  </h6>
                </div>
                <div className='flex space-x-1.5'>
                  <div className=''>
                    <FontAwesomeIcon icon={light('phone')} className='mt-1' />
                  </div>
                  <h6 className='text-sm font-inter font-light text-primary-dark'>
                    {formatPhoneNumber(officeDetail.phoneNumber) || 'N/A'}
                  </h6>
                </div>
                <div className='flex space-x-1.5'>
                  <div className=''>
                    <FontAwesomeIcon icon={light('fax')} className='mt-1' />
                  </div>
                  <h6 className='text-sm text-primary-dark font-inter font-light'>
                    {formatFaxNumber(officeDetail.faxNumber) || 'N/A'}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='hidden md:block'>
          <hr className='text-zinc-100 w-full h-0.5' />
          <ZenRouterTabsInInHeader tabs={tabs} variant='secondary' />
        </div>
      </div>
      <div className='block md:hidden'>
        <ZenRouterTabsInInHeader tabs={tabs} variant='secondary' />
      </div>
    </>
  );
};

export default GeminiOfficeDetailCard;
