import { KBarResults, useMatches } from 'kbar';
import { useSelector } from 'react-redux';
import { EnumMap, KBarThemeEnum, RootState } from '../../types';
import { cn } from '../../utils/classUtils';

export interface SearchResultsProps {}

const SearchResults: React.FC<SearchResultsProps> = () => {
  const { theme } = useSelector((state: RootState) => state.kBar);
  const { results } = useMatches();
  const BgClass: EnumMap<KBarThemeEnum, string> = {
    [KBarThemeEnum.CLASSIC]: 'bg-zen-gray-2',
    [KBarThemeEnum.OCEAN]: 'bg-primary-blue',
  };

  const ActiveShortcutClass: EnumMap<KBarThemeEnum, string> = {
    [KBarThemeEnum.CLASSIC]: 'bg-secondary-dark font-medium text-white',
    [KBarThemeEnum.OCEAN]: 'bg-zen-gray-2 px-1.5 py-1',
  };

  const InActiveShortcutClass: EnumMap<KBarThemeEnum, string> = {
    [KBarThemeEnum.CLASSIC]: 'bg-zen-gray-2 font-medium text-zen-gray-6',
    [KBarThemeEnum.OCEAN]: 'bg-zen-gray-2 px-1.5 py-1',
  };

  const ActiveCommandClass: EnumMap<KBarThemeEnum, string> = {
    [KBarThemeEnum.CLASSIC]: 'text-dark pl-5',
    [KBarThemeEnum.OCEAN]: 'text-white pl-5',
  };

  const InActiveCommandClass: EnumMap<KBarThemeEnum, string> = {
    [KBarThemeEnum.CLASSIC]: 'text-dark pl-7',
    [KBarThemeEnum.OCEAN]: 'text-dark pl-7',
  };

  const AccentBgClass: EnumMap<KBarThemeEnum, string> = {
    [KBarThemeEnum.CLASSIC]: 'bg-zen-dark',
    [KBarThemeEnum.OCEAN]: 'bg-primary-blue',
  };

  if (!results.length) {
    return (
      <div data-testid='kBarResults' className='pt-2 pb-6 scrollbar-hide'>
        <KBarResults
          items={['Nothing here. Try searching for something else.']}
          onRender={({ item }) => (
            <div className='py-3 font-zen-body font-medium text-zen-gray-4 text-xs pl-4'>
              {item}
            </div>
          )}
        />
      </div>
    );
  }

  return (
    <div data-testid='kBarResults' className='pt-2 pb-3 scrollbar-hide'>
      <KBarResults
        items={results}
        onRender={({ item, active }) => {
          if (typeof item === 'string') {
            return (
              <div
                key={item}
                data-testid='kbar-section-title'
                className='py-3 font-zen-body font-medium text-zen-gray-4 text-xs pl-4'
              >
                {item?.toUpperCase()}
              </div>
            );
          } else {
            return (
              <div
                key={item?.name}
                data-testid='kbar-section-result'
                className='h-12'
              >
                <button
                  type='button'
                  className={cn(
                    'appearance-none w-full h-full flex flex-row items-center justify-between',
                    active ? BgClass[theme] : 'bg-white',
                  )}
                >
                  <div className='flex-grow flex flex-row items-center'>
                    {active && (
                      <div className={cn('w-2 h-12', AccentBgClass[theme])} />
                    )}
                    <p
                      key={item?.name}
                      className={cn(
                        'flex-grow text-left text-sm font-zen-body mb-0.5',
                        active
                          ? ActiveCommandClass[theme]
                          : InActiveCommandClass[theme],
                        {
                          'pl-4': !item?.section,
                        },
                      )}
                    >
                      {item?.name}
                    </p>
                  </div>
                  <div className='flex flex-row items-center pr-6'>
                    {item?.shortcut?.map((shortcut) => {
                      const isShiftShortcut = shortcut.startsWith('Shift');
                      return (
                        <p
                          key={shortcut}
                          className={cn(
                            'py-1.5 rounded-sm text-sm font-zen-body ml-2',
                            isShiftShortcut ? 'w-14' : 'w-5',
                            active
                              ? ActiveShortcutClass[theme]
                              : InActiveShortcutClass[theme],
                          )}
                        >
                          {isShiftShortcut
                            ? shortcut.replace('+', ' ')
                            : shortcut}
                        </p>
                      );
                    })}
                  </div>
                </button>
              </div>
            );
          }
        }}
      />
    </div>
  );
};

export default SearchResults;
