import React from 'react';
import { ReactComponent as EyeView } from '../../../assets/icons/eye-view.svg';

interface CommentRowCellProps {
  title: string;
  onClick(): void;
}

const CommentRowCell: React.FC<CommentRowCellProps> = ({ title, onClick }) => {
  return (
    <div className='font-primary-regular text-sm w-full'>
      <div className='flex flex-row items-center space-x-3 justify-between'>
        <p className='text-gray-700 break-words'>{title}</p>
        <button
          className='font-primary-regular text-sm text-primary-blue outline-none focus:outline-none flex flex-row items-center'
          onClick={onClick}
          data-testid='view-comment'
        >
          <EyeView className='-mt-0.5' />{' '}
        </button>
      </div>
    </div>
  );
};

export default CommentRowCell;
