import { faCheck } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { times } from 'lodash';
import StepDivider from '../../assets/img/step-divider.svg';

export interface CircleCheckStepProgressProps {
  stepNames: string[];
  totalStepsCount: number;
  inProgressIndex: number;
}

const CircleCheckStepProgress: React.FC<CircleCheckStepProgressProps> = ({
  stepNames,
  totalStepsCount,
  inProgressIndex,
}) => {
  return (
    <ol className='flex justify-center scrollbar overflow-x-auto px-4'>
      {times(totalStepsCount).map((step, index) => {
        const isStepComplete = inProgressIndex > index;
        const isStepInProgress = inProgressIndex === index;
        const isStepPending = inProgressIndex < index;
        return (
          <li key={step}>
            <span className='relative flex items-center justify-center'>
              <span className='flex items-start justify-center'>
                {index > 0 && (
                  <div className='pt-3'>
                    <img
                      src={StepDivider}
                      alt='step divider'
                      className='w-[96px]'
                    />
                  </div>
                )}
                <div className='flex flex-col items-center'>
                  {isStepComplete && (
                    <div className='h-6 w-6 flex items-center justify-center bg-step-progress rounded-full'>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className='text-white'
                        size='sm'
                      />
                    </div>
                  )}
                  {isStepInProgress && (
                    <div className='relative w-6 h-6 bg-step-progress rounded-full flex flex-row items-center justify-center'>
                      <div className='w-4 h-4 bg-white rounded-full flex flex-row items-center justify-center'>
                        <div className='w-2 h-2.5 bg-step-progress rounded-full' />
                      </div>
                    </div>
                  )}
                  {isStepPending && (
                    <div className='relative w-6 h-6 rounded-full flex flex-row items-center justify-center p-0.5 bg-zen-dark-5'>
                      <div className='w-4 h-4 bg-white rounded-full' />
                    </div>
                  )}
                  <p className='font-zen-body font-medium text-xs leading-4 uppercase text-white mt-2'>
                    {stepNames[index]}
                  </p>
                </div>
              </span>
            </span>
          </li>
        );
      })}
    </ol>
  );
};

export default CircleCheckStepProgress;
