import { useDispatch } from 'react-redux';
import { StepByStepComponent } from '../components/StepByStep/StepByStepContainer';
import HasTransactionClosedModalHeader from '../components/transactions/HasTransactionClosedModalHeader';
import ZenControlledRadioInput from '../components/Zen/Input/ZenControlledRadioInput';
import ZenControlledTextAreaInput from '../components/Zen/Input/ZenControlledTextAreaInput';
import ZenButton from '../components/Zen/ZenButton';
import ZenLink from '../components/Zen/ZenLink';
import { toggleClosedModal } from '../slices/TransactionSlice';
import { YesNoType } from '../types';
import {
  TransactionClosedFormData,
  TransactionClosedStepName,
} from './HasTransactionClosedModal';

const MoneySentToRealNoStep: StepByStepComponent<
  TransactionClosedFormData,
  TransactionClosedStepName
> = ({
  form: {
    control,
    watch,
    formState: { isSubmitting },
  },
  onPrevious,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const hasDepositCheck = watch('hasDepositCheck');
  const isDontHaveDepositCheck = hasDepositCheck === YesNoType.NO;

  return (
    <div className='w-full rounded-lg z-10 md:w-full max-w-[745px] shadow-sm shadow-gray-300 bg-white relative'>
      <HasTransactionClosedModalHeader
        title='Transaction Update'
        onClose={() => dispatch(toggleClosedModal(false))}
      />
      <div className='w-full px-6 md:px-10 lg:px-[129px] py-6'>
        <div className='w-full flex flex-col items-center justify-center'>
          <div className='w-full my-4'>
            <ZenControlledRadioInput<
              TransactionClosedFormData,
              'hasDepositCheck'
            >
              control={control}
              shouldUnregister={false}
              label='Do you have the deposit check?'
              name='hasDepositCheck'
              options={[
                {
                  value: YesNoType.YES,
                  label: 'Yes',
                },
                {
                  value: YesNoType.NO,
                  label: "No, I don't",
                },
              ]}
              inlineOptions
              isRequired
              rules={{ required: 'Required' }}
            />
          </div>
          {isDontHaveDepositCheck && (
            <div className='w-full my-4'>
              <ZenControlledTextAreaInput<
                TransactionClosedFormData,
                'missingMoneyNote'
              >
                name='missingMoneyNote'
                shouldUnregister={false}
                label='How will the money be sent to Real?'
                placeholder='Add Description'
                control={control}
                rows={4}
                className='border px-3 py-2 text-sm text-gray-800 rounded-2xl'
                noResize
                isRequired
                rules={{ required: 'Required' }}
              />
            </div>
          )}
          <div className='w-full mt-32 text-sm flex row items-center space-x-6'>
            <div className='whitespace-nowrap'>
              <ZenLink label='Go Back' size='small' onClick={onPrevious} />
            </div>
            <ZenButton
              label={
                !hasDepositCheck || isDontHaveDepositCheck
                  ? 'Complete'
                  : 'Submit Deposit Check'
              }
              variant='primary'
              onClick={onSubmit}
              isFullWidth
              isSubmitting={isSubmitting}
              isDisabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoneySentToRealNoStep;
