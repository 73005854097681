import { isEqual, uniq } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeamsInfo } from '../slices/TeamSlice';
import { AppDispatch, RootState } from '../types';

interface UseTeamsInfo {
  loading: boolean;
}

const useTeamsInfo = (teamIds: string[]): UseTeamsInfo => {
  const isMountedRef = useRef(true);
  const dispatch = useDispatch<AppDispatch>();
  const ref = useRef<string[]>();
  const { teamsById } = useSelector((state: RootState) => state.team);
  const [loading, setLoading] = useState(false);

  const teamIdsToFetch = useMemo(
    () => uniq(teamIds || []).filter((id) => !!id && !teamsById[id]),
    [teamsById, teamIds],
  );

  useEffect(() => {
    async function fetch() {
      isMountedRef.current && setLoading(true);
      await dispatch(fetchTeamsInfo(teamIdsToFetch));
      isMountedRef.current && setLoading(false);
    }

    if (!isEqual(ref.current, teamIdsToFetch) && teamIdsToFetch.length > 0) {
      fetch();
      ref.current = teamIdsToFetch;
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [teamIdsToFetch, dispatch]);

  return { loading };
};

export default useTeamsInfo;
