import { DateTime } from 'luxon';
import { Dispatch, SetStateAction } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { EscrowDepositResponse, EscrowResponse } from '../../openapi/arrakis';
import { displayFormattedAmountWithCurrency } from '../../utils/CurrencyUtils';
import ZenButton from '../Zen/ZenButton';
import { EscrowTypeEnum } from '../../forms/ZenDeleteEscrowOrInstallmentSidebarModal';

interface DepositCardProps {
  depositPayment: EscrowResponse;
  onDelete(): void;
  deleteInstallment: Dispatch<
    SetStateAction<EscrowDepositResponse | undefined>
  >;
  setEscrowType: Dispatch<SetStateAction<EscrowTypeEnum>>;
  setDeleteDepositModal: Dispatch<SetStateAction<boolean>>;
}

const DepositCard: React.FC<DepositCardProps> = ({
  depositPayment,
  onDelete,
  deleteInstallment,
  setEscrowType,
  setDeleteDepositModal,
}) => {
  return (
    <div className='border rounded-[10px] border-zen-dark-4 p-6'>
      <div className='flex justify-between items-center border-b border-zen-dark-4 pb-4'>
        <p className='text-zen-dark-9 text-lg font-zen-body font-semibold'>
          {displayFormattedAmountWithCurrency(depositPayment.amount)} deposit
        </p>
        <div>
          <ZenButton
            label='Delete'
            variant='danger-outline'
            LeftIconComponent={
              <FontAwesomeIcon
                icon={faTrashCan}
                className='pr-1 text-error text-sm'
              />
            }
            onClick={onDelete}
          />
        </div>
      </div>
      <div className='w-full flex flex-row justify-between py-4'>
        <div>
          <p className='text-[#969799] text-base font-zen-body font-semibold'>
            Required by
          </p>
          <p className='text-zen-dark-9 text-lg font-zen-body'>
            {DateTime.fromISO(depositPayment.requiredByDate!).toFormat(
              'LL/dd/yy',
            )}
          </p>
        </div>
        <div className='space-y-1'>
          <p className='text-[#969799] text-base font-zen-body font-semibold'>{`Installments (${depositPayment.deposits?.length})`}</p>
          {depositPayment.deposits?.map((deposit) => (
            <div
              className='flex flex-row items-center space-x-3 justify-between py-1'
              key={deposit.id}
            >
              <div className='flex flex-row items-center'>
                <p className='text-zen-dark-9 text-lg font-zen-body'>
                  {displayFormattedAmountWithCurrency(deposit.amount)}
                </p>
                <p className='text-zen-dark-9 text-lg font-zen-body'>
                  -{' '}
                  {DateTime.fromISO(deposit.dateReceived!).toFormat('LL/dd/yy')}
                </p>
              </div>
              <div>
                <div
                  data-testid='delete-installment'
                  onClick={() => {
                    deleteInstallment(deposit);
                    setEscrowType(EscrowTypeEnum.Installment);
                    setDeleteDepositModal(true);
                  }}
                  className='bg-zen-light-gray-2 rounded-full text-zen-danger p-2 cursor-pointer hover:bg-zen-light-gray-1 flex-shrink'
                >
                  <RiDeleteBinLine size={16} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='py-2'>
        <div>
          <p className='text-[#969799] text-base font-zen-body font-semibold'>
            Deposit Description
          </p>
          <p className='text-zen-dark-9 text-lg font-zen-body break-words'>
            {depositPayment.description}
          </p>
        </div>
      </div>
      <div className='py-2'>
        <div>
          <p className='text-[#969799] text-base font-zen-body font-semibold'>
            Notes
          </p>
          {depositPayment.note ? (
            <p className='text-zen-dark-9 text-lg font-zen-body break-words'>
              {depositPayment.note}
            </p>
          ) : (
            <p className='text-gray-400'>No notes</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DepositCard;
