import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CreateWebhookRequestEventNamesEnum } from '../../openapi/plutus';
import { RootState } from '../../types';
import { WebhookEventGroup } from '../../utils/WebhookUtils';
import ZenButton from '../Zen/ZenButton';
import ZenResourceContainer from '../Zen/ZenResourceContainer';
import ZenSearchBar from '../Zen/ZenSearchBar';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import WebhookAccordionGroup from './WebhookAccordionGroup';

interface WebhookSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  eventsList?: WebhookEventGroup[];
  isAllSelected?: boolean;
  handleToggleEvent(
    eventEnum: CreateWebhookRequestEventNamesEnum,
    removeFlag?: boolean,
  ): void;
  handleToggleGroup(index: number, toggle: boolean): void;
  handleToggleAll(toggle: boolean): void;
  handleEventSubmit(): void;
}

const WebhookSidebarModal: React.FC<WebhookSidebarModalProps> = ({
  isOpen,
  onClose,
  eventsList = [],
  isAllSelected = false,
  handleToggleEvent,
  handleToggleGroup,
  handleToggleAll,
  handleEventSubmit,
}) => {
  const {
    webhooks: { webhooksLoading },
  } = useSelector((state: RootState) => state);

  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (!isOpen) {
      setSearch('');
    }
  }, [isOpen]);

  return (
    <ZenSidebarModal
      title='Add Events'
      subtitle='Please select the events to add in your endpoint.'
      isOpen={isOpen}
      onClose={onClose}
    >
      <ZenResourceContainer
        isEmpty={isEmpty(eventsList)}
        loading={webhooksLoading}
        resourceName='Event'
      >
        <div className='mt-3 flex flex-col gap-2'>
          <div className='mx-3'>
            <ZenSearchBar
              placeholder='Search'
              value={search}
              onChange={setSearch}
            />
          </div>

          {!search && (
            <div className='flex items-center mt-4 px-3'>
              <input
                id='default-checkbox'
                type='checkbox'
                data-testid='select-all-checkbox'
                onClick={(e) => handleToggleAll(e.currentTarget.checked)}
                checked={isAllSelected}
                value=''
                className='w-5 h-5 text-blue-600 rounded-sm border-gray-300 focus:ring-0 focus:outline-none cursor-pointer'
              />
              <p className='ml-2 font-normal text-dark font-zen-body'>
                Select All Events
              </p>
            </div>
          )}
          <WebhookAccordionGroup
            eventsList={eventsList}
            search={search}
            handleToggleEvent={handleToggleEvent}
            handleToggleGroup={handleToggleGroup}
          />
        </div>

        <div className='absolute w-full bottom-0 bg-white flex gap-2 p-4 border-t justify-end'>
          <div className='w-40'>
            <ZenButton
              label='Cancel'
              variant='primary-outline'
              onClick={onClose}
              isFullWidth
            />
          </div>
          <div className='w-40' data-testid='add-events'>
            <ZenButton
              label='Add Events'
              variant='primary'
              onClick={handleEventSubmit}
              isFullWidth
            />
          </div>
        </div>
      </ZenResourceContainer>
    </ZenSidebarModal>
  );
};

export default WebhookSidebarModal;
