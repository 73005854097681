import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '../utils/classUtils';
import { ButtonSizeType, ButtonType } from '../types';

export type ButtonTypeType = 'submit' | 'reset' | 'button';
export interface ButtonProps {
  type?: ButtonType;
  size?: ButtonSizeType;
  label: string;
  pill?: boolean;
  onClick?: () => void;
  buttonType?: ButtonTypeType;
  className?: string;
  disabled?: boolean;
  isSubmitting?: boolean;
  fullWidth?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  type = 'primary',
  size = 'sm',
  label,
  pill = false,
  buttonType = 'button',
  className,
  disabled = false,
  onClick,
  isSubmitting = false,
  fullWidth = false,
}) => {
  const buttonTypeToClassNameMap: { [type in ButtonType]: string } = {
    primary: 'bg-primary text-white',
    secondary: 'bg-gray-200 text-gray-600',
    danger: 'bg-red-500 text-white',
    outline: 'border border-gray-200 bg-white text-black',
  };
  const sizeToClassNameMap: { [key in ButtonSizeType]: string } = {
    xs: 'py-0.5 px-2',
    sm: 'py-1 px-3',
    lg: 'py-1.5 px-5',
    xl: 'py-2 px-8',
  };
  const pillClass = pill ? 'rounded-full' : 'rounded';
  const fontToClassNameMap: { [type in ButtonSizeType]: string } = {
    xs: 'text-xs',
    sm: 'text-sm',
    lg: 'text-lg',
    xl: 'text-xl',
  };

  const isDisabled = disabled || isSubmitting;

  return (
    <button
      type={buttonType}
      className={cn(
        'focus:outline-none hover:opacity-70',
        isDisabled ? 'bg-gray-500 text-white' : buttonTypeToClassNameMap[type],
        sizeToClassNameMap[size],
        fontToClassNameMap[size],
        pillClass,
        className,
        isDisabled ? 'opacity-70' : 'opacity-100',
        { 'w-full': fullWidth },
      )}
      onClick={onClick}
      disabled={isDisabled}
    >
      <div
        className={cn('flex flex-row items-center', {
          'justify-center': fullWidth,
        })}
      >
        {isSubmitting && (
          <FontAwesomeIcon
            icon={faArrowsRotate}
            size={size}
            className='animate-spin mr-1'
          />
        )}
        <span>{label}</span>
      </div>
    </button>
  );
};

export default Button;
