import { DateTime } from 'luxon';

export interface ZenDocumentDateCellProps {
  date: number | undefined;
}

const ZenDocumentDateCell: React.FC<ZenDocumentDateCellProps> = ({ date }) => {
  return (
    <span>
      {date ? (
        <div>
          <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
            {DateTime.fromMillis(date).toFormat('MMM d, yyyy')}
          </div>
          <div className='font-zen-body font-normal text-xs text-zen-dark-6'>
            {DateTime.fromMillis(date).toFormat('hh:mm a')}
          </div>
        </div>
      ) : (
        'N/A'
      )}
    </span>
  );
};

export default ZenDocumentDateCell;
