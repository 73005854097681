import { KBarAnimator, KBarPortal, KBarPositioner, KBarSearch } from 'kbar';
import '../../assets/css/index.css';
import SearchResults from './SearchResults';
import useActions from './actions';

export interface CommandBarProps {}

const CommandBar: React.FC<CommandBarProps> = () => {
  useActions();

  return (
    <KBarPortal>
      <KBarPositioner className='flex bg-opacity-50 bg-black z-50'>
        <KBarAnimator
          className='flex flex-col w-1/2 w-max-[600px] bg-white rounded-xl'
          style={{ boxShadow: 'rgb(0 0 0 / 50%) 0px 16px 70px' }}
        >
          <div data-testid='kBar' className='px-4 pt-4'>
            <KBarSearch className='flex w-full p-3 font-zen-body outline-none text-base border border-gray-300 rounded-lg' />
          </div>
          <SearchResults />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
};

export default CommandBar;
