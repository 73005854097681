import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { faCircle1, faCircle2 } from '@fortawesome/pro-solid-svg-icons';
import { faCrown } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import millify from 'millify';
import { Earning, PrimaryColor } from '../WealthManagementTypes';
import {
  getOfficialPlan,
  setWealthKnowledgeLandingPage,
} from '../../../slices/WealthManagementSlice';
import CelebrateBlueEmoji from '../../../assets/img/wealthManagement/celebrate-blue-emoji.png';
import SellMoreEarnMore from '../../../assets/img/wealthManagement/sell_more_earn_more.svg';
import { AnalyticsEventEnum, RootState } from '../../../types';
import AnalyticsService from '../../../services/AnalyticsService';
import {
  displayFormattedFixedAmountWithCurrency,
  displayShortAmount,
} from '../../../utils/CurrencyUtils';
import ZenButton from '../../Zen/ZenButton';
import { LearnMoreCarousel } from './LearnMoreCarousel';
import { ZenCustomPrimaryGradientText } from './ZenCustomPrimaryGradientText';
import AgreementDetails from './AgreementDetails';
import ZenEliteStatus from './ZenEliteStatus';
import ZenLandingPageIntro from './ZenLandingPageIntro';

const Header = () => {
  return (
    <ZenLandingPageIntro
      buttonProps={{
        earningType: Earning.ELITE_PRODUCTION_AWARD,
      }}
      earningType={Earning.ELITE_PRODUCTION_AWARD}
      showAddToMyPlan
      sloganImage={SellMoreEarnMore}
      subtitle='Let Real reward you for your success'
    />
  );
};

const EquityAwardInformation = () => {
  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );

  const equityAmount = displayShortAmount({
    amount: officialPlanAndAwardData?.eliteEquityAwardPlan?.award?.amount ?? 0,
  });
  return (
    <ZenEliteStatus
      highlightProps={{
        label: 'unlock elite status for:',
        textToHighlight: 'elite status',
        highlightColor: PrimaryColor.ELITE_PRODUCTION_AWARD,
      }}
      stockProps={{
        amount: equityAmount,
        label: 'stock award',
      }}
    />
  );
};

const HowToAchieve = () => {
  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );

  const postCapTransactionFeesTotal = millify(
    officialPlanAndAwardData?.eliteEquityAwardPlan?.postCapTransactionFeesTotal
      ?.amount ?? 0,
  );
  const equityAmount = millify(
    officialPlanAndAwardData?.eliteEquityAwardPlan?.award?.amount ?? 0,
  );
  const minGCIAmount = millify(
    officialPlanAndAwardData?.eliteEquityAwardPlan?.minGCI?.amount ?? 0,
  );
  const minTransactionAmount = millify(
    officialPlanAndAwardData?.eliteEquityAwardPlan?.minTransactionPrice
      ?.amount ?? 0,
  );
  const postEliteSalesTransactionFee = millify(
    officialPlanAndAwardData?.eliteEquityAwardPlan?.postEliteSalesTransactionFee
      ?.amount ?? 0,
  );

  return (
    <div className='w-full p-5 bg-black mt-[73px] flex flex-col justify-center items-center'>
      <div className='flex w-full flex-col justify-center items-center bg-black pt-5 mb-10'>
        <div className='text-3xl font-poppins font-bold text-white w-full text-center mb-5'>
          <div>How do I become an</div>
          <div>
            <ZenCustomPrimaryGradientText>
              Elite Agent?
            </ZenCustomPrimaryGradientText>
          </div>
        </div>
        <div className='text-white font-inter text-xl md:text-2xl max-w-[606px] font-light text-center mb-5'>
          Achieve Elite agent status by meeting ONE of these two requirements:
        </div>
      </div>
      <div className='max-w-[722px]'>
        <div className='bg-blue-100 rounded-tr-lg rounded-tl-lg p-5 px-5 md:px-10 pb-10 relative text-xl md:text-0.5xl'>
          <div
            className='border-2 absolute top-0 right-1/2 transform translate-x-1/2 -translate-y-1/2 bg-[#FFF2E9] w-16 h-16 rounded-full flex justify-center items-center'
            style={{
              borderColor: PrimaryColor.ELITE_PRODUCTION_AWARD,
            }}
          >
            <FontAwesomeIcon
              icon={faCrown}
              size='2x'
              color={PrimaryColor.ELITE_PRODUCTION_AWARD}
            />
          </div>
          <div className='flex gap-4 mt-8 text-zen-dark-13 font-inter'>
            <FontAwesomeIcon
              icon={faCircle1}
              className='text-black'
              size='xl'
            />
            <div className='max-w-[540px]'>
              Reach your Commission Cap and pay{' '}
              {displayFormattedFixedAmountWithCurrency(
                officialPlanAndAwardData?.commissionPlan
                  ?.postCapSalesTransactionFee,
                false,
                0,
              )}{' '}
              per transaction{' '}
              <span className='font-bold'>
                until you have paid ${postCapTransactionFeesTotal} in
                transaction fees.
              </span>
            </div>
          </div>
          <div className='px-10 my-3 text-zen-dark-12 font-bold font-zen-body flex flex-row before:border-b-gray-400 before:flex-1 before:border-b before:m-auto before:mr-8 after:flex-1 after:border-b after:m-auto after:ml-8 after:border-b-gray-400'>
            OR
          </div>
          <div className='flex gap-4 text-zen-dark-13 font-inter'>
            <FontAwesomeIcon
              icon={faCircle2}
              className='text-black'
              size='xl'
            />
            <div className='max-w-[540px]'>
              Reach your Commission Cap, earn{' '}
              <span className='text-black font-bold'>
                GCI &gt; ${minGCIAmount}
              </span>{' '}
              <span className='font-medium'>
                <span className='underline'>and</span> complete{' '}
                {officialPlanAndAwardData?.eliteEquityAwardPlan
                  ?.minTransactionsClosed ?? 0}{' '}
                transactions with sales price &gt; ${minTransactionAmount}
              </span>
            </div>
          </div>
        </div>
        <div className='relative flex justify-center items-center bg-blue-200 rounded-bl-lg rounded-br-lg'>
          <div className='flex items-center gap-1 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-5 bg-blue-200 px-3 py-1 rounded-tr-lg rounded-tl-lg text-black'>
            <img
              src={CelebrateBlueEmoji}
              alt='celebrate emoji'
              className='h-6 w-6'
            />
            <span className='text-[26px] font-inter font-bold leading-[140%]'>
              Congrats!
            </span>
          </div>
          <div className='md:w-2/3 text-center text-black my-6 text-xl md:text-0.5xl font-bold font-inter'>
            <div>
              You’ve Unlocked{' '}
              <span className='text-primary-blue'>Elite Status</span>
            </div>
            <div className='text-lg md:text-xl'>
              and are eligible for the ${equityAmount} stock award
            </div>
          </div>
        </div>
      </div>
      <div className='w-full md:w-5/6 px-5 my-6 text-white font-inter text-center text-2xl font-light py-3 italic'>
        With Elite status, transaction fees{' '}
        <span className='text-amber-400 font-bold'>
          DECREASE TO ${postEliteSalesTransactionFee}!
        </span>
      </div>
    </div>
  );
};

const EliteAgentCulturalAward = () => {
  const dispatch = useDispatch();
  const handleViewEliteCulturalAward = () => {
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.WEALTH_PLAN_LP_ELITE_PRODUCTION_AWARD_CLICK_ON_SEE_DETAILS,
    );
    dispatch(setWealthKnowledgeLandingPage(Earning.ELITE_CULTURAL_AWARD));
  };

  return (
    <div className='w-full p-5 pt-8 flex flex-col justify-center items-center'>
      <div className='flex flex-col justify-center items-center pt-5 mb-10'>
        <div className='text-3xl md:text-[58px] leading-[100%] font-poppins font-bold text-center mb-5'>
          <div>
            <span className='text-black'>The</span>{' '}
            <span>
              <ZenCustomPrimaryGradientText>
                Elite Agent
              </ZenCustomPrimaryGradientText>
            </span>
          </div>
          <div>
            <span>
              <ZenCustomPrimaryGradientText>
                Cultural
              </ZenCustomPrimaryGradientText>
            </span>
            <span className='text-black'> Award</span>
          </div>
        </div>
        <div className='w-full md:max-w-[550px] font-inter text-2xl font-light text-zen-dark-13 text-center mb-8'>
          <div>
            By unlocking Elite Status you can get{' '}
            <span className='font-bold'>$8K more in stock </span>
            with our Elite Agent Cultural Award
          </div>
        </div>
        <div>
          <ZenButton
            label='See Details →'
            onClick={handleViewEliteCulturalAward}
            isFullWidth
          />
        </div>
      </div>
    </div>
  );
};

export const ZenEliteAgentProductionAwardKnowledgePage = () => {
  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (officialPlanAndAwardData === undefined) {
      dispatch(getOfficialPlan());
    }
  }, [dispatch, officialPlanAndAwardData]);

  return (
    <>
      <Header />
      <EquityAwardInformation />
      <HowToAchieve />
      <EliteAgentCulturalAward />
      <AgreementDetails />
      <LearnMoreCarousel />
    </>
  );
};
