import React, { useRef } from 'react';
import {
  faArrowUpRightFromSquare,
  faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column } from 'react-table';
import { FaFile } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { GiAnticlockwiseRotation } from 'react-icons/gi';
import { groupBy, map, uniqBy } from 'lodash';
import pluralize from 'pluralize';
import { useHistory } from 'react-router-dom';
import { FileApi, FileResponse } from '../../../openapi/dropbox';
import ZenSimpleModal from '../Modal/ZenSimpleModal';
import ZenFixedDataTable from '../ZenFixedDataTable';
import ProfileImage from '../../ProfileImage';
import { getYentaImageUrl } from '../../../utils/ImgUtils';
import { getFullName } from '../../../utils/AgentHelper';
import ZenDocumentDateCell from '../../dropbox/cell/ZenDocumentDateCell';
import IconButton from '../../IconButton';
import { ReactComponent as ZenEye } from '../../../assets/img/eye.svg';
import { downloadDropboxFileByVersion } from '../../../slices/DropboxSlice';
import { AppDispatch, RootState } from '../../../types';
import {
  getDropboxConfiguration,
  getSherlockConfiguration,
} from '../../../utils/OpenapiConfigurationUtils';
import ErrorService from '../../../services/ErrorService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { ItemApi, ItemResponse } from '../../../openapi/sherlock';
import { fetchCheckLists } from '../../../slices/CheckListSlice';
import { getChecklistsWithFileReferences } from '../../../utils/ChecklistUtils';

export interface FileResponseWithFileAndItemId extends FileResponse {
  itemId: string;
  fileId: string; // Added fileId as replacement for id as id is a random uuid
}

interface ChecklistDocumentArchiveModalProps {
  checklistId: string;
  isOpen: boolean;
  onClose: () => void;
  files: FileResponseWithFileAndItemId[];
}

const ChecklistDocumentArchiveModal: React.FC<ChecklistDocumentArchiveModalProps> = ({
  checklistId,
  files,
  isOpen,
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const {
    userIds: { agentById },
    checklist: { checklistsById },
  } = useSelector((state: RootState) => state);
  const ref = useRef<HTMLDivElement>(null);
  const checklistItems = checklistsById?.[checklistId]?.data?.items || [];

  const columns: Array<Column<FileResponseWithFileAndItemId>> = [
    {
      Header: '',
      accessor: 'fileId',
      Cell: ({ row: { original } }) => (
        <div
          onClick={() => {
            handleOpenFile(original);
          }}
          className='py-6'
          data-testid={`view-button-${original.fileId!}`}
        >
          <IconButton
            buttonStyle='border border-primary-blue bg-primary-blue rounded-lg px-1'
            variant='none'
            leftIcon={
              <ZenEye className='p-0.5 h-5 w-5 text-white' aria-label='view' />
            }
          />
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: 'Name / Description',
      accessor: 'filename',
      Cell: ({ value, row: { original } }) => (
        <div className='flex w-full'>
          <div className='mt-1'>
            <div className='border border-zen-dark-5 rounded p-1'>
              <FaFile className='text-zen-dark-5' />
            </div>
          </div>
          <div className='inline-block ml-2'>
            <div className='text-sm font-zen-body font-normal text-zen-dark-9 flex items-start gap-2 word-break'>
              {value}
              <button
                type='button'
                className='appearance-none'
                onClick={() => {
                  handleOpenFile(original);
                }}
                aria-label='file-open'
              >
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className='text-primary-blue text-base'
                />
              </button>
            </div>
            <div
              onClick={() => {
                history.push(`checklist?checklistItemId=${original?.itemId}`);
                onClose();
              }}
              className='text-xs cursor-pointer text-primary-blue leading-tight pt-1 contents'
            >
              {checklistItems?.find((ele) => ele?.id === original?.itemId)
                ?.name || 'N/A'}
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: 'Version',
      accessor: 'fileVersions',
      Cell: ({ value }) => (
        <div className='flex items-center md:justify-center gap-1'>
          <GiAnticlockwiseRotation />
          {value?.[value?.length! - 1]?.version}
        </div>
      ),
    },
    {
      Header: 'Uploaded By',
      accessor: 'currentVersion',
      Cell: ({ value }) =>
        agentById[value?.uploadedBy!] ? (
          <div className='font-zen-body text-sm items-center w-max gap-2 bg-zen-light-blue px-2 py-1.5 rounded-full inline-flex'>
            <ProfileImage
              imageUrl={getYentaImageUrl(agentById[value?.uploadedBy!]?.avatar)}
              width={17}
              variant='circle'
            />
            {getFullName(agentById[value?.uploadedBy!])}
          </div>
        ) : (
          ''
        ),
    },
    {
      Header: 'Upload Date',
      accessor: 'createdAt',
      Cell: ({ row: { original } }) => (
        <ZenDocumentDateCell date={original.currentVersion?.createdAt!} />
      ),
    },
  ];

  const handleOpenFile = async (dropboxFile: FileResponseWithFileAndItemId) => {
    await dispatch(
      downloadDropboxFileByVersion(
        dropboxFile?.fileId!,
        dropboxFile?.currentVersion?.id!,
      ),
    );
  };

  const handleFilesMoveToTrash = async (
    filesToArchive: FileResponseWithFileAndItemId[],
  ) => {
    try {
      const checklistItemsCopy = [...checklistItems];
      const filesGroupedByItemId = groupBy(filesToArchive, 'itemId');

      const removeReferencesResArr = map(
        filesGroupedByItemId,
        async (filesBelongingToSingleItem, itemId) => {
          // remove file references from checklist item
          await new ItemApi(getSherlockConfiguration()).removeFileReferences(
            itemId!,
            {
              references: filesBelongingToSingleItem.map((file) => ({
                fileId: file.fileId!,
                filename: file.filename!,
              })),
            },
          );

          // remove file references from checklist item copy
          const checklistItemIndex = checklistItemsCopy.findIndex(
            (item) => item.id === itemId,
          );

          if (checklistItemIndex !== -1) {
            const currentItem = checklistItemsCopy[checklistItemIndex];
            checklistItemsCopy[checklistItemIndex] = {
              ...currentItem,
              fileReferences: {
                references: currentItem.fileReferences?.references?.filter(
                  (fileRef) =>
                    !filesBelongingToSingleItem.some(
                      (file) => file.fileId === fileRef.fileId,
                    ),
                ),
              },
            };
          }
        },
      );

      await Promise.all(removeReferencesResArr);

      const uniqueFilesIds = uniqBy(filesToArchive, 'fileId');
      const moveFileToTrashResArr = uniqueFilesIds.map((file) => {
        let attachedChecklistArray: ItemResponse[] = getChecklistsWithFileReferences(
          checklistItemsCopy,
          file?.fileId!,
        );
        // move files to archive if not referenced by any other checklist item
        if (attachedChecklistArray?.length === 0) {
          return new FileApi(getDropboxConfiguration()).moveFileToTrash(
            file.fileId!,
          );
        }
        return null;
      });

      await Promise.all(moveFileToTrashResArr);

      dispatch(fetchCheckLists(checklistId));

      dispatch(
        showSuccessToast(
          `${pluralize(
            'Document',
            filesToArchive?.length,
          )} moved to archive successfully`,
        ),
      );
    } catch (e) {
      ErrorService.notify('Failed to move the files to archive', e);
      dispatch(
        showErrorToast(
          'We had a problem archiving 1 or more documents.',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <ZenSimpleModal
      title='Archive Checklist Documents'
      isOpen={isOpen}
      onClose={onClose}
      size='extraLarge'
    >
      <p className='font-zen-body text-base flex items-start text-zen-dark-7 px-4 py-3'>
        <FontAwesomeIcon icon={faInfoCircle} className='mr-1 mt-1' />
        Select the attachment that you want to archive from the checklist.
      </p>
      <div className='w-full max-w-full px-4'>
        <ZenFixedDataTable<FileResponseWithFileAndItemId>
          key={files?.length}
          columns={columns}
          standalone={false}
          resourceName='Checklist Documents'
          data={files}
          hideFilters
          hidePagination
          allowSelection
          compact
          hideHeader={false}
          selectionPortal={ref}
          selectionOptions={[
            {
              label: 'Cancel',
              variant: 'secondary-gray-outline',
              hideCount: true,
              onAction(_data) {
                onClose();
              },
            },
            {
              label: 'Archive',
              variant: 'danger',
              hideCount: true,
              confirm: (docs) => ({
                title: `Are you sure you want to archive ${pluralize(
                  'these',
                  docs?.length,
                )} ${pluralize('document', docs?.length)}?`,
                description: `${pluralize('These', docs?.length)} ${
                  docs?.length
                } ${pluralize(
                  'document',
                  docs?.length,
                )} will get archived and will be visible in the “File Cabinet”.`,
                modalType: 'error',
                primaryActionTitle: 'Archive',
              }),
              async onAction(filesToArchive) {
                await handleFilesMoveToTrash(filesToArchive);
                onClose();
              },
              isActionButtonEnabled(files) {
                return files?.length > 0;
              },
            },
          ]}
        />
      </div>
      <div
        className='sticky bottom-0 bg-white flex flex-row justify-center p-4 border-t space-x-4 min-h-[77px]'
        ref={ref}
      />
    </ZenSimpleModal>
  );
};

export default ChecklistDocumentArchiveModal;
