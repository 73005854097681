import { usePopperTooltip } from 'react-popper-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import CustomSwitch, { SwitchVariant } from './CustomSwitch';
import DefaultLoader from './DefaultLoader';

interface ParticipantToggleFlagOptionProps {
  value: boolean;
  onChange(value: boolean): void;
  title?: string;
  tooltip?: string;
  loading?: boolean;
  rightIcon?: string | React.ReactElement;
  leftIcon?: string | React.ReactElement;
  variant?: SwitchVariant;
}

const ToggleRow: React.FC<ParticipantToggleFlagOptionProps> = ({
  value,
  onChange,
  title,
  tooltip,
  loading,
  leftIcon,
  rightIcon,
  variant,
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();
  return (
    <div className='flex justify-between items-center'>
      <div className='flex flex-row py-1 items-center space-x-3 px-2'>
        <CustomSwitch
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          toggle={value}
          onClick={() => !loading && onChange(!value)}
          variant={variant}
        />
        {title && <p>{title}</p>}
        {tooltip && (
          <div>
            <div ref={setTriggerRef}>
              <FontAwesomeIcon icon={faCircleInfo} />
            </div>
            {visible && (
              <div
                ref={setTooltipRef}
                {...getTooltipProps({ className: 'tooltip' })}
              >
                <div {...getArrowProps({ className: 't-arrow' })} />
                {tooltip}
              </div>
            )}
          </div>
        )}
      </div>
      {loading && (
        <div className='pr-3'>
          <DefaultLoader noPadding iconSize='default' />
        </div>
      )}
    </div>
  );
};

export default ToggleRow;
