import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  InviteResponse,
  InviteResponseStatusEnum,
  MembershipResponseStatusEnum,
} from '../../openapi/avalon';
import { AgentResponse } from '../../openapi/yenta';
import {
  fetchAllAgentJVInvitations,
  markAgentDocumentsAsSigned,
  withdrawJointVentureInvitation,
} from '../../slices/JointVentureSlice';
import ResourceContainer from '../ResourceContainer';
import ZenSimpleConfirmationModal from '../Zen/Modal/ZenSimpleConfirmationModal';
import ZenCard from '../Zen/ZenCard';

import ZenSidebarModal from '../Zen/ZenSidebarModal';
import ZenTabs from '../Zen/ZenTabs';
import InviteJointVentureModalForm from './InviteJointVentureModalForm';
import ZenAgentQJCard from './ZenAgentQJCard';

interface ZenRealJointVentureSideBarModelProps {
  isOpen: boolean;
  onClose(): void;
  jointVentures: InviteResponse[] | undefined;
  detail: AgentResponse | undefined;
}

const ZenJointVentureSibeBarModel: React.FC<ZenRealJointVentureSideBarModelProps> = ({
  isOpen,
  onClose,
  jointVentures,
  detail,
}) => {
  const [currentTab, setCurrentTab] = useState<string>('Qualifies');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [isAgentSignedDoc, setIsAgentSignedDoc] = useState<boolean>(false);
  const [memberShipId, setMembershipId] = useState<string | null>(null);
  const [inviteId, setInviteId] = useState<string | null>(null);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState<boolean>(
    false,
  );
  const [
    isJoinVentureInvitationModalOpen,
    setIsJoinVentureInvitationModalOpen,
  ] = useState<boolean>(false);

  const dispatch = useDispatch();

  const qualifies =
    jointVentures?.filter(
      (jointVenture) =>
        jointVenture.status! !== InviteResponseStatusEnum.Accepted ||
        jointVenture.membership?.status !== MembershipResponseStatusEnum.Active,
    ) || [];

  const handleAgentInvitation = (jointVenture: InviteResponse | undefined) => {
    if (
      jointVenture?.membership?.status ===
      MembershipResponseStatusEnum.PendingSignature
    ) {
      setIsAgentSignedDoc(true);
      setMembershipId(jointVenture?.membership?.id!);
    } else if (
      jointVenture?.status === InviteResponseStatusEnum.Created ||
      jointVenture?.status === InviteResponseStatusEnum.Viewed
    ) {
      setInviteId(jointVenture?.id!);
      setIsWithdrawModalOpen(true);
    } else setIsJoinVentureInvitationModalOpen(true);
  };

  const handleWithdrawInvitation = async () => {
    setIsSubmitting(true);
    await dispatch(withdrawJointVentureInvitation(inviteId!));
    await dispatch(fetchAllAgentJVInvitations(detail?.id!));
    setInviteId(null);
    setIsWithdrawModalOpen(false);
    setIsSubmitting(false);
  };

  const handleAgentSignature = async () => {
    setIsSubmitting(true);
    await dispatch(markAgentDocumentsAsSigned(memberShipId!));
    await dispatch(fetchAllAgentJVInvitations(detail?.id!));
    setMembershipId(null);
    setIsAgentSignedDoc(false);
    setIsSubmitting(false);
  };

  const joined =
    jointVentures?.filter(
      (jointVenture) =>
        jointVenture.status! === InviteResponseStatusEnum.Accepted &&
        jointVenture.membership?.status === MembershipResponseStatusEnum.Active,
    ) || [];

  const qualifiedJVCards = () => {
    return (
      <ResourceContainer
        loading={!qualifies}
        isEmpty={qualifies.length === 0}
        resourceName='qualifiedJVCards'
        emptyMessage='You have not qualifed for any Joint Ventures'
      >
        {qualifies?.map((jointVenture) => (
          <ZenAgentQJCard
            key={jointVenture.id}
            colorCode={jointVenture.tier?.colorCode!}
            interests={jointVenture.interests!}
            jointVentureName={jointVenture.jointVenture?.name!}
            membershipStatus={jointVenture.membership?.status!}
            status={jointVenture.status!}
            tierName={jointVenture.tier?.name!}
            inviteId={jointVenture.id!}
            onClick={() => handleAgentInvitation(jointVenture)}
          />
        ))}
      </ResourceContainer>
    );
  };
  const joinedJVCards = () => {
    return (
      <ResourceContainer
        loading={!joined}
        isEmpty={joined.length === 0}
        resourceName='joinedJVCards'
        emptyMessage='You are not part of any Joint Venture'
      >
        {joined?.map((jointVenture) => (
          <ZenAgentQJCard
            key={jointVenture.id}
            colorCode={jointVenture.tier?.colorCode!}
            interests={jointVenture.interests!}
            jointVentureName={jointVenture.jointVenture?.name!}
            membershipStatus={jointVenture.membership?.status!}
            status={jointVenture.status!}
            tierName={jointVenture.tier?.name!}
            inviteId={jointVenture.id!}
            onClick={() => handleAgentInvitation(jointVenture)}
          />
        ))}
      </ResourceContainer>
    );
  };
  return (
    <ZenSidebarModal title='Real JV Tiers' isOpen={isOpen} onClose={onClose}>
      <ZenCard>
        <div className='px-0 md:px-4 lg:pt-5'>
          <div>
            <ZenTabs
              tabs={[
                {
                  name: 'Qualifies',
                  TabComponent: (
                    <div className='s py-4'>{qualifiedJVCards()}</div>
                  ),
                },
                {
                  name: 'Joined',
                  TabComponent: (
                    <div className='space-y-4 py-4'>{joinedJVCards()}</div>
                  ),
                },
              ]}
              selected={currentTab}
              onChange={setCurrentTab}
              size='lg'
            />
          </div>
        </div>
      </ZenCard>
      <div>
        {isJoinVentureInvitationModalOpen && (
          <InviteJointVentureModalForm
            title={`Invite ${detail?.fullName} to Join`}
            agentId={detail?.id!}
            onClose={() => setIsJoinVentureInvitationModalOpen(false)}
          />
        )}
        {isAgentSignedDoc && memberShipId && (
          <ZenSimpleConfirmationModal
            isSubmitting={isSubmitting}
            variant='info'
            confirmButtonText='Confirm'
            cancelButtonText='Cancel'
            title='Agent has signed the documents?'
            isOpen={isAgentSignedDoc}
            onClose={() => setIsAgentSignedDoc(false)}
            onConfirm={handleAgentSignature}
          />
        )}

        {isWithdrawModalOpen && inviteId && (
          <ZenSimpleConfirmationModal
            isSubmitting={isSubmitting}
            variant='error'
            confirmButtonText='Confirm'
            cancelButtonText='Cancel'
            title='Withdraw invitation?'
            isOpen={isWithdrawModalOpen}
            onClose={() => setIsWithdrawModalOpen(false)}
            onConfirm={handleWithdrawInvitation}
          />
        )}
      </div>
    </ZenSidebarModal>
  );
};

export default ZenJointVentureSibeBarModel;
