import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { debounce, values } from 'lodash';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form-v7';
import {
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
} from '../../openapi/yenta';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import {
  EMAIL_VALIDATIONS,
  getPostalCodeValidation,
  GOOGLE_AUTO_COMPLETE_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../utils/Validations';
import IconButton from '../IconButton';
import ZenControlledEmailInput from '../Zen/Input/ZenControlledEmailInput';
import ZenControlledGoogleAutocompleteSearchInput from '../Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledHTMLSelectInput from '../Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledPhoneNumberInput from '../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledStateOrProvinceInput from '../Zen/Input/ZenControlledStateOrProvince';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import { CreateDirectoryRequestFormData } from './DirectoryUtils';

interface ZenPersonInformationProps {
  isAddressManual?: boolean;
  toggleAddressMode?: () => void;
  role?: string;
}

const ZenPersonInformationForm: React.FC<ZenPersonInformationProps> = ({
  isAddressManual,
  toggleAddressMode,
  role,
}) => {
  const {
    control,
    setValue,
    watch,
  } = useFormContext<CreateDirectoryRequestFormData>();
  const [country] = watch(['country']);
  const isClientRoleSelected = role === 'CLIENT';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string, eventData?: Record<string, unknown>) => {
      AnalyticsService.instance().logEvent(event, eventData);
    }, 500),
    [],
  );

  return (
    <div>
      <div className='flex flex-row mt-5 space-x-2'>
        <ZenControlledTextInput<CreateDirectoryRequestFormData, 'firstName'>
          control={control}
          label={isClientRoleSelected ? 'First Name / Company' : 'First Name'}
          name='firstName'
          placeholder='First Name'
          rules={{
            required: isClientRoleSelected
              ? 'Please enter a first name or company name'
              : 'Please enter a first name',
            minLength: {
              value: 2,
              message: 'Must be at least 2 characters',
            },
          }}
          onChangeSpy={() =>
            logAnalyticsEvent(
              AnalyticsEventEnum.DIRECTORY_CONTACT_FIRST_NAME_OR_COMPANY_ENTERED,
            )
          }
          isRequired
        />
        <ZenControlledTextInput<CreateDirectoryRequestFormData, 'lastName'>
          control={control}
          label={isClientRoleSelected ? 'Last Name / Company' : 'Last Name'}
          name='lastName'
          placeholder='Last Name'
          rules={{
            required: isClientRoleSelected
              ? 'Please enter a last name or company'
              : 'Please enter a last name',
            minLength: {
              value: 2,
              message: 'Must be at least 2 characters',
            },
          }}
          onChangeSpy={() =>
            logAnalyticsEvent(
              AnalyticsEventEnum.DIRECTORY_CONTACT_LAST_NAME_OR_COMPANY_ENTERED,
            )
          }
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledEmailInput<CreateDirectoryRequestFormData, 'emailAddress'>
          control={control}
          label='Email Address'
          name='emailAddress'
          shouldUnregister={false}
          placeholder='E.g. John@example.com'
          rules={{
            required: 'Please enter valid email address',
            ...EMAIL_VALIDATIONS,
          }}
          onChangeSpy={() =>
            logAnalyticsEvent(
              AnalyticsEventEnum.DIRECTORY_CONTACT_EMAIL_ENTERED,
            )
          }
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledPhoneNumberInput<
          CreateDirectoryRequestFormData,
          'phoneNumber'
        >
          control={control}
          label='Phone Number'
          name='phoneNumber'
          shouldUnregister={false}
          placeholder='E.g. +1 (702) 123-4567'
          rules={{
            required: 'Please enter phone number',
            ...PHONE_NUMBER_VALIDATIONS,
          }}
          onChangeSpy={() =>
            logAnalyticsEvent(
              AnalyticsEventEnum.DIRECTORY_CONTACT_PHONE_ENTERED,
            )
          }
          isRequired
        />
      </div>
      <div className='mt-8 relative'>
        {!isAddressManual ? (
          <ZenControlledGoogleAutocompleteSearchInput<
            CreateDirectoryRequestFormData,
            'autoComplete'
          >
            control={control}
            shouldUnregister={false}
            name='autoComplete'
            label='Address'
            placeholder='E.g. 1st St. New York, NY 10011'
            rules={{ ...GOOGLE_AUTO_COMPLETE_VALIDATIONS }}
            onChangeSpy={() =>
              logAnalyticsEvent(
                AnalyticsEventEnum.DIRECTORY_CONTACT_ADDRESS_ENTERED,
              )
            }
            isRequired
          />
        ) : (
          <div className='p-4 border-2 border-gray-300 rounded-lg bg-grey-100'>
            <p className='font-normal text-base'>Address</p>
            <div className='mt-4'>
              <ZenControlledTextInput<CreateDirectoryRequestFormData, 'street'>
                control={control}
                label='Street'
                name='street'
                shouldUnregister={false}
                placeholder='E.g. 270 1st Ave.'
                rules={{
                  required: 'Street is required',
                }}
                isRequired
              />
            </div>
            <div className='mt-4'>
              <ZenControlledTextInput<CreateDirectoryRequestFormData, 'street2'>
                control={control}
                label='Street Line 2'
                name='street2'
                shouldUnregister={false}
                placeholder='E.g. 270 1st Ave'
              />
            </div>
            <div className='flex flex-row space-x-4 mt-4'>
              <ZenControlledHTMLSelectInput<
                CreateDirectoryRequestFormData,
                'country'
              >
                name='country'
                control={control}
                label='Country'
                shouldUnregister={false}
                placeholder='Country'
                options={[
                  {
                    label: 'Select Country',
                    value: '',
                  },
                  ...values(AddressRequestCountryEnum).map((country) => ({
                    value: country,
                    label: capitalizeEnum(country),
                  })),
                ]}
                rules={{ required: 'Please select a country' }}
                isRequired
              />
              <ZenControlledStateOrProvinceInput<
                CreateDirectoryRequestFormData,
                'state'
              >
                name='state'
                control={control}
                shouldUnregister={false}
                setValue={setValue}
                selectedCountry={
                  (country as unknown) as AddressRequestCountryEnum
                }
                rules={{
                  required: 'State is required',
                }}
                defaultValue={
                  ('' as unknown) as AddressRequestStateOrProvinceEnum
                }
                isRequired
              />
            </div>
            <div className='flex flex-row space-x-4 mt-4'>
              <ZenControlledTextInput<CreateDirectoryRequestFormData, 'city'>
                control={control}
                label='City'
                name='city'
                shouldUnregister={false}
                placeholder='Eg. New York'
                rules={{
                  required: 'City is required',
                }}
                isRequired
              />
              <ZenControlledTextInput<CreateDirectoryRequestFormData, 'zip'>
                control={control}
                label='ZIP/Postal Code'
                name='zip'
                shouldUnregister={false}
                placeholder={
                  country === AddressRequestCountryEnum.Canada
                    ? 'E.g. M5V 1A1'
                    : 'E.g. 10001'
                }
                rules={{
                  required: 'Zip code is required',
                  ...getPostalCodeValidation(
                    country as AddressRequestCountryEnum,
                  ),
                }}
                isRequired
              />
            </div>
          </div>
        )}
        <div
          className={`absolute ${
            !isAddressManual ? '-top-3' : 'top-3 mr-3'
          } right-0`}
        >
          <IconButton
            label={
              !isAddressManual ? 'Enter address manually' : 'Search Address'
            }
            variant='none'
            buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
            leftIcon={<FontAwesomeIcon icon={faArrowUpArrowDown} />}
            onClick={() => toggleAddressMode?.()}
          />
        </div>
      </div>
    </div>
  );
};

export default ZenPersonInformationForm;
