import ZenAvatar from '../../ZenAvatar';

interface ProfileImageCellProps {
  imageUrl?: string;
  name: string;
}

const ZenProfileImageCell: React.FC<ProfileImageCellProps> = ({
  imageUrl,
  name,
}) => {
  return (
    <div className='h-8 w-8'>
      <ZenAvatar name={name} size='sm' imageUrl={imageUrl} />
    </div>
  );
};

export default ZenProfileImageCell;
