import React from 'react';
import { useSelector } from 'react-redux';
import ZenOutgoingPaymentsComponent from '../../components/outgoingPayment/ZenOutgoingPaymentsComponent';
import { RootState } from '../../types';
import ZenRoute from '../../components/Zen/ZenRoute';

interface ZenAgentDetailOutgoingPaymentsRouteProps {
  agentId: string;
}

const ZenAgentDetailOutgoingPaymentsRoute: React.FC<ZenAgentDetailOutgoingPaymentsRouteProps> = ({
  agentId,
}) => {
  const { detailResponse } = useSelector(
    (state: RootState) => state.agentDetail,
  );

  return (
    <ZenRoute title={`Outgoing Payments - ${detailResponse.data?.fullName}`}>
      <ZenOutgoingPaymentsComponent
        hiddenColumns={['displayLine1', 'transactionCode']}
        agentId={agentId}
      />
    </ZenRoute>
  );
};

export default ZenAgentDetailOutgoingPaymentsRoute;
