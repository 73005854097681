import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import ZenProcessRevshareSidebarModal from '../../../forms/ZenProcessRevshareSidebarModal';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface ZenRevShareProps {}

const ZenRevShare: React.FC<ZenRevShareProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div>
      <ZenResourceLinkCard
        title='Process Revshare'
        onClick={() => setIsOpen(true)}
        icon={light('chart-mixed')}
      />
      <ZenProcessRevshareSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenRevShare;
