import React from 'react';
import PhoneImage from '../../../assets/img/zen/real-mortgage/V2/phone-2.png';
import { BOOK_A_MEETING } from '../../../constants/RealMortgageConstants';
import ServicePill from '../../ServicePill';

export const QUOTE_POINTS = [
  'Transparent and Efficient Technology',
  'Competitive Pricing',
  '1000+ 5 Star Google Reviews',
  'Variety of Mortgage Products',
  'One Real Advantage',
  'Agent Support',
];

const OneRealMortgageGetQuote: React.FC = () => {
  return (
    <div className='flex flex-col sm:flex-row items-center gap-6'>
      <div className='w-full sm:w-1/2 flex flex-col'>
        <h1 className='text-[40px] font-semibold font-poppins'>Get a Quote</h1>
        <p className='font-light mt-3'>
          One Real Mortgage offers a full-service experience to provide you and
          your clients with confidence and certainty while working efficiently
          to close your loan on time.
        </p>
        <div className='flex flex-wrap gap-3 mt-6'>
          {QUOTE_POINTS.map((point) => {
            return <ServicePill key={point} label={point} />;
          })}
        </div>
        <div className='font-semibold mt-6'>
          <a
            href={BOOK_A_MEETING}
            target='_blank'
            rel='noopener noreferrer'
            className='text-primary-blue underline'
          >
            Let&apos;s connect
          </a>{' '}
          to see how we can work together.
        </div>
      </div>
      <div className='w-full sm:max-w-xl mx-auto'>
        <img
          src={PhoneImage}
          alt='get a quote'
          className='object-contain'
          data-testid='get-a-quote-image'
        />
      </div>
    </div>
  );
};

export default OneRealMortgageGetQuote;
