import { IdentitySummaryResponse } from '../openapi/keymaker';
import { AgentResponse } from '../openapi/yenta';
import IAnalyticsService from './interface/IAnalyticsService';
import GoogleAnalyticsGateway from './GoogleAnalyticsGateway';

class AnalyticsService implements IAnalyticsService {
  private gateway: IAnalyticsService[];
  private static _instance: AnalyticsService;

  constructor() {
    this.gateway = [new GoogleAnalyticsGateway()];
  }

  public static instance(): AnalyticsService {
    if (!AnalyticsService._instance) {
      AnalyticsService._instance = new AnalyticsService();
    }
    return AnalyticsService._instance;
  }

  initialize() {
    this.gateway.forEach((gateway) => gateway.initialize());
  }

  setUser(
    userDetail: AgentResponse,
    keymakerCurrentUser: IdentitySummaryResponse,
  ) {
    this.gateway.forEach((gateway) =>
      gateway.setUser(userDetail, keymakerCurrentUser),
    );
  }

  logEvent(eventName: string, eventData: Record<string, unknown> = {}) {
    this.gateway.forEach((gateway) => gateway.logEvent(eventName, eventData));
  }
}

export default AnalyticsService;
