import { FC, useState } from 'react';
import { TeamResponse } from '../../../../openapi/yenta';
import AnalyticsService from '../../../../services/AnalyticsService';
import { AnalyticsEventEnum, ISelectOption } from '../../../../types';
import { ITab } from '../../../Tabs';
import ZenSidebarModal from '../../ZenSidebarModal';
import ZenTabs from '../../ZenTabs';
import ZenInviteAgentViaEmail from './ZenInviteAgentViaEmail';
import ZenInviteAgentsViaLink from './ZenInviteAgentViaLink';

interface ZenInviteSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  options?: ISelectOption[];
  team: TeamResponse;
  refetch(): void;
}

enum InviteAgentEnum {
  InviteViaEmail = 'Invite via Email',
  InviteViaLink = 'Invite via Link',
}

const ZenInviteSidebarModal: FC<ZenInviteSidebarModalProps> = ({
  isOpen,
  onClose,
  options = [],
  team,
  refetch,
}) => {
  const [selectedTab, setSelectedTab] = useState<string>(
    InviteAgentEnum.InviteViaEmail,
  );

  const tabs: ITab[] = [
    {
      name: InviteAgentEnum.InviteViaEmail,
      TabComponent: (
        <ZenInviteAgentViaEmail
          onClose={onClose}
          options={options}
          team={team}
          refetch={refetch}
        />
      ),
    },
    {
      name: InviteAgentEnum.InviteViaLink,
      TabComponent: (
        <ZenInviteAgentsViaLink
          onClose={onClose}
          options={options}
          team={team}
        />
      ),
    },
  ];

  return (
    <ZenSidebarModal
      isOpen={isOpen}
      onClose={() => {
        if (selectedTab === InviteAgentEnum.InviteViaEmail) {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_X_CLOSE_SIDEBAR,
          );
        } else if (selectedTab === InviteAgentEnum.InviteViaLink) {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.SELF_SERVICE_TEAM_INVITE_VIA_LINK_X_CLOSE_SIDEBAR,
          );
        }

        onClose();
      }}
      title='Invite Agent'
    >
      <ZenTabs
        tabs={tabs}
        onChange={(tabName) => {
          // check if tab clicked is Invite via Email | Invite via Link
          if (tabName === InviteAgentEnum.InviteViaEmail) {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.SELF_SERVICE_TEAM_CLICK_INVITE_VIA_EMAIL_TAB,
            );
          } else if (tabName === InviteAgentEnum.InviteViaLink) {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.SELF_SERVICE_TEAM_CLICK_INVITE_VIA_LINK_TAB,
            );
          }

          setSelectedTab(tabName);
        }}
        equalWidths
        selected={selectedTab}
        size='lg'
      />
    </ZenSidebarModal>
  );
};

export default ZenInviteSidebarModal;
