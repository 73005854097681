import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AppDispatch, RootState } from '../../../types';
import {
  RevshareContributorsResponse,
  RevShareControllerApi,
} from '../../../openapi/arrakis';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import ErrorService from '../../../services/ErrorService';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import ResourceContainer from '../../ResourceContainer';
import { displayAmount } from '../../../utils/CurrencyUtils';
import DefaultLoader from '../../DefaultLoader';
import { getFullName } from '../../../utils/AgentHelper';
import ZenAvatar from '../../Zen/ZenAvatar';
import { FilterDates } from '../../../utils/RevShareInsightsUtils';
import Hover from '../../Hover';
import { formatPhoneNumber } from '../../../utils/StringUtils';
import RevShareInsightsContributionByTierCards from '../RevShareInsightsContributionByTierCards';
import RevShareInsightsTierFilters from '../RevShareInsightsTierFilters';

interface RevShareInsightsContributorsByTierSidebarProps {
  isOpen: boolean;
  onClose(): void;
  agentId: string;
  initialTier: number;
  filterDates: FilterDates;
}

const RevShareInsightsContributorsByTierSidebar: React.FC<RevShareInsightsContributorsByTierSidebarProps> = ({
  isOpen,
  onClose,
  agentId,
  initialTier,
  filterDates,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState(true);
  const [tier, setTier] = useState(initialTier);
  const [
    revShareContributorsByTier,
    setRevShareContributorsByTier,
  ] = useState<RevshareContributorsResponse>();

  const {
    revenue: { revShareByTierResponse },
  } = useSelector((state: RootState) => state);

  const fetchData = useCallback(
    async (pageNum: number) => {
      if (pageNum === 0) {
        setLoading(true);
      }
      try {
        const { data } = await new RevShareControllerApi(
          getArrakisConfiguration(),
        ).getContributorsByTier(
          agentId,
          tier,
          filterDates.startDate,
          filterDates.endDate,
          pageNum,
          20,
        );
        if (pageNum === 0) {
          setRevShareContributorsByTier(data);
        } else {
          setRevShareContributorsByTier((prev) => ({
            ...data,
            contributors: (prev?.contributors || []).concat(
              data.contributors || [],
            ),
          }));
        }
      } catch (e) {
        dispatch(showApiErrorModal(e));
        dispatch(
          showErrorToast(
            'We were unable to fetch information on your revenue share contributors by tier',
          ),
        );
        ErrorService.notify(
          'Unable to fetch information on revshare contributors by tier',
          e,
          {
            agent: { id: agentId },
          },
        );
      }
      setLoading(false);
    },
    [agentId, dispatch, filterDates.endDate, filterDates.startDate, tier],
  );

  useEffect(() => {
    fetchData(0);
  }, [fetchData]);

  const handleNext = useCallback(() => {
    if (revShareContributorsByTier?.hasNext) {
      fetchData((revShareContributorsByTier?.pageNumber || 0) + 1);
    }
  }, [
    fetchData,
    revShareContributorsByTier?.hasNext,
    revShareContributorsByTier?.pageNumber,
  ]);

  const revShareByTier =
    revShareByTierResponse.data?.tierRevshareResponses || [];
  const tierRevShare = revShareByTier[tier - 1];
  const contributors = revShareContributorsByTier?.contributors;

  return (
    <ZenSidebarModal isOpen={isOpen} onClose={onClose} title='Contributors'>
      <div className='p-4'>
        <div className='mb-4 space-y-4'>
          <RevShareInsightsTierFilters
            tier={tier}
            onChange={setTier}
            loading={loading}
          />
          <RevShareInsightsContributionByTierCards
            tierRevShare={tierRevShare}
          />
        </div>
        <div className='-mx-4'>
          <div className='bg-grey-100 flex items-center justify-between px-4 py-2'>
            <p className='font-zen-body text-zen-dark-12 text-xs font-medium'>
              CONTRIBUTORS ({revShareContributorsByTier?.totalCount || 0})
            </p>
          </div>
          <ResourceContainer
            loading={loading}
            isEmpty={isEmpty(contributors)}
            resourceName='contribution'
          >
            <div className='divide-y divide-zen-light-gray-2 text-zen-dark-9 font-zen-body'>
              <InfiniteScroll
                hasMore={!!revShareContributorsByTier?.hasNext}
                loader={<DefaultLoader />}
                dataLength={contributors?.length || 0}
                scrollableTarget='modal-scroll'
                next={handleNext}
              >
                {contributors?.map((value) => {
                  const name = getFullName(value) || 'N/A';

                  return (
                    <div
                      key={value.id}
                      aria-label='contributor-row'
                      className='px-4 py-2 flex items-center justify-between space-x-2 font-zen-body'
                    >
                      <div className='flex items-center space-x-2'>
                        <ZenAvatar name={name} imageUrl={value.avatar} />
                        <div>
                          <p className='font-medium'>{name}</p>
                          <div className='ml-1 flex items-center space-x-3'>
                            <Hover
                              hoverComponent={
                                <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                                  {value.emailAddress || 'N/A'}
                                </div>
                              }
                              config={{ trigger: 'hover', placement: 'top' }}
                            >
                              <FontAwesomeIcon
                                icon={regular('envelope')}
                                title='email'
                                aria-label='emailIcon'
                                className='cursor-pointer text-base text-primary-blue'
                              />
                            </Hover>
                            <Hover
                              hoverComponent={
                                <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                                  {formatPhoneNumber(value.phoneNumber) ||
                                    'N/A'}
                                </div>
                              }
                              config={{ trigger: 'hover', placement: 'top' }}
                            >
                              <FontAwesomeIcon
                                icon={regular('phone')}
                                title='phone'
                                aria-label='phoneIcon'
                                className='cursor-pointer text-base text-primary-blue'
                              />
                            </Hover>
                          </div>
                        </div>
                      </div>
                      <p className='font-medium'>
                        {displayAmount(value.contributionAmount) || 'N/A'}
                      </p>
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          </ResourceContainer>
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default RevShareInsightsContributorsByTierSidebar;
