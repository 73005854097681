import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RoleControllerApi,
  RoleResponse,
  UserControllerApi,
} from '../openapi/keymaker';
import ErrorService from '../services/ErrorService';
import { AppDispatch, AppThunk, AsyncResponse, RoleSliceState } from '../types';
import { getKeymakerConfiguration } from '../utils/OpenapiConfigurationUtils';
import { performAsyncRequest } from './utils/SliceUtil';
import { fetchIdentityByKeymakerId } from './AuthSlice';
import { showApiErrorModal } from './ErrorSlice';
import { showErrorToast } from './ToastNotificationSlice';

export const initialState: RoleSliceState = {
  rolesResponse: { loading: false, name: 'Roles' },
  grantorRolesResponse: { data: [], loading: false, name: 'Grantor Roles' },
};

const RoleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles(state, action: PayloadAction<AsyncResponse<RoleResponse[]>>) {
      state.rolesResponse = action.payload;
    },
    setGrantorRoles(
      state,
      action: PayloadAction<AsyncResponse<RoleResponse[]>>,
    ) {
      state.grantorRolesResponse = action.payload;
    },
  },
});

export const { setRoles, setGrantorRoles } = RoleSlice.actions;

export const getAllRoles = (): AppThunk => async (dispatch) => {
  const fetch = () =>
    new RoleControllerApi(getKeymakerConfiguration()).getRoles();

  await performAsyncRequest(dispatch as AppDispatch, 'Roles', setRoles, fetch, {
    skipAuthDatadog: true,
  });
};

export const getGrantorRoles = (): AppThunk => async (dispatch) => {
  const fetch = () =>
    new RoleControllerApi(getKeymakerConfiguration()).getAllGrantorRoles();

  await performAsyncRequest(
    dispatch as AppDispatch,
    'Grantor Roles',
    setGrantorRoles,
    fetch,
    {
      skipAuthDatadog: true,
    },
  );
};

export const grantRole = (keymakerId: string, role: string): AppThunk => async (
  dispatch,
) => {
  try {
    await new UserControllerApi(getKeymakerConfiguration()).grantRole(
      keymakerId,
      role,
    );

    await dispatch(fetchIdentityByKeymakerId(keymakerId));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to grant role', e, {
      agent: { keymakerId },
      role: { role },
    });
    dispatch(
      showErrorToast(
        'We had a problem granting selected role to the user.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const revokeRole = (
  keymakerId: string,
  role: string,
): AppThunk => async (dispatch) => {
  try {
    await new UserControllerApi(getKeymakerConfiguration()).revokeRole(
      keymakerId,
      role,
    );

    await dispatch(fetchIdentityByKeymakerId(keymakerId));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to revoke role', e, {
      agent: { keymakerId },
      role: { role },
    });
    dispatch(
      showErrorToast(
        'We had a problem revoking selected role to the user.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export default RoleSlice.reducer;
