import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faLock } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import {
  revShareInsightsTierColorMap,
  revShareInsightsTierIconUnlockedMap,
} from '../../utils/RevShareInsightsUtils';
import { AnalyticsEventEnum, RootState } from '../../types';
import { FrontLineAgentsResponse } from '../../openapi/arrakis';
import AnalyticsService from '../../services/AnalyticsService';
import RevShareInsightsTierProgressBar from './RevShareInsightsTierProgressBar';

const InfoIcon: React.FC<{
  frontLineInfo: FrontLineAgentsResponse | undefined;
}> = ({ frontLineInfo }) => {
  const [isNetworkTooltipOpen, setIsNetworkTooltipOpen] = useState(false);

  const completeSponsors = frontLineInfo?.frontLineAgents?.filter(
    (a) => a.sponsorSplit === 100,
  );
  const uncompletedSponsors = frontLineInfo?.frontLineAgents?.filter(
    (a) => a.sponsorSplit !== 100,
  );

  return (
    <Popover
      isOpen={isNetworkTooltipOpen}
      positions={['bottom', 'left', 'right', 'top']}
      padding={5}
      align='center'
      onClickOutside={() => setIsNetworkTooltipOpen(false)}
      content={({ position, childRect, popoverRect }) => {
        return (
          <ArrowContainer
            position={position}
            popoverRect={popoverRect}
            childRect={childRect}
            arrowColor='white'
            arrowSize={10}
            className='popover-arrow-container drop-shadow-lg'
          >
            <div className='bg-white py-3 px-4 pr-10 rounded-md font-zen-body text-sm text-zen-dark-9'>
              <p className='font-semibold'>Sponsorship type</p>
              <div className='mt-2 space-y-0.5'>
                <p>
                  <span className='font-bold'>100%</span> -{' '}
                  {completeSponsors?.length || 0} Agents
                </p>
                <p>
                  <span className='font-bold'>50%</span> -{' '}
                  {uncompletedSponsors?.length || 0} Agents
                </p>
              </div>
            </div>
          </ArrowContainer>
        );
      }}
    >
      <FontAwesomeIcon
        icon={faCircleInfo}
        className='text-zen-dark-9 ml-1 cursor-pointer'
        onClick={() => {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.RSI_OPEN_TOOLTIP_TOTAL_TIER_1_AGENTS,
          );
          setIsNetworkTooltipOpen(true);
        }}
      />
    </Popover>
  );
};

interface RevShareInsightsMyTierProgressProps {
  agentId: string;
  onClickTierAgents(tier: number): void;
  onClickMyUpline(show: boolean): void;
  onClickMyNetwork(show: boolean): void;
}

const RevShareInsightsMyTierProgress: React.FC<RevShareInsightsMyTierProgressProps> = ({
  agentId,
  onClickTierAgents,
  onClickMyUpline,
  onClickMyNetwork,
}) => {
  const {
    agentDetail,
    network: { zenNetworksById },
    revenue: { networkSizeByTierResponse },
  } = useSelector((state: RootState) => state);

  const frontLineInfo = zenNetworksById[agentId];
  const currentNetworkSizeByTier = networkSizeByTierResponse.data;
  const unLockValue =
    frontLineInfo?.tierUnlocked ||
    agentDetail.incomeOverviewResponse.data?.revShareStatus
      ?.unlockedRevShareTier!;
  const UnlockedBadge = revShareInsightsTierIconUnlockedMap[unLockValue];

  return (
    <div
      className='border rounded-[10px] px-4 md:px-6 py-2.5 font-zen-body text-zen-dark-9 border-zen-dark-4'
      aria-label='tier progress'
    >
      <div className='flex items-center justify-between text-sm'>
        <div className='space-y-1.5'>
          <p className='font-semibold'>
            Total Producing Tier 1 Agents:{' '}
            {frontLineInfo?.numberOfFrontLineAgentsUnlockingTier || 0}
            <InfoIcon frontLineInfo={frontLineInfo} />
          </p>
          <p className='text-zen-dark-7'>
            {currentNetworkSizeByTier?.totalNetworkSize || 0} Agents in your
            total network
          </p>
        </div>
      </div>
      <div className='mt-2 flex items-center justify-between'>
        <button
          onClick={() => onClickMyUpline(true)}
          className='flex flex-row items-center justify-center space-x-1 font-zen-body font-semibold'
        >
          <p className='text-primary-blue text-sm'>My Upline</p>
          <FontAwesomeIcon
            icon={faArrowRightLong}
            className='text-primary-blue text-base'
          />
        </button>
        <button
          onClick={() => onClickMyNetwork(true)}
          className='flex flex-row items-center justify-center space-x-1 font-zen-body font-semibold'
        >
          <p className='text-primary-blue text-sm'>My Network</p>
          <FontAwesomeIcon
            icon={faArrowRightLong}
            className='text-primary-blue text-base'
          />
        </button>
      </div>
      <div className='mt-3 -mx-4 md:-mx-6 pt-2 flex flex-col items-center border-t border-zen-dark-4'>
        {!!UnlockedBadge && <UnlockedBadge />}
        <p
          className='mt-1 text-sm text-center font-zen-body font-medium'
          style={{ color: revShareInsightsTierColorMap[unLockValue] }}
        >
          Tier {unLockValue} Unlocked
        </p>
      </div>
      <div className='mt-5'>
        <RevShareInsightsTierProgressBar
          currentNetworkSizeByTier={currentNetworkSizeByTier}
          onClickTierAgents={onClickTierAgents}
        />
      </div>
      {unLockValue < 5 && (
        <div className='pt-4 flex items-center justify-center space-x-2 text-center text-zen-dark-7'>
          <FontAwesomeIcon icon={faLock} />
          <p>Next tier {unLockValue + 1}</p>
          <div className='w-1.5 h-1.5 bg-zen-dark-12 rounded-full' />
          <p>
            {frontLineInfo?.numberOfFrontLineAgentsToUnlockNextTier || 'N/A'}{' '}
            agents to unlock
          </p>
        </div>
      )}
    </div>
  );
};

export default RevShareInsightsMyTierProgress;
