import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../routes/AgentWebsiteOnboardingRoute';
import { AGENT_ONBOARDING_STEPS } from '../../testUtils/AgentWebsiteOnboardingUtils';
import { AgentWebsiteOnboardingStepEnum } from '../../types';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import ApplicationStepProgressHeader from '../onboarding/ApplicationStepProgressHeader';
import Accordion from '../Accordion';
import Button from '../Button';
import StepByStepContainerPagination from '../StepByStep/StepByStepContainerPagination';
import AgentWebsiteOnboardingThemeReview from './review/AgentWebsiteOnboardingThemeReview';
import AgentWebsiteOnboardingContactInfoReview from './review/AgentWebsiteOnboardingContactInfoReview';
import AgentWebsiteOnboardingAboutContentReview from './review/AgentWebsiteOnboardingAboutContentReview';
import AgentWebsiteOnboardingMainContentReview from './review/AgentWebsiteOnboardingMainContentReview';
import AgentWebsiteOnboardingFeaturedContentReview from './review/AgentWebsiteOnboardingFeaturedContentReview';
import AgentWebsiteOnboardingTestimonialsReview from './review/AgentWebsiteOnboardingTestimonialsReview';
import AgentWebsiteOnboardingListingsReview from './review/AgentWebsiteOnboardingListingsReview';
import AgentWebsiteConfirmationModal from './AgentWebsiteConfirmationModal';

interface Params {
  id: string;
}

const AgentWebsiteOnboardingReviewAndPublish: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = (props) => {
  const history = useHistory();
  const { id } = useParams<Params>();
  const [publish, setPublish] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);

  return (
    <div className='h-full w-full flex flex-col items-center max-w-6xl mx-auto'>
      <ApplicationStepProgressHeader
        steps={AGENT_ONBOARDING_STEPS}
        inProgressIndex={AgentWebsiteOnboardingStepEnum.REVIEW}
      />
      <div className='w-full h-full'>
        <p className='my-5 font-primary-regular text-base text-dark leading-loose'>
          Almost done! Make sure all your content and listings, based on your
          search criteria are looking good.
        </p>
        <div className='my-5'>
          <Accordion title='Theme' isOpenDefault>
            <AgentWebsiteOnboardingThemeReview {...props} />
          </Accordion>
          <Accordion title='Contact Info'>
            <AgentWebsiteOnboardingContactInfoReview {...props} />
          </Accordion>
          <Accordion title='About Content'>
            <AgentWebsiteOnboardingAboutContentReview {...props} />
          </Accordion>
          <Accordion title='Main Content'>
            <AgentWebsiteOnboardingMainContentReview {...props} />
          </Accordion>
          <Accordion title='Featured Content'>
            <AgentWebsiteOnboardingFeaturedContentReview {...props} />
          </Accordion>
          <Accordion title='Testimonials'>
            <AgentWebsiteOnboardingTestimonialsReview {...props} />
          </Accordion>
          <Accordion title='Listings (Search criteria)'>
            <AgentWebsiteOnboardingListingsReview {...props} />
          </Accordion>
        </div>
      </div>
      <div className='sticky max-w-6xl w-full bottom-0 z-50 bg-white'>
        <StepByStepContainerPagination
          previousText='Cancel'
          onPrevious={() => setCancel(true)}
          isNextLoading={props.form.formState.isSubmitting}
          nextText='Publish'
          onNext={() => setPublish(true)}
        />
      </div>
      <AgentWebsiteConfirmationModal
        variant='upload'
        title='Publish your Real website?'
        subtitle='Your Real website will be live in a few minutes.'
        isOpen={publish}
        onClose={() => setPublish(false)}
      >
        <div className='flex flex-row justify-between space-x-5 mt-10'>
          <Button
            label='Cancel'
            type='outline'
            size='lg'
            className='text-opacity-40'
            fullWidth
            onClick={() => setPublish(false)}
          />
          <Button
            label='Publish'
            type='primary'
            buttonType='submit'
            size='lg'
            fullWidth
            onClick={() => {
              props.onSubmit();
              setPublish(false);
            }}
          />
        </div>
      </AgentWebsiteConfirmationModal>
      <AgentWebsiteConfirmationModal
        variant='cancel'
        title='Close and discard all edits?'
        subtitle="Changes won't be saved and you'll go back to the start."
        isOpen={cancel}
        onClose={() => setCancel(false)}
      >
        <div className='flex flex-row justify-between space-x-5 mt-10'>
          <Button
            label='No'
            type='outline'
            size='lg'
            className='text-opacity-40'
            fullWidth
            onClick={() => setCancel(false)}
          />
          <Button
            label='Yes'
            size='lg'
            type='danger'
            buttonType='submit'
            fullWidth
            onClick={() => history.push(`/people/${id}`)}
          />
        </div>
      </AgentWebsiteConfirmationModal>
    </div>
  );
};

export default AgentWebsiteOnboardingReviewAndPublish;
