import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ZenCardWithItems from '../Zen/ZenCardWithItems';
import ZenView from '../Zen/ZenView';
import { displayAmount, DisplayAmountOptions } from '../../utils/CurrencyUtils';
import { RootState } from '../../types';
import { DemoModeContainer } from '../../containers/DemoModeContainer';
import { WillBeneficiaryAndTypeResponseTypeEnum } from '../../openapi/yenta';

interface RevShareInsightsOverviewCardProps {
  onClickBeneficiary(): void;
}

const RevShareInsightsOverviewCard: React.FC<RevShareInsightsOverviewCardProps> = ({
  onClickBeneficiary,
}) => {
  const history = useHistory();

  const {
    auth: { isAdmin, isBroker },
    network: { zenNetworksById },
    agentDetail,
  } = useSelector((state: RootState) => state);

  const agentDetailResponse = agentDetail?.detailResponse?.data;
  const incomeOverviewResponse = agentDetail?.incomeOverviewResponse?.data;
  const network = zenNetworksById[agentDetailResponse?.id!];
  const isWillBeneficiaryTypeNone =
    !agentDetail.willBeneficiaryAndTypeResponse.data ||
    agentDetail.willBeneficiaryAndTypeResponse.data?.type ===
      WillBeneficiaryAndTypeResponseTypeEnum.None;
  const showBeneficiaryButton = !isAdmin && !isBroker;

  const displayAmountOptions: DisplayAmountOptions = {
    hideZeroCents: true,
    hideCurrency: true,
  };

  return (
    <div aria-label='revshare-insights'>
      <ZenCardWithItems
        title='Revenue Share Insights'
        RightComponent={
          <DemoModeContainer hide>
            <ZenView
              onClick={() =>
                history.push(
                  `/people/${agentDetailResponse?.id!}/revshare/insights`,
                )
              }
            />
          </DemoModeContainer>
        }
        variant='regular'
        items={[
          {
            name: 'Paid Earnings',
            value: displayAmount(
              incomeOverviewResponse?.revShareEarnings,
              displayAmountOptions,
            ),
            ValueWrapper: DemoModeContainer,
          },
          {
            name: 'Network Size',
            value: agentDetailResponse?.sizeOfNetwork,
          },
          {
            name: 'My Tier 1 Agents',
            value: network?.numberOfFrontLineAgentsUnlockingTier || 0,
          },
          {
            name: 'Tier unlocked',
            value: network?.tierUnlocked || 'N/A',
          },
          {
            name: 'Pending Payment',
            value: displayAmount(
              agentDetail.revShareOverview?.pendingEarnings,
              displayAmountOptions,
            ),
            ValueWrapper: DemoModeContainer,
          },
        ]}
        authResponse={agentDetail.incomeOverviewResponse}
        BottomComponent={
          showBeneficiaryButton ? (
            <button
              className='mt-2 flex items-center space-x-1 px-4 text-primary-blue font-zen-body font-semibold'
              onClick={onClickBeneficiary}
            >
              <p className='text-base'>
                {isWillBeneficiaryTypeNone
                  ? 'Designate Beneficiary'
                  : 'Change Beneficiary'}
              </p>
              <FontAwesomeIcon
                icon={faChevronRight}
                className='mt-0.5 text-sm'
              />
            </button>
          ) : undefined
        }
      />
    </div>
  );
};

export default RevShareInsightsOverviewCard;
