import { useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  FlexTeamDto,
  TeamMemberResponse,
  TeamResponse,
  TeamResponseTypeEnum,
  TransferAgentBetweenTeamsRequestToTeamRolesEnum,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { transferAgentBetweenTeams } from '../../../slices/TeamSlice';
import { AppDispatch, ISelectOption } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { teamSearchRequest } from '../../../utils/TableUtils';
import {
  getCommissionValidation,
  mapTeamRoleToType,
} from '../../../utils/TeamHelper';
import ZenControlledAsyncSelectInput from '../Input/ZenControlledAsyncSelectInput';
import ZenControlledSelectInput from '../Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenSidebarModalActionFooterV2 from '../Modal/ZenSidebarModalActionFooterV2';
import ZenSidebarModal from '../ZenSidebarModal';

interface ZenTransferTeamAgentProps {
  member: TeamMemberResponse;
  team: TeamResponse | FlexTeamDto;
  onClose(): void;
}

interface FormData {
  commissionSplit: number;
  role: ISelectOption;
  agentId: ISelectOption;
  fromTeamId: ISelectOption;
  toTeamIdAndType: ISelectOption;
}

const ZenTransferTeamAgent: React.FC<ZenTransferTeamAgentProps> = ({
  member,
  team,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      agentId: { label: member?.agent?.fullName, value: member?.agent?.id },
      fromTeamId: { label: team?.name!, value: team?.id! },
      role: {
        label: capitalizeEnum(member?.roles![0]),
        value: member?.roles![0],
      },
    },
  });

  const [agentCommission, toTeamIdAndType] = watch([
    'commissionSplit',
    'toTeamIdAndType',
  ]);
  const toTeamType = toTeamIdAndType?.value.split(
    '.',
  )[1] as TeamResponseTypeEnum;
  const leaderCommission = `Leader Commission: ${
    100 - parseFloat(String(agentCommission || 0))
  }%`;

  const onSubmit = async (values: FormData) => {
    const success = await dispatch(
      transferAgentBetweenTeams({
        agentId: values.agentId.value,
        fromTeamId: values.fromTeamId.value,
        toTeamId: values.toTeamIdAndType.value.split('.')[0],
        toTeamCommissionSplit: values.commissionSplit,
        toTeamRoles: [
          (values.role
            ?.value! as any) as TransferAgentBetweenTeamsRequestToTeamRolesEnum,
        ],
      }),
    );
    if (!success) {
      return;
    }
    onClose();
  };

  useEffect(() => {
    setValue('role', {
      label: capitalizeEnum(member?.roles![0]),
      value: member?.roles![0],
    });
  }, [toTeamType, member, setValue]);

  return (
    <ZenSidebarModal
      isOpen
      onClose={onClose}
      title={`Transfer Agent: ${member?.agent?.fullName}`}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='transfer-team-agent-form'
      >
        <div className='p-5 pb-24'>
          <div className='mt-3'>
            <ZenControlledSelectInput<FormData, 'agentId'>
              control={control}
              options={[]}
              label='Agent Name'
              name='agentId'
              readOnly
              isRequired
            />
          </div>
          <div className='mt-3'>
            <ZenControlledSelectInput<FormData, 'fromTeamId'>
              control={control}
              options={[]}
              label='From Team Name'
              name='fromTeamId'
              readOnly
              isRequired
            />
          </div>
          <div className='mt-3'>
            <ZenControlledAsyncSelectInput<FormData, 'toTeamIdAndType'>
              name='toTeamIdAndType'
              control={control}
              label='To Team Name'
              placeholder='Select Team Name'
              fetchData={async (search, page) => {
                let options: ISelectOption[] = [];
                try {
                  const { data } = await teamSearchRequest({
                    page: page || 0,
                    pageSize: 20,
                    search: search,
                  });
                  options = (data || [])
                    .filter(({ id }) => id !== team.id)
                    .map((team) => ({
                      label: team.name!,
                      value: `${team.id}.${team.type}`,
                    }));
                } catch (e) {
                  ErrorService.notify(
                    'Unable to fetch Teams at this moment.',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                      },
                    },
                  );
                }

                return options;
              }}
              rules={{
                required: 'Please select the team',
              }}
              isRequired
            />
          </div>
          <div className='mt-3'>
            <ZenControlledTextInput<FormData, 'commissionSplit'>
              control={control}
              type='number'
              name='commissionSplit'
              label='Agent Commission'
              rules={getCommissionValidation()}
              helperText={leaderCommission}
              isRequired
            />
          </div>
          <div className='mt-3'>
            <ZenControlledSelectInput<FormData, 'role'>
              control={control}
              name='role'
              label='Select Role'
              placeholder='Select Role'
              options={mapTeamRoleToType(toTeamType)}
              rules={{
                required: 'Please select a role',
              }}
              isRequired
            />
          </div>
        </div>
        <ZenSidebarModalActionFooterV2
          onClose={onClose}
          isSubmitting={isSubmitting}
          submitButtonText='Save'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenTransferTeamAgent;
