import { isNil } from 'lodash';
import { ComponentType } from 'react';
import {
  AgentResponse,
  AgentResponseAccountCountryEnum,
  ApplicationResponse,
  ApplicationResponseCountryEnum,
} from '../openapi/yenta';
import { ISelectOption, PlanNameEnum, YesNoType } from '../types';
import { capitalizeEnum } from './StringUtils';

export interface FormComponentProps<T> {
  application?: ApplicationResponse;
  onSubmit(values: T): void;
}

export interface ICAForm {
  customAnniversaryDate: string;
  emergencyContactEmailAddress: string;
  emergencyContactName: string;
  emergencyContactPhoneNumber: string;
  emergencyContactRelationship: string;
  initials: string;
  entityName: string;
  name: string;
  referringAgentName: string;
  referringAgentEmail: string;
  signature: string;
  optInStockPlan: YesNoType;
  referringCoAgentName: string;
  referringCoAgentEmail: string;
}

export type FormTypeToComponentMap = {
  [key in FormType]: ComponentType<FormComponentProps<any>>;
};

export enum FormType {
  US_REAL_BROKERAGE_ICA = 'US_REAL_BROKERAGE_ICA',
  CA_REAL_BROKERAGE_ICA = 'CA_REAL_BROKERAGE_ICA',
  LETTER_OF_INTENT = 'LETTER_OF_INTENT',
}

export const getAllDataTooltips = () => {
  const tooltipSelectors: NodeListOf<HTMLElement> = document.querySelectorAll(
    '[data-tooltip-index]',
  );

  return Array.from(tooltipSelectors)
    .filter((el: HTMLElement) => {
      const matchTags = ['INPUT', 'TEXTAREA'];

      if (matchTags.includes(el.tagName)) {
        return (
          el.getAttribute('readonly') === null &&
          el.getAttribute('disabled') === null
        );
      }

      return !el.querySelectorAll('[disabled], [readonly]').length;
    })
    .sort(
      (a, b) =>
        parseInt(a.getAttribute('data-tooltip-index')!, 10) -
        parseInt(b.getAttribute('data-tooltip-index')!, 10),
    );
};

export const getFormType = (
  country: AgentResponseAccountCountryEnum | ApplicationResponseCountryEnum,
): FormType => {
  if (country === AgentResponseAccountCountryEnum.Canada) {
    return FormType.CA_REAL_BROKERAGE_ICA;
  }

  return FormType.US_REAL_BROKERAGE_ICA;
};

export const isCommercialAgent = (
  application?: ApplicationResponse,
  user?: AgentResponse | null,
) => {
  return (
    !!application?.commercialAgent ||
    user?.planMembership?.commissionPlan?.name ===
      PlanNameEnum.US_COMMERCIAL_STANDARD_V1_PLAN
  );
};

export const getISelectOptionDefaultValue = <T extends string>(
  value?: T,
  label?: string,
): ISelectOption<T> | undefined => {
  if (isNil(value)) {
    return;
  }
  return { label: label ?? capitalizeEnum(value), value };
};
