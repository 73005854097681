import {
  faBuildings,
  faDollarSign,
  faCalendarPen,
  faEnvelope,
  faHammer,
  faCalendarXmark,
} from '@fortawesome/pro-light-svg-icons';
import {
  faHandshakeSimple,
  faTag,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as CopyIcon } from '../../../assets/img/gemini/copy-icon.svg';
import {
  ParticipantValueRoleEnum,
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { fetchDropbox } from '../../../slices/DropboxSlice';
import { setActiveTransition } from '../../../slices/QuickActionSlice';
import { getProcessTransaction } from '../../../slices/TransactionSlice';
import { RootState } from '../../../types';
import { displayFormattedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import {
  getAllTransactionParticipantsByRole,
  getTransactionTransitionToReadableName,
  isCanadaTransaction,
} from '../../../utils/TransactionHelper';
import {
  getTransactionVariant,
  isPersonalDealTransaction,
} from '../../../utils/TransactionUtils';
import CopyToClipboardHover from '../../CopyToClipboardHover';
import ZenSimpleConfirmationModal from '../../Zen/Modal/ZenSimpleConfirmationModal';
import ZenTransitionConfirmationModal from '../../Zen/Transaction/Header/ZenTransitionConfirmationModal';
import GeminiPill from '../GeminiPill';
import GeminiTransactionCheckType from '../Transaction/Header/GeminiTransactionCheckType';
import GeminiTransactionCodeWithCopy from '../Transaction/Header/GeminiTransactionCodeWithCopy';
import GeminiTransactionDealTypeLabel from '../Transaction/Header/GeminiTransactionDealTypeLabel';
import GeminiTransactionHeaderRowCell from '../Transaction/Header/GeminiTransactionHeaderRowCell';
import GeminiTransactionHeaderStatus from '../Transaction/Header/GeminiTransactionHeaderStatus';

interface GeminiListingHeaderProps {
  transaction: TransactionResponse;
}

const GeminiListingHeader: React.FC<GeminiListingHeaderProps> = ({
  transaction,
}) => {
  const dispatch = useDispatch();
  const {
    quickAction: { activeTransition },
    dropbox: { dropboxById },
  } = useSelector((state: RootState) => state);
  const [
    retryTransition,
    setRetryTransition,
  ] = useState<TransactionLifecycleStateValueStateEnum>();

  const allSellers = getAllTransactionParticipantsByRole(
    transaction,
    ParticipantValueRoleEnum.Seller,
  );
  const allLandlords = getAllTransactionParticipantsByRole(
    transaction,
    ParticipantValueRoleEnum.Landlord,
  );

  const allSellersAndLandlordsNames = [
    ...allSellers,
    ...allLandlords,
  ].map((participant) => getParticipantName(participant));

  const dropboxId = transaction?.dropboxId!;
  const dropbox = dropboxById[dropboxId];

  useEffect(() => {
    if (!!dropboxId && dropbox?.id !== dropboxId) {
      dispatch(fetchDropbox(dropboxId));
    }
  }, [dispatch, dropbox?.id, dropboxId]);

  return (
    <header className='md:p-6 p-2 flex md:flex-row flex-col bg-primary-navy text-white rounded-[15px] md:mx-4 mx-2 my-1'>
      <div className='md:w-2/3 w-full md:pr-3'>
        <div className='flex md:flex-row flex-col'>
          <span className='font-zen-body-2 text-xl font-bold md:mr-4 break-all'>
            {transaction.address?.oneLine}
          </span>
          <GeminiTransactionCodeWithCopy
            transactionCode={`Code: ${transaction.code}`}
          />
        </div>
        <div className='flex md:flex-row flex-col md:space-x-2 md:space-y-0 space-y-2 mt-4'>
          <div className='flex flex-row items-center flex-shrink-0'>
            <div className='h-6 w-6 flex items-center justify-center shrink-0'>
              <FontAwesomeIcon icon={faTag} className='text-lg leading-6' />
            </div>
            <span className='font-zen-body-2 font-normal text-lg leading-6 ml-1'>
              {displayFormattedAmountWithCurrency(transaction.price)}
            </span>
          </div>
          <GeminiTransactionDealTypeLabel
            dealType={transaction?.transactionType!}
          />
          {isPersonalDealTransaction(transaction) && (
            <GeminiPill
              label='Personal Deal'
              containerClassNames='bg-midnight-green-300 text-midnight-green-900'
              icon={faHandshakeSimple}
            />
          )}
        </div>
        <div className='grid md:grid-cols-2 grid-cols-1 md:gap-x-6 md:gap-y-0 gap-y-4 mt-4 md:mt-8'>
          <div className='flex flex-col space-y-2.5'>
            <GeminiTransactionHeaderRowCell
              leftComponentIcon={faBuildings}
              label={
                <Link to={`/offices/${transaction.office?.id}`}>
                  {transaction.office?.name}
                </Link>
              }
              rightComponent={
                <GeminiTransactionCheckType transaction={transaction} />
              }
            />
            {!!transaction.listingTransaction?.price && (
              <GeminiTransactionHeaderRowCell
                leftComponentIcon={faDollarSign}
                label='List Price:'
                value={displayFormattedAmountWithCurrency(
                  transaction.listingTransaction?.price,
                )}
              />
            )}
            <GeminiTransactionHeaderRowCell
              leftComponentIcon={faDollarSign}
              label='Commission:'
              value={`${
                transaction.grossCommissionPercentage
              } | ${displayFormattedAmountWithCurrency(
                transaction.grossCommission,
              )}`}
            />
            <GeminiTransactionHeaderRowCell
              leftComponentIcon={faUser}
              label='Seller/Landlord:'
              value={
                allSellersAndLandlordsNames?.length > 0
                  ? allSellersAndLandlordsNames.join(', ')
                  : 'N/A'
              }
            />
            <GeminiTransactionHeaderRowCell
              leftComponentIcon={faUser}
              label='Transaction Owner:'
              value={transaction?.transactionOwner?.name || 'N/A'}
            />
          </div>
          <div className='flex flex-col space-y-2.5'>
            {!isCanadaTransaction(transaction!) && (
              <GeminiTransactionHeaderRowCell
                leftComponentIcon={faHammer}
                label='Year Built:'
                value={
                  transaction.yearBuilt
                    ? transaction.yearBuilt.toString()
                    : 'N/A'
                }
              />
            )}
            <GeminiTransactionHeaderRowCell
              leftComponentIcon={faCalendarPen}
              label='Listing Start Date:'
              value={
                transaction.listingDate
                  ? DateTime.fromISO(transaction.listingDate).toFormat(
                      'MM/dd/yyyy',
                    )
                  : 'N/A'
              }
            />
            <GeminiTransactionHeaderRowCell
              leftComponentIcon={faCalendarXmark}
              label='Listing Expiration Date:'
              value={
                transaction.listingExpirationDate
                  ? DateTime.fromISO(
                      transaction.listingExpirationDate,
                    ).toFormat('MM/dd/yyyy')
                  : 'N/A'
              }
            />
            {!!dropbox?.emailAddress && (
              <GeminiTransactionHeaderRowCell
                leftComponentIcon={faEnvelope}
                label='Listing Email:'
                value={
                  <div className='flex items-center'>
                    <a
                      href={`mailto:${dropbox.emailAddress}`}
                      className='font-zen-body font-normal text-sm text-white whitespace-nowrap'
                    >
                      {dropbox.emailAddress}
                    </a>
                    <CopyToClipboardHover
                      value={dropbox.emailAddress}
                      copyToltipLabel='Copy Listing Email'
                      copyIcon={<CopyIcon className='cursor-pointer ml-1' />}
                    />
                  </div>
                }
              />
            )}
          </div>
        </div>
      </div>
      <div className='md:w-1/3 md:pl-3 mb-10'>
        <GeminiTransactionHeaderStatus
          setRetryTransition={setRetryTransition}
          transaction={transaction}
        />
      </div>
      {!!retryTransition && (
        <ZenSimpleConfirmationModal
          isOpen
          variant='info'
          title={`Retry ${getTransactionTransitionToReadableName(
            transaction,
            retryTransition!,
          )}?`}
          subtitle={`Are you sure you'd like to reprocess this transaction?`}
          onClose={() => {
            setRetryTransition(undefined);
          }}
          onConfirm={async () => {
            await dispatch(getProcessTransaction(transaction.id!));
            setRetryTransition(undefined);
          }}
          confirmButtonText='Reprocess'
          isSubmitting={false}
        />
      )}
      {!!activeTransition && (
        <ZenTransitionConfirmationModal
          isOpen
          onClose={() => dispatch(setActiveTransition(undefined))}
          transaction={transaction}
          activeTransition={activeTransition}
          variant={getTransactionVariant(activeTransition!)}
        />
      )}
    </header>
  );
};

export default GeminiListingHeader;
