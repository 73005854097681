import { useHistory } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import React, { useMemo, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import ZenRoute from '../components/Zen/ZenRoute';
import PageLayout from '../components/PageLayout';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import {
  ExperimentResponse,
  ExperimentResponseStateEnum,
} from '../openapi/yenta';
import ZenViewActionButtonCell from '../components/table/Cells/ZenViewActionButtonCell';
import ZenRouterTabs, { ZenTab } from '../components/Zen/Tab/ZenRouterTabs';
import {
  activateExperiment,
  archiveExperiment,
  fetchFeatureFlagsExperiments,
} from '../slices/ExperimentSlice';
import { AppDispatch, RootState } from '../types';
import ZenButton from '../components/Zen/ZenButton';
import ZenCreateExperiment from '../components/experiment/ZenCreateExperiment';
import { useRefresh } from '../hooks/useRefresh';
import ZenConfirmationModal from '../components/Zen/Modal/ZenConfirmationModal';

export const columns: Array<Column<ExperimentResponse>> = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }) => value,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Weight',
    id: 'weight',
    Cell: ({ row: { original } }: CellProps<ExperimentResponse, number>) => (
      <p className='font-zen-body font-normal'> {original.groups![0].weight}</p>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Number of Override Users',
    id: 'overrideIds',
    Cell: ({ row: { original } }: CellProps<ExperimentResponse, number>) => (
      <p className='font-zen-body font-normal'>
        {' '}
        {original.groups?.[0]?.overrideIds?.length || 0}
      </p>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
];

const FeatureFlagsRoute: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isUpdatingExperiment } = useSelector(
    (state: RootState) => state.experiment,
  );

  const { refresh, key } = useRefresh();
  const [activateExperimentId, setActivateExperimentId] = useState<
    string | undefined
  >(undefined);
  const [archiveExperimentId, setArchiveExperimentId] = useState<
    string | undefined
  >(undefined);

  const [
    openCreateExperimentSidebarModal,
    setOpenCreateExperimentSidebarModal,
  ] = useState(false);
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnsWithAction: Array<Column<ExperimentResponse>> = [
    {
      Header: '',
      id: 'id',
      accessor: 'id',
      Cell: ({ value }) => (
        <div
          onClick={() => {
            history.push(`/feature-flags/${value}`);
          }}
        >
          <ZenViewActionButtonCell />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    ...columns,
  ];

  const activeColumnsWithAction: Array<Column<ExperimentResponse>> = [
    ...columnsWithAction,
    {
      Header: 'Actions',
      accessor: 'id',
      id: 'actions',
      Cell: ({ value }) => (
        <div>
          <ZenButton
            label='Archive'
            variant='primary-outline'
            onClick={() => setArchiveExperimentId(value)}
          />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const archivedColumnsWithAction: Array<Column<ExperimentResponse>> = [
    ...columnsWithAction,
    {
      Header: 'Actions',
      accessor: 'id',
      id: 'actions',
      Cell: ({ value }) => (
        <div>
          <ZenButton
            label='Activate'
            variant='primary-outline'
            onClick={() => setActivateExperimentId(value)}
            type='button'
          />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const tabs: ZenTab[] = useMemo(() => {
    const array = [
      {
        label: 'Active',
        path: `/feature-flags`,
        exact: true,
        TabComponent: () => (
          <ZenRoute title='Active Feature flags experiment'>
            <div className='mt-4'>
              <ZenResourceIndexContainer<ExperimentResponse>
                columns={activeColumnsWithAction}
                initialSort={{ name: 'asc' }}
                resourceName='feature flag'
                hideFilters
                key={`${key}-active`}
                fetchData={async (req) => {
                  const featureFlagsData = await dispatch(
                    fetchFeatureFlagsExperiments(
                      ExperimentResponseStateEnum.Active,
                      req.page,
                      req.pageSize,
                    ),
                  );
                  return {
                    data: featureFlagsData?.experiments!,
                    total: featureFlagsData?.totalCount!,
                  };
                }}
              />
            </div>
          </ZenRoute>
        ),
      },
      {
        label: 'Archived',
        path: `/feature-flags/archived`,
        exact: true,
        TabComponent: () => (
          <ZenRoute title='Archived Feature flags experiment'>
            <div className='mt-4'>
              <ZenResourceIndexContainer<ExperimentResponse>
                columns={archivedColumnsWithAction}
                initialSort={{ name: 'asc' }}
                resourceName='feature flag'
                hideFilters
                key={`${key}-archived`}
                fetchData={async (req) => {
                  const featureFlagsData = await dispatch(
                    fetchFeatureFlagsExperiments(
                      ExperimentResponseStateEnum.Archived,
                      req.page,
                      req.pageSize,
                    ),
                  );
                  return {
                    data: featureFlagsData?.experiments!,
                    total: featureFlagsData?.totalCount!,
                  };
                }}
              />
            </div>
          </ZenRoute>
        ),
      },
    ];
    return array;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, key]);

  return (
    <ZenRoute title='Feature Flags Details'>
      <PageLayout
        path={[
          { title: 'Engineering', url: '/engineering' },
          { title: 'Feature Flags', url: '/feature-flags' },
        ]}
      >
        <div className='px-4 lg:pt-5'>
          <div className='flex justify-between mb-5'>
            <div className='md:flex items-center md:justify-between space-x-3 w-full'>
              <h1 className='font-zen-body font-semibold text-xl'>
                Feature Flags
              </h1>
              <div className='md:space-x-4'>
                <ZenButton
                  variant='primary'
                  label='Create New Experiment'
                  LeftIconComponent={
                    <FiPlus fontSize={18} className='text-white' />
                  }
                  onClick={() => setOpenCreateExperimentSidebarModal(true)}
                />
              </div>
            </div>
          </div>
          <ZenRouterTabs tabs={tabs} />
        </div>
        {openCreateExperimentSidebarModal && (
          <ZenCreateExperiment
            isOpen
            onClose={(isRefreshRequired: boolean) => {
              if (isRefreshRequired) {
                refresh();
              }

              setOpenCreateExperimentSidebarModal(false);
            }}
          />
        )}
      </PageLayout>
      <ZenConfirmationModal
        isOpen={!!activateExperimentId}
        onClose={() => setActivateExperimentId(undefined)}
        variant='primary'
        onConfirm={async () => {
          await dispatch(activateExperiment(activateExperimentId!));
          setActivateExperimentId(undefined);
          refresh();
        }}
        isSubmitting={isUpdatingExperiment}
        isDisabled={isUpdatingExperiment}
        title='Activate Experiment?'
        subtitle='This will turn on the experiment for all users within the existing group. Are you sure you would like to activate this experiment? Please note this may take up to 15 minutes to propagate throughout the system.'
      />
      <ZenConfirmationModal
        isOpen={!!archiveExperimentId}
        onClose={() => setArchiveExperimentId(undefined)}
        variant='primary'
        onConfirm={async () => {
          await dispatch(archiveExperiment(archiveExperimentId!));
          setArchiveExperimentId(undefined);
          refresh();
        }}
        isSubmitting={isUpdatingExperiment}
        isDisabled={isUpdatingExperiment}
        title='Archive Experiment?'
        subtitle='This will turn off the experiment for all users. This should only be done If the feature flag has been removed from all applications. Are you sure you would like to archive this experiment? Please note this may take up to 15 minutes to propagate throughout the system.'
      />
    </ZenRoute>
  );
};

export default FeatureFlagsRoute;
