import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface SearchBarProps {
  placeholder?: string;
  onChange: (str: string) => void;
  value: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  onChange,
  placeholder = 'Search',
  value,
}) => {
  return (
    <div className='w-full flex flex-row bg-trueGray-100 items-center px-2 rounded'>
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        className='text-gray-400'
        size='sm'
      />
      <input
        type='text'
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        className='w-full appearance-none p-2 border-none bg-transparent bg-trueGray-100 focus:outline-none focus:ring-0 primary-light flex-grow'
      />
    </div>
  );
};

export default SearchBar;
