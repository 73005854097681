import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsService from '../../../services/AnalyticsService';
import {
  saveIsLeoModalVisible,
  saveIsLeoThinking,
  saveSession,
} from '../../../slices/LeoSlice';
import { AnalyticsEventEnum, RootState } from '../../../types';
import LeoIdle from '../../../assets/img/leo/leo-idle.gif';
import LeoThinking from '../../../assets/img/leo/leo-thinking.gif';
import LeoModal from './LeoModal';

interface LeoModalContainerProps {}

const LeoModalContainer: React.FC<LeoModalContainerProps> = () => {
  const dispatch = useDispatch();
  const { isLeoThinking } = useSelector((state: RootState) => state.leo);

  return (
    <div className='leo-galaxy-img w-full object-contain bg-leo-galaxy bg-no-repeat bg-cover animate-slide-in-down transition-all ease-in-out delay-150 h-full'>
      <div className='relative w-full h-full flex flex-col overflow-y-scroll scrollbar-hide'>
        <div className='absolute w-full top-0'>
          {isLeoThinking ? (
            <img
              src={LeoThinking}
              className='w-full rounded-t-2xl h-20'
              alt='leo-thinking'
            />
          ) : (
            <img
              src={LeoIdle}
              className='w-full rounded-t-2xl h-10 object-cover'
              alt='leo-idle'
            />
          )}
        </div>
        <LeoModal />
        <div className='hidden sm:block absolute top-[10px] right-[12px]'>
          <button
            className='appearance-none rounded-full overflow-hidden'
            onClick={() => {
              dispatch(saveIsLeoThinking(false));
              dispatch(saveIsLeoModalVisible(false));
              dispatch(saveSession(undefined));
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.LEO_BAR_CLOSE,
              );
            }}
            aria-label='close-leo'
          >
            <FontAwesomeIcon
              icon={faCircleXmark}
              className='text-dark bg-white text-[28px] rounded-full overflow-hidden'
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeoModalContainer;
