import React, { useCallback, useMemo, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DateTime } from 'luxon';
import { RevSharePaymentPreviewResponse } from '../../openapi/arrakis';
import { getPreviousRevenueSharePayments } from '../../slices/RevenueSlice';
import ZenViewActionButtonCell from '../table/Cells/ZenViewActionButtonCell';
import { displayAmount, zeroMoneyValue } from '../../utils/CurrencyUtils';
import ZenMilliDateCell from '../Zen/Table/Cell/ZenMilliDateCell';
import { AnalyticsEventEnum, AppDispatch, RootState } from '../../types';
import ZenFixedResourceIndexContainer from '../Zen/Containers/ZenFixedResourceIndexContainer';
import DefaultLoader from '../DefaultLoader';
import ResourceContainer from '../ResourceContainer';
import AnalyticsService from '../../services/AnalyticsService';
import { cn } from '../../utils/classUtils';

const columns: Array<Column<RevSharePaymentPreviewResponse>> = [
  {
    Header: 'Payment Date',
    accessor: 'createdAt',
    Cell: ({ value }: CellProps<RevSharePaymentPreviewResponse>) => (
      <div
        className={cn('md:w-32 text-sm font-zen-body', {
          'mr-2': !value,
        })}
      >
        {value ? <ZenMilliDateCell date={value} /> : <p>Estimated payments</p>}
      </div>
    ),
    headerContentClassName: 'md:w-32',
    disableSortBy: true,
  },
  {
    Header: 'Contributors',
    accessor: 'numOfContributions',
    Cell: ({ value }: CellProps<RevSharePaymentPreviewResponse>) => (
      <p className='md:w-24 md:text-center'>{value}</p>
    ),
    headerContentClassName: 'md:w-24',
    disableSortBy: true,
  },
];

interface RevShareInsightsPaymentHistoryProps {
  agentId: string;
  onClickPendingPayment(value: boolean): void;
}

const RevShareInsightsPaymentHistory: React.FC<RevShareInsightsPaymentHistoryProps> = ({
  agentId,
  onClickPendingPayment,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const {
    revenue: {
      pendingPaymentsOverview,
      previousPayments,
      previousPaymentsLoading,
      previousPaymentsError,
    },
    agentDetail,
  } = useSelector((state: RootState) => state);

  const [allPreviousPayments, setAllPreviousPayments] = useState<
    RevSharePaymentPreviewResponse[]
  >(previousPayments?.payments || []);

  const detail = agentDetail.detailResponse.data;

  const getPreviousPayments = useCallback(
    async (pageNum: number) => {
      const payments = await dispatch(
        getPreviousRevenueSharePayments(agentId, pageNum, 20, false),
      );
      setAllPreviousPayments((prev) => prev.concat(payments?.payments || []));
    },
    [agentId, dispatch],
  );

  const columnsWithAction: Array<
    Column<RevSharePaymentPreviewResponse>
  > = useMemo(() => {
    return [
      {
        Header: 'Action',
        id: 'actions',
        Cell: ({
          row: { original },
        }: CellProps<RevSharePaymentPreviewResponse>) => {
          const isCreated = !!original.createdAt;
          return (
            <div className='md:w-10'>
              <ZenViewActionButtonCell
                onClick={() => {
                  if (isCreated) {
                    AnalyticsService.instance().logEvent(
                      AnalyticsEventEnum.RSI_OPEN_PREVIOUS_PAYMENT,
                      {
                        previousPaymentDate: DateTime.fromMillis(
                          original.createdAt!,
                        ).toLocaleString(DateTime.DATE_MED),
                      },
                    );
                    history.push(
                      `/people/${agentId}/revshare/payments/${original.outgoingPaymentId}`,
                    );
                  } else {
                    AnalyticsService.instance().logEvent(
                      AnalyticsEventEnum.RSI_OPEN_PENDING_PAYMENT,
                    );
                    onClickPendingPayment(true);
                  }
                }}
              />
            </div>
          );
        },
        headerContentClassName: 'md:w-10',
        disableSortBy: true,
      },
      ...columns,
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value }: CellProps<RevSharePaymentPreviewResponse>) => (
          <p>
            {displayAmount(value || zeroMoneyValue(detail?.defaultCurrency!))}
          </p>
        ),
        disableSortBy: true,
      },
    ];
  }, [history, agentId, onClickPendingPayment, detail?.defaultCurrency]);

  const pendingPayments = useMemo(() => {
    const data = [];
    if (pendingPaymentsOverview) {
      data.push(pendingPaymentsOverview);
    }
    return data;
  }, [pendingPaymentsOverview]);

  return (
    <div className='md:h-full rounded-[10px] border border-zen-dark-4 overflow-hidden'>
      <div className=' bg-grey-100 px-4 py-2 text-base font-zen-title text-zen-dark-9'>
        <span>Payment History</span>
      </div>
      <div aria-label='pending-payments'>
        <ZenFixedResourceIndexContainer
          columns={columnsWithAction}
          resourceName='pending payment'
          data={pendingPayments}
          hideFilters
          hidePagination
          hideRowBorder
        />
      </div>
      <div aria-label='previous-payments'>
        <p className='py-2.5 px-4 bg-zen-light-gray-1 text-zen-salon-blue font-zen-body font-semibold text-xs'>
          PREVIOUS
        </p>
        <div
          className='h-[400px] md:h-full md:max-h-[400px] scrollbar overflow-auto'
          id='previous-payments-container'
        >
          <ResourceContainer
            loading={previousPaymentsLoading && !allPreviousPayments?.length}
            isEmpty={!allPreviousPayments?.length}
            resourceName='previous payment'
            errorCode={previousPaymentsError}
          >
            <InfiniteScroll
              hasMore={!!previousPayments?.hasNext}
              loader={<DefaultLoader />}
              dataLength={allPreviousPayments?.length || 0}
              scrollableTarget='previous-payments-container'
              next={() =>
                getPreviousPayments((previousPayments?.pageNumber || 0) + 1)
              }
            >
              <ZenFixedResourceIndexContainer
                columns={columnsWithAction}
                resourceName='previous payment'
                data={allPreviousPayments}
                hideFilters
                hidePagination
                hideRowBorder
                hideHeader
              />
            </InfiniteScroll>
          </ResourceContainer>
        </div>
      </div>
    </div>
  );
};

export default RevShareInsightsPaymentHistory;
