import isEmpty from 'lodash/isEmpty';
import { ISelectOption } from '../../types';
import { DirectoryVendorCreateRequestRoleEnum } from '../../openapi/yenta';
import { cleanPhoneNumber } from '../../utils/StringUtils';
import { FilterValuesFormData } from '../../routes/ZenDirectoryRoute';
import { getDirectoryVendorRoleLabel } from './DirectoryUtils';

export const countAppliedFilters = (appliedFilters: FilterValuesFormData[]) => {
  let count = 0;

  appliedFilters.forEach((filterObj) => {
    Object.keys(filterObj).forEach((key) => {
      const value = filterObj[key as keyof typeof filterObj];
      if (!isEmpty(value)) {
        count += 1;
      }
    });
  });
  return count;
};

export const removeEmptyValues = (
  obj: FilterValuesFormData,
): FilterValuesFormData => {
  return Object.keys(obj).reduce((newObj, key) => {
    const value = obj[key as keyof FilterValuesFormData];
    if (!isEmpty(value)) {
      return { ...newObj, [key]: value };
    }
    return newObj;
  }, {} as FilterValuesFormData);
};

export const appendRoleLabels = (role?: ISelectOption[]) =>
  role?.map((roleOption) => {
    const label =
      getDirectoryVendorRoleLabel(
        roleOption.value as DirectoryVendorCreateRequestRoleEnum,
      ) || '';
    return {
      ...roleOption,
      label: label, // Now guaranteed to be a string, never undefined
    };
  }) ?? []; // Ensure we default to an empty array if data.participantRole is undefined

export const prepareDataForApi = (
  data: FilterValuesFormData,
): FilterValuesFormData => {
  const convertedRoles = appendRoleLabels(data?.participantRole);
  const sanitizedData = removeEmptyValues(data);
  return {
    ...sanitizedData,
    participantRole: isEmpty(convertedRoles) ? undefined : convertedRoles,
    phoneNumber: cleanPhoneNumber(sanitizedData?.phoneNumber) || undefined,
  };
};
