import { faArrowLeftLong } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export interface ZenHeaderWithBackButtonProps {
  title: string;
  onBack: () => void;
}

const ZenHeaderWithBackButton: React.FC<ZenHeaderWithBackButtonProps> = ({
  title,
  onBack,
}) => {
  return (
    <div className='flex items-center space-x-3'>
      <FontAwesomeIcon
        icon={faArrowLeftLong}
        size='xl'
        onClick={onBack}
        className='cursor-pointer'
      />
      <h1 className='font-zen-body font-semibold text-xl'>{title}</h1>
    </div>
  );
};

export default ZenHeaderWithBackButton;
