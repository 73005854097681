import {
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
} from '../openapi/yenta';
import { EnumMap } from '../types';

export const CANADIAN_STATES: AdministrativeAreaRequestStateOrProvinceEnum[] = [
  AdministrativeAreaRequestStateOrProvinceEnum.Ontario,
  AdministrativeAreaRequestStateOrProvinceEnum.Quebec,
  AdministrativeAreaRequestStateOrProvinceEnum.NovaScotia,
  AdministrativeAreaRequestStateOrProvinceEnum.NewBrunswick,
  AdministrativeAreaRequestStateOrProvinceEnum.Manitoba,
  AdministrativeAreaRequestStateOrProvinceEnum.BritishColumbia,
  AdministrativeAreaRequestStateOrProvinceEnum.PrinceEdwardIsland,
  AdministrativeAreaRequestStateOrProvinceEnum.Saskatchewan,
  AdministrativeAreaRequestStateOrProvinceEnum.Alberta,
  AdministrativeAreaRequestStateOrProvinceEnum.NewfoundlandAndLabrador,
];

export const US_STATES: AdministrativeAreaRequestStateOrProvinceEnum[] = [
  AdministrativeAreaRequestStateOrProvinceEnum.Alabama,
  AdministrativeAreaRequestStateOrProvinceEnum.Alaska,
  AdministrativeAreaRequestStateOrProvinceEnum.Arizona,
  AdministrativeAreaRequestStateOrProvinceEnum.Arkansas,
  AdministrativeAreaRequestStateOrProvinceEnum.California,
  AdministrativeAreaRequestStateOrProvinceEnum.Colorado,
  AdministrativeAreaRequestStateOrProvinceEnum.Connecticut,
  AdministrativeAreaRequestStateOrProvinceEnum.Delaware,
  AdministrativeAreaRequestStateOrProvinceEnum.DistrictOfColumbia,
  AdministrativeAreaRequestStateOrProvinceEnum.Florida,
  AdministrativeAreaRequestStateOrProvinceEnum.Georgia,
  AdministrativeAreaRequestStateOrProvinceEnum.Hawaii,
  AdministrativeAreaRequestStateOrProvinceEnum.Idaho,
  AdministrativeAreaRequestStateOrProvinceEnum.Illinois,
  AdministrativeAreaRequestStateOrProvinceEnum.Indiana,
  AdministrativeAreaRequestStateOrProvinceEnum.Iowa,
  AdministrativeAreaRequestStateOrProvinceEnum.Kansas,
  AdministrativeAreaRequestStateOrProvinceEnum.Kentucky,
  AdministrativeAreaRequestStateOrProvinceEnum.Louisiana,
  AdministrativeAreaRequestStateOrProvinceEnum.Maine,
  AdministrativeAreaRequestStateOrProvinceEnum.Maryland,
  AdministrativeAreaRequestStateOrProvinceEnum.Massachusetts,
  AdministrativeAreaRequestStateOrProvinceEnum.Michigan,
  AdministrativeAreaRequestStateOrProvinceEnum.Minnesota,
  AdministrativeAreaRequestStateOrProvinceEnum.Mississippi,
  AdministrativeAreaRequestStateOrProvinceEnum.Missouri,
  AdministrativeAreaRequestStateOrProvinceEnum.Montana,
  AdministrativeAreaRequestStateOrProvinceEnum.Nebraska,
  AdministrativeAreaRequestStateOrProvinceEnum.Nevada,
  AdministrativeAreaRequestStateOrProvinceEnum.NewHampshire,
  AdministrativeAreaRequestStateOrProvinceEnum.NewJersey,
  AdministrativeAreaRequestStateOrProvinceEnum.NewMexico,
  AdministrativeAreaRequestStateOrProvinceEnum.NewYork,
  AdministrativeAreaRequestStateOrProvinceEnum.NorthCarolina,
  AdministrativeAreaRequestStateOrProvinceEnum.NorthDakota,
  AdministrativeAreaRequestStateOrProvinceEnum.Ohio,
  AdministrativeAreaRequestStateOrProvinceEnum.Oklahoma,
  AdministrativeAreaRequestStateOrProvinceEnum.Oregon,
  AdministrativeAreaRequestStateOrProvinceEnum.Pennsylvania,
  AdministrativeAreaRequestStateOrProvinceEnum.PuertoRico,
  AdministrativeAreaRequestStateOrProvinceEnum.RhodeIsland,
  AdministrativeAreaRequestStateOrProvinceEnum.SouthCarolina,
  AdministrativeAreaRequestStateOrProvinceEnum.SouthDakota,
  AdministrativeAreaRequestStateOrProvinceEnum.Tennessee,
  AdministrativeAreaRequestStateOrProvinceEnum.Texas,
  AdministrativeAreaRequestStateOrProvinceEnum.Utah,
  AdministrativeAreaRequestStateOrProvinceEnum.Vermont,
  AdministrativeAreaRequestStateOrProvinceEnum.Virginia,
  AdministrativeAreaRequestStateOrProvinceEnum.Washington,
  AdministrativeAreaRequestStateOrProvinceEnum.WestVirginia,
  AdministrativeAreaRequestStateOrProvinceEnum.Wisconsin,
  AdministrativeAreaRequestStateOrProvinceEnum.Wyoming,
];

export const STATE_OR_PROVINCE_ABBREVIATIONS: EnumMap<
  AdministrativeAreaRequestStateOrProvinceEnum,
  string
> = {
  [AdministrativeAreaRequestStateOrProvinceEnum.Alabama]: 'AL',
  [AdministrativeAreaRequestStateOrProvinceEnum.Alaska]: 'AK',
  [AdministrativeAreaRequestStateOrProvinceEnum.Arizona]: 'AZ',
  [AdministrativeAreaRequestStateOrProvinceEnum.Arkansas]: 'AR',
  [AdministrativeAreaRequestStateOrProvinceEnum.California]: 'CA',
  [AdministrativeAreaRequestStateOrProvinceEnum.Colorado]: 'CO',
  [AdministrativeAreaRequestStateOrProvinceEnum.Connecticut]: 'CT',
  [AdministrativeAreaRequestStateOrProvinceEnum.Delaware]: 'DE',
  [AdministrativeAreaRequestStateOrProvinceEnum.DistrictOfColumbia]: 'DC',
  [AdministrativeAreaRequestStateOrProvinceEnum.Florida]: 'FL',
  [AdministrativeAreaRequestStateOrProvinceEnum.Georgia]: 'GA',
  [AdministrativeAreaRequestStateOrProvinceEnum.Hawaii]: 'HI',
  [AdministrativeAreaRequestStateOrProvinceEnum.Idaho]: 'ID',
  [AdministrativeAreaRequestStateOrProvinceEnum.Illinois]: 'IL',
  [AdministrativeAreaRequestStateOrProvinceEnum.Indiana]: 'IN',
  [AdministrativeAreaRequestStateOrProvinceEnum.Iowa]: 'IA',
  [AdministrativeAreaRequestStateOrProvinceEnum.Kansas]: 'KS',
  [AdministrativeAreaRequestStateOrProvinceEnum.Kentucky]: 'KY',
  [AdministrativeAreaRequestStateOrProvinceEnum.Louisiana]: 'LA',
  [AdministrativeAreaRequestStateOrProvinceEnum.Maine]: 'ME',
  [AdministrativeAreaRequestStateOrProvinceEnum.Michigan]: 'MI',
  [AdministrativeAreaRequestStateOrProvinceEnum.Minnesota]: 'MN',
  [AdministrativeAreaRequestStateOrProvinceEnum.Massachusetts]: 'MA',
  [AdministrativeAreaRequestStateOrProvinceEnum.Maryland]: 'MD',
  [AdministrativeAreaRequestStateOrProvinceEnum.Mississippi]: 'MS',
  [AdministrativeAreaRequestStateOrProvinceEnum.Missouri]: 'MO',
  [AdministrativeAreaRequestStateOrProvinceEnum.Montana]: 'MT',
  [AdministrativeAreaRequestStateOrProvinceEnum.Nebraska]: 'NE',
  [AdministrativeAreaRequestStateOrProvinceEnum.Nevada]: 'NV',
  [AdministrativeAreaRequestStateOrProvinceEnum.NewHampshire]: 'NH',
  [AdministrativeAreaRequestStateOrProvinceEnum.NorthCarolina]: 'NC',
  [AdministrativeAreaRequestStateOrProvinceEnum.NewJersey]: 'NJ',
  [AdministrativeAreaRequestStateOrProvinceEnum.NewMexico]: 'NM',
  [AdministrativeAreaRequestStateOrProvinceEnum.NewYork]: 'NY',
  [AdministrativeAreaRequestStateOrProvinceEnum.NorthDakota]: 'ND',
  [AdministrativeAreaRequestStateOrProvinceEnum.Ohio]: 'OH',
  [AdministrativeAreaRequestStateOrProvinceEnum.Oklahoma]: 'OK',
  [AdministrativeAreaRequestStateOrProvinceEnum.Oregon]: 'OR',
  [AdministrativeAreaRequestStateOrProvinceEnum.Pennsylvania]: 'PA',
  [AdministrativeAreaRequestStateOrProvinceEnum.PuertoRico]: 'PR',
  [AdministrativeAreaRequestStateOrProvinceEnum.RhodeIsland]: 'RI',
  [AdministrativeAreaRequestStateOrProvinceEnum.SouthCarolina]: 'SC',
  [AdministrativeAreaRequestStateOrProvinceEnum.SouthDakota]: 'SD',
  [AdministrativeAreaRequestStateOrProvinceEnum.Tennessee]: 'TN',
  [AdministrativeAreaRequestStateOrProvinceEnum.Texas]: 'TX',
  [AdministrativeAreaRequestStateOrProvinceEnum.Utah]: 'UT',
  [AdministrativeAreaRequestStateOrProvinceEnum.Vermont]: 'VT',
  [AdministrativeAreaRequestStateOrProvinceEnum.Virginia]: 'VA',
  [AdministrativeAreaRequestStateOrProvinceEnum.Washington]: 'WA',
  [AdministrativeAreaRequestStateOrProvinceEnum.WestVirginia]: 'WV',
  [AdministrativeAreaRequestStateOrProvinceEnum.Wisconsin]: 'WI',
  [AdministrativeAreaRequestStateOrProvinceEnum.Wyoming]: 'WY',
  [AdministrativeAreaRequestStateOrProvinceEnum.Alberta]: 'AB',
  [AdministrativeAreaRequestStateOrProvinceEnum.BritishColumbia]: 'BC',
  [AdministrativeAreaRequestStateOrProvinceEnum.Manitoba]: 'MB',
  [AdministrativeAreaRequestStateOrProvinceEnum.NewBrunswick]: 'NB',
  [AdministrativeAreaRequestStateOrProvinceEnum.NewfoundlandAndLabrador]: 'NL',
  [AdministrativeAreaRequestStateOrProvinceEnum.NovaScotia]: 'NS',
  [AdministrativeAreaRequestStateOrProvinceEnum.Ontario]: 'ON',
  [AdministrativeAreaRequestStateOrProvinceEnum.PrinceEdwardIsland]: 'PE',
  [AdministrativeAreaRequestStateOrProvinceEnum.Quebec]: 'QC',
  [AdministrativeAreaRequestStateOrProvinceEnum.Saskatchewan]: 'SK',
};

export const COUNTRY_ABBREVIATIONS: EnumMap<
  AdministrativeAreaRequestCountryEnum,
  string
> = {
  [AdministrativeAreaRequestCountryEnum.UnitedStates]: 'US',
  [AdministrativeAreaRequestCountryEnum.Canada]: 'CA',
};
