import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import {
  AddressResponseCountryEnum,
  NationalBusinessIdentificationTypeEnum,
} from '../../openapi/yenta';
import { DirectoryCommonEntityResponse } from '../../query/directory/useDirectory';
import ZenAction from '../Zen/ZenAction';

interface TaxDetailsSectionProps {
  handleEdit: (e?: MouseEvent) => void;
  shouldAllowToEdit: boolean;
  vendorData: DirectoryCommonEntityResponse;
  w9Url?: string;
}

const ZenTaxDetailsSection: React.FC<TaxDetailsSectionProps> = ({
  handleEdit,
  shouldAllowToEdit = false,
  vendorData,
  w9Url,
}) => {
  return (
    <div
      className={`border rounded-2xl w-full p-4 gap-3 bg-zen-light-gray-1 ${
        vendorData.address?.country === AddressResponseCountryEnum.Canada
          ? 'h-64'
          : 'h-32'
      }`}
    >
      <div className='flex flex-col'>
        <div className='flex flex-row justify-between items-center'>
          <p className='text-primary-dark font-inter font-semibold text-base'>
            Tax details
          </p>
          {shouldAllowToEdit && (
            <ZenAction
              text='Edit'
              icon={
                <FontAwesomeIcon
                  icon={faPencil}
                  size='sm'
                  className='text-primary-blue'
                />
              }
              onClick={handleEdit}
            />
          )}
        </div>
        {vendorData.address?.country === AddressResponseCountryEnum.Canada ? (
          <div className='mt-2'>
            <div className='flex flex-row items-center gap-x-6 ml-1'>
              <div
                className='text-zen-dark-28 font-inter font-light text-base'
                style={{ minWidth: '100px' }}
              >
                <p>Business No:</p>
              </div>
              <div>
                <p className='text-zen-dark-28 font-inter font-light text-base'>
                  {vendorData?.nationalBusinessIdentifications?.find(
                    (val) =>
                      val.type === NationalBusinessIdentificationTypeEnum.Bn,
                  )?.nationalId || '-'}
                </p>
              </div>
            </div>
            <div className='flex flex-row items-center gap-x-6 mt-3 ml-1'>
              <div
                className='text-zen-dark-28 font-inter font-light text-base'
                style={{ minWidth: '100px' }}
              >
                <p>GST Number:</p>
              </div>
              <div>
                <p className='text-zen-dark-28 font-inter font-light text-base'>
                  {vendorData?.nationalBusinessIdentifications?.find(
                    (val) =>
                      val.type === NationalBusinessIdentificationTypeEnum.GstId,
                  )?.nationalId || '-'}
                </p>
              </div>
            </div>
            <div className='flex flex-row gap-x-6 items-center mt-3 ml-1'>
              <div
                className='text-zen-dark-28 font-inter font-light text-base'
                style={{ minWidth: '100px' }}
              >
                <p>HST Number:</p>
              </div>
              <div>
                <p className='text-zen-dark-28 font-inter font-light text-base'>
                  {vendorData?.nationalBusinessIdentifications?.find(
                    (val) =>
                      val.type === NationalBusinessIdentificationTypeEnum.HstId,
                  )?.nationalId || '-'}
                </p>
              </div>
            </div>
            <div className='flex flex-row gap-x-6 items-center mt-3 ml-1'>
              <div
                className='text-zen-dark-28 font-inter font-light text-base'
                style={{ minWidth: '100px' }}
              >
                <p>QST Number:</p>
              </div>
              <div>
                <p className='text-zen-dark-28 font-inter font-light text-base'>
                  {vendorData?.nationalBusinessIdentifications?.find(
                    (val) =>
                      val.type === NationalBusinessIdentificationTypeEnum.QstId,
                  )?.nationalId || '-'}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='mt-3'>
            <div className='flex flex-row items-center gap-x-6 ml-1'>
              <div
                className='text-zen-dark-28 font-inter font-light text-base'
                style={{ width: '100px' }}
              >
                <p>EIN Number:</p>
              </div>
              <div>
                <p className='text-zen-dark-28 font-inter font-light text-base flex-shrink-0'>
                  {vendorData?.nationalBusinessIdentifications?.find(
                    (val) =>
                      val.type === NationalBusinessIdentificationTypeEnum.Ein,
                  )?.nationalId || '-'}
                </p>
              </div>
            </div>
            <div className='flex flex-row items-center gap-x-6 mt-3 ml-1'>
              <div
                className='text-zen-dark-28 font-inter font-light text-base'
                style={{ width: '100px' }}
              >
                <p>Form W-9:</p>
              </div>
              <div className='flex flex-row flex-shrink'>
                {vendorData?.hasW9 && w9Url ? (
                  <div
                    onClick={() => {
                      window.open(w9Url);
                    }}
                    className='text-primary-blue cursor-pointer flex-shrink'
                  >
                    View Form
                  </div>
                ) : (
                  '-'
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ZenTaxDetailsSection;
