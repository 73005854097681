import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Control, UseFormSetValue, UseFormTrigger } from 'react-hook-form-v7';
import { FlexTeamConfigDtoCountryEnum } from '../../../../openapi/yenta';
import { LeaderDto } from '../../../../routes/ZenTeamsProDetailsRoute';
import ErrorService from '../../../../services/ErrorService';
import {
  AsyncSelectOptionReactElement,
  FlexTeamRoles,
  ISelectOption,
} from '../../../../types';
import { getParticipantName } from '../../../../utils/ParticipantHelper';
import {
  getMaxFeeForAgentValidation,
  getMaxFeeForLeaderValidation,
} from '../../../../utils/ProTeams/ProTeamsHelper';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import { searchForAgents } from '../../../../utils/TableUtils';
import { PERCENT_VALIDATION } from '../../../../utils/Validations';
import ZenControlledAsyncSelectInput from '../../Input/ZenControlledAsyncSelectInput';
import ZenControlledSelectInput from '../../Input/ZenControlledSelectInput';
import ZenControlledSquareToggleInput from '../../Input/ZenControlledSquareToggleInput';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../../Input/ZenControlledToggleInput';
import ZenSidebarModalActionFooterV2 from '../../Modal/ZenSidebarModalActionFooterV2';
import ZenButton from '../../ZenButton';
import LeaderSplitPopover from './ProTeamPopover/LeaderSplitPopover';
import RealBEOPFeePopover from './ProTeamPopover/RealBEOPFeePopover';
import RealBrokerageFeePopover from './ProTeamPopover/RealBrokerageFeePopover';
import RealPostCapTransactionFeePopover from './ProTeamPopover/RealPostCapTransactionFeePopover';
import RealSplitFeePopover from './ProTeamPopover/RealSplitFeePopover';
import { ProTeamMember, TeamMemberDto } from './ZenEditProTeamMember';

interface ZenTeamMemberLeaderFeeSplitsTabProps {
  leaders?: LeaderDto[];
  onClose(): void;
  isEdit?: boolean;
  control: Control<ProTeamMember, object>;
  resetToDefault: (index: number) => void;
  fields: any;
  isSubmitting?: boolean;
  onSubmit: () => any;
  trigger: UseFormTrigger<ProTeamMember>;
  buttonText: string;
  roles: ISelectOption;
  setValue: UseFormSetValue<ProTeamMember>;
  teammate?: TeamMemberDto;
  setMemberToDelete?(teammate: TeamMemberDto): void;
  leaderFeeDetails?: ProTeamMember['leaderFeeDetails'];
  adminSplit?: boolean;
  readonly?: boolean;
  override?: boolean;
  removeMember?: boolean;
  isViewing?: boolean;
  country?: FlexTeamConfigDtoCountryEnum;
}

interface MaxFeeSplits {
  maxRealSplit?: number;
  maxLeaderSplit?: number;
  maxBEOPFee?: number;
  maxBrokerageFee?: number;
  maxTransactionFee?: number;
}

const ZenTeamMemberLeaderFeeSplitsTab: React.FC<ZenTeamMemberLeaderFeeSplitsTabProps> = ({
  leaders = [],
  onClose,
  isEdit,
  control,
  resetToDefault,
  fields,
  onSubmit,
  buttonText,
  isSubmitting,
  trigger,
  roles,
  teammate,
  leaderFeeDetails,
  setMemberToDelete,
  adminSplit,
  readonly,
  override,
  removeMember = false,
  setValue,
  isViewing,
  country,
}) => {
  const readOnly = !override || readonly;
  const [maxFeeSplits, setMaxFeeSplits] = React.useState<MaxFeeSplits[]>([]);
  const leaderOptions =
    leaders?.map((i) => {
      return {
        id: i.leaderDetails?.id,
        fullName: getParticipantName(i?.agent),
      };
    }) ?? [];

  const isTeammateRole =
    [FlexTeamRoles.Teammate, FlexTeamRoles.Admin].includes(
      roles?.value as FlexTeamRoles,
    ) && !isEdit;

  const isLeaderRole = useMemo(
    () => roles?.value === FlexTeamRoles.Leader && !isEdit,
    [roles, isEdit],
  );

  const agentFeeValues = useCallback(() => {
    if (roles?.value === 'LEADER') {
      const leaderList = leaders.filter(
        (val) => val.agent?.id !== teammate?.agent?.id!,
      );
      setMaxFeeSplits([getMaxFeeForLeaderValidation(leaderList)]);
    } else {
      const leaderDetails = leaderFeeDetails.map((leader: any) => {
        const leaderList = leaderFeeDetails.filter(
          (val: any) => leader?.teamLeader?.value !== val?.teamLeader?.value,
        );
        return getMaxFeeForAgentValidation(leaderList);
      });
      setMaxFeeSplits(leaderDetails);
    }
  }, [leaderFeeDetails, leaders, roles?.value, teammate?.agent?.id]);

  useEffect(() => {
    agentFeeValues();
  }, [agentFeeValues, leaderFeeDetails]);

  const onNext = async () => {
    agentFeeValues();
    const isValid = await trigger();
    if (isValid) {
      onSubmit();
    }
  };

  return (
    <div className='flex flex-col justify-between pb-14 !h-[95%]'>
      <div className='p-5 h-full overflow-y-auto'>
        {!isEdit && (
          <div className='mb-5 mr-3'>
            <ZenControlledAsyncSelectInput<ProTeamMember, 'agentId'>
              control={control}
              name='agentId'
              placeholder='Search Agent by Email or Name'
              label='Search Agent'
              shouldUnregister={false}
              fetchData={async (search, page) => {
                try {
                  const { data } = await searchForAgents({
                    page,
                    search,
                    filterBy: {
                      country: [country!],
                      agentStatus: ['ACTIVE'],
                    },
                  });

                  const options: AsyncSelectOptionReactElement[] = data.map(
                    (resp) => ({
                      value: `${resp.id}`,
                      label: `${resp.firstName} ${resp.lastName} - ${resp.emailAddress}`,
                    }),
                  );

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search request registered agents in add team member form',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                      },
                    },
                  );
                }

                return [];
              }}
              isRequired
              rules={{
                required: 'Please select a team member',
              }}
              startAdornment={
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className='text-primary-blue mx-3'
                  size='sm'
                />
              }
              endAdornment={
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className='text-zen-dark mx-5'
                  size='sm'
                />
              }
            />
          </div>
        )}
        <div className='my-5'>
          <ZenControlledSelectInput<ProTeamMember, 'roles'>
            name='roles'
            placeholder='Role'
            control={control}
            shouldUnregister={false}
            isRequired
            options={[
              ...values(FlexTeamRoles).map((role) => ({
                value: role,
                label: capitalizeEnum(role),
              })),
            ]}
            label='Select Team Member Role'
            readOnly={isEdit}
            rules={{
              required: 'Please select a role',
            }}
          />
        </div>

        {(isEdit || isTeammateRole) && (
          <div>
            <div className='font-normal font-primary-light text-sm text-zen-dark-7'>
              Choose the leader’s split and the leader’s responsibility to pay
              fees on behalf of their teammates. These are the defaults used for
              all teammates. These can also be adjusted individually for each
              team member when editing their personal settings.
            </div>
            {!roles.value.includes(FlexTeamRoles.Leader) && (
              <div className='my-2 flex justify-end pr-5'>
                <ZenControlledSquareToggleInput<ProTeamMember, 'override'>
                  label='Override?'
                  name='override'
                  control={control}
                  shouldUnregister={false}
                  readOnly={isViewing}
                  onChangeSpy={(value) => {
                    if (value) {
                      setValue('override', true);
                    } else {
                      setValue('override', false);
                    }
                  }}
                />
              </div>
            )}
            {adminSplit && (
              <div className='my-8'>
                <div className='border border-zen-dark-4 rounded-lg'>
                  <div className='p-3 border-zen-dark-4'>
                    <ZenControlledToggleInput<ProTeamMember, 'adminSplit'>
                      name='adminSplit'
                      control={control}
                      shouldUnregister={false}
                      label='Does this Admin pay a Leader Split?'
                      readOnly={readonly}
                    />
                  </div>
                </div>
              </div>
            )}
            {fields.map((field: any, index: number) => {
              return (
                <div key={field.id}>
                  <div className='mt-5'>
                    <ZenControlledSelectInput<
                      ProTeamMember,
                      `leaderFeeDetails.${number}.teamLeader`
                    >
                      name={`leaderFeeDetails.${index}.teamLeader` as const}
                      placeholder='Team Leader'
                      control={control}
                      options={[
                        ...leaderOptions?.map((val) => ({
                          value: val.id!,
                          label: val.fullName!,
                        })),
                      ]}
                      label='Team Leader'
                      shouldUnregister={false}
                      readOnly
                    />
                  </div>
                  <div className='mt-5'>
                    <div className='font-normal font-semi-bold text-lg text-zen-dark-9'>
                      Leader’s Participation in each transaction
                    </div>
                    <div className='h-px bg-zen-dark-5' />
                    <div className='mt-5 font-inter text-sm text-zen-dark-7'>
                      This is the percentage of each transaction gross amount
                      that will be automatically paid to this leader.
                    </div>
                    <div className='flex flex-row justify-between items-center mt-5'>
                      <LeaderSplitPopover />
                      <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                        <ZenControlledTextInput<
                          ProTeamMember,
                          `leaderFeeDetails.${number}.leaderSplit`
                        >
                          label='Teammate Pays to Real'
                          control={control}
                          startAdornment={
                            <div className='flex w-full h-full items-center justify-center pl-2'>
                              <FontAwesomeIcon
                                className='text-primary-blue'
                                icon={solid('percentage')}
                              />
                            </div>
                          }
                          readOnly={readOnly}
                          type='text'
                          name={
                            `leaderFeeDetails.${index}.leaderSplit` as const
                          }
                          placeholder='Leader’s Split'
                          shouldUnregister={false}
                          isRequired
                          rules={{
                            ...PERCENT_VALIDATION,
                            min: {
                              value: 0,
                              message: 'Number cannot be less than 0',
                            },
                            max: {
                              value: maxFeeSplits[index]?.maxLeaderSplit!,
                              message: "Total Percentage can't be > 100",
                            },
                            required: 'Please enter a leader split',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='mt-5'>
                    <div className='font-normal font-semi-bold text-lg text-zen-dark-9'>
                      Leader’s Payment on behalf Teammates
                    </div>
                    <div className='h-px bg-zen-dark-5' />
                    <div className='mt-5 font-inter text-sm text-zen-dark-7'>
                      This is the percentage of each fee this leader pays to
                      Real on behalf of their teammates.
                    </div>
                    <div className='space-y-2'>
                      <div className='flex flex-row justify-between items-center'>
                        <RealSplitFeePopover />
                        <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                          <ZenControlledTextInput<
                            ProTeamMember,
                            `leaderFeeDetails.${number}.realSplit`
                          >
                            label='Leader Pays to Real'
                            control={control}
                            type='text'
                            name={
                              `leaderFeeDetails.${index}.realSplit` as const
                            }
                            readOnly={readOnly}
                            placeholder='Real Split'
                            startAdornment={
                              <div className='flex w-full h-full items-center justify-center pl-2'>
                                <FontAwesomeIcon
                                  className='text-primary-blue'
                                  icon={solid('percentage')}
                                />
                              </div>
                            }
                            shouldUnregister={false}
                            isRequired={!!override}
                            rules={{
                              ...PERCENT_VALIDATION,
                              min: {
                                value: 0,
                                message: 'Number cannot be less than 0',
                              },
                              max: {
                                value: maxFeeSplits[index]?.maxRealSplit!,
                                message: "Total Percentage can't be > 100",
                              },
                              required: !!override
                                ? 'Please enter a real split'
                                : false,
                            }}
                          />
                        </div>
                      </div>
                      <div className='flex flex-row justify-between items-center'>
                        <RealBEOPFeePopover />
                        <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                          <ZenControlledTextInput<
                            ProTeamMember,
                            `leaderFeeDetails.${number}.beopFee`
                          >
                            control={control}
                            type='text'
                            readOnly={readOnly}
                            name={`leaderFeeDetails.${index}.beopFee` as const}
                            placeholder='Real BEOP Fee'
                            startAdornment={
                              <div className='flex w-full h-full items-center justify-center pl-2'>
                                <FontAwesomeIcon
                                  className='text-primary-blue'
                                  icon={solid('percentage')}
                                />
                              </div>
                            }
                            shouldUnregister={false}
                            isRequired={!!override}
                            rules={{
                              ...PERCENT_VALIDATION,
                              min: {
                                value: 0,
                                message: 'Number cannot be less than 0',
                              },
                              max: {
                                value: maxFeeSplits[index]?.maxBEOPFee!,
                                message: "Total Percentage can't be > 100",
                              },
                              required: !!override
                                ? 'Please enter a real BEOP fee'
                                : false,
                            }}
                          />
                        </div>
                      </div>
                      <div className='flex flex-row justify-between items-center'>
                        <RealBrokerageFeePopover />
                        <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                          <ZenControlledTextInput<
                            ProTeamMember,
                            `leaderFeeDetails.${number}.realFee`
                          >
                            control={control}
                            type='text'
                            readOnly={readOnly}
                            name={`leaderFeeDetails.${index}.realFee` as const}
                            placeholder='Real Brokerage Fee'
                            startAdornment={
                              <div className='flex w-full h-full items-center justify-center pl-2'>
                                <FontAwesomeIcon
                                  className='text-primary-blue'
                                  icon={solid('percentage')}
                                />
                              </div>
                            }
                            shouldUnregister={false}
                            isRequired={!!override}
                            rules={{
                              ...PERCENT_VALIDATION,
                              min: {
                                value: 0,
                                message: 'Number cannot be less than 0',
                              },
                              max: {
                                value: maxFeeSplits[index]?.maxBrokerageFee!,
                                message: "Total Percentage can't be > 100",
                              },
                              required: !!override
                                ? 'Please enter a real brokerage fee'
                                : false,
                            }}
                          />
                        </div>
                      </div>
                      <div className='flex flex-row justify-between items-center'>
                        <RealPostCapTransactionFeePopover />
                        <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                          <ZenControlledTextInput<
                            ProTeamMember,
                            `leaderFeeDetails.${number}.transactionFee`
                          >
                            control={control}
                            type='text'
                            readOnly={readOnly}
                            name={
                              `leaderFeeDetails.${index}.transactionFee` as const
                            }
                            startAdornment={
                              <div className='flex w-full h-full items-center justify-center pl-2'>
                                <FontAwesomeIcon
                                  className='text-primary-blue'
                                  icon={solid('percentage')}
                                />
                              </div>
                            }
                            isRequired={!!override}
                            rules={{
                              ...PERCENT_VALIDATION,
                              min: {
                                value: 0,
                                message: 'Number cannot be less than 0',
                              },
                              max: {
                                value: maxFeeSplits[index]?.maxTransactionFee!,
                                message: "Total Percentage can't be > 100",
                              },
                              required: !!override
                                ? 'Please enter a transaction fee'
                                : false,
                            }}
                            shouldUnregister={false}
                            placeholder='Real Post Cap Transaction Fee'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-row items-center justify-end'>
                    <ZenButton
                      variant='primary-link'
                      onClick={() => resetToDefault(index)}
                      label='Reset All to Defaults'
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {isLeaderRole && (
          <div>
            <div className='font-normal font-primary-light text-sm text-zen-dark-7'>
              Choose the leader’s split and the leader’s responsibility to pay
              fees on behalf of their teammates. These are the defaults used for
              all teammates. These can also be adjusted individually for each
              team member when editing their personal settings.
            </div>
            <div>
              <div className='mt-5'>
                <div className='font-normal font-semi-bold text-lg text-zen-dark-9'>
                  Leader’s Participation in each transaction
                </div>
                <div className='h-px bg-zen-dark-5' />
                <div className='mt-5 font-inter text-sm text-zen-dark-7'>
                  This is the percentage of each transaction gross amount that
                  will be automatically paid to this leader.
                </div>
                <div className='flex flex-row justify-between items-center mt-5'>
                  <LeaderSplitPopover />
                  <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                    <ZenControlledTextInput<ProTeamMember, `leaderSplit`>
                      label='Teammate Pays to Real'
                      control={control}
                      type='text'
                      name='leaderSplit'
                      placeholder='Leader’s Split'
                      startAdornment={
                        <div className='flex w-full h-full items-center justify-center pl-2'>
                          <FontAwesomeIcon
                            className='text-primary-blue'
                            icon={solid('percentage')}
                          />
                        </div>
                      }
                      shouldUnregister={false}
                      isRequired
                      readOnly={readOnly}
                      rules={{
                        ...PERCENT_VALIDATION,
                        min: {
                          value: 0,
                          message: 'Number cannot be less than 0',
                        },
                        max: {
                          value: maxFeeSplits[0]?.maxLeaderSplit!,
                          message: "Total Percentage can't be > 100",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='mt-5'>
                <div className='font-normal font-semi-bold text-lg text-zen-dark-9'>
                  Leader’s Payment on behalf Teammates
                </div>
                <div className='h-px bg-zen-dark-5' />
                <div className='mt-5 font-inter text-sm text-zen-dark-7'>
                  This is the percentage of each fee this leader pays to Real on
                  behalf of their teammates.
                </div>
                <div className='space-y-2'>
                  <div className='flex flex-row justify-between items-center'>
                    <RealSplitFeePopover />
                    <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                      <ZenControlledTextInput<ProTeamMember, `realSplit`>
                        label='Leader Pays to Real'
                        control={control}
                        type='text'
                        name='realSplit'
                        placeholder='Real Split'
                        startAdornment={
                          <div className='flex w-full h-full items-center justify-center pl-2'>
                            <FontAwesomeIcon
                              className='text-primary-blue'
                              icon={solid('percentage')}
                            />
                          </div>
                        }
                        readOnly={readOnly}
                        shouldUnregister={false}
                        rules={{
                          ...PERCENT_VALIDATION,
                          min: {
                            value: 0,
                            message: 'Number cannot be less than 0',
                          },
                          max: {
                            value: maxFeeSplits[0]?.maxRealSplit!,
                            message: "Total Percentage can't be > 100",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex flex-row justify-between items-center'>
                    <RealBEOPFeePopover />
                    <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                      <ZenControlledTextInput<ProTeamMember, `beopFee`>
                        control={control}
                        type='text'
                        name='beopFee'
                        placeholder='Real BEOP Fee'
                        startAdornment={
                          <div className='flex w-full h-full items-center justify-center pl-2'>
                            <FontAwesomeIcon
                              className='text-primary-blue'
                              icon={solid('percentage')}
                            />
                          </div>
                        }
                        readOnly={readOnly}
                        shouldUnregister={false}
                        rules={{
                          ...PERCENT_VALIDATION,
                          min: {
                            value: 0,
                            message: 'Number cannot be less than 0',
                          },
                          max: {
                            value: maxFeeSplits[0]?.maxBEOPFee!,
                            message: "Total Percentage can't be > 100",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex flex-row justify-between items-center'>
                    <RealBrokerageFeePopover />
                    <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                      <ZenControlledTextInput<ProTeamMember, `realFee`>
                        control={control}
                        type='text'
                        name='realFee'
                        placeholder='Real Brokerage Fee'
                        startAdornment={
                          <div className='flex w-full h-full items-center justify-center pl-2'>
                            <FontAwesomeIcon
                              className='text-primary-blue'
                              icon={solid('percentage')}
                            />
                          </div>
                        }
                        shouldUnregister={false}
                        readOnly={readOnly}
                        rules={{
                          ...PERCENT_VALIDATION,
                          min: {
                            value: 0,
                            message: 'Number cannot be less than 0',
                          },
                          max: {
                            value: maxFeeSplits[0]?.maxBrokerageFee!,
                            message: "Total Percentage can't be > 100",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex flex-row justify-between items-center'>
                    <RealPostCapTransactionFeePopover />
                    <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                      <ZenControlledTextInput<ProTeamMember, `transactionFee`>
                        control={control}
                        type='text'
                        readOnly={readOnly}
                        name='transactionFee'
                        startAdornment={
                          <div className='flex w-full h-full items-center justify-center pl-2'>
                            <FontAwesomeIcon
                              className='text-primary-blue'
                              icon={solid('percentage')}
                            />
                          </div>
                        }
                        rules={{
                          ...PERCENT_VALIDATION,
                          min: {
                            value: 0,
                            message: 'Number cannot be less than 0',
                          },
                          max: {
                            value: maxFeeSplits[0]?.maxTransactionFee!,
                            message: "Total Percentage can't be > 100",
                          },
                        }}
                        shouldUnregister={false}
                        placeholder='Real Post Cap Transaction Fee'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-row items-center justify-end'>
                <ZenButton
                  variant='primary-link'
                  onClick={() => resetToDefault(0)}
                  label='Reset All to Defaults'
                />
              </div>
            </div>
          </div>
        )}

        {isEdit && setMemberToDelete && teammate && removeMember && (
          <ZenButton
            variant='danger-link'
            LeftIconComponent={<FontAwesomeIcon icon={regular('trash-can')} />}
            label='Remove Member'
            onClick={() => setMemberToDelete(teammate!)}
          />
        )}
      </div>

      <ZenSidebarModalActionFooterV2
        onClick={onNext}
        onClose={onClose}
        buttonType='button'
        submitButtonText={buttonText}
        isDisabled={(isSubmitting || readonly) ?? false}
        isSubmitting={isSubmitting ?? false}
      />
    </div>
  );
};

export default ZenTeamMemberLeaderFeeSplitsTab;
