import React, { ReactElement } from 'react';
import ZenButton, { ZenButtonProps } from './Zen/ZenButton';

interface ZenToggleButtonProps extends ZenButtonProps {
  /**
   * If `true`, the button is rendered in an active state.
   */
  isSelected?: boolean;
  /**
   * The text to show in the button.
   */
  label: string;
  /**
   * The value to associate with the button when selected in a
   * ZenToggleButtonGroup.
   */
  value: string;

  leftIcon?: ReactElement;
  disabled?: boolean;
}

interface ZenToggleButtonGroupProps {
  /**
   * The content of the button.
   */
  children:
    | ReactElement<ZenToggleButtonProps>[]
    | ReactElement<ZenToggleButtonProps>;
  /**
   * If `true`, only allow one of the child ToggleButton values to be selected.
   */
  exclusive?: boolean;
  /**
   * Callback fired when the value changes.
   *
   * @param {object} event The event source of the callback.
   * @param {string|string[]} value of the selected buttons. When `exclusive` is true
   * this is a single value; when false an array of selected values.
   */
  onChange?: (
    event: React.MouseEvent<HTMLButtonElement>,
    value: string | string[],
  ) => void;
  value: string | string[];
}

export const ZenToggleButtonGroup = ({
  children,
  exclusive = false,
  onChange,
  value,
}: ZenToggleButtonGroupProps) => {
  const handleToggle = (
    buttonValue: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    let newValue: string | string[] = buttonValue;

    if (!exclusive) {
      const arrayValue = Array.isArray(value) ? value : [value];
      const currentIndex = arrayValue.indexOf(buttonValue);
      newValue = [...arrayValue];

      if (currentIndex === -1) {
        newValue.push(buttonValue);
      } else {
        newValue.splice(currentIndex, 1);
      }
    }

    onChange?.(event, newValue);
  };

  const isSelected = (buttonValue: string) => {
    if (value === buttonValue) {
      return true;
    }

    return exclusive
      ? value === buttonValue
      : Array.isArray(value) && value.includes(buttonValue);
  };

  return (
    <div className='flex gap-2'>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          onClick: (event: React.MouseEvent<HTMLButtonElement>) =>
            handleToggle(child.props.value, event),
          isSelected: isSelected(child.props.value),
        }),
      )}
    </div>
  );
};

export const ZenToggleButton = ({
  isSelected,
  label,
  onClick,
  value,
  leftIcon,
  disabled,
}: ZenToggleButtonProps) => {
  return (
    <ZenButton
      buttonProps={{ 'aria-pressed': isSelected, 'aria-label': label }}
      label={label}
      noWrap
      LeftIconComponent={leftIcon}
      onClick={!disabled ? onClick : undefined}
      value={value}
      variant={
        isSelected ? 'primary-outline-selected' : 'secondary-outline-selected'
      }
    />
  );
};
