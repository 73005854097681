import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FileResponse } from '../../../../openapi/dropbox';
import { getExtensionFromFileName } from '../../../../utils/FileUtils';
import ZenConfirmationModal from '../../Modal/ZenConfirmationModal';
import ZenIconCircleWithTooltipCell from '../ZenIconCircleWithTooltipCell';
import { RezenObjectTypeEnum } from '../../../../openapi/yada';
import { RootState } from '../../../../types';

interface TemplateItemNameCellProps {
  file: FileResponse;
  checklistId?: string;
  handleOpenFile: () => Promise<void>;
  handleEditFile: () => void;
  handleDelete(file: FileResponse): Promise<void>;
  handleRestore(file: FileResponse): Promise<void>;
  containerType: RezenObjectTypeEnum;
  containerId?: string;
  dropboxId: string;
  backUrl?: string;
  isTrashFolder: boolean;
  isDesignatedBroker?: boolean;
}

const FileCabinetActionCell: React.FC<TemplateItemNameCellProps> = ({
  file,
  checklistId,
  handleOpenFile,
  handleEditFile,
  handleDelete,
  handleRestore,
  containerType,
  containerId,
  dropboxId,
  backUrl,
  isTrashFolder,
  isDesignatedBroker,
}) => {
  const {
    auth: { isAdmin },
  } = useSelector((state: RootState) => state);
  const history = useHistory();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const isPDFFile = getExtensionFromFileName(file.path!) === 'pdf';
  const isAdminOrBroker = isAdmin || isDesignatedBroker;
  const isOfficeContainer = containerType === RezenObjectTypeEnum.Office;
  const isOfficeAdmin = isAdminOrBroker && isOfficeContainer;
  const showActionButton = !isOfficeContainer || isOfficeAdmin;
  const showSplitPDFButton =
    !isTrashFolder &&
    isPDFFile &&
    !isOfficeContainer &&
    containerId &&
    checklistId &&
    backUrl;
  const showEditButton = !isTrashFolder && showActionButton;
  const showRestoreButton = isTrashFolder && showActionButton;
  const showDeleteButton =
    (!isTrashFolder || (isTrashFolder && (isAdmin || isOfficeAdmin))) &&
    showActionButton;

  return (
    <div>
      <div className='space-x-3 flex'>
        {showSplitPDFButton && (
          <ZenIconCircleWithTooltipCell
            tooltipText='Split PDF'
            wrapText={false}
            iconVariant='light-gray'
            icon={
              <FontAwesomeIcon
                icon={regular('scissors')}
                title='split'
                className='space-x-3 flex'
              />
            }
            onClick={() => {
              history.push(
                `/${containerType}/${containerId}/checklist/${checklistId}/dropbox/${dropboxId}/split-and-attach-pdf?fileId=${file.id}&versionId=${file.currentVersion?.id}&backUrl=${backUrl}`,
              );
            }}
          />
        )}

        {showEditButton && (
          <ZenIconCircleWithTooltipCell
            tooltipText='Edit'
            iconVariant='light-gray'
            icon={
              <FontAwesomeIcon
                icon={regular('pencil')}
                title='edit'
                className='text-zen-dark-8 text-sm'
              />
            }
            onClick={handleEditFile}
          />
        )}

        {showRestoreButton && (
          <ZenIconCircleWithTooltipCell
            tooltipText='Restore'
            iconVariant='light-gray'
            icon={
              <FontAwesomeIcon
                icon={regular('arrow-rotate-right')}
                title='restore'
                className='text-zen-dark-8 text-sm'
              />
            }
            onClick={() => handleRestore([file!])}
          />
        )}

        <ZenIconCircleWithTooltipCell
          tooltipText='Download'
          iconVariant='light-gray'
          icon={
            <FontAwesomeIcon
              icon={regular('cloud-arrow-down')}
              title='download'
              className='text-zen-dark-8 text-sm'
            />
          }
          onClick={handleOpenFile}
        />

        {showDeleteButton && (
          <ZenIconCircleWithTooltipCell
            tooltipText={isTrashFolder ? 'Delete' : 'Archive'}
            iconVariant='error'
            icon={
              <FontAwesomeIcon
                icon={
                  isTrashFolder ? regular('trash-can') : regular('box-archive')
                }
                title={isTrashFolder ? 'delete' : 'archive'}
                className='text-zen-danger text-sm'
              />
            }
            onClick={() => setIsDeleteModalOpen(true)}
          />
        )}
      </div>

      <ZenConfirmationModal
        isOpen={isDeleteModalOpen}
        variant='danger'
        title={
          isTrashFolder
            ? 'Are you sure you want to delete this file?'
            : 'Are you sure you want to archive this file?'
        }
        confirmButtonText={isTrashFolder ? 'Delete' : 'Archive'}
        hideIcon
        subtitle={
          isTrashFolder
            ? 'Deleting this file will remove all previous versions as well.'
            : 'Archiving this file will remove all previous versions as well.'
        }
        onClose={async () => setIsDeleteModalOpen(false)}
        onConfirm={async () => {
          setIsDeleting(true);
          await handleDelete([file!]);
          setIsDeleting(false);
        }}
        isSubmitting={isDeleting}
        isDisabled={isDeleting}
      />
    </div>
  );
};

export default FileCabinetActionCell;
