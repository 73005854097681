import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Control, FieldArrayWithId } from 'react-hook-form-v7';
import { ParticipantResponse } from '../../../openapi/arrakis';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import { getRemainingPercentage } from '../../../utils/TeamHelper';
import { PERCENT_VALIDATION } from '../../../utils/Validations';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import RealBEOPFeePopover from '../Teams/ProTeams/ProTeamPopover/RealBEOPFeePopover';
import RealBrokerageFeePopover from '../Teams/ProTeams/ProTeamPopover/RealBrokerageFeePopover';
import RealPostCapTransactionFeePopover from '../Teams/ProTeams/ProTeamPopover/RealPostCapTransactionFeePopover';
import RealSplitFeePopover from '../Teams/ProTeams/ProTeamPopover/RealSplitFeePopover';
import {
  EditTransactionFormData,
  TeamLeaderFeeSplit,
} from './ZenEditTransactionParticipantFormSidebarModal';

interface ZenEditProTeamFeeSplitFormProps {
  control: Control<EditTransactionFormData, object>;
  fields: FieldArrayWithId<EditTransactionFormData, 'teamSplits', 'id'>[];
  participants?: ParticipantResponse[];
  readOnly?: boolean;
  teamSplits: TeamLeaderFeeSplit[];
}

const ZenEditProTeamFeeSplitForm: React.FC<ZenEditProTeamFeeSplitFormProps> = ({
  control,
  fields,
  participants,
  teamSplits,
  readOnly,
}) => {
  return (
    <div>
      <div className='space-y-2'>
        {fields.map((field, index: number) => {
          const participant = participants?.find(
            (p: any) => p.id === field.leaderId,
          )!;

          return (
            <div key={field.id}>
              <div className='mt-5'>
                <div className='font-normal font-semi-bold text-lg text-zen-dark-9'>
                  {getParticipantName(participant)} payment on behalf Teammates
                </div>
                <div className='h-px bg-zen-dark-5' />
                <div className='mt-5 font-inter text-sm text-zen-dark-7'>
                  This is the percentage of each fee this leader pays to Real on
                  behalf of their teammates.
                </div>
                <div className='space-y-2'>
                  <div className='flex flex-row justify-between items-center'>
                    <RealSplitFeePopover />
                    <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                      <ZenControlledTextInput<
                        EditTransactionFormData,
                        `teamSplits.${number}.realSplit`
                      >
                        label='Leader Pays to Real'
                        control={control}
                        type='text'
                        name={`teamSplits.${index}.realSplit` as const}
                        placeholder='Real Split'
                        readOnly={readOnly}
                        startAdornment={
                          <div className='flex w-full h-full items-center justify-center pl-2'>
                            <FontAwesomeIcon
                              className='text-primary-blue'
                              icon={solid('percentage')}
                            />
                          </div>
                        }
                        shouldUnregister={false}
                        rules={{
                          ...PERCENT_VALIDATION,
                          required: !readOnly ? 'Required' : undefined,
                          validate: () => {
                            const maxRealSplit = getRemainingPercentage(
                              teamSplits.reduce(
                                (init: number, splitData: any) => {
                                  return (
                                    init + Number(splitData?.realSplit ?? 0)
                                  );
                                },
                                0,
                              ),
                            );

                            if (+maxRealSplit < 0) {
                              return 'Total Percentage can not be > 100';
                            }
                            return true;
                          },
                          min: {
                            value: 0,
                            message: 'Number cannot be less than 0',
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex flex-row justify-between items-center'>
                    <RealBEOPFeePopover />
                    <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                      <ZenControlledTextInput<
                        EditTransactionFormData,
                        `teamSplits.${number}.beopFee`
                      >
                        control={control}
                        type='text'
                        name={`teamSplits.${index}.beopFee` as const}
                        placeholder='Real BEOP Fee'
                        startAdornment={
                          <div className='flex w-full h-full items-center justify-center pl-2'>
                            <FontAwesomeIcon
                              className='text-primary-blue'
                              icon={solid('percentage')}
                            />
                          </div>
                        }
                        shouldUnregister={false}
                        readOnly={readOnly}
                        rules={{
                          ...PERCENT_VALIDATION,
                          required: !readOnly ? 'Required' : undefined,
                          validate: () => {
                            const maxRealBEOPFee = getRemainingPercentage(
                              teamSplits.reduce(
                                (init: number, splitData: any) => {
                                  return init + Number(splitData?.beopFee ?? 0);
                                },
                                0,
                              ),
                            );
                            if (+maxRealBEOPFee < 0) {
                              return 'Total Percentage can not be > 100';
                            }
                            return true;
                          },
                          min: {
                            value: 0,
                            message: 'Number cannot be less than 0',
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex flex-row justify-between items-center'>
                    <RealBrokerageFeePopover />
                    <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                      <ZenControlledTextInput<
                        EditTransactionFormData,
                        `teamSplits.${number}.realFee`
                      >
                        control={control}
                        type='text'
                        name={`teamSplits.${index}.realFee` as const}
                        placeholder='Real Brokerage Fee'
                        readOnly={readOnly}
                        startAdornment={
                          <div className='flex w-full h-full items-center justify-center pl-2'>
                            <FontAwesomeIcon
                              className='text-primary-blue'
                              icon={solid('percentage')}
                            />
                          </div>
                        }
                        shouldUnregister={false}
                        rules={{
                          ...PERCENT_VALIDATION,
                          required: !readOnly ? 'Required' : undefined,
                          validate: () => {
                            const maxRealFee = getRemainingPercentage(
                              teamSplits.reduce(
                                (init: number, splitData: any) => {
                                  return init + Number(splitData?.realFee ?? 0);
                                },
                                0,
                              ),
                            );

                            if (+maxRealFee < 0) {
                              return 'Total Percentage can not be > 100';
                            }

                            return true;
                          },
                          min: {
                            value: 0,
                            message: 'Number cannot be less than 0',
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex flex-row justify-between items-center'>
                    <RealPostCapTransactionFeePopover />
                    <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                      <ZenControlledTextInput<
                        EditTransactionFormData,
                        `teamSplits.${number}.transactionFee`
                      >
                        control={control}
                        type='text'
                        name={`teamSplits.${index}.transactionFee` as const}
                        readOnly={readOnly}
                        startAdornment={
                          <div className='flex w-full h-full items-center justify-center pl-2'>
                            <FontAwesomeIcon
                              className='text-primary-blue'
                              icon={solid('percentage')}
                            />
                          </div>
                        }
                        rules={{
                          ...PERCENT_VALIDATION,
                          required: !readOnly ? 'Required' : undefined,
                          validate: () => {
                            const maxRealTransactionFee = getRemainingPercentage(
                              teamSplits.reduce(
                                (init: number, splitData: any) => {
                                  return (
                                    init +
                                    Number(splitData?.transactionFee ?? 0)
                                  );
                                },
                                0,
                              ),
                            );

                            if (+maxRealTransactionFee < 0) {
                              return 'Total Percentage can not be > 100';
                            }

                            return true;
                          },
                          min: {
                            value: 0,
                            message: 'Number cannot be less than 0',
                          },
                        }}
                        shouldUnregister={false}
                        placeholder='Real Post Cap Transaction Fee'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ZenEditProTeamFeeSplitForm;
