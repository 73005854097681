import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE } from '../constants/TableConstants';
import {
  AddGroupMemberRequest,
  BankAccountControllerApi,
  CreateOfficeRequest,
  GenericSearchResponseBankAccountResponse,
  GroupControllerApi,
  GroupResponse,
  OfficeControllerApi,
  OfficeGroupResponse,
  OfficeResponse,
  RemoveGroupMemberRequest,
  UpdateDefaultChecklistsAndJourneysRequest,
  UpdateOfficeRequest,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { AppThunk, ErrorCode, OfficeState } from '../types';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from './ErrorSlice';
import {
  showErrorToast,
  showErrorToastForErrorCode,
  showSuccessToast,
} from './ToastNotificationSlice';

export const initialState: OfficeState = {
  loadingDetail: false,
  officeDetail: undefined,
  fetchDetailErrorCode: null,
  officeDetailById: {},
  officeGroupsById: {},
};

const OfficeSlice = createSlice({
  name: 'office',
  initialState,
  reducers: {
    changeLoadingDetail(state, action: PayloadAction<boolean>) {
      state.loadingDetail = action.payload;
    },
    saveOfficeDetail(state, action: PayloadAction<OfficeResponse>) {
      state.officeDetail = action.payload;
      state.fetchDetailErrorCode = null;
    },
    errorFetchingDetail(state, action: PayloadAction<ErrorCode>) {
      state.fetchDetailErrorCode = action.payload;
    },
    saveOfficeDetailById(state, action: PayloadAction<OfficeResponse>) {
      state.officeDetailById[action.payload.id!] = action.payload;
    },
    saveOfficeGroupsById(
      state,
      action: PayloadAction<{ id: string; data: OfficeGroupResponse }>,
    ) {
      state.officeGroupsById[action.payload.id!] = action.payload.data;
    },
    updateOfficeGroup(
      state,
      action: PayloadAction<{ id: string; group: GroupResponse }>,
    ) {
      const { id, group } = action.payload;
      const groupIndex = (state.officeGroupsById[id!]?.groups || [])?.findIndex(
        (g) => g?.id === group?.id,
      );
      if (groupIndex !== -1) {
        state.officeGroupsById[id!]!.groups![groupIndex] = group;
      }
    },
  },
});

export const {
  changeLoadingDetail,
  errorFetchingDetail,
  saveOfficeDetail,
  saveOfficeDetailById,
  saveOfficeGroupsById,
  updateOfficeGroup,
} = OfficeSlice.actions;

export const fetchOfficeDetailById = (
  id: string,
  loading: boolean = true,
): AppThunk => async (dispatch) => {
  if (loading) {
    dispatch(changeLoadingDetail(true));
  }
  try {
    const { data } = await new OfficeControllerApi(
      getYentaConfiguration(),
    ).getOfficeById(id);
    dispatch(saveOfficeDetail(data));
    dispatch(saveOfficeDetailById(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch office detail', e, { office: { id } });
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    if (loading) {
      dispatch(changeLoadingDetail(false));
    }
  }
};

export const createOffice = (
  officeDetails: CreateOfficeRequest,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new OfficeControllerApi(
      getYentaConfiguration(),
    ).createOffice(officeDetails);
    dispatch(showSuccessToast('Office created successfully.'));
    return data.id;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to create the new office.',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to create new office', e, {
      values: officeDetails,
    });
    return undefined;
  }
};

export const updateOffice = (
  id: string,
  officeDetails: UpdateOfficeRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new OfficeControllerApi(
      getYentaConfiguration(),
    ).updateOffice(id, officeDetails);
    dispatch(saveOfficeDetail(data));
    dispatch(saveOfficeDetailById(data));
    dispatch(showSuccessToast('Office updated successfully.'));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update the office.',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to update office', e, {
      office: { id },
      values: officeDetails,
    });
    return false;
  }
};

export const updateOfficeImage = (
  id: string,
  officeImageFile: File,
): AppThunk => async (dispatch) => {
  try {
    await new OfficeControllerApi(getYentaConfiguration()).updateOfficeImage(
      id,
      officeImageFile,
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update the office image.',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to update office image', e, {
      office: { id },
    });
  }
};

export const updateChecklistsAndJourneys = (
  id: string,
  updateDefaultChecklistsAndJourneysRequest: UpdateDefaultChecklistsAndJourneysRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new OfficeControllerApi(
      getYentaConfiguration(),
    ).updateChecklistsAndJourneys(
      id,
      updateDefaultChecklistsAndJourneysRequest,
    );
    dispatch(saveOfficeDetail(data));
    dispatch(saveOfficeDetailById(data));
    dispatch(showSuccessToast('Default templates saved successfully'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to set default checklists and journeys.',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to set default checklists and journeys', e, {
      office: { id },
      values: updateDefaultChecklistsAndJourneysRequest,
    });
  }
};

export const fetchOfficeGroups = (officeId: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new OfficeControllerApi(
      getYentaConfiguration(),
    ).getVisibleOfficeGroups(officeId);
    dispatch(saveOfficeGroupsById({ id: officeId, data }));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem fetching office groups.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to fetch office groups', e, {
      office: { id: officeId },
    });
  }
};

export const addOfficeGroupMember = (
  officeId: string,
  id: string,
  req: AddGroupMemberRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new GroupControllerApi(
      getYentaConfiguration(),
    ).addMemberToGroup(id, req);
    dispatch(updateOfficeGroup({ id: officeId, group: data }));
    dispatch(showSuccessToast('Group member added successfully'));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem adding group member.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to add group member', e, {
      office: { id: officeId },
      groupId: { id },
      request: req,
    });
  }
};

export const removeOfficeGroupMember = (
  officeId: string,
  id: string,
  req: RemoveGroupMemberRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new GroupControllerApi(
      getYentaConfiguration(),
    ).removeMemberFromGroup(id, req);
    dispatch(updateOfficeGroup({ id: officeId, group: data }));
    dispatch(showSuccessToast('Group member removed successfully'));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem removing group member.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to remove group member', e, {
      office: { id: officeId },
      groupId: { id },
      request: req,
    });
  }
};

export const fetchMentionableOfficeGroups = (
  officeId: string,
): AppThunk<Promise<OfficeGroupResponse | undefined>> => async () => {
  try {
    const { data } = await new OfficeControllerApi(
      getYentaConfiguration(),
    ).getMentionableOfficeGroups(officeId);

    return data;
  } catch (e) {
    ErrorService.notify('Unable to fetch mentionable office groups', e, {
      office: { id: officeId },
    });

    return undefined;
  }
};

export const bankAccountSearchRequest = (
  sortBy: Parameters<
    typeof BankAccountControllerApi.prototype.searchBankAccounts
  >[0],
  sortDirection: Parameters<
    typeof BankAccountControllerApi.prototype.searchBankAccounts
  >[1],
  search?: string,
  pageNumber: number = 0,
  pageSize: number = DEFAULT_PAGE_SIZE,
  accountName?: string,
  bankName?: string,
  streetAddress?: string,
  accountNumber?: string,
  bankRoutingNumber?: string,
  wireRoutingNumber?: string,
): AppThunk<
  Promise<GenericSearchResponseBankAccountResponse | undefined>
> => async (dispatch) => {
  try {
    const { data } = await new BankAccountControllerApi(
      getYentaConfiguration(),
    ).searchBankAccounts(
      sortBy,
      sortDirection,
      pageNumber,
      pageSize,
      accountName,
      bankName,
      streetAddress,
      accountNumber,
      bankRoutingNumber,
      wireRoutingNumber,
      search,
    );

    return data;
  } catch (e) {
    ErrorService.notify(
      'Unable to search request bank accounts in create office form',
      e,
    );
    dispatch(
      showErrorToast(
        'An unexpected error occurred.',
        'We were unable to search for an bank account. Please try again in a few moments or contact support.',
      ),
    );

    return undefined;
  }
};

export default OfficeSlice.reducer;
