import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AnalyticsService from '../../../../services/AnalyticsService';
import { saveFMLSInfo } from '../../../../slices/TransactionBuilderSlice';
import { AnalyticsEventEnum, AppDispatch, YesNoType } from '../../../../types';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledRadioInput from '../../Input/ZenControlledRadioInput';
import ZenNonOutsideClickConfirmationModal from '../../Modal/ZenNonOutsideClickConfirmationModal';
import ZenButton from '../../ZenButton';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
  Match,
} from './ZenCreateTransactionSteps';

const ZenTransactionFMLSFeeStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({ form: { control, setValue, watch, trigger }, onNext, onPrevious }) => {
  const { transactionBuilderId } = useParams<Match>();
  const dispatch: AppDispatch = useDispatch();
  const [isFMLSProperty] = watch(['isFMLSProperty']);

  const [loading, setLoading] = useState<boolean>(false);
  const [noFMLSFee, setNoFMLSFee] = useState(false);

  useEffect(() => {
    if (isFMLSProperty === YesNoType.NO) {
      setNoFMLSFee(true);
    }
  }, [dispatch, isFMLSProperty]);

  const handleFMLSFee = async () => {
    const isValid = await trigger();
    if (isValid) {
      setLoading(true);
      const res = await dispatch(
        saveFMLSInfo(transactionBuilderId, {
          propertyListedOnFmls: isFMLSProperty === YesNoType.YES,
        }),
      );
      if (isFMLSProperty === YesNoType.YES) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.FMLS_CLICKED_YES_IN_TRANSACTION_BUILDER,
        );
      } else {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.FMLS_CLICKED_NO_IN_TRANSACTION_BUILDER,
        );
      }
      setLoading(false);
      if (res) {
        onNext();
      }
    }
  };
  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div className='mb-5 mt-4'>
          <ZenControlledRadioInput<CreateTransactionFormState, 'isFMLSProperty'>
            control={control}
            options={[
              {
                label: 'Yes',
                value: YesNoType.YES,
              },
              {
                label: 'No',
                value: YesNoType.NO,
              },
            ]}
            label='Is this property listed in FMLS? Please search FMLS for this property to confirm.'
            name='isFMLSProperty'
            shouldUnregister={false}
            inlineOptions
            rules={{
              required: 'Required',
            }}
            isRequired
          />
        </div>
        {noFMLSFee && (
          <ZenNonOutsideClickConfirmationModal
            variant='info'
            isOpen
            title='Acknowledgement'
            subtitle='I agree that I am solely responsible for any FMLS closing fees incurred for this transaction if this property is listed in FMLS.'
            onClose={() => {
              setValue('isFMLSProperty', undefined);
              setNoFMLSFee(false);
            }}
            isSubmitting={false}
            confirmButtonText='Agree'
            onConfirm={() => {
              setNoFMLSFee(false);
            }}
            size='medium'
          />
        )}
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              isDisabled={loading}
              label='Next'
              onClick={handleFMLSFee}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenTransactionFMLSFeeStep;
