import useGooglePlaceId from '../../../../hooks/useGooglePlaceId';
import {
  AgentWebsiteResponse,
  MoneyValueCurrencyEnum,
  PropertyFilterResponseCategoryTypeEnum,
} from '../../../../openapi/yenta';
import {
  getDisplayBaths,
  getDisplayBeds,
  getDisplayCategory,
} from '../../../../utils/AgentWebsiteUtils';
import { displayAmount } from '../../../../utils/CurrencyUtils';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import ZenContentRow from './ZenContentRow';

interface ZenListingInfoProps {
  agentWebsite: AgentWebsiteResponse | undefined;
}

const ZenListingInfo: React.FC<ZenListingInfoProps> = ({ agentWebsite }) => {
  const { place } = useGooglePlaceId(
    agentWebsite?.propertyFilter?.googlePlaceId,
  );

  return (
    <div className='flex flex-col space-y-4 p-4'>
      <ZenContentRow
        title='Category:'
        content={
          agentWebsite?.propertyFilter?.categoryType &&
          getDisplayCategory(agentWebsite?.propertyFilter?.categoryType)
        }
        defaultContent='Buy'
      />
      {agentWebsite?.propertyFilter?.categoryType !==
      PropertyFilterResponseCategoryTypeEnum.Mls ? (
        <ZenContentRow
          title='Location:'
          content={place?.formatted_address}
          defaultContent='None'
        />
      ) : (
        <ZenContentRow
          title='MLS Name/Number:'
          content={agentWebsite.propertyFilter.mlsNumber}
          defaultContent='N/A'
        />
      )}
      <ZenContentRow
        title='Property Type:'
        content={
          agentWebsite?.propertyFilter?.homeJunctionPropertyTypes &&
          agentWebsite?.propertyFilter?.homeJunctionPropertyTypes
            .map((type) => capitalizeEnum(type))
            .join(', ')
        }
        defaultContent='None Selected'
      />
      <ZenContentRow
        title='Price Range (USD):'
        content={`${displayAmount(
          {
            amount: agentWebsite?.propertyFilter?.minPrice || 0,
            currency: MoneyValueCurrencyEnum.Usd,
          },
          { hideCurrency: true, hideZeroCents: true },
        )} to ${displayAmount(
          {
            amount: agentWebsite?.propertyFilter?.maxPrice || 0,
            currency: MoneyValueCurrencyEnum.Usd,
          },
          { hideCurrency: true, hideZeroCents: true },
        )}`}
        defaultContent='Any to Any'
      />
      <ZenContentRow
        title='Beds and Baths:'
        content={`${getDisplayBeds(
          agentWebsite?.propertyFilter?.bedrooms,
        )}, ${getDisplayBaths(agentWebsite?.propertyFilter?.bathrooms)}`}
        defaultContent='Any Bedrooms, Any Bathrooms'
      />
    </div>
  );
};

export default ZenListingInfo;
