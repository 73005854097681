import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { faCheckDouble } from '@fortawesome/pro-regular-svg-icons';
import config from '../../config';
import { AnalyticsEventEnum, RootState } from '../../types';
import ReferAnAgentSvg from '../../assets/icons/revshare-insights/refer-an-agent.svg';
import AnalyticsService from '../../services/AnalyticsService';

interface RevShareInsightsReferNowProps {
  onClickLearnMore(): void;
}

const RevShareInsightsReferNow: React.FC<RevShareInsightsReferNowProps> = ({
  onClickLearnMore,
}) => {
  const {
    agentDetail: { detailResponse },
  } = useSelector((state: RootState) => state);

  const agentDetail = detailResponse.data;

  const referralLink = `${config.reactAppHostUrl}/register?${qs.stringify({
    sponsorCode: agentDetail?.sponsorCode,
    sponsorName: agentDetail?.fullName,
  })}`;

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyText = async () => {
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.RSI_COPIES_REFER_NOW_LINK,
    );
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(referralLink);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = referralLink;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      await new Promise<void>((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div
      className='h-full py-3 pr-4 pl-4 lg:pl-6 border text-sm rounded-[10px] bg-no-repeat bg-right-bottom xl:bg-[right_1rem_bottom]'
      style={{ backgroundImage: `url(${ReferAnAgentSvg})` }}
      aria-label='refer-an-agent'
    >
      <div className='flex items-center space-x-3'>
        <FontAwesomeIcon
          icon={solid('user-group')}
          className='text-primary-blue'
          size='xl'
        />
        <div className='space-y-0.5'>
          <p className='text-base text-zen-dark-9 font-semibold font-zen-body'>
            Refer an Agent
          </p>
          <button
            className='text-primary-blue underline'
            onClick={onClickLearnMore}
          >
            Learn more
          </button>
        </div>
      </div>
      <div className='mt-5'>
        <Popover
          isOpen={isCopied}
          positions={['bottom', 'left', 'right', 'top']}
          padding={5}
          align='center'
          onClickOutside={() => setIsCopied(false)}
          content={({ position, childRect, popoverRect }) => {
            return (
              <ArrowContainer
                position={position}
                popoverRect={popoverRect}
                childRect={childRect}
                arrowColor='white'
                arrowSize={10}
                className='popover-arrow-container drop-shadow-lg'
              >
                <div className='px-2 py-1 rounded-md font-zen-body text-sm text-zen-dark-9 bg-white flex items-center justify-center space-x-1'>
                  <FontAwesomeIcon icon={faCheckDouble} className='text-base' />
                  <p className='text-xs'>Copied</p>
                </div>
              </ArrowContainer>
            );
          }}
        >
          <span
            onClick={handleCopyText}
            className='font-zen-body bg-primary-blue rounded-full leading-4 text-white px-3 py-1.5 cursor-pointer'
          >
            Refer now
          </span>
        </Popover>
      </div>
    </div>
  );
};

export default RevShareInsightsReferNow;
