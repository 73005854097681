import React from 'react';
import Loftyimg from '../../assets/img/Lofty_Logo_Image.png';
import { LOFTY_SUBSCRIPTION_AMOUNT } from '../../constants/LoftyConstants';

const ZenLoftySubscriptionCard: React.FC = () => {
  return (
    <div className='pt-6 pb-5 px-5 space-x-6 border flex items-center rounded-md'>
      <img src={Loftyimg} alt='lofty' width={80} className='mx-3' />
      <div className='space-y-3'>
        <p className='text-zen-dark-9 font-zen-title font-light text-base'>
          A powerful CRM that helps you nurture leads and improve productivity.
        </p>
        <p className='mt-1 font-medium font-zen-title text-base text-primary-blue'>
          Activate for ${LOFTY_SUBSCRIPTION_AMOUNT}/Month
        </p>
      </div>
    </div>
  );
};

export default ZenLoftySubscriptionCard;
