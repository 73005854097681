import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../../../openapi/arrakis';
import { AppDispatch, EnumMap, RootState } from '../../../../types';
import ZenButton, { ZenButtonVariant } from '../../ZenButton';
import { fetchTransactionPaymentInvoices } from '../../../../slices/TransactionSlice';
import { getTransactionTransitionToButtonText } from '../../../../utils/TransactionHelper';

interface ZenTransactionActionProps {
  action: TransactionLifecycleStateValueStateEnum;
  onClick(): void;
  variant?: ZenButtonVariant;
  transaction: TransactionResponse;
}

const ZenTransactionAction: React.FC<ZenTransactionActionProps> = ({
  action,
  onClick,
  variant = 'primary',
  transaction,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const transactionStatusToIcon: EnumMap<
    TransactionLifecycleStateValueStateEnum,
    React.ReactElement
  > = {
    [TransactionLifecycleStateValueStateEnum.ApprovedForClosing]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved]: (
      <FontAwesomeIcon icon={faEnvelope} />
    ),
    [TransactionLifecycleStateValueStateEnum.CommissionDocumentGenerated]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.CommissionDocumentSent]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.ReadyForCommissionDocumentGeneration]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.Settled]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.CalculateLedger]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.Closed]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.CommissionValidated]: (
      <FontAwesomeIcon icon={faCheck} />
    ),
    [TransactionLifecycleStateValueStateEnum.NeedsCommissionValidation]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.New]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.PaymentAccepted]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.PaymentScheduled]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.WaitingOnPayment]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.Terminated]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.ListingActive]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.ListingClosed]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.ListingInContract]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
    [TransactionLifecycleStateValueStateEnum.TerminationRequested]: (
      <FontAwesomeIcon icon={faArrowRight} />
    ),
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchInvoices = useCallback(async () => {
    setIsLoading(true);
    await dispatch(fetchTransactionPaymentInvoices(transaction));
    setIsLoading(false);
  }, [dispatch, transaction]);

  useEffect(() => {
    if (action === TransactionLifecycleStateValueStateEnum.PaymentAccepted) {
      fetchInvoices();
    }
  }, [action, fetchInvoices]);

  const {
    transaction: { hasPaymentInvoices },
  } = useSelector((state: RootState) => state);

  const disabled =
    action === TransactionLifecycleStateValueStateEnum.PaymentAccepted &&
    !hasPaymentInvoices;

  return (
    <ZenButton
      LeftIconComponent={transactionStatusToIcon[action]}
      type='button'
      variant={variant}
      label={getTransactionTransitionToButtonText(transaction!, action)}
      onClick={onClick}
      isDisabled={disabled || isLoading}
      isFullWidth
    />
  );
};

export default ZenTransactionAction;
