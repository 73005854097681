import React from 'react';
import { useIFrameInferredClick } from '../../hooks/useIFrameInferredClick';

interface ZenIFrameContainerProps
  extends React.DetailedHTMLProps<
    React.IframeHTMLAttributes<HTMLIFrameElement>,
    HTMLIFrameElement
  > {
  onClick(): void;
}
const ZenIFrameContainer: React.FC<ZenIFrameContainerProps> = (props) => {
  const { iframeRef } = useIFrameInferredClick({
    onClick: props.onClick,
  });
  return <iframe ref={iframeRef} {...props} title={props.title} />;
};

export default ZenIFrameContainer;
