import { useScreenSize } from '../utils';
import { WealthPlanDisclaimer } from '../WealthPlanDisclaimer';
import EarningOverview from './EarningOverview';
import { GoalOverviewCard } from './GoalOverviewCard';
import GotoRealAcademyCard from './GotoRealAcademyCard';
import LearnMoreSection from './LearnMoreSection';
import SendToFriendCard from './SendToFriendCard';
import WealthGoalInfo from './WealthGoalInfo';

interface WealthManagementOverviewPageProps {
  openGoalsSideBar(): void;
}

export const WealthManagementOverviewPageV2: React.FC<WealthManagementOverviewPageProps> = ({
  openGoalsSideBar,
}) => {
  const screenSize = useScreenSize();
  const isSmallScreen = screenSize.screen === 'xs' || screenSize.screen === 's';
  if (isSmallScreen) {
    return (
      <div className='w-full flex flex-col space-y-4'>
        <WealthGoalInfo />
        <GotoRealAcademyCard />
        <GoalOverviewCard />
        <SendToFriendCard />
        <EarningOverview openGoalsSideBar={openGoalsSideBar} />
        <LearnMoreSection />
        <WealthPlanDisclaimer />
      </div>
    );
  }
  return (
    <div className='flex flex-col space-y-6'>
      <div className='flex flex-row space-x-3.5'>
        <div className='md:w-[29%] w-full flex flex-col justify-between space-y-4'>
          <WealthGoalInfo />
          <GotoRealAcademyCard />
        </div>
        <div className='flex md:w-[47%] w-full'>
          <GoalOverviewCard />
        </div>
        <div className='md:w-[22%] w-full flex-1'>
          <SendToFriendCard />
        </div>
      </div>
      <EarningOverview openGoalsSideBar={openGoalsSideBar} />
      <LearnMoreSection />
      <WealthPlanDisclaimer />
    </div>
  );
};
