import { TextInputProps as MTextInputProps } from '@mantine/core';
import { FieldValues, FieldPath, useController } from 'react-hook-form';
import { TextInput as BaseTextInput } from '../TextInput';
import { HookFormControllerProps } from './types';

type HookFormTextInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = HookFormControllerProps<TFieldValues, TName> & MTextInputProps;

export const HookFormTextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: HookFormTextInputProps<TFieldValues, TName>,
) => {
  const {
    formState: { defaultValues },
    field,
    fieldState,
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <BaseTextInput
      {...props}
      defaultValue={defaultValues?.[props.name]}
      onChange={(e) => {
        field.onChange(e);
        props.onChange?.(e.target.value);
      }}
      onBlur={(e) => {
        field.onBlur();
        props.onBlur?.(e);
      }}
      error={fieldState.error?.message}
    />
  );
};
