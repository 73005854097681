import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  OfficePreviewResponseTransactionTypeEnum,
  OfficeResponseTransactionTypeEnum,
} from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenPill, { ZenPillVariant } from '../../Zen/ZenPill';

export interface ZenOfficeTransactionTypeCellProps {
  transactionType:
    | OfficeResponseTransactionTypeEnum
    | OfficePreviewResponseTransactionTypeEnum;
}

export const transactionTypeToPillVariant: EnumMap<
  OfficeResponseTransactionTypeEnum,
  ZenPillVariant
> = {
  SINGLE_CHECK: 'primary-nooutline',
  SPLIT_CHECK: 'purple',
};

export const transactionTypeIconToPillVariant: EnumMap<
  OfficeResponseTransactionTypeEnum,
  React.ReactElement
> = {
  SINGLE_CHECK: (
    <FontAwesomeIcon icon={regular('money-check-dollar')} size='sm' />
  ),
  SPLIT_CHECK: <FontAwesomeIcon icon={regular('split')} size='sm' />,
};

const ZenOfficeTransactionTypeCell: React.FC<ZenOfficeTransactionTypeCellProps> = ({
  transactionType,
}) => {
  return (
    <div className='w-max'>
      <ZenPill
        title={capitalizeEnum(transactionType)}
        variant={transactionTypeToPillVariant[transactionType]}
        leftIcon={transactionTypeIconToPillVariant[transactionType]}
        textStyle='text-sm pl-0.5'
      />
    </div>
  );
};

export default ZenOfficeTransactionTypeCell;
