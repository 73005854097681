import { SessionStorageKeyEnum } from '../types';

export default class SessionStorageService {
  static setItem(key: SessionStorageKeyEnum, value: string) {
    try {
      window.sessionStorage.setItem(key, value);
    } catch (e) {}
  }

  static getItem(key: SessionStorageKeyEnum) {
    try {
      return window.sessionStorage.getItem(key);
    } catch (e) {
      return null;
    }
  }

  static removeItem(key: SessionStorageKeyEnum) {
    try {
      window.sessionStorage.removeItem(key);
    } catch (e) {}
  }

  static clear() {
    try {
      window.sessionStorage.clear();
    } catch (e) {}
  }
}
