import { TextInputProps as MTextInputProps } from '@mantine/core';
import { FieldValues, FieldPath, useController } from 'react-hook-form';
import { PasswordInput as BasePasswordInput } from '../PasswordInput';
import { ErrorComponent } from '../ErrorComponent';
import { HookFormControllerProps } from './types';

type HookFormPasswordInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = HookFormControllerProps<TFieldValues, TName> & MTextInputProps;

export const HookFormPasswordInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  onChange,
  ...props
}: HookFormPasswordInputProps<TFieldValues, TName>) => {
  const {
    formState: { defaultValues },
    field,
    fieldState,
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <BasePasswordInput
      {...props}
      defaultValue={defaultValues?.[props.name]}
      onChange={(e) => {
        field.onChange(e);
        onChange?.(e.target.value);
      }}
      onBlur={field.onBlur}
      error={
        fieldState.error ? (
          <ErrorComponent errors={fieldState.error} />
        ) : undefined
      }
    />
  );
};
