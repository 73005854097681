import { faGavel } from '@fortawesome/pro-light-svg-icons';
import { faCircleDollar } from '@fortawesome/pro-regular-svg-icons';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import millify from 'millify';
import React, { useState } from 'react';
import GeminiButton from '../../components/Gemini/GeminiButton';
import ZenAvatar from '../../components/Zen/ZenAvatar';
import AddressList from './components/ReferralAddressList';
import ReferralDetailSelectAgentSidebar from './sidebars/ReferralDetailSelectAgentSidebar';

interface ReferralApplicantCardProps {
  name: string;
  totalSales: number;
  minimumPrice: number;
  maximumPrice: number;
  address: string[];
  numberOfListings?: number;
  note?: string;
  setIsSendReferralSidebarOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReferralApplicantCard: React.FC<ReferralApplicantCardProps> = ({
  name,
  totalSales,
  minimumPrice,
  maximumPrice,
  address,
  numberOfListings,
  note,
  setIsSendReferralSidebarOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className='border border-grey-300 rounded-xl font-inter'>
      <div className='flex flex-row items-center justify-center py-2'>
        <p className='text-primary-dark text-base'>
          <FontAwesomeIcon icon={faGavel} /> ${millify(totalSales)} Total Sales
        </p>
      </div>
      <div className='flex flex-col justify-between border border-grey-300 border-b-0 shadow-top-lg rounded-xl -mx-px h-[88%]'>
        <div className='flex flex-row items-start justify-between pl-3 pr-1 py-2'>
          <div className='flex items-start justify-start space-x-1'>
            <div className='pt-1'>
              <ZenAvatar name={name} variant='circle' size='md' />
            </div>
            <div className='flex flex-col justify-start pl-1'>
              <p className='text-primary-dark font-normal'>{name}</p>
              <div className='flex flex-row items-center justify-start space-x-1'>
                <FontAwesomeIcon
                  icon={faCircleDollar}
                  className='text-grey-500'
                />
                <p className='text-grey-600'>
                  {millify(minimumPrice)} - {millify(maximumPrice)}
                </p>
              </div>
              {numberOfListings && (
                <p className='text-grey-600 font-normal text-base'>
                  Listings {numberOfListings}
                </p>
              )}
            </div>
          </div>
        </div>
        <AddressList address={address} />

        {!!note && (
          <div className='border border-grey-200 rounded-lg m-2 p-2'>
            <p className='text-sm text-grey-600 font-normal'>
              Application Note
            </p>
            <p className='text-sm text-grey-600 font-light'>{note}</p>
          </div>
        )}
        <div>
          <div className='flex flex-row items-center space-x-3 pb-3 pt-1 px-3'>
            <GeminiButton
              label='Select Agent'
              isFullWidth
              variant='primary-outline'
              onClick={() => {
                setIsSendReferralSidebarOpen?.(true);
              }}
            />
            <GeminiButton
              leftIcon={faEye}
              variant='primary-outline'
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </div>
        </div>
      </div>
      <ReferralDetailSelectAgentSidebar
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ReferralApplicantCard;
