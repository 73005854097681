import React from 'react';
import ZenButton from '../../../ZenButton';

type LeoSocialShareablesMobileCardProps = {
  posterLink: string;
  openModal: () => void;
};

const LeoSocialShareablesMobileCard: React.FC<LeoSocialShareablesMobileCardProps> = ({
  posterLink,
  openModal,
}) => {
  return (
    <div className='w-40 lg:w-60 text-white flex flex-col gap-2'>
      <div className='w-40 h-40 lg:w-60 lg:h-60 rounded-lg'>
        <img
          id='poster-image'
          src={posterLink}
          className='w-full h-full rounded-lg'
          alt='poster'
          onClick={() => openModal()}
        />
      </div>
      <ZenButton
        label='More Details'
        variant='dark-outline'
        className='bg-transparent text-white border-2 border-[#282932] font-inter'
        isFullWidth
        onClick={(e: any) => {
          e.preventDefault();
          openModal();
        }}
      />
    </div>
  );
};

export default LeoSocialShareablesMobileCard;
