import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  CreateChecklistFileUploadQuestion,
  QuestionAssigneeAssigneeTypeEnum,
} from '../../openapi/atlantis';
import AnalyticsService from '../../services/AnalyticsService';
import { addFileQuestion } from '../../slices/MortgageSlice';
import {
  AnalyticsEventEnum,
  AppDispatch,
  ISelectOption,
  RootState,
} from '../../types';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../Zen/ZenSidebarModalForm';

interface MortgageAddChecklistTaskProps {
  isOpen: boolean;
  onClose(): void;
  assigneeList?: ISelectOption[];
}

interface FormData {
  title: string;
  subtitle: string;
  assignee?: ISelectOption;
}

const MortgageAddChecklistTask: React.FC<MortgageAddChecklistTaskProps> = ({
  isOpen,
  onClose,
  assigneeList = [],
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    loanResponse: { data: loan },
  } = useSelector((state: RootState) => state.mortgage);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      assignee: assigneeList?.find((item) => item.value === null),
    },
  });

  const onSubmit = async (formData: FormData) => {
    const borrower = assigneeList?.find((item) => item.value !== null);

    const requestBody: CreateChecklistFileUploadQuestion = {
      title: formData.title,
      subtitle: formData.subtitle,
      borrowerId: borrower?.value!,
      assignee: formData.assignee?.value
        ? {
            assigneeId: formData.assignee?.value,
            assigneeType: QuestionAssigneeAssigneeTypeEnum.Borrower,
          }
        : undefined,
    };

    const response = await dispatch(addFileQuestion(requestBody, loan?.id!));

    if (response) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.MC_CHECKLIST_ITEM_ASSIGNED,
      );
    }

    onClose();
  };

  return (
    <ZenSidebarModalForm
      isOpen={isOpen}
      onClose={onClose}
      isSubmitting={isSubmitting}
      title='New Task'
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Create Task'
      cancelTitle='Cancel'
    >
      <ZenControlledTextInput<FormData, 'title'>
        control={control}
        label='Question Title'
        name='title'
        isRequired
        placeholder='Title'
        rules={{ required: 'Please enter title.' }}
      />

      <div className='mt-5'>
        <ZenControlledTextInput<FormData, 'subtitle'>
          control={control}
          label='Question Subtitle'
          name='subtitle'
          isRequired
          placeholder='Subtitle'
          rules={{ required: 'Please enter subtitle.' }}
        />
      </div>

      <div className='mt-5'>
        <ZenControlledSelectInput<FormData, 'assignee'>
          control={control}
          name='assignee'
          label='Assigned To'
          placeholder='Add Role'
          options={[...assigneeList]}
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default MortgageAddChecklistTask;
