import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, Variants } from 'framer-motion';
import React, { useState } from 'react';
import { Transcript } from '../../../../openapi/yada';

const variants: Variants = {
  initial: { opacity: 0, transition: { duration: 1 } },
  animate: { opacity: 1, transition: { duration: 1 } },
};

type VoiceRecordingTranscriptProps = {
  transcript?: Transcript;
};

const VoiceRecordingTranscript: React.FC<VoiceRecordingTranscriptProps> = ({
  transcript,
}) => {
  const [showTranscript, setShowTranscript] = useState(true);

  return (
    <motion.div
      variants={variants}
      className='font-zen-body text-sm'
      initial='initial'
      animate='animate'
    >
      <button
        className='flex items-center space-x-1.5'
        onClick={() => setShowTranscript(!showTranscript)}
        type='button'
      >
        <p className='text-sm font-medium text-rezen-blue-600'>Transcript</p>
        <FontAwesomeIcon
          icon={showTranscript ? faChevronUp : faChevronDown}
          className='text-rezen-blue-600'
          fontSize={14}
        />
      </button>
      {showTranscript && (
        <p className='pt-4 text-sm text-primary-dark font-inter'>
          {transcript?.text}
        </p>
      )}
    </motion.div>
  );
};

export default VoiceRecordingTranscript;
