import {
  faPen,
  faPersonWalkingArrowRight,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  AgentResponse,
  BoardControllerApi,
  BoardResponse,
  BoardSecretsResponse,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { FeatureFlagTypeEnum } from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../../Zen/ZenButton';
import GeminiCreateBoardForm from '../Gemini/GeminiCreateBoardForm';
import ZenCreateBoardForm from './ZenCreateBoardForm';
import ZenMoveAllAgentsToBoard from './ZenMoveAllAgentsToBoard';

interface ZenBoardActionHeaderProps {
  board: BoardResponse;
  secrets?: BoardSecretsResponse;
  agents: AgentResponse[];
  refetch: () => void;
}

const ZenBoardActionHeader: React.FC<ZenBoardActionHeaderProps> = ({
  board,
  secrets,
  agents,
  refetch,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openEditBoardForm, setOpenEditBoardForm] = useState<boolean>(false);
  const [isDeleteBoard, setIsDeleteBoard] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isMoveAllAgentsOpen, setIsMoveAllAgentsOpen] = useState<boolean>(
    false,
  );

  const isBoardAndMlsImprovementsFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.BOARD_AND_MLS_IMPROVEMENTS,
  );

  const handleDeleteBoard = async () => {
    setIsSubmitting(true);
    try {
      await new BoardControllerApi(getYentaConfiguration()).deleteBoard(
        board?.id!,
      );
      history.push('/boards');
      dispatch(showSuccessToast('Board deleted successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to delete board', e, {
        board: { id: board?.id },
      });
      dispatch(
        showErrorToast(
          'We had a problem deleting board',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setIsSubmitting(false);
      setIsDeleteBoard(false);
    }
  };

  const CreateBoardSidebarModal = isBoardAndMlsImprovementsFlagEnabled
    ? GeminiCreateBoardForm
    : ZenCreateBoardForm;

  return (
    <div className='flex justify-start space-x-3'>
      <ZenButton
        variant='secondary-gray-outline'
        label='Edit Board'
        LeftIconComponent={<FontAwesomeIcon icon={faPen} className='mr-1' />}
        onClick={() => setOpenEditBoardForm(true)}
      />
      <ZenButton
        variant='secondary-gray-outline'
        label='Move All Agents To'
        LeftIconComponent={
          <FontAwesomeIcon
            icon={faPersonWalkingArrowRight}
            className='mr-1 text-lg'
          />
        }
        onClick={() => setIsMoveAllAgentsOpen(true)}
        isDisabled={!agents?.length}
      />
      {!agents?.length && (
        <ZenButton
          variant='danger-outline'
          label='Delete Board'
          LeftIconComponent={
            <FontAwesomeIcon
              icon={faTrashCan}
              className='text-error text-sm mr-1'
            />
          }
          onClick={() => setIsDeleteBoard(true)}
        />
      )}
      <ZenConfirmationModal
        variant='danger'
        title='Delete this Board?'
        subtitle={`This will delete "${board?.name}" permanently.`}
        isOpen={isDeleteBoard}
        onClose={() => setIsDeleteBoard(false)}
        onConfirm={handleDeleteBoard}
        cancelButtonText='No'
        confirmButtonText='Yes'
        isSubmitting={isSubmitting}
      />
      {openEditBoardForm && (
        <CreateBoardSidebarModal
          isOpen
          secrets={secrets}
          onClose={() => {
            refetch();
            setOpenEditBoardForm(false);
          }}
          boardDetails={board}
        />
      )}
      {isMoveAllAgentsOpen && (
        <ZenMoveAllAgentsToBoard
          board={board}
          isOpen={isMoveAllAgentsOpen}
          onClose={() => setIsMoveAllAgentsOpen(false)}
        />
      )}
    </div>
  );
};

export default ZenBoardActionHeader;
