interface EditOutlineActionButtonProps {
  text?: string;
  onClick(): void;
  icon?: React.ReactElement;
  a11yLabel?: string;
}

const EditOutlineActionButton: React.FC<EditOutlineActionButtonProps> = ({
  text,
  onClick,
  icon,
  a11yLabel,
}) => {
  return (
    <button
      title={a11yLabel}
      onClick={onClick}
      className='flex flex-row rounded items-center focus:outline-none text-primary hover:bg-primary hover:bg-opacity-10 p-2'
    >
      {!!icon && <span className='text-primary mr-1'>{icon}</span>}
      {!!text && <span className='text-primary pt-1'>{text}</span>}
    </button>
  );
};

export default EditOutlineActionButton;
