import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BoundExperimentResponse,
  CreateDefaultExperimentRequest,
  ExperimentControllerApi,
  ExperimentGroupResponse,
  ExperimentResponse,
  ExperimentResponseStateEnum,
  PaginatedExperimentsResponse,
  UpdateExperimentGroupRequest,
  UserControllerApi,
} from '../openapi/yenta';
import { AppThunk, ErrorCode, ExperimentSliceState } from '../types';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import ErrorService from '../services/ErrorService';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from './ToastNotificationSlice';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: ExperimentSliceState = {
  loadingDetail: false,
  experiments: [],
  experimentDetail: undefined,
  fetchDetailErrorCode: null,
  isUpdatingExperiment: false,
};

const ExperimentSlice = createSlice({
  name: 'experiment',
  initialState,
  reducers: {
    changeLoadingDetail(state, action: PayloadAction<boolean>) {
      state.loadingDetail = action.payload;
    },
    updateExperiments(state, action: PayloadAction<BoundExperimentResponse[]>) {
      state.experiments = action.payload;
    },
    saveExperimentDetail(state, action: PayloadAction<ExperimentResponse>) {
      state.experimentDetail = action.payload;
    },
    errorFetchingDetail(state, action: PayloadAction<ErrorCode>) {
      state.fetchDetailErrorCode = action.payload;
    },
    saveIsUpdatingExperiment(state, action: PayloadAction<boolean>) {
      state.isUpdatingExperiment = action.payload;
    },
  },
});

export const {
  changeLoadingDetail,
  updateExperiments,
  saveExperimentDetail,
  errorFetchingDetail,
  saveIsUpdatingExperiment,
} = ExperimentSlice.actions;

export const fetchExperiments = (
  agentId: string,
): AppThunk<Promise<BoundExperimentResponse[] | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new UserControllerApi(
      await getYentaConfiguration(),
    ).getBoundExperiments(agentId);
    dispatch(updateExperiments(data.experiments || []));
    return data.experiments || [];
  } catch (e) {
    dispatch(updateExperiments([]));
    ErrorService.notifyIgnoreAuthErrors(
      'unable to fetch experiments for agent',
      e,
      {
        experiments: { agentId: agentId },
      },
    );
    return undefined;
  }
};

export const createExperiment = (
  createDefaultExperimentRequest: CreateDefaultExperimentRequest,
): AppThunk<Promise<ExperimentResponse | undefined>> => async (dispatch) => {
  dispatch(saveIsUpdatingExperiment(true));
  try {
    const { data } = await new ExperimentControllerApi(
      await getYentaConfiguration(),
    ).createDefaultExperiment(createDefaultExperimentRequest);
    dispatch(showSuccessToast('Experiment created successfully.'));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to create experiment',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notifyIgnoreAuthErrors(
      'unable to create experiment for feature flags',
      e,
      {
        experiments: { createDefaultExperimentRequest },
      },
    );
    return undefined;
  } finally {
    dispatch(saveIsUpdatingExperiment(false));
  }
};

export const fetchFeatureFlagsExperiments = (
  state: ExperimentResponseStateEnum,
  pageNumber: number,
  pageSize: number,
): AppThunk<Promise<PaginatedExperimentsResponse | undefined>> => async () => {
  try {
    const { data } = await new ExperimentControllerApi(
      await getYentaConfiguration(),
    ).getExperiments(state, pageNumber, pageSize);
    return data;
  } catch (e) {
    ErrorService.notifyIgnoreAuthErrors(
      'unable to fetch experiments for feature flags',
      e,
      {
        experiments: { state, pageNumber, pageSize },
      },
    );
    return undefined;
  }
};

export const updateExperimentGroup = (
  groupId: string,
  updateExperimentGroupRequest: UpdateExperimentGroupRequest,
  experimentId?: string,
): AppThunk<Promise<ExperimentGroupResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new ExperimentControllerApi(
      await getYentaConfiguration(),
    ).updateExperimentGroup(groupId, updateExperimentGroupRequest);
    if (!!experimentId) {
      dispatch(getExperimentById(experimentId));
    }
    dispatch(showSuccessToast('Experiment group updated successfully.'));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update experiment group',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notifyIgnoreAuthErrors(
      'unable to update experiment group for feature flags',
      e,
      {
        experiments: { groupId, updateExperimentGroupRequest },
      },
    );
    return undefined;
  }
};

export const activateExperiment = (
  experimentId: string,
): AppThunk<Promise<ExperimentResponse | undefined>> => async (dispatch) => {
  dispatch(saveIsUpdatingExperiment(true));

  try {
    const { data } = await new ExperimentControllerApi(
      await getYentaConfiguration(),
    ).activate(experimentId);
    dispatch(showSuccessToast('Successfully activated experiment'));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to activate experiment',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notifyIgnoreAuthErrors(
      'unable to activate experiment of feature flags',
      e,
      {
        experiments: { experimentId },
      },
    );
    return undefined;
  } finally {
    dispatch(saveIsUpdatingExperiment(false));
  }
};

export const archiveExperiment = (
  experimentId: string,
): AppThunk<Promise<ExperimentResponse | undefined>> => async (dispatch) => {
  dispatch(saveIsUpdatingExperiment(true));

  try {
    const { data } = await new ExperimentControllerApi(
      await getYentaConfiguration(),
    ).archiveExperiment(experimentId);
    dispatch(showSuccessToast('Successfully archived experiment'));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to archive experiment',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notifyIgnoreAuthErrors(
      'unable to archive experiment of feature flags',
      e,
      {
        experiments: { experimentId },
      },
    );
    return undefined;
  } finally {
    dispatch(saveIsUpdatingExperiment(false));
  }
};

export const getExperimentById = (experimentId: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingDetail(true));
  try {
    const { data } = await new ExperimentControllerApi(
      await getYentaConfiguration(),
    ).getExperimentById(experimentId);
    dispatch(saveExperimentDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'unable to fetch experiment of feature flags',
      e,
      {
        experiments: { experimentId },
      },
    );
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export const getExperimentByName = (
  experimentName: string,
): AppThunk<Promise<ExperimentResponse | undefined>> => async () => {
  try {
    const { data } = await new ExperimentControllerApi(
      await getYentaConfiguration(),
    ).getExperimentByName(experimentName);
    return data;
  } catch (e) {
    ErrorService.notifyIgnoreNotFound(
      'unable to archive experiment of feature flags',
      e,
      {
        experiments: { experimentName },
      },
    );
    return undefined;
  }
};

export default ExperimentSlice.reducer;
