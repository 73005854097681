import {
  faArrowUpRight,
  faPhoneXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { MentionBlock, VoiceCallBlock } from '../../../../openapi/yada';
import { cn } from '../../../../utils/classUtils';
import { isCallCancelled } from '../../../../utils/RoarUtils';
import { ZenAvatarProps } from '../../../Zen/ZenAvatar';
import ZenAvatarStack from '../../../Zen/ZenAvatarStack';
import VoiceCallStatusPill from './VoiceCallStatusPill';

const ParticipantLoader: React.FC = () => {
  return (
    <ContentLoader
      width='120px'
      viewBox='0 0 120 24'
      backgroundColor='#eaeced'
      foregroundColor='#ffffff'
    >
      <rect x='0' y='0' rx='3' ry='3' width='120' height='24' />
    </ContentLoader>
  );
};

type VoiceCallHeaderProps = {
  isLoading: boolean;
  agents: ZenAvatarProps[];
  recordingBlock: VoiceCallBlock;
  mentionBlock?: MentionBlock;
};

const VoiceCallHeader: React.FC<VoiceCallHeaderProps> = ({
  isLoading,
  agents,
  recordingBlock,
  mentionBlock,
}) => {
  const callCancelled = isCallCancelled(recordingBlock);

  return (
    <div className='flex items-center space-between'>
      <div className='flex-1 flex items-center space-x-2 ml-2'>
        <div className='flex-shrink-0'>
          <ZenAvatarStack size='xs' showRing avatars={agents} />
        </div>
        {isLoading ? (
          <ParticipantLoader />
        ) : (
          <div className='flex-1 flex items-center space-x-1.5'>
            <p className='flex-shrink break-all text-base text-regent-900 line-clamp-1'>
              {agents[0]?.name || 'Unknown'}
            </p>
            <FontAwesomeIcon
              icon={callCancelled ? faPhoneXmark : faArrowUpRight}
              className={cn(callCancelled ? 'text-red-600' : 'text-green-600')}
              fontSize={12}
            />
            <p className='flex-shrink text-base text-regent-900 line-clamp-1'>
              {agents[1]?.name || mentionBlock?.name || 'Unknown'}
            </p>
          </div>
        )}
      </div>
      <VoiceCallStatusPill recordingBlock={recordingBlock} />
    </div>
  );
};

export default VoiceCallHeader;
