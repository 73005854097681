import qs from 'qs';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StepByStepContainer, {
  StepConfig,
} from '../../../components/StepByStep/StepByStepContainer';
import TransactionSetupAttachedFeeAdditionalCommissionStep from '../../../components/transactions/setup/TransactionSetupAttachedFeeAdditionalCommissionStep';
import TransactionSetupAttachedFeeTransactionCoordinatorStep from '../../../components/transactions/setup/TransactionSetupAttachedFeeTransactionCoordinatorStep';
import TransactionSetupCommissionSplitsStep from '../../../components/transactions/setup/TransactionSetupCommissionSplitsStep';
import TransactionSetupPersonalDealStep from '../../../components/transactions/setup/TransactionSetupPersonalDealStep';
import TransactionSetupReferrerStep from '../../../components/transactions/setup/TransactionSetupReferrerStep';
import TransactionSetupReview from '../../../components/transactions/setup/TransactionSetupReview';
import TransactionSetupWelcome from '../../../components/transactions/setup/TransactionSetupWelcome';
import {
  AttachedFeeValueFeeTypeEnum,
  FeaturesResponseEligibleEnum,
  MoneyValue,
  PaymentParticipantValueRoleEnum,
  PercentageValue,
  TransactionControllerApi,
  TransactionResponse,
  UpdateParticipantRequest,
  UpdateParticipantRequestParticipantRoleEnum,
} from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { showErrorToastForErrorCode } from '../../../slices/ToastNotificationSlice';
import { RootState, YesNoType } from '../../../types';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import {
  getAllTransactionParticipantsByRole,
  getTransactionAttachedFeeByType,
  getTransactionOwner,
  getTransactionParticipantByEmail,
} from '../../../utils/TransactionHelper';
import { parseMoney } from '../../../utils/CurrencyUtils';
import { saveTransactionDetailResponse } from '../../../slices/TransactionSlice';
import TransactionSetupRealTitle from '../../../components/transactions/setup/TransactionSetupRealTitle';

interface TransactionSetupContainerProps {
  transaction: TransactionResponse;
}

export enum TransactionSetupStepName {
  WELCOME = 'WELCOME',
  REFERRER = 'REFERRER',
  ATTACHED_FEE_TRANSACTION_COORDINATOR = 'ATTACHED_FEE_TRANSACTION_COORDINATOR',
  ATTACHED_FEE_ADDITIONAL_COMMISSION = 'ATTACHED_FEE_ADDITIONAL_COMMISSION',
  PERONSAL_DEAL = 'PERONSAL_DEAL',
  COMMISSION_SPLITS = 'COMMISSION_SPLITS',
  REAL_TITLE = 'REAL_TITLE',
  REVIEW = 'REVIEW',
}

export interface TransactionSetupFormProps {
  referral: YesNoType;
  personalDeal: YesNoType;
  payingTransactionCoordinator: YesNoType;
  chargingClients: YesNoType;
  commissionParticipant: {
    percent?: PercentageValue;
    money?: MoneyValue;
    isDollar: boolean;
    id: string;
  }[];
  usingTitle: YesNoType;
}

const TransactionSetupContainer: React.FC<TransactionSetupContainerProps> = ({
  transaction,
}) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const {
    auth: { userDetail },
    transaction: { features },
  } = useSelector((state: RootState) => state);

  const transactionOwner = getTransactionOwner(transaction);

  const isTransactionEligibleForRealTitle = !!features?.eligible?.find(
    (feature) => feature === FeaturesResponseEligibleEnum.Title,
  );

  const isPersonalDeal =
    transactionOwner &&
    'personalDeal' in transactionOwner &&
    transactionOwner.personalDeal;

  const steps: StepConfig<
    TransactionSetupFormProps,
    TransactionSetupStepName
  >[] = useMemo(
    () => [
      {
        name: TransactionSetupStepName.WELCOME,
        Component: TransactionSetupWelcome,
        hidePagination: true,
      },
      {
        name: TransactionSetupStepName.REFERRER,
        Component: TransactionSetupReferrerStep,
      },
      {
        name: TransactionSetupStepName.PERONSAL_DEAL,
        Component: TransactionSetupPersonalDealStep,
        showStepIf: () => !!isPersonalDeal,
      },
      {
        name: TransactionSetupStepName.ATTACHED_FEE_TRANSACTION_COORDINATOR,
        Component: TransactionSetupAttachedFeeTransactionCoordinatorStep,
      },
      {
        name: TransactionSetupStepName.ATTACHED_FEE_ADDITIONAL_COMMISSION,
        Component: TransactionSetupAttachedFeeAdditionalCommissionStep,
      },
      {
        name: TransactionSetupStepName.COMMISSION_SPLITS,
        Component: TransactionSetupCommissionSplitsStep,
        hidePagination: true,
      },
      {
        name: TransactionSetupStepName.REAL_TITLE,
        Component: TransactionSetupRealTitle,
        showStepIf: () => isTransactionEligibleForRealTitle,
        hidePagination: true,
      },
      {
        name: TransactionSetupStepName.REVIEW,
        Component: TransactionSetupReview,
        hidePagination: true,
      },
    ],
    [isPersonalDeal, isTransactionEligibleForRealTitle],
  );

  const allReferralParticipants = getAllTransactionParticipantsByRole(
    transaction,
    PaymentParticipantValueRoleEnum.ReferringAgent,
  );

  const payingTransactionCoordinatorAttachedFees = getTransactionAttachedFeeByType(
    transaction,
    AttachedFeeValueFeeTypeEnum.TransactionCoordinator,
  );

  const chargingClientsAdditionalAttachedFees = getTransactionAttachedFeeByType(
    transaction,
    AttachedFeeValueFeeTypeEnum.AdditionalCommission,
  );

  const defaultValues: TransactionSetupFormProps = useMemo(
    () => ({
      referral: allReferralParticipants?.length ? YesNoType.YES : YesNoType.NO,
      payingTransactionCoordinator: payingTransactionCoordinatorAttachedFees?.length
        ? YesNoType.YES
        : YesNoType.NO,
      chargingClients: chargingClientsAdditionalAttachedFees?.length
        ? YesNoType.YES
        : YesNoType.NO,
      personalDeal: isPersonalDeal ? YesNoType.YES : YesNoType.NO,
      commissionParticipant:
        transaction.possiblePayableParticipants?.map((participant) => {
          return {
            percent: {
              value: participant.payment?.percent
                ? parseFloat((participant.payment?.percent! * 100).toFixed(2))
                : 0,
              string: `${((participant.payment?.percent || 0)! * 100).toFixed(
                2,
              )}%`,
            },
            money: {
              amount: parseMoney(participant.payment?.amount?.amount! || 0),
              currency: participant.payment?.amount?.currency,
            },

            isDollar: !!participant.payment?.amount,
            id: participant.id!,
          };
        }) || [],
      usingTitle: YesNoType.YES,
    }),
    [
      allReferralParticipants?.length,
      chargingClientsAdditionalAttachedFees?.length,
      isPersonalDeal,
      payingTransactionCoordinatorAttachedFees?.length,
      transaction.possiblePayableParticipants,
    ],
  );

  const transactionOnboardingComplete = async () => {
    try {
      const { data } = await new TransactionControllerApi(
        await getArrakisConfiguration(),
      ).updateTransactionOnboarded(transaction.id!, {
        onboarded: true,
      });

      dispatch(
        saveTransactionDetailResponse({
          name: 'transactionDetail',
          loading: false,
          data,
        }),
      );
    } catch (e) {
      dispatch(
        showErrorToastForErrorCode(
          'Unable to complete transaction onboarding',
          ErrorService.getErrorCode(e),
        ),
      );
      ErrorService.notify('unable to complete transaction onboarding', e, {
        transaction: { id: transaction.id, onboarded: true },
      });
    }
  };

  const onSubmit = async (values: TransactionSetupFormProps) => {
    const confirmUsingRealTitle =
      isTransactionEligibleForRealTitle && values.usingTitle === YesNoType.YES;

    if (confirmUsingRealTitle) {
      try {
        await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).enableTitleSystemUser(transaction.id!);
      } catch (e) {
        dispatch(
          showErrorToastForErrorCode(
            'We encountered an error while sending this transaction to Real Title. Please try again in a few moments.',
            ErrorService.getErrorCode(e),
          ),
        );
        ErrorService.notify('Unable to Send to Real Title.', e, {
          transaction: { transaction },
        });
      }
    }

    const participant = getTransactionParticipantByEmail(
      transaction,
      userDetail?.emailAddress!,
    );

    if (participant) {
      const updateData: UpdateParticipantRequest = {
        emailAddress: participant.emailAddress,
        firstName: participant.firstName,
        lastName: participant.lastName,
        phoneNumber: participant.phoneNumber,
        commissionDocumentRecipient: !!participant.commissionDocumentRecipient,
        payer: !!participant.commissionDocumentPayer,
        passThrough: !!participant.passThrough,
        paidByReal: !!participant.paidByReal,
        participantRole: (participant.participantRole! as unknown) as UpdateParticipantRequestParticipantRoleEnum,
        address: participant.address,
        paidViaBusinessEntity: participant?.paidViaBusinessEntity,
        personalDeal: values.personalDeal === YesNoType.YES,
      };

      try {
        await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).updateParticipant(transaction.id!, participant.id!, updateData);
      } catch (e) {
        ErrorService.notifyIgnoreHandled('Error updating the participant', e, {
          transaction: { id: transaction.id },
          participant: { yentaId: participant.id, values },
        });
        dispatch(
          showErrorToastForErrorCode(
            'We were unable to update the participant',
            ErrorService.getErrorCode(e),
          ),
        );
      }
    }

    await transactionOnboardingComplete();

    history.replace(
      `/transactions/${transaction.id}?${qs.stringify({
        showStatusOnboardings: true,
      })}`,
    );
  };

  return (
    <StepByStepContainer<TransactionSetupFormProps, TransactionSetupStepName>
      steps={steps}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      mode='onChange'
      reValidateMode='onChange'
    />
  );
};

export default TransactionSetupContainer;
