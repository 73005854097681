import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import DefaultLoader from '../components/DefaultLoader';
import ZenRoute from '../components/Zen/ZenRoute';
import {
  ConveyanceControllerApi,
  ConveyanceResponse,
} from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { getConveyanceDocumentHtml } from '../utils/ConveyanceDocumentHelper';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';

type Match = {
  conveyanceDocumentId: string;
};

const ConveyanceDocumentsPdfRoute: React.FC = () => {
  const { conveyanceDocumentId } = useParams() as Match;
  const dispatch = useDispatch();
  const [
    conveyanceDocument,
    setConveyanceDocument,
  ] = useState<ConveyanceResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchConveyanceDocument = async () => {
      setLoading(true);
      try {
        const { data } = await new ConveyanceControllerApi(
          getArrakisConfiguration(),
        ).getById(conveyanceDocumentId);
        setConveyanceDocument(data);
      } catch (e) {
        dispatch(showApiErrorModal(e));
        dispatch(
          showErrorToast(
            'We had a problem fetching conveyance document',
            'Please try again in a few moments.',
          ),
        );
        ErrorService.notify('Error fetching conveyance document', e, {
          conveyanceId: { conveyanceDocumentId },
        });
      } finally {
        setLoading(false);
      }
    };

    fetchConveyanceDocument();
  }, [conveyanceDocumentId, dispatch]);

  const Print = styled.div`
    width: 1024px;
    margin: 0 auto;
    padding: 20px 60px;

    @media print {
      @page {
        size: A4;
        margin: 20pt 0 35pt;
      }
    }
  `;

  if (loading) {
    return <DefaultLoader />;
  }

  return (
    <ZenRoute title='Convenience Document PDF'>
      <Print>
        <div className='w-full h-full'>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: getConveyanceDocumentHtml(conveyanceDocument),
            }}
          />
        </div>
      </Print>
    </ZenRoute>
  );
};

export default ConveyanceDocumentsPdfRoute;
