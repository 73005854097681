import { z } from 'zod';

export const PASSWORD_VALIDATIONS = z
  .string({ message: 'Password is required' })
  .trim()
  .min(8, 'Password must have a minimum of 8 characters')
  .max(24, 'Password must have a maximum of 24 characters')
  .regex(RegExp(/^[^\s]*$/), 'Password cannot contain any spaces')
  .regex(
    RegExp(/[a-z]/),
    'Password must contain at least 1 lower-case character',
  )
  .regex(
    RegExp(/[A-Z]/),
    'Password must contain at least 1 upper-case character',
  )
  .regex(RegExp(/[0-9]/), 'Password must contain at least 1 digit')
  .regex(
    RegExp(/[!@#$%*()"';:,<>|\\/=\-_^&]/),
    'Password must contain at least 1 symbol',
  );
