import { uniq } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ChecklistResponse } from '../openapi/sherlock';
import { fetchChecklistItemCommentsCount } from '../slices/CheckListSlice';

const useChecklistItemCommentsCount = (
  checklistItem: ChecklistResponse | undefined,
) => {
  const dispatch = useDispatch();

  const checklistItemsIds = useMemo(
    () => checklistItem?.items?.map((item) => `CI#${item?.id!}`),
    [checklistItem?.items],
  );

  useEffect(() => {
    if (uniq(checklistItemsIds)?.length) {
      dispatch(fetchChecklistItemCommentsCount(uniq(checklistItemsIds)));
    }
  }, [checklistItemsIds, dispatch]);
};

export default useChecklistItemCommentsCount;
