import { values } from 'lodash';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
  AdministrativeAreaResponseCountryEnum,
  MlsRequest,
  MlsRequestStatusEnum,
  MlsResponse,
  MlsResponseStatusEnum,
} from '../../../openapi/yenta';
import { createMls, updateMls } from '../../../slices/MLSSlice';
import { AppDispatch, RootState } from '../../../types';
import { defaultMoneyValue } from '../../../utils/CurrencyUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import {
  EMAIL_VALIDATIONS,
  MONEY_VALUE_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../utils/Validations';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledEmailInput from '../../Zen/Input/ZenControlledEmailInput';
import ZenControlledHTMLSelectInput from '../../Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledMoneyInput from '../../Zen/Input/ZenControlledMoneyInput';
import ZenControlledPhoneNumberInput from '../../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledStateOrProvinceInput from '../../Zen/Input/ZenControlledStateOrProvince';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../../Zen/ZenSidebarModalForm';

interface FormData extends Omit<MlsRequest, 'administrativeAreas'> {
  administrativeAreas?: {
    country: AdministrativeAreaRequestCountryEnum;
    stateOrProvince: AdministrativeAreaRequestStateOrProvinceEnum;
  };
}

interface ZenCreateMLSFormProps {
  isOpen: boolean;
  mlsDetails?: MlsResponse;
  onClose(): void;
}

const ZenCreateMLSForm: React.FC<ZenCreateMLSFormProps> = ({
  isOpen,
  onClose,
  mlsDetails,
}) => {
  const history = useHistory();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  let defaultAdministrativeArea = {
    country: (!!mlsDetails?.administrativeAreas?.length
      ? mlsDetails?.administrativeAreas[0]?.country
      : '') as AdministrativeAreaRequestCountryEnum,
    stateOrProvince: (!!mlsDetails?.administrativeAreas?.length
      ? mlsDetails?.administrativeAreas[0]?.stateOrProvince
      : '') as AdministrativeAreaRequestStateOrProvinceEnum,
  };

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      code: mlsDetails?.code,
      joinDate: mlsDetails?.joinDate,
      email: mlsDetails?.email,
      name: mlsDetails?.name,
      phoneNumber: mlsDetails?.phoneNumber,
      registrationFee:
        mlsDetails?.registrationFee ||
        defaultMoneyValue(userDetail?.defaultCurrency!),
      administrativeAreas: defaultAdministrativeArea,
      status: (mlsDetails?.status || '') as MlsRequestStatusEnum,
    },
  });
  const selectedCountry = watch('administrativeAreas.country');
  const dispatch: AppDispatch = useDispatch();

  const onSubmit = async (data: FormData) => {
    const finalData: MlsRequest = {
      ...data,
      administrativeAreas: [
        {
          country: data.administrativeAreas?.country!,
          stateOrProvince: data.administrativeAreas?.stateOrProvince!,
        },
      ],
    };

    if (mlsDetails) {
      await dispatch(updateMls(mlsDetails.id!, finalData));
      onClose();
    } else {
      const mlsId = await dispatch(createMls(finalData));
      if (mlsId) {
        history.push(`/mls/${mlsId}`);
      }
    }
  };

  return (
    <ZenSidebarModalForm
      title={mlsDetails ? 'Edit MLS Details' : 'Add New MLS'}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionTitle={mlsDetails ? 'Update' : 'Create'}
      isSubmitting={isSubmitting}
    >
      <div className='pr-2'>
        <div>
          <ZenControlledTextInput<FormData, 'name'>
            control={control}
            shouldUnregister={!mlsDetails}
            label='MLS Name'
            name='name'
            placeholder='Enter MLS Name'
            rules={{ required: 'Please provide a MLS name' }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledTextInput<FormData, 'code'>
            control={control}
            shouldUnregister={!mlsDetails}
            label='MLS Code'
            name='code'
            placeholder='Enter MLS Code'
            rules={{
              required: 'Please provide a MLS code',
            }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledMoneyInput<FormData, 'registrationFee'>
            control={control}
            shouldUnregister={!mlsDetails}
            label='Registration Fee'
            name='registrationFee'
            placeholder='Enter registration fee'
            rules={MONEY_VALUE_VALIDATIONS}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledDatePickerInput<FormData, 'joinDate'>
            name='joinDate'
            control={control}
            shouldUnregister={!mlsDetails}
            label='Join Date'
            placeholder='Join Date'
            rules={{
              required: 'Please provide a join date',
            }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledPhoneNumberInput<FormData, 'phoneNumber'>
            control={control}
            shouldUnregister={!mlsDetails}
            label='Phone Number'
            name='phoneNumber'
            placeholder='+1 (702) 123-4567'
            rules={{
              required: 'Please provide a phone number',
              ...PHONE_NUMBER_VALIDATIONS,
            }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledEmailInput<FormData, 'email'>
            control={control}
            shouldUnregister={!mlsDetails}
            label='Email Address'
            name='email'
            placeholder='Email Address'
            rules={{
              required: 'Please provide an email address',
              ...EMAIL_VALIDATIONS,
            }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledHTMLSelectInput<FormData, 'administrativeAreas.country'>
            name='administrativeAreas.country'
            control={control}
            shouldUnregister={!mlsDetails}
            label='Country'
            placeholder='Country'
            options={[
              {
                label: 'Select Country',
                value: '',
                disabled: true,
              },
              ...values(AdministrativeAreaResponseCountryEnum).map(
                (country) => ({
                  value: country,
                  label: capitalizeEnum(country),
                }),
              ),
            ]}
            rules={{
              required: 'Please select country',
            }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledStateOrProvinceInput<
            FormData,
            'administrativeAreas.stateOrProvince'
          >
            selectedCountry={
              (selectedCountry as unknown) as AdministrativeAreaRequestCountryEnum
            }
            name='administrativeAreas.stateOrProvince'
            control={control}
            shouldUnregister={!mlsDetails}
            rules={{
              required: 'Please select state or province.',
            }}
            setValue={setValue}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledHTMLSelectInput<FormData, 'status'>
            name='status'
            control={control}
            shouldUnregister={!mlsDetails}
            label='Status'
            placeholder='Status'
            options={[
              { label: 'Select Status', value: '', disabled: true },
              ...values(MlsResponseStatusEnum).map((state) => ({
                value: state,
                label: capitalizeEnum(state),
              })),
            ]}
            rules={{
              required: 'Please select MLS status',
            }}
            isRequired
          />
        </div>
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenCreateMLSForm;
