import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Address,
  CreateAndDisburseReferralRequest,
  CreateAndDisburseReferralRequestExternalPaymentMethodEnum,
  MoneyValue,
} from '../../../../openapi/arrakis';
import { createReferralAndDisburse } from '../../../../slices/AgentSlice';
import { AppDispatch, ISelectOption, RootState } from '../../../../types';
import {
  getCreateReferralTransactionDefaultFormValues,
  isReferralTransactionAddressRequired,
} from '../../../../utils/ReferralTransactionHelper';
import { isStringPresent } from '../../../../utils/StringUtils';
import StepByStepContainer, {
  StepConfig,
} from '../../../StepByStep/StepByStepContainer';
import { GooglePlaceLocationType } from '../../Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenAgentOnboardingLayout from '../../ZenAgentOnboardingLayout';
import ZenRoute from '../../ZenRoute';
import ZenAddressInformationStep from './ZenAddressInformationStep';
import ZenClientInformationStep from './ZenClientInformationStep';
import ZenDealInformationStep from './ZenDealInformationStep';
import ZenExternalAgentInformation from './ZenExternalAgentInformation';
import ZenOwnerStep from './ZenOwnerStep';
import ZenPaymentInformationStep from './ZenPaymentInformationStep';
import ZenReferralTransactionReview from './ZenReferralTransactionReview';

export enum ReferralTransactionStepName {
  EXTERNAL_AGENT_INFORMATION_STEP = 'EXTERNAL_AGENT_INFORMATION_STEP',
  CLIENT_INFORMATION = 'CLIENT_INFORMATION',
  DEAL_INFORMATION = 'DEAL_INFORMATION',
  OWNER_STEP = 'OWNER_STEP',
  ADDRESS = 'ADDRESS',
  PAYMENT_INFORMATION = 'PAYMENT_INFORMATION',
  STEP_REVIEW = 'STEP_REVIEW',
}

export interface ReferralTransactionFormState {
  //STEP ONE
  externalAgentFirstName: string;
  externalAgentLastName: string;
  externalAgentEmailAddress: string;
  externalAgentBrokerage: string;
  externalEin?: string;
  externalAgentPhoneNumber?: string;

  //STEP TWO
  clientFirstName: string;
  clientLastName: string;
  clientEmailAddress?: string;
  location?: GooglePlaceLocationType;
  name?: string;
  address1?: string;
  clientPhoneNumber?: string;

  //STEP THREE
  price: MoneyValue;
  expectedClosingDate: Date;

  // STEP FOUR
  isTeamLeadOrAdmin: boolean;
  transactionOwnerName?: ISelectOption;

  //STEP FIVE
  address?: Address;

  //STEP SIX
  paymentDateSent?: Date;
  paymentMethod?: CreateAndDisburseReferralRequestExternalPaymentMethodEnum;
  referenceNumber?: string;
  senderFullName?: string;
  comments?: string;
}

export const steps: StepConfig<
  ReferralTransactionFormState,
  ReferralTransactionStepName
>[] = [
  {
    name: ReferralTransactionStepName.EXTERNAL_AGENT_INFORMATION_STEP,
    Component: ZenExternalAgentInformation,
    hidePagination: true,
  },
  {
    name: ReferralTransactionStepName.CLIENT_INFORMATION,
    Component: ZenClientInformationStep,
    hidePagination: true,
  },
  {
    name: ReferralTransactionStepName.DEAL_INFORMATION,
    Component: ZenDealInformationStep,
    hidePagination: true,
  },
  {
    name: ReferralTransactionStepName.OWNER_STEP,
    Component: ZenOwnerStep,
    hidePagination: true,
    showStepIf: ({ isTeamLeadOrAdmin }) => isTeamLeadOrAdmin,
  },
  {
    name: ReferralTransactionStepName.ADDRESS,
    Component: ZenAddressInformationStep,
    hidePagination: true,
  },
  {
    name: ReferralTransactionStepName.PAYMENT_INFORMATION,
    Component: ZenPaymentInformationStep,
    hidePagination: true,
  },
  {
    name: ReferralTransactionStepName.STEP_REVIEW,
    Component: ZenReferralTransactionReview,
    hidePagination: true,
  },
];

const ZenReferralTransactionSteps: React.FC = () => {
  const history = useHistory();
  const { userDetail, isAdmin } = useSelector((state: RootState) => state.auth);
  const dispatch: AppDispatch = useDispatch();
  const defaultValues = getCreateReferralTransactionDefaultFormValues(
    userDetail,
    isAdmin,
  );

  const onSubmit = async (values: ReferralTransactionFormState) => {
    const isAddressRequired = isReferralTransactionAddressRequired(
      userDetail!,
      values?.address!,
    );

    const finalData: CreateAndDisburseReferralRequest = {
      expectedCloseDate: (values?.expectedClosingDate as unknown) as string,
      clientEmail: values?.clientEmailAddress,
      clientName: `${values?.clientFirstName} ${values?.clientLastName}`,
      comments: values?.comments,
      externalAgentBrokerage: values?.externalAgentBrokerage,
      externalAgentEmail: values?.externalAgentEmailAddress,
      referredPropertyAddress: isAddressRequired ? values.address : undefined,
      externalAgentName: `${values?.externalAgentFirstName} ${values?.externalAgentLastName}`,
      externalPaymentDateSent: (values?.paymentDateSent as unknown) as string,
      externalPaymentMethod: isStringPresent(values?.paymentMethod)
        ? values.paymentMethod
        : undefined,
      externalReferenceNumber: values?.referenceNumber,
      externalSenderName: values?.senderFullName,
      expectedReferralAmount: {
        amount: +values?.price?.amount!,
        currency: values?.price?.currency,
      },
      transactionOwnerAgentId: values.transactionOwnerName?.value,
    };

    const res = await dispatch(
      createReferralAndDisburse(userDetail?.id!, finalData),
    );

    if (res) {
      history.push(`/transactions/${res.transaction?.id}`);
    }
  };

  return (
    <ZenRoute title='Referral Transaction'>
      <ZenAgentOnboardingLayout
        title='New Referral Transaction'
        onClose={() => history.push('/transactions')}
        modalTitle='Cancel referral transaction?'
        modalSubtitle='The information won’t be saved and you’ll go back to the the transaction list'
        modalSubmitText='Cancel'
        hideLogout
      >
        <StepByStepContainer<
          ReferralTransactionFormState,
          ReferralTransactionStepName
        >
          steps={steps}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          reValidateMode='onChange'
        />
      </ZenAgentOnboardingLayout>
    </ZenRoute>
  );
};

export default ZenReferralTransactionSteps;
