import { useState } from 'react';
import {
  TransactionSetupFormProps,
  TransactionSetupStepName,
} from '../../../containers/transaction/setup/TransactionSetupContainer';
import { YesNoType } from '../../../types';
import Button from '../../Button';
import ControlledRadioInputV7 from '../../ControlledRadioInputV7';
import SimpleConfirmationModal from '../../SimpleConfirmationModal';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import RealTitleInformation from '../CreateTransaction/SidebarForms/RealTitleInformation';

const TransactionSetupRealTitle: StepByStepComponent<
  TransactionSetupFormProps,
  TransactionSetupStepName
> = ({ form: { control, watch }, onPrevious, onNext }) => {
  const [openSidebarModal, setOpenSidebarModal] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(
    false,
  );
  const hasRealTitle = watch('usingTitle');

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <p className='text-xl font-primary-medium text-dark mb-4'>
          Would you prefer to use&nbsp;
          <button
            onClick={() => setOpenSidebarModal(true)}
            className='font-primary-medium text-xl underline text-primary cursor-pointer'
          >
            Real Title
          </button>
          &nbsp;for this transaction?
        </p>

        <ControlledRadioInputV7<TransactionSetupFormProps, 'usingTitle'>
          name='usingTitle'
          control={control}
          inlineOptions
          shouldUnregister={false}
          options={[
            {
              label: 'Yes',
              value: YesNoType.YES,
            },
            {
              label: 'No',
              value: YesNoType.NO,
            },
          ]}
        />

        {openSidebarModal && (
          <RealTitleInformation
            isOpen={openSidebarModal}
            onClose={() => setOpenSidebarModal(false)}
          />
        )}
      </div>
      <div className='absolute bottom-0 w-full z-0 bg-white'>
        <div className='w-full mx-auto max-w-6xl'>
          <div className='w-full flex items-center justify-between py-6 md:py-8 shadow-top-sm'>
            <Button
              fullWidth
              size='lg'
              type='outline'
              className='w-1/3 justify-center text-opacity-50'
              label='Previous'
              onClick={onPrevious}
            />
            <Button
              fullWidth
              className='w-1/3 justify-center bg-primary md:col-span-1 offset'
              size='lg'
              label='Review'
              onClick={() => {
                if (hasRealTitle === YesNoType.YES) {
                  setOpenConfirmationModal(true);
                } else {
                  onNext();
                }
              }}
            />
          </div>
        </div>
      </div>
      <SimpleConfirmationModal
        isOpen={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        isSubmitting={false}
        onConfirm={() => {
          setOpenConfirmationModal(false);
          onNext();
        }}
        variant='warning'
        title='Action requires confirmation'
        subtitle='By continuing you are declaring that Real Title is the closing agent on this transaction.'
      />
    </div>
  );
};

export default TransactionSetupRealTitle;
