import { useState } from 'react';
import ZenSupportComponent from '../components/support/zen/ZenSupportComponent';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenPasswordGuard from './ZenPasswordGuard';

export const SUPPORT_PASSWORD = 'evolution';

interface SupportIndexRouteProps {}

const SupportIndexRoute: React.FC<SupportIndexRouteProps> = () => {
  const [authenticated, setAuthenticated] = useState(false);
  return (
    <ZenRoute title='Support'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Support', url: '/support' },
        ]}
      >
        {!authenticated ? (
          <ZenPasswordGuard
            password={SUPPORT_PASSWORD}
            setAuthenticated={setAuthenticated}
          />
        ) : (
          <ZenSupportComponent />
        )}
      </ZenPageLayout>
    </ZenRoute>
  );
};

export default SupportIndexRoute;
