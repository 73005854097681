import { faAddressBook, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DirectoryEntryResponseTypeEnum,
  DirectoryVendorResponseRoleEnum,
} from '../../../../openapi/yenta';
import { DirectoryCommonEntityResponse } from '../../../../query/directory/useDirectory';
import { RootState } from '../../../../types';
import { EMAIL_VALIDATIONS } from '../../../../utils/Validations';
import ZenRoleDirectoryForm from '../../../Directory/ZenRoleDirectoryForm';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenContactListSidebar from '../../../Directory/ZenContactListSidebar';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenAgentWebsiteConfirmationModal from '../../Modal/ZenAgentWebsiteConfirmationModal';
import ZenButton from '../../ZenButton';
import ZenStickyBottomPagination from '../../ZenStickyBottomPagination';
import { FieldArrayPrefix } from '../CreateTransaction/ZenTransactionBuyerAndSellerStep';
import { ExternalAgentConfirmationModal } from '../../../Directory/ExternalAgentConfirmationModal';
import { companyVerify } from '../../../Directory/DirectoryUtils';
import { extractNationalBusinessIds } from '../../../Directory/utils';
import withZenCreateReferralTransactionProgress from './withZenCreateReferralTransactionProgress';
import {
  ReferralTransactionFormState,
  ReferralTransactionStepName,
} from './ZenReferralTransactionSteps';

const ZenExternalAgentInformationStep: StepByStepComponent<
  ReferralTransactionFormState,
  ReferralTransactionStepName
> = ({ form: { control, setValue, getValues }, onNext }) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [cancel, setCancel] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [roleSibebarOpen, setRoleSidebarOpen] = useState<boolean>(false);
  const [currentFormContact, setCurrentFormContact] = useState<
    DirectoryCommonEntityResponse | undefined
  >();
  const [pendingSaveData, setPendingSaveData] = useState<
    DirectoryCommonEntityResponse | undefined
  >();
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const history = useHistory();

  const handleSidebarToggle = () => () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const importFromDirectory = async (
    contact: DirectoryCommonEntityResponse,
  ) => {
    const vendorName =
      contact.type === DirectoryEntryResponseTypeEnum.Person
        ? contact.linkedName
        : contact.name;

    const { nationalId } = extractNationalBusinessIds(
      contact?.nationalBusinessIdentifications,
    );

    setValue('externalAgentFirstName', contact?.firstName || '');
    setValue('externalAgentLastName', contact?.lastName || '');
    setValue('clientEmailAddress', contact?.emailAddress || '');
    setValue('externalAgentEmailAddress', contact?.emailAddress || '');
    setValue('externalAgentPhoneNumber', contact?.phoneNumber || '');
    setValue('externalAgentBrokerage', vendorName || '');
    setValue('externalEin', nationalId);
  };

  const handleSave = async (
    _fieldArrayPrefix?: FieldArrayPrefix,
    contact?: DirectoryCommonEntityResponse,
  ) => {
    if (
      contact?.role === DirectoryVendorResponseRoleEnum.OtherAgent &&
      contact?.nationalBusinessIdentifications &&
      contact?.nationalBusinessIdentifications?.length > 0
    ) {
      setPendingSaveData(contact);
      setConfirmationModalOpen(true);
      return;
    }

    if (contact) {
      setPendingSaveData(contact);
      await importFromDirectory(contact);
    }
  };

  const completeSaveOperation = async () => {
    if (pendingSaveData) {
      await importFromDirectory(pendingSaveData);
    }
    setPendingSaveData(undefined);
    setConfirmationModalOpen(false);
  };

  const handleRoleSidebarToggle = () => async () => {
    const {
      externalAgentFirstName,
      externalAgentLastName,
      externalAgentEmailAddress,
      externalAgentPhoneNumber,
      name,
    } = getValues();
    setCurrentFormContact({
      role: DirectoryVendorResponseRoleEnum.OtherAgent,
      firstName: externalAgentFirstName,
      lastName: externalAgentLastName,
      emailAddress: externalAgentEmailAddress,
      name: name,
      phoneNumber: externalAgentPhoneNumber,
    });
    setRoleSidebarOpen((prevState) => !prevState);
  };

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div>
          <p className='text-xl font-zen-body font-semibold text-zen-dark-9 mb-4'>
            Hi, {userDetail?.fullName} 👋
          </p>
          <p className='mb-4 font-zen-body text-base text-zen-dark-9'>
            We’ll guide you through a few steps to create a new referral. Once
            created you can add and update any information later.
          </p>
          <div className='flex flex-row items-center justify-between'>
            <p className='text-xl font-zen-body font-semibold text-zen-dark-9 mb-4'>
              External Agent Information
            </p>
            <div className='-mt-4'>
              <ZenButton
                label='Directory'
                variant='primary-link'
                LeftIconComponent={<FontAwesomeIcon icon={faAddressBook} />}
                onClick={handleSidebarToggle()}
                zeroPadding
                fontstyle
              />
            </div>
          </div>

          <div className='space-y-4'>
            <div>
              <ZenControlledTextInput<
                ReferralTransactionFormState,
                'externalAgentFirstName'
              >
                control={control}
                label='First Name'
                name='externalAgentFirstName'
                shouldUnregister={false}
                placeholder='E.g. Andrew'
                rules={{
                  required: 'First Name is Required',
                }}
                isRequired
              />
            </div>

            <div>
              <ZenControlledTextInput<
                ReferralTransactionFormState,
                'externalAgentLastName'
              >
                control={control}
                label='Last Name'
                name='externalAgentLastName'
                shouldUnregister={false}
                placeholder='E.g. Garfield.'
                rules={{
                  required: 'Last Name is Required',
                }}
                isRequired
              />
            </div>

            <div>
              <ZenControlledTextInput<
                ReferralTransactionFormState,
                'externalAgentEmailAddress'
              >
                control={control}
                label='Email Address'
                name='externalAgentEmailAddress'
                shouldUnregister={false}
                placeholder='E.g. garfield@example.com'
                rules={{
                  required: 'Please provide an email',
                  ...EMAIL_VALIDATIONS,
                }}
                isRequired
              />
            </div>

            <div>
              <ZenControlledTextInput<
                ReferralTransactionFormState,
                'externalAgentBrokerage'
              >
                control={control}
                label='External Agent Brokerage'
                name='externalAgentBrokerage'
                shouldUnregister={false}
                placeholder='E.g. Souls Inc.'
                rules={{
                  required: 'External Agent Brokerage is Required',
                }}
                isRequired
              />
            </div>
            <div className='flex items-end justify-end -mr-3'>
              <ZenButton
                label='Add to My Directory'
                variant='primary-link'
                LeftIconComponent={
                  <FontAwesomeIcon
                    icon={faPlus}
                    className='text-primary-blue cursor-pointer'
                  />
                }
                onClick={handleRoleSidebarToggle()}
                fontstyle
              />
            </div>
          </div>
        </div>
      </div>

      <ZenStickyBottomPagination
        maxWidth='2xl'
        previousButtonText='Cancel'
        onNext={onNext}
        onPrevious={() => setCancel(true)}
      />

      <ZenAgentWebsiteConfirmationModal
        variant='cancel'
        title='Cancel referral transaction?'
        subtitle='The information won’t be saved and you’ll go back to the the transaction list'
        isOpen={cancel}
        onClose={() => setCancel(false)}
      >
        <div className='flex flex-row justify-between space-x-5 mt-6'>
          <ZenButton
            label='No'
            variant='secondary-gray-outline'
            type='button'
            isFullWidth
            onClick={() => setCancel(false)}
          />

          <ZenButton
            label='Cancel'
            variant='danger'
            isFullWidth
            onClick={() => history.push('/transactions')}
          />
        </div>
      </ZenAgentWebsiteConfirmationModal>
      {isSidebarOpen && (
        <ZenContactListSidebar
          currentFieldArrayPrefixNotRequired
          isOpen={isSidebarOpen}
          onClose={handleSidebarToggle()}
          onSave={handleSave}
          isExternalAgent
        />
      )}
      {roleSibebarOpen && (
        <ZenRoleDirectoryForm
          isOpen={roleSibebarOpen}
          existingVendorOrPerson={currentFormContact}
          mode='populate'
          onClose={handleRoleSidebarToggle()}
          isExternal
        />
      )}
      {pendingSaveData && isConfirmationModalOpen && (
        <ExternalAgentConfirmationModal
          isConfirmationModalOpen={isConfirmationModalOpen}
          setConfirmationModalOpen={setConfirmationModalOpen}
          companyVerify={companyVerify}
          completeSaveOperation={completeSaveOperation}
          pendingSaveData={pendingSaveData}
        />
      )}
    </div>
  );
};

export default withZenCreateReferralTransactionProgress(
  ZenExternalAgentInformationStep,
);
