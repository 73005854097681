import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import AnalyticsService from '../../../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../../../types';

interface LearningLinksProps {
  learningLinks:
    | {
        title: string;
        url: string;
      }[]
    | undefined;
}

export const LearningLinks: React.FC<LearningLinksProps> = ({
  learningLinks,
}) => {
  if (!learningLinks || learningLinks.length === 0) {
    return null;
  }

  return (
    <div className='mb-4 mt-2'>
      <p className='text-white font-zen-body-medium text-base mb-1 mx-1'>
        Click on these links to learn more.
      </p>
      <div className='flex flex-row flex-wrap items-center'>
        {learningLinks.map(({ title, url }) => (
          <a
            key={url}
            href={url}
            onClick={() => {
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.LEO_LABEL_LINK_CLICK,
                {
                  link: url,
                },
              );
            }}
            target='_blank'
            className='flex flex-row items-center rounded-md bg-white bg-opacity-10 mr-2 mt-1 px-3 py-1'
            rel='noreferrer'
          >
            <FontAwesomeIcon
              icon={faLink}
              className='text-[#A0A0A0] mr-1.5 mb-0.5'
              size='xs'
            />
            <p className='text-primary-blue text-sm font-inter font-medium'>
              {title}
            </p>
          </a>
        ))}
      </div>
    </div>
  );
};
