import { FilterProps } from 'react-table';
import { FilterColumnsToProcess, ISelectOption } from '../../../../types';
import { PROCESS_FILTER_COLUMN } from '../../../../utils/TableUtils';
import ControlledMultiSelectInputV7 from '../../../ControlledMultiSelectInputV7';

export interface MultiSelectColumnFilterProps<D extends object>
  extends FilterProps<D> {
  options: Array<ISelectOption>;
}

const MultiSelectColumnFilter = <D extends object>({
  column,
  control,
  options,
}: MultiSelectColumnFilterProps<D>): React.ReactElement => {
  const prefixFieldName = `${PROCESS_FILTER_COLUMN}.${FilterColumnsToProcess.MULTI_ENUM}.${column.id}`;
  const defaultSelectedOptions = options.filter((option) =>
    (column.filterValue?.values || []).includes(option.value),
  );

  return (
    <ControlledMultiSelectInputV7
      control={control}
      options={options}
      name={prefixFieldName}
      shouldUnregister={false}
      rules={{
        validate: (value) =>
          !value || !value.length
            ? `${column.render('Header')} is required`
            : undefined,
      }}
      defaultValue={defaultSelectedOptions}
    />
  );
};

export default MultiSelectColumnFilter;
