import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { RefCallBack } from 'react-hook-form-v7';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import {
  BankActivityMatchDto,
  TransactionControllerApi,
  TransactionResponse,
} from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { showErrorToastForErrorCode } from '../../../slices/ToastNotificationSlice';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { renderTransactionStatusByCountry } from '../../../utils/TransactionHelper';
import Hover from '../../Hover';
import IconButton from '../../IconButton';
import ResourceContainer from '../../ResourceContainer';
// import ZenButton from '../ZenButton';
import ZenTransactionMatchInfoPill from './ZenTransactionMatchInfoPill';
// import ZenTransactionMatchMeter from './ZenTransactionMatchMeter';

export interface ZenTransactionMatchCardProps {
  name: string;
  value: BankActivityMatchDto;
  option: BankActivityMatchDto;
  onChange: (activity: BankActivityMatchDto) => void;
  ref: RefCallBack;
  onBlur: () => void;
}

const ZenTransactionMatchCard: React.FC<ZenTransactionMatchCardProps> = ({
  name,
  value,
  option,
  ref,
  onChange,
  onBlur,
}) => {
  const dispatch = useDispatch();
  const [seeMore, setSeeMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [
    transactionDetails,
    setTransactionDetails,
  ] = useState<TransactionResponse | null>(null);
  const fetchMoreDetails = async (id: string) => {
    try {
      setLoading(true);
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).getTransaction(id);
      setTransactionDetails(data);
    } catch (e) {
      ErrorService.notify('Unable to fetch transaction details', e, {
        transaction: { id },
      });
      dispatch(
        showErrorToastForErrorCode(
          'Unable to fetch transaction details',
          ErrorService.getErrorCode(e),
        ),
      );
      setSeeMore(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className='border border-zen-dark-4 rounded-lg'>
      <div className='bg-grey-100 rounded-lg'>
        <div className='px-4 py-4 border-b border-zen-dark-4'>
          <div className='flex items-center justify-between'>
            <label className='flex items-center space-x-3 cursor-pointer'>
              <input
                type='checkbox'
                className='border-2 text-primary-blue focus:outline-none focus:ring-0 rounded w-6 h-6 border-zen-dark-4'
                value={option.id}
                name={name}
                onChange={() => {
                  onChange(option);
                }}
                onBlur={onBlur}
                checked={option.id === value.id}
                ref={ref}
              />
              <span className='font-zen-body text-base text-zen-dark-9 font-normal'>
                {option?.transactionAddress!}
              </span>{' '}
            </label>
            <div className='ml-4'>
              <Hover
                hoverComponent={
                  <div className='text-dark'>View Transaction</div>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <IconButton
                  variant='none'
                  size='xs'
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faEye}
                      title='View Transaction'
                      size='xl'
                    />
                  }
                  buttonType='button'
                  buttonStyle='bg-grey-200 rounded-full h-9 w-9 hover:bg-zen-dark-4 flex justify-center'
                  onClick={() =>
                    window.open(
                      `/transactions/${option?.transactionId}`,
                      '_blank',
                    )
                  }
                />
              </Hover>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white rounded-lg p-4' aria-label='match-details'>
        <div className='flex flex-row items-center flex-wrap'>
          {!!option.properties &&
            option.properties
              .map((property) => (
                <div key={property.name} className='p-2'>
                  <ZenTransactionMatchInfoPill
                    label={property.name!}
                    value={property.entityValue!}
                    variant={property.rank!}
                    transactionId={option?.transactionId!}
                  />
                </div>
              ))
              .reverse()}
        </div>

        <div className='flex items-center justify-end mt-5'>
          <button
            type='button'
            onClick={() => {
              fetchMoreDetails(option.transactionId!);
              setSeeMore(!seeMore);
            }}
            className='font-zen-body font-semibold text-primary-blue text-base cursor-pointer'
          >
            More Transaction Details
          </button>
          {seeMore ? (
            <FiChevronDown className='text-primary-blue mt-1' />
          ) : (
            <FiChevronUp className='text-primary-blue mt-1' />
          )}
        </div>
        {seeMore && (
          <ResourceContainer
            isEmpty={!transactionDetails}
            loading={loading}
            resourceName='Transaction Detail'
          >
            {transactionDetails && (
              <div className='p-4'>
                <div className='grid md:grid-cols-3 grid-cols-1 grid-flow-row gap-4 mt-5'>
                  <div className='space-y-1'>
                    <p className='font-zen-body font-semibold text-zen-dark-7 text-sm'>
                      Deal Type
                    </p>
                    <p className='font-zen-body font-normal text-zen-dark text-sm'>
                      {transactionDetails?.transactionType &&
                        capitalizeEnum(transactionDetails?.transactionType!)}
                    </p>
                  </div>
                  <div className='space-y-1'>
                    <p className='font-zen-body font-semibold text-zen-dark-7 text-sm'>
                      Sale Price
                    </p>
                    <p className='font-zen-body font-normal text-zen-dark text-sm'>
                      {displayAmount(transactionDetails?.price!)}
                    </p>
                  </div>
                  <div className='space-y-1'>
                    <p className='font-zen-body font-semibold text-zen-dark-7 text-sm'>
                      Estimated Closing Date
                    </p>
                    <p className='font-zen-body font-normal text-zen-dark text-sm'>
                      {transactionDetails?.skySlopeEscrowClosingDate
                        ? DateTime.fromISO(
                            transactionDetails?.skySlopeEscrowClosingDate,
                          ).toFormat('MM/dd/yyyy')
                        : 'N/A'}
                    </p>
                  </div>
                </div>
                <div className='grid md:grid-cols-3 grid-cols-1 grid-flow-row gap-4 mt-5'>
                  <div className='space-y-1'>
                    <p className='font-zen-body font-semibold text-zen-dark-7 text-sm'>
                      Actual Closing Date
                    </p>
                    <p className='font-zen-body font-normal text-zen-dark text-sm'>
                      {transactionDetails?.skySlopeActualClosingDate
                        ? DateTime.fromISO(
                            transactionDetails?.skySlopeActualClosingDate,
                          ).toFormat('MM/dd/yyyy')
                        : 'N/A'}
                    </p>
                  </div>
                  <div className='space-y-1'>
                    <p className='font-zen-body font-semibold text-zen-dark-7 text-sm'>
                      Current Status
                    </p>
                    <p className='font-zen-body font-normal text-zen-dark text-sm'>
                      {renderTransactionStatusByCountry(
                        transactionDetails?.lifecycleState?.state!,
                        transactionDetails?.country!,
                      )}
                    </p>
                  </div>
                  <div className='space-y-1'>
                    <p className='font-zen-body font-semibold text-zen-dark-7 text-sm'>
                      Commission Payer
                    </p>
                    <p className='font-zen-body font-normal text-zen-dark text-sm'>
                      <p>{`${transactionDetails?.cdPayer?.firstName} ${transactionDetails?.cdPayer?.lastName}`}</p>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </ResourceContainer>
        )}
      </div>
    </div>
  );
};

export default ZenTransactionMatchCard;
