import { isSaleTransaction } from '../../../../utils/TransactionHelper';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledPhoneNumberInput from '../../Input/ZenControlledPhoneNumberInput';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenButton from '../../ZenButton';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
} from './ZenCreateTransactionSteps';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';

const ZenTitleCompanyStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({ form: { control, watch }, onPrevious, onNext }) => {
  const transactionType = watch('transactionType');

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <p className='text-xl font-primary-medium mb-4'>
          {isSaleTransaction(transactionType)
            ? 'What’s the Title (Escrow) Company?'
            : 'What’s the other brokerage?'}
        </p>
        <div className='space-y-4'>
          <div className='flex flex-row space-x-4'>
            <ZenControlledTextInput<CreateTransactionFormState, 'firstName'>
              control={control}
              label='First Name'
              name='firstName'
              shouldUnregister={false}
              placeholder='E.g. John'
              rules={{
                required: 'Required',
              }}
              isRequired
            />
            <ZenControlledTextInput<CreateTransactionFormState, 'lastName'>
              control={control}
              label='Last Name'
              name='lastName'
              shouldUnregister={false}
              placeholder='E.g. Doe'
              rules={{
                required: 'Required',
              }}
              isRequired
            />
          </div>
          <div className='flex flex-row space-x-4'>
            <ZenControlledTextInput<CreateTransactionFormState, 'email'>
              control={control}
              label='Email'
              name='email'
              shouldUnregister={false}
              placeholder='E.g. John@example.com'
              rules={{
                required: 'Required',
              }}
              isRequired
            />
            <ZenControlledPhoneNumberInput<
              CreateTransactionFormState,
              'phoneNumber'
            >
              control={control}
              label='Phone Number'
              name='phoneNumber'
              shouldUnregister={false}
              placeholder='E.g. +1 (123) 456 789'
              rules={{
                required: 'Required',
              }}
              isRequired
            />
          </div>
          <div>
            <ZenControlledTextInput<CreateTransactionFormState, 'companyName'>
              control={control}
              label='Company Name'
              name='companyName'
              shouldUnregister={false}
              placeholder='E.g. John'
              rules={{
                required: 'Required',
              }}
              isRequired
            />
          </div>
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton isFullWidth label='Next' onClick={onNext} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withCreateTransactionProgress(ZenTitleCompanyStep);
