import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isUndefined } from 'lodash';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { faDash } from '@fortawesome/pro-regular-svg-icons';
import {
  AgentResponse,
  AgentResponseAgentStatusEnum,
} from '../../../openapi/yenta';
import { YesNoType } from '../../../types';
import { isSmScreen } from '../../../utils/BrowserUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { agentsTableFetchData, enumFilter } from '../../../utils/TableUtils';
import ZenResourceIndexContainer from '../../Zen/Containers/ZenResourceIndexContainer';
import ZenViewActionButtonCell from '../../table/Cells/ZenViewActionButtonCell';
import CountrySelectColumnFilter from '../../table/Filters/CountrySelectColumnFilter';
import TextColumnFilter from '../../table/Filters/TextColumnFilter';
import TextStrictCaseColumnFilter from '../../table/Filters/TextStrictCaseColumnFilter';
import ZenAgentStatusCell from '../Table/Cell/ZenAgentStatusCell';
import ZenCountryCell from '../Table/Cell/ZenCountryCell';
import ZenDateCell from '../Table/Cell/ZenDateCell';
import ZenMilliDateCell from '../Table/Cell/ZenMilliDateCell';
import ZenPhoneNumberCell from '../Table/Cell/ZenPhoneNumberCell';
import ZenProfileImageCell from '../Table/Cell/ZenProfileImageCell';
import AddressStateEnumSelectColumnFilter from '../../table/Filters/AddressStateEnumSelectColumnFilter';
import StateOrProvinceIconCell from '../../table/Cells/StateOrProvinceIconCell';
import ZenIconText from '../ZenIconText';

export interface AgentResponseModified extends AgentResponse {
  status?: AgentResponseAgentStatusEnum;
  anniversaryDate?: string;
}

interface ZenAgentResourceTableProps {
  search?: string;
  officeId?: string[];
  mlsId?: string[];
  boardId?: string[];
  divisionIds?: string[];
}

// TODO: Add License State and City.
export const columns: Column<AgentResponse>[] = [
  {
    Header: 'Image',
    accessor: 'avatar',
    Cell: ({ value, row }) => (
      <ZenProfileImageCell
        name={`${row.original.firstName} ${row.original.lastName}`}
        imageUrl={value}
      />
    ),
    disableSortBy: true,
    disableFilters: true,
    cardColSize: 2,
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
    cardColSize: 5,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
    cardColSize: 5,
  },
  {
    Header: 'Division',
    accessor: 'divisions',
    Cell: ({ value }) => {
      const division = value?.[0];

      if (!division) {
        return <FontAwesomeIcon icon={faDash} className='text-center' />;
      }

      return (
        <ZenIconText
          icon={
            <img
              className='object-contain w-5 h-5'
              src={division.logoUrl}
              alt='agent-division'
            />
          }
          variant='small'
          text={division.name || '--'}
          textClassName='text-zen-dark-9 whitespace-pre'
        />
      );
    },
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Email',
    accessor: 'emailAddress',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Phone',
    accessor: 'phoneNumber',
    Cell: ({ value }) => <ZenPhoneNumberCell phoneNumber={value} />,
    cardColSize: 6,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Anniversary Date',
    accessor: 'anniversaryDate',
    Cell: ({ value }) => <ZenDateCell date={value} />,
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Country',
    accessor: 'accountCountry',
    Cell: ({ value }) => <ZenCountryCell country={value} />,
    Filter: CountrySelectColumnFilter,
    cardColSize: 5,
    disableSortBy: true,
  },
  {
    Header: 'State / Province',
    id: 'stateOrProvince',
    accessor: 'licenses',
    Cell: ({ value }) => {
      const statesAndProvinces = value!.map(
        (license) => license.administrativeArea?.stateOrProvince,
      );

      return (
        <div>
          {statesAndProvinces.map((stateOrProvince) => (
            <StateOrProvinceIconCell
              state={stateOrProvince!}
              key={stateOrProvince}
            />
          ))}
        </div>
      );
    },
    Filter: AddressStateEnumSelectColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }) => <ZenMilliDateCell date={value} />,
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Status',
    accessor: 'agentStatus',
    Cell: ({ value }) =>
      value ? <ZenAgentStatusCell status={value} /> : 'N/A',
    disableFilters: true,
    cardColSize: 6,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => <p>{value}</p>,
    disableSortBy: true,
    Filter: TextStrictCaseColumnFilter,
  },
];

const ZenAgentResourceTable: React.FC<ZenAgentResourceTableProps> = ({
  search,
  officeId,
  mlsId,
  boardId,
  divisionIds,
}) => {
  const [currentTab, setCurrentTab] = useState<string>('Active');

  const columnsWithAction: Array<Column<AgentResponse>> = useMemo(() => {
    const peopleColumns = [
      {
        Header: 'Actions',
        id: 'actions',
        Cell: ({ row }: { row: Row<AgentResponse> }) => (
          <Link to={`/people/${row.original.id}`}>
            <ZenViewActionButtonCell />
          </Link>
        ),
      },
      ...columns,
    ];

    if (currentTab === 'Inactive') {
      peopleColumns.splice(5, 0, {
        Header: 'Eligible for Rehire?',
        accessor: 'notEligibleForRehire',
        Cell: ({ value }) =>
          !isUndefined(value)
            ? !value
              ? capitalizeEnum(YesNoType.YES)
              : capitalizeEnum(YesNoType.NO)
            : 'N/A',
        disableFilters: true,
      });

      peopleColumns.splice(10, 0, {
        Header: 'Terminated Reason',
        accessor: 'terminationReason',
        Cell: ({ value }) => (value ? value : 'N/A'),
        disableFilters: true,
      });

      peopleColumns.splice(11, 0, {
        Header: 'Terminated As Of',
        accessor: 'terminatedAsOf',
        Cell: ({ value }) => <ZenDateCell date={value} />,
        disableFilters: true,
      });
    }

    return peopleColumns;
  }, [currentTab]);

  return (
    <div className='px-4 lg:pt-5'>
      <ZenResourceIndexContainer<AgentResponse>
        header='People'
        columns={columnsWithAction}
        search={search}
        initialSort={{ lastName: 'asc' }}
        tabs={[
          {
            name: 'Active',
            filter: {
              agentStatus: enumFilter(
                'agentStatus',
                AgentResponseAgentStatusEnum.Active,
              ),
            },
          },
          {
            name: 'Inactive',
            filter: {
              agentStatus: enumFilter(
                'agentStatus',
                AgentResponseAgentStatusEnum.Inactive,
              ),
            },
          },
        ]}
        fetchData={(req, cancelToken) =>
          agentsTableFetchData(req, officeId, mlsId, boardId, divisionIds, {
            cancelToken,
          })
        }
        resourceName='people'
        showSearch={isSmScreen()}
        defaultSelectedTabName={currentTab}
        setCurrentTab={setCurrentTab}
        stickyHeader
      />
    </div>
  );
};

export default ZenAgentResourceTable;
