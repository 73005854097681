import { useEffect, useState } from 'react';
import { cn } from '../utils/classUtils';

export interface ProgressBarProps {
  completed: number;
  total: number;
  containerClassNames?: string;
  progressBarHeight?: string;
  textClassNames?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  completed,
  total,
  containerClassNames,
  textClassNames,
}) => {
  const progress = Math.round((completed / total) * 100);
  const previousProgress = Math.round(((completed - 1) / total) * 100);
  const [width, setWidth] = useState<number>(previousProgress);

  useEffect(() => {
    // updating width to animate here
    setTimeout(() => {
      setWidth(progress);
    }, 100);
  }, [progress]);

  return (
    <div className='relative w-full'>
      <div
        className={cn(
          'h-3.5 bg-success transition-all ease-in-out duration-300',
          containerClassNames,
        )}
        style={{
          width: `${width}%`,
        }}
      />
      <span
        className={cn(
          'absolute right-0 top-0 text-xs mr-5',
          progress !== 100 ? 'text-gray-600' : 'text-white',
          textClassNames,
        )}
      >
        {completed}/{total} steps
      </span>
    </div>
  );
};

export default ProgressBar;
