import React from 'react';
import { cn } from '../../../../utils/classUtils';
import { SharranMediaItem } from '../../../../utils/LeoUtils';
import LeoVideoCard from './LeoVideoCard';
import LeoVideoPagination from './LeoVideoPagination';

type LeoEmbedVideoResponsesProps = {
  isShowingHistory: boolean;
  sharranVideos: SharranMediaItem[];
};

const LeoEmbedVideoResponses: React.FC<LeoEmbedVideoResponsesProps> = ({
  isShowingHistory,
  sharranVideos,
}) => {
  const [pageItems, setPageItems] = React.useState<SharranMediaItem[]>([]);

  return (
    <div
      className={cn('w-full max-w-[292px] lg:w-full lg:max-w-full pb-2', {
        'md:w-full md:max-w-full': !isShowingHistory,
      })}
    >
      <p className='text-white font-zen-body-medium text-base mb-2 mt-4'>
        Here are some videos from Sharran that might help you:
      </p>
      {pageItems.map((video, index) => (
        <LeoVideoCard
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}-${video.source_url}`}
          isShowingHistory={isShowingHistory}
          sharranVideo={video}
          index={index}
        />
      ))}
      <LeoVideoPagination
        sharranVideos={sharranVideos}
        setPageItems={setPageItems}
      />
    </div>
  );
};

export default LeoEmbedVideoResponses;
