import React from 'react';
import { ExperimentResponse } from '../../openapi/yenta';

interface ZenExperimentHeaderSectionProps {
  experiment: ExperimentResponse;
}

const ZenExperimentHeaderSection: React.FC<ZenExperimentHeaderSectionProps> = ({
  experiment,
}) => {
  return (
    <section className='md:px-8 py-6 flex flex-col w-full lg:flex-row justify-between items-start bg-zen-dark-13 text-white rounded-[15px]'>
      <div className='font-zen-body md:ml-4 py-2'>
        <h1 className='text-2xl font-medium mb-4'>{experiment.name}</h1>
      </div>
      <div className='font-zen-body flex flex-col items-center w-full max-w-sm pt-3 space-y-4 lg:items-start lg:pt-0'>
        <div className='w-full'>
          <div className='font-zen-body p-4 border border-gray-600 rounded-lg mt-2'>
            <div className='flex flex-row items-center space-x-2 mb-2'>
              <p className='text-trueGray-300'>
                Weight : {experiment.groups?.[0]?.weight}
              </p>
            </div>
            <div className='flex flex-row items-center mb-2 space-x-2'>
              <p className='text-trueGray-300'>
                Number of Override Users :{' '}
                {experiment.groups?.[0]?.overrideIds?.length || 0}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ZenExperimentHeaderSection;
