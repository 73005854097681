import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsService from '../../../../services/AnalyticsService';
import {
  getAttractingStockAward,
  setSelectedEarningsOverviewType,
} from '../../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum, RootState } from '../../../../types';
import { formatMoneyValue } from '../../../../utils/CurrencyUtils';
import IncomeProjectionChart from '../../V2/IncomeProjectionChart';
import WealthPlanInfoCard from '../../V2/WealthPlanInfoCard';
import { Earning } from '../../WealthManagementTypes';
import WealthPlanSidebarContainer from './WealthPlanSidebarContainer';

const StockAwardForAttractingSidebarContentV2 = () => {
  const dispatch = useDispatch();
  const {
    attractingStockAwardResponse: {
      loading: attractingStockAwardLoading,
      data: attractingStockAwardResponse,
    },
    wealthGoalsResponse: { data: wealthGoalsResponse },
  } = useSelector((state: RootState) => state.wealthManagement);

  const {
    currentPortfolioValue,
    projectedPortfolioValue,
    currentPortfolioAgents,
    currentPortfolioShares,
    projectedPortfolioAgents,
    projectedPortfolioShares,
    agentsReferredPerYear,
  } = attractingStockAwardResponse || {};

  const isLoading =
    attractingStockAwardLoading && !attractingStockAwardResponse;

  useEffect(() => {
    dispatch(getAttractingStockAward());
  }, [dispatch, wealthGoalsResponse]);

  const handleEditClick = () => {
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.WEALTH_PLAN_ATTRACTING_EDIT_AGENTS,
    );
    dispatch(setSelectedEarningsOverviewType(Earning.REVENUE_SHARE_INCOME));
  };

  return (
    <WealthPlanSidebarContainer
      type='STOCK_AWARD_FOR_ATTRACTING'
      loading={isLoading}
      isEmpty={!attractingStockAwardResponse}
      resourceName='Stock Award For Attracting'
    >
      <WealthPlanInfoCard
        label='5 Year Projected Value'
        value={formatMoneyValue(projectedPortfolioValue)}
        valueSizeVariant='text-3xl'
      />
      <div className='flex flex-row justify-between space-x-3'>
        <WealthPlanInfoCard
          label='Shares'
          value={projectedPortfolioShares?.toString()}
        />
        <WealthPlanInfoCard
          label='Agents'
          value={projectedPortfolioAgents?.toString()}
        />
      </div>
      <WealthPlanInfoCard
        label='Value of Shares Earned-to-Date'
        value={formatMoneyValue(currentPortfolioValue)}
        valueStyles='text-zen-dark-9'
      />
      <div className='flex flex-row justify-between space-x-3'>
        <WealthPlanInfoCard
          label='Shares'
          value={currentPortfolioShares?.toString()}
        />
        <WealthPlanInfoCard
          label='Agents'
          value={currentPortfolioAgents?.toString()}
        />
      </div>
      <WealthPlanInfoCard
        label='Number of Agents Attracted Per Year'
        value={(agentsReferredPerYear ?? 0).toString()}
        valueStyles='text-xl font-medium text-zen-dark-7'
        editButtonText='Edit'
        onEditClick={handleEditClick}
      />
      <IncomeProjectionChart
        earningType='STOCK_AWARD_FOR_ATTRACTING'
        title={{ text: '5 Year Projected Value' }}
      />
    </WealthPlanSidebarContainer>
  );
};

export default StockAwardForAttractingSidebarContentV2;
