import { Link } from 'react-router-dom';
import SignAgreementMail from '../../../assets/img/SignAgreementMail.svg';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenButton from '../../Zen/ZenButton';
import CircleCheckStepProgress from '../CircleCheckStepProgress';
import SignUpLayout from '../SignupLayout';
import {
  JointVentureSignUpFormState,
  JointVentureSignUpStepName,
} from './JointVentureSignUpSteps';

const SignAgreementsStep: StepByStepComponent<
  JointVentureSignUpFormState,
  JointVentureSignUpStepName
> = ({ progress: { currentIndex, lastIndex }, onNext, form: { watch } }) => {
  const jointVentureName = watch('jointVentureName');
  return (
    <SignUpLayout
      onNext={onNext}
      header={
        <CircleCheckStepProgress
          stepNames={Object.values(JointVentureSignUpStepName)}
          totalStepsCount={lastIndex + 1}
          inProgressIndex={currentIndex}
        />
      }
      footer={
        <div className='sticky bottom-0 bg-white p-4 border-t border-coolGray-300 flex items-center justify-center'>
          <Link to='/'>
            <div className='w-44'>
              <ZenButton
                variant='primary'
                label='Go Back To Home'
                onClick={() => {}}
                isFullWidth
              />
            </div>
          </Link>
        </div>
      }
      hideNavigation
      hideLogo
    >
      <div className='w-full flex flex-col flex-grow mt-10 relative'>
        <div className='w-full max-w-md mx-auto flex-grow py-5 px-1'>
          <div className='flex flex-col items-center text-dark pt-16'>
            <div className='pt-8 pb-4 mt-2 flex items-center justify-center'>
              <img
                src={SignAgreementMail}
                className='w-28 h-32'
                alt='Signature Mail'
              />
            </div>
            <div className='text-dark text-center'>
              <div className='pt-5 font-zen-title leading-10 text-[26px]'>
                <div>Congratulations!</div>
                <div>
                  You&apos;re 1 step away from joining the {jointVentureName}!
                </div>
              </div>
              <div className='pt-5'>
                <p className='text-base font-zen-body leading-6'>
                  Watch your email inbox for the Subscription Agreement, then
                  sign and return it soon.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SignUpLayout>
  );
};

export default SignAgreementsStep;
