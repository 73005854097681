import { faEye } from '@fortawesome/pro-light-svg-icons';
import { faCloudArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { cn } from '../../../../../utils/classUtils';
import { handleLeoListingPosterDownload } from '../../../../../utils/LeoUtils';
import { useScreenSize } from '../../../../WeathManagement/utils';
import ZenButton from '../../../ZenButton';

type LeoSocialShareablesCardProps = {
  posterLink: string;
  openModal: () => void;
};

const LeoSocialShareablesCard: React.FC<LeoSocialShareablesCardProps> = ({
  posterLink,
  openModal,
}) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownload = async (posterLink: string) => {
    setIsDownloading(true);
    await handleLeoListingPosterDownload(posterLink);
    setIsDownloading(false);
  };
  const { width } = useScreenSize();

  return (
    <div className='w-40 lg:w-60 text-white flex flex-col gap-2'>
      <div className='w-40 h-40 lg:w-60 lg:h-60 rounded-lg'>
        <img
          id='poster-image'
          src={posterLink}
          className='w-full h-full rounded-lg cursor-pointer'
          alt='poster'
          onClick={() => openModal()}
        />
      </div>
      <div className='flex flex-col lg:flex-row justify-between gap-1.5'>
        <ZenButton
          label={width < 1024 ? 'Details' : 'More Details'}
          variant='dark-outline'
          className='bg-transparent text-white border-2 border-[#282932] font-inter'
          isFullWidth
          onClick={(e: any) => {
            e.preventDefault();
            openModal();
          }}
          LeftIconComponent={
            <FontAwesomeIcon
              icon={faEye}
              className={cn('text-white text-sm px-px mr-1')}
            />
          }
        />
        <ZenButton
          label={width < 1024 ? 'Download' : ''}
          variant='dark-outline'
          className='border-2 border-[#00FBF0] font-inter rounded-lg bg-transparent px-2 text-[#00FBF0]'
          LeftIconComponent={
            !isDownloading ? (
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                className={cn(
                  'text-[#00FBF0] text-lg px-px',
                  width < 1024 && 'mr-2',
                )}
              />
            ) : undefined
          }
          onClick={(e: any) => {
            e.preventDefault();
            handleDownload(posterLink);
          }}
          isSubmitting={isDownloading}
          isDisabled={isDownloading}
        />
      </div>
    </div>
  );
};

export default LeoSocialShareablesCard;
