import { TextInputProps } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { TextInput } from './TextInput';

export const PasswordInput: React.FC<TextInputProps> = (props) => {
  const [show, setShow] = useState(false);
  const type = show ? 'text' : 'password';
  const RightSection = props.rightSection ?? (
    <FontAwesomeIcon
      icon={show ? faEyeSlash : faEye}
      className='cursor-pointer'
      onClick={() => setShow(!show)}
      data-testid='password-input-toggle'
    />
  );
  return (
    <TextInput
      type={props.type ?? type}
      {...props}
      rightSection={props.disabled ? undefined : RightSection}
    />
  );
};
