import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';
import { useCallback, useEffect } from 'react';
import { useGemini } from '../hooks/useGemini';
import { resetItems, toggleItem } from '../slices/MainMenuSlice';
import { RootState } from '../types';
import { cn } from '../utils/classUtils';
import { isMenuItemActive } from '../utils/UrlUtils';
import Hover from './Hover';

interface ZenSideBarMenuProps {
  collapsed: boolean;
  label: string;
  icon: React.ReactElement;
  children: Array<React.ReactElement | null> | React.ReactElement;
  RightComponent?: React.ReactElement;
}

const ZenSideBarMenu = ({
  collapsed,
  label,
  icon,
  children,
  RightComponent,
}: ZenSideBarMenuProps) => {
  const dispatch = useDispatch();
  const isGeminiEnabled = useGemini();

  const { openItems } = useSelector((state: RootState) => state.mainMenu);

  const isOpen = !!openItems.find((item) => item === label);

  const childPaths = Array.isArray(children)
    ? children?.filter((child) => !!child)?.map((child) => child!.props.linkTo)
    : children.props.linkTo;

  const isActive = isMenuItemActive(childPaths);

  const toggleIsOpen = () => {
    dispatch(toggleItem(label));
  };

  const handlePopoverClickOutside = () => {
    if (collapsed) {
      toggleIsOpen();
    }
  };

  const handleResetPopovers = useCallback(() => {
    if (collapsed) {
      dispatch(resetItems());
    }
  }, [collapsed, dispatch]);

  useEffect(() => {
    handleResetPopovers();
  }, [handleResetPopovers]);

  return (
    <Popover
      isOpen={collapsed && isOpen}
      onClickOutside={handlePopoverClickOutside}
      content={
        <div
          className={cn(
            'border rounded-md p-1 text-primary-light w-40',
            isGeminiEnabled
              ? 'bg-primary-navy border-regent-900'
              : 'bg-zen-dark-22 border-zen-gray-6',
          )}
          onClick={handleResetPopovers}
        >
          {children}
        </div>
      }
      padding={8}
      align='start'
      positions={['bottom', 'right']}
    >
      <div>
        <Hover
          config={{
            trigger: collapsed ? 'hover' : null,
            placement: 'right',
          }}
          hoverComponent={
            <p
              className={cn(
                'px-2 whitespace-pre text-sm',
                isGeminiEnabled ? 'font-zen-body-2' : 'font-zen-body',
              )}
            >
              {label}
            </p>
          }
          variant='dark'
        >
          <div
            onClick={toggleIsOpen}
            className={cn(
              'flex flex-row items-center text-primary-light group z-0 rounded-xl cursor-pointer my-2 py-1',
              isOpen && !isGeminiEnabled
                ? 'bg-zen-dark-22'
                : 'hover:bg-zen-dark-22',
              isOpen && isGeminiEnabled
                ? 'bg-primary-navy'
                : 'hover:bg-regent-900',
              collapsed ? 'justify-center' : 'justify-between px-2',
              {
                'bg-zen-dark-22': isActive && !isGeminiEnabled,
                'hover:bg-zen-dark-22': !isGeminiEnabled,
                'hover:bg-regent-900': isGeminiEnabled,
                'bg-regent-900': (isActive || isOpen) && isGeminiEnabled,
              },
            )}
          >
            <div
              className={cn(
                'flex items-center text-sm text-primary-light',
                isGeminiEnabled ? 'font-zen-body-2' : 'font-zen-body',
              )}
            >
              <span
                className={cn(
                  isGeminiEnabled
                    ? 'group-hover:text-aqua'
                    : 'group-hover:text-zen-primary-6',
                  collapsed ? 'text-lg' : 'text-sm',
                  {
                    'text-zen-primary-6': isActive && !isGeminiEnabled,
                    'text-aqua': isActive && isGeminiEnabled,
                  },
                )}
              >
                {icon}
              </span>
              {!collapsed && (
                <span className='p-1 pl-2.5 text-sm'>{label}</span>
              )}
            </div>
            {!collapsed && RightComponent}
            {!collapsed && (
              <FontAwesomeIcon
                icon={faAngleDown}
                className={cn('text-sm text-primary-light', {
                  'transform rotate-180': isOpen,
                  'text-zen-primary-6': isActive && !isGeminiEnabled,
                  'text-aqua': isActive && isGeminiEnabled,
                })}
              />
            )}
          </div>
        </Hover>
        {!collapsed && isOpen && children}
      </div>
    </Popover>
  );
};

export default ZenSideBarMenu;
