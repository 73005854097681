import React from 'react';
import { cn } from '../../utils/classUtils';

export type LabelValueDisplayCardBorderVariant =
  | 'none'
  | 'left'
  | 'top'
  | 'right'
  | 'bottom';

export interface LabelValueDisplayCardProps {
  label: string;
  value: string | React.ReactElement;
  border?: LabelValueDisplayCardBorderVariant;
  noPadding?: boolean;
  leftIcon?: React.ReactElement;
}

const LabelValueDisplayCard: React.FC<LabelValueDisplayCardProps> = ({
  label,
  value,
  border = 'none',
  noPadding = false,
  leftIcon,
}) => {
  const variantMapClass: {
    [x in LabelValueDisplayCardBorderVariant]: string;
  } = {
    none: 'border-b-0',
    left: 'md:border-l',
    right: 'md:border-r',
    bottom: 'md:border-b',
    top: 'md:border-t',
  };
  return (
    <div
      className={cn(
        'flex flex-row',
        noPadding ? 'p-0' : 'p-6 md:border-0 border-b',
        cn(variantMapClass[border!]),
      )}
    >
      {leftIcon && (
        <div className='flex flex-shrink-0 justify-center items-center h-9 w-9 rounded-full bg-zen-light-gray-2 mr-3'>
          {leftIcon}
        </div>
      )}
      <div className='flex-shrink font-zen-body text-zen-dark-9 text-base space-y-3.5'>
        <p className='font-semibold'>{label}</p>
        <div>{value}</div>
      </div>
    </div>
  );
};

export default LabelValueDisplayCard;
