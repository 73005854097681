import {
  faCrown,
  faShieldKeyhole,
  faUserGroup,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { AgentTeamMembershipResponseRolesEnum } from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { getTeamMemberRole } from '../../../utils/TeamHelper';

interface ZenTeamsRoleTypePillProps {
  roles: Array<string>;
  isProTeam?: boolean;
}

const ZenTeamsRoleTypePill: React.FC<ZenTeamsRoleTypePillProps> = ({
  roles,
  isProTeam,
}) => {
  const teamRole = roles[0] as AgentTeamMembershipResponseRolesEnum;

  const pillVariantTypeToClassNameMap: EnumMap<
    AgentTeamMembershipResponseRolesEnum,
    string
  > = {
    [AgentTeamMembershipResponseRolesEnum.Leader]:
      'bg-purple-300 text-purple-600',
    [AgentTeamMembershipResponseRolesEnum.Member]:
      'bg-sangria-300 text-sangria-900',
    [AgentTeamMembershipResponseRolesEnum.Admin]:
      'bg-rezen-light-blue-100 text-primary-navy',
  };

  const roleTypeToIconMap: Record<
    AgentTeamMembershipResponseRolesEnum,
    React.ReactElement
  > = {
    [AgentTeamMembershipResponseRolesEnum.Leader]: (
      <FontAwesomeIcon icon={faCrown} />
    ),
    [AgentTeamMembershipResponseRolesEnum.Admin]: (
      <FontAwesomeIcon icon={faShieldKeyhole} />
    ),
    [AgentTeamMembershipResponseRolesEnum.Member]: (
      <FontAwesomeIcon icon={faUserGroup} />
    ),
  };
  return (
    <div
      className={cn(
        'flex flex-row gap-1 items-center py-1 pl-2.5 pr-3 rounded-full',
        pillVariantTypeToClassNameMap[teamRole],
      )}
    >
      <span className='mt-0.5'>{roleTypeToIconMap[teamRole]}</span>
      <span className='font-medium font-inter text-sm'>
        {capitalizeEnum(getTeamMemberRole(teamRole, isProTeam))}
      </span>
    </div>
  );
};

export default ZenTeamsRoleTypePill;
