import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  BankAccountDto,
  MoneyTransferResponse,
} from '../../../openapi/arrakis';
import { deleteMoneyTransfer } from '../../../slices/MoneyTransferSlice';
import { displayFormattedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import { getBankAccountFormattedString } from '../../../utils/TransactionHelper';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';

interface DeleteMoneyTransferConfirmationModalProps {
  isOpen: boolean;
  onClose(): void;
  moneyTransfer: MoneyTransferResponse;
  bankAccounts: BankAccountDto[];
}

const ZenDeleteMoneyTransferConfirmationModal: React.FC<DeleteMoneyTransferConfirmationModalProps> = ({
  isOpen,
  onClose,
  moneyTransfer,
  bankAccounts,
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const fromBankAccount = bankAccounts.find(
    (bankAccount) => bankAccount.id === moneyTransfer?.fromBankAccountId,
  );
  const toBankAccount = bankAccounts.find(
    (bankAccount) => bankAccount.id === moneyTransfer?.toBankAccountId,
  );

  const handleDelete = async () => {
    setIsSubmitting(true);
    await dispatch(deleteMoneyTransfer(moneyTransfer.id!));
    setIsSubmitting(false);
    onClose();
  };

  return (
    <ZenConfirmationModal
      variant='danger'
      isOpen={isOpen}
      onClose={onClose}
      title='Delete money transfer?'
      subtitle={`You're about to delete a transfer of ${displayFormattedAmountWithCurrency(
        moneyTransfer?.amount,
      )} FROM ${getBankAccountFormattedString(
        fromBankAccount!,
        true,
      )} TO ${getBankAccountFormattedString(toBankAccount!, true)}`}
      onConfirm={handleDelete}
      isSubmitting={isSubmitting}
    />
  );
};

export default ZenDeleteMoneyTransferConfirmationModal;
