import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchTopAnnouncement } from '../../../slices/AnnouncementSlice';
import { AppDispatch, RootState } from '../../../types';
import AnnouncementModal from './AnnouncementModal';

export interface AnnouncementProps {}

const Announcement: React.FC<AnnouncementProps> = () => {
  const {
    auth: { userDetail, isMortgageUserRole },
    announcement: { topAnnouncement },
  } = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!isMortgageUserRole) {
      dispatch(fetchTopAnnouncement());
    }
  }, [dispatch, location.pathname, userDetail?.id, isMortgageUserRole]);

  if (isEmpty(topAnnouncement)) {
    return null;
  }

  return <AnnouncementModal announcement={topAnnouncement!} />;
};

export default Announcement;
