import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import { useWatch } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AddressRequestCountryEnum } from '../../openapi/yenta';
import AnalyticsService from '../../services/AnalyticsService';
import { countryMapState } from '../../testUtils/OnboardingUtils';
import { AnalyticsEventEnum, RootState, YesNoType } from '../../types';
import {
  EMAIL_VALIDATIONS,
  getPostalCodeValidation,
  PHONE_NUMBER_VALIDATIONS,
} from '../../utils/Validations';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledCheckboxInput from '../Zen/Input/ZenControlledCheckboxInput';
import ZenControlledPhoneNumberInput from '../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenAvatar from '../Zen/ZenAvatar';
import ZenButton from '../Zen/ZenButton';
import {
  WalletApplicationFormState,
  WalletApplicationStepName,
} from './WalletApplicationSteps';
import withWalletApplicationProgress from './withWalletApplicationProgress';

const WalletPersonalInformationStep: StepByStepComponent<
  WalletApplicationFormState,
  WalletApplicationStepName
> = ({ form: { control }, onNext }) => {
  const history = useHistory();
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const isShippingAddressSameAsBilling = useWatch({
    control,
    name: 'isShippingAddressSameAsBilling',
  });

  return (
    <div className='w-full flex flex-col flex-grow mt-6'>
      <div className='w-full max-w-2xl mx-auto flex-grow mb-20'>
        <div className='flex flex-row justify-center items-center'>
          <ZenAvatar
            colorClassName='bg-seaglass'
            textOverride='font-inter !text-2xl font-medium text-primary-dark'
            size='xxl'
            name={userDetail?.fullName ?? ''}
            imageUrl={userDetail?.avatar}
          />
        </div>
        <div>
          <p className='my-4 font-inter text-base font-light text-center text-primary-dark'>
            Please provide information below
          </p>

          <div className='flex flex-row space-x-4 mt-8'>
            <ZenControlledTextInput
              control={control}
              label='First Name'
              name='firstName'
              placeholder='First Name'
              labelClassName='font-inter font-light'
              shouldUnregister={false}
              readOnly
            />
            <ZenControlledTextInput<WalletApplicationFormState, 'lastName'>
              control={control}
              label='Last Name'
              name='lastName'
              placeholder='Last Name'
              labelClassName='font-inter font-light'
              shouldUnregister={false}
              readOnly
            />
          </div>
          <div className='flex flex-row space-x-4 mt-4'>
            <ZenControlledTextInput<WalletApplicationFormState, 'emailAddress'>
              control={control}
              label='Email Address'
              name='emailAddress'
              placeholder='Email'
              labelClassName='font-inter font-light'
              shouldUnregister={false}
              rules={{
                required: 'Please provide an email',
                ...EMAIL_VALIDATIONS,
              }}
              startAdornment={
                <div className='flex w-full h-full items-center justify-center pl-2'>
                  <FontAwesomeIcon
                    className='text-primary-blue'
                    icon={regular('envelope')}
                  />
                </div>
              }
              isRequired
            />
            <ZenControlledPhoneNumberInput<
              WalletApplicationFormState,
              'phoneNumber'
            >
              control={control}
              label='Phone Number'
              name='phoneNumber'
              placeholder='Phone'
              labelClassName='!font-inter font-light'
              shouldUnregister={false}
              rules={{
                required: 'Please enter phone number',
                ...PHONE_NUMBER_VALIDATIONS,
              }}
              isRequired
            />
          </div>

          <p className='mt-6 font-inter font-medium text-base'>
            Billing Address
          </p>

          <div className='mt-1.5'>
            <ZenControlledTextInput<WalletApplicationFormState, 'street'>
              control={control}
              label='Street'
              name='street'
              placeholder='Enter a location'
              labelClassName='font-inter font-light'
              shouldUnregister={false}
              rules={{
                required: 'Street is required',
              }}
              isRequired
            />

            <div className='flex flex-row space-x-4 mt-4'>
              <ZenControlledTextInput<
                WalletApplicationFormState,
                'apartmentOrUnitOrSuit'
              >
                control={control}
                label='Apartment, Unit, Suite'
                placeholder='Apt, Unit, Suite'
                name='apartmentOrUnitOrSuit'
                labelClassName='font-inter font-light'
                shouldUnregister={false}
              />
              <ZenControlledTextInput<WalletApplicationFormState, 'city'>
                control={control}
                label='City'
                name='city'
                placeholder='City'
                labelClassName='font-inter font-light'
                shouldUnregister={false}
                rules={{
                  required: 'Please enter city',
                }}
                isRequired
              />
            </div>
            <div className='flex flex-row space-x-4 mt-4'>
              <ZenControlledAsyncSelectInput<
                WalletApplicationFormState,
                'state'
              >
                control={control}
                label='State'
                name='state'
                placeholder='State'
                labelClassName='!font-inter font-light'
                fetchData={async (search, page) => {
                  if (page !== 0) {
                    return [];
                  }

                  const filteredStates = values(
                    countryMapState[AddressRequestCountryEnum.UnitedStates],
                  ).filter((state) => {
                    return state?.value
                      ?.toLowerCase()
                      .includes(search.toLowerCase());
                  });

                  return filteredStates;
                }}
                shouldUnregister={false}
                rules={{
                  required: 'Please enter state',
                }}
                isRequired
              />
              <ZenControlledTextInput<WalletApplicationFormState, 'zipCode'>
                control={control}
                label='ZIP Code'
                name='zipCode'
                placeholder='ZIP Code'
                labelClassName='font-inter font-light'
                shouldUnregister={false}
                rules={{
                  required: 'Please enter ZIP code',
                  ...getPostalCodeValidation(
                    AddressRequestCountryEnum.UnitedStates,
                  ),
                }}
                isRequired
              />
            </div>
          </div>

          <div className='mt-6 mb-3'>
            <p className='font-inter font-medium text-base text-primary-dark mb-2'>
              Shipping Address
            </p>

            <ZenControlledCheckboxInput<
              WalletApplicationFormState,
              'isShippingAddressSameAsBilling'
            >
              name='isShippingAddressSameAsBilling'
              shouldUnregister={false}
              size='sm'
              options={[
                {
                  label: (
                    <span className='font-light font-inter text-xs block -ml-2'>
                      Shipping address is the same as the billing address.
                    </span>
                  ),
                  value: YesNoType.YES,
                },
              ]}
              control={control}
              reverse
            />
          </div>
          {isShippingAddressSameAsBilling?.[0] !== YesNoType.YES && (
            <div>
              <ZenControlledTextInput<
                WalletApplicationFormState,
                'shippingStreet'
              >
                control={control}
                label='Street'
                name='shippingStreet'
                shouldUnregister={false}
                placeholder='Enter a Location'
                labelClassName='font-inter font-light'
                rules={{
                  required: 'Street is required',
                }}
                isRequired
              />

              <div className='flex flex-row space-x-4 mt-4'>
                <ZenControlledTextInput<
                  WalletApplicationFormState,
                  'shippingApartmentOrUnitOrSuit'
                >
                  control={control}
                  label='Apartment, Unit, Suite'
                  name='shippingApartmentOrUnitOrSuit'
                  placeholder='Apt, Unit, Suite'
                  labelClassName='font-inter font-light'
                  shouldUnregister={false}
                />
                <ZenControlledTextInput<
                  WalletApplicationFormState,
                  'shippingCity'
                >
                  control={control}
                  label='City'
                  name='shippingCity'
                  placeholder='City'
                  shouldUnregister={false}
                  labelClassName='font-inter font-light'
                  rules={{
                    required: 'Please enter city',
                  }}
                  isRequired
                />
              </div>
              <div className='flex flex-row space-x-4 mt-4'>
                <ZenControlledAsyncSelectInput<
                  WalletApplicationFormState,
                  'shippingState'
                >
                  control={control}
                  label='State'
                  name='shippingState'
                  placeholder='State'
                  labelClassName='!font-inter font-light'
                  fetchData={async (search, page) => {
                    if (page !== 0) {
                      return [];
                    }

                    const filteredStates = values(
                      countryMapState[AddressRequestCountryEnum.UnitedStates],
                    ).filter((state) => {
                      return state?.value
                        ?.toLowerCase()
                        .includes(search.toLowerCase());
                    });

                    return filteredStates;
                  }}
                  shouldUnregister={false}
                  rules={{
                    validate: (value) => {
                      return value?.value ? undefined : 'Please enter state';
                    },
                  }}
                  isRequired
                  maxMenuHeight={185}
                />
                <ZenControlledTextInput<
                  WalletApplicationFormState,
                  'shippingZipCode'
                >
                  control={control}
                  label='ZIP Code'
                  name='shippingZipCode'
                  shouldUnregister={false}
                  placeholder='ZIP Code'
                  labelClassName='font-inter font-light'
                  rules={{
                    required: 'Please enter ZIP code',
                    ...getPostalCodeValidation(
                      AddressRequestCountryEnum.UnitedStates,
                    ),
                  }}
                  isRequired
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='sticky w-full bottom-0 z-0 bg-primary-light'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-outline'
              label='Back'
              onClick={() => history.push('/wallet')}
              className='!font-inter font-medium text-lg border-primary-dark'
            />
            <ZenButton
              isFullWidth
              label='Next'
              onClick={() => {
                onNext();
                AnalyticsService.instance().logEvent(
                  AnalyticsEventEnum.WALLET_SIGN_UP_STEP_1_NEXT_CLICKED,
                );
              }}
              className='!font-inter font-medium text-lg bg-rezen-blue-600 border-rezen-blue-600'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withWalletApplicationProgress(WalletPersonalInformationStep);
