import React, { useMemo, useState } from 'react';
import {
  faArrowUpRightFromSquare,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import ZenSidebarModal from '../../../components/Zen/ZenSidebarModal';
import GeminiButton from '../../../components/Gemini/GeminiButton';
import { ReferralCentralReferralDto } from '../../../openapi/arrakis';
import ReferralDetailCard from '../components/ReferralDetailCard';
import ReferralAgentProfile from '../components/ReferralAgentProfile';
import ReferralSidebarTabs, {
  SidebarTab,
} from '../components/ReferralSidebarTabs';

interface ReferralDetailReceivedSidebarProps {
  referralDetails: ReferralCentralReferralDto;
  onClose: () => void;
  isOpen: boolean;
}

const ReferralDetailReceivedSidebar: React.FC<ReferralDetailReceivedSidebarProps> = ({
  referralDetails,
  onClose,
  isOpen,
}) => {
  const OverviewTab = useMemo(() => {
    return (
      <>
        <div className='p-5 border border-regent-300 rounded-lg my-4'>
          <ReferralDetailCard referralDetails={referralDetails} />
        </div>

        <div className='border border-grey-200 rounded-lg p-4 font-inter'>
          <ReferralAgentProfile isShort />
        </div>
        <div className='pt-24' />
      </>
    );
  }, [referralDetails]);

  const CommentTab = useMemo(() => {
    return (
      <div className='w-full mx-auto px-4 md:pt-6 relative'>
        {/* <ZenCommentSection
        fetchComments={(fetchComments)}
        onDelete={onDeleteComment}
        onEdit={onEditComment}
        onSubmit={handleSubmit}
        getMentions={getMentions}
        htmlElementScrollableParent={document?.getElementById('main-page')!}
        containerType={RezenObjectTypeEnum.Referral}
        containerId=''
        sseURL={`${SSE_URL}?containerType=${RezenObjectTypeEnum.Transaction}&containerId=${transactionDetail?.id}`}
      /> */}
        Comments coming soon
      </div>
    );
  }, []);

  const tabs: SidebarTab[] = [
    {
      tabName: 'Overview',
      tabContent: OverviewTab,
    },
    {
      tabName: 'Messages',
      tabContent: CommentTab,
    },
  ];

  const [selectedTab, setSelectedTab] = useState('Overview');

  return (
    <ZenSidebarModal title='Referral Details' isOpen={isOpen} onClose={onClose}>
      <div className='flex flex-col gap-4 w-full pt-5'>
        <ReferralSidebarTabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <div className='p-5 bg-white border-t border-gray-200 flex flex-row justify-between w-full gap-4 absolute bottom-0'>
          <GeminiButton
            type='button'
            onClick={onClose}
            label='Back'
            size='md'
            variant='primary-link'
          />
          {!referralDetails.transactionId ? (
            <GeminiButton
              type='button'
              onClick={onClose}
              label='Create Listing'
              leftIcon={faPlus}
              size='md'
              variant='primary-outline'
            />
          ) : (
            <GeminiButton
              type='button'
              onClick={onClose}
              label='View Listing'
              size='md'
              variant='primary-outline'
              leftIcon={faArrowUpRightFromSquare}
            />
          )}
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default ReferralDetailReceivedSidebar;
