import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ParticipantValue,
  ParticipantValueRoleEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { showSuccessToast } from '../../../slices/ToastNotificationSlice';
import {
  removeTransactionParticipant,
  updateTransactionParticipantLite,
} from '../../../slices/TransactionSlice';
import { AppDispatch, RootState } from '../../../types';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import { isTransactionReadOnly } from '../../../utils/TransactionHelper';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import ZenEditTransactionParticipantFormSidebarModal from '../forms/ZenEditTransactionParticipantFormSidebarModal';
import ZenTransactionOtherParticipantsTable from './ZenTransactionOtherParticipantsTable';

interface ZenTransactionOtherParticipantsProps {
  transaction: TransactionResponse;
}

const ZenTransactionOtherParticipants: React.FC<ZenTransactionOtherParticipantsProps> = ({
  transaction,
}) => {
  const { userDetail, isAdmin } = useSelector((state: RootState) => state.auth);

  const dispatch: AppDispatch = useDispatch();
  const [
    editingParticipant,
    setEditingParticipant,
  ] = useState<ParticipantValue | null>(null);
  const [
    deletingParticipant,
    setDeletingParticipant,
  ] = useState<ParticipantValue | null>(null);
  const [isDeleting, setDeleting] = useState<boolean>(false);

  const toggleParticipant = async (
    participant: ParticipantValue,
  ): Promise<boolean> => {
    const isSuccess = await dispatch(
      updateTransactionParticipantLite(participant.id!, transaction.id!, {
        commissionDocumentRecipient: !participant.commissionDocumentRecipient,
      }),
    );
    if (isSuccess) {
      dispatch(showSuccessToast('Participant Updated!'));
    }
    return isSuccess;
  };

  const deleteParticipant = async () => {
    setDeleting(true);
    const success = await dispatch(
      removeTransactionParticipant(transaction.id!, deletingParticipant?.id!),
    );
    if (success) {
      setDeletingParticipant(null);
    }
    setDeleting(false);
  };

  return (
    <div className='col-span-12 py-4'>
      <ZenTransactionOtherParticipantsTable
        transaction={transaction!}
        onEdit={(participant) => setEditingParticipant(participant)}
        onDelete={(participant) => {
          if (
            isAdmin ||
            participant.role !== ParticipantValueRoleEnum.TeamLeader
          ) {
            setDeletingParticipant(participant);
          }
        }}
        toggleFlag={toggleParticipant}
        isReadonly={isTransactionReadOnly(transaction, userDetail!)}
      />

      {!!editingParticipant && (
        <ZenEditTransactionParticipantFormSidebarModal
          transaction={transaction}
          participant={editingParticipant}
          isOpen={!!editingParticipant}
          onClose={() => setEditingParticipant(null)}
          readOnly={isTransactionReadOnly(transaction, userDetail!)}
        />
      )}
      <ZenConfirmationModal
        isOpen={!!deletingParticipant}
        onClose={() => setDeletingParticipant(null)}
        variant='danger'
        isSubmitting={isDeleting}
        isDisabled={isDeleting}
        onConfirm={deleteParticipant}
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        title={`Remove ${getParticipantName(deletingParticipant!)}?`}
        subtitle='You can always add them back in the future.'
      />
    </div>
  );
};

export default ZenTransactionOtherParticipants;
