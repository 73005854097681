import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faFilePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form-v7';
import {
  ParticipantResponse,
  ParticipantResponseParticipantRoleEnum,
} from '../../../openapi/arrakis';
import { MoneyValueCurrencyEnum } from '../../../openapi/yenta';
import { displayAmount, parseMoney } from '../../../utils/CurrencyUtils';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import { participantRoleDisplayName } from '../../../utils/TransactionHelper';
import {
  MONEY_VALIDATIONS,
  PERCENT_VALIDATION,
} from '../../../utils/Validations';
import ZenControlledPercentageInput from '../../Zen/Input/ZenControlledPercentageInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../../Zen/Input/ZenControlledToggleInput';
import ZenControlledCurrencyInput from '../Input/ZenControlledCurrencyInput';
import ZenAvatar from '../ZenAvatar';

interface ZenCommissionSplitContentProps {
  fields: Record<'id', string>[];
  participants: ParticipantResponse[];
  commissionSplitErrors: string[];
  currency: MoneyValueCurrencyEnum;
  watchParticipants: any;
  totalAmount: number;
  totalPercentage: number;
  control: Control<any, object>;
  errors: DeepMap<any, FieldError>;
  totalCommission: number;
  toggleSwitches(index: number): void;
  defaultPercent?: string;
  defaultAmount?: string;
  isRequired?: boolean;
}
const ZenCommissionSplitContent: React.FC<ZenCommissionSplitContentProps> = ({
  fields,
  participants,
  commissionSplitErrors,
  currency,
  watchParticipants,
  totalAmount,
  totalPercentage,
  control,
  errors,
  totalCommission,
  toggleSwitches,
  defaultPercent = '0',
  defaultAmount = '0',
}) => {
  const isConsiderDollar =
    watchParticipants.every((p: any) => p.isDollar) ||
    totalAmount === totalCommission;
  const checkAmountError = (index: number) =>
    get(errors, `commissionParticipant[${index}].money.amount`);
  const checkPercentageError = (index: number) =>
    get(errors, `commissionParticipant[${index}].percent.value`);
  return (
    <div>
      <div className='py-3'>
        <div className='divide-y space-y-2'>
          <ul>
            {fields.map((item: any, index: number) => {
              const participant = participants?.find((p) => p.id === item.id)!;
              return (
                <section
                  className='flex flex-row justify-between py-1 px-2 md:px-4'
                  key={participant?.id}
                >
                  <div className='flex flex-row space-x-1'>
                    <div className='flex flex-row space-x-4 items-center'>
                      <ZenAvatar
                        name={getParticipantName(participant) || 'N/A'}
                        imageUrl={item.avatar!}
                        size='sm'
                      />
                      <div className='flex md:flex-row flex-col'>
                        <div className='flex flex-col'>
                          <ZenControlledTextInput
                            type='hidden'
                            control={control}
                            shouldUnregister={false}
                            name={`commissionParticipant[${index}].id`}
                            defaultValue={item.id}
                            noBorder
                          />
                          <div className='flex items-start space-x-2.5'>
                            <p className='font-zen-body font-normal md:text-base text-sm text-zen-dark-9'>
                              {getParticipantName(participant)}
                            </p>
                            {participant?.participantRole! ===
                              ParticipantResponseParticipantRoleEnum.TeamLeader && (
                              <FontAwesomeIcon
                                icon={solid('crown')}
                                size='lg'
                                className='text-zen-warning-dark'
                              />
                            )}
                          </div>
                          <p className='font-zen-body font-medium md:text-sm text-xs text-zen-dark-6'>
                            {participant?.participantRole
                              ? participantRoleDisplayName(
                                  participant?.participantRole!,
                                )
                              : null}
                          </p>
                        </div>
                        <div className='flex flex-row space-x-1 items-center'>
                          {participant?.commissionDocumentPayer && (
                            <FontAwesomeIcon
                              icon={faFilePlus}
                              data-testid='documentIcon'
                              className='text-gray-500 ml-1'
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-row items-center space-x-3'>
                    <div onClick={() => toggleSwitches(index)}>
                      <ZenControlledToggleInput
                        label=''
                        name={`commissionParticipant[${index}].isDollar`}
                        control={control}
                        shouldUnregister={false}
                        defaultValue={
                          watchParticipants[index]?.isDollar || false
                        }
                        rightIcon='$'
                        leftIcon='%'
                      />
                    </div>
                    <div
                      className={classNames(
                        'mt-1 flex rounded-lg shadow-sm',
                        watchParticipants[index]?.isDollar ? 'hidden' : 'block',
                      )}
                    >
                      <span
                        className={classNames(
                          'text-sm inline-flex items-center px-1.5 md:px-2 rounded-l-lg border border-r-0  text-primary-blue sm:text-sm',
                          checkPercentageError(index)
                            ? 'border-zen-danger'
                            : 'border-gray-300',
                        )}
                      >
                        %
                      </span>
                      <span
                        className={classNames(
                          'w-20 rounded-none rounded-r-lg sm:text-sm border border-l-0',
                          checkPercentageError(index)
                            ? 'border-zen-danger'
                            : 'border-gray-300',
                        )}
                      >
                        <ZenControlledPercentageInput
                          control={control}
                          name={`commissionParticipant[${index}].percent.value`}
                          shouldUnregister={false}
                          defaultValue={item.percent?.value || defaultPercent}
                          rules={{
                            required: !watchParticipants[index]?.isDollar
                              ? 'Required'
                              : undefined,
                            pattern: !watchParticipants[index]?.isDollar
                              ? PERCENT_VALIDATION?.pattern!
                              : undefined,
                          }}
                          noBorder
                          placeholder='Percentage'
                          hideErrorMessage
                        />
                        <ZenControlledTextInput
                          control={control}
                          type='hidden'
                          name={`commissionParticipant[${index}].percent.string`}
                          shouldUnregister={false}
                          noBorder
                          defaultValue={item.percent?.string || '0%'}
                        />
                      </span>
                    </div>
                    <div
                      className={classNames(
                        'mt-1 flex rounded shadow-sm',
                        watchParticipants[index]?.isDollar ? 'block' : 'hidden',
                      )}
                    >
                      <span
                        className={classNames(
                          'text-sm inline-flex items-center px-1.5 md:px-2.5 rounded-l-lg border border-r-0 text-primary-blue sm:text-sm',
                          checkAmountError(index)
                            ? 'border-zen-danger'
                            : 'border-gray-300',
                        )}
                      >
                        $
                      </span>
                      <span
                        className={classNames(
                          'w-20 rounded-none rounded-r-lg text-sm border border-l-0',
                          checkAmountError(index)
                            ? 'border-zen-danger'
                            : 'border-gray-300',
                        )}
                      >
                        <ZenControlledCurrencyInput
                          control={control}
                          name={`commissionParticipant[${index}].money.amount`}
                          shouldUnregister={false}
                          noBorder
                          defaultValue={
                            +item?.money?.amount
                              ? parseMoney(+item.money.amount)
                              : defaultAmount
                          }
                          rules={{
                            required: watchParticipants[index]?.isDollar
                              ? 'Required'
                              : undefined,
                            pattern: watchParticipants[index]?.isDollar
                              ? MONEY_VALIDATIONS?.pattern!
                              : undefined,
                          }}
                          placeholder='Amount'
                          hideErrorMessage
                        />
                        <ZenControlledTextInput
                          control={control}
                          type='hidden'
                          name={`commissionParticipant[${index}].money.currency`}
                          shouldUnregister={false}
                          noBorder
                          defaultValue={item.money?.currency || currency}
                        />
                      </span>
                    </div>
                  </div>
                </section>
              );
            })}
          </ul>
        </div>
      </div>
      <div className='flex flex-col space-y-2 py-2 px-2 md:px-4'>
        <div className='flex flex-row justify-between items-start'>
          <p className='text-lg font-zen-body font-medium text-left text-zen-dark-9'>
            Total:
          </p>
          <div className='flex justify-end'>
            <p
              className={classNames(
                'font-zen-body text-base',
                !!commissionSplitErrors.length
                  ? 'text-zen-danger border border-zen-danger rounded p-1'
                  : 'text-zen-dark-9 p-1 border-0 font-bold',
              )}
            >
              {isConsiderDollar
                ? displayAmount({
                    amount: totalAmount,
                    currency: currency,
                  })
                : `${totalPercentage}%`}
            </p>
          </div>
        </div>
        {!!commissionSplitErrors.length && (
          <div className='flex flex-col items-end'>
            {commissionSplitErrors.map((errorText) => (
              <p
                key={errorText}
                className='text-zen-danger font-zen-body p-1 flex text-sm md:text-base mt-2'
              >
                {errorText}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default ZenCommissionSplitContent;
