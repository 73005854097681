import { URL_VALIDATIONS } from '../../../utils/Validations';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenStickyBottomPagination from '../../Zen/ZenStickyBottomPagination';
import {
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName,
} from './ZenWelcomeOnboardingForm';
import withApplicactionFormProgress from './withWelcomeOnboardingFormProgress';

const SocialMediaLinksInfo: StepByStepComponent<
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName
> = ({ form: { control }, onNext, onPrevious }) => {
  return (
    <div className='flex flex-col justify-between w-full h-full mt-8 max-w-md'>
      <div className='text-zen-dark-9 space-y-8 mb-10'>
        <div className='space-y-0.5'>
          <div className='text-0.5xl font-medium font-zen-title text-zen-dark-9'>
            Social Media Links (optional)
          </div>
          <div className='text-zen-dark-7'>
            You will be able to add/update these information later on your
            profile
          </div>
          <div className='text-zen-dark-7'>
            These links will be added to your very own personal website later.
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <div className='text-zen-dark-7'>My Website</div>
          <div className='w-8/12'>
            <ZenControlledTextInput<
              WelcomeOnboardingFormState,
              'agentUpdateRequest.personalWebsiteURL'
            >
              name='agentUpdateRequest.personalWebsiteURL'
              placeholder='E.g. www.andrewgarfield.com'
              control={control}
              rules={URL_VALIDATIONS}
              shouldUnregister={false}
            />
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <div className='text-zen-dark-7'>www.facebook.com/</div>
          <div className='w-8/12'>
            <ZenControlledTextInput<
              WelcomeOnboardingFormState,
              'agentUpdateRequest.facebookURL'
            >
              name='agentUpdateRequest.facebookURL'
              placeholder='E.g. myfacebookname'
              control={control}
              shouldUnregister={false}
            />
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <div className='text-zen-dark-7'>www.instagram.com/</div>
          <div className='w-8/12'>
            <ZenControlledTextInput<
              WelcomeOnboardingFormState,
              'agentUpdateRequest.instagramURL'
            >
              name='agentUpdateRequest.instagramURL'
              placeholder='E.g. myinstagramname'
              control={control}
              shouldUnregister={false}
            />
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <div className='text-zen-dark-7'>www.twitter.com/</div>
          <div className='w-8/12'>
            <ZenControlledTextInput<
              WelcomeOnboardingFormState,
              'agentUpdateRequest.twitterURL'
            >
              name='agentUpdateRequest.twitterURL'
              placeholder='E.g. mytwitterhandle'
              control={control}
              shouldUnregister={false}
            />
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <div className='text-zen-dark-7'>www.youtube.com/</div>
          <div className='w-8/12'>
            <ZenControlledTextInput<
              WelcomeOnboardingFormState,
              'agentUpdateRequest.youtubeURL'
            >
              name='agentUpdateRequest.youtubeURL'
              placeholder='E.g. myyoutubename'
              control={control}
              shouldUnregister={false}
            />
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <div className='text-zen-dark-7'>www.zillow.com/</div>
          <div className='w-8/12'>
            <ZenControlledTextInput<
              WelcomeOnboardingFormState,
              'agentUpdateRequest.zillowURL'
            >
              name='agentUpdateRequest.zillowURL'
              placeholder='E.g. myzillowpage'
              control={control}
              shouldUnregister={false}
            />
          </div>
        </div>
      </div>
      <ZenStickyBottomPagination
        onPrevious={onPrevious}
        onNext={onNext}
        maxWidth='xl'
      />
    </div>
  );
};

export default withApplicactionFormProgress(SocialMediaLinksInfo);
