import EliteStatus from '../../../assets/img/wealthManagement/elite_status.svg';
import { ZenCustomPrimaryGradientText } from './ZenCustomPrimaryGradientText';

interface ZenEliteStatusHighlightProps {
  label: string;
  textToHighlight: string;
  highlightColor: string;
}

interface ZenEliteStockProps {
  amount: string | number;
  label: string;
}

interface ZenEliteStatusProps {
  highlightProps: ZenEliteStatusHighlightProps;
  stockProps: ZenEliteStockProps;
}

export const ZenEliteStatusHighlight = ({
  label,
  textToHighlight,
  highlightColor,
}: ZenEliteStatusHighlightProps) => {
  const parts = label.split(textToHighlight);

  return (
    <div className='text-black text-center text-2xl md:text-[40px] font-inter font-bold uppercase'>
      {parts[0]}
      <span style={{ color: highlightColor }}>{textToHighlight}</span>
      {parts[1] ?? ''}
    </div>
  );
};

export const ZenEliteStockImageBackground = () => {
  return (
    <>
      <div className='absolute top-10 w-[340px] md:w-[725.685px] h-[507.981px] rotate-[-177.377deg] shrink-0 opacity-30 [background:rgba(140,184,255,0.50)] blur-[50.1025276184082px] rounded-[10019.503px]' />
      <img src={EliteStatus} alt='elite_status' className='w-64 h-64' />
    </>
  );
};

export const ZenEliteStock = ({ amount, label }: ZenEliteStockProps) => {
  return (
    <div className='flex flex-col font-inter justify-center items-center relative'>
      <div className='font-bold flex items-center'>
        <ZenCustomPrimaryGradientText className='text-6xl md:text-[107px] leading-[140%]'>
          $
        </ZenCustomPrimaryGradientText>
        <ZenCustomPrimaryGradientText className='text-8xl md:text-[170px] leading-[92%] tracking-[-6.756px]'>
          {amount}
        </ZenCustomPrimaryGradientText>
      </div>
      <div className='text-center text-black text-4xl md:text-6xl font-medium leading-[100%]'>
        {label}
      </div>
    </div>
  );
};

const ZenEliteStatus = ({
  highlightProps,
  stockProps,
}: ZenEliteStatusProps) => {
  return (
    <div className='pb-4 md:pb-10 pt-36 mt-10'>
      <div className='flex flex-col justify-center items-center relative gap-4 md:gap-10'>
        <ZenEliteStockImageBackground />
        <ZenEliteStatusHighlight {...highlightProps} />
        <ZenEliteStock {...stockProps} />
      </div>
    </div>
  );
};

export default ZenEliteStatus;
