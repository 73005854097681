import { cn } from '../../utils/classUtils';

interface BannerCalloutProps {
  title?: string;
  imageSource?: string;
  imageIcon?: React.ReactElement;
  subtitle?: string;
  actionText?: string;
  onActionPress?(): void;
  onPress?(): void;
  actionIcon?: React.ReactElement;
  AdditionalComponent?: React.ReactElement;
}

const BannerCallOut: React.FC<BannerCalloutProps> = ({
  title,
  imageSource,
  imageIcon,
  subtitle,
  onActionPress,
  onPress,
  actionText,
  actionIcon,
  AdditionalComponent,
}) => {
  return (
    <button onClick={onPress} className='focus:outline-none shadow-lg rounded'>
      <div className='rounded'>
        <div className='grid grid-cols-3 w-full'>
          <div className='col-span-1 bg-gray-200 h-full flex content-center rounded-l-md'>
            {imageSource ? (
              <img
                src={imageSource}
                className='object-cover rounded-l'
                alt=''
              />
            ) : (
              imageIcon && (
                <div className='flex flex-grow justify-center items-center py-10'>
                  {imageIcon}
                </div>
              )
            )}
          </div>
          <div
            className={cn(
              'col-span-2 flex-grow flex-shrink py-3 px-2 text-left bg-white rounded-r-md flex flex-col',
              {
                'justify-center': !actionText && !AdditionalComponent,
                'justify-between': !subtitle && !AdditionalComponent,
              },
            )}
          >
            {!!title && (
              <p className='font-primary-medium text-xl flex-wrap leading-5 mb-1'>
                {title}
              </p>
            )}
            {subtitle && (
              <p className='font-primary-thin text-sm flex-wrap mb-1'>
                {subtitle}
              </p>
            )}
            {actionText && (
              <button className='flex flex-row' onClick={onActionPress}>
                <p className='font-primary-medium text-primary-blue flex-wrap'>
                  {actionText}
                </p>
                {actionIcon}
              </button>
            )}
            {AdditionalComponent}
          </div>
        </div>
      </div>
    </button>
  );
};

export default BannerCallOut;
