import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { JV_BOOK_MEETING_LINK } from '../../constants/JointVentureConstants';
import ZenButton from '../Zen/ZenButton';

interface LearnMoreFooterProps {
  issuePrice?: number;
  onSignUp(): void;
}

const LearnMoreFooter: React.FC<LearnMoreFooterProps> = ({
  issuePrice,
  onSignUp,
}) => {
  return (
    <div className='sticky w-full bottom-0 z-0 bg-white border-zen-dark-5 px-5 py-5'>
      <div className='w-full flex-col'>
        <div className='w-full flex justify-between pb-1 border-b border-zen-dark-5'>
          <div className='flex-grow'>
            <p className='font-zen-title text-lg text-dark'>Signup Fee</p>
          </div>
          <div className=''>
            <p className='font-zen-body-medium text-lg text-dark'>
              ${issuePrice!}
            </p>
          </div>
        </div>
        <div className='w-full flex justify-end pt-5'>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='w-40'>
              <ZenButton
                variant='primary-outline'
                label='Call Me'
                LeftIconComponent={
                  <FontAwesomeIcon icon={faPhone} size='1x' className='mr-1' />
                }
                onClick={() => window.open(JV_BOOK_MEETING_LINK, '_blank')}
                isFullWidth
              />
            </div>
            <div className='w-40'>
              <ZenButton
                variant='primary'
                label='Sign Up'
                onClick={onSignUp}
                isFullWidth
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnMoreFooter;
