import { useLocation } from 'react-router-dom';
import { ZenTabsVariant } from '../../../types';
import { ZenTab } from './ZenRouterTabs';
import ZenRouterTabItem from './ZenRouterTabItem';

interface ZenRouterTabsInInHeaderProps {
  tabs: ZenTab[];
  variant?: ZenTabsVariant;
}

const ZenRouterTabsSeparateInHeader = ({
  tabs,
}: ZenRouterTabsInInHeaderProps) => {
  const location = useLocation();
  return (
    <div className='flex flex-col border-b'>
      <div className='mx-4 flex flex-row items-center flex-nowrap space-x-1 overflow-y-auto h-12'>
        {tabs.map(
          ({ label, badgeComponent, path: tabPath, exact, leftLabelIcon }) => {
            const isActive = location.pathname === tabPath;

            return (
              <div className='px-2' key={label}>
                <ZenRouterTabItem
                  label={label}
                  badgeComponent={badgeComponent}
                  path={tabPath}
                  exact={exact}
                  // variant={variant}
                  isActive={isActive}
                  leftLabelIcon={leftLabelIcon}
                />
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};

export default ZenRouterTabsSeparateInHeader;
