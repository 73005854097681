import { useParams } from 'react-router-dom';
import ZenCommissionDocuments from '../components/transactions/CommissionDocuments/ZenCommissionDocuments';

type Match = {
  id: string;
};

const TransactionTradeRecordSheetsRoute: React.FC = () => {
  const { id } = useParams() as Match;

  return (
    <ZenCommissionDocuments
      transactionId={id}
      headerName='Trade Record Sheets'
      isTradeRecordSheet
    />
  );
};

export default TransactionTradeRecordSheetsRoute;
