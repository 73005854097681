import { faSquarePlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form-v7';
import { FieldObject } from '../../../openapi/atlantis';
import { cn } from '../../../utils/classUtils';
import ZenFormErrorMessage from '../../Zen/Input/ZenFormErrorMessage';
import ZenButton from '../../Zen/ZenButton';
import MortgageApplicationConditionalFormInput from './MortgageApplicationConditionalFormInput';
import useFieldArrayValidation from './useFieldArrayValidation';

interface ConfigurationDrivenFormFieldArrayProps {
  fieldArray: FieldObject;
  onSubmit: () => void;
  isReadOnly: boolean;
}

const MortgageApplicationFormFieldArrayInput: React.FC<ConfigurationDrivenFormFieldArrayProps> = ({
  fieldArray,
  onSubmit,
  isReadOnly,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldArray.id!,
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }
  }, [append, fields.length]);

  useFieldArrayValidation(fieldArray);

  const fieldArrayErrors =
    (errors.fieldArrayErrors?.message as string)?.split(', ') || [];

  return (
    <>
      {fields.map((field, index) => {
        return (
          <div
            key={field.id}
            className={cn('border border-gray-2 p-3 rounded relative', {
              'col-start-1': index === 0,
            })}
          >
            {/* @ts-ignore */}
            {fieldArray.fields?.map((input: FieldObject) => {
              const name = `${fieldArray.id}.${index}.${input.id}`;

              return (
                <div key={input.id} className='mb-5'>
                  <MortgageApplicationConditionalFormInput
                    name={name}
                    field={input}
                    onSubmit={onSubmit}
                    isReadOnly={isReadOnly}
                  />
                </div>
              );
            })}
            {!isReadOnly && (
              <FontAwesomeIcon
                icon={faTrash}
                fontSize={16}
                className='text-zen-danger absolute top-2 right-2 bg-white cursor-pointer'
                onClick={() => remove(index)}
              />
            )}
          </div>
        );
      })}
      {!isEmpty(fieldArrayErrors) && (
        <div className='col-span-3 mt-1'>
          {fieldArrayErrors.map((message) => (
            <ZenFormErrorMessage key={message} message={message} />
          ))}
        </div>
      )}
      <div className='flex flex-column justify-center items-center mt-2 col-span-3'>
        <ZenButton
          label={fieldArray.label!}
          variant='secondary-gray-outline'
          textSize='sm'
          onClick={() => append({})}
          isDisabled={isReadOnly}
          LeftIconComponent={
            <FontAwesomeIcon
              icon={faSquarePlus}
              size='sm'
              className='text-primary-blue'
            />
          }
        />
      </div>
    </>
  );
};

export default MortgageApplicationFormFieldArrayInput;
