import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSupportModal } from '../../slices/SupportSlice';
import { RootState } from '../../types';

import SupportModal from './SupportModal';

interface SupportWidgetProps {}

const SupportWidget: React.FC<SupportWidgetProps> = () => {
  const dispatch = useDispatch();
  const { supportModal } = useSelector((state: RootState) => state.support);
  return (
    <>
      <button
        className='rounded-full bg-blue-600 p-3 focus:outline-none shadow-lg'
        onClick={() => dispatch(toggleSupportModal(!supportModal))}
      >
        {supportModal ? (
          <FontAwesomeIcon icon={faXmark} className='text-white' size='2xl' />
        ) : (
          <FontAwesomeIcon
            icon={faCircleQuestion}
            className='text-white'
            size='2xl'
          />
        )}
      </button>

      <SupportModal isOpen={supportModal} />
    </>
  );
};

export default SupportWidget;
