import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResourceContainer from '../components/ResourceContainer';
import TitleProgressContact from '../components/TitleProgress/TitleProgressContact';
import TitleProgressEmpty from '../components/TitleProgress/TitleProgressEmpty';
import TitleProgressMilestones from '../components/TitleProgress/TitleProgressMilestones';
import TitleProgressWireInstructions from '../components/TitleProgress/TitleProgressWireInstructions';
import ZenRoute from '../components/Zen/ZenRoute';
import { AddressStateEnum, TitleProgressStateEnum } from '../openapi/arrakis';
import {
  fetchTitleProgress,
  saveTitleProgress,
} from '../slices/TransactionSlice';
import { AppDispatch, RootState } from '../types';
import { getPageTitleForTransaction } from '../utils/TransactionUtils';

interface TransactionTitleProgressRouteProps {}

const TransactionTitleProgressRoute: React.FC<TransactionTitleProgressRouteProps> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
      titleProgress,
      loadingTitleProgress,
    },
  } = useSelector((state: RootState) => state);
  useEffect(() => {
    dispatch(fetchTitleProgress(transactionDetail?.id!, true));

    return () => {
      dispatch(saveTitleProgress(undefined));
    };
  }, [dispatch, transactionDetail?.id]);

  const isCaliforniaTransaction =
    transactionDetail?.address?.state === AddressStateEnum.California;

  return (
    <ZenRoute
      title={`${getPageTitleForTransaction(
        transactionDetail,
      )} Title Progress - ${transactionDetail?.address?.oneLine}`}
    >
      <div className='py-10 px-5'>
        <ResourceContainer
          loading={loadingTitleProgress}
          isEmpty={titleProgress?.state === TitleProgressStateEnum.InReview}
          resourceName='title'
          EmptyComponent={<TitleProgressEmpty />}
        >
          <div className='flex flex-col md:flex-row items-start space-y-10 md:space-y-0 md:space-x-5'>
            <TitleProgressMilestones
              milestones={titleProgress?.milestones}
              isCaliforniaTransaction={isCaliforniaTransaction}
            />
            <TitleProgressContact
              contact={titleProgress?.contact}
              isCaliforniaTransaction={isCaliforniaTransaction}
            />
            <TitleProgressWireInstructions
              wireInstructions={titleProgress?.wireInstructions}
            />
          </div>
        </ResourceContainer>
      </div>
    </ZenRoute>
  );
};

export default TransactionTitleProgressRoute;
