import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useAgentsInfo from '../../../hooks/useAgentsInfo';
import {
  TaxBreakdownItemResponseParticipantRoleEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { CardItem, RootState } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { isTransactionReadOnly } from '../../../utils/TransactionHelper';
import ZenAvatar from '../ZenAvatar';
import ZenCardWithItems from '../ZenCardWithItems';
import { NationalIdentificationValueTypeEnum } from '../../../openapi/yenta';
import ZenTransactionTaxExempt from './ZenTransactionTaxExempt';
import ZenTransactionTaxPaidFromCommission from './ZenTransactionTaxPaidFromCommission';
import ZenTransactionTaxRate from './ZenTransactionTaxRate';

export interface showType {
  type: NationalIdentificationValueTypeEnum;
  show: boolean;
}

interface ZenTaxInformationCardProps {
  transaction: TransactionResponse;
}

const ZenTaxInformationCard: React.FC<ZenTaxInformationCardProps> = ({
  transaction,
}) => {
  const {
    auth: { isAdmin, userDetail },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const taxInfo = transaction.taxInformation!;
  const breakdown = taxInfo?.taxBreakdown?.items?.filter(
    (o) => !!o.amount?.amount,
  );
  const readonly = isTransactionReadOnly(transaction, userDetail!) || !isAdmin;

  const agentIds = useMemo(
    () => breakdown?.map((p) => p.yentaId!).filter((p) => !!p) || [],
    [breakdown],
  );

  useAgentsInfo(agentIds);

  const items: CardItem[] = [
    {
      name: 'Tax exempt',
      value: (
        <ZenTransactionTaxExempt
          id={transaction.id!}
          taxExempt={taxInfo?.taxExempt!}
          isReadonly={readonly}
        />
      ),
    },
    {
      name: 'Tax Paid from Commission',
      value: (
        <ZenTransactionTaxPaidFromCommission
          id={transaction.id!}
          taxPaidFromCommission={taxInfo?.taxPaidFromCommission!}
          isReadonly={readonly}
        />
      ),
    },
  ];

  if (taxInfo?.taxExempt === false) {
    items.push(
      {
        name: 'Tax Rate',
        value: (
          <ZenTransactionTaxRate
            transactionId={transaction.id!}
            taxes={transaction.taxes}
            readonly={readonly}
          />
        ),
      },
      {
        name: 'Tax Amount',
        value: <span>{displayAmount(taxInfo?.taxAmount)}</span>,
      },
    );
  }

  return (
    <ZenCardWithItems title='Tax Information' items={items}>
      {!isEmpty(breakdown) && (
        <div className='border-t px-4'>
          <div
            className='flex justify-between items-center mt-3 cursor-pointer'
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <p className='font-zen-body font-normal text-base leading-5 text-zen-dark-7'>
              Tax Breakdown
            </p>

            <FontAwesomeIcon
              icon={isExpanded ? faChevronUp : faChevronDown}
              className='text-zen-dark-8 text-sm'
            />
          </div>
          <div className='py-2'>
            {isExpanded && (
              <div className='flex flex-col gap-3 my-2'>
                {(breakdown || []).map((breakdown) => {
                  const fullName =
                    breakdown.participantRole ===
                    TaxBreakdownItemResponseParticipantRoleEnum.Real
                      ? 'Real Broker Holds and Remits'
                      : `${breakdown?.participantFirstName!} ${breakdown?.participantLastName!}`;

                  return (
                    <div
                      className='flex justify-between'
                      key={breakdown.participantId}
                    >
                      <div className='text-sm font-semibold font-zen-body flex flex-row items-center gap-2'>
                        <ZenAvatar
                          name={fullName}
                          size='xs'
                          imageUrl={agentById[breakdown?.yentaId!]?.avatar}
                        />
                        <span>{fullName}</span>
                      </div>
                      <div className='text-sm text-zen-dark-8 font-zen-body'>
                        {displayAmount(breakdown.amount)}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </ZenCardWithItems>
  );
};

export default ZenTaxInformationCard;
