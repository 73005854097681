import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Control } from 'react-hook-form-v7';
import { MAX_DOC_SIZE_100MB } from '../../../../constants/FilesConstants';
import ZenControlledDraggableDocumentUploadInput from '../../Input/ZenControlledDraggableDocumentUploadInput';
import { SplitPDFFormData } from './ZenSplitAndAttachPDF';

interface ZenUploadPDFProps {
  control: Control<SplitPDFFormData>;
  onBack: () => void;
}

const ZenUploadPDF: React.FC<ZenUploadPDFProps> = ({ control, onBack }) => {
  return (
    <div className='h-screen w-screen flex flex-col items-center justify-center relative'>
      <FontAwesomeIcon
        icon={faXmark}
        className='absolute right-3 top-3 cursor-pointer text-zen-dark-9'
        title='close'
        size='lg'
        onClick={onBack}
      />
      <div className='w-full max-w-2xl'>
        <p className='text-center text-lg mb-2 text-zen-dark-9 font-zen-body font-medium'>
          Upload PDF to Split & Attach it to a Checklist Item
        </p>
        <ZenControlledDraggableDocumentUploadInput<SplitPDFFormData, 'pdfFile'>
          variant='primary'
          name='pdfFile'
          accept='application/pdf'
          customPlaceHolder={
            <p className='text-center text-base font-zen-body text-primary-blue'>
              Drag & Drop PDF <br /> or <br /> Click here
            </p>
          }
          control={control}
          shouldUnregister={false}
          multiple
          maxUploadSize={MAX_DOC_SIZE_100MB}
          rules={{
            required: 'Please upload PDF',
            validate: (chosenFiles) =>
              !chosenFiles?.every((file) => file.type === 'application/pdf')
                ? 'Please choose PDF files only.'
                : undefined,
          }}
        />
      </div>
    </div>
  );
};

export default ZenUploadPDF;
