import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError, AppThunk, ErrorState } from '../types';
import { isAPICommonsError } from '../utils/TypeUtils';

export const initialState: ErrorState = {
  visible: false,
  errorData: undefined,
};

const ErrorSlice = createSlice({
  name: 'errorSlice',
  initialState,
  reducers: {
    showErrorModal(state, action: PayloadAction<ApiError>) {
      state.visible = true;
      state.errorData = action.payload;
    },
    closeErrorModal(state) {
      state.visible = false;
      state.errorData = undefined;
    },
  },
});

export const { closeErrorModal, showErrorModal } = ErrorSlice.actions;

export const showApiErrorModal = (error: any): AppThunk => async (dispatch) => {
  if (error.response) {
    let errorDetails: ApiError = isAPICommonsError(error.response.data)
      ? error.response.data['com.real.commons.apierror.ApiError']
      : error.response.data[Object.keys(error.response.data)[0]];

    if (!errorDetails) {
      errorDetails = error.response;
    }

    dispatch(showErrorModal(errorDetails));
  }
};

export const closeApiErrorModal = (): AppThunk => async (dispatch) => {
  dispatch(closeErrorModal());
};

export default ErrorSlice.reducer;
