import { faHandshakeAltSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiKeyResponse } from '../../../openapi/keymaker';
import { isAgentClientApiKeyExpired } from '../../../utils/AgentHelper';
import { cn } from '../../../utils/classUtils';
import {
  getFormattedExpiryTime,
  getFormattedRevokeTime,
} from '../../../utils/DateUtils';
import GeminiFeatureFlagButton from '../../GeminiFeatureFlagButton';
import ZenCardWithItems from '../../Zen/ZenCardWithItems';
import AgentClientApiKeyField from './AgentClientApiKeyField';

interface AgentClientApiKeyTabsProps {
  currentTab: string;
  handleOpenRevokeApiModal(apiKey: ApiKeyResponse): void;
  cardData: ApiKeyResponse;
}

const AgentClientApiKeyTabs: React.FC<AgentClientApiKeyTabsProps> = ({
  currentTab,
  handleOpenRevokeApiModal,
  cardData,
}) => {
  const getCardItems = (apiKeyData: ApiKeyResponse) => {
    let itemsData = [
      {
        name: 'Key',
        value: '',
        rightActionComponent: (
          <AgentClientApiKeyField apiKey={apiKeyData?.value!} />
        ),
      },
      {
        name: 'Expiration Date',
        value: '',
        rightActionComponent: (
          <span
            className={cn(
              'font-primary-regular',
              isAgentClientApiKeyExpired(apiKeyData?.expirationDate || '')
                ? 'text-zen-danger'
                : 'text-zen-dark-9',
            )}
          >
            {getFormattedExpiryTime(apiKeyData?.expirationDate || '')}
          </span>
        ),
      },
    ];
    if (currentTab === 'Active') {
      itemsData.push({
        name: '',
        value: '',
        rightActionComponent: (
          <div className='my-3'>
            <GeminiFeatureFlagButton
              label='Revoke Key'
              type='button'
              zenVariant='danger-link'
              geminiVariant='danger-link'
              LeftIconComponent={
                <FontAwesomeIcon
                  icon={faHandshakeAltSlash}
                  size='xs'
                  className='mr-1'
                />
              }
              leftIcon={faHandshakeAltSlash}
              onClick={() => handleOpenRevokeApiModal(apiKeyData)}
            />
          </div>
        ),
      });
    }
    if (currentTab === 'Revoked') {
      itemsData.push({
        name: 'Revocation Date',
        value: '',
        rightActionComponent: (
          <span className='font-primary-regular text-zen-dark-9'>
            {getFormattedRevokeTime(apiKeyData?.revokedAt || 0)}
          </span>
        ),
      });
    }
    return itemsData;
  };

  return (
    <ZenCardWithItems
      variant='regular'
      items={getCardItems(cardData)}
      title={cardData?.name}
    />
  );
};

export default AgentClientApiKeyTabs;
