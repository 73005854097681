import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as EventEmptyIcon } from '../../../assets/img/eventEmptyState.svg';
import {
  fetchEventDetail,
  saveEventDetail,
} from '../../../slices/CalendarSlice';
import { RootState } from '../../../types';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { EventTabEnum, tabToEmptyStateMap } from '../../../utils/EventHelper';
import DefaultLoader from '../../DefaultLoader';
import ResourceContainer from '../../ResourceContainer';
import ZenEventDetailsSidebarModal from './ZenEventDetailsSidebarModal';
import ZenEvents from './ZenEvents';

interface Params {
  eventId?: string;
}

export interface ZenEventsTabComponentProps {
  tab: EventTabEnum;
  fetchEvents: (tab: EventTabEnum, calendarIds?: string[]) => void;
  calendarIds: string[];
  search: string;
}

const ZenEventsTabComponent: React.FC<ZenEventsTabComponentProps> = ({
  tab,
  fetchEvents,
  calendarIds,
  search,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { eventId } = useParams<Params>();
  const {
    calendar: { eventsByTab, eventDetail },
  } = useSelector((state: RootState) => state);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const filterEvents = (eventsByTab[tab] || []).filter((event) =>
    event.title?.toLowerCase().includes(search.toLowerCase()),
  );

  const emptyState = safeEnumMapGet(
    tabToEmptyStateMap,
    tab,
    tabToEmptyStateMap.YES,
  );

  const handleFetchEventDetail = useCallback(async () => {
    if (eventId) {
      await dispatch(fetchEventDetail(eventId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchEvents = useCallback(async () => {
    setIsFetching(true);
    await fetchEvents(tab, calendarIds);
    await handleFetchEventDetail();
    setIsFetching(false);
  }, [calendarIds, fetchEvents, handleFetchEventDetail, tab]);

  useEffect(() => {
    handleFetchEvents();
  }, [handleFetchEvents]);

  return (
    <div>
      <ResourceContainer
        loading={isFetching}
        isEmpty={!filterEvents?.length}
        LoaderComponent={
          <div className='flex items-center justify-center h-screen'>
            <DefaultLoader />
          </div>
        }
        resourceName='event'
        EmptyComponent={
          <div className='flex flex-col items-center justify-center py-10'>
            <EventEmptyIcon aria-label='empty-events' />
            <p className='font-zen-body font-semibold text-2xl text-zen-dark-7 text-center py-4'>
              {emptyState?.title}
            </p>
            <p className='font-zen-body font-normal text-base text-zen-dark-7 text-center max-w-3xl'>
              {emptyState?.subtitle}
            </p>
          </div>
        }
      >
        <ZenEvents
          events={filterEvents}
          onOpen={(event) => dispatch(saveEventDetail(event))}
        />
      </ResourceContainer>
      {!!eventDetail && (
        <ZenEventDetailsSidebarModal
          isOpen={!!eventDetail}
          onClose={() => {
            dispatch(saveEventDetail(undefined));
            history.replace(
              `/my-events?${qs.stringify({
                calendarIds,
              })}`,
            );
          }}
          event={eventDetail}
          tab={tab}
          calendarIds={calendarIds}
        />
      )}
    </div>
  );
};

export default ZenEventsTabComponent;
