import React from 'react';
import { displayTime, isToday } from '../../../../utils/DateUtils';

interface UserChatBubbleProps {
  text: string;
  sentTime: string;
}

const UserChatBubble: React.FC<UserChatBubbleProps> = ({ text, sentTime }) => (
  <div className='flex flex-row justify-end'>
    <div className='mb-6 lg:w-3/5 sm:w-2/3 flex flex-col items-end'>
      <div
        className='rounded-md py-1.5 px-3 w-fit min-w-[110px] relative'
        style={{
          background:
            'linear-gradient(58.78deg, #3B82F6 -8.45%, #05C3F9 108.33%)',
        }}
      >
        <p className='text-white font-inter font-normal text-base word-break'>
          {text}
        </p>
      </div>
      <div className='flex flex-row items-center mt-1'>
        <div className='w-full'>
          <p className='text-zen-dark-6 leading-5 text-right text-xs font-inter font-normal'>
            {`
              ${isToday(sentTime) ? 'Today ' : ''}
              ${displayTime(sentTime)}
            `}
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default UserChatBubble;
