import { CancelToken } from 'axios';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import useAgentsInfoFromKeymakerId from '../../../hooks/useAgentInfosFromKeymakerId';
import {
  ActivityControllerApi,
  ActivityResponse,
} from '../../../openapi/memento';
import { IPaginateReq, RootState } from '../../../types';
import { getFullName } from '../../../utils/AgentHelper';
import { getMementoConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ActivityTypeDisplayCell from '../../table/Cells/ActivityTypeDisplayCell';
import ZenResourceIndexContainer from '../Containers/ZenResourceIndexContainer';
import ZenMilliDateTimeCell from '../Table/Cell/ZenMilliDateTimeCell';
import ZenAvatar from '../ZenAvatar';
import ZenRoute from '../ZenRoute';

interface ZenAgentActivityProps {
  agentId: string;
}

const ZenAgentActivity: React.FC<ZenAgentActivityProps> = ({ agentId }) => {
  const [activities, setActivities] = useState<ActivityResponse[]>([]);

  const { agentByKeymakerId } = useSelector(
    (state: RootState) => state.userIds,
  );

  const columns: Array<Column<ActivityResponse>> = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'createdBy',
        Cell: ({ value }) => {
          const agentInfo = agentByKeymakerId[value!];
          const agentName = getFullName(agentInfo) || 'N/A';

          return (
            <div className='flex items-center space-x-1.5 font-zen-body text-zen-dark-9 font-light'>
              <ZenAvatar name={agentName} size='sm' />
              <p>{agentName}</p>
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Type',
        accessor: 'activityType',
        Cell: ({ value }) => <ActivityTypeDisplayCell type={value!} />,
        disableSortBy: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value }) => (
          <p className='flex items-center space-x-1.5 font-zen-body text-zen-dark-9 font-light'>
            {value}
          </p>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Date & Time',
        accessor: 'createdAt',
        Cell: ({ value }) => (
          <p className='flex items-center space-x-1.5 font-zen-title text-zen-dark-6 font-normal'>
            <ZenMilliDateTimeCell date={value} />
          </p>
        ),
        disableSortBy: true,
      },
    ];
  }, [agentByKeymakerId]);

  const fetchAgentActivities = useCallback(
    async (
      req: IPaginateReq<ActivityResponse>,
      cancelToken?: CancelToken | undefined,
    ) => {
      const { data } = await new ActivityControllerApi(
        getMementoConfiguration(),
      ).getActivities(agentId, req.page, req.pageSize, { cancelToken });

      setActivities(data.activities || []);

      return {
        data: data.activities || [],
        total: data.totalCount || 0,
      };
    },
    [agentId],
  );

  const agentIds = useMemo(
    () =>
      activities
        ?.filter((activity) => !activity.createdByExternal)
        .map((act) => act.createdBy!) || [],
    [activities],
  );

  useAgentsInfoFromKeymakerId(agentIds);

  const agentName = useSelector(
    (state: RootState) => state.agentDetail.detailResponse.data?.fullName,
  );

  return (
    <ZenRoute title={`Activity - ${agentName}`}>
      <div className='px-2 py-4'>
        <ZenResourceIndexContainer<ActivityResponse>
          columns={columns}
          resourceName='activity'
          fetchData={fetchAgentActivities}
          hideFilters
        />
      </div>
    </ZenRoute>
  );
};

export default ZenAgentActivity;
