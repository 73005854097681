import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { handleLogout } from '../slices/AuthSlice';

export const useLogout = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return async () => {
    queryClient.clear();
    await dispatch(handleLogout());
  };
};
