import React from 'react';
import { cn } from '../../../utils/classUtils';
import ZenButton, { ZenButtonType } from '../ZenButton';

interface ZenSidebarModalActionFooterV2Props {
  submitButtonText?: string;
  cancelButtonText?: string;
  onClose(): void;
  isSubmitting: boolean;
  isDisabled?: boolean;
  onClick?(): Promise<void>;
  buttonType?: ZenButtonType;
  SubmitButtonLeftIconComponent?: React.ReactElement;
  SubmitButtonRightIconComponent?: React.ReactElement;
  isEqualWidth?: boolean;
}

const ZenSidebarModalActionFooterV2: React.FC<ZenSidebarModalActionFooterV2Props> = ({
  submitButtonText = 'Save',
  cancelButtonText = 'Cancel',
  onClose,
  isSubmitting,
  isDisabled,
  onClick,
  buttonType = 'submit',
  SubmitButtonLeftIconComponent,
  SubmitButtonRightIconComponent,
  isEqualWidth = false,
}) => {
  return (
    <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 left-0 right-0 px-3 absolute py-2'>
      <div
        className={cn(
          'flex flex-row ml-auto justify-start items-center space-x-5',
          isEqualWidth ? 'w-full' : 'w-4/6',
        )}
      >
        <ZenButton
          type='button'
          onClick={onClose}
          label={cancelButtonText}
          variant='primary-outline'
          isFullWidth
        />
        <ZenButton
          isDisabled={isDisabled}
          isSubmitting={isSubmitting}
          type={buttonType}
          label={submitButtonText}
          onClick={onClick}
          isFullWidth
          LeftIconComponent={SubmitButtonLeftIconComponent}
          RightIconComponent={SubmitButtonRightIconComponent}
        />
      </div>
    </div>
  );
};

export default ZenSidebarModalActionFooterV2;
