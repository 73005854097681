interface PrimaryGradientTextProps {
  children: React.ReactNode;
}

export const PrimaryGradientText = ({ children }: PrimaryGradientTextProps) => {
  return (
    <span className='bg-gradient-to-r from-primary-blue to-blue-400 bg-clip-text text-transparent'>
      {children}
    </span>
  );
};
