import Slider from 'rc-slider';

export interface IncrementalSliderProps {
  value: number;
  minValue: number;
  maxValue: number;
  step: number;
  onChange(value: number): void;
  disabled?: boolean;
  minValueLabel?: string;
  maxValueLabel?: string;
}

const IncrementalSlider: React.FC<IncrementalSliderProps> = ({
  value,
  minValue,
  maxValue,
  step,
  onChange,
  minValueLabel,
  maxValueLabel,
  disabled,
}) => {
  return (
    <div className='mx-5'>
      <Slider
        defaultValue={value}
        min={minValue}
        max={maxValue}
        step={step}
        onChange={onChange}
        disabled={disabled}
        style={{ backgroundColor: 'transparent' }}
        trackStyle={{
          backgroundColor: disabled ? '#D6D6D6' : '#05C3F9',
          height: 12,
          borderRadius: 15,
          opacity: 0.8,
        }}
        handleStyle={{
          borderColor: disabled ? '#D6D6D6' : '#05C3F9',
          top: 1,
          left: 8,
          height: 30,
          width: 30,
          backgroundColor: disabled ? '#FFF' : '#05C3F9',
          boxShadow:
            '0px 4px 2px rgba(1, 62, 80, 0.12), 0px 2px 2px rgba(1, 62, 80, 0.24)',
        }}
        railStyle={{
          backgroundColor: disabled ? '#D6D6D6' : '#05C3F9',
          opacity: 0.2,
          height: 12,
          borderRadius: 10,
        }}
      />
      <div className='flex flex-row justify-between mt-5'>
        <p>{minValueLabel}</p>
        <p>{maxValueLabel}</p>
      </div>
    </div>
  );
};

export default IncrementalSlider;
