import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { useParams } from 'react-router-dom';
import {
  EquityReleaseResponse,
  TaxDocumentLineItemResponseTypeEnum,
} from '../../openapi/arrakis';
import { getYearlyTaxDocumentDetails } from '../../slices/TaxSlice';
import { RootState } from '../../types';
import { displayAmount, zeroMoneyValue } from '../../utils/CurrencyUtils';
import EmptyTray from '../../assets/img/EmptyTray.png';
import AuthorizationContainer from '../auth/AuthorizationContainer';
import ZenRoute from '../Zen/ZenRoute';
import ZenFixedDataTable from '../Zen/ZenFixedDataTable';
import ZenResourceContainer from '../Zen/ZenResourceContainer';
import ZenDateCell from '../Zen/Table/Cell/ZenDateCell';
import { AgentResponseDefaultCurrencyEnum } from '../../openapi/yenta';

type Match = {
  id: string;
  year: string;
};

export const ZenTaxEquityMatch: React.FC = () => {
  const {
    taxDoc: { taxDocumentsDetailResponse },
    agentDetail: {
      detailResponse: { data: detail },
    },
  } = useSelector((state: RootState) => state);

  const { id, year } = useParams() as Match;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getYearlyTaxDocumentDetails(id, +year));
  }, [dispatch, id, year]);

  const dynamicEquityPaymentsColumns: Array<Column<EquityReleaseResponse>> = [
    {
      Header: 'Shareworks Grant Number',
      accessor: 'grantId',
      Cell: ({ value }) => <p className='font-zen-body font-normal'>{value}</p>,
      disableFilters: true,
    },
    {
      Header: 'Grant Date',
      accessor: 'grantDate',
      Cell: ({ value }) => (
        <div className='font-zen-body font-normal'>
          <ZenDateCell date={value} />
        </div>
      ),
      disableFilters: true,
    },
    {
      Header: 'Number of Shares',
      accessor: 'numberOfShares',
      Cell: ({ value }) => <p className='font-zen-body font-normal'>{value}</p>,
      disableFilters: true,
    },
    {
      Header: 'Release Price',
      accessor: 'releasePrice',
      Cell: ({ value }) => (
        <p className='font-zen-body font-normal'>{displayAmount(value)}</p>
      ),
      disableFilters: true,
    },
    {
      Header: 'Release Amount',
      accessor: 'releaseAmount',
      Cell: ({ value }) => (
        <p className='font-zen-body font-normal'>{displayAmount(value)}</p>
      ),
      disableFilters: true,
    },
    {
      Header: 'Release Date',
      accessor: 'releaseDate',
      Cell: ({ value }) => (
        <div className='font-zen-body font-normal'>
          <ZenDateCell date={value} />
        </div>
      ),
      disableFilters: true,
    },
  ];

  if (detail?.defaultCurrency === AgentResponseDefaultCurrencyEnum.Cad) {
    dynamicEquityPaymentsColumns.splice(5, 0, {
      Header: 'Release Amount(CAD)',
      accessor: 'releaseAmountInAgentCurrency',
      Cell: ({ value }) => (
        <p className='font-zen-body font-normal'>{displayAmount(value)}</p>
      ),
      disableFilters: true,
    });
  }

  // GrantType (just for , GrantDate, grantId, release date, release amount, number of shares
  const equityMatchPayments = taxDocumentsDetailResponse.data
    ?.lineItems!.filter(
      (item) => item.type === TaxDocumentLineItemResponseTypeEnum.EquityMatch,
    )
    .map((item) => item.equityRelease as EquityReleaseResponse);

  return (
    <ZenRoute title='Equity Match'>
      <div className='p-4'>
        <div className='space-y-4'>
          <p className='font-zen-body font-medium text-xl text-zen-dark-9'>
            {year} Equity Match:{' '}
            {displayAmount(
              taxDocumentsDetailResponse.data?.releasedEquityMatch ||
                zeroMoneyValue(detail?.defaultCurrency!),
            )}
          </p>

          <AuthorizationContainer asyncResponse={taxDocumentsDetailResponse}>
            <ZenResourceContainer
              loading={taxDocumentsDetailResponse.loading}
              isEmpty={false}
              resourceName='equity match payments'
            >
              <ZenRoute title='Equity Match'>
                <div className='pt-4 space-y-2'>
                  <p>
                    Equity Match Payments ({equityMatchPayments?.length || 0})
                  </p>
                  <ZenFixedDataTable<EquityReleaseResponse>
                    columns={dynamicEquityPaymentsColumns}
                    data={equityMatchPayments || []}
                    resourceName='Equity Match Payment'
                    hidePagination
                    hideFilters
                    emptyIconComponent={
                      <img
                        src={EmptyTray}
                        alt='EmptyTrayImage'
                        className='h-20'
                      />
                    }
                  />
                </div>
              </ZenRoute>
            </ZenResourceContainer>
          </AuthorizationContainer>
        </div>
      </div>
    </ZenRoute>
  );
};
