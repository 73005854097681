import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import {
  AgentResponse,
  AgentResponseAgentStatusEnum,
} from '../../openapi/yenta';
import { isSmScreen } from '../../utils/BrowserUtils';
import { agentsTableFetchData } from '../../utils/TableUtils';
import ZenResourceIndexContainer from '../Zen/Containers/ZenResourceIndexContainer';
import AgentStatusCell from '../table/Cells/AgentStatusCell';
import CountryCell from '../table/Cells/CountryCell';
import DateCell from '../table/Cells/DateCell';
import MilliDateCell from '../table/Cells/MilliDateCell';
import PhoneNumberCell from '../table/Cells/PhoneNumberCell';
import ProfileImageCell from '../table/Cells/ProfileImageCell';
import CountrySelectColumnFilter from '../table/Filters/CountrySelectColumnFilter';
import TextColumnFilter from '../table/Filters/TextColumnFilter';
import TextStrictCaseColumnFilter from '../table/Filters/TextStrictCaseColumnFilter';

interface SuperAdminAgentResourceTableProps {
  search?: string;
}

export interface AgentResponseModified extends AgentResponse {
  status?: AgentResponseAgentStatusEnum;
}

export const columns: Column<AgentResponseModified>[] = [
  {
    Header: 'Photo',
    accessor: 'avatar',
    Cell: ({ value, row }) => (
      <Link to={`/people/${row.original.id}`}>
        <ProfileImageCell
          name={`${row.original.firstName} ${row.original.lastName}`}
          imageUrl={value}
        />
      </Link>
    ),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    Cell: ({ row }) => (
      <Link to={`/people/${row.original.id}`}>{row.original.firstName}</Link>
    ),
    Filter: TextColumnFilter,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    Cell: ({ row }) => (
      <Link to={`/people/${row.original.id}`}>{row.original.lastName}</Link>
    ),
    Filter: TextColumnFilter,
  },
  {
    Header: 'Email',
    accessor: 'emailAddress',
    Cell: ({ row }) => (
      <Link to={`/people/${row.original.id}`}>{row.original.emailAddress}</Link>
    ),
    Filter: TextColumnFilter,
  },
  {
    Header: 'Phone',
    accessor: 'phoneNumber',
    Cell: ({ value, row }) => (
      <Link to={`/people/${row.original.id}`}>
        <PhoneNumberCell phoneNumber={value} />
      </Link>
    ),
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Anniv. date',
    accessor: 'anniversaryDate',
    Cell: ({ value, row }) => (
      <Link to={`/people/${row.original.id}`}>
        {value ? <DateCell date={value} /> : 'N/A'}
      </Link>
    ),
    disableFilters: true,
  },
  {
    Header: 'Country',
    accessor: 'accountCountry',
    Cell: ({ value, row }) => (
      <Link to={`/people/${row.original.id}`}>
        <CountryCell country={value} />
      </Link>
    ),
    Filter: CountrySelectColumnFilter,
    cardColSize: 5,
    disableSortBy: true,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value, row }) => (
      <Link to={`/people/${row.original.id}`}>
        {value ? <MilliDateCell date={value} /> : 'N/A'}
      </Link>
    ),
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value, row }) => (
      <Link to={`/people/${row.original.id}`}>
        {value ? (
          <AgentStatusCell status={AgentResponseAgentStatusEnum.Active} />
        ) : (
          'N/A'
        )}
      </Link>
    ),
    disableFilters: true,
    cardColSize: 6,
  },
  {
    Header: 'ID',
    accessor: 'id',
    disableSortBy: true,
    Filter: TextStrictCaseColumnFilter,
  },
];

const SuperAdminAgentResourceTable: React.FC<SuperAdminAgentResourceTableProps> = ({
  search,
}) => {
  return (
    <div className='px-4 lg:pt-5'>
      <ZenResourceIndexContainer<AgentResponseModified>
        header='People'
        columns={columns}
        search={search}
        initialSort={{ lastName: 'asc' }}
        fetchData={(req, cancelToken) =>
          agentsTableFetchData(
            req,
            undefined,
            undefined,
            undefined,
            undefined,
            {
              cancelToken,
            },
          )
        }
        resourceName='people'
        showSearch={isSmScreen()}
        stickyHeader
      />
    </div>
  );
};

export default SuperAdminAgentResourceTable;
