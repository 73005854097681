import React from 'react';
import { useSelector } from 'react-redux';
import MortgageApplicationContainer from '../components/Mortgage/MortgageApplication/MortgageApplicationContainer';
import MortgageScrollToTop from '../components/Mortgage/MortgageScrollToTop';
import MortgageSidebar from '../components/Mortgage/MortgageSidebar';
import ZenRoute from '../components/Zen/ZenRoute';
import { RootState } from '../types';
import { getFullName } from '../utils/AgentHelper';
import { getBorrower } from '../utils/MortgageUtils';

interface MortgageApplicationRouteProps {
  borrowerId: string;
}

const MortgageApplicationRoute: React.FC<MortgageApplicationRouteProps> = ({
  borrowerId,
}) => {
  const {
    loanResponse: { data: loan },
  } = useSelector((state: RootState) => state.mortgage);
  const borrower = getBorrower(borrowerId, loan?.borrowers || [])!;

  return (
    <ZenRoute title={`Mortgage Application - ${getFullName(borrower)}`}>
      <div className='-mx-4 flex flex-col lg:flex-row'>
        <MortgageSidebar borrower={borrower} />
        <div className='w-full'>
          <MortgageApplicationContainer borrower={borrower} />
        </div>
        <MortgageScrollToTop />
      </div>
    </ZenRoute>
  );
};

export default MortgageApplicationRoute;
