import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CommissionDocumentControllerApi } from '../../openapi/arrakis';
import ErrorService from '../../services/ErrorService';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import { AppDispatch } from '../../types';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';

interface ZenActivateCommissionDocumentConfirmationModalProps {
  isOpen: boolean;
  onClose(fetch: boolean): void;
  commissionDocumentId: string | undefined;
  isTradeRecordSheet?: boolean;
}

const ZenActivateCommissionDocumentConfirmationModal: React.FC<ZenActivateCommissionDocumentConfirmationModalProps> = ({
  isOpen,
  onClose,
  commissionDocumentId,
  isTradeRecordSheet = false,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onConfirm = async () => {
    setIsSubmitting(true);
    try {
      await new CommissionDocumentControllerApi(
        getArrakisConfiguration(),
      ).setCommissionDocumentAsApproved(commissionDocumentId!);
      dispatch(
        showSuccessToast(
          `${
            isTradeRecordSheet ? 'Trade Record Sheet' : 'Commission Document'
          } activated successfully.`,
        ),
      );
    } catch (e) {
      ErrorService.notify('Unable to activate commission document', e, {
        commissionDocument: {
          id: commissionDocumentId,
        },
      });
      dispatch(
        showErrorToastForErrorCode(
          `We were unable to activate the ${
            isTradeRecordSheet ? 'trade record sheet' : 'commission document'
          }`,
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setIsSubmitting(false);
    }
    onClose(true);
  };

  return (
    <ZenConfirmationModal
      variant='primary'
      isOpen={isOpen}
      onClose={() => onClose(false)}
      title={`Are you sure you want to activate this ${
        isTradeRecordSheet ? 'trade record sheet' : 'commission document'
      }?`}
      cancelButtonText='Cancel'
      confirmButtonText='Confirm'
      onConfirm={onConfirm}
      isSubmitting={isSubmitting}
    />
  );
};

export default ZenActivateCommissionDocumentConfirmationModal;
