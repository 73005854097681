import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import ZenSidebarModalActionFooter from '../components/SidebarModal/ZenSideBarModalActionFooter';
import ZenSidebarModal from '../components/Zen/ZenSidebarModal';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showSuccessToast,
  showErrorToastForErrorCode,
} from '../slices/ToastNotificationSlice';
import { AppDispatch } from '../types';
import ZenControlledDraggableDocumentUploadInput from '../components/Zen/Input/ZenControlledDraggableDocumentUploadInput';
import { ReactComponent as NewFile } from '../assets/img/file-new.svg';
import { MAX_DOC_SIZE_100MB } from '../constants/FilesConstants';
import { ShareworksControllerApi } from '../openapi/arrakis';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';

interface FormData {
  file: File[];
}

interface ZenShareWorksReleasesSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

const ZenShareWorksReleasesSidebarModal: React.FC<ZenShareWorksReleasesSidebarModalProps> = ({
  onClose,
  isOpen,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const [file] = watch(['file']);
  const currentFile = file?.[0];

  const onSubmit = async (formValues: FormData) => {
    try {
      await new ShareworksControllerApi(
        getArrakisConfiguration(),
      ).processReleaseFile(formValues?.file[0]);

      dispatch(
        showSuccessToast('Successfully uploaded shareworks releases file'),
      );
      onClose();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to upload shareworks releases file', e, {
        values: formValues,
      });
      dispatch(
        showErrorToastForErrorCode(
          'We encountered an error while trying to upload shareworks releases file. Please try again in a few moments.',
          ErrorService.getErrorCode(e),
        ),
      );
    }
  };

  return (
    <ZenSidebarModal
      title='Upload Shareworks Releases File'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4'>
          <div>
            <ZenControlledDraggableDocumentUploadInput<FormData, 'file'>
              name='file'
              label='Select Shareworks Releases File'
              control={control}
              height='small'
              customPlaceHolder={
                <div className='flex md:flex-row flex-col justify-center items-center px-4'>
                  <NewFile className='w-8 h-8' />
                  {!!currentFile?.name ? (
                    <span className='font-zen-body font-semibold text-sm text-primary-blue ml-2 text-center'>
                      {currentFile.name}
                    </span>
                  ) : (
                    <>
                      <span className='font-zen-body font-semibold text-sm text-primary-blue ml-2'>
                        Browse Files
                      </span>
                      <p className='font-zen-body text-sm font-semibold text-zen-dark-7 ml-1'>
                        or drag & drop new file
                      </p>
                    </>
                  )}
                </div>
              }
              multiple={false}
              accept='.csv'
              maxUploadSize={MAX_DOC_SIZE_100MB}
              isUploading={file && isSubmitting}
              rules={{
                required: 'Please upload Shareworks Releases File',
                validate: (chosenFiles) =>
                  !chosenFiles?.every((file) => file.type === 'text/csv')
                    ? 'Please choose CSV files only.'
                    : undefined,
              }}
            />
          </div>
        </div>

        <ZenSidebarModalActionFooter
          onClose={onClose}
          isSubmitting={isSubmitting}
          submitButtonDisabled={isSubmitting}
          submitButtonText='Upload'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenShareWorksReleasesSidebarModal;
