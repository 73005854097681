import { useState } from 'react';
import { UseFormTrigger } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAnnouncement,
  saveEditAnnouncementId,
  saveShowEditModal,
} from '../../../slices/AnnouncementSlice';
import { RootState } from '../../../types';
import {
  getAnnouncementDefaultValues,
  getCreateAnnouncementRequest,
} from '../../../utils/AnnouncementUtils';
import DefaultLoader from '../../DefaultLoader';
import ZenButton from '../ZenButton';
import { CreateAnnouncementFormData } from './CreateAnnouncementSidebarModel';

interface AnnouncementPaginationProps {
  submitButtonText?: string;
  LeftIconComponent?: React.ReactElement;
  isSubmitting?: boolean;
  onNext(): void;
  trigger: UseFormTrigger<CreateAnnouncementFormData>;
  formValues: Partial<CreateAnnouncementFormData>;
}
const AnnouncementPagination: React.FC<AnnouncementPaginationProps> = ({
  submitButtonText = 'Next',
  LeftIconComponent,
  onNext,
  formValues,
  trigger,
  isSubmitting = false,
}) => {
  const dispatch = useDispatch();
  const { announcementDefinitionDetail, loading } = useSelector(
    (state: RootState) => state.announcement,
  );
  const [isSavingDraft, setIsSavingDraft] = useState<boolean>(false);

  const saveDraft = async () => {
    setIsSavingDraft(true);
    try {
      const isValid = await trigger();
      if (!isValid) {
        return;
      }
      const req = getCreateAnnouncementRequest({
        ...getAnnouncementDefaultValues(announcementDefinitionDetail),
        ...formValues,
      });
      await dispatch(createAnnouncement(req));
      await dispatch(saveShowEditModal(false));
    } catch {
    } finally {
      setIsSavingDraft(false);
    }
  };

  return (
    <div className='p-2 md:p-4 bg-white bottom-0 left-0 right-0 px-3 absolute py-2'>
      <div className='flex flex-row justify-between items-center'>
        {!announcementDefinitionDetail?.id && (
          <button
            onClick={saveDraft}
            className='flex justify-between items-center font-zen-body-medium text-base text-primary-blue px-4.5 py-2.5 hover:bg-primary-blue hover:bg-opacity-20 rounded-lg'
            disabled={isSavingDraft}
          >
            {isSavingDraft && (
              <div>
                <DefaultLoader iconSize='small' noPadding />
              </div>
            )}
            <p className='ml-2 flex-grow'>
              {isSavingDraft ? 'Saving' : 'Save'} as Draft
            </p>
          </button>
        )}
        <div className='w-4/6 ml-auto flex flex-row justify-end items-center space-x-5'>
          <ZenButton
            onClick={() => {
              dispatch(saveShowEditModal(false));
              dispatch(saveEditAnnouncementId(undefined));
            }}
            label='Cancel'
            variant='primary-outline'
            isFullWidth
          />
          <ZenButton
            isDisabled={isSubmitting || loading}
            isSubmitting={isSubmitting}
            label={submitButtonText}
            LeftIconComponent={LeftIconComponent}
            isFullWidth
            onClick={onNext}
          />
        </div>
      </div>
    </div>
  );
};

export default AnnouncementPagination;
