import React from 'react';
import { ReactComponent as CopyIcon } from '../../../../assets/img/gemini/copy-icon.svg';
import CopyToClipboardHover from '../../../CopyToClipboardHover';

interface GeminiTransactionCodeWithCopyProps {
  transactionCode?: string;
}

const GeminiTransactionCodeWithCopy: React.FC<GeminiTransactionCodeWithCopyProps> = ({
  transactionCode,
}) => {
  if (!transactionCode) return null;

  return (
    <div className='flex flex-row items-center p-1 pl-3 bg-regent-900 rounded-[7px] shrink-0 h-max w-max'>
      <p className='font-zen-body-2 text-sm font-medium text-white shrink-0'>
        {transactionCode}
      </p>
      <div className='h-6 w-6 flex items-center justify-center font-zen-body-2 text-sm'>
        <CopyToClipboardHover
          value={transactionCode}
          copyToltipLabel='Copy Code'
          copyIcon={<CopyIcon />}
        />
      </div>
    </div>
  );
};

export default GeminiTransactionCodeWithCopy;
