import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import Select, {
  components,
  ControlProps,
  GroupTypeBase,
  OptionTypeBase,
  Styles,
  ValueType,
} from 'react-select';
import { ISelectOption } from '../../../types';
import ZenFormErrorMessage from './ZenFormErrorMessage';

interface ZenControlledMultiSelectCustomInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  placeholder?: string;
  options: Array<ISelectOption>;
  placeholderLabel?: string;
  styles?: Partial<Styles<any, boolean, GroupTypeBase<any>>>;
  disabled?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  readOnly?: boolean;
  customClassName?: string;
  isSearchable?: boolean;
  closeMenuOnSelect?: boolean;
  isRequired?: boolean;
  customMultiValue(props: any): React.ReactElement;
  onChangeSpy?(value: ValueType<OptionTypeBase, true>): void;
}

const ZenControlledMultiSelectCustomInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  placeholder = 'Select',
  options,
  shouldUnregister = false,
  placeholderLabel,
  disabled = false,
  readOnly,
  styles,
  startAdornment,
  endAdornment,
  customClassName = '',
  closeMenuOnSelect = true,
  isSearchable = false,
  isRequired,
  customMultiValue,
  onChangeSpy,
  ...rest
}: ZenControlledMultiSelectCustomInputProps<TFieldValues, TName>) => {
  const Control = ({
    children,
    ...props
  }: ControlProps<ISelectOption, true>) => {
    return (
      <components.Control {...props}>
        {!!placeholderLabel && (
          <span className='px-2 font-zen-body font-medium'>
            {placeholderLabel}
          </span>
        )}
        {children}
      </components.Control>
    );
  };
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { error, invalid },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label
              className={classNames(
                'inline-block font-zen-body font-semibold',
                invalid ? 'text-zen-danger' : 'text-zen-dark-9',
              )}
              htmlFor={name}
            >
              {label}
              {isRequired && <span className='text-zen-danger'>*</span>}
            </label>
          )}
          <div
            className={classNames(
              'flex flex-row flex-grow items-center border rounded-md p-px font-zen-body',
              invalid
                ? 'border-zen-danger'
                : !isEmpty(value)
                ? 'border-zen-dark-9'
                : 'border-zen-dark-5',
              customClassName,
            )}
          >
            <div className='flex flex-row items-center flex-grow'>
              {startAdornment && <div>{startAdornment}</div>}
              <div className='w-full'>
                <Select<ISelectOption, true>
                  styles={styles}
                  isSearchable={isSearchable}
                  options={options}
                  placeholder={placeholder}
                  name={name}
                  components={{
                    Control,
                    MultiValue: (props) => customMultiValue(props),
                    IndicatorSeparator: () => null,
                    IndicatorsContainer: () =>
                      endAdornment ? (
                        <div
                          className={classNames(
                            'h-10 border-l pt-2',
                            invalid
                              ? 'border-zen-danger'
                              : !isEmpty(value)
                              ? 'border-zen-dark-9'
                              : 'border-zen-dark-5',
                          )}
                        >
                          {endAdornment}
                        </div>
                      ) : (
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className='text-zen-dark mx-3 text-base'
                        />
                      ),
                  }}
                  isMulti
                  value={value}
                  isDisabled={disabled || readOnly}
                  defaultValue={value}
                  onChange={(value) => {
                    onChange(value);
                    if (onChangeSpy) {
                      onChangeSpy(value);
                    }
                  }}
                  onBlur={onBlur}
                  closeMenuOnSelect={closeMenuOnSelect}
                  ref={ref}
                  className='react-select-container'
                  classNamePrefix='react-select-noborder'
                  inputId={name}
                />
              </div>
            </div>
          </div>
          {error && <ZenFormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenControlledMultiSelectCustomInput;
