import { omit } from 'lodash';
import {
  Address,
  AddressCountryEnum,
  AddressStateEnum,
} from '../openapi/arrakis';
import { AgentResponse, MoneyValueCurrencyEnum } from '../openapi/yenta';
import { ReferralTransactionFormState } from '../components/Zen/Transaction/ReferralTransaction/ZenReferralTransactionSteps';
import { isCanadianUser } from './AgentHelper';
import { capitalizeEnum, formatAddress, isStringPresent } from './StringUtils';
import { isTeamAdminOrTeamLeader } from './TeamHelper';

export const getCreateReferralTransactionDefaultFormValues = (
  userDetail: AgentResponse | null,
  isAdmin: boolean,
): ReferralTransactionFormState => ({
  externalAgentFirstName: '',
  externalAgentLastName: '',
  externalAgentEmailAddress: '',
  externalAgentBrokerage: '',
  clientFirstName: '',
  clientLastName: '',
  clientEmailAddress: undefined,
  isTeamLeadOrAdmin: isTeamAdminOrTeamLeader(userDetail!) || isAdmin,
  transactionOwnerName: {
    label: userDetail?.fullName!,
    value: userDetail?.id!,
  },
  price: {
    currency: (userDetail?.defaultCurrency as unknown) as MoneyValueCurrencyEnum,
  },
  expectedClosingDate: (undefined as unknown) as Date,
  address: {
    state: ('' as unknown) as AddressStateEnum,
    country: (userDetail?.accountCountry as unknown) as AddressCountryEnum,
  },
  paymentDateSent: undefined,
  paymentMethod: undefined,
  referenceNumber: undefined,
  senderFullName: undefined,
  comments: undefined,
});

export const formatReferralTransactionAddress = (address: Address) => {
  if (!address) {
    return '';
  }

  return formatAddress(
    address.street ?? '',
    address.street2 ?? undefined,
    address.city ?? '',
    capitalizeEnum(address?.state ?? ''),
    address.zip ?? '',
  );
};

export const isReferralTransactionAddressRequired = (
  userDetail: AgentResponse,
  address: Address,
) => {
  return (
    isCanadianUser(userDetail.accountCountry!) ||
    Object.values(omit(address || {}, ['country', 'valid'])).some((a) =>
      isStringPresent(a),
    )
  );
};
