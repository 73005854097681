import {
  agentCoverSetMap,
  agentWebsiteThemeColors,
} from '../../testUtils/AgentWebsiteOnboardingUtils';
import {
  CreateOrUpdateAgentWebsiteRequestPhotoSetEnum,
  CreateOrUpdateAgentWebsiteRequestThemeColorEnum,
} from '../../openapi/yenta';
import { cn } from '../../utils/classUtils';

interface AgentWebsiteThemePreviewProps {
  themeColor: CreateOrUpdateAgentWebsiteRequestThemeColorEnum;
  photoSet: CreateOrUpdateAgentWebsiteRequestPhotoSetEnum;
}

const AgentWebsiteThemePreview: React.FC<AgentWebsiteThemePreviewProps> = ({
  themeColor,
  photoSet,
}) => {
  return (
    <div className='w-full h-72 border border-gray-200 p-3'>
      <div className='relative w-full h-full'>
        <img
          src={agentCoverSetMap[photoSet].medium}
          alt='preview'
          className='w-full h-full bg-center bg-cover bg-no-repeat'
        />
        <div
          className='absolute h-16 rounded-full w-16 bg-white border-4 top-1/3 left-0 right-0 m-auto'
          style={{
            borderColor: agentWebsiteThemeColors[themeColor],
          }}
        >
          <span className='absolute font-primary-regular p-3 text-center text-xs'>
            Your Photo
          </span>
        </div>
        <div
          className='absolute right-2 top-2 px-2 py-1 rounded'
          style={{
            backgroundColor: agentWebsiteThemeColors[themeColor],
          }}
        >
          <span
            className={cn(
              'font-primary-regular uppercase text-xs tracking-widest',
              themeColor ===
                CreateOrUpdateAgentWebsiteRequestThemeColorEnum.Yellow
                ? 'text-black'
                : 'text-white',
            )}
          >
            Contact
          </span>
        </div>
      </div>
    </div>
  );
};

export default AgentWebsiteThemePreview;
