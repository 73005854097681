import { TableProps } from '../../containers/ResourceIndexContainer';
import GeminiResourceIndexContainer from './GeminiResourceIndexContainer';

interface GeminiFixedDataTableProps<D extends object>
  extends Omit<TableProps<D>, 'fetchData'> {}

const GeminiFixedDataTable = <D extends object>(
  props: GeminiFixedDataTableProps<D>,
) => {
  return (
    <GeminiResourceIndexContainer<D>
      {...props}
      standalone={props.standalone ?? false}
    />
  );
};

export default GeminiFixedDataTable;
