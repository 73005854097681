import React from 'react';

export interface GeminiInformationCardProps {
  title: string;
  titleIcon?: string;
}

const GeminiInformationCard: React.FC<GeminiInformationCardProps> = ({
  title,
  titleIcon,
  children,
}) => {
  return (
    <div className='rounded-[10px] border border-grey-200 overflow-hidden bg-white'>
      <div className='flex flex-row items-center font-medium bg-grey-100 h-[45px] font-inter leading-[22px] px-3 py-2.5 text-sm md:text-base text-primary-dark space-x-1'>
        {titleIcon && (
          <div className='md:block hidden'>
            <img src={titleIcon} alt={title} className='w-5 h-5 mb-1' />
          </div>
        )}
        <span>{title}</span>
      </div>
      {children}
    </div>
  );
};

export default GeminiInformationCard;
