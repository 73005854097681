import {
  faCalendarXmark,
  faEnvelope,
  faTag,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as CalenderNoteIcon } from '../../assets/img/zen/transactionHeader/calender-note.svg';
import { ReactComponent as CopyIcon } from '../../assets/img/zen/transactionHeader/copy.svg';
import { ReactComponent as DollarIcon } from '../../assets/img/zen/transactionHeader/dollar.svg';
import { ReactComponent as OfficeIcon } from '../../assets/img/zen/transactionHeader/office.svg';
import { ReactComponent as UserIcon } from '../../assets/img/zen/transactionHeader/user.svg';
import {
  ParticipantValueRoleEnum,
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../openapi/arrakis';
import { fetchDropbox } from '../../slices/DropboxSlice';
import { setActiveTransition } from '../../slices/QuickActionSlice';
import { getProcessTransaction } from '../../slices/TransactionSlice';
import { RootState } from '../../types';
import { displayFormattedAmountWithCurrency } from '../../utils/CurrencyUtils';
import { getParticipantName } from '../../utils/ParticipantHelper';
import {
  getAllTransactionParticipantsByRole,
  getTransactionTransitionToReadableName,
  isCanadaTransaction,
} from '../../utils/TransactionHelper';
import {
  getTransactionVariant,
  isPersonalDealTransaction,
} from '../../utils/TransactionUtils';
import CopyToClipboardHover from '../CopyToClipboardHover';
import ZenSimpleConfirmationModal from '../Zen/Modal/ZenSimpleConfirmationModal';
import ZenTransactionCheckType from '../Zen/Transaction/Header/ZenTransactionCheckType';
import ZenTransactionDealTypeLabel from '../Zen/Transaction/Header/ZenTransactionDealTypeLabel';
import ZenTransactionHeaderRowCell from '../Zen/Transaction/Header/ZenTransactionHeaderRowCell';
import ZenTransactionHeaderStatus from '../Zen/Transaction/Header/ZenTransactionHeaderStatus';
import ZenTransactionPersonalDealLabel from '../Zen/Transaction/Header/ZenTransactionPersonalDealLabel';
import ZenTransitionConfirmationModal from '../Zen/Transaction/Header/ZenTransitionConfirmationModal';

interface ZenListingHeaderProps {
  transaction: TransactionResponse;
}

const ZenListingHeader: React.FC<ZenListingHeaderProps> = ({ transaction }) => {
  const dispatch = useDispatch();
  const {
    quickAction: { activeTransition },
    dropbox: { dropboxById },
  } = useSelector((state: RootState) => state);
  const [
    retryTransition,
    setRetryTransition,
  ] = useState<TransactionLifecycleStateValueStateEnum>();

  const allSellers = getAllTransactionParticipantsByRole(
    transaction,
    ParticipantValueRoleEnum.Seller,
  );
  const allLandlords = getAllTransactionParticipantsByRole(
    transaction,
    ParticipantValueRoleEnum.Landlord,
  );

  const allSellersAndLandlordsNames = [
    ...allSellers,
    ...allLandlords,
  ].map((participant) => getParticipantName(participant));

  const dropboxId = transaction?.dropboxId!;
  const dropbox = dropboxById[dropboxId];

  useEffect(() => {
    if (!!dropboxId && dropbox?.id !== dropboxId) {
      dispatch(fetchDropbox(dropboxId));
    }
  }, [dispatch, dropbox?.id, dropboxId]);

  return (
    <header className='px-4 md:px-8 py-6 flex flex-col md:flex-row items-start justify-between md:gap-x-4 bg-zen-dark-13 text-white mx-4 my-1 rounded-[15px] scrollbar overflow-x-auto overflow-auto space-y-4 md:space-y-0'>
      <div className='flex flex-col md:w-2/3'>
        <div className='text-2xl font-primary-medium'>
          <p className='font-zen-body font-bold text-[22px] leading-7 text-white'>
            {transaction.address?.oneLine}
          </p>
          <div className='flex flex-col md:flex-row items-start md:items-center space-x-0 md:space-x-2 space-y-2 md:space-y-0 my-3'>
            <div className='flex flex-row items-center space-x-1'>
              <FontAwesomeIcon icon={faTag} size='xs' className='text-white' />
              <p className='font-zen-body font-normal text-xl text-white min-w-max'>
                {displayFormattedAmountWithCurrency(transaction.price)}
              </p>
            </div>
            <ZenTransactionDealTypeLabel
              dealType={transaction?.transactionType!}
            />
            {isPersonalDealTransaction(transaction) && (
              <ZenTransactionPersonalDealLabel />
            )}
            <div className='flex flex-row items-center bg-zen-gray-6 rounded-[7px] px-2'>
              <p className='font-zen-body font-bold text-sm text-center text-white min-w-max'>{`Code: ${transaction?.code!}`}</p>
              <div className='font-zen-body font-normal text-sm text-white'>
                <CopyToClipboardHover
                  value={transaction?.code!}
                  copyToltipLabel='Copy Code'
                  copyIcon={
                    <CopyIcon width={24} height={24} className='text-white' />
                  }
                />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 md:gap-x-8 gap-y-4 md:gap-y-0 mt-4 md:mt-8'>
            <div className='flex flex-col gap-y-4'>
              <ZenTransactionHeaderRowCell
                icon={
                  <OfficeIcon width={24} height={24} className='text-white' />
                }
                content={
                  <div className='flex flex-row items-center space-x-2'>
                    <Link to={`/offices/${transaction.office?.id}`}>
                      <p className='flex flex-grow font-zen-body font-semibold text-sm text-white'>
                        {transaction.office?.name}
                      </p>
                    </Link>
                    <ZenTransactionCheckType transaction={transaction} />
                  </div>
                }
              />
              {transaction.listingTransaction?.price && (
                <ZenTransactionHeaderRowCell
                  icon={
                    <DollarIcon width={24} height={24} className='text-white' />
                  }
                  content={
                    <div className='flex flex-row items-center space-x-2'>
                      <p className='font-zen-body font-semibold text-sm text-white'>
                        List Price:
                      </p>
                      <p className='font-zen-body font-normal text-sm text-white'>
                        {displayFormattedAmountWithCurrency(
                          transaction.listingTransaction?.price,
                        )}
                      </p>
                    </div>
                  }
                />
              )}

              <ZenTransactionHeaderRowCell
                icon={
                  <DollarIcon width={24} height={24} className='text-white' />
                }
                content={
                  <div className='flex flex-row items-center space-x-2'>
                    <p className='font-zen-body font-semibold text-sm text-white'>
                      Commission:
                    </p>
                    <p className='font-zen-body font-normal text-sm text-white space-x-0.5'>
                      <span>{transaction.grossCommissionPercentage}</span>
                      <span>|</span>
                      <span>
                        {displayFormattedAmountWithCurrency(
                          transaction.grossCommission,
                        )}
                      </span>
                    </p>
                  </div>
                }
              />
              <ZenTransactionHeaderRowCell
                icon={
                  <UserIcon width={24} height={24} className='text-white' />
                }
                content={
                  <div className='flex flex-row items-center space-x-2'>
                    <p className='font-zen-body font-semibold text-sm text-white'>
                      Seller/Landlord:
                    </p>
                    <p className='font-zen-body font-normal text-sm text-white space-x-0.5'>
                      {allSellersAndLandlordsNames?.length > 0
                        ? allSellersAndLandlordsNames.join(', ')
                        : 'N/A'}
                    </p>
                  </div>
                }
              />
              <ZenTransactionHeaderRowCell
                icon={
                  <UserIcon width={24} height={24} className='text-white' />
                }
                content={
                  <div className='flex flex-row items-center space-x-2'>
                    <p className='font-zen-body font-semibold text-sm text-white'>
                      Transaction Owner:
                    </p>
                    <p className='font-zen-body font-normal text-sm text-white space-x-0.5'>
                      {transaction?.transactionOwner?.name || 'N/A'}
                    </p>
                  </div>
                }
              />
            </div>
            <div className='flex flex-col gap-y-4'>
              {!isCanadaTransaction(transaction!) && (
                <ZenTransactionHeaderRowCell
                  icon={
                    <CalenderNoteIcon
                      width={24}
                      height={24}
                      className='text-white'
                    />
                  }
                  content={
                    <div className='flex flex-row items-center space-x-2'>
                      <p className='font-zen-body font-semibold text-sm text-white'>
                        Year Built:
                      </p>
                      <p className='font-zen-body font-normal text-sm text-white'>
                        {transaction.yearBuilt ? transaction.yearBuilt : 'N/A'}
                      </p>
                    </div>
                  }
                />
              )}

              <ZenTransactionHeaderRowCell
                icon={
                  <CalenderNoteIcon
                    width={24}
                    height={24}
                    className='text-white'
                  />
                }
                content={
                  <div className='flex flex-row items-center space-x-2'>
                    <p className='font-zen-body font-semibold text-sm text-white'>
                      Listing Start Date:
                    </p>
                    <p className='font-zen-body font-normal text-sm text-white'>
                      {transaction.listingDate
                        ? DateTime.fromISO(transaction.listingDate).toFormat(
                            'MM/dd/yyyy',
                          )
                        : 'N/A'}
                    </p>
                  </div>
                }
              />

              <ZenTransactionHeaderRowCell
                icon={
                  <div className='w-6 h-6 flex items-center justify-center'>
                    <FontAwesomeIcon
                      icon={faCalendarXmark}
                      className='text-white'
                      fontSize={14}
                    />
                  </div>
                }
                content={
                  <div className='flex flex-row items-center space-x-2'>
                    <p className='font-zen-body font-semibold text-sm text-white'>
                      Listing Expiration Date:
                    </p>
                    <p className='font-zen-body font-normal text-sm text-white'>
                      {transaction.listingExpirationDate
                        ? DateTime.fromISO(
                            transaction.listingExpirationDate,
                          ).toFormat('MM/dd/yyyy')
                        : 'N/A'}
                    </p>
                  </div>
                }
              />

              {!!dropbox?.emailAddress && (
                <ZenTransactionHeaderRowCell
                  icon={
                    <div className='w-6 h-6 flex items-center justify-center'>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className='text-white'
                        fontSize={14}
                      />
                    </div>
                  }
                  content={
                    <div className='flex flex-row items-center flex-wrap'>
                      <p className='font-zen-body font-semibold text-sm text-white whitespace-nowrap mr-2'>
                        Listing Email:
                      </p>
                      <div className='flex-grow flex items-center justify-start'>
                        <a
                          href={`mailto:${dropbox.emailAddress}`}
                          className='font-zen-body font-normal text-sm text-white space-x-0.5 whitespace-nowrap'
                        >
                          {dropbox.emailAddress}
                        </a>
                        <div className='font-zen-body font-normal text-sm text-white'>
                          <CopyToClipboardHover
                            value={dropbox.emailAddress}
                            copyToltipLabel='Copy Listing Email'
                            copyIcon={
                              <CopyIcon
                                width={24}
                                height={24}
                                className='text-white'
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='md:w-1/3'>
        <ZenTransactionHeaderStatus
          setRetryTransition={setRetryTransition}
          transaction={transaction}
        />
      </div>
      <ZenSimpleConfirmationModal
        isOpen={!!retryTransition}
        variant='info'
        title={`Retry ${getTransactionTransitionToReadableName(
          transaction,
          retryTransition!,
        )}?`}
        subtitle={`Are you sure you'd like to reprocess this transaction?`}
        onClose={() => {
          setRetryTransition(undefined);
        }}
        onConfirm={async () => {
          await dispatch(getProcessTransaction(transaction.id!));
          setRetryTransition(undefined);
        }}
        confirmButtonText='Reprocess'
        isSubmitting={false}
      />
      <ZenTransitionConfirmationModal
        isOpen={!!activeTransition}
        onClose={() => dispatch(setActiveTransition(undefined))}
        transaction={transaction}
        activeTransition={activeTransition}
        variant={getTransactionVariant(activeTransition!)}
      />
    </header>
  );
};

export default ZenListingHeader;
