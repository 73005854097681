import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface ZenAgentFileNameCellProps {
  name: string;
  labels: string[];
}

const ZenAgentFileNameCell: React.FC<ZenAgentFileNameCellProps> = ({
  name,
  labels,
}) => {
  return (
    <div className='flex flex-col'>
      <p className='zen-font-body text-base text-zen-dark-9'>{name}</p>
      {!!labels?.length && (
        <p className='zen-font-body text-sm text-zen-dark-9 break-words'>
          {!!labels &&
            labels.map((label) => (
              <div
                className='font-zen-body text-sm text-zen-dark-6 pr-2.5 inline-flex items-center'
                key={label}
              >
                <FontAwesomeIcon
                  icon={light('tag')}
                  className='text-zen-dark-6 mr-1'
                />
                {label}
              </div>
            ))}
        </p>
      )}
    </div>
  );
};

export default ZenAgentFileNameCell;
