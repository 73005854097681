import { get } from 'lodash';
import { DeepMap, FieldError } from 'react-hook-form-v6';
import { MoneyValue } from '../openapi/yenta';
import { displayAmount } from './CurrencyUtils';

export const calculateCommissionSplitErrors = (
  totalCommission: MoneyValue,
  totalPercentages: number,
  totalFixedAmountAllocated: number,
  formErrors: DeepMap<Record<string, any>, FieldError>,
  isZeroCommissionDeal: boolean,
  isPercentageTypeSplitAvailable: boolean,
): string[] => {
  let errors: string[] = [];

  // handle validation for zero commission if percentage field is present
  if (
    isZeroCommissionDeal &&
    isPercentageTypeSplitAvailable &&
    totalPercentages !== 100
  ) {
    errors.push('The total commission percentages must add up to 100%');
  }

  if (
    totalFixedAmountAllocated < totalCommission.amount! &&
    totalPercentages !== 100
  ) {
    // You've only allocated fixed dollar amounts.
    if (totalPercentages === 0) {
      errors.push(
        `The total commission amounts must add up to ${displayAmount(
          totalCommission,
        )}`,
      );
    } else {
      errors.push('The total commission percentages must add up to 100%');
    }
  }

  if (
    totalCommission.amount! !== 0 &&
    totalFixedAmountAllocated === totalCommission.amount! &&
    totalPercentages !== 0
  ) {
    errors.push(
      'You have already allocated the total commission in fixed amounts. Please set all percentages to 0.',
    );
  }

  if (totalFixedAmountAllocated > totalCommission.amount!) {
    errors.push('You cannot allocate more than the total commission.');
  }

  if (hasMissingRequiredCommissionSplitFields(formErrors)) {
    errors.push('Please make sure you have filled in all of the fields');
  }

  if (hasMoneyValidationErrors(formErrors)) {
    errors.push('Please enter a valid monetary amount.');
  }

  if (hasPercentageValidationErrors(formErrors)) {
    errors.push('Percentage should be between 0 and 100.');
  }

  return errors;
};

export const hasMoneyValidationErrors = (
  formErrors: DeepMap<Record<string, any>, FieldError>,
): boolean => {
  const amountFieldsRequired: any[] | undefined = get(
    formErrors,
    'commissionParticipant',
  )?.filter((p: any) => p.money?.amount?.type === 'pattern');

  return !!amountFieldsRequired?.length;
};

export const hasPercentageValidationErrors = (
  formErrors: DeepMap<Record<string, any>, FieldError>,
): boolean => {
  const amountFieldsRequired: any[] | undefined = get(
    formErrors,
    'commissionParticipant',
  )?.filter((p: any) => p?.percent?.value?.type === 'pattern');

  return !!amountFieldsRequired?.length;
};

export const hasMissingRequiredCommissionSplitFields = (
  formErrors: DeepMap<Record<string, any>, FieldError>,
): boolean => {
  const percentageFieldsRequired: any[] | undefined = get(
    formErrors,
    'commissionParticipant',
  )?.filter((p: any) => p.percent?.value?.type === 'validate');

  const amountFieldsRequired: any[] | undefined = get(
    formErrors,
    'commissionParticipant',
  )?.filter((p: any) => p.percent?.value?.type === 'validate');

  return !!percentageFieldsRequired?.length || !!amountFieldsRequired?.length;
};
