import React, { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSelector } from 'react-redux';
import CountUp from 'react-countup';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { displayAmount } from '../../utils/CurrencyUtils';
import { RootState } from '../../types';
import { MoneyValueCurrencyEnum } from '../../openapi/bff';
import { cn } from '../../utils/classUtils';

const RevShareInsightsPaidEarnings: React.FC = () => {
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);

  const {
    revenue: { previousPayments },
    agentDetail,
  } = useSelector((state: RootState) => state);

  const isPaidEarningEmpty = !agentDetail.revShareOverview?.paidEarnings
    ?.amount;

  const prevPayment = previousPayments?.payments?.[0]?.amount?.amount || 0;
  const prevToPrevPayment =
    previousPayments?.payments?.[1]?.amount?.amount || 0;
  const isDiffPositive = prevPayment - prevToPrevPayment >= 0;

  const currency = useMemo(
    () =>
      previousPayments?.payments?.[0]?.amount?.currency ||
      ((agentDetail.detailResponse.data
        ?.defaultCurrency as unknown) as MoneyValueCurrencyEnum),
    [
      agentDetail.detailResponse.data?.defaultCurrency,
      previousPayments?.payments,
    ],
  );

  const formatLastMonthEarnings = useCallback(
    (value: number) => {
      return displayAmount(
        {
          amount: value,
          currency: currency,
        },
        { hideZeroCents: true },
      );
    },
    [currency],
  );

  return (
    <div
      className='font-zen-body space-y-1 px-4 ld:px-6 py-4 border border-zen-dark-4 rounded-[10px]'
      aria-label='paid-earnings'
    >
      <p className='text-zen-dark-7'>
        Paid earnings
        <Popover
          isOpen={isPopOverOpen}
          positions={['bottom', 'left', 'right', 'top']}
          padding={5}
          align='center'
          onClickOutside={() => setIsPopOverOpen(false)}
          content={({ position, childRect, popoverRect }) => {
            return (
              <ArrowContainer
                position={position}
                popoverRect={popoverRect}
                childRect={childRect}
                arrowColor='white'
                arrowSize={10}
                className='popover-arrow-container drop-shadow-lg'
              >
                <div className='bg-white py-3 px-4 rounded-md font-zen-body text-sm text-zen-dark-9'>
                  Current anniversary year
                </div>
              </ArrowContainer>
            );
          }}
        >
          <FontAwesomeIcon
            icon={faCircleInfo}
            className='text-zen-dark-9 ml-1 cursor-pointer'
            onClick={() => setIsPopOverOpen(true)}
          />
        </Popover>
      </p>
      <p className='text-xl text-zen-dark-9 font-semibold'>
        {displayAmount(agentDetail.revShareOverview?.paidEarnings, {
          hideZeroCents: true,
        })}
      </p>
      {isPaidEarningEmpty ? (
        <p className='font-zen-body text-zen-dark-12'>No Earnings</p>
      ) : (
        <p
          className='flex flex-row flex-wrap space-x-1'
          aria-label='last-month'
        >
          <span className='text-zen-dark-9'>Last month</span>
          <span
            className={cn(
              'font-medium',
              isDiffPositive ? 'text-green-600' : 'text-zen-danger',
            )}
          >
            {isDiffPositive ? '+' : '-'}
            <CountUp
              start={0}
              end={Math.abs(prevPayment - prevToPrevPayment)}
              duration={1.5}
              formattingFn={formatLastMonthEarnings}
            />
            <FontAwesomeIcon
              icon={solid('circle-caret-up')}
              className={
                isDiffPositive
                  ? 'ml-1 text-green-600'
                  : 'ml-1 text-zen-danger transform rotate-180'
              }
            />
          </span>
        </p>
      )}
    </div>
  );
};

export default RevShareInsightsPaidEarnings;
