import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WealthManagementParticipationType } from '../components/WeathManagement/UnlockEarningModal';
import {
  Earning,
  EarningType,
} from '../components/WeathManagement/WealthManagementTypes';
import {
  setSelectedEarningsOverviewType,
  setWealthKnowledgeLandingPage,
} from '../slices/WealthManagementSlice';
import { RootState } from '../types';

const useEarningOnEditClick = () => {
  const dispatch = useDispatch();

  const { earningsOverviewResponse, wealthGoalsResponse } = useSelector(
    (state: RootState) => state.wealthManagement,
  );

  const [
    showParticipationModal,
    setShowParticipationModal,
  ] = useState<WealthManagementParticipationType>();
  const [selectedEarning, setSelectedEarning] = useState<EarningType | null>(
    null,
  );

  const handleDispatch = (earning: EarningType) => () => {
    dispatch(setSelectedEarningsOverviewType(earning));
  };

  const onSppEarningClick = (earning: EarningType) => {
    if (
      !wealthGoalsResponse.data?.goalDetails.sppParticipation &&
      earningsOverviewResponse.data?.earningsToDate.preCapSppShares === 0
    ) {
      setSelectedEarning(earning);
      setShowParticipationModal('sppParticipation');
      return;
    }
    dispatch(setSelectedEarningsOverviewType(earning));
  };

  const onEliteAgentProductionAwardClick = () => {
    if (
      !wealthGoalsResponse.data?.goalDetails.seenEliteProductionPage &&
      !wealthGoalsResponse.data?.goalDetails
        .eliteAgentProductionAwardParticipation
    ) {
      dispatch(setWealthKnowledgeLandingPage(Earning.ELITE_PRODUCTION_AWARD));
      return;
    }
    if (
      !wealthGoalsResponse.data?.goalDetails
        .eliteAgentProductionAwardParticipation
    ) {
      setSelectedEarning(Earning.ELITE_PRODUCTION_AWARD);
      setShowParticipationModal('eliteAgentProductionAwardParticipation');
      return;
    }
    dispatch(setSelectedEarningsOverviewType(Earning.ELITE_PRODUCTION_AWARD));
  };

  const onEliteAgentCulturalAwardClick = () => {
    if (
      !wealthGoalsResponse.data?.goalDetails.seenEliteCulturalPage &&
      !wealthGoalsResponse.data?.goalDetails
        .eliteAgentCulturalAwardParticipation
    ) {
      dispatch(setWealthKnowledgeLandingPage(Earning.ELITE_CULTURAL_AWARD));
      return;
    }
    if (
      !wealthGoalsResponse.data?.goalDetails
        .eliteAgentCulturalAwardParticipation
    ) {
      setSelectedEarning(Earning.ELITE_CULTURAL_AWARD);
      setShowParticipationModal('eliteAgentCulturalAwardParticipation');
      return;
    }
    dispatch(setSelectedEarningsOverviewType(Earning.ELITE_CULTURAL_AWARD));
  };

  const onRevenueShareIncomeClick = (earning: EarningType) => {
    if (!wealthGoalsResponse.data?.goalDetails.revShareParticipation) {
      setSelectedEarning(earning);
      setShowParticipationModal('revShareParticipation');
      return;
    }
    dispatch(setSelectedEarningsOverviewType(earning));
  };

  const onClickEnumMap: Record<EarningType, () => void> = {
    COMMISSION_INCOME: handleDispatch('COMMISSION_INCOME'),
    PRE_CAP_SPP: () => onSppEarningClick(Earning.PRE_CAP_SPP),
    STOCK_AWARD_FOR_CAPPING: handleDispatch('STOCK_AWARD_FOR_CAPPING'),
    POST_CAP_SPP: () => onSppEarningClick(Earning.POST_CAP_SPP),
    ELITE_PRODUCTION_AWARD: onEliteAgentProductionAwardClick,
    ELITE_CULTURAL_AWARD: onEliteAgentCulturalAwardClick,
    REVENUE_SHARE_INCOME: () =>
      onRevenueShareIncomeClick(Earning.REVENUE_SHARE_INCOME),
    STOCK_AWARD_FOR_ATTRACTING: () =>
      onRevenueShareIncomeClick(Earning.STOCK_AWARD_FOR_ATTRACTING),
  };

  return {
    onClickEnumMap,
    showParticipationModal,
    selectedEarning,
    setShowParticipationModal,
    setSelectedEarning,
  };
};

export default useEarningOnEditClick;
