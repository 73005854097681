import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChecklistDefinitionDto,
  OfficeControllerApi,
  OfficeResponse,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { fetchChecklistDefinition } from '../../../slices/ChecklistDefinitionSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { RootState } from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ResourceContainer from '../../ResourceContainer';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import OfficeSetDefaultTemplatesForm from './OfficeSetDefaultTemplatesForm';

interface OfficeSetDefaultTemplatesSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  office: OfficeResponse;
}

const OfficeSetDefaultTemplatesSidebarModal: React.FC<OfficeSetDefaultTemplatesSidebarModalProps> = ({
  isOpen,
  onClose,
  office,
}) => {
  const dispatch = useDispatch();
  const {
    checkListDefinition: { checklistDefinitionById },
  } = useSelector((state: RootState) => state);
  const [templates, setTemplates] = useState<ChecklistDefinitionDto[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchData = useCallback(async () => {
    setIsFetching(true);
    try {
      const { data: globalOffice } = await new OfficeControllerApi(
        getYentaConfiguration(),
      ).getGlobalOffice();

      const allTemplates = [
        ...office.checklistDefinitions!,
        ...globalOffice.checklistDefinitions!,
      ];

      const checklistDefinitionIds = allTemplates
        .filter((checkList) => !!checkList?.checklistDefinitionId)
        .map((checkList) => checkList.checklistDefinitionId!);

      const checkListDefinitionIdsToFetch = checklistDefinitionIds.filter(
        (id) => !checklistDefinitionById[id],
      );

      if (checkListDefinitionIdsToFetch.length > 0) {
        await Promise.all(
          checkListDefinitionIdsToFetch?.map((id) =>
            dispatch(fetchChecklistDefinition(id)),
          ),
        );
      }

      setTemplates(allTemplates);
    } catch (e) {
      ErrorService.notify(
        `Unable to fetch checklist definitions for an office`,
        e,
        {
          office: { id: office.id },
        },
      );
      dispatch(
        showErrorToast(
          'An unexpected error occurred.',
          `We were unable to fetch checklist definitions for an office. 
          Please try again in a few moments or contact support.`,
        ),
      );
    } finally {
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, office]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <ZenSidebarModal
      title='Set Default Templates'
      isOpen={isOpen}
      onClose={onClose}
    >
      <ResourceContainer
        loading={isFetching}
        isEmpty={false}
        resourceName='set default template'
      >
        <OfficeSetDefaultTemplatesForm
          office={office}
          templates={templates}
          onClose={onClose}
        />
      </ResourceContainer>
    </ZenSidebarModal>
  );
};

export default OfficeSetDefaultTemplatesSidebarModal;
