import { useState } from 'react';
import { faSlidersSimple } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'react-hook-form-v7';
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '../../../utils/classUtils';
import GeminiButton from '../../../components/Gemini/GeminiButton';
import GeminiControlledSelectInput from '../../../components/Gemini/input/GeminiControlledSelectInput';
import GeminiControlledGroupSelectSquare from '../../../components/Gemini/GenControlledGroupSelectSquare';
import { countryMapState } from '../../../testUtils/OnboardingUtils';
import { AddressRequestCountryEnum } from '../../../openapi/yenta';

interface ReferralFiltersProps {}

const ReferralFilters: React.FC<ReferralFiltersProps> = () => {
  const [open, setOpen] = useState(false);
  const { control, reset, watch } = useForm();

  const watchAllFields = watch('referralOptions');

  const options = [
    { label: 'All', value: 'All' },
    { label: 'Listing Created', value: 'LISTING_CREATED' },
    { label: 'Listing Not Created', value: 'LISTING_NOT_CREATED' },
    { label: 'Buyer', value: 'BUYER' },
    { label: 'Seller', value: 'SELLER' },
    { label: 'Tenant', value: 'TENANT' },
    { label: 'Landlord', value: 'LANDLORD' },
    { label: 'Other', value: 'OTHER' },
    { label: 'Referral', value: 'REFERRAL' },
  ];

  return (
    <section className='relative' title='referral-options'>
      <div className='flex flex-row items-center gap-4 pl-2'>
        <p className='whitespace-nowrap text-zen-salon-blue'>Filter By: </p>
        <GeminiButton
          label={`${
            watchAllFields?.length ? `(${watchAllFields?.length})` : 'All'
          }`}
          rightIcon={open ? faCaretUp : faCaretDown}
          variant='secondary-outline'
          onClick={() => setOpen(!open)}
        />
      </div>
      {open && (
        <form className='absolute right-0 z-10 mt-2 bg-white rounded-lg shadow-lg w-64 border  border-regent-400'>
          <div className='flex flex-row items-center justify-between mx-4 mt-2'>
            <div className='flex flex-row items-center space-x-2'>
              <FontAwesomeIcon
                icon={faSlidersSimple}
                className='text-primary-dark'
              />
              <p className='text-primary-dark font-inter font-medium text-lg'>
                Filter By
              </p>
            </div>
            <GeminiButton
              label='Clear'
              variant='primary-link'
              onClick={() => {
                reset();
              }}
            />
          </div>
          <div className='px-4'>
            <div className='py-2 border-t'>
              <GeminiControlledSelectInput
                control={control}
                label='Location'
                name='location'
                options={[
                  ...countryMapState[AddressRequestCountryEnum.UnitedStates],
                  ...countryMapState[AddressRequestCountryEnum.Canada],
                ]}
                shouldUnregister={false}
              />
            </div>
            <div className='mt-1'>
              <GeminiControlledGroupSelectSquare
                control={control}
                options={options}
                shouldUnregister={false}
                name='referralOptions'
              />
            </div>
            <div className={cn('py-3')}>
              <GeminiButton
                label='Apply'
                variant='primary'
                isFullWidth
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
          </div>
        </form>
      )}
    </section>
  );
};

export default ReferralFilters;
