import { EnumMap } from '../types';
import { cn } from '../utils/classUtils';

export type AlertVariantType =
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | 'default';

export interface AlertProps {
  text: string;
  variant: AlertVariantType;
  icon?: JSX.Element;
  className?: string;
}

const Alert: React.FC<AlertProps> = ({ text, variant, icon, className }) => {
  const alertVariantToClassNameMap: EnumMap<AlertVariantType, string> = {
    success: 'bg-success',
    info: 'bg-primary',
    warning: 'bg-warning',
    error: 'bg-error',
    default: 'bg-gray-500',
  };

  return (
    <div
      className={cn(
        'text-white rounded p-2',
        alertVariantToClassNameMap[variant],
        className,
      )}
    >
      <div className='flex items-center'>
        {icon}
        <span className='font-primary-regular ml-1 pt-0.5 break-all'>
          {text}
        </span>
      </div>
    </div>
  );
};

export default Alert;
