import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';
import ZenReprocessRevshareFormSidebarModal from '../forms/ZenReprocessRevshareFormSidebarModal';

interface ZenReprocessRevshareProps {}

const ZenReprocessRevshare: React.FC<ZenReprocessRevshareProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <ZenResourceLinkCard
        title='Reprocess Revshare'
        onClick={() => setIsOpen(true)}
        icon={solid('circle-dollar')}
        iconSize='2x'
      />
      <ZenReprocessRevshareFormSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenReprocessRevshare;
