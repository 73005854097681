import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChecklistDefinitionDto,
  ChecklistDefinitionDtoTypeEnum,
  OfficeResponse,
  UpdateDefaultChecklistsAndJourneysRequest,
} from '../../../openapi/yenta';
import { updateChecklistsAndJourneys } from '../../../slices/OfficeSlice';
import { ISelectOption, RootState } from '../../../types';
import ZenControlledSelectInput, {
  CustomSelectOptions,
} from '../../Zen/Input/ZenControlledSelectInput';
import ZenButton from '../../Zen/ZenButton';

interface FormData {
  defaultTxChecklistDefId: ISelectOption;
  defaultJourneyDefId: ISelectOption;
  defaultReferralChecklistDefId: ISelectOption;
  defaultReferralJourneyDefId: ISelectOption;
  defaultListingChecklistDefId: ISelectOption;
  defaultListingJourneyDefId: ISelectOption;
}

interface OfficeSetDefaultTemplatesFormProps {
  office: OfficeResponse;
  templates: ChecklistDefinitionDto[];
  onClose(): void;
}

const OfficeSetDefaultTemplatesForm: React.FC<OfficeSetDefaultTemplatesFormProps> = ({
  office,
  templates,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    checkListDefinition: { checklistDefinitionById },
  } = useSelector((state: RootState) => state);

  const getDefaultValue = (
    value?: ChecklistDefinitionDto,
  ): ISelectOption | undefined => {
    if (value) {
      const checklistDefinition = checklistDefinitionById[
        value?.checklistDefinitionId!
      ]?.data!;

      return {
        label: `${checklistDefinition?.name ?? ''}`,
        value: value.id!,
      };
    }

    return undefined;
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      defaultTxChecklistDefId: getDefaultValue(office.defaultTxChecklist),
      defaultJourneyDefId: getDefaultValue(office.defaultJourney),
      defaultReferralChecklistDefId: getDefaultValue(
        office.defaultReferralChecklist,
      ),
      defaultReferralJourneyDefId: getDefaultValue(
        office.defaultReferralJourney,
      ),
      defaultListingChecklistDefId: getDefaultValue(
        office.defaultListingChecklist,
      ),
      defaultListingJourneyDefId: getDefaultValue(office.defaultListingJourney),
    },
  });

  const getOptions = (
    type: ChecklistDefinitionDtoTypeEnum,
  ): CustomSelectOptions[] => {
    return templates
      .filter((t) => t.type === type)
      .map((t) => {
        const checklistDefinition = checklistDefinitionById[
          t.checklistDefinitionId!
        ]?.data!;

        return {
          value: `${t.id}`,
          label: `${checklistDefinition?.name}`,
        };
      });
  };

  const onSubmit = async (values: FormData) => {
    const updateDefaultChecklistsAndJourneysRequest: Required<UpdateDefaultChecklistsAndJourneysRequest> = {
      defaultJourneyDefId: values.defaultJourneyDefId?.value,
      defaultTxChecklistDefId: values.defaultTxChecklistDefId?.value,
      defaultReferralJourneyDefId: values.defaultReferralJourneyDefId?.value,
      defaultReferralChecklistDefId:
        values.defaultReferralChecklistDefId?.value,
      defaultListingJourneyDefId: values.defaultListingJourneyDefId?.value,
      defaultListingChecklistDefId: values.defaultListingChecklistDefId?.value,
    };

    await dispatch(
      updateChecklistsAndJourneys(
        office.id!,
        updateDefaultChecklistsAndJourneysRequest,
      ),
    );

    onClose();
  };

  return (
    <form
      className='flex flex-col justify-between min-h-full'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='py-4 md:px-5 px-4'>
        <div>
          <ZenControlledSelectInput<FormData, 'defaultTxChecklistDefId'>
            control={control}
            name='defaultTxChecklistDefId'
            label='Default Transaction Checklist'
            placeholder='Select'
            shouldUnregister={false}
            options={getOptions(ChecklistDefinitionDtoTypeEnum.Checklist)}
            isSearchable
          />
        </div>
        <div className='mt-5'>
          <ZenControlledSelectInput<FormData, 'defaultJourneyDefId'>
            control={control}
            name='defaultJourneyDefId'
            label='Default Transaction Journey'
            shouldUnregister={false}
            placeholder='Select'
            options={getOptions(ChecklistDefinitionDtoTypeEnum.Journey)}
            isSearchable
          />
        </div>
        <div className='mt-5'>
          <ZenControlledSelectInput<FormData, 'defaultReferralChecklistDefId'>
            control={control}
            name='defaultReferralChecklistDefId'
            label='Default Referral Checklist'
            shouldUnregister={false}
            placeholder='Select'
            options={getOptions(ChecklistDefinitionDtoTypeEnum.Checklist)}
            isSearchable
          />
        </div>
        <div className='mt-5'>
          <ZenControlledSelectInput<FormData, 'defaultReferralJourneyDefId'>
            control={control}
            name='defaultReferralJourneyDefId'
            label='Default Referral Journey'
            shouldUnregister={false}
            placeholder='Select'
            options={getOptions(ChecklistDefinitionDtoTypeEnum.Journey)}
            isSearchable
          />
        </div>
        <div className='mt-5'>
          <ZenControlledSelectInput<FormData, 'defaultListingChecklistDefId'>
            control={control}
            name='defaultListingChecklistDefId'
            label='Default Listing Checklist'
            shouldUnregister={false}
            placeholder='Select'
            options={getOptions(ChecklistDefinitionDtoTypeEnum.Checklist)}
            isSearchable
          />
        </div>
        <div className='mt-5 pb-20'>
          <ZenControlledSelectInput<FormData, 'defaultListingJourneyDefId'>
            control={control}
            name='defaultListingJourneyDefId'
            label='Default Listing Journey'
            shouldUnregister={false}
            placeholder='Select'
            options={getOptions(ChecklistDefinitionDtoTypeEnum.Journey)}
            isSearchable
          />
        </div>
      </div>
      <div className='absolute bottom-0 left-0 right-0 bg-white flex flex-row justify-end space-x-4 items-center w-full py-4 md:px-8 px-4 border border-gray-200'>
        <div className='w-40'>
          <ZenButton
            type='button'
            label='Cancel'
            variant='primary-outline'
            onClick={onClose}
            isFullWidth
          />
        </div>
        <div className='w-40'>
          <ZenButton
            type='submit'
            label='Update'
            variant='primary'
            isDisabled={isSubmitting}
            isSubmitting={isSubmitting}
            isFullWidth
          />
        </div>
      </div>
    </form>
  );
};

export default OfficeSetDefaultTemplatesForm;
