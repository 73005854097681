import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  submitCheckDeposits,
  submitEscrowCheckDeposits,
  submitExternalCheckDeposit,
} from '../../../slices/CheckDepositsSlice';
import { AppDispatch, RootState } from '../../../types';
import { transactionCheckDepositPayAtClosingSteps } from '../../../utils/CheckDepositsHelper';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenApplicationStepProgressHeader from '../../onboarding/ZenApplicationStepProgressHeader';
import {
  DepositCheckFormData,
  DepositCheckStepName,
} from '../../../routes/ZenTransactionDepositCheckRoute';
import ZenCheckDepositHeader from './ZenCheckDepositHeader';
import ZenCheckDepositReviewStep from './ZenCheckDepositReviewStep';
import withZenExternalCheckDepositProgress from './WithZenExternalCheckDepositProgress';

type Match = {
  id: string;
};

const ZenTransactionCheckDepositReview: StepByStepComponent<
  DepositCheckFormData,
  DepositCheckStepName
> = ({ form, onNext, onPrevious }) => {
  const { watch, trigger } = form;
  const { id } = useParams<Match>();
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
      tokenTransactionDetail,
    },
    checkDeposits: { uploadedCheckDeposits },
  } = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();

  const [
    isExternalCheckDeposit,
    amount,
    closingDate,
    showClosing,
    escrowId,
  ] = watch([
    'isExternalCheckDeposit',
    'amount',
    'closingDate',
    'showClosing',
    'escrowId',
  ]);

  const handleExternalCheckDepositSubmit = async () => {
    const isValid = await trigger();
    if (isValid) {
      let confirmationId: number | undefined;
      confirmationId = await dispatch(
        submitExternalCheckDeposit(id, uploadedCheckDeposits?.checkDepositId!, {
          accountNumber: uploadedCheckDeposits?.accountNumber,
          amount: parseFloat(amount!),
          checkNumber: uploadedCheckDeposits?.checkNumber,
          routingNumber: uploadedCheckDeposits?.abaNumber,
          transactionClosedOn: closingDate,
        }),
      );
      if (confirmationId) {
        onNext();
      }
    }
  };

  const handleSubmitCheckDeposits = async () => {
    const isValid = await trigger();
    if (isValid) {
      let confirmationId: number | undefined;

      if (!escrowId) {
        confirmationId = await dispatch(
          submitCheckDeposits(
            transactionDetail?.id!,
            uploadedCheckDeposits?.checkDepositId!,
            {
              accountNumber: uploadedCheckDeposits?.accountNumber,
              amount: parseFloat(amount!),
              checkNumber: uploadedCheckDeposits?.checkNumber,
              routingNumber: uploadedCheckDeposits?.abaNumber,
              transactionClosedOn: closingDate,
            },
          ),
        );
      } else {
        confirmationId = await dispatch(
          submitEscrowCheckDeposits(
            escrowId,
            uploadedCheckDeposits?.checkDepositId!,
            {
              accountNumber: uploadedCheckDeposits?.accountNumber,
              amount: parseFloat(amount!),
              checkNumber: uploadedCheckDeposits?.checkNumber,
              routingNumber: uploadedCheckDeposits?.abaNumber,
              transactionClosedOn: closingDate,
            },
          ),
        );
      }

      if (confirmationId) {
        onNext();
      }
    }
  };

  return (
    <div className='w-full h-full max-w-3xl py-4 mx-auto bg-white'>
      <div className='mb-8'>
        {!isExternalCheckDeposit && showClosing && (
          <div className='flex justify-center mb-3'>
            <ZenApplicationStepProgressHeader
              steps={transactionCheckDepositPayAtClosingSteps(
                !watch('closeAt'),
              )}
              inProgressIndex={1}
            />
          </div>
        )}
      </div>
      <ZenCheckDepositHeader
        transactionAddress={
          isExternalCheckDeposit
            ? tokenTransactionDetail?.propertyAddress
            : transactionDetail?.address?.oneLine
        }
        closingDate={closingDate}
      />
      <ZenCheckDepositReviewStep
        form={form}
        onSubmit={
          isExternalCheckDeposit
            ? handleExternalCheckDepositSubmit
            : handleSubmitCheckDeposits
        }
        onPrevious={onPrevious}
      />
    </div>
  );
};

export default withZenExternalCheckDepositProgress(
  ZenTransactionCheckDepositReview,
);
