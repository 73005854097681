import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash';
import { FeatureFlagTypeEnum, RootState } from '../types';
import { useFeatureFlag } from './useFeatureFlag';

const useBrokerRedirect = () => {
  const history = useHistory();
  const {
    auth: { userDetail, isBroker },
    paymentMethod: { paymentMethodsResponseByAgentId },
    taxInformation: { taxFormResponseByAgentId },
    announcement: { topAnnouncement, topAnnouncementLoading },
  } = useSelector((state: RootState) => state);
  const isMSDynamicsOnboardingEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.MS_DYNAMICS_ONBOARDING,
  );
  const taxForms = taxFormResponseByAgentId[userDetail?.id!];
  const paymentMethods = paymentMethodsResponseByAgentId[userDetail?.id!];
  const showMSDynamicsOnboarding =
    isMSDynamicsOnboardingEnabled &&
    isBroker &&
    !isNil(taxForms?.data) &&
    !isNil(paymentMethods?.data) &&
    (isEmpty(taxForms?.data?.taxForms) ||
      isEmpty(paymentMethods?.data?.paymentMethods));

  useEffect(() => {
    if (userDetail && !topAnnouncementLoading && isEmpty(topAnnouncement)) {
      if (userDetail.needsTaxIdOnboarding) {
        history.push('/tax-national-ids');
      } else if (showMSDynamicsOnboarding) {
        return history.push('/payment-onboarding');
      }
    }
  }, [
    userDetail,
    history,
    showMSDynamicsOnboarding,
    topAnnouncement,
    topAnnouncementLoading,
  ]);
};

export default useBrokerRedirect;
