import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { UnpackNestedValue } from 'react-hook-form-v7';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Column,
  PluginHook,
  TableState,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { DEFAULT_PAGE_SIZE } from '../../../constants/TableConstants';
import { ResourceTableVariant } from '../../../containers/ResourceTable';
import ErrorService from '../../../services/ErrorService';
import { IPaginateRes } from '../../../types';
import { isSmScreen } from '../../../utils/BrowserUtils';
import { cn } from '../../../utils/classUtils';
import { parseQueryString } from '../../../utils/QueryStringUtils';
import {
  getPageCount,
  getPaginateReqFromState,
  getZenSelectionOptions,
  mapToStateFilter,
  mapToStateSortBy,
} from '../../../utils/TableUtils';
import NestedTabsWithRouter, {
  NestedTabsWithRouterProps,
} from '../../NestedTabsWithRouter';
import ResourceContainer from '../../ResourceContainer';
import TableLoader from '../../TableLoader';
import {
  TableProps,
  TableQueryParamState,
} from '../../Zen/Containers/ZenResourceIndexContainer';
import ZenResourceTable from '../../Zen/Table/ZenResourceTable';
import ZenTableOptions from '../../Zen/Table/ZenTableOptions';
import GeminiExpandableSortOptions, {
  GeminiExpandableSortOptionsIconVariant,
} from '../Table/GeminiExpandableSortOptions';

export type CardItemPosition =
  | 'top-left'
  | 'top-right'
  | 'main'
  | 'collapsible';

export type CustomColumn<D extends object = {}> = Column<D> & {
  position?: CardItemPosition;
};

export interface GeminiResourceContainerProps<D extends object>
  extends Omit<
    TableProps<D>,
    | 'tabs'
    | 'defaultSelectedTabName'
    | 'setCurrentTab'
    | 'allowSelection'
    | 'showSelectionActionBar'
    | 'actionBarButtons'
    | 'standalone'
    | 'fetchData'
    | 'showSearch'
  > {
  routerTabs: Omit<NestedTabsWithRouterProps<D>, 'tabComponent'>;
  columns: Array<CustomColumn<D>>;
  isLoading: boolean;
  data: D[];
  total: number;
  error?: Error | null;
  RightComponent?: React.ReactElement;
  isDisplayVariantCardExpandable?: boolean;
  showSortOptions?: boolean;
  tableContainerClassNames?: string;
  sortIconVariant?: GeminiExpandableSortOptionsIconVariant;
  showExpandAllButton?: boolean;
  showTotalCountInPagination?: boolean;
}

interface DataWithOptionalId extends Object {
  id?: string;
}

const GeminiResourceTableWithNestedRouterTabs = <D extends DataWithOptionalId>({
  header,
  columns,
  initialSort,
  hiddenColumns = [],
  filter,
  search,
  data,
  isLoading,
  resourceName,
  total,
  error,
  hideFilters = false,
  hidePagination = false,
  paginationContainerClassNames,
  hidePageSize = false,
  pageSize = DEFAULT_PAGE_SIZE,
  RightComponent,
  selectionOptions = [],
  allowSortingRows = false,
  emptyIconComponent,
  customEmptyComponent,
  cellVerticalAlignment,
  renderToggleRowComponent,
  getTableRowClassNames,
  onSortEnd,
  headerVariant,
  pageSizeOptions,
  hideRowBorder,
  groupByProperty,
  hideHeader = false,
  compact = false,
  selectionPortal,
  stickyHeader = false,
  resourceContainerDisplayVariant = 'TABLE',
  isDisplayVariantCardExpandable = true,
  routerTabs,
  showSortOptions = true,
  tableContainerClassNames,
  sortIconVariant = 'variant-1',
  showExpandAllButton = true,
  showTotalCountInPagination = false,
}: GeminiResourceContainerProps<D>) => {
  const [pageCount, setPageCount] = useState(0);
  const [paginatedRes, setPaginatedRes] = useState<IPaginateRes<D>>({
    total: data?.length ?? 0,
    data,
  });
  const location = useLocation();
  const history = useHistory();
  const {
    filter: filterFromQS,
    pageSize: pageSizeFromQS,
    pageIndex: pageFromQS,
    sortBy: sortByQS,
  }: TableQueryParamState<D> = parseQueryString<TableQueryParamState<D>>(
    location.search,
  );

  const filterByAccessorFromQS = (filterFromQS || [])?.filter(
    (f) => !!columns?.find((c) => c.id === f.id || c.accessor === f.id),
  );

  const initialState: Partial<TableState<D>> = {
    pageIndex: !!pageFromQS ? parseInt(pageFromQS, 10) : 0,
    pageSize: !!pageSizeFromQS
      ? parseInt(pageSizeFromQS, 10)
      : hidePagination
      ? Math.max(paginatedRes.data.length, 1)
      : pageSize,
    filters: !!filterByAccessorFromQS?.length
      ? filterByAccessorFromQS
      : mapToStateFilter(filter),
    sortBy: !!sortByQS?.length ? sortByQS : mapToStateSortBy(initialSort),
    globalFilter: search || undefined,
    hiddenColumns,
  };

  const plugins: PluginHook<D>[] = [
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  ];

  const tableInstance = useTable<D>(
    {
      columns: columns,
      data: data,
      manualGlobalFilter: true,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetPage: false,
      defaultCanFilter: false,
      initialState,
      pageCount,
      getRowId: (originalRow: D, index: number) =>
        'id' in originalRow ? originalRow.id! : index.toString(),
    },
    ...plugins,
  );

  const selectedRows = tableInstance.selectedFlatRows.map(
    (flatRows) => flatRows.original,
  );
  const options = getZenSelectionOptions<D>(selectedRows, selectionOptions);

  useEffect(() => {
    if (!!data && !!total) {
      setPaginatedRes({ total: total, data });
      setPageCount(getPageCount(total, tableInstance.state.pageSize));
    }
  }, [data, tableInstance.state.pageSize, total]);

  const updateStateUrl = useCallback(() => {
    history.replace(
      `${history.location.pathname}?${qs.stringify({
        ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
        filter: tableInstance.state.filters,
        sortBy: tableInstance.state.sortBy,
        search: tableInstance.state.globalFilter,
        pageIndex: tableInstance.state.pageIndex,
        pageSize: tableInstance.state.pageSize,
      })}`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableInstance.state.filters,
    tableInstance.state.globalFilter,
    tableInstance.state.pageIndex,
    tableInstance.state.pageSize,
    tableInstance.state.sortBy,
  ]);

  const onSelectionOptionClicked = async (
    index: number,
    formData?: UnpackNestedValue<D>,
  ) => {
    await selectionOptions[index].onAction(
      tableInstance.selectedFlatRows.map((flatRows) => flatRows.original),
      formData,
    );
    tableInstance.toggleAllRowsSelected(false);
    getPaginateReqFromState(tableInstance.state);
  };

  const renderTable = () => (
    <div
      className={cn(
        'px-6 pt-4 bg-regent-200 min-h-[calc(100vh_-_240px)]',
        tableContainerClassNames,
      )}
    >
      <ResourceContainer
        resourceName={resourceName}
        isEmpty={data?.length === 0}
        loading={isLoading}
        errorCode={error ? ErrorService.getErrorCode(error) : undefined}
        LoaderComponent={<TableLoader />}
      >
        <ZenResourceTable<D>
          {...tableInstance}
          totalCount={total}
          resourceName={resourceName}
          variant={
            isSmScreen() ? ResourceTableVariant.CARD : ResourceTableVariant.ROW
          }
          hidePagination={hidePagination}
          hidePageSize={hidePageSize}
          emptyIconComponent={emptyIconComponent}
          customEmptyComponent={customEmptyComponent}
          cellVerticalAlignment={cellVerticalAlignment}
          renderToggleRowComponent={renderToggleRowComponent}
          getTableRowClassNames={getTableRowClassNames}
          allowSortingRows={allowSortingRows}
          onSortEnd={onSortEnd}
          headerVariant={headerVariant}
          pageSizeOptions={pageSizeOptions}
          hideRowBorder={hideRowBorder}
          groupByProperty={groupByProperty}
          hideHeader={hideHeader}
          compact={compact}
          stickyHeader={stickyHeader}
          paginationContainerClassNames={paginationContainerClassNames}
          resourceContainerDisplayVariant={resourceContainerDisplayVariant}
          isDisplayVariantCardExpandable={isDisplayVariantCardExpandable}
          showTotalCount={showTotalCountInPagination}
        />
      </ResourceContainer>
    </div>
  );

  const renderTableOptions = () => {
    return (
      <div
        className={cn('flex flex-row', {
          'mb-4': !hideFilters || RightComponent,
        })}
      >
        <ZenTableOptions<D>
          selectionPortal={selectionPortal}
          hideFilters={hideFilters}
          tableInstance={tableInstance}
          selectionOptions={options}
          onSelectionOptionClicked={onSelectionOptionClicked}
        />
        {!isSmScreen() && RightComponent && (
          <div className='ml-3'>{RightComponent}</div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (tableInstance.state.globalFilter !== search) {
      tableInstance.setGlobalFilter(search || undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    updateStateUrl();
  }, [updateStateUrl]);

  return (
    <div>
      <div>
        {isSmScreen() && RightComponent && (
          <div className='flex justify-end mb-4'>{RightComponent}</div>
        )}
        <div className='md:flex items-center md:justify-between px-2 md:px-4 py-4 space-y-2 md:space-y-0'>
          {!!header && (
            <h1 className='font-poppins font-medium ml-3'>{header}</h1>
          )}
          {renderTableOptions()}
        </div>
      </div>
      <NestedTabsWithRouter
        {...routerTabs}
        tabsConfig={{
          ...routerTabs?.tabsConfig,
          SUB_TABS: {
            ...routerTabs?.tabsConfig?.SUB_TABS,
            rightSideComponent: showSortOptions ? (
              <GeminiExpandableSortOptions
                tableInstance={tableInstance}
                sortIconVariant={sortIconVariant}
                showExpandAll={showExpandAllButton}
              />
            ) : null,
          },
        }}
        tabComponent={renderTable}
      />
    </div>
  );
};

export default GeminiResourceTableWithNestedRouterTabs;
