import React from 'react';
import { keys } from 'lodash';
import { cn } from '../../utils/classUtils';
import { revShareInsightsTierIconUnlockedMap } from '../../utils/RevShareInsightsUtils';

const filters = keys(revShareInsightsTierIconUnlockedMap);

interface RevShareInsightsTierFiltersProps {
  tier: number;
  loading?: boolean;
  onChange(tier: number): Promise<void> | void;
}

const RevShareInsightsTierFilters: React.FC<RevShareInsightsTierFiltersProps> = ({
  tier,
  loading,
  onChange,
}) => {
  return (
    <div className='flex items-center border border-zen-dark-4 rounded-l-lg rounded-r-lg overflow-hidden'>
      {filters.map((value, index) => {
        const currTier = +value;
        const Icon = revShareInsightsTierIconUnlockedMap[currTier];
        return (
          <button
            onClick={() => {
              onChange(currTier);
            }}
            disabled={loading}
            aria-label={`tier-icon-${currTier}`}
            className={cn(
              'flex-1 flex justify-center px-2 py-2 border',
              index ? 'border-grey-100' : 'border-transparent',
              {
                'bg-[#EAF4FE]': currTier === tier,
                'opacity-50': loading && currTier === tier,
              },
            )}
            key={value}
          >
            <div
              className={cn({
                'opacity-20': currTier !== tier,
              })}
            >
              <Icon />
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default RevShareInsightsTierFilters;
