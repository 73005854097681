import { values } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaResponseCountryEnum,
} from '../../../../openapi/yenta';
import { RootState } from '../../../../types';
import { isReferralTransactionAddressRequired } from '../../../../utils/ReferralTransactionHelper';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledHTMLSelectInput from '../../Input/ZenControlledHTMLSelectInput';
import ZenControlledStateOrProvinceInput from '../../Input/ZenControlledStateOrProvinceInput';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenStickyBottomPagination from '../../ZenStickyBottomPagination';
import withZenCreateReferralTransactionProgress from './withZenCreateReferralTransactionProgress';
import {
  ReferralTransactionFormState,
  ReferralTransactionStepName,
} from './ZenReferralTransactionSteps';

const ZenAddressInformationStep: StepByStepComponent<
  ReferralTransactionFormState,
  ReferralTransactionStepName
> = ({ form: { control, setValue, watch, trigger }, onNext, onPrevious }) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const address = watch('address');
  const isAddressRequired = isReferralTransactionAddressRequired(
    userDetail!,
    address!,
  );

  useEffect(() => {
    if (!isAddressRequired) {
      trigger();
    }
  }, [isAddressRequired, trigger]);

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div>
          <p className='text-xl font-zen-body font-semibold text-zen-dark-9 mb-4'>
            Address
          </p>
          <div className='space-y-4'>
            <div>
              <ZenControlledTextInput<
                ReferralTransactionFormState,
                'address.street'
              >
                control={control}
                label='Street'
                name='address.street'
                shouldUnregister={false}
                placeholder='E.g. 270 1st Ave.'
                isRequired={isAddressRequired}
                rules={{
                  required: isAddressRequired
                    ? 'Street is Required'
                    : undefined,
                }}
              />
            </div>
            <div>
              <ZenControlledTextInput<
                ReferralTransactionFormState,
                'address.street2'
              >
                control={control}
                label='Street Line 2'
                name='address.street2'
                shouldUnregister={false}
                placeholder='E.g. 270 1st Ave.'
              />
            </div>
            <div className='flex flex-row space-x-4'>
              <ZenControlledTextInput<
                ReferralTransactionFormState,
                'address.city'
              >
                control={control}
                label='City'
                name='address.city'
                shouldUnregister={false}
                placeholder='Eg. New York'
                isRequired={isAddressRequired}
                rules={{
                  required: isAddressRequired ? 'City is Required' : undefined,
                }}
              />
              <ZenControlledStateOrProvinceInput<
                ReferralTransactionFormState,
                'address.state'
              >
                name='address.state'
                control={control}
                shouldUnregister={false}
                setValue={setValue}
                selectedCountry={
                  (address?.country as unknown) as AdministrativeAreaRequestCountryEnum
                }
                isRequired={isAddressRequired}
                disableChooseOption={false}
                rules={{
                  required: isAddressRequired ? 'State is Required' : undefined,
                }}
              />
            </div>
            <div className='flex flex-row space-x-4'>
              <ZenControlledHTMLSelectInput<
                ReferralTransactionFormState,
                'address.country'
              >
                name='address.country'
                control={control}
                label='Country'
                shouldUnregister={false}
                placeholder='Country'
                options={[
                  {
                    label: 'Choose Country',
                    value: '',
                  },
                  ...values(AdministrativeAreaResponseCountryEnum).map(
                    (state) => ({
                      value: state,
                      label: capitalizeEnum(state),
                    }),
                  ),
                ]}
                isRequired={isAddressRequired}
                rules={{
                  required: isAddressRequired
                    ? 'Country is Required'
                    : undefined,
                }}
              />
              <ZenControlledTextInput<
                ReferralTransactionFormState,
                'address.zip'
              >
                control={control}
                label='ZIP/Postal Code'
                name='address.zip'
                shouldUnregister={false}
                placeholder='E.g. 10001'
                isRequired={isAddressRequired}
                rules={{
                  required: isAddressRequired
                    ? 'Zip/Postal Code is Required'
                    : undefined,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ZenStickyBottomPagination
        maxWidth='2xl'
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </div>
  );
};

export default withZenCreateReferralTransactionProgress(
  ZenAddressInformationStep,
);
