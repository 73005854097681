import {
  faEnvelope,
  faLocationDot,
  faPhone,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard } from '@fortawesome/pro-regular-svg-icons';
import { ApplicationResponseStatusEnum } from '../openapi/yenta';
import ZenTransactionHeaderRowCell from './Zen/Transaction/Header/ZenTransactionHeaderRowCell';
import ZenButton from './Zen/ZenButton';

export interface ZenApplicationHeaderProps {
  id: string;
  name: string;
  state: string;
  phoneNumber: string;
  primaryEmail: string;
  licenseNumber: string;
  agentStatus: ApplicationResponseStatusEnum;
  expiryDate: string;
  agentId: string;
}

const ZenApplicationHeader: React.FC<ZenApplicationHeaderProps> = ({
  id,
  name,
  phoneNumber,
  primaryEmail,
  state,
  licenseNumber,
  agentStatus,
  expiryDate,
  agentId,
}) => {
  const history = useHistory();

  return (
    <header className='bg-zen-dark-13 p-2 md:p-4 lg:p-10 mt-2 lg:mt-6 mb-2 mx-4 rounded-2xl text-white'>
      <div className='flex flex-row md:pb-4 lg:pb-0 space-y-1 justify-between'>
        <div className='flex flex-col gap-y-5'>
          <p className='text-2xl font-zen-body font-bold'>{name}</p>
          <ZenTransactionHeaderRowCell
            icon={
              <FontAwesomeIcon
                icon={faIdCard}
                className='text-primary-light flex w-4 h-4 p-1.5'
              />
            }
            content={
              <div className='flex flex-row items-center space-x-2'>
                <p className='text-lg font-zen-body font-semibold'>
                  Application ID: #{id}
                </p>
              </div>
            }
          />
          <ZenTransactionHeaderRowCell
            icon={
              <FontAwesomeIcon
                icon={faLocationDot}
                className='text-primary-light flex w-3.5 h-3.5 p-1.5'
              />
            }
            content={
              <div className='flex flex-row items-center space-x-2'>
                <p className='font-zen-body font-semibold text-sm text-white'>
                  {state}
                </p>
              </div>
            }
          />
          <ZenTransactionHeaderRowCell
            icon={
              <FontAwesomeIcon
                icon={faPhone}
                className='text-primary-light flex w-3.5 h-3.5 p-1.5'
              />
            }
            content={
              <div className='flex flex-row items-center space-x-2'>
                <p className='font-zen-body font-semibold text-sm text-white'>
                  {phoneNumber!}
                </p>
              </div>
            }
          />
          <ZenTransactionHeaderRowCell
            icon={
              <FontAwesomeIcon
                icon={faEnvelope}
                className='text-primary-light flex w-3.5 h-3.5 p-1.5'
              />
            }
            content={
              <div className='flex flex-row items-center space-x-2'>
                <p className='font-zen-body font-semibold text-sm text-white'>
                  {primaryEmail!}
                </p>
              </div>
            }
          />
          <div className='flex flex-col md:flex-row space-x-2 space-y-1 mb-2 md:items-center'>
            <ZenTransactionHeaderRowCell
              icon={
                <FontAwesomeIcon
                  icon={faUser}
                  className='text-primary-light flex w-3.5 h-3.5 p-1.5'
                />
              }
              content={
                <div className='flex flex-row items-center space-x-2'>
                  <p className='font-zen-body font-semibold text-sm text-white'>
                    {`License No: ${licenseNumber}`}
                  </p>
                </div>
              }
            />
            <div className='font-primary-light flex flex-row'>
              <p className='pr-2'>•</p> Expires:{' '}
              {DateTime.fromISO(expiryDate!).toFormat('yyyy-MM-dd')}
            </div>
          </div>
        </div>
        {agentStatus === ApplicationResponseStatusEnum.Approved && (
          <div>
            <ZenButton
              label='View Profile'
              onClick={() => history.push(`/people/${agentId}`)}
            />
          </div>
        )}
      </div>
    </header>
  );
};

export default ZenApplicationHeader;
