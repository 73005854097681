import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import ZenContributorCell from '../components/table/Cells/ZenContributorCell';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenSimpleConfirmationModal from '../components/Zen/Modal/ZenSimpleConfirmationModal';
import ZenMilliDateCell from '../components/Zen/Table/Cell/ZenMilliDateCell';
import ZenButton from '../components/Zen/ZenButton';
import ZenResourceContainer from '../components/Zen/ZenResourceContainer';
import ZenRoute from '../components/Zen/ZenRoute';
import { RevShareContributionResponse } from '../openapi/arrakis';
import {
  exportAllRevenueSharePaymentsOfAgent,
  fetchContributionsForOutgoingPaymentForTable,
} from '../slices/RevenueSlice';
import { AppDispatch, RootState } from '../types';
import { displayAmount, zeroMoneyValue } from '../utils/CurrencyUtils';
import { getAllRevShareFees } from '../utils/RevShareUtils';
import ZenRouterTabs, { ZenTab } from '../components/Zen/Tab/ZenRouterTabs';
import RevshareDebtRepayment from '../components/AgentOwesReal/RevshareDebtRepayment';
import { parseCsv } from '../utils/reportsUtils';

type Match = {
  outgoingPaymentId: string;
  id: string; // agent id
};

export const columns: Array<Column<RevShareContributionResponse>> = [
  {
    Header: 'Contributor Name',
    accessor: 'agent',
    Cell: ({ value, row }) => (
      <Link to={`/people/${value?.id}`}>
        <ZenContributorCell
          name={`${value?.firstName} ${value?.lastName}`}
          imageUrl={value?.imageUrl!}
          tier={row.original.tier!}
        />
      </Link>
    ),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Transaction',
    accessor: 'transaction',
    Cell: ({ value }) => <p>{value?.addressOneLine}</p>,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Settled Date',
    accessor: 'settledAt',
    Cell: ({ value }) => <ZenMilliDateCell date={value} />,
    disableSortBy: true,
    disableFilters: true,
  },
];

const ZenAgentDetailRevShareContributors: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    agentDetail: {
      detailResponse: { data: detail },
    },
    revenue: { contributors, contributorsLoading, contributorsError },
  } = useSelector((state: RootState) => state);
  const { outgoingPaymentId, id: agentId } = useParams() as Match;

  const [isOpen, setIsOpen] = useState(false);
  const [exportData, setExportData] = useState<string | undefined>(undefined);
  const exportToCSV = async () => {
    let csvData: string | undefined;
    csvData = await dispatch(
      exportAllRevenueSharePaymentsOfAgent(agentId, outgoingPaymentId),
    );

    setExportData(csvData);
  };

  const dynamicColumn: Array<Column<RevShareContributionResponse>> = useMemo(
    () => [
      ...columns,
      {
        Header: `Amount (${detail?.defaultCurrency})`,
        accessor: 'amount',
        Cell: ({ value }) => (
          <div className='flex flex-row md:justify-end w-24'>
            <p className='md:text-right'>
              {displayAmount(
                value || zeroMoneyValue(detail?.defaultCurrency!),
                { hideCurrency: true },
              )}
            </p>
          </div>
        ),
        disableSortBy: true,
        disableFilters: true,
      },
    ],
    [detail?.defaultCurrency],
  );

  const { annualFee, processingFee, revShareAmount } = getAllRevShareFees(
    contributors,
  );

  const revShareTabs = useMemo(() => {
    const tabs: ZenTab[] = [
      {
        label: 'Contributions',
        path: `/people/${agentId}/revshare/payments/${outgoingPaymentId}`,
        exact: true,
        TabComponent: () => (
          <div className='py-2'>
            <ZenResourceIndexContainer<RevShareContributionResponse>
              columns={dynamicColumn}
              resourceName='Outgoing Payment'
              fetchData={async (req) =>
                dispatch(
                  fetchContributionsForOutgoingPaymentForTable(
                    agentId,
                    outgoingPaymentId,
                    req.page,
                    req.pageSize,
                    false,
                  ),
                )
              }
              hideFilters
            />
          </div>
        ),
      },
      {
        label: 'Debt Repayment',
        path: `/people/${agentId}/revshare/payments/${outgoingPaymentId}/debt-repayment`,
        exact: true,
        TabComponent: () => (
          <RevshareDebtRepayment
            agentId={agentId}
            outgoingPaymentId={outgoingPaymentId}
          />
        ),
      },
    ];

    return tabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, dispatch, outgoingPaymentId]);

  return (
    <ZenRoute title='Revshare Contribution'>
      <div className='p-4'>
        <ZenResourceContainer
          isEmpty={false}
          loading={
            contributorsLoading ||
            outgoingPaymentId !== contributors?.outgoingPaymentId
          }
          resourceName='Outgoing Payments'
          errorCode={contributorsError}
        >
          <div className='flex flex-row items-center mb-4 space-x-2 font-zen-body'>
            <span className='font-semibold text-xl'>
              {DateTime.fromMillis(
                contributors?.createdAt! || 0,
              ).toLocaleString(DateTime.DATE_MED)}{' '}
            </span>
            <span className='font-semibold text-xl'>
              Payment:{' '}
              {displayAmount(contributors?.amount, {
                hideZeroCents: true,
              })}
            </span>
          </div>

          <div className='flex flex-col py-2 md:py-0'>
            <div className='grid grid-cols-12 grid-rows-3 grid-flow-row gap-y-1'>
              <span className='font-zen-body font-light text-base col-span-8 md:col-span-2'>
                Revenue Share this Period:
              </span>
              <span className='font-zen-body font-light text-base text-right col-span-4 md:col-span-2 truncate'>
                {displayAmount(
                  {
                    amount: revShareAmount,
                    currency: contributors?.amount?.currency,
                  },
                  { hideCurrency: true },
                )}
              </span>
              <span className='hidden md:block md:col-span-8' />
              <span className='font-zen-body font-light text-base col-span-8 md:col-span-2'>
                Annual Fee:
              </span>
              <span className='font-zen-body font-light text-base text-right col-span-4 md:col-span-2 truncate'>
                {displayAmount(
                  {
                    amount: annualFee,
                    currency: contributors?.amount?.currency,
                  },
                  { hideCurrency: true },
                )}
              </span>
              <span className='hidden md:block md:col-span-8' />
              <span className='font-zen-body font-light text-base col-span-8 md:col-span-2'>
                Processing Fee:
              </span>
              <span className='font-zen-body font-light text-base text-right col-span-4 md:col-span-2 truncate'>
                {displayAmount(
                  {
                    amount: processingFee,
                    currency: contributors?.amount?.currency,
                  },
                  { hideCurrency: true },
                )}
              </span>
              <span className='hidden md:block md:col-span-8' />
            </div>
          </div>

          <div className='grid grid-flow-row gap-8'>
            <div>
              <div className='flex flex-row justify-between items-center mb-4'>
                <span className='font-zen-body font-light text-base'>
                  Contributions ({contributors?.totalCount})
                </span>
                <ZenButton
                  label='Export'
                  variant='primary-outline'
                  LeftIconComponent={
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      className='text-primary-blue mr-1'
                      fontSize={14}
                    />
                  }
                  onClick={() => {
                    setIsOpen(true);
                    exportToCSV();
                  }}
                />
              </div>
            </div>
          </div>
        </ZenResourceContainer>
        <ZenRouterTabs tabs={revShareTabs} />

        <ZenSimpleConfirmationModal
          variant='info'
          title='Export to CSV?'
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          isSubmitting={false}
          hideBottomButtons
        >
          <div className='mt-3 space-x-3 flex flex-row items-start'>
            {exportData ? (
              <CSVLink
                data={parseCsv(exportData || '')}
                filename={`revshare-payments-${DateTime.fromMillis(
                  contributors?.createdAt! || 0,
                ).toFormat('MM-DD-yyyy')}.csv`}
                target='_blank'
                className='bg-primary-blue text-white rounded-lg py-2 px-4.5 w-full flex items-center justify-center text-center border-2 border-primary-blue'
                onClick={() => setIsOpen(false)}
              >
                Download
              </CSVLink>
            ) : (
              <ZenButton isSubmitting />
            )}
            <ZenButton
              label='Cancel'
              variant='secondary-gray-outline'
              onClick={() => setIsOpen(false)}
              isFullWidth
            />
          </div>
        </ZenSimpleConfirmationModal>
      </div>
    </ZenRoute>
  );
};
export default ZenAgentDetailRevShareContributors;
