import React from 'react';
import Dot from './Dot';
import ReferralAddress from './ReferralAddress';

interface AddressListProps {
  address: string[];
}

const AddressList: React.FC<AddressListProps> = ({ address }) => {
  const slicedAddressLen = address.length - 2;

  return (
    <div className='flex flex-row flex-wrap items-center justify-start space-x-1 px-3 pb-1'>
      {address.slice(0, 2).map((location, index) => {
        return (
          <>
            <ReferralAddress location={location} />
            {index <= slicedAddressLen && <Dot />}
          </>
        );
      })}

      {slicedAddressLen > 0 && (
        <p className='text-grey-600'>+{slicedAddressLen}</p>
      )}
    </div>
  );
};

export default AddressList;
