import { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps } from '../types';
import { cn } from '../utils/classUtils';
import FormErrorMessage from './FormErrorMessage';

export const DEFAULT_DECIMAL_LENGTH_FOR_CURRENCY = 2;

interface ControlledCurrencyInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  subLabel?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  errorBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  forceDecimal?: boolean;
  isRequired?: boolean;
}

const ControlledCurrencyInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  errorBorder = false,
  tooltipIndex,
  shouldUnregister = true,
  startAdornment,
  endAdornment,
  forceDecimal = false,
  isRequired = false,
  ...rest
}: ControlledCurrencyInputV7Props<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              <span>{label}</span>
              {!!subLabel && (
                <span className='font-primary-regular text-sm text-gray-400 ml-1'>
                  {subLabel}
                </span>
              )}
              {isRequired && <span className='text-error'>*</span>}
            </label>
          )}
          <div
            className={cn('flex items-stretch border rounded overflow-hidden', {
              'ring-1 ring-primary': focus && !noBorder,
              'border-none': noBorder,
              'border-red-600': !focus && errorBorder && error,
            })}
          >
            {startAdornment && <div>{startAdornment}</div>}
            <CurrencyInput
              id={name}
              value={value}
              onFocus={() => setFocus(true)}
              onBlur={() => {
                setFocus(false);

                if (forceDecimal && value) {
                  onChange(
                    parseFloat(value).toFixed(
                      DEFAULT_DECIMAL_LENGTH_FOR_CURRENCY,
                    ),
                  );
                }

                onBlur();
              }}
              onValueChange={onChange}
              name={name}
              readOnly={readOnly}
              placeholder={placeholder}
              className={cn(
                'appearance-none p-1.5 w-full border-none focus:outline-none focus:ring-0',
                {
                  'bg-gray-50': readOnly,
                },
              )}
              data-tooltip-index={tooltipIndex}
              allowDecimals
            />
            {endAdornment && <div>{endAdornment}</div>}
          </div>
          {error && !hideErrorMessage && (
            <FormErrorMessage message={error.message} />
          )}
        </div>
      )}
    />
  );
};

export default ControlledCurrencyInputV7;
