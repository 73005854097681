import React from 'react';
import { ApplicantAgentDto } from '../../../openapi/arrakis';
import ReferralAgentStack from './ReferralAgentStack';

interface AppliedAgentsProps {
  applicants: ApplicantAgentDto[];
}

const AppliedAgents: React.FC<AppliedAgentsProps> = ({ applicants }) => {
  return (
    <div className='flex flex-row items-center rounded-md space-x-1'>
      <ReferralAgentStack ids={applicants.map((agent) => agent.agentId!)} />
      <p className='text-grey-600'>{applicants.length} Applied</p>
    </div>
  );
};

export default AppliedAgents;
