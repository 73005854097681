import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowUpRightFromSquare,
  faEye,
} from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import GeminiButton from '../../../components/Gemini/GeminiButton';
import {
  ReferralCentralReferralDto,
  ReferralCentralReferralDtoStatusEnum,
} from '../../../openapi/arrakis';

interface ReferralCardActionsProps {
  referralDetails: ReferralCentralReferralDto;
  isReferralAgent: boolean;
  isReceivingAgent: boolean;
  isApplicant: boolean;
  setIsReferralSidebarOpen?(): void;
}

type Match = {
  id: '123';
};

const ReferralCardActions: React.FC<ReferralCardActionsProps> = ({
  referralDetails,
  isReferralAgent,
  isReceivingAgent,
  isApplicant,
  setIsReferralSidebarOpen,
}) => {
  const containerClass = 'flex flex-row items-center space-x-3 pb-3 px-3';

  const history = useHistory();

  // static id until api is ready
  const { id } = { id: '123' } as Match;

  const handleNavigate = () => {
    history.push(`/referrals/${id}/applicants`);
  };

  if (
    isReferralAgent &&
    !referralDetails.receivingAgentId &&
    (referralDetails.status === ReferralCentralReferralDtoStatusEnum.Posted ||
      referralDetails.status === ReferralCentralReferralDtoStatusEnum.Paused)
  ) {
    return (
      <div className={containerClass}>
        <GeminiButton
          label='View Applicants'
          isFullWidth
          variant='primary-outline'
          onClick={handleNavigate}
        />
        <GeminiButton
          leftIcon={faEye}
          variant='primary-outline'
          onClick={setIsReferralSidebarOpen}
        />
      </div>
    );
  }

  if (!!referralDetails.transactionId && isReferralAgent) {
    return (
      <div className={containerClass}>
        <GeminiButton
          label='View Details'
          isFullWidth
          variant='primary-outline'
          onClick={setIsReferralSidebarOpen}
        />
        <GeminiButton
          leftIcon={faArrowUpRightFromSquare}
          variant='primary-outline'
        />
      </div>
    );
  }

  if (!referralDetails.transactionId && isReferralAgent) {
    return (
      <div className={containerClass}>
        <GeminiButton
          label='View Details'
          isFullWidth
          variant='primary-outline'
          onClick={setIsReferralSidebarOpen}
        />
      </div>
    );
  }

  if (isReceivingAgent && !!referralDetails.transactionId) {
    return (
      <div className={containerClass}>
        <GeminiButton
          label='View Listing'
          isFullWidth
          variant='primary-outline'
          rightIcon={faArrowUpRightFromSquare}
        />
        <GeminiButton
          leftIcon={faEye}
          variant='primary-outline'
          onClick={setIsReferralSidebarOpen}
        />
      </div>
    );
  }

  if (isReceivingAgent && !referralDetails.transactionId) {
    return (
      <div className={containerClass}>
        <GeminiButton
          leftIcon={faPlus}
          label='Create Listing'
          isFullWidth
          variant='primary-outline'
        />
        <GeminiButton
          leftIcon={faEye}
          variant='primary-outline'
          onClick={setIsReferralSidebarOpen}
        />
      </div>
    );
  }

  if (isApplicant) {
    return (
      <div className={containerClass}>
        <GeminiButton
          label='Withdraw Application'
          isFullWidth
          variant='danger-outline'
          onClick={() => {}}
        />
        <GeminiButton
          leftIcon={faEye}
          variant='primary-outline'
          onClick={setIsReferralSidebarOpen}
        />
      </div>
    );
  }

  return <> </>;
};

export default ReferralCardActions;
