import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAgentsInfo from '../../../hooks/useAgentsInfo';
import { TransactionResponse } from '../../../openapi/arrakis';
import { fetchJourney, saveJourney } from '../../../slices/CheckListSlice';
import { RootState } from '../../../types';
import ResourceContainer from '../../ResourceContainer';
import { getPageTitleForTransaction } from '../../../utils/TransactionUtils';
import ZenRoute from '../ZenRoute';
import ZenVerticalJourneyStepProgress from './ZenVerticalJourneyStepProgress';

interface ZenJourneyProps {
  transaction: TransactionResponse;
}

const ZenJourney: React.FC<ZenJourneyProps> = ({ transaction }) => {
  const dispatch = useDispatch();
  const {
    checklist: { journey, checklistLoading, checklistErrorCode },
  } = useSelector((state: RootState) => state);
  const agentIds = (journey?.items || [])
    .map((item) => item.assignee!)
    .filter((assignee) => !!assignee);
  const markedAgentIds = (journey?.items || [])
    .map((item) => item?.markedCompletedBy!)
    .filter((markedAgent) => !!markedAgent);

  useAgentsInfo([...agentIds, ...markedAgentIds]);

  useEffect(() => {
    if (transaction?.journeyId) {
      dispatch(fetchJourney(transaction?.journeyId!));
    }

    return () => {
      // clear the journey state
      dispatch(saveJourney(null));
    };
  }, [dispatch, transaction?.journeyId]);

  return (
    <ZenRoute
      title={`${getPageTitleForTransaction(transaction)} Road to Success - ${
        transaction.address?.oneLine
      }`}
    >
      <div className='grid grid-cols-1 md:grid-cols-2 my-7 mx-0 md:mx-4 gap-4 scrollbar overflow-x-auto'>
        <ResourceContainer
          loading={checklistLoading}
          isEmpty={!journey?.items}
          resourceName='Journey'
          errorCode={checklistErrorCode}
        >
          <div>
            <div className='py-4 my-2.5'>
              <ZenVerticalJourneyStepProgress steps={journey?.items || []} />
            </div>
          </div>
        </ResourceContainer>
      </div>
    </ZenRoute>
  );
};

export default ZenJourney;
