import { faCalendar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { JV_BOOK_MEETING_LINK } from '../../constants/JointVentureConstants';

interface LearnMoreBookMeetingProps {}

const LearnMoreBookMeeting: React.FC<LearnMoreBookMeetingProps> = () => {
  return (
    <div className='px-12 py-10 w-full bg-zen-light-blue'>
      <div className=''>
        <p className='text-dark text-xl font-zen-title font-medium leading-7'>
          Have questions?
        </p>
      </div>
      <div className='bg-white mt-6 border border-zen-dark-4 rounded-md flex items-center'>
        <div className='border-r border-zen-dark-4 p-10'>
          <FontAwesomeIcon
            icon={faCalendar}
            size='5x'
            className='text-primary'
          />
        </div>
        <div className='p-10 flex-grow flex flex-col justify-center'>
          <div className=''>
            <p className='text-dark text-xl font-zen-title font-medium leading-5'>
              The Real Title Team is here to help.
            </p>
            <a
              href={JV_BOOK_MEETING_LINK}
              target='_blank'
              className='text-primary-blue mt-1 text-sm font-zen-body font-semibold leading-6'
              rel='noreferrer'
            >
              Book a Meeting
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnMoreBookMeeting;
