import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MSDynamicOnboardingFormData } from '../../routes/MSDynamicsOnboardingRoute';
import { ZenRadioOptionComponent } from '../Zen/Input/ZenControlledRadioInput';
import { cn } from '../../utils/classUtils';

const MsDynamicTaxFormOptionComponent: ZenRadioOptionComponent<MSDynamicOnboardingFormData> = ({
  name,
  label,
  subLabel,
  value,
  checked,
  invalid,
  onChange,
}) => {
  return (
    <div>
      <input name={name} className='hidden' />
      <button
        onClick={() => onChange(value)}
        className={cn(
          'flex flex-col justify-between w-[232px] h-44 font-zen-body space-y-5 border px-3.5 py-7 rounded-[10px]',
          invalid ? 'border-zen-danger' : 'border-zen-dark-9',
        )}
      >
        <div className='w-full flex justify-between items-center '>
          <div className='font-zen-body font-bold'>{label}</div>
          {checked && (
            <div>
              <FontAwesomeIcon
                icon={solid('check-circle')}
                className='text-primary-blue'
                size='xl'
              />
            </div>
          )}
        </div>
        <p className='flex-grow text-left text-sm leading-snug'>{subLabel}</p>
      </button>
    </div>
  );
};

export default MsDynamicTaxFormOptionComponent;
