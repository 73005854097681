import { Button as MButton, ButtonProps as MButtonProps } from '@mantine/core';
import { cn } from '../../utils/classUtils';
import { combineDefaultClassnamesWithCustomClassnames } from './utils';

export type Color = 'blue' | 'red' | 'green' | 'black' | 'grey';
type Variant = 'filled' | 'outline' | 'link';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  MButtonProps & {
    color?: Color;
    variant?: Variant;
  };

type FilledStyles = {
  background: string;
  border: string;
  color: string;
  [key: string]: string;
};
const baseFilledStyles = 'border-2';
const filledStyles: Record<Color, FilledStyles> = {
  blue: {
    background: 'bg-rezen-blue-600',
    border: 'border-rezen-blue-600',
    color: 'text-primary-light',
  },
  red: {
    background: 'bg-red-600',
    border: 'border-red-600',
    color: 'text-primary-light',
  },
  green: {
    background: 'bg-green-600',
    border: 'border-green-600',
    color: 'text-primary-light',
  },
  black: {
    background: 'bg-primary-dark',
    border: 'border-primary-dark',
    color: 'text-primary-light',
  },
  grey: {
    background: 'bg-grey-500',
    border: 'border-grey-500',
    color: 'text-primary-light',
  },
} as const;

type OutlineStyles = {
  color: string;
  borderColor: string;
  [key: string]: string;
};
const baseOutlineStyles = 'border-2 bg-primary-light';
const outlineStyles: Record<Color, OutlineStyles> = {
  blue: {
    color: 'text-rezen-blue-600',
    borderColor: 'border-rezen-blue-600',
  },
  red: {
    color: 'text-coral-red',
    borderColor: 'border-coral-red',
  },
  green: {
    color: 'text-green-600',
    borderColor: 'border-green-600',
  },
  black: {
    color: 'text-primary-dark',
    borderColor: 'border-primary-dark',
  },
  grey: {
    color: 'text-grey-600',
    borderColor: 'border-grey-300',
  },
} as const;

type LinkStyles = {
  color: string;
  hover: string;
  [key: string]: string;
};
const linkStyles: Record<Color, LinkStyles> = {
  blue: {
    color: 'text-rezen-blue-600',
    hover: 'hover:bg-rezen-light-blue-100',
  },
  red: {
    color: 'text-coral-red',
    hover: 'hover:bg-red-100',
  },
  green: {
    color: 'text-green-600',
    hover: 'hover:bg-green-100',
  },
  black: {
    color: 'text-primary-dark',
    hover: 'hover:bg-grey-100',
  },
  grey: {
    color: 'text-grey-600',
    hover: 'hover:bg-grey-100',
  },
} as const;

const getVariantColors = (color: Color, variant: Variant) => {
  let map = {};
  switch (variant) {
    case 'filled':
      map = {
        defaults: baseFilledStyles,
        ...filledStyles[color],
      };
      break;
    case 'outline':
      map = {
        defaults: baseOutlineStyles,
        ...outlineStyles[color],
      };
      break;
    case 'link':
      map = linkStyles[color];
      break;
  }
  return cn(Object.values(map));
};

const defaultStyles: ButtonProps['classNames'] = {
  root: 'flex space-x-1 rounded-md py-2 px-2 justify-center font-inter',
  inner: 'flex items-center space-x-1',
  leftIcon: 'flex items-center',
  label: 'font-inter text-base',
};

export const Button: React.FC<ButtonProps> = (props) => {
  const color = props.color ?? 'blue';
  const variant = props.variant ?? 'filled';

  return (
    <MButton
      unstyled
      {...props}
      classNames={combineDefaultClassnamesWithCustomClassnames(
        defaultStyles,
        {
          ...(props.disabled && {
            root:
              'disabled:cursor-not-allowed disabled:bg-grey-300 disabled:border-grey-300 disabled:text-primary-light',
          }),
        },
        {
          root: cn(
            props.fullWidth && 'w-full',
            props.loading || props.disabled
              ? 'cursor-not-allowed'
              : 'cursor-pointer',
            getVariantColors(color, variant),
          ),
        },
        props.classNames,
      )}
    />
  );
};
