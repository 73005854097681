import { DateTime } from 'luxon';
import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faHourglassStart } from '@fortawesome/pro-regular-svg-icons';
import useAgentsInfo from '../../../../hooks/useAgentsInfo';
import { TransactionResponse } from '../../../../openapi/arrakis';
import { RootState } from '../../../../types';
import { cn } from '../../../../utils/classUtils';

interface ZenMarkAsCompliantStatusProps {
  transaction: TransactionResponse;
}

const ZenMarkAsCompliantStatus: React.FC<ZenMarkAsCompliantStatusProps> = ({
  transaction,
}) => {
  const { agentById } = useSelector((state: RootState) => state.userIds);
  const agentId = transaction?.complianceApprovedBy;
  const agent = agentById[agentId!];

  useAgentsInfo([agentId!]);

  const isCompliant = !!transaction.compliantAt;

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col'>
        <div
          className={cn(
            'flex flex-row space-x-1 items-start text-zen-body font-zen-body font-semibold',
            isCompliant ? 'text-green-400' : 'text-zen-warning-4',
          )}
        >
          <div className='flex items-center h-5 justify-center'>
            <FontAwesomeIcon
              size='sm'
              icon={isCompliant ? faCheckCircle : faHourglassStart}
            />
          </div>
          <p className='text-sm'>
            {isCompliant
              ? `File was Reviewed and has been Marked Compliant by ${
                  agent && `${agent.firstName} ${agent.lastName}`
                } on ${DateTime.fromMillis(transaction.compliantAt!).toFormat(
                  'LL/dd/yyyy',
                )}.`
              : 'Waiting for all broker-required checklist items to be uploaded and accepted.'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ZenMarkAsCompliantStatus;
