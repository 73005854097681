import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateTaxExempt } from '../../../slices/TransactionSlice';
import ZenToggleRow from '../../Zen/Input/ZenToggleRow';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import AnalyticsService from '../../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../../types';

interface ZenTransactionTaxExemptProps {
  id: string;
  taxExempt: boolean;
  isReadonly: boolean;
}

const ZenTransactionTaxExempt: React.FC<ZenTransactionTaxExemptProps> = ({
  id,
  taxExempt,
  isReadonly,
}) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <div className='flex flex-row items-center'>
      {!isReadonly && (
        <div
          data-testid='tax-exempt'
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <ZenToggleRow onChange={() => {}} value={taxExempt} />
        </div>
      )}
      <p>{taxExempt ? 'Yes' : 'No'}</p>

      <ZenConfirmationModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        variant='primary'
        isSubmitting={isSubmitting}
        isDisabled={isSubmitting}
        onConfirm={async () => {
          setIsSubmitting(true);
          AnalyticsService.instance().logEvent(
            taxExempt
              ? AnalyticsEventEnum.TAX_COLLECTION_TOGGLE_TAX_EXEMPT_NO_ON_TRANSACTION
              : AnalyticsEventEnum.TAX_COLLECTION_TOGGLE_TAX_EXEMPT_YES_ON_TRANSACTION,
            { transaction: { id } },
          );
          await dispatch(updateTaxExempt(id, { taxExempt: !taxExempt }));
          setIsSubmitting(false);
          setIsOpen(false);
        }}
        cancelButtonText='No'
        confirmButtonText='Yes'
        title={
          taxExempt
            ? 'Set as Not Tax-exempt?'
            : 'Set transaction as Tax-exempt?'
        }
        subtitle={
          taxExempt
            ? 'This action will set this transaction as Not Tax-exempt. Please make sure to upload required documentation. Set as Not tax-exempt?'
            : 'This action will set this transaction as Tax-exempt. Please make sure to upload required documentation. Set as tax-exempt?'
        }
      />
    </div>
  );
};

export default ZenTransactionTaxExempt;
