import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AgentOnboardingLayout from '../components/AgentOnboardingLayout';
import DefaultLoader from '../components/DefaultLoader';
import StepByStepContainer, {
  StepConfig,
} from '../components/StepByStep/StepByStepContainer';
import ZenTransactionCertifyClosing from '../components/transactions/CheckDeposit/ZenTransactionCertifyClosing';
import ZenTransactionCheckDepositBasicInfo from '../components/transactions/CheckDeposit/ZenTransactionCheckDepositBasicInfo';
import ZenTransactionCheckDepositConfirmation from '../components/transactions/CheckDeposit/ZenTransactionCheckDepositConfirmation';
import ZenTransactionCheckDepositReview from '../components/transactions/CheckDeposit/ZenTransactionCheckDepositReview';
import ZenRoute from '../components/Zen/ZenRoute';
import { EscrowResponseEscrowTypeEnum } from '../openapi/arrakis';
import {
  getCheckDepositsList,
  saveUploadedCheckDeposits,
} from '../slices/CheckDepositsSlice';
import { fetchTransactionDetails } from '../slices/TransactionSlice';
import { RootState, YesNoType } from '../types';
import { getTransactionClosingDate } from '../utils/CheckDepositsHelper';

export type Match = {
  id: string;
  escrowId?: string;
};

export enum DepositCheckStepName {
  CERTIFY_CLOSING = 'CERTIFY_CLOSING',
  CHECK_INFO = 'CHECK_INFO',
  REVIEW = 'REVIEW',
  CONFIRM = 'CONFIRM',
}

export interface DepositCheckFormData {
  isExternalCheckDeposit?: boolean;
  checkDepositId?: string;
  depositorName?: string;
  depositorEmail?: string;
  depositorPhoneNumber?: string;
  depositRequiredBy?: string;
  depositAmount: string | undefined;
  frontCheckImage: File | undefined;
  backCheckImage: File | undefined;
  amount: string | undefined;
  scannedAmount: string | undefined;
  routingNo: string | undefined;
  accountNo: string | undefined;
  checkNo: string | undefined;
  closingDate?: string | undefined;
  closeAt?: string | undefined;
  acceptTermsAndConditions: YesNoType | undefined;
  escrowId: string | undefined;
  showClosing: boolean;
  warning?: YesNoType | undefined;
}

export const CheckDepositSteps: StepConfig<
  DepositCheckFormData,
  DepositCheckStepName
>[] = [
  {
    name: DepositCheckStepName.CERTIFY_CLOSING,
    Component: ZenTransactionCertifyClosing,
    showStepIf: ({ closeAt, showClosing }) => !closeAt && showClosing,
    hidePagination: true,
  },
  {
    name: DepositCheckStepName.CHECK_INFO,
    Component: ZenTransactionCheckDepositBasicInfo,
    hidePagination: true,
  },
  {
    name: DepositCheckStepName.REVIEW,
    Component: ZenTransactionCheckDepositReview,
    hidePagination: true,
  },
  {
    name: DepositCheckStepName.CONFIRM,
    Component: ZenTransactionCheckDepositConfirmation,
    hidePagination: true,
  },
];

const ZenTransactionDepositCheckRoute: React.FC = () => {
  const history = useHistory();
  const { id: transactionId, escrowId } = useParams() as Match;
  const dispatch = useDispatch();
  const {
    transaction: {
      transactionDetailResponse: {
        data: transactionDetail,
        loading: transactionLoading,
      },
    },
    checkDeposits: { checkDepositsList, checkDepositsListLoading },
  } = useSelector((state: RootState) => state);
  const escrow = escrowId
    ? transactionDetail?.escrows?.find((escrow) => escrow.id === escrowId)
    : undefined;
  const showClosing =
    !escrowId ||
    escrow?.escrowType !== EscrowResponseEscrowTypeEnum.TrustDeposit;

  const defaultValues: DepositCheckFormData = {
    isExternalCheckDeposit: false,
    depositAmount: undefined,
    accountNo: undefined,
    backCheckImage: undefined,
    checkNo: undefined,
    frontCheckImage: undefined,
    amount: undefined,
    scannedAmount: undefined,
    routingNo: undefined,
    closingDate: !escrowId
      ? getTransactionClosingDate(
          transactionDetail?.closedAt,
          checkDepositsList,
        )
      : undefined,
    closeAt: !escrowId
      ? getTransactionClosingDate(
          transactionDetail?.closedAt,
          checkDepositsList,
        )
      : undefined,
    acceptTermsAndConditions: undefined,
    escrowId,
    showClosing,
  };

  const onSubmit = () => {
    history.push(
      !escrowId
        ? `/transactions/${transactionId}`
        : `/transactions/${transactionId}/view-deposits`,
    );
  };

  useEffect(() => {
    if (transactionId !== transactionDetail?.id!) {
      dispatch(fetchTransactionDetails(transactionId));
    }
  }, [dispatch, transactionId, transactionDetail]);

  useEffect(() => {
    if (!checkDepositsList && !escrowId) {
      dispatch(getCheckDepositsList(transactionId));
    }
  }, [dispatch, checkDepositsList, transactionId, escrowId]);

  useEffect(() => {
    return () => {
      dispatch(saveUploadedCheckDeposits(undefined));
    };
  }, [dispatch]);

  if (transactionLoading || checkDepositsListLoading) {
    return <DefaultLoader />;
  }

  return (
    <ZenRoute title='Deposit Check'>
      <AgentOnboardingLayout
        title='Deposit Check'
        onClose={() =>
          history.push(
            escrowId
              ? `/transactions/${transactionId}/view-deposits`
              : `/transactions/${transactionId}`,
          )
        }
        hideLogout
      >
        <StepByStepContainer<DepositCheckFormData, DepositCheckStepName>
          steps={CheckDepositSteps}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          mode='onChange'
          reValidateMode='onChange'
        />
      </AgentOnboardingLayout>
    </ZenRoute>
  );
};

export default ZenTransactionDepositCheckRoute;
