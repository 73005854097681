import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useFormContext } from 'react-hook-form-v7';
import { AnalyticsEventEnum } from '../../../../types';
import ZenControlledSquareToggleInput from '../../../Zen/Input/ZenControlledSquareToggleInput';
import { WealthPlanTooltip } from '../../WealthManagementUtils';
import { GoalsFormInput } from '../SetGoalsSideBarV2';
import WealthManagementCardV2 from '../WealthManagementCardV2';

interface AdditionalRealStocksFormInputsProps {
  logAnalyticsEvent: (event: string) => void;
}

const AdditionalRealStocksFormInputs: React.FC<AdditionalRealStocksFormInputsProps> = ({
  logAnalyticsEvent,
}) => {
  const { control, watch, setValue } = useFormContext<GoalsFormInput>();

  const additionalStocks = watch('additionalRealShares');

  return (
    <WealthManagementCardV2 title='Additional Real Stock'>
      <div className='flex items-start justify-between'>
        <span className='font-zen-body font-semibold text-zen-dark-9 w-10/12'>
          Do you own additional Real Stock shares in an external account?
          <WealthPlanTooltip
            title='We will use this additional information in the calculation of your total Real Wealth.'
            arrow
          >
            <FontAwesomeIcon
              icon={faCircleInfo}
              size='sm'
              className='ml-1 mt-0.5'
            />
          </WealthPlanTooltip>
        </span>
        <div>
          <ZenControlledSquareToggleInput<
            GoalsFormInput,
            'ownsAdditionalRealStockShares'
          >
            control={control}
            name='ownsAdditionalRealStockShares'
            defaultValue={additionalStocks > 0}
            onChangeSpy={(value) => {
              if (value) {
                setValue('additionalRealShares', 1);
              } else {
                setValue('additionalRealShares', 0);
              }
              logAnalyticsEvent(
                value
                  ? AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_EXTERNAL_REAX_SHARES_TO_YES
                  : AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_EXTERNAL_REAX_SHARES_TO_NO,
              );
            }}
          />
        </div>
      </div>
      <div className='border-b border-gray-200 my-4' />
      <div className='flex gap-4 place-content-between'>
        <span className='font-zen-body font-semibold text-zen-dark-9 w-10/12'>
          How many additional shares do you own?
        </span>
        <div>
          <CurrencyInput
            name='additionalRealShares'
            className='appearance-none p-2 border-zen-dark-5 rounded-lg bg-white focus:outline-none focus:ring-0 w-full'
            placeholder='Enter a number'
            allowDecimals={false}
            value={additionalStocks ?? 0}
            onValueChange={(value) => {
              logAnalyticsEvent(
                AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_EXTERNAL_SHARES,
              );
              setValue('additionalRealShares', value ? +value : 0);
            }}
            allowNegativeValue={false}
          />
        </div>
      </div>
    </WealthManagementCardV2>
  );
};

export default AdditionalRealStocksFormInputs;
