import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  faAdd,
  faAddressCard,
  faTrash,
  faTrashCan,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, values } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useRef } from 'react';
import { useFieldArray, useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '../../constants/TableConstants';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import {
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
  AdministrativeAreaControllerApi,
  AddressResponseCountryEnum,
  AgentResponseAccountCountryEnum,
  CompanyControllerApi,
  CompanyResponse,
  CreateOfficeRequest,
  CreateOfficeRequestAdditionalFeesAndRebatesEnum,
  CreateOfficeRequestPaymentBatchNameEnum,
  CreateOfficeRequestPaymentSystemEnum,
  CreateOfficeRequestTransactionTypeEnum,
  OfficeResponsePaymentBatchNameEnum,
  TaxValueTypeEnum,
  UserResponseAgentStatusEnum,
} from '../../openapi/yenta';
import AnalyticsService from '../../services/AnalyticsService';
import ErrorService from '../../services/ErrorService';
import {
  bankAccountSearchRequest,
  createOffice,
} from '../../slices/OfficeSlice';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { countryMapState } from '../../testUtils/OnboardingUtils';
import {
  AnalyticsEventEnum,
  AppDispatch,
  AsyncSelectOption,
  ISelectOption,
  OfficeAddressTypeEnum,
  RootState,
  YesNoType,
} from '../../types';
import { getISelectOptionDefaultValue } from '../../utils/FormUtils';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum, MONEY_AMOUNT_REGEX } from '../../utils/StringUtils';
import { getSupportEmail } from '../../utils/SupportUtils';
import { searchForAgents } from '../../utils/TableUtils';
import { getValidTaxIdTypes } from '../../utils/TaxIDsUtils';
import {
  EMAIL_VALIDATIONS,
  getPostalCodeValidation,
  PHONE_NUMBER_VALIDATIONS,
} from '../../utils/Validations';
import FormErrorMessage from '../FormErrorMessage';
import ResourceContainer from '../ResourceContainer';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledDatePickerInput from '../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledPhoneNumberInput from '../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledRadioInput from '../Zen/Input/ZenControlledRadioInput';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalActionFooter from '../Zen/Modal/ZenSidebarModalActionFooter';
import ZenButton from '../Zen/ZenButton';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import { cn } from '../../utils/classUtils';

export enum RealWithholdsTaxEnum {
  AGENT_REMITS = 'Agent remits',
  REAL_HOLDS_AND_REMITS = 'Real holds and remits',
}

export interface ModifiedTaxValueType {
  type?: ISelectOption<TaxValueTypeEnum> | null;
  rate?: number | null;
  taxId?: string | null;
}

interface Address {
  streetAddress1: string;
  streetAddress2?: string;
  city: string;
  type?: ISelectOption;
  country?: ISelectOption<
    AddressRequestCountryEnum | AddressResponseCountryEnum
  >;
  stateOrProvince?: ISelectOption;
  zipOrPostalCode: string;
}

interface FormData {
  name: string;
  transactionType?: ISelectOption;
  addresses: Address[];
  phoneNumber: string;
  emailAddress: string;
  administrativeAreaId: ISelectOption;
  paymentInstructions: string;
  brokerageLicenseNumber: string;
  licenseExpirationDate: string;
  cdaDocumentTitle: string;
  designatedBrokerId: ISelectOption;
  managingBrokerId?: ISelectOption;
  bankAccountId: ISelectOption;
  trustAccountId?: ISelectOption;
  operatingAccountId?: ISelectOption;
  companyId: ISelectOption;
  faxNumber: string;
  invoiceDocumentTitle: string;
  paysOtherAgent?: ISelectOption;
  leasePaysOtherAgent?: ISelectOption;
  acceptsEscrow?: ISelectOption;
  paysCommissionFromTrust: ISelectOption;
  allowsLease?: ISelectOption;
  usesTipalti?: ISelectOption;
  taxes: ModifiedTaxValueType[];
  realWithholdsTax: ISelectOption<RealWithholdsTaxEnum>;
  additionalCommission: YesNoType;
  rebate: YesNoType;
  transactionCoordinatorFee: YesNoType;
  reimbursementFee: YesNoType;
  mlsFee: YesNoType;
  oneRealImpactFundFee?: YesNoType;
  paymentBatchName: ISelectOption;
}

const PAYMENT_BATCH_NAME_OPTIONS = values(
  OfficeResponsePaymentBatchNameEnum,
).map((value) => ({
  label: capitalizeEnum(value),
  value,
}));

interface ZenCreateOfficeFormProps {
  isOpen: boolean;
  onClose(): void;
}

const ZenCreateOfficeForm: React.FC<ZenCreateOfficeFormProps> = ({
  isOpen,
  onClose,
}) => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    defaultValues: {
      transactionType: undefined,
      addresses: [
        {
          type: {
            label: capitalizeEnum(OfficeAddressTypeEnum.OFFICE),
            value: OfficeAddressTypeEnum.OFFICE,
          },
          streetAddress2: '',
          country: undefined,
          stateOrProvince: undefined,
        },
      ],
      cdaDocumentTitle: 'Commission Disbursement Authorization',
      invoiceDocumentTitle: 'Invoice',
      paymentInstructions: `Please email a signed Settlement Statement to ${getSupportEmail(
        userDetail?.accountCountry,
      )} and wire funds to:`,
      paysOtherAgent: { label: 'No', value: YesNoType.NO },
      leasePaysOtherAgent: { label: 'No', value: YesNoType.NO },
      acceptsEscrow: { label: 'No', value: YesNoType.NO },
      paysCommissionFromTrust: { label: 'No', value: YesNoType.NO },
      allowsLease: { label: 'Yes', value: YesNoType.YES },
      usesTipalti: { label: 'No', value: YesNoType.NO },
      additionalCommission: YesNoType.YES,
      rebate: YesNoType.YES,
      transactionCoordinatorFee: YesNoType.YES,
      reimbursementFee: YesNoType.YES,
      mlsFee: YesNoType.YES,
      oneRealImpactFundFee: YesNoType.YES,

      realWithholdsTax: getISelectOptionDefaultValue(
        RealWithholdsTaxEnum.AGENT_REMITS,
      ),
      paymentBatchName: PAYMENT_BATCH_NAME_OPTIONS.find(
        ({ value }) => value === OfficeResponsePaymentBatchNameEnum.Rezen,
      ),
    },
  });

  const { fields, remove, append, update } = useFieldArray({
    control,
    name: 'taxes',
  });

  const taxesErrorMsg = get(errors.taxes, 'message');

  const yesNoOptions: ISelectOption[] = [
    { label: 'Yes', value: YesNoType.YES },
    { label: 'No', value: YesNoType.NO },
  ];

  const previousCountry = useRef<AddressRequestCountryEnum>();
  const [selectedCountry, realWithholdsTax] = watch([
    `addresses.${0}.country`,
    'realWithholdsTax',
  ]);

  useEffect(() => {
    setValue(
      'paymentInstructions',
      `Please email a signed Settlement Statement to ${getSupportEmail(
        (selectedCountry?.value as unknown) as AgentResponseAccountCountryEnum,
      )} and wire funds to:`,
    );
  }, [selectedCountry, setValue]);

  useEffect(() => {
    // reset tax ids
    if (previousCountry.current !== selectedCountry?.value) {
      previousCountry.current = selectedCountry?.value as AddressRequestCountryEnum;
      fields.forEach((_, index) => {
        update(index, {
          taxId: null,
          rate: null,
          type: null,
        });
      });
    }
  }, [fields, selectedCountry?.value, update]);

  useDidUpdateEffect(() => {
    if (realWithholdsTax?.value === RealWithholdsTaxEnum.AGENT_REMITS) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.TAX_COLLECTION_CLICK_AGENT_REMITS_ON_OFFICE,
      );
    }
    if (
      realWithholdsTax?.value === RealWithholdsTaxEnum.REAL_HOLDS_AND_REMITS
    ) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.TAX_COLLECTION_CLICK_REAL_HOLDS_AND_REMITS_ON_OFFICE,
      );
    }
  }, [realWithholdsTax]);

  const onSubmit = async (formValues: FormData) => {
    const address = formValues.addresses[0];
    const additionalFeesAndRebates: Array<CreateOfficeRequestAdditionalFeesAndRebatesEnum> = [];

    if (formValues.additionalCommission === YesNoType.YES) {
      additionalFeesAndRebates.push(
        CreateOfficeRequestAdditionalFeesAndRebatesEnum.AdditionalCommission,
      );
    }

    if (formValues.rebate === YesNoType.YES) {
      additionalFeesAndRebates.push(
        CreateOfficeRequestAdditionalFeesAndRebatesEnum.Rebate,
      );
    }

    if (formValues.transactionCoordinatorFee === YesNoType.YES) {
      additionalFeesAndRebates.push(
        CreateOfficeRequestAdditionalFeesAndRebatesEnum.TransactionCoordinatorFee,
      );
    }

    if (formValues.reimbursementFee === YesNoType.YES) {
      additionalFeesAndRebates.push(
        CreateOfficeRequestAdditionalFeesAndRebatesEnum.ReimbursementFee,
      );
    }

    if (formValues.mlsFee === YesNoType.YES) {
      additionalFeesAndRebates.push(
        CreateOfficeRequestAdditionalFeesAndRebatesEnum.MlsFee,
      );
    }

    if (formValues.oneRealImpactFundFee === YesNoType.YES) {
      additionalFeesAndRebates.push(
        CreateOfficeRequestAdditionalFeesAndRebatesEnum.OneRealImpactFund,
      );
    }

    const createOfficeRequest: Required<CreateOfficeRequest> = {
      address: {
        city: address?.city,
        country: (address?.country
          ?.value as unknown) as AddressRequestCountryEnum,
        stateOrProvince: address?.stateOrProvince
          ?.value as AddressRequestStateOrProvinceEnum,
        streetAddress1: address?.streetAddress1,
        streetAddress2: address?.streetAddress2,
        zipOrPostalCode: address?.zipOrPostalCode,
        type: OfficeAddressTypeEnum.OFFICE,
      },
      name: formValues.name,
      invoiceDocumentTitle: formValues.invoiceDocumentTitle,
      faxNumber: formValues.faxNumber,
      cdaDocumentTitle: formValues.cdaDocumentTitle,
      brokerageLicenseNumber: formValues.brokerageLicenseNumber,
      licenseExpirationDate: formValues.licenseExpirationDate,
      paymentInstructions: formValues.paymentInstructions,
      emailAddress: formValues.emailAddress,
      phoneNumber: formValues.phoneNumber,
      paymentSystem:
        formValues.usesTipalti?.value === YesNoType.YES
          ? CreateOfficeRequestPaymentSystemEnum.Tipalti
          : CreateOfficeRequestPaymentSystemEnum.MicrosoftDynamics,
      acceptsEscrow: formValues.acceptsEscrow?.value === YesNoType.YES,
      paysOtherAgent: formValues.paysOtherAgent?.value === YesNoType.YES,
      leasePaysOtherAgent:
        formValues.leasePaysOtherAgent?.value === YesNoType.YES,
      allowsLease: formValues.allowsLease?.value === YesNoType.YES,
      additionalFeesAndRebates: additionalFeesAndRebates,
      boardIds: [],
      mlsIds: [],
      designatedBrokerId: formValues.designatedBrokerId?.value,
      managingBrokerId: formValues.managingBrokerId?.value!,
      bankAccountId: formValues.bankAccountId?.value,
      trustAccountId: formValues.trustAccountId?.value!,
      operatingAccountId: formValues.operatingAccountId?.value!,
      companyId: formValues.companyId?.value,
      administrativeAreaId: formValues.administrativeAreaId?.value!,
      taxes: formValues.taxes?.map((tax) => ({
        rate: tax?.rate!,
        taxId: tax?.taxId!,
        type: tax?.type?.value!,
      })),
      // @ts-ignore
      transactionType: formValues.transactionType?.value,
      paysCommissionFromTrust:
        formValues.paysCommissionFromTrust?.value === YesNoType.YES,
      realWithholdsTax:
        formValues.realWithholdsTax?.value ===
        RealWithholdsTaxEnum.REAL_HOLDS_AND_REMITS,
      paymentBatchName: formValues.paymentBatchName
        ?.value as CreateOfficeRequestPaymentBatchNameEnum,
    };

    formValues.addresses.forEach((address) => {
      if (address.type?.value === OfficeAddressTypeEnum.STATE) {
        createOfficeRequest.stateOfficeAddress = {
          city: address?.city,
          country: (address?.country
            ?.value as unknown) as AddressRequestCountryEnum,
          stateOrProvince: address?.stateOrProvince
            ?.value as AddressRequestStateOrProvinceEnum,
          streetAddress1: address?.streetAddress1,
          streetAddress2: address?.streetAddress2,
          zipOrPostalCode: address?.zipOrPostalCode,
          type: OfficeAddressTypeEnum.STATE,
        };
      }
      if (address.type?.value === OfficeAddressTypeEnum.BRANCH) {
        createOfficeRequest.branchOfficeAddress = {
          city: address?.city,
          country: (address?.country
            ?.value as unknown) as AddressRequestCountryEnum,
          stateOrProvince: address?.stateOrProvince
            ?.value as AddressRequestStateOrProvinceEnum,
          streetAddress1: address?.streetAddress1,
          streetAddress2: address?.streetAddress2,
          zipOrPostalCode: address?.zipOrPostalCode,
          type: OfficeAddressTypeEnum.BRANCH,
        };
      }
    });

    const id = await dispatch(createOffice(createOfficeRequest));

    if (id) {
      history.push(`/offices/${id}`);
    }
  };

  const {
    fields: addressFields,
    append: addressAppend,
    remove: addressRemove,
  } = useFieldArray({
    control,
    name: 'addresses',
  });

  const isAddAnotherAddressDisabled = addressFields.length >= 3;

  return (
    <ZenSidebarModal
      title='Create New Office'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='create-office-form'
      >
        <div className='p-6'>
          <div>
            <ZenControlledTextInput<FormData, 'name'>
              control={control}
              label='Office Name'
              name='name'
              placeholder='Enter Office Name'
              rules={{ required: 'Please provide an office name' }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, `transactionType`>
              control={control}
              name='transactionType'
              placeholder='Select Transaction Type'
              label='Transaction Type'
              options={values(CreateOfficeRequestTransactionTypeEnum).map(
                (type) => ({
                  label: capitalizeEnum(type),
                  value: type,
                }),
              )}
              rules={{ required: 'Please select a transaction type' }}
              isRequired
            />
          </div>
          <div>
            {addressFields.map((field, index) => {
              const [countrySelected, addresses] = watch([
                `addresses.${index}.country`,
                'addresses',
              ]);
              const showDeleteBtn = index !== 0;
              const otherAddressTypes = addresses
                ?.filter((_, i) => i !== index)
                .map((f) => f.type?.value);

              const officeAddressList =
                index === 0
                  ? [OfficeAddressTypeEnum.OFFICE]
                  : [OfficeAddressTypeEnum.STATE, OfficeAddressTypeEnum.BRANCH];

              return (
                <div key={field.id} className='relative py-3'>
                  {showDeleteBtn && (
                    <button
                      className='mt-1 absolute top-6 right-3'
                      data-testid='delete-address-btn'
                      onClick={() => addressRemove(index)}
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className='text-zen-danger'
                      />
                    </button>
                  )}
                  <div className='mt-5'>
                    <ZenControlledSelectInput<
                      FormData,
                      `addresses.${number}.type`
                    >
                      control={control}
                      label='Address Type'
                      name={`addresses.${index}.type`}
                      placeholder='Choose address type'
                      options={officeAddressList.map((type) => ({
                        value: type,
                        label: capitalizeEnum(type),
                      }))}
                      rules={{
                        required: 'Required',
                        validate: (val) => {
                          if (
                            val?.value &&
                            otherAddressTypes?.includes(val.value)
                          ) {
                            return "Can't have multiple addresses of the same type";
                          }
                          return undefined;
                        },
                      }}
                      isRequired
                    />
                  </div>
                  <div className='mt-5'>
                    <ZenControlledSelectInput<
                      FormData,
                      `addresses.${number}.country`
                    >
                      name={`addresses.${index}.country`}
                      control={control}
                      label='Country'
                      placeholder='Select Country'
                      options={values(AddressRequestCountryEnum).map(
                        (state) => ({
                          value: state,
                          label: capitalizeEnum(state),
                        }),
                      )}
                      rules={{ required: 'Please select a country' }}
                      isRequired
                    />
                  </div>
                  <div className='mt-5'>
                    <ZenControlledTextInput<
                      FormData,
                      `addresses.${number}.streetAddress1`
                    >
                      control={control}
                      name={`addresses.${index}.streetAddress1`}
                      placeholder='Enter the address'
                      label='Street Address Line 1'
                      rules={{ required: 'Please enter the street address' }}
                      isRequired
                    />
                  </div>
                  <div className='mt-5'>
                    <ZenControlledTextInput<
                      FormData,
                      `addresses.${number}.streetAddress2`
                    >
                      control={control}
                      name={`addresses.${index}.streetAddress2`}
                      placeholder='#100'
                      label='Street Address Line 2'
                    />
                  </div>
                  <div className='mt-5'>
                    <ZenControlledTextInput<
                      FormData,
                      `addresses.${number}.city`
                    >
                      control={control}
                      label='City'
                      name={`addresses.${index}.city`}
                      placeholder='City'
                      rules={{ required: 'Please enter the city' }}
                      isRequired
                    />
                  </div>
                  <div className='mt-5'>
                    <ZenControlledSelectInput<
                      FormData,
                      `addresses.${number}.stateOrProvince`
                    >
                      name={`addresses.${index}.stateOrProvince`}
                      control={control}
                      label='State / Province'
                      placeholder='Select State or Province'
                      options={
                        countryMapState[
                          (countrySelected?.value as AddressRequestCountryEnum) ||
                            AddressRequestCountryEnum.UnitedStates
                        ]
                      }
                      isRequired
                      rules={{ required: 'Please select a state / province.' }}
                    />
                  </div>
                  <div className='mt-5'>
                    <ZenControlledTextInput<
                      FormData,
                      `addresses.${number}.zipOrPostalCode`
                    >
                      control={control}
                      label='Postal Code'
                      name={`addresses.${index}.zipOrPostalCode`}
                      placeholder='Postal Code'
                      rules={{
                        required: 'Please enter the postal code',
                        ...getPostalCodeValidation(
                          countrySelected?.value as AddressRequestCountryEnum,
                        ),
                      }}
                      isRequired
                    />
                  </div>
                </div>
              );
            })}
            <button
              className='mt-2 flex items-center space-x-2'
              type='button'
              disabled={isAddAnotherAddressDisabled}
              onClick={() =>
                addressAppend({
                  country: undefined,
                  type: undefined,
                  city: undefined,
                  stateOrProvince: undefined,
                  streetAddress1: undefined,
                  streetAddress2: undefined,
                  zipOrPostalCode: undefined,
                })
              }
            >
              <FontAwesomeIcon
                icon={faAdd}
                className={cn(
                  'text-base',
                  isAddAnotherAddressDisabled
                    ? 'text-zen-dark-4'
                    : 'text-primary-blue',
                )}
              />
              <p
                className={cn(
                  'text-sm font-zen-body font-semibold',
                  isAddAnotherAddressDisabled
                    ? 'text-zen-dark-4'
                    : 'text-primary-blue',
                )}
              >
                Add another address
              </p>
            </button>
          </div>
          <div className='mt-5'>
            <ZenControlledPhoneNumberInput<FormData, 'phoneNumber'>
              control={control}
              label='Phone Number'
              name='phoneNumber'
              placeholder='+1 (702) 123-4567'
              rules={{
                required: 'Please enter the phone number',
                ...PHONE_NUMBER_VALIDATIONS,
              }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'emailAddress'>
              control={control}
              type='email'
              label='Email Address'
              name='emailAddress'
              placeholder='Enter the email address'
              rules={{
                required: 'Please enter an email address',
                ...EMAIL_VALIDATIONS,
              }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'paymentInstructions'>
              control={control}
              label='Payment Instructions'
              name='paymentInstructions'
              placeholder='Enter the payment instructions'
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'brokerageLicenseNumber'>
              control={control}
              label='Brokerage License Number'
              name='brokerageLicenseNumber'
              placeholder='Enter the brokerage license number'
            />
          </div>
          <div className='mt-5'>
            <ZenControlledDatePickerInput<FormData, 'licenseExpirationDate'>
              control={control}
              label='Expiration date of Brokerage License'
              name='licenseExpirationDate'
              placeholder='MM/DD/YYYY'
              datePickerConfig={{
                minDate: DateTime.local().toJSDate(),
              }}
              icon={
                <FontAwesomeIcon
                  icon={light('calendar')}
                  className='text-primary-blue text-lg flex items-center justify-center'
                />
              }
            />
          </div>
          <div className='mt-5'>
            <ZenControlledPhoneNumberInput<FormData, 'faxNumber'>
              control={control}
              label='Fax Number'
              name='faxNumber'
              placeholder='+1 (212) 123-4567'
              rules={{ required: 'Please enter the fax number' }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'cdaDocumentTitle'>
              control={control}
              label='Commission Document Title'
              name='cdaDocumentTitle'
              placeholder='Enter the Commission Document title'
              rules={{ required: 'Please enter the Commission Document title' }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'invoiceDocumentTitle'>
              control={control}
              label='Invoice Document Title'
              name='invoiceDocumentTitle'
              placeholder='Enter the invoice document title'
              rules={{ required: 'Please enter the invoice document title' }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<FormData, 'administrativeAreaId'>
              control={control}
              name='administrativeAreaId'
              label='Administrative Area'
              placeholder='Search Administrative Area'
              fetchData={async (search, page) => {
                try {
                  const {
                    data: searchResponse,
                  } = await new AdministrativeAreaControllerApi(
                    getYentaConfiguration(),
                  ).searchAdministrativeAreas(
                    page,
                    DEFAULT_PAGE_SIZE,
                    'ASC',
                    ['ID'],
                    undefined,
                    undefined,
                    search,
                  );

                  const options: AsyncSelectOption[] = (
                    searchResponse?.results || []
                  ).map((resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.id}`,
                  }));

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search request administrative areas offices in create office form',
                    e,
                  );

                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an administrative area. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              rules={{ required: 'Please select the administrative area' }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<FormData, 'designatedBrokerId'>
              control={control}
              name='designatedBrokerId'
              label='Designated Broker'
              fetchData={async (search, page) => {
                try {
                  const { data } = await searchForAgents({
                    page,
                    search,
                    filterBy: {
                      agentStatus: [UserResponseAgentStatusEnum.Active],
                    },
                  });

                  const options: AsyncSelectOption[] = data.map((resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.firstName} ${resp.lastName} - ${resp.emailAddress}`,
                  }));

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search request registered agents in create office form',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                      },
                    },
                  );
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an  registered agent. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              rules={{ required: 'Please select a designated broker' }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<FormData, 'managingBrokerId'>
              control={control}
              name='managingBrokerId'
              label='Managing Broker'
              fetchData={async (search, page) => {
                try {
                  const { data } = await searchForAgents({
                    page,
                    search,
                    filterBy: {
                      agentStatus: [UserResponseAgentStatusEnum.Active],
                    },
                  });

                  const options: AsyncSelectOption[] = data.map((resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.firstName} ${resp.lastName} - ${resp.emailAddress}`,
                  }));

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search request registered agents in create office form',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                      },
                    },
                  );
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an registered agent. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<FormData, 'bankAccountId'>
              control={control}
              name='bankAccountId'
              label='Bank Account'
              fetchData={async (search, page) => {
                const searchResponse = await dispatch(
                  bankAccountSearchRequest(['BANK_NAME'], 'ASC', search, page),
                );

                const options: AsyncSelectOption[] = (
                  searchResponse?.results || []
                ).map((resp) => ({
                  value: `${resp.id}`,
                  label: `${resp.bankName} (Account Number: ${resp.accountNumber})`,
                }));

                return options;
              }}
              rules={{ required: 'Please select a bank account' }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<FormData, 'trustAccountId'>
              control={control}
              name='trustAccountId'
              label='Trust Account'
              fetchData={async (search, page) => {
                const searchResponse = await dispatch(
                  bankAccountSearchRequest(['BANK_NAME'], 'ASC', search, page),
                );

                const options: AsyncSelectOption[] = (
                  searchResponse?.results || []
                ).map((resp) => ({
                  value: `${resp.id}`,
                  label: `${resp.bankName} (Account Number: ${resp.accountNumber})`,
                }));

                return options;
              }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<FormData, 'operatingAccountId'>
              control={control}
              name='operatingAccountId'
              label='Operating Account'
              fetchData={async (search, page) => {
                const searchResponse = await dispatch(
                  bankAccountSearchRequest(['BANK_NAME'], 'ASC', search, page),
                );

                const options: AsyncSelectOption[] = (
                  searchResponse?.results || []
                ).map((resp) => ({
                  value: `${resp.id}`,
                  label: `${resp.bankName} (Account Number: ${resp.accountNumber})`,
                }));

                return options;
              }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<FormData, 'companyId'>
              control={control}
              name='companyId'
              label='Company'
              fetchData={async (search, page) => {
                try {
                  const { data } = await new CompanyControllerApi(
                    getYentaConfiguration(),
                  ).searchCompanies(
                    ['ID', 'NAME', 'EIN'],
                    'ASC',
                    page,
                    DEFAULT_PAGE_SIZE,
                    search,
                  );

                  const options: AsyncSelectOption[] = (
                    data?.results || []
                  ).map((resp: CompanyResponse) => ({
                    value: `${resp.id}`,
                    label: `EIN: ${resp.ein} - ${resp.name} (${resp.administrativeArea?.id})`,
                  }));

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search request companies in create office form',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                      },
                    },
                  );
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an company. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              rules={{ required: 'Please select a company' }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, `paysOtherAgent`>
              name='paysOtherAgent'
              control={control}
              label='Pays Other Agent'
              placeholder='Pays Other Agent'
              options={yesNoOptions}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, `leasePaysOtherAgent`>
              name='leasePaysOtherAgent'
              control={control}
              label='Lease Pays Other Agent'
              placeholder='Lease Pays Other Agent'
              options={yesNoOptions}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, `acceptsEscrow`>
              name='acceptsEscrow'
              control={control}
              label='Accepts Escrow'
              placeholder='Accepts Escrow'
              options={yesNoOptions}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, `paysCommissionFromTrust`>
              name='paysCommissionFromTrust'
              control={control}
              label='Pays Commission From Trust'
              placeholder='Pays Commission From Trust'
              options={yesNoOptions}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, `allowsLease`>
              name='allowsLease'
              control={control}
              label='Lease Allowed'
              placeholder='Lease Allowed'
              options={yesNoOptions}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, `usesTipalti`>
              name='usesTipalti'
              control={control}
              label='Uses Tipalti'
              placeholder='Uses Tipalti'
              options={yesNoOptions}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, `paymentBatchName`>
              name='paymentBatchName'
              control={control}
              label='Payment Batch Name'
              placeholder='Select Payment Batch Name'
              options={values(OfficeResponsePaymentBatchNameEnum).map(
                (value) => ({
                  label: capitalizeEnum(value),
                  value,
                }),
              )}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, `realWithholdsTax`>
              name='realWithholdsTax'
              control={control}
              label='Tax Payment Default'
              placeholder='Tax Payment Default'
              options={values(RealWithholdsTaxEnum).map((v) => ({
                label: capitalizeEnum(v),
                value: v,
              }))}
            />
          </div>
          <div className='pb-20'>
            <div className='flex justify-between items-center mt-4'>
              <p className='text-lg font-zen-title'>Taxes</p>
              <ZenButton
                variant='primary-link'
                type='button'
                label='Add'
                LeftIconComponent={<FontAwesomeIcon icon={faAdd} />}
                onClick={() =>
                  append({
                    type: undefined,
                    taxId: undefined,
                    rate: undefined,
                  })
                }
              />
            </div>
            {taxesErrorMsg && <FormErrorMessage message={taxesErrorMsg} />}
            <ResourceContainer
              loading={false}
              isEmpty={!fields.length}
              resourceName='tax'
              emptyIcon={<FontAwesomeIcon icon={faAddressCard} size='2xl' />}
              emptyMessage='There are no taxes to display'
            >
              {fields.map((field, index) => {
                const currentField = watch('taxes')[index];
                const isTaxTypeNotGRT =
                  currentField.type?.value !== TaxValueTypeEnum.Grt;

                return (
                  <div
                    className='mt-2 p-3 rounded-md border shadow relative'
                    key={field.id}
                  >
                    <div className='mt-2'>
                      <ZenControlledSelectInput<
                        FormData,
                        `taxes.${number}.type`
                      >
                        name={`taxes.${index}.type` as const}
                        control={control}
                        label='Tax Type'
                        placeholder='Select Tax Type'
                        options={getValidTaxIdTypes(
                          (selectedCountry?.value as unknown) as AddressRequestCountryEnum,
                        ).map((tax) => ({
                          value: tax,
                          label: capitalizeEnum(tax),
                        }))}
                        rules={{
                          required: 'Please select tax type',
                          validate: (value) => {
                            if (
                              getValues().taxes.filter(
                                (tax) => tax.type?.value === value?.value,
                              ).length > 1
                            ) {
                              return 'You cannot have two taxes of the same type.';
                            }

                            return undefined;
                          },
                        }}
                        shouldUnregister={false}
                        isRequired
                      />
                    </div>
                    <div className='mt-2'>
                      <ZenControlledTextInput<FormData, `taxes.${number}.taxId`>
                        control={control}
                        label='Tax ID'
                        name={`taxes.${index}.taxId` as const}
                        rules={{ required: 'Please enter tax id' }}
                        isRequired
                      />
                    </div>
                    {isTaxTypeNotGRT && (
                      <div className='mt-2'>
                        <ZenControlledTextInput<
                          FormData,
                          `taxes.${number}.rate`
                        >
                          control={control}
                          label='Tax Rate'
                          name={`taxes.${index}.rate` as const}
                          rules={{
                            required: 'Please enter tax rate',
                            pattern: {
                              value: MONEY_AMOUNT_REGEX,
                              message: 'Please enter valid rate',
                            },
                          }}
                          isRequired
                        />
                      </div>
                    )}
                    <div className='absolute right-1 top-1'>
                      <ZenButton
                        variant='danger-link'
                        LeftIconComponent={<FontAwesomeIcon icon={faTrash} />}
                        onClick={() => remove(index)}
                      />
                    </div>
                  </div>
                );
              })}
            </ResourceContainer>
            <div className='flex flex-row items-center mt-4'>
              <p className='text-lg font-zen-title'>
                Additional Fees & Rebates
              </p>
            </div>
            <div className='mt-2 p-3 rounded-md border shadow relative'>
              <div className='mt-2'>
                <ZenControlledRadioInput<FormData, 'additionalCommission'>
                  name='additionalCommission'
                  label='Additional Commission'
                  rules={{
                    required: 'Please choose one',
                  }}
                  options={[
                    {
                      label: 'Yes',
                      value: YesNoType.YES,
                    },
                    {
                      label: 'No',
                      value: YesNoType.NO,
                    },
                  ]}
                  control={control}
                  inlineOptions
                />
              </div>
              <div className='mt-5'>
                <ZenControlledRadioInput<FormData, 'rebate'>
                  name='rebate'
                  label='Rebate'
                  rules={{
                    required: 'Please choose one',
                  }}
                  options={[
                    {
                      label: 'Yes',
                      value: YesNoType.YES,
                    },
                    {
                      label: 'No',
                      value: YesNoType.NO,
                    },
                  ]}
                  control={control}
                  inlineOptions
                />
              </div>
              <div className='mt-5'>
                <ZenControlledRadioInput<FormData, 'transactionCoordinatorFee'>
                  name='transactionCoordinatorFee'
                  label='Transaction Coordinator'
                  rules={{
                    required: 'Please choose one',
                  }}
                  options={[
                    {
                      label: 'Yes',
                      value: YesNoType.YES,
                    },
                    {
                      label: 'No',
                      value: YesNoType.NO,
                    },
                  ]}
                  control={control}
                  inlineOptions
                />
              </div>
              <div className='mt-5'>
                <ZenControlledRadioInput<FormData, 'reimbursementFee'>
                  name='reimbursementFee'
                  label='Reimbursement'
                  rules={{
                    required: 'Please choose one',
                  }}
                  options={[
                    {
                      label: 'Yes',
                      value: YesNoType.YES,
                    },
                    {
                      label: 'No',
                      value: YesNoType.NO,
                    },
                  ]}
                  control={control}
                  inlineOptions
                />
              </div>
              <div className='mt-5'>
                <ZenControlledRadioInput<FormData, 'mlsFee'>
                  name='mlsFee'
                  label='MLS'
                  rules={{
                    required: 'Please choose one',
                  }}
                  options={[
                    {
                      label: 'Yes',
                      value: YesNoType.YES,
                    },
                    {
                      label: 'No',
                      value: YesNoType.NO,
                    },
                  ]}
                  control={control}
                  inlineOptions
                />
              </div>

              <div className='mt-5'>
                <ZenControlledRadioInput<FormData, 'oneRealImpactFundFee'>
                  name='oneRealImpactFundFee'
                  label='One Real Impact'
                  rules={{
                    required: 'Please choose one',
                  }}
                  options={[
                    {
                      label: 'Yes',
                      value: YesNoType.YES,
                    },
                    {
                      label: 'No',
                      value: YesNoType.NO,
                    },
                  ]}
                  control={control}
                  inlineOptions
                />
              </div>
            </div>
          </div>

          <ZenSidebarModalActionFooter
            submitButtonText='Create'
            isSubmitting={isSubmitting}
            onClose={onClose}
          />
        </div>
      </form>
    </ZenSidebarModal>
  );
};

export default ZenCreateOfficeForm;
