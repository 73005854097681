import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import ControlledAsyncSelectInputV7 from '../components/ControlledAsyncSelectInputV7';
import ControlledEmailInputV7 from '../components/ControlledEmailInputV7';
import ControlledFileUploadInputV7 from '../components/ControlledFileUploadInputV7';
import ControlledPhoneNumberInputV7 from '../components/ControlledPhoneNumberInputV7';
import ControlledSelectInputV7 from '../components/ControlledSelectInputV7';
import ControlledTextAreaInputV7 from '../components/ControlledTextAreaInputV7';
import ControlledTextInputV7 from '../components/ControlledTextInputV7';
import ControlledToggleInputV7 from '../components/ControlledToggleInputV7';
import SidebarModal from '../components/SidebarModal';
import SidebarModalActionFooter from '../components/SidebarModal/SideBarModalActionFooter';
import SimpleConfirmationModal from '../components/SimpleConfirmationModal';
import AdminOnly from '../components/auth/AdminOnly';
import { ADDRESS_PLACEHOLDER } from '../constants/PlaceholderConstants';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import {
  NationalIdentificationValueTypeEnum,
  ParticipantValue,
  ParticipantValueRoleEnum,
  PaymentParticipantValue,
  TransactionControllerApi,
  TransactionResponse,
  TransactionResponseCountryEnum,
  UpdateParticipantRequest,
  UpdateParticipantRequestParticipantRoleEnum,
} from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToast,
  showErrorToastForErrorCode,
} from '../slices/ToastNotificationSlice';
import {
  toggleTaxWithheld,
  updateInstantPaymentEligibility,
  updateTransactionParticipant,
  uploadW9Form,
} from '../slices/TransactionSlice';
import {
  AppDispatch,
  AsyncSelectOption,
  EnumMap,
  FeatureFlagTypeEnum,
  ISelectOption,
  RootState,
} from '../types';
import { getIdValidations } from '../utils/AgentHelper';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { getFileNameFromUrl } from '../utils/StringUtils';
import { searchForAgents } from '../utils/TableUtils';
import {
  RequiredField,
  getParticipantScreenTitle,
  getValidParticipantRoles,
  isAddParticipantLabelRequired,
  isCanadaTransaction,
  validateFields,
} from '../utils/TransactionHelper';
import {
  EMAIL_VALIDATIONS,
  FILE_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../utils/Validations';

interface EditTransactionParticipantFormProps {
  transaction: TransactionResponse;
  participant: PaymentParticipantValue | ParticipantValue | null;
  readOnly?: boolean;
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  agentName: ISelectOption;
  participantRole: ParticipantValueRoleEnum;
  firstName: string;
  lastName: string;
  company: string;
  emailAddress: string;
  phoneNumber: string;
  commissionDocumentRecipient: boolean;
  passThrough: boolean;
  paidByReal: boolean;
  personalDeal: boolean;
  taxWithheld: boolean;
  instantPaymentEligible: boolean;
  address: string;
  w9form: string;
  Bn: string;
  gstId: string;
  pstId: string;
  hstId: string;
  qstId: string;
  ein: string;
  sin: string;
  notes: string;
}

const EditTransactionParticipantFormSidebarModal: React.FC<EditTransactionParticipantFormProps> = ({
  transaction,
  participant,
  isOpen,
  onClose,
  readOnly,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [w9FileUrl, setW9FileUrl] = useState<string>('');
  const {
    control,
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormData>({ mode: 'onBlur', reValidateMode: 'onBlur' });
  const isCanada =
    transaction.country === TransactionResponseCountryEnum.Canada;

  const isPersonalDealSelected = watch('personalDeal');

  const { transactionPermissions } = useSelector(
    (state: RootState) => state.transaction,
  );

  const [
    isPersonalDealModalOpen,
    setIsPersonalDealModalOpen,
  ] = useState<boolean>(false);

  const businessIdsByType: EnumMap<
    NationalIdentificationValueTypeEnum,
    string
  > = (participant?.paidViaBusinessEntity?.nationalIds || []).reduce<
    EnumMap<NationalIdentificationValueTypeEnum, string>
  >(
    (map, currentValue) => {
      map[currentValue.type!] = currentValue.id!;
      return map;
    },
    {
      BN: '',
      EIN: '',
      GST_ID: '',
      HST_ID: '',
      PST_ID: '',
      QST_ID: '',
      SIN: '',
      SSN: '',
      FNIN: '',
      B_AND_O_ID: '',
      CBJ_ID: '',
      GRT_ID: '',
      GET_ID: '',
      LA_CBT_ID: '',
    },
  );

  const [
    firstName,
    lastName,
    companyName,
    commissionDocumentRecipient,
    participantRole,
    ein,
  ] = watch([
    'firstName',
    'lastName',
    'company',
    'commissionDocumentRecipient',
    'participantRole',
    'ein',
  ]);

  const watchFields = {
    firstName,
    lastName,
    companyName,
    participantRole,
    ein,
  };

  useEffect(() => {
    if (isOpen && participant?.externalParticipant) {
      trigger(['firstName', 'lastName', 'company', 'emailAddress', 'address']);
    }
  }, [
    trigger,
    firstName,
    lastName,
    companyName,
    isOpen,
    participant?.externalParticipant,
    readOnly,
  ]);

  useEffect(() => {
    if (participant!) {
      if (
        'personalDeal' in participant! &&
        !participant.personalDeal &&
        isPersonalDealSelected
      ) {
        setIsPersonalDealModalOpen(isPersonalDealSelected);
      }
    }
  }, [isPersonalDealSelected, setValue]);

  const instantPaymentEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.INSTANT_PAYMENTS_TOGGLE,
  );

  const onSubmit = async (values: FormData) => {
    if (values.w9form) {
      await dispatch(uploadW9Form(participant?.id!, values.w9form[0]));
    }

    const nationalIds = [];

    if (isCanada) {
      if (values.Bn) {
        nationalIds?.push({
          id: values.Bn,
          type: NationalIdentificationValueTypeEnum.Bn,
        });
      }
      if (values.gstId) {
        nationalIds?.push({
          id: values.gstId,
          type: NationalIdentificationValueTypeEnum.GstId,
        });
      }
      if (values.pstId) {
        nationalIds?.push({
          id: values.pstId,
          type: NationalIdentificationValueTypeEnum.PstId,
        });
      }
      if (values.hstId) {
        nationalIds?.push({
          id: values.hstId,
          type: NationalIdentificationValueTypeEnum.HstId,
        });
      }
      if (values.qstId) {
        nationalIds?.push({
          id: values.qstId,
          type: NationalIdentificationValueTypeEnum.QstId,
        });
      }
      if (values.sin) {
        nationalIds?.push({
          id: values.sin,
          type: NationalIdentificationValueTypeEnum.Sin,
        });
      }
    } else {
      if (values.ein) {
        nationalIds?.push({
          id: values.ein,
          type: NationalIdentificationValueTypeEnum.Ein,
        });
      }
    }

    const data: UpdateParticipantRequest = {
      emailAddress: values.emailAddress,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      commissionDocumentRecipient:
        values.commissionDocumentRecipient ??
        !!participant?.commissionDocumentRecipient,
      payer: !!participant?.commissionDocumentPayer,
      passThrough: values.passThrough ?? !!participant?.passThrough,
      paidByReal: values.paidByReal ?? !!participant?.paidByReal,
      personalDeal:
        values.personalDeal ??
        ('personalDeal' in participant! ? !!participant.personalDeal : false),
      participantRole: (values.participantRole as unknown) as UpdateParticipantRequestParticipantRoleEnum,
      address: values.address,
      paidViaBusinessEntity:
        participant?.externalParticipant && values.company
          ? {
              name: values.company,
              nationalIds,
            }
          : undefined,
      notes: values.notes,
    };

    const success = await dispatch(
      updateTransactionParticipant(participant?.id!, transaction.id!, data),
    );

    if (!transaction?.listing && instantPaymentEnabled) {
      await dispatch(
        updateInstantPaymentEligibility(transaction?.id!, participant?.id!, {
          instantPaymentEligible: values.instantPaymentEligible,
        }),
      );
    }

    if (success) {
      if (values.taxWithheld !== undefined) {
        await dispatch(
          toggleTaxWithheld(transaction.id!, participant?.id!, {
            taxWithheld: values.taxWithheld,
          }),
        );
      }

      onClose();
    }
  };

  const w9File = useCallback(async () => {
    setW9FileUrl('');

    // Force refresh on isOpen to handle bug where you upload a W9, close the modal, and
    // then reopen it for the same user.
    if (isOpen && participant?.externalParticipant) {
      try {
        const { data } = await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).getW9Url(participant?.id!);

        setW9FileUrl(data);
      } catch (e) {
        dispatch(showApiErrorModal(e));
        dispatch(
          showErrorToastForErrorCode(
            'We were unable to fetch the w9 form',
            ErrorService.getErrorCode(e),
          ),
        );
      }
    }
  }, [dispatch, participant?.externalParticipant, participant?.id, isOpen]);

  useEffect(() => {
    w9File();
  }, [w9File]);

  const w9FileName =
    w9FileUrl && getFileNameFromUrl(w9FileUrl) !== 'null'
      ? getFileNameFromUrl(w9FileUrl)
      : '';

  const currentParticipantPermission = transactionPermissions?.participantPermissions?.find(
    (p) => p.participantId === participant?.id,
  );
  const isEligible = currentParticipantPermission?.callerAllowedToAssignInstantPaymentEligibility!;

  return (
    <SidebarModal
      title={getParticipantScreenTitle(
        participant,
        participant?.externalParticipant,
        readOnly,
      )}
      subtitle={transaction.address?.oneLine}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-2 md:p-4 mb-20'>
          <div>
            <ControlledSelectInputV7
              name='participantRole'
              control={control}
              label='Role'
              defaultValue={participant?.role as ParticipantValueRoleEnum}
              placeholder='Role'
              options={[
                {
                  label: `Select Role`,
                  value: '',
                },
                ...getValidParticipantRoles(
                  transaction.transactionType!,
                  transaction?.transactionOwner?.represents,
                ),
              ]}
              readOnly={readOnly}
              isRequired
            />
          </div>
          {!participant?.externalParticipant ? (
            <div className='mt-5'>
              <ControlledAsyncSelectInputV7
                control={control}
                name='agentName'
                defaultValue={{
                  label: `${participant?.firstName} ${participant?.lastName}`,
                  value: participant?.yentaId || '',
                }}
                placeholder='Search'
                label='Name of Participant'
                disabled
                fetchData={async (search, page) => {
                  try {
                    const { data } = await searchForAgents({
                      page,
                      search,
                      filterBy: {
                        country: [transaction.country!],
                      },
                    });

                    const options: AsyncSelectOption[] = data.map((resp) => ({
                      value: `${resp.id}`,
                      label: `${resp.firstName} ${resp.lastName}`,
                    }));

                    return options;
                  } catch (e) {
                    ErrorService.notify(
                      'Unable to search for registered agents in edit transaction participant form',
                      e,
                      {
                        search: {
                          term: search,
                          country: transaction?.country,
                          page,
                        },
                      },
                    );
                    dispatch(
                      showErrorToast(
                        'An unexpected error occurred.',
                        'We were unable to search for an agent. Please try again in a few moments or contact support.',
                      ),
                    );
                  }

                  return [];
                }}
                rules={{
                  required: 'Required',
                }}
                isRequired
              />
            </div>
          ) : (
            <div>
              <div className='flex flex-row mt-5 space-x-2'>
                <ControlledTextInputV7
                  control={control}
                  label='First name'
                  name='firstName'
                  placeholder='E.g. John'
                  rules={{
                    minLength: {
                      value: 2,
                      message: 'Must be at least 2 characters',
                    },
                    ...validateFields(
                      RequiredField.FIRST_NAME,
                      transaction,
                      watchFields,
                    ),
                  }}
                  isRequired
                  defaultValue={participant?.firstName}
                  readOnly={readOnly}
                />
                <ControlledTextInputV7
                  control={control}
                  label='Last name'
                  name='lastName'
                  placeholder='E.g. Dow'
                  rules={{
                    minLength: {
                      value: 2,
                      message: 'Must be at least 2 characters',
                    },
                    ...validateFields(
                      RequiredField.LAST_NAME,
                      transaction,
                      watchFields,
                    ),
                  }}
                  isRequired
                  defaultValue={participant?.lastName}
                  readOnly={readOnly}
                />
              </div>
              <div className='mt-5'>
                <ControlledTextInputV7
                  control={control}
                  label='Business name'
                  subLabel='(I.e. Brokerage or company name)'
                  name='company'
                  defaultValue={participant?.paidViaBusinessEntity?.name}
                  placeholder='E.g. Dream Home LLC.'
                  rules={{
                    ...validateFields(
                      RequiredField.COMPANY_NAME,
                      transaction,
                      watchFields,
                    ),
                  }}
                  readOnly={readOnly}
                  isRequired
                />
              </div>
              {!isCanada ? (
                <div className='mt-5'>
                  <ControlledTextInputV7
                    control={control}
                    name='ein'
                    label='EIN'
                    defaultValue={
                      businessIdsByType[NationalIdentificationValueTypeEnum.Ein]
                    }
                    placeholder='E.g. 12-1234567'
                    rules={{
                      ...getIdValidations(
                        NationalIdentificationValueTypeEnum.Ein,
                      ),
                    }}
                    readOnly={readOnly}
                  />
                </div>
              ) : (
                <div className='flex flex-row mt-5 space-x-2'>
                  <ControlledTextInputV7
                    control={control}
                    name='gstId'
                    label='GST'
                    placeholder='E.g. 123456789 RT 0001'
                    defaultValue={
                      businessIdsByType[
                        NationalIdentificationValueTypeEnum.GstId
                      ]
                    }
                    rules={{
                      ...getIdValidations(
                        NationalIdentificationValueTypeEnum.GstId,
                      ),
                    }}
                    readOnly={readOnly}
                  />
                  <ControlledTextInputV7
                    control={control}
                    name='Bn'
                    label='Business number'
                    placeholder='E.g. 123456789'
                    defaultValue={
                      businessIdsByType[NationalIdentificationValueTypeEnum.Bn]
                    }
                    rules={{
                      ...getIdValidations(
                        NationalIdentificationValueTypeEnum.Bn,
                      ),
                    }}
                    readOnly={readOnly}
                  />
                </div>
              )}
              <div className='mt-5'>
                <ControlledEmailInputV7
                  control={control}
                  label='Email'
                  subLabel='(Required if receiving electronic documents)'
                  name='emailAddress'
                  defaultValue={participant?.emailAddress}
                  placeholder='E.g. John@example.com'
                  rules={{
                    required: {
                      value: !!commissionDocumentRecipient,
                      message:
                        'Email is required if receiving electronic documents',
                    },
                    ...EMAIL_VALIDATIONS,
                    ...validateFields(
                      RequiredField.EMAIL,
                      transaction,
                      watchFields,
                    ),
                  }}
                  isRequired={
                    isAddParticipantLabelRequired(
                      transaction,
                      participantRole,
                    ) || !!commissionDocumentRecipient
                  }
                  readOnly={readOnly}
                />
              </div>
              <div className='mt-5'>
                <ControlledPhoneNumberInputV7
                  control={control}
                  label='Phone number'
                  name='phoneNumber'
                  defaultValue={participant?.phoneNumber}
                  placeholder='E.g. +1 (702) 123-4567'
                  rules={{
                    ...PHONE_NUMBER_VALIDATIONS,
                  }}
                  readOnly={readOnly}
                />
              </div>
              <div className='mt-5'>
                <ControlledTextInputV7
                  control={control}
                  label='Address'
                  subLabel={
                    !isCanada
                      ? '(Must match address on W9 Form, if uploaded)'
                      : ''
                  }
                  name='address'
                  defaultValue={participant?.address}
                  placeholder={ADDRESS_PLACEHOLDER}
                  rules={{
                    ...validateFields(
                      RequiredField.ADDRESS,
                      transaction,
                      watchFields,
                    ),
                  }}
                  isRequired={isAddParticipantLabelRequired(
                    transaction,
                    participantRole,
                  )}
                  readOnly={readOnly}
                />
              </div>
              <div className='mt-5'>
                {!isCanadaTransaction(transaction) && (
                  <ControlledFileUploadInputV7
                    name='w9form'
                    control={control}
                    label='Upload W9 Form'
                    placeholder={w9FileName || 'E.g. W9.pdf'}
                    accept='.pdf'
                    rightAction={
                      w9FileName
                        ? {
                            text: 'View W9',
                            onClick() {
                              window.open(w9FileUrl, '_blank');
                            },
                          }
                        : undefined
                    }
                    rules={{
                      ...FILE_VALIDATIONS,
                    }}
                    readonly={readOnly}
                  />
                )}
              </div>

              {isCanada && (
                <div className='mt-5'>
                  <label className='text-base font-primary-medium text-dark'>
                    Other Tax Id. Numbers
                  </label>
                  <div className='mt-2'>
                    <ControlledTextInputV7
                      control={control}
                      name='sin'
                      label='Social insurance number (SIN)'
                      placeholder='E.g. 123-123-1234'
                      defaultValue={
                        businessIdsByType[
                          NationalIdentificationValueTypeEnum.Sin
                        ]
                      }
                      rules={{
                        ...getIdValidations(
                          NationalIdentificationValueTypeEnum.Sin,
                        ),
                      }}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className='mt-5'>
                    <ControlledTextInputV7
                      control={control}
                      name='hstId'
                      label='Harmonized sales tax (HST)'
                      placeholder='E.g. 123456789 RT 00002'
                      defaultValue={
                        businessIdsByType[
                          NationalIdentificationValueTypeEnum.HstId
                        ]
                      }
                      rules={{
                        ...getIdValidations(
                          NationalIdentificationValueTypeEnum.HstId,
                        ),
                      }}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className='mt-5'>
                    <ControlledTextInputV7
                      control={control}
                      name='pstId'
                      label='Provincial sales tax (PST)'
                      defaultValue={
                        businessIdsByType[
                          NationalIdentificationValueTypeEnum.PstId
                        ]
                      }
                      placeholder='E.g. PST-1234-1234'
                      rules={{
                        ...getIdValidations(
                          NationalIdentificationValueTypeEnum.PstId,
                        ),
                      }}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className='mt-5'>
                    <ControlledTextInputV7
                      control={control}
                      name='qstId'
                      label='Quebec sales tax (QST)'
                      placeholder='E.g. 123456789 TQ 1234'
                      defaultValue={
                        businessIdsByType[
                          NationalIdentificationValueTypeEnum.QstId
                        ]
                      }
                      rules={{
                        ...getIdValidations(
                          NationalIdentificationValueTypeEnum.QstId,
                        ),
                      }}
                      readOnly={readOnly}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {!participant?.commissionDocumentPayer && !transaction.listing && (
            <div className='mt-5'>
              <div className='border divide-y rounded'>
                {participant && 'personalDeal' in participant! && (
                  <ControlledToggleInputV7
                    name='personalDeal'
                    control={control}
                    defaultValue={participant.personalDeal}
                    label='Personal Deal?'
                    readOnly={readOnly}
                  />
                )}
                <ControlledToggleInputV7
                  name='commissionDocumentRecipient'
                  control={control}
                  defaultValue={participant?.commissionDocumentRecipient}
                  label='Receives Invoices/Comm. Documents?'
                  readOnly={readOnly}
                />
                {instantPaymentEnabled &&
                  isEligible &&
                  participant &&
                  'instantPaymentEligibleTeamMember' in participant && (
                    <ControlledToggleInputV7
                      name='instantPaymentEligible'
                      control={control}
                      defaultValue={
                        participant?.instantPaymentEligibleTeamMember
                      }
                      label='Eligible for Instant Payment'
                      readOnly={readOnly}
                    />
                  )}
                <AdminOnly>
                  <ControlledToggleInputV7
                    name='paidByReal'
                    control={control}
                    defaultValue={participant?.paidByReal}
                    label='Is Single Check?'
                    readOnly={readOnly}
                  />
                  <ControlledToggleInputV7
                    name='passThrough'
                    control={control}
                    defaultValue={participant?.passThrough}
                    label='Pass Through Deal?'
                    readOnly={readOnly}
                  />
                  {!!participant && 'taxWithheld' in participant && (
                    <ControlledToggleInputV7
                      name='taxWithheld'
                      control={control}
                      defaultValue={participant?.taxWithheld}
                      label='Tax Withheld?'
                      readOnly={readOnly}
                    />
                  )}
                </AdminOnly>
              </div>
            </div>
          )}
          <div className='mt-5'>
            <ControlledTextAreaInputV7
              control={control}
              label='Notes'
              name='notes'
              placeholder={`E.g. Assistant's name and number, etc.`}
              defaultValue={participant?.notes}
              rows={2}
            />
          </div>
        </div>
        {!readOnly && (
          <SidebarModalActionFooter
            isSubmitting={isSubmitting}
            onClose={onClose}
          />
        )}
        <SimpleConfirmationModal
          title='Personal Deal?'
          variant='info'
          subtitle='By selecting personal deal you acknowledge that you or your spouse are listed on the title of the property being sold/purchase or own the Entity (LLC, Trust, Corp) that owns the property being sold or purchased'
          isOpen={isPersonalDealModalOpen}
          isSubmitting={false}
          onConfirm={() => {
            setIsPersonalDealModalOpen(false);
          }}
          onClose={() => {
            setIsPersonalDealModalOpen(false);
            setValue('personalDeal', false);
          }}
        />
      </form>
    </SidebarModal>
  );
};

export default EditTransactionParticipantFormSidebarModal;
