import {
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
  UseControllerProps,
} from 'react-hook-form-v7';
import { UseFormSetValue } from 'react-hook-form-v7/dist/types/form';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import {
  AddressCountryEnum,
  AddressRequestCountryEnum,
  AdministrativeAreaRequestCountryEnum,
} from '../../../openapi/yenta';
import { countryMapState } from '../../../testUtils/OnboardingUtils';
import { FormFieldTooltipIndexProps } from '../../../types';
import ZenControlledHTMLSelectInput from './ZenControlledHTMLSelectInput';

interface ZenControlledStateOrProvinceInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  selectedCountry?:
    | AdministrativeAreaRequestCountryEnum
    | AddressRequestCountryEnum
    | AddressCountryEnum;
  setValue: UseFormSetValue<TFieldValues>;
  readOnly?: boolean;
  isRequired?: boolean;
  disableChooseOption?: boolean;
  startAdornment?: React.ReactElement;
}

const ZenControlledStateOrProvinceInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  tooltipIndex,
  setValue,
  selectedCountry,
  readOnly,
  shouldUnregister = true,
  isRequired = false,
  disableChooseOption = true,
  startAdornment,
  ...rest
}: ZenControlledStateOrProvinceInputProps<TFieldValues, TName>) => {
  useDidUpdateEffect(() => {
    setValue(
      rest.name,
      '' as UnpackNestedValue<PathValue<TFieldValues, Path<TFieldValues>>>,
    );
  }, [selectedCountry, setValue]);

  return (
    <ZenControlledHTMLSelectInput<TFieldValues, TName>
      shouldUnregister={shouldUnregister}
      {...rest}
      label='State / Province'
      placeholder='State / Province'
      options={[
        {
          label: 'Choose State / Province',
          value: '',
          disabled: disableChooseOption,
        },
        ...(selectedCountry ? countryMapState[selectedCountry] : []),
      ]}
      readOnly={readOnly}
      isRequired={isRequired}
      startAdornment={startAdornment}
    />
  );
};

export default ZenControlledStateOrProvinceInput;
