import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { faArrowDownToSquare } from '@fortawesome/pro-regular-svg-icons';
import { InstantPaymentTransactionSummaryResponse } from '../../openapi/arrakis';
import { displayAmount } from '../../utils/CurrencyUtils';
import FixedDataTable from '../FixedDataTable';
import IconButton from '../IconButton';
import NumberColumnFilter from '../table/Filters/NumberColumnFilter';
import TextColumnFilter from '../table/Filters/TextColumnFilter';
import AgentOnly from '../auth/AgentOnly';
import { isTransactionSummaryEligible } from '../../utils/InstantPaymentHelper';
import EligibleTransactionInstantPaymentSidebarModal from './EligibleTransactionInstantPaymentSidebarModal';
import InstantPaymentTransactionSummaryStatePill from './InstantPaymentTransactionSummaryStatePill';

export const columns: Column<InstantPaymentTransactionSummaryResponse>[] = [
  {
    Header: 'Transaction Code',
    accessor: 'transaction',
    id: 'transactionCode',
    Cell: ({ value }) => (
      <Link to={`/transactions/${value?.id}`}>
        <IconButton
          leftIcon={
            <FontAwesomeIcon
              icon={faArrowDownToSquare}
              className='fa-rotate-270'
              aria-label='Link'
            />
          }
          variant='outline'
          label={value?.code!}
        />
      </Link>
    ),
    cardColSize: 7,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Address',
    accessor: 'transaction',
    id: 'address',
    Cell: ({ value }) => <div className='w-56'>{value?.addressOneLine}</div>,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'state',
    Cell: ({ value }) => (
      <InstantPaymentTransactionSummaryStatePill state={value!} />
    ),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Total Commission Amount',
    accessor: 'transaction',
    Cell: ({ value }) => displayAmount(value?.grossCommission),
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Minimum Payment Allowed',
    accessor: 'minValueAvailable',
    Cell: ({ value }) => displayAmount(value),
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Maximum Payment Allowed',
    accessor: 'maxValueAvailable',
    Cell: ({ value }) => displayAmount(value),
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
];

interface InstantPaymentEligibleTransactionResourceTableProps {
  transactionSummaries: InstantPaymentTransactionSummaryResponse[];
}

const InstantPaymentEligibleTransactionResourceTable: React.FC<InstantPaymentEligibleTransactionResourceTableProps> = ({
  transactionSummaries,
}) => {
  const [
    transactionSummary,
    setTransactionSummary,
  ] = useState<InstantPaymentTransactionSummaryResponse | null>(null);

  const columnsWithAction = [...columns];
  columnsWithAction.unshift({
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<InstantPaymentTransactionSummaryResponse> }) => (
      <AgentOnly>
        {isTransactionSummaryEligible(row.original.state) && (
          <IconButton
            label='Pay Me'
            leftIcon={<FontAwesomeIcon icon={faBolt} size='sm' />}
            onClick={() => setTransactionSummary(row.original)}
          />
        )}
      </AgentOnly>
    ),
    disableSortBy: true,
  });

  return (
    <div>
      <FixedDataTable<InstantPaymentTransactionSummaryResponse>
        columns={columnsWithAction}
        data={transactionSummaries}
        resourceName='Eligible Transaction'
        hidePagination
        hideFilters
      />
      {!!transactionSummary && (
        <EligibleTransactionInstantPaymentSidebarModal
          transactionSummary={transactionSummary}
          onClose={() => setTransactionSummary(null)}
        />
      )}
    </div>
  );
};

export default InstantPaymentEligibleTransactionResourceTable;
