import React from 'react';
import ZenPopover from '../../../ZenPopover';

interface MinimumLeaderSplitPopoverProps {}

const MinimumLeaderSplitPopover: React.FC<MinimumLeaderSplitPopoverProps> = () => {
  return (
    <ZenPopover
      title='Minimum Leader Split'
      description={
        <div className='text-zen-dark-9 text-base font-light'>
          <p>
            Some types of teams require that the Leader Split cannot be less
            than a certain minimum percentage. For instance, if a Real
            Administrator sets this number to 20%, then teammates on this team
            will have to pay at least 20% split to the leader. Although team
            leaders can decide to set the leader split to a higher percentage,
            they cannot choose to set it lower than minimum leader split.
          </p>
        </div>
      }
    />
  );
};

export default MinimumLeaderSplitPopover;
