import { CapStatusValue } from '../../openapi/arrakis';
import { ColoredProgressBarItem } from '../../types';
import { displayAmount } from '../../utils/CurrencyUtils';
import { calculatePercentage } from '../../utils/MathUtils';
import ColoredProgressBar from '../ColoredProgressBar';

interface CapStatusTrackerProps {
  capStatus: CapStatusValue;
}

const CapStatusTracker: React.FC<CapStatusTrackerProps> = ({ capStatus }) => {
  const totalPaid = capStatus.capAmountPaid?.amount!;
  const leftToPay = capStatus.capAmount?.amount! - totalPaid;

  const items: ColoredProgressBarItem[] = [{ value: totalPaid }];

  if (leftToPay > 0) {
    items.push({ value: leftToPay });
  }

  let percentage = calculatePercentage(totalPaid, capStatus.capAmount?.amount!);

  if (percentage > 100) {
    percentage = 100;
  }

  return (
    <div>
      <div className='flex flex-row justify-between font-zen-body text-zen-dark-9'>
        <span>
          {displayAmount(capStatus.capAmountPaid, { hideZeroCents: true })} paid
          towards cap
        </span>
        <p>{percentage}%</p>
      </div>
      <ColoredProgressBar items={items} />
    </div>
  );
};

export default CapStatusTracker;
