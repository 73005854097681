import {
  faChevronsLeft,
  faChevronsRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';
import AnalyticsService from '../../../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../../../types';
import LeoChatHistory from './LeoChatHistory';

interface LeoChatHistoryIndexProps {
  showHistory: boolean;
  toggleHistory(param: boolean): void;
}

const LeoChatHistoryIndex: React.FC<LeoChatHistoryIndexProps> = ({
  showHistory,
  toggleHistory,
}) => {
  return (
    <motion.div
      className={classNames(
        'h-full flex-grow sm:bg-[#05060B] sm:bg-opacity-50 sm:rounded-lg flex flex-row sm:pr-0',
        showHistory && 'p-2 pt-4',
      )}
      aria-label='leo-chat-history'
      transition={{ duration: 0.1 }}
      layout
    >
      {showHistory && <LeoChatHistory toggleHistory={toggleHistory} />}
      <div
        className={classNames(
          'hidden sm:flex flex-row items-center justify-center',
          !showHistory && 'w-full',
        )}
      >
        <button
          className={classNames(
            'appearance-none h-full',
            showHistory ? 'px-4' : 'w-full sm:px-3',
          )}
          onClick={() => {
            toggleHistory(!showHistory);
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.LEO_MESSAGE_HISTORY_CLICK,
            );
          }}
        >
          <FontAwesomeIcon
            icon={showHistory ? faChevronsLeft : faChevronsRight}
            className='text-xl text-white'
          />
        </button>
      </div>
    </motion.div>
  );
};

export default LeoChatHistoryIndex;
