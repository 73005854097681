import React from 'react';
import SunHouse from '../assets/img/SunHouse.png';
import BrokerQueueArrow from '../assets/img/BrokerQueueArrow.png';

export const BrokerQueueEmptyRoute: React.FC = () => {
  return (
    <div className='w-full h-full flex flex-col mt-60'>
      <div className='w-full flex align-center justify-center'>
        <img src={SunHouse} className='h-30 mr-12' alt='Empty broker queue' />
      </div>
      <div className='flex w-full flex-row align-center justify-center items-center text-slate-300'>
        <div className='sm:relative w-72 self-end text-center mt-8'>
          Select a transaction on the left panel to access all its information.
          <div className='sm:absolute sm:-left-52 sm:-top-20 md:-left-72 md:-top-28 flex justify-center mt-8'>
            <img
              src={BrokerQueueArrow}
              className='h-20 md:h-28'
              alt='Empty broker queue arrow'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrokerQueueEmptyRoute;
