import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAnnouncementDefinitionById,
  saveAnnouncementDefinitionDetail,
} from '../../../slices/AnnouncementSlice';
import { AppDispatch, RootState } from '../../../types';
import { createAnnouncementResponseFromDefinition } from '../../../utils/AnnouncementUtils';
import AnnouncementModal from './AnnouncementModal';

export interface PreviewAnnouncementProps {
  announcementId: string;
}

const PreviewAnnouncement: React.FC<PreviewAnnouncementProps> = ({
  announcementId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { announcementDefinitionDetail, loading } = useSelector(
    (state: RootState) => state.announcement,
  );

  useEffect(() => {
    if (announcementId) {
      dispatch(fetchAnnouncementDefinitionById(announcementId!));
    }

    return () => {
      dispatch(saveAnnouncementDefinitionDetail(undefined));
    };
  }, [announcementId, dispatch]);

  if (!announcementId || (!loading && isEmpty(announcementDefinitionDetail))) {
    return null;
  }

  return (
    <AnnouncementModal
      announcement={createAnnouncementResponseFromDefinition(
        announcementDefinitionDetail!,
      )}
      preview
    />
  );
};

export default PreviewAnnouncement;
