import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppThunk,
  ErrorCode,
  MlsPropertyTypesWandererType,
  MLSState,
} from '../types';
import ErrorService from '../services/ErrorService';
import {
  MlsControllerApi,
  MlsCreatePropertyTypeRequest,
  MlsGetPropertyTypeResponse,
  MlsPropertyTypeControllerApi,
  MlsResponse,
} from '../openapi/yenta';
import {
  getWandererConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import {
  GenericSearchResponseMlsPublicResponse,
  MlsRequest,
} from '../openapi/yenta/api';
import { WandererControllerApi } from '../openapi/wanderer';
import { showApiErrorModal } from './ErrorSlice';
import {
  showErrorToast,
  showErrorToastForErrorCode,
  showSuccessToast,
} from './ToastNotificationSlice';

export const initialState: MLSState = {
  loadingDetail: false,
  detail: undefined,
  fetchDetailErrorCode: null,
  loadingMlsPropertyTypes: false,
  fetchMlsPropertyTypesErrorCode: null,
  mlsPropertyTypes: null,
  mlsPropertyTypesWanderer: {},
};

const MLSSlice = createSlice({
  name: 'mls',
  initialState,
  reducers: {
    changeLoadingDetail(state, action: PayloadAction<boolean>) {
      state.loadingDetail = action.payload;
    },
    saveDetail(state, action: PayloadAction<MlsResponse>) {
      state.detail = action.payload;
      state.fetchDetailErrorCode = null;
    },
    errorFetchingDetail(state, action: PayloadAction<ErrorCode>) {
      state.fetchDetailErrorCode = action.payload;
    },
    changeMlsPropertyTypesLoading(state, action: PayloadAction<boolean>) {
      state.loadingMlsPropertyTypes = action.payload;
    },
    errorFetchingMlsPropertyTypes(state, action: PayloadAction<ErrorCode>) {
      state.fetchMlsPropertyTypesErrorCode = action.payload;
    },
    saveMlsPropertyTypes(
      state,
      action: PayloadAction<MlsGetPropertyTypeResponse>,
    ) {
      state.fetchMlsPropertyTypesErrorCode = null;
      state.mlsPropertyTypes = action.payload;
    },
    saveMlsPropertyTypesWanderer(
      state,
      action: PayloadAction<MlsPropertyTypesWandererType>,
    ) {
      state.mlsPropertyTypesWanderer = action.payload;
    },
    resetMlsDetails(state) {
      state.detail = undefined;
      state.mlsPropertyTypes = null;
      state.mlsPropertyTypesWanderer = {};
    },
  },
});

export const {
  changeLoadingDetail,
  saveDetail,
  errorFetchingDetail,
  changeMlsPropertyTypesLoading,
  errorFetchingMlsPropertyTypes,
  saveMlsPropertyTypes,
  saveMlsPropertyTypesWanderer,
  resetMlsDetails,
} = MLSSlice.actions;

export const mlsDetail = (id: string): AppThunk => async (dispatch) => {
  dispatch(changeLoadingDetail(true));
  try {
    const { data } = await new MlsControllerApi(
      getYentaConfiguration(),
    ).getMlsById(id);
    dispatch(saveDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch MLS', e, {
      mls: { id },
    });
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export const createMls = (
  mlsData: MlsRequest,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new MlsControllerApi(
      getYentaConfiguration(),
    ).createMls(mlsData);
    dispatch(saveDetail(data));
    dispatch(showSuccessToast('MLS successfully created'));
    return data.id;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create mls', e, {
      mlsData,
    });
    dispatch(
      showErrorToastForErrorCode(
        "We couldn't create the MLS",
        ErrorService.getErrorCode(e),
      ),
    );
    return undefined;
  }
};

export const updateMls = (id: string, mlsData: MlsRequest): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new MlsControllerApi(
      getYentaConfiguration(),
    ).updateMlsById(id, mlsData);
    dispatch(saveDetail(data));
    dispatch(showSuccessToast('MLS successfully updated.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update mls', e, {
      mls: { id },
      mlsData,
    });
    dispatch(
      showErrorToastForErrorCode(
        "We couldn't update the MLS",
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const getPropertyTypeMapping = (
  mlsId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(changeMlsPropertyTypesLoading(true));
  try {
    const { data } = await new MlsPropertyTypeControllerApi(
      getYentaConfiguration(),
    ).getPropertyTypeMapping(mlsId);
    dispatch(saveMlsPropertyTypes(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch the MLS property types', e, {
      mls: { id: mlsId },
    });
    dispatch(errorFetchingMlsPropertyTypes(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeMlsPropertyTypesLoading(false));
  }
};

export const createOrUpdatePropertyTypeMappingUsing = (
  propertyTypeReq: MlsCreatePropertyTypeRequest,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new MlsPropertyTypeControllerApi(
      getYentaConfiguration(),
    ).createOrUpdatePropertyTypeMapping(propertyTypeReq);
    dispatch(showSuccessToast('MLS property types successfully updated.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update the MLS property types', e, {
      mls: {
        id: propertyTypeReq.mlsId,
        propertyTypeMapping: propertyTypeReq.mlsPropertyTypeMappings,
      },
    });
    dispatch(
      showErrorToastForErrorCode(
        "We couldn't update the MLS property types.",
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const fetchPropertyTypesWanderer = (
  code: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await new WandererControllerApi(
      getWandererConfiguration(),
    ).getPropertyTypeValuesForMLS(code);
    dispatch(saveMlsPropertyTypesWanderer(data));
  } catch (e) {
    if (e.response?.status !== 400) {
      dispatch(showApiErrorModal(e));
    }
    ErrorService.notifyIgnoreHandled(
      'Unable to fetch Property types for MLS',
      e,
      {
        mls: { id: code },
      },
    );
  }
};

export const fetchLiteMLSDetails = (
  pageNumber?: number,
  pageSize?: number,
  sortDirection?: Parameters<MlsControllerApi['searchLiteMls']>[2],
  name?: string,
  state?: Parameters<MlsControllerApi['searchLiteMls']>[4],
): AppThunk<
  Promise<GenericSearchResponseMlsPublicResponse | undefined>
> => async (dispatch) => {
  try {
    const { data } = await new MlsControllerApi(
      getYentaConfiguration(),
    ).searchLiteMls(pageNumber, pageSize, sortDirection, name, state);
    return data;
  } catch (e) {
    ErrorService.notify('Unable to search mls', e, {
      data: {
        search: name,
        pagination: { pageNumber, pageSize, sortDirection, state },
      },
    });
    dispatch(
      showErrorToast(
        'An unexpected error occurred.',
        'We were unable to search for an mls. Please try again in a few moments or contact support.',
      ),
    );
    return undefined;
  }
};

export default MLSSlice.reducer;
