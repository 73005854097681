import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { components, MenuPosition, StylesConfig } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Option } from 'react-select/src/filters';
import classNames from 'classnames';
import { DEFAULT_PAGE_NUM } from '../../../constants/AsyncSelectPaginationConstants';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  AsyncSelectAdditional,
  AsyncSelectOption,
  AsyncSelectOptionReactElement,
  EnumMap,
  FeatureFlagTypeEnum,
  ISelectOption,
} from '../../../types';
import { cn } from '../../../utils/classUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';

export const ASYNC_MULTI_SELECT_DEBOUNCE = 400;

export type ZenControlledAsyncSelectInputVariant = 'light' | 'transparent';

interface ZenControlledAsyncSelectInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  labelClassName?: string;
  subLabel?: string;
  placeholder?: string;
  isMulti?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  fetchData(
    search: string,
    page?: number,
  ): Promise<Array<AsyncSelectOption | AsyncSelectOptionReactElement>>;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  selectClassName?: string;
  filterOption?:
    | ((option: Option, rawInput: string) => boolean)
    | null
    | undefined;
  isRequired?: boolean;
  preHighlightedValue?: string;
  menuPosition?: MenuPosition;
  variant?: ZenControlledAsyncSelectInputVariant;
  labelDescription?: string;
  isClearable?: boolean;
  maxMenuHeight?: number;
  hideChevronDown?: boolean;
}

const ZenControlledAsyncSelectInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  labelClassName,
  subLabel,
  placeholder = 'Select',
  isMulti = false,
  disabled = false,
  readOnly,
  fetchData,
  shouldUnregister = true,
  startAdornment,
  endAdornment,
  filterOption,
  selectClassName,
  isRequired = false,
  preHighlightedValue,
  menuPosition,
  labelDescription,
  variant = 'light',
  isClearable = false,
  maxMenuHeight,
  hideChevronDown = false,
  ...rest
}: ZenControlledAsyncSelectInputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);
  const colorStyles: StylesConfig<ISelectOption, boolean> | undefined = {
    option: (styles, { data, isFocused, isSelected }) => {
      const isFocusable = isFocused || data?.value === preHighlightedValue;
      return {
        ...styles,
        backgroundColor: isSelected
          ? '#2684ff'
          : isFocusable
          ? '#deebff'
          : '#ffffff',
      };
    },
  };

  const isGeminiDesignEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.GEMINI_REDESIGN,
  );

  return (
    <div className='space-y-1 w-full'>
      <Controller
        shouldUnregister={shouldUnregister}
        {...rest}
        render={({
          field: { onChange, value, name, onBlur },
          fieldState: { error, invalid },
        }) => {
          const variantToTextStyleMap: EnumMap<
            ZenControlledAsyncSelectInputVariant,
            string
          > = {
            light: invalid
              ? 'text-zen-danger'
              : isGeminiDesignEnabled
              ? 'text-primary-dark'
              : 'text-zen-dark-9',
            transparent: invalid ? 'text-zen-danger' : 'text-white',
          };

          const variantToBorderStyleMap: EnumMap<
            ZenControlledAsyncSelectInputVariant,
            string
          > = {
            light: focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
            transparent: focus ? 'border-zen-dark-7' : 'border-white',
          };

          return (
            <div
              className={cn(
                'space-y-1 w-full',
                isGeminiDesignEnabled && 'font-inter',
              )}
            >
              {label && (
                <label className='inline-block' htmlFor={name}>
                  <span
                    className={classNames(
                      !isGeminiDesignEnabled && 'font-zen-body font-semibold',
                      variantToTextStyleMap[variant],
                      labelClassName,
                    )}
                  >
                    {label}
                  </span>
                  {!!subLabel && (
                    <span
                      className={cn(
                        !isGeminiDesignEnabled && 'font-zen-body',
                        'text-sm text-zen-dark-12 ml-1',
                      )}
                    >
                      {subLabel}
                    </span>
                  )}
                  {isRequired && <span className='text-zen-danger'>*</span>}
                </label>
              )}
              {!!labelDescription && (
                <div className='font-zen-body text-sm text-zen-dark-6'>
                  {labelDescription}
                </div>
              )}
              <div
                className={cn(
                  'flex flex-row flex-grow items-center border rounded-lg bg-white',
                  value?.value && 'text-zen-dark-9',
                  variantToBorderStyleMap[variant],
                  invalid && '!border-zen-danger',
                )}
              >
                <div className='flex flex-row items-center flex-grow'>
                  {startAdornment && <div>{startAdornment}</div>}
                  <div className={cn('w-full', selectClassName)}>
                    <AsyncPaginate
                      placeholder={placeholder}
                      value={value}
                      name={name}
                      isMulti={isMulti}
                      menuPosition={menuPosition}
                      isDisabled={disabled || readOnly}
                      defaultValue={value}
                      maxMenuHeight={maxMenuHeight}
                      // @ts-ignore
                      loadOptions={async (
                        search,
                        _additionalOptions,
                        { page }: AsyncSelectAdditional,
                      ) => {
                        const data = await fetchData(search, page);
                        return {
                          options: data,
                          hasMore: !!data.length,
                          additional: {
                            page: page + 1,
                          },
                        };
                      }}
                      additional={{
                        page: DEFAULT_PAGE_NUM,
                      }}
                      debounceTimeout={ASYNC_MULTI_SELECT_DEBOUNCE}
                      onChange={onChange}
                      onFocus={() => setFocus(true)}
                      onBlur={() => {
                        setFocus(false);
                        onBlur();
                      }}
                      className='react-select-container font-zen-body font-normal text-base'
                      classNamePrefix={
                        variant === 'transparent'
                          ? 'react-select-noborder-transparent'
                          : 'react-select-noborder'
                      }
                      inputId={name}
                      isClearable={isClearable}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                        IndicatorsContainer: (props) =>
                          endAdornment ? (
                            <div className='flex flex-row items-center h-10'>
                              {isClearable && (
                                //@ts-ignore
                                <components.ClearIndicator {...props} />
                              )}
                              <div
                                className={cn(
                                  'h-10 border-l pt-2',
                                  focus
                                    ? 'border-zen-dark-9'
                                    : 'border-zen-dark-5',
                                  invalid && '!border-zen-danger',
                                )}
                              >
                                {endAdornment}
                              </div>
                            </div>
                          ) : isGeminiDesignEnabled && !hideChevronDown ? (
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              className='text-primary-dark mx-5'
                              size='sm'
                            />
                          ) : (
                            <div className='flex flex-row items-center h-10'>
                              {isClearable && (
                                //@ts-ignore
                                <components.ClearIndicator {...props} />
                              )}
                              {!hideChevronDown && (
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  className='text-zen-dark mx-3'
                                />
                              )}
                            </div>
                          ),
                      }}
                      filterOption={filterOption}
                      styles={colorStyles}
                    />
                  </div>
                </div>
              </div>
              {error && <ZenFormErrorMessage message={error.message} />}
            </div>
          );
        }}
      />
    </div>
  );
};
export default ZenControlledAsyncSelectInput;
