import {
  CreateJointVentureFormState,
  TierEligibility,
  TierEnum,
} from '../components/JointVenture/CreateJointVentureSteps';
import { MoneyValueCurrencyEnum } from '../openapi/arrakis';
import {
  CreateCapLevelsCriteriaRequest,
  CreateEligibilityCriterionRequestTypeEnum,
  CreateEliteStatusCriterionRequest,
  CreateTenureCriterionRequest,
  CreateTierRequest,
  CreateTransactionsCriterionRequest,
  InviteResponse,
  TierResponse,
} from '../openapi/avalon';
import { AgentResponseAccountCountryEnum } from '../openapi/yenta';
import { EnumMap, YesNoType } from '../types';
import { capitalizeEnum } from './StringUtils';

export const createMoneyValue = (
  amount: number,
  country?: AgentResponseAccountCountryEnum,
) => ({
  amount,
  currency:
    country === AgentResponseAccountCountryEnum.Canada
      ? MoneyValueCurrencyEnum.Cad
      : MoneyValueCurrencyEnum.Usd,
});

export const TierToNameMap: EnumMap<TierEnum, string> = {
  SILVER: 'Silver',
  GOLD: 'Gold',
  PLATINUM: 'Platinum',
  CREATE_TIER: '',
  NO_TIER: 'Open Group',
};

export const createEligibilityCriteria = (
  tenure: boolean,
  transactions: boolean,
  capLevels: boolean,
  criteria?: TierEligibility,
  country?: AgentResponseAccountCountryEnum,
) =>
  criteria
    ? [
        ({
          type: CreateEligibilityCriterionRequestTypeEnum.EliteStatus,
          achieved: !!criteria.achieved,
        } as any) as CreateEliteStatusCriterionRequest,
        ...(tenure
          ? [
              ({
                type: CreateEligibilityCriterionRequestTypeEnum.Tenure,
                minimumMonths: criteria.minimumMonths,
              } as any) as CreateTenureCriterionRequest,
            ]
          : []),
        ...(transactions
          ? [
              ({
                type: CreateEligibilityCriterionRequestTypeEnum.Transactions,
                minimumCount: criteria.minimumCount,
              } as any) as CreateTransactionsCriterionRequest,
            ]
          : []),
        ...(capLevels
          ? [
              ({
                type: CreateEligibilityCriterionRequestTypeEnum.CapLevels,
                capLevelsCriteria: criteria.capLevels.map(
                  ({ capped, capLevel }) => ({
                    capped: capped === YesNoType.YES,
                    capLevel: createMoneyValue(parseInt(capLevel), country),
                  }),
                ),
              } as any) as CreateCapLevelsCriteriaRequest,
            ]
          : []),
      ]
    : undefined;

export const createTierRequest = (
  values: CreateJointVentureFormState,
  country?: AgentResponseAccountCountryEnum,
): CreateTierRequest[] =>
  values.tiers.map(
    ({
      name,
      customTierName,
      poolPercentShare,
      buyInShares,
      autoInvite,
      colorCode,
      eligibilityCriteria,
      hasTenure,
      hasTransactions,
      hasCapLevels,
    }) => ({
      name: customTierName || capitalizeEnum(name!),
      poolShare: poolPercentShare,
      buyInShares,
      autoInvite: !!autoInvite,
      colorCode,
      eligibilityCriteria: createEligibilityCriteria(
        !!hasTenure,
        !!hasTransactions,
        !!hasCapLevels,
        eligibilityCriteria,
        country,
      ),
    }),
  );

export const getTotalAvailableShares = (inviteResponse: InviteResponse) => {
  const occupancy = inviteResponse?.tier?.occupancy;
  if (occupancy?.capacity) {
    return occupancy.capacity - (occupancy.occupied || 0);
  }
  return 0;
};

export const getTierAvailableShares = (tier: TierResponse) => {
  const occupancy = tier?.occupancy;
  if (occupancy?.capacity) {
    return occupancy.capacity - (occupancy.occupied || 0);
  }
  return 0;
};
