import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  AgentWebsiteResponse,
  AgentWebsiteResponsePhotoSetEnum,
  AgentWebsiteResponseThemeColorEnum,
} from '../../../../openapi/yenta';
import {
  agentCoverSetMap,
  agentWebsiteThemeColors,
} from '../../../../testUtils/AgentWebsiteOnboardingUtils';
import { capitalizeEnum } from '../../../../utils/StringUtils';

interface ZenAgentWebsiteThemeContentProps {
  agentWebsite: AgentWebsiteResponse | undefined;
}

const ZenAgentWebsiteThemeContent: React.FC<ZenAgentWebsiteThemeContentProps> = ({
  agentWebsite,
}) => {
  const photoSet =
    agentWebsite?.photoSet || AgentWebsiteResponsePhotoSetEnum.Cabin;

  const themeColor =
    agentWebsite?.themeColor! || AgentWebsiteResponseThemeColorEnum.Black;

  return (
    <div className='flex flex-row p-4 space-x-10 md:space-x-20 font-zen-title font-medium text-base'>
      <div className='flex flex-col space-y-2'>
        <p>{capitalizeEnum(photoSet)}</p>
        <div className='relative'>
          <img
            src={agentCoverSetMap[photoSet].medium}
            alt='theme'
            className='w-full md:w-56 h-28 object-cover rounded-lg'
          />
          <div className='absolute bottom-3 right-2 w-6 h-6 flex justify-center items-center bg-zen-dark-4 bg-opacity-80 p-1.5 rounded-full'>
            <FontAwesomeIcon icon={faCheck} className='text-white' />
          </div>
        </div>
      </div>
      <div className='flex flex-col space-y-2'>
        <p className='normal-case'>{capitalizeEnum(themeColor)}</p>
        <div
          className='w-20 h-20 rounded-full'
          style={{
            backgroundColor: agentWebsiteThemeColors[themeColor],
          }}
        />
      </div>
    </div>
  );
};

export default ZenAgentWebsiteThemeContent;
