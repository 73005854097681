import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCommentDots } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { cn } from '../../../utils/classUtils';
import ZenAvatar from '../../Zen/ZenAvatar';

export interface ZenNoteItemProps {
  name: string;
  comment: string;
  followUpDate?: {
    date: number;
    onClick(): void;
  };
  createdAt: number;
  avatar?: string;
  icon?: React.ReactElement;
  isLastItem?: boolean;
}

const ZenNoteItem: React.FC<ZenNoteItemProps> = ({
  comment,
  createdAt,
  name,
  icon,
  avatar,
  isLastItem = false,
  followUpDate,
}) => {
  return (
    <div className={cn('relative', { 'pb-8': !isLastItem })}>
      {!isLastItem && (
        <span
          className='absolute top-5 left-5 -ml-px h-full w-[1px] bg-zen-dark-4'
          aria-hidden='true'
        />
      )}
      <div className='relative flex items-start space-x-3'>
        <div className='relative'>
          <ZenAvatar name={name} imageUrl={avatar} excludeYentaPrefix />
          <span className='absolute -top-1.5 -right-2.5 bg-white rounded-full w-5 h-5 border border-primary-blue p-0.5 '>
            {icon ? (
              icon
            ) : (
              <FontAwesomeIcon
                icon={faCommentDots}
                size='sm'
                className='text-zen-blue-1 pb-0.5'
              />
            )}
          </span>
        </div>

        <div className='min-w-0 flex-1'>
          <div className='flex flex-col md:flex-row justify-between'>
            <div className='flex items-center'>
              <p className='font-zen-body text-sm font-semibold text-zen-dark-9'>
                {name}
              </p>
              <p className='ml-3 font-zen-body text-base font-semibold text-zen-dark-6'>
                {DateTime.fromMillis(createdAt).toLocaleString(
                  DateTime.DATE_FULL,
                )}
              </p>
            </div>

            <div>
              {followUpDate?.date && (
                <div className='flex space-x-1 items-center'>
                  <p className='font-zen-body text-zen-dark-9'>Follow Up</p>
                  <div className='inline-block py-1 rounded-full'>
                    <p className='bg-white  border rounded-full px-2 font-zen-body text-zen-dark-9'>
                      {DateTime.fromMillis(followUpDate.date).toLocaleString()}{' '}
                      <button
                        onClick={followUpDate.onClick}
                        className='focus:outline-none'
                      >
                        <FontAwesomeIcon
                          icon={faXmark}
                          size='sm'
                          className='mt-1 ml-1'
                        />
                      </button>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='mt-1 font-zen-body font-normal text-base text-zen-dark-9 break-words break-all'>
            <p>{comment}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenNoteItem;
