import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBolt,
  faCircleCheck,
  faCircleExclamation,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { InstantPaymentDetailResponseStatusEnum } from '../../openapi/arrakis';
import { EnumMap } from '../../types';
import Alert, { AlertVariantType } from '../Alert';

interface InstantPaymentStatusRowProps {
  status: InstantPaymentDetailResponseStatusEnum;
}

const InstantPaymentStatusRow: React.FC<InstantPaymentStatusRowProps> = ({
  status,
}) => {
  const enumMapIcons: EnumMap<
    InstantPaymentDetailResponseStatusEnum,
    React.ReactElement
  > = {
    [InstantPaymentDetailResponseStatusEnum.Repaid]: (
      <FontAwesomeIcon icon={faCircleCheck} className='text-white' />
    ),
    [InstantPaymentDetailResponseStatusEnum.Disbursed]: (
      <FontAwesomeIcon icon={faBolt} size='sm' className='text-white' />
    ),
    [InstantPaymentDetailResponseStatusEnum.PartiallyRepaid]: (
      <FontAwesomeIcon icon={faCircleExclamation} className='text-white' />
    ),
    [InstantPaymentDetailResponseStatusEnum.InProgress]: (
      <FontAwesomeIcon icon={faTriangleExclamation} className='text-white' />
    ),
  };
  const enumMapStatusText: EnumMap<
    InstantPaymentDetailResponseStatusEnum,
    string
  > = {
    [InstantPaymentDetailResponseStatusEnum.Repaid]: 'Repaid',
    [InstantPaymentDetailResponseStatusEnum.Disbursed]: 'Disbursed',
    [InstantPaymentDetailResponseStatusEnum.PartiallyRepaid]:
      'Partially Repaid',
    [InstantPaymentDetailResponseStatusEnum.InProgress]: 'In Progress',
  };
  const enumMapStatusVariant: EnumMap<
    InstantPaymentDetailResponseStatusEnum,
    AlertVariantType
  > = {
    [InstantPaymentDetailResponseStatusEnum.Repaid]: 'success',
    [InstantPaymentDetailResponseStatusEnum.Disbursed]: 'info',
    [InstantPaymentDetailResponseStatusEnum.PartiallyRepaid]: 'default',
    [InstantPaymentDetailResponseStatusEnum.InProgress]: 'warning',
  };

  return (
    <Alert
      text={enumMapStatusText[status]}
      icon={enumMapIcons[status]}
      variant={enumMapStatusVariant[status]}
    />
  );
};

export default InstantPaymentStatusRow;
