import { AgentWebsiteResponse } from '../../../../openapi/yenta';
import ZenTestimonialRow from './ZenTestimonialRow';

interface ZenTestimonialInfoProps {
  agentWebsite: AgentWebsiteResponse | undefined;
}

const ZenTestimonialInfo: React.FC<ZenTestimonialInfoProps> = ({
  agentWebsite,
}) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-4 p-4'>
      {agentWebsite?.testimonials && agentWebsite.testimonials.length > 0
        ? agentWebsite.testimonials.map((testimonial, index) => (
            <ZenTestimonialRow
              key={testimonial.clientName}
              step={index + 1}
              clientName={testimonial.clientName!}
              testimonial={testimonial.content!}
            />
          ))
        : Array.of(1, 2, 3).map((t, index) => (
            <ZenTestimonialRow key={t} step={index + 1} />
          ))}
    </div>
  );
};

export default ZenTestimonialInfo;
