import { faArrowUpArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form-v7';
import {
  AddressCountryEnum,
  AddressStateOrProvinceEnum,
  FieldObject,
} from '../../../openapi/atlantis';
import { cn } from '../../../utils/classUtils';
import {
  getGoogleAutoCompleteInputValidations,
  getInputValidations,
} from '../../../utils/mortgage/MortgageApplicationValidationsUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { getPostalCodeValidation } from '../../../utils/Validations';
import IconButton from '../../IconButton';
import ZenControlledGoogleAutocompleteSearchInput from '../../Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledSelectInput from '../../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';

interface AddressInputProps {
  name: string;
  field: FieldObject;
  onSubmit: () => void;
  isReadOnly: boolean;
}

const AddressInput: React.FC<AddressInputProps> = ({
  name,
  field,
  onSubmit,
  isReadOnly,
}) => {
  const { control, setValue } = useFormContext();
  const [isManualAddress, setIsManualAddress] = useState<boolean>(false);

  //@ts-ignore
  const isCountyRequired = field.validations?.find(
    //@ts-ignore
    (v) => v?.type === 'county' && v?.value === true,
    //@ts-ignore
  )?.message;

  React.useEffect(() => {
    setValue(`${name}.isManualAddress`, isManualAddress);
  }, [isManualAddress, name, setValue]);

  return (
    <div className='col-span-3'>
      <div className='flex flex-row items-center justify-between mb-5'>
        <p className='font-zen-title font-medium'>ADDRESS</p>
      </div>
      {isManualAddress ? (
        <div>
          <div>
            <ZenControlledTextInput
              name={`${name}.streetAddress1`}
              placeholder='Street'
              control={control}
              onBlurSpy={onSubmit}
              rules={{ required: 'Required' }}
              readOnly={isReadOnly}
            />
          </div>
          <div className='mt-6'>
            <ZenControlledTextInput
              name={`${name}.streetAddress2`}
              placeholder='Street Line 2'
              control={control}
              onBlurSpy={onSubmit}
              readOnly={isReadOnly}
            />
          </div>
          <div className='flex flex-row mt-6'>
            <div className='w-1/2 pr-2'>
              <ZenControlledTextInput
                name={`${name}.county`}
                placeholder='County'
                control={control}
                onBlurSpy={onSubmit}
                readOnly={isReadOnly}
                rules={{
                  required: isCountyRequired,
                }}
              />
            </div>
            <div className='w-1/2 pl-2'>
              <ZenControlledTextInput
                name={`${name}.city`}
                placeholder='City'
                control={control}
                onBlurSpy={onSubmit}
                readOnly={isReadOnly}
                rules={{ required: 'Required' }}
              />
            </div>
          </div>
          <div className='flex flex-row items-center mt-6'>
            <div className='w-1/2 pr-2'>
              <ZenControlledSelectInput
                name={`${name}.stateOrProvince`}
                placeholder='State'
                control={control}
                options={values(AddressStateOrProvinceEnum).map((c) => ({
                  label: capitalizeEnum(c),
                  value: c,
                }))}
                onChangeSpy={onSubmit}
                readOnly={isReadOnly}
                rules={{ required: 'Required' }}
              />
            </div>
            <div className='w-1/2 pl-2'>
              <ZenControlledTextInput
                name={`${name}.zipOrPostalCode`}
                placeholder='Zip Code'
                control={control}
                onBlurSpy={onSubmit}
                readOnly={isReadOnly}
                rules={{
                  required: 'Required',
                  ...getPostalCodeValidation(AddressCountryEnum.UnitedStates),
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <ZenControlledGoogleAutocompleteSearchInput
          name={name}
          control={control}
          //@ts-ignore
          placeholder={field.placeholder}
          onPlaceChangedSpy={onSubmit}
          disabled={isReadOnly}
          rules={{
            ...getInputValidations(field),
            ...getGoogleAutoCompleteInputValidations(field),
          }}
        />
      )}
      <div className='mt-4 flex flex-row justify-end'>
        <IconButton
          label={isManualAddress ? 'Search Address' : 'Enter address manually'}
          variant='none'
          buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
          leftIcon={
            <FontAwesomeIcon
              icon={faArrowUpArrowDown}
              className={cn(
                isReadOnly ? 'text-zen-dark-4' : 'text-primary-blue',
              )}
            />
          }
          onClick={() => setIsManualAddress(!isManualAddress)}
          disabled={isReadOnly}
        />
      </div>
    </div>
  );
};

export default AddressInput;
