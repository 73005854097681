import { isEqual } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StepByStepContainer, {
  StepConfig,
} from '../components/StepByStep/StepByStepContainer';
import {
  AgentReportedTransactionClosedDialogRequestMoneyStatusEnum,
  MoneyValue,
  MoneyValueCurrencyEnum,
  RezenObjectTypeEnum,
  TransactionControllerApi,
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
  TransactionResponseAgentReportedMoneyStatusEnum,
} from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  saveTransactionDetailResponse,
  toggleClosedModal,
  updatedEstimatedClosingDate,
} from '../slices/TransactionSlice';
import { AppDispatch, RootState, YesNoType } from '../types';
import {
  getArrakisConfiguration,
  getYadaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import {
  PostCommentBodyReaderRoleEnum,
  CommentControllerApi as CommentApi,
} from '../openapi/yada';
import { fetchMentionableOfficeGroups } from '../slices/OfficeSlice';
import { showErrorToastForErrorCode } from '../slices/ToastNotificationSlice';
import HasTransactionClosedNoStep from './HasTransactionClosedNoStep';
import HasTransactionClosedStep from './HasTransactionClosedStep';
import HasTransactionClosedYesStep from './HasTransactionClosedYesStep';
import MoneySentToRealNoStep from './MoneySentToRealNoStep';
import MoneySentToRealYesStep from './MoneySentToRealYesStep';

export interface HasTransactionClosedModalProps {
  transaction: TransactionResponse;
  transactionId: string;
}

export enum TransactionNotClosedOptionsEnum {
  NEW_CLOSING_DATE = 'NEW_CLOSING_DATE',
  REQUEST_TERMINATION = 'REQUEST_TERMINATION',
}

export interface TransactionClosedFormData {
  transactionClosed: YesNoType;
  transactionNotClosedReason: TransactionNotClosedOptionsEnum;
  moneyStatus: TransactionResponseAgentReportedMoneyStatusEnum;
  depositAmount: MoneyValue;
  agentReportedTransactionClosingDate: string;
  estimatedClosingDate: string;
  depositedAt: string;
  depositType: string;
  depositConfirmationNumber: string;
  hasDepositCheck: YesNoType;
  missingMoneyNote: string;
  closedModal: boolean;
  reason: string;
}

export enum TransactionClosedStepName {
  HAS_TRANSACTION_CLOSED = 'HAS_TRANSACTION_CLOSED',
  HAS_TRANSACTION_CLOSED_YES = 'HAS_TRANSACTION_CLOSED_YES',
  HAS_TRANSACTION_CLOSED_NO = 'HAS_TRANSACTION_CLOSED_NO',
  MONEY_SENT_TO_REAL_YES = 'MONEY_SENT_TO_REAL_YES',
  MONEY_SENT_TO_REAL_NO = 'MONEY_SENT_TO_REAL_NO',
}

export enum SentDepositToRealTypeEnum {
  Check = 'CHECK',
  Wire = 'WIRE',
  NotSure = 'NOT_SURE',
}

export const steps: StepConfig<
  TransactionClosedFormData,
  TransactionClosedStepName
>[] = [
  {
    name: TransactionClosedStepName.HAS_TRANSACTION_CLOSED,
    Component: HasTransactionClosedStep,
    hidePagination: true,
  },
  {
    name: TransactionClosedStepName.HAS_TRANSACTION_CLOSED_NO,
    Component: HasTransactionClosedNoStep,
    hidePagination: true,
  },
  {
    name: TransactionClosedStepName.HAS_TRANSACTION_CLOSED_YES,
    Component: HasTransactionClosedYesStep,
    hidePagination: true,
  },
  {
    name: TransactionClosedStepName.MONEY_SENT_TO_REAL_YES,
    Component: MoneySentToRealYesStep,
    showStepIf: ({ moneyStatus }) =>
      moneyStatus === TransactionResponseAgentReportedMoneyStatusEnum.Yes,
    hidePagination: true,
  },
  {
    name: TransactionClosedStepName.MONEY_SENT_TO_REAL_NO,
    Component: MoneySentToRealNoStep,
    showStepIf: ({ moneyStatus }) =>
      moneyStatus === TransactionResponseAgentReportedMoneyStatusEnum.No,
    hidePagination: true,
  },
];

const HasTransactionClosedModal: React.FC<HasTransactionClosedModalProps> = ({
  transaction,
  transactionId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const {
    office: { officeDetailById },
  } = useSelector((state: RootState) => state);
  const officeDetails = officeDetailById[transaction?.office?.id!];

  const { showClosedModal } = useSelector(
    (state: RootState) => state.transaction,
  );

  const displayHasTransactionClosedModal = useCallback(async () => {
    try {
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).isDisplayAgentReportedTransactionClosedDialog(transaction?.id!);
      dispatch(toggleClosedModal(data.displayDialog!));
    } catch (e) {
      ErrorService.notifyIgnoreAuthErrors(
        'Failed to fetch isDisplayAgentReportedTransactionClosingDate',
        e,
      );
    }
  }, [dispatch, transaction?.id]);

  useEffect(() => {
    if (!!transaction?.id && isEqual(transaction?.id, transactionId)) {
      displayHasTransactionClosedModal();
    }

    return () => {
      dispatch(toggleClosedModal(false));
    };
  }, [
    dispatch,
    displayHasTransactionClosedModal,
    transaction?.id,
    transactionId,
  ]);

  const onSubmit = async (values: TransactionClosedFormData) => {
    const {
      transactionClosed,
      estimatedClosingDate,
      transactionNotClosedReason,
      moneyStatus,
      hasDepositCheck,
      depositAmount,
      reason,
      ...request
    } = values;

    if (
      transactionNotClosedReason ===
      TransactionNotClosedOptionsEnum.NEW_CLOSING_DATE
    ) {
      await dispatch(
        updatedEstimatedClosingDate(transaction, estimatedClosingDate),
      );
    } else if (
      transactionNotClosedReason ===
      TransactionNotClosedOptionsEnum.REQUEST_TERMINATION
    ) {
      await onTerminateTransaction(values);
    } else if (hasDepositCheck === YesNoType.YES) {
      history.push(`/transactions/${transaction.id}/deposit-check`);
    } else {
      try {
        await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).submitAgentReportedTransactionClosedDialog(transaction.id!, {
          ...request,
          moneyStatus: (moneyStatus as unknown) as AgentReportedTransactionClosedDialogRequestMoneyStatusEnum,
          ...(moneyStatus ===
          TransactionResponseAgentReportedMoneyStatusEnum.Yes
            ? { depositAmount }
            : {}),
        });
      } catch (e) {
        dispatch(showApiErrorModal(e));
        ErrorService.notifyIgnoreAuthErrors(
          'Unable to submit agent report transaction closed',
          e,
        );
      }
    }

    dispatch(toggleClosedModal(false));
  };

  const onTerminateTransaction = async (values: TransactionClosedFormData) => {
    const mentionableGroups = await dispatch(
      fetchMentionableOfficeGroups(officeDetails?.id!),
    );

    const brokerTeam = (mentionableGroups?.groups || [])?.find(
      (group) => group?.groupName === 'Broker Team',
    );

    if (!brokerTeam) {
      return;
    }

    const commentData = {
      richContent: {
        blocks: [
          {
            type: 'TEXT',
            text: `${
              transaction?.listing ? 'LISTING' : 'TRANSACTION'
            } TERMINATION REQUESTED: ${values?.reason}`,
          },
          {
            type: 'MENTION',
            userId: brokerTeam?.id!,
            name: brokerTeam?.groupName!,
          },
          {
            type: 'TEXT',
            text: ' \n',
          },
        ],
      },
      readerRole: PostCommentBodyReaderRoleEnum.Public,
    };
    try {
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).transitionToTerminationRequested(transaction.id!);
      dispatch(
        saveTransactionDetailResponse({
          name: 'transaction detail',
          loading: false,
          data,
        }),
      );

      await new CommentApi(getYadaConfiguration()).postComment(
        RezenObjectTypeEnum.Transaction,
        transaction?.id!,
        'USER',
        {
          richContent: commentData.richContent,
          readerRole: commentData.readerRole,
        },
      );
    } catch (e) {
      ErrorService.notify(
        'Unable to notify broker on transaction termination',
        e,
      );
      dispatch(
        showErrorToastForErrorCode(
          'We had a notifying the broker. Please try again later.',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
    }
  };

  if (
    !showClosedModal ||
    transaction?.lifecycleState?.state ===
      TransactionLifecycleStateValueStateEnum.TerminationRequested
  ) {
    return null;
  }

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-60'
        role='button'
        onClick={() => dispatch(toggleClosedModal(false))}
      />
      <div className='flex items-center justify-center w-full h-full scrollbar overflow-scroll py-5 pt-10'>
        <div className='flex flex-col min-h-750 font-zen-body justify-center items-center w-full z-10'>
          <StepByStepContainer<
            TransactionClosedFormData,
            TransactionClosedStepName
          >
            steps={steps}
            onSubmit={onSubmit}
            defaultValues={{
              depositAmount: {
                currency: (transaction.currency as unknown) as MoneyValueCurrencyEnum,
              },
            }}
            mode='onChange'
          />
        </div>
      </div>
    </div>
  );
};

export default HasTransactionClosedModal;
