import { CellProps, Hooks } from 'react-table';
import { isSmScreen } from '../../../utils/BrowserUtils';
import TableSelectCheckbox from '../TableSelectCheckbox';

const useAllowSelection = <D extends object>(hooks: Hooks<D>) => {
  hooks.visibleColumns.push((columns) => [
    {
      id: 'selection',
      Header: ({ getToggleAllPageRowsSelectedProps }) =>
        !isSmScreen() ? (
          <div>
            <TableSelectCheckbox {...getToggleAllPageRowsSelectedProps()} />
          </div>
        ) : null,
      Cell: ({
        row,
      }: React.PropsWithChildren<CellProps<D, string | undefined>>) => (
        <div>
          <TableSelectCheckbox {...row.getToggleRowSelectedProps()} />
        </div>
      ),
    },
    ...columns,
  ]);
};

export default useAllowSelection;
