import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RealLogoImg from '../../assets/img/new-rezen-black-logo.svg';
import Alert from '../../components/Alert';
import SubmitButton from '../../components/SubmitButton';
import ZenControlledTextInput from '../../components/Zen/Input/ZenControlledTextInput';
import { AuthControllerApi, LoginRequest } from '../../openapi/keymaker';
import ErrorService from '../../services/ErrorService';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { AppDispatch, ErrorCode } from '../../types';
import { getErrorMessage } from '../../utils/ErrorUtils';
import { getKeymakerConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { PASSWORD_VALIDATIONS } from '../../utils/Validations';
import Route404 from '../Route404';

interface ChangePasswordSectionProps {
  onChangePasswordSuccess(req: LoginRequest): void;
  title: string;
  subtitle: string;
  buttonText: string;
}

interface FormData extends LoginRequest {
  serverError: string;
  confirmPassword: string;
}

const ChangePasswordSection: React.FC<ChangePasswordSectionProps> = ({
  onChangePasswordSuccess,
  title,
  subtitle,
  buttonText,
}) => {
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const { passwordToken, emailAddress } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({ mode: 'onChange' });

  const onSubmit = async (values: LoginRequest) => {
    try {
      const api = await new AuthControllerApi(getKeymakerConfiguration());
      await api.changePassword({
        password: values.password,
        token: passwordToken as string,
      });

      onChangePasswordSuccess(values);
    } catch (e) {
      setError('serverError', {
        type: 'server',
        message:
          ErrorService.getErrorMessage(e) ||
          getErrorMessage(ErrorService.getErrorCode(e)),
      });

      const errorCode = ErrorService.getErrorCode(e);
      if (errorCode === ErrorCode.SERVER_ERROR) {
        ErrorService.notify('Unable to reset password.', e);
        dispatch(showErrorToast(getErrorMessage(ErrorService.getErrorCode(e))));
      }
    }
  };

  if (!passwordToken || !emailAddress) {
    return <Route404 />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='bg-white'>
        <div className='container flex items-center justify-center h-screen mx-auto'>
          <div className='p-10 lg:w-1/3'>
            <div className='flex items-center justify-center w-full'>
              <img
                src={RealLogoImg}
                className='h-7 print:h-7'
                alt='Real Logo'
              />
            </div>
            <p className='mt-5 text-3xl text-center font-primary-medium'>
              {title}
            </p>
            <p className='mt-5 text-xl text-center font-primary-medium'>
              {subtitle}
            </p>
            {errors.serverError && (
              <div className='mt-3'>
                <Alert
                  text={errors.serverError.message!}
                  variant='error'
                  icon={
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      size='lg'
                      className='mx-0.5'
                    />
                  }
                />
              </div>
            )}
            <div className='mt-5'>
              <ZenControlledTextInput
                control={control}
                label='Email'
                name='usernameOrEmail'
                defaultValue={emailAddress as string}
                placeholder='Enter username or email'
                readOnly
                rules={{
                  required: 'Email address is required',
                }}
              />
            </div>
            <div className='mt-5'>
              <ZenControlledTextInput
                control={control}
                type='password'
                label='Password'
                name='password'
                rules={PASSWORD_VALIDATIONS}
              />
            </div>
            <div className='mt-5'>
              <ZenControlledTextInput
                control={control}
                type='password'
                label='Password Confirmation'
                name='confirmPassword'
                rules={{
                  required: 'Please re-enter your password',
                  validate: (value) =>
                    getValues().password !== value
                      ? 'Passwords do not match'
                      : undefined,
                }}
              />
            </div>
            <SubmitButton
              label={buttonText}
              isSubmitting={isSubmitting}
              onClick={() => {
                if (errors.serverError) {
                  clearErrors('serverError');
                }
              }}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChangePasswordSection;
