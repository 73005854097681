import { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { faArrowUpArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ADDRESS_PLACEHOLDER } from '../../constants/PlaceholderConstants';
import {
  CreateCommissionAdvanceRequest,
  TransactionControllerApi,
} from '../../openapi/arrakis';
import {
  AgentResponse,
  MoneyValue,
  MoneyValueCurrencyEnum,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { addAgentCommissionAdvances } from '../../slices/AgentSlice';
import { ISelectOption } from '../../types';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { MONEY_AMOUNT_REGEX } from '../../utils/StringUtils';
import {
  EMAIL_VALIDATIONS,
  FILE_VALIDATIONS,
  GOOGLE_AUTO_COMPLETE_VALIDATIONS,
} from '../../utils/Validations';
import IconButton from '../IconButton';
import ZenSidebarModalActionFooter from '../SidebarModal/ZenSideBarModalActionFooter';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledFileUploadInput from '../Zen/Input/ZenControlledFileUploadInput';
import ZenControlledGoogleAutocompleteSearchInput, {
  GooglePlaceLocationType,
} from '../Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledMoneyInput from '../Zen/Input/ZenControlledMoneyInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenSidebarModal from '../Zen/ZenSidebarModal';

interface ZenAddAgentCommissionAdvancesSidebarFormProps {
  isOpen: boolean;
  onClose(): void;
  agent: AgentResponse;
  refresh(): void;
}

interface FormData {
  transactionId: ISelectOption;
  amount: MoneyValue;
  companyName: string;
  companyEmail: string;
  companyAddress: string;
  agreementPath: File[];
  referenceNumber: string;
  notes: string;
  companyAutoAddress: GooglePlaceLocationType;
}

const ZenAddAgentCommissionAdvancesSidebarForm: React.FC<ZenAddAgentCommissionAdvancesSidebarFormProps> = ({
  isOpen,
  onClose,
  agent,
  refresh,
}) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const [isManual, setIsManual] = useState<boolean>(true);

  const onSubmit = async (data: FormData) => {
    const createCommissionAdvanceRequest: Required<CreateCommissionAdvanceRequest> = {
      transactionId: data.transactionId!.value,
      amount: data.amount,
      companyName: data.companyName,
      companyEmail: data.companyEmail,
      companyAddress: isManual
        ? data.companyAddress
        : data.companyAutoAddress?.formatted_address,
      referenceNumber: data.referenceNumber,
      notes: data.notes,
    };

    await dispatch(
      addAgentCommissionAdvances(
        agent?.id!,
        createCommissionAdvanceRequest,
        data.agreementPath[0],
      ),
    );
    refresh();
    onClose();
  };

  return (
    <ZenSidebarModal
      title='Add a Commission Advance'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col justify-between min-h-full mb-8'
      >
        <div className='px-4 pb-24'>
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<FormData, 'transactionId'>
              name='transactionId'
              control={control}
              label='Transaction'
              placeholder='Select Transaction'
              fetchData={async (search, page) => {
                let options: ISelectOption[] = [];

                try {
                  const { data } = await new TransactionControllerApi(
                    getArrakisConfiguration(),
                  ).getTransactionsByStateGroupPaginated(
                    agent.id!,
                    'OPEN',
                    page || 0,
                    10,
                    search,
                    'CREATED_AT',
                    'DESC',
                  );

                  options = (data.transactions || []).map((transaction) => ({
                    label: `${transaction.address?.oneLine} - ${transaction.code}`,
                    value: transaction.id!,
                  }));
                } catch (e) {
                  ErrorService.notify('Unable to fetch transactions', e, {
                    data: { search, page },
                  });
                }

                return options;
              }}
              rules={{
                required: 'Please select a transaction',
              }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledMoneyInput<FormData, 'amount'>
              control={control}
              name='amount'
              label='Amount'
              rules={{
                validate: (v: MoneyValue) => {
                  if (!!v && !v?.amount) {
                    return 'Please enter an amount';
                  } else {
                    return !MONEY_AMOUNT_REGEX?.test(String(v?.amount))
                      ? 'Please enter a valid amount'
                      : undefined;
                  }
                },
              }}
              defaultValue={{
                currency: (agent.defaultCurrency as unknown) as MoneyValueCurrencyEnum,
              }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'companyName'>
              control={control}
              label='Company Name'
              name='companyName'
              rules={{ required: 'Please enter a company name' }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'companyEmail'>
              type='email'
              control={control}
              label='Company Email'
              name='companyEmail'
              rules={{
                required: 'Please enter a company email',
                ...EMAIL_VALIDATIONS,
              }}
              isRequired
            />
          </div>

          <div className='relative mt-7'>
            {isManual ? (
              <div className=''>
                <ZenControlledTextInput<FormData, 'companyAddress'>
                  control={control}
                  label='Company Address'
                  name='companyAddress'
                  placeholder={ADDRESS_PLACEHOLDER}
                  rules={{
                    required: "Please enter the company's address",
                  }}
                  isRequired
                />
              </div>
            ) : (
              <div className=''>
                <ZenControlledGoogleAutocompleteSearchInput<
                  FormData,
                  'companyAutoAddress'
                >
                  control={control}
                  shouldUnregister={false}
                  name='companyAutoAddress'
                  label='Search Company Address'
                  subLabel='(Source by Google)'
                  placeholder='E.g. 1st St. New York, NY 10010'
                  rules={{
                    ...GOOGLE_AUTO_COMPLETE_VALIDATIONS,
                  }}
                  isRequired
                />
              </div>
            )}
            <div className='absolute -top-3 right-0'>
              <IconButton
                label={isManual ? 'Search Address' : 'Enter address manually'}
                variant='none'
                buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
                leftIcon={<FontAwesomeIcon icon={faArrowUpArrowDown} />}
                onClick={() => {
                  setIsManual(!isManual);
                }}
              />
            </div>
          </div>
          <div className='mt-5'>
            <ZenControlledFileUploadInput<FormData, 'agreementPath'>
              control={control}
              label='Agreement'
              name='agreementPath'
              rules={{
                required: 'Please attach the agreement',
                ...FILE_VALIDATIONS,
              }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'referenceNumber'>
              control={control}
              label='Reference Number (Optional)'
              name='referenceNumber'
            />
          </div>
          <div className='my-5'>
            <ZenControlledTextInput<FormData, 'notes'>
              control={control}
              label='Notes (Optional)'
              name='notes'
            />
          </div>
        </div>
        <ZenSidebarModalActionFooter
          onClose={onClose}
          isSubmitting={isSubmitting}
          submitButtonDisabled={isSubmitting}
          submitButtonText='Add'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenAddAgentCommissionAdvancesSidebarForm;
