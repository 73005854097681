import { cn } from '../../utils/classUtils';

type ZenCheckboxVariantType = 'square' | 'circle';
type ZenCheckboxRingVariantType = 'none' | 'default';
type ZenCheckboxBorderVariantType = 'none' | 'dark';
type ZenCheckboxSizeVariantType = 'small' | 'medium' | 'large';

interface ZenCheckBoxProps {
  value: boolean;
  onChange(): void;
  variant?: ZenCheckboxVariantType;
  ringVariant?: ZenCheckboxRingVariantType;
  borderVariant?: ZenCheckboxBorderVariantType;
  isCheckedOnce?: boolean;
  disabled?: boolean;
  name?: string;
  size?: ZenCheckboxSizeVariantType;
}

const ZenCheckBox: React.FC<ZenCheckBoxProps> = ({
  value,
  onChange,
  variant = 'circle',
  ringVariant = 'default',
  borderVariant = 'none',
  isCheckedOnce,
  disabled = false,
  name,
  size = 'small',
}) => {
  const variantClassMap: Record<ZenCheckboxVariantType, string> = {
    square: 'rounded',
    circle: 'rounded-full',
  };

  const ringVariantClassMap: Record<ZenCheckboxRingVariantType, string> = {
    none: 'ring-0 focus:ring-0',
    default: 'ring-1 ring-grey-200 focus:ring-0',
  };

  const borderVariantClassMap: Record<ZenCheckboxBorderVariantType, string> = {
    none: 'border-none',
    dark: 'border-2 border-zen-dark-4',
  };

  const sizeVariantClassMap: Record<ZenCheckboxSizeVariantType, string> = {
    small: 'h-4 w-4',
    medium: 'h-6 w-6',
    large: 'h-8 w-8',
  };

  return (
    <input
      id={name}
      type='checkbox'
      data-testid='zen-checkbox'
      className={cn(
        'focus:outline-none text-primary-blue indeterminate:text-dark',
        variantClassMap[variant],
        ringVariantClassMap[ringVariant],
        borderVariantClassMap[borderVariant],
        sizeVariantClassMap[size],
        isCheckedOnce && !value && 'ring-zen-danger',
        !disabled && 'cursor-pointer',
      )}
      onChange={onChange}
      checked={value}
      disabled={disabled}
    />
  );
};
export default ZenCheckBox;
