import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  BankAccountDto,
  MoneyTransferResponse,
  TransactionResponse,
} from '../../../openapi/arrakis';
import {
  confirmMoneyTransfer,
  fetchBankAccounts,
} from '../../../slices/MoneyTransferSlice';
import { displayFormattedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import { getBankAccountFormattedString } from '../../../utils/TransactionHelper';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';

interface MoneyTransferConfirmationModalProps {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
  moneyTransfer: MoneyTransferResponse;
  bankAccounts: BankAccountDto[];
}

const ZenMoneyTransferConfirmationModal: React.FC<MoneyTransferConfirmationModalProps> = ({
  isOpen,
  onClose,
  transaction,
  moneyTransfer,
  bankAccounts,
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const fromBankAccount = bankAccounts.find(
    (bankAccount) => bankAccount.id === moneyTransfer?.fromBankAccountId,
  );
  const toBankAccount = bankAccounts.find(
    (bankAccount) => bankAccount.id === moneyTransfer?.toBankAccountId,
  );

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await dispatch(confirmMoneyTransfer(moneyTransfer.id!));
    await dispatch(fetchBankAccounts(transaction?.id!));
    setIsSubmitting(false);
    onClose();
  };

  return (
    <ZenConfirmationModal
      isOpen={isOpen}
      variant='primary'
      title='Confirm money transfer is executed?'
      subtitle={`This confirms that the transfer of ${displayFormattedAmountWithCurrency(
        moneyTransfer?.amount,
      )} FROM ${getBankAccountFormattedString(
        fromBankAccount!,
        true,
      )} TO ${getBankAccountFormattedString(
        toBankAccount!,
        true,
      )} has been executed.`}
      // customIcon={<BiListCheck size={24} />}
      onClose={onClose}
      onConfirm={handleSubmit}
      isSubmitting={isSubmitting}
    >
      <p className='text-base text-gray-500 font-primary-regular text-center'>
        Is this Confirmed?
      </p>
    </ZenConfirmationModal>
  );
};

export default ZenMoneyTransferConfirmationModal;
