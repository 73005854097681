import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import ResourceContainer, {
  ResourceContainerProps,
} from '../../../ResourceContainer';
import StockForecastSliderInput from '../../V2/StockForecastSliderInput';
import WealthPlanCalenderEventCard from '../../V2/WealthPlanCalenderEventCard';
import { EarningType, Earning } from '../../WealthManagementTypes';

export const WealthPlanSidebarTitlesEnumMap: Record<EarningType, string> = {
  [Earning.COMMISSION_INCOME]:
    'Project your 5 year net commission income by adjusting transactions and volume',
  [Earning.PRE_CAP_SPP]:
    'See how much your stock can be worth when you join the Stock Purchase Plan (SPP)',
  [Earning.STOCK_AWARD_FOR_CAPPING]:
    'Real grants you a stock award when you cap',
  [Earning.POST_CAP_SPP]:
    'See how much your stock can be worth when you join the Stock Purchase Plan (SPP)',
  [Earning.ELITE_PRODUCTION_AWARD]:
    'Real grants you a stock award when you achieve Elite Agent status',
  [Earning.ELITE_CULTURAL_AWARD]:
    'Real grants you a stock award when you achieve Elite Agent status and educate other agents',
  [Earning.REVENUE_SHARE_INCOME]:
    'Earn revenue share income when you attract producing agents to Real',
  [Earning.STOCK_AWARD_FOR_ATTRACTING]:
    'Earn stock awards when you attract producing agents to Real',
};

interface Props extends ResourceContainerProps {
  type: EarningType;
  showStockForecastInput?: boolean;
}

const WealthPlanSidebarContainer: React.FC<Props> = ({
  type,
  showStockForecastInput = true,
  ...props
}) => {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [
    location,
  ]);
  const token = params.get('token');

  return (
    <div className='w-full h-screen'>
      <ResourceContainer {...props}>
        <div className='flex flex-col space-y-3 pb-5'>
          <p className='font-zen-body text-base text-zen-dark-9'>
            {WealthPlanSidebarTitlesEnumMap[type]}
          </p>
          {props.children}
          {showStockForecastInput && <StockForecastSliderInput />}
          {!token && <WealthPlanCalenderEventCard type={type} />}
        </div>
      </ResourceContainer>
    </div>
  );
};

export default WealthPlanSidebarContainer;
