// VerificationSection.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import { Control } from 'react-hook-form-v7';
import ZenControlledToggleInput from '../Zen/Input/ZenControlledToggleInput'; // Update the import path as necessary

interface VerificationSectionProps {
  control: Control<any>;
  isVerified: boolean;
  setIsLocationEdit: (value: boolean) => void;
  isDisabled?: boolean;
}

const ZenVerificationSection: React.FC<VerificationSectionProps> = ({
  control,
  isVerified,
  setIsLocationEdit,
  isDisabled,
}) => {
  return (
    <div className='flex flex-row justify-end mr-6'>
      <div className='flex flex-row items-center space-x-2'>
        <FontAwesomeIcon icon={faBadgeCheck} color='green' size='lg' />
        <label
          htmlFor='name'
          className='text-base font-zen-body font-semibold text-zen-dark-26'
        >
          Verify Contact
        </label>
      </div>
      <div className='flex items-center w-min mt-0.5'>
        <ZenControlledToggleInput
          name='isVerifiedToggled'
          label=''
          control={control}
          disabled={isDisabled}
          defaultValue={false}
          onChangeSpy={(value) => {
            setIsLocationEdit(value);
          }}
        />
        <label
          htmlFor='istoggle'
          className='text-sm font-zen-body font-medium text-zen-dark-26'
          data-testid='Verify-contact-label'
        >
          {isVerified ? 'Yes' : 'No'}
        </label>
      </div>
    </div>
  );
};

export default ZenVerificationSection;
