import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LoginRequest } from '../../openapi/keymaker';
import { AppDispatch } from '../../types';
import { showSuccessToast } from '../../slices/ToastNotificationSlice';
import ChangePasswordSection from './ChangePasswordSection';

const ChangePasswordRoute: React.FC = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const onChangePasswordSuccess = (_values: LoginRequest) => {
    dispatch(
      showSuccessToast(
        'Password successfully changed.',
        'Please log back in to the mobile app with your new password.',
      ),
    );
    history.push('/login');
  };

  return (
    <ChangePasswordSection
      onChangePasswordSuccess={onChangePasswordSuccess}
      title='Building your future, together'
      subtitle='Please enter a new password'
      buttonText='Change Password'
    />
  );
};

export default ChangePasswordRoute;
