import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import IconText from '../IconText';
import { formatPhoneNumber } from '../../utils/StringUtils';
import { Contact } from '../../openapi/arrakis';
import { getFullName } from '../../utils/AgentHelper';

interface TitleProgressContactProps {
  contact?: Contact;
  isCaliforniaTransaction?: boolean;
}

const TitleProgressContact: React.FC<TitleProgressContactProps> = ({
  contact,
  isCaliforniaTransaction,
}) => {
  return (
    <div className='w-full md:w-min'>
      <p className='font-primary-medium text-xl text-dark mb-3'>
        {isCaliforniaTransaction ? 'Escrow Contact' : 'Title Contact'}
      </p>
      <div className='p-5 border rounded'>
        {/* <Avatar
          name={getFullName(contact) || 'N/A'}
          imageUrl={contact?.avatar}
          size='xl'
        /> */}
        <div className='space-y-2'>
          <p className='font-primary-medium text-sm text-secondary-dark'>
            {getFullName(contact) || 'N/A'}
          </p>
          <IconText
            icon={
              <FontAwesomeIcon
                icon={faPhone}
                size='sm'
                className='text-secondary-dark mt-1'
              />
            }
            text={contact?.phone ? formatPhoneNumber(contact.phone)! : 'N/A'}
            textStyles='text-secondary-dark'
          />
          <IconText
            icon={
              <FontAwesomeIcon
                icon={faEnvelope}
                size='sm'
                className='text-secondary-dark mt-1'
              />
            }
            text={contact?.email || 'N/A'}
            textStyles='text-secondary-dark'
          />
        </div>
      </div>
    </div>
  );
};

export default TitleProgressContact;
