import { useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminOnly from '../components/auth/AdminOnly';
import ZenBoardActionHeader from '../components/board/Zen/ZenBoardActionHeader';
import ZenBoardHeaderSection from '../components/board/Zen/ZenBoardHeaderSection';
import DetailPageLoader from '../components/DetailPageLoader';
import GeminiBoardHeaderSection from '../components/Gemini/Board/GeminiBoardHeaderSection';
import ResourceContainer from '../components/ResourceContainer';
import ZenAgentListContainer from '../components/Zen/ZenAgentListContainer';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { AgentResponse } from '../openapi/yenta';
import {
  useFetchBoardDetails,
  useFetchBoardSecretDetails,
} from '../query/boardsAndMls/useBoardsAndMls';
import ErrorService from '../services/ErrorService';
import { FeatureFlagTypeEnum } from '../types';

type Match = {
  id: string;
};

const ZenBoardDetailRoute: React.FC = () => {
  const [agents, setAgents] = useState<AgentResponse[]>([]);
  const { id: boardId } = useParams() as Match;

  const isBoardAndMlsImprovementsFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.BOARD_AND_MLS_IMPROVEMENTS,
  );

  const {
    isLoading,
    data,
    error,
    refetch: refetchBoard,
  } = useFetchBoardDetails(boardId);
  const { data: secrets, refetch: refetchSecret } = useFetchBoardSecretDetails(
    boardId,
    isBoardAndMlsImprovementsFlagEnabled,
  );

  const BoardHeaderSection = isBoardAndMlsImprovementsFlagEnabled
    ? GeminiBoardHeaderSection
    : ZenBoardHeaderSection;

  const handleRefetch = () => {
    refetchBoard();
    refetchSecret();
  };

  return (
    <ZenRoute title='Board Details'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Boards', url: '/boards' },
          { title: `${data?.name}`, url: `/boards/${data?.id}` },
        ]}
      >
        <ResourceContainer
          loading={isLoading}
          LoaderComponent={<DetailPageLoader />}
          errorCode={!!error ? ErrorService.getErrorCode(error) : undefined}
          isEmpty={!data}
          resourceName='board'
        >
          <div className='px-4 mt-1 pb-6 space-y-5'>
            <BoardHeaderSection board={data || {}} secrets={secrets} />
            <AdminOnly>
              <ZenBoardActionHeader
                board={data || {}}
                secrets={secrets}
                agents={agents!}
                refetch={handleRefetch}
              />
            </AdminOnly>
            <ZenAgentListContainer boardId={[boardId]} setAgents={setAgents} />
          </div>
        </ResourceContainer>
      </ZenPageLayout>
    </ZenRoute>
  );
};

export default ZenBoardDetailRoute;
