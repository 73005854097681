import React from 'react';

interface CheckInfoTableHeaderCellProps {
  data: string;
}

const ZenCheckInfoTableHeaderCell: React.FC<CheckInfoTableHeaderCellProps> = ({
  data,
}) => {
  return (
    <th className='font-zen-body font-normal text-base text-zen-dark-9 py-3 px-2 md:px-5 text-left'>
      {data}
    </th>
  );
};

export default ZenCheckInfoTableHeaderCell;
