import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  CreateJointVentureRequest,
  CreateJointVentureRequestStatesEnum,
} from '../../openapi/avalon';
import { createJointVenture } from '../../slices/JointVentureSlice';
import {
  AppDispatch,
  CapCardISelection,
  ISelectOption,
  RootState,
} from '../../types';
import {
  createMoneyValue,
  createTierRequest,
  TierToNameMap,
} from '../../utils/JVUtils';
import StepByStepContainer, {
  StepConfig,
} from '../StepByStep/StepByStepContainer';
import GeneralInfoStep from './GeneralInfoStep';
import SharesStep from './SharesStep';
import TierStep from './TierStep';

export enum TierEnum {
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
  CREATE_TIER = 'CREATE_TIER',
  NO_TIER = 'NO_TIER',
}

export enum CreateJointVentureStepName {
  GENERAL_INFO = 'GENERAL',
  TIERS = 'TIERS',
  SHARE_SPLIT = 'SHARES',
}

export interface TierEligibility {
  minimumMonths: number;
  minimumCount: number;
  capLevels: CapCardISelection[];
  achieved: boolean;
}

export interface Tier {
  tierLevel?: ISelectOption;
  colorCode: string;
  poolPercentShare: number;
  buyInShares: number;
  name: string | undefined;
  customTierName?: string;
  eligibilityCriteria?: TierEligibility;
  autoInvite?: boolean;
  hasTenure?: boolean;
  hasTransactions?: boolean;
  hasCapLevels?: boolean;
}

export interface CreateJointVentureFormState {
  name: string;
  companyId: ISelectOption;
  bankAccountId: ISelectOption;
  states: ISelectOption<CreateJointVentureRequestStatesEnum>[];
  issuePrice: number;
  shares: number;
  realPoolPercentShare: number;
  tiers: Tier[];
}

const CreateJointVentureSteps: React.FC = () => {
  const history = useHistory();
  const steps: StepConfig<
    CreateJointVentureFormState,
    CreateJointVentureStepName
  >[] = [
    {
      name: CreateJointVentureStepName.GENERAL_INFO,
      Component: GeneralInfoStep,
      hidePagination: true,
    },
    {
      name: CreateJointVentureStepName.TIERS,
      Component: TierStep,
      hidePagination: true,
    },
    {
      name: CreateJointVentureStepName.SHARE_SPLIT,
      Component: SharesStep,
      hidePagination: true,
    },
  ];
  const dispatch: AppDispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const defaultValues = {
    tiers: [{}],
  };

  const onSubmit = async (values: CreateJointVentureFormState) => {
    const {
      name,
      issuePrice,
      shares,
      realPoolPercentShare,
      companyId: { value: companyId },
      bankAccountId: { value: bankAccountId },
      states,
    } = values;

    const req: CreateJointVentureRequest = {
      name:
        values.tiers[0].name === TierToNameMap.NO_TIER
          ? TierToNameMap.NO_TIER
          : name,
      realEquity: realPoolPercentShare,
      issuePrice: createMoneyValue(issuePrice, userDetail?.accountCountry),
      shares,
      companyId,
      bankAccountId,
      states: states.map(({ value }) => value),
      tiers: createTierRequest(values, userDetail?.accountCountry),
    };

    const data = await dispatch(createJointVenture(req));
    if (data) {
      history.push(`/real-title/joint-ventures`);
    }
  };

  return (
    <StepByStepContainer<
      CreateJointVentureFormState,
      CreateJointVentureStepName
    >
      steps={steps}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      reValidateMode='onChange'
      mode='onChange'
    />
  );
};

export default CreateJointVentureSteps;
