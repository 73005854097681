import React from 'react';
import { ReactComponent as PersonIcon } from '../../../../assets/img/zen/transactionHeader/person.svg';
import { TransactionResponse } from '../../../../openapi/arrakis';
import { EnumMap } from '../../../../types';
import { safeEnumMapGet } from '../../../../utils/EnumHelper';
import { cn } from '../../../../utils/classUtils';

export enum RepresentationTypeEnum {
  Buyer = 'Buy Side Representation',
  Dual = 'Dual Representation',
  Seller = 'Listing Side Representation',
}

interface ZenTransactionRepresenteeTypeProps {
  transaction: TransactionResponse;
}

const ZenTransactionRepresenteeType: React.FC<ZenTransactionRepresenteeTypeProps> = ({
  transaction,
}) => {
  const representationTypeMap: EnumMap<RepresentationTypeEnum, string> = {
    [RepresentationTypeEnum.Buyer]: 'text-primary-blue bg-zen-light-blue',
    [RepresentationTypeEnum.Dual]: 'text-green-600 bg-green-100',
    [RepresentationTypeEnum.Seller]: 'text-zen-purple-dark bg-zen-purple-light',
  };

  return (
    <div
      className={cn(
        'rounded-lg w-64',
        safeEnumMapGet(
          representationTypeMap,
          transaction?.transactionOwner?.represents! as RepresentationTypeEnum,
          'text-zen-purple-dark bg-zen-purple-light',
        ),
      )}
    >
      <div className='flex flex-row justify-center items-center p-1'>
        <PersonIcon width={24} height={24} />
        <p className='font-zen-body font-medium text-sm min-w-max'>
          {`Rep: ${transaction.transactionOwner?.represents || 'N/A'}`}
        </p>
      </div>
    </div>
  );
};

export default ZenTransactionRepresenteeType;
