import React from 'react';
import { useForm } from 'react-hook-form-v7';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import { ExperimentResponse } from '../../openapi/yenta';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalActionFooterV2 from '../Zen/Modal/ZenSidebarModalActionFooterV2';
import { NUMBER_REGEX } from '../../utils/StringUtils';

interface ZenEditExperimentProps {
  isOpen: boolean;
  onClose(weightValue: string | undefined): void;
  experimentResponse: ExperimentResponse;
}

interface FormData {
  weight: string | undefined;
}

const ZenEditExperiment: React.FC<ZenEditExperimentProps> = ({
  isOpen,
  onClose,
  experimentResponse,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      weight: `${experimentResponse.groups![0].weight}`,
    },
  });

  const onSubmit = async (data: FormData) => {
    onClose(data.weight);
  };

  return (
    <ZenSidebarModal
      isOpen={isOpen}
      onClose={() => onClose(undefined)}
      title='Edit Experiment Group'
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='edit-experiment-group-form'
      >
        <div className='p-5'>
          <div className='mt-3'>
            <ZenControlledTextInput<FormData, 'weight'>
              control={control}
              type='number'
              label='Weight'
              name='weight'
              placeholder='Weight'
              rules={{
                min: { value: 0, message: 'Number cannot be less than 0' },
                max: {
                  value: 100,
                  message: 'Number cannot be greater than 100',
                },
                pattern: {
                  value: NUMBER_REGEX,
                  message: 'Please enter a valid number',
                },
              }}
            />
          </div>
        </div>
        <ZenSidebarModalActionFooterV2
          onClose={() => onClose(undefined)}
          isSubmitting={isSubmitting}
          submitButtonText='Save'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenEditExperiment;
