import { uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAgentsInfo from '../../../hooks/useAgentsInfo';
import {
  AgentParticipantInfoRoleEnum,
  TransactionOwnerAgentInfoRequest,
} from '../../../openapi/arrakis';
import {
  AgentResponseAccountCountryEnum,
  TeamControllerApi,
  TeamMemberResponse,
  TeamMemberResponseRolesEnum,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  addCoAgent,
  deleteCoAgentInfo,
  saveOwnerInfos,
} from '../../../slices/TransactionBuilderSlice';
import {
  AppDispatch,
  AsyncSelectOption,
  AsyncSelectOptionReactElement,
  ISelectOption,
  RootState,
} from '../../../types';
import { STATE_OR_PROVINCE_ABBREVIATIONS } from '../../../utils/AnnouncementUtils';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { searchActiveAgents } from '../../../utils/TableUtils';
import {
  getOfficesInPropertyState,
  isLeaseTransaction,
} from '../../../utils/TransactionHelper';
import AvatarLabelComponent from '../../AgentReports/AvatarLabelComponent';
import Avatar from '../../Avatar';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenControlledAsyncCustomSelectInput from '../../Zen/Input/ZenControlledAsyncCustomSelectInput';
import ZenControlledAsyncSelectInput from '../../Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledHTMLSelectInput from '../../Zen/Input/ZenControlledHTMLSelectInput';
import ZenButton from '../../Zen/ZenButton';
import ZenUserPill from '../../Zen/ZenUserPill';
import withCreateListingProgress from '../CreateListing/withCreateListingProgress';
import ZenControlledSelectInput from '../../Zen/Input/ZenControlledSelectInput';
import { getTeamIds } from '../../../utils/TeamHelper';
import {
  Match,
  ZenCreateListingFormState,
  ZenCreateListingStepName,
} from './ZenCreateListingSteps';

const ZenListingOwnerStep: StepByStepComponent<
  ZenCreateListingFormState,
  ZenCreateListingStepName
> = ({ form: { control, watch, setValue, trigger }, onPrevious, onNext }) => {
  const {
    auth: { userDetail },
    userIds: { agentById },
    transactionBuilder: { transactionBuilder },
  } = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();
  const { transactionBuilderId } = useParams<Match>();
  const [loading, setLoading] = useState<boolean>(false);
  const [members, setMembers] = useState<ISelectOption[]>([]);
  const [transactionOwnerName, officeId, selectedTeamId] = watch([
    'transactionOwnerName',
    'officeId',
    'teamId',
  ]);

  const [teamLeadersList, setTeamLeadersList] = useState<
    TeamMemberResponse[] | undefined
  >([]);

  const [showTeams, setShowTeams] = useState<ISelectOption[]>([]);

  const isNormalAgent = !userDetail?.flexTeamMemberships?.length;

  const addAgent = async () => {
    const realCoAgent = watch('realCoAgent', {}) as AsyncSelectOption;

    if (realCoAgent && Object.keys(realCoAgent).length !== 0) {
      const idAndAvatar = realCoAgent?.value?.split(' ');

      const res = await dispatch(
        addCoAgent(transactionBuilderId!, {
          agentId: idAndAvatar[0],
          role: AgentParticipantInfoRoleEnum.Seller,
        }),
      );
      if (res) {
        setValue('realCoAgent', {});
      }
    }
  };

  const filterOwnerTeams = useCallback(
    (id: string) => {
      setShowTeams([]);
      setTeamLeadersList([]);

      const teams = userDetail?.flexTeamMemberships
        // eslint-disable-next-line array-callback-return
        ?.map((team) => {
          const isPartOfTeam =
            !!team.leaders?.find((leader) => leader.agentId === id) ||
            !!team.teammates?.find((teammate) => teammate.agentId === id);
          if (isPartOfTeam) return team!;
          return undefined;
        })
        .filter((team) => !!team);

      const flexTeamsToDisplay = teams?.map((team) => ({
        label: team?.teamName!,
        value: team?.teamId!,
      }))!;

      setShowTeams(flexTeamsToDisplay);
    },
    [userDetail],
  );

  useEffect(() => {
    const flexTeamsToDisplay = userDetail?.flexTeamMemberships?.map((team) => ({
      label: team?.teamName!,
      value: team?.teamId!,
    }));

    setShowTeams(flexTeamsToDisplay!);
  }, [userDetail]);

  useEffect(() => {
    filterOwnerTeams(transactionOwnerName?.value);
  }, [filterOwnerTeams, transactionOwnerName?.value]);

  const fetchTeamMembers = useCallback(
    async (teamId: string): Promise<void> => {
      try {
        const {
          data: { agents },
        } = await new TeamControllerApi(getYentaConfiguration()).getTeamById(
          teamId,
        );

        const agentData = (agents || []).map((agent) => ({
          label: `${agent.agent?.fullName!} - ${agent.agent?.emailAddress}`,
          value: agent.agent?.id!,
        }));

        // an agent may belong to multiple teams, so we want to show only uniques
        setMembers((members) => uniqBy([...members, ...agentData], 'value'));
      } catch (e) {
        ErrorService.notify('Unable to fetch team by id', e, {
          team: { id: teamId },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const coAgents = transactionBuilder?.agentsInfo?.coAgents?.filter(
    (p) => p.role !== AgentParticipantInfoRoleEnum.TeamLeader,
  );

  useEffect(() => {
    if (!isNormalAgent && !!getTeamIds(userDetail!).length) {
      const teams = getTeamIds(userDetail!);
      teams.forEach((id) => fetchTeamMembers(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTeamMembers]);

  const agentIds = useMemo(() => {
    const coAgent =
      transactionBuilder?.agentsInfo?.coAgents
        // @ts-ignore
        ?.map((p) => p.agentId!)
        .filter((p) => !!p) || [];

    const ownerAgent =
      transactionBuilder?.agentsInfo?.ownerAgent
        ?.map((p) => p.agentId!)
        .filter((p) => !!p) || [];

    return [...ownerAgent, ...coAgent];
  }, [
    transactionBuilder?.agentsInfo?.coAgents,
    transactionBuilder?.agentsInfo?.ownerAgent,
  ]);

  useAgentsInfo(agentIds);

  const handleOwnerStep = async () => {
    const isValid = await trigger();
    if (isValid) {
      const offices = getOfficesInPropertyState(
        userDetail?.offices!,
        transactionBuilder?.address?.state!,
      );

      const selectedOfficeId =
        offices?.length === 1 ? offices[0]?.value : officeId;

      const selectedOffice = userDetail?.offices?.find(
        (office) => office?.id === selectedOfficeId,
      );

      if (!offices.length) {
        dispatch(
          showErrorToast(
            'Unable to create this listing.',
            `You are not a part of the office that belongs to your property address in ${capitalizeEnum(
              transactionBuilder?.address?.state!,
            )}`,
          ),
        );
      } else if (
        isLeaseTransaction(transactionBuilder?.dealType!) &&
        !selectedOffice?.allowsLease
      ) {
        dispatch(
          showErrorToast(
            'Unable to create this listing.',
            'This office does not allow lease type listings',
          ),
        );
      } else {
        setLoading(true);

        const agentInfoRequest: TransactionOwnerAgentInfoRequest = {
          ownerAgent: {
            agentId: transactionOwnerName.value,
            role: AgentParticipantInfoRoleEnum.Seller,
          },
          officeId: selectedOfficeId,
          teamId: selectedTeamId?.value,
        };

        const res = await dispatch(
          saveOwnerInfos(transactionBuilderId!, agentInfoRequest),
        );

        setLoading(false);

        if (res) {
          onNext();
        }
      }
    }
  };

  const Country =
    userDetail?.accountCountry === AgentResponseAccountCountryEnum.Canada
      ? 'CANADA'
      : 'UNITED_STATES';
  useEffect(() => {
    const fetchTeamLeaders = async () => {
      setLoading(true);
      const offices = getOfficesInPropertyState(
        userDetail?.offices!,
        transactionBuilder?.address?.state!,
      );
      const agentInfoRequest: TransactionOwnerAgentInfoRequest = {
        ownerAgent: {
          agentId: transactionOwnerName.value,
          role: AgentParticipantInfoRoleEnum.Seller,
        },
        officeId: offices.length === 1 ? offices[0]?.value : officeId,
      };

      if (offices.length === 1 || officeId.length > 0) {
        await dispatch(saveOwnerInfos(transactionBuilderId!, agentInfoRequest));
      }
      setLoading(false);
    };
    fetchTeamLeaders();
  }, [
    dispatch,
    officeId,
    transactionBuilder?.address?.state,
    transactionBuilderId,
    transactionOwnerName?.value,
    userDetail?.offices,
  ]);

  const fetchTeamLeaders = useCallback(
    async (teamId: string): Promise<void> => {
      try {
        const {
          data: { agents },
        } = await new TeamControllerApi(getYentaConfiguration()).getTeamById(
          teamId,
        );

        const teamLeaders = agents?.filter((p) =>
          p.roles?.find((role) => role === TeamMemberResponseRolesEnum.Leader),
        );

        setTeamLeadersList(teamLeaders);
      } catch (e) {
        ErrorService.notify('Unable to fetch team by id', e, {
          team: { id: teamId },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (selectedTeamId?.value) fetchTeamLeaders(selectedTeamId?.value!);
  }, [selectedTeamId, fetchTeamLeaders]);

  useEffect(() => {
    if (showTeams?.length === 1) {
      setValue('teamId', {
        label: showTeams[0].label,
        value: showTeams[0].value,
      });
    }
  }, [showTeams, setValue]);

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <p className='text-xl font-primary-medium mb-4'>Listing Owner</p>
        <div className='mt-5'>
          <ZenControlledAsyncSelectInput<
            ZenCreateListingFormState,
            'transactionOwnerName'
          >
            control={control}
            name='transactionOwnerName'
            placeholder='Search'
            label='Agent (owner)'
            rules={{
              required: 'Required',
            }}
            isRequired
            shouldUnregister={false}
            fetchData={async (search, page) => {
              if (page === 0 && !search) {
                if (!isNormalAgent && !!getTeamIds(userDetail!).length) {
                  return [...members];
                }
              } else if (page === 0 && search) {
                const filterOptions = members?.filter(
                  (member) =>
                    member?.label
                      ?.toLowerCase()
                      .indexOf(search.toLowerCase()) !== -1,
                );

                return [...filterOptions];
              }
              return [];
            }}
            filterOption={(option) =>
              !transactionBuilder?.agentsInfo?.coAgents
                ?.map((agent) => agent.agentId)
                .includes(option.value)
            }
            readOnly={
              !userDetail?.flexTeamMemberships?.length ||
              !getTeamIds(userDetail).length
            }
          />
        </div>
        <div>
          {!!userDetail?.offices?.length &&
            getOfficesInPropertyState(
              userDetail?.offices,
              transactionBuilder?.address?.state!,
            ).length > 1 && (
              <div className='mt-5'>
                <ZenControlledHTMLSelectInput<
                  ZenCreateListingFormState,
                  'officeId'
                >
                  control={control}
                  label='Offices'
                  name='officeId'
                  placeholder='Select offices'
                  options={[
                    {
                      label: 'Select Office',
                      value: '',
                      disabled: true,
                    },
                    ...getOfficesInPropertyState(
                      userDetail?.offices,
                      transactionBuilder?.address?.state!,
                    ),
                  ]}
                  rules={{ required: 'Required' }}
                  isRequired
                  shouldUnregister={false}
                />
              </div>
            )}
          <div className='mt-5'>
            {!showTeams.length ? (
              <>
                <p className='text-xl font-zen-title font-medium text-zen-dark-9'>
                  Team
                </p>
                <div className='border-b border-zen-dark-4 py-5'>
                  <p className='flex justify-center text-zen-dark-6 pt-2'>
                    You are not a part of any team.
                  </p>
                </div>
              </>
            ) : (
              <div>
                <ZenControlledSelectInput<ZenCreateListingFormState, 'teamId'>
                  options={showTeams!}
                  name='teamId'
                  label='Team'
                  isRequired
                  rules={{ required: 'Required' }}
                  control={control}
                  placeholder='Select Team'
                  shouldUnregister={false}
                  disabled={showTeams?.length === 1}
                />
              </div>
            )}
          </div>
          <div className='mt-12'>
            <p className='text-xl font-zen-title font-medium text-zen-dark-9'>
              Team Leader(s)
            </p>
          </div>
          {teamLeadersList?.length === 0 && (
            <div className='border-b border-zen-dark-4 py-5'>
              <p className='flex justify-center text-zen-dark-6 pt-2'>
                There are no team leader(s) to display
              </p>
            </div>
          )}
          <div className='py-5'>
            {teamLeadersList?.map((item) => {
              return (
                <div
                  className='py-2 flex flex-row justify-between items-center border-b border-zen-dark-4'
                  key={item.id}
                >
                  <ZenUserPill
                    name={`${item?.agent?.firstName} ${item?.agent?.lastName}`}
                    imageUrl={
                      item?.agent?.avatar !== 'null'
                        ? item?.agent?.avatar
                        : undefined
                    }
                    backgroundVariant='no-background'
                    size='xs'
                  />
                </div>
              );
            })}
          </div>
          <div className='mt-12'>
            <p>REAL Co-agents</p>
          </div>
          <div className='mt-4 flex flex-row items-center'>
            <ZenControlledAsyncCustomSelectInput<
              ZenCreateListingFormState,
              'realCoAgent'
            >
              control={control}
              onClick={addAgent}
              name='realCoAgent'
              placeholder='Search'
              label='Search Agent'
              fetchData={async (search, page) => {
                try {
                  const searchResponse = await searchActiveAgents(
                    page,
                    [Country],
                    50,
                    search,
                    undefined,
                  );
                  const options: AsyncSelectOptionReactElement[] = searchResponse.data?.map(
                    (resp) => ({
                      value: `${resp.id}`,
                      label: (
                        <AvatarLabelComponent
                          avatar={resp?.avatar!}
                          firstName={resp?.firstName!}
                          lastName={resp?.lastName!}
                          emailAddress={resp?.emailAddress!}
                          administrativeAreas={resp?.administrativeAreas?.map(
                            (e) => {
                              if (!e.stateOrProvince) {
                                return 'N/A';
                              }
                              return STATE_OR_PROVINCE_ABBREVIATIONS[
                                e.stateOrProvince
                              ];
                            },
                          )}
                        />
                      ),
                    }),
                  );

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search for registered agents in add participants',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                        country: userDetail?.accountCountry!,
                      },
                    },
                  );
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an agent. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              shouldUnregister={false}
              filterOption={(option) =>
                ![
                  transactionOwnerName.value,
                  ...transactionBuilder?.agentsInfo?.coAgents?.map(
                    (agent) => agent.agentId,
                  )!,
                ].includes(option.value.split(' ')[0])
              }
            />
          </div>

          {coAgents?.length === 0 && (
            <div className='border-b border-gray-300 py-5'>
              <p className='flex justify-center text-gray-500 pt-2'>
                No agents have been added yet.
              </p>
            </div>
          )}
          <div className='py-5'>
            {coAgents?.map((item) => {
              return (
                <div
                  className='py-2 pl-2 text-gray-700  justify-between flex flex-row items-center border-b'
                  key={item.id}
                >
                  <div className='flex flex-row items-center space-x-2'>
                    <Avatar
                      name={`${agentById[item?.agentId]?.firstName} ${
                        agentById[item?.agentId]?.lastName
                      }`}
                      size='xs'
                      imageUrl={
                        agentById[item?.agentId]?.avatar !== 'null'
                          ? agentById[item?.agentId]?.avatar
                          : undefined
                      }
                    />
                    <p>{`${agentById[item?.agentId]?.firstName} ${
                      agentById[item?.agentId]?.lastName
                    }`}</p>
                  </div>

                  <div className='cursor-pointer'>
                    <FontAwesomeIcon
                      icon={faTrash}
                      title='delete'
                      className='text-gray-400 hover:text-gray-900'
                      size='sm'
                      onClick={async () =>
                        await dispatch(
                          deleteCoAgentInfo(
                            transactionBuilderId!,
                            item.agentId!,
                          ),
                        )
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-gray-outline'
              type='button'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              type='submit'
              variant='primary'
              isDisabled={loading}
              label='Next'
              onClick={handleOwnerStep}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withCreateListingProgress(ZenListingOwnerStep);
