import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateInstantPaymentRequest,
  InstantPaymentControllerApi,
  InstantPaymentDetailResponse,
  InstantPaymentEligibleItemsResponse,
  InstantPaymentItemRequest,
  InstantPaymentsSummary,
} from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import {
  AppDispatch,
  AppThunk,
  AsyncResponse,
  ErrorCode,
  InstantPaymentState,
} from '../types';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { performAsyncRequest } from './utils/SliceUtil';

export const initialState: InstantPaymentState = {
  instantPaymentsSummaryResponse: {
    loading: false,
    name: 'instantPaymentsSummary',
  },
  feeCalculation: undefined,
  isFeeCalculationLoading: false,
  feeCalculationError: null,
  instantPayment: undefined,
  instantPaymentLoading: false,
  instantPaymentErrorCode: null,
};

const InstantPaymentSlice = createSlice({
  name: 'instantPayment',
  initialState,
  reducers: {
    saveInstantPaymentsSummaryResponse(
      state,
      action: PayloadAction<AsyncResponse<InstantPaymentsSummary>>,
    ) {
      state.instantPaymentsSummaryResponse = action.payload;
    },
    saveFeeCalculations(
      state,
      action: PayloadAction<InstantPaymentEligibleItemsResponse>,
    ) {
      state.feeCalculation = action.payload;
    },
    setFeeCalculationLoading(state, action: PayloadAction<boolean>) {
      state.isFeeCalculationLoading = action.payload;
    },
    errorCalculatingFees(state, action: PayloadAction<ErrorCode | null>) {
      state.feeCalculationError = action.payload;
    },
    setInstantPayment(
      state,
      action: PayloadAction<InstantPaymentDetailResponse | undefined>,
    ) {
      state.instantPayment = action.payload;
    },
    errorFetchingInstantPayments(
      state,
      action: PayloadAction<ErrorCode | null>,
    ) {
      state.instantPaymentErrorCode = action.payload;
    },
    setInstantPaymentLoading(state, action: PayloadAction<boolean>) {
      state.instantPaymentLoading = action.payload;
    },
  },
});

export const {
  saveInstantPaymentsSummaryResponse,
  saveFeeCalculations,
  setFeeCalculationLoading,
  errorCalculatingFees,
  setInstantPayment,
  setInstantPaymentLoading,
  errorFetchingInstantPayments,
} = InstantPaymentSlice.actions;

export const fetchInstantPaymentsSummary = (
  id: string,
  loading: boolean = true,
): AppThunk => async (dispatch) => {
  if (loading) {
    // dispatch(setInstantPaymentsSummaryLoading(true));
  }
  const fetch = () =>
    new InstantPaymentControllerApi(
      getArrakisConfiguration(),
    ).getInstantPaymentSummary(id);
  performAsyncRequest(
    dispatch as AppDispatch,
    'instantPaymentsSummary',
    saveInstantPaymentsSummaryResponse,
    fetch,
    { skipAuthDatadog: true },
  );
};

export const fetchFeeCalculation = (
  agentId: string,
  request: InstantPaymentItemRequest,
): AppThunk => async (dispatch) => {
  dispatch(setFeeCalculationLoading(true));
  dispatch(errorCalculatingFees(null));
  try {
    const { data } = await new InstantPaymentControllerApi(
      await getArrakisConfiguration(),
    ).getEligiblePaymentItems(agentId, request);
    dispatch(saveFeeCalculations(data));
  } catch (e) {
    dispatch(errorCalculatingFees(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had trouble fetching the instant payment details fees. Please try again in a few moments.',
      ),
    );
    ErrorService.notify('unable to fetch the instant payment details fees', e, {
      instantPayment: { agentId: agentId, request },
    });
  } finally {
    dispatch(setFeeCalculationLoading(false));
  }
};

export const addInstantPayment = (
  agentId: string,
  request: CreateInstantPaymentRequest,
): AppThunk => async (dispatch) => {
  try {
    await new InstantPaymentControllerApi(
      await getArrakisConfiguration(),
    ).createInstantPayment(agentId, request);
    dispatch(showSuccessToast('Instant payment created successfully'));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had trouble creating the instant payment. Please try again in a few moments.',
      ),
    );
    ErrorService.notify('unable to create the instant payment details', e, {
      instantPayment: { agentId: agentId, request },
    });
  }
};

export const fetchInstantPayment = (
  instantPaymentId: string,
): AppThunk => async (dispatch) => {
  dispatch(setInstantPaymentLoading(true));
  dispatch(errorFetchingInstantPayments(null));

  try {
    const { data } = await new InstantPaymentControllerApi(
      await getArrakisConfiguration(),
    ).getInstantPayment(instantPaymentId);

    dispatch(setInstantPayment(data));
  } catch (e) {
    dispatch(errorFetchingInstantPayments(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had trouble fetching that instant payment. Please try again in a few moments.',
      ),
    );
    ErrorService.notify('unable to fetch instant payment detail', e, {
      instantPayment: { id: instantPaymentId },
    });
  } finally {
    dispatch(setInstantPaymentLoading(false));
  }
};

export default InstantPaymentSlice.reducer;
