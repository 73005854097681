import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import AgentStack from './AgentStack';

interface ReferralAgentStackProps {
  ids?: string[];
}

const ReferralAgentStack: React.FC<ReferralAgentStackProps> = ({ ids }) => {
  const {
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  const agents = (ids || [])?.map((id) => {
    const agentName = !!agentById[id]
      ? `${agentById[id]?.firstName} ${agentById[id]?.lastName}`
      : 'Agent Name';

    return {
      avatar: agentById[id]?.avatar ?? null,
      name: agentName,
      id: id,
    };
  });

  return <AgentStack avatarList={agents} />;
};

export default ReferralAgentStack;
