export interface CardProps {
  title?: string;
  bottomActionText?: string;
  onBottomActionClick?(): void;
  RightComponent?: React.ReactElement;
  hideBottomAction?: boolean;
}

const Card: React.FC<CardProps> = ({
  bottomActionText,
  onBottomActionClick,
  title,
  RightComponent,
  hideBottomAction = false,
  children,
}) => {
  return (
    <div className='border border-gray-200 rounded'>
      {title && (
        <div className='flex justify-between items-center pt-4 px-4'>
          <span className='text-xl text-gray-800 font-primary-medium'>
            {title}
          </span>
          {RightComponent}
        </div>
      )}
      <div>{children}</div>
      {!hideBottomAction && bottomActionText && (
        <button
          onClick={onBottomActionClick}
          className='text-primary font-primary-medium focus:outline-none hover:opacity-70 px-4 py-2'
        >
          {bottomActionText}
        </button>
      )}
    </div>
  );
};

export default Card;
