import { useState, useCallback } from 'react';
import { AddressComponentType } from '../types';

interface GeocodeResult {
  formatted_address: string;
  place_id: string;
  address_components: AddressComponentType[];
  // Define other properties you expect in the geocode result based on the Google Maps API documentation
}

interface UseGeocodeAddressReturn {
  isLoading: boolean;
  error: string | null;
  getGeocodeAddress: (address: string) => Promise<GeocodeResult | null>;
}

/**
 * A hook to geocode an address using the Google Maps Geocoding API.
 *
 * @param {boolean} isApiLoaded Boolean indicating if the Google Maps API is loaded.
 * @returns An object containing the geocoded address components, loading state, and any error.
 */
export const useGeocodeAddress = (
  isApiLoaded: boolean,
): UseGeocodeAddressReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getGeocodeAddress = useCallback(
    async (address: string): Promise<GeocodeResult | null> => {
      if (!address || !isApiLoaded) {
        setError('API not loaded or no address provided');
        return null;
      }

      setIsLoading(true);
      setError(null);

      const geocoder = new google.maps.Geocoder();

      try {
        const response = await new Promise<GeocodeResult | null>(
          (resolve, reject) => {
            geocoder.geocode({ address }, (results, status) => {
              if (status === 'OK' && results) {
                resolve(results[0] as GeocodeResult); // Cast the result to GeocodeResult type
              } else {
                reject('Geocoding failed');
              }
            });
          },
        );

        return response;
      } catch (err) {
        setError('Failed to geocode address');
        console.error('Geocoding error:', err);
        return null;
      } finally {
        setIsLoading(false);
      }
    },
    [isApiLoaded],
  );

  return { isLoading, error, getGeocodeAddress };
};
