import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  EarningIcon,
  EarningType,
  LearnMoreBackground,
  LearnMoreSmallBackground,
  PrimaryColor,
  TertiaryColor,
} from '../WealthManagementTypes';
import { acceleratorCardTitleToEarningTypeMap } from '../WealthManagementUtils';

interface WealthPlanAcceleratorCardProps {
  type: EarningType;
}

const WealthPlanAcceleratorCard: React.FC<WealthPlanAcceleratorCardProps> = ({
  type,
}) => {
  return (
    <div
      style={{
        backgroundColor: LearnMoreBackground[type],
      }}
      className='relative overflow-hidden cursor-pointer'
    >
      <div className='font-zen-body text-base font-semibold py-[30px] px-6'>
        <div className='text-zen-dark-9'>Learn how to hit your goals!</div>
        <div className='text-primary-blue flex items-center mt-3'>
          <span>{acceleratorCardTitleToEarningTypeMap[type]}</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
      <div className='absolute right-0 top-0 w-5/12 h-full flex items-center'>
        <div
          className='relative h-[264px] w-[264px] shrink-0 rounded-full pl-11 flex items-center'
          style={{
            backgroundColor: TertiaryColor[type],
          }}
        >
          <div
            className='flex items-center h-[264px] w-[264px] shrink-0 rounded-full'
            style={{
              backgroundColor: LearnMoreSmallBackground[type],
            }}
          >
            <FontAwesomeIcon
              style={{
                color: PrimaryColor[type],
              }}
              icon={EarningIcon[type]}
              size='2xl'
              className='pl-[50px]'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WealthPlanAcceleratorCard;
