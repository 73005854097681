import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../routes/AgentWebsiteOnboardingRoute';
import {
  agentCoverSetMap,
  agentWebsiteThemeColors,
  AGENT_ONBOARDING_STEPS,
} from '../../testUtils/AgentWebsiteOnboardingUtils';
import { CustomColorPickerInputSelectOption } from '../CustomColorPickerInput';
import { capitalizeEnum } from '../../utils/StringUtils';
import { AgentWebsiteOnboardingStepEnum } from '../../types';
import { CreateOrUpdateAgentWebsiteRequestThemeColorEnum } from '../../openapi/yenta';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import ControlledColorSelectorInput from '../ControlledColorSelectorInput';
import ControlledImageSelectorInput from '../ControlledImageSelectorInput';
import ApplicationStepProgressHeader from '../onboarding/ApplicationStepProgressHeader';
import AgentWebsiteThemePreview from './AgentWebsiteThemePreview';
import LabelValueDisplay from './LabelValueDisplay';

const AgentWebsiteOnboardingTheme: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ form: { control, watch } }) => {
  const homePageImageItems = Object.keys(agentCoverSetMap).map((key) => ({
    value: key,
    label: capitalizeEnum(key),
  }));

  const colorItems: CustomColorPickerInputSelectOption[] = Object.keys(
    agentWebsiteThemeColors,
  ).map((key) => ({
    value: key,
    label: capitalizeEnum(key),
    color:
      agentWebsiteThemeColors[
        key as CreateOrUpdateAgentWebsiteRequestThemeColorEnum
      ],
  }));

  const themeColor = watch('themeColor');
  const photoSet = watch('photoSet');

  return (
    <div className='h-full w-full flex flex-col items-center max-w-6xl mx-auto'>
      <ApplicationStepProgressHeader
        steps={AGENT_ONBOARDING_STEPS}
        inProgressIndex={AgentWebsiteOnboardingStepEnum.THEME}
      />

      <div className='mt-5'>
        <ControlledImageSelectorInput<
          AgentWebsiteOnboardingFormData,
          'photoSet'
        >
          name='photoSet'
          label='Select home page image'
          options={homePageImageItems}
          control={control}
          shouldUnregister={false}
        />
      </div>
      <div className='w-full mt-5'>
        <ControlledColorSelectorInput<
          AgentWebsiteOnboardingFormData,
          'themeColor'
        >
          name='themeColor'
          label='Select theme color'
          control={control}
          shouldUnregister={false}
          options={colorItems}
        />
      </div>
      <div className='w-full my-5'>
        <p className='font-primary-medium mb-2'>Preview</p>
        <div className='grid lg:grid-cols-2 grid-cols-1 gap-5'>
          <AgentWebsiteThemePreview
            photoSet={photoSet!}
            themeColor={themeColor!}
          />
          <div>
            <LabelValueDisplay
              label='Theme Image'
              value={capitalizeEnum(photoSet!)}
            />
            <div className='mt-2'>
              <LabelValueDisplay
                label='Theme color'
                value={capitalizeEnum(themeColor!)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingTheme;
