import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import ZenAddMoneyTransfersToClosedTransactionFormSidebarModal from '../../../forms/ZenAddMoneyTransfersToClosedTransactionFormSidebarModal';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface ZenAddMoneyTransfersToClosedTransactionProps {}

const ZenAddMoneyTransfersToClosedTransaction: React.FC<ZenAddMoneyTransfersToClosedTransactionProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <ZenResourceLinkCard
        title='Fix Transaction Money Transfers'
        onClick={() => setIsOpen(true)}
        icon={light('building-columns')}
      />
      <ZenAddMoneyTransfersToClosedTransactionFormSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenAddMoneyTransfersToClosedTransaction;
