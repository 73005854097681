import ContentLoader from 'react-content-loader';

const DetailPageLoader = () => (
  <ContentLoader
    width='100%'
    viewBox='0 0 400 250'
    backgroundColor='#eaeced'
    foregroundColor='#ffffff'
    title='Loading...'
  >
    <rect x='10' y='5' rx='0' ry='0' width='376' height='43' />
    <rect x='10' y='57' rx='0' ry='0' width='85' height='15' />
    <rect x='11' y='94' rx='0' ry='0' width='141' height='86' />
    <rect x='105' y='154' rx='0' ry='0' width='15' height='1' />
    <rect x='12' y='189' rx='0' ry='0' width='140' height='38' />
    <rect x='165' y='95' rx='0' ry='0' width='223' height='116' />
    <rect x='107' y='57' rx='0' ry='0' width='85' height='15' />
    <rect x='206' y='57' rx='0' ry='0' width='85' height='15' />
    <rect x='301' y='57' rx='0' ry='0' width='85' height='15' />
    <rect x='11' y='84' rx='0' ry='0' width='39' height='5' />
    <rect x='164' y='85' rx='0' ry='0' width='39' height='5' />
  </ContentLoader>
);

export default DetailPageLoader;
