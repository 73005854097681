import {
  AgentHasCapStatus,
  AgentIdOneOf,
  AgentJoinedBetween,
  AgentMemberOfTeam,
  AgentStateOneOf,
  AllConditionsMet,
  AnyConditionMet,
  NoDisplayCondition,
  PlatformOneOf,
  UserRoleOneOf,
} from '../../../openapi/mercury';
import AgentJoinedBetweenTray from './AgentJoinedBetweenTray';
import AnnouncementAvatarStack from './AnnouncementAvatarStack';
import AnnouncementTeamStack from './AnnouncementTeamStack';

const TextTray: React.FC<{ text: string }> = ({ text }) => (
  <div className='w-max flex items-center text-base'>
    <span className='font-zen-body font-normal text-sm text-zen-dark-9'>
      {text}
    </span>
  </div>
);

interface AnnouncementRecipientAgentTypeProps {
  agentTypeCondition?:
    | AgentIdOneOf
    | AgentStateOneOf
    | AgentHasCapStatus
    | AgentJoinedBetween
    | AllConditionsMet
    | AnyConditionMet
    | NoDisplayCondition;
}

const AnnouncementRecipientAgentType: React.FC<AnnouncementRecipientAgentTypeProps> = ({
  agentTypeCondition,
}) => {
  if (agentTypeCondition?.$type === 'NoDisplayCondition') {
    return <TextTray text='All ReZen' />;
  } else if (
    agentTypeCondition?.$type === 'AgentHasCapStatus' &&
    (agentTypeCondition as AgentHasCapStatus)?.capped
  ) {
    return <TextTray text='Status: Cap' />;
  } else if (
    agentTypeCondition?.$type === 'AgentHasCapStatus' &&
    !(agentTypeCondition as AgentHasCapStatus)?.capped
  ) {
    return <TextTray text='Status: PreCap' />;
  } else if (agentTypeCondition?.$type === 'AgentJoinedBetween') {
    return (
      <AgentJoinedBetweenTray joinedBetweenCondition={agentTypeCondition} />
    );
  } else if (agentTypeCondition?.$type === 'AgentIdOneOf') {
    return (
      <AnnouncementAvatarStack
        ids={(agentTypeCondition as AgentIdOneOf)?.ids}
      />
    );
  } else if (agentTypeCondition?.$type === 'AgentHasEliteStatus') {
    return <TextTray text='Status: Elite' />;
  } else if (agentTypeCondition?.$type === 'UserRoleOneOf') {
    return (
      <TextTray
        text={`User Role: ${(agentTypeCondition as UserRoleOneOf)?.roles[0]}`}
      />
    );
  } else if (agentTypeCondition?.$type === 'PlatformOneOf') {
    return (
      <TextTray
        text={`Platform: ${(agentTypeCondition as PlatformOneOf)?.platforms?.join(
          ',',
        )}`}
      />
    );
  } else if (agentTypeCondition?.$type === 'AgentMemberOfTeam') {
    return (
      <AnnouncementTeamStack
        ids={(agentTypeCondition as AgentMemberOfTeam)?.teamIds}
      />
    );
  }

  return null;
};

export default AnnouncementRecipientAgentType;
