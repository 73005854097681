import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface ZenCheckListAndJourneyTemplatesProps {}

const ZenCheckListAndJourneyTemplates: React.FC<ZenCheckListAndJourneyTemplatesProps> = () => {
  const history = useHistory();

  return (
    <div>
      <ZenResourceLinkCard
        title='Checklist / Journey Templates'
        onClick={() => history.push('/resources/templates')}
        icon={regular('ballot-check')}
        iconSize='2x'
      />
    </div>
  );
};

export default ZenCheckListAndJourneyTemplates;
