import { DateTime } from 'luxon';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/zen/transaction/calendar-note.svg';
import {
  CreateNoteRequest,
  CreateNoteRequestAllowableRoleEnum,
  CreateNoteRequestEntityTypeEnum,
  NoteControllerApi,
  NoteResponse,
  UpdateNoteRequestAllowableRoleEnum,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { RootState } from '../../../types';
import { getYentaImageUrl } from '../../../utils/ImgUtils';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { isStringEmpty } from '../../../utils/StringUtils';
import ZenNoteItem from '../../Agent/V2/ZenNoteItem';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenButton from '../../Zen/ZenButton';

export interface ZenTransactionNotesContainerProps {
  entityId: string;
  entityType: CreateNoteRequestEntityTypeEnum;
  readOnly?: boolean;
}

interface CalendarIconProps {
  value?: string;
  onClick?(): void;
}

interface FormData {
  comment: string;
  followupDate?: string;
}

const CalendarIconInput = forwardRef<HTMLInputElement, CalendarIconProps>(
  ({ value, onClick }, ref) => (
    <div onClick={onClick} ref={ref} className='cursor-pointer'>
      {value ? (
        <p className='mr-8 mt-1 text-primary-blue'>{value}</p>
      ) : (
        <div className='px-1 space-x-2 text-primary-blue flex flex-row items-center whitespace-nowrap'>
          <CalendarIcon className='text-zen-dark-7 mt-2' />
        </div>
      )}
    </div>
  ),
);

const ZenTransactionNotesContainer: React.FC<ZenTransactionNotesContainerProps> = ({
  entityId,
  entityType,
  readOnly = false,
}) => {
  const dispatch = useDispatch();
  const [notes, setNotes] = useState<NoteResponse[]>([]);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>();
  const { isAdmin } = useSelector((state: RootState) => state.auth);
  const comment = watch('comment');
  const isCommentEmpty = isStringEmpty(comment?.trim());

  const fetchData = useCallback(async () => {
    try {
      const { data } = await new NoteControllerApi(
        getYentaConfiguration(),
      ).getNoteById1(entityType, entityId);

      setNotes(data.notes?.sort((a, b) => b?.createdAt! - a?.createdAt!)!);
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to fetch notes', e);
    }
  }, [entityId, entityType, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSubmit = async (values: FormData) => {
    try {
      const createNoteRequest: CreateNoteRequest = {
        comment: values.comment,
        entityId: entityId,
        followupDate: values.followupDate
          ? DateTime.fromISO(values.followupDate).toMillis()
          : undefined,
        entityType: entityType,
        allowableRole: isAdmin
          ? CreateNoteRequestAllowableRoleEnum.Admin
          : CreateNoteRequestAllowableRoleEnum.Leader,
      };

      const { data } = await new NoteControllerApi(
        getYentaConfiguration(),
      ).createNote(createNoteRequest);

      setNotes([data, ...notes]);
      reset({ comment: '', followupDate: '' });
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notifyIgnoreAuthErrors('Unable to create note', e);
    }
  };

  const updateFollowUpdate = async (note: NoteResponse) => {
    try {
      const { data } = await new NoteControllerApi(
        getYentaConfiguration(),
      ).updateNote(note.id!, {
        followupDate: undefined,
        comment: note.comment!,
        allowableRole: (note.allowableRole! as unknown) as UpdateNoteRequestAllowableRoleEnum,
      });

      setNotes(notes.map((note) => (note.id === data.id ? data : note)));
    } catch (e) {
      showApiErrorModal(e);
      ErrorService.notifyIgnoreAuthErrors('Unable to update follow up date', e);
    }
  };

  return (
    <div className='px-4 py-5'>
      <ul>
        {notes.map((note, index) => {
          return (
            <li key={note.id}>
              <ZenNoteItem
                name={note.user?.fullName!}
                comment={note.comment!}
                followUpDate={{
                  date: note.followupDate!,
                  onClick: () => updateFollowUpdate(note),
                }}
                createdAt={note.createdAt!}
                avatar={getYentaImageUrl(note.user?.avatar)}
                isLastItem={notes.length - 1 === index}
              />
            </li>
          );
        })}
      </ul>
      {!readOnly && (
        <form onSubmit={handleSubmit(onSubmit)} className='mt-10'>
          <ZenControlledTextInput<FormData, 'comment'>
            name='comment'
            placeholder='Add a note here...'
            control={control}
            readOnly={readOnly}
            endAdornment={
              <div className='flex items-center p-1.5 space-x-3'>
                <ZenControlledDatePickerInput
                  customInput={<CalendarIconInput />}
                  noBorder
                  name='followupDate'
                  control={control}
                  datePickerConfig={{
                    isClearable: true,
                    minDate: DateTime.local().plus({ days: 1 }).toJSDate(),
                    popperModifiers: {},
                    popperClassName: 'z-50',
                  }}
                />
                <ZenButton
                  label='Save'
                  type='submit'
                  isSubmitting={isSubmitting}
                  isDisabled={isCommentEmpty || isSubmitting}
                />
              </div>
            }
          />
        </form>
      )}
    </div>
  );
};

export default ZenTransactionNotesContainer;
