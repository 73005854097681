import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faBriefcase, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import {
  AddressResponse,
  DirectoryEntryResponseTypeEnum,
} from '../../openapi/yenta';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';
import { formatPhoneNumber } from '../../utils/StringUtils';
import Hover from '../Hover';
import ZenAvatar from '../Zen/ZenAvatar';

interface ZenDirectoryCardProps {
  /**
   * TODO: Implement stricter type for `role` after we see the API
   */
  address?: AddressResponse;
  avatar?: string | undefined;
  company?: string | undefined;
  emailAddress?: string;
  isRealVerified?: boolean;
  name?: string;
  onClick?: () => void;
  phoneNumber?: string;
  role: string | undefined;
  type: DirectoryEntryResponseTypeEnum;
}

const ZenDirectoryCard: React.FC<ZenDirectoryCardProps> = ({
  address,
  company,
  emailAddress,
  isRealVerified = false,
  name,
  onClick,
  phoneNumber,
  role,
  type,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string, eventData?: Record<string, unknown>) => {
      AnalyticsService.instance().logEvent(event, eventData);
    }, 500),
    [],
  );

  return (
    <div
      className='gap-4 border border-solid border-zen-dark-4 rounded-lg bg-white p-4 min-h-[120px] w-96 hover:cursor-pointer'
      data-testid='directory-card'
      onClick={onClick}
    >
      <div className='flex flex-col gap-2 font-inter'>
        <div className='flex items-center gap-1'>
          {type === DirectoryEntryResponseTypeEnum.Person ? (
            <FontAwesomeIcon icon={faUser} />
          ) : (
            <FontAwesomeIcon icon={faBriefcase} />
          )}
          <div className='text-xs font-medium text-zen-primary-dark'>
            {role}
          </div>
        </div>
        <div className='flex justify-between flex-shrink items-center'>
          <div className='flex items-center space-x-2'>
            <div className='flex items-center space-x-2'>
              <ZenAvatar
                name={name ?? ''}
                colorClassName='bg-zen-blue-1'
                size='sm'
                textOverride='font-semibold text-white'
              />
              <div className='flex flex-shrink flex-wrap max-w-[180px]'>
                <p className='text-base font-semibold text-zen-primary-dark'>
                  {name}
                </p>
              </div>
            </div>
            {isRealVerified && (
              <div>
                <FontAwesomeIcon icon={faBadgeCheck} color='green' />
              </div>
            )}
          </div>
          <div className='flex flex-shrink-0 items-center text-primary-blue space-x-3 mt-1'>
            {emailAddress && (
              <Hover
                hoverComponent={
                  <div className='font-inter text-base text-zen-primary-dark px-2'>
                    {emailAddress}
                  </div>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <FontAwesomeIcon
                  icon={regular('envelope')}
                  title='email'
                  size='lg'
                  onMouseEnter={() =>
                    logAnalyticsEvent(
                      AnalyticsEventEnum.DIRECTORY_HOVER_ON_EMAIL,
                    )
                  }
                />
              </Hover>
            )}
            {phoneNumber && (
              <Hover
                hoverComponent={
                  <div className='font-inter text-base text-zen-primary-dark px-2'>
                    {formatPhoneNumber(phoneNumber)}
                  </div>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <FontAwesomeIcon
                  icon={regular('phone')}
                  title='phone'
                  size='lg'
                  onMouseEnter={() =>
                    logAnalyticsEvent(
                      AnalyticsEventEnum.DIRECTORY_HOVER_ON_PHONE_NUMBER,
                    )
                  }
                />
              </Hover>
            )}
            {address?.oneLine && (
              <Hover
                hoverComponent={
                  <div className='font-inter text-base text-zen-primary-dark px-2'>
                    {address.oneLine}
                  </div>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <FontAwesomeIcon
                  icon={regular('location-dot')}
                  title='location'
                  size='lg'
                  onMouseEnter={() =>
                    logAnalyticsEvent(
                      AnalyticsEventEnum.DIRECTORY_HOVER_ON_ADDRESS,
                    )
                  }
                />
              </Hover>
            )}
          </div>
        </div>
        <div className='flex flex-shrink flex-wrap text-sm text-grey-500'>
          <p>{company}</p>
        </div>
      </div>
    </div>
  );
};

export default ZenDirectoryCard;
