import qs from 'qs';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import HappySupportFeedbackModal from '../components/transactions/HappyFeature/HappySupportFeedbackModal';
import { TableQueryParamState } from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import useQueryParams from '../hooks/useQueryParams';
import { TransactionsRouteUrlParams } from '../types';
import GeminiAgentTransactionOrListingTable from './GeminiAgentTransactionOrListingTable';

interface Props extends RouteComponentProps {}

const GeminiAgentTransactionsRoute: React.FC<Props> = ({
  history,
  location,
  match,
}) => {
  const query = useQueryParams<TableQueryParamState<any>>();
  const params = match?.params as TransactionsRouteUrlParams;

  const PageLayoutWrapper =
    params?.lifecycle === 'draft' ? ZenPageLayout : ZenPageLayoutWithSearch;

  return (
    <PageLayoutWrapper
      path={[
        { title: 'Home', url: '/' },
        {
          title: 'My Transactions',
          url: '/transactions',
        },
      ]}
      search={query?.search}
      onSearchChange={(search: string | undefined) =>
        history.replace(
          `${location?.pathname}?${qs.stringify({ ...query, search })}`,
        )
      }
    >
      <GeminiAgentTransactionOrListingTable
        lifecycle={params?.lifecycle}
        type={params?.type}
      />
      <HappySupportFeedbackModal />
    </PageLayoutWrapper>
  );
};

export default GeminiAgentTransactionsRoute;
