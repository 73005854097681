import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import ZenDeleteEscrowDepositOrInstallmentSidebarModal from '../../../forms/ZenDeleteEscrowOrInstallmentSidebarModal';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface ZenDeleteEscrowDepositOrInstallmentProps {}

const ZenDeleteEscrowDepositOrInstallment: React.FC<ZenDeleteEscrowDepositOrInstallmentProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <ZenResourceLinkCard
        title='Delete Escrow Deposit / Installment'
        icon={light('building-columns')}
        onClick={() => setIsOpen(true)}
      />
      <ZenDeleteEscrowDepositOrInstallmentSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenDeleteEscrowDepositOrInstallment;
