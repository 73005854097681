import React, { useMemo, useState } from 'react';
import {
  faCircleDollar,
  faEnvelope,
  faList,
  faLocation,
  faMoneyCheck,
  faPhone,
  faStar,
  faUser,
  faMessage,
  faGavel,
  faSquareDollar,
} from '@fortawesome/pro-light-svg-icons';
import ZenSidebarModal from '../../../components/Zen/ZenSidebarModal';
import GeminiButton from '../../../components/Gemini/GeminiButton';
import { ReferralCentralReferralDto } from '../../../openapi/arrakis';
import ReferralSidebarTabs, {
  SidebarTab,
} from '../components/ReferralSidebarTabs';
import GeminiPill from '../../../components/Gemini/GeminiPill';
import GeminiCopyToClipboard from '../../../components/Gemini/GeminiCopyToClipborad';
import ReferralAgentDetailItem from '../components/ReferralAgentDetailItem';
import RecentAgentTransactions from '../components/RecentAgentTransactions';
import { AddressData } from '../components/Map';
import ReferralAgentProfile from '../components/ReferralAgentProfile';
import ReferralOverviewCard from '../ReferralOverviewCard';

interface ReferralDetailSelectAgentSidebarProps {
  referralDetails?: ReferralCentralReferralDto;
  onClose: () => void;
  isOpen: boolean;
}

const ReferralDetailSelectAgentSidebar: React.FC<ReferralDetailSelectAgentSidebarProps> = ({
  onClose,
  isOpen,
}) => {
  const dummyAddressData: AddressData[] = useMemo(
    () => [
      {
        name: '1600 Amphitheatre Parkway, Mountain View, CA',
        circle: {
          radius: 5000,
          options: {
            strokeColor: '#629BF8',
            fillColor: '#629BF8',
          },
        },
        latitude: 37.422,
        longitude: -122.084,
      },
    ],
    [],
  );

  const OverviewTab = useMemo(() => {
    return (
      <div className='space-y-8 pt-5'>
        <div className='p-6 bg-white rounded-lg border border-grey-200 flex-col justify-center items-center gap-6 flex'>
          <ReferralAgentProfile />
          <div className='flex-col justify-start items-start gap-2 flex w-full'>
            <div className='text-grey-600 text-sm font-medium font-inter leading-tight'>
              Past 12 months overview
            </div>
            <div className='grid grid-cols-3 grid-flow-row gap-4 w-full'>
              <ReferralOverviewCard
                subtitle='TOTAL SALES'
                icon={faSquareDollar}
                title='9.2m'
              />
              <ReferralOverviewCard
                subtitle='LISTINGS CREATED'
                icon={faList}
                title='26'
              />
              <ReferralOverviewCard
                subtitle='PROPERTY SOLD'
                icon={faGavel}
                title='12'
              />
            </div>
          </div>
        </div>

        <div className='p-4 bg-white rounded-lg border border-grey-200 flex-col justify-start items-start flex'>
          <div className='justify-start items-center gap-1 inline-flex'>
            <div className='text-primary-dark text-base font-medium font-inter leading-snug pb-2'>
              Agent Details
            </div>
          </div>
          <div className='divide-y'>
            <ReferralAgentDetailItem
              icon={faList}
              label='Most Recent Listing Date'
              data='May 27, 2024'
            />
            <ReferralAgentDetailItem
              icon={faCircleDollar}
              label='Price Range'
              data='$50k - $120k'
            />
            <ReferralAgentDetailItem
              icon={faMoneyCheck}
              label='Average Price'
              data='$88k'
            />
            <ReferralAgentDetailItem
              icon={faLocation}
              label='Service Area'
              content={
                <div className='flex flex-wrap justify-end gap-2'>
                  <GeminiPill
                    label='Gainesville, Florida'
                    containerClassNames='text-primary-navy text-sm font-medium bg-rezen-light-blue-100'
                  />
                  <GeminiPill
                    label='Miami, Florida'
                    containerClassNames='text-primary-navy text-sm font-medium bg-rezen-light-blue-100'
                  />
                  <GeminiPill
                    label='Miami, Florida'
                    containerClassNames='text-primary-navy text-sm font-medium bg-rezen-light-blue-100'
                  />
                </div>
              }
            />
            <ReferralAgentDetailItem
              icon={faStar}
              label='Specialties'
              data='Luxury, Commercial'
            />
            <ReferralAgentDetailItem
              icon={faPhone}
              label='Contact Number'
              content={
                <div className='flex flex-wrap justify-end'>
                  <GeminiCopyToClipboard
                    label='(786) 785-0000'
                    value='7867850000'
                    variant='noBackground'
                  />
                </div>
              }
            />
            <ReferralAgentDetailItem
              icon={faEnvelope}
              label='Email Address'
              content={
                <div className='flex flex-wrap justify-end'>
                  <GeminiCopyToClipboard
                    label='Jamestyler@therealbrokerage.com'
                    value='Jamestyler@therealbrokerage.com'
                    variant='noBackground'
                  />
                </div>
              }
            />
            <ReferralAgentDetailItem
              icon={faUser}
              label='Bio Information'
              data='Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum '
            />
          </div>
        </div>
        <RecentAgentTransactions address={dummyAddressData} />
        <div className='pt-20' />
      </div>
    );
  }, [dummyAddressData]);

  const CommentTab = useMemo(() => {
    return (
      <div className='w-full mx-auto px-4 md:pt-6 relative'>
        {/* <ZenCommentSection
        fetchComments={(fetchComments)}
        onDelete={onDeleteComment}
        onEdit={onEditComment}
        onSubmit={handleSubmit}
        getMentions={getMentions}
        htmlElementScrollableParent={document?.getElementById('main-page')!}
        containerType={RezenObjectTypeEnum.Referral}
        containerId=''
        sseURL={`${SSE_URL}?containerType=${RezenObjectTypeEnum.Transaction}&containerId=${transactionDetail?.id}`}
      /> */}
        Comments coming soon
      </div>
    );
  }, []);

  const tabs: SidebarTab[] = [
    {
      tabName: 'Overview',
      tabContent: OverviewTab,
    },
    {
      tabName: 'Messages',
      tabContent: CommentTab,
    },
  ];

  const [selectedTab, setSelectedTab] = useState('Overview');

  return (
    <ZenSidebarModal title='Referral Details' isOpen={isOpen} onClose={onClose}>
      <div className='flex flex-col gap-4 w-full pt-5'>
        <ReferralSidebarTabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <div className='p-5 bg-white border-t border-gray-200 flex flex-row justify-between w-full gap-4 absolute bottom-0'>
          <GeminiButton
            type='button'
            onClick={onClose}
            label='Back'
            size='md'
            variant='primary-link'
          />
          <div className='gap-3 flex flex-row items-center'>
            <GeminiButton
              type='button'
              onClick={() => setSelectedTab('Messages')}
              label='Message'
              leftIcon={faMessage}
              size='md'
              variant='primary-outline'
            />

            <GeminiButton
              type='button'
              onClick={onClose}
              label='Select Agent'
              size='md'
              variant='primary'
            />
          </div>
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default ReferralDetailSelectAgentSidebar;
