import { RumErrorEvent, RumEvent } from '@datadog/browser-rum';
import { DatadogError } from '../errors/DatadogError';
import ForceReportingError from '../errors/ForceReportingError';
import Logger from './Logger';

const IGNORE_ERROR_MESSAGES = [
  'Network Error',
  'Request aborted',
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
  'timeout of 0ms exceeded',
  'Cannot redefine property: googletag',
  'intervention: Ignored attempt to cancel a touchmove event with cancelable=false',
];

export const isDatadogErrorEventValid = (event: RumEvent): boolean => {
  const error = event.error as RumErrorEvent['error'];
  const isForceReportingErr =
    error?.type === DatadogError.FORCE_REPORTING_ERROR;
  if (isForceReportingErr) {
    Logger.debug('Error is set to force reporting. Reporting to Datadog.');
    return true;
  }

  try {
    const isLaunchNotesError = error?.stack?.includes('embed.launchnotes.io');

    if (isLaunchNotesError) {
      Logger.debug('Error is from LaunchNotes. Not reporting to Datadog.');
      return false;
    }

    const isWebkitMaskedUrl = error?.stack?.includes('webkit-masked-url');

    if (isWebkitMaskedUrl) {
      Logger.debug(
        'Error is from webkit-masked-url. Not reporting to Datadog.',
      );
      return false;
    }
  } catch (e) {
    Logger.warn("Couldn't check if error is from LaunchNotes. Continuing...");
  }

  const message = error?.message ?? '';

  const includesIgnorableMessage = IGNORE_ERROR_MESSAGES.find((ignoreMessage) =>
    message.includes(ignoreMessage),
  );

  return !includesIgnorableMessage;
};

export const forceReporting = (error: Error): ForceReportingError => {
  const forceReportingError: ForceReportingError = error as ForceReportingError;
  forceReportingError.forceReporting = true;
  return forceReportingError;
};
