import { JobExecutionResponseJobExecutionStatusEnum } from '../../../../openapi/yenta';
import { EnumMap } from '../../../../types';
import { safeEnumMapGet } from '../../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import ZenStatusPill, { PillVariantType } from '../../ZenStatusPill';

interface ZenCronJobsStatusCellProps {
  status: JobExecutionResponseJobExecutionStatusEnum;
}

const statusToVariant: EnumMap<
  JobExecutionResponseJobExecutionStatusEnum,
  PillVariantType
> = {
  SUCCESS: 'success',
  FAIL: 'danger',
  RUNNING: 'primary',
  CREATED: 'warning',
};

const ZenCronJobsStatusCell: React.FC<ZenCronJobsStatusCellProps> = ({
  status,
}) => {
  return (
    <ZenStatusPill
      text={capitalizeEnum(status)}
      variant={safeEnumMapGet(statusToVariant, status, 'default')}
    />
  );
};

export default ZenCronJobsStatusCell;
