import React from 'react';
import { cn } from '../../utils/classUtils';

interface TEN99FormStepNumberInputFieldProps {
  label: string;
  step: number;
  value?: string | string[];
  isCheck?: boolean;
  isCurrencyInput?: boolean;
}

const TEN99FormStepNumberInputField: React.FC<TEN99FormStepNumberInputFieldProps> = ({
  label,
  step,
  value,
  isCheck = false,
  isCurrencyInput = true,
}) => {
  return (
    <div>
      <div className='flex flex-row justify-between items-center'>
        <div className='flex flex-row items-start px-2 w-11/12'>
          <span className='font-primary-medium font-bold text-sm text-dark pr-2'>
            {step}
          </span>
          <p className='pr-2'>{label}</p>
        </div>
        {isCheck && (
          <div className='w-1/12 flex justify-center items-center'>
            <div className='w-4 h-4 border-2 border-gray-800' />
          </div>
        )}
      </div>
      {!isCheck && Array.isArray(value) ? (
        value.map((v, index) => (
          <p
            key={v}
            className={cn(
              'bg-white h-6 font-primary-medium text-base text-dark',
              index === value.length - 1
                ? 'border-none'
                : 'border-dotted border-b-2 border-gray-600',
            )}
          >
            {isCurrencyInput && '$'} {v}
          </p>
        ))
      ) : (
        <p className='bg-white font-primary-medium text-base text-dark h-6'>
          {isCurrencyInput && '$'} {value}
        </p>
      )}
    </div>
  );
};

export default TEN99FormStepNumberInputField;
