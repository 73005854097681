import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MsDynamicOnboardingTaxFormW8Ben from '../components/MSDynamicsOnboarding/MsDynamicOnboardingTaxFormW8Ben';
import MsDynamicOnboardingTaxFormW9 from '../components/MSDynamicsOnboarding/MsDynamicOnboardingTaxFormW9';
import MsDynamicOnboardingTaxInfo from '../components/MSDynamicsOnboarding/MsDynamicOnboardingTaxInfo';
import MSDynamicsBankInformation from '../components/MSDynamicsOnboarding/MSDynamicsBankInformation';
import MSDynamicsCanadaTaxes from '../components/MSDynamicsOnboarding/MSDynamicsCanadaTaxes';
import MSDynamicsContactInformation from '../components/MSDynamicsOnboarding/MSDynamicsContactInformation';
import MSDynamicsFormW8eci from '../components/MSDynamicsOnboarding/MSDynamicsFormW8eci';
import MSDynamicsOnboardingIntro from '../components/MSDynamicsOnboarding/MSDynamicsOnboardingIntro';
import ResourceContainer from '../components/ResourceContainer';
import StepByStepContainer, {
  StepConfig,
} from '../components/StepByStep/StepByStepContainer';
import ZenAgentOnboardingLayout from '../components/Zen/ZenAgentOnboardingLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import { AddressStateEnum } from '../openapi/arrakis';
import {
  AddressCountryEnum,
  AddressResponseStateOrProvinceEnum,
  AgentResponseAccountCountryEnum,
  CanadaRoutingNumber,
  CreatePaymentMethodPurposeEnum,
  CreatePaymentMethodTypeEnum,
  CreateTaxFormTypeEnum,
  CreateTaxFormW8BENCountryOfIncorporationEnum,
  CreateTaxFormW8ECICountryOfIncorporationEnum,
  CreateTaxFormW8ECITaxEntityTypeEnum,
  CreateTaxFormW9TaxClassificationEnum,
  NationalIdentificationTypeEnum,
  UpdatePaymentMethodPurposeEnum,
  UpdatePaymentMethodTypeEnum,
  UpdateTaxFormW8BENCountryOfIncorporationEnum,
  UpdateTaxFormW8ECICountryOfIncorporationEnum,
  UpdateTaxFormW8ECITaxEntityTypeEnum,
  UpdateTaxFormW9TaxClassificationEnum,
} from '../openapi/yenta';
import { ISelectOption, RootState, YesNoType } from '../types';
import { getMSDynamicsDefaultFormValues } from '../utils/MSDynamicHelper';

interface BankInfo {
  type: CreatePaymentMethodTypeEnum | UpdatePaymentMethodTypeEnum;
  nickname: string;
  usRoutingNumber?: string;
  usConfirmRoutingNumber?: string;
  canadaRoutingNumber?: CanadaRoutingNumber;
  accountNumber: string;
  confirmAccountNumber: string;
  purpose?: ISelectOption<
    CreatePaymentMethodPurposeEnum | UpdatePaymentMethodPurposeEnum
  >;
  bankName: string;
  bankId?: string;
}

interface MsDynamicsOnboardingRouteProps {}

export interface MSDynamicOnboardingFormData {
  //STEP ONE
  bankInfo: BankInfo[];
  //STEP TWO
  firstName: string;
  middleName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  streetAddress1: string;
  streetAddress2: string;
  country: string;
  stateOrProvince: string;
  city: string;
  zipOrPostalCode: string;
  mailAddressSameAsContact?: YesNoType[];
  mailStreetAddress1: string;
  mailStreetAddress2: string;
  mailCountry: string;
  mailStateOrProvince: string;
  mailCity: string;
  mailZipOrPostalCode: string;

  // SELECT TAX FORM STEP
  taxFormType?: CreateTaxFormTypeEnum;

  //Form W-9
  w9: {
    id?: string;
    taxPayer: string;
    disregardedEntityName?: string;
    usTaxIdNumber: string;
    usTaxIdType: NationalIdentificationTypeEnum;
    taxClassification: ISelectOption<
      | CreateTaxFormW9TaxClassificationEnum
      | UpdateTaxFormW9TaxClassificationEnum
    >;
    addressLine: string;
    city: string;
    state: AddressResponseStateOrProvinceEnum;
    zipCode: string;
    signature: string;
    signDate: string;
    electronicDelivery?: YesNoType[];
  };

  w8ECI: {
    id?: string;
    taxPayer: string;
    countryOfIncorporation: ISelectOption<
      | CreateTaxFormW8ECICountryOfIncorporationEnum
      | UpdateTaxFormW8ECICountryOfIncorporationEnum
    >;
    typeOfEntity: ISelectOption<
      CreateTaxFormW8ECITaxEntityTypeEnum | UpdateTaxFormW8ECITaxEntityTypeEnum
    >;
    disregardedEntityName?: string;
    usTaxIdNumber: string;
    usTaxIdType: NationalIdentificationTypeEnum;
    foreignTaxId: string;
    addressLine: string;
    city: string;
    state: AddressStateEnum;
    country: AddressCountryEnum;
    zipCode: string;
    businessAddressSameAsPermanent?: YesNoType[];
    businessAddressLine: string;
    businessCity: string;
    businessState: AddressStateEnum;
    businessCountry: AddressCountryEnum;
    businessZipCode: string;
    specifyIncome?: string;
    signature: string;
    signDate: string;
  };

  //Form W-8Ben Step
  w8BEN: {
    id?: string;
    taxPayer: string;
    countryOfCitizenship: ISelectOption<
      | CreateTaxFormW8BENCountryOfIncorporationEnum
      | UpdateTaxFormW8BENCountryOfIncorporationEnum
    >;
    usTaxIdNumber: string;
    usTaxIdType: NationalIdentificationTypeEnum;
    foreignTaxId: string;
    addressLine: string;
    city: string;
    state: AddressStateEnum;
    country: AddressCountryEnum;
    zipCode: string;
    mailingAddressSameAsPermanent?: YesNoType[];
    mailingAddressLine: string;
    mailingCity: string;
    mailingState: AddressStateEnum;
    mailingCountry: AddressCountryEnum;
    mailingZipCode: string;
    signature: string;
    signDate: string;
  };

  canadaTaxes: {
    id?: string;
    taxPayerFirstname: string;
    taxPayerSurname: string;
    taxpayerBusinessName: string;
    taxpayerBusinessNumber: string;
    gstId: string;
    hstId: string;
    sinId: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    provinceOrRegion: AddressStateEnum;
    zipCode: string;
    country: AddressCountryEnum;
    signature: string;
    signDate: string;
    incorporated?: boolean;
  };
  accountCountry: AgentResponseAccountCountryEnum;
}

export enum MSDynamicOnboardingSteps {
  INTRO = 'INTRO',
  BANK_INFORMATION = 'BANK_INFORMATION',
  CONTACT_INFORMATION = 'CONTACT_INFORMATION',
  TAX_INFO_US = 'TAX_INFO_US',
  W9 = 'W9',
  W8ECI = 'W8ECI',
  W8BEN = 'W8BEN',
  CANADA_TAXES = 'CANADA_TAXES',
}

const steps: StepConfig<
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps
>[] = [
  {
    Component: MSDynamicsOnboardingIntro,
    name: MSDynamicOnboardingSteps.INTRO,
    hidePagination: true,
  },
  {
    Component: MSDynamicsContactInformation,
    name: MSDynamicOnboardingSteps.CONTACT_INFORMATION,
    hidePagination: true,
  },
  {
    Component: MSDynamicsBankInformation,
    name: MSDynamicOnboardingSteps.BANK_INFORMATION,
    hidePagination: true,
  },
  {
    Component: MsDynamicOnboardingTaxInfo,
    name: MSDynamicOnboardingSteps.TAX_INFO_US,
    hidePagination: true,
    showStepIf(values): boolean {
      return (
        values.accountCountry === AgentResponseAccountCountryEnum.UnitedStates
      );
    },
  },
  {
    Component: MsDynamicOnboardingTaxFormW9,
    name: MSDynamicOnboardingSteps.W9,
    hidePagination: true,
    showStepIf(values): boolean {
      return (
        values.accountCountry ===
          AgentResponseAccountCountryEnum.UnitedStates &&
        values.taxFormType === CreateTaxFormTypeEnum.W9
      );
    },
  },
  {
    Component: MSDynamicsFormW8eci,
    name: MSDynamicOnboardingSteps.W8ECI,
    hidePagination: true,
    showStepIf(values): boolean {
      return (
        values.accountCountry ===
          AgentResponseAccountCountryEnum.UnitedStates &&
        values.taxFormType === CreateTaxFormTypeEnum.W8Eci
      );
    },
  },
  {
    Component: MsDynamicOnboardingTaxFormW8Ben,
    name: MSDynamicOnboardingSteps.W8BEN,
    hidePagination: true,
    showStepIf(values): boolean {
      return (
        values.accountCountry ===
          AgentResponseAccountCountryEnum.UnitedStates &&
        values.taxFormType === CreateTaxFormTypeEnum.W8Ben
      );
    },
  },
  {
    Component: MSDynamicsCanadaTaxes,
    name: MSDynamicOnboardingSteps.CANADA_TAXES,
    hidePagination: true,
    showStepIf(values): boolean {
      return values.accountCountry === AgentResponseAccountCountryEnum.Canada;
    },
  },
];

const MsDynamicsOnboardingRoute: React.FC<MsDynamicsOnboardingRouteProps> = () => {
  const history = useHistory();
  const [
    currentStepName,
    setCurrentStepName,
  ] = useState<MSDynamicOnboardingSteps>(MSDynamicOnboardingSteps.INTRO);

  const titleMap: Record<MSDynamicOnboardingSteps, string> = {
    [MSDynamicOnboardingSteps.INTRO]: 'Tax and Payment Information',
    [MSDynamicOnboardingSteps.BANK_INFORMATION]:
      'Set Up Payout Method: Bank Details',
    [MSDynamicOnboardingSteps.CONTACT_INFORMATION]:
      'Set Up Payout Method: Contact Information',
    [MSDynamicOnboardingSteps.TAX_INFO_US]: 'Tax Information',
    [MSDynamicOnboardingSteps.W8BEN]: 'Tax Information-W-8BEN',
    [MSDynamicOnboardingSteps.W9]: 'Tax Information-W-9',
    [MSDynamicOnboardingSteps.W8ECI]: 'Tax Information: W-8ECI',
    [MSDynamicOnboardingSteps.CANADA_TAXES]: 'Canada Taxes',
  };

  const { auth, taxInformation, paymentMethod } = useSelector(
    (state: RootState) => state,
  );

  const taxforms =
    taxInformation.taxFormResponseByAgentId[auth.userDetail?.id!];
  const paymentMethods =
    paymentMethod.paymentMethodsResponseByAgentId[auth.userDetail?.id!];

  const hideClose = !(
    taxforms?.data?.taxForms?.length &&
    paymentMethods?.data?.paymentMethods?.length
  );

  const defaultValues = getMSDynamicsDefaultFormValues(
    auth.userDetail,
    paymentMethods?.data,
    taxforms?.data,
  );

  const onSubmit = () => {
    history.replace(`/people/${auth.userDetail?.id}/payment-settings`);
  };

  return (
    <ZenRoute title='Setup Payments'>
      <ZenAgentOnboardingLayout
        title={titleMap[currentStepName]}
        hideClose={hideClose}
        onClose={() =>
          history.location.key ? history.go(-1) : history.push('/')
        }
      >
        <ResourceContainer
          loading={!!taxforms?.loading || !!paymentMethods?.loading}
          isEmpty={false}
          resourceName='payment-onboarding'
        >
          <StepByStepContainer<
            MSDynamicOnboardingFormData,
            MSDynamicOnboardingSteps
          >
            steps={steps}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            reValidateMode='onChange'
            mode='onChange'
            onChangeStep={(step) => setCurrentStepName(step.name)}
          />
        </ResourceContainer>
      </ZenAgentOnboardingLayout>
    </ZenRoute>
  );
};

export default MsDynamicsOnboardingRoute;
