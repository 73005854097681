import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { faStar, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import millify from 'millify';
import Animate from '../../../assets/img/wealthManagement/animate-background.png';
import DotLines from '../../../assets/img/wealthManagement/dot-lines.png';
import YourSuccess from '../../../assets/img/wealthManagement/your-success.png';
import ZenGraphEmoji from '../../../assets/img/wealthManagement/zen-graph-emoji.png';
import ZenCelebrateEmoji from '../../../assets/img/wealthManagement/zen-celebrate-emoji.png';
import AnalyticsService from '../../../services/AnalyticsService';
import {
  getOfficialPlan,
  setWealthKnowledgeLandingPage,
} from '../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum, RootState } from '../../../types';
import ZenButton from '../../Zen/ZenButton';
import { Earning } from '../WealthManagementTypes';
import { LearnMoreCarousel } from './LearnMoreCarousel';
import { PrimaryGradientText } from './PrimaryGradientText';
import AgreementDetails from './AgreementDetails';
import ZenLandingPageIntro from './ZenLandingPageIntro';

const CappingInfoCard = ({
  title,
  index,
  content,
  icon,
}: {
  title?: string;
  index?: number;
  content?: any;
  icon: string;
}) => (
  <>
    <div className='absolute w-full h-full bg-black rounded-2xl top-1 left-1' />
    <div className='flex flex-col rounded-2xl border-2 border-zen-dark-13 pl-10 lg:pl-16 pt-3 lg:pt-6 pr-5 h-44 w-[300px] lg:w-[400px] bg-white relative'>
      <div className='absolute top-0 left-0 translate-x-[-33%] translate-y-[-33%] rounded-full bg-black text-white w-14 h-14 flex justify-center items-center text-3xl'>
        {index}
      </div>
      <div className='text-2xl lg:text-3xl font-bold uppercase'>
        {title}
        <img src={icon} alt='icon' className='w-8 inline-block ml-2' />
      </div>
      {content}
    </div>
  </>
);

const HowItWorks = () => {
  const dispatch = useDispatch();
  const handleLearnMore = () => {
    dispatch(setWealthKnowledgeLandingPage(Earning.COMMISSION_INCOME));
  };
  return (
    <div className='flex flex-col justify-center items-center m-5 mt-24 lg:m-32'>
      <div className='flex flex-col justify-center items-center mt-20 mb-6'>
        <div className='text-5xl font-bold font-poppins text-black text-center mb-5'>
          How <PrimaryGradientText>Capping Stock</PrimaryGradientText> Works
        </div>
      </div>
      <div
        className='relative h-[450px] lg:h-[500px] w-full'
        style={{
          backgroundImage: `url(${Animate})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className='absolute top-4 left-0'>
          <CappingInfoCard
            title='You Cap'
            index={1}
            icon={ZenCelebrateEmoji}
            content={
              <p className='lg:text-lg'>
                Agents reach &rsquo;capped&rsquo; status by meeting their annual
                cap contribution requirement.{' '}
                <span
                  className='text-primary-blue cursor-pointer'
                  onClick={handleLearnMore}
                >
                  (Learn More)
                </span>
              </p>
            }
          />
        </div>

        <div className='absolute bottom-4 right-0'>
          <CappingInfoCard
            title='You get shares'
            index={2}
            icon={ZenGraphEmoji}
            content={
              <p className='lg:text-lg'>
                When you hit your commission cap, Real grants you a stock award
                for capping!
              </p>
            }
          />
        </div>

        <div className='flex flex-col items-center justify-center h-full w-full'>
          <img
            src={DotLines}
            alt='DotLines'
            className='lg:w-3/5 mt-2 mx-auto px-20'
          />
        </div>
      </div>
    </div>
  );
};

const CappingAwardsRowItem = ({
  title1,
  title2,
  icon,
  iconClasses,
  shares,
  cap,
}: {
  title1: string;
  title2: string;
  icon: any;
  iconClasses?: string;
  shares: number | undefined;
  cap: number;
}) => {
  return (
    <div className='bg-gray-900 rounded-xl flex flex-row gap-2 w-full md:w-3/5 py-4 px-4 justify-between mb-3'>
      <div className='flex gap-3 ml-2 w-2/3'>
        <FontAwesomeIcon icon={icon} size='xl' className={iconClasses} />
        <div>
          <div className='text-xl text-white font-zen-body-2 uppercase'>
            {title1}
          </div>
          <div className='text-xl text-white font-zen-body-2 uppercase'>
            {title2}
          </div>
        </div>
      </div>
      <div className='w-1/3'>
        <div className='text-blue-200 text-xl font-zen-body-2'>
          {shares} shares
        </div>
        <div className='text-gray-400 text-xl font-zen-body-2'>
          ${millify(cap)} CAP
        </div>
      </div>
    </div>
  );
};

const CappingBreakdown = () => {
  const dispatch = useDispatch();
  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );
  useEffect(() => {
    if (officialPlanAndAwardData === undefined) {
      dispatch(getOfficialPlan());
    }
  }, [dispatch, officialPlanAndAwardData]);

  const individual = officialPlanAndAwardData?.awardsConfig?.capLevels?.find(
    (c) => c.awardType === 'CAPPING' && c.capLevel === 12000,
  );
  const teamMember = officialPlanAndAwardData?.awardsConfig?.capLevels?.find(
    (c) => c.awardType === 'CAPPING' && c.capLevel === 6000,
  );
  const platinum = officialPlanAndAwardData?.awardsConfig?.capLevels?.find(
    (c) => c.awardType === 'CAPPING' && c.capLevel === 4000,
  );

  return (
    <div className='flex flex-col justify-center items-center bg-black pt-5 pb-10 mb-10 px-3'>
      <div className='text-5xl font-bold text-white lg:w-1/2 text-center mb-5 mt-10'>
        Your <PrimaryGradientText>Capping Award</PrimaryGradientText> Breakdown
      </div>
      <div className='text-white lg:w-1/3 text-center mb-5 text-2xl'>
        Real grants you a stock award based on your cap level.
      </div>

      <CappingAwardsRowItem
        cap={12000}
        shares={individual?.awardAmount}
        icon={faUser}
        iconClasses='text-primary-blue'
        title1='individual agent'
        title2='& team lead'
      />
      <CappingAwardsRowItem
        cap={6000}
        shares={teamMember?.awardAmount}
        icon={faUsers}
        iconClasses='text-zen-purple-1'
        title1='standard team'
        title2='member'
      />
      <CappingAwardsRowItem
        cap={4000}
        shares={platinum?.awardAmount}
        icon={faStar}
        iconClasses='text-amber-500'
        title1='platinum team'
        title2='member'
      />
    </div>
  );
};

const WhatsMyCommissionCap = () => {
  const dispatch = useDispatch();

  const handleViewCommissionIncome = () => {
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.WEALTH_PLAN_LP_CAPPING_AWARD_CLICK_ON_VIEW_COMMISSION_INCOME,
    );
    dispatch(setWealthKnowledgeLandingPage(Earning.COMMISSION_INCOME));
  };

  return (
    <div className='flex flex-col justify-center items-center mb-32'>
      <div className='text-5xl lg:text-6xl font-bold text-black lg:w-2/3 text-center mb-7'>
        What&apos;s My
        <div className='w-full'>
          <PrimaryGradientText>Commission Cap?</PrimaryGradientText>
        </div>
      </div>

      <div className='px-3 lg:w-1/2 text-center text-2xl mb-7'>
        Agents achieve a &apos;capped&apos; status when they reach their
        earnings limit, with varying cap levels applicable to three distinct
        agent types at Real.
      </div>
      <div>
        <ZenButton
          label='View Commission Income →'
          onClick={handleViewCommissionIncome}
          isFullWidth
        />
      </div>
    </div>
  );
};

export const ZenStockAwardForCappingKnowledgePage = () => {
  return (
    <>
      <ZenLandingPageIntro
        earningType={Earning.STOCK_AWARD_FOR_CAPPING}
        sloganImage={YourSuccess}
        subtitle='Let Real reward you for what you already do'
      />
      <HowItWorks />
      <CappingBreakdown />
      <WhatsMyCommissionCap />
      <AgreementDetails />
      <LearnMoreCarousel />
    </>
  );
};
