import { useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ZenResourceLinkCard from '../../../ZenResourceLinkCard';
import ZenMarkAsCapDeferredSidebarModal from './sidebarForms/ZenMarkAsCapDeferredSidebarModal';

interface ZenMarkAsCapDeferredProps {}

const ZenMarkAsCapDeferred: React.FC<ZenMarkAsCapDeferredProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div>
      <ZenResourceLinkCard
        title='Mark as Cap Deferred'
        onClick={() => setIsOpen(true)}
        icon={solid('redo')}
        iconSize='2x'
      />
      <ZenMarkAsCapDeferredSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenMarkAsCapDeferred;
