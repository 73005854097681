import { AgentWebsiteResponse } from '../../../../openapi/yenta';
import ZenContentRow from './ZenContentRow';

interface ZenAgentWebsiteMainContentProps {
  agentWebsite: AgentWebsiteResponse | undefined;
}

const ZenAgentWebsiteMainContent: React.FC<ZenAgentWebsiteMainContentProps> = ({
  agentWebsite,
}) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-8 p-4'>
      <div className='flex flex-col space-y-8'>
        <ZenContentRow
          step={1}
          title='Display Name'
          content={agentWebsite?.agentResponse?.displayName}
          defaultContent='Display Name'
        />
        <ZenContentRow
          step={2}
          title='Title/Certification'
          content={agentWebsite?.websiteTitle}
          defaultContent='Title/Certification'
        />
        <ZenContentRow
          step={3}
          title='Homepage Title'
          content={agentWebsite?.mainTitle}
          defaultContent='Homepage Title'
        />
        <ZenContentRow
          step={4}
          title='Subtitle'
          content={agentWebsite?.mainSubtitle}
          defaultContent='Subtitle'
        />
      </div>
      <div className='flex flex-col space-y-8'>
        <ZenContentRow
          step={5}
          title='Message Title'
          content={agentWebsite?.bottomTitle}
          defaultContent='Message Title'
        />
        <ZenContentRow
          step={6}
          title='Message Content'
          content={agentWebsite?.bottomSubtitle}
          defaultContent='Message Content'
        />
      </div>
    </div>
  );
};

export default ZenAgentWebsiteMainContent;
