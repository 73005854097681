import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsService from '../../../../services/AnalyticsService';
import {
  getCommissionIncome,
  updateWealthGoals,
} from '../../../../slices/WealthManagementSlice';
import { RootState } from '../../../../types';
import { formatMoneyValue } from '../../../../utils/CurrencyUtils';
import {
  getAverageFixedCommissionEndOfDeal,
  getTeamDefaultValues,
  getTeamInfo,
  safeTypeConToNum,
} from '../../../../utils/WealthPlanUtils';
import { useDebounce } from '../../utils';
import CommissionIncomeFormInputs from '../../V2/Forms/CommissionIncomeFormInputs';
import IncomeProjectionChart from '../../V2/IncomeProjectionChart';
import { GoalsFormInput } from '../../V2/SetGoalsSideBarV2';
import WealthPlanInfoCard from '../../V2/WealthPlanInfoCard';
import { WealthManagementCard } from '../../WealthManagementCard';
import { selectAgentCurrency } from '../../WealthManagementUtils';
import WealthPlanSidebarContainer from './WealthPlanSidebarContainer';

const CommissionIncomeEarningsSidebarContentV2 = () => {
  const dispatch = useDispatch();
  const {
    commissionIncomeResponse: {
      loading: commissionIncomeLoading,
      data: commissionIncomeResponse,
    },
    wealthGoalsResponse: { data: wealthGoalsResponse },
    agentCurrency,
  } = useSelector((state: RootState) => ({
    ...state.wealthManagement,
    agentCurrency: selectAgentCurrency(state),
  }));

  const { goalDetails } = wealthGoalsResponse || {};
  const methods = useForm<GoalsFormInput>({
    defaultValues: {
      averageHomeSalePrice: goalDetails?.averageHomeSalePrice?.amount,
      transactionsPerYear: goalDetails?.transactionsPerYear,
      averageTransactionCommissionPercentage:
        goalDetails?.averageTransactionCommissionPercentage,
      useAverageFixedCommission: goalDetails?.useAverageFixedCommission,
      averageFixedCommission: goalDetails?.averageFixedCommission?.amount,
      ...getTeamDefaultValues(goalDetails),
    },
  });

  const { watch, setValue, handleSubmit } = methods;
  const formDataValues = watch();

  const isLoading = commissionIncomeLoading && !commissionIncomeResponse;

  const {
    projectedNetCommissionIncome,
    currentGCI,
    currentNetCommissionIncome,
  } = commissionIncomeResponse || {};

  useEffect(() => {
    dispatch(getCommissionIncome());
  }, [dispatch, wealthGoalsResponse]);

  useDebounce(
    handleSubmit((formData: GoalsFormInput) => {
      const useAverageFixedCommission = formData.useAverageFixedCommission;
      const averageFixedCommission = safeTypeConToNum(
        formData.averageFixedCommission,
      );
      const averageTransactionCommissionPercentage = safeTypeConToNum(
        formData.averageTransactionCommissionPercentage,
      );
      if (
        formData.transactionsPerYear !== goalDetails?.transactionsPerYear ||
        formData.averageHomeSalePrice !==
          goalDetails?.averageHomeSalePrice?.amount ||
        useAverageFixedCommission !== goalDetails?.useAverageFixedCommission ||
        averageFixedCommission !==
          goalDetails?.averageFixedCommission?.amount ||
        averageTransactionCommissionPercentage !==
          goalDetails?.averageTransactionCommissionPercentage
      ) {
        dispatch(
          updateWealthGoals({
            transactionsPerYear: formData.transactionsPerYear,
            averageHomeSalePrice: {
              amount: formData.averageHomeSalePrice,
              currency: agentCurrency,
            },
            ...getAverageFixedCommissionEndOfDeal(formData, agentCurrency),
          }),
        );
      }

      if (formData.teamLead) {
        const teamNumberCount = safeTypeConToNum(formData.teamNumberCount);
        const teamTransactionsPerMember = safeTypeConToNum(
          formData.teamTransactionsPerMember,
        );
        const teamAverageSalesPrice = safeTypeConToNum(
          formData.teamAverageSalesPrice,
        );
        const teamLeaderCommissionPercentagePerTransaction = safeTypeConToNum(
          formData.teamLeaderCommissionPercentagePerTransaction,
        );
        const teamUseAverageFixedCommission =
          formData.teamUseAverageFixedCommission;
        const teamAverageFixedCommission = safeTypeConToNum(
          formData.teamAverageFixedCommission,
        );
        const teamAverageTransactionCommissionPercentage = safeTypeConToNum(
          formData.teamAverageTransactionCommissionPercentage,
        );

        if (
          formData.teamLead !== goalDetails?.teamLead ||
          teamNumberCount !== goalDetails?.teamNumberCount ||
          teamTransactionsPerMember !==
            goalDetails?.teamTransactionsPerMember ||
          teamLeaderCommissionPercentagePerTransaction !==
            goalDetails?.teamLeaderCommissionPercentagePerTransaction ||
          teamAverageSalesPrice !==
            goalDetails?.teamAverageSalesPrice?.amount ||
          teamUseAverageFixedCommission !==
            goalDetails.teamUseAverageFixedCommission ||
          teamAverageFixedCommission !==
            goalDetails?.teamAverageFixedCommission?.amount ||
          teamAverageTransactionCommissionPercentage !==
            goalDetails?.teamAverageTransactionCommissionPercentage
        ) {
          dispatch(updateWealthGoals(getTeamInfo(formData, agentCurrency)));
        }
      } else if (goalDetails?.teamLead) {
        dispatch(updateWealthGoals(getTeamInfo(formData, agentCurrency)));

        setValue('teamTransactionsPerMember', 1);
        setValue('teamAverageSalesPrice', 10000);
        setValue('teamAverageFixedCommission', 0);
        setValue('teamAverageTransactionCommissionPercentage', 0.1);
      }
    }),
    { delay: 500, dependencies: [JSON.stringify(formDataValues)] },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string) => {
      AnalyticsService.instance().logEvent(event);
    }, 500),
    [],
  );

  return (
    <WealthPlanSidebarContainer
      type='COMMISSION_INCOME'
      loading={isLoading}
      isEmpty={!commissionIncomeResponse}
      resourceName='Commission Income'
      showStockForecastInput={false}
    >
      <WealthPlanInfoCard
        label='5 Year Projected Net Commission'
        value={formatMoneyValue(projectedNetCommissionIncome)}
        labelTooltip='Value of projected cumulative net commission in 5 years'
        valueSizeVariant='text-3xl'
      />
      <div className='flex flex-row justify-between space-x-3'>
        <WealthPlanInfoCard
          label='GCI Earned-to-Date'
          value={formatMoneyValue(currentGCI)}
        />
        <WealthPlanInfoCard
          label='Net Commission Earned-to-Date'
          value={formatMoneyValue(currentNetCommissionIncome)}
        />
      </div>
      <WealthManagementCard>
        <IncomeProjectionChart
          earningType='COMMISSION_INCOME'
          title={{
            text: '5 Year Projected Net Commission',
            tooltip: 'Value of projected cumulative net commission in 5 years',
          }}
          noContainer
        />
        <FormProvider {...methods}>
          <div className='mt-6'>
            <CommissionIncomeFormInputs
              logAnalyticsEvent={logAnalyticsEvent}
              noContainer
            />
          </div>
        </FormProvider>
      </WealthManagementCard>
    </WealthPlanSidebarContainer>
  );
};

export default CommissionIncomeEarningsSidebarContentV2;
