import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ZenMarketingCenterCardItem {
  title: string;
  subtitle: string;
  link: string;
  image: string;
}

interface ZenMarketingCenterCardProps {
  item: ZenMarketingCenterCardItem;
}

const ZenMarketingCenterCard: React.FC<ZenMarketingCenterCardProps> = ({
  item,
}) => {
  return (
    <div
      className='bg-white rounded-3xl shadow-zen-card h-full relative'
      key={item.link}
    >
      <div className='relative h-48'>
        <img
          src={item.image}
          alt={item.title}
          className='h-full w-full bg-center bg-cover bg-no-repeat rounded-t-3xl'
        />
      </div>
      <div className='p-6'>
        <div>
          <p className='text-base zen-dark leading-6 font-zen-body font-semibold mb-3'>
            {item.title}
          </p>
          <p className='text-sm zen-dark leading-6 font-zen-body text-gray-500 mb-10'>
            {item.subtitle}
          </p>
          <a
            href={item.link}
            title={item.title}
            className='absolute bottom-5'
            target='_blank'
            rel='noreferrer'
          >
            <div className='flex flex-row items-center justify-start'>
              <p className='text-sm text-primary-blue leading-5 font-zen-body font-semibold mr-1 cursor-pointer'>
                View more
              </p>
              <FontAwesomeIcon
                icon={faArrowRightLong}
                className='text-primary-blue text-base mt-0.5'
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ZenMarketingCenterCard;
