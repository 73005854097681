import {
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
} from '../../openapi/yenta';
import {
  COUNTRY_ABBREVIATIONS,
  STATE_OR_PROVINCE_ABBREVIATIONS,
} from '../../utils/StateUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import Hover from '../Hover';
import { ZenCountryToEmojiMap } from '../Zen/Table/Cell/ZenCountryCell';
import { CountryAll } from './LQMSidebarModalForm';

export interface LQMGeoIconCellProps {
  country: AdministrativeAreaRequestCountryEnum;
  states: AdministrativeAreaRequestStateOrProvinceEnum[];
}

const LQMGeoIconCell: React.FC<LQMGeoIconCellProps> = ({ country, states }) => {
  if (states?.length > 0) {
    const countOfStatesToShow = 3;
    const statesToShow = states.sort().slice(0, countOfStatesToShow);
    const statesNotShown = states.sort().slice(countOfStatesToShow);

    return (
      <div className='flex flex-row space-x-2 items-center'>
        {statesToShow.map((state) => (
          <Hover
            hoverComponent={<p className='px-1'>{capitalizeEnum(state)}</p>}
            config={{ placement: 'top-start' }}
            key={state}
          >
            <div className='flex flex-row space-x-2 items-center bg-primary-blue bg-opacity-20 px-2 py-px rounded-xl'>
              <p>{STATE_OR_PROVINCE_ABBREVIATIONS[state]}</p>
            </div>
          </Hover>
        ))}
        {statesNotShown.length > 0 && (
          <Hover
            hoverComponent={
              <p className='px-1'>
                {statesNotShown
                  .map((state) => capitalizeEnum(state))
                  .join(', ')}
              </p>
            }
            config={{ placement: 'top-start' }}
          >
            <p className='border border-gray-100 fill-white rounded-full w-9 flex items-center justify-center bg-primary-blue bg-opacity-20 py-px'>
              +{statesNotShown.length}
            </p>
          </Hover>
        )}
      </div>
    );
  }

  if (country) {
    return (
      <Hover
        hoverComponent={<p className='px-1'>{capitalizeEnum(country)}</p>}
        config={{ placement: 'top-start' }}
      >
        <div className='flex flex-row space-x-2 items-center'>
          <p className='border border-gray-100 fill-white rounded-full w-6 flex items-center justify-center'>
            {ZenCountryToEmojiMap[country]}
          </p>
          <p>{COUNTRY_ABBREVIATIONS[country]}</p>
        </div>
      </Hover>
    );
  }

  return (
    <div className='flex flex-row items-center'>
      <p>{capitalizeEnum(CountryAll.All)}</p>
    </div>
  );
};

export default LQMGeoIconCell;
