import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import AnalyticsEventOnLoad from '../components/Analytics/AnalyticsEventOnLoad';
import DetailPageLoader from '../components/DetailPageLoader';
import MortgageDetailsTabComponent from '../components/Mortgage/MortgageDetailsTabComponent';
import MortgageTab from '../components/Mortgage/MortgageTab';
import PageLayout from '../components/PageLayout';
import ResourceContainer from '../components/ResourceContainer';
import useBrokerQueue from '../hooks/useBrokerQueue';
import {
  BorrowerDto,
  BorrowerDtoTypeEnum,
  LeadLoanDtoStatusEnum,
} from '../openapi/atlantis';
import {
  fetchBorrowerAccounts,
  fetchLoanById,
  saveLoanResponse,
} from '../slices/MortgageSlice';
import { AnalyticsEventEnum, RootState } from '../types';
import { getFullName } from '../utils/AgentHelper';

export interface Match {
  id: string;
  borrowerId: string;
}

interface MortgageDetailsRouteProps extends RouteComponentProps<Match> {}

const MortgageDetailsRoute: React.FC<MortgageDetailsRouteProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loanResponse: { data: loan, loading, error },
    borrowerAccountsResponse: { loading: borrowerAccountsLoading },
  } = useSelector((state: RootState) => state.mortgage);
  const { isBrokerQueueActive } = useBrokerQueue();

  const {
    match: {
      params: { id: mortgageId, borrowerId },
    },
    location,
  } = props;

  const borrower: BorrowerDto | undefined = loan?.borrowers?.find(
    (b) => b?.type === BorrowerDtoTypeEnum.Primary,
  );

  const coBorrower: BorrowerDto | undefined = loan?.borrowers?.find(
    (b) => b?.type === BorrowerDtoTypeEnum.CoBorrower,
  );

  const path = [{ title: 'Mortgage', url: '/mortgages' }];

  if (!!loan?.borrowers?.length) {
    const isBorrower = location.pathname.includes(`/borrower/${borrower?.id}`);
    const borrowerId = isBorrower ? borrower?.id : coBorrower?.id;

    path.push({
      title: getFullName(isBorrower ? borrower : coBorrower)!,
      url: `/mortgages/${mortgageId}`,
    });

    if (borrowerId) {
      if (
        location.pathname === `/mortgages/${mortgageId}/borrower/${borrowerId}`
      ) {
        path.push({
          title: 'Application',
          url: `/mortgages/${mortgageId}/borrower/${borrowerId}`,
        });
      }

      if (location.pathname.includes('/accounts')) {
        path.push({
          title: 'Accounts',
          url: `/mortgages/${mortgageId}/borrower/${borrowerId}/accounts`,
        });
      }

      if (location.pathname.includes('/road-to-success')) {
        path.push({
          title: 'Road To Success',
          url: `/mortgages/${mortgageId}/borrower/${borrowerId}/road-to-success`,
        });
      }

      if (location.pathname.includes('/checklist')) {
        path.push({
          title: 'Checklist',
          url: `/mortgages/${mortgageId}/borrower/${borrowerId}/checklist`,
        });
      }

      if (location.pathname.includes('/file-cabinet')) {
        path.push({
          title: 'File Cabinet',
          url: `/mortgages/${mortgageId}/borrower/${borrowerId}/file-cabinet`,
        });
      }

      if (location.pathname.includes('/comments')) {
        path.push({
          title: 'Comments',
          url: `/mortgages/${mortgageId}/borrower/${borrowerId}/comments`,
        });
      }

      if (location.pathname.includes('/activity')) {
        path.push({
          title: 'Activity',
          url: `/mortgages/${mortgageId}/borrower/${borrowerId}/activity`,
        });
      }
    }
  }

  useEffect(() => {
    if (location.pathname === `/mortgages/${mortgageId}` && borrower) {
      const redirectTo =
        `/mortgages/${mortgageId}/borrower/${borrower?.id}` +
        (isBrokerQueueActive ? '/comments' : '');

      history.replace(redirectTo);
    }
  }, [borrower, history, location.pathname, mortgageId, isBrokerQueueActive]);

  useEffect(() => {
    if (mortgageId) {
      dispatch(fetchLoanById(mortgageId));
    }

    return () => {
      dispatch(saveLoanResponse({ loading: false, name: 'loan details' }));
    };
  }, [dispatch, mortgageId]);

  useEffect(() => {
    if (borrowerId) {
      dispatch(fetchBorrowerAccounts(borrowerId));
    }
  }, [borrowerId, dispatch]);

  const getMortgageTabs = useMemo(() => {
    if (!borrower) return [];

    const tabs = [
      {
        name: `${getFullName(borrower)} (Borrower)`,
        path: `/mortgages/${mortgageId}/borrower/${borrower.id}`,
        exact: false,
        applicationStatus: ((borrower.status ??
          loan?.status) as unknown) as LeadLoanDtoStatusEnum,
        TabComponent: () => (
          <MortgageDetailsTabComponent borrowerId={borrower.id} {...props} />
        ),
      },
    ];

    if (coBorrower) {
      tabs.push({
        name: `${getFullName(coBorrower)} (Co-Borrower)`,
        path: `/mortgages/${mortgageId}/borrower/${coBorrower.id}`,
        exact: false,
        applicationStatus: ((coBorrower.status ??
          loan?.status) as unknown) as LeadLoanDtoStatusEnum,
        TabComponent: () => (
          <MortgageDetailsTabComponent borrowerId={coBorrower.id} {...props} />
        ),
      });
    }

    return tabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mortgageId,
    loan?.id,
    loan?.status,
    borrower?.id,
    borrower?.status,
    coBorrower?.id,
    coBorrower?.status,
  ]);

  return (
    <ResourceContainer
      loading={loading || borrowerAccountsLoading}
      LoaderComponent={<DetailPageLoader />}
      isEmpty={!loan?.borrowers?.length}
      errorCode={error}
      resourceName='loan'
    >
      <PageLayout path={path} hideLayoutPaths={isBrokerQueueActive}>
        <div className='mt-6'>
          <MortgageTab tabs={getMortgageTabs} />
          <AnalyticsEventOnLoad eventName={AnalyticsEventEnum.MC_LOAN_VIEWED} />
        </div>
      </PageLayout>
    </ResourceContainer>
  );
};

export default MortgageDetailsRoute;
