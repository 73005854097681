import { DragOverlay } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { ColumnInstance, Row } from 'react-table';
import { ResourceTableVariant } from '../../containers/ResourceTable';
import { VerticalAlignmentVariant } from '../../types';
import { cn } from '../../utils/classUtils';

interface ResourceTableRowProps<D extends object> {
  row: Row<D>;
  variant: ResourceTableVariant;
  renderToggleRowComponent?(d: Row<D>): React.ReactElement;
  visibleColumns: ColumnInstance<D>[];
  cellVerticalAlignment: VerticalAlignmentVariant;
  allowSortingRows?: boolean;
}

const ResourceTableRow = <D extends object>({
  row,
  variant,
  renderToggleRowComponent,
  visibleColumns,
  cellVerticalAlignment,
  allowSortingRows,
}: ResourceTableRowProps<D>) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    transform,
    transition,
  } = useSortable({
    id: row.id,
    disabled: !allowSortingRows,
  });

  const style = {
    transform: CSS?.Transform?.toString(transform),
    transition: transition,
  };

  const { key, ...rest } = row.getRowProps();

  return (
    <React.Fragment key={key}>
      <tr
        ref={setNodeRef}
        {...attributes}
        {...rest}
        style={style}
        className={cn({
          'border-b border-regent-300': variant === ResourceTableVariant.ROW,
          'grid grid-cols-12 grid-flow-row':
            variant === ResourceTableVariant.CARD,
        })}
      >
        {isDragging ? (
          <td className='bg-grey-100 h-16' colSpan={row?.cells?.length + 1}>
            &nbsp;
          </td>
        ) : (
          <Fragment>
            {allowSortingRows && (
              <td>
                <div {...listeners}>
                  <FontAwesomeIcon
                    icon={faGripDotsVertical}
                    size='lg'
                    className='text-zen-dark-6 cursor-move'
                  />
                </div>
              </td>
            )}
            {row.cells.map((cell) => {
              return (
                <td
                  //@ts-ignore
                  key={key}
                  {...cell.getCellProps()}
                  className={cn('p-2 bg-white', cellVerticalAlignment, {
                    [`border col-span-${cell.column.cardColSize || 12}`]:
                      variant === ResourceTableVariant.CARD,
                  })}
                >
                  {variant === ResourceTableVariant.CARD && (
                    <p className='text-xs text-gray-400 font-primary-medium uppercase'>
                      {cell.column.render('Header')}
                    </p>
                  )}
                  {cell.render('Cell')}
                </td>
              );
            })}
          </Fragment>
        )}
      </tr>
      {row.isExpanded && renderToggleRowComponent ? (
        <tr>
          <td colSpan={visibleColumns?.length}>
            {renderToggleRowComponent(row)}
          </td>
        </tr>
      ) : null}
      <tr>
        <td colSpan={row?.cells?.length + 1}>
          {isDragging && (
            <DragOverlay>
              <table className='w-full'>
                <tbody>
                  <tr
                    className='shadow-pop-over rotate-1 w-full bg-white'
                    {...row?.getRowProps()}
                  >
                    <td>
                      <div {...listeners}>
                        <FontAwesomeIcon
                          icon={faGripDotsVertical}
                          size='lg'
                          className='text-zen-dark-6 cursor-move'
                        />
                      </div>
                    </td>
                    {row?.cells?.map((cell) => (
                      // eslint-disable-next-line react/jsx-key
                      <td
                        {...cell.getCellProps()}
                        className={cn('p-2 bg-white', cellVerticalAlignment, {
                          [`border col-span-${cell.column.cardColSize || 12}`]:
                            variant === ResourceTableVariant.CARD,
                        })}
                      >
                        {variant === ResourceTableVariant.CARD && (
                          <p className='text-xs text-gray-400 font-primary-medium uppercase'>
                            {cell.column.render('Header')}
                          </p>
                        )}
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </DragOverlay>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
};

export default ResourceTableRow;
