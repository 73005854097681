import React from 'react';

const ZenEditTransactionToggleSection: React.FC = ({ children }) => {
  const hasContent = React.Children.toArray(children)?.some((child) => {
    if (React.isValidElement(child)) {
      return child.props.children;
    }
    return child;
  });

  if (!hasContent) {
    return null;
  }

  return (
    <div className='border divide-y rounded-[10px] p-3 mt-5'>{children}</div>
  );
};

export default ZenEditTransactionToggleSection;
