import React from 'react';

interface ZenTablePageSizeProps {
  itemsToShow: number;
  pageSizeOptions?: number[];
  setPageSize(size: number): void;
  totalCount?: number;
  showTotalCount?: boolean;
}

const ZenTablePageSize: React.FC<ZenTablePageSizeProps> = ({
  itemsToShow,
  pageSizeOptions = [10, 20, 30, 40, 50],
  setPageSize,
  totalCount,
  showTotalCount = false,
}) => {
  return (
    <div className='flex items-center text-base text-grey-600 font-zen-body-2'>
      <label htmlFor='pageSize'>View</label>
      <select
        id='pageSize'
        value={itemsToShow}
        onChange={(e) => setPageSize(parseInt(e.target.value, 10))}
        className='block focus:ring-0 border-grey-200 rounded-lg mx-2 py-1.5'
      >
        {pageSizeOptions.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
      {showTotalCount && <span>{totalCount ? `of ${totalCount}` : null}</span>}
    </div>
  );
};

export default ZenTablePageSize;
