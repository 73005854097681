import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  setSelectedEarningsOverviewType,
  setWealthKnowledgeLandingPage,
  updateWealthGoals,
} from '../../../slices/WealthManagementSlice';
import { Earning, EarningType } from '../WealthManagementTypes';
import ZenButton, { ZenButtonProps } from '../../Zen/ZenButton';
import { GoalDetails } from '../../../openapi/plutus';
import { AnalyticsEventEnum, RootState } from '../../../types';
import { UUID_REGEX } from '../../../utils/StringUtils';
import AnalyticsService from '../../../services/AnalyticsService';

const EarningEventMap: Partial<Record<EarningType, AnalyticsEventEnum>> = {
  [Earning.PRE_CAP_SPP]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_PRE_CAP_SPP_ADD_TO_PLAN,
  [Earning.POST_CAP_SPP]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_POST_CAP_SPP_ADD_TO_PLAN,
  [Earning.ELITE_PRODUCTION_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_ELITE_PRODUCTION_AWARD_ADD_TO_PLAN,
  [Earning.ELITE_CULTURAL_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_ELITE_CULTURAL_AWARD_ADD_TO_PLAN,
  [Earning.REVENUE_SHARE_INCOME]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_REVENUE_SHARE_ADD_TO_PLAN,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_STOCK_AWARD_ATTRACTING_ADD_TO_PLAN,
};

const getParticipationUpdate = (
  earningType: EarningType,
): Partial<GoalDetails> => {
  switch (earningType) {
    case Earning.PRE_CAP_SPP:
    case Earning.POST_CAP_SPP:
      return {
        sppParticipation: true,
      };
    case Earning.ELITE_PRODUCTION_AWARD:
      return {
        eliteAgentProductionAwardParticipation: true,
        seenEliteProductionPage: true,
      };
    case Earning.ELITE_CULTURAL_AWARD:
      return {
        eliteAgentCulturalAwardParticipation: true,
        seenEliteCulturalPage: true,
      };
    case Earning.REVENUE_SHARE_INCOME:
    case Earning.STOCK_AWARD_FOR_ATTRACTING:
      return {
        revShareParticipation: true,
      };
    default:
      return {};
  }
};

export interface AddToMyPlanWithArrowButtonProps extends ZenButtonProps {
  earningType: EarningType;
}

const AddToMyPlanWithArrowButton = ({
  earningType,
  ...rest
}: AddToMyPlanWithArrowButtonProps) => {
  const { wealthGoalsResponse } = useSelector(
    (state: RootState) => state.wealthManagement,
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(updateWealthGoals(getParticipationUpdate(earningType)));
    dispatch(setWealthKnowledgeLandingPage(undefined));
    dispatch(setSelectedEarningsOverviewType(earningType));
  };

  if (!wealthGoalsResponse.data) {
    return null;
  }

  const revShareInsightsRouteRegExp = new RegExp(
    `/people/${UUID_REGEX}/revshare/insights`,
  );
  const isRevShareInsightsRouteActive = revShareInsightsRouteRegExp.test(
    history.location.pathname,
  );

  if (isRevShareInsightsRouteActive) {
    return null;
  }

  return (
    <ZenButton
      variant='dark'
      onClick={() => {
        const event = EarningEventMap[earningType];
        if (event) {
          AnalyticsService.instance().logEvent(event);
        }
        handleClick();
      }}
      label='Add to my plan '
      isFullWidth
      RightIconComponent={
        <FontAwesomeIcon
          icon={faArrowRight}
          size='sm'
          className='mt-[5px] ml-1'
        />
      }
      {...rest}
    />
  );
};

export default AddToMyPlanWithArrowButton;
