import { useFieldArray, UseFieldArrayReturn } from 'react-hook-form-v7';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../routes/AgentWebsiteOnboardingRoute';
import { AGENT_ONBOARDING_STEPS } from '../../testUtils/AgentWebsiteOnboardingUtils';
import { AgentWebsiteOnboardingStepEnum } from '../../types';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import IconWithTooltip from '../IconWithTooltip';
import ControlledTextAreaInputV7 from '../ControlledTextAreaInputV7';
import ControlledTextInputV7 from '../ControlledTextInputV7';
import ApplicationStepProgressHeader from '../onboarding/ApplicationStepProgressHeader';
import { TEXT_CONTENT_VALIDATIONS } from '../../utils/Validations';
import StepContentInput from './StepContentInput';

const AgentWebsiteOnboardingTestimonial: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ form: { control } }) => {
  const {
    fields,
    append,
    remove,
  } = useFieldArray<AgentWebsiteOnboardingFormData>({
    name: 'testimonials',
    control,
  }) as UseFieldArrayReturn<
    Required<Pick<AgentWebsiteOnboardingFormData, 'testimonials'>>
  >;

  return (
    <div className='h-full w-full flex flex-col items-center max-w-6xl mx-auto'>
      <ApplicationStepProgressHeader
        steps={AGENT_ONBOARDING_STEPS}
        inProgressIndex={AgentWebsiteOnboardingStepEnum.TESTIMONIALS}
      />
      <div className='w-full'>
        <p className='mt-5 font-primary-regular text-base text-dark leading-loose'>
          Testimonials are a great way to build trust with your customers.
          Usually 3 are good enough, but feel free to add more!
        </p>
        <div className='mt-5'>
          {fields.map((testimonial, index) => (
            <div key={testimonial.id} className='mb-5 relative'>
              <StepContentInput step={index + 1}>
                <div className='flex flex-col flex-auto'>
                  <ControlledTextInputV7<
                    AgentWebsiteOnboardingFormData,
                    `testimonials.${number}.clientName`
                  >
                    name={`testimonials.${index}.clientName` as const}
                    control={control}
                    shouldUnregister={false}
                    label='Client name'
                  />
                  <div className='mt-5'>
                    <ControlledTextAreaInputV7<
                      AgentWebsiteOnboardingFormData,
                      `testimonials.${number}.content`
                    >
                      name={`testimonials.${index}.content` as const}
                      control={control}
                      shouldUnregister={false}
                      label='Testimonial'
                      subLabel='(255 characters max)'
                      rows={2}
                      rules={{
                        ...TEXT_CONTENT_VALIDATIONS,
                      }}
                    />
                  </div>
                </div>
                <div className='absolute right-0 cursor-pointer'>
                  <div>
                    <IconWithTooltip
                      icon={
                        <FontAwesomeIcon
                          title='delete'
                          icon={faTrash}
                          className='text-dark'
                          onClick={() => remove(index)}
                        />
                      }
                      toolTipText='Delete Testimonial'
                    />
                  </div>
                </div>
              </StepContentInput>
            </div>
          ))}
          <button
            type='button'
            className='flex flex-row items-center font-primary-medium text-base text-primary rounded-sm px-1 py-1 hover:bg-primary hover:bg-opacity-20 space-x-2 outline-none focus:outline-none'
            onClick={() => append({ clientName: '', content: '' })}
          >
            <FontAwesomeIcon icon={faCirclePlus} className='text-primary' />
            <span className='pt-0.5'>Add a testimonial</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingTestimonial;
