import React from 'react';
import {
  NavLink,
  Route,
  RouteComponentProps,
  Switch,
  useLocation,
} from 'react-router-dom';
import { LeadLoanDtoStatusEnum } from '../../openapi/atlantis';
import { EnumMap } from '../../types';
import { cn } from '../../utils/classUtils';
import MortgageStatus from './MortgageStatus';

export type MortgageTabVariant = 'default' | 'thin';

export interface MortgageTabs {
  name: string;
  path: string;
  exact?: boolean;
  applicationStatus: LeadLoanDtoStatusEnum;
  TabComponent:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

interface MortgageTabProps {
  tabs: MortgageTabs[];
  variant?: MortgageTabVariant;
}

const MortgageTab: React.FC<MortgageTabProps> = ({
  tabs,
  variant = 'default',
}) => {
  const location = useLocation();
  const tabVariantClassMap: EnumMap<MortgageTabVariant, string> = {
    default: 'text-lg',
    thin: 'text-base',
  };

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row items-center justify-start flex-nowrap relative'>
        <div className='flex-grow scrollbar overflow-x-auto flex flex-row px-4'>
          {tabs.map(({ name, applicationStatus, path, exact }, index) => {
            const isActive = location.pathname.includes(path);

            return (
              <div
                className={cn(
                  'flex flex-row justify-center items-center space-x-6 px-5 py-3',
                  isActive
                    ? 'bg-zen-dark-13 text-white rounded-t-lg'
                    : 'bg-regent-200 text-zen-dark-13',
                  !isActive && index === 0 ? 'rounded-tl-lg' : 'rounded-tr-lg',
                )}
                key={name}
              >
                <NavLink
                  className={cn(
                    'focus:outline-none whitespace-pre font-zen-body z-10',
                    tabVariantClassMap[variant],
                    isActive ? 'font-bold' : 'font-semibold',
                  )}
                  to={path}
                  exact={exact}
                >
                  <div className='flex flex-row items-center space-x-3'>
                    <p className='flex flex-col items-center justify-center min-w-max'>
                      {name}
                    </p>
                  </div>
                </NavLink>
                <MortgageStatus status={applicationStatus} />
              </div>
            );
          })}
        </div>
      </div>
      <Switch>
        {tabs.map(({ TabComponent, path, exact = true }) => (
          <Route
            key={path}
            path={path}
            exact={exact}
            component={TabComponent}
          />
        ))}
      </Switch>
    </div>
  );
};

export default MortgageTab;
