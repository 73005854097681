import { groupBy, values } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '../../../assets/img/deleteIconDanger.svg';
import {
  AddressResponseStateOrProvinceEnum,
  AgentControllerApi,
  AgentUpdateLicenseBoardMlsRequest,
  BoardControllerApi,
  BoardPreviewResponse,
  LicenseRequest,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { fetchAgentDetail } from '../../../slices/AgentSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { AsyncSelectOption, ISelectOption, RootState } from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenControlledAsyncSelectInput from '../../Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledSelectInput from '../../Zen/Input/ZenControlledSelectInput';
import ZenSimpleConfirmationModal from '../../Zen/Modal/ZenSimpleConfirmationModal';
import ZenIconCircleWithTooltipCell from '../../Zen/Transaction/ZenIconCircleWithTooltipCell';
import ZenCardWithItems from '../../Zen/ZenCardWithItems';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import GeminiFeatureFlagButton from '../../GeminiFeatureFlagButton';

interface ManageBoardFormProps {
  isOpen: boolean;
  onClose(): void;
  defaultStateOrProvince: AddressResponseStateOrProvinceEnum;
}

interface FormData {
  stateOrProvince?: ISelectOption;
  boardIds: string[];
  board: ISelectOption;
}

const ManageBoardForm: React.FC<ManageBoardFormProps> = ({
  isOpen,
  onClose,
  defaultStateOrProvince,
}) => {
  const agentDetailResponse = useSelector(
    (state: RootState) => state.agentDetail?.detailResponse?.data,
  );

  const stateBoard = groupBy(
    agentDetailResponse!.boards!,
    'administrativeArea.stateOrProvince',
  );

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      boardIds: agentDetailResponse?.boards?.map((item) => item.id),
      stateOrProvince: defaultStateOrProvince
        ? {
            label: capitalizeEnum(defaultStateOrProvince),
            value: defaultStateOrProvince,
          }
        : undefined,
    },
  });

  const stateOrProvince = watch('stateOrProvince');
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [deleteBoard, setDeleteBoard] = useState<BoardPreviewResponse | null>();
  const [isDeleting, setDeleting] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    const boardIds = [
      ...agentDetailResponse!.boards!.map((item) => item.id),
      data.board?.value,
    ];
    try {
      await new AgentControllerApi(
        getYentaConfiguration(),
      ).updateAgentBoardsById(agentDetailResponse?.id!, {
        boardIds: boardIds,
      });
      await dispatch(fetchAgentDetail(agentDetailResponse?.id!));
      onClose();
      dispatch(showSuccessToast('Board updated successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notifyIgnoreAuthErrors('Error updating agent boards', e, {
        data: { ...data },
      });
    }
  };

  const onDelete = async (data: string) => {
    setDeleting(true);
    const formValues: AgentUpdateLicenseBoardMlsRequest = {
      licenses: (agentDetailResponse!.licenses!.map((item) => {
        return {
          ...item,
          administrativeAreaRequest: item.administrativeArea,
        };
      }) as unknown) as LicenseRequest[],
      boardIds: [
        ...agentDetailResponse!
          .boards!.filter((item) => data !== item.id)
          .map((item) => item.id!),
      ],
      mlsIds: agentDetailResponse!.mls!.map((item) => item.id!),
    };

    try {
      await new AgentControllerApi(
        getYentaConfiguration(),
      ).updateAgentLicenseBoardMlsById(agentDetailResponse?.id!, formValues);
      await dispatch(fetchAgentDetail(agentDetailResponse?.id!));
      dispatch(showSuccessToast('Board deleted successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notifyIgnoreAuthErrors(
        'Error deleting board from agent',
        e,
        {
          data: { data },
        },
      );
    } finally {
      setDeleting(false);
      setOpen(false);
      setDeleteBoard(null);
    }
  };

  return (
    <ZenSidebarModal title='Manage Board' isOpen={isOpen} onClose={onClose}>
      <form
        className='flex flex-col justify-between min-h-full '
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='px-4'>
          <div className='mb-4 mt-3'>
            <ZenControlledSelectInput
              name='stateOrProvince'
              control={control}
              options={[
                ...values(AddressResponseStateOrProvinceEnum).map((state) => ({
                  value: state,
                  label: capitalizeEnum(state),
                })),
              ]}
              label='State/Province'
            />
          </div>

          <div className='border-t'>
            <div className='mt-4'>
              <ZenControlledAsyncSelectInput
                key={JSON.stringify(stateOrProvince?.value)}
                name='board'
                control={control}
                fetchData={async (search, page) => {
                  try {
                    const { data } = await new BoardControllerApi(
                      getYentaConfiguration(),
                    ).getBoards(
                      page!,
                      20,
                      (stateOrProvince?.value as unknown) as AddressResponseStateOrProvinceEnum,
                    );

                    const options: AsyncSelectOption[] = data?.boardResponseList
                      ? data?.boardResponseList?.map((resp) => ({
                          value: `${resp.id}`,
                          label: `${resp.name}`,
                        }))
                      : [];

                    return options;
                  } catch (e) {
                    ErrorService.notify('Unable to search for a board', e, {
                      filter: {
                        search,
                        page,
                      },
                    });
                    dispatch(
                      showErrorToast(
                        'An unexpected error occurred.',
                        'We were unable to search for a board. Please try again in a few moments or contact support.',
                      ),
                    );
                  }

                  return [];
                }}
                placeholder='Select an Board'
                label='Add Board'
                disabled={!stateOrProvince}
                rules={{ required: 'Please select a Board' }}
                isRequired
              />

              <div className='pt-4'>
                <p className='text-base font-primary-medium'>Board</p>
                {stateOrProvince !== undefined &&
                !!stateBoard[stateOrProvince.value] ? (
                  <ZenCardWithItems
                    items={stateBoard[stateOrProvince.value].map((item) => {
                      return {
                        name: item.name!,
                        rightActionComponent: (
                          <ZenIconCircleWithTooltipCell
                            tooltipText='Delete'
                            icon={
                              <img src={DeleteIcon} alt='delete' width={14} />
                            }
                            onClick={() => {
                              setDeleteBoard(item);
                              setOpen(true);
                            }}
                            iconVariant='error'
                          />
                        ),
                      };
                    })}
                  />
                ) : (
                  <div className='border rounded-lg p-8 justify-center self-center flex'>
                    <p className='font-zen-body text-gray-500 text-base'>
                      No Boards added yet
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='sticky bottom-0 p-4 bg-white border-t border-gray-200 space'>
          <div className='flex items-center space-x-3'>
            <div className='flex-1'>
              <GeminiFeatureFlagButton
                zenVariant='primary-outline'
                geminiVariant='primary-outline'
                onClick={onClose}
                label='Cancel'
                isFullWidth
              />
            </div>
            <div className='flex-1'>
              <GeminiFeatureFlagButton
                type='submit'
                label='Save'
                isFullWidth
                isSubmitting={isSubmitting}
                isDisabled={isSubmitting}
              />
            </div>
          </div>
        </div>

        <ZenSimpleConfirmationModal
          isOpen={open}
          onClose={() => setOpen(false)}
          variant='danger'
          title='Delete Board'
          subtitle={`Are you sure you want to delete ${deleteBoard?.name}`}
          isSubmitting={isDeleting}
          onConfirm={() => {
            onDelete(deleteBoard!.id!);
          }}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ManageBoardForm;
