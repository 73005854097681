import React, { useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { useFetchChecklistById } from '../../../../query/transaction/useChecklist';
import { RootState } from '../../../../types';
import ResourceContainer from '../../../ResourceContainer';
import VerticalJourneyStepProgress from '../../../RoadToSuccess/VerticalJourneyStepProgress';
import GeminiJourneyProgressCell from '../../Transaction/GeminiJourneyProgressCell';

interface GeminiJourneyPopperProps {
  journeyId: string | undefined;
}

const GeminiJourneyPopper: React.FC<GeminiJourneyPopperProps> = ({
  journeyId,
}) => {
  const { checklistProgressById } = useSelector(
    (state: RootState) => state.checklist,
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const checklistProgress = journeyId
    ? checklistProgressById[journeyId]
    : undefined;

  const { data, isLoading } = useFetchChecklistById(journeyId, isOpen);

  const progress =
    Math.round(
      ((checklistProgress?.completedCountIncludingOptionals || 0) /
        (checklistProgress?.itemCountIncludingOptionals || 0)) *
        100,
    ) || 0;

  return (
    <div onMouseLeave={() => setIsOpen(false)}>
      <Popover
        isOpen={isOpen}
        positions={['top', 'bottom']}
        onClickOutside={() => setIsOpen(false)}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor='white'
            arrowSize={10}
            arrowStyle={{
              bottom: 1,
              zIndex: 1,
            }}
            className='popover-arrow-container'
            arrowClassName='popover-arrow'
          >
            <div className='w-[381px] z-30 border rounded-[20px] border-gray-300 bg-white divide-y-2 space-y-2 py-2 text-dark shadow-2xl'>
              <div className='flex items-center justify-between px-4'>
                <div className='text-base pt-2'>
                  Road To Success Progress: {progress}%
                </div>
                <div
                  onClick={() => setIsOpen(false)}
                  className='bg-zen-dark-10 rounded-full w-5 h-5 flex items-center justify-center text-white'
                >
                  <MdOutlineClose size={24} />
                </div>
              </div>
              <div className='py-2 px-4 max-h-64 scrollbar overflow-y-auto'>
                <ResourceContainer
                  loading={isLoading}
                  isEmpty={false}
                  resourceName='Road To Success'
                >
                  <VerticalJourneyStepProgress steps={data?.items || []} />
                </ResourceContainer>
              </div>
            </div>
          </ArrowContainer>
        )}
      >
        {!!journeyId ? (
          <div
            onMouseEnter={() => setIsOpen(true)}
            aria-label='journey-popper'
            className='cursor-default'
          >
            <GeminiJourneyProgressCell progress={progress} />
          </div>
        ) : (
          <div className='pl-2'>N/A</div>
        )}
      </Popover>
    </div>
  );
};

export default GeminiJourneyPopper;
