import {
  faCircleMinus,
  faCirclePlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import ZenFormErrorMessage from './ZenFormErrorMessage';

interface ZenCounterControlledInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  subLabel?: string;
  isRequired?: boolean;
  counterLabel?: string;
  disabled?: boolean;
  initialCount?: number;
}

const ZenCounterControlledInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  disabled = false,
  counterLabel,
  shouldUnregister = true,
  isRequired = false,
  initialCount = 0,
  ...rest
}: ZenCounterControlledInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      {...rest}
      shouldUnregister={shouldUnregister}
      defaultValue={initialCount as any}
      render={({
        field: { name, onChange, value },
        fieldState: { error, invalid },
      }) => (
        <div className='w-full space-y-1'>
          {label && (
            <label
              className={classNames(
                'inline-block font-zen-body font-semibold',
                invalid ? 'text-danger-error' : 'text-zen-dark-9',
              )}
              htmlFor={name}
            >
              <span>{label}</span>
              {!!subLabel && (
                <span className='font-primary-regular text-sm text-zen-dark-12 ml-1'>
                  {subLabel}
                </span>
              )}
              {isRequired && <span className='text-error'>*</span>}
            </label>
          )}
          <div className='flex flex-row items-center gap-x-4'>
            <div
              className={classNames(
                'flex flex-row items-center gap-x-4 px-3 py-2 bg-white border border-zen-dark-5 rounded-lg',
                invalid && '!border-zen-danger',
                value && 'text-zen-dark-9',
              )}
            >
              <button
                type='button'
                className='appearance-none'
                disabled={!value || disabled}
                onClick={() => onChange(value - 1)}
              >
                <FontAwesomeIcon
                  icon={faCircleMinus}
                  className={classNames('text-dark text-base', {
                    'text-gray-400': !value || disabled,
                  })}
                  aria-label='decrement-counter'
                />
              </button>
              <span className='font-zen-body text-base text-dark font-normal'>
                {value}
              </span>
              <button
                disabled={disabled}
                type='button'
                className='appearance-none'
                onClick={() => onChange(value + 1)}
              >
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  className={classNames('text-dark', {
                    'text-gray-400': disabled,
                  })}
                  aria-label='increment-counter'
                />
              </button>
            </div>
            <span className='font-zen-body text-base font-semibold text-zen-dark-6'>
              {counterLabel}
            </span>
          </div>

          {error && <ZenFormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenCounterControlledInput;
