import {
  faClockRotateLeft,
  faLongArrowLeft,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import AnalyticsService from '../../../../services/AnalyticsService';
import { saveIsLeoModalVisible } from '../../../../slices/LeoSlice';
import { AnalyticsEventEnum } from '../../../../types';

interface LeoChatHeaderProps {
  toggleHistory(show: boolean): void;
}

const LeoChatHeader: React.FC<LeoChatHeaderProps> = ({ toggleHistory }) => {
  const dispatch = useDispatch();

  return (
    <div className='flex flex-row items-center py-3 mx-6 border-b border-b-white border-opacity-20'>
      <div className='mr-3 block sm:hidden'>
        <button
          type='button'
          className='appearance-none'
          onClick={() => dispatch(saveIsLeoModalVisible(false))}
        >
          <FontAwesomeIcon
            icon={faLongArrowLeft}
            className='text-lg text-white'
          />
        </button>
      </div>
      <div className='flex-grow flex flex-col items-center sm:items-start'>
        <div>
          <p className='font-zen-body font-bold text-white text-xl leading-7'>
            Leo
          </p>
        </div>
        <div className='mt-0.5'>
          <p className='font-zen-body text-white text-xs leading-4 text-opacity-80'>
            Online
          </p>
        </div>
      </div>
      <div className='block sm:hidden'>
        <button
          type='button'
          className='appearance-none'
          onClick={() => {
            toggleHistory(true);
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.LEO_MESSAGE_HISTORY_CLICK,
            );
          }}
        >
          <FontAwesomeIcon
            icon={faClockRotateLeft}
            className='text-base text-white'
          />
        </button>
      </div>
    </div>
  );
};

export default LeoChatHeader;
