import AgreementDetailsSvg from '../../../assets/img/wealthManagement/agreement_details.svg';
import { ZenCustomPrimaryGradientText } from './ZenCustomPrimaryGradientText';

const AgreementDetails = () => {
  return (
    <div className='w-full p-5 px-0 pt-8 flex flex-col justify-center items-center bg-regent-100'>
      <div className='flex flex-col justify-center items-center pt-5'>
        <div className='text-3xl md:text-[52px] font-poppins font-bold text-center mb-12'>
          <div>
            <span className='text-black'>Agreement</span>{' '}
            <span>
              <ZenCustomPrimaryGradientText>
                Details
              </ZenCustomPrimaryGradientText>
            </span>
          </div>
        </div>
        <img
          src={AgreementDetailsSvg}
          alt='agreement details'
          className='w-full'
        />
      </div>
    </div>
  );
};

export default AgreementDetails;
