import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { AgentControllerApi, BoardResponse } from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { boardDetail, fetchBoards } from '../../../slices/BoardSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { AppDispatch, AsyncSelectOption, ISelectOption } from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenControlledAsyncSelectInput from '../../Zen/Input/ZenControlledAsyncSelectInput';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../../Zen/ZenButton';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';

interface ZenMoveAllAgentsToBoardProps {
  isOpen: boolean;
  onClose(): void;
  board: BoardResponse;
}

interface FormData {
  board: ISelectOption;
}

const ZenMoveAllAgentsToBoard: React.FC<ZenMoveAllAgentsToBoardProps> = ({
  isOpen,
  onClose,
  board,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { control, watch, handleSubmit } = useForm<FormData>();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const boardValue = watch('board');

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      await new AgentControllerApi(getYentaConfiguration()).migrateBoard1({
        fromId: board.id!,
        toId: boardValue.value,
      });
      dispatch(showSuccessToast('Board agents moved successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to move the agents', e, {
        mls: { fromId: board.id, toId: boardValue.value },
      });
      dispatch(
        showErrorToast(
          'We had a problem moving the agents',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      dispatch(boardDetail(board.id!));
      setIsSubmitting(false);
      setOpenConfirmModal(false);
      onClose();
    }
  };

  return (
    <ZenSidebarModal
      title='Move Agents to Another Board'
      subtitle='Migrates all agents from this Board to another Board'
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='h-full'>
        <form className='flex flex-col justify-between min-h-full'>
          <div className='py-6 px-4'>
            <ZenControlledAsyncSelectInput<FormData, 'board'>
              control={control}
              name='board'
              label='Move To Board'
              placeholder='Search'
              fetchData={async (search, page) => {
                const res = await dispatch(
                  fetchBoards({
                    search,
                    page: page ?? 0,
                    pageSize: 20,
                    sortBy: { name: 'asc' },
                  }),
                );

                const options: AsyncSelectOption[] = (res?.results || []).map(
                  (resp) => ({
                    value: `${resp?.id}`,
                    label: `${resp?.name}`,
                  }),
                );

                return options;
              }}
              rules={{
                required: 'Please select Board',
              }}
              isRequired
            />
          </div>
          <div className='p-4 h-20  border-t border-gray-200'>
            <div className='flex items-center space-x-3'>
              <div className='flex-1'>
                <ZenButton
                  type='button'
                  onClick={() => onClose()}
                  label='Cancel'
                  variant='secondary-gray-outline'
                  isFullWidth
                />
              </div>
              <div className='flex-1'>
                <ZenButton
                  isSubmitting={isSubmitting}
                  label='Move all agents'
                  onClick={handleSubmit(() => setOpenConfirmModal(true))}
                  isFullWidth
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <ZenConfirmationModal
        title='Confirm you want to move all agents.'
        subtitle={`You’re about to move all agents from ${board.name} to ${boardValue?.label}`}
        isOpen={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        onConfirm={onSubmit}
        confirmButtonText='Confirm'
        isSubmitting={isSubmitting}
        isDisabled={isSubmitting}
      />
    </ZenSidebarModal>
  );
};

export default ZenMoveAllAgentsToBoard;
