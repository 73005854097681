import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { cn } from '../utils/classUtils';
import { SwitchVariant } from './CustomSwitch';
import FormErrorMessage from './FormErrorMessage';
import ToggleRow from './ToggleRow';

export interface ControlledToggleInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  tooltip?: string;
  reverse?: boolean;
  rightIcon?: string | React.ReactElement;
  leftIcon?: string | React.ReactElement;
  readOnly?: boolean;
  hideErrorMessage?: boolean;
  variant?: SwitchVariant;
}

const ControlledToggleInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  tooltip,
  reverse = false,
  leftIcon,
  rightIcon,
  readOnly = false,
  hideErrorMessage = false,
  variant,
  ...rest
}: ControlledToggleInputV7Props<TFieldValues, TName>) => {
  return (
    <Controller
      {...rest}
      render={({ field: { value, onChange, name }, fieldState: { error } }) => (
        <div className='space-y-1 w-full'>
          <div
            className={cn('flex flex-row items-center', {
              'justify-between': !reverse,
            })}
          >
            {!!label && (
              <label
                htmlFor={name}
                className={cn(
                  'font-primary-regular text-dark px-2',
                  reverse ? 'order-last' : 'order-first',
                )}
              >
                {label}
              </label>
            )}
            <ToggleRow
              value={value}
              onChange={(checked) => {
                if (!readOnly) {
                  onChange(checked);
                }
              }}
              tooltip={tooltip}
              leftIcon={leftIcon}
              rightIcon={rightIcon}
              variant={variant}
            />
          </div>
          {error && !hideErrorMessage && (
            <FormErrorMessage message={error.message} />
          )}
        </div>
      )}
    />
  );
};

export default ControlledToggleInputV7;
