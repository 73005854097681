import { cn } from '../utils/classUtils';

export type PillVariantType =
  | 'default'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'dark'
  | 'alert';

export type FontSize = 'text-xs' | 'text-sm' | 'text-lg';

export interface PillProps {
  text: string;
  variant?: PillVariantType;
  fontSize?: FontSize;
  icon?: JSX.Element;
}

export const pillVariantTypeToClassNameMap: {
  [type in PillVariantType]: string;
} = {
  default: 'bg-gray-200 text-dark',
  primary: 'bg-primary bg-opacity-20 text-primary',
  success: 'bg-success bg-opacity-20 text-success',
  warning: 'bg-warning bg-opacity-20 text-warning',
  danger: 'bg-error bg-opacity-20 text-error',
  dark: 'bg-dark text-white',
  alert: 'bg-zen-purple-light text-zen-purple-dark',
};

const Pill: React.FC<PillProps> = ({
  text,
  variant = 'default',
  icon,
  fontSize = 'text-sm',
}) => {
  return (
    <div
      className={cn(
        'inline-block py-1 px-3 rounded-full',
        pillVariantTypeToClassNameMap[variant],
      )}
    >
      <div className='flex items-center'>
        {icon && <div className='mr-1 flex items-center'>{icon}</div>}
        <p className={`font-primary-medium ${fontSize}`}>{text}</p>
      </div>
    </div>
  );
};

export default Pill;
