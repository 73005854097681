import {
  faEye,
  faPencilAlt,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from '@mantine/core';

import React, { useState } from 'react';

interface ReferralMenuProps {
  agentSelected?: boolean;
}

const ReferralMenu: React.FC<ReferralMenuProps> = ({ agentSelected }) => {
  const [opened, setOpened] = useState(false);

  return (
    <Menu
      opened={opened}
      onChange={setOpened}
      openDelay={100}
      closeDelay={400}
      width={180}
      radius='md'
      position='bottom-start'
    >
      <Menu.Target>
        <FontAwesomeIcon
          onClick={() => setOpened(!opened)}
          className='text-grey-600 hover:bg-grey-200 px-3 py-1.5 rounded-full'
          icon={faEllipsisVertical}
        />
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>More Actions</Menu.Label>
        {!agentSelected ? (
          <>
            <Menu.Item
              className='h-8'
              icon={<FontAwesomeIcon icon={faPencilAlt} />}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              className='h-8'
              icon={
                <FontAwesomeIcon icon={faTrashCan} className='text-coral-red' />
              }
            >
              <p className='text-coral-red'>Delete</p>
            </Menu.Item>
          </>
        ) : (
          <Menu.Item className='h-8' icon={<FontAwesomeIcon icon={faEye} />}>
            View Applicants
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export default ReferralMenu;
