import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../types';
import { getPageTitleForTransaction } from '../utils/TransactionUtils';
import ZenRoute from '../components/Zen/ZenRoute';
import FileCabinetTable from '../components/Zen/Transaction/FileCabinet/FileCabinetTable';
import { RezenObjectTypeEnum } from '../openapi/yada';

interface ZenTransactionFileCabinetRouteProps {}

const ZenTransactionFileCabinetRoute: React.FC<ZenTransactionFileCabinetRouteProps> = () => {
  const {
    transaction: { transactionDetailResponse },
  } = useSelector((state: RootState) => state);

  return (
    <ZenRoute
      title={`${getPageTitleForTransaction(
        transactionDetailResponse.data,
      )} File Cabinet - ${transactionDetailResponse.data?.address?.oneLine}`}
    >
      <FileCabinetTable
        dropboxId={transactionDetailResponse.data?.dropboxId!}
        containerType={RezenObjectTypeEnum.Transaction}
        containerId={transactionDetailResponse.data?.id!}
        checklistId={transactionDetailResponse.data?.checklistId!}
        backUrl={window.location.pathname}
      />
    </ZenRoute>
  );
};

export default ZenTransactionFileCabinetRoute;
