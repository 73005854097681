import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Column, Row } from 'react-table';
import DeleteIcon from '../../../assets/img/deleteIconDanger.svg';
import EditIcon from '../../../assets/img/editIcon.svg';
import {
  ParticipantValue,
  ParticipantValueRoleEnum,
  PaymentParticipantValue,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { toggleAddCommissionParticipant } from '../../../slices/QuickActionSlice';
import { ParticipantType } from '../../../types';
import { getAllParticipants } from '../../../utils/AgentHelper';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import { participantRoleDisplayName } from '../../../utils/TransactionHelper';
import PhoneNumberCell from '../../table/Cells/PhoneNumberCell';
import ZenFixedDataTable from '../Table/ZenFixedDataTable';
import ZenIconCircleWithTooltipCell from '../Transaction/ZenIconCircleWithTooltipCell';
import ZenAdd from '../ZenAdd';
import ZenCard from '../ZenCard';
import ZenUserPill from '../ZenUserPill';

interface ZenListingAllParticipantsTableProps {
  transaction: TransactionResponse;
  onEdit(participant: ParticipantValue | PaymentParticipantValue): void;
  onDelete(participant: ParticipantValue | PaymentParticipantValue): void;
  isReadonly: boolean;
}

const ZenListingAllParticipantsTable: React.FC<ZenListingAllParticipantsTableProps> = ({
  transaction,
  onEdit,
  onDelete,
  isReadonly,
}) => {
  const dispatch = useDispatch();
  const participants = getAllParticipants(transaction);
  const realParticipants = participants.filter(
    (participant) => participant.role !== ParticipantValueRoleEnum.Real,
  );

  const columns: Column<ParticipantValue | PaymentParticipantValue>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: ({ row }) => (
          <div>
            {!row.original.externalParticipant ? (
              <Link to={`/people/${row.original.yentaId}`}>
                <ZenUserPill
                  name={getParticipantName(row.original)!}
                  backgroundVariant='background'
                  noWrap
                />
              </Link>
            ) : (
              <ZenUserPill
                name={getParticipantName(row.original)!}
                backgroundVariant='background'
              />
            )}
          </div>
        ),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ value }) => (
          <p className='font-zen-body text-sm text-zen-dark-9 font-normal'>
            {participantRoleDisplayName(value!)}
          </p>
        ),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Email',
        accessor: 'emailAddress',
        Cell: ({ value }) => (
          <p className='font-zen-body text-sm text-zen-dark-9 font-normal'>
            {value || 'N/A'}
          </p>
        ),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        Cell: ({ value }) => (
          <div className='font-zen-body text-sm text-zen-dark-9 font-normal'>
            <PhoneNumberCell phoneNumber={value} />
          </div>
        ),
        disableSortBy: true,
        disableFilters: true,
      },
    ],
    [],
  );

  const columnsWithActions: Column<
    ParticipantValue | PaymentParticipantValue
  >[] = useMemo(() => {
    const updatedColumns: Column<
      ParticipantValue | PaymentParticipantValue
    >[] = [
      ...columns,
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({
          row,
        }: {
          row: Row<ParticipantValue | PaymentParticipantValue>;
        }) =>
          isReadonly ? (
            <ZenIconCircleWithTooltipCell
              tooltipText='View'
              icon={
                <FontAwesomeIcon
                  icon={faEye}
                  className='text-zen-dark-8 text-base'
                  title='view'
                />
              }
              onClick={() => onEdit(row.original)}
            />
          ) : (
            <div className='space-x-3 flex flex-row'>
              <ZenIconCircleWithTooltipCell
                tooltipText='Edit'
                icon={
                  <img
                    src={EditIcon}
                    alt='edit'
                    className='text-zen-dark-8'
                    width={16}
                  />
                }
                onClick={() => onEdit(row.original)}
              />
              <ZenIconCircleWithTooltipCell
                tooltipText='Delete'
                icon={<img src={DeleteIcon} alt='delete' width={14} />}
                onClick={() => onDelete(row.original)}
              />
            </div>
          ),
        disableSortBy: true,
        disableFilters: true,
      },
    ];

    return updatedColumns;
  }, [columns, isReadonly, onDelete, onEdit]);

  return (
    <div className='px-4'>
      <ZenCard
        title='Participants'
        RightComponent={
          !isReadonly ? (
            <div className='flex flex-row items-center space-x-4'>
              <ZenAdd
                text='Add Participants'
                onClick={() => {
                  dispatch(
                    toggleAddCommissionParticipant({
                      agentType: ParticipantType.EXTERNAL_ENTITY,
                      isOpen: true,
                    }),
                  );
                }}
              />
            </div>
          ) : (
            <div />
          )
        }
      >
        <ZenFixedDataTable<ParticipantValue | PaymentParticipantValue>
          columns={columnsWithActions}
          data={realParticipants}
          resourceName='Participant'
          hidePagination
          hideFilters
          headerVariant='light'
        />
      </ZenCard>
    </div>
  );
};

export default ZenListingAllParticipantsTable;
