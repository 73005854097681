import React from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalActionFooterV2 from '../Zen/Modal/ZenSidebarModalActionFooterV2';
import {
  createExperiment,
  getExperimentByName,
} from '../../slices/ExperimentSlice';
import { RootState } from '../../types';

interface ZenCreateExperimentProps {
  isOpen: boolean;
  onClose(isRefreshRequired: boolean): void;
}

interface FormData {
  name: string;
}

const ZenCreateExperiment: React.FC<ZenCreateExperimentProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();
  const dispatch = useDispatch();
  const { isUpdatingExperiment } = useSelector(
    (state: RootState) => state.experiment,
  );

  const onSubmit = async (data: any) => {
    await dispatch(createExperiment({ name: data.name }));
    onClose(true);
  };

  return (
    <ZenSidebarModal
      isOpen={isOpen}
      onClose={() => onClose(false)}
      title='Create Experiment'
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='edit-experiment-group-form'
      >
        <div className='p-5'>
          <div className='mt-3'>
            <ZenControlledTextInput<FormData, 'name'>
              control={control}
              label='Name'
              name='name'
              placeholder='Test Experiment'
              rules={{
                required: 'Name is required',
                validate: async (value) => {
                  const experimentResponse = await dispatch(
                    getExperimentByName(value),
                  );

                  return !!experimentResponse
                    ? 'There is already an experiment with that name. Please use a different name.'
                    : undefined;
                },
              }}
              isRequired
            />
          </div>
        </div>
        <ZenSidebarModalActionFooterV2
          onClose={() => onClose(false)}
          isDisabled={isUpdatingExperiment || isSubmitting}
          isSubmitting={isUpdatingExperiment || isSubmitting}
          submitButtonText='Save'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenCreateExperiment;
