import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGemini } from '../../../hooks/useGemini';
import AnalyticsService from '../../../services/AnalyticsService';
import { toggleDemoMode } from '../../../slices/DemoModeSlice';
import { AnalyticsEventEnum, RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import Hover from '../../Hover';
import ZenToggleRow from '../../Zen/Input/ZenToggleRow';

export const ZenDemoModeMenuItem: React.FC = () => {
  const { enabled } = useSelector((state: RootState) => state.demoMode);
  const dispatch = useDispatch();

  const isGeminiEnabled = useGemini();

  const handleChange = (res: boolean) => {
    dispatch(toggleDemoMode());
    if (res) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.DEMO_MODE_ENABLED,
      );
    } else {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.DEMO_MODE_DISABLED,
      );
    }
  };

  return (
    <Hover
      config={{
        placement: 'right',
      }}
      hoverComponent={
        <p
          className={cn(
            'px-2 whitespace-pre text-sm',
            isGeminiEnabled ? 'font-zen-body-2' : 'font-zen-body',
          )}
        >
          Demo Mode
        </p>
      }
      variant='dark'
    >
      <div
        className={cn(
          'flex flex-row items-center text-primary-light group z-0  rounded-xl justify-start px-2 py-1 my-2 hover:bg-zen-dark-22',
          isGeminiEnabled
            ? 'font-zen-body-2 hover:bg-regent-900'
            : 'font-zen-body hover:bg-zen-dark-21',
        )}
      >
        <span className='p-1 items-center pl-2'>
          <div className='flex flex-row items-center space-x-2 font-semibold'>
            <span className='items-center text-sm'>Demo Mode</span>
            <ZenToggleRow value={enabled} onChange={handleChange} />
          </div>
        </span>
      </div>
    </Hover>
  );
};
