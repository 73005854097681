import ChecklistOverTimeResourceTable from '../components/BrokerDashboard/ChecklistOverTimeResourceTable';
import ChecklistsFollowUpResourceTable from '../components/BrokerDashboard/ChecklistsFollowUpResourceTable';
import ChecklistToReviewResourceTable from '../components/BrokerDashboard/ChecklistToReviewResourceTable';
import ListingsToApproveResourceTable from '../components/BrokerDashboard/ListingsToApproveResourceTable';
import TerminatedWithPendingDocumentToReviewResourceTable from '../components/BrokerDashboard/TerminatedWithPendingDocumentToReviewResourceTable';
import ToApproveCDAResourceTable from '../components/BrokerDashboard/TransactionToApproveCDAResourceTable';
import PageLayout from '../components/PageLayout';
import ZenRoute from '../components/Zen/ZenRoute';

const BrokerDashboardRoute: React.FC = () => {
  return (
    <ZenRoute title='Broker Dashboard'>
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Broker Dashboard', url: '/dashboard' },
        ]}
      >
        <div className='p-4'>
          <ChecklistOverTimeResourceTable />
        </div>
        <div className='p-4'>
          <ChecklistsFollowUpResourceTable />
        </div>
        <div className='p-4'>
          <ToApproveCDAResourceTable />
        </div>
        <div className='p-4'>
          <ChecklistToReviewResourceTable />
        </div>
        <div className='p-4'>
          <ListingsToApproveResourceTable />
        </div>
        <div className='p-4'>
          <TerminatedWithPendingDocumentToReviewResourceTable />
        </div>
      </PageLayout>
    </ZenRoute>
  );
};
export default BrokerDashboardRoute;
