import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { useParams } from 'react-router-dom';
import DetailPageLoader from '../components/DetailPageLoader';
import MilliDateTimeCell from '../components/table/Cells/MilliDateTimeCell';
import ZenCronJobsStatusCell from '../components/Zen/Table/Cell/ZenCronJobsStatusCell';
import ZenFixedDataTable from '../components/Zen/Table/ZenFixedDataTable';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenResourceContainer from '../components/Zen/ZenResourceContainer';
import ZenRoute from '../components/Zen/ZenRoute';
import { JobExecutionResponse } from '../openapi/yenta';
import { getCronJobsHistory } from '../slices/CronJobs';
import { RootState } from '../types';

type Match = {
  jobName: string;
};

export const columns: Array<Column<JobExecutionResponse>> = [
  {
    Header: 'Name',
    accessor: 'jobExecutionName',
    Cell: ({ value }) => value,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'jobExecutionStatus',
    Cell: ({ value }) => <ZenCronJobsStatusCell status={value!} />,
  },
  {
    Header: 'Log',
    accessor: 'errorLog',
    Cell: ({ value }) => value,
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Notes',
    accessor: 'notes',
    Cell: ({ value }) => value,
    disableSortBy: true,
  },
  {
    Header: 'Started At',
    accessor: 'startedAt',
    Cell: ({ value }) => <MilliDateTimeCell date={value!} />,
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Completed At',
    accessor: 'completedAt',
    Cell: ({ value }) => <MilliDateTimeCell date={value!} />,
    disableSortBy: true,
    cardColSize: 6,
  },
];

const ZenCronJobsHistoryRoute: React.FC = () => {
  const { jobName } = useParams() as Match;
  const dispatch = useDispatch();
  const fetchData = useCallback(async () => {
    await dispatch(getCronJobsHistory(jobName));
  }, [dispatch, jobName]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    cronJobs: {
      cronJobHistory,
      fetchCronJobHistoryErrorCode,
      cronJobHistoryLoading,
    },
  } = useSelector((state: RootState) => state);

  return (
    <ZenRoute title='Jobs History'>
      <ZenResourceContainer
        loading={cronJobHistoryLoading}
        isEmpty={!cronJobHistory!.length}
        errorCode={fetchCronJobHistoryErrorCode}
        LoaderComponent={<DetailPageLoader />}
        resourceName='Jobs History'
      >
        <ZenPageLayout
          path={[
            { title: 'Jobs', url: '/jobs' },
            {
              title: `${jobName} History`,
              url: `/jobs/${jobName}/history`,
            },
          ]}
        >
          <div className='p-4'>
            <ZenFixedDataTable<JobExecutionResponse>
              header={`${jobName} History`}
              columns={columns}
              resourceName='Jobs History'
              data={cronJobHistory || []}
              hidePagination
              hideFilters
              initialSort={{ startedAt: 'desc' }}
            />
          </div>
        </ZenPageLayout>
      </ZenResourceContainer>
    </ZenRoute>
  );
};

export default ZenCronJobsHistoryRoute;
