import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionLifecycleStateValue } from '../openapi/arrakis';
import {
  AddAttachedFeeQuickAction,
  AddParticipantsQuickAction,
  QuickActionState,
} from '../types';

export const initialState: QuickActionState = {
  showAdditionalFeeCreateModal: undefined,
  showEditTransactionAddressMlsEscrow: false,
  showCommissionParticipantSidebar: false,
  addCommissionParticipantSidebar: undefined,
  showRecalculateCommissionModal: false,
  activeTransition: undefined,
  showChangeFirmDateModal: false,
};

const QuickActionSlice = createSlice({
  name: 'quickActionSlice',
  initialState,
  reducers: {
    toggleAdditionalFee(
      state,
      action: PayloadAction<AddAttachedFeeQuickAction>,
    ) {
      state.showAdditionalFeeCreateModal = action.payload;
    },
    toggleCommissionSplit(state, action: PayloadAction<boolean>) {
      state.showCommissionParticipantSidebar = action.payload;
    },
    toggleAddCommissionParticipant(
      state,
      action: PayloadAction<AddParticipantsQuickAction>,
    ) {
      state.addCommissionParticipantSidebar = action.payload;
    },
    recalculateCommissionModal(state, action: PayloadAction<boolean>) {
      state.showRecalculateCommissionModal = action.payload;
    },
    toggleEditTransactionAddressMlsEscrow(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.showEditTransactionAddressMlsEscrow = action.payload;
    },
    setActiveTransition(
      state,
      action: PayloadAction<TransactionLifecycleStateValue | undefined>,
    ) {
      state.activeTransition = action.payload;
    },
    toggleShowChangeFirmDateModal(state, action: PayloadAction<boolean>) {
      state.showChangeFirmDateModal = action.payload;
    },
  },
});

export const {
  toggleAdditionalFee,
  toggleCommissionSplit,
  toggleAddCommissionParticipant,
  setActiveTransition,
  recalculateCommissionModal,
  toggleEditTransactionAddressMlsEscrow,
  toggleShowChangeFirmDateModal,
} = QuickActionSlice.actions;

export default QuickActionSlice.reducer;
