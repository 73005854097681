import React from 'react';
import classNames from 'classnames';

interface ZenChecklistProgressCellProps {
  progress: number;
  percentageBar?: boolean;
  total?: number;
  completed?: number;
}

const ZenChecklistProgressCell: React.FC<ZenChecklistProgressCellProps> = ({
  progress,
  percentageBar,
  completed,
  total,
}) => {
  return (
    <div className='w-20'>
      <div className='flex flex-row justify-between items-center pl-2'>
        {!percentageBar ? (
          <div>
            {completed}/{total}
          </div>
        ) : (
          <div>
            <p className='font-primary-regular text-base text-dark'>
              {progress}%
            </p>
          </div>
        )}
      </div>
      <div className='w-20 h-2.5 bg-zen-dark-4 relative m-1 rounded-lg'>
        <div
          className='h-2.5 rounded-lg bg-zen-red-1'
          style={{
            width: `${progress}%`,
            backgroundImage:
              'linear-gradient(-45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)',
            backgroundSize: '10px 10px',
          }}
        />
        <div
          className={classNames(
            'h-2.5 opacity-50 rounded-lg absolute left-0 top-0 right-0 bottom-0',
          )}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
};

export default ZenChecklistProgressCell;
