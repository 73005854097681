import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { flattenDepth, uniq } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChecklistDefinitionDto } from '../../../openapi/sherlock';
import {
  fetchChecklistDefinition,
  fetchItemDefinition,
  toggleCreateTriggerModal,
} from '../../../slices/ChecklistDefinitionSlice';
import { RootState } from '../../../types';
import ResourceContainer from '../../ResourceContainer';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import ZenButton from '../ZenButton';
import EmptyTriggerPlaceholder from './EmptyTriggerPlaceholder';
import TriggerCard from './TriggerCard';
import CreateEditTriggerModal from './CreateEditTriggerModal';

interface ManageTriggerSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  itemId: string;
  checklist?: ChecklistDefinitionDto;
  templateId: string;
}

const ManageTriggerSidebarModal: React.FC<ManageTriggerSidebarModalProps> = ({
  isOpen,
  onClose,
  itemId,
  checklist,
  templateId,
}) => {
  const dispatch = useDispatch();
  const checklistItem = checklist?.items?.find((item) => item?.id === itemId);
  const {
    showCreateTriggerModal,
    showEditTriggerModal,
    checklistDefinitionById,
    itemDefinitionById,
    selectedTrigger,
  } = useSelector((state: RootState) => state.checkListDefinition);

  const checklistDefinitionId = useMemo(() => {
    const conditionPair = flattenDepth(
      (checklistItem?.triggerDefinitions || [])?.map(
        (def) => def?.conditionSets?.definedConditionPairs || [],
      ),
      2,
    );

    const Ids =
      conditionPair
        ?.map((p) => p?.eventSubscription?.checklistDefinitionId!)
        ?.filter((p) => !!p) || [];

    const IdsToFetch = uniq(Ids).filter(
      (id) => !!id && !checklistDefinitionById[id],
    );

    return IdsToFetch;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistItem?.triggerDefinitions]);

  const itemDefinitionId = useMemo(() => {
    const conditionPair = flattenDepth(
      (checklistItem?.triggerDefinitions || [])?.map(
        (def) => def?.conditionSets?.definedConditionPairs || [],
      ),
      2,
    );

    const itemIds =
      conditionPair
        ?.map((p) => p.eventSubscription?.itemDefinitionId!)
        ?.filter((p) => !!p) || [];

    const itemIdsToFetch = uniq(itemIds).filter(
      (id) => !!id && !itemDefinitionById[id],
    );

    return itemIdsToFetch;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistItem?.triggerDefinitions]);

  const fetchChecklist = useCallback(async () => {
    if (checklistDefinitionId.length > 0) {
      await Promise.all(
        checklistDefinitionId?.map((id) =>
          dispatch(fetchChecklistDefinition(id, false)),
        ),
      );
    }
  }, [checklistDefinitionId, dispatch]);

  const fetchItems = useCallback(async () => {
    if (itemDefinitionId.length > 0) {
      await Promise.all(
        itemDefinitionId?.map((id) => dispatch(fetchItemDefinition(id, false))),
      );
    }
  }, [dispatch, itemDefinitionId]);

  useEffect(() => {
    fetchChecklist();
  }, [fetchChecklist]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <ZenSidebarModal
      isOpen={isOpen}
      onClose={onClose}
      customHeader={
        <div className='flex flex-row items-center justify-between px-4 py-3 bg-zen-light-blue border-b border-zen-dark-4'>
          <div className='font-zen-body text-base font-bold text-zen-dark-9'>
            Manage Triggers
          </div>
          <div
            onClick={onClose}
            className='flex bg-zen-dark-5 cursor-pointer items-center justify-center text-white h-5 w-5 rounded-full'
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
      }
    >
      <div className='p-4 pt-0 h-full overflow-hidden font-zen-body'>
        {/* Checklist Item Name & New Trigger Button */}
        <div className='flex items-center justify-between flex-wrap'>
          <div className='mt-4 flex items-center'>
            <div className='w-7 h-7 shrink-0 mr-2 flex justify-center items-center rounded-full bg-zen-dark-10 overflow-hidden'>
              <span className='text-base text-white'>
                {checklistItem?.position! + 1}
              </span>
            </div>
            <p className='font-semibold text-lg text-black'>
              {checklistItem?.name}
            </p>
          </div>
          <div className='mt-4'>
            <ZenButton
              label='New Trigger'
              variant='primary'
              LeftIconComponent={
                <FontAwesomeIcon icon={faPlus} className='mx-1' size='lg' />
              }
              onClick={() => dispatch(toggleCreateTriggerModal(true))}
            />
          </div>
        </div>

        {/* Horizontal Line */}
        <div className='w-full mt-6 border-b border-zen-dark-4' />
        <div className='h-[90%] scrollbar overflow-y-scroll'>
          {/* Trigger Count */}
          <div className='font-semibold text-lg my-6 text-black'>
            {`Triggers (${checklistItem?.triggerDefinitions?.length || 0})`}
          </div>
          <ResourceContainer
            loading={false}
            isEmpty={!checklistItem?.triggerDefinitions?.length}
            resourceName='trigger'
            EmptyComponent={<EmptyTriggerPlaceholder />}
          >
            {checklistItem?.triggerDefinitions?.map((trigger) => (
              <TriggerCard
                key={trigger.id}
                trigger={trigger}
                itemId={checklistItem?.id!}
                templateId={templateId}
              />
            ))}
          </ResourceContainer>
        </div>
      </div>
      {showCreateTriggerModal && (
        <CreateEditTriggerModal
          itemId={checklistItem?.id!}
          templateId={templateId}
        />
      )}
      {showEditTriggerModal && (
        <CreateEditTriggerModal
          itemId={checklistItem?.id!}
          templateId={templateId}
          triggerDefId={selectedTrigger.id!}
        />
      )}
    </ZenSidebarModal>
  );
};

export default ManageTriggerSidebarModal;
