import React from 'react';
import { BankAccountDto } from '../../../openapi/arrakis';
import { displayFormattedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { bankAccountTypeVariant } from '../../../utils/TransactionHelper';
import { cn } from '../../../utils/classUtils';

interface AccountBalanceRowProps {
  bankAccount: BankAccountDto;
}

const ZenAccountBalanceRow: React.FC<AccountBalanceRowProps> = ({
  bankAccount,
}) => {
  return (
    <div className='flex flex-row justify-between items-center'>
      <div className='flex flex-row items-center'>
        <div
          className={cn(
            'w-1 h-6 rounded',
            bankAccountTypeVariant[bankAccount.bankAccountType!],
          )}
        />
        <div className='flex flex-col md:flex-row items-start md:items-center'>
          <p className='font- text-base text-zen-dark-9 px-2'>
            {`${bankAccount.bankName} ${capitalizeEnum(
              bankAccount.bankAccountType!,
            )}`}
          </p>
          <div className='hidden md:block w-px h-4 bg-gray-300' />
          <p className='font-normal font-zen-body text-base text-zen-dark-9 px-2'>
            {`${bankAccount.bankName} - *${bankAccount.accountNumber?.slice(
              -4,
            )}`}
          </p>
        </div>
      </div>
      <p className='font-normal font-zen-body text-base text-zen-dark-9 min-w-max'>
        {displayFormattedAmountWithCurrency(bankAccount.balance)}
      </p>
    </div>
  );
};

export default ZenAccountBalanceRow;
