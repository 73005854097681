import { faArrowRight, faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CreateAndDisburseReferralRequestExternalPaymentMethodEnum } from '../../../../openapi/arrakis';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledDatePickerInput from '../../Input/ZenControlledDatePickerInput';
import ZenControlledHTMLSelectInput from '../../Input/ZenControlledHTMLSelectInput';
import ZenControlledTextAreaInput from '../../Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenButton from '../../ZenButton';
import ZenStickyBottomPagination from '../../ZenStickyBottomPagination';
import withZenCreateReferralTransactionProgress from './withZenCreateReferralTransactionProgress';
import {
  ReferralTransactionFormState,
  ReferralTransactionStepName,
} from './ZenReferralTransactionSteps';

const ZenPaymentInformationStep: StepByStepComponent<
  ReferralTransactionFormState,
  ReferralTransactionStepName
> = ({ form: { control }, onNext, onPrevious }) => {
  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div>
          <p className='text-xl font-zen-body font-semibold text-zen-dark-9 mb-4'>
            Payment Information
          </p>

          <div className='space-y-5 mb-5'>
            <div>
              <ZenControlledDatePickerInput<
                ReferralTransactionFormState,
                'paymentDateSent'
              >
                control={control}
                label='Payment Date Sent'
                name='paymentDateSent'
                shouldUnregister={false}
                placeholder='MM/DD/YYYY'
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    title='calendar'
                    className='text-gray-700 mb-0.5'
                    size='lg'
                  />
                }
              />
            </div>

            <div>
              <ZenControlledHTMLSelectInput<
                ReferralTransactionFormState,
                'paymentMethod'
              >
                control={control}
                label='Payment Method'
                name='paymentMethod'
                placeholder='Representation type'
                options={[
                  {
                    label: 'Select Payment Method',
                    value: '',
                  },
                  {
                    label: 'Check',
                    value:
                      CreateAndDisburseReferralRequestExternalPaymentMethodEnum.Check,
                  },
                  {
                    label: 'Wire',
                    value:
                      CreateAndDisburseReferralRequestExternalPaymentMethodEnum.Wire,
                  },
                ]}
                shouldUnregister={false}
              />
            </div>

            <div>
              <ZenControlledTextInput<
                ReferralTransactionFormState,
                'referenceNumber'
              >
                control={control}
                label='Reference Number'
                name='referenceNumber'
                shouldUnregister={false}
                placeholder='E.g. 123456'
              />
            </div>

            <div>
              <ZenControlledTextInput<
                ReferralTransactionFormState,
                'senderFullName'
              >
                control={control}
                label='Sender Full Name'
                name='senderFullName'
                shouldUnregister={false}
                placeholder='E.g. Andrew Garfield'
              />
            </div>

            <div>
              <ZenControlledTextAreaInput<
                ReferralTransactionFormState,
                'comments'
              >
                control={control}
                shouldUnregister={false}
                name='comments'
                label='Additional Comments'
                placeholder='Add payment comments here'
                rows={2}
              />
            </div>

            <div className='pb-10 pt-5 font-zen-body'>
              <p className='mb-1'>
                I don&apos;t have the payment information right now
              </p>
              <ZenButton
                label='Skip this section'
                onClick={onNext}
                variant='primary-outline'
                RightIconComponent={
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className='text-primary-blue mt-0.5 ml-1'
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
      <ZenStickyBottomPagination
        maxWidth='2xl'
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </div>
  );
};

export default withZenCreateReferralTransactionProgress(
  ZenPaymentInformationStep,
);
