import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps } from '../../types';
import { CustomColorPickerInputSelectOption } from '../CustomColorPickerInput';
import { cn } from '../../utils/classUtils';
import ZenFormErrorMessage from './Input/ZenFormErrorMessage';
import ZenCustomColorPickerInput from './ZenCustomColorPickerInput';

export interface ZenControlledColorSelectorInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  options: CustomColorPickerInputSelectOption[];
  disabled?: boolean;
  isRequired?: boolean;
}

const ZenControlledColorSelectorInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  options,
  disabled = false,
  tooltipIndex,
  shouldUnregister = true,
  isRequired,
  ...rest
}: ZenControlledColorSelectorInputProps<TFieldValues, TName>) => {
  const {
    field: { onChange, value, name },
    fieldState: { error, invalid },
  } = useController<TFieldValues, TName>(rest);

  return (
    <div className='w-full'>
      <div className='flex flex-col space-y-2'>
        {label && (
          <label className='inline-block' htmlFor={name}>
            <span
              className={cn(
                'font-zen-body font-semibold text-base',
                invalid ? 'text-zen-danger' : 'text-zen-dark-9',
              )}
            >
              {label}
            </span>
            {isRequired && <span className='text-zen-danger'>*</span>}
          </label>
        )}
        <div
          className='flex flex-row items-center justify-evenly space-x-1 md:space-x-3 w-full scrollbar overflow-x-auto mt-5'
          data-tooltip-index={tooltipIndex}
        >
          {options.map((option) => (
            <ZenCustomColorPickerInput
              key={option.label}
              isActive={option.value === value}
              item={option}
              onChange={onChange}
              disabled={disabled}
            />
          ))}
        </div>
      </div>
      {error && <ZenFormErrorMessage message={error.message} />}
    </div>
  );
};

export default ZenControlledColorSelectorInput;
