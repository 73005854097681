import { faAddressBook, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filter, isEmpty, omit } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  I_DONT_KNOW_YET,
  I_DONT_KNOW_YET_EMAIL,
  NA_100_PERCENT_PAID_FOR_WITH_DEPOSIT_EMAIL,
} from '../../../../constants/TransactionConstants';
import useDidUpdateEffect from '../../../../hooks/useDidUpdateEffect';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useUpdateCommissionPayerChanges } from '../../../../hooks/useUpdateCommissionPayerChanges';
import {
  CommissionPayerDisplayValueRoleEnum,
  ExternalParticipantInfoRoleEnum,
  TransactionBuilderResponse,
} from '../../../../openapi/arrakis';
import {
  DirectoryEntryResponseTypeEnum,
  DirectoryVendorResponseRoleEnum,
} from '../../../../openapi/yenta';
import { DirectoryCommonEntityResponse } from '../../../../query/directory/useDirectory';
import {
  addCommissionPayer,
  fetchCommissionPayerRoles,
} from '../../../../slices/TransactionBuilderSlice';
import {
  AppDispatch,
  FeatureFlagTypeEnum,
  ISelectOption,
  RootState,
} from '../../../../types';
import { isCanadianUser } from '../../../../utils/AgentHelper';
import { getParticipantName } from '../../../../utils/ParticipantHelper';
import { getTransactionRealTitleName } from '../../../../utils/RealTitleUtils';
import {
  capitalizeEnum,
  getValueOrEmptyString,
} from '../../../../utils/StringUtils';
import {
  EMAIL_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../../utils/Validations';
import { getDirectoryRoleFromParticipantRolee } from '../../../Directory/DirectoryUtils';
import ZenRoleDirectoryForm from '../../../Directory/ZenRoleDirectoryForm';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenContactListSidebar from '../../../Directory/ZenContactListSidebar';
import ZenControlledCheckboxInput from '../../Input/ZenControlledCheckboxInput';
import ZenControlledHTMLSelectInput from '../../Input/ZenControlledHTMLSelectInput';
import ZenControlledPhoneNumberInput from '../../Input/ZenControlledPhoneNumberInput';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenButton from '../../ZenButton';
import { createTransactionCommissionPayerStepHeader } from '../../../../utils/TransactionUtils';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
  Match,
} from './ZenCreateTransactionSteps';
import { FieldArrayPrefix } from './ZenTransactionBuyerAndSellerStep';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';

const ZenCommissionPayerStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({
  form: { control, trigger, setValue, watch, clearErrors, getValues },
  onPrevious,
  onNext,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { transactionBuilderId } = useParams<Match>();
  const [loading, setLoading] = useState<boolean>(false);
  const [
    commissionPayer,
    commissionPayerParticipant,
    otherBrokerageInfo,
  ] = watch([
    'commissionPayerInfo',
    'commissionPayerInfo.participant',
    'otherBrokerageInfo',
  ]);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [roleSibebarOpen, setRoleSidebarOpen] = useState<boolean>(false);
  const [currentFormContact, setCurrentFormContact] = useState<
    DirectoryCommonEntityResponse | undefined
  >();
  const {
    auth: { userDetail },
    transactionBuilder: { transactionBuilder, commissionPayerRoles },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(fetchCommissionPayerRoles(transactionBuilderId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDoubleEnderEnabled = useFeatureFlag(FeatureFlagTypeEnum.DOUBLE_ENDER);
  const LISTING_BROKERAGE = 'Listing Brokerage';
  const currentCommissionPayerRole = watch('commissionPayerInfo.role');
  const isRoleListingBrokerage =
    currentCommissionPayerRole === LISTING_BROKERAGE;
  const doubleEnderAgent =
    agentById[transactionBuilder?.doubleEnderInfo?.doubleEnderAgentId!];
  const isIDontKnowYetChecked = watch(
    'commissionPayerInfo.isInfoUnknown',
  )?.includes(I_DONT_KNOW_YET);
  const selectedRoleEnum = commissionPayerRoles?.find(
    (role) => role.displayName === commissionPayer.role,
  )?.role;
  const buyerLawyerAgent = transactionBuilder?.otherParticipants?.find(
    (participant) =>
      participant.role === ExternalParticipantInfoRoleEnum.BuyersLawyer,
  );

  const sellerLawyerAgent = transactionBuilder?.otherParticipants?.find(
    (participant) =>
      participant.role === ExternalParticipantInfoRoleEnum.SellersLawyer,
  );

  const isOtherAgent =
    selectedRoleEnum === CommissionPayerDisplayValueRoleEnum.OtherAgent;
  const isBuyerLawyer =
    selectedRoleEnum === CommissionPayerDisplayValueRoleEnum.BuyersLawyer;
  const isSellerLawyer =
    selectedRoleEnum === CommissionPayerDisplayValueRoleEnum.SellersLawyer;

  const isNoInformationCheckboxVisible = !isOtherAgent;
  const isOneRealTitleEnabled = !!transactionBuilder?.titleInfo?.useRealTitle;

  const roleOptions = [
    { label: 'Choose...', value: '' },
    ...(commissionPayerRoles || []).map((option) => ({
      label: option.displayName!,
      value: option.displayName!,
    })),
  ];

  const isBuyerLawyerPresent = !!buyerLawyerAgent;
  const isSellerLawyerPresent = !!sellerLawyerAgent;

  const participantsWithSameRole: ISelectOption[] = useMemo(
    () => [
      {
        label: 'Choose a participant...',
        value: '',
      },
      ...(transactionBuilder?.allParticipants
        // @ts-ignore
        ?.filter((participant) => participant?.role === selectedRoleEnum)
        .map((participant) => ({
          // @ts-ignore
          label: `${getParticipantName(participant)} - (${capitalizeEnum(
            participant?.role!,
          )})`,
          value: participant?.id!,
        })) || []),
    ],
    [selectedRoleEnum, transactionBuilder?.allParticipants],
  );

  const canChooseParticipants =
    participantsWithSameRole.length > 1 &&
    !(isOtherAgent || isSellerLawyer || isBuyerLawyer);

  const readOnly =
    (canChooseParticipants && !!commissionPayer?.participant) ||
    isOtherAgent ||
    (isBuyerLawyer && isBuyerLawyerPresent) ||
    (isSellerLawyer && isSellerLawyerPresent);

  const isParticipantRequired = isEmpty(
    filter(
      omit(commissionPayer, ['participant', 'role', 'isInfoUnknown']),
      (p) => !!p,
    ),
  );

  const handleSidebarToggle = () => () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleSave = (
    _fieldArrayPrefix?: FieldArrayPrefix | string,
    contact?: DirectoryCommonEntityResponse,
  ) => {
    setValue(
      'commissionPayerInfo.firstName',
      contact?.linkedPersons?.[0]?.firstName! || contact?.firstName!,
    );
    setValue(
      'commissionPayerInfo.lastName',
      contact?.linkedPersons?.[0]?.lastName! || contact?.lastName!,
    );
    setValue('commissionPayerInfo.email', contact?.emailAddress!);
    setValue('commissionPayerInfo.phoneNumber', contact?.phoneNumber!);
    setValue('commissionPayerInfo.companyName', contact?.name!);
  };

  const handleRoleSidebarToggle = () => async () => {
    const values = getValues(`commissionPayerInfo`);
    // Fallback as Client role if role is not found
    const role = (getDirectoryRoleFromParticipantRolee(
      currentCommissionPayerRole,
    ) ||
      DirectoryVendorResponseRoleEnum.Client) as DirectoryVendorResponseRoleEnum;
    setCurrentFormContact({
      emailAddress: values?.email,
      name: values?.companyName,
      phoneNumber: values?.phoneNumber,
      firstName: values?.firstName,
      lastName: values?.lastName,
      role,
    });
    setRoleSidebarOpen((prevState) => !prevState);
  };

  const resetCommissionPayerFormDetails = () => {
    setValue(
      'commissionPayerInfo.companyName',
      isOneRealTitleEnabled
        ? `${getTransactionRealTitleName(transactionBuilder?.address?.state)}`
        : '',
    );
    setValue('commissionPayerInfo.firstName', '');
    setValue('commissionPayerInfo.lastName', '');
    setValue('commissionPayerInfo.email', '');
    setValue('commissionPayerInfo.phoneNumber', '');
  };

  const resetAllCommissionPayerDetails = () => {
    resetCommissionPayerFormDetails();
    setValue('commissionPayerInfo.isInfoUnknown', []);
    setValue('commissionPayerInfo.participant', '');
  };

  useDidUpdateEffect(() => {
    if (!isIDontKnowYetChecked && !isOtherAgent) {
      resetAllCommissionPayerDetails();
    } else if (isOtherAgent) {
      setValue('commissionPayerInfo.isInfoUnknown', []);
      setValue(
        'commissionPayerInfo.companyName',
        getValueOrEmptyString(otherBrokerageInfo?.companyName),
      );
      setValue(
        'commissionPayerInfo.firstName',
        getValueOrEmptyString(otherBrokerageInfo?.firstName),
      );
      setValue(
        'commissionPayerInfo.lastName',
        getValueOrEmptyString(otherBrokerageInfo?.lastName),
      );
      setValue(
        'commissionPayerInfo.email',
        getValueOrEmptyString(otherBrokerageInfo?.email),
      );
      setValue(
        'commissionPayerInfo.phoneNumber',
        getValueOrEmptyString(otherBrokerageInfo?.phoneNumber),
      );
      setValue(
        'commissionPayerInfo.participant',
        getValueOrEmptyString(participantsWithSameRole[1]?.value),
      );
    }
  }, [isIDontKnowYetChecked, selectedRoleEnum]);

  useDidUpdateEffect(() => {
    if (!(isOtherAgent || isBuyerLawyer || isSellerLawyer)) {
      resetCommissionPayerFormDetails();
      clearErrors();
    }
  }, [commissionPayer.participant]);

  useDidUpdateEffect(() => {
    if (isDoubleEnderEnabled && isRoleListingBrokerage && doubleEnderAgent) {
      setValue('commissionPayerInfo', {
        isInfoUnknown: [],
        role: currentCommissionPayerRole,
        companyName: 'Real Brokerage',
        firstName: doubleEnderAgent.firstName!,
        lastName: doubleEnderAgent.lastName!,
        email: doubleEnderAgent.emailAddress!,
        phoneNumber: doubleEnderAgent.phoneNumber!,
        participant: undefined,
      });
    }
  }, [
    isDoubleEnderEnabled,
    currentCommissionPayerRole,
    doubleEnderAgent,
    setValue,
    isRoleListingBrokerage,
  ]);

  useEffect(() => {
    if (!isIDontKnowYetChecked) {
      if (isBuyerLawyer && buyerLawyerAgent) {
        clearErrors();
        setValue('commissionPayerInfo', {
          isInfoUnknown: [],
          role: currentCommissionPayerRole,
          companyName: buyerLawyerAgent.companyName!,
          firstName: buyerLawyerAgent.firstName!,
          lastName: buyerLawyerAgent.lastName!,
          email: buyerLawyerAgent.email!,
          phoneNumber: buyerLawyerAgent.phoneNumber!,
          participant: buyerLawyerAgent.id,
        });
      } else if (isSellerLawyer && sellerLawyerAgent) {
        clearErrors();
        setValue('commissionPayerInfo', {
          isInfoUnknown: [],
          role: currentCommissionPayerRole,
          companyName: sellerLawyerAgent.companyName!,
          firstName: sellerLawyerAgent.firstName!,
          lastName: sellerLawyerAgent.lastName!,
          email: sellerLawyerAgent.email!,
          phoneNumber: sellerLawyerAgent.phoneNumber!,
          participant: sellerLawyerAgent.id,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCommissionPayerRole,
    setValue,
    isBuyerLawyer,
    isSellerLawyer,
    isIDontKnowYetChecked,
  ]);

  const handleCommissionPayerStep = async () => {
    const isValid = await trigger();
    if (isValid) {
      setLoading(true);
      let res: TransactionBuilderResponse | undefined;

      if (isIDontKnowYetChecked) {
        res = await dispatch(
          addCommissionPayer(
            transactionBuilderId!,
            undefined,
            selectedRoleEnum,
            'Enter',
            'When Firm',
            'Fill in when firm',
            commissionPayer.role === 'N/A - 100% paid for with deposit'
              ? NA_100_PERCENT_PAID_FOR_WITH_DEPOSIT_EMAIL
              : I_DONT_KNOW_YET_EMAIL,
            '11111111111',
          ),
        );
      } else if (commissionPayer.participant) {
        res = await dispatch(
          addCommissionPayer(
            transactionBuilderId!,
            commissionPayer.participant,
          ),
        );
      } else {
        res = await dispatch(
          addCommissionPayer(
            transactionBuilderId!,
            undefined,
            selectedRoleEnum,
            commissionPayer.firstName,
            commissionPayer.lastName,
            commissionPayer.companyName,
            commissionPayer.email,
            commissionPayer.phoneNumber,
          ),
        );
      }

      setLoading(false);
      if (res) {
        onNext();
      }
    }
  };

  useUpdateCommissionPayerChanges(transactionBuilder!, setValue);

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <p className='text-xl font-zen-title font-medium text-zen-dark-9 mb-4'>
          {createTransactionCommissionPayerStepHeader(
            isCanadianUser(userDetail?.accountCountry!),
          )}
        </p>
        <div className='space-y-4'>
          <div>
            <ZenControlledHTMLSelectInput<
              CreateTransactionFormState,
              'commissionPayerInfo.role'
            >
              control={control}
              label={isOneRealTitleEnabled ? 'Title Escrow' : 'Role'}
              name='commissionPayerInfo.role'
              placeholder={`Select ${
                isOneRealTitleEnabled ? 'Title Escrow' : 'role'
              }`}
              options={roleOptions}
              shouldUnregister={false}
              rules={{
                required: isOneRealTitleEnabled
                  ? 'Title Escrow is required'
                  : 'Role is required',
                validate: () => {
                  if (!selectedRoleEnum) {
                    return isOneRealTitleEnabled
                      ? 'Title Escrow is required'
                      : 'Role is required';
                  }

                  return undefined;
                },
              }}
              isRequired
            />
          </div>
          {isNoInformationCheckboxVisible && (
            <ZenControlledCheckboxInput<
              CreateTransactionFormState,
              `commissionPayerInfo.isInfoUnknown`
            >
              label=''
              name='commissionPayerInfo.isInfoUnknown'
              shouldUnregister={false}
              options={[
                {
                  label: capitalizeEnum(I_DONT_KNOW_YET),
                  value: I_DONT_KNOW_YET,
                },
              ]}
              control={control}
            />
          )}

          {!isIDontKnowYetChecked && (
            <>
              {canChooseParticipants && (
                <>
                  <div>
                    <ZenControlledHTMLSelectInput<
                      CreateTransactionFormState,
                      'commissionPayerInfo.participant'
                    >
                      name='commissionPayerInfo.participant'
                      control={control}
                      label='Commission Payer Participant'
                      placeholder='Select Participant'
                      options={participantsWithSameRole}
                      shouldUnregister={false}
                      isRequired={isParticipantRequired}
                      rules={{
                        validate: (val: string | undefined) => {
                          if (isParticipantRequired && !val) {
                            return 'Please choose a participant or fill details below.';
                          }
                          return undefined;
                        },
                      }}
                    />
                  </div>
                  <div className='flex flex-row items-center justify-center pt-2'>
                    <div className='h-0.5 w-1/3 bg-gray-100' />
                    <div className='font-zen-body font-medium text-zen-dark-6 px-7 my-auto'>
                      OR
                    </div>
                    <div className='h-0.5 w-1/3 bg-gray-100' />
                  </div>
                </>
              )}
              <div className='pt-2 font-zen-body font-normal text-zen-dark-6'>
                Please enter the contact information for the person or company
                to whom the transaction documents and/or invoices should be
                sent.
              </div>
              {currentCommissionPayerRole && !commissionPayerParticipant && (
                <div className='whitespace-nowrap mr-1 flex justify-end -mt-10'>
                  <ZenButton
                    label='Directory'
                    variant='primary-link'
                    LeftIconComponent={<FontAwesomeIcon icon={faAddressBook} />}
                    onClick={handleSidebarToggle()}
                    zeroPadding
                    fontstyle
                  />
                </div>
              )}
              <div>
                <ZenControlledTextInput<
                  CreateTransactionFormState,
                  'commissionPayerInfo.companyName'
                >
                  control={control}
                  label={
                    isOneRealTitleEnabled
                      ? `${getTransactionRealTitleName(
                          transactionBuilder?.address?.state,
                        )}`
                      : 'Company Name'
                  }
                  name='commissionPayerInfo.companyName'
                  shouldUnregister={false}
                  placeholder={
                    isOneRealTitleEnabled ? 'E.g. Real Venture' : 'E.g. John'
                  }
                  readOnly={readOnly}
                  rules={{
                    required:
                      !commissionPayer.participant &&
                      `${
                        isOneRealTitleEnabled
                          ? `${getTransactionRealTitleName(
                              transactionBuilder?.address?.state,
                            )}`
                          : 'Company Name'
                      } is required`,
                  }}
                  isRequired={!commissionPayer.participant}
                />
              </div>
              <div className='flex flex-row space-x-4'>
                <ZenControlledTextInput<
                  CreateTransactionFormState,
                  'commissionPayerInfo.firstName'
                >
                  control={control}
                  label='First Name'
                  name='commissionPayerInfo.firstName'
                  shouldUnregister={false}
                  readOnly={readOnly}
                  placeholder='E.g. John'
                  rules={{
                    required:
                      !commissionPayer.participant && 'First name is required',
                  }}
                  maxLength={50}
                  isRequired={!commissionPayer.participant}
                />
                <ZenControlledTextInput<
                  CreateTransactionFormState,
                  'commissionPayerInfo.lastName'
                >
                  control={control}
                  label='Last Name'
                  name='commissionPayerInfo.lastName'
                  shouldUnregister={false}
                  readOnly={readOnly}
                  placeholder='E.g. Doe'
                  rules={{
                    required:
                      !commissionPayer.participant && 'Last name is required',
                  }}
                  maxLength={50}
                  isRequired={!commissionPayer.participant}
                />
              </div>
              <div className='flex flex-row space-x-4'>
                <ZenControlledTextInput<
                  CreateTransactionFormState,
                  'commissionPayerInfo.email'
                >
                  control={control}
                  label='Email'
                  name='commissionPayerInfo.email'
                  shouldUnregister={false}
                  readOnly={readOnly}
                  placeholder='E.g. John@example.com'
                  rules={{
                    required:
                      !commissionPayer.participant && 'Email is required',
                    ...EMAIL_VALIDATIONS,
                  }}
                  isRequired={!commissionPayer.participant}
                />
                <ZenControlledPhoneNumberInput<
                  CreateTransactionFormState,
                  'commissionPayerInfo.phoneNumber'
                >
                  control={control}
                  label='Phone Number'
                  name='commissionPayerInfo.phoneNumber'
                  shouldUnregister={false}
                  readOnly={readOnly}
                  placeholder='E.g. +1 (123) 456 789'
                  rules={{
                    required:
                      !commissionPayer.participant &&
                      'Phone number is required',
                    ...PHONE_NUMBER_VALIDATIONS,
                  }}
                  isRequired={!commissionPayer.participant}
                />
              </div>
              {currentCommissionPayerRole && !commissionPayerParticipant && (
                <div className='flex items-end justify-end'>
                  <ZenButton
                    label='Add to My Directory'
                    variant='primary-link'
                    LeftIconComponent={
                      <FontAwesomeIcon
                        icon={faPlus}
                        className='text-primary-blue cursor-pointer'
                      />
                    }
                    onClick={handleRoleSidebarToggle()}
                    fontstyle
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              isDisabled={loading}
              label='Next'
              onClick={handleCommissionPayerStep}
            />
          </div>
        </div>
      </div>
      {isSidebarOpen && (
        <ZenContactListSidebar
          participantRole={getDirectoryRoleFromParticipantRolee(
            currentCommissionPayerRole,
          )}
          isOpen={isSidebarOpen}
          onClose={handleSidebarToggle()}
          onSave={handleSave}
        />
      )}
      {roleSibebarOpen && (
        <ZenRoleDirectoryForm
          isOpen={roleSibebarOpen}
          existingVendorOrPerson={currentFormContact}
          mode='populate'
          onClose={handleRoleSidebarToggle()}
          isExternal={
            getDirectoryRoleFromParticipantRolee(currentCommissionPayerRole) !==
            'CLIENT'
          }
          existingType={
            getDirectoryRoleFromParticipantRolee(currentCommissionPayerRole) !==
            'CLIENT'
              ? DirectoryEntryResponseTypeEnum.Vendor
              : DirectoryEntryResponseTypeEnum.Person
          }
        />
      )}
    </div>
  );
};

export default withCreateTransactionProgress(ZenCommissionPayerStep);
