import { useSelector } from 'react-redux';
import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../../routes/AgentWebsiteOnboardingRoute';
import { RootState } from '../../../types';
import { validateSlug } from '../../../utils/AgentHelper';
import { formatPhoneNumber } from '../../../utils/StringUtils';
import { TEXT_CONTENT_VALIDATIONS } from '../../../utils/Validations';
import ControlledProfileImageUpload from '../../ControlledProfileImageUpload';
import ControlledToggleInputV7 from '../../ControlledToggleInputV7';
import ControlledWebsiteURLTextInputV7 from '../../ControlledWebsiteURLTextInputV7';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';

const AgentWebsiteOnboardingContactInfo: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ form: { control } }) => {
  const {
    detailResponse: { data: detail },
  } = useSelector((state: RootState) => state.agentDetail);

  return (
    <div className='p-4'>
      <div className='pb-5'>
        <ControlledProfileImageUpload
          name='photo'
          control={control}
          shouldUnregister={false}
          label='Profile photo'
          personName={detail?.fullName!}
        />
      </div>
      <div className='grid lg:grid-cols-2 lg:gap-20 grid-cols-1 gap-5'>
        <div>
          <ControlledWebsiteURLTextInputV7<
            AgentWebsiteOnboardingFormData,
            'slug'
          >
            name='slug'
            label='Real website'
            shouldUnregister={false}
            leftWebsiteSegment='www.joinreal.com/'
            control={control}
            rules={{
              required: 'Slug is required',
              validate: (slug: string | undefined) =>
                !!slug && detail?.slug !== slug
                  ? validateSlug(slug)
                  : undefined,
              ...TEXT_CONTENT_VALIDATIONS,
            }}
          />
          <div className='mt-5'>
            <p className='font-primary-regular text-dark'>Email</p>
            <p className='font-primary-regular text-dark'>
              {detail?.emailAddress}
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-primary-regular text-dark'>Phone</p>
            <p className='font-primary-regular text-dark'>
              {formatPhoneNumber(detail?.phoneNumber)}
            </p>
          </div>
          <div className='mt-5'>
            <ControlledToggleInputV7<
              AgentWebsiteOnboardingFormData,
              'showReferralLink'
            >
              label='Show agent referral link'
              shouldUnregister={false}
              name='showReferralLink'
              control={control}
            />
          </div>
        </div>
        <div>
          <p className='mb-1'>Social media</p>
          <div>
            <ControlledWebsiteURLTextInputV7<
              AgentWebsiteOnboardingFormData,
              'instagramURL'
            >
              control={control}
              shouldUnregister={false}
              name='instagramURL'
              leftWebsiteSegment='www.Instagram.com/'
              rules={{ ...TEXT_CONTENT_VALIDATIONS }}
            />
          </div>
          <div className='mt-5'>
            <ControlledWebsiteURLTextInputV7<
              AgentWebsiteOnboardingFormData,
              'facebookURL'
            >
              control={control}
              shouldUnregister={false}
              name='facebookURL'
              leftWebsiteSegment='www.facebook.com/'
              rules={{ ...TEXT_CONTENT_VALIDATIONS }}
            />
          </div>
          <div className='mt-5'>
            <ControlledWebsiteURLTextInputV7<
              AgentWebsiteOnboardingFormData,
              'linkedInURL'
            >
              control={control}
              shouldUnregister={false}
              name='linkedInURL'
              leftWebsiteSegment='www.linkedin.com/'
              rules={{ ...TEXT_CONTENT_VALIDATIONS }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingContactInfo;
