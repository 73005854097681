import {
  faLoader,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useFormContext } from 'react-hook-form-v7';
import { cn } from '../../utils/classUtils';
import { stripTags } from '../../utils/MortgageUtils';
import ZenPill from '../Zen/ZenPill';

interface MortgageApplicationQuestionHeaderProps {
  title?: string;
  subtitle?: string;
  titleClassName?: string;
  hideDivider?: boolean;
}

const MortgageApplicationQuestionHeader: React.FC<MortgageApplicationQuestionHeaderProps> = ({
  title,
  subtitle,
  titleClassName,
  hideDivider,
}) => {
  const { formState } = useFormContext();
  const strippedTitle = stripTags(title);
  const strippedSubtitle = stripTags(subtitle);
  const isDirty = !formState.isSubmitting && formState.isDirty;

  return (
    <div className='mb-4'>
      {!!title && (
        <div className='w-full flex items-center'>
          <p
            className={cn(
              'shrink-0 font-zen-body text-lg text-zen-dark-6',
              titleClassName,
            )}
          >
            {strippedTitle}
          </p>
          {!hideDivider && (
            <div className='flex items-center w-full'>
              {isDirty && (
                <div className='ml-4'>
                  <ZenPill
                    title='Unsaved Changes'
                    variant='warning'
                    textStyle='text-sm py-1 pl-1'
                    leftIcon={
                      <FontAwesomeIcon icon={faTriangleExclamation} size='lg' />
                    }
                    border={false}
                  />
                </div>
              )}
              {formState.isSubmitting && (
                <div className='ml-4'>
                  <ZenPill
                    title='Saving'
                    variant='primary'
                    textStyle='text-sm py-1 pl-1'
                    leftIcon={
                      <FontAwesomeIcon icon={faLoader} size='lg' spin />
                    }
                    border={false}
                  />
                </div>
              )}
              <div className='ml-4 flex-grow h-px bg-zen-light-gray-2' />
            </div>
          )}
        </div>
      )}
      {!!subtitle && (
        <div className='mb-3'>
          <div className='font-zen-body text-sm text-zen-dark-6 mt-2'>
            {strippedSubtitle}
          </div>
        </div>
      )}
    </div>
  );
};

export default MortgageApplicationQuestionHeader;
