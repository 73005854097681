import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export interface ZenExternalParticipantWarningProps {}

const ZenExternalParticipantWarning: React.FC<ZenExternalParticipantWarningProps> = () => {
  return (
    <div className='flex flex-row space-x-2 items-center bg-zen-warning-light text-zen-warning-dark p-2'>
      <FontAwesomeIcon icon={regular('triangle-exclamation')} />
      <span className='font-zen-body'>
        External participant is an agent who is not licensed with Real Broker.
      </span>
    </div>
  );
};

export default ZenExternalParticipantWarning;
