import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import ZenButton from '../../Zen/ZenButton';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import ControlledToggleInputV7 from '../../ControlledToggleInputV7';
import { SwitchVariant } from '../../CustomSwitch';
import {
  fetchWealthPlanCommsSettings,
  updateWealthPlanCommsSettings,
} from '../../../slices/WealthManagementSlice';
import { RootState } from '../../../types';

interface WealthPlanNotificationProps {
  isOpen: boolean;
  close: () => void;
}

interface FormData {
  enableEmails: boolean;
  enableNotifications: boolean;
}

export const WealthPlanNotification: React.FC<WealthPlanNotificationProps> = ({
  isOpen,
  close,
}) => {
  const {
    wealthManagement: { commsSettings },
  } = useSelector((state: RootState) => state);

  const { control, handleSubmit, setValue } = useForm<FormData>();

  const dispatch = useDispatch();

  const onSubmit = (data: FormData) => {
    dispatch(updateWealthPlanCommsSettings(data));
    close();
  };

  useEffect(() => {
    dispatch(fetchWealthPlanCommsSettings());
  }, [dispatch]);

  useEffect(() => {
    if (!!commsSettings) {
      setValue('enableEmails', commsSettings?.data?.enableEmails!);
      setValue(
        'enableNotifications',
        commsSettings?.data?.enableNotifications!,
      );
    }
  }, [setValue, commsSettings]);

  return (
    <ZenSidebarModal
      title='WealthPlan Notification Settings'
      subtitle='Get notified when an external agent opens their WealthPlan email, views or edits their WealthPlan'
      isOpen={isOpen}
      onClose={close}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='w-full h-full'>
          <div
            className='mx-5 my-8 border rounded-xl border-gray-300 divide-y'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='flex flex-row items-center p-2'>
              <div className='py-6 px-4 flex flex-row items-center gap-4'>
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <ControlledToggleInputV7<FormData, 'enableEmails'>
                name='enableEmails'
                control={control}
                defaultValue={commsSettings?.data?.enableEmails}
                variant={SwitchVariant.zen}
                label='Allow Email Notification'
                leftIcon={
                  <FontAwesomeIcon
                    icon={faXmark}
                    className='text-primary-blue'
                    size='sm'
                  />
                }
                rightIcon={
                  <FontAwesomeIcon
                    icon={faCheck}
                    className='text-primary-blue'
                    size='sm'
                  />
                }
              />
            </div>
            <div className='flex flex-row items-center p-2'>
              <div className='py-6 px-4 flex flex-row items-center gap-4'>
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <ControlledToggleInputV7<FormData, 'enableNotifications'>
                name='enableNotifications'
                control={control}
                defaultValue={commsSettings?.data?.enableNotifications}
                variant={SwitchVariant.zen}
                label='Allow Push Notification'
                leftIcon={
                  <FontAwesomeIcon
                    icon={faXmark}
                    className='text-primary-blue'
                    size='sm'
                  />
                }
                rightIcon={
                  <FontAwesomeIcon
                    icon={faCheck}
                    className='text-primary-blue'
                    size='sm'
                  />
                }
              />
            </div>
          </div>
        </div>
        <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-end items-center left-0 right-0 px-3 absolute py-2 w-full'>
          <div className='w-44'>
            <ZenButton
              label='Cancel'
              onClick={close}
              variant='primary-outline'
              isFullWidth
            />
          </div>
          <div className='w-44'>
            <ZenButton label='Save' type='submit' isFullWidth />
          </div>
        </div>
      </form>
    </ZenSidebarModal>
  );
};
