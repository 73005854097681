import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import Select, { GroupTypeBase, Styles } from 'react-select';

import ZenFormErrorMessage from '../../Zen/Input/ZenFormErrorMessage';
import { cn } from '../../../utils/classUtils';

export interface CustomSelectOptions {
  value: string;
  label: string;
  color: string;
  disabled?: boolean;
}

interface ControlledCustomSelectInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label: string;
  placeholder: string;
  options: CustomSelectOptions[];
  styles?: Partial<Styles<any, boolean, GroupTypeBase<any>>>;
  disabled?: boolean;
  readOnly?: boolean;
  isRequired?: boolean;
}

const ZenControlledCustomSelectInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  placeholder = 'Select',
  disabled = false,
  readOnly,
  options,
  styles,
  shouldUnregister = true,
  isRequired,
  ...rest
}: ControlledCustomSelectInputV7Props<TFieldValues, TName>) => {
  return (
    <div className='space-y-1 w-full'>
      <Controller
        shouldUnregister={shouldUnregister}
        {...rest}
        render={({
          field: { onChange, value, name, onBlur },
          fieldState: { error, invalid },
        }) => (
          <div className='space-y-1 w-full'>
            {label && (
              <>
                <label
                  className={cn(
                    'inline-block font-zen-body font-semibold',
                    invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                  )}
                  htmlFor={name}
                >
                  {label}
                </label>
                {isRequired && <span className='text-zen-danger'>*</span>}
              </>
            )}

            <Select
              styles={styles}
              placeholder={placeholder}
              value={value}
              name={name}
              isDisabled={disabled || readOnly}
              defaultValue={value}
              options={options}
              onChange={onChange}
              onBlur={onBlur}
              className='react-select-container'
              classNamePrefix='react-select'
              inputId={name}
            />

            {error && <ZenFormErrorMessage message={error.message} />}
          </div>
        )}
      />
    </div>
  );
};
export default ZenControlledCustomSelectInput;
