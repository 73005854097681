import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ZenResourceLinkCard from '../../../ZenResourceLinkCard';

interface CreateCompanyProps {}

const ZenCompaniesLink: React.FC<CreateCompanyProps> = () => {
  return (
    <div>
      <ZenResourceLinkCard
        title='Companies'
        icon={solid('apartment')}
        iconSize='2x'
        href='/companies'
      />
    </div>
  );
};

export default ZenCompaniesLink;
