import { some } from 'lodash';
import {
  AgentResponse,
  OfficePreviewResponseTransactionTypeEnum,
} from '../openapi/yenta';

export const showSingleCheckPreference = (
  agentDetails?: AgentResponse,
): boolean => {
  if (!agentDetails) {
    return false;
  }
  return some(
    agentDetails.offices || [],
    (office) =>
      office.transactionType ===
      OfficePreviewResponseTransactionTypeEnum.SplitCheck,
  );
};
