import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../constants/TableConstants';
import {
  AgentControllerApi,
  AgentInfo,
  UpdatePersonalAccountRequest,
} from '../../../openapi/yenta';
import { useUpdatePersonalAccount } from '../../../query/agent/useAgent';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { AppDispatch, ISelectOption, RootState } from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenControlledAsyncSelectInput from '../../Zen/Input/ZenControlledAsyncSelectInput';
import ZenSidebarModalForm from '../../Zen/ZenSidebarModalForm';

interface AddPersonalAccountFormProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  agent: ISelectOption;
}

const AddPersonalAccountForm: React.FC<AddPersonalAccountFormProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    agentDetail: {
      detailResponse: { data: agentDetailResponse },
    },
  } = useSelector((state: RootState) => state);

  const { control, handleSubmit } = useForm<FormData>();

  const {
    mutate: mutatePersonalAccount,
    isLoading,
  } = useUpdatePersonalAccount();

  const onSubmit = async (formValues: FormData) => {
    const req: UpdatePersonalAccountRequest = {
      userId: agentDetailResponse?.id!,
      personalAccountId: formValues?.agent?.value,
    };

    mutatePersonalAccount(req, {
      onSuccess: onClose,
    });
  };

  return (
    <ZenSidebarModalForm
      title='Add Personal (Payment) Account'
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      cancelTitle='Cancel'
      actionTitle='Save'
      isSubmitting={isLoading}
    >
      <div className='mt-3'>
        <ZenControlledAsyncSelectInput<FormData, 'agent'>
          name='agent'
          control={control}
          label='Select Agent'
          placeholder='Search Agent'
          fetchData={async (search, page) => {
            try {
              const { data } = await new AgentControllerApi(
                getYentaConfiguration(),
              ).searchNonBrokerAgents(
                page,
                DEFAULT_PAGE_SIZE,
                'ASC',
                ['LAST_NAME'],
                search,
                ['ACTIVE'],
              );

              const options = (data?.results || [])?.map(
                (account: AgentInfo) => ({
                  value: account?.id!,
                  label: `${account?.firstName} ${account?.lastName}`,
                }),
              )!;

              return options;
            } catch (e) {
              dispatch(showApiErrorModal(e));
              ErrorService.notify('Unable to fetch agents', e, {
                request: { search, page },
              });
              dispatch(
                showErrorToast(
                  'We had a problem fetching agents.',
                  'Please try again in a few moments.',
                ),
              );
              return [];
            }
          }}
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default AddPersonalAccountForm;
