import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  AgentResponse,
  UserAvailabilityResponse,
  UserControllerApi,
} from '../../../../openapi/yenta';
import { queryKeys } from '../../../../query/base/queryKeys';
import { useBaseMutation } from '../../../../query/base/useBaseMutation';
import { saveLoggedInAgentDetails } from '../../../../slices/AgentSlice';
import { AppDispatch } from '../../../../types';
import { getYentaConfiguration } from '../../../../utils/OpenapiConfigurationUtils';
import ZenControlledDatePickerInput from '../../../Zen/Input/ZenControlledDatePickerInput';
import ZenSimpleModal from '../../../Zen/Modal/ZenSimpleModal';
import ZenButton from '../../../Zen/ZenButton';

interface IFormData {
  startDate?: string;
  endDate?: string;
}

type OutOfOfficeFormProps = {
  agentId: string;
  agentDetail?: AgentResponse | null;
  onClose(): void;
};

const OutOfOfficeForm: React.FC<OutOfOfficeFormProps> = ({
  agentId,
  agentDetail,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const userAvailability = (agentDetail?.availability ||
    {}) as UserAvailabilityResponse;
  const outOfOffice = userAvailability.outOfOffice?.[0];
  const timeZone =
    agentDetail?.availability?.timeZone || DateTime.local().zoneName;

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IFormData>({
    defaultValues: {
      startDate: outOfOffice?.startDate,
      endDate: outOfOffice?.endDate,
    },
  });

  const [startDate, endDate] = watch(['startDate', 'endDate']);

  const { mutateAsync } = useBaseMutation({
    queryKey: queryKeys.roar.officeHours(agentId).queryKey,
    mutationFn: async (formData: IFormData) => {
      const { data } = await new UserControllerApi(
        getYentaConfiguration(),
      ).patchUser(agentId, {
        userAvailability: {
          ...userAvailability,
          timeZone,
          outOfOffice: [
            { startDate: formData.startDate!, endDate: formData.endDate! },
          ],
        },
      });
      dispatch(
        saveLoggedInAgentDetails({
          ...agentDetail,
          availability: data.availability,
        }),
      );
      onClose();
    },
    successMessage: 'Settings Saved',
    errorMessage: 'Failed to save settings',
  });

  function getJSDate(date?: string): Date | undefined {
    if (!date) {
      return undefined;
    }
    return DateTime.fromISO(date).toJSDate();
  }

  return (
    <ZenSimpleModal
      title={outOfOffice ? 'Edit Scheduled Time Off' : 'Schedule Time Off'}
      isOpen
      onClose={onClose}
      size='large'
    >
      <form onSubmit={handleSubmit((formData) => mutateAsync(formData))}>
        <div className='px-4 py-6'>
          {!outOfOffice && (
            <p className='mb-3 font-inter text-primary-dark'>
              Planning to be away for a couple of days? Set up your
              out-of-office!
            </p>
          )}
          <p className='font-inter font-medium text-primary-dark'>
            Select start and end date
          </p>
          <div className='mt-2 flex items-center space-x-2.5'>
            <div className='flex-1'>
              <ZenControlledDatePickerInput
                control={control}
                shouldUnregister={false}
                name='startDate'
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className='text-primary-blue pt-2'
                  />
                }
                placeholder='Start date'
                rules={{ required: 'Required' }}
                datePickerConfig={{
                  selectsStart: true,
                  startDate: getJSDate(startDate),
                  endDate: getJSDate(endDate),
                  minDate: getJSDate(DateTime.now().toISO()),
                  maxDate: getJSDate(endDate),
                  dateFormat: 'EE, MMM dd',
                }}
              />
            </div>
            <div className='w-3 h-px bg-grey-300' />
            <div className='flex-1'>
              <ZenControlledDatePickerInput
                control={control}
                shouldUnregister={false}
                name='endDate'
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className='text-primary-blue pt-2'
                  />
                }
                placeholder='End Date'
                rules={{ required: 'Required' }}
                datePickerConfig={{
                  selectsEnd: true,
                  startDate: getJSDate(startDate),
                  endDate: getJSDate(endDate),
                  minDate: getJSDate(startDate),
                  dateFormat: 'EE, MMM dd',
                }}
              />
            </div>
          </div>
        </div>
        <div className='p-4 border-t border-grey-300 flex items-center justify-end space-x-3'>
          <ZenButton
            label='Cancel'
            variant='primary-outline'
            className='!px-10'
            onClick={onClose}
          />
          <ZenButton
            label={outOfOffice ? 'Update' : 'Schedule'}
            className='!px-10'
            type='submit'
            isDisabled={isSubmitting || !(startDate && endDate)}
          />
        </div>
      </form>
    </ZenSimpleModal>
  );
};

export default OutOfOfficeForm;
