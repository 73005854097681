import { isEqual } from 'lodash';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import AmplitudeEventOnLoad from '../components/Amplitude/AmplitudeEventOnLoad';
import AgentOnly from '../components/auth/AgentOnly';
import DetailPageLoader from '../components/DetailPageLoader';
import GeminiTransactionHeader from '../components/Gemini/Transaction/Header/GeminiTransactionHeader';
import InstantPaymentAlert from '../components/InstantPaymentAlert';
import PageLayout from '../components/PageLayout';
import ResourceContainer from '../components/ResourceContainer';
import HappyBrokerFeedbackModal from '../components/transactions/HappyFeature/HappyBrokerFeedbackModal';
import HappyFeatureModal from '../components/transactions/HappyFeature/HappyFeatureModal';
import SilenceNotification from '../components/transactions/SilenceNotification';
import TransactionServerError from '../components/transactions/TransactionServerError';
import ZenTransactionChecklist from '../components/Zen/Checklist/ZenTransactionChecklist';
import ZenConveyanceDocumentsRoute from '../components/Zen/ConveyanceDocuments/ZenConveyanceDocumentsRoute';
import ZenJourney from '../components/Zen/RoadToSuccess/ZenJourney';
import ZenRouterTabs, { ZenTab } from '../components/Zen/Tab/ZenRouterTabs';
import ZenTransactionDetailErrors from '../components/Zen/Transaction/Header/ZenTransactionDetailErrors';
import ZenTransactionDetailSecondaryHeader from '../components/Zen/Transaction/Header/ZenTransactionDetailSecondaryHeader';
import ZenTransactionHeader from '../components/Zen/Transaction/Header/ZenTransactionHeader';
import ZenTransactionDetail from '../components/Zen/Transaction/ZenTransactionDetail';
import ZenDetailPagePrevAndNextPaginator from '../components/ZenDetailPagePrevAndNextPaginator';
import UseBrokerQueue from '../hooks/useBrokerQueue';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import useRedirectAwayTransactionEffect from '../hooks/useRedirectAwayTransactionEffect';
import useTransactionOnboardingRedirect from '../hooks/useTransactionOnboardingRedirect';
import {
  CreateSentimentRequestContextTypeEnum,
  RezenObjectTypeEnum,
  SentimentDisplayRequestContextTypeEnum,
  SentimentDisplayResponseContextTypeEnum,
  TransactionControllerApi,
  TransactionResponse,
} from '../openapi/arrakis';
import AmplitudeService, { AmplitudeEvent } from '../services/AmplitudeService';
import ErrorService from '../services/ErrorService';
import {
  fetchApprovedCDAForTransaction,
  saveApprovedCDADetail,
} from '../slices/CDAFormSlice';
import { getCheckDepositsList } from '../slices/CheckDepositsSlice';
import { fetchDropbox } from '../slices/DropboxSlice';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { fetchOfficeDetailById } from '../slices/OfficeSlice';
import {
  getTeamDetailOverview,
  saveTeamDetailOverview,
} from '../slices/TeamSlice';
import {
  fetchDoubleEnderAgent,
  fetchTransactionCommentParticipants,
  fetchTransactionDetails,
  getSentimentDisplayStatus,
  getTransactionFeatures,
  getTransactionPermission,
  saveBrokerSentimentDetails,
  saveTransactionFeatures,
  saveTransactionPaginatedResultsByIdWithFilters,
} from '../slices/TransactionSlice';
import {
  BreadcrumbMatcherType,
  EnumMap,
  RootState,
  FeatureFlagTypeEnum,
  AppDispatch,
} from '../types';
import { getInstantPaymentAmplitudeEventData } from '../utils/InstantPaymentHelper';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import {
  canHaveApprovedCDAs,
  getBreadcrumbMatched,
  isShowHappyModel,
  shouldRedirectToDetailsTab,
  showHappyBrokerFeedbackModal,
} from '../utils/TransactionHelper';
import {
  getPaginatedResults,
  getSearchableTransactions,
  getTransactionListPageUrlQueryParams,
  TransactionSortDirectionTypeEnum,
} from '../utils/TransactionUtils';
import HasTransactionClosedModal from './HasTransactionClosedModal';
import Route404 from './Route404';
import TransactionCDARoute from './TransactionCDARoute';
import TransactionCommentRoute from './TransactionCommentRoute';
import TransactionInvoiceRoute from './TransactionInvoiceRoute';
import TransactionTitleProgressRoute from './TransactionTitleProgressRoute';
import TransactionTradeRecordSheetsRoute from './TransactionTradeRecordSheetsRoute';
import ZenTransactionActivity from './ZenTransactionActivity';
import ZenTransactionFileCabinetRoute from './ZenTransactionFileCabinetRoute';
import ZenTransactionOutgoingPaymentRoute from './ZenTransactionOutgoingPaymentRoute';
import ZenTransactionPaymentInvoicesRoute from './ZenTransactionPaymentInvoicesRoute';
import ZenTransactionViewCheckDepositRoute from './ZenTransactionViewCheckDepositRoute';
import ZenTransactionViewDepositsRoute from './ZenTransactionViewDepositsRoute';

const TransactionSortByTypeEnum: EnumMap<
  string,
  'ESCROW_CLOSING_DATE' | 'ACTUAL_CLOSING_DATE' | 'PRICE'
> = {
  skySlopeEscrowClosingDate: 'ESCROW_CLOSING_DATE',
  rezenClosedAt: 'ACTUAL_CLOSING_DATE',
  price: 'PRICE',
};

type Match = {
  id: string;
};

const ZenTransactionDetailRoute: React.FC = () => {
  const history = useHistory();
  const { id: transactionId } = useParams() as Match;
  const match = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const { isBrokerQueueActive } = UseBrokerQueue();
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail, loading, error },
      sentimentDetails,
      brokerSentimentDetails,
      transactionPaginatedResultsByIdWithFilters,
    },
    auth: { isAdmin, isBroker, userDetail },
  } = useSelector((state: RootState) => state);
  const { showStatusOnboardings } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const isAgent = !(isAdmin || isBroker);
  const [isDropboxLoading, setIsDropboxLoading] = useState<boolean>(false);
  const isLoading =
    isDropboxLoading || loading || transactionDetail?.id !== transactionId;

  const eventData = getInstantPaymentAmplitudeEventData(transactionDetail);
  const filters = getTransactionListPageUrlQueryParams(
    transactionPaginatedResultsByIdWithFilters,
  );

  const willTransition = useTransactionOnboardingRedirect(
    !isAdmin && !isBroker,
    transactionId,
    transactionDetail,
  );

  const willTransitionToListing = useRedirectAwayTransactionEffect(
    transactionId,
    transactionDetail,
  );

  useEffect(() => {
    dispatch(fetchTransactionDetails(transactionId));
    dispatch(getTransactionPermission(transactionId));
    dispatch(getCheckDepositsList(transactionId));
    dispatch(
      fetchTransactionCommentParticipants(transactionId, userDetail?.id!),
    );
  }, [dispatch, transactionId, userDetail?.id]);

  useEffect(() => {
    if (transactionDetail?.teamId) {
      dispatch(getTeamDetailOverview(transactionDetail.teamId));
    } else {
      dispatch(saveTeamDetailOverview(null));
    }
  }, [dispatch, transactionDetail?.teamId]);

  useEffect(() => {
    dispatch(getTransactionFeatures(transactionId));

    return () => {
      dispatch(saveTransactionFeatures(undefined));
    };
  }, [dispatch, transactionId]);

  useEffect(() => {
    if (isAdmin && transactionId === transactionDetail?.id) {
      if (canHaveApprovedCDAs(transactionDetail?.lifecycleState?.state!)) {
        dispatch(fetchApprovedCDAForTransaction(transactionId));
      } else {
        dispatch(saveApprovedCDADetail(null));
      }
    }
  }, [transactionId, isAdmin, dispatch, transactionDetail]);

  useEffect(() => {
    if (transactionDetail?.office?.id) {
      dispatch(fetchOfficeDetailById(transactionDetail.office.id));
    }
    if (transactionDetail?.doubleEnderInfo?.otherAgentId) {
      dispatch(
        fetchDoubleEnderAgent(transactionDetail?.doubleEnderInfo?.otherAgentId),
      );
    }
  }, [dispatch, transactionDetail]);

  const handleCreateDropboxForTransaction = useCallback(
    async (txnId: string) => {
      setIsDropboxLoading(true);
      try {
        const { data } = await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).createDropboxForTx(txnId, {});
        await dispatch(fetchTransactionDetails(txnId));
        await dispatch(fetchDropbox(data?.dropboxId!));
      } catch (e) {
        dispatch(showApiErrorModal(e));
        ErrorService.notifyIgnoreHandled(
          'Unable to create dropbox for transaction',
          e,
          {
            transaction: { id: txnId },
          },
        );
      } finally {
        setIsDropboxLoading(false);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      isAdmin &&
      !transactionDetail?.dropboxId &&
      transactionDetail?.id === transactionId
    ) {
      handleCreateDropboxForTransaction(transactionId);
    }
  }, [
    handleCreateDropboxForTransaction,
    transactionDetail?.dropboxId,
    transactionDetail?.id,
    transactionId,
    isAdmin,
  ]);

  useEffect(
    () => {
      if (
        !!transactionDetail?.id &&
        isEqual(transactionDetail?.id, transactionId) &&
        isAgent
      ) {
        dispatch(
          getSentimentDisplayStatus(
            {
              contextId: transactionDetail?.id!,
              containingContext: {
                id: transactionDetail?.id!,
                type: RezenObjectTypeEnum.Transaction,
              },
              contextType: SentimentDisplayRequestContextTypeEnum.Broker,
              contextState: transactionDetail?.lifecycleState?.state,
            },
            true,
          ),
        );
      }

      return () => {
        dispatch(saveBrokerSentimentDetails(undefined));
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, transactionDetail?.id, transactionDetail?.lifecycleState?.state],
  );

  useEffect(() => {
    if (
      transactionDetail?.id === transactionId &&
      !willTransition &&
      !willTransitionToListing &&
      shouldRedirectToDetailsTab(
        transactionDetail,
        location.pathname,
        true,
        'transactions',
      )
    ) {
      history.replace(
        `/transactions/${transactionDetail.id}/detail?${qs.stringify({
          showStatusOnboardings,
        })}`,
      );
    }
  }, [
    history,
    location.pathname,
    showStatusOnboardings,
    transactionDetail,
    transactionId,
    willTransition,
    willTransitionToListing,
  ]);

  const path = [
    { title: 'Home', url: '/' },
    { title: 'Transactions', url: `/transactions?${qs.stringify(filters)}` },
  ];

  if (transactionDetail) {
    path.push({
      title: `${transactionDetail?.address?.oneLine}`,
      url: `/transactions/${transactionDetail?.id}`,
    });

    const breadcrumbsToMatch: BreadcrumbMatcherType[] = [
      {
        title: 'Details',
        url: `/transactions/${transactionDetail?.id}/detail`,
      },
      {
        title: 'Commission Documents',
        url: `/transactions/${transactionDetail?.id}/cda`,
      },
      {
        title: 'Outgoing Payments',
        url: `/transactions/${transactionDetail?.id}/outgoing-payments`,
      },
      {
        title: 'Payment Invoices',
        url: `/transactions/${transactionDetail?.id}/payment-invoices`,
      },
      {
        title: 'Invoices',
        url: `/transactions/${transactionDetail?.id}/invoices`,
      },
      {
        title: 'View Check Deposits',
        url: `/transactions/${transactionDetail?.id}/view-check-deposit`,
      },
      {
        title: 'Checklist',
        url: `/transactions/${transactionDetail?.id}/checklist`,
      },
      {
        title: 'Conveyance Documents',
        url: `/transactions/${transactionDetail?.id}/conveyance-documents`,
      },
      {
        title: 'Trade Record Sheets',
        url: `/transactions/${transactionDetail?.id}/trade-record-sheets`,
      },
      {
        title: 'Title Progress',
        url: `/transactions/${transactionDetail?.id}/title-progress`,
      },
      {
        title: 'File Cabinet',
        url: `/transactions/${transactionDetail?.id}/file-cabinet`,
      },
      {
        title: 'Deposits',
        url: `/transactions/${transactionDetail?.id}/view-deposits`,
      },
      {
        title: 'Comments',
        url: `/transactions/${transactionDetail?.id}/comments`,
      },
      {
        title: 'Activity',
        url: `/transactions/${transactionDetail?.id}/activity`,
      },
      {
        title: 'Title Progress',
        url: `/transactions/${transactionDetail?.id}/title-progress`,
      },
      {
        title: 'Road To Success',
        url: `/transactions/${transactionDetail?.id}`,
      },
    ];

    const breadcrumbMatched = getBreadcrumbMatched(
      location.pathname,
      breadcrumbsToMatch,
    );

    if (breadcrumbMatched) {
      path.push(breadcrumbMatched);
    }
  }

  const currentUserParticipant = transactionDetail?.paymentParticipants?.filter(
    (participant) => participant.yentaId === userDetail?.id,
  )[0];

  const transactionTabs = useMemo(() => {
    const tabs: ZenTab[] = [
      {
        label: 'Road To Success',
        TabComponent: () => <ZenJourney transaction={transactionDetail!} />,
        path: `/transactions/${transactionId}`,
        exact: true,
      },
      {
        label: 'Checklist',
        TabComponent: ZenTransactionChecklist,
        path: `/transactions/${transactionId}/checklist`,
        exact: true,
      },
      {
        label: 'Details',
        TabComponent: ZenTransactionDetail,
        path: `/transactions/${transactionId}/detail`,
        exact: true,
      },
      {
        label: 'Deposits',
        TabComponent: ZenTransactionViewDepositsRoute,
        path: `/transactions/${transactionId}/view-deposits`,
        exact: true,
      },
      {
        label: 'Comments',
        TabComponent: TransactionCommentRoute,
        path: `/transactions/${transactionId}/comments`,
        exact: true,
        // messageCount: 3,
      },
      {
        label: 'File Cabinet',
        TabComponent: ZenTransactionFileCabinetRoute,
        path: `/transactions/${transactionId}/file-cabinet`,
        exact: true,
      },
      {
        label: 'Activity',
        TabComponent: ZenTransactionActivity,
        path: `/transactions/${transactionId}/activity`,
        exact: true,
      },
      {
        label: 'Payment Invoices',
        TabComponent: ZenTransactionPaymentInvoicesRoute,
        path: `/transactions/${transactionId}/payment-invoices`,
        exact: true,
      },
      {
        label: 'Outgoing Payments',
        TabComponent: ZenTransactionOutgoingPaymentRoute,
        path: `/transactions/${transactionId}/outgoing-payments`,
        exact: true,
      },
      {
        label: 'Title Progress',
        TabComponent: TransactionTitleProgressRoute,
        path: `/transactions/${transactionId}/title-progress`,
        exact: true,
      },
    ];
    return tabs.filter((tab) => {
      const label = tab.label;
      if (label === 'Road To Success') {
        return transactionDetail?.journeyId;
      }
      if (label === 'Checklist') {
        return transactionDetail?.checklistId;
      }
      if (label === 'Payment Invoices') {
        return isAdmin || isBroker;
      }
      if (label === 'Title Progress') {
        return transactionDetail?.usingTitle;
      }
      if (label === 'File Cabinet') {
        return transactionDetail?.dropboxId;
      }
      return true;
    });
  }, [isAdmin, isBroker, transactionDetail, transactionId]);

  const tabsPathRegex = useMemo(
    () => `(${transactionTabs.map((tab) => tab.path).join('|')})`,
    [transactionTabs],
  );

  const geminiRedesignFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum?.GEMINI_REDESIGN,
  );

  const HeaderComponent = geminiRedesignFlagEnabled
    ? GeminiTransactionHeader
    : ZenTransactionHeader;

  return (
    <PageLayout
      path={path}
      hideLayoutPaths={isBrokerQueueActive}
      RightComponent={<SilenceNotification />}
    >
      <ZenDetailPagePrevAndNextPaginator
        title='Transactions'
        itemId={transactionId}
        items={transactionPaginatedResultsByIdWithFilters}
        apiCall={async (isNext, items) => {
          const filters = items?.filters;
          if (isAgent) {
            const sortKey = Object.keys(filters?.sortBy || {})[0];
            const sortType = Object.values(filters?.sortBy || {})[0];

            const { data } = await new TransactionControllerApi(
              getArrakisConfiguration(),
            ).getTransactionsByStateGroupPaginated(
              items.agentId!,
              items.lifecycleGroup!,
              filters?.page! + (isNext ? 1 : -1),
              filters?.pageSize!,
              filters?.search!,
              TransactionSortByTypeEnum[sortKey],
              TransactionSortDirectionTypeEnum[sortType!],
              items?.transactionTypes,
            );

            return {
              ...data,
              data: data.transactions as TransactionResponse[],
              total: data.totalCount,
            };
          }

          const res = await getSearchableTransactions(
            {
              ...filters!,
              page: filters?.page! + (isNext ? 1 : -1),
              pageSize: filters?.pageSize!,
            },
            false,
            items?.officeIds,
          );

          return res;
        }}
        dispatchCall={async (res, items) =>
          await dispatch(
            saveTransactionPaginatedResultsByIdWithFilters({
              hasNext: res.hasNext,
              totalCount: res.total,
              results: getPaginatedResults(
                res.pageNumber!,
                res.pageSize!,
                res.data || [],
              ),
              filters: {
                ...items.filters,
                page: res.pageNumber!,
                pageSize: res.pageSize!,
              },
              officeIds: items.officeIds,
              agentId: items.agentId,
              lifecycleGroup: items.lifecycleGroup,
              transactionTypes: items.transactionTypes,
            }),
          )
        }
        handleNavigate={(transactionId) =>
          history.push(`/transactions/${transactionId}`)
        }
      />
      <ResourceContainer
        loading={isLoading}
        LoaderComponent={<DetailPageLoader />}
        isEmpty={!transactionDetail}
        errorCode={error}
        ServerErrorComponent={
          <TransactionServerError transactionId={transactionId} />
        }
        resourceName='transaction'
      >
        {!!transactionDetail && (
          <>
            <ZenTransactionDetailErrors errors={transactionDetail.errors!} />
            {!willTransition && currentUserParticipant?.instantPaymentEligible && (
              <AgentOnly>
                <AmplitudeEventOnLoad
                  eventName={
                    AmplitudeEvent.INSTANT_PAYMENTS_TRANSACTION_BANNER_VIEW
                  }
                  eventData={eventData}
                />
                <InstantPaymentAlert
                  text='An instant payment is available for this transaction! Learn more.'
                  onClick={() => {
                    AmplitudeService.logEvent(
                      AmplitudeEvent.INSTANT_PAYMENTS_TRANSACTION_BANNER_CLICK,
                      eventData,
                    );
                    history.push(
                      `/instant-payments?fromTransactionId=${transactionDetail.id}`,
                    );
                  }}
                />
              </AgentOnly>
            )}
            <HeaderComponent transaction={transactionDetail} />

            {isShowHappyModel(sentimentDetails, transactionDetail) && (
              <HappyFeatureModal transaction={transactionDetail} />
            )}

            {showHappyBrokerFeedbackModal(
              brokerSentimentDetails,
              SentimentDisplayResponseContextTypeEnum.Broker,
            ) && (
              <HappyBrokerFeedbackModal
                transaction={transactionDetail}
                contextType={CreateSentimentRequestContextTypeEnum.Broker}
              />
            )}

            <Switch>
              <Route
                path={`${match.path}/cda`}
                component={TransactionCDARoute}
                exact
              />
              <Route
                path={`${match.path}/invoices`}
                component={TransactionInvoiceRoute}
                exact
              />
              <Route
                path={`${match.path}/trade-record-sheets`}
                component={TransactionTradeRecordSheetsRoute}
                exact
              />
              <Route
                path={`${match.path}/view-check-deposit`}
                component={ZenTransactionViewCheckDepositRoute}
                exact
              />
              <Route
                path={`${match.path}/conveyance-documents`}
                component={ZenConveyanceDocumentsRoute}
                exact
              />
              <Route path={match.path}>
                <ZenTransactionDetailSecondaryHeader
                  transaction={transactionDetail}
                />
                <Switch>
                  <Route path={tabsPathRegex} exact>
                    <ZenRouterTabs tabs={transactionTabs} stickyTabs />
                  </Route>
                  <Route path={`${match.path}/*`} component={Route404} />
                </Switch>
              </Route>
            </Switch>
          </>
        )}
        {!!transactionDetail && (
          <HasTransactionClosedModal
            transaction={transactionDetail}
            transactionId={transactionId}
          />
        )}
      </ResourceContainer>
    </PageLayout>
  );
};

export default ZenTransactionDetailRoute;
