import React from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FileResponse } from '../../../openapi/dropbox';
import { AppDispatch } from '../../../types';
import { downloadDropboxFileByVersion } from '../../../slices/DropboxSlice';

interface ZenFileRowCellProps {
  file: FileResponse;
}

const ZenFileRowCell: React.FC<ZenFileRowCellProps> = ({ file }) => {
  const dispatch: AppDispatch = useDispatch();

  const handleViewFile = async (file: FileResponse) => {
    await dispatch(
      downloadDropboxFileByVersion(file.id!, file.currentVersion?.id!),
    );
  };

  return (
    <div className='flex items-center'>
      <div className='inline-block items-center'>
        <div className='text-sm font-zen-body font-normal text-zen-dark-9 flex items-start gap-2'>
          {file.filename}
          <button
            type='button'
            className='appearance-none'
            onClick={() => {
              handleViewFile(file);
            }}
            aria-label='file-open'
          >
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              size='sm'
              className='text-primary-blue text-base mt-1'
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ZenFileRowCell;
