import React from 'react';
import { CheckDepositDtoStatusEnum } from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenPill, { ZenPillVariant } from '../../Zen/ZenPill';
import { safeEnumMapGet } from '../../../utils/EnumHelper';

interface ZenViewCheckDepositStatusCellProps {
  status: CheckDepositDtoStatusEnum;
}

const ZenViewCheckDepositStatusCell: React.FC<ZenViewCheckDepositStatusCellProps> = ({
  status,
}) => {
  const statusToVariantMap: EnumMap<
    CheckDepositDtoStatusEnum,
    ZenPillVariant
  > = {
    UPLOADED: 'primary',
    DEPOSITED: 'warning',
    SUBMITTED: 'success',
    ERROR: 'danger',
    REJECTED: 'danger',
  };

  return (
    <ZenPill
      title={capitalizeEnum(status)}
      variant={safeEnumMapGet(statusToVariantMap, status, 'default')}
    />
  );
};

export default ZenViewCheckDepositStatusCell;
