import { faCamera } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutorenewRounded from '@material-ui/icons/AutorenewRounded';
import React, { ChangeEvent, createRef } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { cn } from '../../utils/classUtils';
import { isValidUserAvatarFile } from '../../utils/FileUtils';

export type ProfileImageVariant = 'rounded' | 'circle';

export interface GeminiProfileImageProps {
  imageUrl?: string | undefined;
  width: number;
  variant?: ProfileImageVariant;
  editable?: boolean;
  hideEdit?: boolean;
  isSubmitting?: boolean;
  onImageChange?(e: ChangeEvent<HTMLInputElement>): void;
  imageClassName?: string;
}

const GeminiProfileImage: React.FC<GeminiProfileImageProps> = ({
  imageUrl,
  width = 50,
  variant = 'circle',
  editable = false,
  onImageChange,
  hideEdit = false,
  isSubmitting = false,
  imageClassName,
}) => {
  const ref = createRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const variantMapClass: { [x in ProfileImageVariant]: string } = {
    rounded: 'rounded',
    circle: 'rounded-full',
  };

  const handleChangeImage = () => {
    if (!!onImageChange) {
      ref.current?.click();
    }
  };

  return (
    <div
      className={cn('relative', {
        'cursor-pointer': !!editable,
      })}
      onClick={handleChangeImage}
    >
      {isSubmitting && (
        <AutorenewRounded
          className='animate-spin text-primary-blue absolute right-0 top-0 left-0 bottom-0 mx-auto my-auto'
          fontSize='large'
        />
      )}
      <div
        style={{ width, height: width }}
        className={cn(
          'flex items-center justify-center bg-white overflow-hidden',
          variantMapClass[variant],
        )}
      >
        <img
          src={imageUrl ?? '/img/default-avatar.svg'}
          alt='profile_image'
          className={cn('h-full object-contain object-center', imageClassName)}
        />
      </div>
      {editable && (
        <div className='flex items-center justify-center w-9 h-9 absolute -right-1 top-5 rounded-full p-2 border-[1.5px] border-white bg-regent-900'>
          {!hideEdit && (
            <FontAwesomeIcon icon={faCamera} className='text-white' />
          )}
          <input
            type='file'
            className='hidden'
            ref={ref}
            aria-label='uploadAvatar'
            onChange={(e) => {
              if (e.target?.files && e.target?.files?.[0]) {
                if (
                  onImageChange &&
                  isValidUserAvatarFile(e.target?.files?.[0]?.name)
                ) {
                  onImageChange(e);
                } else {
                  dispatch(
                    showErrorToast(
                      'File type not supported. Upload jpg/jpeg, png, gif, bmp file only',
                    ),
                  );
                }
                e.target.value = '';
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default GeminiProfileImage;
