import { values } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { FaRegCalendarAlt, FaRegClock } from 'react-icons/fa';
import { MdOutlineLocationOn } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../config';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  CalendarEventInviteeResponseRsvpStatusEnum,
  CalendarEventResponse,
  CalendarEventResponseRsvpStatusEnum,
} from '../../../openapi/yenta';
import AmplitudeService, {
  AmplitudeEvent,
} from '../../../services/AmplitudeService';
import {
  fetchEventInviteesByStatus,
  fetchEventInviteesCount,
  rsvpToCalendarEvent,
} from '../../../slices/CalendarSlice';
import { FeatureFlagTypeEnum, RootState } from '../../../types';
import {
  DEFAULT_INVITEES_PAGE_NUMBER,
  DEFAULT_INVITEES_PAGE_SIZE,
  EventTabEnum,
  getEventTemplateBasedOnTitle,
  isYesOrMaybeRsvpStatus,
} from '../../../utils/EventHelper';
import { getCalendarImageUrl } from '../../../utils/ImgUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ResourceContainer from '../../ResourceContainer';
import ZenAvatarStack from '../ZenAvatarStack';
import ZenCopyToClipboard from '../ZenCopyToClipBoard';
import ZenSidebarModal from '../ZenSidebarModal';
import ZenStatusLabelWithIcon from '../ZenStatusLabelWithIcon';
import ZenRealWhiteLogo from '../../../assets/img/zen/zen-real-white-logo.png';
import { cn } from '../../../utils/classUtils';
import { getCurrentUserTimeZone } from '../../../utils/DateUtils';
import ZenAddToCalendarButton from './ZenAddToCalendarButton';
import ZenEventAddToCalendarModal from './ZenEventAddToCalendarModal';
import ZenEventGroupRadioButton from './ZenEventGroupRadioButton';
import ZenEventInviteesModal from './ZenEventInviteesModal';
import ZenEventLocation from './ZenEventLocation';

interface ZenEventDetailsSidebarModalProps {
  event: CalendarEventResponse;
  isOpen: boolean;
  onClose(): void;
  tab?: EventTabEnum;
  calendarIds?: string[];
}

const ZenEventDetailsSidebarModal: React.FC<ZenEventDetailsSidebarModalProps> = ({
  event,
  tab,
  isOpen,
  onClose,
  calendarIds,
}) => {
  const dispatch = useDispatch();
  const {
    auth: { userDetail, isAdmin },
    calendar: { eventInviteesCount, pagedEventInviteesByStatus },
  } = useSelector((state: RootState) => state);
  const [isFetchingInvitees, setIsFetchingInvitees] = useState<boolean>(false);
  const [isSeeAll, setIsSeeAll] = useState<boolean>(false);
  const [isAddToCalendar, setIsAddToCalendar] = useState<boolean>(false);
  const eventLink = `${config.reactAppHostUrl}/my-events/${event.id}`;

  const isCopyEventLinkEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.COPY_EVENT_LINK,
  );

  const invitees =
    pagedEventInviteesByStatus[CalendarEventInviteeResponseRsvpStatusEnum.Yes]
      ?.invitees || [];

  const isFutureEvent =
    DateTime.fromMillis(event.startAt!).diffNow().milliseconds >= 0;

  const fetchEventInvitees = useCallback(async () => {
    setIsFetchingInvitees(true);
    await dispatch(
      fetchEventInviteesByStatus(
        event.id!,
        CalendarEventInviteeResponseRsvpStatusEnum.Yes,
        DEFAULT_INVITEES_PAGE_NUMBER,
        DEFAULT_INVITEES_PAGE_SIZE,
        true,
      ),
    );
    await dispatch(fetchEventInviteesCount(event.id!));
    setIsFetchingInvitees(false);
  }, [dispatch, event.id]);

  useEffect(() => {
    fetchEventInvitees();
  }, [fetchEventInvitees]);

  const onSubmit = async (status: CalendarEventResponseRsvpStatusEnum) => {
    await dispatch(
      rsvpToCalendarEvent(
        event,
        userDetail?.id!,
        status,
        isAdmin,
        calendarIds,
        tab,
      ),
    );

    if (isYesOrMaybeRsvpStatus(status)) {
      AmplitudeService.logEvent(
        AmplitudeEvent.ADD_EVENT_TO_CALENDAR_MODAL_VIEW,
        { ...event },
      );

      setIsAddToCalendar(true);
    }

    await fetchEventInvitees();
  };

  return (
    <ZenSidebarModal
      title='Event Details'
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <div>
        <div>
          {!!event.imageUrl ? (
            <img
              src={getCalendarImageUrl(event.imageUrl)}
              className='h-96 w-full bg-center bg-cover bg-no-repeat'
              alt='event background'
            />
          ) : (
            <div
              className='h-96 bg-center bg-cover bg-no-repeat'
              style={{
                backgroundImage: `url(${getEventTemplateBasedOnTitle(
                  event.title!,
                )})`,
              }}
            >
              <div className='p-5 md:p-10'>
                <img src={ZenRealWhiteLogo} className='w-24' alt='real logo' />
                <p className='font-zen-body text-4xl font-bold text-white mt-28'>
                  {event.title}
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className={cn('pt-4 md:px-8 px-4', isFutureEvent ? 'pb-28' : 'pb-10')}
        >
          <div className='flex justify-between items-center mt-3'>
            <div>
              <ZenStatusLabelWithIcon text={event.calendarName!} />
            </div>
            <div>
              <ZenAddToCalendarButton event={event} />
            </div>
          </div>
          <div className='flex items-center justify-between mt-5'>
            <div className='flex md:flex-row flex-col md:space-x-3 space-x-0 md:space-y-0 space-y-3'>
              <div className='flex space-x-1'>
                <FaRegCalendarAlt
                  className='text-zen-dark-12 mt-1'
                  fontSize={18}
                />
                <span className='font-zen-body text-zen-dark-12 text-lg font-semibold'>
                  {DateTime.fromMillis(event.startAt!).toFormat('LLL dd, yyyy')}
                </span>
              </div>
              <div className='flex space-x-1'>
                <FaRegClock className='text-zen-dark-12 mt-1' fontSize={18} />
                <span className='font-zen-body text-zen-dark-12 text-lg font-semibold'>
                  {DateTime.fromMillis(event.startAt!).toFormat('hh:mm a')} -{' '}
                  {DateTime.fromMillis(event.endAt!).toFormat('hh:mm a')}{' '}
                  {getCurrentUserTimeZone(event?.startAt)}
                </span>
              </div>
              {event.status && (
                <div className='flex space-x-1'>
                  <MdOutlineLocationOn
                    className='text-zen-dark-12 mt-1'
                    fontSize={22}
                  />
                  <span className='font-zen-body text-zen-dark-12 text-lg font-semibold'>
                    {capitalizeEnum(event.status!)}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='mt-5'>
            <span className='font-zen-body font-semibold text-zen-dark-9 text-2xl  pr-3 mr-3'>
              {event.title}
            </span>
          </div>
          <div className='my-2 flex flex-row items-center space-x-2'>
            {event.location && <ZenEventLocation location={event.location} />}
            {isCopyEventLinkEnabled && (
              <ZenCopyToClipboard
                label='Copy Event Link'
                value={eventLink}
                className='p-0'
              />
            )}
          </div>
          <div className='mt-8 mb-4'>
            <p className='font-zen-body font-semibold text-base text-zen-dark-9'>
              Description
            </p>
            <div className='mt-2 font-zen-body font-light text-base text-zen-dark-9'>
              {event.description ? (
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: event.description }}
                />
              ) : (
                <div>No description available</div>
              )}
            </div>
          </div>
          <ResourceContainer
            loading={isFetchingInvitees}
            isEmpty={false}
            resourceName='invitee'
          >
            <div className='mt-8'>
              <div className='flex items-center space-x-2'>
                <p className='font-zen-body font-semibold text-base text-zen-dark-9'>
                  Attendees ({eventInviteesCount?.rsvpYesCount})
                </p>
                <p
                  className='font-zen-body font-semibold text-base text-primary-blue cursor-pointer'
                  onClick={() => setIsSeeAll(true)}
                >
                  See all
                </p>
              </div>
              <div className='mt-2'>
                <ZenAvatarStack
                  avatars={invitees.map((invitee) => ({
                    name: `${invitee.firstName} ${invitee.lastName}`,
                    imageUrl: invitee.avatar,
                  }))}
                  marginClassName='mr-5 mb-5'
                  numToDisplay={5}
                  overrideOverflowText={`+${
                    eventInviteesCount?.rsvpYesCount! - 5
                  }`}
                />
              </div>
            </div>
          </ResourceContainer>
        </div>
        {isFutureEvent && (
          <div className='absolute bottom-0 left-0 right-0 bg-white'>
            <div className='border border-gray-200 flex md:flex-row flex-col md:items-center justify-between p-4 md:space-y-0 space-y-3'>
              <p className='font-zen-body font-semibold text-base text-zen-dark-9'>
                Going?
              </p>
              <ZenEventGroupRadioButton
                options={values(CalendarEventResponseRsvpStatusEnum).map(
                  (status) => ({
                    label: capitalizeEnum(status),
                    value: status,
                  }),
                )}
                selected={event?.rsvpStatus}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        )}
      </div>
      {!!isAddToCalendar && (
        <ZenEventAddToCalendarModal
          isOpen={isAddToCalendar}
          onClose={() => setIsAddToCalendar(false)}
          event={event}
        />
      )}
      {!!isSeeAll && (
        <ZenEventInviteesModal
          isOpen={isSeeAll}
          onClose={() => setIsSeeAll(false)}
          calendarEventId={event.id!}
          eventInviteesCount={eventInviteesCount!}
        />
      )}
    </ZenSidebarModal>
  );
};

export default ZenEventDetailsSidebarModal;
