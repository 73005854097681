import { keys, values, mapValues, reduce } from 'lodash';
import { useSelector } from 'react-redux';
import {
  Earning,
  EarningTitle,
  EarningType,
  PrimaryColor,
} from '../components/WeathManagement/WealthManagementTypes';
import { MINIMUM_SLICE_PERCENTAGE } from '../constants/WealthPlanConstants';
import { EarningsDetails, EarningsOverviewResponse } from '../openapi/plutus';
import { RootState } from '../types';
import { getLockedEarnings } from '../utils/WealthPlanUtils';

export interface EarningsChartData {
  x: number;
  y: number;
  fill: string;
  padding?: number;
  radius?: number;
  title?: string;
  earnings?: number;
  percentage?: number;
  type?: EarningType;
  locked: boolean;
}

const getNetEarningsPlusProjectionsValues = (
  earningsPlusProjections: EarningsDetails,
): Record<EarningType, number> => ({
  COMMISSION_INCOME: earningsPlusProjections.netCommission?.amount || 0,
  PRE_CAP_SPP: earningsPlusProjections.combinedPreCapShareValue.amount || 0,
  STOCK_AWARD_FOR_CAPPING:
    earningsPlusProjections.cappingAwardValue.amount || 0,
  POST_CAP_SPP: earningsPlusProjections.combinedPostCapShareValue.amount || 0,
  ELITE_PRODUCTION_AWARD:
    earningsPlusProjections.eliteAgentProductionAwardValue.amount || 0,
  ELITE_CULTURAL_AWARD: earningsPlusProjections.culturalAwardValue.amount || 0,
  REVENUE_SHARE_INCOME: earningsPlusProjections.revShareEarnings.amount || 0,
  STOCK_AWARD_FOR_ATTRACTING:
    earningsPlusProjections.attractingAwardValue.amount || 0,
});

const calculateActualEarningPercentage = (
  response: EarningsOverviewResponse,
) => {
  const totalEarnings = response.projectedEarningsValue.amount || 0;
  const earningsPlusProjections = response?.netEarningsPlusProjections;

  const EarningPercentage: Record<EarningType, number> = mapValues(
    getNetEarningsPlusProjectionsValues(earningsPlusProjections),
    (value) => (value / totalEarnings) * 100,
  );

  return EarningPercentage;
};

const calculateEarningPercentageWithMinimumSlice = (
  response: EarningsOverviewResponse,
) => {
  const totalEarnings = response.projectedEarningsValue.amount || 0;
  const earningsPlusProjections = response?.netEarningsPlusProjections;

  const EarningPercentage: Record<
    EarningType,
    number
  > = mapValues(
    getNetEarningsPlusProjectionsValues(earningsPlusProjections),
    (value) => parseFloat(((value / totalEarnings) * 100).toFixed(2)),
  );

  keys(Earning).forEach((key) => {
    const earningType = key as EarningType;
    EarningPercentage[earningType] = Math.max(
      MINIMUM_SLICE_PERCENTAGE,
      EarningPercentage[earningType],
    );
  });

  const totalEarningsPercentage = parseFloat(
    reduce(values(EarningPercentage), (a, b) => a + b, 0).toFixed(2),
  );

  keys(Earning).forEach((key) => {
    const earningType = key as EarningType;
    EarningPercentage[earningType] = parseFloat(
      (
        (EarningPercentage[earningType] / totalEarningsPercentage) *
        100
      ).toFixed(2),
    );
  });

  return EarningPercentage;
};

const useEarningsChartData = (): EarningsChartData[] => {
  const {
    earningsOverviewResponse: { data: overviewResponse },
    wealthGoalsResponse: { data: wealthGoalsResponse },
  } = useSelector((state: RootState) => state.wealthManagement);

  if (!overviewResponse) {
    return [];
  }

  const ActualEarningsPercentage = calculateActualEarningPercentage(
    overviewResponse,
  );
  const EarningPercentage = calculateEarningPercentageWithMinimumSlice(
    overviewResponse,
  );
  const NET_EARNINGS_PLUS_PROJECTIONS_ENUM_MAP = getNetEarningsPlusProjectionsValues(
    overviewResponse.netEarningsPlusProjections,
  );
  const { isLockedEarningTypeEnumMap } = getLockedEarnings(
    wealthGoalsResponse?.goalDetails,
  );

  const earningsChartData: EarningsChartData[] = keys(Earning).map((key) => {
    const earningType = key as EarningType;

    return {
      x: 0,
      y: EarningPercentage[earningType],
      fill: PrimaryColor[earningType],
      title: EarningTitle[earningType],
      earnings: NET_EARNINGS_PLUS_PROJECTIONS_ENUM_MAP[earningType],
      percentage: ActualEarningsPercentage[earningType],
      type: earningType,
      locked: isLockedEarningTypeEnumMap[earningType],
    };
  });

  return earningsChartData;
};

export default useEarningsChartData;
