import { values } from 'lodash';
import { useEffect, useState } from 'react';
import {
  TaxFormCanadaResponse,
  TaxFormResponseTypeEnum,
  TaxFormW8BENResponse,
  TaxFormW8ECIResponse,
  TaxFormW9Response,
} from '../../openapi/yenta';
import TaxTabDetails from '../../routes/TaxTabDetails';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenTabs from './ZenTabs';

interface Props {
  agentId: string;
  taxData: TaxFormW9Response[];
}

const ZenTaxTabs: React.FC<Props> = ({ agentId, taxData }) => {
  const getTaxTabTitle = (type: TaxFormResponseTypeEnum): string => {
    if (type === TaxFormResponseTypeEnum.W9) {
      return 'W-9 Form';
    }
    if (type === TaxFormResponseTypeEnum.W8Eci) {
      return 'W-8ECI Form';
    }
    if (type === TaxFormResponseTypeEnum.W8Ben) {
      return 'W-8BEN Form';
    }
    if (type === TaxFormResponseTypeEnum.Canada) {
      return 'Canada Tax Form';
    }
    return capitalizeEnum(type);
  };

  const tabs = values(taxData).map(
    (
      data:
        | TaxFormW9Response
        | TaxFormW8ECIResponse
        | TaxFormW8BENResponse
        | TaxFormCanadaResponse,
    ) => {
      return {
        name: getTaxTabTitle(data.type!),
        TabComponent: <TaxTabDetails data={data!} agentId={agentId} />,
      };
    },
  );

  const tabName = tabs[0].name.toString();
  const [taxTab, setTaxTab] = useState(tabName);

  useEffect(() => {
    // set active tab after removing/adding the form
    setTaxTab(tabName);
  }, [taxData, tabName]);

  return (
    <div>
      <ZenTabs tabs={tabs} selected={taxTab} onChange={setTaxTab} size='sm' />
    </div>
  );
};

export default ZenTaxTabs;
