import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { showEncryptedApiKey } from '../../../utils/DisplayHelpers';
import IconButton from '../../IconButton';
import ZenCopyToClipBoard from '../../Zen/ZenCopyToClipBoard';

interface AgentClientApiKeyFieldProps {
  apiKey: string;
}

const AgentClientApiKeyField: React.FC<AgentClientApiKeyFieldProps> = ({
  apiKey,
}) => {
  const [showApiKey, setShowApiKey] = useState(false);

  return (
    <div className='flex items-center space-x-3'>
      <div className='flex items-center border rounded-lg border-zen-dark-4 font-zen-body text-sm px-3 space-x-3'>
        <div className='py-2'>
          {showApiKey ? apiKey : showEncryptedApiKey(apiKey || '')}
        </div>
        <FontAwesomeIcon
          icon={showApiKey ? faEyeSlash : faEye}
          className='mr-1'
          size='sm'
          onClick={() => setShowApiKey(!showApiKey)}
          aria-label={showApiKey ? 'hide-eye-icon' : 'show-eye-icon'}
        />
      </div>
      <IconButton
        leftIcon={
          <ZenCopyToClipBoard
            label=''
            value={apiKey || ''}
            variant='secondary'
          />
        }
        size='xs'
        variant='none'
        toolTipText='Copy Key'
        toolTipStyles='w-auto'
      />
    </div>
  );
};

export default AgentClientApiKeyField;
