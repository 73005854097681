import { isEmpty } from 'lodash';
import {
  CommissionDocumentPayeeResponse,
  CommissionDocumentPayeeResponseTransactionParticipantRoleEnum,
  CommissionDocumentResponse,
} from '../openapi/arrakis';
import { displayAmount } from './CurrencyUtils';
import { capitalizeEnum } from './StringUtils';

export const getDocumentIdentifier = (
  commissionDocument: CommissionDocumentResponse,
): string => {
  return `${commissionDocument.transactionCode}-${commissionDocument.revision}`;
};

export const isRealParticipantForCDA = (
  payee: CommissionDocumentPayeeResponse,
): boolean => {
  return (
    payee.transactionParticipantRole ===
    CommissionDocumentPayeeResponseTransactionParticipantRoleEnum.Real
  );
};

export const getCDATaxLineItem = (
  payee: CommissionDocumentPayeeResponse,
  cdaDetails: CommissionDocumentResponse,
): string => {
  if (isEmpty(payee) || isEmpty(cdaDetails)) {
    return 'N/A';
  }

  const payeeTax = payee?.totalTaxesByType?.[0];
  const totalAmount = isRealParticipantForCDA(payee)
    ? cdaDetails?.expectedPaymentToReal
    : payee?.amount;

  return `${payee?.fullName} disbursement of ${displayAmount(
    totalAmount,
  )} includes tax amount of ${displayAmount(payeeTax?.amount)} ${
    payeeTax?.type ? `(${capitalizeEnum(payeeTax?.type!)})` : ''
  }`;
};
