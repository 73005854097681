import { cn } from '../utils/classUtils';

interface CheckBoxProps {
  value: boolean;
  onChange(): void;
  isCheckedOnce?: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  value,
  onChange,
  isCheckedOnce,
}) => {
  return (
    <input
      type='checkbox'
      className={cn(
        'rounded-full border-none ring-1 focus:outline-none ring-gray-500 focus:ring-0 h-4 w-4 text-primary',
        isCheckedOnce && !value && 'ring-red-500',
      )}
      onChange={onChange}
      checked={value}
    />
  );
};
export default CheckBox;
