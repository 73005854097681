import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface ReferralOverviewCardProps {
  title: string;
  icon: IconProp;
  subtitle: string;
}

const ReferralOverviewCard: React.FC<ReferralOverviewCardProps> = ({
  title,
  icon,
  subtitle,
}): JSX.Element => {
  return (
    <div className='flex flex-col justify-start items-center px-2 py-3 bg-grey-100 rounded-lg gap-1'>
      <div className='justify-start items-center gap-1.5 flex -ml-2'>
        <FontAwesomeIcon
          icon={icon}
          className='text-primary-dark text-sm font-semibold font-inter leading-relaxed'
        />
        <div className='text-primary-dark text-xl font-semibold font-inter leading-relaxed'>
          {title}
        </div>
      </div>
      <div className='self-stretch text-center text-grey-500 text-sm font-medium font-inter leading-tight'>
        {subtitle}
      </div>
    </div>
  );
};

export default ReferralOverviewCard;
