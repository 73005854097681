import {
  TeamMemberResponse,
  TeamMemberResponseRolesEnum,
  UserPreviewResponse,
} from '../../../../openapi/yenta';
import ZenTeamAgentNameCell from './ZenTeamAgentNameCell';
import ZenMultiLeaderNameCell from './ZenMultiLeaderNameCell';

export interface ZenTeamLeaderNameCellProps {
  agents: TeamMemberResponse[];
}

const ZenTeamLeaderNameCell: React.FC<ZenTeamLeaderNameCellProps> = ({
  agents,
}) => {
  const leaders: TeamMemberResponse[] = agents.filter((obj) => {
    return obj.roles![0] === TeamMemberResponseRolesEnum.Leader;
  });
  const leaderAgents: UserPreviewResponse[] = leaders.map((ele) => ele.agent!);

  return (
    <div>
      {leaderAgents.length === 1 ? (
        <ZenTeamAgentNameCell
          agent={leaderAgents[0]}
          role={TeamMemberResponseRolesEnum.Leader}
          showLeaderIcon={false}
        />
      ) : (
        <>
          {leaderAgents.length > 1 ? (
            <div className='flex items-center'>
              <ZenMultiLeaderNameCell users={leaderAgents!} numToDisplay={2} />
            </div>
          ) : (
            <div className='font-zen-body font-normal'>N/A</div>
          )}
        </>
      )}
    </div>
  );
};

export default ZenTeamLeaderNameCell;
