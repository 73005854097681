import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import qs from 'qs';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Column, Row } from 'react-table';
import ZenCreateOfficeForm from '../components/office/ZenCreateOfficeForm';
import NullableTextCell from '../components/table/Cells/NullableTextCell';
import PhoneNumberCell from '../components/table/Cells/PhoneNumberCell';
import ZenOfficeTransactionTypeCell from '../components/table/Cells/ZenOfficeTransactionTypeCell';
import ZenViewActionButtonCell from '../components/table/Cells/ZenViewActionButtonCell';
import OfficeResponseTransactionTypeSelectColumnFilter from '../components/table/Filters/OfficeResponseTransactionTypeSelectColumnFilter';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import TextStrictCaseColumnFilter from '../components/table/Filters/TextStrictCaseColumnFilter';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenButton from '../components/Zen/ZenButton';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenStatusPill from '../components/Zen/ZenStatusPill';
import { OfficeResponse } from '../openapi/yenta';
import { ActiveInactiveType } from '../types';
import { isSmScreen } from '../utils/BrowserUtils';
import { formatAddressObject } from '../utils/StringUtils';
import { enumFilter, officeTableFetchData } from '../utils/TableUtils';

interface ZenOfficeIndexRouteProps {}

export const columns: Array<Column<OfficeResponse>> = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }) => <p className='font-zen-body font-normal'>{value}</p>,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Transaction Type',
    accessor: 'transactionType',
    Cell: ({ value }) => (
      <ZenOfficeTransactionTypeCell transactionType={value!} />
    ),
    Filter: OfficeResponseTransactionTypeSelectColumnFilter,
  },
  {
    Header: 'Address',
    accessor: 'address',
    Cell: ({ value }) => (
      <div className='font-zen-body font-normal'>
        <NullableTextCell text={value ? formatAddressObject(value) : null} />
      </div>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Administrative Area ID',
    accessor: 'administrativeArea',
    Cell: ({ value }) => (
      <div className='font-zen-body font-normal'>
        <NullableTextCell text={value?.id} />
      </div>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    Cell: ({ value }) => (
      <div className='font-zen-body font-normal'>
        <PhoneNumberCell phoneNumber={value} />
      </div>
    ),
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'active',
    Cell: ({ value }) => (
      <ZenStatusPill
        text={!!value ? 'Active' : 'Inactive'}
        variant={!!value ? 'primary' : 'danger'}
      />
    ),
    disableFilters: true,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => <p className='font-zen-body font-normal'>{value}</p>,
    Filter: TextStrictCaseColumnFilter,
  },
];

export const columnsWithAction: Array<Column<OfficeResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<OfficeResponse> }) => (
      <Link to={`/offices/${row.original.id}`}>
        <ZenViewActionButtonCell />
      </Link>
    ),
  },
  ...columns,
];

export const columnsToFetch = columns.map((col) => col.accessor as string);

const ZenOfficeIndexRoute: React.FC<ZenOfficeIndexRouteProps> = () => {
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState<string>('Active');
  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );
  const [openCreateOfficeForm, setOpenCreateOfficeForm] = useState<boolean>(
    false,
  );

  return (
    <ZenRoute title='Offices'>
      <ZenPageLayoutWithSearch
        path={[
          { title: 'Home', url: '/' },
          { title: 'Offices', url: '/offices' },
        ]}
        search={search}
        onSearchChange={setSearch}
      >
        <div className='px-4 lg:py-5'>
          <ZenResourceIndexContainer<OfficeResponse>
            header='Offices'
            columns={columnsWithAction}
            search={search}
            resourceName='office'
            RightComponent={
              <ZenButton
                LeftIconComponent={
                  <FontAwesomeIcon icon={faPlus} className='mx-1' />
                }
                label='Create an Office'
                variant='primary'
                onClick={() => setOpenCreateOfficeForm(true)}
                className='whitespace-nowrap'
              />
            }
            tabs={[
              {
                name: 'Active',
                filter: {
                  active: enumFilter('active', ActiveInactiveType.ACTIVE),
                },
              },
              {
                name: 'Inactive',
                filter: {
                  active: enumFilter('active', ActiveInactiveType.INACTIVE),
                },
              },
            ]}
            fetchData={async (req, cancelToken) => {
              const res = await officeTableFetchData(req, undefined, {
                cancelToken,
              });
              return {
                data: res.data as OfficeResponse[],
                total: res.total,
              };
            }}
            showSearch={isSmScreen()}
            defaultSelectedTabName={currentTab}
            setCurrentTab={setCurrentTab}
            stickyHeader
          />
        </div>
        {!!openCreateOfficeForm && (
          <ZenCreateOfficeForm
            isOpen
            onClose={() => setOpenCreateOfficeForm(false)}
          />
        )}
      </ZenPageLayoutWithSearch>
    </ZenRoute>
  );
};

export default ZenOfficeIndexRoute;
