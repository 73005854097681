import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { ISelectOption } from '../types';
import ControlledCheckboxGroupItem from './ControlledCheckboxGroupItem';

interface ControlledCheckboxGroupV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label: string;
  options: ISelectOption[];
}

const ControlledCheckboxGroupV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  options,
  shouldUnregister = true,
  ...rest
}: ControlledCheckboxGroupV7Props<TFieldValues, TName>) => {
  const oddItems = options.filter((_, index) => index % 2 === 0);
  const evenItems = options.filter((_, index) => index % 2 !== 0);

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({ field: { name, onChange, value } }) => (
        <div>
          {!!label && <label htmlFor={name}>{label}</label>}
          <div className=' flex flex-row'>
            <div className='flex-grow'>
              <ControlledCheckboxGroupItem
                items={oddItems}
                onToggle={onChange}
                value={value}
              />
            </div>
            <div className='flex-grow'>
              <ControlledCheckboxGroupItem
                items={evenItems}
                onToggle={onChange}
                value={value}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};
export default ControlledCheckboxGroupV7;
