import React, { useMemo, useState } from 'react';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import ZenTabs, { ZenTab } from '../../Zen/ZenTabs';
import RevShareInsightsNetworkTierAgents from './networks/RevShareInsightsNetworkTierAgents';
import RevShareInsightsNetworkUplineAgents from './networks/RevShareInsightsNetworkUplineAgents';

interface RevShareInsightsNetworksSidebarProps {
  isOpen: boolean;
  onClose(): void;
  initialTier: number;
  agentId: string;
}

const RevShareInsightsNetworksSidebar: React.FC<RevShareInsightsNetworksSidebarProps> = ({
  isOpen,
  onClose,
  initialTier,
  agentId,
}) => {
  const [currentTab, setCurrentTab] = useState('Tier Agents');

  const tabs: ZenTab[] = useMemo(
    () => [
      {
        name: 'Tier Agents',
        TabComponent: (
          <RevShareInsightsNetworkTierAgents
            agentId={agentId}
            initialTier={initialTier}
          />
        ),
      },
      {
        name: 'Upline Agents',
        TabComponent: <RevShareInsightsNetworkUplineAgents agentId={agentId} />,
      },
    ],
    [agentId, initialTier],
  );

  return (
    <ZenSidebarModal title='My Network' isOpen={isOpen} onClose={onClose}>
      <ZenTabs
        tabs={tabs}
        onChange={setCurrentTab}
        selected={currentTab}
        equalWidths
        size='sm'
      />
    </ZenSidebarModal>
  );
};

export default RevShareInsightsNetworksSidebar;
