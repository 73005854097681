import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { ApplicationResponse } from '../../openapi/yenta';
import { getApplicationDriverLicenseImage } from '../../slices/ApplicationSlice';
import { AppDispatch } from '../../types';
import { capitalizeEnum, formatPhoneNumber } from '../../utils/StringUtils';
import ZenCard from '../Zen/ZenCard';
import DataTable from '../DataTable';
import ZenDataRow from '../Zen/ZenDataRow';
import ZenBooleanButton from '../Agent/V2/ZenBooleanButton';
import ZenButton from '../Zen/ZenButton';

interface ApplicationDetailCardProps {
  application: ApplicationResponse;
}

const ApplicationDetailCard: React.FC<ApplicationDetailCardProps> = ({
  application,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const handleDriverLicenceImage = async () => {
    const licenseImage = await dispatch(
      getApplicationDriverLicenseImage(application?.id!),
    );
    if (licenseImage) {
      window.open(licenseImage, '_blank');
    }
  };

  const doesBusinessInExtended = (application.doesBusinessInExtended || []).map(
    (item) => {
      let data;
      let state = capitalizeEnum(
        item.licenseResponse?.administrativeArea?.stateOrProvince!,
      );
      data = [
        {
          name: 'State / Province',
          value: state || 'N/A',
        },
        {
          name: `What is the name of the MLS you are a member of or will be joining in ${state}?`,
          value: item?.mlses?.[0] || 'N/A',
        },
        {
          name: `What is the name of the local Board of Realtors (Association of Realtors) you are a member of or will be joining in ${state}?`,
          value: item?.boards?.[0] || 'N/A',
        },
        {
          name: `License Number in ${state}`,
          value: item?.licenseResponse?.number || 'N/A',
        },
        {
          name: `License Active in ${state}?`,
          value: <ZenBooleanButton value={item?.licenseResponse?.active!} />,
        },
        {
          name: `License Expiration Date in ${state}`,
          value: item?.licenseResponse?.expirationDate
            ? DateTime.fromISO(
                item?.licenseResponse?.expirationDate,
              ).toLocaleString()
            : 'N/A',
        },
        {
          name: `Registered Complaint filed in ${state}?`,
          value: (
            <ZenBooleanButton value={item?.licenseResponse?.knownComplaints!} />
          ),
        },
      ];
      return data;
    },
  );
  const applicationItems = [
    {
      name: 'Phone Number',
      value: formatPhoneNumber(application?.phoneNumber!)!,
    },
    {
      name: 'Date of Birth',
      value: application?.birthDate!,
    },
    {
      name: "Driver's License",
      value: !!application?.driverLicenseImagePath ? (
        <ZenButton onClick={handleDriverLicenceImage} label='View' />
      ) : (
        'N/A'
      ),
    },

    {
      name: 'Current Brokerage Name',
      value: application.currentBrokerage || 'N/A',
    },
    {
      name: 'Estimated Sales Volume in last 12 months',
      value: application.estimatedSales || 'N/A',
    },
    {
      name: 'Individual agent that will be on our $12,000 cap plan',
      value: application.teamName! ? 'No' : 'Yes',
    },
    {
      name:
        'Do you have a preferred title vendor that you currently work with?',
      value: application.preferredTitleVendor ? 'Yes' : 'No',
    },
    {
      name: 'Commercial-only Agent?',
      value: !!application.commercialAgent ? 'Yes' : 'No',
    },
    {
      name:
        'Do you have any pending transactions with your current or previous brokerage?',
      value: (
        <ZenBooleanButton
          value={application.hasPendingTransactionsWithCurrentBrokerage!}
        />
      ),
    },
  ];

  if (application.teamName) {
    applicationItems.splice(-2, 0, {
      name: 'Team name',
      value: application?.teamName! || 'N/A',
    });
  }

  if (application.preferredTitleVendor) {
    applicationItems.splice(-1, 0, {
      name: 'Preferred title vendor',
      value: application.preferredTitleVendor! || 'N/A',
    });
  }

  const gettableItems = (rowData: any) => {
    return rowData.filter((item: any) => item.name !== 'State / Province');
  };

  return (
    <div>
      <DataTable>
        {applicationItems.map((item) => (
          <ZenDataRow
            customValueStyle='px-4 py-4'
            customNameStyle='px-4 py-4'
            key={item.name}
            name={item.name}
            value={item.value}
          />
        ))}
      </DataTable>
      {doesBusinessInExtended.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className='m-4' key={index}>
          <ZenCard title={item[0].value as string}>
            <DataTable>
              {gettableItems(item).map((v: any) => (
                <ZenDataRow
                  customValueStyle='px-4 py-4 w-[30%]]'
                  customNameStyle='px-4 py-4 w-[70%]'
                  key={v.name}
                  name={v.name}
                  value={v.value}
                />
              ))}
            </DataTable>
          </ZenCard>
        </div>
      ))}
    </div>
  );
};

export default ApplicationDetailCard;
