import { debounce } from 'lodash';
import { useCallback } from 'react';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';
import { DirectoryCommonEntityResponse } from '../../query/directory/useDirectory';
import { extractNationalBusinessIds } from './utils';

interface ExternalAgentConfirmationModalProps {
  isConfirmationModalOpen: boolean;
  setConfirmationModalOpen: (value: boolean) => void;
  companyVerify: string;
  completeSaveOperation: () => void;
  pendingSaveData: DirectoryCommonEntityResponse;
}

export const NationalIdText = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <div className='flex items-center justify-between text-base text-primary-dark font-inter font-light'>
      <p>{label}:</p>
      <p>{value}</p>
    </div>
  );
};

export const ExternalAgentConfirmationModal = ({
  isConfirmationModalOpen,
  setConfirmationModalOpen,
  companyVerify,
  pendingSaveData,
  completeSaveOperation,
}: ExternalAgentConfirmationModalProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string, eventData?: Record<string, unknown>) => {
      AnalyticsService.instance().logEvent(event, eventData);
    }, 500),
    [],
  );

  const name = pendingSaveData?.name;
  const address = pendingSaveData?.address?.oneLine;
  const {
    nationalId,
    gstNationalId,
    hstNationalId,
    qstNationalId,
    bnNationalId,
  } = extractNationalBusinessIds(
    pendingSaveData?.nationalBusinessIdentifications,
  );

  return (
    <ZenConfirmationModal
      isOpen={isConfirmationModalOpen}
      title={companyVerify}
      cancelButtonText='Cancel'
      size='large'
      confirmButtonText='Confirm'
      subtitle={
        <p className='text-base text-center text-primary-dark font-inter font-normal'>
          Please verify that{' '}
          <strong className='font-bold text-base font-inter text-primary-dark'>
            {name} at {address}{' '}
          </strong>
          is the correct referring company.
        </p>
      }
      onClose={() => {
        setConfirmationModalOpen(false);
        logAnalyticsEvent(AnalyticsEventEnum.VERIFY_ADDRESS_AND_TAX_ID_CANCEL);
      }}
      onConfirm={() => {
        completeSaveOperation();
        logAnalyticsEvent(AnalyticsEventEnum.VERIFY_ADDRESS_AND_TAX_ID_CONFIRM);
      }}
      onIconClose={() => {
        setConfirmationModalOpen(false);
        logAnalyticsEvent(AnalyticsEventEnum.VERIFY_ADDRESS_AND_TAX_ID_CLICK_X);
      }}
    >
      <div className='flex flex-col p-6 mb-10'>
        <p className='text-base text-primary-dark font-inter font-normal text-center'>
          Please confirm{' '}
          <strong className='font-bold text-base font-inter text-primary-dark'>
            {name}
          </strong>
          &apos;s {nationalId ? 'EIN' : 'Tax Number'}
        </p>
        <div className='flex items-center justify-center'>
          <div className='bg-zen-light-gray-1 w-80 h-auto border rounded-2xl p-4 mt-2 items-center'>
            {nationalId && (
              <NationalIdText label='EIN Number' value={nationalId} />
            )}
            {bnNationalId && (
              <NationalIdText label='Business Number' value={bnNationalId} />
            )}
            {gstNationalId && (
              <NationalIdText label='GST Number' value={gstNationalId} />
            )}
            {hstNationalId && (
              <NationalIdText label='HST Number' value={hstNationalId} />
            )}
            {qstNationalId && (
              <NationalIdText label='QST Number' value={qstNationalId} />
            )}
          </div>
        </div>
      </div>
    </ZenConfirmationModal>
  );
};
