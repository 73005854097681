import React from 'react';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import OneRealTitleMap from '../../assets/img/zen/real-title/one-real-title-map.svg';
import { CHECK_THE_MAP_LINK } from '../../constants/RealTitleConstants';
import ButtonV2 from '../ButtonV2';

const RealClosingServicesCoverageSection: React.FC = () => {
  return (
    <div
      className='flex flex-col lg:flex-row lg:items-center justify-between gap-12 lg:p-10'
      data-testid='real-closing-services-coverage-section'
    >
      <div>
        <h1 className='font-poppins text-[40px] font-semibold leading-[48px]'>
          One Real <span className='text-rezen-blue-600'>Coverage</span>
        </h1>
        <p className='font-inter text-primary-dark text-lg font-light my-4'>
          We are in so many different states!
        </p>
        <ButtonV2
          label='Go to One Real'
          icon={faArrowUpRightFromSquare}
          className='mt-5'
          onClick={() => {
            window.open(CHECK_THE_MAP_LINK, '_blank');
          }}
        />
      </div>
      <img
        src={OneRealTitleMap}
        alt='one real title map'
        className='w-full lg:w-[50%]'
        data-testid='one-real-title-map'
      />
    </div>
  );
};

export default RealClosingServicesCoverageSection;
