import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { getInboxLink } from '../../../utils/UrlUtils';
import ZenSideBarItem from '../../ZenSideBarItem';
import RealSignatureSidebarMenuItem from '../ZenMenu/RealSignatureSidebarMenuItem';
import ZenUnreadCountPill from '../ZenMenu/ZenUnreadCountPill';

interface ZenLoanOfficerMenuProps {
  isCollapsed: boolean;
}

const ZenLoanOfficerMenu: React.FC<ZenLoanOfficerMenuProps> = ({
  isCollapsed,
}) => {
  const location = useLocation();

  return (
    <div>
      <ZenSideBarItem
        collapsed={isCollapsed}
        icon={
          isCollapsed ? (
            <ZenUnreadCountPill isCollapsed={isCollapsed} />
          ) : (
            <FontAwesomeIcon icon={regular('inbox')} />
          )
        }
        label='Inbox'
        hideActive
        linkTo={getInboxLink(location.search)}
        target='_blank'
        BadgeComponent={<ZenUnreadCountPill />}
      />

      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={regular('money-bills')} />}
        label='Mortgage'
        linkTo='/mortgages'
        collapsed={isCollapsed}
      />

      <RealSignatureSidebarMenuItem isCollapsed={isCollapsed} />
    </div>
  );
};

export default ZenLoanOfficerMenu;
