import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Control } from 'react-hook-form-v7';
import { NUMBER_REGEX } from '../../utils/StringUtils';
import ZenControlledPercentageInput from '../Zen/Input/ZenControlledPercentageInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import { CreateJointVentureFormState } from './CreateJointVentureSteps';

export interface ShareDistributionCardProps {
  tierIndex: number;
  tierName: string;
  tierColor: string;
  control: Control<any, object>;
  showErrorExclamation: boolean;
}

const ShareDistributionCard: React.FC<ShareDistributionCardProps> = ({
  tierIndex,
  tierName,
  tierColor,
  control,
  showErrorExclamation,
}) => {
  return (
    <div className='flex flex-col rounded-lg border border-zen-dark-4'>
      <span className='font-zen-title font-medium text-xl text-zen-dark-9 p-5'>
        {tierName}
      </span>
      <div
        className='w-full h-2'
        style={{ backgroundColor: tierColor || '#000' }}
      />
      <div className='px-5 py-8 pb-7.5 mt-px grid grid-cols-2 gap-x-2'>
        <ZenControlledTextInput<
          CreateJointVentureFormState,
          `tiers.${number}.buyInShares`
        >
          control={control}
          name={`tiers.${tierIndex}.buyInShares`}
          label='Buy in shares'
          shouldUnregister={false}
          rules={{
            min: { value: 1, message: 'Number cannot be less than 1' },
            pattern: {
              value: NUMBER_REGEX,
              message: 'Please enter a valid number',
            },
            required: 'Buy in shares is required.',
          }}
        />
        <ZenControlledPercentageInput<
          CreateJointVentureFormState,
          `tiers.${number}.poolPercentShare`
        >
          control={control}
          name={`tiers.${tierIndex}.poolPercentShare`}
          label='Pool Share (%)'
          rules={{
            min: { value: 0, message: 'Number cannot be less than 0' },
            pattern: {
              value: NUMBER_REGEX,
              message: 'Please enter a valid number',
            },
            required: 'Pool Share (%) is required.',
            validate: (_v: number | undefined) => {
              if (showErrorExclamation) {
                return '';
              }
              return undefined;
            },
          }}
          shouldUnregister={false}
          endAdornment={
            showErrorExclamation ? (
              <div className='flex items-center justify-center ml-1 mr-3 h-full'>
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  className='text-zen-danger'
                  size='sm'
                />
              </div>
            ) : undefined
          }
        />
      </div>
    </div>
  );
};

export default ShareDistributionCard;
