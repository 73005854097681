import { cn } from '../../utils/classUtils';

type VariantType = 'default' | 'primary';

interface ZenTitleHrProps {
  title: string;
  variant?: VariantType;
}

export const variantTypeToClassNameMap: {
  [type in VariantType]: string;
} = {
  default: 'text-zen-dark-9',
  primary: 'text-primary-blue',
};

const ZenTitleHr: React.FC<ZenTitleHrProps> = ({
  title,
  variant = 'primary',
}) => {
  return (
    <div className='relative'>
      <span
        className={cn(
          'font-zen-body font-semibold relative z-10 text-lg pr-2 py-2 bg-white',
          variantTypeToClassNameMap[variant],
        )}
      >
        {title}
      </span>
      <div className='absolute border-b border-coolGray-200 top-1/2 z-0 w-full' />
    </div>
  );
};

export default ZenTitleHr;
