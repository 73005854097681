import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Control, FieldName, useFormContext } from 'react-hook-form-v7';
import {
  LeoTableHeaderFormData,
  TABS_ENUM,
} from '../../../routes/LeoQuestionManagerRoute';
import { EnumMap } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenControlledDatePickerInput from '../Input/ZenControlledDatePickerInput';
import ZenButton from '../ZenButton';

export interface DateRange {
  start?: string | undefined;
  end?: string | undefined;
}

interface LQMDateFilterProps {
  dateFilterLabel: DateFilterEnum;
  setDateFilterLabel(dateFilterType: DateFilterEnum): void;
  setDateFilter(range: DateRange): void;
  control: Control<LeoTableHeaderFormData, object>;
  tabName: TABS_ENUM;
}

export enum DateFilterEnum {
  ALL_TIME = 'ALL_TIME',
  TODAY = 'TODAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  CUSTOM_RANGE = 'CUSTOM_RANGE',
}

export interface DateFields {
  startDateField: FieldName<LeoTableHeaderFormData>;
  endDateField: FieldName<LeoTableHeaderFormData>;
}

const TabAndDateFieldMap: Record<TABS_ENUM, DateFields> = {
  [TABS_ENUM.WORK_QUEUE]: {
    startDateField: 'workQueueCustomStartDate',
    endDateField: 'workQueueCustomEndDate',
  },
  [TABS_ENUM.ASSIGNED_QUEUE]: {
    startDateField: 'assignedCustomStartDate',
    endDateField: 'assignedCustomEndDate',
  },
  [TABS_ENUM.COMPLETED_QUEUE]: {
    startDateField: 'completedCustomStartDate',
    endDateField: 'completedCustomEndDate',
  },
};

const LQMDateFilter: React.FC<LQMDateFilterProps> = ({
  dateFilterLabel,
  setDateFilterLabel,
  setDateFilter,
  control,
  tabName,
}) => {
  const [open, setOpen] = useState(false);
  const [customFilterOpen, setCustomFilterOpen] = useState(false);
  const { watch } = useFormContext<LeoTableHeaderFormData>();
  const formValues = watch();
  const dateFilterOptions: EnumMap<DateFilterEnum, { dateRange: DateRange }> = {
    [DateFilterEnum.ALL_TIME]: {
      dateRange: {
        start: undefined,
        end: undefined,
      },
    },
    [DateFilterEnum.TODAY]: {
      dateRange: {
        start: DateTime.now().toISODate(),
        end: DateTime.now().toISODate(),
      },
    },
    [DateFilterEnum.LAST_7_DAYS]: {
      dateRange: {
        start: DateTime.now().minus({ days: 6 }).toISODate(),
        end: DateTime.now().toISODate(),
      },
    },
    [DateFilterEnum.MONTH_TO_DATE]: {
      dateRange: {
        start: DateTime.now().startOf('month').toISODate(),
        end: DateTime.now().toISODate(),
      },
    },
    [DateFilterEnum.LAST_30_DAYS]: {
      dateRange: {
        start: DateTime.now().minus({ days: 30 }).toISODate(),
        end: DateTime.now().toISODate(),
      },
    },
    [DateFilterEnum.LAST_90_DAYS]: {
      dateRange: {
        start: DateTime.now().minus({ days: 90 }).toISODate(),
        end: DateTime.now().toISODate(),
      },
    },
    [DateFilterEnum.YEAR_TO_DATE]: {
      dateRange: {
        start: DateTime.now().startOf('year').toISODate(),
        end: DateTime.now().toISODate(),
      },
    },
    [DateFilterEnum.CUSTOM_RANGE]: {
      dateRange: {},
    },
  };

  const dateChange = (dateLabel: DateFilterEnum) => {
    if (dateLabel === DateFilterEnum.CUSTOM_RANGE) {
      setCustomFilterOpen(true);
    } else {
      setDateFilterLabel(dateLabel);
      setDateFilter(dateFilterOptions[dateLabel].dateRange);
      setOpen(false);
    }
  };

  const handleCustomDateFilter = () => {
    const startDateFieldName = TabAndDateFieldMap[tabName].startDateField;
    const endDateFieldName = TabAndDateFieldMap[tabName].endDateField;
    if (startDateFieldName && endDateFieldName) {
      setDateFilterLabel(DateFilterEnum.CUSTOM_RANGE);
      setDateFilter({
        // @ts-expect-error
        start: formValues[startDateFieldName],
        // @ts-expect-error
        end: formValues[endDateFieldName],
      });
      setOpen(false);
    }
  };

  const getMinDatePickerConfig = (endDate?: string) => ({
    dropdownMode: 'scroll',
    dateFormatCalendar: 'LLLL - yyyy',
    maxDate: endDate
      ? DateTime.fromFormat(endDate, 'yyyy-MM-dd').minus({ days: 1 }).toJSDate()
      : undefined,
  });

  const getMaxDatePickerConfig = (startDate?: string) => ({
    dropdownMode: 'scroll',
    dateFormatCalendar: 'LLLL - yyyy',
    minDate: startDate
      ? DateTime.fromFormat(startDate, 'yyyy-MM-dd')
          .plus({ days: 1 })
          .toJSDate()
      : DateTime.now().plus({ days: 1 }).toJSDate(),
  });

  useEffect(() => {
    if (dateFilterLabel === DateFilterEnum.CUSTOM_RANGE) {
      setCustomFilterOpen(true);
    } else {
      setCustomFilterOpen(false);
    }
  }, [dateFilterLabel]);

  return (
    <section className='relative w-full md:w-auto' title='table-options'>
      <ZenButton
        LeftIconComponent={
          <FontAwesomeIcon
            icon={faCalendar}
            className='text-zen-dark-7'
            aria-label='calender-icon'
          />
        }
        isFullWidth
        RightIconComponent={
          <FontAwesomeIcon
            icon={solid('chevron-down')}
            className='text-zen-dark-7'
            aria-label='calender-icon'
          />
        }
        label={capitalizeEnum(dateFilterLabel)}
        variant='secondary-gray-text-outline'
        onClick={() => setOpen(!open)}
      />

      {open && (
        <div className='absolute right-0 z-10 mt-1 bg-white rounded-lg shadow-lg w-52 ring-2 ring-gray-200'>
          {Object.keys(dateFilterOptions).map((val) => {
            const dateLabel = val as DateFilterEnum;
            return (
              <div className='font-zen-body' key={dateLabel}>
                <div className='p-3 border-t border-gray-200'>
                  <label className='flex items-center justify-start space-x-2'>
                    <input
                      type='checkbox'
                      className='w-4 h-4 border-none rounded-full ring-1 ring-gray-200 focus:outline-none focus:ring-0 text-primary-blue'
                      onChange={() => {
                        dateChange(dateLabel);
                      }}
                      checked={
                        customFilterOpen
                          ? dateLabel === DateFilterEnum.CUSTOM_RANGE
                          : dateFilterLabel === dateLabel
                      }
                    />
                    <div className='font-zen-body text-base'>
                      {capitalizeEnum(dateLabel)}
                    </div>
                  </label>
                </div>
                {customFilterOpen && dateLabel === DateFilterEnum.CUSTOM_RANGE && (
                  <div>
                    {tabName === TABS_ENUM.WORK_QUEUE && (
                      <div className='p-2 bg-gray-100'>
                        <ZenControlledDatePickerInput<LeoTableHeaderFormData>
                          control={control}
                          shouldUnregister={false}
                          name='workQueueCustomStartDate'
                          placeholder='Start Date'
                          // @ts-expect-error
                          datePickerConfig={getMinDatePickerConfig(
                            formValues.workQueueCustomEndDate,
                          )}
                          endAdornment={
                            <FontAwesomeIcon
                              icon={faCalendar}
                              className='text-primary-blue text-lg'
                            />
                          }
                        />
                        <div className='mt-1'>
                          <ZenControlledDatePickerInput<LeoTableHeaderFormData>
                            control={control}
                            shouldUnregister={false}
                            name='workQueueCustomEndDate'
                            placeholder='End Date'
                            // @ts-expect-error
                            datePickerConfig={getMaxDatePickerConfig(
                              formValues.workQueueCustomStartDate,
                            )}
                            endAdornment={
                              <FontAwesomeIcon
                                icon={faCalendar}
                                className='text-primary-blue text-lg'
                              />
                            }
                          />
                        </div>
                      </div>
                    )}
                    {tabName === TABS_ENUM.ASSIGNED_QUEUE && (
                      <div className='p-2 bg-gray-100'>
                        <ZenControlledDatePickerInput<LeoTableHeaderFormData>
                          control={control}
                          shouldUnregister={false}
                          name='assignedCustomStartDate'
                          placeholder='Start Date'
                          // @ts-expect-error
                          datePickerConfig={getMinDatePickerConfig(
                            formValues.assignedCustomEndDate,
                          )}
                          endAdornment={
                            <FontAwesomeIcon
                              icon={faCalendar}
                              className='text-primary-blue text-lg'
                            />
                          }
                        />
                        <div className='mt-1'>
                          <ZenControlledDatePickerInput<LeoTableHeaderFormData>
                            control={control}
                            shouldUnregister={false}
                            name='assignedCustomEndDate'
                            placeholder='End Date'
                            // @ts-expect-error
                            datePickerConfig={getMaxDatePickerConfig(
                              formValues.assignedCustomStartDate,
                            )}
                            endAdornment={
                              <FontAwesomeIcon
                                icon={faCalendar}
                                className='text-primary-blue text-lg'
                              />
                            }
                          />
                        </div>
                      </div>
                    )}
                    {tabName === TABS_ENUM.COMPLETED_QUEUE && (
                      <div className='p-2 bg-gray-100'>
                        <ZenControlledDatePickerInput<LeoTableHeaderFormData>
                          control={control}
                          shouldUnregister={false}
                          name='completedCustomStartDate'
                          placeholder='Start Date'
                          // @ts-expect-error
                          datePickerConfig={getMinDatePickerConfig(
                            formValues.completedCustomEndDate,
                          )}
                          endAdornment={
                            <FontAwesomeIcon
                              icon={faCalendar}
                              className='text-primary-blue text-lg'
                            />
                          }
                        />
                        <div className='mt-1'>
                          <ZenControlledDatePickerInput<LeoTableHeaderFormData>
                            control={control}
                            shouldUnregister={false}
                            name='completedCustomEndDate'
                            placeholder='End Date'
                            // @ts-expect-error
                            datePickerConfig={getMaxDatePickerConfig(
                              formValues.completedCustomStartDate,
                            )}
                            endAdornment={
                              <FontAwesomeIcon
                                icon={faCalendar}
                                className='text-primary-blue text-lg'
                              />
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className='mt-2'>
                      <ZenButton
                        label='Apply'
                        isFullWidth
                        onClick={handleCustomDateFilter}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default LQMDateFilter;
