import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  ApplicationApi,
  ApplicationProgressResponse,
  BankCustomerAccounts,
  BankingApi,
  BorrowerApplicationDto,
  BorrowerForCommentDto,
  BorrowersApi,
  CreateChecklistFileUploadQuestion,
  CreateLead,
  LoanDto,
  LoansApi,
  MortgageLeadsApi,
} from '../openapi/atlantis';
import ErrorService from '../services/ErrorService';
import {
  AppDispatch,
  AppThunk,
  AsyncResponse,
  MortgageState,
  UpdateLoanRequestStatusEnum,
} from '../types';
import { getAtlantisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from './ErrorSlice';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from './ToastNotificationSlice';
import { performAsyncRequest } from './utils/SliceUtil';

export const initialState: MortgageState = {
  borrowerProgress: {},
  loanResponse: { loading: false, name: 'loan details' },
  uploadingPreApprovalLetter: false,
  borrowerApplications: {},
  borrowerAccountsResponse: { loading: false, name: 'borrower accounts' },
  formValidityById: undefined,
  showApplicationError: false,
  borrowerDetailsForInbox: { loading: false, name: 'borrower comment' },
};

const MortgageSlice = createSlice({
  name: 'mortgages',
  initialState,
  reducers: {
    saveLoanResponse(state, action: PayloadAction<AsyncResponse<LoanDto>>) {
      state.loanResponse = action.payload;
    },
    setUploadingPreApprovalLetter(state, action: PayloadAction<boolean>) {
      state.uploadingPreApprovalLetter = action.payload;
    },
    saveBorrowerProgress(
      state,
      action: PayloadAction<
        AsyncResponse<ApplicationProgressResponse, { borrowerId: string }>
      >,
    ) {
      state.borrowerProgress[action.payload.additionalProps!.borrowerId!] =
        action.payload;
    },
    saveBorrowerApplication(
      state,
      action: PayloadAction<
        AsyncResponse<BorrowerApplicationDto, { borrowerId: string }>
      >,
    ) {
      state.borrowerApplications[action.payload.additionalProps?.borrowerId!] =
        action.payload;
    },
    setFetchLoanResponseLoading(state, action: PayloadAction<boolean>) {
      state.loanResponse.loading = action.payload;
    },
    saveBorrowerAccounts(
      state,
      action: PayloadAction<AsyncResponse<BankCustomerAccounts>>,
    ) {
      state.borrowerAccountsResponse = action.payload;
    },
    setFormValidityById(
      state,
      action: PayloadAction<Record<string, boolean> | undefined>,
    ) {
      if (action.payload) {
        state.formValidityById = {
          ...state.formValidityById,
          ...action.payload,
        };
      } else {
        state.formValidityById = undefined;
      }
    },
    setShowApplicationError(state, action: PayloadAction<boolean>) {
      state.showApplicationError = action.payload;
    },
    saveBorrowerDetailsForInbox(
      state,
      action: PayloadAction<AsyncResponse<BorrowerForCommentDto>>,
    ) {
      state.borrowerDetailsForInbox = action.payload;
    },
  },
});

export const {
  saveLoanResponse,
  setUploadingPreApprovalLetter,
  saveBorrowerProgress,
  saveBorrowerApplication,
  setFetchLoanResponseLoading,
  saveBorrowerAccounts,
  setFormValidityById,
  setShowApplicationError,
  saveBorrowerDetailsForInbox,
} = MortgageSlice.actions;

export const createMortgageLead = (
  requestBody: CreateLead,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new MortgageLeadsApi(getAtlantisConfiguration()).createMortgageLead(
      requestBody,
    );
    dispatch(showSuccessToast('Buyer invited successfully.'));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to invite buyer. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to invite buyer', e, {
      data: requestBody,
    });
  }

  return false;
};

export const fetchLoanById = (
  loanId: string,
  changeLoading: boolean = true,
): AppThunk => async (dispatch) => {
  performAsyncRequest(
    dispatch as AppDispatch,
    'loan details',
    saveLoanResponse,
    () => new LoansApi(getAtlantisConfiguration()).getLoanByLoanId(loanId),
    { changeLoading },
  );
};

export const uploadPreApprovalLetter = (
  loanId: string,
  preApprovalLetter: File,
): AppThunk => async (dispatch) => {
  dispatch(setUploadingPreApprovalLetter(true));
  try {
    await new LoansApi(getAtlantisConfiguration()).uploadPreApprovalLetter(
      loanId,
      preApprovalLetter,
    );

    const { data } = await new LoansApi(
      getAtlantisConfiguration(),
    ).getLoanByLoanId(loanId);

    dispatch(
      saveLoanResponse({ name: 'loan details', data: data, loading: false }),
    );

    dispatch(showSuccessToast('Pre-approval letter uploaded successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to upload pre-approval letter. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to upload pre-approval letter', e, {
      req: { loanId },
    });
  } finally {
    dispatch(setUploadingPreApprovalLetter(false));
  }
};

export const fetchBorrowerProgress = (
  borrowerId: string,
  changeLoading: boolean = true,
) => async (dispatch: AppDispatch) => {
  return performAsyncRequest(
    dispatch,
    'borrower progress',
    saveBorrowerProgress,
    () =>
      new ApplicationApi(getAtlantisConfiguration()).getProgressSummary(
        borrowerId,
      ),
    { additionalProps: { borrowerId }, changeLoading },
  );
};

export const fetchBorrowerQuestions = (
  borrowerId: string,
  changeLoading = true,
): AppThunk => async (dispatch) => {
  return performAsyncRequest(
    dispatch as AppDispatch,
    'borrower questions',
    saveBorrowerApplication,
    () =>
      new ApplicationApi(getAtlantisConfiguration()).getAllBorrowerQuestions(
        borrowerId,
      ),
    { additionalProps: { borrowerId }, changeLoading },
  );
};

export const assignAuthorizedPersonToLoan = (
  loanId: string,
  authorizedPersonIds: string[],
): AppThunk => async (dispatch) => {
  try {
    await new LoansApi(getAtlantisConfiguration()).assignAuthorizedPersonToLoan(
      loanId,
      {
        authorizedPersonIds,
      },
    );
    dispatch(fetchLoanById(loanId, false));
    dispatch(showSuccessToast('Assigned successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to assign. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to assign authorized person to loan', e, {
      req: { loanId, authorizedPersonIds },
    });
  }
};

export const removeAuthorizedPersonFromLoan = (
  loanId: string,
  authorizedPersonId: string,
): AppThunk => async (dispatch) => {
  try {
    await new LoansApi(
      getAtlantisConfiguration(),
    ).removeAuthorizedPersonFromLoan(loanId, authorizedPersonId);
    dispatch(fetchLoanById(loanId, false));
    dispatch(showSuccessToast('Unassigned successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update unassign. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to unassign authorized person to loan', e, {
      req: { loanId, authorizedPersonId },
    });
  }
};

export const updateLoanRealEstateAgent = (
  loanId: string,
  agentId: string,
): AppThunk => async (dispatch) => {
  try {
    await new LoansApi(getAtlantisConfiguration()).updateAgent(loanId, agentId);
    dispatch(fetchLoanById(loanId, false));
    dispatch(showSuccessToast('Agent updated successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update agent. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to update agent', e, {
      req: { loanId, agentId },
    });
  }
};

export const updateApplicationStatus = (
  loanId: string,
  status: UpdateLoanRequestStatusEnum,
): AppThunk => async (dispatch) => {
  try {
    await new LoansApi(getAtlantisConfiguration()).updateLoanStatus(
      loanId,
      status,
    );
    dispatch(fetchLoanById(loanId));
    dispatch(showSuccessToast('Application status updated successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update application status. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to update application status', e, {
      req: { loanId, status },
    });
  }
};

export const addFileQuestion = (
  createChecklistFileUploadQuestionRequest: CreateChecklistFileUploadQuestion,
  loanId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new LoansApi(getAtlantisConfiguration()).addChecklistQuestion(
      createChecklistFileUploadQuestionRequest,
    );

    dispatch(fetchLoanById(loanId));
    dispatch(showSuccessToast('Question added successfully.'));

    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to add a question. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to add a question', e, {
      loan: {
        loanId,
        questionRequest: createChecklistFileUploadQuestionRequest,
      },
    });
  }

  return false;
};

export const fetchBorrowerDetailsForInbox = (
  borrowerId: string,
): AppThunk<Promise<AxiosResponse<BorrowerForCommentDto> | void>> => async (
  dispatch,
) => {
  return performAsyncRequest(
    dispatch as AppDispatch,
    'borrower comment',
    saveBorrowerDetailsForInbox,
    () =>
      new BorrowersApi(getAtlantisConfiguration()).getBorrowerForCommentDto(
        borrowerId,
      ),
    { additionalProps: { borrowerId } },
  );
};

export const submitApplication = (
  loanId: string,
  borrowerId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    dispatch(setFetchLoanResponseLoading(true));
    await new ApplicationApi(getAtlantisConfiguration()).submitApplication(
      borrowerId,
    );

    dispatch(fetchLoanById(loanId));

    dispatch(showSuccessToast('Application submitted successfully.'));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));

    dispatch(
      showErrorToastForErrorCode(
        'We were unable to submit application.',
        ErrorService.getErrorCode(e),
      ),
    );

    ErrorService.notify('Unable to submit application', e, {
      req: { loanId, borrowerId },
    });
    dispatch(setFetchLoanResponseLoading(false));
  }
  return false;
};

export const fetchBorrowerAccounts = (borrowerId: string): AppThunk => async (
  dispatch,
) => {
  performAsyncRequest(
    dispatch as AppDispatch,
    'borrower accounts',
    saveBorrowerAccounts,
    () =>
      new BankingApi(getAtlantisConfiguration()).getBankCustomerAccounts(
        borrowerId,
      ),
  );
};

export default MortgageSlice.reducer;
