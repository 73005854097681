import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsService from '../../../../services/AnalyticsService';
import { chatReply, saveShowIntro } from '../../../../slices/LeoSlice';
import { AnalyticsEventEnum, RootState } from '../../../../types';
import LeoControlledTextInput from '../../Input/LeoControlledInput';
import { ChatFormData } from './LeoChatIndex';

interface LeoChatInputProps {
  closePrompts(): void;
}

const LeoChatInput: React.FC<LeoChatInputProps> = ({ closePrompts }) => {
  const { control, reset, watch, setFocus } = useFormContext<ChatFormData>();
  const {
    leo: { session, isLeoThinking, currentChat, selectedItems, msgContext },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const askLeoText = watch('askLeoText');
  const isDisabled = !session?.id || isLeoThinking;

  const onSubmit = async (text: string) => {
    if (session?.id && !!text?.trim()) {
      reset({ askLeoText: '' });
      closePrompts();
      dispatch(saveShowIntro(false));
      const updateSessionTitle =
        currentChat.length === 0 ||
        (currentChat.length === 1 &&
          currentChat[0].message === session?.disclaimer);
      await dispatch(
        chatReply(
          session?.id,
          { prompt: text, title: text, handler_type: '' },
          updateSessionTitle,
          selectedItems.length
            ? [
                {
                  type: msgContext?.details[0]?.type || '',
                  items: selectedItems,
                },
              ]
            : undefined,
        ),
      );
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.LEO_MESSAGE_SEND_CLICK,
      );
    }
  };

  useEffect(() => {
    if (isDisabled) {
      return;
    }
    setFocus('askLeoText');
  }, [isDisabled, setFocus]);

  return (
    <div className='flex flex-row items-end pt-2'>
      <div className='flex-grow'>
        <LeoControlledTextInput<ChatFormData, 'askLeoText'>
          control={control}
          name='askLeoText'
          placeholder='Ask Leo'
          onSubmit={() => onSubmit(askLeoText!)}
          disabled={isDisabled}
        />
      </div>
      <div className='pl-3 pr-1'>
        <button
          type='button'
          className={classNames('appearance-none h-[40px]', {
            'cursor-progress': isLeoThinking,
          })}
          onClick={() => onSubmit(askLeoText!)}
          aria-label='leo-send'
          disabled={isDisabled || !askLeoText?.trim()}
        >
          <FontAwesomeIcon
            icon={faPaperPlaneTop}
            className={classNames(
              'text-base',
              askLeoText ? 'text-white' : 'text-zen-dark-6',
            )}
          />
        </button>
      </div>
    </div>
  );
};

export default LeoChatInput;
