import DropboxEmptyFilesImg from '../../assets/img/dropbox/EmptyTray.png';

const EmptyDropbox: React.FC = () => {
  return (
    <div className='my-6 flex flex-col items-center justify-center'>
      <p className='text-zen-dark-5 text-base text-center font-primary-thin'>
        You have no documents in your file cabinet.
      </p>
      <img src={DropboxEmptyFilesImg} className='my-3' alt='dropbox-img' />
      <p className='text-base text-center font-primary-thin'>
        Once you upload documents to your file cabinet, you will be able to
        easily attach them to any checklist item.
      </p>
    </div>
  );
};

export default EmptyDropbox;
