import { ItemResponse } from '../../openapi/sherlock';
import JourneyStep from './JourneyStep';

export interface VerticalJourneyStepProgressProps {
  steps: ItemResponse[];
  isJourneyProgress?: boolean;
}

const VerticalJourneyStepProgress: React.FC<VerticalJourneyStepProgressProps> = ({
  steps,
}) => {
  return (
    <ol>
      {steps.map((step, index) => {
        return (
          <JourneyStep
            key={step.name}
            isLastStep={index === steps.length - 1}
            step={step}
          />
        );
      })}
    </ol>
  );
};

export default VerticalJourneyStepProgress;
