import React from 'react';
import { EnumMap } from '../../types';
import { cn } from '../../utils/classUtils';

type T4AFieldBG = 'white' | 'grey';

export interface T4AFieldProps {
  label?: React.ReactElement;
  labelNumber?: string;
  primaryText?: string | React.ReactElement;
  secondaryText?: string;
  boxCaseText?: string;
  primaryFieldColor?: T4AFieldBG;
  secondaryFieldColor?: T4AFieldBG;
  hideSecondaryField?: boolean;
}

const fieldBgColorMap: EnumMap<T4AFieldBG, string> = {
  white: 'bg-white',
  grey: 'bg-gray-50',
};

const T4AField: React.FC<T4AFieldProps> = ({
  label,
  labelNumber,
  primaryText,
  secondaryText,
  primaryFieldColor = 'white',
  secondaryFieldColor = 'grey',
  hideSecondaryField = false,
}) => {
  return (
    <div>
      {label && <div className='leading-4 mb-1'>{label}</div>}
      <div className='flex items-start'>
        {labelNumber && (
          <p className='pt-1 px-1 border-2 border-r-0 border-black border-collapse'>
            {labelNumber}
          </p>
        )}
        <div className='h-14 grid grid-cols-3 border-2 border-black flex-grow'>
          <p
            className={cn(
              'px-2 flex-grow pt-1',
              fieldBgColorMap[primaryFieldColor],
              hideSecondaryField ? 'col-span-3' : 'col-span-2',
            )}
          >
            {primaryText}
          </p>
          {!hideSecondaryField && (
            <p
              className={cn(
                'px-2 pt-1 border-l-2 col-span-1 border-black flex-none',
                fieldBgColorMap[secondaryFieldColor],
              )}
            >
              {secondaryText}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default T4AField;
