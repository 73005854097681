import { useState } from 'react';
import { useFieldArray } from 'react-hook-form-v7';
import Alert from '../Alert';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../Zen/ZenButton';
import CircleCheckStepProgress from './CircleCheckStepProgress';
import {
  CreateJointVentureFormState,
  CreateJointVentureStepName,
} from './CreateJointVentureSteps';
import TierCard from './TierCard';
import TitleLayout from './TitleLayout';

const TierStep: StepByStepComponent<
  CreateJointVentureFormState,
  CreateJointVentureStepName
> = ({
  form: { control, watch, setValue, trigger, setError, clearErrors },
  progress: { currentIndex, lastIndex },
  onNext,
  onPrevious,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tiers',
  });
  const [tierRemoveIndex, setTierRemoveIndex] = useState();
  const [showError, setShowError] = useState<boolean>(false);
  const handleTier = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    onNext();
  };

  return (
    <TitleLayout
      onNext={handleTier}
      onPrevious={onPrevious}
      header={
        <CircleCheckStepProgress
          stepNames={Object.values(CreateJointVentureStepName)}
          totalStepsCount={lastIndex + 1}
          inProgressIndex={currentIndex}
        />
      }
    >
      <div className='w-full mx-auto flex-grow p-5 max-w-7xl'>
        <p className='text-xl pt-5 font-primary-medium text-center text-dark'>
          Tiers
        </p>
        {showError && (
          <div className='pt-5'>
            <Alert
              text='Cannot add more tiers when No Tier is added.'
              variant='error'
            />
          </div>
        )}
        <div className='grid gap-6 grid-cols-[repeat(auto-fit,minmax(350px,1fr))] pt-8'>
          {fields.map((tier, i) => (
            <div key={tier.id} className='w-full max-w-2xl mx-auto'>
              <TierCard
                tierIndex={i}
                control={control}
                remove={(i) => setTierRemoveIndex(i)}
                watch={watch}
                setValue={setValue}
                setError={setError}
                setShowError={(val: boolean) => setShowError(val)}
                clearErrors={clearErrors}
              />
            </div>
          ))}
        </div>
        <div className='flex flex-row justify-center mt-6'>
          <ZenButton
            label='Add'
            variant='primary-outline'
            onClick={() => append({})}
          />
        </div>
        <ZenConfirmationModal
          variant='danger'
          isOpen={tierRemoveIndex !== undefined}
          onClose={() => setTierRemoveIndex(undefined)}
          title='Delete Tier'
          subtitle='Are you sure you want to delete this tier?'
          onConfirm={() => {
            remove(tierRemoveIndex);
            setTierRemoveIndex(undefined);
          }}
          hideIcon
        />
      </div>
    </TitleLayout>
  );
};

export default TierStep;
