import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { cn } from '../utils/classUtils';

export interface CollapsibleTableRowProps {
  name: string;
  ValueComponent: React.ReactElement;
  RightComponent?: React.ReactElement;
  defaultCollapsed?: boolean;
}

const CollapsibleTableRow: React.FC<CollapsibleTableRowProps> = ({
  name,
  ValueComponent,
  RightComponent,
  defaultCollapsed = true,
  children,
}) => {
  const [isCollapsed, setCollapsed] = useState(defaultCollapsed);

  return (
    <>
      <thead
        className={cn(isCollapsed ? 'bg-gray-100' : 'bg-white')}
        onClick={() => {
          children && setCollapsed(!isCollapsed);
        }}
      >
        <tr>
          <td
            className={cn('p-2 flex flex-row justify-between items-center', {
              'cursor-pointer': children,
            })}
          >
            <p>{name}</p>
            {RightComponent}
            {children ? (
              isCollapsed ? (
                <FontAwesomeIcon icon={faChevronRight} size='xs' />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size='xs' />
              )
            ) : null}
          </td>
          <td className='w-2/6 p-2 h-full border-l-2 border-gray-200 text-right'>
            {isCollapsed && ValueComponent}
          </td>
        </tr>
      </thead>
      <tbody className='divide-y-2 divide-dashed bg-white'>
        {!isCollapsed && children}
      </tbody>
    </>
  );
};

export default CollapsibleTableRow;
