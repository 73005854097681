import { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import {
  TeamMemberResponse,
  TeamResponse,
  TeamResponseTypeEnum,
  UserPreviewResponse,
} from '../../../openapi/yenta';
import {
  getTeamDetailOverview,
  updateTeamMember,
} from '../../../slices/TeamSlice';
import { ISelectOption } from '../../../types';
import { capitalizeEnum, NUMBER_REGEX } from '../../../utils/StringUtils';
import { mapTeamRoleToType } from '../../../utils/TeamHelper';
import IconButton from '../../IconButton';
import ZenControlledSelectInput from '../Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenSidebarModalActionFooterV2 from '../Modal/ZenSidebarModalActionFooterV2';
import ZenSidebarModal from '../ZenSidebarModal';
import ZenDeleteTeamMemberModal from './ZenDeleteTeamMemberModal';

interface ZenEditTeamMemberProps {
  member: TeamMemberResponse | null;
  team: TeamResponse;
  onClose(): void;
  teamType: TeamResponseTypeEnum;
}

interface FormData {
  commissionSplit: number;
  role?: ISelectOption;
}

const ZenEditTeamMember: React.FC<ZenEditTeamMemberProps> = ({
  member,
  team,
  onClose,
  teamType,
}) => {
  const dispatch = useDispatch();
  const [memberToDelete, setMemberToDelete] = useState<UserPreviewResponse>();
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      commissionSplit: member?.memberCommissionSplit,
      role: !!member
        ? {
            label: capitalizeEnum(member?.roles![0]),
            value: member?.roles![0],
          }
        : undefined,
    },
  });

  const agentCommission = watch('commissionSplit');
  const leaderCommission = `Leader Commission: ${
    100 - parseFloat(String(agentCommission || 0))
  }%`;

  const onSubmit = async (values: any) => {
    await dispatch(
      updateTeamMember(team.id!, {
        createRequests: [],
        updateRequests: [
          {
            commissionSplit:
              typeof values?.commissionSplit !== undefined
                ? values.commissionSplit
                : undefined,
            roles: [values.role?.value],
            userId: member?.agent?.id!,
          },
        ],
        userIdsToDelete: [],
      }),
    );
    dispatch(getTeamDetailOverview(team?.id!));
    onClose();
  };

  return (
    <ZenSidebarModal
      isOpen={!!member}
      onClose={onClose}
      title={`Edit ${member?.agent?.fullName}`}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='add-team-member-form'
      >
        <div className='p-5'>
          <div className='mt-3'>
            <ZenControlledTextInput<FormData, 'commissionSplit'>
              control={control}
              type='number'
              name='commissionSplit'
              label='Agent Commission'
              isRequired
              rules={{
                required: 'Please enter commission split',
                min: {
                  value: 0,
                  message: 'Number cannot be less than 0',
                },
                max: {
                  value: 100,
                  message: 'Number cannot be greater than 100',
                },
                pattern: {
                  value: NUMBER_REGEX,
                  message: 'Please enter a valid number',
                },
              }}
              helperText={leaderCommission}
              readOnly={team.type === TeamResponseTypeEnum.Domestic}
            />
          </div>
          <div className='mt-8'>
            <ZenControlledSelectInput<FormData, 'role'>
              control={control}
              name='role'
              label='Select Role'
              placeholder='Select Role'
              options={mapTeamRoleToType(teamType)}
              isRequired
              rules={{
                required: 'Please select a role',
              }}
              readOnly={team.type === TeamResponseTypeEnum.Domestic}
            />
          </div>
          <div className='mt-8'>
            <IconButton
              variant='none'
              buttonType='button'
              label='Remove Team Member'
              leftIcon={
                <FaRegTrashAlt fontSize={16} className='text-zen-danger mr-1' />
              }
              onClick={() => setMemberToDelete(member?.agent)}
              buttonStyle='hover:bg-zen-danger hover:bg-opacity-10 hover:rounded-md'
              textStyle='text-zen-danger'
            />
          </div>
        </div>
        <ZenSidebarModalActionFooterV2
          onClose={onClose}
          isSubmitting={isSubmitting}
          submitButtonText='Save'
        />
      </form>
      <ZenDeleteTeamMemberModal
        teamId={team?.id!}
        memberToDelete={memberToDelete}
        onClose={() => onClose()}
        setMemberToDelete={() => setMemberToDelete(undefined)}
      />
    </ZenSidebarModal>
  );
};

export default ZenEditTeamMember;
