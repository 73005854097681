import { ISelectOption } from '../types';
import { cn } from '../utils/classUtils';

interface ZenCustomGroupRadioButtonProps {
  items: Array<ISelectOption>;
  onChange(value: string): void;
  value: string;
  disabled?: boolean;
  variant?: 'default' | 'primary';
}

const ZenCustomGroupRadioButton: React.FC<ZenCustomGroupRadioButtonProps> = ({
  items = [],
  onChange,
  value,
  variant = 'default',
}) => {
  return (
    <div
      className='flex flex-row items-center'
      key={items.map((i) => i.value).join()}
    >
      {items.map((item, index) => {
        const isActive = item.value === value;

        const variantToBackgroundStyleMap = {
          default: isActive
            ? 'bg-primary-blue border-primary-blue bg-opacity-20'
            : 'bg-white border-zen-dark-4 bg-opacity-95',
          primary: isActive
            ? 'bg-zen-light-blue border-primary-blue'
            : 'bg-white border-zen-dark-5',
        };

        const variantToTextStyleMap = {
          default: isActive
            ? 'text-zen-dark-9 font-semibold'
            : 'text-zen-dark-6 font-medium',
          primary: isActive
            ? 'text-primary-blue font-medium'
            : 'text-zen-dark-9 font-medium',
        };

        const isFirst = index === 0;
        const isLast = index === items.length - 1;

        const borderStyles =
          items.length === 1 || (!isFirst && !isLast)
            ? 'rounded-none'
            : isFirst
            ? 'rounded-l-lg'
            : 'rounded-r-lg';

        return (
          <button
            className={cn(
              'flex-grow flex-shrink items-center text-center px-2 py-3 border outline-none focus:outline-none',
              variantToBackgroundStyleMap[variant],
              borderStyles,
              { 'bg-gray-50 text-gray-300': item.disabled },
            )}
            type='button'
            name={item.label}
            key={item.label}
            onClick={() => onChange(item.value)}
            disabled={item.disabled}
          >
            <span
              className={cn(
                'font-zen-title flex-wrap text-sm',
                variantToTextStyleMap[variant],
              )}
            >
              {item.label}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default ZenCustomGroupRadioButton;
