import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { FlexTeamDto, UpdateFlexTeam } from '../../../../openapi/yenta';
import {
  getFlexTeamDetailOverview,
  updateFlexTeam,
} from '../../../../slices/TeamSlice';
import { AppDispatch } from '../../../../types';
import { MONEY_VALIDATIONS } from '../../../../utils/Validations';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenSidebarModalActionFooterV2 from '../../Modal/ZenSidebarModalActionFooterV2';
import TeamCapPopover from './ProTeamPopover/TeamCapPopover';
import TeamPostCapFeePopover from './ProTeamPopover/TeamPostCapFeePopover';
import TeamPreCapFeePopover from './ProTeamPopover/TeamPreCapFeePopover';

interface FormData {
  teamFee: string;
  teamPreCapFee: string;
  teamPostCapFee: string;
}
interface ZenTeamFeesTabProps {
  onClose(): void;
  team: FlexTeamDto;
  readOnly?: boolean;
}

const ZenTeamFeesTab: React.FC<ZenTeamFeesTabProps> = ({
  onClose,
  team,
  readOnly,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      teamFee: team?.teamCap?.capAmount?.toString(),
      teamPreCapFee: team?.teamCap?.preCapFee?.toString(),
      teamPostCapFee: team?.teamCap?.postCapFee?.toString(),
    },
  });

  const onSubmit = async (values: FormData) => {
    setIsSubmitting(true);
    let formData = {
      name: team.name!,
      teamCap: {
        capAmount: parseInt(values.teamFee),
        preCapFee: parseInt(values.teamPreCapFee),
        postCapFee: parseInt(values.teamPostCapFee),
      },
    };

    await dispatch(
      updateFlexTeam(team.id!, (formData as unknown) as UpdateFlexTeam),
    );
    dispatch(getFlexTeamDetailOverview(team.id!, false));
    setIsSubmitting(false);
  };

  return (
    <form
      className='flex flex-col justify-between max-h-[calc(100vh-175px)]'
      onSubmit={handleSubmit(onSubmit)}
      title='edit-team-form'
    >
      <div className='p-5 pb-28 h-full overflow-y-auto'>
        <div className='font-normal font-primary-light text-sm text-zen-dark-7'>
          Choose a team cap amount. This is the default that will be used for
          all the Teammates. This can also be adjusted individually for each
          teammate when editing their personal settings.
        </div>
        <div className='flex flex-row justify-between items-center mt-5'>
          <TeamCapPopover />
          <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
            <ZenControlledTextInput<FormData, 'teamFee'>
              placeholder='Team Cap'
              control={control}
              name='teamFee'
              readOnly={readOnly}
              startAdornment={
                <div
                  className={classNames(
                    'flex w-full h-full items-center justify-center pl-2',
                    readOnly && 'bg-gray-50',
                  )}
                >
                  <FontAwesomeIcon
                    className='text-primary-blue'
                    icon={solid('dollar')}
                  />
                </div>
              }
              shouldUnregister={false}
              isRequired
              rules={{
                ...MONEY_VALIDATIONS,
                required: 'Please enter a team cap amount',
              }}
            />
          </div>
        </div>
        <div className='mt-5'>
          <div className='font-normal font-primary-medium text-lg text-zen-dark-9'>
            Fixed Fees
          </div>
          <div className='h-px bg-zen-dark-5 mt-2' />
          <div className='mt-5 font-inter text-sm text-zen-dark-7'>
            Choose team pre cap & post cap fee amount. This is the default that
            will be used for all the Teammates. This can also be adjusted
            individually for each teammate when editing their personal settings.
          </div>
          <div className='space-y-2'>
            <div className='flex flex-row justify-between items-center mt-5'>
              <TeamPreCapFeePopover />
              <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                <ZenControlledTextInput<FormData, 'teamPreCapFee'>
                  label='Agent Pays to Real'
                  placeholder='Team Pre Cap Fee'
                  control={control}
                  readOnly={readOnly}
                  name='teamPreCapFee'
                  startAdornment={
                    <div
                      className={classNames(
                        'flex w-full h-full items-center justify-center pl-2',
                        readOnly && 'bg-gray-50',
                      )}
                    >
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={solid('dollar')}
                      />
                    </div>
                  }
                  shouldUnregister={false}
                  isRequired
                  rules={{
                    ...MONEY_VALIDATIONS,
                    required: 'Please enter a team pre cap fee',
                  }}
                />
              </div>
            </div>
            <div className='flex flex-row justify-between items-center'>
              <TeamPostCapFeePopover />
              <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                <ZenControlledTextInput<FormData, 'teamPostCapFee'>
                  control={control}
                  readOnly={readOnly}
                  placeholder='Team Post Cap Fee'
                  name='teamPostCapFee'
                  startAdornment={
                    <div
                      className={classNames(
                        'flex w-full h-full items-center justify-center pl-2',
                        readOnly && 'bg-gray-50',
                      )}
                    >
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={solid('dollar')}
                      />
                    </div>
                  }
                  shouldUnregister={false}
                  isRequired
                  rules={{
                    ...MONEY_VALIDATIONS,
                    required: 'Please enter a team post cap fee',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ZenSidebarModalActionFooterV2
        onClose={onClose}
        isDisabled={isSubmitting || readOnly}
        isSubmitting={isSubmitting}
        submitButtonText='Update'
      />
    </form>
  );
};

export default ZenTeamFeesTab;
