import {
  faHandshake,
  faEye,
  faUser,
  faSend,
} from '@fortawesome/pro-solid-svg-icons';
import React, { useMemo, useState } from 'react';
import ZenSidebarModal from '../../components/Zen/ZenSidebarModal';
import StepByStepContainer, {
  StepConfig,
} from '../../components/StepByStep/StepByStepContainer';
import { GooglePlaceLocationType } from '../../components/ControlledGoogleAutocompleteSearchInputV7';
import { ISelectOption, ReferralClientRole } from '../../types';
import ReferralDetailStep from './ReferralDetailStep';
import ReferralReviewStep from './ReferralReviewStep';
import SimpleStepProgress from './components/SimpleStepProgress';
import SendAgreementStep from './ReferralSendAgreement';
import ReferralClientDetailsStep from './ReferralClientDetailsStep';
import AgreementSentSuccess from './AgreementSentSuccess';

interface SendReferralProps {
  isOpen: boolean;
  onClose(): void;
}

export enum SendReferralStepName {
  REFERRAL_DETAILS = 'REFERRAL_DETAILS',
  CLIENT_DETAILS = 'CLIENT_DETAILS',
  REVIEW = 'REVIEW',
  SEND_AGREEMENT = 'SEND_AGREEMENT',
}

export interface RefAddress {
  address: GooglePlaceLocationType;
  distance: ISelectOption;
}

export interface SendReferralFormState {
  clientRole?: ReferralClientRole;
  referralAddress: RefAddress[];
  referralFee?: number;
  timeline?: string;
  minimum?: number;
  maximum?: number;
  language?: ISelectOption[];
  notes?: string;
  personalNickname?: string;
  clientInformation?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    expirationDate?: Date;
    address?: GooglePlaceLocationType;
  };
}

const SendReferral: React.FC<SendReferralProps> = ({ isOpen, onClose }) => {
  const defaultValues: SendReferralFormState = {
    referralAddress: [
      {
        address: {
          formatted_address: '',
          geometry: {
            location: {
              lat: undefined,
              lng: undefined,
            },
          },
          place_id: '',
        },
        distance: {
          label: '5 miles',
          value: '5',
        },
      },
    ],
  };

  // const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const steps: StepConfig<SendReferralFormState, SendReferralStepName>[] = [
    {
      name: SendReferralStepName.REFERRAL_DETAILS,
      Component: (props) => <ReferralDetailStep {...props} onClose={onClose} />,
      hidePagination: true,
    },
    {
      name: SendReferralStepName.CLIENT_DETAILS,
      Component: (props) => (
        <ReferralClientDetailsStep {...props} onClose={onClose} />
      ),
      hidePagination: true,
    },
    {
      name: SendReferralStepName.REVIEW,
      Component: (props) => <ReferralReviewStep {...props} onClose={onClose} />,
      hidePagination: true,
    },
    {
      name: SendReferralStepName.SEND_AGREEMENT,
      Component: (props) => <SendAgreementStep {...props} onClose={onClose} />,
      hidePagination: true,
    },
  ];

  const stepToIcon = {
    [SendReferralStepName.REFERRAL_DETAILS]: faHandshake,
    [SendReferralStepName.CLIENT_DETAILS]: faUser,
    [SendReferralStepName.REVIEW]: faEye,
    [SendReferralStepName.SEND_AGREEMENT]: faSend,
  };

  const [stepCount, setStepCount] = useState<number>(0);
  const [isAgreementSent, setIsAgreementSent] = useState<boolean>(false);

  const totalSteps = useMemo(() => {
    return steps.length;
  }, [steps.length]);

  const onSubmit = () => {
    setIsAgreementSent(true);
  };

  const handleClose = () => {
    setIsAgreementSent(false);
    onClose();
  };

  const onStepChange = (step: any) => {
    const index = steps.findIndex((s) => s.name === step.name);
    setStepCount(index);
  };

  return (
    <ZenSidebarModal
      title='Send Referral'
      isOpen={isOpen}
      onClose={handleClose}
    >
      {!isAgreementSent ? (
        <>
          <SimpleStepProgress
            currentStep={stepCount}
            totalSteps={totalSteps}
            subtitle='Complete all the steps to finish sending this referral'
            title={steps[stepCount].name}
            icon={stepToIcon[steps[stepCount].name]}
          />
          <StepByStepContainer<SendReferralFormState, SendReferralStepName>
            steps={steps}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            reValidateMode='onChange'
            mode='onChange'
            pageStyle='relative h-full w-full'
            onChangeStep={(step) => onStepChange(step)}
          />
        </>
      ) : (
        <AgreementSentSuccess onClose={handleClose} />
      )}
    </ZenSidebarModal>
  );
};

export default SendReferral;
