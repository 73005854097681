import React from 'react';
import { faExclamationSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TransactionError,
  TransactionErrorSeverityEnum,
} from '../../../../openapi/arrakis';
import { EnumMap } from '../../../../types';
import ZenAlert, { ZenAlertVariantType } from '../../ZenAlert';

interface ZenTransactionDetailErrorsProps {
  errors: TransactionError[];
}

const ZenTransactionDetailErrors: React.FC<ZenTransactionDetailErrorsProps> = ({
  errors,
}) => {
  const transactionErrorVariantMap: EnumMap<
    TransactionErrorSeverityEnum,
    ZenAlertVariantType
  > = {
    BLOCKER: 'error',
    CRITICAL: 'error',
    WARNING: 'warning',
  };

  return (
    <div className='mx-4'>
      {errors.map((error) => (
        <div key={error.message} className='my-1'>
          <ZenAlert
            text={error.message!}
            variant={transactionErrorVariantMap[error.severity!]}
            icon={<FontAwesomeIcon icon={faExclamationSquare} fontSize={20} />}
          />
        </div>
      ))}
    </div>
  );
};

export default ZenTransactionDetailErrors;
