import { cn } from '../../utils/classUtils';

export type PillVariantType =
  | 'default'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'purple'
  | 'warningTwo';

export type FontSize = 'text-xs' | 'text-sm' | 'text-lg';

export interface ZenStatusPillProps {
  text: string;
  variant?: PillVariantType;
  fontSize?: FontSize;
  icon?: JSX.Element;
}

export const pillVariantTypeToClassNameMap: {
  [type in PillVariantType]: string;
} = {
  default: 'bg-gray-200 text-dark',
  primary: 'bg-primary-blue bg-opacity-10 text-primary-blue',
  success: 'bg-green-600 bg-opacity-10 text-green-600',
  warning: 'bg-zen-warning bg-opacity-10 text-zen-warning',
  danger: 'bg-zen-danger bg-opacity-10 text-zen-danger',
  purple: 'bg-zen-purple bg-opacity-10 text-zen-purple',
  warningTwo: 'bg-zen-warning-3 bg-opacity-10 text-zen-warning-3',
};

const ZenStatusPill: React.FC<ZenStatusPillProps> = ({
  text,
  variant = 'default',
  icon,
  fontSize = 'text-sm',
}) => {
  return (
    <div
      className={cn(
        'inline-block py-1 px-3 rounded-full whitespace-nowrap',
        pillVariantTypeToClassNameMap[variant],
      )}
    >
      <div className='flex items-center'>
        {icon && <div className='mr-1 flex items-center'>{icon}</div>}
        <p className={`font-zen-body ${fontSize}`}>{text}</p>
      </div>
    </div>
  );
};

export default ZenStatusPill;
