import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LAST_ALLOWABLE_TIER } from '../../constants/NetworkConstants';
import { FrontLineAgentResponseStatusEnum } from '../../openapi/arrakis';
import { EnumMap, RootState } from '../../types';
import { getCommissionAmount } from '../../utils/AgentHelper';
import { cn } from '../../utils/classUtils';
import { safeEnumMapGet } from '../../utils/EnumHelper';
import { capitalizeEnum, formatPhoneNumber } from '../../utils/StringUtils';
import Hover from '../Hover';
import ZenAvatar from '../Zen/ZenAvatar';
import ZenStatusPill, { PillVariantType } from '../Zen/ZenStatusPill';

export interface ZenNetworkRowProps {
  id: string;
  name: string;
  tier: number;
  imageUrl: string;
  sizeOfNetwork: number;
  onClick(): void;
  email: string;
  phone: string;
  status: FrontLineAgentResponseStatusEnum;
  sponsorSplit: number;
  unlocking: boolean;
  expiryDate: string;
  openAgentProfile?: boolean;
}

const statusToPillVariantMap: EnumMap<
  FrontLineAgentResponseStatusEnum,
  PillVariantType
> = {
  [FrontLineAgentResponseStatusEnum.Candidate]: 'primary',
  [FrontLineAgentResponseStatusEnum.Active]: 'success',
  [FrontLineAgentResponseStatusEnum.Inactive]: 'danger',
  [FrontLineAgentResponseStatusEnum.Rejected]: 'danger',
};
const ZenNetworkRow: React.FC<ZenNetworkRowProps> = ({
  id,
  name,
  tier,
  imageUrl,
  status,
  sizeOfNetwork,
  onClick,
  email,
  phone,
  sponsorSplit,
  unlocking,
  expiryDate,
  openAgentProfile,
}) => {
  const {
    detailResponse: { data },
  } = useSelector((state: RootState) => state.agentDetail);
  const isClickable = tier !== LAST_ALLOWABLE_TIER;
  const safeSponsorSplit = sponsorSplit === 100 ? 100 : 50;
  const history = useHistory();

  const redirectToUserProfile = () => {
    if (openAgentProfile) {
      history.push(`/people/${id}`);
    }
  };

  return (
    <div onClick={isClickable ? onClick : () => null}>
      <div className='py-3 flex justify-between items-center cursor-pointer'>
        <div
          data-testId='agent-personal-info'
          className='flex items-center'
          onClick={(e) => {
            e.stopPropagation();
            redirectToUserProfile();
          }}
        >
          <ZenAvatar name={name} imageUrl={imageUrl} size='md' />

          <div className='px-3'>
            <div className='flex items-baseline'>
              <p className='mr-2 font-zen-body text-zen-dark-9 text-base font-medium'>
                {name}
              </p>

              <Hover
                hoverComponent={
                  <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                    {email || 'N/A'}
                  </div>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <FontAwesomeIcon
                  icon={regular('envelope')}
                  title='email'
                  className='cursor-pointer text-primary-blue'
                />
              </Hover>

              <Hover
                hoverComponent={
                  <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                    {formatPhoneNumber(phone) || 'N/A'}
                  </div>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <FontAwesomeIcon
                  icon={regular('phone')}
                  title='phone'
                  className='cursor-pointer text-base text-primary-blue ml-2.5'
                />
              </Hover>

              <FontAwesomeIcon
                onClick={(e) => {
                  e.stopPropagation();
                  redirectToUserProfile();
                }}
                icon={faUser}
                title='profile'
                className='cursor-pointer text-base text-primary-blue ml-2.5'
              />
            </div>

            <div className='flex items-center font-zen-body font-normal space-x-2'>
              <Hover
                hoverComponent={
                  <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                    {unlocking
                      ? `Is a tier-unlocking agent: the agent is active and has at least ${getCommissionAmount(
                          data?.accountCountry!,
                        )} in closed commission in the last 6 months.`
                      : `Is not a tier-unlocking agent: either the agent is inactive or does not have at least ${getCommissionAmount(
                          data?.accountCountry!,
                        )} in closed commission in the last 6 months.`}
                  </div>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <FontAwesomeIcon
                  icon={unlocking ? solid('lock-open') : solid('lock')}
                  className={cn(
                    'cursor-pointer',
                    unlocking ? 'text-green-600' : 'text-gray-300',
                  )}
                  aria-label='lockUnlockIcon'
                />
              </Hover>
              {unlocking && expiryDate && (
                <Hover
                  hoverComponent={
                    <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                      Date when tier-unlocking status expires.
                    </div>
                  }
                  config={{ trigger: 'hover', placement: 'top' }}
                >
                  <p className='ml-1 mb-0.5 font-medium text-sm text-zen-dark-7 opacity-80'>
                    Expires: {DateTime.fromISO(expiryDate).toFormat('LL/dd/yy')}
                  </p>
                </Hover>
              )}
              {!unlocking && (
                <ZenStatusPill
                  text={capitalizeEnum(status)}
                  variant={safeEnumMapGet(
                    statusToPillVariantMap,
                    status,
                    'primary',
                  )}
                />
              )}
            </div>
          </div>
        </div>

        <div className='flex' data-testId='agent-network-info'>
          <div className='flex flex-col items-center mr-3 font-zen-body text-sm'>
            <p className='font-semibold text-zen-dark-7'>{`Tier ${tier}`}</p>
            <Hover
              hoverComponent={
                <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                  Percentage of sponsorship
                </div>
              }
              config={{ trigger: 'hover', placement: 'top' }}
            >
              <p className='mt-0.5 font-medium text-zen-dark-7 opacity-80'>{`${safeSponsorSplit}%`}</p>
            </Hover>
          </div>

          <div className='flex'>
            <Hover
              hoverComponent={
                <div className='font-zen-body font-semibold text-base text-zen-dark-12 px-2'>
                  Number of agents in network.
                </div>
              }
              config={{ trigger: 'hover', placement: 'top' }}
            >
              <div className='flex items-center justify-center h-5 w-8 mt-0.5 mr-2 bg-zen-light-gray-2 rounded-full'>
                <p className='font-zen-body text-sm  text-zen-dark-9'>
                  {sizeOfNetwork || 0}
                </p>
              </div>
            </Hover>

            {isClickable && (
              <FontAwesomeIcon
                icon={regular('chevron-right')}
                className='cursor-pointer text-zen-dark-5 text-base pt-1'
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenNetworkRow;
