import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import { ISelectOption } from '../../../../types';
import {
  FeeSplitFeeTypeEnum,
  UpdateFlexTeamMemberTypeEnum,
} from '../../../../openapi/yenta';
import ZenControlledSelectInput from '../../Input/ZenControlledSelectInput';
import { PERCENT_VALIDATION } from '../../../../utils/Validations';
import ZenSidebarModalActionFooterV2 from '../../Modal/ZenSidebarModalActionFooterV2';
import { LeaderDto } from '../../../../routes/ZenTeamsProDetailsRoute';
import {
  getFlexTeamDetailOverview,
  updateFlexTeamMember,
} from '../../../../slices/TeamSlice';
import ZenButton from '../../ZenButton';
import {
  combineFeeSplits,
  getMaxFeeForLeaderValidation,
  getSplitPercentage,
} from '../../../../utils/ProTeams/ProTeamsHelper';
import { getParticipantName } from '../../../../utils/ParticipantHelper';
import RealBEOPFeePopover from './ProTeamPopover/RealBEOPFeePopover';
import RealBrokerageFeePopover from './ProTeamPopover/RealBrokerageFeePopover';
import RealPostCapTransactionFeePopover from './ProTeamPopover/RealPostCapTransactionFeePopover';
import RealSplitFeePopover from './ProTeamPopover/RealSplitFeePopover';
import LeaderSplitPopover from './ProTeamPopover/LeaderSplitPopover';

interface FormData {
  name: string;
  commissionDocumentApprover?: ISelectOption;
  teamLeader: ISelectOption;
  realSplit: number;
  leaderSplit: number;
  beopFee: number;
  realFee: number;
  transactionFee: number;
  roles: ISelectOption;
}

interface MaxFeeSplits {
  maxLeaderSplit?: number;
  maxRealSplit?: number;
  maxBEOPFee?: number;
  maxBrokerageFee?: number;
  maxTransactionFee?: number;
}

interface ZenTeamLeaderFeeSplitsTabProps {
  leaders: LeaderDto[];
  teamId: string;
  onClose(): void;
  readOnly?: boolean;
}
const ZenTeamLeaderFeeSplitsTab: React.FC<ZenTeamLeaderFeeSplitsTabProps> = ({
  leaders = [],
  teamId,
  onClose,
  readOnly,
}) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, watch, setValue } = useForm<FormData>({
    defaultValues: {},
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [teamLeader] = watch(['teamLeader']);
  const [maxFeeSplits, setMaxFeeSplits] = useState<MaxFeeSplits>({});

  const leaderOptions =
    leaders?.map((i) => {
      return { id: i.leaderDetails?.id, fullName: getParticipantName(i.agent) };
    }) ?? [];

  const setDefaultFeeSplitValues = useCallback(() => {
    const updateValues = leaders?.find(
      (agent) => agent.leaderDetails?.id === teamLeader.value,
    )!;
    const leaderList = leaders.filter(
      (val) => val.leaderDetails?.id !== teamLeader.value,
    );

    setMaxFeeSplits(getMaxFeeForLeaderValidation(leaderList));

    const realSplit = getSplitPercentage(
      FeeSplitFeeTypeEnum.RealSplit,
      updateValues?.leaderDetails?.feeSplits,
    );
    const beopFee = getSplitPercentage(
      FeeSplitFeeTypeEnum.BeopFee,
      updateValues?.leaderDetails?.feeSplits,
    );

    const realFee = getSplitPercentage(
      FeeSplitFeeTypeEnum.BrokerageFee,
      updateValues?.leaderDetails?.feeSplits,
    );
    const transactionFee = getSplitPercentage(
      FeeSplitFeeTypeEnum.TransactionFee,
      updateValues?.leaderDetails?.feeSplits,
    );
    setValue('leaderSplit', updateValues?.leaderDetails?.leaderSplit!);
    setValue('realSplit', realSplit);
    setValue('beopFee', beopFee);
    setValue('realFee', realFee);
    setValue('transactionFee', transactionFee);
  }, [leaders, setValue, teamLeader]);

  useEffect(() => {
    if (teamLeader) {
      setDefaultFeeSplitValues();
    }
  }, [setDefaultFeeSplitValues, teamLeader]);

  const onSubmit = async (values: FormData) => {
    setIsSubmitting(true);

    const feeSplits = combineFeeSplits({
      beopFee: values.beopFee,
      realFee: values.realFee,
      transactionFee: values.transactionFee,
      realSplit: values.realSplit,
    });

    await dispatch(
      updateFlexTeamMember(teamId, values.teamLeader.value, {
        type: UpdateFlexTeamMemberTypeEnum.Leader,
        feeSplits: feeSplits,
        leaderSplit: +values.leaderSplit,
      }),
    );
    dispatch(getFlexTeamDetailOverview(teamId, false));

    setIsSubmitting(false);
  };

  const resetToDefault = () => {
    setDefaultFeeSplitValues();
  };

  return (
    <form
      className='flex flex-col justify-between max-h-[calc(100vh-175px)]'
      onSubmit={handleSubmit(onSubmit)}
      title='team-leader-fee-split-form'
    >
      <div className='p-5 pb-28 h-full overflow-y-auto'>
        <div className='font-normal font-primary-light text-sm text-zen-dark-7'>
          Choose the leader’s split and the leader’s responsibility to pay fees
          on behalf of their teammates. These are the defaults used for all
          teammates. These can also be adjusted individually for each team
          member when editing their personal settings.
        </div>
        <div className='mt-5'>
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, 'teamLeader'>
              name='teamLeader'
              placeholder='Team Leader'
              shouldUnregister={false}
              control={control}
              options={[
                ...leaderOptions?.map((val) => ({
                  value: val.id!,
                  label: val.fullName!,
                })),
              ]}
              isRequired
              rules={{ required: 'Please select a team leader' }}
              label='Team Leader'
            />
          </div>
        </div>
        {/* <div className='mt-5'>
          <ZenControlledSelectInput<FormData, 'roles'>
            name='roles'
            placeholder='Assign Admin'
            subLabel='Admins share team configuration settings with leaders, assisting in daily operations by supporting leaders and ensuring system integrity for optimal team performance.'
            control={control}
            options={[
              // {
              //   label: capitalizeEnum('Remove_As_Admin'),
              //   value: 'NOT_ADMIN',
              // },
              {
                label: capitalizeEnum(FlexTeammateDtoRolesEnum.Admin),
                value: FlexTeammateDtoRolesEnum.Admin,
              },
            ]}
            label='Assign Admin'
          />
        </div> */}
        <div className='mt-5'>
          <div className='font-normal font-primary-medium text-lg text-zen-dark-9'>
            Leader’s Participation in each transaction
          </div>
          <div className='h-px bg-zen-dark-5 mt-2' />
          <div className='mt-5 font-inter text-sm text-zen-dark-7'>
            This is the percentage of each transaction gross amount that will be
            automatically paid to this leader.
          </div>
          <div className='flex flex-row justify-between items-center'>
            <LeaderSplitPopover />
            <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
              <ZenControlledTextInput<FormData, 'leaderSplit'>
                label='Teammate Pays to Real'
                startAdornment={
                  <div className='flex w-full h-full items-center justify-center pl-2'>
                    <FontAwesomeIcon
                      className='text-primary-blue'
                      icon={solid('percentage')}
                    />
                  </div>
                }
                readOnly={readOnly}
                control={control}
                type='text'
                placeholder='Leader’s Split'
                rules={{
                  ...PERCENT_VALIDATION,
                  min: {
                    value: 1,
                    message: 'Number cannot be less than 0',
                  },
                  max: {
                    value: maxFeeSplits.maxLeaderSplit!,
                    message: 'Total percentage cannot be > 100',
                  },
                  required: 'Please enter a leader split',
                }}
                isRequired
                name='leaderSplit'
              />
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <div className='font-normal font-primary-medium text-lg text-zen-dark-9'>
            Leader’s Payment on behalf Teammates
          </div>
          <div className='h-px bg-zen-dark-5 mt-2' />
          <div className='mt-5 font-inter text-sm text-zen-dark-7'>
            This is the percentage of each fee this leader pays to Real on
            behalf of their teammates.
          </div>
          <div className='space-y-2'>
            <div className='flex flex-row justify-between items-center mt-5'>
              <RealSplitFeePopover />
              <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                <ZenControlledTextInput<FormData, 'realSplit'>
                  label='Leader Pays to Real'
                  control={control}
                  type='text'
                  readOnly={readOnly}
                  placeholder='Real Split'
                  startAdornment={
                    <div className='flex w-full h-full items-center justify-center pl-2'>
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={solid('percentage')}
                      />
                    </div>
                  }
                  rules={{
                    ...PERCENT_VALIDATION,
                    min: {
                      value: 0,
                      message: 'Number cannot be less than 0',
                    },
                    max: {
                      value: maxFeeSplits.maxRealSplit!,
                      message: 'Total percentage cannot be > 100',
                    },
                  }}
                  name='realSplit'
                  shouldUnregister={false}
                />
              </div>
            </div>
            <div className='flex flex-row justify-between items-center'>
              <RealBEOPFeePopover />
              <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                <ZenControlledTextInput<FormData, 'beopFee'>
                  control={control}
                  type='text'
                  readOnly={readOnly}
                  placeholder='Real BEOP Fee'
                  startAdornment={
                    <div className='flex w-full h-full items-center justify-center pl-2'>
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={solid('percentage')}
                      />
                    </div>
                  }
                  rules={{
                    ...PERCENT_VALIDATION,
                    min: {
                      value: 0,
                      message: 'Number cannot be less than 0',
                    },
                    max: {
                      value: maxFeeSplits.maxBEOPFee!,
                      message: 'Total percentage cannot be > 100',
                    },
                  }}
                  name='beopFee'
                  shouldUnregister={false}
                />
              </div>
            </div>
            <div className='flex flex-row justify-between items-center'>
              <RealBrokerageFeePopover />
              <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                <ZenControlledTextInput<FormData, 'realFee'>
                  control={control}
                  type='text'
                  readOnly={readOnly}
                  rules={{
                    ...PERCENT_VALIDATION,
                    min: {
                      value: 0,
                      message: 'Number cannot be less than 0',
                    },
                    max: {
                      value: maxFeeSplits.maxBrokerageFee!,
                      message: 'Total percentage cannot be > 100',
                    },
                  }}
                  name='realFee'
                  startAdornment={
                    <div className='flex w-full h-full items-center justify-center pl-2'>
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={solid('percentage')}
                      />
                    </div>
                  }
                  placeholder='Real Brokerage Fee'
                  shouldUnregister={false}
                />
              </div>
            </div>
            <div className='flex flex-row justify-between items-center'>
              <RealPostCapTransactionFeePopover />
              <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
                <ZenControlledTextInput<FormData, 'transactionFee'>
                  control={control}
                  type='text'
                  readOnly={readOnly}
                  rules={{
                    ...PERCENT_VALIDATION,
                    min: {
                      value: 0,
                      message: 'Number cannot be less than 0',
                    },
                    max: {
                      value: maxFeeSplits.maxTransactionFee!,
                      message: 'Total percentage cannot be > 100',
                    },
                  }}
                  startAdornment={
                    <div className='flex w-full h-full items-center justify-center pl-2'>
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={solid('percentage')}
                      />
                    </div>
                  }
                  name='transactionFee'
                  placeholder='Real Post Cap Transaction Fee'
                  shouldUnregister={false}
                />
              </div>
            </div>
            {teamLeader && (
              <div className='flex flex-row items-center justify-end'>
                <ZenButton
                  variant='primary-link'
                  onClick={resetToDefault}
                  label='Reset All to Defaults'
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ZenSidebarModalActionFooterV2
        onClose={onClose}
        isSubmitting={isSubmitting}
        isDisabled={isSubmitting || readOnly}
        submitButtonText='Update'
      />
    </form>
  );
};

export default ZenTeamLeaderFeeSplitsTab;
