import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import {
  ApplicationControllerApi,
  FeePaymentRequest,
  MoneyValueCurrencyEnum,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { saveApplication } from '../slices/AuthSlice';
import { getErrorMessage } from '../utils/ErrorUtils';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import ZenButton from './Zen/ZenButton';

interface PaymentCardProps {
  totalFee: number;
  currency: MoneyValueCurrencyEnum;
  applicationId: string;
  couponCode?: string;
}

const PaymentCard: React.FC<PaymentCardProps> = ({
  totalFee,
  currency,
  applicationId,
  couponCode,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [stripeError, setStripeError] = useState<StripeError>();
  const [processing, setProcessing] = useState(false);
  const [isError, setIsError] = useState('');
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    const { paymentMethod, error } = await stripe!.createPaymentMethod({
      type: 'card',
      card: elements.getElement!(CardElement)!,
    });

    if (error) {
      setStripeError(error);
      setProcessing(false);
      return;
    }

    try {
      setIsError('');
      const paymentDetail: FeePaymentRequest = {
        fees: {
          currency: currency,
          amount: totalFee * 100, // converting dollar to cents for stripe
        },
        applicationId: applicationId,
        paymentMethod: paymentMethod!.id,
        promoCode: couponCode ?? '',
      };

      const { data } = await new ApplicationControllerApi(
        getYentaConfiguration(),
      ).payApplicationFees(paymentDetail!);

      dispatch(saveApplication(data));

      history.push('/onboarding/application-fee/success');
    } catch (e) {
      ErrorService.notify('Unable to pay application fee', e);
      setProcessing(false);
      setIsError(getErrorMessage(ErrorService.getErrorCode(e)));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {stripeError && (
        <div className='text-error bg-red-200 rounded space-x-1 px-1  items-center flex flex-col lg:flex-row'>
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className='text-error'
          />
          <p className='text-red-600 text-center my-2 '>
            {stripeError.message!}
          </p>
        </div>
      )}
      {isError && (
        <div className='text-error bg-red-200 rounded space-x-1 px-1  items-center flex flex-col lg:flex-row'>
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className='text-error'
          />
          <p className='text-red-600 rounded bg-red-200 text-center my-4'>
            {isError}
          </p>
        </div>
      )}
      <div className='bg-white rounded my-4'>
        <CardElement
          onChange={(e) => {
            setStripeError(e.error);
          }}
          className='appearance-none p-2 border border-gray-200 rounded bg-white focus:outline-none focus:ring-0 w-full'
        />
      </div>

      <ZenButton
        isSubmitting={processing}
        isDisabled={processing}
        type='submit'
        label={processing ? 'Processing...' : 'Pay'}
        isFullWidth
      />
    </form>
  );
};

export default PaymentCard;
