import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { Resolver, useFieldArray, useForm } from 'react-hook-form-v7';
import { values, get } from 'lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { SchemaOf } from 'yup';
import {
  faAddressCard,
  faClipboardCheck,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import Button from '../Button';
import {
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
  AdministrativeAreaResponseCountryEnum,
  AgentControllerApi,
  LicenseRequest,
} from '../../openapi/yenta';
import {
  AppDispatch,
  AsyncSelectOption,
  ISelectOption,
  RootState,
  YesNoType,
} from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import ErrorService from '../../services/ErrorService';
import FormErrorMessage from '../FormErrorMessage';
import ResourceContainer from '../ResourceContainer';
import ControlledSelectInputV7 from '../ControlledSelectInputV7';
import ControlledTextInputV7 from '../ControlledTextInputV7';
import ControlledDatePickerInputV7 from '../ControlledDatePickerInputV7';
import ControlledAsyncMultiSelectInputV7 from '../ControlledAsyncMultiSelectInputV7';
import ControlledStateOrProvinceInputV7 from '../ControlledStateOrProvinceInputV7';
import AgentOnboardingLayout from '../AgentOnboardingLayout';
import ControlledRadioInputV7 from '../ControlledRadioInputV7';
import Alert from '../Alert';
import { getYesterdayDate } from '../../utils/DateUtils';
import ZenControlledImageUpload from '../Zen/Input/ZenControlledImageUploadInput';
import { fetchAgentDetail } from '../../slices/AgentSlice';
import { FILE_VALIDATIONS } from '../../utils/Validations';
import ZenRoute from '../Zen/ZenRoute';
import { fetchBoards } from '../../slices/BoardSlice';
import { DEFAULT_PAGE_SIZE } from '../../constants/TableConstants';
import { fetchLiteMLSDetails } from '../../slices/MLSSlice';

interface FormDataLicenses
  extends Omit<LicenseRequest, 'active' | 'knownComplaints'> {
  active: YesNoType;
  knownComplaints: YesNoType;
  licenseImage: string;
}
interface FormData {
  mls: ISelectOption[];
  boards: ISelectOption[];
  licenses: FormDataLicenses[];
}

const validationSchema: SchemaOf<FormData> = yup.object().shape({
  mls: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
        disabled: yup.boolean().nullable(),
        subLabel: yup.string().nullable(),
      }) as SchemaOf<ISelectOption>,
    )
    .min(1, 'MLS is required')
    .required('MLS is required'),
  boards: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
        disabled: yup.boolean().nullable(),
        subLabel: yup.string().nullable(),
      }) as SchemaOf<ISelectOption>,
    )
    .min(1, 'Board is required')
    .required('Board is required'),
  licenses: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          expirationDate: yup
            .string()
            .nullable()
            .required('Expiration Date is required.')
            .test(
              'Expiration Date',
              'Expiration date cannot be in the past',
              (value) => getYesterdayDate() < DateTime.fromISO(value!),
            ),
          number: yup.string().required('License Number is required.'),
          active: yup.string().oneOf(['YES', 'NO']).required('Required'),
          licenseType: yup.string().nullable(),
          licenseImage: yup.mixed().required('License image is required'),
          knownComplaints: yup
            .string()
            .oneOf(['YES', 'NO'])
            .required('Required'),
          administrativeAreaRequest: yup.object().shape({
            country: yup.string().required('Country is required.'),
            stateOrProvince: yup
              .string()
              .required('State / Province is required.'),
          }),
        })
        .test(
          'one license per state',
          'Only one license per state is allowed',
          function (value) {
            const states =
              this.parent?.map(
                (license: FormDataLicenses) =>
                  license.administrativeAreaRequest.stateOrProvince,
              ) || [];

            return (
              states.filter(
                (state: string) =>
                  state === value.administrativeAreaRequest.stateOrProvince,
              ).length === 1
            );
          },
        ) as SchemaOf<FormDataLicenses>,
    )
    .min(1, 'License is required')
    .required('License is required'),
});

const VerifyLicensesOnboarding: React.FC = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const defaultValues = useMemo<FormData>(
    () => ({
      mls: userDetail!.mls!.map((m) => ({ value: m.id!, label: m.name! })),
      boards: userDetail!.boards!.map((board) => ({
        value: board.id!,
        label: board.name!,
      })),
      licenses: userDetail!.licenses!.map(
        (license) =>
          (({
            ...license,
            active: license.active ? YesNoType.YES : YesNoType.NO,
            knownComplaints: license.knownComplaints
              ? YesNoType.YES
              : YesNoType.NO,
            administrativeAreaRequest: {
              ...license.administrativeArea!,
            },
          } as unknown) as FormDataLicenses),
      ),
    }),
    [userDetail],
  );
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
    resolver: (yupResolver(validationSchema) as unknown) as Resolver<FormData>,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'licenses',
  });
  const licenseErrorMsg = get(errors.licenses, 'message');
  const yesNoOptions: ISelectOption[] = [
    { label: 'Yes', value: YesNoType.YES },
    { label: 'No', value: YesNoType.NO },
  ];

  const onSubmit = async (data: FormData) => {
    const mlsIds = data.mls?.map((m: AsyncSelectOption) => m.value);
    const boardIds = data.boards?.map((b: AsyncSelectOption) => b.value);
    const licenses = data.licenses.map((license) => ({
      licenseType: license.licenseType,
      number: license.number,
      expirationDate: license.expirationDate,
      administrativeAreaRequest: license.administrativeAreaRequest,
      active: license.active === YesNoType.YES,
      knownComplaints: license.knownComplaints === YesNoType.YES,
    })) as LicenseRequest[];
    const licenseImages = data.licenses.map((license) => license.licenseImage);

    try {
      await Promise.all([
        await new AgentControllerApi(
          getYentaConfiguration(),
        ).updateAgentMlsesById(userDetail?.id!, {
          mlsIds: mlsIds,
        }),
        await new AgentControllerApi(
          getYentaConfiguration(),
        ).updateAgentBoardsById(userDetail?.id!, {
          boardIds: boardIds,
        }),
        await new AgentControllerApi(
          getYentaConfiguration(),
        ).updateAgentLicensesById(
          userDetail?.id!,
          //@ts-ignore
          { licenses: licenses },
          licenseImages,
        ),
        await new AgentControllerApi(
          getYentaConfiguration(),
        ).updateLicenseVerified(userDetail?.id!, {
          licenseVerified: true,
        }),
      ]);

      await dispatch(fetchAgentDetail(userDetail?.id!));
      dispatch(showSuccessToast('Licenses updated successfully.'));
      history.push('/');
    } catch (e) {
      ErrorService.notifyIgnoreAuthErrors('Error updating licenses', e, {
        userDetail: { id: userDetail!.id },
        data: { ...data },
      });
      dispatch(showErrorToast('Error updating licenses'));
    }
  };

  return (
    <ZenRoute title='Verify Licenses'>
      <AgentOnboardingLayout title='Verify Licenses'>
        <form
          className='flex flex-col flex-auto justify-between h-full w-full sm:max-w-xl mx-auto'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='p-4'>
            <Alert
              text='Please verify that your license information as well as your memberships to boards and MLSs are up-to-date.'
              variant='default'
              icon={<FontAwesomeIcon icon={faClipboardCheck} size='lg' />}
              className='mb-5'
            />
            <ControlledAsyncMultiSelectInputV7<FormData, 'mls'>
              control={control}
              name='mls'
              label='MLSs'
              fetchData={async (search, page) => {
                const res = await dispatch(
                  fetchLiteMLSDetails(page, DEFAULT_PAGE_SIZE, 'ASC', search),
                );
                return (res?.results || []).map((resp) => ({
                  value: `${resp?.id}`,
                  label: `${resp?.name}`,
                }));
              }}
              shouldUnregister={false}
            />
            <div className='mt-4'>
              <ControlledAsyncMultiSelectInputV7<FormData, 'boards'>
                control={control}
                name='boards'
                label='Boards'
                fetchData={async (search, page) => {
                  const res = await dispatch(
                    fetchBoards({
                      search,
                      page: page ?? 0,
                      pageSize: 20,
                      sortBy: { name: 'asc' },
                    }),
                  );
                  const options: AsyncSelectOption[] = (res?.results || []).map(
                    (resp) => ({
                      value: `${resp?.id}`,
                      label: `${resp?.name}`,
                    }),
                  );

                  return options;
                }}
                shouldUnregister={false}
              />
            </div>
            <p className='font-primary-medium mt-4'>Licenses</p>
            {licenseErrorMsg && <FormErrorMessage message={licenseErrorMsg} />}
            <ResourceContainer
              loading={false}
              isEmpty={!fields.length}
              resourceName='license'
              emptyIcon={
                <FontAwesomeIcon icon={faAddressCard} className='text-4xl' />
              }
            >
              {fields.map((field, index) => {
                const stateOrProvinceErrorMsg = get(
                  errors.licenses,
                  `${index}.message`,
                );

                return (
                  <div
                    className='border-b py-4 border-coolGray-200'
                    key={field.id}
                  >
                    <div className='relative grid grid-cols-1 md:grid-cols-2 gap-3'>
                      <ControlledSelectInputV7<
                        FormData,
                        `licenses.${number}.administrativeAreaRequest.country`
                      >
                        label='Country'
                        control={control}
                        options={[
                          {
                            label: 'Choose Country',
                            value: '',
                            disabled: true,
                          },
                          ...values(AdministrativeAreaResponseCountryEnum).map(
                            (state) => ({
                              value: state,
                              label: capitalizeEnum(state),
                            }),
                          ),
                        ]}
                        name={
                          `licenses.${index}.administrativeAreaRequest.country` as const
                        }
                        shouldUnregister={false}
                      />
                      <div>
                        <ControlledStateOrProvinceInputV7<
                          FormData,
                          `licenses.${number}.administrativeAreaRequest.stateOrProvince`
                        >
                          selectedCountry={watch(
                            `licenses.${index}.administrativeAreaRequest.country` as const,
                          )}
                          control={control}
                          setValue={setValue}
                          name={
                            `licenses.${index}.administrativeAreaRequest.stateOrProvince` as const
                          }
                          shouldUnregister={false}
                        />
                        {stateOrProvinceErrorMsg && (
                          <p className='mt-1'>
                            <FormErrorMessage
                              message={stateOrProvinceErrorMsg}
                            />
                          </p>
                        )}
                      </div>
                      <div className='col-span-2'>
                        <ZenControlledImageUpload<
                          FormData,
                          `licenses.${number}.licenseImage`
                        >
                          name={`licenses.${index}.licenseImage`}
                          control={control}
                          shouldUnregister={false}
                          label='License Image'
                          uploadText='Upload License'
                          rules={{
                            required: 'License Image is required',
                            ...FILE_VALIDATIONS,
                          }}
                        />
                      </div>
                      <ControlledTextInputV7<
                        FormData,
                        `licenses.${number}.number`
                      >
                        control={control}
                        name={`licenses.${index}.number` as const}
                        label='Number'
                        shouldUnregister={false}
                      />
                      <ControlledDatePickerInputV7<
                        FormData,
                        `licenses.${number}.expirationDate`
                      >
                        control={control}
                        label='Expiration Date'
                        name={`licenses.${index}.expirationDate` as const}
                        shouldUnregister={false}
                      />
                      <div className='col-span-full space-y-3 max-w-sm'>
                        <ControlledRadioInputV7<
                          FormData,
                          `licenses.${number}.active`
                        >
                          control={control}
                          label='License Active?'
                          name={`licenses.${index}.active` as const}
                          options={yesNoOptions}
                          inlineOptions
                          inlineLabel
                          shouldUnregister={false}
                        />
                        <ControlledRadioInputV7<
                          FormData,
                          `licenses.${number}.knownComplaints`
                        >
                          control={control}
                          label='Known Complaints?'
                          name={`licenses.${index}.knownComplaints` as const}
                          options={yesNoOptions}
                          inlineOptions
                          inlineLabel
                          shouldUnregister={false}
                        />
                      </div>
                      <div className='absolute right-0 top-0'>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className='text-sm
                         text-dark cursor-pointer'
                          onClick={() => remove(index)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </ResourceContainer>
            <div className='mt-4'>
              <Button
                type='outline'
                buttonType='button'
                label='Add More Licenses'
                onClick={() =>
                  append({
                    administrativeAreaRequest: {
                      country: '' as AdministrativeAreaRequestCountryEnum,
                      stateOrProvince: '' as AdministrativeAreaRequestStateOrProvinceEnum,
                    },
                    active: YesNoType.YES,
                    knownComplaints: YesNoType.NO,
                    number: '',
                    expirationDate: '',
                  })
                }
              />
            </div>
            <div className='flex flex-row-reverse mt-4'>
              <Button
                disabled={isSubmitting}
                isSubmitting={isSubmitting}
                buttonType='submit'
                label='Save'
                size='lg'
              />
            </div>
          </div>
        </form>
      </AgentOnboardingLayout>
    </ZenRoute>
  );
};

export default VerifyLicensesOnboarding;
