import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  NationalIdentificationValue,
  NationalIdentificationValueTypeEnum,
  SetNationalIdentficationsRequest,
} from '../../openapi/yenta';
import { updateNationalIdentifications } from '../../slices/AgentSlice';
import { RootState } from '../../types';
import { getIdValidations, isCanadianUser } from '../../utils/AgentHelper';
import { getTaxId } from '../../utils/TaxIDsUtils';
import ZenSidebarModalActionFooter from '../SidebarModal/ZenSideBarModalActionFooter';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenSidebarModal from '../Zen/ZenSidebarModal';

interface ZenNationalIDFormProps {
  isOpen: boolean;
  onClose(): void;
  nationIDs: NationalIdentificationValue[];
}

interface FormData {
  ssn: string;
  sin: string;
  gstId?: string;
  pstId?: string;
  hstId?: string;
  qstId?: string;
}

const ZenNationalIDForm: React.FC<ZenNationalIDFormProps> = ({
  isOpen,
  onClose,
  nationIDs,
}) => {
  const dispatch = useDispatch();
  const {
    detailResponse: { data: detail },
  } = useSelector((state: RootState) => state.agentDetail);
  const defaultValues = useMemo(
    () => ({
      ssn: getTaxId(NationalIdentificationValueTypeEnum.Ssn, nationIDs),
      sin: getTaxId(NationalIdentificationValueTypeEnum.Sin, nationIDs),
      gstId: getTaxId(NationalIdentificationValueTypeEnum.GstId, nationIDs),
      pstId: getTaxId(NationalIdentificationValueTypeEnum.PstId, nationIDs),
      hstId: getTaxId(NationalIdentificationValueTypeEnum.HstId, nationIDs),
      qstId: getTaxId(NationalIdentificationValueTypeEnum.QstId, nationIDs),
    }),
    [nationIDs],
  );
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({ defaultValues });
  const canadianUser = isCanadianUser(detail?.accountCountry!);

  const onSubmit = async (formData: FormData) => {
    let identifications: SetNationalIdentficationsRequest = {
      identifications: !canadianUser
        ? [{ id: formData.ssn!, type: NationalIdentificationValueTypeEnum.Ssn }]
        : [
            {
              id: formData.sin!,
              type: NationalIdentificationValueTypeEnum.Sin,
            },
            {
              id: formData.gstId!,
              type: NationalIdentificationValueTypeEnum.GstId,
            },
            {
              id: formData.pstId!,
              type: NationalIdentificationValueTypeEnum.PstId,
            },
            {
              id: formData.hstId!,
              type: NationalIdentificationValueTypeEnum.HstId,
            },
            {
              id: formData.qstId!,
              type: NationalIdentificationValueTypeEnum.QstId,
            },
          ].filter((item) => !!item.id),
    };
    await dispatch(updateNationalIdentifications(detail?.id!, identifications));
    onClose();
  };

  return (
    <ZenSidebarModal
      title='Personal Tax ID'
      subtitle='Personal or individual ID for tax reporting purposes'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        title='tax-ids-form'
        className='flex flex-col'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4'>
          {!canadianUser ? (
            <div>
              <ZenControlledTextInput<FormData, 'ssn'>
                control={control}
                name='ssn'
                label='Social security number (SSN)'
                placeholder='Ex. 123-12-1234'
                rules={{
                  required: 'SSN is required',
                  ...getIdValidations(NationalIdentificationValueTypeEnum.Ssn),
                }}
                isRequired
              />
            </div>
          ) : (
            <div>
              <div>
                <ZenControlledTextInput<FormData, 'sin'>
                  control={control}
                  name='sin'
                  label='Social insurance number (SIN)'
                  placeholder='Ex. 123-123-1234'
                  rules={{
                    required: 'SIN is required',
                    ...getIdValidations(
                      NationalIdentificationValueTypeEnum.Sin,
                    ),
                  }}
                  isRequired
                />
              </div>
              <div className='mt-5'>
                <ZenControlledTextInput<FormData, 'gstId'>
                  control={control}
                  name='gstId'
                  label='Goods and services tax (GST)'
                  placeholder='Ex. 123456789 RT 0001'
                  rules={{
                    required: 'GST is required',
                    ...getIdValidations(
                      NationalIdentificationValueTypeEnum.GstId,
                    ),
                  }}
                  isRequired
                />
              </div>
              <div className='mt-5'>
                <ZenControlledTextInput<FormData, 'hstId'>
                  control={control}
                  name='hstId'
                  label='Harmonized sales tax (HST)'
                  placeholder='Ex. 123456789 RT 0002'
                  rules={{
                    ...getIdValidations(
                      NationalIdentificationValueTypeEnum.HstId,
                    ),
                  }}
                />
              </div>
              <div className='mt-5'>
                <ZenControlledTextInput<FormData, 'pstId'>
                  control={control}
                  name='pstId'
                  label='Provincial sales tax (PST)'
                  placeholder='Ex. PST-1234-1234'
                  rules={{
                    ...getIdValidations(
                      NationalIdentificationValueTypeEnum.PstId,
                    ),
                  }}
                />
              </div>
              <div className='mt-5'>
                <ZenControlledTextInput<FormData, 'qstId'>
                  control={control}
                  name='qstId'
                  label='Quebec sales tax (QST)'
                  placeholder='Ex. 1234567890 TQ 1234'
                  rules={{
                    ...getIdValidations(
                      NationalIdentificationValueTypeEnum.QstId,
                    ),
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <ZenSidebarModalActionFooter
          isSubmitting={isSubmitting}
          onClose={onClose}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenNationalIDForm;
