import { CancelToken } from 'axios';
import { isEqual, isMatch, map, merge } from 'lodash';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UnpackNestedValue } from 'react-hook-form-v7';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Column,
  Filters,
  IdType,
  PluginHook,
  Row,
  SortingRule,
  TableState,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { DEFAULT_PAGE_SIZE } from '../../../constants/TableConstants';
import { ResourceTableVariant } from '../../../containers/ResourceTable';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import {
  IPaginateReq,
  IPaginateRes,
  SearchParam,
  VerticalAlignmentVariant,
  ZenRowSelectionConfirmType,
  ZenRowsSelectionAction,
} from '../../../types';
import { isSmScreen } from '../../../utils/BrowserUtils';
import { cn } from '../../../utils/classUtils';
import { PaginateMockRealAPI } from '../../../utils/PaginateMockRealAPI';
import { parseQueryString } from '../../../utils/QueryStringUtils';
import {
  customFixedFilter,
  getPageCount,
  getPaginateReqFromState,
  getZenSelectionOptions,
  mapToStateFilter,
  mapToStateSortBy,
} from '../../../utils/TableUtils';
import SearchBar from '../../SearchBar';
import useZenAllowSelection from '../../table/plugins/useZenAllowSelection';
import ZenTableSelectCheckbox from '../../table/ZenTableSelectCheckbox';
import TableRowActionBar, {
  ActionBarButtonsProps,
} from '../../TableRowActionBar';
import ZenResourceTable, {
  ResourceIndexContainerDisplayVariant,
  ResourceTableHeaderVariant,
  ResourceTableRowGroupByVariant,
} from '../Table/ZenResourceTable';
import ZenTableOptions from '../Table/ZenTableOptions';
import ZenTabs from '../ZenTabs';

export interface IResourceIndexTab<D> {
  name: string;
  filter?: TableResourceFilter<D>;
  hiddenColumns?: IdType<D>[];
}

export type CardItemPosition =
  | 'top-left'
  | 'top-right'
  | 'main'
  | 'collapsible';

export type InitialSort<T> = {
  [key in keyof T]?: 'asc' | 'desc';
};

export type TableResourceFilter<T> = {
  [key in keyof T]?: T[key] | SearchParam;
};

export type ApiHandlerMethodType = keyof PaginateMockRealAPI;

export type ZenTableSelectionOption<D extends object> = Omit<
  ZenRowsSelectionAction<D>,
  'disabled' | 'confirm'
> & {
  confirm?:
    | ZenRowSelectionConfirmType<D>
    | ((data: D[]) => ZenRowSelectionConfirmType<D>);
  isActionButtonEnabled?(data: D[]): boolean;
};

export type CustomColumn<D extends object = {}> = Column<D> & {
  position?: CardItemPosition;
};

export interface TableProps<D extends object> {
  header?: string;
  tabs?: Array<IResourceIndexTab<D>>;
  defaultSelectedTabName?: string;
  columns: Array<CustomColumn<D>>;
  hiddenColumns?: Array<IdType<D>>;
  filter?: TableResourceFilter<D>;
  initialSort?: InitialSort<D>;
  search?: string;
  resourceName: string;
  fetchData?: (
    req: IPaginateReq<D>,
    cancelToken?: CancelToken,
  ) => Promise<IPaginateRes<D>>;
  data?: D[];
  hideFilters?: boolean;
  hidePagination?: boolean;
  paginationContainerClassNames?: string;
  hidePageSize?: boolean;
  pageSize?: number;
  RightComponent?: React.ReactElement;
  standalone?: boolean;
  allowSelection?: boolean;
  actionBarButtons?: Array<ActionBarButtonsProps<D>>;
  showSelectionActionBar?: boolean;
  selectionOptions?: ZenTableSelectionOption<D>[];
  showSearch?: boolean;
  emptyIconComponent?: React.ReactElement;
  customEmptyComponent?: React.ReactElement;
  cellVerticalAlignment?: VerticalAlignmentVariant;
  renderToggleRowComponent?(d: Row<D>): React.ReactElement;
  getTableRowClassNames?(d: Row<D>): string;
  allowSortingRows?: boolean;
  onSortEnd?(
    oldIndex: number,
    newIndex: number,
    item: D,
    items: Row<D>[],
  ): void;
  headerVariant?: ResourceTableHeaderVariant;
  pageSizeOptions?: number[];
  hideRowBorder?: boolean;
  groupByProperty?: ResourceTableRowGroupByVariant;
  setCurrentTab?: React.Dispatch<React.SetStateAction<string>>;
  hideHeader?: boolean;
  compact?: boolean;
  selectionPortal?: React.MutableRefObject<any>;
  stickyHeader?: boolean;
  resourceContainerDisplayVariant?: ResourceIndexContainerDisplayVariant;
}

export interface TableQueryParamState<T extends object> {
  filter?: Filters<T>;
  pageSize: string;
  pageIndex: string;
  sortBy: SortingRule<T>[];
  search?: string;
}

interface DataWithOptionalId extends Object {
  id?: string;
}

const ZenResourceIndexContainer = <D extends DataWithOptionalId>({
  header,
  tabs = [],
  defaultSelectedTabName,
  columns,
  initialSort,
  hiddenColumns = [],
  filter,
  search,
  fetchData,
  data = [],
  resourceName,
  hideFilters = false,
  hidePagination = false,
  paginationContainerClassNames,
  hidePageSize = false,
  pageSize = DEFAULT_PAGE_SIZE,
  RightComponent,
  standalone = true,
  allowSelection = false,
  showSelectionActionBar = false,
  selectionOptions = [],
  actionBarButtons,
  showSearch = false,
  allowSortingRows = false,
  emptyIconComponent,
  customEmptyComponent,
  cellVerticalAlignment,
  renderToggleRowComponent,
  getTableRowClassNames,
  onSortEnd,
  headerVariant,
  pageSizeOptions,
  hideRowBorder,
  groupByProperty,
  setCurrentTab,
  hideHeader = false,
  compact = false,
  selectionPortal,
  stickyHeader = false,
  resourceContainerDisplayVariant = 'TABLE',
}: TableProps<D>) => {
  const isNoTabs = !tabs.length;
  const [pageCount, setPageCount] = useState(0);
  const [paginatedRes, setPaginatedRes] = useState<IPaginateRes<D>>({
    total: data?.length ?? 0,
    data,
  });
  const location = useLocation();
  const history = useHistory();
  const {
    filter: filterFromQS,
    pageSize: pageSizeFromQS,
    pageIndex: pageFromQS,
    sortBy: sortByQS,
  }: TableQueryParamState<D> = parseQueryString<TableQueryParamState<D>>(
    location.search,
  );
  const isFixedTable = !fetchData;

  const initialActiveTab = tabs.find(
    (tab) => tab.name === defaultSelectedTabName || tabs[0]?.filter,
  );

  const filterByAccessorFromQS = (filterFromQS || [])?.filter(
    (f) => !!columns?.find((c) => c.id === f.id || c.accessor === f.id),
  );

  const initialState: Partial<TableState<D>> = {
    pageIndex: !!pageFromQS ? parseInt(pageFromQS, 10) : 0,
    pageSize: !!pageSizeFromQS
      ? parseInt(pageSizeFromQS, 10)
      : hidePagination
      ? Math.max(paginatedRes.data.length, 1)
      : pageSize,
    filters: !!filterByAccessorFromQS?.length
      ? filterByAccessorFromQS
      : mapToStateFilter({
          ...(filter || {}),
          ...(initialActiveTab?.filter || {}),
        }),
    sortBy: !!sortByQS?.length ? sortByQS : mapToStateSortBy(initialSort),
    globalFilter: search || undefined,
    hiddenColumns,
  };

  const plugins: PluginHook<D>[] = [
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  ];

  if (allowSelection) {
    plugins.push(useZenAllowSelection);
  }

  let columnsToUse = useMemo(() => {
    if (!isFixedTable) return columns;

    return columns.map((column) => ({
      ...column,
      filter: customFixedFilter,
    }));
  }, [columns, isFixedTable]);

  const tableInstance = useTable<D>(
    {
      columns: columnsToUse,
      data: paginatedRes.data,
      manualGlobalFilter: !isFixedTable,
      manualFilters: !isFixedTable,
      manualPagination: !isFixedTable,
      manualSortBy: !isFixedTable,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetPage: false,
      defaultCanFilter: false,
      initialState,
      pageCount,
      getRowId: (originalRow: D, index: number) =>
        'id' in originalRow ? originalRow.id! : index.toString(),
    },
    ...plugins,
  );

  const selectedRows = tableInstance.selectedFlatRows.map(
    (flatRows) => flatRows.original,
  );
  const options = getZenSelectionOptions<D>(selectedRows, selectionOptions);
  const getStateFilters = () =>
    merge(
      {},
      ...map(tableInstance.state.filters, (f) => ({ [f.id]: f.value })),
    );

  const selectedTabFromQS = tabs.find(
    (tab) => !!isMatch(getStateFilters(), tab.filter || {}),
  );
  const [selectedTab, setSelectedTab] = useState(
    selectedTabFromQS?.name || initialActiveTab?.name,
  );

  const handleFetch = async (
    req: IPaginateReq<D>,
    cancelToken?: CancelToken,
  ): Promise<IPaginateRes<D>> => {
    const res = await fetchData!(req, cancelToken);
    setPaginatedRes(res);
    setPageCount(getPageCount(res.total, tableInstance.state.pageSize));
    return res;
  };

  const updateStateUrl = useCallback(() => {
    history.replace(
      `${history.location.pathname}?${qs.stringify({
        ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
        filter: tableInstance.state.filters,
        sortBy: tableInstance.state.sortBy,
        search: tableInstance.state.globalFilter,
        pageIndex: tableInstance.state.pageIndex,
        pageSize: tableInstance.state.pageSize,
      })}`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableInstance.state.filters,
    tableInstance.state.globalFilter,
    tableInstance.state.pageIndex,
    tableInstance.state.pageSize,
    tableInstance.state.sortBy,
  ]);

  const onSelectionOptionClicked = async (
    index: number,
    formData?: UnpackNestedValue<D>,
  ) => {
    await selectionOptions[index].onAction(
      tableInstance.selectedFlatRows.map((flatRows) => flatRows.original),
      formData,
    );
    tableInstance.toggleAllRowsSelected(false);
    const req = getPaginateReqFromState(tableInstance.state);
    if (!isFixedTable) {
      await handleFetch(req);
    }
  };

  const renderTable = () => (
    <>
      {allowSelection &&
        showSelectionActionBar &&
        !!tableInstance?.selectedFlatRows?.length && (
          <div className='mb-1'>
            <TableRowActionBar
              items={tableInstance?.selectedFlatRows}
              handleCancel={() => tableInstance?.toggleAllRowsSelected(false)}
              actionBarButtons={actionBarButtons}
            />
          </div>
        )}
      <ZenResourceTable<D>
        {...tableInstance}
        totalCount={paginatedRes.total}
        resourceName={resourceName}
        fetchData={!isFixedTable ? handleFetch : undefined}
        variant={
          isSmScreen() ? ResourceTableVariant.CARD : ResourceTableVariant.ROW
        }
        hidePagination={hidePagination}
        hidePageSize={hidePageSize}
        emptyIconComponent={emptyIconComponent}
        customEmptyComponent={customEmptyComponent}
        cellVerticalAlignment={cellVerticalAlignment}
        renderToggleRowComponent={renderToggleRowComponent}
        getTableRowClassNames={getTableRowClassNames}
        allowSortingRows={allowSortingRows}
        onSortEnd={onSortEnd}
        headerVariant={headerVariant}
        pageSizeOptions={pageSizeOptions}
        hideRowBorder={hideRowBorder}
        groupByProperty={groupByProperty}
        hideHeader={hideHeader}
        compact={compact}
        stickyHeader={stickyHeader}
        paginationContainerClassNames={paginationContainerClassNames}
        resourceContainerDisplayVariant={resourceContainerDisplayVariant}
      />
    </>
  );

  const renderTableOptions = () => {
    return (
      <div
        className={cn('flex flex-row', {
          'mb-4': !hideFilters || RightComponent,
        })}
      >
        <ZenTableOptions<D>
          selectionPortal={selectionPortal}
          hideFilters={hideFilters}
          tableInstance={tableInstance}
          selectionOptions={options}
          onSelectionOptionClicked={onSelectionOptionClicked}
        />
        {!isSmScreen() && RightComponent && (
          <div className='ml-3'>{RightComponent}</div>
        )}
      </div>
    );
  };

  const filterTabs = tabs.map(({ name }: IResourceIndexTab<D>) => ({
    name,
    TabComponent: renderTable(),
  }));

  const handleTabChange = (tabName: string) => {
    setSelectedTab(tabName);
    // if tab is changed then we will reset the page to 0
    tableInstance.gotoPage(0);
  };

  const handleChange = useCallback(() => {
    if (!isNoTabs) {
      const currentTab = tabs.find((t) => t.name === selectedTab);
      tableInstance.setAllFilters(
        mapToStateFilter({
          ...(getStateFilters() || {}),
          ...(currentTab?.filter || {}),
        }),
      );

      tableInstance.setHiddenColumns(currentTab?.hiddenColumns || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useDidUpdateEffect(() => {
    handleChange();
  }, [handleChange]);

  useEffect(() => {
    if (!isNoTabs && setCurrentTab) {
      setCurrentTab(selectedTab!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    if (tableInstance.state.globalFilter !== search) {
      tableInstance.setGlobalFilter(search || undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (standalone) {
      updateStateUrl();
    }
  }, [standalone, updateStateUrl]);

  useEffect(() => {
    if (isFixedTable && !isEqual(paginatedRes.data, data)) {
      setPaginatedRes({ data, total: data.length });

      // Reset the page to the new array length.
      if (hidePagination) {
        tableInstance.setPageSize(data?.length);
      }
    }
  }, [tableInstance, data, isFixedTable, paginatedRes?.data, hidePagination]);

  return (
    <div>
      <div>
        {isSmScreen() && RightComponent && (
          <div className='flex justify-end mb-4'>{RightComponent}</div>
        )}
        <div className='flex flex-row items-center'>
          {header && <h1 className='text-xl hidden lg:block mb-4'>{header}</h1>}
          <div className='flex flex-row items-center flex-grow justify-end space-x-5'>
            {(isSmScreen() || isNoTabs) && showSearch && (
              <div className='flex-grow md:max-w-sm mb-4 ml-auto'>
                <SearchBar
                  onChange={(text) =>
                    tableInstance.setGlobalFilter(text || undefined)
                  }
                  value={tableInstance.state.globalFilter || ''}
                />
              </div>
            )}
            {(isSmScreen() || isNoTabs) && renderTableOptions()}
          </div>
        </div>
      </div>
      {isSmScreen() && allowSelection && (
        <div className='flex flex-row items-center space-x-2 self-start py-3'>
          <ZenTableSelectCheckbox
            {...tableInstance?.getToggleAllPageRowsSelectedProps()}
          />
          <p className='font-zen-body font-semibold uppercase text-xs text-zen-dark-5'>
            Select All Rows
          </p>
        </div>
      )}

      {isNoTabs ? (
        renderTable()
      ) : (
        <ZenTabs
          onChange={handleTabChange}
          selected={selectedTab}
          tabs={filterTabs}
          size='lg'
          RightComponent={
            <div className='hidden md:block'>
              {!isSmScreen() && showSearch && (
                <div className='w-72 mb-4'>
                  <SearchBar
                    onChange={(text) =>
                      tableInstance.setGlobalFilter(text || undefined)
                    }
                    value={tableInstance.state.globalFilter || ''}
                  />
                </div>
              )}
              {renderTableOptions()}
            </div>
          }
        />
      )}
    </div>
  );
};

export default ZenResourceIndexContainer;
