import { faTag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface ReferralTagProps {
  personalNickname?: string;
}

const ReferralTag: React.FC<ReferralTagProps> = ({ personalNickname }) => {
  return (
    <div className='flex flex-row items-center justify-start space-x-2 bg-purple-400 bg-opacity-5 p-2 rounded-md relative mt-1'>
      <div className='pl-2 flex items-center justify-center'>
        <FontAwesomeIcon
          icon={faTag}
          size='sm'
          className='text-white pl-[7px] p-1.5 rounded-full bg-purple-400'
        />
      </div>
      <p className='text-primary-navy'>{personalNickname}</p>
      <div className='bg-white rounded-full absolute top-4 h-3 w-3 -right-1.5' />
    </div>
  );
};

export default ReferralTag;
