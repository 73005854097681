export interface CrownProps {
  length: number;
}

const Crown: React.FC<CrownProps> = ({ length }) => {
  return (
    <svg
      width={length}
      height={length}
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid='crownIcon'
    >
      <path
        d='M3.75 12.7273L2.25 4.47727L6.375 8.22727L9 3.72727L11.625 8.22727L15.75 4.47727L14.25 12.7273H3.75ZM14.25 14.9773C14.25 15.4273 13.95 15.7273 13.5 15.7273H4.5C4.05 15.7273 3.75 15.4273 3.75 14.9773V14.2273H14.25V14.9773Z'
        fill='#FFCE3D'
      />
    </svg>
  );
};

export default Crown;
