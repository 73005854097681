import {
  CheckCircleOutline,
  Close,
  WarningRounded,
  Adjust,
} from '@material-ui/icons';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponseCountryEnum,
} from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';
import { ReactComponent as FileContract } from '../../../assets/icons/zen/transaction/file-contract.svg';
import { renderTransactionStatusByCountry } from '../../../utils/TransactionHelper';
import Pill, { PillVariantType } from '../../Pill';

export interface TransactionStatusCellProps {
  status: TransactionLifecycleStateValueStateEnum;
  country: TransactionResponseCountryEnum;
}

export const transactionStatusToVariant: EnumMap<
  TransactionLifecycleStateValueStateEnum,
  PillVariantType
> = {
  [TransactionLifecycleStateValueStateEnum.ApprovedForClosing]: 'success',
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved]:
    'success',
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentGenerated]:
    'success',
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentSent]: 'success',
  [TransactionLifecycleStateValueStateEnum.ReadyForCommissionDocumentGeneration]:
    'warning',
  [TransactionLifecycleStateValueStateEnum.Settled]: 'success',
  [TransactionLifecycleStateValueStateEnum.CalculateLedger]: 'warning',
  [TransactionLifecycleStateValueStateEnum.WaitingOnPayment]: 'warning',
  [TransactionLifecycleStateValueStateEnum.Closed]: 'success',
  [TransactionLifecycleStateValueStateEnum.CommissionValidated]: 'success',
  [TransactionLifecycleStateValueStateEnum.NeedsCommissionValidation]:
    'warning',
  [TransactionLifecycleStateValueStateEnum.New]: 'warning',
  [TransactionLifecycleStateValueStateEnum.PaymentAccepted]: 'success',
  [TransactionLifecycleStateValueStateEnum.PaymentScheduled]: 'primary',
  [TransactionLifecycleStateValueStateEnum.Terminated]: 'danger',
  [TransactionLifecycleStateValueStateEnum.ListingActive]: 'success',
  [TransactionLifecycleStateValueStateEnum.ListingClosed]: 'default',
  [TransactionLifecycleStateValueStateEnum.ListingInContract]: 'warning',
  [TransactionLifecycleStateValueStateEnum.TerminationRequested]: 'alert',
};

export const transactionStatusToIcon: EnumMap<
  TransactionLifecycleStateValueStateEnum,
  React.ReactElement
> = {
  [TransactionLifecycleStateValueStateEnum.ApprovedForClosing]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentGenerated]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentSent]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.ReadyForCommissionDocumentGeneration]: (
    <WarningRounded fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.Settled]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.CalculateLedger]: (
    <WarningRounded fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.Closed]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.CommissionValidated]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.NeedsCommissionValidation]: (
    <WarningRounded fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.New]: (
    <WarningRounded fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.PaymentAccepted]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.PaymentScheduled]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.WaitingOnPayment]: (
    <WarningRounded fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.Terminated]: (
    <Close fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.ListingActive]: (
    <Adjust fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.ListingClosed]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.ListingInContract]: <FileContract />,
  [TransactionLifecycleStateValueStateEnum.TerminationRequested]: (
    <Close fontSize='small' />
  ),
};

const TransactionStateCell: React.FC<TransactionStatusCellProps> = ({
  status,
  country,
}) => {
  const variant: PillVariantType = transactionStatusToVariant[status];
  const icon: React.ReactElement = transactionStatusToIcon[status];

  return (
    <Pill
      text={renderTransactionStatusByCountry(status, country)}
      variant={variant!}
      icon={icon!}
    />
  );
};

export default TransactionStateCell;
