import React, { useState } from 'react';
import {
  Control,
  FormState,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as CheckDeposit } from '../../../assets/img/deposit_check.svg';
import { ReactComponent as ZenClipboard } from '../../../assets/img/zen-clipboard.svg';
import { TransactionAddress } from '../../../constants/CommonConstants';
import { REAL_DEPOSITS_FAQ_LINK } from '../../../constants/SupportConstants';
import {
  DepositCheckFormData,
  Match,
} from '../../../routes/ZenTransactionDepositCheckRoute';
import { RootState } from '../../../types';
import {
  getMaxLimitModalSubtitle,
  isUploadLimitExceed,
} from '../../../utils/CheckDepositsHelper';
import { isCanadaTransaction } from '../../../utils/TransactionHelper';
import AgentWebsiteConfirmationModal from '../../agentWebsiteOnboarding/AgentWebsiteConfirmationModal';
import Button from '../../Button';
import ConfirmationModal from '../../ConfirmationModal';
import CopyToClipboardHover from '../../CopyToClipboardHover';
import ZenControlledCurrencyInput from '../../Zen/Input/ZenControlledCurrencyInput';
import ZenControlledDraggableFileUploadInput from '../../Zen/Input/ZenControlledDraggableFileUploadInput';
import ZenButton from '../../Zen/ZenButton';

interface ZenCheckDepositBasicInfoStepProps {
  onSubmit(): Promise<boolean>;
  onPrevious(): void;
  form: {
    control: Control<DepositCheckFormData, object>;
    watch: UseFormWatch<DepositCheckFormData>;
    formState: FormState<DepositCheckFormData>;
    trigger: UseFormTrigger<DepositCheckFormData>;
    setValue: UseFormSetValue<DepositCheckFormData>;
  };
}

const ZenCheckDepositBasicInfoStep: React.FC<ZenCheckDepositBasicInfoStepProps> = ({
  onSubmit,
  onPrevious,
  form: { control, watch },
}) => {
  const [error, setError] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);
  const history = useHistory();
  const { id, escrowId } = useParams<Match>();
  const isExternalCheckDepositUpload = watch('isExternalCheckDeposit');
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
      tokenTransactionDetail,
    },
    checkDeposits: {
      loading,
      requestCounter,
      uploadedCheckDepositsErrorCode,
      uploadedCheckDepositsErrorMessages,
    },
  } = useSelector((state: RootState) => state);

  const currency = isExternalCheckDepositUpload
    ? tokenTransactionDetail?.currencyCode
    : transactionDetail?.currency;

  const handleSubmit = async () => {
    const isError = await onSubmit();
    setError(isError);
  };

  return (
    <div>
      <div className='mt-6'>
        <ZenControlledCurrencyInput
          name='depositAmount'
          control={control}
          shouldUnregister={false}
          label={`Enter deposit amount (${currency})`}
          placeholder='E.g. 10,000.00'
          startAdornment={
            <div className='flex items-center justify-center w-8 h-full text-zen-dark-9'>
              <p className='text-sm font-medium font-primary-medium'>$</p>
            </div>
          }
          rules={{
            required: 'Check Amount is required',
            validate: (amount: string | undefined) =>
              !!amount && parseFloat(amount) <= 0
                ? 'Check Amount should be greater than 0'
                : undefined,
          }}
        />
      </div>
      <div className='mt-6'>
        <ZenControlledDraggableFileUploadInput
          name='frontCheckImage'
          label='Upload front of check image'
          control={control}
          accept='image/*'
          rules={{
            required:
              'An image of the front of the check is required. Make sure the information on the image is legible',
          }}
          shouldUnregister={false}
        />
      </div>
      <div className='mt-6'>
        <ZenControlledDraggableFileUploadInput
          name='backCheckImage'
          label='Upload back of check image'
          control={control}
          accept='image/*'
          rules={{
            required:
              'An image of the back of the check is required. Make sure the information on the image is legible',
          }}
          shouldUnregister={false}
        />
      </div>

      <div className='py-12 grid grid-cols-2 gap-8'>
        <ZenButton
          type='button'
          label={isExternalCheckDepositUpload ? 'Previous' : 'Cancel'}
          variant='secondary-outline'
          onClick={() =>
            isExternalCheckDepositUpload ? onPrevious() : setCancel(true)
          }
        />
        <ZenButton
          type='button'
          LeftIconComponent={<CheckDeposit />}
          label='Preview Deposit'
          isSubmitting={loading}
          isDisabled={loading}
          onClick={handleSubmit}
        />
      </div>
      <AgentWebsiteConfirmationModal
        variant='cancel'
        title='Close and discard all edits?'
        subtitle="Changes won't be saved and you'll go back to the start."
        isOpen={cancel}
        onClose={() => setCancel(false)}
      >
        <div className='flex flex-row justify-between space-x-5 mt-10'>
          <Button
            label='No'
            type='outline'
            size='lg'
            className='text-opacity-40'
            fullWidth
            onClick={() => setCancel(false)}
          />
          <Button
            label='Yes'
            size='lg'
            type='danger'
            buttonType='submit'
            fullWidth
            onClick={() =>
              history.push(`/transactions/${transactionDetail?.id!}`)
            }
          />
        </div>
      </AgentWebsiteConfirmationModal>
      <AgentWebsiteConfirmationModal
        variant='error'
        title='Submission Errors'
        subtitle={
          <div>
            <p className='pb-3'>We were unable to upload your check.</p>
            {uploadedCheckDepositsErrorMessages && (
              <div className='pb-3'>
                <strong className='text-dark'>
                  The following error occurred:{' '}
                </strong>{' '}
                <p>{uploadedCheckDepositsErrorMessages}</p>
              </div>
            )}
            <span>
              Please make sure that the information on the check photos is
              legible and try again.
            </span>
          </div>
        }
        isOpen={
          error &&
          !!uploadedCheckDepositsErrorCode &&
          !isUploadLimitExceed(requestCounter, id)
        }
        onClose={() => setError(false)}
      >
        <div className='flex mt-10'>
          <Button
            label='Close'
            type='outline'
            size='lg'
            className='text-zen-dark-9'
            fullWidth
            onClick={() => setError(false)}
          />
        </div>
      </AgentWebsiteConfirmationModal>
      <ConfirmationModal
        variant='close'
        size='large'
        title='Multiple failed attempts'
        subtitle={getMaxLimitModalSubtitle(transactionDetail)}
        isOpen={
          isUploadLimitExceed(requestCounter, id) &&
          !!uploadedCheckDepositsErrorCode
        }
        onClose={() => undefined}
      >
        <div className='flex flex-col'>
          {!!transactionDetail && (
            <>
              {!isCanadaTransaction(transactionDetail) ? (
                <div className='flex items-center pt-2'>
                  <p className='font-zen-body text-base text-dark'>
                    {TransactionAddress}
                  </p>
                  <div className='ml-2'>
                    <CopyToClipboardHover
                      value={TransactionAddress}
                      copyToltipLabel='Copy'
                      copyIcon={
                        <ZenClipboard
                          width={13}
                          height={13}
                          aria-label='copy-transaction-address'
                        />
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className='flex flex-col space-y-1'>
                  <p className='font-zen-body text-base text-dark pt-2'>
                    {transactionDetail.office?.bankName}
                  </p>
                  <div className='flex flex-row items-center space-x-1'>
                    <p className='flex-shrink-0 font-zen-body text-base text-gray-500'>
                      Account No.
                      <span className='font-zen-body text-base text-dark px-1'>
                        {transactionDetail.office?.bankAccountNumber}
                      </span>
                    </p>
                    <CopyToClipboardHover
                      value={transactionDetail.office?.bankAccountNumber!}
                      copyToltipLabel='Copy'
                      copyIcon={
                        <ZenClipboard
                          width={13}
                          height={13}
                          aria-label='copy-bank-account-number'
                        />
                      }
                    />
                  </div>
                  <div className='flex flex-row items-center space-x-1'>
                    <p className='flex-shrink-0 font-zen-body text-base text-gray-500'>
                      Routing No.
                      <span className='font-zen-body text-base text-dark px-1'>
                        {transactionDetail.office?.bankRoutingNumber}
                      </span>
                    </p>
                    <CopyToClipboardHover
                      value={transactionDetail.office?.bankRoutingNumber!}
                      copyToltipLabel='Copy'
                      copyIcon={
                        <ZenClipboard
                          width={13}
                          height={13}
                          aria-label='copy-routing-number'
                        />
                      }
                    />
                  </div>
                </div>
              )}
              <div className='flex flex-col md:flex-row md:items-center space-x-1 mb-4'>
                <p className='font-zen-body text-base text-dark py-2'>
                  For help, please visit our
                </p>
                <a
                  href={REAL_DEPOSITS_FAQ_LINK}
                  target='_blank'
                  rel='noreferrer'
                  className='flex flex-row items-center space-x-2'
                >
                  <span
                    className='font-zen-body text-base text-primary-blue underline'
                    style={{ textUnderlineOffset: 4 }}
                  >
                    Support Page
                  </span>
                </a>
              </div>
            </>
          )}

          {!!isExternalCheckDepositUpload && (
            <p className='font-zen-body text-base text-dark pt-2'>
              Your check has failed to be processed multiple times. Please reach
              out to the agent or support for more help.
            </p>
          )}

          {!isExternalCheckDepositUpload && (
            <Button
              label='Close'
              size='lg'
              type='outline'
              className='text-gray-400'
              fullWidth
              onClick={() =>
                history.push(
                  escrowId
                    ? `/transactions/${id}/view-deposits`
                    : `/transactions/${id}`,
                )
              }
            />
          )}
        </div>
      </ConfirmationModal>
    </div>
  );
};

export default ZenCheckDepositBasicInfoStep;
