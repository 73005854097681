import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleCheck,
  faCircleDot,
  faEnvelopeCircleCheck,
  faFileCertificate,
  faFileCheck,
  faFileContract,
  faFileExcel,
  faHouseLock,
  faMessageExclamation,
  faMoneyCheckDollarPen,
  faThumbsUp,
  faTriangleExclamation,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponseCountryEnum,
} from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { renderTransactionStatusByCountry } from '../../../utils/TransactionHelper';

type TransactionStatusVariantType =
  | 'default'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'orange'
  | 'alert';

export const variantToClassNameMap: EnumMap<
  TransactionStatusVariantType,
  string
> = {
  default: 'text-grey-500',
  primary: 'text-rezen-blue-600',
  success: 'text-green-600',
  warning: 'text-yellow-600',
  danger: 'text-red-600',
  orange: 'text-orange-600',
  alert: 'text-sangria-900',
};

const mapTransactionStatusToVariant = (
  state: TransactionLifecycleStateValueStateEnum,
): TransactionStatusVariantType => {
  switch (state) {
    case TransactionLifecycleStateValueStateEnum.ApprovedForClosing:
    case TransactionLifecycleStateValueStateEnum.Settled:
    case TransactionLifecycleStateValueStateEnum.WaitingOnPayment:
    case TransactionLifecycleStateValueStateEnum.Closed:
    case TransactionLifecycleStateValueStateEnum.PaymentAccepted:
    case TransactionLifecycleStateValueStateEnum.PaymentScheduled:
    case TransactionLifecycleStateValueStateEnum.ListingActive:
      return 'success';
    case TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved:
    case TransactionLifecycleStateValueStateEnum.CommissionDocumentSent:
    case TransactionLifecycleStateValueStateEnum.CommissionValidated:
      return 'primary';
    case TransactionLifecycleStateValueStateEnum.CommissionDocumentGenerated:
      return 'orange';
    case TransactionLifecycleStateValueStateEnum.ReadyForCommissionDocumentGeneration:
    case TransactionLifecycleStateValueStateEnum.CalculateLedger:
    case TransactionLifecycleStateValueStateEnum.New:
    case TransactionLifecycleStateValueStateEnum.ListingClosed:
      return 'default';
    case TransactionLifecycleStateValueStateEnum.NeedsCommissionValidation:
    case TransactionLifecycleStateValueStateEnum.ListingInContract:
      return 'warning';
    case TransactionLifecycleStateValueStateEnum.Terminated:
      return 'danger';
    case TransactionLifecycleStateValueStateEnum.TerminationRequested:
      return 'alert';
    default:
      return 'default';
  }
};

const mapTransactionStatusToIcon = (
  state: TransactionLifecycleStateValueStateEnum,
): IconProp => {
  switch (state) {
    case TransactionLifecycleStateValueStateEnum.ApprovedForClosing:
    case TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved:
      return faThumbsUp;
    case TransactionLifecycleStateValueStateEnum.CommissionDocumentGenerated:
      return faFileCheck;
    case TransactionLifecycleStateValueStateEnum.CommissionDocumentSent:
      return faEnvelopeCircleCheck;
    case TransactionLifecycleStateValueStateEnum.ReadyForCommissionDocumentGeneration:
    case TransactionLifecycleStateValueStateEnum.CalculateLedger:
    case TransactionLifecycleStateValueStateEnum.New:
      return faTriangleExclamation;
    case TransactionLifecycleStateValueStateEnum.Settled:
    case TransactionLifecycleStateValueStateEnum.ListingClosed:
      return faCircleCheck;
    case TransactionLifecycleStateValueStateEnum.WaitingOnPayment:
    case TransactionLifecycleStateValueStateEnum.PaymentAccepted:
    case TransactionLifecycleStateValueStateEnum.PaymentScheduled:
      return faMoneyCheckDollarPen;
    case TransactionLifecycleStateValueStateEnum.NeedsCommissionValidation:
      return faFileExcel;
    case TransactionLifecycleStateValueStateEnum.Terminated:
      return faXmark;
    case TransactionLifecycleStateValueStateEnum.ListingActive:
      return faCircleDot;
    case TransactionLifecycleStateValueStateEnum.ListingInContract:
      return faFileContract;
    case TransactionLifecycleStateValueStateEnum.TerminationRequested:
      return faMessageExclamation;
    case TransactionLifecycleStateValueStateEnum.Closed:
      return faHouseLock;
    case TransactionLifecycleStateValueStateEnum.CommissionValidated:
      return faFileCertificate;
    default:
      return faTriangleExclamation;
  }
};

export interface GeminiTransactionStatusProps {
  status: TransactionLifecycleStateValueStateEnum;
  country: TransactionResponseCountryEnum;
}

const GeminiTransactionStatus: FC<GeminiTransactionStatusProps> = ({
  status,
  country,
}) => {
  const variant = mapTransactionStatusToVariant(status);

  return (
    <div
      className={cn(
        'flex items-center space-x-1.5 py-1 rounded-full font-zen-body-2 text-sm font-medium 2xl:max-w-full xl:max-w-[230px] md:max-w-[180px]',
        variantToClassNameMap[variant],
      )}
    >
      <div className='h-[22px] flex items-center justify-center'>
        <FontAwesomeIcon icon={mapTransactionStatusToIcon(status)} />
      </div>
      <span className='truncate'>
        {renderTransactionStatusByCountry(status, country)}
      </span>
    </div>
  );
};

export default GeminiTransactionStatus;
