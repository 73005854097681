import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { onBoardingStepType } from '../../types';
import { cn } from '../../utils/classUtils';

export interface VerticalStepProgressProps {
  steps: onBoardingStepType[];
  inProgressIndex: number;
}

const VerticalStepProgress: React.FC<VerticalStepProgressProps> = ({
  steps,
  inProgressIndex,
}) => (
  <ol>
    {steps.map((step, index) => {
      const isLastStep = index === steps.length - 1;
      const isStepCompleted = inProgressIndex > index;
      const isStepInProgress = inProgressIndex === index;

      return (
        <li
          key={step.name}
          className={cn('relative', {
            'pb-10': !isLastStep,
          })}
        >
          {!isLastStep && (
            <div
              className={cn(
                '-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300',
                { 'bg-primary': isStepCompleted },
              )}
            />
          )}
          <div className='grid md:grid-flow-col grid-flow-row grid-cols-1'>
            <div className='relative flex items-start group'>
              <span className='h-9 flex items-center'>
                <span
                  className={cn(
                    'relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full',
                    {
                      'bg-primary border-primary': isStepCompleted,
                      'border-primary': isStepInProgress,
                    },
                  )}
                >
                  {isStepCompleted && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className='w-5 h-5 text-white'
                    />
                  )}
                  {isStepInProgress && (
                    <span className='h-2.5 w-2.5 bg-primary rounded-full' />
                  )}
                </span>
              </span>
              <span className='ml-4 min-w-0 flex flex-col my-auto'>
                <span
                  className={cn(
                    'text-sm font-primary-medium tracking-wide uppercase text-gray-500',
                    {
                      'text-dark': isStepCompleted,
                      'text-primary': isStepInProgress,
                    },
                  )}
                >
                  {step.name}
                </span>
                {step.subtitle && (
                  <span className='text-sm text-gray-500'>{step.subtitle}</span>
                )}
              </span>
            </div>
            {step.action && (
              <span className='md:pt-0 pt-2'>
                <button
                  className='border rounded text-sm ml-12 md:ml-4 p-1 font-primary-regular items-center'
                  onClick={step.action!.onClick}
                >
                  {step.action!.buttonText}
                </button>
              </span>
            )}
          </div>
        </li>
      );
    })}
  </ol>
);

export default VerticalStepProgress;
