import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flatten, values } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import {
  MlsPropertyTypeMapping,
  MlsResponse,
  MlsSecretsResponse,
} from '../../../openapi/yenta';
import { MlsPropertyTypesWandererType, YesNoType } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { capitalizeEnum, formatPhoneNumber } from '../../../utils/StringUtils';
import GeminiAgentContactInfoDetailHeader from '../Agent/GeminiAgentContactInfoDetailHeader';
import GeminiMlsOrBoardStatusPill from '../Board/GeminiMlsOrBoardStatusPill';
import GeminiCopyToClipboard from '../GeminiCopytoClipboard';
import GeminiProfileImage from '../GeminiProfileImage';
import GeminiYesOrNoPill from '../GeminiYesOrNoPill';
import GeminiTransactionHeaderRowCell from '../Transaction/Header/GeminiTransactionHeaderRowCell';

interface GeminiMlsHeaderSectionProps {
  mls: MlsResponse;
  secrets?: MlsSecretsResponse;
  mlsPropertyTypesMappings?: MlsPropertyTypeMapping[];
  mlsPropertyTypesWanderer: MlsPropertyTypesWandererType;
}

const GeminiMlsHeaderSection: React.FC<GeminiMlsHeaderSectionProps> = ({
  mls,
  secrets,
  mlsPropertyTypesMappings,
  mlsPropertyTypesWanderer,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <header className='w-full bg-primary-navy rounded-2xl md:px-8 px-4 py-5 flex md:flex-row flex-col md:space-x-8 font-zen-body-2 text-white'>
      <section className='md:w-[16%] w-full h-full flex flex-col items-center'>
        <GeminiProfileImage
          width={174}
          imageUrl={mls.logo!}
          imageClassName='object-contain'
        />
        <div className='w-full flex justify-center mt-6'>
          <GeminiMlsOrBoardStatusPill
            status={mls?.status!}
            className='w-full'
          />
        </div>
      </section>
      <section className='md:w-[50%] w-full h-full space-y-3'>
        <article className='md:px-5 px-2 md:py-4 py-2 rounded-lg bg-regent-900 font-zen-body-2 text-white flex flex-col space-y-2.5'>
          <h2 className='font-semibold text-[20px] leading-[26px]'>
            {mls?.name}
          </h2>
          <GeminiTransactionHeaderRowCell
            label='Code:'
            value={mls?.code!}
            valueClassName='font-light'
          />
          <div className='flex flex-row flex-wrap md:items-center md:space-x-2.5'>
            <GeminiTransactionHeaderRowCell
              label='Office Code:'
              value={mls?.officeCode || 'N/A'}
              valueClassName='font-light'
            />
            <span className='h-1 w-1 bg-grey-400 rounded-full' />
            <GeminiTransactionHeaderRowCell
              label='Broker/Member ID:'
              value={mls?.brokerOrMemberId ?? 'N/A'}
              valueClassName='font-light'
            />
          </div>
          <div className='flex flex-row flex-wrap md:items-center md:space-x-2.5'>
            <GeminiTransactionHeaderRowCell
              label='Registration Fee:'
              value={
                !!mls.registrationFee
                  ? displayAmount(mls.registrationFee)
                  : 'N/A'
              }
              valueClassName='font-light'
            />
            <span className='h-1 w-1 bg-grey-400 rounded-full' />
            <GeminiTransactionHeaderRowCell
              label='Join Date:'
              value={
                !!mls.joinDate
                  ? DateTime.fromISO(mls.joinDate!).toFormat('LL/dd/yy')
                  : 'N/A'
              }
              valueClassName='font-light'
            />
          </div>
        </article>
        <article className='md:px-5 px-2 md:py-4 py-2 rounded-lg bg-regent-900 font-zen-body-2 text-white flex flex-col'>
          <div className='flex flex-row flex-wrap md:items-start md:space-x-2.5'>
            <div className='md:flex-1 w-full'>
              <GeminiTransactionHeaderRowCell
                label='Billing Type:'
                value={
                  !!mls?.billingType ? capitalizeEnum(mls?.billingType) : 'N/A'
                }
                valueClassName='font-light'
              />
            </div>
            <div className='h-4.5 w-[1px] bg-grey-200 md:block hidden rounded-lg' />

            <div className='md:flex-1 w-full'>
              <GeminiTransactionHeaderRowCell
                label='Billing Frequency:'
                value={
                  !!mls?.billingFrequency
                    ? capitalizeEnum(mls?.billingFrequency)
                    : 'N/A'
                }
                valueClassName='font-light'
              />
            </div>
          </div>

          <div className='flex flex-row flex-wrap md:items-start md:space-x-2.5 mt-3'>
            <div className='md:flex-1 w-full'>
              <GeminiTransactionHeaderRowCell
                label='Fee Amount:'
                value={
                  !!mls.recurringFee ? displayAmount(mls.recurringFee) : 'N/A'
                }
                valueClassName='font-light'
              />
            </div>
            <div className='h-4.5 w-[1px] bg-grey-200 md:block hidden rounded-lg' />

            <div className='md:flex-1 w-full'>
              <GeminiTransactionHeaderRowCell
                label='Payment Type:'
                value={
                  !!mls?.paymentType ? capitalizeEnum(mls?.paymentType) : 'N/A'
                }
                valueClassName='font-light'
              />
            </div>
          </div>
          <div className='flex flex-row flex-wrap md:items-start'>
            <div className='w-1/2 flex justify-between mr-2.5 mt-3'>
              <GeminiTransactionHeaderRowCell
                label='Next Due Date:'
                value={
                  mls?.nextDueDate
                    ? DateTime.fromISO(mls.nextDueDate!).toFormat('LL/dd/yy')
                    : 'No Date Yet'
                }
                valueClassName='font-light'
              />
              <div className='h-4.5 w-[1px] bg-grey-200 md:block hidden rounded-lg' />
            </div>
            <div className='mt-3'>
              <GeminiTransactionHeaderRowCell
                label='Unreported License Risk:'
                value={
                  !!mls?.unreportedLicenseRisk
                    ? capitalizeEnum(mls?.unreportedLicenseRisk)
                    : 'N/A'
                }
                valueClassName='font-light'
              />
            </div>
          </div>
        </article>

        <article className='md:px-5 px-2 md:py-4 py-2 rounded-lg bg-regent-900 font-zen-body-2 text-white flex flex-col'>
          <div className='flex flex-row flex-wrap items-center'>
            <div className='w-1/2 flex justify-between mr-2.5 items-center'>
              <GeminiTransactionHeaderRowCell
                label='Provisioned Property Types:'
                value={
                  <GeminiYesOrNoPill
                    value={
                      !!mlsPropertyTypesMappings?.length
                        ? YesNoType.YES
                        : YesNoType.NO
                    }
                    className='!px-0'
                    noBackground
                  />
                }
                valueClassName='font-light'
              />
              <div className='h-4.5 w-[1px] bg-grey-200 md:block hidden rounded-lg' />
            </div>
            <div>
              <GeminiTransactionHeaderRowCell
                label='Access to MLS Property Types:'
                value={
                  <GeminiYesOrNoPill
                    value={
                      !!flatten(values(mlsPropertyTypesWanderer)).length
                        ? YesNoType.YES
                        : YesNoType.NO
                    }
                    className='!px-0'
                    noBackground
                  />
                }
                valueClassName='font-light'
              />
            </div>
          </div>
          <div className='flex flex-row flex-wrap  mt-3'>
            <GeminiTransactionHeaderRowCell
              label='Access to MLS Property Types:'
              value={
                <span className='text-green-600 ml-1 font-medium font-inter text-sm'>
                  {!!mls?.idxFeedStatus
                    ? capitalizeEnum(mls.idxFeedStatus)
                    : 'N/A'}
                </span>
              }
              valueClassName='font-light'
            />
          </div>
        </article>
      </section>

      <section className='md:w-[34%] w-full h-full space-y-3'>
        <GeminiAgentContactInfoDetailHeader
          phoneNumber={mls?.phoneNumber && formatPhoneNumber(mls?.phoneNumber)}
          emailAddress={mls?.email}
          addresses={
            !!mls.address
              ? mls.address?.oneLine
              : capitalizeEnum(mls?.administrativeAreas?.[0]?.stateOrProvince!)
          }
        />
        <article className='md:px-5 px-2 md:py-4 py-2 rounded-lg bg-regent-900 font-zen-body-2 text-white flex flex-col space-y-2.5'>
          <div className='flex flex-col space-y-1.5'>
            <p className='font-inter font-medium text-sm'>Username/Email ID</p>
            <div className='flex flex-row items-center justify-between p-3 border border-regent-600 rounded-[5px]'>
              <span className='font-light text-sm leading-4'>
                {secrets?.username ?? 'N/A'}
              </span>
              <GeminiCopyToClipboard
                value={secrets?.username ?? ''}
                className='text-white'
                label=''
              />
            </div>
          </div>
          <div className='flex flex-col space-y-1.5'>
            <p className='font-inter font-medium text-sm'>Password</p>
            <div className='flex flex-row items-center justify-between py-2 px-3 border border-regent-600 rounded-[5px]'>
              <span className='font-light text-sm leading-4'>
                {showPassword
                  ? secrets?.password ?? 'N/A'
                  : Array(secrets?.password?.length).fill('*').join('')}
              </span>
              <div className='flex items-center space-x-4'>
                <button onClick={() => setShowPassword((prev) => !prev)}>
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className='text-sm'
                  />
                </button>
                <GeminiCopyToClipboard
                  value={secrets?.password ?? ''}
                  className='text-white'
                  label=''
                />
              </div>
            </div>
          </div>
        </article>
      </section>
    </header>
  );
};

export default GeminiMlsHeaderSection;
