import { faCamera } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';
import { useRef } from 'react';
import {
  Controller,
  FieldErrors,
  FieldValues,
  UseControllerOptions,
} from 'react-hook-form-v6';
import { useDispatch } from 'react-redux';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { FormFieldTooltipIndexProps } from '../types';
import { isImageValid } from '../utils/AgentHelper';
import FormErrorMessage from './FormErrorMessage';

interface ControlledImageUploadProps<
  TFieldValues extends FieldValues = FieldValues
> extends UseControllerOptions<TFieldValues>,
    FormFieldTooltipIndexProps {
  errors: FieldErrors<TFieldValues>;
  label?: string;
  uploadText?: string;
  isRequired?: boolean;
}

const ControlledImageUpload: React.FC<ControlledImageUploadProps> = ({
  errors,
  label,
  name,
  rules,
  control,
  uploadText = 'Upload Photo',
  isRequired = false,
  ...rest
}) => {
  const error = get(errors, name);
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  return (
    <div>
      <Controller
        id={name}
        control={control}
        name={name}
        rules={rules}
        {...rest}
        render={({ name, value, onChange, onBlur }) => (
          <div>
            <div className='space-y-1'>
              <div className='flex flex-row items-center justify-between'>
                {!!label && (
                  <label className='inline-block' htmlFor={name}>
                    <span>{label}</span>
                    {isRequired && <span className='text-error'>*</span>}
                  </label>
                )}
                <div
                  onClick={() => ref.current?.click()}
                  className='flex flex-row items-center text-primary cursor-pointer space-x-1'
                >
                  <FontAwesomeIcon
                    icon={faCamera}
                    className='text-primary'
                    size='lg'
                  />
                  <span className='font-primary-medium text-base'>
                    {uploadText}
                  </span>
                </div>
              </div>
              <div className='flex flex-col space-y-4'>
                {value && (
                  <div className='border border-dashed p-2'>
                    <img
                      className='object-cover w-1/2 h-auto mx-auto'
                      src={
                        typeof value === 'object'
                          ? URL.createObjectURL(value)
                          : value
                      }
                      alt='avatar'
                    />
                  </div>
                )}
              </div>
              <input
                type='file'
                className='hidden'
                ref={ref}
                onBlur={onBlur}
                aria-label='uploadAvatar'
                onChange={(e) => {
                  if (isImageValid(e)) {
                    onChange(e.target!.files![0]);
                  } else {
                    dispatch(
                      showErrorToast(
                        'File size exceeds maximum limit of 10 MB.',
                      ),
                    );
                  }
                }}
              />
            </div>
          </div>
        )}
      />
      {error && <FormErrorMessage message={error.message} />}
    </div>
  );
};

export default ControlledImageUpload;
