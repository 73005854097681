import { DateTime } from 'luxon';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import {
  CreateNoteRequest,
  CreateNoteRequestAllowableRoleEnum,
  CreateNoteRequestEntityTypeEnum,
  NoteControllerApi,
  NoteResponse,
  UpdateNoteRequestAllowableRoleEnum,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { RootState } from '../../../types';
import { getYentaImageUrl } from '../../../utils/ImgUtils';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import Button from '../../Button';
import NoteItem from '../../NoteItem';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';

export interface ZenNotesContainerProps {
  entityId: string;
  entityType: CreateNoteRequestEntityTypeEnum;
  readOnly?: boolean;
}

interface CalendarIconProps {
  value?: string;
  onClick?(): void;
}

interface FormData {
  comment: string;
  followupDate?: string;
}

const CalendarIconInput = forwardRef<HTMLInputElement, CalendarIconProps>(
  ({ value, onClick }, ref) => (
    <div
      onClick={onClick}
      ref={ref}
      className='px-1 space-x-2 rounded-lg cursor-pointer border-2 border-primary-blue'
    >
      {value ? (
        <p className='mr-8 mt-1 text-primary-blue'>{value}</p>
      ) : (
        <div className='px-3 space-x-2 text-primary-blue flex flex-row items-center whitespace-nowrap'>
          <p>Follow up</p>
          <FontAwesomeIcon
            aria-label='calendar'
            className='text-primary-blue mb-0.5'
            icon={faCalendar}
          />
        </div>
      )}
    </div>
  ),
);

const ZenNotesContainer: React.FC<ZenNotesContainerProps> = ({
  entityId,
  entityType,
  readOnly = false,
}) => {
  const dispatch = useDispatch();
  const [notes, setNotes] = useState<NoteResponse[]>([]);
  const { control, handleSubmit, reset } = useForm<FormData>();
  const { isAdmin } = useSelector((state: RootState) => state.auth);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await new NoteControllerApi(
        getYentaConfiguration(),
      ).getNoteById1(entityType, entityId);

      setNotes(data.notes?.sort((a, b) => b?.createdAt! - a?.createdAt!)!);
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to fetch notes', e);
    }
  }, [entityId, entityType, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSubmit = async (values: FormData) => {
    try {
      const createNoteRequest: CreateNoteRequest = {
        comment: values.comment,
        entityId: entityId,
        followupDate: values.followupDate
          ? DateTime.fromISO(values.followupDate).toMillis()
          : undefined,
        entityType: entityType,
        allowableRole: isAdmin
          ? CreateNoteRequestAllowableRoleEnum.Admin
          : CreateNoteRequestAllowableRoleEnum.Leader,
      };

      const { data } = await new NoteControllerApi(
        getYentaConfiguration(),
      ).createNote(createNoteRequest);

      setNotes([data, ...notes]);
      reset({ comment: '', followupDate: '' });
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notifyIgnoreAuthErrors('Unable to create note', e);
    }
  };

  const updateFollowUpdate = async (note: NoteResponse) => {
    try {
      const { data } = await new NoteControllerApi(
        getYentaConfiguration(),
      ).updateNote(note.id!, {
        followupDate: undefined,
        comment: note.comment!,
        allowableRole: (note.allowableRole! as unknown) as UpdateNoteRequestAllowableRoleEnum,
      });

      setNotes(notes.map((note) => (note.id === data.id ? data : note)));
    } catch (e) {
      showApiErrorModal(e);
      ErrorService.notifyIgnoreAuthErrors('Unable to update follow up date', e);
    }
  };

  return (
    <div className='px-4 py-5'>
      <ul>
        {notes.map((note, index) => {
          return (
            <li key={note.id}>
              <NoteItem
                name={note.user?.fullName!}
                comment={note.comment!}
                followUpDate={{
                  date: note.followupDate!,
                  onClick: () => updateFollowUpdate(note),
                }}
                createdAt={note.createdAt!}
                avatar={getYentaImageUrl(note.user?.avatar)}
                isLastItem={notes.length - 1 === index}
              />
            </li>
          );
        })}
      </ul>
      {!readOnly && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex-col md:flex-row flex p-1 mt-10 focus:outline-none items-start md:items-center border border-gray-800 rounded-md related'>
            <ZenControlledTextInput<FormData, 'comment'>
              name='comment'
              placeholder='Add a note here...'
              control={control}
              readOnly={readOnly}
              rules={{ required: 'Note is required' }}
              noBorder
            />
            <div className='flex items-center'>
              <ZenControlledDatePickerInput
                customInput={<CalendarIconInput />}
                noBorder
                name='followupDate'
                control={control}
                datePickerConfig={{
                  isClearable: true,
                  minDate: DateTime.local().plus({ days: 1 }).toJSDate(),
                  popperModifiers: {},
                  popperClassName: 'z-50',
                }}
              />
              <Button
                label='Save'
                type='secondary'
                buttonType='submit'
                onClick={handleSubmit(onSubmit)}
                className='!mx-3 flex !py-0.5 items-center space-x-2 border-2 rounded-lg !px-8 font-zen-title font-medium disabled:bg-zen-dark-4 disabled:text-white disabled:border-zen-dark-4 justify-center border-primary-blue bg-primary-blue !text-white'
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default ZenNotesContainer;
