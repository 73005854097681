import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownToSquare,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import RealLogo from '../assets/img/new-rezen-black-logo.svg';
import { useLogout } from '../hooks/useLogout';
import { ButtonType } from '../types';
import AgentWebsiteConfirmationModal, {
  ModalVariantType,
} from './agentWebsiteOnboarding/AgentWebsiteConfirmationModal';
import Button from './Button';
import IconButton from './IconButton';

export interface AgentOnboardingLayoutProps {
  variant?: ModalVariantType;
  title: string;
  hideLogout?: boolean;
  hideClose?: boolean;
  onClose?(): void;
  modalTitle?: string;
  modalSubtitle?: string;
  modalSubmitText?: string;
  modalCancelText?: string;
  hideCancelButton?: boolean;
  RightHeaderComponent?: React.ReactNode;
}

const AgentOnboardingLayout: React.FC<AgentOnboardingLayoutProps> = ({
  variant = 'cancel',
  title,
  hideLogout = false,
  hideClose = false,
  hideCancelButton = false,
  onClose,
  children,
  modalTitle = 'Close and discard all edits?',
  modalSubtitle = "Changes won't be saved and you'll go back to the start.",
  modalSubmitText = 'Yes',
  modalCancelText = 'No',
  RightHeaderComponent,
}) => {
  const logout = useLogout();
  const [cancel, setCancel] = useState<boolean>(false);
  const history = useHistory();

  const modalVariantMapToButtonType: {
    [key in ModalVariantType]: ButtonType;
  } = {
    cancel: 'danger',
    error: 'danger',
    info: 'primary',
    success: 'primary',
    upload: 'secondary',
    warning: 'outline',
  };

  return (
    <div className='fixed top-0 right-0 bottom-0 left-0 w-full h-full bg-white scrollbar overflow-auto z-10'>
      <div className='flex flex-col w-full h-full'>
        <header className='p-4 border-b border-coolGray-300 flex items-center justify-between'>
          <div className='flex items-center'>
            <img src={RealLogo} className='h-7' alt='Logo' />
            <div className='h-7 border-l border-coolGray-300 mx-6' />
            <h1 className='font-primary-medium text-xl text-dark'>{title}</h1>
          </div>
          {RightHeaderComponent}
          {!hideClose && (
            <IconButton
              label='Close'
              variant='outline'
              leftIcon={
                <FontAwesomeIcon
                  icon={faXmark}
                  size='lg'
                  className='mr-0.5 mb-0.5'
                />
              }
              buttonType='button'
              onClick={() => setCancel(true)}
            />
          )}
        </header>
        <div className='flex-grow flex flex-col'>
          <div className='h-full'>{children}</div>
        </div>
        {!hideLogout && (
          <div className='p-4 lg:p-6'>
            <IconButton
              label='Logout'
              variant='outline'
              leftIcon={
                <FontAwesomeIcon
                  icon={faArrowDownToSquare}
                  className='-rotate-90 mr-1 text-lg'
                />
              }
              buttonType='button'
              onClick={async () => {
                await logout();
                history.push('/');
              }}
            />
          </div>
        )}
      </div>
      <AgentWebsiteConfirmationModal
        variant={variant}
        title={modalTitle}
        subtitle={modalSubtitle}
        isOpen={cancel}
        onClose={() => setCancel(false)}
      >
        <div className='flex flex-row justify-between space-x-5 mt-10'>
          {!hideCancelButton && (
            <Button
              label={modalCancelText}
              type='outline'
              size='lg'
              className='text-opacity-40'
              fullWidth
              onClick={() => setCancel(false)}
            />
          )}
          <Button
            label={modalSubmitText}
            size='lg'
            type={modalVariantMapToButtonType[variant]}
            buttonType='submit'
            fullWidth
            onClick={onClose}
          />
        </div>
      </AgentWebsiteConfirmationModal>
    </div>
  );
};

export default AgentOnboardingLayout;
