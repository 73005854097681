import { debounce } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
  DirectoryEntryResponseRoleEnum,
  DirectoryEntryResponseTypeEnum,
  DirectoryPersonCreateRequestRoleEnum,
  DirectoryPersonUpdateRequest,
  DirectoryVendorCreateRequest,
  DirectoryVendorUpdateRequest,
  NationalBusinessIdentificationTypeEnum,
} from '../../openapi/yenta/api';
import {
  DirectoryCommonEntityResponse,
  SUCCESS_CREATE_PRIMARY_MESSAGE,
  SUCCESS_CREATE_SECONDARY_MESSAGE,
  SUCCESS_UPDATE_MESSAGE,
  useCreatePerson,
  useCreateVendor,
  useDirectoryVendorW9Url,
  useFileUpdate,
  useLinkPersontoVendor,
  useUpdatePerson,
  useUpdateVendor,
} from '../../query/directory/useDirectory';
import AnalyticsService from '../../services/AnalyticsService';
import { showSuccessToast } from '../../slices/ToastNotificationSlice';
import { AnalyticsEventEnum, RootState } from '../../types';
import { isCanadianUser } from '../../utils/AgentHelper';
import AnalyticsEventOnLoad from '../Analytics/AnalyticsEventOnLoad';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledToggleInput from '../Zen/Input/ZenControlledToggleInput';
import ZenButton from '../Zen/ZenButton';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import {
  CreateDirectoryRequestFormData,
  getDirectoryVendorRoleLabel,
  getDirectoryVendorRolesByUserCountry,
  getPersonCreateData,
  getVendorCreateData,
  updatePersonRequest,
  updateVendorCreateData,
} from './DirectoryUtils';
import ZenBusinessInformation from './ZenBusinessInformationForm';
import ZenPersonInformationForm from './ZenPersonInformationForm';
import { isInEnum } from './utils';

interface ZenRoleDirectoryFormProps {
  isOpen: boolean;
  onClose(): void;
  mode?: 'add' | 'edit' | 'populate';
  existingVendorOrPerson?: DirectoryCommonEntityResponse;
  existingType?: DirectoryEntryResponseTypeEnum;
  isExternal?: boolean;
}

const ZenRoleDirectoryForm: React.FC<ZenRoleDirectoryFormProps> = ({
  isOpen,
  onClose,
  mode = 'add',
  existingVendorOrPerson,
  existingType = DirectoryEntryResponseTypeEnum.Vendor,
  isExternal,
}) => {
  const dispatch = useDispatch();

  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [isPersonAddressManual, setIsPersonAddressManual] = useState<boolean>(
    false,
  );
  const [
    isBusinessAddressManual,
    setIsBusinessAddressManual,
  ] = useState<boolean>(false);

  const roleOptionsByCountry = getDirectoryVendorRolesByUserCountry(
    userDetail?.accountCountry!,
  );
  const roleOptionsExcludeClient = roleOptionsByCountry.filter(
    ({ value }) => value !== 'CLIENT',
  );

  const {
    mutate: createPersonMutation,
    isLoading: createPersonLoading,
  } = useCreatePerson();

  const {
    mutate: createVendorMutation,
    isLoading: createVendorLoading,
  } = useCreateVendor();

  const { mutate: fileUpdate, isLoading: updateFileLoading } = useFileUpdate(
    existingVendorOrPerson?.id ?? '',
  );

  const {
    mutate: updateVendor,
    isLoading: updateVendorLoading,
  } = useUpdateVendor(existingVendorOrPerson?.id ?? '');
  const {
    mutate: updatePerson,
    isLoading: updatePersonLoading,
  } = useUpdatePerson(existingVendorOrPerson?.id ?? '');
  const { mutate: linkPerson } = useLinkPersontoVendor(
    existingVendorOrPerson?.id ?? '',
  );
  const { data: signedUrl } = useDirectoryVendorW9Url(
    existingVendorOrPerson?.id ?? '',
    existingVendorOrPerson?.hasW9,
  );

  const isLoading =
    createPersonLoading ||
    createVendorLoading ||
    updateVendorLoading ||
    updatePersonLoading ||
    updateFileLoading;
  const isEditMode = mode === 'edit';
  const isPopulateMode = mode === 'populate';
  const isAddMode = mode === 'add';
  const isExistingTypeVendor =
    existingType === DirectoryEntryResponseTypeEnum.Vendor;
  const isEditingExistingVendor =
    (isEditMode || isPopulateMode) && isExistingTypeVendor;
  // show the full form if the user is adding a new contact
  // or NOT editing an existing vendor contact
  // or if is used as an integration for external agents
  const shouldShowFullForm = isExternal || !isEditingExistingVendor;

  const methods = useForm<CreateDirectoryRequestFormData>({
    reValidateMode: 'onBlur',
    defaultValues: {
      participantRole:
        (isPopulateMode || isEditMode) && existingVendorOrPerson
          ? {
              label: getDirectoryVendorRoleLabel(existingVendorOrPerson?.role),
              value: existingVendorOrPerson?.role,
            }
          : undefined,
      firstName:
        isPopulateMode || isEditMode
          ? existingVendorOrPerson?.firstName ??
            existingVendorOrPerson?.linkedPersons?.[0]?.firstName ??
            ''
          : '',
      lastName:
        isPopulateMode || isEditMode
          ? existingVendorOrPerson?.lastName ??
            existingVendorOrPerson?.linkedPersons?.[0]?.lastName ??
            ''
          : '',
      company: existingVendorOrPerson?.name,
      primaryEmailAddress: existingVendorOrPerson?.emailAddress,
      secondaryEmailAddress: existingVendorOrPerson?.secondaryEmailAddress,
      businessPhoneNumber: existingVendorOrPerson?.phoneNumber,
      emailAddress:
        isPopulateMode || isEditMode
          ? existingVendorOrPerson?.emailAddress ??
            existingVendorOrPerson?.linkedPersons?.[0]?.emailAddress ??
            ''
          : '',
      phoneNumber:
        isPopulateMode || isEditMode
          ? existingVendorOrPerson?.phoneNumber ??
            existingVendorOrPerson?.linkedPersons?.[0]?.phoneNumber ??
            ''
          : '',
      ein:
        isPopulateMode || isEditMode
          ? existingVendorOrPerson?.nationalBusinessIdentifications?.find(
              (ein) => ein.type === NationalBusinessIdentificationTypeEnum.Ein,
            )?.nationalId
          : '',
      autoComplete: isPopulateMode
        ? {
            formatted_address: existingVendorOrPerson?.address?.oneLine,
            address_components: existingVendorOrPerson?.addressComponents,
            place_id: existingVendorOrPerson?.placeId, // from using useGeocodeAddress hook
          }
        : isEditMode
        ? {
            formatted_address: existingVendorOrPerson?.address?.oneLine,
            address_components: [
              {
                long_name:
                  existingVendorOrPerson?.address?.streetAddress1! ?? '',
                short_name:
                  existingVendorOrPerson?.address?.streetAddress1! ?? '',
                types: ['route'],
              },
              {
                long_name:
                  existingVendorOrPerson?.address?.streetAddress2! ?? '',
                short_name:
                  existingVendorOrPerson?.address?.streetAddress2! ?? '',
                types: ['neighborhood'],
              },
              {
                long_name: existingVendorOrPerson?.address?.city! ?? '',
                short_name: existingVendorOrPerson?.address?.city! ?? '',
                types: ['locality'],
              },
              {
                long_name:
                  existingVendorOrPerson?.address?.stateOrProvince! ?? '',
                short_name:
                  existingVendorOrPerson?.address?.stateOrProvince! ?? '',
                types: ['administrative_area_level_1'],
              },
              {
                long_name: existingVendorOrPerson?.address?.country! ?? '',
                short_name: existingVendorOrPerson?.address?.country! ?? '',
                types: ['country'],
              },
              {
                long_name:
                  existingVendorOrPerson?.address?.zipOrPostalCode! ?? '',
                short_name:
                  existingVendorOrPerson?.address?.zipOrPostalCode! ?? '',
                types: ['postal_code'],
              },
            ],
          }
        : undefined,
      autoCompletebusinessAddress: isPopulateMode
        ? {
            formatted_address: existingVendorOrPerson?.address?.oneLine,
            address_components: existingVendorOrPerson?.addressComponents,
            place_id: existingVendorOrPerson?.placeId, // from using useGeocodeAddress hook
          }
        : isEditMode
        ? {
            formatted_address: existingVendorOrPerson?.address?.oneLine,
            address_components: [
              {
                long_name:
                  existingVendorOrPerson?.address?.streetAddress1! ?? '',
                short_name:
                  existingVendorOrPerson?.address?.streetAddress1! ?? '',
                types: ['route'],
              },
              {
                long_name:
                  existingVendorOrPerson?.address?.streetAddress2! ?? '',
                short_name:
                  existingVendorOrPerson?.address?.streetAddress2! ?? '',
                types: ['neighborhood'],
              },
              {
                long_name: existingVendorOrPerson?.address?.city! ?? '',
                short_name: existingVendorOrPerson?.address?.city! ?? '',
                types: ['locality'],
              },
              {
                long_name:
                  existingVendorOrPerson?.address?.stateOrProvince! ?? '',
                short_name:
                  existingVendorOrPerson?.address?.stateOrProvince! ?? '',
                types: ['administrative_area_level_1'],
              },
              {
                long_name: existingVendorOrPerson?.address?.country! ?? '',
                short_name: existingVendorOrPerson?.address?.country! ?? '',
                types: ['country'],
              },
              {
                long_name:
                  existingVendorOrPerson?.address?.zipOrPostalCode! ?? '',
                short_name:
                  existingVendorOrPerson?.address?.zipOrPostalCode! ?? '',
                types: ['postal_code'],
              },
            ],
          }
        : undefined,
      gstId:
        isPopulateMode || isEditMode
          ? existingVendorOrPerson?.nationalBusinessIdentifications?.find(
              (gstId) =>
                gstId.type === NationalBusinessIdentificationTypeEnum.GstId,
            )?.nationalId
          : '',
      bn:
        isPopulateMode || isEditMode
          ? existingVendorOrPerson?.nationalBusinessIdentifications?.find(
              (bn) => bn.type === NationalBusinessIdentificationTypeEnum.Bn,
            )?.nationalId
          : '',
      hstId:
        isPopulateMode || isEditMode
          ? existingVendorOrPerson?.nationalBusinessIdentifications?.find(
              (hstId) =>
                hstId.type === NationalBusinessIdentificationTypeEnum.HstId,
            )?.nationalId
          : '',
      qstId:
        isPopulateMode || isEditMode
          ? existingVendorOrPerson?.nationalBusinessIdentifications?.find(
              (qstId) =>
                qstId.type === NationalBusinessIdentificationTypeEnum.QstId,
            )?.nationalId
          : '',
      istoggle: isExternal ?? false,
      street: existingVendorOrPerson?.address?.streetAddress1,
      street2: existingVendorOrPerson?.address?.streetAddress2,
      city: existingVendorOrPerson?.address?.city,
      state: (existingVendorOrPerson?.address
        ?.stateOrProvince! as unknown) as AddressRequestStateOrProvinceEnum,
      country: existingVendorOrPerson?.address?.country,
      zip: existingVendorOrPerson?.address?.zipOrPostalCode,
      businessStreet: existingVendorOrPerson?.address?.streetAddress1,
      businessStreet2: existingVendorOrPerson?.address?.streetAddress2,
      businessCity: existingVendorOrPerson?.address?.city,
      businessState: (existingVendorOrPerson?.address
        ?.stateOrProvince! as unknown) as AddressRequestStateOrProvinceEnum,
      businessZip: existingVendorOrPerson?.address?.zipOrPostalCode,
      businessCountry: (existingVendorOrPerson?.address
        ?.country as unknown) as AddressRequestCountryEnum,
      w9form:
        isPopulateMode || isEditMode ? existingVendorOrPerson?.w9form : [],
    },
  });

  const [participantRole, istoggle, w9form] = methods.watch([
    'participantRole',
    'istoggle',
    'w9form',
  ]);

  const clientRole =
    participantRole?.value === DirectoryEntryResponseRoleEnum.Client;
  const isExternalAgentRole =
    participantRole?.value === DirectoryEntryResponseRoleEnum.OtherAgent;
  const isPopulateExternalAgent = isExternalAgentRole && isPopulateMode;

  const canadianUser = isCanadianUser(userDetail?.accountCountry!);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string, eventData?: Record<string, unknown>) => {
      AnalyticsService.instance().logEvent(event, eventData);
    }, 500),
    [],
  );

  const onSubmit = async (formData: CreateDirectoryRequestFormData) => {
    // ensure that the role is selected before submitting the form
    // This typically does not happen, but just in case...
    if (
      !isInEnum(DirectoryPersonCreateRequestRoleEnum, participantRole.value)
    ) {
      methods.setError('participantRole', {
        message: 'Please select a role',
      });
      return;
    }
    const personCreateData = getPersonCreateData(
      formData,
      isPersonAddressManual,
    );
    // handle when in populate mode if the existing contact is a vendor,
    // and is NOT external from choosing an external participant
    // Used mainly in step 3 of create listing/transaction flow when createParticipant is "Company"
    if (
      isPopulateMode &&
      existingType === DirectoryEntryResponseTypeEnum.Vendor &&
      !isExternal
    ) {
      const vendorData: DirectoryVendorCreateRequest = getVendorCreateData(
        formData,
        isBusinessAddressManual,
      );
      createVendorMutation(vendorData, {
        onSuccess: (data) => {
          dispatch(
            showSuccessToast(
              SUCCESS_CREATE_PRIMARY_MESSAGE,
              SUCCESS_CREATE_SECONDARY_MESSAGE,
            ),
          );
          if (!canadianUser && w9form?.length) {
            fileUpdate({
              vendorId: data.id,
              w9: formData?.w9form?.[0]! as File,
            });
          }
          onClose();
        },
      });
      return;
    }
    // 1. handle when in add mode - this happens in `/directory` page
    // 2. handle when in populate mode and the existing contact is a person or
    // if `isExternal`, external participant, we want to show them the full form
    if (
      isAddMode ||
      (isPopulateMode &&
        (existingType === DirectoryEntryResponseTypeEnum.Person || isExternal))
    ) {
      // If the person is associated with a business, create a vendor
      // when `isExternal` is true, `istoggle` is always true
      if (istoggle) {
        const vendorData: DirectoryVendorCreateRequest = getVendorCreateData(
          formData,
          isBusinessAddressManual,
          personCreateData,
        );
        createVendorMutation(vendorData, {
          onSuccess: (data) => {
            dispatch(
              showSuccessToast(
                SUCCESS_CREATE_PRIMARY_MESSAGE,
                SUCCESS_CREATE_SECONDARY_MESSAGE,
              ),
            );
            if (!canadianUser && w9form?.length) {
              fileUpdate({
                vendorId: data.id,
                w9: formData?.w9form?.[0]! as File,
              });
            }
            onClose();
          },
        });
        return;
      }
      createPersonMutation(personCreateData, {
        onSuccess: () => {
          dispatch(
            showSuccessToast(
              SUCCESS_CREATE_PRIMARY_MESSAGE,
              SUCCESS_CREATE_SECONDARY_MESSAGE,
            ),
          );
          onClose();
        },
      });
      return;
    }

    // 3. handle when in edit mode - this happens in `/directory` page
    if (isEditMode && existingVendorOrPerson && existingVendorOrPerson.id) {
      // Only updating person information
      if (existingType === DirectoryEntryResponseTypeEnum.Person && !istoggle) {
        const personRequest: DirectoryPersonUpdateRequest = updatePersonRequest(
          formData,
          isPersonAddressManual,
        );
        updatePerson(
          {
            id: existingVendorOrPerson.id,
            personRequest,
          },
          {
            onSuccess: () => {
              dispatch(showSuccessToast(SUCCESS_UPDATE_MESSAGE));
              onClose();
            },
          },
        );
        return;
      }
      // Only updating vendor information
      if (existingType === DirectoryEntryResponseTypeEnum.Vendor) {
        const vendorRequest: DirectoryVendorUpdateRequest = updateVendorCreateData(
          formData,
          isBusinessAddressManual,
        );
        updateVendor(
          {
            id: existingVendorOrPerson.id,
            vendorRequest,
          },
          {
            onSuccess: () => {
              showSuccessToast(SUCCESS_UPDATE_MESSAGE);
              if (!canadianUser && w9form?.length) {
                fileUpdate({
                  w9: formData?.w9form?.[0]! as File,
                });
              }
              onClose();
            },
          },
        );
        return;
      }
      // Updating both person and vendor information and links them
      // Handles w9 form upload for non-canadian users
      // NOTE: For person associated with a business (i.e., linked),
      // we do not show the full form in edit mode, and would execute the above conditions instead.
      if (
        existingType === DirectoryEntryResponseTypeEnum.Person &&
        istoggle &&
        !existingVendorOrPerson?.linkedVendor
      ) {
        updatePerson(
          {
            id: existingVendorOrPerson.id,
            personRequest: updatePersonRequest(formData, isPersonAddressManual),
          },
          {
            onSuccess: () => {
              createVendorMutation(
                getVendorCreateData(formData, isBusinessAddressManual),
                {
                  onSuccess: (vendorData) => {
                    showSuccessToast(SUCCESS_UPDATE_MESSAGE);
                    // Here, vendorData should contain the information about the newly created vendor
                    // Now, link this new vendor with the person
                    linkPerson({
                      id: existingVendorOrPerson.id!,
                      personRequest: {
                        vendorId: vendorData.id!,
                      },
                    });
                    if (!canadianUser && w9form?.length) {
                      fileUpdate({
                        vendorId: vendorData.id,
                        w9: formData?.w9form?.[0]! as File,
                      });
                    }
                    onClose();
                  },
                },
              );
            },
          },
        );
      }
    }
  };

  // sync the toggle state with the role state in Add Mode
  useEffect(() => {
    if (
      isAddMode &&
      participantRole?.value === DirectoryEntryResponseRoleEnum.OtherAgent
    ) {
      methods.setValue('istoggle', true);
      return;
    }

    if (
      isAddMode &&
      participantRole?.value !== DirectoryEntryResponseRoleEnum.OtherAgent
    ) {
      methods.setValue('istoggle', false);
    }
  }, [isAddMode, methods, participantRole?.value]);

  return (
    <ZenSidebarModal
      title={isEditMode ? 'Edit Contact' : 'Add Contact'}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        if (!isEditMode) {
          logAnalyticsEvent(AnalyticsEventEnum.DIRECTORY_CONTACT_CLICK_X);
        }
      }}
    >
      <FormProvider {...methods}>
        <form
          className='flex flex-col justify-between min-h-full'
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className='mt-4 p-5'>
            <ZenControlledSelectInput<
              CreateDirectoryRequestFormData,
              'participantRole'
            >
              name='participantRole'
              control={methods.control}
              label='Role'
              placeholder='Select Role'
              rules={{ required: 'Please select a role' }}
              options={
                isEditingExistingVendor
                  ? roleOptionsExcludeClient
                  : roleOptionsByCountry
              }
              isRequired
              disabled={isEditMode || isExternal}
              onChangeSpy={(value) => {
                if (value) {
                  logAnalyticsEvent(
                    AnalyticsEventEnum.DIRECTORY_CONTACT_SELECT_ROLE,
                    { role: value.label },
                  );
                }
              }}
            />
            {clientRole && (
              <ZenPersonInformationForm
                isAddressManual={isPersonAddressManual}
                role={participantRole.value}
                toggleAddressMode={() =>
                  setIsPersonAddressManual((current) => !current)
                }
              />
            )}
            {/*show the rest of the form if the role is selected*/}
            {participantRole && !clientRole && shouldShowFullForm && (
              <div>
                <ZenPersonInformationForm
                  isAddressManual={isPersonAddressManual}
                  role={participantRole.value}
                  toggleAddressMode={() =>
                    setIsPersonAddressManual((current) => !current)
                  }
                />
                {!clientRole && isEmpty(existingVendorOrPerson?.linkedVendor) && (
                  <div className='flex justify-between mt-5'>
                    <label
                      htmlFor='name'
                      className='text-base font-zen-body font-semibold text-zen-dark-9 mt-1'
                    >
                      Is this person associated with a business?
                    </label>
                    <div className='flex items-center w-min'>
                      <ZenControlledToggleInput<
                        CreateDirectoryRequestFormData,
                        'istoggle'
                      >
                        name='istoggle'
                        label=''
                        control={methods.control}
                        disabled={isExternalAgentRole}
                        shouldUnregister={false}
                        onChangeSpy={(value: boolean) => {
                          logAnalyticsEvent(
                            value
                              ? AnalyticsEventEnum.DIRECTORY_CONTACT_ASSOCIATED_W_BUSINESS_YES
                              : AnalyticsEventEnum.DIRECTORY_CONTACT_ASSOCIATED_W_BUSINESS_NO,
                          );
                        }}
                      />
                      <label
                        htmlFor='istoggle'
                        className='text-sm font-zen-body font-medium text-zen-dark-9'
                        data-testid='person-belong-to-business-label'
                      >
                        {istoggle ? 'Yes' : 'No'}
                      </label>
                    </div>
                  </div>
                )}
                {(istoggle || isPopulateExternalAgent) && (
                  <ZenBusinessInformation
                    w9Url={signedUrl}
                    isAddressManual={isBusinessAddressManual}
                    toggleAddressMode={() =>
                      setIsBusinessAddressManual((current) => !current)
                    }
                  />
                )}
              </div>
            )}
            {/** Need `!isExternal` here to prevent business form to duplicating */}
            {!isExternal && isEditingExistingVendor && !clientRole && (
              <ZenBusinessInformation
                w9Url={signedUrl}
                isAddressManual={isBusinessAddressManual}
                toggleAddressMode={() =>
                  setIsBusinessAddressManual((current) => !current)
                }
              />
            )}
          </div>
          <div className='p-4 bg-white border-t border-gray-200 sticky bottom-0 space-x-5'>
            <div className='flex flex-row justify-end items-center space-x-3'>
              <ZenButton
                type='button'
                onClick={() => {
                  onClose();
                  logAnalyticsEvent(
                    AnalyticsEventEnum.DIRECTORY_CONTACT_CLICK_CANCEL,
                  );
                }}
                label='Cancel'
                variant='primary-outline'
                buttonProps={{ style: { width: '150px' } }}
              />
              <ZenButton
                type='submit'
                label='Save'
                variant='primary'
                isDisabled={methods.formState.isSubmitting || isLoading}
                isSubmitting={methods.formState.isSubmitting || isLoading}
                buttonProps={{ style: { width: '150px' } }}
                onClick={() =>
                  logAnalyticsEvent(
                    AnalyticsEventEnum.DIRECTORY_CONTACT_CLICK_SAVE,
                  )
                }
              />
            </div>
          </div>
        </form>
      </FormProvider>
      <AnalyticsEventOnLoad
        eventName={AnalyticsEventEnum.DIRECTORY_ADD_CONTACT_OPENED}
      />
    </ZenSidebarModal>
  );
};

export default ZenRoleDirectoryForm;
