import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ZenResourceLinkCardProps {
  title: string;
  imageSource?: string;
  icon?: IconProp;
  iconSize?: SizeProp;
  href?: string;
  onClick?(): void;
}

const ZenResourceLinkCard: React.FC<ZenResourceLinkCardProps> = ({
  title,
  href,
  imageSource,
  icon,
  iconSize = '3x',
  onClick,
}) => {
  const handleOnClick = () => {
    if (href) {
      window.open(href, '_blank');
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className='w-full h-44 border border-zen-dark-4 bg-white rounded-lg hover:border-primary-blue cursor-pointer max-w-sm'
      onClick={handleOnClick}
    >
      <div className='flex flex-col justify-center items-center h-full p-5'>
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            className='text-primary-blue'
            size={iconSize}
          />
        )}
        {imageSource && (
          <img src={imageSource} className='w-9 h-9' alt={title} />
        )}
        <p className='font-zen-body text-sm font-semibold whitespace-normal text-center mt-5'>
          {title}
        </p>
      </div>
    </div>
  );
};

export default ZenResourceLinkCard;
