import React from 'react';
import { FilterProps } from 'react-table';
import { AsyncSelectOption, FilterColumnsToProcess } from '../../../../types';
import { PROCESS_FILTER_COLUMN } from '../../../../utils/TableUtils';
import ControlledAsyncMultiSelectInputV7 from '../../../ControlledAsyncMultiSelectInputV7';

export interface AsyncMultiSelectColumnFilterProps<D extends object>
  extends FilterProps<D> {
  fetchData(search: string, page?: number): Promise<Array<AsyncSelectOption>>;
}

const AsyncMultiSelectColumnFilter = <D extends object>({
  column,
  control,
  fetchData,
}: AsyncMultiSelectColumnFilterProps<D>): React.ReactElement => {
  const prefixFieldName = `${PROCESS_FILTER_COLUMN}.${FilterColumnsToProcess.MULTI_ENUM}.${column.id}`;

  return (
    <ControlledAsyncMultiSelectInputV7
      control={control}
      fetchData={fetchData}
      name={prefixFieldName}
      shouldUnregister={false}
      rules={{
        validate: (value) =>
          !value || !value.length
            ? `${column.render('Header')} is required`
            : undefined,
      }}
    />
  );
};

export default AsyncMultiSelectColumnFilter;
