import {
  Controller,
  UseControllerProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form-v7';
import { cn } from '../utils/classUtils';
import FormErrorMessage from './FormErrorMessage';

interface ControlledFileUploadInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  buttonLabel?: string;
  placeholder?: string;
  accept?: string;
  multiple?: boolean;
  readonly?: boolean;
  rightAction?: {
    text: string;
    onClick(): void;
  };
  subLabel?: string;
  isRequired?: boolean;
}

const ControlledFileUploadInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  placeholder,
  buttonLabel = 'Browse',
  readonly,
  accept,
  multiple,
  rules,
  rightAction,
  defaultValue,
  shouldUnregister = true,
  subLabel,
  isRequired = false,
  ...rest
}: ControlledFileUploadInputV7Props<TFieldValues, TName>) => {
  return (
    <div className='space-y-1 w-full'>
      <div className='flex flex-row justify-between'>
        {label && (
          <label className='inline-block' htmlFor={name}>
            <span>{label}</span>
            {!!subLabel && (
              <span className='font-primary-regular text-sm text-gray-400 ml-1'>
                {subLabel}
              </span>
            )}
            {isRequired && <span className='text-error'>*</span>}
          </label>
        )}

        {rightAction && (
          <a
            className='text-primary underline'
            href='#!'
            onClick={rightAction.onClick}
          >
            {rightAction.text}
          </a>
        )}
      </div>

      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        shouldUnregister={shouldUnregister}
        {...rest}
        render={({
          field: { name, value, ref, onChange },
          fieldState: { error },
        }) => {
          const fileNames = ((value as any) || [])
            .map((f: File) => f.name)
            .join(', ');

          return (
            <div
              className={cn({
                'cursor-not-allowed': readonly,
              })}
            >
              <input
                id={name}
                ref={ref}
                onChange={(e) => {
                  const files = Array.from(e.target.files!);
                  if (!!files?.length) {
                    onChange(Array.from(e.target.files!));
                  } else {
                    onChange(undefined);
                  }
                }}
                placeholder={placeholder}
                accept={accept}
                type='file'
                disabled={readonly}
                name={name}
                multiple={multiple}
                className='hidden'
              />
              <div className='flex items-center appearance-none text-gray-500 border border-gray-200 rounded bg-white focus:outline-none focus:ring-0 w-full'>
                <label htmlFor={name} className='border-r px-4 py-1.5'>
                  {buttonLabel}
                </label>
                <span className='px-3 line-clamp-1'>
                  {fileNames || placeholder}
                </span>
              </div>
              {error && <FormErrorMessage message={error.message} />}
            </div>
          );
        }}
      />
    </div>
  );
};

export default ControlledFileUploadInputV7;
