import { faCirclePlay } from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import ClosingServicesSvg from '../../assets/icons/closing-services.svg';
import OneRealEscrowLogo from '../../assets/icons/one-real-escrow-logo.svg';
import OneRealTitleLogo from '../../assets/icons/one-real-title-logo.svg';
import VideoThumbnail from '../../assets/img/zen/real-title/video-thumbnail.png';
import IFrameVideoplayer from '../IFrameVideoplayer';
import ButtonV2 from '../ButtonV2';

const RealClosingServicesLandingSection: React.FC = () => {
  const [play, setPlay] = useState(false);

  return (
    <>
      <IFrameVideoplayer
        play={play}
        setPlay={setPlay}
        videoUrl='/videos/one-real-title.mp4'
      />
      <div
        className='relative w-[calc(100%_+_24px)] md:w-[calc(100%_+_40px)]'
        data-testid='real-closing-services-landing-section'
      >
        <div className='absolute top-0 -z-10 w-full h-4/5 sm:h-2/3 bg-primary-navy'>
          <div className='invisible lg:visible border-b-2 border-l-2 border-coral-red h-4/5 w-full relative left-16' />
        </div>
        <div className='max-w-[842px] mx-auto p-4 md:p-8 flex flex-col gap-6'>
          <div className='flex items-center justify-center gap-3'>
            <img
              src={OneRealTitleLogo}
              alt='one real title logo'
              data-testid='one-real-title-logo'
            />
            <div className='w-[1px] h-6 bg-primary-light' />
            <img
              src={OneRealEscrowLogo}
              alt='one real escrow logo'
              data-testid='one-real-escrow-logo'
            />
          </div>
          <div>
            <h1 className='font-poppins text-[40px] font-bold text-white text-center leading-[48px] px-12'>
              <span className='text-aqua'>Seamless,</span>
              <span> tech-driven closings you can</span>
              <span className='text-aqua'> rely on.</span>
            </h1>
            <p className='text-base font-inter font-light text-primary-light text-center mt-4'>
              Our industry-leading technology and agent resources allow us to
              offer top-notch services in all our operating states, with the
              exception of California where we are a full-service escrow
              company.
            </p>
          </div>
          <div className='bg-seaglass rounded-lg relative border border-primary-dark'>
            <img
              src={ClosingServicesSvg}
              alt='closing services'
              className='absolute'
              data-testid='closing-services-svg'
            />
            <div className='flex flex-col items-center w-fit mx-auto'>
              <img
                src={VideoThumbnail}
                alt='thumbnail'
                className='h-[210px] md:h-[360px]'
                data-testid='video-thumbnail'
              />
              <ButtonV2
                label='See Video'
                icon={faCirclePlay}
                onClick={() => setPlay(true)}
                className='relative -top-6 md:-top-10'
                data-testid='see-video-button'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RealClosingServicesLandingSection;
