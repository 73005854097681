import { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import {
  EnumMap,
  FormFieldTooltipIndexProps,
  ISelectOption,
} from '../../../types';
import { cn } from '../../../utils/classUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';

export type ControlledSelectV7InputVariant = 'light' | 'transparent';

interface ControlledSelectV7InputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  subLabel?: string;
  placeholder?: string;
  options: Array<ISelectOption>;
  readOnly?: boolean;
  isRequired?: boolean;
  startAdornment?: React.ReactElement;
  variant?: ControlledSelectV7InputVariant;
  onChangeSpy?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ZenControlledHTMLSelectInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  placeholder,
  options,
  readOnly = false,
  shouldUnregister = true,
  isRequired = false,
  startAdornment,
  variant = 'light',
  onChangeSpy,
  ...rest
}: ControlledSelectV7InputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error, invalid },
      }) => {
        const variantToBackgroundStyleMap: EnumMap<
          ControlledSelectV7InputVariant,
          string
        > = {
          light: 'bg-white',
          transparent: 'bg-transparent',
        };

        const variantToTextStyleMap: EnumMap<
          ControlledSelectV7InputVariant,
          string
        > = {
          light: invalid ? 'text-zen-danger' : 'text-zen-dark-9',
          transparent: invalid ? 'text-zen-danger' : 'text-white',
        };

        const variantToBorderStyleMap: EnumMap<
          ControlledSelectV7InputVariant,
          string
        > = {
          light: focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
          transparent: focus ? 'border-zen-dark-7' : 'border-white',
        };

        return (
          <div className='space-y-1 w-full'>
            {label && (
              <label className='inline-block' htmlFor={name}>
                <span
                  className={cn(
                    'font-zen-body font-semibold text-base',
                    variantToTextStyleMap[variant],
                  )}
                >
                  {label}
                </span>
                {!!subLabel && (
                  <span className='font-zen-body text-sm text-zen-dark-12 ml-1'>
                    {subLabel}
                  </span>
                )}
                {isRequired && <span className='text-zen-danger'>*</span>}
              </label>
            )}
            <div
              className={cn(
                'flex items-stretch border rounded-lg overflow-hidden',
                value && 'text-zen-dark-9',
                invalid && '!border-zen-danger',
                variantToBackgroundStyleMap[variant],
                variantToBorderStyleMap[variant],
              )}
            >
              {startAdornment && <div>{startAdornment}</div>}
              <select
                id={name}
                value={value}
                onChange={(event) => {
                  onChange(event);
                  if (onChangeSpy) {
                    onChangeSpy(event);
                  }
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => {
                  setFocus(false);
                  onBlur();
                }}
                name={name}
                placeholder={placeholder}
                disabled={readOnly}
                className={cn(
                  'appearance-none py-2 pl-2 w-full border-none focus:outline-none focus:ring-0 font-zen-body font-normal overflow-ellipsis',
                  {
                    'bg-gray-50 pointer-events-none': readOnly,
                    'text-white': variant === 'transparent',
                  },
                  variantToBackgroundStyleMap[variant],
                )}
                data-testid='html-select'
              >
                {options.map(({ value, label, disabled }) => (
                  <option key={value} value={value} disabled={disabled}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            {error && <ZenFormErrorMessage message={error.message} />}
          </div>
        );
      }}
    />
  );
};

export default ZenControlledHTMLSelectInput;
