import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import {
  TransactionError,
  TransactionErrorSeverityEnum,
} from '../../../openapi/arrakis';

interface GeminiTransactionErrorsPopoverProps {
  errors?: Array<TransactionError>;
}

const GeminiTransactionErrorsPopover: React.FC<GeminiTransactionErrorsPopoverProps> = ({
  errors = [],
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const requiredAttentionErrors = errors?.filter(
    (error) =>
      error.severity === TransactionErrorSeverityEnum.Blocker ||
      error.severity === TransactionErrorSeverityEnum.Critical,
  );

  if (!requiredAttentionErrors.length) {
    return null;
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['top']}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor='white'
          arrowSize={10}
          arrowStyle={{
            bottom: 1,
            zIndex: 1,
          }}
          className='popover-arrow-container'
          arrowClassName='popover-arrow'
        >
          <div className='bg-white p-3 max-w-xs stroke-1 stroke-zen-dark-4 shadow-pop-over rounded-xl'>
            <div className='flex items-center font-zen-body-2 pb-1 border-b border-grey-200'>
              <div className='h-6 w-6 flex items-center justify-center shrink-0'>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  size='sm'
                  className='text-red-600'
                />
              </div>
              <div className='text-primary-dark text-sm leading-4 capitalize'>
                Requires Attention
              </div>
            </div>
            <ul className='my-2 list-disc ml-5 font-zen-body-2 space-y-1.5'>
              {requiredAttentionErrors?.map((error) => (
                <li
                  className='text-xs font-light text-primary-dark leading-5'
                  key={error.message}
                >
                  {error.message}
                </li>
              ))}
            </ul>
          </div>
        </ArrowContainer>
      )}
    >
      <button
        data-testid='transaction-errors-popover'
        onMouseEnter={() => setIsPopoverOpen(true)}
        onMouseLeave={() => setIsPopoverOpen(false)}
        className='flex items-center justify-center ml-2'
      >
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          className='text-lg text-red-600'
          data-testid='gemini-transaction-error-icon'
        />
      </button>
    </Popover>
  );
};

export default GeminiTransactionErrorsPopover;
