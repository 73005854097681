import { ErrorCode } from '../../types';
import { getPageCount } from '../../utils/TableUtils';
import DefaultLoader from '../DefaultLoader';
import ResourceContainer from '../ResourceContainer';
import ZenTablePageSize from './Table/ZenTablePageSize';
import ZenTablePagination from './Table/ZenTablePagination';
import ZenSearchBar from './ZenSearchBar';

interface ZenListPaginateProps<D> {
  listItems: Array<D>;
  search?: string;
  itemsToShow: number;
  page: number;
  totalItems: number;
  loading: boolean;
  errorCode: null | ErrorCode;
  searchPlaceholder?: string;
  resourceName: string;
  onSearch(searchValue?: string): void;
  goToPage(page: number): void;
  updateTotalItemsToShow(itemsToShow: number): void;
  children(data: Array<D>): React.ReactNode;
}

const ZenListPaginate = <D extends Object>({
  listItems,
  search,
  itemsToShow,
  page,
  totalItems,
  loading,
  errorCode,
  onSearch,
  goToPage,
  updateTotalItemsToShow,
  searchPlaceholder = 'Search...',
  resourceName,
  children,
}: ZenListPaginateProps<D>) => {
  const lastPage = getPageCount(totalItems, itemsToShow);

  return (
    <>
      <div className='w-full relative p-3'>
        <div>
          <ZenSearchBar
            value={search || ''}
            onChange={(search) => onSearch(search || undefined)}
            placeholder={searchPlaceholder}
          />
        </div>
        <ResourceContainer
          loading={!listItems.length && loading}
          isEmpty={!listItems.length}
          errorCode={errorCode}
          resourceName={resourceName}
        >
          {loading && (
            <div className='absolute left-0 right-0 bottom-0 top-0 flex items-center justify-center bg-white bg-opacity-50'>
              <DefaultLoader />
            </div>
          )}
          {children(listItems)}
        </ResourceContainer>
      </div>
      {listItems?.length! > 0 && (
        <div className='mt-5 px-4 flex flex-row items-center justify-between'>
          <ZenTablePageSize
            itemsToShow={itemsToShow}
            setPageSize={updateTotalItemsToShow}
          />
          <ZenTablePagination
            currentPage={page}
            pageSize={itemsToShow}
            lastPage={lastPage}
            totalCount={totalItems}
            goToPage={goToPage}
          />
        </div>
      )}
    </>
  );
};

export default ZenListPaginate;
