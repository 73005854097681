import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AnalyticsEventEnum, AppDispatch, RootState } from '../../../../types';
import {
  FrontLineAgentResponse,
  FrontLineAgentResponseStatusEnum,
} from '../../../../openapi/arrakis';
import {
  fetchDownLineAgents,
  fetchZenFrontLineForAgent,
} from '../../../../slices/NetworkSlice';
import useDidUpdateEffect from '../../../../hooks/useDidUpdateEffect';
import AnalyticsService from '../../../../services/AnalyticsService';
import RevShareInsightsTierFilters from '../../RevShareInsightsTierFilters';
import ResourceContainer from '../../../ResourceContainer';
import { getFullName } from '../../../../utils/AgentHelper';
import DefaultLoader from '../../../DefaultLoader';
import RevShareInsightsNetworkRow from '../../RevShareInsightsNetworkRow';

interface RevShareInsightsNetworkTierAgentsProps {
  initialTier: number;
  agentId: string;
}

const RevShareInsightsNetworkTierAgents: React.FC<RevShareInsightsNetworkTierAgentsProps> = ({
  initialTier,
  agentId,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    network: { zenLoading, zenNetworksById, downLineAgentsById },
    agentDetail: { detailResponse },
  } = useSelector((state: RootState) => state);

  const [tier, setTier] = useState<number>(initialTier);

  const isTier1 = tier === 1;
  const downLineAgentRes = downLineAgentsById[agentId];
  const frontLineActiveAgents = zenNetworksById[
    agentId
  ]?.frontLineAgents?.filter(
    (agent) => agent.status === FrontLineAgentResponseStatusEnum.Active,
  );
  const downLineAgents = downLineAgentRes?.data?.downLineAgents;
  const tierAgents = isTier1
    ? frontLineActiveAgents
    : (downLineAgents as FrontLineAgentResponse[]);
  const loading = isTier1
    ? zenLoading
    : !!downLineAgentRes?.loading && !downLineAgents?.length;
  const hasMore = isTier1 ? false : !!downLineAgentRes?.data?.hasNext;
  const total = isTier1
    ? frontLineActiveAgents?.length
    : downLineAgentRes?.data?.totalCount;

  const fetchData = useCallback(
    async (pageNum: number) => {
      if (isTier1 && !frontLineActiveAgents?.length) {
        dispatch(fetchZenFrontLineForAgent(agentId));
      } else if (!isTier1) {
        dispatch(fetchDownLineAgents(agentId, tier, pageNum, 20));
      }
    },
    [agentId, dispatch, frontLineActiveAgents?.length, isTier1, tier],
  );

  const handleNext = useCallback(() => {
    if (hasMore) {
      fetchData((downLineAgentRes?.data?.pageNumber || 0) + 1);
    }
  }, [downLineAgentRes?.data?.pageNumber, fetchData, hasMore]);

  useEffect(() => {
    fetchData(0);
  }, [fetchData]);

  useDidUpdateEffect(() => {
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.RSI_CLICK_TAB_IN_MY_NETWORK,
    );
  }, [tier]);

  return (
    <div>
      <div className='p-4'>
        <RevShareInsightsTierFilters
          tier={tier}
          onChange={setTier}
          loading={zenLoading}
        />
      </div>
      <p className='py-2 px-4 bg-grey-100 text-zen-dark-12 text-xs font-zen-body font-medium'>
        TIER {tier} AGENTS ({total || 0})
      </p>
      <ResourceContainer
        loading={loading}
        isEmpty={!tierAgents?.length}
        resourceName='sponsor agent'
        emptyMessage={`${getFullName(
          detailResponse.data,
        )} has not referred any other agents.`}
      >
        <InfiniteScroll
          hasMore={hasMore}
          loader={<DefaultLoader />}
          dataLength={tierAgents?.length || 0}
          scrollableTarget='modal-scroll'
          next={handleNext}
          className='divide-y divide-zen-light-gray-2'
        >
          {tierAgents?.map((agent) => (
            <div key={agent.id} className='px-4'>
              <RevShareInsightsNetworkRow
                agent={agent}
                hideExtraInfo={!isTier1}
              />
            </div>
          ))}
        </InfiniteScroll>
      </ResourceContainer>
    </div>
  );
};

export default RevShareInsightsNetworkTierAgents;
