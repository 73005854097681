import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { IdentitySummaryResponseMfaTypeEnum } from '../../../openapi/keymaker';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';

interface ZenAgentSelectTwoFactorAuthenticationMethodSideBarModalProps {
  isOpen: boolean;
  onClose(): void;
  setAuthenticationType(type: IdentitySummaryResponseMfaTypeEnum): void;
}

const ZenAgentSelectTwoFactorAuthenticationMethodSideBarModal: React.FC<ZenAgentSelectTwoFactorAuthenticationMethodSideBarModalProps> = ({
  isOpen,
  onClose,
  setAuthenticationType,
}) => {
  return (
    <ZenSidebarModal
      isOpen={isOpen}
      onClose={onClose}
      title='Select a Two-Factor Authentication Method'
      subtitle='This can be updated at any time by deleting the current active method.'
    >
      <div className='px-4 py-6'>
        <div>
          <p className='font-zen-body font-medium text-base'>
            Text Messages (SMS)
          </p>
          <p className='font-zen-body font-normal text-sm my-3'>
            Receive a confirmation code via text messages on your mobile phone
            upon login.
          </p>
          <ul className='list-disc my-3 ml-6'>
            <li className='font-zen-body font-normal text-sm'>
              You will need to provide your phone number.
            </li>
            <li className='font-zen-body font-normal text-sm'>
              You will need cellular service to receive messages and log in.
            </li>
          </ul>
          <div className='mt-8'>
            <button
              type='button'
              className='flex flex-row rounded-lg items-center focus:outline-none hover:opacity-90 bg-white border ring-1 border-1 text-base border-primary-blue ring-primary-blue hover:bg-primary-blue hover:bg-opacity-10 px-5 py-1.5'
              onClick={() =>
                setAuthenticationType(IdentitySummaryResponseMfaTypeEnum.Sms)
              }
            >
              <div className='items-center flex flex-row space-x-1'>
                <span className='px-2 items-center whitespace-nowrap text-primary-blue'>
                  Proceed with Text Messages
                </span>

                <FontAwesomeIcon
                  icon={faArrowRight}
                  className='text-primary-blue'
                />
              </div>
            </button>
          </div>
        </div>
        <div className='mt-10'>
          <p className='font-zen-body font-medium text-base'>
            Authenticator App
          </p>
          <p className='font-zen-body font-normal text-sm my-3'>
            Get free verification codes from an authenticator app even when your
            phone is offline.
          </p>
          <ul className='list-disc my-3 ml-6'>
            <li className=' font-zen-body font-normal text-sm'>
              Required: Download and install an authenticator app on your phone.
            </li>
            <li className='font-zen-body font-normal text-sm'>
              Access Rezen even when you’re phone is not connected to a cellular
              network.
            </li>
          </ul>
          <div className='mt-8'>
            <button
              type='button'
              className='flex flex-row rounded-lg items-center focus:outline-none hover:opacity-90 bg-white border ring-1 border-1 text-base border-primary-blue ring-primary-blue hover:bg-primary-blue hover:bg-opacity-10 px-5 py-1.5'
              onClick={() =>
                setAuthenticationType(
                  IdentitySummaryResponseMfaTypeEnum.Authenticator,
                )
              }
            >
              <div className='items-center flex flex-row space-x-1'>
                <span className='px-2 items-center whitespace-nowrap text-primary-blue'>
                  Proceed with Authenticator
                </span>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className='text-primary-blue'
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default ZenAgentSelectTwoFactorAuthenticationMethodSideBarModal;
