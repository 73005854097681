import { last } from 'lodash';
import { useSelector } from 'react-redux';
import {
  TransactionSetupFormProps,
  TransactionSetupStepName,
} from '../../../containers/transaction/setup/TransactionSetupContainer';
import {
  AttachedFeeValueFeeTypeEnum,
  FeaturesResponseEligibleEnum,
  PaymentParticipantValueRoleEnum,
} from '../../../openapi/arrakis';
import { KeyValuePair, RootState, YesNoType } from '../../../types';
import {
  getAllTransactionParticipantsByRole,
  getAllTransactionPaymentParticipantsText,
  getAttachedFeeRowSelectionLabel,
  getTransactionAttachedFeeByType,
  getTransactionOwner,
} from '../../../utils/TransactionHelper';
import Button from '../../Button';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import StepsReviewRow from '../../StepsReviewRow';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';

interface ReviewItem {
  items: KeyValuePair<string, string>[];
  navigateTo: TransactionSetupStepName;
}

const TransactionSetupReview: StepByStepComponent<
  TransactionSetupFormProps,
  TransactionSetupStepName
> = ({
  navigateTo,
  form: {
    watch,
    formState: { isSubmitting },
  },
  onSubmit,
}) => {
  const formData = watch();

  const {
    transactionDetailResponse: { data: transactionDetail },
    features,
  } = useSelector((state: RootState) => state.transaction);

  const isTransactionEligibleForRealTitle = !!features?.eligible?.find(
    (feature) => feature === FeaturesResponseEligibleEnum.Title,
  );

  const allReferralParticipants = getAllTransactionParticipantsByRole(
    transactionDetail!,
    PaymentParticipantValueRoleEnum.ReferringAgent,
  );

  const attachedFeeTransactionCoordinator = getTransactionAttachedFeeByType(
    transactionDetail!,
    AttachedFeeValueFeeTypeEnum.TransactionCoordinator,
  );

  const attachedFeeAdditionalCommission = getTransactionAttachedFeeByType(
    transactionDetail!,
    AttachedFeeValueFeeTypeEnum.AdditionalCommission,
  );

  const reviews: ReviewItem[] = [];

  reviews.push({
    items: [
      {
        key: 'Do you have a referral or an OpCity referral?',
        value: capitalizeEnum(formData?.referral),
      },
    ],
    navigateTo: TransactionSetupStepName.REFERRER,
  });

  if (formData.referral === YesNoType.YES) {
    last(reviews)!.items.push({
      key: 'Referral/OpCity referrer',
      value: allReferralParticipants
        .map((participant) => getParticipantName(participant))
        .join(`\n`),
    });
  }

  reviews.push({
    items: [
      {
        key: 'Will you be paying a transaction coordinator?',
        value: capitalizeEnum(formData?.payingTransactionCoordinator),
      },
    ],
    navigateTo: TransactionSetupStepName.ATTACHED_FEE_TRANSACTION_COORDINATOR,
  });

  if (formData.payingTransactionCoordinator === YesNoType.YES) {
    const allTransactionPayingCoordinatorsName = attachedFeeTransactionCoordinator?.map(
      (fee) => getAttachedFeeRowSelectionLabel(transactionDetail!, fee),
    );

    last(reviews)!.items.push({
      key: 'Transaction Coordinator Fee',
      value: allTransactionPayingCoordinatorsName?.join(`\n`) || 'N/A',
    });
  }

  reviews.push({
    items: [
      {
        key: 'Are you charging your clients any additional commission amount?',
        value: capitalizeEnum(formData?.chargingClients),
      },
    ],
    navigateTo: TransactionSetupStepName.ATTACHED_FEE_ADDITIONAL_COMMISSION,
  });

  if (formData.chargingClients === YesNoType.YES) {
    const additionalFees = attachedFeeAdditionalCommission?.map((fee) =>
      getAttachedFeeRowSelectionLabel(transactionDetail!, fee),
    );

    last(reviews)!.items.push({
      key: 'Additional Commission Fee / Rebate',
      value: additionalFees?.join(`\n`) || 'A/A',
    });
  }

  const transactionOwner = getTransactionOwner(transactionDetail!);

  const isPersonalDeal =
    transactionOwner &&
    'personalDeal' in transactionOwner &&
    transactionOwner.personalDeal;

  if (isPersonalDeal) {
    reviews.splice(1, 0, {
      items: [
        {
          key: 'Is this a personal deal?',
          value: capitalizeEnum(formData?.personalDeal),
        },
      ],
      navigateTo: TransactionSetupStepName.PERONSAL_DEAL,
    });
  }

  const allParticipants = getAllTransactionPaymentParticipantsText(
    transactionDetail!,
  );

  reviews.push({
    items: [{ key: 'Transaction Participants', value: allParticipants }],
    navigateTo: TransactionSetupStepName.COMMISSION_SPLITS,
  });

  if (isTransactionEligibleForRealTitle) {
    reviews.push({
      items: [
        {
          key: 'Would you prefer to use Real Title for this transaction?',
          value: capitalizeEnum(formData.usingTitle),
        },
      ],
      navigateTo: TransactionSetupStepName.REAL_TITLE,
    });
  }

  return (
    <div className='flex-grow flex flex-col justify-center w-full max-w-md pb-10'>
      <div>
        <p className='font-primary-medium text-xl text-center mb-10'>
          Please review your transaction before submitting
        </p>
        <div className='flex flex-col divide-y'>
          {reviews.map((review, index) => (
            <div key={review.navigateTo} className='py-3'>
              <StepsReviewRow
                items={review.items}
                number={index + 1}
                onClick={() => navigateTo(review.navigateTo)}
              />
            </div>
          ))}
        </div>
        <div className='flex flex-row justify-end mt-3'>
          <Button
            label='Submit'
            buttonType='button'
            onClick={onSubmit}
            disabled={isSubmitting}
            isSubmitting={isSubmitting}
            size='lg'
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionSetupReview;
