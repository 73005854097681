import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendar,
  faSquareList,
  faUserPen,
} from '@fortawesome/pro-light-svg-icons';
import { TransactionCoordinatorAccessEnum } from '../types';

export const I_DONT_KNOW_YET = "I_DON'T_HAVE_THE_INFORMATION_YET";

export const I_DONT_KNOW_YET_EMAIL = 'idontknowyet@example.com';

export const NA_100_PERCENT_PAID_FOR_WITH_DEPOSIT_EMAIL =
  'idontknowyetna@example.com';

export const UNVERIFIED_COMPLIANCE_HEADLINE =
  'Compliance not verified for this transaction';

export const UNVERIFIED_COMPLIANCE_CDA =
  'If you attempt to send the CDA before the broker marks this transaction as compliant, it will result in a single-check CDA and all funds will be sent to Real.';

export const GRT_TAX_RATE_INFO_LINK =
  'https://www.tax.newmexico.gov/governments/gross-receipts-location-code-and-tax-rate-map/';

export const TransactionCoordinatorAccessInfo: Record<
  TransactionCoordinatorAccessEnum,
  {
    title: string;
    description: string;
    icon: IconDefinition;
  }
> = {
  [TransactionCoordinatorAccessEnum.ALL_OPEN_LISTINGS_AND_TRANSACTIONS]: {
    title: 'All Open Listings and Transactions',
    description: 'Access to all your open listings and transactions',
    icon: faSquareList,
  },
  [TransactionCoordinatorAccessEnum.CREATED_AFTER]: {
    icon: faCalendar,
    title: 'Created After',
    description:
      'Access to listings and transactions created after a specific set date',
  },
  [TransactionCoordinatorAccessEnum.CREATED_BY_THEM_OR_MANUALLY_ADDED]: {
    icon: faUserPen,
    title: 'Created by Them or Manually Added',
    description:
      'Access to ONLY listings and transactions you add them to or that they create',
  },
};
