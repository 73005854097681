import { useDispatch, useSelector } from 'react-redux';
import { closeTransactionErrorModal } from '../slices/TransactionBuilderSlice';
import { AppDispatch, RootState } from '../types';
import ZenSimpleConfirmationModal from './Zen/Modal/ZenSimpleConfirmationModal';
import ZenButton from './Zen/ZenButton';

interface TransactionErrorModalProps {}

const ZenTransactionErrorModal: React.FC<TransactionErrorModalProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isErrorModalVisible, transactionErrors } = useSelector(
    (state: RootState) => state.transactionBuilder,
  );

  const onClose = () => {
    dispatch(closeTransactionErrorModal());
  };

  if (!isErrorModalVisible && !transactionErrors?.length) {
    return null;
  }

  return (
    <ZenSimpleConfirmationModal
      variant='error'
      title='Transaction Errors'
      isOpen={isErrorModalVisible}
      onClose={onClose}
      isSubmitting
      hideBottomButtons
      hideCancelButton
      size='large'
    >
      <div className='w-full flex-col'>
        <div className='w-full my-2'>
          <div className='flex flex-col space-y-1 font-primary-regular'>
            <ul className='list-disc ml-4 text-zen-dark-9'>
              {transactionErrors?.map((error) => (
                <li key={error?.message} className='my-1'>
                  {error?.message}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='flex items-center justify-center mt-6 w-full -ml-6'>
          <ZenButton
            label='Ok'
            variant='primary'
            type='button'
            onClick={onClose}
          />
        </div>
      </div>
    </ZenSimpleConfirmationModal>
  );
};

export default ZenTransactionErrorModal;
