import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BorrowerDto } from '../../../openapi/atlantis';
import {
  fetchBorrowerQuestions,
  setFormValidityById,
  setShowApplicationError,
} from '../../../slices/MortgageSlice';
import { RootState } from '../../../types';
import {
  hasOnlyButtonFieldsInQuestion,
  hasOnlyButtonFieldsInSection,
} from '../../../utils/mortgage/MortgageApplicationFormUtils';
import { isApplicationQuestion } from '../../../utils/TypeUtils';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import ZenResourceContainer from '../../Zen/ZenResourceContainer';
import MortgageAccordion from '../MortgageAccordion';
import MortgageApplicationForm from './MortgageApplicationForm';

export interface MortgageApplicationFormContainerProps {
  borrower: BorrowerDto;
}

const MortgageApplicationContainer: React.FC<MortgageApplicationFormContainerProps> = ({
  borrower,
}) => {
  const dispatch = useDispatch();
  const { borrowerApplications } = useSelector(
    (state: RootState) => state.mortgage,
  );
  const application = borrowerApplications[borrower.id] || {};

  useEffect(() => {
    dispatch(fetchBorrowerQuestions(borrower.id));
    dispatch(setShowApplicationError(false));
    dispatch(setFormValidityById(undefined));
  }, [borrower.id, dispatch]);

  return (
    <AuthorizationContainer asyncResponse={application}>
      <ZenResourceContainer
        loading={application.loading}
        isEmpty={!application.data}
        resourceName={`Application for ${borrower.firstName}`}
      >
        <div className='p-10 flex flex-col gap-y-10'>
          {application.data?.sections.map((section) => {
            if (hasOnlyButtonFieldsInSection(section)) {
              return null;
            }

            return (
              <MortgageAccordion
                key={section.name}
                title={section.name}
                isOpenDefault
              >
                {(section.questionSteps || []).map((question) => {
                  const isInvalidQuestion =
                    !isApplicationQuestion(question) ||
                    hasOnlyButtonFieldsInQuestion(question);

                  if (isInvalidQuestion) {
                    return null;
                  }

                  return (
                    <div key={question.content.id} className='mb-8'>
                      <MortgageApplicationForm
                        question={question}
                        borrower={borrower}
                      />
                    </div>
                  );
                })}
              </MortgageAccordion>
            );
          })}
        </div>
      </ZenResourceContainer>
    </AuthorizationContainer>
  );
};

export default MortgageApplicationContainer;
