import { LegacyRef, useRef } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  isDraggableImageMimeTypeValid,
  isDraggableImageValid,
  isImageMimeTypeValid,
  isImageValid,
} from '../../../utils/AgentHelper';
import { ReactComponent as NewFile } from '../../../assets/img/file-new.svg';
import { MAX_FILE_SIZE_30MB } from '../../../constants/FilesConstants';
import { cn } from '../../../utils/classUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';

export type fileInputSize = 'medium' | 'large' | 'small';

interface ZenControlledDraggableFileUploadInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  placeholder?: string;
  accept?: string;
  fileInputSize?: fileInputSize;
  downloadUrl?: string;
  isRequired?: boolean;
}

const ZenControlledDraggableFileUploadInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  placeholder,
  accept,
  fileInputSize = 'large',
  rules,
  downloadUrl,
  isRequired,
  ...rest
}: ZenControlledDraggableFileUploadInputProps<TFieldValues, TName>) => {
  const fileInputRef = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const fileUploadInputContainerSizeToClassNameMap: {
    [type in fileInputSize]: string;
  } = {
    medium: 'w-full h-48',
    large: 'w-full h-60',
    small: 'w-full h-24',
  };

  const fileUploadInputImageSizeToClassNameMap: {
    [type in fileInputSize]: string;
  } = {
    medium: 'w-4/5 h-40',
    large: 'w-4/5 h-52',
    small: 'w-4/5 h-20',
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      {...rest}
      render={({
        field: { name, onChange, value },
        fieldState: { error, invalid },
      }) => {
        return (
          <div className='w-full space-y-1'>
            {label && (
              <label
                className={cn(
                  'inline-block font-zen-body font-semibold',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                )}
                htmlFor={name}
              >
                {label}
                {isRequired && <span className='text-zen-danger'>*</span>}
              </label>
            )}
            <div>
              <div
                className={cn(
                  'border border-dashed border-zen-dark-5 rounded bg-white group hover:bg-grey-100 cursor-default mb-1',
                  fileUploadInputContainerSizeToClassNameMap[fileInputSize],
                  {
                    'border-zen-danger': invalid,
                  },
                )}
                onClick={() => fileInputRef.current?.click()}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => e.preventDefault()}
                onDragLeave={(e) => e.preventDefault()}
                onDrop={async (e) => {
                  if (
                    isDraggableImageValid(e) &&
                    isDraggableImageMimeTypeValid(e)
                  ) {
                    e.preventDefault();
                    onChange(e.dataTransfer.files[0]);
                  } else if (
                    isDraggableImageValid(e) &&
                    !isDraggableImageMimeTypeValid(e)
                  ) {
                    e.preventDefault();
                    await dispatch(
                      showErrorToast(
                        'File type is incorrect. Only .jpeg, .png files are accepted',
                      ),
                    );
                  } else {
                    e.preventDefault();
                    await dispatch(
                      showErrorToast(
                        'File size exceeds maximum limit of 30 MB.',
                      ),
                    );
                  }
                }}
              >
                <div className='flex items-center justify-center h-full'>
                  <input
                    id={name}
                    ref={
                      (fileInputRef as unknown) as LegacyRef<HTMLInputElement>
                    }
                    onChange={async (e) => {
                      if (!isImageValid(e, MAX_FILE_SIZE_30MB)) {
                        return await dispatch(
                          showErrorToast(
                            'File size exceeds maximum limit of 30 MB.',
                          ),
                        );
                      } else if (!isImageMimeTypeValid(e)) {
                        return await dispatch(
                          showErrorToast(
                            'File type is incorrect. Only .jpeg, .png files are accepted',
                          ),
                        );
                      } else {
                        return onChange(e.target.files![0]);
                      }
                    }}
                    placeholder={placeholder}
                    accept={accept}
                    type='file'
                    name={name}
                    className='hidden w-full h-full'
                  />
                  {typeof value === 'object' ? (
                    <img
                      src={URL.createObjectURL(value)}
                      alt='check-img'
                      className={cn(
                        fileUploadInputImageSizeToClassNameMap[fileInputSize],
                        'my-6 object-contain',
                      )}
                    />
                  ) : typeof value === 'string' ? (
                    <img
                      src={value}
                      alt='check-img'
                      className={cn(
                        fileUploadInputImageSizeToClassNameMap[fileInputSize],
                        'my-6 object-contain',
                      )}
                    />
                  ) : (
                    <div className='text-zen-dark-7 flex flex-col justify-center items-center gap-y-2'>
                      <NewFile fontSize={20} />
                      <p className='font-zen-body text-sm font-semibold'>
                        Drag your documents here
                      </p>
                      <div className='w-32 h-px flex justify-center items-center bg-zen-dark-5 my-3'>
                        <span className='font-zen-body text-sm font-semibold px-2 py-0.5 bg-white group-hover:bg-grey-100'>
                          OR
                        </span>
                      </div>
                      <span className='font-zen-body font-semibold text-base text-primary-blue'>
                        Browse Files
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {error && <ZenFormErrorMessage message={error.message} />}
            </div>
          </div>
        );
      }}
    />
  );
};

export default ZenControlledDraggableFileUploadInput;
