/* tslint:disable */
/* eslint-disable */
/**
 * Yada API
 * Yada Main Application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AudioInfo
 */
export interface AudioInfo {
    /**
     * 
     * @type {number}
     * @memberof AudioInfo
     */
    'durationSecs'?: number;
    /**
     * 
     * @type {number}
     * @memberof AudioInfo
     */
    'truncationSecs'?: number;
}
/**
 * 
 * @export
 * @interface AuthorizedUser
 */
export interface AuthorizedUser {
    /**
     * 
     * @type {string}
     * @memberof AuthorizedUser
     */
    'yentaId': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedUser
     */
    'teamId'?: string;
}
/**
 * 
 * @export
 * @interface AuthorizedUsers
 */
export interface AuthorizedUsers {
    /**
     * 
     * @type {Array<AuthorizedUser>}
     * @memberof AuthorizedUsers
     */
    'users': Array<AuthorizedUser>;
}
/**
 * 
 * @export
 * @interface BaseMessage
 */
export interface BaseMessage {
    /**
     * 
     * @type {string}
     * @memberof BaseMessage
     */
    'eventId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseMessage
     */
    'createdAt'?: number;
}
/**
 * 
 * @export
 * @interface BatchComment
 */
export interface BatchComment {
    /**
     * 
     * @type {PostCommentParams}
     * @memberof BatchComment
     */
    'params'?: PostCommentParams;
    /**
     * 
     * @type {PostCommentBody}
     * @memberof BatchComment
     */
    'body'?: PostCommentBody;
}
/**
 * 
 * @export
 * @interface BatchCommentRequest
 */
export interface BatchCommentRequest {
    /**
     * 
     * @type {Array<BatchComment>}
     * @memberof BatchCommentRequest
     */
    'comments'?: Array<BatchComment>;
}
/**
 * 
 * @export
 * @interface BatchCommentResponse
 */
export interface BatchCommentResponse {
    /**
     * 
     * @type {Array<CommentDto>}
     * @memberof BatchCommentResponse
     */
    'comments'?: Array<CommentDto>;
}
/**
 * 
 * @export
 * @interface Block
 */
export interface Block {
    /**
     * 
     * @type {string}
     * @memberof Block
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Block
     */
    'preview'?: string;
}
/**
 * 
 * @export
 * @interface CallParticipantDto
 */
export interface CallParticipantDto {
    /**
     * 
     * @type {string}
     * @memberof CallParticipantDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof CallParticipantDto
     */
    'name'?: string;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof CallParticipantDto
     */
    'phone'?: PhoneNumber;
    /**
     * 
     * @type {EmailAddress}
     * @memberof CallParticipantDto
     */
    'email'?: EmailAddress;
}
/**
 * 
 * @export
 * @interface CommentCountRs
 */
export interface CommentCountRs {
    /**
     * 
     * @type {Array<TargetCommentCount>}
     * @memberof CommentCountRs
     */
    'counts'?: Array<TargetCommentCount>;
}
/**
 * 
 * @export
 * @interface CommentDto
 */
export interface CommentDto {
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    'id'?: string;
    /**
     * 
     * @type {RezenObject}
     * @memberof CommentDto
     */
    'container'?: RezenObject;
    /**
     * 
     * @type {RezenObject}
     * @memberof CommentDto
     */
    'refersTo'?: RezenObject;
    /**
     * 
     * @type {number}
     * @memberof CommentDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    'createdById'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    'createdByGroupId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommentDto
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    'updatedById'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommentDto
     */
    'edited'?: boolean;
    /**
     * 
     * @type {RichText}
     * @memberof CommentDto
     */
    'richContent'?: RichText;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    'readerRole'?: CommentDtoReaderRoleEnum;
    /**
     * 
     * @type {Array<ReadReceiptDto>}
     * @memberof CommentDto
     */
    'readReceipts'?: Array<ReadReceiptDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommentDto
     */
    'rolesToNotify'?: Array<CommentDtoRolesToNotifyEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum CommentDtoReaderRoleEnum {
    User = 'USER',
    Admin = 'ADMIN',
    Public = 'PUBLIC'
}
/**
    * @export
    * @enum {string}
    */
export enum CommentDtoRolesToNotifyEnum {
    Agent = 'AGENT',
    AgentExperience = 'AGENT_EXPERIENCE',
    DesignatedBroker = 'DESIGNATED_BROKER',
    RegionalBroker = 'REGIONAL_BROKER',
    ManagingBroker = 'MANAGING_BROKER',
    FinanceSpecialist = 'FINANCE_SPECIALIST',
    SettlementSpecialist = 'SETTLEMENT_SPECIALIST',
    LoanOfficer = 'LOAN_OFFICER',
    Borrower = 'BORROWER',
    None = 'NONE'
}

/**
 * 
 * @export
 * @interface CommentTemplateCreateRequest
 */
export interface CommentTemplateCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof CommentTemplateCreateRequest
     */
    'type': CommentTemplateCreateRequestTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommentTemplateCreateRequest
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CommentTemplateCreateRequest
     */
    'message': string;
}

/**
    * @export
    * @enum {string}
    */
export enum CommentTemplateCreateRequestTypeEnum {
    MissingSignature = 'MISSING_SIGNATURE',
    Termination = 'TERMINATION',
    MissingDocument = 'MISSING_DOCUMENT',
    NoDocsAtAll = 'NO_DOCS_AT_ALL',
    CompensationDoesNotMatch = 'COMPENSATION_DOES_NOT_MATCH',
    LateMlsEntry = 'LATE_MLS_ENTRY',
    LateFileEntry = 'LATE_FILE_ENTRY',
    UsCommissionNotApproved = 'US_COMMISSION_NOT_APPROVED',
    CanadaCommissionNotApproved = 'CANADA_COMMISSION_NOT_APPROVED'
}

/**
 * 
 * @export
 * @interface CommentTemplateGroup
 */
export interface CommentTemplateGroup {
    /**
     * 
     * @type {string}
     * @memberof CommentTemplateGroup
     */
    'type': string;
    /**
     * 
     * @type {Array<CommentTemplateResponse>}
     * @memberof CommentTemplateGroup
     */
    'commentTemplatesByType': Array<CommentTemplateResponse>;
}
/**
 * 
 * @export
 * @interface CommentTemplatePaginatedResponse
 */
export interface CommentTemplatePaginatedResponse {
    /**
     * 
     * @type {number}
     * @memberof CommentTemplatePaginatedResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommentTemplatePaginatedResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommentTemplatePaginatedResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommentTemplatePaginatedResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommentTemplatePaginatedResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommentTemplatePaginatedResponse
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CommentTemplatePaginatedResponse
     */
    'sortDirection'?: CommentTemplatePaginatedResponseSortDirectionEnum;
    /**
     * 
     * @type {Array<CommentTemplateGroup>}
     * @memberof CommentTemplatePaginatedResponse
     */
    'commentTemplatesByType': Array<CommentTemplateGroup>;
    /**
     * 
     * @type {Array<CommentTemplateResponse>}
     * @memberof CommentTemplatePaginatedResponse
     */
    'allCommentTemplates': Array<CommentTemplateResponse>;
}

/**
    * @export
    * @enum {string}
    */
export enum CommentTemplatePaginatedResponseSortDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface CommentTemplateResponse
 */
export interface CommentTemplateResponse {
    /**
     * 
     * @type {string}
     * @memberof CommentTemplateResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CommentTemplateResponse
     */
    'type': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommentTemplateResponse
     */
    'roles': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CommentTemplateResponse
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof CommentTemplateResponse
     */
    'deactivatedAt'?: number;
}
/**
 * 
 * @export
 * @interface CommentTemplateUpdateRequest
 */
export interface CommentTemplateUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof CommentTemplateUpdateRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CommentTemplateUpdateRequest
     */
    'type'?: CommentTemplateUpdateRequestTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommentTemplateUpdateRequest
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CommentTemplateUpdateRequest
     */
    'message'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CommentTemplateUpdateRequestTypeEnum {
    MissingSignature = 'MISSING_SIGNATURE',
    Termination = 'TERMINATION',
    MissingDocument = 'MISSING_DOCUMENT',
    NoDocsAtAll = 'NO_DOCS_AT_ALL',
    CompensationDoesNotMatch = 'COMPENSATION_DOES_NOT_MATCH',
    LateMlsEntry = 'LATE_MLS_ENTRY',
    LateFileEntry = 'LATE_FILE_ENTRY',
    UsCommissionNotApproved = 'US_COMMISSION_NOT_APPROVED',
    CanadaCommissionNotApproved = 'CANADA_COMMISSION_NOT_APPROVED'
}

/**
 * 
 * @export
 * @interface CommentsByOwner
 */
export interface CommentsByOwner {
    /**
     * 
     * @type {RezenObject}
     * @memberof CommentsByOwner
     */
    'owner'?: RezenObject;
    /**
     * 
     * @type {Array<CommentDto>}
     * @memberof CommentsByOwner
     */
    'comments'?: Array<CommentDto>;
}
/**
 * 
 * @export
 * @interface ConversationMemberResponse
 */
export interface ConversationMemberResponse {
    /**
     * 
     * @type {string}
     * @memberof ConversationMemberResponse
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface ConversationResponse
 */
export interface ConversationResponse {
    /**
     * 
     * @type {string}
     * @memberof ConversationResponse
     */
    'id'?: string;
    /**
     * 
     * @type {Array<ConversationMemberResponse>}
     * @memberof ConversationResponse
     */
    'members'?: Array<ConversationMemberResponse>;
}
/**
 * 
 * @export
 * @interface CreateConversation
 */
export interface CreateConversation {
    /**
     * 
     * @type {Array<CreateConversationMember>}
     * @memberof CreateConversation
     */
    'members': Array<CreateConversationMember>;
}
/**
 * 
 * @export
 * @interface CreateConversationMember
 */
export interface CreateConversationMember {
    /**
     * 
     * @type {string}
     * @memberof CreateConversationMember
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface CreateVirtualNumber
 */
export interface CreateVirtualNumber {
    /**
     * 
     * @type {PhoneNumber}
     * @memberof CreateVirtualNumber
     */
    'phone': PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof CreateVirtualNumber
     */
    'groupName': CreateVirtualNumberGroupNameEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateVirtualNumberGroupNameEnum {
    BrokerTeam = 'BROKER_TEAM',
    FinanceTeam = 'FINANCE_TEAM',
    SupportTeam = 'SUPPORT_TEAM',
    TransactionTeam = 'TRANSACTION_TEAM'
}

/**
 * 
 * @export
 * @interface CreateVoiceCallRequest
 */
export interface CreateVoiceCallRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateVoiceCallRequest
     */
    'targetId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVoiceCallRequest
     */
    'targetType': CreateVoiceCallRequestTargetTypeEnum;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof CreateVoiceCallRequest
     */
    'targetPhone'?: PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof CreateVoiceCallRequest
     */
    'targetOfficeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVoiceCallRequest
     */
    'containerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVoiceCallRequest
     */
    'containerType'?: CreateVoiceCallRequestContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateVoiceCallRequest
     */
    'direction': CreateVoiceCallRequestDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateVoiceCallRequest
     */
    'callerGroupId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateVoiceCallRequestTargetTypeEnum {
    Group = 'GROUP',
    User = 'USER'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateVoiceCallRequestContainerTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateVoiceCallRequestDirectionEnum {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}

/**
 * 
 * @export
 * @interface DropboxFileId
 */
export interface DropboxFileId {
    /**
     * 
     * @type {string}
     * @memberof DropboxFileId
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface EmailAddress
 */
export interface EmailAddress {
    /**
     * 
     * @type {string}
     * @memberof EmailAddress
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface FailedMessage
 */
export interface FailedMessage {
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'clientType': FailedMessageClientTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'status': FailedMessageStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'reprocessingAttempts'?: number;
    /**
     * 
     * @type {KafkaMessageLocation}
     * @memberof FailedMessage
     */
    'kafkaMessageLocation': KafkaMessageLocation;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'key'?: string;
    /**
     * 
     * @type {WrapperBaseMessage}
     * @memberof FailedMessage
     */
    'payload'?: WrapperBaseMessage;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'unprocessed'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FailedMessage
     */
    'processed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'shortenedId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageClientTypeEnum {
    Producer = 'PRODUCER',
    Consumer = 'CONSUMER'
}
/**
    * @export
    * @enum {string}
    */
export enum FailedMessageStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FailedMessageRetryRequest
 */
export interface FailedMessageRetryRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageRetryRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface FailedMessageUpdateStatusRequest
 */
export interface FailedMessageUpdateStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'status'?: FailedMessageUpdateStatusRequestStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageUpdateStatusRequestStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FailedMessagesCriteriaRequest
 */
export interface FailedMessagesCriteriaRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtTo'?: string;
}
/**
 * 
 * @export
 * @interface FileBlock
 */
export interface FileBlock extends Block {
    /**
     * 
     * @type {string}
     * @memberof FileBlock
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileBlock
     */
    'altText'?: string;
}
/**
 * 
 * @export
 * @interface FileBlockAllOf
 */
export interface FileBlockAllOf {
    /**
     * 
     * @type {string}
     * @memberof FileBlockAllOf
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileBlockAllOf
     */
    'altText'?: string;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseConversationResponse
 */
export interface GenericSearchResponseConversationResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseConversationResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseConversationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseConversationResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseConversationResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseConversationResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericSearchResponseConversationResponse
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenericSearchResponseConversationResponse
     */
    'sortDirection'?: GenericSearchResponseConversationResponseSortDirectionEnum;
    /**
     * 
     * @type {Array<ConversationResponse>}
     * @memberof GenericSearchResponseConversationResponse
     */
    'results'?: Array<ConversationResponse>;
}

/**
    * @export
    * @enum {string}
    */
export enum GenericSearchResponseConversationResponseSortDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GenericSearchResponseFailedMessage
 */
export interface GenericSearchResponseFailedMessage {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseFailedMessage
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortDirection'?: GenericSearchResponseFailedMessageSortDirectionEnum;
    /**
     * 
     * @type {Array<FailedMessage>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'results'?: Array<FailedMessage>;
}

/**
    * @export
    * @enum {string}
    */
export enum GenericSearchResponseFailedMessageSortDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface HashtagBlock
 */
export interface HashtagBlock extends Block {
    /**
     * 
     * @type {string}
     * @memberof HashtagBlock
     */
    'hashtag'?: string;
}
/**
 * 
 * @export
 * @interface HashtagBlockAllOf
 */
export interface HashtagBlockAllOf {
    /**
     * 
     * @type {string}
     * @memberof HashtagBlockAllOf
     */
    'hashtag'?: string;
}
/**
 * 
 * @export
 * @interface HyperlinkBlock
 */
export interface HyperlinkBlock extends Block {
    /**
     * 
     * @type {string}
     * @memberof HyperlinkBlock
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof HyperlinkBlock
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface HyperlinkBlockAllOf
 */
export interface HyperlinkBlockAllOf {
    /**
     * 
     * @type {string}
     * @memberof HyperlinkBlockAllOf
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof HyperlinkBlockAllOf
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface ImageBlock
 */
export interface ImageBlock extends Block {
    /**
     * 
     * @type {string}
     * @memberof ImageBlock
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageBlock
     */
    'altText'?: string;
}
/**
 * 
 * @export
 * @interface KafkaMessageLocation
 */
export interface KafkaMessageLocation {
    /**
     * 
     * @type {string}
     * @memberof KafkaMessageLocation
     */
    'topic': string;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'offset'?: number;
}
/**
 * 
 * @export
 * @interface LatestCommentsResponse
 */
export interface LatestCommentsResponse {
    /**
     * 
     * @type {Array<CommentsByOwner>}
     * @memberof LatestCommentsResponse
     */
    'comments'?: Array<CommentsByOwner>;
}
/**
 * 
 * @export
 * @interface MediaAudioBlock
 */
export interface MediaAudioBlock extends Block {
    /**
     * 
     * @type {YadaMediaFileId}
     * @memberof MediaAudioBlock
     */
    'id'?: YadaMediaFileId;
    /**
     * 
     * @type {string}
     * @memberof MediaAudioBlock
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaAudioBlock
     */
    'extension'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaAudioBlock
     */
    'sizeBytes'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaAudioBlock
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaAudioBlock
     */
    'durationSecs'?: number;
    /**
     * 
     * @type {MediaTranscription}
     * @memberof MediaAudioBlock
     */
    'transcription'?: MediaTranscription;
    /**
     * 
     * @type {string}
     * @memberof MediaAudioBlock
     */
    'transcriptionStatus'?: MediaAudioBlockTranscriptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MediaAudioBlock
     */
    'recordingFlowStatus'?: MediaAudioBlockRecordingFlowStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MediaAudioBlockTranscriptionStatusEnum {
    Success = 'SUCCESS',
    Failure = 'FAILURE',
    NoOutcome = 'NO_OUTCOME'
}
/**
    * @export
    * @enum {string}
    */
export enum MediaAudioBlockRecordingFlowStatusEnum {
    Cancelled = 'CANCELLED',
    RecordingInProgress = 'RECORDING_IN_PROGRESS',
    RecordingUnavailable = 'RECORDING_UNAVAILABLE',
    TranscriptionInProgress = 'TRANSCRIPTION_IN_PROGRESS',
    TranscriptAvailable = 'TRANSCRIPT_AVAILABLE',
    TranscriptUnavailable = 'TRANSCRIPT_UNAVAILABLE'
}

/**
 * 
 * @export
 * @interface MediaAudioBlockAllOf
 */
export interface MediaAudioBlockAllOf {
    /**
     * 
     * @type {YadaMediaFileId}
     * @memberof MediaAudioBlockAllOf
     */
    'id'?: YadaMediaFileId;
    /**
     * 
     * @type {string}
     * @memberof MediaAudioBlockAllOf
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaAudioBlockAllOf
     */
    'extension'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaAudioBlockAllOf
     */
    'sizeBytes'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaAudioBlockAllOf
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaAudioBlockAllOf
     */
    'durationSecs'?: number;
    /**
     * 
     * @type {MediaTranscription}
     * @memberof MediaAudioBlockAllOf
     */
    'transcription'?: MediaTranscription;
    /**
     * 
     * @type {string}
     * @memberof MediaAudioBlockAllOf
     */
    'transcriptionStatus'?: MediaAudioBlockAllOfTranscriptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MediaAudioBlockAllOf
     */
    'recordingFlowStatus'?: MediaAudioBlockAllOfRecordingFlowStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MediaAudioBlockAllOfTranscriptionStatusEnum {
    Success = 'SUCCESS',
    Failure = 'FAILURE',
    NoOutcome = 'NO_OUTCOME'
}
/**
    * @export
    * @enum {string}
    */
export enum MediaAudioBlockAllOfRecordingFlowStatusEnum {
    Cancelled = 'CANCELLED',
    RecordingInProgress = 'RECORDING_IN_PROGRESS',
    RecordingUnavailable = 'RECORDING_UNAVAILABLE',
    TranscriptionInProgress = 'TRANSCRIPTION_IN_PROGRESS',
    TranscriptAvailable = 'TRANSCRIPT_AVAILABLE',
    TranscriptUnavailable = 'TRANSCRIPT_UNAVAILABLE'
}

/**
 * 
 * @export
 * @interface MediaDocBlock
 */
export interface MediaDocBlock extends Block {
    /**
     * 
     * @type {YadaMediaFileId}
     * @memberof MediaDocBlock
     */
    'id'?: YadaMediaFileId;
    /**
     * 
     * @type {string}
     * @memberof MediaDocBlock
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDocBlock
     */
    'extension'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaDocBlock
     */
    'sizeBytes'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaDocBlock
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface MediaDocBlockAllOf
 */
export interface MediaDocBlockAllOf {
    /**
     * 
     * @type {YadaMediaFileId}
     * @memberof MediaDocBlockAllOf
     */
    'id'?: YadaMediaFileId;
    /**
     * 
     * @type {string}
     * @memberof MediaDocBlockAllOf
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDocBlockAllOf
     */
    'extension'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaDocBlockAllOf
     */
    'sizeBytes'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaDocBlockAllOf
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface MediaImageBlock
 */
export interface MediaImageBlock extends Block {
    /**
     * 
     * @type {YadaMediaFileId}
     * @memberof MediaImageBlock
     */
    'id'?: YadaMediaFileId;
    /**
     * 
     * @type {string}
     * @memberof MediaImageBlock
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaImageBlock
     */
    'extension'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaImageBlock
     */
    'sizeBytes'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaImageBlock
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface MediaTranscription
 */
export interface MediaTranscription {
    /**
     * 
     * @type {Summary}
     * @memberof MediaTranscription
     */
    'summary'?: Summary;
    /**
     * 
     * @type {Transcript}
     * @memberof MediaTranscription
     */
    'transcript'?: Transcript;
    /**
     * 
     * @type {AudioInfo}
     * @memberof MediaTranscription
     */
    'audioInfo'?: AudioInfo;
}
/**
 * 
 * @export
 * @interface MediaVideoBlock
 */
export interface MediaVideoBlock extends Block {
    /**
     * 
     * @type {YadaMediaFileId}
     * @memberof MediaVideoBlock
     */
    'id'?: YadaMediaFileId;
    /**
     * 
     * @type {string}
     * @memberof MediaVideoBlock
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaVideoBlock
     */
    'extension'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaVideoBlock
     */
    'sizeBytes'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaVideoBlock
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaVideoBlock
     */
    'durationSecs'?: number;
    /**
     * 
     * @type {MediaTranscription}
     * @memberof MediaVideoBlock
     */
    'transcription'?: MediaTranscription;
    /**
     * 
     * @type {string}
     * @memberof MediaVideoBlock
     */
    'transcriptionStatus'?: MediaVideoBlockTranscriptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MediaVideoBlock
     */
    'recordingFlowStatus'?: MediaVideoBlockRecordingFlowStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MediaVideoBlockTranscriptionStatusEnum {
    Success = 'SUCCESS',
    Failure = 'FAILURE',
    NoOutcome = 'NO_OUTCOME'
}
/**
    * @export
    * @enum {string}
    */
export enum MediaVideoBlockRecordingFlowStatusEnum {
    Cancelled = 'CANCELLED',
    RecordingInProgress = 'RECORDING_IN_PROGRESS',
    RecordingUnavailable = 'RECORDING_UNAVAILABLE',
    TranscriptionInProgress = 'TRANSCRIPTION_IN_PROGRESS',
    TranscriptAvailable = 'TRANSCRIPT_AVAILABLE',
    TranscriptUnavailable = 'TRANSCRIPT_UNAVAILABLE'
}

/**
 * 
 * @export
 * @interface MentionBlock
 */
export interface MentionBlock extends Block {
    /**
     * 
     * @type {string}
     * @memberof MentionBlock
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MentionBlock
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MentionBlock
     */
    'mentionType'?: MentionBlockMentionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MentionBlock
     */
    'autoTag'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum MentionBlockMentionTypeEnum {
    User = 'USER',
    Group = 'GROUP'
}

/**
 * 
 * @export
 * @interface MentionBlockAllOf
 */
export interface MentionBlockAllOf {
    /**
     * 
     * @type {string}
     * @memberof MentionBlockAllOf
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MentionBlockAllOf
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MentionBlockAllOf
     */
    'mentionType'?: MentionBlockAllOfMentionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MentionBlockAllOf
     */
    'autoTag'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum MentionBlockAllOfMentionTypeEnum {
    User = 'USER',
    Group = 'GROUP'
}

/**
 * 
 * @export
 * @interface MessageCountRes
 */
export interface MessageCountRes {
    /**
     * 
     * @type {string}
     * @memberof MessageCountRes
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageCountRes
     */
    'unreadMessageCount'?: number;
}
/**
 * 
 * @export
 * @interface MessageDto
 */
export interface MessageDto {
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'messageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {RezenObject}
     * @memberof MessageDto
     */
    'target'?: RezenObject;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    'unreadComments'?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    'unreadFileUploads'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MessageDto
     */
    'read'?: boolean;
}
/**
 * 
 * @export
 * @interface MessageQueryResDto
 */
export interface MessageQueryResDto {
    /**
     * 
     * @type {string}
     * @memberof MessageQueryResDto
     */
    'nextPage'?: string;
    /**
     * 
     * @type {Array<MessageDto>}
     * @memberof MessageQueryResDto
     */
    'messages'?: Array<MessageDto>;
}
/**
 * 
 * @export
 * @interface NotifiableUser
 */
export interface NotifiableUser {
    /**
     * 
     * @type {string}
     * @memberof NotifiableUser
     */
    'keymakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifiableUser
     */
    'yentaId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifiableUser
     */
    'yentaUsername'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifiableUser
     */
    'yentaEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifiableUser
     */
    'yentaFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifiableUser
     */
    'role'?: NotifiableUserRoleEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum NotifiableUserRoleEnum {
    Agent = 'AGENT',
    AgentExperience = 'AGENT_EXPERIENCE',
    DesignatedBroker = 'DESIGNATED_BROKER',
    RegionalBroker = 'REGIONAL_BROKER',
    ManagingBroker = 'MANAGING_BROKER',
    FinanceSpecialist = 'FINANCE_SPECIALIST',
    SettlementSpecialist = 'SETTLEMENT_SPECIALIST',
    LoanOfficer = 'LOAN_OFFICER',
    Borrower = 'BORROWER',
    None = 'NONE'
}

/**
 * 
 * @export
 * @interface NotifiableUsers
 */
export interface NotifiableUsers {
    /**
     * 
     * @type {Array<NotifiableUser>}
     * @memberof NotifiableUsers
     */
    'users': Array<NotifiableUser>;
}
/**
 * 
 * @export
 * @interface PatchVoiceCallRequest
 */
export interface PatchVoiceCallRequest {
    /**
     * 
     * @type {PhoneNumber}
     * @memberof PatchVoiceCallRequest
     */
    'targetPhone'?: PhoneNumber;
}
/**
 * 
 * @export
 * @interface PhoneNumber
 */
export interface PhoneNumber {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumber
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface PostCommentBody
 */
export interface PostCommentBody {
    /**
     * 
     * @type {RichText}
     * @memberof PostCommentBody
     */
    'richContent'?: RichText;
    /**
     * 
     * @type {string}
     * @memberof PostCommentBody
     */
    'readerRole': PostCommentBodyReaderRoleEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostCommentBody
     */
    'rolesToNotify'?: Array<PostCommentBodyRolesToNotifyEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum PostCommentBodyReaderRoleEnum {
    User = 'USER',
    Admin = 'ADMIN',
    Public = 'PUBLIC'
}
/**
    * @export
    * @enum {string}
    */
export enum PostCommentBodyRolesToNotifyEnum {
    Agent = 'AGENT',
    AgentExperience = 'AGENT_EXPERIENCE',
    DesignatedBroker = 'DESIGNATED_BROKER',
    RegionalBroker = 'REGIONAL_BROKER',
    ManagingBroker = 'MANAGING_BROKER',
    FinanceSpecialist = 'FINANCE_SPECIALIST',
    SettlementSpecialist = 'SETTLEMENT_SPECIALIST',
    LoanOfficer = 'LOAN_OFFICER',
    Borrower = 'BORROWER',
    None = 'NONE'
}

/**
 * 
 * @export
 * @interface PostCommentParams
 */
export interface PostCommentParams {
    /**
     * 
     * @type {string}
     * @memberof PostCommentParams
     */
    'containerType': PostCommentParamsContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostCommentParams
     */
    'containerId': string;
    /**
     * 
     * @type {string}
     * @memberof PostCommentParams
     */
    'refersToType'?: PostCommentParamsRefersToTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostCommentParams
     */
    'refersToId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCommentParams
     */
    'refersToName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostCommentParams
     */
    'triggerActivity'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostCommentParams
     */
    'authorType'?: PostCommentParamsAuthorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostCommentParams
     */
    'callerGroupId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PostCommentParamsContainerTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE'
}
/**
    * @export
    * @enum {string}
    */
export enum PostCommentParamsRefersToTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE'
}
/**
    * @export
    * @enum {string}
    */
export enum PostCommentParamsAuthorTypeEnum {
    User = 'USER',
    System = 'SYSTEM'
}

/**
 * 
 * @export
 * @interface QueryCommentsRs
 */
export interface QueryCommentsRs {
    /**
     * 
     * @type {number}
     * @memberof QueryCommentsRs
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryCommentsRs
     */
    'nextPage'?: string;
    /**
     * 
     * @type {Array<CommentDto>}
     * @memberof QueryCommentsRs
     */
    'comments'?: Array<CommentDto>;
}
/**
 * 
 * @export
 * @interface ReadReceiptDto
 */
export interface ReadReceiptDto {
    /**
     * 
     * @type {string}
     * @memberof ReadReceiptDto
     */
    'readerId': string;
    /**
     * 
     * @type {number}
     * @memberof ReadReceiptDto
     */
    'readAt': number;
}
/**
 * 
 * @export
 * @interface ReferenceBlock
 */
export interface ReferenceBlock extends Block {
    /**
     * 
     * @type {RezenObject}
     * @memberof ReferenceBlock
     */
    'entity'?: RezenObject;
}
/**
 * 
 * @export
 * @interface ReferenceBlockAllOf
 */
export interface ReferenceBlockAllOf {
    /**
     * 
     * @type {RezenObject}
     * @memberof ReferenceBlockAllOf
     */
    'entity'?: RezenObject;
}
/**
 * 
 * @export
 * @interface RezenObject
 */
export interface RezenObject {
    /**
     * 
     * @type {string}
     * @memberof RezenObject
     */
    'type': RezenObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RezenObject
     */
    'id': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RezenObject
     */
    'metadata'?: { [key: string]: string; };
}

/**
    * @export
    * @enum {string}
    */
export enum RezenObjectTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE'
}

/**
 * 
 * @export
 * @interface RichText
 */
export interface RichText {
    /**
     * 
     * @type {Array<UploadMediaRichBlocksParameterInner>}
     * @memberof RichText
     */
    'blocks'?: Array<UploadMediaRichBlocksParameterInner>;
    /**
     * 
     * @type {string}
     * @memberof RichText
     */
    'preview'?: string;
}
/**
 * 
 * @export
 * @interface Segment
 */
export interface Segment {
    /**
     * 
     * @type {number}
     * @memberof Segment
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof Segment
     */
    'end'?: number;
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'speaker'?: string;
}
/**
 * 
 * @export
 * @interface Speaker
 */
export interface Speaker {
    /**
     * 
     * @type {string}
     * @memberof Speaker
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Speaker
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Speaker
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface Speakers
 */
export interface Speakers {
    /**
     * 
     * @type {Array<Speaker>}
     * @memberof Speakers
     */
    'speakers'?: Array<Speaker>;
}
/**
 * 
 * @export
 * @interface SpeechOutline
 */
export interface SpeechOutline {
    /**
     * 
     * @type {string}
     * @memberof SpeechOutline
     */
    'title'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpeechOutline
     */
    'segments'?: Array<string>;
}
/**
 * 
 * @export
 * @interface SseEmitter
 */
export interface SseEmitter {
    /**
     * 
     * @type {number}
     * @memberof SseEmitter
     */
    'timeout'?: number;
}
/**
 * 
 * @export
 * @interface Summary
 */
export interface Summary {
    /**
     * 
     * @type {string}
     * @memberof Summary
     */
    'abstractSummary'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Summary
     */
    'actionItems'?: Array<string>;
    /**
     * 
     * @type {Array<SpeechOutline>}
     * @memberof Summary
     */
    'speechOutlines'?: Array<SpeechOutline>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Summary
     */
    'keywords'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TargetCommentCount
 */
export interface TargetCommentCount {
    /**
     * 
     * @type {RezenObject}
     * @memberof TargetCommentCount
     */
    'target'?: RezenObject;
    /**
     * 
     * @type {number}
     * @memberof TargetCommentCount
     */
    'commentCount'?: number;
}
/**
 * 
 * @export
 * @interface TextBlock
 */
export interface TextBlock extends Block {
    /**
     * 
     * @type {string}
     * @memberof TextBlock
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface TextBlockAllOf
 */
export interface TextBlockAllOf {
    /**
     * 
     * @type {string}
     * @memberof TextBlockAllOf
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface Transcript
 */
export interface Transcript {
    /**
     * 
     * @type {string}
     * @memberof Transcript
     */
    'text'?: string;
    /**
     * 
     * @type {Array<Segment>}
     * @memberof Transcript
     */
    'segments'?: Array<Segment>;
}
/**
 * 
 * @export
 * @interface UnknownBlock
 */
export interface UnknownBlock extends Block {
}
/**
 * 
 * @export
 * @interface UpdateCommentBody
 */
export interface UpdateCommentBody {
    /**
     * 
     * @type {RichText}
     * @memberof UpdateCommentBody
     */
    'richContent'?: RichText;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommentBody
     */
    'readerRole'?: UpdateCommentBodyReaderRoleEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateCommentBodyReaderRoleEnum {
    User = 'USER',
    Admin = 'ADMIN',
    Public = 'PUBLIC'
}

/**
 * 
 * @export
 * @interface UpdateFailedMessageStatusBatch
 */
export interface UpdateFailedMessageStatusBatch {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'status': UpdateFailedMessageStatusBatchStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateFailedMessageStatusBatchStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface UpdateVirtualNumberRequest
 */
export interface UpdateVirtualNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateVirtualNumberRequest
     */
    'groupName': UpdateVirtualNumberRequestGroupNameEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateVirtualNumberRequestGroupNameEnum {
    BrokerTeam = 'BROKER_TEAM',
    FinanceTeam = 'FINANCE_TEAM',
    SupportTeam = 'SUPPORT_TEAM',
    TransactionTeam = 'TRANSACTION_TEAM'
}

/**
 * @type UploadMediaRichBlocksParameterInner
 * @export
 */
export type UploadMediaRichBlocksParameterInner = FileBlock | HashtagBlock | HyperlinkBlock | ImageBlock | MediaAudioBlock | MediaDocBlock | MediaImageBlock | MediaVideoBlock | MentionBlock | ReferenceBlock | TextBlock | UnknownBlock | VoiceCallBlock;

/**
 * 
 * @export
 * @interface VoiceCallBlock
 */
export interface VoiceCallBlock extends Block {
    /**
     * 
     * @type {VoiceCallId}
     * @memberof VoiceCallBlock
     */
    'voiceCallId'?: VoiceCallId;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallBlock
     */
    'direction'?: VoiceCallBlockDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallBlock
     */
    'callerId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VoiceCallBlock
     */
    'callerCancelledCall'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallBlock
     */
    'callerCalledAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallBlock
     */
    'callerConnectedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallBlock
     */
    'callerCompletedCallAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallBlock
     */
    'callerConnectedDurationMillis'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallBlock
     */
    'calleeId'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallBlock
     */
    'calleeConnectedDurationMillis'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallBlock
     */
    'recordingType'?: VoiceCallBlockRecordingTypeEnum;
    /**
     * 
     * @type {VoiceRecordingId}
     * @memberof VoiceCallBlock
     */
    'recordingId'?: VoiceRecordingId;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallBlock
     */
    'recordingDurationSecs'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallBlock
     */
    'recordingUrl'?: string;
    /**
     * 
     * @type {VoiceRecordingTranscription}
     * @memberof VoiceCallBlock
     */
    'recordingTranscription'?: VoiceRecordingTranscription;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallBlock
     */
    'recordingTranscriptionStatus'?: VoiceCallBlockRecordingTranscriptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallBlock
     */
    'recordingFlowStatus'?: VoiceCallBlockRecordingFlowStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof VoiceCallBlock
     */
    'recordingAccessible'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceCallBlockDirectionEnum {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallBlockRecordingTypeEnum {
    Call = 'CALL',
    Voicemail = 'VOICEMAIL'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallBlockRecordingTranscriptionStatusEnum {
    Success = 'SUCCESS',
    Failure = 'FAILURE',
    NoOutcome = 'NO_OUTCOME'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallBlockRecordingFlowStatusEnum {
    Cancelled = 'CANCELLED',
    RecordingInProgress = 'RECORDING_IN_PROGRESS',
    RecordingUnavailable = 'RECORDING_UNAVAILABLE',
    TranscriptionInProgress = 'TRANSCRIPTION_IN_PROGRESS',
    TranscriptAvailable = 'TRANSCRIPT_AVAILABLE',
    TranscriptUnavailable = 'TRANSCRIPT_UNAVAILABLE'
}

/**
 * 
 * @export
 * @interface VoiceCallBlockAllOf
 */
export interface VoiceCallBlockAllOf {
    /**
     * 
     * @type {VoiceCallId}
     * @memberof VoiceCallBlockAllOf
     */
    'voiceCallId'?: VoiceCallId;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallBlockAllOf
     */
    'direction'?: VoiceCallBlockAllOfDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallBlockAllOf
     */
    'callerId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VoiceCallBlockAllOf
     */
    'callerCancelledCall'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallBlockAllOf
     */
    'callerCalledAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallBlockAllOf
     */
    'callerConnectedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallBlockAllOf
     */
    'callerCompletedCallAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallBlockAllOf
     */
    'callerConnectedDurationMillis'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallBlockAllOf
     */
    'calleeId'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallBlockAllOf
     */
    'calleeConnectedDurationMillis'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallBlockAllOf
     */
    'recordingType'?: VoiceCallBlockAllOfRecordingTypeEnum;
    /**
     * 
     * @type {VoiceRecordingId}
     * @memberof VoiceCallBlockAllOf
     */
    'recordingId'?: VoiceRecordingId;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallBlockAllOf
     */
    'recordingDurationSecs'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallBlockAllOf
     */
    'recordingUrl'?: string;
    /**
     * 
     * @type {VoiceRecordingTranscription}
     * @memberof VoiceCallBlockAllOf
     */
    'recordingTranscription'?: VoiceRecordingTranscription;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallBlockAllOf
     */
    'recordingTranscriptionStatus'?: VoiceCallBlockAllOfRecordingTranscriptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallBlockAllOf
     */
    'recordingFlowStatus'?: VoiceCallBlockAllOfRecordingFlowStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof VoiceCallBlockAllOf
     */
    'recordingAccessible'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceCallBlockAllOfDirectionEnum {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallBlockAllOfRecordingTypeEnum {
    Call = 'CALL',
    Voicemail = 'VOICEMAIL'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallBlockAllOfRecordingTranscriptionStatusEnum {
    Success = 'SUCCESS',
    Failure = 'FAILURE',
    NoOutcome = 'NO_OUTCOME'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallBlockAllOfRecordingFlowStatusEnum {
    Cancelled = 'CANCELLED',
    RecordingInProgress = 'RECORDING_IN_PROGRESS',
    RecordingUnavailable = 'RECORDING_UNAVAILABLE',
    TranscriptionInProgress = 'TRANSCRIPTION_IN_PROGRESS',
    TranscriptAvailable = 'TRANSCRIPT_AVAILABLE',
    TranscriptUnavailable = 'TRANSCRIPT_UNAVAILABLE'
}

/**
 * 
 * @export
 * @interface VoiceCallDto
 */
export interface VoiceCallDto {
    /**
     * 
     * @type {VoiceCallId}
     * @memberof VoiceCallDto
     */
    'id': VoiceCallId;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof VoiceCallDto
     */
    'virtualNumber': PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'callCode': string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'dialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'containerType'?: VoiceCallDtoContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'containerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'direction': VoiceCallDtoDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'callerCallStatus'?: VoiceCallDtoCallerCallStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'callerCallSid'?: string;
    /**
     * 
     * @type {CallParticipantDto}
     * @memberof VoiceCallDto
     */
    'caller': CallParticipantDto;
    /**
     * 
     * @type {VoiceCallTarget}
     * @memberof VoiceCallDto
     */
    'target': VoiceCallTarget;
    /**
     * 
     * @type {VoiceCallTargetMembers}
     * @memberof VoiceCallDto
     */
    'targetMembers': VoiceCallTargetMembers;
    /**
     * 
     * @type {CallParticipantDto}
     * @memberof VoiceCallDto
     */
    'callee'?: CallParticipantDto;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'internalParticipantId'?: string;
    /**
     * 
     * @type {Array<VoiceCalleeDto>}
     * @memberof VoiceCallDto
     */
    'callees'?: Array<VoiceCalleeDto>;
    /**
     * 
     * @type {VoiceRecordingDto}
     * @memberof VoiceCallDto
     */
    'recording'?: VoiceRecordingDto;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'callerGroupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'callerGroupName'?: VoiceCallDtoCallerGroupNameEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'conferenceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'conferenceSid'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'callerParticipantStatus'?: VoiceCallDtoCallerParticipantStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'callerLeftReason'?: VoiceCallDtoCallerLeftReasonEnum;
    /**
     * 
     * @type {boolean}
     * @memberof VoiceCallDto
     */
    'callerCancelledCall'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'appSource'?: VoiceCallDtoAppSourceEnum;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallDto
     */
    'callerCalledAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallDto
     */
    'callerConnectedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallDto
     */
    'callerConnectedDurationMillis'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'recordingFlowStatus'?: VoiceCallDtoRecordingFlowStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallDto
     */
    'callerCompletedCallAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'commentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallDto
     */
    'createdAt'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceCallDtoContainerTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallDtoDirectionEnum {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallDtoCallerCallStatusEnum {
    Pending = 'PENDING',
    Dialing = 'DIALING',
    Queued = 'QUEUED',
    Initiated = 'INITIATED',
    Ringing = 'RINGING',
    Connected = 'CONNECTED',
    InProgress = 'IN_PROGRESS',
    Busy = 'BUSY',
    Failed = 'FAILED',
    Noanswer = 'NOANSWER',
    Canceled = 'CANCELED',
    Answered = 'ANSWERED',
    Undialed = 'UNDIALED',
    Completed = 'COMPLETED'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallDtoCallerGroupNameEnum {
    BrokerTeam = 'BROKER_TEAM',
    FinanceTeam = 'FINANCE_TEAM',
    SupportTeam = 'SUPPORT_TEAM',
    TransactionTeam = 'TRANSACTION_TEAM'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallDtoCallerParticipantStatusEnum {
    NoAnswer = 'NO_ANSWER',
    Busy = 'BUSY',
    InProgress = 'IN_PROGRESS',
    Failed = 'FAILED',
    Canceled = 'CANCELED',
    Completed = 'COMPLETED'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallDtoCallerLeftReasonEnum {
    ConfEndedViaApi = 'CONF_ENDED_VIA_API',
    ModeratorEndedConf = 'MODERATOR_ENDED_CONF',
    UpdatedViaApi = 'UPDATED_VIA_API',
    HungUp = 'HUNG_UP',
    AddFailed = 'ADD_FAILED'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallDtoAppSourceEnum {
    Desktop = 'DESKTOP',
    Ios = 'IOS',
    Android = 'ANDROID'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallDtoRecordingFlowStatusEnum {
    Cancelled = 'CANCELLED',
    RecordingInProgress = 'RECORDING_IN_PROGRESS',
    RecordingUnavailable = 'RECORDING_UNAVAILABLE',
    TranscriptionInProgress = 'TRANSCRIPTION_IN_PROGRESS',
    TranscriptAvailable = 'TRANSCRIPT_AVAILABLE',
    TranscriptUnavailable = 'TRANSCRIPT_UNAVAILABLE'
}

/**
 * 
 * @export
 * @interface VoiceCallId
 */
export interface VoiceCallId {
    /**
     * 
     * @type {string}
     * @memberof VoiceCallId
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface VoiceCallLiteDto
 */
export interface VoiceCallLiteDto {
    /**
     * 
     * @type {VoiceCallId}
     * @memberof VoiceCallLiteDto
     */
    'id': VoiceCallId;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallLiteDto
     */
    'containerType'?: VoiceCallLiteDtoContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallLiteDto
     */
    'containerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallLiteDto
     */
    'direction': VoiceCallLiteDtoDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallLiteDto
     */
    'callerCallStatus'?: VoiceCallLiteDtoCallerCallStatusEnum;
    /**
     * 
     * @type {CallParticipantDto}
     * @memberof VoiceCallLiteDto
     */
    'caller': CallParticipantDto;
    /**
     * 
     * @type {CallParticipantDto}
     * @memberof VoiceCallLiteDto
     */
    'callee'?: CallParticipantDto;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallLiteDto
     */
    'internalParticipantId'?: string;
    /**
     * 
     * @type {VoiceRecordingLiteDto}
     * @memberof VoiceCallLiteDto
     */
    'recording'?: VoiceRecordingLiteDto;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallLiteDto
     */
    'callerGroupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallLiteDto
     */
    'callerGroupName'?: VoiceCallLiteDtoCallerGroupNameEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallLiteDto
     */
    'callerParticipantStatus'?: VoiceCallLiteDtoCallerParticipantStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallLiteDto
     */
    'callerLeftReason'?: VoiceCallLiteDtoCallerLeftReasonEnum;
    /**
     * 
     * @type {boolean}
     * @memberof VoiceCallLiteDto
     */
    'callerCancelledCall'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallLiteDto
     */
    'appSource'?: VoiceCallLiteDtoAppSourceEnum;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallLiteDto
     */
    'callerCalledAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallLiteDto
     */
    'callerConnectedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallLiteDto
     */
    'callerConnectedDurationMillis'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallLiteDto
     */
    'callerCompletedCallAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallLiteDto
     */
    'createdAt'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceCallLiteDtoContainerTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallLiteDtoDirectionEnum {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallLiteDtoCallerCallStatusEnum {
    Pending = 'PENDING',
    Dialing = 'DIALING',
    Queued = 'QUEUED',
    Initiated = 'INITIATED',
    Ringing = 'RINGING',
    Connected = 'CONNECTED',
    InProgress = 'IN_PROGRESS',
    Busy = 'BUSY',
    Failed = 'FAILED',
    Noanswer = 'NOANSWER',
    Canceled = 'CANCELED',
    Answered = 'ANSWERED',
    Undialed = 'UNDIALED',
    Completed = 'COMPLETED'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallLiteDtoCallerGroupNameEnum {
    BrokerTeam = 'BROKER_TEAM',
    FinanceTeam = 'FINANCE_TEAM',
    SupportTeam = 'SUPPORT_TEAM',
    TransactionTeam = 'TRANSACTION_TEAM'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallLiteDtoCallerParticipantStatusEnum {
    NoAnswer = 'NO_ANSWER',
    Busy = 'BUSY',
    InProgress = 'IN_PROGRESS',
    Failed = 'FAILED',
    Canceled = 'CANCELED',
    Completed = 'COMPLETED'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallLiteDtoCallerLeftReasonEnum {
    ConfEndedViaApi = 'CONF_ENDED_VIA_API',
    ModeratorEndedConf = 'MODERATOR_ENDED_CONF',
    UpdatedViaApi = 'UPDATED_VIA_API',
    HungUp = 'HUNG_UP',
    AddFailed = 'ADD_FAILED'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallLiteDtoAppSourceEnum {
    Desktop = 'DESKTOP',
    Ios = 'IOS',
    Android = 'ANDROID'
}

/**
 * 
 * @export
 * @interface VoiceCallTarget
 */
export interface VoiceCallTarget {
    /**
     * 
     * @type {string}
     * @memberof VoiceCallTarget
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallTarget
     */
    'type': VoiceCallTargetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallTarget
     */
    'name'?: string;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof VoiceCallTarget
     */
    'phone'?: PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallTarget
     */
    'officeId'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallTarget
     */
    'holdTimeSecs'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceCallTargetTypeEnum {
    Group = 'GROUP',
    User = 'USER'
}

/**
 * 
 * @export
 * @interface VoiceCallTargetMember
 */
export interface VoiceCallTargetMember {
    /**
     * 
     * @type {string}
     * @memberof VoiceCallTargetMember
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallTargetMember
     */
    'userId': string;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof VoiceCallTargetMember
     */
    'phone'?: PhoneNumber;
    /**
     * 
     * @type {EmailAddress}
     * @memberof VoiceCallTargetMember
     */
    'email'?: EmailAddress;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallTargetMember
     */
    'groupRole'?: VoiceCallTargetMemberGroupRoleEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceCallTargetMemberGroupRoleEnum {
    DesignatedBroker = 'DESIGNATED_BROKER',
    RegionalBroker = 'REGIONAL_BROKER',
    ManagingBroker = 'MANAGING_BROKER',
    SettlementSpecialist = 'SETTLEMENT_SPECIALIST',
    FinanceSpecialist = 'FINANCE_SPECIALIST',
    ContractSpecialist = 'CONTRACT_SPECIALIST',
    AgentExperience = 'AGENT_EXPERIENCE',
    None = 'NONE'
}

/**
 * 
 * @export
 * @interface VoiceCallTargetMembers
 */
export interface VoiceCallTargetMembers {
    /**
     * 
     * @type {Array<VoiceCallTargetMember>}
     * @memberof VoiceCallTargetMembers
     */
    'members'?: Array<VoiceCallTargetMember>;
}
/**
 * 
 * @export
 * @interface VoiceCalleeDto
 */
export interface VoiceCalleeDto {
    /**
     * 
     * @type {VoiceCalleeId}
     * @memberof VoiceCalleeDto
     */
    'id': VoiceCalleeId;
    /**
     * 
     * @type {string}
     * @memberof VoiceCalleeDto
     */
    'callSid'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCalleeDto
     */
    'callStatus'?: VoiceCalleeDtoCallStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCalleeDto
     */
    'participantStatus'?: VoiceCalleeDtoParticipantStatusEnum;
    /**
     * 
     * @type {CallParticipantDto}
     * @memberof VoiceCalleeDto
     */
    'callee': CallParticipantDto;
    /**
     * 
     * @type {string}
     * @memberof VoiceCalleeDto
     */
    'gatheredEntry'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceCalleeDto
     */
    'gatherEntryRetryCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCalleeDto
     */
    'gatherEntryRedirectCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VoiceCalleeDto
     */
    'maxGatherEntryRedirectExceeded'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VoiceCalleeDto
     */
    'gatherAction'?: VoiceCalleeDtoGatherActionEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCalleeDto
     */
    'leftReason'?: VoiceCalleeDtoLeftReasonEnum;
    /**
     * 
     * @type {boolean}
     * @memberof VoiceCalleeDto
     */
    'entryGathered'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoiceCalleeDto
     */
    'dialedInvalidPhone'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VoiceCalleeDto
     */
    'connectedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCalleeDto
     */
    'connectedDurationMillis'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceCalleeDto
     */
    'createdAt'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceCalleeDtoCallStatusEnum {
    Pending = 'PENDING',
    Dialing = 'DIALING',
    Queued = 'QUEUED',
    Initiated = 'INITIATED',
    Ringing = 'RINGING',
    Connected = 'CONNECTED',
    InProgress = 'IN_PROGRESS',
    Busy = 'BUSY',
    Failed = 'FAILED',
    Noanswer = 'NOANSWER',
    Canceled = 'CANCELED',
    Answered = 'ANSWERED',
    Undialed = 'UNDIALED',
    Completed = 'COMPLETED'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCalleeDtoParticipantStatusEnum {
    NoAnswer = 'NO_ANSWER',
    Busy = 'BUSY',
    InProgress = 'IN_PROGRESS',
    Failed = 'FAILED',
    Canceled = 'CANCELED',
    Completed = 'COMPLETED'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCalleeDtoGatherActionEnum {
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED',
    SentToVoicemail = 'SENT_TO_VOICEMAIL',
    InvalidEntry = 'INVALID_ENTRY',
    NoAnswer = 'NO_ANSWER'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCalleeDtoLeftReasonEnum {
    ConfEndedViaApi = 'CONF_ENDED_VIA_API',
    ModeratorEndedConf = 'MODERATOR_ENDED_CONF',
    UpdatedViaApi = 'UPDATED_VIA_API',
    HungUp = 'HUNG_UP',
    AddFailed = 'ADD_FAILED'
}

/**
 * 
 * @export
 * @interface VoiceCalleeId
 */
export interface VoiceCalleeId {
    /**
     * 
     * @type {string}
     * @memberof VoiceCalleeId
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface VoiceRecordingDto
 */
export interface VoiceRecordingDto {
    /**
     * 
     * @type {VoiceRecordingId}
     * @memberof VoiceRecordingDto
     */
    'id': VoiceRecordingId;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'callSid'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'recordingSid'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'originalRecordingUrl': string;
    /**
     * 
     * @type {DropboxFileId}
     * @memberof VoiceRecordingDto
     */
    'dropboxFileId'?: DropboxFileId;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'signedRecordingUrl': string;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'startTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingDto
     */
    'durationSecs'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingDto
     */
    'errorCode'?: number;
    /**
     * 
     * @type {VoiceCallId}
     * @memberof VoiceRecordingDto
     */
    'voiceCallId': VoiceCallId;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'source'?: VoiceRecordingDtoSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'status'?: VoiceRecordingDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'type': VoiceRecordingDtoTypeEnum;
    /**
     * 
     * @type {Summary}
     * @memberof VoiceRecordingDto
     */
    'summary'?: Summary;
    /**
     * 
     * @type {Speakers}
     * @memberof VoiceRecordingDto
     */
    'speakers'?: Speakers;
    /**
     * 
     * @type {Transcript}
     * @memberof VoiceRecordingDto
     */
    'transcript'?: Transcript;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'transcriptionStatus'?: VoiceRecordingDtoTranscriptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'transcriptionUrl'?: string;
    /**
     * 
     * @type {Speakers}
     * @memberof VoiceRecordingDto
     */
    'previousSpeakers'?: Speakers;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingDto
     */
    'speakersUpdatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'speakersUpdatedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingDto
     */
    'unidentifiedSpeakers'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingDto
     */
    'createdAt'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceRecordingDtoSourceEnum {
    Dialverb = 'DIALVERB',
    Conference = 'CONFERENCE',
    Outboundapi = 'OUTBOUNDAPI',
    Trunking = 'TRUNKING',
    Recordverb = 'RECORDVERB',
    Startcallrecordingapi = 'STARTCALLRECORDINGAPI',
    Startconferencerecordingapi = 'STARTCONFERENCERECORDINGAPI'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceRecordingDtoStatusEnum {
    InProgress = 'IN_PROGRESS',
    Paused = 'PAUSED',
    Stopped = 'STOPPED',
    Processing = 'PROCESSING',
    Completed = 'COMPLETED',
    Absent = 'ABSENT'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceRecordingDtoTypeEnum {
    Call = 'CALL',
    Voicemail = 'VOICEMAIL'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceRecordingDtoTranscriptionStatusEnum {
    Success = 'SUCCESS',
    Failure = 'FAILURE',
    NoOutcome = 'NO_OUTCOME'
}

/**
 * 
 * @export
 * @interface VoiceRecordingId
 */
export interface VoiceRecordingId {
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingId
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface VoiceRecordingLiteDto
 */
export interface VoiceRecordingLiteDto {
    /**
     * 
     * @type {VoiceRecordingId}
     * @memberof VoiceRecordingLiteDto
     */
    'id': VoiceRecordingId;
    /**
     * 
     * @type {DropboxFileId}
     * @memberof VoiceRecordingLiteDto
     */
    'dropboxFileId'?: DropboxFileId;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingLiteDto
     */
    'startTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingLiteDto
     */
    'durationSecs'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingLiteDto
     */
    'errorCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingLiteDto
     */
    'status'?: VoiceRecordingLiteDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingLiteDto
     */
    'type': VoiceRecordingLiteDtoTypeEnum;
    /**
     * 
     * @type {Summary}
     * @memberof VoiceRecordingLiteDto
     */
    'summary'?: Summary;
    /**
     * 
     * @type {Speakers}
     * @memberof VoiceRecordingLiteDto
     */
    'speakers'?: Speakers;
    /**
     * 
     * @type {Transcript}
     * @memberof VoiceRecordingLiteDto
     */
    'transcript'?: Transcript;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingLiteDto
     */
    'transcriptionStatus'?: VoiceRecordingLiteDtoTranscriptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingLiteDto
     */
    'transcriptionUrl'?: string;
    /**
     * 
     * @type {Speakers}
     * @memberof VoiceRecordingLiteDto
     */
    'previousSpeakers'?: Speakers;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingLiteDto
     */
    'speakersUpdatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingLiteDto
     */
    'speakersUpdatedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingLiteDto
     */
    'unidentifiedSpeakers'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingLiteDto
     */
    'createdAt'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceRecordingLiteDtoStatusEnum {
    InProgress = 'IN_PROGRESS',
    Paused = 'PAUSED',
    Stopped = 'STOPPED',
    Processing = 'PROCESSING',
    Completed = 'COMPLETED',
    Absent = 'ABSENT'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceRecordingLiteDtoTypeEnum {
    Call = 'CALL',
    Voicemail = 'VOICEMAIL'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceRecordingLiteDtoTranscriptionStatusEnum {
    Success = 'SUCCESS',
    Failure = 'FAILURE',
    NoOutcome = 'NO_OUTCOME'
}

/**
 * 
 * @export
 * @interface VoiceRecordingTranscription
 */
export interface VoiceRecordingTranscription {
    /**
     * 
     * @type {Array<Speaker>}
     * @memberof VoiceRecordingTranscription
     */
    'speakers'?: Array<Speaker>;
    /**
     * 
     * @type {Summary}
     * @memberof VoiceRecordingTranscription
     */
    'summary'?: Summary;
    /**
     * 
     * @type {Transcript}
     * @memberof VoiceRecordingTranscription
     */
    'transcript'?: Transcript;
    /**
     * 
     * @type {AudioInfo}
     * @memberof VoiceRecordingTranscription
     */
    'audioInfo'?: AudioInfo;
}
/**
 * 
 * @export
 * @interface VoiceVirtualNumberDto
 */
export interface VoiceVirtualNumberDto {
    /**
     * 
     * @type {VoiceVirtualNumberId}
     * @memberof VoiceVirtualNumberDto
     */
    'id': VoiceVirtualNumberId;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof VoiceVirtualNumberDto
     */
    'phone': PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof VoiceVirtualNumberDto
     */
    'groupName': VoiceVirtualNumberDtoGroupNameEnum;
    /**
     * 
     * @type {number}
     * @memberof VoiceVirtualNumberDto
     */
    'deactivatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceVirtualNumberDto
     */
    'deactivatedBy'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceVirtualNumberDtoGroupNameEnum {
    BrokerTeam = 'BROKER_TEAM',
    FinanceTeam = 'FINANCE_TEAM',
    SupportTeam = 'SUPPORT_TEAM',
    TransactionTeam = 'TRANSACTION_TEAM'
}

/**
 * 
 * @export
 * @interface VoiceVirtualNumberId
 */
export interface VoiceVirtualNumberId {
    /**
     * 
     * @type {string}
     * @memberof VoiceVirtualNumberId
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface WrapperBaseMessage
 */
export interface WrapperBaseMessage {
    /**
     * 
     * @type {BaseMessage}
     * @memberof WrapperBaseMessage
     */
    'message'?: BaseMessage;
    /**
     * 
     * @type {string}
     * @memberof WrapperBaseMessage
     */
    '_class'?: string;
}
/**
 * 
 * @export
 * @interface YadaMediaFileId
 */
export interface YadaMediaFileId {
    /**
     * 
     * @type {string}
     * @memberof YadaMediaFileId
     */
    'id': string;
}

/**
 * CommentControllerApi - axios parameter creator
 * @export
 */
export const CommentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a comment by providing its container (type and id) and the comment id itself
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('deleteComment', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('deleteComment', 'containerId', containerId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteComment', 'commentId', commentId)
            const localVarPath = `/api/v1/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }

            if (commentId !== undefined) {
                localVarQueryParameter['commentId'] = commentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find the authorized users that can see the provided comment
         * @param {string} commentId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizedUsersForComment: async (commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getAuthorizedUsersForComment', 'commentId', commentId)
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('getAuthorizedUsersForComment', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('getAuthorizedUsersForComment', 'containerId', containerId)
            const localVarPath = `/api/v1/comments/{commentId}/authorized-users`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find the notifiable users for the provided comment
         * @param {string} commentId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentListeners: async (commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getCommentListeners', 'commentId', commentId)
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('getCommentListeners', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('getCommentListeners', 'containerId', containerId)
            const localVarPath = `/api/v1/comments/{commentId}/notifiable-users`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all comments within a particular Rezen container entity (such as a Transaction), including any comments on Rezen reference entities owned by the container (such as Checklist Items). Comments are returned in descending order of creation date
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByContainer: async (containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('getCommentsByContainer', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('getCommentsByContainer', 'containerId', containerId)
            const localVarPath = `/api/v1/commentsByContainer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageStart !== undefined) {
                localVarQueryParameter['pageStart'] = pageStart;
            }

            if (authorType !== undefined) {
                localVarQueryParameter['authorType'] = authorType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all comments on a particular Rezen reference entity (such as a Checklist Item) owned by the container object. This endpoint queries on Dynamo\'s secondary index and returns comments in descending order of creation date
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} refersToType 
         * @param {string} refersToId 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByReference: async (refersToType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refersToType' is not null or undefined
            assertParamExists('getCommentsByReference', 'refersToType', refersToType)
            // verify required parameter 'refersToId' is not null or undefined
            assertParamExists('getCommentsByReference', 'refersToId', refersToId)
            const localVarPath = `/api/v1/commentsByReference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (refersToType !== undefined) {
                localVarQueryParameter['refersToType'] = refersToType;
            }

            if (refersToId !== undefined) {
                localVarQueryParameter['refersToId'] = refersToId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageStart !== undefined) {
                localVarQueryParameter['pageStart'] = pageStart;
            }

            if (authorType !== undefined) {
                localVarQueryParameter['authorType'] = authorType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the number of comments on each provided Rezen reference entity (such as a Checklist Item).Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
         * @param {Array<string>} refersToTargets 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByReferenceCount: async (refersToTargets: Array<string>, authorType?: 'USER' | 'SYSTEM', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refersToTargets' is not null or undefined
            assertParamExists('getCommentsByReferenceCount', 'refersToTargets', refersToTargets)
            const localVarPath = `/api/v1/commentsByReferenceCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (refersToTargets) {
                localVarQueryParameter['refersToTargets'] = Array.from(refersToTargets);
            }

            if (authorType !== undefined) {
                localVarQueryParameter['authorType'] = authorType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the latest comment for each provided Rezen reference entity (such as a Checklist Item). Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
         * @param {Array<RezenObject>} rezenObject 
         * @param {number} [limit] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestCommentsByReference: async (rezenObject: Array<RezenObject>, limit?: number, authorType?: 'USER' | 'SYSTEM', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rezenObject' is not null or undefined
            assertParamExists('getLatestCommentsByReference', 'rezenObject', rezenObject)
            const localVarPath = `/api/v1/latestCommentsByReference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (authorType !== undefined) {
                localVarQueryParameter['authorType'] = authorType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rezenObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post a new comment. Container fields (id and type) are required.
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'USER' | 'SYSTEM'} authorType 
         * @param {PostCommentBody} postCommentBody 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {string} [refersToName] 
         * @param {string} [callerGroupId] 
         * @param {boolean} [triggerActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postComment: async (containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, authorType: 'USER' | 'SYSTEM', postCommentBody: PostCommentBody, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, refersToName?: string, callerGroupId?: string, triggerActivity?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('postComment', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('postComment', 'containerId', containerId)
            // verify required parameter 'authorType' is not null or undefined
            assertParamExists('postComment', 'authorType', authorType)
            // verify required parameter 'postCommentBody' is not null or undefined
            assertParamExists('postComment', 'postCommentBody', postCommentBody)
            const localVarPath = `/api/v1/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }

            if (refersToType !== undefined) {
                localVarQueryParameter['refersToType'] = refersToType;
            }

            if (refersToId !== undefined) {
                localVarQueryParameter['refersToId'] = refersToId;
            }

            if (refersToName !== undefined) {
                localVarQueryParameter['refersToName'] = refersToName;
            }

            if (callerGroupId !== undefined) {
                localVarQueryParameter['callerGroupId'] = callerGroupId;
            }

            if (authorType !== undefined) {
                localVarQueryParameter['authorType'] = authorType;
            }

            if (triggerActivity !== undefined) {
                localVarQueryParameter['triggerActivity'] = triggerActivity;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCommentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post a batch of comments. Container fields (id and type) are required.
         * @param {BatchCommentRequest} batchCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsBatch: async (batchCommentRequest: BatchCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchCommentRequest' is not null or undefined
            assertParamExists('postCommentsBatch', 'batchCommentRequest', batchCommentRequest)
            const localVarPath = `/api/v1/comments-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe to Server-Sent Events for comments
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribe1: async (containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('subscribe1', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('subscribe1', 'containerId', containerId)
            const localVarPath = `/api/v1/comments/sse-subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }

            if (refersToType !== undefined) {
                localVarQueryParameter['refersToType'] = refersToType;
            }

            if (refersToId !== undefined) {
                localVarQueryParameter['refersToId'] = refersToId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a comment by providing its container (type and id) and the comment id itself
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {string} commentId 
         * @param {UpdateCommentBody} updateCommentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment: async (containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, updateCommentBody: UpdateCommentBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('updateComment', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('updateComment', 'containerId', containerId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('updateComment', 'commentId', commentId)
            // verify required parameter 'updateCommentBody' is not null or undefined
            assertParamExists('updateComment', 'updateCommentBody', updateCommentBody)
            const localVarPath = `/api/v1/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }

            if (commentId !== undefined) {
                localVarQueryParameter['commentId'] = commentId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a media-file in a comment.
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'USER' | 'SYSTEM'} authorType 
         * @param {'AUDIO' | 'VIDEO' | 'DOCUMENT' | 'IMAGE'} mediaType 
         * @param {Array<UploadMediaRichBlocksParameterInner>} richBlocks 
         * @param {File} file 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {string} [refersToName] 
         * @param {string} [callerGroupId] 
         * @param {boolean} [triggerActivity] 
         * @param {'USER' | 'ADMIN' | 'PUBLIC'} [readerRole] 
         * @param {string} [fileDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMedia: async (containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, authorType: 'USER' | 'SYSTEM', mediaType: 'AUDIO' | 'VIDEO' | 'DOCUMENT' | 'IMAGE', richBlocks: Array<UploadMediaRichBlocksParameterInner>, file: File, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, refersToName?: string, callerGroupId?: string, triggerActivity?: boolean, readerRole?: 'USER' | 'ADMIN' | 'PUBLIC', fileDescription?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('uploadMedia', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('uploadMedia', 'containerId', containerId)
            // verify required parameter 'authorType' is not null or undefined
            assertParamExists('uploadMedia', 'authorType', authorType)
            // verify required parameter 'mediaType' is not null or undefined
            assertParamExists('uploadMedia', 'mediaType', mediaType)
            // verify required parameter 'richBlocks' is not null or undefined
            assertParamExists('uploadMedia', 'richBlocks', richBlocks)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadMedia', 'file', file)
            const localVarPath = `/api/v1/comments/upload-media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }

            if (refersToType !== undefined) {
                localVarQueryParameter['refersToType'] = refersToType;
            }

            if (refersToId !== undefined) {
                localVarQueryParameter['refersToId'] = refersToId;
            }

            if (refersToName !== undefined) {
                localVarQueryParameter['refersToName'] = refersToName;
            }

            if (callerGroupId !== undefined) {
                localVarQueryParameter['callerGroupId'] = callerGroupId;
            }

            if (authorType !== undefined) {
                localVarQueryParameter['authorType'] = authorType;
            }

            if (triggerActivity !== undefined) {
                localVarQueryParameter['triggerActivity'] = triggerActivity;
            }

            if (mediaType !== undefined) {
                localVarQueryParameter['mediaType'] = mediaType;
            }

            if (readerRole !== undefined) {
                localVarQueryParameter['readerRole'] = readerRole;
            }

            if (fileDescription !== undefined) {
                localVarQueryParameter['fileDescription'] = fileDescription;
            }

            if (richBlocks) {
                localVarQueryParameter['richBlocks'] = richBlocks;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentControllerApi - functional programming interface
 * @export
 */
export const CommentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a comment by providing its container (type and id) and the comment id itself
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(containerType, containerId, commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find the authorized users that can see the provided comment
         * @param {string} commentId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthorizedUsersForComment(commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthorizedUsersForComment(commentId, containerType, containerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find the notifiable users for the provided comment
         * @param {string} commentId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentListeners(commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotifiableUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentListeners(commentId, containerType, containerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all comments within a particular Rezen container entity (such as a Transaction), including any comments on Rezen reference entities owned by the container (such as Checklist Items). Comments are returned in descending order of creation date
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsByContainer(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryCommentsRs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsByContainer(containerType, containerId, pageSize, pageStart, authorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all comments on a particular Rezen reference entity (such as a Checklist Item) owned by the container object. This endpoint queries on Dynamo\'s secondary index and returns comments in descending order of creation date
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} refersToType 
         * @param {string} refersToId 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsByReference(refersToType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryCommentsRs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsByReference(refersToType, refersToId, pageSize, pageStart, authorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the number of comments on each provided Rezen reference entity (such as a Checklist Item).Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
         * @param {Array<string>} refersToTargets 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsByReferenceCount(refersToTargets: Array<string>, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentCountRs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsByReferenceCount(refersToTargets, authorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the latest comment for each provided Rezen reference entity (such as a Checklist Item). Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
         * @param {Array<RezenObject>} rezenObject 
         * @param {number} [limit] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestCommentsByReference(rezenObject: Array<RezenObject>, limit?: number, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestCommentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestCommentsByReference(rezenObject, limit, authorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post a new comment. Container fields (id and type) are required.
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'USER' | 'SYSTEM'} authorType 
         * @param {PostCommentBody} postCommentBody 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {string} [refersToName] 
         * @param {string} [callerGroupId] 
         * @param {boolean} [triggerActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, authorType: 'USER' | 'SYSTEM', postCommentBody: PostCommentBody, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, refersToName?: string, callerGroupId?: string, triggerActivity?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postComment(containerType, containerId, authorType, postCommentBody, refersToType, refersToId, refersToName, callerGroupId, triggerActivity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post a batch of comments. Container fields (id and type) are required.
         * @param {BatchCommentRequest} batchCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCommentsBatch(batchCommentRequest: BatchCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchCommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCommentsBatch(batchCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Subscribe to Server-Sent Events for comments
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribe1(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribe1(containerType, containerId, refersToType, refersToId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a comment by providing its container (type and id) and the comment id itself
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {string} commentId 
         * @param {UpdateCommentBody} updateCommentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, updateCommentBody: UpdateCommentBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateComment(containerType, containerId, commentId, updateCommentBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a media-file in a comment.
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'USER' | 'SYSTEM'} authorType 
         * @param {'AUDIO' | 'VIDEO' | 'DOCUMENT' | 'IMAGE'} mediaType 
         * @param {Array<UploadMediaRichBlocksParameterInner>} richBlocks 
         * @param {File} file 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {string} [refersToName] 
         * @param {string} [callerGroupId] 
         * @param {boolean} [triggerActivity] 
         * @param {'USER' | 'ADMIN' | 'PUBLIC'} [readerRole] 
         * @param {string} [fileDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMedia(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, authorType: 'USER' | 'SYSTEM', mediaType: 'AUDIO' | 'VIDEO' | 'DOCUMENT' | 'IMAGE', richBlocks: Array<UploadMediaRichBlocksParameterInner>, file: File, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, refersToName?: string, callerGroupId?: string, triggerActivity?: boolean, readerRole?: 'USER' | 'ADMIN' | 'PUBLIC', fileDescription?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMedia(containerType, containerId, authorType, mediaType, richBlocks, file, refersToType, refersToId, refersToName, callerGroupId, triggerActivity, readerRole, fileDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommentControllerApi - factory interface
 * @export
 */
export const CommentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a comment by providing its container (type and id) and the comment id itself
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteComment(containerType, containerId, commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find the authorized users that can see the provided comment
         * @param {string} commentId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizedUsersForComment(commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options?: any): AxiosPromise<AuthorizedUsers> {
            return localVarFp.getAuthorizedUsersForComment(commentId, containerType, containerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find the notifiable users for the provided comment
         * @param {string} commentId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentListeners(commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options?: any): AxiosPromise<NotifiableUsers> {
            return localVarFp.getCommentListeners(commentId, containerType, containerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all comments within a particular Rezen container entity (such as a Transaction), including any comments on Rezen reference entities owned by the container (such as Checklist Items). Comments are returned in descending order of creation date
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByContainer(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options?: any): AxiosPromise<QueryCommentsRs> {
            return localVarFp.getCommentsByContainer(containerType, containerId, pageSize, pageStart, authorType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all comments on a particular Rezen reference entity (such as a Checklist Item) owned by the container object. This endpoint queries on Dynamo\'s secondary index and returns comments in descending order of creation date
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} refersToType 
         * @param {string} refersToId 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByReference(refersToType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options?: any): AxiosPromise<QueryCommentsRs> {
            return localVarFp.getCommentsByReference(refersToType, refersToId, pageSize, pageStart, authorType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the number of comments on each provided Rezen reference entity (such as a Checklist Item).Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
         * @param {Array<string>} refersToTargets 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByReferenceCount(refersToTargets: Array<string>, authorType?: 'USER' | 'SYSTEM', options?: any): AxiosPromise<CommentCountRs> {
            return localVarFp.getCommentsByReferenceCount(refersToTargets, authorType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the latest comment for each provided Rezen reference entity (such as a Checklist Item). Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
         * @param {Array<RezenObject>} rezenObject 
         * @param {number} [limit] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestCommentsByReference(rezenObject: Array<RezenObject>, limit?: number, authorType?: 'USER' | 'SYSTEM', options?: any): AxiosPromise<LatestCommentsResponse> {
            return localVarFp.getLatestCommentsByReference(rezenObject, limit, authorType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post a new comment. Container fields (id and type) are required.
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'USER' | 'SYSTEM'} authorType 
         * @param {PostCommentBody} postCommentBody 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {string} [refersToName] 
         * @param {string} [callerGroupId] 
         * @param {boolean} [triggerActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, authorType: 'USER' | 'SYSTEM', postCommentBody: PostCommentBody, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, refersToName?: string, callerGroupId?: string, triggerActivity?: boolean, options?: any): AxiosPromise<CommentDto> {
            return localVarFp.postComment(containerType, containerId, authorType, postCommentBody, refersToType, refersToId, refersToName, callerGroupId, triggerActivity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post a batch of comments. Container fields (id and type) are required.
         * @param {BatchCommentRequest} batchCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsBatch(batchCommentRequest: BatchCommentRequest, options?: any): AxiosPromise<BatchCommentResponse> {
            return localVarFp.postCommentsBatch(batchCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Subscribe to Server-Sent Events for comments
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribe1(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, options?: any): AxiosPromise<SseEmitter> {
            return localVarFp.subscribe1(containerType, containerId, refersToType, refersToId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a comment by providing its container (type and id) and the comment id itself
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {string} commentId 
         * @param {UpdateCommentBody} updateCommentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, updateCommentBody: UpdateCommentBody, options?: any): AxiosPromise<CommentDto> {
            return localVarFp.updateComment(containerType, containerId, commentId, updateCommentBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a media-file in a comment.
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'USER' | 'SYSTEM'} authorType 
         * @param {'AUDIO' | 'VIDEO' | 'DOCUMENT' | 'IMAGE'} mediaType 
         * @param {Array<UploadMediaRichBlocksParameterInner>} richBlocks 
         * @param {File} file 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {string} [refersToName] 
         * @param {string} [callerGroupId] 
         * @param {boolean} [triggerActivity] 
         * @param {'USER' | 'ADMIN' | 'PUBLIC'} [readerRole] 
         * @param {string} [fileDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMedia(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, authorType: 'USER' | 'SYSTEM', mediaType: 'AUDIO' | 'VIDEO' | 'DOCUMENT' | 'IMAGE', richBlocks: Array<UploadMediaRichBlocksParameterInner>, file: File, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, refersToName?: string, callerGroupId?: string, triggerActivity?: boolean, readerRole?: 'USER' | 'ADMIN' | 'PUBLIC', fileDescription?: string, options?: any): AxiosPromise<CommentDto> {
            return localVarFp.uploadMedia(containerType, containerId, authorType, mediaType, richBlocks, file, refersToType, refersToId, refersToName, callerGroupId, triggerActivity, readerRole, fileDescription, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentControllerApi - object-oriented interface
 * @export
 * @class CommentControllerApi
 * @extends {BaseAPI}
 */
export class CommentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Delete a comment by providing its container (type and id) and the comment id itself
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentControllerApi
     */
    public deleteComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, options?: AxiosRequestConfig) {
        return CommentControllerApiFp(this.configuration).deleteComment(containerType, containerId, commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find the authorized users that can see the provided comment
     * @param {string} commentId 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentControllerApi
     */
    public getAuthorizedUsersForComment(commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options?: AxiosRequestConfig) {
        return CommentControllerApiFp(this.configuration).getAuthorizedUsersForComment(commentId, containerType, containerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find the notifiable users for the provided comment
     * @param {string} commentId 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentControllerApi
     */
    public getCommentListeners(commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options?: AxiosRequestConfig) {
        return CommentControllerApiFp(this.configuration).getCommentListeners(commentId, containerType, containerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all comments within a particular Rezen container entity (such as a Transaction), including any comments on Rezen reference entities owned by the container (such as Checklist Items). Comments are returned in descending order of creation date
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {number} [pageSize] 
     * @param {string} [pageStart] 
     * @param {'USER' | 'SYSTEM'} [authorType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentControllerApi
     */
    public getCommentsByContainer(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig) {
        return CommentControllerApiFp(this.configuration).getCommentsByContainer(containerType, containerId, pageSize, pageStart, authorType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all comments on a particular Rezen reference entity (such as a Checklist Item) owned by the container object. This endpoint queries on Dynamo\'s secondary index and returns comments in descending order of creation date
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} refersToType 
     * @param {string} refersToId 
     * @param {number} [pageSize] 
     * @param {string} [pageStart] 
     * @param {'USER' | 'SYSTEM'} [authorType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentControllerApi
     */
    public getCommentsByReference(refersToType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig) {
        return CommentControllerApiFp(this.configuration).getCommentsByReference(refersToType, refersToId, pageSize, pageStart, authorType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the number of comments on each provided Rezen reference entity (such as a Checklist Item).Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
     * @param {Array<string>} refersToTargets 
     * @param {'USER' | 'SYSTEM'} [authorType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentControllerApi
     */
    public getCommentsByReferenceCount(refersToTargets: Array<string>, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig) {
        return CommentControllerApiFp(this.configuration).getCommentsByReferenceCount(refersToTargets, authorType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the latest comment for each provided Rezen reference entity (such as a Checklist Item). Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
     * @param {Array<RezenObject>} rezenObject 
     * @param {number} [limit] 
     * @param {'USER' | 'SYSTEM'} [authorType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentControllerApi
     */
    public getLatestCommentsByReference(rezenObject: Array<RezenObject>, limit?: number, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig) {
        return CommentControllerApiFp(this.configuration).getLatestCommentsByReference(rezenObject, limit, authorType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post a new comment. Container fields (id and type) are required.
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {'USER' | 'SYSTEM'} authorType 
     * @param {PostCommentBody} postCommentBody 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
     * @param {string} [refersToId] 
     * @param {string} [refersToName] 
     * @param {string} [callerGroupId] 
     * @param {boolean} [triggerActivity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentControllerApi
     */
    public postComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, authorType: 'USER' | 'SYSTEM', postCommentBody: PostCommentBody, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, refersToName?: string, callerGroupId?: string, triggerActivity?: boolean, options?: AxiosRequestConfig) {
        return CommentControllerApiFp(this.configuration).postComment(containerType, containerId, authorType, postCommentBody, refersToType, refersToId, refersToName, callerGroupId, triggerActivity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post a batch of comments. Container fields (id and type) are required.
     * @param {BatchCommentRequest} batchCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentControllerApi
     */
    public postCommentsBatch(batchCommentRequest: BatchCommentRequest, options?: AxiosRequestConfig) {
        return CommentControllerApiFp(this.configuration).postCommentsBatch(batchCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Subscribe to Server-Sent Events for comments
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
     * @param {string} [refersToId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentControllerApi
     */
    public subscribe1(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, options?: AxiosRequestConfig) {
        return CommentControllerApiFp(this.configuration).subscribe1(containerType, containerId, refersToType, refersToId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a comment by providing its container (type and id) and the comment id itself
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {string} commentId 
     * @param {UpdateCommentBody} updateCommentBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentControllerApi
     */
    public updateComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, updateCommentBody: UpdateCommentBody, options?: AxiosRequestConfig) {
        return CommentControllerApiFp(this.configuration).updateComment(containerType, containerId, commentId, updateCommentBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a media-file in a comment.
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {'USER' | 'SYSTEM'} authorType 
     * @param {'AUDIO' | 'VIDEO' | 'DOCUMENT' | 'IMAGE'} mediaType 
     * @param {Array<UploadMediaRichBlocksParameterInner>} richBlocks 
     * @param {File} file 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
     * @param {string} [refersToId] 
     * @param {string} [refersToName] 
     * @param {string} [callerGroupId] 
     * @param {boolean} [triggerActivity] 
     * @param {'USER' | 'ADMIN' | 'PUBLIC'} [readerRole] 
     * @param {string} [fileDescription] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentControllerApi
     */
    public uploadMedia(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, authorType: 'USER' | 'SYSTEM', mediaType: 'AUDIO' | 'VIDEO' | 'DOCUMENT' | 'IMAGE', richBlocks: Array<UploadMediaRichBlocksParameterInner>, file: File, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, refersToName?: string, callerGroupId?: string, triggerActivity?: boolean, readerRole?: 'USER' | 'ADMIN' | 'PUBLIC', fileDescription?: string, options?: AxiosRequestConfig) {
        return CommentControllerApiFp(this.configuration).uploadMedia(containerType, containerId, authorType, mediaType, richBlocks, file, refersToType, refersToId, refersToName, callerGroupId, triggerActivity, readerRole, fileDescription, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommentTemplateControllerApi - axios parameter creator
 * @export
 */
export const CommentTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate inactive comment template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCommentTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activateCommentTemplate', 'id', id)
            const localVarPath = `/api/v1/comment-templates/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a comment template
         * @param {CommentTemplateCreateRequest} commentTemplateCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentTemplate: async (commentTemplateCreateRequest: CommentTemplateCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentTemplateCreateRequest' is not null or undefined
            assertParamExists('createCommentTemplate', 'commentTemplateCreateRequest', commentTemplateCreateRequest)
            const localVarPath = `/api/v1/comment-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentTemplateCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make comment template inactive
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateCommentTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateCommentTemplate', 'id', id)
            const localVarPath = `/api/v1/comment-templates/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get comment template by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById', 'id', id)
            const localVarPath = `/api/v1/comment-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get paginated comment templates by request params
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'MISSING_SIGNATURE' | 'TERMINATION' | 'MISSING_DOCUMENT' | 'NO_DOCS_AT_ALL' | 'COMPENSATION_DOES_NOT_MATCH' | 'LATE_MLS_ENTRY' | 'LATE_FILE_ENTRY' | 'US_COMMISSION_NOT_APPROVED' | 'CANADA_COMMISSION_NOT_APPROVED'} [templateType] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentTemplates: async (pageNumber?: number, pageSize?: number, templateType?: 'MISSING_SIGNATURE' | 'TERMINATION' | 'MISSING_DOCUMENT' | 'NO_DOCS_AT_ALL' | 'COMPENSATION_DOES_NOT_MATCH' | 'LATE_MLS_ENTRY' | 'LATE_FILE_ENTRY' | 'US_COMMISSION_NOT_APPROVED' | 'CANADA_COMMISSION_NOT_APPROVED', searchString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/comment-templates/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (templateType !== undefined) {
                localVarQueryParameter['templateType'] = templateType;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing comment template
         * @param {CommentTemplateUpdateRequest} commentTemplateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommentTemplate: async (commentTemplateUpdateRequest: CommentTemplateUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentTemplateUpdateRequest' is not null or undefined
            assertParamExists('updateCommentTemplate', 'commentTemplateUpdateRequest', commentTemplateUpdateRequest)
            const localVarPath = `/api/v1/comment-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentTemplateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentTemplateControllerApi - functional programming interface
 * @export
 */
export const CommentTemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentTemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate inactive comment template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateCommentTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateCommentTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a comment template
         * @param {CommentTemplateCreateRequest} commentTemplateCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentTemplate(commentTemplateCreateRequest: CommentTemplateCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentTemplate(commentTemplateCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make comment template inactive
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateCommentTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateCommentTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get comment template by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get paginated comment templates by request params
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'MISSING_SIGNATURE' | 'TERMINATION' | 'MISSING_DOCUMENT' | 'NO_DOCS_AT_ALL' | 'COMPENSATION_DOES_NOT_MATCH' | 'LATE_MLS_ENTRY' | 'LATE_FILE_ENTRY' | 'US_COMMISSION_NOT_APPROVED' | 'CANADA_COMMISSION_NOT_APPROVED'} [templateType] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentTemplates(pageNumber?: number, pageSize?: number, templateType?: 'MISSING_SIGNATURE' | 'TERMINATION' | 'MISSING_DOCUMENT' | 'NO_DOCS_AT_ALL' | 'COMPENSATION_DOES_NOT_MATCH' | 'LATE_MLS_ENTRY' | 'LATE_FILE_ENTRY' | 'US_COMMISSION_NOT_APPROVED' | 'CANADA_COMMISSION_NOT_APPROVED', searchString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentTemplatePaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentTemplates(pageNumber, pageSize, templateType, searchString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update existing comment template
         * @param {CommentTemplateUpdateRequest} commentTemplateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommentTemplate(commentTemplateUpdateRequest: CommentTemplateUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommentTemplate(commentTemplateUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommentTemplateControllerApi - factory interface
 * @export
 */
export const CommentTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentTemplateControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate inactive comment template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCommentTemplate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.activateCommentTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a comment template
         * @param {CommentTemplateCreateRequest} commentTemplateCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentTemplate(commentTemplateCreateRequest: CommentTemplateCreateRequest, options?: any): AxiosPromise<string> {
            return localVarFp.createCommentTemplate(commentTemplateCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make comment template inactive
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateCommentTemplate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deactivateCommentTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get comment template by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: string, options?: any): AxiosPromise<CommentTemplateResponse> {
            return localVarFp.getById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get paginated comment templates by request params
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'MISSING_SIGNATURE' | 'TERMINATION' | 'MISSING_DOCUMENT' | 'NO_DOCS_AT_ALL' | 'COMPENSATION_DOES_NOT_MATCH' | 'LATE_MLS_ENTRY' | 'LATE_FILE_ENTRY' | 'US_COMMISSION_NOT_APPROVED' | 'CANADA_COMMISSION_NOT_APPROVED'} [templateType] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentTemplates(pageNumber?: number, pageSize?: number, templateType?: 'MISSING_SIGNATURE' | 'TERMINATION' | 'MISSING_DOCUMENT' | 'NO_DOCS_AT_ALL' | 'COMPENSATION_DOES_NOT_MATCH' | 'LATE_MLS_ENTRY' | 'LATE_FILE_ENTRY' | 'US_COMMISSION_NOT_APPROVED' | 'CANADA_COMMISSION_NOT_APPROVED', searchString?: string, options?: any): AxiosPromise<CommentTemplatePaginatedResponse> {
            return localVarFp.getCommentTemplates(pageNumber, pageSize, templateType, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update existing comment template
         * @param {CommentTemplateUpdateRequest} commentTemplateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommentTemplate(commentTemplateUpdateRequest: CommentTemplateUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateCommentTemplate(commentTemplateUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentTemplateControllerApi - object-oriented interface
 * @export
 * @class CommentTemplateControllerApi
 * @extends {BaseAPI}
 */
export class CommentTemplateControllerApi extends BaseAPI {
    /**
     * 
     * @summary Activate inactive comment template
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentTemplateControllerApi
     */
    public activateCommentTemplate(id: string, options?: AxiosRequestConfig) {
        return CommentTemplateControllerApiFp(this.configuration).activateCommentTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a comment template
     * @param {CommentTemplateCreateRequest} commentTemplateCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentTemplateControllerApi
     */
    public createCommentTemplate(commentTemplateCreateRequest: CommentTemplateCreateRequest, options?: AxiosRequestConfig) {
        return CommentTemplateControllerApiFp(this.configuration).createCommentTemplate(commentTemplateCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make comment template inactive
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentTemplateControllerApi
     */
    public deactivateCommentTemplate(id: string, options?: AxiosRequestConfig) {
        return CommentTemplateControllerApiFp(this.configuration).deactivateCommentTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get comment template by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentTemplateControllerApi
     */
    public getById(id: string, options?: AxiosRequestConfig) {
        return CommentTemplateControllerApiFp(this.configuration).getById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get paginated comment templates by request params
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'MISSING_SIGNATURE' | 'TERMINATION' | 'MISSING_DOCUMENT' | 'NO_DOCS_AT_ALL' | 'COMPENSATION_DOES_NOT_MATCH' | 'LATE_MLS_ENTRY' | 'LATE_FILE_ENTRY' | 'US_COMMISSION_NOT_APPROVED' | 'CANADA_COMMISSION_NOT_APPROVED'} [templateType] 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentTemplateControllerApi
     */
    public getCommentTemplates(pageNumber?: number, pageSize?: number, templateType?: 'MISSING_SIGNATURE' | 'TERMINATION' | 'MISSING_DOCUMENT' | 'NO_DOCS_AT_ALL' | 'COMPENSATION_DOES_NOT_MATCH' | 'LATE_MLS_ENTRY' | 'LATE_FILE_ENTRY' | 'US_COMMISSION_NOT_APPROVED' | 'CANADA_COMMISSION_NOT_APPROVED', searchString?: string, options?: AxiosRequestConfig) {
        return CommentTemplateControllerApiFp(this.configuration).getCommentTemplates(pageNumber, pageSize, templateType, searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update existing comment template
     * @param {CommentTemplateUpdateRequest} commentTemplateUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentTemplateControllerApi
     */
    public updateCommentTemplate(commentTemplateUpdateRequest: CommentTemplateUpdateRequest, options?: AxiosRequestConfig) {
        return CommentTemplateControllerApiFp(this.configuration).updateCommentTemplate(commentTemplateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConversationsApi - axios parameter creator
 * @export
 */
export const ConversationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * A conversation must have at least one member aside from the caller. If a conversation with the requested members already exists, the existing conversation will be returned. Caller will always be included as a conversation member.
         * @summary Create or find existing conversation
         * @param {CreateConversation} createConversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConversation: async (createConversation: CreateConversation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createConversation' is not null or undefined
            assertParamExists('createConversation', 'createConversation', createConversation)
            const localVarPath = `/api/v1/conversations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConversation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a conversation by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConversation', 'id', id)
            const localVarPath = `/api/v1/conversations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get conversations
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
         * @param {Array<string>} [withUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversations: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'UPDATED_AT'>, withUser?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/conversations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (withUser) {
                localVarQueryParameter['withUser'] = Array.from(withUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationsApi - functional programming interface
 * @export
 */
export const ConversationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConversationsApiAxiosParamCreator(configuration)
    return {
        /**
         * A conversation must have at least one member aside from the caller. If a conversation with the requested members already exists, the existing conversation will be returned. Caller will always be included as a conversation member.
         * @summary Create or find existing conversation
         * @param {CreateConversation} createConversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConversation(createConversation: CreateConversation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConversation(createConversation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a conversation by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get conversations
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
         * @param {Array<string>} [withUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversations(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'UPDATED_AT'>, withUser?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseConversationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversations(pageNumber, pageSize, sortDirection, sortBy, withUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConversationsApi - factory interface
 * @export
 */
export const ConversationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConversationsApiFp(configuration)
    return {
        /**
         * A conversation must have at least one member aside from the caller. If a conversation with the requested members already exists, the existing conversation will be returned. Caller will always be included as a conversation member.
         * @summary Create or find existing conversation
         * @param {CreateConversation} createConversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConversation(createConversation: CreateConversation, options?: any): AxiosPromise<ConversationResponse> {
            return localVarFp.createConversation(createConversation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a conversation by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation(id: string, options?: any): AxiosPromise<ConversationResponse> {
            return localVarFp.getConversation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get conversations
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
         * @param {Array<string>} [withUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversations(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'UPDATED_AT'>, withUser?: Array<string>, options?: any): AxiosPromise<GenericSearchResponseConversationResponse> {
            return localVarFp.getConversations(pageNumber, pageSize, sortDirection, sortBy, withUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConversationsApi - object-oriented interface
 * @export
 * @class ConversationsApi
 * @extends {BaseAPI}
 */
export class ConversationsApi extends BaseAPI {
    /**
     * A conversation must have at least one member aside from the caller. If a conversation with the requested members already exists, the existing conversation will be returned. Caller will always be included as a conversation member.
     * @summary Create or find existing conversation
     * @param {CreateConversation} createConversation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public createConversation(createConversation: CreateConversation, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).createConversation(createConversation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a conversation by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public getConversation(id: string, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).getConversation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get conversations
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
     * @param {Array<string>} [withUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public getConversations(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'UPDATED_AT'>, withUser?: Array<string>, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).getConversations(pageNumber, pageSize, sortDirection, sortBy, withUser, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InboxControllerApi - axios parameter creator
 * @export
 */
export const InboxControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all messages for a given user Id. Sorted descending from date of last activity
         * @param {string} userId 
         * @param {'ALL' | 'UNREAD' | 'WITH_UPLOADS'} [filter] 
         * @param {string} [searchText] 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesForUser: async (userId: string, filter?: 'ALL' | 'UNREAD' | 'WITH_UPLOADS', searchText?: string, pageSize?: number, pageStart?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getMessagesForUser', 'userId', userId)
            const localVarPath = `/api/v1/inbox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageStart !== undefined) {
                localVarQueryParameter['pageStart'] = pageStart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get number of unread messages for a given user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadMessageCountForUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUnreadMessageCountForUser', 'userId', userId)
            const localVarPath = `/api/v1/inbox/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a given message or all messages for a user + target as read
         * @param {string} userId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsRead: async (userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('markMessageAsRead', 'userId', userId)
            const localVarPath = `/api/v1/inbox/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (targetType !== undefined) {
                localVarQueryParameter['targetType'] = targetType;
            }

            if (targetId !== undefined) {
                localVarQueryParameter['targetId'] = targetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a given message or all messages for a user + target as unread
         * @param {string} userId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsUnread: async (userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('markMessageAsUnread', 'userId', userId)
            const localVarPath = `/api/v1/inbox/unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (targetType !== undefined) {
                localVarQueryParameter['targetType'] = targetType;
            }

            if (targetId !== undefined) {
                localVarQueryParameter['targetId'] = targetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboxControllerApi - functional programming interface
 * @export
 */
export const InboxControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InboxControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all messages for a given user Id. Sorted descending from date of last activity
         * @param {string} userId 
         * @param {'ALL' | 'UNREAD' | 'WITH_UPLOADS'} [filter] 
         * @param {string} [searchText] 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagesForUser(userId: string, filter?: 'ALL' | 'UNREAD' | 'WITH_UPLOADS', searchText?: string, pageSize?: number, pageStart?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageQueryResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagesForUser(userId, filter, searchText, pageSize, pageStart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get number of unread messages for a given user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadMessageCountForUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageCountRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadMessageCountForUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a given message or all messages for a user + target as read
         * @param {string} userId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markMessageAsRead(userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markMessageAsRead(userId, targetType, targetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a given message or all messages for a user + target as unread
         * @param {string} userId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markMessageAsUnread(userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markMessageAsUnread(userId, targetType, targetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InboxControllerApi - factory interface
 * @export
 */
export const InboxControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InboxControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all messages for a given user Id. Sorted descending from date of last activity
         * @param {string} userId 
         * @param {'ALL' | 'UNREAD' | 'WITH_UPLOADS'} [filter] 
         * @param {string} [searchText] 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesForUser(userId: string, filter?: 'ALL' | 'UNREAD' | 'WITH_UPLOADS', searchText?: string, pageSize?: number, pageStart?: string, options?: any): AxiosPromise<MessageQueryResDto> {
            return localVarFp.getMessagesForUser(userId, filter, searchText, pageSize, pageStart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get number of unread messages for a given user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadMessageCountForUser(userId: string, options?: any): AxiosPromise<MessageCountRes> {
            return localVarFp.getUnreadMessageCountForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a given message or all messages for a user + target as read
         * @param {string} userId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsRead(userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.markMessageAsRead(userId, targetType, targetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a given message or all messages for a user + target as unread
         * @param {string} userId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsUnread(userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.markMessageAsUnread(userId, targetType, targetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboxControllerApi - object-oriented interface
 * @export
 * @class InboxControllerApi
 * @extends {BaseAPI}
 */
export class InboxControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get all messages for a given user Id. Sorted descending from date of last activity
     * @param {string} userId 
     * @param {'ALL' | 'UNREAD' | 'WITH_UPLOADS'} [filter] 
     * @param {string} [searchText] 
     * @param {number} [pageSize] 
     * @param {string} [pageStart] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxControllerApi
     */
    public getMessagesForUser(userId: string, filter?: 'ALL' | 'UNREAD' | 'WITH_UPLOADS', searchText?: string, pageSize?: number, pageStart?: string, options?: AxiosRequestConfig) {
        return InboxControllerApiFp(this.configuration).getMessagesForUser(userId, filter, searchText, pageSize, pageStart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get number of unread messages for a given user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxControllerApi
     */
    public getUnreadMessageCountForUser(userId: string, options?: AxiosRequestConfig) {
        return InboxControllerApiFp(this.configuration).getUnreadMessageCountForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a given message or all messages for a user + target as read
     * @param {string} userId 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
     * @param {string} [targetId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxControllerApi
     */
    public markMessageAsRead(userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options?: AxiosRequestConfig) {
        return InboxControllerApiFp(this.configuration).markMessageAsRead(userId, targetType, targetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a given message or all messages for a user + target as unread
     * @param {string} userId 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
     * @param {string} [targetId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxControllerApi
     */
    public markMessageAsUnread(userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options?: AxiosRequestConfig) {
        return InboxControllerApiFp(this.configuration).markMessageAsUnread(userId, targetType, targetId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KafkaRetryConsumerControllerApi - axios parameter creator
 * @export
 */
export const KafkaRetryConsumerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus1: async (updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFailedMessageStatusBatch' is not null or undefined
            assertParamExists('batchUpdateFailedMessageStatus1', 'updateFailedMessageStatusBatch', updateFailedMessageStatusBatch)
            const localVarPath = `/api/v1/kafka-retry/consumer/status-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFailedMessageStatusBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages1: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/consumer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = createdAtFrom;
            }

            if (createdAtTo !== undefined) {
                localVarQueryParameter['createdAtTo'] = createdAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage1: async (failedMessageRetryRequest: FailedMessageRetryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageRetryRequest' is not null or undefined
            assertParamExists('retryFailedMessage1', 'failedMessageRetryRequest', failedMessageRetryRequest)
            const localVarPath = `/api/v1/kafka-retry/consumer/retry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageRetryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages1: async (failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessagesCriteriaRequest' is not null or undefined
            assertParamExists('retryFailedMessages1', 'failedMessagesCriteriaRequest', failedMessagesCriteriaRequest)
            const localVarPath = `/api/v1/kafka-retry/consumer/retry-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessagesCriteriaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus1: async (failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageUpdateStatusRequest' is not null or undefined
            assertParamExists('updateFailedMessageStatus1', 'failedMessageUpdateStatusRequest', failedMessageUpdateStatusRequest)
            const localVarPath = `/api/v1/kafka-retry/consumer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageUpdateStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaRetryConsumerControllerApi - functional programming interface
 * @export
 */
export const KafkaRetryConsumerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KafkaRetryConsumerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFailedMessages1(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFailedMessages1(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessage1(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessage1(failedMessageRetryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessages1(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessages1(failedMessagesCriteriaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateFailedMessageStatus1(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFailedMessageStatus1(failedMessageUpdateStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KafkaRetryConsumerControllerApi - factory interface
 * @export
 */
export const KafkaRetryConsumerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KafkaRetryConsumerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: any): AxiosPromise<number> {
            return localVarFp.batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages1(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: any): AxiosPromise<GenericSearchResponseFailedMessage> {
            return localVarFp.getFailedMessages1(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage1(failedMessageRetryRequest: FailedMessageRetryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryFailedMessage1(failedMessageRetryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages1(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: any): AxiosPromise<number> {
            return localVarFp.retryFailedMessages1(failedMessagesCriteriaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus1(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: any): AxiosPromise<FailedMessage> {
            return localVarFp.updateFailedMessageStatus1(failedMessageUpdateStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KafkaRetryConsumerControllerApi - object-oriented interface
 * @export
 * @class KafkaRetryConsumerControllerApi
 * @extends {BaseAPI}
 */
export class KafkaRetryConsumerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Update provided failed messages status
     * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of failed messages
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
     * @param {number} [createdAtFrom] 
     * @param {number} [createdAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public getFailedMessages1(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).getFailedMessages1(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry one message by id
     * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public retryFailedMessage1(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).retryFailedMessage1(failedMessageRetryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry messages by criteria
     * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public retryFailedMessages1(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).retryFailedMessages1(failedMessagesCriteriaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update failed event status
     * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public updateFailedMessageStatus1(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).updateFailedMessageStatus1(failedMessageUpdateStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KafkaRetryProducerControllerApi - axios parameter creator
 * @export
 */
export const KafkaRetryProducerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus: async (updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFailedMessageStatusBatch' is not null or undefined
            assertParamExists('batchUpdateFailedMessageStatus', 'updateFailedMessageStatusBatch', updateFailedMessageStatusBatch)
            const localVarPath = `/api/v1/kafka-retry/producer/status-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFailedMessageStatusBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/producer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = createdAtFrom;
            }

            if (createdAtTo !== undefined) {
                localVarQueryParameter['createdAtTo'] = createdAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage: async (failedMessageRetryRequest: FailedMessageRetryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageRetryRequest' is not null or undefined
            assertParamExists('retryFailedMessage', 'failedMessageRetryRequest', failedMessageRetryRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageRetryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages: async (failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessagesCriteriaRequest' is not null or undefined
            assertParamExists('retryFailedMessages', 'failedMessagesCriteriaRequest', failedMessagesCriteriaRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessagesCriteriaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus: async (failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageUpdateStatusRequest' is not null or undefined
            assertParamExists('updateFailedMessageStatus', 'failedMessageUpdateStatusRequest', failedMessageUpdateStatusRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageUpdateStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - functional programming interface
 * @export
 */
export const KafkaRetryProducerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KafkaRetryProducerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessage(failedMessageRetryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessages(failedMessagesCriteriaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - factory interface
 * @export
 */
export const KafkaRetryProducerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KafkaRetryProducerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: any): AxiosPromise<number> {
            return localVarFp.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: any): AxiosPromise<GenericSearchResponseFailedMessage> {
            return localVarFp.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: any): AxiosPromise<number> {
            return localVarFp.retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: any): AxiosPromise<FailedMessage> {
            return localVarFp.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KafkaRetryProducerControllerApi - object-oriented interface
 * @export
 * @class KafkaRetryProducerControllerApi
 * @extends {BaseAPI}
 */
export class KafkaRetryProducerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Update provided failed messages status
     * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of failed messages
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
     * @param {number} [createdAtFrom] 
     * @param {number} [createdAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry one message by id
     * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry messages by criteria
     * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update failed event status
     * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VoiceCallControllerApi - axios parameter creator
 * @export
 */
export const VoiceCallControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Setup a new voice-call
         * @param {CreateVoiceCallRequest} createVoiceCallRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVoiceCall: async (createVoiceCallRequest: CreateVoiceCallRequest, xRealAppName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVoiceCallRequest' is not null or undefined
            assertParamExists('createVoiceCall', 'createVoiceCallRequest', createVoiceCallRequest)
            const localVarPath = `/api/v1/voice/calls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xRealAppName != null) {
                localVarHeaderParameter['X-real-app-name'] = String(xRealAppName);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVoiceCallRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get voice-call details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceCall: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVoiceCall', 'id', id)
            const localVarPath = `/api/v1/voice/calls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get latest voice-call that have unidentified speakers in which the logged-in internal-user (broker, loan-officer, etc.) was a call-participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceCallWithUnidentifiedSpeakers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/voice/calls/with-unidentified-speakers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch voice-call attributes
         * @param {string} id 
         * @param {PatchVoiceCallRequest} patchVoiceCallRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchVoiceCall: async (id: string, patchVoiceCallRequest: PatchVoiceCallRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchVoiceCall', 'id', id)
            // verify required parameter 'patchVoiceCallRequest' is not null or undefined
            assertParamExists('patchVoiceCall', 'patchVoiceCallRequest', patchVoiceCallRequest)
            const localVarPath = `/api/v1/voice/calls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchVoiceCallRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary SMS the dial number for the voice-call to the caller
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smsDialNumber: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('smsDialNumber', 'id', id)
            const localVarPath = `/api/v1/voice/calls/{id}/sms-dial-number`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe to Server-Sent Events for voice-calls
         * @param {string} calleeUserId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [containerType] 
         * @param {string} [containerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribe: async (calleeUserId: string, containerType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calleeUserId' is not null or undefined
            assertParamExists('subscribe', 'calleeUserId', calleeUserId)
            const localVarPath = `/api/v1/voice/calls/sse-subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (calleeUserId !== undefined) {
                localVarQueryParameter['calleeUserId'] = calleeUserId;
            }

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoiceCallControllerApi - functional programming interface
 * @export
 */
export const VoiceCallControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VoiceCallControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Setup a new voice-call
         * @param {CreateVoiceCallRequest} createVoiceCallRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVoiceCall(createVoiceCallRequest: CreateVoiceCallRequest, xRealAppName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceCallDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVoiceCall(createVoiceCallRequest, xRealAppName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get voice-call details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVoiceCall(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceCallDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVoiceCall(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get latest voice-call that have unidentified speakers in which the logged-in internal-user (broker, loan-officer, etc.) was a call-participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVoiceCallWithUnidentifiedSpeakers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceCallLiteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVoiceCallWithUnidentifiedSpeakers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch voice-call attributes
         * @param {string} id 
         * @param {PatchVoiceCallRequest} patchVoiceCallRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchVoiceCall(id: string, patchVoiceCallRequest: PatchVoiceCallRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceCallDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchVoiceCall(id, patchVoiceCallRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary SMS the dial number for the voice-call to the caller
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smsDialNumber(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smsDialNumber(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Subscribe to Server-Sent Events for voice-calls
         * @param {string} calleeUserId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [containerType] 
         * @param {string} [containerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribe(calleeUserId: string, containerType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribe(calleeUserId, containerType, containerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VoiceCallControllerApi - factory interface
 * @export
 */
export const VoiceCallControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VoiceCallControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Setup a new voice-call
         * @param {CreateVoiceCallRequest} createVoiceCallRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVoiceCall(createVoiceCallRequest: CreateVoiceCallRequest, xRealAppName?: string, options?: any): AxiosPromise<VoiceCallDto> {
            return localVarFp.createVoiceCall(createVoiceCallRequest, xRealAppName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get voice-call details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceCall(id: string, options?: any): AxiosPromise<VoiceCallDto> {
            return localVarFp.getVoiceCall(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get latest voice-call that have unidentified speakers in which the logged-in internal-user (broker, loan-officer, etc.) was a call-participant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceCallWithUnidentifiedSpeakers(options?: any): AxiosPromise<VoiceCallLiteDto> {
            return localVarFp.getVoiceCallWithUnidentifiedSpeakers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch voice-call attributes
         * @param {string} id 
         * @param {PatchVoiceCallRequest} patchVoiceCallRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchVoiceCall(id: string, patchVoiceCallRequest: PatchVoiceCallRequest, options?: any): AxiosPromise<VoiceCallDto> {
            return localVarFp.patchVoiceCall(id, patchVoiceCallRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary SMS the dial number for the voice-call to the caller
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smsDialNumber(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.smsDialNumber(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Subscribe to Server-Sent Events for voice-calls
         * @param {string} calleeUserId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [containerType] 
         * @param {string} [containerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribe(calleeUserId: string, containerType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId?: string, options?: any): AxiosPromise<SseEmitter> {
            return localVarFp.subscribe(calleeUserId, containerType, containerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VoiceCallControllerApi - object-oriented interface
 * @export
 * @class VoiceCallControllerApi
 * @extends {BaseAPI}
 */
export class VoiceCallControllerApi extends BaseAPI {
    /**
     * 
     * @summary Setup a new voice-call
     * @param {CreateVoiceCallRequest} createVoiceCallRequest 
     * @param {string} [xRealAppName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceCallControllerApi
     */
    public createVoiceCall(createVoiceCallRequest: CreateVoiceCallRequest, xRealAppName?: string, options?: AxiosRequestConfig) {
        return VoiceCallControllerApiFp(this.configuration).createVoiceCall(createVoiceCallRequest, xRealAppName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get voice-call details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceCallControllerApi
     */
    public getVoiceCall(id: string, options?: AxiosRequestConfig) {
        return VoiceCallControllerApiFp(this.configuration).getVoiceCall(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get latest voice-call that have unidentified speakers in which the logged-in internal-user (broker, loan-officer, etc.) was a call-participant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceCallControllerApi
     */
    public getVoiceCallWithUnidentifiedSpeakers(options?: AxiosRequestConfig) {
        return VoiceCallControllerApiFp(this.configuration).getVoiceCallWithUnidentifiedSpeakers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch voice-call attributes
     * @param {string} id 
     * @param {PatchVoiceCallRequest} patchVoiceCallRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceCallControllerApi
     */
    public patchVoiceCall(id: string, patchVoiceCallRequest: PatchVoiceCallRequest, options?: AxiosRequestConfig) {
        return VoiceCallControllerApiFp(this.configuration).patchVoiceCall(id, patchVoiceCallRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary SMS the dial number for the voice-call to the caller
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceCallControllerApi
     */
    public smsDialNumber(id: string, options?: AxiosRequestConfig) {
        return VoiceCallControllerApiFp(this.configuration).smsDialNumber(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Subscribe to Server-Sent Events for voice-calls
     * @param {string} calleeUserId 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [containerType] 
     * @param {string} [containerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceCallControllerApi
     */
    public subscribe(calleeUserId: string, containerType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId?: string, options?: AxiosRequestConfig) {
        return VoiceCallControllerApiFp(this.configuration).subscribe(calleeUserId, containerType, containerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VoiceRecordingControllerApi - axios parameter creator
 * @export
 */
export const VoiceRecordingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get voice-recording details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceRecording: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVoiceRecording', 'id', id)
            const localVarPath = `/api/v1/voice/recordings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update speakers for a given recording
         * @param {string} id 
         * @param {Speakers} speakers 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpeakers: async (id: string, speakers: Speakers, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSpeakers', 'id', id)
            // verify required parameter 'speakers' is not null or undefined
            assertParamExists('updateSpeakers', 'speakers', speakers)
            const localVarPath = `/api/v1/voice/recordings/{id}/speakers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(speakers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoiceRecordingControllerApi - functional programming interface
 * @export
 */
export const VoiceRecordingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VoiceRecordingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get voice-recording details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVoiceRecording(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceRecordingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVoiceRecording(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update speakers for a given recording
         * @param {string} id 
         * @param {Speakers} speakers 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSpeakers(id: string, speakers: Speakers, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSpeakers(id, speakers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VoiceRecordingControllerApi - factory interface
 * @export
 */
export const VoiceRecordingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VoiceRecordingControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get voice-recording details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceRecording(id: string, options?: any): AxiosPromise<VoiceRecordingDto> {
            return localVarFp.getVoiceRecording(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update speakers for a given recording
         * @param {string} id 
         * @param {Speakers} speakers 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpeakers(id: string, speakers: Speakers, options?: any): AxiosPromise<void> {
            return localVarFp.updateSpeakers(id, speakers, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VoiceRecordingControllerApi - object-oriented interface
 * @export
 * @class VoiceRecordingControllerApi
 * @extends {BaseAPI}
 */
export class VoiceRecordingControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get voice-recording details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceRecordingControllerApi
     */
    public getVoiceRecording(id: string, options?: AxiosRequestConfig) {
        return VoiceRecordingControllerApiFp(this.configuration).getVoiceRecording(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update speakers for a given recording
     * @param {string} id 
     * @param {Speakers} speakers 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceRecordingControllerApi
     */
    public updateSpeakers(id: string, speakers: Speakers, options?: AxiosRequestConfig) {
        return VoiceRecordingControllerApiFp(this.configuration).updateSpeakers(id, speakers, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VoiceVirtualNumberControllerApi - axios parameter creator
 * @export
 */
export const VoiceVirtualNumberControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate a virtual-number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activate', 'id', id)
            const localVarPath = `/api/v1/voice/virtual-numbers/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new virtual-number
         * @param {CreateVirtualNumber} createVirtualNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVirtualNumber: async (createVirtualNumber: CreateVirtualNumber, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVirtualNumber' is not null or undefined
            assertParamExists('createVirtualNumber', 'createVirtualNumber', createVirtualNumber)
            const localVarPath = `/api/v1/voice/virtual-numbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVirtualNumber, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate a virtual-number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivate', 'id', id)
            const localVarPath = `/api/v1/voice/virtual-numbers/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get virtual-number details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVirtualNumber: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVirtualNumber', 'id', id)
            const localVarPath = `/api/v1/voice/virtual-numbers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a virtual-number
         * @param {string} id 
         * @param {UpdateVirtualNumberRequest} updateVirtualNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateVirtualNumberRequest: UpdateVirtualNumberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateVirtualNumberRequest' is not null or undefined
            assertParamExists('update', 'updateVirtualNumberRequest', updateVirtualNumberRequest)
            const localVarPath = `/api/v1/voice/virtual-numbers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVirtualNumberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoiceVirtualNumberControllerApi - functional programming interface
 * @export
 */
export const VoiceVirtualNumberControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VoiceVirtualNumberControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate a virtual-number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceVirtualNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new virtual-number
         * @param {CreateVirtualNumber} createVirtualNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVirtualNumber(createVirtualNumber: CreateVirtualNumber, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceVirtualNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVirtualNumber(createVirtualNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate a virtual-number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceVirtualNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get virtual-number details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVirtualNumber(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceVirtualNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVirtualNumber(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a virtual-number
         * @param {string} id 
         * @param {UpdateVirtualNumberRequest} updateVirtualNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateVirtualNumberRequest: UpdateVirtualNumberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceVirtualNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateVirtualNumberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VoiceVirtualNumberControllerApi - factory interface
 * @export
 */
export const VoiceVirtualNumberControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VoiceVirtualNumberControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate a virtual-number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(id: string, options?: any): AxiosPromise<VoiceVirtualNumberDto> {
            return localVarFp.activate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new virtual-number
         * @param {CreateVirtualNumber} createVirtualNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVirtualNumber(createVirtualNumber: CreateVirtualNumber, options?: any): AxiosPromise<VoiceVirtualNumberDto> {
            return localVarFp.createVirtualNumber(createVirtualNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate a virtual-number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate(id: string, options?: any): AxiosPromise<VoiceVirtualNumberDto> {
            return localVarFp.deactivate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get virtual-number details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVirtualNumber(id: string, options?: any): AxiosPromise<VoiceVirtualNumberDto> {
            return localVarFp.getVirtualNumber(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a virtual-number
         * @param {string} id 
         * @param {UpdateVirtualNumberRequest} updateVirtualNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateVirtualNumberRequest: UpdateVirtualNumberRequest, options?: any): AxiosPromise<VoiceVirtualNumberDto> {
            return localVarFp.update(id, updateVirtualNumberRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VoiceVirtualNumberControllerApi - object-oriented interface
 * @export
 * @class VoiceVirtualNumberControllerApi
 * @extends {BaseAPI}
 */
export class VoiceVirtualNumberControllerApi extends BaseAPI {
    /**
     * 
     * @summary Activate a virtual-number
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceVirtualNumberControllerApi
     */
    public activate(id: string, options?: AxiosRequestConfig) {
        return VoiceVirtualNumberControllerApiFp(this.configuration).activate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new virtual-number
     * @param {CreateVirtualNumber} createVirtualNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceVirtualNumberControllerApi
     */
    public createVirtualNumber(createVirtualNumber: CreateVirtualNumber, options?: AxiosRequestConfig) {
        return VoiceVirtualNumberControllerApiFp(this.configuration).createVirtualNumber(createVirtualNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate a virtual-number
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceVirtualNumberControllerApi
     */
    public deactivate(id: string, options?: AxiosRequestConfig) {
        return VoiceVirtualNumberControllerApiFp(this.configuration).deactivate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get virtual-number details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceVirtualNumberControllerApi
     */
    public getVirtualNumber(id: string, options?: AxiosRequestConfig) {
        return VoiceVirtualNumberControllerApiFp(this.configuration).getVirtualNumber(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a virtual-number
     * @param {string} id 
     * @param {UpdateVirtualNumberRequest} updateVirtualNumberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceVirtualNumberControllerApi
     */
    public update(id: string, updateVirtualNumberRequest: UpdateVirtualNumberRequest, options?: AxiosRequestConfig) {
        return VoiceVirtualNumberControllerApiFp(this.configuration).update(id, updateVirtualNumberRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


