import millify from 'millify';
import { useDispatch, useSelector } from 'react-redux';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ZenButton from '../../Zen/ZenButton';
import { cn } from '../../../utils/classUtils';
import SppPreCapCard from '../../../assets/img/wealthManagement/spp-pre-cap-card.svg';
import SppPostCapCard from '../../../assets/img/wealthManagement/spp-post-cap-card.svg';
import { Earning, EarningType } from '../WealthManagementTypes';
import { AnalyticsEventEnum, RootState } from '../../../types';
import AnalyticsService from '../../../services/AnalyticsService';
import { setWealthKnowledgeLandingPage } from '../../../slices/WealthManagementSlice';
import { PrimaryGradientText } from './PrimaryGradientText';

const ContributionCard = ({
  cap,
  maxContribution,
  realMatchingPercent,
  type,
}: {
  cap: number;
  maxContribution: number;
  realMatchingPercent: number;
  type: EarningType;
}) => {
  const isPreCap = type === Earning.PRE_CAP_SPP;

  return (
    <div className='w-full h-16 bg-neutral-900 border-neutral-800 rounded-lg border py-10 px-3 lg:px-5 flex justify-between items-center mt-4'>
      <div className='flex flex-col'>
        <div className='text-white text-xl lg:text-xl mb-2'>
          up to ${maxContribution.toLocaleString()}
        </div>
        <div className='text-white text-opacity-60'>${millify(cap)} CAP</div>
      </div>
      <div
        className={cn('font-semibold text-xl lg:text-2xl', {
          'text-purple-300': isPreCap,
          'text-green-200': !isPreCap,
        })}
      >
        {realMatchingPercent}% of ${maxContribution.toLocaleString()} = $
        {Math.floor(
          maxContribution * (realMatchingPercent / 100),
        ).toLocaleString()}
      </div>
    </div>
  );
};

type SppContributionSectionProps = {
  type: EarningType;
  scrollToExample(): void;
};

export const SppContributionSection = ({
  type,
  scrollToExample,
}: SppContributionSectionProps) => {
  const dispatch = useDispatch();

  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );

  const isPreCap = type === Earning.PRE_CAP_SPP;

  const _12kCapMax = isPreCap
    ? officialPlanAndAwardData?.equityPurchasePlan?.preCapMax?.amount ?? 0
    : officialPlanAndAwardData?.equityPurchasePlan?.postCapMax?.amount ?? 0;
  const _6kCapMax = isPreCap
    ? officialPlanAndAwardData?.equityPurchasePlan?.normalTeamMemberPreCapMax
        ?.amount ?? 0
    : officialPlanAndAwardData?.equityPurchasePlan?.normalTeamMemberPostCapMax
        ?.amount ?? 0;
  const _4kCapMax = isPreCap
    ? officialPlanAndAwardData?.equityPurchasePlan?.platinumTeamMemberPreCapMax
        ?.amount ?? 0
    : officialPlanAndAwardData?.equityPurchasePlan?.platinumTeamMemberPostCapMax
        ?.amount ?? 0;

  const capContributionPercent = isPreCap
    ? officialPlanAndAwardData?.equityPurchasePlan
        ?.preCapNetCommissionPercent ?? 5
    : officialPlanAndAwardData?.equityPurchasePlan
        ?.postCapNetCommissionPercent ?? 10;
  const capMatchPercent = isPreCap
    ? officialPlanAndAwardData?.equityPurchasePlan?.preCapBonusSharePercent ??
      10
    : officialPlanAndAwardData?.equityPurchasePlan?.postCapBonusSharePercent ??
      20;

  const handleButtonClick = () => {
    const event = isPreCap
      ? AnalyticsEventEnum.WEALTH_PLAN_LP_PRE_CAP_SPP_CLICK_ON_POST_CAP_SPP
      : AnalyticsEventEnum.WEALTH_PLAN_LP_POST_CAP_SPP_CLICK_ON_PRE_CAP_SPP;
    AnalyticsService.instance().logEvent(event);
    const landingPage = isPreCap ? Earning.POST_CAP_SPP : Earning.PRE_CAP_SPP;
    dispatch(setWealthKnowledgeLandingPage(landingPage));
  };

  return (
    <div className='flex flex-col justify-center items-center bg-black pt-5 mt-4'>
      <div className='px-3 lg:px-40'>
        <div className='text-3xl lg:text-5xl font-poppins font-bold text-white w-full mt-32 text-center mb-5 flex flex-col items-center'>
          <div className='mb-2'>{isPreCap ? 'Pre-Cap' : 'Post-Cap'}</div>
          <PrimaryGradientText>Stock Purchase Plan (SPP)</PrimaryGradientText>
        </div>
        <div className='lg:text-2xl text-white text-center mb-5 font-inter lg:px-10'>
          When you participate in SPP, Real rewards you with a matching grant
          bonus.
        </div>
      </div>
      <div className='px-3 lg:px-64'>
        <div className='w-full mb-5'>
          <div className='flex w-full items-center justify-between flex-col'>
            <div className='relative flex'>
              <img
                src={isPreCap ? SppPreCapCard : SppPostCapCard}
                alt={isPreCap ? 'Pre cap' : 'post cap'}
                className='w-full my-4'
              />
              <div className='absolute top-[6%] h-[80%] w-[42.8%]'>
                <div className='flex flex-col items-center justify-center h-full'>
                  <div
                    className={cn('font-extrabold text-6xl lg:text-8xl', {
                      'text-zen-purple-3': isPreCap,
                      'text-green-600': !isPreCap,
                    })}
                  >
                    {capContributionPercent}%
                  </div>
                  <div className='text-black uppercase text-center lg:text-lg !leading-4 font-bold'>
                    <p>your commission</p>
                    <p>contribution</p>
                  </div>
                </div>
              </div>
              <div className='absolute left-[52%] top-[6%] h-[80%] w-[41%]'>
                <div className='flex flex-col items-center justify-center h-full'>
                  <div
                    className={cn('font-extrabold text-6xl lg:text-8xl', {
                      'text-zen-purple-3': isPreCap,
                      'text-green-600': !isPreCap,
                    })}
                  >
                    {capMatchPercent}%
                  </div>
                  <div className='text-black uppercase text-center lg:text-lg !leading-4 font-bold'>
                    <div>real matching</div>
                    <div>grant</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='my-6 text-center'>
              <div className='text-3xl font-bold'>
                <PrimaryGradientText>Maximum Contribution</PrimaryGradientText>
              </div>
              <div className='text-white text-2xl text-center'>
                Real pays bonus shares up to this limit:
              </div>
            </div>
          </div>
          <div className='flex w-full items-center justify-between'>
            <div className='text-neutral-400 text-xl font-semibold'>
              Agent Contribution
            </div>
            <div className='text-neutral-400 text-xl font-semibold'>
              Real Match (Max)
            </div>
          </div>
          <ContributionCard
            cap={12000}
            maxContribution={_12kCapMax}
            realMatchingPercent={capMatchPercent}
            type={type}
          />
          <ContributionCard
            cap={6000}
            maxContribution={_6kCapMax}
            realMatchingPercent={capMatchPercent}
            type={type}
          />
          <ContributionCard
            cap={4000}
            maxContribution={_4kCapMax}
            realMatchingPercent={capMatchPercent}
            type={type}
          />

          <div className='flex flex-col items-center text-lg mt-8'>
            <div
              className='text-primary-blue cursor-pointer border-b-2 border-primary-blue mb-2'
              onClick={scrollToExample}
            >
              See Example
            </div>
            <div className='mt-2 mb-8'>
              <ZenButton
                label={
                  (
                    <div className='flex items-center justify-center'>
                      {isPreCap ? 'Post-Cap' : 'Pre-Cap'} Stock Purchase Plan
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        size='sm'
                        className='ml-1'
                      />
                    </div>
                  ) as any
                }
                isFullWidth
                onClick={handleButtonClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
