import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faAdd } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, values } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form-v7';
import { MdDeleteOutline } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import {
  faAddressBook,
  faAddressCard,
  faBuilding,
  faBuildingColumns,
  faClipboardList,
  faHandHoldingDollar,
  faHandshake,
} from '@fortawesome/pro-regular-svg-icons';
import { DEFAULT_PAGE_SIZE } from '../../constants/TableConstants';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import {
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
  AddressResponseCountryEnum,
  AdministrativeAreaResponseCountryEnum,
  CompanyControllerApi,
  CompanyResponse,
  OfficeResponse,
  OfficeResponseAdditionalFeesAndRebatesEnum,
  OfficeResponsePaymentBatchNameEnum,
  TaxValueTypeEnum,
  UpdateOfficeRequest,
  UpdateOfficeRequestAdditionalFeesAndRebatesEnum,
  UpdateOfficeRequestPaymentBatchNameEnum,
  UpdateOfficeRequestPaymentSystemEnum,
  UpdateOfficeRequestTransactionTypeEnum,
  UserResponseAgentStatusEnum,
} from '../../openapi/yenta';
import AnalyticsService from '../../services/AnalyticsService';
import ErrorService from '../../services/ErrorService';
import {
  bankAccountSearchRequest,
  updateOffice,
  updateOfficeImage,
} from '../../slices/OfficeSlice';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { countryMapState } from '../../testUtils/OnboardingUtils';
import {
  AnalyticsEventEnum,
  AppDispatch,
  AsyncSelectOption,
  ISelectOption,
  OfficeAddressTypeEnum,
  YesNoType,
} from '../../types';
import { isImage } from '../../utils/EventHelper';
import { resizeFile } from '../../utils/FileUtils';
import { getISelectOptionDefaultValue } from '../../utils/FormUtils';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum, MONEY_AMOUNT_REGEX } from '../../utils/StringUtils';
import { searchForAgents } from '../../utils/TableUtils';
import { getValidTaxIdTypes } from '../../utils/TaxIDsUtils';
import { isFeeTypeEnabled } from '../../utils/TransactionUtils';
import {
  EMAIL_VALIDATIONS,
  FILE_VALIDATIONS,
  getPostalCodeValidation,
  PHONE_NUMBER_VALIDATIONS,
} from '../../utils/Validations';
import FormErrorMessage from '../FormErrorMessage';
import ResourceContainer from '../ResourceContainer';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledDatePickerInput from '../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledPhoneNumberInput from '../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledRadioInput from '../Zen/Input/ZenControlledRadioInput';
import GeminiControlledSelectInput from '../Gemini/input/GeminiControlledSelectInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenToggleRow from '../Zen/Input/ZenToggleRow';
import ZenButton from '../Zen/ZenButton';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import { cn } from '../../utils/classUtils';
import GeminiAccordion from '../Gemini/GeminiAccordion';
import GeminiControlledDraggableImageUploadInput from '../Gemini/input/GeminiControlledDraggableImageUploadInput';
import ZenControlledTextAreaInput from '../Zen/Input/ZenControlledTextAreaInput';
import GeminiSidebarModalActionFooter from '../Gemini/Modal/GeminiSidebarModalActionFooter';
import {
  ModifiedTaxValueType,
  RealWithholdsTaxEnum,
} from './ZenCreateOfficeForm';

interface Address {
  streetAddress1: string;
  streetAddress2?: string;
  city: string;
  type?: ISelectOption;
  country?: ISelectOption<
    AddressRequestCountryEnum | AddressResponseCountryEnum
  >;
  stateOrProvince?: ISelectOption;
  zipOrPostalCode: string;
}

interface FormData {
  active: boolean;
  name: string;
  transactionType?: ISelectOption;
  addresses: Address[];
  phoneNumber: string;
  emailAddress: string;
  paymentInstructions: string;
  brokerageLicenseNumber: string;
  licenseExpirationDate: string;
  cdaDocumentTitle: string;
  designatedBrokerId: ISelectOption;
  managingBrokerId?: ISelectOption;
  bankAccountId: ISelectOption;
  trustAccountId?: ISelectOption;
  operatingAccountId?: ISelectOption;
  companyId: ISelectOption;
  faxNumber: string;
  invoiceDocumentTitle: string;
  paysOtherAgent: YesNoType;
  leasePaysOtherAgent: YesNoType;
  acceptsEscrow: YesNoType;
  usesTipalti: YesNoType;
  allowsLease?: YesNoType;
  taxes: ModifiedTaxValueType[];
  paysCommissionFromTrust: YesNoType;
  realWithholdsTax: ISelectOption<RealWithholdsTaxEnum>;
  taxPaidFromCommission: ISelectOption;
  officeImageFile?: File | string;
  additionalCommission: YesNoType;
  rebate: YesNoType;
  transactionCoordinatorFee: YesNoType;
  reimbursementFee: YesNoType;
  mlsFee: YesNoType;
  oneRealImpactFundFee?: YesNoType;
  paymentBatchName: ISelectOption;
}

const PAYMENT_BATCH_NAME_OPTIONS = values(
  OfficeResponsePaymentBatchNameEnum,
).map((value) => ({
  label: capitalizeEnum(value),
  value,
}));

interface GeminiUpdateOfficeFormProps {
  isOpen: boolean;
  onClose(): void;
  officeDetails: OfficeResponse;
}

const GeminiUpdateOfficeForm: React.FC<GeminiUpdateOfficeFormProps> = ({
  isOpen,
  onClose,
  officeDetails,
}) => {
  const [hasOfficeDetailsErrors, setHasOfficeDetailsErrors] = useState<boolean>(
    false,
  );
  const [
    hasContactDetailsErrors,
    setHasContactDetailsErrors,
  ] = useState<boolean>(false);
  const [hasBrokerageOpsErrors, setHasBrokerageOpsErrors] = useState<boolean>(
    false,
  );
  const [hasTaxesErrors, setHasTaxesErrors] = useState<boolean>(false);
  const [hasTransactionsErrors, setHasTransactionsErrors] = useState<boolean>(
    false,
  );
  const [
    hasFinanceDetailsErrors,
    setHasFinanceDetailsErrors,
  ] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const oneRealImpactFundFeeValue = isFeeTypeEnabled(
    officeDetails,
    OfficeResponseAdditionalFeesAndRebatesEnum.OneRealImpactFund,
  )
    ? YesNoType.YES
    : YesNoType.NO;
  const {
    control,
    watch,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    defaultValues: {
      active: !!officeDetails?.active,
      name: officeDetails?.name,
      transactionType: officeDetails?.transactionType
        ? {
            label: capitalizeEnum(officeDetails?.transactionType),
            value: officeDetails?.transactionType!,
          }
        : undefined,
      addresses: [
        {
          type: getISelectOptionDefaultValue(OfficeAddressTypeEnum.OFFICE),
          city: officeDetails?.address?.city || '',
          zipOrPostalCode: officeDetails?.address?.zipOrPostalCode || '',
          streetAddress1: officeDetails?.address?.streetAddress1 || '',
          streetAddress2: officeDetails?.address?.streetAddress2 || '',
          stateOrProvince: getISelectOptionDefaultValue(
            officeDetails?.address?.stateOrProvince,
          ),
          country: getISelectOptionDefaultValue(
            officeDetails?.address?.country,
          ),
        },
      ],
      phoneNumber: officeDetails?.phoneNumber,
      emailAddress: officeDetails?.emailAddress,
      paymentInstructions: officeDetails?.paymentInstructions,
      brokerageLicenseNumber: officeDetails?.brokerageLicenseNumber,
      licenseExpirationDate: officeDetails?.licenseExpirationDate,
      faxNumber: officeDetails?.faxNumber,
      cdaDocumentTitle: officeDetails?.cdaDocumentTitle,
      invoiceDocumentTitle: officeDetails?.invoiceDocumentTitle,
      designatedBrokerId: {
        label: `${officeDetails.designatedBroker?.firstName} ${officeDetails.designatedBroker?.lastName} - ${officeDetails.designatedBroker?.emailAddress}`,
        value: officeDetails?.designatedBroker?.id,
      },
      managingBrokerId: officeDetails.managingBroker
        ? {
            label: `${officeDetails.managingBroker?.firstName} ${officeDetails.managingBroker?.lastName} - ${officeDetails.managingBroker?.emailAddress}`,
            value: officeDetails?.managingBroker?.id,
          }
        : {},
      bankAccountId: {
        label: `${officeDetails?.bankAccount?.bankName} (Account Number: ${officeDetails?.bankAccount?.accountNumber})`,
        value: officeDetails?.bankAccount?.id,
      },
      trustAccountId: officeDetails.trustAccount
        ? {
            label: `${officeDetails?.trustAccount?.bankName} (Account Number: ${officeDetails?.trustAccount?.accountNumber})`,
            value: officeDetails?.trustAccount?.id,
          }
        : {},
      operatingAccountId: officeDetails.operatingAccount
        ? {
            label: `${officeDetails?.operatingAccount?.bankName} (Account Number: ${officeDetails?.operatingAccount?.accountNumber})`,
            value: officeDetails?.operatingAccount?.id,
          }
        : {},
      companyId: {
        label: `${
          officeDetails.administrativeArea?.country ===
          AdministrativeAreaResponseCountryEnum.Canada
            ? 'BN'
            : 'EIN'
        }: ${officeDetails?.company?.ein} - ${officeDetails?.company?.name} (${
          officeDetails?.company?.administrativeArea?.id
        })`,
        value: officeDetails?.company?.id,
      },
      paysOtherAgent: officeDetails?.paysOtherAgent
        ? YesNoType.YES
        : YesNoType.NO,
      leasePaysOtherAgent: officeDetails?.leasePaysOtherAgent
        ? YesNoType.YES
        : YesNoType.NO,
      acceptsEscrow: officeDetails?.acceptsEscrow
        ? YesNoType.YES
        : YesNoType.NO,
      allowsLease: officeDetails?.allowsLease ? YesNoType.YES : YesNoType.NO,
      usesTipalti: officeDetails?.usesTipalti ? YesNoType.YES : YesNoType.NO,
      realWithholdsTax: getISelectOptionDefaultValue(
        officeDetails.realWithholdsTax
          ? RealWithholdsTaxEnum.REAL_HOLDS_AND_REMITS
          : RealWithholdsTaxEnum.AGENT_REMITS,
      ),
      taxPaidFromCommission: getISelectOptionDefaultValue(
        officeDetails.taxPaidFromCommission ? YesNoType.YES : YesNoType.NO,
      ),
      taxes: officeDetails?.taxes?.map((tax) => ({
        ...tax,
        type: getISelectOptionDefaultValue(tax.type),
      })),
      paysCommissionFromTrust: officeDetails?.paysCommissionFromTrust
        ? YesNoType.YES
        : YesNoType.NO,
      officeImageFile: officeDetails?.imageUrl,
      additionalCommission: isFeeTypeEnabled(
        officeDetails,
        OfficeResponseAdditionalFeesAndRebatesEnum.AdditionalCommission,
      )
        ? YesNoType.YES
        : YesNoType.NO,
      rebate: isFeeTypeEnabled(
        officeDetails,
        OfficeResponseAdditionalFeesAndRebatesEnum.Rebate,
      )
        ? YesNoType.YES
        : YesNoType.NO,
      transactionCoordinatorFee: isFeeTypeEnabled(
        officeDetails,
        OfficeResponseAdditionalFeesAndRebatesEnum.TransactionCoordinatorFee,
      )
        ? YesNoType.YES
        : YesNoType.NO,
      reimbursementFee: isFeeTypeEnabled(
        officeDetails,
        OfficeResponseAdditionalFeesAndRebatesEnum.ReimbursementFee,
      )
        ? YesNoType.YES
        : YesNoType.NO,
      mlsFee: isFeeTypeEnabled(
        officeDetails,
        OfficeResponseAdditionalFeesAndRebatesEnum.MlsFee,
      )
        ? YesNoType.YES
        : YesNoType.NO,
      oneRealImpactFundFee: oneRealImpactFundFeeValue,

      paymentBatchName:
        PAYMENT_BATCH_NAME_OPTIONS.find(
          ({ value }) => value === officeDetails.paymentBatchName,
        ) || PAYMENT_BATCH_NAME_OPTIONS[0],
    },
  });

  const {
    fields: addressFields,
    append: addressAppend,
    remove: addressRemove,
  } = useFieldArray({
    control,
    name: 'addresses',
  });

  const { fields, remove, append, update } = useFieldArray({
    control,
    name: 'taxes',
  });

  const taxesErrorMsg = get(errors.taxes, 'message');

  const yesNoOptions: ISelectOption[] = [
    { label: 'Yes', value: YesNoType.YES },
    { label: 'No', value: YesNoType.NO },
  ];

  const previousCountry = useRef(officeDetails?.address?.country);
  const [
    selectedCountry,
    realWithholdsTax,
    taxPaidFromCommission,
    isActive,
  ] = watch([
    `addresses.${0}.country`,
    'realWithholdsTax',
    'taxPaidFromCommission',
    'active',
  ]);

  useEffect(() => {
    if (officeDetails.stateOfficeAddress) {
      addressAppend({
        type: getISelectOptionDefaultValue(OfficeAddressTypeEnum.STATE),
        city: officeDetails?.stateOfficeAddress?.city || '',
        zipOrPostalCode:
          officeDetails?.stateOfficeAddress?.zipOrPostalCode || '',
        streetAddress1: officeDetails?.stateOfficeAddress?.streetAddress1 || '',
        streetAddress2: officeDetails?.stateOfficeAddress?.streetAddress2 || '',
        stateOrProvince: getISelectOptionDefaultValue(
          officeDetails?.stateOfficeAddress?.stateOrProvince,
        ),
        country: getISelectOptionDefaultValue(
          officeDetails?.stateOfficeAddress?.country,
        ),
      });
    }
    if (officeDetails.branchOfficeAddress) {
      addressAppend({
        type: getISelectOptionDefaultValue(OfficeAddressTypeEnum.BRANCH),
        city: officeDetails?.branchOfficeAddress?.city || '',
        zipOrPostalCode:
          officeDetails?.branchOfficeAddress?.zipOrPostalCode || '',
        streetAddress1:
          officeDetails?.branchOfficeAddress?.streetAddress1 || '',
        streetAddress2:
          officeDetails?.branchOfficeAddress?.streetAddress2 || '',
        stateOrProvince: getISelectOptionDefaultValue(
          officeDetails?.branchOfficeAddress?.stateOrProvince,
        ),
        country: getISelectOptionDefaultValue(
          officeDetails?.branchOfficeAddress?.country,
        ),
      });
    }
  }, [
    addressAppend,
    officeDetails.branchOfficeAddress,
    officeDetails.stateOfficeAddress,
  ]);

  useEffect(() => {
    // reset tax ids
    if (previousCountry.current !== selectedCountry?.value) {
      previousCountry.current = selectedCountry?.value as AddressResponseCountryEnum;
      fields.forEach((_, index) => {
        update(index, {
          taxId: null,
          rate: null,
          type: null,
        });
      });
    }
  }, [fields, selectedCountry?.value, update]);

  useDidUpdateEffect(() => {
    if (realWithholdsTax?.value === RealWithholdsTaxEnum.AGENT_REMITS) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.TAX_COLLECTION_CLICK_AGENT_REMITS_ON_OFFICE,
      );
    }
    if (
      realWithholdsTax?.value === RealWithholdsTaxEnum.REAL_HOLDS_AND_REMITS
    ) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.TAX_COLLECTION_CLICK_REAL_HOLDS_AND_REMITS_ON_OFFICE,
      );
    }
  }, [realWithholdsTax]);

  useDidUpdateEffect(() => {
    if (taxPaidFromCommission?.value) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.TAX_COLLECTION_CLICK_TAX_PAID_FROM_COMMISSION_ON_OFFICE,
      );
    }
  }, [taxPaidFromCommission]);

  const onSubmit = async (formValues: FormData) => {
    const address = formValues.addresses[0];
    const additionalFeesAndRebates: Array<UpdateOfficeRequestAdditionalFeesAndRebatesEnum> = [];

    if (formValues.additionalCommission === YesNoType.YES) {
      additionalFeesAndRebates?.push(
        UpdateOfficeRequestAdditionalFeesAndRebatesEnum.AdditionalCommission,
      );
    }
    if (formValues.rebate === YesNoType.YES) {
      additionalFeesAndRebates?.push(
        UpdateOfficeRequestAdditionalFeesAndRebatesEnum.Rebate,
      );
    }

    if (formValues.transactionCoordinatorFee === YesNoType.YES) {
      additionalFeesAndRebates?.push(
        UpdateOfficeRequestAdditionalFeesAndRebatesEnum.TransactionCoordinatorFee,
      );
    }

    if (formValues.reimbursementFee === YesNoType.YES) {
      additionalFeesAndRebates?.push(
        UpdateOfficeRequestAdditionalFeesAndRebatesEnum.ReimbursementFee,
      );
    }

    if (formValues.mlsFee === YesNoType.YES) {
      additionalFeesAndRebates?.push(
        UpdateOfficeRequestAdditionalFeesAndRebatesEnum.MlsFee,
      );
    }

    if (formValues.oneRealImpactFundFee === YesNoType.YES) {
      additionalFeesAndRebates?.push(
        UpdateOfficeRequestAdditionalFeesAndRebatesEnum.OneRealImpactFund,
      );
    }

    const updateOfficeRequest: Required<UpdateOfficeRequest> = {
      address: {
        city: address?.city,
        country: (address?.country
          ?.value as unknown) as AddressRequestCountryEnum,
        stateOrProvince: (address?.stateOrProvince
          ?.value as unknown) as AddressRequestStateOrProvinceEnum,
        streetAddress1: address?.streetAddress1,
        streetAddress2: address?.streetAddress2,
        zipOrPostalCode: address?.zipOrPostalCode,
        type: OfficeAddressTypeEnum.OFFICE,
      },
      active: formValues.active,
      name: formValues.name,
      invoiceDocumentTitle: formValues.invoiceDocumentTitle,
      faxNumber: formValues.faxNumber,
      cdaDocumentTitle: formValues.cdaDocumentTitle,
      brokerageLicenseNumber: formValues.brokerageLicenseNumber,
      licenseExpirationDate: formValues.licenseExpirationDate,
      paymentInstructions: formValues.paymentInstructions,
      emailAddress: formValues.emailAddress,
      phoneNumber: formValues.phoneNumber,
      paymentBatchName: formValues.paymentBatchName
        .value as UpdateOfficeRequestPaymentBatchNameEnum,
      paymentSystem:
        formValues.usesTipalti === YesNoType.YES
          ? UpdateOfficeRequestPaymentSystemEnum.Tipalti
          : UpdateOfficeRequestPaymentSystemEnum.MicrosoftDynamics,
      acceptsEscrow: formValues.acceptsEscrow === YesNoType.YES,
      paysOtherAgent: formValues.paysOtherAgent === YesNoType.YES,
      leasePaysOtherAgent: formValues.leasePaysOtherAgent === YesNoType.YES,
      additionalFeesAndRebates: additionalFeesAndRebates,
      allowsLease: formValues.allowsLease === YesNoType.YES,
      boardIds: [],
      mlsIds: [],
      designatedBrokerId: formValues.designatedBrokerId.value,
      managingBrokerId: formValues.managingBrokerId?.value!,
      bankAccountId: formValues.bankAccountId.value!,
      trustAccountId: formValues.trustAccountId?.value!,
      operatingAccountId: formValues.operatingAccountId?.value!,
      companyId: formValues.companyId.value,
      taxes: formValues.taxes?.map((tax) => ({
        taxId: tax.taxId!,
        rate: tax.rate!,
        type: tax?.type?.value!,
      })),
      //@ts-ignore
      transactionType: formValues.transactionType?.value,
      paysCommissionFromTrust:
        formValues.paysCommissionFromTrust === YesNoType.YES,
      realWithholdsTax:
        formValues.realWithholdsTax?.value ===
        RealWithholdsTaxEnum.REAL_HOLDS_AND_REMITS,
      taxPaidFromCommission:
        formValues.taxPaidFromCommission?.value === YesNoType.YES,
    };

    formValues.addresses.forEach((address) => {
      if (address.type?.value === OfficeAddressTypeEnum.STATE) {
        updateOfficeRequest.stateOfficeAddress = {
          city: address?.city,
          country: (address?.country
            ?.value as unknown) as AddressRequestCountryEnum,
          stateOrProvince: (address?.stateOrProvince
            ?.value as unknown) as AddressRequestStateOrProvinceEnum,
          streetAddress1: address?.streetAddress1,
          streetAddress2: address?.streetAddress2,
          zipOrPostalCode: address?.zipOrPostalCode,
          type: OfficeAddressTypeEnum.STATE,
        };
      }
      if (address.type?.value === OfficeAddressTypeEnum.BRANCH) {
        updateOfficeRequest.branchOfficeAddress = {
          city: address?.city,
          country: (address?.country
            ?.value as unknown) as AddressRequestCountryEnum,
          stateOrProvince: (address?.stateOrProvince
            ?.value as unknown) as AddressRequestStateOrProvinceEnum,
          streetAddress1: address?.streetAddress1,
          streetAddress2: address?.streetAddress2,
          zipOrPostalCode: address?.zipOrPostalCode,
          type: OfficeAddressTypeEnum.BRANCH,
        };
      }
    });

    if (
      updateOfficeRequest.realWithholdsTax !== officeDetails.realWithholdsTax
    ) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.TAX_COLLECTION_EDIT_TAX_PAYMENT_DEFAULT_ON_OFFICE,
        {
          office: {
            id: officeDetails.id,
            taxWithHeld: updateOfficeRequest.realWithholdsTax,
          },
        },
      );
    }

    const officeTaxes = officeDetails.taxes || [];
    updateOfficeRequest.taxes?.forEach((tax, index) => {
      const currentOfficeTax = officeTaxes[index] || {};
      if (tax.rate !== currentOfficeTax.rate) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.TAX_COLLECTION_EDIT_TAX_RATE_ON_OFFICE,
          {
            office: {
              id: officeDetails.id,
              tax: { id: tax.taxId, type: tax.type, rate: tax.rate },
            },
          },
        );
      }
      if (tax.type !== currentOfficeTax.type) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.TAX_COLLECTION_EDIT_TAX_TYPE_ON_OFFICE,
          {
            office: {
              id: officeDetails.id,
              tax: { id: tax.taxId, type: tax.type, rate: tax.rate },
            },
          },
        );
      }
      if (tax.taxId !== currentOfficeTax.taxId) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.TAX_COLLECTION_EDIT_TAX_ID_ON_OFFICE,
          {
            office: {
              id: officeDetails.id,
              tax: { id: tax.taxId, type: tax.type, rate: tax.rate },
            },
          },
        );
      }
    });

    if (isImage(formValues?.officeImageFile! as File)) {
      const resizedImage = await resizeFile(
        formValues?.officeImageFile! as File,
      );
      await dispatch(
        updateOfficeImage(
          officeDetails?.id!,
          (resizedImage! as unknown) as File,
        ),
      );
    }

    await dispatch(updateOffice(officeDetails?.id!, updateOfficeRequest));

    onClose();
  };

  const officeDetailsCard = (): React.ReactNode => {
    return (
      <div className=' bg-regent-200 rounded-2xl p-4'>
        <ZenControlledTextInput<FormData, 'name'>
          control={control}
          label='Office Name'
          name='name'
          placeholder='Enter Office Name'
          rules={{ required: 'Please provide an office name' }}
          shouldUnregister={false}
          isRequired
        />
        <div>
          {addressFields.map((field, index) => {
            const [countrySelected, addresses] = watch([
              `addresses.${index}.country`,
              'addresses',
            ]);
            const showDeleteBtn = index !== 0;
            const otherAddressTypes = addresses
              ?.filter((_, i) => i !== index)
              .map((f) => f.type?.value);

            const officeAddressList =
              index === 0
                ? [OfficeAddressTypeEnum.OFFICE]
                : [OfficeAddressTypeEnum.STATE, OfficeAddressTypeEnum.BRANCH];

            return (
              <div key={field.id} className='relative pb-3'>
                {showDeleteBtn && (
                  <div className='mt-1 absolute top-6 -right-2'>
                    <ZenButton
                      variant='danger-link'
                      LeftIconComponent={<MdDeleteOutline size={20} />}
                      onClick={() => addressRemove(index)}
                    />
                  </div>
                )}
                <div className='mt-5'>
                  <GeminiControlledSelectInput<
                    FormData,
                    `addresses.${number}.type`
                  >
                    control={control}
                    label='Address Type'
                    name={`addresses.${index}.type`}
                    placeholder='Choose address type'
                    options={officeAddressList.map((type) => ({
                      value: type,
                      label: capitalizeEnum(type),
                    }))}
                    rules={{
                      required: 'Required',
                      validate: (val) => {
                        if (
                          val?.value &&
                          otherAddressTypes?.includes(val.value)
                        ) {
                          return "Can't have multiple addresses of the same type";
                        }
                        return undefined;
                      },
                    }}
                    isRequired
                  />
                </div>
                <div className='mt-5'>
                  <ZenControlledTextInput<
                    FormData,
                    `addresses.${number}.streetAddress1`
                  >
                    control={control}
                    name={`addresses.${index}.streetAddress1`}
                    placeholder='Enter the address'
                    label='Street Address Line 1'
                    rules={{
                      required: 'Please enter the street address',
                    }}
                    isRequired
                  />
                </div>
                <div className='mt-5'>
                  <ZenControlledTextInput<
                    FormData,
                    `addresses.${number}.streetAddress2`
                  >
                    control={control}
                    name={`addresses.${index}.streetAddress2`}
                    placeholder='#100'
                    label='Street Address Line 2'
                  />
                </div>
                <div className='flex items-center gap-4'>
                  <div className='mt-5 w-1/2'>
                    <GeminiControlledSelectInput<
                      FormData,
                      `addresses.${number}.country`
                    >
                      name={`addresses.${index}.country`}
                      control={control}
                      label='Country'
                      placeholder='Select Country'
                      options={values(AddressRequestCountryEnum).map(
                        (state) => ({
                          value: state,
                          label: capitalizeEnum(state),
                        }),
                      )}
                      rules={{ required: 'Please select a country' }}
                      isRequired
                    />
                  </div>

                  <div className='mt-5 w-1/2'>
                    <ZenControlledTextInput<
                      FormData,
                      `addresses.${number}.city`
                    >
                      control={control}
                      label='City'
                      name={`addresses.${index}.city`}
                      placeholder='City'
                      rules={{ required: 'Please enter the city' }}
                      isRequired
                    />
                  </div>
                </div>
                <div className='flex items-center gap-4'>
                  <div className='mt-5 w-1/2'>
                    <GeminiControlledSelectInput<
                      FormData,
                      `addresses.${number}.stateOrProvince`
                    >
                      name={`addresses.${index}.stateOrProvince`}
                      control={control}
                      label='State / Province'
                      placeholder='Select State or Province'
                      options={
                        countryMapState[
                          ((countrySelected?.value as unknown) as AddressRequestCountryEnum) ||
                            AddressRequestCountryEnum.UnitedStates
                        ]
                      }
                      rules={{ required: 'Please select a state / province.' }}
                      shouldUnregister={false}
                      isRequired
                    />
                  </div>
                  <div className='mt-5 w-1/2'>
                    <ZenControlledTextInput<
                      FormData,
                      `addresses.${number}.zipOrPostalCode`
                    >
                      control={control}
                      label='Postal Code'
                      name={`addresses.${index}.zipOrPostalCode`}
                      placeholder='Postal Code'
                      rules={{
                        required: 'Please enter the postal code',
                        ...getPostalCodeValidation(
                          countrySelected?.value as AddressRequestCountryEnum,
                        ),
                      }}
                      isRequired
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <button
            className='mt-2 flex justify-end space-x-2 w-full'
            type='button'
            disabled={isAddAnotherAddressDisabled}
            onClick={() =>
              addressAppend({
                country: undefined,
                type: undefined,
                city: undefined,
                stateOrProvince: undefined,
                streetAddress1: undefined,
                streetAddress2: undefined,
                zipOrPostalCode: undefined,
              })
            }
          >
            <FontAwesomeIcon
              icon={faAdd}
              className={cn(
                'text-base',
                isAddAnotherAddressDisabled
                  ? 'text-zen-dark-4'
                  : 'text-primary-blue',
              )}
            />
            <p
              className={cn(
                'text-sm font-zen-body font-semibold',
                isAddAnotherAddressDisabled
                  ? 'text-zen-dark-4'
                  : 'text-primary-blue',
              )}
            >
              Add another address
            </p>
          </button>
        </div>
      </div>
    );
  };
  const contactDetailsCard = (): React.ReactNode => {
    return (
      <div className=' bg-regent-200 rounded-2xl p-4'>
        <div className='w-full flex flex-col md:flex-row md:gap-4'>
          <div className='mt-5 md:w-1/2'>
            <ZenControlledPhoneNumberInput<FormData, 'phoneNumber'>
              control={control}
              label='Main Phone Number'
              name='phoneNumber'
              placeholder='+1 (702) 123-4567'
              rules={{
                required: 'Please enter the phone number',
                ...PHONE_NUMBER_VALIDATIONS,
              }}
              shouldUnregister={false}
              isRequired
            />
          </div>
          <div className='mt-5 md:w-1/2'>
            <ZenControlledPhoneNumberInput<FormData, 'faxNumber'>
              control={control}
              label='Fax Number'
              name='faxNumber'
              placeholder='+1 (212) 123-4567'
              shouldUnregister={false}
              rules={{ required: 'Please enter the fax number' }}
              isRequired
            />
          </div>
        </div>
        <div className='mt-5'>
          <ZenControlledTextInput<FormData, 'emailAddress'>
            control={control}
            // type='email'
            label='Email Address'
            name='emailAddress'
            placeholder='Enter the email address'
            rules={{
              required: 'Please enter an email address',
              ...EMAIL_VALIDATIONS,
            }}
            shouldUnregister={false}
            isRequired
          />
        </div>
      </div>
    );
  };
  const brokerageOpsCard = (): React.ReactNode => {
    return (
      <div className=' bg-regent-200 rounded-2xl p-4'>
        <div className='mt-5'>
          <ZenControlledTextInput<FormData, 'brokerageLicenseNumber'>
            control={control}
            label='Brokerage License Number'
            name='brokerageLicenseNumber'
            placeholder='Enter the brokerage license number'
            shouldUnregister={false}
          />
        </div>
        <div className='mt-5'>
          <ZenControlledDatePickerInput<FormData, 'licenseExpirationDate'>
            control={control}
            label='Expiration date of Brokerage License'
            name='licenseExpirationDate'
            shouldUnregister={false}
            placeholder='MM/DD/YYYY'
            datePickerConfig={{
              minDate: DateTime.local().toJSDate(),
            }}
            icon={
              <FontAwesomeIcon
                icon={light('calendar')}
                className='text-primary-blue text-lg flex items-center justify-center'
              />
            }
          />
        </div>
        <div className='mt-5'>
          <ZenControlledAsyncSelectInput<FormData, 'designatedBrokerId'>
            control={control}
            name='designatedBrokerId'
            label='Designated Broker'
            fetchData={async (search, page) => {
              try {
                const { data } = await searchForAgents({
                  page,
                  pageSize: 20,
                  search,
                  filterBy: {
                    agentStatus: [UserResponseAgentStatusEnum.Active],
                  },
                });

                const options: AsyncSelectOption[] = data.map((resp) => ({
                  value: `${resp.id}`,
                  label: `${resp.firstName} ${resp.lastName} - ${resp.emailAddress}`,
                }));

                return options;
              } catch (e) {
                ErrorService.notify(
                  'Unable to search request registered agents in update office form',
                  e,
                  {
                    search: {
                      term: search,
                      page,
                    },
                  },
                );
                dispatch(
                  showErrorToast(
                    'An unexpected error occurred.',
                    'We were unable to search for an registered agent. Please try again in a few moments or contact support.',
                  ),
                );
              }

              return [];
            }}
            rules={{ required: 'Please select a designated broker' }}
            shouldUnregister={false}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledAsyncSelectInput<FormData, 'managingBrokerId'>
            control={control}
            name='managingBrokerId'
            label='Managing Broker'
            fetchData={async (search, page) => {
              try {
                const { data } = await searchForAgents({
                  page,
                  search,
                  pageSize: 20,
                  filterBy: {
                    agentStatus: [UserResponseAgentStatusEnum.Active],
                  },
                });

                const options: AsyncSelectOption[] = data.map((resp) => ({
                  value: `${resp.id}`,
                  label: `${resp.firstName} ${resp.lastName} - ${resp.emailAddress}`,
                }));

                return options;
              } catch (e) {
                ErrorService.notify(
                  'Unable to search request registered agents in update office form',
                  e,
                  {
                    search: {
                      term: search,
                      page,
                    },
                  },
                );
                dispatch(
                  showErrorToast(
                    'An unexpected error occurred.',
                    'We were unable to search for an registered agent. Please try again in a few moments or contact support.',
                  ),
                );
              }

              return [];
            }}
            shouldUnregister={false}
          />
        </div>
      </div>
    );
  };
  const financeDetailsCard = (): React.ReactNode => {
    return (
      <div className='bg-regent-200 rounded-2xl p-4'>
        <div className='mt-5'>
          <ZenControlledAsyncSelectInput<FormData, 'bankAccountId'>
            control={control}
            name='bankAccountId'
            label='Bank Account'
            fetchData={async (search, page) => {
              const searchResponse = await dispatch(
                bankAccountSearchRequest(['BANK_NAME'], 'ASC', search, page),
              );

              const options: AsyncSelectOption[] = (
                searchResponse?.results || []
              ).map((resp) => ({
                value: `${resp.id}`,
                label: `${resp.bankName} (Account Number: ${resp.accountNumber})`,
              }));

              return options;
            }}
            rules={{ required: 'Please select a bank account' }}
            shouldUnregister={false}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledAsyncSelectInput<FormData, 'trustAccountId'>
            control={control}
            name='trustAccountId'
            label='Trust Account'
            fetchData={async (search, page) => {
              const searchResponse = await dispatch(
                bankAccountSearchRequest(['BANK_NAME'], 'ASC', search, page),
              );

              const options: AsyncSelectOption[] = (
                searchResponse?.results || []
              ).map((resp) => ({
                value: `${resp.id}`,
                label: `${resp.bankName} (Account Number: ${resp.accountNumber})`,
              }));

              return options;
            }}
            shouldUnregister={false}
          />
        </div>
        <div className='mt-5'>
          <ZenControlledAsyncSelectInput<FormData, 'operatingAccountId'>
            control={control}
            name='operatingAccountId'
            label='Operating Account'
            fetchData={async (search, page) => {
              const searchResponse = await dispatch(
                bankAccountSearchRequest(['BANK_NAME'], 'ASC', search, page),
              );

              const options: AsyncSelectOption[] = (
                searchResponse?.results || []
              ).map((resp) => ({
                value: `${resp.id}`,
                label: `${resp.bankName} (Account Number: ${resp.accountNumber})`,
              }));

              return options;
            }}
            shouldUnregister={false}
          />
        </div>
        <div className='mt-5'>
          <ZenControlledAsyncSelectInput<FormData, 'companyId'>
            control={control}
            name='companyId'
            label='Company'
            fetchData={async (search, page) => {
              try {
                const { data } = await new CompanyControllerApi(
                  getYentaConfiguration(),
                ).searchCompanies(
                  ['ID', 'NAME', 'EIN'],
                  'ASC',
                  page,
                  DEFAULT_PAGE_SIZE,
                  search,
                );

                const options: AsyncSelectOption[] = (data?.results || []).map(
                  (resp: CompanyResponse) => ({
                    value: `${resp.id}`,
                    label: `${
                      resp.administrativeArea?.country ===
                      AdministrativeAreaResponseCountryEnum.Canada
                        ? 'BN'
                        : 'EIN'
                    }: ${resp.ein} - ${resp.name} (${
                      resp.administrativeArea?.id
                    })`,
                  }),
                );

                return options;
              } catch (e) {
                ErrorService.notify(
                  'Unable to search request companies in update office form',
                  e,
                  {
                    search: {
                      term: search,
                      page,
                    },
                  },
                );
                dispatch(
                  showErrorToast(
                    'An unexpected error occurred.',
                    'We were unable to search for an company. Please try again in a few moments or contact support.',
                  ),
                );
              }

              return [];
            }}
            rules={{ required: 'Please select a company' }}
            shouldUnregister={false}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledRadioInput<FormData, `acceptsEscrow`>
            name='acceptsEscrow'
            control={control}
            label='Accepts Escrow'
            options={yesNoOptions}
            shouldUnregister={false}
            inlineOptions
          />
        </div>
        <div className='mt-5'>
          <ZenControlledRadioInput<FormData, `paysCommissionFromTrust`>
            name='paysCommissionFromTrust'
            control={control}
            label='Pays Commission From Trust'
            options={yesNoOptions}
            shouldUnregister={false}
            inlineOptions
          />
        </div>

        <div className='mt-5'>
          <ZenControlledRadioInput<FormData, `usesTipalti`>
            name='usesTipalti'
            control={control}
            label='Uses Tipalti'
            options={yesNoOptions}
            shouldUnregister={false}
            inlineOptions
          />
        </div>
        <div className='mt-5'>
          <GeminiControlledSelectInput<FormData, `paymentBatchName`>
            name='paymentBatchName'
            control={control}
            label='Payment Batch Name'
            placeholder='Select Payment Batch Name'
            options={values(OfficeResponsePaymentBatchNameEnum).map(
              (value) => ({
                label: capitalizeEnum(value),
                value,
              }),
            )}
          />
        </div>
        <div className='mt-5'>
          <GeminiControlledSelectInput<FormData, `realWithholdsTax`>
            name='realWithholdsTax'
            control={control}
            label='Tax Payment Default'
            placeholder='Tax Payment Default'
            options={values(RealWithholdsTaxEnum).map((v) => ({
              label: capitalizeEnum(v),
              value: v,
            }))}
          />
        </div>
        <div className='mt-5'>
          <GeminiControlledSelectInput<FormData, `taxPaidFromCommission`>
            name='taxPaidFromCommission'
            control={control}
            label='Default Tax Paid from Commission'
            placeholder='Default Tax Paid from Commission'
            options={values(YesNoType).map((v) => ({
              label: capitalizeEnum(v),
              value: v,
            }))}
          />
        </div>
      </div>
    );
  };
  const taxesCard = (): React.ReactNode => {
    return (
      <div className=' bg-regent-200 rounded-2xl p-4'>
        {taxesErrorMsg && <FormErrorMessage message={taxesErrorMsg} />}
        <ResourceContainer
          loading={false}
          isEmpty={!fields.length}
          resourceName='tax'
          emptyMessage='There are no taxes to display'
          emptyIcon={<FontAwesomeIcon icon={faAddressCard} size='3x' />}
        >
          {fields.map((field, index) => {
            const currentField = watch('taxes')?.[index];
            const isTaxTypeNotGRT =
              currentField?.type?.value !== TaxValueTypeEnum.Grt;

            return (
              <div className='mt-2 p-3 rounded-md relative' key={field.id}>
                <div className='mt-2'>
                  <GeminiControlledSelectInput<FormData, `taxes.${number}.type`>
                    name={`taxes.${index}.type` as const}
                    control={control}
                    label='Tax Type'
                    placeholder='Select Tax Type'
                    options={getValidTaxIdTypes(
                      selectedCountry?.value as AddressRequestCountryEnum,
                    ).map((tax) => ({
                      value: tax,
                      label: capitalizeEnum(tax),
                    }))}
                    rules={{
                      required: 'Please select tax type',
                      validate: (value) => {
                        if (
                          getValues().taxes.filter(
                            (tax) => tax?.type?.value === value?.value,
                          ).length > 1
                        ) {
                          return 'You cannot have two taxes of the same type.';
                        }

                        return undefined;
                      },
                    }}
                    isRequired
                  />
                </div>
                <div className='flex flex-row justify-between gap-4'>
                  <div className='mt-2 w-1/2'>
                    <ZenControlledTextInput<FormData, `taxes.${number}.taxId`>
                      control={control}
                      label='Tax ID'
                      name={`taxes.${index}.taxId` as const}
                      rules={{ required: 'Please enter tax id' }}
                      isRequired
                    />
                  </div>
                  {isTaxTypeNotGRT && (
                    <div className='mt-2 w-1/2'>
                      <ZenControlledTextInput<FormData, `taxes.${number}.rate`>
                        control={control}
                        label='Tax Rate'
                        name={`taxes.${index}.rate` as const}
                        rules={{
                          required: 'Please enter tax rate',
                          pattern: {
                            value: MONEY_AMOUNT_REGEX,
                            message: 'Please enter valid rate',
                          },
                        }}
                        isRequired
                      />
                    </div>
                  )}
                </div>
                <div className='absolute right-1 top-1'>
                  <ZenButton
                    variant='danger-link'
                    LeftIconComponent={<MdDeleteOutline size={20} />}
                    onClick={() => remove(index)}
                  />
                </div>
              </div>
            );
          })}
        </ResourceContainer>
        <div className='flex justify-end  mt-4 w-full'>
          <ZenButton
            variant='primary-link'
            type='button'
            label='Add Another Tax Section'
            LeftIconComponent={
              <FontAwesomeIcon
                icon={faAdd}
                className='text-base text-primary-blue'
              />
            }
            onClick={() =>
              append({
                type: undefined,
                taxId: undefined,
                rate: undefined,
              })
            }
          />
        </div>
      </div>
    );
  };
  const transactionsCard = (): React.ReactNode => {
    return (
      <div className=' bg-regent-200 rounded-2xl p-4'>
        <div className='mt-5'>
          <GeminiControlledSelectInput<FormData, `transactionType`>
            control={control}
            name='transactionType'
            placeholder='Select Transaction Type'
            label='Transaction Type'
            options={values(UpdateOfficeRequestTransactionTypeEnum).map(
              (type) => ({
                label: capitalizeEnum(type),
                value: type,
              }),
            )}
            rules={{ required: 'Please select a transaction type' }}
            shouldUnregister={false}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledTextAreaInput<FormData, 'paymentInstructions'>
            control={control}
            label='Payment Instructions'
            name='paymentInstructions'
            placeholder='Enter the payment instructions'
            shouldUnregister={false}
            rows={3}
          />
        </div>
        <div className='mt-5'>
          <ZenControlledTextInput<FormData, 'cdaDocumentTitle'>
            control={control}
            label='Commission Document Title'
            name='cdaDocumentTitle'
            placeholder='Enter the Commission Document title'
            rules={{ required: 'Please enter the Commission Document title' }}
            shouldUnregister={false}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledTextInput<FormData, 'invoiceDocumentTitle'>
            control={control}
            label='Invoice Document Title'
            name='invoiceDocumentTitle'
            placeholder='Enter the invoice document title'
            rules={{ required: 'Please enter the invoice document title' }}
            shouldUnregister={false}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledRadioInput<FormData, `paysOtherAgent`>
            name='paysOtherAgent'
            control={control}
            label='Sale Pays Other Agent'
            options={yesNoOptions}
            shouldUnregister={false}
            inlineOptions
          />
        </div>
        <div className='mt-5'>
          <ZenControlledRadioInput<FormData, `leasePaysOtherAgent`>
            name='leasePaysOtherAgent'
            control={control}
            label='Lease Pays Other Agent'
            options={yesNoOptions}
            shouldUnregister={false}
            inlineOptions
          />
        </div>
        <div className='mt-5'>
          <ZenControlledRadioInput<FormData, `allowsLease`>
            name='allowsLease'
            control={control}
            label='Lease Allowed'
            options={yesNoOptions}
            shouldUnregister={false}
            inlineOptions
          />
        </div>
      </div>
    );
  };
  const additionalFeesAndRebatesCard = (): React.ReactNode => {
    return (
      <div className='bg-regent-200 rounded-2xl p-4'>
        <div className='w-full flex divide-x-2'>
          <div className='mt-5 w-1/2'>
            <ZenControlledRadioInput<FormData, 'additionalCommission'>
              name='additionalCommission'
              label='Additional Commission'
              rules={{
                required: 'Please choose one',
              }}
              options={yesNoOptions}
              control={control}
              inlineOptions
              shouldUnregister={false}
            />
          </div>
          <div className='mt-5 w-1/2 pl-10'>
            <ZenControlledRadioInput<FormData, 'reimbursementFee'>
              name='reimbursementFee'
              label='Reimbursement'
              rules={{
                required: 'Please choose one',
              }}
              options={yesNoOptions}
              control={control}
              inlineOptions
              shouldUnregister={false}
            />
          </div>
        </div>
        <div className='w-full flex divide-x-2'>
          <div className='mt-5 w-1/2'>
            <ZenControlledRadioInput<FormData, 'rebate'>
              name='rebate'
              label='Rebate'
              rules={{
                required: 'Please choose one',
              }}
              options={yesNoOptions}
              control={control}
              inlineOptions
              shouldUnregister={false}
            />
          </div>
          <div className='mt-5 w-1/2 pl-10'>
            <ZenControlledRadioInput<FormData, 'mlsFee'>
              name='mlsFee'
              label='MLS'
              rules={{
                required: 'Please choose one',
              }}
              options={[
                {
                  label: 'Yes',
                  value: YesNoType.YES,
                },
                {
                  label: 'No',
                  value: YesNoType.NO,
                },
              ]}
              control={control}
              inlineOptions
              shouldUnregister={false}
            />
          </div>
        </div>
        <div className='w-full flex divide-x-2'>
          <div className='mt-5 w-1/2'>
            <ZenControlledRadioInput<FormData, 'transactionCoordinatorFee'>
              name='transactionCoordinatorFee'
              label='Transaction Coordinator'
              rules={{
                required: 'Please choose one',
              }}
              options={yesNoOptions}
              control={control}
              inlineOptions
              shouldUnregister={false}
            />
          </div>
          <div className='mt-5 w-1/2 pl-10'>
            <ZenControlledRadioInput<FormData, 'oneRealImpactFundFee'>
              name='oneRealImpactFundFee'
              label='One Real Impact'
              rules={{
                required: 'Please choose one',
              }}
              options={yesNoOptions}
              control={control}
              inlineOptions
              shouldUnregister={false}
            />
          </div>
        </div>
      </div>
    );
  };
  const handleSubmitClicked = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    const ok = await trigger();
    if (!ok) {
      setHasOfficeDetailsErrors('name' in errors || 'addresses' in errors);
      setHasContactDetailsErrors(
        'phoneNumber' in errors ||
          'emailAddress' in errors ||
          'faxNumber' in errors,
      );
      setHasBrokerageOpsErrors('designatedBrokerId' in errors);
      setHasFinanceDetailsErrors(
        'bankAccountId' in errors || 'companyId' in errors,
      );
      setHasTaxesErrors('taxes' in errors);
      setHasTransactionsErrors(
        'transactionType' in errors || 'cdaDocumentTitle' in errors,
      );
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const isAddAnotherAddressDisabled = addressFields.length >= 3;
  return (
    <ZenSidebarModal
      title='Edit Office Details'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmitClicked}
        title='update-office-form'
      >
        <div className='p-6 pb-4'>
          <div className='mb-3 flex flex-row justify-end'>
            <ZenToggleRow
              loading={false}
              value={isActive}
              onChange={(value) => setValue('active', value)}
              title={isActive ? 'Active' : 'Inactive'}
            />
          </div>
          <div className='mt-5'>
            <GeminiControlledDraggableImageUploadInput<
              FormData,
              'officeImageFile'
            >
              name='officeImageFile'
              placeholder='Upload Image'
              control={control}
              fileInputSize='small'
              onDelete={() => {
                setValue('officeImageFile', undefined);
              }}
              rules={{
                ...FILE_VALIDATIONS,
              }}
            />
          </div>
          <GeminiAccordion
            title='Office Details'
            leftIcon={faBuilding}
            isOpenDefault={hasOfficeDetailsErrors}
          >
            {officeDetailsCard()}
          </GeminiAccordion>
          <GeminiAccordion
            title='Contact Details'
            leftIcon={faAddressBook}
            isOpenDefault={hasContactDetailsErrors}
          >
            {contactDetailsCard()}
          </GeminiAccordion>
          <GeminiAccordion
            title='Brokerage Ops'
            leftIcon={faHandshake}
            isOpenDefault={hasBrokerageOpsErrors}
          >
            {brokerageOpsCard()}
          </GeminiAccordion>
          <GeminiAccordion
            title='Finance Details'
            leftIcon={faBuildingColumns}
            isOpenDefault={hasFinanceDetailsErrors}
          >
            {financeDetailsCard()}
          </GeminiAccordion>
          <GeminiAccordion
            title='Taxes'
            leftIcon={faAddressCard}
            isOpenDefault={hasTaxesErrors}
          >
            {taxesCard()}
          </GeminiAccordion>
          <GeminiAccordion
            title='Transactions'
            leftIcon={faClipboardList}
            isOpenDefault={hasTransactionsErrors}
          >
            {transactionsCard()}
          </GeminiAccordion>
          <GeminiAccordion
            title='Additional Fees & Rebates'
            leftIcon={faHandHoldingDollar}
          >
            {additionalFeesAndRebatesCard()}
          </GeminiAccordion>
        </div>
        <GeminiSidebarModalActionFooter
          submitButtonText='Save'
          isSubmitting={isSubmitting}
          onClose={onClose}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default GeminiUpdateOfficeForm;
