import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form-v7';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalActionFooter from '../../SidebarModal/ZenSideBarModalActionFooter';
import { updatePriorAgent } from '../../../slices/AgentSlice';

interface ZenAgentManagePreviousUserSidebarModalProps {
  onClose(): void;
  agentId: string;
}
interface FormData {
  priorAgentId: string;
}

const ZenAgentManagePreviousUserSidebarModal: React.FC<ZenAgentManagePreviousUserSidebarModalProps> = ({
  onClose,
  agentId,
}) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    await dispatch(updatePriorAgent(agentId, data.priorAgentId));
    onClose();
  };

  return (
    <ZenSidebarModal isOpen onClose={onClose} title='Add Previous User'>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col justify-between min-h-full'
      >
        <div className='p-4'>
          <div>
            <ZenControlledTextInput
              label='Prior User ID'
              name='priorAgentId'
              control={control}
              placeholder='12345678-1111-2222-3333-12345678'
              rules={{ required: 'Prior User ID is required' }}
              isRequired
            />
          </div>
        </div>
        <ZenSidebarModalActionFooter
          onClose={onClose}
          isSubmitting={isSubmitting}
          submitButtonDisabled={isSubmitting}
          submitButtonText='Add'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenAgentManagePreviousUserSidebarModal;
