import React from 'react';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSelector } from 'react-redux';
import { displayFormattedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import ZenPill from '../../Zen/ZenPill';
import AdminOnly from '../../auth/AdminOnly';
import {
  isTransactionOrFinanceAdmin,
  isTransactionReadOnly,
} from '../../../utils/TransactionHelper';
import HoverItem from '../../HoverItem';
import {
  EscrowDepositResponse,
  EscrowResponse,
  EscrowResponseEscrowTypeEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { RootState } from '../../../types';
import { getAgentFullName } from '../../../utils/AgentHelper';
import { isExternDeposit } from '../../../utils/DepositsUtil';

interface ZenDepositDetailInstallmentTableProps {
  deposit: EscrowResponse;
  transaction: TransactionResponse;
  onViewCheckDeposit(id: string): void;
  onViewProofOfDeposit(id: string): void;
  onConfirm(deposit: EscrowDepositResponse): void;
  onEdit(deposit: EscrowDepositResponse): void;
}

const ZenDepositDetailInstallmentTable: React.FC<ZenDepositDetailInstallmentTableProps> = ({
  deposit,
  transaction,
  onViewCheckDeposit,
  onViewProofOfDeposit,
  onEdit,
  onConfirm,
}) => {
  const {
    auth: { userDetail, keymakerCurrentUser },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  const allowConfirmOrEdit =
    !isTransactionReadOnly(transaction, userDetail!) ||
    (isTransactionOrFinanceAdmin(keymakerCurrentUser?.activeRoles!) &&
      transaction.terminated);

  const openReceipt = (depositId: string) => {
    const depositType =
      deposit.escrowType === EscrowResponseEscrowTypeEnum.Commission
        ? 'commission-deposit-receipt'
        : 'trust-deposit-receipt';
    window.open(
      `/transactions/${transaction.id!}/${depositType}/${depositId}`,
      '_blank',
    );
  };

  return (
    <table className='w-full relative table-auto'>
      <thead className='hidden lg:table-header-group text-grey-600 text-sm font-zen-body font-semibold border-b border-zen-light-gray-2'>
        <tr>
          <th className=' align-top text-left py-2 whitespace-nowrap'>No.</th>
          <th className=' align-top text-left pl-8 py-2 whitespace-nowrap'>
            Installments
          </th>
          <th className=' align-top text-left pl-12 py-2 whitespace-nowrap'>
            Confirmation
          </th>
          <th className=' align-top text-left pl-12 py-2 whitespace-nowrap'>
            Actions
          </th>
        </tr>
      </thead>
      <tbody className='grid grid-cols-1 gap-4 lg:table-row-group'>
        {deposit.deposits?.map((p, index) => {
          const depositCreatedByAt = `Created by ${
            !!p?.createdBy
              ? `${getAgentFullName({
                  firstName: agentById[p?.createdBy]?.firstName,
                  lastName: agentById[p?.createdBy]?.lastName,
                })}`
              : isExternDeposit(deposit?.deposits || [])
              ? p?.depositorName
              : 'N/A'
          } on ${DateTime.fromMillis(p.createdAt!).toFormat('LL/dd/yy')}.`;

          return (
            <tr
              key={p.createdAt}
              className='grid grid-cols-1 lg:table-row divide-y lg:divide-y-0 border border-zen-light-gray-2 lg:border-none text-center text-zen-dark-9 text-sm font-zen-body'
            >
              <td className='py-4 lg:py-0 px-5'>
                <div className='lg:mt-4 flex flex-col lg:block items-start'>
                  <p className='lg:hidden mb-1 uppercase'>No.</p>
                  <div className='inline-block bg-zen-dark-9 w-7 h-7 rounded-full'>
                    <div className='text-sm pt-0.5 font-zen-body font-semibold text-white '>
                      {++index}
                    </div>
                  </div>
                </div>
              </td>
              <td className='w-full px-5 lg:pl-8 py-4 align-middle text-left'>
                <div className='flex flex-col lg:block items-start'>
                  <p className='lg:hidden mb-1 uppercase'>Installments</p>
                  <div className='space-y-0.5'>
                    <div>
                      <span>
                        {displayFormattedAmountWithCurrency(p.amount)}
                      </span>{' '}
                      | <span>Receipt Number: {p.receiptNumber}</span> |{' '}
                      <span>
                        Received on:{' '}
                        {DateTime.fromISO(p.dateReceived!).toFormat('LL/dd/yy')}
                      </span>
                    </div>
                    <div className='text-zen-dark-6 font-semibold space-x-1.5'>
                      <span>{depositCreatedByAt}</span>
                      {p.checkDepositId && (
                        <span
                          className='text-primary-blue cursor-pointer'
                          onClick={() => onViewCheckDeposit(p.checkDepositId!)}
                        >
                          View Check
                        </span>
                      )}
                      {p.receiptImagePath && (
                        <span
                          className='text-primary-blue cursor-pointer'
                          onClick={() => onViewProofOfDeposit(p.id!)}
                        >
                          View Proof of deposit
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </td>
              <td className='px-5 lg:pl-12 py-4 align-middle text-left'>
                <div className='flex flex-col lg:block items-start'>
                  <p className='lg:hidden mb-1 uppercase'>Confirmation</p>
                  <div className='w-36'>
                    <div className='w-fit'>
                      <ZenPill
                        title={
                          p.confirmedAt
                            ? 'Confirmed'
                            : p.checkDepositId
                            ? 'Pending'
                            : 'Not Confirmed'
                        }
                        variant={p.confirmedAt ? 'success' : 'warning'}
                        leftIcon={
                          <FontAwesomeIcon
                            icon={
                              p.confirmedAt
                                ? regular('thumbs-up')
                                : regular('hourglass-half')
                            }
                            size='sm'
                            className='pr-1'
                          />
                        }
                        border={false}
                        textStyle='text-sm py-0.5 whitespace-nowrap'
                      />
                    </div>
                  </div>
                </div>
              </td>
              <td className='px-5 lg:pl-12 py-4 align-middle text-left'>
                <div className='flex flex-col lg:block items-start'>
                  <p className='lg:hidden mb-1 uppercase'>Actions</p>
                  <div className='flex space-x-2.5 text-zen-dark-9 items-center'>
                    <AdminOnly>
                      {allowConfirmOrEdit && !p.confirmedAt && (
                        <HoverItem
                          onClick={() => onConfirm(p)}
                          label='Confirm'
                          icon={
                            <FontAwesomeIcon
                              icon={regular('check')}
                              size='xl'
                              className='text-green-600 px-0.5'
                            />
                          }
                        />
                      )}
                    </AdminOnly>
                    {p.confirmedAt && (
                      <HoverItem
                        onClick={() => openReceipt(p.id!)}
                        label='View Receipt'
                        icon={
                          <FontAwesomeIcon
                            icon={light('receipt')}
                            size='lg'
                            className='text-zen-dark-9 px-1'
                          />
                        }
                      />
                    )}
                    {allowConfirmOrEdit && (
                      <HoverItem
                        onClick={() => onEdit(p)}
                        label='Edit'
                        disabled={!!p.confirmedAt || !!p.checkDepositId}
                        icon={
                          <FontAwesomeIcon
                            icon={light('pencil')}
                            size='lg'
                            className='text-zen-dark-9 px-0.5'
                            aria-label='edit-installment'
                          />
                        }
                      />
                    )}
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ZenDepositDetailInstallmentTable;
