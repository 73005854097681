import { FrontLineAgentResponseStatusEnum } from '../../openapi/arrakis';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenAvatar from '../Zen/ZenAvatar';
import ZenPill from '../Zen/ZenPill';

export interface ZenNetworkHeaderProps {
  name: string;
  imageUrl: string;
  status: FrontLineAgentResponseStatusEnum;
  tier: number;
}

const ZenNetworkHeader: React.FC<ZenNetworkHeaderProps> = ({
  name,
  imageUrl,
  status,
  tier,
}) => {
  return (
    <div
      className='py-2.5 px-4 flex justify-between items-center bg-grey-100'
      title='network-header'
    >
      <div className='flex items-center'>
        <ZenAvatar name={name} imageUrl={imageUrl} size='sm' />
        <div className='px-3'>
          <p className='font-zen-body text-base font-semibold'>
            {name}
            {status !== FrontLineAgentResponseStatusEnum.Active && (
              <span className='ml-2'>{`(${capitalizeEnum(status)})`}</span>
            )}
          </p>
        </div>
      </div>
      <ZenPill title={`Tier ${tier}`} variant='gray' />
    </div>
  );
};

export default ZenNetworkHeader;
