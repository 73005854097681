import React, { forwardRef, useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
  Validate,
} from 'react-hook-form-v7';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormFieldTooltipIndexProps } from '../../../types';
import {
  MAX_YEAR_ALLOWED,
  getAutoFormattedDate,
} from '../../../utils/DateUtils';
import { cn } from '../../../utils/classUtils';
import {
  ControlledTextInputDirection,
  ControlledTextInputModeProps,
  ControlledTextInputType,
} from './ZenControlledTextInput';
import ZenFormErrorMessage from './ZenFormErrorMessage';

export interface ZenControlledDatePickerInputV2Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  type?: ControlledTextInputType;
  inputMode?: ControlledTextInputModeProps;
  direction?: ControlledTextInputDirection;
  label?: string;
  subLabel?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  isRequired?: boolean;
  helperText?: string;
  maxLength?: number;
  datePickerConfig?: Omit<
    ReactDatePickerProps,
    'name' | 'onChange' | 'selected' | 'onBlur' | 'readOnly'
  >;
  minDate?: Date;
  maxDate?: Date;
  defaultFormat?: string;
  customInput?: React.ReactElement;
}

interface CalendarIconProps {
  value?: string;
  onClick?(): void;
}

const CalendarIconInput = forwardRef<HTMLInputElement, CalendarIconProps>(
  ({ onClick }, ref) => (
    <div
      onClick={onClick}
      ref={ref}
      className='flex justify-center items-center px-1 py-2 cursor-pointer'
    >
      <FontAwesomeIcon
        icon={regular('calendar')}
        title='calendar'
        className='text-primary-blue'
        fontSize={20}
      />
    </div>
  ),
);

const ZenControlledDatePickerInputV2 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  type = 'text',
  inputMode = 'text',
  direction = 'ltr',
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  tooltipIndex,
  shouldUnregister = true,
  startAdornment,
  helperText,
  isRequired = false,
  maxLength,
  rules,
  defaultValue,
  datePickerConfig,
  minDate,
  maxDate,
  defaultFormat = 'LL/dd/yyyy',
  customInput,
  ...rest
}: ZenControlledDatePickerInputV2Props<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      rules={{
        ...(rules || {}),
        validate: (val) => {
          if (val && DateTime.fromISO(val).year > MAX_YEAR_ALLOWED) {
            return `Year cannot be greater than ${MAX_YEAR_ALLOWED}.`;
          }
          return (rules?.validate as Validate<TFieldValues>)?.(val);
        },
      }}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error, invalid },
      }) => (
        <div className='w-full space-y-1'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              <span
                className={cn(
                  'font-zen-body font-semibold',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                )}
              >
                {label}
              </span>
              {!!subLabel && (
                <span className='font-zen-body text-sm text-zen-dark-12 ml-1'>
                  {subLabel}
                </span>
              )}
              {isRequired && <span className='text-zen-danger'>*</span>}
            </label>
          )}
          <div
            className={cn(
              'flex items-stretch border rounded-lg overflow-hidden',
              focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
              value && 'text-zen-dark-9',
              invalid && '!border-zen-danger',
              {
                'border-none': noBorder,
              },
            )}
          >
            {startAdornment && <div>{startAdornment}</div>}
            <div className='flex flex-grow flex-shrink'>
              <input
                id={name}
                value={value}
                onChange={onChange}
                onFocus={() => setFocus(true)}
                onBlur={(e) => {
                  onChange(e.target.value?.trim());
                  const formattedDate = getAutoFormattedDate(e.target.value);
                  onChange(formattedDate);
                  setFocus(false);
                  onBlur();
                }}
                type={type}
                inputMode={inputMode}
                dir={direction}
                name={name}
                readOnly={readOnly}
                placeholder={placeholder}
                className={cn(
                  'appearance-none p-2 w-full border-none focus:outline-none focus:ring-0 font-zen-body font-normal',
                  {
                    'bg-gray-50': readOnly,
                  },
                )}
                maxLength={maxLength}
                data-tooltip-index={tooltipIndex}
              />
            </div>
            <div className='mx-4'>
              <DatePicker
                name={name}
                selected={
                  value && DateTime.fromFormat(value, defaultFormat).isValid
                    ? DateTime.fromFormat(value, defaultFormat).toJSDate()
                    : null
                }
                onChange={(date: Date) => {
                  onChange(DateTime.fromJSDate(date).toFormat(defaultFormat));
                  setFocus(false);
                }}
                onFocus={() => setFocus(true)}
                onBlur={onBlur}
                placeholderText={placeholder}
                autoComplete='off'
                readOnly={readOnly}
                dateFormatCalendar='MMMM'
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                id={name}
                disabledKeyboardNavigation
                customInput={customInput ?? <CalendarIconInput />}
                minDate={minDate}
                maxDate={maxDate}
                className={cn('rounded-l', {
                  'bg-gray-50': readOnly,
                })}
                {...datePickerConfig}
              />
            </div>
          </div>
          <div className='flex'>
            {!!error && !hideErrorMessage && (
              <ZenFormErrorMessage message={error.message} />
            )}
            {helperText && (
              <div className='ml-auto font-zen-body text-base font-normal text-zen-dark-6'>
                {helperText}
              </div>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default ZenControlledDatePickerInputV2;
