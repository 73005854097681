import { useDispatch } from 'react-redux';
import TransactionClosed from '../assets/img/transaction-closed-modal.png';
import { StepByStepComponent } from '../components/StepByStep/StepByStepContainer';
import HasTransactionClosedModalHeader from '../components/transactions/HasTransactionClosedModalHeader';
import ZenButton from '../components/Zen/ZenButton';
import { toggleClosedModal } from '../slices/TransactionSlice';
import {
  TransactionClosedFormData,
  TransactionClosedStepName,
} from './HasTransactionClosedModal';

const HasTransactionClosedStep: StepByStepComponent<
  TransactionClosedFormData,
  TransactionClosedStepName
> = ({ navigateTo }) => {
  const dispatch = useDispatch();

  return (
    <div className='w-full rounded-lg z-10 md:w-full max-w-[745px] shadow-sm shadow-gray-300 bg-white relative'>
      <HasTransactionClosedModalHeader
        title='Transaction Update'
        onClose={() => dispatch(toggleClosedModal(false))}
      />
      <div className='w-full px-6 md:px-10 lg:px-[95px] py-6 font-zen-body text-black'>
        <div className='w-full flex flex-col items-center justify-center'>
          <div className='w-64'>
            <img
              src={TransactionClosed}
              alt='Transaction Closed'
              className='w-full'
            />
          </div>
          <div className=' font-bold mt-8 mb-4 font-zen-body text-center'>
            Has this Transaction Closed?
          </div>
          <div className='font-zen-body text-center lg:px-6'>
            <p>
              We noticed this transaction’s estimated closing date has lapsed.
              Please let us know if this transaction has closed.
            </p>
          </div>
          <div className='w-10/12 my-8 text-sm flex row items-center lg:px-6'>
            <div className='mr-1 w-1/2'>
              <ZenButton
                label='No'
                variant='primary-outline'
                onClick={() =>
                  navigateTo(
                    TransactionClosedStepName.HAS_TRANSACTION_CLOSED_NO,
                  )
                }
                isFullWidth
              />
            </div>
            <div className='ml-1 w-1/2'>
              <ZenButton
                label='Yes'
                variant='primary'
                onClick={() =>
                  navigateTo(
                    TransactionClosedStepName.HAS_TRANSACTION_CLOSED_YES,
                  )
                }
                isFullWidth
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HasTransactionClosedStep;
