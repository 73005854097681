import React from 'react';
import classNames from 'classnames';
import LeoOrb from '../../../../assets/img/leo/leoOrb.png';
import { displayTime, isToday } from '../../../../utils/DateUtils';

interface TimeStampProps {
  messageTime: string;
}

export const TimeStamp: React.FC<TimeStampProps> = ({ messageTime }) => {
  return (
    <div className={classNames('flex flex-row items-center mt-1')}>
      <img className='w-4.5 h-4.5 rounded-full' src={LeoOrb} alt='leo-avatar' />
      <div className='ml-1.5'>
        <p className='mt-0.5 text-zen-dark-6 leading-5 text-xs word-break font-inter font-normal'>
          {`
          ${isToday(messageTime) ? 'Today ' : ''}
          ${displayTime(messageTime)}
        `}
        </p>
      </div>
    </div>
  );
};
