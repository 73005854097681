import { useState } from 'react';
import { Column, Row } from 'react-table';
import {
  InstantPaymentResponse,
  TransactionLiteResponse,
} from '../../../openapi/arrakis';
import { displayAmount } from '../../../utils/CurrencyUtils';
import NumberColumnFilter from '../../table/Filters/NumberColumnFilter';
import TextColumnFilter from '../../table/Filters/TextColumnFilter';
import ZenFixedDataTable from '../Table/ZenFixedDataTable';
import ZenTransactionCodeCell from '../Transaction/ZenTransactionCodeCell';
import ZenMilliDateCell from '../Table/Cell/ZenMilliDateCell';
import ZenViewActionButtonCell from '../../table/Cells/ZenViewActionButtonCell';
import ZenInstantPaymentStatusSidebarModal from './ZenInstantPaymentStatusSidebarModal';
import ZenInstantPaymentStatusCell from './ZenInstantPaymentStatusCell';

export const columns: Column<InstantPaymentResponse>[] = [
  {
    Header: 'Transaction Code',
    accessor: 'transaction',
    id: 'transactionCode',
    Cell: ({ value }) => (
      <ZenTransactionCodeCell
        linkTo={`/transactions/${value?.id}`}
        transaction={value! as TransactionLiteResponse}
        hideInvoice
      />
    ),
    cardColSize: 7,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Address',
    accessor: 'transaction',
    id: 'address',
    Cell: ({ value }) => <div className='w-56'>{value?.addressOneLine}</div>,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <div className='w-max pt-1 md:pt-0'>
        <ZenInstantPaymentStatusCell status={value!} />
      </div>
    ),
    Filter: TextColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Principal',
    accessor: 'principal',
    Cell: ({ value }) => (
      <div>
        {displayAmount(value, { hideCurrency: true })}
        <div className='font-zen-body text-sm	text-zen-gray-4'>
          {value?.currency}
        </div>
      </div>
    ),
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Amount Repaid',
    accessor: 'amountRepaid',
    Cell: ({ value }) => (
      <div>
        {displayAmount(value, { hideCurrency: true })}
        <div className='font-zen-body text-sm	text-zen-gray-4'>
          {value?.currency}
        </div>
      </div>
    ),
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Amount Repay',
    accessor: 'amountToRepay',
    Cell: ({ value }) => (
      <div>
        {displayAmount(value, { hideCurrency: true })}
        <div className='font-zen-body text-sm	text-zen-gray-4'>
          {value?.currency}
        </div>
      </div>
    ),
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }) => <ZenMilliDateCell date={value} />,
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Paid At',
    accessor: 'paymentConfirmationTime',
    Cell: ({ value }) => <ZenMilliDateCell date={value} />,
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
];

interface ZenInstantPaymentResourceTableProps {
  instantPayments: InstantPaymentResponse[];
}

const ZenInstantPaymentResourceTable: React.FC<ZenInstantPaymentResourceTableProps> = ({
  instantPayments,
}) => {
  const [
    activeInstantPayment,
    setActiveInstantPayment,
  ] = useState<InstantPaymentResponse | null>(null);

  const columnsWithAction = [
    {
      Header: 'Actions',
      id: 'actions',
      Cell: ({ row }: { row: Row<InstantPaymentResponse> }) => (
        <ZenViewActionButtonCell
          onClick={() => setActiveInstantPayment(row.original)}
        />
      ),
      disableSortBy: true,
    },
    ...columns,
  ];

  return (
    <div>
      <ZenFixedDataTable<InstantPaymentResponse>
        columns={columnsWithAction}
        data={instantPayments}
        resourceName='Instant Payment'
        hidePagination
        hideFilters
      />
      {!!activeInstantPayment && (
        <ZenInstantPaymentStatusSidebarModal
          instantPayment={activeInstantPayment}
          onClose={() => setActiveInstantPayment(null)}
        />
      )}
    </div>
  );
};

export default ZenInstantPaymentResourceTable;
