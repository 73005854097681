import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  AddFlexTeamLeader,
  AddFlexTeamMemberTypeEnum,
  AddFlexTeammate,
  AddFlexTeamMemberRolesEnum,
  FeeSplitFeeTypeEnum,
  FlexTeamConfigDtoTeamTypeEnum,
  FlexTeamDto,
  FlexTeamConfigDtoCountryEnum,
} from '../../../../openapi/yenta';
import ZenSidebarModal from '../../ZenSidebarModal';
import ZenTabs from '../../ZenTabs';
import { LeaderDto } from '../../../../routes/ZenTeamsProDetailsRoute';
import { addFlexTeamMember } from '../../../../slices/TeamSlice';
import { FlexTeamRoles } from '../../../../types';
import {
  calculateDefaultFeeSplits,
  combineFeeSplits,
  getOriginalLeaderDetails,
  getSplitPercentage,
  setDefaultFeeSplits,
} from '../../../../utils/ProTeams/ProTeamsHelper';
import ZenTeamMemberAgentFeesTab from './ZenTeamMemberAgentFeesTab';
import ZenTeamMemberLeaderFeeSplitsTab from './ZenTeamMemberLeaderFeeSplitsTab';
import ZenTeamMemberTeamFeesTab from './ZenTeamMemberTeamFeesTab';
import { ProTeamMember } from './ZenEditProTeamMember';
import ZenAddProAdminTeamMemberForm from './ZenProAdminTeamMemberForm';

interface ZenAddProTeamMemberProps {
  isOpen: boolean;
  onClose(): void;
  teamId: string;
  teamType: FlexTeamConfigDtoTeamTypeEnum;
  leaders?: LeaderDto[];
  team: FlexTeamDto;
  refetch(): void;
}

const ZenAddProTeamMember: React.FC<ZenAddProTeamMemberProps> = ({
  isOpen,
  onClose,
  teamId,
  team,
  leaders = [],
  refetch,
}) => {
  const country = team.config?.country;
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = useForm<ProTeamMember>({
    reValidateMode: 'onChange',
    defaultValues: {
      capAmount: team.teamCap?.capAmount?.toString(),
      teamPreCapFee: team.teamCap?.preCapFee?.toString(),
      teamPostCapFee: team.teamCap?.postCapFee?.toString(),
      leaderFeeDetails: getOriginalLeaderDetails(leaders),
      override: false,
    },
  });

  const { fields } = useFieldArray<ProTeamMember>({
    name: 'leaderFeeDetails',
    control,
  });

  const [selected, setSelected] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [roles, adminSplit, override, leaderFeeDetails] = watch([
    'roles',
    'adminSplit',
    'override',
    'leaderFeeDetails',
  ]);

  const isAdminNotMember = roles?.value === FlexTeamRoles.Admin && !adminSplit;

  const showTeamMatesTab =
    roles?.value === FlexTeamRoles.Teammate ||
    (roles?.value === FlexTeamRoles.Admin && adminSplit);

  useEffect(() => {
    calculateDefaultFeeSplits(setValue, leaders);
  }, [leaders, setValue]);

  const onSubmit = async (values: ProTeamMember) => {
    setIsSubmitting(true);
    const overridden = values.override;

    if (showTeamMatesTab) {
      const feeSplits = [];
      const leaderSplits = [];
      for (let i = 0; i < values.leaderFeeDetails.length; i++) {
        const leaderDetails = leaders.find(
          (item) =>
            item.leaderDetails?.id ===
            values.leaderFeeDetails[i].teamLeader.value,
        )!;
        const individualLeaderFeeSplit = overridden
          ? combineFeeSplits(values.leaderFeeDetails[i])
          : [];

        feeSplits.push({
          agentId: leaderDetails.leaderDetails?.agentId,
          feeSplits: individualLeaderFeeSplit,
          leaderId: leaderDetails.leaderDetails?.id!,
        });
        leaderSplits.push({
          agentId: leaderDetails.leaderDetails?.agentId,
          leaderId: leaderDetails.leaderDetails?.id!,
          leaderSplit: parseFloat(values.leaderFeeDetails[i].leaderSplit),
        });
      }

      let teamCap: any = {};

      values.capAmount &&
        values.capAmount !== team.teamCap?.capAmount?.toString() &&
        Object.assign(teamCap, { capAmount: parseFloat(values.capAmount) });
      values.teamPreCapFee &&
        values.teamPreCapFee !== team.teamCap?.preCapFee?.toString() &&
        Object.assign(teamCap, { preCapFee: parseFloat(values.teamPreCapFee) });
      values.teamPostCapFee &&
        values.teamPostCapFee !== team.teamCap?.postCapFee?.toString() &&
        Object.assign(teamCap, {
          postCapFee: parseFloat(values.teamPostCapFee),
        });

      const roles =
        values.roles.value === FlexTeamRoles.Admin
          ? [AddFlexTeamMemberRolesEnum.Admin]
          : [];

      const req: AddFlexTeammate = {
        agentId: values.agentId.value,
        type: AddFlexTeamMemberTypeEnum.Teammate,
        paysLeaderSplit: true,
        ...(!isEmpty(teamCap) && { teamCap: teamCap }),
        feeSplits: feeSplits,
        leaderSplits: leaderSplits,
        realCap: parseFloat(values.realCap),
        roles,
      };
      await dispatch(addFlexTeamMember(teamId, req));
    }
    if (values.roles?.value === FlexTeamRoles.Leader) {
      const feeSplits = combineFeeSplits({
        realSplit: +values.realSplit,
        beopFee: +values.beopFee,
        realFee: +values.realFee,
        transactionFee: +values.transactionFee,
      });

      const req: AddFlexTeamLeader = {
        agentId: values.agentId.value,
        feeSplits: feeSplits,
        leaderSplit: parseFloat(values.leaderSplit),
        type: AddFlexTeamMemberTypeEnum.Leader,
      };
      await dispatch(addFlexTeamMember(teamId, req));
    }

    if (isAdminNotMember) {
      const req: AddFlexTeammate = {
        agentId: values.agentId.value,
        type: AddFlexTeamMemberTypeEnum.Teammate,
        paysLeaderSplit: values.adminSplit ?? false,
        roles: [AddFlexTeamMemberRolesEnum.Admin],
      };
      await dispatch(addFlexTeamMember(teamId, req));
    }
    await refetch();
    onClose();
    setIsSubmitting(false);
  };

  const agentFeeValues = useCallback(() => {
    if (leaderFeeDetails) {
      let feeTypeTotals = {
        [FeeSplitFeeTypeEnum.RealSplit]: 0,
        [FeeSplitFeeTypeEnum.BeopFee]: 0,
        [FeeSplitFeeTypeEnum.BrokerageFee]: 0,
        [FeeSplitFeeTypeEnum.TransactionFee]: 0,
      };
      leaderFeeDetails.forEach((leaderFeeDetail: any) => {
        const { realSplit, beopFee, realFee, transactionFee } = leaderFeeDetail;
        feeTypeTotals[FeeSplitFeeTypeEnum.RealSplit] += parseFloat(
          realSplit || 0,
        );
        feeTypeTotals[FeeSplitFeeTypeEnum.BeopFee] += parseFloat(beopFee || 0);
        feeTypeTotals[FeeSplitFeeTypeEnum.BrokerageFee] += parseFloat(
          realFee || 0,
        );
        feeTypeTotals[FeeSplitFeeTypeEnum.TransactionFee] += parseFloat(
          transactionFee || 0,
        );

        setDefaultFeeSplits(setValue, feeTypeTotals);
      });
    }
  }, [leaderFeeDetails, setValue]);

  useEffect(() => {
    if (selected === 'Agent Fees') {
      agentFeeValues();
    }
  }, [agentFeeValues, selected]);

  useEffect(() => {
    if (roles?.value === FlexTeamRoles.Leader && !adminSplit) {
      setValue('override', true);
    } else {
      setValue('override', false);
    }
  }, [roles, setValue, adminSplit]);

  const resetToDefault = useCallback(
    (index: number) => {
      const leaderId = leaderFeeDetails[index]?.teamLeader?.value;
      const leader = leaders.find((val) => val.leaderDetails?.id === leaderId);
      setValue(
        `leaderFeeDetails.${index}.leaderSplit`,
        leader?.leaderDetails?.leaderSplit?.toString() ?? '',
      );
      setValue(
        `leaderFeeDetails.${index}.realSplit`,
        getSplitPercentage(
          FeeSplitFeeTypeEnum.RealSplit,
          leader?.leaderDetails?.feeSplits,
        ),
      );
      setValue(
        `leaderFeeDetails.${index}.beopFee`,
        getSplitPercentage(
          FeeSplitFeeTypeEnum.BeopFee,
          leader?.leaderDetails?.feeSplits,
        ),
      );
      setValue(
        `leaderFeeDetails.${index}.realFee`,
        getSplitPercentage(
          FeeSplitFeeTypeEnum.BrokerageFee,
          leader?.leaderDetails?.feeSplits,
        ),
      );
      setValue(
        `leaderFeeDetails.${index}.transactionFee`,
        getSplitPercentage(
          FeeSplitFeeTypeEnum.TransactionFee,
          leader?.leaderDetails?.feeSplits,
        ),
      );
    },
    [leaderFeeDetails, setValue, leaders],
  );

  const resetAllToDefault = useCallback(() => {
    leaderFeeDetails.forEach((_: any, index: number) => {
      resetToDefault(index);
    });
  }, [leaderFeeDetails, resetToDefault]);

  useEffect(() => {
    if (override) {
      resetAllToDefault();
    }
  }, [override, resetAllToDefault]);

  return (
    <ZenSidebarModal isOpen={isOpen} onClose={onClose} title='Add New Member'>
      {showTeamMatesTab && (
        <ZenTabs
          equalWidths
          tabs={[
            {
              name: 'Leader Fees',
              TabComponent: (
                <ZenTeamMemberLeaderFeeSplitsTab
                  leaders={leaders}
                  isEdit={false}
                  roles={roles}
                  onClose={onClose}
                  control={control}
                  fields={fields}
                  resetToDefault={resetToDefault}
                  trigger={trigger}
                  buttonText='Next'
                  onSubmit={() => setSelected('Agent Fees')}
                  leaderFeeDetails={leaderFeeDetails}
                  override={override}
                  setValue={setValue}
                  adminSplit={
                    roles?.value === FlexTeamRoles.Admin && adminSplit
                  }
                  country={country as FlexTeamConfigDtoCountryEnum}
                />
              ),
            },
            {
              name: 'Agent Fees',
              TabComponent: (
                <ZenTeamMemberAgentFeesTab
                  onClose={onClose}
                  control={control}
                  team={team}
                  buttonText='Next'
                  trigger={trigger}
                  setValue={setValue}
                  onSubmit={() => setSelected('Team Fees')}
                />
              ),
            },
            {
              name: 'Team Fees',
              TabComponent: (
                <ZenTeamMemberTeamFeesTab
                  team={team}
                  control={control}
                  onSubmit={handleSubmit(onSubmit)}
                  onClose={onClose}
                  isSubmitting={isSubmitting}
                  buttonText='Add Member'
                  setValue={setValue}
                />
              ),
            },
          ]}
          selected={selected}
          onChange={setSelected}
          size='sm'
        />
      )}
      {(!roles || roles?.value === FlexTeamRoles.Leader) && (
        <ZenTeamMemberLeaderFeeSplitsTab
          leaders={leaders}
          isEdit={false}
          roles={roles}
          onClose={onClose}
          control={control}
          fields={fields}
          resetToDefault={resetToDefault}
          trigger={trigger}
          buttonText='Add'
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit(onSubmit)}
          leaderFeeDetails={leaders}
          override={override}
          setValue={setValue}
          country={country as FlexTeamConfigDtoCountryEnum}
        />
      )}

      {isAdminNotMember && (
        <ZenAddProAdminTeamMemberForm
          onClose={onClose}
          control={control}
          buttonText='Add'
          isSubmitting={isSubmitting}
          watch={watch}
          onSubmit={handleSubmit(onSubmit)}
          country={country as FlexTeamConfigDtoCountryEnum}
        />
      )}
    </ZenSidebarModal>
  );
};

export default ZenAddProTeamMember;
