import config from '../config';

export const getYentaImageUrl = (
  path: string | undefined,
): string | undefined =>
  path ? `${config.yentaImageBaseUrl}/${path}` : undefined;

export const getCalendarImageUrl = (
  path: string | undefined,
): string | undefined =>
  path ? `${config.calendarImageUrl}/${path}` : undefined;

export const getMercuryAssetUrl = (
  path: string | undefined,
): string | undefined => {
  return path ? `${config.mercuryAssetsBaseUrl}/${path}` : undefined;
};
