import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Column,
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import DeleteIcon from '../../../assets/img/deleteIconDanger.svg';
import EditIcon from '../../../assets/img/editIcon.svg';
import { ResourceTableVariant } from '../../../containers/ResourceTable';
import useAgentsInfo from '../../../hooks/useAgentsInfo';
import {
  ParticipantValue,
  ParticipantValueRoleEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { toggleAddCommissionParticipant } from '../../../slices/QuickActionSlice';
import { ParticipantType, RootState } from '../../../types';
import { isSmScreen } from '../../../utils/BrowserUtils';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import {
  FIXED_TABLE_FILTER_TYPES,
  getPriorityWiseSortedData,
} from '../../../utils/TableUtils';
import {
  ParticipantRoleSortingOrder,
  getTransactionOtherParticipants,
  isCanadaTransaction,
  participantRoleDisplayName,
} from '../../../utils/TransactionHelper';
import ZenAdd from '../../Zen/ZenAdd';
import ZenCard from '../../Zen/ZenCard';
import ZenUserPill from '../../Zen/ZenUserPill';
import PhoneNumberCell from '../../table/Cells/PhoneNumberCell';
import ZenResourceTable from '../Table/ZenResourceTable';
import ZenIconCircleWithTooltipCell from './ZenIconCircleWithTooltipCell';
import ZenTransactionToggleDocsReceive from './ZenTransactionToggleDocsReceive';

interface ZenTransactionOtherParticipantsTableProps {
  transaction: TransactionResponse;
  onEdit(participant: ParticipantValue): void;
  onDelete(participant: ParticipantValue): void;
  toggleFlag(participant: ParticipantValue): void;
  isReadonly: boolean;
}

const ZenTransactionOtherParticipantsTable: React.FC<ZenTransactionOtherParticipantsTableProps> = ({
  transaction,
  onEdit,
  onDelete,
  toggleFlag,
  isReadonly,
}) => {
  const dispatch = useDispatch();
  const {
    auth: { isAdmin },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  const agentIds = useMemo(
    () =>
      transaction?.otherParticipants?.map(
        (participant) => participant.yentaId!,
      ) || [],
    [transaction],
  );

  const agentInfo = useAgentsInfo(agentIds);

  const otherParticipants = useMemo(
    () => getTransactionOtherParticipants(transaction),
    [transaction],
  );

  const columns: Column<ParticipantValue>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: ({ row }) => (
          <div>
            {!row.original.externalParticipant ? (
              <Link to={`/people/${row.original.yentaId}`}>
                <ZenUserPill
                  name={getParticipantName(row.original)!}
                  backgroundVariant='background'
                  imageUrl={
                    agentInfo.loading
                      ? ''
                      : agentById[row.original.yentaId!]?.avatar || ''
                  }
                  noWrap
                />
              </Link>
            ) : (
              <ZenUserPill
                name={getParticipantName(row.original)!}
                backgroundVariant='background'
              />
            )}
          </div>
        ),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ value }) => (
          <p className='font-zen-body text-sm text-zen-dark-9 font-normal'>
            {participantRoleDisplayName(value!)}
          </p>
        ),

        sortType: (row1, row2) =>
          getPriorityWiseSortedData(
            row1.values.role,
            row2.values.role,
            ParticipantRoleSortingOrder,
          ),
        disableFilters: true,
      },
      {
        Header: 'Email',
        accessor: 'emailAddress',
        Cell: ({ value }) => (
          <p className='font-zen-body text-sm text-zen-dark-9 font-normal'>
            {value || 'N/A'}
          </p>
        ),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        Cell: ({ value }) => (
          <div className='font-zen-body text-sm text-zen-dark-9 font-normal'>
            <PhoneNumberCell phoneNumber={value} />
          </div>
        ),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Address',
        accessor: 'address',
        Cell: ({ value }) => (
          <div className='font-zen-body text-sm text-zen-dark-9 font-normal'>
            {value || 'N/A'}
          </div>
        ),
        disableSortBy: true,
        disableFilters: true,
      },
    ],
    [agentById, agentInfo.loading],
  );

  const columnsWithActions: Column<ParticipantValue>[] = useMemo(() => {
    const updatedColumns: Column<ParticipantValue>[] = [
      ...columns,
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ row }: { row: Row<ParticipantValue> }) =>
          isReadonly ? (
            <ZenIconCircleWithTooltipCell
              tooltipText='View'
              icon={
                <FontAwesomeIcon
                  icon={faEye}
                  className='text-zen-dark-8 text-lg'
                  data-testid='view-other-participant'
                />
              }
              onClick={() => onEdit(row.original)}
            />
          ) : (
            <div className='space-x-3 flex flex-row'>
              <ZenIconCircleWithTooltipCell
                tooltipText='Edit'
                icon={
                  <img
                    src={EditIcon}
                    alt='edit'
                    className='text-zen-dark-8'
                    width={16}
                    data-testid='edit-other-participant'
                  />
                }
                onClick={() => onEdit(row.original)}
              />

              {(isAdmin ||
                row.original.role !== ParticipantValueRoleEnum.TeamLeader) && (
                <ZenIconCircleWithTooltipCell
                  tooltipText='Delete'
                  icon={<img src={DeleteIcon} alt='delete' width={14} />}
                  onClick={() => onDelete(row.original)}
                  iconVariant='error'
                />
              )}
            </div>
          ),
        disableSortBy: true,
        disableFilters: true,
      },
    ];

    if (!isReadonly) {
      updatedColumns.push({
        Header: isCanadaTransaction(transaction) ? (
          <div>Receives Trade Record Sheet?</div>
        ) : (
          <div>
            Receives Invoice /<br /> Commission Document?
          </div>
        ),
        accessor: 'id',
        id: 'toggle',
        Cell: ({ row }: { row: Row<ParticipantValue> }) => (
          <ZenTransactionToggleDocsReceive
            receivesDocs={!!row.original.commissionDocumentRecipient}
            onToggle={async () => {
              await toggleFlag(row.original);
            }}
          />
        ),
        disableSortBy: true,
        disableFilters: true,
      });
    }

    return updatedColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, isReadonly, onDelete, onEdit, toggleFlag, transaction]);

  const tableInstance = useTable(
    {
      columns: columnsWithActions,
      data: otherParticipants,
      initialState: {
        pageSize: 1000,
        sortBy: [{ id: 'role' }],
      },
      filterTypes: FIXED_TABLE_FILTER_TYPES,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  return (
    <div>
      <ZenCard
        title='Other Participants'
        RightComponent={
          !isReadonly ? (
            <div className='flex flex-row items-center space-x-4'>
              <ZenAdd
                text='Add Other Participants'
                onClick={() => {
                  dispatch(
                    toggleAddCommissionParticipant({
                      agentType: ParticipantType.EXTERNAL_ENTITY,
                      isOpen: true,
                    }),
                  );
                }}
              />
            </div>
          ) : (
            <div />
          )
        }
      >
        <ZenResourceTable<ParticipantValue>
          {...tableInstance}
          resourceName='Other Participant'
          fetchData={() => ({
            data: otherParticipants,
            total: otherParticipants.length,
          })}
          totalCount={otherParticipants.length}
          variant={
            isSmScreen() ? ResourceTableVariant.CARD : ResourceTableVariant.ROW
          }
          headerVariant='light'
          hidePageSize
          hidePagination
        />
      </ZenCard>
    </div>
  );
};

export default ZenTransactionOtherParticipantsTable;
