import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AddFlexTeamMemberTypeEnum,
  AgentControllerApi,
  CreateFlexTeamConfig,
  CreateFlexTeamConfigCdaApproverEnum,
  CreateFlexTeamConfigLeaderOverridablePropertiesEnum,
  CreateFlexTeamConfigPaymentDetailsVisibilityEnum,
  CreateFlexTeamConfigPermittedTransactionEditorsEnum,
  CreateFlexTeamConfigTeamTypeEnum,
  CreateTeamMemberRequestRolesEnum,
  CreateTeamRequestTypeEnum,
  TeamResponseTypeEnum,
  UserResponseAgentStatusEnum,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { createFlexTeamAndConfig, createTeam } from '../../../slices/TeamSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { AppDispatch, AsyncSelectOption, ISelectOption } from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { NUMBER_REGEX } from '../../../utils/StringUtils';
import { searchForAgents } from '../../../utils/TableUtils';
import { teamTypeToDisplayName } from '../../../utils/TeamHelper';
import ZenControlledAsyncSelectInput from '../Input/ZenControlledAsyncSelectInput';
import ZenControlledSelectInput from '../Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenSidebarModalActionFooterV2 from '../Modal/ZenSidebarModalActionFooterV2';
import ZenSidebarModal from '../ZenSidebarModal';
import AddConfigFlexTeam from './ProTeams/AddConfigFlexTeam';

interface ZenCreateTeamFormProps {
  isOpen: boolean;
  onClose(): void;
}

export interface ZenCreateTeamFormData {
  name: string;
  teamType?: ISelectOption;
  leaderAgentId?: ISelectOption;
  commissionSplit?: number;
  leaderCap?: string;
  realCap?: string[];
  minTeamMates?: number;
  maxTeamMates?: number;
  minLeaders?: number;
  maxLeaders?: number;
  minLeaderSplit?: number;
  eligibleInstantPayments?: boolean;
  paymentDetailsVisibility?: boolean;
  leaderCDARoles?: boolean;
  permittedTransactionToggle?: boolean;
  leaderPaymentDetailsVisibility?: boolean;
  commissionDocumentApprover?: ISelectOption;
  enforceLeaderSplits?: boolean;
  teamFee?: string;
  teamPreCapFee?: string;
  teamPostCapFee?: string;
  permittedTransactionEditors?: ISelectOption;
}

const defaultValues: ZenCreateTeamFormData = {
  name: '',
  teamType: undefined,
  leaderAgentId: undefined,
  commissionSplit: undefined,
  teamFee: undefined,
  teamPreCapFee: undefined,
  teamPostCapFee: undefined,
};

const ZenCreateTeamForm: React.FC<ZenCreateTeamFormProps> = ({
  isOpen,
  onClose,
}) => {
  const history = useHistory();
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<ZenCreateTeamFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });
  const dispatch: AppDispatch = useDispatch();

  const onSubmit = async (values: ZenCreateTeamFormData) => {
    let teamId: string | undefined;
    if (teamType?.value !== TeamResponseTypeEnum.Pro) {
      let role, memberCommissionSplit;
      if (values.teamType?.value === TeamResponseTypeEnum.Group) {
        role = CreateTeamMemberRequestRolesEnum.Member;
        memberCommissionSplit = +values.commissionSplit!;
      } else {
        role = CreateTeamMemberRequestRolesEnum.Leader;
        memberCommissionSplit = 100;
      }

      teamId = await dispatch(
        createTeam({
          name: values.name,
          memberCommissionSplit: +values.commissionSplit!,
          type: (values.teamType
            ?.value as unknown) as CreateTeamRequestTypeEnum,
          members: [
            {
              memberAgentId: values.leaderAgentId!.value,
              memberCommissionSplit: memberCommissionSplit,
              roles: [role],
            },
          ],
        }),
      );
    }
    if (teamType?.value === TeamResponseTypeEnum.Pro) {
      const { data } = await new AgentControllerApi(
        getYentaConfiguration(),
      ).getAgentById(values.leaderAgentId?.value!);

      const leaderOverridableProperties = [];

      values.leaderCDARoles &&
        leaderOverridableProperties.push(
          CreateFlexTeamConfigLeaderOverridablePropertiesEnum.CdaApprovalRoles,
        );
      values.leaderPaymentDetailsVisibility &&
        leaderOverridableProperties.push(
          CreateFlexTeamConfigLeaderOverridablePropertiesEnum.PaymentDetailsVisibility,
        );

      values.permittedTransactionToggle &&
        leaderOverridableProperties.push(
          CreateFlexTeamConfigLeaderOverridablePropertiesEnum.PermittedTransactionEditorRoles,
        );
      const defaultCaps = [4000, 6000, 12000];
      const excludedMemberCaps = defaultCaps
        ?.filter((x) => !values.realCap?.map(Number).includes(x))
        .map((x) => x);

      const configFormData: CreateFlexTeamConfig = {
        paidAtClosing: true,
        teamType: CreateFlexTeamConfigTeamTypeEnum.Pro,
        maxLeaders: values.maxLeaders!,
        minLeaders: 0,
        maxTeammates: values.maxTeamMates!,
        minTeammates: 0,
        commissionPlanId: data.planMembership?.commissionPlan?.id!,
        realCapConfig: {
          leaderCap: parseInt(values.leaderCap!),
          excludedMemberCaps,
        },
        permittedTransactionEditors: values.permittedTransactionEditors
          ?.value! as CreateFlexTeamConfigPermittedTransactionEditorsEnum,
        paymentDetailsVisibility: values.paymentDetailsVisibility
          ? CreateFlexTeamConfigPaymentDetailsVisibilityEnum.Full
          : CreateFlexTeamConfigPaymentDetailsVisibilityEnum.None,
        cdaApprover: values.commissionDocumentApprover
          ?.value! as CreateFlexTeamConfigCdaApproverEnum,
        leaderOverridableProperties: leaderOverridableProperties,
        leaderSplitConfig: {
          enforceSplits: false,
          minSplitPercent: values.minLeaderSplit!,
        },
        name: values.name!,
      };

      teamId = await dispatch(
        createFlexTeamAndConfig({
          config: configFormData,
          team: {
            name: values.name,
            initialLeaders: [
              {
                agentId: values.leaderAgentId!.value,
                type: AddFlexTeamMemberTypeEnum.Leader,
              },
            ],
            teamCap: {
              capAmount: parseFloat(values?.teamFee!),
              preCapFee: parseFloat(values?.teamPreCapFee!),
              postCapFee: parseFloat(values?.teamPostCapFee!),
            },
          },
        }),
      );
    }

    if (teamId) {
      history.push(`/teams/${teamId}`);
    }
  };

  const teamType = watch('teamType');

  return (
    <ZenSidebarModal title='Add New Team' isOpen={isOpen} onClose={onClose}>
      <form
        className='flex flex-col justify-between h-[93%] overflow-y-auto'
        onSubmit={handleSubmit(onSubmit)}
        title='create-team-form'
      >
        <div className='p-5'>
          <div>
            <ZenControlledTextInput<ZenCreateTeamFormData, 'name'>
              control={control}
              label='Name'
              name='name'
              placeholder='Enter Team Name'
              isRequired
              rules={{ required: 'Please enter a team name' }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledSelectInput<ZenCreateTeamFormData, 'teamType'>
              name='teamType'
              control={control}
              label='Type'
              placeholder='Team Type'
              isRequired
              options={values(TeamResponseTypeEnum).map((type) => ({
                value: type,
                label: teamTypeToDisplayName(type),
              }))}
              rules={{
                required: 'Please select the team type',
              }}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<
              ZenCreateTeamFormData,
              'leaderAgentId'
            >
              control={control}
              name='leaderAgentId'
              placeholder='Search Member'
              label={
                teamType?.value === TeamResponseTypeEnum.Group
                  ? 'First Member'
                  : 'Team Leader'
              }
              isRequired
              rules={{
                required: 'Please select a team leader',
              }}
              fetchData={async (search, page) => {
                try {
                  const { data } = await searchForAgents({
                    page,
                    search,
                    filterBy: {
                      agentStatus: [UserResponseAgentStatusEnum.Active],
                    },
                  });

                  const options: AsyncSelectOption[] = data.map((resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.firstName} ${resp.lastName} - ${resp.emailAddress}`,
                  }));

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search for registered agents in create team form',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                      },
                    },
                  );
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an agent. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              startAdornment={
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className='text-primary-blue mx-3'
                  size='sm'
                />
              }
              endAdornment={
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className='text-zen-dark mx-5'
                  size='sm'
                />
              }
            />
          </div>
          {(teamType?.value === TeamResponseTypeEnum.Normal ||
            teamType?.value === TeamResponseTypeEnum.Platinum) && (
            <div className='mt-5'>
              <ZenControlledTextInput<ZenCreateTeamFormData, 'commissionSplit'>
                control={control}
                type='number'
                label='Commission Split'
                name='commissionSplit'
                placeholder='Commission Split'
                isRequired
                rules={{
                  required: 'Please enter commission split',
                  min: {
                    value: 0,
                    message: 'Number cannot be less than 0',
                  },
                  max: {
                    value: 100,
                    message: 'Number cannot be greater than 100',
                  },
                  pattern: {
                    value: NUMBER_REGEX,
                    message: 'Please enter a valid number',
                  },
                }}
              />
            </div>
          )}
          {teamType?.value === TeamResponseTypeEnum.Pro && (
            <AddConfigFlexTeam control={control} />
          )}
        </div>

        <ZenSidebarModalActionFooterV2
          onClose={onClose}
          isSubmitting={isSubmitting}
          submitButtonText='Create'
          isDisabled={isSubmitting}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenCreateTeamForm;
