import { isEmpty } from 'lodash';
import { AgentCapInfoResponse } from '../../openapi/arrakis';
import { displayAmount } from '../../utils/CurrencyUtils';
import { calculatePercentage } from '../../utils/MathUtils';
import ZenColoredProgressBar from '../ZenColoredProgressBar';

interface ZenCapStatusTrackerProps {
  capStatus?: AgentCapInfoResponse;
  isTeamCap?: boolean;
  isTeamLeader?: boolean;
}

const ZenCapStatusTracker: React.FC<ZenCapStatusTrackerProps> = ({
  capStatus,
  isTeamCap = false,
  isTeamLeader = false,
}) => {
  const capAmount = isTeamCap ? capStatus?.teamCapAmount : capStatus?.capAmount;
  const capAmountPaid = isTeamCap
    ? capStatus?.teamCapAmountPaid
    : capStatus?.capAmountPaid;

  const percentage = calculatePercentage(
    capAmountPaid?.amount || 0,
    capAmount?.amount || 0,
  );

  const displayPaidAmount = displayAmount(capAmountPaid, {
    hideCurrency: true,
    hideZeroCents: true,
  });

  const displayTotalAmount = displayAmount(capAmount, {
    hideCurrency: true,
    hideZeroCents: true,
  });

  if (isEmpty(capStatus) || !capAmount || !capAmountPaid || isTeamLeader) {
    return <div className='font-normal'>N/A</div>;
  }

  return (
    <div>
      <div className='font-zen-body text-zen-dark-9 mb-1'>
        {displayPaidAmount} / {displayTotalAmount}
      </div>
      <ZenColoredProgressBar progressPercentage={Math.min(percentage, 100)} />
    </div>
  );
};

export default ZenCapStatusTracker;
