import React from 'react';

interface TierProgressBarProps {
  bookedShares: number;
  totalShares: number;
  utilization: number;
  tierColor: string;
}

const TierProgressBar: React.FC<TierProgressBarProps> = ({
  totalShares,
  bookedShares,
  utilization,
  tierColor,
}) => {
  const availableShares = totalShares - bookedShares;

  return (
    <div className='flex flex-col'>
      <div className='flex justify-end font-normal text-zen-dark-9 text-xs leading-5 mt-2'>
        <span>
          {availableShares > 0 ? `${availableShares} Available` : 'Tier Filled'}
        </span>
      </div>
      <div className='overflow-hidden h-3 flex rounded-full bg-zen-dark-4'>
        <div
          data-testid='colored-progress-bar'
          style={{
            width: `${utilization}%`,
            backgroundColor: tierColor,
          }}
          className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center rounded-full'
        />
      </div>
    </div>
  );
};

export default TierProgressBar;
