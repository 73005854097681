import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { BorrowerDto } from '../../openapi/atlantis';
import { cn } from '../../utils/classUtils';
import MortgageSidebarSection from './MortgageSidebarSection';

interface MortgageSidebarProps {
  borrower: BorrowerDto;
}

const MortgageSidebar: React.FC<MortgageSidebarProps> = ({ borrower }) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  return (
    <div className='sticky top-[100px] -mb-0.5 z-30 lg:z-0 border-r border-gray-200 text-dark bg-zen-light-gray-2 h-fit lg:h-screen w-full lg:w-[235px] scrollbar overflow-y-visible scrollbar overflow-y-auto'>
      {/* Hamburger Menu */}
      <div className='w-full min-w-max flex items-center border-b border-gray-200 bg-gray-100 py-2 px-5 lg:hidden'>
        <FontAwesomeIcon
          icon={faBars}
          className='text-dark mr-5'
          onClick={() => setCollapsed(!collapsed)}
        />
      </div>
      <div
        className={cn('fixed inset-0 lg:static z-10', {
          'hidden lg:block': collapsed,
        })}
      >
        {/* Sidebar Outside Overlay */}
        <div
          className='absolute inset-0 bg-black bg-opacity-30 lg:hidden'
          role='button'
          onClick={() => setCollapsed(true)}
        />

        {/* Sidebar */}
        <div className='py-10 px-4 fixed w-[196px] inset-y-0 lg:static animate-slide-in-left lg:animate-none scrollbar overflow-y-auto scrollbar overflow-y-visible bg-zen-light-gray-2'>
          <MortgageSidebarSection borrower={borrower} />
        </div>
      </div>
    </div>
  );
};

export default MortgageSidebar;
