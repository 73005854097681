import { get, last } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useRedirectAwayOnboardingEffect from '../../hooks/useRedirectAwayOnboardingEffect';
import {
  ApplicationControllerApi,
  ApplicationResponse,
  ApplicationResponseNextStatusEnum,
  CreateApplicationRequestApplicationTypeEnum,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { fetchAuthUserDetail } from '../../slices/AuthSlice';
import {
  ApplicationFormFieldName,
  applicationFormQuestions,
  getCurrentApplicationStepData,
} from '../../testUtils/OnboardingUtils';
import { AppDispatch, RootState } from '../../types';
import Logger from '../../utils/Logger';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ApplicationLayout from '../ApplicationLayout';
import ResourceContainer from '../ResourceContainer';
import Review from './application-form/Review';
import StepQuestionForm from './application-form/StepQuestionForm';

export type FormDataType = {
  [X in ApplicationFormFieldName]: any;
};

const ApplicationForm: React.FC = () => {
  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [formData, setFormData] = useState<Partial<FormDataType>>({});
  const [isReviewing, setIsReviewing] = useState(false);

  const [currentApplicationId, setCurrentApplicationId] = useState<
    string | undefined
  >(undefined);
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const [isLoading, setIsLoading] = useState(false);
  const applicationResponse = last(userDetail?.applications!);

  const invitationId =
    applicationResponse?.teamInvitation?.invitationId! ||
    applicationResponse?.genericTeamInvitation?.invitationId!;

  const questions = applicationFormQuestions(
    userDetail?.fullName!,
    userDetail?.accountCountry!,
    get(formData, 'selectedProvinces'),
    // Show team question if not invited
    invitationId,
  );

  useRedirectAwayOnboardingEffect(ApplicationResponseNextStatusEnum.Started);

  const getPrefilledApplicationData = useCallback(
    async (application: ApplicationResponse) => {
      setIsLoading(true);
      setCurrentApplicationId(application?.id);
      try {
        let driverLicenseImageUrl: string | undefined;
        if (application.driverLicenseImagePath) {
          const { data } = await new ApplicationControllerApi(
            getYentaConfiguration(),
          ).getApplicationDriverLicenseImage(application?.id!);

          driverLicenseImageUrl = data.driverLicenseImageUrl;
        }

        const {
          currentFormData,
          currentStep,
          isReviewingForm,
        } = await getCurrentApplicationStepData(
          application,
          driverLicenseImageUrl,
        );

        setFormData(currentFormData);
        setCurrentIndex(currentStep);
        setIsReviewing(isReviewingForm);
      } catch (e: any) {
        ErrorService.notify(
          'Unable to fetch driver license image for the application',
          e,
        );
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const createApplication = async () => {
    const { data: application } = await new ApplicationControllerApi(
      getYentaConfiguration(),
    ).createApplication({
      applicationType: CreateApplicationRequestApplicationTypeEnum.Regular,
    });

    return application;
  };

  useEffect(() => {
    const handleStagedApplication = async () => {
      // If user doesn't have an application, then create one.
      if (!!userDetail) {
        let application = applicationResponse;

        if (!application?.id) {
          application = await createApplication();

          Logger.debug(
            '[Application] - Application created. id=',
            application.id,
          );

          dispatch(fetchAuthUserDetail(false));
        }

        Logger.debug(
          '[Application] - getting prefilled application data',
          application.id,
        );
      }
    };

    handleStagedApplication();
  }, [applicationResponse, getPrefilledApplicationData, dispatch, userDetail]);

  useEffect(() => {
    if (
      !!applicationResponse?.id &&
      currentApplicationId !== applicationResponse?.id // Disable auto refresh when application is updated
    ) {
      getPrefilledApplicationData(applicationResponse);
    }
  }, [applicationResponse, currentApplicationId, getPrefilledApplicationData]);

  return (
    <ApplicationLayout>
      <ResourceContainer
        isEmpty={false}
        loading={isLoading}
        resourceName='Application Form'
      >
        {isReviewing ? (
          <Review
            questions={questions}
            formData={formData as FormDataType}
            title='Please review your application before submitting'
            setIsReviewing={setIsReviewing}
            setCurrentIndex={setCurrentIndex}
          />
        ) : (
          <StepQuestionForm
            questions={questions}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            setIsReviewing={setIsReviewing}
            formData={formData}
            setFormData={setFormData}
          />
        )}
      </ResourceContainer>
    </ApplicationLayout>
  );
};

export default ApplicationForm;
