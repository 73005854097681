import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  Address,
  MoneyValue,
  PercentageValue,
  TransactionBuilderResponseBuilderTypeEnum,
  TransactionResponseTransactionTypeEnum,
} from '../../../openapi/arrakis';
import {
  fetchTransactionBuilder,
  saveTransaction,
  saveTransactionBuilderDetail,
} from '../../../slices/TransactionBuilderSlice';
import {
  AppDispatch,
  CreateTransactionParticipantType,
  ISelectOption,
  RootState,
  YesNoType,
} from '../../../types';
import { getcreateListingDefaultFormValues } from '../../../utils/TransactionHelper';
import DefaultLoader from '../../DefaultLoader';
import StepByStepContainer, {
  StepConfig,
} from '../../StepByStep/StepByStepContainer';
import { GooglePlaceLocationType } from '../../Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenAgentOnboardingLayout from '../../Zen/ZenAgentOnboardingLayout';
import ZenRoute from '../../Zen/ZenRoute';
import ZenListingAddressStep from './ZenListingAddressStep';
import ZenListingOwnerStep from './ZenListingOwnerStep';
import ZenListingSellerStep from './ZenListingSellerStep';
import ZenListingStepsReview from './ZenListingStepsReview';
import ZenListingTypeAndPriceStep from './ZenListingTypeAndPriceStep';

export interface Match {
  transactionBuilderId: string;
}

interface ZenCreateListingStepsProps extends RouteComponentProps<Match> {}

export enum ZenCreateListingStepName {
  ADDRESS_STEP = 'ADDRESS_STEP',
  TYPE_PRICE_STEP = 'TYPE_PRICE_STEP',
  BUYER_AND_SELLER_STEP = 'BUYER_AND_SELLER_STEP',
  OWNER_STEP = 'OWNER_STEP',
  STEP_REVIEW = 'STEP_REVIEW',
}

export interface Participant {
  participantType: CreateTransactionParticipantType;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  address: string;
  isSearchAddress?: boolean;
  location?: GooglePlaceLocationType;
  usePropertyAddress?: YesNoType[];
}

interface Commission {
  percent?: PercentageValue;
  money?: MoneyValue;
  isDollar?: boolean;
}

interface RealCoAgents {
  id?: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  agentId?: string;
}

export interface ZenCreateListingFormState {
  //STEP ONE
  location?: GooglePlaceLocationType;
  unitNo: string;
  yearBuilt: number;
  mlsNumber: string;
  address: Address;
  isManualAddress: boolean;
  manualAddressUnitNo: string;
  manualAddressYearBuilt: number;
  // STEP TWO
  listingDate: Date;
  listingExpirationDate: Date;
  firmDate: Date;
  transactionType: TransactionResponseTransactionTypeEnum;
  price: MoneyValue;
  commission: Commission;
  listingCommission: Commission;
  //STEP THREE
  sellerOrLandlord: Participant[];
  buyerOrTenant: Participant[];
  //STEP FOUR
  transactionOwnerName: ISelectOption;
  realCoAgent: RealCoAgents;
  realCoAgents: RealCoAgents[];
  officeId: string;
  teamId: ISelectOption;
}

export const steps: StepConfig<
  ZenCreateListingFormState,
  ZenCreateListingStepName
>[] = [
  {
    name: ZenCreateListingStepName.ADDRESS_STEP,
    Component: ZenListingAddressStep,
    hidePagination: true,
  },
  {
    name: ZenCreateListingStepName.TYPE_PRICE_STEP,
    Component: ZenListingTypeAndPriceStep,
    hidePagination: true,
  },
  {
    name: ZenCreateListingStepName.BUYER_AND_SELLER_STEP,
    Component: ZenListingSellerStep,
    hidePagination: true,
  },
  {
    name: ZenCreateListingStepName.OWNER_STEP,
    Component: ZenListingOwnerStep,
    hidePagination: true,
  },
  {
    name: ZenCreateListingStepName.STEP_REVIEW,
    Component: ZenListingStepsReview,
    hidePagination: true,
  },
];

const ZenCreateListingSteps: React.FC<ZenCreateListingStepsProps> = ({
  match,
}) => {
  const history = useHistory();
  const { transactionBuilderId } = match.params;
  const {
    userIds: { agentById },
    auth: { userDetail },
    transactionBuilder: { transactionBuilder, transactionBuilderLoading },
  } = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();

  const defaultValue = getcreateListingDefaultFormValues(
    agentById,
    userDetail,
    transactionBuilder,
  );

  useEffect(() => {
    if (transactionBuilderId) {
      dispatch(fetchTransactionBuilder(transactionBuilderId));
    }
  }, [dispatch, transactionBuilderId]);

  const onSubmit = async () => {
    const transactionResponse = await dispatch(
      saveTransaction(
        transactionBuilderId!,
        TransactionBuilderResponseBuilderTypeEnum.Listing,
      ),
    );

    if (transactionResponse) {
      history.push(`/listings/${transactionResponse?.id}/detail`);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(saveTransactionBuilderDetail(undefined));
    };
  }, [dispatch]);

  if (transactionBuilderLoading) {
    return <DefaultLoader />;
  }

  return (
    <ZenRoute title='Create Listing'>
      <ZenAgentOnboardingLayout
        variant='primary'
        title='New Listing'
        onClose={() => history.push('/listings')}
        modalTitle='Listing Saved!'
        modalSubtitle='This listing will be saved in your drafts and will be available for future references.'
        modalSubmitText='OK'
        hideCancelButton
        hideLogout
      >
        <StepByStepContainer<
          ZenCreateListingFormState,
          ZenCreateListingStepName
        >
          steps={steps}
          onSubmit={onSubmit}
          defaultValues={defaultValue}
          reValidateMode='onChange'
          mode='onChange'
        />
      </ZenAgentOnboardingLayout>
    </ZenRoute>
  );
};

export default ZenCreateListingSteps;
