import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  CommissionInfoResponse,
  PaymentParticipantValue,
  TransactionResponse,
} from '../openapi/arrakis';
import { FeatureFlagTypeEnum, RootState } from '../types';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import ZenPaymentParticipantCard from './Zen/Transaction/ZenPaymentParticipantCard';
import ZenDoubleEnderParticipant from './Zen/Transaction/ZenDoubleEnderParticipant';

export interface FormPaymentParticipantsProps {
  paymentParticipants: PaymentParticipantValue[];
  doubleEnderCommissionInfo?: CommissionInfoResponse;
  defaultCollapsed?: boolean;
  transaction?: TransactionResponse;
}

const FormPaymentParticipantsSection: React.FC<FormPaymentParticipantsProps> = ({
  paymentParticipants,
  doubleEnderCommissionInfo,
  defaultCollapsed,
  transaction,
}) => {
  const { isAdmin } = useSelector((state: RootState) => state.auth);

  const isDoubleEnderEnabled = useFeatureFlag(FeatureFlagTypeEnum.DOUBLE_ENDER);

  const showDoubleEnderAgent =
    isDoubleEnderEnabled &&
    !transaction?.buySideRepresentation &&
    !isEmpty(doubleEnderCommissionInfo);

  return (
    <div className='mx-4 mb-20'>
      <div className='py-2 flex flex-row space-x-2'>
        <span className='text-xl text-gray-800 font-primary-medium'>
          Payment Participants
        </span>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 print:grid-cols-2 lg:grid-cols-3 gap-4'>
        {paymentParticipants?.map((participant) => {
          return (
            <div key={participant.id} className='w-full'>
              <ZenPaymentParticipantCard
                participant={participant}
                onEdit={() => {}}
                onDelete={() => {}}
                transactionOwner={false}
                defaultCollapsed={defaultCollapsed}
                isSingleCheck={false}
                isAdmin={isAdmin}
                isReadonly
                hideView
                isPaymentDetailsHidden={participant?.paymentDetailsHidden}
              />
            </div>
          );
        })}
        {showDoubleEnderAgent && (
          <ZenDoubleEnderParticipant
            doubleEnderInfo={transaction?.doubleEnderInfo}
            commissionInfo={doubleEnderCommissionInfo!}
          />
        )}
      </div>
    </div>
  );
};

export default FormPaymentParticipantsSection;
