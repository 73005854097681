import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  OfficePreviewResponse,
  OfficePreviewResponseTransactionTypeEnum,
} from '../../openapi/yenta';
import OfficeCheckPill from './OfficeCheckPill';

interface OptOutSingleCheckProps {
  office: OfficePreviewResponse;
}

const OptOutSingleCheck: React.FC<OptOutSingleCheckProps> = ({ office }) => {
  return (
    <React.Fragment>
      <OfficeCheckPill isSingleCheck />
      {office.transactionType ===
        OfficePreviewResponseTransactionTypeEnum.SplitCheck && (
        <div className='flex items-center'>
          <FontAwesomeIcon
            icon={regular('arrow-right-long')}
            className='mx-2 text-regent-600'
            data-testid='arrowRightLongIcon'
          />
          <OfficeCheckPill isSingleCheck={false} />
        </div>
      )}
    </React.Fragment>
  );
};

export default OptOutSingleCheck;
