import {
  faCalendar,
  faClock,
  faLocationDot,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, values } from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ZenRealWhiteLogo from '../../../assets/img/zen/zen-real-white-logo.png';
import {
  CalendarControllerApi,
  CalendarEventResponseRsvpStatusEnum,
} from '../../../openapi/yenta';
import AmplitudeService, {
  AmplitudeEvent,
} from '../../../services/AmplitudeService';
import ErrorService from '../../../services/ErrorService';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { setWealthPlanEvents } from '../../../slices/WealthManagementSlice';
import { RootState } from '../../../types';
import {
  getEventTemplateBasedOnTitle,
  isYesOrMaybeRsvpStatus,
} from '../../../utils/EventHelper';
import { cn } from '../../../utils/classUtils';
import { getCalendarImageUrl } from '../../../utils/ImgUtils';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { wealthPlanCalenderEvents } from '../../../utils/WealthPlanUtils';
import ResourceContainer from '../../ResourceContainer';
import ZenEventAddToCalendarModal from '../../Zen/Event/ZenEventAddToCalendarModal';
import ZenEventGroupRadioButton from '../../Zen/Event/ZenEventGroupRadioButton';
import { EarningType, WealthPlanCalenderMap } from '../WealthManagementTypes';
import { acceleratorUrlToEarningTypeMap } from '../WealthManagementUtils';
import WealthPlanAcceleratorCard from './WealthPlanAcceleratorCard';

interface WealthPlanCalenderEventCardProps {
  type: EarningType;
}

const WealthPlanCalenderEventCard: React.FC<WealthPlanCalenderEventCardProps> = ({
  type,
}) => {
  const dispatch = useDispatch();
  const {
    auth: { userDetail },
    wealthManagement: { wealthPlanEvents },
  } = useSelector((state: RootState) => state);
  const [isAddToCalendar, setIsAddToCalendar] = React.useState<boolean>(false);
  const eventType = WealthPlanCalenderMap[type]!;
  const EVENT_RESPONSE_BY_TYPE = wealthPlanCalenderEvents(wealthPlanEvents);
  const eventDetails = EVENT_RESPONSE_BY_TYPE[type] || {};

  const isFutureEvent = useMemo(() => {
    if (!!eventDetails?.startAt) {
      return (
        DateTime.fromMillis(eventDetails?.startAt!).diffNow().milliseconds >= 0
      );
    }
    return false;
  }, [eventDetails?.startAt]);

  const onSubmit = async (status: CalendarEventResponseRsvpStatusEnum) => {
    try {
      await new CalendarControllerApi(
        getYentaConfiguration(),
      ).rsvpToCalendarEvent(eventDetails!.id!, userDetail?.id!, status);
      dispatch(
        setWealthPlanEvents({
          eventType,
          data: [{ ...eventDetails, rsvpStatus: status }],
        }),
      );
    } catch (e) {
      ErrorService.notify('Unable to change calendar rsvp status', e);
      dispatch(
        showErrorToast(
          'We had a problem changing calendar rsvp status.',
          'Please try again in a few moments.',
        ),
      );
    }

    if (isYesOrMaybeRsvpStatus(status)) {
      AmplitudeService.logEvent(
        AmplitudeEvent.ADD_EVENT_TO_CALENDAR_MODAL_VIEW,
        { ...eventDetails },
      );

      setIsAddToCalendar(true);
    }
  };

  return (
    <div
      className={cn(
        'w-full bg-white rounded-2xl border-zen-dark-12 overflow-hidden',
        !isEmpty(eventDetails) ? 'border' : 'border-0',
      )}
    >
      <div
        className='relative cursor-pointer'
        onClick={() =>
          window.open(acceleratorUrlToEarningTypeMap[type], '_blank')
        }
      >
        {!!eventDetails.imageUrl ? (
          <img
            src={getCalendarImageUrl(eventDetails?.imageUrl)}
            className='w-full bg-center bg-cover bg-no-repeat rounded-t-2xl'
            alt='event background'
          />
        ) : !!eventDetails?.title ? (
          <div
            className='w-full h-64 rounded-t-2xl bg-center bg-cover bg-no-repeat'
            style={{
              backgroundImage: `url(${getEventTemplateBasedOnTitle(
                eventDetails?.title!,
              )})`,
            }}
          >
            <div className='px-6 py-8'>
              <img src={ZenRealWhiteLogo} className='w-18' alt='real logo' />
              <p className='font-zen-body text-lg font-bold text-white mt-10'>
                {eventDetails?.title}
              </p>
            </div>
          </div>
        ) : (
          <WealthPlanAcceleratorCard type={type} />
        )}
      </div>
      {!isEmpty(eventDetails) && (
        <ResourceContainer
          resourceName='eventDetails detail'
          isEmpty={!eventDetails}
          loading={false}
        >
          <div className='px-6 text-base mt-4'>
            {isFutureEvent && (
              <div className='text-zen-dark-12 flex lg:space-x-6 md:space-x-4 space-x-2'>
                <div className='flex space-x-1'>
                  <div className='h-5.5 w-5.5 flex items-center justify-center'>
                    <FontAwesomeIcon icon={faCalendar} size='sm' />
                  </div>
                  <span className='font-medium'>
                    {eventDetails?.startAt
                      ? DateTime.fromMillis(eventDetails?.startAt!).toFormat(
                          'LLL dd, yyyy',
                        )
                      : 'N/A'}
                  </span>
                </div>
                <div className='flex space-x-1 items-center'>
                  <div className='h-5.5 w-5.5 flex items-center justify-center'>
                    <FontAwesomeIcon icon={faClock} fontSize={14} />
                  </div>
                  <span className='font-medium'>
                    {eventDetails?.startAt
                      ? DateTime.fromMillis(eventDetails.startAt).toFormat(
                          'hh:mm a',
                        )
                      : 'N/A'}{' '}
                    -{' '}
                    {eventDetails?.endAt
                      ? DateTime.fromMillis(eventDetails.endAt).toFormat(
                          'hh:mm a',
                        )
                      : 'N/A'}
                  </span>
                </div>
                <div className='flex space-x-1 items-center'>
                  <div className='h-5.5 w-5.5 flex items-center justify-center'>
                    <FontAwesomeIcon icon={faLocationDot} fontSize={14} />
                  </div>
                  <span className='font-medium'>
                    {eventDetails?.status
                      ? capitalizeEnum(eventDetails.status)
                      : 'N/A'}
                  </span>
                </div>
              </div>
            )}
            <div className='text-zen-dark-9 my-3'>
              <p className=' font-semibold left-7'>Description</p>
              <div className='font-zen-body text-sm font-light text-gray-500 line-clamp-3 loading-4 overflow-hidden'>
                {eventDetails?.description ? (
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: eventDetails.description,
                    }}
                  />
                ) : (
                  <div>No description available</div>
                )}
              </div>
            </div>
          </div>
          {isFutureEvent && (
            <div className='relative h-[72px] mt-3'>
              <div className='absolute bottom-0 left-0 right-0 bg-white px-6 py-4 border-t border-t-zen-light-gray-2'>
                <div className='flex flex-row items-center justify-between'>
                  <p className='font-zen-body font-semibold text-base text-zen-dark-9'>
                    Going?
                  </p>
                  <ZenEventGroupRadioButton
                    options={values(CalendarEventResponseRsvpStatusEnum).map(
                      (status) => ({
                        label: capitalizeEnum(status),
                        value: status,
                      }),
                    )}
                    selected={eventDetails?.rsvpStatus}
                    onSubmit={onSubmit}
                  />
                </div>
              </div>
            </div>
          )}
          {!!isAddToCalendar && (
            <ZenEventAddToCalendarModal
              isOpen={isAddToCalendar}
              onClose={() => setIsAddToCalendar(false)}
              event={eventDetails || {}}
            />
          )}
        </ResourceContainer>
      )}
    </div>
  );
};

export default WealthPlanCalenderEventCard;
