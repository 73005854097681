import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../types';
import { MentionSource } from '../components/transactions/Comments/ZenCommentSection';
import { filterMentionParticipants } from '../utils/TransactionUtils';
import { fetchOfficeDetailById } from '../slices/OfficeSlice';
import { fetchTransactionCommentParticipants } from '../slices/TransactionSlice';

const useTransactionMentions = () => {
  const dispatch = useDispatch();
  const {
    auth: { userDetail },
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
      commentsMentionUsers,
    },
    office: { officeDetailById },
  } = useSelector((state: RootState) => state);

  const officeId = transactionDetail?.office?.id!;

  const getMentions = useCallback(
    (searchTerm: string): MentionSource[] => {
      return filterMentionParticipants(searchTerm, commentsMentionUsers);
    },
    [commentsMentionUsers],
  );

  useEffect(() => {
    if (!officeDetailById[officeId] && officeId) {
      dispatch(fetchOfficeDetailById(officeId));
    }
  }, [dispatch, officeDetailById, officeId]);

  useEffect(() => {
    if (transactionDetail?.id && userDetail?.id) {
      dispatch(
        fetchTransactionCommentParticipants(
          transactionDetail?.id!,
          userDetail?.id!,
        ),
      );
    }
  }, [dispatch, transactionDetail?.id, userDetail?.id]);

  const callerGroupId = commentsMentionUsers?.participantGroupId;

  return { getMentions, callerGroupId };
};

export default useTransactionMentions;
