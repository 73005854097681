import { SvgIcon } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as CompliantIcon } from '../../../../assets/icons/compliant.svg';
import { TransactionResponse } from '../../../../openapi/arrakis';
import {
  markAsNonCompliant,
  markAsCompliant,
} from '../../../../slices/TransactionSlice';
import { AppDispatch } from '../../../../types';
import ExcludeAgent from '../../../auth/ExcludeAgent';
import ZenSimpleConfirmationModal from '../../Modal/ZenSimpleConfirmationModal';
import ZenButton from '../../ZenButton';

interface ZenMarkAsCompliantButtonProps {
  transaction: TransactionResponse;
}

const ZenMarkAsCompliantButton: React.FC<ZenMarkAsCompliantButtonProps> = ({
  transaction,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const setTransactionAsCompliant = async () => {
    setLoading(true);
    if (!!transaction.compliantAt) {
      await dispatch(markAsNonCompliant(transaction.id!));
    } else {
      await dispatch(markAsCompliant(transaction.id!));
    }
    setLoading(false);
    setOpen(false);
  };

  return (
    <ExcludeAgent>
      <ZenButton
        label={`Mark As ${
          !!transaction.compliantAt ? 'Non-Compliant' : 'Compliant'
        }`}
        variant='secondary-gray-outline'
        onClick={() => setOpen(true)}
        LeftIconComponent={
          <SvgIcon
            component={CompliantIcon}
            viewBox='0 0 18 18'
            className='ml-1'
            fill='#05C3F9'
          />
        }
      />
      <ZenSimpleConfirmationModal
        isOpen={isOpen}
        variant='info'
        cancelButtonText='No'
        size='medium'
        hideIcon
        customIcon={
          <SvgIcon
            component={CompliantIcon}
            viewBox='0 0 19 19'
            className='ml-1 mt-1'
            fill='#05C3F9'
          />
        }
        title={`Mark Transaction as ${
          !!transaction.compliantAt ? 'Non-Compliant' : 'Compliant'
        }?`}
        subtitle={`You can always mark this transaction as ${
          !!transaction.compliantAt ? 'compliant' : 'non-compliant'
        } in the future.`}
        confirmButtonText='Yes'
        onConfirm={setTransactionAsCompliant}
        onClose={() => setOpen(false)}
        isSubmitting={isLoading}
      />
    </ExcludeAgent>
  );
};

export default ZenMarkAsCompliantButton;
