import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { faLink, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { cn } from '../../../../utils/classUtils';
import { SharranMediaItem } from '../../../../utils/LeoUtils';
import SharranVideoDefaultThumnail from '../../../../assets/img/leo/sharranVideoDefaultThumnail.jpeg';
import LeoVideoModal from './LeoVideoModal';

type LeoVideoCardProps = {
  isShowingHistory: boolean;
  sharranVideo: SharranMediaItem;
  index: number;
};

const LeoVideoCard: React.FC<LeoVideoCardProps> = ({
  isShowingHistory,
  sharranVideo,
  index,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);

  return (
    <>
      <div
        className={cn(
          'bg-[#0D0F1C] p-4 text-white flex flex-col lg:flex-row gap-4 relative mt-1',
          {
            'rounded-t-md': index === 0,
            'md:flex-row': !isShowingHistory,
          },
        )}
      >
        <div
          className={cn(
            'relative w-full lg:w-72 max-w-[292px] h-fit rounded-md border border-grey-600 overflow-hidden flex-shrink-0',
            {
              'md:w-60': !isShowingHistory,
              'cursor-pointer': !!sharranVideo.media_url,
            },
          )}
          onClick={() => {
            if (!sharranVideo.media_url) {
              return;
            }
            setShowVideoModal(true);
            setIsPlaying(true);
          }}
        >
          <img
            src={sharranVideo.thumbnail || SharranVideoDefaultThumnail}
            alt={sharranVideo.title}
            className='h-full w-full rounded-md'
          />
          {!!sharranVideo.media_url && !isPlaying && (
            <div className='absolute inset-0 flex items-center justify-center'>
              <button className='bg-rezen-blue-600 rounded-md p-4 w-[22px] h-[22px] flex justify-center items-center'>
                <FontAwesomeIcon
                  icon={faPlay}
                  className='text-white'
                  size='xs'
                />
              </button>
            </div>
          )}
          {!!sharranVideo.media_url && !!sharranVideo.duration && (
            <div
              className='absolute bottom-0 w-full px-3'
              style={{
                background:
                  'linear-gradient(0deg, #171821 0%, rgba(23, 24, 33, 0.578385) 50.54%, rgba(23, 24, 33, 0) 100%)',
              }}
            >
              <FontAwesomeIcon
                icon={faClock}
                className='text-grey-300 text-xs'
              />
              <span className='text-grey-300 text-xs font-light ml-1'>
                {Math.floor(sharranVideo.duration / 60)}:
                {sharranVideo.duration % 60}
              </span>
            </div>
          )}
        </div>

        <div className='flex flex-col gap-2.5'>
          <div className='font-inter flex flex-col gap-1'>
            <h2 className='text-base font-normal line-clamp-2'>
              {sharranVideo.title}
            </h2>
            <p className='text-grey-400 text-sm font-light line-clamp-3'>
              {sharranVideo.snippet}
            </p>
          </div>

          <div className='flex flex-row flex-wrap items-center'>
            <a
              href={sharranVideo.source_url}
              target='_blank'
              className='flex flex-row items-center rounded-md bg-white bg-opacity-10 mr-2 mt-1 px-3 py-2'
              rel='noreferrer'
            >
              <FontAwesomeIcon
                icon={faLink}
                className='text-grey-400 mr-1.5 mb-0.5'
                size='xs'
              />
              <p className='text-rezen-blue-600 text-sm font-inter font-normal line-clamp-1'>
                {sharranVideo.title}
              </p>
            </a>
          </div>
        </div>
      </div>

      {showVideoModal && (
        <LeoVideoModal
          title={sharranVideo.title}
          play={isPlaying}
          setPlay={setIsPlaying}
          videoUrl={sharranVideo.media_url!}
          onClose={() => setShowVideoModal(false)}
        />
      )}
    </>
  );
};

export default LeoVideoCard;
