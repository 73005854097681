import { faPaperPlaneTop } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import * as Quill from 'quill';
import quillEmoji from 'quill-emoji';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';
import useAgentsInfoFromKeymakerId from '../../../../hooks/useAgentInfosFromKeymakerId';
import {
  CommentDto,
  FileBlock,
  HashtagBlock,
  HyperlinkBlock,
  ImageBlock,
  MentionBlock,
  PostCommentBodyReaderRoleEnum,
  QueryCommentsRs,
  ReferenceBlock,
  RichText,
  TextBlock,
  UnknownBlock,
} from '../../../../openapi/yada';
import ErrorService from '../../../../services/ErrorService';
import { showErrorToast } from '../../../../slices/ToastNotificationSlice';
import { RootState } from '../../../../types';
import { getExcludedAutoTagMentionPreview } from '../../../../utils/CommentUtils';
import MentionRow from '../../../CommentWidget/MentionRow';
import DefaultLoader from '../../../DefaultLoader';
import { MentionSource } from '../../../transactions/Comments/ZenCommentSection';
import ZenFormErrorMessage from '../../Input/ZenFormErrorMessage';
import ZenAvatar from '../../ZenAvatar';
import ZenResourceContainer from '../../ZenResourceContainer';

interface ZenCommentsCellProps {
  fetchComments(
    pageStart: string | undefined,
  ): QueryCommentsRs | Promise<QueryCommentsRs>;
  getMentions?(searchTerm?: string): MentionSource[];
  onSubmit(
    richContent: RichText,
    readerRole: PostCommentBodyReaderRoleEnum,
  ): Promise<CommentDto>;
  onClose(): void;
}

const ZenCommentsCell: React.FC<ZenCommentsCellProps> = ({
  fetchComments,
  getMentions,
  onSubmit,
  onClose,
}) => {
  const {
    userIds: { agentByKeymakerId, agentById },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const reactQuillRef = useRef<ReactQuill>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [comments, setComments] = useState<CommentDto[]>([]);
  const [nextPageCursor, setNextPageCursor] = useState<string | null>();
  const [mentionError, setMentionError] = useState<string | undefined>(
    undefined,
  );
  const isMortgageRoute = location.pathname.includes('mortgages');

  const handleFetch = useCallback(async () => {
    if (nextPageCursor === null) {
      return;
    }
    setIsLoading(true);
    try {
      const queryCommentRes = await fetchComments(nextPageCursor);
      setComments([...comments, ...(queryCommentRes.comments || [])]);
      setNextPageCursor(queryCommentRes.nextPage);
    } catch (e) {
      ErrorService.notify('Unable to fetch comments', e);
    } finally {
      setIsLoading(false);
    }
  }, [comments, fetchComments, nextPageCursor]);

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agentKeyMakerIds = useMemo(
    () => comments?.map((comment) => comment.createdById!) || [],
    [comments],
  );

  useAgentsInfoFromKeymakerId(agentKeyMakerIds);

  const isEmptyCommentWidget = () => {
    const ops = reactQuillRef.current?.getEditor().getContents().ops || [];
    if (ops.length > 0) {
      if (ops[0]?.insert.emoji || ops[0]?.insert.mention) {
        return false;
      }

      if (typeof ops[0]?.insert === 'object' && isEmpty(ops[0]?.insert)) {
        return true;
      }

      return !(ops[0]?.insert.trim() !== '');
    }
    return true;
  };

  const clearMention = () => {
    document
      .querySelectorAll('.ql-mention-list-container')
      .forEach((element) => {
        element.remove();
      });
  };

  useEffect(() => {
    return () => {
      clearMention();
    };
  }, []);

  const source = async (searchTerm: string, renderList: any) => {
    if (!isSubmitting) {
      const mentions = await getMentions?.(searchTerm);
      const cloneMentions = JSON.parse(JSON.stringify(mentions));
      cloneMentions?.forEach((mention: MentionSource) => {
        mention['value'] = `@${mention?.value}`;
      });
      clearMention();
      renderList(cloneMentions);
    }
  };

  const getMessage = (): [any, Quill.Delta] => {
    const contents = reactQuillRef.current?.getEditor().getContents()!;

    const message = contents.ops!.map((item) => {
      if (item.insert.mention) {
        const mentionId = item.insert.mention.id;
        const agent = getMentions?.('').find((m) => m.id === mentionId);
        return {
          type: 'MENTION',
          userId: mentionId,
          name: agent?.value,
          mentionType: agent?.mentionType,
          autoTag: false,
        } as MentionBlock;
      }

      if (item.insert.emoji) {
        const code = quillEmoji.ShortNameEmoji.DEFAULTS.emojiList.find(
          (emoji: any) => emoji.name === item.insert.emoji,
        ).unicode;
        return {
          type: 'TEXT',
          text: String.fromCodePoint(parseInt(code, 16)),
        } as TextBlock;
      }

      if (item.attributes?.link) {
        return {
          type: 'HYPERLINK',
          text: item.insert,
          url: item.attributes.link,
        };
      }

      return { type: 'TEXT', text: item.insert } as TextBlock;
    });

    return [message, contents];
  };

  const handleSubmit = async (
    blocks?: (
      | FileBlock
      | HashtagBlock
      | HyperlinkBlock
      | ImageBlock
      | MentionBlock
      | ReferenceBlock
      | TextBlock
      | UnknownBlock
    )[],
  ) => {
    const [comment] = getMessage();

    if (comment.length <= 0) {
      return;
    }

    setIsSubmitting(true);
    try {
      const newComment = await onSubmit(
        { blocks: !!blocks?.length ? blocks : comment },
        PostCommentBodyReaderRoleEnum.Public,
      );
      setComments([newComment, ...comments]);
      clearMention();
      // @ts-ignore
      reactQuillRef.current?.getEditor().setContents('');
    } catch (e) {
      ErrorService.notify('Unable to post a comment', e);
      dispatch(
        showErrorToast(
          'Something went wrong while posting comment. Please try after some time.',
        ),
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const getMention = useCallback(() => {
    reactQuillRef.current?.getEditor()?.getModule('mention').openMenu('@');
  }, [reactQuillRef]);

  const submitHandler = () => {
    if (!isMortgageRoute) {
      const [comment] = getMessage();
      const isMentionPresent = (comment || [])?.some(
        (c: any) => c?.type === 'MENTION',
      );
      const textComments: TextBlock[] = (comment || [])?.filter(
        (c: any) => c?.type === 'TEXT',
      );
      let atMentionCharLength = 0;
      for (let textComment of textComments) {
        atMentionCharLength += (textComment?.text?.match(/@/g) || [])?.length;
      }

      if (!isMentionPresent) {
        setMentionError('@ mention is required');
        if (atMentionCharLength < 1) {
          getMention();
        }

        return;
      } else {
        setMentionError(undefined);
      }
    }
    handleSubmit();
  };

  const handleScroll = () => {
    const mentionItemEls = document.querySelectorAll('.ql-mention-list-item');

    if (mentionItemEls.length > 0) {
      const mentionPopup = document.querySelector(
        '.ql-mention-list-container.ql-mention-list-container-bottom',
      );

      if (mentionPopup) {
        mentionPopup.remove();
      }
    }
  };

  useEffect(() => {
    const mainPageEl = document.getElementById('main-page');

    if (mainPageEl) {
      mainPageEl.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (mainPageEl) {
        mainPageEl.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const getFormats = () => {
    const formats = ['link', 'emoji', 'send'];

    if (!!getMentions) {
      formats.splice(2, 0, 'mention');
    }

    return formats;
  };

  const getEnabledModules = () => {
    const modules: Quill.StringMap = {
      toolbar: false,
      linkify: {
        url: true,
        mail: true,
        phoneNumber: false,
      },
      'emoji-shortname': true,
      'emoji-toolbar': true,
      'emoji-textarea': false,
    };

    if (!!getMentions) {
      modules.mention = {
        allowedChars: /^[A-Za-z0-9\s]*$/,
        mentionDenotationChars: ['@'],
        offsetTop: 20,
        defaultMenuOrientation: 'bottom',
        showDenotationChar: false,
        dataAttributes: ['id', 'value', 'emailAddress'],
        positioningStrategy: 'fixed',
        onSelect: (item: any, insertItem: any) => {
          insertItem(item);
          setMentionError(undefined);
        },
        renderItem: (item: any) =>
          ReactDOMServer.renderToStaticMarkup(
            <MentionRow
              name={
                item.value[0] === '@' ? item.value.substring(1) : item.value
              }
              emailAddress={item.emailAddress}
              avatar={agentById[item.id]?.avatar}
            />,
          ),
        source,
      };
    }

    return modules;
  };

  return (
    <Popover
      isOpen
      positions={['bottom']}
      align='end'
      onClickOutside={onClose}
      content={
        <div className='z-10 mt-1 bg-white ring-1 ring-zen-dark-6 w-80 rounded-lg'>
          <ZenResourceContainer
            loading={!comments?.length && isLoading}
            isEmpty={!comments?.length && nextPageCursor === null}
            resourceName='comments'
            emptyMessage='No comments added.'
            emptyIcon={<span />}
          >
            <InfiniteScroll
              hasMore={!!nextPageCursor}
              loader={<DefaultLoader />}
              dataLength={comments?.length || 0}
              height={200}
              next={async () => await handleFetch()}
            >
              {comments
                ?.filter((comment) =>
                  comment.richContent?.blocks?.every(
                    (block) => block.type !== 'IMAGE',
                  ),
                )
                ?.map((comment) => {
                  const agentData = agentByKeymakerId[comment.createdById!];

                  return (
                    <div key={comment.id}>
                      <div
                        key={comment.id}
                        className='flex items-start space-x-2 px-3 py-2'
                      >
                        <div>
                          <ZenAvatar
                            name={`${agentData?.firstName} ${agentData?.lastName}`}
                            imageUrl={agentData?.avatar!}
                            size='xs'
                          />
                        </div>
                        <p className='font-zen-body font-normal text-sm text-zen-dark-9 word-break'>
                          <span className='m-0 p-0'>
                            {getExcludedAutoTagMentionPreview(
                              comment?.richContent,
                            )}
                          </span>
                          <span className='text-xs text-zen-dark-6 whitespace-nowrap'>
                            {DateTime.fromMillis(
                              comment.createdAt || 0,
                            ).toFormat('LL/dd/yyyy')}
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                })}
            </InfiniteScroll>
          </ZenResourceContainer>
        </div>
      }
    >
      <div id='cell' className='w-80'>
        {mentionError && (
          <div className='mt-2'>
            <ZenFormErrorMessage message={mentionError} />
          </div>
        )}
        <div className='flex flex-row items-center border px-2 py-1 border-zen-dark-9 rounded-lg font-normal'>
          <ReactQuill
            ref={reactQuillRef}
            className='w-[270px] checklist-comment'
            onKeyDown={(event) => {
              if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
                if (!isEmptyCommentWidget()) {
                  submitHandler();
                }
              }
            }}
            onChange={() => {
              if (isEmptyCommentWidget()) {
                submitButtonRef.current?.setAttribute('disabled', 'true');
              } else {
                submitButtonRef.current?.removeAttribute('disabled');
              }
            }}
            modules={getEnabledModules()}
            formats={getFormats()}
            placeholder='Add Comments'
          />
          <button
            type='button'
            ref={submitButtonRef}
            disabled={isSubmitting}
            onClick={() => {
              if (!isEmptyCommentWidget()) {
                submitHandler();
              }
            }}
            className={classNames(
              'flex items-center gap-x-1 font-primary-regular text-lg text-white z-0 disabled:opacity-40 disabled:cursor-not-allowed cursor-pointer bg-primary-blue p-1.5 rounded-md ml-1',
              { 'opacity-40 cursor-not-allowed': isLoading },
            )}
          >
            <FontAwesomeIcon icon={faPaperPlaneTop} className='text-base' />
          </button>
        </div>
      </div>
    </Popover>
  );
};

export default ZenCommentsCell;
