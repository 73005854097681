import { useState } from 'react';
import {
  CalendarEventInviteeResponseRsvpStatusEnum,
  CalendarInviteesCountResponse,
} from '../../../openapi/yenta';
import ZenSimpleModal from '../Modal/ZenSimpleModal';
import ZenTabs from '../ZenTabs';
import ZenEventInvitees from './ZenEventInvitees';

interface ZenEventInviteesModalProps {
  isOpen: boolean;
  onClose(): void;
  calendarEventId: string;
  eventInviteesCount: CalendarInviteesCountResponse;
}

const ZenEventInviteesModal: React.FC<ZenEventInviteesModalProps> = ({
  isOpen,
  onClose,
  calendarEventId,
  eventInviteesCount,
}) => {
  const [selected, setSelected] = useState<string>();

  return (
    <ZenSimpleModal
      isOpen={isOpen}
      onClose={onClose}
      title='Event Attendees'
      size='extraLarge'
    >
      <div>
        <ZenTabs
          tabs={[
            {
              name: `Going (${eventInviteesCount?.rsvpYesCount || 0})`,
              TabComponent: (
                <ZenEventInvitees
                  calendarEventId={calendarEventId}
                  status={CalendarEventInviteeResponseRsvpStatusEnum.Yes}
                />
              ),
            },
            {
              name: `Maybe (${eventInviteesCount?.rsvpMaybeCount || 0})`,
              TabComponent: (
                <ZenEventInvitees
                  calendarEventId={calendarEventId}
                  status={CalendarEventInviteeResponseRsvpStatusEnum.Maybe}
                />
              ),
            },
          ]}
          selected={selected}
          onChange={setSelected}
          size='sm'
        />
      </div>
    </ZenSimpleModal>
  );
};

export default ZenEventInviteesModal;
