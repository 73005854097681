import { ApplicationResponse } from '../openapi/yenta';
import Route404 from '../routes/Route404';
import { FormType, FormTypeToComponentMap } from '../utils/FormUtils';
import CARealBrokerageICAForm from './forms/CARealBrokerageICAForm';
import USRealBrokerageICAForm from './forms/USRealBrokerageICAForm';
import LetterOfIntentForm from './LetterOfIntentForm';
import withNextPrevTooltip from './NextButtonTooltip';

export interface FormBasedOnTypeProps<T> {
  formType: FormType;

  onSubmit(values: T): void;

  application?: ApplicationResponse;
}

export const FORM_TYPE_TO_COMPONENT: FormTypeToComponentMap = {
  [FormType.US_REAL_BROKERAGE_ICA]: USRealBrokerageICAForm,
  [FormType.CA_REAL_BROKERAGE_ICA]: CARealBrokerageICAForm,
  [FormType.LETTER_OF_INTENT]: LetterOfIntentForm,
};

const FormBasedOnType = <T extends any>({
  formType,
  onSubmit,
  application,
}: FormBasedOnTypeProps<T>) => {
  const FormComponent = FORM_TYPE_TO_COMPONENT[formType];

  if (!FormComponent) {
    return <Route404 />;
  }

  return <FormComponent onSubmit={onSubmit} application={application} />;
};

export default withNextPrevTooltip()(FormBasedOnType);
