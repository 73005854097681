import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keys, last } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useEarningOnEditClick from '../../../hooks/useEarningOnEditClick';
import { setSelectedEarningsOverviewType } from '../../../slices/WealthManagementSlice';
import { RootState } from '../../../types';
import { getLockedEarnings } from '../../../utils/WealthPlanUtils';
import ResourceContainer from '../../ResourceContainer';
import EarningsSidebarV2 from '../EarningsSidebar/V2/EarningsSidebarV2';
import { UnlockEarningModal } from '../UnlockEarningModal';
import { Earning, EarningType } from '../WealthManagementTypes';
import AdditionalRealStockCard from './AdditionalRealStockCard';
import EarningsOverviewCard from './EarningsOverviewCard';

interface EarningOverviewProps {
  openGoalsSideBar(): void;
}

const EarningOverview: React.FC<EarningOverviewProps> = ({
  openGoalsSideBar,
}) => {
  const dispatch = useDispatch();
  const {
    earningsOverviewResponse,
    wealthGoalsResponse,
    selectedEarningsOverviewType,
  } = useSelector((state: RootState) => state.wealthManagement);

  const selectedType = last(selectedEarningsOverviewType);

  const {
    onClickEnumMap,
    selectedEarning,
    showParticipationModal,
    setSelectedEarning,
    setShowParticipationModal,
  } = useEarningOnEditClick();

  const { isLockedEarningTypeEnumMap } = getLockedEarnings(
    wealthGoalsResponse.data?.goalDetails,
  );

  return (
    <div className='w-full bg-white rounded-[10px] md:p-6 p-4 shadow-pop-over'>
      <div className='flex items-center mb-6'>
        <FontAwesomeIcon
          icon={faBullseyeArrow}
          className='text-zen-dark-13 text-[20px] font-[300] mr-3'
        />
        <p className='text-zen-dark-13 text-lg font-semibold'>
          Earnings Overview
        </p>
      </div>
      <ResourceContainer
        loading={
          earningsOverviewResponse.loading && !earningsOverviewResponse.data
        }
        isEmpty={false}
        resourceName='wealth earnings overview'
      >
        <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-flow-row gap-4'>
          {keys(Earning)
            .filter((earning) => earning !== 'REAL_TITLE_JOINT_VENTURE')
            .map((key) => (
              <EarningsOverviewCard
                key={key}
                onClick={onClickEnumMap[key as EarningType]}
                earningType={key as EarningType}
                isLocked={isLockedEarningTypeEnumMap[key as EarningType]}
              />
            ))}
        </div>
        <AdditionalRealStockCard onEditClick={openGoalsSideBar} />
        <UnlockEarningModal
          isOpen={!!showParticipationModal}
          onClose={(openSidebar) => {
            if (!openSidebar) {
              dispatch(setSelectedEarningsOverviewType(undefined));
              setSelectedEarning(null);
            } else {
              dispatch(setSelectedEarningsOverviewType(selectedEarning!));
            }
            setShowParticipationModal(undefined);
          }}
          wealthGoalProperty={showParticipationModal!}
          earningType={selectedEarning}
        />
      </ResourceContainer>
      {!!selectedType && (
        <EarningsSidebarV2
          isOpen
          earningType={selectedType}
          onClose={() => dispatch(setSelectedEarningsOverviewType(undefined))}
        />
      )}
    </div>
  );
};

export default EarningOverview;
