import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { CreateSentimentRequestSatisfactionRateEnum } from '../../../openapi/arrakis';
import { FormFieldTooltipIndexProps } from '../../../types';
import CustomFeedbackGroupRadioButton from './CustomFeedbackGroupRadioButton';

export interface FeedbackOption {
  icon: React.ReactElement;
  label: string;
  value: CreateSentimentRequestSatisfactionRateEnum;
}

export interface ControlledSentimentFeedbackInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  options: Array<FeedbackOption>;
  disabled?: boolean;
}

const ControlledSentimentFeedbackInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  disabled = false,
  options,
  tooltipIndex,
  shouldUnregister = true,
  ...rest
}: ControlledSentimentFeedbackInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      {...rest}
      shouldUnregister={shouldUnregister}
      render={({ field: { name, value, onChange } }) => (
        <div className='w-full space-y-2'>
          <div className='flex flex-col space-y-1'>
            {label && <label htmlFor={name}>{label}</label>}
            <div data-tooltip-index={tooltipIndex}>
              <CustomFeedbackGroupRadioButton
                items={options}
                onChange={onChange}
                value={value}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default ControlledSentimentFeedbackInput;
