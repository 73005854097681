import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LeadLoanDtoStatusEnum } from '../../openapi/atlantis';
import AnalyticsService from '../../services/AnalyticsService';
import {
  setShowApplicationError,
  submitApplication,
  updateApplicationStatus,
} from '../../slices/MortgageSlice';
import store from '../../slices/store';
import {
  AnalyticsEventEnum,
  AppDispatch,
  RootState,
  UpdateLoanRequestStatusEnum,
} from '../../types';
import { getFullName } from '../../utils/AgentHelper';
import { cn } from '../../utils/classUtils';
import { isReadyToSubmit } from '../../utils/mortgage/MortgageApplicationFormUtils';
import {
  canSubmitApplication,
  canUploadPreApprovalLetter,
  getBorrower,
} from '../../utils/MortgageUtils';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../Zen/ZenButton';
import MortgageDetailsHeaderCell from './MortgageDetailsHeaderCell';
import MortgageDetailsRouteHeaderAuthorizedPersons from './MortgageDetailsRouteHeaderAuthorizedPersons';
import MortgageStatus from './MortgageStatus';
import MortgageUploadPreApprovalLetterSidebar from './MortgageUploadPreApprovalLetterSidebar';

export interface MortgageDetailsRouteHeaderProps {
  borrowerId: string;
}

const MortgageDetailsRouteHeader: React.FC<MortgageDetailsRouteHeaderProps> = ({
  borrowerId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    mortgage: {
      loanResponse: { data: loan },
      borrowerApplications,
    },
    auth: { isMortgageAdmin },
  } = useSelector((state: RootState) => state);
  const [isSticky, setIsSticky] = useState(false);
  const [uploadPreApproval, setUploadPreApproval] = useState<boolean>(false);
  const [isTerminating, setIsTerminating] = useState<boolean>(false);
  const [isTerminateModalOpen, setIsTerminateModalOpen] = useState<boolean>(
    false,
  );
  const [
    isSubmittingApplication,
    setIsSubmittingApplication,
  ] = useState<boolean>(false);
  const loanId = loan?.id!;
  const borrower = getBorrower(borrowerId, loan?.borrowers || [])!;
  const showTerminateButton = isMortgageAdmin && loan?.status !== 'TERMINATED';
  const showSubmitApplication = canSubmitApplication(borrower);
  const showUploadPreApprovalLetter = canUploadPreApprovalLetter(
    loan,
    borrower,
  );

  const onApplicationSubmit = async () => {
    setIsSubmittingApplication(true);

    const buttons = document.querySelectorAll('.trigger');

    if (buttons) {
      const triggerPromises = Array.from(buttons).map((button: any) => {
        // Adding setTimeout to ensure the click event is triggered and loading state is set
        setTimeout(() => {
          (button as HTMLButtonElement).click();
        }, 0);

        // Add event listener to wait for the trigger event to finish
        return new Promise((resolve) => {
          button.addEventListener('click', () => setTimeout(resolve, 0), {
            once: true,
          });
        });
      });

      await Promise.all(triggerPromises);
    }

    const application = borrowerApplications[borrowerId] || {};

    // Retrieving the updated form validity value from the store because the useSelector value is not updated.
    const formValidityById = store.getState().mortgage.formValidityById;

    if (isReadyToSubmit(formValidityById, application.data?.sections)) {
      const response = await dispatch(submitApplication(loanId, borrowerId));

      if (response) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.MC_APPLICATION_SUBMITTED,
        );
      }
      dispatch(setShowApplicationError(false));
    } else {
      dispatch(setShowApplicationError(true));
    }

    setIsSubmittingApplication(false);
  };

  const handleTerminateApplication = async () => {
    setIsTerminating(true);
    await dispatch(
      updateApplicationStatus(
        loanId,
        'TERMINATED' as UpdateLoanRequestStatusEnum,
      ),
    );
    setIsTerminating(false);
    setIsTerminateModalOpen(false);
  };

  useEffect(() => {
    const element = document.getElementById('main-page');

    const handleScroll = () => {
      if (element && window.innerWidth >= 1024 && element.scrollTop > 126) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <React.Fragment>
      <div
        id='sticky-header'
        className={cn(
          'z-[31] w-full mb-4 lg:mb-2 h-auto bg-transparent pointer-events-none',
          isSticky ? 'lg:sticky top-0' : 'static',
        )}
      >
        <header
          className={cn(
            'px-4 md:px-8 flex flex-col gap-y-5 justify-between md:gap-x-4 bg-zen-dark-13 scrollbar overflow-x-auto overflow-auto space-y-4 md:space-y-0 transition-all pointer-events-auto',
            isSticky
              ? 'mx-0 py-4 rounded-none'
              : 'mx-4 py-6 rounded-2xl rounded-tl-none',
          )}
        >
          <div className={cn({ hidden: isSticky })}>
            <MortgageDetailsRouteHeaderAuthorizedPersons />
            <div className='w-full flex flex-col md:flex-row items-center space-x-4 space-y-10 md:space-y-0'>
              <div className='flex flex-col md:flex-row  items-start md:items-center md:space-x-6 space-y-6 md:space-y-0'>
                <div className='flex flex-col items-start gap-y-1'>
                  <p className='mb-2 font-zen-body font-semibold text-xs text-white uppercase'>
                    Personal Contact Info
                  </p>
                  <div className='flex flex-row items-center space-x-2 w-max'>
                    <MortgageDetailsHeaderCell
                      icon={regular('phone')}
                      text={borrower?.phoneNumber || 'N/A'}
                    />
                    <div className='h-5 w-px bg-zen-dark-8' />
                    <MortgageDetailsHeaderCell
                      icon={regular('envelope')}
                      text={borrower?.emailAddress || 'N/A'}
                    />
                  </div>
                </div>

                <div className='flex flex-col items-start gap-y-1'>
                  <p className='mb-2 font-zen-body font-semibold text-xs text-white uppercase'>
                    File Cabinet Email
                  </p>
                  <div className='flex flex-row items-center space-x-2 w-max'>
                    <MortgageDetailsHeaderCell
                      icon={regular('envelope')}
                      text={borrower?.dropboxEmailAddress || 'N/A'}
                    />
                  </div>
                </div>
              </div>
              <div className='w-full flex flex-col md:flex-row flex-grow flex-shrink items-center md:items-end md:space-x-4 justify-center md:justify-end space-y-6 md:space-y-0'>
                {showTerminateButton && (
                  <div className='flex items-center'>
                    <ZenButton
                      label='Terminate'
                      variant='danger-outline'
                      isDisabled={isTerminating}
                      isSubmitting={isTerminating}
                      onClick={() => setIsTerminateModalOpen(true)}
                      LeftIconComponent={
                        <FontAwesomeIcon icon={regular('trash-can')} />
                      }
                    />
                  </div>
                )}
                {showSubmitApplication && (
                  <div className='flex items-center'>
                    <ZenButton
                      label='Submit Application'
                      isDisabled={isSubmittingApplication}
                      isSubmitting={isSubmittingApplication}
                      onClick={onApplicationSubmit}
                    />
                  </div>
                )}
                {showUploadPreApprovalLetter && (
                  <div className='flex items-center'>
                    <ZenButton
                      label='Upload Pre-approval Letter'
                      onClick={() => setUploadPreApproval(true)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={cn('flex flex-row justify-between items-center', {
              hidden: !isSticky,
            })}
          >
            <div className='flex items-center'>
              <MortgageStatus
                status={(borrower.status as unknown) as LeadLoanDtoStatusEnum}
              />
              {isSticky && (
                <p className='ml-4 font-zen-body font-bold text-xl text-white'>
                  {getFullName(borrower) || 'N/A'}
                </p>
              )}
            </div>

            <div className='flex items-center'>
              {showSubmitApplication && (
                <ZenButton
                  label='Submit Application'
                  isDisabled={isSubmittingApplication}
                  isSubmitting={isSubmittingApplication}
                  onClick={onApplicationSubmit}
                />
              )}

              {showUploadPreApprovalLetter && (
                <div className='flex items-center'>
                  <ZenButton
                    label='Upload Pre-approval Letter'
                    onClick={() => setUploadPreApproval(true)}
                  />
                </div>
              )}
            </div>
          </div>
        </header>
      </div>

      <MortgageUploadPreApprovalLetterSidebar
        isOpen={uploadPreApproval}
        onClose={() => setUploadPreApproval(false)}
        borrower={borrower}
      />

      <ZenConfirmationModal
        isOpen={isTerminateModalOpen}
        variant='danger'
        title='Are you sure you want to terminate this loan?'
        subtitle='Moving forward with this termination will permanently delete this loan.'
        size='large'
        onClose={() => setIsTerminateModalOpen(false)}
        confirmButtonText='Yes'
        cancelButtonText='No'
        onConfirm={handleTerminateApplication}
        isSubmitting={isTerminating}
        isDisabled={isTerminating}
      />
    </React.Fragment>
  );
};

export default MortgageDetailsRouteHeader;
