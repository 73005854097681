import { useSelector } from 'react-redux';
import Route404 from '../routes/Route404';
import { RootState, UserType } from '../types';

export type RouteComponentUserRoleMappingConfig = {
  [x in UserType]?: React.FC<any> | React.ComponentClass<any, any>;
};

interface RenderRoleBasedComponentProps {
  config: RouteComponentUserRoleMappingConfig;
}

const RenderRoleBasedComponent: React.FC<RenderRoleBasedComponentProps> = ({
  config,
  ...rest
}) => {
  const {
    isSuperAdmin,
    isRealTitleOfficer,
    isAdmin,
    isBroker,
    isLoanOfficer,
    isMortgageAdmin,
    isLoanProcessor,
    isROMA,
  } = useSelector((state: RootState) => state.auth);

  const isAgent =
    !isSuperAdmin &&
    !isAdmin &&
    !isBroker &&
    !isMortgageAdmin &&
    !isLoanOfficer &&
    !isLoanProcessor;

  let Component: React.FC | React.ComponentClass;
  if (isSuperAdmin && !!config.SUPER_ADMIN) {
    Component = config.SUPER_ADMIN;
  } else if (isAdmin && !!config.ADMIN) {
    Component = config.ADMIN;
  } else if (isBroker && config.BROKER) {
    Component = config.BROKER;
  } else if (isRealTitleOfficer && !!config.REAL_TITLE) {
    Component = config.REAL_TITLE;
  } else if (isMortgageAdmin && !!config.MORTGAGE_ADMIN) {
    Component = config.MORTGAGE_ADMIN;
  } else if (isLoanOfficer && !!config.LOAN_OFFICER) {
    Component = config.LOAN_OFFICER;
  } else if (isLoanProcessor && !!config.LOAN_PROCESSOR) {
    Component = config.LOAN_PROCESSOR;
  } else if (isROMA && !!config.REAL_ORIGINATE_LOAN_OFFICER) {
    Component = config.REAL_ORIGINATE_LOAN_OFFICER;
  } else if (isAgent && !!config.AGENT) {
    Component = config.AGENT;
  } else {
    Component = Route404;
  }

  return <Component {...rest} />;
};

export default RenderRoleBasedComponent;
