import { faTrashCan, faPencilAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TriggerDefinitionDto } from '../../../openapi/sherlock';
import {
  removeTriggerFromItem,
  setEditTrigger,
} from '../../../slices/ChecklistDefinitionSlice';
import IconButton from '../../IconButton';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import ZenCard from '../ZenCard';
import TriggerSubscription from './TriggerSubscription';

interface TriggerCardProps {
  trigger: TriggerDefinitionDto;
  itemId: string;
  templateId: string;
}

const TriggerCard: React.FC<TriggerCardProps> = ({
  trigger,
  itemId,
  templateId,
}) => {
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const deleteTrigger = async () => {
    setIsDeleting(true);
    await dispatch(removeTriggerFromItem(templateId, itemId, trigger?.id!));
    setIsDeleting(false);
    setOpenDeleteModal(false);
  };

  return (
    <div className='mb-10'>
      <ZenCard
        title={`Trigger created on ${DateTime.fromMillis(
          trigger?.createdAt! || 0,
        ).toFormat('LL/dd/yyyy')}`}
        RightComponent={
          <div className='my-3 flex'>
            <IconButton
              variant='none'
              buttonStyle='bg-zen-dark-light rounded-full'
              leftIcon={
                <FontAwesomeIcon
                  size='sm'
                  className='my-1 mx-0 p-0.5 text-zen-dark'
                  title='edit-trigger'
                  icon={faPencilAlt}
                />
              }
              onClick={() => {
                dispatch(setEditTrigger(trigger));
              }}
            />

            <IconButton
              variant='none'
              buttonStyle='bg-red-100 rounded-full'
              leftIcon={
                <FontAwesomeIcon
                  icon={faTrashCan}
                  size='sm'
                  className='my-1 mx-0 p-0.5 text-red-600'
                  title='delete-trigger'
                />
              }
              onClick={() => setOpenDeleteModal(true)}
            />
          </div>
        }
      >
        <div className='px-6 py-8'>
          <TriggerSubscription trigger={trigger} />
        </div>
      </ZenCard>

      <ZenConfirmationModal
        isOpen={openDeleteModal}
        isSubmitting={isDeleting}
        size='default'
        variant='danger'
        title='Delete Trigger'
        subtitle='Are you sure you want to delete this trigger?'
        confirmButtonText='Delete'
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={deleteTrigger}
        hideIcon={true!}
      />
    </div>
  );
};

export default TriggerCard;
