import { debounce } from 'lodash';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import AnalyticsService from '../../../../services/AnalyticsService';
import { AnalyticsEventEnum, RootState } from '../../../../types';
import { getTeamDefaultValues } from '../../../../utils/WealthPlanUtils';
import ZenSidebarModalForm from '../../../Zen/ZenSidebarModalForm';
import CommissionIncomeFormInputs from '../../V2/Forms/CommissionIncomeFormInputs';
import RevenueShareIncomeFormInputs from '../../V2/Forms/RevenueShareIncomeFormInputs';
import StockPurchasePlanSppFormInputs from '../../V2/Forms/StockPurchasePlanSppFormInputs';
import StockForecastSliderInput from '../../V2/StockForecastSliderInput';

export interface GoalsFormInput {
  earningsGoalValue: number;
  averageHomeSalePrice: number;
  transactionsPerYear: number;
  volumePerYear: number;
  useAverageFixedCommission: boolean;
  averageFixedCommission: number;
  averageTransactionCommissionPercentage: number;
  teamLead: boolean;
  teamNumberCount?: number;
  teamTransactionsPerMember?: number;
  teamAverageSalesPrice?: number;
  teamLeaderCommissionPercentagePerTransaction?: number;
  teamUseAverageFixedCommission?: boolean;
  teamAverageFixedCommission?: number;
  teamAverageTransactionCommissionPercentage?: number;
  sppParticipation: boolean;
  revShareParticipation: boolean;
  agentReferredPerYear: number;
  agentsAgentReferredPerYear: number;

  ownsAdditionalRealStockShares: boolean;
  additionalRealShares: number;

  eliteAgentProductionAwardParticipation: boolean;
  eliteAgentCulturalAwardParticipation: boolean;
  realStockFuturePrice: number;
}

interface SetGoalsSideBarProps {
  isOpen: boolean;
  onClose(): void;
  onSubmit(values: GoalsFormInput): void;
}

const EditReferralGoalsSideBarV2: React.FC<SetGoalsSideBarProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const {
    wealthManagement: {
      defaultWealthGoalsResponse: { data: defaultWealthGoalsResponse },
      wealthGoalsResponse: { data: wealthGoalsResponse },
    },
  } = useSelector((state: RootState) => state);

  const defaultValues =
    wealthGoalsResponse ?? defaultWealthGoalsResponse ?? undefined;

  const goalDetails = defaultValues?.goalDetails;

  const formControls = useForm<GoalsFormInput>({
    defaultValues: {
      ...goalDetails,
      earningsGoalValue: goalDetails?.earningsGoalValue?.amount,
      averageFixedCommission: goalDetails?.averageFixedCommission?.amount,
      realStockFuturePrice: goalDetails?.realStockFuturePrice?.amount,
      averageHomeSalePrice: goalDetails?.averageHomeSalePrice?.amount || 300000,
      transactionsPerYear: goalDetails?.transactionsPerYear || 12,
      volumePerYear: goalDetails?.volumePerYear?.amount ?? 0,
      useAverageFixedCommission:
        goalDetails?.useAverageFixedCommission || false,
      averageTransactionCommissionPercentage:
        goalDetails?.averageTransactionCommissionPercentage || 2.25,
      additionalRealShares: goalDetails?.additionalRealShares || 10,
      eliteAgentProductionAwardParticipation:
        goalDetails?.eliteAgentProductionAwardParticipation ?? false,
      eliteAgentCulturalAwardParticipation:
        goalDetails?.eliteAgentProductionAwardParticipation ?? false,
      ...getTeamDefaultValues(goalDetails),
    },
  });

  const {
    control,
    watch,
    formState: { isSubmitting },
    handleSubmit,
  } = formControls;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string) => {
      AnalyticsService.instance().logEvent(event);
    }, 500),
    [],
  );

  const handleSubmitPlan = (values: GoalsFormInput) => {
    logAnalyticsEvent(AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_CLICK_MY_PLAN);
    onSubmit(values);
    onClose();
  };

  const handleClose = () => {
    logAnalyticsEvent(AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_X_GO_BACK);
    onClose();
  };

  return (
    <ZenSidebarModalForm
      title='Goal Setup'
      isOpen={isOpen}
      onClose={handleClose}
      actionTitle='Update My Plan'
      onSubmit={handleSubmit(handleSubmitPlan)}
      isSubmitting={isSubmitting}
      isDisabled={isSubmitting}
    >
      <FormProvider {...formControls}>
        <div className='pb-3'>
          Please provide some information so we can create a plan for you.
        </div>
        <div className='space-y-4'>
          <CommissionIncomeFormInputs logAnalyticsEvent={logAnalyticsEvent} />
          <StockPurchasePlanSppFormInputs
            logAnalyticsEvent={logAnalyticsEvent}
          />
          <RevenueShareIncomeFormInputs logAnalyticsEvent={logAnalyticsEvent} />
          <StockForecastSliderInput
            formControlParam={control}
            formWatchParam={watch}
            noContainer={false}
          />
        </div>
      </FormProvider>
    </ZenSidebarModalForm>
  );
};

export default EditReferralGoalsSideBarV2;
