import { AvatarSizeType, EnumMap } from '../../types';
import { cn } from '../../utils/classUtils';
import Avatar from '../Avatar';

export type ZenUserPillSizeType = 'xs' | 'sm' | 'md';
export type ZenPillBackgroundType = 'background' | 'no-background';

export interface GeminiUserPillProps {
  name: string;
  size?: ZenUserPillSizeType;
  imageUrl?: string;
  backgroundVariant?: ZenPillBackgroundType;
  fontWeight?: string;
  noWrap?: boolean;
  isRealBroker?: boolean;
}

const GeminiUserPill: React.FC<GeminiUserPillProps> = ({
  size = 'xs',
  backgroundVariant = 'background',
  imageUrl,
  name,
  isRealBroker = false,
  fontWeight = 'font-semibold',
  noWrap = false,
}) => {
  const geminiUserPillTextToClassNameMap: EnumMap<
    ZenUserPillSizeType,
    string
  > = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
  };

  const geminiUserPillSizeToClassNameMap: EnumMap<
    ZenUserPillSizeType,
    AvatarSizeType
  > = {
    xs: 'xxs',
    sm: 'xxs',
    md: 'sm',
  };

  const geminiUserBackgroundToClassNameMap: EnumMap<
    ZenPillBackgroundType,
    string
  > = {
    background:
      'rounded-full bg-zen-light-blue hover:bg-opacity-50 px-2 py-1.5',
    'no-background': 'bg-none',
  };

  return (
    <div
      className={cn(
        geminiUserBackgroundToClassNameMap[backgroundVariant],
        fontWeight,
        'font-zen-body inline-block',
      )}
    >
      <div
        className={cn('flex flex-row content-center space-x-2 items-center', {
          'whitespace-nowrap': noWrap,
        })}
      >
        <Avatar
          name={name}
          size={geminiUserPillSizeToClassNameMap[size]}
          imageUrl={imageUrl}
        />
        <p
          className={cn(
            geminiUserPillTextToClassNameMap[size],
            'text-primary-dark font-inter capitalize',
          )}
        >
          {isRealBroker ? `Real Broker (${name})` : name}
        </p>
      </div>
    </div>
  );
};

export default GeminiUserPill;
