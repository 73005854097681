import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { last } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useRedirectAwayOnboardingEffect from '../../hooks/useRedirectAwayOnboardingEffect';
import {
  ApplicationControllerApi,
  ApplicationResponseNextStatusEnum,
  ICASignRequest,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { saveApplication } from '../../slices/AuthSlice';
import { RootState, YesNoType } from '../../types';
import { getErrorMessage } from '../../utils/ErrorUtils';
import { getFormType, ICAForm } from '../../utils/FormUtils';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import Alert from '../Alert';
import ApplicationLayout from '../ApplicationLayout';
import FormBasedOnType from '../FormBasedOnType';

const ApplicationICASigning: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const application = last(userDetail?.applications);

  useRedirectAwayOnboardingEffect(ApplicationResponseNextStatusEnum.SignIca);

  const handleSubmit = async (values: ICAForm) => {
    try {
      const api = new ApplicationControllerApi(getYentaConfiguration());
      const icaSignRequest: ICASignRequest = {
        applicationId: application?.id!,
        customAnniversaryDate: values.customAnniversaryDate,
        emergencyContactEmailAddress: values.emergencyContactEmailAddress,
        emergencyContactName: values.emergencyContactName,
        emergencyContactPhoneNumber: values.emergencyContactPhoneNumber,
        emergencyContactRelationship: values.emergencyContactRelationship,
        initials: values.initials,
        entityName: values.entityName,
        name: values.name,
        signature: values.signature,
        optInStockPlan: values.optInStockPlan === YesNoType.YES,
        sponsoringAgents: [
          ...(values.referringAgentName
            ? [
                {
                  name: values.referringAgentName,
                  email: values.referringAgentEmail,
                },
              ]
            : []),
          ...(values.referringCoAgentName
            ? [
                {
                  name: values.referringCoAgentName,
                  email: values.referringCoAgentEmail,
                },
              ]
            : []),
        ],
      };

      const { data } = await api.signICA(icaSignRequest);
      dispatch(saveApplication(data));
      history.push('/onboarding/application-ica-signing/success');
    } catch (e) {
      ErrorService.notify('Unable to sign ICA', e);
      setErrorMessage(getErrorMessage(ErrorService.getErrorCode(e)));
    }
  };

  return (
    <ApplicationLayout>
      <div className='flex justify-center items-center my-10 lg:mx-4 mx-0'>
        <div className='container'>
          <div className='text-center mx-2 lg:mx-0'>
            <p className='text-xl font-primary-medium'>
              INDEPENDENT CONTRACTOR AGREEMENT
            </p>
            <p className='text-gray-800'>
              Please Review and Sign the document below.
            </p>
          </div>
          {errorMessage && (
            <div className='my-5'>
              <Alert
                text={errorMessage}
                variant='error'
                icon={<FontAwesomeIcon icon={faInfoCircle} size='lg' />}
              />
            </div>
          )}
          <div className='bg-gray-100 flex justify-center lg:p-5 p-0 mt-10 mb-20 lg:mb-40'>
            <FormBasedOnType
              formType={getFormType(userDetail?.accountCountry!)}
              onSubmit={handleSubmit}
              application={application!}
            />
          </div>
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default ApplicationICASigning;
