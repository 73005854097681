import { getFormattedDate } from '../../../utils/DateUtils';

export interface MilliDateCellProps {
  date: number | undefined;
}

const MilliDateCell: React.FC<MilliDateCellProps> = ({ date }) => {
  return <span>{date ? getFormattedDate(date) : 'N/A'}</span>;
};

export default MilliDateCell;
