import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import IconButton from '../../IconButton';
import {
  RefundResponse,
  RefundResponseStatusEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import {
  AdminRoles,
  AnalyticsEventEnum,
  AppDispatch,
  RootState,
} from '../../../types';
import ZenFixedDataResourceIndexContainer from '../../Zen/Containers/ZenFixedDataResourceIndexContainer';
import {
  brokerApproveRefundRequest,
  cancelRefundRequest,
  fetchRefundsByTransactionId,
  releaseRefundRequest,
  restoreRefundRequest,
  revertRefundRequest,
} from '../../../slices/ReleaseFundsSlice';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenIconCircleWithTooltipCell from '../../Zen/Transaction/ZenIconCircleWithTooltipCell';
import ResourceContainer from '../../ResourceContainer';
import { numberToMoney } from '../../../utils/CurrencyUtils';
import ZenViewActionButtonCell from '../../table/Cells/ZenViewActionButtonCell';
import ExcludeAgent from '../../auth/ExcludeAgent';
import AdminOnly from '../../auth/AdminOnly';
import AnalyticsService from '../../../services/AnalyticsService';
import { capitalizeEnum } from '../../../utils/StringUtils';
import {
  isRefundRequestAbleToCancel,
  isRefundRequestAbleToRevert,
  isRefundRequestAbleToView,
  isRefundRequestEditable,
} from '../../../utils/RefundsUtils';
import {
  fetchBankAccounts,
  fetchMoneyTransfers,
} from '../../../slices/MoneyTransferSlice';
import { fetchTransactionDetails } from '../../../slices/TransactionSlice';
import { cn } from '../../../utils/classUtils';
import RefundStatusPill from './RefundStatusPill';
import AddTrustfund from './AddTrustfund';
import TrustFundMarkAsReviewModal from './TrustFundMarkAsReviewModal';
import ParticipantLabelComponent from './ParticipantLabelComponent';
import RefundActionButton from './RefundActionButton';

interface TrustFundSectionProps {
  transaction: TransactionResponse;
}

const TrustFundSection: React.FC<TrustFundSectionProps> = ({ transaction }) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    auth: { isAdmin, isBroker, keymakerCurrentUser },
    userIds: { agentById },
    releaseFunds: { refunds },
  } = useSelector((state: RootState) => state);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAddTrustFund, setIsAddTrustFund] = useState<boolean>(false);
  const [isEditTrustFund, setIsEditTrustFund] = useState<boolean>(false);
  const [isViewTrustFund, setIsViewTrustFund] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [refundIdToDelete, setRefundIdToDelete] = useState<string>('');
  const [refundIdToRevert, setRefundIdToRevert] = useState<string>('');
  const [isRevertModalOpen, setIsRevertModalOpen] = useState<boolean>(false);
  const [isReverting, setIsReverting] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [
    isMarkAsReviewedModalOpen,
    setIsMarkAsReviewedModalOpen,
  ] = useState<boolean>(false);
  const [
    selectedRefundRequest,
    setSelectedRefundRequest,
  ] = useState<RefundResponse>();

  const isAgent = !(isAdmin || isBroker);

  const isFinanceRoleAvailable = (keymakerCurrentUser?.activeRoles || [])?.some(
    (p) => p?.role === AdminRoles.FINANCE1 || p?.role === AdminRoles.FINANCE2,
  );

  const fetchRefunds = useCallback(async () => {
    setIsLoading(true);
    await dispatch(fetchRefundsByTransactionId(transaction?.id!));
    setIsLoading(false);
  }, [dispatch, transaction?.id]);

  useEffect(() => {
    fetchRefunds();
  }, [fetchRefunds]);

  const columns: Array<Column<RefundResponse>> = useMemo(
    () => [
      {
        Header: '',
        id: 'view',
        accessor: 'id',
        Cell: ({ row: { original } }) =>
          isRefundRequestAbleToView(original?.status!, isAgent) ? (
            <div className='py-2'>
              <ZenViewActionButtonCell
                onClick={() => {
                  setSelectedRefundRequest(original);
                  setIsViewTrustFund(true);
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.EXCESS_FUNDS_CLICKED_VIEW_REQUEST,
                    {
                      requestId: original?.id,
                      paymentType: original?.paymentType,
                    },
                  );
                }}
              />
            </div>
          ) : null,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Date Submitted',
        accessor: 'createdAt',
        Cell: ({ value }) => (
          <p
            className={cn(
              'font-zen-body text-sm font-medium py-2',
              value ? 'text-zen-dark-9' : 'text-zen-dark-5',
            )}
          >
            {value ? DateTime.fromMillis(value).toFormat('LL/dd/yy') : 'N/A'}
          </p>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Payable to',
        accessor: 'payableTo',
        Cell: ({ value }) => {
          return (
            <p
              className={cn(
                'font-zen-body text-sm font-medium py-2',
                value ? 'text-zen-dark-9' : 'text-zen-dark-5',
              )}
            >
              {value || 'N/A'}
            </p>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Amount',
        accessor: 'refundAmount',
        Cell: ({ value }) => (
          <p
            className={cn(
              'font-zen-body text-sm font-medium py-2 w-max',
              value ? 'text-zen-dark-9' : 'text-zen-dark-5',
            )}
          >
            {value
              ? `${value?.currency} ${numberToMoney(value?.amount!, {
                  includeCurrencySymbol: false,
                })}`
              : 'N/A'}
          </p>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <div className='py-2'>
            <RefundStatusPill status={value!} />
          </div>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Status Update by',
        accessor: 'updatedBy',
        Cell: ({ value }) => {
          if (!value || !agentById[value!]) {
            return (
              <p className='font-zen-body text-sm font-medium text-zen-dark-5 py-2'>
                N/A
              </p>
            );
          }

          return (
            <div className='py-2'>
              <ParticipantLabelComponent
                participant={agentById[value!]!}
                showParticipantRole={false}
              />
            </div>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Status Updated on',
        accessor: 'updatedAt',
        Cell: ({ value }) => {
          return (
            <p
              className={cn(
                'font-zen-body text-sm font-medium py-2',
                value ? 'text-zen-dark-9' : 'text-zen-dark-5',
              )}
            >
              {value ? DateTime.fromMillis(value).toFormat('LL/dd/yy') : 'N/A'}
            </p>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Source',
        accessor: 'fundingSource',
        Cell: ({ value }) => {
          return (
            <p
              className={cn(
                'font-zen-body text-sm font-medium py-2',
                value ? 'text-zen-dark-9' : 'text-zen-dark-5',
              )}
            >
              {value ? capitalizeEnum(value) : 'N/A'}
            </p>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: 'id',
        Cell: ({ row }) => (
          <div className='flex flex-row justify-start items-center space-x-3 py-2'>
            {row.original.status === RefundResponseStatusEnum.Complete && (
              <RefundActionButton
                variant='secondary-gray-text-outline'
                label='Funds Released'
                isDisabled
              />
            )}
            <AdminOnly>
              {row.original.status === RefundResponseStatusEnum.Cancelled && (
                <RefundActionButton
                  label='Resubmit'
                  onClick={async () => {
                    await dispatch(
                      restoreRefundRequest(
                        transaction?.id!,
                        row?.original?.id!,
                      ),
                    );
                    AnalyticsService.instance().logEvent(
                      AnalyticsEventEnum.EXCESS_FUNDS_CLICKED_RESUBMIT_REQUEST,
                      {
                        requestId: row.original?.id,
                        paymentType: row.original?.paymentType,
                      },
                    );
                  }}
                />
              )}
              {row.original.status === RefundResponseStatusEnum.Submitted && (
                <RefundActionButton
                  label='Mark as Reviewed'
                  onClick={async () => {
                    setSelectedRefundRequest(row?.original);
                    setIsMarkAsReviewedModalOpen(true);
                  }}
                />
              )}
              {row.original.status ===
                RefundResponseStatusEnum.ReadyForRelease && (
                <RefundActionButton
                  label='Release'
                  onClick={async () => {
                    await dispatch(
                      releaseRefundRequest(
                        transaction?.id!,
                        row?.original?.id!,
                      ),
                    );
                    await dispatch(fetchMoneyTransfers(transaction?.id!));
                    await dispatch(fetchBankAccounts(transaction?.id!));
                    await dispatch(
                      fetchTransactionDetails(transaction?.id!, false),
                    );
                    AnalyticsService.instance().logEvent(
                      AnalyticsEventEnum.EXCESS_FUNDS_CLICKED_RELEASE_REQUEST,
                      {
                        requestId: row.original?.id,
                        paymentType: row.original?.paymentType,
                      },
                    );
                  }}
                />
              )}
            </AdminOnly>

            <ExcludeAgent>
              {row.original.status ===
                RefundResponseStatusEnum.PendingApproval && (
                <RefundActionButton
                  label='Approve'
                  onClick={async () => {
                    await dispatch(
                      brokerApproveRefundRequest(
                        transaction?.id!,
                        row?.original?.id!,
                      ),
                    );
                    AnalyticsService.instance().logEvent(
                      AnalyticsEventEnum.EXCESS_FUNDS_CLICKED_APPROVE_REQUEST,
                      {
                        requestId: row.original?.id,
                        paymentType: row.original?.paymentType,
                      },
                    );
                  }}
                />
              )}
            </ExcludeAgent>

            {isRefundRequestEditable(
              row.original?.status!,
              isAdmin,
              isBroker,
            ) && (
              <ZenIconCircleWithTooltipCell
                tooltipText='Edit'
                icon={
                  <FontAwesomeIcon
                    icon={regular('pencil-alt')}
                    title='edit'
                    className='text-zen-dark-7'
                  />
                }
                onClick={() => {
                  setSelectedRefundRequest(row?.original);
                  setIsEditTrustFund(true);
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.EXCESS_FUNDS_CLICKED_EDIT_REQUEST,
                    {
                      requestId: row?.original?.id,
                      paymentType: row.original?.paymentType,
                    },
                  );
                }}
              />
            )}
            {isRefundRequestAbleToCancel(
              row?.original?.status!,
              isAgent,
              isAdmin,
              isBroker,
            ) && (
              <ZenIconCircleWithTooltipCell
                tooltipText='Cancel'
                icon={
                  <FontAwesomeIcon
                    icon={regular('cancel')}
                    title='cancel'
                    className='text-zen-danger'
                  />
                }
                onClick={() => {
                  setRefundIdToDelete(row?.id);
                  setIsDeleteModalOpen(true);
                }}
              />
            )}
            {isRefundRequestAbleToRevert(
              row?.original?.status!,
              isFinanceRoleAvailable,
            ) && (
              <RefundActionButton
                variant='danger'
                label='Cancel'
                onClick={() => {
                  setRefundIdToRevert(row?.original?.id!);
                  setIsRevertModalOpen(true);
                }}
              />
            )}
          </div>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
    ],

    [
      agentById,
      dispatch,
      isAdmin,
      isAgent,
      isBroker,
      isFinanceRoleAvailable,
      transaction?.id,
    ],
  );

  return (
    <div className='w-full p-4'>
      <div className='flex flex-col md:flex-row flex-wrap md:justify-between md:items-center space-y-3 md:space-y-0 py-3'>
        <p className='font-semibold text-xl text-zen-dark-9'>
          Release of Funds
        </p>
        <div>
          <IconButton
            label='Request Release'
            buttonStyle={cn(
              '!ring-1 !border-1 px-2 py-1',
              'hover:bg-zen-primary/10 hover:ring-zen-primary/10 !hover:border-primary-blue',
            )}
            textStyle='!text-primary-blue'
            variant='no-outline'
            leftIcon={
              <FontAwesomeIcon
                icon={solid('plus')}
                className='text-primary-blue mb-1'
              />
            }
            onClick={() => {
              setIsAddTrustFund(true);
              AnalyticsService.instance().logEvent(
                AnalyticsEventEnum.EXCESS_FUNDS_CLICKED_ADD_REQUEST,
              );
            }}
          />
        </div>
      </div>
      <ResourceContainer
        loading={isLoading}
        resourceName='release of trust fund'
        isEmpty={false}
        errorCode={refunds?.error}
      >
        <div className='table-align-top'>
          <ZenFixedDataResourceIndexContainer<RefundResponse>
            columns={columns}
            data={refunds.data || []}
            initialSort={{ createdAt: 'desc' }}
            resourceName='release of trust fund'
            hidePagination
            hideFilters
            hidePageSize
          />
        </div>
      </ResourceContainer>
      {(isAddTrustFund || isEditTrustFund || isViewTrustFund) && (
        <AddTrustfund
          isOpen
          onEdit={(request) => {
            setSelectedRefundRequest(request);
            setIsEditTrustFund(true);
          }}
          onClose={() => {
            setIsAddTrustFund(false);
            setIsEditTrustFund(false);
            setIsViewTrustFund(false);
            setSelectedRefundRequest(undefined);
          }}
          transaction={transaction}
          refundRequest={selectedRefundRequest}
          readonly={isViewTrustFund}
        />
      )}
      {isMarkAsReviewedModalOpen && !!selectedRefundRequest && (
        <TrustFundMarkAsReviewModal
          isOpen
          onClose={() => {
            setIsMarkAsReviewedModalOpen(false);
            setSelectedRefundRequest(undefined);
          }}
          transaction={transaction}
          refundRequest={selectedRefundRequest}
        />
      )}
      {isDeleteModalOpen && (
        <ZenConfirmationModal
          isOpen
          variant='danger'
          title='Are you sure you want to cancel this refund?'
          confirmButtonText='Cancel'
          cancelButtonText='No'
          hideIcon
          subtitle='Canceling this refund will remove it from the transaction.'
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={async () => {
            setIsDeleting(true);
            await dispatch(
              cancelRefundRequest(
                refundIdToDelete,
                transaction?.id!,
                false,
                isAgent,
              ),
            );
            setIsDeleting(false);
            setIsDeleteModalOpen(false);
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.EXCESS_FUNDS_CLICKED_CANCEL_REQUEST,
              { requestId: refundIdToDelete },
            );
          }}
          isSubmitting={isDeleting}
          isDisabled={isDeleting}
        />
      )}
      {isRevertModalOpen && (
        <ZenConfirmationModal
          isOpen
          variant='danger'
          title='Are you Sure?'
          confirmButtonText='Yes'
          cancelButtonText='No'
          hideIcon
          subtitle='This action will undo the approved release of funds, please ensure payment has not been made or is received prior to canceling this release of funds'
          onClose={() => setIsRevertModalOpen(false)}
          onConfirm={async () => {
            setIsReverting(true);
            await dispatch(
              revertRefundRequest(transaction?.id!, refundIdToRevert),
            );
            await dispatch(fetchMoneyTransfers(transaction?.id!));
            await dispatch(fetchBankAccounts(transaction?.id!));
            await dispatch(fetchTransactionDetails(transaction?.id!, false));
            setIsReverting(false);
            setIsRevertModalOpen(false);
          }}
          isSubmitting={isReverting}
          isDisabled={isReverting}
        />
      )}
    </div>
  );
};

export default TrustFundSection;
