import { Config, usePopperTooltip } from 'react-popper-tooltip';

export interface PopperProps {
  popperComponent: React.ReactElement;
  config?: Config;
}

const Popper: React.FC<PopperProps> = ({
  children,
  popperComponent,
  config = {},
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(config);

  return (
    <div ref={setTriggerRef}>
      <div>
        {visible && (
          <div
            ref={setTooltipRef}
            {...getTooltipProps({ className: 'tooltip' })}
          >
            <div
              {...getArrowProps({
                className: 't-arrow',
              })}
            />
            {popperComponent}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default Popper;
