import { Route, Switch } from 'react-router-dom';
import Route401 from './Route401';
import Route500 from './Route500';
import Route404 from './Route404';

interface ErrorRoutesProps {}

const ErrorRoutes: React.FC<ErrorRoutesProps> = () => {
  return (
    <Switch>
      <Route path='/401' component={Route401} exact />
      <Route path='/500' component={Route500} exact />
      <Route component={Route404} exact />
    </Switch>
  );
};

export default ErrorRoutes;
