import { faMessage } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import GeminiButton from '../../../components/Gemini/GeminiButton';

interface ReferralSidebarFooterActionsProps {
  onClose: () => void;
  setSelectedTab: (tab: string) => void;
}

const ReferralSidebarFooterActions: React.FC<ReferralSidebarFooterActionsProps> = ({
  onClose,
  setSelectedTab,
}) => {
  return (
    <div className='py-6 px-5 bg-white border-t border-gray-200 flex flex-row justify-between items-center w-full gap-4 absolute bottom-0'>
      <GeminiButton
        type='button'
        onClick={onClose}
        label='Back'
        variant='primary-link'
      />
      <div className='w-2/6'>
        <GeminiButton
          leftIcon={faMessage}
          label='Message'
          variant='primary-outline'
          isFullWidth
          onClick={() => {
            setSelectedTab('Messages');
          }}
        />
      </div>
    </div>
  );
};

export default ReferralSidebarFooterActions;
