import { useForm } from 'react-hook-form-v7';
import ConfirmationModal from '../ConfirmationModal';
import { FileResponse } from '../../openapi/dropbox';
import ControlledTextInputV7 from '../ControlledTextInputV7';
import Button from '../Button';

interface FormData {
  name: string;
}

interface RenameFileModalProps {
  onClose(): void;
  file: FileResponse;
  isRenaming: boolean;
  onRename(data: FormData): void;
}

const RenameFileModal: React.FC<RenameFileModalProps> = ({
  onClose,
  file,
  isRenaming,
  onRename,
}) => {
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: { name: file.filename },
  });

  return (
    <ConfirmationModal
      isOpen
      onClose={onClose}
      variant='info'
      title='Rename File'
    >
      <p className='text-zen-dark-6 mb-3'>
        Rename from: &apos;
        <span className='text-zen-dark-9'>{file.filename}</span>&apos; to:
      </p>
      <ControlledTextInputV7<FormData, 'name'>
        name='name'
        control={control}
        rules={{
          required: 'Required',
          validate: (val) =>
            !val?.trim() ? 'Please enter a valid document name' : undefined,
        }}
        placeholder='E.g. new file name'
      />
      <div className='grid grid-cols-2 gap-4 mt-4'>
        <Button
          label='Cancel'
          type='secondary'
          size='lg'
          onClick={onClose}
          fullWidth
        />
        <Button
          label='Save'
          type='primary'
          size='lg'
          isSubmitting={isRenaming}
          onClick={handleSubmit(onRename)}
          fullWidth
        />
      </div>
    </ConfirmationModal>
  );
};

export default RenameFileModal;
