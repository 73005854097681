import React from 'react';
import ZenCheckListAndJourneyTemplates from '../Engineering/ZenCheckListAndJourneyTemplates';

interface ZenOperationsComponentProps {}

const ZenOperationsComponent: React.FC<ZenOperationsComponentProps> = () => {
  return (
    <div className='grid grid-cols-1 grid-flow-row md:grid-cols-6 gap-4 p-4'>
      <ZenCheckListAndJourneyTemplates />
    </div>
  );
};

export default ZenOperationsComponent;
