import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_SHOWN_ITEMS_COUNT } from '../../../../constants/LeoConstants';
import { saveSelectedItems } from '../../../../slices/LeoSlice';
import {
  TransactionItem,
  FollowUpItem,
  LeoResponseMessageContext,
} from '../../../../utils/LeoUtils';
import LeoResponseItemCard from './LeoResponseItemCard';

interface ContextItemsProps {
  msgContext: LeoResponseMessageContext | undefined;
  items: TransactionItem[];
  setItems: React.Dispatch<React.SetStateAction<TransactionItem[]>>;
  isShowingHistory: boolean;
}

export const ContextItems: React.FC<ContextItemsProps> = ({
  msgContext,
  items,
  setItems,
  isShowingHistory,
}) => {
  const dispatch = useDispatch();
  const [shownItemsLength, setShownItemsLength] = React.useState<number>(
    DEFAULT_SHOWN_ITEMS_COUNT,
  );
  const isShowingMore = useMemo(
    () => shownItemsLength > DEFAULT_SHOWN_ITEMS_COUNT,
    [shownItemsLength],
  );

  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const isShowingAllItemsByDefault = useMemo(
    () => items.length <= DEFAULT_SHOWN_ITEMS_COUNT,
    [items.length],
  );

  const msgContextType = msgContext?.details[0]?.type;

  const onItemSelect = (id: string) => {
    if (!id) {
      return;
    }
    const selectedItems: FollowUpItem[] = [];
    setItems(
      items.map((item) => {
        const isSelected = item.id === id ? !item.isSelected : item.isSelected;
        if (isSelected) {
          selectedItems.push({ id: item.id! });
        }
        return { ...item, isSelected };
      }),
    );
    dispatch(saveSelectedItems(selectedItems));
  };

  const markAllItemsSelectionAs = (
    selectAll: boolean,
    arr?: TransactionItem[],
  ) => {
    const selectedItems: FollowUpItem[] = [];
    setItems(
      arr?.map((item) => {
        if (selectAll) {
          selectedItems.push({ id: item.id! });
        }
        return {
          ...item,
          isSelected: selectAll,
        };
      }) || [],
    );
    setIsAllSelected(selectAll);
    dispatch(saveSelectedItems(selectedItems));
  };

  useEffect(() => {
    if (msgContext?.details[0]?.items) {
      markAllItemsSelectionAs(
        false,
        msgContext?.details[0]?.items.filter(
          (el) => !!el && !!Object.keys(el).length,
        ),
      );
    } else {
      setItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msgContext]);

  useEffect(() => {
    setShownItemsLength(
      items.length >= DEFAULT_SHOWN_ITEMS_COUNT
        ? DEFAULT_SHOWN_ITEMS_COUNT
        : items.length,
    );
  }, [items.length]);

  if (!items.length) {
    return null;
  }

  return (
    <div className='w-full pb-2'>
      <p className='text-white font-zen-body-medium text-base my-2 mx-1'>
        {msgContext?.details[0]?.message}
      </p>
      <div
        className={classNames(
          'w-full grid gap-3 sm:grid-cols-1',
          isShowingHistory ? 'lg:grid-cols-2' : 'lg:grid-cols-3 md:grid-cols-2',
        )}
      >
        {items.slice(0, shownItemsLength).map((item) => (
          <LeoResponseItemCard
            key={item.id}
            {...item}
            msgContextType={msgContextType}
            onSelect={onItemSelect}
          />
        ))}
      </div>
      <div className='flex flex-row justify-between mt-3'>
        <div className='flex flex-row items-center px-1'>
          <p className='text-white font-zen-body-medium text-sm'>
            {`${shownItemsLength} of ${items.length}`}
          </p>
          <p className='text-white font-zen-body-medium text-gray-400 text-sm mx-2 mb-0.5'>
            |
          </p>
          <button
            type='button'
            onClick={() => markAllItemsSelectionAs(!isAllSelected, items)}
          >
            <p className='text-white font-zen-body-medium text-[#80CDFF] text-sm'>
              {isAllSelected ? 'Unselect All' : 'Select All'}
            </p>
          </button>
        </div>
        <button
          type='button'
          className={classNames(
            'flex flex-row items-center',
            isShowingAllItemsByDefault ? 'opacity-50' : 'opacity-100',
          )}
          onClick={() =>
            setShownItemsLength(
              isShowingMore ? DEFAULT_SHOWN_ITEMS_COUNT : items.length,
            )
          }
          disabled={isShowingAllItemsByDefault}
        >
          {!isShowingAllItemsByDefault && (
            <FontAwesomeIcon
              className='text-[#80CDFF] mr-1.5 mb-0.5'
              icon={isShowingMore ? faAngleUp : faAngleDown}
              size='sm'
            />
          )}
          <p className='text-white font-zen-body-medium text-[#80CDFF] text-sm'>
            {isShowingAllItemsByDefault
              ? 'Showing All'
              : isShowingMore
              ? 'Show Less'
              : 'Show More'}
          </p>
        </button>
      </div>
    </div>
  );
};
