export const JV_EXPLANATION_VIDEO_LINK =
  'https://player.vimeo.com/video/830194434?h=edeea70837&title=0&byline=0&portrait=0';

export const ONE_REAL_TITLE_VIDEO_LINK =
  'https://drive.google.com/file/d/1VMHM6FextocBSoLd_SN1FiTTHj9QW_3b/preview';

export const BOOK_A_MEETING = 'https://calendly.com/taylor-conley-2';

export const REAL_TITLE_LEARN_MORE_VIDEO_LINK =
  'https://www.loom.com/embed/7d81ca92ab0b42c2b822f1c211ab3e6e?sid=c3731c31-6d32-4696-8996-6dcc0b741d2b';

export const REAL_TITLE_QUOTE = 'https://therealtitle.titlecapture.com/';

export const SEND_TRANSACTION_TO_REAL_TITLE_VIDEO_LINK =
  'https://www.loom.com/embed/b812e49110434b16967ff009fc8ebb07?sid=68f1fb35-b88e-4aed-b320-33e8c458d354';

export const CHECK_THE_MAP_LINK = 'https://www.onerealtitle.com/';

export const CHECK_THE_MAP_LINK_ESCROW = 'https://www.onerealescrow.com/';

export const REAL_TITLE_CONTRACT_EMAIL = 'neworders@therealtitle.com';

export const SUBMIT_ORDER_LINK = 'https://form.jotform.com/240645437483158';

export const ONE_REAL_TITLE_MARKETING_RESOURCES_URL =
  'https://onereal.widencollective.com/portals/n3lzclc7/OneRealTitle';

export const ONE_REAL_TITLE_WORKPLACE_URL =
  'https://realbroker.workplace.com/groups/604949615053930';

export const ONE_REAL_ESCROW_WORKPLACE_URL =
  'https://realbroker.workplace.com/groups/286368557415687';

export const REQUEST_EVENT_SUPPORT_URL = 'https://rebrand.ly/ortevent';

export const RESOURCES = [
  'Title Quotes',
  'Loan Estimate Quote',
  'Closing Disclosure Quote',
  'Seller Net Sheet',
  'Sell to Net',
  "Seller's Multiple Offers",
  'Buyer Estimate',
  'Monthly Affordability',
  'Rent Vs Buy Estimate',
  'ALTA Settlement Statement',
];
