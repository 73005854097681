import { useEffect } from 'react';

export const extractPdfFileName = (url: string): string => {
  const regex = /\/([^\/]+\.pdf)/;
  const match = url.match(regex);
  return match ? match[1] : 'w9.pdf';
};

export const fetchPdfAndConvertToFile = async (signedUrl: string) => {
  try {
    const response = await fetch(signedUrl);
    if (!response.ok) throw new Error('Network response was not ok.');

    const blob = await response.blob();
    const fileName = extractPdfFileName(signedUrl);
    const pdfFile = new File([blob], fileName, { type: 'application/pdf' });

    return pdfFile;
  } catch (error) {
    console.error('Error fetching or converting the PDF:', error);
    return null;
  }
};

export const useFetchPdfAndSetForm = (
  signedUrl?: string,
  callback?: (file: File | null) => void,
) => {
  useEffect(() => {
    // Fetch the W9 form and convert it to a File object
    // Set the file object to the form field via the callback
    // Ensure your callback function is memoized/stable:
    // Example:
    // const setPdfFile = useCallback((file: File | null) => {
    //   // Logic to handle the file, e.g., using setValue from react-hook-form
    //   if (file) setValue('pdfFieldName', file);
    // }, [setValue]);

    if (signedUrl) {
      fetchPdfAndConvertToFile(signedUrl).then((file) => {
        if (file) {
          callback?.(file);
        }
      });
    }
  }, [signedUrl, callback]);
};
