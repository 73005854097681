import { useState } from 'react';
import PageLayout from '../components/PageLayout';
import ZenFinanceComponent from '../components/Zen/Finance/ZenFinanceComponent';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenPasswordGuard from './ZenPasswordGuard';

export const FINANCE_PASSWORD = 'workhardbekind';

interface FinanceRouteProps {}

const FinanceRoute: React.FC<FinanceRouteProps> = () => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  return (
    <ZenRoute title='Finance'>
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Finance', url: '/finance' },
        ]}
      >
        {!authenticated ? (
          <ZenPasswordGuard
            password={FINANCE_PASSWORD}
            setAuthenticated={setAuthenticated}
          />
        ) : (
          <ZenFinanceComponent />
        )}
      </PageLayout>
    </ZenRoute>
  );
};

export default FinanceRoute;
