import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import {
  faCheck,
  faClose,
  faCopy,
  faPencil,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faPersonWalkingArrowRight,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, RouteComponentProps } from 'react-router-dom';
import { CellProps, Column, Row } from 'react-table';
import ZenCapStatusTracker from '../components/Agent/ZenCapStatusTracker';
import DetailPageLoader from '../components/DetailPageLoader';
import FeatureFlagEnabledOnly from '../components/FeatureFlagEnabledOnly';
import GeminiProTeamDetailHeaderSection from '../components/Gemini/Teams/ProTeams/GeminiProTeamDetailHeaderSection';
import Hover from '../components/Hover';
import IconButton from '../components/IconButton';
import ResourceContainer from '../components/ResourceContainer';
import ZenTeamStatusCell from '../components/table/Cells/ZenTeamStatusCell';
import ZenViewActionButtonCell from '../components/table/Cells/ZenViewActionButtonCell';
import NumberColumnFilter from '../components/table/Filters/NumberColumnFilter';
import TeamInvitedMemberStatusColumnFilter from '../components/table/Filters/TeamInvitedMemberStatusColumnFilter';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import TableLoader from '../components/TableLoader';
import ZenInvitedMemberStatusCell from '../components/Zen/Table/Cell/ZenInvitedMemberStatusCell';
import ZenTeamAgentNameCell from '../components/Zen/Table/Cell/ZenTeamAgentNameCell';
import ZenFixedDataTable from '../components/Zen/Table/ZenFixedDataTable';
import ZenInviteAgentSidebarModal from '../components/Zen/Teams/InviteAgent/ZenInviteAgentSidebarModal';
import ZenAddProTeamMember from '../components/Zen/Teams/ProTeams/ZenAddProTeamMember';
import ZenEditProTeamForm from '../components/Zen/Teams/ProTeams/ZenEditProTeamForm';
import ZenEditProTeamMember from '../components/Zen/Teams/ProTeams/ZenEditProTeamMember';
import ZenProTeamDetailHeaderSection from '../components/Zen/Teams/ProTeams/ZenProTeamDetailHeaderSection';
import ZenTransferProTeamAgent from '../components/Zen/Teams/ProTeams/ZenTransferProTeamAgent';
import ZenRevokeInvitation from '../components/Zen/Teams/ZenRevokeInvitationModal';
import ZenAvatar from '../components/Zen/ZenAvatar';
import ZenButton from '../components/Zen/ZenButton';
import ZenIconText from '../components/Zen/ZenIconText';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import useAgentsInfo from '../hooks/useAgentsInfo';
import useCopyToClipboard from '../hooks/useCopyToClipboard';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import {
  AgentCapInfoResponse,
  MoneyValueCurrencyEnum,
} from '../openapi/arrakis';
import {
  AgentInfo,
  AgentInfoStatusEnum,
  AgentResponse,
  DivisionResponse,
  FlexTeamConfigDtoTeamTypeEnum,
  FlexTeamDtoStatusEnum,
  FlexTeamLeaderDto,
  FlexTeammateDto,
  TeamInvitationSimplifiedDtoInvitationStatusEnum,
  TeamMemberResponseRolesEnum,
} from '../openapi/yenta';
import AnalyticsService from '../services/AnalyticsService';
import { fetchAgentTeamCapInfo } from '../slices/AgentSlice';
import { getFlexTeamDetailOverview } from '../slices/TeamSlice';
import {
  AnalyticsEventEnum,
  AppDispatch,
  FeatureFlagTypeEnum,
  FlexTeamRoles,
  RootState,
  TeamAgentsInviteTableDataType,
} from '../types';
import { getFullName } from '../utils/AgentHelper';
import { getTeamVisibility } from '../utils/ProTeams/ProTeamsHelper';
import { capitalizeEnum, formatPhoneNumber } from '../utils/StringUtils';
import { getPriorityWiseSortedData } from '../utils/TableUtils';
import {
  getPersonalizedEmailInvitationLink,
  getTeamCountry,
  getTeamMembersTotalCount,
  isInvitationModificationAllowed,
} from '../utils/TeamHelper';
import { getRealCapOptionsForTeam } from '../utils/teams/capUtils';

interface Match {
  id: string;
}

export interface LeaderDto {
  agent: AgentResponse;
  leaderDetails?: FlexTeamLeaderDto;
  roles: FlexTeamRoles[];
  memberId?: string;
}

export interface TeammateDto {
  agent: AgentResponse;
  teammateDetails?: FlexTeammateDto;
  roles: FlexTeamRoles[];
  memberId?: string;
}

interface TeamAgentsTableDataType {
  agent?: AgentResponse | AgentInfo;
  memberCommissionSplit?: number;
  status?:
    | TeamInvitationSimplifiedDtoInvitationStatusEnum
    | AgentInfoStatusEnum;
  email?: string;
  invitation?: TeamAgentsInviteTableDataType;
  roles?: FlexTeamRoles[];
  memberId?: string;
}

interface ZenTeamsProDetailRouteProps extends RouteComponentProps<Match> {}

const teamRolePriority = [
  TeamMemberResponseRolesEnum.Leader,
  TeamMemberResponseRolesEnum.Admin,
  TeamMemberResponseRolesEnum.Member,
];

export const columnWithLink: Array<Column<TeamAgentsTableDataType>> = [
  {
    Header: 'Name',
    accessor: 'agent',
    id: 'name',
    Cell: ({ row }) =>
      row.original.invitation ? (
        <div className='font-zen-body text-sm items-center w-max gap-2 bg-zen-light-blue px-2 py-1.5 rounded-full inline-flex'>
          <ZenAvatar name={getFullName(row.original.invitation!)!} size='xs' />
          {getFullName(row.original.invitation!)}
        </div>
      ) : (
        <Link
          to={`/people/${row.original.agent?.id}`}
          data-testid={row.original.agent?.avatar}
        >
          <ZenTeamAgentNameCell
            agent={row.original?.agent!}
            role={row.original?.roles![0]}
          />
        </Link>
      ),
    Filter: TextColumnFilter,
    cardColSize: 12,
    disableFilters: true,
    sortType: (row1, row2) => {
      const fullName1 = getFullName(
        row1.original.agent ?? row1.original.invitation,
      )!;
      const fullName2 = getFullName(
        row2.original.agent ?? row2.original.invitation,
      )!;

      return fullName1.localeCompare(fullName2);
    },
  },
];

export const columns: Array<Column<TeamAgentsTableDataType>> = [
  {
    Header: 'Status',
    accessor: 'status',
    id: 'status',
    Cell: ({ value }: any) =>
      value ? <ZenInvitedMemberStatusCell status={value!} /> : null,
    cardColSize: 6,
    Filter: TeamInvitedMemberStatusColumnFilter,
  },

  {
    Header: 'Fees Waived',
    accessor: 'agent',
    id: 'waiveFees',
    Cell: ({ row }) => (
      <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
        {/* N/A for team members and Yes || No for invited members */}
        {!row?.original?.invitation
          ? 'N/A'
          : row.original.invitation.waiveFees
          ? 'Yes'
          : 'No'}
      </div>
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },

  {
    Header: 'Division',
    accessor: (d) => d.agent,
    id: 'divisions',
    Cell: ({ value }: CellProps<DivisionResponse>) => {
      const division = value?.divisions?.[0];

      if (!division) {
        return (
          <FontAwesomeIcon icon={regular('dash')} className='text-center' />
        );
      }

      return (
        <ZenIconText
          icon={
            <img
              className='object-contain w-5 h-5'
              src={division.logoUrl}
              alt='agent-division'
            />
          }
          variant='small'
          text={division.name || '--'}
          textClassName='text-zen-dark-9 whitespace-pre'
        />
      );
    },
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Role',
    accessor: 'roles',
    Cell: ({ value }) => (
      <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
        {value!.map((val) => capitalizeEnum(val)).join(', ')}
      </div>
    ),
    cardColSize: 6,
    sortType: (row1, row2) =>
      getPriorityWiseSortedData(
        row1.values.roles[0],
        row2.values.roles[0],
        teamRolePriority,
      ),
    disableFilters: true,
  },
  {
    Header: 'Phone',
    accessor: 'agent',
    id: 'phoneNumber',
    Cell: ({ value }) => (
      <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
        {formatPhoneNumber(value?.phoneNumber) || 'N/A'}
      </div>
    ),
    Filter: TextColumnFilter,
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    id: 'emailAddress',
    Cell: ({ value }) => (
      <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
        {value}
      </div>
    ),
    Filter: TextColumnFilter,
    disableFilters: true,
    sortType: (row1, row2) =>
      row1.original.email!.localeCompare(row2.original.email!),
  },
];

const ZenTeamsProDetailRoute: React.FC<ZenTeamsProDetailRouteProps> = ({
  match,
  location,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const teamId = match.params.id;
  const {
    team: {
      loading,
      fetchErrorCode,
      flexTeamDetailOverview,
      teamDetailOverview,
    },
    auth: { isAdmin, userDetail },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  const [transferTeamAgent, setTransferTeamAgent] = useState<
    TeammateDto | LeaderDto | null
  >(null);
  const [openAddMemberForm, setOpenAddMemberForm] = useState<boolean>(false);
  const [openEditTeamForm, setOpenEditTeamForm] = useState<boolean>(false);
  const [loadingCapInfo, setLoadingCapInfo] = useState(false);
  const [capStatusById, setCapStatusById] = useState<
    Record<string, AgentCapInfoResponse>
  >({});

  const [revokedMember, setRevokedMember] = useState<any>(null);
  const [showInviteAgentSidebar, setShowInviteAgentSidebar] = useState(false);
  const isSelfServiceFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.SELF_SERVICE_TEAM,
  );

  const [
    editingTeamMember,
    setEditingTeamMember,
  ] = useState<null | TeammateDto>(null);
  const [
    viewingTeamMember,
    setViewingTeamMember,
  ] = useState<null | TeammateDto>(null);

  const visibilityPermission = getTeamVisibility(
    flexTeamDetailOverview!,
    userDetail?.id!,
    isAdmin,
  );

  const agentIds = useMemo(() => {
    let leaders: string[] = [];
    let members: string[] = [];
    flexTeamDetailOverview?.leaders?.map((leader) =>
      leaders.push(leader.agentId!),
    );

    flexTeamDetailOverview?.teammates?.map((agents) =>
      members.push(agents.agentId!),
    );

    return [...leaders!, ...members!];
  }, [flexTeamDetailOverview]);

  useAgentsInfo(agentIds);

  const leaders: TeamAgentsTableDataType[] = (
    flexTeamDetailOverview?.leaders || []
  ).map((leader) => {
    const userPreviewResponse = agentById[leader.agentId!];
    return {
      agent: userPreviewResponse!,
      leaderDetails: leader,
      memberCommissionSplit: leader.leaderSplit!,
      email: userPreviewResponse?.emailAddress,
      roles: [FlexTeamRoles.Leader],
      status:
        userPreviewResponse?.status === AgentInfoStatusEnum.Active
          ? TeamInvitationSimplifiedDtoInvitationStatusEnum.Approved
          : AgentInfoStatusEnum.Inactive,
      memberId: leader.id,
    };
  });

  const teamMembers: TeamAgentsTableDataType[] = (
    flexTeamDetailOverview?.teammates || []
  ).map((agents) => {
    const userPreviewResponse = agentById[agents.agentId!];
    const memberCommissionSplit =
      agents.leaderSplits?.length !== 0
        ? agents.leaderSplits?.reduce(
            (prev, curr) => prev + curr.leaderSplit,
            0,
          ) || 0
        : flexTeamDetailOverview?.leaders?.reduce(
            (prev, curr) => prev + curr?.leaderSplit!,
            0,
          ) || 0;

    return {
      agent: userPreviewResponse!,
      teammateDetails: agents,
      memberCommissionSplit: memberCommissionSplit,
      email: userPreviewResponse?.emailAddress,
      roles:
        agents.roles?.length! > 0
          ? ((agents.roles! as unknown) as FlexTeamRoles[])
          : [FlexTeamRoles.Teammate],
      status:
        userPreviewResponse?.status === AgentInfoStatusEnum.Active
          ? TeamInvitationSimplifiedDtoInvitationStatusEnum.Approved
          : AgentInfoStatusEnum.Inactive,
      memberId: agents.id,
    };
  });

  const invitationsViaEmail: TeamAgentsTableDataType[] = (
    flexTeamDetailOverview?.teamInvitations || []
  )
    // hide cancelled invitations
    .filter(
      (invitation) =>
        invitation.invitationStatus !==
        TeamInvitationSimplifiedDtoInvitationStatusEnum.Cancelled,
    )
    .map((invitation) => ({
      invitation: invitation as TeamAgentsInviteTableDataType,
      status: invitation?.invitationStatus!,
      email: invitation?.emailAddress,
      memberCommissionSplit: 0,
      roles: [FlexTeamRoles.Teammate],
      memberId: undefined,
    }));

  const invitationsViaGenericLink: TeamAgentsTableDataType[] = (
    flexTeamDetailOverview?.pendingGenericTeamApplications || []
  ).map((invitation) => ({
    invitation: {
      capLevel: invitation.capLevel,
      emailAddress: invitation.emailAddress,
      firstName: invitation.firstName,
      lastName: invitation.lastName,
      waiveFees: invitation.waiveFees,
      invitationPending: false,
      invitationStatus: invitation.status,
    },
    status: (invitation?.status! as unknown) as TeamInvitationSimplifiedDtoInvitationStatusEnum,
    email: invitation.emailAddress!,
    memberCommissionSplit: 0,
    roles: [FlexTeamRoles.Teammate],
  }));

  const agents = [...leaders, ...teamMembers];
  if (isSelfServiceFlagEnabled && visibilityPermission.canInviteMembers) {
    agents.unshift(...invitationsViaEmail, ...invitationsViaGenericLink);
  }

  const fetchTeamCapInfo = useCallback(
    async (loading: boolean) => {
      if (!visibilityPermission.showCapColumns) {
        return;
      }
      if (loading) {
        setLoadingCapInfo(true);
      }
      const agentCapInfo = await dispatch(fetchAgentTeamCapInfo(teamId));
      const data: Record<string, AgentCapInfoResponse> = {};
      agentCapInfo?.capInfo?.forEach((info) => {
        data[info?.agentId!] = info;
      });
      setCapStatusById(data);
      if (loading) {
        setLoadingCapInfo(false);
      }
    },
    [dispatch, teamId, visibilityPermission.showCapColumns],
  );

  const fetchTeamData = useCallback(async () => {
    await dispatch(getFlexTeamDetailOverview(teamId));
  }, [dispatch, teamId]);

  const onRefresh = useCallback(() => {
    fetchTeamData();
    fetchTeamCapInfo(false);
  }, [fetchTeamCapInfo, fetchTeamData]);

  useEffect(() => {
    fetchTeamData();
  }, [fetchTeamData]);

  useEffect(() => {
    fetchTeamCapInfo(true);
  }, [fetchTeamCapInfo]);

  const path = [
    { title: 'Home', url: '/' },
    { title: 'Teams', url: '/teams' },
  ];

  if (flexTeamDetailOverview) {
    path.push({
      title: `${flexTeamDetailOverview.name}`,
      url: `/teams/${flexTeamDetailOverview.id}`,
    });

    if (location.pathname.includes('transactions')) {
      path.push({
        title: 'Transactions',
        url: `/teams/${flexTeamDetailOverview.id}/transactions`,
      });
    }
  }

  const formatCapResetDate = (agentId: string) => {
    const date = capStatusById[agentId]?.anniversaryDate!;
    if (!date) {
      return 'N/A';
    }
    return DateTime.fromISO(date).toFormat('MM/dd/yyyy');
  };

  const capColumns: Array<
    Column<TeamAgentsTableDataType>
  > = visibilityPermission.showCapColumns
    ? [
        ...columns,
        {
          Header: 'CAP Progress',
          id: 'capProgress',
          Cell: ({
            row: { original },
          }: CellProps<TeamAgentsTableDataType, string>) => (
            <ZenCapStatusTracker
              capStatus={
                original.invitation
                  ? {
                      capAmountPaid: {
                        amount: 0,
                        currency: (userDetail?.defaultCurrency as unknown) as MoneyValueCurrencyEnum,
                      },
                      capAmount: {
                        amount: original.invitation.capLevel!,
                        currency: (userDetail?.defaultCurrency as unknown) as MoneyValueCurrencyEnum,
                      },
                    }
                  : capStatusById[original.agent?.id!]
              }
            />
          ),
          disableFilters: true,
        },
        {
          Header: 'Team CAP Progress',
          id: 'teamCapProgress',
          Cell: ({
            row: { original },
          }: CellProps<TeamAgentsTableDataType, string>) => (
            <ZenCapStatusTracker
              capStatus={capStatusById[original.agent?.id!]}
              isTeamLeader={leaders.some(
                (leader) => leader.agent?.id === original.agent?.id,
              )}
              isTeamCap
            />
          ),
          disableFilters: true,
        },
        {
          Header: 'CAP Reset Date',
          id: 'capResetDate',
          Cell: ({
            row: { original },
          }: CellProps<TeamAgentsTableDataType, string>) => (
            <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
              {formatCapResetDate(original.agent?.id!)}
            </div>
          ),
          disableFilters: true,
        },
      ]
    : columns;

  const showEditCommissionSplit = [
    FlexTeamConfigDtoTeamTypeEnum.Platinum,
    FlexTeamConfigDtoTeamTypeEnum.Normal,
    FlexTeamConfigDtoTeamTypeEnum.Group,
    FlexTeamConfigDtoTeamTypeEnum.Domestic,
    FlexTeamConfigDtoTeamTypeEnum.Pro,
  ].includes(flexTeamDetailOverview?.config?.teamType!);

  const columnsWithActions: Array<Column<TeamAgentsTableDataType>> = [
    ...columnWithLink,
    ...capColumns,
    {
      Header: 'Commissions (Agent/Leader)',
      accessor: 'memberCommissionSplit',
      Cell: ({ value, row }) => (
        <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
          {row.original?.invitation
            ? 'N/A'
            : `${100 - ((value as unknown) as number)}% / ${value}%`}
        </div>
      ),
      Filter: NumberColumnFilter,
      disableFilters: true,
    },
    {
      Header: '',
      id: 'transferTeamAgent',
      Cell: ({ row }: { row: Row<TeammateDto | LeaderDto> }) => (
        <>
          {!!row.original.memberId && isAdmin && (
            <Hover
              hoverComponent={
                <div className='font-zen-body font-normal text-sm text-zen-dark-9 px-2'>
                  Transfer Agent
                </div>
              }
              config={{ trigger: 'hover', placement: 'top' }}
            >
              <div className='flex flex-nowrap space-x-3'>
                <IconButton
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faPersonWalkingArrowRight}
                      title='Transfer'
                      className='text-zen-dark-8'
                    />
                  }
                  variant='none'
                  onClick={() => setTransferTeamAgent(row.original)}
                  buttonStyle='bg-zen-light-gray-2 hover:bg-zen-dark-4 flex justify-center item-center rounded-full cursor-pointer h-8 w-8'
                />
              </div>
            </Hover>
          )}
        </>
      ),
      disableSortBy: true,
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ row }: CellProps<TeamAgentsTableDataType, string>) => {
        const [copied, copyToClipboard] = useCopyToClipboard();

        return (
          <div className='flex flex-nowrap space-x-3'>
            {showEditCommissionSplit && !row?.original.invitation && (
              <Hover
                hoverComponent={
                  <div className='font-zen-body font-normal text-sm text-zen-dark-9 px-2'>
                    Edit
                  </div>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <IconButton
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faPencil}
                      title='Edit'
                      className='text-zen-dark-8'
                    />
                  }
                  variant='none'
                  onClick={() =>
                    setEditingTeamMember(row.original as TeammateDto)
                  }
                  buttonStyle='bg-zen-light-gray-2 hover:bg-zen-dark-4 flex justify-center item-center rounded-full cursor-pointer h-8 w-8'
                />
              </Hover>
            )}

            {isInvitationModificationAllowed(
              row?.original?.invitation?.invitationStatus!,
            ) && (
              <>
                <Hover
                  hoverComponent={
                    <div className='font-zen-body font-normal whitespace-nowrap text-sm text-zen-dark-9 px-2'>
                      {copied ? 'Copied!' : 'Copy Invitation Link'}
                    </div>
                  }
                  config={{ trigger: 'hover', placement: 'top' }}
                >
                  <IconButton
                    leftIcon={
                      <FontAwesomeIcon
                        icon={copied ? faCheck : faCopy}
                        className='text-lg text-zen-dark-8'
                        title='copy invitation link'
                      />
                    }
                    variant='none'
                    onClick={() => {
                      AnalyticsService.instance().logEvent(
                        AnalyticsEventEnum.SELF_SERVICE_TEAM_CLICK_COPY_INVITATION_LINK_BTN,
                      );

                      copyToClipboard(
                        getPersonalizedEmailInvitationLink(
                          row?.original?.invitation?.invitationId!,
                          row?.original?.invitation?.firstName!,
                          row?.original?.invitation?.lastName!,
                          row?.original?.invitation?.emailAddress!,
                          flexTeamDetailOverview?.name!,
                          row?.original?.invitation?.capLevel!,
                          getTeamCountry(teamDetailOverview),
                        ),
                      );
                    }}
                    buttonStyle='bg-zen-light-gray-2 hover:bg-zen-dark-4 flex justify-center item-center rounded-full cursor-pointer h-8 w-8'
                  />
                </Hover>
                <Hover
                  hoverComponent={
                    <div className='font-zen-body whitespace-nowrap font-normal text-sm text-zen-dark-9 px-2'>
                      Revoke Invitation
                    </div>
                  }
                  config={{ trigger: 'hover', placement: 'top' }}
                >
                  <IconButton
                    leftIcon={
                      <FontAwesomeIcon
                        icon={faClose}
                        title='Revoke Invitation'
                        className='text-lg text-zen-dark-8'
                      />
                    }
                    variant='none'
                    onClick={() => {
                      AnalyticsService.instance().logEvent(
                        AnalyticsEventEnum.SELF_SERVICE_TEAM_REVOKE_INVITATION_BTN,
                      );

                      setRevokedMember(row?.original?.invitation!);
                    }}
                    buttonStyle='bg-zen-light-gray-2 hover:bg-zen-dark-4 flex justify-center item-center rounded-full cursor-pointer h-8 w-8'
                  />
                </Hover>
              </>
            )}
          </div>
        );
      },
      disableSortBy: true,
    },
  ];

  const viewColumn: Array<Column<TeamAgentsTableDataType>> = [
    {
      Header: '',
      id: 'actions',
      Cell: ({ row }: { row: Row<TeamAgentsTableDataType> }) => {
        const teamMember = visibilityPermission.viewSelf
          ? userDetail?.id === row.original.agent?.id
          : false;

        if (teamMember || visibilityPermission.teamViewMember) {
          return (
            <div className='flex flex-nowrap space-x-3'>
              {showEditCommissionSplit && (
                <Hover
                  hoverComponent={
                    <div className='font-zen-body font-normal text-sm text-zen-dark-9 px-2'>
                      View
                    </div>
                  }
                  config={{ trigger: 'hover', placement: 'top' }}
                >
                  <ZenViewActionButtonCell
                    onClick={() =>
                      setViewingTeamMember(row.original as TeammateDto)
                    }
                  />
                </Hover>
              )}
            </div>
          );
        }

        return null;
      },
      disableSortBy: true,
    },
  ];

  const columnsToShow = visibilityPermission.teamEditMember
    ? columnsWithActions
    : [...viewColumn, ...columnWithLink, ...capColumns];

  const rightComponent = () => {
    return (
      <div className='space-x-2 flex items-center'>
        {(visibilityPermission.teamConfigEdit ||
          visibilityPermission.teamConfigView) && (
          <ZenButton
            label={
              visibilityPermission.teamConfigEdit ? 'Edit Team' : 'View Team'
            }
            type='button'
            variant='secondary-light-outline'
            LeftIconComponent={
              visibilityPermission.teamConfigEdit ? (
                <FontAwesomeIcon icon={faPencil} />
              ) : undefined
            }
            onClick={() => setOpenEditTeamForm(true)}
          />
        )}

        <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.SELF_SERVICE_TEAM}>
          {visibilityPermission.canInviteMembers && (
            <ZenButton
              label='Invite Agent'
              type='button'
              LeftIconComponent={
                <FontAwesomeIcon icon={faUser} size='sm' className='mr-1' />
              }
              onClick={() => {
                AnalyticsService.instance().logEvent(
                  AnalyticsEventEnum.SELF_SERVICE_TEAM_CLICK_INVITE_AGENT_BTN,
                );

                setShowInviteAgentSidebar(true);
              }}
              isDisabled={
                flexTeamDetailOverview?.status ===
                FlexTeamDtoStatusEnum.Inactive
              }
            />
          )}
        </FeatureFlagEnabledOnly>

        {visibilityPermission.teamAddOrRemoveMember && (
          <ZenButton
            label='Add New Member'
            type='button'
            variant='primary'
            LeftIconComponent={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => setOpenAddMemberForm(true)}
            isDisabled={
              flexTeamDetailOverview?.status === FlexTeamDtoStatusEnum.Inactive
            }
          />
        )}
      </div>
    );
  };

  const isEmpty = agents?.length === 0;

  const capsAllowed = flexTeamDetailOverview?.config?.allowedMemberCaps;

  const realCapOptions = getRealCapOptionsForTeam(capsAllowed);

  const geminiRedesignEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.GEMINI_REDESIGN,
  );

  const HeaderSection = geminiRedesignEnabled
    ? GeminiProTeamDetailHeaderSection
    : ZenProTeamDetailHeaderSection;

  const getHiddenColumns = () => {
    if (isSelfServiceFlagEnabled && visibilityPermission.canInviteMembers) {
      // if user is Team Leader || Team Admin || Real Admin and self service flag is enabled
      // hide waive fees and division if user is not real admin but is Team Leader || Team Admin
      if (!isAdmin) {
        return ['waiveFees', 'divisions'];
      }

      // hide divisions column if user is Team Admin || Team Leader || Real Admin
      return ['divisions'];
    }

    // hide status and waive fees if user is not Team Leader || Team Admin || Real Admin
    return ['status', 'waiveFees'];
  };

  const totalMemberCount = getTeamMembersTotalCount(
    true,
    isSelfServiceFlagEnabled && visibilityPermission.canInviteMembers,
    flexTeamDetailOverview!,
  );

  return (
    <ZenRoute title='Teams Details'>
      <ResourceContainer
        loading={loading || loadingCapInfo}
        isEmpty={!flexTeamDetailOverview}
        errorCode={fetchErrorCode}
        LoaderComponent={<DetailPageLoader />}
        resourceName='team detail'
      >
        <ZenPageLayout
          path={path}
          RightComponent={
            <div className='ml-auto'>
              <ZenTeamStatusCell
                status={flexTeamDetailOverview?.status!}
                isPill={false}
              />
            </div>
          }
        >
          {!!flexTeamDetailOverview && (
            <HeaderSection
              teamDetailOverview={flexTeamDetailOverview}
              totalMemberCount={totalMemberCount}
            />
          )}
          {isEmpty && (
            <div className='w-full flex justify-end px-6 pt-5'>
              {rightComponent()}
            </div>
          )}
          <ResourceContainer
            isEmpty={isEmpty}
            loading={loading}
            LoaderComponent={<TableLoader />}
            resourceName='team member'
          >
            <Route path={`${match.path}`} exact>
              <div className='px-6 pt-5 pb-10'>
                <ZenFixedDataTable<TeamAgentsTableDataType>
                  header='Agents'
                  columns={columnsToShow}
                  resourceName='Team Member'
                  data={agents}
                  hidePagination
                  hiddenColumns={getHiddenColumns()}
                  hideFilters={
                    !isSelfServiceFlagEnabled ||
                    !visibilityPermission.canInviteMembers
                  }
                  RightComponent={rightComponent()}
                  stickyHeader
                />
              </div>
            </Route>
          </ResourceContainer>

          {showInviteAgentSidebar && (
            <ZenInviteAgentSidebarModal
              isOpen={showInviteAgentSidebar}
              onClose={() => setShowInviteAgentSidebar(false)}
              options={realCapOptions}
              team={teamDetailOverview!}
              refetch={onRefresh}
            />
          )}

          {openAddMemberForm && !!flexTeamDetailOverview && (
            <ZenAddProTeamMember
              isOpen
              leaders={leaders as LeaderDto[]}
              team={flexTeamDetailOverview!}
              onClose={() => setOpenAddMemberForm(false)}
              teamId={flexTeamDetailOverview?.id!}
              teamType={flexTeamDetailOverview?.config?.teamType!}
              refetch={onRefresh}
            />
          )}

          {!!revokedMember && (
            <ZenRevokeInvitation
              memberToRevoke={revokedMember}
              onClose={() => {
                setRevokedMember(null);
              }}
              onConfirm={onRefresh}
            />
          )}

          {!!flexTeamDetailOverview && !!editingTeamMember && (
            <ZenEditProTeamMember
              team={flexTeamDetailOverview}
              teammate={editingTeamMember}
              leaders={leaders as LeaderDto[]}
              refetch={onRefresh}
              readOnly={!visibilityPermission.teamEditMember}
              removeMember={visibilityPermission?.teamAddOrRemoveMember}
              onClose={() => setEditingTeamMember(null)}
            />
          )}
          {!!flexTeamDetailOverview && !!viewingTeamMember && (
            <ZenEditProTeamMember
              team={flexTeamDetailOverview}
              teammate={viewingTeamMember}
              leaders={leaders as LeaderDto[]}
              refetch={onRefresh}
              readOnly={!visibilityPermission.teamEditMember}
              removeMember={visibilityPermission?.teamAddOrRemoveMember}
              onClose={() => setViewingTeamMember(null)}
              isViewing
            />
          )}
          {!!flexTeamDetailOverview && openEditTeamForm && (
            <ZenEditProTeamForm
              isOpen
              leaders={leaders as LeaderDto[]}
              onClose={() => setOpenEditTeamForm(false)}
              team={flexTeamDetailOverview}
              readOnly={!visibilityPermission.teamConfigEdit}
              teamDetailReadOnly={!visibilityPermission.teamDetailEdit}
            />
          )}

          {!!flexTeamDetailOverview && !!transferTeamAgent && (
            <ZenTransferProTeamAgent
              flexTeam={flexTeamDetailOverview}
              member={transferTeamAgent}
              onClose={() => setTransferTeamAgent(null)}
            />
          )}
        </ZenPageLayout>
      </ResourceContainer>
    </ZenRoute>
  );
};

export default ZenTeamsProDetailRoute;
