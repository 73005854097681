import { faRectangleHistoryCirclePlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { kebabCase } from 'lodash';
import React, { useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EmptyState from '../../../../assets/img/pdf-split-empty-state-image.svg';
import config from '../../../../config';
import { ChecklistResponse } from '../../../../openapi/sherlock';
import { RezenObjectTypeEnum } from '../../../../openapi/yada';
import AnalyticsService from '../../../../services/AnalyticsService';
import ErrorService from '../../../../services/ErrorService';
import {
  fetchChecklistItemById,
  uploadChecklistDocument,
  uploadToDropboxAndAddFileReferences,
} from '../../../../slices/CheckListSlice';
import { showApiErrorModal } from '../../../../slices/ErrorSlice';
import { showErrorToastForErrorCode } from '../../../../slices/ToastNotificationSlice';
import {
  AnalyticsEventEnum,
  AppDispatch,
  ImageContent,
  RootState,
} from '../../../../types';
import { isV2Checklist } from '../../../../utils/ChecklistUtils';
import ZenControlledSelectInput from '../../Input/ZenControlledSelectInput';
import ZenSimpleConfirmationModal from '../../Modal/ZenSimpleConfirmationModal';
import ZenChecklistLabel from '../../Transaction/ZenChecklistLabel';
import ZenButton from '../../ZenButton';
import RenderPDF from './RenderPDF';
import { SplitPDFFormData } from './ZenSplitAndAttachPDF';

interface ZenSplitPdfPreviewAndAssignProps {
  form: UseFormReturn<SplitPDFFormData>;
  pdfUrl: string;
  selectedPages: ImageContent[];
  setSelectedPages(pages: ImageContent[]): void;
  checklist: ChecklistResponse;
  containerId: string;
  containerType: RezenObjectTypeEnum;
  backUrl?: string;
  dropboxId: string;
}

const ZenSplitPdfPreviewAndAssign: React.FC<ZenSplitPdfPreviewAndAssignProps> = ({
  selectedPages,
  pdfUrl,
  form: {
    control,
    setValue,
    formState: { isSubmitting },
    handleSubmit,
  },
  setSelectedPages,
  checklist,
  containerId,
  backUrl,
  dropboxId,
}) => {
  const [isAssigning, setIsAssigning] = useState<boolean>(false);
  const [isShowingSuccess, setIsShowingSuccess] = useState<boolean>(false);
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  const isChecklistV2 = isV2Checklist(checklist);

  const filteredChecklistItems = useMemo(() => {
    const checklistData = (checklist?.items || [])?.filter(
      (item) => !item?.hidden,
    );

    return checklistData;
  }, [checklist?.items]);

  const onSubmit = async (formData: SplitPDFFormData) => {
    try {
      // temporary code. move to slice when swagger docs are ready.
      const url = `${config.apiBasePath.dali}/api/split-pdf`;
      const pages = selectedPages.map((image) => {
        return image.pageNumber;
      });
      const requestData = { pages: pages, url: pdfUrl };
      const { data: pdf } = await axios.post(url, requestData, {
        headers: { 'content-type': 'application/json' },
        responseType: 'arraybuffer',
      });

      const blob = new Blob([pdf], { type: 'application/pdf' });

      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.SPLIT_PDF_PROCESSED_PDF_SPLITTING,
      );
      const checklistItem = filteredChecklistItems?.find(
        (item) => item.id === formData.assignTo?.value,
      );

      const fileName = `split-pdf-${kebabCase(checklistItem?.name)}.pdf`;
      const file = new File([blob], fileName);
      let success: boolean = false;
      if (isChecklistV2) {
        success = await dispatch(
          uploadToDropboxAndAddFileReferences(
            checklist.id!,
            checklistItem?.id!,
            dropboxId!,
            userDetail?.id!,
            [file],
          ),
        );
      } else {
        success = await dispatch(
          uploadChecklistDocument(
            formData.assignTo?.value!,
            fileName,
            '',
            userDetail?.id!,
            new File([blob], fileName),
            containerId,
            checklist.id!,
          ),
        );
      }

      await dispatch(
        fetchChecklistItemById(checklist?.id!, checklistItem?.id!),
      );

      // reset
      if (success) {
        setIsAssigning(false);

        setSelectedPages([]);
        setValue('selectStartPageNumber', '');
        setValue('selectEndPageNumber', '');
        setValue('assignTo', undefined);

        setIsShowingSuccess(true);
      }
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to process the splitting of pdf.', e, {
        splitPdf: { pdfUrl },
      });
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to process the pdf. Please try again in a few moments.',
          ErrorService.getErrorCode(e),
        ),
      );
    }
  };

  return (
    <div className='w-full h-full'>
      {!!selectedPages.length ? (
        <div className='h-screen flex flex-col'>
          <div className='flex-grow'>
            <RenderPDF
              pdfImages={selectedPages}
              width={650}
              className='!h-[calc(100vh-61px)] w-full'
            />
          </div>
          <div className='flex justify-end border-t p-2'>
            <ZenButton
              variant='primary'
              label='Assign'
              LeftIconComponent={
                <FontAwesomeIcon
                  icon={faRectangleHistoryCirclePlus}
                  className='text-base'
                />
              }
              onClick={() => {
                AnalyticsService.instance().logEvent(
                  AnalyticsEventEnum.SPLIT_PDF_ASSIGN_BUTTON_CLICKED,
                );
                setIsAssigning(true);
              }}
            />
          </div>
        </div>
      ) : (
        <div className='w-full h-full flex items-center justify-center'>
          <div className='flex flex-col items-center'>
            <p className='font-zen-body text-zen-dark-9 text-lg'>
              Select the pages on the left to attach to the checklist item
            </p>
            <img
              alt='Select pages'
              src={EmptyState}
              className='w-full max-w-md mt-4'
            />
          </div>
        </div>
      )}

      <ZenSimpleConfirmationModal
        isOpen={isAssigning}
        variant='info'
        size='medium'
        title='Assign PDF to Checklist'
        onConfirm={handleSubmit(onSubmit)}
        onClose={() => setIsAssigning(false)}
        isSubmitting={isSubmitting}
      >
        <div className='my-6'>
          <ZenControlledSelectInput<SplitPDFFormData, 'assignTo'>
            control={control}
            label='Checklist Item:'
            placeholder='Choose checklist item'
            options={(filteredChecklistItems || []).map((item) => ({
              LabelComponent: (
                <div className='w-full lg:flex flex-row items-center justify-between'>
                  <span className='pr-2 truncate'>{item?.name!}</span>
                  <ZenChecklistLabel
                    status={item?.status!}
                    isRequired={item?.required!}
                  />
                </div>
              ),
              label: item.name!,
              value: item.id!,
            }))}
            rules={{ required: 'Required' }}
            isRequired
            name='assignTo'
            isSearchable
          />
        </div>
      </ZenSimpleConfirmationModal>

      <ZenSimpleConfirmationModal
        isOpen={isShowingSuccess}
        variant='success'
        title='Uploaded Successfully!'
        subtitle='The new PDF has been successfully uploaded & attached to the selected checklist item.'
        isSubmitting={false}
        confirmButtonText='Attach Another'
        cancelButtonText="I'm Finished"
        onConfirm={() => {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.SPLIT_PDF_UPLOAD_ANOTHER,
          );
          setIsShowingSuccess(false);
        }}
        onClose={() => {
          AnalyticsService.instance().logEvent(
            AnalyticsEventEnum.SPLIT_PDF_FINISHED_BUTTON_CLICKED,
          );

          if (!!backUrl) {
            history.push(backUrl);
          }
        }}
      />
    </div>
  );
};

export default ZenSplitPdfPreviewAndAssign;
