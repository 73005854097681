import { Earning, EarningType } from '../WealthManagementTypes';
import { AnalyticsEventEnum } from '../../../types';
import AnalyticsService from '../../../services/AnalyticsService';
import {
  LANDING_PAGE_VIDEO_THUMBNAIL_URL,
  LANDING_PAGE_VIDEO_URL,
} from './LandingPageVideoConstants';

const EarningTypeToEventMap: Record<EarningType, AnalyticsEventEnum> = {
  [Earning.COMMISSION_INCOME]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_COMMISSION_INCOME_PLAY_VIDEO,
  [Earning.PRE_CAP_SPP]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_PRE_CAP_SPP_PLAY_VIDEO,
  [Earning.STOCK_AWARD_FOR_CAPPING]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_CAPPING_AWARD_PLAY_VIDEO,
  [Earning.POST_CAP_SPP]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_POST_CAP_SPP_PLAY_VIDEO,
  [Earning.ELITE_PRODUCTION_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_ELITE_PRODUCTION_AWARD_PLAY_VIDEO,
  [Earning.ELITE_CULTURAL_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_ELITE_CULTURAL_AWARD_PLAY_VIDEO,
  [Earning.REVENUE_SHARE_INCOME]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_REVENUE_SHARE_PLAY_VIDEO,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_STOCK_AWARD_ATTRACTING_PLAY_VIDEO,
};

interface ZenLandingPageVideoProps {
  earningType: EarningType;
}

export const ZenLandingPageVideo: React.FC<ZenLandingPageVideoProps> = ({
  earningType,
}) => {
  return (
    <div className='flex items-center justify-center bg-[#F5FAFF]'>
      <video
        src={LANDING_PAGE_VIDEO_URL[earningType]}
        controls
        poster={LANDING_PAGE_VIDEO_THUMBNAIL_URL[earningType]}
        className='h-3/4 bg-black rounded-2xl sticky -mb-32 z-50 w-3/4 object-'
        onPlay={() => {
          AnalyticsService.instance().logEvent(
            EarningTypeToEventMap[earningType],
          );
        }}
      />
    </div>
  );
};
