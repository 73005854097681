import { faCircleInfo, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VictoryPie } from 'victory';
import { LINE_GRAPH_PATH_DATA_FIVE } from '../../../constants/WealthPlanConstants';
import AnalyticsService from '../../../services/AnalyticsService';
import { setWealthKnowledgeLandingPage } from '../../../slices/WealthManagementSlice';
import { RootState } from '../../../types';
import { displayFormattedFixedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import { calculatePercentage } from '../../../utils/MathUtils';
import {
  earningTypeToEditEventMap,
  getEarningsOverviewCardGradient,
} from '../../../utils/WealthPlanUtils';
import {
  EarningIcon,
  EarningNumber,
  EarningTitle,
  EarningType,
  PrimaryColor,
} from '../WealthManagementTypes';
import { selectTerminalValue } from '../WealthManagementUtils';
import { cn } from '../../../utils/classUtils';
import EarningOverviewCurveGraph from './EarningOverviewCurveGraph';

interface EarningsOverviewCardProps {
  earningType: EarningType;
  onClick(): void;
  isLocked?: boolean;
}

const EarningsOverviewCard: React.FC<EarningsOverviewCardProps> = ({
  earningType,
  onClick,
  isLocked = false,
}) => {
  const dispatch = useDispatch();
  const {
    terminalValue,
    readonlyMode,
    netEarningsPlusProjections,
  } = useSelector((state: RootState) => ({
    terminalValue: selectTerminalValue(state, earningType),
    readonlyMode: state.wealthManagement.readonlyMode,
    netEarningsPlusProjections:
      state.wealthManagement.earningsOverviewResponse.data
        ?.netEarningsPlusProjections,
  }));

  const {
    teamCommission,
    netCommission,
    preCapShareValue,
    combinedPreCapShareValue,
    postCapShareValue,
    combinedPostCapShareValue,
  } = netEarningsPlusProjections || {};

  const projectedEarnings = displayFormattedFixedAmountWithCurrency(
    {
      amount: terminalValue,
    },
    false,
    0,
  );

  const teamCommissionValue = displayFormattedFixedAmountWithCurrency(
    {
      amount: teamCommission?.amount,
    },
    false,
    0,
  );
  const netCommissionValue = displayFormattedFixedAmountWithCurrency(
    {
      amount: netCommission?.amount,
    },
    false,
    0,
  );

  const isPostOrPreCapSPP =
    earningType === 'POST_CAP_SPP' || earningType === 'PRE_CAP_SPP';
  const isCommissionIncomeType = earningType === 'COMMISSION_INCOME';

  const preCapSppPercentage = calculatePercentage(
    preCapShareValue?.amount ?? 0,
    combinedPreCapShareValue?.amount! > 0
      ? combinedPreCapShareValue?.amount!
      : 100,
  );

  const postCapSppPercentage = calculatePercentage(
    postCapShareValue?.amount ?? 0,
    combinedPostCapShareValue?.amount! > 0
      ? combinedPostCapShareValue?.amount!
      : 100,
  );

  const preOrPostCapSppPercentage =
    earningType === 'PRE_CAP_SPP' ? preCapSppPercentage : postCapSppPercentage;

  const handleClick = () => {
    if (readonlyMode) {
      return;
    }
    AnalyticsService.instance().logEvent(
      earningTypeToEditEventMap[earningType],
    );
    onClick();
  };

  return (
    <div
      data-testId='wealthplan-earnings-overview-card'
      className='relative rounded-xl border border-zen-dark-4 overflow-hidden cursor-pointer'
      style={{
        background: getEarningsOverviewCardGradient(earningType),
        backgroundPosition: 'right bottom',
      }}
      onClick={handleClick}
    >
      <div className='p-4'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-1'>
            <FontAwesomeIcon
              icon={EarningIcon[earningType]}
              className='mr-1 text-base'
              style={{ color: PrimaryColor[earningType] }}
            />
            <span className='text-sm font-medium mt-0.5 text-zen-dark-8'>
              {EarningNumber[earningType]}. {EarningTitle[earningType]}
            </span>
          </div>
          <div className='flex items-center space-x-3'>
            <div
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setWealthKnowledgeLandingPage(earningType));
              }}
              className='h-7 w-7 flex items-center justify-center bg-white rounded-full cursor-pointer'
            >
              <FontAwesomeIcon
                icon={faCircleInfo}
                className='text-zen-dark-7 text-xs'
                data-testid='earnings-overview-card-info-icon'
              />
            </div>
            <div
              onClick={handleClick}
              className={cn(
                'h-7 w-7 flex items-center justify-center bg-primary-light rounded-full cursor-pointer',
                readonlyMode
                  ? 'text-grey-400 cursor-not-allowed'
                  : 'text-primary-blue',
              )}
            >
              <FontAwesomeIcon icon={faPencil} className='text-xs' />
            </div>
          </div>
        </div>
        <p className='text-[32px] text-zen-dark-9 mt-1.5'>
          {isCommissionIncomeType ? netCommissionValue : projectedEarnings}
        </p>
      </div>
      {isLocked ? (
        <div className='relative h-24'>
          <div className='absolute bottom-0 left-0 right-0'>
            <div className='mt-3'>
              <div className='flex items-center justify-end px-4'>
                <div
                  onClick={handleClick}
                  className='bg-primary-light flex items-center space-x-2 text-base justify-center py-1 px-4 rounded-[30px] border border-zen-warning-5 cursor-pointer'
                >
                  <FontAwesomeIcon
                    icon={faLock}
                    className='text-yellow-300 mb-1'
                  />
                  <span className='text-grey-400'>Locked</span>
                </div>
              </div>
            </div>
            <div className='mt-4.5 px-4 py-1 bg-zen-warning-7 text-yellow-600 rounded-b-xl'>
              <p className='text-sm'>
                You don&apos;t participate in this way to earn
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {isPostOrPreCapSPP ? (
            <div className='flex items-center justify-end px-6 pb-6'>
              <div className='h-[70px] w-[70px]'>
                <VictoryPie
                  width={70}
                  height={70}
                  innerRadius={15}
                  padding={5}
                  labels={() => null}
                  style={{
                    data: {
                      fill: ({ datum }) => datum.fill,
                    },
                  }}
                  data={[
                    {
                      name: 'currentEarnings',
                      value:
                        preOrPostCapSppPercentage > 0
                          ? preOrPostCapSppPercentage
                          : 0.6,
                      fill: PrimaryColor[earningType],
                    },
                    {
                      name: 'remainingEarnings',
                      value: 100 - preOrPostCapSppPercentage,
                      fill: '#8BB7FF',
                    },
                  ]}
                  x='name'
                  y='value'
                />
              </div>
            </div>
          ) : (
            <div className='relative h-24'>
              {isCommissionIncomeType && teamCommission?.amount! > 0 && (
                <div className='absolute bottom-0 right-0'>
                  <EarningOverviewCurveGraph
                    completionPercentage={15}
                    tooltipInfo={{
                      label: 'Team',
                      value: teamCommissionValue,
                    }}
                    earningType={earningType}
                    customLineGraphAttributes={{
                      height: 87.5,
                      width: 313.5,
                      pathData: LINE_GRAPH_PATH_DATA_FIVE,
                      strokeColor: '#FABE05',
                    }}
                  />
                </div>
              )}
              <div className='absolute bottom-0 right-0'>
                <EarningOverviewCurveGraph
                  completionPercentage={isCommissionIncomeType ? 30 : 70}
                  tooltipInfo={{
                    label: isCommissionIncomeType ? 'You' : 'Projected',
                    value: projectedEarnings,
                  }}
                  earningType={earningType}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EarningsOverviewCard;
