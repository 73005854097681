import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import MortgageAccountsRoute from '../../routes/MortgageAccountsRoute';
import MortgageActivityRoute from '../../routes/MortgageActivityRoute';
import MortgageApplicationRoute from '../../routes/MortgageApplicationRoute';
import MortgageCommentRoute from '../../routes/MortgageCommentRoute';
import { Match } from '../../routes/MortgageDetailsRoute';
import ZenMortgageFileCabinetRoute from '../../routes/MortgageFileCabinetRoute';
import MortgageRoadToSuccessRoute from '../../routes/MortgageRoadToSuccessRoute';
import Route404 from '../../routes/Route404';
import { RootState } from '../../types';
import { getBorrower } from '../../utils/MortgageUtils';
import ZenRouterTabs, { ZenTab } from '../Zen/Tab/ZenRouterTabs';
import ZenAlert from '../Zen/ZenAlert';
import MortgageChecklistRoute from './MortgageChecklistRoute';
import MortgageDetailsRouteHeader from './MortgageDetailsRouteHeader';

interface MortgageDetailsTabComponentProps extends RouteComponentProps<Match> {
  borrowerId: string;
}

const MortgageDetailsTabComponent: React.FC<MortgageDetailsTabComponentProps> = ({
  borrowerId,
  match,
}) => {
  const { id: mortgageId } = match.params;
  const {
    loanResponse: { data: loan },
    borrowerAccountsResponse: { data: borrowerAccounts },
    showApplicationError,
  } = useSelector((state: RootState) => state.mortgage);
  const borrowerResponse = getBorrower(borrowerId, loan?.borrowers || []);

  const mortgageTabs = useMemo(() => {
    const tabs: ZenTab[] = [
      {
        label: 'Application',
        TabComponent: () => (
          <MortgageApplicationRoute borrowerId={borrowerId} />
        ),
        path: `/mortgages/${mortgageId}/borrower/${borrowerId}`,
        exact: true,
      },
      {
        label: 'Accounts',
        TabComponent: () => <MortgageAccountsRoute borrowerId={borrowerId} />,
        path: `/mortgages/${mortgageId}/borrower/${borrowerId}/accounts`,
        exact: true,
      },
      {
        label: 'Road To Success',
        TabComponent: () => (
          <MortgageRoadToSuccessRoute borrowerId={borrowerId} />
        ),
        path: `/mortgages/${mortgageId}/borrower/${borrowerId}/road-to-success`,
        exact: true,
      },
      {
        label: 'Checklist',
        TabComponent: () => <MortgageChecklistRoute borrowerId={borrowerId} />,
        path: `/mortgages/${mortgageId}/borrower/${borrowerId}/checklist`,
        exact: true,
      },
      {
        label: 'File Cabinet',
        TabComponent: () => (
          <ZenMortgageFileCabinetRoute borrowerId={borrowerId} />
        ),
        path: `/mortgages/${mortgageId}/borrower/${borrowerId}/file-cabinet`,
        exact: true,
      },
      {
        label: 'Comments',
        TabComponent: () => <MortgageCommentRoute borrowerId={borrowerId} />,
        path: `/mortgages/${mortgageId}/borrower/${borrowerId}/comments`,
        exact: true,
      },
      {
        label: 'Activity',
        TabComponent: () => <MortgageActivityRoute borrowerId={borrowerId} />,
        path: `/mortgages/${mortgageId}/borrower/${borrowerId}/activity`,
        exact: true,
      },
    ];

    return tabs.filter((tab) => {
      const label = tab.label;

      if (label === 'File Cabinet') {
        return borrowerResponse?.dropboxId;
      }

      if (label === 'Accounts') {
        return !isEmpty(borrowerAccounts?.accounts);
      }

      return true;
    });
  }, [
    mortgageId,
    borrowerId,
    borrowerResponse?.dropboxId,
    borrowerAccounts?.accounts,
  ]);

  const tabsPathRegex = useMemo(
    () => `(${mortgageTabs.map((tab) => tab.path).join('|')})`,
    [mortgageTabs],
  );

  return (
    <React.Fragment>
      <MortgageDetailsRouteHeader borrowerId={borrowerId} />
      {showApplicationError && (
        <div className='px-4 my-2'>
          <ZenAlert
            text='Please fill all the required fields before submitting the application.'
            variant='error'
            icon={<FontAwesomeIcon icon={solid('circle-exclamation')} />}
          />
        </div>
      )}
      <Switch>
        <Route path={tabsPathRegex} exact>
          <ZenRouterTabs
            tabs={mortgageTabs}
            stickyTabs
            customTop='top-[76px]'
          />
        </Route>
        <Route path={`${match.path}/*`} component={Route404} />
      </Switch>
    </React.Fragment>
  );
};

export default MortgageDetailsTabComponent;
