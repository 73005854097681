import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createTransactionBuilder } from '../../slices/TransactionBuilderSlice';
import GeminiButton from '../Gemini/GeminiButton';

interface TransactionsRouteHeaderProps {
  isListing?: boolean;
}

const TransactionsOrListingsRouteHeader: React.FC<TransactionsRouteHeaderProps> = ({
  isListing = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div className='flex items-center justify-between md:space-x-4'>
      {!isListing && (
        <GeminiButton
          variant='primary-outline'
          label='Create Referral'
          leftIcon={faPlus}
          onClick={() => history.push('/transaction/referral')}
        />
      )}
      <GeminiButton
        label={`Add ${isListing ? 'Listing' : 'Transaction'}`}
        leftIcon={faPlus}
        onClick={async () => {
          const transactionBuilderId = await dispatch(
            createTransactionBuilder(isListing ? 'LISTING' : 'TRANSACTION'),
          );

          if (!!transactionBuilderId) {
            history.push(
              `/${
                isListing ? 'listing' : 'transaction'
              }/create/${transactionBuilderId}`,
            );
          }
        }}
      />
    </div>
  );
};

export default TransactionsOrListingsRouteHeader;
