import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Control } from 'react-hook-form-v7';
import { cn } from '../../utils/classUtils';
import ColorPicker from '../ColorPicker';
import Hover from '../Hover';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenButton from '../Zen/ZenButton';
import { CreateJointVentureFormState } from './CreateJointVentureSteps';

export interface CreateTierLevelProps {
  tierIndex: number;
  control: Control<CreateJointVentureFormState, object>;
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
}

const DEFAULT_COLORS = ['#05C3F9', '#444444', '#439775', '#D09F43'];

const CreateTierLevel: React.FC<CreateTierLevelProps> = ({
  tierIndex,
  control,
  color,
  setColor,
}) => {
  const [colorPickerColor, setColorPickerColor] = useState<string>(color);
  const [colorOptionClicked, setColorOptionClicked] = useState<number>();
  const [mounted, setMounted] = useState(false);

  const handleColorOptionClick = (
    optionNumber: number,
    optionColor: string,
  ) => {
    setColorOptionClicked(optionNumber);
    setColor(optionColor);
  };

  const handleColorPickerOptionClick = () => {
    setMounted(true);
    setColor(colorPickerColor);
    setColorOptionClicked(4);
  };

  const handleColorPick = (col: string) => {
    setColorPickerColor(col);
    setColor(col);
  };

  return (
    <div className='pt-3'>
      <div>
        <ZenControlledTextInput<
          CreateJointVentureFormState,
          `tiers.${number}.customTierName`
        >
          name={`tiers.${tierIndex}.customTierName`}
          control={control}
          label='Tier Name'
          placeholder='Type tier name'
          shouldUnregister={false}
          isRequired
          rules={{
            required: 'Please provide the tier name',
          }}
        />
      </div>

      <div className='flex justify-between items-center pt-2.5'>
        <p className='font-semibold text-base text-zen-dark-9'>Pick Color</p>
        <div className='relative grid grid-cols-5 gap-x-2'>
          {DEFAULT_COLORS.map((color, index) => (
            <div
              key={color}
              className={`w-7 h-7 flex items-center justify-center border-zen-dark-4 rounded-full ${
                colorOptionClicked === index && 'border-2'
              }`}
            >
              <div className='w-6.5 h-5 flex items-center justify-center rounded-full'>
                <p
                  className='relative w-5 h-5 m-1 rounded-full'
                  style={{
                    backgroundColor: color,
                  }}
                  onClick={() => handleColorOptionClick(index, color)}
                />
              </div>
            </div>
          ))}
          <Hover
            hoverComponent={
              <div>
                <ColorPicker
                  color={colorPickerColor}
                  showPicker
                  setColor={handleColorPick}
                />
                <div className='flex flex-row items-center justify-between py-2'>
                  <ZenButton
                    label='Cancel'
                    variant='secondary-outline'
                    onClick={() => {
                      setColor('');
                      setMounted(false);
                    }}
                  />
                  <ZenButton
                    label='OK'
                    variant='primary'
                    onClick={() => {
                      setMounted(false);
                    }}
                  />
                </div>
              </div>
            }
            config={{
              placement: 'bottom',
              trigger: 'click',
              visible: mounted,
            }}
          >
            <p
              className={cn(
                'flex relative items-center justify-center w-5 h-5 m-1 rounded-full',
                !colorPickerColor && 'border-2 border-zen-dark-9',
              )}
              style={{
                backgroundColor: colorPickerColor,
              }}
              onClick={handleColorPickerOptionClick}
            >
              <FontAwesomeIcon icon={faPlus} size='sm' />
            </p>
          </Hover>
        </div>
      </div>
      {!color && (
        <div className='flex justify-end items-center'>
          <p className='text-zen-danger text-sm'>
            Please pick a color for your custom tier
          </p>
        </div>
      )}
    </div>
  );
};

export default CreateTierLevel;
