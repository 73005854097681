import { Select as MSelect, SelectProps } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { cn } from '../../utils/classUtils';
import { combineDefaultClassnamesWithCustomClassnames } from './utils';

const defaultStyles: SelectProps['classNames'] = {
  icon: cn(
    'absolute left-0 top-1/2 -translate-y-1/2 text-sm text-primary-blue pl-4 pt-1 z-10',
    'disabled:bg-grey-100',
  ),
  rightSection:
    'absolute right-0 mr-4 top-1/2 -translate-y-1/2 text-sm bg-primary-light text-primary-dark',
  label: 'font-inter font-light text-[15px] text-primary-dark leading-6',
  root: 'relative w-full space-y-1',
  error: '!text-red-600 !text-sm',
  required: 'text-red-600 text-sm',
  input: cn(
    'rounded-md w-full font-inter font-light bg-primary-light py-2.5 cursor-pointer',
    'placeholder:text-[15px] placeholder:font-inter placeholder:font-light placeholder:text-grey-500',
    'border border-regent-400 outline-none !ring-0',
    'focus:!border-primary-dark',
    'disabled:bg-grey-100 disabled:cursor-not-allowed',
  ),
  wrapper: 'relative flex items-stretch',
  nothingFound: 'text-base text-grey-600 py-2 px-5',
  itemsWrapper: 'bg-primary-light !p-0',
  item:
    '!font-inter !font-light bg-primary-light text-base text-primary-dark py-2 px-5 hover:bg-rezen-light-blue-100 cursor-pointer',
  dropdown:
    'z-[300] top-0 absolute bg-primary-light border border-regent-300 rounded-md p-0 overflow-hidden',
};

export const Select: React.FC<SelectProps> = (props) => {
  const showClearIcon = !!props.clearable && !!props.value;
  const hasError = !!props.error;

  return (
    <MSelect
      unstyled
      type='text' // This is needed or else type='search' will add an x icon
      rightSection={
        !showClearIcon &&
        (props.rightSection ?? (
          <FontAwesomeIcon icon={faChevronDown} data-testid='chevron-down' />
        ))
      }
      clearButtonProps={{
        style: {
          color: '#1D1D1D',
          ...props.clearButtonProps?.style,
        },
        ...props.clearButtonProps,
      }}
      nothingFound={props.nothingFound ?? 'No items found'}
      transitionProps={{
        transition: 'pop-top-right',
        duration: 80,
        timingFunction: 'ease',
      }}
      {...props}
      classNames={combineDefaultClassnamesWithCustomClassnames(
        defaultStyles,
        {
          rightSection: cn(
            !showClearIcon && 'pt-0.5 pr-1 pointer-events-none cursor-pointer',
            props.disabled && 'bg-grey-100',
          ),
          label: 'text-primary-dark',
          input: cn(
            props.icon ? 'pl-10' : 'pl-4',
            props.rightSection ? 'pr-10' : 'pr-4',
            hasError && 'border-coral-red',
          ),
        },
        props.classNames,
      )}
    />
  );
};
