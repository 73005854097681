import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseFormSetValue,
} from 'react-hook-form-v7';
import InputMask from 'react-input-mask';
import { EnumMap, FormFieldTooltipIndexProps } from '../../../types';
import {
  ControlledTextInputDirection,
  ControlledTextInputVariant,
} from './ZenControlledTextInput';
import ZenFormErrorMessage from './ZenFormErrorMessage';

export type ZenControlledMaskTextInputType = 'text' | 'password';

interface ZenControlledMaskTextInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  direction?: ControlledTextInputDirection;
  label?: string;
  labelClassName?: string;
  placeholder?: string;
  subLabel?: string;
  noBorder?: boolean;
  type?: ZenControlledMaskTextInputType;
  maskType: string;
  readOnly?: boolean;
  variant?: ControlledTextInputVariant;
  isRequired?: boolean;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  helperText?: string;
  setValue: UseFormSetValue<TFieldValues>;
  onBlurSpy?: () => void;
}

const ZenControlledMaskTextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  labelClassName,
  subLabel,
  direction = 'ltr',
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  tooltipIndex,
  shouldUnregister = true,
  startAdornment,
  endAdornment,
  helperText,
  isRequired = false,
  variant = 'light',
  type = 'text',
  maskType,
  setValue,
  onBlurSpy,
  ...rest
}: ZenControlledMaskTextInputProps<TFieldValues, TName>) => {
  const ref = useRef<InputMask>(null);
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlurCapture = () => {
    if (onBlurSpy) {
      onBlurSpy();
    }
    setIsFocused(false);
  };

  useEffect(() => {
    if (maskType) {
      // @ts-expect-error
      const formattedValue = ref.current?.value;
      setValue(rest.name, formattedValue);
    }
  }, [maskType, rest.name, setValue]);

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error, invalid },
      }) => {
        const variantToBackgroundStyleMap: EnumMap<
          ControlledTextInputVariant,
          string
        > = {
          light: 'bg-white',
          transparent: 'bg-transparent',
        };

        const variantToTextStyleMap: EnumMap<
          ControlledTextInputVariant,
          string
        > = {
          light: invalid ? 'text-zen-danger' : 'text-zen-dark-9',
          transparent: invalid ? 'text-zen-danger' : 'text-white',
        };

        const variantToBorderStyleMap: EnumMap<
          ControlledTextInputVariant,
          string
        > = {
          light: isFocused ? 'border-zen-dark-9' : 'border-zen-dark-5',
          transparent: isFocused ? 'border-zen-dark-7' : 'border-white',
        };

        return (
          <div className='space-y-1 w-full'>
            {label && (
              <label className='inline-block' htmlFor={name}>
                <span
                  className={classNames(
                    'font-zen-body font-semibold',
                    variantToTextStyleMap[variant],
                    labelClassName,
                  )}
                >
                  {label}
                </span>
                {!!subLabel && (
                  <span className='font-zen-body text-sm text-zen-dark-12 ml-1'>
                    {subLabel}
                  </span>
                )}
                {isRequired && <span className='text-zen-danger'>*</span>}
              </label>
            )}
            <div
              className={classNames(
                'flex items-stretch border rounded-lg overflow-hidden',
                variantToBackgroundStyleMap[variant],
                variantToBorderStyleMap[variant],
                value && 'text-zen-dark-9',
                invalid && '!border-zen-danger',
                {
                  'border-none': noBorder,
                  'border-zen-dark-7 pointer-events-none':
                    variant === 'transparent' && !!readOnly,
                },
              )}
            >
              {startAdornment && <div>{startAdornment}</div>}
              <InputMask
                id={name}
                ref={ref}
                value={value}
                onBlur={() => {
                  onBlur();
                  onBlurCapture();
                }}
                onChange={onChange}
                name={name}
                maskChar=''
                mask={maskType}
                type={type}
                onFocus={onFocus}
                dir={direction}
                required={isRequired}
                placeholder={placeholder}
                className='appearance-none p-2 w-full border-none focus:outline-none focus:ring-0 font-zen-body font-normal'
                data-tooltip-index={tooltipIndex}
                readOnly={readOnly}
              />
              {endAdornment && <div>{endAdornment}</div>}
            </div>
            <div className='flex'>
              {!!error && !hideErrorMessage && (
                <ZenFormErrorMessage message={error.message} />
              )}
              {helperText && (
                <div className='ml-auto font-zen-body text-base font-normal text-zen-dark-6'>
                  {helperText}
                </div>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default ZenControlledMaskTextInput;
