import { faArrowUpArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import ZenControlledAsyncSelectInput from '../../../components/Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledEmailInput from '../../../components/Zen/Input/ZenControlledEmailInput';
import ZenControlledFileUploadInput from '../../../components/Zen/Input/ZenControlledFileUploadInput';
import ZenControlledPhoneNumberInput from '../../../components/Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledSelectInput from '../../../components/Zen/Input/ZenControlledSelectInput';
import ZenControlledTextAreaInput from '../../../components/Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../../../components/Zen/Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../../../components/Zen/Input/ZenControlledToggleInput';
import { ADDRESS_PLACEHOLDER } from '../../../constants/PlaceholderConstants';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  FeeSplitFeeTypeEnum,
  LeaderFeeSplits,
  LeaderSplitDto,
  MoneyValueCurrencyEnum,
  NationalIdentificationValueTypeEnum,
  ParticipantValue,
  PaymentParticipantValue,
  PaymentParticipantValueRoleEnum,
  TransactionControllerApi,
  TransactionResponse,
  TransactionResponseCountryEnum,
  UpdateParticipantRequest,
  UpdateParticipantRequestParticipantRoleEnum,
} from '../../../openapi/arrakis';
import {
  TeamMemberResponseRolesEnum,
  TeamResponseTypeEnum,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import {
  toggleTaxWithheld,
  updateInstantPaymentEligibility,
  updateProTeammateCommissionSplits,
  updateProTeammateSplitFees,
  updateProTeammateTeamFees,
  updateTransactionParticipant,
  uploadW9Form,
} from '../../../slices/TransactionSlice';
import {
  AppDispatch,
  AsyncResponse,
  AsyncSelectOptionReactElement,
  EnumMap,
  FeatureFlagTypeEnum,
  ISelectOption,
  ParticipantType,
  RootState,
} from '../../../types';
import { getIdValidations } from '../../../utils/AgentHelper';
import { STATE_OR_PROVINCE_ABBREVIATIONS } from '../../../utils/AnnouncementUtils';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import {
  cleanTeamSplits,
  isValidFee,
} from '../../../utils/ProTeams/ProTeamsHelper';
import { capitalizeEnum, getFileNameFromUrl } from '../../../utils/StringUtils';
import { searchActiveAgents } from '../../../utils/TableUtils';
import {
  getParticipantScreenTitle,
  getValidParticipantRoles,
  isAddParticipantLabelRequired,
  isCanadaTransaction,
  RequiredField,
  validateFields,
} from '../../../utils/TransactionHelper';
import { isParticipantAdmin } from '../../../utils/TransactionUtils';
import {
  EMAIL_VALIDATIONS,
  FILE_VALIDATIONS,
  GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../utils/Validations';
import AvatarLabelComponent from '../../AgentReports/AvatarLabelComponent';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import { GooglePlaceLocationType } from '../../ControlledGoogleAutocompleteSearchInputV7';
import DefaultLoader from '../../DefaultLoader';
import IconButton from '../../IconButton';
import ResourceContainer from '../../ResourceContainer';
import ZenEditTransactionToggleSection from '../../transactions/ZenEditTransactionToggleSection';
import ZenControlledGoogleAutocompleteSearchInput from '../Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenSidebarModalActionFooterV2 from '../Modal/ZenSidebarModalActionFooterV2';
import ZenSimpleConfirmationModal from '../Modal/ZenSimpleConfirmationModal';
import ZenSidebarModal from '../ZenSidebarModal';
import ZenEditProLeaderSplitForm from './ZenEditProLeaderSplitForm';
import ZenEditProTeamFeesForm from './ZenEditProTeamFeesForm';
import ZenEditProTeamFeeSplitForm from './ZenEditProTeamFeeSplitForm';

interface ZenEditTransactionParticipantFormProps {
  transaction: TransactionResponse;
  participant: PaymentParticipantValue | ParticipantValue | null;
  readOnly?: boolean;
  isOpen: boolean;
  onClose(): void;
}

export interface TeamLeaderFeeSplit {
  leaderId: string;
  realSplit: number;
  beopFee: number;
  realFee: number;
  transactionFee: number;
}

export interface EditTransactionFormData {
  agentName: ISelectOption;
  participantRole: ISelectOption;
  firstName: string;
  lastName: string;
  company: string;
  emailAddress: string;
  phoneNumber: string;
  commissionDocumentRecipient: boolean;
  passThrough: boolean;
  paidByReal: boolean;
  personalDeal: boolean;
  taxWithheld: boolean;
  instantPaymentEligible: boolean;
  address: string;
  w9form: string;
  Bn: string;
  gstId: string;
  pstId: string;
  hstId: string;
  qstId: string;
  ein: string;
  sin: string;
  notes: string;
  autoAddress: GooglePlaceLocationType;
  proLeaders: LeaderSplitDto[];
  teamPreCapFee: number;
  teamPostCapFee: number;
  teamSplits: TeamLeaderFeeSplit[];
}

const ZenEditTransactionParticipantFormSidebarModal: React.FC<ZenEditTransactionParticipantFormProps> = ({
  transaction,
  participant,
  isOpen,
  onClose,
  readOnly,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const defaultw9FileResponse = {
    name: 'w9File',
    error: null,
    loading: true,
  };
  const [w9FileResponse, setW9FileResponse] = useState<AsyncResponse<String>>(
    defaultw9FileResponse,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const businessIdsByType: EnumMap<
    NationalIdentificationValueTypeEnum,
    string
  > = (participant?.paidViaBusinessEntity?.nationalIds || []).reduce<
    EnumMap<NationalIdentificationValueTypeEnum, string>
  >(
    (map, currentValue) => {
      map[currentValue.type!] = currentValue.id!;
      return map;
    },
    {
      BN: '',
      EIN: '',
      GST_ID: '',
      HST_ID: '',
      PST_ID: '',
      QST_ID: '',
      SIN: '',
      SSN: '',
      FNIN: '',
      B_AND_O_ID: '',
      CBJ_ID: '',
      GRT_ID: '',
      GET_ID: '',
      LA_CBT_ID: '',
    },
  );

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<EditTransactionFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      firstName: participant?.firstName,
      lastName: participant?.lastName,
      address: participant?.address,
      autoAddress: { formatted_address: participant?.address },
      agentName: {
        label: `${participant?.firstName} ${participant?.lastName}`,
        value: participant?.yentaId || '',
      },
      commissionDocumentRecipient: participant?.commissionDocumentRecipient,
      company: participant?.paidViaBusinessEntity?.name,
      phoneNumber: participant?.phoneNumber,
      emailAddress: participant?.emailAddress,
      Bn: businessIdsByType[NationalIdentificationValueTypeEnum.Bn],
      ein: businessIdsByType[NationalIdentificationValueTypeEnum.Ein],
      gstId: businessIdsByType[NationalIdentificationValueTypeEnum.GstId],
      hstId: businessIdsByType[NationalIdentificationValueTypeEnum.HstId],
      pstId: businessIdsByType[NationalIdentificationValueTypeEnum.PstId],
      sin: businessIdsByType[NationalIdentificationValueTypeEnum.Sin],
      qstId: businessIdsByType[NationalIdentificationValueTypeEnum.QstId],
      participantRole: participant?.role
        ? {
            label: capitalizeEnum(participant?.role!),
            value: participant?.role! as string,
          }
        : undefined,
      personalDeal:
        participant && 'personalDeal' in participant!
          ? participant?.personalDeal
          : undefined,
      instantPaymentEligible:
        participant && 'instantPaymentEligibleTeamMember' in participant!
          ? participant?.instantPaymentEligibleTeamMember
          : undefined,
      paidByReal: participant?.paidByReal,
      passThrough: participant?.passThrough,
      taxWithheld: 'taxWithheld' in participant! && participant.taxWithheld,
      notes: participant?.notes,
    },
  });

  const { fields, append, update } = useFieldArray({
    control,
    name: 'proLeaders',
    shouldUnregister: false,
  });

  const { fields: splitFields, append: appendSplits } = useFieldArray({
    control,
    name: 'teamSplits',
    shouldUnregister: false,
  });

  const isCanada =
    transaction.country === TransactionResponseCountryEnum.Canada;

  const isPersonalDealSelected = watch('personalDeal');

  const {
    transaction: { transactionPermissions },
    team: { teamDetailOverview },
    auth: { userDetail, isAdmin },
  } = useSelector((state: RootState) => state);

  const [isAutoComplete, setIsAutoComplete] = useState<boolean>(false);

  const canUpdateProTeamLeaderSplits = () => {
    const teamMember = teamDetailOverview?.agents?.find(
      (agent) => agent?.agent?.id === userDetail?.id,
    );

    const isMember = teamMember?.roles?.includes(
      TeamMemberResponseRolesEnum.Member,
    );

    const isTeamLeader = teamMember?.roles?.includes(
      TeamMemberResponseRolesEnum.Leader,
    );

    const isTeamAdmin = teamMember?.roles?.includes(
      TeamMemberResponseRolesEnum.Admin,
    );

    const isTeamOwner = transaction.transactionOwner?.id === userDetail?.id;

    if (isAdmin) {
      return true;
    }
    if (isTeamLeader) {
      return true;
    }
    if (isTeamAdmin) {
      return true;
    }
    if (isTeamOwner) {
      return false;
    }
    if (isMember) {
      return false;
    }
    return false;
  };

  const [
    isPersonalDealModalOpen,
    setIsPersonalDealModalOpen,
  ] = useState<boolean>(false);

  const [
    firstName,
    lastName,
    companyName,
    commissionDocumentRecipient,
    participantRole,
    proLeaders,
    ein,
    teamSplits,
  ] = watch([
    'firstName',
    'lastName',
    'company',
    'commissionDocumentRecipient',
    'participantRole',
    'proLeaders',
    'ein',
    'teamSplits',
  ]);
  const isAdminRole = isParticipantAdmin(participant);
  const watchFields = {
    firstName,
    lastName,
    companyName,
    participantRole,
    ein,
  };

  const isInternalReferralParticipant = !!(participant as PaymentParticipantValue)
    ?.internalReferral;

  useEffect(() => {
    if (isOpen && participant?.externalParticipant) {
      trigger(['firstName', 'lastName', 'company', 'emailAddress', 'address']);
    }
  }, [
    trigger,
    firstName,
    lastName,
    companyName,
    isOpen,
    participant?.externalParticipant,
    readOnly,
  ]);

  const getProTeammateDetails = useCallback(async () => {
    try {
      setLoading(true);

      const { data: teamFeeData } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).getProTeammateTeamFees(transaction?.id!, participant?.id!);
      setValue('teamPreCapFee', teamFeeData?.preCapTeamFee?.amount!);
      setValue('teamPostCapFee', teamFeeData?.postCapTeamFee?.amount!);

      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).getProTeammateCommissionSplits(transaction?.id!, participant?.id!);
      append(data?.splits!);

      const { data: teammateFeesData } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).getProTeammateFeeSplits(transaction?.id!, participant?.id!);

      const cleanedSplits = cleanTeamSplits(teammateFeesData);
      appendSplits(cleanedSplits);
    } catch (e) {
      ErrorService.notify(
        'Unable to get pro teammate details in edit transaction participant form',
        e,
        {
          data: {
            transactionId: transaction?.id!,
            participantId: participant?.id!,
          },
        },
      );
      dispatch(
        showErrorToast(
          'An unexpected error occurred.',
          'We were unable to get pro teammate fees. Please try again in a few moments or contact support.',
        ),
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, participant?.id, transaction?.id]);

  const isProTeamMember =
    teamDetailOverview?.type === TeamResponseTypeEnum.Pro &&
    participant?.role !== PaymentParticipantValueRoleEnum.ProTeamLeader &&
    !participant?.externalParticipant;

  useEffect(() => {
    if (isProTeamMember) {
      getProTeammateDetails();
    }
  }, [getProTeammateDetails, isProTeamMember]);

  useEffect(() => {
    if (participant!) {
      if (
        'personalDeal' in participant! &&
        !participant.personalDeal &&
        isPersonalDealSelected
      ) {
        setIsPersonalDealModalOpen(isPersonalDealSelected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPersonalDealSelected, setValue]);

  const instantPaymentEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.INSTANT_PAYMENTS_TOGGLE,
  );

  const currentParticipantPermission = transactionPermissions?.participantPermissions?.find(
    (p) => p.participantId === participant?.id,
  );
  const isEligible = currentParticipantPermission?.callerAllowedToAssignInstantPaymentEligibility!;

  const onSubmit = async (values: EditTransactionFormData) => {
    if (values.w9form) {
      await dispatch(uploadW9Form(participant?.id!, values.w9form[0]));
    }

    const nationalIds = [];

    if (isCanada) {
      if (values.Bn) {
        nationalIds?.push({
          id: values.Bn,
          type: NationalIdentificationValueTypeEnum.Bn,
        });
      }
      if (values.gstId) {
        nationalIds?.push({
          id: values.gstId,
          type: NationalIdentificationValueTypeEnum.GstId,
        });
      }
      if (values.pstId) {
        nationalIds?.push({
          id: values.pstId,
          type: NationalIdentificationValueTypeEnum.PstId,
        });
      }
      if (values.hstId) {
        nationalIds?.push({
          id: values.hstId,
          type: NationalIdentificationValueTypeEnum.HstId,
        });
      }
      if (values.qstId) {
        nationalIds?.push({
          id: values.qstId,
          type: NationalIdentificationValueTypeEnum.QstId,
        });
      }
      if (values.sin) {
        nationalIds?.push({
          id: values.sin,
          type: NationalIdentificationValueTypeEnum.Sin,
        });
      }
    } else {
      if (values.ein) {
        nationalIds?.push({
          id: values.ein,
          type: NationalIdentificationValueTypeEnum.Ein,
        });
      }
    }

    const data: UpdateParticipantRequest = {
      emailAddress: values.emailAddress,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      commissionDocumentRecipient:
        values.commissionDocumentRecipient ??
        !!participant?.commissionDocumentRecipient,
      payer: !!participant?.commissionDocumentPayer,
      passThrough: values.passThrough ?? !!participant?.passThrough,
      paidByReal: values.paidByReal ?? !!participant?.paidByReal,
      personalDeal:
        values.personalDeal ??
        (participant && 'personalDeal' in participant!
          ? !!participant.personalDeal
          : false),
      participantRole: values.participantRole
        ?.value as UpdateParticipantRequestParticipantRoleEnum,
      address: isAutoComplete
        ? values.autoAddress.formatted_address
        : values.address,
      paidViaBusinessEntity:
        participant?.externalParticipant && values.company
          ? {
              name: values.company,
              nationalIds,
            }
          : undefined,
      notes: values.notes,
    };

    if (canUpdateProTeamLeaderSplits()) {
      if (values.proLeaders.length > 0) {
        const splitData: any = (values.proLeaders || []).map(
          (item: LeaderSplitDto) => {
            return {
              commission: {
                commissionAmount: item?.commission?.percentEnabled
                  ? null
                  : {
                      amount: +item?.commission?.commissionAmount?.amount!,
                      currency: transaction?.currency!,
                    },
                commissionPercent: item?.commission?.percentEnabled
                  ? +item?.commission?.commissionPercent!
                  : null,
                percentEnabled: item?.commission?.percentEnabled,
              },
              teamLeaderParticipantId: item.teamLeaderParticipantId,
            };
          },
        );

        if (!!splitData?.length) {
          await dispatch(
            updateProTeammateCommissionSplits(
              transaction?.id!,
              participant?.id!,
              splitData,
            ),
          );
        }
      }

      if (
        isValidFee(values.teamPreCapFee) &&
        isValidFee(values.teamPostCapFee)
      ) {
        await dispatch(
          updateProTeammateTeamFees(transaction?.id!, participant?.id!, {
            preCapTeamFee: {
              amount: +values.teamPreCapFee,
              currency: (transaction?.currency! as unknown) as MoneyValueCurrencyEnum,
            },
            postCapTeamFee: {
              amount: +values.teamPostCapFee,
              currency: (transaction?.currency! as unknown) as MoneyValueCurrencyEnum,
            },
          }),
        );
      }

      if (values.teamSplits.length > 0) {
        const splitsByLeader: LeaderFeeSplits[] = values.teamSplits.map(
          (split) => {
            return {
              leaderParticipantId: split.leaderId,
              splits: [
                {
                  feeType: FeeSplitFeeTypeEnum.RealSplit,
                  paidByLeader: split.realSplit,
                },
                {
                  feeType: FeeSplitFeeTypeEnum.BeopFee,
                  paidByLeader: split.beopFee,
                },
                {
                  feeType: FeeSplitFeeTypeEnum.BrokerageFee,
                  paidByLeader: split.realFee,
                },
                {
                  feeType: FeeSplitFeeTypeEnum.TransactionFee,
                  paidByLeader: split.transactionFee,
                },
              ],
            };
          },
        );

        await dispatch(
          updateProTeammateSplitFees(transaction?.id!, participant?.id!, {
            splitsByLeader,
          }),
        );
      }
    }
    const success = await dispatch(
      updateTransactionParticipant(participant?.id!, transaction.id!, data),
    );

    if (
      !transaction?.listing &&
      instantPaymentEnabled &&
      isEligible &&
      participant &&
      'instantPaymentEligibleTeamMember' in participant
    ) {
      await dispatch(
        updateInstantPaymentEligibility(transaction?.id!, participant?.id!, {
          instantPaymentEligible: values.instantPaymentEligible,
        }),
      );
    }

    if (success) {
      dispatch(
        showSuccessToast(
          participant?.commissionDocumentPayer
            ? 'Commission Payer Updated!'
            : 'Participant Updated!',
        ),
      );
      if (values.taxWithheld !== undefined) {
        await dispatch(
          toggleTaxWithheld(transaction.id!, participant?.id!, {
            taxWithheld: values.taxWithheld,
          }),
        );
      }

      onClose();
    }
  };

  const w9File = useCallback(async () => {
    setW9FileResponse(defaultw9FileResponse);

    // Force refresh on isOpen to handle bug where you upload a W9, close the modal, and
    // then reopen it for the same user.
    if (isOpen && participant?.externalParticipant) {
      try {
        const { data } = await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).getW9Url(participant?.id!);

        setW9FileResponse({
          name: 'w9File',
          data,
          loading: false,
        });
      } catch (e) {
        setW9FileResponse({
          name: 'w9File',
          error: ErrorService.getErrorCode(e),
          loading: false,
        });
      }
    }
  }, [dispatch, participant?.externalParticipant, participant?.id, isOpen]);

  useEffect(() => {
    w9File();
  }, [w9File]);

  const w9FileName =
    w9FileResponse.data &&
    getFileNameFromUrl(w9FileResponse.data as string) !== 'null'
      ? getFileNameFromUrl(w9FileResponse.data as string)
      : '';

  const Country =
    transaction.country === TransactionResponseCountryEnum.Canada
      ? 'CANADA'
      : 'UNITED_STATES';

  return (
    <ZenSidebarModal
      title={
        !participant?.commissionDocumentPayer
          ? getParticipantScreenTitle(
              participant,
              participant?.externalParticipant,
              readOnly,
            )
          : 'Commission Payer'
      }
      subtitle={
        !participant?.commissionDocumentPayer
          ? transaction.address?.oneLine
          : undefined
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='p-4 mb-20'>
        <div>
          <ZenControlledSelectInput<EditTransactionFormData, 'participantRole'>
            name='participantRole'
            control={control}
            label='Role'
            placeholder={
              !participant?.commissionDocumentPayer
                ? 'Select Role'
                : 'Select Commission Payer'
            }
            options={getValidParticipantRoles(
              transaction.transactionType!,
              transaction?.transactionOwner?.represents,
              participant?.externalParticipant
                ? ParticipantType.EXTERNAL_ENTITY
                : ParticipantType.AGENT,
            )}
            readOnly={readOnly || isAdminRole}
            isRequired
          />
        </div>
        {!participant?.externalParticipant ? (
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<EditTransactionFormData, 'agentName'>
              control={control}
              name='agentName'
              placeholder='Search'
              label='Name of Participant'
              disabled
              fetchData={async (search, page) => {
                try {
                  const searchResponse = await searchActiveAgents(
                    page,
                    [Country],
                    50,
                    search,
                    undefined,
                  );
                  const options: AsyncSelectOptionReactElement[] = searchResponse.data?.map(
                    (resp) => ({
                      value: `${resp.id}`,
                      label: (
                        <AvatarLabelComponent
                          avatar={resp?.avatar!}
                          firstName={resp?.firstName!}
                          lastName={resp?.lastName!}
                          emailAddress={resp?.emailAddress!}
                          administrativeAreas={resp?.administrativeAreas?.map(
                            (e) => {
                              if (!e.stateOrProvince) {
                                return 'N/A';
                              }
                              return STATE_OR_PROVINCE_ABBREVIATIONS[
                                e.stateOrProvince
                              ];
                            },
                          )}
                        />
                      ),
                    }),
                  );

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search for registered agents in edit transaction participant form',
                    e,
                    {
                      search: {
                        term: search,
                        country: transaction?.country,
                        page,
                      },
                    },
                  );
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an agent. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              rules={{
                required: 'Agent is required',
              }}
              isRequired
            />
          </div>
        ) : (
          <div>
            <div className='flex flex-row mt-5 space-x-2'>
              <ZenControlledTextInput<EditTransactionFormData, 'firstName'>
                control={control}
                label='First name'
                name='firstName'
                placeholder='E.g. John'
                rules={{
                  minLength: {
                    value: 2,
                    message: 'Must be at least 2 characters',
                  },
                  ...validateFields(
                    RequiredField.FIRST_NAME,
                    transaction,
                    watchFields,
                  ),
                }}
                readOnly={readOnly}
                isRequired
              />
              <ZenControlledTextInput<EditTransactionFormData, 'lastName'>
                control={control}
                label='Last name'
                name='lastName'
                placeholder='E.g. Dow'
                rules={{
                  minLength: {
                    value: 2,
                    message: 'Must be at least 2 characters',
                  },
                  ...validateFields(
                    RequiredField.LAST_NAME,
                    transaction,
                    watchFields,
                  ),
                }}
                readOnly={readOnly}
                isRequired
              />
            </div>
            <div className='mt-5'>
              <ZenControlledTextInput<EditTransactionFormData, 'company'>
                control={control}
                label='Business name'
                subLabel='(I.e. Brokerage or company name)'
                name='company'
                placeholder='E.g. Dream Home LLC.'
                rules={{
                  ...validateFields(
                    RequiredField.COMPANY_NAME,
                    transaction,
                    watchFields,
                  ),
                }}
                readOnly={readOnly}
                isRequired
              />
            </div>
            {!isCanada ? (
              <div className='mt-5'>
                <ZenControlledTextInput<EditTransactionFormData, 'ein'>
                  control={control}
                  name='ein'
                  label='EIN'
                  placeholder='E.g. 12-1234567'
                  rules={{
                    ...getIdValidations(
                      NationalIdentificationValueTypeEnum.Ein,
                    ),
                  }}
                  readOnly={readOnly}
                />
              </div>
            ) : (
              <div className='flex flex-row mt-5 space-x-2'>
                <ZenControlledTextInput<EditTransactionFormData, 'gstId'>
                  control={control}
                  name='gstId'
                  label='GST'
                  placeholder='E.g. 123456789 RT 0001'
                  rules={{
                    ...getIdValidations(
                      NationalIdentificationValueTypeEnum.GstId,
                    ),
                  }}
                  readOnly={readOnly}
                />
                <ZenControlledTextInput<EditTransactionFormData, 'Bn'>
                  control={control}
                  name='Bn'
                  label='Business number'
                  placeholder='E.g. 123456789'
                  rules={{
                    ...getIdValidations(NationalIdentificationValueTypeEnum.Bn),
                  }}
                  readOnly={readOnly}
                />
              </div>
            )}
            <div className='mt-5'>
              <ZenControlledEmailInput<EditTransactionFormData, 'emailAddress'>
                control={control}
                label='Email'
                subLabel='(Required if receiving electronic documents)'
                name='emailAddress'
                placeholder='E.g. John@example.com'
                rules={{
                  required: {
                    value: !!commissionDocumentRecipient,
                    message:
                      'Email is required if receiving electronic documents',
                  },
                  ...EMAIL_VALIDATIONS,
                  ...validateFields(
                    RequiredField.EMAIL,
                    transaction,
                    watchFields,
                  ),
                }}
                isRequired={
                  isAddParticipantLabelRequired(transaction, participantRole) ||
                  !!commissionDocumentRecipient
                }
                readOnly={readOnly}
              />
            </div>
            <div className='mt-5'>
              <ZenControlledPhoneNumberInput<
                EditTransactionFormData,
                'phoneNumber'
              >
                control={control}
                label='Phone number'
                name='phoneNumber'
                placeholder='E.g. +1 (702) 123-4567'
                rules={{
                  ...PHONE_NUMBER_VALIDATIONS,
                }}
                readOnly={readOnly}
              />
            </div>
            <div className='relative mt-8'>
              {isAutoComplete && !readOnly ? (
                <ZenControlledGoogleAutocompleteSearchInput<
                  EditTransactionFormData,
                  'autoAddress'
                >
                  control={control}
                  shouldUnregister={false}
                  name='autoAddress'
                  label='Search Address'
                  subLabel='(Source by Google)'
                  placeholder='E.g. 1st St. New York, NY 10010'
                  rules={{
                    ...GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
                  }}
                  isRequired={isAddParticipantLabelRequired(
                    transaction,
                    participantRole,
                  )}
                />
              ) : (
                <ZenControlledTextInput<EditTransactionFormData, 'address'>
                  control={control}
                  label='Address'
                  subLabel={
                    !isCanada
                      ? '(Must match address on W9 Form, if uploaded)'
                      : ''
                  }
                  name='address'
                  placeholder={ADDRESS_PLACEHOLDER}
                  rules={{
                    ...validateFields(
                      RequiredField.ADDRESS,
                      transaction,
                      watchFields,
                    ),
                  }}
                  isRequired={isAddParticipantLabelRequired(
                    transaction,
                    participantRole,
                  )}
                  shouldUnregister={false}
                  readOnly={readOnly}
                />
              )}
              {!readOnly && (
                <div className='absolute -top-3 right-0'>
                  <IconButton
                    label={
                      isAutoComplete
                        ? 'Enter address manually'
                        : 'Search Address'
                    }
                    variant='none'
                    buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
                    leftIcon={<FontAwesomeIcon icon={faArrowUpArrowDown} />}
                    onClick={() => {
                      setIsAutoComplete(!isAutoComplete);
                    }}
                  />
                </div>
              )}
            </div>
            <div className='mt-5'>
              {!isCanadaTransaction(transaction) && (
                <AuthorizationContainer asyncResponse={w9FileResponse}>
                  <ResourceContainer
                    loading={w9FileResponse.loading}
                    isEmpty={false}
                    resourceName='W9 Form'
                  >
                    <ZenControlledFileUploadInput<
                      EditTransactionFormData,
                      'w9form'
                    >
                      name='w9form'
                      control={control}
                      label='Upload W9 Form'
                      placeholder={w9FileName || 'E.g. W9.pdf'}
                      accept='.pdf'
                      rightAction={
                        w9FileName
                          ? {
                              text: 'View W9',
                              onClick() {
                                window.open(
                                  w9FileResponse.data! as string,
                                  '_blank',
                                );
                              },
                            }
                          : undefined
                      }
                      rules={{
                        ...FILE_VALIDATIONS,
                      }}
                      readonly={readOnly}
                    />
                  </ResourceContainer>
                </AuthorizationContainer>
              )}
            </div>

            {isCanada && (
              <div className='mt-5'>
                <label className='text-base font-primary-medium text-dark'>
                  Other Tax Id. Numbers
                </label>
                <div className='mt-2'>
                  <ZenControlledTextInput<EditTransactionFormData, 'sin'>
                    control={control}
                    name='sin'
                    label='Social insurance number (SIN)'
                    placeholder='E.g. 123-123-1234'
                    rules={{
                      ...getIdValidations(
                        NationalIdentificationValueTypeEnum.Sin,
                      ),
                    }}
                    readOnly={readOnly}
                  />
                </div>
                <div className='mt-5'>
                  <ZenControlledTextInput<EditTransactionFormData, 'hstId'>
                    control={control}
                    name='hstId'
                    label='Harmonized sales tax (HST)'
                    placeholder='E.g. 123456789 RT 00002'
                    rules={{
                      ...getIdValidations(
                        NationalIdentificationValueTypeEnum.HstId,
                      ),
                    }}
                    readOnly={readOnly}
                  />
                </div>
                <div className='mt-5'>
                  <ZenControlledTextInput<EditTransactionFormData, 'pstId'>
                    control={control}
                    name='pstId'
                    label='Provincial sales tax (PST)'
                    placeholder='E.g. PST-1234-1234'
                    rules={{
                      ...getIdValidations(
                        NationalIdentificationValueTypeEnum.PstId,
                      ),
                    }}
                    readOnly={readOnly}
                  />
                </div>
                <div className='mt-5'>
                  <ZenControlledTextInput<EditTransactionFormData, 'qstId'>
                    control={control}
                    name='qstId'
                    label='Quebec sales tax (QST)'
                    placeholder='E.g. 123456789 TQ 1234'
                    rules={{
                      ...getIdValidations(
                        NationalIdentificationValueTypeEnum.QstId,
                      ),
                    }}
                    readOnly={readOnly}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {!participant?.commissionDocumentPayer && !transaction.listing && (
          <ZenEditTransactionToggleSection>
            {!isInternalReferralParticipant && (
              <React.Fragment>
                {participant && 'personalDeal' in participant! && (
                  <div className='p-1.5'>
                    <ZenControlledToggleInput<
                      EditTransactionFormData,
                      'personalDeal'
                    >
                      name='personalDeal'
                      control={control}
                      label='Personal Deal?'
                      readOnly={readOnly}
                    />
                  </div>
                )}
                <div className='p-1.5'>
                  <ZenControlledToggleInput<
                    EditTransactionFormData,
                    'commissionDocumentRecipient'
                  >
                    name='commissionDocumentRecipient'
                    control={control}
                    label='Receives Invoices/Comm. Documents?'
                    readOnly={readOnly}
                  />
                </div>
              </React.Fragment>
            )}
            {instantPaymentEnabled &&
              isEligible &&
              participant &&
              'instantPaymentEligibleTeamMember' in participant && (
                <div className='p-1.5'>
                  <ZenControlledToggleInput<
                    EditTransactionFormData,
                    'instantPaymentEligible'
                  >
                    name='instantPaymentEligible'
                    control={control}
                    label='Eligible for Instant Payment'
                    readOnly={readOnly}
                  />
                </div>
              )}
            {isAdmin && (
              <React.Fragment>
                <div className='p-1.5'>
                  <ZenControlledToggleInput<
                    EditTransactionFormData,
                    'paidByReal'
                  >
                    name='paidByReal'
                    control={control}
                    label='Is Single Check?'
                    readOnly={readOnly}
                  />
                </div>
                <div className='p-1.5'>
                  <ZenControlledToggleInput<
                    EditTransactionFormData,
                    'passThrough'
                  >
                    name='passThrough'
                    control={control}
                    label='Pass Through Deal?'
                    readOnly={readOnly}
                  />
                </div>
                {!!participant && 'taxWithheld' in participant && (
                  <div className='p-1.5'>
                    <ZenControlledToggleInput<
                      EditTransactionFormData,
                      'taxWithheld'
                    >
                      name='taxWithheld'
                      control={control}
                      label='Tax Withheld?'
                      readOnly={readOnly}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </ZenEditTransactionToggleSection>
        )}
        <div className='mt-5'>
          <ZenControlledTextAreaInput<EditTransactionFormData, 'notes'>
            control={control}
            label='Notes'
            name='notes'
            placeholder={`E.g. Assistant's name and number, etc.`}
            rows={3}
            readOnly={readOnly}
          />
        </div>
        {loading ? (
          <DefaultLoader />
        ) : (
          <>
            {!!fields.length && (
              <>
                {isProTeamMember && (
                  <div>
                    <div className='border-t my-5' />
                    <ZenEditProTeamFeesForm
                      control={control}
                      readOnly={readOnly || !canUpdateProTeamLeaderSplits()}
                    />
                  </div>
                )}
                <div className='pt-5'>
                  <p className='text-lg font-zen-body font-medium text-zen-dark-9 pb-4'>
                    Leaders
                  </p>
                  <ZenEditProLeaderSplitForm
                    control={control}
                    fields={fields}
                    proLeaders={proLeaders}
                    participants={transaction?.participants!}
                    update={update}
                    errors={errors}
                    readOnly={!canUpdateProTeamLeaderSplits()}
                  />
                </div>
                {splitFields.length > 0 && (
                  <div className='pt-5'>
                    <p className='text-lg font-zen-body font-medium text-zen-dark-9 pb-2'>
                      Fee Splits
                    </p>
                    <ZenEditProTeamFeeSplitForm
                      control={control}
                      fields={splitFields}
                      teamSplits={teamSplits}
                      participants={transaction?.participants!}
                      readOnly={!canUpdateProTeamLeaderSplits()}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
        {!readOnly && (
          <ZenSidebarModalActionFooterV2
            submitButtonText='Save'
            cancelButtonText='Cancel'
            onClose={onClose}
            isSubmitting={isSubmitting}
            isDisabled={isSubmitting}
            buttonType='button'
            onClick={handleSubmit(onSubmit)}
          />
        )}
      </div>
      <ZenSimpleConfirmationModal
        title='Personal Deal?'
        variant='info'
        subtitle='By selecting personal deal you acknowledge that you or your spouse are listed on the title of the property being sold/purchase or own the Entity (LLC, Trust, Corp) that owns the property being sold or purchased'
        isOpen={isPersonalDealModalOpen}
        isSubmitting={false}
        onConfirm={() => {
          setIsPersonalDealModalOpen(false);
        }}
        onClose={() => {
          setIsPersonalDealModalOpen(false);
          setValue('personalDeal', false);
        }}
      />
    </ZenSidebarModal>
  );
};

export default ZenEditTransactionParticipantFormSidebarModal;
