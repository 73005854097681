import { useState } from 'react';
import { URL_VALIDATIONS } from '../../../utils/Validations';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenControlledTextAreaInput from '../Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenCheckBox from '../ZenCheckbox';
import AnnouncementPagination from './AnnouncementPagination';
import {
  CreateAnnouncementFormData,
  CreateAnnouncementStepName,
} from './CreateAnnouncementSidebarModel';
import UploadAudioVideo from './UploadAudioVideo';
import UploadCoverImage from './UploadCoverImage';

const AnnouncementDetailsStep: StepByStepComponent<
  CreateAnnouncementFormData,
  CreateAnnouncementStepName
> = ({ form: { control, setValue, watch, trigger }, onNext }) => {
  const formValues = watch();
  const { audio, video } = formValues;
  const [willHaveLinkDetails, setWillHaveLinkDetails] = useState<boolean>(
    !!formValues.msgHyperlink,
  );

  const toggleLinkDetails = () => {
    setWillHaveLinkDetails(!willHaveLinkDetails);
    setValue('msgHyperlink', '');
    setValue('msgHyperlinkTitle', '');
  };

  return (
    <div className='w-full h-full flex flex-col min-h-full scrollbar overflow-y-auto px-4 pt-3'>
      <div className='pb-28'>
        <div className='pb-6'>
          <UploadCoverImage control={control} setValue={setValue} />
        </div>
        <div className='pb-6'>
          <div className='pb-4'>
            <ZenControlledTextInput<CreateAnnouncementFormData, 'msgTitle'>
              control={control}
              name='msgTitle'
              label='Announcement Title'
              placeholder='Announcement Title'
              rules={{
                required: 'Title is required',
              }}
              shouldUnregister={false}
              isRequired
            />
          </div>
          <div>
            <ZenControlledTextAreaInput<
              CreateAnnouncementFormData,
              'msgDetails'
            >
              control={control}
              name='msgDetails'
              label='Description'
              placeholder='Add Description'
              rows={4}
              rules={{
                required: 'Description is required',
              }}
              shouldUnregister={false}
              isRequired
            />
          </div>
        </div>
        <div className='border-b-2 border-b-zen-light-gray-2 pb-6 mb-6'>
          <div
            className='flex flex-row items-center cursor-pointer'
            data-testid='toggle-url'
            onClick={toggleLinkDetails}
          >
            <ZenCheckBox
              value={willHaveLinkDetails}
              onChange={toggleLinkDetails}
              variant='square'
              borderVariant='none'
            />
            <div className='pl-2'>
              <p className='font-zen-body font-semibold text-zen-black'>
                Add URL
              </p>
            </div>
          </div>
          {willHaveLinkDetails && (
            <div>
              <div className='pb-4 pt-2'>
                <ZenControlledTextInput<
                  CreateAnnouncementFormData,
                  'msgHyperlinkTitle'
                >
                  control={control}
                  name='msgHyperlinkTitle'
                  label='Add URL Title'
                  placeholder='Add URL Title'
                  rules={{
                    required: 'URL Title is required',
                  }}
                  shouldUnregister={false}
                  isRequired
                />
              </div>
              <div>
                <ZenControlledTextInput<
                  CreateAnnouncementFormData,
                  'msgHyperlink'
                >
                  control={control}
                  name='msgHyperlink'
                  label='Add URL Link'
                  placeholder='http://'
                  rules={{
                    required: 'URL is required',
                    ...URL_VALIDATIONS,
                  }}
                  shouldUnregister={false}
                  isRequired
                />
              </div>
            </div>
          )}
        </div>
        <div className='pb-6'>
          <UploadAudioVideo setValue={setValue} audio={audio} video={video} />
        </div>
      </div>
      <AnnouncementPagination
        onNext={onNext}
        trigger={trigger}
        formValues={formValues}
      />
    </div>
  );
};

export default AnnouncementDetailsStep;
