import ZenBankAccountsLink from '../Finance/ZenBankAccountsLink';
import ZenClearTransactionErrors from './ZenClearTransactionErrors';
import ZenEmailReset from './ZenEmailReset';
import ZenEmailStatusCheck from './ZenEmailStatusCheck';
import ZenFeatureFlags from './ZenFeatureFlags';
import ZenInvoiceCancel from './ZenInvoiceCancel';
import ZenMarkPermanentlyProducing from './ZenMarkPermanentlyProducing';
import ZenPromoteUserAsBroker from './ZenPromoteUserAsBroker';
import ZenReprocessRevshare from './ZenReprocessRevshare';
import ZenUnbanEmail from './ZenUnbanEmail';

interface ZenEngineeringComponentProps {}

const ZenEngineeringComponent: React.FC<ZenEngineeringComponentProps> = () => (
  <div className='grid grid-cols-1 grid-flow-row md:grid-cols-6 gap-4 p-4'>
    <ZenEmailReset />
    <ZenBankAccountsLink />
    <ZenInvoiceCancel />
    <ZenClearTransactionErrors />
    <ZenReprocessRevshare />
    <ZenPromoteUserAsBroker />
    <ZenMarkPermanentlyProducing />
    <ZenEmailStatusCheck />
    <ZenUnbanEmail />
    <ZenFeatureFlags />
  </div>
);

export default ZenEngineeringComponent;
