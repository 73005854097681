import React from 'react';

interface CheckInfoTableDataCellProps {
  data: string | number;
}

const ZenCheckInfoTableDataCell: React.FC<CheckInfoTableDataCellProps> = ({
  data,
}) => {
  return (
    <td className='font-primary-medium text-base text-zen-dark-9 py-3 px-2 md:px-5 text-left'>
      {data || 'N/A'}
    </td>
  );
};

export default ZenCheckInfoTableDataCell;
