import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AnnouncementResponse,
  UpdateAnnouncementRequestActionEnum,
} from '../../../openapi/mercury';
import {
  dismissAnnouncement,
  savePreviewAnnouncementId,
} from '../../../slices/AnnouncementSlice';
import { parseUrl } from '../../../utils/UrlUtils';
import ZenButton from '../ZenButton';

interface AnnouncementFooterProps {
  topAnnouncement: AnnouncementResponse;
  preview: boolean;
}

const AnnouncementFooter: React.FC<AnnouncementFooterProps> = ({
  topAnnouncement,
  preview,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAcknowledgeAnnouncement = async () => {
    if (preview) {
      dispatch(savePreviewAnnouncementId(undefined));
      return;
    }
    setIsLoading(true);
    await dispatch(
      dismissAnnouncement(
        topAnnouncement?.definitionId!,
        UpdateAnnouncementRequestActionEnum.Acknowledge,
      ),
    );
    setIsLoading(false);
  };

  return (
    <div className='w-full'>
      {!!topAnnouncement?.msgHyperlinkTitle && !!topAnnouncement?.msgHyperlink && (
        <div
          className='mb-6 p-3 flex items-center justify-between rounded-lg bg-regent-200 text-primary-blue cursor-pointer'
          onClick={() =>
            window.open(parseUrl(topAnnouncement?.msgHyperlink!)!, '_blank')
          }
        >
          <div className='flex items-center'>
            <FontAwesomeIcon icon={regular('link')} />
            <span className='ml-2 pb-0.5 font-zen-body font-semibold text-sm cursor-pointer'>
              {topAnnouncement?.msgHyperlinkTitle}
            </span>
          </div>
          <FontAwesomeIcon
            icon={regular('arrow-right-long')}
            className='ml-1'
          />
        </div>
      )}
      <div className='flex flex-col items-center justify-center'>
        <ZenButton
          label='I Acknowledge'
          variant='dark'
          isSubmitting={isLoading}
          isDisabled={isLoading}
          onClick={handleAcknowledgeAnnouncement}
        />
        <p className='mt-2 font-zen-body font-semibold text-sm text-zen-dark-6'>
          Please acknowledge you’ve read this news
        </p>
      </div>
    </div>
  );
};

export default AnnouncementFooter;
