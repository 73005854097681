import React from 'react';
import CreateBeneficiaryScreenV2 from '../RevenueShare/V2/CreateBeneficiaryScreenV2';

export interface DesignateBeneficiaryModalProps {
  isOpen: boolean;
  onClose(): void;
}

const DesignateBeneficiaryModal: React.FC<DesignateBeneficiaryModalProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'
      title='zen-modal'
    >
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full lg:p-0 scrollbar overflow-scroll'>
        <div
          className='md:w-1/2 w-full rounded-xl z-10 relative'
          aria-label='simple-modal'
        >
          <CreateBeneficiaryScreenV2 close={onClose} />
        </div>
      </div>
    </div>
  );
};

export default DesignateBeneficiaryModal;
