import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  CreateLead,
  CreateLeadStateEnum,
  LoanOfficerApi,
} from '../../openapi/atlantis';
import {
  AdministrativeAreaRequestCountryEnum,
  AgentResponseAgentStatusEnum,
} from '../../openapi/yenta';
import AnalyticsService from '../../services/AnalyticsService';
import ErrorService from '../../services/ErrorService';
import { createMortgageLead } from '../../slices/MortgageSlice';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import {
  AnalyticsEventEnum,
  AppDispatch,
  ISelectOption,
  RootState,
} from '../../types';
import { getAtlantisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { agentsTableFetchData, enumFilter } from '../../utils/TableUtils';
import {
  EMAIL_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../utils/Validations';
import AvatarLabelComponent from '../AgentReports/AvatarLabelComponent';
import AnalyticsEventOnLoad from '../Analytics/AnalyticsEventOnLoad';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledPhoneNumberInput from '../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledStateOrProvinceInput from '../Zen/Input/ZenControlledStateOrProvince';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../Zen/Input/ZenControlledToggleInput';
import ZenButton from '../Zen/ZenButton';
import ZenSidebarModal from '../Zen/ZenSidebarModal';

interface FormData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  state: CreateLeadStateEnum;
  loanOfficer?: ISelectOption;
  agent?: ISelectOption;
  agentIsRoma: boolean;
}

interface InviteBuyerSidebarModalFormProps {
  isOpen: boolean;
  onClose(): void;
  refresh(): void;
}

const InviteBuyerSidebarModalForm: React.FC<InviteBuyerSidebarModalFormProps> = ({
  isOpen,
  onClose,
  refresh,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormData>();
  const { hasMortgagePermission, isROMA } = useSelector(
    (state: RootState) => state.auth,
  );
  const [state, loanOfficer] = watch(['state', 'loanOfficer']);

  const onSubmit = async (values: FormData) => {
    const createMortgageLeadRequest: CreateLead = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.emailAddress,
      phoneNumber: values.phoneNumber,
      state: values.state,
      loanOfficerId: values.loanOfficer?.value || undefined,
      agentId: values.agent?.value || undefined,
      agentIsMlor: !!values.agentIsRoma,
    };

    const response = await dispatch(
      createMortgageLead(createMortgageLeadRequest),
    );

    if (response) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.MC_LEAD_SUBMITTED,
      );
      refresh();
      onClose();
    }
  };

  useEffect(() => {
    setValue('loanOfficer', { label: 'Search', value: '' });
    setValue('agent', { label: 'Search', value: '' });
  }, [state, setValue]);

  useEffect(() => {
    if (loanOfficer?.value) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.MC_LOAN_OFFICER_SELECTED,
      );
    }
  }, [loanOfficer?.value]);

  return (
    <ZenSidebarModal
      title='Invite Buyer to Apply'
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <div className='flex flex-col justify-between min-h-full'>
        <div className='p-4 pb-48'>
          <div>
            <ZenControlledTextInput<FormData, 'firstName'>
              name='firstName'
              control={control}
              label='First Name'
              placeholder='First Name'
              rules={{ required: 'Please enter a first name' }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'lastName'>
              name='lastName'
              control={control}
              label='Last Name'
              placeholder='Last Name'
              rules={{ required: 'Please enter a last name' }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextInput<FormData, 'emailAddress'>
              control={control}
              type='email'
              label='Email Address'
              name='emailAddress'
              placeholder='Email'
              rules={{
                required: 'Please enter an email address',
                ...EMAIL_VALIDATIONS,
              }}
              startAdornment={
                <div className='flex w-full h-full items-center justify-center pl-2'>
                  <FontAwesomeIcon
                    className='text-primary-blue'
                    icon={regular('envelope')}
                  />
                </div>
              }
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledPhoneNumberInput<FormData, 'phoneNumber'>
              control={control}
              label='Phone Number'
              name='phoneNumber'
              placeholder='Phone Number'
              rules={{
                required: 'Please enter a phone number',
                ...PHONE_NUMBER_VALIDATIONS,
              }}
              disableDropdown
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledStateOrProvinceInput<FormData, 'state'>
              name='state'
              control={control}
              setValue={setValue}
              disableChooseOption={false}
              selectedCountry={
                AdministrativeAreaRequestCountryEnum.UnitedStates
              }
              startAdornment={
                <div className='flex w-full h-full items-center justify-center pl-2'>
                  <FontAwesomeIcon
                    className='text-primary-blue'
                    icon={regular('location-dot')}
                  />
                </div>
              }
              rules={{
                required: 'Please select a state',
              }}
              isRequired
            />
          </div>
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<FormData, 'loanOfficer'>
              key={state}
              control={control}
              name='loanOfficer'
              placeholder='Search'
              label='Loan Officer'
              subLabel='Optional'
              shouldUnregister={false}
              startAdornment={
                <div className='flex w-full h-full items-center justify-center pl-2'>
                  <FontAwesomeIcon
                    className='text-primary-blue'
                    icon={regular('magnifying-glass')}
                  />
                </div>
              }
              fetchData={async (search, page) => {
                if (page !== 0) {
                  return [];
                }

                try {
                  const { data } = await new LoanOfficerApi(
                    getAtlantisConfiguration(),
                  ).getLoanOfficers(state);

                  const filteredOfficers = (data || []).filter((officer) => {
                    return (
                      officer?.firstName
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                      officer?.lastName
                        ?.toLowerCase()
                        .includes(search.toLowerCase())
                    );
                  });

                  return filteredOfficers?.map((officer) => ({
                    value: officer?.id!,
                    label: (
                      <AvatarLabelComponent
                        avatar=''
                        firstName={officer?.firstName!}
                        lastName={officer?.lastName!}
                      />
                    ),
                  }));
                } catch (e) {
                  ErrorService.notify('Unable to search for loan officers', e, {
                    data: { state },
                  });

                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for loan officers. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
            />
          </div>
          {hasMortgagePermission && !isROMA && (
            <div className='mt-5'>
              <ZenControlledAsyncSelectInput<FormData, 'agent'>
                key={state}
                control={control}
                name='agent'
                placeholder='Search'
                label='Agent'
                subLabel='Optional'
                shouldUnregister={false}
                startAdornment={
                  <div className='flex w-full h-full items-center justify-center pl-2'>
                    <FontAwesomeIcon
                      className='text-primary-blue'
                      icon={regular('magnifying-glass')}
                    />
                  </div>
                }
                fetchData={async (search, page) => {
                  try {
                    const { data } = await agentsTableFetchData({
                      search: search,
                      page: page || 0,
                      pageSize: 20,
                      filter: {
                        agentStatus: enumFilter(
                          'agentStatus',
                          AgentResponseAgentStatusEnum.Active,
                        ),
                        stateOrProvince: {
                          values: [state],
                        },
                      },
                    });

                    const filteredAgents = (data || []).filter((agent) => {
                      return (
                        agent?.firstName
                          ?.toLowerCase()
                          .includes(search?.toLowerCase()) ||
                        agent?.lastName
                          ?.toLowerCase()
                          .includes(search?.toLowerCase())
                      );
                    });

                    return filteredAgents?.map((agent) => ({
                      value: agent?.id!,
                      label: (
                        <AvatarLabelComponent
                          avatar=''
                          firstName={agent?.firstName!}
                          lastName={agent?.lastName!}
                        />
                      ),
                    }));
                  } catch (e) {
                    ErrorService.notify('Unable to search for agents', e, {
                      data: { state },
                    });

                    dispatch(
                      showErrorToast(
                        'An unexpected error occurred.',
                        'We were unable to search for agents. Please try again in a few moments or contact support.',
                      ),
                    );
                  }

                  return [];
                }}
              />
            </div>
          )}
          {hasMortgagePermission && (
            <div className='mt-5'>
              <ZenControlledToggleInput<FormData, 'agentIsRoma'>
                name='agentIsRoma'
                control={control}
                label={
                  isROMA
                    ? 'Are you the Real Originate Mortgage Advisor for this client?'
                    : 'Real Originate Mortgage Advisor'
                }
                labelClassName='text-base font-zen-body font-semibold text-zen-dark-9 mt-1'
                defaultValue={false}
              />
            </div>
          )}
        </div>
        <div className='absolute bottom-0 left-0 right-0 bg-white flex flex-row justify-end space-x-4 items-center w-full p-4 border border-gray-200'>
          <div className='w-40'>
            <ZenButton
              type='button'
              label='Cancel'
              variant='primary-outline'
              onClick={onClose}
              isFullWidth
            />
          </div>
          <div className='w-40'>
            <ZenButton
              type='submit'
              label='Invite'
              variant='primary'
              isDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              isFullWidth
            />
          </div>
        </div>
      </div>
      <AnalyticsEventOnLoad
        eventName={AnalyticsEventEnum.MC_INVITE_BUYER_CLICKED}
      />
    </ZenSidebarModal>
  );
};

export default InviteBuyerSidebarModalForm;
