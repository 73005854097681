import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { capitalize } from 'lodash';
import { getAgentIdsToFetchForTransactionBuilder } from '../hooks/useUpdateAgentIdsForTransactionBuilder';
import {
  AddDoubleEnderAgentRequest,
  AdditionalFeesRequest,
  AddParticipantRequestRoleEnum,
  AddParticipantRequestTypeEnum,
  AgentParticipantInfo,
  BuyerAndSellerRequest,
  CommissionPayerDisplayValue,
  CommissionSplitsRequest,
  FeaturesResponse,
  FmlsInfoRequest,
  LocationInfoRequest,
  PersonalDealInfoRequest,
  PriceAndDateInfoRequest,
  TitleInfo,
  TransactionBuilderControllerApi,
  TransactionBuilderResponse,
  TransactionBuilderResponseBuilderTypeEnum,
  TransactionError,
  TransactionOwnerAgentInfoRequest,
  TransactionPreviewResponse,
} from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import { AppThunk, ErrorCode, TransactionBuilderState } from '../types';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../utils/StringUtils';
import { showApiErrorModal } from './ErrorSlice';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from './ToastNotificationSlice';
import { fetchAgentsInfo } from './UserIdsSlice';

export const initialState: TransactionBuilderState = {
  transactionBuilderIdLoading: false,
  transactionBuilderId: undefined,
  transactionBuilderIdErrorCode: null,
  transactionBuilderLoading: false,
  transactionBuilder: undefined,
  transactionBuilderErrorCode: null,
  isErrorModalVisible: false,
  transactionErrors: undefined,
  commissionPayerRoles: undefined,
  loadingCommissionPayerRoles: false,
  builderFeatures: undefined,
};

const TransactionBuilderSlice = createSlice({
  name: 'transactionBuilder',
  initialState,
  reducers: {
    changeLoadingTransactionBuilderId(state, action: PayloadAction<boolean>) {
      state.transactionBuilderIdLoading = action.payload;
    },
    saveTransactionBuilderId(state, action: PayloadAction<string | undefined>) {
      state.transactionBuilderId = action.payload;
    },
    errorFetchingTransactionBuilderId(state, action: PayloadAction<ErrorCode>) {
      state.transactionBuilderIdErrorCode = action.payload;
    },
    changeLoadingTransactionBuilder(state, action: PayloadAction<boolean>) {
      state.transactionBuilderLoading = action.payload;
    },
    saveTransactionBuilderDetail(
      state,
      action: PayloadAction<TransactionBuilderResponse | undefined>,
    ) {
      state.transactionBuilder = action.payload;
    },
    errorFetchingTransactionBuilderDetail(
      state,
      action: PayloadAction<ErrorCode>,
    ) {
      state.transactionBuilderErrorCode = action.payload;
    },
    showTransactionErrorModal(
      state,
      action: PayloadAction<Array<TransactionError>>,
    ) {
      state.isErrorModalVisible = true;
      state.transactionErrors = action.payload;
    },
    closeTransactionErrorModal(state) {
      state.isErrorModalVisible = false;
      state.transactionErrors = undefined;
    },
    saveCommissionPayerRoles(
      state,
      action: PayloadAction<CommissionPayerDisplayValue[]>,
    ) {
      state.commissionPayerRoles = action.payload;
    },
    changeLoadingCommissionPayerRoles(state, action: PayloadAction<boolean>) {
      state.loadingCommissionPayerRoles = action.payload;
    },
    saveTransactionBuilderFeatures(
      state,
      action: PayloadAction<FeaturesResponse | undefined>,
    ) {
      state.builderFeatures = action.payload;
    },
  },
});

export const {
  changeLoadingTransactionBuilderId,
  saveTransactionBuilderId,
  errorFetchingTransactionBuilderId,
  changeLoadingTransactionBuilder,
  saveTransactionBuilderDetail,
  errorFetchingTransactionBuilderDetail,
  showTransactionErrorModal,
  closeTransactionErrorModal,
  changeLoadingCommissionPayerRoles,
  saveCommissionPayerRoles,
  saveTransactionBuilderFeatures,
} = TransactionBuilderSlice.actions;

export const createTransactionBuilder = (
  type: 'LISTING' | 'TRANSACTION' = 'TRANSACTION',
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  dispatch(changeLoadingTransactionBuilderId(true));
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).initializeTransactionBuilder(type);
    dispatch(saveTransactionBuilderId(data));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(
      `Unable to create an empty ${capitalizeEnum(type)} builder`,
      e,
      {},
    );
    dispatch(errorFetchingTransactionBuilderId(ErrorService.getErrorCode(e)));
    return undefined;
  } finally {
    dispatch(changeLoadingTransactionBuilderId(false));
  }
};

export const fetchTransactionBuilder = (
  transactionBuilderId: string,
  isFetchCommissionPayerRoles = false,
  loading: boolean = true,
): AppThunk<Promise<TransactionBuilderResponse | undefined>> => async (
  dispatch,
) => {
  if (loading) {
    dispatch(changeLoadingTransactionBuilder(true));
  }
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).getTransactionBuilder(transactionBuilderId);

    if (isFetchCommissionPayerRoles && !!data.agentsInfo?.representationType) {
      await dispatch(fetchCommissionPayerRoles(transactionBuilderId));
    }

    await dispatch(
      fetchAgentsInfo(getAgentIdsToFetchForTransactionBuilder(data)),
    );
    await dispatch(saveTransactionBuilderDetail(data));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreNotFound(
      'Unable to fetch the transaction builder detail',
      e,
      {
        transactionBuilderId: { transactionBuilderId },
      },
    );
    dispatch(
      errorFetchingTransactionBuilderDetail(ErrorService.getErrorCode(e)),
    );
  } finally {
    if (loading) {
      dispatch(changeLoadingTransactionBuilder(false));
    }
  }
  return undefined;
};

export const fetchCommissionPayerRoles = (
  id: string,
  isLoading: boolean = false,
): AppThunk => async (dispatch) => {
  if (isLoading) {
    dispatch(changeLoadingCommissionPayerRoles(true));
  }
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).getCommissionPayerRolesAndDisplayName(id);
    dispatch(saveCommissionPayerRoles(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch commission payer roles', e, {
      data: { id },
    });
  } finally {
    if (isLoading) {
      dispatch(changeLoadingCommissionPayerRoles(false));
    }
  }
};

export const saveLocationInfos = (
  transactionBuilderId: string,
  request: LocationInfoRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).updateLocationInfo(transactionBuilderId, request);
    dispatch(saveTransactionBuilderDetail(data));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem updating location infos. Please try again later',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to update location infos', e, {
      transaction: { transactionBuilderId },
    });
    return false;
  }
};

export const savePersonalDealInfos = (
  transactionBuilderId: string,
  request: PersonalDealInfoRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).updatePersonalDealInfo(transactionBuilderId, request);
    dispatch(saveTransactionBuilderDetail(data));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem updating personal deal infos. Please try again later',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to update personal deal infos', e, {
      transaction: { transactionBuilderId },
    });
    return false;
  }
};

export const savePriceDateInfos = (
  transactionBuilderId: string,
  request: PriceAndDateInfoRequest,
  setConditionalDateErrorCallback?: (e: any) => boolean,
): AppThunk<Promise<TransactionBuilderResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).updatePriceAndDateInfo(transactionBuilderId, request);
    dispatch(saveTransactionBuilderDetail(data));
    return data;
  } catch (e) {
    if (setConditionalDateErrorCallback) {
      const errorResponse = setConditionalDateErrorCallback(e);
      if (errorResponse) {
        return;
      }
    }
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem updating price dates infos. Please try again later',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to update price dates infos', e, {
      transaction: { transactionBuilderId },
    });
    return undefined;
  }
};

export const addCommissionPayer = (
  id: string,
  participantId?: string,
  role?: string,
  firstName?: string,
  lastName?: string,
  companyName?: string,
  email?: string,
  phoneNumber?: string,
  address?: string,
  w9Path?: string,
  file?: any,
  receivesInvoice?: boolean,
): AppThunk<Promise<TransactionBuilderResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).addCommissionPayer(
      id,
      role,
      firstName,
      lastName,
      companyName,
      email,
      phoneNumber,
      address,
      participantId,
      w9Path,
      file,
      receivesInvoice,
    );
    dispatch(saveTransactionBuilderDetail(data));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem adding commission payer infos. Please try again later',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to add commission payer infos', e, {
      transaction: { transactionBuilderId: id },
    });
    return undefined;
  }
};

export const saveParticipantInfo = (
  id: string,
  role: string,
  firstName?: string,
  lastName?: string,
  companyName?: string,
  email?: string,
  phoneNumber?: string,
  address?: string,
  agentId?: string,
  file?: any,
  receivesInvoice?: boolean,
  type?: string,
  ein?: string,
): AppThunk<Promise<TransactionBuilderResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).addParticipant(
      id,
      role!,
      firstName,
      lastName,
      companyName,
      email,
      ein,
      phoneNumber,
      address,
      undefined,
      agentId,
      file,
      receivesInvoice,
      type,
    );
    dispatch(saveTransactionBuilderDetail(data));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem adding participant info. Please try again later',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to add participant info', e, {
      transactionBuilderId: { transactionBuilderId: id },
    });
    return undefined;
  }
};

export const saveReferralInfos = (
  transactionBuilderId: string,
  role?: string,
  firstName?: string,
  lastName?: string,
  companyName?: string,
  email?: string,
  phoneNumber?: string,
  address?: string,
  agentId?: string,
  file?: any,
  receivesInvoice?: boolean,
  type?: string,
  ein?: string,
): AppThunk<Promise<TransactionBuilderResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      await getArrakisConfiguration(),
    ).addReferralInfo1(transactionBuilderId, {
      role: role as AddParticipantRequestRoleEnum,
      receivesInvoice,
      type: type as AddParticipantRequestTypeEnum,
      firstName,
      lastName,
      companyName,
      email,
      ein,
      phoneNumber,
      address,
      agentId,
    });

    if (file && data?.id) {
      await new TransactionBuilderControllerApi(
        await getArrakisConfiguration(),
      ).uploadW9ToExternalReferralParticipant(
        transactionBuilderId,
        data.id,
        file,
      );
    }

    const transactionBuilder = await dispatch(
      fetchTransactionBuilder(transactionBuilderId, false, false),
    );
    return transactionBuilder;
  } catch (e) {
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem adding referral infos. Please try again later',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to add referral infos', e, {
      transaction: { transactionBuilderId },
    });
    return undefined;
  }
};

export const saveOwnerInfos = (
  transactionBuilderId: string,
  request: TransactionOwnerAgentInfoRequest,
): AppThunk<Promise<TransactionBuilderResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).updateOwnerAgentInfo(transactionBuilderId, request);
    dispatch(saveTransactionBuilderDetail(data));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem saving owner infos. Please try again later',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to save owner infos', e, {
      transaction: { transactionBuilderId, request },
    });
  }
  return undefined;
};

export const saveCommissionSplitsInfos = (
  transactionBuilderId: string,
  request: CommissionSplitsRequest[],
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).updateCommissionSplits(transactionBuilderId, request);
    dispatch(saveTransactionBuilderDetail(data));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem saving commission splits infos. Please try again later',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to save commission splits infos', e, {
      transaction: { transactionBuilderId, request },
    });
    return false;
  }
};

export const saveBuyersAndSellersInfos = (
  transactionBuilderId: string,
  request: BuyerAndSellerRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).updateBuyerAndSellerInfo(transactionBuilderId, request);
    dispatch(saveTransactionBuilderDetail(data));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem saving buyers and sellers infos. Please try again later',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to save buyers and sellers infos', e, {
      transaction: { transactionBuilderId, request },
    });
    return false;
  }
};

export const saveAdditionalFeesInfos = (
  transactionBuilderId: string,
  request: AdditionalFeesRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).updateAdditionalFeesInfo(transactionBuilderId, request);
    dispatch(saveTransactionBuilderDetail(data));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem saving additional fees infos. Please try again later',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to save additional fees infos', e, {
      transaction: { transactionBuilderId },
    });
    return false;
  }
};

export const saveTransaction = (
  transactionBuilderId: string,
  builderType: TransactionBuilderResponseBuilderTypeEnum,
): AppThunk<Promise<TransactionPreviewResponse | undefined>> => async (
  dispatch,
) => {
  const entityName =
    builderType === TransactionBuilderResponseBuilderTypeEnum.Listing
      ? 'listing'
      : 'transaction';

  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).submitTransaction(transactionBuilderId);
    if (!!data.builderErrors?.length) {
      dispatch(showTransactionErrorModal(data.builderErrors));
    } else {
      dispatch(closeTransactionErrorModal());
      dispatch(
        showSuccessToast(`${capitalize(entityName)} created successfully.`),
      );
    }
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        `We had a problem creating ${entityName}. Please try again later`,
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify(`Unable to save ${entityName}`, e, {
      transaction: { transactionBuilderId },
    });
    return undefined;
  }
};

export const addCoAgent = (
  transactionBuilderId: string,
  req: AgentParticipantInfo,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).addCoAgent(transactionBuilderId, req);
    dispatch(saveTransactionBuilderDetail(data));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem adding a coAgent in transaction. Please try again later',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to add a coAgent in transaction', e, {
      transaction: { transactionBuilderId, req },
    });
    return false;
  }
};

export const deleteReferralInfo = (
  transactionBuilderId: string,
  participantId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).deleteReferralInfo(transactionBuilderId, participantId);
    await dispatch(saveTransactionBuilderDetail(data));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem deleting a referral info in transaction. Please try again later',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to delete a referral info in transaction', e, {
      transaction: { transactionBuilderId, participantId },
    });
    return false;
  }
};

export const deleteCoAgentInfo = (
  transactionBuilderId: string,
  coAgentId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).deleteCoAgent(transactionBuilderId, coAgentId);
    dispatch(saveTransactionBuilderDetail(data));
    return true;
  } catch (e) {
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem deleting a coAgent info in transaction. Please try again later',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to delete a coAgent info in transaction', e, {
      transaction: { transactionBuilderId, coAgentId },
    });
    return false;
  }
};

export const addOpCityInfo = (
  transactionBuilderId: string,
  opcity: boolean,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).addOpcity(transactionBuilderId, opcity);
    dispatch(saveTransactionBuilderDetail(data));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem adding opcity info. Please try again later',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to add opcity info in transaction', e, {
      transaction: { transactionBuilderId, opcity },
    });
    return false;
  }
};

export const addRealTitle = (
  transactionBuilderId: string,
  request: TitleInfo,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).updateTitleInfo(transactionBuilderId, request);
    dispatch(saveTransactionBuilderDetail(data));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToastForErrorCode(
        'We encountered an error while adding the Real Title. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
    ErrorService.notify('Unable to add the Real Title.', e, {
      transaction: { transactionBuilderId },
    });
    return false;
  }
};

export const getTransactionBuilderFeatures = (
  transactionBuilderId: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).getFeatures1(transactionBuilderId);
    dispatch(saveTransactionBuilderFeatures(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(
      'Unable to get transaction builder optional features',
      e,
      {
        transactionBuilder: { transactionBuilderId },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        'We encountered an error while getting optional features. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const addDoubleEnderAgent = (
  transactionBuilderId: string,
  req: AddDoubleEnderAgentRequest,
): AppThunk<Promise<TransactionBuilderResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).addDoubleEnderAgent1(transactionBuilderId, req);
    dispatch(saveTransactionBuilderDetail(data));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to add double ender agent', e, {
      transactionBuilder: { transactionBuilderId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We encountered an error while adding double ender agent. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
    return undefined;
  }
};

export const saveFMLSInfo = (
  transactionBuilderId: string,
  fmlsInfoRequest: FmlsInfoRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).updateFmlsInfo1(transactionBuilderId, fmlsInfoRequest);
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update FMLS info', e, {
      transactionBuilder: { transactionBuilderId },
      fmlsInfoRequest: { fmlsInfoRequest },
    });
    return false;
  }
};

export default TransactionBuilderSlice.reducer;
