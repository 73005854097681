import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import {
  faChevronDown,
  faChevronUp,
  faFile,
} from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CellProps, Column } from 'react-table';
import {
  CommissionDocumentControllerApi,
  CommissionDocumentResponse,
  CommissionDocumentResponseStatusEnum,
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { AgentResponse } from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { RootState } from '../../../types';
import { displayFormattedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { isGenerateTradeRecordSheetDisabled } from '../../../utils/TransactionHelper';
import AdminOnly from '../../auth/AdminOnly';
import ZenActivateCommissionDocumentConfirmationModal from '../../CommissionDocument/ZenActivateCommissionDocumentConfirmationModal';
import Hover from '../../Hover';
import ZenCommissionDocumentStatusCell from '../../table/Cells/ZenCommissionDocumentResponseStatusCell';
import ZenViewActionButtonCell from '../../table/Cells/ZenViewActionButtonCell';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../../Zen/ZenButton';
import ZenFixedDataTable from '../../Zen/ZenFixedDataTable';
import ZenUserPill from '../../Zen/ZenUserPill';

interface ZenCommissionDocumentsTableProps {
  transaction: TransactionResponse;
  commissionDocuments: CommissionDocumentResponse[];

  fetchData(): Promise<void>;

  authUserDetail: AgentResponse;
  isAdminOrTeamLeader: boolean;
  isTradeRecordSheet: boolean;
  isCDA: boolean;
  isTransactionOwner: boolean;
}

const ZenCommissionDocumentsTable: React.FC<ZenCommissionDocumentsTableProps> = ({
  transaction,
  commissionDocuments,
  authUserDetail,
  isAdminOrTeamLeader,
  isTransactionOwner,
  fetchData,
  isTradeRecordSheet,
  isCDA,
}) => {
  const dispatch = useDispatch();
  const [activateCda, setActivateCda] = useState<CommissionDocumentResponse>();
  const [
    generateTradeRecordSheet,
    setGenerateTradeRecordSheet,
  ] = useState<boolean>(false);
  const [transitionLoading, setTransitionLoading] = useState<boolean>(false);
  const {
    auth: { isBroker },
  } = useSelector((state: RootState) => state);

  const handleGenerateTradeRecordSheet = async () => {
    setTransitionLoading(true);
    try {
      await new CommissionDocumentControllerApi(
        getArrakisConfiguration(),
      ).generateTradeRecordSheetByTransactionId(transaction.id!);
      dispatch(showSuccessToast('Trade record sheet generated successfully.'));
      fetchData();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notifyIgnoreAuthErrors(
        'Unable to generate trade record sheet',
        e,
      );
      dispatch(
        showErrorToast(
          'We had a problem generating trade record sheet.',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setTransitionLoading(false);
      setGenerateTradeRecordSheet(false);
    }
  };

  const handleParticipantPDFDownload = async (s3path: string) => {
    try {
      const { data } = await new CommissionDocumentControllerApi(
        getArrakisConfiguration(),
      ).getPdfUrlForTradeRecordSheet(s3path);
      window.open(data, '_blank');
    } catch (e) {
      ErrorService.notify(
        'Unable to download participant trade record sheet',
        e,
        { data: { s3Path: s3path } },
      );
      dispatch(
        showErrorToast(
          'Something went wrong while downloading pdf, please try again later.',
        ),
      );
    }
  };

  const handleMainPDFDownload = async (documentId: string) => {
    try {
      const { data } = await new CommissionDocumentControllerApi(
        getArrakisConfiguration(),
      ).getPreSignedUrl2(documentId);
      window.open(data, '_blank');
    } catch (e) {
      ErrorService.notify('Unable to download commission document', e, {
        data: { id: documentId },
      });
      dispatch(
        showErrorToast(
          'Something went wrong while downloading pdf, please try again later.',
        ),
      );
    }
  };

  const columns: Array<Column<CommissionDocumentResponse>> = useMemo(
    () => [
      {
        Header: 'DATE CREATED',
        accessor: 'createdAt',
        Cell: ({ value }) =>
          DateTime.fromMillis(value!).toFormat('LLL dd, yyyy'),
        disableSortBy: true,
      },
      {
        Header: 'DOCUMENT CODE',
        accessor: 'id',
        Cell: ({ value }) => value,
        disableSortBy: true,
      },
      {
        Header: 'REVISIONS',
        accessor: 'revision',
        Cell: ({ value }) => value,
        disableSortBy: true,
      },
      {
        Header: 'CLOSING DATE',
        accessor: 'anticipatedClosingDate',
        Cell: ({ value }) => DateTime.fromISO(value!).toFormat('LLL dd, yyyy'),
        disableSortBy: true,
      },
      {
        Header: 'TOTAL COMMISSION',
        accessor: 'grossCommission',
        Cell: ({
          value,
          row: { original },
        }: CellProps<CommissionDocumentResponse>) => {
          if (isAdminOrTeamLeader || isTransactionOwner || isBroker) {
            return displayFormattedAmountWithCurrency(value);
          }

          const user = original.payeesPaidByReal?.find(
            (p) => p.yentaUserId === authUserDetail?.id,
          )!;

          return user
            ? displayFormattedAmountWithCurrency(user.amount)
            : displayFormattedAmountWithCurrency({
                amount: 0,
                currency: value.currency,
              });
        },
        disableSortBy: true,
      },
      {
        Header: 'EXPECTED PAYMENT TO REAL',
        accessor: 'expectedPaymentToReal',
        Cell: ({ value }) => displayFormattedAmountWithCurrency(value),
        disableSortBy: true,
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        Cell: ({ value }) => (
          <ZenCommissionDocumentStatusCell status={value!} />
        ),
        disableSortBy: true,
      },
      {
        Header: 'ACTION',
        id: 'actions',
        Cell: ({ row }: CellProps<CommissionDocumentResponse>) => {
          const user = row.original.payeesPaidByReal?.find(
            (p) => p.yentaUserId === authUserDetail?.id,
          )!;

          const isActivateButtonVisible =
            row.original.status ===
              CommissionDocumentResponseStatusEnum.Invalidated &&
            transaction?.lifecycleState?.state ===
              TransactionLifecycleStateValueStateEnum.CommissionDocumentSent;

          const isDownloadButtonVisible =
            (isAdminOrTeamLeader ||
              isTransactionOwner ||
              isBroker ||
              !!user?.s3Path) &&
            row.original?.status !==
              CommissionDocumentResponseStatusEnum.Closing;

          return (
            <div className='flex flex-row flex-nowrap space-x-2'>
              <ZenViewActionButtonCell
                onClick={() => {
                  window.open(
                    isTradeRecordSheet
                      ? isAdminOrTeamLeader || isTransactionOwner || isBroker
                        ? `/trade-record-sheets/${row.original.id}`
                        : `/trade-record-sheets/${row.original.id}/participants/${user?.id}`
                      : isCDA
                      ? `/cda/${row.original.id}`
                      : `/invoice/${row.original.id}`,
                    '_blank',
                  );
                }}
              />
              {isDownloadButtonVisible && (
                <ZenButton
                  LeftIconComponent={
                    <FontAwesomeIcon icon={faFile} size='lg' className='mr-1' />
                  }
                  variant='primary-outline'
                  label='Download'
                  onClick={async () => {
                    if (isAdminOrTeamLeader || isTransactionOwner || isBroker) {
                      await handleMainPDFDownload(row.original.id!);
                    } else {
                      await handleParticipantPDFDownload(user.s3Path!);
                    }
                  }}
                />
              )}
              <AdminOnly>
                {isActivateButtonVisible && (
                  <ZenButton
                    LeftIconComponent={
                      <FontAwesomeIcon
                        icon={faCheck}
                        size='lg'
                        className='mr-1'
                      />
                    }
                    label='Activate'
                    onClick={() => setActivateCda(row.original)}
                  />
                )}
                {isTradeRecordSheet && (
                  <ZenButton
                    LeftIconComponent={
                      row.isExpanded ? (
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          title='Shrink'
                          size='sm'
                          className='px-1.5'
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          title='Expand'
                          size='sm'
                          className='px-1.5'
                        />
                      )
                    }
                    onClick={() => row.toggleRowExpanded()}
                  />
                )}
              </AdminOnly>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isAdminOrTeamLeader,
      isTransactionOwner,
      isBroker,
      authUserDetail?.id,
      isTradeRecordSheet,
      isCDA,
    ],
  );

  return (
    <div>
      <ZenFixedDataTable<CommissionDocumentResponse>
        key={commissionDocuments.length}
        columns={columns}
        data={commissionDocuments}
        resourceName={
          isTradeRecordSheet ? 'Trade Record Sheet' : 'Commission Document'
        }
        hidePagination
        hideFilters
        renderToggleRowComponent={(row) => (
          <div className='w-full scrollbar overflow-x-auto m-5'>
            <table className='relative table-auto w-full'>
              <thead>
                <tr>
                  <th className='font-zen-body font-normal align-top p-2 whitespace-nowrap'>
                    Participants
                  </th>
                  <th className='font-zen-body font-normal align-top p-2 whitespace-nowrap'>
                    Role
                  </th>
                  <th className='font-zen-body font-normal align-top p-2 whitespace-nowrap'>
                    Amount
                  </th>
                  <th className='font-zen-body font-normal align-top p-2 whitespace-nowrap'>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {row.original.payeesPaidByReal?.map((p) => {
                  const isDownloadButtonVisible =
                    !!p.s3Path &&
                    row.original?.status !==
                      CommissionDocumentResponseStatusEnum.Closing;

                  return (
                    <tr key={p.id} className='text-center'>
                      <td className='p-2 align-middle'>
                        <ZenUserPill name={p.fullName!} imageUrl='' />
                      </td>
                      <td className='p-2 align-middle'>
                        {capitalizeEnum(p.commissionDocumentRole!)}
                      </td>
                      <td className='p-2 align-middle'>
                        {displayFormattedAmountWithCurrency(p.amount)}
                      </td>
                      <td className='p-2 align-middle'>
                        <div className='flex flex-row justify-center space-x-2'>
                          <ZenViewActionButtonCell
                            onClick={() => {
                              window.open(
                                `/trade-record-sheets/${row.original.id}/participants/${p.id}`,
                                '_blank',
                              );
                            }}
                          />
                          {isDownloadButtonVisible && (
                            <ZenButton
                              LeftIconComponent={
                                <FontAwesomeIcon
                                  icon={faFile}
                                  size='lg'
                                  className='mr-1'
                                />
                              }
                              variant='primary-outline'
                              label='Download'
                              onClick={() => {
                                handleParticipantPDFDownload(p.s3Path!);
                              }}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        RightComponent={
          isTradeRecordSheet ? (
            <Hover
              config={{
                trigger: isGenerateTradeRecordSheetDisabled(transaction)
                  ? 'hover'
                  : null,
                placement: 'top',
              }}
              hoverComponent={
                <div className='font-zen-body font-normal text-sm text-dark px-2'>
                  The transaction&apos;s commissions must be validated before a
                  trade record sheet can be generated
                </div>
              }
            >
              <ZenButton
                label='Generate Trade Record Sheet'
                variant='primary'
                onClick={() => setGenerateTradeRecordSheet(true)}
                isDisabled={isGenerateTradeRecordSheetDisabled(transaction)}
              />
            </Hover>
          ) : undefined
        }
      />
      <ZenActivateCommissionDocumentConfirmationModal
        isOpen={!!activateCda}
        onClose={(fetch) => {
          setActivateCda(undefined);
          if (fetch) {
            fetchData();
          }
        }}
        commissionDocumentId={activateCda?.id!}
        isTradeRecordSheet={isTradeRecordSheet}
      />
      <ZenConfirmationModal
        isOpen={generateTradeRecordSheet}
        title='Generate Trade Record Sheet'
        subtitle='If broker has approved this transaction, are you ready for trade record sheet generation?'
        onClose={() => setGenerateTradeRecordSheet(false)}
        isSubmitting={transitionLoading}
        cancelButtonText='Cancel'
        confirmButtonText='Yes, please continue'
        onConfirm={handleGenerateTradeRecordSheet}
      />
    </div>
  );
};

export default ZenCommissionDocumentsTable;
