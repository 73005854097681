import {
  Controller,
  FieldPath,
  UseControllerProps,
  FieldValues,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps } from '../types';
import { cn } from '../utils/classUtils';
import FormErrorMessage from './FormErrorMessage';

export type ControlledEmailInputV7Type =
  | 'text'
  | 'email'
  | 'number'
  | 'hidden'
  | 'date'
  | 'datetime-local'
  | 'image'
  | 'month'
  | 'password'
  | 'search'
  | 'tel'
  | 'time'
  | 'url'
  | 'week';

export interface ControlledEmailInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  type?: ControlledEmailInputV7Type;
  label?: string;
  subLabel?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  isRequired?: boolean;
}

const ControlledEmailInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  type = 'text',
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  tooltipIndex,
  shouldUnregister = true,
  isRequired = false,
  ...rest
}: ControlledEmailInputV7Props<TFieldValues, TName>) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <div className='w-full space-y-1'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              <span>{label}</span>
              {!!subLabel && (
                <span className='ml-1 text-sm text-gray-400 font-primary-regular'>
                  {subLabel}
                </span>
              )}
              {isRequired && <span className='text-error'>*</span>}
            </label>
          )}
          <input
            id={name}
            value={value}
            onChange={(e) => {
              onChange(e.target.value.trim());
            }}
            onBlur={onBlur}
            type={type}
            name={name}
            readOnly={readOnly}
            placeholder={placeholder}
            className={cn(
              'appearance-none p-1.5 border-gray-200 rounded bg-white focus:outline-none focus:ring-0 w-full',
              {
                'bg-gray-50': readOnly,
              },
              {
                'border-none': noBorder,
              },
            )}
            data-tooltip-index={tooltipIndex}
          />
          {error && !hideErrorMessage && (
            <FormErrorMessage message={error.message} />
          )}
        </div>
      )}
    />
  );
};

export default ControlledEmailInputV7;
