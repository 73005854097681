import {
  faAddressBook,
  faPlus,
  faUserPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isNumber from 'lodash/isNumber';
import values from 'lodash/values';
import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form-v7';
import { MdOutlineDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faArrowUpArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { BuyerAndSellerRequest } from '../../../../openapi/arrakis';
import {
  DirectoryEntryResponseTypeEnum,
  DirectoryPersonResponse,
  DirectoryPersonResponseRoleEnum,
  DirectoryVendorResponse,
} from '../../../../openapi/yenta';
import {
  DirectoryCommonEntityResponse,
  useDirectoryAddress,
} from '../../../../query/directory/useDirectory';
import AnalyticsService from '../../../../services/AnalyticsService';
import { saveBuyersAndSellersInfos } from '../../../../slices/TransactionBuilderSlice';
import {
  AnalyticsEventEnum,
  AppDispatch,
  CreateTransactionParticipantType,
  RootState,
  YesNoType,
} from '../../../../types';
import { capitalizeEnum, formatAddress } from '../../../../utils/StringUtils';
import { isSaleTransaction } from '../../../../utils/TransactionHelper';
import {
  EMAIL_VALIDATIONS,
  GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../../utils/Validations';
import ZenRoleDirectoryForm from '../../../Directory/ZenRoleDirectoryForm';
import IconButton from '../../../IconButton';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenContactListSidebar from '../../../Directory/ZenContactListSidebar';
import ZenControlledCheckboxInput from '../../Input/ZenControlledCheckboxInput';
import ZenControlledEmailInput from '../../Input/ZenControlledEmailInput';
import ZenControlledGoogleAutocompleteSearchInput from '../../Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledPhoneNumberInput from '../../Input/ZenControlledPhoneNumberInput';
import ZenControlledRadioInput from '../../Input/ZenControlledRadioInput';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenButton from '../../ZenButton';
import {
  CreateTransactionFormState,
  CreateTransactionStepName,
  Match,
} from './ZenCreateTransactionSteps';
import withCreateTransactionProgress from './ZenwithCreateTransactionProgress';

export const SELLER_FIELD_PREFIX = 'sellerOrLandlord';
export const BUYER_FIELD_PREFIX = 'buyerOrTenant';
export type SellerFieldArrayPrefix = typeof SELLER_FIELD_PREFIX;
export type BuyerFieldArrayPrefix = typeof BUYER_FIELD_PREFIX;
export type FieldArrayPrefix =
  | typeof SELLER_FIELD_PREFIX
  | typeof BUYER_FIELD_PREFIX;

const ZenTransactionBuyerAndSellerStep: StepByStepComponent<
  CreateTransactionFormState,
  CreateTransactionStepName
> = ({
  form: { control, watch, trigger, setValue, getValues },
  onPrevious,
  onNext,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [roleSibebarOpen, setRoleSidebarOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined,
  );
  const [currentFieldArrayPrefix, setCurrentFieldArrayPrefix] = useState<
    FieldArrayPrefix | undefined
  >(undefined);
  const [currentParticipantType, setCurrentParticipantType] = useState<
    CreateTransactionParticipantType | undefined
  >(undefined);
  const [currentFormContact, setCurrentFormContact] = useState<
    DirectoryCommonEntityResponse | undefined
  >();

  const { getGeocodeAddress, isLoading } = useDirectoryAddress();

  const { transactionBuilderId } = useParams<Match>();
  const {
    auth: { userDetail },
    transactionBuilder: { transactionBuilder },
  } = useSelector((state: RootState) => state);

  const propertyAddress = `${formatAddress(
    transactionBuilder?.address?.street!,
    transactionBuilder?.address?.street2,
    transactionBuilder?.address?.city!,
    capitalizeEnum(transactionBuilder?.address?.state! || ''),
    transactionBuilder?.address?.zip!,
  )} ${capitalizeEnum(userDetail?.accountCountry!)}`;

  const {
    fields: sellerOrLandlordFields,
    append: appendSellerOrLandlord,
    remove: removeSellerOrLandlord,
  } = useFieldArray({
    control,
    name: 'sellerOrLandlord',
  });

  const {
    fields: buyerOrTenantFields,
    append: appendBuyerOrTenant,
    remove: removeBuyerOrTenant,
  } = useFieldArray({
    control,
    name: 'buyerOrTenant',
  });

  const [transactionType, buyerOrTenant, sellerOrLandlord] = watch([
    'transactionType',
    'buyerOrTenant',
    'sellerOrLandlord',
  ]);

  const handleBuyerSellerStep = async () => {
    const isValid = await trigger();
    if (isValid) {
      const buyerSellerInfoRequest: BuyerAndSellerRequest = {
        buyers: buyerOrTenant.map((buyer) => {
          if (buyer.address || buyer.location) {
            AnalyticsService.instance().logEvent(
              buyer.isSearchAddress
                ? AnalyticsEventEnum.CREATE_TX_BUYER_ADDRESS_ENTERED_W_GOOGLE_SEARCH
                : AnalyticsEventEnum.CREATE_TX_BUYER_ADDRESS_ENTERED_MANUALLY,
            );
          }
          return {
            firstName:
              buyer.participantType === CreateTransactionParticipantType.PERSON
                ? buyer.firstName
                : '',
            lastName:
              buyer.participantType === CreateTransactionParticipantType.PERSON
                ? buyer.lastName
                : '',
            email: buyer.email,
            companyName:
              buyer.participantType === CreateTransactionParticipantType.COMPANY
                ? buyer.companyName
                : '',
            phoneNumber: buyer.phoneNumber,
            address: buyer.isSearchAddress
              ? buyer.location?.formatted_address
              : buyer.address,
          };
        }),
        sellers: sellerOrLandlord.map((seller) => {
          const isChecked = seller.usePropertyAddress?.[0] === YesNoType.YES;
          if (!isChecked && (seller.address || seller.location)) {
            AnalyticsService.instance().logEvent(
              seller.isSearchAddress
                ? AnalyticsEventEnum.CREATE_TX_SELLER_ADDRESS_ENTERED_W_GOOGLE_SEARCH
                : AnalyticsEventEnum.CREATE_TX_SELLER_ADDRESS_ENTERED_MANUALLY,
            );
          }
          return {
            firstName:
              seller?.participantType ===
              CreateTransactionParticipantType.PERSON
                ? seller.firstName
                : '',
            lastName:
              seller?.participantType ===
              CreateTransactionParticipantType.PERSON
                ? seller.lastName
                : '',
            email: seller.email,
            companyName:
              seller?.participantType ===
              CreateTransactionParticipantType.COMPANY
                ? seller.companyName
                : '',
            phoneNumber: seller.phoneNumber,
            address:
              !isChecked && seller.isSearchAddress
                ? seller.location?.formatted_address
                : seller.address,
          };
        }),
      };
      setLoading(true);
      const res = await dispatch(
        saveBuyersAndSellersInfos(
          transactionBuilderId!,
          buyerSellerInfoRequest,
        ),
      );
      setLoading(false);
      if (res) {
        onNext();
      }
    }
  };

  useEffect(() => {
    sellerOrLandlordFields.forEach((element, index) => {
      const isCheckboxChecked = element?.usePropertyAddress?.includes(
        YesNoType.YES,
      );

      if (isCheckboxChecked) {
        const isAddressSame = propertyAddress === element.address;

        if (!isAddressSame) {
          setValue(`sellerOrLandlord.${index}.usePropertyAddress`, []);
          setValue(`sellerOrLandlord.${index}.address`, '');
        }
      }
    });
  }, [propertyAddress, sellerOrLandlordFields, setValue]);

  const handleCheckboxClick = (index: number, isChecked: boolean) => {
    setValue(
      `sellerOrLandlord.${index}.address`,
      !isChecked ? propertyAddress : '',
    );
  };

  const handleSidebarToggle = (
    participantType?: CreateTransactionParticipantType,
    fieldArrayPrefix?: FieldArrayPrefix,
    index?: number,
  ) => () => {
    setIsSidebarOpen((prevState) => !prevState);
    if (isNumber(index)) {
      setCurrentIndex((currentIndex) =>
        currentIndex === index ? undefined : index,
      );
      setCurrentFieldArrayPrefix(fieldArrayPrefix);
      setCurrentParticipantType(participantType);
    } else {
      setCurrentIndex(undefined);
      setCurrentFieldArrayPrefix(undefined);
      setCurrentParticipantType(undefined);
    }
  };

  const handleSave = (
    fieldArrayPrefix?: FieldArrayPrefix,
    contact?: DirectoryCommonEntityResponse,
  ) => {
    if (isNumber(currentIndex) && contact && fieldArrayPrefix) {
      if (currentParticipantType === CreateTransactionParticipantType.PERSON) {
        setValue(
          `${fieldArrayPrefix}.${currentIndex}.firstName`,
          (contact as DirectoryPersonResponse)?.firstName ?? '',
        );
        setValue(
          `${fieldArrayPrefix}.${currentIndex}.lastName`,
          (contact as DirectoryPersonResponse)?.lastName ?? '',
        );
      } else if (
        currentParticipantType === CreateTransactionParticipantType.COMPANY
      ) {
        setValue(
          `${fieldArrayPrefix}.${currentIndex}.companyName`,
          (contact as DirectoryVendorResponse)?.name ?? '',
        );
      }
      setValue(
        `${fieldArrayPrefix}.${currentIndex}.phoneNumber`,
        contact?.phoneNumber ?? '',
      );
      setValue(
        `${fieldArrayPrefix}.${currentIndex}.email`,
        contact?.emailAddress ?? '',
      );
      setValue(
        `${fieldArrayPrefix}.${currentIndex}.address`,
        contact?.address?.oneLine ?? '',
      );
    }
  };

  const handleRoleSidebarToggle = (
    participantType?: CreateTransactionParticipantType,
    fieldArrayPrefix?: FieldArrayPrefix,
    index?: number,
  ) => async () => {
    if (isNumber(index) && fieldArrayPrefix) {
      const values = getValues(`${fieldArrayPrefix}.${index}`) as any;
      const geoAddress =
        values?.location ?? (await getGeocodeAddress(values?.address));
      setCurrentParticipantType(participantType);
      setCurrentFormContact({
        ...values,
        emailAddress: values?.email,
        address: {
          oneLine: geoAddress?.formatted_address ?? undefined,
        },
        addressComponents: geoAddress?.address_components ?? [],
        placeId: geoAddress?.place_id,
        name: values?.companyName,
        role:
          participantType === CreateTransactionParticipantType.PERSON
            ? DirectoryPersonResponseRoleEnum.Client
            : DirectoryPersonResponseRoleEnum.Other,
      });
    } else {
      setCurrentParticipantType(undefined);
      setCurrentFormContact(undefined);
    }

    setRoleSidebarOpen((prevState) => !prevState);
  };

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        {sellerOrLandlordFields.map((field, index) => {
          const [participantType, isSearchAddress, usePropertyAddress] = watch([
            `sellerOrLandlord.${index}.participantType`,
            `sellerOrLandlord.${index}.isSearchAddress`,
            `sellerOrLandlord.${index}.usePropertyAddress`,
          ]);
          const isChecked = usePropertyAddress?.[0] === YesNoType.YES;

          return (
            <div key={field.id} className='space-y-7'>
              <div className='space-y-4 mb-5'>
                <div className='flex justify-between items-center'>
                  <p className='text-xl font-zen-title font-medium text-zen-dark-9'>
                    {isSaleTransaction(transactionType) ? 'Seller' : 'Landlord'}
                  </p>
                  {sellerOrLandlordFields.length > 1 && (
                    <div className='cursor-pointer'>
                      <ZenButton
                        variant='danger-link'
                        LeftIconComponent={
                          <MdOutlineDelete
                            title='delete'
                            size={22}
                            className='text-zen-danger'
                          />
                        }
                        onClick={() => removeSellerOrLandlord(index)}
                      />
                    </div>
                  )}
                </div>
                <div className='flex justify-between items-center'>
                  <ZenControlledRadioInput<
                    CreateTransactionFormState,
                    `sellerOrLandlord.${number}.participantType`
                  >
                    name={`sellerOrLandlord.${index}.participantType`}
                    control={control}
                    inlineOptions
                    shouldUnregister={false}
                    options={values(CreateTransactionParticipantType).map(
                      (value) => {
                        return { label: capitalizeEnum(value), value };
                      },
                    )}
                    rules={{
                      required: 'Please select a type',
                    }}
                    defaultValue={CreateTransactionParticipantType.PERSON}
                    isRequired
                  />
                  <div className='flex flex-row items-center relative mt-0.5'>
                    <div className='whitespace-nowrap'>
                      <ZenButton
                        label='Directory'
                        variant='primary-link'
                        LeftIconComponent={
                          <FontAwesomeIcon icon={faAddressBook} />
                        }
                        onClick={handleSidebarToggle(
                          participantType,
                          'sellerOrLandlord',
                          index,
                        )}
                        fontstyle
                        zeroPadding
                      />
                    </div>
                    <div className='whitespace-nowrap'>
                      <ZenButton
                        label={
                          isSaleTransaction(transactionType)
                            ? 'Duplicate Seller'
                            : 'Duplicate Landlord'
                        }
                        variant='primary-link'
                        LeftIconComponent={
                          <FontAwesomeIcon icon={faUserPlus} size='sm' />
                        }
                        onClick={() => {
                          appendSellerOrLandlord(sellerOrLandlord[index]);
                        }}
                        fontstyle
                      />
                    </div>
                  </div>
                </div>

                {participantType === CreateTransactionParticipantType.PERSON ? (
                  <div className='flex flex-row space-x-4 mt-5'>
                    <ZenControlledTextInput<
                      CreateTransactionFormState,
                      `sellerOrLandlord.${number}.firstName`
                    >
                      control={control}
                      label='First Name'
                      name={`sellerOrLandlord.${index}.firstName`}
                      placeholder='E.g. John'
                      shouldUnregister={false}
                      rules={{ required: 'First Name is required' }}
                      maxLength={50}
                      isRequired
                    />

                    <ZenControlledTextInput<
                      CreateTransactionFormState,
                      `sellerOrLandlord.${number}.lastName`
                    >
                      control={control}
                      label='Last Name'
                      name={`sellerOrLandlord.${index}.lastName`}
                      shouldUnregister={false}
                      placeholder='E.g. Doe'
                      rules={{ required: 'Last Name is required' }}
                      maxLength={50}
                      isRequired
                    />
                  </div>
                ) : (
                  <ZenControlledTextInput<
                    CreateTransactionFormState,
                    `sellerOrLandlord.${number}.companyName`
                  >
                    control={control}
                    label='Company Name'
                    name={`sellerOrLandlord.${index}.companyName`}
                    placeholder='E.g. Company Name LLC.'
                    shouldUnregister={false}
                    rules={{ required: 'Company Name is required' }}
                    isRequired
                  />
                )}
                <div className='flex flex-row space-x-4'>
                  <ZenControlledEmailInput<
                    CreateTransactionFormState,
                    `sellerOrLandlord.${number}.email`
                  >
                    control={control}
                    label='Email Address'
                    name={`sellerOrLandlord.${index}.email`}
                    shouldUnregister={false}
                    placeholder='Eg. johndoe@business.com'
                    rules={{ ...EMAIL_VALIDATIONS }}
                  />

                  <ZenControlledPhoneNumberInput<
                    CreateTransactionFormState,
                    `sellerOrLandlord.${number}.phoneNumber`
                  >
                    control={control}
                    label='Phone Number'
                    name={`sellerOrLandlord.${index}.phoneNumber`}
                    shouldUnregister={false}
                    placeholder='E.g. +1 (123) 456 7890'
                    rules={{
                      ...PHONE_NUMBER_VALIDATIONS,
                    }}
                  />
                </div>
              </div>
              <div className='relative flex flex-col'>
                <div className='relative'>
                  {!isChecked && isSearchAddress ? (
                    <ZenControlledGoogleAutocompleteSearchInput<
                      CreateTransactionFormState,
                      `sellerOrLandlord.${number}.location`
                    >
                      control={control}
                      shouldUnregister={false}
                      name={`sellerOrLandlord.${index}.location`}
                      label='Search Address'
                      subLabel='(Source by Google)'
                      placeholder='E.g. 1st St. New York, NY 10010'
                      rules={{
                        ...GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
                      }}
                    />
                  ) : (
                    <ZenControlledTextInput<
                      CreateTransactionFormState,
                      `sellerOrLandlord.${number}.address`
                    >
                      control={control}
                      label='Address'
                      name={`sellerOrLandlord.${index}.address`}
                      placeholder='11011 Street, Block 11..'
                      shouldUnregister={false}
                      readOnly={isChecked}
                    />
                  )}

                  {!isChecked && (
                    <div className='absolute -top-3 right-0'>
                      <IconButton
                        label={
                          isSearchAddress
                            ? 'Enter address manually'
                            : 'Search Address'
                        }
                        variant='none'
                        buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
                        leftIcon={
                          <FontAwesomeIcon
                            icon={faArrowUpArrowDown}
                            className='p-1'
                          />
                        }
                        onClick={() => {
                          setValue(
                            `sellerOrLandlord.${index}.isSearchAddress`,
                            !isSearchAddress,
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className='mt-1 mb-4 flex flex-row items-center space-x-2'>
                  <div
                    className='mr-1'
                    onClick={() => {
                      handleCheckboxClick(index, isChecked);
                    }}
                    data-testid='use-property-address'
                  >
                    <ZenControlledCheckboxInput<
                      CreateTransactionFormState,
                      `sellerOrLandlord.${number}.usePropertyAddress`
                    >
                      label=''
                      name={`sellerOrLandlord.${index}.usePropertyAddress`}
                      shouldUnregister={false}
                      options={[
                        {
                          label: 'Same address as property address',
                          value: YesNoType.YES,
                        },
                      ]}
                      control={control}
                      reverse
                    />
                  </div>
                  <div className='flex items-center'>
                    <hr className='h-7 border-l border-solid border-zen-dark-6' />
                    <div className='-ml-2'>
                      <ZenButton
                        label='Add to My Directory'
                        variant='primary-link'
                        isSubmitting={isLoading}
                        isDisabled={isLoading}
                        LeftIconComponent={
                          <FontAwesomeIcon
                            icon={faPlus}
                            className='text-primary-blue cursor-pointer'
                          />
                        }
                        onClick={handleRoleSidebarToggle(
                          participantType,
                          SELLER_FIELD_PREFIX,
                          index,
                        )}
                        fontstyle
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className='pb-12'>
          <ZenButton
            label={
              isSaleTransaction(transactionType) ? 'Add Seller' : 'Add Landlord'
            }
            variant='primary-link'
            LeftIconComponent={<FontAwesomeIcon icon={faUserPlus} size='sm' />}
            onClick={() =>
              appendSellerOrLandlord({
                companyName: '',
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                address: '',
              })
            }
            zeroPadding
            fontstyle
          />
        </div>
        {buyerOrTenantFields.map((field, index) => {
          const [participantType, isSearchAddress] = watch([
            `buyerOrTenant.${index}.participantType`,
            `buyerOrTenant.${index}.isSearchAddress`,
          ]);

          return (
            <div key={field.id} className='space-y-7'>
              <div className='space-y-4 mb-5'>
                <div className='flex justify-between items-center'>
                  <p className='text-xl font-zen-title font-medium text-zen-dark-9'>
                    {isSaleTransaction(transactionType) ? 'Buyer' : 'Tenant'}
                  </p>
                  {buyerOrTenantFields.length > 1 && (
                    <div className='cursor-pointer'>
                      <ZenButton
                        variant='danger-link'
                        LeftIconComponent={
                          <MdOutlineDelete
                            title='delete'
                            size={22}
                            className='text-zen-danger'
                          />
                        }
                        onClick={() => removeBuyerOrTenant(index)}
                      />
                    </div>
                  )}
                </div>
                <div className='flex justify-between items-center'>
                  <ZenControlledRadioInput<
                    CreateTransactionFormState,
                    `buyerOrTenant.${number}.participantType`
                  >
                    name={`buyerOrTenant.${index}.participantType`}
                    control={control}
                    inlineOptions
                    shouldUnregister={false}
                    options={values(CreateTransactionParticipantType).map(
                      (value) => {
                        return { label: capitalizeEnum(value), value };
                      },
                    )}
                    rules={{
                      required: 'Please select a type',
                    }}
                    isRequired
                    defaultValue={CreateTransactionParticipantType.PERSON}
                  />
                  <div className='flex flex-row items-center relative mt-0.5'>
                    <div className='whitespace-nowrap'>
                      <ZenButton
                        label='Directory'
                        variant='primary-link'
                        LeftIconComponent={
                          <FontAwesomeIcon icon={faAddressBook} />
                        }
                        onClick={handleSidebarToggle(
                          participantType,
                          'buyerOrTenant',
                          index,
                        )}
                        zeroPadding
                        fontstyle
                      />
                    </div>
                    <div className='whitespace-nowrap'>
                      <ZenButton
                        label={
                          isSaleTransaction(transactionType)
                            ? 'Duplicate Buyer'
                            : 'Duplicate Tenant'
                        }
                        variant='primary-link'
                        LeftIconComponent={
                          <FontAwesomeIcon icon={faUserPlus} size='sm' />
                        }
                        onClick={() => {
                          appendBuyerOrTenant(buyerOrTenant[index]);
                        }}
                        fontstyle
                      />
                    </div>
                  </div>
                </div>
                {participantType === CreateTransactionParticipantType.PERSON ? (
                  <div className='flex flex-row space-x-4 mt-5'>
                    <ZenControlledTextInput<
                      CreateTransactionFormState,
                      `buyerOrTenant.${number}.firstName`
                    >
                      control={control}
                      label='First Name'
                      name={`buyerOrTenant.${index}.firstName`}
                      placeholder='E.g. John'
                      shouldUnregister={false}
                      rules={{ required: 'First Name is required' }}
                      maxLength={50}
                      isRequired
                    />

                    <ZenControlledTextInput<
                      CreateTransactionFormState,
                      `buyerOrTenant.${number}.lastName`
                    >
                      control={control}
                      label='Last Name'
                      name={`buyerOrTenant.${index}.lastName`}
                      shouldUnregister={false}
                      placeholder='E.g. Doe'
                      rules={{ required: 'Last Name is required' }}
                      maxLength={50}
                      isRequired
                    />
                  </div>
                ) : (
                  <ZenControlledTextInput<
                    CreateTransactionFormState,
                    `buyerOrTenant.${number}.companyName`
                  >
                    control={control}
                    label='Company Name'
                    name={`buyerOrTenant.${index}.companyName`}
                    placeholder='E.g. Company Name LLC.'
                    shouldUnregister={false}
                    rules={{ required: 'Company Name is required' }}
                    isRequired
                  />
                )}
                <div className='flex flex-row space-x-4'>
                  <ZenControlledEmailInput<
                    CreateTransactionFormState,
                    `buyerOrTenant.${number}.email`
                  >
                    control={control}
                    label='Email Address'
                    name={`buyerOrTenant.${index}.email`}
                    shouldUnregister={false}
                    placeholder='Eg. johndoe@business.com'
                    rules={{ ...EMAIL_VALIDATIONS }}
                  />

                  <ZenControlledPhoneNumberInput<
                    CreateTransactionFormState,
                    `buyerOrTenant.${number}.phoneNumber`
                  >
                    control={control}
                    label='Phone Number'
                    name={`buyerOrTenant.${index}.phoneNumber`}
                    shouldUnregister={false}
                    placeholder='E.g. +1 (123) 456 7890'
                    rules={{
                      ...PHONE_NUMBER_VALIDATIONS,
                    }}
                  />
                </div>
              </div>
              <div className='relative'>
                {isSearchAddress ? (
                  <ZenControlledGoogleAutocompleteSearchInput<
                    CreateTransactionFormState,
                    `buyerOrTenant.${number}.location`
                  >
                    control={control}
                    shouldUnregister={false}
                    name={`buyerOrTenant.${index}.location`}
                    label='Search Address'
                    subLabel='(Source by Google)'
                    placeholder='E.g. 1st St. New York, NY 10010'
                    rules={{
                      ...GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
                    }}
                    data-testid='search-address'
                  />
                ) : (
                  <ZenControlledTextInput<
                    CreateTransactionFormState,
                    `buyerOrTenant.${number}.address`
                  >
                    control={control}
                    label='Address'
                    name={`buyerOrTenant.${index}.address`}
                    placeholder='11011 Street, Block 11..'
                    shouldUnregister={false}
                    aria-label='Address'
                    data-testid='address'
                  />
                )}
                <div className='absolute -top-3 right-0'>
                  <IconButton
                    label={
                      isSearchAddress
                        ? 'Enter address manually'
                        : 'Search Address'
                    }
                    variant='none'
                    buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
                    leftIcon={
                      <FontAwesomeIcon
                        icon={faArrowUpArrowDown}
                        className='p-1'
                      />
                    }
                    onClick={() => {
                      setValue(
                        `buyerOrTenant.${index}.isSearchAddress`,
                        !isSearchAddress,
                      );
                    }}
                  />
                </div>
              </div>
              <div className='flex items-center'>
                <div className='-ml-4'>
                  <ZenButton
                    label='Add to My Directory'
                    variant='primary-link'
                    isSubmitting={isLoading}
                    isDisabled={isLoading}
                    LeftIconComponent={
                      <FontAwesomeIcon
                        icon={faPlus}
                        className='text-primary-blue cursor-pointer'
                      />
                    }
                    onClick={handleRoleSidebarToggle(
                      participantType,
                      BUYER_FIELD_PREFIX,
                      index,
                    )}
                    fontstyle
                  />
                </div>
              </div>
            </div>
          );
        })}

        <div className='mr-1'>
          <ZenButton
            label={
              isSaleTransaction(transactionType) ? 'Add Buyer' : 'Add Tenant'
            }
            variant='primary-link'
            LeftIconComponent={<FontAwesomeIcon icon={faUserPlus} size='sm' />}
            onClick={() =>
              appendBuyerOrTenant({
                companyName: '',
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                address: '',
              })
            }
            zeroPadding
            fontstyle
          />
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-light-outline'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              isDisabled={loading}
              label='Next'
              onClick={handleBuyerSellerStep}
            />
          </div>
        </div>
      </div>
      {isSidebarOpen && (
        <ZenContactListSidebar
          participantType={currentParticipantType}
          currentFieldArrayPrefix={currentFieldArrayPrefix}
          isOpen={isSidebarOpen}
          onClose={handleSidebarToggle()}
          onSave={handleSave}
        />
      )}
      {roleSibebarOpen && (
        <ZenRoleDirectoryForm
          isOpen={roleSibebarOpen}
          existingVendorOrPerson={currentFormContact}
          mode='populate'
          existingType={
            currentParticipantType === CreateTransactionParticipantType.PERSON
              ? DirectoryEntryResponseTypeEnum.Person
              : DirectoryEntryResponseTypeEnum.Vendor
          }
          onClose={handleRoleSidebarToggle()}
        />
      )}
    </div>
  );
};

export default withCreateTransactionProgress(ZenTransactionBuyerAndSellerStep);
