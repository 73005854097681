import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import {
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName,
} from '../../../routes/AgentWebsiteOnboardingRoute';
import {
  AgentWebsiteOnboardingSiteInfoAccordionName,
  RootState,
} from '../../../types';
import { formatPhoneNumber } from '../../../utils/StringUtils';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ReviewRow from '../../ReviewRow';
import EditOutlineActionButton from '../EditOutlineActionButton';
import { setDefaultAccordionOpenType } from '../../../slices/AgentWebsiteOnboardingSlice';

const AgentWebsiteOnboardingContactInfoReview: StepByStepComponent<
  AgentWebsiteOnboardingFormData,
  AgentWebsiteOnboardingStepName
> = ({ form: { watch }, navigateTo }) => {
  const {
    detailResponse: { data: detail },
  } = useSelector((state: RootState) => state.agentDetail);

  const dispatch = useDispatch();

  const [
    slug,
    showReferralLink,
    instagramURL,
    facebookURL,
    linkedInURL,
  ] = watch([
    'slug',
    'showReferralLink',
    'instagramURL',
    'facebookURL',
    'linkedInURL',
  ]);

  const socialMedias = [];

  if (instagramURL) {
    socialMedias.push(`https://instagram.com/${instagramURL}`);
  }
  if (facebookURL) {
    socialMedias.push(`https://facebook.com/${facebookURL}`);
  }
  if (linkedInURL) {
    socialMedias.push(`https://linkedin.com/${linkedInURL}`);
  }

  return (
    <div className='p-4'>
      <div className='grid lg:grid-cols-2 grid-cols-1 gap-5'>
        <div className='flex flex-col space-y-5'>
          <ReviewRow
            label='Real Website'
            value={slug ? `https://www.joinreal.com/${slug}` : 'N/A'}
          />
          <ReviewRow label='Email' value={detail?.emailAddress!} />
          <ReviewRow
            label='Phone'
            value={formatPhoneNumber(detail?.phoneNumber!)!}
          />
        </div>
        <div className='flex flex-col space-y-5'>
          <ReviewRow
            label='Social Media'
            value={socialMedias.length > 0 ? socialMedias : 'N/A'}
          />
          <ReviewRow
            label='Show Agent Referral Link'
            value={showReferralLink ? 'Yes' : 'No'}
          />
        </div>
      </div>
      <div className='mt-5 mb-2'>
        <EditOutlineActionButton
          text='Edit contact information'
          icon={<FontAwesomeIcon icon={faPen} className='mt-1 mr-1' />}
          onClick={() => {
            dispatch(
              setDefaultAccordionOpenType(
                AgentWebsiteOnboardingSiteInfoAccordionName.CONTACT_INFO,
              ),
            );
            navigateTo(AgentWebsiteOnboardingStepName.SITE_INFO);
          }}
        />
      </div>
    </div>
  );
};

export default AgentWebsiteOnboardingContactInfoReview;
