import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import ZenRoute from '../ZenRoute';
import { RezenObjectTypeEnum } from '../../../openapi/yada';
import FileCabinetTable from '../Transaction/FileCabinet/FileCabinetTable';
import { isDesignatedBrokerOfOffice } from '../../../utils/AgentHelper';

interface ZenOfficeFileCabinetRouteProps {
  officeId: string;
}

const ZenOfficeFileCabinetRoute: React.FC<ZenOfficeFileCabinetRouteProps> = ({
  officeId,
}) => {
  const {
    auth: { userDetail, isBroker },
    office: { officeDetail, officeGroupsById },
  } = useSelector((state: RootState) => state);

  const isDesignatedBroker = useMemo(
    () =>
      isBroker
        ? isDesignatedBrokerOfOffice(userDetail!, officeGroupsById!, officeId!)
        : false,
    [isBroker, userDetail, officeGroupsById, officeId],
  );

  const dropboxIdMemo = useMemo(() => officeDetail?.dropboxId, [officeDetail]);

  return (
    <ZenRoute title='Office Documents'>
      <FileCabinetTable
        dropboxId={dropboxIdMemo!}
        containerType={RezenObjectTypeEnum.Office}
        isDesignatedBroker={isDesignatedBroker}
      />
    </ZenRoute>
  );
};

export default ZenOfficeFileCabinetRoute;
