import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, ApplicationState, ErrorCode } from '../types';
import ErrorService from '../services/ErrorService';
import {
  AmountDiscountResponse,
  ApplicationControllerApi,
  ApplicationResponse,
  ApproveApplicationRequest,
  RejectApplicationRequest,
  UpdateApplicationRequest,
} from '../openapi/yenta';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import {
  getApiErrorMessage,
  TEAMMATE_LIMIT_REACHED,
} from '../utils/ErrorUtils';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: ApplicationState = {
  loadingDetail: false,
  applicationDetail: undefined,
  fetchDetailErrorCode: null,
};

const ApplicationSlice = createSlice({
  name: 'applicationSlice',
  initialState,
  reducers: {
    changeLoadingDetail(state, action: PayloadAction<boolean>) {
      state.loadingDetail = action.payload;
    },
    saveApplicationDetail(state, action: PayloadAction<ApplicationResponse>) {
      state.applicationDetail = action.payload;
      state.fetchDetailErrorCode = null;
    },
    errorFetchingDetail(state, action: PayloadAction<ErrorCode>) {
      state.fetchDetailErrorCode = action.payload;
    },
  },
});

export const {
  changeLoadingDetail,
  errorFetchingDetail,
  saveApplicationDetail,
} = ApplicationSlice.actions;

export const fetchApplicationById = (id: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingDetail(true));
  try {
    const { data } = await new ApplicationControllerApi(
      getYentaConfiguration(),
    ).getApplication(id);
    await dispatch(saveApplicationDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors('Unable to fetch application', e);
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export const approveApplicationById = (
  id: string,
  approveRequest: ApproveApplicationRequest,
): AppThunk<Promise<boolean | 'TEAMMATE_LIMIT_REACHED'>> => async (
  dispatch,
) => {
  try {
    const { data } = await new ApplicationControllerApi(
      getYentaConfiguration(),
    ).approveApplication(id, approveRequest);
    await dispatch(saveApplicationDetail(data));
    await dispatch(showSuccessToast('Application Approved.'));
    return true;
  } catch (e) {
    const errorMessage =
      e?.response?.data?.['com.real.commons.apierror.ApiError']?.message;

    // check for cannot add more than X teammates error message
    if (errorMessage?.includes('cannot have more than')) {
      return TEAMMATE_LIMIT_REACHED;
    }

    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreHandled('Unable to approve agent', e);
    dispatch(
      showErrorToast('Cannot Approve Application', getApiErrorMessage(e)),
    );
    return false;
  }
};

export const rejectApplicationById = (
  id: string,
  rejectRequest: RejectApplicationRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new ApplicationControllerApi(
      getYentaConfiguration(),
    ).rejectApplication(id, rejectRequest);
    await dispatch(saveApplicationDetail(data));
    await dispatch(showSuccessToast('Application Rejected.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to reject agent', e);
    dispatch(showErrorToast(getApiErrorMessage(e)));
  }
};

export const editApplicationForm = (
  id: string,
  values: UpdateApplicationRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new ApplicationControllerApi(
      getYentaConfiguration(),
    ).updateApplication(id, values);
    await dispatch(saveApplicationDetail(data));
    await dispatch(showSuccessToast('Application Updated.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to edit application form', e);
    dispatch(
      showErrorToast(
        'We had a problem updating application',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const agentLicenseTransferred = (id: string): AppThunk => async (
  dispatch,
) => {
  try {
    const api = new ApplicationControllerApi(getYentaConfiguration());
    const { data } = await api.licenseTransferred({
      applicationId: id,
    });
    await dispatch(saveApplicationDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to transfer license', e);
    dispatch(showErrorToast(getApiErrorMessage(e)));
  }
};

export const agentJoinedBoard = (id: string): AppThunk => async (dispatch) => {
  try {
    const api = new ApplicationControllerApi(getYentaConfiguration());
    const { data } = await api.boardJoined({
      applicationId: id,
    });
    await dispatch(saveApplicationDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to join board in application', e);
    dispatch(showErrorToast(getApiErrorMessage(e)));
  }
};

export const waiveApplicationFeeById = (id: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new ApplicationControllerApi(
      getYentaConfiguration(),
    ).waiveApplicationFees(id);
    dispatch(saveApplicationDetail(data));
    dispatch(showSuccessToast('Successfully waived application fee.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(showErrorToast('Failed trying to waive application fee.'));
    ErrorService.notify('Unable to waive application fee', e, {
      application: { id },
    });
  }
};

export const getApplicationDriverLicenseImage = (
  id: string,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new ApplicationControllerApi(
      getYentaConfiguration(),
    ).getApplicationDriverLicenseImage(id);
    return data?.driverLicenseImageUrl;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem fetching application driver license image',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to fetch application driver license image', e, {
      application: { id },
    });
    return undefined;
  }
};

export const updateApplicationDriverLicenseImage = (
  id: string,
  driverLicenseImage: File,
): AppThunk<Promise<ApplicationResponse | undefined>> => async (dispatch) => {
  try {
    const { data } = await new ApplicationControllerApi(
      getYentaConfiguration(),
    ).updateApplicationDriverLicenseImage(id, driverLicenseImage);
    return data;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem updating application driver license image',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify(
      'Unable to update application driver license image',
      e,
      {
        application: { id },
      },
    );
    return undefined;
  }
};

export const applyCoupon = (
  applicationId: string,
  coupon: string,
  amount: number,
): AppThunk<Promise<AmountDiscountResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new ApplicationControllerApi(
      getYentaConfiguration(),
    ).calculateAmountDiscountInfo(applicationId, coupon, amount * 100);
    return data;
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem applying coupon',
        e?.response?.data['com.real.commons.apierror.ApiError']?.message ||
          'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to apply coupon', e, {
      application: { id: applicationId, coupon, amount },
    });
    return undefined;
  }
};
export default ApplicationSlice.reducer;
