import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { ClassValue } from 'clsx';
import { cn } from '../../../utils/classUtils';
import { BreakPoint, useScreenSize } from '../../WeathManagement/utils';
import { ZenTab } from './ZenRouterTabs';

export interface ZenTabItemProps extends ZenTab {
  isActive?: boolean;
  leftLabelIcon?: React.ReactElement;
  textClasses?: ClassValue;
}

const SMALL_FONT_SCREEN_SIZES = [BreakPoint.xs, BreakPoint.s];

const ZenRouterTabItem: React.FC<ZenTabItemProps> = ({
  label,
  leftLabelIcon,
  badgeComponent,
  path,
  exact,
  isActive,
  textClasses,
}) => {
  const { screen } = useScreenSize();

  const isSmallScreen = SMALL_FONT_SCREEN_SIZES.includes(screen);

  return (
    <div>
      <div className='flex flex-row items-center'>
        <NavLink
          className={cn(
            'block relative focus:outline-none text-grey-500 text-base font-normal font-zen-body',
            isActive && 'text-primary-dark font-medium',
          )}
          to={path}
          exact={exact}
          title={label}
        >
          <div
            className={cn(
              'py-2.5 flex flex-row items-center justify-center',
              isActive ? 'pt-3 pb-2' : 'py-2.5',
              isSmallScreen ? 'text-sm px-2' : 'text-base min-w-[118px] px-4',
              textClasses,
            )}
          >
            {leftLabelIcon && <div>{leftLabelIcon}</div>}
            <p className='whitespace-pre'>{label}</p>
          </div>
          <div
            className={classNames(
              'absolute bottom-[-2px] inset-x-0 z-[1] h-[2px] rounded-md',
              isActive && 'bg-primary-blue',
            )}
          />
        </NavLink>
        {badgeComponent && <div>{badgeComponent}</div>}
      </div>
    </div>
  );
};

export default ZenRouterTabItem;
