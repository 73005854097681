import { CheckCircleOutline, Close } from '@material-ui/icons';
import { AgentResponseAgentStatusEnum } from '../../openapi/yenta';
import { EnumMap } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import { PillVariantType } from '../Zen/ZenStatusPill';
import GeminiStatusPill from './GeminiStatusPill';

export interface GeminiAgentStatusCellProps {
  status: AgentResponseAgentStatusEnum;
  showIcon?: boolean;
  pillContainerStyle?: string;
}

const statusToPillVariantMap: EnumMap<
  AgentResponseAgentStatusEnum,
  PillVariantType
> = {
  [AgentResponseAgentStatusEnum.Inactive]: 'danger',
  [AgentResponseAgentStatusEnum.Rejected]: 'danger',
  [AgentResponseAgentStatusEnum.Active]: 'success',
  [AgentResponseAgentStatusEnum.Candidate]: 'warning',
};

const statusToPillIconMap: EnumMap<
  AgentResponseAgentStatusEnum,
  React.ReactElement
> = {
  [AgentResponseAgentStatusEnum.Inactive]: <Close fontSize='small' />,
  [AgentResponseAgentStatusEnum.Rejected]: <Close fontSize='small' />,
  [AgentResponseAgentStatusEnum.Active]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [AgentResponseAgentStatusEnum.Candidate]: (
    <CheckCircleOutline fontSize='small' />
  ),
};

const GeminiAgentStatusCell: React.FC<GeminiAgentStatusCellProps> = ({
  status,
  showIcon = false,
  pillContainerStyle,
}) => {
  return (
    <GeminiStatusPill
      text={capitalizeEnum(status)}
      variant={statusToPillVariantMap[status]}
      icon={showIcon ? statusToPillIconMap[status] : undefined}
      pillContainerStyle={pillContainerStyle}
    />
  );
};

export default GeminiAgentStatusCell;
