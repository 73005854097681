import { AnnouncementResponse } from '../../../openapi/mercury';
import AnnouncementAudio from './AnnouncementAudio';
import AnnouncementContent from './AnnouncementContent';
import AnnouncementFooter from './AnnouncementFooter';
import AnnouncementHeader from './AnnouncementHeader';

export interface AnnouncementDetailsProps {
  announcement: AnnouncementResponse;
  preview: boolean;
}

const AnnouncementDetails: React.FC<AnnouncementDetailsProps> = ({
  announcement,
  preview,
}) => {
  return (
    <div className='w-full h-full relative rounded-2xl flex flex-col flex-grow scrollbar overflow-y-auto'>
      <AnnouncementHeader
        preview={preview}
        videoURL={announcement?.signedMsgVideoUrl}
        bannerURL={announcement?.signedMsgBannerUrl}
      />
      <div className='flex flex-col flex-grow py-5 px-7 bg-white font-zen-body rounded-b-2xl scrollbar overflow-y-auto'>
        <AnnouncementContent
          title={announcement.msgTitle!}
          details={announcement.msgDetails}
        />
        <div className='flex flex-col pt-4'>
          <AnnouncementAudio />
          <AnnouncementFooter
            topAnnouncement={announcement}
            preview={preview}
          />
        </div>
      </div>
    </div>
  );
};

export default AnnouncementDetails;
