import { DateTime } from 'luxon';
import { TaxFormW9Response } from '../openapi/yenta';
import { capitalizeEnum, isStringEmpty } from '../utils/StringUtils';

interface Props {
  agentId: string;
  data: TaxFormW9Response;
}

const TaxTabDetails: React.FC<Props> = ({ data }) => {
  const nationalIds = data.nationalIds?.filter(
    (nationalId) => !isStringEmpty(nationalId.id),
  );

  return (
    <div className='divide-y'>
      <div key={data.id} className='px-7 py-6 flex flex-col'>
        <div className='flex flex-row items-center justify-between w-full space-x-4'>
          <div className='flex flex-row items-center space-x-2'>
            <p className='capitalize font-zen-body font-semibold text-base text-zen-dark-9'>
              {data.name}
            </p>
          </div>
        </div>

        <div className='space-y-1'>
          {(nationalIds || []).map((nationalId) => (
            <div
              key={nationalId.id}
              className='flex font-zen-body text-zen-dark-7'
            >
              <p className='font-semibold'>
                {`${capitalizeEnum(
                  nationalId.type!,
                ).toUpperCase()}: *****${nationalId.id?.slice(-4)}`}
              </p>
            </div>
          ))}

          <div className='flex font-zen-body space-x-1 text-zen-dark-7'>
            <p className='font-semibold'>Date Created:</p>
            <p>{`${DateTime.fromMillis(data.signedAt!).toFormat(
              'LL/dd/yy',
            )}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaxTabDetails;
