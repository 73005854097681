import { Route, Switch } from 'react-router-dom';
import { ZenTab } from './ZenRouterTabs';

interface ZenRouterTabsSeparatedItemsProps {
  tabs: ZenTab[];
}

const ZenRouterTabsSeparatedItems = ({
  tabs,
}: ZenRouterTabsSeparatedItemsProps) => {
  return (
    <div className='px-2'>
      <Switch>
        {tabs.map(({ label, TabComponent, path, exact = true }) => (
          <Route
            key={label}
            path={path}
            exact={exact}
            component={TabComponent}
          />
        ))}
      </Switch>
    </div>
  );
};

export default ZenRouterTabsSeparatedItems;
