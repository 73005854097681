import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChangeEvent } from 'react';
import { BankActivityControllerApi } from '../openapi/arrakis';
import {
  BankAccountControllerApi,
  BankAccountResponse,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { AppThunk, BankState, ErrorCode } from '../types';
import {
  getArrakisConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from './ErrorSlice';
import { showErrorToast } from './ToastNotificationSlice';

export const initialState: BankState = {
  loadingDetail: false,
  bankDetail: null,
  errorCode: null,
  pendingCount: 0,
  reconciledCount: 0,
};

const BankSlice = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    changeLoadingDetail(state, action: PayloadAction<boolean>) {
      state.loadingDetail = action.payload;
    },
    saveDetail(state, action: PayloadAction<BankAccountResponse>) {
      state.bankDetail = action.payload;
      state.errorCode = null;
    },
    errorFetchingDetail(state, action: PayloadAction<ErrorCode>) {
      state.errorCode = action.payload;
    },
    updatePendingCount(state, action: PayloadAction<number>) {
      state.pendingCount = action.payload;
    },
    updateReconciledCount(state, action: PayloadAction<number>) {
      state.reconciledCount = action.payload;
    },
  },
});

export const {
  changeLoadingDetail,
  saveDetail,
  errorFetchingDetail,
  updatePendingCount,
  updateReconciledCount,
} = BankSlice.actions;

export const fetchBankDetailsById = (bankId: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new BankAccountControllerApi(
      getYentaConfiguration(),
    ).getBankAccountById(bankId);
    dispatch(saveDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Error fetching bank details by id', e);
    dispatch(
      showErrorToast(
        'We had a problem fetching bank details',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const uploadBankStatement = (
  id: string,
  e: ChangeEvent<HTMLInputElement>,
): AppThunk => async (dispatch) => {
  try {
    await new BankActivityControllerApi(
      getArrakisConfiguration(),
    ).generateMatches(id, e.target.files![0]!);
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Error uploading bank statement', e);
    dispatch(
      showErrorToast(
        'We had a problem uploading bank statement',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const fetchReconciledCount = (id: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new BankActivityControllerApi(
      getArrakisConfiguration(),
    ).getActivities(id, 'RECONCILED', '', '', 0, 1);
    dispatch(updateReconciledCount(data.totalCount!));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch reconciled Count', e);
    dispatch(
      showErrorToast(
        'We had a problem fetching reconciled Count',
        'Please try again in a few moments.',
      ),
    );
  }
};

export default BankSlice.reducer;
