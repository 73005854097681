import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  EscrowDepositRequest,
  EscrowDepositRequestExemptAmountTypeEnum,
  EscrowDepositResponse,
  MoneyValue,
  MoneyValueCurrencyEnum,
  TransactionResponse,
} from '../../openapi/arrakis';
import {
  addDepositPayment,
  getDepositInstallmentReceipt,
  updateDepositPayment,
} from '../../slices/TransactionSlice';
import { AppDispatch, ISelectOption, RootState } from '../../types';
import { getAllBuyersFromTransaction } from '../../utils/AgentHelper';
import {
  getFileNameFromUrl,
  MONEY_AMOUNT_REGEX,
} from '../../utils/StringUtils';
import { isCanadaTransaction } from '../../utils/TransactionHelper';
import {
  DATE_FORMAT_VALIDATIONS,
  DATE_SELECT_VALIDATIONS,
  FILE_VALIDATIONS,
  MONEY_NON_ZERO_VALUE_VALIDATIONS,
} from '../../utils/Validations';
import ZenControlledDatePickerInputV2 from '../Zen/Input/ZenControlledDatePickerInputV2';
import ZenControlledFileUploadInput from '../Zen/Input/ZenControlledFileUploadInput';
import ZenControlledFormattedMoneyInput from '../Zen/Input/ZenControlledFormattedMoneyInput';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextAreaInput from '../Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalActionFooter from '../Zen/Modal/ZenSidebarModalActionFooter';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import AdminOnly from '../auth/AdminOnly';
import { getISelectOptionDefaultValue } from '../../utils/FormUtils';
import {
  getBankAccountISelectOptions,
  getDepositAccountISelectOption,
} from '../../utils/DepositsUtil';
import ZenDeleteTrustDepositInstallmentModal from './TrustDeposits/ZenDeleteTrustDepositInstallmentModal';

interface FormData {
  amount: MoneyValue;
  dateReceived: string;
  depositorName: string;
  receiptNumber: string;
  trustAccount: ISelectOption;
  dateDeposited?: string;
  depositMethod?: string;
  depositorInfo?: string;
  sourceAccount?: string;
  note?: string;
  exemptAmountType?: ISelectOption;
  exemptAmount?: MoneyValue;
  exemptReason?: string;
  receipt?: File[];
}

interface DepositAddFormProps {
  onClose(): void;
  depositDetails?: EscrowDepositResponse;
  transaction: TransactionResponse;
  escrowId: string;
}

const ZenDepositAddForm: React.FC<DepositAddFormProps> = ({
  transaction,
  depositDetails,
  onClose,
  escrowId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [receiptFileUrl, setReceiptFileUrl] = useState<string | undefined>();
  const [deleteDeposit, setDeleteDeposit] = useState<EscrowDepositResponse>();
  const {
    office: { officeDetailById },
  } = useSelector((state: RootState) => state);
  const officeDetails = officeDetailById[transaction?.office?.id!];
  const buyersNames = getAllBuyersFromTransaction(transaction);

  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      amount: depositDetails?.amount ?? {
        currency: (transaction.currency as unknown) as MoneyValueCurrencyEnum,
      },
      dateReceived: depositDetails?.dateReceived
        ? DateTime.fromISO(depositDetails?.dateReceived).toFormat('LL/dd/yyyy')
        : undefined,
      dateDeposited: depositDetails?.dateDeposited
        ? DateTime.fromISO(depositDetails?.dateDeposited).toFormat('LL/dd/yyyy')
        : undefined,
      depositMethod: depositDetails?.depositMethod,
      depositorInfo: depositDetails?.depositorInfo,
      sourceAccount: depositDetails?.sourceAccount,
      trustAccount: getDepositAccountISelectOption(
        depositDetails,
        officeDetails?.trustAccount,
      ),
      depositorName: depositDetails
        ? depositDetails?.depositorName
        : buyersNames,
      receiptNumber: depositDetails?.receiptNumber,
      note: depositDetails?.note,
      exemptAmount: depositDetails?.exemptAmount! ?? {
        currency: (transaction.currency as unknown) as MoneyValueCurrencyEnum,
      },
      exemptAmountType: getISelectOptionDefaultValue(
        depositDetails?.exemptAmountType,
      ),
      exemptReason: depositDetails?.exemptReason,
    },
  });

  const [receipt, exemptAmount] = watch(['receipt', 'exemptAmount']);

  useEffect(() => {
    const getReceiptUrl = async () => {
      if (depositDetails?.receiptImagePath) {
        const url = await dispatch(
          getDepositInstallmentReceipt(
            transaction?.id!,
            escrowId,
            depositDetails?.id!,
          ),
        );
        setReceiptFileUrl(url);
      }
    };

    getReceiptUrl();
  }, [
    depositDetails?.id,
    depositDetails?.receiptImagePath,
    dispatch,
    escrowId,
    transaction?.id,
  ]);

  useEffect(() => {
    if (isCanadaTransaction(transaction) && !exemptAmount?.amount) {
      clearErrors('exemptAmountType');
    }
  }, [clearErrors, exemptAmount, transaction]);

  const onSubmit = async (data: FormData) => {
    const final: EscrowDepositRequest = {
      depositorId: depositDetails?.depositorId,
      depositorAddress: depositDetails?.depositorAddress,
      depositorName: data.depositorName || depositDetails?.depositorName!,
      receiptNumber: data.receiptNumber || depositDetails?.receiptNumber,
      amount: data.amount || depositDetails?.amount,
      dateReceived: !!data.dateReceived
        ? DateTime.fromFormat(data?.dateReceived, 'LL/dd/yyyy').toISODate()
        : depositDetails?.dateReceived!,
      dateDeposited: !!data.dateDeposited
        ? DateTime.fromFormat(data?.dateDeposited, 'LL/dd/yyyy').toISODate()
        : depositDetails?.dateDeposited,
      depositMethod: data.depositMethod || depositDetails?.depositMethod,
      depositorInfo: data.depositorInfo || depositDetails?.depositorInfo,
      sourceAccount: data.sourceAccount || depositDetails?.sourceAccount,
      trustAccount: data.trustAccount?.value,
      note: data.note || depositDetails?.note,
      exemptAmount: !!data?.exemptAmount?.amount
        ? {
            amount: +data?.exemptAmount?.amount!,
            currency: data?.exemptAmount?.currency!,
          }
        : undefined,
      exemptAmountType: !!(data?.exemptAmountType
        ?.value as EscrowDepositRequestExemptAmountTypeEnum)
        ? (data?.exemptAmountType
            ?.value as EscrowDepositRequestExemptAmountTypeEnum)
        : undefined,
      exemptReason: data?.exemptReason,
    };

    let isDepositSaved: boolean;
    if (depositDetails) {
      isDepositSaved = await dispatch(
        updateDepositPayment(
          transaction?.id!,
          escrowId,
          depositDetails.id!,
          final,
          receipt?.[0],
        ),
      );
    } else {
      isDepositSaved = await dispatch(
        addDepositPayment(transaction.id!, escrowId, final, receipt?.[0]),
      );
    }

    if (isDepositSaved) {
      onClose();
    }
  };

  return (
    <ZenSidebarModal
      title={depositDetails ? 'Edit Installment' : 'Add Installment'}
      isOpen
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full mb-20'
        onSubmit={handleSubmit(onSubmit)}
        title='deposit-form'
      >
        <div>
          <div className='m-4'>
            <div>
              <ZenControlledFormattedMoneyInput<FormData, 'amount'>
                control={control}
                label={`Amount (${transaction.currency})`}
                shouldUnregister={!depositDetails}
                name='amount'
                placeholder='Please enter the amount for deposits'
                currencyReadOnly
                rules={MONEY_NON_ZERO_VALUE_VALIDATIONS}
                isRequired
              />
            </div>
            <div className='mt-5'>
              <ZenControlledDatePickerInputV2<FormData, 'dateReceived'>
                name='dateReceived'
                shouldUnregister={!depositDetails}
                control={control}
                label='Date Received'
                placeholder='Date amount was received'
                datePickerConfig={{
                  maxDate: DateTime.local().toJSDate(),
                }}
                isRequired
                rules={{
                  required: 'Please select a date',
                  ...DATE_FORMAT_VALIDATIONS,
                  ...DATE_SELECT_VALIDATIONS,
                }}
              />
            </div>
            <div className='mt-5'>
              <ZenControlledDatePickerInputV2<FormData, 'dateDeposited'>
                name='dateDeposited'
                shouldUnregister={!depositDetails}
                control={control}
                label='Date Deposited'
                placeholder='Date amount was Deposited'
                datePickerConfig={{
                  maxDate: DateTime.local().toJSDate(),
                }}
                rules={{
                  required:
                    'Please enter the date on which the amount was deposited',
                  ...DATE_FORMAT_VALIDATIONS,
                  ...DATE_SELECT_VALIDATIONS,
                }}
                isRequired
              />
            </div>
            <div className='mt-5'>
              <ZenControlledTextInput<FormData, 'depositMethod'>
                name='depositMethod'
                shouldUnregister={!depositDetails}
                control={control}
                label='Deposit Type'
                placeholder='Wire, Check, EFT, Cash, etc.'
                rules={{ required: 'Please enter a type of deposit' }}
                isRequired
              />
            </div>
            <div className='mt-5'>
              <ZenControlledTextInput<FormData, 'depositorName'>
                name='depositorName'
                shouldUnregister={!depositDetails}
                control={control}
                label='Depositor Name'
                placeholder='Depositor Name'
                rules={{
                  required: 'Please select depositor name',
                }}
                isRequired
              />
            </div>
            <div className='mt-5'>
              <ZenControlledTextInput<FormData, 'receiptNumber'>
                name='receiptNumber'
                shouldUnregister={!depositDetails}
                control={control}
                label='Deposit Reference Number / Check Number'
                placeholder='Deposit Reference Number / Check Number'
                rules={{ required: 'Please enter a receipt number ' }}
                isRequired
              />
            </div>
            {isCanadaTransaction(transaction) && (
              <>
                <div className='mt-5'>
                  <ZenControlledTextInput<FormData, 'depositorInfo'>
                    name='depositorInfo'
                    shouldUnregister={!depositDetails}
                    control={control}
                    label='Bank Branch'
                    placeholder='Bank where funds were deposited'
                  />
                </div>
                <div className='mt-5'>
                  <ZenControlledTextInput<FormData, 'sourceAccount'>
                    name='sourceAccount'
                    shouldUnregister={!depositDetails}
                    control={control}
                    label='Bank Transit Number'
                  />
                </div>
              </>
            )}
            <div className='mt-5'>
              <ZenControlledSelectInput<FormData, 'trustAccount'>
                name='trustAccount'
                shouldUnregister={!depositDetails}
                control={control}
                label='Trust Account'
                placeholder='Trust Account'
                options={getBankAccountISelectOptions(
                  officeDetails?.trustAccount,
                )}
                isRequired
                rules={{
                  required: 'Please provide a trust account',
                }}
              />
            </div>
            {isCanadaTransaction(transaction) && (
              <AdminOnly>
                <div className='mt-5'>
                  <ZenControlledSelectInput<FormData, 'exemptAmountType'>
                    name='exemptAmountType'
                    control={control}
                    shouldUnregister={!depositDetails}
                    label='Exempt Amount Type'
                    placeholder='Select Amount Type'
                    options={[
                      {
                        label: 'Select Amount Type',
                        value: '',
                      },
                      {
                        label: 'Fee',
                        value: EscrowDepositRequestExemptAmountTypeEnum.Fee,
                      },
                      {
                        label: 'Tax',
                        value: EscrowDepositRequestExemptAmountTypeEnum.Tax,
                      },
                    ]}
                    isRequired={!!exemptAmount?.amount}
                    rules={{
                      validate: (v: ISelectOption | undefined) => {
                        if (!!exemptAmount?.amount && !v?.value) {
                          return 'Please select the type of exempt amount';
                        }
                        return undefined;
                      },
                    }}
                  />
                </div>
                <div className='mt-5'>
                  <ZenControlledFormattedMoneyInput<FormData, 'exemptAmount'>
                    control={control}
                    label={`Exempt Amount (${transaction.currency})`}
                    shouldUnregister={!depositDetails}
                    name='exemptAmount'
                    placeholder='Please enter exempt amount'
                    currencyReadOnly
                    rules={{
                      validate: (v: MoneyValue | undefined) => {
                        if (!!v?.amount && v?.amount <= 0) {
                          return 'Amount should be greater than 0';
                        }
                        if (
                          !!v?.amount &&
                          !MONEY_AMOUNT_REGEX.test(v?.amount?.toString()!)
                        ) {
                          return 'Please enter valid amount';
                        }
                        return undefined;
                      },
                    }}
                  />
                </div>
                <div className='mt-5'>
                  <ZenControlledTextAreaInput<FormData, 'exemptReason'>
                    name='exemptReason'
                    shouldUnregister={!depositDetails}
                    control={control}
                    label='Exempt Reason'
                    placeholder='E.g. Please enter exempt reason'
                    rows={2}
                  />
                </div>
              </AdminOnly>
            )}
            <div className='mt-5'>
              <ZenControlledFileUploadInput<FormData, 'receipt'>
                name='receipt'
                control={control}
                label='Upload Receipt'
                placeholder={
                  getFileNameFromUrl(depositDetails?.receiptImagePath!) ||
                  'E.g. Receipt.png'
                }
                accept='.png,.jpg,.pdf'
                rightAction={
                  depositDetails?.receiptImagePath
                    ? {
                        text: 'View Receipt',
                        onClick: () => window.open(receiptFileUrl, '_blank'),
                      }
                    : undefined
                }
                rules={{
                  ...FILE_VALIDATIONS,
                }}
              />
            </div>
            <div className='mt-5'>
              <ZenControlledTextInput<FormData, 'note'>
                name='note'
                shouldUnregister={!depositDetails}
                control={control}
                label='Note'
                placeholder='Additional notes for the deposit'
              />
            </div>
          </div>
          {!!depositDetails && (
            <div className='inline-block p-4'>
              <div
                className='flex items-center cursor-pointer hover:bg-red-50 p-2 rounded'
                onClick={() => setDeleteDeposit(depositDetails)}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  className='text-error mb-1 mr-1'
                />
                <p className='text-error font-primary-medium text-sm'>
                  Delete Installment
                </p>
              </div>
            </div>
          )}
          <ZenDeleteTrustDepositInstallmentModal
            isOpen={!!deleteDeposit}
            onClose={() => setDeleteDeposit(undefined)}
            deposit={deleteDeposit!}
            transaction={transaction}
            escrowId={escrowId}
          />
        </div>
        <ZenSidebarModalActionFooter
          isSubmitting={isSubmitting}
          onClose={onClose}
          submitButtonText={depositDetails ? 'Update' : 'Add'}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenDepositAddForm;
