import { useSelector } from 'react-redux';
import { RootState, TransactionPrintTypeEnum } from '../../../types';
import { TransactionResponse } from '../../../openapi/arrakis';
import {
  getCommissionDocumentName,
  getTransactionPrintType,
  isCanadaTransaction,
} from '../../../utils/TransactionHelper';
import ZenButton from '../../Zen/ZenButton';

interface ZenApproveCommissionDocumentIntroProps {
  transaction: TransactionResponse;
  onNext(): void;
  goBack(): void;
}

const ZenApproveCommissionDocumentIntro: React.FC<ZenApproveCommissionDocumentIntroProps> = ({
  transaction,
  onNext,
  goBack,
}) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const title = `View ${
    isCanadaTransaction(transaction)
      ? 'Trade Record Sheet'
      : getTransactionPrintType(transaction.transactionType!) ===
        TransactionPrintTypeEnum.CDA
      ? 'Commission Document'
      : 'Invoice'
  }`;

  return (
    <div className='px-4 md:px-0'>
      <p className='text-xl font-zen-body font-medium text-zen-dark-9 mb-4'>
        Hi, {userDetail?.fullName} 👋
      </p>
      <p className='font-zen-body font-normal text-base text-zen-dark-9 mb-2'>
        {`Looks like it is time to review and approve your ${getCommissionDocumentName(
          transaction.country!,
        ).toLowerCase()}.`}
      </p>
      <p className='font-zen-body font-normal text-base text-zen-dark-9 mb-4'>
        Let&apos;s take a look at what will be sent to the authorized parties.
      </p>
      <div className='flex justify-between items-center space-x-3 md:space-x-0'>
        <ZenButton
          label='View Transaction'
          variant='secondary-gray-outline'
          onClick={goBack}
        />
        <ZenButton label={title} onClick={onNext} />
      </div>
    </div>
  );
};

export default ZenApproveCommissionDocumentIntro;
