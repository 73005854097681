import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressCard,
  faPhone,
  faLocationDot,
} from '@fortawesome/pro-regular-svg-icons';
import { BoardResponse } from '../../../openapi/yenta';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { capitalizeEnum, formatPhoneNumber } from '../../../utils/StringUtils';
import ProfileImage from '../../ProfileImage';
import ZenMlsOrBoardPill from '../../Zen/ZenMlsOrBoardPill';

interface ZenBoardHeaderSectionProps {
  board: BoardResponse;
}

const ZenBoardHeaderSection: React.FC<ZenBoardHeaderSectionProps> = ({
  board,
}) => {
  return (
    <div className='px-4 md:px-8 py-6 flex flex-col w-full lg:flex-row justify-between items-start bg-zen-dark-13 text-white rounded-[15px]'>
      <div className='flex flex-col w-full md:flex-row md:pb-4 lg:pb-0 md:space-x-4'>
        <div className='pb-3 mx-auto space-y-4 md:pb-0 md:mx-0'>
          <div>
            <ProfileImage
              imageUrl={board.logo!}
              width={120}
              variant='rounded'
            />
          </div>
          <div className='flex justify-center mt-2'>
            <ZenMlsOrBoardPill status={board?.status!} />
          </div>
        </div>
        <div className='font-zen-body flex flex-col md:flex-row justify-between w-full'>
          <div className='py-3 lg:py-0'>
            <h1 className='text-2xl font-zen-body font-medium mb-4'>
              {board.name}
            </h1>
            <div className='mb-2'>Code : {board.code}</div>
            <div className='mb-2'>
              Join Date :{' '}
              {DateTime.fromISO(board.joinDate!).toFormat('LL/dd/yy')}
            </div>
            <div className='mb-2'>
              Registration fee : {displayAmount(board.registrationFee)}
            </div>
          </div>
          <div className='flex flex-col items-center w-full max-w-sm pt-3 space-y-4 lg:items-start lg:pt-0'>
            <div className='w-full'>
              <div>
                <span className='text-lg text-white'>Contact Information</span>
              </div>
              <div className='p-4 border border-grey-600 rounded-lg mt-2'>
                <div className='flex flex-row items-center space-x-2'>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className='text-gray-200 text-base'
                  />
                  <p className='text-trueGray-300'>
                    {capitalizeEnum(board.administrativeArea?.stateOrProvince!)}
                  </p>
                </div>
                <div className='flex flex-row items-center mb-2 space-x-2 mt-2'>
                  <FontAwesomeIcon
                    icon={faPhone}
                    className='text-gray-200 text-lg'
                  />
                  <p className='text-trueGray-300'>
                    {formatPhoneNumber(board.phoneNumber) || 'N/A'}
                  </p>
                </div>
                <div className='flex flex-row items-center mb-2 space-x-2'>
                  <FontAwesomeIcon
                    icon={faAddressCard}
                    className='text-gray-200 text-lg'
                  />
                  <p className='text-trueGray-300'>{board.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenBoardHeaderSection;
