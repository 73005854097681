import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState, YesNoType } from '../../../types';
import { transactionCheckDepositPayAtClosingSteps } from '../../../utils/CheckDepositsHelper';
import ZenApplicationStepProgressHeader from '../../onboarding/ZenApplicationStepProgressHeader';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenControlledCheckboxInput from '../../Zen/Input/ZenControlledCheckboxInput';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenButton from '../../Zen/ZenButton';
import {
  DepositCheckFormData,
  DepositCheckStepName,
  Match,
} from '../../../routes/ZenTransactionDepositCheckRoute';
import ZenCheckDepositHeader from './ZenCheckDepositHeader';

const ZenTransactionCertifyClosing: StepByStepComponent<
  DepositCheckFormData,
  DepositCheckStepName
> = ({ form: { control, watch }, onNext }) => {
  const {
    transactionDetailResponse: { data: transactionDetail },
  } = useSelector((state: RootState) => state.transaction);
  const history = useHistory();
  const { id, escrowId } = useParams<Match>();

  const certify = () => {
    onNext();
  };

  return (
    <div className='w-full h-full max-w-3xl py-4 mx-auto bg-white'>
      <div className='flex justify-center'>
        <ZenApplicationStepProgressHeader
          steps={transactionCheckDepositPayAtClosingSteps(!watch('closeAt'))}
          inProgressIndex={0}
        />
      </div>

      <div className='flex flex-grow justify-center flex-col space-y-10 py-8'>
        <ZenCheckDepositHeader
          transactionAddress={transactionDetail?.address?.oneLine}
        />
        <ZenControlledDatePickerInput<DepositCheckFormData, `closingDate`>
          control={control}
          placeholder='MM/DD/YYYY'
          label='Closing Date'
          name={'closingDate' as const}
          shouldUnregister={false}
          rules={{ required: 'Closing date is required' }}
          datePickerConfig={{ maxDate: DateTime.local().toJSDate() }}
          icon={
            <FontAwesomeIcon
              icon={faCalendar}
              title='calendar'
              size='lg'
              className='text-gray-700 mr-0.5 ml-[2px]'
            />
          }
        />
        <ZenControlledCheckboxInput<
          DepositCheckFormData,
          'acceptTermsAndConditions'
        >
          label=''
          name='acceptTermsAndConditions'
          reverse
          options={[
            {
              label:
                'By selecting this checkbox, I certify that this transaction has closed on the day entered above, and I accept the responsibility if this transaction does not result in a payment.',
              value: YesNoType.YES,
            },
          ]}
          shouldUnregister={false}
          control={control}
          rules={{
            validate: (val) => {
              if (!val?.length) {
                return 'Select the checkbox to certify closing';
              }

              return undefined;
            },
          }}
        />
        <ZenControlledCheckboxInput<DepositCheckFormData, 'warning'>
          label=''
          name='warning'
          reverse
          options={[
            {
              label: (
                <p>
                  By selecting this checkbox, I confirm that this check is
                  issued to REAL Broker or one of its entities.
                  <span className='font-bold'>
                    Checks not issued to REAL Broker or one of its entities
                    should NOT be deposited.
                  </span>
                </p>
              ),
              value: YesNoType.YES,
            },
          ]}
          shouldUnregister={false}
          control={control}
          rules={{
            validate: (val) => {
              if (!val?.length) {
                return 'Select the checkbox to certify closing';
              }

              return undefined;
            },
          }}
        />
        <div className='grid grid-cols-2 gap-8 py-12'>
          <ZenButton
            isFullWidth
            type='button'
            variant='primary-outline'
            label='Cancel'
            onClick={() =>
              history.push(
                escrowId
                  ? `/transactions/${id}/view-deposits`
                  : `/transactions/${id}`,
              )
            }
          />
          <ZenButton
            isFullWidth
            type='submit'
            variant='primary'
            label='Certify'
            onClick={certify}
          />
        </div>
      </div>
    </div>
  );
};

export default ZenTransactionCertifyClosing;
