import React, { useState } from 'react';
import {
  InviteResponse,
  InviteResponseStatusEnum,
  MembershipResponseStatusEnum,
} from '../../openapi/avalon';
import ResourceContainer from '../ResourceContainer';
import ZenAction from '../Zen/ZenAction';
import ZenCard from '../Zen/ZenCard';
import ZenAgentJVCard from './ZenAgentJVCard';
import ZenRealJointVentureSideBarModel from './ZenRealJointVentureSideBarModel';

interface ZenRealJointVentureTiersProps {
  jointVentureInvites: InviteResponse[] | undefined;
}

const ZenRealJointVentureTiers: React.FC<ZenRealJointVentureTiersProps> = ({
  jointVentureInvites,
}) => {
  const filteredJointVentures = jointVentureInvites
    ?.filter(
      (item) =>
        item?.membership?.status === MembershipResponseStatusEnum.Active ||
        item.status === InviteResponseStatusEnum.Viewed ||
        item.status === InviteResponseStatusEnum.Created,
    )
    .slice(0, 4);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ZenCard
      title='Real JV Tiers'
      RightComponent={
        <ZenAction text='Show All' onClick={() => setIsOpen(true)} />
      }
    >
      <ResourceContainer
        isEmpty={!filteredJointVentures?.length}
        loading={false}
        resourceName='Joint Venture'
      >
        <div className='scrollbar overflow-y-auto max-h-44'>
          {filteredJointVentures?.map((jointVenture) => {
            return (
              <ZenAgentJVCard
                key={jointVenture.id}
                colorCode={jointVenture.tier?.colorCode!}
                interests={jointVenture.interests!}
                jointVentureName={jointVenture.jointVenture?.name!}
                status={jointVenture.status!}
                tierName={jointVenture.tier?.name!}
                inviteId={jointVenture.id!}
              />
            );
          })}
        </div>
      </ResourceContainer>
      {isOpen && (
        <ZenRealJointVentureSideBarModel
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          jointVentures={jointVentureInvites}
        />
      )}
    </ZenCard>
  );
};
export default ZenRealJointVentureTiers;
