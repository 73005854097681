import React from 'react';
import { Control } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { ReportFormData } from '../../routes/ReportsRoute';
import ErrorService from '../../services/ErrorService';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import {
  AppDispatch,
  AsyncSelectOptionReactElement,
  RootState,
} from '../../types';
import { administrativeAreaList } from '../../utils/AgentHelper';
import { searchForAgents } from '../../utils/TableUtils';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';
import AvatarLabelComponent from './AvatarLabelComponent';

interface BrokerAgentSelectorProps {
  control: Control<ReportFormData, object>;
}

const BrokerAgentSelector: React.FC<BrokerAgentSelectorProps> = ({
  control,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  return (
    <ZenControlledAsyncSelectInput<ReportFormData, 'agent'>
      control={control}
      name='agent'
      placeholder='Search Agent'
      label='Select Agent'
      startAdornment={
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className='text-blue-500 mx-2'
        />
      }
      endAdornment={
        <FontAwesomeIcon icon={faChevronDown} className='text-gray-500 mx-3' />
      }
      selectClassName='border-r'
      fetchData={async (search, page) => {
        try {
          const officeIds =
            userDetail?.offices!.map((office) => office.id!) || [];
          const { data } = await searchForAgents({
            page,
            search,
            officeIds,
          });

          const options: AsyncSelectOptionReactElement[] = data.map(
            (agent) => ({
              value: `${agent.id}`,
              label: (
                <AvatarLabelComponent
                  avatar={agent?.avatar!}
                  firstName={agent?.firstName!}
                  lastName={agent?.lastName!}
                  emailAddress={agent?.emailAddress!}
                  administrativeAreas={administrativeAreaList(agent)}
                />
              ),
            }),
          );

          return options;
        } catch (e) {
          ErrorService.notify(
            'Unable to search for registered agents in create team form',
            e,
            {
              search: {
                term: search,
                page,
              },
            },
          );
          dispatch(
            showErrorToast(
              'An unexpected error occurred.',
              'We were unable to search for an agent. Please try again in a few moments or contact support.',
            ),
          );
        }

        return [];
      }}
      rules={{
        required: 'Select an agent for whom you want to generate a report',
      }}
    />
  );
};

export default BrokerAgentSelector;
