import { useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ZenResourceLinkCard from '../../../ZenResourceLinkCard';
import ZenChangeAnniversaryDateSidebarModal from './sidebarForms/ZenChangeAnniversaryDateSidebarModal';

interface ZenChangeAnniversaryDateProps {}

const ZenChangeAnniversaryDate: React.FC<ZenChangeAnniversaryDateProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div>
      <ZenResourceLinkCard
        title='Change Anniversary Date'
        onClick={() => setIsOpen(true)}
        icon={solid('calendar')}
        iconSize='2x'
      />
      <ZenChangeAnniversaryDateSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenChangeAnniversaryDate;
