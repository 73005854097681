import { useLocation } from 'react-router-dom';
import qs from 'qs';

const useQueryParams = <T extends object>(): T => {
  const location = useLocation();

  return qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as T;
};

export default useQueryParams;
