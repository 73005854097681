import React from 'react';
import { FieldObject } from '../../../openapi/atlantis';
import useValidateVisibleCondition from '../hooks/useValidateVisibleCondition';
import MortgageApplicationFormFieldArrayInput from './MortgageApplicationFormFieldArrayInput';

interface MortgageApplicationConditionalFormFieldArrayInputProps {
  field: FieldObject;
  onSubmit: () => void;
  isReadOnly: boolean;
}

const MortgageApplicationConditionalFormFieldArrayInput: React.FC<MortgageApplicationConditionalFormFieldArrayInputProps> = ({
  field,
  onSubmit,
  isReadOnly,
}) => {
  const { validateFieldVisibleCondition } = useValidateVisibleCondition();
  const isVisible = validateFieldVisibleCondition(field);

  if (!isVisible) {
    return null;
  }

  return (
    <MortgageApplicationFormFieldArrayInput
      fieldArray={field}
      onSubmit={onSubmit}
      isReadOnly={isReadOnly}
    />
  );
};

export default MortgageApplicationConditionalFormFieldArrayInput;
