import Slider, { Settings } from 'react-slick';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import ReferralTransactionCard from './ReferralTrasactionCard';
import { AddressData } from './Map';

interface RecentAgentTransactionsProps {
  address: AddressData[];
}

const RecentAgentTransactions: React.FC<RecentAgentTransactionsProps> = ({
  address,
}) => {
  const sliderRef = useRef<Slider | null>(null);

  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    className: 'w-full',
  };

  return (
    <div className='p-6 bg-white rounded-lg border border-grey-200 justify-start items-start gap-4 flex w-full mb-40'>
      <div className='flex-col justify-start items-start gap-6 flex w-full'>
        <div className='w-full justify-between items-center flex'>
          <div className='justify-start items-center gap-1 flex'>
            <div className='text-primary-dark text-base font-medium font-inter leading-snug'>
              Recent Transactions
            </div>
          </div>
          <div className='justify-start items-center gap-4 flex'>
            <div
              className='w-7 h-7 px-1.5 bg-[#f7f7f7] rounded-[30px] flex-col justify-center items-center gap-2.5 flex cursor-pointer'
              onClick={() => sliderRef.current?.slickPrev()}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className='text-grey-600 text-base font-normal leading-normal'
              />
            </div>
            <div
              className='w-7 h-7 px-1.5 bg-[#f7f7f7] rounded-[30px] flex-col justify-center items-center gap-2.5 flex cursor-pointer'
              onClick={() => sliderRef.current?.slickNext()}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                className='text-grey-600 text-base font-normal leading-normal'
              />
            </div>
          </div>
        </div>
        <Slider ref={sliderRef} {...settings}>
          <ReferralTransactionCard address={address} />
          <ReferralTransactionCard address={address} />
          <ReferralTransactionCard address={address} />
        </Slider>
      </div>
    </div>
  );
};

export default RecentAgentTransactions;
