import { faUser } from '@fortawesome/pro-light-svg-icons';
import {
  faCheck,
  faClose,
  faCopy,
  faDash,
  faPencil,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faPersonWalkingArrowRight,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Route,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { CellProps, Column, Row } from 'react-table';
import ZenCapStatusTracker from '../components/Agent/ZenCapStatusTracker';
import AdminOnly from '../components/auth/AdminOnly';
import DetailPageLoader from '../components/DetailPageLoader';
import GeminiTeamDetailHeaderSection from '../components/Gemini/Teams/GeminiTeamDetailHeaderSection';
import Hover from '../components/Hover';
import IconButton from '../components/IconButton';
import ResourceContainer from '../components/ResourceContainer';
import ZenTeamStatusCell from '../components/table/Cells/ZenTeamStatusCell';
import NumberColumnFilter from '../components/table/Filters/NumberColumnFilter';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import ZenTeamAgentNameCell from '../components/Zen/Table/Cell/ZenTeamAgentNameCell';
import ZenFixedDataTable from '../components/Zen/Table/ZenFixedDataTable';
import ZenInviteSidebarModal from '../components/Zen/Teams/InviteAgent/ZenInviteAgentSidebarModal';
import ZenAddTeamMember from '../components/Zen/Teams/ZenAddTeamMember';
import ZenEditTeamForm from '../components/Zen/Teams/ZenEditTeamForm';
import ZenEditTeamMember from '../components/Zen/Teams/ZenEditTeamMember';
import ZenTeamDetailHeaderSection from '../components/Zen/Teams/ZenTeamDetailHeaderSection';
import ZenTransferTeamAgent from '../components/Zen/Teams/ZenTransferTeamAgent';
import ZenButton from '../components/Zen/ZenButton';
import ZenIconText from '../components/Zen/ZenIconText';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { AgentCapInfoResponse } from '../openapi/arrakis';
import {
  DivisionResponse,
  MoneyValueCurrencyEnum,
  TeamInvitationSimplifiedDtoInvitationStatusEnum,
  TeamMemberResponse,
  TeamMemberResponseRolesEnum,
  TeamResponseStatusEnum,
  TeamResponseTypeEnum,
  UserPreviewResponse,
  UserPreviewResponseAgentStatusEnum,
} from '../openapi/yenta';
import { fetchAgentTeamCapInfo } from '../slices/AgentSlice';
import {
  AnalyticsEventEnum,
  AppDispatch,
  FeatureFlagTypeEnum,
  RootState,
  TeamAgentsInviteTableDataType,
} from '../types';
import { calculatePercentage } from '../utils/MathUtils';
import { capitalizeEnum, formatPhoneNumber } from '../utils/StringUtils';
// import { getPriorityWiseSortedData } from '../utils/TableUtils';
import FeatureFlagEnabledOnly from '../components/FeatureFlagEnabledOnly';
import TeamInvitedMemberStatusColumnFilter from '../components/table/Filters/TeamInvitedMemberStatusColumnFilter';
import ZenInvitedMemberStatusCell from '../components/Zen/Table/Cell/ZenInvitedMemberStatusCell';
import ZenRevokeInvitation from '../components/Zen/Teams/ZenRevokeInvitationModal';
import ZenAvatar from '../components/Zen/ZenAvatar';
import useCopyToClipboard from '../hooks/useCopyToClipboard';
import AnalyticsService from '../services/AnalyticsService';
import { getTeamDetailOverview } from '../slices/TeamSlice';
import { getFullName } from '../utils/AgentHelper';
import { getPriorityWiseSortedData } from '../utils/TableUtils';
import {
  getPersonalizedEmailInvitationLink,
  getTeamCountry,
  isInvitationModificationAllowed,
  getTeamMembersTotalCount,
} from '../utils/TeamHelper';
import { getRealCapOptionsForTeam } from '../utils/teams/capUtils';

type Match = {
  id: string;
};

interface TeamAgentResponse extends UserPreviewResponse {
  capStatus?: AgentCapInfoResponse;
  capProgressPercentage?: number;
}

interface TeamAgentsTableDataType extends TeamMemberResponse {
  agent?: TeamAgentResponse;
  invitation?: TeamAgentsInviteTableDataType;
  status?:
    | TeamInvitationSimplifiedDtoInvitationStatusEnum
    | UserPreviewResponseAgentStatusEnum;
  email?: string;
}

const teamRolePriority = [
  TeamMemberResponseRolesEnum.Leader,
  TeamMemberResponseRolesEnum.Admin,
  TeamMemberResponseRolesEnum.Member,
];

export const columnWithLink: Array<Column<TeamAgentsTableDataType>> = [
  {
    Header: 'Name',
    accessor: 'agent',
    id: 'name',
    Cell: ({ row }) =>
      row.original.invitation ? (
        <div className='font-zen-body text-sm items-center w-max gap-2 bg-zen-light-blue px-2 py-1.5 rounded-full inline-flex'>
          <ZenAvatar name={getFullName(row.original.invitation!)!} size='xs' />
          {getFullName(row.original.invitation!)}
        </div>
      ) : (
        <Link
          to={`/people/${row.original.agent?.id}`}
          data-testid={row.original.agent?.avatar}
        >
          <ZenTeamAgentNameCell
            agent={row.original?.agent!}
            role={row.original?.roles![0]}
          />
        </Link>
      ),
    Filter: TextColumnFilter,
    cardColSize: 12,
    disableFilters: true,
    sortType: (row1, row2) => {
      const fullName1 = getFullName(
        row1.original.agent ?? row1.original.invitation,
      )!;
      const fullName2 = getFullName(
        row2.original.agent ?? row2.original.invitation,
      )!;

      return fullName1.localeCompare(fullName2);
    },
  },
];

export const columns: Array<Column<TeamAgentsTableDataType>> = [
  {
    Header: 'Status',
    accessor: 'status',
    id: 'status',
    Cell: ({ value }) =>
      value ? <ZenInvitedMemberStatusCell status={value!} /> : null,
    cardColSize: 6,
    Filter: TeamInvitedMemberStatusColumnFilter,
  },
  {
    Header: 'Fees Waived',
    accessor: 'agent',
    id: 'waiveFees',
    Cell: ({ row }) => (
      <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
        {/* N/A for team members and Yes || No for invited members */}
        {!row?.original?.invitation
          ? 'N/A'
          : row.original.invitation.waiveFees
          ? 'Yes'
          : 'No'}
      </div>
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Division',
    accessor: (d) => d['agent']?.divisions,
    id: 'divisions',
    Cell: ({ value }: CellProps<DivisionResponse>) => {
      const division = value?.[0];

      if (!division) {
        return <FontAwesomeIcon icon={faDash} className='text-center' />;
      }

      return (
        <ZenIconText
          icon={
            <img
              className='object-contain w-5 h-5'
              src={division.logoUrl}
              alt='agent-division'
            />
          }
          variant='small'
          text={division.name || '--'}
          textClassName='text-zen-dark-9 whitespace-pre'
        />
      );
    },
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Role',
    accessor: 'roles',
    Cell: ({ value }) => (
      <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
        {value!.map((val) => capitalizeEnum(val)).join(', ')}
      </div>
    ),
    cardColSize: 6,
    sortType: (row1, row2) =>
      getPriorityWiseSortedData(
        row1.values.roles[0],
        row2.values.roles[0],
        teamRolePriority,
      ),
    disableFilters: true,
  },
  {
    Header: 'Phone',
    accessor: 'agent',
    id: 'phoneNumber',
    Cell: ({ value }) => (
      <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
        {formatPhoneNumber(value?.phoneNumber) || 'N/A'}
      </div>
    ),
    cardColSize: 6,
    Filter: TextColumnFilter,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    id: 'emailAddress',
    Cell: ({ value }) => (
      <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
        {value}
      </div>
    ),
    disableFilters: true,
    Filter: TextColumnFilter,
    sortType: (row1, row2) =>
      row1.original.email!.localeCompare(row2.original.email!),
  },
];

const ZenTeamsDetailRoute: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const match = useRouteMatch();
  const { id: teamId } = useParams() as Match;
  const location = useLocation();
  const {
    team: { teamDetailOverview, loading, fetchErrorCode },
    auth: { isAdmin, userDetail, isBroker },
  } = useSelector((state: RootState) => state);
  const [openAddMemberForm, setOpenAddMemberForm] = useState<boolean>(false);
  const [openEditTeamForm, setOpenEditTeamForm] = useState<boolean>(false);
  const [loadingCapInfo, setLoadingCapInfo] = useState(false);
  const [capStatusById, setCapStatusById] = useState<
    Record<string, AgentCapInfoResponse>
  >({});

  const [
    editingTeamMember,
    setEditingTeamMember,
  ] = useState<null | TeamMemberResponse>(null);

  const country = teamDetailOverview?.config?.country;

  const [revokedMember, setRevokedMember] = useState<any>(null);

  const [
    transferTeamAgent,
    setTransferTeamAgent,
  ] = useState<null | TeamMemberResponse>(null);

  const [showInviteAgentSidebar, setShowInviteAgentSidebar] = useState(false);

  const teamMember = teamDetailOverview?.agents?.find(
    (agent) => agent.agent?.id === userDetail?.id,
  );

  const isUserTeamLeader = teamMember?.roles?.includes(
    TeamMemberResponseRolesEnum.Leader,
  );

  const isUserTeamAdmin = teamMember?.roles?.includes(
    TeamMemberResponseRolesEnum.Admin,
  );

  const isSelfServiceFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.SELF_SERVICE_TEAM,
  );

  const isAdminOrTeamLeader = isAdmin || isUserTeamAdmin || isUserTeamLeader;

  const showCapColumns = isAdminOrTeamLeader || isBroker;

  const fetchTeamCapInfo = useCallback(
    async (loading: boolean) => {
      if (!showCapColumns) {
        return;
      }
      if (loading) {
        setLoadingCapInfo(true);
      }
      const agentCapInfo = await dispatch(fetchAgentTeamCapInfo(teamId));
      const data: Record<string, AgentCapInfoResponse> = {};
      agentCapInfo?.capInfo?.forEach((info) => {
        data[info?.agentId!] = info;
      });
      setCapStatusById(data);
      if (loading) {
        setLoadingCapInfo(false);
      }
    },
    [dispatch, showCapColumns, teamId],
  );

  // const fetchTeamData = useCallback(() => {
  //   dispatch(getTeamDetailOverview(teamId));
  // }, [dispatch]);

  useEffect(() => {
    fetchTeamCapInfo(true);
  }, [fetchTeamCapInfo]);

  const path = [
    { title: 'Home', url: '/' },
    { title: 'Teams', url: '/teams' },
  ];

  if (teamDetailOverview) {
    path.push({
      title: `${teamDetailOverview.name}`,
      url: `/teams/${teamDetailOverview.id}`,
    });

    if (location.pathname.includes('transactions')) {
      path.push({
        title: 'Transactions',
        url: `/teams/${teamDetailOverview.id}/transactions`,
      });
    }
  }

  const leaders: TeamMemberResponse[] = teamDetailOverview?.agents?.filter(
    (agent) => agent.roles?.includes(TeamMemberResponseRolesEnum.Leader),
  )!;

  const otherAgents: TeamMemberResponse[] = teamDetailOverview?.agents?.filter(
    (agent) => !agent.roles?.includes(TeamMemberResponseRolesEnum.Leader),
  )!;

  const formatCapResetDate = (agentId: string) => {
    const date = capStatusById[agentId]?.anniversaryDate!;
    if (!date) {
      return 'N/A';
    }
    return DateTime.fromISO(date).toFormat('MM/dd/yyyy');
  };

  const userPreviewResponseToTeamAgentResponse = (
    agent: UserPreviewResponse,
  ): TeamAgentResponse => {
    const capStatus = capStatusById[agent.id!];
    let capProgressPercentage = calculatePercentage(
      capStatus?.capAmountPaid?.amount!,
      capStatus?.capAmount?.amount!,
    );
    if (capProgressPercentage > 100) {
      capProgressPercentage = 100;
    }
    return { ...agent, capStatus, capProgressPercentage };
  };

  const teamAgents: TeamAgentsTableDataType[] = [
    ...(leaders || []),
    ...(otherAgents || []),
  ].map((agents) => {
    return {
      agent: userPreviewResponseToTeamAgentResponse(agents.agent!),
      status:
        agents.agent?.agentStatus === UserPreviewResponseAgentStatusEnum.Active
          ? TeamInvitationSimplifiedDtoInvitationStatusEnum.Approved
          : UserPreviewResponseAgentStatusEnum.Inactive,

      email: agents?.agent?.emailAddress!,
      memberCommissionSplit: agents.memberCommissionSplit,
      roles: agents.roles,
    };
  });

  const invitationsViaEmail: TeamAgentsTableDataType[] = (
    teamDetailOverview?.teamInvitations || []
  )
    // hide cancelled invitations
    .filter(
      (invitation) =>
        invitation.invitationStatus !==
        TeamInvitationSimplifiedDtoInvitationStatusEnum.Cancelled,
    )
    .map((invitation) => ({
      invitation: invitation as TeamAgentsInviteTableDataType,
      status: invitation?.invitationStatus!,
      email: invitation.emailAddress,
      memberCommissionSplit: 0,
      roles: [TeamMemberResponseRolesEnum.Member],
    }));

  const invitationsViaGenericLink: TeamAgentsTableDataType[] = (
    teamDetailOverview?.pendingGenericTeamApplications || []
  ).map((invitation) => ({
    invitation: {
      capLevel: invitation.capLevel,
      emailAddress: invitation.emailAddress,
      firstName: invitation.firstName,
      lastName: invitation.lastName,
      waiveFees: invitation.waiveFees,
      invitationPending: false,
      invitationStatus: invitation.status,
    },
    status: (invitation?.status! as unknown) as TeamInvitationSimplifiedDtoInvitationStatusEnum,
    email: invitation.emailAddress!,
    memberCommissionSplit: 0,
    roles: [TeamMemberResponseRolesEnum.Member],
  }));

  const allAgents = [...teamAgents];
  if (isAdminOrTeamLeader && isSelfServiceFlagEnabled) {
    allAgents.unshift(...invitationsViaEmail, ...invitationsViaGenericLink);
  }

  const capColumns: Array<Column<TeamAgentsTableDataType>> = showCapColumns
    ? [
        ...columns,
        {
          Header: 'CAP Progress',
          accessor: (d) => d['agent']?.capProgressPercentage,
          id: 'capProgress',
          Cell: ({
            row: { original },
          }: CellProps<TeamAgentsTableDataType, string>) => (
            <ZenCapStatusTracker
              capStatus={
                original.invitation
                  ? {
                      capAmountPaid: {
                        amount: 0,
                        currency: (userDetail?.defaultCurrency as unknown) as MoneyValueCurrencyEnum,
                      },
                      capAmount: {
                        amount: original.invitation.capLevel,
                        currency: (userDetail?.defaultCurrency as unknown) as MoneyValueCurrencyEnum,
                      },
                    }
                  : capStatusById[original.agent?.id!]
              }
            />
          ),
          disableFilters: true,
        },
        {
          Header: 'CAP Reset Date',
          accessor: (d) => d['agent']?.capStatus?.anniversaryDate,
          id: 'capResetDate',
          Cell: ({
            row: { original },
          }: CellProps<TeamAgentsTableDataType, string>) => (
            <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
              {formatCapResetDate(original.agent?.id!)}
            </div>
          ),
          disableFilters: true,
        },
      ]
    : columns;

  const showEditCommissionSplit = [
    TeamResponseTypeEnum.Platinum,
    TeamResponseTypeEnum.Normal,
    TeamResponseTypeEnum.Group,
    TeamResponseTypeEnum.Domestic,
  ].includes(teamDetailOverview?.type!);

  const refetch = () => {
    dispatch(getTeamDetailOverview(teamId));
  };

  const hideRevokeMemberModal = () => {
    setRevokedMember(null);
  };

  const actionColumn: Array<Column<TeamAgentsTableDataType>> = [
    {
      Header: '',
      id: 'actions',
      Cell: ({ row }: { row: Row<TeamAgentsTableDataType> }) => {
        const [copied, copyToClipboard] = useCopyToClipboard();
        const canEdit =
          isAdmin && showEditCommissionSplit && !row?.original?.invitation;

        return (
          <div className='flex flex-nowrap space-x-3'>
            {canEdit && (
              <Hover
                hoverComponent={
                  <div className='font-zen-body font-normal text-sm text-zen-dark-9 px-2'>
                    Edit
                  </div>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <IconButton
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faPencil}
                      title='Edit'
                      className='text-zen-dark-8'
                    />
                  }
                  variant='none'
                  onClick={() => setEditingTeamMember(row.original)}
                  buttonStyle='bg-zen-light-gray-2 hover:bg-zen-dark-4 flex justify-center item-center rounded-full cursor-pointer h-8 w-8'
                />
              </Hover>
            )}

            {isInvitationModificationAllowed(
              row.original?.invitation?.invitationStatus!,
            ) && (
              <>
                <Hover
                  hoverComponent={
                    <div className='font-zen-body font-normal whitespace-nowrap text-sm text-zen-dark-9 px-2'>
                      {copied ? 'Copied!' : 'Copy Invitation Link'}
                    </div>
                  }
                  config={{ trigger: 'hover', placement: 'top' }}
                >
                  <IconButton
                    leftIcon={
                      <FontAwesomeIcon
                        icon={copied ? faCheck : faCopy}
                        className='text-lg text-zen-dark-8'
                        title='copy invitation link'
                      />
                    }
                    variant='none'
                    onClick={() => {
                      AnalyticsService.instance().logEvent(
                        AnalyticsEventEnum.SELF_SERVICE_TEAM_CLICK_COPY_INVITATION_LINK_BTN,
                      );

                      copyToClipboard(
                        getPersonalizedEmailInvitationLink(
                          row?.original?.invitation?.invitationId!,
                          row?.original?.invitation?.firstName!,
                          row?.original?.invitation?.lastName!,
                          row?.original?.invitation?.emailAddress!,
                          teamDetailOverview?.name!,
                          row?.original?.invitation?.capLevel!,
                          getTeamCountry(teamDetailOverview),
                        ),
                      );
                    }}
                    buttonStyle='bg-zen-light-gray-2 hover:bg-zen-dark-4 flex justify-center item-center rounded-full cursor-pointer h-8 w-8'
                  />
                </Hover>
                <Hover
                  hoverComponent={
                    <div className='font-zen-body whitespace-nowrap font-normal text-sm text-zen-dark-9 px-2'>
                      Revoke Invitation
                    </div>
                  }
                  config={{ trigger: 'hover', placement: 'top' }}
                >
                  <IconButton
                    leftIcon={
                      <FontAwesomeIcon
                        icon={faClose}
                        title='Revoke Invitation'
                        className='text-lg text-zen-dark-8'
                      />
                    }
                    variant='none'
                    onClick={() => {
                      AnalyticsService.instance().logEvent(
                        AnalyticsEventEnum.SELF_SERVICE_TEAM_REVOKE_INVITATION_BTN,
                      );

                      setRevokedMember(row?.original?.invitation);
                    }}
                    buttonStyle='bg-zen-light-gray-2 hover:bg-zen-dark-4 flex justify-center item-center rounded-full cursor-pointer h-8 w-8'
                  />
                </Hover>
              </>
            )}
          </div>
        );
      },
      disableSortBy: true,
    },
  ];

  const columnsWithActions: Array<Column<TeamAgentsTableDataType>> = [
    ...columnWithLink,
    ...capColumns,
    {
      Header: 'Commissions (Agent/Leader)',
      accessor: 'memberCommissionSplit',
      id: 'memberCommisionSplit',
      Cell: ({ row }) => (
        <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
          {row.original.invitation
            ? 'N/A'
            : `${row?.original?.memberCommissionSplit}% / ${
                100 - row?.original?.memberCommissionSplit!
              }%`}
        </div>
      ),
      disableFilters: true,
      Filter: NumberColumnFilter,
    },
    {
      Header: '',
      id: 'transferTeamAgent',
      Cell: ({ row }: { row: Row<TeamAgentsTableDataType> }) => (
        <>
          {!row.original.invitation && isAdmin && (
            <Hover
              hoverComponent={
                <div className='font-zen-body font-normal text-sm text-zen-dark-9 px-2'>
                  Transfer Agent
                </div>
              }
              config={{ trigger: 'hover', placement: 'top' }}
            >
              <div className='flex flex-nowrap space-x-3'>
                <IconButton
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faPersonWalkingArrowRight}
                      title='Transfer'
                      className='text-zen-dark-8'
                    />
                  }
                  variant='none'
                  onClick={() => setTransferTeamAgent(row.original)}
                  buttonStyle='bg-zen-light-gray-2 hover:bg-zen-dark-4 flex justify-center item-center rounded-full cursor-pointer h-8 w-8'
                />
              </div>
            </Hover>
          )}
        </>
      ),
      disableSortBy: true,
    },
    ...actionColumn,
  ];

  const columnsToShow = isAdmin
    ? columnsWithActions
    : isUserTeamAdmin || isUserTeamLeader
    ? [...columnWithLink, ...capColumns, ...actionColumn]
    : [...columnWithLink, ...capColumns];

  const allowedCaps = teamDetailOverview?.config?.allowedMemberCaps;

  const realCapOptions = getRealCapOptionsForTeam(allowedCaps);

  const geminiFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.GEMINI_REDESIGN,
  );

  const HeaderComponent = geminiFeatureFlagEnabled
    ? GeminiTeamDetailHeaderSection
    : ZenTeamDetailHeaderSection;

  const totalMemberCount = getTeamMembersTotalCount(
    false,
    isSelfServiceFlagEnabled && !!isAdminOrTeamLeader,
    teamDetailOverview!,
  );

  const getHiddenColumns = () => {
    if (isSelfServiceFlagEnabled && isAdminOrTeamLeader) {
      // if user is Team Leader || Team Admin || Real Admin and self service flag is enabled
      // hide waive fees and division if user is not real admin but is Team Leader || Team Admin
      if (!isAdmin) {
        return ['waiveFees', 'divisions'];
      }

      // hide divisions column if user is Team Admin || Team Leader || Real Admin
      return ['divisions'];
    }

    // hide status and waive fees if user is not Team Leader || Team Admin || Real Admin
    return ['status', 'waiveFees'];
  };

  return (
    <ZenRoute title='Teams Details'>
      <ResourceContainer
        loading={loading || loadingCapInfo}
        isEmpty={!teamDetailOverview}
        errorCode={fetchErrorCode}
        LoaderComponent={<DetailPageLoader />}
        resourceName='team detail'
      >
        <ZenPageLayout
          path={path}
          RightComponent={
            <div className='ml-auto'>
              <ZenTeamStatusCell
                status={teamDetailOverview?.status!}
                isPill={false}
              />
            </div>
          }
        >
          {!!teamDetailOverview && (
            <HeaderComponent
              teamDetailOverview={teamDetailOverview}
              country={userDetail?.accountCountry!}
              totalMemberCount={totalMemberCount}
            />
          )}
          <Route path={`${match.path}`} exact>
            <div className='px-6 pt-5 pb-10'>
              <ZenFixedDataTable<TeamAgentsTableDataType>
                header='Agents'
                columns={columnsToShow}
                resourceName='Team Member'
                data={allAgents}
                hidePagination
                hiddenColumns={getHiddenColumns()}
                hideFilters={!isSelfServiceFlagEnabled || !isAdminOrTeamLeader}
                stickyHeader
                RightComponent={
                  <div className='space-x-2 flex items-center'>
                    <AdminOnly>
                      <ZenButton
                        label='Edit Team'
                        type='button'
                        variant='secondary-light-outline'
                        LeftIconComponent={<FontAwesomeIcon icon={faPencil} />}
                        onClick={() => setOpenEditTeamForm(true)}
                      />
                    </AdminOnly>

                    <FeatureFlagEnabledOnly
                      flag={FeatureFlagTypeEnum.SELF_SERVICE_TEAM}
                    >
                      {isAdminOrTeamLeader && (
                        <ZenButton
                          label='Invite Agent'
                          type='button'
                          LeftIconComponent={
                            <FontAwesomeIcon
                              icon={faUser}
                              size='sm'
                              className='mr-1'
                            />
                          }
                          onClick={() => {
                            AnalyticsService.instance().logEvent(
                              AnalyticsEventEnum.SELF_SERVICE_TEAM_CLICK_INVITE_AGENT_BTN,
                            );

                            setShowInviteAgentSidebar(true);
                          }}
                        />
                      )}
                    </FeatureFlagEnabledOnly>

                    <AdminOnly>
                      <ZenButton
                        label='Add New Member'
                        type='button'
                        variant='primary'
                        LeftIconComponent={<FontAwesomeIcon icon={faPlus} />}
                        onClick={() => setOpenAddMemberForm(true)}
                        isDisabled={
                          teamDetailOverview?.status ===
                          TeamResponseStatusEnum.Inactive
                        }
                      />
                    </AdminOnly>
                  </div>
                }
                initialSort={{ roles: 'asc' }}
              />
            </div>

            {openAddMemberForm && (
              <ZenAddTeamMember
                isOpen
                onClose={() => setOpenAddMemberForm(false)}
                teamId={teamDetailOverview?.id!}
                teamType={teamDetailOverview?.type!}
                fetchTeamCapInfo={fetchTeamCapInfo}
                country={country}
              />
            )}

            {showInviteAgentSidebar && (
              <ZenInviteSidebarModal
                isOpen={showInviteAgentSidebar}
                onClose={() => setShowInviteAgentSidebar(false)}
                team={teamDetailOverview!}
                options={realCapOptions!}
                refetch={refetch}
              />
            )}

            {!!revokedMember && (
              <ZenRevokeInvitation
                memberToRevoke={revokedMember}
                onClose={hideRevokeMemberModal}
                onConfirm={refetch}
              />
            )}

            {!!teamDetailOverview && !!transferTeamAgent && (
              <ZenTransferTeamAgent
                team={teamDetailOverview}
                member={transferTeamAgent}
                onClose={() => setTransferTeamAgent(null)}
              />
            )}
            {!!teamDetailOverview && !!editingTeamMember && (
              <ZenEditTeamMember
                team={teamDetailOverview}
                member={editingTeamMember}
                onClose={() => setEditingTeamMember(null)}
                teamType={teamDetailOverview?.type!}
              />
            )}
            {!!teamDetailOverview && openEditTeamForm && (
              <ZenEditTeamForm
                country={userDetail?.accountCountry!}
                isOpen
                onClose={() => setOpenEditTeamForm(false)}
                team={teamDetailOverview}
              />
            )}
          </Route>
        </ZenPageLayout>
      </ResourceContainer>
    </ZenRoute>
  );
};

export default ZenTeamsDetailRoute;
