import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  ApplicationControllerApi,
  ApplicationResponse,
} from '../openapi/yenta';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import ErrorService from '../services/ErrorService';
import ResourceContainer from '../components/ResourceContainer';
import { navigateTo500ErrorPage } from '../utils/BrowserUtils';

const Print = styled.div`
  @media print {
    @page {
      size: A4;
      margin: 0.5in;
    }
  }
`;

interface RenderApplicationFormForPDFProps {
  applicationId: string;
  children: (application: ApplicationResponse) => React.ReactElement;
}

const RenderApplicationFormForPDF: React.FC<RenderApplicationFormForPDFProps> = ({
  applicationId,
  children,
}) => {
  const history = useHistory();
  const [application, setApplication] = useState<ApplicationResponse>();
  const [loading, setLoading] = useState(true);

  const fetchApplicationById = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const { data } = await new ApplicationControllerApi(
          getYentaConfiguration(),
        ).getApplication(id);
        setApplication(data);
      } catch (e) {
        ErrorService.notify('unable to fetch application', e, {
          application: { id },
        });
        navigateTo500ErrorPage(history);
      } finally {
        setLoading(false);
      }
    },
    [history],
  );

  useEffect(() => {
    fetchApplicationById(applicationId);
  }, [applicationId, fetchApplicationById]);

  return (
    <ResourceContainer
      loading={loading}
      isEmpty={!application}
      resourceName='Application'
    >
      <div className='flex justify-center items-center lg:mx-4 mx-0'>
        <div className='container mx-auto'>
          <div className='bg-gray-100 flex justify-center lg:p-16 p-0'>
            <Print>{children(application!)}</Print>
          </div>
        </div>
      </div>
    </ResourceContainer>
  );
};

export default RenderApplicationFormForPDF;
