import { faInbox } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGemini } from '../../../hooks/useGemini';
import { getUnreadMessageCountForUser } from '../../../slices/BrokerQueueSlice';
import { AppDispatch, RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';

interface ZenUnreadCountPillProps {
  isCollapsed?: boolean;
}

const ZenUnreadCountPill: React.FC<ZenUnreadCountPillProps> = ({
  isCollapsed = false,
}) => {
  const isGeminiEnabled = useGemini();

  const dispatch: AppDispatch = useDispatch();
  const {
    auth: { keymakerCurrentUser },
    brokerQueue: { unreadMessageCount },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(getUnreadMessageCountForUser(keymakerCurrentUser?.id!));
  }, [dispatch, keymakerCurrentUser?.id]);

  if (!isCollapsed && !unreadMessageCount) {
    return null;
  }

  if (isCollapsed) {
    return (
      <div className='relative -mb-2'>
        <FontAwesomeIcon icon={faInbox} />
        {!!unreadMessageCount && (
          <FontAwesomeIcon
            icon={faCircle}
            className={cn(
              'absolute text-3xs -right-px top-px',
              isGeminiEnabled ? 'text-red-600' : 'text-zen-primary-6',
            )}
          />
        )}
      </div>
    );
  }

  return (
    <div
      className={cn(
        'h-[17px] w-auto flex items-center justify-center rounded-[40px]',
        isGeminiEnabled
          ? 'bg-red-600 font-zen-body-2'
          : 'bg-count-pill font-zen-body',
      )}
      aria-label='unread-count-pill'
    >
      <p className='text-xs text-white font-medium text-center px-2'>
        {unreadMessageCount}
      </p>
    </div>
  );
};

export default ZenUnreadCountPill;
