import React from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  ApplicationApi,
  ApplicationStep,
  BorrowerDto,
  Question,
} from '../../../openapi/atlantis';
import ErrorService from '../../../services/ErrorService';
import {
  fetchBorrowerProgress,
  fetchBorrowerQuestions,
  setFormValidityById,
  setShowApplicationError,
} from '../../../slices/MortgageSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import Logger from '../../../utils/Logger';
import {
  getFormDefaultValues,
  getFormQuestionAnswers,
} from '../../../utils/mortgage/MortgageApplicationFormUtils';
import { canSubmitApplication } from '../../../utils/MortgageUtils';
import { getAtlantisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import MortgageApplicationQuestionHeader from '../MortgageApplicationQuestionHeader';
import MortgageApplicationFields from './MortgageApplicationFields';
import MortgageApplicationSegments from './MortgageApplicationSegments';

export interface MortgageApplicationFormProps {
  question: Omit<ApplicationStep, 'content'> & { content: Question };
  borrower: BorrowerDto;
}

const MortgageApplicationForm: React.FC<MortgageApplicationFormProps> = ({
  question,
  borrower,
}) => {
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: getFormDefaultValues(question),
  });

  const isReadOnly = !canSubmitApplication(borrower);

  const onSubmit = async (formData: any) => {
    if (!methods.formState.isDirty) {
      return;
    }

    try {
      const answerApplicationQuestionReq = getFormQuestionAnswers(
        question,
        formData,
      );

      await new ApplicationApi(getAtlantisConfiguration()).answerQuestion(
        borrower.id,
        answerApplicationQuestionReq,
      );

      dispatch(fetchBorrowerQuestions(borrower.id, false));
      dispatch(fetchBorrowerProgress(borrower.id, false));
      dispatch(setShowApplicationError(false));
      methods.reset({ ...formData });
    } catch (error) {
      Logger.error('Failed to answer question:', error);
      ErrorService.notify('Failed to answer question', error, {
        data: {
          questionId: question.content.id,
          borrowerId: borrower.id,
        },
      });
      dispatch(
        showErrorToast(
          'Failed to answer question',
          'Please reload the page and try again',
        ),
      );
    }
  };

  const handleOnSubmit = async () => {
    const isValid = await methods.trigger();

    if (isValid) {
      methods.handleSubmit(onSubmit)();
    }
  };

  const handleTrigger = async () => {
    const isValid = await methods.trigger();

    if (question.content.id) {
      dispatch(
        setFormValidityById({
          [question.content.id]: isValid,
        }),
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <button className='trigger hidden' onClick={handleTrigger} />
      <MortgageApplicationQuestionHeader
        title={question.content.title}
        subtitle={question.content.subtitle}
      />
      <MortgageApplicationSegments
        segments={question.content.segments}
        onSubmit={handleOnSubmit}
        isReadOnly={isReadOnly}
      />
      <MortgageApplicationFields
        fields={question.content.fields}
        onSubmit={handleOnSubmit}
        isReadOnly={isReadOnly}
      />
    </FormProvider>
  );
};

export default MortgageApplicationForm;
