import { uniq } from 'lodash';
import { useMemo } from 'react';
import { TransactionBuilderResponse } from '../openapi/arrakis';
import useAgentsInfo from './useAgentsInfo';

export const getAgentIdsToFetchForTransactionBuilder = (
  transactionBuilder: TransactionBuilderResponse,
): string[] => {
  const {
    agentsInfo,
    additionalFeesInfo,
    referralInfo,
    allCommissionRecipient,
  } = transactionBuilder!;

  const ownerAgentsId =
    agentsInfo?.ownerAgent
      // @ts-ignore
      ?.map((p) => p.agentId!)
      .filter((p) => !!p) || [];

  const coAgentsId =
    agentsInfo?.coAgents
      // @ts-ignore
      ?.map((p) => p.agentId!)
      .filter((p) => !!p) || [];

  const additionalFeesParticipantsId =
    additionalFeesInfo?.additionalFeesParticipantInfos
      ?.map((p) => p.participantId!)
      .filter((p) => !!p) || [];

  const referralAgentId =
    referralInfo?.agentParticipantInfos
      ?.map((p) => p.agentId!)
      .filter((p) => !!p) || [];

  const commissionParticipantsId: string[] =
    allCommissionRecipient
      ?.map(
        (p) =>
          //@ts-ignore
          p.agentId!,
      )
      .filter((p) => !!p) || [];

  const doubleEnderAgentId =
    transactionBuilder?.doubleEnderInfo?.doubleEnderAgentId;
  const doubleEnderIds = doubleEnderAgentId ? [doubleEnderAgentId] : [];

  return uniq([
    ...ownerAgentsId,
    ...coAgentsId,
    ...additionalFeesParticipantsId,
    ...referralAgentId,
    ...commissionParticipantsId,
    ...doubleEnderIds,
  ]);
};

export const useUpdateAgentIdsForTransactionBuilder = (
  transactionBuilder: TransactionBuilderResponse,
) => {
  const agentIds = getAgentIdsToFetchForTransactionBuilder(transactionBuilder);

  const memoedAgentIds = useMemo(() => {
    return agentIds;
  }, [agentIds]);

  useAgentsInfo(memoedAgentIds);
};
