import React from 'react';
import { Column } from 'react-table';
import {
  LicenseResponse,
  TeamResponseStatusEnum,
} from '../../../openapi/yenta';
import ZenTeamStatusCell from '../../table/Cells/ZenTeamStatusCell';
import ZenDateCell from '../Table/Cell/ZenDateCell';
import ZenFixedDataTable from '../Table/ZenFixedDataTable';

interface ZenTeamAgentLicenseDetailProps {
  license: LicenseResponse;
}

const ZenTeamAgentLicenseDetail: React.FC<ZenTeamAgentLicenseDetailProps> = ({
  license,
}) => {
  const columns: Array<Column<LicenseResponse>> = [
    {
      Header: 'License No',
      accessor: 'number',
      Cell: ({ value }) => (
        <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
          {value}
        </div>
      ),
    },
    {
      Header: 'Expires',
      accessor: 'expirationDate',
      Cell: ({ value }) => (
        <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
          <ZenDateCell date={value} />
        </div>
      ),
    },
    {
      Header: 'Complaints',
      accessor: 'knownComplaints',
      Cell: ({ value }) => (
        <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
          {value ? 'Yes' : 'No'}
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'active',
      Cell: ({ value }) => (
        <ZenTeamStatusCell
          status={
            value
              ? TeamResponseStatusEnum.Active
              : TeamResponseStatusEnum.Inactive
          }
        />
      ),
    },
  ];

  return (
    <div className='p-2 px-3'>
      <ZenFixedDataTable<LicenseResponse>
        key={license.id}
        columns={columns}
        resourceName='Agent Licenses'
        data={[license]}
        hideFilters
        hidePagination
      />
    </div>
  );
};

export default ZenTeamAgentLicenseDetail;
