import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  AgentDocumentControllerApi,
  AgentDocumentResponse,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import {
  deleteAgentFile,
  fetchAgentFiles,
  getAgentFileLink,
} from '../../slices/AgentSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import { ISelectOption, RootState } from '../../types';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import {
  FILE_VALIDATIONS,
  TEXT_CONTENT_VALIDATIONS,
} from '../../utils/Validations';
import ZenSidebarModalActionFooter from '../SidebarModal/ZenSideBarModalActionFooter';
import ZenControlledFileUploadInput from '../Zen/Input/ZenControlledFileUploadInput';
import ZenControlledMultiSelectCreatableInput from '../Zen/Input/ZenControlledMultiSelectCreatableInput';
import ZenControlledTextAreaInput from '../Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../Zen/ZenButton';
import ZenSidebarModal from '../Zen/ZenSidebarModal';

interface ZenAddAgentFileFormProps {
  isOpen: boolean;
  onClose(): void;
  agentFile?: AgentDocumentResponse | undefined;
  labelOptions: ISelectOption[];
}
interface FormData {
  fileName: string;
  docFile: any;
  description: string;
  labels: ISelectOption[];
}

const ZenAddAgentFileForm: React.FC<ZenAddAgentFileFormProps> = ({
  isOpen,
  onClose,
  agentFile,
  labelOptions,
}) => {
  const agentDetailResponse = useSelector(
    (state: RootState) => state.agentDetail?.detailResponse?.data,
  );

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      fileName: agentFile?.name || '',
      description: agentFile?.description || '',
      labels:
        agentFile?.tags?.map((tag) => ({
          label: tag,
          value: tag,
        })) || [],
    },
  });

  const onSubmit = async (formData: FormData) => {
    let file: AgentDocumentResponse | undefined = agentFile;

    if (formData.docFile) {
      try {
        const { data } = await new AgentDocumentControllerApi(
          getYentaConfiguration(),
        ).uploadDocument(agentDetailResponse?.id!, formData.docFile![0]);

        file = data;
      } catch (e) {
        dispatch(
          showErrorToast(
            'We had a problem uploading an agent file',
            'Please try again in a few moments.',
          ),
        );
        ErrorService.notify('Error uploading agent file', e, {
          agent: { id: agentDetailResponse?.id },
        });
      }
    }

    if (file) {
      try {
        await new AgentDocumentControllerApi(
          getYentaConfiguration(),
        ).editDocument(agentDetailResponse?.id!, file.id!, {
          description: formData.description,
          name: formData.fileName,
          tags: formData.labels?.map((label) => label.value) || [],
        });
        dispatch(showSuccessToast('Agent file updated successfully'));
        dispatch(fetchAgentFiles(agentDetailResponse?.id!));
        onClose();
      } catch (e) {
        dispatch(
          showErrorToast(
            'We had a problem updating an agent file',
            'Please try again in a few moments.',
          ),
        );
        ErrorService.notify('Error updating agent file', e, {
          agent: { id: agentDetailResponse?.id },
        });
      }
    }
  };

  const downloadCall = async () => {
    dispatch(getAgentFileLink(agentDetailResponse?.id!, agentFile?.id!));
  };

  const deleteFile = async () => {
    dispatch(deleteAgentFile(agentDetailResponse?.id!, agentFile?.id!));
    setOpenDeleteModal(false);
    onClose();
  };

  const handleCancelForm = () => {
    setOpenCancelModal(false);
    onClose();
  };

  return (
    <ZenSidebarModal
      title={!!agentFile ? 'Edit Agent File' : 'Add Agent File'}
      subtitle={
        !!agentFile
          ? 'Edit and replace agent file. Supported files: PDF, Doc, Docx, Xls, and Xlsx.'
          : 'Add and upload a new file. Supported files includes PDF, DOC, Docx, Xls, and Xlsx'
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='h-full p-4'>
        <form
          className='flex flex-col h-full'
          onSubmit={handleSubmit(onSubmit)}
          title={!!agentFile ? 'edit-agent-file-form' : 'add-agent-file-form'}
          encType='multipart/form-data'
        >
          <div className='flex-grow'>
            <ZenControlledTextInput<FormData, 'fileName'>
              control={control}
              label='File Name'
              name='fileName'
              placeholder='Ex. Tax form 1099'
              rules={{
                required: 'Please enter file name.',
                validate: (val) => !!val?.trim(),
              }}
              isRequired
            />
            <div className='mt-5'>
              <ZenControlledTextAreaInput<FormData, 'description'>
                control={control}
                label='Description'
                placeholder='Description'
                name='description'
                rows={3}
                rules={{
                  ...TEXT_CONTENT_VALIDATIONS,
                }}
              />
            </div>
            <div className='mt-5'>
              <ZenControlledFileUploadInput<FormData, 'docFile'>
                name='docFile'
                control={control}
                label='Upload file'
                placeholder={
                  !agentFile ? '.pdf, doc, docx, xls, xlsx' : agentFile.name
                }
                accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*'
                readonly={!!agentFile}
                rules={{
                  required: !agentFile ? 'File is required' : undefined,
                  ...FILE_VALIDATIONS,
                }}
                isRequired
              />
            </div>
            {agentFile && (
              <div className='mt-1.5 -ml-2'>
                <ZenButton
                  LeftIconComponent={
                    <FontAwesomeIcon
                      icon={regular('cloud-arrow-down')}
                      className='text-base mr-1'
                    />
                  }
                  label='Download File'
                  variant='primary-link'
                  onClick={downloadCall}
                />
              </div>
            )}
            <div className='mt-5'>
              <ZenControlledMultiSelectCreatableInput<FormData, 'labels'>
                control={control}
                name='labels'
                label='Labels/Tags'
                placeholder='Add label...'
                placeholderLabel='Labels:'
                shouldUnregister
                options={labelOptions}
              />
            </div>
            {agentFile && (
              <div className='mt-1.5 -ml-2'>
                <ZenButton
                  LeftIconComponent={
                    <FontAwesomeIcon
                      icon={regular('trash-can')}
                      className='text-base mr-1'
                    />
                  }
                  label='Delete Agent File'
                  variant='danger-link'
                  onClick={() => setOpenDeleteModal(true)}
                />
              </div>
            )}
          </div>
          <ZenSidebarModalActionFooter
            onClose={() => setOpenCancelModal(true)}
            submitButtonDisabled={isSubmitting}
            isSubmitting={isSubmitting}
          />
        </form>
      </div>
      <ZenConfirmationModal
        isOpen={openDeleteModal || openCancelModal}
        variant='danger'
        title={openDeleteModal ? 'Delete agent file' : 'Discard changes?'}
        cancelButtonText='No'
        confirmButtonText='Yes'
        subtitle={
          openDeleteModal
            ? `This will delete "${agentFile?.name}" and associated information permanently`
            : `Changes will be lost. Any changes or uploaded file won't be saved.`
        }
        onClose={() =>
          openDeleteModal
            ? setOpenDeleteModal(false)
            : setOpenCancelModal(false)
        }
        onConfirm={openDeleteModal ? deleteFile : handleCancelForm}
      />
    </ZenSidebarModal>
  );
};

export default ZenAddAgentFileForm;
