import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AgentWebsiteOnboardingSiteInfoAccordionName,
  AgentWebsiteOnboardingState,
} from '../types';

export const initialState: AgentWebsiteOnboardingState = {
  defaultAccordionOpenType: null,
};

const AgentWebsiteOnboardingSlice = createSlice({
  name: 'agentWebsiteOnboardingSlice',
  initialState,
  reducers: {
    setDefaultAccordionOpenType(
      state,
      action: PayloadAction<AgentWebsiteOnboardingSiteInfoAccordionName>,
    ) {
      state.defaultAccordionOpenType = action.payload;
    },
  },
});

export const {
  setDefaultAccordionOpenType,
} = AgentWebsiteOnboardingSlice.actions;

export default AgentWebsiteOnboardingSlice.reducer;
