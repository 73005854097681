import { cn } from '../../../../utils/classUtils';

interface FileCabinetActionCardProps {
  icon?: React.ReactElement;
  name: string;
  onClick?: () => void;
  selected?: boolean;
  onButtonClick?: () => void;
}
const FileCabinetActionCard: React.FC<FileCabinetActionCardProps> = ({
  name,
  onClick,
  selected,
  icon,
  onButtonClick,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <div onClick={handleClick} className='cursor-pointer mr-2'>
      <div
        onClick={onButtonClick}
        className={cn(
          'flex flex-row justify-between items-center px-5',
          selected &&
            'rounded-lg border-2 py-1 px-9 bg-primary-light items-center border-regent-600',
        )}
      >
        {!!icon && (
          <div
            className={`text-xs self-center font-light ${
              selected ? 'text-primary-dark' : 'text-grey-500'
            }`}
          >
            {icon}
          </div>
        )}
        <div
          className={`px-1 font-normal text-base whitespace-nowrap ${
            selected ? 'text-primary-dark' : 'text-grey-500'
          }`}
        >
          {name}
        </div>
      </div>
    </div>
  );
};
export default FileCabinetActionCard;
