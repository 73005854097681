interface ZenFormErrorMessageProps {
  message?: string;
}

const ZenFormErrorMessage: React.FC<ZenFormErrorMessageProps> = ({
  message,
}) => {
  return <p className='text-zen-danger text-sm'>{message}</p>;
};

export default ZenFormErrorMessage;
