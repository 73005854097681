import React from 'react';
import { Control } from 'react-hook-form-v7';
import { values } from 'lodash';
import ZenControlledSelectInput from '../../Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../../Input/ZenControlledToggleInput';
import ZenControlledCheckButtonInput from '../../Input/ZenControlledGroupCheckButtonInput';
import ZenControlledGroupRadioButtonInputV2 from '../../ZenControlledGroupRadioButtonInputV2';
import { ZenCreateTeamFormData } from '../ZenCreateTeamForm';
import {
  MONEY_VALIDATIONS,
  NUMBER_VALIDATIONS,
} from '../../../../utils/Validations';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import {
  CreateFlexTeamConfigCdaApproverEnum,
  UpdateFlexTeamPermittedTransactionEditorsEnum,
} from '../../../../openapi/yenta';
import MaximumTeammatePopover from './ProTeamPopover/MaximumTeammatePopover';
import MaximumLeadersPopover from './ProTeamPopover/MaximumLeadersPopover';
import MinimumLeaderSplitPopover from './ProTeamPopover/MinimumLeaderSplitPopover';
import AvailableRealCapLevelPopover from './ProTeamPopover/AvailableRealCapLevelPopover';
import LeadersRealCapPopover from './ProTeamPopover/LeadersRealCapPopover';
import TeamCapPopover from './ProTeamPopover/TeamCapPopover';
import TeamPreCapFeePopover from './ProTeamPopover/TeamPreCapFeePopover';
import TeamPostCapFeePopover from './ProTeamPopover/TeamPostCapFeePopover';

interface AddConfigFlexTeamProps {
  control: Control<ZenCreateTeamFormData>;
}

const AddConfigFlexTeam: React.FC<AddConfigFlexTeamProps> = ({ control }) => {
  return (
    <>
      <div className='mt-5'>
        <ZenControlledSelectInput<
          ZenCreateTeamFormData,
          'commissionDocumentApprover'
        >
          name='commissionDocumentApprover'
          placeholder='Commission Document Approver'
          control={control}
          shouldUnregister={false}
          options={[
            ...values(CreateFlexTeamConfigCdaApproverEnum).map((role) => ({
              value: role,
              label: capitalizeEnum(role),
            })),
          ]}
          isRequired
          rules={{
            required: 'Commission Document Approver is required',
          }}
          label='Commission Document Approver'
        />
      </div>
      <div className='mt-5'>
        <ZenControlledSelectInput<
          ZenCreateTeamFormData,
          'permittedTransactionEditors'
        >
          name='permittedTransactionEditors'
          placeholder='Permitted Transaction Editors'
          control={control}
          shouldUnregister={false}
          isRequired
          rules={{
            required: 'Please select a transaction editor',
          }}
          options={[
            ...values(UpdateFlexTeamPermittedTransactionEditorsEnum).map(
              (role) => ({
                value: role,
                label: capitalizeEnum(role),
              }),
            ),
          ]}
          label='Permitted Transaction Editors'
        />
      </div>
      <div className='mt-5'>
        <div className='font-inter text-sm text-zen-dark-9 mb-5'>
          Leaders Real Cap <span className='text-zen-danger'>*</span>
          <LeadersRealCapPopover />
        </div>
        <ZenControlledGroupRadioButtonInputV2<
          ZenCreateTeamFormData,
          'leaderCap'
        >
          options={[
            { value: '4000', label: '4K' },
            { value: '6000', label: '6K' },
            { value: '12000', label: '12K' },
          ]}
          control={control}
          name='leaderCap'
          isRequired
          rules={{
            required: 'Leader Cap is required',
          }}
          minWidth='w-60'
        />
      </div>
      <div className='mt-5'>
        <div className='font-inter text-sm text-zen-dark-9 mb-5'>
          Available Real Cap for Teammates
          <AvailableRealCapLevelPopover />
        </div>
        <ZenControlledCheckButtonInput<ZenCreateTeamFormData, 'realCap'>
          options={[
            { value: '4000', label: '4K' },
            { value: '6000', label: '6K' },
            { value: '12000', label: '12K' },
          ]}
          control={control}
          minWidth='w-60'
          name='realCap'
        />
      </div>
      <div className='space-y-3 mt-8'>
        <div className='flex flex-row justify-between items-center'>
          <div className='font-inter text-sm text-zen-dark-9'>
            Maximum Teammates <span className='text-zen-danger'>*</span>
            <MaximumTeammatePopover />
          </div>
          <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
            <ZenControlledTextInput<ZenCreateTeamFormData, 'maxTeamMates'>
              type='text'
              rules={{
                ...NUMBER_VALIDATIONS,
                required: 'Maximum Teammates is required',
              }}
              name='maxTeamMates'
              shouldUnregister={false}
              placeholder='Maximum Teammates'
              control={control}
              isRequired
            />
          </div>
        </div>
        <div className='flex flex-row justify-between items-center'>
          <div className='font-inter text-sm text-zen-dark-9'>
            Maximum Leaders <span className='text-zen-danger'>*</span>
            <MaximumLeadersPopover />
          </div>
          <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
            <ZenControlledTextInput<ZenCreateTeamFormData, 'maxLeaders'>
              type='text'
              rules={{
                ...NUMBER_VALIDATIONS,
                required: 'Maximum Leaders is required',
              }}
              name='maxLeaders'
              shouldUnregister={false}
              control={control}
              placeholder='Maximum Leaders'
              isRequired
            />
          </div>
        </div>

        <div className='flex flex-row justify-between items-center'>
          <div className='font-inter text-sm text-zen-dark-9'>
            Minimum Leader Split <span className='text-zen-danger'>*</span>
            <MinimumLeaderSplitPopover />
          </div>
          <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
            <ZenControlledTextInput<ZenCreateTeamFormData, 'minLeaderSplit'>
              type='text'
              rules={{
                ...NUMBER_VALIDATIONS,
                min: {
                  value: 0,
                  message: 'Number cannot be less than 0',
                },
                max: {
                  value: 100,
                  message: 'Number cannot be greater than 100',
                },
                required: 'Please enter a minimum leader split',
              }}
              name='minLeaderSplit'
              shouldUnregister={false}
              placeholder='Minimum Leader Split'
              control={control}
              isRequired
            />
          </div>
        </div>

        <div className='flex flex-row justify-between items-center'>
          <TeamCapPopover />
          <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
            <ZenControlledTextInput<ZenCreateTeamFormData, 'teamFee'>
              placeholder='Team Cap'
              control={control}
              name='teamFee'
              shouldUnregister={false}
              isRequired
              rules={{
                ...MONEY_VALIDATIONS,
                required: 'Please enter a team cap amount',
                validate: (value) => {
                  if (value && Number(value) <= 0) {
                    return 'Team cap amount must be greater than 0';
                  }

                  return undefined;
                },
              }}
            />
          </div>
        </div>
        <div className='flex flex-row justify-between items-center'>
          <TeamPreCapFeePopover noMarginTop />
          <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
            <ZenControlledTextInput<ZenCreateTeamFormData, 'teamPreCapFee'>
              placeholder='Team Pre Cap Fee'
              control={control}
              name='teamPreCapFee'
              shouldUnregister={false}
              isRequired
              rules={{
                ...MONEY_VALIDATIONS,
                required: 'Please enter a team pre cap fee',
              }}
            />
          </div>
        </div>
        <div className='flex flex-row justify-between items-center'>
          <TeamPostCapFeePopover />
          <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
            <ZenControlledTextInput<ZenCreateTeamFormData, 'teamPostCapFee'>
              control={control}
              placeholder='Team Post Cap Fee'
              name='teamPostCapFee'
              shouldUnregister={false}
              isRequired
              rules={{
                ...MONEY_VALIDATIONS,
                required: 'Please enter a team post cap fee',
              }}
            />
          </div>
        </div>
      </div>
      <div className='mt-8 pb-10'>
        <div className='divide-y border border-zen-dark-4 rounded-lg'>
          <div className='p-3'>
            <ZenControlledToggleInput<
              ZenCreateTeamFormData,
              'leaderPaymentDetailsVisibility'
            >
              control={control}
              name='leaderPaymentDetailsVisibility'
              shouldUnregister={false}
              label='Allow Leader to change Payment Visibility'
            />
          </div>
          <div className='p-3'>
            <ZenControlledToggleInput<ZenCreateTeamFormData, 'leaderCDARoles'>
              control={control}
              name='leaderCDARoles'
              shouldUnregister={false}
              label='Allow Leader to change CDA Approval Roles'
            />
          </div>
          <div className='p-3'>
            <ZenControlledToggleInput<
              ZenCreateTeamFormData,
              'permittedTransactionToggle'
            >
              control={control}
              name='permittedTransactionToggle'
              shouldUnregister={false}
              label='Allow Leader to change Permitted Transaction Editors'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddConfigFlexTeam;
