import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TransactionControllerApi,
  TransactionResponse,
  UpdateDealRequest,
} from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { fetchTransactionDetails } from '../../../slices/TransactionSlice';
import { AppDispatch } from '../../../types';
import {
  displayPercentage,
  getFixedDecimalMoneyValue,
  numberWithCommas,
} from '../../../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import {
  getTransactionPriceLabel,
  isReferralTransaction,
} from '../../../utils/TransactionHelper';
import { isListingCommissionRequiredForTransaction } from '../../../utils/TransactionUtils';
import {
  MONEY_VALIDATIONS,
  getRangeAmountValidationRules,
} from '../../../utils/Validations';
import ZenControlledCurrencyInput from '../Input/ZenControlledCurrencyInput';
import ZenControlledDatePickerInput from '../Input/ZenControlledDatePickerInput';
import ZenControlledPercentageInput from '../Input/ZenControlledPercentageInput';
import ZenControlledToggleInput from '../Input/ZenControlledToggleInput';
import ZenSidebarModalForm from '../ZenSidebarModalForm';

interface ZenEditDealInformationFormProps {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
}

const ZenEditDealInformationForm: React.FC<ZenEditDealInformationFormProps> = ({
  isOpen,
  onClose,
  transaction,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const propertyName = transaction.address?.oneLine!;
  const referralTransaction = isReferralTransaction(transaction);

  const {
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<UpdateDealRequest>({
    defaultValues: {
      acceptanceDate: transaction.contractAcceptanceDate,
      estimatedClosingDate: transaction.skySlopeEscrowClosingDate,
      salePrice: getFixedDecimalMoneyValue(transaction.price!),
      listingDate: transaction.listingDate,
      listingExpirationDate: transaction.listingExpirationDate,
      listingCommission: {
        commissionAmount: getFixedDecimalMoneyValue(
          transaction.listingCommissionAmount!,
        ),
        commissionPercent: +(
          (transaction.listingCommissionPercent || 0) * 100
        ).toFixed(2),
        percentEnabled: !!transaction.listingCommissionPercent,
      },
      saleCommission: {
        commissionAmount: getFixedDecimalMoneyValue(
          transaction.saleCommissionAmount!,
        ),
        commissionPercent: +(
          (transaction.saleCommissionPercent || 0) * 100
        ).toFixed(2),
        percentEnabled: !!transaction.saleCommissionPercent,
      },
    },
  });

  const isListingCommissionRequired = isListingCommissionRequiredForTransaction(
    transaction,
  );

  const onSubmit = async (formData: UpdateDealRequest) => {
    try {
      const dealInfoData: UpdateDealRequest = {
        listingCommission: {
          commissionAmount: !isListingPercent
            ? {
                amount:
                  formData.listingCommission.commissionAmount?.amount || 0,
                currency:
                  formData.listingCommission.commissionAmount?.currency ||
                  transaction.currency,
              }
            : undefined,
          commissionPercent: isListingPercent
            ? +(
                (formData.listingCommission.commissionPercent || 0) / 100
              ).toFixed(4)
            : undefined,
          percentEnabled: formData.listingCommission.percentEnabled,
        },
        saleCommission: {
          commissionAmount: !isSalePercent
            ? {
                amount: formData.saleCommission.commissionAmount?.amount || 0,
                currency:
                  formData.saleCommission.commissionAmount?.currency ||
                  transaction.currency,
              }
            : undefined,
          commissionPercent: isSalePercent
            ? +((formData.saleCommission.commissionPercent || 0) / 100).toFixed(
                4,
              )
            : undefined,
          percentEnabled: formData.saleCommission.percentEnabled,
        },
        salePrice: formData.salePrice,
      };

      if (transaction.listing) {
        dealInfoData.listingDate = formData.listingDate;
        dealInfoData.listingExpirationDate = formData.listingExpirationDate;
      } else {
        dealInfoData.acceptanceDate = formData.acceptanceDate;
        dealInfoData.estimatedClosingDate = formData.estimatedClosingDate;
      }

      await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).updateDealInfo(transaction.id!, dealInfoData);
      await dispatch(fetchTransactionDetails(transaction.id!, false));
      dispatch(showSuccessToast('Successfully updated the deal information.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      dispatch(
        showErrorToast(
          'We encountered an error while updating the deal information. Please try again in a few moments.',
        ),
      );
      ErrorService.notifyIgnoreAuthErrors(
        'Unable to update deal information',
        e,
      );
    } finally {
      onClose();
    }
  };

  const salePrice = watch('salePrice')?.amount || 0;
  const isListingPercent = watch('listingCommission.percentEnabled');
  const isSalePercent = watch('saleCommission.percentEnabled');
  const listingAmount = watch('listingCommission.commissionAmount.amount') || 0;
  const listingPercent = watch('listingCommission.commissionPercent') || 0;
  const saleAmount = watch('saleCommission.commissionAmount.amount') || 0;
  const salePercent = watch('saleCommission.commissionPercent') || 0;
  const [listingDate, listingExpirationDate] = watch([
    'listingDate',
    'listingExpirationDate',
  ]);

  const calculateCommissionAmount = (
    percentage: number,
    totalAmount: number,
  ): string => {
    return Math.abs((+percentage / 100) * totalAmount).toFixed(2);
  };

  const calculateCommissionPercentage = (
    amount: number,
    totalAmount: number,
  ): string => {
    return ((+amount / totalAmount) * 100).toFixed(2);
  };

  useEffect(() => {
    if (isListingPercent) {
      setValue(
        'listingCommission.commissionAmount.amount',
        +calculateCommissionAmount(listingPercent, salePrice),
      );
    } else {
      setValue(
        'listingCommission.commissionPercent',
        +calculateCommissionPercentage(listingAmount, salePrice),
      );
    }
  }, [salePrice, isListingPercent, listingPercent, listingAmount, setValue]);

  useEffect(() => {
    if (isSalePercent) {
      setValue(
        'saleCommission.commissionAmount.amount',
        +calculateCommissionAmount(salePercent, salePrice),
      );
    } else {
      setValue(
        'saleCommission.commissionPercent',
        +calculateCommissionPercentage(saleAmount, salePrice),
      );
    }
  }, [salePrice, isSalePercent, saleAmount, salePercent, setValue]);

  return (
    <ZenSidebarModalForm
      title='Edit Deal Information'
      subtitle={propertyName}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Save'
      isSubmitting={isSubmitting}
    >
      <div>
        <div className='flex flex-row space-x-4 mt-2'>
          <ZenControlledCurrencyInput<UpdateDealRequest, 'salePrice.amount'>
            control={control}
            label={
              transaction.listing
                ? `List Price (${transaction?.currency})`
                : `${getTransactionPriceLabel(transaction.transactionType!)} (${
                    transaction?.currency
                  })`
            }
            startAdornment={
              <div className='font-zen-body text-base font-medium h-full w-10 flex items-center justify-center bg-gray-100'>
                $
              </div>
            }
            name='salePrice.amount'
            rules={{
              ...getRangeAmountValidationRules({ min: 1 }),
            }}
            shouldUnregister={false}
            isRequired
          />
        </div>
        {!referralTransaction && (
          <div>
            <div className='mt-5'>
              <div>
                <span className='font-zen-body font-semibold text-base text-zen-dark-9'>
                  Listing Commission
                </span>
                {isListingCommissionRequired && (
                  <span className='text-zen-danger'>*</span>
                )}
              </div>
              <div className='flex flex-row items-center md:gap-x-3'>
                <div className='flex flex-row items-center md:gap-x-2'>
                  <div>
                    <ZenControlledToggleInput<
                      UpdateDealRequest,
                      'listingCommission.percentEnabled'
                    >
                      label=''
                      name='listingCommission.percentEnabled'
                      control={control}
                      shouldUnregister={false}
                      defaultValue={false}
                      rightIcon='%'
                      leftIcon='$'
                      hideErrorMessage
                    />
                  </div>
                  <div
                    className={classNames(
                      'mt-1',
                      isListingPercent ? 'block' : 'hidden',
                    )}
                  >
                    <ZenControlledPercentageInput<
                      UpdateDealRequest,
                      'listingCommission.commissionPercent'
                    >
                      control={control}
                      startAdornment={
                        <div className='font-zen-body text-base font-medium h-full w-8 flex items-center justify-center bg-gray-100'>
                          %
                        </div>
                      }
                      name='listingCommission.commissionPercent'
                      shouldUnregister={false}
                      rules={{
                        required: isListingCommissionRequired
                          ? 'Listing commission is required'
                          : undefined,
                      }}
                      placeholder='Percentage'
                      hideErrorMessage
                    />
                  </div>

                  <div
                    className={classNames(
                      'mt-1 flex rounded shadow-sm',
                      isListingPercent ? 'hidden' : 'block',
                    )}
                  >
                    <ZenControlledCurrencyInput<
                      UpdateDealRequest,
                      'listingCommission.commissionAmount.amount'
                    >
                      startAdornment={
                        <div className='font-zen-body text-base font-medium h-full w-8 flex items-center justify-center bg-gray-100'>
                          $
                        </div>
                      }
                      control={control}
                      name='listingCommission.commissionAmount.amount'
                      shouldUnregister={false}
                      rules={{
                        required: isListingCommissionRequired
                          ? 'Listing commission is required'
                          : undefined,
                        ...MONEY_VALIDATIONS,
                      }}
                      placeholder='Amount'
                      hideErrorMessage
                    />
                  </div>
                </div>
                <div className='mx-5'>
                  {isListingPercent
                    ? `${transaction?.currency} ${numberWithCommas(
                        Number(listingAmount)?.toFixed(2),
                      )}`
                    : displayPercentage(listingPercent)}
                </div>
              </div>
              <p className='text-zen-danger text-sm pt-1'>
                {errors?.listingCommission?.commissionPercent?.message ||
                  errors?.listingCommission?.commissionAmount?.amount?.message}
              </p>
            </div>

            <div className='mt-5'>
              <div>
                <span className='font-zen-body text-base font-semibold text-zen-dark-9'>
                  Sale Commission
                </span>
                <span className='text-zen-danger'>*</span>
              </div>
              <div className='flex flex-row items-center md:gap-x-3'>
                <div className='flex flex-row items-center md:gap-x-2'>
                  <div>
                    <ZenControlledToggleInput<
                      UpdateDealRequest,
                      'saleCommission.percentEnabled'
                    >
                      label=''
                      name='saleCommission.percentEnabled'
                      control={control}
                      shouldUnregister={false}
                      defaultValue={false}
                      rightIcon='%'
                      leftIcon='$'
                      hideErrorMessage
                    />
                  </div>
                  <div
                    className={classNames(
                      'mt-1',
                      isSalePercent ? 'block' : 'hidden',
                    )}
                  >
                    <ZenControlledPercentageInput<
                      UpdateDealRequest,
                      'saleCommission.commissionPercent'
                    >
                      control={control}
                      name='saleCommission.commissionPercent'
                      shouldUnregister={false}
                      rules={{
                        required: 'Sale commission is required',
                      }}
                      startAdornment={
                        <div className='font-zen-body text-base font-medium h-full w-8 flex items-center justify-center bg-gray-100'>
                          %
                        </div>
                      }
                      placeholder='Percentage'
                      hideErrorMessage
                    />
                  </div>
                  <div
                    className={classNames(
                      'mt-1 flex rounded shadow-sm',
                      isSalePercent ? 'hidden' : 'block',
                    )}
                  >
                    <ZenControlledCurrencyInput<
                      UpdateDealRequest,
                      'saleCommission.commissionAmount.amount'
                    >
                      control={control}
                      name='saleCommission.commissionAmount.amount'
                      startAdornment={
                        <div className='font-zen-body text-base font-medium h-full w-8 flex items-center justify-center bg-gray-100'>
                          $
                        </div>
                      }
                      shouldUnregister={false}
                      rules={{
                        required: 'Sale commission is required',
                        ...MONEY_VALIDATIONS,
                      }}
                      placeholder='Amount'
                      hideErrorMessage
                    />
                  </div>
                </div>
                <div className='mx-5'>
                  {isSalePercent
                    ? `${transaction?.currency} ${numberWithCommas(
                        Number(saleAmount)?.toFixed(2),
                      )}`
                    : displayPercentage(salePercent)}
                </div>
              </div>
              <p className='text-zen-danger text-sm pt-1'>
                {errors?.saleCommission?.commissionPercent?.message ||
                  errors?.saleCommission?.commissionAmount?.amount?.message}
              </p>
            </div>
          </div>
        )}

        {transaction.listing ? (
          <>
            <div className='mt-5'>
              <ZenControlledDatePickerInput<UpdateDealRequest, 'listingDate'>
                control={control}
                label='Listing Start Date'
                name='listingDate'
                shouldUnregister={false}
                placeholder='MM/DD/YYYY'
                rules={{
                  required: 'Listing Date is Required',
                }}
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    title='calendar'
                    className='text-gray-700'
                    size='lg'
                  />
                }
                isRequired
                onChangeSpy={(date) => {
                  const startDateInISO = DateTime.fromJSDate(date).toISODate();

                  if (startDateInISO > listingExpirationDate!) {
                    setValue('listingExpirationDate', undefined);
                  }
                }}
              />
            </div>
            <div className='mt-5'>
              <ZenControlledDatePickerInput<
                UpdateDealRequest,
                'listingExpirationDate'
              >
                control={control}
                label='Expiration Date'
                name='listingExpirationDate'
                shouldUnregister={false}
                placeholder='MM/DD/YYYY'
                datePickerConfig={{
                  minDate: listingDate
                    ? DateTime.fromISO(listingDate).toJSDate()
                    : undefined,
                }}
                rules={{
                  required: 'Expiration Date is Required',
                }}
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    title='calendar'
                    className='text-gray-700'
                    size='lg'
                  />
                }
                isRequired
              />
            </div>
          </>
        ) : (
          <>
            {!referralTransaction && (
              <div className='mt-5'>
                <ZenControlledDatePickerInput<
                  UpdateDealRequest,
                  'acceptanceDate'
                >
                  control={control}
                  name='acceptanceDate'
                  placeholder='MM/DD/YYYY'
                  label='Acceptance Date'
                  datePickerConfig={{
                    maxDate: DateTime.local().toJSDate(),
                  }}
                  rules={{
                    required: 'Acceptance Date is Required',
                  }}
                  icon={
                    <FontAwesomeIcon
                      icon={faCalendar}
                      title='calendar'
                      className='text-gray-700'
                      size='lg'
                    />
                  }
                  shouldUnregister={false}
                  isRequired
                />
              </div>
            )}
            <div className='mt-5'>
              <ZenControlledDatePickerInput<
                UpdateDealRequest,
                'estimatedClosingDate'
              >
                control={control}
                name='estimatedClosingDate'
                placeholder='MM/DD/YYYY'
                label='Estimated Closing Date'
                rules={{
                  required: 'Estimated Closing Date is Required',
                }}
                shouldUnregister={false}
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    title='calendar'
                    className='text-gray-700'
                    size='lg'
                  />
                }
                isRequired
              />
            </div>
          </>
        )}
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenEditDealInformationForm;
