import qs from 'qs';
import React from 'react';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import useQueryParams from '../hooks/useQueryParams';
import { useRefresh } from '../hooks/useRefresh';
import GeminiTab from './Gemini/Tabs/GeminiTab';
import { TableResourceFilter } from './Zen/Containers/ZenResourceIndexContainer';

// Define types and interfaces for tab configuration and props
interface TabItem<D extends object> {
  key: string;
  title: string;
  filter?: TableResourceFilter<D>;
}

export enum TabsTypeEnum {
  MAIN_TABS = 'MAIN_TABS',
  SUB_TABS = 'SUB_TABS',
}

export type TabsConfig<D extends object> = {
  [key in keyof typeof TabsTypeEnum]: {
    tabs: TabItem<D>[];
    queryKey: string;
    rightSideComponent?: React.ReactNode;
  };
};

export interface NestedTabsWithRouterProps<D extends object> {
  basePath: string;
  tabsConfig: TabsConfig<D>;
  tabComponent: (mainTabKey: string, subTabKey: string) => React.ReactElement;
  clearQueryParamsOnTabChange?: boolean;
}

/**
 * NestedTabsWithRouter Component
 * @description A component for rendering nested tabs with dynamic routing based on React Router.
 * @param basePath The base path for constructing tab links.
 * @param tabsConfig Configuration object defining main and sub tabs.
 * @param tabComponent Function to render content based on selected tabs.
 */
const NestedTabsWithRouter = <D extends object>({
  basePath,
  tabsConfig,
  tabComponent,
  clearQueryParamsOnTabChange = false,
}: NestedTabsWithRouterProps<D>) => {
  // Custom hook to handle query params
  const query = useQueryParams();
  // Custom hook to handle refresh functionality
  const { key: refreshKey, refresh } = useRefresh();

  // React Router hook to get current match information
  const match = useRouteMatch();
  const params = match?.params as { [key: string]: string };

  // Query keys for accessing tab keys from params
  const mainTabQueryKey = tabsConfig.MAIN_TABS.queryKey;
  const subTabQueryKey = tabsConfig.SUB_TABS.queryKey;

  // Right side component for sub tabs (if provided)
  const subTabRightSideComponent = tabsConfig.SUB_TABS.rightSideComponent;

  return (
    <>
      {/* Main tabs navigation */}
      <div className='flex items-center space-x-2 border-b-2 border-b-regent-300'>
        {tabsConfig.MAIN_TABS.tabs.map((tab) => (
          <NavLink
            key={tab.key}
            to={`${basePath}/${tab.key}/${tabsConfig.SUB_TABS.tabs[0].key}`}
            activeClassName='font-bold'
          >
            <GeminiTab
              title={tab.title}
              isActive={params[mainTabQueryKey] === tab.key}
            />
          </NavLink>
        ))}
      </div>

      {/* Sub tabs and right side component */}
      <div className='flex items-center justify-between pt-4 bg-zen-light-gray-1'>
        <div className='flex items-center space-x-2 px-6 flex-shrink-0'>
          {!!params[mainTabQueryKey] && (
            <>
              {tabsConfig.SUB_TABS.tabs.map((subTab) => {
                let path = `${basePath}/${params[mainTabQueryKey]}/${subTab.key}`;
                if (!clearQueryParamsOnTabChange) {
                  path += `?${qs.stringify(query)}`;
                }

                return (
                  <NavLink
                    key={subTab.key}
                    to={path}
                    onClick={refresh}
                    activeClassName='font-bold'
                  >
                    <GeminiTab
                      title={subTab.title}
                      isActive={params[subTabQueryKey] === subTab.key}
                      isPill
                    />
                  </NavLink>
                );
              })}
            </>
          )}
        </div>
        {/* Render right side component if provided */}
        {!!subTabRightSideComponent && (
          <div className='pr-6'>{subTabRightSideComponent}</div>
        )}
      </div>

      {/* Switch statement to handle routing */}
      <Switch>
        {tabsConfig.MAIN_TABS.tabs.map((tab) => (
          // Route for each main tab with dynamic sub tab parameter
          <Route
            key={tab.key}
            path={`${basePath}/${tab.key}/:${subTabQueryKey}`}
            exact
          >
            {/* Render tabComponent based on selected main and sub tabs */}
            <div key={refreshKey}>
              {tabComponent(params[mainTabQueryKey], params[subTabQueryKey])}
            </div>
          </Route>
        ))}
        {tabsConfig.MAIN_TABS.tabs.map((tab) => (
          // Redirect to default sub tab for each main tab
          <Route key={tab.key} path={`${basePath}/${tab.key}`}>
            <Redirect
              to={`${basePath}/${tab.key}/${tabsConfig.SUB_TABS.tabs[0].key}`}
            />
          </Route>
        ))}
        {/* Redirect to default main and sub tabs if no match is found */}
        <Route path={`${basePath}`}>
          <Redirect
            to={`${basePath}/${tabsConfig.MAIN_TABS.tabs[0].key}/${tabsConfig.SUB_TABS.tabs[0].key}`}
          />
        </Route>
      </Switch>
    </>
  );
};

export default NestedTabsWithRouter;
