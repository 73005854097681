import React, { useEffect, useState } from 'react';
import { MdOutlineBolt } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLoader from '../../../components/DefaultLoader';
import ResourceContainer from '../../../components/ResourceContainer';
import { InstantPaymentResponseStatusEnum } from '../../../openapi/arrakis';
import { fetchInstantPaymentsSummary } from '../../../slices/InstantPaymentSlice';
import { AppDispatch, RootState } from '../../../types';
import ZenInstantPaymentEligibleTransactionResourceTable from '../instantPayments/ZenInstantPaymentEligibleTransactionResourceTable';
import ZenInstantPaymentResourceTable from '../instantPayments/ZenInstantPaymentResourceTable';
import ZenTabs from '../ZenTabs';
import ZenRoute from '../ZenRoute';

interface ZenInstantPaymentResourceContainerProps {
  agentId: string;
}

const ZenInstantPaymentResourceContainer: React.FC<ZenInstantPaymentResourceContainerProps> = ({
  agentId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { detailResponse } = useSelector(
    (state: RootState) => state.agentDetail,
  );
  const [currentTab, setCurrentTab] = useState<string>('Current');

  const {
    instantPayment: {
      instantPaymentsSummaryResponse: {
        data: instantPaymentsSummary,
        loading,
        error,
      },
    },
  } = useSelector((state: RootState) => state);

  const activeInstantPayments =
    instantPaymentsSummary?.instantPayments?.filter(
      (instantPayment) =>
        instantPayment.status !== InstantPaymentResponseStatusEnum.Repaid,
    ) || [];

  const archivedInstantPayments =
    instantPaymentsSummary?.instantPayments?.filter(
      (instantPayment) =>
        instantPayment.status === InstantPaymentResponseStatusEnum.Repaid,
    ) || [];

  useEffect(() => {
    dispatch(fetchInstantPaymentsSummary(agentId));
  }, [dispatch, agentId]);

  return (
    <ZenRoute title={`Instant Payments - ${detailResponse.data?.fullName}`}>
      <ResourceContainer
        loading={loading}
        isEmpty={!instantPaymentsSummary}
        errorCode={error}
        emptyIcon={<MdOutlineBolt fontSize={30} />}
        LoaderComponent={<DefaultLoader />}
        resourceName='instant payments summary'
      >
        <div className='px-0 md:px-4 lg:pt-5'>
          <ZenTabs
            tabs={[
              {
                name: 'Current',
                TabComponent: (
                  <ZenRoute
                    title={`Instant Payments - ${detailResponse.data?.fullName}`}
                  >
                    <div className='my-5'>
                      <div>
                        <h1 className='text-xl'>Transactions</h1>
                        <div className='mt-5'>
                          <ZenInstantPaymentEligibleTransactionResourceTable
                            key={instantPaymentsSummary?.transactionSummaries
                              ?.map((resp) => resp.transaction?.id)
                              .join(',')}
                            transactionSummaries={
                              instantPaymentsSummary?.transactionSummaries!
                            }
                          />
                        </div>
                      </div>
                      <div className='mt-10'>
                        <h1 className='text-xl mb-5'>Instant Payments</h1>
                        <div className='mt-5'>
                          <ZenInstantPaymentResourceTable
                            key={activeInstantPayments
                              .map((resp) => resp.id)
                              .join(',')}
                            instantPayments={activeInstantPayments}
                          />
                        </div>
                      </div>
                    </div>
                  </ZenRoute>
                ),
              },
              {
                name: 'Archived',
                TabComponent: (
                  <ZenRoute
                    title={`Archived Instant Payments - ${detailResponse.data?.fullName}`}
                  >
                    <div className='mt-5'>
                      <div className='mb-10'>
                        <h1 className='text-xl mb-5'>Instant Payments</h1>
                        <ZenInstantPaymentResourceTable
                          key={archivedInstantPayments
                            .map((resp) => resp.id)
                            .join(',')}
                          instantPayments={archivedInstantPayments}
                        />
                      </div>
                    </div>
                  </ZenRoute>
                ),
              },
            ]}
            selected={currentTab}
            onChange={setCurrentTab}
            size='lg'
          />
        </div>
      </ResourceContainer>
    </ZenRoute>
  );
};

export default ZenInstantPaymentResourceContainer;
