import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaxAlert } from '../components/Agent/TaxFormAlert';
import {
  TaxDocumentControllerApi,
  TaxDocumentControllerV2Api,
  TaxDocumentListResponse,
  TaxDocumentResponse,
} from '../openapi/arrakis';
import {
  AppDispatch,
  AppThunk,
  AsyncResponse,
  Mapping,
  TaxDocState,
} from '../types';
import { getTaxType } from '../utils/AgentHelper';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showSuccessToast, showWarningToast } from './ToastNotificationSlice';
import { performAsyncRequest } from './utils/SliceUtil';

export const initialState: TaxDocState = {
  taxAlert: null,
  taxDocumentsDetailResponse: { loading: false, name: 'tax document' },
  taxAlertV2: null,
  taxDocumentsDetailResponseV2: { loading: false, name: 'tax document' },
  taxDocumentsResponse: { loading: false, name: 'Tax Documents' },
};

const TaxSlice = createSlice({
  name: 'taxSlice',
  initialState,
  reducers: {
    saveTaxDocuments(
      state,
      action: PayloadAction<AsyncResponse<TaxDocumentListResponse>>,
    ) {
      state.taxDocumentsResponse = action.payload;
    },
    saveTaxDocumentDetails(
      state,
      action: PayloadAction<AsyncResponse<TaxDocumentResponse>>,
    ) {
      state.taxDocumentsDetailResponse = action.payload;
    },
    setTaxAlertData(state, action: PayloadAction<TaxAlert | null>) {
      state.taxAlert = action.payload;
    },
    saveTaxDocumentDetailsV2(
      state,
      action: PayloadAction<AsyncResponse<TaxDocumentResponse[]>>,
    ) {
      state.taxDocumentsDetailResponseV2 = action.payload;
    },
    setTaxAlertDataV2(state, action: PayloadAction<Mapping<TaxAlert> | null>) {
      state.taxAlertV2 = action.payload;
    },
  },
});

export const {
  saveTaxDocuments,
  setTaxAlertData,
  saveTaxDocumentDetails,
  saveTaxDocumentDetailsV2,
  setTaxAlertDataV2,
} = TaxSlice.actions;

export const getTaxDocuments = (id: string): AppThunk => async (dispatch) => {
  const fetch = () =>
    new TaxDocumentControllerApi(
      getArrakisConfiguration(),
    ).getTaxDocumentsForAgent(id);

  await performAsyncRequest(
    dispatch as AppDispatch,
    'Tax Documents',
    saveTaxDocuments,
    fetch,
    {
      skipAuthDatadog: true,
      errorMetadata: { agent: { id } },
      ignoreStatusCodesDatadog: [404],
    },
  );
};

export const getYearlyTaxDocumentDetails = (
  id: string,
  year: number,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new TaxDocumentControllerApi(
      getArrakisConfiguration(),
    ).getTaxDocumentForAgentByYear(id, year);

  await performAsyncRequest(
    dispatch as AppDispatch,
    'Tax Document',
    saveTaxDocumentDetails,
    fetch,
    {
      skipAuthDatadog: true,
      errorMetadata: {
        agent: {
          id,
        },
        taxDocument: {
          year,
        },
      },
    },
  );
};

export const regenerateTaxDocument = (
  id: string,
  year: number,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new TaxDocumentControllerApi(getArrakisConfiguration()).generateTaxDocument(
      id,
      year,
    );

  const data = await performAsyncRequest(
    dispatch as AppDispatch,
    'Tax Document',
    saveTaxDocumentDetails,
    fetch,
    { skipAuthDatadog: true, errorMetadata: { data: { id } } },
  );

  if (data) {
    dispatch(showSuccessToast('Successfully regenerated the tax document.'));
    dispatch(
      setTaxAlertData({
        description: `A new ${getTaxType(
          data.data.taxDocumentType!,
        )} PDF has been generated. View the new PDF to verify.`,
        taxFormState: 'SUCCESS',
        title: `${getTaxType(data.data.taxDocumentType!)} RECALCULATED`,
      }),
    );
  } else {
    dispatch(
      showWarningToast(
        'Tax Document was not generated',
        `It seems that the agent does not have any taxable information for the tax year ${year}`,
      ),
    );
  }
};

export const getYearlyTaxDocumentDetailsV2 = (
  id: string,
  year: number,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new TaxDocumentControllerV2Api(
      getArrakisConfiguration(),
    ).getTaxDocumentsForAgentByYear(id, year);

  await performAsyncRequest(
    dispatch as AppDispatch,
    'Tax Document',
    saveTaxDocumentDetailsV2,
    fetch,
    {
      skipAuthDatadog: true,
      errorMetadata: {
        agent: {
          id,
        },
        taxDocument: {
          year,
        },
      },
    },
  );
};

export default TaxSlice.reducer;
