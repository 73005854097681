import { faEnvelope, faPhone } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { formatPhoneNumber } from '../../../utils/StringUtils';
import GeminiAvatar from '../GeminiAvatar';
import GeminiHover from '../GeminiHover';
import GeminiIconText from '../GeminiIconText';
import GeminiInformationCard from '../GeminiInformationCard';

export interface ProfileDisplayCardProps {
  title: string;
  imageUrl?: string;
  name?: string;
  phoneNumber?: string;
  emailAddress?: string;
  profileLink?(): void;
}

const GeminiProfileDisplayCard: React.FC<ProfileDisplayCardProps> = ({
  title,
  phoneNumber,
  emailAddress,
  imageUrl,
  name,
  profileLink,
}) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const nameRef = useRef<HTMLParagraphElement>(null);

  const checkIfTruncated = useCallback(() => {
    if (nameRef.current) {
      const isTextTruncated =
        nameRef.current.scrollHeight > nameRef.current.clientHeight ||
        nameRef.current.scrollWidth > nameRef.current.clientWidth;
      setIsTruncated(isTextTruncated);
    }
  }, []);

  useEffect(() => {
    checkIfTruncated();
    window.addEventListener('resize', checkIfTruncated);
    return () => window.removeEventListener('resize', checkIfTruncated);
  }, [checkIfTruncated, name]);

  return (
    <GeminiInformationCard title={title}>
      <div className='md:px-6 md:py-7 p-3 h-56'>
        <div className='grid grid-cols-12 divide-x-2'>
          <div className='col-span-4 pr-5 md:space-y-2.5 space-y-1.5 flex  flex-col items-center'>
            <div className='block xl:hidden'>
              <GeminiAvatar name={name!} imageUrl={imageUrl} size='xxl' />
            </div>
            <div className='hidden xl:block'>
              <GeminiAvatar name={name!} imageUrl={imageUrl} size='3xl' />
            </div>

            {name && isTruncated ? (
              <GeminiHover
                hoverComponent={
                  <p className='text-primary-dark text-base text-center leading-6 font-inter'>
                    {name}
                  </p>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <p
                  ref={nameRef}
                  className='text-primary-dark line-clamp-2 text-base text-center leading-6 font-inter'
                >
                  {name}
                </p>
              </GeminiHover>
            ) : (
              <p
                ref={nameRef}
                className='text-primary-dark line-clamp-2 text-base text-center leading-6 font-inter'
              >
                {name}
              </p>
            )}
          </div>
          <div className='col-span-8 flex flex-col justify-center pl-4 py-4 text-zen-dark-9 '>
            <div className='space-y-2.5'>
              {phoneNumber && (
                <GeminiIconText
                  text={formatPhoneNumber(phoneNumber)!}
                  icon={
                    <FontAwesomeIcon
                      icon={faPhone}
                      className='h-4 w-4 mt-1.5'
                    />
                  }
                  variant='small'
                  textClassName='font-light font-inter'
                />
              )}
              {emailAddress && (
                <GeminiIconText
                  text={emailAddress}
                  icon={
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className='h-4 w-4 mt-1.5'
                    />
                  }
                  variant='small'
                  textClassName='font-light'
                />
              )}
              <div>
                <p
                  onClick={profileLink}
                  className='text-rezen-blue-600 text-sm md:text-base font-medium font-inter pl-6 cursor-pointer'
                >
                  See Profile
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GeminiInformationCard>
  );
};

export default GeminiProfileDisplayCard;
