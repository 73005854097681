import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual, values } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  AgentJoinedBetween,
  AgentStateOneOfStateOrProvincesEnum,
} from '../../../openapi/mercury';
import {
  AgentResponseAccountCountryEnum,
  UserResponseAgentStatusEnum,
} from '../../../openapi/yenta';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  AppDispatch,
  AsyncSelectOptionReactElement,
  ISelectOption,
  RootState,
  YesNoType,
} from '../../../types';
import {
  ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION,
  ANNOUNCEMENT_LOCATION_TYPE,
  AnnounceRecipientEnumAndLabelMapping,
  ANNOUNCEMENT_PLATFORM_TYPE,
  ANNOUNCEMENT_USER_ROLE,
} from '../../../utils/AnnouncementUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { searchForAgents } from '../../../utils/TableUtils';
import Avatar from '../../Avatar';
import ZenControlledAsyncCustomSelectInput from '../Input/ZenControlledAsyncCustomSelectInput';
import ZenControlledDatePickerInput from '../Input/ZenControlledDatePickerInput';
import ZenControlledMultiSelectInput from '../Input/ZenControlledMultiSelectInput';
import ZenControlledRadioInput from '../Input/ZenControlledRadioInput';
import ZenControlledSelectInput from '../Input/ZenControlledSelectInput';
import ZenTeamMembersNameCell from '../Table/Cell/ZenTeamMembersNameCell';
import { searchForTeams } from '../../../slices/TeamSlice';
import { CreateAnnouncementFormData } from './CreateAnnouncementSidebarModel';
import RecipientTable from './RecipientTable';
import RecipientTeamTable from './RecipientTeamTable';

interface WhoIsThisAnnouncementForProps {
  control: Control<CreateAnnouncementFormData, object>;
  recipientType?: ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION;
  userId?: AsyncSelectOptionReactElement;
  allUserIds?: string[];
  teamId?: AsyncSelectOptionReactElement;
  allTeamIds?: string[];
  setUsers(ids: string[]): void;
  setTeams(ids: string[]): void;
  joinedStartDate?: string;
  locationType: ANNOUNCEMENT_LOCATION_TYPE;
  selectSpecificRecipients: YesNoType;
  selectedStatesOptions?: ISelectOption<
    AgentStateOneOfStateOrProvincesEnum | AgentResponseAccountCountryEnum
  >[];
  setValue: UseFormSetValue<CreateAnnouncementFormData>;
}

const WhoIsThisAnnouncementFor: React.FC<WhoIsThisAnnouncementForProps> = ({
  control,
  recipientType,
  userId,
  allUserIds = [],
  teamId,
  allTeamIds = [],
  setUsers,
  setTeams,
  joinedStartDate,
  locationType,
  selectSpecificRecipients,
  selectedStatesOptions,
  setValue,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { announcementDefinitionDetail } = useSelector(
    (state: RootState) => state.announcement,
  );

  const stateOrProvincesOptions = useMemo(() => {
    return values(
      locationType === ANNOUNCEMENT_LOCATION_TYPE.countries
        ? AgentResponseAccountCountryEnum
        : AgentStateOneOfStateOrProvincesEnum,
    ).map((state) => ({
      value: state,
      label: capitalizeEnum(state),
    }));
  }, [locationType]);

  const joinedBetween =
    recipientType === ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentJoinedBetween
      ? (announcementDefinitionDetail?.displayCondition
          ?.conditions?.[0] as AgentJoinedBetween)
      : undefined;

  const addSelectedUser = useCallback(async () => {
    const id = userId?.value;
    if (id) {
      setUsers([...allUserIds, id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const addSelectedTeam = useCallback(
    async () => {
      const id = teamId?.value;
      if (id) {
        setTeams([...allTeamIds, id]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamId],
  );

  const removeUser = (id: string) => {
    setUsers(allUserIds?.filter((el) => el !== id));
  };
  const removeTeam = (id: string) => {
    setTeams(allTeamIds?.filter((el) => el !== id));
  };

  useEffect(() => {
    addSelectedUser();
  }, [addSelectedUser, userId]);

  useEffect(() => {
    addSelectedTeam();
  }, [addSelectedTeam, teamId]);

  useEffect(() => {
    if (!isEqual(joinedStartDate, joinedBetween?.startDate))
      setValue('announcementForJoinedBetween.endDate', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedStartDate, setValue]);

  return (
    <div>
      <div>
        <p className='w-full font-zen-body font-medium text-base leading-6 text-dark'>
          3. Who is this announcement for?
        </p>
      </div>
      <div className='z-20'>
        <div className='mt-4'>
          <ZenControlledRadioInput<
            CreateAnnouncementFormData,
            'selectSpecificRecipients'
          >
            name='selectSpecificRecipients'
            control={control}
            shouldUnregister={false}
            options={[
              {
                label: 'Select Target Audience',
                value: YesNoType.YES,
              },
              {
                label: 'All Rezen Members',
                value: YesNoType.NO,
              },
            ]}
            rules={{
              required: 'Please select an audience type',
            }}
            inlineOptions
            isRequired
          />
        </div>
        {selectSpecificRecipients === YesNoType.YES && (
          <div className='space-y-4 mt-5'>
            <div className='flex items-center justify-between'>
              <p className='font-medium font-zen-body text-zen-dark-9'>
                Select Location Type
              </p>
              <div>
                <ZenControlledRadioInput<
                  CreateAnnouncementFormData,
                  'locationType'
                >
                  name='locationType'
                  control={control}
                  shouldUnregister={false}
                  options={[
                    {
                      label: 'Countries',
                      value: ANNOUNCEMENT_LOCATION_TYPE.countries,
                    },
                    {
                      label: 'States',
                      value: ANNOUNCEMENT_LOCATION_TYPE.states,
                    },
                  ]}
                  rules={{
                    required: 'Please select an audience type',
                  }}
                  inlineOptions
                  isRequired
                  defaultValue={ANNOUNCEMENT_LOCATION_TYPE.countries}
                />
              </div>
            </div>
            <div>
              <ZenControlledMultiSelectInput<
                CreateAnnouncementFormData,
                'stateOrProvinces'
              >
                name='stateOrProvinces'
                control={control}
                placeholder='State name'
                shouldUnregister={false}
                options={stateOrProvincesOptions}
                rules={{
                  required: 'Please select a state',
                }}
                isSearchable
                isRequired
                isClearable
              />
            </div>
            <div>
              <p className='font-medium font-zen-body text-zen-dark-9'>
                Select Agent Type
              </p>
              <div className='pt-4'>
                <ZenControlledSelectInput<
                  CreateAnnouncementFormData,
                  'announcementFor'
                >
                  control={control}
                  name='announcementFor'
                  options={values(ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION).map(
                    (el) => ({
                      label: AnnounceRecipientEnumAndLabelMapping[el]!,
                      value: el,
                    }),
                  )}
                  placeholder='Agent Type'
                  isClearable={
                    (selectedStatesOptions?.length !== 0 && !!recipientType) ||
                    !!recipientType
                  }
                  shouldUnregister={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {selectSpecificRecipients === YesNoType.YES &&
        recipientType ===
          ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentJoinedBetween && (
          <div className='pt-7'>
            <div className='flex flex-row items-center'>
              <p className='w-full font-zen-body font-medium text-base leading-6 text-dark'>
                Date Range:
              </p>
            </div>
            <div className='flex flex-row items-start mt-2.5'>
              <FontAwesomeIcon
                icon={faArrowRightLong}
                className='text-zen-dark-8 text-base mr-4.5 mt-1'
              />
              <div>
                <p className='w-full font-zen-body font-medium text-base leading-6 text-dark mb-1'>
                  Start Date
                </p>
                <ZenControlledDatePickerInput<
                  CreateAnnouncementFormData,
                  'announcementForJoinedBetween.startDate'
                >
                  control={control}
                  name='announcementForJoinedBetween.startDate'
                  placeholder='MM/DD/YYYY'
                  shouldUnregister={false}
                  icon={
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className='text-primary-blue text-lg mt-1 ml-2'
                    />
                  }
                  rules={{
                    required: 'Start Date is required.',
                  }}
                  isRequired
                />
              </div>
              <div className='mx-4.5 w-1 h-px border-b-zen-dark-6' />
              <div>
                <p className='w-full font-zen-body font-medium text-base leading-6 text-dark mb-1'>
                  End Date
                </p>
                <ZenControlledDatePickerInput<
                  CreateAnnouncementFormData,
                  'announcementForJoinedBetween.endDate'
                >
                  control={control}
                  name='announcementForJoinedBetween.endDate'
                  placeholder='MM/DD/YYYY'
                  shouldUnregister={false}
                  icon={
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className='text-primary-blue text-lg mt-1 ml-2'
                    />
                  }
                  datePickerConfig={{
                    minDate: joinedStartDate
                      ? DateTime.fromISO(joinedStartDate)
                          .plus({ days: 1 })
                          .toJSDate()
                      : undefined,
                  }}
                  rules={{
                    required: 'End Date is required.',
                  }}
                  isRequired
                />
              </div>
            </div>
          </div>
        )}
      {selectSpecificRecipients === YesNoType.YES &&
        recipientType ===
          ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentIdOneOf && (
          <div className='pt-7'>
            <ZenControlledAsyncCustomSelectInput<
              CreateAnnouncementFormData,
              'userId'
            >
              control={control}
              name='userId'
              placeholder='Search by name'
              fetchData={async (search, page) => {
                try {
                  const { data } = await searchForAgents({
                    page,
                    search,
                    filterBy: {
                      agentStatus: [UserResponseAgentStatusEnum.Active],
                    },
                  });

                  const options: AsyncSelectOptionReactElement[] = data
                    .filter((s) => !s.nonReportable)
                    .map((resp) => ({
                      value: `${resp.id}`,
                      label: (
                        <div className='flex flex-row items-center'>
                          <Avatar
                            name={`${resp.firstName} ${resp.lastName}`}
                            size='xxs'
                            imageUrl={resp.avatar}
                          />
                          <div className='flex flex-row'>
                            <p className='font-zen-body text-zen-dark flex-grow ml-3'>
                              {`${resp.firstName} ${resp.lastName}`}
                            </p>
                            <p className='font-zen-body text-zen-dark-6 ml-1.5'>
                              {`${resp.emailAddress}`}
                            </p>
                          </div>
                        </div>
                      ),
                    }));

                  return options;
                } catch (e) {
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an agent. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              filterOption={(option) =>
                !(allUserIds || []).includes(option?.value)
              }
              shouldUnregister={false}
            />
            <RecipientTable ids={allUserIds} removeUser={removeUser} />
          </div>
        )}

      {selectSpecificRecipients === YesNoType.YES &&
        recipientType ===
          ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.AgentMemberOfTeam && (
          <div className='pt-7'>
            <ZenControlledAsyncCustomSelectInput<
              CreateAnnouncementFormData,
              'teamId'
            >
              control={control}
              name='teamId'
              placeholder='Enter the name of team'
              fetchData={async (search, page) => {
                try {
                  const searchResponse = await dispatch(
                    searchForTeams(
                      page,
                      20,
                      'ASC',
                      [],
                      'ACTIVE',
                      undefined,
                      undefined,
                      search,
                    ),
                  );

                  const options: AsyncSelectOptionReactElement[] = (
                    searchResponse?.results || []
                  )?.map((resp) => ({
                    value: `${resp?.id}`,
                    label: (
                      <div className='flex items-center'>
                        <div className='flex flex-row items-center w-3/12'>
                          <ZenTeamMembersNameCell
                            agents={resp?.agents || []}
                            excludeName
                          />
                        </div>
                        <div className='flex flex-row'>
                          <p className='font-zen-body text-zen-dark flex-grow ml-3 w-9/12'>
                            {resp?.name}
                          </p>
                        </div>
                      </div>
                    ),
                  }));

                  return options;
                } catch (e) {
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an team. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              filterOption={(option) =>
                !(allTeamIds || []).includes(option?.value)
              }
              shouldUnregister={false}
            />
            <RecipientTeamTable ids={allTeamIds} removeTeam={removeTeam} />
          </div>
        )}
      {selectSpecificRecipients === YesNoType.YES &&
        recipientType ===
          ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION.UserRoleOneOf && (
          <div className='my-6 pt-3'>
            <ZenControlledRadioInput<CreateAnnouncementFormData, 'userRole'>
              name='userRole'
              control={control}
              label=''
              options={[
                {
                  label: 'Agents',
                  value: ANNOUNCEMENT_USER_ROLE.agent,
                },
                {
                  label: 'Brokers',
                  value: ANNOUNCEMENT_USER_ROLE.broker,
                },
              ]}
              inlineOptions
              shouldUnregister={false}
            />
          </div>
        )}
      {selectSpecificRecipients === YesNoType.YES && (
        <div className='my-6 pt-3 border-t border-zen-dark-4'>
          <ZenControlledRadioInput<CreateAnnouncementFormData, 'platformType'>
            name='platformType'
            control={control}
            label='Targeting by platform'
            options={[
              {
                label: 'Both',
                value: ANNOUNCEMENT_PLATFORM_TYPE.both,
              },
              {
                label: 'Bolt',
                value: ANNOUNCEMENT_PLATFORM_TYPE.bolt,
              },
              {
                label: 'App',
                value: ANNOUNCEMENT_PLATFORM_TYPE.app,
              },
            ]}
            inlineOptions
            shouldUnregister={false}
          />
        </div>
      )}
    </div>
  );
};

export default WhoIsThisAnnouncementFor;
