import config from '../../../config';
import { Earning, EarningType } from '../WealthManagementTypes';

const baseUrl = `${config.assetsBaseUrl}/wealth-management`;

export const REAL_TECHNOLOGY_VIDEO_URL = `${baseUrl}/State-of-the-Art Technology (2).mp4`;

export const LANDING_PAGE_VIDEO_URL: Record<EarningType, string> = {
  [Earning.COMMISSION_INCOME]: `${baseUrl}/1-commission-income.mp4`,
  [Earning.PRE_CAP_SPP]: `${baseUrl}/2-pre-cap-spp.mp4`,
  [Earning.STOCK_AWARD_FOR_CAPPING]: `${baseUrl}/3-stock-award-for-capping.mp4`,
  [Earning.POST_CAP_SPP]: `${baseUrl}/4-post-cap-spp.mp4`,
  [Earning.ELITE_PRODUCTION_AWARD]: `${baseUrl}/5-elite-agent-production-award.mp4`,
  [Earning.ELITE_CULTURAL_AWARD]: `${baseUrl}/6-elite-agent-cultural-award.mp4`,
  [Earning.REVENUE_SHARE_INCOME]: `${baseUrl}/7-revenue-share-income.mp4`,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]: `${baseUrl}/8-stock-award-for-attracting.mp4`,
};

export const LANDING_PAGE_VIDEO_THUMBNAIL_URL: Record<EarningType, string> = {
  [Earning.COMMISSION_INCOME]: `${baseUrl}/1_commission_thumbnail.png`,
  [Earning.PRE_CAP_SPP]: `${baseUrl}/2_precap_thumbnail.png`,
  [Earning.STOCK_AWARD_FOR_CAPPING]: `${baseUrl}/3_stock_award_capping_thumbnail.png`,
  [Earning.POST_CAP_SPP]: `${baseUrl}/4_postcap_spp_thumbnail.png`,
  [Earning.ELITE_PRODUCTION_AWARD]: `${baseUrl}/5_elite_production_thumbnail.png`,
  [Earning.ELITE_CULTURAL_AWARD]: `${baseUrl}/6_elite_cultural_thumbnail.png`,
  [Earning.REVENUE_SHARE_INCOME]: `${baseUrl}/7_revenue_share_thumbnail.png`,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]: `${baseUrl}/8_stock_award_attracting_thumbnail.png`,
};
