import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faCircleInfo,
  faXmark,
  faKey,
} from '@fortawesome/pro-regular-svg-icons';
import { cn } from '../../../utils/classUtils';
import { useGemini } from '../../../hooks/useGemini';
import { ApiKeyResponse } from '../../../openapi/keymaker';
import {
  fetchClientApiKeys,
  revokeClientApiKey,
} from '../../../slices/ClientApiKeySlice';
import { RootState } from '../../../types';
import { isAgentClientApiKeyExpired } from '../../../utils/AgentHelper';
import { getFormattedExpiryTime } from '../../../utils/DateUtils';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenAction from '../../Zen/ZenAction';
import ZenCardWithItems from '../../Zen/ZenCardWithItems';
import ZenPill from '../../Zen/ZenPill';
import AgentClientApiKeyField from './AgentClientApiKeyField';
import AgentClientApiKeySideBarModal from './AgentClientApiKeySideBarModal';
import AgentGenerateKeyBox from './AgentGenerateKeyBox';
import AgentGenerateKeyFormModal from './AgentGenerateKeyFormModal';

interface AgentClientApiKeyProps {}

const AgentClientApiKey: React.FC<AgentClientApiKeyProps> = () => {
  const dispatch = useDispatch();
  const { clientApiKeys } = useSelector(
    (state: RootState) => state.clientApiKey,
  );
  const [openClientApiKeyModal, setOpenClientApiKeyModal] = useState<boolean>(
    false,
  );
  const [
    openGenerateApiKeyModal,
    setOpenGenerateApiKeyModal,
  ] = useState<boolean>(false);
  const [openRevokeApiKeyModal, setOpenRevokeApiKeyModal] = useState<boolean>(
    false,
  );
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [apiKeyToBeRevoked, setApiKeyToBeRevoked] = useState<ApiKeyResponse>(
    {},
  );
  const [mostRecentApiKey, setMostRecentApiKey] = useState<ApiKeyResponse>();

  useEffect(() => {
    dispatch(fetchClientApiKeys());
  }, [dispatch]);

  const handleOpenGenerateApiKeyModal = () => {
    setOpenGenerateApiKeyModal(true);
  };

  const handleOpenRevokeApiModal = (data: ApiKeyResponse) => {
    setOpenRevokeApiKeyModal(true);
    setApiKeyToBeRevoked(data || {});
  };

  const handleApiKeyRevoke = async () => {
    setConfirmLoading(true);
    await dispatch(
      revokeClientApiKey({ apiKey: apiKeyToBeRevoked?.value || '' }),
    );
    setConfirmLoading(false);
    setOpenRevokeApiKeyModal(false);
    setApiKeyToBeRevoked({});
  };

  useEffect(() => {
    const recentKey = (clientApiKeys || []).filter((key) => !key.revokedAt)[0];
    setMostRecentApiKey(recentKey);
  }, [clientApiKeys]);

  const isGeminiEnabled = useGemini();

  return (
    <div>
      <ZenCardWithItems
        RightComponent={
          <ZenAction
            text='Manage Keys'
            icon={
              <FontAwesomeIcon
                icon={faKey}
                className={cn(
                  isGeminiEnabled
                    ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                    : 'text-primary-blue',
                )}
              />
            }
            onClick={() => setOpenClientApiKeyModal(true)}
          />
        }
        variant='regular'
        items={
          !!mostRecentApiKey
            ? [
                {
                  name: 'Key Name',
                  value: mostRecentApiKey?.name,
                },
                {
                  name: 'Key',
                  value: '',
                  rightActionComponent: (
                    <AgentClientApiKeyField apiKey={mostRecentApiKey?.value!} />
                  ),
                },
                {
                  name: 'Expires',
                  value: !isAgentClientApiKeyExpired(
                    mostRecentApiKey?.expirationDate || '',
                  )
                    ? getFormattedExpiryTime(
                        mostRecentApiKey?.expirationDate || '',
                      )
                    : '',
                  rightActionComponent: isAgentClientApiKeyExpired(
                    mostRecentApiKey?.expirationDate || '',
                  ) ? (
                    <ZenPill
                      title={`Expired on ${getFormattedExpiryTime(
                        mostRecentApiKey?.expirationDate || '',
                      )}`}
                      variant='error'
                      leftIcon={
                        <FontAwesomeIcon
                          icon={faXmark}
                          className='text-error text-sm flex flex-center pr-1'
                        />
                      }
                    />
                  ) : (
                    <span />
                  ),
                },
                {
                  name: '',
                  value: '',
                },
              ]
            : []
        }
        title='Recent API Key'
      >
        {!mostRecentApiKey && (
          <div className='pt-4'>
            <AgentGenerateKeyBox
              onClick={() => handleOpenGenerateApiKeyModal()}
            />
          </div>
        )}
        <div
          className={cn(
            'mx-4 mb-6 p-4 border rounded-xl',
            !mostRecentApiKey && 'mt-3',
          )}
        >
          <div className='flex flex-row items-center'>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className='text-xl text-zen-dark-9'
            />
            <span className='font-zen-title font-normal text-lg text-zen-dark-9 pl-2 pt-1'>
              API Key Resources
            </span>
          </div>
          <div className='py-2'>
            <span className='font-zen-body text-sm text-zen-dark-8'>
              Find helpful information about our APIs following the links below:
            </span>
          </div>
          <div className='grid grid-cols-1 justify-evenly gap-2'>
            <div className='font-zen-body font-semibold text-sm text-zen-dark-9'>
              <a target='_blank' href='/api/docs' rel='noreferrer'>
                <span className='text-sm text-zen-dark-8'>
                  API Documentation
                </span>
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  size='lg'
                  className='text-primary-blue ml-1.5'
                />
              </a>
            </div>
            <div className='font-zen-body py-2'>
              <p className='font-zen-body text-sm text-zen-dark-8'>
                Add the above API key as the X-API-KEY header on all requests.
                For example: <pre className='mt-1'>X-API-KEY: my_api_key</pre>
              </p>
            </div>
          </div>
        </div>
      </ZenCardWithItems>
      <AgentClientApiKeySideBarModal
        isOpen={openClientApiKeyModal}
        onClose={() => setOpenClientApiKeyModal(false)}
        handleOpenGenerateApiKeyModal={handleOpenGenerateApiKeyModal}
        handleOpenRevokeApiModal={handleOpenRevokeApiModal}
      />
      {openGenerateApiKeyModal && (
        <AgentGenerateKeyFormModal
          isOpen={openGenerateApiKeyModal}
          onClose={() => setOpenGenerateApiKeyModal(false)}
        />
      )}
      <ZenConfirmationModal
        variant='danger'
        subtitle={
          <span className='text-zen-dark-9'>
            API Key:{' '}
            <span className='font-primary-medium'>
              {apiKeyToBeRevoked.name}
            </span>{' '}
            will be revoked.
            <br />
            This action is not reversible.
          </span>
        }
        title='Are you sure you want to revoke this API Key'
        onClose={() => {
          setOpenRevokeApiKeyModal(false);
          setApiKeyToBeRevoked({});
        }}
        onConfirm={() => handleApiKeyRevoke()}
        isSubmitting={confirmLoading}
        isOpen={openRevokeApiKeyModal}
        confirmButtonText='Revoke'
        size='large'
      />
    </div>
  );
};
export default AgentClientApiKey;
