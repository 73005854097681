import React from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { TransactionResponse } from '../../../openapi/arrakis';
import { AgentResponseAccountCountryEnum } from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  addDoubleEnderAgent,
  deleteDoubleEnderAgent,
  unlinkDoubleEnderTransaction,
} from '../../../slices/TransactionSlice';
import {
  AppDispatch,
  AsyncSelectOptionReactElement,
  RootState,
} from '../../../types';
import { STATE_OR_PROVINCE_ABBREVIATIONS } from '../../../utils/AnnouncementUtils';
import { searchActiveAgents } from '../../../utils/TableUtils';
import AvatarLabelComponent from '../../AgentReports/AvatarLabelComponent';
import ZenControlledAsyncSelectInput from '../Input/ZenControlledAsyncSelectInput';
import ZenButton from '../ZenButton';
import ZenSidebarModal from '../ZenSidebarModal';

interface ZenAddRealParticipantProps {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
  isDoubleEnderTx: boolean;
}

interface FormData {
  agentId?: AsyncSelectOptionReactElement;
}

const ZenDoubleEnderSidebarModal: React.FC<ZenAddRealParticipantProps> = ({
  isOpen,
  transaction,
  onClose,
  isDoubleEnderTx,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const {
    auth: { userDetail },
    transaction: { transactionDetailResponse, doubleEnderAgent },
  } = useSelector((state: RootState) => state);

  const agentDetail = doubleEnderAgent.data;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      agentId: agentDetail
        ? {
            value: agentDetail?.id!,
            label: (
              <AvatarLabelComponent
                avatar={agentDetail?.avatar!}
                firstName={agentDetail?.firstName!}
                lastName={agentDetail?.lastName!}
                emailAddress={agentDetail?.emailAddress!}
                administrativeAreas={agentDetail?.offices?.map((e) => {
                  if (!e.administrativeArea?.stateOrProvince) {
                    return 'N/A';
                  }
                  return STATE_OR_PROVINCE_ABBREVIATIONS[
                    e.administrativeArea?.stateOrProvince
                  ];
                })}
              />
            ),
          }
        : undefined,
    },
  });

  const onSubmit = async (data: FormData) => {
    let success: boolean;
    if (isDoubleEnderTx) {
      const doubleEnderTxId = transaction.doubleEnderInfo?.otherTxId;
      if (doubleEnderTxId) {
        await dispatch(
          unlinkDoubleEnderTransaction(doubleEnderTxId, transaction.id!, {
            silent: true,
            refreshTx: false,
          }),
        );
      }
      success = await dispatch(deleteDoubleEnderAgent(transaction.id!));
    } else {
      success = await dispatch(
        addDoubleEnderAgent(transaction.id!, data.agentId?.value!),
      );
    }
    if (success) {
      onClose();
    }
  };

  return (
    <ZenSidebarModal
      title={
        isDoubleEnderTx ? 'Remove Double Ender Agent' : 'Add Double Ender Agent'
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='p-4'>
        <div>
          <div className='mt-5'>
            <ZenControlledAsyncSelectInput<FormData, 'agentId'>
              control={control}
              name='agentId'
              placeholder='Search'
              label='Name of Participant'
              disabled={isDoubleEnderTx}
              fetchData={async (search, page) => {
                try {
                  const searchResponse = await searchActiveAgents(
                    page,
                    [
                      (transactionDetailResponse.data?.address
                        ?.country as unknown) as AgentResponseAccountCountryEnum,
                    ],
                    undefined,
                    search,
                    undefined,
                  );
                  const options: AsyncSelectOptionReactElement[] = searchResponse.data?.map(
                    (resp) => ({
                      value: `${resp.id}`,
                      label: (
                        <AvatarLabelComponent
                          avatar={resp?.avatar!}
                          firstName={resp?.firstName!}
                          lastName={resp?.lastName!}
                          emailAddress={resp?.emailAddress!}
                          administrativeAreas={resp?.administrativeAreas?.map(
                            (e) => {
                              if (!e.stateOrProvince) {
                                return 'N/A';
                              }
                              return STATE_OR_PROVINCE_ABBREVIATIONS[
                                e.stateOrProvince
                              ];
                            },
                          )}
                        />
                      ),
                    }),
                  );

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search for registered agents in add participants',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                        country: userDetail?.accountCountry!,
                      },
                    },
                  );
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an agent. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              rules={{
                required: 'Participant is required',
              }}
              isRequired
            />
          </div>
        </div>
      </div>
      <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2 w-full'>
        <ZenButton
          label='Cancel'
          variant='secondary-outline'
          onClick={onClose}
          isFullWidth
        />
        <ZenButton
          label={isDoubleEnderTx ? 'Delete' : 'Save'}
          onClick={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          isFullWidth
        />
      </div>
    </ZenSidebarModal>
  );
};

export default ZenDoubleEnderSidebarModal;
