import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SEND_TRANSACTION_TO_REAL_TITLE_VIDEO_LINK } from '../../constants/RealTitleConstants';
import { createTransactionBuilder } from '../../slices/TransactionBuilderSlice';
import ZenButton from '../Zen/ZenButton';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';
import ZenIFrameContainer from '../Zen/ZenIFrameContainer';

interface RealTitleSendTransactionSectionProps {}

const RealTitleSendTransactionSection: React.FC<RealTitleSendTransactionSectionProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <div className='grid lg:grid-cols-3 grid-cols-1 place-items-center justify-items-start lg:gap-0 gap-4 border border-zen-dark-4 rounded-2xl mt-8 lg:py-8 lg:px-6 p-4'>
      <div className='lg:w-2/3'>
        <p className='font-zen-body font-medium text-lg text-black'>
          Do you know how to send transactions to Real Title?
        </p>
        <p className='font-zen-body font-normal text-sm text-zen-dark-9 my-4'>
          Ready to send a transaction to Real Title? Make sure to include ABA
          (affiliated business disclosure) signed from your client(s)
        </p>
        <ZenButton
          label='Add Transaction'
          variant='primary-outline'
          LeftIconComponent={
            <FontAwesomeIcon icon={faPlus} className='text-primary-blue' />
          }
          isSubmitting={isLoading}
          onClick={async () => {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.ONE_APP_TITLE_CLICKED_ADD_TRANSACTION,
            );
            setIsLoading(true);

            const transactionBuilderId = await dispatch(
              createTransactionBuilder(),
            );

            setIsLoading(false);

            if (transactionBuilderId) {
              history.push(`/transaction/create/${transactionBuilderId}`);
            }
          }}
        />
      </div>
      <div
        className='relative w-full lg:order-none order-first col-span-2'
        style={{ paddingTop: '56.25%' }}
      >
        <ZenIFrameContainer
          title='Send Transaction to Real Title'
          onClick={() => {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.ONE_APP_TITLE_CLICKED_SEND_TRANSACTION,
            );
          }}
          id='send-transaction-to-real-title-video'
          src={SEND_TRANSACTION_TO_REAL_TITLE_VIDEO_LINK}
          className='absolute top-0 w-full h-full border border-zen-dark-4 rounded-2xl'
          frameBorder='0'
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default RealTitleSendTransactionSection;
