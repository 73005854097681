import { ISelectOption } from '../../../types';
import ZenCheckBox from '../ZenCheckbox';

interface ZenControlledCheckboxGroupItemProps {
  value: string[];
  items: ISelectOption[];
  onToggle(newValue: string[]): void;
}

const ZenControlledCheckboxGroupItem = ({
  items,
  onToggle,
  value = [],
}: ZenControlledCheckboxGroupItemProps) => {
  const handleToggle = (checked: boolean, valueToUpdate: string) => {
    let newValue: string[];

    if (checked) {
      newValue = [...value, valueToUpdate];
    } else {
      newValue = value.filter((v) => v !== valueToUpdate);
    }

    onToggle(newValue);
  };

  return (
    <div>
      {items.map((item) => {
        const checked = !!value?.find((val: string) => val === item.value);

        return (
          <div
            key={item.value}
            className='flex-row items-center my-2'
            onClick={() => handleToggle(!checked, item.value)}
          >
            <ZenCheckBox
              value={checked}
              onChange={() => handleToggle(!checked, item.value)}
            />
            <span className='font-primary-regular flex-wrap text-zen-dark-7 text-base ml-2'>
              {item.label}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default ZenControlledCheckboxGroupItem;
