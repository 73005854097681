import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TransactionResponse } from '../openapi/arrakis';

const useRedirectAwayListingEffect = (
  listingId: string,
  listingDetail: TransactionResponse | undefined,
): boolean => {
  const history = useHistory();
  const location = useLocation();
  const isListingDetailLoaded = listingDetail?.id! === listingId;
  const isNonListing = !listingDetail?.listing && isListingDetailLoaded;

  const path = location.pathname?.replace('/listings', '/transactions');

  const handleRedirection = useCallback(async () => {
    if (isNonListing) {
      history.replace(`${path}${location?.search}`);
    }
  }, [history, isNonListing, location?.search, path]);

  useEffect(() => {
    handleRedirection();
  }, [handleRedirection]);

  return isNonListing;
};

export default useRedirectAwayListingEffect;
