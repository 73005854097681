import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';

export type PrompSizeVariantType = 'xs' | 'sm' | 'md';
interface PromptProps {
  prompt: string;
  promptSizeVariant?: PrompSizeVariantType;
  isNudge?: boolean;
  disabled?: boolean;
  onSelect(): void;
}

const promptSizeVariantToClassMap: Record<PrompSizeVariantType, string> = {
  xs: 'sm:text-xs',
  sm: 'sm:text-sm',
  md: 'sm:text-base',
};

const Prompt: React.FC<PromptProps> = ({
  prompt,
  promptSizeVariant = 'sm',
  isNudge = false,
  disabled = false,
  onSelect,
}) => {
  return (
    <motion.div
      key={prompt}
      className={classNames('p-[1px] mb-1 mr-2 sm:mb-2 h-fit rounded-full', {
        'hover:bg-leo-prompt-gradient': !isNudge,
      })}
      transition={{ duration: 0.1 }}
      layout
    >
      <button
        type='button'
        className={classNames(
          'appearance-none bg-[#171821] border rounded-full border-white px-5 md:px-3 py-1.5 text-zen-dark-4 text-xs',
          promptSizeVariantToClassMap[promptSizeVariant],
          { 'hover:border-transparent': !isNudge },
          { 'whitespace-nowrap': isNudge },
        )}
        onClick={(e: any) => {
          e.stopPropagation();
          onSelect();
        }}
        disabled={disabled}
      >
        {prompt}
      </button>
    </motion.div>
  );
};

export default Prompt;
