import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  InstantPaymentItemResponseTypeEnum,
  InstantPaymentResponse,
} from '../../openapi/arrakis';
import { AmplitudeEvent } from '../../services/AmplitudeService';
import { fetchInstantPayment } from '../../slices/InstantPaymentSlice';
import { RootState } from '../../types';
import { displayAmount } from '../../utils/CurrencyUtils';
import { calculateInterestAmount } from '../../utils/InstantPaymentHelper';
import AmplitudeEventOnLoad from '../Amplitude/AmplitudeEventOnLoad';
import IconCircle from '../IconCircle';
import ResourceContainer from '../ResourceContainer';
import SidebarModal from '../SidebarModal';
import TextInfoRow from '../TextInfoRow';
import ZenTitleHr from '../Zen/ZenTitleHr';
import InstantPaymentStatusRow from './InstantPaymentStatusRow';
import InstantPaymentsTransactionRow from './InstantPaymentsTransactionRow';

interface InstantPaymentStatusSidebarModalProps {
  instantPayment: InstantPaymentResponse;
  onClose(): void;
}

const InstantPaymentStatusSidebarModal: React.FC<InstantPaymentStatusSidebarModalProps> = ({
  instantPayment,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    instantPayment: instantPaymentDetail,
    instantPaymentErrorCode,
    instantPaymentLoading,
  } = useSelector((state: RootState) => state.instantPayment);

  const interestAmount: number = calculateInterestAmount(
    instantPaymentDetail?.items || [],
  );
  const repayments = (instantPaymentDetail?.items || []).filter(
    (item) => item.type === InstantPaymentItemResponseTypeEnum.Repayment,
  );

  useEffect(() => {
    dispatch(fetchInstantPayment(instantPayment.id!));
  }, [dispatch, instantPayment]);

  return (
    <SidebarModal
      isOpen
      title='Instant Payment'
      subtitle={instantPaymentDetail?.transaction?.addressOneLine}
      onClose={onClose}
    >
      <ResourceContainer
        isEmpty={!instantPaymentDetail}
        loading={instantPaymentLoading}
        errorCode={instantPaymentErrorCode}
        resourceName='Instant payment'
      >
        <div className='p-4'>
          <div className='flex justify-center my-3'>
            <IconCircle
              variant='success'
              icon={
                <FontAwesomeIcon
                  icon={faCheck}
                  className='text-success'
                  size='lg'
                />
              }
            />
          </div>
          <p className='text-2xl text-center'>
            {displayAmount(instantPaymentDetail?.principal)}
          </p>
          <p className='text-xs uppercase text-center mb-4'>
            money is on your way
          </p>
          <InstantPaymentStatusRow status={instantPaymentDetail?.status!} />
          <div className='border-b w-full' />
          <p className='my-3'>Cost Breakup</p>
          <div className='flex flex-col space-y-2 mb-4'>
            <TextInfoRow
              title='Payment Transferred on'
              value={
                instantPaymentDetail?.paymentConfirmationTime
                  ? DateTime.fromMillis(
                      instantPaymentDetail?.paymentConfirmationTime!,
                    ).toFormat('LL/dd/yyyy')
                  : 'Pending'
              }
            />
            <TextInfoRow
              title='Instant Payment amount'
              value={displayAmount(instantPaymentDetail?.principal)}
            />
            <TextInfoRow
              title='Processing fees'
              value={displayAmount({
                amount: interestAmount,
                currency: instantPaymentDetail?.principal?.currency,
              })}
            />
            <TextInfoRow
              title='Amount to repay'
              value={displayAmount(instantPaymentDetail?.amountToRepay)}
            />
          </div>
          {repayments.length > 0 && (
            <div className='my-4'>
              <div className='mb-3'>
                <ZenTitleHr title='Amount Repaid' />
              </div>
              <div className='flex flex-col divide-y'>
                {repayments.map((repayment) => (
                  <InstantPaymentsTransactionRow
                    repayment={repayment}
                    key={repayment.id}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <AmplitudeEventOnLoad
          eventName={AmplitudeEvent.INSTANT_PAYMENTS_DETAIL_PAGE_VIEW}
          eventData={{ instantPaymentId: instantPaymentDetail?.id }}
        />
      </ResourceContainer>
    </SidebarModal>
  );
};

export default InstantPaymentStatusSidebarModal;
