import { faArrowUpArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import { useFormContext } from 'react-hook-form-v7';
import {
  AddressRequest,
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaResponseCountryEnum,
} from '../../openapi/yenta';
import { capitalizeEnum } from '../../utils/StringUtils';
import { GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL } from '../../utils/Validations';
import IconButton from '../IconButton';
import ZenControlledGoogleAutocompleteSearchInput, {
  GooglePlaceLocationType,
} from '../Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledHTMLSelectInput from '../Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledStateOrProvinceInput from '../Zen/Input/ZenControlledStateOrProvinceInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';

interface FormData {
  address: AddressRequest;
  isManualAddress: boolean;
  location?: GooglePlaceLocationType;
}

const AddressFormComponent = () => {
  const { control, watch, setValue } = useFormContext<FormData>();
  const [isManualAddress, country, state, city, zip, streetAddress1] = watch([
    'isManualAddress',
    'address.country',
    'address.stateOrProvince',
    'address.city',
    'address.zipOrPostalCode',
    'address.streetAddress1',
  ]);

  const isFieldRequired = (label: string) => {
    if (country || state || city || zip || streetAddress1) {
      return `${label} is required`;
    }
    return false;
  };

  return (
    <div>
      {isManualAddress ? (
        <div className='space-y-4'>
          <div>
            <ZenControlledTextInput<FormData, 'address.streetAddress1'>
              control={control}
              label='Street'
              name='address.streetAddress1'
              shouldUnregister={false}
              placeholder='E.g. 270 1st Ave.'
              rules={{
                required: isFieldRequired('Street'),
              }}
            />
          </div>
          <div className='flex flex-row space-x-4'>
            <ZenControlledTextInput<FormData, 'address.streetAddress2'>
              control={control}
              label='Street Line 2'
              name='address.streetAddress2'
              shouldUnregister={false}
              placeholder='E.g. 270 1st Ave.'
            />
            <ZenControlledHTMLSelectInput<FormData, 'address.country'>
              name='address.country'
              control={control}
              label='Country'
              shouldUnregister={false}
              placeholder='Country'
              options={[
                {
                  label: 'Choose Country',
                  value: '',
                },
                ...values(AdministrativeAreaResponseCountryEnum).map(
                  (state) => ({
                    value: state,
                    label: capitalizeEnum(state),
                  }),
                ),
              ]}
              rules={{
                required: isFieldRequired('Country'),
              }}
            />
          </div>
          <div className='flex flex-row space-x-4'>
            <ZenControlledTextInput<FormData, 'address.city'>
              control={control}
              label='City'
              name='address.city'
              shouldUnregister={false}
              placeholder='Eg. New York'
              rules={{
                required: isFieldRequired('City'),
              }}
            />
          </div>
          <div className='flex flex-row space-x-4'>
            <ZenControlledStateOrProvinceInput<
              FormData,
              'address.stateOrProvince'
            >
              name='address.stateOrProvince'
              control={control}
              shouldUnregister={false}
              setValue={setValue}
              selectedCountry={
                (watch(
                  'address.country',
                ) as unknown) as AdministrativeAreaRequestCountryEnum
              }
              rules={{
                required: isFieldRequired('State / Province'),
              }}
            />
            <ZenControlledTextInput<FormData, 'address.zipOrPostalCode'>
              control={control}
              label='ZIP/Postal Code'
              name='address.zipOrPostalCode'
              shouldUnregister={false}
              placeholder='E.g. 10001'
              rules={{
                required: isFieldRequired('Zip/Postal Code'),
              }}
            />
          </div>
        </div>
      ) : (
        <ZenControlledGoogleAutocompleteSearchInput<FormData, 'location'>
          control={control}
          shouldUnregister={false}
          name='location'
          label='Address'
          subLabel='(Source by Google)'
          placeholder='E.g. 1st St. New York, NY 10010'
          rules={{
            ...GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL,
          }}
        />
      )}

      <div className='mt-6'>
        <IconButton
          label={isManualAddress ? 'Search Address' : 'Enter address manually'}
          variant='none'
          buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
          leftIcon={<FontAwesomeIcon icon={faArrowUpArrowDown} />}
          onClick={() => {
            setValue('isManualAddress', !isManualAddress);
          }}
        />
      </div>
    </div>
  );
};

export default AddressFormComponent;
