import { debounce } from 'lodash';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import AnalyticsService from '../../../services/AnalyticsService';
import { AnalyticsEventEnum, RootState } from '../../../types';
import { getTeamDefaultValues } from '../../../utils/WealthPlanUtils';
import ZenSidebarModalForm from '../../Zen/ZenSidebarModalForm';
import AdditionalRealStocksFormInputs from './Forms/AdditionalRealStocksFormInputs';
import CommissionIncomeFormInputs from './Forms/CommissionIncomeFormInputs';
import RevenueShareIncomeFormInputs from './Forms/RevenueShareIncomeFormInputs';
import StockPurchasePlanSppFormInputs from './Forms/StockPurchasePlanSppFormInputs';
import SetWealthGoalCardV2 from './SetWealthGoalCardV2';
import StockForecastSliderInput from './StockForecastSliderInput';

export interface GoalsFormInput {
  earningsGoalValue: number;
  averageHomeSalePrice: number;
  transactionsPerYear: number;
  volumePerYear: number;
  useAverageFixedCommission: boolean;
  averageFixedCommission: number;
  averageTransactionCommissionPercentage: number;
  teamLead: boolean;
  teamNumberCount?: number;
  teamTransactionsPerMember?: number;
  teamAverageSalesPrice?: number;
  teamLeaderCommissionPercentagePerTransaction?: number;
  teamUseAverageFixedCommission?: boolean;
  teamAverageFixedCommission?: number;
  teamAverageTransactionCommissionPercentage?: number;
  sppParticipation: boolean;
  revShareParticipation: boolean;
  agentReferredPerYear: number;
  agentsAgentReferredPerYear: number;

  ownsAdditionalRealStockShares: boolean;
  additionalRealShares: number;

  eliteAgentProductionAwardParticipation: boolean;
  eliteAgentCulturalAwardParticipation: boolean;
  realStockFuturePrice: number;
}

interface SetGoalsSideBarV2Props {
  allowWealthGoalEdit?: boolean;
  isOpen: boolean;
  onClose(): void;
  onSubmit(values: GoalsFormInput): void;
  isSubmitting?: boolean;
}

const SetGoalsSideBarV2: React.FC<SetGoalsSideBarV2Props> = ({
  allowWealthGoalEdit,
  isOpen,
  onClose,
  onSubmit,
  isSubmitting = false,
}) => {
  const {
    wealthManagement: {
      defaultWealthGoalsResponse: { data: defaultWealthGoalsResponse },
      wealthGoalsResponse: { data: wealthGoalsResponse },
    },
  } = useSelector((state: RootState) => state);

  const defaultValues =
    allowWealthGoalEdit && !!wealthGoalsResponse
      ? wealthGoalsResponse
      : !!defaultWealthGoalsResponse && !allowWealthGoalEdit
      ? defaultWealthGoalsResponse
      : undefined;

  const goalDetails = defaultValues?.goalDetails;

  const formControls = useForm<GoalsFormInput>({
    defaultValues: {
      additionalRealShares: goalDetails?.additionalRealShares ?? 0,
      averageFixedCommission: goalDetails?.averageFixedCommission?.amount ?? 0,
      averageHomeSalePrice: goalDetails?.averageHomeSalePrice?.amount ?? 300000,
      averageTransactionCommissionPercentage:
        goalDetails?.averageTransactionCommissionPercentage ?? 2.25,
      earningsGoalValue: goalDetails?.earningsGoalValue?.amount,
      eliteAgentProductionAwardParticipation:
        goalDetails?.eliteAgentProductionAwardParticipation ?? false,
      eliteAgentCulturalAwardParticipation:
        goalDetails?.eliteAgentProductionAwardParticipation ?? false,
      realStockFuturePrice: goalDetails?.realStockFuturePrice?.amount,
      transactionsPerYear: goalDetails?.transactionsPerYear ?? 12,
      useAverageFixedCommission:
        goalDetails?.useAverageFixedCommission ?? false,
      volumePerYear: goalDetails?.volumePerYear?.amount ?? 0,
      agentReferredPerYear: goalDetails?.agentReferredPerYear ?? 0,
      agentsAgentReferredPerYear: goalDetails?.agentsAgentReferredPerYear ?? 0,
      ownsAdditionalRealStockShares:
        !!goalDetails?.additionalRealShares &&
        goalDetails?.additionalRealShares > 0,
      sppParticipation: goalDetails?.sppParticipation ?? false,
      revShareParticipation: goalDetails?.revShareParticipation ?? false,
      ...getTeamDefaultValues(goalDetails),
    },
  });

  const { control, watch, handleSubmit } = formControls;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string) => {
      AnalyticsService.instance().logEvent(event);
    }, 500),
    [],
  );

  const handleClose = () => {
    logAnalyticsEvent(AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_X_GO_BACK);
    onClose();
  };

  return (
    <ZenSidebarModalForm
      title={allowWealthGoalEdit ? 'Edit Goal Setup' : 'Goal Setup'}
      isOpen={isOpen}
      onClose={handleClose}
      actionTitle={allowWealthGoalEdit ? 'Update My Plan' : 'My Plan'}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      isDisabled={isSubmitting}
    >
      <FormProvider {...formControls}>
        {allowWealthGoalEdit && <SetWealthGoalCardV2 />}
        <h2 className='font-zen-body text-xl font-medium text-zen-dark-9'>
          Set Sub Goals
        </h2>
        <p className='font-zen-body mt-1 text-base text-zen-dark-8 mb-4'>
          Please provide some information so we can create a plan for you.
        </p>
        <div className='space-y-4'>
          <CommissionIncomeFormInputs logAnalyticsEvent={logAnalyticsEvent} />
          <StockPurchasePlanSppFormInputs
            logAnalyticsEvent={logAnalyticsEvent}
          />
          <RevenueShareIncomeFormInputs logAnalyticsEvent={logAnalyticsEvent} />
          <AdditionalRealStocksFormInputs
            logAnalyticsEvent={logAnalyticsEvent}
          />
          <StockForecastSliderInput
            formControlParam={control}
            formWatchParam={watch}
            noContainer={false}
          />
        </div>
      </FormProvider>
    </ZenSidebarModalForm>
  );
};

export default SetGoalsSideBarV2;
