import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import ZenContributorCell from '../components/table/Cells/ZenContributorCell';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenSimpleConfirmationModal from '../components/Zen/Modal/ZenSimpleConfirmationModal';
import ZenMilliDateCell from '../components/Zen/Table/Cell/ZenMilliDateCell';
import ZenButton from '../components/Zen/ZenButton';
import ZenResourceContainer from '../components/Zen/ZenResourceContainer';
import ZenRoute from '../components/Zen/ZenRoute';
import { RevShareContributionResponse } from '../openapi/arrakis';
import {
  exportPendingRevenueSharePayments,
  fetchPendingContributionsForTable,
} from '../slices/RevenueSlice';
import { AppDispatch, RootState } from '../types';
import { isSmScreen } from '../utils/BrowserUtils';
import { displayAmount, zeroMoneyValue } from '../utils/CurrencyUtils';
import { parseCsv } from '../utils/reportsUtils';

type Match = {
  outgoingPaymentId: string;
  id: string;
};

export const columns: Array<Column<RevShareContributionResponse>> = [
  {
    Header: 'Contributor Name',
    accessor: 'agent',
    Cell: ({ value, row }) => (
      <Link to={`/people/${value?.id}`}>
        <ZenContributorCell
          name={`${value?.firstName} ${value?.lastName}`}
          imageUrl={value?.imageUrl!}
          tier={row.original.tier!}
        />
      </Link>
    ),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Transaction',
    accessor: 'transaction',
    Cell: ({ value }) => <p>{value?.addressOneLine}</p>,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Settled Date',
    accessor: 'settledAt',
    Cell: ({ value }) => <ZenMilliDateCell date={value} />,
    disableSortBy: true,
    disableFilters: true,
  },
];

const ZenAgentDetailRevSharePendingPayment: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    agentDetail: {
      detailResponse: { data: detail },
    },
    revenue: { contributors, contributorsLoading, contributorsError },
  } = useSelector((state: RootState) => state);
  const { id: agentId } = useParams() as Match;

  const [isOpen, setIsOpen] = useState(false);
  const [exportData, setExportData] = useState<string | undefined>(undefined);
  const exportToCSV = async () => {
    let csvData: string | undefined;
    csvData = await dispatch(exportPendingRevenueSharePayments(agentId));

    setExportData(csvData);
  };

  const dynamicColumn: Array<Column<RevShareContributionResponse>> = useMemo(
    () => [
      ...columns,
      {
        Header: `Amount (${detail?.defaultCurrency})`,
        accessor: 'amount',
        Cell: ({ value }) => (
          <div className='flex flex-row justify-end w-24'>
            <p className='md:text-right'>
              {displayAmount(
                value || zeroMoneyValue(detail?.defaultCurrency!),
                { hideCurrency: true },
              )}
            </p>
          </div>
        ),
        disableSortBy: true,
        disableFilters: true,
      },
    ],
    [detail?.defaultCurrency],
  );

  return (
    <ZenRoute title='Pending Payments'>
      <div className='p-4'>
        <ZenResourceContainer
          isEmpty={false}
          loading={contributorsLoading || !!contributors?.outgoingPaymentId}
          resourceName='Pending Payments'
          errorCode={contributorsError}
        >
          <div className='flex flex-row items-center mb-4 space-x-2'>
            <span className='font-bold text-xl'>Next Payment: </span>
            <span className='font-bold text-xl'>
              {displayAmount(contributors?.amount)}
            </span>
          </div>
          <div className='flex flex-row justify-between items-center mb-4 md:hidden'>
            <span className='font-zen-body text-xl text-zen-dark-9'>
              Contributions ({contributors?.totalCount || 0})
            </span>
            <ZenButton
              label='Export'
              variant='primary-outline'
              LeftIconComponent={
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className='text-primary-blue mr-1'
                  fontSize={14}
                />
              }
              onClick={() => {
                setIsOpen(true);
                exportToCSV();
              }}
            />
          </div>
        </ZenResourceContainer>

        <ZenResourceIndexContainer<RevShareContributionResponse>
          columns={dynamicColumn}
          header={`Contributions (${contributors?.totalCount || 0})`}
          RightComponent={
            !isSmScreen() ? (
              <ZenButton
                label='Export'
                variant='primary-outline'
                LeftIconComponent={
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className='text-primary-blue mr-1'
                    fontSize={14}
                  />
                }
                onClick={() => {
                  setIsOpen(true);
                  exportToCSV();
                }}
              />
            ) : undefined
          }
          resourceName='Pending Payment'
          fetchData={async (req) =>
            dispatch(
              fetchPendingContributionsForTable(
                agentId,
                req.page,
                req.pageSize,
              ),
            )
          }
          hideFilters
        />
        <ZenSimpleConfirmationModal
          variant='info'
          title='Export to CSV?'
          isSubmitting={false}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          hideBottomButtons
        >
          <div className='mt-6 space-x-3 flex flex-row items-start'>
            {exportData ? (
              <CSVLink
                data={parseCsv(exportData! || '')}
                filename='revshare-payments-pending.csv'
                target='_blank'
                className='bg-primary-blue text-white rounded-lg py-2 px-4.5 w-full flex items-center justify-center text-center border-2 border-primary-blue'
                onClick={() => setIsOpen(false)}
              >
                Download
              </CSVLink>
            ) : (
              <ZenButton isSubmitting />
            )}
            <ZenButton
              label='Cancel'
              variant='secondary-gray-outline'
              onClick={() => setIsOpen(false)}
              isFullWidth
            />
          </div>
        </ZenSimpleConfirmationModal>
      </div>
    </ZenRoute>
  );
};
export default ZenAgentDetailRevSharePendingPayment;
