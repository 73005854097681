import { get } from 'lodash';
import { DateTime } from 'luxon';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Controller, UseControllerOptions, Validate } from 'react-hook-form-v6';
import { FieldValues } from 'react-hook-form-v6/dist/types';
import { FieldErrors } from 'react-hook-form-v6/dist/types/errors';
import { FormFieldTooltipIndexProps } from '../types';
import { MAX_YEAR_ALLOWED } from '../utils/DateUtils';
import { cn } from '../utils/classUtils';
import FormErrorMessage from './FormErrorMessage';

interface ControlledDatePickerInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends UseControllerOptions<TFieldValues>,
    FormFieldTooltipIndexProps {
  errors: FieldErrors<TFieldValues>;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  customInput?: React.ReactElement;
  datePickerConfig?: Omit<
    ReactDatePickerProps,
    'name' | 'onChange' | 'selected' | 'onBlur' | 'readOnly'
  >;
}

const ControlledDatePickerInput: React.FC<ControlledDatePickerInputProps> = ({
  errors,
  control,
  name,
  label,
  defaultValue,
  rules,
  placeholder,
  readOnly,
  customInput,
  datePickerConfig = {},
  tooltipIndex,
}) => {
  const error = get(errors, name);

  return (
    <div className='space-y-1 w-full'>
      {label && (
        <label className='inline-block' htmlFor={name}>
          {label}
        </label>
      )}
      <Controller
        id={name}
        control={control}
        name={name}
        defaultValue={defaultValue || ''}
        rules={{
          ...(rules || {}),
          validate: (val) => {
            if (val && DateTime.fromISO(val).year > MAX_YEAR_ALLOWED) {
              return `Year cannot be greater than ${MAX_YEAR_ALLOWED}.`;
            }
            return (rules?.validate as Validate)?.(val);
          },
        }}
        render={({ name, value, onChange, onBlur, ref }) => (
          <div className='rounded' data-tooltip-index={tooltipIndex}>
            <DatePicker
              {...datePickerConfig}
              name={name}
              selected={value ? DateTime.fromISO(value).toJSDate() : null}
              onChange={(date: Date) =>
                onChange(DateTime.fromJSDate(date).toISODate())
              }
              placeholderText={placeholder}
              autoComplete='off'
              onBlur={onBlur}
              ref={ref}
              readOnly={readOnly}
              dateFormatCalendar='MMMM'
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              id={name}
              disabledKeyboardNavigation
              customInput={customInput}
              className={cn(
                !customInput &&
                  'appearance-none p-1.5 border-gray-200 rounded bg-white focus:outline-none focus:ring-0 w-full',
                {
                  'bg-gray-50': readOnly,
                },
              )}
            />
          </div>
        )}
      />
      {error && <FormErrorMessage message={error.message} />}
    </div>
  );
};

export default ControlledDatePickerInput;
