import { faTableColumns } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface GeminiDefaultEmptyProps {
  message: string;
  icon?: React.ReactElement;
}

const GeminiDefaultEmpty: React.FC<GeminiDefaultEmptyProps> = ({
  message,
  icon = <FontAwesomeIcon icon={faTableColumns} size='2x' />,
}) => {
  return (
    <div className='w-full h-full p-5 flex flex-col items-center justify-center'>
      {icon}
      <p className='text-center font-inter text-gray-400 mt-0.5 text-sm'>
        {message}
      </p>
    </div>
  );
};

export default GeminiDefaultEmpty;
