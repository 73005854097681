import React from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';

export interface ColorPickerProps {
  color?: string;
  showPicker: boolean;
  setColor(col: string): void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  color,
  showPicker = false,
  setColor,
}) => {
  if (showPicker) {
    return (
      <div className='z-50'>
        <HexColorPicker color={color} onChange={setColor} />
        <div className='mt-3 border rounded-md px-2 py-1 flex flex-row items-center'>
          #
          <HexColorInput
            color={color}
            onChange={setColor}
            className='focus:outline-none'
          />
        </div>
      </div>
    );
  }
  return null;
};

export default ColorPicker;
