import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  AgentDivisionRequest,
  DivisionControllerApi,
  DivisionResponse,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { attachDivisionsToAgent } from '../../../slices/AgentSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { ISelectOption } from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import IconButton from '../../IconButton';
import ZenControlledAsyncSelectInput from '../../Zen/Input/ZenControlledAsyncSelectInput';
import ZenSidebarModalActionFooterV2 from '../../Zen/Modal/ZenSidebarModalActionFooterV2';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';

interface AddDivisionToAgentFormProps {
  isOpen: boolean;
  onClose(): void;
  agentId: string;
}

interface FormData {
  division: ISelectOption;
}

const AddDivisionToAgentForm: React.FC<AddDivisionToAgentFormProps> = ({
  isOpen,
  onClose,
  agentId,
}) => {
  const dispatch = useDispatch();

  const [divisions, setDivisions] = useState<DivisionResponse[]>();
  const [selectedDivisions, setSelectedDivisions] = useState<
    DivisionResponse[]
  >([]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const division = watch('division');

  const onSubmit = async () => {
    const divisionIds: AgentDivisionRequest[] = (selectedDivisions || [])?.map(
      (division) => ({
        id: division?.id!,
      }),
    );

    await dispatch(attachDivisionsToAgent(agentId, divisionIds));

    onClose();
  };

  const fetchDivisions = async () => {
    try {
      const {
        data: { divisions },
      } = await new DivisionControllerApi(
        getYentaConfiguration(),
      ).getDivisions();

      setDivisions(divisions || []);

      const options = divisions?.map((division) => ({
        value: division?.id!,
        label: division?.name!,
      }))!;

      return options;
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to fetch divisions', e);
      dispatch(
        showErrorToast(
          'We had a problem fetching divisions',
          'Please try again in a few moments.',
        ),
      );
      return [];
    }
  };

  useEffect(() => {
    if (division?.value) {
      const isAlreadyPresent = selectedDivisions?.find(
        (selectedDivision) => selectedDivision?.id === division?.value,
      );

      if (!isAlreadyPresent) {
        const divisionObject = divisions?.find(
          (obj) => obj?.id === division?.value,
        );

        setSelectedDivisions([
          ...selectedDivisions,
          divisionObject as DivisionResponse,
        ]);
      }

      setValue('division', { label: 'Select a division', value: '' });
    }
  }, [division?.value, divisions, selectedDivisions, setValue]);

  useEffect(() => {
    fetchDivisions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ZenSidebarModal title='Add Division' isOpen={isOpen} onClose={onClose}>
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='edit-team-form'
      >
        <div className='p-5'>
          <div className='mt-3'>
            <ZenControlledAsyncSelectInput<FormData, 'division'>
              name='division'
              control={control}
              label='Select Division'
              placeholder='Select a division'
              fetchData={async (search, page) => {
                if (page !== 0) {
                  return [];
                }

                const filteredDivisions = (divisions || []).filter(
                  (division) => {
                    return division?.name
                      ?.toLowerCase()
                      .includes(search.toLowerCase());
                  },
                );

                return filteredDivisions.map((division) => ({
                  label: division?.name!,
                  value: division?.id!,
                }));
              }}
            />
          </div>

          {!isEmpty(selectedDivisions) && (
            <div className='font-medium font-zen-body text-base mt-6'>
              List of Divisions
            </div>
          )}

          {selectedDivisions?.map((division) => {
            return (
              <div
                className='py-2 flex flex-row justify-between items-center border-b border-zen-dark-4'
                key={division?.id}
              >
                <div className='flex items-end space-x-4'>
                  <img
                    className='object-contain w-5 h-5'
                    src={division?.logoUrl}
                    alt='division-icon'
                  />

                  <span className='font-zen-body font-normal text-base leading-5 text-zen-dark-7'>
                    {division?.name}
                  </span>

                  <span className='text-xs font-zen-body text-zen-dark-8'>
                    Member
                  </span>
                </div>

                <div className='cursor-pointer'>
                  <IconButton
                    variant='none'
                    buttonStyle='bg-zen-danger-light rounded-full'
                    leftIcon={
                      <FontAwesomeIcon
                        icon={light('trash-can')}
                        className='my-1 mx-0 p-0.5 text-zen-danger'
                        aria-label='delete-division'
                      />
                    }
                    onClick={async () => {
                      setSelectedDivisions(
                        selectedDivisions.filter((selectedDivision) => {
                          return selectedDivision?.id !== division?.id;
                        }),
                      );
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <ZenSidebarModalActionFooterV2
          onClose={onClose}
          isSubmitting={isSubmitting}
          isDisabled={isEmpty(selectedDivisions) || isSubmitting}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default AddDivisionToAgentForm;
