import {
  faArrowUpRightFromSquare,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import TranscriptionGif from '../../../../assets/img/roar/transcription.gif';
import {
  VoiceCallBlock,
  VoiceCallBlockRecordingTypeEnum,
} from '../../../../openapi/yada';
import { cn } from '../../../../utils/classUtils';
import TranscribedByLeoPill from './TranscribedByLeoPill';

const inProgressVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 1 } },
  exit: { opacity: 0, transition: { duration: 1 } },
  bgInitialLeft: {
    background:
      'linear-gradient(to right, rgba(255,255,255,0) 90%, rgba(255,255,255, 1) 98%), linear-gradient(to left, rgba(255,255,255,0) 90%, rgba(255,255,255, 1) 100%)',
  },
  bgAnimateLeft: {
    background:
      'linear-gradient(to right, rgba(255,255,255,0) 90%, rgba(255,255,255, 1) 98%), linear-gradient(to left, rgba(255,255,255,0) 0%, rgba(255,255,255, 1) 0%)',
    transition: { duration: 9 },
  },
  bgInitialRight: {
    background:
      'linear-gradient(to left, rgba(255,255,255,0) 90%, rgba(255,255,255, 1) 98%), linear-gradient(to right, rgba(255,255,255,0) 90%, rgba(255,255,255, 1) 100%)',
  },
  bgAnimateRight: {
    background:
      'linear-gradient(to left, rgba(255,255,255,0) 90%, rgba(255,255,255, 1) 98%), linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255, 1) 0%)',
    transition: { duration: 9 },
  },
  inProgressTextInitial: { opacity: 0 },
  inProgressTextAnimate: { opacity: 1, transition: { duration: 1 } },
  inProgressTextExit: { opacity: 0, transition: { duration: 1 } },
};

const InProgressText: React.FC<{ text: string }> = ({ text }) => {
  return (
    <motion.p
      variants={inProgressVariants}
      className='text-sm py-2 bg-white text-rezen-blue-600'
      initial='inProgressTextInitial'
      animate='inProgressTextAnimate'
      exit='inProgressTextExit'
    >
      {text}
    </motion.p>
  );
};

type VoiceCallTranscriptionInProgressProps = {
  animate: boolean;
  recordingInProgress: boolean;
  onAnimationComplete: () => void;
};

export const VoiceCallTranscriptionInProgress: React.FC<VoiceCallTranscriptionInProgressProps> = ({
  animate,
  recordingInProgress,
  onAnimationComplete,
}) => {
  const [animated, setAnimated] = useState(false);

  const handleOnAnimationComplete = () => {
    setAnimated(true);
    onAnimationComplete();
  };

  return (
    <motion.div
      className={cn(
        '-mx-3 relative',
        animated && 'absolute inset-0 flex justify-center',
      )}
      variants={inProgressVariants}
      initial='initial'
      animate='animate'
      exit='exit'
      key='transcription-in-progress'
    >
      <img
        src={TranscriptionGif}
        className='w-full h-12'
        alt='transcription-in-progress'
      />
      <div className='absolute inset-0 flex items-stretch justify-center'>
        <motion.div
          className='flex-1 transform'
          variants={inProgressVariants}
          initial='bgInitialLeft'
          animate={animate ? 'bgAnimateLeft' : 'bgInitialLeft'}
        />
        <div className='relative bg-white flex items-center'>
          <div
            className={cn(
              'flex items-center h-full',
              animate && 'absolute inset-0 justify-center whitespace-nowrap',
            )}
          >
            <AnimatePresence>
              {!animate && (
                <>
                  {recordingInProgress ? (
                    <InProgressText
                      key='recording-in-progress'
                      text='Recording in progress'
                    />
                  ) : (
                    <InProgressText
                      key='transcription-in-progress'
                      text='Transcription in progress'
                    />
                  )}
                </>
              )}
            </AnimatePresence>
          </div>
          {animate && (
            <VoiceCallTranscriptionComplete
              onAnimationComplete={handleOnAnimationComplete}
            />
          )}
        </div>
        <motion.div
          className='flex-1 transform'
          variants={inProgressVariants}
          initial='bgInitialRight'
          animate={animate ? 'bgAnimateRight' : 'bgInitialRight'}
        />
      </div>
    </motion.div>
  );
};

type VoiceCallTranscriptionCompleteProps = { onAnimationComplete: () => void };

const completeVariants: Variants = {
  containerInitial: { opacity: 0 },
  containerAnimate: {
    opacity: 1,
    transition: { duration: 1 },
  },
  containerExit: { opacity: 0, transition: { duration: 1 } },
  textColor: {
    color: [
      '#00A5FE',
      '#00A5FE',
      '#00A5FE',
      '#321268',
      '#321268',
      '#321268',
      '#00A5FE',
    ],
    transition: { duration: 3, delay: 1.5, repeat: 2 },
  },
  fadeLeft: {
    background: [
      'linear-gradient(to left,rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 1) 95%)',
      'linear-gradient(to left,rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 1) 90%',
      'linear-gradient(to left,rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 1) 95%)',
    ],
    transition: {
      duration: 1,
      delay: 4,
      repeatDelay: 2,
      repeat: 1,
    },
  },
  fadeRight: {
    background: [
      'linear-gradient(to right,rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 1) 100%)',
      'linear-gradient(to right,rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 1) 20%)',
      'linear-gradient(to right,rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 1) 100%)',
    ],
    transition: {
      duration: 3,
      delay: 1.5,
      repeat: 1,
    },
  },
};

export const VoiceCallTranscriptionComplete: React.FC<VoiceCallTranscriptionCompleteProps> = ({
  onAnimationComplete,
}) => {
  return (
    <motion.div
      key='transcription-complete'
      className='relative'
      variants={completeVariants}
      initial='containerInitial'
      animate='containerAnimate'
      exit='containerExit'
    >
      <motion.div
        className='absolute inset-0'
        variants={completeVariants}
        animate='fadeLeft'
        onAnimationComplete={onAnimationComplete}
      />
      <motion.p
        variants={completeVariants}
        animate='textColor'
        className='py-2 text-center font-zen-body text-sm'
      >
        Transcription Complete!
      </motion.p>
      <motion.div
        className='absolute inset-0'
        variants={completeVariants}
        animate='fadeRight'
      />
    </motion.div>
  );
};

type VoiceCallTranscriptionSummaryProps = {
  recordingBlock: VoiceCallBlock;
  showTranscriptionDetails(): void;
};

const summaryVariants: Variants = {
  initial: { opacity: 0, transition: { duration: 1 } },
  animate: { opacity: 1, transition: { duration: 1 } },
};

export const VoiceCallTranscriptionSummary: React.FC<VoiceCallTranscriptionSummaryProps> = ({
  recordingBlock,
  showTranscriptionDetails,
}) => {
  const [showSummary, setShowSummary] = useState(true);

  const { summary, transcript } = recordingBlock?.recordingTranscription || {};

  const showViewTranscript =
    recordingBlock.recordingType === VoiceCallBlockRecordingTypeEnum.Call &&
    !isEmpty(transcript?.segments);

  return (
    <motion.div
      variants={summaryVariants}
      className='font-zen-body text-sm'
      initial='initial'
      animate='animate'
    >
      <div className='flex items-center justify-between space-x-2'>
        <button
          className='font-medium text-rezen-blue-600'
          onClick={() => setShowSummary(!showSummary)}
        >
          Summary
          <FontAwesomeIcon
            icon={showSummary ? faChevronUp : faChevronDown}
            className='ml-1 text-rezen-blue-600'
            fontSize={13}
          />
        </button>
        <TranscribedByLeoPill />
      </div>
      <p
        className={cn(
          'mt-3 font-light text-zen-dark',
          showSummary ? 'line-clamp-none' : 'line-clamp-2',
        )}
      >
        {summary?.abstractSummary || 'There is no summary for this recording'}
      </p>
      {showViewTranscript && (
        <div className='mt-4 flex justify-end'>
          <button
            className='font-medium text-rezen-blue-600'
            onClick={showTranscriptionDetails}
          >
            View Transcript
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              className='ml-1 text-rezen-blue-600'
              fontSize={13}
            />
          </button>
        </div>
      )}
    </motion.div>
  );
};
