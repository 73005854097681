import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { ReactComponent as PdfLink } from '../../../assets/img/pdf-link.svg';

interface AttachedFileProps {
  altText: string;
  url: string;
}

const AttachedFile: React.FC<AttachedFileProps> = ({ altText, url }) => {
  return (
    <div className='max-w-max py-2'>
      <div className='flex flex-row items-center space-x-2 bg-white shadow-md p-2'>
        <PdfLink className='w-10 h-10 text-gray-400' />
        <div className='flex flex-row items-center space-x-2'>
          <p className='font-primary-regular text-sm text-gray-600'>
            {altText}
          </p>
          <a href={url} target='_blank' rel='noreferrer'>
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              className='text-primary px-0.5 mt-1'
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AttachedFile;
