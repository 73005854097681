import { faHandsHoldingDollar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../types';
import { displayFormattedFixedAmountWithCurrency } from '../../../../utils/CurrencyUtils';
import ResourceContainer from '../../../ResourceContainer';

interface WealthPlanReferralInfoCardV2Props {}

const WealthPlanReferralInfoCardV2: React.FC<WealthPlanReferralInfoCardV2Props> = () => {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [
    location,
  ]);

  const { earningsOverviewResponse, referralInfo } = useSelector(
    (state: RootState) => state.wealthManagement,
  );

  const projectedEarnings = displayFormattedFixedAmountWithCurrency(
    earningsOverviewResponse?.data?.projectedEarningsValue,
    false,
    0,
  );

  const realAgentFirstName =
    params.get('realAgentFirstName') ??
    referralInfo.data?.referringAgentFirstName;
  const realAgentLastName =
    params.get('realAgentLastName') ??
    referralInfo.data?.referringAgentLastName;

  return (
    <ResourceContainer
      loading={
        earningsOverviewResponse.loading && !earningsOverviewResponse.data
      }
      isEmpty={false}
      resourceName='Wealth goal info'
    >
      <div className='w-full p-4 border border-zen-light-gray-2 bg-zen-light-blue rounded-xl font-zen-body-2'>
        <div className='text-base flex flex-col items-start space-y-2 font-zen-title-2'>
          <FontAwesomeIcon icon={faHandsHoldingDollar} />
          {realAgentFirstName && realAgentLastName ? (
            <div>
              {realAgentFirstName} {realAgentLastName} created this WealthPlan
              for you
            </div>
          ) : (
            <div>A WealthPlan has been created for you</div>
          )}
        </div>
        <div className='border-b border-zen-dark-4 my-4' />
        <div className='flex items-center justify-between'>
          <span className='text-sm font-medium text-zen-dark-12 leading-[22px]'>
            5 Year Projection
          </span>
          <span className='text-[22px] font-semibold leading-10 text-zen-dark-9'>
            {projectedEarnings}
          </span>
        </div>
      </div>
    </ResourceContainer>
  );
};

export default WealthPlanReferralInfoCardV2;
