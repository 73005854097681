import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { padStart } from 'lodash';
import React from 'react';

interface GeminiChecklistProgressCellProps {
  total?: number;
  completed?: number;
}

const GeminiChecklistProgressCell: React.FC<GeminiChecklistProgressCellProps> = ({
  total = 0,
  completed = 0,
}) => {
  return (
    <div className='flex items-center font-zen-body-2 text-xs font-semibold text-regent-600 flex-shrink-0 space-x-1'>
      <div className='flex items-center justify-center h-5'>
        <FontAwesomeIcon icon={faCheck} />
      </div>
      <span>CHECKLIST</span>
      <div className='flex items-center text-sm shrink-0'>
        <span className='text-regent-900 mr-0.5'>
          {padStart(completed.toString(), 2, '0')}
        </span>
        /<span className='ml-0.5'>{padStart(total.toString(), 2, '0')}</span>
      </div>
    </div>
  );
};

export default GeminiChecklistProgressCell;
