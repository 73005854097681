import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TransactionResponse } from '../openapi/arrakis';

const useRedirectAwayTransactionEffect = (
  transactionId: string,
  transactionDetail: TransactionResponse | undefined,
): boolean => {
  const history = useHistory();
  const location = useLocation();
  const isTransactionDetailLoaded = transactionDetail?.id! === transactionId;
  const isListing = !!transactionDetail?.listing && isTransactionDetailLoaded;

  const path = location.pathname?.replace('/transactions', '/listings');

  const handleRedirection = useCallback(async () => {
    if (isListing) {
      history.replace(`${path}${location?.search}`);
    }
  }, [history, isListing, location?.search, path]);

  useEffect(() => {
    handleRedirection();
  }, [handleRedirection]);

  return isListing;
};

export default useRedirectAwayTransactionEffect;
