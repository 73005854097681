import React from 'react';
import { AgentStateOneOfStateOrProvincesEnum } from '../../../openapi/mercury';
import { AgentResponseAccountCountryEnum } from '../../../openapi/yenta';
import { STATE_OR_PROVINCE_ABBREVIATIONS } from '../../../utils/AnnouncementUtils';
import AnnouncementRecipientStateBubble from './AnnouncementRecipientStateBubble';

interface AnnouncementRecipientStatesStackProps {
  statesOrProvincesList?:
    | AgentStateOneOfStateOrProvincesEnum[]
    | AgentResponseAccountCountryEnum[];
  statesStackLength?: number;
}

const AnnouncementRecipientStatesStack: React.FC<AnnouncementRecipientStatesStackProps> = ({
  statesOrProvincesList,
  statesStackLength = 2,
}) => {
  const statesOrProvincesDisplayValues = statesOrProvincesList?.map(
    (el) =>
      STATE_OR_PROVINCE_ABBREVIATIONS[
        el as keyof typeof STATE_OR_PROVINCE_ABBREVIATIONS
      ],
  );
  const statesOrProvincesDisplayValuesLength =
    statesOrProvincesDisplayValues?.length;

  if (!statesOrProvincesDisplayValuesLength) {
    return null;
  }

  return (
    <div className='flex flex-row items-center mt-1.5'>
      {statesOrProvincesDisplayValues
        ?.slice(0, statesStackLength)
        .map((state) => (
          <AnnouncementRecipientStateBubble key={state} state={state} />
        ))}
      {statesOrProvincesDisplayValuesLength > statesStackLength && (
        <AnnouncementRecipientStateBubble
          state={`+${statesOrProvincesDisplayValuesLength - statesStackLength}`}
        />
      )}
    </div>
  );
};

export default AnnouncementRecipientStatesStack;
