import { Autocomplete } from '@react-google-maps/api';
import { useCallback, useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { GOOGLE_MAPS_REQUESTED_FIELDS } from '../constants/GoogleMapsConstants';
import { useGoogleMapsAPILoader } from '../hooks/useGoogleMapsAPILoader';
import { FormFieldTooltipIndexProps } from '../types';
import FormErrorMessage from './FormErrorMessage';

export interface GooglePlaceLocationType {
  formatted_address: string;
  place_id: string;
  geometry: {
    location: {
      lat: number | undefined;
      lng: number | undefined;
    };
  };
  address_components?:
    | { long_name: string; short_name: string; types: string[] }[]
    | undefined;
}

export type GooglePlaceType = Record<string, GooglePlaceLocationType>;

interface ControlledGoogleAutocompleteSearchBarV7<
  TFieldValues extends FieldValues | GooglePlaceType =
    | FieldValues
    | GooglePlaceType,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  subLabel?: string;
  placeholder?: string;
  isRequired?: boolean;
}

const ControlledGoogleAutocompleteSearchInputV7 = <
  TFieldValues extends FieldValues | GooglePlaceType =
    | FieldValues
    | GooglePlaceType,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  placeholder,
  name,
  tooltipIndex,
  shouldUnregister = true,
  isRequired = false,
  ...rest
}: ControlledGoogleAutocompleteSearchBarV7<TFieldValues, TName>) => {
  const [
    autocomplete,
    setAutocomplete,
  ] = useState<google.maps.places.Autocomplete>();
  const isLoaded = useGoogleMapsAPILoader();
  const onLoad = useCallback(
    (autocomplete) => {
      setAutocomplete(autocomplete);
    },
    [setAutocomplete],
  );

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      name={name}
      {...rest}
      render={({
        field: { onBlur, onChange, value },
        fieldState: { error },
      }) => {
        return (
          <div className='w-full space-y-1'>
            {!!label && (
              <label htmlFor={name}>
                {label}
                {subLabel && (
                  <span className='font-primary-regular text-xs text-grey-500 pl-2'>
                    {subLabel}
                  </span>
                )}
                {isRequired && <span className='text-red-600'>*</span>}
              </label>
            )}
            {isLoaded && (
              <Autocomplete
                className='w-full'
                onLoad={onLoad}
                onPlaceChanged={() => {
                  const selectedPlace = autocomplete?.getPlace();
                  onChange({
                    formatted_address: selectedPlace?.formatted_address,
                    place_id: selectedPlace?.place_id,
                    geometry: {
                      location: {
                        lat: selectedPlace?.geometry?.location?.lat(),
                        lng: selectedPlace?.geometry?.location?.lng(),
                      },
                    },
                    address_components: selectedPlace?.address_components,
                  });
                }}
                restrictions={{
                  country: ['us', 'ca'],
                }}
                options={{
                  fields: GOOGLE_MAPS_REQUESTED_FIELDS,
                }}
              >
                <div className='relative'>
                  <input
                    type='text'
                    value={value?.formatted_address}
                    onChange={(place) => onChange(place)}
                    placeholder={placeholder}
                    className='appearance-none p-1.5 border-grey-200 rounded bg-primary-light focus:outline-none focus:ring-0 w-full pr-14 truncate'
                    onBlur={onBlur}
                    data-tooltip-index={tooltipIndex}
                  />
                  <span className='absolute top-2 right-3'>
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className='text-primary-dark'
                    />
                  </span>
                </div>
              </Autocomplete>
            )}

            {error?.message && <FormErrorMessage message={error.message} />}
          </div>
        );
      }}
    />
  );
};

export default ControlledGoogleAutocompleteSearchInputV7;
