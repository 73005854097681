import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Config } from 'react-popper-tooltip';
import {
  LedgerItemValue,
  LedgerItemValueLedgerItemTypeEnum,
  PaymentParticipantValue,
} from '../../openapi/arrakis';
import { capitalizeEnum, isStringPresent } from '../../utils/StringUtils';
import { isLedgerItemDebt } from '../../utils/TransactionHelper';
import Hover from '../Hover';
import ZenSignedNumber from './ZenSignedNumber';

interface ZenLedgerItemRowProps {
  item: LedgerItemValue;
  participant: PaymentParticipantValue;
  showBorder: boolean;
}

const ZenLedgerItemRow: React.FC<ZenLedgerItemRowProps> = ({
  item,
  participant,
  showBorder,
}) => {
  const getItemName = () => {
    if (item.ledgerItemType === LedgerItemValueLedgerItemTypeEnum.LeaderSplit)
      return 'Team Split';
    let name = capitalizeEnum(item.ledgerItemType!);

    if (
      item.ledgerItemType ===
        LedgerItemValueLedgerItemTypeEnum.InstantPaymentRepayment &&
      isStringPresent(item.note)
    ) {
      name = item.note;
    }

    if (
      item.ledgerItemType ===
      LedgerItemValueLedgerItemTypeEnum.TransactionRepayment
    ) {
      name = 'Debt Repayment';
    }

    if (!!item.paidOnBehalfOfName)
      return `${name} for ${item.paidOnBehalfOfName}`;

    return name;
  };

  const hoverConfig: Config = {
    trigger: 'hover',
    placement: 'top',
  };

  const noteTooltipIcon = () => {
    return (
      <FontAwesomeIcon
        icon={regular('circle-info')}
        className='pl-0.5 text-gray-500 cursor-pointer'
        data-testid='ledger-item-row-note'
      />
    );
  };

  const noteTooltipContent = () => {
    return (
      <div className='text-zen-dark-12 font-medium text-base font-zen-body px-6 '>
        {item.note}
      </div>
    );
  };

  return (
    <div>
      {showBorder && <div className='border-b' />}
      <div className='flex justify-between items-center p-2 font-zen-bod'>
        <div className='flex flex-row items-center space-x-1'>
          <span className='text-gray-400 text-[12px]'>{getItemName()}</span>
          {item.note && !isLedgerItemDebt(item?.ledgerItemType) && (
            <Hover hoverComponent={noteTooltipContent()} config={hoverConfig}>
              {noteTooltipIcon()}
            </Hover>
          )}
          {isLedgerItemDebt(item?.ledgerItemType) && (
            <a
              href={`/people/${participant?.yentaId}/all-debts`}
              target='_blank'
              rel='noreferrer'
            >
              <FontAwesomeIcon
                icon={regular('circle-info')}
                className='text-gray-500 cursor-pointer'
                aria-label='debt-info'
              />
            </a>
          )}
        </div>
        <span className='font-semibold'>
          <ZenSignedNumber value={item.amount!} textSize='xs' />
        </span>
      </div>
    </div>
  );
};

export default ZenLedgerItemRow;
