import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import flag from '../../../assets/img/flag.png';
import {
  ChecklistApi,
  ItemResponse,
  ItemResponseStatusEnum,
} from '../../../openapi/sherlock';
import ErrorService from '../../../services/ErrorService';
import { showErrorToastForErrorCode } from '../../../slices/ToastNotificationSlice';
import { RootState } from '../../../types';
import { getSherlockConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ResourceContainer from '../../ResourceContainer';
import ZenChecklistLabel from './ZenChecklistLabel';
import ZenChecklistProgressCell from './ZenChecklistProgressCell';

interface ZenChecklistPopperProps {
  checklistId: string | undefined;
  transactionId: string | undefined;
  isListing?: boolean;
}

const ZenChecklistPopper: React.FC<ZenChecklistPopperProps> = ({
  checklistId,
  transactionId,
  isListing,
}) => {
  const dispatch = useDispatch();
  const { checklistProgressById } = useSelector(
    (state: RootState) => state.checklist,
  );
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checklistItems, setChecklistItems] = useState<ItemResponse[]>([]);

  const checklistProgress = checklistId
    ? checklistProgressById[checklistId]
    : undefined;

  const progress =
    Math.round(
      ((checklistProgress?.completedCountIncludingOptionals || 0) /
        (checklistProgress?.itemCountIncludingOptionals || 0)) *
        100,
    ) || 0;

  const getChecklistDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await new ChecklistApi(
        getSherlockConfiguration(),
      ).getChecklistById(checklistId!);
      setChecklistItems(data.items || []);
    } catch (e) {
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to fetch the checklists details',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, checklistId]);

  useEffect(() => {
    if (!!checklistId && isOpen) {
      getChecklistDetails();
    }
  }, [checklistId, getChecklistDetails, isOpen]);

  const filterChecklist = useMemo(() => {
    const checklist = (checklistItems || [])
      ?.filter((item) => !item?.hidden)
      .filter(
        (item) =>
          item?.status === ItemResponseStatusEnum.RevisionRequested ||
          !!item?.required,
      );

    return checklist;
  }, [checklistItems]);

  const CompletedItemCount = filterChecklist.reduce((count, item) => {
    if (item.complete) {
      return count + 1;
    }
    return count;
  }, 0);

  return (
    <div onMouseLeave={() => setIsOpen(false)}>
      <Popover
        isOpen={isOpen}
        positions={['top', 'bottom']}
        onClickOutside={() => setIsOpen(false)}
        parentElement={document.getElementById('main-page')!}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor='white'
            arrowSize={10}
            arrowStyle={{
              bottom: 1,
              zIndex: 1,
            }}
            className='popover-arrow-container'
            arrowClassName='popover-arrow'
          >
            <div className='md:w-[420px] w-[400px] border rounded-xl border-grey-200 bg-primary-light divide-y-2 space-y-2 py-2 text-primary-dark shadow-2xl'>
              <div className='flex items-center justify-between px-6'>
                <div className='text-base pt-2'>
                  Checklist Completed: {CompletedItemCount}/
                  {filterChecklist?.length ?? 0}
                </div>
                <div
                  onClick={() => setIsOpen(false)}
                  className='bg-grey-400 rounded-full w-5 h-5 flex items-center justify-center text-primary-light cursor-pointer'
                >
                  <FontAwesomeIcon icon={faClose} size='sm' />
                </div>
              </div>
              <div className='px-6 max-h-64 scrollbar overflow-y-auto'>
                <ResourceContainer
                  loading={isLoading}
                  isEmpty={false}
                  resourceName='Checklist'
                >
                  <div className='py-5 space-y-4'>
                    {(filterChecklist || [])?.map((checklist) => (
                      <div className='flex items-center' key={checklist.id}>
                        <div className='w-3/4'>
                          <div className='text-base'>
                            {checklist.position}. {checklist.name}
                          </div>
                          <div className='flex items-center space-x-1'>
                            <div
                              className={classNames('text-sm ', {
                                'text-red-500': checklist.required,
                              })}
                            >
                              {checklist.required ? 'Required' : 'Optional'}
                            </div>
                            {checklist.urgent && (
                              <div className='flex items-center space-x-1'>
                                <img
                                  src={flag}
                                  alt='flag'
                                  className='-mt-1 h-4 w-4 object-contain'
                                />
                                <div className='text-sm text-red-500'>
                                  Urgent
                                </div>
                              </div>
                            )}
                            {checklist.dueDate && (
                              <div className='flex items-center space-x-1'>
                                <div className='text-grey-400 text-sm ml-2'>
                                  Due:
                                  {DateTime.fromISO(checklist.dueDate).toFormat(
                                    ' LL/dd/y',
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          <ZenChecklistLabel
                            status={checklist.status}
                            isRequired={checklist?.required!}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </ResourceContainer>
              </div>
            </div>
          </ArrowContainer>
        )}
      >
        {checklistId ? (
          <div
            onMouseEnter={() => setIsOpen(true)}
            onClick={() => {
              const routePrefix = isListing ? 'listings' : 'transactions';
              history.push(`/${routePrefix}/${transactionId}/checklist`);
            }}
            className='cursor-pointer'
            aria-label='checklist-popper'
          >
            <ZenChecklistProgressCell
              progress={progress}
              total={checklistProgress?.itemCount || 0}
              completed={checklistProgress?.completedCount || 0}
              percentageBar={false}
            />
          </div>
        ) : (
          <div className='pl-2' aria-label='checklist-popper'>
            N/A
          </div>
        )}
      </Popover>
    </div>
  );
};

export default ZenChecklistPopper;
