import { capitalize, last } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgentResponse as ArrakisAgentResponse } from '../../../openapi/arrakis';
import { InviteResponse } from '../../../openapi/avalon';
import { AgentResponse, UserControllerApi } from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { fetchAuthUserDetail } from '../../../slices/AuthSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { AgentTransactionCard, RootState } from '../../../types';
import { isAgentActive, isImageValid } from '../../../utils/AgentHelper';
import { cn } from '../../../utils/classUtils';
import { resizeFile } from '../../../utils/FileUtils';
import { getYentaImageUrl } from '../../../utils/ImgUtils';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import {
  formatPhoneNumber,
  formatAddressObject,
} from '../../../utils/StringUtils';
import AdminOrSuperAdmin from '../../auth/AdminOrSuperAdmin';
import GeminiAgentContactInfoDetailHeader from '../../Gemini/Agent/GeminiAgentContactInfoDetailHeader';
import GeminiPill from '../GeminiPill';
import GeminiProfileImage from '../GeminiProfileImage';
import TransactionOrListingOverviewDisplayCard from '../Profile/TransactionOrListingOverviewDisplayCard';
import { TransactionOrListingPillVariantType } from '../Profile/TransactionOrListingOverviewDisplayPill';
import GeminiAgentResidentialDetail from './GeminiAgentResidentialDetail';
import GeminiAgentSocialMediaLinks from './GeminiAgentSocialMediaLinks';
import GeminiIdentityInfoHeaderDetails from './GeminiIdentityInfoHeaderDetails';
import GeminiStatusHeaderInfo from './GeminiStatusHeaderInfo';

export interface GeminiAgentDetailHeaderProps {
  agentId: string;
  performance: ArrakisAgentResponse | undefined;
  detail: AgentResponse | undefined;
  transactionOverview?: AgentTransactionCard[];
  listingOverview?: AgentTransactionCard[];
  invitedJointVentures?: InviteResponse[];
}

const GeminiAgentDetailHeader: React.FC<GeminiAgentDetailHeaderProps> = ({
  detail,
  performance,
  listingOverview,
  transactionOverview,
  invitedJointVentures,
}) => {
  const dispatch = useDispatch();
  const { isAdmin, userDetail, isBroker } = useSelector(
    (state: RootState) => state.auth,
  );

  const [loading, setLoading] = useState<boolean>(false);

  const onResizedImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (isImageValid(event)) {
      try {
        setLoading(true);
        const uploadedFile = event.target.files![0]!;
        const resizedImage = await resizeFile(uploadedFile);
        await new UserControllerApi(
          getYentaConfiguration(),
        ).uploadAvatarProvided(detail?.id!, (resizedImage! as unknown) as File);
        setLoading(false);
        await dispatch(fetchAuthUserDetail());
      } catch (err) {
        setLoading(false);
        dispatch(showApiErrorModal(err));
        ErrorService.notifyIgnoreHandled('Error uploading user avatar', err);
      }
    } else {
      dispatch(showErrorToast('File size exceeds maximum limit of 10 MB.'));
    }
  };

  const isSelf = userDetail?.id === detail?.id;
  const isProducing = performance?.producingStatus;

  return (
    <header className='w-full bg-primary-navy rounded-2xl md:px-8 px-4 py-5 flex md:flex-row flex-col md:space-x-8 font-zen-body-2 text-white'>
      <div className='md:w-[16%] w-full h-full flex flex-col items-center'>
        <GeminiProfileImage
          width={174}
          imageUrl={getYentaImageUrl(detail?.avatar!)}
          editable={isAdmin || isSelf}
          onImageChange={onResizedImageChange}
          isSubmitting={loading}
          imageClassName='object-cover'
        />
        <h1 className='text-xl font-semibold text-center mt-2'>
          {detail?.fullName}
        </h1>
        <h3 className='text-base font-light text-center mt-1'>
          {detail?.title}
        </h3>
        <GeminiAgentSocialMediaLinks detail={detail} />
        <AdminOrSuperAdmin>
          <GeminiPill
            label={capitalize(detail?.agentStatus)}
            containerClassNames={cn(
              'w-full mt-6',
              isAgentActive(detail?.agentStatus)
                ? 'bg-midnight-green-300 text-midnight-green-900'
                : 'bg-sangria-300 text-sangria-900',
            )}
          />
        </AdminOrSuperAdmin>
        <GeminiPill
          label={isProducing ? 'Producing' : 'Non Producing'}
          containerClassNames={cn(
            'w-full mt-2.5',
            isProducing
              ? 'bg-rezen-light-blue-100 text-regent-900'
              : 'bg-grey-200 text-grey-600',
          )}
        />
        <AdminOrSuperAdmin>
          {performance?.permanentlyProducing && (
            <GeminiPill
              label='Permanently Producing'
              containerClassNames='w-full bg-yellow-100 text-yellow-900 mt-2.5'
            />
          )}
        </AdminOrSuperAdmin>
      </div>
      <div className='md:w-[50%] w-full h-full space-y-3'>
        <GeminiAgentResidentialDetail
          detail={detail}
          performance={performance}
        />
        <GeminiIdentityInfoHeaderDetails detail={detail} />
        <GeminiStatusHeaderInfo
          detail={detail}
          invitedJointVentures={invitedJointVentures}
        />
      </div>
      <div className='md:w-[34%] w-full h-full space-y-5 mt-3 md:mt-0'>
        <GeminiAgentContactInfoDetailHeader
          phoneNumber={
            !!detail?.phoneNumber
              ? formatPhoneNumber(detail?.phoneNumber)
              : 'N/A'
          }
          emailAddress={detail?.emailAddress}
          addresses={
            !!detail?.addresses?.length
              ? formatAddressObject(last(detail?.addresses)!)
              : 'N/A'
          }
        />
        {transactionOverview && (
          <TransactionOrListingOverviewDisplayCard
            title='Transactions'
            items={transactionOverview.map((transaction) => ({
              value: transaction.title,
              title: transaction.subtitle.toUpperCase() as TransactionOrListingPillVariantType,
            }))}
            navigateTo={
              isAdmin || isBroker
                ? `/people/${detail?.id}/transactions`
                : '/transactions'
            }
          />
        )}
        {listingOverview && (
          <TransactionOrListingOverviewDisplayCard
            title='Listings'
            items={listingOverview.map((transaction) => ({
              value: transaction.title,
              title: transaction.subtitle.toUpperCase() as TransactionOrListingPillVariantType,
            }))}
            navigateTo={
              isAdmin || isBroker
                ? `/people/${detail?.id}/listings`
                : '/listings'
            }
          />
        )}
      </div>
    </header>
  );
};

export default GeminiAgentDetailHeader;
