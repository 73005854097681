import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCappingAward } from '../../../../slices/WealthManagementSlice';
import { RootState } from '../../../../types';
import { formatMoneyValue } from '../../../../utils/CurrencyUtils';
import IncomeProjectionChart from '../../V2/IncomeProjectionChart';
import WealthPlanInfoCard from '../../V2/WealthPlanInfoCard';
import WealthPlanSidebarContainer from './WealthPlanSidebarContainer';

const StockAwardForCappingSidebarContentV2 = () => {
  const dispatch = useDispatch();
  const {
    cappingAwardResponse: {
      loading: cappingAwardLoading,
      data: cappingAwardResponse,
    },
    wealthGoalsResponse: { data: wealthGoalsResponse },
  } = useSelector((state: RootState) => state.wealthManagement);

  const {
    currentCappingAwardValue,
    projectedCappingAwardValue,
    currentCappingAwardShares,
    projectedCappingAwardShares,
  } = cappingAwardResponse || {};

  const isLoading = cappingAwardLoading && !cappingAwardResponse;

  useEffect(() => {
    dispatch(getCappingAward());
  }, [dispatch, wealthGoalsResponse]);

  return (
    <WealthPlanSidebarContainer
      type='STOCK_AWARD_FOR_CAPPING'
      loading={isLoading}
      isEmpty={!cappingAwardResponse}
      resourceName='Stock Award for Capping'
    >
      <WealthPlanInfoCard
        label='5 Year Projected Value'
        value={formatMoneyValue(projectedCappingAwardValue)}
        valueSizeVariant='text-3xl'
        subValue={projectedCappingAwardShares}
      />
      <div className='flex flex-row justify-between space-x-3'>
        <WealthPlanInfoCard
          label='Value of Shares Earned-to-Date'
          value={formatMoneyValue(currentCappingAwardValue)}
        />
        <WealthPlanInfoCard
          label='Number of Shares Earned-to-Date'
          value={currentCappingAwardShares?.toString()}
        />
      </div>
      <IncomeProjectionChart
        earningType='STOCK_AWARD_FOR_CAPPING'
        title={{
          text: '5 Year Projected Value',
        }}
      />
    </WealthPlanSidebarContainer>
  );
};

export default StockAwardForCappingSidebarContentV2;
