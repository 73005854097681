import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import ZenAction from '../Zen/ZenAction';
import { formatStateOrProvince } from './utils';

interface ContactLocationProps {
  onClick: () => void;
  administrativeAreaIds?: string[];
}

const ContactLocation = ({
  onClick,
  administrativeAreaIds,
}: ContactLocationProps) => {
  return (
    <div className='p-6'>
      <div className='border rounded-2xl w-full h-auto p-4 gap-3 bg-zen-light-gray-1'>
        <div className='flex flex-col'>
          <div className='flex flex-row justify-between items-center'>
            <p className='text-primary-dark font-semibold font-zen-body text-base'>
              Contact available in
            </p>
            <div>
              <ZenAction
                text='Edit'
                icon={
                  <FontAwesomeIcon
                    icon={faPencil}
                    size='sm'
                    className='text-primary-blue'
                  />
                }
                onClick={onClick}
              />
            </div>
          </div>
          <div className='flex flex-wrap flex-shrink-0'>
            {(administrativeAreaIds?.length || 0) > 0
              ? formatStateOrProvince(administrativeAreaIds!)
              : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactLocation;
