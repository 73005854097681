import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faPowerOff } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  FlexTeamConfigControllerApi,
  FlexTeamConfigDtoCdaApproverEnum,
  FlexTeamConfigDtoLeaderOverridablePropertiesEnum,
  FlexTeamConfigDtoPaymentDetailsVisibilityEnum,
  FlexTeamDto,
  FlexTeamDtoStatusEnum,
  UpdateFlexTeamConfig,
  UpdateFlexTeamConfigCdaApproverEnum,
  UpdateFlexTeamConfigLeaderOverridablePropertiesEnum,
  UpdateFlexTeamConfigPaymentDetailsVisibilityEnum,
  UpdateFlexTeamConfigPermittedTransactionEditorsEnum,
} from '../../../../openapi/yenta';
import ErrorService from '../../../../services/ErrorService';
import {
  deleteFlexTeam,
  getCommissionPlan,
  getFlexTeamDetailOverview,
  updateFlexTeamConfigurations,
} from '../../../../slices/TeamSlice';
import { AppDispatch, ISelectOption } from '../../../../types';
import { isSmScreen } from '../../../../utils/BrowserUtils';
import { getYentaConfiguration } from '../../../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import { NUMBER_VALIDATIONS } from '../../../../utils/Validations';
import ZenControlledAsyncSelectInput from '../../Input/ZenControlledAsyncSelectInput';
import ZenControlledCheckButtonInput from '../../Input/ZenControlledGroupCheckButtonInput';
import ZenControlledSelectInput from '../../Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../../Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../../Input/ZenControlledToggleInput';
import ZenConfirmationModal from '../../Modal/ZenConfirmationModal';
import ZenSidebarModalActionFooterV2 from '../../Modal/ZenSidebarModalActionFooterV2';
import ZenButton from '../../ZenButton';
import ZenControlledGroupRadioButtonInputV2 from '../../ZenControlledGroupRadioButtonInputV2';
import AvailableRealCapLevelPopover from './ProTeamPopover/AvailableRealCapLevelPopover';
import LeadersRealCapPopover from './ProTeamPopover/LeadersRealCapPopover';
import MaximumTeammatePopover from './ProTeamPopover/MaximumTeammatePopover';
import MinimumTeammatesPopover from './ProTeamPopover/MinimumTeammatesPopover';

interface EditConfigTeamsProps {
  team: FlexTeamDto;
  onClose(): void;
  readOnly?: boolean;
}

interface FormData {
  teamConfigType: ISelectOption;
  commissionPlan: ISelectOption;
  leaderCap: string;
  realCap: string[];
  minTeamMates: number;
  maxTeamMates: number;
  minLeaders: number;
  maxLeaders: number;
  minLeaderSplit: number;
  eligibleInstantPayments: boolean;
  paymentDetailsVisibility: boolean;
  leaderCDARoles: boolean;
  permittedTransactionToggle?: boolean;
  leaderPaymentDetailsVisibility: boolean;
  commissionDocumentApprover?: ISelectOption;
  enforceLeaderSplits: boolean;
  permittedTransactionEditors: ISelectOption;
}

const EditConfigTeams: React.FC<EditConfigTeamsProps> = ({
  team,
  onClose,
  readOnly,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [inActivating, setInActivating] = useState(false);

  const { control, watch, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: {
      teamConfigType: {
        label: team?.config?.name,
        value: team?.config?.id!,
      },
      maxLeaders: team?.config?.maxLeaders!,
      minLeaders: team?.config?.minLeaders!,
      maxTeamMates: team?.config?.maxTeammates!,
      minTeamMates: team?.config?.minTeammates!,
      minLeaderSplit: team?.config?.leaderSplitConfig?.minSplitPercent!,
      leaderCap: team?.config?.realCapConfig?.leaderCap!.toString(),
      enforceLeaderSplits: team.config?.leaderSplitConfig?.enforceSplits,
      realCap: team?.config?.allowedMemberCaps?.map(String),
      paymentDetailsVisibility:
        team.config?.paymentDetailsVisibility ===
        FlexTeamConfigDtoPaymentDetailsVisibilityEnum.Full
          ? true
          : false,
      commissionDocumentApprover: {
        label: capitalizeEnum(team.config?.cdaApprover ?? ''),
        value: team.config?.cdaApprover,
      },
      leaderCDARoles: team?.config?.leaderOverridableProperties?.includes(
        FlexTeamConfigDtoLeaderOverridablePropertiesEnum.CdaApprovalRoles,
      ),
      leaderPaymentDetailsVisibility: team?.config?.leaderOverridableProperties?.includes(
        FlexTeamConfigDtoLeaderOverridablePropertiesEnum.PaymentDetailsVisibility,
      ),
      permittedTransactionToggle: team?.config?.leaderOverridableProperties?.includes(
        FlexTeamConfigDtoLeaderOverridablePropertiesEnum.PermittedTransactionEditorRoles,
      ),
      permittedTransactionEditors: team?.config?.permittedTransactionEditors
        ? {
            value: team?.config?.permittedTransactionEditors,
            label: capitalizeEnum(
              team?.config?.permittedTransactionEditors ?? '',
            ),
          }
        : undefined,
    },
  });

  const commissionPlanId =
    watch('teamConfigType')?.value.split(',')[1] ||
    team?.config?.commissionPlanId;

  useEffect(() => {
    async function fetchData() {
      if (commissionPlanId) {
        const commissionPlan = await dispatch(
          getCommissionPlan(commissionPlanId),
        );
        setValue('commissionPlan', {
          label: commissionPlan?.name!,
          value: commissionPlan?.id!,
        });
      }
    }
    fetchData();
  }, [dispatch, commissionPlanId, setValue]);

  const onSubmit = async (values: FormData) => {
    setIsSubmitting(true);
    const leaderOverridableProperties = [];

    const defaultCaps = [4000, 6000, 12000];
    const excludedMemberCaps = defaultCaps
      ?.filter((x) => !values.realCap?.map(Number).includes(x))
      .map((x) => x);

    values.leaderCDARoles &&
      leaderOverridableProperties.push(
        UpdateFlexTeamConfigLeaderOverridablePropertiesEnum.CdaApprovalRoles,
      );
    values.leaderPaymentDetailsVisibility &&
      leaderOverridableProperties.push(
        UpdateFlexTeamConfigLeaderOverridablePropertiesEnum.PaymentDetailsVisibility,
      );

    values.permittedTransactionToggle &&
      leaderOverridableProperties.push(
        UpdateFlexTeamConfigLeaderOverridablePropertiesEnum.PermittedTransactionEditorRoles,
      );

    const formData: UpdateFlexTeamConfig = {
      maxLeaders: values.maxLeaders,
      minLeaders: values.minLeaders,
      maxTeammates: values.maxTeamMates!,
      minTeammates: values.minTeamMates!,
      commissionPlanId: values.commissionPlan.value,
      realCapConfig: {
        leaderCap: parseInt(values.leaderCap),
        excludedMemberCaps,
      },
      paymentDetailsVisibility: values.paymentDetailsVisibility
        ? UpdateFlexTeamConfigPaymentDetailsVisibilityEnum.Full
        : UpdateFlexTeamConfigPaymentDetailsVisibilityEnum.None,
      cdaApprover: values.commissionDocumentApprover
        ?.value! as UpdateFlexTeamConfigCdaApproverEnum,
      leaderOverridableProperties: leaderOverridableProperties,
      leaderSplitConfig: {
        enforceSplits: values.enforceLeaderSplits,
        minSplitPercent: values.minLeaderSplit,
      },
      name: values.teamConfigType.label,
      permittedTransactionEditors: (values.permittedTransactionEditors
        .value as unknown) as UpdateFlexTeamConfigPermittedTransactionEditorsEnum,
    };

    await dispatch(updateFlexTeamConfigurations(team?.config?.id!, formData));
    dispatch(getFlexTeamDetailOverview(team?.id!, true));
    setIsSubmitting(false);
    onClose();
  };

  // TODO: Re-enable once Delete Functionality is added back.

  // const hasMembers =
  //   (team?.teammates?.length ?? 0) + (team?.leaders?.length ?? 0);

  return (
    <form
      className='flex flex-col justify-between max-h-[calc(100vh-175px)]'
      onSubmit={handleSubmit(onSubmit)}
      title='edit-team-form'
    >
      <div className='p-5 h-full overflow-y-auto'>
        <div className='mt-5'>
          <ZenControlledAsyncSelectInput<FormData, 'teamConfigType'>
            label='Team Config Type'
            name='teamConfigType'
            shouldUnregister={false}
            fetchData={async (search, page) => {
              let options: ISelectOption[] = [];

              try {
                const { data } = await new FlexTeamConfigControllerApi(
                  getYentaConfiguration(),
                ).searchFlexTeamConfigs(
                  page || 0,
                  10,
                  'ASC',
                  ['ID', 'NAME'],
                  undefined,
                  undefined,
                  search,
                  'PRO',
                );

                options = (data.results || []).map((config) => ({
                  label: `${config.name}`,
                  value: `${config.id!},${config.commissionPlanId}`,
                }));
              } catch (e) {
                ErrorService.notify('Unable to fetch team configs', e, {
                  data: { search, page },
                });
              }

              return options;
            }}
            control={control}
            readOnly
          />
        </div>
        <div className='mt-5'>
          <ZenControlledSelectInput<FormData, 'commissionPlan'>
            label='Commission Plan'
            name='commissionPlan'
            control={control}
            options={[]}
            shouldUnregister={false}
            readOnly
          />
        </div>
        <div className='mt-5'>
          <ZenControlledSelectInput<FormData, 'commissionDocumentApprover'>
            name='commissionDocumentApprover'
            placeholder='Commission Document Approver'
            control={control}
            shouldUnregister={false}
            options={[
              ...values(FlexTeamConfigDtoCdaApproverEnum).map((role) => ({
                value: role,
                label: capitalizeEnum(role),
              })),
            ]}
            readOnly={readOnly}
            label='Commission Document Approver'
          />
        </div>
        <div className='mt-5'>
          <ZenControlledSelectInput<FormData, 'permittedTransactionEditors'>
            name='permittedTransactionEditors'
            placeholder='Permitted Transaction Editors'
            control={control}
            shouldUnregister={false}
            isRequired
            rules={{
              required: 'Please select a transaction editor',
            }}
            options={[
              ...values(
                UpdateFlexTeamConfigPermittedTransactionEditorsEnum,
              ).map((role) => ({
                value: role,
                label: capitalizeEnum(role),
              })),
            ]}
            readOnly={readOnly}
            label='Transaction Editors'
          />
        </div>
        <div className='mt-5'>
          <div className='font-inter text-sm text-zen-dark-9 mb-5'>
            Leaders Real Cap
            <LeadersRealCapPopover />
          </div>
          <ZenControlledGroupRadioButtonInputV2<FormData, 'leaderCap'>
            options={[
              { value: '4000', label: '4K', disabled: readOnly },
              { value: '6000', label: '6K', disabled: readOnly },
              { value: '12000', label: '12K', disabled: readOnly },
            ]}
            control={control}
            name='leaderCap'
            minWidth='w-60'
          />
        </div>
        <div className='mt-5'>
          <div className='font-inter text-sm text-zen-dark-9 mb-5'>
            Available Real Cap for Teammates
            <AvailableRealCapLevelPopover />
          </div>
          <ZenControlledCheckButtonInput<FormData, 'realCap'>
            options={[
              { value: '4000', label: '4K', disabled: readOnly },
              { value: '6000', label: '6K', disabled: readOnly },
              { value: '12000', label: '12K', disabled: readOnly },
            ]}
            control={control}
            minWidth='w-60'
            name='realCap'
          />
        </div>
        <div className='space-y-3 mt-8'>
          <div className='flex flex-row justify-between items-center'>
            <div className='font-inter text-sm text-zen-dark-9'>
              Minimum Teammates <span className='text-zen-danger'>*</span>
              <MinimumTeammatesPopover />
            </div>
            <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
              <ZenControlledTextInput<FormData, 'minTeamMates'>
                type='number'
                name='minTeamMates'
                shouldUnregister={false}
                control={control}
                isRequired
                readOnly={readOnly}
                rules={{ ...NUMBER_VALIDATIONS }}
              />
            </div>
          </div>
          <div className='flex flex-row justify-between items-center'>
            <div className='font-inter text-sm text-zen-dark-9'>
              Maximum Teammates <span className='text-zen-danger'>*</span>
              <MaximumTeammatePopover />
            </div>
            <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
              <ZenControlledTextInput<FormData, 'maxTeamMates'>
                type='number'
                name='maxTeamMates'
                shouldUnregister={false}
                control={control}
                isRequired
                readOnly={readOnly}
                rules={{ ...NUMBER_VALIDATIONS }}
              />
            </div>
          </div>
          <div className='flex flex-row justify-between items-center'>
            <div className='font-inter text-sm text-zen-dark-9'>
              Minimum Leaders <span className='text-zen-danger'>*</span>
              <MinimumTeammatesPopover />
            </div>
            <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
              <ZenControlledTextInput<FormData, 'minLeaders'>
                type='number'
                name='minLeaders'
                shouldUnregister={false}
                control={control}
                readOnly={readOnly}
                isRequired
                rules={{ ...NUMBER_VALIDATIONS }}
              />
            </div>
          </div>
          <div className='flex flex-row justify-between items-center'>
            <div className='font-inter text-sm text-zen-dark-9'>
              Maximum Leaders <span className='text-zen-danger'>*</span>
              <MaximumTeammatePopover />
            </div>
            <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
              <ZenControlledTextInput<FormData, 'maxLeaders'>
                type='number'
                name='maxLeaders'
                shouldUnregister={false}
                control={control}
                readOnly={readOnly}
                isRequired
                rules={{ ...NUMBER_VALIDATIONS }}
              />
            </div>
          </div>

          <div className='flex flex-row justify-between items-center'>
            <div className='font-inter text-sm text-zen-dark-9'>
              Minimum Leader Split <span className='text-zen-danger'>*</span>
            </div>
            <div className='font-normal font-primary-regular text-sm text-zen-dark-9'>
              <ZenControlledTextInput<FormData, 'minLeaderSplit'>
                type='number'
                name='minLeaderSplit'
                shouldUnregister={false}
                control={control}
                readOnly={readOnly}
                isRequired
                rules={{
                  ...NUMBER_VALIDATIONS,
                  min: {
                    value: 0,
                    message: 'Number cannot be less than 0',
                  },
                  max: {
                    value: 100,
                    message: 'Number cannot be greater than 100',
                  },
                  required: 'Please enter a minimum leader split',
                }}
              />
            </div>
          </div>
        </div>
        <div className='mt-8'>
          <div className='divide-y border border-zen-dark-4 rounded-lg'>
            <div className='p-3'>
              <ZenControlledToggleInput<
                FormData,
                'leaderPaymentDetailsVisibility'
              >
                control={control}
                name='leaderPaymentDetailsVisibility'
                shouldUnregister={false}
                readOnly={readOnly}
                label='Allow Leader to change Payment Visibility'
              />
            </div>
            <div className='p-3'>
              <ZenControlledToggleInput<FormData, 'leaderCDARoles'>
                control={control}
                name='leaderCDARoles'
                shouldUnregister={false}
                readOnly={readOnly}
                label='Allow Leader to change CDA Approval Roles'
              />
            </div>
            <div className='p-3'>
              <ZenControlledToggleInput<FormData, 'permittedTransactionToggle'>
                control={control}
                name='permittedTransactionToggle'
                shouldUnregister={false}
                label='Allow Leader to change Permitted Transaction Editors'
              />
            </div>
          </div>
        </div>

        <div className='mt-8 pb-20'>
          {!readOnly && team.status === FlexTeamDtoStatusEnum.Active && (
            <ZenButton
              label='Mark as Inactive'
              variant='danger-link'
              onClick={() => setConfirmationModal(true)}
              LeftIconComponent={
                <FontAwesomeIcon icon={regular('power-off')} />
              }
            />
          )}
        </div>

        <ZenSidebarModalActionFooterV2
          onClose={onClose}
          buttonType='submit'
          isSubmitting={isSubmitting}
          isDisabled={isSubmitting || readOnly}
          submitButtonText='Update'
        />
      </div>
      <ZenConfirmationModal
        title={`Are you sure you want to mark ${team.name} as inactive?`}
        subtitle={
          <span className='text-zen-dark-9 font-zen-body'>
            Once a team is inactive, you cannot reactivate it.
          </span>
        }
        variant='danger'
        isOpen={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        onConfirm={async () => {
          setInActivating(true);
          await dispatch(deleteFlexTeam(team?.id!));
          setInActivating(false);
          setConfirmationModal(false);
          onClose();
          history.push('/teams');
        }}
        confirmButtonText='Mark as Inactive'
        confirmButtonLeftIcon={<FontAwesomeIcon icon={faPowerOff} />}
        isSubmitting={inActivating}
        size='large'
        hideIcon={isSmScreen()}
      />
    </form>
  );
};

export default EditConfigTeams;
