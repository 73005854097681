import { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { EmailControllerApi } from '../../../openapi/hermes';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showErrorToast,
  showInfoToast,
} from '../../../slices/ToastNotificationSlice';
import { getHermesConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { EMAIL_VALIDATIONS } from '../../../utils/Validations';
import ZenControlledEmailInput from '../Input/ZenControlledEmailInput';
import ZenSidebarModalForm from '../ZenSidebarModalForm';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';

interface ZenEmailStatusCheckFormSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  email: string;
}

const ZenEmailStatusCheckFormSidebarModal: React.FC<ZenEmailStatusCheckFormSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [emailStatusModal, setEmailStatusModal] = useState<{
    isOpen: boolean;
    title?: string;
  }>({
    isOpen: false,
    title: undefined,
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (values: FormData) => {
    try {
      const { data } = await new EmailControllerApi(
        getHermesConfiguration(),
      ).emailStatus({ email: values.email });
      if (!!data?.status) {
        dispatch(
          showInfoToast(
            `${values.email} is ${data?.banned ? 'banned' : 'not banned'}`,
          ),
        );
        setEmailStatusModal({
          isOpen: true,
          title: `${values?.email} is marked as ${capitalizeEnum(
            data?.status!,
          )}`,
        });
      } else {
        setEmailStatusModal({
          isOpen: true,
          title: `${values?.email} is not valid. Please check the email and try again.`,
        });
      }
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error email status check', e, {
        data: {
          email: values.email,
        },
      });
      dispatch(
        showErrorToast(
          'We had a problem checking email status',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <ZenSidebarModalForm
      title='Email Status Check'
      isOpen={isOpen}
      onClose={onClose}
      actionTitle='Save'
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <div className='mt-5 space-y-5'>
        <ZenControlledEmailInput<FormData, 'email'>
          control={control}
          label='Email'
          name='email'
          placeholder='jane@example.com'
          rules={{
            required: 'Email is required',
            ...EMAIL_VALIDATIONS,
          }}
          isRequired
        />
      </div>
      <ZenConfirmationModal
        isOpen={emailStatusModal?.isOpen}
        title='Email Status'
        subtitle={emailStatusModal?.title}
        size='large'
        hideFooterButtons
        onClose={() => setEmailStatusModal({ isOpen: false, title: undefined })}
      />
    </ZenSidebarModalForm>
  );
};

export default ZenEmailStatusCheckFormSidebarModal;
