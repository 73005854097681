interface GridCardItemProps {
  name: string;
  value: React.ReactElement | string;
}

const GridCardItem: React.FC<GridCardItemProps> = ({ name, value }) => {
  return (
    <td className='border p-3 h-20' key={name}>
      <p className='text-xs font-semibold text-gray-400 uppercase pb-1'>
        {name}
      </p>
      <div className='my-auto'>{value}</div>
    </td>
  );
};

export default GridCardItem;
