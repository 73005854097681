import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import WalletOfferings from '../components/Wallet/WalletOfferings';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenResourceContainer from '../components/Zen/ZenResourceContainer';
import ZenRoute from '../components/Zen/ZenRoute';
import { useWallet } from '../query/wallet/useWallet';

interface WalletRouteProps {}

const WalletRoute: React.FC<WalletRouteProps> = () => {
  const history = useHistory();
  const { data: wallet, isLoading, errorCode } = useWallet();

  const isPartneredWithUnit = wallet?.bankingPartnerships?.some(
    (partnership) => partnership.partner === 'UNIT',
  );

  useEffect(() => {
    if (isPartneredWithUnit) {
      const debitAccount = wallet?.bankingPartnerships
        ?.flatMap((partner) => partner.accounts || [])
        .find((account) => account?.id && account?.type === 'DEBIT');

      if (debitAccount) {
        history.push(`/wallet/${debitAccount.id}`);
      }
    }
  }, [wallet, isPartneredWithUnit, history]);

  return (
    <ZenRoute title='Wallet'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          {
            title: isPartneredWithUnit ? 'Wallet' : 'Apply for Charge or Debit',
            url: '/wallet',
          },
        ]}
        textClassNames='font-inter'
      >
        <ZenResourceContainer
          loading={isLoading}
          isEmpty={false}
          resourceName='wallet'
          errorCode={errorCode}
        >
          {!isPartneredWithUnit && <WalletOfferings />}
        </ZenResourceContainer>
      </ZenPageLayout>
    </ZenRoute>
  );
};

export default WalletRoute;
