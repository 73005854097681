import { faCloudArrowDown } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import config from '../../../config';
import { getAuthCookie } from '../../../utils/AuthUtils';
import FontAwesomeIconWithWrapper from '../../FontAwesomeIconWithWrapper';
import IconButton from '../../IconButton';
import ZenRoute from '../../Zen/ZenRoute';
import CommissionDepositReceipt from './CommissionDepositReceipt';

interface Match {
  transactionId: string;
  id: string;
}

interface CommissionDepositReceiptRouteProps
  extends RouteComponentProps<Match> {}

const CommissionDepositReceiptRoute: React.FC<CommissionDepositReceiptRouteProps> = ({
  match,
}) => {
  const { transactionId, id } = match.params;

  return (
    <ZenRoute title='Commission Deposit Receipt'>
      <div className='flex justify-end space-x-3 p-5'>
        <IconButton
          label='Download'
          variant='outline'
          buttonStyle='hover:bg-gray-100'
          textStyle='text-dark'
          leftIcon={
            <FontAwesomeIconWithWrapper
              icon={faCloudArrowDown}
              size='sm'
              className='text-gray-800'
            />
          }
          onClick={() => {
            window.open(
              `${config.apiBasePath.dali}/api/pdf?token=${
                config.daliAuthToken
              }&jwt=${getAuthCookie()}&url=${
                config.reactAppHostUrl
              }/pdf/transactions/${transactionId}/commission-deposit-receipt/${id}`,
              '_blank',
            );
          }}
        />
      </div>
      <CommissionDepositReceipt
        transactionId={transactionId}
        escrowDepositId={id}
        isPdf={false}
      />
    </ZenRoute>
  );
};

export default CommissionDepositReceiptRoute;
