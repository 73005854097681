import { debounce } from 'lodash';
import values from 'lodash/values';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { ReactComponent as NewFile } from '../../assets/img/file-new.svg';
import {
  CHECKLIST_ALLOWED_FILE_TYPES,
  MAX_DOC_SIZE_100MB,
} from '../../constants/FilesConstants';
import { useFetchPdfAndSetForm } from '../../hooks/useFetchPdfAndSetForm';
import {
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
  DirectoryEntryResponseRoleEnum,
  NationalBusinessIdentificationTypeEnum,
} from '../../openapi/yenta';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum, RootState } from '../../types';
import { getIdValidations, isCanadianUser } from '../../utils/AgentHelper';
import { capitalizeEnum } from '../../utils/StringUtils';
import { getAddressFromAddressComponent } from '../../utils/TransactionHelper';
import {
  EMAIL_VALIDATIONS,
  getPostalCodeValidation,
  GOOGLE_AUTO_COMPLETE_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../utils/Validations';
import IconButton from '../IconButton';
import ZenControlledDraggableDocumentUploadInput from '../Zen/Input/ZenControlledDraggableDocumentUploadInput';
import ZenControlledEmailInput from '../Zen/Input/ZenControlledEmailInput';
import ZenControlledGoogleAutocompleteSearchInput from '../Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledHTMLSelectInput from '../Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledPhoneNumberInput from '../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledStateOrProvinceInput from '../Zen/Input/ZenControlledStateOrProvince';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import { CreateDirectoryRequestFormData } from './DirectoryUtils';

interface BusinessInformationProps {
  isSubmitting?: boolean;
  isAddressManual?: boolean;
  toggleAddressMode?: () => void;
  w9Url?: string;
}

const ZenBusinessInformationForm: React.FC<BusinessInformationProps> = ({
  isSubmitting = false,
  isAddressManual = false,
  toggleAddressMode,
  w9Url,
}) => {
  const {
    control,
    setValue,
    watch,
  } = useFormContext<CreateDirectoryRequestFormData>();
  const [
    participantRole,
    autoCompletebusinessAddress,
    businessState,
    businessCountry,
    w9form,
  ] = watch([
    'participantRole',
    'autoCompletebusinessAddress',
    'businessState',
    'businessCountry',
    'w9form',
  ]);
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const setPdfFile = useCallback(
    (file: File | null) => {
      if (file) setValue('w9form', [file]);
    },
    [setValue],
  );

  useFetchPdfAndSetForm(w9Url, setPdfFile);

  const canadianUser = isCanadianUser(userDetail?.accountCountry!);
  const stateOrProvince = getAddressFromAddressComponent(
    autoCompletebusinessAddress?.address_components!,
  )?.stateOrProvince;

  const countryFromAutoComplete = getAddressFromAddressComponent(
    autoCompletebusinessAddress?.address_components!,
  )?.country;

  const currentCountry = isAddressManual
    ? businessCountry
    : countryFromAutoComplete;

  const showCanadaFields = currentCountry
    ? currentCountry === AddressRequestCountryEnum.Canada
    : canadianUser;

  const isExternalAgentRole =
    participantRole?.value === DirectoryEntryResponseRoleEnum.OtherAgent;

  const isHstIdRequired =
    (stateOrProvince || businessState) ===
    AddressRequestStateOrProvinceEnum.Ontario;
  const isGstIdRequired =
    (stateOrProvince || businessState) ===
      AddressRequestStateOrProvinceEnum.Alberta ||
    (stateOrProvince || businessState) ===
      AddressRequestStateOrProvinceEnum.BritishColumbia ||
    (stateOrProvince || businessState) ===
      AddressRequestStateOrProvinceEnum.Manitoba;
  const isQstIdRequired =
    (stateOrProvince || businessState) ===
    AddressRequestStateOrProvinceEnum.Quebec;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string, eventData?: Record<string, unknown>) => {
      AnalyticsService.instance().logEvent(event, eventData);
    }, 500),
    [],
  );

  return (
    <div>
      <div className='mt-5'>
        <ZenControlledTextInput<CreateDirectoryRequestFormData, 'company'>
          control={control}
          label='Business Name'
          name='company'
          shouldUnregister={false}
          placeholder='Business Name'
          rules={{
            required: 'Please enter a business name',
          }}
          onChangeSpy={() =>
            logAnalyticsEvent(
              AnalyticsEventEnum.DIRECTORY_CONTACT_BUSINESS_NAME_ENTERED,
            )
          }
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledEmailInput<
          CreateDirectoryRequestFormData,
          'primaryEmailAddress'
        >
          control={control}
          label='Business Primary Email Address'
          name='primaryEmailAddress'
          shouldUnregister={false}
          placeholder='E.g. John@example.com'
          rules={{
            required: 'Please enter a valid email address',
            ...EMAIL_VALIDATIONS,
          }}
          onChangeSpy={() =>
            logAnalyticsEvent(
              AnalyticsEventEnum.DIRECTORY_CONTACT_BUSINESS_PRIMARY_EMAIL_ENTERED,
            )
          }
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledEmailInput<
          CreateDirectoryRequestFormData,
          'secondaryEmailAddress'
        >
          control={control}
          label='Business Secondary Email Address'
          name='secondaryEmailAddress'
          shouldUnregister={false}
          placeholder='E.g. John@example.com'
          rules={{
            ...EMAIL_VALIDATIONS,
          }}
          onChangeSpy={() =>
            logAnalyticsEvent(
              AnalyticsEventEnum.DIRECTORY_CONTACT_BUSINESS_SECONDARY_EMAIL_ENTERED,
            )
          }
        />
      </div>
      <div className='mt-5'>
        <ZenControlledPhoneNumberInput<
          CreateDirectoryRequestFormData,
          'businessPhoneNumber'
        >
          control={control}
          label='Business Phone Number'
          name='businessPhoneNumber'
          shouldUnregister={false}
          placeholder='E.g. +1 (702) 123-4567'
          rules={{
            required: 'Please enter business phone number',
            ...PHONE_NUMBER_VALIDATIONS,
          }}
          onChangeSpy={() =>
            logAnalyticsEvent(
              AnalyticsEventEnum.DIRECTORY_CONTACT_BUSINESS_PHONE_ENTERED,
            )
          }
          isRequired
        />
      </div>
      <div className='mt-8 relative'>
        {!isAddressManual ? (
          <ZenControlledGoogleAutocompleteSearchInput<
            CreateDirectoryRequestFormData,
            'autoCompletebusinessAddress'
          >
            control={control}
            shouldUnregister={false}
            name='autoCompletebusinessAddress'
            label='Business Address'
            placeholder='E.g. 1st St. New York, NY 10010'
            rules={{
              ...GOOGLE_AUTO_COMPLETE_VALIDATIONS,
            }}
            onChangeSpy={() =>
              logAnalyticsEvent(
                AnalyticsEventEnum.DIRECTORY_CONTACT_BUSINESS_ADDRESS_ENTERED,
              )
            }
            isRequired
          />
        ) : (
          <div className='p-4 border-2 border-gray-300 rounded-lg bg-grey-100'>
            <p className='font-normal text-base'>Business Address</p>
            <div className='mt-4'>
              <ZenControlledTextInput<
                CreateDirectoryRequestFormData,
                'businessStreet'
              >
                control={control}
                label='Street'
                name='businessStreet'
                shouldUnregister={false}
                placeholder='E.g. 270 1st Ave.'
                rules={{
                  required: 'Street is required',
                }}
                isRequired
              />
            </div>
            <div className='mt-4'>
              <ZenControlledTextInput<
                CreateDirectoryRequestFormData,
                'businessStreet2'
              >
                control={control}
                label='Street Line 2'
                name='businessStreet2'
                shouldUnregister={false}
                placeholder='E.g. 270 1st Ave.'
              />
            </div>
            <div className='flex flex-row space-x-4 mt-4'>
              <ZenControlledHTMLSelectInput<
                CreateDirectoryRequestFormData,
                'businessCountry'
              >
                name='businessCountry'
                control={control}
                label='Country'
                shouldUnregister={false}
                placeholder='Country'
                options={[
                  {
                    label: 'Select Country',
                    value: '',
                  },
                  ...values(AddressRequestCountryEnum).map((country) => ({
                    value: country,
                    label: capitalizeEnum(country),
                  })),
                ]}
                isRequired
              />

              <ZenControlledStateOrProvinceInput<
                CreateDirectoryRequestFormData,
                'businessState'
              >
                name='businessState'
                control={control}
                shouldUnregister={false}
                setValue={setValue}
                selectedCountry={
                  (businessCountry as unknown) as AddressRequestCountryEnum
                }
                rules={{
                  required: 'State is required',
                }}
                defaultValue={
                  ('' as unknown) as AddressRequestStateOrProvinceEnum
                }
                isRequired
              />
            </div>
            <div className='flex flex-row space-x-4 mt-4'>
              <ZenControlledTextInput<
                CreateDirectoryRequestFormData,
                'businessCity'
              >
                control={control}
                label='City'
                name='businessCity'
                shouldUnregister={false}
                placeholder='Eg. New York'
                rules={{
                  required: 'City is required',
                }}
                isRequired
              />
              <ZenControlledTextInput<
                CreateDirectoryRequestFormData,
                'businessZip'
              >
                control={control}
                label='ZIP/Postal Code'
                name='businessZip'
                shouldUnregister={false}
                placeholder={
                  currentCountry === AddressRequestCountryEnum.Canada
                    ? 'E.g. M5V 1A1'
                    : 'E.g. 10001'
                }
                rules={{
                  required: 'Zip code is required',
                  ...getPostalCodeValidation(
                    currentCountry as AddressRequestCountryEnum,
                  ),
                }}
                isRequired
              />
            </div>
          </div>
        )}
        <div
          className={`absolute ${
            !isAddressManual ? '-top-3' : 'top-3 mr-3'
          } right-0`}
        >
          <IconButton
            label={
              !isAddressManual ? 'Enter address manually' : 'Search Address'
            }
            variant='none'
            buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
            leftIcon={<FontAwesomeIcon icon={faArrowUpArrowDown} />}
            onClick={() => toggleAddressMode?.()}
          />
        </div>
      </div>
      <div className='mt-5'>
        {showCanadaFields ? (
          <>
            <div className='text-lg font-zen-body font-semibold text-zen-dark-9 mt-3 mb-1'>
              <p>Tax Numbers</p>
            </div>
            {/** Ref: https://turbotax.intuit.ca/tips/business-number-know-need-one-8830 */}
            <div className='mt-5'>
              <ZenControlledTextInput<CreateDirectoryRequestFormData, 'bn'>
                control={control}
                name='bn'
                label='Business Number (BN)'
                placeholder='E.g. 123456789'
                rules={{
                  ...getIdValidations(
                    NationalBusinessIdentificationTypeEnum.Bn,
                  ),
                  required:
                    isExternalAgentRole && 'Please enter business number',
                }}
                isRequired={isExternalAgentRole}
                onChangeSpy={() =>
                  logAnalyticsEvent(
                    AnalyticsEventEnum.DIRECTORY_CONTACT_BUSINESS_NUMBER_ENTERED,
                  )
                }
              />
            </div>
            {/** Ref: https://turbotax.intuit.ca/tips/business-number-know-need-one-8830 */}
            <div className='mt-5'>
              <ZenControlledTextInput<CreateDirectoryRequestFormData, 'gstId'>
                control={control}
                name='gstId'
                shouldUnregister={false}
                label='GST Number'
                placeholder='E.g. 123456789RT1234'
                rules={{
                  ...getIdValidations(
                    NationalBusinessIdentificationTypeEnum.GstId,
                  ),
                  required:
                    isExternalAgentRole &&
                    isGstIdRequired &&
                    'Please enter GST number',
                }}
                isRequired={isExternalAgentRole && isGstIdRequired}
                onChangeSpy={() =>
                  logAnalyticsEvent(
                    AnalyticsEventEnum.DIRECTORY_CONTACT_GST_NUMBER_ENTERED,
                  )
                }
              />
            </div>
            {/** Ref: https://turbotax.intuit.ca/tips/business-number-know-need-one-8830 */}
            <div className='mt-5'>
              <ZenControlledTextInput<CreateDirectoryRequestFormData, 'hstId'>
                control={control}
                name='hstId'
                shouldUnregister={false}
                label='HST Number'
                placeholder='E.g. 123456789RT1234'
                rules={{
                  ...getIdValidations(
                    NationalBusinessIdentificationTypeEnum.HstId,
                  ),
                  required:
                    isExternalAgentRole &&
                    isHstIdRequired &&
                    'Please enter HST number',
                }}
                isRequired={isExternalAgentRole && isHstIdRequired}
                onChangeSpy={() =>
                  logAnalyticsEvent(
                    AnalyticsEventEnum.DIRECTORY_CONTACT_HST_NUMBER_ENTERED,
                  )
                }
              />
            </div>
            {/** Ref: https://support.stripe.com/questions/quebec-sales-tax-information */}
            <div className='mt-5'>
              <ZenControlledTextInput<CreateDirectoryRequestFormData, 'qstId'>
                control={control}
                name='qstId'
                shouldUnregister={false}
                label='QST Number'
                placeholder='E.g. 1234567890TQ1234'
                rules={{
                  ...getIdValidations(
                    NationalBusinessIdentificationTypeEnum.QstId,
                  ),
                  required:
                    isExternalAgentRole &&
                    isQstIdRequired &&
                    'Please enter QST number',
                }}
                isRequired={isExternalAgentRole && isQstIdRequired}
                onChangeSpy={() =>
                  logAnalyticsEvent(
                    AnalyticsEventEnum.DIRECTORY_CONTACT_QST_NUMBER_ENTERED,
                  )
                }
              />
            </div>
          </>
        ) : (
          <>
            <ZenControlledTextInput<CreateDirectoryRequestFormData, 'ein'>
              control={control}
              name='ein'
              shouldUnregister={false}
              label='EIN Number'
              placeholder='E.g. 12-1234567'
              isRequired={isExternalAgentRole}
              rules={{
                ...getIdValidations(NationalBusinessIdentificationTypeEnum.Ein),
                required: isExternalAgentRole && 'Please enter EIN number',
              }}
              onChangeSpy={() =>
                logAnalyticsEvent(
                  AnalyticsEventEnum.DIRECTORY_CONTACT_EIN_NUMBER_ENTERED,
                )
              }
            />
            <div className='mt-5'>
              <ZenControlledDraggableDocumentUploadInput<
                CreateDirectoryRequestFormData,
                'w9form'
              >
                name='w9form'
                control={control}
                height='small'
                shouldUnregister={false}
                isRequired={isExternalAgentRole}
                rules={{
                  required: isExternalAgentRole && 'Please upload W9 form',
                }}
                label='Upload W9 Form'
                accept={CHECKLIST_ALLOWED_FILE_TYPES.join(',')}
                isUploading={!!w9form && isSubmitting}
                onChangeSpy={() =>
                  logAnalyticsEvent(
                    AnalyticsEventEnum.DIRECTORY_CONTACT_W9_FORM_SELECTED,
                  )
                }
                customPlaceHolder={
                  <div className='flex md:flex-row flex-col justify-center items-center px-4'>
                    <NewFile className='w-8 h-8' />
                    {w9form?.[0]?.name ? (
                      <span className='font-zen-body font-semibold text-sm text-primary-blue ml-2 text-center'>
                        {w9form[0].name}
                      </span>
                    ) : (
                      <>
                        <span className='font-zen-body font-semibold text-sm text-primary-blue ml-2'>
                          Browse Files
                        </span>
                        <p className='font-zen-body text-sm font-semibold text-zen-dark-7 ml-1'>
                          or drag & drop new file
                        </p>
                      </>
                    )}
                  </div>
                }
                multiple={false}
                maxUploadSize={MAX_DOC_SIZE_100MB}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ZenBusinessInformationForm;
