import { SvgIcon } from '@material-ui/core';
import React from 'react';
import { ReactComponent as Pencil } from '../../../assets/icons/zen-pencil.svg';
import { BankAccountResponse } from '../../../openapi/yenta';
import Hover from '../../Hover';
import ZenBank from '../../../assets/img/zen/zen-bank.png';

export interface ZenBankDetailsCardProps {
  bank: BankAccountResponse;
  setEditing: (bank: BankAccountResponse) => void;
}

const ZenBankDetailsCard: React.FC<ZenBankDetailsCardProps> = ({
  bank,
  setEditing,
}) => {
  return (
    <div className='p-5 space-y-5 relative'>
      <div className='flex items-center space-x-3'>
        <div className='bg-grey-100 rounded-lg p-2'>
          <img src={ZenBank} alt='bank-icon' className='w-10 h-10' />
        </div>
        <div>
          <div className='flex flex-row items-center'>
            <p className='font-zen-body text-zen-dark text-lg font-semibold '>
              {bank?.bankName}{' '}
            </p>
            <div
              onClick={() => setEditing(bank)}
              className='cursor-pointer ml-2'
              aria-label='edit-bank'
            >
              <Hover
                hoverComponent={<div>Edit Account Information</div>}
                config={{ placement: 'top' }}
              >
                <SvgIcon component={Pencil} viewBox='0 0 22 22' />
              </Hover>
            </div>
          </div>

          <p className='font-zen-body text-zen-dark text-base font-normal'>
            {bank?.bankAddress?.oneLine}
          </p>
        </div>
      </div>
      <div className='flex flex-row space-x-5'>
        <p className='font-zen-body text-zen-dark text-base font-normal'>
          <span className='font-semibold'>Account Name: </span>
          {bank?.accountName}
        </p>
        <p className='font-zen-body text-zen-dark text-base font-normal'>
          <span className='font-semibold'>Routing No. </span>
          {bank?.bankRoutingNumber}
        </p>
        <p className='font-zen-body text-zen-dark text-base font-normal'>
          <span className='font-semibold'>ID: </span>
          {bank?.id}
        </p>
      </div>
      <div className='flex flex-row space-x-5'>
        <p className='font-zen-body text-zen-dark text-base font-normal'>
          <span className='font-semibold'>Account Number: </span>
          {bank?.accountNumber}
        </p>
        <p className='font-zen-body text-zen-dark text-base font-normal'>
          <span className='font-semibold'>Wire No. </span>
          {bank?.wireRoutingNumber || 'N/A'}
        </p>
        <p className='font-zen-body text-zen-dark text-base font-normal'>
          <span className='font-semibold'>FTNI ID: </span>
          {bank?.ftniSettlementId}
        </p>
      </div>
    </div>
  );
};

export default ZenBankDetailsCard;
