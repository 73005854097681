import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import ZenSidebarModalActionFooter from '../components/SidebarModal/ZenSideBarModalActionFooter';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenSidebarModal from '../components/Zen/ZenSidebarModal';
import { UUID_PLACEHOLDER } from '../constants/PlaceholderConstants';
import { TransactionControllerApi } from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';

interface ZenAddMoneyTransfersToClosedTransactionFormSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  transactionId: string;
}

const ZenAddMoneyTransfersToClosedTransactionFormSidebarModal: React.FC<ZenAddMoneyTransfersToClosedTransactionFormSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (values: FormData) => {
    try {
      await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).addMoneyTransfersPostClosingService(values.transactionId);

      onClose();
      dispatch(showSuccessToast('Added money transfers successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify(
        'Unable to add money transfers to a closed transaction',
        e,
        {
          data: {
            transactionId: values.transactionId,
          },
        },
      );
      dispatch(
        showErrorToast(
          'We had a problem adding money transfers to that transaction.',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <ZenSidebarModal
      title='Add Money Transfers to Closed Transaction'
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4'>
          <ZenControlledTextInput<FormData, 'transactionId'>
            label='Transaction ID'
            name='transactionId'
            control={control}
            placeholder={UUID_PLACEHOLDER}
            rules={{ required: 'Please provide a transaction ID' }}
            isRequired
          />
        </div>
        <ZenSidebarModalActionFooter
          submitButtonText='Add'
          onClose={onClose}
          submitButtonDisabled={isSubmitting}
          isSubmitting={isSubmitting}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenAddMoneyTransfersToClosedTransactionFormSidebarModal;
