import {
  PagedReferralResponse,
  ReferralCentralReferralDtoClientTypeEnum,
  ReferralCentralReferralDtoStatusEnum,
  ReferralCentralReferralDtoTimelineEnum,
  ReferralCentralReferralLocationDtoStateOrProvinceEnum,
  ReferralCentralReferralLocationDtoUnitOfMeasureEnum,
} from '../../../openapi/arrakis';

export const DefaultReferralAppliedPaginationResponse: Required<PagedReferralResponse> = {
  pageNumber: 0,
  pageSize: 10,
  hasNext: false,
  totalCount: 1,
  items: [
    {
      applicantAgentIds: [
        {
          agentId: '974c5489-8e8d-4a0e-bf2f-14ce073b1203',
          createdAt: 1629878400000,
          hidden: false,
          updatedAt: 1632566400000,
        },
      ],
      clientType: ReferralCentralReferralDtoClientTypeEnum.Buyer,
      createdAt: 1629878400000,
      expirationDate: '2021-10-25T00:00:00Z',
      id: 'ref12345',
      languages: ['English'],
      nickname: 'Sample Referral',
      notes: 'Sample Notes',
      priceRange: {
        maximum: {
          amount: 500000,
          currency: 'USD',
        },
        minimum: {
          amount: 100000,
          currency: 'USD',
        },
      },
      status: ReferralCentralReferralDtoStatusEnum.Sent,
      referralAgentId: '974c5489-8e8d-4a0e-bf2f-14ce073bas23',
      referralFeePercentage: 15,
      timeline: ReferralCentralReferralDtoTimelineEnum.From0To3Months,
      locations: [
        {
          city: 'San Francisco',
          coordinates: {
            latitude: 37.7749,
            longitude: -122.4194,
          },
          createdAt: 1629878400000,
          id: 'loc12345',
          radius: 10,
          stateOrProvince:
            ReferralCentralReferralLocationDtoStateOrProvinceEnum.California,
          unitOfMeasure:
            ReferralCentralReferralLocationDtoUnitOfMeasureEnum.Miles,
          updatedAt: 1632566400000,
          zipCode: '94103',
        },
        {
          city: 'Los Angeles',
          coordinates: {
            latitude: 34.0522,
            longitude: -118.2437,
          },
          createdAt: 1629878400000,
          id: 'loc24680',
          radius: 20,
          stateOrProvince:
            ReferralCentralReferralLocationDtoStateOrProvinceEnum.California,
          unitOfMeasure:
            ReferralCentralReferralLocationDtoUnitOfMeasureEnum.Miles,
          updatedAt: 1632566400000,
          zipCode: '90001',
        },
      ],
    },
  ],
};
