import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cn } from '../../../utils/classUtils';
import { Division } from '../../../openapi/yenta';
import { detachDivisionsFromAgent } from '../../../slices/AgentSlice';
import { showSuccessToast } from '../../../slices/ToastNotificationSlice';
import { RootState } from '../../../types';
import { SUPPORT_EMAIL_ADDRESS } from '../../../utils/SupportUtils';
import ResourceContainer from '../../ResourceContainer';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenAction from '../../Zen/ZenAction';
import ZenAdd from '../../Zen/ZenAdd';
import ZenCard from '../../Zen/ZenCard';
import AddDivisionToAgentForm from './AddDivisionToAgentForm';

interface AgentDivisionCardProps {}

const AgentDivisionCard: React.FC<AgentDivisionCardProps> = () => {
  const {
    agentDetail: {
      detailResponse: { data: agentDetailResponse },
    },
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const [supportModal, setSupportModal] = useState(false);
  const [addDivision, setAddDivision] = useState(false);
  const [detachDivision, setDetachDivision] = useState<Division>();
  const [isDeleting, setIsDeleting] = useState(false);

  const isSelf = agentDetailResponse?.id! === userDetail?.id;
  const agentDivisions = agentDetailResponse?.divisions;

  return (
    <>
      <ZenCard
        title='Divisions'
        RightComponent={
          isSelf ? (
            <ZenAction
              text='More Information'
              onClick={() => setSupportModal(true)}
            />
          ) : (
            <ZenAdd onClick={() => setAddDivision(true)} />
          )
        }
      >
        <ResourceContainer
          loading={!agentDetailResponse}
          isEmpty={!agentDivisions?.length}
          resourceName='Division'
          emptyIcon={
            <FontAwesomeIcon
              icon={solid('triangle-exclamation')}
              className='text-gray-400'
            />
          }
          emptyMessage='No division found.'
        >
          {agentDivisions?.map((division, index) => {
            const isLast = agentDivisions?.length === index + 1;

            return (
              <div key={division?.id} aria-label={division?.name}>
                <div
                  className={cn(
                    'px-4 py-3 justify-between flex-row flex items-center',
                    !isLast && 'border-b',
                  )}
                >
                  <div className='flex flex-row items-center space-x-2 justify-between'>
                    <img
                      className='object-contain w-5 h-5'
                      src={division?.logoUrl}
                      alt='division-icon'
                    />
                    <span className='font-zen-body font-normal text-base leading-5 text-zen-dark-7'>
                      {division?.name}
                    </span>
                    {!isSelf && (
                      <span className='text-xs font-zen-body text-zen-dark-8'>
                        Member
                      </span>
                    )}
                  </div>
                  <div className='ml-4'>
                    {isSelf ? (
                      <span className='font-zen-body leading-4 text-zen-dark-8 text-sm text-right font-bold'>
                        Member
                      </span>
                    ) : (
                      <ZenAction
                        text='Delete'
                        onClick={() => setDetachDivision(division)}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </ResourceContainer>
      </ZenCard>

      {!!agentDetailResponse && addDivision && (
        <AddDivisionToAgentForm
          isOpen={addDivision}
          onClose={() => setAddDivision(false)}
          agentId={agentDetailResponse?.id!}
        />
      )}

      {supportModal && (
        <ZenConfirmationModal
          isOpen={supportModal}
          onClose={() => setSupportModal(false)}
          size='large'
          title='More Information'
          subtitle={
            <p className='font-zen-body '>
              For more information or to apply for this division, please contact
              support at <br />
              <p className='text-primary-blue'>{SUPPORT_EMAIL_ADDRESS}</p>
            </p>
          }
          confirmButtonText='Copy Email ID'
          onConfirm={() => {
            navigator.clipboard.writeText(SUPPORT_EMAIL_ADDRESS);
            dispatch(showSuccessToast('Email ID copied to clipboard'));
            setSupportModal(false);
          }}
        />
      )}

      {!!detachDivision && (
        <ZenConfirmationModal
          isOpen
          onClose={() => setDetachDivision(undefined)}
          isSubmitting={isDeleting}
          isDisabled={isDeleting}
          variant='danger'
          size='large'
          title='Delete Division'
          subtitle={`Are you sure you want to delete ${detachDivision.name} Division?`}
          confirmButtonText='Delete'
          onConfirm={async () => {
            setIsDeleting(true);
            await dispatch(
              detachDivisionsFromAgent(
                agentDetailResponse?.id!,
                detachDivision?.id!,
              ),
            );
            setDetachDivision(undefined);
            setIsDeleting(false);
          }}
        />
      )}
    </>
  );
};

export default AgentDivisionCard;
