import ReactQuill from 'react-quill';
import * as Quill from 'quill';
import quillEmoji from 'quill-emoji';
import { ControllerProps } from 'react-hook-form-v7';
import { MentionBlock, TextBlock } from '../openapi/yada';
import { MentionSource } from '../components/transactions/Comments/ZenCommentSection';

type Validation = ControllerProps['rules'];

export const isRichTextInputEmpty = (
  reactQuillRef: React.RefObject<ReactQuill>,
) => {
  const ops = reactQuillRef.current?.getEditor().getContents()?.ops || [];
  const isEmojiPresent = (ops || []).find(
    (op: Quill.DeltaOperation) => op?.insert?.emoji,
  );
  const isMentionPresent = (ops || []).find(
    (op: Quill.DeltaOperation) => op?.insert?.mention,
  );

  if (ops.length > 0) {
    if (isEmojiPresent || isMentionPresent) {
      return false;
    }
    return !(ops[0]?.insert.trim() !== '');
  }
  return true;
};

export const geRichTexttMessage = (
  reactQuillRef: React.RefObject<ReactQuill>,
  getMentions: (searchTerm: string) => MentionSource[],
): [any, Quill.Delta] => {
  const contents = reactQuillRef.current?.getEditor().getContents()!;

  const message = (contents.ops || [])?.map((item) => {
    if (item?.insert?.mention) {
      const mentionId = item?.insert?.mention?.id;
      const agent = getMentions?.('').find((m) => m?.id === mentionId);
      return {
        type: 'MENTION',
        userId: mentionId,
        name: agent?.value,
      } as MentionBlock;
    }

    if (item?.insert?.emoji) {
      const code = quillEmoji.ShortNameEmoji.DEFAULTS.emojiList.find(
        (emoji: any) => emoji.name === item.insert.emoji,
      ).unicode;
      return {
        type: 'TEXT',
        text: String.fromCodePoint(parseInt(code, 16)),
      } as TextBlock;
    }

    if (item.attributes?.link) {
      return {
        type: 'HYPERLINK',
        text: item.insert,
        url: item.attributes.link,
      };
    }

    return { type: 'TEXT', text: item.insert } as TextBlock;
  });

  return [message, contents];
};

export const richTextInputValidation = (
  reactQuillRef: React.RefObject<ReactQuill>,
  getMentions: (searchTerm: string) => MentionSource[],
): Validation => {
  return {
    validate: () => {
      const [comment] = geRichTexttMessage(reactQuillRef, getMentions);
      const isMentionPresent = (comment || [])?.some(
        (c: any) => c?.type === 'MENTION',
      );
      const textComments: TextBlock[] = (comment || [])?.filter(
        (c: any) => c?.type === 'TEXT',
      );

      let atMentionCharLength = 0;
      for (let textComment of textComments) {
        atMentionCharLength += (textComment?.text?.match(/@/g) || [])?.length;
      }

      if (isRichTextInputEmpty(reactQuillRef)) {
        return 'Please provide the revision for Agent.';
      }

      if (!isMentionPresent) {
        if (atMentionCharLength < 1) {
          reactQuillRef.current
            ?.getEditor()
            ?.getModule('mention')
            .openMenu('@');
        }
        return '@ mention is required';
      }

      return undefined;
    },
  };
};
