import { groupBy, values } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '../../../assets/img/deleteIconDanger.svg';
import {
  AddressResponseStateOrProvinceEnum,
  AgentControllerApi,
  AgentUpdateLicenseBoardMlsRequest,
  LicenseRequest,
  MlsPreviewResponse,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { fetchAgentDetail } from '../../../slices/AgentSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { AppDispatch, ISelectOption, RootState } from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenControlledAsyncSelectInput from '../../Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledSelectInput from '../../Zen/Input/ZenControlledSelectInput';
import ZenSimpleConfirmationModal from '../../Zen/Modal/ZenSimpleConfirmationModal';
import ZenIconCircleWithTooltipCell from '../../Zen/Transaction/ZenIconCircleWithTooltipCell';
import ZenCardWithItems from '../../Zen/ZenCardWithItems';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import { DEFAULT_PAGE_SIZE } from '../../../constants/TableConstants';
import { fetchLiteMLSDetails } from '../../../slices/MLSSlice';
import GeminiFeatureFlagButton from '../../GeminiFeatureFlagButton';

interface ManageMLSFormProps {
  isOpen: boolean;
  onClose(): void;
  defaultStateOrProvince: AddressResponseStateOrProvinceEnum;
}

interface FormData {
  stateOrProvince?: ISelectOption;
  mlsIds: string[];
  mls: ISelectOption;
}

const ManageMLSForm: React.FC<ManageMLSFormProps> = ({
  isOpen,
  onClose,
  defaultStateOrProvince,
}) => {
  const agentDetailResponse = useSelector(
    (state: RootState) => state.agentDetail?.detailResponse?.data,
  );

  const stateMls = groupBy(
    agentDetailResponse?.mls!,
    'administrativeAreas[0].stateOrProvince',
  );

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      mlsIds: agentDetailResponse?.mls!.map((item) => item.id),
      stateOrProvince: defaultStateOrProvince
        ? {
            label: capitalizeEnum(defaultStateOrProvince),
            value: defaultStateOrProvince,
          }
        : undefined,
    },
  });

  const stateOrProvince = watch('stateOrProvince');
  const dispatch: AppDispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [deleteMLS, setDeleteMLS] = useState<MlsPreviewResponse | null>();
  const [isDeleting, setDeleting] = useState(false);

  const onSubmit = async (data: FormData) => {
    const mlsIds = [
      ...agentDetailResponse!.mls!.map!((item) => item.id!),
      data!.mls!.value,
    ];
    try {
      await new AgentControllerApi(
        getYentaConfiguration(),
      ).updateAgentMlsesById(agentDetailResponse?.id!, {
        mlsIds: mlsIds,
      });
      await dispatch(fetchAgentDetail(agentDetailResponse?.id!));
      onClose();
      dispatch(showSuccessToast('MLS updated successfully.'));
    } catch (e) {
      ErrorService.notify('Error updating MLS', e, {
        data: { ...data },
      });
      dispatch(showErrorToast('Error updating MLS'));
    }
  };

  const onDelete = async (data: string) => {
    setDeleting(true);
    const formValues: AgentUpdateLicenseBoardMlsRequest = {
      licenses: (agentDetailResponse!.licenses!.map((item) => {
        return {
          ...item,
          administrativeAreaRequest: item.administrativeArea,
        };
      }) as unknown) as LicenseRequest[],
      boardIds: agentDetailResponse!.boards!.map((item) => item.id!)!,
      mlsIds: [
        ...agentDetailResponse!
          .mls!.filter((item) => data !== item.id)
          .map((item) => item.id!),
      ],
    };

    try {
      await new AgentControllerApi(
        getYentaConfiguration(),
      ).updateAgentLicenseBoardMlsById(agentDetailResponse?.id!, formValues);
      await dispatch(fetchAgentDetail(agentDetailResponse?.id!));
      dispatch(showSuccessToast('MLS deleted successfully.'));
    } catch (e) {
      ErrorService.notify('Error updating MLS', e, {
        data: { data },
      });
      dispatch(showErrorToast('Error updating MLS'));
    } finally {
      setDeleting(false);
      setOpen(false);
      setDeleteMLS(null);
    }
  };

  return (
    <ZenSidebarModal title='Manage MLS' isOpen={isOpen} onClose={onClose}>
      <form
        className='flex flex-col justify-between min-h-full '
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4'>
          <div className='mb-4'>
            <ZenControlledAsyncSelectInput
              name='mls'
              control={control}
              fetchData={async (search, page) => {
                const res = await dispatch(
                  fetchLiteMLSDetails(page, DEFAULT_PAGE_SIZE, 'ASC', search),
                );
                return (res?.results || []).map((resp) => ({
                  value: `${resp?.id}`,
                  label: `${resp?.name}`,
                }));
              }}
              placeholder='Select an MLS'
              label='Add MLS'
              rules={{ required: 'Please select an MLS' }}
              isRequired
            />
          </div>
          <div>
            <div className='mt-4'>
              <ZenControlledSelectInput
                name='stateOrProvince'
                control={control}
                options={[
                  ...values(AddressResponseStateOrProvinceEnum).map(
                    (state) => ({
                      value: state,
                      label: capitalizeEnum(state),
                    }),
                  ),
                ]}
                label='State/Province'
              />
            </div>
            <div className='pt-4'>
              <p className='text-base font-primary-medium'>MLS</p>
              {stateOrProvince !== undefined &&
              !!stateMls[stateOrProvince.value] ? (
                <ZenCardWithItems
                  items={stateMls[stateOrProvince.value].map((item) => {
                    return {
                      name: item.name!,
                      rightActionComponent: (
                        <ZenIconCircleWithTooltipCell
                          tooltipText='Delete'
                          icon={
                            <img src={DeleteIcon} alt='delete' width={14} />
                          }
                          onClick={() => {
                            setDeleteMLS(item);
                            setOpen(true);
                          }}
                          iconVariant='error'
                        />
                      ),
                    };
                  })}
                />
              ) : (
                <div className='border rounded-lg p-8 justify-center self-center flex'>
                  <p className='font-zen-body text-gray-500 text-base'>
                    No MLS added yet
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='sticky bottom-0 p-4 bg-white border-t border-gray-200 space'>
          <div className='flex items-center space-x-3'>
            <div className='flex-1'>
              <GeminiFeatureFlagButton
                zenVariant='primary-outline'
                geminiVariant='primary-outline'
                onClick={() => onClose()}
                label='Cancel'
                isFullWidth
              />
            </div>
            <div className='flex-1'>
              <GeminiFeatureFlagButton
                type='submit'
                label='Save'
                isFullWidth
                isSubmitting={isSubmitting}
                isDisabled={isSubmitting}
              />
            </div>
          </div>
        </div>
        <ZenSimpleConfirmationModal
          isOpen={open}
          onClose={() => setOpen(false)}
          variant='danger'
          title='Delete MLS'
          subtitle={`Are you sure you want to delete ${deleteMLS?.name}`}
          isSubmitting={isDeleting}
          onConfirm={() => {
            onDelete(deleteMLS!.id!);
          }}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ManageMLSForm;
