import AgreementSent from '../../assets/img/agreement-sent.gif';
import GeminiButton from '../../components/Gemini/GeminiButton';

interface AgreementSentSuccessProps {
  onClose(): void;
}

const AgreementSentSuccess: React.FC<AgreementSentSuccessProps> = ({
  onClose,
}) => {
  return (
    <>
      <div className='flex items-center flex-col gap-4 h-full pt-24'>
        <img src={AgreementSent} alt='agreement-sent' className='w-60 mt-8' />
        <div className='text-primary-dark text-xl font-inter font-semibold'>
          Your Agreement was sent to Agentn
        </div>
        <div className='text-grey-600 w-[400px] font-inter font-normal text-center'>
          You can track the progress of your agreement within Real Signature
        </div>
      </div>
      <div className='sticky w-full bottom-0 right-0 left-0 bg-primary-light border-t flex'>
        <div className='ml-auto p-4 w-44'>
          <GeminiButton
            type='button'
            label='Done'
            variant='primary'
            onClick={onClose}
            isFullWidth
          />
        </div>
      </div>
    </>
  );
};

export default AgreementSentSuccess;
