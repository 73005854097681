import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import GeminiCreateMLSForm from '../components/mls/Gemini/GeminiCreateMLSForm';
import ZenCreateMLSForm from '../components/mls/Zen/ZenCreateMLSForm';
import ZenMLSResourceTable from '../components/Zen/mls/ZenMLSResourceTable';
import ZenButton from '../components/Zen/ZenButton';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenRoute from '../components/Zen/ZenRoute';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { FeatureFlagTypeEnum } from '../types';

const ZenMLSSearchRoute: React.FC = () => {
  const location = useLocation();
  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );
  const [openCreateMLSForm, setOpenCreateMLSForm] = useState<boolean>(false);

  const isBoardAndMlsImprovementsFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.BOARD_AND_MLS_IMPROVEMENTS,
  );
  const CreateMlsSidebarModal = isBoardAndMlsImprovementsFlagEnabled
    ? GeminiCreateMLSForm
    : ZenCreateMLSForm;

  return (
    <ZenRoute title='MLS'>
      <ZenPageLayoutWithSearch
        path={[
          { title: 'Home', url: '/' },
          { title: 'MLS', url: '/mls' },
        ]}
        search={search}
        onSearchChange={setSearch}
      >
        <ZenMLSResourceTable
          search={search}
          RightComponent={
            <ZenButton
              LeftIconComponent={
                <FontAwesomeIcon icon={faPlus} className='px-1' />
              }
              label='Create an MLS'
              variant='primary'
              onClick={() => {
                setOpenCreateMLSForm(true);
              }}
            />
          }
        />
        {openCreateMLSForm && (
          <CreateMlsSidebarModal
            isOpen
            onClose={() => setOpenCreateMLSForm(false)}
          />
        )}
      </ZenPageLayoutWithSearch>
    </ZenRoute>
  );
};

export default ZenMLSSearchRoute;
