import { useSelector } from 'react-redux';
import { PropsWithChildren } from 'react';
import { RootState } from '../../types';
import { AgentResponseAccountCountryEnum } from '../../openapi/yenta';
import { ProspectiveAgentInfoCountryEnum } from '../../openapi/plutus';
import useQueryParams from '../../hooks/useQueryParams';
import { selectIsWealthPlanReferral } from './wealthPlanSelectors';

export const useIsUSAgent = () => {
  const queryParams = useQueryParams<{
    accountCountry?: ProspectiveAgentInfoCountryEnum;
  }>();
  const {
    auth: { userDetail },
    isWealthPlanReferral,
    wealthManagement,
  } = useSelector((state: RootState) => ({
    ...state,
    isWealthPlanReferral: selectIsWealthPlanReferral(state),
  }));

  if (isWealthPlanReferral) {
    return (
      wealthManagement.referralInfo?.data?.country ===
      ProspectiveAgentInfoCountryEnum.UnitedStates
    );
  }
  if (wealthManagement.readonlyMode && queryParams.accountCountry) {
    return (
      queryParams.accountCountry ===
      ProspectiveAgentInfoCountryEnum.UnitedStates
    );
  }
  return (
    userDetail?.accountCountry === AgentResponseAccountCountryEnum.UnitedStates
  );
};

export const USOnly: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const isUSAgent = useIsUSAgent();

  if (!isUSAgent) {
    return null;
  }

  return children;
};
