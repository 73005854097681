import { useDispatch } from 'react-redux';
import {
  UpdatePersonalAccountRequest,
  UserControllerApi,
  UserPreviewResponse,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { AppDispatch } from '../../types';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { queryKeys } from '../base/queryKeys';
import { useBaseMutation } from '../base/useBaseMutation';
import { useSimpleQuery } from '../base/useSimpleQuery';

export const useFetchPersonalAccount = (
  agentId?: string,
  enabled: boolean = false,
) => {
  const getPersonalAccount = async () => {
    const { data } = await new UserControllerApi(
      getYentaConfiguration(),
    ).getPersonalAccount(agentId!);
    return data?.personalAccount!;
  };

  const queryResult = useSimpleQuery<UserPreviewResponse>({
    queryKey: queryKeys.agent.keymaker(agentId!).queryKey,
    queryFn: () => getPersonalAccount(),
    options: {
      toastErrorMessage: 'Unable to fetch personal account',
      logErrorMessage: 'Unable to fetch personal account',
      enabled: !!agentId && enabled,
      staleTime: 0,
    },
  });

  return queryResult;
};

export const useUpdatePersonalAccount = () => {
  const dispatch = useDispatch<AppDispatch>();
  const updatePersonalAccount = async (req: UpdatePersonalAccountRequest) => {
    await new UserControllerApi(getYentaConfiguration()).updatePersonalAccount(
      req,
    );
  };

  return useBaseMutation({
    queryKey: queryKeys.agent._def,
    mutationFn: updatePersonalAccount,
    successMessage: 'Personal account added successfully',
    onError: (e, variables) => {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to add personal account', e, {
        request: variables,
      });
      dispatch(
        showErrorToast(
          'We had a problem adding personal account.',
          'Please try again in a few moments.',
        ),
      );
    },
  });
};
