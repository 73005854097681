import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useFormContext } from 'react-hook-form-v7';
import { FieldObject, FieldObjectTypeEnum } from '../../../openapi/atlantis';
import { getTextMaskRegexFromDisplayType } from '../../../utils/mortgage/MaskUtils';
import {
  getDatePickerConfig,
  getInputValidations,
} from '../../../utils/mortgage/MortgageApplicationValidationsUtils';
import {
  EMAIL_VALIDATIONS,
  MONEY_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../utils/Validations';
import ZenControlledCurrencyInput from '../../Zen/Input/ZenControlledCurrencyInput';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledMaskTextInput from '../../Zen/Input/ZenControlledMaskTextInput';
import ZenControlledMultiSelectInput from '../../Zen/Input/ZenControlledMultiSelectInput';
import ZenControlledPhoneNumberInput from '../../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledSelectInput from '../../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenMortgageAddressInput from '../MortgageApplicationForms/ZenMortgageAddressInput';

interface MortgageApplicationFormInputProps {
  name: string;
  field: FieldObject;
  onSubmit: () => void;
  isReadOnly: boolean;
}

const MortgageApplicationFormInput: React.FC<MortgageApplicationFormInputProps> = ({
  name,
  field,
  onSubmit,
  isReadOnly,
}) => {
  const { control, setValue } = useFormContext();

  if (field.type === FieldObjectTypeEnum.Text) {
    const mask = getTextMaskRegexFromDisplayType(
      field.fieldStyle?.displayType!,
    );

    if (mask) {
      return (
        <ZenControlledMaskTextInput
          control={control}
          name={name}
          maskType={mask}
          label={field.label}
          setValue={setValue}
          //@ts-ignore
          placeholder={field.placeholder}
          onBlurSpy={onSubmit}
          rules={getInputValidations(field)}
          readOnly={isReadOnly}
        />
      );
    }

    return (
      <ZenControlledTextInput
        control={control}
        name={name}
        //@ts-ignore
        placeholder={field.placeholder}
        label={field.label}
        onBlurSpy={onSubmit}
        rules={getInputValidations(field)}
        readOnly={isReadOnly}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.Email) {
    return (
      <ZenControlledTextInput
        name={name}
        control={control}
        type='email'
        label={field.label}
        //@ts-ignore
        placeholder={field.placeholder}
        startAdornment={
          <div className='flex w-full h-full items-center justify-center pl-3'>
            <FontAwesomeIcon
              className='text-primary-blue text-sm'
              icon={regular('envelope')}
            />
          </div>
        }
        onBlurSpy={onSubmit}
        rules={{
          ...EMAIL_VALIDATIONS,
          ...getInputValidations(field),
        }}
        readOnly={isReadOnly}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.Number) {
    return (
      <ZenControlledTextInput
        name={name}
        control={control}
        type='number'
        label={field.label}
        //@ts-ignore
        placeholder={field.placeholder}
        onBlurSpy={onSubmit}
        rules={getInputValidations(field)}
        readOnly={isReadOnly}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.Phone) {
    return (
      <ZenControlledPhoneNumberInput
        name={name}
        control={control}
        label={field.label}
        //@ts-ignore
        placeholder={field.placeholder}
        onBlurSpy={onSubmit}
        disableDropdown
        rules={{
          ...PHONE_NUMBER_VALIDATIONS,
          ...getInputValidations(field),
        }}
        readOnly={isReadOnly}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.Date) {
    return (
      <ZenControlledDatePickerInput
        control={control}
        name={name}
        label={field.label}
        //@ts-ignore
        placeholder={field.placeholder}
        icon={
          <div className='flex w-full h-full items-center justify-center pl-3'>
            <FontAwesomeIcon
              className='text-primary-blue text-sm'
              icon={regular('calendar')}
            />
          </div>
        }
        onBlurSpy={onSubmit}
        datePickerConfig={getDatePickerConfig(field)}
        rules={getInputValidations(field)}
        readOnly={isReadOnly}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.SingleSelect) {
    return (
      <ZenControlledSelectInput
        control={control}
        name={name}
        label={field.label}
        //@ts-ignore
        placeholder={field.placeholder}
        //@ts-ignore
        options={(field.options || []).map((option) => ({
          label: option.label,
          value: option.value,
        }))}
        menuPosition='fixed'
        onChangeSpy={onSubmit}
        rules={getInputValidations(field)}
        readOnly={isReadOnly}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.MultiSelect) {
    return (
      <ZenControlledMultiSelectInput
        name={name}
        control={control}
        label={field.label}
        //@ts-ignore
        placeholder={field.placeholder}
        //@ts-ignore
        options={(field.options || []).map((option) => ({
          label: option.label,
          value: option.value,
        }))}
        onChangeSpy={onSubmit}
        shouldUnregister
        rules={getInputValidations(field)}
        readOnly={isReadOnly}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.Money) {
    return (
      <ZenControlledCurrencyInput
        control={control}
        name={name}
        label={field.label}
        //@ts-ignore
        placeholder={field.placeholder}
        startAdornment={
          <div className='flex w-full h-full items-center justify-center pl-3'>
            <FontAwesomeIcon
              className='text-primary-blue text-sm'
              icon={regular('dollar-sign')}
            />
          </div>
        }
        onBlurSpy={onSubmit}
        rules={{
          ...MONEY_VALIDATIONS,
          ...getInputValidations(field),
        }}
        readOnly={isReadOnly}
      />
    );
  }

  if (
    field.type === FieldObjectTypeEnum.Binary ||
    field.type === FieldObjectTypeEnum.Toggle
  ) {
    return (
      <ZenControlledSelectInput
        control={control}
        name={name}
        label={field.label}
        // @ts-ignore
        options={[field?.trueOption, field?.falseOption]}
        onChangeSpy={onSubmit}
        rules={getInputValidations(field)}
        readOnly={isReadOnly}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.Address) {
    return (
      <ZenMortgageAddressInput
        name={name}
        field={field}
        onSubmit={onSubmit}
        isReadOnly={isReadOnly}
      />
    );
  }

  return null;
};

export default MortgageApplicationFormInput;
