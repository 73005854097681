import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AgentStateOneOfStateOrProvincesEnum,
  AnnouncementDefRequestDriverEnum,
  AnnouncementDefRequestStatusEnum,
} from '../../../openapi/mercury';
import { AgentResponseAccountCountryEnum } from '../../../openapi/yenta';
import {
  createAnnouncement,
  fetchAnnouncementDefinitionById,
  fetchAnnouncementDefinitionMediaInfo,
  saveAnnouncementDefinitionDetail,
  saveAnnouncementDefinitionMediaInfo,
  updateAnnouncement,
} from '../../../slices/AnnouncementSlice';
import {
  AppDispatch,
  AsyncSelectOption,
  ISelectOption,
  RootState,
  YesNoType,
} from '../../../types';
import {
  ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION,
  ANNOUNCEMENT_LOCATION_TYPE,
  ANNOUNCEMENT_PLATFORM_TYPE,
  ANNOUNCEMENT_USER_ROLE,
  getAnnouncementDefaultValues,
  getCreateAnnouncementRequest,
} from '../../../utils/AnnouncementUtils';
import DefaultLoader from '../../DefaultLoader';
import StepByStepContainer, {
  StepConfig,
} from '../../StepByStep/StepByStepContainer';
import ZenSidebarModal from '../ZenSidebarModal';
import AnnouncementDetailsStep from './AnnouncementDetailsStep';
import AnnouncementRulesStep from './AnnouncementRulesStep';

interface CreateAnnouncementSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  announcementId?: string;
}

export interface CreateAnnouncementFormData {
  driver: AnnouncementDefRequestDriverEnum;
  status: AnnouncementDefRequestStatusEnum;
  maxDismissalCount: number;
  msgRepeatIntervalInHours: number;
  deliverAfter?: string;
  expiresAt?: string;
  msgTitle: string;
  msgDetails: string;
  msgHyperlinkTitle: string;
  msgHyperlink: string;
  banner?: string | File;
  video?: string | File;
  audio?: string | File;
  mandatoryActionRequired: boolean;
  announcementFor?: ISelectOption<ANNOUNCEMENT_AGENT_RECEPIENT_CONDITION>;
  userId?: AsyncSelectOption;
  allUserIds?: string[];
  announcementForJoinedBetween: {
    startDate?: string;
    endDate?: string;
  };
  selectSpecificRecipients: YesNoType;
  stateOrProvinces:
    | ISelectOption<
        AgentStateOneOfStateOrProvincesEnum | AgentResponseAccountCountryEnum
      >[]
    | undefined;
  locationType: ANNOUNCEMENT_LOCATION_TYPE;
  platformType: ANNOUNCEMENT_PLATFORM_TYPE;
  userRole: ANNOUNCEMENT_USER_ROLE;
  teamId?: AsyncSelectOption;
  allTeamIds?: string[];
}

export enum CreateAnnouncementStepName {
  DETAILS = 'DETAILS',
  RULES = 'RULES',
}

const steps: StepConfig<
  CreateAnnouncementFormData,
  CreateAnnouncementStepName
>[] = [
  {
    name: CreateAnnouncementStepName.DETAILS,
    Component: AnnouncementDetailsStep,
    hidePagination: true,
  },
  {
    name: CreateAnnouncementStepName.RULES,
    Component: AnnouncementRulesStep,
    hidePagination: true,
  },
];

const CreateAnnouncementSidebarModal: React.FC<CreateAnnouncementSidebarModalProps> = ({
  isOpen,
  onClose,
  announcementId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { announcementDefinitionDetail, loading } = useSelector(
    (state: RootState) => state.announcement,
  );

  const { signedMsgAudioUrl, signedMsgVideoUrl } =
    announcementDefinitionDetail || {};

  useEffect(() => {
    if (announcementId) {
      dispatch(fetchAnnouncementDefinitionById(announcementId!));
    }

    return () => {
      dispatch(saveAnnouncementDefinitionDetail(undefined));
    };
  }, [announcementId, dispatch]);

  useEffect(() => {
    if (announcementId && (signedMsgAudioUrl || signedMsgVideoUrl)) {
      dispatch(fetchAnnouncementDefinitionMediaInfo(announcementId));
    }

    return () => {
      dispatch(
        saveAnnouncementDefinitionMediaInfo({
          loading: false,
          name: 'announcement media info',
          error: null,
          data: undefined,
        }),
      );
    };
  }, [announcementId, dispatch, signedMsgAudioUrl, signedMsgVideoUrl]);

  const onSubmit = async (values: CreateAnnouncementFormData) => {
    const req = getCreateAnnouncementRequest(values);
    let success = false;
    if (announcementId) {
      success = await dispatch(updateAnnouncement(announcementId, req));
    } else {
      success = await dispatch(createAnnouncement(req));
    }
    if (!success) {
      return;
    }
    onClose();
  };

  const defaultValues = getAnnouncementDefaultValues(
    announcementDefinitionDetail,
  );

  return (
    <ZenSidebarModal title='Announcement' isOpen={isOpen} onClose={onClose}>
      {loading ? (
        <DefaultLoader />
      ) : (
        <StepByStepContainer<
          CreateAnnouncementFormData,
          CreateAnnouncementStepName
        >
          steps={steps}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          reValidateMode='onChange'
          mode='onChange'
        />
      )}
    </ZenSidebarModal>
  );
};

export default CreateAnnouncementSidebarModal;
