import qs from 'qs';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import AmplitudeEventOnLoad from '../components/Amplitude/AmplitudeEventOnLoad';
import InstantPaymentsSplashModal from '../components/instantPayments/InstantPaymentsSplashModal';
import InstantPaymentsTermsAndConditionsModal from '../components/instantPayments/InstantPaymentsTermsAndConditionsModal';
import PageLayout from '../components/PageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import InstantPaymentResourceContainer from '../containers/InstantPaymentResourceContainer';
import { AmplitudeEvent } from '../services/AmplitudeService';
import { RootState } from '../types';

const InstantPaymentsRoute: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [
    isTermsAndConditionOpen,
    setIsTermsAndConditionOpen,
  ] = useState<boolean>(false);
  const {
    auth: { userDetail },
    instantPayment: {
      instantPaymentsSummaryResponse: { data: instantPaymentsSummary },
    },
  } = useSelector((state: RootState) => state);
  const { fromTransactionId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const transactionSummary = instantPaymentsSummary?.transactionSummaries?.find(
    (t) => t.transaction?.id === fromTransactionId,
  );

  const handleOnClose = () => {
    setIsTermsAndConditionOpen(false);
  };
  const handleRedirect = () => {
    setIsTermsAndConditionOpen(false);
    history.push('/transactions');
  };

  return (
    <ZenRoute title='Instant Payments'>
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Instant Payments', url: '/instant-payments' },
        ]}
      >
        <InstantPaymentResourceContainer agentId={userDetail?.id!} />
        <InstantPaymentsSplashModal
          isOpen={
            !!instantPaymentsSummary &&
            !instantPaymentsSummary.signedTsAndCs &&
            !isTermsAndConditionOpen
          }
          onClose={handleOnClose}
          onDecline={handleRedirect}
          onNext={() => {
            setIsTermsAndConditionOpen(true);
          }}
          transaction={transactionSummary?.transaction}
        />
        <InstantPaymentsTermsAndConditionsModal
          agentId={userDetail?.id!}
          isOpen={isTermsAndConditionOpen}
          onClose={handleOnClose}
          onDecline={handleRedirect}
          transaction={transactionSummary?.transaction}
        />
        <AmplitudeEventOnLoad
          eventName={AmplitudeEvent.INSTANT_PAYMENTS_INDEX_PAGE_VIEW}
        />
      </PageLayout>
    </ZenRoute>
  );
};
export default InstantPaymentsRoute;
