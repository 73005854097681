import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TransactionResponseTransactionTypeEnum } from '../../../../openapi/arrakis';
import { RootState } from '../../../../types';
import { displayAmount } from '../../../../utils/CurrencyUtils';
import {
  formatReferralTransactionAddress,
  isReferralTransactionAddressRequired,
} from '../../../../utils/ReferralTransactionHelper';
import { ReactComponent as EditIcon } from '../../../../assets/img/editPencil.svg';
import { capitalizeEnum } from '../../../../utils/StringUtils';
import { getTransactionPriceLabel } from '../../../../utils/TransactionHelper';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenAgentWebsiteConfirmationModal from '../../Modal/ZenAgentWebsiteConfirmationModal';
import ZenSimpleConfirmationModal from '../../Modal/ZenSimpleConfirmationModal';
import ZenButton from '../../ZenButton';
import ZenStickyBottomPagination from '../../ZenStickyBottomPagination';
import withZenCreateReferralTransactionProgress from './withZenCreateReferralTransactionProgress';
import {
  ReferralTransactionFormState,
  ReferralTransactionStepName,
} from './ZenReferralTransactionSteps';

const ZenReferralTransactionReview: StepByStepComponent<
  ReferralTransactionFormState,
  ReferralTransactionStepName
> = ({
  navigateTo,
  form: {
    watch,
    formState: { isSubmitting },
  },
  onSubmit,
}) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [createTransaction, setCreateTransaction] = useState(false);
  const [cancel, setCancel] = useState<boolean>(false);
  const values = watch();
  const history = useHistory();
  const isAddressRequired = isReferralTransactionAddressRequired(
    userDetail!,
    values?.address!,
  );

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div>
          <h4 className='font-zen-body font-semibold mb-2 text-2xl text-zen-dark-9'>
            Review referral transaction information
          </h4>

          {/* STEP ONE */}
          <div className='py-2 flex flex-row items-center border-b justify-between border-coolGray-300'>
            <div className='flex flex-row items-center font-zen-body'>
              <div className='w-7 h-7 shrink-0 flex justify-center items-center rounded-full bg-gray-200'>
                <span className='print-review-counter' />
              </div>

              <div className='py-2 grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-4 mx-5 border-coolGray-100'>
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>
                    External Agent Full Name:
                  </p>
                  <p className='leading-5 break-words'>
                    {values.externalAgentFirstName}{' '}
                    {values.externalAgentLastName}
                  </p>
                </div>
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>
                    External Agent Email:
                  </p>
                  <p className='leading-5 break-words'>
                    {values.externalAgentEmailAddress}
                  </p>
                </div>
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>
                    External Agent Brokerage
                  </p>
                  <p className='leading-5 break-words'>
                    {values.externalAgentBrokerage}
                  </p>
                </div>
              </div>
            </div>

            <ZenButton
              label='Edit'
              variant='primary-link'
              LeftIconComponent={<EditIcon className='text-primary-blue' />}
              onClick={() =>
                navigateTo(
                  ReferralTransactionStepName.EXTERNAL_AGENT_INFORMATION_STEP,
                )
              }
            />
          </div>

          {/* STEP TWO */}
          <div className='py-2 flex flex-row items-center border-b justify-between border-coolGray-300'>
            <div className='flex flex-row items-center font-zen-body'>
              <div className='w-7 h-7 shrink-0 rounded-full bg-gray-200 flex justify-center items-center'>
                <span className='print-review-counter' />
              </div>
              <div className='py-2 grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-4 mx-5 border-coolGray-100'>
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>Client Full Name:</p>
                  <p className='leading-5 break-words'>
                    {values.clientFirstName} {values.clientLastName}
                  </p>
                </div>
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>Client Email:</p>
                  <p className='leading-5 break-words'>
                    {values.clientEmailAddress || 'Not Provided'}
                  </p>
                </div>
              </div>
            </div>

            <ZenButton
              label='Edit'
              variant='primary-link'
              LeftIconComponent={<EditIcon className='text-primary-blue' />}
              onClick={() =>
                navigateTo(ReferralTransactionStepName.CLIENT_INFORMATION)
              }
            />
          </div>

          {/* STEP THREE */}
          <div className='py-2 flex flex-row items-center border-b justify-between border-coolGray-300'>
            <div className='flex flex-row items-center font-zen-body'>
              <div className='w-7 h-7 shrink-0 rounded-full bg-gray-200 flex justify-center items-center'>
                <span className='print-review-counter' />
              </div>
              <div className='py-2 grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-4 mx-5 border-coolGray-100'>
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>
                    {getTransactionPriceLabel(
                      TransactionResponseTransactionTypeEnum.Referral,
                    )}{' '}
                    ({userDetail?.defaultCurrency}):
                  </p>
                  <p className='leading-5'>
                    {values?.price?.amount
                      ? displayAmount(
                          {
                            amount: +values?.price?.amount!,
                            currency: values?.price?.currency,
                          },
                          { hideCurrency: true },
                        )
                      : '--'}
                  </p>
                </div>
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>
                    Expected Closing Date:
                  </p>
                  <p className='leading-5'>
                    {values.expectedClosingDate || 'Not Provided'}
                  </p>
                </div>
              </div>
            </div>

            <ZenButton
              label='Edit'
              variant='primary-link'
              LeftIconComponent={<EditIcon className='text-primary-blue' />}
              onClick={() =>
                navigateTo(ReferralTransactionStepName.DEAL_INFORMATION)
              }
            />
          </div>

          {/* STEP FOUR */}
          {values.isTeamLeadOrAdmin && (
            <div className='py-2 flex flex-row items-center border-b justify-between border-coolGray-300'>
              <div className='flex flex-row items-center font-zen-body'>
                <div className='w-7 h-7 shrink-0 rounded-full bg-gray-200 flex justify-center items-center'>
                  <span className='print-review-counter' />
                </div>
                <div className='py-2 grid grid-flow-row grid-cols-1 gap-4 mx-5 border-coolGray-100'>
                  <div className='px-3'>
                    <p className='leading-5 text-gray-500'>
                      Transaction Owner:
                    </p>
                    <p className='leading-5'>
                      {values.transactionOwnerName?.label}
                    </p>
                  </div>
                </div>
              </div>

              <ZenButton
                label='Edit'
                variant='primary-link'
                LeftIconComponent={<EditIcon className='text-primary-blue' />}
                onClick={() =>
                  navigateTo(ReferralTransactionStepName.OWNER_STEP)
                }
              />
            </div>
          )}

          {/* STEP FIVE */}
          <div className='py-2 flex flex-row items-center border-b justify-between border-coolGray-300'>
            <div className='flex flex-row items-center font-zen-body'>
              <div className='w-7 h-7 shrink-0 rounded-full bg-gray-200 flex justify-center items-center'>
                <span className='print-review-counter' />
              </div>
              <div className='py-2 grid grid-flow-row grid-cols-2 gap-4 mx-5 border-coolGray-100'>
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>Address:</p>
                  <p className='leading-5'>
                    {isAddressRequired
                      ? formatReferralTransactionAddress(values?.address!)
                      : 'Not provided'}
                  </p>
                </div>
              </div>
            </div>

            <ZenButton
              label='Edit'
              variant='primary-link'
              LeftIconComponent={<EditIcon className='text-primary-blue' />}
              onClick={() => navigateTo(ReferralTransactionStepName.ADDRESS)}
            />
          </div>

          {/* STEP SIX */}
          <div className='py-2 flex flex-row items-center border-b justify-between border-coolGray-300'>
            <div className='flex flex-row items-center font-zen-body'>
              <div className='w-7 h-7 shrink-0 rounded-full bg-gray-200 flex justify-center items-center'>
                <span className='print-review-counter' />
              </div>
              <div className='py-2 grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-4 mx-5 border-coolGray-100'>
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>Payment Date Sent:</p>
                  <p className='leading-5'>
                    {values.paymentDateSent || 'Not provided'}
                  </p>
                </div>
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>Payment Method:</p>
                  <p className='leading-5'>
                    {values.paymentMethod
                      ? capitalizeEnum(values.paymentMethod)
                      : 'Not provided'}
                  </p>
                </div>
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>Reference Number:</p>
                  <p className='leading-5'>
                    {values.referenceNumber || 'Not provided'}
                  </p>
                </div>

                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>Sender Full Name:</p>
                  <p className='leading-5'>
                    {values.senderFullName || 'Not provided'}
                  </p>
                </div>

                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>Comments:</p>
                  <p className='leading-5'>
                    {values.comments || 'no comments'}
                  </p>
                </div>
              </div>
            </div>

            <ZenButton
              label='Edit'
              variant='primary-link'
              LeftIconComponent={<EditIcon className='text-primary-blue' />}
              onClick={() =>
                navigateTo(ReferralTransactionStepName.PAYMENT_INFORMATION)
              }
            />
          </div>
        </div>
      </div>

      <ZenStickyBottomPagination
        maxWidth='2xl'
        onNext={() => setCreateTransaction(true)}
        onPrevious={() => setCancel(true)}
        previousButtonText='Cancel'
        nextButtonText='Create Referral'
      />

      <ZenSimpleConfirmationModal
        variant='info'
        isOpen={createTransaction}
        isSubmitting={isSubmitting}
        onClose={() => setCreateTransaction(false)}
        onConfirm={async () => {
          await onSubmit();
          setCreateTransaction(false);
        }}
        confirmButtonText='Create'
        title='Create Referral Transaction'
        subtitle='You will be able to complete and update any information once the referral is created'
      />

      <ZenAgentWebsiteConfirmationModal
        variant='cancel'
        title='Cancel referral transaction?'
        subtitle='The information won’t be saved and you’ll go back to the the transaction list'
        isOpen={cancel}
        onClose={() => setCancel(false)}
      >
        <div className='flex flex-row justify-between space-x-5 mt-6'>
          <ZenButton
            label='No'
            variant='secondary-gray-outline'
            type='button'
            isFullWidth
            onClick={() => setCancel(false)}
          />

          <ZenButton
            label='Cancel'
            variant='danger'
            isFullWidth
            onClick={() => history.push('/transactions')}
          />
        </div>
      </ZenAgentWebsiteConfirmationModal>
    </div>
  );
};

export default withZenCreateReferralTransactionProgress(
  ZenReferralTransactionReview,
);
