import { SvgIcon } from '@material-ui/core';
import { DateTime } from 'luxon';
import { ReactComponent as LeftBracket } from '../assets/icons/left-bracket.svg';
import { ReactComponent as RightBracket } from '../assets/icons/right-bracket.svg';

export interface SlimDocumentTitleProps {
  title: string;
  revision?: number;
  createdAt?: number;
  isPdf?: boolean;
}

const SlimDocumentTitle: React.FC<SlimDocumentTitleProps> = ({
  title,
  createdAt,
  revision,
  isPdf,
}) => {
  const styledTitle = title.replace(/ /, '&').split('&').join('\n');
  return (
    <div className='bg-mineshaft flex w-full flex-col px-8'>
      <div className='flex flex-row justify-center'>
        <SvgIcon
          component={LeftBracket}
          className='text-mineshaft mt-2 ml-3 md:ml-0'
          viewBox='0 0 20 20'
        />
        <pre className='text-white font-primary-regular text-center md:text-2xl print:text-lg my-3 whitespace-nowrap self-center'>
          {styledTitle}
        </pre>
        <SvgIcon
          component={RightBracket}
          className='text-mineshaft mt-14 ml-4'
          viewBox='0 0 20 20'
        />
      </div>
      {revision && createdAt && (
        <p className='text-center mb-2 print:text-xs text-white'>
          Revision {revision} |{' '}
          {isPdf
            ? DateTime.fromMillis(createdAt!)
                .setZone('America/New_York')
                .toFormat('MMM d, yyyy | hh:mma ZZZZ')
            : DateTime.fromMillis(createdAt).toFormat('MMM d, yyyy - HH:mm ')}
        </p>
      )}
    </div>
  );
};

export default SlimDocumentTitle;
