import { DateTime } from 'luxon';
import config from '../config';

interface MainFooterProps {}

const MainFooter: React.FC<MainFooterProps> = () => {
  return (
    <div
      className='border-t h-12 px-3 grid grid-cols-3 items-center text-xs mt-0.5'
      title='main-footer'
    >
      <p className='font-zen-title font-normal text-xs text-zen-gray-5'>
        {`reZEN (build ${config?.datadog?.deployVersion})`}
      </p>
      <p className='font-zen-title text-xs text-dark text-center'>
        {`Copyright © ${DateTime.local().toFormat('y')} Real Broker`}
      </p>
      <p />
    </div>
  );
};

export default MainFooter;
