import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import ResourceContainer from '../components/ResourceContainer';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../components/Zen/ZenSidebarModalForm';
import { UserControllerApi } from '../openapi/keymaker';
import { AgentControllerApi } from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { fetchIdentity } from '../slices/AuthSlice';
import {
  showErrorToast,
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { getForceMFAStatusMessage } from '../utils/AgentHelper';
import {
  getKeymakerConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { ModifiedIdentitySummaryResponse } from './ZenRemoveForceMFAFormSidebarModal';

interface ZenAddForceMFAFormSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  agentId: string;
}

const ZenAddForceMFAFormSidebarModal: React.FC<ZenAddForceMFAFormSidebarModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [
    keyMakerAgentDetail,
    setKeyMakerAgentDetail,
  ] = useState<ModifiedIdentitySummaryResponse>();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<FormData>();

  const agentId = watch('agentId');

  const mfaMessage = getForceMFAStatusMessage(
    !!keyMakerAgentDetail?.forceMfa,
    keyMakerAgentDetail?.fullName || '',
  );

  const fetchAgentInfo = async (id: string) => {
    setLoading(true);
    try {
      const { data } = await new AgentControllerApi(
        getYentaConfiguration(),
      ).getAgentById(id);

      if (data?.keyMakerId) {
        const identityData = await dispatch(fetchIdentity(data?.keyMakerId!));
        setKeyMakerAgentDetail({
          ...identityData,
          fullName: data?.fullName || '',
        });
      }
    } catch (e) {
      ErrorService.notify('Unable to fetch agent detail', e, { agent: { id } });
      dispatch(
        showErrorToast(
          'We had a problem fetching agent details.',
          'Please try again in a few moments.',
        ),
      );
      setKeyMakerAgentDetail(undefined);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceAgentInfo = useCallback(debounce(fetchAgentInfo, 1000), []);

  useEffect(() => {
    if (agentId) {
      debounceAgentInfo(agentId);
    }

    return () => {
      setKeyMakerAgentDetail(undefined);
    };
  }, [agentId, debounceAgentInfo]);

  const handleForceMFA = async () => {
    try {
      await new UserControllerApi(getKeymakerConfiguration()).forceMfa(
        keyMakerAgentDetail?.id!,
      );
      dispatch(showSuccessToast('Force MFA enabled successfully'));
      onClose();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to enable force MFA', e, {
        agent: { keymakerId: keyMakerAgentDetail?.id },
      });
      dispatch(
        showErrorToastForErrorCode(
          'We had a problem enabling force MFA.',
          ErrorService.getErrorCode(e),
        ),
      );
    }
  };

  return (
    <ZenSidebarModalForm
      title='Add Force MFA'
      isOpen={isOpen}
      onClose={onClose}
      actionTitle='Save'
      onSubmit={handleSubmit(handleForceMFA)}
      isSubmitting={isSubmitting}
      isDisabled={
        isSubmitting || !keyMakerAgentDetail || !!keyMakerAgentDetail?.forceMfa
      }
    >
      <div>
        <div className='mb-5'>
          <ZenControlledTextInput<FormData, 'agentId'>
            label='Agent ID'
            name='agentId'
            control={control}
            placeholder='12345678-1111-2222-3333-12345678'
            rules={{ required: 'Agent ID is required' }}
            isRequired
          />

          <ResourceContainer
            isEmpty={false}
            loading={loading}
            resourceName='add-agent-force-mfa'
          >
            {!!keyMakerAgentDetail && (
              <p className='font-primary-regular text-sm text-zen-dark-9 my-2'>
                {mfaMessage}
              </p>
            )}
          </ResourceContainer>
        </div>
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenAddForceMFAFormSidebarModal;
