import React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Control } from 'react-hook-form-v7';
import { MONEY_VALIDATIONS } from '../../../utils/Validations';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import { EditTransactionFormData } from './ZenEditTransactionParticipantFormSidebarModal';

interface ZenEditProTeamFeesFormProps {
  control: Control<EditTransactionFormData, object>;
  readOnly?: boolean;
}

const ZenEditProTeamFeesForm: React.FC<ZenEditProTeamFeesFormProps> = ({
  control,
  readOnly = false,
}) => {
  return (
    <div>
      <p className='text-lg font-zen-body font-medium text-zen-dark-9'>
        Team Fees
      </p>
      <div className='mt-4'>
        <div className='space-y-4'>
          <div className='font-normal font-primary-regular text-base text-zen-dark-9'>
            <ZenControlledTextInput<EditTransactionFormData, 'teamPreCapFee'>
              control={control}
              label='Team Pre Cap Fee'
              name='teamPreCapFee'
              shouldUnregister={false}
              placeholder='Team Pre Cap Fee'
              readOnly={readOnly}
              startAdornment={
                <div className='flex w-full h-full items-center justify-center pl-2'>
                  <FontAwesomeIcon
                    className='text-primary-blue'
                    icon={solid('dollar')}
                  />
                </div>
              }
              isRequired={!readOnly}
              rules={{
                ...MONEY_VALIDATIONS,
                required: !readOnly
                  ? 'Please enter a team pre cap fee amount'
                  : undefined,
              }}
            />
          </div>

          <div className='font-normal font-primary-regular text-base text-zen-dark-9'>
            <ZenControlledTextInput<EditTransactionFormData, 'teamPostCapFee'>
              control={control}
              label='Team Post Cap Fee'
              name='teamPostCapFee'
              shouldUnregister={false}
              placeholder='Team Post Cap Fee'
              readOnly={readOnly}
              startAdornment={
                <div className='flex w-full h-full items-center justify-center pl-2'>
                  <FontAwesomeIcon
                    className='text-primary-blue'
                    icon={solid('dollar')}
                  />
                </div>
              }
              isRequired={!readOnly}
              rules={{
                ...MONEY_VALIDATIONS,
                required: !readOnly
                  ? 'Please enter a team post cap fee amount'
                  : undefined,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenEditProTeamFeesForm;
