import classNames from 'classnames';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import ZenFormErrorMessage from './ZenFormErrorMessage';

interface ZenControlledSquareToggleInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  readOnly?: boolean;
  hideErrorMessage?: boolean;
  onChangeSpy?: (value: boolean) => void;
}

const ZenControlledSquareToggleInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  readOnly = false,
  hideErrorMessage = false,
  onChangeSpy,
  ...rest
}: ZenControlledSquareToggleInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      {...rest}
      render={({
        field: { value, onChange, name },
        fieldState: { error, invalid },
      }) => {
        return (
          <div className='space-y-1 w-full'>
            <div className='flex flex-row items-center justify-between'>
              {!!label && (
                <label
                  htmlFor={name}
                  className={classNames(
                    'font-zen-body font-semibold text-zen-dark-9 mr-2',
                    invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                  )}
                >
                  {label}
                </label>
              )}
              <div className='flex flex-row py-1 items-center font-zen-body font-normal text-base space-x-3'>
                <div className='flex flex-row space-x-3 cursor-pointer items-center'>
                  <button
                    type='button'
                    className='relative inline-flex flex-shrink-0 h-9 w-[87px] border-2 border-transparent rounded-[10px] cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none bg-zen-dark-4'
                    onClick={() => {
                      onChange(!value);
                      onChangeSpy?.(!value);
                    }}
                    disabled={readOnly}
                    aria-label='square-toggle-switch'
                  >
                    <span className='text-sm absolute font-bold text-white left-2.5 top-1.5'>
                      Yes
                    </span>
                    <span className='text-sm absolute font-bold text-white top-1.5 right-2.5'>
                      No
                    </span>
                    <span
                      className={classNames(
                        'translate-x-0 pointer-events-none relative inline-block h-8 self-center rounded-lg bg-white shadow transform ring-0 transition ease-in-out duration-200',
                        value
                          ? 'translate-x-0 w-11'
                          : 'translate-x-[43px] w-10',
                      )}
                    >
                      <span
                        aria-label='square-toggle-value'
                        className='text-sm absolute inset-x-px top-1.5 text-primary-blue font-bold'
                      >
                        {value ? 'Yes' : 'No'}
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            {error && !hideErrorMessage && (
              <ZenFormErrorMessage message={error.message} />
            )}
          </div>
        );
      }}
    />
  );
};

export default ZenControlledSquareToggleInput;
