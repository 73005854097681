import React from 'react';
import { useSelector } from 'react-redux';
import ZenRoute from '../../components/Zen/ZenRoute';
import { RootState } from '../../types';
import ZenAgentDetailPaymentsComponent from './ZenAgentDetailPaymentsComponent';

interface ZenAgentDetailPaymentsRouteProps {
  agentId: string;
}

const ZenAgentDetailPaymentsRoute: React.FC<ZenAgentDetailPaymentsRouteProps> = ({
  agentId,
}) => {
  const { detailResponse } = useSelector(
    (state: RootState) => state.agentDetail,
  );

  return (
    <ZenRoute title={`Payment Invoices - ${detailResponse.data?.fullName}`}>
      <div className='px-0 md:px-4 py-4'>
        <ZenAgentDetailPaymentsComponent
          hiddenColumns={['transactionCode']}
          agentId={agentId}
        />
      </div>
    </ZenRoute>
  );
};
export default ZenAgentDetailPaymentsRoute;
