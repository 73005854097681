import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import DefaultLoader from '../components/DefaultLoader';
import PageLayout from '../components/PageLayout';
import ResourceContainer from '../components/ResourceContainer';
import MilliDateCell from '../components/table/Cells/MilliDateCell';
import ZenBankDetailsCard from '../components/Zen/Bank/ZenBankDetailsCard';
import ZenReconcilePendingTransactionSidebarModal from '../components/Zen/Bank/ZenReconcilePendingTransactionSidebarModal';
import ZenReconcileTransactionSidebarModal from '../components/Zen/Bank/ZenReconcileTransactionSidebarModal';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenRouterTabs, { ZenTab } from '../components/Zen/Tab/ZenRouterTabs';
import ZenButton from '../components/Zen/ZenButton';
import ZenHeaderWithBackButton from '../components/Zen/ZenHeaderWithBackButton';
import ZenRoute from '../components/Zen/ZenRoute';
import CreateBankAccountSidebarModal from '../forms/BankAccountSidebarModal';
import { BankActivityControllerApi, BankActivityDto } from '../openapi/arrakis';
import { BankAccountResponse } from '../openapi/yenta';
import {
  changeLoadingDetail,
  fetchBankDetailsById,
  fetchReconciledCount,
  updatePendingCount,
  updateReconciledCount,
  uploadBankStatement,
} from '../slices/BankSlice';
import { RootState } from '../types';
import { displayAmount } from '../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';

interface Params {
  id: string;
}

const ReconcileTransactions: React.FC = () => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    bankDetail,
    loadingDetail,
    pendingCount,
    reconciledCount,
  } = useSelector((state: RootState) => state.bank);
  const inputFileRef = createRef<HTMLInputElement>();
  const [isOpenPendingSidebar, setIsOpenPendingSidebar] = useState(false);
  const [isOpenReconciledSidebar, setIsOpenReconciledSidebar] = useState(false);
  const [
    selectedTransaction,
    setSelectedTransaction,
  ] = useState<null | BankActivityDto>(null);
  const [isEditing, setEditing] = useState<BankAccountResponse>();
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const fetchData = useCallback(
    async (loading: boolean = true) => {
      if (loading) {
        setIsFetching(true);
      }
      await Promise.all([
        dispatch(fetchBankDetailsById(id)),
        dispatch(fetchReconciledCount(id)),
      ]);
      if (loading) {
        setIsFetching(false);
      }
    },
    [dispatch, id],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRowClick = (transaction: any) => {
    const path = history.location.pathname;
    if (path === `/bank-accounts/${id}/reconcile-transactions`) {
      handlePendingSelectedTransaction(transaction);
    } else {
      handleReconciledSelectedTransaction(transaction);
    }
  };

  const handlePendingSelectedTransaction = (transaction?: any) => {
    setSelectedTransaction(transaction ?? null);
    setIsOpenPendingSidebar(!!transaction);
  };

  const handleReconciledSelectedTransaction = (transaction?: any) => {
    setSelectedTransaction(transaction ?? null);
    setIsOpenReconciledSidebar(!!transaction);
  };

  const columns: Array<Column<BankActivityDto>> = [
    {
      Header: 'Activity Date',
      accessor: 'date',
      Cell: ({ value, row: { original: transaction } }) => (
        <p
          className='cursor-pointer font-zen-body font-normal'
          onClick={() => handleRowClick(transaction)}
        >
          {value}
        </p>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Bank Name / ID',
      accessor: 'bank',
      Cell: ({ value, row: { original: transaction } }) => (
        <div
          className='flex flex-col cursor-pointer font-zen-body font-normal'
          onClick={() => handleRowClick(transaction)}
        >
          <p>{value}</p>
          <p className='text-zen-gray-4'>{transaction.id}</p>
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ value, row: { original: transaction } }) => (
        <div
          className='cursor-pointer font-zen-body font-normal'
          onClick={() => handleRowClick(transaction)}
        >
          <p>{value}</p>
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value, row: { original: transaction } }) => (
        <p
          className='cursor-pointer font-zen-body font-normal'
          onClick={() => handleRowClick(transaction)}
        >
          {displayAmount(value)}
        </p>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Uploaded Date',
      accessor: 'source',
      Cell: ({ value, row: { original: transaction } }) => (
        <p
          className='cursor-pointer font-zen-body font-normal'
          onClick={() => handleRowClick(transaction)}
        >
          <MilliDateCell date={value?.uploadedAt} />
        </p>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Matches',
      accessor: 'possibleMatches',
      Cell: ({ value, row: { original: transaction } }) => (
        <p
          className='cursor-pointer font-zen-body font-normal'
          onClick={() => handleRowClick(transaction)}
        >
          {value?.length}
        </p>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const tabs: ZenTab[] = useMemo(() => {
    return [
      {
        label: `Pending (${pendingCount})`,
        path: `/bank-accounts/${id}/reconcile-transactions`,
        TabComponent: () => (
          <div className='pt-4'>
            <ZenResourceIndexContainer<BankActivityDto>
              columns={columns}
              fetchData={async (req) => {
                const { data } = await new BankActivityControllerApi(
                  getArrakisConfiguration(),
                ).getActivities(id, 'PENDING', '', '', req.page, req.pageSize);

                dispatch(updatePendingCount(data.totalCount!));
                return {
                  data: data.activities!,
                  total: data.totalCount!,
                };
              }}
              resourceName='reconciled transaction'
              hideFilters
            />
          </div>
        ),
      },
      {
        label: `Reconciled (${reconciledCount})`,
        path: `/bank-accounts/${id}/reconcile-transactions/reconciled`,
        TabComponent: () => (
          <div className='pt-4'>
            <ZenResourceIndexContainer<BankActivityDto>
              columns={columns}
              fetchData={async (req) => {
                const { data } = await new BankActivityControllerApi(
                  getArrakisConfiguration(),
                ).getActivities(
                  id,
                  'RECONCILED',
                  '',
                  '',
                  req.page,
                  req.pageSize,
                );

                dispatch(updateReconciledCount(data.totalCount!));
                return {
                  data: data.activities!,
                  total: data.totalCount!,
                };
              }}
              resourceName='reconciled transaction'
              hideFilters
            />
          </div>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, pendingCount, reconciledCount]);

  const tabsPathRegex = `(${tabs.map((tab) => tab.path).join('|')})`;

  if (isFetching) {
    return <DefaultLoader />;
  }

  return (
    <ZenRoute title='Reconcile Transactions'>
      <PageLayout
        path={[
          { title: 'Finance', url: '/finance' },
          { title: 'Bank Accounts', url: '/bank-accounts' },
          {
            title: bankDetail?.bankName || '',
            url: `/bank-accounts/${id}/reconcile-transactions`,
          },
        ]}
      >
        <div className='px-4 lg:pt-5'>
          <div className='flex justify-between mb-5'>
            <ZenHeaderWithBackButton
              title='Reconcile Transactions'
              onBack={() => history.replace('/bank-accounts')}
            />
            <div>
              <input
                type='file'
                id='bankStatement'
                aria-label='bankStatement'
                ref={inputFileRef}
                className='hidden'
                accept='application/msword, application/vnd.ms-excel, application/pdf, .csv'
                onChange={async (e) => {
                  setUploadLoading(true);
                  await dispatch(uploadBankStatement(id, e));
                  setUploadLoading(false);
                  dispatch(changeLoadingDetail(true));
                  dispatch(changeLoadingDetail(false));
                }}
              />
              <ZenButton
                label='Upload Bank Statement'
                LeftIconComponent={
                  <FontAwesomeIcon
                    icon={faCloudArrowUp}
                    className='text-primary-light text-sm'
                  />
                }
                variant='primary'
                onClick={() => inputFileRef.current?.click()}
                isFullWidth
                isDisabled={uploadLoading}
                isSubmitting={uploadLoading}
              />
            </div>
          </div>
          <ResourceContainer
            isEmpty={!bankDetail}
            loading={loadingDetail}
            resourceName='Bank Account Detail'
          >
            <div className='my-5'>
              <ZenBankDetailsCard bank={bankDetail!} setEditing={setEditing} />
            </div>

            <Switch>
              <Route path={tabsPathRegex} exact>
                <ZenRouterTabs tabs={tabs} />
              </Route>
            </Switch>
            {isOpenPendingSidebar && selectedTransaction && (
              <ZenReconcilePendingTransactionSidebarModal
                isOpen={isOpenPendingSidebar}
                onClose={handlePendingSelectedTransaction}
                selectedTransaction={selectedTransaction}
              />
            )}
            {isOpenReconciledSidebar && selectedTransaction && (
              <ZenReconcileTransactionSidebarModal
                isOpen={isOpenReconciledSidebar}
                onClose={handleReconciledSelectedTransaction}
                selectedTransaction={selectedTransaction}
              />
            )}
            {!!isEditing && (
              <CreateBankAccountSidebarModal
                isOpen
                onClose={() => {
                  setEditing(undefined);
                  fetchData(false);
                }}
                bankAccountDetails={isEditing}
              />
            )}
          </ResourceContainer>
        </div>
      </PageLayout>
    </ZenRoute>
  );
};

export default ReconcileTransactions;
