import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';
import ZenPromoteUserAsBrokerSidebarModal from '../forms/ZenPromoteUserAsBrokerSidebarModal';

interface ZenPromoteUserAsBrokerProps {}

const ZenPromoteUserAsBroker: React.FC<ZenPromoteUserAsBrokerProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <ZenResourceLinkCard
        title='Promote User as Broker'
        onClick={() => setIsOpen(true)}
        icon={solid('stairs')}
        iconSize='2x'
      />

      <ZenPromoteUserAsBrokerSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ZenPromoteUserAsBroker;
