import React from 'react';
import { useFormContext } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import ZenControlledCurrencyInput from '../../../components/Zen/Input/ZenControlledCurrencyInput';
import { RootState } from '../../../types';
import { displayFormattedFixedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import { WealthManagementCard } from '../WealthManagementCard';
import { GoalsFormInput } from './SetGoalsSideBarV2';

interface SetWealthGoalCardProps {}

const SetWealthGoalCardV2: React.FC<SetWealthGoalCardProps> = () => {
  const { currentWealthResponse } = useSelector(
    (state: RootState) => state.wealthManagement,
  );

  const { control } = useFormContext<GoalsFormInput>();

  const currentWealth = displayFormattedFixedAmountWithCurrency(
    currentWealthResponse.data?.wealthValue,
    false,
    0,
  );
  const currentWealthAmount =
    currentWealthResponse?.data?.wealthValue?.amount ?? 0;

  return (
    <WealthManagementCard>
      <div className='items-center flex flex-col mx-auto'>
        <div className=' px-4 py-2 w-3/4 flex flex-col items-center'>
          <div className='text-coolGray-500'>Real Wealth Earned-to-Date:</div>
          <div className='text-3xl'>{currentWealth}</div>
        </div>
        <div className='border-b border-gray-200 my-2 w-full' />
        <div className='text-base font-semibold space-y-2 mt-4 w-full flex flex-col justify-center items-center'>
          <p className='text-zen-dark-8'>Edit your goal amount</p>
          <p className='text-zen-dark-12'>Real Wealth Goal in 5 Years:</p>
        </div>
        <div className='w-full mt-2'>
          <ZenControlledCurrencyInput<GoalsFormInput, 'earningsGoalValue'>
            name='earningsGoalValue'
            control={control}
            placeholder='Enter Amount'
            rules={{
              required: 'Please set a goal amount.',
              min: {
                value: currentWealthAmount,
                message: 'Goal amount must be greater than current wealth.',
              },
            }}
            startAdornment={
              <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
                $
              </div>
            }
          />
        </div>
      </div>
    </WealthManagementCard>
  );
};

export default SetWealthGoalCardV2;
