import React from 'react';
import ZenPromoteAdministrator from './ZenPromoteAdministrator';

interface ZenSuperAdminComponentProps {}

const ZenSuperAdminComponent: React.FC<ZenSuperAdminComponentProps> = () => {
  return (
    <div className='grid grid-cols-1 grid-flow-row md:grid-cols-6 gap-4 p-4'>
      <ZenPromoteAdministrator />
    </div>
  );
};

export default ZenSuperAdminComponent;
