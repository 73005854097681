import React from 'react';
import Map, { AddressData } from '../components/Map';
import GeminiPill from '../../../components/Gemini/GeminiPill';

interface ReferralTransactionCardProps {
  address: AddressData[];
}

const ReferralTransactionCard: React.FC<ReferralTransactionCardProps> = ({
  address,
}) => {
  return (
    <div>
      <div className='flex flex-col bg-white rounded-lg border border-grey-200 w-80 mx-2'>
        <div className='relative'>
          <GeminiPill
            label='Sold'
            containerClassNames='bg-green-100 text-green-600 font-inter top-4 left-4 absolute px-4'
          />
          <Map
            places={address}
            options={{
              disableDefaultUI: true,
              disableDoubleClickZoom: true,
              fullscreenControl: false,
            }}
            customContainerStyle={{ height: '200px' }}
          />
        </div>
        <div className='flex flex-col justify-start items-start gap-4 p-4'>
          <div className='flex-col justify-start items-start gap-4 flex'>
            <div className='text-[#050e3c] text-md font-semibold font-inter leading-tight'>
              $400,000
            </div>
            <div className='text-grey-600 text-sm font-normal font-inter leading-tight line-clamp-2 w-3/4'>
              {address[0].name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralTransactionCard;
