import React from 'react';
import { useSelector } from 'react-redux';
import { CalendarEventResponse } from '../../../openapi/yenta';
import { RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import ZenEventCard from './ZenEventCard';

export interface ZenEventsProps {
  events: CalendarEventResponse[];
  onOpen: (event: CalendarEventResponse) => void;
  onEdit?: (event: CalendarEventResponse) => void;
}

const ZenEvents: React.FC<ZenEventsProps> = ({ events, onEdit, onOpen }) => {
  const {
    mainMenu: { isCollapsed },
  } = useSelector((state: RootState) => state);

  return (
    <div
      className={cn(
        'grid gap-6 p-5 md:p-10 grid-cols-1 md:grid-cols-2 3xl:grid-cols-4',
        isCollapsed ? 'lg:grid-cols-4' : 'lg:grid-cols-3',
      )}
    >
      {events?.map((event, index) => {
        return (
          <ZenEventCard
            key={[event.id, index].join(',')}
            onOpen={onOpen}
            onEdit={onEdit}
            event={event}
          />
        );
      })}
    </div>
  );
};

export default ZenEvents;
