import { capitalize, keys } from 'lodash';
import { YesNoType } from '../../../types';
import SelectColumnFilter, {
  SelectColumnFilterProps,
} from './Base/SelectColumnFilter';

export interface YesNoSelectColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const YesNoSelectColumnFilter = <D extends object>(
  props: YesNoSelectColumnFilterProps<D>,
) => {
  return (
    <SelectColumnFilter
      {...props}
      options={keys(YesNoType).map((type) => ({
        label: capitalize(type),
        value: type,
      }))}
    />
  );
};

export default YesNoSelectColumnFilter;
