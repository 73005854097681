import { useSelector } from 'react-redux';
import ZenTeamMembersNameCell from '../Table/Cell/ZenTeamMembersNameCell';
import useTeamsInfo from '../../../hooks/useTeamsInfo';
import { RootState } from '../../../types';

interface AnnouncementTeamStackProps {
  ids: string[];
}

const AnnouncementTeamStack: React.FC<AnnouncementTeamStackProps> = ({
  ids,
}) => {
  const { teamsById } = useSelector((state: RootState) => state.team);

  useTeamsInfo(ids);

  const getCircleWithText = (text: string) => {
    return (
      <span className='flex items-center justify-center rounded-full h-7 w-7 ring-2 ring-white bg-zen-blue-4 text-white'>
        <span className='font-zen-body font-normal text-[11px] leading-1'>
          {text}
        </span>
      </span>
    );
  };

  return (
    <div className='flex flex-col'>
      <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
        Teams:
      </div>
      {ids?.slice(0, 2).map((id, index) => {
        const team = teamsById[id];
        if (index < 1) {
          return (
            <div className='flex items-center gap-3' key={team?.id}>
              <ZenTeamMembersNameCell key={team?.id} agents={team?.agents!} />
              <div className='font-zen-body font-normal text-sm text-zen-dark-9'>
                {team?.name}
              </div>
            </div>
          );
        } else {
          return (
            <div className='flex items-center font-zen-body font-normal text-sm text-zen-dark-9'>
              {getCircleWithText(`+${ids?.length - 1}`)}{' '}
              <span className='ml-2'>Teams</span>
            </div>
          );
        }
      })}
    </div>
  );
};

export default AnnouncementTeamStack;
