import { motion } from 'framer-motion';
import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { sortBy } from 'lodash';
import { cn } from '../../utils/classUtils';
import { ReactComponent as LockedTier1 } from '../../assets/icons/revshare-insights/tiers/off/tier1.svg';
import { ReactComponent as LockedTier2 } from '../../assets/icons/revshare-insights/tiers/off/tier2.svg';
import { ReactComponent as LockedTier3 } from '../../assets/icons/revshare-insights/tiers/off/tier3.svg';
import { ReactComponent as LockedTier4 } from '../../assets/icons/revshare-insights/tiers/off/tier4.svg';
import { ReactComponent as LockedTier5 } from '../../assets/icons/revshare-insights/tiers/off/tier5.svg';
import { ReactComponent as UnlockedTier1 } from '../../assets/icons/revshare-insights/tiers/on/tier1.svg';
import { ReactComponent as UnlockedTier2 } from '../../assets/icons/revshare-insights/tiers/on/tier2.svg';
import { ReactComponent as UnlockedTier3 } from '../../assets/icons/revshare-insights/tiers/on/tier3.svg';
import { ReactComponent as UnlockedTier4 } from '../../assets/icons/revshare-insights/tiers/on/tier4.svg';
import { ReactComponent as UnlockedTier5 } from '../../assets/icons/revshare-insights/tiers/on/tier5.svg';
import { RootState } from '../../types';
import { NetworkSizeByTierResponse } from '../../openapi/yenta';
import {
  getTierProgressPercentage,
  revShareInsightsTierColorMap,
} from '../../utils/RevShareInsightsUtils';

const tiers = [
  {
    unlockedIcon: UnlockedTier1,
    lockedIcon: LockedTier1,
  },
  {
    unlockedIcon: UnlockedTier2,
    lockedIcon: LockedTier2,
  },
  {
    unlockedIcon: UnlockedTier3,
    lockedIcon: LockedTier3,
  },
  {
    unlockedIcon: UnlockedTier4,
    lockedIcon: LockedTier4,
  },
  {
    unlockedIcon: UnlockedTier5,
    lockedIcon: LockedTier5,
  },
];

interface RevShareInsightsTierProgressBarProps {
  currentNetworkSizeByTier?: NetworkSizeByTierResponse;
  onClickTierAgents(tier: number): void;
}

const RevShareInsightsTierProgressBar: React.FC<RevShareInsightsTierProgressBarProps> = ({
  currentNetworkSizeByTier,
  onClickTierAgents,
}) => {
  const {
    agentDetail,
    network: { zenNetworksById },
  } = useSelector((state: RootState) => state);
  const frontLineInfo = zenNetworksById[agentDetail.detailResponse.data?.id!];
  const unlockedTier = frontLineInfo?.tierUnlocked || 0;
  const agentsRequiredToUnlockNextTier =
    frontLineInfo?.numberOfFrontLineAgentsToUnlockNextTier || 0;

  const sortedTiers = sortBy(
    agentDetail.detailResponse.data?.planMembership?.revenueSharePlan?.tiers ||
      [],
    'tier',
  );

  return (
    <div className='relative flex flex-row items-center'>
      {tiers.map((tier, index) => {
        const isFirstTier = index === 0;
        const isLastTier = index === tiers.length - 1;
        const Icon = unlockedTier > index ? tier.unlockedIcon : tier.lockedIcon;
        const progress = getTierProgressPercentage(
          index + 1,
          unlockedTier,
          agentsRequiredToUnlockNextTier,
          sortedTiers,
        );
        const networkTier = currentNetworkSizeByTier?.tierNetworkSizes?.[index];
        const currTier = networkTier?.tier || index + 1;
        const color = revShareInsightsTierColorMap[currTier];

        if (isLastTier) {
          return (
            <div
              className='absolute -top-2.5 -right-2 z-10'
              key={color}
              aria-label={`tier-${currTier}`}
            >
              <div className='flex justify-end'>
                <Icon width={25} height={25} />
              </div>
              <button
                className='flex items-center space-x-1 text-primary-blue text-sm'
                onClick={() => onClickTierAgents(currTier)}
                aria-label={`view-tier${currTier}-networks`}
              >
                <FontAwesomeIcon icon={regular('user')} size='sm' />
                <p>{networkTier?.networkSize || 0}</p>
              </button>
            </div>
          );
        }

        return (
          <div
            key={color}
            className='flex-1 relative pb-6'
            style={{ zIndex: index + 1 }}
            aria-label={`tier-${currTier}`}
          >
            <div
              className={cn(
                'flex-1 h-1.5',
                isFirstTier ? 'rounded-full' : 'rounded-none',
              )}
              style={{ backgroundColor: '#EDEFF3' }}
            />
            <motion.div
              initial={{ width: '0%' }}
              animate={{ width: progress + '%' }}
              transition={{ duration: 1.5 }}
              style={{ backgroundColor: color }}
              aria-label={`progress-bar-${progress}%`}
              className={cn(
                'absolute h-1.5 top-0 inset-x-0',
                isFirstTier ? 'rounded-full' : 'rounded-r',
              )}
            />
            <div className='absolute -top-2.5 -left-2 z-10'>
              <Icon width={25} height={25} />
              <button
                className='flex items-center space-x-1 text-primary-blue text-sm'
                onClick={() => onClickTierAgents(currTier)}
                aria-label={`view-tier${currTier}-networks`}
              >
                <FontAwesomeIcon icon={regular('user')} size='sm' />
                <p>{networkTier?.networkSize || 0}</p>
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RevShareInsightsTierProgressBar;
