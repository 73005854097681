import TableChartIcon from '@material-ui/icons/TableChart';

export interface ZenDefaultEmptyProps {
  message: string;
  icon?: React.ReactElement;
}

const ZenDefaultEmpty: React.FC<ZenDefaultEmptyProps> = ({
  message,
  icon = <TableChartIcon fontSize='large' />,
}) => {
  return (
    <div className='w-full h-full p-5 flex flex-col items-center justify-center'>
      {icon}
      <p className='font-zen-body font-medium text-zen-dark-6'>{message}</p>
    </div>
  );
};

export default ZenDefaultEmpty;
