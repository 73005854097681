import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import ZenControlledSelectInput from '../../../components/Zen/Input/ZenControlledSelectInput';
import {
  ParticipantValue,
  PaymentParticipantValue,
  TransactionResponse,
  UpdateParticipantRequest,
  UpdateParticipantRequestParticipantRoleEnum,
} from '../../../openapi/arrakis';
import { updateTransactionParticipant } from '../../../slices/TransactionSlice';
import { AppDispatch, ISelectOption } from '../../../types';
import ZenSidebarModalForm from '../ZenSidebarModalForm';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import { isParticipantValid } from '../../../utils/TransactionHelper';
import { showSuccessToast } from '../../../slices/ToastNotificationSlice';

interface ZenChangeCdPayerSidebarModalProps {
  isOpen: boolean;
  transaction: TransactionResponse;
  onClose(): void;
}

interface FormData {
  payer: ISelectOption;
}

const ZenChangeCdPayerSidebarModal: React.FC<ZenChangeCdPayerSidebarModalProps> = ({
  isOpen,
  onClose,
  transaction,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const cdPayer = transaction.cdPayer;
  const allParticipants: Array<ParticipantValue | PaymentParticipantValue> = [
    transaction.cdPayer!,
    ...transaction.otherParticipants!,
    ...transaction.paymentParticipants!,
  ].filter((p) => !!p && isParticipantValid(p));
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      payer: {
        value: cdPayer?.id!,
        label: getParticipantName(cdPayer!),
      },
    },
  });

  const onSubmit = async (value: { payer: ISelectOption }) => {
    const participant:
      | ParticipantValue
      | PaymentParticipantValue
      | undefined = allParticipants.find((p) =>
      !value.payer?.value
        ? p.commissionDocumentPayer
        : p.id === value.payer?.value,
    );

    if (participant) {
      const data: UpdateParticipantRequest = {
        emailAddress: participant?.emailAddress,
        firstName: participant?.firstName,
        lastName: participant?.lastName,
        phoneNumber: participant?.phoneNumber,
        commissionDocumentRecipient: !!participant?.commissionDocumentRecipient,
        payer: !!value.payer,
        passThrough: !!participant?.passThrough,
        paidByReal: !!participant?.paidByReal,
        paidViaBusinessEntity: participant.paidViaBusinessEntity!,
        notes: participant.notes,
        paymentInstructions: participant.paymentInstructions!,
        personalDeal:
          'personalDeal' in participant! ? !!participant?.personalDeal : false,
        participantRole: (participant?.role as unknown) as UpdateParticipantRequestParticipantRoleEnum,
        address: participant?.address,
      };

      const success = await dispatch(
        updateTransactionParticipant(participant?.id!, transaction.id!, data),
      );
      if (success) {
        dispatch(showSuccessToast('Commission Payer Updated!'));
        onClose();
      }
    }
  };

  return (
    <ZenSidebarModalForm
      title='Commission Payer'
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Save'
      isSubmitting={isSubmitting}
    >
      <div>
        <ZenControlledSelectInput
          name='payer'
          control={control}
          label='Commission Payer'
          placeholder='Select Commission Payer'
          options={allParticipants?.map((participant) => ({
            value: participant?.id!,
            label: getParticipantName(participant),
          }))}
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenChangeCdPayerSidebarModal;
