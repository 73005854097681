import React from 'react';
import ReactGiphySearchbox from 'react-giphy-searchbox';
import { IGif } from '@giphy/js-types';
import { Popover } from 'react-tiny-popover';
import config from '../../../config';

interface SelectGiphyProps {
  onClickOutside(): void;
  onGiphySelect(gif: IGif): void;
}

const SelectGiphy: React.FC<SelectGiphyProps> = ({
  onClickOutside,
  onGiphySelect,
}) => {
  return (
    <div className='relative'>
      <div
        className='fixed inset-0 bg-black bg-opacity-0'
        onClick={onClickOutside}
      />
      <Popover
        isOpen
        positions={['top']}
        align='start'
        // onClickOutside={onClickOutside}
        content={
          <div className='p-4 bg-white rounded-md shadow-lg z-20'>
            <ReactGiphySearchbox
              apiKey={config.giphyApiKey}
              onSelect={onGiphySelect}
              masonryConfig={[
                { columns: 2, imageWidth: 110, gutter: 5 },
                { mq: '700px', columns: 3, imageWidth: 120, gutter: 5 },
              ]}
              poweredByGiphy={false}
              autoFocus
              wrapperClassName='min-w-max'
            />
          </div>
        }
      >
        <div />
      </Popover>
    </div>
  );
};

export default SelectGiphy;
