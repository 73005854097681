import { SvgIcon } from '@material-ui/core';
import { ReactComponent as LeftBracket } from '../assets/icons/left-bracket.svg';
import { ReactComponent as RightBracket } from '../assets/icons/right-bracket.svg';

export interface DocumentTitleProps {
  title: string;
}

const DocumentTitle: React.FC<DocumentTitleProps> = ({ title }) => {
  return (
    <div className='bg-mineshaft flex w-full justify-center'>
      <SvgIcon
        component={LeftBracket}
        className='text-mineshaft mt-1 ml-2 md:ml-0'
        viewBox='0 0 20 20'
      />
      <p className='text-white font-primary-regular md:text-2xl print:text-lg pr-2 md:pr-4 md:pl-2 my-1 self-center'>
        {title}
      </p>
      <SvgIcon
        component={RightBracket}
        className='text-mineshaft mt-4'
        viewBox='0 0 20 20'
      />
    </div>
  );
};

export default DocumentTitle;
