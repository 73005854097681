import { useState } from 'react';
import { UnpackNestedValue } from 'react-hook-form-v7';
import { TableInstance } from 'react-table';
import { RowsSelectionAction } from '../../types';
import IconButton from '../IconButton';
import FilterConfirmationModal from './FilterConfirmationModal';
import FilterOption from './Options/FilterOption';

interface TableOptionsProps<D extends object> {
  tableInstance: TableInstance<D>;
  selectionOptions?: RowsSelectionAction<D>[];
  onSelectionOptionClicked?(
    index: number,
    formData?: UnpackNestedValue<D>,
  ): void;
}

const TableOptions = <D extends object>({
  tableInstance,
  selectionOptions = [],
  onSelectionOptionClicked,
}: TableOptionsProps<D>) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [selectionIndex, setSelectionIndex] = useState<number | null>(null);

  const handleOptionClick = async (
    index: number,
    formData?: UnpackNestedValue<D>,
  ) => {
    setSubmitting(true);
    await onSelectionOptionClicked!(index, formData);
    setSubmitting(false);
    setSelectionIndex(null);
  };

  return (
    <div className='flex flex-row flex-nowrap space-x-3 justify-end'>
      {selectionOptions.map(({ label, confirm, disabled }, index) => (
        <div key={label}>
          <IconButton
            buttonType='button'
            label={label}
            disabled={disabled}
            onClick={() => {
              if (confirm) {
                setSelectionIndex(index);
              } else {
                handleOptionClick(index);
              }
            }}
          />
        </div>
      ))}
      <FilterOption<D> tableInstance={tableInstance} />
      {selectionIndex !== null && !!selectionOptions[selectionIndex] && (
        <div>
          <FilterConfirmationModal<D>
            selection={selectionOptions[selectionIndex]}
            isSubmitting={isSubmitting}
            onClick={(formData?: UnpackNestedValue<D>) =>
              handleOptionClick(selectionIndex, formData)
            }
            onClose={() => setSelectionIndex(null)}
          />
        </div>
      )}
    </div>
  );
};

export default TableOptions;
