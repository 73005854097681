import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DefaultLoader from '../components/DefaultLoader';
import TEN99NECForm from '../components/forms/TEN99NECForm';
import T4AForm from '../components/T4A/T4AForm';
import ZenRoute from '../components/Zen/ZenRoute';
import {
  TaxDocumentControllerApi,
  TaxDocumentResponse,
  TaxDocumentResponseTaxDocumentTypeEnum,
} from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';

type Match = {
  id: string;
};

const TaxDocumentPdfRoute: React.FC = () => {
  const { id } = useParams() as Match;

  const dispatch = useDispatch();
  const [taxDocument, setTaxDocument] = useState<TaxDocumentResponse>();
  const [loading, setIsLoading] = useState(true);

  const fetchTaxDocument = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await new TaxDocumentControllerApi(
        getArrakisConfiguration(),
      ).getTaxDocument(id);
      setTaxDocument(data);
    } catch (e) {
      dispatch(
        showErrorToast(
          'We had trouble fetch tax document. Please try again in a few moments.',
        ),
      );
      ErrorService.notifyIgnoreAuthErrors('Unable to fetch tax document', e, {
        taxDocId: { id },
      });
    } finally {
      setIsLoading(false);
    }
  }, [id, dispatch]);

  useEffect(() => {
    fetchTaxDocument();
  }, [fetchTaxDocument]);

  if (loading) {
    return <DefaultLoader />;
  }

  return (
    <ZenRoute
      title={
        taxDocument?.taxDocumentType ===
        TaxDocumentResponseTaxDocumentTypeEnum.Ten99
          ? '1099 Form PDF'
          : 'T4A Form PDF'
      }
    >
      {taxDocument?.taxDocumentType ===
        TaxDocumentResponseTaxDocumentTypeEnum.Ten99 && (
        <TEN99NECForm taxDocument={taxDocument} />
      )}
      {taxDocument?.taxDocumentType ===
        TaxDocumentResponseTaxDocumentTypeEnum.T4A && (
        <T4AForm taxDocument={taxDocument} />
      )}
    </ZenRoute>
  );
};

export default TaxDocumentPdfRoute;
