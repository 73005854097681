import classNames from 'classnames';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form-v7';
import ZenFormErrorMessage from '../../components/Zen/Input/ZenFormErrorMessage';
import { ISelectOption } from '../../types';
import { cn } from '../../utils/classUtils';

export interface SelectImageOption extends ISelectOption {
  icon?: React.ReactElement;
}

type ColsPerRow = 'grid-cols-2' | 'grid-cols-4';

export interface ControlledSingleSelectInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  options: SelectImageOption[];
  label?: string;
  colsPerRow?: ColsPerRow;
  isRequired?: boolean;
}

const ControlledSingleSelectInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  options,
  shouldUnregister = true,
  isRequired,
  colsPerRow = 'grid-cols-2',
  ...controllerProps
}: ControlledSingleSelectInputProps<TFieldValues, TName>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  return (
    <div>
      {label && (
        <label className='inline-block'>
          <span className='font-zen-body font-semibold text-base text-black'>
            {label} {isRequired && <span className='text-error'>*</span>}
          </span>
        </label>
      )}
      <div className={cn('grid grid-flow-row gap-4 pt-2', colsPerRow)}>
        {(options || []).map((option) => {
          const isSelected = option.value === value;

          return (
            <div key={option.label}>
              <button
                onClick={() => {
                  if (!isSelected) {
                    onChange(option.value);
                  }
                }}
                disabled={option.disabled}
                className={classNames([
                  'flex flex-row justify-center items-center rounded-xl w-full px-4 py-6 hover:bg-regent-200  hover:text-rezen-blue-600',
                  isSelected
                    ? 'border border-rezen-blue-600 bg-regent-200 bg-opacity-10 text-rezen-blue-600'
                    : 'bg-transparent border border-gray-200',
                ])}
              >
                <div className='flex flex-col items-center justify-center'>
                  {!!option.icon && <div>{option.icon}</div>}
                  <span className='text-sm'>{option.label}</span>
                </div>
              </button>
            </div>
          );
        })}
      </div>
      <ZenFormErrorMessage message={error?.message} />
    </div>
  );
};

export default ControlledSingleSelectInput;
