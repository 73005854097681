import classNames from 'classnames';
import React, { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps } from '../../../types';
import ZenFormErrorMessage from './ZenFormErrorMessage';

interface ZenControlledTextAreaInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  subLabel?: string;
  placeholder?: string;
  rows?: number;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  noScroll?: boolean;
  noResize?: boolean;
  borderPrimary?: boolean;
  className?: string;
  border?: boolean;
  isRequired?: boolean;
  readOnly?: boolean;
}

const ZenControlledTextAreaInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  placeholder,
  label,
  subLabel,
  startAdornment,
  endAdornment,
  noScroll = false,
  noResize = false,
  borderPrimary = false,
  rows = 1,
  className,
  shouldUnregister = true,
  border = true,
  isRequired,
  readOnly = false,
  ...rest
}: ZenControlledTextAreaInputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);
  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { error, invalid },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              <span
                className={classNames(
                  'font-zen-body font-semibold',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                )}
              >
                {label}
              </span>
              {!!subLabel && (
                <span className='font-zen-body text-sm text-zen-dark-12 ml-1'>
                  {subLabel}
                </span>
              )}
              {isRequired && <span className='text-zen-danger'>*</span>}
            </label>
          )}
          <div
            className={classNames(
              'flex rounded-lg bg-white border overflow-hidden',
              value && 'text-zen-dark-9',
              invalid && '!border-zen-danger',
              borderPrimary && 'border-primary-blue',
              focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
            )}
          >
            {startAdornment && <div>{startAdornment}</div>}
            <textarea
              id={name}
              ref={ref}
              value={value}
              onFocus={() => setFocus(true)}
              onBlur={(e) => {
                onChange(e.target.value?.trim());
                setFocus(false);
                onBlur();
              }}
              onChange={onChange}
              name={name}
              placeholder={placeholder}
              rows={rows}
              className={classNames(
                'appearance-none p-2 focus:outline-none focus:ring-0 w-full border-none font-zen-body font-normal text-base',
                { 'overflow-hidden': noScroll },
                { 'resize-none': noResize },
                {
                  'bg-gray-50': readOnly,
                },
                className,
              )}
              disabled={readOnly}
            />
            {endAdornment && <div>{endAdornment}</div>}
          </div>
          {error && <ZenFormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenControlledTextAreaInput;
