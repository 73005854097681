import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { useSelector } from 'react-redux';
import { VictoryPie } from 'victory';
import ChartIcon from '../../../assets/img/wealthManagement/chart-mixed-up-circle-dollar-light.svg';
import SvgImage from '../../../assets/img/wealthManagement/VectorLine.svg';
import { RootState } from '../../../types';
import { WealthManagementCard } from '../WealthManagementCard';

interface DataItem {
  value: number;
  name: string;
  fill: string;
  label: string;
}

const outerRadius = 70;
const innerRadiusRatio = 0.5;

const LabelComponent: React.FC<any> = (props) => {
  const { x, y, datum } = props;

  const startAngleGreater180 = datum.startAngle > 180;

  return (
    <g>
      <foreignObject
        x={x - (startAngleGreater180 ? 90 : 10)}
        y={startAngleGreater180 ? y - 4 : y - 30}
        rx={5}
        ry={5}
        width={200}
        height={35}
      >
        <div className='flex items-center'>
          {!startAngleGreater180 && (
            <img
              src={SvgImage}
              alt='vector-line'
              className='h-2.5 mb-2 rotate-180'
            />
          )}
          <div
            className={classNames(
              'py-1.5 px-2 rounded-md border border-zen-light-gray-2 bg-white min-w-max flex-shrink-0 z-10',
              startAngleGreater180 ? '-mr-2' : '-ml-2',
            )}
          >
            <div className='flex items-center space-x-1'>
              <span className='text-sm text-zen-dark-8 font-semibold'>
                {datum.name}
              </span>
            </div>
          </div>
          {startAngleGreater180 && (
            <img src={SvgImage} alt='vector-line' className='h-2.5 mt-2' />
          )}
        </div>
      </foreignObject>
    </g>
  );
};

interface IncomeProjectionPieChartProps {
  data: DataItem[];
  title?: string;
}

const IncomeProjectionPieChart: React.FC<IncomeProjectionPieChartProps> = ({
  data,
  title = 'Projected Earnings',
}) => {
  const {
    incomeOverviewResponse: { data: incomeOverviewResponse },
  } = useSelector((state: RootState) => state.agentDetail);

  return (
    <WealthManagementCard classNames='flex-1 flex-grow'>
      <div className='flex items-center'>
        <img
          src={ChartIcon}
          className='w-[17px] h-[15px] mr-1'
          alt='chart-icon'
        />
        <span className='text-base font-semibold text-zen-dark-9'>{title}</span>
      </div>
      {!!incomeOverviewResponse?.anniversaryDate && (
        <p className='text-zen-dark-6 text-base mt-2'>
          Cap Resets on:{' '}
          {DateTime.fromISO(incomeOverviewResponse?.anniversaryDate).toFormat(
            'MM/dd/yyyy',
          )}
        </p>
      )}
      <div className='flex items-center justify-center mb-2 !overflow-hidden -mx-4'>
        <div className='w-full flex items-center justify-center'>
          <svg
            height={outerRadius * 3.6}
            className='w-full md:pl-[15%] md:ml-0 -ml-20'
          >
            <VictoryPie
              standalone={false}
              height={outerRadius * 4}
              radius={outerRadius}
              innerRadius={outerRadius * innerRadiusRatio}
              labelComponent={
                data.every((d) => d.value > 1) ? <LabelComponent /> : <div />
              }
              style={{
                data: {
                  fill: ({ datum }) => datum.fill,
                },
              }}
              animate
              data={data}
              x='name'
              y='value'
            />
          </svg>
        </div>
      </div>
      <div className='flex flex-row items-center justify-center md:space-x-4 space-x-2 mb-4'>
        {data
          .filter((datum) => datum.value > 0)
          .map((datum) => (
            <div key={datum.label} className='flex items-center'>
              <div
                className='h-2 w-2 rounded-full mr-1'
                style={{ backgroundColor: datum.fill }}
              />
              <span className='font-zen-body md:text-base text-sm leading-5 text-zen-dark-6'>
                {datum.label}
              </span>
            </div>
          ))}
      </div>
    </WealthManagementCard>
  );
};

export default IncomeProjectionPieChart;
