import {
  faArrowUpToLine,
  faCircleXmark,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCheck,
  faCircleExclamation,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnumMap } from '../types';
import { cn } from '../utils/classUtils';

export type ModalVariantType =
  | 'info'
  | 'warning'
  | 'success'
  | 'error'
  | 'danger'
  | 'upload'
  | 'cancel'
  | 'close';
export type SizeVariantType = 'default' | 'small' | 'large';

export interface ConfirmationModalProps {
  variant: ModalVariantType;
  title?: string;
  isOpen: boolean;
  subtitle?: string;
  hideIcon?: boolean;
  size?: SizeVariantType;
  onClose(): void;
  customIcon?: React.ReactElement;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  variant,
  title,
  isOpen,
  subtitle,
  children,
  onClose,
  hideIcon = false,
  size = 'default',
  customIcon,
}) => {
  const classMapStyle: EnumMap<ModalVariantType, string> = {
    error: 'bg-error text-error',
    info: 'bg-primary text-primary',
    success: 'bg-success text-success',
    warning: 'bg-warning text-warning',
    danger: 'bg-error text-error',
    upload: 'bg-primary text-primary',
    cancel: 'bg-error text-error',
    close: 'bg-error text-error',
  };

  const IconComponent: EnumMap<ModalVariantType, React.ReactElement> = {
    error: <FontAwesomeIcon icon={faCircleExclamation} />,
    info: <FontAwesomeIcon icon={faCircleExclamation} className='rotate-180' />,
    success: <FontAwesomeIcon icon={faCheck} />,
    warning: <FontAwesomeIcon icon={faTriangleExclamation} />,
    danger: <FontAwesomeIcon icon={faTrash} />,
    upload: <FontAwesomeIcon icon={faArrowUpToLine} />,
    cancel: <FontAwesomeIcon icon={faTrash} />,
    close: <FontAwesomeIcon icon={faCircleXmark} />,
  };

  const sizeVariant: EnumMap<SizeVariantType, string> = {
    default: 'md:w-1/2 lg:w-1/4',
    large: 'md:w-3/5 lg:w-1/3',
    small: 'md:w-2/5 lg:w-1/5',
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
        <div
          className={cn(
            'w-full bg-white rounded-lg p-4 z-10',
            sizeVariant[size],
          )}
        >
          <div
            className={cn('flex items-start', {
              'justify-center': hideIcon,
            })}
          >
            {customIcon ? (
              <div className='mr-3'>
                <div
                  className={cn(
                    'flex justify-center items-center w-10 h-10 p-1 bg-opacity-20 rounded-full',
                    classMapStyle[variant],
                  )}
                >
                  {customIcon}
                </div>
              </div>
            ) : (
              !hideIcon && (
                <div className='mr-3'>
                  <div
                    className={cn(
                      'flex justify-center items-center w-10 h-10 bg-opacity-20 rounded-full p-1',
                      classMapStyle[variant],
                    )}
                  >
                    {IconComponent[variant]}
                  </div>
                </div>
              )
            )}
            <div className='w-full'>
              {title && (
                <p className='text-lg font-primary-bold text-dark'>{title}</p>
              )}
              {subtitle && (
                <p className='text-base text-gray-500 font-primary-regular'>
                  {subtitle}
                </p>
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
