import { orderBy } from 'lodash';
import { DateTime } from 'luxon';
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import EmptyTray from '../../../assets/img/EmptyTray.png';
import { AgentDocumentsResponse } from '../../../openapi/yenta';
import { getAgentFileLink } from '../../../slices/AgentSlice';
import { AsyncResponse, ISelectOption } from '../../../types';
import { getUniqueLabelOption } from '../../../utils/AgentFileUtils';
import DefaultEmpty from '../../DefaultEmpty';
import ZenAdd from '../../Zen/ZenAdd';
import ZenCard from '../../Zen/ZenCard';
import ZenView from '../../Zen/ZenView';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import ZenAddAgentFileForm from '../ZenAddAgentFileForm';
import AuthorizedOnly from '../../auth/AuthorizedOnly';
import ZenAgentFileRow from './ZenAgentFileRow';

interface ZenAgentFilesProps {
  id: string;
  agentFiles: AsyncResponse<AgentDocumentsResponse>;
}

const ZenAgentFiles: React.FC<ZenAgentFilesProps> = ({ id, agentFiles }) => {
  const dispatch = useDispatch();

  const [isAddingFile, setIsAddingFile] = useState<boolean>(false);
  const totalLength = agentFiles.data?.documents?.length;
  const agentFilesToShow = orderBy(
    agentFiles.data?.documents,
    'createdAt',
    'desc',
  )?.slice(0, 5);

  const labelOptions: ISelectOption[] = getUniqueLabelOption(
    agentFiles.data?.documents || [],
  );

  return (
    <React.Fragment>
      <ZenCard
        title='Recent Agent Files'
        RightComponent={
          <AuthorizedOnly asyncResponse={agentFiles}>
            <div className='flex flex-row items-center space-x-2'>
              <ZenAdd onClick={() => setIsAddingFile(true)} />
              {!!agentFiles?.data?.documents?.length && (
                <Link to={`/people/${id}/agent-documents`}>
                  <ZenView text={`View All (${totalLength})`} />
                </Link>
              )}
            </div>
          </AuthorizedOnly>
        }
      >
        <AuthorizationContainer asyncResponse={agentFiles}>
          <div className='mt-2 divide-y rounded'>
            {!!agentFiles.data?.documents?.length ? (
              <Fragment>
                {agentFilesToShow!.map((file) => (
                  <ZenAgentFileRow
                    key={file.id}
                    title={file.name!}
                    rightActionText='Download'
                    date={DateTime.fromMillis(file.createdAt!).toFormat(
                      'M/d/y',
                    )}
                    label={file.tags!}
                    onRightActionClick={() =>
                      dispatch(getAgentFileLink(id, file.id!))
                    }
                  />
                ))}
              </Fragment>
            ) : (
              <DefaultEmpty
                icon={
                  <img src={EmptyTray} alt='EmptyTrayImage' className='h-20' />
                }
                message='No recent agent files.'
              />
            )}
          </div>
        </AuthorizationContainer>
      </ZenCard>

      {isAddingFile && (
        <ZenAddAgentFileForm
          isOpen
          onClose={() => setIsAddingFile(false)}
          labelOptions={labelOptions}
        />
      )}
    </React.Fragment>
  );
};

export default ZenAgentFiles;
