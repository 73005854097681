import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface ServicePillProps {
  onClick?: () => void;
  label: string;
}

const ServicePill: React.FC<ServicePillProps> = ({ onClick, label }) => {
  return (
    <div
      className='flex items-center gap-2 border border-[#83C8D0] py-2 px-4 rounded-md bg-[#83C8D0] bg-opacity-10 w-fit h-fit'
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faCircleCheck}
        className='text-[#83C8D0]'
        size='lg'
      />
      <p className='font-light font-inter text-sm'>{label}</p>
    </div>
  );
};

export default ServicePill;
