import { cn } from '../../utils/classUtils';
import { ShadeVariant } from './TierMembershipCard';

export type SizeVariant = 'xs' | 'small' | 'medium';

export type TierDiamondProps = {
  tierType: ShadeVariant;
  size?: SizeVariant;
};

const TierDiamond = ({ tierType, size = 'small' }: TierDiamondProps) => {
  const typeVariantClassMap: Record<ShadeVariant, string> = {
    silver:
      'radial-gradient(138.57% 109.45% at 33.22% 10.74%, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(84.79deg, rgb(133, 141, 143) 2.91%, rgb(159, 162, 161) 19.98%, rgb(133, 139, 139) 36.57%, rgb(128, 133, 135) 50.23%, rgb(118, 130, 134) 75.1%, rgb(131, 140, 142) 84.37%, rgb(118, 130, 134) 96.56%)',
    gold:
      'radial-gradient(82.57% 102.45% at 22.22% 9.74%, rgba(250, 224, 154, 0.77) 0%, rgba(255, 255, 255, 0) 160%), linear-gradient(140deg, rgb(164, 124, 78) 2.91%, rgb(216, 186, 149) 19.98%, rgb(180, 140, 92) 36.57%, rgb(164, 124, 78) 50.23%, rgb(164, 124, 78) 75.1%, rgb(183, 141, 91) 84.37%, rgb(164, 124, 78) 96.56%)',
    platinum:
      'radial-gradient(102.41% 115.63% at 110% 0%, rgb(165, 177, 180) 42.97%, rgba(255, 255, 255, 0) 100%), linear-gradient(360deg, rgba(41, 51, 54, 0.7), rgba(41, 51, 54, 0.7)), radial-gradient(100% 107.17% at 0% 0%, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(84.79deg, rgb(87, 98, 102) 2.91%, rgb(159, 162, 161) 19.98%, rgb(133, 139, 139) 36.57%, rgb(87, 98, 102) 50.23%, rgb(87, 98, 102) 75.1%, rgb(118, 122, 123) 84.37%, rgb(218, 245, 255) 96.56%)',
  };

  const sizeVariantClassMap: Record<SizeVariant, string> = {
    xs: 'w-[24px] h-[24px]',
    small: 'w-[28px] h-[28px]',
    medium: 'w-[34px] h-[34px]',
  };

  return (
    <div
      className={cn(
        '-skew-y-[8deg] rotate-[139deg] shadow-lg rounded-md',
        sizeVariantClassMap[size],
      )}
      style={{
        backgroundImage: typeVariantClassMap[tierType],
        boxShadow:
          'rgb(0 0 0 / 45%) -2px 2px 3px -1.5px inset, rgb(255 255 255 / 40%) 1.5px -1.5px 1.5px 0.5px inset',
      }}
    />
  );
};

export default TierDiamond;
