import { faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import BreakRoomRealLogo from '../../../assets/img/break_room_real_logo.png';

export interface BreakRoomModalProps {
  isOpen: boolean;
  hideIcon?: boolean;
  onClose(): void;
}

const BreakRoomModal: React.FC<BreakRoomModalProps> = ({
  isOpen,
  children,
  onClose,
  hideIcon = false,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      id='break-room-modal'
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window animate-slide-in-up transition-all ease-in-out'
    >
      <div className='flex items-center justify-center w-full h-full bg-white z-10 overflow-y-auto scrollbar-hide'>
        <div className='w-full h-full flex flex-col'>
          <div className='p-2 py-4 bg-primary-blue flex flex-shrink justify-between'>
            <div className='flex-grow flex sm:pl-10 pl-2 items-end'>
              <img src={BreakRoomRealLogo} alt='Logo' className='h-6' />
              <p className='font-zen-title font-medium text-white text-base text-left tracking-widest leading-3 ml-2'>
                BREAK ROOM
              </p>
            </div>
            {!hideIcon && (
              <div className='mx-2'>
                <FontAwesomeIcon
                  icon={faXmarkCircle}
                  size='lg'
                  className='text-primary-light cursor-pointer text-2xl'
                  onClick={onClose}
                />
              </div>
            )}
          </div>
          <div className='w-full flex-grow'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default BreakRoomModal;
