import { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { cn } from '../../../utils/classUtils';
import { FormFieldTooltipIndexProps } from '../../../types';
import ZenFormErrorMessage from './ZenFormErrorMessage';

export type ControlledEmailInputType =
  | 'text'
  | 'email'
  | 'number'
  | 'hidden'
  | 'date'
  | 'datetime-local'
  | 'image'
  | 'month'
  | 'password'
  | 'search'
  | 'tel'
  | 'time'
  | 'url'
  | 'week';

export interface ZenControlledEmailInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  type?: ControlledEmailInputType;
  label?: string;
  subLabel?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  isRequired?: boolean;
  onChangeSpy?: (value: string) => void;
}

const ZenControlledEmailInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  type = 'text',
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  tooltipIndex,
  shouldUnregister = true,
  startAdornment,
  endAdornment,
  isRequired = false,
  onChangeSpy,
  ...rest
}: ZenControlledEmailInputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState(false);
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error, invalid },
      }) => (
        <div className='w-full space-y-1'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              <span
                className={cn(
                  'font-zen-body font-semibold',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                )}
              >
                {label}
              </span>
              {!!subLabel && (
                <span className='font-zen-body text-sm text-zen-dark-12 ml-1'>
                  {subLabel}
                </span>
              )}
              {isRequired && <span className='text-zen-danger'>*</span>}
            </label>
          )}
          <div
            className={cn(
              'flex items-stretch border rounded-lg overflow-hidden',
              focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
              value && 'text-zen-dark-9',
              invalid && '!border-zen-danger',
              {
                'border-none': noBorder,
              },
            )}
          >
            {startAdornment && <div>{startAdornment}</div>}
            <input
              id={name}
              value={value}
              onChange={(e) => {
                onChange(e.target.value.trim());
                if (onChangeSpy) {
                  onChangeSpy(e.target.value.trim());
                }
              }}
              onFocus={() => setFocus(true)}
              onBlur={() => {
                setFocus(false);
                onBlur();
              }}
              type={type}
              name={name}
              readOnly={readOnly}
              placeholder={placeholder}
              className={cn(
                'appearance-none p-2 w-full border-none focus:outline-none focus:ring-0 font-zen-body font-normal',
                {
                  'bg-gray-50': readOnly,
                },
              )}
              data-tooltip-index={tooltipIndex}
            />
            {endAdornment && <div>{endAdornment}</div>}
          </div>
          {!!error && !hideErrorMessage && (
            <ZenFormErrorMessage message={error.message} />
          )}
        </div>
      )}
    />
  );
};
export default ZenControlledEmailInput;
