import { values } from 'lodash';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  AgentResponseCompetenciesEnum,
  AgentUpdateRequestCompetenciesEnum,
  AgentWebsiteResponse,
} from '../../../../../openapi/yenta';
import { saveAgentWebsiteDetails } from '../../../../../slices/AgentSlice';
import { ISelectOption } from '../../../../../types';
import { capitalizeEnum } from '../../../../../utils/StringUtils';
import { TEXT_CONTENT_VALIDATIONS } from '../../../../../utils/Validations';
import ZenControlledMultiSelectInput from '../../../../Zen/Input/ZenControlledMultiSelectInput';
import ZenControlledTextAreaInput from '../../../../Zen/Input/ZenControlledTextAreaInput';
import ZenSidebarModalForm from '../../../../Zen/ZenSidebarModalForm';

interface ZenEditAboutContentSidebarModalFormProps {
  isOpen: boolean;
  onClose(): void;
  agentWebsite: AgentWebsiteResponse;
}

interface FormData {
  workWith: ISelectOption[];
  serviceZipCodes: string;
}

const ZenEditAboutContentSidebarModalForm: React.FC<ZenEditAboutContentSidebarModalFormProps> = ({
  isOpen,
  onClose,
  agentWebsite,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    const updatedData: AgentWebsiteResponse = {
      ...agentWebsite,
      agentResponse: {
        ...agentWebsite.agentResponse,
        competencies: (formData.workWith || []).map(
          (competency) => competency.value,
        ) as AgentResponseCompetenciesEnum[],
        serviceAreaZipcodes: formData.serviceZipCodes,
      },
    };
    dispatch(saveAgentWebsiteDetails(updatedData));
    onClose();
  };

  return (
    <ZenSidebarModalForm
      title='About Content'
      subtitle='This content shows on the About page. Changes here will also update Profile information.'
      isOpen={isOpen}
      onClose={onClose}
      actionTitle='Save'
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <ZenControlledMultiSelectInput
          control={control}
          name='workWith'
          label='I Work With'
          placeholder='Works with'
          options={[
            ...values(AgentUpdateRequestCompetenciesEnum).map(
              (competencies) => ({
                value: competencies,
                label: capitalizeEnum(competencies),
              }),
            ),
          ]}
          defaultValue={agentWebsite.agentResponse?.competencies?.map(
            (competencies) => ({
              value: competencies,
              label: capitalizeEnum(competencies),
            }),
          )}
        />
      </div>
      <div className='mt-5'>
        <ZenControlledTextAreaInput<FormData, 'serviceZipCodes'>
          control={control}
          rows={3}
          name='serviceZipCodes'
          label='Service Area ZIP Codes'
          placeholder='Service Area ZIP Codes'
          rules={{
            required: 'Service area zip codes are required',
            ...TEXT_CONTENT_VALIDATIONS,
          }}
          defaultValue={agentWebsite.agentResponse?.serviceAreaZipcodes}
          isRequired
        />
      </div>
    </ZenSidebarModalForm>
  );
};

export default ZenEditAboutContentSidebarModalForm;
