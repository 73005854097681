import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  FlexTeamDtoStatusEnum,
  TeamResponseStatusEnum,
} from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { cn } from '../../../utils/classUtils';

interface ZenTeamStatusCellProps {
  status: TeamResponseStatusEnum | FlexTeamDtoStatusEnum;
  isPill?: boolean;
}

const ZenTeamStatusCell: React.FC<ZenTeamStatusCellProps> = ({
  status,
  isPill = true,
}) => {
  const pillIconVariantClassNameMap: EnumMap<
    TeamResponseStatusEnum | FlexTeamDtoStatusEnum,
    string
  > = {
    [TeamResponseStatusEnum.Active]: 'bg-green-100 text-green-600',
    [TeamResponseStatusEnum.Inactive]: 'bg-zen-danger-light text-zen-danger',
  };

  const normalIconVariantClassNameMap: EnumMap<
    TeamResponseStatusEnum | FlexTeamDtoStatusEnum,
    string
  > = {
    [TeamResponseStatusEnum.Active]: 'text-green-600',
    [TeamResponseStatusEnum.Inactive]: 'text-zen-danger',
  };

  const iconVariantMap: EnumMap<
    TeamResponseStatusEnum | FlexTeamDtoStatusEnum,
    React.ReactElement
  > = {
    [TeamResponseStatusEnum.Active]: (
      <FontAwesomeIcon icon={regular('circle-dot')} className='text-sm' />
    ),
    [TeamResponseStatusEnum.Inactive]: (
      <FontAwesomeIcon icon={regular('power-off')} className='text-sm' />
    ),
  };

  return (
    <div
      className={cn(
        'flex flex-row justify-center items-center font-zen-body font-semibold text-sm space-x-2 rounded-full px-3 py-1.5 w-max',
        isPill
          ? safeEnumMapGet(
              pillIconVariantClassNameMap,
              status,
              'bg-green-100 text-green-600',
            )
          : safeEnumMapGet(
              normalIconVariantClassNameMap,
              status,
              'text-green-600',
            ),
      )}
    >
      {safeEnumMapGet(
        iconVariantMap,
        status,
        <FontAwesomeIcon icon={regular('circle-dot')} className='text-sm' />,
      )}
      <span>{capitalizeEnum(status)}</span>
    </div>
  );
};

export default ZenTeamStatusCell;
