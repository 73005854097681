import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  TransactionSetupFormProps,
  TransactionSetupStepName,
} from '../../../containers/transaction/setup/TransactionSetupContainer';
import { AttachedFeeValueFeeTypeEnum } from '../../../openapi/arrakis';
import { RootState, YesNoType } from '../../../types';
import { getTransactionAttachedFeeByType } from '../../../utils/TransactionHelper';
import ControlledRadioInputV7 from '../../ControlledRadioInputV7';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import TransactionAttachedFeeSelectionRow from './TransactionAttachedFeeSelectionRow';

const TransactionSetupAttachedFeeTransactionCoordinatorStep: StepByStepComponent<
  TransactionSetupFormProps,
  TransactionSetupStepName
> = ({ form: { control, watch, trigger } }) => {
  const {
    transactionDetailResponse: { data: transactionDetail },
  } = useSelector((state: RootState) => state.transaction);

  const attachedFeeTransactionCoordinators = useMemo(
    () =>
      getTransactionAttachedFeeByType(
        transactionDetail!,
        AttachedFeeValueFeeTypeEnum.TransactionCoordinator,
      ),
    [transactionDetail],
  );

  const needsPayingTransactionCoordinator: boolean =
    watch('payingTransactionCoordinator') === YesNoType.YES;

  useEffect(() => {
    trigger('payingTransactionCoordinator');
  }, [attachedFeeTransactionCoordinators, trigger]);

  return (
    <div className='flex-grow flex flex-col mt-10 w-full max-w-xl'>
      <div>
        <p className='text-xl font-primary-medium text-dark mb-4'>
          Will you be paying a transaction coordinator?
        </p>
        <ControlledRadioInputV7<
          TransactionSetupFormProps,
          'payingTransactionCoordinator'
        >
          shouldUnregister={false}
          control={control}
          name='payingTransactionCoordinator'
          rules={{
            required: 'Required',
            validate: (value: YesNoType) => {
              return value === YesNoType.YES &&
                !attachedFeeTransactionCoordinators?.length
                ? 'Please add a transaction coordinator additional fee'
                : undefined;
            },
          }}
          options={[
            { label: 'Yes', value: YesNoType.YES },
            { label: 'No', value: YesNoType.NO },
          ]}
          inlineOptions
        />
        {needsPayingTransactionCoordinator && (
          <div className='mt-3'>
            <TransactionAttachedFeeSelectionRow
              transaction={transactionDetail!}
              attachedFeeType={
                AttachedFeeValueFeeTypeEnum.TransactionCoordinator
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionSetupAttachedFeeTransactionCoordinatorStep;
