import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import { CellProps } from 'react-table';
import {
  MoneyValue,
  TransactionBuilderResponse,
  TransactionLiteResponse,
  TransactionResponse,
  TransactionResponseCountryEnum,
} from '../../../openapi/arrakis';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { displayDate } from '../../../utils/DateUtils';
import { getTransactionSystemActualClosingDate } from '../../../utils/TransactionHelper';
import {
  getBuyerOrSellers,
  isListingNotActionable,
} from '../../../utils/TransactionUtils';
import ZenViewActionButtonCell from '../../table/Cells/ZenViewActionButtonCell';
import AddressStateEnumSelectColumnFilter from '../../table/Filters/AddressStateEnumSelectColumnFilter';
import DateColumnFilter from '../../table/Filters/DateColumnFilter';
import TextColumnFilter from '../../table/Filters/TextColumnFilter';
import TransactionCompliantToggleColumnFilter from '../../table/Filters/TransactionCompliantToggleColumnFilter';
import { CustomColumn } from '../../Zen/Containers/ZenResourceIndexContainer';
import ZenAgentsInfoPopper from '../../Zen/Transaction/ZenAgentsInfoPopper';
import ListLabelWithValue from '../ListLabelWithValue';
import GeminiAddressCell from '../Table/Cell/GeminiAddressCell';
import GeminiChecklistPopper from '../Table/Cell/GeminiChecklistPopper';
import GeminiCompliancePopover from '../Table/Cell/GeminiCompliancePopover';
import GeminiJourneyPopper from '../Table/Cell/GeminiJourneyPopper';
import GeminiTransactionCodeWithCopy from '../Table/Cell/GeminiTransactionCodeWithCopy';
import GeminiTransactionTypeCell from '../Table/Cell/GeminiTransactionTypeCell';
import GeminiTransactionErrorsPopover from './GeminiTransactionErrorsPopover';
import GeminiTransactionStatus from './GeminiTransactionStatus';
import GeminiTransactionDealTypeLabel from './Header/GeminiTransactionDealTypeLabel';

export const nonAgentTransactionOrListingListColumns = (
  isListing: boolean,
  handleNavigate: (path: string) => void,
): Array<CustomColumn<TransactionResponse>> => {
  const type = isListing ? 'listings' : 'transactions';
  let columns: Array<CustomColumn<TransactionResponse>> = [
    {
      Header: 'Id',
      accessor: 'id',
      Cell: ({ value }) => (
        <ZenViewActionButtonCell
          className='p-1 bg-rezen-blue-600'
          iconClassName='p-1'
          onClick={() =>
            handleNavigate(
              `/${type}/${value}?${qs.stringify({
                showStatusOnboardings: true,
              })}`,
            )
          }
        />
      ),
      position: 'top-left',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      accessor: 'journeyId',
      Cell: ({ value }) => <GeminiJourneyPopper journeyId={value} />,
      position: 'top-left',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      accessor: 'checklistId',
      Cell: ({ value }) => <GeminiChecklistPopper checklistId={value} />,
      position: 'top-left',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: isListing ? 'Listing Code' : 'Transaction Code',
      accessor: 'code',
      Cell: ({ value }) => (
        <GeminiTransactionCodeWithCopy transactionCode={value} />
      ),
      position: 'top-left',
      Filter: TextColumnFilter,
    },
    {
      Header: 'Marked Compliant',
      accessor: 'compliantAt',
      Cell: ({ value }) => (
        <div className='mt-2 md:mt-0'>
          <GeminiCompliancePopover isComplianceVerified={!!value} />
        </div>
      ),
      Filter: TransactionCompliantToggleColumnFilter,
      position: 'top-right',
      disableSortBy: true,
    },
    {
      accessor: 'errors',
      Cell: ({ value }) =>
        !!value?.length ? (
          <GeminiTransactionErrorsPopover errors={value} />
        ) : null,
      position: 'top-right',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Address',
      accessor: 'address',
      id: 'street',
      Cell: ({
        row: { original },
      }: CellProps<TransactionLiteResponse, string>) => (
        <GeminiAddressCell
          address={original.address}
          showInvoice={!isListing}
          invoiceNumber={original.commissionInvoiceNumber}
        />
      ),
      Filter: TextColumnFilter,
      position: 'main',
      disableSortBy: true,
    },
    {
      id: 'transactionOwner',
      accessor: 'transactionOwner',
      Cell: ({ value }) => (
        <ListLabelWithValue
          label={isListing ? 'Listing Owner' : 'Transaction Owner'}
          value={value?.name}
        />
      ),
      position: 'collapsible',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'City',
      accessor: 'address',
      id: 'city',
      Cell: ({ value }) => value?.city,
      Filter: TextColumnFilter,
      disableSortBy: true,
    },
    {
      Header: 'State / Province',
      accessor: 'address',
      id: 'state',
      Cell: ({ value }) => value?.state,
      Filter: AddressStateEnumSelectColumnFilter,
      disableSortBy: true,
    },
    {
      Header: 'Postal Code',
      accessor: 'address',
      id: 'zip',
      Cell: ({ value }) => value?.zip,
      Filter: TextColumnFilter,
      disableSortBy: true,
    },
  ];

  if (isListing) {
    columns.push(
      {
        Header: 'Link',
        accessor: 'inContractTransactionId',
        id: 'link',
        Cell: ({
          row: { original },
        }: CellProps<TransactionLiteResponse, string>) => {
          const inContractTransactionId = original?.inContractTransactionId;
          if (isListingNotActionable(original!) && !!inContractTransactionId) {
            return (
              <button
                className='h-full flex items-center justify-center ml-4'
                onClick={() =>
                  handleNavigate(
                    `/transactions/${inContractTransactionId}/detail`,
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className='text-base text-rezen-blue-600 font-black'
                />
              </button>
            );
          }
          return null;
        },
        disableFilters: true,
        disableSortBy: true,
        position: 'top-right',
      },
      {
        Header: 'List Price',
        accessor: 'price',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='List Price'
            value={
              !!value ? displayAmount(value, { hideCurrency: true }) : 'N/A'
            }
          />
        ),
        position: 'collapsible',
        disableFilters: true,
      },
      {
        Header: 'Listing Date',
        accessor: 'listingDate',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='Listing Date'
            value={!!value ? displayDate(value) : 'N/A'}
          />
        ),
        disableFilters: true,
        position: 'collapsible',
      },
      {
        Header: 'Expiration Date',
        accessor: 'listingExpirationDate',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='Expiration Date'
            value={!!value ? displayDate(value) : 'N/A'}
          />
        ),
        disableFilters: true,
        position: 'collapsible',
      },
      {
        Header: 'Seller',
        accessor: 'participants',
        id: 'seller',
        Cell: ({ row: { original } }) => (
          <ListLabelWithValue
            label='Seller'
            value={getBuyerOrSellers(original.participants, 'SELLER')}
            isExpanded
          />
        ),
        position: 'collapsible',
        disableFilters: true,
        disableSortBy: true,
      },
    );
  }

  if (!isListing) {
    columns.push(
      {
        Header: 'Closing Date',
        accessor: 'skySlopeEscrowClosingDate',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='Closing Date'
            value={!!value ? displayDate(value) : 'N/A'}
          />
        ),
        Filter: DateColumnFilter,
        position: 'collapsible',
      },
      {
        Header: 'Sale Price',
        accessor: 'price',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='Sale price'
            value={
              !!value ? displayAmount(value, { hideCurrency: true }) : 'N/A'
            }
          />
        ),
        position: 'collapsible',
        disableFilters: true,
      },
      {
        Header: 'Buyer',
        accessor: 'participants',
        id: 'buyer',
        Cell: ({ row: { original, isExpanded } }) => (
          <ListLabelWithValue
            label='Buyer'
            value={getBuyerOrSellers(original.participants)}
            isExpanded={isExpanded}
          />
        ),
        position: 'collapsible',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Seller',
        accessor: 'participants',
        id: 'seller',
        Cell: ({ row: { original, isExpanded } }) => (
          <ListLabelWithValue
            label='Seller'
            value={getBuyerOrSellers(original.participants, 'SELLER')}
            isExpanded={isExpanded}
          />
        ),
        position: 'collapsible',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: 'grossCommission',
        id: 'myNetPayout',
        Cell: ({ row: { original, isExpanded } }) => {
          return (
            <ListLabelWithValue
              label='Monies'
              value={[
                <div key={original.grossCommission?.amount}>
                  <span className='font-normal leading-5'>Gross Comm:</span>{' '}
                  {!!original.grossCommission
                    ? displayAmount(original.grossCommission, {
                        hideCurrency: true,
                      })
                    : 'N/A'}
                </div>,
                <div key='totalNetPayout'>
                  {/* TODO: Add total net payout */}
                  <span className='font-normal leading-5'>
                    Total Net Pay:
                  </span>{' '}
                  N/A
                </div>,
              ]}
              isExpanded={isExpanded}
            />
          );
        },
        position: 'collapsible',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'System Closing Date',
        accessor: 'rezenClosedAt',
        Cell: ({ row: { original }, value }) => (
          <ListLabelWithValue
            label='System Closing Date'
            value={
              !!value
                ? getTransactionSystemActualClosingDate(
                    original,
                    'MMM dd, yyyy',
                  )
                : 'N/A'
            }
          />
        ),
        Filter: DateColumnFilter,
        position: 'collapsible',
      },
    );
  }

  columns.push({
    accessor: 'participants',
    id: 'participantYentaIds',
    Cell: ({ row: { original } }) => {
      const yentaIds = (original?.participants || [])
        ?.map((v) => v.yentaUserId!)
        .filter(Boolean);

      return (
        <ListLabelWithValue
          label='Agents'
          value={
            <ZenAgentsInfoPopper
              participants={yentaIds || []}
              represents={original.transactionOwner?.represents}
            />
          }
        />
      );
    },
    position: 'collapsible',
    disableFilters: true,
    disableSortBy: true,
  });

  return columns;
};

export const agentTransactionOrListingListColumns = (
  isListing: boolean,
  handleNavigate: (path: string) => void,
): Array<CustomColumn<TransactionLiteResponse>> => {
  let columns: Array<CustomColumn<TransactionLiteResponse>> = [
    {
      Header: 'Id',
      accessor: 'id',
      Cell: ({ value }) => (
        <ZenViewActionButtonCell
          className='p-1 bg-rezen-blue-600'
          iconClassName='p-1'
          onClick={() =>
            handleNavigate(
              `/${
                isListing ? 'listings' : 'transactions'
              }/${value}?${qs.stringify({
                showStatusOnboardings: true,
              })}`,
            )
          }
        />
      ),
      position: 'top-left',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Type',
      accessor: 'transactionType',
      Cell: ({ value }) => (
        <GeminiTransactionTypeCell dealType={value!} noBackground />
      ),
      position: 'top-left',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      accessor: 'journeyId',
      Cell: ({ value }) => <GeminiJourneyPopper journeyId={value} />,
      position: 'top-left',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      accessor: 'checklistId',
      Cell: ({ value }) => <GeminiChecklistPopper checklistId={value} />,
      position: 'top-left',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Status',
      id: 'lifecycleState',
      accessor: ({ lifecycleState }) => lifecycleState?.state,
      Cell: ({
        value,
        row: { original },
      }: React.PropsWithChildren<CellProps<TransactionLiteResponse>>) => (
        <GeminiTransactionStatus
          status={value}
          country={
            (original.country as unknown) as TransactionResponseCountryEnum
          }
        />
      ),
      position: 'top-left',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Code',
      accessor: 'code',
      Cell: ({ value }) => (
        <GeminiTransactionCodeWithCopy transactionCode={value} />
      ),
      position: 'top-left',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Marked Compliant',
      accessor: 'compliantAt',
      Cell: ({ value }) => (
        <div className='mt-2 md:mt-0'>
          <GeminiCompliancePopover isComplianceVerified={!!value} />
        </div>
      ),
      position: 'top-right',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      accessor: 'errors',
      Cell: ({ value }) =>
        !!value?.length ? (
          <div className='ml-1'>
            <GeminiTransactionErrorsPopover errors={value} />
          </div>
        ) : null,
      position: 'top-right',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Address',
      id: 'address',
      accessor: (d) => d['address']?.street,
      Cell: ({
        row: { original },
      }: CellProps<TransactionLiteResponse, string>) => (
        <GeminiAddressCell
          address={original.address}
          showInvoice={!isListing}
          invoiceNumber={original.commissionInvoiceNumber}
        />
      ),
      position: 'main',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      id: 'transactionOwner',
      accessor: 'transactionOwner',
      Cell: ({ value }) => (
        <ListLabelWithValue
          label={isListing ? 'Listing Owner' : 'Transaction Owner'}
          value={value?.name}
        />
      ),
      position: 'collapsible',
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  if (isListing) {
    columns.push(
      {
        Header: 'Link',
        accessor: 'inContractTransactionId',
        id: 'link',
        Cell: ({
          row: { original },
        }: CellProps<TransactionLiteResponse, string>) => {
          const { inContractTransactionId } = original;

          if (isListingNotActionable(original!) && !!inContractTransactionId) {
            return (
              <button
                onClick={() =>
                  handleNavigate(
                    `/transactions/${inContractTransactionId}/detail`,
                  )
                }
                className='h-full flex items-center justify-center ml-4'
              >
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className='text-base text-rezen-blue-600 font-black'
                />
              </button>
            );
          }
          return null;
        },
        disableFilters: true,
        disableSortBy: true,
        position: 'top-right',
      },
      {
        Header: 'List Price',
        accessor: 'price',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='List Price'
            value={
              !!value ? displayAmount(value, { hideCurrency: true }) : 'N/A'
            }
          />
        ),
        position: 'collapsible',
        disableFilters: true,
      },
      {
        Header: 'Listing Date',
        accessor: 'listingDate',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='Listing Date'
            value={!!value ? displayDate(value) : 'N/A'}
          />
        ),
        disableFilters: true,
        position: 'collapsible',
      },
      {
        Header: 'Expiration Date',
        accessor: 'listingExpirationDate',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='Expiration Date'
            value={!!value ? displayDate(value) : 'N/A'}
          />
        ),
        disableFilters: true,
        position: 'collapsible',
      },
      {
        Header: 'Seller',
        accessor: 'participants',
        id: 'seller',
        Cell: ({ row: { original } }) => (
          <ListLabelWithValue
            label='Seller Name'
            value={getBuyerOrSellers(original.participants, 'SELLER')}
            isExpanded
          />
        ),
        position: 'collapsible',
        disableFilters: true,
        disableSortBy: true,
      },
    );
  }

  if (!isListing) {
    columns.push(
      {
        Header: 'Closing Date',
        accessor: 'skySlopeEscrowClosingDate',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='Closing Date'
            value={!!value ? displayDate(value) : 'N/A'}
          />
        ),
        position: 'collapsible',
        disableFilters: true,
      },
      {
        Header: 'Sale Price',
        accessor: 'price',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='Sale price'
            value={
              !!value ? displayAmount(value, { hideCurrency: true }) : 'N/A'
            }
          />
        ),
        position: 'collapsible',
        disableFilters: true,
      },
      {
        Header: 'Buyer',
        accessor: 'participants',
        id: 'buyer',
        Cell: ({ row: { original, isExpanded } }) => (
          <ListLabelWithValue
            label='Buyer'
            value={getBuyerOrSellers(original.participants)}
            isExpanded={isExpanded}
          />
        ),
        position: 'collapsible',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Seller',
        accessor: 'participants',
        id: 'seller',
        Cell: ({ row: { original, isExpanded } }) => (
          <ListLabelWithValue
            label='Seller'
            value={getBuyerOrSellers(original.participants, 'SELLER')}
            isExpanded={isExpanded}
          />
        ),
        position: 'collapsible',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: 'myNetPayout',
        Cell: ({ row: { original, isExpanded } }) => {
          return (
            <ListLabelWithValue
              label='Monies'
              value={
                ([
                  <div key={original.grossCommission?.amount}>
                    <span className='font-normal leading-5'>Gross Comm:</span>{' '}
                    {!!original.grossCommission
                      ? displayAmount(original.grossCommission, {
                          hideCurrency: true,
                        })
                      : 'N/A'}
                  </div>,
                  <div key={original.myNetPayout?.amount}>
                    <span className='font-normal leading-5'>My Net Pay:</span>{' '}
                    {!!original.myNetPayout
                      ? displayAmount(original.myNetPayout, {
                          hideCurrency: true,
                        })
                      : 'N/A'}
                  </div>,
                ] as unknown) as string[]
              }
              isExpanded={isExpanded}
            />
          );
        },
        position: 'collapsible',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'System Closing Date',
        accessor: 'rezenClosedAt',
        Cell: ({ row: { original }, value }) => (
          <ListLabelWithValue
            label='System Closing Date'
            value={
              !!value
                ? getTransactionSystemActualClosingDate(
                    original,
                    'MMM dd, yyyy',
                  )
                : 'N/A'
            }
          />
        ),
        disableFilters: true,
        position: 'collapsible',
      },
    );
  }

  columns.push({
    accessor: 'participantYentaIds',
    Cell: ({ value, row: { original } }) => (
      <ListLabelWithValue
        label='Agents'
        value={
          <ZenAgentsInfoPopper
            participants={value?.filter((v) => !!v) || []}
            represents={original.transactionOwner?.represents}
          />
        }
      />
    ),
    position: 'collapsible',
    disableFilters: true,
    disableSortBy: true,
  });

  return columns;
};

export const agentTransactionOrListingListDraftColumns = (
  isListing: boolean,
): Array<CustomColumn<TransactionBuilderResponse>> => {
  const columns: Array<CustomColumn<TransactionBuilderResponse>> = [
    {
      Header: 'Type',
      accessor: 'dealType',
      Cell: ({ value }) =>
        value ? (
          <GeminiTransactionDealTypeLabel dealType={value as any} />
        ) : (
          '--'
        ),
      position: 'top-left',
    },
    {
      accessor: 'journeyId',
      Cell: ({ value }) => <GeminiJourneyPopper journeyId={value} />,
      position: 'top-left',
    },
    {
      accessor: 'checklistId',
      Cell: ({ value }) => <GeminiChecklistPopper checklistId={value} />,
      position: 'top-left',
    },
    {
      Header: 'Address',
      accessor: (d) => d['address']?.street,
      Cell: ({
        row: { original },
      }: CellProps<TransactionBuilderResponse, string>) => (
        <GeminiAddressCell
          address={original.address}
          placeholder={<p className='text-gray-500'>No Address</p>}
          showInvoice={false}
        />
      ),
      position: 'main',
    },
    {
      accessor: 'salePrice',
      Cell: ({ value }) => (
        <ListLabelWithValue
          label='Price'
          value={
            !!value
              ? displayAmount(value as MoneyValue, {
                  hideCurrency: true,
                })
              : 'N/A'
          }
        />
      ),
      position: 'collapsible',
    },
    {
      accessor: 'sellers',
      Cell: ({ value, row: { isExpanded } }) => {
        const sellers = value?.filter((v) => !!v.firstName && !!v.lastName);

        return (
          <ListLabelWithValue
            label='Seller'
            value={
              !!sellers?.length
                ? sellers.map((v) => `${v.firstName} ${v.lastName}`)
                : 'N/A'
            }
            isExpanded={isExpanded}
          />
        );
      },
      position: 'collapsible',
    },
  ];

  if (isListing) {
    columns.push(
      {
        Header: 'Listing Date',
        accessor: 'listingDate',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='List Date'
            value={!!value ? displayDate(value) : 'N/A'}
          />
        ),
        disableFilters: true,
        disableSortBy: true,
        position: 'collapsible',
      },
      {
        Header: 'Expiration Date',
        accessor: 'listingExpirationDate',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='Expiration Date'
            value={!!value ? displayDate(value) : 'N/A'}
          />
        ),
        disableFilters: true,
        disableSortBy: true,
        position: 'collapsible',
      },
    );
  }

  if (!isListing) {
    columns.push(
      {
        Header: 'Closing Date',
        accessor: 'estimatedClosingDate',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='Closing Date'
            value={!!value ? displayDate(value) : 'N/A'}
          />
        ),
        position: 'collapsible',
      },
      {
        accessor: 'buyers',
        Cell: ({ value, row: { isExpanded } }) => {
          const buyers = value?.filter((v) => !!v.firstName && !!v.lastName);

          return (
            <ListLabelWithValue
              label='Buyer'
              value={
                !!buyers?.length
                  ? buyers.map((v) => `${v.firstName} ${v.lastName}`)
                  : 'N/A'
              }
              isExpanded={isExpanded}
            />
          );
        },
        position: 'collapsible',
      },
      {
        accessor: 'grossCommission',
        Cell: ({ value, row: { isExpanded } }) => {
          return (
            <ListLabelWithValue
              label='Monies'
              value={
                ([
                  <div key={value?.commissionAmount?.amount}>
                    <span className='font-normal leading-5'>Gross Comm:</span>{' '}
                    {!!value?.commissionAmount
                      ? displayAmount(value?.commissionAmount as MoneyValue, {
                          hideCurrency: true,
                        })
                      : 'N/A'}
                  </div>,
                  <div key='Net Payment'>
                    <span className='font-normal leading-5'>My Net Pay:</span>{' '}
                    N/A
                  </div>,
                ] as unknown) as string[]
              }
              isExpanded={isExpanded}
            />
          );
        },
        position: 'collapsible',
      },
      {
        accessor: 'acceptanceDate',
        Cell: ({ value }) => (
          <ListLabelWithValue
            label='Acceptance Date'
            value={!!value ? displayDate(value) : 'N/A'}
          />
        ),
        position: 'collapsible',
      },
    );
  }

  columns.push({
    accessor: 'allCommissionRecipient',
    Cell: ({ value, row: { original } }) => (
      <ListLabelWithValue
        label='Agents'
        value={
          <ZenAgentsInfoPopper
            participants={
              // @ts-ignore
              value?.filter((v) => !!v.agentId).map((v) => v.agentId) || []
            }
            represents={original.agentsInfo?.representationType}
          />
        }
      />
    ),
    position: 'collapsible',
  });

  return columns;
};
