import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faAward,
  faCircle1,
  faCircle2,
} from '@fortawesome/pro-solid-svg-icons';
import { updateWealthGoals } from '../../../slices/WealthManagementSlice';
import { Earning, PrimaryColor } from '../WealthManagementTypes';
import CelebrateBlueEmoji from '../../../assets/img/wealthManagement/celebrate-blue-emoji.png';
import { AnalyticsEventEnum } from '../../../types';
import GiveBack from '../../../assets/img/wealthManagement/give_back.svg';
import CulturalAwardVideo from '../../../assets/img/wealthManagement/cultural-awad-video.png';
import AnalyticsService from '../../../services/AnalyticsService';
import {
  SUPPORT_EMAIL_ADDRESS,
  CANADA_SUPPORT_EMAIL_ADDRESS,
} from '../../../utils/SupportUtils';
import { useIsUSAgent } from '../USOnly';
import { ZenPrimaryGradientText } from './ZenPrimaryGradientText';
import { PrimaryGradientText } from './PrimaryGradientText';
import { LearnMoreCarousel } from './LearnMoreCarousel';
import AgreementDetails from './AgreementDetails';
import ZenEliteStatus from './ZenEliteStatus';
import ZenLandingPageIntro from './ZenLandingPageIntro';

const Header = () => {
  return (
    <ZenLandingPageIntro
      buttonProps={{
        earningType: Earning.ELITE_CULTURAL_AWARD,
      }}
      earningType={Earning.ELITE_CULTURAL_AWARD}
      showAddToMyPlan
      sloganImage={GiveBack}
      subtitle='Let Real reward you for sharing and collaborating'
    />
  );
};

const UnlockedEliteStatus = () => {
  return (
    <ZenEliteStatus
      highlightProps={{
        label: 'you can earn:',
        textToHighlight: 'earn',
        highlightColor: PrimaryColor.ELITE_PRODUCTION_AWARD,
      }}
      stockProps={{
        amount: '8K',
        label: 'more in stock',
      }}
    />
  );
};

const HowToAchieve = () => {
  const isUSAgent = useIsUSAgent();

  return (
    <div className='w-full mt-10 p-5 bg-black py-20 flex flex-col justify-center items-center'>
      <div className='flex flex-col justify-center items-center bg-black pt-5 mb-10'>
        <div className='text-3xl md:text-[42px] font-bold text-white w-full text-center mb-5 font-zen-primary-regular'>
          <div className='mb-2'>How do I achieve the</div>
          <div>
            <ZenPrimaryGradientText>
              Elite Cultural Award?
            </ZenPrimaryGradientText>
          </div>
        </div>
        <div className='text-white md:w-3/4 text-center mb-5 font-zen-body-2 font-light text-xl md:text-2xl'>
          Achieve our Cultural Award by meeting{' '}
          <span className='font-bold'>ONE</span> of these two requirements:
        </div>
      </div>
      <div className='md:w-4/6 bg-blue-100 rounded-tr-lg rounded-tl-lg p-5 pb-10 relative font-zen-body-2'>
        <div className='border-2 border-lightBlue-400 absolute top-0 right-1/2 transform translate-x-1/2 -translate-y-1/2 bg-lightBlue-50 w-16 h-16 rounded-full flex justify-center items-center'>
          <FontAwesomeIcon
            icon={faAward}
            size='2x'
            className='text-lightBlue-400'
          />
        </div>
        <div className='flex gap-3 mt-8'>
          <FontAwesomeIcon icon={faCircle1} className='text-black' size='xl' />
          <div>
            <div className='text-black font-bold'>
              Teach in the Real Academy
            </div>
            <div>
              Give back to the entire agent community by teaching monthly topics
              at the Real Academy.*
            </div>
            <span className='text-primary-blue cursor-pointer flex items-center gap-1'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.real.academy/start'
                onClick={() => {
                  AnalyticsService.instance().logEvent(
                    AnalyticsEventEnum.WEALTH_PLAN_LP_ELITE_CULTURAL_AWARD_CLICK_ON_APPLY_NOW,
                  );
                }}
              >
                Real Academy Link
              </a>
              <FontAwesomeIcon icon={faArrowRight} size='xs' />
            </span>
            <div className='flex justify-end w-full'>
              <img
                src={CulturalAwardVideo}
                alt='Cultural Award Video'
                className='w-2/3'
              />
            </div>
          </div>
        </div>
        <div className='my-3 text-zen-dark-12 font-bold flex flex-row before:border-b-gray-400 before:flex-1 before:border-b before:m-auto before:mr-10 after:flex-1 after:border-b after:m-auto after:ml-10 after:border-b-gray-400'>
          OR
        </div>
        <div className='flex gap-3'>
          <FontAwesomeIcon icon={faCircle2} className='text-black' size='xl' />
          <div>
            <div className='text-black font-bold'>
              Give back to your local community
            </div>
            <div>Meet cultural contribution criteria as defined by Real.</div>
          </div>
        </div>
      </div>
      <div className='relative w-full md:w-4/6 flex justify-center items-center font-zen-body-2 bg-blue-200 rounded-bl-lg rounded-br-lg'>
        <div className='flex gap-1 absolute top-0 left-1/2 font-bold transform -translate-x-1/2 -translate-y-5 bg-blue-200 px-3 py-1 rounded-tr-lg rounded-tl-lg text-black'>
          <img
            src={CelebrateBlueEmoji}
            alt='celebrate emoji'
            className='h-4 w-4'
          />
          Congrats!
        </div>
        <div className='md:w-2/3 text-center text-black my-3 font-bold'>
          <div>
            You’ve Unlocked our{' '}
            <PrimaryGradientText>Cultural Award</PrimaryGradientText>
          </div>
          <div>and are eligible for $8K more in stock</div>
        </div>
      </div>
      <div className='md:w-4/6 px-5 text-white text-center py-3 italic'>
        <div>*Courses and services require prior approval.</div>
        <div>All qualifiers must be met in an anniversary year.</div>
        <a
          href={`mailto:${
            isUSAgent ? SUPPORT_EMAIL_ADDRESS : CANADA_SUPPORT_EMAIL_ADDRESS
          }`}
          className='text-primary-blue'
        >
          Apply Now
        </a>
      </div>
    </div>
  );
};

export const ZenEliteAgentCulturalAwardKnowledgePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateWealthGoals({
        seenEliteCulturalPage: true,
      }),
    );
  }, [dispatch]);

  return (
    <div>
      <Header />
      <UnlockedEliteStatus />
      <HowToAchieve />
      <AgreementDetails />
      <LearnMoreCarousel />
    </div>
  );
};
