import { EnumMap, PartialEnumMap } from '../types';

export const safeEnumMapGet = <T extends string, R>(
  map: EnumMap<T, R>,
  key: T,
  defaultValue: R,
): R => {
  return map[key] ?? defaultValue;
};

export const safePartialEnumMapGet = <T extends string, R>(
  map: PartialEnumMap<T, R>,
  key: T,
  defaultValue: R,
): R => {
  return map[key] ?? defaultValue;
};
