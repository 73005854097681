import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import {
  faDollar,
  faWavePulse,
  faCrown,
  faAward,
  faArrowTrendUp,
  faMagnet,
} from '@fortawesome/pro-regular-svg-icons';
import { faChartMixed } from '@fortawesome/pro-solid-svg-icons';

export const Earning = {
  COMMISSION_INCOME: 'COMMISSION_INCOME',
  PRE_CAP_SPP: 'PRE_CAP_SPP',
  STOCK_AWARD_FOR_CAPPING: 'STOCK_AWARD_FOR_CAPPING',
  POST_CAP_SPP: 'POST_CAP_SPP',
  ELITE_PRODUCTION_AWARD: 'ELITE_PRODUCTION_AWARD',
  ELITE_CULTURAL_AWARD: 'ELITE_CULTURAL_AWARD',
  REVENUE_SHARE_INCOME: 'REVENUE_SHARE_INCOME',
  STOCK_AWARD_FOR_ATTRACTING: 'STOCK_AWARD_FOR_ATTRACTING',
} as const;
export type EarningType = typeof Earning[keyof typeof Earning];

export const PrimaryColor: Record<EarningType, string> = {
  COMMISSION_INCOME: '#8ABCF9',
  PRE_CAP_SPP: '#8C51D7',
  STOCK_AWARD_FOR_CAPPING: '#499CCB',
  POST_CAP_SPP: '#439775',
  ELITE_PRODUCTION_AWARD: '#CA895F',
  ELITE_CULTURAL_AWARD: '#58C0F4',
  REVENUE_SHARE_INCOME: '#6A1AB2',
  STOCK_AWARD_FOR_ATTRACTING: '#547793',
};

export const SecondaryColor: Record<EarningType, string> = {
  COMMISSION_INCOME: '#C5DEFC',
  PRE_CAP_SPP: '#C6A8EB',
  STOCK_AWARD_FOR_CAPPING: '#A4CEE5',
  POST_CAP_SPP: '#A1CBBA',
  ELITE_PRODUCTION_AWARD: '#E5C4AF',
  ELITE_CULTURAL_AWARD: '#ACE0FA',
  REVENUE_SHARE_INCOME: '#B58DD9',
  STOCK_AWARD_FOR_ATTRACTING: '#AABBC9',
};

export const TertiaryColor: Record<EarningType, string> = {
  COMMISSION_INCOME: '#E8F2FE',
  PRE_CAP_SPP: '#E8DCF7',
  STOCK_AWARD_FOR_CAPPING: '#DBEBF5',
  POST_CAP_SPP: '#D9EAE3',
  ELITE_PRODUCTION_AWARD: '#F4E7DF',
  ELITE_CULTURAL_AWARD: '#DEF2FD',
  REVENUE_SHARE_INCOME: '#E1D1F0',
  STOCK_AWARD_FOR_ATTRACTING: '#DDE4E9',
};

export const EarningIcon: Record<EarningType, FontAwesomeIconProps['icon']> = {
  COMMISSION_INCOME: faDollar,
  PRE_CAP_SPP: faChartMixed,
  STOCK_AWARD_FOR_CAPPING: faWavePulse,
  POST_CAP_SPP: faChartMixed,
  ELITE_PRODUCTION_AWARD: faCrown,
  ELITE_CULTURAL_AWARD: faAward,
  REVENUE_SHARE_INCOME: faArrowTrendUp,
  STOCK_AWARD_FOR_ATTRACTING: faMagnet,
};

export const LearnMoreBackground: Record<EarningType, string> = {
  COMMISSION_INCOME: '#F3F8FE',
  PRE_CAP_SPP: '#F4EEFB',
  STOCK_AWARD_FOR_CAPPING: '#EDF5FA',
  POST_CAP_SPP: '#ECF5F1',
  ELITE_PRODUCTION_AWARD: '#FAF3EF',
  ELITE_CULTURAL_AWARD: '#EEF9FE',
  REVENUE_SHARE_INCOME: '#F0E8F7',
  STOCK_AWARD_FOR_ATTRACTING: '#EEF1F4',
};

export const LearnMoreSmallBackground: Record<EarningType, string> = {
  COMMISSION_INCOME: '#D0E4FD',
  PRE_CAP_SPP: '#DDCBF3',
  STOCK_AWARD_FOR_CAPPING: '#C8E1EF',
  POST_CAP_SPP: '#C7E0D6',
  ELITE_PRODUCTION_AWARD: '#EFDCCF',
  ELITE_CULTURAL_AWARD: '#CDECFC',
  REVENUE_SHARE_INCOME: '#D2BAE8',
  STOCK_AWARD_FOR_ATTRACTING: '#CCD6DF',
};

export const EarningTitle: Record<EarningType, string> = {
  COMMISSION_INCOME: 'Commission Income',
  PRE_CAP_SPP: 'Pre-cap SPP + Bonus',
  STOCK_AWARD_FOR_CAPPING: 'Stock Award for Capping',
  POST_CAP_SPP: 'Post-cap SPP + Bonus',
  ELITE_PRODUCTION_AWARD: 'Elite Agent Production Award',
  ELITE_CULTURAL_AWARD: 'Elite Agent Cultural Award',
  REVENUE_SHARE_INCOME: 'Revenue Share Income',
  STOCK_AWARD_FOR_ATTRACTING: 'Stock Award for Attracting',
};

export const EarningNumber: Record<EarningType, number> = {
  COMMISSION_INCOME: 1,
  PRE_CAP_SPP: 2,
  STOCK_AWARD_FOR_CAPPING: 3,
  POST_CAP_SPP: 4,
  ELITE_PRODUCTION_AWARD: 5,
  ELITE_CULTURAL_AWARD: 6,
  REVENUE_SHARE_INCOME: 7,
  STOCK_AWARD_FOR_ATTRACTING: 8,
};

export enum WealthPlanEventEnum {
  ATTRACTION_ACCELERATOR = 'ATTRACTION_ACCELERATOR',
  SALES_ACCELERATOR = 'SALES_ACCELERATOR',
}

export const WealthPlanCalenderMap: Record<
  EarningType,
  WealthPlanEventEnum | undefined
> = {
  COMMISSION_INCOME: WealthPlanEventEnum.SALES_ACCELERATOR,
  PRE_CAP_SPP: WealthPlanEventEnum.SALES_ACCELERATOR,
  STOCK_AWARD_FOR_CAPPING: WealthPlanEventEnum.SALES_ACCELERATOR,
  POST_CAP_SPP: WealthPlanEventEnum.SALES_ACCELERATOR,
  ELITE_PRODUCTION_AWARD: WealthPlanEventEnum.SALES_ACCELERATOR,
  ELITE_CULTURAL_AWARD: WealthPlanEventEnum.SALES_ACCELERATOR,
  REVENUE_SHARE_INCOME: WealthPlanEventEnum.ATTRACTION_ACCELERATOR,
  STOCK_AWARD_FOR_ATTRACTING: WealthPlanEventEnum.ATTRACTION_ACCELERATOR,
};
