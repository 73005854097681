import { formatPhoneNumber } from '../utils/StringUtils';

interface DataRowDetailsProps {
  title?: string;
  name: string;
  phoneNumber: string;
  address: string;
  emailAddress: string;
  gstNumber?: string;
}

const DataRowDetails: React.FC<DataRowDetailsProps> = ({
  title,
  address,
  emailAddress,
  name,
  phoneNumber,
  gstNumber,
}) => {
  return (
    <div>
      {!!title && (
        <p className='uppercase font-primary-bold text-gray-700 text-xs tracking-wider'>
          {title}
        </p>
      )}
      <div className='text-xs font-thin text-gray-600 space-y-1 tracking-wider print:tracking-normal'>
        <div className='flex flex-row space-x-1'>
          <p>{name}</p>
          <p>&bull;</p>
          <p>{formatPhoneNumber(phoneNumber)}</p>
        </div>
        <div className='flex flex-col space-y-1'>
          <p className='break-all'>{address}</p>
          <p className='break-all font-primary-regular'>{emailAddress}</p>
        </div>
        {gstNumber && (
          <div>
            <p>GST: {gstNumber}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataRowDetails;
