import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSignAgentAgreement } from '../../../slices/AgentSlice';
import { RootState } from '../../../types';
import { hasPastAgreementRequiredDate } from '../../../utils/AgreementUtils';
import AgentSigningICAModal from '../../Zen/Modal/AgentSigningICAModal';

interface AgentICADocumentSignatureModalIndexProps {}

const AgentICADocumentSignatureModalIndex: React.FC<AgentICADocumentSignatureModalIndexProps> = () => {
  const dispatch = useDispatch();
  const {
    auth: { isSuperAdmin, isAdmin, isBroker },
    agentDetail: { signAgentAgreement, agentNonSignedAgreementsResponse },
  } = useSelector((state: RootState) => state);
  const isAgent = !isSuperAdmin && !isAdmin && !isBroker;
  const hasPastRequiredDate = hasPastAgreementRequiredDate(
    agentNonSignedAgreementsResponse?.requiredAt!,
  );

  if (hasPastRequiredDate || !isAgent || !signAgentAgreement) {
    return null;
  }

  return (
    <AgentSigningICAModal
      isOpen
      onClose={async () => {
        await dispatch(saveSignAgentAgreement(false));
      }}
    />
  );
};

export default AgentICADocumentSignatureModalIndex;
