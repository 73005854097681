import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BreadCrumbsNoUrlType } from '../../types';
import { cn } from '../../utils/classUtils';

export interface BreadCrumbsNoUrlProps {
  paths: BreadCrumbsNoUrlType[];
}

const BreadCrumbsWithActions: React.FC<BreadCrumbsNoUrlProps> = ({ paths }) => {
  const itemsLength = paths.length;

  return (
    <div className='flex flex-row scrollbar overflow-x-auto px-4 py-3'>
      {paths.map((item, index) => (
        <div
          key={item.title}
          className='flex flex-row items-center text-center'
        >
          <p
            className={cn(
              'whitespace-nowrap max-w-2 truncate lg:max-w-none cursor-pointer font-primary-medium text-base',
              itemsLength - 1 === index && 'text-gray-400',
            )}
            onClick={item.onClick}
          >
            {item.title}
          </p>
          {itemsLength - 1 !== index && (
            <FontAwesomeIcon
              icon={faChevronRight}
              size='sm'
              className='text-gray-400 text-center'
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default BreadCrumbsWithActions;
