import { FormFieldConfig } from '../../../types';

interface WelcomeFieldProps extends FormFieldConfig {
  fields: React.ReactElement;
}

const WelcomeField: React.FC<WelcomeFieldProps> = ({
  label,
  secondaryLabel,
  helperText,
  rules,
  fields,
}) => {
  return (
    <div className='pb-1'>
      {label && (
        <p className='text-lg font-primary-medium m1-2 mt-2'>{label}</p>
      )}
      {typeof secondaryLabel === 'string' ? (
        <p className='mb-3'>{secondaryLabel}</p>
      ) : (
        secondaryLabel
      )}
      {helperText && (
        <p className='text-gray-500 text-sm mb-1'>
          {helperText}
          {rules?.required ? '*' : ' (optional)'}:
        </p>
      )}
      {fields}
    </div>
  );
};

export default WelcomeField;
