import { QRCodeSVG } from 'qrcode.react';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  EnableMfaRequestMfaTypeEnum,
  MfaControllerApi,
} from '../../../openapi/keymaker';
import ErrorService from '../../../services/ErrorService';
import { fetchKeymakerCurrentUser, mfaSignIn } from '../../../slices/AuthSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { getApiErrorMessage } from '../../../utils/ErrorUtils';
import { getKeymakerConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import StepContentInput from '../../agentWebsiteOnboarding/StepContentInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../../Zen/ZenSidebarModalForm';

interface FormData {
  code: number;
}

interface AgentAuthenticatorAppAuthenticationMethodSideBarModalFormProps {
  isOpen: boolean;
  onClose(): void;
  setEnableTwoFactorAuthentication(enable: boolean): void;
  isForceMfa?: boolean;
}

const AgentAuthenticatorAppAuthenticationMethodSideBarModalForm: React.FC<AgentAuthenticatorAppAuthenticationMethodSideBarModalFormProps> = ({
  isOpen,
  onClose,
  setEnableTwoFactorAuthentication,
  isForceMfa,
}) => {
  const dispatch = useDispatch();
  const [QRcode, setQRcode] = useState<string>();
  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const fetchQRCode = useCallback(async () => {
    try {
      const { data } = await new MfaControllerApi(
        getKeymakerConfiguration(),
      ).getAuthenticatorQrCode();
      setQRcode(data);
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify(
        'Unable to fetch two factor authentication qr code',
        e,
      );
      dispatch(
        showErrorToast(
          'We had a problem fetching last two factor authentication qr code',
          'Please try again in a few moments.',
        ),
      );
    }
  }, [dispatch]);

  useEffect(() => {
    fetchQRCode();
  }, [fetchQRCode]);

  const onSubmit = async (values: FormData) => {
    try {
      await new MfaControllerApi(getKeymakerConfiguration()).enableMfa({
        code: values.code,
        mfaType: EnableMfaRequestMfaTypeEnum.Authenticator,
      });
      if (isForceMfa) {
        await dispatch(mfaSignIn(values.code));
      }
      await dispatch(fetchKeymakerCurrentUser());
      setEnableTwoFactorAuthentication(false);
      onClose();
      dispatch(
        showSuccessToast('Successfully enabled two factor authentication.'),
      );
    } catch (e) {
      setError('code', {
        message: getApiErrorMessage(e),
      });
      ErrorService.notify('Unable to enable two factor authentication', e, {
        data: {
          code: values.code,
          mfaType: EnableMfaRequestMfaTypeEnum.Authenticator,
        },
      });
    }
  };

  return (
    <ZenSidebarModalForm
      isOpen={isOpen}
      onClose={onClose}
      isSubmitting={isSubmitting}
      title='Two-Factor Authentication: Authenticator App'
      subtitle='Scan QR code to link authenticator app with Rezen and get a code for verification.'
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Verify Code'
      cancelTitle='Back'
    >
      <div>
        <StepContentInput step={1}>
          <p className='font-zen-body font-normal text-base'>
            Scan the QR code below
          </p>
        </StepContentInput>
        <p className='font-zen-body font-normal my-3 text-sm'>
          On your authenticator app, add an account to select “Scan a QR code”
        </p>
        <div className='flex flex-row justify-center'>
          <QRCodeSVG value={QRcode!} size={200} />,
        </div>
        <div className='mt-10'>
          <StepContentInput step={2}>
            <p className='font-zen-body font-normal text-base'>
              Enter authentication code
            </p>
          </StepContentInput>
          <p className='font-zen-body font-normal my-3 text-sm'>
            Enter the code shown on the authenticator app
          </p>
          <ZenControlledTextInput<FormData, 'code'>
            control={control}
            name='code'
            type='number'
            placeholder='123456'
            rules={{
              required: 'Please enter authentication code',
              minLength: {
                value: 6,
                message: 'Must have 6 digit number',
              },
              maxLength: {
                value: 6,
                message: 'Must have 6 digit number',
              },
            }}
          />
        </div>
      </div>
    </ZenSidebarModalForm>
  );
};

export default AgentAuthenticatorAppAuthenticationMethodSideBarModalForm;
