import React from 'react';
import { BreadCrumbsPath } from '../../types';
import { isSmScreen } from '../../utils/BrowserUtils';
import ZenPageLayout from './ZenPageLayout';
import ZenSearchBar from './ZenSearchBar';

interface ZenPageLayoutWithSearchProps {
  path: BreadCrumbsPath[];
  search?: string;
  stickyHeader?: boolean;
  headerRef?: React.RefObject<HTMLDivElement>;
  onSearchChange(search: string | undefined): void;
}

const ZenPageLayoutWithSearch: React.FC<ZenPageLayoutWithSearchProps> = ({
  path,
  search,
  stickyHeader,
  headerRef,
  onSearchChange,
  children,
}) => {
  return (
    <ZenPageLayout
      path={path}
      stickyHeader={stickyHeader}
      headerRef={headerRef}
      RightComponent={
        !isSmScreen() ? (
          <div className='w-80 ml-auto hidden md:block'>
            <ZenSearchBar
              onChange={(text) => onSearchChange(text || undefined)}
              value={search || ''}
            />
          </div>
        ) : undefined
      }
    >
      {children}
    </ZenPageLayout>
  );
};

export default ZenPageLayoutWithSearch;
