import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  TransactionControllerApi,
  TransactionLifecycleStateValue,
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import {
  CommentControllerApi,
  PostCommentBodyReaderRoleEnum,
  RezenObjectTypeEnum,
} from '../../../openapi/yada';
import ErrorService from '../../../services/ErrorService';
import { fetchMentionableOfficeGroups } from '../../../slices/OfficeSlice';
import { setActiveTransition } from '../../../slices/QuickActionSlice';
import { showErrorToastForErrorCode } from '../../../slices/ToastNotificationSlice';
import { saveTransactionDetailResponse } from '../../../slices/TransactionSlice';
import { AppDispatch, KBarActionEnum, RootState } from '../../../types';
import {
  getArrakisConfiguration,
  getYadaConfiguration,
} from '../../../utils/OpenapiConfigurationUtils';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSimpleConfirmationModal from '../Modal/ZenSimpleConfirmationModal';
import { useNavigateIfNotCurrentRoute } from '../../../hooks/useNavigateIfNotCurrentRoute';
import ZenConfirmationModal from './ZenConfirmationModal';

interface TerminateTransactionConfirmationModalProps {
  transaction: TransactionResponse;
  onClose(): void;
  secondaryTransitions: TransactionLifecycleStateValue[];
  setShowTerminationTransactionModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  showTerminationTransactionModal: boolean;
}

interface FormData {
  terminationReason: string;
}
const TerminateTransactionConfirmationModal: React.FC<TerminateTransactionConfirmationModalProps> = ({
  transaction,
  onClose,
  secondaryTransitions,
  setShowTerminationTransactionModal,
  showTerminationTransactionModal,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigateIfNotCurrentRoute = useNavigateIfNotCurrentRoute();
  const isListing = transaction.listing;
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();
  const {
    office: { officeDetailById },
    auth: { isBroker, isAdmin },
    kBar: { sidebarToOpen },
  } = useSelector((state: RootState) => state);
  const officeDetails = officeDetailById[transaction?.office?.id!];
  const [showNotifyConfirmation, setShowNotifyConfirmation] = useState(false);

  const preActions = useCallback(() => {
    navigateIfNotCurrentRoute(
      isListing
        ? `/listings/${transaction.id}/detail`
        : `/transactions/${transaction.id}/detail`,
    );
  }, [navigateIfNotCurrentRoute, isListing, transaction.id]);

  const terminatedTransaction =
    secondaryTransitions.find(
      (item) =>
        item.state === TransactionLifecycleStateValueStateEnum.Terminated,
    ) || undefined;

  const onTerminateTransaction = async (values: FormData) => {
    const mentionableGroups = await dispatch(
      fetchMentionableOfficeGroups(officeDetails?.id!),
    );

    const brokerTeam = (mentionableGroups?.groups || [])?.find(
      (group) => group?.groupName === 'Broker Team',
    );

    if (!brokerTeam) {
      return;
    }

    const commentData = {
      richContent: {
        blocks: [
          {
            type: 'TEXT',
            text: `${
              isListing ? 'LISTING' : 'TRANSACTION'
            } TERMINATION REQUESTED: ${values.terminationReason} `,
          },
          {
            type: 'MENTION',
            userId: brokerTeam?.id!,
            name: brokerTeam?.groupName!,
          },
          {
            type: 'TEXT',
            text: ' \n',
          },
        ],
      },
      readerRole: PostCommentBodyReaderRoleEnum.Public,
    };
    try {
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).transitionToTerminationRequested(transaction.id!);
      dispatch(
        saveTransactionDetailResponse({
          name: 'transaction detail',
          loading: false,
          data,
        }),
      );

      await new CommentControllerApi(getYadaConfiguration()).postComment(
        RezenObjectTypeEnum.Transaction,
        transaction?.id!,
        'USER',
        {
          richContent: commentData.richContent,
          readerRole: commentData.readerRole,
        },
      );
    } catch (e) {
      ErrorService.notify(
        'Unable to notify broker on transaction termination',
        e,
      );
      dispatch(
        showErrorToastForErrorCode(
          'We had a notifying the broker. Please try again later.',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setShowTerminationTransactionModal(false);
      setShowNotifyConfirmation(true);
    }
  };

  const handleTerminateTransaction = useCallback(() => {
    if (isBroker || isAdmin) {
      onClose();
      preActions();
      dispatch(setActiveTransition(terminatedTransaction));
    } else {
      setShowTerminationTransactionModal(true);
    }
  }, [
    isBroker,
    isAdmin,
    onClose,
    preActions,
    dispatch,
    terminatedTransaction,
    setShowTerminationTransactionModal,
  ]);

  useEffect(() => {
    if (sidebarToOpen === KBarActionEnum.TERMINATE_TRANSACTION) {
      handleTerminateTransaction();
    }
  }, [handleTerminateTransaction, sidebarToOpen]);

  return (
    <>
      <ZenSimpleConfirmationModal
        isOpen={showTerminationTransactionModal}
        onClose={() => setShowTerminationTransactionModal(false)}
        variant='danger'
        title='Terminate'
        subtitle={`Why do you need to terminate this ${
          isListing ? 'listing' : 'transaction'
        }?`}
        isSubmitting={isSubmitting}
        confirmButtonText='Notify Broker'
        size='large'
        onConfirm={handleSubmit(onTerminateTransaction)}
      >
        <div className='py-4'>
          <ZenControlledTextInput
            name='terminationReason'
            control={control}
            label='Reason'
            rules={{
              required: 'Please provide the reason for termination',
            }}
          />
        </div>
      </ZenSimpleConfirmationModal>
      <ZenConfirmationModal
        isOpen={showNotifyConfirmation}
        hideIcon
        hideFooterButtons
        variant='primary'
        title='Broker Notified'
        subtitle={`Thank you. Your broker will be notified that this ${
          isListing ? 'listing' : 'transaction'
        } needs to be terminated.`}
        onClose={() => setShowNotifyConfirmation(false)}
      />
    </>
  );
};

export default TerminateTransactionConfirmationModal;
