import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import twitterIcon from '../../../assets/img/gemini/x-twitter.svg';
import { AgentResponse } from '../../../openapi/yenta';

interface Props {
  detail?: AgentResponse;
}

const GeminiAgentSocialMediaLinks: React.FC<Props> = ({ detail }) => {
  const { facebookURL, instagramURL, twitterURL } = detail || {};
  const isSocialMediaLinksExist = facebookURL || instagramURL || twitterURL;

  if (!isSocialMediaLinksExist) return null;

  const handleLinkClick = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <div className='flex items-center justify-center space-x-6 mt-4.5'>
      {facebookURL && (
        <FaFacebookF
          className='cursor-pointer text-xl'
          onClick={() => handleLinkClick(facebookURL)}
        />
      )}
      {instagramURL && (
        <FaInstagram
          className='cursor-pointer text-xl'
          onClick={() => handleLinkClick(instagramURL)}
        />
      )}
      {twitterURL && (
        <img
          src={twitterIcon}
          alt='twitter'
          className='object-contain w-4.5 h-4.5 cursor-pointer'
          onClick={() => handleLinkClick(twitterURL)}
        />
      )}
    </div>
  );
};

export default GeminiAgentSocialMediaLinks;
