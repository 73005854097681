import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TeamControllerApi } from '../../../../openapi/yenta';
import ErrorService from '../../../../services/ErrorService';
import { ISelectOption, RootState } from '../../../../types';
import { getYentaConfiguration } from '../../../../utils/OpenapiConfigurationUtils';
import { StepByStepComponent } from '../../../StepByStep/StepByStepContainer';
import ZenControlledAsyncSelectInput from '../../Input/ZenControlledAsyncSelectInput';
import ZenStickyBottomPagination from '../../ZenStickyBottomPagination';
import { getTeamIds } from '../../../../utils/TeamHelper';
import withZenCreateReferralTransactionProgress from './withZenCreateReferralTransactionProgress';
import {
  ReferralTransactionFormState,
  ReferralTransactionStepName,
} from './ZenReferralTransactionSteps';

const ZenOwnerStep: StepByStepComponent<
  ReferralTransactionFormState,
  ReferralTransactionStepName
> = ({ form: { control }, onPrevious, onNext }) => {
  const [members, setMembers] = useState<ISelectOption[]>([]);
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const teamIdsWithAdminOrLeaderRole = getTeamIds(userDetail!);

  const fetchTeamMembers = async (teamId: string): Promise<void> => {
    try {
      const {
        data: { agents },
      } = await new TeamControllerApi(getYentaConfiguration()).getTeamById(
        teamId,
      );

      const agentData = (agents || []).map((agent) => ({
        label: `${agent.agent?.fullName!} - ${agent.agent?.emailAddress}`,
        value: agent.agent?.id!,
      }));

      // an agent may belong to multiple teams, so we want to show only uniques
      setMembers((members) => uniqBy([...members, ...agentData], 'value'));
    } catch (e) {
      ErrorService.notify('Unable to fetch team by id', e, {
        team: { id: teamId },
      });
    }
  };

  useEffect(() => {
    if (!!teamIdsWithAdminOrLeaderRole.length) {
      teamIdsWithAdminOrLeaderRole.forEach((id) => fetchTeamMembers(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-2xl mx-auto flex-grow'>
        <div>
          <p className='text-xl font-zen-body font-semibold text-zen-dark-9 mb-4'>
            Transaction Owner
          </p>
          <div className='space-y-5 mb-5'>
            <div>
              <ZenControlledAsyncSelectInput<
                ReferralTransactionFormState,
                'transactionOwnerName'
              >
                control={control}
                name='transactionOwnerName'
                placeholder='Search'
                label='Agent (owner)'
                shouldUnregister={false}
                fetchData={async (search, page) => {
                  if (page !== 0) {
                    return [];
                  }

                  const filteredMembers = members.filter((teamMember) => {
                    return teamMember.label
                      ?.toLowerCase()
                      .includes(search.toLowerCase());
                  });

                  return [...filteredMembers];
                }}
                rules={{
                  required: 'Required',
                }}
                isRequired
              />
            </div>
          </div>
        </div>
      </div>
      <ZenStickyBottomPagination
        maxWidth='2xl'
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </div>
  );
};

export default withZenCreateReferralTransactionProgress(ZenOwnerStep);
