import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenControlledRadioInput from '../../Zen/Input/ZenControlledRadioInput';
import { YesNoType } from '../../../types';
import ZenStickyBottomPagination from '../../Zen/ZenStickyBottomPagination';
import { LOFTY_SUBSCRIPTION_AMOUNT } from '../../../constants/LoftyConstants';
import ZenLoftySubscriptionCard from '../../Zen/ZenLoftySubscriptionCard';
import withApplicactionFormProgress from './withWelcomeOnboardingFormProgress';
import {
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName,
} from './ZenWelcomeOnboardingForm';

const LoftySubscription: StepByStepComponent<
  WelcomeOnboardingFormState,
  WelcomeOnboardingFormStepName
> = ({ form: { control }, onNext, onPrevious }) => {
  return (
    <div className='mt-8 w-full max-w-md h-full flex flex-col justify-between'>
      <div className='mb-10 md:mb-0'>
        <p className='font-zen-title font-medium text-zen-dark-9 text-0.5xl'>
          Subscribe to Lofty for ${LOFTY_SUBSCRIPTION_AMOUNT} per month?
        </p>
        <div className='mt-6 mb-5'>
          <ZenLoftySubscriptionCard />
        </div>
        <ZenControlledRadioInput
          control={control}
          name='loftySubscription'
          shouldUnregister={false}
          radioButtonStyle='!mt-5 mr-2'
          options={[
            {
              label: 'No, I would not like to subscribe',
              subLabel: 'You’ll be able to subscribe later.',
              value: YesNoType.NO,
            },
            {
              label: 'Yes, I would like to subscribe',
              subLabel:
                'A request will be sent to our team to give you access to Lofty.',
              value: YesNoType.YES,
            },
          ]}
          rules={{ required: 'Required' }}
        />
      </div>
      <ZenStickyBottomPagination
        onPrevious={onPrevious}
        onNext={onNext}
        maxWidth='xl'
      />
    </div>
  );
};

export default withApplicactionFormProgress(LoftySubscription);
