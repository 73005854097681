import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReferralCentralReferralDto } from '../../openapi/arrakis';
import { RootState } from '../../types';
import MyReferralCardHeader from './components/MyReferralCardHeader';
import ReferralAddressTimelineStatus from './components/ReferralAddressTimelineStatus';
import ReferralAgent from './components/ReferralAgent';
import ReferralCardActions from './components/ReferralCardActions';
import ReferralInfo from './components/ReferralInfo';
import ReferralDetailReceivedSidebar from './sidebars/ReferralDetailReceivedSidebar';

interface MyReferralReceivedCardProps {
  referralDetails: ReferralCentralReferralDto;
}

const MyReferralReceivedCard: React.FC<MyReferralReceivedCardProps> = ({
  referralDetails,
}) => {
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  const isReceivingAgent = userDetail?.id === referralDetails.receivingAgentId;
  const isReferralAgent = userDetail?.id === referralDetails.referralAgentId;
  const isApplicant = referralDetails.applicantAgentIds?.some(
    (applicant) => applicant.agentId === userDetail?.id,
  )!;
  const [isDetailSidebarOpen, setDetailSidebarOpen] = useState<boolean>(false);

  return (
    <div className='border border-grey-300 rounded-xl font-inter h-[16rem]'>
      <MyReferralCardHeader referralDetails={referralDetails} />
      <div className='flex flex-col justify-between border border-grey-300 border-b-0 shadow-top-lg rounded-xl h-[82%] -mx-px'>
        <div className='pl-3 pr-1 py-2'>
          <div className='flex flex-row items-center justify-between'>
            <ReferralInfo
              clientRole={referralDetails.clientType!}
              maximumPrice={referralDetails.priceRange?.maximum?.amount!}
              minimumPrice={referralDetails.priceRange?.minimum?.amount!}
              referralFee={referralDetails.referralFeePercentage!}
            />
          </div>
          <ReferralAddressTimelineStatus
            address={referralDetails.locations!}
            status={referralDetails.status}
            timeline={referralDetails.timeline!}
          />
        </div>
        <div>
          <ReferralCardActions
            isApplicant={isApplicant}
            isReceivingAgent={isReceivingAgent}
            isReferralAgent={isReferralAgent}
            referralDetails={referralDetails}
            setIsReferralSidebarOpen={() => setDetailSidebarOpen(true)}
          />

          {isApplicant && (
            <ReferralAgent
              receivingAgentId={referralDetails?.referralAgentId!}
            />
          )}
        </div>
      </div>
      <ReferralDetailReceivedSidebar
        referralDetails={referralDetails}
        isOpen={isDetailSidebarOpen}
        onClose={() => setDetailSidebarOpen(false)}
      />
    </div>
  );
};

export default MyReferralReceivedCard;
