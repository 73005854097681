import React from 'react';
import { useGemini } from '../../hooks/useGemini';
import { cn } from '../../utils/classUtils';

interface ZenActionProps {
  text?: string;
  icon?: React.ReactElement;
  onClick?(): void;
}

const ZenAction: React.FC<ZenActionProps> = ({
  text = 'Add',
  onClick,
  icon,
}) => {
  const isGeminiEnabled = useGemini();

  return (
    <button
      onClick={onClick}
      data-testid='download'
      className={cn(
        'flex flex-row items-center justify-center space-x-1',
        isGeminiEnabled
          ? 'font-inter font-medium text-base leading-5 text-rezen-blue-600'
          : 'font-zen-body font-semibold text-primary-blue text-sm',
      )}
    >
      <span>{icon}</span>
      <p>{text}</p>
    </button>
  );
};

export default ZenAction;
