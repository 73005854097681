import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressCard,
  faGavel,
  faLanguage,
  faLocation,
  faNotes,
  faSquareDollar,
  faTag,
  faUserCircle,
  faUserGroup,
} from '@fortawesome/pro-light-svg-icons';
import { DateTime } from 'luxon';
import { millify } from 'millify';
import { capitalizeEnum, isStringEmpty } from '../../../utils/StringUtils';
import ReferralOverviewCard from '../ReferralOverviewCard';
import GeminiPill from '../../../components/Gemini/GeminiPill';
import { ReferralCentralReferralDto } from '../../../openapi/arrakis';
import DefaultLoader from '../../../components/DefaultLoader';
import Map, { AddressData } from './Map';
import ReferralAgentDetailItem from './ReferralAgentDetailItem';

interface ReferralDetailCardProps {
  referralDetails?: ReferralCentralReferralDto;
}

const ReferralDetailCard: React.FC<ReferralDetailCardProps> = ({
  referralDetails,
}) => {
  const [mapData, setMapData] = useState<AddressData[]>([]);

  const plotCircle = useCallback(() => {
    const data: any = [];

    for (let i = 0; i < referralDetails?.locations?.length!; i++) {
      const location = referralDetails?.locations![i]!;
      if (
        !!location.coordinates?.latitude &&
        !isStringEmpty(location.radius?.toString())
      ) {
        const radius = location?.radius ?? 0;
        data.push({
          name: location.city,
          circle: {
            radius,
            options: {
              strokeColor: '#629BF8',
              fillColor: '#629BF8',
            },
          },
          latitude: location.coordinates.latitude,
          longitude: location.coordinates.longitude,
        });
      }
    }
    setMapData(data);
  }, [referralDetails?.locations]); // Assuming dummyAddressData is part of the dependency array

  useEffect(() => {
    plotCircle();
  }, [plotCircle]);

  if (!referralDetails) return <DefaultLoader />;

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex justify-end'>
        <GeminiPill
          label={`Referral Created on ${DateTime.fromMillis(
            referralDetails?.createdAt!,
          ).toFormat('LLL dd, yyyy')}`}
          containerClassNames='bg-grey-200 text-slate-600'
        />
      </div>
      <Map
        places={mapData}
        options={{
          disableDefaultUI: true,
          disableDoubleClickZoom: true,
          fullscreenControl: false,
        }}
      />
      <div className='flex items-center space-x-2'>
        <FontAwesomeIcon
          icon={faAddressCard}
          className='text-grey-600 text-sm font-medium'
        />
        <div className='text-grey-600  text-sm font-medium font-inter'>
          Referral Details
        </div>
      </div>
      <div className='self-stretch justify-start items-center grid grid-flow-col grid-cols-3 gap-2'>
        <ReferralOverviewCard
          subtitle='REFERRAL FEE'
          icon={faGavel}
          title={`${referralDetails.referralFeePercentage?.toString()}%`}
        />
        <ReferralOverviewCard
          subtitle='HOME PRICE RANGE'
          icon={faSquareDollar}
          title={`$${millify(
            referralDetails.priceRange?.minimum?.amount!,
          )} - $${millify(referralDetails.priceRange?.maximum?.amount!)}`}
        />
        <ReferralOverviewCard
          subtitle='TIMELINE'
          icon={faSquareDollar}
          title={capitalizeEnum(referralDetails.timeline!).replace('From', '')}
        />
      </div>
      <div className='flex flex-col divide-y'>
        <ReferralAgentDetailItem
          icon={faLocation}
          label='Location Preferences'
          content={
            <div className='flex flex-wrap justify-end gap-2'>
              {referralDetails.locations?.map((location) => {
                return (
                  <GeminiPill
                    key={location.id}
                    label={`${location.city}, ${capitalizeEnum(
                      location.stateOrProvince!,
                    )}`}
                    containerClassNames='text-primary-navy text-sm font-medium bg-rezen-light-blue-100'
                  />
                );
              })}
            </div>
          }
        />
        <ReferralAgentDetailItem
          icon={faUserCircle}
          label='Client Role'
          data={capitalizeEnum(referralDetails.clientType!)}
        />
        <ReferralAgentDetailItem
          icon={faLanguage}
          label='Language Preference'
          data={capitalizeEnum(referralDetails.languages!.join(', '))}
        />
        <ReferralAgentDetailItem
          icon={faUserGroup}
          label='Agents Applied'
          data={
            referralDetails.applicantAgentIds?.length.toString() ??
            'No Agents Applied Yet'
          }
        />
        {referralDetails.notes && (
          <ReferralAgentDetailItem
            icon={faNotes}
            label='Note'
            data={referralDetails.notes ?? 'No Note'}
          />
        )}
        {referralDetails.nickname && (
          <ReferralAgentDetailItem
            icon={faTag}
            label='Personal Nickname'
            data={referralDetails.nickname}
          />
        )}
      </div>
    </div>
  );
};

export default ReferralDetailCard;
