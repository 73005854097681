import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import ZenTeamMembersNameCell from '../Table/Cell/ZenTeamMembersNameCell';
import { RootState } from '../../../types';
import useTeamsInfo from '../../../hooks/useTeamsInfo';

interface RecipientTeamTableProps {
  ids: string[];
  removeTeam(id: string): void;
}

const RecipientTeamTable: React.FC<RecipientTeamTableProps> = ({
  ids,
  removeTeam,
}) => {
  const { teamsById } = useSelector((state: RootState) => state.team);

  useTeamsInfo(ids);

  if ((ids || []).length === 0 || !ids) {
    return null;
  }

  return (
    <div className='pt-8'>
      <p className='mb-1'>Share With Teams</p>
      <div className='max-h-72 overflow-y-auto'>
        {ids?.map((id) => {
          const team = teamsById[id];
          return (
            <div
              key={team?.id}
              className='py-3 flex flex-row items-center border-b'
            >
              <div className='flex items-center w-2/12'>
                <ZenTeamMembersNameCell
                  agents={team?.agents || []}
                  excludeName
                />
              </div>
              <div className='flex flex-row flex-grow ml-4 w-10/12'>
                <p className='font-zen-body text-zen-dark leading-6 text-base mr-1.5'>
                  {team?.name} (Team)
                </p>
              </div>
              <FontAwesomeIcon
                icon={faTrashCan}
                className='w-4 h-4 text-zen-danger cursor-pointer p-2'
                onClick={() => removeTeam(id)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecipientTeamTable;
