import CurrencyInput from 'react-currency-input-field';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form-v7';
import { cn } from '../../utils/classUtils';
import ZenFormErrorMessage from './Input/ZenFormErrorMessage';
import ZenRangeSlider from './ZenRangeSlider';

interface RangeValue {
  min: number;
  max: number;
}

type IRangeValue = Record<string, RangeValue>;

interface ZenControlledRangeSliderProps<
  TFieldValues extends FieldValues | IRangeValue = FieldValues | IRangeValue,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  min: number;
  max: number;
  step: number;
  label?: string;
  minValueLabel: string;
  maxValueLabel: string;
  disabled?: boolean;
  isRequired?: boolean;
  minValuePlaceholder?: string;
  maxValuePlaceholder?: string;
}

const ZenControlledRangeSlider = <
  TFieldValues extends FieldValues | IRangeValue = FieldValues | IRangeValue,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  minValueLabel,
  maxValueLabel,
  min,
  max,
  step,
  disabled,
  minValuePlaceholder,
  maxValuePlaceholder,
  isRequired,
  ...rest
}: ZenControlledRangeSliderProps<TFieldValues, TName>) => {
  const {
    field: { onChange, value, name },
    fieldState: { invalid, error },
  } = useController<TFieldValues, TName>(rest);

  return (
    <div className='space-y-1 w-full'>
      {label && (
        <label className='inline-block' htmlFor={name}>
          <span
            className={cn(
              'font-zen-body font-semibold',
              invalid ? 'text-zen-danger' : 'text-zen-dark-9',
            )}
          >
            {label}
          </span>
          {isRequired && <span className='text-zen-danger'>*</span>}
        </label>
      )}
      <div className='flex flex-row justify-between space-x-8'>
        <div>
          <CurrencyInput
            id={`${name}.min`}
            name={`${name}.min`}
            value={value?.min}
            onValueChange={(min) => {
              onChange({ min, max: value.max });
            }}
            readOnly={disabled}
            placeholder={minValuePlaceholder}
            className={cn(
              'appearance-none p-1.5 border-zen-dark-5 rounded-lg bg-white focus:outline-none focus:ring-0 w-full',
              {
                'bg-gray-50': disabled,
              },
            )}
            allowDecimals={false}
            prefix='$ '
          />
          <label
            className='font-zen-body text-sm inline-block mt-1 text-zen-dark-6'
            htmlFor={`${name}.min`}
          >
            {minValueLabel}
          </label>
        </div>
        <div>
          <CurrencyInput
            id={`${name}.max`}
            name={`${name}.max`}
            value={value?.max}
            onValueChange={(max) => {
              onChange({ min: value.min, max });
            }}
            readOnly={disabled}
            placeholder={maxValuePlaceholder}
            className={cn(
              'appearance-none p-2 border-zen-dark-5 rounded-lg bg-white focus:outline-none focus:ring-0 w-full',
              {
                'bg-gray-50': disabled,
              },
            )}
            allowDecimals={false}
            prefix='$ '
          />
          <label
            className='font-zen-body text-sm inline-block mt-1 text-zen-dark-6'
            htmlFor={`${name}.max`}
          >
            {maxValueLabel}
          </label>
        </div>
      </div>
      <div className='p-4'>
        <ZenRangeSlider
          value={[value?.min!, value?.max!]}
          min={min}
          max={max}
          step={step}
          onChange={(number) => {
            onChange({ min: number[0], max: number[1] });
          }}
          disabled={disabled}
        />
      </div>
      {!!error && <ZenFormErrorMessage message={error.message} />}
    </div>
  );
};
export default ZenControlledRangeSlider;
