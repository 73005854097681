import {
  faArrowDownToSquare,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import RealLogo from '../assets/img/new-rezen-black-logo.svg';
import { useLogout } from '../hooks/useLogout';
import { cn } from '../utils/classUtils';
import ZenAgentWebsiteConfirmationModal, {
  ModalVariantType,
} from './Zen/Modal/ZenAgentWebsiteConfirmationModal';
import ZenButton, { ZenButtonVariant } from './Zen/ZenButton';

export interface ZenAgentOnboardingLayoutProps {
  variant?: ModalVariantType;
  title: string;
  hideLogout?: boolean;
  hideClose?: boolean;
  onClose?(): void;
  modalTitle?: string;
  modalSubtitle?: string;
  modalSubmitText?: string;
  modalCancelText?: string;
  hideCancelButton?: boolean;
  RightHeaderComponent?: React.ReactNode;
  LeftHeaderComponent?: React.ReactNode;
  textClassNames?: string;
}

const ZenAgentOnboardingLayout: React.FC<ZenAgentOnboardingLayoutProps> = ({
  variant = 'cancel',
  title,
  hideLogout = false,
  hideClose = false,
  hideCancelButton = false,
  onClose,
  children,
  modalTitle = 'Close and discard all edits?',
  modalSubtitle = "Changes won't be saved and you'll go back to the start.",
  modalSubmitText = 'Yes',
  modalCancelText = 'No',
  RightHeaderComponent,
  LeftHeaderComponent,
  textClassNames = '',
}) => {
  const logout = useLogout();
  const [cancel, setCancel] = useState<boolean>(false);
  const history = useHistory();

  const modalVariantMapToButtonVariant: {
    [key in ModalVariantType]: ZenButtonVariant;
  } = {
    cancel: 'danger',
    error: 'danger',
    info: 'primary',
    success: 'primary',
    upload: 'secondary',
    warning: 'danger-outline',
  };

  return (
    <div className='fixed top-0 right-0 bottom-0 left-0 w-full h-full bg-white scrollbar overflow-auto z-10'>
      <div className='flex flex-col w-full h-full'>
        <header className='p-4 border-b border-coolGray-300 flex items-center justify-between'>
          <div className='flex items-center'>
            {LeftHeaderComponent ? (
              LeftHeaderComponent
            ) : (
              <img src={RealLogo} className='h-7' alt='Logo' />
            )}
            <div className='h-7 border-l border-coolGray-300 mx-6' />
            <h1
              className={cn(
                'font-zen-title font-medium text-xl text-zen-dark-9',
                textClassNames,
              )}
            >
              {title}
            </h1>
          </div>
          {RightHeaderComponent}
          {!hideClose && (
            <ZenButton
              variant='secondary-light-outline'
              label='Close'
              LeftIconComponent={
                <FontAwesomeIcon
                  icon={faXmark}
                  className='mt-0.5 w-6'
                  size='lg'
                />
              }
              onClick={() => setCancel(true)}
              className={textClassNames}
            />
          )}
        </header>
        <div className='flex-grow flex flex-col'>
          <div className='h-full'>{children}</div>
        </div>
        {!hideLogout && (
          <div className='p-4 lg:p-6'>
            <ZenButton
              variant='primary-outline'
              label='Logout'
              LeftIconComponent={
                <FontAwesomeIcon
                  icon={faArrowDownToSquare}
                  className='-rotate-90 mr-1 text-[18px]'
                />
              }
              onClick={async () => {
                await logout();
                history.push('/');
              }}
            />
          </div>
        )}
      </div>
      <ZenAgentWebsiteConfirmationModal
        variant={variant}
        title={modalTitle}
        subtitle={modalSubtitle}
        isOpen={cancel}
        onClose={() => setCancel(false)}
      >
        <div className='flex flex-row justify-between space-x-5 mt-10'>
          {!hideCancelButton && (
            <ZenButton
              variant='secondary-light-outline'
              label={modalCancelText}
              isFullWidth
              onClick={() => setCancel(false)}
            />
          )}

          <ZenButton
            variant={modalVariantMapToButtonVariant[variant]}
            label={modalSubmitText}
            type='submit'
            isFullWidth
            onClick={onClose}
          />
        </div>
      </ZenAgentWebsiteConfirmationModal>
    </div>
  );
};

export default ZenAgentOnboardingLayout;
