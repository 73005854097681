import { useSelector } from 'react-redux';
import { RootState } from '../../types';

interface TransactionCommissionIntroProps {}

const TransactionCommissionIntro: React.FC<TransactionCommissionIntroProps> = () => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  return (
    <div className='flex flex-grow justify-center flex-col'>
      <p className='text-2xl font-primary-medium mb-3'>
        Hi, {userDetail!.fullName!} 👋
      </p>
      <p className='mb-1\\.5 text-base font-primary-thin'>
        Looks like it is time to review and approve the commissions for this
        transaction.
      </p>
      <p className='mb-4 text-base font-primary-thin'>
        Let&apos;s take a look at everyone&apos;s payments and confirm that they
        are correct.
      </p>
    </div>
  );
};

export default TransactionCommissionIntro;
