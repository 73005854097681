import { faMapLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface ReferralAddressProps {
  location: string;
}

const ReferralAddress: React.FC<ReferralAddressProps> = ({ location }) => {
  return (
    <div className='flex flex-row items-center space-x-1'>
      <FontAwesomeIcon
        icon={faMapLocationDot}
        className='text-grey-400'
        fontSize={16}
      />
      <p className='text-grey-600 font-base'>{location}</p>
    </div>
  );
};

export default ReferralAddress;
