import React from 'react';
import { Done } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import IconButton from '../../IconButton';
import { cn } from '../../../utils/classUtils';

interface ZenFileSelectionOrActionProps {
  isSelected: boolean;
  isSelectionEnabled: boolean;
  onRename(): void;
  onDelete(): void;
  onSelectionChange(): void;
  disabled?: boolean;
}

const ZenFileSelectionOrAction: React.FC<ZenFileSelectionOrActionProps> = ({
  isSelected,
  isSelectionEnabled,
  onRename,
  onDelete,
  onSelectionChange,
  disabled = false,
}) => {
  return isSelectionEnabled ? (
    <button
      className={cn(
        'appearance-none w-6 h-6 rounded-full border flex items-center justify-center',
        isSelected
          ? 'border-primary-blue bg-primary-blue text-white'
          : 'border-zen-dark-4',
        disabled && 'cursor-not-allowed',
      )}
      onClick={onSelectionChange}
      disabled={disabled}
      data-testid='file-selection-checkbox'
    >
      {isSelected && <Done className='!text-sm' />}
    </button>
  ) : (
    <div className='flex space-x-2 flex-nowrap'>
      <IconButton
        variant='outline'
        leftIcon={<FontAwesomeIcon icon={faPen} size='sm' />}
        buttonStyle='!border-0 !text-dark !ring-0 hover:!bg-primary/10'
        buttonType='button'
        label='Rename'
        onClick={onRename}
      />
      <IconButton
        variant='none'
        leftIcon={<FontAwesomeIcon icon={faTrash} className='mr-1' />}
        buttonStyle='hover:!bg-zen-danger/10 text-dark hover:text-zen-danger'
        buttonType='button'
        label='Delete'
        onClick={onDelete}
      />
    </div>
  );
};

export default ZenFileSelectionOrAction;
