import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminOnly from '../components/auth/AdminOnly';
import ZenViewCheckDepositSidebarForm from '../components/transactions/CheckDeposit/ZenViewCheckDepositSidebarForm';
import ZenCommissionDepositsSection from '../components/transactions/CommissionDeposits/ZenCommissionDepositsSection';
import ZenMoneyTransfersSection from '../components/transactions/MoneyTransfers/ZenMoneyTransfersSection';
import ZenTrustDepositsSection from '../components/transactions/TrustDeposits/ZenTrustDepositSection';
import { saveCheckDepositsDetail } from '../slices/CheckDepositsSlice';
import { RootState } from '../types';
import { isCanadaTransaction } from '../utils/TransactionHelper';
import { getPageTitleForTransaction } from '../utils/TransactionUtils';
import ZenRoute from '../components/Zen/ZenRoute';
import TrustFundSection from '../components/transactions/TrustFund/TrustFundSection';

interface TransactionViewDepositsRouteProps {}

const ZenTransactionViewDepositsRoute: React.FC<TransactionViewDepositsRouteProps> = () => {
  const dispatch = useDispatch();
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
    },
    auth: { isAdmin },
    checkDeposits: { checkDepositsDetails },
    office: { officeDetailById },
  } = useSelector((state: RootState) => state);

  const office = officeDetailById[transactionDetail?.office?.id!];

  return (
    <ZenRoute
      title={`${getPageTitleForTransaction(transactionDetail)} Deposits - ${
        transactionDetail?.address?.oneLine
      }`}
    >
      <div className='mb-4'>
        {(transactionDetail?.officeAcceptsEscrow ||
          isCanadaTransaction(transactionDetail!)) && (
          <ZenTrustDepositsSection
            transaction={transactionDetail!}
            office={office}
          />
        )}
        <ZenCommissionDepositsSection
          transaction={transactionDetail!}
          office={office}
        />
        {(isAdmin || !!transactionDetail?.officeAcceptsEscrow) && (
          <TrustFundSection transaction={transactionDetail!} />
        )}
      </div>
      {checkDepositsDetails && (
        <ZenViewCheckDepositSidebarForm
          checkDepositResponse={checkDepositsDetails}
          isOpen={!!checkDepositsDetails}
          onClose={() => dispatch(saveCheckDepositsDetail(undefined))}
          transaction={transactionDetail!}
        />
      )}
      <AdminOnly>
        {(transactionDetail?.officeAcceptsEscrow ||
          isCanadaTransaction(transactionDetail!)) && (
          <ZenMoneyTransfersSection transaction={transactionDetail!} />
        )}
      </AdminOnly>
    </ZenRoute>
  );
};

export default ZenTransactionViewDepositsRoute;
