import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AdminOnly from '../components/auth/AdminOnly';
import DetailPageLoader from '../components/DetailPageLoader';
import GeminiMlsHeaderSection from '../components/Gemini/Mls/GeminiMLSHeaderSection';
import ZenMLSActionHeader from '../components/mls/Zen/ZenMLSActionHeader';
import ZenMLSHeaderSection from '../components/mls/Zen/ZenMLSHeaderSection';
import ResourceContainer from '../components/ResourceContainer';
import ZenAgentListContainer from '../components/Zen/ZenAgentListContainer';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { AgentResponse } from '../openapi/yenta';
import {
  useFetchMlsDetails,
  useFetchMlsSecretDetails,
  useFetchPropertyTypesWanderer,
  useGetPropertyTypeMapping,
} from '../query/boardsAndMls/useBoardsAndMls';
import ErrorService from '../services/ErrorService';
import { FeatureFlagTypeEnum, RootState } from '../types';

type Match = {
  id: string;
};

const ZenMLSDetailRoute: React.FC = () => {
  const [agents, setAgents] = useState<AgentResponse[]>([]);
  const { id: mlsId } = useParams() as Match;

  const { isAdmin } = useSelector((state: RootState) => state.auth);
  const isBoardAndMlsImprovementsFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.BOARD_AND_MLS_IMPROVEMENTS,
  );

  const {
    isLoading,
    data: detail,
    error,
    refetch: refetchMls,
  } = useFetchMlsDetails(mlsId!);
  const { data: secrets, refetch: refetchSecret } = useFetchMlsSecretDetails(
    mlsId!,
    isBoardAndMlsImprovementsFlagEnabled,
  );
  const { data: mlsPropertyTypes } = useGetPropertyTypeMapping(mlsId!, isAdmin);
  const { data: mlsPropertyTypesWanderer } = useFetchPropertyTypesWanderer(
    detail?.code!,
  );

  const HeaderComponent = isBoardAndMlsImprovementsFlagEnabled
    ? GeminiMlsHeaderSection
    : ZenMLSHeaderSection;

  const handleRefetch = () => {
    refetchMls();
    refetchSecret();
  };

  return (
    <ZenRoute title='MLS Details'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'MLS', url: '/mls' },
          { title: `${detail?.name}`, url: `/mls/${detail?.id}` },
        ]}
      >
        <ResourceContainer
          loading={isLoading}
          LoaderComponent={<DetailPageLoader />}
          errorCode={!!error ? ErrorService.getErrorCode(error) : undefined}
          isEmpty={!detail}
          resourceName='MLS'
        >
          <div className='px-4 mt-1 pb-6 space-y-5'>
            <HeaderComponent
              mls={detail!}
              secrets={secrets}
              mlsPropertyTypesMappings={
                mlsPropertyTypes?.mlsPropertyTypeMappings
              }
              mlsPropertyTypesWanderer={mlsPropertyTypesWanderer}
            />
            <AdminOnly>
              <ZenMLSActionHeader
                mls={detail!}
                secrets={secrets}
                agents={agents}
                mlsPropertyTypesMappings={
                  mlsPropertyTypes?.mlsPropertyTypeMappings
                }
                mlsPropertyTypesWanderer={mlsPropertyTypesWanderer}
                refetch={handleRefetch}
              />
            </AdminOnly>
            <ZenAgentListContainer mlsId={[mlsId]} setAgents={setAgents} />
          </div>
        </ResourceContainer>
      </ZenPageLayout>
    </ZenRoute>
  );
};

export default ZenMLSDetailRoute;
