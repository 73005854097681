import React from 'react';
import ZenButton from '../../Zen/ZenButton';
import {
  MARKETING_RESOURCES_URL,
  WORKPLACE_JOIN_US_URL,
} from '../../../constants/RealMortgageConstants';

const OneRealMortgageJoinUs: React.FC = () => {
  return (
    <div className='flex flex-col sm:flex-row gap-6 sm:gap-12'>
      <div className='border border-slate-600 rounded-xl p-5 sm:pr-10 flex flex-col justify-between gap-3'>
        <p className='text-xs text-zen-dark-6'>PORTAL</p>
        <h1 className='font-poppins font-semibold text-2xl leading-8'>
          Access our Agent Resources
        </h1>
        <p className='font-light'>
          Browse the One Real Mortgage portal on Marketing Center for branded
          social shareables, presentations, and more.
        </p>
        <div className='mt-3'>
          <ZenButton
            label='Go to Agent Portal'
            onClick={() => window.open(MARKETING_RESOURCES_URL, '_blank')}
          />
        </div>
      </div>
      <div className='border border-slate-600 rounded-xl p-5 sm:pr-10 flex flex-col justify-between'>
        <p className='text-xs text-zen-dark-6'>WORKSPACE</p>
        <h1 className='font-poppins font-semibold text-2xl leading-8'>
          Join us on Workplace
        </h1>
        <p className='font-light'>
          The One Real Mortgage group is a place to ask questions, share
          reviews, and learn the latest mortgage news and updates.
        </p>
        <div className='mt-3'>
          <ZenButton
            label='Join Us Now'
            onClick={() => window.open(WORKPLACE_JOIN_US_URL, '_blank')}
          />
        </div>
      </div>
    </div>
  );
};

export default OneRealMortgageJoinUs;
