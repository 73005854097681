import { faRotate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnumMap } from '../types';
import { cn } from '../utils/classUtils';

type IconSizeType = 'small' | 'large' | 'default';

export interface DefaultLoaderProps {
  noPadding?: boolean;
  iconSize?: IconSizeType;
  isDark?: boolean;
}

const DefaultLoader: React.FC<DefaultLoaderProps> = ({
  noPadding,
  iconSize = 'large',
  isDark = false,
}) => {
  const IconSizeEnumMap: EnumMap<IconSizeType, string> = {
    small: 'text-base',
    large: 'text-[26px]',
    default: 'text-lg',
  };

  return (
    <div
      className={cn('w-full h-full flex items-center justify-center', {
        'p-5': !noPadding,
      })}
      title='spinning-loader'
    >
      <FontAwesomeIcon
        icon={faRotate}
        className={cn(
          'animate-spin',
          isDark ? 'text-zen-dark-9' : 'text-primary-blue',
          IconSizeEnumMap[iconSize],
        )}
      />
    </div>
  );
};

export default DefaultLoader;
