import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form-v7';
import { FieldObject } from '../../../openapi/atlantis';

const useFieldArrayValidation = (fieldArray: FieldObject) => {
  const { control, setError, clearErrors } = useFormContext();

  const watchValues = useWatch({ control, name: fieldArray.id! });

  const customFieldArrayValidation = useCallback(() => {
    if (!watchValues) {
      return;
    }

    const errors: string[] = [];

    for (const validation of fieldArray.validations || []) {
      if (validation.type === 'group') {
        //@ts-ignore
        const { type, effectiveValue } = validation.validation;

        if (type === 'maxDate') {
          const validDates = (watchValues || [])
            //@ts-ignore
            .map((value: any) => value[validation.fieldId])
            .filter((v: string) => DateTime.fromISO(v).isValid);

          if (validDates.length > 0) {
            const isValidValues = validDates.some((date: string) => {
              const maxDate = DateTime.fromISO(effectiveValue);

              return DateTime.fromISO(date).diff(maxDate, 'days').days < 0;
            });

            if (!isValidValues) {
              //@ts-ignore
              errors.push(validation.message);
            }
          }
        }
      }
    }

    if (errors.length > 0) {
      setError('fieldArrayErrors', {
        type: 'manual',
        message: errors.join(', '),
      });
    } else {
      clearErrors('fieldArrayErrors');
    }
  }, [watchValues, fieldArray.validations, setError, clearErrors]);

  useEffect(() => {
    customFieldArrayValidation();
  }, [customFieldArrayValidation]);
};

export default useFieldArrayValidation;
