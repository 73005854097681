import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '../../utils/classUtils';

interface ZenRemoveProps {
  text?: string;
  onClick?(): void;
  disabled?: boolean;
}

const ZenRemove: React.FC<ZenRemoveProps> = ({ text, onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        'flex flex-row items-center justify-center space-x-1 font-zen-body font-semibold bg-zen-danger-light px-2 py-1 rounded-full',
        { 'opacity-40': disabled },
      )}
      data-testid='remove'
      disabled={disabled}
    >
      <FontAwesomeIcon
        icon={regular('xmark')}
        className='text-zen-danger'
        fontSize={18}
      />
      {text && (
        <p className='text-zen-danger font-zen-body font-medium text-sm'>
          {text}
        </p>
      )}
    </button>
  );
};

export default ZenRemove;
