import {
  faHourglassHalf,
  faPen,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { AgentResponse } from '../../../../openapi/yenta';
import { cn } from '../../../../utils/classUtils';
import { formatDateRange, isOutOfOffice } from '../../../../utils/RoarUtils';

type OutOfOfficeStatusProps = {
  agentDetail?: AgentResponse;
  onClickEdit(): void;
  onClickDelete(): void;
};

const OutOfOfficeStatus: React.FC<OutOfOfficeStatusProps> = ({
  agentDetail,
  onClickEdit,
  onClickDelete,
}) => {
  const isOutOfOfficeActive = isOutOfOffice(agentDetail);
  const outOfOffice = agentDetail?.availability?.outOfOffice?.[0]!;

  return (
    <div className='flex items-stretch'>
      <div
        className={cn(
          'w-3 rounded-bl-lg',
          isOutOfOfficeActive ? 'bg-green-600' : 'bg-yellow-900',
        )}
      />
      <div className='p-4 flex-1 font-inter'>
        <div
          className={cn(
            'font-normal inline-flex text-sm',
            isOutOfOfficeActive ? 'text-green-600 ' : 'text-yellow-900',
          )}
        >
          <FontAwesomeIcon
            icon={isOutOfOfficeActive ? faCircle : faHourglassHalf}
            size='sm'
            className='mt-1'
          />
          <p className='ml-2 font-medium'>
            {isOutOfOfficeActive ? 'Active' : 'Scheduled'}
          </p>
        </div>
        <div className='text-primary-dark flex items-center justify-between space-x-3'>
          {formatDateRange(outOfOffice)}
          <div className='flex items-center space-x-3'>
            <button
              type='button'
              className='p-2 rounded-full bg-regent-200 text-primary-dark active:opacity-60'
              onClick={onClickEdit}
              data-testid='Edit-Office'
            >
              <FontAwesomeIcon icon={faPen} width={24} height={24} />
            </button>
            <button
              type='button'
              className='p-2 rounded-full bg-red-100 text-coral-red active:opacity-60'
              onClick={onClickDelete}
              data-testid='Delete-Office'
            >
              <FontAwesomeIcon icon={faTrashCan} width={24} height={24} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutOfOfficeStatus;
