import { faPen, faUserGroup } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useGemini } from '../../../hooks/useGemini';
import {
  ParticipantValue,
  TransactionResponse,
  TransactionResponseTransactionTypeEnum,
  UpdateParticipantRequest,
  UpdateParticipantRequestParticipantRoleEnum,
} from '../../../openapi/arrakis';
import { showSuccessToast } from '../../../slices/ToastNotificationSlice';
import { updateTransactionParticipant } from '../../../slices/TransactionSlice';
import {
  AppDispatch,
  CardItem,
  FeatureFlagTypeEnum,
  RootState,
} from '../../../types';
import { cn } from '../../../utils/classUtils';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import { formatPhoneNumber } from '../../../utils/StringUtils';
import {
  isCanadaTransaction,
  isTransactionReadOnly,
  participantRoleDisplayName,
} from '../../../utils/TransactionHelper';
import { cdPayerCardHeader } from '../../../utils/TransactionUtils';
import ZenChangeCdPayerSidebarModal from '../forms/ZenChangeCdPayerSidebarModal';
import ZenEditTransactionParticipantFormSidebarModal from '../forms/ZenEditTransactionParticipantFormSidebarModal';
import ZenToggleRow from '../Input/ZenToggleRow';
import ZenCardWithItems from '../ZenCardWithItems';

interface ZenCdPayerInformationCardProps {
  transaction: TransactionResponse;
}

const ZenCdPayerInformationCard: React.FC<ZenCdPayerInformationCardProps> = ({
  transaction,
}) => {
  const cdPayer = transaction.cdPayer!;
  const { userDetail, isAdmin, isBroker } = useSelector(
    (state: RootState) => state.auth,
  );
  const [
    openEditParticipantFormSidebarModal,
    setOpenEditParticipantFormSidebarModal,
  ] = useState<boolean>(false);
  const [openChangeCdPayer, setOpenChangeCdPayer] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const isAgent = !(isAdmin || isBroker);

  const internalReferralFeatureEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.INTERNAL_REFERRAL,
  );
  const isGeminiEnabled = useGemini();

  const isInternalReferralTransaction =
    transaction?.transactionType ===
    TransactionResponseTransactionTypeEnum.InternalReferral;

  const toggleParticipant = async (
    participant: ParticipantValue,
    flag: keyof UpdateParticipantRequest,
    value: boolean,
  ) => {
    setLoading(true);
    await dispatch(
      updateTransactionParticipant(participant.id!, transaction?.id!, {
        paidByReal: !!participant?.paidByReal,
        passThrough: !!participant?.passThrough,
        participantRole: (participant?.role as unknown) as UpdateParticipantRequestParticipantRoleEnum,
        paymentInstructions: participant?.paymentInstructions,
        address: participant?.address,
        payer: !!participant?.commissionDocumentPayer,
        commissionDocumentRecipient: !!participant?.commissionDocumentRecipient,
        firstName: participant?.firstName,
        lastName: participant?.lastName,
        phoneNumber: participant?.phoneNumber,
        emailAddress: participant?.emailAddress,
        personalDeal: false,
        paidViaBusinessEntity: participant?.paidViaBusinessEntity,
        [flag]: value,
      }),
    );
    dispatch(showSuccessToast('Commission Payer Updated!'));
    setLoading(false);
  };

  const items: CardItem[] = [
    {
      name: 'Name',
      value: getParticipantName(cdPayer),
    },
    { name: 'Company', value: cdPayer?.company || 'N/A' },
    { name: 'Email', value: cdPayer?.emailAddress || 'N/A' },
    {
      name: 'Phone',
      value: formatPhoneNumber(cdPayer?.phoneNumber) || 'N/A',
    },
    {
      name: 'Role',
      value: cdPayer?.role ? participantRoleDisplayName(cdPayer.role) : 'N/A',
    },
  ];

  if (isAdmin) {
    items.push({
      name: isCanadaTransaction(transaction!)
        ? 'Receives Trade Record Sheet?'
        : 'Receives Invoice / Commission Document?',
      value: (
        <div className='mr-[-12px]'>
          <ZenToggleRow
            loading={loading}
            value={!!cdPayer?.commissionDocumentRecipient}
            onChange={() =>
              toggleParticipant(
                cdPayer,
                'commissionDocumentRecipient',
                !cdPayer?.commissionDocumentRecipient,
              )
            }
          />
        </div>
      ),
    });
  }

  if (isAgent || isBroker) {
    items.push({
      name: isCanadaTransaction(transaction!)
        ? 'Receives Trade Record Sheet?'
        : 'Receives Invoice / Commission Document?',
      value: !!cdPayer?.commissionDocumentRecipient ? 'Yes' : 'No',
    });
  }

  return (
    <div>
      <ZenCardWithItems
        title={cdPayerCardHeader(
          internalReferralFeatureEnabled && isInternalReferralTransaction,
          isCanadaTransaction(transaction!),
        )}
        RightComponent={
          !isTransactionReadOnly(transaction, userDetail!) ? (
            <div className='flex flex-row flex-nowrap ml-2 space-x-6'>
              <button
                onClick={() => setOpenChangeCdPayer(true)}
                className='flex flex-row items-center justify-center space-x-1'
              >
                <span>
                  <FontAwesomeIcon
                    icon={faUserGroup}
                    className={cn(
                      isGeminiEnabled
                        ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                        : 'text-base text-primary-blue',
                    )}
                  />
                </span>
                <p
                  className={cn(
                    'mt-0.5',
                    isGeminiEnabled
                      ? 'font-inter font-medium text-base leading-5 text-center text-rezen-blue-600'
                      : 'text-primary-blue',
                  )}
                >
                  Change
                </p>
              </button>

              <button
                onClick={() => setOpenEditParticipantFormSidebarModal(true)}
                className='flex flex-row items-center justify-center space-x-1'
              >
                <span>
                  <FontAwesomeIcon
                    icon={faPen}
                    className={cn(
                      isGeminiEnabled
                        ? 'font-light text-[13px] leading-[23px] text-rezen-blue-600'
                        : 'text-base text-primary-blue',
                    )}
                  />
                </span>
                <p
                  className={cn(
                    isGeminiEnabled
                      ? 'font-inter font-medium text-base leading-5 text-center text-rezen-blue-600'
                      : 'text-primary-blue',
                  )}
                >
                  Edit
                </p>
              </button>
            </div>
          ) : undefined
        }
        items={items}
        variant='regular'
      />

      {openEditParticipantFormSidebarModal && (
        <ZenEditTransactionParticipantFormSidebarModal
          transaction={transaction}
          participant={cdPayer}
          isOpen
          onClose={() => setOpenEditParticipantFormSidebarModal(false)}
        />
      )}

      {openChangeCdPayer && (
        <ZenChangeCdPayerSidebarModal
          isOpen
          onClose={() => setOpenChangeCdPayer(false)}
          transaction={transaction}
        />
      )}
    </div>
  );
};

export default ZenCdPayerInformationCard;
