import { IdentityRoleResponse, RoleResponse } from '../openapi/keymaker';

export const getRoleDescription = (
  grantorRoles: RoleResponse[],
  role: string,
) => {
  const description = grantorRoles?.find(
    (granterRole) => granterRole?.name === role,
  )?.description;

  return description || 'N/A';
};

export const getOnlyGrantorRoles = (
  identityRole: IdentityRoleResponse[],
  grantorRoles: RoleResponse[],
): IdentityRoleResponse[] => {
  const onlyGrantorRoles = identityRole.filter((role) =>
    grantorRoles.find((grantorRole) => grantorRole.name === role.role),
  );

  return onlyGrantorRoles;
};
