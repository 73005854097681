/* eslint-disable react/jsx-key */
/* disabling eslint because .map keys is handled by plugin */
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import axios, { CancelToken } from 'axios';
import classNames from 'classnames';
import { findIndex } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, TableInstance, useAsyncDebounce } from 'react-table';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  AppDispatch,
  IPaginateReq,
  IPaginateRes,
  VerticalAlignmentVariant,
} from '../../../types';
import {
  getPaginateReqFromState,
  isLastGroupMember,
} from '../../../utils/TableUtils';
import TableLoader from '../../TableLoader';
import TableResourceContainer from '../../TableResourceContainer';
import { cn } from '../../../utils/classUtils';
import ZenResourceDropzoneTableRow from './ZenResourceDropzoneTableRow';
import ZenResourceTableCard from './ZenResourceTableCard';
import ZenResourceTableRow from './ZenResourceTableRow';
import ZenTablePageSize from './ZenTablePageSize';
import ZenTablePagination from './ZenTablePagination';

export enum ResourceTableVariant {
  ROW = 'row',
  CARD = 'card',
}

export type ResourceIndexContainerDisplayVariant = 'TABLE' | 'CARDS';

export type ResourceTableHeaderVariant = 'default' | 'light' | 'light2';

export type ResourceTableRowGroupByVariant = 'email' | 'none';

const TableRowVariantMap: Record<ResourceIndexContainerDisplayVariant, any> = {
  TABLE: ZenResourceTableRow,
  CARDS: ZenResourceTableCard,
};

interface GeneralTableProps<D extends object> extends TableInstance<D> {
  fetchData?: (
    req: IPaginateReq<D>,
    cancelToken: CancelToken,
  ) => Promise<IPaginateRes<D>> | IPaginateRes<D>;
  resourceName: string;
  totalCount: number;
  variant?: ResourceTableVariant;
  hidePagination?: boolean;
  paginationContainerClassNames?: string;
  hidePageSize?: boolean;
  emptyIconComponent?: React.ReactElement;
  customEmptyComponent?: React.ReactElement;
  cellVerticalAlignment?: VerticalAlignmentVariant;
  renderToggleRowComponent?(d: Row<D>): React.ReactElement;
  getTableRowClassNames?(d: Row<D>): string;
  allowSortingRows?: boolean;
  onSortEnd?(
    oldIndex: number,
    newIndex: number,
    item: D,
    items: Row<D>[],
  ): void;
  isDropzone?: boolean;
  checklistId?: string;
  headerVariant?: ResourceTableHeaderVariant;
  pageSizeOptions?: number[];
  hideRowBorder?: boolean;
  groupByProperty?: ResourceTableRowGroupByVariant;
  hideHeader?: boolean;
  dropboxId?: string;
  compact?: boolean;
  resourceContainerDisplayVariant?: ResourceIndexContainerDisplayVariant;
  isDisplayVariantCardExpandable?: boolean;
  stickyHeader?: boolean;
  showTotalCount?: boolean;
}

const ZenResourceTable = <D extends object>({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  visibleColumns,
  gotoPage,
  setPageSize,
  resourceName,
  state,
  data = [],
  fetchData,
  pageCount,
  totalCount,
  variant = ResourceTableVariant.ROW,
  hidePagination = false,
  paginationContainerClassNames,
  hidePageSize = false,
  filteredRows,
  emptyIconComponent,
  customEmptyComponent,
  cellVerticalAlignment = 'align-middle',
  renderToggleRowComponent,
  getTableRowClassNames,
  allowSortingRows,
  onSortEnd,
  rows,
  isDropzone = false,
  checklistId = '',
  headerVariant = 'default',
  pageSizeOptions,
  hideRowBorder,
  groupByProperty,
  hideHeader = false,
  dropboxId,
  compact = false,
  resourceContainerDisplayVariant = 'TABLE',
  isDisplayVariantCardExpandable = true,
  stickyHeader = false,
  showTotalCount = false,
}: GeneralTableProps<D>) => {
  const dispatch = useDispatch<AppDispatch>();
  const cancelToken = useRef(axios.CancelToken.source());

  const [loading, setLoading] = useState<boolean>(!!fetchData);
  const [isError, setIsError] = useState<boolean>(false);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor),
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (active?.id !== over?.id && onSortEnd) {
      const oldIndex = findIndex(rows, (item: Row<D>) => item.id === active.id);
      const newIndex = findIndex(rows, (item: Row<D>) => item.id === over?.id);
      onSortEnd(oldIndex, newIndex, rows[oldIndex].original, rows);
    }
  };

  const onStateChange = useAsyncDebounce(
    async (): Promise<IPaginateRes<D> | void> => {
      setLoading(true);
      let cancelled = false;
      try {
        cancelToken.current.cancel();
        cancelToken.current = axios.CancelToken.source();
        const req: IPaginateReq<D> = getPaginateReqFromState<D>(state);
        const res = await fetchData!(req, cancelToken.current.token);
        setIsError(false);
        return res;
      } catch (e) {
        if (axios.isCancel(e)) {
          cancelled = true;
        } else {
          ErrorService.notifyIgnoreAuthErrors('Unable to fetch data', e);
          dispatch(showApiErrorModal(e));
          setIsError(true);
        }
      } finally {
        if (!cancelled) {
          setLoading(false);
        }
      }
    },
    400,
  );

  useEffect(() => {
    if (!!fetchData) {
      onStateChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.filters,
    state.sortBy,
    state.globalFilter,
    state.pageIndex,
    state.pageSize,
    state.hiddenColumns,
  ]);

  if (loading && !data.length) {
    return <TableLoader />;
  }

  const headerVariantStyle: Record<ResourceTableHeaderVariant, string> = {
    default: 'bg-zen-light-gray-1',
    light: 'bg-inherit',
    light2: 'bg-inherit',
  };

  const headerCellVariantStyle: Record<ResourceTableHeaderVariant, string> = {
    default:
      'font-zen-body font-bold text-zen-gray-5 text-xs align-top uppercase',
    light:
      'font-zen-body font-semibold text-regent-600 text-sm align-center pt-5 leading-5 border-b border-gray-100',
    light2:
      'font-zen-body font-semibold text-regent-600 text-sm align-center pt-5 leading-5 border-b border-zen-dark-4',
  };

  const showGroupMemberBorder = (
    property: ResourceTableRowGroupByVariant,
    row: any,
    rows: any[],
    index: number,
  ) => {
    switch (property) {
      case 'email':
        return isLastGroupMember(row, rows, index);

      case 'none':
      default:
        return false;
    }
  };

  const TableRowComponent =
    variant === ResourceTableVariant.CARD
      ? ZenResourceTableRow
      : TableRowVariantMap[resourceContainerDisplayVariant];

  const showTableHeader =
    !hideHeader &&
    variant === ResourceTableVariant.ROW &&
    resourceContainerDisplayVariant === 'TABLE';

  return (
    <div>
      <div
        className={classNames('w-full scrollbar overflow-x-auto', {
          'lg:max-h-[80vh]': stickyHeader,
        })}
        data-testid='zen-resource-table'
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={rows} strategy={verticalListSortingStrategy}>
            <table {...getTableProps()} className='relative table-auto w-full'>
              {showTableHeader && (
                <thead
                  className={cn(headerVariantStyle[headerVariant], {
                    'sticky top-0 z-[1]': stickyHeader,
                  })}
                  data-testid='table-head'
                >
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {allowSortingRows && (
                        <th className='font-primary-regular font-normal align-top p-2 whitespace-nowrap' />
                      )}
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps()}
                          className={classNames(
                            'whitespace-nowrap',
                            compact ? 'px-2 py-3.5' : 'px-5 py-3.5',
                            headerCellVariantStyle[headerVariant],
                            {
                              'rounded-l': index === 0,
                              'rounded-r':
                                index === headerGroup.headers.length - 1,
                            },
                          )}
                        >
                          <div
                            className={classNames(
                              'flex flex-row flex-nowrap',
                              {
                                'cursor-pointer': column.canSort,
                              },
                              column.headerContentClassName,
                            )}
                            onClick={() => {
                              if (column.canSort) {
                                column.toggleSortBy(
                                  column.isSorted && !column.isSortedDesc,
                                );
                              }
                            }}
                          >
                            {column.render('Header')}
                            {column.canSort && (
                              <div
                                className={classNames(
                                  'flex flex-col flex-nowrap justify-center',
                                )}
                              >
                                <FontAwesomeIcon
                                  icon={faCaretUp}
                                  className={classNames(
                                    'text-gray-400 ml-1',
                                    column.isSorted && !column.isSortedDesc
                                      ? 'invisible'
                                      : 'visible',
                                  )}
                                />
                                <FontAwesomeIcon
                                  icon={faCaretDown}
                                  className={classNames(
                                    'text-gray-400 -mt-1.5 ml-1',
                                    column.isSorted && column.isSortedDesc
                                      ? 'invisible'
                                      : 'visible',
                                  )}
                                />
                              </div>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
              )}
              <tbody
                {...getTableBodyProps()}
                className={classNames({
                  'space-y-4': resourceContainerDisplayVariant === 'CARDS',
                  'grid grid-flow-row grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4':
                    variant === ResourceTableVariant.CARD,
                })}
              >
                <TableResourceContainer
                  loading={loading}
                  isEmpty={!filteredRows.length}
                  isError={isError}
                  resourceName={resourceName}
                  colSpan={visibleColumns.length}
                  emptyIconComponent={emptyIconComponent}
                  customEmptyComponent={customEmptyComponent}
                >
                  {page.map((row, index) => {
                    prepareRow(row);
                    return !!isDropzone && !!dropboxId ? (
                      <ZenResourceDropzoneTableRow
                        key={row.id}
                        cellVerticalAlignment={cellVerticalAlignment}
                        row={row}
                        variant={variant}
                        visibleColumns={visibleColumns}
                        renderToggleRowComponent={renderToggleRowComponent}
                        allowSortingRows={allowSortingRows}
                        index={index}
                        pageLength={page.length}
                        isDropzone={isDropzone}
                        checklistId={checklistId}
                        hideRowBorder={hideRowBorder}
                        hideGroupMemberBorder={showGroupMemberBorder(
                          groupByProperty!,
                          row,
                          rows,
                          index,
                        )}
                        dropboxId={dropboxId}
                        compact={compact}
                      />
                    ) : (
                      <TableRowComponent<D>
                        key={row.id}
                        cellVerticalAlignment={cellVerticalAlignment}
                        row={row}
                        variant={variant}
                        visibleColumns={visibleColumns}
                        renderToggleRowComponent={renderToggleRowComponent}
                        isExpandable={isDisplayVariantCardExpandable}
                        getTableRowClassNames={getTableRowClassNames}
                        allowSortingRows={allowSortingRows}
                        index={index}
                        pageLength={page.length}
                        hideRowBorder={hideRowBorder}
                        hideGroupMemberBorder={showGroupMemberBorder(
                          groupByProperty!,
                          row,
                          rows,
                          index,
                        )}
                        compact={compact}
                      />
                    );
                  })}
                </TableResourceContainer>
              </tbody>
            </table>
          </SortableContext>
        </DndContext>
      </div>
      {data?.length > 0 && !hidePagination && (
        <div
          className={classNames(
            'py-5 flex flex-row items-center justify-between',
            paginationContainerClassNames,
          )}
        >
          <div className='hidden lg:inline-block'>
            {!hidePageSize && (
              <ZenTablePageSize
                itemsToShow={state.pageSize}
                setPageSize={setPageSize}
                pageSizeOptions={pageSizeOptions}
                totalCount={totalCount}
                showTotalCount={showTotalCount}
              />
            )}
          </div>
          <ZenTablePagination
            currentPage={state.pageIndex}
            pageSize={state.pageSize}
            lastPage={pageCount}
            totalCount={totalCount}
            goToPage={gotoPage}
          />
        </div>
      )}
    </div>
  );
};

export default ZenResourceTable;
