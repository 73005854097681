import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateNoteRequestEntityTypeEnum } from '../../../openapi/yenta';
import { toggleAddCommissionParticipant } from '../../../slices/QuickActionSlice';
import { RootState } from '../../../types';
import ZenAddParticipantsForm from '../Transaction/ZenAddParticipantsForm';
import ZenTransactionNotesContainer from '../Transaction/ZenTransactionNotesContainer';
import ZenCard from '../ZenCard';
import { isTeamAdminOrTeamLeader } from '../../../utils/TeamHelper';
import ZenListingInformationCard from './ZenListingInformationCard';
import ZenListingAllParticipants from './ZenListingAllParticipants';

interface ZenListingDetailProps {}

const ZenListingDetail: React.FC<ZenListingDetailProps> = () => {
  const {
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
    },
    auth: { isAdmin, isBroker, userDetail },
    quickAction: { addCommissionParticipantSidebar },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  if (!transactionDetail) {
    return null;
  }

  return (
    <>
      <div className='p-4'>
        <ZenListingInformationCard transaction={transactionDetail} />
      </div>

      {addCommissionParticipantSidebar?.isOpen && (
        <ZenAddParticipantsForm
          isOpen={addCommissionParticipantSidebar?.isOpen || false}
          onClose={() =>
            dispatch(toggleAddCommissionParticipant({ isOpen: false }))
          }
          transaction={transactionDetail}
          agentType={addCommissionParticipantSidebar?.agentType}
          role={addCommissionParticipantSidebar?.role}
        />
      )}

      <ZenListingAllParticipants transaction={transactionDetail} />

      {(isAdmin || isBroker || isTeamAdminOrTeamLeader(userDetail!)) && (
        <div className='py-4 px-8'>
          <ZenCard title='Notes'>
            <ZenTransactionNotesContainer
              entityId={transactionDetail.id!}
              readOnly={
                isTeamAdminOrTeamLeader(userDetail!) && !(isAdmin || isBroker)
              }
              entityType={CreateNoteRequestEntityTypeEnum.Transaction}
            />
          </ZenCard>
        </div>
      )}
    </>
  );
};

export default ZenListingDetail;
