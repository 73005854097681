import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface InputInfoBannerProps {
  message: string;
}

const InputInfoBanner = (props: InputInfoBannerProps) => {
  const { message } = props;
  return (
    <div className='p-3 bg-regent-100 my-2 flex items-center'>
      <FontAwesomeIcon
        icon={faInfoCircle}
        className='text-rezen-blue-600 text-sm'
      />
      <span className='font-light text-sm text-grey-500 ml-1'>{message}</span>
    </div>
  );
};

export default InputInfoBanner;
