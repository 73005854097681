import { AxiosRequestConfig } from 'axios';
import { values } from 'lodash';
import { GooglePlaceLocationType } from '../components/ControlledGoogleAutocompleteSearchInputV7';
import {
  AddressRequest,
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
  AddressResponse,
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
  AdministrativeAreaResponseCountryEnum,
  AgentResponseDefaultCurrencyEnum,
  MlsControllerApi,
  MlsRequestBillingFrequencyEnum,
  MlsRequestBillingTypeEnum,
  MlsRequestIdxFeedStatusEnum,
  MlsRequestPaymentTypeEnum,
  MlsRequestStatusEnum,
  MlsRequestUnreportedLicenseRiskEnum,
  MlsResponse,
  MlsResponseBillingFrequencyEnum,
  MlsResponseBillingTypeEnum,
  MlsResponseIdxFeedStatusEnum,
  MlsResponsePaymentTypeEnum,
  MlsResponseStatusEnum,
  MlsResponseUnreportedLicenseRiskEnum,
  MlsSecretsResponse,
  MoneyValueCurrencyEnum,
} from '../openapi/yenta';
import { EnumMap, IPaginateReq, SearchParam } from '../types';
import { getYentaConfiguration } from './OpenapiConfigurationUtils';
import { capitalizeEnum } from './StringUtils';
import { SortDirectionTypeEnum } from './TableUtils';
import { getAddressFromAddressComponent } from './TransactionHelper';

export type SearchMLSSortByType = Parameters<
  typeof MlsControllerApi.prototype.searchMls
>[3];

export const MLSSortByTypeEnum: EnumMap<string, SearchMLSSortByType> = {
  id: ['ID'],
  name: ['NAME'],
  code: ['CODE'],
  emailAddress: ['EMAIL'],
  joinDate: ['JOIN_DATE'],
  status: ['STATUS'],
};

export const fetchMLSSearchData = async (
  req: IPaginateReq<MlsResponse>,
  search?: string,
  axiosOptions?: AxiosRequestConfig,
) => {
  const sortKey = Object.keys(req.sortBy || {})[0];
  const sortType = Object.values(req.sortBy || {})[0];

  const { data } = await new MlsControllerApi(
    getYentaConfiguration(),
  ).searchMls(
    req.page,
    req.pageSize,
    SortDirectionTypeEnum[sortType!],
    MLSSortByTypeEnum[sortKey!],
    search,
    req.filter?.name as string,
    req.filter?.email as string,
    req.filter?.code as string,
    (req.filter?.id as SearchParam)?.value,
    // @ts-ignore
    (req.filter[`administrativeAreas:id`] as SearchParam)?.values as any[],
    (req.filter?.status as SearchParam)?.values as any[],
    axiosOptions,
  );

  return {
    data: data?.results || [],
    total: data?.totalCount || 0,
  };
};

export const countryOptions = [
  { label: 'Select Country', value: '', disabled: true },
  ...values(AdministrativeAreaResponseCountryEnum).map((country) => {
    return { value: country, label: capitalizeEnum(country) };
  }),
];

export const statusOptions = [
  { label: 'Select Status', value: '', disabled: true },
  ...values(MlsResponseStatusEnum).map((state) => {
    return { value: state, label: capitalizeEnum(state) };
  }),
];

export const unreportedLicenseRiskOptions = [
  { label: 'Select Unreported Liscense Risk', value: '', disabled: true },
  ...values(MlsResponseUnreportedLicenseRiskEnum).map((state) => {
    return { value: state, label: capitalizeEnum(state) };
  }),
];

export const billingTypeOptions = [
  { label: 'Select Billing Type', value: '', disabled: true },
  ...values(MlsResponseBillingTypeEnum).map((state) => {
    return { value: state, label: capitalizeEnum(state) };
  }),
];

export const idxFeedStatusOptions = [
  { label: 'Select IDX Feed Status', value: '', disabled: true },
  ...values(MlsResponseIdxFeedStatusEnum).map((state) => {
    return { value: state, label: capitalizeEnum(state) };
  }),
];

export const billingFrequencyOptions = [
  { label: 'Select Billing Frequency', value: '', disabled: true },
  ...values(MlsResponseBillingFrequencyEnum).map((state) => {
    return { value: state, label: capitalizeEnum(state) };
  }),
];

export const paymentTypeOptions = [
  { label: 'Select Payment Type', value: '', disabled: true },
  ...values(MlsResponsePaymentTypeEnum).map((state) => {
    return { value: state, label: capitalizeEnum(state) };
  }),
];

export const getDefaultFormValuesForMlsForm = (
  details?: MlsResponse,
  secrets?: MlsSecretsResponse,
  currency?: AgentResponseDefaultCurrencyEnum,
) => {
  return {
    logo: details?.logo,
    name: details?.name,
    isManualAddress: false,
    location: !!details?.address
      ? getGoogleLocationFromAddress(details?.address)
      : (undefined as any),
    address: (details?.address as unknown) as AddressRequest,
    officeCode: details?.officeCode,
    registrationFee: {
      amount: details?.registrationFee?.amount,
      currency:
        details?.registrationFee?.currency ??
        ((currency as unknown) as MoneyValueCurrencyEnum),
    },
    brokerOrMemberId: details?.brokerOrMemberId,
    administrativeAreas: {
      country: (details?.administrativeAreas?.[0]?.country ||
        '') as AdministrativeAreaRequestCountryEnum,
      stateOrProvince: (details?.administrativeAreas?.[0]?.stateOrProvince ||
        '') as AdministrativeAreaRequestStateOrProvinceEnum,
    },
    joinDate: details?.joinDate,
    status: (details?.status || '') as MlsRequestStatusEnum,

    phoneNumber: details?.phoneNumber,
    email: details?.email,

    username: secrets?.username,
    password: secrets?.password,

    billingType: (details?.billingType || '') as MlsRequestBillingTypeEnum,
    billingFrequency: (details?.billingFrequency ||
      '') as MlsRequestBillingFrequencyEnum,
    recurringFee: {
      amount: details?.recurringFee?.amount,
      currency:
        details?.recurringFee?.currency ??
        ((currency as unknown) as MoneyValueCurrencyEnum),
    },
    paymentType: (details?.paymentType || '') as MlsRequestPaymentTypeEnum,
    nextDueDate: details?.nextDueDate,
    unreportedLicenseRisk: (details?.unreportedLicenseRisk ||
      '') as MlsRequestUnreportedLicenseRiskEnum,

    code: details?.code,
    idxFeedStatus: (details?.idxFeedStatus ||
      '') as MlsRequestIdxFeedStatusEnum,
  };
};

export const getAddressFromGoogleLocation = (
  location: GooglePlaceLocationType,
) => {
  return {
    streetAddress1: getAddressFromAddressComponent(location.address_components!)
      ?.streetAddress1,
    streetAddress2: getAddressFromAddressComponent(location.address_components!)
      ?.streetAddress2,
    city: getAddressFromAddressComponent(location.address_components!)?.city!,
    stateOrProvince: (getAddressFromAddressComponent(
      location.address_components!,
    )?.stateOrProvince! as unknown) as AddressRequestStateOrProvinceEnum,
    zipOrPostalCode: getAddressFromAddressComponent(
      location.address_components!,
    )?.zipOrPostalCode!,
    country: (getAddressFromAddressComponent(location.address_components!)
      ?.country! as unknown) as AddressRequestCountryEnum,
    type: '',
  };
};

export const getGoogleLocationFromAddress = (address: AddressResponse) => {
  return {
    formatted_address: address?.oneLine,
    address_components: [],
    geometry: { location: { lat: undefined, lng: undefined } },
    place_id: '',
  };
};

export const isCollapsible = (
  formValues: any,
  errors: any,
  fields: string[],
) => {
  return fields.every((field) => {
    const hasValue = formValues.hasOwnProperty(field) && !!formValues[field];
    const hasNoError = !errors.hasOwnProperty(field);

    // Check for nested objects
    if (field.split('.').length > 1) {
      const [parent, child] = field.split('.');
      return !errors?.[parent]?.[child] && formValues?.[parent]?.[child];
    }

    return hasValue && hasNoError;
  });
};

export const isMlsAgentActive = (
  status: MlsResponseStatusEnum = MlsResponseStatusEnum.Active,
) => {
  const agentStatusMapp: EnumMap<MlsResponseStatusEnum, boolean> = {
    ACTIVE: true,
    INACTIVE: false,
    NOT_MEMBERS: false,
    PENDING: false,
    WILLING_TO_JOIN: false,
  };
  return agentStatusMapp[status];
};
