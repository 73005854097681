import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { ChecklistApi, ItemResponse } from '../../../openapi/sherlock';
import ErrorService from '../../../services/ErrorService';
import { showErrorToastForErrorCode } from '../../../slices/ToastNotificationSlice';
import { RootState } from '../../../types';
import { getSherlockConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ResourceContainer from '../../ResourceContainer';
import VerticalJourneyStepProgress from '../../RoadToSuccess/VerticalJourneyStepProgress';
import ZenJourneyProgressCell from './ZenJourneyProgressCell';

interface ZenJourneyPopperProps {
  journeyId: string | undefined;
  transactionId: string | undefined;
  isListing?: boolean;
}

const ZenJourneyPopper: React.FC<ZenJourneyPopperProps> = ({
  journeyId,
  transactionId,
  isListing,
}) => {
  const dispatch = useDispatch();
  const { checklistProgressById } = useSelector(
    (state: RootState) => state.checklist,
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checklistItems, setChecklistItems] = useState<ItemResponse[]>([]);
  const history = useHistory();

  const checklistProgress = journeyId
    ? checklistProgressById[journeyId]
    : undefined;

  const progress =
    Math.round(
      ((checklistProgress?.completedCountIncludingOptionals || 0) /
        (checklistProgress?.itemCountIncludingOptionals || 0)) *
        100,
    ) || 0;

  const getChecklistDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await new ChecklistApi(
        getSherlockConfiguration(),
      ).getChecklistById(journeyId!);
      setChecklistItems(data.items || []);
    } catch (e) {
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to fetch the checklists details',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, journeyId]);

  useEffect(() => {
    if (!!journeyId && isOpen) {
      getChecklistDetails();
    }
  }, [journeyId, getChecklistDetails, isOpen]);

  return (
    <div onMouseLeave={() => setIsOpen(false)}>
      <Popover
        isOpen={isOpen}
        positions={['top', 'bottom']}
        onClickOutside={() => setIsOpen(false)}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor='white'
            arrowSize={10}
            arrowStyle={{
              bottom: 1,
              zIndex: 1,
            }}
            className='popover-arrow-container'
            arrowClassName='popover-arrow'
          >
            <div className='w-[381px] z-30 border rounded-xl border-grey-200 bg-primary-light divide-y-2 space-y-2 py-2 text-primary-dark shadow-2xl'>
              <div className='flex items-center justify-between px-4'>
                <div className='text-base pt-2'>
                  Road To Success Progress: {progress}%
                </div>
                <div
                  onClick={() => setIsOpen(false)}
                  className='bg-grey-400 rounded-full w-5 h-5 flex items-center justify-center text-primary-light cursor-pointer'
                >
                  <FontAwesomeIcon icon={faClose} size='sm' />
                </div>
              </div>
              <div className='py-2 px-4 max-h-64 scrollbar overflow-y-auto'>
                <ResourceContainer
                  loading={isLoading}
                  isEmpty={false}
                  resourceName='Road To Success'
                >
                  <VerticalJourneyStepProgress steps={checklistItems || []} />
                </ResourceContainer>
              </div>
            </div>
          </ArrowContainer>
        )}
      >
        {journeyId ? (
          <div
            onMouseEnter={() => setIsOpen(true)}
            onClick={() => {
              const routePrefix = isListing ? 'listings' : 'transactions';
              history.push(`/${routePrefix}/${transactionId}`);
            }}
            className='cursor-pointer'
            aria-label='journey-popper'
          >
            <ZenJourneyProgressCell progress={progress} percentageBar />
          </div>
        ) : (
          <div className='pl-2'>N/A</div>
        )}
      </Popover>
    </div>
  );
};

export default ZenJourneyPopper;
