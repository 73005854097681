import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { ReactComponent as Processing } from '../../../assets/img/processing.svg';
import {
  ConveyanceLetterRevisionResponse,
  ConveyanceLetterRevisionResponseStatusEnum,
} from '../../../openapi/arrakis';
import ZenButton from '../ZenButton';

interface ZenConveyanceDocumentPreviewHeaderProps {
  transactionAddress: string;
  numberOfDocuments: number;
  conveyanceDocument: ConveyanceLetterRevisionResponse;
  isSubmitting: boolean;
  onSubmit(): void;
  onDownload(): void;
}

const ZenConveyanceDocumentPreviewHeader: React.FC<ZenConveyanceDocumentPreviewHeaderProps> = ({
  transactionAddress,
  numberOfDocuments,
  conveyanceDocument,
  isSubmitting,
  onSubmit,
  onDownload,
}) => {
  const [index, setIndex] = useState<number>(1);
  const isDraft =
    conveyanceDocument.status ===
    ConveyanceLetterRevisionResponseStatusEnum.Draft;
  const isValid =
    conveyanceDocument.status ===
    ConveyanceLetterRevisionResponseStatusEnum.Valid;

  const handleNext = () => {
    const nextIndex = index + 1;
    setIndex(nextIndex);
    document.querySelector(`#header-${nextIndex}`)?.scrollIntoView();
  };

  return (
    <div className='sticky top-0 bg-white'>
      <div className='flex flex-col md:flex-row md:justify-between md:items-center px-2 md:px-4 py-2 space-y-1 md:space-y-0'>
        <div className='flex flex-col flex-grow flex-shrink space-y-1'>
          <p className='font-primary-medium text-2xl text-dark'>
            {transactionAddress}
          </p>
          <div className='flex flex-row items-center space-x-3'>
            <p className='font-primary-regular text-base md:text-xl text-dark'>
              Conveyance Documents
            </p>
            <div className='flex flex-row items-center space-x-2'>
              <div className='w-px h-3 bg-gray-400' />
              <p className='font-primary-regular text-base text-gray-400'>{`${numberOfDocuments} documents to review`}</p>
            </div>
          </div>
        </div>
        <div className='flex flex-row items-center space-x-2'>
          {index < conveyanceDocument?.conveyanceDocuments?.length! && (
            <ZenButton
              label='Next document'
              variant='primary-outline'
              LeftIconComponent={
                <FontAwesomeIcon
                  icon={faArrowDown}
                  className='text-primary-blue'
                />
              }
              onClick={handleNext}
            />
          )}

          <div className='flex flex-row items-center space-x-2'>
            {index < conveyanceDocument?.conveyanceDocuments?.length! && (
              <div className='hidden md:block w-px h-5 mx-0 md:mx-1 lg:4 bg-gray-300' />
            )}
            <ZenButton
              label={!isDraft ? 'Download PDFs' : 'Generate Final PDFs'}
              isDisabled={!(isDraft || isValid)}
              isSubmitting={isSubmitting}
              LeftIconComponent={
                <Processing fontSize={16} className='text-white' />
              }
              onClick={!isDraft ? onDownload : onSubmit}
            />
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default ZenConveyanceDocumentPreviewHeader;
