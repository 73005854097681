import React, { useEffect } from 'react';
import { Popover } from 'react-tiny-popover';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { cn } from '../../../utils/classUtils';
import ZenSearchBar from '../../Zen/ZenSearchBar';
import { CommentTemplateResponse } from '../../../openapi/yada';
import { RootState } from '../../../types';

interface ZenTemplatePopperProps {
  handleTemplateSelect: (text: string) => void;
}

const ZenTemplatePopper: React.FC<ZenTemplatePopperProps> = ({
  handleTemplateSelect,
}) => {
  const { commentTemplates } = useSelector(
    (state: RootState) => state.transaction,
  );

  const [hoveredItemId, setHoveredItemId] = React.useState<string | null>(null);
  const [templateItems, setTemplateItems] = React.useState<
    CommentTemplateResponse[]
  >(commentTemplates);
  const [searchText, setSearchText] = React.useState('');

  const handleSearchTemplate = (searchText: string) => {
    setSearchText(searchText);
    if (searchText === '') {
      setTemplateItems(commentTemplates);
    } else {
      setTemplateItems(
        commentTemplates.filter(
          (item: CommentTemplateResponse) =>
            item.message.toLowerCase().includes(searchText.toLowerCase()) ||
            item.type.toLowerCase().includes(searchText.toLowerCase()),
        ),
      );
    }
  };

  useEffect(() => {
    if (!isEmpty(commentTemplates)) {
      setTemplateItems(commentTemplates);
    }
  }, [commentTemplates]);

  const onScroll = () => {
    if (hoveredItemId) {
      setHoveredItemId(null);
    }
  };

  return (
    <div
      className='bg-white w-[295px] h-[281px] border border-zen-dark-4 rounded-md shadow-lg'
      data-testid='templatesContainer'
    >
      <div className='p-2'>
        <ZenSearchBar
          onChange={(text) => handleSearchTemplate(text)}
          value={searchText}
          placeholder='Search Key Words'
        />
      </div>

      <div className='overflow-y-auto h-[220px]' onScroll={onScroll}>
        {templateItems?.map((item: CommentTemplateResponse) => {
          const isLast =
            templateItems.length - 1 === templateItems.indexOf(item);
          const isOpen = hoveredItemId === item.id;
          return (
            <div key={item.id} data-testid={item.message}>
              <Popover
                isOpen={isOpen}
                positions={['right', 'top']}
                onClickOutside={() => setHoveredItemId(null)}
                content={() => (
                  <div className='border border-primary-dark px-2.5 py-2 rounded-[5px] w-[241px] text-sm font-normal text-zen-dark -ml-4 bg-primary-light font-inter'>
                    {item?.message}
                  </div>
                )}
              >
                <div
                  className={cn(
                    'text-primary-dark font-light text-sm px-5 py-1.5 hover:bg-rezen-light-blue-100 cursor-pointer',
                    isLast ? '' : 'border-b-2',
                  )}
                  onMouseEnter={() => {
                    if (item.message.length <= 65) {
                      return;
                    }
                    setHoveredItemId(item.id);
                  }}
                  onClick={() => handleTemplateSelect(item.message)}
                  onMouseLeave={() => setHoveredItemId(null)}
                >
                  <p className='text-sm font-light text-primary-dark font-inter line-clamp-2'>
                    <span className='font-medium text-base'>{item.type}: </span>
                    <span>{item.message}</span>
                  </p>
                </div>
              </Popover>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ZenTemplatePopper;
