import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import DogImage from '../../../assets/img/zen/real-mortgage/V2/dog.png';
import ZenButton from '../../Zen/ZenButton';

export const PRE_APPROVED_POINTS = [
  'Get notified when your client is pre-approved.',
  "Track your client's loan application status.",
  'Sit back. Relax. Your One Real Mortgage Team is on it.',
];

interface OneRealMortgagePreApprovedProps {
  onGetClientPreApproved?: () => void;
}

const OneRealMortgagePreApproved: React.FC<OneRealMortgagePreApprovedProps> = ({
  onGetClientPreApproved,
}) => {
  return (
    <div className='flex items-center gap-12'>
      <div className='hidden sm:block sm:w-1/2'>
        <img
          src={DogImage}
          alt='pre-approved'
          className='w-full max-w-4xl lg:aspect-video lg:object-cover'
        />
      </div>
      <div className='w-full sm:w-1/2'>
        <h1 className='font-poppins text-[40px] font-semibold flex flex-col leading-[48px]'>
          <span>Get Your Client</span>
          <span>Pre-Approved!</span>
        </h1>
        <div className='mt-6 flex flex-col gap-2'>
          {PRE_APPROVED_POINTS.map((point) => (
            <div key={point} className='flex items-center gap-2'>
              <FontAwesomeIcon
                icon={faCircleCheck}
                className='text-[#83C8D0]'
              />
              <p>{point}</p>
            </div>
          ))}
        </div>
        <div className='mt-6'>
          <ZenButton
            label='Get Your Client Pre-Approved'
            RightIconComponent={<FontAwesomeIcon icon={faArrowUpRight} />}
            onClick={onGetClientPreApproved}
            buttonProps={{
              style: {
                display: 'flex',
                gap: 6,
                fontFamily: 'Inter',
                lineHeight: '20px',
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OneRealMortgagePreApproved;
