import { useSelector } from 'react-redux';
import {
  TransactionSetupFormProps,
  TransactionSetupStepName,
} from '../../../containers/transaction/setup/TransactionSetupContainer';
import { RootState } from '../../../types';
import IconButton from '../../IconButton';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import TransactionAddressBlock from '../TransactionAddressBlock';

const TransactionSetupWelcome: StepByStepComponent<
  TransactionSetupFormProps,
  TransactionSetupStepName
> = ({ onNext }) => {
  const {
    auth: { userDetail },
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
    },
  } = useSelector((state: RootState) => state);

  return (
    <div className='flex-grow flex justify-center'>
      <div className='max-w-xl mt-10'>
        <div className='w-full flex-1'>
          <p className='text-xl font-primary-medium mb-4'>
            Hi, {userDetail?.fullName!} 👋
          </p>
          <TransactionAddressBlock
            addressOneLine={transactionDetail?.address?.oneLine!}
          />
          <p className='my-3'>
            You&apos;re on your way to your next successful deal!
          </p>
          <p className='mb-3'>
            Let&apos;s answer a few questions about the transaction before
            moving on.
          </p>
          <IconButton
            buttonType='button'
            label="Let's get started"
            onClick={onNext}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionSetupWelcome;
