import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  REAL_TITLE_INSTAGRAM_URL,
  REAL_TITLE_LINKEDIN_URL,
} from '../../constants/MarketingCenterLinksConstants';

const socialLinks = [
  { icon: brands('instagram'), url: REAL_TITLE_INSTAGRAM_URL },
  { icon: brands('linkedin'), url: REAL_TITLE_LINKEDIN_URL },
];

const RealClosingServicesHeaderSection: React.FC = () => {
  return (
    <div
      className='flex items-center justify-between mb-6'
      data-testid='real-closing-services-header-section'
    >
      <h2 className='text-lg font-medium font-poppins whitespace-nowrap'>
        Real Closing Services
      </h2>
      <div className='flex items-center gap-6'>
        <p className='font-inter text-sm font-normal text-primary-dark invisible sm:visible'>
          Connect with us
        </p>
        <div className='flex gap-4'>
          {socialLinks.map((link) => (
            <FontAwesomeIcon
              key={link.url}
              title={link.url}
              icon={link.icon}
              className='text-xl cursor-pointer'
              onClick={() => window.open(link.url, '_blank')}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RealClosingServicesHeaderSection;
