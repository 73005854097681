import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export interface ZenDirectoryWarningProps {}

const ZenDirectoryWarning: React.FC<ZenDirectoryWarningProps> = () => {
  return (
    <div className='flex flex-row space-x-2 items-center bg-zen-warning-light text-zen-warning-dark p-2 h-14 rounded-lg'>
      <FontAwesomeIcon icon={regular('triangle-exclamation')} />
      <span className='font-zen-body'>
        Complete the Tax details area to verify the contact
      </span>
    </div>
  );
};

export default ZenDirectoryWarning;
