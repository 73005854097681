import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { cn } from '../utils/classUtils';

export interface AccordionProps {
  title: string;
  isOpenDefault?: boolean;
  canToggle?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  isOpenDefault = false,
  children,
  canToggle = true,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenDefault);

  return (
    <div>
      <div
        className={cn(
          'w-full flex flex-row items-center justify-between p-3 border-b cursor-pointer',
          isOpen
            ? 'bg-legacy-blue/10 border-legacy-blue/30'
            : 'bg-grey-100 border-grey-300',
        )}
        onClick={() => {
          if (canToggle) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <div className='font-primary-regular text-primary-dark'>{title}</div>
        <FontAwesomeIcon
          icon={isOpen ? faAngleUp : faAngleDown}
          className='text-legacy-blue'
        />
      </div>
      {isOpen && children}
    </div>
  );
};

export default Accordion;
