import { useCallback, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarEventInviteeResponseRsvpStatusEnum } from '../../../openapi/yenta';
import { fetchEventInviteesByStatus } from '../../../slices/CalendarSlice';
import { RootState } from '../../../types';
import {
  DEFAULT_INVITEES_PAGE_NUMBER,
  DEFAULT_INVITEES_PAGE_SIZE,
} from '../../../utils/EventHelper';
import DefaultLoader from '../../DefaultLoader';
import ResourceContainer from '../../ResourceContainer';
import ZenAvatar from '../ZenAvatar';

interface ZenEventInviteesProps {
  calendarEventId: string;
  status: CalendarEventInviteeResponseRsvpStatusEnum;
}

const ZenEventInvitees: React.FC<ZenEventInviteesProps> = ({
  calendarEventId,
  status,
}) => {
  const dispatch = useDispatch();
  const {
    pagedEventInviteesByStatus,
    eventInviteesLoading,
    eventInviteesErrorCode,
  } = useSelector((state: RootState) => state.calendar);

  const { invitees, hasNext, pageNumber } =
    pagedEventInviteesByStatus[status] || {};

  const getEventInvitees = useCallback(
    async (
      calendarEventId: string,
      status: CalendarEventInviteeResponseRsvpStatusEnum,
      pageNumber: number,
      refresh: boolean,
    ) => {
      await dispatch(
        fetchEventInviteesByStatus(
          calendarEventId,
          status,
          pageNumber,
          DEFAULT_INVITEES_PAGE_SIZE,
          refresh,
        ),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    getEventInvitees(
      calendarEventId,
      status,
      DEFAULT_INVITEES_PAGE_NUMBER,
      true,
    );
  }, [calendarEventId, dispatch, getEventInvitees, status]);

  return (
    <div className='px-8 py-4'>
      <div className='h-[400px]'>
        <ResourceContainer
          isEmpty={!invitees?.length}
          loading={eventInviteesLoading}
          resourceName='invitee'
          errorCode={eventInviteesErrorCode}
        >
          <InfiniteScroll
            hasMore={!!hasNext}
            loader={<DefaultLoader />}
            dataLength={invitees?.length || 0}
            height={400}
            next={async () =>
              await getEventInvitees(
                calendarEventId,
                status,
                pageNumber! + 1,
                false,
              )
            }
          >
            <div className='space-y-4'>
              {invitees?.map((invitee) => (
                <div
                  key={invitee.agentId}
                  className='flex items-center space-x-3'
                >
                  <ZenAvatar
                    name={`${invitee.firstName} ${invitee.lastName}`}
                    imageUrl={invitee.avatar}
                    size='lg'
                    showTooltip
                  />
                  <span className='font-zen-body font-semibold text-base text-zen-dark-9'>
                    {`${invitee.firstName} ${invitee.lastName}`}
                  </span>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </ResourceContainer>
      </div>
    </div>
  );
};

export default ZenEventInvitees;
