import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TransactionLifecycleStateValue,
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../../../openapi/arrakis';
import { setActiveTransition } from '../../../../slices/QuickActionSlice';
import { RootState } from '../../../../types';
import {
  getActionableTransitions,
  renderTransactionStatusByCountry,
} from '../../../../utils/TransactionHelper';
import ExcludeBroker from '../../../auth/ExcludeBroker';
import ExcludeRealTitleOfficer from '../../../auth/ExcludeRealTitleOfficer';
import {
  transactionStatusToIcon,
  transactionStatusToVariant,
} from '../../../table/Cells/TransactionStateCell';
import { safeEnumMapGet } from '../../../../utils/EnumHelper';
import ZenTransactionAction from './ZenTransactionAction';

interface ZenTransactionStatusInfoProps {
  transaction: TransactionResponse;
  setIsHelpSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export type ZenTransactionHeaderVariantType =
  | 'default'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'dark'
  | 'alert';

export const ZenTransactionStatusVariantTypeToClassNameMap: {
  [type in ZenTransactionHeaderVariantType]: string;
} = {
  default: 'bg-gray-200 text-dark',
  primary: 'bg-zen-light-blue text-primary-blue',
  success: 'bg-green-100 text-green-600',
  warning: 'bg-zen-warning-1 text-zen-warning-dark',
  danger: 'bg-zen-danger-light text-zen-danger',
  dark: 'bg-zen-dark-9 text-white',
  alert: 'bg-zen-purple-light text-zen-purple-dark',
};

const ZenTransactionStatusInfo: React.FC<ZenTransactionStatusInfoProps> = ({
  transaction,
  setIsHelpSidebarOpen,
}) => {
  const dispatch = useDispatch();
  const {
    auth: { isAdmin, isBroker },
  } = useSelector((state: RootState) => state);
  const lifecycleState = transaction.lifecycleState!;
  const transitionInfo = getActionableTransitions(transaction, isAdmin);
  const isCommissionValidated =
    transaction.lifecycleState?.state ===
    TransactionLifecycleStateValueStateEnum.CommissionValidated;
  const safeStatusVariantEnum = safeEnumMapGet(
    transactionStatusToVariant,
    lifecycleState?.state!,
    'warning',
  );

  return (
    <div
      id='transaction-status'
      data-testid='transaction-status'
      className='bg-white rounded-[10px] overflow-hidden'
    >
      <div
        className={classNames(
          safeEnumMapGet(
            ZenTransactionStatusVariantTypeToClassNameMap,
            safeStatusVariantEnum,
            '',
          ),
          'px-6 py-3 space-y-1',
        )}
      >
        <div className='flex flex-row items-start justify-between'>
          <div className='flex flex-row items-center space-x-2'>
            <div className='flex flex-col space-y-0.5'>
              {!!lifecycleState?.state && (
                <div className='flex flex-row items-center space-x-2'>
                  {safeEnumMapGet(
                    transactionStatusToIcon,
                    lifecycleState?.state!,
                    <FontAwesomeIcon icon={faXmark} className='text-xl' />,
                  )}
                  <p className='font-zen-body font-bold text-base'>
                    {renderTransactionStatusByCountry(
                      lifecycleState.state!,
                      transaction.country!,
                    )}
                  </p>
                </div>
              )}
              {!!lifecycleState?.description && (
                <p className='font-zen-body font-semibold text-sm'>
                  {lifecycleState.description}
                </p>
              )}
              <div className='space-y-4 flex flex-col py-2'>
                <ExcludeBroker>
                  <ExcludeRealTitleOfficer>
                    {transitionInfo.primaryTransitions.map(
                      (transition: TransactionLifecycleStateValue) => {
                        return (
                          <ZenTransactionAction
                            key={transition.state!}
                            action={transition.state!}
                            onClick={() =>
                              dispatch(setActiveTransition(transition))
                            }
                            transaction={transaction}
                          />
                        );
                      },
                    )}
                  </ExcludeRealTitleOfficer>
                </ExcludeBroker>

                {isBroker && isCommissionValidated && (
                  <ZenTransactionAction
                    action={transaction.nextPrimaryAdminTransition?.state!}
                    onClick={() =>
                      dispatch(
                        setActiveTransition(
                          transaction.nextPrimaryAdminTransition,
                        ),
                      )
                    }
                    transaction={transaction}
                  />
                )}
              </div>
            </div>
          </div>
          <FontAwesomeIcon
            icon={faCircleQuestion}
            className='text-dark cursor-pointer text-xl'
            aria-label='help-modal'
            onClick={() => setIsHelpSidebarOpen(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default ZenTransactionStatusInfo;
