import { ReactNode } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form-v7';
import { ZenRowsSelectionAction } from '../../types';
import ZenSimpleConfirmationModal from '../Zen/Modal/ZenSimpleConfirmationModal';

interface ZenFilterConfirmationModalProps<D extends object> {
  selection: ZenRowsSelectionAction<D>;
  isSubmitting: boolean;
  onClick: SubmitHandler<D>;
  onClose(): void;
}

const ZenFilterConfirmationModal = <D extends object>({
  selection,
  isSubmitting,
  onClick,
  onClose,
}: ZenFilterConfirmationModalProps<D>) => {
  const { control, handleSubmit } = useForm<D>({
    reValidateMode: 'onChange',
  });

  return (
    <ZenSimpleConfirmationModal
      title={selection.confirm!.title}
      variant={selection.confirm!.modalType || 'info'}
      subtitle={selection.confirm!.description}
      isOpen
      onClose={onClose}
      onConfirm={handleSubmit(onClick)}
      cancelButtonText={selection.confirm!.secondaryActionTitle || 'Cancel'}
      confirmButtonText={selection.confirm!.primaryActionTitle || 'Confirm'}
      isSubmitting={isSubmitting}
    >
      {selection?.confirm?.childJSX &&
        (selection?.confirm?.childJSX(control) as ReactNode)}
    </ZenSimpleConfirmationModal>
  );
};

export default ZenFilterConfirmationModal;
