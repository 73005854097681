import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ItemResponse } from '../../../openapi/sherlock';
import AnalyticsService from '../../../services/AnalyticsService';
import { markChecklistItemAsCompleted } from '../../../slices/CheckListSlice';
import { AnalyticsEventEnum } from '../../../types';
import ExcludeBroker from '../../auth/ExcludeBroker';
import ZenCheckBox from '../ZenCheckbox';
import ZenAgentJourneyDescription from './ZenAgentJourneyDescription';
import { ChecklistType } from './ZenVerticalJourneyStepProgress';

interface ZenAgentJourneyStepProps {
  step: ItemResponse;
  isLastStep: boolean;
}

const ZenAgentJourneyStep: React.FC<ZenAgentJourneyStepProps> = ({
  step,
  isLastStep,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const updateJourneyProgress = async () => {
    setLoading(true);
    await dispatch(
      markChecklistItemAsCompleted(
        step?.id!,
        !step?.complete,
        ChecklistType.AGENT_JOURNEY,
      ),
    );
    AnalyticsService.instance().logEvent(
      step?.complete
        ? AnalyticsEventEnum.ONBOARDING_CHECKLIST_UNMARK_ITEM_AS_DONE
        : AnalyticsEventEnum.ONBOARDING_CHECKLIST_MARK_ITEM_AS_DONE,
      {
        itemName: step?.name,
      },
    );
    setLoading(false);
  };

  return (
    <div
      key={step?.name}
      className={classNames(
        'relative flex space-x-5 border border-zen-dark-4 rounded-[10px] p-6',
        {
          'mb-3': !isLastStep,
        },
      )}
    >
      <ExcludeBroker>
        <ZenCheckBox
          variant='square'
          size='medium'
          value={step?.complete ?? false}
          onChange={updateJourneyProgress}
          disabled={loading}
        />
      </ExcludeBroker>
      <div className='flex flex-grow flex-col gap-1'>
        <span className='text-base font-zen-body font-semibold text-zen-dark-9 whitespace-pre-wrap'>
          {step?.name}
        </span>
        {!!step?.description && (
          <ZenAgentJourneyDescription description={step?.description} />
        )}
        {!!step?.completedAt && (
          <div className='flex font-zen-body text-sm text-zen-dark-7 pt-1'>
            <span className='font-semibold whitespace-pre-wrap'>
              Completed:{' '}
            </span>
            <span className={classNames('font-normal  text-gray-500')}>
              {DateTime.fromMillis(step?.completedAt!).toFormat(
                'DDDD hh:mm a ZZZZ',
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ZenAgentJourneyStep;
