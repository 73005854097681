import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface MortgageDetailsHeaderCellProps {
  icon: IconDefinition;
  text: string;
}

const MortgageDetailsHeaderCell: React.FC<MortgageDetailsHeaderCellProps> = ({
  icon,
  text,
}) => {
  return (
    <div className='flex items-center'>
      <FontAwesomeIcon icon={icon} className='text-white text-sm mr-1.5' />
      <p className='font-zen-body font-normal text-sm text-white'>{text}</p>
    </div>
  );
};

export default MortgageDetailsHeaderCell;
