interface ZenPrimaryGradientTextProps {
  children: React.ReactNode;
  className?: string;
  gradientStyle?: string;
}

/**
 * New Primary Gradient Text as seen in WealthPlan Sidebar Redesign
 */
export const ZenCustomPrimaryGradientText = ({
  children,
  className,
  gradientStyle,
  ...props
}: ZenPrimaryGradientTextProps) => {
  return (
    <span
      className={`${
        gradientStyle ?? 'bg-step-progress'
      } bg-clip-text text-transparent ${className}`}
      {...props}
    >
      {children}
    </span>
  );
};
