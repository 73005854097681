import {
  faAddressBook,
  faBuildingColumns,
  faCalendar,
  faListUl,
  faUserMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pickBy, values } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AddressResponse,
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaResponseCountryEnum,
  BoardRequest,
  BoardResponse,
  BoardResponseBillingFrequencyEnum,
  BoardResponseBillingTypeEnum,
  BoardResponseBrokerAffiliationEnum,
  BoardResponsePaymentTypeEnum,
  BoardResponseStatusEnum,
  BoardResponseUnreportedLicenseRiskEnum,
  BoardSecretsResponse,
} from '../../../openapi/yenta';
import {
  useCreateBoard,
  useUpdateBoard,
} from '../../../query/boardsAndMls/useBoardsAndMls';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { showErrorToastForErrorCode } from '../../../slices/ToastNotificationSlice';
import { AppDispatch, RootState } from '../../../types';
import {
  getAddressFromGoogleLocation,
  getDefaultFormValuesForBoardForm,
  isCollapsible,
} from '../../../utils/BoardUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import {
  EMAIL_VALIDATIONS,
  MONEY_VALIDATIONS,
  MONEY_VALUE_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../utils/Validations';
import AddressFormComponent from '../../Gemini/AddressFormComponent';
import GeminiAccordion from '../../Gemini/GeminiAccordion';
import GeminiProfileImage from '../../Gemini/GeminiProfileImage';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledEmailInput from '../../Zen/Input/ZenControlledEmailInput';
import { GooglePlaceLocationType } from '../../Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledHTMLSelectInput from '../../Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledMoneyInput from '../../Zen/Input/ZenControlledMoneyInput';
import ZenControlledPhoneNumberInput from '../../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledStateOrProvinceInput from '../../Zen/Input/ZenControlledStateOrProvince';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../../Zen/ZenSidebarModalForm';

interface GeminiCreateBoardFormProps {
  isOpen: boolean;
  boardDetails?: BoardResponse;
  secrets?: BoardSecretsResponse;
  onClose(): void;
}

export interface BoardFormData extends BoardRequest {
  location?: GooglePlaceLocationType;
  isManualAddress: boolean;
}

const isValidAddress = (address: AddressResponse) => {
  const { oneLine, streetAddress2, type, ...requiredFields } = address || {};

  return values(requiredFields).some((value) => value);
};

const GeminiCreateBoardForm: React.FC<GeminiCreateBoardFormProps> = ({
  isOpen,
  onClose,
  boardDetails,
  secrets,
}) => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  const { userDetail } = useSelector((state: RootState) => state.auth);

  const {
    mutate: createBoard,
    isLoading: createBoardLoading,
  } = useCreateBoard();

  const { mutate: updateBoard, isLoading: updateBoardLoading } = useUpdateBoard(
    boardDetails?.id!,
  );

  const methods = useForm<BoardFormData>({
    defaultValues: getDefaultFormValuesForBoardForm(
      boardDetails,
      secrets,
      userDetail?.defaultCurrency,
    ),
    mode: 'onChange',
  });

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  const formValues = watch();
  const [selectedCountry, isManualAddress] = watch([
    'administrativeArea.country',
    'isManualAddress',
  ]);

  const onError = (e: any, data: BoardRequest, type: 'create' | 'update') => {
    dispatch(showApiErrorModal(e));

    ErrorService.notify(`Unable to ${type} board`, e, {
      data,
    });

    dispatch(
      showErrorToastForErrorCode(
        `We couldn't ${type} the board`,
        ErrorService.getErrorCode(e),
      ),
    );
  };

  const onSubmit = async (data: BoardFormData) => {
    const filteredData = pickBy(data, (value) => value);
    filteredData.fee = !!filteredData?.fee?.amount
      ? filteredData.fee
      : undefined;

    if (!isManualAddress && !!data.location?.place_id) {
      filteredData.address = getAddressFromGoogleLocation(data.location);
    } else if (isValidAddress((data.address! as unknown) as AddressResponse)) {
      filteredData.address = { ...data.address!, type: '' };
    } else {
      filteredData.address = undefined;
    }

    delete filteredData.location;

    if (!!boardDetails) {
      updateBoard(
        {
          id: boardDetails.id!,
          boardData: {
            ...filteredData,
            registrationFee: filteredData.registrationFee!,
          },
        },
        {
          onSuccess: () => onClose(),
          onError: (e) => onError(e, data, 'update'),
        },
      );
    } else {
      createBoard(
        {
          boardData: {
            ...filteredData,
            registrationFee: filteredData.registrationFee!,
          },
        },
        {
          onSuccess: (data) => {
            if (data?.id) {
              history.push(`/boards/${data?.id}`);
            }
          },
          onError: (e) =>
            onError(
              e,
              {
                ...filteredData,
                registrationFee: filteredData.registrationFee!,
              },
              'create',
            ),
        },
      );
    }
  };

  return (
    <ZenSidebarModalForm
      title={!!boardDetails ? 'Edit Board' : 'Create Board'}
      isOpen={isOpen}
      onClose={onClose}
      isSubmitting={isSubmitting || createBoardLoading || updateBoardLoading}
      onSubmit={handleSubmit(onSubmit)}
      actionTitle={!!boardDetails ? 'Update' : 'Create'}
      cancelTitle='Cancel'
      isEqualWidth
    >
      <div className='pl-5 pr-6'>
        <FormProvider {...methods}>
          <div className='mt-10 mb-4 flex justify-center items-center'>
            <GeminiProfileImage
              width={134}
              imageUrl={boardDetails?.logo ?? '/img/default-avatar.svg'}
            />
          </div>

          {/* Board Details Section */}
          <GeminiAccordion
            leftIcon={faListUl}
            title='Board Details'
            isOpenDefault
            canToggle={isCollapsible(formValues, errors, [
              'name',
              'code',
              'registrationFee.amount',
              'joinDate',
              'status',
              'administrativeArea.stateOrProvince',
              'administrativeArea.country',
            ])}
          >
            <div className='flex flex-col gap-6 bg-regent-200 rounded-2xl px-5 py-4'>
              <ZenControlledTextInput<BoardFormData, 'name'>
                control={control}
                shouldUnregister={false}
                label='Board Name'
                name='name'
                placeholder='Enter Board Name'
                rules={{ required: 'Please provide a board name' }}
                isRequired
              />
              <ZenControlledTextInput<BoardFormData, 'code'>
                control={control}
                shouldUnregister={false}
                label='Board Code'
                name='code'
                placeholder='US-AKSMLS'
                rules={{
                  required: 'Please provide a board code',
                }}
                isRequired
              />
              <AddressFormComponent />
              <ZenControlledTextInput<BoardFormData, 'officeCode'>
                control={control}
                label='Office Code'
                name='officeCode'
                placeholder='T2M 3YU7'
                shouldUnregister={false}
              />
              <ZenControlledHTMLSelectInput<BoardFormData, 'brokerAffiliation'>
                name='brokerAffiliation'
                control={control}
                label='Broker Affiliation'
                placeholder='Select Broker Affiliation'
                options={[
                  {
                    label: 'Select Broker Affiliation',
                    value: '',
                    disabled: true,
                  },
                  ...values(BoardResponseBrokerAffiliationEnum).map(
                    (state) => ({
                      value: state,
                      label: capitalizeEnum(state),
                    }),
                  ),
                ]}
                shouldUnregister={false}
              />

              <div className='flex gap-3'>
                <ZenControlledHTMLSelectInput<
                  BoardFormData,
                  'administrativeArea.country'
                >
                  name='administrativeArea.country'
                  control={control}
                  shouldUnregister={false}
                  label='Country'
                  placeholder='Country'
                  options={[
                    {
                      label: 'Select Country',
                      value: '',
                      disabled: true,
                    },
                    ...values(AdministrativeAreaResponseCountryEnum).map(
                      (country) => ({
                        value: country,
                        label: capitalizeEnum(country),
                      }),
                    ),
                  ]}
                  rules={{
                    required: 'Please select country',
                  }}
                  isRequired
                />
                <ZenControlledStateOrProvinceInput<
                  BoardFormData,
                  'administrativeArea.stateOrProvince'
                >
                  selectedCountry={
                    (selectedCountry as unknown) as AdministrativeAreaRequestCountryEnum
                  }
                  name='administrativeArea.stateOrProvince'
                  control={control}
                  shouldUnregister={false}
                  rules={{
                    required: 'Please select state or province.',
                  }}
                  setValue={setValue}
                  isRequired
                />
              </div>
              <div className='flex gap-3'>
                <ZenControlledMoneyInput<BoardFormData, 'registrationFee'>
                  control={control}
                  shouldUnregister={false}
                  label='Registration Fee'
                  name='registrationFee'
                  placeholder='Enter registration fee'
                  rules={MONEY_VALUE_VALIDATIONS}
                  isRequired
                />
                <ZenControlledDatePickerInput<BoardFormData, 'joinDate'>
                  name='joinDate'
                  control={control}
                  shouldUnregister={false}
                  label='Join Date'
                  placeholder='Join Date'
                  rules={{
                    required: 'Please provide a join date',
                  }}
                  icon={
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className='text-primary-blue'
                    />
                  }
                  isRequired
                />
              </div>
              <ZenControlledHTMLSelectInput<BoardFormData, 'status'>
                name='status'
                control={control}
                shouldUnregister={false}
                label='Status'
                placeholder='Status'
                options={[
                  { label: 'Select Status', value: '', disabled: true },
                  ...values(BoardResponseStatusEnum).map((state) => ({
                    value: state,
                    label: capitalizeEnum(state),
                  })),
                ]}
                rules={{
                  required: 'Please select board status',
                }}
                isRequired
              />
            </div>
          </GeminiAccordion>

          {/* Contact Details Section */}
          <GeminiAccordion
            leftIcon={faAddressBook}
            title='Contact Details'
            isOpenDefault
            canToggle={isCollapsible(formValues, errors, [
              'phoneNumber',
              'email',
            ])}
          >
            <div className='flex flex-col gap-6 bg-regent-200 rounded-2xl px-5 py-4'>
              <ZenControlledPhoneNumberInput<BoardFormData, 'phoneNumber'>
                control={control}
                label='Phone Number'
                name='phoneNumber'
                placeholder='+1 (702) 123-4567'
                rules={{
                  required: 'Please provide a phone number',
                  ...PHONE_NUMBER_VALIDATIONS,
                }}
                isRequired
                shouldUnregister={false}
              />
              <ZenControlledEmailInput<BoardFormData, 'email'>
                control={control}
                label='Email Address'
                name='email'
                placeholder='example@gmail.com'
                rules={{
                  required: 'Please provide an email address',
                  ...EMAIL_VALIDATIONS,
                }}
                isRequired
                shouldUnregister={false}
              />
            </div>
          </GeminiAccordion>

          {/* Login Credentials Section  */}
          <GeminiAccordion
            leftIcon={faUserMagnifyingGlass}
            title='Login Credentials'
            isOpenDefault
          >
            <div className='flex flex-col gap-6 bg-regent-200 rounded-2xl px-5 py-4'>
              <ZenControlledTextInput<BoardFormData, 'username'>
                control={control}
                label='Username/ Email'
                name='username'
                placeholder='Enter Username/Email ID'
                shouldUnregister={false}
              />

              <ZenControlledTextInput<BoardFormData, 'password'>
                control={control}
                type='password'
                label='Password'
                name='password'
                placeholder='Password'
                shouldUnregister={false}
              />
            </div>
          </GeminiAccordion>

          {/* Finance Details Section */}
          <GeminiAccordion
            leftIcon={faBuildingColumns}
            title='Finance Details'
            isOpenDefault
          >
            <div className='flex flex-col gap-6 bg-regent-200 rounded-2xl px-5 py-4'>
              <div className='flex gap-3'>
                <ZenControlledHTMLSelectInput<BoardFormData, 'billingType'>
                  name='billingType'
                  control={control}
                  label='Billing Type'
                  placeholder='Select Billing Type'
                  options={[
                    { label: 'Select Billing Type', value: '', disabled: true },
                    ...values(BoardResponseBillingTypeEnum).map((state) => ({
                      value: state,
                      label: capitalizeEnum(state),
                    })),
                  ]}
                  shouldUnregister={false}
                />

                <ZenControlledHTMLSelectInput<BoardFormData, 'billingFrequency'>
                  name='billingFrequency'
                  control={control}
                  label='Billing Frequency'
                  placeholder='Billing Frequency'
                  options={[
                    {
                      label: 'Select Billing Frequency',
                      value: '',
                      disabled: true,
                    },
                    ...values(BoardResponseBillingFrequencyEnum).map(
                      (state) => ({
                        value: state,
                        label: capitalizeEnum(state),
                      }),
                    ),
                  ]}
                  shouldUnregister={false}
                />
              </div>
              <div className='flex gap-3'>
                <ZenControlledMoneyInput<BoardFormData, 'fee'>
                  control={control}
                  label='Fee Amount'
                  name='fee'
                  placeholder='Enter Fee Amount'
                  rules={MONEY_VALIDATIONS}
                  shouldUnregister={false}
                />
                <ZenControlledHTMLSelectInput<BoardFormData, 'paymentType'>
                  name='paymentType'
                  control={control}
                  label='Payment Type'
                  placeholder='Select Payment Type'
                  options={[
                    { label: 'Select Payment Type', value: '', disabled: true },
                    ...values(BoardResponsePaymentTypeEnum).map((state) => ({
                      value: state,
                      label: capitalizeEnum(state),
                    })),
                  ]}
                  shouldUnregister={false}
                />
              </div>
              <ZenControlledDatePickerInput<BoardFormData, 'nextDueDate'>
                name='nextDueDate'
                control={control}
                label='Next Due Date'
                placeholder='Next Due Date'
                shouldUnregister={false}
                icon={
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className='text-primary-blue'
                  />
                }
              />
              <ZenControlledHTMLSelectInput<
                BoardFormData,
                'unreportedLicenseRisk'
              >
                name='unreportedLicenseRisk'
                control={control}
                label='Unreported License Risk'
                placeholder='Select Unreported License Risk'
                options={[
                  {
                    label: 'Select Unreported License Risk',
                    value: '',
                    disabled: true,
                  },
                  ...values(BoardResponseUnreportedLicenseRiskEnum).map(
                    (state) => ({
                      value: state,
                      label: capitalizeEnum(state),
                    }),
                  ),
                ]}
                shouldUnregister={false}
              />
            </div>
          </GeminiAccordion>
        </FormProvider>
      </div>
    </ZenSidebarModalForm>
  );
};

export default GeminiCreateBoardForm;
