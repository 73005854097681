import React from 'react';
import { isMobile } from 'react-device-detect';
import { ReactComponent as Participant } from '../assets/icons/participant.svg';

interface EmptyParticipantPlaceholderProps {
  participantLength: number;
}

const EmptyParticipantPlaceholder: React.FC<EmptyParticipantPlaceholderProps> = ({
  participantLength,
}) => {
  const showEmptyParticipantCard = () => {
    if (participantLength < 3) {
      const len = (isMobile ? 2 : 3) - participantLength;
      const empty = [];
      for (let index = 0; index < len; index++) {
        empty.push(
          <div
            className='border flex justify-center flex-col items-center'
            key={index}
          >
            <Participant />
            <p className='text-gray-400 text-center'>
              The next payment participant will show up here
            </p>
          </div>,
        );
      }
      return empty;
    }
    return [];
  };

  return (
    <>
      {showEmptyParticipantCard().map((i) => {
        return i;
      })}
    </>
  );
};

export default EmptyParticipantPlaceholder;
