import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import GeminiPill from '../../../components/Gemini/GeminiPill';
import { cn } from '../../../utils/classUtils';

interface ReferralReviewRowProps {
  label: string;
  value?: string | string[];
  icon?: IconProp;
  addressPill?: boolean;
  isLast?: boolean;
}

const ReferralReviewRow: React.FC<ReferralReviewRowProps> = ({
  label,
  value,
  icon,
  addressPill = false,
  isLast = false,
}) => {
  const content = Array.isArray(value) ? value : [value];

  return (
    <div
      className={cn(
        'flex flex-row items-center pb-4',
        isLast ? 'border-b-0' : 'border-b',
      )}
    >
      <div className='flex flex-row items-center w-1/3 '>
        {icon && (
          <FontAwesomeIcon icon={icon} className='text-sm text-grey-600' />
        )}
        <label className='font-inter text-sm text-grey-500 pl-1'>{label}</label>
      </div>
      <div className='flex flex-row items-center w-2/3 flex-wrap gap-2'>
        {addressPill
          ? content.map((text) => (
              <GeminiPill
                key={text}
                label={text!}
                containerClassNames='bg-rezen-light-blue-100 text-primary-navy'
              />
            ))
          : content.map((text) => (
              <p
                key={text}
                className='font-inter text-sm text-primary-dark mr-2'
              >
                {text}
              </p>
            ))}
      </div>
    </div>
  );
};

export default ReferralReviewRow;
