import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsService from '../../../../services/AnalyticsService';
import {
  getPreCapSpp,
  setSelectedEarningsOverviewType,
} from '../../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum, RootState } from '../../../../types';
import { formatMoneyValue } from '../../../../utils/CurrencyUtils';
import { calculatePercentage } from '../../../../utils/MathUtils';
import IncomeProjectionPieChart from '../../V2/IncomeProjectionPieChart';
import WealthPlanInfoCard from '../../V2/WealthPlanInfoCard';
import { Earning } from '../../WealthManagementTypes';
import SppParticipationToggleV2 from './SppParticipationToggleV2';
import WealthPlanSidebarContainer from './WealthPlanSidebarContainer';

const PreCapSppSidebarContentV2 = () => {
  const dispatch = useDispatch();
  const {
    preCapSppResponse: { loading, data: preCapSppResponse },
    wealthGoalsResponse,
  } = useSelector((state: RootState) => state.wealthManagement);

  const {
    matchingGrantsValue,
    projectedMatchingPreCapSppValue,
    projectedPreCapSppValue,
    projectedPurchasePreCapSppValue,
    projectedVolumePerYear,
    currentPreCapSpp,
    matchingGrantsShares,
    projectedPreCapSppShares,
  } = preCapSppResponse || {};

  const isLoading = loading && !preCapSppResponse;
  const projectedPreCapSppValuePercentage = calculatePercentage(
    projectedPurchasePreCapSppValue?.amount || 0,
    projectedPreCapSppValue?.amount! > 0
      ? projectedPreCapSppValue?.amount!
      : 100,
  );
  const currentSppValue = formatMoneyValue({
    amount:
      (currentPreCapSpp ?? 0) *
      (wealthGoalsResponse.data?.stockPriceDetails.realStockPrice.amount ?? 0),
  });

  useEffect(() => {
    dispatch(getPreCapSpp());
  }, [dispatch, wealthGoalsResponse.data]);

  const handleEditClick = () => {
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.WEALTH_PLAN_PRE_CAP_EDIT_VOLUME,
    );
    dispatch(setSelectedEarningsOverviewType(Earning.COMMISSION_INCOME));
  };

  return (
    <WealthPlanSidebarContainer
      type='PRE_CAP_SPP'
      loading={isLoading}
      isEmpty={!preCapSppResponse}
      resourceName='SPP'
    >
      <WealthPlanInfoCard
        label='5 Year Projected SPP Value'
        value={formatMoneyValue(projectedPreCapSppValue)}
        labelTooltip='Projected stock value, made up of the stock you purchased from your commission income plus the Real bonus.'
        subValue={projectedPreCapSppShares}
        valueSizeVariant='text-3xl'
        tooltipAnalyticsEvent={
          AnalyticsEventEnum.WEALTH_PLAN_PRE_CAP_5_YEAR_PROJ_TOOLTIP
        }
      />
      <div className='flex flex-row justify-between space-x-3'>
        <WealthPlanInfoCard
          label='SPP Earned-to-Date'
          labelTooltip='Approximate value of stock purchased from your commission income contribution. Refer to your Morgan Stanley Shareworks account for exact value.'
          value={currentSppValue}
          subValue={currentPreCapSpp}
          tooltipAnalyticsEvent={
            AnalyticsEventEnum.WEALTH_PLAN_PRE_CAP_SPP_TO_DATE_TOOLTIP
          }
        />
        <WealthPlanInfoCard
          label='Bonus Earned-to-Date'
          value={formatMoneyValue(matchingGrantsValue)}
          subValue={matchingGrantsShares}
          labelTooltip='Approximate value of Real stock bonus awarded to you. Refer to your Morgan Stanley Shareworks account for exact value.'
          tooltipAnalyticsEvent={
            AnalyticsEventEnum.WEALTH_PLAN_PRE_CAP_BONUS_TO_DATE_TOOLTIP
          }
        />
      </div>
      <WealthPlanInfoCard
        label='Average Volume Per Year'
        value={formatMoneyValue(projectedVolumePerYear)}
        valueStyles='text-xl font-medium text-zen-dark-7'
        editButtonText='Edit'
        onEditClick={handleEditClick}
      />
      <IncomeProjectionPieChart
        data={[
          {
            value:
              projectedPreCapSppValuePercentage > 0
                ? projectedPreCapSppValuePercentage
                : 0.5,
            name: formatMoneyValue(projectedPurchasePreCapSppValue),
            fill: '#8C51D7',
            label: 'Agent Contribution',
          },
          {
            value: 100 - projectedPreCapSppValuePercentage,
            name: formatMoneyValue(projectedMatchingPreCapSppValue),
            fill: '#8BB7FF',
            label: 'Real Bonus',
          },
        ]}
        title='5 Year Projected SPP Value'
      />
      <SppParticipationToggleV2 />
      <div className='border-b border-gray-300' />
    </WealthPlanSidebarContainer>
  );
};

export default PreCapSppSidebarContentV2;
