import React from 'react';
import { cn } from '../../utils/classUtils';

export type ZenDirection = 'lr' | 'rl';

interface ZenOutlineActionButtonProps {
  text?: string;
  onClick(): void;
  icon?: React.ReactElement;
  a11yLabel?: string;
  direction?: ZenDirection;
}

const ZenOutlineActionButton: React.FC<ZenOutlineActionButtonProps> = ({
  text,
  onClick,
  icon,
  a11yLabel,
  direction = 'lr',
}) => {
  return (
    <button
      title={a11yLabel}
      onClick={onClick}
      className={cn(
        'flex rounded items-center focus:outline-none ml-2 text-primary space-x-1',
        direction === 'lr' ? 'flex-row' : 'flex-row-reverse',
      )}
    >
      {!!icon && (
        <span className={cn('text-primary', { 'pl-1': direction === 'rl' })}>
          {icon}
        </span>
      )}
      {!!text && <span className='text-primary-blue'>{text}</span>}
    </button>
  );
};

export default ZenOutlineActionButton;
