import { faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { ResourceTableVariant } from '../../../containers/ResourceTable';
import useAgentsInfo from '../../../hooks/useAgentsInfo';
import {
  AttachedFeeValue,
  FeaturesResponseEligibleEnum,
  ParticipantResponse,
  TransactionResponse,
} from '../../../openapi/arrakis';
import AnalyticsService from '../../../services/AnalyticsService';
import { toggleAdditionalFee } from '../../../slices/QuickActionSlice';
import { deleteAttachedFee } from '../../../slices/TransactionSlice';
import { AnalyticsEventEnum, RootState } from '../../../types';
import { isSmScreen } from '../../../utils/BrowserUtils';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import {
  getTransactionParticipantById,
  isTransactionReadOnly,
} from '../../../utils/TransactionHelper';
import ZenAddAttachedFee from '../../transactions/ZenAddAttachedFee';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import ZenResourceTable from '../Table/ZenResourceTable';
import ZenAdd from '../ZenAdd';
import ZenCard from '../ZenCard';
import ZenUserPill from '../ZenUserPill';
import ZenFeeTypeCell from './ZenFeeTypeCell';
import ZenIconCircleWithTooltipCell from './ZenIconCircleWithTooltipCell';

interface ZenAttachedFeeProps {
  attachedFees: AttachedFeeValue[];
  transaction: TransactionResponse;
  participants: ParticipantResponse[];
}

const columns: Array<Column<AttachedFeeValue>> = [
  {
    Header: 'Amount',
    accessor: 'amount',
    disableFilters: true,
    Cell: ({ value }) => (
      <p className='font-zen-body text-sm text-zen-dark-9 font-normal'>
        {displayAmount(value)}
      </p>
    ),
  },
  {
    Header: 'Fee Type',
    accessor: 'feeType',
    disableFilters: true,
    Cell: ({ value }) => <ZenFeeTypeCell status={value!} />,
  },
  {
    Header: 'Description',
    accessor: 'description',
    disableFilters: true,
    Cell: ({ value }) => (
      <p className='font-zen-body text-sm text-zen-dark-9 font-normal'>
        {value}
      </p>
    ),
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    disableFilters: true,
    Cell: ({ value }) => (
      <p className='font-zen-body text-sm text-zen-dark-9 font-normal'>
        {value ? DateTime.fromMillis(value).toFormat('LL/dd/yy') : 'N/A'}
      </p>
    ),
  },
];

const ZenAttachedFee: React.FC<ZenAttachedFeeProps> = ({
  attachedFees,
  transaction,
}) => {
  const dispatch = useDispatch();

  const {
    quickAction: { showAdditionalFeeCreateModal },
    auth: { userDetail },
    transaction: { features },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  const isFMLSFeeApplicable = !!features?.eligible?.includes(
    FeaturesResponseEligibleEnum.Fmls,
  );

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const [
    attachedFeeRowDetails,
    setAttachedFeeRowDetails,
  ] = useState<AttachedFeeValue>();

  const [
    attachedParticipantDetails,
    setAttachedParticipantDetails,
  ] = useState<ParticipantResponse>();
  const agentIds = useMemo(
    () =>
      transaction?.attachedFees?.map(
        (participant) => participant.counterpartyYentaId!,
      ) || [],
    [transaction],
  );
  const agentInfo = useAgentsInfo(agentIds);

  const getYentaId = useCallback(
    (participantId: string) => {
      const id = transaction?.attachedFees?.find(
        (p) => p.counterpartyId === participantId,
      )?.counterpartyYentaId;
      return id;
    },
    [transaction.attachedFees],
  );

  const columnsWithAction: Array<Column<AttachedFeeValue>> = useMemo(() => {
    let actionColumns: Array<Column<AttachedFeeValue>> = [
      {
        Header: 'Participant',
        accessor: 'counterpartyId',
        disableFilters: true,
        Cell: ({ value }) => (
          <div>
            <ZenUserPill
              noWrap
              name={getParticipantName(
                getTransactionParticipantById(transaction, value!),
              )}
              imageUrl={
                agentInfo.loading
                  ? ''
                  : agentById[getYentaId(value!)!]?.avatar || ''
              }
              backgroundVariant='background'
            />
          </div>
        ),
      },
      ...columns,
    ];

    if (!isTransactionReadOnly(transaction, userDetail!)) {
      actionColumns.push({
        Header: 'Actions',
        accessor: 'id',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className='md:space-x-3 flex md:flex-row flex-col'>
            <ZenIconCircleWithTooltipCell
              tooltipText='Edit'
              icon={
                <FontAwesomeIcon
                  icon={faPen}
                  className='text-zen-dark-8'
                  title='edit'
                />
              }
              onClick={() => {
                setAttachedFeeRowDetails(row.original);
                dispatch(toggleAdditionalFee({ isOpen: true }));
              }}
            />

            <ZenIconCircleWithTooltipCell
              tooltipText='Delete'
              icon={
                <FontAwesomeIcon
                  icon={faTrash}
                  className='text-zen-danger'
                  title='delete'
                />
              }
              onClick={() => {
                setAttachedFeeRowDetails(row.original);
                setAttachedParticipantDetails(
                  getTransactionParticipantById(
                    transaction,
                    row.original.counterpartyId!,
                  ),
                );
                setOpenDeleteModal(true);
              }}
            />
          </div>
        ),
      });
    }

    return actionColumns;
  }, [
    agentById,
    agentInfo.loading,
    dispatch,
    getYentaId,
    transaction,
    userDetail,
  ]);

  const tableInstance = useTable(
    {
      columns: columnsWithAction,
      data: attachedFees,
      initialState: {
        pageSize: 1000,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  return (
    <div className='py-4'>
      <ZenCard
        title='Additional Fees & Rebates'
        RightComponent={
          !isTransactionReadOnly(transaction, userDetail!) ? (
            <div className='flex flex-row items-center space-x-4'>
              <ZenAdd
                text='Add Fees & Rebates'
                onClick={() => {
                  setAttachedFeeRowDetails(undefined);
                  dispatch(toggleAdditionalFee({ isOpen: true }));
                }}
              />
            </div>
          ) : undefined
        }
      >
        <ZenResourceTable<AttachedFeeValue>
          {...tableInstance}
          resourceName='Additional Fees & Rebate'
          fetchData={() => ({
            data: attachedFees,
            total: attachedFees.length,
          })}
          totalCount={attachedFees.length}
          variant={
            isSmScreen() ? ResourceTableVariant.CARD : ResourceTableVariant.ROW
          }
          headerVariant='light'
          hidePageSize
          hidePagination
        />
      </ZenCard>

      {showAdditionalFeeCreateModal?.isOpen && (
        <ZenAddAttachedFee
          isOpen
          onClose={() => {
            setAttachedFeeRowDetails(undefined);
            dispatch(toggleAdditionalFee({ isOpen: false }));
          }}
          attachedFee={attachedFeeRowDetails!}
          transaction={transaction}
          preselectedFeeType={showAdditionalFeeCreateModal?.preselectedFeeType}
        />
      )}

      <ZenConfirmationModal
        isOpen={openDeleteModal}
        size='default'
        variant='danger'
        title='Delete Additional fee / Rebate'
        subtitle={`Are you sure you want to delete an additional fee / rebate of ${displayAmount(
          attachedFeeRowDetails?.amount,
        )} from ${getParticipantName(attachedParticipantDetails)}`}
        confirmButtonText='Delete'
        isSubmitting={deleting}
        isDisabled={deleting}
        onClose={() => {
          setAttachedFeeRowDetails(undefined);
          setOpenDeleteModal(false);
        }}
        onConfirm={async () => {
          setDeleting(true);
          await dispatch(
            deleteAttachedFee(attachedFeeRowDetails?.id!, transaction?.id!),
          );
          if (isFMLSFeeApplicable && attachedFeeRowDetails?.addedBySystem) {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.FMLS_AMOUNT_DELETED_WHICH_CREATED_BY_SYS,
            );
          }
          setDeleting(false);
          setOpenDeleteModal(false);
          setAttachedFeeRowDetails(undefined);
        }}
        hideIcon={true!}
      />
    </div>
  );
};

export default ZenAttachedFee;
