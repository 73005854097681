import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  getTeamDetailOverview,
  saveTeamDetailOverview,
} from '../slices/TeamSlice';
import { AppDispatch, RootState } from '../types';
import DetailPageLoader from '../components/DetailPageLoader';
import ZenTeamsDetailRoute from './ZenTeamsDetailsRoute';
import ZenTeamsProDetailRoute from './ZenTeamsProDetailsRoute';

interface Match {
  id: string;
}

interface ZenTeamDecideRouteProps extends RouteComponentProps<Match> {}

const ZenTeamDecideRoute: React.FC<ZenTeamDecideRouteProps> = (props) => {
  const dispatch: AppDispatch = useDispatch();
  const teamId = props.match.params.id;
  const {
    team: { teamDetailOverview },
  } = useSelector((state: RootState) => state);

  const fetchTeamData = useCallback(async () => {
    await dispatch(getTeamDetailOverview(teamId));
  }, [dispatch, teamId]);

  useEffect(() => {
    fetchTeamData();

    return () => {
      // cleanup
      dispatch(saveTeamDetailOverview(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (teamId !== teamDetailOverview?.id) {
    return <DetailPageLoader />;
  }

  if (teamDetailOverview?.flex) {
    return <ZenTeamsProDetailRoute {...props} />;
  }

  return <ZenTeamsDetailRoute {...props} />;
};

export default ZenTeamDecideRoute;
