import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  AdministrativeAreaResponseCountryEnum,
  AddressResponseCountryEnum,
  OfficeResponse,
} from '../../../openapi/yenta';
import {
  formatAddressObject,
  formatPhoneNumber,
} from '../../../utils/StringUtils';
import AdminOnly from '../../auth/AdminOnly';
import StateIcon from '../../StateIcon';
import ZenCopyToClipboard from '../ZenCopyToClipBoard';
import ZenStatusPill from '../ZenStatusPill';

interface ZenOfficeDetailCardProps {
  officeDetail: OfficeResponse;
  onClickEdit(): void;
}

const ZenOfficeDetailCard: React.FC<ZenOfficeDetailCardProps> = ({
  officeDetail,
  onClickEdit,
}) => {
  return (
    <div
      data-testid='office-card'
      className='px-4 lg:px-6 2xl:px-8 py-8 md:rounded-[40px] rounded-[10px] bg-white md:shadow-lg md:border-0 border border-zen-dark-4 max-h-[77vh] overflow-y-scroll'
    >
      <div className='relative space-y-3 w-full'>
        <div className='absolute right-0 -top-7 flex flex-row items-center space-x-1.5 md:space-x-3'>
          <ZenStatusPill
            text={!!officeDetail.active ? 'Active' : 'Inactive'}
            variant={!!officeDetail.active ? 'primary' : 'danger'}
          />
          <AdminOnly>
            <button
              className='flex items-center justify-center rounded-lg md:p-2.5 p-1 cursor-pointer bg-white shadow-lg'
              onClick={onClickEdit}
            >
              <FontAwesomeIcon
                icon={faPen}
                className='h-[18px] w-[18px] text-zen-dark-12'
                title='Edit Office'
              />
            </button>
          </AdminOnly>
        </div>
        <div className='space-y-8'>
          <div className='flex justify-center mb-2'>
            <div className='border-[10px] border-zen-salon-blue p-2 rounded-full'>
              {!!officeDetail?.imageUrl ? (
                <img
                  className='object-contain h-60 w-60 rounded-full'
                  src={officeDetail?.imageUrl}
                  alt='officeImage'
                />
              ) : (
                <StateIcon
                  state={officeDetail.administrativeArea?.stateOrProvince!}
                  width={240}
                  variant='circle'
                />
              )}
            </div>
          </div>
          <div className='space-y-4 font-zen-body'>
            <p className='text-[22px] leading-5 font-semibold text-zen-dark-9'>
              {officeDetail.name}
            </p>
            <div className='space-y-4'>
              <div className='-space-y-2 -mb-2.5'>
                <p className='text-zen-dark-9 text-xs leading-4'>
                  {officeDetail?.administrativeArea?.country ===
                  AdministrativeAreaResponseCountryEnum.Canada
                    ? 'BN'
                    : 'EIN'}
                </p>

                <div className='-ml-2'>
                  <ZenCopyToClipboard
                    label={officeDetail?.company?.ein!}
                    value={officeDetail?.company?.ein!}
                    textStyle='text-base'
                    iconStyle='h-6 w-4'
                  />
                </div>
              </div>
              <div className='space-y-1 '>
                <p className=' text-zen-dark-9 text-xs leading-4'>
                  Mailing & Payment Processing Address
                </p>
                <div className='flex space-x-1.5'>
                  <div className=''>
                    <FontAwesomeIcon
                      icon={regular('location-dot')}
                      className='mt-1'
                    />
                  </div>
                  <h6 className='text-base text-zen-dark-9'>
                    {(officeDetail.address &&
                      formatAddressObject(officeDetail.address!)) ||
                      'N/A'}
                  </h6>
                </div>
              </div>

              {officeDetail.stateOfficeAddress && (
                <div className='space-y-1'>
                  <p className=' text-zen-dark-9 text-xs leading-4'>
                    {officeDetail.address?.country ===
                    AddressResponseCountryEnum.Canada
                      ? 'Province Office Address'
                      : 'State Office Address'}
                  </p>
                  <div className='flex space-x-1.5'>
                    <div className=''>
                      <FontAwesomeIcon
                        icon={regular('location-dot')}
                        className='mt-1'
                      />
                    </div>
                    <h6 className='text-base text-zen-dark-9'>
                      {formatAddressObject(officeDetail.stateOfficeAddress!)}
                    </h6>
                  </div>
                </div>
              )}

              {officeDetail.branchOfficeAddress && (
                <div className='space-y-1'>
                  <p className=' text-zen-dark-9 text-xs leading-4'>
                    Branch Office Address
                  </p>
                  <div className='flex space-x-1.5'>
                    <div className=''>
                      <FontAwesomeIcon
                        icon={regular('location-dot')}
                        className='mt-1'
                      />
                    </div>
                    <h6 className='text-base text-zen-dark-9'>
                      {formatAddressObject(officeDetail.branchOfficeAddress!)}
                    </h6>
                  </div>
                </div>
              )}

              <div className='space-y-1'>
                <p className=' text-zen-dark-9 text-xs leading-4'>
                  Phone & Fax Numbers
                </p>
                <div className='space-y-2'>
                  <div className='flex space-x-1.5'>
                    <div className=''>
                      <FontAwesomeIcon
                        icon={regular('phone')}
                        className='mt-1'
                      />
                    </div>
                    <h6 className='text-base text-zen-dark-9'>
                      {formatPhoneNumber(officeDetail.phoneNumber) || 'N/A'}
                    </h6>
                  </div>
                  <div className='flex space-x-1.5'>
                    <div className=''>
                      <FontAwesomeIcon icon={regular('fax')} className='mt-1' />
                    </div>
                    <h6 className='text-base text-zen-dark-9'>
                      {formatPhoneNumber(officeDetail.faxNumber) || 'N/A'}
                    </h6>
                  </div>
                </div>
              </div>

              <div className='space-y-1'>
                <p className=' text-zen-dark-9 text-xs leading-4'>
                  Support Email
                </p>
                <div className='flex space-x-1.5'>
                  <div className=''>
                    <FontAwesomeIcon
                      icon={regular('envelope')}
                      className='mt-1'
                    />
                  </div>
                  <h6 className='text-base text-zen-dark-9'>
                    {officeDetail.emailAddress || 'N/A'}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenOfficeDetailCard;
