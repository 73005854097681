import { faCircleInfo, faTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Slider from 'rc-slider';
import CurrencyInput from 'react-currency-input-field';
import {
  Control,
  useController,
  useForm,
  UseFormWatch,
} from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { MoneyValueCurrencyEnum } from '../../../openapi/plutus';
import AnalyticsService from '../../../services/AnalyticsService';
import { updateWealthGoals } from '../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum, RootState } from '../../../types';
import ZenFormErrorMessage from '../../Zen/Input/ZenFormErrorMessage';
import { useDebounce } from '../utils';
import { WealthPlanTooltip } from '../WealthManagementUtils';
import { GoalsFormInput } from './SetGoalsSideBarV2';
import WealthManagementCardV2 from './WealthManagementCardV2';

const MAX_STOCK_PRICE = 10;

interface StockForecastSliderInputProps {
  formControlParam?: Control<GoalsFormInput, object>;
  formWatchParam?: UseFormWatch<GoalsFormInput>;
  noContainer?: boolean;
}

const StockForecastSliderInput: React.FC<StockForecastSliderInputProps> = ({
  formControlParam,
  formWatchParam,
  noContainer = true,
}) => {
  const {
    defaultWealthGoalsResponse: { data: defaultWealthGoalsData },
    wealthGoalsResponse: { data: wealthGoalsData },
  } = useSelector((state: RootState) => state.wealthManagement);
  const dispatch = useDispatch();

  const realStockFuturePriceAmount =
    wealthGoalsData?.goalDetails.realStockFuturePrice.amount ??
    defaultWealthGoalsData?.goalDetails.realStockFuturePrice.amount ??
    0;

  const { control, watch, handleSubmit } = useForm<GoalsFormInput>({
    defaultValues: { realStockFuturePrice: realStockFuturePriceAmount },
  });

  const formControl = formControlParam ?? control;
  const formWatch = formWatchParam ?? watch;

  const realStockFuturePrice = formWatch('realStockFuturePrice') ?? 0;
  const realStockPrice =
    wealthGoalsData?.stockPriceDetails.realStockPrice.amount ??
    defaultWealthGoalsData?.stockPriceDetails.realStockPrice.amount ??
    1.23;

  const {
    field: { onChange, value, name },
    fieldState: { error },
  } = useController({
    control: formControl,
    name: 'realStockFuturePrice',
    defaultValue: realStockFuturePriceAmount,
    rules: {
      required: 'Value is required',
      min: {
        value: 0,
        message: 'Value must be greater than 0',
      },
      max: {
        value: MAX_STOCK_PRICE,
        message: `Value must be less than or equal to ${MAX_STOCK_PRICE}`,
      },
    },
  });

  useDebounce(
    handleSubmit((formData) => {
      if (
        formData.realStockFuturePrice !== realStockFuturePriceAmount &&
        !formControlParam &&
        !formWatchParam
      ) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.WEALTH_PLAN_FUTURE_STOCK_PRICE,
        );
        dispatch(
          updateWealthGoals({
            realStockFuturePrice: {
              amount: formData.realStockFuturePrice,
              currency: MoneyValueCurrencyEnum.Usd,
            },
          }),
        );
      }
    }),
    {
      delay: 500,
      dependencies: [realStockFuturePrice],
    },
  );

  return (
    <WealthManagementCardV2 noContainer={noContainer} className='md:!p-4 !p-3'>
      <div className='flex md:flex-row flex-col items-start justify-between md:space-x-5'>
        <div className='flex-shrink-0'>
          <div className='flex flex-row flex-wrap items-center font-zen-body text-base text-zen-dark-7'>
            <span className='font-zen-body-2 text-lg leading-6 shrink-0'>
              Real Stock Price
            </span>
            <span className='h-1 w-1 bg-zen-dark-4 mx-2 rounded-full' />
            <span>REAX</span>
            <span className='text-zen-dark-9 mx-1'>
              ${realStockPrice.toFixed(2)}
            </span>
            <span>(Delayed 15 mins)</span>
          </div>
          <div className='flex items-center font-zen-body text-base mt-1'>
            <span className='mr-1'>
              Forecast Real&apos;s stock price in 5 years.
            </span>
            <WealthPlanTooltip
              title='Real stock price estimate will be used to forecast the value of your Real stock in 5 years. Real stock prices are in USD.'
              arrow
            >
              <FontAwesomeIcon icon={faCircleInfo} />
            </WealthPlanTooltip>
          </div>
        </div>
        <div className='flex gap-4 place-content-between flex-shrink'>
          <div
            className={classNames(
              'flex items-center border rounded-lg overflow-hidden justify-center',
              value && 'text-zen-dark-9',
            )}
          >
            <div className='font-zen-body text-base font-medium text-primary-blue h-full w-8 flex items-center justify-center'>
              $
            </div>
            <CurrencyInput
              id={`${name}.input`}
              name={`${name}.input`}
              value={value}
              onValueChange={(value) => {
                onChange(value);
                AnalyticsService.instance().logEvent(
                  AnalyticsEventEnum.WEALTH_PLAN_FUTURE_STOCK_PRICE,
                );
              }}
              className={classNames(
                'appearance-none p-2 border-none rounded-lg w-full focus:outline-none focus:ring-0 font-zen-body font-normal',
              )}
              placeholder='Enter value'
              allowDecimals
              min={0}
              max={MAX_STOCK_PRICE}
              allowNegativeValue={false}
              decimalSeparator='.'
            />
          </div>
        </div>
      </div>
      <div className='h-10 flex items-center'>
        {!!error && <ZenFormErrorMessage message={error.message} />}
      </div>
      <div className='w-full px-3'>
        <div className='relative w-full mb-20'>
          <div
            style={{
              left: `max(min(calc(${
                (realStockFuturePrice / MAX_STOCK_PRICE) * 100
              }% - 2rem), 90%), -2%)`,
            }}
            className='absolute top-[-75px] flex flex-col items-center gap-1'
          >
            <div className='border-1 px-2 border-gray-200 border bg-white rounded text-gray-300 text-center'>
              <div>Future</div>
              <div className='text-primary-blue'>
                ${realStockFuturePrice ?? 0}
              </div>
            </div>
          </div>
        </div>
        <div className='relative w-full'>
          <div
            style={{
              left: `${(realStockFuturePrice / MAX_STOCK_PRICE) * 100}%`,
              transform: 'translateX(-50%)',
            }}
            className='absolute flex flex-col items-center top-[-22px]'
          >
            <FontAwesomeIcon
              icon={faTriangle}
              className='text-primary-blue'
              rotation={180}
            />
          </div>
        </div>
        <Slider
          value={value ?? 0}
          min={0}
          max={MAX_STOCK_PRICE}
          step={0.05}
          onChange={(value) => {
            onChange(value);
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.WEALTH_PLAN_FUTURE_STOCK_PRICE,
            );
          }}
          dotStyle={{
            backgroundColor: '#3B82F6',
            borderColor: '#3B82F6',
            height: 12,
            width: 12,
          }}
          handleStyle={{
            borderColor: '#3B82F6',
            borderWidth: 8,
            top: 0,
            left: 8,
            height: 25,
            width: 25,
            backgroundColor: '#FFF',
            boxShadow:
              '0px 4px 2px rgba(1, 62, 80, 0.12), 0px 2px 2px rgba(1, 62, 80, 0.24)',
          }}
          trackStyle={{
            backgroundColor: '#3B82F6',
            height: 6,
            borderRadius: 15,
            opacity: 0.8,
          }}
          railStyle={{
            backgroundColor: '#3B82F6',
            opacity: 0.2,
            height: 6,
            borderRadius: 10,
          }}
        />
        <div className='relative w-full'>
          <div
            style={{
              left: `${(realStockPrice / MAX_STOCK_PRICE) * 100}%`,
              transform: 'translateX(-50%)',
            }}
            className='absolute top-2 flex flex-col items-center gap-1'
          >
            <FontAwesomeIcon icon={faTriangle} className='text-green-600' />
          </div>
        </div>
        <div className='relative w-full mb-24'>
          <div
            style={{
              left: `max(min(calc(${
                (realStockPrice / MAX_STOCK_PRICE) * 100
              }% - 2rem), 90%), -2%)`,
            }}
            className='absolute top-[26px] flex flex-col items-center gap-1'
          >
            <div className='border-1 px-2 border-gray-200 border bg-white rounded text-gray-300 text-center'>
              <div>Today</div>
              <div className='text-green-600'>
                ${realStockPrice?.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </WealthManagementCardV2>
  );
};
export default StockForecastSliderInput;
